export const polygonsData = [
    {
        type: 'Polygon',
        coordinates: [
            [
                [104.7784534, 10.4872499],
                [104.7857286, 10.4792016],
                [104.7911061, 10.473276],
                [104.7976569, 10.4661384],
                [104.8044594, 10.4486789],
                [104.8080385, 10.4426665],
                [104.8101745, 10.4390047],
                [104.8139742, 10.4324905],
                [104.8190036, 10.4238036],
                [104.8238983, 10.4156021],
                [104.8278128, 10.4089263],
                [104.8256067, 10.4063574],
                [104.8305176, 10.3998915],
                [104.8330802, 10.3965693],
                [104.8385938, 10.3896597],
                [104.8437445, 10.3830424],
                [104.8489482, 10.3762148],
                [104.8541516, 10.3693084],
                [104.8607087, 10.3606161],
                [104.8645288, 10.3556219],
                [104.8647561, 10.3554778],
                [104.8649865, 10.3553384],
                [104.86522, 10.3552041],
                [104.8654938, 10.3550548],
                [104.86579, 10.3549018],
                [104.8661668, 10.3547063],
                [104.8665592, 10.3544944],
                [104.8669333, 10.3542852],
                [104.8673042, 10.3540706],
                [104.8674148, 10.3540102],
                [104.8675224, 10.3539448],
                [104.8676267, 10.3538744],
                [104.8677178, 10.3538068],
                [104.8678128, 10.3537445],
                [104.8679113, 10.3536878],
                [104.868013, 10.3536369],
                [104.8681265, 10.3535884],
                [104.8682428, 10.353547],
                [104.8683616, 10.3535129],
                [104.8779044, 10.3512705],
                [104.8868989, 10.349172],
                [104.8936646, 10.3475978],
                [104.9034901, 10.3452904],
                [104.9115204, 10.3435569],
                [104.9205675, 10.3414574],
                [104.9302247, 10.3392788],
                [104.939643, 10.3370737],
                [104.944686, 10.3359108],
                [104.9471247, 10.3353434],
                [104.9544812, 10.3336095],
                [104.9589829, 10.3325646],
                [104.9645245, 10.3312685],
                [104.9699521, 10.3300287],
                [104.9784732, 10.3280749],
                [104.9796243, 10.327743],
                [104.9832745, 10.3269851],
                [104.9886071, 10.3258013],
                [104.9968915, 10.3238642],
                [104.9997358, 10.3232173],
                [105.0054548, 10.3219096],
                [105.0085616, 10.3211889],
                [105.0143044, 10.3198831],
                [105.0191306, 10.3187981],
                [105.0172319, 10.3161375],
                [105.0187923, 10.3152175],
                [105.0274849, 10.3101872],
                [105.0292167, 10.3092178],
                [105.0374913, 10.3043952],
                [105.0408919, 10.3024089],
                [105.0425678, 10.30143],
                [105.0445015, 10.3003005],
                [105.0476043, 10.2981849],
                [105.0515346, 10.2960009],
                [105.0563843, 10.2931575],
                [105.0566602, 10.293021],
                [105.0576871, 10.2925616],
                [105.0587519, 10.2919461],
                [105.0616695, 10.29023],
                [105.0638033, 10.2889609],
                [105.0667719, 10.2871858],
                [105.0698689, 10.2853863],
                [105.0705889, 10.2849282],
                [105.0707441, 10.2848212],
                [105.0712755, 10.2846184],
                [105.0736821, 10.2832567],
                [105.074873, 10.2825652],
                [105.0761443, 10.2818421],
                [105.0781604, 10.2806796],
                [105.0802836, 10.2794439],
                [105.0822227, 10.2783196],
                [105.0845396, 10.2769702],
                [105.0865545, 10.2757856],
                [105.0877207, 10.2751122],
                [105.0889331, 10.2743838],
                [105.091385, 10.2729156],
                [105.0953255, 10.2708118],
                [105.0974885, 10.2696418],
                [105.1066249, 10.264151],
                [105.1077388, 10.2635468],
                [105.1078732, 10.2634737],
                [105.1085138, 10.2631256],
                [105.1099586, 10.2622594],
                [105.1113084, 10.2615236],
                [105.1143169, 10.2597466],
                [105.1174025, 10.2580609],
                [105.1183027, 10.2575233],
                [105.1209419, 10.2560069],
                [105.123304, 10.2546637],
                [105.1253835, 10.2534524],
                [105.1256233, 10.2532762],
                [105.1260932, 10.252958],
                [105.1267093, 10.2526063],
                [105.1355426, 10.2447608],
                [105.1413333, 10.2385651],
                [105.1415653, 10.2384255],
                [105.1433072, 10.2379185],
                [105.1463187, 10.2351976],
                [105.1488014, 10.2330245],
                [105.1501155, 10.2318226],
                [105.1529205, 10.2295355],
                [105.1537796, 10.2286008],
                [105.1574436, 10.2255124],
                [105.1609728, 10.2223573],
                [105.1645523, 10.2192355],
                [105.1651832, 10.218608],
                [105.1605505, 10.2133591],
                [105.1831591, 10.1984872],
                [105.1869459, 10.1959676],
                [105.1907358, 10.1934527],
                [105.194529, 10.1909424],
                [105.1983148, 10.1884438],
                [105.2021038, 10.1859497],
                [105.2058959, 10.1834603],
                [105.2137686, 10.1942762],
                [105.2168432, 10.1985253],
                [105.2273862, 10.2129571],
                [105.2275218, 10.2128188],
                [105.2276533, 10.2126767],
                [105.2277803, 10.2125308],
                [105.2279121, 10.2123696],
                [105.2280386, 10.2122045],
                [105.2281597, 10.2120354],
                [105.2296173, 10.2099399],
                [105.2296771, 10.2098546],
                [105.229741, 10.2097722],
                [105.2298122, 10.2096895],
                [105.2298875, 10.2096105],
                [105.2299668, 10.2095353],
                [105.2302347, 10.2092945],
                [105.2305055, 10.2090569],
                [105.2308083, 10.2087979],
                [105.2311146, 10.2085429],
                [105.2330951, 10.2111949],
                [105.2330267, 10.2112584],
                [105.2336222, 10.2119447],
                [105.2342713, 10.2127208],
                [105.2355909, 10.2143469],
                [105.2369374, 10.2160204],
                [105.2383291, 10.2177561],
                [105.2395445, 10.2194345],
                [105.2407569, 10.221115],
                [105.2419859, 10.2228002],
                [105.2422473, 10.2231602],
                [105.2425057, 10.2235223],
                [105.2428128, 10.2239612],
                [105.2431154, 10.2244031],
                [105.2427289, 10.2248243],
                [105.2480094, 10.2317643],
                [105.253082, 10.2385529],
                [105.2609219, 10.2459188],
                [105.2671215, 10.2389138],
                [105.2746732, 10.2466094],
                [105.2758817, 10.2478131],
                [105.281801, 10.2533628],
                [105.2881065, 10.2592973],
                [105.2888462, 10.2599806],
                [105.2874575, 10.2621656],
                [105.2920157, 10.2666093],
                [105.2913631, 10.2674413],
                [105.2957885, 10.2715435],
                [105.2961404, 10.2717681],
                [105.2990001, 10.2746962],
                [105.3012161, 10.2770331],
                [105.3013065, 10.2770704],
                [105.3014394, 10.2770344],
                [105.301522, 10.2769587],
                [105.3065283, 10.2690398],
                [105.3120201, 10.2740144],
                [105.313957, 10.2706851],
                [105.3153106, 10.2684848],
                [105.3158205, 10.2676117],
                [105.3179651, 10.2641016],
                [105.3191957, 10.2620935],
                [105.3214281, 10.2583389],
                [105.3221312, 10.2571689],
                [105.3238011, 10.2543575],
                [105.3251545, 10.2521047],
                [105.3264614, 10.2501123],
                [105.3292036, 10.2455825],
                [105.3309567, 10.2428931],
                [105.3312036, 10.2425418],
                [105.3326073, 10.240282],
                [105.3349782, 10.2363869],
                [105.336192, 10.2343516],
                [105.3444152, 10.2368733],
                [105.3453949, 10.2371737],
                [105.3474133, 10.2378001],
                [105.3475551, 10.2378459],
                [105.3513886, 10.2390838],
                [105.3563001, 10.2406349],
                [105.3570498, 10.2409198],
                [105.3606284, 10.2419876],
                [105.3650402, 10.2432852],
                [105.3661312, 10.2435114],
                [105.3712773, 10.2450984],
                [105.3747145, 10.2462175],
                [105.3754735, 10.2464592],
                [105.3810733, 10.2481715],
                [105.3839229, 10.2490279],
                [105.3881468, 10.2502676],
                [105.3932966, 10.2517061],
                [105.3966557, 10.2527238],
                [105.3980584, 10.2531906],
                [105.3988663, 10.2534658],
                [105.3997483, 10.2538012],
                [105.4012896, 10.2544193],
                [105.4030326, 10.2550938],
                [105.4048838, 10.2557918],
                [105.4069155, 10.2565883],
                [105.4098888, 10.2577238],
                [105.4165406, 10.2602787],
                [105.4221304, 10.262464],
                [105.4234929, 10.2629655],
                [105.4266955, 10.2642271],
                [105.4283692, 10.2647972],
                [105.4298652, 10.2653398],
                [105.4299843, 10.265383],
                [105.4331864, 10.2666552],
                [105.4342282, 10.2670119],
                [105.4360319, 10.2674917],
                [105.4359417, 10.2677609],
                [105.4391563, 10.2690026],
                [105.441357, 10.2698772],
                [105.4403934, 10.2720383],
                [105.4389447, 10.2752254],
                [105.4366261, 10.2803388],
                [105.4345192, 10.2851188],
                [105.4325364, 10.289548],
                [105.4314188, 10.2920817],
                [105.4302288, 10.2946635],
                [105.4290098, 10.2973965],
                [105.4278177, 10.3001279],
                [105.426874, 10.3022908],
                [105.4265297, 10.3030676],
                [105.4296466, 10.3036068],
                [105.4316928, 10.3039583],
                [105.4339395, 10.3043503],
                [105.4401223, 10.3053819],
                [105.4430297, 10.3058761],
                [105.4433795, 10.3061697],
                [105.4443315, 10.3058377],
                [105.4457904, 10.3053334],
                [105.4475713, 10.3047135],
                [105.4476566, 10.3050057],
                [105.4477117, 10.3051765],
                [105.4477828, 10.3053664],
                [105.4480375, 10.3055866],
                [105.4486396, 10.3059561],
                [105.4489134, 10.3062406],
                [105.4491373, 10.3065367],
                [105.4494165, 10.3067351],
                [105.4496899, 10.3068644],
                [105.4499992, 10.3069885],
                [105.4502831, 10.3071334],
                [105.4505364, 10.3072986],
                [105.4507827, 10.3075231],
                [105.45107, 10.3078126],
                [105.4514758, 10.3082791],
                [105.4518245, 10.3073048],
                [105.4522868, 10.3058432],
                [105.4529064, 10.3061435],
                [105.4531673, 10.306252],
                [105.4537164, 10.3064281],
                [105.4544993, 10.3069237],
                [105.4549801, 10.3072767],
                [105.4559001, 10.3076973],
                [105.4565866, 10.3080365],
                [105.4573533, 10.3082213],
                [105.4590949, 10.3084936],
                [105.4608141, 10.3088196],
                [105.4647432, 10.3094954],
                [105.4681014, 10.3100763],
                [105.4687968, 10.3106319],
                [105.4723504, 10.3107158],
                [105.4751978, 10.3107537],
                [105.4754427, 10.3104155],
                [105.4762632, 10.3108875],
                [105.4761653, 10.3110218],
                [105.4765059, 10.3112987],
                [105.4769924, 10.3106983],
                [105.478623, 10.3119772],
                [105.4792782, 10.3124996],
                [105.4796174, 10.31277],
                [105.4818148, 10.3143633],
                [105.4830177, 10.3152274],
                [105.4838197, 10.3158457],
                [105.4841726, 10.3161232],
                [105.4844455, 10.3164247],
                [105.4847347, 10.3169962],
                [105.4850081, 10.3176471],
                [105.4853139, 10.3185442],
                [105.4855876, 10.3193857],
                [105.4860706, 10.3209022],
                [105.4866018, 10.3225298],
                [105.4868594, 10.3233476],
                [105.4870202, 10.323681],
                [105.4872127, 10.3238078],
                [105.487405, 10.323879],
                [105.4877735, 10.3239261],
                [105.4882222, 10.3239254],
                [105.4887991, 10.3240198],
                [105.4895204, 10.3242411],
                [105.4902896, 10.3243273],
                [105.4907062, 10.3243426],
                [105.4913152, 10.3244846],
                [105.4921486, 10.3246739],
                [105.4960645, 10.3252088],
                [105.4922554, 10.3294654],
                [105.4880479, 10.334198],
                [105.4903966, 10.3365624],
                [105.4911964, 10.337789],
                [105.4915485, 10.3389086],
                [105.4916097, 10.3396015],
                [105.4915485, 10.3401638],
                [105.4914044, 10.3406924],
                [105.4907993, 10.3420012],
                [105.4905402, 10.3426832],
                [105.4904442, 10.3435538],
                [105.4905427, 10.3443273],
                [105.4908845, 10.345052],
                [105.4915187, 10.3460665],
                [105.4920794, 10.3468151],
                [105.4926213, 10.3473519],
                [105.4928591, 10.3475875],
                [105.493282, 10.3478667],
                [105.493882, 10.3482629],
                [105.4950019, 10.3487446],
                [105.4963166, 10.3493229],
                [105.4971447, 10.3499018],
                [105.4978271, 10.3505777],
                [105.4985833, 10.3518579],
                [105.4992092, 10.3531119],
                [105.4995598, 10.3539355],
                [105.4995838, 10.3539652],
                [105.4999499, 10.3544185],
                [105.500408, 10.354708],
                [105.5012353, 10.3550084],
                [105.5017844, 10.3550717],
                [105.5020204, 10.3551151],
                [105.5026315, 10.355159],
                [105.5035368, 10.3551575],
                [105.5046004, 10.3551447],
                [105.5055284, 10.3551879],
                [105.5066377, 10.3553651],
                [105.5071812, 10.3555656],
                [105.5076568, 10.3557774],
                [105.5084611, 10.3563019],
                [105.509039, 10.3567708],
                [105.5096624, 10.3574186],
                [105.5102749, 10.3583013],
                [105.5108533, 10.3591059],
                [105.5115902, 10.3599213],
                [105.5123157, 10.3606472],
                [105.5131882, 10.3613617],
                [105.5141396, 10.3618971],
                [105.5148983, 10.3621979],
                [105.5162229, 10.3625538],
                [105.5172416, 10.3627535],
                [105.5182376, 10.3628301],
                [105.5190411, 10.36284],
                [105.5195278, 10.3629063],
                [105.520139, 10.3629724],
                [105.5206032, 10.3631618],
                [105.5208293, 10.3633643],
                [105.5209994, 10.3634965],
                [105.5208222, 10.3641432],
                [105.5205439, 10.3651827],
                [105.5203443, 10.3660766],
                [105.52025, 10.3668115],
                [105.5202209, 10.3675244],
                [105.5202545, 10.3681489],
                [105.5203218, 10.3688375],
                [105.5204497, 10.3693253],
                [105.5206382, 10.369738],
                [105.5209231, 10.370122],
                [105.5211856, 10.3703736],
                [105.5215356, 10.3705766],
                [105.5222267, 10.370868],
                [105.5235123, 10.371466],
                [105.5247627, 10.3721574],
                [105.5259937, 10.3728962],
                [105.5264671, 10.3732493],
                [105.5273309, 10.3739952],
                [105.5279928, 10.3746772],
                [105.5283414, 10.3752193],
                [105.5286023, 10.3756103],
                [105.5288181, 10.3761022],
                [105.5288867, 10.37646],
                [105.5289015, 10.376979],
                [105.5289187, 10.3772106],
                [105.5289329, 10.3774028],
                [105.5289688, 10.3778971],
                [105.52898, 10.378301],
                [105.5288589, 10.3788593],
                [105.5287198, 10.3793206],
                [105.5285178, 10.3799474],
                [105.5283226, 10.3804836],
                [105.5279435, 10.3813377],
                [105.5273916, 10.3824025],
                [105.5271223, 10.3830701],
                [105.5268391, 10.3835892],
                [105.5266028, 10.3844062],
                [105.5265143, 10.3849019],
                [105.5264268, 10.3854249],
                [105.5263976, 10.3863761],
                [105.5265143, 10.3871992],
                [105.5266309, 10.3888036],
                [105.5266579, 10.3894679],
                [105.5266175, 10.3901255],
                [105.5265349, 10.3910734],
                [105.526434, 10.3916664],
                [105.526162, 10.392553],
                [105.5258591, 10.3934534],
                [105.5255001, 10.3945944],
                [105.5252264, 10.395764],
                [105.5247261, 10.3971145],
                [105.5241368, 10.3984381],
                [105.5238241, 10.3992485],
                [105.5237007, 10.3996214],
                [105.5237344, 10.4000363],
                [105.5238569, 10.4002619],
                [105.5240158, 10.400463],
                [105.5242774, 10.4007403],
                [105.5245847, 10.4009279],
                [105.5249033, 10.4010338],
                [105.5251838, 10.401036],
                [105.5255203, 10.4009301],
                [105.5261777, 10.4007204],
                [105.5267947, 10.4005858],
                [105.5278749, 10.4003895],
                [105.5284752, 10.4002702],
                [105.5288521, 10.4002526],
                [105.5292537, 10.4002769],
                [105.5295903, 10.4004159],
                [105.5298259, 10.400716],
                [105.5300659, 10.4013427],
                [105.5302522, 10.4017841],
                [105.5307615, 10.4028499],
                [105.531185, 10.4035609],
                [105.5315557, 10.4043505],
                [105.5316903, 10.4047478],
                [105.5317082, 10.4050813],
                [105.5316976, 10.4054617],
                [105.5315742, 10.4060883],
                [105.5314956, 10.4064912],
                [105.5314625, 10.4070282],
                [105.5314858, 10.4073526],
                [105.5315086, 10.407654],
                [105.5316096, 10.4079608],
                [105.5317778, 10.4082234],
                [105.5320381, 10.4084176],
                [105.5323612, 10.4085875],
                [105.532682, 10.4087088],
                [105.5331958, 10.4089604],
                [105.5335862, 10.4089538],
                [105.53425, 10.4089028],
                [105.5350109, 10.4091083],
                [105.5355404, 10.4093289],
                [105.5361462, 10.4096026],
                [105.5367049, 10.4099799],
                [105.5370414, 10.4103683],
                [105.5377975, 10.4115423],
                [105.538253, 10.4121403],
                [105.5386927, 10.4125882],
                [105.5390024, 10.4128905],
                [105.5393524, 10.4131134],
                [105.5395992, 10.4132657],
                [105.5400322, 10.4134599],
                [105.5405909, 10.4134841],
                [105.541026, 10.4134553],
                [105.5419819, 10.413387],
                [105.5431037, 10.4131377],
                [105.5435457, 10.4129921],
                [105.5441147, 10.4128795],
                [105.5445673, 10.412834],
                [105.5448277, 10.4128111],
                [105.5451333, 10.4128442],
                [105.545405, 10.412922],
                [105.5458275, 10.413109],
                [105.5462403, 10.4135239],
                [105.5467048, 10.4139277],
                [105.5472186, 10.4143447],
                [105.5477526, 10.4147618],
                [105.5483359, 10.4150774],
                [105.5489776, 10.4153047],
                [105.549442, 10.4154481],
                [105.5502946, 10.4156952],
                [105.5511068, 10.4159424],
                [105.5516228, 10.416141],
                [105.55202, 10.4163639],
                [105.5524956, 10.4168162],
                [105.5527761, 10.4171825],
                [105.5528456, 10.4175312],
                [105.5528456, 10.4177849],
                [105.5527491, 10.4181579],
                [105.5525607, 10.4185374],
                [105.5522466, 10.4188375],
                [105.5519235, 10.4190449],
                [105.551569, 10.419184],
                [105.5512616, 10.4192943],
                [105.5507815, 10.4193737],
                [105.5503081, 10.4194179],
                [105.5497314, 10.4194929],
                [105.5492019, 10.4195723],
                [105.5484481, 10.4196782],
                [105.5471625, 10.4199033],
                [105.5467295, 10.4201505],
                [105.546523, 10.4204064],
                [105.5463974, 10.4206536],
                [105.5463952, 10.4208764],
                [105.5464535, 10.4210949],
                [105.546624, 10.4213575],
                [105.5467654, 10.4215428],
                [105.547075, 10.421876],
                [105.5476965, 10.4226285],
                [105.5481654, 10.423178],
                [105.5486702, 10.4237782],
                [105.5492101, 10.4243565],
                [105.5493602, 10.4245714],
                [105.5494226, 10.4248302],
                [105.5494137, 10.4250788],
                [105.5493567, 10.4253493],
                [105.549258, 10.4256229],
                [105.5491279, 10.4259936],
                [105.5488923, 10.4265055],
                [105.5486208, 10.4271719],
                [105.5484952, 10.4276882],
                [105.5483853, 10.4282112],
                [105.548353, 10.4286785],
                [105.5483647, 10.4289134],
                [105.5484218, 10.4291929],
                [105.5485221, 10.4294712],
                [105.5487263, 10.4296543],
                [105.5488946, 10.4297249],
                [105.5490763, 10.4298021],
                [105.5493904, 10.4298419],
                [105.5495609, 10.4298683],
                [105.5498944, 10.4298727],
                [105.5502789, 10.429811],
                [105.5505594, 10.4297054],
                [105.5508767, 10.4294963],
                [105.5511496, 10.4293777],
                [105.5514479, 10.4294433],
                [105.5514891, 10.4300175],
                [105.5515811, 10.4305729],
                [105.551625, 10.4308095],
                [105.5517205, 10.4311949],
                [105.551762, 10.4313874],
                [105.5519048, 10.4317556],
                [105.5521237, 10.432077],
                [105.5523585, 10.4323891],
                [105.5528202, 10.4326242],
                [105.5532568, 10.4329857],
                [105.5537097, 10.433281],
                [105.5541938, 10.4336275],
                [105.5546831, 10.4341697],
                [105.5548257, 10.4343101],
                [105.5571188, 10.4330318],
                [105.5595081, 10.431853],
                [105.5617968, 10.4308805],
                [105.5637901, 10.4301793],
                [105.5647566, 10.4299318],
                [105.5657508, 10.4296732],
                [105.5668904, 10.4294655],
                [105.567916, 10.4293632],
                [105.5696548, 10.4290813],
                [105.5717975, 10.4288504],
                [105.5741207, 10.4294508],
                [105.5741224, 10.4303518],
                [105.5740421, 10.4317171],
                [105.5741558, 10.4334916],
                [105.5741857, 10.4347202],
                [105.5744383, 10.436822],
                [105.574607, 10.4383779],
                [105.5751105, 10.4417898],
                [105.5755301, 10.4445467],
                [105.5756159, 10.44613],
                [105.5755902, 10.4471677],
                [105.575482, 10.4484237],
                [105.5750174, 10.4511276],
                [105.5746611, 10.4526845],
                [105.5743053, 10.4544598],
                [105.5740868, 10.455798],
                [105.573896, 10.4572182],
                [105.5737875, 10.4583105],
                [105.5736265, 10.4608772],
                [105.5734093, 10.4629253],
                [105.5732733, 10.4640723],
                [105.5731646, 10.465028],
                [105.5729461, 10.4664209],
                [105.5725909, 10.4685784],
                [105.5716333, 10.4736585],
                [105.5712221, 10.4754067],
                [105.5710309, 10.476581],
                [105.5709497, 10.4775094],
                [105.5707305, 10.4784928],
                [105.5706585, 10.4786838],
                [105.5704006, 10.479367],
                [105.5698776, 10.4804327],
                [105.5694918, 10.4813303],
                [105.5682378, 10.4836416],
                [105.5665368, 10.487197],
                [105.5646573, 10.4915076],
                [105.5634498, 10.4946403],
                [105.5622417, 10.4975732],
                [105.5615041, 10.4996412],
                [105.5609081, 10.5008751],
                [105.5602353, 10.5025725],
                [105.5590334, 10.5053459],
                [105.5577666, 10.5083691],
                [105.5567735, 10.5105101],
                [105.5560278, 10.5119448],
                [105.5545806, 10.5143369],
                [105.553128, 10.5165622],
                [105.5516466, 10.5188567],
                [105.5503469, 10.5210091],
                [105.5485643, 10.5232101],
                [105.5455764, 10.5263449],
                [105.542829, 10.5289775],
                [105.5402017, 10.531395],
                [105.5388398, 10.5325798],
                [105.5381526, 10.5330827],
                [105.5369084, 10.5339578],
                [105.5351024, 10.53525],
                [105.5324501, 10.5371896],
                [105.5309067, 10.5381837],
                [105.5284225, 10.5396213],
                [105.5252027, 10.5414662],
                [105.5233936, 10.542413],
                [105.5210537, 10.5436113],
                [105.5190997, 10.5445224],
                [105.517242, 10.5453616],
                [105.514914, 10.5463808],
                [105.5133296, 10.5470926],
                [105.5124941, 10.5473365],
                [105.5114972, 10.5475967],
                [105.5112036, 10.5468542],
                [105.510851, 10.5463642],
                [105.5105408, 10.5457842],
                [105.5101362, 10.5453035],
                [105.5099036, 10.5452008],
                [105.5097013, 10.5452273],
                [105.5092361, 10.5453798],
                [105.5084741, 10.5457411],
                [105.507611, 10.545993],
                [105.5068389, 10.5461587],
                [105.5060905, 10.5462581],
                [105.5057263, 10.5462581],
                [105.5053926, 10.5462913],
                [105.5049644, 10.5462184],
                [105.5046508, 10.546109],
                [105.5043845, 10.5460361],
                [105.5041042, 10.5459417],
                [105.5038233, 10.5458417],
                [105.503446, 10.5456817],
                [105.5030241, 10.5454589],
                [105.5027359, 10.5453167],
                [105.5024266, 10.5452074],
                [105.5017896, 10.5449984],
                [105.5012531, 10.5447662],
                [105.5004015, 10.5443422],
                [105.4998578, 10.5441775],
                [105.4992478, 10.5438938],
                [105.4988683, 10.5451333],
                [105.4978575, 10.5448969],
                [105.4962272, 10.5444547],
                [105.4951099, 10.5441969],
                [105.4883327, 10.5423892],
                [105.4879079, 10.5408766],
                [105.4870739, 10.5380927],
                [105.485536, 10.5338067],
                [105.4798628, 10.5356135],
                [105.4791208, 10.5358783],
                [105.4770284, 10.5367668],
                [105.4756207, 10.5373717],
                [105.4736614, 10.5381846],
                [105.4716636, 10.5387716],
                [105.4697612, 10.5394902],
                [105.4668891, 10.5409638],
                [105.4615829, 10.5440608],
                [105.4557248, 10.5472527],
                [105.4504559, 10.5499727],
                [105.4480489, 10.5516393],
                [105.446151, 10.5533907],
                [105.4437979, 10.5530525],
                [105.4419512, 10.5529421],
                [105.4392409, 10.5525954],
                [105.4318792, 10.5515813],
                [105.4238443, 10.5503868],
                [105.4215405, 10.5501263],
                [105.4191798, 10.5498658],
                [105.4181708, 10.5498294],
                [105.4169659, 10.5500111],
                [105.4156778, 10.550379],
                [105.4138323, 10.5510784],
                [105.4117928, 10.5518647],
                [105.409519, 10.553482],
                [105.4081337, 10.5545654],
                [105.4068717, 10.555985],
                [105.4056938, 10.5574398],
                [105.404811, 10.5584128],
                [105.4035671, 10.5598723],
                [105.399354, 10.5642507],
                [105.3974414, 10.5661309],
                [105.396037, 10.5675115],
                [105.3942053, 10.5685242],
                [105.3923958, 10.5693865],
                [105.3894154, 10.5705019],
                [105.3869615, 10.5711187],
                [105.3850195, 10.5714463],
                [105.3833272, 10.5716957],
                [105.3818084, 10.5717425],
                [105.3788659, 10.571611],
                [105.3773412, 10.5715847],
                [105.3754419, 10.5714795],
                [105.3737289, 10.5717863],
                [105.3714294, 10.5730573],
                [105.3685136, 10.5757658],
                [105.3673433, 10.5781521],
                [105.3672273, 10.5786566],
                [105.3666959, 10.5800135],
                [105.3639619, 10.5862326],
                [105.3631832, 10.5877405],
                [105.3600502, 10.59477],
                [105.359044, 10.597201],
                [105.3565188, 10.6030808],
                [105.3548162, 10.6065337],
                [105.3517397, 10.6123908],
                [105.349434, 10.6176115],
                [105.3476492, 10.6219458],
                [105.3466242, 10.6247535],
                [105.3457514, 10.6273727],
                [105.3451444, 10.6294077],
                [105.3446514, 10.6311977],
                [105.3437031, 10.6344762],
                [105.3430844, 10.6368791],
                [105.3429069, 10.6376416],
                [105.3425676, 10.6387505],
                [105.342138, 10.6400257],
                [105.341068, 10.6428651],
                [105.3404795, 10.645941],
                [105.3399068, 10.6493834],
                [105.3397305, 10.6517509],
                [105.3395433, 10.6546164],
                [105.3390885, 10.6573505],
                [105.338928, 10.6599793],
                [105.3388745, 10.6624241],
                [105.339035, 10.6647638],
                [105.338928, 10.6673137],
                [105.3388478, 10.6694956],
                [105.338714, 10.6717826],
                [105.339249, 10.6751211],
                [105.3402655, 10.6790642],
                [105.3418438, 10.6822974],
                [105.3444122, 10.6861035],
                [105.3462858, 10.6929003],
                [105.3466139, 10.6941906],
                [105.3477986, 10.7001394],
                [105.3481753, 10.7021254],
                [105.3484506, 10.7046502],
                [105.3487948, 10.7079494],
                [105.3494618, 10.7128853],
                [105.3494618, 10.7156218],
                [105.3485791, 10.7168522],
                [105.3474705, 10.7181365],
                [105.3455818, 10.7200594],
                [105.3423109, 10.7219756],
                [105.3419856, 10.7220599],
                [105.3393274, 10.7227488],
                [105.3339146, 10.7231522],
                [105.3293383, 10.722886],
                [105.3252862, 10.7222507],
                [105.3218128, 10.72148],
                [105.3184221, 10.7203687],
                [105.3139263, 10.7188693],
                [105.3128603, 10.7185667],
                [105.3114628, 10.7181701],
                [105.3086435, 10.717222],
                [105.3060774, 10.7165699],
                [105.3026491, 10.716153],
                [105.3007946, 10.7161597],
                [105.2989334, 10.7160656],
                [105.2970447, 10.7161799],
                [105.2944307, 10.7166438],
                [105.291365, 10.7171817],
                [105.2874372, 10.7185668],
                [105.2826882, 10.7205771],
                [105.2802099, 10.7218242],
                [105.2784549, 10.723134],
                [105.2768785, 10.72486],
                [105.2756184, 10.7268786],
                [105.2755854, 10.7279224],
                [105.2760298, 10.7300094],
                [105.2770873, 10.7321969],
                [105.2789622, 10.7346193],
                [105.2828319, 10.7374796],
                [105.2851585, 10.7389788],
                [105.2904823, 10.741742],
                [105.2981845, 10.745869],
                [105.2997646, 10.7468644],
                [105.3011324, 10.7479434],
                [105.3022129, 10.7491917],
                [105.3031144, 10.7505491],
                [105.3038808, 10.7526466],
                [105.3040956, 10.753801],
                [105.3042435, 10.7545962],
                [105.3043325, 10.755887],
                [105.3042093, 10.7576282],
                [105.3036208, 10.759161],
                [105.3026422, 10.7603576],
                [105.3004049, 10.7617693],
                [105.2986012, 10.7626801],
                [105.295231, 10.7633231],
                [105.2911597, 10.7634702],
                [105.2866165, 10.7635671],
                [105.2819516, 10.7635041],
                [105.2774601, 10.7635844],
                [105.2756536, 10.7638466],
                [105.273474, 10.7643536],
                [105.2715676, 10.764894],
                [105.2688788, 10.7661758],
                [105.2680276, 10.7666206],
                [105.267007, 10.7671538],
                [105.2655776, 10.7680979],
                [105.2644546, 10.7688058],
                [105.2627409, 10.7699977],
                [105.2616392, 10.7710262],
                [105.2606196, 10.7723304],
                [105.2595726, 10.7747437],
                [105.2588103, 10.7781032],
                [105.258099, 10.7824134],
                [105.2582588, 10.784101],
                [105.2587309, 10.7852639],
                [105.2597163, 10.7869041],
                [105.2608317, 10.7886989],
                [105.2613109, 10.79018],
                [105.2621026, 10.7920534],
                [105.262717, 10.7946171],
                [105.2624165, 10.7968287],
                [105.260894, 10.7998035],
                [105.2595967, 10.8023382],
                [105.2591415, 10.8026979],
                [105.2577256, 10.8032376],
                [105.2532651, 10.8040495],
                [105.2504136, 10.8043054],
                [105.2463798, 10.8043356],
                [105.2402082, 10.8043819],
                [105.2365816, 10.8049388],
                [105.2338112, 10.8057554],
                [105.2313642, 10.8068506],
                [105.2292441, 10.8081305],
                [105.2250282, 10.8147277],
                [105.2245418, 10.8157362],
                [105.222449, 10.8200756],
                [105.2202178, 10.8250579],
                [105.2187838, 10.828444],
                [105.2170476, 10.8325095],
                [105.2153812, 10.8366421],
                [105.2123915, 10.842827],
                [105.2096277, 10.8486492],
                [105.207905, 10.8520945],
                [105.205449, 10.8557529],
                [105.202474, 10.8588325],
                [105.1924626, 10.8660834],
                [105.1903727, 10.8678851],
                [105.1885504, 10.8698535],
                [105.1873634, 10.8720324],
                [105.1868389, 10.8744824],
                [105.1864282, 10.8772815],
                [105.1859618, 10.8805888],
                [105.1855756, 10.8828772],
                [105.1863888, 10.8957139],
                [105.1867515, 10.9011733],
                [105.1872347, 10.9115598],
                [105.1802714, 10.9118403],
                [105.1595284, 10.9219609],
                [105.1563853, 10.9234945],
                [105.1321066, 10.921615],
                [105.1291284, 10.9211014],
                [105.1088619, 10.9176057],
                [105.1087284, 10.9175899],
                [105.1085943, 10.9175812],
                [105.1084598, 10.9175794],
                [105.1083255, 10.9175846],
                [105.1081827, 10.9175978],
                [105.108041, 10.917619],
                [105.1079006, 10.917648],
                [105.1077622, 10.9176847],
                [105.1073316, 10.917803],
                [105.1069036, 10.9179303],
                [105.1064784, 10.9180664],
                [105.1060563, 10.9182114],
                [105.1056825, 10.9183481],
                [105.1053123, 10.9184939],
                [105.1049459, 10.9186486],
                [105.1045937, 10.9188074],
                [105.1042455, 10.9189746],
                [105.1039015, 10.9191499],
                [105.1035619, 10.9193333],
                [105.1023656, 10.9200602],
                [105.1016736, 10.9215614],
                [105.1012055, 10.922469],
                [105.101414, 10.9226773],
                [105.1016153, 10.9228924],
                [105.1018092, 10.9231139],
                [105.1019955, 10.9233417],
                [105.102174, 10.9235757],
                [105.1023445, 10.9238154],
                [105.1025068, 10.9240605],
                [105.1026607, 10.9243109],
                [105.1042617, 10.9272225],
                [105.1046531, 10.9279165],
                [105.1050718, 10.9285949],
                [105.1055172, 10.9292567],
                [105.1057463, 10.9295766],
                [105.1062231, 10.9302029],
                [105.1064706, 10.9305092],
                [105.1069834, 10.9311074],
                [105.1075414, 10.931729],
                [105.1078414, 10.9320525],
                [105.10843, 10.9326626],
                [105.1087072, 10.9329595],
                [105.1089799, 10.9332604],
                [105.1094917, 10.93385],
                [105.1111257, 10.9358616],
                [105.1116251, 10.9365097],
                [105.1118837, 10.9368268],
                [105.1124185, 10.937447],
                [105.113002, 10.9380744],
                [105.1133028, 10.9383798],
                [105.1139096, 10.9389763],
                [105.1142047, 10.939278],
                [105.1147789, 10.9398961],
                [105.1152658, 10.9404599],
                [105.1157258, 10.9410451],
                [105.1161577, 10.9416505],
                [105.1165608, 10.9422748],
                [105.1167512, 10.9425936],
                [105.1170832, 10.9431939],
                [105.1172239, 10.9434754],
                [105.1173561, 10.9437607],
                [105.1174798, 10.9440498],
                [105.1175947, 10.9443423],
                [105.1177007, 10.9446381],
                [105.1177979, 10.9449367],
                [105.1178861, 10.945238],
                [105.1179651, 10.9455418],
                [105.118092, 10.9461346],
                [105.1181816, 10.9467126],
                [105.1182388, 10.9472945],
                [105.1182634, 10.9478786],
                [105.1182634, 10.9481709],
                [105.1182389, 10.948755],
                [105.1177898, 10.9531128],
                [105.1177261, 10.9536468],
                [105.1176715, 10.9541818],
                [105.1176261, 10.9547176],
                [105.1175898, 10.9552541],
                [105.1175626, 10.9557962],
                [105.1175447, 10.9563388],
                [105.1175362, 10.9568815],
                [105.1175891, 10.9599523],
                [105.1175756, 10.9602162],
                [105.1175317, 10.9604769],
                [105.1174537, 10.9607437],
                [105.1173998, 10.9608792],
                [105.1173373, 10.9610112],
                [105.1172664, 10.961139],
                [105.1171873, 10.961262],
                [105.1171003, 10.9613799],
                [105.117006, 10.9614922],
                [105.1169045, 10.9615983],
                [105.1167964, 10.9616978],
                [105.116682, 10.9617904],
                [105.1165619, 10.9618756],
                [105.1164365, 10.9619532],
                [105.1163069, 10.9620225],
                [105.1161731, 10.9620836],
                [105.1160356, 10.9621362],
                [105.115895, 10.9621802],
                [105.1157518, 10.9622154],
                [105.1156067, 10.9622416],
                [105.1154602, 10.9622587],
                [105.1149929, 10.9622908],
                [105.1145246, 10.9622908],
                [105.1140574, 10.9622587],
                [105.113587, 10.9621935],
                [105.1131226, 10.9620958],
                [105.1128933, 10.9620348],
                [105.1120823, 10.9617266],
                [105.1116814, 10.9615612],
                [105.1108897, 10.961208],
                [105.1100996, 10.9608189],
                [105.1093255, 10.9604001],
                [105.1089448, 10.9601798],
                [105.1081967, 10.9597176],
                [105.1054274, 10.9578571],
                [105.1050775, 10.9576366],
                [105.1043639, 10.9572174],
                [105.1040005, 10.957019],
                [105.1032614, 10.956645],
                [105.1025068, 10.9563019],
                [105.1021242, 10.9561422],
                [105.1013899, 10.9558613],
                [105.1006447, 10.9556095],
                [105.0998899, 10.9553871],
                [105.0994543, 10.9552761],
                [105.0985772, 10.9550784],
                [105.097693, 10.9549134],
                [105.0972487, 10.9548432],
                [105.0967758, 10.9547777],
                [105.0963017, 10.9547216],
                [105.0958266, 10.9546748],
                [105.0953506, 10.9546373],
                [105.0948739, 10.9546092],
                [105.0943968, 10.9545905],
                [105.0933574, 10.9545887],
                [105.0928378, 10.9546012],
                [105.0917997, 10.9546532],
                [105.0907642, 10.9547409],
                [105.0897324, 10.9548644],
                [105.0887626, 10.9550136],
                [105.0877984, 10.9551943],
                [105.0868409, 10.9554063],
                [105.0858679, 10.9556548],
                [105.0853731, 10.9557931],
                [105.0843903, 10.9560923],
                [105.0834175, 10.9564213],
                [105.0824555, 10.9567798],
                [105.0821042, 10.9549012],
                [105.082078, 10.9547898],
                [105.0820428, 10.9546807],
                [105.0819988, 10.9545748],
                [105.0819463, 10.9544727],
                [105.0818857, 10.954375],
                [105.0817451, 10.9541994],
                [105.0815825, 10.9540508],
                [105.081493, 10.953986],
                [105.0808765, 10.9535977],
                [105.0804397, 10.9533553],
                [105.0801123, 10.9531617],
                [105.0798592, 10.9529936],
                [105.0796845, 10.9528485],
                [105.0795238, 10.9526763],
                [105.0794496, 10.9525793],
                [105.0793219, 10.9523718],
                [105.0792689, 10.9522622],
                [105.0791602, 10.9519756],
                [105.0791058, 10.951799],
                [105.0790605, 10.9516199],
                [105.0790243, 10.9514388],
                [105.0789973, 10.9512561],
                [105.0789797, 10.9510724],
                [105.0789714, 10.950888],
                [105.0789724, 10.9507054],
                [105.0789826, 10.9505231],
                [105.079002, 10.9503415],
                [105.0790304, 10.950161],
                [105.0790679, 10.9499821],
                [105.0795159, 10.9485127],
                [105.0796035, 10.9481896],
                [105.0796657, 10.9478352],
                [105.0796852, 10.9476437],
                [105.0796949, 10.9474514],
                [105.079662, 10.9469261],
                [105.0796366, 10.9466641],
                [105.0795706, 10.9461594],
                [105.0795293, 10.9459083],
                [105.0793167, 10.9450915],
                [105.079198, 10.9446864],
                [105.0788369, 10.9435742],
                [105.0785561, 10.9427567],
                [105.078472, 10.94249],
                [105.0783283, 10.94195],
                [105.0782688, 10.941677],
                [105.0781866, 10.9412076],
                [105.0781646, 10.9410115],
                [105.0781519, 10.9408147],
                [105.0781484, 10.9406175],
                [105.0781542, 10.9404203],
                [105.0781693, 10.9402236],
                [105.0781935, 10.9400278],
                [105.0782273, 10.9398315],
                [105.0783043, 10.939512],
                [105.078356, 10.9393477],
                [105.0784168, 10.9391864],
                [105.0785583, 10.9388875],
                [105.0790353, 10.9380363],
                [105.0791617, 10.9377858],
                [105.079256, 10.937522],
                [105.0792906, 10.9373863],
                [105.0793343, 10.9371101],
                [105.0793434, 10.9368306],
                [105.0793349, 10.936691],
                [105.0792919, 10.9364147],
                [105.0792556, 10.9362719],
                [105.07921, 10.9361318],
                [105.0791553, 10.9359948],
                [105.0790918, 10.9358616],
                [105.0790197, 10.9357326],
                [105.078815, 10.9354434],
                [105.0785854, 10.9351729],
                [105.0784618, 10.9350453],
                [105.0783324, 10.9349295],
                [105.0782613, 10.9348794],
                [105.0781082, 10.9347961],
                [105.0780272, 10.9347635],
                [105.0778408, 10.9347138],
                [105.0777451, 10.9347009],
                [105.0776485, 10.9346961],
                [105.0774559, 10.9347108],
                [105.0769768, 10.9348219],
                [105.0764874, 10.9349646],
                [105.0759901, 10.9351414],
                [105.0762999, 10.9366043],
                [105.0723839, 10.9382528],
                [105.0721944, 10.9383213],
                [105.0720022, 10.9383822],
                [105.0718077, 10.9384355],
                [105.0716112, 10.938481],
                [105.0714129, 10.9385188],
                [105.0712303, 10.9385465],
                [105.0710468, 10.9385676],
                [105.0708626, 10.9385821],
                [105.070678, 10.9385899],
                [105.0698251, 10.9385899],
                [105.0693718, 10.9385803],
                [105.0689185, 10.9385768],
                [105.0684625, 10.9385794],
                [105.0683806, 10.9385793],
                [105.0682991, 10.9385722],
                [105.0682184, 10.9385583],
                [105.0681469, 10.93854],
                [105.068077, 10.9385162],
                [105.0680092, 10.9384872],
                [105.0678349, 10.9383951],
                [105.0675023, 10.938266],
                [105.0672529, 10.9381687],
                [105.0670069, 10.9380633],
                [105.0667647, 10.93795],
                [105.0665834, 10.9378587],
                [105.0664045, 10.937763],
                [105.0662282, 10.9376629],
                [105.0654155, 10.9371784],
                [105.0640207, 10.9365305],
                [105.0636356, 10.9363595],
                [105.0632483, 10.9361935],
                [105.0628995, 10.936049],
                [105.062549, 10.9359085],
                [105.0621968, 10.9357721],
                [105.0606465, 10.9349873],
                [105.0604799, 10.9348019],
                [105.0603082, 10.934621],
                [105.0601315, 10.9344448],
                [105.0599639, 10.9342861],
                [105.0597922, 10.9341316],
                [105.0596166, 10.9339813],
                [105.0582218, 10.9328173],
                [105.0580584, 10.9326872],
                [105.0578921, 10.9325608],
                [105.0577229, 10.9324381],
                [105.0575367, 10.9323094],
                [105.0573474, 10.9321853],
                [105.0548181, 10.9307105],
                [105.0545525, 10.9311318],
                [105.0531122, 10.93006],
                [105.0514492, 10.9281217],
                [105.0513527, 10.925625],
                [105.0509343, 10.9256171],
                [105.0507867, 10.9251457],
                [105.0507537, 10.92503],
                [105.0507295, 10.9249121],
                [105.0507143, 10.9247928],
                [105.050709, 10.9247095],
                [105.0507082, 10.924626],
                [105.0507116, 10.9245426],
                [105.0507438, 10.9242529],
                [105.0516289, 10.9243109],
                [105.0516826, 10.9237473],
                [105.0528788, 10.9211663],
                [105.0527501, 10.9196599],
                [105.0527374, 10.9193071],
                [105.052734, 10.9189541],
                [105.0527373, 10.918702],
                [105.0527454, 10.91845],
                [105.0527582, 10.9181982],
                [105.0527997, 10.9176004],
                [105.0461197, 10.9154302],
                [105.0457632, 10.9152946],
                [105.0455887, 10.9152178],
                [105.0452156, 10.9150291],
                [105.0450187, 10.9149149],
                [105.0448269, 10.9147929],
                [105.0441241, 10.9143188],
                [105.0427455, 10.9120538],
                [105.0420749, 10.9115956],
                [105.0419258, 10.9114678],
                [105.0417982, 10.9113193],
                [105.0416951, 10.9111534],
                [105.0416189, 10.910974],
                [105.0415708, 10.9107816],
                [105.0415537, 10.9105842],
                [105.0415679, 10.9103866],
                [105.0416213, 10.910143],
                [105.0417099, 10.9098556],
                [105.0417638, 10.9097151],
                [105.0418842, 10.9094529],
                [105.0420266, 10.9092015],
                [105.0428587, 10.9084503],
                [105.0430056, 10.9082823],
                [105.0430715, 10.9081912],
                [105.0431853, 10.9079979],
                [105.0431907, 10.9075133],
                [105.0436789, 10.9062596],
                [105.0438651, 10.9058456],
                [105.0440652, 10.9054681],
                [105.044167, 10.9052957],
                [105.0444782, 10.9048427],
                [105.0444894, 10.9045934],
                [105.0444835, 10.9044687],
                [105.0444681, 10.904331],
                [105.0444296, 10.9041025],
                [105.0444227, 10.9039164],
                [105.0444433, 10.9037312],
                [105.0445001, 10.903525],
                [105.0445908, 10.9033309],
                [105.0447145, 10.903123],
                [105.0448451, 10.9029193],
                [105.0450422, 10.9026373],
                [105.0451755, 10.9024828],
                [105.0452663, 10.9023954],
                [105.0454652, 10.9022405],
                [105.0454625, 10.9020271],
                [105.0453517, 10.9020139],
                [105.0452423, 10.9019917],
                [105.0451352, 10.9019606],
                [105.0450311, 10.9019208],
                [105.0449308, 10.9018727],
                [105.0447567, 10.901762],
                [105.0446131, 10.9016375],
                [105.0445485, 10.9015679],
                [105.0444353, 10.9014161],
                [105.0442141, 10.901047],
                [105.0441701, 10.9009913],
                [105.044065, 10.9009024],
                [105.0439444, 10.9008446],
                [105.0437352, 10.9007787],
                [105.0434214, 10.9006549],
                [105.0422734, 10.899978],
                [105.0420634, 10.8998378],
                [105.0419644, 10.8997596],
                [105.0417799, 10.8995882],
                [105.0416169, 10.8994007],
                [105.0415432, 10.8993009],
                [105.041475, 10.8991974],
                [105.0410408, 10.8984518],
                [105.040908, 10.8982764],
                [105.0407538, 10.8981188],
                [105.0406694, 10.8980474],
                [105.0405657, 10.8979711],
                [105.0404565, 10.8979023],
                [105.0403424, 10.8978416],
                [105.0402242, 10.8977893],
                [105.0400513, 10.8977438],
                [105.0399624, 10.8977334],
                [105.0397803, 10.8977381],
                [105.0396889, 10.8977538],
                [105.0395995, 10.8977783],
                [105.039513, 10.8978113],
                [105.0394303, 10.8978525],
                [105.0375715, 10.8971572],
                [105.0372809, 10.8967071],
                [105.0368607, 10.8960562],
                [105.0284761, 10.892278],
                [105.0288561, 10.8908453],
                [105.0291711, 10.8894256],
                [105.0290933, 10.8893075],
                [105.0292594, 10.8888106],
                [105.0295281, 10.8886147],
                [105.0295775, 10.8884042],
                [105.0295844, 10.887785],
                [105.02961, 10.8876539],
                [105.0297192, 10.8866339],
                [105.0300164, 10.885547],
                [105.0304939, 10.8853094],
                [105.0307403, 10.8848408],
                [105.0306942, 10.8847078],
                [105.0308175, 10.8838803],
                [105.0311169, 10.8828147],
                [105.0312972, 10.8826847],
                [105.0313886, 10.8822447],
                [105.0312394, 10.8820597],
                [105.0315593, 10.880661],
                [105.0318175, 10.8807444],
                [105.0321212, 10.8798865],
                [105.0322319, 10.8795736],
                [105.0322803, 10.8787906],
                [105.0321806, 10.8778753],
                [105.0323675, 10.878015],
                [105.0329494, 10.8763733],
                [105.0331472, 10.8745922],
                [105.0330856, 10.8745728],
                [105.0331444, 10.8734792],
                [105.0329275, 10.8728253],
                [105.0331019, 10.8719939],
                [105.0333215, 10.8714675],
                [105.0336681, 10.8706653],
                [105.0341661, 10.8704253],
                [105.0343289, 10.8695775],
                [105.033965, 10.8695489],
                [105.0340219, 10.8682672],
                [105.0344489, 10.8683358],
                [105.0346369, 10.8672358],
                [105.0349036, 10.8666444],
                [105.0351897, 10.8662725],
                [105.0359161, 10.8655117],
                [105.0363994, 10.864422],
                [105.0368442, 10.8636633],
                [105.0375169, 10.8623583],
                [105.0376158, 10.8623125],
                [105.0376742, 10.8619533],
                [105.0386636, 10.8604719],
                [105.0389461, 10.8595488],
                [105.0396219, 10.8582194],
                [105.0397997, 10.8578303],
                [105.0400253, 10.8565664],
                [105.0398278, 10.8565081],
                [105.0401481, 10.8555936],
                [105.0402886, 10.8556836],
                [105.0403415, 10.8549139],
                [105.0407258, 10.8544342],
                [105.0407711, 10.8544308],
                [105.0410581, 10.8540803],
                [105.0412467, 10.8536883],
                [105.0417303, 10.85279],
                [105.0418544, 10.8520006],
                [105.0419544, 10.8517003],
                [105.0420592, 10.8516417],
                [105.0422332, 10.8511404],
                [105.0429581, 10.8489367],
                [105.0431461, 10.8483989],
                [105.0432039, 10.8484183],
                [105.0434703, 10.8470661],
                [105.0440276, 10.8471956],
                [105.0440519, 10.8471017],
                [105.0440536, 10.8463183],
                [105.0441558, 10.8453822],
                [105.0446181, 10.8455592],
                [105.0449389, 10.8445792],
                [105.0453394, 10.844585],
                [105.045598, 10.8443993],
                [105.0462908, 10.8431756],
                [105.0464042, 10.8421792],
                [105.0469075, 10.8421267],
                [105.0485069, 10.8409253],
                [105.0490231, 10.8400458],
                [105.0491556, 10.8396231],
                [105.0497069, 10.8397206],
                [105.0499023, 10.8391352],
                [105.0497697, 10.8389733],
                [105.0502215, 10.8380295],
                [105.051173, 10.8360418],
                [105.0534947, 10.8311789],
                [105.0536212, 10.8309961],
                [105.0546158, 10.8290719],
                [105.0555458, 10.8281078],
                [105.0557039, 10.8279908],
                [105.0558314, 10.8273358],
                [105.0557531, 10.8272328],
                [105.0559522, 10.8267308],
                [105.0560772, 10.8266922],
                [105.0562, 10.8263908],
                [105.0566908, 10.8246217],
                [105.0579508, 10.8217319],
                [105.0580408, 10.8216248],
                [105.05833, 10.8208911],
                [105.0589337, 10.8198557],
                [105.0586788, 10.8157657],
                [105.0584269, 10.8116653],
                [105.0580823, 10.8060215],
                [105.0590642, 10.8041871],
                [105.0601889, 10.80209],
                [105.0613753, 10.7998759],
                [105.0621403, 10.7984383],
                [105.0627333, 10.7973239],
                [105.0625009, 10.7972207],
                [105.0627522, 10.7951597],
                [105.0627075, 10.7950758],
                [105.0628675, 10.7926394],
                [105.0633208, 10.7892994],
                [105.0634486, 10.7892206],
                [105.0633644, 10.7890022],
                [105.0637019, 10.7848569],
                [105.0641608, 10.7804922],
                [105.0651164, 10.7805856],
                [105.0654264, 10.7806219],
                [105.0656444, 10.7805922],
                [105.0664361, 10.7804657],
                [105.0697897, 10.7796864],
                [105.072385, 10.7791859],
                [105.0728184, 10.7790876],
                [105.0730992, 10.7790121],
                [105.0733773, 10.7789277],
                [105.0736526, 10.7788347],
                [105.0738028, 10.7787622],
                [105.0738989, 10.7786914],
                [105.0739305, 10.7786569],
                [105.0740308, 10.7785053],
                [105.0740614, 10.7784739],
                [105.0741306, 10.7784285],
                [105.0742051, 10.7784052],
                [105.0747206, 10.778352],
                [105.0748063, 10.7783117],
                [105.0749318, 10.7782141],
                [105.0749909, 10.7782024],
                [105.0750256, 10.7782067],
                [105.0750835, 10.7782181],
                [105.0751171, 10.7782128],
                [105.0751371, 10.7781939],
                [105.0751712, 10.7781194],
                [105.0752079, 10.7780997],
                [105.0752304, 10.7780989],
                [105.075687, 10.7781694],
                [105.075805, 10.7782036],
                [105.0758906, 10.7782544],
                [105.076021, 10.7783867],
                [105.0760542, 10.7784051],
                [105.0761088, 10.7784003],
                [105.0762358, 10.7783108],
                [105.0763358, 10.7781819],
                [105.0799893, 10.7731571],
                [105.0840119, 10.7676658],
                [105.0882237, 10.7618734],
                [105.0889911, 10.7608424],
                [105.0903332, 10.7590394],
                [105.0924461, 10.7561343],
                [105.0929983, 10.7529464],
                [105.0935767, 10.7495239],
                [105.094209, 10.7457995],
                [105.0947299, 10.7426602],
                [105.0952533, 10.7396047],
                [105.0959112, 10.7357959],
                [105.0961191, 10.7345253],
                [105.096593, 10.731706],
                [105.0970994, 10.7287389],
                [105.09769, 10.7252667],
                [105.0982893, 10.7216543],
                [105.0943729, 10.7193707],
                [105.0886584, 10.7160523],
                [105.0832792, 10.7129414],
                [105.0788736, 10.7103881],
                [105.0784278, 10.7101318],
                [105.0747028, 10.7079911],
                [105.0703925, 10.7055161],
                [105.0661003, 10.70305],
                [105.0617514, 10.7005519],
                [105.0574617, 10.6980875],
                [105.0529175, 10.6954783],
                [105.0483767, 10.6928678],
                [105.0439294, 10.6903139],
                [105.0389267, 10.6874514],
                [105.033995, 10.6846278],
                [105.0290656, 10.6818061],
                [105.0240258, 10.6789242],
                [105.0194616, 10.676311],
                [105.0192139, 10.6761692],
                [105.0194933, 10.6750164],
                [105.0186469, 10.6745494],
                [105.0177717, 10.6740792],
                [105.0161869, 10.6732117],
                [105.0126806, 10.6712847],
                [105.0096989, 10.6695911],
                [105.0068217, 10.6679622],
                [105.0040986, 10.6664006],
                [105.0041119, 10.6663553],
                [105.0021939, 10.6651586],
                [105.0012836, 10.6646533],
                [104.9980369, 10.6627181],
                [104.9978758, 10.6629689],
                [104.9977181, 10.6628714],
                [104.9954331, 10.6614792],
                [104.9936094, 10.6604186],
                [104.9935781, 10.6605217],
                [104.9931217, 10.6602522],
                [104.9931575, 10.6601497],
                [104.9928425, 10.6599608],
                [104.9914767, 10.659175],
                [104.9914361, 10.6592758],
                [104.9900253, 10.6584505],
                [104.9897264, 10.6582619],
                [104.9890347, 10.6577653],
                [104.9884058, 10.6574367],
                [104.9882611, 10.6574308],
                [104.9856494, 10.6559122],
                [104.9839503, 10.6549183],
                [104.9834758, 10.6546581],
                [104.9834481, 10.6545714],
                [104.9826544, 10.653915],
                [104.9826656, 10.6539006],
                [104.9781633, 10.6502311],
                [104.9778212, 10.6500852],
                [104.9743489, 10.6486044],
                [104.9738767, 10.6484443],
                [104.9704736, 10.6470261],
                [104.9637914, 10.6442142],
                [104.9641497, 10.6434306],
                [104.9633875, 10.6430033],
                [104.9633906, 10.6428486],
                [104.9631897, 10.6427792],
                [104.9615983, 10.6417303],
                [104.9615647, 10.6417397],
                [104.9572729, 10.6389274],
                [104.9538447, 10.6367211],
                [104.9533, 10.6361725],
                [104.9531153, 10.6357178],
                [104.9530058, 10.6351969],
                [104.9523594, 10.6347664],
                [104.9523061, 10.63469],
                [104.9502364, 10.6321044],
                [104.9453983, 10.6260644],
                [104.9433081, 10.6234544],
                [104.9390078, 10.61838],
                [104.9368783, 10.6158003],
                [104.9351453, 10.6138547],
                [104.9330792, 10.6101086],
                [104.9310344, 10.6063994],
                [104.9289707, 10.602706],
                [104.9276633, 10.5997656],
                [104.92601, 10.59616],
                [104.9230017, 10.5895956],
                [104.9225967, 10.588835],
                [104.9218383, 10.5874944],
                [104.9210276, 10.5856981],
                [104.9206383, 10.5848356],
                [104.9188014, 10.5806792],
                [104.9189019, 10.580655],
                [104.9185911, 10.5800417],
                [104.9181197, 10.5791822],
                [104.9175125, 10.5782017],
                [104.9169586, 10.5772492],
                [104.9159631, 10.5755986],
                [104.9153915, 10.5746453],
                [104.9147239, 10.5736075],
                [104.9138408, 10.5723553],
                [104.9130325, 10.5711531],
                [104.9122572, 10.5700081],
                [104.9121822, 10.5700039],
                [104.9118539, 10.5696419],
                [104.9112383, 10.5687169],
                [104.9105867, 10.5677644],
                [104.9095142, 10.5661483],
                [104.9087856, 10.5650747],
                [104.9078206, 10.563635],
                [104.9067461, 10.5618189],
                [104.9062764, 10.5610483],
                [104.9059417, 10.5604575],
                [104.9053514, 10.5595311],
                [104.9050833, 10.5590847],
                [104.9048272, 10.5584839],
                [104.9048556, 10.5583769],
                [104.9048069, 10.5582589],
                [104.904735, 10.5582175],
                [104.9046367, 10.558185],
                [104.9038308, 10.5568897],
                [104.9036111, 10.5564628],
                [104.9031972, 10.5557106],
                [104.9031306, 10.5555356],
                [104.9028392, 10.55508],
                [104.9025664, 10.5546617],
                [104.9018761, 10.5534481],
                [104.9011886, 10.5522017],
                [104.900935, 10.5517678],
                [104.9003336, 10.5508317],
                [104.8998042, 10.5500436],
                [104.8991497, 10.5493767],
                [104.8985234, 10.5487766],
                [104.8968611, 10.5476456],
                [104.8959064, 10.5469389],
                [104.8940364, 10.5451936],
                [104.8931661, 10.5443592],
                [104.8931314, 10.5443908],
                [104.8913472, 10.5427117],
                [104.8913686, 10.5426767],
                [104.8893686, 10.5409225],
                [104.8866725, 10.5385286],
                [104.8866119, 10.5385975],
                [104.8859864, 10.5379828],
                [104.8847497, 10.5367639],
                [104.8827206, 10.534795],
                [104.8790275, 10.5314317],
                [104.8759783, 10.5285644],
                [104.8733697, 10.5261625],
                [104.8723286, 10.5252117],
                [104.8712964, 10.5242775],
                [104.8701219, 10.52314],
                [104.868515, 10.5216928],
                [104.8682608, 10.5215856],
                [104.8664975, 10.5211725],
                [104.8645286, 10.5211267],
                [104.8638106, 10.5211203],
                [104.8626975, 10.5210669],
                [104.8624278, 10.5210806],
                [104.8616103, 10.5211228],
                [104.8615925, 10.5208967],
                [104.8614356, 10.5209261],
                [104.8594508, 10.5207036],
                [104.8589011, 10.5206919],
                [104.8585122, 10.5206789],
                [104.8568969, 10.5204511],
                [104.8563631, 10.5203581],
                [104.8558475, 10.5202944],
                [104.8546864, 10.5201664],
                [104.8537464, 10.5201686],
                [104.8525367, 10.5200769],
                [104.8525022, 10.5204814],
                [104.8506289, 10.5204764],
                [104.849285, 10.5204378],
                [104.84793, 10.5203222],
                [104.8479267, 10.5204478],
                [104.8465186, 10.5204128],
                [104.8465158, 10.5204475],
                [104.8461919, 10.5204478],
                [104.8455064, 10.5203633],
                [104.8442889, 10.5202139],
                [104.8442883, 10.52024],
                [104.8432108, 10.5201294],
                [104.8418047, 10.5200853],
                [104.8418072, 10.5201975],
                [104.8410767, 10.5202081],
                [104.8390356, 10.5201404],
                [104.8390575, 10.5202283],
                [104.8390553, 10.5203075],
                [104.8382308, 10.5202558],
                [104.8382378, 10.5202294],
                [104.8378617, 10.5201883],
                [104.8378583, 10.5202153],
                [104.8363683, 10.5200978],
                [104.8337814, 10.5198422],
                [104.8325419, 10.5197117],
                [104.8313708, 10.5196281],
                [104.8293831, 10.5196231],
                [104.8293753, 10.5198775],
                [104.8283456, 10.5197836],
                [104.8237125, 10.5205578],
                [104.8207444, 10.5204822],
                [104.8177761, 10.5204072],
                [104.8148078, 10.5203311],
                [104.8118369, 10.5202558],
                [104.8107311, 10.5202283],
                [104.80866, 10.5202128],
                [104.8053514, 10.5202342],
                [104.8053483, 10.5203333],
                [104.8028803, 10.5202467],
                [104.8022197, 10.5202044],
                [104.80218, 10.5203156],
                [104.8016183, 10.5202336],
                [104.8016128, 10.5202806],
                [104.801055, 10.5202525],
                [104.79968, 10.5202578],
                [104.7995169, 10.5203558],
                [104.7992094, 10.5203258],
                [104.7985622, 10.5202442],
                [104.7977875, 10.5201914],
                [104.7965961, 10.5201133],
                [104.7950706, 10.5200175],
                [104.7944869, 10.5199428],
                [104.7927714, 10.5197136],
                [104.7922625, 10.5196528],
                [104.7916664, 10.5196522],
                [104.7916417, 10.5195869],
                [104.7914525, 10.5195522],
                [104.7914122, 10.5196647],
                [104.7911206, 10.519665],
                [104.7909403, 10.5196167],
                [104.7907511, 10.5195075],
                [104.7906467, 10.5198011],
                [104.7902706, 10.5197303],
                [104.7902375, 10.5196622],
                [104.7889239, 10.5197069],
                [104.7887733, 10.5196672],
                [104.7886383, 10.5197131],
                [104.7883669, 10.5197181],
                [104.7883203, 10.5199003],
                [104.7874322, 10.519855],
                [104.7856822, 10.5196617],
                [104.7841094, 10.5195753],
                [104.7832408, 10.5195517],
                [104.7812469, 10.5195736],
                [104.7812161, 10.5196092],
                [104.7805658, 10.5195583],
                [104.7800789, 10.5195522],
                [104.7784833, 10.5195006],
                [104.7784783, 10.516607],
                [104.7784892, 10.5163552],
                [104.7787019, 10.5145067],
                [104.7789194, 10.512879],
                [104.7789956, 10.5117695],
                [104.7791429, 10.5099687],
                [104.7794634, 10.5098782],
                [104.7798906, 10.509779],
                [104.7798945, 10.509746],
                [104.7801969, 10.5096662],
                [104.7803495, 10.5096468],
                [104.7804281, 10.5096451],
                [104.7805848, 10.5096585],
                [104.7804563, 10.5093279],
                [104.7804583, 10.5091353],
                [104.7814334, 10.5091295],
                [104.7814136, 10.5071011],
                [104.7815071, 10.507001],
                [104.7823616, 10.5063325],
                [104.7829132, 10.5033884],
                [104.7825219, 10.502098],
                [104.7815325, 10.4989926],
                [104.7784534, 10.4872499],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.9769387, 8.702505],
                [106.0553036, 8.3456879],
                [106.4217647, 8.3979987],
                [106.6531936, 8.4310306],
                [106.699124, 8.4430979],
                [106.7539877, 8.4648655],
                [106.7998305, 8.4860938],
                [108.4311207, 9.3780225],
                [107.7731742, 10.31234],
                [107.5704034, 10.5674135],
                [107.5702286, 10.5684161],
                [107.5688124, 10.5781243],
                [107.569039, 10.5785804],
                [107.5692293, 10.5791125],
                [107.5692818, 10.5791987],
                [107.5693224, 10.5792445],
                [107.5694977, 10.5793793],
                [107.5695519, 10.5794494],
                [107.5695855, 10.5795309],
                [107.5695939, 10.5795756],
                [107.5695919, 10.5796661],
                [107.5695015, 10.5800556],
                [107.569467, 10.5801059],
                [107.5693799, 10.5801665],
                [107.5693547, 10.5801944],
                [107.5693347, 10.5802264],
                [107.5693148, 10.5802871],
                [107.5692415, 10.5808809],
                [107.5691983, 10.581417],
                [107.5691635, 10.5814782],
                [107.5691362, 10.5815012],
                [107.569071, 10.5815255],
                [107.5690358, 10.5815262],
                [107.5687998, 10.581472],
                [107.5687184, 10.5814821],
                [107.5686461, 10.5815203],
                [107.5686142, 10.5815509],
                [107.5685718, 10.5816276],
                [107.5685216, 10.5819418],
                [107.5685099, 10.5819782],
                [107.5684723, 10.5820377],
                [107.5683607, 10.582123],
                [107.5683355, 10.5821515],
                [107.5682981, 10.582224],
                [107.5682887, 10.582267],
                [107.5682662, 10.5825332],
                [107.5682558, 10.5827634],
                [107.5682746, 10.5830824],
                [107.5682936, 10.5832483],
                [107.5683301, 10.5834682],
                [107.5683325, 10.583524],
                [107.5683108, 10.5836335],
                [107.568235, 10.5837686],
                [107.5682216, 10.5838049],
                [107.5681093, 10.5844695],
                [107.5681088, 10.5845124],
                [107.5681298, 10.5846014],
                [107.5681525, 10.5846446],
                [107.5682813, 10.5847936],
                [107.5683063, 10.584843],
                [107.5683228, 10.5848958],
                [107.5683292, 10.5850056],
                [107.5683188, 10.5850599],
                [107.5682994, 10.5851124],
                [107.5682716, 10.5851611],
                [107.5682361, 10.5852047],
                [107.5681938, 10.5852421],
                [107.5681458, 10.5852721],
                [107.5678468, 10.585433],
                [107.5703023, 10.5927177],
                [107.5710171, 10.5961003],
                [107.5719469, 10.600185],
                [107.5719225, 10.6002492],
                [107.5718605, 10.6003719],
                [107.5717936, 10.6004702],
                [107.5716716, 10.6006277],
                [107.5712585, 10.6010726],
                [107.5705417, 10.6017838],
                [107.5704331, 10.601911],
                [107.5703019, 10.6021001],
                [107.5702199, 10.6021917],
                [107.5701716, 10.60223],
                [107.5700727, 10.6022855],
                [107.5699644, 10.6023197],
                [107.569768, 10.602343],
                [107.5696987, 10.6023574],
                [107.5695647, 10.6024027],
                [107.5694868, 10.6024411],
                [107.5693448, 10.6025398],
                [107.568978, 10.6028387],
                [107.5688808, 10.6028878],
                [107.5688323, 10.6029028],
                [107.5687319, 10.6029168],
                [107.5680325, 10.6029122],
                [107.5680828, 10.6034191],
                [107.5680827, 10.60348],
                [107.5680687, 10.603601],
                [107.5680524, 10.6036695],
                [107.5680023, 10.6038013],
                [107.5678006, 10.6041459],
                [107.5677026, 10.6042561],
                [107.5676479, 10.6043018],
                [107.5675263, 10.6043766],
                [107.5669253, 10.6046175],
                [107.5667618, 10.6046964],
                [107.5666562, 10.6047588],
                [107.5665553, 10.6048282],
                [107.5661194, 10.6051815],
                [107.5660542, 10.6052468],
                [107.5659437, 10.6053937],
                [107.5658727, 10.6055336],
                [107.5655957, 10.6063428],
                [107.56553, 10.6064694],
                [107.5654502, 10.6065926],
                [107.5650705, 10.607084],
                [107.5645726, 10.6077726],
                [107.5645253, 10.6078531],
                [107.5644524, 10.6080119],
                [107.5644256, 10.6080888],
                [107.5644048, 10.6081815],
                [107.564379, 10.6083752],
                [107.5643764, 10.6087427],
                [107.5643949, 10.6090355],
                [107.5645544, 10.6100944],
                [107.5646347, 10.6107615],
                [107.5646509, 10.6108266],
                [107.5646979, 10.6109459],
                [107.5648313, 10.6111977],
                [107.564846, 10.6112333],
                [107.5648621, 10.6113084],
                [107.564856, 10.611407],
                [107.5647244, 10.6118677],
                [107.5647103, 10.6120164],
                [107.5647233, 10.6121652],
                [107.5647422, 10.6122464],
                [107.5648038, 10.6124012],
                [107.5650151, 10.6127095],
                [107.5650464, 10.6127662],
                [107.5650906, 10.6128865],
                [107.5651096, 10.6130121],
                [107.5651044, 10.6136908],
                [107.5650985, 10.6137358],
                [107.5650693, 10.6138215],
                [107.5650454, 10.6138624],
                [107.5649818, 10.613933],
                [107.5646915, 10.6141243],
                [107.5645446, 10.6142331],
                [107.5644236, 10.6143389],
                [107.5640944, 10.6146546],
                [107.5637652, 10.6148705],
                [107.56368, 10.614936],
                [107.5636017, 10.615007],
                [107.563464, 10.6151667],
                [107.5658405, 10.6157783],
                [107.5660738, 10.6159233],
                [107.5662631, 10.615863],
                [107.5663608, 10.6158442],
                [107.5665251, 10.6158301],
                [107.5666582, 10.6158349],
                [107.5667077, 10.6158463],
                [107.5667548, 10.615865],
                [107.5668266, 10.6159122],
                [107.5669154, 10.6160101],
                [107.5669521, 10.616039],
                [107.5670367, 10.6160788],
                [107.567089, 10.6160897],
                [107.5671423, 10.6160915],
                [107.5671951, 10.6160843],
                [107.567246, 10.6160683],
                [107.5673747, 10.6159787],
                [107.5674759, 10.6158705],
                [107.5675175, 10.6158094],
                [107.5675805, 10.6156763],
                [107.5676014, 10.6156056],
                [107.5676276, 10.6158429],
                [107.5676188, 10.6160815],
                [107.5675858, 10.6162739],
                [107.5675158, 10.6164964],
                [107.5675086, 10.6165336],
                [107.5675155, 10.6166087],
                [107.5675289, 10.6166431],
                [107.5675732, 10.616702],
                [107.5679071, 10.616991],
                [107.5679972, 10.6170878],
                [107.5680671, 10.6171996],
                [107.5681151, 10.6173262],
                [107.5681298, 10.6173944],
                [107.5681378, 10.6175333],
                [107.5680774, 10.6182775],
                [107.5680821, 10.6184081],
                [107.5681096, 10.6185359],
                [107.5681643, 10.6186671],
                [107.5682008, 10.6187283],
                [107.5682642, 10.6187852],
                [107.5683058, 10.6188589],
                [107.568317, 10.6188997],
                [107.5683188, 10.618984],
                [107.5682919, 10.6190671],
                [107.5682386, 10.6191369],
                [107.5682039, 10.6191643],
                [107.568123, 10.6192002],
                [107.5678369, 10.6192038],
                [107.5677267, 10.6192308],
                [107.567628, 10.6192859],
                [107.5675853, 10.6193228],
                [107.5675196, 10.6194072],
                [107.567478, 10.6195052],
                [107.5674302, 10.6197693],
                [107.5673735, 10.6201593],
                [107.5673184, 10.6206686],
                [107.5672993, 10.6207618],
                [107.5672452, 10.6209443],
                [107.5671575, 10.6211457],
                [107.5670964, 10.6212542],
                [107.5670035, 10.6213907],
                [107.5669749, 10.6214194],
                [107.5669063, 10.6214629],
                [107.5668262, 10.6214847],
                [107.5667419, 10.6214815],
                [107.5666151, 10.6214418],
                [107.5665244, 10.6214437],
                [107.5664377, 10.621477],
                [107.5663682, 10.6215379],
                [107.5663206, 10.6216743],
                [107.5663066, 10.6217525],
                [107.5663052, 10.621911],
                [107.5663179, 10.6219894],
                [107.5665271, 10.6228119],
                [107.5665317, 10.6229038],
                [107.5665224, 10.622949],
                [107.566482, 10.6230321],
                [107.5664188, 10.6230961],
                [107.5663816, 10.6231192],
                [107.5662985, 10.6231474],
                [107.5662546, 10.6231517],
                [107.566092, 10.6231464],
                [107.5658566, 10.6231177],
                [107.5656728, 10.6230732],
                [107.565538, 10.6230264],
                [107.5654431, 10.6230096],
                [107.565347, 10.6230175],
                [107.5652509, 10.6230524],
                [107.5652075, 10.6230793],
                [107.5651341, 10.6231496],
                [107.5650637, 10.6232651],
                [107.5650281, 10.6233418],
                [107.5649768, 10.6235026],
                [107.5649536, 10.6236599],
                [107.5649519, 10.623889],
                [107.5649389, 10.623958],
                [107.5649167, 10.6240246],
                [107.5648856, 10.6240878],
                [107.5648173, 10.6241806],
                [107.5646811, 10.6243015],
                [107.5646385, 10.6243522],
                [107.5645745, 10.6244674],
                [107.5645429, 10.6245894],
                [107.5645387, 10.6246523],
                [107.5645464, 10.6247879],
                [107.5645289, 10.6249321],
                [107.5645074, 10.6250018],
                [107.5644448, 10.6251252],
                [107.5643614, 10.625227],
                [107.564312, 10.6252709],
                [107.5642007, 10.6253423],
                [107.56414, 10.625369],
                [107.5635536, 10.6255376],
                [107.563499, 10.6255667],
                [107.5634496, 10.6256038],
                [107.5634067, 10.625648],
                [107.5633714, 10.6256982],
                [107.5633444, 10.6257532],
                [107.5633265, 10.6258116],
                [107.563318, 10.6258721],
                [107.5633192, 10.6259332],
                [107.5633471, 10.6260435],
                [107.5634061, 10.6261413],
                [107.5634458, 10.6261829],
                [107.5635415, 10.6262469],
                [107.5636995, 10.6262953],
                [107.5637863, 10.626343],
                [107.5638556, 10.626413],
                [107.5639014, 10.6264982],
                [107.5639147, 10.6265448],
                [107.5639039, 10.626898],
                [107.563895, 10.6269317],
                [107.5638589, 10.6269911],
                [107.5638329, 10.6270148],
                [107.5637698, 10.6270456],
                [107.5636944, 10.6270507],
                [107.5636231, 10.6270259],
                [107.5633782, 10.6268769],
                [107.5633357, 10.6268616],
                [107.5632462, 10.6268507],
                [107.5632012, 10.6268554],
                [107.563116, 10.6268847],
                [107.5630394, 10.6269423],
                [107.5629833, 10.6270266],
                [107.5629672, 10.6270747],
                [107.5629559, 10.6272216],
                [107.5629447, 10.627269],
                [107.5628993, 10.6273663],
                [107.5628634, 10.6274133],
                [107.562772, 10.6274885],
                [107.5624824, 10.6275992],
                [107.5623814, 10.6276622],
                [107.5623027, 10.6277506],
                [107.5622738, 10.6278022],
                [107.5622399, 10.6279149],
                [107.5622398, 10.6280322],
                [107.5622525, 10.6280896],
                [107.5623022, 10.6281962],
                [107.5623381, 10.6282432],
                [107.5627001, 10.6285812],
                [107.5627508, 10.6286361],
                [107.5627946, 10.6286964],
                [107.5628593, 10.6288301],
                [107.5628793, 10.6289015],
                [107.5628933, 10.6290242],
                [107.5628829, 10.6291472],
                [107.5628416, 10.6293218],
                [107.5628418, 10.6294393],
                [107.5628535, 10.6295031],
                [107.5629008, 10.6296238],
                [107.5630671, 10.6298822],
                [107.5630932, 10.6299566],
                [107.563124, 10.6301109],
                [107.5631285, 10.6301895],
                [107.5631154, 10.6303462],
                [107.5630967, 10.6304269],
                [107.5630362, 10.6305813],
                [107.5629465, 10.6307213],
                [107.5627649, 10.6309112],
                [107.5627184, 10.6309947],
                [107.5626926, 10.6311068],
                [107.5627023, 10.6312214],
                [107.5626944, 10.6313404],
                [107.5626566, 10.6314538],
                [107.5626272, 10.6315061],
                [107.5625514, 10.6315961],
                [107.5624556, 10.6316652],
                [107.5623544, 10.6317159],
                [107.5622713, 10.6317835],
                [107.5622088, 10.6318699],
                [107.5621884, 10.6319136],
                [107.5621641, 10.6320065],
                [107.5621659, 10.6323286],
                [107.5621782, 10.6323743],
                [107.5622259, 10.6324561],
                [107.5622993, 10.6325168],
                [107.5623912, 10.6325494],
                [107.562441, 10.6325532],
                [107.5625789, 10.6325303],
                [107.5626573, 10.632548],
                [107.562721, 10.6325961],
                [107.5627586, 10.632666],
                [107.5627653, 10.6327061],
                [107.5627515, 10.6327858],
                [107.5626779, 10.6329192],
                [107.562645, 10.6330377],
                [107.5626396, 10.6331048],
                [107.5626501, 10.6332882],
                [107.5626398, 10.6333456],
                [107.5625929, 10.6334523],
                [107.5625575, 10.633499],
                [107.5624709, 10.6335712],
                [107.5623667, 10.6336153],
                [107.5623107, 10.6336255],
                [107.5621855, 10.6336285],
                [107.5620639, 10.6335992],
                [107.5620036, 10.633571],
                [107.5619479, 10.6335347],
                [107.5618979, 10.6334911],
                [107.5618547, 10.633441],
                [107.5616944, 10.6333634],
                [107.5616094, 10.6333362],
                [107.5614286, 10.6333059],
                [107.5613344, 10.6333039],
                [107.5612781, 10.6333157],
                [107.5612541, 10.6333317],
                [107.5612222, 10.6333788],
                [107.5612178, 10.6334345],
                [107.5612411, 10.6334845],
                [107.5612615, 10.6335038],
                [107.5614983, 10.6336442],
                [107.5615489, 10.6336985],
                [107.5615741, 10.6337678],
                [107.5615694, 10.6338435],
                [107.5615549, 10.6338801],
                [107.5615033, 10.633958],
                [107.5614803, 10.6340067],
                [107.5614558, 10.6341111],
                [107.5614545, 10.6341581],
                [107.5614282, 10.6342483],
                [107.5614041, 10.634289],
                [107.5613371, 10.6343558],
                [107.561294, 10.634381],
                [107.5611979, 10.6344077],
                [107.5610682, 10.634403],
                [107.5610398, 10.634413],
                [107.5609957, 10.6344527],
                [107.5609782, 10.6345039],
                [107.5609851, 10.6345525],
                [107.5610477, 10.6346342],
                [107.5610649, 10.6346696],
                [107.5610797, 10.6347463],
                [107.5610662, 10.6348266],
                [107.5610229, 10.6348996],
                [107.5609555, 10.6349521],
                [107.5609156, 10.6349684],
                [107.5608301, 10.6349779],
                [107.5607877, 10.6349914],
                [107.5607094, 10.6350332],
                [107.5606716, 10.6350639],
                [107.5606102, 10.6351387],
                [107.5605723, 10.6351713],
                [107.560531, 10.6351995],
                [107.5604123, 10.6352603],
                [107.5603802, 10.6352869],
                [107.5603315, 10.6353507],
                [107.5603044, 10.6354234],
                [107.5602979, 10.6358481],
                [107.5602883, 10.6359348],
                [107.5602603, 10.6360554],
                [107.56024, 10.6361141],
                [107.5597172, 10.6371348],
                [107.5596606, 10.6372885],
                [107.5596278, 10.6374525],
                [107.5595856, 10.637527],
                [107.5595278, 10.637581],
                [107.5593394, 10.6376712],
                [107.5592232, 10.6377561],
                [107.5591676, 10.6378126],
                [107.5589794, 10.6380437],
                [107.5589373, 10.638083],
                [107.5588383, 10.6381419],
                [107.5587262, 10.6381706],
                [107.558608, 10.6381664],
                [107.5585495, 10.6381512],
                [107.5584426, 10.638096],
                [107.5583571, 10.6380121],
                [107.5582874, 10.6378486],
                [107.5582512, 10.6377978],
                [107.5581955, 10.6377686],
                [107.558164, 10.6377643],
                [107.558101, 10.6377781],
                [107.5580517, 10.637819],
                [107.5580272, 10.6378776],
                [107.5580202, 10.6379909],
                [107.5580233, 10.6382057],
                [107.5580507, 10.6384434],
                [107.5580409, 10.6385149],
                [107.5580067, 10.6385787],
                [107.5579511, 10.6386276],
                [107.5578804, 10.6386547],
                [107.5578425, 10.6386584],
                [107.5574619, 10.6386284],
                [107.5574272, 10.6386301],
                [107.5573632, 10.6386555],
                [107.5573164, 10.6387054],
                [107.557296, 10.6387701],
                [107.557297, 10.6388041],
                [107.557354, 10.6390028],
                [107.557354, 10.6390274],
                [107.557333, 10.6390679],
                [107.5573156, 10.6390809],
                [107.5572735, 10.6390902],
                [107.5570854, 10.6390835],
                [107.5570252, 10.639107],
                [107.5569791, 10.6391519],
                [107.5569637, 10.6391803],
                [107.5569516, 10.6392431],
                [107.5569929, 10.6394719],
                [107.5569919, 10.6396148],
                [107.5569668, 10.6397287],
                [107.5568836, 10.6399478],
                [107.556865, 10.6400337],
                [107.5568539, 10.6402069],
                [107.5568612, 10.6402927],
                [107.5569009, 10.64046],
                [107.5570919, 10.6409077],
                [107.5570961, 10.6409619],
                [107.5570725, 10.641015],
                [107.5570225, 10.6410519],
                [107.5569919, 10.6410594],
                [107.5567573, 10.6410603],
                [107.556696, 10.6410854],
                [107.5566539, 10.6411358],
                [107.556644, 10.6411641],
                [107.556644, 10.6412236],
                [107.5567358, 10.6414964],
                [107.5567348, 10.6415214],
                [107.5567143, 10.6415668],
                [107.5566918, 10.6415872],
                [107.5566641, 10.6416001],
                [107.5566338, 10.6416042],
                [107.5560021, 10.6415339],
                [107.5558803, 10.6415384],
                [107.5558212, 10.6415535],
                [107.5557125, 10.6416077],
                [107.555631, 10.6416813],
                [107.5555716, 10.6417731],
                [107.5555515, 10.6418239],
                [107.5555309, 10.6419937],
                [107.555514, 10.6423353],
                [107.5555185, 10.6425222],
                [107.5555329, 10.6427086],
                [107.5555569, 10.6428941],
                [107.5555648, 10.6430869],
                [107.5555462, 10.643279],
                [107.5555223, 10.6433925],
                [107.5554892, 10.6435038],
                [107.555447, 10.643612],
                [107.5553415, 10.6438107],
                [107.555324, 10.6438622],
                [107.5553101, 10.6439696],
                [107.5553236, 10.6440722],
                [107.5553624, 10.6441683],
                [107.5553906, 10.6442121],
                [107.5554309, 10.6442466],
                [107.5555227, 10.6442997],
                [107.5556289, 10.6443298],
                [107.5557431, 10.644332],
                [107.5557994, 10.644322],
                [107.5558539, 10.6443048],
                [107.5559929, 10.6442386],
                [107.5560849, 10.6442178],
                [107.5561765, 10.6442174],
                [107.5561963, 10.6442233],
                [107.5562269, 10.6442499],
                [107.5562382, 10.6442886],
                [107.5562342, 10.6443114],
                [107.5561572, 10.644431],
                [107.5561198, 10.6445438],
                [107.5561102, 10.6446137],
                [107.5561102, 10.6446843],
                [107.5561199, 10.6447543],
                [107.556139, 10.6448223],
                [107.5561672, 10.6448872],
                [107.5562041, 10.6449478],
                [107.5562489, 10.6450029],
                [107.5562629, 10.645033],
                [107.5562721, 10.6450981],
                [107.5562555, 10.6451618],
                [107.5562125, 10.6452173],
                [107.5561815, 10.6452383],
                [107.5561466, 10.6452523],
                [107.5560718, 10.6452567],
                [107.5560355, 10.6452469],
                [107.5556027, 10.6450212],
                [107.5555129, 10.6449959],
                [107.5554661, 10.6449954],
                [107.5553757, 10.6450187],
                [107.5552994, 10.6450715],
                [107.5552484, 10.6451434],
                [107.555233, 10.6451846],
                [107.5552243, 10.6452718],
                [107.5552328, 10.6453466],
                [107.5552672, 10.6454932],
                [107.5552966, 10.6455732],
                [107.5553761, 10.645724],
                [107.5554978, 10.6458864],
                [107.5555231, 10.6459394],
                [107.5555492, 10.6460533],
                [107.5555496, 10.6461119],
                [107.5555247, 10.6462261],
                [107.555469, 10.646327],
                [107.5553868, 10.6464086],
                [107.5553377, 10.64644],
                [107.5552285, 10.646481],
                [107.5550789, 10.6464977],
                [107.5548948, 10.6464964],
                [107.5546557, 10.6464686],
                [107.5545644, 10.6464674],
                [107.554428, 10.6464805],
                [107.5543418, 10.6465108],
                [107.554307, 10.6465308],
                [107.5542453, 10.6465819],
                [107.5541809, 10.6466109],
                [107.5540662, 10.6466414],
                [107.5539476, 10.6466478],
                [107.5539216, 10.6466546],
                [107.5538794, 10.6466865],
                [107.5538591, 10.6467348],
                [107.5538593, 10.646763],
                [107.5538831, 10.6468138],
                [107.5539046, 10.6468324],
                [107.5543258, 10.6470775],
                [107.5543608, 10.6471258],
                [107.5543713, 10.6471537],
                [107.5543771, 10.6472089],
                [107.554366, 10.6472594],
                [107.5543402, 10.647311],
                [107.5543043, 10.6474202],
                [107.5542902, 10.6475385],
                [107.5542899, 10.647633],
                [107.5542806, 10.6476668],
                [107.5542428, 10.6477256],
                [107.5541846, 10.6477655],
                [107.5541507, 10.6477761],
                [107.5540797, 10.6477768],
                [107.553964, 10.6477412],
                [107.5538285, 10.6476844],
                [107.5537088, 10.6476548],
                [107.5536429, 10.6476571],
                [107.5536123, 10.6476699],
                [107.5535647, 10.6477148],
                [107.5535506, 10.6477444],
                [107.5535457, 10.647809],
                [107.5535721, 10.6478683],
                [107.553588, 10.6479372],
                [107.5535863, 10.6479726],
                [107.553564, 10.6480397],
                [107.5535145, 10.6480985],
                [107.5533831, 10.6481643],
                [107.5533582, 10.6481887],
                [107.553328, 10.6482505],
                [107.5533241, 10.6482851],
                [107.55334, 10.6483524],
                [107.5534133, 10.648442],
                [107.5534356, 10.6484822],
                [107.553458, 10.6485709],
                [107.5534487, 10.6486618],
                [107.5534322, 10.6487051],
                [107.5533778, 10.6487804],
                [107.5533007, 10.648833],
                [107.55321, 10.6488568],
                [107.5525833, 10.6488068],
                [107.5525181, 10.6488147],
                [107.5524547, 10.6488321],
                [107.5523947, 10.6488585],
                [107.5523394, 10.6488933],
                [107.5522512, 10.6489804],
                [107.5522188, 10.6490296],
                [107.5521757, 10.6491385],
                [107.5521658, 10.6491963],
                [107.5521654, 10.6493327],
                [107.5521569, 10.6494101],
                [107.5521387, 10.6494859],
                [107.5521111, 10.6495589],
                [107.5520743, 10.6496278],
                [107.5520291, 10.6496918],
                [107.5519226, 10.6497958],
                [107.5517942, 10.6498722],
                [107.5516511, 10.6499168],
                [107.5515014, 10.649927],
                [107.551424, 10.6499181],
                [107.5513479, 10.6499346],
                [107.5512814, 10.6499745],
                [107.5512252, 10.6500457],
                [107.551201, 10.6501327],
                [107.5512289, 10.6505841],
                [107.5512117, 10.6506757],
                [107.5511927, 10.6507232],
                [107.5511358, 10.6508083],
                [107.5506887, 10.6512292],
                [107.5506465, 10.6512795],
                [107.5505808, 10.6513925],
                [107.5505581, 10.6514537],
                [107.5505348, 10.6515819],
                [107.5505417, 10.651712],
                [107.5505564, 10.6517758],
                [107.5506074, 10.6518963],
                [107.5507137, 10.6520387],
                [107.5507638, 10.6521386],
                [107.5507818, 10.6521979],
                [107.550796, 10.6523205],
                [107.5507799, 10.6527502],
                [107.5508, 10.6528646],
                [107.5508526, 10.6529685],
                [107.5509262, 10.6530475],
                [107.5509664, 10.6530767],
                [107.5510562, 10.6531194],
                [107.5511802, 10.6531439],
                [107.5512144, 10.6531614],
                [107.5512625, 10.6532084],
                [107.5512788, 10.6532378],
                [107.5513136, 10.6534118],
                [107.5513201, 10.6534846],
                [107.551319, 10.6536306],
                [107.5513122, 10.6536736],
                [107.5512798, 10.6537543],
                [107.5512548, 10.6537902],
                [107.5511903, 10.6538494],
                [107.5511503, 10.6538721],
                [107.5510623, 10.6538982],
                [107.5509343, 10.6538975],
                [107.550899, 10.6539043],
                [107.5508654, 10.653917],
                [107.5508077, 10.6539588],
                [107.5507658, 10.654023],
                [107.5507544, 10.6540627],
                [107.5507514, 10.6541038],
                [107.5507691, 10.6541794],
                [107.5507707, 10.6542524],
                [107.55076, 10.6542876],
                [107.5507216, 10.6543447],
                [107.5506685, 10.6543816],
                [107.5504685, 10.6544335],
                [107.5503427, 10.6544978],
                [107.5502278, 10.6545942],
                [107.5501798, 10.6546515],
                [107.5501065, 10.6547642],
                [107.5500626, 10.6548745],
                [107.5500517, 10.6549329],
                [107.550053, 10.6550514],
                [107.5500642, 10.6551061],
                [107.5501061, 10.6552097],
                [107.5501361, 10.655257],
                [107.550209, 10.6553428],
                [107.5502347, 10.6553929],
                [107.5502616, 10.6555015],
                [107.5502551, 10.6556088],
                [107.5502193, 10.6557059],
                [107.5501576, 10.6557895],
                [107.54992, 10.6559782],
                [107.5498583, 10.6560637],
                [107.5498223, 10.6561625],
                [107.5498146, 10.6562202],
                [107.5498158, 10.6562783],
                [107.5498446, 10.6563909],
                [107.5498716, 10.6564427],
                [107.5499573, 10.6566122],
                [107.5499661, 10.656652],
                [107.549958, 10.6567326],
                [107.5499173, 10.6568031],
                [107.5498546, 10.6568497],
                [107.5497825, 10.6568686],
                [107.5497084, 10.6568601],
                [107.5496426, 10.6568254],
                [107.5495611, 10.6568018],
                [107.5495185, 10.6568008],
                [107.5494418, 10.6568178],
                [107.5493794, 10.6568538],
                [107.5492591, 10.6569862],
                [107.5492093, 10.6570268],
                [107.5490981, 10.6570916],
                [107.5490685, 10.6571181],
                [107.5490438, 10.6571491],
                [107.5490126, 10.6572214],
                [107.5490076, 10.657294],
                [107.5490238, 10.6573593],
                [107.5490391, 10.6573895],
                [107.5490893, 10.6574513],
                [107.5491463, 10.6575071],
                [107.5492094, 10.6575561],
                [107.5493384, 10.6576264],
                [107.5494014, 10.6576493],
                [107.5495771, 10.6576851],
                [107.549619, 10.657702],
                [107.5496882, 10.6577585],
                [107.5497114, 10.6577933],
                [107.549736, 10.6578725],
                [107.5497258, 10.6581249],
                [107.5496952, 10.6581999],
                [107.5496709, 10.6582327],
                [107.5496075, 10.6582842],
                [107.5495287, 10.6583129],
                [107.5494858, 10.658317],
                [107.549401, 10.6583033],
                [107.5493261, 10.658262],
                [107.5491975, 10.6580917],
                [107.5491297, 10.658055],
                [107.5490525, 10.6580485],
                [107.5489781, 10.658074],
                [107.548921, 10.6581275],
                [107.5488809, 10.6582177],
                [107.5488107, 10.6583376],
                [107.5488007, 10.6583758],
                [107.5488031, 10.6584544],
                [107.5488356, 10.6585281],
                [107.5488939, 10.6585844],
                [107.5490263, 10.6586405],
                [107.5490491, 10.6586618],
                [107.5490776, 10.6587164],
                [107.5490792, 10.6587788],
                [107.549069, 10.6588092],
                [107.5490292, 10.6588592],
                [107.5489741, 10.6589032],
                [107.5489358, 10.6589696],
                [107.5489238, 10.6590449],
                [107.548937, 10.6591134],
                [107.5489721, 10.659174],
                [107.5492361, 10.6594489],
                [107.5492511, 10.6595004],
                [107.5492389, 10.6595523],
                [107.5492028, 10.6595916],
                [107.549062, 10.6596352],
                [107.5489781, 10.6596854],
                [107.5487897, 10.6598752],
                [107.5487627, 10.659895],
                [107.5487321, 10.6599088],
                [107.5486656, 10.6599158],
                [107.548602, 10.659895],
                [107.5485751, 10.6598752],
                [107.5485375, 10.6598222],
                [107.5485279, 10.6597911],
                [107.5485293, 10.6597266],
                [107.5485402, 10.6596959],
                [107.5485885, 10.6595852],
                [107.5486231, 10.6595265],
                [107.5486232, 10.6594587],
                [107.54861, 10.6594271],
                [107.5485721, 10.6593858],
                [107.5485203, 10.6593637],
                [107.548432, 10.6593611],
                [107.5483149, 10.6593849],
                [107.5482126, 10.659436],
                [107.5481272, 10.6595114],
                [107.5479126, 10.6598699],
                [107.5478778, 10.6599764],
                [107.5478721, 10.6600322],
                [107.5478842, 10.6601434],
                [107.5479281, 10.6602491],
                [107.5479616, 10.6602972],
                [107.5480481, 10.6603767],
                [107.548154, 10.6604287],
                [107.5482516, 10.6605154],
                [107.5482938, 10.6605651],
                [107.5483608, 10.6606708],
                [107.5484264, 10.6608427],
                [107.5484866, 10.6609559],
                [107.5485819, 10.6610638],
                [107.5487012, 10.6611457],
                [107.5487456, 10.6611865],
                [107.5487849, 10.6612322],
                [107.548846, 10.6613355],
                [107.5488685, 10.6613968],
                [107.5488889, 10.6615253],
                [107.5488996, 10.6622369],
                [107.5488987, 10.6622841],
                [107.5488742, 10.6623749],
                [107.5488513, 10.6624163],
                [107.5487871, 10.6624859],
                [107.5487038, 10.6625322],
                [107.5486157, 10.6625502],
                [107.548526, 10.6625419],
                [107.5484832, 10.6625279],
                [107.5484429, 10.6625079],
                [107.5483761, 10.66246],
                [107.5483064, 10.662434],
                [107.5482318, 10.6624346],
                [107.5481589, 10.6624642],
                [107.548103, 10.662519],
                [107.5480092, 10.6627351],
                [107.547918, 10.6630514],
                [107.5479018, 10.6630866],
                [107.5478485, 10.6631427],
                [107.547776, 10.6631714],
                [107.5477368, 10.6631736],
                [107.5476643, 10.6631545],
                [107.5476078, 10.6631104],
                [107.5475875, 10.663081],
                [107.5475664, 10.6630132],
                [107.5475838, 10.6628815],
                [107.5475818, 10.66278],
                [107.5475585, 10.6626771],
                [107.5475239, 10.6626098],
                [107.5474629, 10.6625638],
                [107.5474262, 10.6625519],
                [107.5473493, 10.6625533],
                [107.5472892, 10.6625807],
                [107.5472642, 10.6626024],
                [107.5472292, 10.6626577],
                [107.5472204, 10.6626893],
                [107.547219, 10.6627856],
                [107.5471972, 10.662856],
                [107.5471455, 10.6629223],
                [107.5470252, 10.6630246],
                [107.5470017, 10.6630776],
                [107.547008, 10.6631378],
                [107.5470235, 10.6631658],
                [107.5472169, 10.6633174],
                [107.5472418, 10.6633773],
                [107.5472363, 10.663435],
                [107.5472256, 10.6634585],
                [107.5471911, 10.6634969],
                [107.5470677, 10.6635707],
                [107.5469202, 10.6636814],
                [107.5468705, 10.6637089],
                [107.5467627, 10.6637453],
                [107.5466493, 10.6637552],
                [107.5465872, 10.6637492],
                [107.5464681, 10.6637133],
                [107.5464132, 10.6636841],
                [107.5463798, 10.6636556],
                [107.5463321, 10.6635829],
                [107.5463196, 10.6635412],
                [107.5463194, 10.6634547],
                [107.5463489, 10.6633789],
                [107.5463734, 10.663346],
                [107.5464381, 10.6632955],
                [107.5465943, 10.6632333],
                [107.5466327, 10.6632016],
                [107.5466529, 10.6631566],
                [107.5466546, 10.6631318],
                [107.5466352, 10.663075],
                [107.5466144, 10.6630528],
                [107.5465403, 10.663021],
                [107.5465006, 10.663013],
                [107.5464163, 10.6630143],
                [107.5463736, 10.6630245],
                [107.5462578, 10.6630814],
                [107.5462177, 10.6630928],
                [107.5461346, 10.6630943],
                [107.5460941, 10.6630844],
                [107.546026, 10.6630482],
                [107.5459733, 10.6629926],
                [107.5459546, 10.6629592],
                [107.545897, 10.6629205],
                [107.5458279, 10.6629118],
                [107.5457936, 10.6629196],
                [107.5457439, 10.6629492],
                [107.5457095, 10.6629953],
                [107.5456877, 10.6630637],
                [107.5456841, 10.6631064],
                [107.5456999, 10.6632136],
                [107.5456783, 10.6632755],
                [107.5456333, 10.6633179],
                [107.5455425, 10.6633432],
                [107.5454855, 10.6633712],
                [107.5454354, 10.6634218],
                [107.5454164, 10.6634565],
                [107.5454047, 10.6634943],
                [107.5453682, 10.663828],
                [107.5453423, 10.663952],
                [107.5452968, 10.6641091],
                [107.5452934, 10.6641456],
                [107.5453109, 10.6642165],
                [107.5453295, 10.6642458],
                [107.545383, 10.6642902],
                [107.5454155, 10.6643035],
                [107.5456032, 10.6643404],
                [107.5457166, 10.6643863],
                [107.5458009, 10.6644381],
                [107.5458731, 10.6644608],
                [107.5459487, 10.6644545],
                [107.5460135, 10.664422],
                [107.5460598, 10.6643711],
                [107.5460952, 10.6643038],
                [107.5461228, 10.6642724],
                [107.5461568, 10.6642478],
                [107.5461955, 10.6642311],
                [107.5462369, 10.6642232],
                [107.5463179, 10.664234],
                [107.5463851, 10.6642755],
                [107.5464293, 10.6643404],
                [107.5464401, 10.6646356],
                [107.5464328, 10.6646799],
                [107.5464165, 10.6647218],
                [107.5463918, 10.6647595],
                [107.5463318, 10.6648107],
                [107.5462957, 10.6648276],
                [107.5462174, 10.6648412],
                [107.5459345, 10.66483],
                [107.5457936, 10.6648148],
                [107.545652, 10.6647931],
                [107.5453354, 10.6647253],
                [107.5452626, 10.664741],
                [107.5452088, 10.6647809],
                [107.5451714, 10.6648359],
                [107.5451543, 10.6649008],
                [107.5451543, 10.6649345],
                [107.5451838, 10.66505],
                [107.5451889, 10.6651018],
                [107.5451767, 10.665205],
                [107.5451352, 10.6653016],
                [107.5450432, 10.6654259],
                [107.5450249, 10.6654733],
                [107.5450156, 10.6655232],
                [107.5450231, 10.6656154],
                [107.5450366, 10.6656552],
                [107.5451102, 10.6657891],
                [107.545128, 10.6658415],
                [107.5451428, 10.6659309],
                [107.5451403, 10.6660375],
                [107.5451291, 10.6661077],
                [107.545084, 10.6662426],
                [107.5450104, 10.6663647],
                [107.5449625, 10.6664208],
                [107.5448491, 10.6665155],
                [107.5447172, 10.6665832],
                [107.5445089, 10.6666363],
                [107.5444468, 10.6666591],
                [107.5443323, 10.6667243],
                [107.5442813, 10.6667659],
                [107.5441976, 10.6668607],
                [107.5441651, 10.6669122],
                [107.5441173, 10.6670237],
                [107.5441073, 10.6670845],
                [107.5441033, 10.6672075],
                [107.5441204, 10.6673293],
                [107.5441377, 10.6673883],
                [107.544161, 10.6674453],
                [107.5442246, 10.6675509],
                [107.5442738, 10.6676086],
                [107.54433, 10.6676598],
                [107.5443922, 10.6677038],
                [107.5444085, 10.6677235],
                [107.5444211, 10.6677723],
                [107.5444037, 10.6678212],
                [107.5443843, 10.6678402],
                [107.5442755, 10.6678935],
                [107.5442061, 10.6679397],
                [107.5440842, 10.6680525],
                [107.5439894, 10.6681881],
                [107.5439537, 10.6682626],
                [107.5439099, 10.6684065],
                [107.543899, 10.6684809],
                [107.5438994, 10.668631],
                [107.5440448, 10.6694698],
                [107.544062, 10.6695234],
                [107.5441198, 10.6696199],
                [107.5441591, 10.6696608],
                [107.5442541, 10.6697228],
                [107.5443001, 10.6697404],
                [107.5443972, 10.6697574],
                [107.5444955, 10.6697492],
                [107.5445922, 10.6697218],
                [107.5446428, 10.6697211],
                [107.5446927, 10.6697291],
                [107.5447843, 10.6697703],
                [107.5448231, 10.6698021],
                [107.5448808, 10.6698832],
                [107.5448979, 10.6699299],
                [107.5449058, 10.6700286],
                [107.5448959, 10.6700791],
                [107.5448768, 10.6701271],
                [107.544849, 10.6701708],
                [107.5448136, 10.6702088],
                [107.5447718, 10.6702397],
                [107.5447248, 10.6702627],
                [107.5446744, 10.6702768],
                [107.5446223, 10.6702817],
                [107.5445701, 10.6702771],
                [107.5444706, 10.6702513],
                [107.5443701, 10.6702513],
                [107.5442741, 10.6702829],
                [107.544193, 10.6703436],
                [107.5441611, 10.6703828],
                [107.5440883, 10.6705167],
                [107.5439816, 10.6706757],
                [107.5438634, 10.6708104],
                [107.5437307, 10.6709437],
                [107.5436103, 10.6710987],
                [107.5435595, 10.6711985],
                [107.5435463, 10.6712529],
                [107.5435456, 10.6713645],
                [107.5435792, 10.6714711],
                [107.543608, 10.6715194],
                [107.5436862, 10.6716004],
                [107.5437835, 10.6716537],
                [107.543836, 10.6716689],
                [107.5439451, 10.6716758],
                [107.5440515, 10.6716514],
                [107.5441461, 10.6715977],
                [107.5441864, 10.6715613],
                [107.5444311, 10.6712727],
                [107.544499, 10.6712131],
                [107.5445848, 10.6711832],
                [107.5446305, 10.6711809],
                [107.5446758, 10.6711874],
                [107.5447583, 10.6712252],
                [107.5447899, 10.6712524],
                [107.544836, 10.6713209],
                [107.5448549, 10.6714009],
                [107.5448442, 10.6714824],
                [107.5447852, 10.6716063],
                [107.544643, 10.6718194],
                [107.5446135, 10.6718763],
                [107.5445515, 10.6720577],
                [107.5444892, 10.6721616],
                [107.5443987, 10.6722438],
                [107.5442876, 10.6722971],
                [107.5439483, 10.6723797],
                [107.5439006, 10.6723979],
                [107.5438556, 10.6724219],
                [107.5437769, 10.6724859],
                [107.5437133, 10.6725746],
                [107.5436902, 10.6726285],
                [107.5436693, 10.6727435],
                [107.5436653, 10.6729751],
                [107.5436546, 10.6730078],
                [107.543613, 10.6730624],
                [107.5435875, 10.6730799],
                [107.5435287, 10.6730987],
                [107.543385, 10.673094],
                [107.5432348, 10.6731125],
                [107.5431674, 10.6731648],
                [107.5431271, 10.6732394],
                [107.5431202, 10.6733196],
                [107.5431433, 10.6733927],
                [107.5431919, 10.6734525],
                [107.5432038, 10.6734801],
                [107.5432026, 10.6735395],
                [107.5431763, 10.6735828],
                [107.5431383, 10.6736765],
                [107.5431207, 10.6737885],
                [107.5431217, 10.6738924],
                [107.5430995, 10.6739841],
                [107.5430511, 10.6740653],
                [107.5430022, 10.674109],
                [107.5429395, 10.6741295],
                [107.5428707, 10.6741226],
                [107.5428091, 10.6740857],
                [107.5427862, 10.6740581],
                [107.5427672, 10.6739776],
                [107.542775, 10.6738997],
                [107.5428048, 10.6738317],
                [107.542852, 10.6737705],
                [107.5428703, 10.6737353],
                [107.5428854, 10.6736578],
                [107.5428815, 10.6736183],
                [107.5428518, 10.6735456],
                [107.5427974, 10.673489],
                [107.5427631, 10.6734692],
                [107.5426863, 10.6734499],
                [107.5420815, 10.6733971],
                [107.5419627, 10.6733922],
                [107.5418439, 10.6733967],
                [107.5417259, 10.6734107],
                [107.5415048, 10.6734635],
                [107.5414027, 10.6735004],
                [107.5412405, 10.6735784],
                [107.5411251, 10.6736623],
                [107.541074, 10.6737119],
                [107.5409871, 10.6738241],
                [107.5409489, 10.6738921],
                [107.540895, 10.6740379],
                [107.5408798, 10.6741141],
                [107.5408816, 10.6741663],
                [107.5409074, 10.6742675],
                [107.5409557, 10.6743512],
                [107.5410178, 10.6744149],
                [107.5410836, 10.6744649],
                [107.5411271, 10.6745261],
                [107.541146, 10.6745985],
                [107.5411362, 10.6746784],
                [107.5411183, 10.674718],
                [107.5410598, 10.6747823],
                [107.5410218, 10.6748043],
                [107.5409362, 10.6748231],
                [107.5408767, 10.6748478],
                [107.5407687, 10.6749168],
                [107.5406871, 10.6749994],
                [107.5406081, 10.6751146],
                [107.5405498, 10.675156],
                [107.5404802, 10.6751737],
                [107.5404038, 10.6751637],
                [107.5403373, 10.6751253],
                [107.5403112, 10.6750972],
                [107.5402083, 10.6750388],
                [107.5400473, 10.6749626],
                [107.5399464, 10.6748995],
                [107.5398418, 10.6747968],
                [107.5397077, 10.6745833],
                [107.5396742, 10.6745448],
                [107.5396264, 10.6745259],
                [107.5396004, 10.6745253],
                [107.5395711, 10.6745322],
                [107.5395237, 10.6745678],
                [107.5395092, 10.6745938],
                [107.539477, 10.6747256],
                [107.5394721, 10.6747669],
                [107.5394797, 10.6748495],
                [107.5395226, 10.674947],
                [107.5397223, 10.6751982],
                [107.5397778, 10.6753127],
                [107.5398012, 10.6754374],
                [107.5398002, 10.6755009],
                [107.5397747, 10.6756206],
                [107.5397517, 10.6756751],
                [107.5396863, 10.6757739],
                [107.5396449, 10.6758167],
                [107.5395479, 10.6758859],
                [107.5394938, 10.6759114],
                [107.5393528, 10.675951],
                [107.5390885, 10.6759971],
                [107.539004, 10.6760398],
                [107.5389338, 10.6761073],
                [107.5389065, 10.6761495],
                [107.5388736, 10.6762438],
                [107.5388762, 10.6765548],
                [107.5389028, 10.6766603],
                [107.5389567, 10.6767551],
                [107.5389915, 10.6767951],
                [107.5390754, 10.6768602],
                [107.5394445, 10.6770199],
                [107.5394729, 10.6770382],
                [107.5395141, 10.6770908],
                [107.5395247, 10.6771225],
                [107.539524, 10.6771877],
                [107.5394959, 10.6772455],
                [107.5394459, 10.6772864],
                [107.5388193, 10.6775591],
                [107.5387735, 10.6775883],
                [107.5386943, 10.6776617],
                [107.5386348, 10.6777515],
                [107.5386129, 10.6778031],
                [107.5385892, 10.6779122],
                [107.5385926, 10.6780103],
                [107.5385651, 10.6781047],
                [107.5385418, 10.6781456],
                [107.5384779, 10.678215],
                [107.5383046, 10.6783179],
                [107.5382308, 10.6783944],
                [107.5381797, 10.6784871],
                [107.5381547, 10.6785896],
                [107.5381529, 10.6786481],
                [107.5381749, 10.6787627],
                [107.5381983, 10.6788164],
                [107.5382864, 10.6789456],
                [107.538313, 10.6790193],
                [107.5383227, 10.6791265],
                [107.5382727, 10.6795772],
                [107.5382925, 10.6796297],
                [107.5383344, 10.6796677],
                [107.5383642, 10.6796792],
                [107.5384276, 10.679678],
                [107.5384819, 10.6796456],
                [107.5385501, 10.6795569],
                [107.5386348, 10.6794208],
                [107.5386993, 10.6792747],
                [107.5387423, 10.6791214],
                [107.5387817, 10.6788825],
                [107.5388154, 10.6788356],
                [107.5388414, 10.6788216],
                [107.5388922, 10.6788172],
                [107.5389167, 10.6788248],
                [107.5389556, 10.6788571],
                [107.5391176, 10.6791563],
                [107.5391543, 10.6792082],
                [107.5392464, 10.679296],
                [107.5392891, 10.6793241],
                [107.5393821, 10.6793665],
                [107.5395817, 10.6794199],
                [107.5396235, 10.679441],
                [107.5396934, 10.6794948],
                [107.5397867, 10.679611],
                [107.5398344, 10.6796569],
                [107.5399403, 10.6797273],
                [107.5400551, 10.6797697],
                [107.5403273, 10.67981],
                [107.5403914, 10.6798521],
                [107.5404292, 10.6799181],
                [107.5404355, 10.6799541],
                [107.5404231, 10.6800257],
                [107.5404051, 10.6800577],
                [107.539925, 10.6805612],
                [107.5398883, 10.6806131],
                [107.5398351, 10.6807278],
                [107.5398193, 10.680789],
                [107.5398106, 10.6809149],
                [107.5398323, 10.6810389],
                [107.5398831, 10.6811541],
                [107.5399602, 10.6812542],
                [107.5400592, 10.6813337],
                [107.5401151, 10.6813642],
                [107.5402361, 10.6814046],
                [107.5406043, 10.6814287],
                [107.5406461, 10.6814412],
                [107.540684, 10.6814625],
                [107.5407162, 10.6814916],
                [107.5407404, 10.6815258],
                [107.5407565, 10.6815644],
                [107.5407638, 10.6816055],
                [107.5407587, 10.6817276],
                [107.5407645, 10.6818079],
                [107.5407914, 10.6819306],
                [107.5408299, 10.682044],
                [107.5408235, 10.6821004],
                [107.5407917, 10.6821527],
                [107.5407401, 10.6821865],
                [107.5405338, 10.6822191],
                [107.5404792, 10.6822504],
                [107.5404308, 10.6823049],
                [107.5404127, 10.6823423],
                [107.5403951, 10.6824402],
                [107.5403964, 10.6824984],
                [107.5404239, 10.6826113],
                [107.5405154, 10.6827747],
                [107.5405392, 10.6828411],
                [107.5405526, 10.6830071],
                [107.5405697, 10.6830788],
                [107.5406055, 10.6831465],
                [107.5406597, 10.683205],
                [107.5409281, 10.6833761],
                [107.5410263, 10.6834479],
                [107.5411212, 10.6835237],
                [107.5412688, 10.6836608],
                [107.5413396, 10.6837074],
                [107.5414198, 10.6837361],
                [107.541462, 10.6837431],
                [107.5419125, 10.6837504],
                [107.5419502, 10.683756],
                [107.5420186, 10.6837882],
                [107.5420466, 10.6838137],
                [107.5420827, 10.6838725],
                [107.5421006, 10.6839921],
                [107.5420988, 10.6840443],
                [107.5420717, 10.6841485],
                [107.5420131, 10.6842434],
                [107.5419286, 10.6843171],
                [107.5419032, 10.6843544],
                [107.5418842, 10.6843953],
                [107.5418669, 10.6844831],
                [107.5418785, 10.6845725],
                [107.5418951, 10.6846147],
                [107.5419474, 10.6846887],
                [107.5420171, 10.6848679],
                [107.5420701, 10.6851479],
                [107.5421137, 10.6852106],
                [107.5421409, 10.6852323],
                [107.5422049, 10.6852598],
                [107.5422396, 10.6852646],
                [107.5424729, 10.6852539],
                [107.5425397, 10.6852711],
                [107.5425692, 10.6852891],
                [107.5426145, 10.6853402],
                [107.5426362, 10.6854051],
                [107.5426304, 10.6854735],
                [107.5425976, 10.6855342],
                [107.5425433, 10.6855774],
                [107.542476, 10.6856121],
                [107.5424262, 10.6856675],
                [107.5423229, 10.6859196],
                [107.5422838, 10.6859754],
                [107.5422203, 10.6860235],
                [107.5418521, 10.6861687],
                [107.5418239, 10.6861853],
                [107.5417844, 10.6862363],
                [107.5417757, 10.6862675],
                [107.5417795, 10.6863207],
                [107.5418052, 10.6863677],
                [107.5418962, 10.6864819],
                [107.5419698, 10.6866179],
                [107.5419983, 10.6866945],
                [107.542002, 10.6867814],
                [107.5419903, 10.6868236],
                [107.5419452, 10.6868939],
                [107.5418763, 10.6869421],
                [107.5417996, 10.6869728],
                [107.5417368, 10.6870226],
                [107.5416912, 10.6870898],
                [107.5416835, 10.6871397],
                [107.5417022, 10.6871714],
                [107.5417188, 10.6871827],
                [107.5417583, 10.6871887],
                [107.5418589, 10.6871636],
                [107.5419129, 10.6871373],
                [107.5419621, 10.687103],
                [107.5420271, 10.687078],
                [107.5421088, 10.6870688],
                [107.5421561, 10.6870738],
                [107.5422451, 10.6871057],
                [107.5424016, 10.6872066],
                [107.5426255, 10.6873167],
                [107.5426618, 10.687362],
                [107.5426689, 10.6873903],
                [107.5426588, 10.6874457],
                [107.5426427, 10.6874693],
                [107.5425942, 10.6874994],
                [107.5423577, 10.6875247],
                [107.5421393, 10.6875392],
                [107.5420788, 10.6875671],
                [107.5420355, 10.6876171],
                [107.5420171, 10.6876802],
                [107.5420265, 10.6877443],
                [107.5420412, 10.6877735],
                [107.5420873, 10.6878198],
                [107.5421485, 10.6878436],
                [107.5422973, 10.6878618],
                [107.5424409, 10.6879043],
                [107.5425993, 10.6879846],
                [107.5427177, 10.6880653],
                [107.5427675, 10.6880882],
                [107.5428741, 10.6881134],
                [107.542929, 10.6881151],
                [107.5430296, 10.6880991],
                [107.5432335, 10.688011],
                [107.5433636, 10.6879847],
                [107.5434229, 10.6879859],
                [107.5434504, 10.6879975],
                [107.5434923, 10.6880387],
                [107.5435085, 10.6880956],
                [107.5434941, 10.688153],
                [107.5434762, 10.6881771],
                [107.5433059, 10.6883281],
                [107.5432841, 10.6883594],
                [107.5432641, 10.6884223],
                [107.5432707, 10.6884997],
                [107.5432911, 10.6885368],
                [107.5433125, 10.6885552],
                [107.5433382, 10.6885669],
                [107.5434282, 10.6885738],
                [107.5434849, 10.6886011],
                [107.5435081, 10.6886232],
                [107.5436291, 10.688861],
                [107.5437792, 10.689058],
                [107.5438187, 10.6891424],
                [107.5438308, 10.689213],
                [107.543813, 10.689244],
                [107.543778, 10.6892603],
                [107.5433675, 10.6892403],
                [107.5433295, 10.6892486],
                [107.5432596, 10.6892821],
                [107.5432008, 10.6893389],
                [107.5431628, 10.6894161],
                [107.5431376, 10.6896498],
                [107.5430956, 10.6898151],
                [107.5429338, 10.6901911],
                [107.5429273, 10.6902242],
                [107.5429378, 10.6902903],
                [107.5429773, 10.6903449],
                [107.5430041, 10.6903633],
                [107.5430667, 10.690381],
                [107.5431309, 10.6903707],
                [107.5431924, 10.6903361],
                [107.5432289, 10.6903249],
                [107.5433046, 10.6903291],
                [107.5433605, 10.6903589],
                [107.5433829, 10.6903814],
                [107.5434118, 10.6904371],
                [107.5434548, 10.6906875],
                [107.5434986, 10.6908064],
                [107.5435705, 10.6909112],
                [107.5436641, 10.6909942],
                [107.5437166, 10.6910262],
                [107.5438314, 10.691071],
                [107.5440842, 10.6911033],
                [107.5443426, 10.6911514],
                [107.5444193, 10.6911845],
                [107.5444776, 10.6912437],
                [107.5445089, 10.6913201],
                [107.5445127, 10.6913614],
                [107.5444961, 10.6914423],
                [107.5444763, 10.6914788],
                [107.5444172, 10.6915375],
                [107.544308, 10.6915829],
                [107.5442524, 10.6916347],
                [107.5442339, 10.6916679],
                [107.5442192, 10.6917418],
                [107.5442234, 10.6917783],
                [107.5442534, 10.6918453],
                [107.5442779, 10.6918731],
                [107.5443412, 10.6919117],
                [107.5450051, 10.6920475],
                [107.5450401, 10.6920656],
                [107.5450998, 10.6921163],
                [107.5451428, 10.6921856],
                [107.5451558, 10.6922263],
                [107.5451618, 10.6922685],
                [107.5451606, 10.6926853],
                [107.5451501, 10.6927315],
                [107.5451131, 10.6928031],
                [107.5450668, 10.6928514],
                [107.5449217, 10.6930366],
                [107.5449096, 10.6930861],
                [107.5449192, 10.693136],
                [107.5449346, 10.6931622],
                [107.5449825, 10.6931996],
                [107.5450119, 10.6932084],
                [107.5451499, 10.6932099],
                [107.5453631, 10.6931877],
                [107.545468, 10.6931655],
                [107.5456589, 10.6931098],
                [107.5457507, 10.693107],
                [107.5457934, 10.693114],
                [107.5458745, 10.6931432],
                [107.5459117, 10.693165],
                [107.5459701, 10.6932209],
                [107.5459921, 10.6932546],
                [107.5460069, 10.6933164],
                [107.5460042, 10.6933482],
                [107.5459872, 10.6933716],
                [107.5459558, 10.6933769],
                [107.54594, 10.6933693],
                [107.5459251, 10.693339],
                [107.5458838, 10.6933129],
                [107.545834, 10.693311],
                [107.5458103, 10.6933198],
                [107.545774, 10.6933542],
                [107.545747, 10.6934048],
                [107.5456656, 10.6934824],
                [107.5456369, 10.6935199],
                [107.5455978, 10.6936052],
                [107.5455878, 10.6936575],
                [107.5455869, 10.6937107],
                [107.5456043, 10.6937946],
                [107.5456381, 10.6938503],
                [107.5456645, 10.6938752],
                [107.5459278, 10.6940137],
                [107.5461032, 10.6941354],
                [107.5463408, 10.6943458],
                [107.5464669, 10.6944433],
                [107.5464995, 10.6944611],
                [107.54655, 10.6945145],
                [107.5465749, 10.6945886],
                [107.5465634, 10.694877],
                [107.5465849, 10.6950152],
                [107.5466251, 10.6951154],
                [107.5466625, 10.6951803],
                [107.5466673, 10.6952546],
                [107.5466385, 10.6953236],
                [107.5465834, 10.6953726],
                [107.5465123, 10.6953936],
                [107.5464422, 10.6953922],
                [107.5464102, 10.6953991],
                [107.5463543, 10.6954319],
                [107.5463173, 10.6954846],
                [107.5463082, 10.6955155],
                [107.5463105, 10.6955785],
                [107.5463382, 10.6956342],
                [107.5463864, 10.6956741],
                [107.5464067, 10.6957411],
                [107.5463911, 10.6958093],
                [107.5463435, 10.6958613],
                [107.5462799, 10.6958834],
                [107.546213, 10.6958739],
                [107.5461833, 10.6958577],
                [107.5461048, 10.6957796],
                [107.5460594, 10.6957565],
                [107.5459601, 10.6957337],
                [107.545909, 10.6957348],
                [107.5458622, 10.6957432],
                [107.5457753, 10.6957805],
                [107.5456702, 10.6958712],
                [107.5455871, 10.6959113],
                [107.5454854, 10.6959251],
                [107.5454342, 10.6959193],
                [107.5452269, 10.6958676],
                [107.545148, 10.6958694],
                [107.5450748, 10.6958982],
                [107.5450397, 10.6959249],
                [107.5449891, 10.6959962],
                [107.5449029, 10.6963232],
                [107.5449105, 10.6963828],
                [107.5449418, 10.6964343],
                [107.5449934, 10.69647],
                [107.5450248, 10.6964792],
                [107.5450899, 10.6964765],
                [107.545276, 10.6963778],
                [107.5453193, 10.6963482],
                [107.5453717, 10.6963426],
                [107.5453973, 10.6963495],
                [107.5454396, 10.6963805],
                [107.545453, 10.6964013],
                [107.5454633, 10.6964492],
                [107.5454503, 10.6964965],
                [107.5451821, 10.6969445],
                [107.5451436, 10.6969865],
                [107.5450542, 10.6970574],
                [107.5448648, 10.697145],
                [107.5447989, 10.6972115],
                [107.544777, 10.6972529],
                [107.5447637, 10.6972977],
                [107.5447594, 10.6973443],
                [107.5447644, 10.6973909],
                [107.5447785, 10.6974357],
                [107.5448012, 10.6974769],
                [107.5449809, 10.6976746],
                [107.5450042, 10.6977085],
                [107.5450194, 10.6977466],
                [107.5450256, 10.697787],
                [107.5450226, 10.6978278],
                [107.5450106, 10.697867],
                [107.5449901, 10.6979026],
                [107.5449622, 10.6979329],
                [107.5448911, 10.6979715],
                [107.5448101, 10.6979771],
                [107.5447342, 10.6979487],
                [107.5445166, 10.6977486],
                [107.5444823, 10.6977286],
                [107.544405, 10.6977101],
                [107.5443265, 10.697722],
                [107.5442612, 10.6977601],
                [107.5442351, 10.6977874],
                [107.5442004, 10.6978538],
                [107.544213, 10.6980944],
                [107.5441902, 10.6981769],
                [107.544136, 10.6982439],
                [107.5440613, 10.6982836],
                [107.5439767, 10.6982917],
                [107.5437391, 10.6982518],
                [107.5436767, 10.6982335],
                [107.5436439, 10.6982344],
                [107.5435826, 10.6982558],
                [107.5435567, 10.6982755],
                [107.5435215, 10.6983248],
                [107.5435084, 10.6983835],
                [107.5435195, 10.6984494],
                [107.5435621, 10.6985759],
                [107.5436106, 10.6986585],
                [107.5436822, 10.6987286],
                [107.5437263, 10.6987569],
                [107.5438295, 10.6987956],
                [107.5439302, 10.6988519],
                [107.54401, 10.6989344],
                [107.5440405, 10.6989845],
                [107.544077, 10.6990951],
                [107.5441089, 10.6994991],
                [107.5440924, 10.699545],
                [107.5440596, 10.6995786],
                [107.544018, 10.6995959],
                [107.5439161, 10.6996091],
                [107.5438624, 10.6996066],
                [107.5437579, 10.6995827],
                [107.5434226, 10.6994438],
                [107.5433507, 10.69943],
                [107.5433141, 10.6994344],
                [107.5432478, 10.6994649],
                [107.5432, 10.6995195],
                [107.5431794, 10.6995844],
                [107.5431793, 10.6996185],
                [107.5431993, 10.6996835],
                [107.5433035, 10.6998021],
                [107.5433645, 10.699913],
                [107.5433843, 10.6999748],
                [107.5434103, 10.7001046],
                [107.5434494, 10.7001599],
                [107.5434789, 10.7001824],
                [107.5435489, 10.7002067],
                [107.5436373, 10.7002044],
                [107.5437374, 10.7002242],
                [107.5438249, 10.7002759],
                [107.5438829, 10.7003423],
                [107.5439038, 10.7003811],
                [107.5439268, 10.7004656],
                [107.5439313, 10.7007524],
                [107.543959, 10.7008346],
                [107.5439832, 10.7008755],
                [107.5440482, 10.7009447],
                [107.5440878, 10.7009717],
                [107.5446296, 10.7011087],
                [107.5446682, 10.7011107],
                [107.5447402, 10.7011377],
                [107.5447703, 10.7011616],
                [107.5448125, 10.7012249],
                [107.5448249, 10.7012997],
                [107.5448054, 10.701373],
                [107.5447571, 10.7014323],
                [107.5447247, 10.7014533],
                [107.5446077, 10.7015055],
                [107.5445319, 10.7015629],
                [107.5444973, 10.7015996],
                [107.5444418, 10.7016833],
                [107.5444268, 10.7017328],
                [107.5443809, 10.7018256],
                [107.5443079, 10.7019143],
                [107.5442058, 10.701989],
                [107.5440603, 10.7020471],
                [107.5439099, 10.7021359],
                [107.5437632, 10.7022605],
                [107.5437331, 10.7022975],
                [107.5436922, 10.7023831],
                [107.5436824, 10.7024295],
                [107.5436854, 10.702524],
                [107.5436974, 10.7025676],
                [107.5437402, 10.7026471],
                [107.5438048, 10.7027108],
                [107.5439408, 10.7027818],
                [107.5440373, 10.7028527],
                [107.5441251, 10.7029504],
                [107.5441897, 10.7030643],
                [107.544349, 10.7034926],
                [107.5443562, 10.7035937],
                [107.5443489, 10.703644],
                [107.5443131, 10.703739],
                [107.5442427, 10.703829],
                [107.5441976, 10.7038647],
                [107.5441473, 10.7038929],
                [107.5440931, 10.703913],
                [107.5437874, 10.703992],
                [107.5436645, 10.7040341],
                [107.5433828, 10.704156],
                [107.5433451, 10.7041849],
                [107.5432887, 10.7042604],
                [107.543272, 10.7043044],
                [107.5432643, 10.7043977],
                [107.5432911, 10.7044875],
                [107.5433416, 10.7045549],
                [107.5434114, 10.7046027],
                [107.5434513, 10.7046176],
                [107.5435358, 10.7046273],
                [107.5435781, 10.7046219],
                [107.5437592, 10.7045384],
                [107.5438464, 10.7044901],
                [107.5440864, 10.7043332],
                [107.5441422, 10.7043318],
                [107.5441919, 10.7043618],
                [107.5442085, 10.7043872],
                [107.5442165, 10.7044163],
                [107.5442123, 10.7044525],
                [107.5441897, 10.7045218],
                [107.5441342, 10.7045991],
                [107.5440556, 10.7046535],
                [107.5439885, 10.7047168],
                [107.5439608, 10.7047536],
                [107.5439206, 10.704831],
                [107.5438667, 10.7050298],
                [107.5438559, 10.7051115],
                [107.5438538, 10.7052712],
                [107.5438753, 10.7054245],
                [107.5438946, 10.7054995],
                [107.5439313, 10.7055811],
                [107.5439884, 10.7056503],
                [107.5440621, 10.7057025],
                [107.5441473, 10.7057338],
                [107.544238, 10.705742],
                [107.5442728, 10.7057539],
                [107.5443313, 10.7057975],
                [107.5443524, 10.7058272],
                [107.5443734, 10.7058933],
                [107.5443736, 10.7059266],
                [107.5443548, 10.7059903],
                [107.5443365, 10.7060184],
                [107.5442297, 10.7061279],
                [107.5440448, 10.7062797],
                [107.5438738, 10.7063853],
                [107.543598, 10.7065102],
                [107.5435219, 10.7065763],
                [107.5434727, 10.706654],
                [107.5434484, 10.7067337],
                [107.5434473, 10.7071112],
                [107.5434291, 10.7071743],
                [107.5433877, 10.7072258],
                [107.5433593, 10.7072452],
                [107.5432936, 10.7072653],
                [107.5430475, 10.7072656],
                [107.5430201, 10.7072759],
                [107.5429783, 10.7073154],
                [107.5429624, 10.7073698],
                [107.5429763, 10.7074244],
                [107.5429934, 10.7074472],
                [107.5432194, 10.7075849],
                [107.5432426, 10.7076218],
                [107.5432418, 10.7076665],
                [107.5432152, 10.7077045],
                [107.5431363, 10.7077584],
                [107.5430896, 10.7078139],
                [107.5430578, 10.7078794],
                [107.5430342, 10.7080329],
                [107.5429966, 10.7080809],
                [107.5429424, 10.708102],
                [107.542888, 10.7080951],
                [107.5428636, 10.7080822],
                [107.5427685, 10.7079958],
                [107.5427147, 10.7079721],
                [107.5426558, 10.7079755],
                [107.5426286, 10.7079875],
                [107.5425889, 10.708025],
                [107.5425764, 10.7080494],
                [107.5425693, 10.7081031],
                [107.5425897, 10.708177],
                [107.5426004, 10.7082749],
                [107.5425857, 10.7083723],
                [107.5425656, 10.7084268],
                [107.5425018, 10.7085241],
                [107.5423582, 10.7086471],
                [107.5423149, 10.7086959],
                [107.5422481, 10.7088011],
                [107.5422064, 10.7089124],
                [107.5421351, 10.7092446],
                [107.5421187, 10.7092843],
                [107.5420632, 10.7093499],
                [107.5420265, 10.7093731],
                [107.5419427, 10.7093953],
                [107.5418991, 10.7093935],
                [107.5418276, 10.7093701],
                [107.5417689, 10.7093238],
                [107.5417466, 10.7092938],
                [107.5417019, 10.7091682],
                [107.5416895, 10.7091509],
                [107.5416534, 10.7091284],
                [107.541608, 10.709127],
                [107.5415854, 10.709136],
                [107.5414287, 10.7092715],
                [107.5413036, 10.7093658],
                [107.5412559, 10.7093953],
                [107.5411518, 10.7094375],
                [107.5410968, 10.7094496],
                [107.5409884, 10.7094553],
                [107.5408853, 10.7094387],
                [107.5407333, 10.7093786],
                [107.5406224, 10.7093671],
                [107.5405241, 10.7093854],
                [107.5404346, 10.7094291],
                [107.5402106, 10.7096],
                [107.5401635, 10.7096473],
                [107.5400872, 10.7097563],
                [107.540039, 10.7098764],
                [107.5400264, 10.7099383],
                [107.5400242, 10.7100642],
                [107.5400364, 10.7101336],
                [107.540058, 10.7102008],
                [107.5401041, 10.7102908],
                [107.5401253, 10.7103129],
                [107.5401801, 10.7103403],
                [107.5402415, 10.710341],
                [107.5402956, 10.7103157],
                [107.540317, 10.710295],
                [107.5403434, 10.7102421],
                [107.5403725, 10.7101201],
                [107.5403912, 10.7100901],
                [107.5404171, 10.7100657],
                [107.5404485, 10.7100487],
                [107.5404832, 10.7100401],
                [107.5405497, 10.7100483],
                [107.5405777, 10.7100627],
                [107.5406203, 10.710108],
                [107.5406384, 10.710167],
                [107.5406519, 10.7103251],
                [107.5406894, 10.7105808],
                [107.5407135, 10.711013],
                [107.5407072, 10.7110861],
                [107.5406706, 10.7111501],
                [107.5406173, 10.7111903],
                [107.5405526, 10.711208],
                [107.5402855, 10.7112159],
                [107.5402301, 10.7111887],
                [107.5402093, 10.7111659],
                [107.5401952, 10.7111397],
                [107.5401794, 10.7110547],
                [107.5401649, 10.7110305],
                [107.5401221, 10.7109961],
                [107.5400699, 10.7109839],
                [107.5399806, 10.7109933],
                [107.5398552, 10.7109919],
                [107.5397747, 10.7109806],
                [107.5396957, 10.7109612],
                [107.539583, 10.7109219],
                [107.5395451, 10.7109167],
                [107.5394697, 10.7109276],
                [107.5394037, 10.7109649],
                [107.5393563, 10.7110236],
                [107.5393419, 10.7110584],
                [107.5393339, 10.7111329],
                [107.5393407, 10.71117],
                [107.5393747, 10.7112371],
                [107.5397386, 10.711564],
                [107.5397706, 10.7116093],
                [107.5398107, 10.711712],
                [107.5398177, 10.7117668],
                [107.5398102, 10.7118548],
                [107.5397801, 10.7119381],
                [107.5397351, 10.7119913],
                [107.5396978, 10.7120501],
                [107.5396689, 10.7121132],
                [107.5396491, 10.7121797],
                [107.5396385, 10.7122481],
                [107.5396375, 10.7123174],
                [107.539646, 10.7123861],
                [107.5396827, 10.7124999],
                [107.5397453, 10.7126022],
                [107.5398044, 10.7126735],
                [107.5398234, 10.7127157],
                [107.5398343, 10.7127606],
                [107.5398311, 10.7128526],
                [107.5397972, 10.7129344],
                [107.5397701, 10.7129698],
                [107.5396581, 10.7130811],
                [107.5395883, 10.7131885],
                [107.539539, 10.7133225],
                [107.5395226, 10.713464],
                [107.5395091, 10.713516],
                [107.5394882, 10.7135657],
                [107.5394261, 10.7136537],
                [107.5393891, 10.713688],
                [107.5393032, 10.7137408],
                [107.5390817, 10.7138032],
                [107.5389674, 10.7138619],
                [107.5388734, 10.7139382],
                [107.5388328, 10.7139829],
                [107.5387663, 10.7140833],
                [107.5385624, 10.7145129],
                [107.5385455, 10.7145564],
                [107.5385347, 10.7146486],
                [107.538552, 10.714732],
                [107.5385892, 10.7148002],
                [107.5387682, 10.7149795],
                [107.5387801, 10.7150337],
                [107.538763, 10.7150829],
                [107.5387467, 10.7151014],
                [107.5387031, 10.7151243],
                [107.538601, 10.7151224],
                [107.538499, 10.7150988],
                [107.5383594, 10.7150233],
                [107.5382568, 10.7149849],
                [107.5381439, 10.714969],
                [107.5380846, 10.7149708],
                [107.538026, 10.7149794],
                [107.537744, 10.7150814],
                [107.5376873, 10.7151177],
                [107.5376478, 10.7151718],
                [107.5376368, 10.7152015],
                [107.5376313, 10.7152644],
                [107.537649, 10.7153409],
                [107.5376909, 10.7154247],
                [107.5377583, 10.7154976],
                [107.5378014, 10.7155275],
                [107.5378988, 10.7155666],
                [107.5379954, 10.7155816],
                [107.5380378, 10.7155963],
                [107.5381105, 10.7156478],
                [107.5381381, 10.7156828],
                [107.5381708, 10.7157647],
                [107.53817, 10.7158538],
                [107.5381352, 10.7159359],
                [107.5380716, 10.7159993],
                [107.5375378, 10.7162892],
                [107.5374774, 10.7163213],
                [107.5373472, 10.7163632],
                [107.5372792, 10.7163724],
                [107.537148, 10.7163675],
                [107.5370268, 10.7163356],
                [107.5369696, 10.7163101],
                [107.5369155, 10.7162786],
                [107.5368394, 10.7162199],
                [107.5367955, 10.7161996],
                [107.5367008, 10.7161799],
                [107.5366522, 10.7161809],
                [107.5365539, 10.7162062],
                [107.536507, 10.7162314],
                [107.5364651, 10.7162639],
                [107.5364006, 10.7163472],
                [107.5363321, 10.7165513],
                [107.536289, 10.7167621],
                [107.5362718, 10.7169815],
                [107.5362736, 10.7170941],
                [107.5362982, 10.7173542],
                [107.5362919, 10.7174284],
                [107.5362763, 10.7175013],
                [107.5362516, 10.7175717],
                [107.5361813, 10.7176944],
                [107.53609, 10.7177924],
                [107.5359788, 10.7178681],
                [107.5358534, 10.7179179],
                [107.5357549, 10.7179944],
                [107.5356799, 10.7180932],
                [107.5356529, 10.718149],
                [107.5356229, 10.7182613],
                [107.5356209, 10.7183702],
                [107.5356294, 10.718424],
                [107.5356729, 10.7185767],
                [107.5356926, 10.7186788],
                [107.5357032, 10.7187823],
                [107.5357046, 10.7188829],
                [107.5356817, 10.7190827],
                [107.5356817, 10.7200789],
                [107.535676, 10.7202064],
                [107.5356374, 10.7203283],
                [107.5355686, 10.7204367],
                [107.5355242, 10.7204835],
                [107.5354189, 10.7205586],
                [107.5353489, 10.7206393],
                [107.5352861, 10.7207256],
                [107.5352311, 10.7208168],
                [107.5351526, 10.7209917],
                [107.5351238, 10.721083],
                [107.5350972, 10.7212093],
                [107.5350746, 10.7212699],
                [107.5350135, 10.7213808],
                [107.534976, 10.7214303],
                [107.5348878, 10.7215178],
                [107.5346607, 10.7216592],
                [107.5343975, 10.7217852],
                [107.5341564, 10.7218772],
                [107.5341065, 10.7218835],
                [107.5340144, 10.7218734],
                [107.5338635, 10.7218048],
                [107.5337829, 10.7218036],
                [107.5337101, 10.721836],
                [107.5335857, 10.7219379],
                [107.5334856, 10.7220061],
                [107.5332725, 10.7221219],
                [107.5330447, 10.7222083],
                [107.5329265, 10.72224],
                [107.5327573, 10.7222712],
                [107.5327076, 10.7222719],
                [107.5326103, 10.722253],
                [107.5325646, 10.7222337],
                [107.5324806, 10.7221745],
                [107.5324155, 10.7220905],
                [107.5323933, 10.7220423],
                [107.5323762, 10.7219451],
                [107.5323863, 10.721847],
                [107.5324267, 10.7217486],
                [107.5324999, 10.7216608],
                [107.5326433, 10.7215695],
                [107.5326637, 10.7215189],
                [107.532651, 10.7214658],
                [107.5326095, 10.7214294],
                [107.5325824, 10.721422],
                [107.5322616, 10.7214085],
                [107.5321788, 10.7214309],
                [107.5321063, 10.7214888],
                [107.5320503, 10.7216131],
                [107.5319695, 10.7217234],
                [107.531913, 10.7217789],
                [107.5318501, 10.7218275],
                [107.5317818, 10.7218683],
                [107.5317197, 10.721934],
                [107.5316772, 10.7220133],
                [107.5316557, 10.722124],
                [107.5316394, 10.7221725],
                [107.5315833, 10.7222584],
                [107.5315136, 10.7223147],
                [107.5313963, 10.7223718],
                [107.5313365, 10.7224323],
                [107.5312999, 10.7225143],
                [107.5312909, 10.7227169],
                [107.5312831, 10.7227515],
                [107.5312504, 10.7228143],
                [107.5311912, 10.7228677],
                [107.531151, 10.7228868],
                [107.5311076, 10.7228973],
                [107.530688, 10.7229122],
                [107.5304543, 10.722936],
                [107.530217, 10.7229854],
                [107.5299793, 10.7230622],
                [107.5295107, 10.7232831],
                [107.5294306, 10.7233402],
                [107.5293678, 10.7234153],
                [107.5293269, 10.7235013],
                [107.5293149, 10.7235474],
                [107.5293007, 10.7237026],
                [107.529267, 10.7237845],
                [107.5292417, 10.7238212],
                [107.5291805, 10.7238769],
                [107.5290459, 10.723974],
                [107.5289337, 10.7240319],
                [107.5288126, 10.7240689],
                [107.5287362, 10.7240806],
                [107.5285819, 10.72409],
                [107.5282955, 10.7240671],
                [107.5282204, 10.7240906],
                [107.528158, 10.7241418],
                [107.5281196, 10.7242152],
                [107.5280723, 10.7243983],
                [107.527941, 10.7247473],
                [107.5278926, 10.7249491],
                [107.5278893, 10.7251874],
                [107.527914, 10.7256044],
                [107.5278983, 10.7257812],
                [107.5278519, 10.7259527],
                [107.5277764, 10.726113],
                [107.5276747, 10.7262575],
                [107.5275493, 10.7263827],
                [107.5271684, 10.7266911],
                [107.5271427, 10.7267271],
                [107.5271133, 10.7268098],
                [107.5271156, 10.7268973],
                [107.5271496, 10.7269783],
                [107.5271754, 10.7270111],
                [107.5272421, 10.7270614],
                [107.5273221, 10.7270867],
                [107.527392, 10.7270945],
                [107.5274393, 10.7271246],
                [107.5274661, 10.7271733],
                [107.5274695, 10.7272001],
                [107.5274567, 10.7272522],
                [107.5274412, 10.7272745],
                [107.5273964, 10.7273051],
                [107.5270751, 10.7274276],
                [107.5270448, 10.7274493],
                [107.5269967, 10.7275054],
                [107.5269789, 10.7275417],
                [107.5269639, 10.7276207],
                [107.526994, 10.7278743],
                [107.5269908, 10.7279194],
                [107.5269594, 10.7280038],
                [107.5269, 10.7280704],
                [107.5268628, 10.7280942],
                [107.5267783, 10.7281205],
                [107.5265059, 10.7281326],
                [107.5264529, 10.7281432],
                [107.5263549, 10.7281875],
                [107.5263123, 10.7282202],
                [107.5262446, 10.7283027],
                [107.5262055, 10.7284014],
                [107.5261979, 10.7284539],
                [107.5262075, 10.7285594],
                [107.5262491, 10.728657],
                [107.5263805, 10.7287532],
                [107.5264173, 10.7287955],
                [107.5264241, 10.7288507],
                [107.5264032, 10.7288953],
                [107.5263849, 10.7289122],
                [107.5263382, 10.7289298],
                [107.5260061, 10.7289785],
                [107.5259547, 10.729012],
                [107.5259254, 10.7290654],
                [107.5259252, 10.7291261],
                [107.5259351, 10.7291524],
                [107.5259714, 10.7291953],
                [107.5260231, 10.7292183],
                [107.5262591, 10.729221],
                [107.5263298, 10.7292359],
                [107.5263877, 10.7292786],
                [107.5264083, 10.7293081],
                [107.5264281, 10.7293765],
                [107.5264264, 10.7294121],
                [107.5264003, 10.7294782],
                [107.5261503, 10.729738],
                [107.5261276, 10.7297765],
                [107.5261009, 10.7298614],
                [107.5261018, 10.7299561],
                [107.5261861, 10.7302176],
                [107.5262672, 10.7305676],
                [107.5263101, 10.7308786],
                [107.5263103, 10.7310462],
                [107.5262777, 10.7312107],
                [107.5262134, 10.731366],
                [107.5261199, 10.7315062],
                [107.5260005, 10.7316264],
                [107.5259322, 10.7316775],
                [107.5257823, 10.7317591],
                [107.5256191, 10.7318106],
                [107.5254488, 10.7318302],
                [107.5252779, 10.731817],
                [107.5251943, 10.7317983],
                [107.5249937, 10.7317334],
                [107.5248908, 10.7317101],
                [107.5246702, 10.7316811],
                [107.5245121, 10.7316768],
                [107.5244719, 10.7316685],
                [107.5244004, 10.7316296],
                [107.5243532, 10.7315727],
                [107.5243382, 10.731539],
                [107.5243279, 10.7314663],
                [107.5243519, 10.7311907],
                [107.5243311, 10.7311291],
                [107.5243118, 10.7311026],
                [107.5242598, 10.7310635],
                [107.5241965, 10.7310472],
                [107.5240329, 10.7310578],
                [107.5239768, 10.7310823],
                [107.5239382, 10.7311291],
                [107.5239255, 10.7311865],
                [107.5239392, 10.7312419],
                [107.5239782, 10.731323],
                [107.5240007, 10.7314433],
                [107.5239999, 10.7315047],
                [107.5239739, 10.7316244],
                [107.5239483, 10.7316824],
                [107.5238748, 10.7317857],
                [107.5237759, 10.7318661],
                [107.5236676, 10.7319294],
                [107.5235797, 10.7320144],
                [107.5235147, 10.7321228],
                [107.5234699, 10.7322774],
                [107.5234306, 10.7323236],
                [107.5233743, 10.7323474],
                [107.5233132, 10.7323437],
                [107.5232603, 10.7323133],
                [107.5232406, 10.7322899],
                [107.523227, 10.7322627],
                [107.523137, 10.7318352],
                [107.523125, 10.7318047],
                [107.5230829, 10.7317546],
                [107.5230548, 10.7317372],
                [107.5229905, 10.7317218],
                [107.5229573, 10.7317245],
                [107.5228982, 10.7317488],
                [107.5228541, 10.7317944],
                [107.5228324, 10.7318536],
                [107.5228321, 10.7319261],
                [107.5228159, 10.732006],
                [107.5227797, 10.7320742],
                [107.5227068, 10.732156],
                [107.522651, 10.7322033],
                [107.5225269, 10.7322754],
                [107.5224606, 10.7322998],
                [107.5222758, 10.7323337],
                [107.522232, 10.7323496],
                [107.5221557, 10.732402],
                [107.5221017, 10.7324763],
                [107.522085, 10.7325191],
                [107.5220749, 10.73261],
                [107.5220813, 10.7326537],
                [107.5221153, 10.7327351],
                [107.5221419, 10.7327706],
                [107.522211, 10.7328266],
                [107.5222948, 10.7328577],
                [107.5223685, 10.7329035],
                [107.5224263, 10.7329678],
                [107.5224634, 10.7330451],
                [107.5224772, 10.7331291],
                [107.5224656, 10.7335799],
                [107.5224534, 10.7336207],
                [107.5224055, 10.7336909],
                [107.5223342, 10.7337365],
                [107.5222506, 10.733751],
                [107.5218093, 10.73373],
                [107.5217354, 10.7337356],
                [107.5216699, 10.7337695],
                [107.5216273, 10.733819],
                [107.5216135, 10.7338486],
                [107.5216064, 10.7340149],
                [107.5215832, 10.7340966],
                [107.5215358, 10.7341674],
                [107.5214705, 10.7342197],
                [107.5214326, 10.734238],
                [107.5213507, 10.734257],
                [107.5208813, 10.734257],
                [107.5208305, 10.7342683],
                [107.5207989, 10.734309],
                [107.5207955, 10.7343348],
                [107.5208136, 10.7343801],
                [107.5208562, 10.7344049],
                [107.5209108, 10.7344055],
                [107.520939, 10.7344137],
                [107.5209817, 10.7344527],
                [107.5209939, 10.7345087],
                [107.5209883, 10.734533],
                [107.5209591, 10.7345731],
                [107.5209133, 10.7345935],
                [107.5208491, 10.7345996],
                [107.5207767, 10.7346312],
                [107.5207174, 10.7346802],
                [107.5206478, 10.7347663],
                [107.5205862, 10.7348052],
                [107.520503, 10.7348236],
                [107.5202349, 10.7348104],
                [107.5202009, 10.7348132],
                [107.520139, 10.7348402],
                [107.5200944, 10.7348923],
                [107.5200821, 10.7349252],
                [107.5200788, 10.7350856],
                [107.5200659, 10.7351293],
                [107.5200207, 10.7352055],
                [107.5199532, 10.7352637],
                [107.5197118, 10.7353981],
                [107.5196555, 10.7354199],
                [107.5195363, 10.7354398],
                [107.5194185, 10.7354279],
                [107.5193103, 10.7353866],
                [107.5192612, 10.7353559],
                [107.5192122, 10.7352998],
                [107.5191795, 10.7352809],
                [107.5191057, 10.7352663],
                [107.5190711, 10.7352706],
                [107.5190082, 10.7352988],
                [107.5189661, 10.7353446],
                [107.5189201, 10.7353767],
                [107.5188643, 10.7353849],
                [107.5188126, 10.7353688],
                [107.5187167, 10.735288],
                [107.5186483, 10.7352657],
                [107.5186121, 10.7352637],
                [107.5185716, 10.735269],
                [107.5184977, 10.7353027],
                [107.5184128, 10.7353955],
                [107.5183773, 10.7354201],
                [107.5183378, 10.7354378],
                [107.5182539, 10.7354508],
                [107.5181723, 10.7354344],
                [107.5181349, 10.7354159],
                [107.5180329, 10.7353238],
                [107.5179408, 10.735264],
                [107.517837, 10.7352268],
                [107.5177279, 10.7352144],
                [107.5176729, 10.7352176],
                [107.5175661, 10.7352426],
                [107.5171443, 10.7354267],
                [107.5171117, 10.7354362],
                [107.5170441, 10.7354362],
                [107.5169814, 10.7354113],
                [107.5169521, 10.735388],
                [107.5169117, 10.735326],
                [107.5169009, 10.7352532],
                [107.5169384, 10.7349264],
                [107.5169361, 10.7349009],
                [107.516914, 10.7348548],
                [107.5168955, 10.7348368],
                [107.5168458, 10.734815],
                [107.5167914, 10.7348188],
                [107.5166126, 10.734911],
                [107.5163502, 10.7350161],
                [107.5160383, 10.7351069],
                [107.5159409, 10.7351516],
                [107.515898, 10.7351836],
                [107.515828, 10.7352637],
                [107.5158046, 10.7353053],
                [107.5157748, 10.7353956],
                [107.515769, 10.7354903],
                [107.5157922, 10.7362059],
                [107.515786, 10.73631],
                [107.5157499, 10.7365153],
                [107.5157203, 10.7366154],
                [107.5156156, 10.7368813],
                [107.5155955, 10.7369696],
                [107.5155848, 10.7370595],
                [107.5155836, 10.73715],
                [107.515592, 10.7372401],
                [107.5156318, 10.7374014],
                [107.5157019, 10.7375524],
                [107.5158005, 10.7377004],
                [107.5158165, 10.7377448],
                [107.5158253, 10.7378383],
                [107.5158041, 10.7379267],
                [107.5157557, 10.738004],
                [107.5157227, 10.738036],
                [107.5154984, 10.7381888],
                [107.5154518, 10.738249],
                [107.5154262, 10.738324],
                [107.5154232, 10.7383654],
                [107.5154277, 10.7384067],
                [107.5154705, 10.7385214],
                [107.5154757, 10.7385604],
                [107.5154735, 10.7385997],
                [107.5154471, 10.7386737],
                [107.5154211, 10.738709],
                [107.5153881, 10.7387382],
                [107.5153498, 10.7387602],
                [107.5153077, 10.7387739],
                [107.5149301, 10.7387944],
                [107.514793, 10.7388296],
                [107.5146625, 10.7388966],
                [107.5146019, 10.7389425],
                [107.5145473, 10.7389952],
                [107.5144662, 10.7391002],
                [107.5144173, 10.7391454],
                [107.5143071, 10.7392176],
                [107.514185, 10.739264],
                [107.5138073, 10.7393458],
                [107.5136782, 10.7393991],
                [107.5135584, 10.7394813],
                [107.5132428, 10.7397753],
                [107.5131128, 10.7398759],
                [107.5129762, 10.739957],
                [107.5125425, 10.7401589],
                [107.51249, 10.7401929],
                [107.5123986, 10.7402775],
                [107.5123608, 10.7403269],
                [107.5123036, 10.7404367],
                [107.5122718, 10.7405679],
                [107.5122686, 10.7406355],
                [107.5123275, 10.7413307],
                [107.5123225, 10.7415659],
                [107.5122955, 10.7418044],
                [107.5120718, 10.7428606],
                [107.5120438, 10.7429454],
                [107.5119656, 10.7431061],
                [107.5118567, 10.7432547],
                [107.5117223, 10.7433816],
                [107.5115669, 10.7434827],
                [107.510249, 10.7441023],
                [107.5090688, 10.744761],
                [107.508468, 10.7449033],
                [107.5079262, 10.7452248],
                [107.5064643, 10.7456055],
                [107.5063447, 10.74566],
                [107.5062901, 10.7456966],
                [107.5061949, 10.7457861],
                [107.5061197, 10.7458978],
                [107.5060721, 10.7460233],
                [107.5059785, 10.7467365],
                [107.5059575, 10.7468323],
                [107.5058716, 10.7470958],
                [107.5057899, 10.7472663],
                [107.5057402, 10.7474483],
                [107.5057241, 10.747636],
                [107.505729, 10.7477314],
                [107.5057647, 10.747919],
                [107.5059079, 10.7482985],
                [107.505955, 10.7485074],
                [107.5059749, 10.7487407],
                [107.5059717, 10.7488678],
                [107.5059279, 10.7491897],
                [107.5059271, 10.7492603],
                [107.5059336, 10.7493306],
                [107.5059682, 10.7494675],
                [107.5059999, 10.7495406],
                [107.50604, 10.7496096],
                [107.5060878, 10.7496737],
                [107.5061428, 10.749732],
                [107.5062042, 10.7497837],
                [107.5062783, 10.7498299],
                [107.5064111, 10.749942],
                [107.5065198, 10.750077],
                [107.5065637, 10.7501515],
                [107.5066289, 10.7503113],
                [107.5066623, 10.7504857],
                [107.5066657, 10.7505773],
                [107.5066595, 10.7506687],
                [107.5066439, 10.750759],
                [107.506619, 10.7508473],
                [107.5065851, 10.7509326],
                [107.5065416, 10.7510266],
                [107.5064322, 10.7512028],
                [107.5062388, 10.7514233],
                [107.5062087, 10.7515026],
                [107.5062088, 10.7515873],
                [107.5062373, 10.7516634],
                [107.5062873, 10.7517232],
                [107.5063191, 10.7517463],
                [107.5064694, 10.7518148],
                [107.5065423, 10.7518615],
                [107.5066097, 10.7519154],
                [107.5066709, 10.7519761],
                [107.5067691, 10.752109],
                [107.5068074, 10.7521821],
                [107.5068605, 10.7523378],
                [107.5068748, 10.7524188],
                [107.5069445, 10.7538576],
                [107.5068933, 10.7540244],
                [107.5068782, 10.7541104],
                [107.5068694, 10.7542844],
                [107.5068854, 10.7544363],
                [107.5069606, 10.7547206],
                [107.5069821, 10.7548273],
                [107.5069989, 10.7549409],
                [107.5070143, 10.7551698],
                [107.5070423, 10.7553216],
                [107.5071001, 10.755465],
                [107.5071415, 10.7555348],
                [107.5072449, 10.75566],
                [107.507717, 10.7560394],
                [107.5077616, 10.7560781],
                [107.5078349, 10.75617],
                [107.5078833, 10.7562766],
                [107.5079028, 10.7563743],
                [107.5078985, 10.7565027],
                [107.5078841, 10.7565803],
                [107.5078288, 10.7567355],
                [107.5077871, 10.7568109],
                [107.5077371, 10.7568813],
                [107.5076796, 10.7569459],
                [107.5073746, 10.7571956],
                [107.507261, 10.757299],
                [107.5071384, 10.7574215],
                [107.5069123, 10.7576837],
                [107.506825, 10.7577655],
                [107.5067749, 10.7577988],
                [107.506665, 10.7578477],
                [107.5065445, 10.7578717],
                [107.5064195, 10.7578681],
                [107.5063581, 10.7578556],
                [107.5062418, 10.7578102],
                [107.5061286, 10.7577817],
                [107.5060117, 10.7577817],
                [107.5058985, 10.7578102],
                [107.5058479, 10.7578333],
                [107.505757, 10.7578964],
                [107.5056839, 10.7579788],
                [107.5056272, 10.7580711],
                [107.5055908, 10.7581116],
                [107.5055093, 10.7581755],
                [107.5054202, 10.7582179],
                [107.5052135, 10.7582834],
                [107.505067, 10.7583636],
                [107.5049346, 10.7584733],
                [107.5048264, 10.7586062],
                [107.5045037, 10.7591488],
                [107.5032806, 10.7600289],
                [107.5029051, 10.7604505],
                [107.5019449, 10.761104],
                [107.5052501, 10.7715976],
                [107.502256, 10.7711592],
                [107.5011241, 10.7705637],
                [107.5006199, 10.7698048],
                [107.4997133, 10.7694991],
                [107.4985197, 10.7684214],
                [107.4982656, 10.7682721],
                [107.4979994, 10.7681448],
                [107.497846, 10.7680835],
                [107.4975312, 10.7679831],
                [107.4973705, 10.7679442],
                [107.4957409, 10.7676309],
                [107.4952348, 10.7681427],
                [107.4951922, 10.7681738],
                [107.4950945, 10.7682133],
                [107.4950516, 10.7682199],
                [107.494965, 10.7682159],
                [107.4941933, 10.7679867],
                [107.4940199, 10.7679468],
                [107.4938425, 10.7679319],
                [107.4936517, 10.7679441],
                [107.4934525, 10.7679891],
                [107.4906528, 10.7689669],
                [107.4868441, 10.7687877],
                [107.4849772, 10.7685505],
                [107.483561, 10.7701895],
                [107.4805704, 10.7756543],
                [107.4801815, 10.7758968],
                [107.4801602, 10.7760046],
                [107.4801382, 10.7760552],
                [107.4800763, 10.7761418],
                [107.4799964, 10.7762066],
                [107.4799506, 10.7762307],
                [107.4798515, 10.7762604],
                [107.4797255, 10.7765713],
                [107.4793607, 10.7769929],
                [107.4787974, 10.7779625],
                [107.478025, 10.7785053],
                [107.4776172, 10.7791241],
                [107.4775851, 10.779222],
                [107.4775824, 10.7793137],
                [107.4775904, 10.779359],
                [107.4776306, 10.7794837],
                [107.4776389, 10.7796108],
                [107.4776173, 10.7797328],
                [107.4775749, 10.779871],
                [107.4775475, 10.7800335],
                [107.4775405, 10.7802304],
                [107.4775475, 10.7803718],
                [107.4775261, 10.7804551],
                [107.477475, 10.7805305],
                [107.4772257, 10.7807344],
                [107.4772135, 10.7807941],
                [107.4771703, 10.780908],
                [107.4771001, 10.7810151],
                [107.4770542, 10.7810643],
                [107.4769467, 10.7811455],
                [107.476851, 10.7811942],
                [107.4767459, 10.7812176],
                [107.4766595, 10.781217],
                [107.4765974, 10.7812346],
                [107.4765485, 10.7812804],
                [107.4765335, 10.781312],
                [107.4765272, 10.7813464],
                [107.4765417, 10.7814142],
                [107.4767858, 10.7820202],
                [107.4768287, 10.782895],
                [107.4766409, 10.7830531],
                [107.476362, 10.7835432],
                [107.4762748, 10.7836503],
                [107.4761769, 10.7837284],
                [107.4760777, 10.7837803],
                [107.4757153, 10.7839003],
                [107.4756445, 10.7839484],
                [107.4755955, 10.7840142],
                [107.4755803, 10.7840506],
                [107.4755681, 10.7841281],
                [107.4755758, 10.7842281],
                [107.4755681, 10.7844285],
                [107.4755292, 10.7846271],
                [107.4754983, 10.7847236],
                [107.4752945, 10.7851557],
                [107.4752683, 10.7852705],
                [107.4752355, 10.7855035],
                [107.4752283, 10.7856404],
                [107.4752369, 10.7859159],
                [107.4752271, 10.7859846],
                [107.4751944, 10.7860884],
                [107.475139, 10.7861935],
                [107.4750414, 10.7863185],
                [107.4749153, 10.7864244],
                [107.474842, 10.7864683],
                [107.4747642, 10.786504],
                [107.4746829, 10.7865311],
                [107.4746298, 10.7865585],
                [107.4745816, 10.7865937],
                [107.4745396, 10.7866359],
                [107.4745049, 10.786684],
                [107.4744782, 10.7867369],
                [107.4744603, 10.7867932],
                [107.4744516, 10.7868515],
                [107.4744523, 10.7869105],
                [107.4744619, 10.7869672],
                [107.4745069, 10.7870731],
                [107.4745823, 10.7871607],
                [107.4748868, 10.7873637],
                [107.474969, 10.7874679],
                [107.4749995, 10.7875268],
                [107.475037, 10.7876535],
                [107.4750431, 10.7877143],
                [107.4750337, 10.7878361],
                [107.4749961, 10.7879524],
                [107.474914, 10.7881029],
                [107.4748692, 10.7882032],
                [107.4748331, 10.7883069],
                [107.4748062, 10.7884125],
                [107.4747882, 10.7885199],
                [107.4747689, 10.7887215],
                [107.4747491, 10.7888131],
                [107.4747205, 10.7889024],
                [107.4746903, 10.7889736],
                [107.4746132, 10.7891079],
                [107.4743771, 10.7894346],
                [107.4743178, 10.7895317],
                [107.4742162, 10.789735],
                [107.4741308, 10.7899744],
                [107.4740998, 10.7900976],
                [107.4740569, 10.7903353],
                [107.4739968, 10.7905411],
                [107.4739587, 10.7906335],
                [107.4737987, 10.7908967],
                [107.4737556, 10.7910094],
                [107.4737442, 10.7910682],
                [107.4737227, 10.791358],
                [107.4734116, 10.7915635],
                [107.4729642, 10.7920292],
                [107.4728396, 10.7921254],
                [107.47269, 10.7922038],
                [107.472623, 10.7922618],
                [107.4725157, 10.7924251],
                [107.472454, 10.7925391],
                [107.4722956, 10.7929142],
                [107.4722276, 10.7930461],
                [107.4721509, 10.7931734],
                [107.4719876, 10.7933945],
                [107.471842, 10.7935567],
                [107.4717835, 10.7936118],
                [107.4716547, 10.7937082],
                [107.4714884, 10.7937952],
                [107.4714304, 10.7938064],
                [107.4713125, 10.7938038],
                [107.4712551, 10.7937899],
                [107.471176, 10.7937553],
                [107.471093, 10.7937038],
                [107.4709895, 10.7936687],
                [107.4708726, 10.7936651],
                [107.4707664, 10.7936854],
                [107.4706677, 10.7936792],
                [107.4705762, 10.7936423],
                [107.4704449, 10.793549],
                [107.4703485, 10.7934922],
                [107.4702474, 10.7934433],
                [107.4701426, 10.7934028],
                [107.4695116, 10.7932261],
                [107.4681973, 10.7928309],
                [107.4678259, 10.7926794],
                [107.467398, 10.792599],
                [107.4659538, 10.7920994],
                [107.4656895, 10.7920325],
                [107.465439, 10.7919892],
                [107.4653126, 10.7919746],
                [107.4629858, 10.7918718],
                [107.4626881, 10.7918007],
                [107.4623689, 10.7917849],
                [107.4621697, 10.7917508],
                [107.4618112, 10.7917087],
                [107.4599844, 10.7915978],
                [107.4592978, 10.7914661],
                [107.4575651, 10.7913554],
                [107.4574949, 10.7913673],
                [107.457436, 10.7914065],
                [107.4573988, 10.7914661],
                [107.4573895, 10.7915319],
                [107.4574073, 10.791596],
                [107.4574256, 10.7916241],
                [107.4578494, 10.7920694],
                [107.4578758, 10.7921053],
                [107.4579138, 10.7921853],
                [107.4579244, 10.7922267],
                [107.4579298, 10.7923118],
                [107.457903, 10.7926754],
                [107.4579138, 10.793284],
                [107.4580053, 10.7933944],
                [107.458074, 10.7935198],
                [107.4581176, 10.7936555],
                [107.4581346, 10.7938028],
                [107.4581319, 10.793877],
                [107.4581045, 10.7940227],
                [107.4577475, 10.7951521],
                [107.4577201, 10.7952558],
                [107.4577021, 10.7953614],
                [107.4576939, 10.7955476],
                [107.4577099, 10.7957054],
                [107.4577432, 10.7958698],
                [107.457749, 10.7959535],
                [107.4577384, 10.796122],
                [107.4577219, 10.7962055],
                [107.457667, 10.7963667],
                [107.4573062, 10.7970096],
                [107.4568301, 10.7975075],
                [107.4564962, 10.7979673],
                [107.4563862, 10.7983862],
                [107.4562455, 10.7987826],
                [107.4562027, 10.7988617],
                [107.4561728, 10.7988957],
                [107.4561011, 10.798948],
                [107.4560189, 10.7989775],
                [107.4558844, 10.7989848],
                [107.4557921, 10.799004],
                [107.4557403, 10.7990248],
                [107.4556473, 10.7990857],
                [107.4556073, 10.7991281],
                [107.45554, 10.7992227],
                [107.455481, 10.7993597],
                [107.4552771, 10.7997286],
                [107.4549955, 10.8005954],
                [107.4543706, 10.801478],
                [107.4543181, 10.8015816],
                [107.4542777, 10.8017143],
                [107.4542659, 10.8017942],
                [107.454218, 10.8023252],
                [107.4541838, 10.8023941],
                [107.4541576, 10.8024234],
                [107.454046, 10.8025161],
                [107.4540084, 10.8025649],
                [107.453979, 10.8026188],
                [107.4539065, 10.8028085],
                [107.4538866, 10.8028433],
                [107.4538605, 10.802874],
                [107.4537939, 10.8029192],
                [107.4537086, 10.8029386],
                [107.4536222, 10.8029245],
                [107.4533594, 10.8028454],
                [107.4532521, 10.8028444],
                [107.4531501, 10.802877],
                [107.4531047, 10.8029049],
                [107.4530302, 10.8029801],
                [107.452937, 10.8031602],
                [107.4528712, 10.8032327],
                [107.452792, 10.8032871],
                [107.4526191, 10.8033565],
                [107.4525547, 10.8034092],
                [107.4523635, 10.8037347],
                [107.4523074, 10.8037869],
                [107.4522355, 10.803815],
                [107.452161, 10.8038153],
                [107.4521249, 10.8038055],
                [107.4520612, 10.8037676],
                [107.4519163, 10.8035937],
                [107.4518881, 10.8035693],
                [107.4518196, 10.8035397],
                [107.4517822, 10.8035357],
                [107.4517469, 10.8035388],
                [107.4516814, 10.8035643],
                [107.4513745, 10.8037913],
                [107.4513396, 10.80381],
                [107.4512633, 10.8038308],
                [107.4511841, 10.8038282],
                [107.4511119, 10.8038036],
                [107.45105, 10.8037597],
                [107.4509179, 10.8035961],
                [107.4508597, 10.8035514],
                [107.4507893, 10.8035293],
                [107.4507134, 10.8035332],
                [107.4506423, 10.8035647],
                [107.4505028, 10.8036543],
                [107.4502849, 10.8037503],
                [107.4502478, 10.8037743],
                [107.4502158, 10.8038044],
                [107.4501756, 10.8038677],
                [107.4501675, 10.8039704],
                [107.4501565, 10.8040116],
                [107.4501112, 10.8040837],
                [107.4500619, 10.8041226],
                [107.4499471, 10.8041685],
                [107.4499074, 10.8041917],
                [107.449829, 10.80426],
                [107.4497692, 10.8043379],
                [107.4497476, 10.8043477],
                [107.4497008, 10.8043445],
                [107.4496655, 10.8043131],
                [107.4496577, 10.8042906],
                [107.4496575, 10.8042239],
                [107.4496458, 10.8041493],
                [107.4496203, 10.8040873],
                [107.4495975, 10.804061],
                [107.449537, 10.8040267],
                [107.449466, 10.8040223],
                [107.449431, 10.8040325],
                [107.4493732, 10.8040752],
                [107.4493534, 10.8041053],
                [107.4492583, 10.8043261],
                [107.4492371, 10.804353],
                [107.4491791, 10.8043895],
                [107.4491107, 10.8043972],
                [107.4490542, 10.8043795],
                [107.4490088, 10.8043419],
                [107.448931, 10.8042392],
                [107.4489051, 10.8042245],
                [107.4488463, 10.8042169],
                [107.4487916, 10.8042392],
                [107.4487571, 10.8042816],
                [107.4487486, 10.8043077],
                [107.448747, 10.8043895],
                [107.4487197, 10.8044605],
                [107.4486994, 10.8044895],
                [107.4486467, 10.8045369],
                [107.4485891, 10.804561],
                [107.4485273, 10.8045512],
                [107.4485009, 10.8045342],
                [107.4484674, 10.8044821],
                [107.4484629, 10.8044513],
                [107.4484804, 10.804392],
                [107.4486308, 10.804222],
                [107.4486391, 10.8041983],
                [107.4486377, 10.8041495],
                [107.4485609, 10.8040284],
                [107.4485441, 10.8039553],
                [107.4485448, 10.8039177],
                [107.4485271, 10.8038839],
                [107.4484722, 10.8038307],
                [107.4484, 10.8038044],
                [107.4483293, 10.803808],
                [107.4482959, 10.8038197],
                [107.4482389, 10.8038609],
                [107.4480057, 10.804197],
                [107.4479707, 10.8042252],
                [107.4478909, 10.8042664],
                [107.4478011, 10.8042851],
                [107.4477543, 10.8042851],
                [107.4476489, 10.804265],
                [107.4475363, 10.8042207],
                [107.4473565, 10.8041024],
                [107.4472842, 10.804089],
                [107.4472442, 10.8040934],
                [107.4471715, 10.8041259],
                [107.4470159, 10.804276],
                [107.4469716, 10.8043068],
                [107.4469229, 10.8043306],
                [107.4468175, 10.8043551],
                [107.4467233, 10.8043501],
                [107.4466337, 10.8043208],
                [107.4464583, 10.8041918],
                [107.4463923, 10.8041707],
                [107.4463575, 10.8041733],
                [107.4463071, 10.8041949],
                [107.4462218, 10.8042858],
                [107.4461324, 10.8043385],
                [107.4460272, 10.804371],
                [107.4459436, 10.8043806],
                [107.445915, 10.8043757],
                [107.4458653, 10.8043472],
                [107.4458358, 10.8043038],
                [107.4457959, 10.8040019],
                [107.445783, 10.8039725],
                [107.4457371, 10.8039274],
                [107.4457071, 10.8039148],
                [107.4456452, 10.8039124],
                [107.4455914, 10.8039367],
                [107.4455194, 10.8040109],
                [107.4454749, 10.8040696],
                [107.4454066, 10.8041996],
                [107.4453916, 10.8042569],
                [107.4453986, 10.8043156],
                [107.4454125, 10.8043473],
                [107.445433, 10.8043754],
                [107.4454898, 10.8044157],
                [107.4455692, 10.8045207],
                [107.4455986, 10.8045795],
                [107.4456346, 10.8047055],
                [107.4456402, 10.8048145],
                [107.4456346, 10.8048688],
                [107.445623, 10.8048928],
                [107.4456059, 10.8049134],
                [107.4455595, 10.80494],
                [107.445508, 10.8049429],
                [107.4454831, 10.8049357],
                [107.4454415, 10.8049057],
                [107.445353, 10.8047863],
                [107.4452452, 10.8046799],
                [107.4451201, 10.8045901],
                [107.4450526, 10.8045527],
                [107.4446344, 10.8043847],
                [107.4445678, 10.8043807],
                [107.4444826, 10.8043982],
                [107.4444303, 10.8043867],
                [107.4444032, 10.8043695],
                [107.4443671, 10.8043176],
                [107.4442559, 10.8037886],
                [107.4442131, 10.8037128],
                [107.4441809, 10.8036827],
                [107.4441433, 10.8036595],
                [107.4440751, 10.8036391],
                [107.4436927, 10.8036358],
                [107.4436155, 10.8036057],
                [107.4435837, 10.803579],
                [107.4435413, 10.803509],
                [107.4435331, 10.803428],
                [107.4435425, 10.8033882],
                [107.4436498, 10.8032037],
                [107.4436999, 10.8031399],
                [107.4437156, 10.8031025],
                [107.4437255, 10.8030225],
                [107.4437052, 10.8029415],
                [107.4436543, 10.8028712],
                [107.4436196, 10.8028445],
                [107.4435804, 10.8028247],
                [107.4434942, 10.8028085],
                [107.443429, 10.8027822],
                [107.4433679, 10.8027477],
                [107.4432764, 10.8026727],
                [107.4430865, 10.8024476],
                [107.4430247, 10.8023611],
                [107.4429685, 10.8022711],
                [107.4428719, 10.8020682],
                [107.4428185, 10.8020135],
                [107.4427491, 10.8019804],
                [107.442711, 10.8019733],
                [107.4426665, 10.8019735],
                [107.4425412, 10.8020068],
                [107.4424585, 10.8020009],
                [107.4423821, 10.801969],
                [107.442318, 10.8019119],
                [107.4422762, 10.8018336],
                [107.4422648, 10.8017459],
                [107.4422711, 10.801702],
                [107.4423435, 10.8014385],
                [107.4423296, 10.8013819],
                [107.4422872, 10.801341],
                [107.4422372, 10.8013282],
                [107.4421869, 10.8013396],
                [107.4419305, 10.8015597],
                [107.4418579, 10.8016059],
                [107.4417741, 10.8016267],
                [107.4416879, 10.8016198],
                [107.4416469, 10.8016062],
                [107.4415754, 10.8015611],
                [107.441523, 10.8014976],
                [107.4414932, 10.8014212],
                [107.4414611, 10.8012067],
                [107.4414511, 10.8011761],
                [107.4414157, 10.8011226],
                [107.4413913, 10.8011013],
                [107.4413264, 10.8010708],
                [107.4412907, 10.8010654],
                [107.4412546, 10.801067],
                [107.4410659, 10.8011131],
                [107.4410368, 10.8011158],
                [107.4409837, 10.801103],
                [107.4409434, 10.8010723],
                [107.4409168, 10.8010344],
                [107.4408555, 10.8009789],
                [107.4407541, 10.8009301],
                [107.4406913, 10.8008826],
                [107.4406488, 10.800822],
                [107.4406206, 10.8007548],
                [107.4405706, 10.8007034],
                [107.4405113, 10.8006813],
                [107.4404102, 10.8006821],
                [107.4403453, 10.8006586],
                [107.440296, 10.8006152],
                [107.4402158, 10.8005014],
                [107.4401343, 10.8004325],
                [107.4400878, 10.8004057],
                [107.439994, 10.8003713],
                [107.4398029, 10.8003425],
                [107.4397176, 10.8003056],
                [107.4396422, 10.800248],
                [107.4395218, 10.8000996],
                [107.4393851, 10.7999631],
                [107.4391973, 10.7998208],
                [107.4391033, 10.7997797],
                [107.439002, 10.7997623],
                [107.4389505, 10.7997628],
                [107.4388938, 10.7997707],
                [107.4387863, 10.7998086],
                [107.438693, 10.7998735],
                [107.4385053, 10.8000737],
                [107.4384843, 10.8001067],
                [107.4384229, 10.8001552],
                [107.4383855, 10.8001682],
                [107.4383068, 10.8001686],
                [107.4382428, 10.8001408],
                [107.4382163, 10.8001181],
                [107.4381792, 10.8000599],
                [107.4381701, 10.8000265],
                [107.4381727, 10.7999578],
                [107.438141, 10.7993452],
                [107.438132, 10.7992919],
                [107.4381138, 10.7992409],
                [107.4380869, 10.7991937],
                [107.4380255, 10.7991278],
                [107.4379476, 10.7990817],
                [107.4376255, 10.798983],
                [107.4374188, 10.7988921],
                [107.4372232, 10.7987801],
                [107.4371771, 10.7987307],
                [107.4371641, 10.7986995],
                [107.4371615, 10.7986325],
                [107.4371836, 10.7985803],
                [107.437202, 10.7985584],
                [107.4372244, 10.7985404],
                [107.4374875, 10.7984089],
                [107.4375414, 10.7983521],
                [107.4375725, 10.7982806],
                [107.4375772, 10.7982031],
                [107.4375683, 10.7981612],
                [107.4375514, 10.7981217],
                [107.437468, 10.7980177],
                [107.4374446, 10.7979764],
                [107.4374159, 10.7978865],
                [107.4374109, 10.7978395],
                [107.4374241, 10.7977323],
                [107.4374431, 10.7976814],
                [107.4374849, 10.797604],
                [107.4374967, 10.7975617],
                [107.4375012, 10.797475],
                [107.4374807, 10.7973916],
                [107.43725, 10.7970623],
                [107.4372089, 10.7970275],
                [107.4371157, 10.7969731],
                [107.4370649, 10.7969542],
                [107.4369726, 10.7969356],
                [107.4368849, 10.7969329],
                [107.4368061, 10.7969143],
                [107.4367449, 10.7968793],
                [107.4367255, 10.7968563],
                [107.4367093, 10.7967994],
                [107.4367136, 10.7967698],
                [107.4367385, 10.7967264],
                [107.4367806, 10.7966987],
                [107.4368812, 10.7966786],
                [107.436921, 10.796649],
                [107.4369344, 10.7966281],
                [107.4369442, 10.7965801],
                [107.4369306, 10.7965337],
                [107.4369158, 10.7965141],
                [107.4368746, 10.7964879],
                [107.4365723, 10.7964279],
                [107.4364921, 10.7964047],
                [107.4363407, 10.7963351],
                [107.4362793, 10.7962952],
                [107.4362233, 10.7962482],
                [107.4361737, 10.7961946],
                [107.4361313, 10.7961355],
                [107.436073, 10.7960118],
                [107.4360463, 10.7958863],
                [107.4360484, 10.7957581],
                [107.4363086, 10.7950098],
                [107.4363165, 10.7949332],
                [107.4362963, 10.7948587],
                [107.43625, 10.7947955],
                [107.436183, 10.7947523],
                [107.4361047, 10.7947358],
                [107.4358856, 10.7947336],
                [107.4358172, 10.794708],
                [107.4357657, 10.7946601],
                [107.4357086, 10.7945271],
                [107.4356595, 10.794467],
                [107.4355909, 10.7944227],
                [107.4353859, 10.7943564],
                [107.4352705, 10.7942958],
                [107.4351633, 10.7942036],
                [107.4350935, 10.7941219],
                [107.4350023, 10.7940718],
                [107.4349781, 10.794049],
                [107.4349487, 10.7939902],
                [107.4349477, 10.7939326],
                [107.4349701, 10.7938795],
                [107.4351847, 10.7935633],
                [107.4352867, 10.7935081],
                [107.4353993, 10.793479],
                [107.4355134, 10.7934775],
                [107.4356546, 10.7935109],
                [107.4356858, 10.7935109],
                [107.4357426, 10.7934869],
                [107.4357758, 10.7934446],
                [107.4357838, 10.7934189],
                [107.4358016, 10.7931075],
                [107.4357968, 10.7930468],
                [107.4357614, 10.7929304],
                [107.4357315, 10.792877],
                [107.4356562, 10.79279],
                [107.435567, 10.792729],
                [107.4355173, 10.792707],
                [107.435383, 10.7926694],
                [107.4352437, 10.7926596],
                [107.4351651, 10.7926666],
                [107.4350879, 10.7926824],
                [107.4349476, 10.7927369],
                [107.4348852, 10.7927725],
                [107.4347717, 10.7928598],
                [107.4346748, 10.7929679],
                [107.4345841, 10.7931198],
                [107.4345346, 10.7931613],
                [107.4345038, 10.7931724],
                [107.434442, 10.7931727],
                [107.4343888, 10.7931486],
                [107.4343673, 10.7931287],
                [107.4342543, 10.7929489],
                [107.4341708, 10.7928651],
                [107.4340635, 10.7927966],
                [107.4340248, 10.7927615],
                [107.4339923, 10.7927208],
                [107.4339498, 10.7926279],
                [107.4339402, 10.7925279],
                [107.4339486, 10.7924756],
                [107.4339662, 10.7924256],
                [107.4340935, 10.792234],
                [107.4341333, 10.7921168],
                [107.434141, 10.7920665],
                [107.4341464, 10.7918856],
                [107.4341242, 10.7918339],
                [107.4340767, 10.7917987],
                [107.4340153, 10.7917928],
                [107.4338592, 10.7918508],
                [107.4338301, 10.7918553],
                [107.4337722, 10.791845],
                [107.4337252, 10.7918097],
                [107.4337095, 10.7917849],
                [107.4336979, 10.7917335],
                [107.4337347, 10.7915822],
                [107.4337363, 10.7914792],
                [107.4337251, 10.7914377],
                [107.4336934, 10.7914081],
                [107.4336676, 10.7913998],
                [107.4336405, 10.7914005],
                [107.4336151, 10.79141],
                [107.4335944, 10.7914272],
                [107.4334665, 10.791622],
                [107.4334067, 10.791674],
                [107.4333354, 10.7917054],
                [107.4332595, 10.7917147],
                [107.4331569, 10.7916947],
                [107.4330947, 10.7916715],
                [107.4329799, 10.7916057],
                [107.4328794, 10.791513],
                [107.4328378, 10.7914587],
                [107.432768, 10.7913404],
                [107.4327148, 10.7912132],
                [107.4326969, 10.7911466],
                [107.4326789, 10.791008],
                [107.4326865, 10.7908663],
                [107.4327, 10.7907966],
                [107.4327459, 10.7906422],
                [107.4327734, 10.7904648],
                [107.4327698, 10.790315],
                [107.4327369, 10.7902496],
                [107.4327095, 10.7902245],
                [107.4326439, 10.7901974],
                [107.4326096, 10.7901955],
                [107.4325441, 10.7902137],
                [107.4325159, 10.790233],
                [107.4323404, 10.790398],
                [107.4322209, 10.7904806],
                [107.4321047, 10.790539],
                [107.4320105, 10.7905728],
                [107.4319411, 10.790568],
                [107.4318829, 10.7905307],
                [107.431853, 10.7904792],
                [107.4318482, 10.7904202],
                [107.4318818, 10.7903245],
                [107.4318829, 10.7902409],
                [107.4318598, 10.7901759],
                [107.4318159, 10.7901223],
                [107.4317509, 10.7900845],
                [107.4317142, 10.790075],
                [107.431591, 10.7900596],
                [107.4314138, 10.7900537],
                [107.4313282, 10.7900232],
                [107.4312901, 10.7899985],
                [107.4312309, 10.7899368],
                [107.4312089, 10.7899002],
                [107.4311475, 10.7897141],
                [107.431083, 10.7896238],
                [107.4309951, 10.7895558],
                [107.430897, 10.7895141],
                [107.4308533, 10.7894836],
                [107.4307809, 10.7894062],
                [107.4307335, 10.7893133],
                [107.4307138, 10.7892123],
                [107.4307147, 10.7891608],
                [107.4307846, 10.7888675],
                [107.4308005, 10.7886696],
                [107.4307966, 10.7885704],
                [107.4307692, 10.7883911],
                [107.4307458, 10.7883033],
                [107.430704, 10.7881869],
                [107.4306604, 10.7881381],
                [107.4305992, 10.7881134],
                [107.4305659, 10.788112],
                [107.4305041, 10.7881305],
                [107.4304566, 10.7881737],
                [107.4304414, 10.7882019],
                [107.4304318, 10.7882648],
                [107.4304011, 10.7883613],
                [107.4303617, 10.7884546],
                [107.4303138, 10.7885441],
                [107.4302117, 10.7886883],
                [107.4300879, 10.788815],
                [107.4299744, 10.7889065],
                [107.4299248, 10.7889347],
                [107.4298711, 10.7889546],
                [107.4298149, 10.7889656],
                [107.4297161, 10.7889633],
                [107.4296218, 10.788934],
                [107.4293349, 10.7887172],
                [107.4292799, 10.788662],
                [107.4292322, 10.7886004],
                [107.4291645, 10.7884695],
                [107.4291307, 10.7883343],
                [107.4291252, 10.7881981],
                [107.4291015, 10.7881357],
                [107.4290468, 10.7880774],
                [107.4290114, 10.7880579],
                [107.4288168, 10.7880191],
                [107.4287728, 10.7879975],
                [107.4287555, 10.7879802],
                [107.4287341, 10.7879361],
                [107.4287206, 10.7877141],
                [107.4286886, 10.7876561],
                [107.4286639, 10.7876335],
                [107.4286019, 10.7876062],
                [107.4285331, 10.7876075],
                [107.4285006, 10.7876193],
                [107.4282988, 10.7877369],
                [107.4282482, 10.7877512],
                [107.428197, 10.7877391],
                [107.4281587, 10.7877036],
                [107.4281435, 10.7876577],
                [107.4281447, 10.7876335],
                [107.4281642, 10.7875892],
                [107.4282193, 10.7875427],
                [107.4283439, 10.7874604],
                [107.4284041, 10.7873795],
                [107.4284171, 10.787333],
                [107.4284127, 10.787309],
                [107.4283863, 10.7872714],
                [107.4283463, 10.7872538],
                [107.4283028, 10.7872584],
                [107.4280635, 10.7874079],
                [107.4279471, 10.7874424],
                [107.4278864, 10.787448],
                [107.4277878, 10.7874399],
                [107.4276392, 10.7873898],
                [107.427526, 10.7873633],
                [107.4274103, 10.7873591],
                [107.4272963, 10.7873768],
                [107.4272132, 10.7874058],
                [107.4271368, 10.7874419],
                [107.4270523, 10.787448],
                [107.4270065, 10.787438],
                [107.426936, 10.7874011],
                [107.4268734, 10.7873797],
                [107.4268035, 10.7873799],
                [107.4267679, 10.7873895],
                [107.4267048, 10.7874268],
                [107.4264448, 10.7876813],
                [107.4263783, 10.7877165],
                [107.4263028, 10.7877188],
                [107.4262667, 10.7877072],
                [107.4262342, 10.7876878],
                [107.4261892, 10.7876346],
                [107.426171, 10.7875679],
                [107.4261731, 10.7875332],
                [107.4262521, 10.7873282],
                [107.4262818, 10.7872092],
                [107.426321, 10.7871227],
                [107.4263866, 10.7870474],
                [107.4264289, 10.7870164],
                [107.4264756, 10.7869922],
                [107.4266051, 10.7869631],
                [107.426698, 10.7869192],
                [107.4267252, 10.786895],
                [107.4267597, 10.7868319],
                [107.4267653, 10.7867963],
                [107.4267529, 10.7867286],
                [107.4267147, 10.7866743],
                [107.4266879, 10.7866541],
                [107.4266247, 10.7866321],
                [107.4265909, 10.7866312],
                [107.4264826, 10.7866476],
                [107.426375, 10.7866844],
                [107.4262726, 10.7867426],
                [107.4261962, 10.7868042],
                [107.4261244, 10.7868358],
                [107.4260457, 10.7868385],
                [107.4260075, 10.7868286],
                [107.4259402, 10.7867883],
                [107.4259138, 10.7867594],
                [107.4258807, 10.7866913],
                [107.4258753, 10.7866179],
                [107.4258829, 10.7865817],
                [107.4259177, 10.7865164],
                [107.4260105, 10.7864393],
                [107.4260415, 10.7864049],
                [107.4260828, 10.7863231],
                [107.426092, 10.7862781],
                [107.4260861, 10.7861875],
                [107.4260491, 10.7861042],
                [107.4260062, 10.7859461],
                [107.4259852, 10.7857252],
                [107.4259544, 10.785676],
                [107.4259312, 10.785658],
                [107.4258738, 10.7856399],
                [107.4258132, 10.7856505],
                [107.4257282, 10.7857088],
                [107.4256879, 10.7857214],
                [107.4256458, 10.785725],
                [107.4255689, 10.7857075],
                [107.425509, 10.785665],
                [107.4254864, 10.7856362],
                [107.4254477, 10.7855619],
                [107.4254274, 10.7854705],
                [107.4254404, 10.7853779],
                [107.4254592, 10.7853346],
                [107.425518, 10.7852611],
                [107.4256198, 10.7851823],
                [107.4256971, 10.7850868],
                [107.4257487, 10.7849818],
                [107.425782, 10.7848741],
                [107.4258392, 10.7847764],
                [107.4259175, 10.7846938],
                [107.4259633, 10.7846597],
                [107.426121, 10.7845809],
                [107.426157, 10.7845312],
                [107.4261646, 10.7845014],
                [107.4261586, 10.7844461],
                [107.4261326, 10.7844031],
                [107.4261135, 10.7843863],
                [107.4260236, 10.7843566],
                [107.4259767, 10.7843494],
                [107.4258655, 10.7843535],
                [107.4256552, 10.7844108],
                [107.4254429, 10.784439],
                [107.425223, 10.784439],
                [107.4251615, 10.7844215],
                [107.425135, 10.7844035],
                [107.4250967, 10.784353],
                [107.4250835, 10.7842915],
                [107.4250972, 10.7842314],
                [107.4251346, 10.7841822],
                [107.4251894, 10.7841524],
                [107.4252203, 10.7841466],
                [107.4254322, 10.7841387],
                [107.4254618, 10.7841246],
                [107.4255092, 10.7840801],
                [107.4255248, 10.7840517],
                [107.4255368, 10.7839885],
                [107.4255204, 10.7839231],
                [107.4254775, 10.7838707],
                [107.4254161, 10.7838409],
                [107.4252176, 10.7838067],
                [107.4251826, 10.7837945],
                [107.4251252, 10.7837487],
                [107.4251059, 10.7837175],
                [107.4250942, 10.7836828],
                [107.4250948, 10.7836151],
                [107.4251237, 10.7835537],
                [107.4251715, 10.7835042],
                [107.4251962, 10.7834404],
                [107.4251929, 10.7833688],
                [107.4251801, 10.783335],
                [107.4250486, 10.7832244],
                [107.4249761, 10.783175],
                [107.4248215, 10.7830918],
                [107.4246169, 10.7830065],
                [107.4244966, 10.7829481],
                [107.4242668, 10.7828123],
                [107.4241579, 10.7827353],
                [107.4240535, 10.7826526],
                [107.4238727, 10.7824847],
                [107.4236269, 10.7822238],
                [107.423594, 10.7821782],
                [107.423549, 10.7820758],
                [107.4235342, 10.7819653],
                [107.4235513, 10.7818532],
                [107.4235721, 10.7817991],
                [107.4236362, 10.7817025],
                [107.423678, 10.7816619],
                [107.4240574, 10.7814197],
                [107.4241009, 10.7813609],
                [107.4241115, 10.7813258],
                [107.4241079, 10.7812531],
                [107.4240733, 10.7811903],
                [107.4240159, 10.7811486],
                [107.4239817, 10.7811379],
                [107.4239103, 10.7811389],
                [107.4236142, 10.7812589],
                [107.4235745, 10.7812667],
                [107.4234947, 10.7812572],
                [107.4234581, 10.7812403],
                [107.4234038, 10.7811915],
                [107.4233848, 10.7811603],
                [107.4233669, 10.7810901],
                [107.4233668, 10.7809825],
                [107.423391, 10.7807687],
                [107.4234255, 10.7806281],
                [107.4234505, 10.7804856],
                [107.4234661, 10.7803418],
                [107.423472, 10.7802115],
                [107.4234706, 10.7800585],
                [107.4234648, 10.7800368],
                [107.4234366, 10.7800019],
                [107.4233966, 10.7799869],
                [107.4233749, 10.7799874],
                [107.4232293, 10.7800654],
                [107.4230832, 10.7801846],
                [107.4230152, 10.7802097],
                [107.4229426, 10.7802065],
                [107.4229082, 10.7801943],
                [107.4228531, 10.7801541],
                [107.4228323, 10.7801271],
                [107.4228011, 10.7800395],
                [107.4227887, 10.7798925],
                [107.4227978, 10.7797863],
                [107.4228541, 10.7796129],
                [107.4228527, 10.7795553],
                [107.4228439, 10.7795276],
                [107.4228053, 10.7794738],
                [107.4227473, 10.7794407],
                [107.4225676, 10.7794328],
                [107.4225281, 10.7794284],
                [107.4224907, 10.779415],
                [107.4224342, 10.7793691],
                [107.4224045, 10.7793094],
                [107.4223798, 10.7783209],
                [107.4223648, 10.7782366],
                [107.4223462, 10.7781978],
                [107.4222892, 10.7781325],
                [107.4222121, 10.7780908],
                [107.422125, 10.7780785],
                [107.4220052, 10.7780958],
                [107.4219641, 10.778089],
                [107.4219266, 10.7780574],
                [107.4219051, 10.7779441],
                [107.4218901, 10.7779175],
                [107.4218423, 10.7778798],
                [107.4217817, 10.7778703],
                [107.4217492, 10.7778782],
                [107.4217202, 10.7778947],
                [107.4216969, 10.7779184],
                [107.4216813, 10.7779475],
                [107.4216745, 10.7779798],
                [107.4216932, 10.7781233],
                [107.4216986, 10.778226],
                [107.4216777, 10.7782916],
                [107.4216561, 10.7783189],
                [107.4215966, 10.7783551],
                [107.4215326, 10.7783614],
                [107.4215011, 10.7783542],
                [107.4214719, 10.7783405],
                [107.4214099, 10.7782882],
                [107.4213792, 10.7782501],
                [107.4213406, 10.7781685],
                [107.4213284, 10.7780864],
                [107.4213576, 10.777844],
                [107.4213557, 10.7778096],
                [107.4213322, 10.7777449],
                [107.4212855, 10.7776938],
                [107.4212195, 10.7776631],
                [107.4211465, 10.7776605],
                [107.4211112, 10.7776701],
                [107.420885, 10.7777837],
                [107.4207658, 10.7778228],
                [107.4207008, 10.7778361],
                [107.42064, 10.7778296],
                [107.4206122, 10.7778165],
                [107.4205691, 10.7777738],
                [107.4205559, 10.7777465],
                [107.4205504, 10.7776825],
                [107.4205595, 10.7776514],
                [107.4205987, 10.7776],
                [107.4206266, 10.7775827],
                [107.4213188, 10.7773243],
                [107.4214181, 10.777269],
                [107.4214969, 10.7771879],
                [107.4215484, 10.7770877],
                [107.4215628, 10.7770308],
                [107.4215682, 10.7769724],
                [107.4215645, 10.7769138],
                [107.4215455, 10.776836],
                [107.4215279, 10.7767998],
                [107.4214715, 10.7767297],
                [107.4214316, 10.7766994],
                [107.4213391, 10.7766609],
                [107.4209968, 10.7765611],
                [107.4209316, 10.7765232],
                [107.4209049, 10.7764966],
                [107.4208673, 10.7764325],
                [107.4208537, 10.7763604],
                [107.4208564, 10.7763236],
                [107.4209529, 10.7761866],
                [107.4209601, 10.7761583],
                [107.4209517, 10.7761011],
                [107.4209139, 10.7760539],
                [107.4208543, 10.776032],
                [107.4208223, 10.7760339],
                [107.4206042, 10.7761339],
                [107.42051, 10.7761633],
                [107.4204111, 10.7761603],
                [107.420324, 10.7761279],
                [107.4202516, 10.7760703],
                [107.4202233, 10.7760338],
                [107.4199761, 10.7756244],
                [107.4198954, 10.7755107],
                [107.4197946, 10.775411],
                [107.4197372, 10.7753674],
                [107.4196761, 10.775329],
                [107.4195654, 10.7752773],
                [107.4194829, 10.7752215],
                [107.4194198, 10.7751451],
                [107.4193815, 10.7750551],
                [107.4193702, 10.7749592],
                [107.419375, 10.774911],
                [107.4194568, 10.7745252],
                [107.4194568, 10.77447],
                [107.4194476, 10.7744155],
                [107.4194072, 10.7743216],
                [107.4193462, 10.7742493],
                [107.4192672, 10.7741966],
                [107.4191958, 10.7741639],
                [107.4191732, 10.7741429],
                [107.4191471, 10.7740881],
                [107.4191508, 10.7740278],
                [107.4191831, 10.7739767],
                [107.4192078, 10.7739586],
                [107.4193204, 10.7739277],
                [107.4193962, 10.7738874],
                [107.4194584, 10.7738289],
                [107.4195477, 10.7737039],
                [107.4196328, 10.773632],
                [107.4197345, 10.7735825],
                [107.4198467, 10.7735593],
                [107.4199042, 10.7735583],
                [107.4199814, 10.773529],
                [107.4200461, 10.7734783],
                [107.4200955, 10.7734039],
                [107.4201181, 10.77331],
                [107.4201161, 10.7732616],
                [107.420054, 10.7730323],
                [107.4200281, 10.7728451],
                [107.4200249, 10.7727507],
                [107.4200424, 10.7725319],
                [107.4200643, 10.7724242],
                [107.4201074, 10.7722816],
                [107.4201139, 10.7722042],
                [107.4200946, 10.7721289],
                [107.4200467, 10.7720585],
                [107.4199701, 10.7720062],
                [107.4199256, 10.7719918],
                [107.4196887, 10.7719733],
                [107.4196014, 10.771936],
                [107.4195635, 10.7719075],
                [107.419508, 10.7718409],
                [107.4194884, 10.7718023],
                [107.4194748, 10.7717613],
                [107.419467, 10.7716756],
                [107.4194545, 10.7716303],
                [107.419414, 10.7715454],
                [107.4193865, 10.771507],
                [107.4192148, 10.7713383],
                [107.4190497, 10.7711264],
                [107.418992, 10.7710899],
                [107.4189581, 10.771083],
                [107.4188905, 10.7710943],
                [107.4188375, 10.7711343],
                [107.4188202, 10.7711615],
                [107.4188067, 10.7712239],
                [107.4188113, 10.7712557],
                [107.4188628, 10.7713673],
                [107.4188737, 10.7714113],
                [107.4188769, 10.7715017],
                [107.4188552, 10.7715892],
                [107.4188104, 10.7716679],
                [107.4185112, 10.7720558],
                [107.4184585, 10.7721499],
                [107.4184389, 10.7722011],
                [107.4184155, 10.772308],
                [107.4184003, 10.7723359],
                [107.4183536, 10.7723791],
                [107.4182916, 10.7723992],
                [107.4182256, 10.7723911],
                [107.4179995, 10.772269],
                [107.4179403, 10.7722461],
                [107.4178189, 10.7722181],
                [107.4177579, 10.7722128],
                [107.417407, 10.7722131],
                [107.4169066, 10.7721802],
                [107.4168425, 10.7722003],
                [107.4168143, 10.7722184],
                [107.4167714, 10.7722654],
                [107.4167472, 10.7723238],
                [107.4167177, 10.7725056],
                [107.4166888, 10.772561],
                [107.4166399, 10.7726005],
                [107.4166138, 10.7726111],
                [107.4165579, 10.7726178],
                [107.4164681, 10.7725994],
                [107.4163529, 10.7725478],
                [107.4163041, 10.7725133],
                [107.4162, 10.7724045],
                [107.4161736, 10.7723868],
                [107.4161118, 10.7723716],
                [107.4160498, 10.7723857],
                [107.4160035, 10.772423],
                [107.415978, 10.7724762],
                [107.4159697, 10.7727649],
                [107.4159509, 10.7728162],
                [107.4159104, 10.7728534],
                [107.4158496, 10.7728684],
                [107.4157897, 10.7728508],
                [107.415708, 10.7727891],
                [107.4156521, 10.7727312],
                [107.4155987, 10.7727068],
                [107.4155402, 10.7727138],
                [107.4154967, 10.772747],
                [107.4154758, 10.7727971],
                [107.4154758, 10.773333],
                [107.4154632, 10.7733955],
                [107.415428, 10.773449],
                [107.4153704, 10.7734882],
                [107.4153342, 10.7734989],
                [107.4152591, 10.7734964],
                [107.4150556, 10.7734051],
                [107.4149391, 10.7733329],
                [107.4148824, 10.7732653],
                [107.4148549, 10.773182],
                [107.4148607, 10.7730944],
                [107.414876, 10.773053],
                [107.4149287, 10.7729821],
                [107.4150044, 10.7729353],
                [107.4150476, 10.7729232],
                [107.4151227, 10.7729152],
                [107.4151786, 10.7728911],
                [107.4152261, 10.7728425],
                [107.4152425, 10.7728085],
                [107.4152729, 10.7725718],
                [107.4152762, 10.7723312],
                [107.4152479, 10.7720524],
                [107.4152292, 10.7719451],
                [107.4152011, 10.7718399],
                [107.4151638, 10.7717374],
                [107.4151176, 10.7716385],
                [107.4150628, 10.7715439],
                [107.4149551, 10.7713994],
                [107.4148936, 10.7713331],
                [107.414734, 10.7711907],
                [107.4146755, 10.7711598],
                [107.4146093, 10.7711553],
                [107.414547, 10.7711781],
                [107.4144989, 10.7712258],
                [107.4144835, 10.771257],
                [107.4144756, 10.7713256],
                [107.4145045, 10.7714249],
                [107.4145313, 10.7714883],
                [107.4146537, 10.7716947],
                [107.4147195, 10.7718521],
                [107.4147544, 10.7719882],
                [107.4147713, 10.7720986],
                [107.4147684, 10.7721399],
                [107.4147564, 10.7721796],
                [107.414736, 10.7722158],
                [107.414708, 10.7722468],
                [107.4146739, 10.7722711],
                [107.414642, 10.7722853],
                [107.4145731, 10.7722958],
                [107.4145049, 10.7722816],
                [107.414352, 10.7721578],
                [107.4143225, 10.7721425],
                [107.4142573, 10.7721311],
                [107.4142242, 10.7721353],
                [107.4141642, 10.7721631],
                [107.4141379, 10.7721875],
                [107.4141177, 10.7722171],
                [107.4140996, 10.7722857],
                [107.4141026, 10.7723212],
                [107.4141367, 10.7724069],
                [107.4142305, 10.7725774],
                [107.4142909, 10.7726602],
                [107.4144517, 10.7728354],
                [107.4144695, 10.7728981],
                [107.4144573, 10.7729621],
                [107.4144151, 10.7730163],
                [107.4143492, 10.7730458],
                [107.4143127, 10.7730476],
                [107.414063, 10.7729938],
                [107.4140294, 10.7729943],
                [107.4139671, 10.7730181],
                [107.4139409, 10.7730412],
                [107.4139094, 10.7731024],
                [107.4139032, 10.7732442],
                [107.4138813, 10.7733132],
                [107.4138385, 10.7733595],
                [107.4137807, 10.7733857],
                [107.4137036, 10.7733889],
                [107.4136659, 10.77338],
                [107.4136305, 10.7733646],
                [107.4135716, 10.7733167],
                [107.4135497, 10.7732857],
                [107.4135275, 10.7732227],
                [107.413532, 10.773161],
                [107.413544, 10.7731305],
                [107.4135622, 10.7731031],
                [107.413714, 10.7729382],
                [107.4137895, 10.7728388],
                [107.4138791, 10.7727008],
                [107.4138983, 10.7726243],
                [107.4138864, 10.7725464],
                [107.4138451, 10.7724788],
                [107.4138152, 10.7724522],
                [107.4137432, 10.7724186],
                [107.4136639, 10.7724143],
                [107.4135888, 10.7724397],
                [107.4135292, 10.7724912],
                [107.4134267, 10.7726922],
                [107.4133676, 10.7728271],
                [107.4133165, 10.7729654],
                [107.413252, 10.7731738],
                [107.4132021, 10.7732248],
                [107.4131343, 10.7732487],
                [107.4130663, 10.7732417],
                [107.413035, 10.7732276],
                [107.412992, 10.7731533],
                [107.4129814, 10.7731116],
                [107.4129821, 10.7730336],
                [107.4130071, 10.7729302],
                [107.4130178, 10.7727966],
                [107.4130023, 10.7726604],
                [107.4129841, 10.7725926],
                [107.4129373, 10.7725599],
                [107.4129091, 10.7725537],
                [107.4128526, 10.7725636],
                [107.4128114, 10.7725968],
                [107.412791, 10.7726453],
                [107.4127962, 10.7727196],
                [107.4128259, 10.7728486],
                [107.4128341, 10.7729533],
                [107.4128288, 10.7730002],
                [107.4127928, 10.7731322],
                [107.412791, 10.773217],
                [107.4128148, 10.7733107],
                [107.4129812, 10.7736385],
                [107.4129797, 10.7736943],
                [107.4129492, 10.7737414],
                [107.4129247, 10.7737577],
                [107.4128669, 10.773768],
                [107.4128124, 10.7737467],
                [107.4127278, 10.7736579],
                [107.4126777, 10.773622],
                [107.4125656, 10.7735701],
                [107.4124939, 10.7735589],
                [107.412458, 10.773566],
                [107.4123963, 10.7736037],
                [107.4123591, 10.773665],
                [107.412353, 10.7736946],
                [107.4123592, 10.7737545],
                [107.4124198, 10.7738656],
                [107.412531, 10.7740332],
                [107.4125419, 10.7740658],
                [107.4125461, 10.7741324],
                [107.4125399, 10.7741645],
                [107.412512, 10.7742236],
                [107.4123993, 10.7743764],
                [107.412373, 10.7744066],
                [107.4123045, 10.7744483],
                [107.4122251, 10.7744599],
                [107.4121477, 10.7744397],
                [107.4121137, 10.7744186],
                [107.4120779, 10.7743506],
                [107.4120507, 10.7742789],
                [107.4120366, 10.7742258],
                [107.4120135, 10.7740699],
                [107.4120014, 10.774049],
                [107.4119655, 10.7740177],
                [107.4119202, 10.7740042],
                [107.411856, 10.7740135],
                [107.411818, 10.7740295],
                [107.4117564, 10.774079],
                [107.4117181, 10.7741445],
                [107.4116953, 10.7742421],
                [107.4116993, 10.7743422],
                [107.4117138, 10.7743984],
                [107.4117681, 10.774501],
                [107.4120158, 10.7747453],
                [107.4120431, 10.7748072],
                [107.4120373, 10.7748744],
                [107.4120215, 10.7749058],
                [107.4119981, 10.7749322],
                [107.4119353, 10.774964],
                [107.4118173, 10.774935],
                [107.4117212, 10.77488],
                [107.4116797, 10.7748435],
                [107.4116436, 10.7748019],
                [107.4114186, 10.7744414],
                [107.4113825, 10.7743982],
                [107.4113398, 10.7743613],
                [107.4112916, 10.7743316],
                [107.4111951, 10.7742989],
                [107.4110931, 10.7742947],
                [107.4107248, 10.774327],
                [107.4106904, 10.7743369],
                [107.4106304, 10.7743747],
                [107.4105889, 10.7744317],
                [107.4105717, 10.7745024],
                [107.4105835, 10.774574],
                [107.4106505, 10.7747875],
                [107.4106434, 10.7748518],
                [107.4106298, 10.7748815],
                [107.4105855, 10.7749294],
                [107.4105567, 10.7749456],
                [107.4104932, 10.7749586],
                [107.4104298, 10.7749456],
                [107.4103436, 10.7748844],
                [107.4102956, 10.7748184],
                [107.4102831, 10.7747796],
                [107.4102826, 10.7747031],
                [107.4103426, 10.7745291],
                [107.4103636, 10.7744234],
                [107.410348, 10.7743554],
                [107.4103014, 10.774303],
                [107.4102363, 10.774279],
                [107.4102019, 10.7742791],
                [107.4101382, 10.7743028],
                [107.4100926, 10.7743527],
                [107.4099478, 10.7745951],
                [107.4099192, 10.7746252],
                [107.4098503, 10.774672],
                [107.4098115, 10.7746878],
                [107.4097322, 10.7747021],
                [107.4096551, 10.7746954],
                [107.4096179, 10.7746847],
                [107.4095292, 10.7745894],
                [107.4094646, 10.7744698],
                [107.409444, 10.7744018],
                [107.4094328, 10.7743316],
                [107.4094342, 10.7741129],
                [107.4094182, 10.7740743],
                [107.4093664, 10.7740086],
                [107.4092967, 10.7739665],
                [107.4092193, 10.7739506],
                [107.4091409, 10.7739611],
                [107.4087006, 10.7741498],
                [107.4085682, 10.7742161],
                [107.4084973, 10.7742382],
                [107.4083486, 10.7742591],
                [107.4082726, 10.7742574],
                [107.4081972, 10.7742474],
                [107.4081677, 10.774237],
                [107.4081214, 10.774196],
                [107.4081078, 10.7741683],
                [107.4081031, 10.7741135],
                [107.4081239, 10.7740624],
                [107.4081427, 10.7740418],
                [107.4086052, 10.7738731],
                [107.4086372, 10.7738522],
                [107.4086827, 10.7737919],
                [107.4086938, 10.7737557],
                [107.4086962, 10.773718],
                [107.4086757, 10.7736472],
                [107.4086269, 10.773593],
                [107.4085592, 10.7735646],
                [107.4084856, 10.7735675],
                [107.408056, 10.7736681],
                [107.4079636, 10.77366],
                [107.4079201, 10.7736439],
                [107.4078773, 10.7736184],
                [107.4078402, 10.7735854],
                [107.4078103, 10.7735459],
                [107.4077886, 10.7735016],
                [107.4077787, 10.7733945],
                [107.4077833, 10.7733409],
                [107.4078179, 10.7732209],
                [107.4078472, 10.7731654],
                [107.4079024, 10.7730885],
                [107.4079145, 10.7730592],
                [107.4079168, 10.7729964],
                [107.4079069, 10.7729663],
                [107.4078678, 10.7729167],
                [107.4078039, 10.7728883],
                [107.4077341, 10.772894],
                [107.4077025, 10.7729096],
                [107.4076557, 10.7729502],
                [107.4076049, 10.7729681],
                [107.4075517, 10.7729595],
                [107.4075101, 10.7729273],
                [107.4074895, 10.7728805],
                [107.4074883, 10.7728549],
                [107.4075295, 10.7726664],
                [107.4075382, 10.772483],
                [107.4075299, 10.7723147],
                [107.4075056, 10.7722258],
                [107.4074855, 10.7721841],
                [107.4074166, 10.7720943],
                [107.4071876, 10.7719265],
                [107.4070212, 10.7717719],
                [107.4069459, 10.7716866],
                [107.406812, 10.7715081],
                [107.4067726, 10.7714697],
                [107.4066819, 10.7714067],
                [107.4066316, 10.7713829],
                [107.406579, 10.7713647],
                [107.4064993, 10.7713601],
                [107.4064165, 10.771327],
                [107.4063771, 10.7712972],
                [107.4063443, 10.7712606],
                [107.4063017, 10.7711988],
                [107.406238, 10.771158],
                [107.4062013, 10.7711476],
                [107.4061235, 10.7711495],
                [107.4060514, 10.7711826],
                [107.4059992, 10.7712416],
                [107.4059755, 10.7713052],
                [107.4059373, 10.7713472],
                [107.4058816, 10.7713607],
                [107.4058289, 10.7713417],
                [107.405809, 10.7713218],
                [107.4057904, 10.7712698],
                [107.4057696, 10.7704464],
                [107.4057455, 10.7703754],
                [107.4057216, 10.770346],
                [107.4056564, 10.7703073],
                [107.4056187, 10.7703002],
                [107.4055824, 10.7703015],
                [107.4055152, 10.7703274],
                [107.4054658, 10.7703792],
                [107.4053264, 10.7706901],
                [107.4052597, 10.7707666],
                [107.4052174, 10.7707954],
                [107.4051251, 10.770829],
                [107.4050308, 10.770832],
                [107.4049844, 10.7708226],
                [107.4049401, 10.7708061],
                [107.4048748, 10.770755],
                [107.4048041, 10.7707114],
                [107.4047291, 10.7706755],
                [107.4046504, 10.770648],
                [107.4045674, 10.7706287],
                [107.4044827, 10.7706187],
                [107.4043973, 10.7706181],
                [107.4043125, 10.7706269],
                [107.4042272, 10.7706113],
                [107.4041518, 10.7705693],
                [107.4041204, 10.7705397],
                [107.4040745, 10.7704675],
                [107.4040612, 10.7704268],
                [107.4040557, 10.7703437],
                [107.4040767, 10.770265],
                [107.4041215, 10.7701966],
                [107.4045732, 10.7698414],
                [107.404596, 10.7698127],
                [107.4046106, 10.7697793],
                [107.4046162, 10.7697433],
                [107.4046124, 10.7697071],
                [107.4045995, 10.769673],
                [107.4045789, 10.769644],
                [107.4045194, 10.7696048],
                [107.4044842, 10.7695971],
                [107.4044134, 10.7696078],
                [107.4040579, 10.7697835],
                [107.4040205, 10.7697954],
                [107.4039423, 10.7697995],
                [107.4038661, 10.7697763],
                [107.4038323, 10.7697551],
                [107.403591, 10.7695123],
                [107.4034978, 10.7694472],
                [107.4033889, 10.7694127],
                [107.4032745, 10.7694122],
                [107.4031714, 10.7694427],
                [107.4030815, 10.7695007],
                [107.4030438, 10.7695387],
                [107.4029428, 10.7697262],
                [107.4028854, 10.7697764],
                [107.4028131, 10.7698022],
                [107.4027346, 10.7697995],
                [107.4026629, 10.7697679],
                [107.4025776, 10.769633],
                [107.4025464, 10.7695596],
                [107.4025235, 10.7694833],
                [107.4025043, 10.7693575],
                [107.4025031, 10.7692939],
                [107.4025147, 10.769195],
                [107.4025047, 10.7691241],
                [107.4024644, 10.7690644],
                [107.4024089, 10.7690302],
                [107.4023441, 10.7690201],
                [107.4021944, 10.7690484],
                [107.4020664, 10.7690469],
                [107.4019341, 10.7690151],
                [107.4018094, 10.7689676],
                [107.4016798, 10.768949],
                [107.4016142, 10.7689511],
                [107.4014756, 10.7689812],
                [107.4012623, 10.7690733],
                [107.4011046, 10.7691092],
                [107.4010162, 10.7691163],
                [107.4008863, 10.7691109],
                [107.4008098, 10.769081],
                [107.4007505, 10.7690248],
                [107.4007196, 10.7689596],
                [107.4007131, 10.768924],
                [107.400713, 10.7688878],
                [107.4007559, 10.7686928],
                [107.4007586, 10.768588],
                [107.4007349, 10.7684857],
                [107.4006861, 10.7683924],
                [107.4006048, 10.768305],
                [107.4005018, 10.7682434],
                [107.4004447, 10.7682238],
                [107.4000414, 10.7681295],
                [107.3999962, 10.7680924],
                [107.3999727, 10.7680394],
                [107.3999756, 10.767982],
                [107.3999869, 10.7679553],
                [107.4000263, 10.7679129],
                [107.4005209, 10.7676672],
                [107.4005652, 10.7676277],
                [107.4005793, 10.7676016],
                [107.4005877, 10.7675444],
                [107.4005702, 10.7674904],
                [107.4005306, 10.7674491],
                [107.4000156, 10.767075],
                [107.3999258, 10.7669686],
                [107.3998905, 10.7669087],
                [107.3998412, 10.7667793],
                [107.3998221, 10.7666379],
                [107.3998372, 10.7664917],
                [107.3998579, 10.7664209],
                [107.3999095, 10.7663139],
                [107.3999304, 10.7662267],
                [107.3999168, 10.7661382],
                [107.3998976, 10.7660974],
                [107.3998372, 10.7660298],
                [107.3997555, 10.7659892],
                [107.3997102, 10.765981],
                [107.399278, 10.7659735],
                [107.3991355, 10.7659434],
                [107.3990017, 10.7658866],
                [107.3988615, 10.7657876],
                [107.3989355, 10.7655503],
                [107.3989076, 10.7653921],
                [107.3988295, 10.7651963],
                [107.3986905, 10.7650411],
                [107.3985413, 10.764932],
                [107.3983911, 10.7647591],
                [107.3982557, 10.7645011],
                [107.3981468, 10.7642064],
                [107.3980929, 10.7641176],
                [107.3980283, 10.7640287],
                [107.3978484, 10.7638904],
                [107.3976739, 10.7637204],
                [107.3975734, 10.7634997],
                [107.3974945, 10.7632737],
                [107.3974188, 10.7631267],
                [107.3973065, 10.7630594],
                [107.3970829, 10.7630403],
                [107.3965994, 10.7630814],
                [107.3960139, 10.7631172],
                [107.3950515, 10.7631621],
                [107.3946146, 10.7631445],
                [107.394442, 10.7631135],
                [107.3942801, 10.7630403],
                [107.394128, 10.7629572],
                [107.3940321, 10.7628477],
                [107.3939363, 10.7627277],
                [107.3938733, 10.7625719],
                [107.3938672, 10.7624142],
                [107.3939507, 10.7621191],
                [107.3939934, 10.7616738],
                [107.3939794, 10.76156],
                [107.3939074, 10.7614581],
                [107.3937281, 10.7613139],
                [107.3932703, 10.7608426],
                [107.3930128, 10.7605934],
                [107.3927744, 10.7602687],
                [107.3926956, 10.7601762],
                [107.392581, 10.7600417],
                [107.3924968, 10.7599106],
                [107.3924816, 10.7597502],
                [107.3925093, 10.7595898],
                [107.3927276, 10.7593803],
                [107.392877, 10.7592132],
                [107.3930291, 10.7589342],
                [107.3931598, 10.7585498],
                [107.3931489, 10.7583528],
                [107.3929572, 10.7578972],
                [107.3928999, 10.7576681],
                [107.392907, 10.7574073],
                [107.3927878, 10.7569799],
                [107.3925421, 10.7566486],
                [107.3920956, 10.7562466],
                [107.3919064, 10.7560028],
                [107.3918192, 10.7557696],
                [107.3916722, 10.7555536],
                [107.3915119, 10.7554623],
                [107.3913988, 10.7553942],
                [107.391248, 10.7553834],
                [107.3910649, 10.7554254],
                [107.3908278, 10.7555943],
                [107.3903187, 10.7561061],
                [107.3901389, 10.7564704],
                [107.3900976, 10.7565243],
                [107.3900502, 10.7565732],
                [107.3899399, 10.7566532],
                [107.3898138, 10.7567061],
                [107.3896835, 10.7567289],
                [107.3895513, 10.7567227],
                [107.3894866, 10.7567087],
                [107.3893235, 10.7566378],
                [107.3891749, 10.7565406],
                [107.3890454, 10.7564202],
                [107.3889314, 10.7562686],
                [107.3888475, 10.7560992],
                [107.3888177, 10.7560095],
                [107.3887837, 10.755824],
                [107.3887312, 10.7550753],
                [107.3887199, 10.7550214],
                [107.3886992, 10.7549703],
                [107.3886698, 10.7549235],
                [107.3886339, 10.7548835],
                [107.3885915, 10.75485],
                [107.3885441, 10.754824],
                [107.3883138, 10.754747],
                [107.3882212, 10.7547284],
                [107.3879337, 10.7547028],
                [107.3878384, 10.7546846],
                [107.3876533, 10.7546268],
                [107.3875648, 10.7545875],
                [107.3873902, 10.7544836],
                [107.387235, 10.7543534],
                [107.3871661, 10.7542794],
                [107.3869732, 10.7540409],
                [107.3868231, 10.7538996],
                [107.3867413, 10.7538365],
                [107.3865416, 10.7537134],
                [107.3863256, 10.7536204],
                [107.3862306, 10.7535975],
                [107.3861382, 10.7535659],
                [107.3860492, 10.753526],
                [107.3859645, 10.7534779],
                [107.3858847, 10.7534223],
                [107.3857484, 10.7532963],
                [107.3856417, 10.7531554],
                [107.385598, 10.7530787],
                [107.3855612, 10.7529985],
                [107.3854164, 10.7525158],
                [107.3853442, 10.7523465],
                [107.3852419, 10.7521929],
                [107.3851805, 10.7521236],
                [107.3850398, 10.7520028],
                [107.3848756, 10.7519074],
                [107.3847856, 10.7518707],
                [107.3846924, 10.7518426],
                [107.384432, 10.7517816],
                [107.3841061, 10.7516849],
                [107.3837124, 10.7515417],
                [107.3835195, 10.7514604],
                [107.3832616, 10.7513402],
                [107.3831976, 10.7513007],
                [107.3830841, 10.7512029],
                [107.383036, 10.7511457],
                [107.3829598, 10.7510177],
                [107.3829326, 10.7509484],
                [107.3829022, 10.7508104],
                [107.3828979, 10.7507398],
                [107.3829297, 10.7504121],
                [107.3829482, 10.7503199],
                [107.3830269, 10.7500465],
                [107.383042, 10.7499526],
                [107.3830479, 10.7497626],
                [107.3830254, 10.7495926],
                [107.3830044, 10.7495094],
                [107.3829769, 10.749428],
                [107.382865, 10.7491745],
                [107.3828395, 10.7490824],
                [107.3828236, 10.7489882],
                [107.3828198, 10.7488078],
                [107.3828481, 10.7486398],
                [107.3828736, 10.7485583],
                [107.3831747, 10.7480524],
                [107.3832099, 10.7479716],
                [107.3832165, 10.7478839],
                [107.3832088, 10.7478405],
                [107.3831723, 10.7477602],
                [107.3831445, 10.7477256],
                [107.3830725, 10.7476715],
                [107.3830306, 10.747654],
                [107.382941, 10.7476405],
                [107.3828957, 10.747645],
                [107.3828106, 10.7476755],
                [107.3824503, 10.7479322],
                [107.3823506, 10.747972],
                [107.3822341, 10.747985],
                [107.3821756, 10.7479792],
                [107.3819761, 10.7479337],
                [107.3818302, 10.7479285],
                [107.3816975, 10.7479472],
                [107.3814876, 10.748013],
                [107.381403, 10.7480298],
                [107.3812307, 10.7480379],
                [107.3810722, 10.7480147],
                [107.3809209, 10.7479628],
                [107.3805857, 10.7477757],
                [107.3805202, 10.7477111],
                [107.3804742, 10.747632],
                [107.3804596, 10.7475886],
                [107.3804485, 10.7474975],
                [107.3804623, 10.7474068],
                [107.3805229, 10.7471902],
                [107.3805517, 10.7469673],
                [107.3805481, 10.7467427],
                [107.3805336, 10.7466281],
                [107.3804794, 10.7464037],
                [107.3803925, 10.7461893],
                [107.3795933, 10.7445502],
                [107.3795384, 10.7443893],
                [107.3795181, 10.7442208],
                [107.3795215, 10.7441333],
                [107.3795342, 10.7440466],
                [107.3795562, 10.7439617],
                [107.3795872, 10.7438796],
                [107.3798803, 10.7434948],
                [107.3799479, 10.743396],
                [107.38006, 10.7431852],
                [107.3801304, 10.7429922],
                [107.3801753, 10.7427919],
                [107.3801872, 10.7426109],
                [107.3801893, 10.7424296],
                [107.3801818, 10.7422484],
                [107.3801422, 10.7419143],
                [107.3800764, 10.741611],
                [107.3800086, 10.7413909],
                [107.3799349, 10.7412602],
                [107.3798868, 10.7412022],
                [107.3797782, 10.7411095],
                [107.3797204, 10.7410747],
                [107.3796185, 10.7410338],
                [107.3795764, 10.7410288],
                [107.3794964, 10.7410399],
                [107.3794605, 10.7410544],
                [107.3793984, 10.7410994],
                [107.3793194, 10.7412134],
                [107.3792496, 10.7414118],
                [107.3791637, 10.7416523],
                [107.3790805, 10.7418903],
                [107.378975, 10.742081],
                [107.3788696, 10.7422032],
                [107.3787612, 10.7422627],
                [107.378642, 10.7422905],
                [107.3785003, 10.7422935],
                [107.378367, 10.7422574],
                [107.3782496, 10.7421214],
                [107.3781794, 10.7419116],
                [107.3781602, 10.7417222],
                [107.3781731, 10.7414801],
                [107.3781818, 10.7411745],
                [107.3781668, 10.7409533],
                [107.3781572, 10.7407585],
                [107.3781203, 10.7405693],
                [107.3780392, 10.7403984],
                [107.3779504, 10.7402128],
                [107.3777865, 10.7400114],
                [107.3775762, 10.7398026],
                [107.3773909, 10.7395326],
                [107.3772163, 10.7392047],
                [107.3770527, 10.7388886],
                [107.376857, 10.738467],
                [107.3766301, 10.7379891],
                [107.3763765, 10.7376594],
                [107.3760871, 10.7374246],
                [107.3757241, 10.7372172],
                [107.3753688, 10.7370825],
                [107.3751429, 10.7369729],
                [107.374976, 10.7368476],
                [107.3748076, 10.736704],
                [107.3747251, 10.7365921],
                [107.3746136, 10.7363812],
                [107.3745236, 10.7361756],
                [107.3744182, 10.7359233],
                [107.3742913, 10.7356604],
                [107.3740757, 10.7353668],
                [107.3738224, 10.7351367],
                [107.3736134, 10.7349595],
                [107.3733465, 10.7348464],
                [107.3730366, 10.7347426],
                [107.3726677, 10.7347338],
                [107.3723018, 10.734775],
                [107.371931, 10.7347666],
                [107.3716813, 10.7347308],
                [107.3714476, 10.7346476],
                [107.3712824, 10.7345726],
                [107.3711225, 10.7344713],
                [107.3709991, 10.7343506],
                [107.3709178, 10.7341515],
                [107.3708784, 10.7339633],
                [107.3708766, 10.7338014],
                [107.3708863, 10.7336119],
                [107.3709441, 10.7334696],
                [107.3710494, 10.7333742],
                [107.3712779, 10.7331996],
                [107.3714674, 10.7330082],
                [107.3716339, 10.7328491],
                [107.3717385, 10.7326852],
                [107.371811, 10.7325161],
                [107.371852, 10.732289],
                [107.3718317, 10.7321175],
                [107.3717632, 10.7319197],
                [107.3717197, 10.7317879],
                [107.371649, 10.7316671],
                [107.3715462, 10.7315627],
                [107.3714193, 10.7314309],
                [107.3712602, 10.7313518],
                [107.3710525, 10.7312953],
                [107.3708882, 10.7312868],
                [107.3706544, 10.7313725],
                [107.370423, 10.7315805],
                [107.3701643, 10.7319274],
                [107.3699967, 10.732269],
                [107.3698654, 10.7325232],
                [107.3697354, 10.7326662],
                [107.3695739, 10.7327798],
                [107.3693265, 10.7329092],
                [107.3690405, 10.7330433],
                [107.3688131, 10.7331508],
                [107.3686056, 10.7332259],
                [107.3684571, 10.733254],
                [107.3683099, 10.733236],
                [107.3682095, 10.7331743],
                [107.3681198, 10.7330968],
                [107.3678624, 10.732799],
                [107.3673198, 10.7321125],
                [107.366846, 10.7314992],
                [107.3665255, 10.7309519],
                [107.3663272, 10.7307268],
                [107.366194, 10.7306177],
                [107.3660451, 10.7305456],
                [107.365893, 10.7304912],
                [107.3657195, 10.7305157],
                [107.3655468, 10.7305592],
                [107.3652025, 10.7307185],
                [107.3645364, 10.7311377],
                [107.3643147, 10.7312591],
                [107.3640855, 10.7312923],
                [107.3639227, 10.7312826],
                [107.3637974, 10.7312149],
                [107.3636901, 10.7311153],
                [107.3636258, 10.7310181],
                [107.363599, 10.7308893],
                [107.363614, 10.730726],
                [107.3636637, 10.7305363],
                [107.363751, 10.7303781],
                [107.3637914, 10.7301986],
                [107.3638247, 10.7300507],
                [107.3638413, 10.7298872],
                [107.3638093, 10.7298028],
                [107.3637291, 10.7297236],
                [107.3635884, 10.7296552],
                [107.3633952, 10.7295559],
                [107.3632434, 10.7294708],
                [107.3627254, 10.7292304],
                [107.3622617, 10.7290335],
                [107.3617845, 10.7287285],
                [107.3613834, 10.7284575],
                [107.3610895, 10.7282076],
                [107.3609531, 10.7276167],
                [107.3611829, 10.7267972],
                [107.3607048, 10.7246903],
                [107.3589407, 10.7217043],
                [107.3582119, 10.7205371],
                [107.3545401, 10.7146559],
                [107.3507517, 10.7085878],
                [107.3506873, 10.690572],
                [107.352008, 10.6783643],
                [107.3489593, 10.6772965],
                [107.3439759, 10.6764257],
                [107.3373416, 10.6776674],
                [107.3327974, 10.6775825],
                [107.3320297, 10.6774301],
                [107.3317577, 10.6773688],
                [107.3316051, 10.6772514],
                [107.3314917, 10.6770467],
                [107.3314103, 10.6768417],
                [107.3312803, 10.6765422],
                [107.3311189, 10.6763062],
                [107.3309904, 10.6762122],
                [107.3307665, 10.6761822],
                [107.3304152, 10.6761848],
                [107.3299051, 10.6762993],
                [107.3295704, 10.6763809],
                [107.3293308, 10.6763827],
                [107.3290908, 10.6763371],
                [107.3286755, 10.6763243],
                [107.3283885, 10.6763739],
                [107.3281649, 10.6763755],
                [107.3280368, 10.6763291],
                [107.3279723, 10.6762505],
                [107.3278305, 10.6760647],
                [107.3277849, 10.6759382],
                [107.3277436, 10.6757496],
                [107.3276583, 10.6752542],
                [107.3275525, 10.6749546],
                [107.3272332, 10.6745875],
                [107.3271689, 10.6745248],
                [107.3269443, 10.674384],
                [107.3265601, 10.6742604],
                [107.3262319, 10.6741996],
                [107.325986, 10.6741353],
                [107.3257962, 10.6740007],
                [107.3255628, 10.6738953],
                [107.3254422, 10.6737298],
                [107.3253295, 10.6735817],
                [107.3252429, 10.6734929],
                [107.3251283, 10.6734446],
                [107.3250452, 10.6734288],
                [107.3248909, 10.6734853],
                [107.3247874, 10.6735023],
                [107.3246267, 10.6734973],
                [107.3245572, 10.6734266],
                [107.3244445, 10.673264],
                [107.3243633, 10.6730907],
                [107.3243288, 10.6730834],
                [107.3241906, 10.6731164],
                [107.3240715, 10.6731494],
                [107.3239803, 10.6731731],
                [107.3238945, 10.6731257],
                [107.3238593, 10.6730306],
                [107.3238486, 10.6729546],
                [107.3238509, 10.6729047],
                [107.3236731, 10.6726214],
                [107.3233887, 10.6724862],
                [107.3232154, 10.6724376],
                [107.3230901, 10.6722913],
                [107.3229289, 10.672153],
                [107.3228621, 10.6720184],
                [107.3228694, 10.6718632],
                [107.3228165, 10.6717316],
                [107.3228028, 10.6715442],
                [107.3228189, 10.671249],
                [107.322889, 10.6709992],
                [107.3228881, 10.6708039],
                [107.3228039, 10.6706431],
                [107.322739, 10.6705012],
                [107.3225948, 10.670439],
                [107.3221461, 10.6702368],
                [107.3219531, 10.6700484],
                [107.3215905, 10.6698124],
                [107.3215061, 10.6697811],
                [107.3214613, 10.669775],
                [107.3213738, 10.6697821],
                [107.3212069, 10.6698515],
                [107.321113, 10.6698704],
                [107.3209938, 10.6698645],
                [107.3207644, 10.6697966],
                [107.3206577, 10.6697986],
                [107.320577, 10.6698244],
                [107.3205145, 10.6698244],
                [107.3204602, 10.669793],
                [107.3204411, 10.6697677],
                [107.3204127, 10.6696589],
                [107.3203808, 10.66962],
                [107.3203531, 10.6696029],
                [107.320322, 10.6695934],
                [107.3202151, 10.6696004],
                [107.3201314, 10.6695884],
                [107.3200598, 10.6695605],
                [107.3199941, 10.6695208],
                [107.3199211, 10.6694976],
                [107.3198827, 10.6694951],
                [107.3198102, 10.6695075],
                [107.3197478, 10.6695395],
                [107.319721, 10.669562],
                [107.3196949, 10.6696022],
                [107.3196745, 10.6696155],
                [107.3196511, 10.6696227],
                [107.319603, 10.6696174],
                [107.3195665, 10.6695898],
                [107.3195491, 10.6695479],
                [107.3195608, 10.6693917],
                [107.3195467, 10.6693599],
                [107.3195332, 10.6693485],
                [107.3194993, 10.6693397],
                [107.3194818, 10.669343],
                [107.3193535, 10.6694197],
                [107.3193221, 10.6694286],
                [107.319257, 10.6694276],
                [107.3191934, 10.6693996],
                [107.3191674, 10.6693765],
                [107.3190878, 10.669275],
                [107.3190061, 10.6692211],
                [107.3189082, 10.6691944],
                [107.3186763, 10.6691956],
                [107.3186496, 10.669189],
                [107.3186079, 10.6691548],
                [107.3185932, 10.6691117],
                [107.3186119, 10.6690043],
                [107.318605, 10.6689657],
                [107.318573, 10.6689373],
                [107.3185217, 10.6689201],
                [107.3184376, 10.6688691],
                [107.3183433, 10.668769],
                [107.3182967, 10.6687528],
                [107.3182436, 10.6687609],
                [107.3180896, 10.6688882],
                [107.3180556, 10.6688931],
                [107.3180392, 10.6688873],
                [107.3180141, 10.668856],
                [107.3180124, 10.6688359],
                [107.3180305, 10.6687473],
                [107.3180207, 10.668664],
                [107.3179893, 10.6686249],
                [107.3179391, 10.6686076],
                [107.317911, 10.6686104],
                [107.3178637, 10.6686396],
                [107.3177463, 10.6688187],
                [107.3176798, 10.6688635],
                [107.3176387, 10.6688753],
                [107.317596, 10.668878],
                [107.3173448, 10.6688256],
                [107.3173174, 10.6688258],
                [107.3172668, 10.6688451],
                [107.3172475, 10.6688618],
                [107.3172227, 10.6689058],
                [107.317211, 10.6689735],
                [107.3171806, 10.6690552],
                [107.3171383, 10.6691167],
                [107.3170854, 10.6691491],
                [107.3170549, 10.6691561],
                [107.3169981, 10.6691516],
                [107.3169715, 10.6691412],
                [107.3169043, 10.6690939],
                [107.316851, 10.6690844],
                [107.3167993, 10.6690992],
                [107.3167247, 10.6691579],
                [107.316639, 10.6691956],
                [107.3164986, 10.669218],
                [107.3164232, 10.6692022],
                [107.3163705, 10.6691676],
                [107.3163262, 10.6691194],
                [107.3162985, 10.6690994],
                [107.3162346, 10.6690773],
                [107.3162006, 10.6690757],
                [107.3160822, 10.6691223],
                [107.3159818, 10.6691889],
                [107.3159015, 10.6692694],
                [107.3158842, 10.6692988],
                [107.3158691, 10.6693648],
                [107.315885, 10.6694629],
                [107.3158827, 10.6694958],
                [107.3158592, 10.6695613],
                [107.3158373, 10.6695912],
                [107.3155421, 10.6698124],
                [107.3153122, 10.6700358],
                [107.3152336, 10.6700971],
                [107.315179, 10.670127],
                [107.3148356, 10.6702775],
                [107.3147514, 10.6703436],
                [107.3146845, 10.670433],
                [107.3146302, 10.6704661],
                [107.3145944, 10.6704743],
                [107.3145357, 10.6704699],
                [107.3144974, 10.6704477],
                [107.3144695, 10.6704006],
                [107.3144706, 10.6703258],
                [107.3144616, 10.6703074],
                [107.3144302, 10.6702868],
                [107.3143956, 10.6702895],
                [107.3143236, 10.6703178],
                [107.3142645, 10.6703204],
                [107.3142043, 10.6703128],
                [107.3141728, 10.6703172],
                [107.3141178, 10.6703475],
                [107.3140847, 10.6703969],
                [107.3140701, 10.6705182],
                [107.3140444, 10.6705732],
                [107.3140052, 10.6706164],
                [107.3138845, 10.6707007],
                [107.3138303, 10.6707245],
                [107.3137155, 10.6707534],
                [107.3136189, 10.6707578],
                [107.3135443, 10.6707501],
                [107.3134956, 10.6707692],
                [107.3134724, 10.670804],
                [107.3134567, 10.6709419],
                [107.3134289, 10.6710332],
                [107.313403, 10.6710737],
                [107.3133476, 10.6711272],
                [107.3132247, 10.6711923],
                [107.3131953, 10.6712221],
                [107.313159, 10.6712964],
                [107.3131537, 10.6713349],
                [107.313167, 10.6714111],
                [107.3132355, 10.6715442],
                [107.3132418, 10.6715706],
                [107.3132403, 10.6715978],
                [107.3132153, 10.6716456],
                [107.3131715, 10.6716721],
                [107.3130372, 10.6716699],
                [107.3129931, 10.6716868],
                [107.3129752, 10.6717023],
                [107.3129535, 10.6717398],
                [107.3129424, 10.6718452],
                [107.3129283, 10.6718736],
                [107.3128841, 10.6719169],
                [107.312811, 10.6719433],
                [107.3127647, 10.6719501],
                [107.3126164, 10.6719379],
                [107.3125662, 10.6719593],
                [107.3125494, 10.6719769],
                [107.3125313, 10.6720213],
                [107.3125358, 10.6721829],
                [107.312525, 10.6722068],
                [107.3124857, 10.6722413],
                [107.3124636, 10.6722487],
                [107.3124173, 10.6722466],
                [107.3123631, 10.6722043],
                [107.3123087, 10.6721438],
                [107.3122699, 10.6721352],
                [107.3122502, 10.6721389],
                [107.3119748, 10.6723138],
                [107.3119267, 10.6723548],
                [107.3118997, 10.6724114],
                [107.3118976, 10.6724699],
                [107.3119484, 10.672625],
                [107.3119918, 10.6728615],
                [107.3119751, 10.6729214],
                [107.3119332, 10.6729646],
                [107.3119068, 10.6729771],
                [107.3118137, 10.672996],
                [107.3116824, 10.6730018],
                [107.3115392, 10.6729779],
                [107.3114705, 10.6729543],
                [107.3114224, 10.6729131],
                [107.3114008, 10.6728542],
                [107.3114087, 10.6727978],
                [107.3114323, 10.6727527],
                [107.3114332, 10.6727089],
                [107.3114088, 10.6726723],
                [107.3113622, 10.6726551],
                [107.3113148, 10.6726701],
                [107.3112975, 10.6726881],
                [107.3111685, 10.6727633],
                [107.3111165, 10.6727804],
                [107.3110239, 10.6727896],
                [107.3109811, 10.6728156],
                [107.3109689, 10.6728502],
                [107.3109712, 10.6728701],
                [107.3109927, 10.6729163],
                [107.3110023, 10.6729766],
                [107.310988, 10.6730385],
                [107.3109494, 10.6730922],
                [107.3108793, 10.6731341],
                [107.3108657, 10.6731599],
                [107.3108718, 10.6731837],
                [107.3109551, 10.6732838],
                [107.310977, 10.6733207],
                [107.3109959, 10.6734034],
                [107.3109797, 10.6734868],
                [107.3109368, 10.6735509],
                [107.3109072, 10.6735761],
                [107.3108363, 10.6736084],
                [107.3107584, 10.6736133],
                [107.3105304, 10.6735764],
                [107.3105096, 10.6735759],
                [107.3104728, 10.673594],
                [107.3104543, 10.6736318],
                [107.3104557, 10.6736537],
                [107.3105659, 10.673835],
                [107.3105871, 10.6739164],
                [107.3105817, 10.6740045],
                [107.3105676, 10.6740487],
                [107.3105183, 10.6741273],
                [107.3103122, 10.6742538],
                [107.3102823, 10.674283],
                [107.3102394, 10.6743539],
                [107.3102268, 10.6743981],
                [107.3102275, 10.6744893],
                [107.3102408, 10.6745333],
                [107.310301, 10.6746388],
                [107.3103609, 10.674777],
                [107.3103975, 10.6749365],
                [107.3104028, 10.675044],
                [107.3103842, 10.6750775],
                [107.3103484, 10.6750963],
                [107.3103279, 10.6750972],
                [107.3101949, 10.675051],
                [107.3101643, 10.6750577],
                [107.3101516, 10.675071],
                [107.3101482, 10.6751065],
                [107.3102045, 10.6753819],
                [107.3102098, 10.6754806],
                [107.3101863, 10.6755782],
                [107.3101348, 10.6756666],
                [107.3099926, 10.6758596],
                [107.3099479, 10.6758904],
                [107.3098934, 10.6758932],
                [107.3098672, 10.6758833],
                [107.3098283, 10.6758445],
                [107.3097986, 10.6757746],
                [107.3097848, 10.6757593],
                [107.309749, 10.6757426],
                [107.309711, 10.6757456],
                [107.3095916, 10.6758194],
                [107.3095286, 10.6758432],
                [107.3094858, 10.6758484],
                [107.3093102, 10.6758336],
                [107.3092598, 10.6758564],
                [107.3092282, 10.6759011],
                [107.3092226, 10.6759254],
                [107.3092295, 10.6759742],
                [107.3092416, 10.675996],
                [107.3093499, 10.6761157],
                [107.3093572, 10.6761348],
                [107.3093569, 10.6761753],
                [107.3093482, 10.6761963],
                [107.3093156, 10.676228],
                [107.3092313, 10.6762498],
                [107.3091991, 10.6762828],
                [107.3091906, 10.6763071],
                [107.309196, 10.6763809],
                [107.3091832, 10.6764501],
                [107.3091289, 10.6766234],
                [107.3091099, 10.6767695],
                [107.3090833, 10.6768052],
                [107.3090662, 10.6768138],
                [107.3090281, 10.6768156],
                [107.3088885, 10.6767517],
                [107.3087749, 10.6767288],
                [107.3087091, 10.6767263],
                [107.3085261, 10.6767529],
                [107.3084854, 10.6767835],
                [107.3084576, 10.6768258],
                [107.3084384, 10.676842],
                [107.3083907, 10.676857],
                [107.308342, 10.676846],
                [107.3083216, 10.6768316],
                [107.3082439, 10.6767497],
                [107.308225, 10.676746],
                [107.3081902, 10.6767604],
                [107.3081764, 10.6767898],
                [107.3081963, 10.6768626],
                [107.3081991, 10.6769432],
                [107.3081553, 10.6770793],
                [107.308158, 10.6771437],
                [107.3081687, 10.6771742],
                [107.3083243, 10.6773693],
                [107.3083303, 10.6774125],
                [107.3083109, 10.6774517],
                [107.3082701, 10.6774742],
                [107.3082212, 10.6774679],
                [107.3082009, 10.6774536],
                [107.3081468, 10.6773827],
                [107.3081165, 10.6773732],
                [107.3080993, 10.677377],
                [107.3080848, 10.6773867],
                [107.3080427, 10.6774534],
                [107.3079632, 10.6775474],
                [107.3079166, 10.677588],
                [107.3078184, 10.6776512],
                [107.3077154, 10.6777067],
                [107.3076068, 10.6777546],
                [107.3074946, 10.6777939],
                [107.3073796, 10.6778243],
                [107.3072625, 10.6778457],
                [107.3071926, 10.6778394],
                [107.30716, 10.6778262],
                [107.3071307, 10.6778068],
                [107.3070897, 10.6777584],
                [107.3070614, 10.6776787],
                [107.3070475, 10.6776622],
                [107.3070093, 10.6776472],
                [107.3069713, 10.6776569],
                [107.3068356, 10.6777567],
                [107.3066854, 10.6779676],
                [107.3065871, 10.6781573],
                [107.3065138, 10.6783577],
                [107.3064736, 10.6784464],
                [107.306425, 10.6785309],
                [107.3063685, 10.6786105],
                [107.3063046, 10.6786845],
                [107.3061849, 10.6787922],
                [107.3061191, 10.6788389],
                [107.3059773, 10.6789165],
                [107.3057654, 10.6792143],
                [107.305616, 10.6793465],
                [107.3054477, 10.6794547],
                [107.3053578, 10.679499],
                [107.305188, 10.6795611],
                [107.3051003, 10.6795833],
                [107.3050017, 10.6795957],
                [107.3049553, 10.6796136],
                [107.3048797, 10.6796652],
                [107.304851, 10.679696],
                [107.3048079, 10.6797678],
                [107.3047221, 10.6799734],
                [107.3046859, 10.6800138],
                [107.3046362, 10.6800367],
                [107.3046033, 10.6800402],
                [107.3045704, 10.6800357],
                [107.304509, 10.6800108],
                [107.3044431, 10.680005],
                [107.3044134, 10.6800112],
                [107.30436, 10.6800393],
                [107.3040724, 10.6803704],
                [107.3039871, 10.6805032],
                [107.3038845, 10.680728],
                [107.3038516, 10.6807799],
                [107.3037645, 10.6808669],
                [107.3037212, 10.6808952],
                [107.3036257, 10.6809352],
                [107.3034563, 10.6809609],
                [107.3033913, 10.6809788],
                [107.303271, 10.6810382],
                [107.3031843, 10.6811103],
                [107.3031474, 10.6811528],
                [107.3030708, 10.6812657],
                [107.302963, 10.6813849],
                [107.3028292, 10.6814843],
                [107.3026729, 10.6815575],
                [107.3026203, 10.6815949],
                [107.3025872, 10.6816497],
                [107.302579, 10.681713],
                [107.3025854, 10.6817464],
                [107.302619, 10.6818055],
                [107.3026446, 10.6818283],
                [107.3027077, 10.6818553],
                [107.302925, 10.6818711],
                [107.3029464, 10.6818849],
                [107.3029755, 10.6819257],
                [107.3029796, 10.6819809],
                [107.3029683, 10.6820097],
                [107.3029486, 10.6820336],
                [107.3028528, 10.6821069],
                [107.3027775, 10.6821558],
                [107.3025961, 10.6822517],
                [107.3023932, 10.6823883],
                [107.3022831, 10.682478],
                [107.3021793, 10.6825749],
                [107.3021137, 10.6826177],
                [107.3020774, 10.6826329],
                [107.3020012, 10.6826497],
                [107.301742, 10.6826373],
                [107.3017073, 10.6826513],
                [107.3016848, 10.6826859],
                [107.3016724, 10.68282],
                [107.3016559, 10.6828901],
                [107.301617, 10.6829567],
                [107.3015004, 10.6830784],
                [107.3014739, 10.6831152],
                [107.3014471, 10.6831785],
                [107.3014109, 10.6833537],
                [107.3013963, 10.6833863],
                [107.3013485, 10.6834391],
                [107.3013173, 10.6834572],
                [107.3012471, 10.6834728],
                [107.3011778, 10.6834599],
                [107.3011204, 10.6834222],
                [107.3010823, 10.6833656],
                [107.3010023, 10.6831354],
                [107.3009703, 10.6831095],
                [107.3009495, 10.683106],
                [107.3009262, 10.6831104],
                [107.3009064, 10.6831232],
                [107.3008929, 10.6831425],
                [107.3008501, 10.6832925],
                [107.3007953, 10.683363],
                [107.3007229, 10.6833893],
                [107.3006631, 10.6834013],
                [107.30053, 10.683407],
                [107.3004228, 10.6834389],
                [107.3003476, 10.6834395],
                [107.3002839, 10.6834206],
                [107.3002253, 10.6834196],
                [107.3001737, 10.6834457],
                [107.3001388, 10.6834895],
                [107.3000966, 10.6835237],
                [107.3000448, 10.6835401],
                [107.299973, 10.683536],
                [107.2999327, 10.6835579],
                [107.2999129, 10.6836002],
                [107.2999306, 10.6837057],
                [107.2999263, 10.6837517],
                [107.2998941, 10.6837992],
                [107.2998458, 10.6838315],
                [107.2998244, 10.6838848],
                [107.2998332, 10.6839335],
                [107.2998651, 10.6839717],
                [107.2999062, 10.6839987],
                [107.299921, 10.6840176],
                [107.2999357, 10.6840627],
                [107.2999266, 10.6841132],
                [107.2998643, 10.6842028],
                [107.2998512, 10.6842538],
                [107.2998527, 10.6842836],
                [107.2998606, 10.6843125],
                [107.2998941, 10.6843619],
                [107.2999318, 10.6844495],
                [107.2999906, 10.6846307],
                [107.3000274, 10.6848249],
                [107.30004, 10.6849474],
                [107.300036, 10.6849715],
                [107.3000067, 10.6850103],
                [107.2999684, 10.6850242],
                [107.2999477, 10.6850235],
                [107.2998527, 10.6849924],
                [107.2998029, 10.6849866],
                [107.2996931, 10.6849951],
                [107.2996518, 10.6850192],
                [107.2996373, 10.6850383],
                [107.2996259, 10.6850841],
                [107.2996409, 10.6851315],
                [107.2996792, 10.6851638],
                [107.2999209, 10.6852264],
                [107.2999837, 10.6852599],
                [107.3000089, 10.6852852],
                [107.3000416, 10.6853476],
                [107.3000471, 10.6854183],
                [107.3000014, 10.6855611],
                [107.2999931, 10.685609],
                [107.2999887, 10.6857325],
                [107.299981, 10.6857578],
                [107.2999504, 10.685801],
                [107.2999273, 10.6858177],
                [107.2998726, 10.6858339],
                [107.2996125, 10.6858115],
                [107.2995815, 10.6858182],
                [107.2995301, 10.6858537],
                [107.2995132, 10.6858801],
                [107.2995025, 10.6859377],
                [107.2995347, 10.686036],
                [107.2995377, 10.6861206],
                [107.2995133, 10.6861974],
                [107.2994649, 10.6862622],
                [107.3021076, 10.687412],
                [107.3047435, 10.6885157],
                [107.3036519, 10.691133],
                [107.3047875, 10.6915957],
                [107.3048217, 10.6916155],
                [107.3048767, 10.6916712],
                [107.3049081, 10.6917424],
                [107.3049136, 10.6917801],
                [107.3049043, 10.6918554],
                [107.3046332, 10.6925259],
                [107.3056441, 10.6929463],
                [107.3059426, 10.6930611],
                [107.3065713, 10.6932752],
                [107.3069014, 10.6933732],
                [107.3071488, 10.6934704],
                [107.3073653, 10.693588],
                [107.3075511, 10.6937185],
                [107.3075932, 10.693763],
                [107.3076663, 10.6938608],
                [107.3077375, 10.6940026],
                [107.307762, 10.6940779],
                [107.3076175, 10.6941389],
                [107.3075351, 10.6941947],
                [107.3073804, 10.6942776],
                [107.3069762, 10.6944448],
                [107.3067793, 10.6945171],
                [107.3067456, 10.6945389],
                [107.3066964, 10.6946012],
                [107.3066786, 10.694678],
                [107.3066954, 10.6947549],
                [107.3067436, 10.6948176],
                [107.3068142, 10.6948545],
                [107.3068391, 10.6948778],
                [107.306859, 10.6949054],
                [107.3068802, 10.6949626],
                [107.3068839, 10.6950442],
                [107.3068758, 10.6950994],
                [107.3068587, 10.6951526],
                [107.3068196, 10.6952342],
                [107.3068151, 10.6952683],
                [107.306833, 10.6953341],
                [107.3068522, 10.6953595],
                [107.3069061, 10.6953934],
                [107.3070008, 10.6954036],
                [107.3070549, 10.6954359],
                [107.3070872, 10.6954895],
                [107.3070925, 10.6955206],
                [107.3070756, 10.6956291],
                [107.3070837, 10.6957232],
                [107.3071145, 10.6958163],
                [107.3071389, 10.6958611],
                [107.3072031, 10.6959403],
                [107.3072533, 10.6959756],
                [107.3073067, 10.6959825],
                [107.3073915, 10.6959592],
                [107.3074313, 10.6959759],
                [107.3074526, 10.6960141],
                [107.3074501, 10.6961063],
                [107.3074206, 10.6961969],
                [107.307364, 10.6962777],
                [107.3073443, 10.696289],
                [107.3072997, 10.6962975],
                [107.3072714, 10.6962924],
                [107.3072456, 10.6962798],
                [107.3072085, 10.6962466],
                [107.3071682, 10.696233],
                [107.3071236, 10.6962422],
                [107.3071038, 10.6962566],
                [107.3070892, 10.696276],
                [107.3070207, 10.6964912],
                [107.306967, 10.6966285],
                [107.3069054, 10.6967626],
                [107.3068276, 10.6968391],
                [107.3066313, 10.696942],
                [107.3065884, 10.6969786],
                [107.3065219, 10.6970666],
                [107.3064839, 10.6971677],
                [107.3064521, 10.6973767],
                [107.3064206, 10.6974372],
                [107.3063647, 10.69748],
                [107.3063302, 10.6974916],
                [107.3062054, 10.6975019],
                [107.3061222, 10.6975322],
                [107.3060788, 10.6975601],
                [107.3060086, 10.6976346],
                [107.3059666, 10.6977273],
                [107.305765, 10.6980941],
                [107.3057434, 10.6981559],
                [107.3057268, 10.6982857],
                [107.3057322, 10.6983511],
                [107.3057467, 10.6984152],
                [107.3057954, 10.6984968],
                [107.3058168, 10.6985891],
                [107.3058079, 10.6986863],
                [107.3057671, 10.6987783],
                [107.3056984, 10.6988527],
                [107.3056693, 10.6989018],
                [107.3056608, 10.6989291],
                [107.3056394, 10.6991189],
                [107.3056283, 10.6991515],
                [107.3055831, 10.6992032],
                [107.3055597, 10.6992158],
                [107.3055077, 10.6992266],
                [107.3053309, 10.6992058],
                [107.3051941, 10.6992243],
                [107.305103, 10.6992573],
                [107.3049501, 10.6993403],
                [107.3048267, 10.699393],
                [107.3044485, 10.6994457],
                [107.3042916, 10.6996605],
                [107.3042612, 10.6996927],
                [107.3042259, 10.6997198],
                [107.3041053, 10.6997725],
                [107.3040753, 10.6997957],
                [107.3040394, 10.6998484],
                [107.3040274, 10.699899],
                [107.304014, 10.6999192],
                [107.303971, 10.6999412],
                [107.3039317, 10.6999358],
                [107.3037377, 10.6998068],
                [107.3037043, 10.6998011],
                [107.3036385, 10.6998147],
                [107.3035943, 10.6998489],
                [107.3035476, 10.6999275],
                [107.303503, 10.69997],
                [107.3034735, 10.700011],
                [107.3034615, 10.7000641],
                [107.3034668, 10.7001836],
                [107.3034417, 10.7002884],
                [107.3033863, 10.7003813],
                [107.3033021, 10.7004563],
                [107.3031986, 10.7005025],
                [107.3031243, 10.7005169],
                [107.303049, 10.7005248],
                [107.3029353, 10.7005245],
                [107.3028606, 10.7005368],
                [107.3028156, 10.7005554],
                [107.3027219, 10.7006174],
                [107.3026085, 10.7006765],
                [107.3025306, 10.7007054],
                [107.3024185, 10.7007351],
                [107.3023894, 10.7007503],
                [107.3023433, 10.7007996],
                [107.3023185, 10.7008762],
                [107.3023216, 10.7009631],
                [107.3023656, 10.7010764],
                [107.3023697, 10.7011143],
                [107.3023581, 10.7011785],
                [107.3023439, 10.7012021],
                [107.3023, 10.7012352],
                [107.3022526, 10.7012436],
                [107.3021791, 10.7012276],
                [107.3021522, 10.7012321],
                [107.3021083, 10.7012629],
                [107.3020971, 10.7012818],
                [107.3020902, 10.7013246],
                [107.3021096, 10.7014751],
                [107.3020974, 10.7015739],
                [107.3020506, 10.7016622],
                [107.3019848, 10.7017226],
                [107.3019454, 10.7017444],
                [107.3018249, 10.7017886],
                [107.3017441, 10.7018098],
                [107.3016023, 10.7018274],
                [107.3013049, 10.7018203],
                [107.3011797, 10.7018073],
                [107.3009046, 10.7017601],
                [107.3008773, 10.7017635],
                [107.3008275, 10.7017861],
                [107.3008045, 10.7018073],
                [107.3007765, 10.7018625],
                [107.3007658, 10.7021023],
                [107.3006988, 10.7025346],
                [107.3002723, 10.7029773],
                [107.2997157, 10.7039189],
                [107.3004409, 10.7040497],
                [107.3005043, 10.7045692],
                [107.3008181, 10.7047563],
                [107.3007408, 10.7052062],
                [107.3006921, 10.705972],
                [107.3011516, 10.707743],
                [107.3012097, 10.7078807],
                [107.3012929, 10.7080026],
                [107.3015785, 10.708311],
                [107.301895, 10.708591],
                [107.302157, 10.7089876],
                [107.3038664, 10.7112054],
                [107.3045122, 10.7120089],
                [107.3051844, 10.7126855],
                [107.3054382, 10.7128578],
                [107.3053122, 10.7144075],
                [107.3052977, 10.7144524],
                [107.3052505, 10.714534],
                [107.3052176, 10.71457],
                [107.3051378, 10.7146262],
                [107.3050839, 10.7147032],
                [107.3050611, 10.7147867],
                [107.3050654, 10.714866],
                [107.3045927, 10.7156732],
                [107.3041239, 10.7152087],
                [107.3027976, 10.7163867],
                [107.3012741, 10.7177637],
                [107.3021083, 10.7179983],
                [107.3011708, 10.718893],
                [107.3008704, 10.7188087],
                [107.3008811, 10.7196309],
                [107.3010582, 10.7196414],
                [107.3009884, 10.720664],
                [107.3010459, 10.7206914],
                [107.3011495, 10.7207643],
                [107.3011944, 10.720809],
                [107.3012674, 10.7209117],
                [107.3012946, 10.7209686],
                [107.3013285, 10.7210897],
                [107.3013339, 10.7212152],
                [107.3013103, 10.7213386],
                [107.3016, 10.7216918],
                [107.3019004, 10.7216707],
                [107.3019372, 10.7216788],
                [107.302005, 10.7217108],
                [107.3020609, 10.7217621],
                [107.3020826, 10.721794],
                [107.3020989, 10.7218288],
                [107.3021516, 10.7220292],
                [107.3021856, 10.7221945],
                [107.3022175, 10.7224096],
                [107.3022294, 10.722457],
                [107.3022705, 10.7225456],
                [107.3023074, 10.7225955],
                [107.3023518, 10.7226392],
                [107.3024025, 10.7226756],
                [107.3024583, 10.7227038],
                [107.3025324, 10.7227624],
                [107.3026668, 10.7228945],
                [107.3027898, 10.7230581],
                [107.3028452, 10.7231539],
                [107.3028921, 10.7232538],
                [107.3029303, 10.7233573],
                [107.3029748, 10.7235178],
                [107.3030007, 10.7237102],
                [107.3030028, 10.7238212],
                [107.3030122, 10.7238881],
                [107.3030498, 10.7240181],
                [107.3031128, 10.7241413],
                [107.3031791, 10.7242431],
                [107.3032441, 10.7244176],
                [107.3032685, 10.7244623],
                [107.3033006, 10.724502],
                [107.3033394, 10.7245355],
                [107.3033836, 10.7245617],
                [107.3034737, 10.7245882],
                [107.3035208, 10.7245903],
                [107.3036501, 10.7245695],
                [107.3036867, 10.7245763],
                [107.3037203, 10.724592],
                [107.3037481, 10.7246151],
                [107.3037691, 10.7246442],
                [107.303782, 10.7246776],
                [107.3037928, 10.7247855],
                [107.3038163, 10.724877],
                [107.3038878, 10.7250331],
                [107.3038864, 10.7250852],
                [107.3038611, 10.725127],
                [107.3038429, 10.7252062],
                [107.3038443, 10.7252912],
                [107.3038534, 10.7253348],
                [107.303868, 10.7253769],
                [107.3040166, 10.7256659],
                [107.3042057, 10.7259835],
                [107.3042127, 10.7260116],
                [107.3042017, 10.7260678],
                [107.3041872, 10.7260887],
                [107.3041448, 10.726117],
                [107.3040938, 10.7261218],
                [107.3039094, 10.7260941],
                [107.3038756, 10.7260969],
                [107.3038135, 10.7261224],
                [107.3037878, 10.726144],
                [107.3037525, 10.7262002],
                [107.3037444, 10.7262325],
                [107.303748, 10.7262945],
                [107.3037753, 10.7263504],
                [107.3038369, 10.7264194],
                [107.3039037, 10.7265285],
                [107.3039657, 10.7267029],
                [107.3040321, 10.7268251],
                [107.3040736, 10.7268811],
                [107.3046523, 10.7274461],
                [107.3047036, 10.7275334],
                [107.3047235, 10.7276324],
                [107.30471, 10.7277323],
                [107.3046909, 10.7277793],
                [107.3046309, 10.7278611],
                [107.304549, 10.7279187],
                [107.304502, 10.7279371],
                [107.3044023, 10.7279508],
                [107.3043033, 10.7279327],
                [107.3042151, 10.7278849],
                [107.3038396, 10.7275344],
                [107.3037229, 10.7274883],
                [107.3035979, 10.7274746],
                [107.3035352, 10.7274803],
                [107.3034242, 10.7275124],
                [107.3033246, 10.7275704],
                [107.3032812, 10.7276081],
                [107.3032101, 10.7276978],
                [107.303127, 10.7278925],
                [107.3030782, 10.7280939],
                [107.3030631, 10.7282963],
                [107.3030799, 10.7283438],
                [107.3031221, 10.7283724],
                [107.3031503, 10.7283755],
                [107.3031779, 10.7283691],
                [107.3032016, 10.7283538],
                [107.3032187, 10.7283315],
                [107.3032773, 10.7281115],
                [107.3033099, 10.7280654],
                [107.3033632, 10.7280346],
                [107.3034295, 10.7280306],
                [107.3041065, 10.7282499],
                [107.3043117, 10.7282841],
                [107.3044741, 10.7282845],
                [107.3045549, 10.728276],
                [107.3049688, 10.728209],
                [107.3051646, 10.728209],
                [107.3052059, 10.7282262],
                [107.305279, 10.7282768],
                [107.30534, 10.728354],
                [107.3053621, 10.7284033],
                [107.3053754, 10.7284556],
                [107.3053853, 10.7285905],
                [107.3053746, 10.7287526],
                [107.3053316, 10.7289153],
                [107.3052972, 10.7289953],
                [107.3052051, 10.7291434],
                [107.3050852, 10.7292708],
                [107.304942, 10.7293725],
                [107.3047791, 10.7295248],
                [107.3046041, 10.7296549],
                [107.3043524, 10.7297981],
                [107.3043082, 10.7298601],
                [107.3042971, 10.7299316],
                [107.3043168, 10.7299976],
                [107.3043369, 10.7300261],
                [107.3045987, 10.7302909],
                [107.3046337, 10.7303449],
                [107.3046505, 10.7304168],
                [107.3046362, 10.7304991],
                [107.3045531, 10.7306282],
                [107.3045008, 10.7306746],
                [107.3044355, 10.730704],
                [107.3043994, 10.7307111],
                [107.3043179, 10.7307091],
                [107.3042296, 10.7307229],
                [107.3041882, 10.7307399],
                [107.3041159, 10.7307916],
                [107.3040879, 10.7308237],
                [107.3040481, 10.7308984],
                [107.3040372, 10.7309393],
                [107.304032, 10.7310207],
                [107.3040059, 10.7310947],
                [107.30398, 10.7311279],
                [107.3039473, 10.7311547],
                [107.3038681, 10.7311957],
                [107.3038311, 10.7312241],
                [107.3037719, 10.7313001],
                [107.3037526, 10.7313464],
                [107.3037269, 10.7315003],
                [107.3037027, 10.731596],
                [107.3036806, 10.7316405],
                [107.3036183, 10.7317179],
                [107.3035271, 10.7317799],
                [107.3034279, 10.7318115],
                [107.3033106, 10.7318769],
                [107.3031907, 10.7319789],
                [107.3030245, 10.7321352],
                [107.3029795, 10.73219],
                [107.3029089, 10.7323122],
                [107.3028663, 10.7324478],
                [107.3028565, 10.7325182],
                [107.3028606, 10.73266],
                [107.3029518, 10.733419],
                [107.3030799, 10.734591],
                [107.3030861, 10.734727],
                [107.3030853, 10.7348631],
                [107.3030685, 10.7350845],
                [107.3029143, 10.7359983],
                [107.3027453, 10.7372955],
                [107.3025548, 10.737422],
                [107.3025148, 10.7375672],
                [107.3024576, 10.7378627],
                [107.3024315, 10.7381625],
                [107.3024484, 10.7389004],
                [107.3024312, 10.7392522],
                [107.3020866, 10.7424722],
                [107.3018575, 10.7449919],
                [107.3018245, 10.7452251],
                [107.3017535, 10.7455126],
                [107.3017463, 10.7455872],
                [107.3017597, 10.7456592],
                [107.3017914, 10.7457233],
                [107.3022665, 10.7463066],
                [107.3024573, 10.7465578],
                [107.3025294, 10.7466633],
                [107.3025869, 10.7467779],
                [107.3027124, 10.7470726],
                [107.3028071, 10.7472619],
                [107.3028613, 10.7473531],
                [107.3030504, 10.747623],
                [107.3031054, 10.7477151],
                [107.3031876, 10.7478927],
                [107.3032797, 10.7481347],
                [107.3033951, 10.7483775],
                [107.3034446, 10.7484342],
                [107.3035165, 10.7484789],
                [107.3036083, 10.7485003],
                [107.303881, 10.7485245],
                [107.3039436, 10.7485498],
                [107.3039698, 10.7485727],
                [107.3040029, 10.7486331],
                [107.3042388, 10.7493004],
                [107.2995082, 10.7504464],
                [107.299986, 10.7524616],
                [107.3004983, 10.7549603],
                [107.2574357, 10.7558912],
                [107.2571906, 10.7559768],
                [107.2440599, 10.7561422],
                [107.244369, 10.7578418],
                [107.2444129, 10.7582582],
                [107.244423, 10.7586768],
                [107.2443992, 10.7590948],
                [107.2443734, 10.7593113],
                [107.2443385, 10.7595267],
                [107.2442946, 10.7597404],
                [107.2419584, 10.7591949],
                [107.2415936, 10.7594914],
                [107.2408318, 10.7610408],
                [107.2403598, 10.7617839],
                [107.2402751, 10.7617162],
                [107.2402391, 10.7616758],
                [107.2402016, 10.7616214],
                [107.2401119, 10.7614443],
                [107.2400713, 10.7613914],
                [107.2399972, 10.7613208],
                [107.2399334, 10.7612888],
                [107.2398604, 10.7612779],
                [107.2397856, 10.7612912],
                [107.2392172, 10.7615994],
                [107.239156, 10.7616238],
                [107.239027, 10.7616505],
                [107.2388953, 10.7616468],
                [107.2388246, 10.761632],
                [107.2387565, 10.7616083],
                [107.2386921, 10.7615762],
                [107.2386324, 10.7615362],
                [107.238332, 10.7612674],
                [107.2382332, 10.7612159],
                [107.238125, 10.7611878],
                [107.2380692, 10.7611831],
                [107.2380044, 10.7611854],
                [107.2378748, 10.7612109],
                [107.2378412, 10.7612068],
                [107.2377878, 10.761182],
                [107.2377487, 10.7611385],
                [107.23764, 10.7608642],
                [107.2376205, 10.7608294],
                [107.2375615, 10.7607754],
                [107.2375247, 10.7607588],
                [107.2374426, 10.7607501],
                [107.2373645, 10.7607762],
                [107.2371522, 10.760959],
                [107.2371144, 10.7609767],
                [107.2370319, 10.7609874],
                [107.236952, 10.7609644],
                [107.2368965, 10.7609208],
                [107.2368598, 10.760861],
                [107.2368488, 10.7608029],
                [107.2368265, 10.7607598],
                [107.2367843, 10.7607346],
                [107.2367595, 10.7607315],
                [107.2367348, 10.7607352],
                [107.2366932, 10.7607615],
                [107.2365158, 10.7610827],
                [107.2364555, 10.7611447],
                [107.2363901, 10.7611831],
                [107.2360702, 10.761286],
                [107.236025, 10.7612927],
                [107.2359793, 10.7612926],
                [107.2358922, 10.7612731],
                [107.2357862, 10.7612118],
                [107.2357196, 10.7611883],
                [107.2356137, 10.7611855],
                [107.2355613, 10.7611936],
                [107.2354688, 10.7612247],
                [107.2353428, 10.7613034],
                [107.2352581, 10.7613342],
                [107.2349296, 10.7613451],
                [107.2348896, 10.7613564],
                [107.2348512, 10.7613888],
                [107.2347454, 10.7616307],
                [107.2346561, 10.7617812],
                [107.2346266, 10.7618154],
                [107.234585, 10.7618339],
                [107.2345423, 10.7618338],
                [107.234522, 10.7618273],
                [107.2344452, 10.7617566],
                [107.23442, 10.7617522],
                [107.2344047, 10.7617585],
                [107.2343892, 10.7617865],
                [107.2343785, 10.7618734],
                [107.2343648, 10.7619159],
                [107.234318, 10.7619873],
                [107.2342873, 10.7620147],
                [107.234209, 10.7620591],
                [107.2341158, 10.7620883],
                [107.2340673, 10.7620948],
                [107.2339704, 10.7620911],
                [107.2339229, 10.7620811],
                [107.2338273, 10.7620452],
                [107.2337213, 10.7620289],
                [107.2336381, 10.7620399],
                [107.2335604, 10.7620711],
                [107.2335019, 10.76211],
                [107.2334021, 10.7622081],
                [107.2333344, 10.7623189],
                [107.2332962, 10.7624188],
                [107.2332439, 10.7624848],
                [107.2331874, 10.7625181],
                [107.2330745, 10.7625439],
                [107.2329837, 10.7625849],
                [107.2329417, 10.7626147],
                [107.2328713, 10.7626891],
                [107.2328442, 10.7627325],
                [107.2328334, 10.7627661],
                [107.2328214, 10.762873],
                [107.2327848, 10.7629364],
                [107.2327244, 10.7629795],
                [107.2325009, 10.7630381],
                [107.2324358, 10.7630717],
                [107.232394, 10.7631197],
                [107.2323507, 10.763216],
                [107.2321844, 10.7633359],
                [107.232107, 10.7634222],
                [107.2320778, 10.7634722],
                [107.2320412, 10.7635802],
                [107.2320342, 10.7636363],
                [107.232041, 10.7636927],
                [107.2320383, 10.7637494],
                [107.2320262, 10.7638049],
                [107.2320066, 10.7638545],
                [107.2319457, 10.7639419],
                [107.2316487, 10.7640853],
                [107.2315876, 10.7641014],
                [107.2315323, 10.7640976],
                [107.2314428, 10.7640697],
                [107.2314066, 10.7640708],
                [107.2313358, 10.764087],
                [107.2312992, 10.7640848],
                [107.2312644, 10.7640737],
                [107.2312344, 10.7640552],
                [107.2311654, 10.7639692],
                [107.231133, 10.7639446],
                [107.2310632, 10.7639182],
                [107.2310096, 10.7639156],
                [107.2309755, 10.7639035],
                [107.230795, 10.763768],
                [107.2307655, 10.7637589],
                [107.2307346, 10.7637562],
                [107.230677, 10.7637693],
                [107.2306048, 10.7638194],
                [107.2305163, 10.7638464],
                [107.2303686, 10.7638418],
                [107.2302469, 10.7638125],
                [107.2301888, 10.7637891],
                [107.2299984, 10.7636732],
                [107.2299088, 10.7636289],
                [107.229816, 10.7635915],
                [107.2295717, 10.7635372],
                [107.2295196, 10.7635111],
                [107.2294894, 10.7634843],
                [107.2294348, 10.7633899],
                [107.2294108, 10.7633676],
                [107.2293822, 10.7633514],
                [107.2292812, 10.7633308],
                [107.2292553, 10.7633196],
                [107.2292164, 10.7632845],
                [107.2291966, 10.7632402],
                [107.2291937, 10.7631812],
                [107.229175, 10.7631145],
                [107.2291394, 10.7630639],
                [107.229116, 10.7630434],
                [107.2287257, 10.7628965],
                [107.2287136, 10.7624426],
                [107.228778, 10.762158],
                [107.228769, 10.762098],
                [107.2287351, 10.7620474],
                [107.2286754, 10.7620132],
                [107.228641, 10.7620077],
                [107.2279305, 10.7620851],
                [107.2278956, 10.7620658],
                [107.2278735, 10.7620232],
                [107.2278739, 10.761999],
                [107.2279073, 10.761938],
                [107.2279119, 10.7619156],
                [107.2279036, 10.7618708],
                [107.2278908, 10.7618508],
                [107.2278519, 10.7618235],
                [107.2278285, 10.7618181],
                [107.2276493, 10.7618052],
                [107.2275952, 10.7617725],
                [107.2275764, 10.761747],
                [107.2275618, 10.7616846],
                [107.2275817, 10.7616238],
                [107.2276032, 10.7615994],
                [107.2276211, 10.7615357],
                [107.2276305, 10.7614041],
                [107.2276071, 10.7612817],
                [107.2275576, 10.7611752],
                [107.2275129, 10.7611072],
                [107.2274996, 10.7610687],
                [107.2274932, 10.7609881],
                [107.2275164, 10.7609044],
                [107.2275388, 10.7608669],
                [107.2275677, 10.7608338],
                [107.2276742, 10.7607564],
                [107.2277017, 10.7607221],
                [107.2277352, 10.7606415],
                [107.227737, 10.7605571],
                [107.2277096, 10.7604797],
                [107.2276568, 10.7604163],
                [107.227582, 10.760296],
                [107.2275224, 10.7601481],
                [107.2275013, 10.7600618],
                [107.2274074, 10.7594123],
                [107.2273673, 10.7593372],
                [107.2273387, 10.7593052],
                [107.227268, 10.7592565],
                [107.2271886, 10.7592324],
                [107.2271086, 10.7592324],
                [107.2269774, 10.759277],
                [107.226879, 10.7592885],
                [107.2267828, 10.7592751],
                [107.226667, 10.7592239],
                [107.2266287, 10.7592149],
                [107.2265498, 10.7592155],
                [107.2265114, 10.7592252],
                [107.2264418, 10.7592621],
                [107.2261333, 10.7595625],
                [107.2260915, 10.7595925],
                [107.2260457, 10.7596163],
                [107.2259461, 10.7596433],
                [107.2258428, 10.7596411],
                [107.2257924, 10.759629],
                [107.225695, 10.759581],
                [107.225651, 10.7595447],
                [107.2256135, 10.7595019],
                [107.2255834, 10.7594537],
                [107.2255616, 10.7594015],
                [107.2255371, 10.7592888],
                [107.2255059, 10.7592355],
                [107.2254561, 10.7591936],
                [107.2252968, 10.7590941],
                [107.2252243, 10.7590347],
                [107.2250961, 10.7588986],
                [107.2250413, 10.758823],
                [107.2249934, 10.758743],
                [107.2249214, 10.7585787],
                [107.224878, 10.758405],
                [107.2248608, 10.7582799],
                [107.2248244, 10.758216],
                [107.2247957, 10.7581911],
                [107.2247621, 10.7581732],
                [107.2246674, 10.7581459],
                [107.2246125, 10.7581211],
                [107.2245344, 10.7580687],
                [107.2244617, 10.7580062],
                [107.2243765, 10.7579578],
                [107.2242839, 10.7579283],
                [107.2241756, 10.7579107],
                [107.2240613, 10.7578695],
                [107.2239728, 10.7578128],
                [107.2238038, 10.7576718],
                [107.2237212, 10.7576273],
                [107.2236764, 10.7576126],
                [107.2235658, 10.7575988],
                [107.2233331, 10.7576191],
                [107.2231668, 10.7576508],
                [107.2228329, 10.7577456],
                [107.2226616, 10.7576912],
                [107.2225728, 10.7576766],
                [107.2223501, 10.7576672],
                [107.2222844, 10.7576574],
                [107.222157, 10.7576218],
                [107.2220483, 10.757573],
                [107.2219362, 10.7575423],
                [107.2219051, 10.7575257],
                [107.2218633, 10.7574778],
                [107.2218527, 10.757451],
                [107.2218499, 10.7573938],
                [107.2218255, 10.7572873],
                [107.2217734, 10.7571651],
                [107.2217318, 10.7570987],
                [107.2216393, 10.7570359],
                [107.221532, 10.7570025],
                [107.2214524, 10.7569984],
                [107.2212455, 10.7570327],
                [107.2211605, 10.7570355],
                [107.2210165, 10.7570212],
                [107.220899, 10.756995],
                [107.2208031, 10.7569963],
                [107.2207626, 10.7570058],
                [107.2206871, 10.7570401],
                [107.2205256, 10.7571634],
                [107.2204818, 10.757186],
                [107.2203819, 10.7572098],
                [107.2203282, 10.7572089],
                [107.2202754, 10.7571989],
                [107.2199294, 10.7571198],
                [107.2198612, 10.7571115],
                [107.2197925, 10.7571123],
                [107.2197245, 10.7571222],
                [107.2196017, 10.7571651],
                [107.219499, 10.7572328],
                [107.2194546, 10.7572753],
                [107.2192104, 10.757592],
                [107.2191373, 10.7576605],
                [107.2190503, 10.7577047],
                [107.2189576, 10.7577233],
                [107.218416, 10.7577423],
                [107.2183733, 10.7577329],
                [107.2183407, 10.7577044],
                [107.2183308, 10.757685],
                [107.2183272, 10.7576419],
                [107.2183455, 10.7576025],
                [107.2183847, 10.7575636],
                [107.218401, 10.7575349],
                [107.2184096, 10.7575031],
                [107.2184031, 10.7574412],
                [107.2183708, 10.7573912],
                [107.2183469, 10.7573728],
                [107.2182778, 10.7573393],
                [107.2182394, 10.7573342],
                [107.2181685, 10.7573463],
                [107.2181118, 10.7573812],
                [107.2180894, 10.7574057],
                [107.2180131, 10.7575565],
                [107.217991, 10.7575766],
                [107.2179365, 10.7576014],
                [107.2178652, 10.7575993],
                [107.2178322, 10.7575854],
                [107.2174921, 10.7572935],
                [107.2174387, 10.7572778],
                [107.2173843, 10.7572898],
                [107.2173441, 10.7573248],
                [107.2173258, 10.7573724],
                [107.2173381, 10.7574676],
                [107.2173307, 10.7574893],
                [107.2172992, 10.7575207],
                [107.2172565, 10.7575293],
                [107.2171654, 10.7575098],
                [107.2171165, 10.7575078],
                [107.2170691, 10.757523],
                [107.2157814, 10.7593365],
                [107.2080958, 10.75952],
                [107.2080701, 10.7594807],
                [107.2080526, 10.7594373],
                [107.2080374, 10.7593321],
                [107.2080076, 10.7592845],
                [107.2079635, 10.7592566],
                [107.207903, 10.7592482],
                [107.2078434, 10.7592244],
                [107.2078206, 10.7592057],
                [107.2077714, 10.7591351],
                [107.2077502, 10.7591159],
                [107.207631, 10.7590646],
                [107.2076026, 10.7590434],
                [107.2075591, 10.7589845],
                [107.2075311, 10.7589142],
                [107.2075072, 10.7588831],
                [107.2074766, 10.7588583],
                [107.2074058, 10.7588331],
                [107.2072002, 10.7588302],
                [107.2070249, 10.7588152],
                [107.2068369, 10.7587746],
                [107.2067665, 10.7587317],
                [107.2067139, 10.7586689],
                [107.2066961, 10.758632],
                [107.2066798, 10.758549],
                [107.2066828, 10.7585051],
                [107.2067119, 10.7584222],
                [107.2067371, 10.7583858],
                [107.2068105, 10.7582905],
                [107.2068259, 10.7582318],
                [107.2068228, 10.7582015],
                [107.2067922, 10.758143],
                [107.2067667, 10.7581213],
                [107.2067034, 10.7581],
                [107.2065405, 10.7580717],
                [107.2068791, 10.7738165],
                [107.2068207, 10.7738327],
                [107.2067844, 10.7738695],
                [107.2067463, 10.774026],
                [107.2066609, 10.7741651],
                [107.2066367, 10.774217],
                [107.2066035, 10.7743274],
                [107.206595, 10.7743849],
                [107.2065948, 10.7744844],
                [107.2065217, 10.774477],
                [107.20645, 10.7744923],
                [107.2063802, 10.7745342],
                [107.2063347, 10.7745891],
                [107.2062877, 10.7746294],
                [107.2062211, 10.774652],
                [107.2061061, 10.7746464],
                [107.2060275, 10.774632],
                [107.2058283, 10.7745664],
                [107.2057807, 10.7745635],
                [107.2057323, 10.7745697],
                [107.205686, 10.7745849],
                [107.2056435, 10.7746086],
                [107.2053458, 10.7748273],
                [107.2053095, 10.774842],
                [107.2052319, 10.77485],
                [107.2051566, 10.7748283],
                [107.2050951, 10.77478],
                [107.2050726, 10.7747479],
                [107.2049895, 10.7745767],
                [107.2049612, 10.7745455],
                [107.2048936, 10.774495],
                [107.204806, 10.7744608],
                [107.2047028, 10.7744528],
                [107.204487, 10.7744827],
                [107.2041303, 10.7745061],
                [107.2039388, 10.7745052],
                [107.2037071, 10.7744881],
                [107.2036312, 10.7744575],
                [107.2035675, 10.7744067],
                [107.203542, 10.7743751],
                [107.2034766, 10.7742568],
                [107.2033969, 10.7741822],
                [107.2033489, 10.7741554],
                [107.203297, 10.7741366],
                [107.2031978, 10.7741245],
                [107.2030992, 10.7741414],
                [107.2030237, 10.7741749],
                [107.2029595, 10.774187],
                [107.2028899, 10.774173],
                [107.2028563, 10.7741543],
                [107.2028276, 10.7741287],
                [107.2027579, 10.7740049],
                [107.2026939, 10.7738127],
                [107.202673, 10.7737738],
                [107.2026111, 10.773711],
                [107.2025722, 10.7736893],
                [107.2024857, 10.7736689],
                [107.2023954, 10.7736822],
                [107.2023169, 10.773728],
                [107.2022641, 10.7737839],
                [107.2022369, 10.7738001],
                [107.2021745, 10.7738086],
                [107.2021261, 10.7737909],
                [107.202106, 10.7737747],
                [107.2020173, 10.7736512],
                [107.2019543, 10.7736148],
                [107.2019184, 10.773607],
                [107.2018496, 10.7736127],
                [107.2017393, 10.773672],
                [107.2016826, 10.7736729],
                [107.2016327, 10.7736465],
                [107.2016135, 10.7736235],
                [107.2015969, 10.7735666],
                [107.2016126, 10.7735095],
                [107.201677, 10.7734542],
                [107.2017075, 10.7734061],
                [107.2017118, 10.7733778],
                [107.2016984, 10.7733251],
                [107.2016421, 10.7732856],
                [107.2015757, 10.7732664],
                [107.2014141, 10.7732684],
                [107.2013437, 10.77325],
                [107.2012914, 10.7732277],
                [107.2012627, 10.7732257],
                [107.2012061, 10.7732418],
                [107.2011815, 10.7732597],
                [107.2011264, 10.7733264],
                [107.201072, 10.7733418],
                [107.2010185, 10.7733239],
                [107.2009072, 10.7732329],
                [107.2008265, 10.7731958],
                [107.2006597, 10.7731347],
                [107.2004861, 10.7730854],
                [107.2003573, 10.7729904],
                [107.2001607, 10.7729086],
                [107.2001132, 10.7728666],
                [107.1999255, 10.77264],
                [107.1995822, 10.772445],
                [107.199153, 10.7720629],
                [107.1991145, 10.7720387],
                [107.1990292, 10.7720068],
                [107.1989332, 10.771999],
                [107.1988341, 10.7720204],
                [107.1987114, 10.7720742],
                [107.1986262, 10.7720823],
                [107.1985827, 10.7720763],
                [107.1985012, 10.7720445],
                [107.1980128, 10.7717757],
                [107.1978924, 10.7717415],
                [107.1977667, 10.7717388],
                [107.1975566, 10.7717863],
                [107.1974042, 10.7717968],
                [107.1972776, 10.7717838],
                [107.197102, 10.77173],
                [107.197035, 10.7717176],
                [107.196898, 10.7717148],
                [107.1967629, 10.7717416],
                [107.1966985, 10.7717658],
                [107.1965861, 10.7718249],
                [107.196531, 10.7718444],
                [107.1964735, 10.7718548],
                [107.1963871, 10.7718529],
                [107.1961918, 10.7717915],
                [107.1960703, 10.7717815],
                [107.1958814, 10.7717954],
                [107.1957542, 10.771813],
                [107.1955854, 10.7718499],
                [107.1954966, 10.7718511],
                [107.1954119, 10.7718248],
                [107.1953738, 10.7718021],
                [107.1953118, 10.7717415],
                [107.1952488, 10.7716306],
                [107.1951845, 10.7715783],
                [107.1951458, 10.7715623],
                [107.1950652, 10.7715537],
                [107.1949871, 10.7715753],
                [107.1947998, 10.7717046],
                [107.1947029, 10.7717524],
                [107.1946499, 10.7717646],
                [107.1945415, 10.7717645],
                [107.1944886, 10.771752],
                [107.194393, 10.7717048],
                [107.1943164, 10.7716313],
                [107.194266, 10.7715386],
                [107.1942107, 10.7714927],
                [107.1941775, 10.771478],
                [107.1941059, 10.7714676],
                [107.1940699, 10.7714722],
                [107.1939885, 10.7715033],
                [107.1939447, 10.7715292],
                [107.1938691, 10.7715966],
                [107.1938076, 10.7716939],
                [107.1937575, 10.7718753],
                [107.1937102, 10.771932],
                [107.1936787, 10.7719521],
                [107.1936103, 10.7719713],
                [107.193543, 10.7719643],
                [107.1934828, 10.7719338],
                [107.1934529, 10.7719018],
                [107.1934184, 10.771839],
                [107.1933541, 10.7717441],
                [107.1932656, 10.7716662],
                [107.1929558, 10.7714865],
                [107.1928781, 10.7714656],
                [107.1928378, 10.7714639],
                [107.1927586, 10.771478],
                [107.1926855, 10.7715155],
                [107.1926272, 10.7715728],
                [107.1924958, 10.7717863],
                [107.1924616, 10.7718201],
                [107.1924215, 10.7718469],
                [107.1923769, 10.7718657],
                [107.1923295, 10.7718759],
                [107.1922385, 10.7718703],
                [107.1921554, 10.7718335],
                [107.1921203, 10.7718047],
                [107.1919628, 10.7716169],
                [107.1919382, 10.7715954],
                [107.1919086, 10.7715813],
                [107.1918436, 10.7715783],
                [107.1918131, 10.7715896],
                [107.1917662, 10.7716334],
                [107.1916065, 10.7720192],
                [107.1915223, 10.7721493],
                [107.1914466, 10.7722324],
                [107.1914172, 10.7722511],
                [107.1913844, 10.772263],
                [107.1913148, 10.7722646],
                [107.1912512, 10.7722368],
                [107.1912082, 10.7721895],
                [107.1911949, 10.7721604],
                [107.1912056, 10.772005],
                [107.1912385, 10.7718944],
                [107.1912626, 10.7718418],
                [107.1914954, 10.7714601],
                [107.1914973, 10.7714028],
                [107.1914712, 10.7713515],
                [107.1914463, 10.7713302],
                [107.1914165, 10.7713165],
                [107.191384, 10.7713114],
                [107.1913513, 10.7713152],
                [107.191321, 10.7713278],
                [107.1909964, 10.7715465],
                [107.1909227, 10.7715826],
                [107.1907829, 10.7716316],
                [107.1906531, 10.7716572],
                [107.1905694, 10.7716382],
                [107.1904948, 10.7715966],
                [107.1904365, 10.771537],
                [107.1903205, 10.7713489],
                [107.1902701, 10.7713266],
                [107.1902427, 10.7713225],
                [107.1902083, 10.7713243],
                [107.190145, 10.77135],
                [107.189953, 10.7714964],
                [107.1896511, 10.7716924],
                [107.1896191, 10.7717217],
                [107.1895683, 10.7717889],
                [107.1895359, 10.771864],
                [107.1895308, 10.7719147],
                [107.189503, 10.7719577],
                [107.1894576, 10.7719842],
                [107.1894309, 10.7719889],
                [107.189378, 10.7719793],
                [107.1892154, 10.7718811],
                [107.1890919, 10.7718399],
                [107.1890277, 10.7718284],
                [107.1882766, 10.7714437],
                [107.1882194, 10.7714345],
                [107.1881135, 10.7713938],
                [107.1879652, 10.7712873],
                [107.1878368, 10.7712224],
                [107.1877023, 10.7711815],
                [107.1873969, 10.7711117],
                [107.1873174, 10.7710576],
                [107.1872567, 10.7709835],
                [107.1872351, 10.7709409],
                [107.187211, 10.7708452],
                [107.1872102, 10.770794],
                [107.1872323, 10.7706943],
                [107.1872548, 10.7706481],
                [107.1873536, 10.7705255],
                [107.1873767, 10.7704776],
                [107.1873909, 10.7704266],
                [107.1873959, 10.770374],
                [107.1873915, 10.7703212],
                [107.1873575, 10.7702256],
                [107.1873288, 10.7701834],
                [107.1872006, 10.770069],
                [107.1871531, 10.7700184],
                [107.187074, 10.7699111],
                [107.1869834, 10.7698201],
                [107.1868799, 10.7697573],
                [107.1867719, 10.7697215],
                [107.1866738, 10.7697056],
                [107.1866336, 10.769692],
                [107.1865627, 10.7696467],
                [107.1865153, 10.7695899],
                [107.1864671, 10.7694919],
                [107.1864179, 10.7694385],
                [107.1863814, 10.7694141],
                [107.1862983, 10.769386],
                [107.1861723, 10.7693761],
                [107.1860124, 10.769339],
                [107.1859359, 10.7693093],
                [107.1857988, 10.7692328],
                [107.185683, 10.7691376],
                [107.1852616, 10.7686678],
                [107.1851599, 10.7685927],
                [107.1850435, 10.768544],
                [107.1849185, 10.7685242],
                [107.1839382, 10.7685105],
                [107.1838408, 10.7684935],
                [107.1837525, 10.7684497],
                [107.1837142, 10.7684188],
                [107.183653, 10.768342],
                [107.1836171, 10.7682511],
                [107.1836037, 10.7681638],
                [107.183568, 10.7680937],
                [107.1835046, 10.7680432],
                [107.1834651, 10.7680288],
                [107.1834234, 10.7680233],
                [107.1833815, 10.7680268],
                [107.1833414, 10.7680394],
                [107.1829095, 10.7681764],
                [107.1828168, 10.7682141],
                [107.1827208, 10.7682432],
                [107.1826225, 10.7682633],
                [107.1825407, 10.7682731],
                [107.1823758, 10.7682739],
                [107.182303, 10.7682553],
                [107.1822703, 10.7682366],
                [107.1822179, 10.7681836],
                [107.1821998, 10.768151],
                [107.1821829, 10.7680785],
                [107.1821849, 10.768041],
                [107.1822093, 10.7679702],
                [107.1822309, 10.7679392],
                [107.1823356, 10.7678575],
                [107.1823528, 10.7678281],
                [107.1823624, 10.7677614],
                [107.1823543, 10.7677284],
                [107.1823145, 10.7676733],
                [107.1822524, 10.7676441],
                [107.1818769, 10.767623],
                [107.1816969, 10.7675817],
                [107.1815028, 10.7675155],
                [107.1813995, 10.7674702],
                [107.1809526, 10.7672279],
                [107.1808773, 10.7671579],
                [107.1808481, 10.7671156],
                [107.1808092, 10.7670185],
                [107.180801, 10.7669655],
                [107.180817, 10.7667227],
                [107.1807986, 10.7665875],
                [107.1807537, 10.7664554],
                [107.1807217, 10.7663932],
                [107.1806404, 10.7662792],
                [107.1806192, 10.7662349],
                [107.1805922, 10.7661408],
                [107.1805868, 10.7660921],
                [107.1805927, 10.7659913],
                [107.1806044, 10.7659421],
                [107.1808013, 10.7653385],
                [107.1808321, 10.7652806],
                [107.1808543, 10.7652191],
                [107.1808674, 10.7651551],
                [107.1808713, 10.76509],
                [107.1808535, 10.7649701],
                [107.1807846, 10.7648094],
                [107.1807675, 10.7647494],
                [107.1807475, 10.7646225],
                [107.1807076, 10.7645695],
                [107.1806793, 10.7645513],
                [107.1806446, 10.7645395],
                [107.1806079, 10.7645367],
                [107.1805718, 10.7645432],
                [107.1802634, 10.7646497],
                [107.1800812, 10.7646868],
                [107.1799269, 10.7646982],
                [107.1798574, 10.7646788],
                [107.1798263, 10.7646603],
                [107.1797767, 10.7646086],
                [107.1797496, 10.7645468],
                [107.1797443, 10.7645135],
                [107.1797512, 10.7644465],
                [107.1797764, 10.764385],
                [107.1797928, 10.7643206],
                [107.1798001, 10.7642546],
                [107.1797982, 10.7641883],
                [107.1797719, 10.7640721],
                [107.1797177, 10.7639656],
                [107.1793476, 10.7634413],
                [107.1793256, 10.7634186],
                [107.1792985, 10.7633622],
                [107.1792947, 10.7633311],
                [107.1793073, 10.76327],
                [107.1793251, 10.7632403],
                [107.1793789, 10.763197],
                [107.1794119, 10.7631857],
                [107.1796346, 10.7631857],
                [107.1796674, 10.763174],
                [107.1797191, 10.7631287],
                [107.1797346, 10.763098],
                [107.1797408, 10.7630337],
                [107.1797181, 10.7629767],
                [107.1796975, 10.7629534],
                [107.1793533, 10.7627132],
                [107.1792921, 10.7626531],
                [107.1792528, 10.7625735],
                [107.1792438, 10.7625283],
                [107.1792485, 10.7623223],
                [107.1792317, 10.7622662],
                [107.1792143, 10.7622422],
                [107.1791672, 10.7622089],
                [107.1791116, 10.7621981],
                [107.1788728, 10.7622318],
                [107.1788153, 10.7622293],
                [107.1787044, 10.7622003],
                [107.1786533, 10.7621744],
                [107.1785649, 10.7621024],
                [107.1785031, 10.7620132],
                [107.1784693, 10.7619157],
                [107.1784624, 10.7618645],
                [107.1784659, 10.7617798],
                [107.1784503, 10.7617156],
                [107.178408, 10.7616643],
                [107.1783462, 10.7616358],
                [107.1783113, 10.7616325],
                [107.178244, 10.7616495],
                [107.1782151, 10.7616689],
                [107.1780375, 10.7618587],
                [107.1780127, 10.7618739],
                [107.1779558, 10.7618835],
                [107.1779273, 10.7618774],
                [107.1778855, 10.7618513],
                [107.1778082, 10.7617109],
                [107.177757, 10.7616771],
                [107.1777254, 10.7616711],
                [107.1776934, 10.7616735],
                [107.1774942, 10.7617522],
                [107.1774126, 10.7617721],
                [107.1773294, 10.761784],
                [107.1771616, 10.7617838],
                [107.1769944, 10.7617509],
                [107.1767605, 10.7616553],
                [107.176591, 10.7616121],
                [107.1764991, 10.761602],
                [107.1759579, 10.7615782],
                [107.1758226, 10.7615516],
                [107.1757081, 10.7614976],
                [107.1756177, 10.761426],
                [107.1755791, 10.7613833],
                [107.17537, 10.7610881],
                [107.1753189, 10.7610487],
                [107.1752572, 10.761032],
                [107.175225, 10.7610329],
                [107.1750404, 10.7610826],
                [107.1749989, 10.7610878],
                [107.1749167, 10.7610745],
                [107.1748433, 10.7610299],
                [107.1748139, 10.7609966],
                [107.1747921, 10.7609581],
                [107.1747787, 10.760916],
                [107.1747422, 10.7606402],
                [107.1747294, 10.7605982],
                [107.1746809, 10.7605249],
                [107.1746082, 10.7604746],
                [107.174521, 10.7604543],
                [107.174432, 10.7604676],
                [107.1743545, 10.7605128],
                [107.1742949, 10.7605731],
                [107.1742232, 10.7606087],
                [107.174143, 10.7606132],
                [107.1741039, 10.7606034],
                [107.1740396, 10.7605651],
                [107.1739945, 10.7605061],
                [107.1739751, 10.7604347],
                [107.1739523, 10.7598419],
                [107.1739211, 10.7596602],
                [107.1738598, 10.7594861],
                [107.1738133, 10.7593943],
                [107.1737585, 10.7593072],
                [107.1736957, 10.7592253],
                [107.1736256, 10.7591495],
                [107.1735486, 10.7590803],
                [107.1732134, 10.7588762],
                [107.1730739, 10.7587694],
                [107.1729571, 10.7586517],
                [107.1728546, 10.7585249],
                [107.172798, 10.7584676],
                [107.1726758, 10.7583755],
                [107.1725455, 10.7583109],
                [107.1719643, 10.7581113],
                [107.1718475, 10.7580407],
                [107.1717956, 10.7579966],
                [107.1717082, 10.7578937],
                [107.1716453, 10.7577755],
                [107.1716238, 10.7577121],
                [107.1715826, 10.7575045],
                [107.1715397, 10.7573728],
                [107.1710622, 10.7566929],
                [107.1710287, 10.7566035],
                [107.1710211, 10.7565563],
                [107.1710247, 10.756461],
                [107.1710363, 10.7564131],
                [107.1710783, 10.756324],
                [107.1713197, 10.756129],
                [107.171357, 10.7561077],
                [107.1713887, 10.756079],
                [107.1714134, 10.7560442],
                [107.1714297, 10.756005],
                [107.1714371, 10.7559632],
                [107.1714351, 10.7559208],
                [107.1714077, 10.7558486],
                [107.171354, 10.7557924],
                [107.1713197, 10.7557733],
                [107.1712097, 10.7557522],
                [107.1711723, 10.7557383],
                [107.1711387, 10.7557169],
                [107.1710897, 10.7556568],
                [107.1710703, 10.7555835],
                [107.1710649, 10.7554175],
                [107.1710527, 10.7553233],
                [107.1710169, 10.7552322],
                [107.1709578, 10.7551503],
                [107.1708798, 10.7550855],
                [107.1703485, 10.7547603],
                [107.1702307, 10.7546639],
                [107.1701074, 10.7545163],
                [107.169803, 10.7540394],
                [107.1697513, 10.7539706],
                [107.1696924, 10.7539075],
                [107.1696271, 10.7538509],
                [107.1695542, 10.7538001],
                [107.1694762, 10.7537574],
                [107.1693939, 10.7537232],
                [107.168944, 10.7536094],
                [107.1687469, 10.7535469],
                [107.1685599, 10.7534595],
                [107.1684353, 10.7533833],
                [107.1683756, 10.7533221],
                [107.1683532, 10.7532857],
                [107.168326, 10.753208],
                [107.168321, 10.7531672],
                [107.1683425, 10.7528852],
                [107.168339, 10.7528598],
                [107.1683125, 10.7528161],
                [107.1682915, 10.7528009],
                [107.1682405, 10.7527892],
                [107.1681907, 10.7528049],
                [107.1680652, 10.7529131],
                [107.1679693, 10.7530264],
                [107.1678691, 10.7531933],
                [107.1678415, 10.7532186],
                [107.1678081, 10.7532361],
                [107.1677714, 10.7532446],
                [107.1677336, 10.7532436],
                [107.1676772, 10.7532223],
                [107.1676344, 10.7531803],
                [107.1676066, 10.7531345],
                [107.167551, 10.7529977],
                [107.1675148, 10.7529774],
                [107.1674937, 10.7529767],
                [107.1674565, 10.7529944],
                [107.1674442, 10.7530107],
                [107.1674375, 10.7530503],
                [107.1674631, 10.7531168],
                [107.167517, 10.7532193],
                [107.1675271, 10.7532831],
                [107.1675254, 10.7533076],
                [107.1675016, 10.7533502],
                [107.1674815, 10.7533648],
                [107.1674334, 10.7533745],
                [107.1673876, 10.7533569],
                [107.1673363, 10.7533101],
                [107.1673045, 10.7532489],
                [107.1672815, 10.753168],
                [107.1672341, 10.7530982],
                [107.1672025, 10.7530697],
                [107.1671654, 10.7530456],
                [107.1670277, 10.7529968],
                [107.1669765, 10.7529703],
                [107.1669289, 10.7529379],
                [107.1668252, 10.7528322],
                [107.166784, 10.7528167],
                [107.1667412, 10.7528272],
                [107.1667125, 10.7528593],
                [107.1667066, 10.7528801],
                [107.1667144, 10.7529221],
                [107.1668994, 10.7531408],
                [107.1669293, 10.753197],
                [107.1669692, 10.7533174],
                [107.166981, 10.753419],
                [107.166977, 10.7539504],
                [107.1669668, 10.7539866],
                [107.1669263, 10.7540499],
                [107.1668967, 10.7540751],
                [107.1668256, 10.754106],
                [107.1665829, 10.7541184],
                [107.1665055, 10.7540992],
                [107.1664435, 10.7540499],
                [107.1664083, 10.7539802],
                [107.1664059, 10.7539024],
                [107.1664339, 10.7537076],
                [107.1664177, 10.7536244],
                [107.1663999, 10.7535856],
                [107.1663369, 10.7535093],
                [107.1662959, 10.7534807],
                [107.1660674, 10.7533664],
                [107.1659472, 10.7532735],
                [107.1658963, 10.7532172],
                [107.1658279, 10.7531122],
                [107.1658019, 10.7530553],
                [107.1657817, 10.7529961],
                [107.1657527, 10.7528524],
                [107.1656981, 10.752693],
                [107.1656121, 10.7525476],
                [107.1655583, 10.752482],
                [107.1654953, 10.7524195],
                [107.1654258, 10.7523639],
                [107.1653509, 10.7523158],
                [107.1652711, 10.7522756],
                [107.1651875, 10.752244],
                [107.1649831, 10.7521959],
                [107.1648754, 10.7521427],
                [107.1647803, 10.7520601],
                [107.1647402, 10.7520077],
                [107.1647079, 10.7519503],
                [107.1646839, 10.7518891],
                [107.1645793, 10.751399],
                [107.1644908, 10.7509379],
                [107.1644616, 10.7508492],
                [107.164405, 10.7507745],
                [107.1643234, 10.75072],
                [107.164228, 10.7506954],
                [107.1640773, 10.750697],
                [107.1639929, 10.7506782],
                [107.1639215, 10.7506341],
                [107.1638699, 10.7505711],
                [107.1638417, 10.7504952],
                [107.1637239, 10.7497544],
                [107.1636888, 10.7496766],
                [107.1636603, 10.7496443],
                [107.1635869, 10.7495992],
                [107.1635444, 10.7495881],
                [107.1634569, 10.7495915],
                [107.1634154, 10.7496059],
                [107.1631014, 10.7497995],
                [107.1630762, 10.7498111],
                [107.1630212, 10.7498165],
                [107.1629692, 10.7497977],
                [107.1629306, 10.7497585],
                [107.162919, 10.7497336],
                [107.1628962, 10.7495834],
                [107.1628704, 10.7495336],
                [107.1628211, 10.7495057],
                [107.1627913, 10.7495031],
                [107.1627358, 10.7495238],
                [107.1625851, 10.7496519],
                [107.1624589, 10.7497308],
                [107.1623182, 10.749781],
                [107.1622465, 10.7497943],
                [107.1621009, 10.7497983],
                [107.1617764, 10.7497402],
                [107.1617225, 10.7497216],
                [107.1616724, 10.7496946],
                [107.1616276, 10.7496598],
                [107.1615688, 10.7495895],
                [107.1615164, 10.7494754],
                [107.1614926, 10.749443],
                [107.161424, 10.7493871],
                [107.1613794, 10.7493673],
                [107.1613318, 10.7493562],
                [107.1612829, 10.7493541],
                [107.1609558, 10.7493875],
                [107.1608402, 10.7493769],
                [107.1607477, 10.7493488],
                [107.1606868, 10.7493023],
                [107.1606647, 10.749271],
                [107.1606418, 10.7491987],
                [107.1606422, 10.7491586],
                [107.1606684, 10.7490832],
                [107.160693, 10.7490513],
                [107.1607599, 10.7490063],
                [107.1607905, 10.7489773],
                [107.1608362, 10.7489072],
                [107.160858, 10.7488267],
                [107.1608591, 10.7487849],
                [107.1608387, 10.7486961],
                [107.1607894, 10.7486189],
                [107.1603656, 10.748192],
                [107.1603282, 10.7481647],
                [107.1602426, 10.7481293],
                [107.1601966, 10.7481222],
                [107.1601045, 10.7481299],
                [107.1600196, 10.7481657],
                [107.1598095, 10.7482918],
                [107.1597245, 10.7483159],
                [107.1596732, 10.7483199],
                [107.1595716, 10.7483053],
                [107.1593046, 10.7481933],
                [107.1591935, 10.7481789],
                [107.1591363, 10.7481849],
                [107.1590808, 10.7481999],
                [107.1588808, 10.7482825],
                [107.1588153, 10.748279],
                [107.158766, 10.7482477],
                [107.1587486, 10.7482242],
                [107.1586501, 10.7479664],
                [107.1585906, 10.7478724],
                [107.1585477, 10.7478274],
                [107.1584451, 10.7477572],
                [107.1582466, 10.7476755],
                [107.158155, 10.7476301],
                [107.158056, 10.7475552],
                [107.1580057, 10.7475022],
                [107.1579623, 10.7474437],
                [107.1575407, 10.7467275],
                [107.157502, 10.7467044],
                [107.1574795, 10.7467005],
                [107.1574302, 10.7467118],
                [107.1574098, 10.7467269],
                [107.1573347, 10.7468481],
                [107.1573157, 10.7468906],
                [107.1572974, 10.7469056],
                [107.1572515, 10.7469166],
                [107.157205, 10.7468976],
                [107.1571799, 10.7468547],
                [107.1571706, 10.7464552],
                [107.1571486, 10.7461692],
                [107.1571711, 10.7460997],
                [107.157214, 10.746045],
                [107.157273, 10.7460075],
                [107.1573235, 10.7459877],
                [107.1573582, 10.7459467],
                [107.1573689, 10.7458943],
                [107.1573642, 10.7458678],
                [107.1573355, 10.7458217],
                [107.1573133, 10.7458054],
                [107.1572603, 10.7457911],
                [107.1571491, 10.7458012],
                [107.1570677, 10.7458388],
                [107.1570315, 10.7458699],
                [107.1570021, 10.7459073],
                [107.1569391, 10.7461486],
                [107.1568933, 10.7462145],
                [107.1568251, 10.7462578],
                [107.1567848, 10.7462689],
                [107.1567018, 10.7462662],
                [107.1565846, 10.7462147],
                [107.1564952, 10.7462025],
                [107.1564534, 10.7462069],
                [107.1563262, 10.7462504],
                [107.1562758, 10.746258],
                [107.1562248, 10.7462565],
                [107.1561749, 10.7462461],
                [107.1561277, 10.746227],
                [107.1560848, 10.7461999],
                [107.1560202, 10.7461303],
                [107.1559979, 10.7460884],
                [107.1559762, 10.7459967],
                [107.1559586, 10.7456142],
                [107.1559386, 10.74557],
                [107.1558998, 10.7455465],
                [107.1558769, 10.7455437],
                [107.1557602, 10.7455793],
                [107.1556881, 10.7455815],
                [107.1556186, 10.7455568],
                [107.1555881, 10.7455346],
                [107.1555626, 10.7455071],
                [107.1555032, 10.7454145],
                [107.155439, 10.7452903],
                [107.1554142, 10.7452275],
                [107.1553753, 10.745047],
                [107.1553562, 10.745015],
                [107.1553203, 10.7450035],
                [107.1553012, 10.7450079],
                [107.1552739, 10.7450347],
                [107.1552566, 10.7452782],
                [107.1552412, 10.7453158],
                [107.1552044, 10.7453416],
                [107.1551799, 10.7453454],
                [107.1551335, 10.7453297],
                [107.1551165, 10.7453118],
                [107.1550685, 10.7452624],
                [107.1550253, 10.745209],
                [107.1549878, 10.7451531],
                [107.1549556, 10.7450941],
                [107.1549287, 10.7450325],
                [107.1548429, 10.7447887],
                [107.154815, 10.7447234],
                [107.1547812, 10.7446609],
                [107.1547423, 10.7446024],
                [107.1546981, 10.7445476],
                [107.1545452, 10.7443895],
                [107.1544017, 10.7442406],
                [107.1543825, 10.7442287],
                [107.1543612, 10.7442211],
                [107.1543387, 10.7442182],
                [107.1543129, 10.7442208],
                [107.1542886, 10.7442296],
                [107.1542673, 10.7442439],
                [107.1542501, 10.744263],
                [107.1541697, 10.7443921],
                [107.154151, 10.744414],
                [107.1541278, 10.7444312],
                [107.1541012, 10.7444429],
                [107.1540727, 10.7444484],
                [107.1540436, 10.7444475],
                [107.1540135, 10.7444394],
                [107.1539863, 10.7444244],
                [107.1539636, 10.7444035],
                [107.1539466, 10.7443778],
                [107.1539364, 10.7443488],
                [107.1538933, 10.7441335],
                [107.1538806, 10.7441064],
                [107.1538615, 10.7440832],
                [107.1538371, 10.7440654],
                [107.1538089, 10.744054],
                [107.1537787, 10.7440499],
                [107.1537483, 10.7440532],
                [107.1537198, 10.7440639],
                [107.1536949, 10.7440812],
                [107.1534857, 10.7442103],
                [107.1534369, 10.7442334],
                [107.1533857, 10.744251],
                [107.1533328, 10.744263],
                [107.153273, 10.7442694],
                [107.1532128, 10.7442685],
                [107.1531531, 10.7442604],
                [107.1531168, 10.7442593],
                [107.153081, 10.7442653],
                [107.153047, 10.7442781],
                [107.1530163, 10.7442973],
                [107.1529893, 10.7443229],
                [107.1529681, 10.7443534],
                [107.1529536, 10.7443875],
                [107.1529466, 10.7444237],
                [107.1529394, 10.7444603],
                [107.1529229, 10.7444938],
                [107.1528982, 10.744522],
                [107.152867, 10.7445431],
                [107.1528313, 10.7445555],
                [107.1527953, 10.7445586],
                [107.1527598, 10.744553],
                [107.1527267, 10.7445389],
                [107.1526981, 10.7445174],
                [107.1526757, 10.7444896],
                [107.152614, 10.7443526],
                [107.1526014, 10.7443299],
                [107.1525826, 10.7443117],
                [107.1525594, 10.7442996],
                [107.1525335, 10.7442946],
                [107.152507, 10.7442972],
                [107.1524824, 10.7443073],
                [107.1524619, 10.744324],
                [107.1524472, 10.7443459],
                [107.1524397, 10.744371],
                [107.1523914, 10.744487],
                [107.1523697, 10.7445198],
                [107.1523426, 10.7445486],
                [107.1523109, 10.7445724],
                [107.1522756, 10.7445906],
                [107.1522377, 10.7446026],
                [107.1521982, 10.7446082],
                [107.1521586, 10.7446071],
                [107.1521196, 10.7445995],
                [107.1520826, 10.7445855],
                [107.1520484, 10.7445655],
                [107.1520182, 10.7445402],
                [107.1519928, 10.7445102],
                [107.1519729, 10.7444764],
                [107.151812, 10.7442498],
                [107.1517717, 10.7442067],
                [107.1517254, 10.7441698],
                [107.1516741, 10.7441401],
                [107.1516189, 10.7441181],
                [107.1515589, 10.744104],
                [107.1514975, 10.744099],
                [107.1514359, 10.7441033],
                [107.1513758, 10.7441168],
                [107.1513185, 10.7441391],
                [107.1507767, 10.7442867],
                [107.1487677, 10.7446556],
                [107.1486405, 10.74468],
                [107.1485118, 10.7446952],
                [107.1483823, 10.7447011],
                [107.1482527, 10.7446978],
                [107.1481438, 10.7446878],
                [107.1480357, 10.7446712],
                [107.1479288, 10.7446482],
                [107.1478236, 10.7446187],
                [107.1477256, 10.7445843],
                [107.1476257, 10.744556],
                [107.1475241, 10.7445341],
                [107.1474212, 10.7445186],
                [107.1473235, 10.7445099],
                [107.1472254, 10.744507],
                [107.1471274, 10.7445099],
                [107.1470296, 10.7445186],
                [107.1384305, 10.7450088],
                [107.1389044, 10.7397513],
                [107.1388362, 10.7373386],
                [107.1390292, 10.7325411],
                [107.1441436, 10.732639],
                [107.1441972, 10.7322173],
                [107.1447846, 10.7319169],
                [107.1447887, 10.7318352],
                [107.1451521, 10.7316362],
                [107.1462893, 10.7310696],
                [107.1463028, 10.729839],
                [107.145423, 10.7298679],
                [107.1449026, 10.7301051],
                [107.1445633, 10.7299424],
                [107.1435126, 10.7291956],
                [107.1434516, 10.7291274],
                [107.1434216, 10.729047],
                [107.1434375, 10.7280884],
                [107.1438405, 10.7280983],
                [107.1438847, 10.728041],
                [107.143945, 10.7279991],
                [107.1439797, 10.7279854],
                [107.144132, 10.7279557],
                [107.1441873, 10.7279358],
                [107.1442401, 10.7279099],
                [107.1443291, 10.7278519],
                [107.1443685, 10.7277954],
                [107.144389, 10.7277109],
                [107.1444306, 10.7276398],
                [107.1444363, 10.7274428],
                [107.1444482, 10.7274084],
                [107.1444774, 10.7273871],
                [107.1445754, 10.7273871],
                [107.144617, 10.7273697],
                [107.1446416, 10.727336],
                [107.1447256, 10.7271259],
                [107.1447989, 10.7270349],
                [107.1448968, 10.726949],
                [107.1450208, 10.7268761],
                [107.1451923, 10.726807],
                [107.1452381, 10.7267789],
                [107.145277, 10.7267272],
                [107.1453328, 10.7265105],
                [107.1453456, 10.726485],
                [107.1453824, 10.7264488],
                [107.145465, 10.7264115],
                [107.145492, 10.7263786],
                [107.1455035, 10.7263392],
                [107.1455301, 10.725353],
                [107.1455067, 10.7252313],
                [107.1454847, 10.7251731],
                [107.145435, 10.7250845],
                [107.1454042, 10.725044],
                [107.1451012, 10.7247966],
                [107.1447819, 10.7245696],
                [107.1446018, 10.724455],
                [107.1440844, 10.7241647],
                [107.1440068, 10.7241348],
                [107.1438719, 10.7241014],
                [107.1436211, 10.7240827],
                [107.1435723, 10.7240636],
                [107.1435336, 10.7240249],
                [107.1435213, 10.7240004],
                [107.1435034, 10.7238664],
                [107.1434811, 10.7238251],
                [107.1434622, 10.7238088],
                [107.1434154, 10.7237922],
                [107.142494, 10.7237961],
                [107.1424533, 10.7238051],
                [107.1424216, 10.7238317],
                [107.1424065, 10.7238675],
                [107.1424162, 10.7241558],
                [107.142195, 10.7241743],
                [107.1421689, 10.7241827],
                [107.1421266, 10.7242165],
                [107.1421067, 10.7242581],
                [107.1420917, 10.7248845],
                [107.1420729, 10.7249913],
                [107.1420273, 10.7251599],
                [107.1419696, 10.7251915],
                [107.1418905, 10.7253839],
                [107.1410778, 10.7261772],
                [107.141002, 10.7266972],
                [107.1410108, 10.7267991],
                [107.1410262, 10.7268503],
                [107.141096, 10.7269767],
                [107.1411063, 10.7270126],
                [107.141101, 10.7270865],
                [107.1410646, 10.727149],
                [107.1410064, 10.7271893],
                [107.1409363, 10.727202],
                [107.1406654, 10.7271403],
                [107.1405764, 10.7271504],
                [107.1405331, 10.7271664],
                [107.1396475, 10.7277847],
                [107.1395899, 10.7278133],
                [107.1394685, 10.7278559],
                [107.1393864, 10.7278726],
                [107.139219, 10.7278796],
                [107.1393357, 10.7204848],
                [107.1395677, 10.7203477],
                [107.1396253, 10.7203076],
                [107.1396791, 10.7202627],
                [107.1397287, 10.7202133],
                [107.1397803, 10.7201512],
                [107.1398252, 10.7200842],
                [107.1398695, 10.7199722],
                [107.1398802, 10.7199477],
                [107.1398959, 10.7199258],
                [107.1399157, 10.7199076],
                [107.1399378, 10.7198943],
                [107.1399622, 10.7198855],
                [107.1399878, 10.7198815],
                [107.1400136, 10.7198826],
                [107.140079, 10.7198867],
                [107.1401444, 10.7198826],
                [107.1402059, 10.7198711],
                [107.1402655, 10.7198524],
                [107.1403224, 10.7198268],
                [107.1403757, 10.7197946],
                [107.1405856, 10.7196559],
                [107.1406101, 10.719629],
                [107.1406312, 10.7195994],
                [107.1406487, 10.7195676],
                [107.1406649, 10.7195257],
                [107.1406748, 10.7194819],
                [107.1406782, 10.7194372],
                [107.1407251, 10.7188403],
                [107.1407269, 10.7187908],
                [107.1407194, 10.7187418],
                [107.140703, 10.718695],
                [107.1406783, 10.7186521],
                [107.140646, 10.7186143],
                [107.1406073, 10.7185829],
                [107.1405635, 10.7185589],
                [107.1400934, 10.718416],
                [107.1395463, 10.7181893],
                [107.139648, 10.7142057],
                [107.1397673, 10.7096234],
                [107.1394819, 10.7096518],
                [107.1393944, 10.7096377],
                [107.139353, 10.7096218],
                [107.1392764, 10.7095716],
                [107.1392433, 10.7095375],
                [107.1391947, 10.7094566],
                [107.1389669, 10.7089293],
                [107.138747, 10.7089257],
                [107.138519, 10.7083617],
                [107.1384843, 10.7083048],
                [107.1384332, 10.7082616],
                [107.1383673, 10.7082356],
                [107.1383319, 10.7082311],
                [107.1379745, 10.7082803],
                [107.1379557, 10.7082774],
                [107.1379235, 10.7082537],
                [107.13789, 10.7081375],
                [107.1378556, 10.7081063],
                [107.137831, 10.7080995],
                [107.1376798, 10.7081113],
                [107.1374689, 10.7081627],
                [107.1372758, 10.7081759],
                [107.137233, 10.7078628],
                [107.1372357, 10.7077899],
                [107.1372476, 10.707718],
                [107.1372892, 10.7075996],
                [107.1373194, 10.7075444],
                [107.1375841, 10.7071662],
                [107.1376908, 10.7069351],
                [107.1377318, 10.7068147],
                [107.137778, 10.7066253],
                [107.1378063, 10.70641],
                [107.1378123, 10.7062905],
                [107.1378022, 10.7056657],
                [107.1376648, 10.705303],
                [107.1374974, 10.7049549],
                [107.1371753, 10.7040246],
                [107.1370664, 10.7034754],
                [107.1370288, 10.7033567],
                [107.1369243, 10.7025872],
                [107.1368574, 10.7017599],
                [107.1368544, 10.7014407],
                [107.1368295, 10.7009216],
                [107.1368797, 10.7005216],
                [107.1368685, 10.7004011],
                [107.1364084, 10.6996779],
                [107.1362651, 10.6994048],
                [107.1361533, 10.6991439],
                [107.1357018, 10.6979644],
                [107.1352065, 10.6968417],
                [107.1349049, 10.6961275],
                [107.1347618, 10.6958432],
                [107.1342181, 10.6949124],
                [107.1341946, 10.6948123],
                [107.1341965, 10.6947284],
                [107.1342047, 10.6946871],
                [107.1343374, 10.6942707],
                [107.1344072, 10.6939511],
                [107.1344474, 10.6936058],
                [107.1344634, 10.6931949],
                [107.1344421, 10.6929834],
                [107.134343, 10.6925047],
                [107.1343007, 10.6922397],
                [107.1341493, 10.69106],
                [107.1341094, 10.6909327],
                [107.1338885, 10.6904024],
                [107.133854, 10.6903024],
                [107.1338028, 10.6900974],
                [107.1337743, 10.6898637],
                [107.1337717, 10.6897339],
                [107.1337785, 10.6896043],
                [107.1339311, 10.6880202],
                [107.1339153, 10.6877893],
                [107.1338885, 10.6865722],
                [107.1338435, 10.6860658],
                [107.1338383, 10.6855855],
                [107.133832, 10.6853524],
                [107.1338375, 10.6850708],
                [107.1334142, 10.6848328],
                [107.1332842, 10.6847473],
                [107.1331841, 10.6845517],
                [107.1331036, 10.6843777],
                [107.1328405, 10.6841783],
                [107.1325323, 10.6839929],
                [107.1323821, 10.6839349],
                [107.1320924, 10.6839375],
                [107.1319181, 10.6839323],
                [107.1318446, 10.6839147],
                [107.1317581, 10.6838975],
                [107.1316123, 10.6837847],
                [107.1313199, 10.6835791],
                [107.1310088, 10.6834157],
                [107.1308452, 10.6833735],
                [107.1307272, 10.6834051],
                [107.1305126, 10.6835026],
                [107.1303517, 10.6835764],
                [107.130062, 10.6836634],
                [107.1299352, 10.6837105],
                [107.1297616, 10.6837188],
                [107.1296774, 10.6837445],
                [107.1295631, 10.6837161],
                [107.1294877, 10.6836923],
                [107.1293485, 10.6835896],
                [107.1292928, 10.6834586],
                [107.129252, 10.6833919],
                [107.1290152, 10.6832594],
                [107.1285391, 10.6830048],
                [107.1280485, 10.682764],
                [107.1277177, 10.6826803],
                [107.1272608, 10.6825985],
                [107.1268272, 10.682572],
                [107.1265376, 10.6824615],
                [107.1262443, 10.682318],
                [107.1260038, 10.6822823],
                [107.12558, 10.6823535],
                [107.1250476, 10.682453],
                [107.1250275, 10.6821901],
                [107.1249792, 10.6820056],
                [107.1249706, 10.6819426],
                [107.1249731, 10.681853],
                [107.125004, 10.6818103],
                [107.1250537, 10.6817917],
                [107.1251056, 10.6818034],
                [107.1251556, 10.681857],
                [107.1251762, 10.6818691],
                [107.1252233, 10.6818764],
                [107.125273, 10.6818565],
                [107.1253037, 10.6818132],
                [107.1253075, 10.6817631],
                [107.1253037, 10.681713],
                [107.1252821, 10.6816384],
                [107.1252394, 10.6815733],
                [107.1252082, 10.6815436],
                [107.1251335, 10.6815009],
                [107.1250493, 10.6814767],
                [107.1249711, 10.6814362],
                [107.1249164, 10.6813881],
                [107.1248711, 10.6813282],
                [107.1248538, 10.6813188],
                [107.1248154, 10.6813169],
                [107.1247418, 10.6813704],
                [107.1246786, 10.6813902],
                [107.1245876, 10.6813941],
                [107.1245265, 10.6813875],
                [107.1244669, 10.681373],
                [107.1244487, 10.6813555],
                [107.1244267, 10.6813109],
                [107.124424, 10.681286],
                [107.1244382, 10.6812336],
                [107.1244765, 10.6811945],
                [107.124563, 10.6811553],
                [107.1245884, 10.6811332],
                [107.1246241, 10.681077],
                [107.1246359, 10.6810119],
                [107.12461, 10.6809258],
                [107.1245548, 10.6808572],
                [107.1245196, 10.6808324],
                [107.1244546, 10.6808015],
                [107.1244264, 10.6807958],
                [107.1243703, 10.6808063],
                [107.1243328, 10.6808354],
                [107.1243197, 10.6808553],
                [107.1242978, 10.6809273],
                [107.1242589, 10.6809619],
                [107.1242335, 10.6809697],
                [107.1241864, 10.6809651],
                [107.1241652, 10.6809541],
                [107.1241267, 10.6809133],
                [107.1241007, 10.6808542],
                [107.1240967, 10.6808221],
                [107.1241083, 10.6807569],
                [107.1241563, 10.6806568],
                [107.1241611, 10.6805796],
                [107.1241515, 10.6805377],
                [107.1241076, 10.6804637],
                [107.1238232, 10.6802343],
                [107.1237807, 10.6801878],
                [107.1237599, 10.6801287],
                [107.1237588, 10.6800973],
                [107.1237799, 10.6800287],
                [107.1238315, 10.6799716],
                [107.1238366, 10.6799312],
                [107.1238282, 10.6799115],
                [107.1237939, 10.6798862],
                [107.1235979, 10.6798812],
                [107.1235583, 10.6798624],
                [107.1235337, 10.6798267],
                [107.1235308, 10.6797836],
                [107.1235387, 10.6797617],
                [107.123593, 10.6796958],
                [107.1236189, 10.6796222],
                [107.1236188, 10.6795469],
                [107.1236098, 10.6795115],
                [107.1235771, 10.6794507],
                [107.1235235, 10.6794137],
                [107.1234914, 10.6794061],
                [107.1234293, 10.6794143],
                [107.1233801, 10.6794476],
                [107.1233288, 10.6795368],
                [107.1232656, 10.6795978],
                [107.1232268, 10.6796191],
                [107.1231407, 10.6796399],
                [107.1230511, 10.6796292],
                [107.1229698, 10.6795868],
                [107.1229097, 10.6795183],
                [107.1228903, 10.6794771],
                [107.1228606, 10.6793647],
                [107.1228516, 10.6792945],
                [107.1228524, 10.6792237],
                [107.1228629, 10.6791537],
                [107.1228831, 10.6790851],
                [107.1229127, 10.6790199],
                [107.1229509, 10.6789592],
                [107.1229972, 10.6789042],
                [107.123105, 10.6788142],
                [107.1231339, 10.6787528],
                [107.1231365, 10.6787188],
                [107.1231198, 10.678658],
                [107.1230762, 10.6786091],
                [107.1230434, 10.6785542],
                [107.1230251, 10.6783975],
                [107.1229917, 10.6782048],
                [107.1229407, 10.6780207],
                [107.1228728, 10.6778418],
                [107.1228675, 10.6777941],
                [107.1228821, 10.6777025],
                [107.1229256, 10.6776237],
                [107.1230134, 10.6775333],
                [107.123044, 10.6774593],
                [107.123048, 10.6774194],
                [107.1230313, 10.6773378],
                [107.123011, 10.6773011],
                [107.1229005, 10.6771663],
                [107.1228295, 10.6770296],
                [107.1228049, 10.6769567],
                [107.1227878, 10.6768817],
                [107.1227769, 10.6767391],
                [107.1227816, 10.6766677],
                [107.1228152, 10.6764873],
                [107.1228093, 10.6763756],
                [107.1227735, 10.6762638],
                [107.1227449, 10.6762122],
                [107.1225518, 10.6759222],
                [107.1223426, 10.6755532],
                [107.1222085, 10.6753424],
                [107.1220905, 10.6751842],
                [107.1217847, 10.6748626],
                [107.1217164, 10.6747757],
                [107.1216145, 10.6746082],
                [107.1215379, 10.6744277],
                [107.121495, 10.6743671],
                [107.1213663, 10.6742722],
                [107.1213359, 10.6742301],
                [107.1213394, 10.6741787],
                [107.1213697, 10.6741441],
                [107.1213912, 10.674135],
                [107.1215185, 10.6741135],
                [107.1215864, 10.6740686],
                [107.1216298, 10.6740004],
                [107.1216412, 10.673919],
                [107.1216333, 10.6738775],
                [107.1216164, 10.6738387],
                [107.1215913, 10.6738044],
                [107.1214916, 10.6737311],
                [107.1214548, 10.6736711],
                [107.1214476, 10.67364],
                [107.1214467, 10.6734235],
                [107.1214394, 10.673381],
                [107.1214069, 10.6733011],
                [107.121347, 10.673228],
                [107.12126, 10.6731732],
                [107.1212107, 10.6731573],
                [107.1206469, 10.6731607],
                [107.1206067, 10.6731677],
                [107.1205321, 10.6731995],
                [107.1204689, 10.6732547],
                [107.1204073, 10.6733429],
                [107.1203806, 10.6733626],
                [107.1203174, 10.673383],
                [107.1202504, 10.6733749],
                [107.1202194, 10.6733599],
                [107.1201713, 10.6733119],
                [107.1201405, 10.6732562],
                [107.1201198, 10.6732349],
                [107.1200679, 10.6732059],
                [107.1200386, 10.6731995],
                [107.119967, 10.6732076],
                [107.1198549, 10.6732814],
                [107.1198243, 10.6732892],
                [107.1197616, 10.6732836],
                [107.1197059, 10.6732498],
                [107.1196713, 10.6731924],
                [107.1196657, 10.6731592],
                [107.1196733, 10.6729827],
                [107.119655, 10.6728937],
                [107.1196047, 10.6727979],
                [107.1195445, 10.6727284],
                [107.1195088, 10.6726631],
                [107.1194937, 10.6725916],
                [107.1194891, 10.672453],
                [107.1194762, 10.6724034],
                [107.1194396, 10.6723256],
                [107.1193873, 10.6722796],
                [107.1193185, 10.6722607],
                [107.1192821, 10.6722634],
                [107.1191996, 10.6722908],
                [107.1190974, 10.6723122],
                [107.1187216, 10.6723296],
                [107.1185172, 10.67235],
                [107.1182714, 10.6723849],
                [107.1181865, 10.672377],
                [107.1181074, 10.6723455],
                [107.1180378, 10.6722896],
                [107.1179887, 10.6722157],
                [107.1179733, 10.6721741],
                [107.1179412, 10.6718891],
                [107.1179062, 10.6718025],
                [107.1178794, 10.6717622],
                [107.1178104, 10.6716944],
                [107.1175285, 10.6715323],
                [107.1174805, 10.6714723],
                [107.1174583, 10.6713992],
                [107.1174582, 10.6713583],
                [107.1174663, 10.6713183],
                [107.1174993, 10.6712404],
                [107.1175066, 10.6711541],
                [107.1174846, 10.6710837],
                [107.1174647, 10.6710524],
                [107.1173643, 10.6709624],
                [107.1172035, 10.670851],
                [107.1170412, 10.6707639],
                [107.1168759, 10.6706922],
                [107.1167985, 10.6706506],
                [107.116728, 10.6706047],
                [107.1166441, 10.6705346],
                [107.1165987, 10.6705116],
                [107.1165459, 10.6705112],
                [107.1164975, 10.6705356],
                [107.1162513, 10.6707877],
                [107.1161643, 10.6708477],
                [107.1160662, 10.6708878],
                [107.1160094, 10.6709007],
                [107.1158933, 10.670906],
                [107.1158355, 10.6708984],
                [107.1156076, 10.670822],
                [107.1155534, 10.6707899],
                [107.1155329, 10.670766],
                [107.1155098, 10.6707082],
                [107.1155131, 10.6706487],
                [107.1155234, 10.6706218],
                [107.1155774, 10.6705533],
                [107.1155891, 10.6705261],
                [107.1155908, 10.6704676],
                [107.1155653, 10.6704166],
                [107.1155205, 10.6703837],
                [107.1154935, 10.6703756],
                [107.1154654, 10.6703739],
                [107.1144623, 10.6705294],
                [107.1131855, 10.6699495],
                [107.1130446, 10.66991],
                [107.1127572, 10.6698546],
                [107.1124781, 10.6698314],
                [107.1123444, 10.6698307],
                [107.1122108, 10.6698367],
                [107.1120216, 10.6698569],
                [107.1119084, 10.6698556],
                [107.111791, 10.6698302],
                [107.1117318, 10.6698072],
                [107.111595, 10.6697281],
                [107.1115137, 10.6697058],
                [107.1114215, 10.669703],
                [107.1112348, 10.6697432],
                [107.1111444, 10.6697334],
                [107.1110986, 10.669717],
                [107.1110183, 10.6696635],
                [107.1109892, 10.6696251],
                [107.1109679, 10.669582],
                [107.1109517, 10.6694977],
                [107.1109639, 10.6693803],
                [107.1109431, 10.6693415],
                [107.110903, 10.6693182],
                [107.1108372, 10.6693063],
                [107.1107648, 10.6692708],
                [107.1106911, 10.6692075],
                [107.1105905, 10.6690968],
                [107.1105049, 10.6690405],
                [107.1104185, 10.669015],
                [107.1102979, 10.6690152],
                [107.1102129, 10.6690015],
                [107.1101359, 10.6689637],
                [107.1100705, 10.668901],
                [107.1100286, 10.6688214],
                [107.1099254, 10.6683585],
                [107.1098967, 10.6682669],
                [107.1098172, 10.668098],
                [107.1097122, 10.6679499],
                [107.1096504, 10.667883],
                [107.1085574, 10.6670777],
                [107.1084836, 10.6670293],
                [107.1083528, 10.6669118],
                [107.1082491, 10.6667709],
                [107.1082087, 10.6666936],
                [107.1081344, 10.6664952],
                [107.1080711, 10.666399],
                [107.1079954, 10.6663252],
                [107.1078587, 10.6662435],
                [107.1078096, 10.6662046],
                [107.1077299, 10.6661091],
                [107.1076876, 10.6660209],
                [107.1076552, 10.6658787],
                [107.1076099, 10.6657934],
                [107.1075424, 10.6657324],
                [107.1073861, 10.6656589],
                [107.107335, 10.6656212],
                [107.1072577, 10.6655348],
                [107.1072096, 10.6654414],
                [107.1071944, 10.6653911],
                [107.1071559, 10.6650703],
                [107.1071233, 10.6650075],
                [107.1070646, 10.6649538],
                [107.1069816, 10.6649229],
                [107.1067303, 10.6649203],
                [107.1066542, 10.6649093],
                [107.106508, 10.6648629],
                [107.1064398, 10.664828],
                [107.1063337, 10.6647522],
                [107.1062441, 10.6646581],
                [107.106217, 10.6646181],
                [107.1061377, 10.6644504],
                [107.1061063, 10.6644012],
                [107.1060699, 10.6643663],
                [107.1059832, 10.6643237],
                [107.1059355, 10.664314],
                [107.1058492, 10.6643172],
                [107.1057679, 10.6643461],
                [107.1056945, 10.6643944],
                [107.1056091, 10.6644212],
                [107.1055641, 10.664422],
                [107.1054807, 10.6643995],
                [107.1054137, 10.6643509],
                [107.105388, 10.6643185],
                [107.105326, 10.6641684],
                [107.1052452, 10.6640294],
                [107.1051739, 10.6639425],
                [107.1051172, 10.6639139],
                [107.1050558, 10.6639148],
                [107.105028, 10.6639254],
                [107.1049491, 10.6639897],
                [107.1049101, 10.6640084],
                [107.1048336, 10.6640208],
                [107.1047661, 10.6640083],
                [107.1046668, 10.6639714],
                [107.1045327, 10.663945],
                [107.1041156, 10.6639349],
                [107.1039695, 10.6639397],
                [107.1038089, 10.6639562],
                [107.1037281, 10.6639561],
                [107.1035453, 10.6639347],
                [107.1034449, 10.66391],
                [107.1033472, 10.6638765],
                [107.1028402, 10.6636498],
                [107.1023601, 10.6632557],
                [107.1021206, 10.6630848],
                [107.1020007, 10.6629065],
                [107.1015619, 10.6628974],
                [107.1014938, 10.6628722],
                [107.1014248, 10.6628146],
                [107.1013999, 10.6627773],
                [107.101196, 10.6623042],
                [107.1011585, 10.6620972],
                [107.10107, 10.6620577],
                [107.1011102, 10.6618205],
                [107.1011223, 10.6609084],
                [107.1011845, 10.660872],
                [107.1010681, 10.6600605],
                [107.1010233, 10.6598208],
                [107.1007768, 10.6589306],
                [107.1006967, 10.658755],
                [107.1005239, 10.6585078],
                [107.1002746, 10.6582515],
                [107.0995274, 10.6575998],
                [107.0992565, 10.657306],
                [107.099023, 10.6569205],
                [107.098297, 10.6554014],
                [107.0972312, 10.6532583],
                [107.0969977, 10.6527489],
                [107.0966849, 10.6521339],
                [107.096489, 10.6519593],
                [107.0963254, 10.652049],
                [107.0960303, 10.6521465],
                [107.0957621, 10.6522387],
                [107.0955985, 10.6522915],
                [107.095274, 10.6524154],
                [107.0950165, 10.6525234],
                [107.0940965, 10.6529874],
                [107.0939624, 10.6530401],
                [107.0936968, 10.653106],
                [107.0935493, 10.6531218],
                [107.0926239, 10.653164],
                [107.0922431, 10.6531693],
                [107.0915376, 10.6532035],
                [107.0913177, 10.6532035],
                [107.0910758, 10.653221],
                [107.0910361, 10.6532167],
                [107.090902, 10.6532299],
                [107.0907652, 10.6532457],
                [107.0906311, 10.6532668],
                [107.0905184, 10.65328],
                [107.0904004, 10.6532879],
                [107.0900839, 10.65328],
                [107.0895555, 10.6532562],
                [107.0891451, 10.6532299],
                [107.0887374, 10.6532035],
                [107.0882251, 10.6531877],
                [107.087914, 10.6532009],
                [107.0873936, 10.6532431],
                [107.086479, 10.6533643],
                [107.0861035, 10.6534118],
                [107.0856609, 10.6534566],
                [107.0852854, 10.6534987],
                [107.0848884, 10.6535172],
                [107.0842984, 10.6535357],
                [107.0838504, 10.6535515],
                [107.0832533, 10.6535875],
                [107.0830189, 10.6536016],
                [107.0829852, 10.6536035],
                [107.0825925, 10.6536253],
                [107.0818897, 10.653649],
                [107.0816204, 10.653651],
                [107.0815437, 10.6536516],
                [107.0812004, 10.6536279],
                [107.0806586, 10.6535963],
                [107.0800256, 10.6535567],
                [107.0796555, 10.6535383],
                [107.0795302, 10.6535302],
                [107.0792451, 10.6535119],
                [107.0790455, 10.6534953],
                [107.0788347, 10.6534777],
                [107.0782875, 10.6534091],
                [107.0779898, 10.653388],
                [107.0778085, 10.6533899],
                [107.0772047, 10.6534664],
                [107.076851, 10.6535263],
                [107.0765136, 10.6535823],
                [107.0761802, 10.6536342],
                [107.0755948, 10.6537261],
                [107.075237, 10.653774],
                [107.0749403, 10.653818],
                [107.0747533, 10.653818],
                [107.0745215, 10.65381],
                [107.0741394, 10.653774],
                [107.0738792, 10.6537581],
                [107.0736312, 10.6537621],
                [107.0733791, 10.653782],
                [107.0730823, 10.653814],
                [107.0728587, 10.653814],
                [107.0726026, 10.653794],
                [107.0719887, 10.6537381],
                [107.0716719, 10.6536994],
                [107.0713017, 10.6536542],
                [107.0705821, 10.6535583],
                [107.0701873, 10.653523],
                [107.0694922, 10.6534868],
                [107.0692727, 10.653505],
                [107.0684997, 10.6535089],
                [107.0679052, 10.6535335],
                [107.0674415, 10.6535196],
                [107.0670415, 10.6535156],
                [107.0668936, 10.6535217],
                [107.0667334, 10.653555],
                [107.0665855, 10.6536065],
                [107.0664161, 10.6537004],
                [107.0662497, 10.6538124],
                [107.0661111, 10.6539244],
                [107.0659447, 10.6541031],
                [107.0657814, 10.6542757],
                [107.0656797, 10.654421],
                [107.065615, 10.6545209],
                [107.0655503, 10.6546602],
                [107.0654979, 10.6548116],
                [107.0654455, 10.6549781],
                [107.0653808, 10.6552083],
                [107.0653069, 10.6554505],
                [107.0652237, 10.6556746],
                [107.0651559, 10.6558714],
                [107.0650438, 10.656133],
                [107.0649294, 10.6563533],
                [107.0648266, 10.6565575],
                [107.0647332, 10.656709],
                [107.0646562, 10.6568283],
                [107.0645791, 10.6569063],
                [107.0644811, 10.656966],
                [107.0643947, 10.657005],
                [107.0642569, 10.6570555],
                [107.0641, 10.6571026],
                [107.0638992, 10.6571645],
                [107.063661, 10.6572426],
                [107.0633948, 10.6573137],
                [107.0632477, 10.6573504],
                [107.0630212, 10.6573917],
                [107.0626561, 10.6574366],
                [107.0625495, 10.6574514],
                [107.0624377, 10.6574652],
                [107.0622343, 10.6574812],
                [107.0621199, 10.6574858],
                [107.0620368, 10.6574844],
                [107.0619798, 10.6574835],
                [107.0618537, 10.6574697],
                [107.0616926, 10.6574445],
                [107.0615771, 10.6574249],
                [107.0615034, 10.6574124],
                [107.0610615, 10.657338],
                [107.0605359, 10.6572445],
                [107.0603509, 10.6572116],
                [107.0598558, 10.6571336],
                [107.0596037, 10.6570854],
                [107.0593071, 10.6570327],
                [107.0590549, 10.6569845],
                [107.0588363, 10.6569534],
                [107.0588121, 10.65695],
                [107.0586276, 10.6569156],
                [107.0584222, 10.6568973],
                [107.0582634, 10.6568904],
                [107.0581046, 10.6569041],
                [107.0579318, 10.6569363],
                [107.0577543, 10.6569753],
                [107.0574897, 10.6570671],
                [107.0571893, 10.657191],
                [107.057143, 10.6572122],
                [107.0561175, 10.6576821],
                [107.0560868, 10.6576941],
                [107.055906, 10.6577648],
                [107.0556598, 10.657861],
                [107.055438, 10.6579437],
                [107.0551928, 10.6580423],
                [107.0550159, 10.6580949],
                [107.054915, 10.6581249],
                [107.0547258, 10.6581708],
                [107.0545601, 10.6582007],
                [107.0543686, 10.6582144],
                [107.054023, 10.6581984],
                [107.0534826, 10.6581702],
                [107.0534423, 10.6581681],
                [107.0534066, 10.6581662],
                [107.0528952, 10.6581341],
                [107.052664, 10.6581318],
                [107.0524586, 10.6581433],
                [107.052064, 10.6581387],
                [107.0517568, 10.6581261],
                [107.0516859, 10.658119],
                [107.0515922, 10.6581096],
                [107.0512821, 10.6580786],
                [107.0510688, 10.6580518],
                [107.0505257, 10.6579837],
                [107.0503401, 10.6579602],
                [107.0498793, 10.657902],
                [107.0495327, 10.6578567],
                [107.0494813, 10.65785],
                [107.0494152, 10.6578414],
                [107.0490281, 10.657807],
                [107.0485274, 10.6577966],
                [107.0485066, 10.6577964],
                [107.0482129, 10.6577937],
                [107.0480588, 10.6577923],
                [107.0479481, 10.6577913],
                [107.0476416, 10.6577926],
                [107.0473042, 10.657794],
                [107.0472114, 10.6577873],
                [107.0470196, 10.657781],
                [107.0468269, 10.6577597],
                [107.0467479, 10.6577496],
                [107.046263, 10.6576878],
                [107.0461561, 10.6576742],
                [107.0451666, 10.6575435],
                [107.0450286, 10.6575272],
                [107.0449919, 10.6575229],
                [107.0446758, 10.6574855],
                [107.0444585, 10.6574539],
                [107.0441742, 10.6573722],
                [107.0440195, 10.6573207],
                [107.043617, 10.6571867],
                [107.0433427, 10.6570954],
                [107.0429889, 10.6569838],
                [107.0428331, 10.6569346],
                [107.0423315, 10.6567554],
                [107.0419721, 10.6566631],
                [107.0419056, 10.6566422],
                [107.0415856, 10.6565417],
                [107.0411232, 10.6563963],
                [107.0409715, 10.6563542],
                [107.0403957, 10.6561947],
                [107.0401588, 10.656142],
                [107.0398569, 10.6560784],
                [107.0396258, 10.656036],
                [107.0391765, 10.6559551],
                [107.0383733, 10.6558062],
                [107.0383116, 10.6557851],
                [107.0386514, 10.6550067],
                [107.0377243, 10.6545528],
                [107.0349945, 10.6532659],
                [107.0324218, 10.6520608],
                [107.0289125, 10.6503651],
                [107.0261928, 10.6490567],
                [107.0215096, 10.6468008],
                [107.0212337, 10.6466889],
                [107.0209852, 10.6470354],
                [107.0208177, 10.6472273],
                [107.0205953, 10.6473528],
                [107.0203759, 10.6474067],
                [107.0201526, 10.6473983],
                [107.0199239, 10.6473107],
                [107.0195514, 10.6470999],
                [107.019371, 10.6469287],
                [107.0192562, 10.6467272],
                [107.0192207, 10.6464852],
                [107.0192439, 10.6461712],
                [107.0192265, 10.6458743],
                [107.0191534, 10.6456438],
                [107.0190233, 10.645508],
                [107.0187387, 10.6453322],
                [107.0183322, 10.6451883],
                [107.0178443, 10.6451883],
                [107.0169499, 10.6449566],
                [107.014657, 10.6440935],
                [107.0139195, 10.6436473],
                [107.0139274, 10.6406386],
                [107.0159696, 10.6387026],
                [107.0175102, 10.6347118],
                [107.0180622, 10.6330091],
                [107.01806, 10.6326693],
                [107.0179587, 10.6321359],
                [107.0177852, 10.631773],
                [107.0175147, 10.6315562],
                [107.017, 10.6314138],
                [107.0164367, 10.6313204],
                [107.0157281, 10.6314705],
                [107.0150208, 10.6318148],
                [107.0142529, 10.6322529],
                [107.013109, 10.6332065],
                [107.0108951, 10.6352102],
                [107.0093354, 10.6361907],
                [107.0085933, 10.6365844],
                [107.007971, 10.6367742],
                [107.0073487, 10.6370272],
                [107.0065977, 10.6371538],
                [107.0040228, 10.6371959],
                [107.0026066, 10.6371327],
                [107.0009543, 10.636015],
                [106.9987656, 10.6342013],
                [106.9979717, 10.6321978],
                [106.997049, 10.6297725],
                [106.9968345, 10.6272207],
                [106.9972422, 10.6249641],
                [106.9975855, 10.6231715],
                [106.9991304, 10.6204087],
                [107.001964, 10.6179383],
                [107.0082867, 10.6130075],
                [107.0114821, 10.6108825],
                [107.0131742, 10.6097573],
                [107.0140281, 10.6091322],
                [107.0164458, 10.6068571],
                [107.0177179, 10.6055087],
                [107.0179458, 10.6052671],
                [107.0191458, 10.6038971],
                [107.0201458, 10.6023171],
                [107.0205458, 10.6015371],
                [107.0209458, 10.6004171],
                [107.0213458, 10.5989571],
                [107.0217458, 10.5976171],
                [107.0220458, 10.5967371],
                [107.0223458, 10.5959671],
                [107.0233458, 10.5942071],
                [107.0253458, 10.5907771],
                [107.0259731, 10.5898876],
                [107.0268288, 10.5884412],
                [107.0271732, 10.5871076],
                [107.0271988, 10.5859663],
                [107.0270584, 10.5848303],
                [107.0269081, 10.5829301],
                [107.0266719, 10.5817513],
                [107.0259087, 10.5801975],
                [107.0246006, 10.5785186],
                [107.0206579, 10.5719102],
                [107.0176963, 10.5682309],
                [107.0154978, 10.5654624],
                [107.0143531, 10.5639263],
                [107.0132812, 10.5621581],
                [107.0126998, 10.5609792],
                [107.011864, 10.5589073],
                [107.0112462, 10.5568353],
                [107.0113007, 10.5546741],
                [107.0114824, 10.5535309],
                [107.0117913, 10.5521556],
                [107.0122637, 10.5508338],
                [107.0134265, 10.5494584],
                [107.0149164, 10.5484938],
                [107.0160429, 10.5480294],
                [107.0172057, 10.5476007],
                [107.0184049, 10.5472971],
                [107.0200401, 10.5469398],
                [107.0236921, 10.5460825],
                [107.0250344, 10.5455893],
                [107.0254909, 10.5454215],
                [107.0267082, 10.5444213],
                [107.0271443, 10.5434031],
                [107.0273441, 10.5425457],
                [107.0276167, 10.5416883],
                [107.0276712, 10.5407773],
                [107.0275803, 10.5394019],
                [107.0272314, 10.5382138],
                [107.0267379, 10.5370324],
                [107.026062, 10.5357877],
                [107.0251983, 10.5344692],
                [107.0240235, 10.5330874],
                [107.0154726, 10.5250392],
                [107.0126188, 10.5223388],
                [107.0125544, 10.5209675],
                [107.012328, 10.5197312],
                [107.0119321, 10.5186468],
                [107.0114386, 10.5175498],
                [107.0105551, 10.5158971],
                [107.0088111, 10.5129079],
                [107.0076882, 10.5114154],
                [107.0058274, 10.5098492],
                [107.0022869, 10.5080454],
                [106.999712, 10.5067162],
                [106.9991648, 10.5062679],
                [106.9987464, 10.5057668],
                [106.9984889, 10.5051339],
                [106.9984031, 10.5044165],
                [106.9983458, 10.5027771],
                [106.9979458, 10.5004771],
                [106.9965458, 10.4910871],
                [107.0065458, 10.4671571],
                [107.0085458, 10.4568771],
                [106.9933717, 10.1399458],
                [106.8722725, 9.4272857],
                [106.4538443, 9.0800586],
                [105.9769387, 8.702505],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.8805752, 21.3373646],
                [105.880669, 21.3369493],
                [105.8809828, 21.3363524],
                [105.8815178, 21.3357257],
                [105.8819386, 21.3353179],
                [105.883277, 21.3344834],
                [105.8836733, 21.3342363],
                [105.884248, 21.3338188],
                [105.8846223, 21.3334805],
                [105.8850456, 21.3332211],
                [105.8860886, 21.3316784],
                [105.8877631, 21.3280619],
                [105.8885759, 21.3264833],
                [105.8923021, 21.3261864],
                [105.8964403, 21.3267856],
                [105.8981323, 21.3267545],
                [105.8984702, 21.3264164],
                [105.8987263, 21.3259384],
                [105.8993003, 21.3252336],
                [105.8995165, 21.3247597],
                [105.8996982, 21.3243293],
                [105.8996944, 21.3236942],
                [105.8995541, 21.3229147],
                [105.8992974, 21.3220995],
                [105.8990783, 21.3211025],
                [105.8990326, 21.3199777],
                [105.8990651, 21.3189432],
                [105.8991468, 21.3179003],
                [105.8991591, 21.3177439],
                [105.8992089, 21.3171096],
                [105.8992802, 21.3160929],
                [105.8992364, 21.3152585],
                [105.8989983, 21.3143342],
                [105.8977778, 21.3110744],
                [105.8975418, 21.3104767],
                [105.8973806, 21.3094434],
                [105.8973572, 21.3087901],
                [105.8974881, 21.3080274],
                [105.8975445, 21.3077367],
                [105.8977356, 21.3073182],
                [105.8980039, 21.3068632],
                [105.8984846, 21.3062981],
                [105.9009698, 21.3041979],
                [105.9027826, 21.3029723],
                [105.9052748, 21.3020335],
                [105.9063577, 21.3017918],
                [105.9072863, 21.3016416],
                [105.908138, 21.3015826],
                [105.9090678, 21.3016501],
                [105.9097661, 21.3018278],
                [105.9106785, 21.302204],
                [105.9117668, 21.3028332],
                [105.9141782, 21.3044895],
                [105.9153636, 21.3051727],
                [105.9160436, 21.305532],
                [105.9165286, 21.3056745],
                [105.9169933, 21.305672],
                [105.9174563, 21.3053972],
                [105.9176866, 21.3050693],
                [105.9178211, 21.3048871],
                [105.9179545, 21.3045416],
                [105.9179519, 21.3041242],
                [105.9177919, 21.3033085],
                [105.9171408, 21.3013523],
                [105.9161337, 21.2982002],
                [105.915932, 21.2968948],
                [105.9160238, 21.2960958],
                [105.9161567, 21.2956595],
                [105.9164443, 21.2952044],
                [105.9168871, 21.2947846],
                [105.9174658, 21.2944366],
                [105.9184516, 21.2941591],
                [105.9197479, 21.2939887],
                [105.9227683, 21.2939721],
                [105.9229992, 21.2939891],
                [105.9241438, 21.2940735],
                [105.9251128, 21.2942133],
                [105.9270725, 21.2948558],
                [105.9279454, 21.295105],
                [105.928856, 21.2951907],
                [105.929708, 21.295186],
                [105.9305009, 21.2950365],
                [105.9311186, 21.2947426],
                [105.931523, 21.2943776],
                [105.9317335, 21.2939953],
                [105.9318462, 21.2934503],
                [105.931804, 21.292888],
                [105.9315093, 21.2922001],
                [105.931158, 21.2917665],
                [105.9306713, 21.2913517],
                [105.9302246, 21.2911365],
                [105.9297978, 21.2910118],
                [105.9289647, 21.2909257],
                [105.9280147, 21.2907313],
                [105.9269863, 21.290374],
                [105.9259368, 21.2897446],
                [105.9242832, 21.2885017],
                [105.9234655, 21.2877803],
                [105.9231326, 21.2871833],
                [105.9229727, 21.2863858],
                [105.9226808, 21.2830302],
                [105.9225533, 21.2781314],
                [105.9225537, 21.2780775],
                [105.9225546, 21.2779016],
                [105.9225623, 21.2764617],
                [105.9229008, 21.2717418],
                [105.9230108, 21.2707613],
                [105.9231805, 21.2700345],
                [105.923761, 21.2690537],
                [105.9238701, 21.2688693],
                [105.9239032, 21.2688191],
                [105.9246756, 21.2676491],
                [105.9254629, 21.2665922],
                [105.927654, 21.264076],
                [105.9286273, 21.2629147],
                [105.9289222, 21.2625629],
                [105.9297852, 21.2612697],
                [105.9308341, 21.2587414],
                [105.9321162, 21.2563754],
                [105.9334836, 21.2541307],
                [105.9338022, 21.2536076],
                [105.9359908, 21.2507284],
                [105.9362984, 21.2504],
                [105.9369136, 21.2497253],
                [105.9375103, 21.2491775],
                [105.9376727, 21.2490137],
                [105.9381257, 21.2485571],
                [105.9386435, 21.2477921],
                [105.9390457, 21.2471184],
                [105.9392168, 21.2466093],
                [105.9392127, 21.2459741],
                [105.9391316, 21.245394],
                [105.9388364, 21.2446153],
                [105.9378728, 21.2432584],
                [105.9363918, 21.2408767],
                [105.9359035, 21.2399521],
                [105.9357308, 21.2391678],
                [105.9358249, 21.2383809],
                [105.9359346, 21.2380663],
                [105.9362666, 21.237541],
                [105.9368208, 21.2368051],
                [105.9382118, 21.2358551],
                [105.9398782, 21.2342755],
                [105.9409905, 21.2334318],
                [105.9420495, 21.2330072],
                [105.9433337, 21.232843],
                [105.9441167, 21.2329433],
                [105.9460761, 21.2335081],
                [105.9485428, 21.2347505],
                [105.9497185, 21.2351103],
                [105.9505574, 21.2352103],
                [105.9514511, 21.2351529],
                [105.9522324, 21.2349915],
                [105.9530123, 21.2346206],
                [105.9540693, 21.2338818],
                [105.9547619, 21.2329619],
                [105.9562985, 21.2286087],
                [105.9567401, 21.2277686],
                [105.9570174, 21.227453],
                [105.9574072, 21.2272414],
                [105.957965, 21.2270812],
                [105.9582443, 21.2270796],
                [105.9585806, 21.2272347],
                [105.9593114, 21.227911],
                [105.9603294, 21.2297896],
                [105.9608918, 21.2303623],
                [105.9613958, 21.2305164],
                [105.9635191, 21.2305043],
                [105.9648028, 21.2302876],
                [105.9653596, 21.2299703],
                [105.9658042, 21.2296014],
                [105.9661923, 21.229128],
                [105.966578, 21.2282883],
                [105.9669612, 21.2270822],
                [105.9672369, 21.2265048],
                [105.9680126, 21.2255059],
                [105.9691819, 21.2248711],
                [105.9715227, 21.2239678],
                [105.9748703, 21.2231569],
                [105.9764756, 21.2230616],
                [105.9779541, 21.223577],
                [105.97865, 21.2244239],
                [105.9790924, 21.2261275],
                [105.9796838, 21.2288137],
                [105.9804457, 21.2308754],
                [105.9810046, 21.2319464],
                [105.9822666, 21.232925],
                [105.9837725, 21.2336356],
                [105.9872663, 21.2345283],
                [105.9887783, 21.2349219],
                [105.9898779, 21.2354312],
                [105.9907419, 21.2363955],
                [105.9912956, 21.2375368],
                [105.9915942, 21.239245],
                [105.9916162, 21.2408552],
                [105.9909436, 21.2424726],
                [105.9892909, 21.2446006],
                [105.9871949, 21.2487576],
                [105.9870974, 21.2511223],
                [105.9869392, 21.2525364],
                [105.9869503, 21.2542114],
                [105.9871225, 21.2548908],
                [105.9875193, 21.255726],
                [105.9891241, 21.2575224],
                [105.9905324, 21.2591892],
                [105.9917749, 21.2611185],
                [105.9930775, 21.2636758],
                [105.9933591, 21.2639882],
                [105.9936958, 21.2641956],
                [105.9940878, 21.2642979],
                [105.9944231, 21.2642959],
                [105.9953697, 21.2637669],
                [105.9957029, 21.2634509],
                [105.9963128, 21.2627145],
                [105.9980284, 21.2601918],
                [105.9985826, 21.2595081],
                [105.9997524, 21.2589254],
                [106.0020416, 21.2585977],
                [106.003102, 21.2586311],
                [106.0034392, 21.2586418],
                [106.0061239, 21.2589398],
                [106.0083099, 21.2589547],
                [106.010928, 21.2584175],
                [106.013052, 21.2577629],
                [106.0165283, 21.2561004],
                [106.0217172, 21.2549464],
                [106.0241152, 21.2541991],
                [106.0268142, 21.2525339],
                [106.0284239, 21.2513316],
                [106.0292946, 21.2506813],
                [106.0302056, 21.2500007],
                [106.0319836, 21.2485242],
                [106.0341532, 21.2470977],
                [106.0352657, 21.2463581],
                [106.035765, 21.2458316],
                [106.035986, 21.2454637],
                [106.0361506, 21.2450441],
                [106.0361459, 21.2443636],
                [106.036029, 21.2436315],
                [106.0352046, 21.2416214],
                [106.0341835, 21.2394293],
                [106.0337299, 21.2384899],
                [106.0336145, 21.2379671],
                [106.0333833, 21.2368693],
                [106.0333253, 21.2365556],
                [106.0333216, 21.2360322],
                [106.0333717, 21.2351944],
                [106.0334235, 21.2346183],
                [106.033528, 21.2335708],
                [106.0342979, 21.2317864],
                [106.0349271, 21.2298721],
                [106.0349793, 21.2293484],
                [106.0350863, 21.2286672],
                [106.0350837, 21.228298],
                [106.0350619, 21.2251604],
                [106.0345824, 21.2205048],
                [106.03454, 21.2184375],
                [106.0345284, 21.2167626],
                [106.034577, 21.2157154],
                [106.0348428, 21.213777],
                [106.0351358, 21.2120771],
                [106.0358417, 21.2104448],
                [106.0363224, 21.2095658],
                [106.0365982, 21.209129],
                [106.0381373, 21.2066224],
                [106.0392263, 21.2048001],
                [106.0400032, 21.203325],
                [106.0406024, 21.202514],
                [106.0410577, 21.2021465],
                [106.0416085, 21.2018452],
                [106.0424281, 21.2015126],
                [106.0430276, 21.2014078],
                [106.043969, 21.2014846],
                [106.0451227, 21.2018057],
                [106.0456273, 21.2020643],
                [106.0461885, 21.2024272],
                [106.0464697, 21.2026872],
                [106.0470339, 21.2034689],
                [106.0471485, 21.2038869],
                [106.0472074, 21.2043053],
                [106.0465999, 21.2053035],
                [106.0458248, 21.2063029],
                [106.0442734, 21.2081445],
                [106.0434986, 21.2091961],
                [106.0428896, 21.2099851],
                [106.0427275, 21.2107712],
                [106.042568, 21.2119237],
                [106.042408, 21.2130239],
                [106.0424154, 21.2140707],
                [106.0426451, 21.2149592],
                [106.0429848, 21.2155852],
                [106.0450112, 21.2177187],
                [106.046926, 21.2198529],
                [106.0478584, 21.2213389],
                [106.0486106, 21.2228537],
                [106.0487652, 21.2231652],
                [106.0491615, 21.2238956],
                [106.0495567, 21.2244689],
                [106.0501758, 21.2250932],
                [106.0517498, 21.2264443],
                [106.0523682, 21.2269639],
                [106.0528729, 21.2272224],
                [106.0532651, 21.227377],
                [106.0536566, 21.2274269],
                [106.055055, 21.2276275],
                [106.0574549, 21.2272462],
                [106.0615244, 21.225912],
                [106.0645332, 21.2247415],
                [106.0672339, 21.2234421],
                [106.0684663, 21.2225445],
                [106.0689014, 21.2222276],
                [106.0706771, 21.2205414],
                [106.0722276, 21.2185949],
                [106.0730603, 21.2178568],
                [106.0736171, 21.2175916],
                [106.0741184, 21.217379],
                [106.07518, 21.2173723],
                [106.0767484, 21.217938],
                [106.07849, 21.2192354],
                [106.0791075, 21.2196502],
                [106.0801703, 21.2198005],
                [106.0808973, 21.2199005],
                [106.0817909, 21.2198424],
                [106.0830459, 21.2195465],
                [106.0842697, 21.2188058],
                [106.085436, 21.2178562],
                [106.0860441, 21.2169625],
                [106.0864317, 21.2164888],
                [106.0865577, 21.2162081],
                [106.0865967, 21.2161214],
                [106.0868175, 21.2157536],
                [106.0869262, 21.2153342],
                [106.0872967, 21.2148065],
                [106.0873815, 21.2142634],
                [106.0870768, 21.2135492],
                [106.0867571, 21.212467],
                [106.0860007, 21.2113498],
                [106.0856649, 21.2109455],
                [106.0856051, 21.2104225],
                [106.0855994, 21.2096373],
                [106.085658, 21.2093232],
                [106.085707, 21.2090609],
                [106.0861197, 21.2081946],
                [106.0867851, 21.2075099],
                [106.0882861, 21.2065056],
                [106.09029, 21.2054983],
                [106.0904489, 21.2054291],
                [106.0928517, 21.2043825],
                [106.0968119, 21.2035194],
                [106.1016077, 21.2023366],
                [106.1032809, 21.2019593],
                [106.1046438, 21.2017756],
                [106.1071597, 21.2014367],
                [106.1100079, 21.2013133],
                [106.1130226, 21.2010318],
                [106.11503, 21.2005474],
                [106.1180915, 21.1990616],
                [106.1199282, 21.1981596],
                [106.1214341, 21.1978356],
                [106.1216417, 21.1979024],
                [106.1224957, 21.1981299],
                [106.1231991, 21.1985305],
                [106.1235371, 21.1988947],
                [106.1239317, 21.1993631],
                [106.1243841, 21.2000929],
                [106.124667, 21.200562],
                [106.1248381, 21.201032],
                [106.1248991, 21.201712],
                [106.1249007, 21.2019214],
                [106.1249067, 21.2027065],
                [106.1245382, 21.2056924],
                [106.1243844, 21.2075254],
                [106.1243365, 21.2085726],
                [106.1244285, 21.209645],
                [106.1249935, 21.2104787],
                [106.1265174, 21.2125098],
                [106.1281537, 21.2141545],
                [106.1284315, 21.2144338],
                [106.1305086, 21.2157284],
                [106.1312944, 21.2161942],
                [106.1323575, 21.2163965],
                [106.132972, 21.2163924],
                [106.1347586, 21.2162233],
                [106.1365436, 21.2158449],
                [106.1407803, 21.2146124],
                [106.1422838, 21.2139742],
                [106.1468247, 21.212347],
                [106.1491059, 21.2111276],
                [106.1504109, 21.2102144],
                [106.1516065, 21.2093833],
                [106.1525493, 21.208487],
                [106.1543778, 21.2065379],
                [106.1564195, 21.2032788],
                [106.1590409, 21.199152],
                [106.1610237, 21.1960395],
                [106.1629592, 21.1930011],
                [106.1640652, 21.1915802],
                [106.1654233, 21.1902362],
                [106.1670259, 21.1880268],
                [106.1682999, 21.1857702],
                [106.1685685, 21.1852944],
                [106.1698335, 21.1827732],
                [106.1706618, 21.1815636],
                [106.1715472, 21.1805106],
                [106.1732982, 21.1794255],
                [106.1758012, 21.1780996],
                [106.1776766, 21.1771562],
                [106.1788611, 21.1765604],
                [106.1806997, 21.1759718],
                [106.1819826, 21.1757535],
                [106.1834339, 21.1756387],
                [106.1845518, 21.1757355],
                [106.1849999, 21.1758894],
                [106.1855613, 21.1762519],
                [106.1858993, 21.1766158],
                [106.1862945, 21.1771365],
                [106.1865775, 21.1776055],
                [106.1868614, 21.1781793],
                [106.1869776, 21.1787542],
                [106.1871486, 21.1791718],
                [106.1874316, 21.1796409],
                [106.1877709, 21.1801619],
                [106.1889485, 21.1807294],
                [106.1913829, 21.1813141],
                [106.1940668, 21.1816616],
                [106.1961906, 21.1818036],
                [106.1976418, 21.1816886],
                [106.1997572, 21.1807839],
                [106.2034833, 21.1787684],
                [106.2065432, 21.1772811],
                [106.2096348, 21.1762383],
                [106.2113273, 21.1760785],
                [106.2120347, 21.1760118],
                [106.215723, 21.176247],
                [106.2181255, 21.1763344],
                [106.218962, 21.1761713],
                [106.2195733, 21.1758006],
                [106.2201297, 21.1755348],
                [106.2208532, 21.1752155],
                [106.2214619, 21.1745308],
                [106.2219036, 21.1738995],
                [106.2220664, 21.1733225],
                [106.2220651, 21.1733129],
                [106.2220071, 21.1729042],
                [106.2218349, 21.1723297],
                [106.220482, 21.1708216],
                [106.2178915, 21.1682233],
                [106.2148975, 21.1653346],
                [106.2123771, 21.1624236],
                [106.2088563, 21.1588623],
                [106.2078586, 21.1575926],
                [106.2075242, 21.1565052],
                [106.2077898, 21.1555025],
                [106.2087543, 21.1549337],
                [106.2106369, 21.1547501],
                [106.2135787, 21.1545813],
                [106.2165493, 21.1545464],
                [106.2195619, 21.1546905],
                [106.2226851, 21.155021],
                [106.2256734, 21.1556762],
                [106.2277386, 21.1565509],
                [106.2292505, 21.157476],
                [106.2309242, 21.158812],
                [106.2329939, 21.1607698],
                [106.2347678, 21.1626629],
                [106.2385946, 21.1660839],
                [106.2420782, 21.1685708],
                [106.2467865, 21.1705774],
                [106.2471524, 21.1706674],
                [106.2500886, 21.1713905],
                [106.2518762, 21.1714295],
                [106.2535209, 21.1710772],
                [106.2544105, 21.1705995],
                [106.2550186, 21.1698622],
                [106.2566232, 21.1680707],
                [106.2573395, 21.1669139],
                [106.2577256, 21.1663353],
                [106.2579441, 21.165758],
                [106.2581068, 21.165181],
                [106.2581595, 21.1648142],
                [106.2581564, 21.1644478],
                [106.2580953, 21.1638202],
                [106.2580363, 21.1634543],
                [106.2569629, 21.1619967],
                [106.2560007, 21.160486],
                [106.2557417, 21.1595719],
                [106.2556818, 21.1591014],
                [106.2557329, 21.1585252],
                [106.255728, 21.1579495],
                [106.2557786, 21.157321],
                [106.2557675, 21.1560127],
                [106.2560255, 21.1534984],
                [106.2567905, 21.1515037],
                [106.257676, 21.150555],
                [106.2595693, 21.1499129],
                [106.2622113, 21.1486632],
                [106.264042, 21.1472363],
                [106.2651907, 21.1463031],
                [106.2659277, 21.1457044],
                [106.2673644, 21.143914],
                [106.2686277, 21.1414446],
                [106.2693903, 21.1391882],
                [106.2704588, 21.1367987],
                [106.2714996, 21.1344356],
                [106.2723246, 21.1329638],
                [106.2734321, 21.131861],
                [106.2735427, 21.1317509],
                [106.2741533, 21.1313276],
                [106.2747093, 21.1310616],
                [106.275323, 21.1310047],
                [106.2763289, 21.1311018],
                [106.2777847, 21.1315619],
                [106.2811383, 21.1319553],
                [106.2831483, 21.13194],
                [106.2848192, 21.1314564],
                [106.2870208, 21.1309947],
                [106.2899723, 21.1300825],
                [106.2924743, 21.1288596],
                [106.294747, 21.126958],
                [106.2967374, 21.1246921],
                [106.2980101, 21.1233739],
                [106.2990063, 21.1223718],
                [106.2992819, 21.1219509],
                [106.3024584, 21.1244651],
                [106.3053268, 21.1268507],
                [106.3072301, 21.1274117],
                [106.3089092, 21.1278697],
                [106.3096378, 21.1281782],
                [106.310424, 21.1286955],
                [106.3114908, 21.1293677],
                [106.3122212, 21.1298854],
                [106.3126711, 21.1302483],
                [106.3138538, 21.1313906],
                [106.3173796, 21.1354719],
                [106.3175991, 21.1358967],
                [106.3199929, 21.1405285],
                [106.3205657, 21.1421465],
                [106.3225364, 21.1471296],
                [106.3228277, 21.1484881],
                [106.3229579, 21.1505807],
                [106.3231394, 21.1521495],
                [106.3232017, 21.1528817],
                [106.3227441, 21.1547957],
                [106.3213243, 21.1584182],
                [106.3201198, 21.1611493],
                [106.3184114, 21.1637273],
                [106.3167906, 21.1667494],
                [106.315352, 21.1682784],
                [106.3139143, 21.1699122],
                [106.312238, 21.172987],
                [106.310822, 21.1770805],
                [106.3103361, 21.1782805],
                [106.309674, 21.1799157],
                [106.309367, 21.1831108],
                [106.3094994, 21.1854651],
                [106.3098793, 21.1873725],
                [106.3105117, 21.1894089],
                [106.3119279, 21.1916485],
                [106.3130543, 21.1926865],
                [106.3132825, 21.192803],
                [106.3135593, 21.1929443],
                [106.31423, 21.1929914],
                [106.3164084, 21.1929745],
                [106.3248628, 21.1920448],
                [106.3247308, 21.191034],
                [106.3245966, 21.1905469],
                [106.3245993, 21.1905417],
                [106.3240785, 21.1888087],
                [106.3238918, 21.1881784],
                [106.3237981, 21.1878221],
                [106.3235793, 21.1869724],
                [106.3230882, 21.1852158],
                [106.324413, 21.1844895],
                [106.3255752, 21.1839201],
                [106.3263168, 21.1834423],
                [106.326767, 21.1830261],
                [106.3271823, 21.182315],
                [106.3278302, 21.1822509],
                [106.328054, 21.1818953],
                [106.3283735, 21.1813619],
                [106.3293128, 21.1812365],
                [106.3308767, 21.1813379],
                [106.3308503, 21.1809983],
                [106.3308469, 21.1806187],
                [106.330906, 21.1801557],
                [106.3309541, 21.1798825],
                [106.3310649, 21.1795496],
                [106.3312604, 21.1787889],
                [106.3313319, 21.178302],
                [106.3313914, 21.1778864],
                [106.3313808, 21.1776817],
                [106.3318048, 21.1770883],
                [106.3320259, 21.1764377],
                [106.3324308, 21.1745766],
                [106.3343423, 21.1744141],
                [106.3350223, 21.1743203],
                [106.3352459, 21.1739351],
                [106.3354341, 21.1732259],
                [106.3358516, 21.1727802],
                [106.3364322, 21.1724512],
                [106.3378869, 21.1719679],
                [106.3384048, 21.1718754],
                [106.3386329, 21.1719914],
                [106.3388293, 21.1721963],
                [106.339255, 21.1726648],
                [106.3410055, 21.1726215],
                [106.3427051, 21.1705437],
                [106.3452037, 21.1671619],
                [106.3457573, 21.1664523],
                [106.3452341, 21.1656866],
                [106.3456984, 21.1652263],
                [106.3461262, 21.1659795],
                [106.346753, 21.1671028],
                [106.3469819, 21.1673632],
                [106.3473136, 21.1676041],
                [106.3474998, 21.1677151],
                [106.3475625, 21.1678081],
                [106.3475843, 21.167939],
                [106.3475448, 21.1681267],
                [106.3473203, 21.1683532],
                [106.346912, 21.1687499],
                [106.3465861, 21.1691458],
                [106.3462209, 21.1697482],
                [106.34594, 21.1705746],
                [106.3457784, 21.1709131],
                [106.3455331, 21.1711211],
                [106.3449593, 21.171444],
                [106.3445101, 21.1718597],
                [106.3443271, 21.1721235],
                [106.3442469, 21.1723676],
                [106.3441872, 21.1725928],
                [106.3441892, 21.1728176],
                [106.3442533, 21.1730793],
                [106.3444196, 21.1732466],
                [106.3446475, 21.1733946],
                [106.3449575, 21.1735046],
                [106.3453487, 21.1735014],
                [106.3456569, 21.173424],
                [106.3460059, 21.1733088],
                [106.3464577, 21.1731554],
                [106.3469725, 21.1731514],
                [106.3476528, 21.1732208],
                [106.3481058, 21.1732172],
                [106.3484143, 21.1731774],
                [106.3494397, 21.1727008],
                [106.3506692, 21.1720167],
                [106.3515105, 21.1716728],
                [106.3518801, 21.1715574],
                [106.3523529, 21.17146],
                [106.3530119, 21.1714547],
                [106.3536103, 21.171581],
                [106.3541894, 21.1718574],
                [106.3553086, 21.1726352],
                [106.3559091, 21.1729862],
                [106.3562602, 21.1731145],
                [106.35659, 21.1731306],
                [106.3567957, 21.1731103],
                [106.3570626, 21.1730145],
                [106.3575127, 21.1726924],
                [106.3579217, 21.1723707],
                [106.3582908, 21.1721991],
                [106.3587434, 21.1721393],
                [106.359546, 21.1720767],
                [106.3601427, 21.1720157],
                [106.3604713, 21.1719194],
                [106.3611076, 21.1716895],
                [106.3615189, 21.17163],
                [106.3618485, 21.1716273],
                [106.3621377, 21.1717373],
                [106.3628636, 21.1722934],
                [106.3634234, 21.172701],
                [106.3637337, 21.1728485],
                [106.3640429, 21.1728834],
                [106.364331, 21.1728623],
                [106.3648875, 21.172914],
                [106.3662103, 21.1734278],
                [106.3667682, 21.1736293],
                [106.3667197, 21.1738358],
                [106.3661755, 21.1741211],
                [106.3655376, 21.1741825],
                [106.3652908, 21.174222],
                [106.3651884, 21.174279],
                [106.3651481, 21.174373],
                [106.3652152, 21.1749531],
                [106.3653455, 21.1756825],
                [106.365309, 21.1762073],
                [106.3650663, 21.1766776],
                [106.3647408, 21.177111],
                [106.364498, 21.1776001],
                [106.364481, 21.1779748],
                [106.3644507, 21.1791739],
                [106.365195, 21.1794862],
                [106.3658373, 21.1798931],
                [106.367415, 21.1812478],
                [106.3684368, 21.1803966],
                [106.3692753, 21.179753],
                [106.3709845, 21.1815504],
                [106.3702024, 21.1820119],
                [106.3702899, 21.1825731],
                [106.3702096, 21.1827986],
                [106.3697597, 21.1831394],
                [106.3694759, 21.1836288],
                [106.3694578, 21.1839098],
                [106.3695213, 21.1840966],
                [106.3697899, 21.1841881],
                [106.3702021, 21.1842222],
                [106.3703887, 21.1843519],
                [106.3705598, 21.1850435],
                [106.3708326, 21.1855844],
                [106.3712503, 21.186218],
                [106.3717081, 21.18672],
                [106.3725177, 21.1874065],
                [106.3727891, 21.1877977],
                [106.3731449, 21.1884128],
                [106.373544, 21.1892525],
                [106.3737553, 21.1898315],
                [106.3739076, 21.1907107],
                [106.3739594, 21.1918528],
                [106.3740703, 21.1927135],
                [106.3741994, 21.1933119],
                [106.3742872, 21.1938918],
                [106.374275, 21.1940536],
                [106.3742321, 21.1946229],
                [106.3741364, 21.1954103],
                [106.3761626, 21.1962367],
                [106.3774636, 21.1966008],
                [106.3780822, 21.1966707],
                [106.3786585, 21.1966285],
                [106.3792334, 21.1964178],
                [106.3801168, 21.1961671],
                [106.3814539, 21.1959875],
                [106.3824416, 21.1958858],
                [106.383473, 21.1960459],
                [106.38498, 21.1964082],
                [106.3855991, 21.1965343],
                [106.3856218, 21.1965531],
                [106.3863879, 21.1971834],
                [106.3871123, 21.1975521],
                [106.388601, 21.198158],
                [106.3894702, 21.1986003],
                [106.3901956, 21.1990814],
                [106.3909834, 21.1996181],
                [106.391709, 21.2001179],
                [106.3921462, 21.20062],
                [106.3924601, 21.2011419],
                [106.3927544, 21.2017764],
                [106.3933633, 21.2030076],
                [106.3947218, 21.2029027],
                [106.3970272, 21.2027337],
                [106.3987364, 21.2026822],
                [106.3990857, 21.2025856],
                [106.3992911, 21.2025277],
                [106.3994545, 21.2023766],
                [106.4000903, 21.2020903],
                [106.4003396, 21.2023131],
                [106.4005488, 21.2026484],
                [106.4005735, 21.2030791],
                [106.4006388, 21.2034531],
                [106.4008286, 21.2039198],
                [106.4011007, 21.2043858],
                [106.4012257, 21.2045346],
                [106.401557, 21.2047192],
                [106.4028594, 21.2052142],
                [106.4038099, 21.2055247],
                [106.4046974, 21.2057046],
                [106.4057288, 21.2058647],
                [106.4069042, 21.2060046],
                [106.4073787, 21.2060756],
                [106.4078952, 21.2062399],
                [106.4083918, 21.2064793],
                [106.4091591, 21.2070161],
                [106.4099892, 21.2076647],
                [106.4104457, 21.2080169],
                [106.4108389, 21.2082008],
                [106.4111277, 21.2082546],
                [106.4115389, 21.2081763],
                [106.4122561, 21.2077582],
                [106.412523, 21.207681],
                [106.41275, 21.2077166],
                [106.4128748, 21.2078466],
                [106.4131921, 21.2087244],
                [106.4137648, 21.2104615],
                [106.4145034, 21.2123098],
                [106.4149051, 21.2133928],
                [106.4152879, 21.2146445],
                [106.4156286, 21.2158031],
                [106.4158649, 21.2168312],
                [106.4160612, 21.2179722],
                [106.4162973, 21.218963],
                [106.4164694, 21.2197294],
                [106.4165217, 21.2208903],
                [106.4166312, 21.2215637],
                [106.4168227, 21.222199],
                [106.4169909, 21.2225722],
                [106.4172002, 21.2229076],
                [106.417429, 21.2231304],
                [106.4176781, 21.2233344],
                [106.418194, 21.2234237],
                [106.4189557, 21.2233611],
                [106.4194505, 21.2234131],
                [106.420483, 21.2236667],
                [106.4210194, 21.2237371],
                [106.4217403, 21.223731],
                [106.422563, 21.223593],
                [106.4229862, 21.2247507],
                [106.4231774, 21.2253673],
                [106.4233161, 21.2261213],
                [106.4233156, 21.2270479],
                [106.4233933, 21.2278037],
                [106.4239126, 21.2292181],
                [106.4240898, 21.2300985],
                [106.4242179, 21.2307343],
                [106.4248585, 21.2309349],
                [106.4254176, 21.2312298],
                [106.4258515, 21.2313573],
                [106.42614, 21.2313736],
                [106.4268606, 21.23133],
                [106.4273954, 21.2312319],
                [106.4283808, 21.2308676],
                [106.4287911, 21.2306956],
                [106.4293259, 21.2305974],
                [106.4296353, 21.2306323],
                [106.4304, 21.230888],
                [106.4309589, 21.2311642],
                [106.4312282, 21.2313118],
                [106.4314757, 21.2313471],
                [106.4319886, 21.2311181],
                [106.4325399, 21.2306076],
                [106.4332732, 21.2297398],
                [106.4337437, 21.2293986],
                [106.4343579, 21.2289999],
                [106.4350548, 21.2286382],
                [106.4358763, 21.228369],
                [106.4368237, 21.2283422],
                [106.4381015, 21.2283874],
                [106.4383045, 21.2284175],
                [106.4386998, 21.228476],
                [106.4393616, 21.2287326],
                [106.439878, 21.2288781],
                [106.4403521, 21.2289115],
                [106.4413404, 21.2288469],
                [106.4423503, 21.2288944],
                [106.4431549, 21.2289999],
                [106.4435477, 21.2291464],
                [106.4440881, 21.2296288],
                [106.4452938, 21.2307423],
                [106.4457501, 21.2310568],
                [106.4465153, 21.2313499],
                [106.447361, 21.2314551],
                [106.4475656, 21.2314486],
                [106.4481642, 21.2314295],
                [106.4488431, 21.2313299],
                [106.4503222, 21.2309052],
                [106.451493, 21.2305392],
                [106.4517604, 21.2304994],
                [106.4521726, 21.2305147],
                [106.4526071, 21.2306982],
                [106.4539784, 21.2318852],
                [106.4548306, 21.2326458],
                [106.4550586, 21.2327936],
                [106.4553272, 21.2328663],
                [106.4556152, 21.2328263],
                [106.4562487, 21.2322964],
                [106.4572263, 21.2311454],
                [106.4578346, 21.2301474],
                [106.4582615, 21.2295631],
                [106.4585476, 21.2293358],
                [106.4589988, 21.2291259],
                [106.4594101, 21.2290475],
                [106.4599455, 21.2290241],
                [106.4603359, 21.228927],
                [106.4615632, 21.2280361],
                [106.4624822, 21.2272226],
                [106.4630392, 21.2272928],
                [106.4637608, 21.2273427],
                [106.4642952, 21.2272257],
                [106.4649307, 21.2269017],
                [106.4656457, 21.2262961],
                [106.4659533, 21.2261437],
                [106.4663446, 21.2261402],
                [106.4668201, 21.2263047],
                [106.4675457, 21.2267666],
                [106.4682307, 21.2272851],
                [106.4699712, 21.2283001],
                [106.4702388, 21.2282792],
                [106.4706911, 21.2281815],
                [106.4710619, 21.2281782],
                [106.4715781, 21.2282861],
                [106.4724479, 21.2287468],
                [106.4731323, 21.2292092],
                [106.473715, 21.2298034],
                [106.474048, 21.2301377],
                [106.4742755, 21.2302293],
                [106.4746259, 21.230245],
                [106.4757342, 21.2298232],
                [106.4771093, 21.2293055],
                [106.4779313, 21.2290923],
                [106.4792463, 21.2287435],
                [106.4801098, 21.2285674],
                [106.48151, 21.2284989],
                [106.483735, 21.2284982],
                [106.4851578, 21.2286354],
                [106.4862095, 21.2287386],
                [106.4866847, 21.2288842],
                [106.4870374, 21.2291246],
                [106.4873283, 21.2293656],
                [106.4875367, 21.2296072],
                [106.4879546, 21.2301842],
                [106.4882491, 21.2307996],
                [106.4883143, 21.2311363],
                [106.4883646, 21.2320349],
                [106.4884641, 21.2337385],
                [106.4885699, 21.2360789],
                [106.4887827, 21.2367512],
                [106.4889086, 21.2369749],
                [106.4889706, 21.2369932],
                [106.4891554, 21.2369354],
                [106.4892983, 21.2368029],
                [106.4895223, 21.2365387],
                [106.4897041, 21.2361813],
                [106.4897417, 21.235825],
                [106.4897485, 21.2344576],
                [106.4897747, 21.232959],
                [106.4898279, 21.2320968],
                [106.4899651, 21.2314027],
                [106.4901263, 21.2310453],
                [106.4904104, 21.2306121],
                [106.4912662, 21.2296679],
                [106.4919163, 21.2287632],
                [106.4919165, 21.2287629],
                [106.4924856, 21.2280089],
                [106.49329, 21.2281141],
                [106.4946922, 21.2282328],
                [106.4952486, 21.2282466],
                [106.4962979, 21.228125],
                [106.4975925, 21.227795],
                [106.4983523, 21.2275448],
                [106.4987414, 21.2273166],
                [106.4992326, 21.2269938],
                [106.4994355, 21.2266923],
                [106.4995759, 21.2263165],
                [106.4996957, 21.2259408],
                [106.4998552, 21.225415],
                [106.5000767, 21.2249072],
                [106.5002603, 21.224737],
                [106.5008618, 21.2245042],
                [106.5018799, 21.2244007],
                [106.5028978, 21.2240069],
                [106.5039287, 21.223663],
                [106.5050131, 21.2233186],
                [106.5056524, 21.2230452],
                [106.5063085, 21.2226711],
                [106.5069798, 21.2220458],
                [106.5074133, 21.2217072],
                [106.5076614, 21.2215543],
                [106.5078747, 21.2214855],
                [106.508285, 21.2214986],
                [106.5088575, 21.2216609],
                [106.5096457, 21.222022],
                [106.5099499, 21.2221197],
                [106.5103068, 21.2221332],
                [106.5107074, 21.2220794],
                [106.5112053, 21.221941],
                [106.5115618, 21.2219211],
                [106.5119363, 21.2219178],
                [106.5124727, 21.2220469],
                [106.5130654, 21.22246],
                [106.5137126, 21.222973],
                [106.514801, 21.2238838],
                [106.5152139, 21.2241478],
                [106.5158415, 21.2244936],
                [106.5167009, 21.2248207],
                [106.5175061, 21.2250811],
                [106.5185692, 21.2252724],
                [106.5196589, 21.2254467],
                [106.5201584, 21.225459],
                [106.5208888, 21.2253855],
                [106.5221623, 21.2252233],
                [106.5231057, 21.2250475],
                [106.5241728, 21.2247533],
                [106.5255145, 21.2242893],
                [106.5266146, 21.2237438],
                [106.5274482, 21.2233011],
                [106.5283436, 21.2227909],
                [106.5287151, 21.222503],
                [106.5290503, 21.2221485],
                [106.5293668, 21.2217106],
                [106.5297345, 21.2210545],
                [106.5301727, 21.2203142],
                [106.5303992, 21.2197933],
                [106.5305376, 21.2193736],
                [106.5305869, 21.2189716],
                [106.5305817, 21.2184695],
                [106.5305947, 21.2180007],
                [106.5307593, 21.2175306],
                [106.5310759, 21.2171094],
                [106.5314828, 21.2167876],
                [106.5321743, 21.2163965],
                [106.5326528, 21.2161076],
                [106.5330058, 21.2157529],
                [106.5332936, 21.215131],
                [106.5334461, 21.2143598],
                [106.5336529, 21.2136552],
                [106.5339894, 21.2125809],
                [106.5342311, 21.2118089],
                [106.5343674, 21.2111884],
                [106.5348396, 21.2094268],
                [106.5350449, 21.2085882],
                [106.5355724, 21.2070101],
                [106.5358853, 21.2062208],
                [106.5365384, 21.2047252],
                [106.5370571, 21.2040008],
                [106.5375686, 21.2034774],
                [106.5380801, 21.2029204],
                [106.538414, 21.2024488],
                [106.5385705, 21.2020624],
                [106.5387084, 21.2016093],
                [106.5389379, 21.2005362],
                [106.5391272, 21.199865],
                [106.5394069, 21.1993269],
                [106.5401716, 21.1982655],
                [106.5405939, 21.1977261],
                [106.5410154, 21.1971031],
                [106.5412858, 21.1965316],
                [106.5414394, 21.1958607],
                [106.5415559, 21.1950563],
                [106.5416422, 21.1939342],
                [106.5417972, 21.1933972],
                [106.5420241, 21.1929266],
                [106.5426159, 21.1924024],
                [106.5433066, 21.1919608],
                [106.5440702, 21.1916526],
                [106.5452964, 21.1912565],
                [106.5466757, 21.1910096],
                [106.5470498, 21.1909894],
                [106.5473361, 21.1910872],
                [106.5476061, 21.1913357],
                [106.5480391, 21.1918171],
                [106.548399, 21.1921318],
                [106.5488652, 21.1923786],
                [106.549076, 21.1924403],
                [106.5503049, 21.1928005],
                [106.5515742, 21.1931236],
                [106.5524684, 21.1933832],
                [106.5546235, 21.1940161],
                [106.5556614, 21.1943579],
                [106.5566702, 21.1944993],
                [106.5570983, 21.1945121],
                [106.5574721, 21.1944585],
                [106.5577924, 21.1943885],
                [106.5580407, 21.1942691],
                [106.5583943, 21.1939812],
                [106.5586948, 21.1937275],
                [106.558772, 21.1936585],
                [106.5593573, 21.1931356],
                [106.5596409, 21.1929824],
                [106.5599434, 21.1929294],
                [106.5604792, 21.1930081],
                [106.5608544, 21.1930883],
                [106.5611577, 21.1931023],
                [106.5614959, 21.193049],
                [106.5617794, 21.192879],
                [106.56238, 21.1923379],
                [106.5631682, 21.1918453],
                [106.5635927, 21.1915233],
                [106.5639107, 21.1912358],
                [106.5640861, 21.1909664],
                [106.5642251, 21.1906305],
                [106.5643804, 21.1901269],
                [106.5646321, 21.1894886],
                [106.5648435, 21.1892356],
                [106.5651261, 21.188982],
                [106.56541, 21.1888454],
                [106.5658546, 21.1887409],
                [106.5663534, 21.1887028],
                [106.5670839, 21.1886625],
                [106.5673696, 21.1886933],
                [106.5676744, 21.1888579],
                [106.5693849, 21.1895951],
                [106.5700481, 21.1899237],
                [106.5702988, 21.1900218],
                [106.570513, 21.1900533],
                [106.5710297, 21.190015],
                [106.5721475, 21.1901661],
                [106.5726216, 21.1902755],
                [106.5735699, 21.1905197],
                [106.5743984, 21.1907534],
                [106.5756442, 21.1913779],
                [106.5764513, 21.191839],
                [106.5769705, 21.1920182],
                [106.577757, 21.1922117],
                [106.5788382, 21.1924526],
                [106.5794094, 21.1925141],
                [106.5799265, 21.1925261],
                [106.5804252, 21.1924545],
                [106.5813681, 21.1922616],
                [106.582337, 21.1920015],
                [106.582798, 21.1917628],
                [106.5831341, 21.1915087],
                [106.5833452, 21.1912389],
                [106.583537, 21.1908355],
                [106.5838144, 21.1900966],
                [106.5840508, 21.1896926],
                [106.5842987, 21.1895229],
                [106.5846192, 21.1894865],
                [106.5854391, 21.1894621],
                [106.5858844, 21.1894244],
                [106.5863291, 21.1893198],
                [106.5871084, 21.1888439],
                [106.5877892, 21.1883186],
                [106.5881068, 21.1879977],
                [106.5885973, 21.1871898],
                [106.5890714, 21.1864992],
                [106.5897835, 21.185572],
                [106.5903281, 21.1848138],
                [106.5906433, 21.1842752],
                [106.5908257, 21.1838217],
                [106.5908409, 21.1835872],
                [106.5907659, 21.1832364],
                [106.5904226, 21.1828213],
                [106.589486, 21.1819264],
                [106.5888986, 21.1811788],
                [106.5886251, 21.1806123],
                [106.5885206, 21.1800108],
                [106.5885507, 21.1794917],
                [106.5886158, 21.1789053],
                [106.5887019, 21.17862],
                [106.588896, 21.1784341],
                [106.589216, 21.1783474],
                [106.5902861, 21.1783876],
                [106.5915547, 21.1786434],
                [106.5923942, 21.1788029],
                [106.593493, 21.1790101],
                [106.594421, 21.1791017],
                [106.5956869, 21.1791065],
                [106.5973545, 21.1791744],
                [106.5985848, 21.1791796],
                [106.5995121, 21.1792043],
                [106.600895, 21.1793251],
                [106.6018427, 21.1795839],
                [106.6025587, 21.1798448],
                [106.6030768, 21.179957],
                [106.6038885, 21.1799996],
                [106.6046373, 21.1799925],
                [106.6051537, 21.1799373],
                [106.6057403, 21.1797812],
                [106.6059181, 21.1796399],
                [106.6060624, 21.1794898],
                [106.6061795, 21.1793208],
                [106.6064868, 21.1786828],
                [106.6066046, 21.1785146],
                [106.6067378, 21.1783789],
                [106.6068769, 21.1782736],
                [106.6076195, 21.1778009],
                [106.6077281, 21.1776972],
                [106.6078349, 21.1775223],
                [106.6078724, 21.177398],
                [106.607909, 21.177124],
                [106.6079807, 21.1762284],
                [106.6086942, 21.175243],
                [106.6085291, 21.175051],
                [106.6083884, 21.1748428],
                [106.6082221, 21.1744927],
                [106.6086996, 21.1727069],
                [106.609928, 21.1722742],
                [106.6110091, 21.1717415],
                [106.6111976, 21.1716222],
                [106.6113584, 21.1714757],
                [106.6114876, 21.1713075],
                [106.6116339, 21.1710361],
                [106.6119564, 21.1711122],
                [106.6121736, 21.1711956],
                [106.6123896, 21.1713089],
                [106.6127546, 21.1715551],
                [106.6129368, 21.1716379],
                [106.6131282, 21.1716921],
                [106.6133236, 21.1717174],
                [106.6135209, 21.1717142],
                [106.6138702, 21.1716574],
                [106.614183, 21.171628],
                [106.6150923, 21.1715914],
                [106.6153356, 21.1715318],
                [106.6155717, 21.1714297],
                [106.6157362, 21.1713982],
                [106.6162768, 21.1714188],
                [106.6166845, 21.1713337],
                [106.6167879, 21.1713237],
                [106.6171103, 21.1713407],
                [106.6172232, 21.1713644],
                [106.6174403, 21.1714387],
                [106.6183019, 21.1719065],
                [106.6186198, 21.1720382],
                [106.6187845, 21.1720904],
                [106.6191226, 21.1721666],
                [106.6192877, 21.1721896],
                [106.6196209, 21.1722086],
                [106.6205871, 21.1721666],
                [106.620571, 21.1717864],
                [106.6225129, 21.1714913],
                [106.6230105, 21.1713062],
                [106.6233977, 21.1712673],
                [106.6237816, 21.1712062],
                [106.6245443, 21.1710376],
                [106.6247381, 21.1710248],
                [106.6249342, 21.1710335],
                [106.6257746, 21.1711288],
                [106.6265202, 21.1711886],
                [106.6268802, 21.1712422],
                [106.6272238, 21.1712614],
                [106.6277084, 21.1712362],
                [106.6282692, 21.1711202],
                [106.6285034, 21.1711117],
                [106.6286202, 21.1711217],
                [106.6288484, 21.1711697],
                [106.6289581, 21.1712074],
                [106.6291649, 21.1713084],
                [106.6293499, 21.1714413],
                [106.6295326, 21.1716088],
                [106.6299389, 21.1720949],
                [106.6302184, 21.1723406],
                [106.6303933, 21.1724567],
                [106.6306668, 21.1724517],
                [106.6309973, 21.172392],
                [106.6314589, 21.1722096],
                [106.6320932, 21.171916],
                [106.6329253, 21.1714763],
                [106.6342127, 21.1713212],
                [106.6351217, 21.1705001],
                [106.635983, 21.1696354],
                [106.6366298, 21.1689213],
                [106.636941, 21.168555],
                [106.6375387, 21.1678045],
                [106.6380537, 21.1679346],
                [106.6381677, 21.1679927],
                [106.6382963, 21.1681277],
                [106.638438, 21.168426],
                [106.6385846, 21.168686],
                [106.6387698, 21.1689576],
                [106.6398963, 21.1707434],
                [106.6400007, 21.1712271],
                [106.6400643, 21.1716925],
                [106.6401726, 21.1732571],
                [106.6400482, 21.1736378],
                [106.6399205, 21.1742225],
                [106.6398718, 21.1746691],
                [106.63984, 21.1757932],
                [106.639808, 21.1759771],
                [106.6396995, 21.176237],
                [106.6395249, 21.1764632],
                [106.6381556, 21.1777041],
                [106.6380295, 21.1782743],
                [106.6372436, 21.1787895],
                [106.6376721, 21.1790623],
                [106.6381213, 21.179313],
                [106.6397649, 21.1800901],
                [106.6398596, 21.1801545],
                [106.6400317, 21.1803024],
                [106.6401814, 21.1804781],
                [106.6403024, 21.1806788],
                [106.6404382, 21.1809595],
                [106.6405455, 21.1811024],
                [106.6407251, 21.1812655],
                [106.6414761, 21.1805352],
                [106.6424578, 21.1801501],
                [106.642612, 21.1800576],
                [106.6427606, 21.1799575],
                [106.6430336, 21.1797402],
                [106.6433859, 21.1793748],
                [106.6450837, 21.1794023],
                [106.6455531, 21.1795884],
                [106.6456955, 21.1795884],
                [106.6457838, 21.1795548],
                [106.6458897, 21.1794557],
                [106.6459265, 21.1793684],
                [106.6459342, 21.178996],
                [106.6459715, 21.1787195],
                [106.6460818, 21.1783404],
                [106.6461915, 21.1780992],
                [106.6465493, 21.1775577],
                [106.6468379, 21.1771863],
                [106.6471754, 21.1768064],
                [106.6475395, 21.1764486],
                [106.6477311, 21.1762785],
                [106.6479421, 21.1761211],
                [106.6483137, 21.175909],
                [106.6486173, 21.175785],
                [106.649268, 21.1755781],
                [106.6494675, 21.1754698],
                [106.6497171, 21.1752711],
                [106.6499379, 21.1750004],
                [106.6500485, 21.1749028],
                [106.6501921, 21.1748246],
                [106.6504905, 21.1747384],
                [106.6506198, 21.1746752],
                [106.6507602, 21.1745713],
                [106.6508746, 21.1744426],
                [106.6510516, 21.1743176],
                [106.6512168, 21.1742366],
                [106.6520521, 21.1739399],
                [106.6525161, 21.1738174],
                [106.6528957, 21.1737572],
                [106.6538143, 21.1737123],
                [106.6541866, 21.1739564],
                [106.6546203, 21.1741642],
                [106.6552444, 21.1743609],
                [106.655657, 21.1745402],
                [106.6558084, 21.1745602],
                [106.6559481, 21.1745492],
                [106.6560701, 21.1745152],
                [106.6563758, 21.174205],
                [106.6566875, 21.1741449],
                [106.6571356, 21.173995],
                [106.6574152, 21.1738577],
                [106.6577954, 21.1736285],
                [106.6580425, 21.1735183],
                [106.6583021, 21.1734317],
                [106.6587089, 21.1733491],
                [106.6589856, 21.1733271],
                [106.6599405, 21.1733897],
                [106.6601661, 21.1733923],
                [106.6603893, 21.1733619],
                [106.6606053, 21.1732992],
                [106.6608086, 21.1732054],
                [106.6609936, 21.173083],
                [106.661758, 21.172362],
                [106.6620068, 21.1722173],
                [106.6621955, 21.172151],
                [106.6625027, 21.1721058],
                [106.6637599, 21.1721166],
                [106.6639638, 21.1717164],
                [106.6646907, 21.171849],
                [106.6652378, 21.1718089],
                [106.6657501, 21.171894],
                [106.666013, 21.1719015],
                [106.6661728, 21.1718707],
                [106.6663241, 21.1718139],
                [106.6668203, 21.1715388],
                [106.6676062, 21.1715188],
                [106.6679201, 21.1713412],
                [106.6685986, 21.1713162],
                [106.6691887, 21.1711086],
                [106.6698968, 21.1710661],
                [106.6706804, 21.1702851],
                [106.6708648, 21.1701765],
                [106.671004, 21.1701298],
                [106.6711521, 21.1701065],
                [106.6713023, 21.1701081],
                [106.6717932, 21.1701832],
                [106.67257, 21.1702124],
                [106.6732513, 21.1702955],
                [106.6734422, 21.1702894],
                [106.6736407, 21.1702521],
                [106.6740552, 21.1700828],
                [106.6742825, 21.1700431],
                [106.6755526, 21.1701337],
                [106.6758144, 21.1701225],
                [106.6761645, 21.1700724],
                [106.6763614, 21.1700623],
                [106.6765434, 21.1700806],
                [106.6768894, 21.1702032],
                [106.6770981, 21.170283],
                [106.6773097, 21.1703557],
                [106.677524, 21.1704214],
                [106.6777407, 21.1704797],
                [106.6779596, 21.1705308],
                [106.6781692, 21.1705726],
                [106.6783803, 21.1706076],
                [106.6785926, 21.1706359],
                [106.678685, 21.1706382],
                [106.6787773, 21.1706319],
                [106.6788683, 21.1706169],
                [106.6789573, 21.1705934],
                [106.679035, 21.1705651],
                [106.6791095, 21.1705305],
                [106.6791804, 21.1704896],
                [106.6792469, 21.1704429],
                [106.6793087, 21.1703908],
                [106.6793768, 21.1703183],
                [106.6794512, 21.1702515],
                [106.6795313, 21.1701907],
                [106.6796213, 21.1701337],
                [106.6797163, 21.1700844],
                [106.6798156, 21.1700431],
                [106.6798983, 21.1700022],
                [106.6799767, 21.1699546],
                [106.6800502, 21.1699006],
                [106.6801183, 21.1698407],
                [106.6801804, 21.1697755],
                [106.6802377, 21.169703],
                [106.6802875, 21.169626],
                [106.6803296, 21.169545],
                [106.6803635, 21.1694607],
                [106.680389, 21.1693739],
                [106.6804057, 21.1692852],
                [106.6804969, 21.16875],
                [106.680572, 21.1685665],
                [106.6806559, 21.1683864],
                [106.6807483, 21.1682099],
                [106.6808492, 21.1680375],
                [106.6809583, 21.1678696],
                [106.6810623, 21.1677237],
                [106.6811726, 21.1675819],
                [106.6812888, 21.1674443],
                [106.6814109, 21.1673112],
                [106.6815386, 21.1671827],
                [106.6816717, 21.1670592],
                [106.6817146, 21.166714],
                [106.6817748, 21.166667],
                [106.6818392, 21.1666252],
                [106.6819074, 21.1665889],
                [106.6819787, 21.1665584],
                [106.6820526, 21.1665339],
                [106.6821343, 21.1665145],
                [106.6822177, 21.1665024],
                [106.6823019, 21.1664978],
                [106.6823862, 21.1665007],
                [106.6824698, 21.1665111],
                [106.682552, 21.1665289],
                [106.682632, 21.1665539],
                [106.683045, 21.1672442],
                [106.6831615, 21.1673751],
                [106.683285, 21.1675003],
                [106.6834152, 21.1676194],
                [106.6835598, 21.1677385],
                [106.6837111, 21.1678501],
                [106.6838687, 21.1679539],
                [106.6840321, 21.1680496],
                [106.6848448, 21.1680822],
                [106.6850069, 21.1681198],
                [106.685167, 21.168164],
                [106.6853249, 21.1682147],
                [106.6854675, 21.1682669],
                [106.6856077, 21.1683243],
                [106.6857453, 21.168387],
                [106.6858801, 21.1684548],
                [106.686151, 21.1687175],
                [106.6861973, 21.1687463],
                [106.6862481, 21.1687676],
                [106.6863021, 21.1687806],
                [106.6863576, 21.168785],
                [106.6864089, 21.1687813],
                [106.6864589, 21.1687703],
                [106.6865066, 21.1687522],
                [106.6865507, 21.1687275],
                [106.6865799, 21.1686861],
                [106.6866032, 21.1686417],
                [106.6866204, 21.1685949],
                [106.6866316, 21.168543],
                [106.6866352, 21.1684901],
                [106.6866311, 21.1684373],
                [106.6866179, 21.1683393],
                [106.6865973, 21.1682425],
                [106.6865694, 21.1681472],
                [106.6866124, 21.167772],
                [106.6867779, 21.1677893],
                [106.6869423, 21.1678145],
                [106.6870933, 21.1678449],
                [106.6872427, 21.1678821],
                [106.6872917, 21.1678877],
                [106.6873412, 21.1678877],
                [106.6873902, 21.1678821],
                [106.6874395, 21.1678704],
                [106.6874869, 21.1678529],
                [106.6875315, 21.16783],
                [106.6875726, 21.167802],
                [106.687658, 21.1677501],
                [106.6877471, 21.1677038],
                [106.6878395, 21.1676636],
                [106.6879347, 21.1676294],
                [106.688215, 21.1676829],
                [106.6884967, 21.1677296],
                [106.6887796, 21.1677695],
                [106.6891023, 21.1678067],
                [106.6894261, 21.167835],
                [106.6897505, 21.1678545],
                [106.6906035, 21.1678095],
                [106.6908447, 21.1679099],
                [106.6910898, 21.1680016],
                [106.6913384, 21.1680847],
                [106.6915918, 21.1681592],
                [106.691848, 21.1682247],
                [106.6922075, 21.1684298],
                [106.6923117, 21.1685793],
                [106.692422, 21.168725],
                [106.6925321, 21.1688594],
                [106.6926473, 21.1689901],
                [106.6931838, 21.1690701],
                [106.6937631, 21.1693903],
                [106.6938824, 21.1694456],
                [106.6940047, 21.1694949],
                [106.6941295, 21.1695383],
                [106.6942567, 21.1695754],
                [106.6943988, 21.169609],
                [106.6945427, 21.1696349],
                [106.6946881, 21.1696529],
                [106.6948343, 21.1696631],
                [106.6949809, 21.1696654],
                [106.6951536, 21.1696768],
                [106.6953252, 21.1696976],
                [106.6954953, 21.1697278],
                [106.6956633, 21.1697671],
                [106.6958284, 21.1698155],
                [106.6960438, 21.1698841],
                [106.6962621, 21.1699441],
                [106.6964829, 21.1699956],
                [106.6966584, 21.17003],
                [106.6968351, 21.170059],
                [106.6970127, 21.1700826],
                [106.697191, 21.1701006],
                [106.6972795, 21.1700939],
                [106.6973669, 21.1700794],
                [106.6974524, 21.1700572],
                [106.6975353, 21.1700275],
                [106.6976148, 21.1699906],
                [106.697686, 21.1699494],
                [106.6977529, 21.1699025],
                [106.6978151, 21.1698501],
                [106.697872, 21.1697927],
                [106.6979231, 21.1697308],
                [106.697968, 21.1696648],
                [106.6980064, 21.1695954],
                [106.6980708, 21.1694203],
                [106.6981056, 21.169369],
                [106.698146, 21.1693213],
                [106.6981915, 21.1692777],
                [106.6982478, 21.1692345],
                [106.6983092, 21.1691977],
                [106.6983748, 21.1691678],
                [106.6984436, 21.1691452],
                [106.6988003, 21.1690251],
                [106.6988461, 21.1690021],
                [106.6988885, 21.1689741],
                [106.6989271, 21.1689416],
                [106.6989613, 21.1689051],
                [106.6989924, 21.1688618],
                [106.6990174, 21.1688152],
                [106.6990357, 21.168766],
                [106.6990471, 21.168715],
                [106.6990832, 21.1686472],
                [106.6991244, 21.1685821],
                [106.6991705, 21.1685199],
                [106.6992293, 21.1684523],
                [106.6992939, 21.1683895],
                [106.6993639, 21.1683318],
                [106.6994387, 21.1682798],
                [106.699533, 21.1682266],
                [106.6996241, 21.1681688],
                [106.6997117, 21.1681064],
                [106.6997954, 21.1680396],
                [106.6998965, 21.1679483],
                [106.6999906, 21.1678506],
                [106.7000771, 21.167747],
                [106.7000863, 21.167645],
                [106.7001038, 21.1675439],
                [106.7001293, 21.1674443],
                [106.7001629, 21.1673468],
                [106.7002092, 21.1672417],
                [106.7002648, 21.1671406],
                [106.7003292, 21.1670441],
                [106.7007369, 21.1670692],
                [106.7011607, 21.1671642],
                [106.701237, 21.1671712],
                [106.7013138, 21.1671727],
                [106.7013904, 21.1671687],
                [106.7014664, 21.1671592],
                [106.7015518, 21.1671418],
                [106.7016352, 21.1671176],
                [106.7017161, 21.1670866],
                [106.7017937, 21.1670491],
                [106.701853, 21.1670106],
                [106.7019157, 21.1669772],
                [106.7019814, 21.1669491],
                [106.7020495, 21.1669266],
                [106.7021194, 21.1669099],
                [106.7021906, 21.1668991],
                [106.7028075, 21.1669041],
                [106.702904, 21.1669215],
                [106.702999, 21.1669449],
                [106.7030922, 21.1669742],
                [106.703183, 21.1670091],
                [106.7032681, 21.1670481],
                [106.7033504, 21.167092],
                [106.7034294, 21.1671408],
                [106.7035049, 21.1671942],
                [106.7036853, 21.1673161],
                [106.7038695, 21.1674329],
                [106.7040574, 21.1675444],
                [106.7041725, 21.1675437],
                [106.7042874, 21.1675498],
                [106.7044016, 21.1675629],
                [106.7045146, 21.1675828],
                [106.7046261, 21.1676094],
                [106.7047445, 21.1676458],
                [106.7048599, 21.1676897],
                [106.7049717, 21.167741],
                [106.7050794, 21.1677995],
                [106.7051676, 21.1678608],
                [106.7052598, 21.1679167],
                [106.7053556, 21.1679671],
                [106.7054753, 21.1680201],
                [106.7055989, 21.1680643],
                [106.7057258, 21.1680997],
                [106.7059094, 21.1681643],
                [106.7060906, 21.1682347],
                [106.7063073, 21.1683281],
                [106.7065197, 21.1684298],
                [106.7066082, 21.1684006],
                [106.7066941, 21.1683655],
                [106.7067772, 21.1683248],
                [106.706857, 21.1682786],
                [106.706933, 21.1682271],
                [106.7070049, 21.1681707],
                [106.7070723, 21.1681097],
                [106.7077202, 21.1674081],
                [106.7083409, 21.166764],
                [106.708585, 21.1660586],
                [106.7093038, 21.1654333],
                [106.7095335, 21.165125],
                [106.7096572, 21.1649768],
                [106.7099306, 21.1646847],
                [106.7100807, 21.1645418],
                [106.7103982, 21.1642727],
                [106.7110693, 21.1637507],
                [106.7117607, 21.1632522],
                [106.7119858, 21.1631097],
                [106.7120907, 21.1630289],
                [106.7122923, 21.1628397],
                [106.7123869, 21.1627309],
                [106.7124732, 21.1626163],
                [106.7125507, 21.1624964],
                [106.7126191, 21.1623717],
                [106.713016, 21.162463],
                [106.7131325, 21.162463],
                [106.7132486, 21.1624538],
                [106.7133634, 21.1624355],
                [106.7135621, 21.1623811],
                [106.7137499, 21.1622996],
                [106.7139226, 21.1621929],
                [106.7140108, 21.1621024],
                [106.7140642, 21.1620007],
                [106.7140858, 21.1618986],
                [106.7140936, 21.1617806],
                [106.7141238, 21.1616514],
                [106.7141565, 21.1615651],
                [106.7141986, 21.1614824],
                [106.7142496, 21.1614041],
                [106.7143089, 21.1613312],
                [106.7149285, 21.1607827],
                [106.7152168, 21.1609697],
                [106.7153109, 21.1610168],
                [106.7154904, 21.1610827],
                [106.7168248, 21.1613712],
                [106.717785, 21.1619065],
                [106.7203998, 21.1620922],
                [106.7208883, 21.1621041],
                [106.721348, 21.1621053],
                [106.7209701, 21.1616401],
                [106.7205302, 21.161046],
                [106.720474, 21.1609513],
                [106.7204548, 21.1609001],
                [106.720435, 21.1607934],
                [106.720435, 21.1607354],
                [106.7204571, 21.1606215],
                [106.7204789, 21.1605672],
                [106.7205074, 21.1605157],
                [106.7208803, 21.1600555],
                [106.7210699, 21.1599423],
                [106.721245, 21.1598104],
                [106.7213473, 21.1597181],
                [106.7214425, 21.1596195],
                [106.7215301, 21.159515],
                [106.7216098, 21.1594051],
                [106.7225432, 21.1594202],
                [106.7227318, 21.1594144],
                [106.7229197, 21.1593994],
                [106.7231065, 21.1593751],
                [106.7232949, 21.1593411],
                [106.723481, 21.1592977],
                [106.7237993, 21.1592086],
                [106.7239364, 21.1591803],
                [106.7242151, 21.1591484],
                [106.7244959, 21.15915],
                [106.7247658, 21.1591835],
                [106.7248986, 21.1592119],
                [106.7251573, 21.1592915],
                [106.7254034, 21.1594003],
                [106.7260838, 21.1597703],
                [106.7287472, 21.1603306],
                [106.7292429, 21.160593],
                [106.729758, 21.1608362],
                [106.7300235, 21.1609505],
                [106.730563, 21.1611611],
                [106.7313892, 21.1611311],
                [106.7323387, 21.1615463],
                [106.7324304, 21.1615725],
                [106.732619, 21.1616052],
                [106.7328107, 21.1616113],
                [106.7329952, 21.1615917],
                [106.7331747, 21.1615474],
                [106.7333991, 21.16146],
                [106.7336636, 21.1613264],
                [106.7339104, 21.1611661],
                [106.7339992, 21.1609791],
                [106.7340542, 21.1608906],
                [106.734184, 21.1607259],
                [106.7343511, 21.1605698],
                [106.7344439, 21.1605013],
                [106.7346454, 21.1603857],
                [106.7367348, 21.1594502],
                [106.7368364, 21.1594153],
                [106.7369402, 21.1593869],
                [106.7371419, 21.1593513],
                [106.7375493, 21.1593272],
                [106.7377621, 21.1593051],
                [106.7380199, 21.1592467],
                [106.7382663, 21.159155],
                [106.7383846, 21.1590567],
                [106.738481, 21.158937],
                [106.7385979, 21.1587089],
                [106.7387116, 21.1585372],
                [106.7390172, 21.1585268],
                [106.7391692, 21.1585114],
                [106.7394701, 21.1584602],
                [106.7396182, 21.1584246],
                [106.7399122, 21.1583321],
                [106.7400552, 21.1582759],
                [106.7401952, 21.1582133],
                [106.7411363, 21.1577492],
                [106.7415762, 21.1577342],
                [106.7417216, 21.1576339],
                [106.7418015, 21.1575941],
                [106.7419778, 21.1575357],
                [106.7420697, 21.1575191],
                [106.7424613, 21.1574991],
                [106.7425697, 21.1574771],
                [106.7426705, 21.157434],
                [106.7427645, 21.1573671],
                [106.7428048, 21.1573266],
                [106.742869, 21.1572339],
                [106.7436013, 21.1557906],
                [106.7437182, 21.1557258],
                [106.7438497, 21.1556936],
                [106.7439178, 21.1556906],
                [106.7440415, 21.1557081],
                [106.7441564, 21.1557544],
                [106.7442549, 21.1558264],
                [106.7448217, 21.1565385],
                [106.7448865, 21.1566048],
                [106.7450307, 21.1567233],
                [106.7451918, 21.1568212],
                [106.7453724, 21.1568985],
                [106.7455634, 21.1569497],
                [106.7457604, 21.1569738],
                [106.7459519, 21.156972],
                [106.7460471, 21.1569621],
                [106.7462686, 21.156917],
                [106.7465147, 21.156833],
                [106.7467167, 21.1567364],
                [106.7468753, 21.1566361],
                [106.7469487, 21.1565786],
                [106.7470955, 21.1564344],
                [106.7472169, 21.1562709],
                [106.7473456, 21.1560633],
                [106.7474512, 21.1560738],
                [106.7476593, 21.1561131],
                [106.7478606, 21.1561758],
                [106.7480405, 21.1562552],
                [106.7482093, 21.1563534],
                [106.7486224, 21.1566836],
                [106.7489889, 21.1566871],
                [106.7493546, 21.1566636],
                [106.7495679, 21.1566373],
                [106.7497797, 21.1566018],
                [106.7499895, 21.1565572],
                [106.7501968, 21.1565035],
                [106.7507306, 21.1561008],
                [106.7507912, 21.1560635],
                [106.7509225, 21.1560062],
                [106.750992, 21.1559865],
                [106.7511419, 21.155966],
                [106.7512211, 21.1559666],
                [106.751377, 21.1559907],
                [106.752077, 21.1565285],
                [106.7522364, 21.1565668],
                [106.7524008, 21.1565719],
                [106.7525625, 21.1565435],
                [106.7544776, 21.1559782],
                [106.755631, 21.1558131],
                [106.7559378, 21.1557514],
                [106.7560886, 21.1557105],
                [106.7563757, 21.155612],
                [106.756848, 21.1554054],
                [106.7572644, 21.1552528],
                [106.7601049, 21.1564585],
                [106.7607347, 21.1564433],
                [106.761049, 21.1564235],
                [106.7613216, 21.1563996],
                [106.7618644, 21.1563334],
                [106.7621008, 21.1563112],
                [106.7625753, 21.1562926],
                [106.76305, 21.1563084],
                [106.7632624, 21.1563079],
                [106.7636865, 21.1563304],
                [106.7640749, 21.1563789],
                [106.7642513, 21.15641],
                [106.7646003, 21.1564885],
                [106.7654157, 21.1562184],
                [106.7658582, 21.1559657],
                [106.7660528, 21.1559136],
                [106.7662525, 21.1558832],
                [106.7664636, 21.155875],
                [106.7665691, 21.15588],
                [106.7667782, 21.1559082],
                [106.7670518, 21.1560983],
                [106.7671911, 21.1560545],
                [106.7673269, 21.1560022],
                [106.7674587, 21.1559416],
                [106.7675859, 21.1558731],
                [106.7677079, 21.1557968],
                [106.7678243, 21.1557131],
                [106.7680129, 21.1555498],
                [106.76818, 21.1553672],
                [106.7683232, 21.1551678],
                [106.7692566, 21.1532191],
                [106.76972, 21.152999],
                [106.7701989, 21.1528099],
                [106.7706909, 21.1526526],
                [106.770941, 21.1525862],
                [106.7714459, 21.1524787],
                [106.7717011, 21.1524373],
                [106.7722154, 21.1523798],
                [106.7724738, 21.1523637],
                [106.7732083, 21.1523378],
                [106.7741579, 21.1522777],
                [106.7751013, 21.152187],
                [106.7760372, 21.1520659],
                [106.7771416, 21.1519998],
                [106.7782435, 21.1519031],
                [106.7787931, 21.1518432],
                [106.7798526, 21.1517059],
                [106.7808711, 21.1515464],
                [106.7818844, 21.1513605],
                [106.7838156, 21.1512554],
                [106.78463, 21.1511327],
                [106.7850387, 21.1510803],
                [106.7857677, 21.1510022],
                [106.7865139, 21.1509453],
                [106.788767, 21.1502849],
                [106.7891007, 21.1502135],
                [106.7894366, 21.1501514],
                [106.7897743, 21.1500984],
                [106.7901134, 21.1500547],
                [106.790762, 21.1499973],
                [106.7914131, 21.1499737],
                [106.7917389, 21.1499747],
                [106.7951882, 21.1500447],
                [106.795524, 21.1500827],
                [106.7958584, 21.1501298],
                [106.7961913, 21.1501859],
                [106.7965223, 21.1502509],
                [106.7968511, 21.1503249],
                [106.7974369, 21.1504803],
                [106.7977264, 21.1505686],
                [106.7982973, 21.1507661],
                [106.7985785, 21.1508752],
                [106.8007243, 21.1518458],
                [106.8009362, 21.1519818],
                [106.8013466, 21.1522711],
                [106.8015447, 21.1524242],
                [106.8019259, 21.1527463],
                [106.802108, 21.1529146],
                [106.8024558, 21.1532658],
                [106.8026213, 21.1534484],
                [106.8029344, 21.153827],
                [106.8030304, 21.1539734],
                [106.803243, 21.1542535],
                [106.8034818, 21.1545146],
                [106.8037541, 21.1547627],
                [106.8039042, 21.1548813],
                [106.8042219, 21.1550977],
                [106.8053952, 21.1550654],
                [106.8059814, 21.1550377],
                [106.8066402, 21.1549974],
                [106.8072983, 21.1549473],
                [106.8079555, 21.1548876],
                [106.8097258, 21.1544874],
                [106.8111098, 21.1540771],
                [106.8112918, 21.1540398],
                [106.8116604, 21.153988],
                [106.8118462, 21.1539736],
                [106.8122092, 21.153968],
                [106.8125619, 21.1539909],
                [106.8127372, 21.1540127],
                [106.8130839, 21.1540771],
                [106.8148863, 21.1546274],
                [106.81678, 21.1554329],
                [106.8169622, 21.1555635],
                [106.8173428, 21.1558036],
                [106.8175405, 21.1559128],
                [106.8179494, 21.1561083],
                [106.818169, 21.1561979],
                [106.8183925, 21.1562788],
                [106.8186194, 21.1563509],
                [106.8188493, 21.156414],
                [106.8190819, 21.1564681],
                [106.8193167, 21.1565129],
                [106.8195534, 21.1565486],
                [106.8198986, 21.1564316],
                [106.8202347, 21.1562934],
                [106.8206329, 21.1560956],
                [106.8210125, 21.1558682],
                [106.821903, 21.1557331],
                [106.8220671, 21.155698],
                [106.8222286, 21.1556537],
                [106.8223868, 21.1556003],
                [106.8225414, 21.155538],
                [106.8227977, 21.1554101],
                [106.8230382, 21.1552578],
                [106.8232602, 21.1550827],
                [106.8234138, 21.1550216],
                [106.8234944, 21.1550012],
                [106.823667, 21.154981],
                [106.8237576, 21.1549828],
                [106.8239359, 21.1550112],
                [106.8240219, 21.1550377],
                [106.82414, 21.1551002],
                [106.8241922, 21.1551408],
                [106.8242794, 21.1552378],
                [106.8244731, 21.1552357],
                [106.8246592, 21.1552032],
                [106.8248326, 21.1551435],
                [106.8249147, 21.1551037],
                [106.8252652, 21.15489],
                [106.8255535, 21.1547475],
                [106.8258358, 21.1546379],
                [106.8261275, 21.1545524],
                [106.8273529, 21.1546084],
                [106.8276197, 21.1545905],
                [106.8277516, 21.1545692],
                [106.8279947, 21.1545073],
                [106.8282137, 21.1544239],
                [106.8284208, 21.1543173],
                [106.8289823, 21.1538829],
                [106.8291771, 21.1537769],
                [106.8293828, 21.1537044],
                [106.8294898, 21.1536803],
                [106.8295985, 21.1536644],
                [106.8298106, 21.1536467],
                [106.8300122, 21.1536085],
                [106.8302071, 21.1535468],
                [106.830408, 21.1534542],
                [106.8305933, 21.1533367],
                [106.8307596, 21.1531966],
                [106.831221, 21.1526813],
                [106.8315686, 21.1526017],
                [106.8317449, 21.1525726],
                [106.8321007, 21.1525362],
                [106.8324535, 21.1525289],
                [106.8326297, 21.1525359],
                [106.8329805, 21.1525712],
                [106.833694, 21.1526938],
                [106.8336775, 21.1528809],
                [106.8336662, 21.1530879],
                [106.8334912, 21.1534387],
                [106.8325396, 21.1545477],
                [106.8308636, 21.1561945],
                [106.8300012, 21.1572702],
                [106.8295549, 21.157986],
                [106.8289068, 21.1591174],
                [106.828103, 21.1604769],
                [106.827374, 21.1617643],
                [106.826732, 21.1628246],
                [106.8265032, 21.1632795],
                [106.8263429, 21.1637079],
                [106.8262388, 21.1642131],
                [106.826199, 21.164905],
                [106.8262071, 21.1655641],
                [106.8261297, 21.1665095],
                [106.8262834, 21.1700033],
                [106.8261765, 21.1715781],
                [106.8260701, 21.172759],
                [106.8260691, 21.1736941],
                [106.8260686, 21.1740878],
                [106.8262257, 21.1744816],
                [106.8270115, 21.1761065],
                [106.8270632, 21.1767463],
                [106.8270622, 21.1775829],
                [106.8268242, 21.1790345],
                [106.8264031, 21.1799199],
                [106.8258243, 21.1809034],
                [106.8256136, 21.1814446],
                [106.8255606, 21.181789],
                [106.8255601, 21.1821827],
                [106.8256122, 21.1825764],
                [106.8257694, 21.1829211],
                [106.8261884, 21.1838074],
                [106.8263981, 21.1842013],
                [106.8266076, 21.1847429],
                [106.8269995, 21.1863919],
                [106.8268937, 21.1870808],
                [106.826683, 21.1876711],
                [106.8265249, 21.1881138],
                [106.8264719, 21.1883599],
                [106.8266295, 21.1884093],
                [106.8271024, 21.1882129],
                [106.8280481, 21.1877709],
                [106.8304646, 21.1872812],
                [106.8319879, 21.1869383],
                [106.8344565, 21.1867439],
                [106.8358745, 21.1867453],
                [106.8366622, 21.186746],
                [106.8372924, 21.1867467],
                [106.8378176, 21.1867471],
                [106.8383428, 21.1867476],
                [106.8399186, 21.1863554],
                [106.8426762, 21.1860381],
                [106.8437789, 21.1860883],
                [106.8451442, 21.1862373],
                [106.8466668, 21.1866324],
                [106.8472139, 21.1868427],
                [106.8478217, 21.1870763],
                [106.848399, 21.1874705],
                [106.8488186, 21.1879138],
                [106.8492385, 21.1882095],
                [106.8497635, 21.1884067],
                [106.8506561, 21.1886045],
                [106.8517587, 21.188753],
                [106.8540432, 21.188878],
                [106.8554089, 21.1886824],
                [106.8557767, 21.1884859],
                [106.8566699, 21.1880437],
                [106.8570903, 21.187798],
                [106.8574582, 21.187503],
                [106.8577211, 21.1871096],
                [106.8580892, 21.1867161],
                [106.8582472, 21.1862241],
                [106.8583, 21.1858797],
                [106.858353, 21.1853384],
                [106.8584062, 21.1846494],
                [106.8587246, 21.1812048],
                [106.8588572, 21.1799009],
                [106.8590678, 21.1792612],
                [106.8593832, 21.178917],
                [106.8612761, 21.1764088],
                [106.8615388, 21.1762121],
                [106.8622218, 21.175819],
                [106.8626945, 21.1757208],
                [106.8634824, 21.1755739],
                [106.8651105, 21.1753783],
                [106.8658457, 21.175379],
                [106.8669484, 21.1755767],
                [106.8673158, 21.1756262],
                [106.8676309, 21.1756265],
                [106.8681037, 21.1754792],
                [106.8695223, 21.1745945],
                [106.8699951, 21.1743488],
                [106.8708355, 21.1741033],
                [106.8732251, 21.1738836],
                [106.8735925, 21.1739824],
                [106.8740124, 21.1742779],
                [106.8744846, 21.1747212],
                [106.8750614, 21.1757059],
                [106.8773694, 21.1788081],
                [106.878576, 21.180236],
                [106.8795728, 21.1815162],
                [106.8803861, 21.1824765],
                [106.8810684, 21.1829691],
                [106.8821184, 21.1833635],
                [106.882906, 21.1835609],
                [106.8834836, 21.1836106],
                [106.8840088, 21.1836109],
                [106.8858471, 21.1833168],
                [106.8876332, 21.1824323],
                [106.8904179, 21.1806132],
                [106.8926508, 21.1792121],
                [106.8927558, 21.1792613],
                [106.8934382, 21.1795078],
                [106.8941733, 21.179902],
                [106.8949082, 21.1802469],
                [106.8956432, 21.1804442],
                [106.8962734, 21.1804446],
                [106.8973237, 21.1804945],
                [106.8978488, 21.1805932],
                [106.9013141, 21.1816288],
                [106.9025216, 21.1821215],
                [106.9041228, 21.1828853],
                [106.9049104, 21.183181],
                [106.9066958, 21.1834281],
                [106.9085863, 21.1837244],
                [106.9111594, 21.1838242],
                [106.9116846, 21.1838245],
                [106.9126299, 21.1836773],
                [106.9132602, 21.1836285],
                [106.9138903, 21.1836288],
                [106.914573, 21.1836292],
                [106.9151507, 21.1838262],
                [106.9155181, 21.1840726],
                [106.9158856, 21.1842203],
                [106.9160956, 21.1843188],
                [106.9162496, 21.184332],
                [106.9163845, 21.1843436],
                [106.91733, 21.1840488],
                [106.9175402, 21.183852],
                [106.9188537, 21.182524],
                [106.9225314, 21.179376],
                [106.9231095, 21.1787365],
                [106.9232672, 21.1782937],
                [106.9235567, 21.1770389],
                [106.9240296, 21.1764486],
                [106.9244499, 21.1761536],
                [106.9253957, 21.1749728],
                [106.926499, 21.1739398],
                [106.9267616, 21.1737431],
                [106.928285, 21.1728579],
                [106.9303862, 21.1712348],
                [106.9323296, 21.1702513],
                [106.9343779, 21.1694646],
                [106.9354577, 21.1689133],
                [106.9358836, 21.1689497],
                [106.9360353, 21.1689627],
                [106.9365046, 21.1692441],
                [106.936977, 21.1697856],
                [106.9372393, 21.1705238],
                [106.9373965, 21.1711637],
                [106.9376063, 21.1716066],
                [106.9379738, 21.172099],
                [106.9383412, 21.1726403],
                [106.9387612, 21.172739],
                [106.9392338, 21.1727392],
                [106.9402316, 21.1724934],
                [106.9408093, 21.1723951],
                [106.9411242, 21.1723953],
                [106.9416494, 21.1725431],
                [106.9422794, 21.1730846],
                [106.9431191, 21.1742169],
                [106.9446409, 21.1770718],
                [106.9456116, 21.1791637],
                [106.9457689, 21.179951],
                [106.9457687, 21.1804925],
                [106.9457685, 21.1810829],
                [106.9457682, 21.1818211],
                [106.9457156, 21.1823132],
                [106.9458204, 21.1829038],
                [106.9465025, 21.1843804],
                [106.9474735, 21.185931],
                [106.9476833, 21.1869645],
                [106.9477354, 21.1877519],
                [106.9477354, 21.1880964],
                [106.9477353, 21.1883916],
                [106.9479452, 21.1886377],
                [106.9485754, 21.188884],
                [106.9494681, 21.1889336],
                [106.9503609, 21.1889338],
                [106.9512012, 21.1889833],
                [106.9518839, 21.1891311],
                [106.9547196, 21.1901161],
                [106.9552447, 21.19051],
                [106.9556122, 21.1909037],
                [106.9557696, 21.1911499],
                [106.955822, 21.1915928],
                [106.9554802, 21.1932414],
                [106.955375, 21.1937826],
                [106.9553748, 21.1943732],
                [106.9555845, 21.1956036],
                [106.955742, 21.1959481],
                [106.9560044, 21.1963911],
                [106.9564769, 21.1969818],
                [106.9566344, 21.1974247],
                [106.9566867, 21.1978677],
                [106.9562138, 21.1988517],
                [106.9557669, 21.2002541],
                [106.9557668, 21.2007956],
                [106.9557666, 21.2012877],
                [106.9559765, 21.2020259],
                [106.9561865, 21.2027641],
                [106.9563437, 21.2035024],
                [106.9563961, 21.204093],
                [106.9563959, 21.2046343],
                [106.9563432, 21.2052248],
                [106.9563431, 21.2056186],
                [106.956448, 21.2060123],
                [106.9568943, 21.2067752],
                [106.9582069, 21.2083996],
                [106.9589945, 21.2089903],
                [106.9600975, 21.2094335],
                [106.9608853, 21.2097289],
                [106.9614631, 21.2099259],
                [106.9619882, 21.2101721],
                [106.9623558, 21.2104183],
                [106.9627759, 21.2110581],
                [106.9640885, 21.2133221],
                [106.9650074, 21.215217],
                [106.9652172, 21.2156107],
                [106.9656374, 21.2160046],
                [106.9659525, 21.2163491],
                [106.9661626, 21.216546],
                [106.966215, 21.2168904],
                [106.9662149, 21.2173827],
                [106.9662148, 21.2178256],
                [106.9662147, 21.2183177],
                [106.9663722, 21.2187606],
                [106.9666873, 21.2191544],
                [106.9671074, 21.2195481],
                [106.967475, 21.2199912],
                [106.9677376, 21.2204833],
                [106.9687613, 21.2231656],
                [106.9688137, 21.2237561],
                [106.9689712, 21.2242484],
                [106.9690761, 21.2246912],
                [106.9691286, 21.2249374],
                [106.9693912, 21.2251342],
                [106.9697589, 21.2251835],
                [106.9703894, 21.2249376],
                [106.972123, 21.2245933],
                [106.9731736, 21.2245444],
                [106.974014, 21.224889],
                [106.9752745, 21.2258242],
                [106.9756423, 21.2260211],
                [106.9762201, 21.2262672],
                [106.9768504, 21.2264149],
                [106.978295, 21.2267842],
                [106.9785576, 21.2269811],
                [106.9785575, 21.2271779],
                [106.9777695, 21.2282113],
                [106.9766661, 21.2295399],
                [106.9765085, 21.2298844],
                [106.9764558, 21.2302781],
                [106.9764558, 21.2306717],
                [106.9766658, 21.2310655],
                [106.9777688, 21.2326896],
                [106.9780838, 21.2333294],
                [106.9782414, 21.2337232],
                [106.9782938, 21.2346582],
                [106.9777156, 21.2366266],
                [106.9772163, 21.2382259],
                [106.9772162, 21.2388657],
                [106.9773213, 21.2394071],
                [106.9777414, 21.2398009],
                [106.9782666, 21.2404407],
                [106.9794223, 21.2417203],
                [106.9808405, 21.2434922],
                [106.9812607, 21.2440827],
                [106.9814446, 21.2453377],
                [106.9814968, 21.2471094],
                [106.9814967, 21.2482905],
                [106.9815491, 21.2496684],
                [106.9818642, 21.2512432],
                [106.9823106, 21.2522521],
                [106.9833086, 21.2538762],
                [106.9841493, 21.254713],
                [106.9844119, 21.2552051],
                [106.9844643, 21.2557464],
                [106.9845168, 21.2561401],
                [106.9848846, 21.2567306],
                [106.9851473, 21.2570753],
                [106.9853049, 21.2574689],
                [106.9854098, 21.2586501],
                [106.9852783, 21.2599049],
                [106.984963, 21.2609384],
                [106.9848578, 21.2616765],
                [106.9845424, 21.2624147],
                [106.983833, 21.2634726],
                [106.9837279, 21.2638664],
                [106.9837278, 21.2642601],
                [106.9837803, 21.2648014],
                [106.9837802, 21.2651951],
                [106.9837276, 21.2658348],
                [106.9822037, 21.2674588],
                [106.9819934, 21.2676556],
                [106.9817832, 21.2677048],
                [106.981468, 21.2677047],
                [106.9805222, 21.2676061],
                [106.9800493, 21.2677046],
                [106.9795237, 21.2679014],
                [106.9774217, 21.2693282],
                [106.976318, 21.2704599],
                [106.9756347, 21.2722806],
                [106.9754243, 21.2733142],
                [106.9743993, 21.275061],
                [106.9743992, 21.2755532],
                [106.9744518, 21.2758976],
                [106.9747669, 21.2765866],
                [106.9749244, 21.2770296],
                [106.9749769, 21.2775217],
                [106.9749767, 21.2788996],
                [106.9748789, 21.2794621],
                [106.9749294, 21.2795309],
                [106.9749711, 21.2796046],
                [106.9750035, 21.2796823],
                [106.975023, 21.2797493],
                [106.975041, 21.2798872],
                [106.9749238, 21.2800706],
                [106.9748373, 21.2802537],
                [106.974805, 21.2803421],
                [106.9746709, 21.2809969],
                [106.9746182, 21.2811571],
                [106.9744902, 21.2814703],
                [106.9743329, 21.2817717],
                [106.9742504, 21.2819068],
                [106.9740679, 21.2821669],
                [106.9737652, 21.2825248],
                [106.9733405, 21.2829763],
                [106.9730989, 21.2832136],
                [106.9728503, 21.2834444],
                [106.9725949, 21.2836686],
                [106.9723561, 21.2839885],
                [106.9736999, 21.2846958],
                [106.9740423, 21.2850536],
                [106.974408, 21.2853906],
                [106.9746198, 21.2855676],
                [106.974838, 21.2857376],
                [106.9750625, 21.2859005],
                [106.9751886, 21.2863528],
                [106.9752931, 21.2864957],
                [106.9754246, 21.2866214],
                [106.9754999, 21.2866765],
                [106.9755802, 21.2867252],
                [106.975688, 21.2867659],
                [106.9758912, 21.2868712],
                [106.9760734, 21.2870062],
                [106.976155, 21.2870837],
                [106.9762965, 21.2872562],
                [106.9768006, 21.2880648],
                [106.9797724, 21.2886146],
                [106.9802338, 21.2890895],
                [106.9806468, 21.2892744],
                [106.9807344, 21.2893228],
                [106.980899, 21.2894343],
                [106.9809852, 21.2895056],
                [106.9811404, 21.2896643],
                [106.9815802, 21.2902341],
                [106.981658, 21.2903965],
                [106.9817358, 21.2905989],
                [106.9818753, 21.2910938],
                [106.983439, 21.2919635],
                [106.9835329, 21.2924308],
                [106.9842839, 21.2924583],
                [106.9843506, 21.2925048],
                [106.984496, 21.29258],
                [106.9846578, 21.2926308],
                [106.9847439, 21.2926458],
                [106.9856518, 21.2926882],
                [106.9866013, 21.2927032],
                [106.9879049, 21.293278],
                [106.9900292, 21.2936229],
                [106.9911611, 21.2938578],
                [106.9912765, 21.2937837],
                [106.9913857, 21.2937019],
                [106.9915658, 21.2935363],
                [106.9917055, 21.2933711],
                [106.9917673, 21.293283],
                [106.991998, 21.2930281],
                [106.9921273, 21.2929553],
                [106.9921981, 21.2929302],
                [106.9923466, 21.2929044],
                [106.9924973, 21.2929122],
                [106.9926417, 21.2929531],
                [106.9935134, 21.2933805],
                [106.9936695, 21.2934447],
                [106.9939918, 21.2935486],
                [106.9943044, 21.2936154],
                [106.9946025, 21.2936502],
                [106.9949028, 21.2936579],
                [106.9954902, 21.2936329],
                [106.9958244, 21.2937775],
                [106.9965041, 21.2940427],
                [106.9967633, 21.294134],
                [106.9972873, 21.2943026],
                [106.998505, 21.2943576],
                [106.9986115, 21.2943809],
                [106.9988161, 21.2944526],
                [106.9989886, 21.2945456],
                [106.9991434, 21.2946625],
                [106.9994384, 21.2949374],
                [106.999597, 21.2949177],
                [106.9996744, 21.2948982],
                [106.9998286, 21.2948374],
                [106.9999037, 21.2947955],
                [107.0000392, 21.2946925],
                [107.0006186, 21.2941377],
                [107.0006794, 21.2941629],
                [107.0008077, 21.2941953],
                [107.0009404, 21.2942027],
                [107.0010205, 21.2941949],
                [107.001099, 21.2941779],
                [107.0011746, 21.2941521],
                [107.0014501, 21.2940377],
                [107.0020026, 21.2942777],
                [107.003848, 21.293243],
                [107.0039656, 21.2932161],
                [107.0040867, 21.293218],
                [107.0042011, 21.2932477],
                [107.0043039, 21.293303],
                [107.0043903, 21.2934136],
                [107.0044568, 21.2935354],
                [107.004485, 21.2936079],
                [107.0045061, 21.2936825],
                [107.0045266, 21.2938353],
                [107.0056607, 21.2946979],
                [107.0058489, 21.2948599],
                [107.005928, 21.2949411],
                [107.0060715, 21.2951148],
                [107.0065972, 21.2958496],
                [107.0082307, 21.2952673],
                [107.009148, 21.2950874],
                [107.0098829, 21.2949174],
                [107.0100033, 21.2948972],
                [107.0102472, 21.2948789],
                [107.0103695, 21.2948809],
                [107.0106125, 21.2949074],
                [107.0107348, 21.2949324],
                [107.0109726, 21.2950048],
                [107.0111978, 21.2951063],
                [107.0113045, 21.2951673],
                [107.0113777, 21.2952202],
                [107.0114446, 21.29528],
                [107.0115045, 21.2953459],
                [107.0115566, 21.2954172],
                [107.0116301, 21.2955586],
                [107.0116735, 21.2957104],
                [107.0116854, 21.2958671],
                [107.0119938, 21.296152],
                [107.0126027, 21.2970916],
                [107.0130721, 21.2976564],
                [107.0142227, 21.2980012],
                [107.0144677, 21.298025],
                [107.0147072, 21.2980785],
                [107.0149454, 21.2981646],
                [107.0150634, 21.2982209],
                [107.0152849, 21.2983561],
                [107.0161915, 21.2990408],
                [107.0168889, 21.2992607],
                [107.0179349, 21.3000404],
                [107.0181345, 21.2999453],
                [107.01823, 21.2998905],
                [107.0185352, 21.2996798],
                [107.0186619, 21.2996246],
                [107.0187288, 21.2996056],
                [107.0198661, 21.2994107],
                [107.0199217, 21.2994064],
                [107.0200324, 21.2994164],
                [107.020086, 21.2994307],
                [107.0201744, 21.2994698],
                [107.0202148, 21.2994957],
                [107.0204025, 21.2999855],
                [107.0205367, 21.3003053],
                [107.0207374, 21.3006494],
                [107.0209625, 21.3009801],
                [107.021215, 21.301301],
                [107.021495, 21.3016097],
                [107.0217973, 21.3018997],
                [107.0224518, 21.3023645],
                [107.022838, 21.3027343],
                [107.0230165, 21.3027973],
                [107.0231836, 21.3028832],
                [107.0233393, 21.302993],
                [107.0234118, 21.303057],
                [107.0234791, 21.3031258],
                [107.0237036, 21.3034013],
                [107.0238599, 21.3036079],
                [107.0241523, 21.3040338],
                [107.0243807, 21.304053],
                [107.0248389, 21.3040738],
                [107.0253685, 21.3040683],
                [107.0256329, 21.3040538],
                [107.025767, 21.3044236],
                [107.0257258, 21.3045791],
                [107.0256597, 21.3048934],
                [107.025608, 21.3052869],
                [107.0255899, 21.305683],
                [107.0256118, 21.3060083],
                [107.0256704, 21.3063296],
                [107.0260835, 21.3078071],
                [107.0277625, 21.3082569],
                [107.0296884, 21.3090765],
                [107.0298272, 21.3092115],
                [107.0300885, 21.3094953],
                [107.0303267, 21.3097962],
                [107.0304563, 21.3099817],
                [107.0305771, 21.3101722],
                [107.0306889, 21.3103675],
                [107.0307915, 21.3105671],
                [107.0308846, 21.3107707],
                [107.0310122, 21.3111399],
                [107.0310885, 21.3113204],
                [107.0312365, 21.3116196],
                [107.0313191, 21.3117652],
                [107.0320138, 21.3128497],
                [107.0322418, 21.3137243],
                [107.0326924, 21.3141491],
                [107.0332396, 21.3152835],
                [107.0329499, 21.3159481],
                [107.0328433, 21.3162148],
                [107.0326441, 21.3167527],
                [107.0327229, 21.316871],
                [107.0328561, 21.31712],
                [107.0329553, 21.3173824],
                [107.0330123, 21.3176176],
                [107.0330411, 21.3178572],
                [107.0330448, 21.3179923],
                [107.0330168, 21.3183674],
                [107.0330165, 21.318473],
                [107.0330394, 21.3186832],
                [107.0330932, 21.3188879],
                [107.0331312, 21.3189869],
                [107.0332289, 21.3191765],
                [107.0334971, 21.3195862],
                [107.0335291, 21.3196465],
                [107.0335692, 21.3197753],
                [107.0335769, 21.3198422],
                [107.0335668, 21.319976],
                [107.03355, 21.3200389],
                [107.0334942, 21.3201572],
                [107.0334113, 21.3202609],
                [107.0329499, 21.3206657],
                [107.0329227, 21.3207249],
                [107.0328957, 21.3208511],
                [107.0329044, 21.3209705],
                [107.0329412, 21.3210759],
                [107.0330036, 21.3211704],
                [107.0332429, 21.3214713],
                [107.0333238, 21.321607],
                [107.0333723, 21.3217561],
                [107.0333835, 21.3218337],
                [107.0333791, 21.3219899],
                [107.0333228, 21.3222071],
                [107.0332342, 21.3224147],
                [107.0331154, 21.3226082],
                [107.0330453, 21.3226986],
                [107.0328855, 21.3228644],
                [107.032502, 21.323609],
                [107.0324405, 21.323748],
                [107.0323385, 21.3240331],
                [107.0322615, 21.3243436],
                [107.0322341, 21.3245101],
                [107.0322069, 21.3248458],
                [107.0321751, 21.3249912],
                [107.0321124, 21.3251276],
                [107.0320181, 21.3252527],
                [107.0319591, 21.3253093],
                [107.0318234, 21.325403],
                [107.0300665, 21.3261426],
                [107.0297822, 21.3264099],
                [107.0282829, 21.3271895],
                [107.0278709, 21.3273767],
                [107.0275264, 21.3275644],
                [107.0272449, 21.3277416],
                [107.0263329, 21.3283762],
                [107.026239, 21.3284386],
                [107.0261395, 21.3284929],
                [107.0260352, 21.3285386],
                [107.025851, 21.3285956],
                [107.0255063, 21.3286537],
                [107.0252037, 21.328726],
                [107.0250365, 21.3287766],
                [107.0247102, 21.3288984],
                [107.0246482, 21.3289799],
                [107.0246121, 21.3290781],
                [107.0246058, 21.3291318],
                [107.0246102, 21.329725],
                [107.0246037, 21.3298343],
                [107.0245653, 21.3300502],
                [107.0245361, 21.3301486],
                [107.0244568, 21.3303389],
                [107.0243508, 21.3305175],
                [107.0242649, 21.3306028],
                [107.0241725, 21.3306819],
                [107.0239699, 21.3308198],
                [107.0238102, 21.3309015],
                [107.0234804, 21.3310458],
                [107.0232305, 21.3311486],
                [107.0227414, 21.3313267],
                [107.022503, 21.3314029],
                [107.0219801, 21.3315499],
                [107.0219073, 21.3315893],
                [107.0218481, 21.3316429],
                [107.0218241, 21.3316742],
                [107.0217953, 21.3317896],
                [107.0217758, 21.3319066],
                [107.0217651, 21.3321315],
                [107.0217802, 21.3322659],
                [107.0217966, 21.3323319],
                [107.0218465, 21.3324587],
                [107.021918, 21.3325762],
                [107.0224634, 21.3330956],
                [107.0226063, 21.3332571],
                [107.0227196, 21.3334378],
                [107.0227642, 21.3335339],
                [107.0228258, 21.333725],
                [107.0228439, 21.3338185],
                [107.0228581, 21.3340078],
                [107.0228454, 21.3343285],
                [107.0228282, 21.3345538],
                [107.0227682, 21.3350021],
                [107.0227476, 21.3351154],
                [107.0226856, 21.3353376],
                [107.0225966, 21.3355518],
                [107.0224797, 21.3357583],
                [107.0223378, 21.3359507],
                [107.0221728, 21.3361264],
                [107.0221406, 21.3365062],
                [107.0220781, 21.3366138],
                [107.0219924, 21.3367065],
                [107.0218814, 21.3367837],
                [107.0218163, 21.3368144],
                [107.0216767, 21.3368533],
                [107.0216042, 21.3368609],
                [107.0211106, 21.3368559],
                [107.0201182, 21.3373706],
                [107.0200395, 21.3374406],
                [107.0198929, 21.3375905],
                [107.0198222, 21.337674],
                [107.0196944, 21.3378503],
                [107.0194635, 21.3383879],
                [107.0193958, 21.3385172],
                [107.0192359, 21.3387632],
                [107.0191443, 21.338879],
                [107.0189461, 21.3390881],
                [107.0187304, 21.3392687],
                [107.0186147, 21.3393501],
                [107.0183694, 21.3394942],
                [107.0175594, 21.339844],
                [107.0177096, 21.3406734],
                [107.0176935, 21.341253],
                [107.0176965, 21.3413417],
                [107.0177203, 21.3415178],
                [107.017774, 21.3417077],
                [107.0178639, 21.3419621],
                [107.0178974, 21.3420924],
                [107.0179241, 21.3422313],
                [107.017942, 21.3423713],
                [107.0179587, 21.3426059],
                [107.0179886, 21.3427919],
                [107.0180452, 21.3429984],
                [107.0180824, 21.3430992],
                [107.0181208, 21.3431725],
                [107.0181435, 21.3432687],
                [107.0181441, 21.3433266],
                [107.0181066, 21.3433975],
                [107.0180626, 21.3434652],
                [107.0179563, 21.3435884],
                [107.0178949, 21.343643],
                [107.0177579, 21.3437363],
                [107.0176059, 21.3438057],
                [107.0174434, 21.3438494],
                [107.0172751, 21.3438662],
                [107.0159662, 21.3438862],
                [107.0141994, 21.3443967],
                [107.0140928, 21.3444326],
                [107.0138947, 21.3445231],
                [107.0138039, 21.3445766],
                [107.0136353, 21.3447006],
                [107.0131016, 21.3452852],
                [107.0117068, 21.3462045],
                [107.0113585, 21.3464178],
                [107.0110062, 21.3466255],
                [107.0102906, 21.3470239],
                [107.0098652, 21.3472465],
                [107.009435, 21.3474611],
                [107.0092509, 21.3475859],
                [107.0091677, 21.3476585],
                [107.0090219, 21.3478215],
                [107.0089602, 21.3479108],
                [107.0088651, 21.3480936],
                [107.008802, 21.348288],
                [107.0087242, 21.3488201],
                [107.0076466, 21.348995],
                [107.0061439, 21.3495596],
                [107.0054868, 21.3494322],
                [107.0049477, 21.3493972],
                [107.0047092, 21.3494156],
                [107.0045917, 21.3494367],
                [107.0043629, 21.3495021],
                [107.0041434, 21.3495989],
                [107.0040398, 21.3496582],
                [107.003848, 21.3497969],
                [107.0029253, 21.3501167],
                [107.0017827, 21.3501216],
                [107.0016668, 21.3502079],
                [107.0015573, 21.3503011],
                [107.0014545, 21.3504007],
                [107.0012774, 21.3506088],
                [107.0011349, 21.3508266],
                [107.0008559, 21.3514047],
                [107.0007243, 21.3516247],
                [107.0005618, 21.3518459],
                [107.0004667, 21.351957],
                [107.0002592, 21.3521651],
                [107.0001846, 21.3522517],
                [107.0000581, 21.3524396],
                [107.000007, 21.3525398],
                [106.9999359, 21.352731],
                [106.9998944, 21.3529295],
                [106.9998207, 21.3534881],
                [106.9998026, 21.3535708],
                [106.9997759, 21.3536515],
                [106.9996981, 21.3538037],
                [106.9995867, 21.3539424],
                [106.9995185, 21.3540049],
                [106.9994438, 21.3540605],
                [106.9993633, 21.3541086],
                [106.9991862, 21.3542457],
                [106.9990152, 21.3543894],
                [106.9986931, 21.3546958],
                [106.9985425, 21.354858],
                [106.9982896, 21.3551646],
                [106.9981725, 21.3553242],
                [106.9979577, 21.355655],
                [106.9978604, 21.3558258],
                [106.9969301, 21.3559075],
                [106.9963758, 21.3560598],
                [106.9959802, 21.3564182],
                [106.995604, 21.3565683],
                [106.99467, 21.3566002],
                [106.9923396, 21.3570234],
                [106.9915166, 21.357033],
                [106.9909596, 21.3569772],
                [106.990446, 21.3568377],
                [106.9899345, 21.3568438],
                [106.9894682, 21.3569117],
                [106.9890711, 21.3571661],
                [106.9884997, 21.3576932],
                [106.9875009, 21.3595153],
                [106.9873331, 21.3602664],
                [106.9873263, 21.361411],
                [106.9876914, 21.3620933],
                [106.9886336, 21.3626648],
                [106.9893058, 21.3630106],
                [106.9897562, 21.3634214],
                [106.9901891, 21.3641654],
                [106.9908556, 21.365739],
                [106.991191, 21.3675037],
                [106.9911186, 21.3720201],
                [106.9910147, 21.3725624],
                [106.9910665, 21.3731028],
                [106.9925196, 21.3752497],
                [106.992975, 21.3760143],
                [106.9930809, 21.3772615],
                [106.9928305, 21.3784922],
                [106.9921228, 21.3804566],
                [106.9915044, 21.3824408],
                [106.990753, 21.3844682],
                [106.987878, 21.3874363],
                [106.9877536, 21.3881037],
                [106.9878284, 21.3887062],
                [106.9880385, 21.389432],
                [106.9881591, 21.3901173],
                [106.9880582, 21.3908884],
                [106.9872555, 21.392417],
                [106.9863566, 21.3950704],
                [106.985869, 21.3968657],
                [106.9853921, 21.4010956],
                [106.9855549, 21.4016138],
                [106.9858039, 21.401923],
                [106.988229, 21.4035174],
                [106.9885223, 21.4038052],
                [106.9887047, 21.4041359],
                [106.9887769, 21.4045305],
                [106.9890258, 21.4048397],
                [106.9904705, 21.4063415],
                [106.9913354, 21.4077462],
                [106.9914339, 21.4084525],
                [106.9913278, 21.4088283],
                [106.9909769, 21.409207],
                [106.9905853, 21.4098777],
                [106.9902865, 21.4108175],
                [106.9901405, 21.4115476],
                [106.990224, 21.4127743],
                [106.9903874, 21.4133342],
                [106.9907946, 21.4138288],
                [106.9915346, 21.4142362],
                [106.9929547, 21.415551],
                [106.9934044, 21.4158786],
                [106.9945226, 21.4162815],
                [106.9968824, 21.4179597],
                [106.9978867, 21.4181558],
                [107.0003647, 21.4183546],
                [107.0019244, 21.4189986],
                [107.0026971, 21.4194542],
                [107.0030401, 21.4198192],
                [107.0034307, 21.4203842],
                [107.0037926, 21.4213081],
                [107.0038175, 21.4214871],
                [107.0037973, 21.4216561],
                [107.003548, 21.4218768],
                [107.0035032, 21.4219278],
                [107.0034325, 21.4220411],
                [107.0033893, 21.4221652],
                [107.003338, 21.4226406],
                [107.0032922, 21.4228387],
                [107.0032577, 21.4229349],
                [107.0031667, 21.423119],
                [107.003104, 21.4232151],
                [107.0030332, 21.4233061],
                [107.0029548, 21.4233915],
                [107.0028691, 21.4234707],
                [107.0027768, 21.4235431],
                [107.0014139, 21.4244761],
                [107.0013314, 21.4245422],
                [107.0011803, 21.424688],
                [107.0010484, 21.4248507],
                [107.0009904, 21.4249379],
                [107.0008922, 21.4251215],
                [106.9994813, 21.4262251],
                [106.9982529, 21.4263749],
                [106.9981785, 21.426389],
                [106.9980379, 21.4264418],
                [106.9979197, 21.426521],
                [106.9978709, 21.4265676],
                [106.9977915, 21.4266745],
                [106.9975877, 21.427069],
                [106.9974862, 21.4271339],
                [106.997298, 21.4272815],
                [106.9972122, 21.4273637],
                [106.9970786, 21.4275168],
                [106.997019, 21.4275984],
                [106.9967025, 21.4281976],
                [106.9962358, 21.4287419],
                [106.9961675, 21.4288548],
                [106.9960749, 21.4289516],
                [106.9960139, 21.4289972],
                [106.9959474, 21.4290357],
                [106.9958005, 21.4290936],
                [106.995657, 21.4291646],
                [106.9955277, 21.4292562],
                [106.9954572, 21.4293213],
                [106.995394, 21.4293926],
                [106.9953386, 21.4294694],
                [106.9952917, 21.4295509],
                [106.9951893, 21.429773],
                [106.9950557, 21.4299803],
                [106.9948971, 21.430165],
                [106.9947137, 21.4303286],
                [106.9935767, 21.4303555],
                [106.9934604, 21.4303477],
                [106.9932304, 21.4303124],
                [106.9929778, 21.430241],
                [106.9928561, 21.4301932],
                [106.9927382, 21.4301376],
                [106.9926174, 21.4300695],
                [106.9925516, 21.4300452],
                [106.9924293, 21.4300191],
                [106.9923198, 21.4300252],
                [106.9922745, 21.4300374],
                [106.9921911, 21.4300777],
                [106.9920355, 21.4303199],
                [106.9917378, 21.4305583],
                [106.9916552, 21.4306446],
                [106.9916237, 21.4306946],
                [106.9915864, 21.4307909],
                [106.9915534, 21.43102],
                [106.9915058, 21.431155],
                [106.9914408, 21.4312687],
                [106.9913138, 21.4314156],
                [106.9912436, 21.4315162],
                [106.9911973, 21.4316282],
                [106.9911772, 21.431743],
                [106.9911758, 21.4320029],
                [106.9911504, 21.432522],
                [106.9911298, 21.4327485],
                [106.9910713, 21.4331999],
                [106.991068, 21.433301],
                [106.9910747, 21.433402],
                [106.9910914, 21.433502],
                [106.9911374, 21.4336552],
                [106.9911931, 21.4337822],
                [106.9912088, 21.4338379],
                [106.9912148, 21.4339526],
                [106.991187, 21.4340633],
                [106.9911159, 21.4342163],
                [106.9910784, 21.4343203],
                [106.991027, 21.4345343],
                [106.9910132, 21.4346454],
                [106.9910109, 21.4348689],
                [106.9910565, 21.4359517],
                [106.9910411, 21.4360689],
                [106.9909987, 21.4361803],
                [106.9909275, 21.4362851],
                [106.9908804, 21.4363331],
                [106.9907695, 21.4364118],
                [106.9892245, 21.4372058],
                [106.9891534, 21.4372491],
                [106.9890242, 21.4373518],
                [106.9888607, 21.4375308],
                [106.9888006, 21.4375852],
                [106.9886699, 21.4376798],
                [106.9885273, 21.4377549],
                [106.9880471, 21.4379348],
                [106.987987, 21.4379831],
                [106.9878832, 21.4380947],
                [106.9878405, 21.438157],
                [106.9877788, 21.4382829],
                [106.9877574, 21.4383492],
                [106.987713, 21.4385709],
                [106.9876358, 21.4388861],
                [106.98756, 21.4390682],
                [106.9875097, 21.4391541],
                [106.9873926, 21.4393051],
                [106.987328, 21.4393702],
                [106.9871834, 21.4394852],
                [106.9870893, 21.4395409],
                [106.9869849, 21.4395776],
                [106.9869248, 21.439589],
                [106.9868025, 21.4395926],
                [106.984756, 21.4391332],
                [106.9836831, 21.4387038],
                [106.9828123, 21.4390081],
                [106.982717, 21.4390305],
                [106.982522, 21.4390523],
                [106.9823259, 21.4390433],
                [106.9821274, 21.4390017],
                [106.9820318, 21.4389689],
                [106.9817765, 21.4388545],
                [106.9816898, 21.4388293],
                [106.9815105, 21.4388036],
                [106.9813529, 21.4388087],
                [106.9812751, 21.4388208],
                [106.9811243, 21.4388636],
                [106.9805932, 21.4391282],
                [106.9804698, 21.4390084],
                [106.9802116, 21.4390089],
                [106.9800828, 21.4389994],
                [106.9798373, 21.4389628],
                [106.9796064, 21.4389053],
                [106.9794935, 21.4388685],
                [106.978544, 21.4384891],
                [106.9784206, 21.4381295],
                [106.9783522, 21.4380018],
                [106.978195, 21.4377569],
                [106.9780118, 21.4375281],
                [106.9778025, 21.437316],
                [106.9776884, 21.437217],
                [106.9774443, 21.437036],
                [106.9773132, 21.436976],
                [106.9772535, 21.4369366],
                [106.9771986, 21.4368915],
                [106.977083, 21.4367726],
                [106.9769989, 21.4367273],
                [106.976906, 21.4367084],
                [106.9768113, 21.4367164],
                [106.976737, 21.4367534],
                [106.9765806, 21.4368113],
                [106.9764961, 21.4368325],
                [106.9763231, 21.4368563],
                [106.9759823, 21.4368475],
                [106.9757384, 21.4368413],
                [106.9756622, 21.4369009],
                [106.9754982, 21.437006],
                [106.9752977, 21.4371],
                [106.9750839, 21.4372208],
                [106.9749844, 21.4372923],
                [106.9748548, 21.4374001],
                [106.9748144, 21.4374245],
                [106.9747245, 21.4374554],
                [106.9746295, 21.4374608],
                [106.9745368, 21.4374405],
                [106.9736764, 21.4371509],
                [106.9735981, 21.4371122],
                [106.9735251, 21.4370654],
                [106.9734585, 21.437011],
                [106.9733666, 21.4369093],
                [106.9733287, 21.4368529],
                [106.9731577, 21.4364774],
                [106.9730889, 21.4363558],
                [106.9729278, 21.4361253],
                [106.9727498, 21.4359268],
                [106.9725618, 21.4357593],
                [106.9724607, 21.4356828],
                [106.9723275, 21.4356889],
                [106.9720641, 21.4357264],
                [106.9718416, 21.435786],
                [106.9717511, 21.4358214],
                [106.971664, 21.4358637],
                [106.9715095, 21.4359623],
                [106.9713797, 21.4360763],
                [106.9712698, 21.4362071],
                [106.9710979, 21.4360652],
                [106.9709041, 21.4359503],
                [106.9706932, 21.4358654],
                [106.9704705, 21.4358126],
                [106.9702455, 21.4357933],
                [106.97002, 21.4358068],
                [106.9699087, 21.4358257],
                [106.9696047, 21.4359069],
                [106.9694248, 21.4359235],
                [106.9692447, 21.4359103],
                [106.9690682, 21.435867],
                [106.9689012, 21.4357943],
                [106.9688237, 21.4357477],
                [106.968543, 21.4355283],
                [106.9683945, 21.4354282],
                [106.9682412, 21.4353348],
                [106.9679735, 21.4351926],
                [106.9679237, 21.4351573],
                [106.9678366, 21.4350736],
                [106.9677653, 21.4349679],
                [106.9677201, 21.4348505],
                [106.9677079, 21.434789],
                [106.967048, 21.4344545],
                [106.9669193, 21.4336255],
                [106.9667537, 21.433409],
                [106.9666805, 21.4332948],
                [106.9665545, 21.4330563],
                [106.9664472, 21.4327767],
                [106.9662809, 21.4322523],
                [106.9661039, 21.431778],
                [106.9655836, 21.4313935],
                [106.9654173, 21.4310239],
                [106.9652358, 21.4309912],
                [106.9650635, 21.4309289],
                [106.964906, 21.430839],
                [106.9647682, 21.4307243],
                [106.9646508, 21.4305832],
                [106.9645635, 21.4304243],
                [106.9645321, 21.4303398],
                [106.964479, 21.4301386],
                [106.9643912, 21.4299481],
                [106.964271, 21.4297736],
                [106.9641998, 21.4296939],
                [106.9640374, 21.4295521],
                [106.9637149, 21.4293523],
                [106.9636061, 21.4292731],
                [106.9634056, 21.4290964],
                [106.9632324, 21.4289002],
                [106.9630866, 21.4286854],
                [106.9630247, 21.4285721],
                [106.9629295, 21.4284512],
                [106.9627201, 21.4282237],
                [106.9626063, 21.4281177],
                [106.9623767, 21.4279328],
                [106.962255, 21.4278483],
                [106.9619994, 21.4276958],
                [106.961866, 21.4276283],
                [106.9618331, 21.4267815],
                [106.9618173, 21.4267304],
                [106.9617923, 21.4266826],
                [106.9617277, 21.4266105],
                [106.9616546, 21.4265652],
                [106.9616139, 21.4265497],
                [106.9610828, 21.4264498],
                [106.9596881, 21.4250716],
                [106.9596046, 21.4249982],
                [106.9595146, 21.4249318],
                [106.9594188, 21.4248729],
                [106.9593179, 21.4248219],
                [106.9591273, 21.4247516],
                [106.9590282, 21.424727],
                [106.9588156, 21.4246942],
                [106.9586025, 21.4246299],
                [106.9584968, 21.4245849],
                [106.9582102, 21.4244338],
                [106.9580097, 21.4243665],
                [106.9579056, 21.4243453],
                [106.9577066, 21.4243284],
                [106.9575205, 21.4243399],
                [106.9573384, 21.4243774],
                [106.9571359, 21.4243781],
                [106.9569363, 21.4243458],
                [106.9568396, 21.4243175],
                [106.9566565, 21.4242382],
                [106.9564097, 21.424092],
                [106.9562433, 21.4240037],
                [106.9559563, 21.4238735],
                [106.9557358, 21.4237534],
                [106.9555302, 21.4236033],
                [106.9554341, 21.4235168],
                [106.9552624, 21.4233262],
                [106.9550471, 21.4233258],
                [106.9548333, 21.4233487],
                [106.9546161, 21.4233967],
                [106.9544068, 21.4234686],
                [106.9539911, 21.4232938],
                [106.9538277, 21.4232637],
                [106.9534977, 21.4232235],
                [106.9533315, 21.4232136],
                [106.9529986, 21.4232139],
                [106.9526649, 21.4232415],
                [106.9523352, 21.4232963],
                [106.9521725, 21.4233337],
                [106.951518, 21.4235684],
                [106.9512466, 21.4239307],
                [106.9511005, 21.4241047],
                [106.9507885, 21.4244374],
                [106.9504313, 21.4247652],
                [106.9500482, 21.4250666],
                [106.9499585, 21.4251664],
                [106.9498926, 21.4252813],
                [106.9498534, 21.4254064],
                [106.9498183, 21.4258846],
                [106.9498063, 21.4259529],
                [106.949761, 21.4260845],
                [106.9496888, 21.4262051],
                [106.9495851, 21.4263166],
                [106.9495243, 21.4263641],
                [106.9493884, 21.4264398],
                [106.9493493, 21.4266605],
                [106.9492543, 21.427099],
                [106.9491856, 21.4273632],
                [106.9490236, 21.4278855],
                [106.9489109, 21.428692],
                [106.9488428, 21.4288482],
                [106.9487232, 21.4291663],
                [106.9486684, 21.4293395],
                [106.9485784, 21.4296907],
                [106.9485439, 21.4297828],
                [106.9484521, 21.4299579],
                [106.9483953, 21.4300399],
                [106.9482619, 21.43019],
                [106.9481784, 21.4302634],
                [106.9480884, 21.4303298],
                [106.9479926, 21.4303887],
                [106.9478917, 21.4304397],
                [106.9474311, 21.430565],
                [106.9473538, 21.4305923],
                [106.9472796, 21.4306262],
                [106.9471429, 21.4307126],
                [106.9470817, 21.4307643],
                [106.9469803, 21.4308767],
                [106.9468703, 21.4310582],
                [106.9468315, 21.4311083],
                [106.9467862, 21.4311534],
                [106.9466794, 21.4312262],
                [106.9465663, 21.4312694],
                [106.9465064, 21.4312815],
                [106.9463843, 21.4312861],
                [106.9459967, 21.43119],
                [106.9460936, 21.4312835],
                [106.9461832, 21.4313831],
                [106.946265, 21.4314883],
                [106.9463398, 21.4316008],
                [106.9466299, 21.4321199],
                [106.9468754, 21.4325159],
                [106.9470066, 21.4327117],
                [106.9470426, 21.4327833],
                [106.9470893, 21.432935],
                [106.9470994, 21.4330135],
                [106.9470924, 21.4331711],
                [106.9470757, 21.4332478],
                [106.9470167, 21.4333941],
                [106.9469261, 21.4335257],
                [106.946499, 21.433906],
                [106.9462758, 21.4340863],
                [106.9459486, 21.4343302],
                [106.9458468, 21.4343956],
                [106.9456325, 21.4345103],
                [106.9453946, 21.4346065],
                [106.9451344, 21.4346792],
                [106.9447608, 21.4347274],
                [106.9445727, 21.4347404],
                [106.9441956, 21.4347441],
                [106.9438301, 21.4347191],
                [106.943468, 21.4346661],
                [106.943289, 21.4346292],
                [106.9431637, 21.4346124],
                [106.94304, 21.4345867],
                [106.9429189, 21.4345523],
                [106.9426915, 21.4344606],
                [106.9425863, 21.4344045],
                [106.9425284, 21.4343586],
                [106.9424647, 21.43432],
                [106.9423961, 21.4342893],
                [106.9423239, 21.4342672],
                [106.9422492, 21.4342539],
                [106.9421732, 21.4342497],
                [106.9420048, 21.4342687],
                [106.9419225, 21.4342881],
                [106.9417647, 21.4343463],
                [106.94161, 21.4344355],
                [106.9415353, 21.4344935],
                [106.9414059, 21.4346284],
                [106.9412347, 21.4348781],
                [106.9410926, 21.4350355],
                [106.9410132, 21.4351071],
                [106.940887, 21.435203],
                [106.9408412, 21.4352267],
                [106.940741, 21.4352543],
                [106.9406365, 21.435254],
                [106.9405854, 21.4352434],
                [106.9404914, 21.4352026],
                [106.9404137, 21.4351385],
                [106.9403215, 21.4350238],
                [106.9402376, 21.4349038],
                [106.9401621, 21.4347789],
                [106.9400956, 21.4346498],
                [106.940015, 21.4344549],
                [106.9399461, 21.4343407],
                [106.9399013, 21.4342901],
                [106.939734, 21.4341559],
                [106.9396826, 21.4341045],
                [106.9395956, 21.4339901],
                [106.9395544, 21.4339148],
                [106.9395221, 21.4338358],
                [106.9394991, 21.4337541],
                [106.9394696, 21.4335865],
                [106.9394118, 21.4334246],
                [106.9393218, 21.4332761],
                [106.9392015, 21.4331451],
                [106.9390546, 21.4330372],
                [106.9389734, 21.4329939],
                [106.9384879, 21.4328366],
                [106.938221, 21.4327786],
                [106.9379654, 21.4326865],
                [106.9377261, 21.4325619],
                [106.9375877, 21.4324692],
                [106.9374554, 21.432369],
                [106.9373297, 21.4322616],
                [106.9372112, 21.4321475],
                [106.9371022, 21.4320295],
                [106.9370009, 21.4319059],
                [106.9369075, 21.4317769],
                [106.9368222, 21.4316431],
                [106.9364306, 21.4312012],
                [106.9363625, 21.4311989],
                [106.9362268, 21.4312087],
                [106.93609, 21.4312387],
                [106.9350937, 21.4316094],
                [106.9350164, 21.4316103],
                [106.9349447, 21.4315832],
                [106.934887, 21.4315282],
                [106.9348589, 21.4314559],
                [106.9348213, 21.4311213],
                [106.9344163, 21.4305995],
                [106.9341908, 21.4302713],
                [106.9340866, 21.4301024],
                [106.9338959, 21.4297556],
                [106.9337481, 21.4294412],
                [106.9336384, 21.4291857],
                [106.93359, 21.4290924],
                [106.933437, 21.4288382],
                [106.9333005, 21.4285122],
                [106.9332019, 21.4283396],
                [106.9331449, 21.4282575],
                [106.9322705, 21.4276133],
                [106.9322271, 21.4275734],
                [106.9321525, 21.4274835],
                [106.9320881, 21.4273637],
                [106.9320774, 21.4272488],
                [106.9320601, 21.4271878],
                [106.9320023, 21.427074],
                [106.9317412, 21.4269032],
                [106.9316034, 21.426828],
                [106.9313157, 21.4266995],
                [106.9310509, 21.4266113],
                [106.9309154, 21.4265763],
                [106.9306397, 21.4265247],
                [106.9296607, 21.4261577],
                [106.929497, 21.4260566],
                [106.9293523, 21.425933],
                [106.9292924, 21.4258685],
                [106.9291368, 21.4256668],
                [106.9290753, 21.4256184],
                [106.9289398, 21.4255377],
                [106.9288668, 21.425506],
                [106.9287026, 21.4254592],
                [106.9284943, 21.4254368],
                [106.928431, 21.4255521],
                [106.9284081, 21.4256134],
                [106.928382, 21.4257333],
                [106.9283786, 21.4258556],
                [106.9284081, 21.4267145],
                [106.9284359, 21.4269788],
                [106.928494, 21.4272388],
                [106.92858, 21.4274875],
                [106.9287041, 21.427774],
                [106.9287461, 21.4279479],
                [106.9287582, 21.4281376],
                [106.9287141, 21.4287049],
                [106.9287275, 21.4288803],
                [106.9287461, 21.4289666],
                [106.9288046, 21.4291295],
                [106.9288444, 21.429207],
                [106.9290831, 21.4295779],
                [106.9293658, 21.4299884],
                [106.9294403, 21.4301113],
                [106.9295056, 21.4302386],
                [106.9295615, 21.4303698],
                [106.92964, 21.4306241],
                [106.9297052, 21.4309665],
                [106.929768, 21.4311738],
                [106.9298601, 21.4313861],
                [106.9301021, 21.4318122],
                [106.9301817, 21.4319805],
                [106.9302519, 21.4321523],
                [106.9303125, 21.4323273],
                [106.9304, 21.4326637],
                [106.9304816, 21.4331568],
                [106.9304788, 21.4333097],
                [106.9304437, 21.4334591],
                [106.9304144, 21.4335307],
                [106.9303353, 21.4336621],
                [106.9302302, 21.4337767],
                [106.9300365, 21.4339194],
                [106.9299194, 21.4340343],
                [106.9298275, 21.4341676],
                [106.9297922, 21.4342397],
                [106.9297457, 21.4343843],
                [106.9297278, 21.4345343],
                [106.9297187, 21.4354364],
                [106.9297103, 21.4355392],
                [106.9296919, 21.4356409],
                [106.9296637, 21.4357407],
                [106.9295812, 21.4359265],
                [106.9295285, 21.4360115],
                [106.9294006, 21.4361672],
                [106.9293626, 21.4362307],
                [106.9293469, 21.436302],
                [106.9293481, 21.4363401],
                [106.9293559, 21.4363776],
                [106.9293898, 21.4364468],
                [106.9298314, 21.4369175],
                [106.929883, 21.4370085],
                [106.9298968, 21.4370585],
                [106.9299007, 21.4371444],
                [106.9298807, 21.4372283],
                [106.9296446, 21.4377875],
                [106.929598, 21.4379827],
                [106.9295813, 21.4381536],
                [106.9295671, 21.4382239],
                [106.9295434, 21.438292],
                [106.9293818, 21.4385665],
                [106.9293174, 21.4387212],
                [106.9291833, 21.4393154],
                [106.9280675, 21.4401643],
                [106.9269812, 21.4409282],
                [106.9268482, 21.4409631],
                [106.9267103, 21.4409707],
                [106.9266304, 21.4409624],
                [106.9265522, 21.4409448],
                [106.926477, 21.4409183],
                [106.9261497, 21.4406661],
                [106.9260143, 21.4405272],
                [106.9257619, 21.4402349],
                [106.9256453, 21.4400819],
                [106.92541, 21.4397446],
                [106.9253387, 21.4396602],
                [106.9251811, 21.4395053],
                [106.9250956, 21.4394353],
                [106.9248365, 21.4392716],
                [106.9240442, 21.4387961],
                [106.9239634, 21.4387282],
                [106.9239038, 21.4386434],
                [106.9238833, 21.4385964],
                [106.9238697, 21.4385491],
                [106.9238618, 21.4384516],
                [106.9238392, 21.4383384],
                [106.9238082, 21.4382269],
                [106.9237162, 21.4379589],
                [106.9236641, 21.4377574],
                [106.9236406, 21.4376945],
                [106.9235699, 21.4375789],
                [106.9234699, 21.4374812],
                [106.9233456, 21.4374082],
                [106.9231934, 21.4373581],
                [106.9230333, 21.4372914],
                [106.9228855, 21.4372033],
                [106.9227533, 21.4370959],
                [106.9226395, 21.4369714],
                [106.9225904, 21.4369037],
                [106.922408, 21.4367186],
                [106.9222022, 21.4365562],
                [106.9219872, 21.4364248],
                [106.9217686, 21.4363228],
                [106.921539, 21.4362446],
                [106.9208819, 21.4361222],
                [106.9208096, 21.4360951],
                [106.9207438, 21.4360459],
                [106.9206941, 21.4359749],
                [106.9206476, 21.4358199],
                [106.9205765, 21.4355051],
                [106.920552, 21.4353458],
                [106.9205327, 21.4351582],
                [106.9205238, 21.4349699],
                [106.9205253, 21.4346436],
                [106.9205182, 21.4345059],
                [106.9204822, 21.4342322],
                [106.9204084, 21.433933],
                [106.9202998, 21.433643],
                [106.9198814, 21.4329539],
                [106.9189694, 21.4320951],
                [106.9179448, 21.4318878],
                [106.9178966, 21.4316481],
                [106.9178694, 21.4315646],
                [106.917801, 21.4314185],
                [106.9177195, 21.4312961],
                [106.9169658, 21.4306894],
                [106.9167367, 21.4306081],
                [106.9165112, 21.4305181],
                [106.9162899, 21.4304197],
                [106.9159455, 21.4302452],
                [106.915614, 21.4300502],
                [106.9145988, 21.429534],
                [106.9145096, 21.4295124],
                [106.9144633, 21.4295128],
                [106.9144146, 21.4295215],
                [106.9143265, 21.4295633],
                [106.9140715, 21.4297835],
                [106.9139361, 21.4298851],
                [106.9136506, 21.4300702],
                [106.9133417, 21.4302327],
                [106.9131814, 21.4303036],
                [106.9129322, 21.4303974],
                [106.9128443, 21.4304205],
                [106.9126637, 21.4304455],
                [106.9124784, 21.4304413],
                [106.9122941, 21.4304066],
                [106.9119623, 21.4302848],
                [106.9117958, 21.4302611],
                [106.9117114, 21.4302624],
                [106.9115812, 21.430282],
                [106.9114566, 21.4303224],
                [106.9109657, 21.430607],
                [106.910947, 21.4309341],
                [106.9091632, 21.4339694],
                [106.9090882, 21.4341424],
                [106.9090634, 21.4342268],
                [106.9090345, 21.4343995],
                [106.9089822, 21.4345315],
                [106.9089204, 21.4346598],
                [106.9087821, 21.4348861],
                [106.9087085, 21.4349844],
                [106.9085437, 21.4351685],
                [106.9082326, 21.4352134],
                [106.9081186, 21.435222],
                [106.9078901, 21.4352188],
                [106.9076639, 21.4351885],
                [106.9075358, 21.4351588],
                [106.9074104, 21.4351205],
                [106.9072884, 21.4350736],
                [106.9068325, 21.4352284],
                [106.9063474, 21.43526],
                [106.9062658, 21.4352745],
                [106.9061098, 21.4353269],
                [106.9059688, 21.4354082],
                [106.9058503, 21.4355134],
                [106.9057575, 21.435639],
                [106.905722, 21.4357078],
                [106.9055999, 21.4360093],
                [106.9054873, 21.436314],
                [106.9053057, 21.4368789],
                [106.9052339, 21.4371378],
                [106.9050902, 21.4377257],
                [106.9050243, 21.4378473],
                [106.9049797, 21.4379013],
                [106.9048819, 21.4379843],
                [106.9047794, 21.4380395],
                [106.9046713, 21.4380793],
                [106.9046216, 21.438105],
                [106.9045347, 21.4381732],
                [106.9044691, 21.4382598],
                [106.9044066, 21.4384168],
                [106.904377, 21.4384715],
                [106.9043409, 21.4385227],
                [106.9042511, 21.438612],
                [106.9041406, 21.4386804],
                [106.9040792, 21.4387055],
                [106.904015, 21.4387237],
                [106.903882, 21.4387387],
                [106.903819, 21.4387549],
                [106.9037594, 21.4387799],
                [106.9037046, 21.4388131],
                [106.903656, 21.4388538],
                [106.9036147, 21.4389009],
                [106.9035816, 21.4389534],
                [106.9035578, 21.4390094],
                [106.9035434, 21.4390681],
                [106.9035388, 21.4391281],
                [106.9035492, 21.4392539],
                [106.903533, 21.4393848],
                [106.903488, 21.439498],
                [106.9033996, 21.4396407],
                [106.903258, 21.4398283],
                [106.903175, 21.4399195],
                [106.903086, 21.4400058],
                [106.9028473, 21.4402072],
                [106.9027821, 21.4402739],
                [106.9027219, 21.4403446],
                [106.902563, 21.4405693],
                [106.902436, 21.4407034],
                [106.9022888, 21.4408184],
                [106.9020988, 21.4409236],
                [106.9018922, 21.4409968],
                [106.9016705, 21.4410475],
                [106.90141, 21.4411175],
                [106.9011938, 21.4412095],
                [106.9010888, 21.4412639],
                [106.9008887, 21.4413876],
                [106.9004942, 21.4417182],
                [106.9004333, 21.4417617],
                [106.9003684, 21.4417999],
                [106.9002289, 21.4418595],
                [106.9001466, 21.4418823],
                [106.8999767, 21.4419044],
                [106.899594, 21.4418786],
                [106.899451, 21.4418894],
                [106.8993822, 21.4419058],
                [106.8992525, 21.4419593],
                [106.8991625, 21.4420377],
                [106.899054, 21.4420921],
                [106.8989419, 21.4421181],
                [106.8988341, 21.4421191],
                [106.8987509, 21.4421317],
                [106.8986694, 21.4421517],
                [106.8985145, 21.4422131],
                [106.8983766, 21.4422999],
                [106.8982602, 21.442408],
                [106.8982107, 21.4424693],
                [106.8981314, 21.4426034],
                [106.897498, 21.4433032],
                [106.8973719, 21.4434272],
                [106.8972384, 21.4435444],
                [106.897098, 21.4436544],
                [106.8969512, 21.4437568],
                [106.8968058, 21.443847],
                [106.8965005, 21.4440049],
                [106.8961787, 21.4441313],
                [106.8962431, 21.4466478],
                [106.8963128, 21.4475116],
                [106.8954277, 21.4474617],
                [106.8944675, 21.4473194],
                [106.8944085, 21.4476764],
                [106.8932176, 21.4496336],
                [106.8910838, 21.451726],
                [106.8909051, 21.4526698],
                [106.8909188, 21.4537367],
                [106.8916982, 21.4567588],
                [106.8920989, 21.4595922],
                [106.8923946, 21.4599294],
                [106.8928354, 21.4602196],
                [106.8935665, 21.4604382],
                [106.8955016, 21.4608364],
                [106.8969653, 21.4613874],
                [106.897529, 21.4617897],
                [106.8989645, 21.4648496],
                [106.9015092, 21.4691876],
                [106.9017388, 21.4700471],
                [106.9017677, 21.4703872],
                [106.9016522, 21.4708423],
                [106.9014636, 21.4712755],
                [106.9011302, 21.4717785],
                [106.900601, 21.4721701],
                [106.8987954, 21.4733479],
                [106.8956623, 21.4751305],
                [106.8927119, 21.4760262],
                [106.8921591, 21.4764636],
                [106.8917049, 21.4770133],
                [106.8915449, 21.4777865],
                [106.8915557, 21.478626],
                [106.8916737, 21.4802355],
                [106.8916062, 21.4806446],
                [106.8903045, 21.4813473],
                [106.8892968, 21.4816143],
                [106.8890841, 21.4817927],
                [106.8889217, 21.482079],
                [106.888803, 21.4823321],
                [106.8887461, 21.482642],
                [106.8886544, 21.4838212],
                [106.8884109, 21.4846758],
                [106.8884411, 21.4849584],
                [106.8890075, 21.4858388],
                [106.8897161, 21.48659],
                [106.8899045, 21.486878],
                [106.8902136, 21.4891506],
                [106.8901367, 21.4891107],
                [106.8899862, 21.489133],
                [106.8898101, 21.4892436],
                [106.8896165, 21.489428],
                [106.8894527, 21.4896537],
                [106.8893379, 21.4898884],
                [106.889223, 21.4901106],
                [106.8890705, 21.4902811],
                [106.8888842, 21.4903739],
                [106.888721, 21.4904037],
                [106.888569, 21.4903723],
                [106.8883983, 21.490288],
                [106.8882255, 21.4901951],
                [106.88805, 21.4901401],
                [106.8878243, 21.4901625],
                [106.8874284, 21.4902905],
                [106.886869, 21.4905776],
                [106.886256, 21.4910104],
                [106.885711, 21.4913528],
                [106.8852058, 21.4915648],
                [106.8846896, 21.4917467],
                [106.884397, 21.491872],
                [106.884384, 21.4918873],
                [106.8828501, 21.492421],
                [106.8800694, 21.4939652],
                [106.8762119, 21.4952743],
                [106.8739503, 21.4960561],
                [106.8726473, 21.4963617],
                [106.8704092, 21.4965614],
                [106.8689213, 21.4970144],
                [106.8683324, 21.4971956],
                [106.8670105, 21.4972541],
                [106.8656386, 21.4970366],
                [106.864325, 21.4965276],
                [106.8628586, 21.496224],
                [106.8618607, 21.4960605],
                [106.8596827, 21.4960847],
                [106.8579523, 21.4958276],
                [106.8565482, 21.4955232],
                [106.8553002, 21.4952752],
                [106.8543356, 21.4952859],
                [106.8538379, 21.4952914],
                [106.8532483, 21.4954144],
                [106.8526611, 21.4957409],
                [106.8522299, 21.4960949],
                [106.8519871, 21.4965921],
                [106.8519378, 21.4976111],
                [106.8519352, 21.4998516],
                [106.8488835, 21.4972375],
                [106.8473475, 21.4953143],
                [106.8467673, 21.4946736],
                [106.8460444, 21.4943228],
                [106.8454509, 21.4947331],
                [106.8451513, 21.4956781],
                [106.8448093, 21.4970274],
                [106.8440635, 21.4998791],
                [106.8433892, 21.501718],
                [106.8427457, 21.5035999],
                [106.8421225, 21.5044502],
                [106.841464, 21.5051448],
                [106.8403715, 21.5058131],
                [106.8393747, 21.5061365],
                [106.8390787, 21.5064629],
                [106.8389905, 21.5074217],
                [106.8389743, 21.5087811],
                [106.8391219, 21.5099042],
                [106.8392648, 21.5106526],
                [106.839472, 21.5112128],
                [106.8395445, 21.5116806],
                [106.8397844, 21.5121779],
                [106.840125, 21.5127365],
                [106.8403963, 21.5130772],
                [106.8409336, 21.5133524],
                [106.841835, 21.513405],
                [106.8433024, 21.5133576],
                [106.8454052, 21.513428],
                [106.8462425, 21.5136688],
                [106.8468468, 21.5139745],
                [106.8474199, 21.5144369],
                [106.8481309, 21.5152569],
                [106.8491062, 21.515871],
                [106.8498453, 21.516269],
                [106.8502503, 21.5166395],
                [106.8506578, 21.5171973],
                [106.8507316, 21.5177589],
                [106.8507062, 21.5183841],
                [106.8505004, 21.5205735],
                [106.8503858, 21.5220744],
                [106.8504314, 21.5230269],
                [106.8508411, 21.5237723],
                [106.8512821, 21.5243298],
                [106.8519905, 21.5249468],
                [106.8525957, 21.525315],
                [106.8533335, 21.525588],
                [106.8537666, 21.5255519],
                [106.8542646, 21.5253589],
                [106.8548585, 21.5248212],
                [106.8561747, 21.5233693],
                [106.85733, 21.5223878],
                [106.8579929, 21.5220368],
                [106.8585595, 21.5219992],
                [106.8594298, 21.5222083],
                [106.8603417, 21.5230729],
                [106.8622342, 21.5249576],
                [106.8635202, 21.5263805],
                [106.8644661, 21.5272916],
                [106.8650119, 21.5282229],
                [106.8650557, 21.5290347],
                [106.865064, 21.5296908],
                [106.8651567, 21.5317206],
                [106.8650992, 21.5324399],
                [106.8651403, 21.5330487],
                [106.8650218, 21.5337612],
                [106.8645999, 21.5346945],
                [106.8634793, 21.5358008],
                [106.8624174, 21.5362814],
                [106.8613202, 21.5366061],
                [106.8602942, 21.5368411],
                [106.8595571, 21.5370633],
                [106.8590642, 21.5376626],
                [106.8585377, 21.5382622],
                [106.8582096, 21.5387034],
                [106.8578793, 21.5389883],
                [106.8575461, 21.5390233],
                [106.8571438, 21.5388715],
                [106.8568722, 21.5385307],
                [106.8566714, 21.5384706],
                [106.8564051, 21.5385359],
                [106.8562416, 21.5387878],
                [106.8561438, 21.5389763],
                [106.8556805, 21.539294],
                [106.8555149, 21.5393896],
                [106.8551841, 21.5396121],
                [106.8546182, 21.5397433],
                [106.8535487, 21.5396303],
                [106.8529148, 21.5396373],
                [106.8524487, 21.5397362],
                [106.8518236, 21.5404463],
                [106.8506352, 21.5414908],
                [106.8503428, 21.542119],
                [106.8502852, 21.5428384],
                [106.849905, 21.5444364],
                [106.8496844, 21.54547],
                [106.8496594, 21.5461266],
                [106.8498307, 21.5464685],
                [106.8500693, 21.5468721],
                [106.8500429, 21.5474193],
                [106.8493226, 21.548521],
                [106.8485009, 21.5495301],
                [106.8480773, 21.5503473],
                [106.8480506, 21.5508788],
                [106.8481253, 21.551503],
                [106.8483025, 21.5523135],
                [106.8482775, 21.5529701],
                [106.8479174, 21.5535365],
                [106.8467998, 21.5548928],
                [106.8458813, 21.5561686],
                [106.8451636, 21.557489],
                [106.8443796, 21.5588415],
                [106.8438873, 21.5595031],
                [106.8434558, 21.5596954],
                [106.8427545, 21.5596719],
                [106.8410515, 21.5596282],
                [106.8397837, 21.5596736],
                [106.8382514, 21.5599404],
                [106.8368209, 21.5603312],
                [106.8337311, 21.5614903],
                [106.8320688, 21.5620242],
                [106.8311643, 21.5619393],
                [106.830265, 21.561855],
                [106.8297278, 21.5616111],
                [106.8293585, 21.561459],
                [106.8292898, 21.5613036],
                [106.8293205, 21.5610845],
                [106.8295515, 21.5608634],
                [106.8296799, 21.5604559],
                [106.8294433, 21.56024],
                [106.8289733, 21.5600264],
                [106.8284062, 21.560064],
                [106.8275081, 21.5603236],
                [106.8255859, 21.5614691],
                [106.8242588, 21.5621396],
                [106.8232274, 21.562432],
                [106.8218269, 21.5625722],
                [106.8205246, 21.5625552],
                [106.8194213, 21.5624422],
                [106.8174776, 21.5618701],
                [106.8159366, 21.5614497],
                [106.8133525, 21.5603847],
                [106.8121771, 21.5598353],
                [106.8113731, 21.5596163],
                [106.8094357, 21.5595437],
                [106.80823, 21.5592442],
                [106.8074945, 21.5591584],
                [106.8066933, 21.5591671],
                [106.8061277, 21.5593294],
                [106.8052974, 21.5596823],
                [106.8043355, 21.5601928],
                [106.8035727, 21.5606075],
                [106.802839, 21.5606779],
                [106.8021383, 21.5607167],
                [106.8015067, 21.5609424],
                [106.8009423, 21.5611985],
                [106.7998779, 21.5615226],
                [106.7992805, 21.5618103],
                [106.7991891, 21.5625146],
                [106.7990985, 21.5632969],
                [106.7988046, 21.5638315],
                [106.7982441, 21.5644001],
                [106.7973508, 21.5650663],
                [106.7953567, 21.5658066],
                [106.7942905, 21.5660057],
                [106.7935906, 21.566107],
                [106.7930922, 21.5662999],
                [106.7929284, 21.5665517],
                [106.7928339, 21.5670215],
                [106.792674, 21.5675859],
                [106.792215, 21.5682785],
                [106.7916221, 21.5689412],
                [106.7904957, 21.5696566],
                [106.7889023, 21.570424],
                [106.7882765, 21.5711183],
                [106.7876191, 21.5719693],
                [106.7872947, 21.5727542],
                [106.7871733, 21.5737244],
                [106.7868604, 21.5754469],
                [106.7867414, 21.5766359],
                [106.7866787, 21.5769648],
                [106.7842303, 21.5760846],
                [106.7798678, 21.5743183],
                [106.7765473, 21.5731036],
                [106.7744343, 21.5723447],
                [106.7734274, 21.5719179],
                [106.7719564, 21.5717616],
                [106.7710907, 21.5718055],
                [106.7703074, 21.5719045],
                [106.7686883, 21.572109],
                [106.766693, 21.5727864],
                [106.7659616, 21.5730442],
                [106.7650264, 21.573023],
                [106.7640551, 21.5727832],
                [106.7621703, 21.5715528],
                [106.7598853, 21.570358],
                [106.7579941, 21.5685807],
                [106.7566079, 21.5671576],
                [106.756092, 21.5659127],
                [106.7558748, 21.5645083],
                [106.755689, 21.5629475],
                [106.755679, 21.5621193],
                [106.7558065, 21.5615762],
                [106.7562428, 21.5618008],
                [106.7569158, 21.5622313],
                [106.7572177, 21.5623531],
                [106.7575838, 21.5622555],
                [106.7578478, 21.5620026],
                [106.7579086, 21.5615019],
                [106.7579326, 21.5607202],
                [106.757993, 21.5601882],
                [106.7583556, 21.5598093],
                [106.7587521, 21.5594613],
                [106.758982, 21.5591463],
                [106.7590781, 21.5588015],
                [106.7590413, 21.5585205],
                [106.7583983, 21.5578085],
                [106.7579927, 21.5574064],
                [106.7580216, 21.557031],
                [106.7582534, 21.5568722],
                [106.7586867, 21.5568052],
                [106.7593198, 21.5567046],
                [106.7597512, 21.5564814],
                [106.7599473, 21.5561354],
                [106.7597419, 21.5557156],
                [106.7595011, 21.5551244],
                [106.7595319, 21.5549051],
                [106.7596965, 21.5547159],
                [106.7600296, 21.5546498],
                [106.7605637, 21.5546442],
                [106.760998, 21.5546709],
                [106.7614961, 21.5544468],
                [106.7616263, 21.5541641],
                [106.7615902, 21.5539456],
                [106.7614203, 21.5536975],
                [106.7610155, 21.5533579],
                [106.7604105, 21.5530204],
                [106.7600065, 21.5527434],
                [106.7596394, 21.5527473],
                [106.7594747, 21.5529365],
                [106.7593126, 21.5533446],
                [106.7591488, 21.5535964],
                [106.7589493, 21.553661],
                [106.7587141, 21.5535385],
                [106.7584414, 21.5530725],
                [106.7583694, 21.5526356],
                [106.7584316, 21.5522599],
                [106.7587294, 21.552038],
                [106.7591607, 21.5518146],
                [106.7597567, 21.551402],
                [106.7600525, 21.5510237],
                [106.7601827, 21.5507411],
                [106.760147, 21.550554],
                [106.7600105, 21.5503053],
                [106.7597089, 21.5502147],
                [106.759107, 21.5501274],
                [106.7587042, 21.5499441],
                [106.7584675, 21.5496964],
                [106.7583304, 21.5494166],
                [106.7584282, 21.5492124],
                [106.759026, 21.548956],
                [106.759784, 21.5481354],
                [106.7599471, 21.547821],
                [106.759943, 21.5474772],
                [106.7598395, 21.547197],
                [106.7595375, 21.5470752],
                [106.7590372, 21.5471117],
                [106.7583699, 21.54715],
                [106.7576705, 21.5472825],
                [106.7572698, 21.5472867],
                [106.7570677, 21.5471325],
                [106.7571318, 21.5469131],
                [106.7574638, 21.5467532],
                [106.7581577, 21.546152],
                [106.7585845, 21.5455536],
                [106.7586804, 21.5452088],
                [106.7587086, 21.5447709],
                [106.7585708, 21.5444286],
                [106.7583016, 21.5442438],
                [106.757899, 21.5440918],
                [106.7575619, 21.5438141],
                [106.7573249, 21.5435352],
                [106.7570535, 21.5431787],
                [106.7564777, 21.5424971],
                [106.7561999, 21.5415936],
                [106.7559275, 21.5411588],
                [106.7555569, 21.5408813],
                [106.7550554, 21.5408241],
                [106.7545875, 21.5407666],
                [106.7534514, 21.5406848],
                [106.7524799, 21.5404136],
                [106.7518818, 21.5403401],
                [106.7496358, 21.5416457],
                [106.7488969, 21.5419168],
                [106.7481641, 21.5420078],
                [106.7461823, 21.5418205],
                [106.7444904, 21.5416926],
                [106.7436907, 21.5417841],
                [106.7428473, 21.5419178],
                [106.7417175, 21.5423042],
                [106.7406777, 21.5427729],
                [106.739439, 21.5433476],
                [106.7375539, 21.5438042],
                [106.7358005, 21.5441139],
                [106.7346019, 21.5443344],
                [106.7340718, 21.5446312],
                [106.7336107, 21.5451354],
                [106.7335281, 21.5456773],
                [106.7334293, 21.5467187],
                [106.7335617, 21.548507],
                [106.7335669, 21.5489439],
                [106.7333923, 21.5492162],
                [106.7326188, 21.5496404],
                [106.7301678, 21.5511641],
                [106.7293963, 21.5517547],
                [106.7283846, 21.5527225],
                [106.7278356, 21.5533107],
                [106.7272888, 21.5541073],
                [106.7268753, 21.5548814],
                [106.7263907, 21.5552817],
                [106.7254607, 21.5556244],
                [106.7247053, 21.5557154],
                [106.7237703, 21.5556418],
                [106.7227474, 21.5556733],
                [106.7222386, 21.5559074],
                [106.7216456, 21.5565586],
                [106.7210288, 21.5570643],
                [106.7200537, 21.5573866],
                [106.7192102, 21.5575202],
                [106.7185913, 21.5578594],
                [106.7179309, 21.5584489],
                [106.7174302, 21.5593697],
                [106.7170347, 21.5597899],
                [106.7166593, 21.5600227],
                [106.7163051, 21.5601721],
                [106.7156821, 21.5601576],
                [106.7150796, 21.5599974],
                [106.7144765, 21.5597955],
                [106.7138979, 21.5597598],
                [106.7134091, 21.5598065],
                [106.7130553, 21.5599974],
                [106.7125705, 21.5603769],
                [106.7119535, 21.5608826],
                [106.7113304, 21.5608683],
                [106.7106618, 21.5607502],
                [106.710239, 21.5607338],
                [106.7097784, 21.5613004],
                [106.7085042, 21.562666],
                [106.7077364, 21.5635894],
                [106.7072315, 21.5641564],
                [106.7063851, 21.5659755],
                [106.7060642, 21.5670609],
                [106.7056713, 21.56771],
                [106.7051877, 21.5681936],
                [106.7042606, 21.5688273],
                [106.7024476, 21.5697822],
                [106.6999306, 21.5714103],
                [106.699319, 21.5723737],
                [106.698928, 21.5731892],
                [106.698848, 21.57396],
                [106.6989225, 21.5746251],
                [106.699242, 21.5753086],
                [106.6996318, 21.5763034],
                [106.6999079, 21.5770912],
                [106.7000061, 21.577881],
                [106.70008, 21.5784838],
                [106.700175, 21.579003],
                [106.7005835, 21.5797064],
                [106.7020959, 21.5815636],
                [106.7025946, 21.5823493],
                [106.702665, 21.5826608],
                [106.7024747, 21.5835158],
                [106.7017734, 21.5863322],
                [106.7017445, 21.5876643],
                [106.701937, 21.5889108],
                [106.702104, 21.5898663],
                [106.7025141, 21.5906945],
                [106.702811, 21.5913365],
                [106.7028866, 21.5921057],
                [106.7026973, 21.593044],
                [106.7025935, 21.5931433],
                [106.7020592, 21.593654],
                [106.7008613, 21.5939576],
                [106.6998411, 21.5942386],
                [106.6993543, 21.5944724],
                [106.6982761, 21.595503],
                [106.6966544, 21.5957277],
                [106.6950344, 21.596098],
                [106.6930617, 21.5967423],
                [106.6912892, 21.5974054],
                [106.6903494, 21.5988508],
                [106.6898884, 21.5993965],
                [106.6893381, 21.5999014],
                [106.68843, 21.6002645],
                [106.6876546, 21.6005636],
                [106.6865877, 21.6006577],
                [106.6859666, 21.6008304],
                [106.6851047, 21.6013386],
                [106.6840431, 21.6018904],
                [106.6832932, 21.6024598],
                [106.6828491, 21.6025475],
                [106.6822244, 21.6024083],
                [106.6816446, 21.6022892],
                [106.6810455, 21.602441],
                [106.6801145, 21.6027417],
                [106.6783358, 21.6028845],
                [106.6775159, 21.6031633],
                [106.6750175, 21.6045619],
                [106.6724196, 21.6069811],
                [106.670792, 21.6086413],
                [106.6702393, 21.608959],
                [106.6693609, 21.6080314],
                [106.6682348, 21.6068567],
                [106.6658812, 21.6053611],
                [106.6645831, 21.6047082],
                [106.6636676, 21.604426],
                [106.6630005, 21.6044744],
                [106.6624692, 21.6047086],
                [106.6620057, 21.6050462],
                [106.6614786, 21.6056549],
                [106.6607725, 21.6061822],
                [106.6601731, 21.6062922],
                [106.6596372, 21.6061311],
                [106.6585367, 21.6052472],
                [106.6574796, 21.6042589],
                [106.6567146, 21.6035174],
                [106.6559113, 21.6033173],
                [106.654732, 21.6033291],
                [106.6531724, 21.6031573],
                [106.6522401, 21.6033538],
                [106.6511345, 21.6039682],
                [106.6497445, 21.6050225],
                [106.6477539, 21.6060827],
                [106.6465779, 21.6063857],
                [106.6455775, 21.6064581],
                [106.6449978, 21.6063597],
                [106.6446609, 21.6060926],
                [106.6442507, 21.6052434],
                [106.6436158, 21.6041883],
                [106.6426744, 21.6035734],
                [106.6417789, 21.6031036],
                [106.6404868, 21.6029708],
                [106.6395716, 21.6027092],
                [106.6380237, 21.6016007],
                [106.6366805, 21.6008856],
                [106.6357665, 21.6007282],
                [106.6349671, 21.6008817],
                [106.6341477, 21.601114],
                [106.63337, 21.6013344],
                [106.6325503, 21.6016546],
                [106.62955, 21.6019753],
                [106.6273288, 21.60233],
                [106.6259508, 21.6024684],
                [106.6255083, 21.6027016],
                [106.6252684, 21.6031409],
                [106.6251178, 21.6036001],
                [106.6250835, 21.6045162],
                [106.6250459, 21.6051408],
                [106.6246961, 21.6056853],
                [106.6242087, 21.6058773],
                [106.6222351, 21.6065001],
                [106.6204831, 21.6070373],
                [106.6181303, 21.6075597],
                [106.6161744, 21.6077869],
                [106.6154406, 21.6078355],
                [106.6150835, 21.607735],
                [106.6143625, 21.6069304],
                [106.6137752, 21.6061454],
                [106.613235, 21.6055887],
                [106.612453, 21.605305],
                [106.6116282, 21.6051881],
                [106.6111804, 21.604922],
                [106.6096111, 21.6038759],
                [106.6090727, 21.6034856],
                [106.6074689, 21.6033347],
                [106.6054212, 21.603292],
                [106.6036638, 21.6033298],
                [106.6029948, 21.6032114],
                [106.6025233, 21.6028205],
                [106.6019844, 21.6023886],
                [106.6006912, 21.6021515],
                [106.5997792, 21.602181],
                [106.5991969, 21.6018328],
                [106.5988333, 21.6011496],
                [106.5984237, 21.6003212],
                [106.5981302, 21.5999494],
                [106.5944292, 21.5992774],
                [106.5934055, 21.5992664],
                [106.5922525, 21.5996521],
                [106.5910566, 21.6001629],
                [106.5907396, 21.5996664],
                [106.5905553, 21.5990855],
                [106.5906826, 21.5985225],
                [106.5900817, 21.5985075],
                [106.588571, 21.59873],
                [106.586683, 21.5990393],
                [106.5851957, 21.5993657],
                [106.5840204, 21.5997306],
                [106.5824258, 21.6004326],
                [106.5802895, 21.6003916],
                [106.5769574, 21.6008604],
                [106.5730758, 21.6019589],
                [106.5711463, 21.6025183],
                [106.5711405, 21.602499],
                [106.5707076, 21.6010139],
                [106.5701134, 21.5995416],
                [106.5693247, 21.5986122],
                [106.567152, 21.5969947],
                [106.564684, 21.5950609],
                [106.5638419, 21.5945533],
                [106.5637658, 21.5945076],
                [106.5618222, 21.5937554],
                [106.560708, 21.5935786],
                [106.5595482, 21.5938242],
                [106.5583246, 21.5938148],
                [106.5570975, 21.5934932],
                [106.5567616, 21.5932881],
                [106.556468, 21.5928744],
                [106.5560591, 21.5920872],
                [106.5554274, 21.5912604],
                [106.5547542, 21.5907254],
                [106.5533675, 21.5900306],
                [106.5520697, 21.5893349],
                [106.5520687, 21.5892308],
                [106.5521293, 21.5886558],
                [106.5522101, 21.5878849],
                [106.552528, 21.5864037],
                [106.5528026, 21.5850271],
                [106.5527251, 21.5840287],
                [106.5524557, 21.5832706],
                [106.55187, 21.5825891],
                [106.5509714, 21.5817648],
                [106.5500012, 21.580483],
                [106.5493682, 21.5795313],
                [106.5488057, 21.5789327],
                [106.5478887, 21.5784833],
                [106.5449707, 21.5773757],
                [106.5444116, 21.5770894],
                [106.5441414, 21.5767797],
                [106.5439096, 21.5759073],
                [106.5437257, 21.575347],
                [106.5435628, 21.5746615],
                [106.5433137, 21.5742475],
                [106.5428652, 21.5738976],
                [106.5425713, 21.5737113],
                [106.5422829, 21.5735283],
                [106.5418997, 21.5730531],
                [106.5417605, 21.5725132],
                [106.5417554, 21.5720343],
                [106.5421051, 21.5714166],
                [106.5424783, 21.5709551],
                [106.5424751, 21.5706637],
                [106.5423377, 21.5702903],
                [106.5416395, 21.569464],
                [106.5410755, 21.5687197],
                [106.5405349, 21.5680794],
                [106.5401962, 21.5676036],
                [106.539657, 21.567109],
                [106.5391647, 21.5668221],
                [106.5384931, 21.5664119],
                [106.5380223, 21.5660624],
                [106.5376611, 21.565566],
                [106.5370752, 21.5648428],
                [106.53667, 21.5643885],
                [106.5362865, 21.5638716],
                [106.5358159, 21.5635429],
                [106.5353471, 21.5633807],
                [106.5349245, 21.5633846],
                [106.534385, 21.5635829],
                [106.5331651, 21.5639273],
                [106.532587, 21.5639326],
                [106.5320966, 21.563833],
                [106.531403, 21.5634439],
                [106.5307961, 21.5628457],
                [106.5301197, 21.5619775],
                [106.5298685, 21.5613553],
                [106.5295056, 21.5606925],
                [106.528986, 21.5599269],
                [106.5284683, 21.5593279],
                [106.5283557, 21.5592041],
                [106.5278157, 21.5586052],
                [106.5275439, 21.5578528],
                [106.527472, 21.5573538],
                [106.526787, 21.5535294],
                [106.5270862, 21.5523902],
                [106.5275028, 21.5518243],
                [106.5276541, 21.5514065],
                [106.5276255, 21.5508031],
                [106.527351, 21.5500769],
                [106.5269897, 21.5495598],
                [106.5264958, 21.5491062],
                [106.5261565, 21.5485681],
                [106.5260392, 21.5479862],
                [106.5265028, 21.5455139],
                [106.5263381, 21.5446619],
                [106.5260454, 21.5443106],
                [106.5252984, 21.5430684],
                [106.52507, 21.5424875],
                [106.5240625, 21.5395928],
                [106.5234005, 21.5379543],
                [106.5221121, 21.5354904],
                [106.5200177, 21.5334336],
                [106.5188955, 21.5324576],
                [106.5181798, 21.5320685],
                [106.517422, 21.5319089],
                [106.5152198, 21.5318457],
                [106.5146618, 21.5316425],
                [106.5128451, 21.5295035],
                [106.5125058, 21.5289445],
                [106.5118314, 21.5282428],
                [106.5105545, 21.5273384],
                [106.5086728, 21.5257239],
                [106.5078828, 21.5246069],
                [106.5075173, 21.5236526],
                [106.5073731, 21.5226129],
                [106.5074963, 21.5216125],
                [106.508073, 21.5199914],
                [106.5089468, 21.5184637],
                [106.5101506, 21.5166208],
                [106.5119735, 21.5144182],
                [106.5131843, 21.5132415],
                [106.5139072, 21.512194],
                [106.5144051, 21.5109565],
                [106.5150498, 21.5088075],
                [106.5166843, 21.5056092],
                [106.5178861, 21.50358],
                [106.5191125, 21.5017794],
                [106.5202141, 21.5008123],
                [106.5215239, 21.4998796],
                [106.5203453, 21.4983111],
                [106.5196146, 21.4978334],
                [106.5190904, 21.4972569],
                [106.5182963, 21.4956659],
                [106.5176627, 21.4945577],
                [106.5137893, 21.4909118],
                [106.5124887, 21.4896114],
                [106.5111208, 21.4876843],
                [106.5110434, 21.4875403],
                [106.5109394, 21.4872399],
                [106.5108315, 21.4870274],
                [106.5106919, 21.4868314],
                [106.5105243, 21.4866566],
                [106.5103322, 21.4865063],
                [106.5095812, 21.4862268],
                [106.5092432, 21.486651],
                [106.5091806, 21.4867556],
                [106.5091239, 21.486863],
                [106.5090287, 21.4870853],
                [106.5089868, 21.4872116],
                [106.5089267, 21.4874697],
                [106.5088567, 21.4875201],
                [106.5087823, 21.4875645],
                [106.5086222, 21.4876345],
                [106.5084821, 21.4876717],
                [106.5083123, 21.4876964],
                [106.5081982, 21.4877049],
                [106.5079692, 21.4877018],
                [106.5077658, 21.4876762],
                [106.5075669, 21.4876294],
                [106.5061972, 21.4869683],
                [106.5059461, 21.4868154],
                [106.5058276, 21.4867297],
                [106.5056062, 21.4865412],
                [106.5053924, 21.4863134],
                [106.5052086, 21.486064],
                [106.5050608, 21.485832],
                [106.5049021, 21.4856469],
                [106.5047256, 21.4854924],
                [106.5045382, 21.4853683],
                [106.5043348, 21.4852684],
                [106.5038885, 21.4851293],
                [106.5036696, 21.4850487],
                [106.5032385, 21.4848645],
                [106.5030286, 21.4847617],
                [106.5029267, 21.4846982],
                [106.5028825, 21.4846586],
                [106.5028113, 21.4845681],
                [106.5027303, 21.4844876],
                [106.5026826, 21.4844547],
                [106.5025874, 21.4844098],
                [106.5024841, 21.4843848],
                [106.5020388, 21.4843699],
                [106.5017008, 21.4842822],
                [106.5015346, 21.4842301],
                [106.5011351, 21.4840788],
                [106.5007514, 21.4838957],
                [106.499659, 21.4834135],
                [106.4995105, 21.4833784],
                [106.4993575, 21.4833727],
                [106.4992064, 21.4833965],
                [106.4991308, 21.4834203],
                [106.4989899, 21.48349],
                [106.4989264, 21.483535],
                [106.4988187, 21.4836326],
                [106.4987022, 21.483706],
                [106.49859, 21.4837451],
                [106.4984715, 21.4837609],
                [106.498214, 21.4836161],
                [106.4979525, 21.4835058],
                [106.497817, 21.4834614],
                [106.4975409, 21.4834346],
                [106.4972634, 21.4834355],
                [106.4969859, 21.4834644],
                [106.496712, 21.4835213],
                [106.4963445, 21.4832542],
                [106.4953601, 21.4832293],
                [106.494711, 21.4829248],
                [106.494247, 21.4829347],
                [106.4944339, 21.4826926],
                [106.4945, 21.4825772],
                [106.4945419, 21.4824409],
                [106.4945508, 21.4823648],
                [106.4945501, 21.4822883],
                [106.4943194, 21.4817891],
                [106.4941818, 21.4814601],
                [106.4940539, 21.4811277],
                [106.4937562, 21.4807703],
                [106.4936405, 21.4805849],
                [106.4935952, 21.4804863],
                [106.4935394, 21.4803143],
                [106.4935094, 21.4801369],
                [106.4928281, 21.4783797],
                [106.4929201, 21.4778777],
                [106.4930998, 21.4771503],
                [106.4931214, 21.4769141],
                [106.4931187, 21.4767956],
                [106.4930899, 21.4765768],
                [106.4930362, 21.4763792],
                [106.4929271, 21.476116],
                [106.4929032, 21.4760285],
                [106.4928823, 21.4758726],
                [106.4928818, 21.4757939],
                [106.4931232, 21.4752048],
                [106.4931294, 21.4750911],
                [106.4931213, 21.4748636],
                [106.493082, 21.4746201],
                [106.4930047, 21.4743651],
                [106.4928925, 21.4741216],
                [106.491943, 21.4735475],
                [106.4916942, 21.4732999],
                [106.4915621, 21.4731831],
                [106.4912403, 21.4729335],
                [106.4908272, 21.4728087],
                [106.490467, 21.472512],
                [106.4902961, 21.4723544],
                [106.4899796, 21.4720279],
                [106.4896899, 21.4716804],
                [106.4887243, 21.4709865],
                [106.488873, 21.4707908],
                [106.4889982, 21.4705814],
                [106.4891019, 21.4703521],
                [106.4891447, 21.4702294],
                [106.4892071, 21.4699781],
                [106.4892393, 21.4692642],
                [106.4890194, 21.4688649],
                [106.4888638, 21.4684605],
                [106.4887476, 21.4681848],
                [106.4884937, 21.4676418],
                [106.4881416, 21.467284],
                [106.487764, 21.4669432],
                [106.4873614, 21.4666211],
                [106.4871526, 21.4664686],
                [106.4874687, 21.4660312],
                [106.4875709, 21.4658601],
                [106.4876641, 21.4656846],
                [106.4878101, 21.4653546],
                [106.4879148, 21.4650473],
                [106.4880047, 21.464686],
                [106.4880753, 21.464272],
                [106.4881134, 21.4638508],
                [106.4881182, 21.4634282],
                [106.4882692, 21.4630313],
                [106.4883327, 21.4628292],
                [106.4884454, 21.4623698],
                [106.488381, 21.4622351],
                [106.4882427, 21.4620155],
                [106.4880961, 21.4618007],
                [106.4879411, 21.461591],
                [106.487774, 21.4613815],
                [106.4875986, 21.4611779],
                [106.4874152, 21.4609805],
                [106.4872242, 21.4607894],
                [106.4865177, 21.4601431],
                [106.4861883, 21.4598744],
                [106.4858323, 21.4596243],
                [106.4854574, 21.4593993],
                [106.4853513, 21.4591365],
                [106.4852882, 21.459009],
                [106.4851426, 21.4587632],
                [106.4850604, 21.4586454],
                [106.4848646, 21.4584065],
                [106.484757, 21.4582943],
                [106.484524, 21.4580863],
                [106.4845506, 21.4578493],
                [106.4845723, 21.4577318],
                [106.4846835, 21.4572706],
                [106.4847279, 21.4570378],
                [106.4847921, 21.4565859],
                [106.4848137, 21.4563588],
                [106.4845616, 21.4561192],
                [106.4843651, 21.455488],
                [106.4842558, 21.4551756],
                [106.4840536, 21.4546512],
                [106.4839446, 21.4543917],
                [106.4835316, 21.4536528],
                [106.4835251, 21.4533066],
                [106.4835287, 21.4529603],
                [106.4835423, 21.4526143],
                [106.4835944, 21.4519711],
                [106.4836335, 21.4516507],
                [106.4834849, 21.4512548],
                [106.4833975, 21.4510615],
                [106.483232, 21.4507462],
                [106.4830434, 21.4504424],
                [106.4826599, 21.4484852],
                [106.482438, 21.4484152],
                [106.4822093, 21.4483679],
                [106.481982, 21.4483442],
                [106.4817533, 21.4483429],
                [106.4816307, 21.448313],
                [106.4815725, 21.4482895],
                [106.4814627, 21.4482245],
                [106.4814126, 21.4481831],
                [106.4813318, 21.4480411],
                [106.4812835, 21.4479744],
                [106.4811645, 21.4478438],
                [106.4810932, 21.4477817],
                [106.4809359, 21.4476743],
                [106.4807622, 21.4475915],
                [106.4800474, 21.447589],
                [106.4798606, 21.4475459],
                [106.4796717, 21.4475116],
                [106.4794812, 21.4474862],
                [106.4792895, 21.4474698],
                [106.4790972, 21.4474625],
                [106.4789048, 21.4474642],
                [106.4786341, 21.4474857],
                [106.4783688, 21.4475399],
                [106.4781124, 21.4476263],
                [106.4778694, 21.4477438],
                [106.477333, 21.4482131],
                [106.4772383, 21.4482851],
                [106.4770326, 21.4484078],
                [106.4769473, 21.4484476],
                [106.4767697, 21.4485127],
                [106.4765968, 21.4485214],
                [106.4762506, 21.4485214],
                [106.4758782, 21.4484954],
                [106.4756796, 21.4484704],
                [106.4754823, 21.4484378],
                [106.4753734, 21.4483702],
                [106.4752593, 21.4483104],
                [106.4751407, 21.4482588],
                [106.4750183, 21.4482156],
                [106.4748176, 21.448165],
                [106.4747152, 21.4481482],
                [106.4741546, 21.4481058],
                [106.4739779, 21.4480553],
                [106.4737984, 21.4480141],
                [106.4736168, 21.4479822],
                [106.4732911, 21.4479489],
                [106.4731273, 21.4479435],
                [106.4728242, 21.4468775],
                [106.4727736, 21.4467253],
                [106.4726531, 21.446427],
                [106.4725077, 21.4461385],
                [106.4723191, 21.4458332],
                [106.4721027, 21.4455444],
                [106.4718542, 21.4453742],
                [106.4717379, 21.4452797],
                [106.4715261, 21.4450769],
                [106.4714295, 21.4449677],
                [106.471371, 21.4447512],
                [106.4712766, 21.4443136],
                [106.4712384, 21.4440774],
                [106.4711881, 21.4436021],
                [106.4716762, 21.4431177],
                [106.4717628, 21.4430113],
                [106.4717964, 21.4429523],
                [106.4718425, 21.4428259],
                [106.4718586, 21.4426933],
                [106.4718421, 21.442552],
                [106.4718211, 21.4424836],
                [106.4715561, 21.4421998],
                [106.4712985, 21.4419102],
                [106.4708728, 21.4413979],
                [106.4705336, 21.4409557],
                [106.4701903, 21.4403615],
                [106.4701181, 21.4402521],
                [106.4699526, 21.4400468],
                [106.4697611, 21.4398622],
                [106.4695479, 21.4397019],
                [106.4694338, 21.4396312],
                [106.4691925, 21.4395102],
                [106.4686775, 21.4390233],
                [106.467331, 21.4385839],
                [106.4671326, 21.4384565],
                [106.4669555, 21.4383043],
                [106.4668744, 21.4382179],
                [106.4667334, 21.4380299],
                [106.4666229, 21.437825],
                [106.4665076, 21.437453],
                [106.4664513, 21.4373599],
                [106.4663865, 21.4372718],
                [106.4663136, 21.4371893],
                [106.4662333, 21.4371131],
                [106.4661462, 21.4370436],
                [106.4660529, 21.4369816],
                [106.4659541, 21.4369273],
                [106.4658506, 21.4368812],
                [106.4657432, 21.4368438],
                [106.4655378, 21.4367981],
                [106.4653273, 21.4367838],
                [106.4652218, 21.4367884],
                [106.4650136, 21.4368213],
                [106.4642717, 21.4370598],
                [106.4635089, 21.43728],
                [106.4627257, 21.4374804],
                [106.4625895, 21.4375085],
                [106.4623126, 21.4375406],
                [106.4620337, 21.4375403],
                [106.4617605, 21.4375082],
                [106.461626, 21.4374804],
                [106.4607409, 21.4372044],
                [106.459478, 21.438124],
                [106.4590702, 21.4383468],
                [106.4583528, 21.4385745],
                [106.4580276, 21.4385946],
                [106.4562884, 21.4383496],
                [106.4554292, 21.4383809],
                [106.4549045, 21.4385347],
                [106.4534128, 21.4392504],
                [106.4529971, 21.4393285],
                [106.4528055, 21.4392931],
                [106.4523527, 21.4390841],
                [106.4518599, 21.4387259],
                [106.4516588, 21.4385086],
                [106.4514146, 21.4380359],
                [106.4512193, 21.4373565],
                [106.4509591, 21.4368053],
                [106.45056, 21.4363368],
                [106.4499896, 21.4360011],
                [106.4492169, 21.4358424],
                [106.4472538, 21.4358456],
                [106.4459191, 21.4360061],
                [106.4444727, 21.4363159],
                [106.4438088, 21.4365431],
                [106.4435734, 21.4366828],
                [106.4433925, 21.4368541],
                [106.4431483, 21.4373033],
                [106.4426902, 21.4384267],
                [106.4419397, 21.4399397],
                [106.4415002, 21.4406041],
                [106.4398773, 21.44265],
                [106.4395126, 21.4430494],
                [106.4391403, 21.443361],
                [106.4387769, 21.4435487],
                [106.4384257, 21.4436132],
                [106.4380766, 21.443571],
                [106.4376575, 21.4434219],
                [106.4354497, 21.4424343],
                [106.4350028, 21.4421981],
                [106.4348407, 21.4420667],
                [106.4345856, 21.4416393],
                [106.4339466, 21.4394233],
                [106.4337699, 21.4389417],
                [106.433599, 21.4386341],
                [106.4334283, 21.4384422],
                [106.4328695, 21.4380165],
                [106.4324703, 21.4377908],
                [106.4322722, 21.4377364],
                [106.4318663, 21.4377259],
                [106.431207, 21.4378714],
                [106.4305626, 21.4382084],
                [106.4292, 21.4392534],
                [106.4287697, 21.4395236],
                [106.4278463, 21.4398678],
                [106.4274745, 21.4399464],
                [106.4266981, 21.4400105],
                [106.4259493, 21.439959],
                [106.4252237, 21.4397937],
                [106.4237866, 21.4392852],
                [106.420847, 21.4380871],
                [106.4201245, 21.437878],
                [106.4187936, 21.4375899],
                [106.4180712, 21.4373331],
                [106.4173537, 21.4369518],
                [106.4172133, 21.4367792],
                [106.4164109, 21.4359986],
                [106.4157311, 21.4349914],
                [106.4147652, 21.4332381],
                [106.4141834, 21.4325539],
                [106.4138876, 21.4323103],
                [106.413543, 21.4321235],
                [106.4131556, 21.4320125],
                [106.4118869, 21.4318291],
                [106.4110192, 21.4315745],
                [106.4105474, 21.4313782],
                [106.4094988, 21.4308541],
                [106.407381, 21.4297014],
                [106.4062933, 21.4290234],
                [106.405813, 21.4285619],
                [106.4054218, 21.427989],
                [106.4051491, 21.4273828],
                [106.4048878, 21.4262977],
                [106.4049882, 21.4257956],
                [106.4056986, 21.4239788],
                [106.4072554, 21.4210485],
                [106.4078238, 21.4201376],
                [106.4081169, 21.4197757],
                [106.4084216, 21.4195208],
                [106.4087387, 21.4193472],
                [106.4099909, 21.4189132],
                [106.4114065, 21.4182286],
                [106.41196, 21.4180486],
                [106.4134099, 21.4177389],
                [106.4138082, 21.4175804],
                [106.4141318, 21.4173227],
                [106.4147577, 21.4165218],
                [106.415434, 21.4150828],
                [106.4156355, 21.4143456],
                [106.4155525, 21.4139222],
                [106.4151189, 21.4129374],
                [106.4150152, 21.412496],
                [106.4150621, 21.4120653],
                [106.4153371, 21.4113152],
                [106.4154869, 21.4107195],
                [106.415523, 21.410062],
                [106.4154626, 21.4093782],
                [106.4152615, 21.4086997],
                [106.4150844, 21.408372],
                [106.4146228, 21.4077676],
                [106.4141398, 21.4072708],
                [106.4134414, 21.4066873],
                [106.4126921, 21.4062169],
                [106.4121529, 21.4059983],
                [106.4113713, 21.4058445],
                [106.4094204, 21.4058558],
                [106.4087587, 21.4057749],
                [106.408034, 21.405533],
                [106.4053496, 21.4043696],
                [106.4048912, 21.4042716],
                [106.4044337, 21.4043293],
                [106.4041931, 21.4044247],
                [106.4022265, 21.4056766],
                [106.4016283, 21.4060008],
                [106.4011989, 21.4061344],
                [106.4010804, 21.4060934],
                [106.4006274, 21.4055424],
                [106.4004392, 21.4053734],
                [106.4000833, 21.4052486],
                [106.3989985, 21.4052523],
                [106.396194, 21.4054841],
                [106.3957277, 21.4054863],
                [106.3953911, 21.4054343],
                [106.3950081, 21.4051973],
                [106.394089, 21.4042396],
                [106.392525, 21.4028261],
                [106.3917413, 21.4019919],
                [106.3906815, 21.4006311],
                [106.3902815, 21.4001943],
                [106.3876929, 21.3977671],
                [106.3872515, 21.3974071],
                [106.3867861, 21.3971351],
                [106.3861525, 21.3969092],
                [106.3852336, 21.3967667],
                [106.3833568, 21.3966199],
                [106.3824643, 21.3964455],
                [106.3816278, 21.3960387],
                [106.3785159, 21.3941765],
                [106.377697, 21.3937847],
                [106.3772848, 21.3936905],
                [106.377064, 21.3937465],
                [106.3763804, 21.3945485],
                [106.3761204, 21.3947636],
                [106.3758415, 21.3948822],
                [106.3755594, 21.3948931],
                [106.3744454, 21.3945584],
                [106.3735283, 21.3944123],
                [106.3727391, 21.3944718],
                [106.3721058, 21.3946804],
                [106.371583, 21.3951136],
                [106.3702638, 21.3966757],
                [106.3694102, 21.3974377],
                [106.3677215, 21.3987405],
                [106.3671479, 21.399087],
                [106.3667513, 21.3991832],
                [106.3663599, 21.3991343],
                [106.36585, 21.3989173],
                [106.3648534, 21.3983989],
                [106.36377, 21.397759],
                [106.3631625, 21.3972638],
                [106.3623594, 21.3961708],
                [106.3621177, 21.3960185],
                [106.3618623, 21.3959448],
                [106.3611154, 21.3959356],
                [106.3601936, 21.3961106],
                [106.3595556, 21.3960782],
                [106.3591212, 21.3961057],
                [106.3582078, 21.3963905],
                [106.3567313, 21.397134],
                [106.3558292, 21.3973545],
                [106.355129, 21.3974152],
                [106.3542955, 21.3973694],
                [106.3534148, 21.397153],
                [106.3530138, 21.3969578],
                [106.3526576, 21.3967188],
                [106.3517199, 21.3959457],
                [106.3514247, 21.3958214],
                [106.3511101, 21.3958461],
                [106.3505508, 21.3961649],
                [106.3500196, 21.3966534],
                [106.3498552, 21.3968843],
                [106.3497397, 21.3970588],
                [106.3492286, 21.3982791],
                [106.3489117, 21.398826],
                [106.3484384, 21.3994113],
                [106.3472591, 21.4007149],
                [106.3467538, 21.4010843],
                [106.3466025, 21.4011412],
                [106.346455, 21.4011767],
                [106.3461801, 21.4011897],
                [106.3459488, 21.4011285],
                [106.34565, 21.4008832],
                [106.3453025, 21.400386],
                [106.344446, 21.3989416],
                [106.3441003, 21.3985421],
                [106.3438697, 21.3984582],
                [106.3436175, 21.3985029],
                [106.342496, 21.399235],
                [106.3422071, 21.3993544],
                [106.3419956, 21.3993515],
                [106.3418155, 21.3992397],
                [106.3416762, 21.3990138],
                [106.3415185, 21.3979061],
                [106.3413927, 21.39763],
                [106.3411842, 21.3974581],
                [106.3409394, 21.3973385],
                [106.3406582, 21.3973092],
                [106.3404915, 21.3973626],
                [106.3400893, 21.3976349],
                [106.3395831, 21.3981214],
                [106.3367488, 21.4012158],
                [106.3362354, 21.4018261],
                [106.3358555, 21.4024174],
                [106.3352614, 21.4038798],
                [106.3350996, 21.4041653],
                [106.3349203, 21.4043506],
                [106.3347278, 21.4044533],
                [106.3345281, 21.4044959],
                [106.3341182, 21.4044761],
                [106.3334064, 21.4042902],
                [106.3324664, 21.4039624],
                [106.332089, 21.4038924],
                [106.33189, 21.4039157],
                [106.3314934, 21.4040751],
                [106.3311597, 21.4043601],
                [106.3310153, 21.4045632],
                [106.3299932, 21.4069422],
                [106.3296801, 21.4074791],
                [106.3292194, 21.4080086],
                [106.3290843, 21.4080961],
                [106.3288129, 21.4081653],
                [106.3280576, 21.4081415],
                [106.3272477, 21.4082425],
                [106.3263848, 21.4084546],
                [106.3257713, 21.4086998],
                [106.3251823, 21.4090456],
                [106.3247633, 21.409463],
                [106.324491, 21.4099415],
                [106.3242616, 21.410481],
                [106.3240863, 21.4110774],
                [106.3239626, 21.4120641],
                [106.3239265, 21.4130832],
                [106.3240257, 21.413844],
                [106.3242097, 21.4142777],
                [106.3246358, 21.4149425],
                [106.3249458, 21.4152842],
                [106.3259519, 21.4161836],
                [106.3263337, 21.4166468],
                [106.3265974, 21.4172538],
                [106.3267643, 21.4181882],
                [106.326808, 21.4196348],
                [106.3267731, 21.4205635],
                [106.326699, 21.4212132],
                [106.3265182, 21.42181],
                [106.3258742, 21.4227213],
                [106.3255282, 21.4233719],
                [106.3253957, 21.4240292],
                [106.3254572, 21.4244402],
                [106.3256257, 21.424804],
                [106.3259991, 21.4252232],
                [106.3263846, 21.4254447],
                [106.3276185, 21.4259786],
                [106.3278418, 21.4261643],
                [106.3279822, 21.4263876],
                [106.3280787, 21.4270075],
                [106.3280146, 21.4286554],
                [106.3280557, 21.4291194],
                [106.3281622, 21.4295132],
                [106.3283248, 21.4298371],
                [106.3292003, 21.4310281],
                [106.3292072, 21.431195],
                [106.3289831, 21.4315094],
                [106.3288988, 21.4318571],
                [106.3289657, 21.4325715],
                [106.3291694, 21.4330683],
                [106.3293835, 21.4333566],
                [106.3298195, 21.4337259],
                [106.3322035, 21.4352664],
                [106.3324277, 21.4354773],
                [106.3324956, 21.4356585],
                [106.3321806, 21.4374025],
                [106.331966, 21.4389039],
                [106.3318481, 21.4392937],
                [106.3316769, 21.4395425],
                [106.3314602, 21.4396719],
                [106.3304681, 21.4399012],
                [106.3299742, 21.4401061],
                [106.3295167, 21.4403703],
                [106.3291941, 21.4406491],
                [106.328926, 21.4411307],
                [106.3287911, 21.4415859],
                [106.3286926, 21.4422345],
                [106.3286419, 21.4435255],
                [106.3287284, 21.4440557],
                [106.3291159, 21.4451683],
                [106.3291756, 21.4457997],
                [106.3291245, 21.4460533],
                [106.3290081, 21.4463156],
                [106.3287167, 21.4467165],
                [106.3284707, 21.4469512],
                [106.3276706, 21.4474436],
                [106.3262547, 21.44816],
                [106.3258014, 21.4482975],
                [106.3254866, 21.4482853],
                [106.3249997, 21.4481139],
                [106.3233032, 21.4473655],
                [106.322471, 21.4470901],
                [106.3216062, 21.4469786],
                [106.3189955, 21.4469087],
                [106.3184693, 21.4468018],
                [106.3181799, 21.4466347],
                [106.3179677, 21.4464096],
                [106.3173571, 21.4451555],
                [106.3169879, 21.4445624],
                [106.3168265, 21.4444582],
                [106.3166875, 21.4444271],
                [106.3163672, 21.444418],
                [106.3160351, 21.4444725],
                [106.3157029, 21.4445874],
                [106.3154345, 21.4447503],
                [106.3148957, 21.4453126],
                [106.3146057, 21.4457322],
                [106.314392, 21.4463453],
                [106.3140774, 21.4476571],
                [106.3138419, 21.4484128],
                [106.3133488, 21.4494571],
                [106.3129295, 21.450064],
                [106.3120953, 21.451012],
                [106.3113892, 21.4516851],
                [106.3109998, 21.4518857],
                [106.3104902, 21.4519528],
                [106.3100895, 21.4518346],
                [106.3090966, 21.4512342],
                [106.3073135, 21.4502464],
                [106.3049316, 21.4490412],
                [106.30445, 21.4486776],
                [106.3040771, 21.4482236],
                [106.3039295, 21.4478174],
                [106.3038802, 21.4473319],
                [106.3039057, 21.4469168],
                [106.304024, 21.446523],
                [106.3044401, 21.4458764],
                [106.3058184, 21.4441666],
                [106.3060529, 21.4438088],
                [106.3061596, 21.4435339],
                [106.3061627, 21.4433098],
                [106.3060636, 21.4429633],
                [106.3059117, 21.4427138],
                [106.3056814, 21.4424998],
                [106.3055401, 21.4424332],
                [106.3051879, 21.4423908],
                [106.3044109, 21.4424737],
                [106.3020383, 21.442919],
                [106.3013795, 21.4431602],
                [106.300866, 21.4435562],
                [106.3003203, 21.4442165],
                [106.2999878, 21.4447764],
                [106.2994946, 21.4458246],
                [106.2992522, 21.446177],
                [106.2988868, 21.4464931],
                [106.2984699, 21.4465723],
                [106.2981291, 21.4465597],
                [106.2979238, 21.4464782],
                [106.2975564, 21.4461043],
                [106.2970045, 21.4452754],
                [106.2963238, 21.4439225],
                [106.2959878, 21.4434317],
                [106.2956503, 21.4432268],
                [106.2954062, 21.4431824],
                [106.2951704, 21.4431927],
                [106.2947782, 21.4432983],
                [106.2943563, 21.4435228],
                [106.2934175, 21.4442739],
                [106.2893387, 21.4483045],
                [106.28873, 21.448979],
                [106.2885443, 21.4493144],
                [106.2884348, 21.4496696],
                [106.2883929, 21.4500008],
                [106.2884455, 21.4504714],
                [106.2885574, 21.4507927],
                [106.2888593, 21.4512635],
                [106.290013, 21.4527072],
                [106.2902949, 21.4532436],
                [106.2904701, 21.4538122],
                [106.2905219, 21.4543187],
                [106.2904018, 21.4550697],
                [106.2902228, 21.4554986],
                [106.2898374, 21.4559588],
                [106.2895929, 21.4560527],
                [106.289242, 21.4560059],
                [106.2887046, 21.4557957],
                [106.2881056, 21.4554969],
                [106.287505, 21.455068],
                [106.2849448, 21.4524585],
                [106.2841661, 21.4522416],
                [106.2837033, 21.4521868],
                [106.2836165, 21.4525575],
                [106.2835044, 21.4529045],
                [106.2832497, 21.4539315],
                [106.2831396, 21.4545807],
                [106.2830897, 21.4558432],
                [106.2831813, 21.4567801],
                [106.2834193, 21.4575821],
                [106.2842202, 21.4588995],
                [106.2844695, 21.4594801],
                [106.2845623, 21.4600567],
                [106.2844517, 21.461517],
                [106.2845835, 21.4620173],
                [106.2850244, 21.462968],
                [106.2859525, 21.4645566],
                [106.2862655, 21.4653855],
                [106.2864352, 21.4666312],
                [106.2864494, 21.4675986],
                [106.2863968, 21.4687891],
                [106.2863401, 21.4693422],
                [106.2861652, 21.4702042],
                [106.2860534, 21.470519],
                [106.2857929, 21.4709537],
                [106.2852663, 21.4713766],
                [106.2847778, 21.4716354],
                [106.2841519, 21.4718699],
                [106.2826687, 21.4723252],
                [106.282509, 21.4724311],
                [106.282416, 21.4725596],
                [106.2823924, 21.4728934],
                [106.2826165, 21.4735855],
                [106.2833149, 21.4752321],
                [106.2846111, 21.4779189],
                [106.2848424, 21.4786654],
                [106.2848953, 21.4790302],
                [106.2848367, 21.479673],
                [106.2846276, 21.4802325],
                [106.284257, 21.4809903],
                [106.284125, 21.4814566],
                [106.2841122, 21.4816832],
                [106.2841378, 21.4819083],
                [106.2842981, 21.4823713],
                [106.2854332, 21.4844087],
                [106.2856575, 21.4849968],
                [106.2857141, 21.4852676],
                [106.2857364, 21.4855185],
                [106.285691, 21.4859521],
                [106.285113, 21.4872965],
                [106.2850849, 21.4875192],
                [106.2851551, 21.487912],
                [106.2853332, 21.4882469],
                [106.2856108, 21.4885823],
                [106.2870284, 21.4899698],
                [106.2875361, 21.4905304],
                [106.2879608, 21.4911439],
                [106.2880083, 21.4912609],
                [106.2879617, 21.4913259],
                [106.2875712, 21.4913202],
                [106.2856475, 21.4908003],
                [106.2843548, 21.4906608],
                [106.2835082, 21.4906311],
                [106.2826284, 21.490662],
                [106.2802414, 21.4909367],
                [106.279781, 21.4910426],
                [106.2792384, 21.4912472],
                [106.277854, 21.4919228],
                [106.2771156, 21.4923197],
                [106.2761492, 21.4929976],
                [106.2754758, 21.493377],
                [106.2747294, 21.4936158],
                [106.2740064, 21.4937173],
                [106.2733635, 21.4936843],
                [106.2730787, 21.493616],
                [106.2725276, 21.4933225],
                [106.2718831, 21.4927257],
                [106.2707531, 21.4915473],
                [106.2700184, 21.4908883],
                [106.2691135, 21.4902622],
                [106.2686371, 21.4901154],
                [106.2682238, 21.4901558],
                [106.2678488, 21.4902876],
                [106.2655946, 21.4913471],
                [106.2648573, 21.4916236],
                [106.2641953, 21.4917384],
                [106.2634346, 21.4917387],
                [106.2630573, 21.4916936],
                [106.2627112, 21.491616],
                [106.2621164, 21.4913832],
                [106.2616085, 21.4910938],
                [106.2609665, 21.4906015],
                [106.2606119, 21.4902015],
                [106.2604512, 21.4899501],
                [106.2598639, 21.4888242],
                [106.259534, 21.4884834],
                [106.258741, 21.4879478],
                [106.2580289, 21.4875463],
                [106.2572825, 21.4872068],
                [106.2566303, 21.4869748],
                [106.2561376, 21.4868631],
                [106.2557793, 21.4868499],
                [106.2554705, 21.486926],
                [106.2547623, 21.487242],
                [106.2543949, 21.4873153],
                [106.2534814, 21.4872421],
                [106.2517378, 21.4870209],
                [106.2506953, 21.487209],
                [106.2499552, 21.4874727],
                [106.2494885, 21.4874762],
                [106.2488482, 21.4870044],
                [106.2477145, 21.4858807],
                [106.2468557, 21.4846754],
                [106.2459955, 21.4832914],
                [106.2455845, 21.4823411],
                [106.2450722, 21.4819675],
                [106.2444895, 21.4820413],
                [106.2438596, 21.482781],
                [106.2440533, 21.4831172],
                [106.2446716, 21.48349],
                [106.2453101, 21.4837633],
                [106.2456548, 21.4843764],
                [106.2455756, 21.4850326],
                [106.2450711, 21.4855725],
                [106.2434839, 21.4860016],
                [106.2419258, 21.4861025],
                [106.2414635, 21.4866224],
                [106.2402298, 21.4887172],
                [106.2383513, 21.4935677],
                [106.2377234, 21.4945655],
                [106.237228, 21.4949367],
                [106.2337075, 21.4950222],
                [106.2263813, 21.4953443],
                [106.2259124, 21.4950697],
                [106.2249708, 21.4941033],
                [106.2243734, 21.4936707],
                [106.22378, 21.4937347],
                [106.2223072, 21.4951756],
                [106.2214986, 21.4961448],
                [106.2202272, 21.496313],
                [106.2187186, 21.4960063],
                [106.215579, 21.4959894],
                [106.2131322, 21.4963747],
                [106.2102531, 21.4970908],
                [106.2081002, 21.4983975],
                [106.2063501, 21.4996777],
                [106.2058019, 21.4999888],
                [106.2053701, 21.5001714],
                [106.2047128, 21.5003042],
                [106.2017394, 21.5005476],
                [106.1998406, 21.5008225],
                [106.1985882, 21.5010917],
                [106.1972872, 21.5014301],
                [106.1962031, 21.5017627],
                [106.1954666, 21.5020512],
                [106.1947994, 21.5024314],
                [106.1942899, 21.502884],
                [106.1935894, 21.503799],
                [106.1928396, 21.505134],
                [106.1925218, 21.5054179],
                [106.1920769, 21.5056413],
                [106.1905051, 21.5060847],
                [106.1876335, 21.5070867],
                [106.1845659, 21.5080536],
                [106.1817112, 21.5088703],
                [106.1801135, 21.5092527],
                [106.1795665, 21.5093557],
                [106.1787748, 21.5094354],
                [106.1755495, 21.5091682],
                [106.1748186, 21.5092378],
                [106.1744494, 21.5093341],
                [106.17364, 21.5096847],
                [106.1689038, 21.5121726],
                [106.1682895, 21.51259],
                [106.1678814, 21.5130104],
                [106.1676094, 21.5135073],
                [106.1675866, 21.5139906],
                [106.1681788, 21.5171183],
                [106.1682541, 21.5182147],
                [106.168153, 21.5188443],
                [106.1676278, 21.5202787],
                [106.1674388, 21.5210183],
                [106.1673778, 21.5217266],
                [106.1674164, 21.5224417],
                [106.1677334, 21.5251314],
                [106.1679857, 21.5279658],
                [106.1681316, 21.5289036],
                [106.1683822, 21.5301456],
                [106.1687158, 21.5314317],
                [106.1693915, 21.533233],
                [106.1696865, 21.5341923],
                [106.1698055, 21.5348356],
                [106.1698459, 21.5354336],
                [106.1698058, 21.535787],
                [106.1695662, 21.5362159],
                [106.16782, 21.5383229],
                [106.1675947, 21.5386468],
                [106.1673109, 21.5391876],
                [106.1671485, 21.539702],
                [106.1670255, 21.5405269],
                [106.1670042, 21.5423671],
                [106.1671257, 21.5433111],
                [106.1673406, 21.5438897],
                [106.1677317, 21.5445651],
                [106.1689908, 21.5463212],
                [106.1694682, 21.5471507],
                [106.1699596, 21.5483558],
                [106.1700633, 21.5490982],
                [106.1699899, 21.5498243],
                [106.1697343, 21.5508263],
                [106.1696169, 21.5511268],
                [106.1694377, 21.5514528],
                [106.1686459, 21.5525082],
                [106.1684419, 21.5528313],
                [106.1683149, 21.5531315],
                [106.1682571, 21.5534276],
                [106.1682604, 21.553739],
                [106.1686197, 21.555571],
                [106.1686313, 21.5564943],
                [106.1685025, 21.5570352],
                [106.168046, 21.558356],
                [106.1677478, 21.5594298],
                [106.1676532, 21.5599369],
                [106.1675746, 21.5607921],
                [106.1675902, 21.5611186],
                [106.1677495, 21.561707],
                [106.168742, 21.5638087],
                [106.1690727, 21.5646814],
                [106.1692213, 21.5652373],
                [106.1691892, 21.5657313],
                [106.1687855, 21.567254],
                [106.1687125, 21.5680398],
                [106.1688349, 21.5692092],
                [106.1693225, 21.5720125],
                [106.1694058, 21.5729542],
                [106.1693083, 21.5733528],
                [106.1690501, 21.5737226],
                [106.1677625, 21.5748604],
                [106.1673315, 21.5753787],
                [106.1671338, 21.5757388],
                [106.1670013, 21.576115],
                [106.1668866, 21.5770192],
                [106.166945, 21.5777754],
                [106.1671457, 21.5784425],
                [106.1674045, 21.578955],
                [106.1682365, 21.5802221],
                [106.1685032, 21.5807886],
                [106.1689989, 21.5830149],
                [106.1692223, 21.5835819],
                [106.1698901, 21.58494],
                [106.1700958, 21.5855418],
                [106.1704216, 21.5874712],
                [106.1705683, 21.5892353],
                [106.1705554, 21.590505],
                [106.1704959, 21.5912683],
                [106.1702881, 21.5920151],
                [106.1692773, 21.5939795],
                [106.1691907, 21.5942757],
                [106.1691746, 21.5948388],
                [106.1693256, 21.5953333],
                [106.1697198, 21.5958978],
                [106.1702479, 21.5963903],
                [106.1710057, 21.5968232],
                [106.1717265, 21.5970677],
                [106.1726497, 21.5972708],
                [106.174755, 21.5974744],
                [106.1752158, 21.5975551],
                [106.1756067, 21.5976837],
                [106.1759284, 21.5978543],
                [106.1761843, 21.5980581],
                [106.1765187, 21.5985298],
                [106.176687, 21.5990346],
                [106.1768891, 21.5999311],
                [106.1770528, 21.6002668],
                [106.1772597, 21.6005552],
                [106.1775355, 21.6008375],
                [106.1782128, 21.6013222],
                [106.17912, 21.6017994],
                [106.1797085, 21.6019997],
                [106.1812859, 21.6020826],
                [106.1815516, 21.6021262],
                [106.1820327, 21.6022878],
                [106.1841267, 21.6034752],
                [106.184857, 21.6040447],
                [106.1834084, 21.6045189],
                [106.1817939, 21.6052458],
                [106.18069, 21.6058582],
                [106.178578, 21.6071818],
                [106.1777635, 21.6078672],
                [106.1768417, 21.6083941],
                [106.1739423, 21.6097357],
                [106.1732332, 21.6104086],
                [106.172549, 21.6116191],
                [106.1713135, 21.6142256],
                [106.169728, 21.6164653],
                [106.1689158, 21.617407],
                [106.167832, 21.6185211],
                [106.1666133, 21.6196796],
                [106.1624189, 21.6247727],
                [106.1615307, 21.625489],
                [106.1608069, 21.6260052],
                [106.1601275, 21.6262253],
                [106.1595179, 21.6262727],
                [106.1586706, 21.6260704],
                [106.1553068, 21.6243454],
                [106.1544842, 21.6238832],
                [106.1536667, 21.6238842],
                [106.1525564, 21.62402],
                [106.1513932, 21.62407],
                [106.1504185, 21.6239886],
                [106.149296, 21.6235773],
                [106.1482748, 21.6231075],
                [106.1475587, 21.6226124],
                [106.1464661, 21.6223192],
                [106.1456464, 21.6221961],
                [106.1429048, 21.622752],
                [106.1423511, 21.6227956],
                [106.1417912, 21.622666],
                [106.139794, 21.6214374],
                [106.1387876, 21.620721],
                [106.1380413, 21.6203753],
                [106.1370058, 21.6197116],
                [106.1366842, 21.619417],
                [106.1363558, 21.6185701],
                [106.1359318, 21.6181772],
                [106.1352464, 21.6178426],
                [106.1329847, 21.6171403],
                [106.1311688, 21.6167762],
                [106.1289342, 21.6161329],
                [106.1271279, 21.6158668],
                [106.1263995, 21.6158742],
                [106.1251488, 21.6163735],
                [106.1245389, 21.616437],
                [106.1201914, 21.6166324],
                [106.1185948, 21.6167518],
                [106.1173803, 21.6165412],
                [106.1168969, 21.6165976],
                [106.1165124, 21.6168724],
                [106.1162757, 21.6172674],
                [106.1156131, 21.6180054],
                [106.1153653, 21.6184069],
                [106.1149098, 21.6187312],
                [106.1141139, 21.6189061],
                [106.1132666, 21.6189408],
                [106.1123981, 21.6188924],
                [106.1105368, 21.6192898],
                [106.109726, 21.6198574],
                [106.1082117, 21.6213492],
                [106.1076053, 21.6216097],
                [106.106139, 21.6218102],
                [106.1050743, 21.6218148],
                [106.1032364, 21.6213586],
                [106.1025158, 21.6213365],
                [106.1017023, 21.6216244],
                [106.101352, 21.621933],
                [106.1006524, 21.6231009],
                [106.1004481, 21.6238762],
                [106.1000647, 21.6244573],
                [106.0996389, 21.6247269],
                [106.0991484, 21.6248174],
                [106.0984683, 21.6247638],
                [106.0979224, 21.624553],
                [106.0974786, 21.6244712],
                [106.0957039, 21.6245088],
                [106.0941351, 21.6244092],
                [106.0935408, 21.6241669],
                [106.0931389, 21.6237711],
                [106.0927116, 21.6232193],
                [106.0925034, 21.6225679],
                [106.0907094, 21.6226594],
                [106.0901094, 21.6225306],
                [106.0894909, 21.6221629],
                [106.0879307, 21.6215109],
                [106.0868481, 21.6208909],
                [106.085721, 21.6203992],
                [106.0849609, 21.6200096],
                [106.0840115, 21.6196844],
                [106.0805097, 21.6188858],
                [106.079661, 21.6185807],
                [106.0785501, 21.6180428],
                [106.0781785, 21.617757],
                [106.077823, 21.6173705],
                [106.0775012, 21.6167246],
                [106.0771015, 21.6150879],
                [106.0767458, 21.6141108],
                [106.0763563, 21.6132828],
                [106.0757911, 21.6125658],
                [106.0747235, 21.6118785],
                [106.0737242, 21.6113382],
                [106.0728546, 21.610868],
                [106.0717574, 21.6104036],
                [106.0711647, 21.6102128],
                [106.07045, 21.6097216],
                [106.0692905, 21.6083586],
                [106.0687736, 21.607682],
                [106.0675976, 21.6049237],
                [106.0672686, 21.6046251],
                [106.0666358, 21.6044949],
                [106.0646053, 21.6045044],
                [106.0638945, 21.6043986],
                [106.0615652, 21.6041927],
                [106.060085, 21.6039796],
                [106.0588883, 21.6036601],
                [106.0582355, 21.6034161],
                [106.0576551, 21.6031339],
                [106.0572276, 21.602823],
                [106.056621, 21.6022039],
                [106.0561439, 21.6007849],
                [106.0560767, 21.5998999],
                [106.0558605, 21.599081],
                [106.0548449, 21.5965348],
                [106.0537607, 21.5946769],
                [106.0531451, 21.5938652],
                [106.0519149, 21.5925887],
                [106.0516475, 21.5920083],
                [106.0515865, 21.5915106],
                [106.0516152, 21.5908194],
                [106.052074, 21.5899469],
                [106.0522823, 21.5892642],
                [106.0523362, 21.5889219],
                [106.0522421, 21.5884901],
                [106.0520405, 21.5880701],
                [106.0512272, 21.5872847],
                [106.0508694, 21.5868162],
                [106.0506615, 21.5865123],
                [106.0503996, 21.5859768],
                [106.0502542, 21.5848331],
                [106.0498164, 21.5831123],
                [106.0490808, 21.5812396],
                [106.0484059, 21.5803994],
                [106.0477766, 21.5800068],
                [106.0472496, 21.5799213],
                [106.0465922, 21.5800467],
                [106.0462977, 21.5800435],
                [106.0461018, 21.5799194],
                [106.0447198, 21.5781886],
                [106.0439117, 21.5773804],
                [106.0415826, 21.5755417],
                [106.0405927, 21.5749925],
                [106.0396371, 21.5746583],
                [106.0389107, 21.5743251],
                [106.0381507, 21.5738994],
                [106.0375052, 21.5734654],
                [106.0368788, 21.5733176],
                [106.036386, 21.5733172],
                [106.0359419, 21.5731423],
                [106.0355472, 21.5726324],
                [106.035235, 21.5720663],
                [106.0349177, 21.5717732],
                [106.0344258, 21.571649],
                [106.0336505, 21.5716176],
                [106.0332903, 21.5714931],
                [106.0331591, 21.5713597],
                [106.033201, 21.5711086],
                [106.033338, 21.5709369],
                [106.033475, 21.5705079],
                [106.0334749, 21.5701219],
                [106.033292, 21.5692642],
                [106.0334289, 21.5688351],
                [106.0336573, 21.5685348],
                [106.0340684, 21.5682774],
                [106.0341139, 21.56802],
                [106.0339311, 21.5676341],
                [106.0336569, 21.5672051],
                [106.033337, 21.5668621],
                [106.0332001, 21.5668192],
                [106.0328805, 21.5675913],
                [106.0325835, 21.5673555],
                [106.032355, 21.5668837],
                [106.0324919, 21.5664119],
                [106.0326746, 21.5661115],
                [106.0330399, 21.5657255],
                [106.0336793, 21.5652106],
                [106.0337706, 21.5649104],
                [106.0336792, 21.5645243],
                [106.033542, 21.5642241],
                [106.0332679, 21.5639668],
                [106.0328111, 21.563881],
                [106.0315777, 21.5634953],
                [106.0312122, 21.563238],
                [106.0309838, 21.5628949],
                [106.0309151, 21.5621014],
                [106.0333356, 21.5602137],
                [106.0338837, 21.5600848],
                [106.0342947, 21.5601705],
                [106.0346146, 21.5603849],
                [106.0347973, 21.560342],
                [106.0351626, 21.5597843],
                [106.0353453, 21.5596985],
                [106.0355738, 21.5599987],
                [106.0356652, 21.5603847],
                [106.0358023, 21.5606849],
                [106.0364876, 21.5611995],
                [106.0368531, 21.5612424],
                [106.038132, 21.5609417],
                [106.0387714, 21.5607271],
                [106.039365, 21.5600836],
                [106.0397761, 21.5600835],
                [106.0403472, 21.5601905],
                [106.0408497, 21.5605336],
                [106.0412608, 21.5605335],
                [106.041946, 21.5604047],
                [106.0432705, 21.5599325],
                [106.0436817, 21.5602327],
                [106.0442298, 21.5602325],
                [106.0445496, 21.5601466],
                [106.0451888, 21.5594173],
                [106.0454627, 21.5587738],
                [106.0457822, 21.5584306],
                [106.0460563, 21.5582161],
                [106.046239, 21.5581731],
                [106.0466502, 21.5582588],
                [106.0472442, 21.5588592],
                [106.0477466, 21.5588161],
                [106.0480205, 21.5586444],
                [106.0483402, 21.5583012],
                [106.0485684, 21.557915],
                [106.0487053, 21.5572717],
                [106.0489336, 21.5570572],
                [106.049436, 21.5568854],
                [106.04971, 21.5566709],
                [106.0498696, 21.5561346],
                [106.0498238, 21.5553625],
                [106.0499147, 21.5542045],
                [106.0502342, 21.5536896],
                [106.051193, 21.552617],
                [106.0517411, 21.5524024],
                [106.0531113, 21.5522303],
                [106.0538877, 21.5520586],
                [106.0542073, 21.5516723],
                [106.0545497, 21.5511062],
                [106.0547188, 21.550779],
                [106.0546786, 21.550562],
                [106.0545788, 21.5502632],
                [106.0540008, 21.5490774],
                [106.0531325, 21.5477051],
                [106.0529038, 21.5467616],
                [106.0526753, 21.5464185],
                [106.0522869, 21.5461398],
                [106.0517388, 21.5459255],
                [106.0514189, 21.5454967],
                [106.0513275, 21.5451106],
                [106.0516926, 21.544553],
                [106.0518825, 21.5441218],
                [106.052671, 21.5432488],
                [106.0527469, 21.542749],
                [106.0525448, 21.542193],
                [106.0522414, 21.5409615],
                [106.0521729, 21.5403017],
                [106.0522755, 21.5399773],
                [106.0526053, 21.5396712],
                [106.0533601, 21.5394481],
                [106.0537703, 21.5392082],
                [106.0538741, 21.5389781],
                [106.0538091, 21.5386264],
                [106.0527379, 21.536871],
                [106.0519719, 21.5354263],
                [106.0516344, 21.5344734],
                [106.0512002, 21.5337368],
                [106.0509993, 21.5334931],
                [106.0506663, 21.5332524],
                [106.0503365, 21.5331959],
                [106.049547, 21.5333537],
                [106.0491875, 21.5333496],
                [106.0490356, 21.5332092],
                [106.0488253, 21.5328875],
                [106.0483644, 21.5318281],
                [106.0478322, 21.530906],
                [106.0474942, 21.5305213],
                [106.0473608, 21.5298],
                [106.0470945, 21.529123],
                [106.0461033, 21.5272062],
                [106.045834, 21.5264991],
                [106.045656, 21.5248667],
                [106.0454143, 21.524019],
                [106.0448593, 21.5232053],
                [106.0443503, 21.5223294],
                [106.0438897, 21.5213008],
                [106.0438591, 21.5206896],
                [106.0439472, 21.5200813],
                [106.0442352, 21.5193389],
                [106.0442323, 21.5188946],
                [106.0440903, 21.5184489],
                [106.0429809, 21.5175869],
                [106.0419241, 21.5170065],
                [106.0413145, 21.516474],
                [106.0411221, 21.5161659],
                [106.0412524, 21.5156723],
                [106.0416341, 21.5148848],
                [106.0420065, 21.514349],
                [106.0433888, 21.5142474],
                [106.0440934, 21.5141228],
                [106.0447181, 21.5138741],
                [106.0449501, 21.5135377],
                [106.0449837, 21.513182],
                [106.0445523, 21.5118454],
                [106.0445102, 21.510983],
                [106.045001, 21.5100067],
                [106.0454211, 21.5089247],
                [106.0455462, 21.508492],
                [106.0454793, 21.5079862],
                [106.0450753, 21.5063092],
                [106.0449647, 21.5056152],
                [106.0446631, 21.5051561],
                [106.0442136, 21.5047705],
                [106.0438432, 21.5040041],
                [106.0433658, 21.5022016],
                [106.0432918, 21.5010306],
                [106.0428977, 21.499013],
                [106.0424302, 21.4977894],
                [106.0422226, 21.4974801],
                [106.0403737, 21.4953613],
                [106.0398211, 21.494331],
                [106.0398034, 21.4940646],
                [106.0396749, 21.4937841],
                [106.0395003, 21.4936815],
                [106.038409, 21.4924891],
                [106.0375674, 21.4902689],
                [106.0378055, 21.4898237],
                [106.0386392, 21.4892113],
                [106.0392346, 21.4888773],
                [106.0397704, 21.4881537],
                [106.0398891, 21.4869294],
                [106.0400082, 21.4867624],
                [106.0409526, 21.4865904],
                [106.042061, 21.485893],
                [106.0440539, 21.4849626],
                [106.0455051, 21.4839637],
                [106.0470335, 21.4826225],
                [106.0481463, 21.481472],
                [106.0482867, 21.4810137],
                [106.0484143, 21.4807709],
                [106.0490907, 21.4798939],
                [106.0491306, 21.4794494],
                [106.0490709, 21.4791268],
                [106.0485745, 21.4780395],
                [106.0474803, 21.4767674],
                [106.0455282, 21.4751104],
                [106.0440449, 21.4736927],
                [106.0437417, 21.4734926],
                [106.0434395, 21.4734272],
                [106.0430948, 21.4734562],
                [106.0427363, 21.4735662],
                [106.0419697, 21.4738941],
                [106.0417397, 21.4738821],
                [106.0412784, 21.473683],
                [106.0410178, 21.4734154],
                [106.0409012, 21.4731873],
                [106.0404299, 21.4715747],
                [106.0403919, 21.4712923],
                [106.0406164, 21.4705236],
                [106.0423908, 21.4664201],
                [106.0436476, 21.4643122],
                [106.0440536, 21.4638116],
                [106.0446695, 21.4635249],
                [106.0450567, 21.4634015],
                [106.0464058, 21.4631642],
                [106.0470512, 21.4629851],
                [106.0475019, 21.4626996],
                [106.0476731, 21.4625235],
                [106.0478289, 21.4622129],
                [106.0479098, 21.461472],
                [106.0480516, 21.4612153],
                [106.0482235, 21.4611335],
                [106.0499193, 21.4611498],
                [106.0506372, 21.461051],
                [106.0514684, 21.4603951],
                [106.0511293, 21.4595402],
                [106.0508855, 21.4576032],
                [106.0507019, 21.4568307],
                [106.0506054, 21.4565395],
                [106.0504907, 21.4563458],
                [106.0501743, 21.4556759],
                [106.0500813, 21.4552091],
                [106.0500793, 21.4549438],
                [106.0500491, 21.4546696],
                [106.0499822, 21.454564],
                [106.0498116, 21.4544854],
                [106.0488468, 21.4542703],
                [106.0486143, 21.4541037],
                [106.0485755, 21.4539535],
                [106.048555, 21.4537237],
                [106.0486001, 21.4534226],
                [106.0486738, 21.4531744],
                [106.0488221, 21.4527843],
                [106.0491138, 21.4519862],
                [106.0491397, 21.4516498],
                [106.0491186, 21.4513403],
                [106.0491358, 21.4504467],
                [106.0491436, 21.4502166],
                [106.0491125, 21.4498276],
                [106.048571, 21.4476936],
                [106.0484515, 21.4463581],
                [106.0482696, 21.4436843],
                [106.0481524, 21.4419619],
                [106.0479141, 21.441461],
                [106.047259, 21.441016],
                [106.0453537, 21.4405157],
                [106.0443806, 21.4404899],
                [106.04327, 21.4404606],
                [106.0410078, 21.4407949],
                [106.0402338, 21.4406282],
                [106.0391441, 21.4387717],
                [106.0388203, 21.4384724],
                [106.0385683, 21.4382228],
                [106.0384068, 21.43814],
                [106.0381204, 21.4380748],
                [106.0377644, 21.438278],
                [106.0362887, 21.4393923],
                [106.035994, 21.4394277],
                [106.0357796, 21.4394291],
                [106.0354929, 21.4393303],
                [106.0338976, 21.4387038],
                [106.0318639, 21.4379796],
                [106.0299766, 21.436484],
                [106.0283377, 21.4347188],
                [106.0269428, 21.4333374],
                [106.026602, 21.4331553],
                [106.0262789, 21.4329563],
                [106.0260452, 21.4327735],
                [106.0258828, 21.4325401],
                [106.0253764, 21.4316891],
                [106.0260563, 21.4305126],
                [106.0275259, 21.4285276],
                [106.0285105, 21.4274832],
                [106.0287417, 21.4273312],
                [106.0291076, 21.4271979],
                [106.0297528, 21.4263283],
                [106.0302287, 21.4250482],
                [106.0304071, 21.4240464],
                [106.030942, 21.4201509],
                [106.0314771, 21.4171457],
                [106.031715, 21.4163665],
                [106.032267, 21.4157202],
                [106.0326792, 21.4154],
                [106.03314, 21.4148445],
                [106.0333345, 21.4145586],
                [106.033422, 21.4142901],
                [106.0334743, 21.4141056],
                [106.033512, 21.4137847],
                [106.0338567, 21.4100456],
                [106.0340291, 21.4081754],
                [106.03268, 21.4078807],
                [106.0319091, 21.4075338],
                [106.0311277, 21.4069525],
                [106.0308617, 21.4059829],
                [106.0307715, 21.4058494],
                [106.0306096, 21.4056997],
                [106.0302862, 21.4054504],
                [106.0301235, 21.4051835],
                [106.0300145, 21.4049329],
                [106.0298614, 21.4034769],
                [106.0297698, 21.4031593],
                [106.0296601, 21.4028083],
                [106.0296917, 21.4022219],
                [106.0296799, 21.4018201],
                [106.0295898, 21.4017035],
                [106.0293378, 21.4014538],
                [106.0278269, 21.4000228],
                [106.0276648, 21.3998396],
                [106.0272794, 21.399169],
                [106.0274334, 21.3986207],
                [106.0274766, 21.3974667],
                [106.0274925, 21.3969193],
                [106.0283474, 21.3955975],
                [106.0290577, 21.3944688],
                [106.0291311, 21.3942466],
                [106.0289165, 21.3941031],
                [106.0287385, 21.3940376],
                [106.0282918, 21.3940404],
                [106.0277564, 21.3941274],
                [106.0265084, 21.394537],
                [106.0259913, 21.3946741],
                [106.0255625, 21.3946767],
                [106.0235421, 21.3945048],
                [106.0228541, 21.3944924],
                [106.0223568, 21.3946004],
                [106.0217324, 21.3947584],
                [106.02078, 21.3951034],
                [106.0200687, 21.3955399],
                [106.019827, 21.3960347],
                [106.0197409, 21.3964874],
                [106.0198238, 21.3981448],
                [106.0198397, 21.3991328],
                [106.019788, 21.3994178],
                [106.0197007, 21.3997197],
                [106.0186937, 21.4013838],
                [106.017942, 21.4025105],
                [106.0176586, 21.4028638],
                [106.0172849, 21.4030838],
                [106.0169461, 21.4032031],
                [106.0164823, 21.4033063],
                [106.01575, 21.4033611],
                [106.0153568, 21.4033635],
                [106.0133157, 21.4027897],
                [106.013188, 21.4027404],
                [106.0123087, 21.4024008],
                [106.0119316, 21.4038522],
                [106.0104284, 21.404272],
                [106.009016, 21.4066448],
                [106.0074282, 21.4056844],
                [106.0065355, 21.4063523],
                [106.0057617, 21.4065749],
                [106.0040356, 21.4064636],
                [106.0015953, 21.4059628],
                [105.9986787, 21.4050724],
                [105.9955664, 21.4045692],
                [105.9948942, 21.4048258],
                [105.9943186, 21.4051362],
                [105.9938601, 21.4056802],
                [105.993557, 21.4064399],
                [105.9935023, 21.4068913],
                [105.9936059, 21.4079734],
                [105.9935115, 21.4082447],
                [105.9932631, 21.4085529],
                [105.9930528, 21.4087707],
                [105.9926309, 21.4090259],
                [105.991133, 21.4096663],
                [105.9908652, 21.4099387],
                [105.990732, 21.4101739],
                [105.9907538, 21.4105528],
                [105.9909299, 21.4109668],
                [105.9912844, 21.4121376],
                [105.9913053, 21.4123721],
                [105.9912297, 21.4125891],
                [105.9910002, 21.4128251],
                [105.9908467, 21.4129162],
                [105.9906157, 21.4129176],
                [105.9899586, 21.4125786],
                [105.98967, 21.4126163],
                [105.9894976, 21.4127618],
                [105.9892498, 21.4131422],
                [105.9890006, 21.413324],
                [105.9875095, 21.4135313],
                [105.9872791, 21.413641],
                [105.9868106, 21.4140726],
                [105.9847673, 21.410779],
                [105.9838573, 21.4124175],
                [105.9828453, 21.4130295],
                [105.9819524, 21.4133634],
                [105.9810595, 21.4138085],
                [105.9803452, 21.414031],
                [105.9796309, 21.4136414],
                [105.9791547, 21.4135857],
                [105.9785, 21.4138638],
                [105.9781428, 21.4144202],
                [105.9776069, 21.4157559],
                [105.9770709, 21.4166462],
                [105.9762374, 21.4178704],
                [105.9743437, 21.4195279],
                [105.9735769, 21.4195409],
                [105.9731535, 21.4195974],
                [105.9728751, 21.4197254],
                [105.9724155, 21.420116],
                [105.9723298, 21.4202699],
                [105.9723508, 21.4205405],
                [105.9722738, 21.4220024],
                [105.972191, 21.4225805],
                [105.9721056, 21.4227705],
                [105.9719332, 21.4229158],
                [105.9717411, 21.4229891],
                [105.9710581, 21.4231103],
                [105.9707038, 21.4234011],
                [105.9705893, 21.4235732],
                [105.9705138, 21.4237992],
                [105.9703922, 21.4243503],
                [105.9696762, 21.4252928],
                [105.9695087, 21.426187],
                [105.9693282, 21.426567],
                [105.969185, 21.4267483],
                [105.9690795, 21.4268121],
                [105.9683497, 21.4271303],
                [105.9681303, 21.4274562],
                [105.968134, 21.4280153],
                [105.9679939, 21.4286743],
                [105.9677647, 21.4289643],
                [105.9674772, 21.4291913],
                [105.9672097, 21.4295264],
                [105.9672709, 21.4300221],
                [105.9673785, 21.430292],
                [105.9677877, 21.4309988],
                [105.9677693, 21.4311251],
                [105.9675224, 21.4316495],
                [105.9665483, 21.4328633],
                [105.9662452, 21.4336403],
                [105.9652767, 21.4357196],
                [105.9649711, 21.4361179],
                [105.9643191, 21.4365364],
                [105.9639732, 21.4366467],
                [105.9638006, 21.4367738],
                [105.9631278, 21.436958],
                [105.9626074, 21.4368889],
                [105.9620662, 21.4366035],
                [105.9610634, 21.4363749],
                [105.9610049, 21.436285],
                [105.9610549, 21.435623],
                [105.9609398, 21.4352026],
                [105.9607063, 21.4348072],
                [105.9600485, 21.434324],
                [105.9590632, 21.4337886],
                [105.9584639, 21.4333953],
                [105.9580373, 21.4329468],
                [105.9577241, 21.432137],
                [105.9575797, 21.4306588],
                [105.9575956, 21.4301357],
                [105.9578193, 21.4290161],
                [105.9585726, 21.4264508],
                [105.9588765, 21.4258178],
                [105.9590422, 21.4246625],
                [105.9589992, 21.4239773],
                [105.9585621, 21.4218877],
                [105.9574666, 21.4192066],
                [105.9569221, 21.418344],
                [105.9565902, 21.4176424],
                [105.9561547, 21.4157872],
                [105.9559383, 21.415067],
                [105.9557439, 21.4147796],
                [105.9549291, 21.4138103],
                [105.9547342, 21.4134327],
                [105.9546941, 21.4131804],
                [105.9546882, 21.4122786],
                [105.9547628, 21.4118994],
                [105.9549707, 21.4113211],
                [105.955294, 21.410706],
                [105.9555213, 21.4101456],
                [105.9562205, 21.4081397],
                [105.9569263, 21.4071616],
                [105.9577873, 21.4063632],
                [105.9583796, 21.4056924],
                [105.9588367, 21.4049503],
                [105.9591021, 21.4043175],
                [105.9591558, 21.403704],
                [105.9590704, 21.4024059],
                [105.9588351, 21.4017399],
                [105.9585052, 21.401327],
                [105.957983, 21.4009512],
                [105.9575583, 21.4007372],
                [105.9568449, 21.4005608],
                [105.9560752, 21.4005834],
                [105.9553834, 21.4007496],
                [105.9549225, 21.4009146],
                [105.9545777, 21.401169],
                [105.9543679, 21.4014588],
                [105.9542924, 21.4016937],
                [105.9542743, 21.4018742],
                [105.9543918, 21.4021801],
                [105.9547012, 21.4024128],
                [105.9553382, 21.4026977],
                [105.9556866, 21.4029843],
                [105.9558418, 21.4031818],
                [105.9560173, 21.4035235],
                [105.9560782, 21.4040102],
                [105.9559663, 21.4045699],
                [105.9554721, 21.4055286],
                [105.9547447, 21.406146],
                [105.9540357, 21.4066371],
                [105.9535173, 21.4068384],
                [105.9531514, 21.4068044],
                [105.9528038, 21.406644],
                [105.9524939, 21.4063212],
                [105.9519881, 21.4054944],
                [105.9518109, 21.4049002],
                [105.9516355, 21.4045585],
                [105.9513835, 21.4042894],
                [105.9506689, 21.4038966],
                [105.9499358, 21.4036483],
                [105.9484107, 21.4029355],
                [105.9478112, 21.402506],
                [105.9466114, 21.4015028],
                [105.94543, 21.4003551],
                [105.9449856, 21.4000871],
                [105.9447542, 21.4000163],
                [105.9445425, 21.4000175],
                [105.944312, 21.4000908],
                [105.9438134, 21.4003823],
                [105.9428559, 21.4011452],
                [105.9422016, 21.4011669],
                [105.9413993, 21.4005675],
                [105.9406688, 21.3989468],
                [105.9403613, 21.3981936],
                [105.9401936, 21.397732],
                [105.9403255, 21.3967225],
                [105.9404964, 21.3963608],
                [105.9407755, 21.3957116],
                [105.9419626, 21.394689],
                [105.9440595, 21.3929575],
                [105.9448231, 21.392072],
                [105.9450642, 21.3912155],
                [105.9454808, 21.3902907],
                [105.9453974, 21.3892812],
                [105.9452773, 21.3885604],
                [105.945118, 21.3877136],
                [105.9445647, 21.3854802],
                [105.9442506, 21.3845081],
                [105.9438541, 21.3827067],
                [105.9437463, 21.3808856],
                [105.9440215, 21.378792],
                [105.9440183, 21.3782869],
                [105.9439964, 21.3778722],
                [105.9435855, 21.3768104],
                [105.9432358, 21.3763073],
                [105.9425583, 21.3756799],
                [105.9419975, 21.3752501],
                [105.9418034, 21.3749807],
                [105.9413745, 21.3741354],
                [105.9405586, 21.3729135],
                [105.9396872, 21.3720707],
                [105.9390104, 21.3715695],
                [105.9384896, 21.371374],
                [105.9379888, 21.3712866],
                [105.9373537, 21.3712902],
                [105.9367577, 21.3713656],
                [105.9351054, 21.3717897],
                [105.934568, 21.3720091],
                [105.9341851, 21.3723179],
                [105.9336866, 21.3726092],
                [105.9322639, 21.3728155],
                [105.9313613, 21.3731091],
                [105.930208, 21.3733319],
                [105.9275932, 21.373689],
                [105.9265533, 21.3735686],
                [105.9259184, 21.3736082],
                [105.9251311, 21.373883],
                [105.9237888, 21.3746299],
                [105.9231743, 21.3748317],
                [105.9227703, 21.37487],
                [105.9221159, 21.3748555],
                [105.9205361, 21.3745576],
                [105.9201112, 21.3743255],
                [105.9184672, 21.3729819],
                [105.9179071, 21.3726602],
                [105.9173862, 21.3724466],
                [105.9170204, 21.3724307],
                [105.9166554, 21.3725228],
                [105.9162721, 21.3727865],
                [105.9158799, 21.3731582],
                [105.9144576, 21.3749696],
                [105.9136641, 21.3758127],
                [105.9130795, 21.3761945],
                [105.9124653, 21.3764504],
                [105.9119849, 21.3765523],
                [105.9114456, 21.3764921],
                [105.91076, 21.37609],
                [105.9094051, 21.3748258],
                [105.9090556, 21.3743227],
                [105.9078592, 21.3722369],
                [105.9067757, 21.3697268],
                [105.9052692, 21.3656857],
                [105.9052091, 21.3652982],
                [105.9052228, 21.3643873],
                [105.9051248, 21.3640992],
                [105.9038041, 21.3621224],
                [105.9023939, 21.359641],
                [105.9014386, 21.3576081],
                [105.9008259, 21.3565111],
                [105.9004672, 21.3560621],
                [105.8999643, 21.3556319],
                [105.8990661, 21.3550867],
                [105.8969541, 21.3542412],
                [105.8964902, 21.353901],
                [105.8960065, 21.3534617],
                [105.8956577, 21.3530487],
                [105.8953655, 21.352464],
                [105.8952275, 21.3519148],
                [105.8952353, 21.3516081],
                [105.8953287, 21.3511476],
                [105.8955578, 21.3508579],
                [105.8956188, 21.3508109],
                [105.8960175, 21.3505037],
                [105.8968997, 21.350039],
                [105.8976002, 21.3497557],
                [105.8984556, 21.3496159],
                [105.89911, 21.3496304],
                [105.8994227, 21.3497199],
                [105.8996978, 21.3497986],
                [105.9001515, 21.3500487],
                [105.90103, 21.350558],
                [105.9031745, 21.3519894],
                [105.9038787, 21.3522921],
                [105.9052853, 21.3526092],
                [105.9062958, 21.352667],
                [105.9079494, 21.3524867],
                [105.9083528, 21.3523762],
                [105.9086687, 21.352113],
                [105.9088876, 21.3517421],
                [105.9089521, 21.3512728],
                [105.9088918, 21.3508402],
                [105.9086278, 21.3501833],
                [105.9083754, 21.349806],
                [105.9061368, 21.3471307],
                [105.904908, 21.3459919],
                [105.9036512, 21.3449796],
                [105.9028312, 21.3446143],
                [105.9009323, 21.3440023],
                [105.8977713, 21.3430633],
                [105.896895, 21.3429327],
                [105.8954904, 21.3429041],
                [105.8949616, 21.3429611],
                [105.8939916, 21.3432278],
                [105.8929071, 21.3436394],
                [105.8921397, 21.3440132],
                [105.8908075, 21.3448409],
                [105.8892173, 21.3459676],
                [105.8884886, 21.3463682],
                [105.888143, 21.3464964],
                [105.8878644, 21.3465428],
                [105.8871807, 21.3464382],
                [105.8869296, 21.3462773],
                [105.8865519, 21.3458644],
                [105.8857645, 21.3444978],
                [105.8849953, 21.3429598],
                [105.8846163, 21.3423124],
                [105.8840928, 21.3416568],
                [105.881327, 21.3392185],
                [105.8809011, 21.3387697],
                [105.8806776, 21.3383921],
                [105.8805789, 21.3379778],
                [105.8805752, 21.3373646],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.4311192, 22.2163239],
                [105.4311401, 22.2162289],
                [105.43125, 22.2157447],
                [105.4314686, 22.2144303],
                [105.4318361, 22.2132537],
                [105.4325177, 22.211627],
                [105.4332748, 22.2104149],
                [105.4342728, 22.209133],
                [105.4351796, 22.2083872],
                [105.436309, 22.207589],
                [105.4370684, 22.2071547],
                [105.4378095, 22.2068243],
                [105.4381982, 22.2065121],
                [105.438605, 22.2060443],
                [105.4389926, 22.2054036],
                [105.4394543, 22.2046936],
                [105.4404515, 22.2031869],
                [105.4409692, 22.2025978],
                [105.4415617, 22.2021986],
                [105.442247, 22.201851],
                [105.4433024, 22.2011567],
                [105.4439131, 22.2006364],
                [105.4445046, 22.1999433],
                [105.4448, 22.1994757],
                [105.445039, 22.1988009],
                [105.4451118, 22.1983512],
                [105.4451084, 22.1972621],
                [105.4451999, 22.196847],
                [105.4455699, 22.1965175],
                [105.4462738, 22.1961699],
                [105.4469401, 22.1956494],
                [105.4475137, 22.1951638],
                [105.4482539, 22.1945568],
                [105.4484386, 22.1943142],
                [105.4486971, 22.1939159],
                [105.4488626, 22.1934314],
                [105.4491202, 22.1927565],
                [105.449359, 22.1920125],
                [105.4495614, 22.1915106],
                [105.4498942, 22.1911467],
                [105.4502085, 22.1908173],
                [105.4511341, 22.1901579],
                [105.4527264, 22.1891681],
                [105.4538372, 22.1884736],
                [105.4551708, 22.1877783],
                [105.4556148, 22.1873795],
                [105.4559104, 22.1870157],
                [105.456188, 22.1868074],
                [105.4563178, 22.1867725],
                [105.4564292, 22.1868067],
                [105.4565222, 22.1868929],
                [105.4565785, 22.1871002],
                [105.4567662, 22.1877566],
                [105.4569908, 22.1883783],
                [105.4572707, 22.1888788],
                [105.4574941, 22.1891375],
                [105.4579777, 22.1894819],
                [105.4585904, 22.1896531],
                [105.4589988, 22.189721],
                [105.4594442, 22.1897544],
                [105.4597965, 22.1897015],
                [105.4600374, 22.1896317],
                [105.4601483, 22.1894931],
                [105.4602405, 22.1893199],
                [105.4602579, 22.1889742],
                [105.4602562, 22.1884556],
                [105.4601784, 22.1873494],
                [105.4601767, 22.1868135],
                [105.4602494, 22.1863465],
                [105.4603223, 22.1859487],
                [105.4604698, 22.1856891],
                [105.4606731, 22.1854637],
                [105.4610063, 22.1852208],
                [105.461599, 22.1849079],
                [105.461932, 22.184665],
                [105.4621169, 22.184457],
                [105.4622831, 22.1842318],
                [105.4630029, 22.1830888],
                [105.4634643, 22.1823787],
                [105.4637782, 22.1819111],
                [105.4641109, 22.1815471],
                [105.4641848, 22.1814604],
                [105.4642031, 22.181374],
                [105.4642028, 22.1812876],
                [105.4641652, 22.1811321],
                [105.4639231, 22.1808388],
                [105.4634209, 22.1804428],
                [105.462844, 22.1798912],
                [105.4623045, 22.179426],
                [105.4618762, 22.1789432],
                [105.4616525, 22.1786153],
                [105.4614654, 22.1781145],
                [105.4614269, 22.1776824],
                [105.461387, 22.1768355],
                [105.4613829, 22.1755736],
                [105.4613444, 22.1751415],
                [105.4612689, 22.1747269],
                [105.4612303, 22.1742775],
                [105.461322, 22.1739661],
                [105.4615806, 22.1736023],
                [105.4618939, 22.1729964],
                [105.46242, 22.1693819],
                [105.46242, 22.1693646],
                [105.4626047, 22.169122],
                [105.4632707, 22.168567],
                [105.464473, 22.1675609],
                [105.4655746, 22.1665545],
                [105.4664016, 22.1656139],
                [105.466753, 22.1650861],
                [105.4671391, 22.1643277],
                [105.467314, 22.1638169],
                [105.4674187, 22.1634545],
                [105.4675943, 22.1631576],
                [105.4677879, 22.1629431],
                [105.4681571, 22.1624481],
                [105.4683501, 22.162069],
                [105.4687894, 22.1614092],
                [105.4690708, 22.1610627],
                [105.469423, 22.1607819],
                [105.4697755, 22.1605504],
                [105.4701988, 22.160401],
                [105.470623, 22.1604986],
                [105.4710121, 22.1606785],
                [105.4713662, 22.1609738],
                [105.4717733, 22.161236],
                [105.4720566, 22.1614492],
                [105.4722692, 22.1616791],
                [105.4725703, 22.161958],
                [105.4727296, 22.1620564],
                [105.472977, 22.162105],
                [105.4732066, 22.1621043],
                [105.4740888, 22.1618713],
                [105.4743359, 22.1618542],
                [105.474495, 22.1618866],
                [105.4746719, 22.1619849],
                [105.4751147, 22.1623787],
                [105.4758199, 22.1634546],
                [105.4760196, 22.1636107],
                [105.4761787, 22.1636925],
                [105.4763439, 22.1636213],
                [105.4767892, 22.1633854],
                [105.4771244, 22.1632692],
                [105.4772902, 22.1632873],
                [105.4774845, 22.1633759],
                [105.4779788, 22.1633744],
                [105.478032, 22.1633743],
                [105.4782896, 22.1632493],
                [105.4785642, 22.163078],
                [105.4792166, 22.1627633],
                [105.4799714, 22.1624467],
                [105.4804174, 22.1621254],
                [105.4809349, 22.1619583],
                [105.4812607, 22.1619722],
                [105.4816408, 22.1618497],
                [105.4821418, 22.1618909],
                [105.4826004, 22.1618016],
                [105.48306, 22.1619273],
                [105.4833601, 22.1618935],
                [105.4836425, 22.1618433],
                [105.4838892, 22.1617109],
                [105.4846459, 22.1609185],
                [105.4850866, 22.1606702],
                [105.4857391, 22.1604049],
                [105.4865322, 22.1599745],
                [105.4873966, 22.1597085],
                [105.4880844, 22.1594431],
                [105.4887194, 22.1592272],
                [105.4891073, 22.1590449],
                [105.4893363, 22.1588797],
                [105.4895827, 22.1586484],
                [105.4897237, 22.1585492],
                [105.4898999, 22.1584499],
                [105.4901646, 22.1584162],
                [105.4905708, 22.1584149],
                [105.4914718, 22.1585439],
                [105.4918603, 22.1585427],
                [105.4925131, 22.1583762],
                [105.4929538, 22.1581609],
                [105.4931826, 22.1579297],
                [105.4933052, 22.1576165],
                [105.4933038, 22.1572215],
                [105.4932137, 22.156695],
                [105.4929808, 22.1557574],
                [105.4927489, 22.1550831],
                [105.4926067, 22.1548037],
                [105.4925173, 22.1544912],
                [105.492481, 22.1542279],
                [105.4924802, 22.1539975],
                [105.4925569, 22.1527402],
                [105.4927671, 22.1518811],
                [105.4932471, 22.1511652],
                [105.4935465, 22.1508666],
                [105.4935108, 22.1507679],
                [105.4931579, 22.1508183],
                [105.4925929, 22.150853],
                [105.49171, 22.1508557],
                [105.4911982, 22.150923],
                [105.4907391, 22.1509243],
                [105.4904739, 22.1508594],
                [105.490085, 22.1507288],
                [105.4891653, 22.1503036],
                [105.4878916, 22.149616],
                [105.4868476, 22.1489771],
                [105.4858922, 22.1484203],
                [105.4850781, 22.1479124],
                [105.4835736, 22.1468469],
                [105.4831138, 22.1466507],
                [105.4825477, 22.1463231],
                [105.4821056, 22.1461269],
                [105.4818573, 22.1458313],
                [105.4811636, 22.1443189],
                [105.4806301, 22.1432339],
                [105.4800623, 22.1424125],
                [105.4792292, 22.1414602],
                [105.4789104, 22.1411648],
                [105.477227, 22.1397834],
                [105.4763147, 22.1388987],
                [105.4759647, 22.1385177],
                [105.4754451, 22.1380639],
                [105.475014, 22.1376205],
                [105.474677, 22.1371605],
                [105.4745339, 22.1366012],
                [105.4745331, 22.1363707],
                [105.4745851, 22.1360907],
                [105.4747951, 22.135514],
                [105.4752509, 22.1345414],
                [105.4754435, 22.1340634],
                [105.4755828, 22.1335034],
                [105.4757756, 22.1330748],
                [105.4757213, 22.132159],
                [105.4762797, 22.1309845],
                [105.4763827, 22.1298465],
                [105.4759888, 22.1282015],
                [105.4756507, 22.1274287],
                [105.475224, 22.1265411],
                [105.4750998, 22.1263439],
                [105.4749403, 22.1261797],
                [105.4747457, 22.1260486],
                [105.4745513, 22.1259997],
                [105.4730927, 22.1259686],
                [105.4724121, 22.1260257],
                [105.4712204, 22.1259296],
                [105.4707019, 22.1258134],
                [105.4700654, 22.1255354],
                [105.469428, 22.1249775],
                [105.4691797, 22.1246489],
                [105.4690198, 22.1243367],
                [105.4688771, 22.1238926],
                [105.4688823, 22.1235939],
                [105.4689695, 22.1230831],
                [105.4688995, 22.1229269],
                [105.4681682, 22.1224231],
                [105.4678831, 22.1222822],
                [105.4675643, 22.1219703],
                [105.4674224, 22.1217896],
                [105.4673155, 22.1214937],
                [105.4672268, 22.1213458],
                [105.4670674, 22.121198],
                [105.4664924, 22.1211649],
                [105.4662112, 22.1211657],
                [105.4657923, 22.1210919],
                [105.4653894, 22.120753],
                [105.4652028, 22.1205339],
                [105.4648852, 22.1198514],
                [105.4648306, 22.1193412],
                [105.4647538, 22.1189897],
                [105.4650206, 22.1182857],
                [105.4655883, 22.1179009],
                [105.4658029, 22.1178435],
                [105.4658204, 22.1177776],
                [105.46582, 22.1176788],
                [105.4655723, 22.1174819],
                [105.465254, 22.1173511],
                [105.4643698, 22.1168927],
                [105.4640513, 22.1166467],
                [105.4637324, 22.1163184],
                [105.4634665, 22.1159899],
                [105.4631461, 22.1155347],
                [105.4630392, 22.1152552],
                [105.4630254, 22.1149787],
                [105.4629061, 22.114336],
                [105.4631306, 22.1140191],
                [105.463428, 22.1137539],
                [105.4639839, 22.1130393],
                [105.4645709, 22.1124492],
                [105.4646881, 22.1120901],
                [105.4645972, 22.1114637],
                [105.4645583, 22.1108093],
                [105.4643965, 22.1099373],
                [105.4642042, 22.109436],
                [105.4637478, 22.1089274],
                [105.4635695, 22.1083847],
                [105.4636726, 22.1077215],
                [105.4640205, 22.1070637],
                [105.4642128, 22.1063075],
                [105.4642521, 22.1058836],
                [105.4643979, 22.1052208],
                [105.4646324, 22.1045165],
                [105.4648527, 22.1040053],
                [105.4652153, 22.1035154],
                [105.4655978, 22.1031854],
                [105.4659535, 22.1030166],
                [105.4665836, 22.1029103],
                [105.4670245, 22.1028103],
                [105.4673599, 22.1028093],
                [105.4678025, 22.102832],
                [105.4682611, 22.1030298],
                [105.4691799, 22.1032557],
                [105.4694759, 22.1032687],
                [105.4700675, 22.1031704],
                [105.4703334, 22.1030178],
                [105.4704791, 22.1023552],
                [105.4705662, 22.1018443],
                [105.471038, 22.1013462],
                [105.4712732, 22.1008764],
                [105.4715134, 22.0993988],
                [105.4718524, 22.0990114],
                [105.4720888, 22.0980228],
                [105.4721913, 22.0976914],
                [105.4727757, 22.0975154],
                [105.4730579, 22.0974487],
                [105.4733416, 22.0973656],
                [105.4738328, 22.0974244],
                [105.4741179, 22.0974741],
                [105.4743659, 22.0975471],
                [105.4745798, 22.0975865],
                [105.4747032, 22.0975533],
                [105.4748266, 22.0974871],
                [105.4748968, 22.0973716],
                [105.4748294, 22.0972823],
                [105.4745356, 22.0969876],
                [105.4736074, 22.096404],
                [105.4734557, 22.0959963],
                [105.4734412, 22.0953558],
                [105.4728794, 22.0946195],
                [105.472494, 22.0944137],
                [105.4718193, 22.0939708],
                [105.4711719, 22.0931312],
                [105.4709493, 22.0926106],
                [105.4705722, 22.092034],
                [105.4700944, 22.0916568],
                [105.4695595, 22.0913753],
                [105.4689956, 22.0909528],
                [105.4687513, 22.0904628],
                [105.4685362, 22.0901798],
                [105.4681804, 22.0893412],
                [105.4681444, 22.0891272],
                [105.4681608, 22.0887486],
                [105.4679691, 22.0881346],
                [105.4681996, 22.0872069],
                [105.4685818, 22.0866717],
                [105.4690231, 22.0858701],
                [105.4693346, 22.0851269],
                [105.4695442, 22.0844349],
                [105.4695201, 22.084055],
                [105.469362, 22.0832646],
                [105.4693539, 22.0830206],
                [105.4695138, 22.0821509],
                [105.4695561, 22.0815022],
                [105.4694414, 22.0810407],
                [105.469193, 22.0806135],
                [105.4683421, 22.07948],
                [105.4678996, 22.0790862],
                [105.4672669, 22.0787275],
                [105.4664073, 22.0782746],
                [105.4656012, 22.0776251],
                [105.4642387, 22.0768249],
                [105.4636378, 22.0765467],
                [105.4630887, 22.0759392],
                [105.4629288, 22.0756269],
                [105.4629099, 22.0752483],
                [105.4629602, 22.0744231],
                [105.464409, 22.0738748],
                [105.4667746, 22.0732336],
                [105.4671406, 22.0731086],
                [105.4675748, 22.0726527],
                [105.4683471, 22.0703036],
                [105.4685681, 22.0700132],
                [105.4688038, 22.0696345],
                [105.4691193, 22.0689915],
                [105.4693654, 22.0686781],
                [105.4695765, 22.0685128],
                [105.4698943, 22.0685284],
                [105.4701892, 22.0685101],
                [105.4706175, 22.0685465],
                [105.4712224, 22.0685218],
                [105.4721053, 22.0685486],
                [105.4725856, 22.0684756],
                [105.4734722, 22.0681832],
                [105.4737049, 22.0679626],
                [105.4738276, 22.0676989],
                [105.4739625, 22.0673009],
                [105.4739883, 22.0668391],
                [105.4745612, 22.0658687],
                [105.4746464, 22.0657513],
                [105.4744959, 22.0652219],
                [105.4743324, 22.0649602],
                [105.4739461, 22.0645614],
                [105.473326, 22.0635647],
                [105.47364, 22.0629629],
                [105.4737574, 22.0627062],
                [105.4737205, 22.0622454],
                [105.4736723, 22.0618784],
                [105.47372, 22.0604847],
                [105.4739476, 22.0599572],
                [105.4746505, 22.0590497],
                [105.4750788, 22.0582268],
                [105.4758018, 22.0576081],
                [105.4765833, 22.057222],
                [105.4769649, 22.0570795],
                [105.4773354, 22.0568047],
                [105.4775989, 22.0564686],
                [105.4777707, 22.0560815],
                [105.4780059, 22.055653],
                [105.4784347, 22.0551324],
                [105.4787577, 22.0548782],
                [105.4790778, 22.0547543],
                [105.4793991, 22.0545417],
                [105.4798158, 22.0544219],
                [105.4803265, 22.0541241],
                [105.4810748, 22.0533397],
                [105.481428, 22.052842],
                [105.4820033, 22.0523574],
                [105.4825157, 22.0517591],
                [105.4825729, 22.0511656],
                [105.4825571, 22.0508758],
                [105.4825049, 22.0506799],
                [105.4825394, 22.0504328],
                [105.4827148, 22.0501361],
                [105.4829261, 22.0500037],
                [105.4831551, 22.0499043],
                [105.4835255, 22.0498373],
                [105.4839647, 22.0496803],
                [105.4845289, 22.0495634],
                [105.4847811, 22.0494956],
                [105.4851887, 22.0491591],
                [105.4856283, 22.0487298],
                [105.4861144, 22.048381],
                [105.4864475, 22.0480419],
                [105.4868018, 22.0478477],
                [105.4875232, 22.0475537],
                [105.4876429, 22.0472794],
                [105.4878479, 22.0466717],
                [105.4881271, 22.0461327],
                [105.4886086, 22.0450647],
                [105.4888017, 22.0447678],
                [105.4890302, 22.0444873],
                [105.4891353, 22.044273],
                [105.4891348, 22.0441249],
                [105.4890634, 22.0438946],
                [105.4888852, 22.0434296],
                [105.4886539, 22.0428258],
                [105.4883162, 22.0421025],
                [105.4880848, 22.0415105],
                [105.4879777, 22.0411487],
                [105.4879232, 22.0407208],
                [105.4879572, 22.0403256],
                [105.4881147, 22.039963],
                [105.4884129, 22.0394518],
                [105.488712, 22.0391875],
                [105.489135, 22.039071],
                [105.4897699, 22.0389704],
                [105.4900517, 22.0388213],
                [105.4903685, 22.0385899],
                [105.4905969, 22.0383093],
                [105.4907363, 22.0378151],
                [105.4907155, 22.0368769],
                [105.4906597, 22.0360539],
                [105.4902994, 22.0338821],
                [105.4900851, 22.0331583],
                [105.4900304, 22.0326317],
                [105.4900114, 22.0322367],
                [105.4898485, 22.0310355],
                [105.4895623, 22.0299004],
                [105.4899856, 22.0297899],
                [105.4904258, 22.0295089],
                [105.4907599, 22.0291458],
                [105.4912344, 22.0285355],
                [105.4917253, 22.0275797],
                [105.4923209, 22.0263107],
                [105.492654, 22.0256515],
                [105.4928113, 22.0252067],
                [105.4930022, 22.0242709],
                [105.4931395, 22.0231507],
                [105.4930844, 22.022525],
                [105.4932236, 22.0219318],
                [105.4933796, 22.0211243],
                [105.4934313, 22.0207619],
                [105.4933242, 22.0204163],
                [105.4929502, 22.0194458],
                [105.492896, 22.0190673],
                [105.4928768, 22.0186391],
                [105.4929803, 22.0179472],
                [105.4932597, 22.017057],
                [105.4935735, 22.0159692],
                [105.4937303, 22.0153594],
                [105.4939193, 22.0138932],
                [105.4940049, 22.0131354],
                [105.4940557, 22.0125094],
                [105.4941419, 22.0119328],
                [105.494387, 22.0113556],
                [105.4947554, 22.0107287],
                [105.4951068, 22.010283],
                [105.4954587, 22.009969],
                [105.4957579, 22.0097376],
                [105.49708, 22.0093384],
                [105.4982089, 22.0091867],
                [105.49865, 22.0091689],
                [105.4990913, 22.0092005],
                [105.4993387, 22.0092985],
                [105.4998339, 22.0096099],
                [105.5000694, 22.0097751],
                [105.5005071, 22.0098856],
                [105.5018927, 22.0097791],
                [105.5031175, 22.0093396],
                [105.5049118, 22.0085177],
                [105.5055725, 22.0081186],
                [105.5058312, 22.0077206],
                [105.5058967, 22.0061761],
                [105.5058768, 22.0059962],
                [105.5057501, 22.0048529],
                [105.5054605, 22.0032211],
                [105.505338, 22.0020081],
                [105.5053776, 21.9998716],
                [105.5052809, 21.9990215],
                [105.5053689, 21.9986607],
                [105.5055215, 21.9981041],
                [105.5056303, 21.9976299],
                [105.5056948, 21.9971353],
                [105.505737, 21.9965996],
                [105.5058012, 21.996002],
                [105.5058003, 21.9957548],
                [105.5057551, 21.9954459],
                [105.5056435, 21.9951373],
                [105.5055542, 21.9948697],
                [105.5054205, 21.9945405],
                [105.505309, 21.9942525],
                [105.5051972, 21.9938409],
                [105.5051298, 21.9935321],
                [105.5050404, 21.9932439],
                [105.5049954, 21.9930174],
                [105.5049941, 21.9926467],
                [105.5050377, 21.9924817],
                [105.5051696, 21.9923165],
                [105.5053238, 21.9921924],
                [105.5057207, 21.9919851],
                [105.5058751, 21.9919229],
                [105.5069959, 21.99133],
                [105.5074366, 21.9910195],
                [105.507855, 21.9906471],
                [105.5082297, 21.9904193],
                [105.5085606, 21.9902946],
                [105.5090462, 21.9902313],
                [105.5093333, 21.9902098],
                [105.5103273, 21.9902067],
                [105.5106804, 21.9901232],
                [105.5109449, 21.9899368],
                [105.5112091, 21.9897094],
                [105.511473, 21.9893994],
                [105.5116708, 21.9891102],
                [105.5118906, 21.988821],
                [105.512, 21.9885114],
                [105.512065, 21.988161],
                [105.5120859, 21.9878311],
                [105.5120847, 21.9875013],
                [105.5121061, 21.9872951],
                [105.5121482, 21.9867179],
                [105.5119908, 21.985968],
                [105.511857, 21.9855975],
                [105.5117675, 21.9852886],
                [105.5117668, 21.9850825],
                [105.511832, 21.9847938],
                [105.5120076, 21.9844841],
                [105.5122499, 21.9842978],
                [105.512713, 21.9840903],
                [105.5129116, 21.9840484],
                [105.5131324, 21.9840271],
                [105.5136625, 21.9840255],
                [105.5139499, 21.9840865],
                [105.5141489, 21.9841476],
                [105.5144365, 21.9842704],
                [105.5146358, 21.9844347],
                [105.5147692, 21.9846403],
                [105.5148811, 21.9850728],
                [105.5149926, 21.9853609],
                [105.5150818, 21.985608],
                [105.5151709, 21.9858138],
                [105.515216, 21.986061],
                [105.5153052, 21.9862874],
                [105.5154165, 21.9865344],
                [105.5155942, 21.9868224],
                [105.515838, 21.9870483],
                [105.5161033, 21.9871093],
                [105.5163463, 21.9871085],
                [105.5165449, 21.9870461],
                [105.5167431, 21.9869012],
                [105.5169635, 21.9867562],
                [105.5171617, 21.9865907],
                [105.5173378, 21.9864253],
                [105.5174696, 21.9862394],
                [105.5177336, 21.98595],
                [105.5182015, 21.98521],
                [105.518421, 21.9848383],
                [105.5187513, 21.9845487],
                [105.5191922, 21.9843],
                [105.5196993, 21.9840511],
                [105.5201624, 21.9838641],
                [105.5206036, 21.9837184],
                [105.5210668, 21.9835521],
                [105.5215521, 21.9833651],
                [105.5219052, 21.9832609],
                [105.522236, 21.9831569],
                [105.5225888, 21.9829702],
                [105.5228313, 21.9828458],
                [105.5230517, 21.9827214],
                [105.5233384, 21.9825969],
                [105.5235809, 21.9824724],
                [105.5239335, 21.9822652],
                [105.5242199, 21.9820581],
                [105.5244842, 21.9818513],
                [105.5249246, 21.9814788],
                [105.5252104, 21.9811276],
                [105.5254961, 21.980735],
                [105.5259078, 21.9797766],
                [105.5261255, 21.9788897],
                [105.5262778, 21.9782709],
                [105.526386, 21.9776729],
                [105.5264953, 21.9773634],
                [105.5266047, 21.9770745],
                [105.5267582, 21.9767855],
                [105.526934, 21.9765376],
                [105.5272419, 21.9761863],
                [105.5285831, 21.9745332],
                [105.5294177, 21.9732732],
                [105.5299665, 21.9723853],
                [105.5302739, 21.9719103],
                [105.5306033, 21.9713939],
                [105.5310357, 21.9704366],
                [105.5311673, 21.9701888],
                [105.5312765, 21.9698587],
                [105.5312973, 21.9695289],
                [105.5313183, 21.9692197],
                [105.5313831, 21.9688484],
                [105.5315807, 21.9685181],
                [105.5322411, 21.9679389],
                [105.5325935, 21.9676904],
                [105.532858, 21.9675246],
                [105.5331888, 21.9673999],
                [105.5334977, 21.9673371],
                [105.5337846, 21.9672743],
                [105.5343588, 21.9672724],
                [105.5346017, 21.9672923],
                [105.5348889, 21.9673119],
                [105.5352644, 21.9673107],
                [105.5360498, 21.9672466],
                [105.5367563, 21.9671825],
                [105.5372198, 21.9670985],
                [105.537815, 21.966808],
                [105.5380352, 21.9666424],
                [105.5383432, 21.9663323],
                [105.538673, 21.9659396],
                [105.5388486, 21.9656505],
                [105.5390023, 21.9654027],
                [105.539222, 21.9651134],
                [105.5394198, 21.9648448],
                [105.5396179, 21.9646587],
                [105.539794, 21.9645138],
                [105.5399482, 21.9644103],
                [105.5401245, 21.9643273],
                [105.540367, 21.9642029],
                [105.5405875, 21.9641197],
                [105.5408743, 21.9640363],
                [105.5411391, 21.9639736],
                [105.5414261, 21.963952],
                [105.5421991, 21.9639494],
                [105.5424862, 21.9639692],
                [105.5427514, 21.9640095],
                [105.543193, 21.964008],
                [105.5437894, 21.9640266],
                [105.544452, 21.9640657],
                [105.5453262, 21.96415],
                [105.5457238, 21.9641693],
                [105.5462314, 21.9640851],
                [105.5467607, 21.9638979],
                [105.5473341, 21.9636899],
                [105.547819, 21.9634409],
                [105.5481056, 21.9633163],
                [105.5484805, 21.9631707],
                [105.5488555, 21.9630665],
                [105.5491864, 21.9629623],
                [105.5494291, 21.9629203],
                [105.5496941, 21.9628988],
                [105.5500695, 21.9628975],
                [105.5510579, 21.9628361],
                [105.5514772, 21.9627728],
                [105.5519402, 21.9625652],
                [105.5523586, 21.9622547],
                [105.5527329, 21.9619648],
                [105.5529972, 21.9617785],
                [105.5531955, 21.9616542],
                [105.5533716, 21.9615299],
                [105.5534815, 21.9613852],
                [105.5536128, 21.9610757],
                [105.5536783, 21.9608693],
                [105.5537436, 21.9606218],
                [105.5538086, 21.9602918],
                [105.5538956, 21.9599617],
                [105.5540049, 21.9596729],
                [105.5541804, 21.9593631],
                [105.554334, 21.9591153],
                [105.5545315, 21.9588055],
                [105.554949, 21.9582682],
                [105.5552144, 21.9580026],
                [105.5555154, 21.9577455],
                [105.5555974, 21.9576428],
                [105.5556883, 21.9574802],
                [105.5558072, 21.9574628],
                [105.5562087, 21.9573503],
                [105.5565707, 21.9572148],
                [105.5570662, 21.9571236],
                [105.5578813, 21.9569268],
                [105.5586485, 21.956745],
                [105.5593837, 21.9565932],
                [105.5599429, 21.956427],
                [105.5602945, 21.9563362],
                [105.5605499, 21.9562161],
                [105.5608533, 21.9560656],
                [105.5610608, 21.9559455],
                [105.5613163, 21.9558252],
                [105.5616838, 21.9557195],
                [105.5619235, 21.9556738],
                [105.5622115, 21.9556728],
                [105.5627235, 21.955701],
                [105.5633718, 21.9557307],
                [105.5637716, 21.9556996],
                [105.5642354, 21.9556681],
                [105.5644912, 21.9556522],
                [105.5646993, 21.9556665],
                [105.5648754, 21.9557107],
                [105.5650997, 21.9557994],
                [105.5653563, 21.9559777],
                [105.5655008, 21.9561116],
                [105.5657417, 21.9563495],
                [105.5660144, 21.9565278],
                [105.566351, 21.956691],
                [105.5667031, 21.9567494],
                [105.566863, 21.9567339],
                [105.5670069, 21.9567335],
                [105.5672948, 21.9567026],
                [105.5677744, 21.9566412],
                [105.5685102, 21.9566237],
                [105.5686704, 21.9566679],
                [105.5687826, 21.9567273],
                [105.5691454, 21.9569734],
                [105.5692739, 21.9570924],
                [105.5694505, 21.957271],
                [105.5696272, 21.9574346],
                [105.5697557, 21.9575684],
                [105.5699323, 21.957732],
                [105.5700926, 21.9578062],
                [105.5703006, 21.9578353],
                [105.5706684, 21.9577892],
                [105.5708602, 21.9577587],
                [105.571212, 21.9577425],
                [105.5715158, 21.9577116],
                [105.5718358, 21.9576955],
                [105.5721875, 21.9576645],
                [105.5726194, 21.957648],
                [105.5728433, 21.9576323],
                [105.5738191, 21.9576289],
                [105.5748907, 21.9575952],
                [105.5753866, 21.9575936],
                [105.5754827, 21.9576231],
                [105.5755148, 21.9576529],
                [105.5755151, 21.9577126],
                [105.5754197, 21.9578771],
                [105.5747687, 21.9588582],
                [105.5746419, 21.9591573],
                [105.5745466, 21.9593367],
                [105.57442, 21.9596657],
                [105.5742931, 21.9599348],
                [105.5741822, 21.960204],
                [105.5740711, 21.9604433],
                [105.5739923, 21.9607271],
                [105.5739611, 21.9609064],
                [105.5739621, 21.9611751],
                [105.5739792, 21.9614289],
                [105.5740441, 21.9616675],
                [105.574125, 21.9618762],
                [105.5742542, 21.9621892],
                [105.5743188, 21.9623235],
                [105.5743674, 21.9625024],
                [105.5744339, 21.9631369],
                [105.5744027, 21.9633161],
                [105.5743713, 21.9634805],
                [105.5742763, 21.9637346],
                [105.5741491, 21.9638993],
                [105.573926, 21.964124],
                [105.5736872, 21.9644084],
                [105.5734959, 21.9645884],
                [105.5733048, 21.9647831],
                [105.5731136, 21.9649927],
                [105.572795, 21.9653223],
                [105.5726039, 21.9655469],
                [105.5724609, 21.9657863],
                [105.5723819, 21.9660254],
                [105.5723831, 21.966324],
                [105.5723996, 21.9664583],
                [105.572448, 21.9665776],
                [105.5725924, 21.9666816],
                [105.5728168, 21.9667853],
                [105.5729449, 21.9667998],
                [105.5731689, 21.966799],
                [105.5736006, 21.9667527],
                [105.5741354, 21.9666164],
                [105.5745028, 21.9664957],
                [105.5749981, 21.9663147],
                [105.5753334, 21.9661494],
                [105.5755822, 21.9660127],
                [105.5782125, 21.9671921],
                [105.5811595, 21.9687175],
                [105.5807369, 21.9690486],
                [105.5806094, 21.9691386],
                [105.5803221, 21.9693188],
                [105.5800191, 21.9695587],
                [105.57978, 21.9697686],
                [105.5795889, 21.9699782],
                [105.579398, 21.9702477],
                [105.5792234, 21.9705917],
                [105.5791762, 21.9707859],
                [105.5790676, 21.9715835],
                [105.5790685, 21.9718075],
                [105.5790373, 21.9720167],
                [105.5789744, 21.9722706],
                [105.5788636, 21.9725696],
                [105.5786106, 21.973287],
                [105.5785313, 21.9734964],
                [105.5784044, 21.9737357],
                [105.5782771, 21.9739153],
                [105.578134, 21.9741248],
                [105.577975, 21.9743642],
                [105.57788, 21.9746333],
                [105.5777853, 21.9749322],
                [105.5777878, 21.9755593],
                [105.5778223, 21.9761185],
                [105.5778233, 21.9763573],
                [105.57784, 21.9765216],
                [105.5778889, 21.9767453],
                [105.5780505, 21.9771479],
                [105.5781793, 21.9773415],
                [105.578356, 21.97752],
                [105.5786448, 21.9777131],
                [105.5789174, 21.9778614],
                [105.5791418, 21.9779801],
                [105.5794145, 21.9781433],
                [105.5796872, 21.9783365],
                [105.579928, 21.9785296],
                [105.5802171, 21.9788122],
                [105.5807638, 21.9794821],
                [105.581371, 21.9801008],
                [105.5816116, 21.9802642],
                [105.5818202, 21.9804128],
                [105.5819489, 21.9805616],
                [105.5819814, 21.9806809],
                [105.5819665, 21.9809646],
                [105.5819031, 21.9811141],
                [105.5817919, 21.9812936],
                [105.5816804, 21.9814284],
                [105.5814258, 21.9817728],
                [105.5810433, 21.9821174],
                [105.5807403, 21.9823573],
                [105.580421, 21.9825526],
                [105.58007, 21.9827777],
                [105.5797032, 21.9830776],
                [105.5794643, 21.9833322],
                [105.5793693, 21.9835864],
                [105.579372, 21.9842731],
                [105.5794023, 21.9848066],
                [105.5794192, 21.9850454],
                [105.5795169, 21.9854629],
                [105.5795977, 21.9856718],
                [105.5796947, 21.9859103],
                [105.5798399, 21.9862232],
                [105.5799851, 21.9865213],
                [105.580066, 21.9867152],
                [105.5801465, 21.9868641],
                [105.5802754, 21.9870726],
                [105.5805169, 21.9872766],
                [105.5806728, 21.9874208],
                [105.5813073, 21.9878417],
                [105.5814274, 21.9880083],
                [105.5815606, 21.9884644],
                [105.5818135, 21.9895807],
                [105.5819007, 21.9898775],
                [105.582033, 21.9900998],
                [105.5821532, 21.990311],
                [105.5822854, 21.9904886],
                [105.5824171, 21.9906805],
                [105.5828026, 21.9910374],
                [105.5829472, 21.991216],
                [105.5830601, 21.9914097],
                [105.5831728, 21.9915735],
                [105.5832375, 21.9917525],
                [105.5833023, 21.9919463],
                [105.5833349, 21.9920955],
                [105.5833515, 21.9922448],
                [105.583352, 21.9923642],
                [105.5833367, 21.9925285],
                [105.5833052, 21.9926628],
                [105.5832897, 21.9927824],
                [105.5832582, 21.992887],
                [105.5831475, 21.9932308],
                [105.5830683, 21.9934103],
                [105.5830049, 21.9935447],
                [105.5829254, 21.9936794],
                [105.582846, 21.993844],
                [105.5827827, 21.9940084],
                [105.5827354, 21.9941727],
                [105.5827361, 21.9943519],
                [105.5827846, 21.9944862],
                [105.5828813, 21.994635],
                [105.5831062, 21.9948582],
                [105.5834116, 21.9952155],
                [105.583444, 21.9954128],
                [105.5835409, 21.9956215],
                [105.5836376, 21.9958002],
                [105.5838468, 21.9960829],
                [105.5840879, 21.9963656],
                [105.58462, 21.9973486],
                [105.5851484, 21.9981949],
                [105.5854978, 21.9990204],
                [105.5855748, 21.9992023],
                [105.5858415, 21.9998902],
                [105.5860318, 22.0007232],
                [105.5862954, 22.0013308],
                [105.5867826, 22.0019202],
                [105.5872321, 22.0024229],
                [105.5875514, 22.0029954],
                [105.587684, 22.0035165],
                [105.5881366, 22.0047493],
                [105.5886803, 22.0054776],
                [105.5895039, 22.0062571],
                [105.5899352, 22.0068672],
                [105.5904417, 22.0077003],
                [105.591159, 22.0086024],
                [105.5921779, 22.0099379],
                [105.5928308, 22.0104727],
                [105.5956665, 22.0112966],
                [105.5970964, 22.011712],
                [105.5986456, 22.0120889],
                [105.6004183, 22.012413],
                [105.6011473, 22.0128452],
                [105.6017086, 22.0133129],
                [105.602234, 22.0140763],
                [105.6023831, 22.0140758],
                [105.602977, 22.0134299],
                [105.6036642, 22.0127837],
                [105.6043149, 22.012364],
                [105.6049851, 22.0121005],
                [105.6058234, 22.0119235],
                [105.6068115, 22.0119025],
                [105.607296, 22.0118311],
                [105.6076679, 22.0116036],
                [105.608189, 22.0113755],
                [105.6089717, 22.0112856],
                [105.6106694, 22.011488],
                [105.6119202, 22.0118661],
                [105.6125748, 22.0123333],
                [105.6133055, 22.0131655],
                [105.613829, 22.0134766],
                [105.6144636, 22.0136309],
                [105.6149665, 22.0135072],
                [105.6156353, 22.0129306],
                [105.616025, 22.0125117],
                [105.6162854, 22.0123716],
                [105.6164905, 22.0123708],
                [105.6168451, 22.012439],
                [105.6172378, 22.0127158],
                [105.6187919, 22.0142235],
                [105.6193915, 22.0148995],
                [105.6195798, 22.0153164],
                [105.6199959, 22.0166542],
                [105.6206718, 22.0183923],
                [105.6209368, 22.0183238],
                [105.6211537, 22.0183005],
                [105.6238373, 22.0170086],
                [105.6255012, 22.017764],
                [105.6269575, 22.0181933],
                [105.628993, 22.0188639],
                [105.6296797, 22.019086],
                [105.6307102, 22.0192574],
                [105.6316611, 22.0192537],
                [105.6329089, 22.0190939],
                [105.6347366, 22.018998],
                [105.6356125, 22.0189946],
                [105.6361651, 22.0190635],
                [105.636185, 22.0192587],
                [105.6359781, 22.0198454],
                [105.6357518, 22.0203258],
                [105.6351829, 22.0208784],
                [105.6350128, 22.0211631],
                [105.6351272, 22.0211805],
                [105.6361741, 22.0210876],
                [105.6366502, 22.0210858],
                [105.6369551, 22.0211379],
                [105.637356, 22.0213494],
                [105.6375474, 22.0215794],
                [105.6377037, 22.0224489],
                [105.6382029, 22.0233736],
                [105.6387411, 22.0244907],
                [105.6391245, 22.02504],
                [105.6393923, 22.0253232],
                [105.639851, 22.0256944],
                [105.6401385, 22.026084],
                [105.6403136, 22.0269183],
                [105.6405425, 22.0270063],
                [105.64098, 22.0268802],
                [105.6430711, 22.0260904],
                [105.6442875, 22.0255527],
                [105.6452184, 22.0250338],
                [105.6457278, 22.0246958],
                [105.6462268, 22.0248522],
                [105.6471603, 22.0249373],
                [105.648074, 22.0248627],
                [105.6486071, 22.024825],
                [105.6493119, 22.02484],
                [105.6498461, 22.0250866],
                [105.6504567, 22.0253328],
                [105.6511425, 22.0254012],
                [105.6517895, 22.0252742],
                [105.6521686, 22.0248997],
                [105.652623, 22.0243294],
                [105.6529805, 22.0233864],
                [105.6532295, 22.0236875],
                [105.6536527, 22.0246095],
                [105.6540576, 22.0257094],
                [105.6551312, 22.0272684],
                [105.6571391, 22.0290546],
                [105.6580372, 22.029726],
                [105.6583803, 22.0298135],
                [105.6589515, 22.0297757],
                [105.6592368, 22.0297034],
                [105.6618341, 22.0293666],
                [105.6626849, 22.0329482],
                [105.6632048, 22.0379382],
                [105.6633234, 22.0397283],
                [105.6637315, 22.0401481],
                [105.6653255, 22.0408369],
                [105.6666326, 22.0417917],
                [105.6673674, 22.0425167],
                [105.6679676, 22.0439259],
                [105.6680673, 22.0446436],
                [105.6680563, 22.0463846],
                [105.6681354, 22.047006],
                [105.6684227, 22.0473423],
                [105.6689383, 22.0476244],
                [105.670368, 22.0478673],
                [105.6725007, 22.047752],
                [105.6735858, 22.0476587],
                [105.6747833, 22.0471563],
                [105.6763386, 22.0457821],
                [105.6774204, 22.0449959],
                [105.6784089, 22.0445832],
                [105.6798736, 22.0441863],
                [105.6828969, 22.0431611],
                [105.6855205, 22.0421732],
                [105.6881997, 22.0408829],
                [105.6902342, 22.0401992],
                [105.6923249, 22.0393022],
                [105.6942426, 22.0381216],
                [105.6954375, 22.0371039],
                [105.6962167, 22.0360436],
                [105.696701, 22.0345403],
                [105.6970954, 22.0334196],
                [105.6971682, 22.0327264],
                [105.6969929, 22.0319278],
                [105.6969907, 22.0314837],
                [105.6971602, 22.0310921],
                [105.6981841, 22.0301817],
                [105.7004229, 22.028449],
                [105.702985, 22.0266793],
                [105.7046544, 22.025393],
                [105.7051091, 22.0249115],
                [105.7054489, 22.024306],
                [105.7059029, 22.0236822],
                [105.706263, 22.0233431],
                [105.7071561, 22.0229662],
                [105.7078411, 22.0227619],
                [105.709514, 22.0222632],
                [105.7132442, 22.0218207],
                [105.7154154, 22.0219001],
                [105.7163686, 22.0221268],
                [105.7182599, 22.0233088],
                [105.7200553, 22.0243669],
                [105.7203561, 22.0244633],
                [105.7218601, 22.0244906],
                [105.7231757, 22.0240949],
                [105.7234974, 22.0239982],
                [105.7248898, 22.0232389],
                [105.7260371, 22.0222888],
                [105.7270288, 22.0212513],
                [105.72737, 22.0210658],
                [105.7276922, 22.0207446],
                [105.7280894, 22.0202276],
                [105.7288431, 22.0186787],
                [105.7296372, 22.017556],
                [105.7308863, 22.0160582],
                [105.7316446, 22.0154154],
                [105.7324466, 22.0156763],
                [105.7325979, 22.015746],
                [105.7328378, 22.0159091],
                [105.7331783, 22.016107],
                [105.7335441, 22.0162929],
                [105.734098, 22.0164194],
                [105.7346896, 22.0164989],
                [105.7349532, 22.0164157],
                [105.7351029, 22.0163273],
                [105.7353815, 22.0163224],
                [105.7362403, 22.0166916],
                [105.7366236, 22.017134],
                [105.7370084, 22.0179316],
                [105.7371265, 22.0186773],
                [105.7372987, 22.0188186],
                [105.7379279, 22.0189756],
                [105.7394134, 22.0190044],
                [105.7407821, 22.0185542],
                [105.7411322, 22.0183534],
                [105.7410949, 22.0181798],
                [105.7411476, 22.0179013],
                [105.741472, 22.0176975],
                [105.7418514, 22.0176199],
                [105.7420413, 22.0176191],
                [105.7427186, 22.017444],
                [105.7440684, 22.0170293],
                [105.745, 22.0167408],
                [105.7453735, 22.0165155],
                [105.745364, 22.0163918],
                [105.7453778, 22.0162277],
                [105.7455166, 22.0161245],
                [105.745614, 22.0161106],
                [105.7458169, 22.0160684],
                [105.746043, 22.0159189],
                [105.7468566, 22.0149381],
                [105.7472338, 22.0142258],
                [105.7474225, 22.0139372],
                [105.7477753, 22.0135644],
                [105.7500659, 22.0136059],
                [105.7516072, 22.0130169],
                [105.7516149, 22.013014],
                [105.7518922, 22.0125765],
                [105.7520879, 22.0123897],
                [105.7523712, 22.0120165],
                [105.7527666, 22.0112443],
                [105.7529351, 22.0107122],
                [105.7530473, 22.0103662],
                [105.7529858, 22.0095165],
                [105.7528697, 22.0091451],
                [105.7528395, 22.0088264],
                [105.7527805, 22.0084547],
                [105.7527498, 22.0080565],
                [105.7527187, 22.0075783],
                [105.7527168, 22.0072064],
                [105.7527727, 22.0069937],
                [105.7528854, 22.0067275],
                [105.7531966, 22.0062213],
                [105.7533656, 22.0057955],
                [105.7535044, 22.0050776],
                [105.7535861, 22.0043333],
                [105.7536099, 22.00343],
                [105.7535627, 22.0031332],
                [105.7534919, 22.0026867],
                [105.7532903, 22.0023423],
                [105.7530881, 22.0018916],
                [105.7529726, 22.0016264],
                [105.7529721, 22.0015202],
                [105.753, 22.0014138],
                [105.7531131, 22.0012273],
                [105.7533121, 22.0010669],
                [105.753596, 22.0008],
                [105.7540785, 22.0003196],
                [105.7547279, 21.9997116],
                [105.7551093, 21.9992782],
                [105.7552174, 21.9991443],
                [105.7552723, 21.9990185],
                [105.7551807, 21.9980829],
                [105.7552276, 21.9974429],
                [105.7553663, 21.9967176],
                [105.7558721, 21.9957368],
                [105.7561948, 21.9946704],
                [105.7562421, 21.993817],
                [105.7561065, 21.9927499],
                [105.7559699, 21.992195],
                [105.7559513, 21.9920383],
                [105.7559552, 21.9920258],
                [105.7562072, 21.9917174],
                [105.7567887, 21.9913602],
                [105.7572682, 21.9910786],
                [105.7575125, 21.9909285],
                [105.7584224, 21.9903334],
                [105.7592809, 21.9895968],
                [105.7600385, 21.9889788],
                [105.7607957, 21.9882899],
                [105.7611861, 21.98778],
                [105.7617151, 21.9871158],
                [105.7623715, 21.9865455],
                [105.7628769, 21.9862123],
                [105.7631853, 21.9860348],
                [105.7636345, 21.9856344],
                [105.7635904, 21.9845675],
                [105.763549, 21.9818789],
                [105.7636421, 21.9810683],
                [105.7636883, 21.9807695],
                [105.7627273, 21.98],
                [105.7621781, 21.9795725],
                [105.7619953, 21.9792309],
                [105.7622403, 21.9785464],
                [105.7622382, 21.9781446],
                [105.7620845, 21.9778618],
                [105.7617908, 21.9774731],
                [105.76123, 21.9769322],
                [105.7608222, 21.9765559],
                [105.7607454, 21.9764145],
                [105.7607189, 21.9762019],
                [105.7607694, 21.9760555],
                [105.7607755, 21.9760262],
                [105.7608927, 21.9755626],
                [105.7611184, 21.9750889],
                [105.7614449, 21.9744966],
                [105.7618209, 21.9736677],
                [105.7619462, 21.9733834],
                [105.7619706, 21.9731943],
                [105.761868, 21.9730056],
                [105.7616897, 21.972841],
                [105.7614355, 21.9727241],
                [105.7611306, 21.9726073],
                [105.7606735, 21.9724912],
                [105.760419, 21.972327],
                [105.7603297, 21.9722405],
                [105.7603282, 21.9719571],
                [105.7603768, 21.9715554],
                [105.7604495, 21.9709173],
                [105.7604656, 21.9706296],
                [105.7604958, 21.9700907],
                [105.7605174, 21.969382],
                [105.7605906, 21.9688385],
                [105.7606644, 21.9684131],
                [105.7608151, 21.9681289],
                [105.7610418, 21.9678681],
                [105.7617238, 21.967369],
                [105.7622037, 21.9669889],
                [105.7627342, 21.9666087],
                [105.7629613, 21.966395],
                [105.7631872, 21.9659689],
                [105.7634384, 21.965519],
                [105.7637651, 21.9649507],
                [105.7640669, 21.9645006],
                [105.7643185, 21.9641215],
                [105.7645699, 21.9637189],
                [105.7648273, 21.9632724],
                [105.7650229, 21.9629032],
                [105.7652186, 21.9625544],
                [105.765612, 21.962184],
                [105.7661373, 21.9618541],
                [105.7668162, 21.9615235],
                [105.7674064, 21.9610294],
                [105.767712, 21.9606596],
                [105.7678422, 21.960393],
                [105.7680156, 21.9599623],
                [105.7682638, 21.9591528],
                [105.7685885, 21.9582916],
                [105.7688484, 21.9576149],
                [105.7691085, 21.9569792],
                [105.7694571, 21.9564659],
                [105.770309, 21.9556228],
                [105.7711832, 21.9548614],
                [105.7715861, 21.9545487],
                [105.7720963, 21.9542512],
                [105.7723979, 21.9541087],
                [105.7731652, 21.9538799],
                [105.7737572, 21.9537134],
                [105.7743273, 21.9535674],
                [105.7747436, 21.9534018],
                [105.7749626, 21.9532778],
                [105.7751157, 21.9531749],
                [105.7751812, 21.9531131],
                [105.7752464, 21.95299],
                [105.7752676, 21.9528262],
                [105.7752443, 21.9526012],
                [105.775221, 21.9523352],
                [105.7751527, 21.9518852],
                [105.774839, 21.9507405],
                [105.7747269, 21.9503112],
                [105.7744481, 21.9495246],
                [105.7743587, 21.9492385],
                [105.7743573, 21.9489724],
                [105.7743997, 21.9486856],
                [105.7745298, 21.9483781],
                [105.7748787, 21.9479261],
                [105.7750283, 21.9476969],
                [105.7752271, 21.9473923],
                [105.7756183, 21.9466537],
                [105.7758137, 21.9462434],
                [105.7760849, 21.9456383],
                [105.7761922, 21.9451875],
                [105.7762772, 21.9446755],
                [105.77629, 21.9440037],
                [105.7763001, 21.9430241],
                [105.77628, 21.9425788],
                [105.776156, 21.9421527],
                [105.7759904, 21.9417848],
                [105.7757208, 21.9413972],
                [105.7748434, 21.9403279],
                [105.7741749, 21.9396197],
                [105.7738188, 21.9391039],
                [105.7742377, 21.9386625],
                [105.774476, 21.9384517],
                [105.7746943, 21.9382255],
                [105.7750656, 21.9378553],
                [105.7755455, 21.93728],
                [105.7761019, 21.9366531],
                [105.7766693, 21.9359955],
                [105.7774117, 21.9352347],
                [105.7776951, 21.9348854],
                [105.7778036, 21.9346393],
                [105.7778243, 21.9343935],
                [105.7778444, 21.9340661],
                [105.777837, 21.9327152],
                [105.7777908, 21.9322652],
                [105.777602, 21.9318874],
                [105.7773584, 21.9314997],
                [105.7770927, 21.9310711],
                [105.7768496, 21.9307857],
                [105.7766068, 21.9305414],
                [105.7762983, 21.9303381],
                [105.7759241, 21.9301761],
                [105.7755719, 21.9299936],
                [105.7753514, 21.9298104],
                [105.7751089, 21.9296273],
                [105.7749761, 21.9294438],
                [105.7747773, 21.9292195],
                [105.7746217, 21.9288723],
                [105.7742004, 21.9280966],
                [105.7737469, 21.9274745],
                [105.7734154, 21.9270462],
                [105.7731725, 21.9268017],
                [105.7730183, 21.9267001],
                [105.7727103, 21.9265787],
                [105.7719631, 21.9264594],
                [105.7714793, 21.926298],
                [105.771193, 21.9261356],
                [105.7709724, 21.925932],
                [105.7708173, 21.9256666],
                [105.7707277, 21.9253395],
                [105.7707231, 21.92448],
                [105.7707428, 21.9240706],
                [105.7708995, 21.9225859],
                [105.7709418, 21.9222992],
                [105.7709186, 21.9220537],
                [105.7707855, 21.9218087],
                [105.7705206, 21.9215439],
                [105.770278, 21.9213199],
                [105.7698813, 21.9210352],
                [105.7691982, 21.9205676],
                [105.7687575, 21.9202628],
                [105.7685375, 21.9201819],
                [105.7683176, 21.9201215],
                [105.767834, 21.9200009],
                [105.767438, 21.919839],
                [105.7671513, 21.9195947],
                [105.7669961, 21.9193089],
                [105.7668513, 21.9189105],
                [105.7666945, 21.9183381],
                [105.7664714, 21.9176637],
                [105.7662266, 21.9170509],
                [105.7660051, 21.9166836],
                [105.7657401, 21.9163777],
                [105.7653427, 21.9159702],
                [105.7648572, 21.9154813],
                [105.7641868, 21.9146887],
                [105.7659522, 21.9135604],
                [105.7686113, 21.9120278],
                [105.770033, 21.9110057],
                [105.7713175, 21.9097272],
                [105.7716396, 21.9088315],
                [105.7718258, 21.9069967],
                [105.7717399, 21.9033266],
                [105.7716058, 21.901278],
                [105.7716534, 21.9001686],
                [105.7717924, 21.8991872],
                [105.7721148, 21.8980355],
                [105.7726671, 21.8963292],
                [105.773493, 21.8953915],
                [105.7755564, 21.893858],
                [105.7796832, 21.890663],
                [105.7812422, 21.8894275],
                [105.7812427, 21.8890861],
                [105.7810142, 21.8888297],
                [105.779824, 21.8884441],
                [105.7785419, 21.8882716],
                [105.7774885, 21.8883556],
                [105.7768935, 21.8880561],
                [105.7760243, 21.8874575],
                [105.7751542, 21.8874989],
                [105.7732765, 21.8874537],
                [105.7726816, 21.8872395],
                [105.7713548, 21.8864268],
                [105.770166, 21.8851449],
                [105.7701484, 21.8851118],
                [105.770221, 21.8850006],
                [105.7703665, 21.8849235],
                [105.7705327, 21.8849043],
                [105.7712807, 21.8848085],
                [105.7717482, 21.8847073],
                [105.7720891, 21.8844502],
                [105.7720189, 21.8839925],
                [105.7720297, 21.884074],
                [105.7712813, 21.8839539],
                [105.7708619, 21.8829147],
                [105.7709607, 21.8825452],
                [105.7714305, 21.8818617],
                [105.7718465, 21.8815312],
                [105.7728575, 21.8773178],
                [105.7732722, 21.8757394],
                [105.772129, 21.8747136],
                [105.7709856, 21.8737732],
                [105.7703912, 21.8732603],
                [105.7702548, 21.8726626],
                [105.7703022, 21.8716385],
                [105.7705324, 21.8708707],
                [105.7704873, 21.8704011],
                [105.7692978, 21.8697167],
                [105.7686046, 21.8688041],
                [105.7683404, 21.867608],
                [105.7683867, 21.8667709],
                [105.7683887, 21.865576],
                [105.7684359, 21.8647226],
                [105.7686665, 21.8636986],
                [105.7689422, 21.863059],
                [105.7695848, 21.8620782],
                [105.7699064, 21.8614812],
                [105.7699534, 21.8606705],
                [105.7697734, 21.8587073],
                [105.7693183, 21.8569996],
                [105.7690874, 21.8566509],
                [105.7687244, 21.8561026],
                [105.7684959, 21.8558888],
                [105.7672153, 21.8549909],
                [105.7660731, 21.8534957],
                [105.7655254, 21.8523853],
                [105.7652976, 21.8517022],
                [105.7655737, 21.8509344],
                [105.765849, 21.8505081],
                [105.7658497, 21.850124],
                [105.7656213, 21.8497823],
                [105.7648444, 21.848885],
                [105.7643877, 21.8482442],
                [105.764206, 21.8473477],
                [105.764208, 21.8461102],
                [105.7640714, 21.8455979],
                [105.7624243, 21.8449554],
                [105.7610061, 21.8443133],
                [105.760686, 21.8440567],
                [105.7605959, 21.8432458],
                [105.7606437, 21.8420083],
                [105.760839, 21.8407734],
                [105.7609319, 21.8399821],
                [105.7609939, 21.8396891],
                [105.7611763, 21.8392381],
                [105.7612479, 21.8389376],
                [105.7612608, 21.8386237],
                [105.7612156, 21.8383512],
                [105.7611559, 21.8381331],
                [105.7610085, 21.8379292],
                [105.7608319, 21.8377117],
                [105.7604499, 21.8373724],
                [105.7601415, 21.8371283],
                [105.7595399, 21.836749],
                [105.7592754, 21.8365047],
                [105.7590472, 21.8362124],
                [105.7587077, 21.8356138],
                [105.7584265, 21.8349876],
                [105.7583224, 21.8346742],
                [105.7583063, 21.8343742],
                [105.7583186, 21.833924],
                [105.7583314, 21.8335828],
                [105.7583221, 21.8332077],
                [105.7582769, 21.8329487],
                [105.7582169, 21.8326762],
                [105.7581421, 21.8323764],
                [105.7579795, 21.8320224],
                [105.7578753, 21.8316955],
                [105.75786, 21.8315318],
                [105.7578888, 21.8314635],
                [105.7579908, 21.8313948],
                [105.7582097, 21.8313121],
                [105.7584871, 21.8312425],
                [105.7587064, 21.8312142],
                [105.7588524, 21.8311726],
                [105.7590277, 21.8311445],
                [105.7592908, 21.8311161],
                [105.7594955, 21.8311015],
                [105.7596998, 21.8310323],
                [105.7598454, 21.8309225],
                [105.7599325, 21.8307993],
                [105.7600337, 21.8305806],
                [105.7601705, 21.8301774],
                [105.760516, 21.8291391],
                [105.7607614, 21.828524],
                [105.7611517, 21.8276765],
                [105.7613031, 21.8272734],
                [105.7614478, 21.8269726],
                [105.7616943, 21.8265757],
                [105.762028, 21.8260832],
                [105.7622745, 21.8257],
                [105.7623608, 21.825413],
                [105.7623885, 21.8251265],
                [105.7623864, 21.8247446],
                [105.7623042, 21.8243834],
                [105.7620815, 21.8237706],
                [105.7615364, 21.8229955],
                [105.761021, 21.8223158],
                [105.7604698, 21.8217894],
                [105.7602669, 21.8208836],
                [105.7602228, 21.8198592],
                [105.7604072, 21.8190487],
                [105.7609126, 21.8179399],
                [105.7616037, 21.8170048],
                [105.7619281, 21.816882],
                [105.7622491, 21.8167577],
                [105.762672, 21.8165512],
                [105.7632406, 21.8162348],
                [105.7637363, 21.815946],
                [105.7646179, 21.8153758],
                [105.7653755, 21.8148539],
                [105.7658126, 21.8145653],
                [105.766191, 21.8142498],
                [105.7664968, 21.8140028],
                [105.7668167, 21.8136603],
                [105.7671946, 21.8132493],
                [105.7674419, 21.8130162],
                [105.7676459, 21.8128925],
                [105.7678798, 21.8128641],
                [105.7680261, 21.812877],
                [105.768231, 21.8129171],
                [105.7683628, 21.8129574],
                [105.7686117, 21.8130108],
                [105.7692707, 21.813226],
                [105.7695564, 21.8133133],
                [105.7698926, 21.8133118],
                [105.770287, 21.8132281],
                [105.7705638, 21.8130358],
                [105.7707383, 21.8128576],
                [105.7707814, 21.8127074],
                [105.7708236, 21.812407],
                [105.7708356, 21.8123433],
                [105.770915, 21.8121377],
                [105.7723055, 21.8122229],
                [105.772617, 21.8122593],
                [105.7734867, 21.8122605],
                [105.7746316, 21.8118779],
                [105.7757307, 21.8115381],
                [105.7774705, 21.8112417],
                [105.7787983, 21.8109448],
                [105.7793022, 21.810732],
                [105.7802646, 21.8100079],
                [105.781044, 21.8091127],
                [105.781594, 21.808644],
                [105.7825559, 21.8081758],
                [105.7843895, 21.8063432],
                [105.7862232, 21.8043825],
                [105.7866156, 21.804335],
                [105.7922415, 21.8047956],
                [105.7974592, 21.8048875],
                [105.7985592, 21.8049344],
                [105.7995187, 21.8049753],
                [105.8002958, 21.8057444],
                [105.8007985, 21.8063851],
                [105.8009806, 21.8070682],
                [105.8009796, 21.807751],
                [105.8004737, 21.8095],
                [105.800747, 21.8104818],
                [105.8017975, 21.8121475],
                [105.8027107, 21.8137703],
                [105.8029846, 21.8143253],
                [105.8029841, 21.8146241],
                [105.8027085, 21.8154347],
                [105.8026158, 21.81616],
                [105.8027979, 21.8169711],
                [105.8031627, 21.817953],
                [105.8031157, 21.8188918],
                [105.8026548, 21.821153],
                [105.8025162, 21.8221344],
                [105.8027438, 21.8231162],
                [105.8033368, 21.8245253],
                [105.8038386, 21.825806],
                [105.8040646, 21.8279401],
                [105.8041981, 21.8307568],
                [105.8047912, 21.8322512],
                [105.8057963, 21.8337886],
                [105.8072131, 21.8355827],
                [105.8093151, 21.838487],
                [105.8108689, 21.8406226],
                [105.812881, 21.8424172],
                [105.8147775, 21.8437596],
                [105.8173031, 21.8449331],
                [105.8189508, 21.8455752],
                [105.819958, 21.8457471],
                [105.8220642, 21.8460481],
                [105.8240789, 21.846221],
                [105.8264602, 21.8462662],
                [105.8290706, 21.8460982],
                [105.8318641, 21.8461011],
                [105.8338791, 21.8459324],
                [105.8343527, 21.8457983],
                [105.8366645, 21.8452357],
                [105.8380944, 21.8452285],
                [105.8390768, 21.8451125],
                [105.8403925, 21.8451668],
                [105.841096, 21.8451729],
                [105.8416372, 21.8451974],
                [105.842573, 21.8451654],
                [105.843099, 21.8450944],
                [105.8435664, 21.8449693],
                [105.8443406, 21.8448289],
                [105.8448304, 21.8448059],
                [105.8451814, 21.8448042],
                [105.8456491, 21.8447608],
                [105.8460291, 21.8447179],
                [105.8463942, 21.8446479],
                [105.8467883, 21.8445094],
                [105.8478081, 21.844162],
                [105.8486352, 21.8443316],
                [105.8507405, 21.8450163],
                [105.8520222, 21.8453589],
                [105.8556588, 21.8458551],
                [105.8561559, 21.8458252],
                [105.8572512, 21.8455468],
                [105.8579233, 21.8454478],
                [105.8589616, 21.8454559],
                [105.8598179, 21.8455811],
                [105.860316, 21.8457149],
                [105.8606975, 21.8459176],
                [105.8612709, 21.8464329],
                [105.8615509, 21.8467862],
                [105.8626974, 21.848956],
                [105.862978, 21.8494047],
                [105.8641557, 21.8506946],
                [105.8645835, 21.8512925],
                [105.8646651, 21.8515036],
                [105.864711, 21.8518444],
                [105.8647008, 21.8525674],
                [105.8646165, 21.8531272],
                [105.8644307, 21.8538648],
                [105.8641646, 21.8546097],
                [105.8636782, 21.8564266],
                [105.8635055, 21.8568776],
                [105.8627895, 21.8581979],
                [105.8625878, 21.8587309],
                [105.862517, 21.8591133],
                [105.8624636, 21.8599593],
                [105.8625829, 21.8603407],
                [105.8628625, 21.8606257],
                [105.8629798, 21.8606659],
                [105.8634622, 21.8606362],
                [105.8647244, 21.8602606],
                [105.8654851, 21.8601686],
                [105.8658064, 21.8600851],
                [105.8665944, 21.8597672],
                [105.8672066, 21.8594365],
                [105.8692685, 21.8581639],
                [105.8702019, 21.8577087],
                [105.8722993, 21.8562907],
                [105.8737369, 21.855787],
                [105.8745575, 21.8560419],
                [105.8754967, 21.8565552],
                [105.8767726, 21.8571215],
                [105.8771755, 21.8572488],
                [105.878054, 21.8573805],
                [105.878918, 21.857567],
                [105.8807062, 21.858185],
                [105.8816162, 21.8587122],
                [105.8819101, 21.8589424],
                [105.8821604, 21.8592004],
                [105.8825235, 21.8598718],
                [105.8849006, 21.8626593],
                [105.884954, 21.8627664],
                [105.8850792, 21.8629225],
                [105.885522, 21.8635749],
                [105.8859208, 21.8642004],
                [105.8864379, 21.8650296],
                [105.8868656, 21.8656139],
                [105.8872563, 21.8661097],
                [105.8875362, 21.8664356],
                [105.8880228, 21.8670743],
                [105.8883327, 21.8674954],
                [105.8885831, 21.8677805],
                [105.8888193, 21.8681476],
                [105.8889376, 21.8683515],
                [105.8889831, 21.868597],
                [105.8889932, 21.8690675],
                [105.8889954, 21.8694085],
                [105.8889789, 21.8697377],
                [105.8891739, 21.8703781],
                [105.8892919, 21.8710876],
                [105.8895768, 21.8715399],
                [105.890774, 21.8731361],
                [105.8916287, 21.8748385],
                [105.892655, 21.8760358],
                [105.89525, 21.8783243],
                [105.8963907, 21.8791493],
                [105.8981305, 21.8803471],
                [105.8987859, 21.8817301],
                [105.8999257, 21.8840709],
                [105.9005238, 21.8856135],
                [105.9011795, 21.8865978],
                [105.9019493, 21.8875556],
                [105.9034607, 21.8890455],
                [105.9055428, 21.8908814],
                [105.9070466, 21.8921341],
                [105.9079385, 21.8928772],
                [105.9095072, 21.8943937],
                [105.9104484, 21.895192],
                [105.9109905, 21.8955378],
                [105.9115896, 21.8957509],
                [105.9147857, 21.8958856],
                [105.9178964, 21.8960201],
                [105.9196944, 21.8958615],
                [105.9228907, 21.8954376],
                [105.9244034, 21.8953585],
                [105.9259445, 21.8953592],
                [105.9280847, 21.8955462],
                [105.9300251, 21.8958662],
                [105.9329069, 21.8970905],
                [105.9351894, 21.8983944],
                [105.9370439, 21.8994854],
                [105.9397762, 21.9004982],
                [105.9405269, 21.9007571],
                [105.9420221, 21.9011851],
                [105.9443014, 21.9018473],
                [105.9448735, 21.9020622],
                [105.9453867, 21.9022503],
                [105.9456725, 21.9023238],
                [105.9460117, 21.9023868],
                [105.9465377, 21.9025442],
                [105.9467271, 21.9027662],
                [105.9475161, 21.9036905],
                [105.9486001, 21.9050734],
                [105.9497698, 21.9063503],
                [105.9511394, 21.9073877],
                [105.9524805, 21.9079731],
                [105.9540216, 21.908399],
                [105.9553629, 21.908878],
                [105.9561903, 21.9095962],
                [105.9571603, 21.9106601],
                [105.9579592, 21.9118303],
                [105.9589008, 21.9128409],
                [105.9595856, 21.9136122],
                [105.9599849, 21.9147558],
                [105.9600987, 21.9157929],
                [105.9602696, 21.9168299],
                [105.9605263, 21.9178405],
                [105.9606973, 21.918718],
                [105.9608113, 21.9191169],
                [105.9607255, 21.9196488],
                [105.96044, 21.9202336],
                [105.960183, 21.9207387],
                [105.9601829, 21.920925],
                [105.9604111, 21.9214036],
                [105.9616382, 21.9225207],
                [105.9622089, 21.9229197],
                [105.9625514, 21.9230793],
                [105.963265, 21.9231327],
                [105.9641499, 21.923],
                [105.9646065, 21.9230001],
                [105.9653487, 21.9231331],
                [105.9663191, 21.9233726],
                [105.9670897, 21.9235324],
                [105.9675179, 21.9235856],
                [105.9687737, 21.9236124],
                [105.9694874, 21.9237455],
                [105.9703721, 21.9240116],
                [105.9708287, 21.9245701],
                [105.9709428, 21.9247563],
                [105.9706001, 21.9255805],
                [105.969715, 21.9271758],
                [105.9694579, 21.9277607],
                [105.9692294, 21.9283457],
                [105.9692293, 21.9288244],
                [105.969429, 21.9294626],
                [105.9694289, 21.9300475],
                [105.9696284, 21.9313771],
                [105.9698832, 21.9329839],
                [105.9702271, 21.9351532],
                [105.9703412, 21.9356584],
                [105.9705695, 21.9361105],
                [105.9713399, 21.9372008],
                [105.9723675, 21.9381849],
                [105.9738802, 21.9392222],
                [105.9745039, 21.9395223],
                [105.974623, 21.9395526],
                [105.9748429, 21.9395922],
                [105.9750771, 21.9396181],
                [105.9753698, 21.9396164],
                [105.9757354, 21.9395869],
                [105.9761889, 21.9395569],
                [105.9764374, 21.9395009],
                [105.9766416, 21.9394179],
                [105.9768166, 21.9393214],
                [105.9773123, 21.9390592],
                [105.9777639, 21.9387564],
                [105.9781864, 21.938481],
                [105.9786309, 21.9382125],
                [105.9791111, 21.937814],
                [105.9795184, 21.9374569],
                [105.9800564, 21.9369625],
                [105.9805222, 21.9365915],
                [105.9809443, 21.9362615],
                [105.9812212, 21.9360962],
                [105.9814694, 21.9359992],
                [105.9817761, 21.9359292],
                [105.9821417, 21.9358861],
                [105.9827414, 21.9358552],
                [105.9832827, 21.9358383],
                [105.9835391, 21.9358709],
                [105.9840228, 21.9359908],
                [105.9847564, 21.9362729],
                [105.9852991, 21.9364469],
                [105.9856461, 21.936513],
                [105.9866693, 21.9369635],
                [105.9875543, 21.9373625],
                [105.988582, 21.9374688],
                [105.9927497, 21.9374159],
                [105.9943197, 21.9372564],
                [105.9956044, 21.9367778],
                [105.9969746, 21.9359536],
                [105.9987445, 21.9345709],
                [106.0005713, 21.9328159],
                [106.0007713, 21.9327362],
                [106.0019415, 21.9337731],
                [106.0033689, 21.9349431],
                [106.0042253, 21.935475],
                [106.0057667, 21.9360066],
                [106.007451, 21.936432],
                [106.0090782, 21.9366713],
                [106.0102201, 21.936884],
                [106.0116759, 21.9372296],
                [106.0123325, 21.9374953],
                [106.0127607, 21.9379209],
                [106.0133033, 21.9389312],
                [106.0135602, 21.9394364],
                [106.0139028, 21.9398618],
                [106.0146736, 21.9407925],
                [106.0149823, 21.9414593],
                [106.0152068, 21.9418437],
                [106.0154262, 21.9420599],
                [106.0158973, 21.9426375],
                [106.0172679, 21.9444422],
                [106.017613, 21.9453224],
                [106.017675, 21.9454121],
                [106.0178689, 21.9458112],
                [106.018022, 21.9463323],
                [106.0190886, 21.9471299],
                [106.0193867, 21.9474619],
                [106.019529, 21.9477656],
                [106.0196082, 21.9479683],
                [106.019609, 21.9480844],
                [106.0195953, 21.9483456],
                [106.0195186, 21.9485057],
                [106.0193959, 21.9487385],
                [106.0192568, 21.9488845],
                [106.0188712, 21.9493948],
                [106.018781, 21.9495189],
                [106.0184715, 21.9497],
                [106.0183289, 21.949966],
                [106.0182432, 21.950418],
                [106.0183004, 21.9513753],
                [106.0185005, 21.9527314],
                [106.0187005, 21.9537152],
                [106.019221, 21.9547899],
                [106.0193152, 21.954891],
                [106.019543, 21.9551796],
                [106.0199966, 21.9555105],
                [106.0209811, 21.9560557],
                [106.0218885, 21.9566885],
                [106.0222015, 21.9569478],
                [106.0223583, 21.9571064],
                [106.0224374, 21.95728],
                [106.0224855, 21.9574829],
                [106.0225028, 21.9577149],
                [106.0225044, 21.9579325],
                [106.0224678, 21.9582664],
                [106.0224085, 21.958673],
                [106.0223945, 21.9589052],
                [106.0224115, 21.9590938],
                [106.0224598, 21.9593256],
                [106.022555, 21.9595717],
                [106.0226968, 21.9598175],
                [106.0228542, 21.9600487],
                [106.0231688, 21.9605255],
                [106.0235865, 21.9612483],
                [106.0239019, 21.9618267],
                [106.0239914, 21.9620023],
                [106.0246026, 21.9645968],
                [106.0297236, 21.9678599],
                [106.0303332, 21.9667595],
                [106.0307932, 21.966188],
                [106.0316366, 21.9654737],
                [106.0333619, 21.9643306],
                [106.0347804, 21.9639018],
                [106.0365441, 21.9634372],
                [106.0388445, 21.9630795],
                [106.040033, 21.962615],
                [106.0416048, 21.9619005],
                [106.0421415, 21.9614361],
                [106.0427547, 21.9604717],
                [106.0431763, 21.9600431],
                [106.0439047, 21.959793],
                [106.0445949, 21.9598642],
                [106.0459754, 21.9603281],
                [106.0465888, 21.9603635],
                [106.0470871, 21.9597921],
                [106.0483135, 21.9583634],
                [106.0488114, 21.9570419],
                [106.049386, 21.9551849],
                [106.0497426, 21.9541462],
                [106.0501127, 21.9537184],
                [106.0505644, 21.9530969],
                [106.0509539, 21.9524757],
                [106.0513123, 21.9518547],
                [106.0515165, 21.9514281],
                [106.0516184, 21.951176],
                [106.0517816, 21.9508077],
                [106.0517955, 21.9507613],
                [106.0518626, 21.9505365],
                [106.0519021, 21.9502656],
                [106.0518978, 21.9496856],
                [106.0519159, 21.9493181],
                [106.0519127, 21.9488928],
                [106.0519936, 21.9486023],
                [106.0521421, 21.947654],
                [106.0523026, 21.9469182],
                [106.0524249, 21.9466274],
                [106.0525885, 21.9463171],
                [106.0527734, 21.9460646],
                [106.0531485, 21.9455425],
                [106.0548644, 21.943899],
                [106.0556529, 21.9433135],
                [106.0559156, 21.9432989],
                [106.0565162, 21.9431791],
                [106.0568888, 21.9430077],
                [106.0571982, 21.9428654],
                [106.0578999, 21.9423969],
                [106.0587874, 21.9418112],
                [106.059035, 21.9416355],
                [106.0593449, 21.9414982],
                [106.0597898, 21.94136],
                [106.0603495, 21.9413177],
                [106.0609095, 21.9413334],
                [106.0617395, 21.9413861],
                [106.0627776, 21.9415534],
                [106.0633504, 21.9416765],
                [106.0633675, 21.9416802],
                [106.0643981, 21.9419295],
                [106.0654993, 21.9422124],
                [106.0663509, 21.9423809],
                [106.0670985, 21.9425308],
                [106.0680017, 21.9426602],
                [106.0687898, 21.9426744],
                [106.0695565, 21.942592],
                [106.0696578, 21.9425673],
                [106.0711186, 21.9423215],
                [106.0731503, 21.9419992],
                [106.0740705, 21.9421774],
                [106.0756807, 21.9423552],
                [106.0775975, 21.9422829],
                [106.0805112, 21.9423886],
                [106.082198, 21.9423164],
                [106.083233, 21.942173],
                [106.0845746, 21.9417795],
                [106.0859162, 21.9415288],
                [106.0872578, 21.9412424],
                [106.0887143, 21.9408132],
                [106.0897494, 21.9407054],
                [106.0908995, 21.9407048],
                [106.0918964, 21.9409185],
                [106.0925484, 21.9414181],
                [106.0932387, 21.9419176],
                [106.0943502, 21.9413456],
                [106.0953466, 21.9408809],
                [106.0960363, 21.9403805],
                [106.097377, 21.9388442],
                [106.0986133, 21.9375758],
                [106.0985894, 21.9376758],
                [106.0986511, 21.9380107],
                [106.0989252, 21.938512],
                [106.0993238, 21.9389962],
                [106.099702, 21.9393243],
                [106.1002172, 21.9396261],
                [106.1019606, 21.9402421],
                [106.1024773, 21.9405147],
                [106.1028999, 21.9408601],
                [106.1036076, 21.941648],
                [106.1039544, 21.9419498],
                [106.105211, 21.9426238],
                [106.1056974, 21.9429463],
                [106.1075594, 21.9448883],
                [106.1092721, 21.9461948],
                [106.109963, 21.9468829],
                [106.1106124, 21.9477006],
                [106.1116984, 21.9493579],
                [106.1118335, 21.9496451],
                [106.1118753, 21.9498862],
                [106.1117931, 21.9503102],
                [106.1115378, 21.950724],
                [106.1113204, 21.9509802],
                [106.1105278, 21.9517048],
                [106.10981, 21.9521078],
                [106.1093509, 21.9522261],
                [106.1086907, 21.9522892],
                [106.1056524, 21.9523487],
                [106.1050741, 21.9524832],
                [106.103723, 21.95299],
                [106.1033071, 21.9530882],
                [106.1027615, 21.9530678],
                [106.1022463, 21.9528664],
                [106.1017844, 21.9525145],
                [106.1003402, 21.9512086],
                [106.0998703, 21.9509234],
                [106.0996822, 21.9508877],
                [106.099278, 21.9509313],
                [106.0988691, 21.951091],
                [106.0986885, 21.9512098],
                [106.0983791, 21.9515371],
                [106.0980271, 21.9522563],
                [106.0978749, 21.9529791],
                [106.0978241, 21.9538854],
                [106.0979071, 21.9544986],
                [106.0983832, 21.9566053],
                [106.0987062, 21.9587412],
                [106.0988223, 21.9591513],
                [106.0989793, 21.9593969],
                [106.0993433, 21.9597388],
                [106.0998306, 21.9600406],
                [106.100329, 21.9602419],
                [106.1017027, 21.9605832],
                [106.1024213, 21.9606712],
                [106.103807, 21.96076],
                [106.104279, 21.9608513],
                [106.1045912, 21.9610603],
                [106.1051378, 21.9618599],
                [106.1054625, 21.9621981],
                [106.1060794, 21.9625726],
                [106.1065812, 21.9627454],
                [106.1071335, 21.9628387],
                [106.1076852, 21.9628688],
                [106.1086927, 21.9627834],
                [106.1094998, 21.9625423],
                [106.1106577, 21.9620774],
                [106.1111848, 21.9619696],
                [106.1114998, 21.9620122],
                [106.1116598, 21.9620772],
                [106.1121407, 21.9624716],
                [106.1125195, 21.9630353],
                [106.1127101, 21.9636314],
                [106.1127103, 21.9640319],
                [106.1123734, 21.9651791],
                [106.1123661, 21.9655751],
                [106.1124585, 21.9657798],
                [106.1126305, 21.9659801],
                [106.1137013, 21.9669332],
                [106.1138721, 21.9672177],
                [106.1139761, 21.9676685],
                [106.1139043, 21.9690536],
                [106.1140079, 21.970189],
                [106.114009, 21.9707261],
                [106.1139042, 21.9713813],
                [106.1133989, 21.9730246],
                [106.1132859, 21.9735256],
                [106.1132759, 21.9740096],
                [106.1133553, 21.9745163],
                [106.1135933, 21.9752985],
                [106.1139062, 21.9759867],
                [106.1142769, 21.9765181],
                [106.1151911, 21.9774946],
                [106.1153396, 21.9777324],
                [106.1153992, 21.9779866],
                [106.1152939, 21.9785595],
                [106.1149944, 21.9792824],
                [106.1139546, 21.9809664],
                [106.1135182, 21.9817901],
                [106.1131512, 21.9827981],
                [106.1130462, 21.9834487],
                [106.1131042, 21.9840876],
                [106.1136038, 21.9857821],
                [106.1137882, 21.9866573],
                [106.1138171, 21.9871809],
                [106.1137313, 21.989509],
                [106.1137576, 21.9906856],
                [106.1139277, 21.9914336],
                [106.1144043, 21.992407],
                [106.1144951, 21.9926869],
                [106.1145003, 21.9931321],
                [106.1144118, 21.9934786],
                [106.1141513, 21.9939279],
                [106.1134816, 21.9945623],
                [106.1124482, 21.9953503],
                [106.1112094, 21.9961461],
                [106.1099283, 21.9967359],
                [106.1090672, 21.9969949],
                [106.1076944, 21.9973113],
                [106.1075707, 21.9973967],
                [106.1075211, 21.9975072],
                [106.1075502, 21.9977063],
                [106.1076657, 21.9978828],
                [106.108916, 21.9990143],
                [106.1095662, 21.9998164],
                [106.109921, 22.0006058],
                [106.1101293, 22.0021676],
                [106.1101265, 22.0032133],
                [106.1103703, 22.0037394],
                [106.1103962, 22.0042671],
                [106.1103326, 22.0044909],
                [106.1101174, 22.0048375],
                [106.1095758, 22.0052471],
                [106.1097674, 22.0060682],
                [106.1103157, 22.0065314],
                [106.1107118, 22.0070159],
                [106.1108239, 22.0074212],
                [106.1108712, 22.0079081],
                [106.110899, 22.0100498],
                [106.1107492, 22.0104366],
                [106.1104052, 22.0110073],
                [106.1103201, 22.0112921],
                [106.1103241, 22.0117997],
                [106.1105353, 22.0123363],
                [106.1110635, 22.0130026],
                [106.1111752, 22.013347],
                [106.1112012, 22.0138949],
                [106.1110962, 22.0144033],
                [106.1107313, 22.0151162],
                [106.1093248, 22.0191861],
                [106.1085551, 22.0210795],
                [106.1081016, 22.0216103],
                [106.1064423, 22.0226062],
                [106.1054677, 22.0233843],
                [106.1047114, 22.0242014],
                [106.1042377, 22.0249559],
                [106.1031685, 22.0261913],
                [106.1023487, 22.0272526],
                [106.1017207, 22.0277644],
                [106.100765, 22.0281768],
                [106.0986266, 22.0292367],
                [106.0974318, 22.0297319],
                [106.0970636, 22.0300389],
                [106.0966988, 22.0307519],
                [106.096507, 22.0313217],
                [106.0963072, 22.032267],
                [106.0960419, 22.0346036],
                [106.0957115, 22.0355701],
                [106.0955641, 22.036241],
                [106.0956543, 22.0366262],
                [106.095962, 22.0369693],
                [106.0964443, 22.0373315],
                [106.0973627, 22.0377313],
                [106.098727, 22.0380166],
                [106.0994721, 22.0385394],
                [106.1004186, 22.0397512],
                [106.1009462, 22.0403364],
                [106.1012556, 22.0409028],
                [106.1015367, 22.0420073],
                [106.1024468, 22.0441532],
                [106.1027263, 22.0450548],
                [106.1027086, 22.0455828],
                [106.1024979, 22.0465181],
                [106.1020908, 22.047414],
                [106.1016073, 22.0483207],
                [106.1008573, 22.0499702],
                [106.1006238, 22.050784],
                [106.1001412, 22.0517921],
                [106.0996509, 22.0532166],
                [106.0994389, 22.0539894],
                [106.0992619, 22.0550564],
                [106.0992719, 22.0563557],
                [106.099344, 22.0572282],
                [106.0996011, 22.058069],
                [106.1002206, 22.0592424],
                [106.1011224, 22.060292],
                [106.1012029, 22.0608294],
                [106.1010566, 22.0616425],
                [106.100483, 22.0635751],
                [106.0989496, 22.0668235],
                [106.0988368, 22.067748],
                [106.0988608, 22.0680321],
                [106.0989953, 22.0684981],
                [106.0999672, 22.0701564],
                [106.1002508, 22.0715857],
                [106.1005585, 22.0719085],
                [106.1010186, 22.0722099],
                [106.1024164, 22.0725456],
                [106.105514, 22.0728292],
                [106.106639, 22.073116],
                [106.1072526, 22.0735383],
                [106.1076084, 22.0744291],
                [106.1077949, 22.0759707],
                [106.1079595, 22.0761219],
                [106.1081123, 22.0761412],
                [106.10833, 22.0760991],
                [106.1093475, 22.0751989],
                [106.1112555, 22.0738663],
                [106.1122532, 22.0732302],
                [106.1132325, 22.0730204],
                [106.1142655, 22.0726988],
                [106.114463, 22.0728599],
                [106.1148852, 22.0738924],
                [106.1155228, 22.0745783],
                [106.1164442, 22.0753232],
                [106.1173233, 22.0762308],
                [106.1177543, 22.0769689],
                [106.1182963, 22.0807514],
                [106.1185669, 22.0818864],
                [106.1189658, 22.0827161],
                [106.1200511, 22.0834903],
                [106.1211917, 22.0843758],
                [106.1226359, 22.0850561],
                [106.1233129, 22.0851936],
                [106.1243599, 22.0852675],
                [106.1283594, 22.0851283],
                [106.1303458, 22.0854394],
                [106.1318208, 22.0858658],
                [106.1329995, 22.0860606],
                [106.1341772, 22.0861132],
                [106.1345692, 22.0860496],
                [106.1354148, 22.0854753],
                [106.1362091, 22.0852973],
                [106.1391992, 22.0857028],
                [106.1393514, 22.0856611],
                [106.1398054, 22.0851911],
                [106.1403355, 22.0833196],
                [106.1407331, 22.0826165],
                [106.1409278, 22.0824323],
                [106.1412969, 22.0822267],
                [106.1422539, 22.0819561],
                [106.1450422, 22.081693],
                [106.146306, 22.0816232],
                [106.1477327, 22.081461],
                [106.1498909, 22.0814459],
                [106.1520933, 22.0815116],
                [106.1565075, 22.0814702],
                [106.1570965, 22.081527],
                [106.1572654, 22.0821855],
                [106.1574508, 22.0835242],
                [106.1576996, 22.084639],
                [106.1586144, 22.0858811],
                [106.1590611, 22.0871772],
                [106.1592204, 22.0880084],
                [106.1594784, 22.0888897],
                [106.1602532, 22.0903256],
                [106.1607411, 22.0913372],
                [106.1613274, 22.0923786],
                [106.1613333, 22.0931094],
                [106.1611881, 22.0940037],
                [106.1606505, 22.0962508],
                [106.1608088, 22.0969399],
                [106.1609419, 22.0972232],
                [106.1616595, 22.0983246],
                [106.1620135, 22.0989514],
                [106.1622618, 22.099985],
                [106.162358, 22.1010806],
                [106.1627068, 22.1024078],
                [106.1631044, 22.1030345],
                [106.1634126, 22.1033977],
                [106.1641357, 22.1038188],
                [106.1653371, 22.1040945],
                [106.166594, 22.1044814],
                [106.1674936, 22.1051652],
                [106.1682191, 22.1058909],
                [106.1683972, 22.1063362],
                [106.1684653, 22.1066606],
                [106.1685134, 22.1085381],
                [106.1684812, 22.1099189],
                [106.1685723, 22.1103851],
                [106.169333, 22.1114252],
                [106.1702957, 22.111804],
                [106.1723068, 22.1124393],
                [106.17318, 22.1125344],
                [106.1737466, 22.1124898],
                [106.175036, 22.1115366],
                [106.1753378, 22.111108],
                [106.1758991, 22.1104544],
                [106.1762034, 22.1103099],
                [106.176421, 22.1102679],
                [106.1766397, 22.1103475],
                [106.1767722, 22.1105496],
                [106.1769844, 22.1124563],
                [106.1771849, 22.1129827],
                [106.1789618, 22.1142691],
                [106.1799169, 22.1150439],
                [106.181275, 22.1158055],
                [106.1825017, 22.1164464],
                [106.1845137, 22.1171625],
                [106.185225, 22.1174721],
                [106.1852949, 22.1180197],
                [106.1857208, 22.1193971],
                [106.1859247, 22.1203092],
                [106.1859647, 22.1211717],
                [106.1855582, 22.1221288],
                [106.1848283, 22.1234943],
                [106.184608, 22.1245414],
                [106.1843521, 22.1252335],
                [106.1839432, 22.1258861],
                [106.1837272, 22.1261313],
                [106.1819175, 22.1274741],
                [106.1810737, 22.128272],
                [106.1810328, 22.1285971],
                [106.1810797, 22.1289825],
                [106.1813231, 22.1294071],
                [106.1825231, 22.130789],
                [106.1837076, 22.1316128],
                [106.1854169, 22.1325952],
                [106.1865787, 22.1333176],
                [106.1877069, 22.1339082],
                [106.1882591, 22.1347568],
                [106.1885937, 22.1356477],
                [106.188776, 22.1365803],
                [106.1887124, 22.1380932],
                [106.1887434, 22.1391892],
                [106.1889668, 22.1398169],
                [106.1895745, 22.1407565],
                [106.1903427, 22.1413396],
                [106.1908712, 22.1419448],
                [106.1912509, 22.1430179],
                [106.1913536, 22.1435552],
                [106.1914866, 22.1437978],
                [106.1917713, 22.1439378],
                [106.1921642, 22.1439756],
                [106.1924692, 22.1439531],
                [106.1929911, 22.1437665],
                [106.1941193, 22.143068],
                [106.194554, 22.1428821],
                [106.1950548, 22.1427973],
                [106.196539, 22.1429285],
                [106.1971436, 22.1435027],
                [106.1975641, 22.1442101],
                [106.1982231, 22.1447737],
                [106.1992086, 22.1452537],
                [106.1998672, 22.1457564],
                [106.2002414, 22.1461596],
                [106.2006968, 22.1471409],
                [106.2011294, 22.1492896],
                [106.2017341, 22.1498637],
                [106.2028945, 22.150383],
                [106.2074065, 22.1538922],
                [106.2082866, 22.154779],
                [106.2088829, 22.1556475],
                [106.2094916, 22.1566886],
                [106.2101321, 22.1576175],
                [106.2105265, 22.1578379],
                [106.2109638, 22.1579565],
                [106.2118371, 22.1580516],
                [106.2122746, 22.1581904],
                [106.2126032, 22.1583504],
                [106.2129555, 22.1587538],
                [106.2137636, 22.1601384],
                [106.214344, 22.161677],
                [106.2147613, 22.1632878],
                [106.2157995, 22.1648026],
                [106.2166086, 22.166309],
                [106.2170042, 22.1666512],
                [106.2174198, 22.1667901],
                [106.2179003, 22.1668475],
                [106.2196011, 22.1667739],
                [106.2227155, 22.1674915],
                [106.2248226, 22.1689779],
                [106.225809, 22.1695187],
                [106.2266652, 22.1701618],
                [106.2280828, 22.1713794],
                [106.2290066, 22.1722656],
                [106.2297535, 22.1728691],
                [106.2305656, 22.1734313],
                [106.2314788, 22.1743482],
                [106.2325151, 22.1756193],
                [106.2331556, 22.1765078],
                [106.2334787, 22.1772869],
                [106.2339133, 22.1795978],
                [106.2340419, 22.1805815],
                [106.2340259, 22.1812515],
                [106.2337721, 22.1821466],
                [106.2333201, 22.1828607],
                [106.2325025, 22.1829175],
                [106.2313725, 22.1834133],
                [106.230461, 22.1839684],
                [106.2280134, 22.1859966],
                [106.2266421, 22.1876006],
                [106.225736, 22.1887849],
                [106.2245422, 22.1907529],
                [106.224136, 22.1917101],
                [106.223838, 22.192565],
                [106.2212173, 22.1973147],
                [106.2211799, 22.1980256],
                [106.2216874, 22.1986917],
                [106.2219751, 22.1991564],
                [106.2228458, 22.2001751],
                [106.2233103, 22.2009227],
                [106.2232932, 22.2014506],
                [106.223209, 22.2018167],
                [106.2222512, 22.2033364],
                [106.2217163, 22.2045382],
                [106.221573, 22.2056354],
                [106.2213531, 22.2067029],
                [106.2213147, 22.2073122],
                [106.2214924, 22.2076763],
                [106.2219323, 22.208079],
                [106.2222412, 22.2084624],
                [106.2227247, 22.2088648],
                [106.2232238, 22.209805],
                [106.224342, 22.210426],
                [106.224737, 22.2106869],
                [106.2249574, 22.2109289],
                [106.2250326, 22.2120651],
                [106.2249347, 22.2133651],
                [106.2251352, 22.2138305],
                [106.2255086, 22.2141119],
                [106.2262538, 22.214492],
                [106.2271973, 22.215094],
                [106.2292357, 22.2161342],
                [106.230588, 22.2185601],
                [106.2311748, 22.2195403],
                [106.231727, 22.2203075],
                [106.2323417, 22.2207291],
                [106.2326262, 22.2208285],
                [106.2332156, 22.2208443],
                [106.2341745, 22.2207152],
                [106.2350038, 22.220709],
                [106.236184, 22.2209234],
                [106.2371584, 22.2213118],
                [106.2375339, 22.2218367],
                [106.2376271, 22.222506],
                [106.2376206, 22.2254801],
                [106.2378023, 22.226311],
                [106.2380241, 22.2267154],
                [106.2385309, 22.2272596],
                [106.2394214, 22.2280344],
                [106.2397986, 22.228742],
                [106.2400743, 22.2303234],
                [106.2400748, 22.2315923],
                [106.2406172, 22.2337196],
                [106.2408809, 22.2351691],
                [106.2412599, 22.2360798],
                [106.2419005, 22.2369479],
                [106.2427051, 22.2378654],
                [106.2429277, 22.2383509],
                [106.2429752, 22.2387972],
                [106.2427845, 22.2394279],
                [106.2422238, 22.2402036],
                [106.2418518, 22.2413332],
                [106.2418141, 22.2420034],
                [106.241893, 22.2435253],
                [106.2418915, 22.2445911],
                [106.2419892, 22.2457882],
                [106.2422169, 22.246842],
                [106.242629, 22.2477828],
                [106.2430546, 22.2484115],
                [106.2433752, 22.2488851],
                [106.2448771, 22.251495],
                [106.2454176, 22.252516],
                [106.2466363, 22.2553844],
                [106.2468925, 22.2561356],
                [106.2469976, 22.2566667],
                [106.2469677, 22.2571143],
                [106.246724, 22.2577164],
                [106.2463379, 22.2583383],
                [106.2452183, 22.2598934],
                [106.2449621, 22.2603869],
                [106.2448215, 22.2608684],
                [106.2448048, 22.2617465],
                [106.2451533, 22.2646532],
                [106.2452065, 22.2658297],
                [106.2450944, 22.2670744],
                [106.244955, 22.2675397],
                [106.2447173, 22.2680098],
                [106.2443765, 22.2684976],
                [106.244062, 22.268855],
                [106.2429259, 22.269939],
                [106.2414645, 22.2715293],
                [106.2400922, 22.2728949],
                [106.2397552, 22.273371],
                [106.2392586, 22.2742361],
                [106.2387544, 22.2748503],
                [106.2351305, 22.278616],
                [106.2348157, 22.2788916],
                [106.2342819, 22.2791402],
                [106.2324602, 22.2796281],
                [106.2314375, 22.2800691],
                [106.2302069, 22.2808507],
                [106.2281188, 22.2824333],
                [106.2276165, 22.2826953],
                [106.227325, 22.2827513],
                [106.2269869, 22.2827427],
                [106.2250529, 22.2824167],
                [106.2247691, 22.2824358],
                [106.2246039, 22.2825332],
                [106.2244482, 22.2828659],
                [106.2243693, 22.2833165],
                [106.224316, 22.2854549],
                [106.2242569, 22.2861637],
                [106.2241183, 22.2868475],
                [106.2238855, 22.28746],
                [106.2236598, 22.2878375],
                [106.2233985, 22.2881336],
                [106.2224361, 22.2888784],
                [106.2208713, 22.2902929],
                [106.2206057, 22.2904142],
                [106.220373, 22.2903661],
                [106.2201192, 22.2901265],
                [106.2195813, 22.2894661],
                [106.2190929, 22.2890413],
                [106.2183386, 22.2885882],
                [106.2168702, 22.287818],
                [106.216715, 22.2877485],
                [106.2166008, 22.2877501],
                [106.2161731, 22.2881209],
                [106.2159223, 22.2882661],
                [106.2143868, 22.2887462],
                [106.2135773, 22.2891718],
                [106.2127652, 22.289767],
                [106.2120067, 22.2904492],
                [106.2114098, 22.2911039],
                [106.2111406, 22.291481],
                [106.2105181, 22.2925676],
                [106.2098332, 22.2940059],
                [106.2095522, 22.2947108],
                [106.2089989, 22.2964518],
                [106.2086434, 22.2973635],
                [106.207854, 22.2987554],
                [106.2071356, 22.299699],
                [106.2067438, 22.3000823],
                [106.2053828, 22.3011173],
                [106.2051687, 22.3013281],
                [106.2048851, 22.3017236],
                [106.20475, 22.302143],
                [106.2046509, 22.303413],
                [106.2045883, 22.3037188],
                [106.2043699, 22.3041875],
                [106.2037957, 22.3048768],
                [106.2036878, 22.3051465],
                [106.2033727, 22.3066807],
                [106.2031391, 22.3070327],
                [106.2017142, 22.3084969],
                [106.2010404, 22.3093428],
                [106.2006391, 22.3101948],
                [106.1997652, 22.3124222],
                [106.1996092, 22.3129813],
                [106.1996049, 22.3133995],
                [106.1997455, 22.313887],
                [106.2002111, 22.3145966],
                [106.2015347, 22.3163036],
                [106.201732, 22.3166451],
                [106.2019369, 22.3172039],
                [106.2019035, 22.317702],
                [106.2013118, 22.319219],
                [106.2011415, 22.3201533],
                [106.2011506, 22.3217459],
                [106.2013076, 22.32287],
                [106.2014791, 22.3233668],
                [106.2017176, 22.3238084],
                [106.2034888, 22.326389],
                [106.2044651, 22.3276018],
                [106.2049443, 22.3283502],
                [106.2050884, 22.3288161],
                [106.2049355, 22.3294545],
                [106.2049145, 22.329781],
                [106.2050441, 22.330175],
                [106.2054591, 22.3311092],
                [106.2057552, 22.3319978],
                [106.2071701, 22.333825],
                [106.2075009, 22.3341281],
                [106.2085892, 22.334929],
                [106.2089267, 22.3353113],
                [106.2091568, 22.3357255],
                [106.2092012, 22.3358997],
                [106.2091735, 22.3360973],
                [106.2088528, 22.3369543],
                [106.2088806, 22.3372524],
                [106.2090148, 22.3374623],
                [106.2098503, 22.3383276],
                [106.2108925, 22.3396267],
                [106.211538, 22.3407288],
                [106.2117685, 22.3409538],
                [106.2120808, 22.3411328],
                [106.2136714, 22.3416335],
                [106.2142455, 22.3419503],
                [106.2146587, 22.3424304],
                [106.214895, 22.3428624],
                [106.215416, 22.3448002],
                [106.2155637, 22.3451315],
                [106.215826, 22.3454809],
                [106.2163818, 22.3459978],
                [106.2168685, 22.3463157],
                [106.2174593, 22.3465489],
                [106.2184374, 22.3468299],
                [106.2189196, 22.3470575],
                [106.2193682, 22.3474871],
                [106.2196328, 22.3479562],
                [106.219896, 22.3487237],
                [106.2199994, 22.3492015],
                [106.2200044, 22.3497106],
                [106.2199404, 22.3502863],
                [106.2197015, 22.3509695],
                [106.2189474, 22.3522492],
                [106.2187675, 22.3526511],
                [106.2186802, 22.3530532],
                [106.2186106, 22.3540963],
                [106.2184283, 22.3547559],
                [106.2182093, 22.3558889],
                [106.2179431, 22.3564115],
                [106.2173045, 22.357327],
                [106.216532, 22.3583406],
                [106.2160793, 22.3588109],
                [106.2154887, 22.3592507],
                [106.2134366, 22.3606123],
                [106.2127318, 22.3609723],
                [106.2112458, 22.3615855],
                [106.2108663, 22.3618158],
                [106.2106803, 22.362016],
                [106.2105546, 22.3623183],
                [106.2104994, 22.3630988],
                [106.2103723, 22.3635687],
                [106.209492, 22.365126],
                [106.2092358, 22.3657466],
                [106.2091821, 22.3660333],
                [106.2091807, 22.3671817],
                [106.2090786, 22.3676272],
                [106.2088507, 22.3680088],
                [106.2084454, 22.3683829],
                [106.2075673, 22.3689387],
                [106.2052909, 22.3702149],
                [106.204038, 22.3710574],
                [106.2030166, 22.3718221],
                [106.2024692, 22.3723581],
                [106.2018754, 22.373058],
                [106.2014782, 22.3733597],
                [106.201174, 22.3734911],
                [106.1992468, 22.374102],
                [106.1972756, 22.374838],
                [106.1955929, 22.3751179],
                [106.1943739, 22.3754906],
                [106.1933715, 22.3757245],
                [106.1914487, 22.3760388],
                [106.1912741, 22.3760261],
                [106.1907639, 22.3763425],
                [106.1899891, 22.3769406],
                [106.1896534, 22.3772915],
                [106.1887902, 22.3785132],
                [106.1884311, 22.3788375],
                [106.1875623, 22.3794738],
                [106.1873226, 22.3797239],
                [106.1871744, 22.3799901],
                [106.1871214, 22.3802948],
                [106.187177, 22.38083],
                [106.1879718, 22.3836815],
                [106.1879962, 22.3838731],
                [106.1879455, 22.3841267],
                [106.1877954, 22.3842962],
                [106.1875523, 22.384434],
                [106.186581, 22.3847133],
                [106.1862292, 22.3849166],
                [106.1858855, 22.3851865],
                [106.185522, 22.3855674],
                [106.1849784, 22.38639],
                [106.1847959, 22.3868191],
                [106.1847986, 22.3870627],
                [106.1846357, 22.3884556],
                [106.1844298, 22.3895888],
                [106.1841389, 22.3902329],
                [106.1836848, 22.3905427],
                [106.1830733, 22.390645],
                [106.182403, 22.390643],
                [106.181643, 22.3908435],
                [106.18093, 22.3910911],
                [106.1802725, 22.3911228],
                [106.179477, 22.3910337],
                [106.1784684, 22.3912554],
                [106.1781174, 22.392925],
                [106.177724, 22.393746],
                [106.1763114, 22.3953377],
                [106.1758757, 22.3961208],
                [106.1757859, 22.3967438],
                [106.1759387, 22.3971141],
                [106.176141, 22.3973152],
                [106.1762365, 22.3978393],
                [106.1760432, 22.3983587],
                [106.1759825, 22.3986903],
                [106.176187, 22.3992429],
                [106.1760495, 22.3996699],
                [106.1762053, 22.3998216],
                [106.1767352, 22.3998266],
                [106.1770657, 22.4000007],
                [106.1772795, 22.4002238],
                [106.1773477, 22.4007236],
                [106.1773002, 22.4018745],
                [106.1763249, 22.4026328],
                [106.1752737, 22.4024932],
                [106.1743508, 22.4025786],
                [106.1737191, 22.4033134],
                [106.1727357, 22.4032786],
                [106.1724117, 22.403976],
                [106.1719718, 22.4040214],
                [106.1712985, 22.4037667],
                [106.1706302, 22.4039135],
                [106.1698383, 22.4035191],
                [106.1688327, 22.4032623],
                [106.1681068, 22.4033385],
                [106.1677072, 22.4037908],
                [106.1677702, 22.4042879],
                [106.1680458, 22.4049279],
                [106.1679612, 22.405329],
                [106.1674252, 22.4055211],
                [106.1672097, 22.4058576],
                [106.1671055, 22.4071167],
                [106.1676242, 22.4080355],
                [106.1683249, 22.4086701],
                [106.1681299, 22.408938],
                [106.1667934, 22.4091307],
                [106.1662577, 22.4089057],
                [106.1655682, 22.4089695],
                [106.1642143, 22.4093483],
                [106.1602941, 22.4094048],
                [106.1590312, 22.4095641],
                [106.1575989, 22.4102531],
                [106.1569769, 22.4104057],
                [106.1565449, 22.4103215],
                [106.1560821, 22.4096708],
                [106.1557857, 22.4091036],
                [106.1556153, 22.4079404],
                [106.1542327, 22.4055932],
                [106.1527791, 22.4048141],
                [106.1519103, 22.4046694],
                [106.1512429, 22.4042671],
                [106.1506836, 22.4032759],
                [106.1500004, 22.4023254],
                [106.1491951, 22.4017876],
                [106.147647, 22.4015493],
                [106.144847, 22.3992443],
                [106.1425368, 22.3972714],
                [106.1414723, 22.3958858],
                [106.1402629, 22.3947561],
                [106.139517, 22.3947022],
                [106.1386173, 22.3951528],
                [106.1370813, 22.3954258],
                [106.1361237, 22.3954617],
                [106.1347608, 22.3956274],
                [106.133296, 22.3985053],
                [106.1323664, 22.3993573],
                [106.1307065, 22.3998187],
                [106.1280403, 22.4006137],
                [106.1268658, 22.4003166],
                [106.1265709, 22.4001658],
                [106.1243439, 22.4000521],
                [106.1230247, 22.3997723],
                [106.1221443, 22.3997907],
                [106.1221093, 22.4013372],
                [106.1223406, 22.4023344],
                [106.1226114, 22.4027602],
                [106.121908, 22.4030067],
                [106.1199439, 22.4030873],
                [106.1182719, 22.4025658],
                [106.1172519, 22.4025227],
                [106.1162896, 22.4023307],
                [106.1156094, 22.4018702],
                [106.1151359, 22.4009584],
                [106.1135009, 22.3999457],
                [106.1124496, 22.3994202],
                [106.1115657, 22.3992974],
                [106.1104464, 22.3996298],
                [106.1075462, 22.4009812],
                [106.1043795, 22.4023725],
                [106.1043437, 22.4027546],
                [106.1047802, 22.4034595],
                [106.1062936, 22.4043104],
                [106.10638, 22.4053564],
                [106.1062993, 22.4059278],
                [106.1058801, 22.4064923],
                [106.1055637, 22.4074868],
                [106.1056892, 22.4091774],
                [106.1056792, 22.41001],
                [106.1034662, 22.4114441],
                [106.1016053, 22.4128585],
                [106.1007749, 22.413847],
                [106.0993577, 22.4147269],
                [106.0991699, 22.4154487],
                [106.0998084, 22.4164147],
                [106.1008024, 22.4172954],
                [106.1011958, 22.4180825],
                [106.1011815, 22.4198465],
                [106.1014403, 22.4204883],
                [106.1015392, 22.4210697],
                [106.1019723, 22.4220492],
                [106.1018909, 22.4233283],
                [106.10168, 22.4242259],
                [106.1017372, 22.424996],
                [106.1018203, 22.4256888],
                [106.1016193, 22.426232],
                [106.1011813, 22.4267715],
                [106.1004828, 22.4273693],
                [106.1004766, 22.4282069],
                [106.1005789, 22.4292437],
                [106.0998811, 22.4305287],
                [106.1000133, 22.4309988],
                [106.1008463, 22.4315719],
                [106.1010136, 22.4325213],
                [106.101338, 22.4329656],
                [106.1017195, 22.434262],
                [106.101709, 22.4359296],
                [106.1003261, 22.4373926],
                [106.0994453, 22.4380462],
                [106.0985465, 22.4398506],
                [106.0967164, 22.4407656],
                [106.09576, 22.4409407],
                [106.0947052, 22.4416451],
                [106.0943789, 22.4427059],
                [106.0945656, 22.445133],
                [106.0942337, 22.4463039],
                [106.095397, 22.4466487],
                [106.0956782, 22.446942],
                [106.0962129, 22.4470111],
                [106.0979465, 22.4478457],
                [106.0985651, 22.4482903],
                [106.0993143, 22.4491469],
                [106.0997483, 22.4499439],
                [106.1002749, 22.4504277],
                [106.1008439, 22.4507156],
                [106.1017178, 22.4510056],
                [106.1030861, 22.451698],
                [106.1041898, 22.4527872],
                [106.104858, 22.453784],
                [106.1049473, 22.4549004],
                [106.105325, 22.4553688],
                [106.1060456, 22.4558182],
                [106.1064016, 22.4562857],
                [106.107021, 22.458279],
                [106.1077048, 22.4596058],
                [106.1088642, 22.4609622],
                [106.1090235, 22.4613246],
                [106.1097155, 22.4636764],
                [106.1107726, 22.4641496],
                [106.1118576, 22.4656124],
                [106.1140239, 22.4678312],
                [106.1152195, 22.4689748],
                [106.1155081, 22.4705123],
                [106.1158818, 22.4705123],
                [106.1168461, 22.4708573],
                [106.1178056, 22.4709912],
                [106.1187422, 22.4713965],
                [106.1195969, 22.4721054],
                [106.1196929, 22.4726114],
                [106.1191998, 22.4738363],
                [106.1190696, 22.4744363],
                [106.118307, 22.4754378],
                [106.1176132, 22.4765785],
                [106.1172029, 22.4777994],
                [106.1168877, 22.4789861],
                [106.1164104, 22.4799517],
                [106.1159252, 22.4805691],
                [106.1154016, 22.4813876],
                [106.1150902, 22.4820799],
                [106.1148688, 22.4827273],
                [106.1146395, 22.4838944],
                [106.114759, 22.4849858],
                [106.1151431, 22.4859351],
                [106.1159075, 22.4872068],
                [106.1164024, 22.4885085],
                [106.1164231, 22.4893595],
                [106.11636, 22.49232],
                [106.1163676, 22.4947721],
                [106.1165754, 22.4956026],
                [106.1174332, 22.4970593],
                [106.1184507, 22.4988146],
                [106.118987, 22.5012727],
                [106.1183169, 22.5025079],
                [106.117962, 22.503548],
                [106.1178098, 22.5044959],
                [106.1178496, 22.5056037],
                [106.1182992, 22.5064989],
                [106.1186716, 22.5077814],
                [106.1187409, 22.5086721],
                [106.1189677, 22.5095988],
                [106.1189474, 22.510726],
                [106.1188137, 22.5114861],
                [106.1185948, 22.512082],
                [106.1158792, 22.512624],
                [106.1137229, 22.5128605],
                [106.1119925, 22.5136157],
                [106.1098465, 22.5141812],
                [106.1082162, 22.5145691],
                [106.1055904, 22.5158345],
                [106.1023171, 22.5160721],
                [106.100962, 22.5169182],
                [106.1004398, 22.5175842],
                [106.1003418, 22.5182128],
                [106.1004497, 22.5187666],
                [106.1008174, 22.5193626],
                [106.1015999, 22.520277],
                [106.1020675, 22.520999],
                [106.1022814, 22.5215523],
                [106.1022891, 22.5220214],
                [106.0993791, 22.5236412],
                [106.0988514, 22.5241796],
                [106.0982955, 22.5250857],
                [106.0977634, 22.5257917],
                [106.0970896, 22.5262494],
                [106.0963592, 22.5264073],
                [106.0957254, 22.5268161],
                [106.0952364, 22.5271942],
                [106.0949504, 22.5277442],
                [106.0954062, 22.5290089],
                [106.0958337, 22.5307379],
                [106.09659, 22.5312392],
                [106.0983844, 22.5318961],
                [106.0991484, 22.5321223],
                [106.099784, 22.532681],
                [106.1004911, 22.5339696],
                [106.10092, 22.5352003],
                [106.0997832, 22.5346306],
                [106.0989908, 22.5343358],
                [106.0976353, 22.5334156],
                [106.0966107, 22.5328099],
                [106.0957577, 22.5324081],
                [106.0952871, 22.5323876],
                [106.0948263, 22.5325637],
                [106.0944537, 22.5329975],
                [106.0940548, 22.5337765],
                [106.0931338, 22.5351321],
                [106.0927271, 22.5363242],
                [106.0926977, 22.5370927],
                [106.0921252, 22.5380167],
                [106.0921812, 22.5389019],
                [106.0923298, 22.5399042],
                [106.0927235, 22.5412084],
                [106.0934758, 22.5420898],
                [106.0939042, 22.5426671],
                [106.0945239, 22.5432495],
                [106.0951437, 22.5445022],
                [106.0954421, 22.5459811],
                [106.0950499, 22.5467966],
                [106.0939626, 22.5475668],
                [106.0926924, 22.5474908],
                [106.0916608, 22.5476505],
                [106.0909224, 22.5479463],
                [106.0903418, 22.5483983],
                [106.0895684, 22.5491823],
                [106.0887059, 22.5504582],
                [106.0876217, 22.5520559],
                [106.0870786, 22.5525312],
                [106.08609, 22.5525821],
                [106.0848822, 22.552383],
                [106.0837875, 22.5520608],
                [106.0829853, 22.5519919],
                [106.0806788, 22.5522767],
                [106.0788147, 22.5523696],
                [106.0770296, 22.5526791],
                [106.0749376, 22.5541065],
                [106.0737315, 22.5542223],
                [106.073202, 22.5543855],
                [106.0738928, 22.556346],
                [106.0742586, 22.5576452],
                [106.0743953, 22.5592093],
                [106.074605, 22.5605717],
                [106.0754256, 22.5619406],
                [106.0760652, 22.5626994],
                [106.0774403, 22.5639165],
                [106.0792016, 22.5653848],
                [106.0799633, 22.5657322],
                [106.0808045, 22.5657903],
                [106.0817673, 22.5661219],
                [106.0823424, 22.5668516],
                [106.0824602, 22.5676082],
                [106.081966, 22.5683462],
                [106.0819671, 22.5694148],
                [106.0823951, 22.5703336],
                [106.0825737, 22.5714871],
                [106.082359, 22.5719993],
                [106.0815315, 22.5729461],
                [106.0805379, 22.573395],
                [106.0794919, 22.5735179],
                [106.0783068, 22.5729304],
                [106.0768574, 22.5723955],
                [106.0754779, 22.5720909],
                [106.0735413, 22.571984],
                [106.0717093, 22.5723202],
                [106.0708157, 22.5722999],
                [106.0677362, 22.5691476],
                [106.0666571, 22.5689701],
                [106.0656722, 22.5690585],
                [106.064012, 22.569465],
                [106.0628025, 22.5698279],
                [106.0616903, 22.5699],
                [106.0594632, 22.5699116],
                [106.0584644, 22.5702158],
                [106.0570876, 22.5710897],
                [106.055011, 22.5726589],
                [106.05361, 22.573693],
                [106.052454, 22.5743362],
                [106.0511631, 22.5747919],
                [106.0505147, 22.5752514],
                [106.0499193, 22.576225],
                [106.0490555, 22.577013],
                [106.0482756, 22.5774018],
                [106.0476135, 22.5773421],
                [106.0471777, 22.5770185],
                [106.046611, 22.5761206],
                [106.0460126, 22.5752807],
                [106.0457325, 22.5741606],
                [106.045888, 22.5728857],
                [106.0441295, 22.5700366],
                [106.0420015, 22.5672396],
                [106.0420343, 22.5650603],
                [106.0415565, 22.5644591],
                [106.0406871, 22.5639525],
                [106.0396899, 22.5634815],
                [106.0387355, 22.5628482],
                [106.037219, 22.5613305],
                [106.0363619, 22.5611063],
                [106.0345366, 22.5603805],
                [106.0333886, 22.5600623],
                [106.0310492, 22.5594144],
                [106.0298772, 22.5589525],
                [106.0280363, 22.5580368],
                [106.0261934, 22.5574628],
                [106.0257957, 22.557565],
                [106.0246285, 22.5583657],
                [106.0234616, 22.5595997],
                [106.0226405, 22.5601164],
                [106.0213378, 22.5599515],
                [106.0195454, 22.5594386],
                [106.0183166, 22.5590671],
                [106.0173479, 22.5584535],
                [106.0162904, 22.5577169],
                [106.0145301, 22.5566872],
                [106.0132526, 22.5560145],
                [106.0120461, 22.555782],
                [106.0109652, 22.5553524],
                [106.008959, 22.5550449],
                [106.0067456, 22.5548133],
                [106.0049665, 22.5537875],
                [106.0042905, 22.5532617],
                [106.0038045, 22.5526589],
                [106.0030736, 22.550841],
                [106.0030257, 22.5488578],
                [106.0029537, 22.548308],
                [106.0027259, 22.5478212],
                [106.000965, 22.5469529],
                [105.9972735, 22.5459252],
                [105.9945092, 22.5452882],
                [105.9933615, 22.5448902],
                [105.9924824, 22.544212],
                [105.9913725, 22.5427283],
                [105.9896886, 22.5429112],
                [105.9886766, 22.5429705],
                [105.9868871, 22.5436383],
                [105.9864304, 22.543719],
                [105.9859187, 22.5434661],
                [105.9854364, 22.5430038],
                [105.9852586, 22.5424417],
                [105.985214, 22.541743],
                [105.9850982, 22.5413003],
                [105.9847033, 22.5406854],
                [105.9837334, 22.5397225],
                [105.9826292, 22.5391583],
                [105.98103, 22.5386678],
                [105.9795765, 22.5382667],
                [105.9775513, 22.5363778],
                [105.9766037, 22.5364459],
                [105.9753078, 22.5369261],
                [105.9745178, 22.5368876],
                [105.9740101, 22.5367104],
                [105.9734941, 22.5367827],
                [105.9728001, 22.5370129],
                [105.9721091, 22.5368813],
                [105.9712301, 22.5364348],
                [105.9706048, 22.5360592],
                [105.9701928, 22.5356165],
                [105.9689825, 22.5350193],
                [105.9688456, 22.5342029],
                [105.9684619, 22.5330606],
                [105.9677934, 22.5318059],
                [105.9674739, 22.53132],
                [105.966768, 22.5300328],
                [105.9662763, 22.5289796],
                [105.9655308, 22.5280799],
                [105.9649568, 22.5277514],
                [105.964046, 22.5273715],
                [105.9638805, 22.5275651],
                [105.9632473, 22.5275855],
                [105.9626408, 22.5273467],
                [105.9623197, 22.5273895],
                [105.9617173, 22.5273058],
                [105.960855, 22.5276666],
                [105.9598547, 22.5266484],
                [105.9590852, 22.5257768],
                [105.9587589, 22.524971],
                [105.9579182, 22.524077],
                [105.9573456, 22.523734],
                [105.9564146, 22.5234518],
                [105.9557612, 22.523447],
                [105.9545034, 22.5229037],
                [105.9537722, 22.5225415],
                [105.952829, 22.5225627],
                [105.9518977, 22.5230539],
                [105.9514263, 22.5239233],
                [105.9490636, 22.5256091],
                [105.9484205, 22.5259742],
                [105.9477931, 22.5271845],
                [105.9475756, 22.527759],
                [105.9469616, 22.528396],
                [105.9450839, 22.5281078],
                [105.9429927, 22.5275285],
                [105.9419935, 22.5274928],
                [105.9408651, 22.5275077],
                [105.9403321, 22.527411],
                [105.9396172, 22.5270854],
                [105.9389154, 22.5269774],
                [105.9380003, 22.5275218],
                [105.9370339, 22.5277184],
                [105.9358657, 22.5278321],
                [105.9345744, 22.5278451],
                [105.9333593, 22.5279599],
                [105.9327685, 22.5278171],
                [105.9317506, 22.5272898],
                [105.9312018, 22.5266081],
                [105.9301469, 22.5257413],
                [105.928525, 22.5246487],
                [105.9278325, 22.5235847],
                [105.9261245, 22.5222815],
                [105.9246956, 22.5213952],
                [105.9241286, 22.5208667],
                [105.9237863, 22.5203178],
                [105.9237283, 22.5195604],
                [105.9240604, 22.5188641],
                [105.923907, 22.517481],
                [105.9238852, 22.516132],
                [105.9238375, 22.5149228],
                [105.9226851, 22.5133185],
                [105.9216278, 22.5114786],
                [105.9210446, 22.5103482],
                [105.9207947, 22.5095613],
                [105.9208057, 22.5088474],
                [105.9204259, 22.508163],
                [105.9195111, 22.5069511],
                [105.9187279, 22.506103],
                [105.9179983, 22.5048936],
                [105.917049, 22.504081],
                [105.9162409, 22.5028092],
                [105.9149754, 22.5010941],
                [105.9139361, 22.4997666],
                [105.9134667, 22.4988892],
                [105.9132152, 22.4981721],
                [105.9131859, 22.4967265],
                [105.9119587, 22.4959186],
                [105.9107629, 22.4957321],
                [105.9087267, 22.496035],
                [105.907601, 22.4958341],
                [105.9066389, 22.4954724],
                [105.905775, 22.4951805],
                [105.9040317, 22.4950472],
                [105.9037365, 22.4958199],
                [105.9034538, 22.4966036],
                [105.9030289, 22.4973214],
                [105.9026531, 22.4980717],
                [105.9018546, 22.4991683],
                [105.9007484, 22.5006438],
                [105.8997337, 22.5019088],
                [105.8985254, 22.5033425],
                [105.8982185, 22.50442],
                [105.8981994, 22.5055752],
                [105.8989679, 22.5067735],
                [105.8968646, 22.5075601],
                [105.8942757, 22.5087387],
                [105.8899109, 22.5110376],
                [105.8869737, 22.5119104],
                [105.8837361, 22.5131191],
                [105.8822981, 22.513368],
                [105.8810837, 22.5135813],
                [105.8795767, 22.51415],
                [105.8786958, 22.5142308],
                [105.8777448, 22.5141528],
                [105.876836, 22.5142436],
                [105.8757966, 22.5147255],
                [105.8749357, 22.515054],
                [105.8738184, 22.5157349],
                [105.8734628, 22.5168257],
                [105.8729363, 22.5171561],
                [105.8724569, 22.5170799],
                [105.8719662, 22.5166886],
                [105.8707835, 22.517021],
                [105.8696667, 22.5179659],
                [105.8690953, 22.5189314],
                [105.8666919, 22.5201674],
                [105.8631113, 22.5209439],
                [105.8590537, 22.5214385],
                [105.8572395, 22.5213576],
                [105.8557285, 22.520805],
                [105.8542892, 22.5200701],
                [105.8534329, 22.5196747],
                [105.8521689, 22.5196456],
                [105.8505386, 22.5195949],
                [105.8493122, 22.5191975],
                [105.8486882, 22.5186596],
                [105.8479342, 22.5177113],
                [105.8462512, 22.5167309],
                [105.8443348, 22.515808],
                [105.8418117, 22.5153473],
                [105.8408216, 22.5155411],
                [105.8399695, 22.5155908],
                [105.8388911, 22.5154216],
                [105.8376665, 22.5156495],
                [105.8366472, 22.5157749],
                [105.8346131, 22.5156416],
                [105.8341428, 22.5160893],
                [105.8337609, 22.5179093],
                [105.8343312, 22.5198085],
                [105.8351246, 22.5216765],
                [105.8353442, 22.5224504],
                [105.8352247, 22.5231336],
                [105.8349932, 22.5238786],
                [105.8348667, 22.5244485],
                [105.8343159, 22.5253042],
                [105.8336123, 22.525891],
                [105.8331276, 22.5260564],
                [105.8325617, 22.5260091],
                [105.8319957, 22.5257239],
                [105.831219, 22.5251366],
                [105.8304243, 22.5243051],
                [105.8299295, 22.5237747],
                [105.8295122, 22.523571],
                [105.8287144, 22.5234799],
                [105.8276022, 22.5230372],
                [105.8266112, 22.5225967],
                [105.825311, 22.5218719],
                [105.8237196, 22.5206603],
                [105.8223822, 22.5201307],
                [105.8216046, 22.5201735],
                [105.820159, 22.5207824],
                [105.8189967, 22.5210544],
                [105.8180586, 22.5211067],
                [105.8160988, 22.5210853],
                [105.8147425, 22.5211481],
                [105.8132312, 22.5218114],
                [105.8119852, 22.5222152],
                [105.8109537, 22.5223765],
                [105.8090931, 22.522807],
                [105.8069585, 22.5230415],
                [105.804359, 22.5237394],
                [105.8040308, 22.524183],
                [105.803612, 22.5245726],
                [105.8031626, 22.5248328],
                [105.8025503, 22.5250937],
                [105.8020213, 22.5255347],
                [105.801632, 22.5261936],
                [105.8012568, 22.5275902],
                [105.8006692, 22.5290864],
                [105.8001357, 22.5300397],
                [105.7994557, 22.5306432],
                [105.798639, 22.5313306],
                [105.7984145, 22.5319846],
                [105.798335, 22.5325481],
                [105.7981717, 22.5329959],
                [105.7977956, 22.5332987],
                [105.7969831, 22.5334233],
                [105.7965148, 22.533512],
                [105.7960728, 22.533699],
                [105.7956297, 22.5342749],
                [105.7949763, 22.5349632],
                [105.794597, 22.5355606],
                [105.794227, 22.5367905],
                [105.7940505, 22.537569],
                [105.7937629, 22.5381354],
                [105.7931215, 22.5389573],
                [105.7929086, 22.5393489],
                [105.7928193, 22.5400701],
                [105.79286, 22.5403758],
                [105.7930614, 22.5407499],
                [105.7932211, 22.541254],
                [105.7932079, 22.5416518],
                [105.7930252, 22.5420212],
                [105.7928127, 22.5423352],
                [105.7925715, 22.5427032],
                [105.7922622, 22.5429066],
                [105.7919237, 22.5432336],
                [105.7916932, 22.5438018],
                [105.7914, 22.5441975],
                [105.7912489, 22.5447013],
                [105.7912162, 22.5451697],
                [105.79116, 22.5456401],
                [105.7909914, 22.546112],
                [105.7907452, 22.5464605],
                [105.7903963, 22.5467284],
                [105.789711, 22.5469685],
                [105.7889587, 22.5470929],
                [105.7882294, 22.5471566],
                [105.7878288, 22.5472719],
                [105.7875057, 22.54755],
                [105.787272, 22.5479365],
                [105.7873167, 22.5483938],
                [105.7876176, 22.548929],
                [105.7876559, 22.5493093],
                [105.7876137, 22.550041],
                [105.7874597, 22.5503485],
                [105.7870957, 22.5506942],
                [105.7863022, 22.5510694],
                [105.7854605, 22.5512406],
                [105.7844728, 22.5512755],
                [105.7837067, 22.5511988],
                [105.7832635, 22.5509743],
                [105.78315, 22.5505989],
                [105.7829029, 22.5497957],
                [105.7827177, 22.5493465],
                [105.7825048, 22.5490945],
                [105.7821696, 22.5489223],
                [105.7816902, 22.5488837],
                [105.7809823, 22.5490521],
                [105.7803406, 22.5492085],
                [105.7798851, 22.54918],
                [105.7794328, 22.5489346],
                [105.7791437, 22.5487401],
                [105.7789818, 22.5486913],
                [105.7788252, 22.5486441],
                [105.7783935, 22.5486658],
                [105.7779337, 22.5488087],
                [105.7775719, 22.5490358],
                [105.7773795, 22.5492874],
                [105.7772452, 22.5495894],
                [105.7770144, 22.5499249],
                [105.7767552, 22.5501185],
                [105.7763841, 22.5502968],
                [105.7760671, 22.5503558],
                [105.7757516, 22.550317],
                [105.7754899, 22.5500992],
                [105.775049, 22.5498453],
                [105.7744504, 22.5496648],
                [105.7736142, 22.5496148],
                [105.7720759, 22.5497043],
                [105.771767, 22.5499337],
                [105.7716382, 22.5501505],
                [105.7716265, 22.5504401],
                [105.7717584, 22.550979],
                [105.7717528, 22.5513818],
                [105.7717732, 22.5516307],
                [105.7719511, 22.5517871],
                [105.7721708, 22.551839],
                [105.7725682, 22.5518303],
                [105.7728602, 22.5517028],
                [105.7731075, 22.5514759],
                [105.7733238, 22.5512171],
                [105.7735956, 22.5510558],
                [105.7739805, 22.5510161],
                [105.7742478, 22.5511734],
                [105.7744523, 22.5514457],
                [105.7745297, 22.5518746],
                [105.7744555, 22.5522569],
                [105.7742142, 22.5526628],
                [105.7740696, 22.5529956],
                [105.7741501, 22.5533942],
                [105.7742569, 22.5537044],
                [105.7741888, 22.5540235],
                [105.7740563, 22.5542176],
                [105.7738696, 22.5543603],
                [105.7735437, 22.5544803],
                [105.7730983, 22.5545758],
                [105.7726342, 22.5547164],
                [105.7718947, 22.5550137],
                [105.7712814, 22.5552861],
                [105.7710311, 22.5553342],
                [105.7707863, 22.5552928],
                [105.7705869, 22.5551373],
                [105.7704031, 22.5548866],
                [105.7702161, 22.5547711],
                [105.7699911, 22.5547627],
                [105.7694097, 22.5549343],
                [105.7687193, 22.5551647],
                [105.767968, 22.555545],
                [105.7676694, 22.5558696],
                [105.7676411, 22.5562135],
                [105.7677434, 22.5565637],
                [105.7681189, 22.5567386],
                [105.7691483, 22.5569642],
                [105.7702217, 22.557368],
                [105.7713229, 22.5577397],
                [105.7719904, 22.5578332],
                [105.7724575, 22.5578367],
                [105.7727868, 22.5578644],
                [105.7730779, 22.5580093],
                [105.7733065, 22.5583042],
                [105.7734749, 22.5586502],
                [105.7735668, 22.5591102],
                [105.7735453, 22.5594161],
                [105.7734624, 22.5597242],
                [105.7732396, 22.5601382],
                [105.7729412, 22.5606973],
                [105.7727908, 22.5610381],
                [105.7727496, 22.5613274],
                [105.7727941, 22.5616235],
                [105.7727819, 22.5618279],
                [105.7726588, 22.5620111],
                [105.7724291, 22.5621007],
                [105.7719469, 22.5622278],
                [105.7716556, 22.5623546],
                [105.7714017, 22.562592],
                [105.771171, 22.5629092],
                [105.7710156, 22.5633801],
                [105.7708747, 22.5636429],
                [105.7706793, 22.564009],
                [105.7705069, 22.5645497],
                [105.7705481, 22.5649432],
                [105.7707839, 22.5656772],
                [105.7709583, 22.5659637],
                [105.7711958, 22.5661772],
                [105.7715683, 22.5662626],
                [105.7720815, 22.5662444],
                [105.7727193, 22.5660676],
                [105.7733741, 22.5658777],
                [105.7738885, 22.5658022],
                [105.7741952, 22.56584],
                [105.7743887, 22.5659947],
                [105.7744675, 22.5662219],
                [105.7744727, 22.5666012],
                [105.7745415, 22.5668562],
                [105.7749658, 22.5673324],
                [105.7751994, 22.5677994],
                [105.775906, 22.5690131],
                [105.7762106, 22.5696968],
                [105.7765388, 22.5699874],
                [105.7770444, 22.570188],
                [105.7778314, 22.5707202],
                [105.7784417, 22.5712484],
                [105.7788251, 22.5717715],
                [105.7789751, 22.5722229],
                [105.7790727, 22.5727613],
                [105.7789819, 22.5731043],
                [105.7786418, 22.5732516],
                [105.7780419, 22.5733824],
                [105.7775865, 22.5735175],
                [105.7772709, 22.5736636],
                [105.7771069, 22.5739258],
                [105.7770475, 22.5742578],
                [105.7770448, 22.5746156],
                [105.7772142, 22.574883],
                [105.7774399, 22.5750238],
                [105.7777039, 22.5752503],
                [105.777827, 22.5756591],
                [105.777792, 22.5761096],
                [105.7774883, 22.5766374],
                [105.7770333, 22.5771078],
                [105.7763924, 22.5776287],
                [105.7758264, 22.5782363],
                [105.7752396, 22.5787627],
                [105.7747768, 22.5792187],
                [105.7744047, 22.5795206],
                [105.7737231, 22.5798797],
                [105.7732985, 22.5800987],
                [105.7729557, 22.5803239],
                [105.7727364, 22.5805402],
                [105.772423, 22.5809959],
                [105.7721521, 22.5814368],
                [105.7717279, 22.5819389],
                [105.7711161, 22.5826899],
                [105.7694641, 22.5843603],
                [105.7689049, 22.5846224],
                [105.7684439, 22.5848434],
                [105.7681807, 22.5851148],
                [105.7677259, 22.5857735],
                [105.7672218, 22.5865603],
                [105.7665455, 22.5873916],
                [105.7659725, 22.5878761],
                [105.7653109, 22.5879605],
                [105.764553, 22.5878679],
                [105.7637309, 22.5875675],
                [105.7629474, 22.5869218],
                [105.7625107, 22.5864387],
                [105.7620188, 22.5860896],
                [105.7613229, 22.5858427],
                [105.7608336, 22.5858408],
                [105.7602358, 22.5860104],
                [105.7594255, 22.5864426],
                [105.7583518, 22.5869276],
                [105.7576466, 22.5871147],
                [105.7573021, 22.5870084],
                [105.7569646, 22.5867383],
                [105.7567443, 22.5864125],
                [105.7565178, 22.5861942],
                [105.7561958, 22.5859945],
                [105.7556922, 22.5858573],
                [105.755083, 22.5858515],
                [105.7544293, 22.5860071],
                [105.753893, 22.5862873],
                [105.7534907, 22.5865216],
                [105.7531607, 22.586669],
                [105.7529365, 22.5866604],
                [105.7525643, 22.586499],
                [105.752236, 22.5863071],
                [105.7518353, 22.5861028],
                [105.7513968, 22.5860199],
                [105.7510538, 22.586142],
                [105.750809, 22.5864216],
                [105.7506734, 22.5867212],
                [105.7507427, 22.5869903],
                [105.7510242, 22.5872079],
                [105.7515406, 22.5874143],
                [105.751878, 22.5876469],
                [105.752046, 22.5878807],
                [105.752127, 22.5885092],
                [105.7519745, 22.5888704],
                [105.7517257, 22.589101],
                [105.7513177, 22.5892145],
                [105.7509363, 22.58939],
                [105.7507158, 22.5896849],
                [105.7506639, 22.5900651],
                [105.7506217, 22.5905487],
                [105.7503958, 22.5914684],
                [105.7502667, 22.5917411],
                [105.7499803, 22.5920563],
                [105.7496009, 22.5923005],
                [105.7490097, 22.5925246],
                [105.7488165, 22.5927312],
                [105.7486769, 22.59306],
                [105.7486143, 22.5934196],
                [105.7485044, 22.5936728],
                [105.7482133, 22.5939343],
                [105.7478642, 22.5941231],
                [105.7474379, 22.5941429],
                [105.747016, 22.5940128],
                [105.7467381, 22.5938131],
                [105.7464618, 22.593684],
                [105.7461904, 22.593652],
                [105.7457959, 22.5937471],
                [105.7455575, 22.5940573],
                [105.7454197, 22.5943508],
                [105.7454195, 22.5947147],
                [105.7455784, 22.5950957],
                [105.7458509, 22.5953945],
                [105.7461378, 22.5955122],
                [105.746432, 22.5955492],
                [105.7466824, 22.5956346],
                [105.7468872, 22.5958457],
                [105.7469481, 22.5961021],
                [105.7468611, 22.5965748],
                [105.7466956, 22.5973181],
                [105.7465027, 22.5977371],
                [105.7461916, 22.5981905],
                [105.7461649, 22.5983813],
                [105.7462996, 22.5986497],
                [105.7467198, 22.5991381],
                [105.74713, 22.5995295],
                [105.7480028, 22.6000438],
                [105.7486846, 22.6005672],
                [105.7495107, 22.6010809],
                [105.7498515, 22.6014595],
                [105.7506407, 22.6021231],
                [105.7511524, 22.6023028],
                [105.7518273, 22.6023775],
                [105.7520976, 22.6024708],
                [105.7523569, 22.602667],
                [105.7527309, 22.6030968],
                [105.7527929, 22.6033667],
                [105.7526671, 22.6038988],
                [105.7526117, 22.6044886],
                [105.7528709, 22.6048189],
                [105.7532985, 22.605018],
                [105.7536688, 22.6053541],
                [105.7538434, 22.6056427],
                [105.7538614, 22.6060117],
                [105.7536764, 22.6065529],
                [105.7535139, 22.6069468],
                [105.7532963, 22.6076796],
                [105.7532831, 22.6080958],
                [105.7532841, 22.6084142],
                [105.7530636, 22.6086231],
                [105.7527636, 22.608707],
                [105.7523365, 22.6086999],
                [105.7518068, 22.6087663],
                [105.7514425, 22.6089186],
                [105.7511358, 22.6092163],
                [105.7508791, 22.6096215],
                [105.7507854, 22.6100665],
                [105.7507942, 22.6109597],
                [105.750744, 22.6113534],
                [105.7503812, 22.6129374],
                [105.7501746, 22.6136034],
                [105.7499065, 22.6140249],
                [105.7493773, 22.6145002],
                [105.748936, 22.6148932],
                [105.748458, 22.6155173],
                [105.74817, 22.615913],
                [105.747727, 22.6163422],
                [105.7473969, 22.6167337],
                [105.7470569, 22.6173286],
                [105.7467983, 22.617795],
                [105.7465802, 22.6180861],
                [105.7462762, 22.6182434],
                [105.7458092, 22.6184975],
                [105.7454681, 22.618843],
                [105.7452448, 22.6191459],
                [105.7449747, 22.6192915],
                [105.7446686, 22.6193487],
                [105.7441796, 22.6195684],
                [105.7438744, 22.6196254],
                [105.7435896, 22.6195896],
                [105.743108, 22.6196124],
                [105.7426143, 22.6197525],
                [105.7419011, 22.62014],
                [105.7415793, 22.6204181],
                [105.7414254, 22.6206918],
                [105.7412097, 22.6210541],
                [105.7410087, 22.6211753],
                [105.7407008, 22.6213008],
                [105.7405927, 22.6214083],
                [105.74051, 22.6216219],
                [105.7405193, 22.6217911],
                [105.7406423, 22.6219733],
                [105.7409432, 22.6222215],
                [105.7411806, 22.6225373],
                [105.7412962, 22.6229472],
                [105.7413517, 22.6235834],
                [105.7412997, 22.6240127],
                [105.7409518, 22.6251696],
                [105.74059, 22.6260625],
                [105.7401459, 22.6271877],
                [105.7397501, 22.6278398],
                [105.7391472, 22.6282965],
                [105.7385492, 22.6284841],
                [105.7376596, 22.6286128],
                [105.7369687, 22.62896],
                [105.7357374, 22.6299048],
                [105.7345566, 22.6305887],
                [105.7331024, 22.6310977],
                [105.7320648, 22.6311883],
                [105.7310195, 22.630937],
                [105.7299397, 22.6304719],
                [105.7289074, 22.6305454],
                [105.7282915, 22.6308327],
                [105.7283174, 22.631205],
                [105.7289206, 22.6318085],
                [105.7294154, 22.6326428],
                [105.729578, 22.6332984],
                [105.7293538, 22.633906],
                [105.7289628, 22.634603],
                [105.7287807, 22.6351426],
                [105.7289412, 22.6356388],
                [105.7290748, 22.6358801],
                [105.7289711, 22.6361121],
                [105.7287007, 22.6362208],
                [105.7282623, 22.6361206],
                [105.7277331, 22.63594],
                [105.7268327, 22.6355674],
                [105.7268923, 22.6369681],
                [105.7284671, 22.6389121],
                [105.729529, 22.6400124],
                [105.7313764, 22.6411978],
                [105.7321853, 22.6415104],
                [105.7332226, 22.6414385],
                [105.7346031, 22.6412875],
                [105.7360601, 22.6413552],
                [105.7377313, 22.6418836],
                [105.7393811, 22.6426623],
                [105.7401404, 22.6436161],
                [105.7403243, 22.6446182],
                [105.7401688, 22.6456604],
                [105.7400907, 22.646436],
                [105.7402886, 22.6484614],
                [105.7404523, 22.6496134],
                [105.7406339, 22.6502266],
                [105.7413907, 22.6510225],
                [105.7429151, 22.6521057],
                [105.7440348, 22.6530328],
                [105.7447897, 22.65393],
                [105.7457904, 22.6555824],
                [105.7465144, 22.6567611],
                [105.7473359, 22.6576781],
                [105.7484703, 22.6588871],
                [105.7484908, 22.6594759],
                [105.7490481, 22.6620983],
                [105.7497034, 22.664046],
                [105.7505522, 22.6652717],
                [105.7514749, 22.6660278],
                [105.7526187, 22.6664748],
                [105.7533566, 22.6673165],
                [105.7542469, 22.6684411],
                [105.7549635, 22.6690447],
                [105.7568634, 22.6699397],
                [105.759468, 22.6709989],
                [105.7601626, 22.6714954],
                [105.7606234, 22.672583],
                [105.7606008, 22.6734413],
                [105.7612255, 22.6742823],
                [105.7618637, 22.6749165],
                [105.7631344, 22.6756706],
                [105.7643465, 22.6768133],
                [105.7654267, 22.6777112],
                [105.76648, 22.6782286],
                [105.7678374, 22.6788788],
                [105.7685374, 22.6798677],
                [105.7691742, 22.6809929],
                [105.7698397, 22.6823361],
                [105.7709867, 22.6838982],
                [105.771888, 22.6842961],
                [105.7728643, 22.6843539],
                [105.7737617, 22.6848996],
                [105.7739185, 22.6854562],
                [105.7739281, 22.6867516],
                [105.7746893, 22.687803],
                [105.7748342, 22.6891044],
                [105.775314, 22.6900468],
                [105.7768494, 22.6919609],
                [105.7741055, 22.6926301],
                [105.7720475, 22.6933085],
                [105.7703822, 22.6936579],
                [105.7686571, 22.69374],
                [105.7678695, 22.6941278],
                [105.7661042, 22.6961906],
                [105.7645406, 22.6958966],
                [105.7632017, 22.6958475],
                [105.7617394, 22.6963674],
                [105.7603568, 22.6974711],
                [105.7589456, 22.698754],
                [105.7569794, 22.7005402],
                [105.755896, 22.7025768],
                [105.7550864, 22.7043198],
                [105.7544333, 22.7061069],
                [105.7532231, 22.7081769],
                [105.7525175, 22.7095441],
                [105.7520152, 22.7104705],
                [105.7517893, 22.7116429],
                [105.751718, 22.7126299],
                [105.7518843, 22.7130555],
                [105.7531687, 22.7139466],
                [105.7540716, 22.7147524],
                [105.7550327, 22.7154368],
                [105.7558082, 22.7164279],
                [105.7561207, 22.7169326],
                [105.7578261, 22.7188178],
                [105.7589961, 22.720007],
                [105.7592322, 22.7205277],
                [105.7590911, 22.7215627],
                [105.7585747, 22.7234671],
                [105.7582632, 22.7252813],
                [105.757852, 22.7260856],
                [105.7573649, 22.7266771],
                [105.7567518, 22.7271705],
                [105.7558215, 22.7279076],
                [105.7552303, 22.7281066],
                [105.7547107, 22.7282097],
                [105.7543164, 22.7277799],
                [105.7535832, 22.7272374],
                [105.7527781, 22.7268428],
                [105.7517268, 22.7266334],
                [105.7505125, 22.7269317],
                [105.7490563, 22.729639],
                [105.7478605, 22.7298066],
                [105.747075, 22.7297413],
                [105.7464081, 22.7317258],
                [105.7460214, 22.7323994],
                [105.7457499, 22.7340285],
                [105.7448899, 22.7360507],
                [105.7436563, 22.737504],
                [105.742605, 22.7393424],
                [105.741803, 22.7396915],
                [105.7401911, 22.741218],
                [105.7392944, 22.7413755],
                [105.7384161, 22.7408652],
                [105.7373977, 22.7403693],
                [105.7361475, 22.7399728],
                [105.7355609, 22.7396668],
                [105.7343258, 22.7396735],
                [105.7336912, 22.7406256],
                [105.7328399, 22.7411388],
                [105.7315956, 22.7398159],
                [105.7301787, 22.739573],
                [105.7292232, 22.7390743],
                [105.7276162, 22.7388038],
                [105.726455, 22.7388148],
                [105.7258894, 22.738495],
                [105.7248398, 22.7366461],
                [105.7244871, 22.7354717],
                [105.7243858, 22.7335868],
                [105.7235291, 22.7321694],
                [105.7227218, 22.7314176],
                [105.7213396, 22.731504],
                [105.7188924, 22.7324171],
                [105.7164643, 22.732835],
                [105.7138978, 22.7334562],
                [105.7117538, 22.7325882],
                [105.7099008, 22.732005],
                [105.709475, 22.7302193],
                [105.7092517, 22.7279908],
                [105.7081271, 22.7280733],
                [105.7067636, 22.7277763],
                [105.705485, 22.7269329],
                [105.704345, 22.7257542],
                [105.7035818, 22.7246757],
                [105.7028996, 22.7234485],
                [105.7020277, 22.7221505],
                [105.7005788, 22.7201035],
                [105.6983633, 22.7242505],
                [105.6962246, 22.7291475],
                [105.6947479, 22.7284457],
                [105.6938322, 22.7267155],
                [105.6930798, 22.7242818],
                [105.6925794, 22.7225069],
                [105.6918462, 22.7208708],
                [105.6912465, 22.7196602],
                [105.6912017, 22.7183213],
                [105.6891022, 22.7189098],
                [105.687971, 22.7190467],
                [105.6864359, 22.7183013],
                [105.6849489, 22.7192867],
                [105.6832027, 22.7198827],
                [105.6817117, 22.7202562],
                [105.6804349, 22.7213288],
                [105.6793401, 22.7215406],
                [105.678122, 22.7226269],
                [105.6758895, 22.7234138],
                [105.6738495, 22.7252224],
                [105.6732722, 22.7260005],
                [105.6716684, 22.726099],
                [105.6706841, 22.7258853],
                [105.6702507, 22.7251735],
                [105.6701442, 22.7243769],
                [105.6691175, 22.7239166],
                [105.6685019, 22.7235183],
                [105.6674194, 22.725155],
                [105.6662548, 22.7249741],
                [105.6648427, 22.7248594],
                [105.6641445, 22.724834],
                [105.6638131, 22.7240306],
                [105.6625659, 22.7235359],
                [105.6617087, 22.722588],
                [105.6614656, 22.7210019],
                [105.6608155, 22.7195005],
                [105.6600711, 22.7186302],
                [105.6587134, 22.7177332],
                [105.6571065, 22.7171346],
                [105.6558068, 22.7165991],
                [105.6550034, 22.715941],
                [105.6547161, 22.7148019],
                [105.6545789, 22.713877],
                [105.6542654, 22.7128111],
                [105.6552202, 22.7118021],
                [105.655096, 22.7105885],
                [105.6540254, 22.7103802],
                [105.6534138, 22.7098194],
                [105.6533029, 22.7085403],
                [105.6521196, 22.7084651],
                [105.6519451, 22.7074874],
                [105.6506337, 22.7064914],
                [105.6487205, 22.7052413],
                [105.6467375, 22.7037408],
                [105.6467643, 22.7023281],
                [105.6456109, 22.7006672],
                [105.6463698, 22.6985656],
                [105.6443264, 22.6995277],
                [105.6427856, 22.699632],
                [105.6403673, 22.6991641],
                [105.6380087, 22.6994568],
                [105.6364597, 22.6991906],
                [105.6356704, 22.6999541],
                [105.6351094, 22.700295],
                [105.6350255, 22.7009644],
                [105.6338378, 22.701183],
                [105.632287, 22.7006084],
                [105.6311948, 22.7008147],
                [105.6305467, 22.7003526],
                [105.6301193, 22.6992266],
                [105.6287249, 22.6993878],
                [105.6269274, 22.6995878],
                [105.6260886, 22.6999158],
                [105.6249832, 22.6993342],
                [105.6242028, 22.7000995],
                [105.6232516, 22.7000539],
                [105.6225314, 22.6995967],
                [105.6213987, 22.7001446],
                [105.6207143, 22.7020423],
                [105.6200187, 22.7027879],
                [105.618751, 22.7028314],
                [105.6178622, 22.7025321],
                [105.6173223, 22.701749],
                [105.6171448, 22.7004764],
                [105.6167302, 22.6991604],
                [105.6148698, 22.6979135],
                [105.6131856, 22.6967358],
                [105.6116593, 22.6954748],
                [105.6117077, 22.6948472],
                [105.6124895, 22.6935278],
                [105.6105598, 22.691839],
                [105.6093367, 22.6915688],
                [105.6083096, 22.6909158],
                [105.6080653, 22.6897795],
                [105.6071761, 22.6891094],
                [105.6063183, 22.6878216],
                [105.6044532, 22.6863938],
                [105.6023035, 22.685223],
                [105.6008332, 22.6842607],
                [105.5996454, 22.6836133],
                [105.5996112, 22.6830352],
                [105.6000496, 22.6825465],
                [105.6005848, 22.6821702],
                [105.6005084, 22.6802093],
                [105.5993095, 22.6798905],
                [105.5983282, 22.6787865],
                [105.5978667, 22.6782204],
                [105.5986708, 22.6772681],
                [105.5988372, 22.6751975],
                [105.5997176, 22.6744312],
                [105.5997911, 22.6733644],
                [105.6010306, 22.6709332],
                [105.6009409, 22.6692262],
                [105.6012439, 22.6682127],
                [105.5985634, 22.667957],
                [105.5971684, 22.6664829],
                [105.5947757, 22.6668677],
                [105.5934035, 22.6671402],
                [105.5924366, 22.6670934],
                [105.5917008, 22.6680066],
                [105.5887063, 22.6679033],
                [105.5870722, 22.6674783],
                [105.5843084, 22.6686094],
                [105.5827807, 22.6692479],
                [105.581084, 22.6696477],
                [105.5806576, 22.6676977],
                [105.5780737, 22.6676239],
                [105.5770365, 22.6655237],
                [105.5728372, 22.6632386],
                [105.568076, 22.6611191],
                [105.5627395, 22.6601944],
                [105.5577338, 22.6590607],
                [105.5576832, 22.6573335],
                [105.5541137, 22.6568496],
                [105.5501268, 22.6573679],
                [105.5468149, 22.6554356],
                [105.5435676, 22.6532202],
                [105.5412862, 22.6491267],
                [105.5411524, 22.6409014],
                [105.5397242, 22.6344792],
                [105.5352304, 22.6335392],
                [105.5315883, 22.6332117],
                [105.5318428, 22.6324467],
                [105.5317878, 22.6315392],
                [105.5311654, 22.6303947],
                [105.5300275, 22.6291086],
                [105.5297146, 22.6280586],
                [105.5299036, 22.6248807],
                [105.5300516, 22.6231604],
                [105.5302537, 22.6221565],
                [105.5299403, 22.621011],
                [105.5291639, 22.6199625],
                [105.5289037, 22.6192468],
                [105.5294169, 22.6188151],
                [105.5310625, 22.6182364],
                [105.532245, 22.6177548],
                [105.5328599, 22.6169884],
                [105.5331117, 22.6155065],
                [105.5340283, 22.6142843],
                [105.5353639, 22.6134677],
                [105.5365941, 22.6120304],
                [105.5382382, 22.6111172],
                [105.5390957, 22.6097957],
                [105.5397067, 22.608026],
                [105.5403206, 22.6070207],
                [105.5415533, 22.6062522],
                [105.5427333, 22.6051972],
                [105.5439636, 22.6038076],
                [105.5453989, 22.6022262],
                [105.5461156, 22.6011728],
                [105.5475538, 22.6003079],
                [105.5489795, 22.5992662],
                [105.5493879, 22.5984049],
                [105.5493334, 22.5976407],
                [105.5490724, 22.5967339],
                [105.5478294, 22.594875],
                [105.5474655, 22.5939685],
                [105.5470129, 22.5925871],
                [105.5468401, 22.5920597],
                [105.5463676, 22.5895952],
                [105.5463627, 22.5883531],
                [105.5467181, 22.5871098],
                [105.547537, 22.5859127],
                [105.5483038, 22.5845246],
                [105.5488649, 22.5832329],
                [105.5492171, 22.5811774],
                [105.550185, 22.5800063],
                [105.5513122, 22.5786647],
                [105.5516153, 22.5772305],
                [105.5518165, 22.5760354],
                [105.5533567, 22.5750269],
                [105.5545373, 22.5747547],
                [105.555671, 22.5744934],
                [105.55783, 22.5737693],
                [105.5588042, 22.5728105],
                [105.5599788, 22.5705133],
                [105.5607864, 22.5685774],
                [105.5626309, 22.5664689],
                [105.5635492, 22.5644591],
                [105.5635436, 22.5630738],
                [105.5631783, 22.5618329],
                [105.5643092, 22.5614946],
                [105.5656471, 22.5613943],
                [105.5701281, 22.5621429],
                [105.5716564, 22.5622774],
                [105.5736099, 22.5616971],
                [105.5748276, 22.5610279],
                [105.5759721, 22.5603988],
                [105.5779742, 22.5591495],
                [105.5802151, 22.5570984],
                [105.5820581, 22.5547507],
                [105.5828205, 22.5533881],
                [105.5845174, 22.5530476],
                [105.5853897, 22.5524234],
                [105.5860004, 22.5507968],
                [105.5874339, 22.5489763],
                [105.5880962, 22.5473973],
                [105.5885513, 22.5454847],
                [105.5901875, 22.5442537],
                [105.5909553, 22.5432954],
                [105.5912587, 22.5420043],
                [105.5915077, 22.5400448],
                [105.5915739, 22.5395912],
                [105.591656, 22.5390283],
                [105.5918076, 22.5379894],
                [105.5928302, 22.5364091],
                [105.594402, 22.534192],
                [105.5950127, 22.5326132],
                [105.595415, 22.5304141],
                [105.5955632, 22.5289804],
                [105.5952976, 22.5270226],
                [105.5951889, 22.5256854],
                [105.5965698, 22.5237215],
                [105.5979033, 22.5227133],
                [105.5979505, 22.5217099],
                [105.5972237, 22.5202317],
                [105.5971113, 22.5190254],
                [105.5972608, 22.5178783],
                [105.596688, 22.5163039],
                [105.5958102, 22.5155906],
                [105.5944705, 22.5151178],
                [105.5937963, 22.5138781],
                [105.5937342, 22.5113942],
                [105.5926962, 22.5092959],
                [105.5916624, 22.5081532],
                [105.5920691, 22.5070051],
                [105.5939149, 22.5055174],
                [105.5954537, 22.5044129],
                [105.5961689, 22.5033024],
                [105.5957009, 22.5021575],
                [105.594873, 22.5010617],
                [105.5939112, 22.5002468],
                [105.5933955, 22.4999621],
                [105.592855, 22.4990227],
                [105.5935562, 22.4990228],
                [105.5940364, 22.498606],
                [105.5943362, 22.4982425],
                [105.5943207, 22.4966613],
                [105.5941642, 22.4953194],
                [105.5942046, 22.4943886],
                [105.5942384, 22.4940096],
                [105.5945443, 22.4930036],
                [105.5946586, 22.4927892],
                [105.5955125, 22.4914353],
                [105.5967208, 22.4900389],
                [105.5973389, 22.4895095],
                [105.5978073, 22.4891783],
                [105.5983084, 22.4890819],
                [105.5967491, 22.4883587],
                [105.5956306, 22.4879674],
                [105.5952759, 22.4879193],
                [105.5948497, 22.4877233],
                [105.5942361, 22.4872479],
                [105.5935327, 22.4864844],
                [105.5932213, 22.486222],
                [105.592493, 22.4858459],
                [105.5907358, 22.4852759],
                [105.5898666, 22.4851143],
                [105.5893515, 22.4848856],
                [105.5883824, 22.4841314],
                [105.5880085, 22.4837456],
                [105.5875447, 22.4830556],
                [105.5868855, 22.4822426],
                [105.5857556, 22.4812089],
                [105.5850532, 22.4806679],
                [105.5833201, 22.47948],
                [105.5828764, 22.4793415],
                [105.5826369, 22.4793177],
                [105.5810949, 22.4793151],
                [105.5806958, 22.4792341],
                [105.5804648, 22.4791114],
                [105.5737172, 22.4739633],
                [105.5729346, 22.4733155],
                [105.5724017, 22.4730291],
                [105.5714875, 22.4726617],
                [105.5711678, 22.4724899],
                [105.5708479, 22.4722769],
                [105.5701986, 22.4717027],
                [105.5684994, 22.4700862],
                [105.5675544, 22.4687058],
                [105.566432, 22.4672602],
                [105.5649554, 22.4655905],
                [105.5646629, 22.465177],
                [105.5639493, 22.4637557],
                [105.5636668, 22.4634233],
                [105.5634918, 22.4632977],
                [105.5632102, 22.4631996],
                [105.5615901, 22.4629439],
                [105.5607463, 22.4628748],
                [105.5602514, 22.4627593],
                [105.5599208, 22.4625172],
                [105.5596463, 22.4617612],
                [105.5592803, 22.4599422],
                [105.5589594, 22.457285],
                [105.5587555, 22.4547896],
                [105.55901, 22.4505534],
                [105.5589292, 22.4497517],
                [105.5560823, 22.4456975],
                [105.5558371, 22.4449954],
                [105.5555443, 22.4420588],
                [105.5555518, 22.4415091],
                [105.5556075, 22.440887],
                [105.5559434, 22.4400568],
                [105.5561345, 22.4393714],
                [105.5562382, 22.438614],
                [105.5562372, 22.4383707],
                [105.5561684, 22.4381457],
                [105.5558764, 22.4378223],
                [105.5556433, 22.4377059],
                [105.5553716, 22.4376438],
                [105.5528708, 22.4376885],
                [105.5499441, 22.4378607],
                [105.5489647, 22.4377739],
                [105.5487512, 22.4377025],
                [105.5486145, 22.4374596],
                [105.5488455, 22.4370444],
                [105.5494241, 22.4362764],
                [105.552167, 22.4312929],
                [105.5529856, 22.4299474],
                [105.5536222, 22.4291702],
                [105.5545876, 22.4289688],
                [105.5543455, 22.4282216],
                [105.5542953, 22.4278072],
                [105.553865, 22.4268355],
                [105.5535625, 22.4263409],
                [105.5530467, 22.425793],
                [105.5527836, 22.4254334],
                [105.5522462, 22.4243719],
                [105.5519398, 22.4228681],
                [105.5517928, 22.4224541],
                [105.551363, 22.4215995],
                [105.5511928, 22.4212994],
                [105.5503368, 22.4205998],
                [105.550279, 22.419634],
                [105.5503187, 22.4190882],
                [105.5505081, 22.4185662],
                [105.5505581, 22.4179239],
                [105.5511967, 22.4171354],
                [105.5512246, 22.4169692],
                [105.5510199, 22.4163937],
                [105.5512336, 22.4160402],
                [105.5513453, 22.4155225],
                [105.5516335, 22.4148395],
                [105.5521997, 22.4140967],
                [105.5525481, 22.4126492],
                [105.5528116, 22.4121309],
                [105.5531647, 22.411871],
                [105.5535561, 22.4117168],
                [105.5542257, 22.4115499],
                [105.5549461, 22.4114416],
                [105.555642, 22.4115215],
                [105.5558817, 22.4113678],
                [105.5563615, 22.4111899],
                [105.5569673, 22.4108702],
                [105.5574083, 22.4104743],
                [105.5577473, 22.4103955],
                [105.5583531, 22.4100947],
                [105.5586688, 22.410057],
                [105.5590541, 22.4100898],
                [105.5594213, 22.4101908],
                [105.5610705, 22.4113573],
                [105.5621761, 22.4126154],
                [105.5632454, 22.4139929],
                [105.5644791, 22.4151824],
                [105.565806, 22.4167637],
                [105.5670567, 22.417629],
                [105.5676631, 22.4178826],
                [105.5712035, 22.4192687],
                [105.5706279, 22.4183516],
                [105.5700921, 22.4176776],
                [105.5698682, 22.4174261],
                [105.5696929, 22.4172375],
                [105.569401, 22.4169502],
                [105.569206, 22.4166715],
                [105.5690399, 22.4163387],
                [105.5687864, 22.4159611],
                [105.5683471, 22.4152057],
                [105.5682007, 22.4149359],
                [105.5680928, 22.4146389],
                [105.5680042, 22.4142878],
                [105.5677603, 22.4138831],
                [105.5674475, 22.4132354],
                [105.5673202, 22.4129294],
                [105.5671056, 22.4125697],
                [105.56693, 22.412291],
                [105.5668313, 22.4118408],
                [105.5666941, 22.4114989],
                [105.5665571, 22.4111389],
                [105.5664683, 22.4107697],
                [105.566351, 22.4105179],
                [105.565912, 22.4097985],
                [105.5658138, 22.4094834],
                [105.5656473, 22.4090514],
                [105.5655869, 22.4084929],
                [105.5652529, 22.4073947],
                [105.5650664, 22.4068187],
                [105.5649389, 22.4064317],
                [105.5647723, 22.4059907],
                [105.5645379, 22.4055409],
                [105.5640299, 22.4045244],
                [105.5636396, 22.4038679],
                [105.5632701, 22.4035629],
                [105.5630174, 22.4033925],
                [105.5626291, 22.4032046],
                [105.5621437, 22.4030171],
                [105.5615423, 22.402884],
                [105.5601458, 22.4026366],
                [105.5597872, 22.4026197],
                [105.5591184, 22.402595],
                [105.5582849, 22.4025709],
                [105.5574224, 22.4025828],
                [105.5565697, 22.4026129],
                [105.5560369, 22.4026507],
                [105.5551266, 22.4028341],
                [105.5543908, 22.4030078],
                [105.5533356, 22.4032908],
                [105.5522901, 22.4035827],
                [105.5509934, 22.4040738],
                [105.5498515, 22.4044832],
                [105.5486412, 22.4047846],
                [105.5477793, 22.4049317],
                [105.5473241, 22.4050234],
                [105.546927, 22.4050698],
                [105.5466073, 22.4050799],
                [105.5461808, 22.4050814],
                [105.5454249, 22.4050749],
                [105.5446399, 22.4050775],
                [105.5442718, 22.4051238],
                [105.5436809, 22.4052069],
                [105.5431, 22.405353],
                [105.5427517, 22.4055074],
                [105.5424519, 22.4056525],
                [105.5421038, 22.405852],
                [105.5417757, 22.4062226],
                [105.5414377, 22.4065391],
                [105.5411284, 22.4067564],
                [105.5407706, 22.4069469],
                [105.5401607, 22.4071111],
                [105.5394053, 22.4072308],
                [105.5388532, 22.4073498],
                [105.5382723, 22.4074778],
                [105.5374877, 22.4075976],
                [105.53711, 22.4076529],
                [105.5367128, 22.4076903],
                [105.5364511, 22.4076911],
                [105.5359763, 22.4077197],
                [105.5356467, 22.4077028],
                [105.5354042, 22.4076316],
                [105.5352099, 22.407506],
                [105.5349572, 22.4073176],
                [105.5346944, 22.4070301],
                [105.5341774, 22.4061307],
                [105.5338844, 22.405564],
                [105.5337092, 22.4053663],
                [105.5334856, 22.4051688],
                [105.5330286, 22.4047918],
                [105.5327275, 22.4046126],
                [105.532446, 22.4044694],
                [105.5321646, 22.4043892],
                [105.5318253, 22.4043452],
                [105.5314473, 22.4043374],
                [105.5311081, 22.4043566],
                [105.5308175, 22.4043846],
                [105.5304492, 22.4043677],
                [105.5297418, 22.4044061],
                [105.5293447, 22.4044705],
                [105.5291026, 22.4045074],
                [105.5286765, 22.4045989],
                [105.5282987, 22.4046541],
                [105.5279887, 22.4046732],
                [105.5275624, 22.4047016],
                [105.5271458, 22.40473],
                [105.5267097, 22.4047314],
                [105.5263414, 22.4047237],
                [105.5258566, 22.4046441],
                [105.5255171, 22.4045732],
                [105.5250225, 22.4044846],
                [105.5245665, 22.4043509],
                [105.5240912, 22.4042353],
                [105.5236547, 22.4041196],
                [105.523354, 22.4040755],
                [105.5231117, 22.4040492],
                [105.5228595, 22.404005],
                [105.5224038, 22.4039434],
                [105.522075, 22.4037196],
                [105.5216359, 22.4033061],
                [105.5214509, 22.4030994],
                [105.5213243, 22.4029286],
                [105.5212557, 22.4027216],
                [105.5212159, 22.4024333],
                [105.521205, 22.402127],
                [105.5210089, 22.4014968],
                [105.5208337, 22.4013081],
                [105.520649, 22.4011555],
                [105.5203483, 22.4010573],
                [105.5200476, 22.4009953],
                [105.5197568, 22.4009872],
                [105.5195532, 22.4009518],
                [105.5191556, 22.400881],
                [105.518787, 22.400792],
                [105.5185831, 22.4006936],
                [105.5184373, 22.4005679],
                [105.5182913, 22.4003971],
                [105.5182422, 22.4002171],
                [105.518251, 22.3999828],
                [105.5183276, 22.3997212],
                [105.5184623, 22.3994775],
                [105.5186742, 22.3991253],
                [105.5191659, 22.3984569],
                [105.5193878, 22.3981588],
                [105.5195031, 22.3978971],
                [105.5196474, 22.3976083],
                [105.5198486, 22.3970129],
                [105.519925, 22.3966972],
                [105.5199243, 22.396508],
                [105.5198558, 22.396319],
                [105.5197388, 22.3961481],
                [105.5196342, 22.3960467],
                [105.51879, 22.395401],
                [105.5182386, 22.3949964],
                [105.5180685, 22.3947478],
                [105.5180675, 22.3944856],
                [105.5184462, 22.3939469],
                [105.5188111, 22.3935132],
                [105.519021, 22.3931061],
                [105.5191748, 22.3927516],
                [105.5192869, 22.3925677],
                [105.5196805, 22.3922388],
                [105.5200031, 22.3918313],
                [105.5200666, 22.3916356],
                [105.5200803, 22.3915512],
                [105.5200789, 22.3911909],
                [105.5200467, 22.3903529],
                [105.519977, 22.3898214],
                [105.5197816, 22.3893985],
                [105.5196541, 22.3889844],
                [105.5196144, 22.3887232],
                [105.5195063, 22.388336],
                [105.519466, 22.3879307],
                [105.5193277, 22.3872102],
                [105.5192204, 22.3870033],
                [105.5190934, 22.3867424],
                [105.5189183, 22.3865537],
                [105.5187529, 22.386392],
                [105.51851, 22.3862036],
                [105.5181507, 22.3859884],
                [105.5178205, 22.3857912],
                [105.5173449, 22.3855675],
                [105.5171509, 22.3855321],
                [105.5168986, 22.3854338],
                [105.516714, 22.3853082],
                [105.5165584, 22.3851465],
                [105.5164318, 22.3849847],
                [105.5163823, 22.3846875],
                [105.5163507, 22.3839846],
                [105.5163486, 22.383426],
                [105.5162694, 22.3829666],
                [105.5162394, 22.3827144],
                [105.5161703, 22.3823723],
                [105.5160415, 22.3816157],
                [105.515963, 22.3813366],
                [105.5158361, 22.3811027],
                [105.5157287, 22.3808867],
                [105.5155724, 22.3805178],
                [105.5155232, 22.3803197],
                [105.5153572, 22.3799778],
                [105.5151614, 22.3794377],
                [105.5147904, 22.3786639],
                [105.5144293, 22.3779802],
                [105.514079, 22.3775668],
                [105.5139328, 22.3773149],
                [105.5137962, 22.3770721],
                [105.5136692, 22.3767751],
                [105.5136001, 22.3764509],
                [105.5135699, 22.3761446],
                [105.5135687, 22.3758022],
                [105.5135949, 22.3750272],
                [105.5135932, 22.3745495],
                [105.5135534, 22.3742883],
                [105.5136374, 22.3733869],
                [105.5136844, 22.3730083],
                [105.5137315, 22.3726477],
                [105.5138833, 22.3717641],
                [105.5139789, 22.3714124],
                [105.5140843, 22.3710966],
                [105.5142862, 22.3706544],
                [105.5144594, 22.3703475],
                [105.5145939, 22.3700226],
                [105.5149216, 22.369562],
                [105.515163, 22.3693359],
                [105.5154336, 22.3691639],
                [105.5157046, 22.3690729],
                [105.5159853, 22.369009],
                [105.5161693, 22.3689633],
                [105.5164014, 22.3688634],
                [105.5166138, 22.3686375],
                [105.5167678, 22.3683757],
                [105.5168446, 22.3681862],
                [105.5168922, 22.3679427],
                [105.5169007, 22.3676183],
                [105.5168993, 22.3672398],
                [105.5169259, 22.3665818],
                [105.5169151, 22.3662665],
                [105.5168845, 22.3658521],
                [105.5166795, 22.3654653],
                [105.5164349, 22.3648082],
                [105.5163465, 22.3644841],
                [105.5163165, 22.3642048],
                [105.5161598, 22.3637637],
                [105.5160712, 22.3633856],
                [105.5159536, 22.3630164],
                [105.5158264, 22.3626744],
                [105.5157471, 22.3622061],
                [105.5157361, 22.3618457],
                [105.5157443, 22.3614491],
                [105.5158778, 22.3608539],
                [105.5158765, 22.3604845],
                [105.5158851, 22.3601961],
                [105.5158257, 22.3598629],
                [105.5156886, 22.3594758],
                [105.5153478, 22.3590173],
                [105.5151727, 22.3588016],
                [105.5149489, 22.358541],
                [105.5142684, 22.3578944],
                [105.5139378, 22.357553],
                [105.5136653, 22.3572294],
                [105.5134512, 22.3569778],
                [105.5133049, 22.3567079],
                [105.5132072, 22.3564829],
                [105.5131867, 22.3561766],
                [105.5132532, 22.3558159],
                [105.5134165, 22.3554369],
                [105.5134838, 22.3552925],
                [105.5135506, 22.355013],
                [105.5136249, 22.3541476],
                [105.5136724, 22.3538771],
                [105.5137388, 22.3535165],
                [105.5137669, 22.353246],
                [105.5138339, 22.3530115],
                [105.5139771, 22.3524344],
                [105.5143589, 22.3509012],
                [105.5143524, 22.3502732],
                [105.5143075, 22.3500077],
                [105.5143281, 22.3496296],
                [105.5142717, 22.3492415],
                [105.5141969, 22.3489034],
                [105.5141088, 22.3486603],
                [105.5139499, 22.3483332],
                [105.5137475, 22.3479474],
                [105.5129988, 22.3472109],
                [105.5121149, 22.3465378],
                [105.5118623, 22.3463494],
                [105.5115228, 22.3462242],
                [105.5111542, 22.3460903],
                [105.5108148, 22.3459922],
                [105.5105046, 22.3459301],
                [105.510146, 22.3458952],
                [105.5096326, 22.3458788],
                [105.5090415, 22.3458536],
                [105.50754, 22.3458583],
                [105.5071914, 22.3458864],
                [105.5068138, 22.3459597],
                [105.5062524, 22.3460786],
                [105.5059038, 22.3461247],
                [105.5055649, 22.3461528],
                [105.5051515, 22.3462058],
                [105.5050089, 22.3461905],
                [105.5046831, 22.3460835],
                [105.5044212, 22.3459671],
                [105.5038581, 22.3456535],
                [105.5034308, 22.3453394],
                [105.502877, 22.3448997],
                [105.5021874, 22.3443881],
                [105.5019351, 22.3442537],
                [105.5016924, 22.3441283],
                [105.50145, 22.344057],
                [105.5011592, 22.3439948],
                [105.5009945, 22.3439863],
                [105.5007523, 22.3439961],
                [105.5004909, 22.3440148],
                [105.500411, 22.3440286],
                [105.5000786, 22.3439732],
                [105.4993666, 22.3436275],
                [105.498638, 22.3432097],
                [105.4978929, 22.3425625],
                [105.4972768, 22.3421027],
                [105.496637, 22.341599],
                [105.4962811, 22.3412044],
                [105.4959723, 22.3407876],
                [105.4958996, 22.3402603],
                [105.4961334, 22.3395561],
                [105.496769, 22.3388286],
                [105.4974043, 22.3380572],
                [105.4975676, 22.3374632],
                [105.4976812, 22.3361877],
                [105.4978796, 22.3347555],
                [105.4980954, 22.3339515],
                [105.4982445, 22.3332142],
                [105.4982182, 22.3327675],
                [105.4980651, 22.3319075],
                [105.4977354, 22.330513],
                [105.4973797, 22.3288628],
                [105.4970728, 22.3268403],
                [105.4968927, 22.3253757],
                [105.4968741, 22.324938],
                [105.4968382, 22.3240966],
                [105.4967102, 22.3232365],
                [105.4967327, 22.3225155],
                [105.4970046, 22.3217006],
                [105.4974761, 22.3207921],
                [105.4976497, 22.3204427],
                [105.4975987, 22.3201406],
                [105.4975986, 22.3201172],
                [105.4975233, 22.3200012],
                [105.497298, 22.3198856],
                [105.4969727, 22.319747],
                [105.496523, 22.3197484],
                [105.4960234, 22.3197733],
                [105.4949492, 22.3197765],
                [105.4945241, 22.3196615],
                [105.4940986, 22.3194535],
                [105.4935973, 22.3189667],
                [105.4930461, 22.3185264],
                [105.4921956, 22.3182267],
                [105.4914959, 22.3181823],
                [105.4909214, 22.3182072],
                [105.4902971, 22.3183022],
                [105.4894487, 22.3185838],
                [105.4880763, 22.3190531],
                [105.4874271, 22.3191713],
                [105.4870273, 22.3191493],
                [105.4865768, 22.3188948],
                [105.486176, 22.3186169],
                [105.4855997, 22.318107],
                [105.4850491, 22.3178296],
                [105.4833733, 22.3172531],
                [105.4826475, 22.3169064],
                [105.4818956, 22.3161876],
                [105.4813682, 22.3153984],
                [105.481016, 22.3146553],
                [105.480663, 22.313726],
                [105.4804342, 22.3125638],
                [105.479928, 22.3106349],
                [105.4791735, 22.3091719],
                [105.4788709, 22.3083355],
                [105.4787185, 22.3075917],
                [105.4787409, 22.3068474],
                [105.4787596, 22.3062495],
                [105.4787634, 22.3061264],
                [105.4789354, 22.3052654],
                [105.4796059, 22.3041005],
                [105.4807024, 22.303353],
                [105.4818988, 22.3025819],
                [105.48287, 22.301672],
                [105.4840392, 22.300273],
                [105.4849332, 22.2987353],
                [105.4853301, 22.2979201],
                [105.4858527, 22.2973604],
                [105.4863263, 22.2970799],
                [105.4869247, 22.2967525],
                [105.4885958, 22.2960265],
                [105.4895435, 22.2956282],
                [105.4906154, 22.2949737],
                [105.4914374, 22.29432],
                [105.4918608, 22.2939699],
                [105.4919848, 22.2937137],
                [105.4919844, 22.2935974],
                [105.4918842, 22.2935047],
                [105.4916593, 22.2934821],
                [105.4909097, 22.2934146],
                [105.4900724, 22.2934171],
                [105.4895608, 22.2934187],
                [105.4888364, 22.2933976],
                [105.4878864, 22.2931446],
                [105.4870356, 22.2927286],
                [105.4848326, 22.2912932],
                [105.4830305, 22.290252],
                [105.4815787, 22.289326],
                [105.4805526, 22.2887475],
                [105.479702, 22.2883547],
                [105.4786268, 22.2880322],
                [105.4778022, 22.2879184],
                [105.4765025, 22.2876664],
                [105.4752274, 22.287298],
                [105.4735769, 22.2866981],
                [105.4719496, 22.2855864],
                [105.4714742, 22.285332],
                [105.470949, 22.2851475],
                [105.4703496, 22.2851492],
                [105.4697001, 22.2851278],
                [105.4688008, 22.2850839],
                [105.4679011, 22.2849237],
                [105.4667264, 22.2846945],
                [105.4659765, 22.2845106],
                [105.4648276, 22.2845139],
                [105.4637281, 22.2843543],
                [105.4622289, 22.2841725],
                [105.4609674, 22.2840141],
                [105.4607795, 22.2839905],
                [105.4603047, 22.2839221],
                [105.4601471, 22.2838388],
                [105.4601296, 22.2838296],
                [105.4602286, 22.2835502],
                [105.4605268, 22.283061],
                [105.4605758, 22.2829664],
                [105.4609735, 22.2821991],
                [105.4614208, 22.2815234],
                [105.4620435, 22.2809866],
                [105.4627414, 22.2805428],
                [105.4638888, 22.2800744],
                [105.4651856, 22.2794892],
                [105.4662333, 22.2790908],
                [105.4671308, 22.2786231],
                [105.4677785, 22.2781095],
                [105.4688746, 22.2772459],
                [105.4700704, 22.2763353],
                [105.471216, 22.2753551],
                [105.4720626, 22.2746085],
                [105.4726103, 22.2740719],
                [105.4728331, 22.2734899],
                [105.4728318, 22.2730945],
                [105.4727809, 22.2728155],
                [105.4725304, 22.2725837],
                [105.4719304, 22.2723993],
                [105.4713304, 22.2722383],
                [105.4707302, 22.2719842],
                [105.4700541, 22.2714745],
                [105.4692277, 22.2707792],
                [105.4682815, 22.2697565],
                [105.4677244, 22.2693415],
                [105.4664463, 22.2680194],
                [105.4644661, 22.2658156],
                [105.4638647, 22.2652127],
                [105.4632641, 22.2648422],
                [105.462764, 22.2646344],
                [105.4623143, 22.2645892],
                [105.4618652, 22.2647067],
                [105.4611419, 22.2649878],
                [105.4604688, 22.2653619],
                [105.4598207, 22.2657358],
                [105.4592233, 22.2663189],
                [105.4582766, 22.2670427],
                [105.4573789, 22.2674638],
                [105.4560318, 22.2679095],
                [105.4551834, 22.2681677],
                [105.4545845, 22.2682857],
                [105.4541848, 22.2682636],
                [105.45381, 22.2682181],
                [105.4534846, 22.2679864],
                [105.4532072, 22.2671499],
                [105.4527546, 22.2661743],
                [105.4524286, 22.2657799],
                [105.4519277, 22.2653393],
                [105.4512522, 22.2649459],
                [105.450702, 22.2647148],
                [105.4502023, 22.2646232],
                [105.449753, 22.2647174],
                [105.449304, 22.2648815],
                [105.4484309, 22.265163],
                [105.4483809, 22.2651631],
                [105.4478314, 22.2651414],
                [105.4466823, 22.2650282],
                [105.4457329, 22.2648913],
                [105.4446833, 22.2646849],
                [105.4441583, 22.2645235],
                [105.443783, 22.2642919],
                [105.4433824, 22.2639674],
                [105.4432066, 22.2636423],
                [105.4430053, 22.2631776],
                [105.4426545, 22.2628065],
                [105.4415789, 22.262228],
                [105.440604, 22.2619282],
                [105.4398541, 22.261721],
                [105.4391295, 22.2615834],
                [105.4382798, 22.2613996],
                [105.4378545, 22.2611682],
                [105.4373539, 22.2607974],
                [105.4366705, 22.2598573],
                [105.436326, 22.2594919],
                [105.4358816, 22.259121],
                [105.4357375, 22.2589528],
                [105.4356551, 22.2587809],
                [105.4358361, 22.2584961],
                [105.4359565, 22.2582703],
                [105.4364135, 22.2574605],
                [105.4368535, 22.2566351],
                [105.4372262, 22.2560316],
                [105.4375998, 22.2557611],
                [105.4381777, 22.2554742],
                [105.4385341, 22.2551562],
                [105.4386867, 22.2549814],
                [105.4388221, 22.2547274],
                [105.4388217, 22.2546006],
                [105.4388041, 22.2544262],
                [105.4386502, 22.254173],
                [105.4381549, 22.2536353],
                [105.4378035, 22.2532286],
                [105.4377449, 22.2531608],
                [105.4375228, 22.2529078],
                [105.4373683, 22.2524643],
                [105.4373502, 22.2521314],
                [105.4373992, 22.2514655],
                [105.4376018, 22.2509259],
                [105.4379744, 22.2503383],
                [105.4380417, 22.2500686],
                [105.4380746, 22.2497039],
                [105.4380733, 22.2493076],
                [105.4379176, 22.2484678],
                [105.4378477, 22.2479131],
                [105.4379651, 22.2473421],
                [105.4380985, 22.246454],
                [105.4382663, 22.2456767],
                [105.438316, 22.2452485],
                [105.4383318, 22.244868],
                [105.4383311, 22.2446144],
                [105.4382966, 22.244456],
                [105.4382108, 22.2442659],
                [105.4379035, 22.2439338],
                [105.4373744, 22.2434914],
                [105.4370839, 22.24308],
                [105.4368609, 22.2425416],
                [105.4367912, 22.2420186],
                [105.4367899, 22.2415747],
                [105.4367372, 22.2410675],
                [105.4365825, 22.2405924],
                [105.4363773, 22.24026],
                [105.436138, 22.2399277],
                [105.4360181, 22.2396744],
                [105.4360005, 22.2395001],
                [105.4360512, 22.2393731],
                [105.4361869, 22.2392617],
                [105.4368667, 22.2389112],
                [105.4385152, 22.2381775],
                [105.439314, 22.2377949],
                [105.4395348, 22.2376358],
                [105.4396872, 22.2373975],
                [105.4397715, 22.2371437],
                [105.4397696, 22.2365412],
                [105.4398534, 22.2361289],
                [105.4400395, 22.2357478],
                [105.4403272, 22.2352556],
                [105.4407511, 22.2347313],
                [105.4409881, 22.2343185],
                [105.4410548, 22.2339062],
                [105.4411535, 22.232812],
                [105.4411348, 22.2323048],
                [105.4410995, 22.2318927],
                [105.441132, 22.2314011],
                [105.4412327, 22.2309253],
                [105.4412829, 22.2306556],
                [105.4412993, 22.2304812],
                [105.441299, 22.2303703],
                [105.4412647, 22.2302752],
                [105.4412135, 22.2302436],
                [105.4410773, 22.230244],
                [105.4407035, 22.2304194],
                [105.4400577, 22.2307065],
                [105.4397004, 22.2307233],
                [105.4393941, 22.2307242],
                [105.4390195, 22.2306776],
                [105.4384911, 22.2304254],
                [105.4380818, 22.2301411],
                [105.4373478, 22.2294138],
                [105.4369205, 22.2288442],
                [105.4365623, 22.2285599],
                [105.43617, 22.2282915],
                [105.4357948, 22.228023],
                [105.4356069, 22.2278015],
                [105.4354529, 22.2275324],
                [105.4354183, 22.2273423],
                [105.4353321, 22.2269779],
                [105.4353644, 22.2264388],
                [105.4355634, 22.2247262],
                [105.4357475, 22.2237269],
                [105.4357628, 22.223172],
                [105.4357274, 22.2227282],
                [105.4354874, 22.2221739],
                [105.4348365, 22.2207965],
                [105.4343573, 22.2199258],
                [105.4342543, 22.219609],
                [105.4342364, 22.2193396],
                [105.4343375, 22.2190064],
                [105.4345406, 22.2186254],
                [105.4347094, 22.2181969],
                [105.4347767, 22.2179589],
                [105.4347757, 22.2176259],
                [105.4347238, 22.2173725],
                [105.4345867, 22.2170241],
                [105.4344156, 22.2167233],
                [105.4342449, 22.2165494],
                [105.4339719, 22.2163282],
                [105.4336312, 22.2162023],
                [105.4331202, 22.2160451],
                [105.4328135, 22.2159191],
                [105.4325752, 22.2159038],
                [105.4322012, 22.2159999],
                [105.4318617, 22.2162704],
                [105.4316919, 22.2163976],
                [105.4314878, 22.2164141],
                [105.4311707, 22.216375],
                [105.4311192, 22.2163239],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.2323669, 9.2137108],
                [105.2335766, 9.2130661],
                [105.2348573, 9.2123803],
                [105.2353227, 9.2121513],
                [105.2352912, 9.2119157],
                [105.2352369, 9.2116536],
                [105.2364787, 9.211831],
                [105.2364787, 9.211876],
                [105.2365136, 9.2119025],
                [105.2374095, 9.2119978],
                [105.2377179, 9.2114815],
                [105.2378695, 9.2111955],
                [105.2379473, 9.2110698],
                [105.2380784, 9.2108888],
                [105.2383026, 9.2106726],
                [105.2385523, 9.2105008],
                [105.2388632, 9.2103351],
                [105.2389839, 9.2102636],
                [105.2390698, 9.2101789],
                [105.2391744, 9.2100438],
                [105.239446, 9.2097003],
                [105.239694, 9.2094143],
                [105.23979, 9.2092889],
                [105.2398912, 9.2091231],
                [105.2399447, 9.2089654],
                [105.2399576, 9.208761],
                [105.2399254, 9.2085532],
                [105.2398637, 9.2083626],
                [105.239684, 9.2080025],
                [105.2394587, 9.2075736],
                [105.2393219, 9.2072426],
                [105.2392226, 9.2070123],
                [105.2391744, 9.2067846],
                [105.2391797, 9.2065039],
                [105.2391797, 9.2062445],
                [105.2392226, 9.2059267],
                [105.2392092, 9.2057838],
                [105.2391019, 9.2053813],
                [105.2390295, 9.2050662],
                [105.2390188, 9.2049736],
                [105.2390215, 9.2048783],
                [105.2390376, 9.2047829],
                [105.2390617, 9.204685],
                [105.2391234, 9.2045023],
                [105.2394801, 9.2037451],
                [105.2395499, 9.2035121],
                [105.2396035, 9.2032791],
                [105.2396411, 9.2030725],
                [105.2396464, 9.202919],
                [105.2396464, 9.2024583],
                [105.2396491, 9.2022743],
                [105.2396679, 9.2020505],
                [105.2397229, 9.2016587],
                [105.2398181, 9.2009835],
                [105.2398476, 9.200446],
                [105.2402607, 9.2003189],
                [105.2404913, 9.2002342],
                [105.2407247, 9.200131],
                [105.2409875, 9.1999747],
                [105.2412531, 9.1998225],
                [105.2414596, 9.1997457],
                [105.2415387, 9.1999205],
                [105.2416286, 9.2000833],
                [105.2417493, 9.2002766],
                [105.2419183, 9.2005043],
                [105.2421758, 9.2008114],
                [105.2425942, 9.2012403],
                [105.2431816, 9.2018334],
                [105.2432728, 9.2019632],
                [105.2433211, 9.2020664],
                [105.2433586, 9.2021909],
                [105.2437529, 9.2023868],
                [105.2438816, 9.2024345],
                [105.2440265, 9.2024556],
                [105.2442008, 9.2024053],
                [105.2450967, 9.2021088],
                [105.2465411, 9.201611],
                [105.2466483, 9.2015342],
                [105.2464887, 9.2013727],
                [105.2463157, 9.2012311],
                [105.2461991, 9.2011212],
                [105.2461454, 9.2010391],
                [105.2461132, 9.200961],
                [105.2460945, 9.2008624],
                [105.2460931, 9.2007452],
                [105.2461114, 9.200639],
                [105.2461642, 9.20054],
                [105.2463627, 9.2004593],
                [105.2464861, 9.2004407],
                [105.2467422, 9.2004222],
                [105.2470694, 9.200364],
                [105.2474597, 9.2003242],
                [105.2482513, 9.2000998],
                [105.2483971, 9.2000608],
                [105.2485366, 9.2000396],
                [105.2486821, 9.200035],
                [105.2492622, 9.2000588],
                [105.2494734, 9.200072],
                [105.2495559, 9.2000714],
                [105.2496236, 9.2000595],
                [105.249673, 9.2000398],
                [105.2497222, 9.1999999],
                [105.2497664, 9.1999377],
                [105.2498093, 9.1998529],
                [105.2498603, 9.1997523],
                [105.2499086, 9.1996808],
                [105.249981, 9.1996034],
                [105.2501352, 9.1994862],
                [105.250335, 9.1993631],
                [105.2504638, 9.1993102],
                [105.2508889, 9.1991539],
                [105.251314, 9.1989673],
                [105.2514213, 9.1989262],
                [105.2515206, 9.1989024],
                [105.2516413, 9.1988799],
                [105.2517647, 9.198864],
                [105.2521167, 9.1988481],
                [105.2522092, 9.1988375],
                [105.2523091, 9.198815],
                [105.2526055, 9.1987237],
                [105.2526779, 9.1987065],
                [105.252753, 9.1986985],
                [105.2529864, 9.1986985],
                [105.2530977, 9.198447],
                [105.2542269, 9.1958125],
                [105.2568354, 9.1894221],
                [105.2581865, 9.1862302],
                [105.2586787, 9.1851698],
                [105.2592413, 9.18374],
                [105.2599373, 9.1820533],
                [105.2604014, 9.1809359],
                [105.2611457, 9.1794054],
                [105.2613026, 9.1790705],
                [105.2613723, 9.1788825],
                [105.2614045, 9.1787223],
                [105.2614219, 9.1784727],
                [105.2616754, 9.1786151],
                [105.2619933, 9.1788269],
                [105.2621636, 9.1789249],
                [105.2623057, 9.1789858],
                [105.2624023, 9.1790069],
                [105.2625203, 9.1790069],
                [105.262633, 9.178991],
                [105.2627134, 9.1789672],
                [105.2628314, 9.1789143],
                [105.2629441, 9.1788322],
                [105.2632096, 9.1786521],
                [105.2633008, 9.1786177],
                [105.2633893, 9.1786045],
                [105.2634859, 9.1786071],
                [105.2635905, 9.1786256],
                [105.2637675, 9.1786786],
                [105.2637863, 9.1786151],
                [105.2637863, 9.1785436],
                [105.2637756, 9.1784535],
                [105.2637649, 9.1783688],
                [105.263789, 9.1783105],
                [105.2638534, 9.1782788],
                [105.2639902, 9.1782364],
                [105.2640894, 9.1781861],
                [105.2643925, 9.1779769],
                [105.2652186, 9.1784112],
                [105.2658704, 9.1787792],
                [105.2658918, 9.1795418],
                [105.2658986, 9.1802766],
                [105.2658972, 9.1806142],
                [105.2659616, 9.1806566],
                [105.2660447, 9.1807333],
                [105.2660662, 9.1808022],
                [105.2661198, 9.1808498],
                [105.2662164, 9.1808922],
                [105.2673456, 9.1809769],
                [105.2672678, 9.1825312],
                [105.2699608, 9.1827642],
                [105.2699554, 9.182886],
                [105.2715915, 9.1828251],
                [105.2723747, 9.1827854],
                [105.2729863, 9.1827748],
                [105.2729487, 9.1832594],
                [105.2729192, 9.1837519],
                [105.2740971, 9.1836627],
                [105.2752232, 9.1836327],
                [105.2753895, 9.1836036],
                [105.2752903, 9.1835427],
                [105.2751884, 9.1834315],
                [105.2748853, 9.1831005],
                [105.2746332, 9.1827722],
                [105.2744454, 9.1824319],
                [105.2742054, 9.181946],
                [105.2741316, 9.1817607],
                [105.2741021, 9.1816667],
                [105.274094, 9.181582],
                [105.2740967, 9.1815092],
                [105.2741195, 9.1814641],
                [105.2741678, 9.1814231],
                [105.2742523, 9.18139],
                [105.274546, 9.1813212],
                [105.2747512, 9.181255],
                [105.2749658, 9.181157],
                [105.275124, 9.181059],
                [105.2754995, 9.1807333],
                [105.2757972, 9.1804686],
                [105.2759421, 9.1803097],
                [105.2761272, 9.1800555],
                [105.2761996, 9.179984],
                [105.2762935, 9.179931],
                [105.2765483, 9.1798225],
                [105.2767843, 9.1797033],
                [105.2767843, 9.1796054],
                [105.2767655, 9.1793697],
                [105.2766395, 9.1790387],
                [105.2764785, 9.1787236],
                [105.2764624, 9.1786826],
                [105.2764557, 9.1786442],
                [105.2764584, 9.1786071],
                [105.2764718, 9.1785608],
                [105.2765308, 9.1784323],
                [105.276559, 9.1783238],
                [105.2765751, 9.1782073],
                [105.276567, 9.1780272],
                [105.2765402, 9.1775903],
                [105.2765375, 9.1769337],
                [105.2765027, 9.1767112],
                [105.2773207, 9.1765471],
                [105.2778223, 9.1768119],
                [105.2781147, 9.1763193],
                [105.2783695, 9.1758639],
                [105.2786692, 9.1752714],
                [105.2791465, 9.1742252],
                [105.2793646, 9.173216],
                [105.2794424, 9.1727659],
                [105.2795188, 9.1721833],
                [105.2796274, 9.1709891],
                [105.2799983, 9.1672681],
                [105.2804204, 9.1627222],
                [105.2798903, 9.1626004],
                [105.27915, 9.1625157],
                [105.278922, 9.1625448],
                [105.2786913, 9.1625951],
                [105.2785572, 9.1626136],
                [105.278289, 9.1626348],
                [105.2780771, 9.162664],
                [105.2778869, 9.1627304],
                [105.2776667, 9.1628016],
                [105.277538, 9.1628149],
                [105.2773798, 9.1628086],
                [105.2770874, 9.1627699],
                [105.2769506, 9.1627593],
                [105.2768192, 9.1627619],
                [105.2765992, 9.1627805],
                [105.2763739, 9.1627831],
                [105.2760547, 9.1627381],
                [105.2756309, 9.1626295],
                [105.2752689, 9.1625157],
                [105.2748048, 9.1623488],
                [105.2744054, 9.1621791],
                [105.274082, 9.1620245],
                [105.2738634, 9.1618993],
                [105.2736568, 9.161761],
                [105.2734264, 9.161588],
                [105.2731043, 9.1612658],
                [105.2728924, 9.1610857],
                [105.2726687, 9.1609361],
                [105.2722084, 9.1606541],
                [105.2720448, 9.1605403],
                [105.2719241, 9.1605654],
                [105.2718168, 9.1605734],
                [105.2717069, 9.1605734],
                [105.2715754, 9.1605561],
                [105.2714065, 9.1605164],
                [105.2712187, 9.1604635],
                [105.2709921, 9.1603734],
                [105.2704784, 9.1601537],
                [105.2702022, 9.1600689],
                [105.2699876, 9.1600239],
                [105.2698052, 9.1600027],
                [105.2696282, 9.1600054],
                [105.2692795, 9.1600239],
                [105.2691239, 9.1600027],
                [105.2689737, 9.159963],
                [105.2688315, 9.1598941],
                [105.2684668, 9.1596929],
                [105.2682656, 9.1596214],
                [105.2680671, 9.1595711],
                [105.2678686, 9.1595393],
                [105.2676675, 9.1595234],
                [105.2674261, 9.1595234],
                [105.2670237, 9.159536],
                [105.2666633, 9.159524],
                [105.2668702, 9.1592586],
                [105.2670626, 9.1590415],
                [105.2673174, 9.1587641],
                [105.2674556, 9.1586125],
                [105.2675548, 9.1584523],
                [105.2675924, 9.1582709],
                [105.2675951, 9.1581094],
                [105.2675468, 9.157887],
                [105.2674475, 9.1576354],
                [105.2672651, 9.1572541],
                [105.267072, 9.1569469],
                [105.2668503, 9.156664],
                [105.2664189, 9.1561882],
                [105.2662633, 9.155979],
                [105.266158, 9.1558196],
                [105.2660649, 9.1556712],
                [105.2658458, 9.1552565],
                [105.265692, 9.1550112],
                [105.2655297, 9.1548179],
                [105.2653769, 9.1546775],
                [105.2649799, 9.1543439],
                [105.2648123, 9.1541502],
                [105.264711, 9.153954],
                [105.2646205, 9.1536964],
                [105.2644562, 9.1532675],
                [105.2642584, 9.1528822],
                [105.2640384, 9.1525432],
                [105.2636066, 9.1518759],
                [105.2634604, 9.1516045],
                [105.2633773, 9.1512893],
                [105.2633464, 9.1510047],
                [105.2633545, 9.150536],
                [105.263386, 9.1497495],
                [105.2634108, 9.1489683],
                [105.2634886, 9.1482679],
                [105.2635235, 9.1477317],
                [105.263507, 9.1474771],
                [105.2634644, 9.1472815],
                [105.2633759, 9.1471226],
                [105.2631238, 9.1467413],
                [105.2630112, 9.1465321],
                [105.2629521, 9.1463493],
                [105.2629066, 9.1461454],
                [105.2628341, 9.1456423],
                [105.2627885, 9.1453298],
                [105.2627215, 9.145063],
                [105.2623755, 9.1440653],
                [105.2623031, 9.1437469],
                [105.262268, 9.1434604],
                [105.2622595, 9.1431968],
                [105.2622803, 9.1427625],
                [105.2623138, 9.1423454],
                [105.262295, 9.1420753],
                [105.262228, 9.1417717],
                [105.2621575, 9.1415854],
                [105.262057, 9.141396],
                [105.2618987, 9.1412103],
                [105.2617465, 9.1410749],
                [105.2615722, 9.1409485],
                [105.2609351, 9.1405672],
                [105.260746, 9.1404361],
                [105.2605958, 9.1402997],
                [105.2599695, 9.1396734],
                [105.2598113, 9.1395264],
                [105.2595095, 9.1393133],
                [105.2593526, 9.1391756],
                [105.2592306, 9.1390259],
                [105.2591152, 9.138826],
                [105.2590308, 9.1386155],
                [105.2589637, 9.138442],
                [105.2588832, 9.1383255],
                [105.2587759, 9.1382434],
                [105.2585936, 9.1381825],
                [105.2583092, 9.1381189],
                [105.2581161, 9.1380514],
                [105.2579391, 9.1379706],
                [105.2571371, 9.1375549],
                [105.2568608, 9.1374489],
                [105.2565444, 9.1373655],
                [105.255742, 9.1371689],
                [105.2552274, 9.137008],
                [105.2548827, 9.1368743],
                [105.2545032, 9.136681],
                [105.2538132, 9.1362612],
                [105.2532499, 9.1358804],
                [105.2529676, 9.1356429],
                [105.2527182, 9.1354218],
                [105.2526122, 9.1352152],
                [105.2526015, 9.1350219],
                [105.2526163, 9.134867],
                [105.252691, 9.1344456],
                [105.2527155, 9.134234],
                [105.2527034, 9.1339798],
                [105.2526471, 9.133662],
                [105.2525398, 9.1333628],
                [105.2523574, 9.1329735],
                [105.2521536, 9.1326054],
                [105.2517191, 9.1319486],
                [105.2515581, 9.1316415],
                [105.2514508, 9.1313442],
                [105.2513751, 9.1309324],
                [105.251361, 9.1306682],
                [105.2513838, 9.1304868],
                [105.2514817, 9.1300803],
                [105.2515112, 9.1299228],
                [105.2515165, 9.1297625],
                [105.2514911, 9.1296182],
                [105.2513704, 9.1292342],
                [105.2512041, 9.1286516],
                [105.2510257, 9.1281961],
                [105.2507977, 9.1277909],
                [105.2506851, 9.1275499],
                [105.2506167, 9.127354],
                [105.2503806, 9.1265039],
                [105.250335, 9.1263],
                [105.2502385, 9.125667],
                [105.2501929, 9.1254154],
                [105.2501305, 9.1252135],
                [105.2500507, 9.1250467],
                [105.249901, 9.1248423],
                [105.2496739, 9.1245912],
                [105.2490303, 9.123891],
                [105.2487056, 9.1235014],
                [105.2484957, 9.1231988],
                [105.2483315, 9.1228788],
                [105.2482697, 9.1226003],
                [105.248258, 9.1223519],
                [105.2482845, 9.1220829],
                [105.2483676, 9.1218184],
                [105.2485286, 9.1215609],
                [105.2487378, 9.1213556],
                [105.2490422, 9.1211676],
                [105.2494875, 9.1209107],
                [105.2496551, 9.1207849],
                [105.2497879, 9.1206419],
                [105.2498724, 9.1204949],
                [105.2499233, 9.1203016],
                [105.2499488, 9.1200712],
                [105.2499421, 9.1198329],
                [105.2498636, 9.1195323],
                [105.2497681, 9.1192347],
                [105.2496243, 9.1189218],
                [105.2494875, 9.1187106],
                [105.2489322, 9.1179473],
                [105.24854, 9.1171882],
                [105.2484156, 9.1169224],
                [105.2483502, 9.1166867],
                [105.2483308, 9.116445],
                [105.2483495, 9.1158117],
                [105.2483812, 9.1152922],
                [105.2483743, 9.1149917],
                [105.2483321, 9.1146478],
                [105.2482213, 9.1143116],
                [105.2480718, 9.1140044],
                [105.2479345, 9.1137934],
                [105.2477454, 9.113559],
                [105.2471285, 9.1129115],
                [105.2470145, 9.1127532],
                [105.2469689, 9.1125751],
                [105.2469461, 9.1123765],
                [105.2469633, 9.1121638],
                [105.2470285, 9.1119571],
                [105.2471875, 9.1116495],
                [105.2473444, 9.1113741],
                [105.2475657, 9.111047],
                [105.2477883, 9.1107716],
                [105.2483127, 9.1102591],
                [105.2488947, 9.1097308],
                [105.2490959, 9.1095255],
                [105.2492702, 9.1093123],
                [105.2494566, 9.1090276],
                [105.2496437, 9.1087151],
                [105.2498764, 9.108294],
                [105.2500534, 9.1079603],
                [105.2501848, 9.1076981],
                [105.25027, 9.1074767],
                [105.2503357, 9.1072227],
                [105.2505221, 9.1064878],
                [105.2506019, 9.1062594],
                [105.2507716, 9.1058601],
                [105.2508581, 9.1055847],
                [105.250909, 9.105349],
                [105.2509117, 9.1051689],
                [105.2508822, 9.1049835],
                [105.2508178, 9.1047822],
                [105.2507173, 9.1045849],
                [105.2505989, 9.1044201],
                [105.2504551, 9.1043088],
                [105.2502546, 9.1042248],
                [105.2498167, 9.1040877],
                [105.249494, 9.1039849],
                [105.2492414, 9.1038824],
                [105.2489832, 9.1037348],
                [105.2487834, 9.1035905],
                [105.2486345, 9.1034474],
                [105.2484709, 9.1032488],
                [105.2481678, 9.1028251],
                [105.247975, 9.1026095],
                [105.2477869, 9.1024304],
                [105.2475643, 9.1023007],
                [105.2473202, 9.1022159],
                [105.2469876, 9.1021563],
                [105.2464985, 9.1020866],
                [105.2461488, 9.1020083],
                [105.2458128, 9.1018637],
                [105.2454182, 9.1016167],
                [105.2451959, 9.1013929],
                [105.2450923, 9.1011903],
                [105.245039, 9.1009745],
                [105.2450176, 9.1007553],
                [105.2450367, 9.1005219],
                [105.2450933, 9.100273],
                [105.2452328, 9.1000025],
                [105.2454132, 9.0996906],
                [105.2458329, 9.0991212],
                [105.245959, 9.0989133],
                [105.2460515, 9.0987226],
                [105.2461334, 9.0985114],
                [105.2463385, 9.0978721],
                [105.2464257, 9.0976573],
                [105.2465431, 9.0974752],
                [105.2467754, 9.0971875],
                [105.246997, 9.0969773],
                [105.2472207, 9.0967952],
                [105.2474594, 9.0966562],
                [105.2477601, 9.0965351],
                [105.2483396, 9.0963232],
                [105.2486553, 9.0961913],
                [105.2490513, 9.0960087],
                [105.2494432, 9.0958232],
                [105.2497718, 9.0956451],
                [105.2501673, 9.0953773],
                [105.2503619, 9.0952359],
                [105.2504906, 9.095122],
                [105.2508701, 9.0947406],
                [105.2510539, 9.0945989],
                [105.2517781, 9.0941037],
                [105.2523219, 9.0937249],
                [105.2528711, 9.093423],
                [105.253028, 9.0933098],
                [105.2531433, 9.0931727],
                [105.253197, 9.0930536],
                [105.253205, 9.0928747],
                [105.2532077, 9.0926099],
                [105.2532184, 9.0924245],
                [105.253256, 9.092255],
                [105.2533578, 9.0919769],
                [105.2536355, 9.0913689],
                [105.2544308, 9.091617],
                [105.2553835, 9.0918962],
                [105.2561907, 9.0921057],
                [105.2571815, 9.0923697],
                [105.2577592, 9.092572],
                [105.2582809, 9.092789],
                [105.2588399, 9.0930551],
                [105.2596959, 9.0934875],
                [105.2605084, 9.0939001],
                [105.2611113, 9.0942158],
                [105.2614537, 9.09437],
                [105.2617525, 9.0944883],
                [105.2619864, 9.0945615],
                [105.2622591, 9.0946135],
                [105.2625691, 9.094619],
                [105.2628686, 9.0945552],
                [105.2632102, 9.0944051],
                [105.2635718, 9.0941549],
                [105.2639033, 9.0938248],
                [105.2647016, 9.0928446],
                [105.265073, 9.0924045],
                [105.2653943, 9.0919843],
                [105.2660822, 9.0911741],
                [105.2665241, 9.0906739],
                [105.2668353, 9.0903139],
                [105.2676136, 9.0893336],
                [105.2679347, 9.0888736],
                [105.2682561, 9.0884934],
                [105.2687332, 9.0879634],
                [105.2690646, 9.0876135],
                [105.2694466, 9.0873734],
                [105.2699188, 9.0870533],
                [105.2704412, 9.0867132],
                [105.2710692, 9.0862544],
                [105.2713405, 9.0860843],
                [105.2717322, 9.085814],
                [105.2722045, 9.0855237],
                [105.2725361, 9.0853335],
                [105.2729179, 9.0851133],
                [105.2735661, 9.0848627],
                [105.2739882, 9.0847624],
                [105.2742696, 9.0847123],
                [105.2744707, 9.0847121],
                [105.2748628, 9.0847919],
                [105.2752651, 9.0849615],
                [105.2756572, 9.0852011],
                [105.2761249, 9.0854807],
                [105.276477, 9.0857603],
                [105.2767988, 9.0859701],
                [105.2773017, 9.0862997],
                [105.2777744, 9.0865592],
                [105.278237, 9.0868388],
                [105.2789863, 9.0872182],
                [105.2794288, 9.0874078],
                [105.2799114, 9.0875974],
                [105.280384, 9.0877469],
                [105.2807661, 9.0878567],
                [105.2819375, 9.0881957],
                [105.282591, 9.0883552],
                [105.2833753, 9.0885245],
                [105.2850795, 9.0889031],
                [105.2859241, 9.0890523],
                [105.2874674, 9.089361],
                [105.2884829, 9.0895902],
                [105.2904284, 9.0899986],
                [105.2911724, 9.0901679],
                [105.2918511, 9.0903273],
                [105.2924645, 9.0904567],
                [105.2931684, 9.0905561],
                [105.2936712, 9.0906156],
                [105.2940834, 9.0906153],
                [105.2944257, 9.0905724],
                [105.2947118, 9.0905365],
                [105.2951743, 9.0904761],
                [105.2956468, 9.0903757],
                [105.2961293, 9.0902653],
                [105.2966118, 9.0900748],
                [105.29724, 9.0898443],
                [105.2977928, 9.0896739],
                [105.2982552, 9.0895034],
                [105.2986774, 9.0893432],
                [105.2990893, 9.0890628],
                [105.2995466, 9.0887524],
                [105.2999284, 9.0884621],
                [105.30027, 9.0881817],
                [105.3006417, 9.0878514],
                [105.3010436, 9.087531],
                [105.3014455, 9.0872106],
                [105.3028169, 9.0860595],
                [105.3032189, 9.0857491],
                [105.3035204, 9.0855289],
                [105.3041081, 9.0850883],
                [105.3045201, 9.084748],
                [105.304731, 9.0845578],
                [105.3050123, 9.0842575],
                [105.3053036, 9.0839373],
                [105.3055798, 9.083587],
                [105.3057404, 9.0833268],
                [105.3059111, 9.0830667],
                [105.3061421, 9.0826865],
                [105.306353, 9.0823663],
                [105.30684, 9.0816659],
                [105.3071011, 9.0813557],
                [105.3073321, 9.0810654],
                [105.3075932, 9.0807751],
                [105.3085225, 9.0798843],
                [105.3091055, 9.0793459],
                [105.3093435, 9.0791231],
                [105.3099942, 9.078573],
                [105.3102957, 9.0783727],
                [105.3105369, 9.0782425],
                [105.3107379, 9.0782023],
                [105.3110194, 9.0781621],
                [105.3110557, 9.0781631],
                [105.3113915, 9.0781717],
                [105.3119143, 9.0782213],
                [105.3126434, 9.0783006],
                [105.313146, 9.0782902],
                [105.3135381, 9.0782095],
                [105.3140307, 9.0780691],
                [105.3145534, 9.0778388],
                [105.3149555, 9.0775985],
                [105.3153173, 9.0773284],
                [105.3157896, 9.0769981],
                [105.3161765, 9.0766794],
                [105.3164578, 9.076379],
                [105.3167289, 9.0759787],
                [105.3169197, 9.0756584],
                [105.3170702, 9.0752782],
                [105.3171555, 9.0749681],
                [105.3172053, 9.0745279],
                [105.3172452, 9.0741178],
                [105.3172548, 9.0736577],
                [105.3172696, 9.0733276],
                [105.3173195, 9.0729874],
                [105.3174198, 9.0726773],
                [105.3176207, 9.072327],
                [105.3178416, 9.0720467],
                [105.318128, 9.0718065],
                [105.3184195, 9.0716861],
                [105.3187512, 9.0715858],
                [105.3191534, 9.0714955],
                [105.3196059, 9.071445],
                [105.3199277, 9.0714348],
                [105.3203701, 9.0714244],
                [105.3210389, 9.0714138],
                [105.3214713, 9.0714133],
                [105.3218835, 9.0713929],
                [105.3224366, 9.0713625],
                [105.3228791, 9.071362],
                [105.323417, 9.0713217],
                [105.323697, 9.0712708],
                [105.3237489, 9.0712613],
                [105.3243789, 9.0711355],
                [105.3249486, 9.0709955],
                [105.3254989, 9.070845],
                [105.3265588, 9.0704585],
                [105.3270111, 9.0702681],
                [105.3275439, 9.0700576],
                [105.3281873, 9.0698168],
                [105.3297705, 9.0691552],
                [105.3303434, 9.0688646],
                [105.3311576, 9.0684237],
                [105.3330018, 9.0674218],
                [105.3333435, 9.0672114],
                [105.3337957, 9.066931],
                [105.3340721, 9.0667006],
                [105.3342832, 9.0665804],
                [105.3345043, 9.0664903],
                [105.3347154, 9.06645],
                [105.3349869, 9.0663998],
                [105.3352585, 9.0663895],
                [105.33554, 9.0664191],
                [105.3357311, 9.066459],
                [105.3359121, 9.0665189],
                [105.336053, 9.0666088],
                [105.3364053, 9.0669184],
                [105.3368131, 9.0674183],
                [105.337075, 9.067928],
                [105.3373471, 9.0684876],
                [105.3376091, 9.0689673],
                [105.3378608, 9.0693569],
                [105.3382588, 9.0699286],
                [105.3386313, 9.0703885],
                [105.3390442, 9.0708782],
                [105.3393564, 9.0712482],
                [105.3398246, 9.0717679],
                [105.3401468, 9.0720677],
                [105.3404688, 9.0722776],
                [105.3407908, 9.0723871],
                [105.341173, 9.0724269],
                [105.3415954, 9.0724265],
                [105.3419877, 9.0723661],
                [105.3426363, 9.0721953],
                [105.3431993, 9.0719847],
                [105.3436819, 9.0717642],
                [105.3441142, 9.0715938],
                [105.3445968, 9.0713831],
                [105.3450844, 9.0712126],
                [105.3454766, 9.0710721],
                [105.3458286, 9.0710217],
                [105.3462509, 9.0709414],
                [105.3466331, 9.070911],
                [105.3470556, 9.0709005],
                [105.347835, 9.0708398],
                [105.3484988, 9.0708091],
                [105.3491324, 9.0707785],
                [105.3498265, 9.0707678],
                [105.3510284, 9.0707767],
                [105.3517324, 9.070796],
                [105.3526679, 9.070835],
                [105.3533874, 9.0708939],
                [105.3539709, 9.0709634],
                [105.3543533, 9.0710029],
                [105.3548865, 9.0710624],
                [105.3553896, 9.0711219],
                [105.3558122, 9.0711414],
                [105.3562447, 9.0711511],
                [105.3567829, 9.0710922],
                [105.3571752, 9.0710018],
                [105.3575371, 9.0708613],
                [105.3579896, 9.0706507],
                [105.3583114, 9.0704503],
                [105.3585124, 9.07028],
                [105.3587234, 9.0700195],
                [105.3588891, 9.0697093],
                [105.3589893, 9.0694089],
                [105.3590294, 9.0691588],
                [105.358999, 9.0689086],
                [105.3588678, 9.0686185],
                [105.3585758, 9.0682886],
                [105.3581377, 9.0679388],
                [105.3577854, 9.067689],
                [105.3573726, 9.0673792],
                [105.3570908, 9.0671394],
                [105.356849, 9.0668994],
                [105.3565972, 9.0665695],
                [105.3563502, 9.0661294],
                [105.3562393, 9.0657893],
                [105.3561685, 9.0654692],
                [105.3561684, 9.0653191],
                [105.3562283, 9.0649389],
                [105.3563536, 9.0644184],
                [105.356484, 9.064048],
                [105.3566748, 9.0637276],
                [105.356936, 9.0634371],
                [105.3572577, 9.0631466],
                [105.3577352, 9.062836],
                [105.3581374, 9.0626454],
                [105.35862, 9.0624648],
                [105.359113, 9.0623443],
                [105.3596862, 9.0622236],
                [105.3603148, 9.0621228],
                [105.3608681, 9.0620022],
                [105.3613711, 9.0619017],
                [105.3618238, 9.0617913],
                [105.3621759, 9.0616708],
                [105.3626686, 9.0614203],
                [105.363091, 9.0612099],
                [105.3634882, 9.060951],
                [105.36383, 9.0607304],
                [105.3642525, 9.0604897],
                [105.364544, 9.0603294],
                [105.3649866, 9.0601587],
                [105.3652683, 9.0600684],
                [105.3657512, 9.0599778],
                [105.3662475, 9.0599237],
                [105.3664, 9.0599071],
                [105.3667723, 9.0598767],
                [105.3672654, 9.0599463],
                [105.3676881, 9.0600459],
                [105.3682517, 9.0601755],
                [105.3693236, 9.0604446],
                [105.3699879, 9.0606741],
                [105.3708233, 9.0609435],
                [105.3719858, 9.0612324],
                [105.3724386, 9.061272],
                [105.3727907, 9.0612717],
                [105.3732435, 9.0612211],
                [105.3735503, 9.0611308],
                [105.373852, 9.0610303],
                [105.3741236, 9.06089],
                [105.3744151, 9.0606595],
                [105.3746664, 9.0604191],
                [105.3748573, 9.0601686],
                [105.374923, 9.0600583],
                [105.3750481, 9.0598481],
                [105.3752188, 9.0594777],
                [105.3754848, 9.058927],
                [105.3758866, 9.058326],
                [105.3761581, 9.0580455],
                [105.3764697, 9.0578049],
                [105.376917, 9.0575947],
                [105.377239, 9.0575044],
                [105.3776113, 9.0574839],
                [105.3779032, 9.0575137],
                [105.3783261, 9.0577133],
                [105.3786686, 9.058023],
                [105.3790314, 9.0584528],
                [105.3793136, 9.0588426],
                [105.3795152, 9.0592026],
                [105.3797572, 9.0596425],
                [105.3802965, 9.0603744],
                [105.3806995, 9.0608344],
                [105.3810421, 9.0612746],
                [105.3812992, 9.0616647],
                [105.381541, 9.0619347],
                [105.3818836, 9.0622347],
                [105.3823468, 9.0625946],
                [105.3827901, 9.0628943],
                [105.3835101, 9.0633741],
                [105.3845776, 9.0639836],
                [105.3853429, 9.0643532],
                [105.3859319, 9.0645929],
                [105.3865965, 9.0649224],
                [105.387382, 9.065352],
                [105.3881775, 9.0657917],
                [105.3894715, 9.066471],
                [105.3901058, 9.0667705],
                [105.3907704, 9.0670401],
                [105.3913996, 9.0672096],
                [105.3918425, 9.0673092],
                [105.3921847, 9.0673389],
                [105.3923759, 9.0673087],
                [105.3927627, 9.0672176],
                [105.3930298, 9.0670176],
                [105.393271, 9.066747],
                [105.3934265, 9.0663164],
                [105.393567, 9.0659358],
                [105.3937272, 9.0653051],
                [105.3938977, 9.0646542],
                [105.3941332, 9.063803],
                [105.3942435, 9.0634726],
                [105.3943841, 9.0631822],
                [105.3945547, 9.0627114],
                [105.3949212, 9.0620404],
                [105.3951723, 9.0615997],
                [105.3953632, 9.0613291],
                [105.3956446, 9.0610185],
                [105.3960618, 9.0606276],
                [105.3964943, 9.0603468],
                [105.3969067, 9.0601361],
                [105.3977266, 9.0598249],
                [105.3986421, 9.0596338],
                [105.3993767, 9.0595128],
                [105.4000106, 9.059432],
                [105.400715, 9.0593212],
                [105.4014243, 9.05911],
                [105.4022088, 9.0587889],
                [105.4027319, 9.058538],
                [105.4031542, 9.0582272],
                [105.4037122, 9.0576961],
                [105.4041041, 9.0572852],
                [105.404456, 9.0568844],
                [105.4047876, 9.0565037],
                [105.4058179, 9.0553913],
                [105.4063003, 9.0548903],
                [105.4075821, 9.0537575],
                [105.4081049, 9.0532865],
                [105.4087534, 9.0527953],
                [105.409055, 9.0525546],
                [105.4093768, 9.052294],
                [105.4098192, 9.0519231],
                [105.4101209, 9.0517126],
                [105.4103369, 9.0514721],
                [105.4105177, 9.0512016],
                [105.4107388, 9.050871],
                [105.4107989, 9.0506707],
                [105.4108288, 9.0503803],
                [105.4108583, 9.0498599],
                [105.4108627, 9.0492791],
                [105.4108018, 9.0488288],
                [105.4107208, 9.0484084],
                [105.4105189, 9.0478481],
                [105.4102206, 9.0466872],
                [105.4100992, 9.0461068],
                [105.4100434, 9.0456865],
                [105.4100128, 9.0453464],
                [105.4100327, 9.0450761],
                [105.4100926, 9.0446859],
                [105.4102883, 9.0441968],
                [105.4104891, 9.043756],
                [105.4107303, 9.0434053],
                [105.411062, 9.0429844],
                [105.4119115, 9.0421323],
                [105.4123136, 9.0417614],
                [105.4128668, 9.0413604],
                [105.4137368, 9.0408588],
                [105.4142095, 9.0405578],
                [105.4146722, 9.0402669],
                [105.4153864, 9.0398755],
                [105.416347, 9.0393138],
                [105.4169504, 9.0389627],
                [105.4173427, 9.0387118],
                [105.4180467, 9.0382404],
                [105.4183936, 9.0378896],
                [105.4186045, 9.037589],
                [105.4187149, 9.0372885],
                [105.4188855, 9.0368478],
                [105.4189957, 9.0364372],
                [105.4190908, 9.0360064],
                [105.4191407, 9.0355659],
                [105.4191503, 9.0352255],
                [105.41915, 9.0349451],
                [105.4190388, 9.0345948],
                [105.4187998, 9.0338738],
                [105.4184071, 9.0328682],
                [105.4178835, 9.0315707],
                [105.4176054, 9.030857],
                [105.4174661, 9.0303541],
                [105.4173635, 9.0298187],
                [105.4173059, 9.0294458],
                [105.4161572, 9.0219702],
                [105.4161153, 9.0213129],
                [105.4166353, 9.0171618],
                [105.5552754, 8.8313486],
                [105.6738606, 8.291231],
                [106.0553036, 8.3456879],
                [105.9769387, 8.702505],
                [105.8997374, 9.0536918],
                [105.8270534, 9.2450076],
                [105.8269188, 9.2454568],
                [105.826524, 9.2468737],
                [105.8259874, 9.2488212],
                [105.8256521, 9.2500227],
                [105.8249339, 9.2525662],
                [105.8246296, 9.2536248],
                [105.8242004, 9.2551116],
                [105.8237913, 9.256467],
                [105.8232238, 9.2584304],
                [105.822473, 9.2611328],
                [105.8221954, 9.2619812],
                [105.8221274, 9.2621673],
                [105.8219929, 9.2625888],
                [105.8216937, 9.263728],
                [105.8213718, 9.2648263],
                [105.8204161, 9.268146],
                [105.8198562, 9.2699854],
                [105.8195308, 9.2712708],
                [105.8191554, 9.2725795],
                [105.8192408, 9.2726246],
                [105.8199579, 9.2728468],
                [105.8202615, 9.2729605],
                [105.820519, 9.2730822],
                [105.8208878, 9.2733033],
                [105.8212271, 9.2734436],
                [105.8228971, 9.2743377],
                [105.823684, 9.2746904],
                [105.823481, 9.2754088],
                [105.8245275, 9.275884],
                [105.8256528, 9.2764388],
                [105.8258812, 9.2765179],
                [105.8257091, 9.2775758],
                [105.8252105, 9.2804761],
                [105.8251475, 9.2808233],
                [105.824773, 9.2824094],
                [105.8241215, 9.2854804],
                [105.8234534, 9.2886564],
                [105.8227375, 9.2919108],
                [105.8222306, 9.2942434],
                [105.82205, 9.2952043],
                [105.8216643, 9.2971136],
                [105.8210903, 9.2998056],
                [105.8209428, 9.3004812],
                [105.8207592, 9.3012436],
                [105.8204935, 9.3031368],
                [105.8203876, 9.3037654],
                [105.8200128, 9.3056521],
                [105.8197921, 9.3066744],
                [105.8193691, 9.308785],
                [105.818848, 9.311272],
                [105.8185047, 9.313018],
                [105.817836, 9.3163038],
                [105.8173057, 9.3186196],
                [105.8165815, 9.3221346],
                [105.8156361, 9.3266672],
                [105.8153973, 9.3277934],
                [105.8148783, 9.3304348],
                [105.8147147, 9.3313321],
                [105.8144541, 9.3323258],
                [105.8139645, 9.3336185],
                [105.8135319, 9.3350242],
                [105.8125688, 9.3379693],
                [105.8124402, 9.3383669],
                [105.8120928, 9.3393061],
                [105.8128211, 9.3397138],
                [105.8144557, 9.3406929],
                [105.815388, 9.3412386],
                [105.8163254, 9.3417498],
                [105.8179194, 9.3426503],
                [105.8193842, 9.3434645],
                [105.8211734, 9.3444527],
                [105.8219272, 9.3448115],
                [105.8226425, 9.3451325],
                [105.8234996, 9.3455033],
                [105.8239672, 9.3457183],
                [105.8243524, 9.3458918],
                [105.8255349, 9.3464346],
                [105.8265033, 9.3468741],
                [105.827272, 9.347254],
                [105.8282755, 9.3477512],
                [105.8296105, 9.3483462],
                [105.8302564, 9.3485885],
                [105.8309456, 9.3487972],
                [105.8322781, 9.349175],
                [105.8340689, 9.3496761],
                [105.8355337, 9.3501485],
                [105.8366579, 9.3505875],
                [105.8378267, 9.3511195],
                [105.8384609, 9.3514165],
                [105.8387924, 9.3515678],
                [105.8390772, 9.3516991],
                [105.8394291, 9.3518435],
                [105.839884, 9.3520324],
                [105.8409029, 9.3524187],
                [105.842718, 9.3530425],
                [105.8439033, 9.35341],
                [105.8447526, 9.3536386],
                [105.8454527, 9.353812],
                [105.8465817, 9.3540933],
                [105.8473473, 9.3543471],
                [105.848101, 9.3545626],
                [105.8489058, 9.3548437],
                [105.8496257, 9.3551706],
                [105.8504165, 9.3555912],
                [105.8510895, 9.3560212],
                [105.8517951, 9.3565981],
                [105.8523273, 9.3571657],
                [105.8528311, 9.3578483],
                [105.8534948, 9.3587859],
                [105.8540702, 9.3595545],
                [105.8545268, 9.3599763],
                [105.855046, 9.3603054],
                [105.8556251, 9.3605237],
                [105.8562744, 9.3606135],
                [105.8568089, 9.3605802],
                [105.8573922, 9.3604955],
                [105.8579435, 9.3603284],
                [105.8584091, 9.3600931],
                [105.8587944, 9.3597579],
                [105.8589524, 9.3601827],
                [105.8592743, 9.3610031],
                [105.8596337, 9.3616965],
                [105.8597678, 9.3619559],
                [105.8598697, 9.3621887],
                [105.8601621, 9.3630092],
                [105.860256, 9.3633241],
                [105.8603284, 9.3635649],
                [105.8603927, 9.363819],
                [105.8604518, 9.3640995],
                [105.8604705, 9.3642345],
                [105.8604732, 9.3643827],
                [105.8604377, 9.3645335],
                [105.8603364, 9.3647294],
                [105.8602077, 9.364904],
                [105.8600306, 9.3650787],
                [105.8598214, 9.3652163],
                [105.8595988, 9.3653248],
                [105.8593145, 9.3654042],
                [105.8584186, 9.3655815],
                [105.8580485, 9.3656662],
                [105.8576924, 9.3657959],
                [105.8573914, 9.3659977],
                [105.857221, 9.3661611],
                [105.8571044, 9.3663093],
                [105.8569998, 9.3665104],
                [105.8568965, 9.3667797],
                [105.8568089, 9.3670292],
                [105.8567342, 9.3673004],
                [105.8566843, 9.3675581],
                [105.8566571, 9.3678052],
                [105.8566457, 9.3680599],
                [105.8566524, 9.368359],
                [105.8567046, 9.3687221],
                [105.8568502, 9.3693282],
                [105.8571982, 9.3706944],
                [105.8572894, 9.3712237],
                [105.857327, 9.3716842],
                [105.8573216, 9.3721341],
                [105.8572733, 9.3725284],
                [105.8572197, 9.372801],
                [105.8571285, 9.3730603],
                [105.8569595, 9.3733091],
                [105.8567449, 9.3735314],
                [105.8565304, 9.3736928],
                [105.8562648, 9.3738331],
                [105.8558035, 9.3740421],
                [105.8555299, 9.3741811],
                [105.8552469, 9.3743372],
                [105.8550444, 9.3744722],
                [105.8547118, 9.3747275],
                [105.8544704, 9.3749604],
                [105.8542545, 9.3752237],
                [105.8541124, 9.3754275],
                [105.8539789, 9.3756842],
                [105.8538482, 9.3760005],
                [105.8537704, 9.3762161],
                [105.8537087, 9.3764278],
                [105.8533788, 9.3778079],
                [105.8530757, 9.37893],
                [105.8529496, 9.3793719],
                [105.8528665, 9.3797609],
                [105.8528289, 9.3800203],
                [105.8528195, 9.3802743],
                [105.8528316, 9.3804649],
                [105.8528638, 9.380666],
                [105.8529389, 9.3808512],
                [105.8530569, 9.3810312],
                [105.8532071, 9.3811953],
                [105.8534163, 9.3813382],
                [105.8536524, 9.3814652],
                [105.8539474, 9.381571],
                [105.8542532, 9.3816293],
                [105.8545482, 9.3816557],
                [105.8555648, 9.3817404],
                [105.8561119, 9.3818198],
                [105.856584, 9.3819706],
                [105.8568415, 9.3821056],
                [105.8569944, 9.3822379],
                [105.8571231, 9.3824337],
                [105.857209, 9.3826719],
                [105.8572572, 9.3829207],
                [105.8572572, 9.3831906],
                [105.8572143, 9.3834446],
                [105.8571392, 9.3836775],
                [105.8570051, 9.3839263],
                [105.8568549, 9.3841274],
                [105.8565733, 9.3844264],
                [105.8563051, 9.3846566],
                [105.856061, 9.3847863],
                [105.8558223, 9.3848683],
                [105.85556, 9.3849013],
                [105.8552912, 9.3848922],
                [105.8550391, 9.3848657],
                [105.8547816, 9.3848181],
                [105.8544517, 9.3847413],
                [105.854044, 9.3846249],
                [105.8534485, 9.3844317],
                [105.8532621, 9.3843841],
                [105.8530636, 9.3843708],
                [105.8528799, 9.384388],
                [105.8527189, 9.3844344],
                [105.8525687, 9.3845137],
                [105.8524346, 9.3846143],
                [105.8523273, 9.3847413],
                [105.8522469, 9.3849054],
                [105.8522201, 9.3850853],
                [105.852204, 9.3852971],
                [105.8522576, 9.3855193],
                [105.8523381, 9.3857258],
                [105.8528021, 9.386562],
                [105.8529758, 9.386902],
                [105.8530623, 9.3871362],
                [105.8531266, 9.38734],
                [105.8531588, 9.3875623],
                [105.8531642, 9.3877899],
                [105.8531401, 9.3879645],
                [105.853081, 9.3881392],
                [105.8530086, 9.38829],
                [105.8529147, 9.3884276],
                [105.852786, 9.3885308],
                [105.8526385, 9.3885943],
                [105.8524507, 9.3886367],
                [105.8522361, 9.3886499],
                [105.8519948, 9.3886234],
                [105.8517265, 9.388552],
                [105.8514905, 9.3884567],
                [105.8506805, 9.3881418],
                [105.8504176, 9.3880836],
                [105.8502057, 9.3880677],
                [105.8500019, 9.3880836],
                [105.8498007, 9.3881233],
                [105.8496049, 9.3881921],
                [105.8494171, 9.3882979],
                [105.849224, 9.3884488],
                [105.849039, 9.3886234],
                [105.8487895, 9.388896],
                [105.8485991, 9.3891527],
                [105.8482343, 9.3897163],
                [105.848119, 9.3899572],
                [105.8480385, 9.3901927],
                [105.8479875, 9.390415],
                [105.8479607, 9.3907405],
                [105.8479419, 9.391103],
                [105.84795, 9.3913385],
                [105.8479848, 9.3915529],
                [105.8481418, 9.3922197],
                [105.8482826, 9.392749],
                [105.8485293, 9.393818],
                [105.8485937, 9.3941726],
                [105.8486111, 9.3944809],
                [105.8486098, 9.3947442],
                [105.8485883, 9.3949877],
                [105.8485454, 9.3952206],
                [105.8484596, 9.3955169],
                [105.8481538, 9.396496],
                [105.8476174, 9.3975439],
                [105.8471024, 9.3985336],
                [105.8469093, 9.3988935],
                [105.8467591, 9.3991158],
                [105.8465874, 9.3993063],
                [105.8463702, 9.3994915],
                [105.84622, 9.3995894],
                [105.8460885, 9.3996318],
                [105.8459464, 9.3996477],
                [105.8457747, 9.3996424],
                [105.8455467, 9.3996212],
                [105.8452624, 9.3995789],
                [105.8444879, 9.3994208],
                [105.8436745, 9.3992137],
                [105.8433969, 9.3991912],
                [105.8431461, 9.3992071],
                [105.8429275, 9.399256],
                [105.8427586, 9.3993341],
                [105.842607, 9.3994399],
                [105.8424783, 9.3995709],
                [105.8423388, 9.3997879],
                [105.8422476, 9.4000221],
                [105.8421993, 9.4002007],
                [105.8421779, 9.4004256],
                [105.8421779, 9.4006241],
                [105.842194, 9.4007935],
                [105.8422235, 9.4009496],
                [105.8422798, 9.4011249],
                [105.8423368, 9.4012559],
                [105.8425869, 9.401803],
                [105.8430013, 9.4025955],
                [105.8432347, 9.4030639],
                [105.8433044, 9.4033179],
                [105.8433258, 9.4035614],
                [105.8433044, 9.4037889],
                [105.8432132, 9.4040377],
                [105.8430576, 9.4042705],
                [105.8428538, 9.4044716],
                [105.8425963, 9.4046304],
                [105.8423361, 9.4047072],
                [105.8420947, 9.4047204],
                [105.8418077, 9.4046992],
                [105.8415046, 9.4046463],
                [105.8411533, 9.4045431],
                [105.8407885, 9.404379],
                [105.8404371, 9.4041964],
                [105.8401152, 9.4040165],
                [105.8397204, 9.4037012],
                [105.8394579, 9.4034467],
                [105.8392222, 9.4031912],
                [105.8389015, 9.4028734],
                [105.8386561, 9.4026114],
                [105.8384523, 9.40236],
                [105.8378863, 9.4015609],
                [105.8376637, 9.4013201],
                [105.8375081, 9.4011772],
                [105.8373392, 9.4010475],
                [105.8371621, 9.4009655],
                [105.836969, 9.4009046],
                [105.8367424, 9.4008676],
                [105.8364446, 9.4008768],
                [105.8361791, 9.4009006],
                [105.8358479, 9.4009522],
                [105.8355394, 9.4010131],
                [105.8349413, 9.4011481],
                [105.8339207, 9.4013597],
                [105.8329135, 9.4015238],
                [105.8324897, 9.4015556],
                [105.8321625, 9.4015662],
                [105.8318567, 9.4015238],
                [105.8315724, 9.4014074],
                [105.8313417, 9.4012116],
                [105.8309179, 9.4007405],
                [105.8307356, 9.4005024],
                [105.8306926, 9.4003489],
                [105.8307034, 9.4001637],
                [105.8307624, 9.3998991],
                [105.8308831, 9.3995498],
                [105.8310118, 9.3992851],
                [105.8311325, 9.3991131],
                [105.8313053, 9.3989817],
                [105.8315075, 9.3988782],
                [105.8317542, 9.3988138],
                [105.8320257, 9.3988181],
                [105.8322381, 9.3988372],
                [105.8324656, 9.3989226],
                [105.8326668, 9.3990099],
                [105.8329672, 9.3992163],
                [105.8334821, 9.3995868],
                [105.833804, 9.3997509],
                [105.8340561, 9.3998303],
                [105.8343083, 9.3998779],
                [105.8345658, 9.3998938],
                [105.8348179, 9.3998197],
                [105.8350378, 9.3996503],
                [105.8351934, 9.3994227],
                [105.8352739, 9.3991634],
                [105.8352846, 9.3988988],
                [105.8352524, 9.39865],
                [105.8351827, 9.3984172],
                [105.8347059, 9.3971424],
                [105.834614, 9.39676],
                [105.8344665, 9.3959125],
                [105.834268, 9.3941435],
                [105.8342332, 9.3938154],
                [105.8341795, 9.3935534],
                [105.8340964, 9.3932518],
                [105.8340186, 9.3930321],
                [105.833922, 9.3928257],
                [105.8337906, 9.3926167],
                [105.8336645, 9.3924341],
                [105.8335693, 9.3923097],
                [105.8334728, 9.3922171],
                [105.8333494, 9.3921377],
                [105.8332126, 9.3920649],
                [105.8327177, 9.3918201],
                [105.8323154, 9.3916375],
                [105.8315483, 9.3913134],
                [105.8293435, 9.3904269],
                [105.8291222, 9.3903462],
                [105.8289385, 9.3903065],
                [105.8287668, 9.3902932],
                [105.8285925, 9.3902932],
                [105.8284081, 9.3903118],
                [105.8282357, 9.3903515],
                [105.8281271, 9.3903978],
                [105.8280171, 9.3904745],
                [105.8279072, 9.3905764],
                [105.827812, 9.3907034],
                [105.8277261, 9.3908662],
                [105.8276061, 9.3912221],
                [105.8274472, 9.3916137],
                [105.8273238, 9.3919286],
                [105.8272594, 9.3920689],
                [105.827195, 9.3921853],
                [105.8270985, 9.3922912],
                [105.8269644, 9.3923891],
                [105.8268329, 9.3924711],
                [105.8266693, 9.3925399],
                [105.8264816, 9.3925981],
                [105.8262911, 9.3926299],
                [105.8256528, 9.3926881],
                [105.8253899, 9.3927066],
                [105.8251405, 9.392741],
                [105.8248401, 9.3928151],
                [105.8244753, 9.3929395],
                [105.8237538, 9.3932518],
                [105.8232187, 9.3934992],
                [105.822233, 9.3940086],
                [105.8217099, 9.3942335],
                [105.8214793, 9.3942944],
                [105.8212674, 9.3942891],
                [105.8210769, 9.3942203],
                [105.8208945, 9.3940933],
                [105.8207604, 9.393916],
                [105.8206719, 9.3937069],
                [105.8206344, 9.3934793],
                [105.8206183, 9.393093],
                [105.8206397, 9.3925558],
                [105.8207175, 9.3916508],
                [105.8207738, 9.3912274],
                [105.8208382, 9.3907696],
                [105.820924, 9.3902985],
                [105.8211172, 9.3895682],
                [105.8212566, 9.3891183],
                [105.8215731, 9.3882662],
                [105.8219486, 9.3873665],
                [105.8222222, 9.3866916],
                [105.8223134, 9.3864138],
                [105.8223483, 9.3861571],
                [105.8223671, 9.3859666],
                [105.8223429, 9.3857681],
                [105.8222786, 9.3855776],
                [105.82219, 9.385395],
                [105.8220801, 9.3852335],
                [105.8219406, 9.3850946],
                [105.8211306, 9.3843947],
                [105.820578, 9.3838098],
                [105.8203769, 9.383569],
                [105.820232, 9.3833441],
                [105.8201033, 9.3830953],
                [105.819996, 9.3828042],
                [105.8197761, 9.3819389],
                [105.819717, 9.3815657],
                [105.8196674, 9.380404],
                [105.8196285, 9.3801023],
                [105.8195615, 9.3798747],
                [105.8194367, 9.3796006],
                [105.8192825, 9.3793243],
                [105.8191484, 9.3791232],
                [105.8189553, 9.378885],
                [105.8187501, 9.378672],
                [105.8185637, 9.378533],
                [105.8183169, 9.3784272],
                [105.8180889, 9.3783637],
                [105.8177751, 9.3783107],
                [105.8174613, 9.3782737],
                [105.8160987, 9.3781281],
                [105.8148944, 9.3779244],
                [105.8145779, 9.3778476],
                [105.8143365, 9.3777656],
                [105.8141112, 9.3776491],
                [105.8138993, 9.3774983],
                [105.8137411, 9.3773422],
                [105.813607, 9.3771331],
                [105.8135185, 9.3769108],
                [105.8134514, 9.3766488],
                [105.8134353, 9.3763471],
                [105.8134943, 9.3759819],
                [105.8136445, 9.3755506],
                [105.8138725, 9.3751271],
                [105.8141112, 9.3748043],
                [105.8143124, 9.3745688],
                [105.8145511, 9.374365],
                [105.8148086, 9.374185],
                [105.8151224, 9.3740315],
                [105.8154979, 9.3738741],
                [105.8157742, 9.373796],
                [105.8160049, 9.3737404],
                [105.8163013, 9.3737034],
                [105.8167921, 9.3736809],
                [105.8169731, 9.3736716],
                [105.8171502, 9.3736425],
                [105.8173218, 9.3735856],
                [105.8174854, 9.3734837],
                [105.8176437, 9.373317],
                [105.8177617, 9.3731106],
                [105.817869, 9.3728724],
                [105.8179575, 9.3726078],
                [105.817991, 9.3723577],
                [105.8179937, 9.372103],
                [105.817985, 9.3718985],
                [105.8179495, 9.3716107],
                [105.8178717, 9.3713481],
                [105.8177657, 9.3711059],
                [105.817646, 9.3708979],
                [105.8174915, 9.3706971],
                [105.8172529, 9.3704829],
                [105.8170375, 9.3703411],
                [105.8168189, 9.3702419],
                [105.8166654, 9.370187],
                [105.8165333, 9.3701387],
                [105.8163951, 9.3701082],
                [105.8162583, 9.370095],
                [105.8160277, 9.3700897],
                [105.8157903, 9.3700897],
                [105.8152471, 9.3701248],
                [105.8146669, 9.3701905],
                [105.8138303, 9.3702816],
                [105.8129901, 9.3703822],
                [105.8121371, 9.3704351],
                [105.8118126, 9.3704615],
                [105.8114988, 9.3705251],
                [105.8109368, 9.3707282],
                [105.8105788, 9.3708175],
                [105.8102489, 9.3709247],
                [105.8099136, 9.3710477],
                [105.8094006, 9.3712707],
                [105.8089292, 9.3715386],
                [105.8084893, 9.3718536],
                [105.8080146, 9.3722585],
                [105.8077088, 9.3725734],
                [105.807462, 9.372883],
                [105.8071938, 9.3733011],
                [105.8069739, 9.3737219],
                [105.8068076, 9.3740607],
                [105.806424, 9.3749445],
                [105.8063006, 9.3752833],
                [105.8061612, 9.3755598],
                [105.8060525, 9.375757],
                [105.8059734, 9.3758602],
                [105.8058742, 9.3759581],
                [105.8057602, 9.3760203],
                [105.8056408, 9.376056],
                [105.8055255, 9.3760746],
                [105.8054169, 9.3760812],
                [105.8053096, 9.3760772],
                [105.8051902, 9.376056],
                [105.8050628, 9.376015],
                [105.8049139, 9.3759574],
                [105.8043091, 9.3756961],
                [105.804069, 9.3755982],
                [105.8038706, 9.3755215],
                [105.8036533, 9.3754394],
                [105.8034254, 9.375361],
                [105.8031336, 9.3752839],
                [105.8020138, 9.3750193],
                [105.8013443, 9.3748999],
                [105.8003367, 9.3746984],
                [105.7999733, 9.3746257],
                [105.7996032, 9.3745516],
                [105.7993422, 9.374517],
                [105.7990972, 9.3745099],
                [105.7988977, 9.3745185],
                [105.7987046, 9.3745423],
                [105.7985182, 9.3745899],
                [105.7983211, 9.3746865],
                [105.7981548, 9.3747858],
                [105.7980099, 9.3749088],
                [105.7978638, 9.3750795],
                [105.7977431, 9.3752595],
                [105.7974869, 9.3756994],
                [105.7971409, 9.3763074],
                [105.7969166, 9.3766263],
                [105.7966474, 9.3769889],
                [105.7965267, 9.3771199],
                [105.796414, 9.3772204],
                [105.7962745, 9.3773051],
                [105.7961243, 9.3773554],
                [105.7959339, 9.3773792],
                [105.795722, 9.3773792],
                [105.7955235, 9.3773527],
                [105.7953331, 9.3773051],
                [105.7949576, 9.377186],
                [105.7946384, 9.3771013],
                [105.7943286, 9.3770511],
                [105.7939397, 9.3770431],
                [105.793646, 9.3770749],
                [105.7933107, 9.3771569],
                [105.7929915, 9.3772787],
                [105.7926683, 9.377452],
                [105.7922841, 9.3777226],
                [105.7913809, 9.3786032],
                [105.7907854, 9.3792211],
                [105.7904474, 9.3796326],
                [105.7903013, 9.3798522],
                [105.7901604, 9.3800983],
                [105.7900625, 9.3803213],
                [105.7899968, 9.3805019],
                [105.7899633, 9.3806607],
                [105.7899526, 9.3808142],
                [105.7899633, 9.381008],
                [105.7899955, 9.38118],
                [105.7900498, 9.3813686],
                [105.7901467, 9.381616],
                [105.7902704, 9.3818992],
                [105.7906969, 9.3827023],
                [105.7907599, 9.3828532],
                [105.7907921, 9.3829789],
                [105.7908095, 9.383094],
                [105.7908149, 9.3832448],
                [105.7908082, 9.3834327],
                [105.7907908, 9.3835664],
                [105.7907492, 9.3837093],
                [105.7906647, 9.3838561],
                [105.7905494, 9.3839832],
                [105.7903844, 9.3840956],
                [105.7901765, 9.3841975],
                [105.7899271, 9.3842504],
                [105.7896776, 9.3842597],
                [105.7894316, 9.3842266],
                [105.7891949, 9.3841624],
                [105.7889327, 9.3840533],
                [105.7879282, 9.3835247],
                [105.7876164, 9.3833547],
                [105.7873186, 9.3832276],
                [105.7869901, 9.3831218],
                [105.7867326, 9.3830516],
                [105.7864054, 9.3829723],
                [105.7859927, 9.3828815],
                [105.7854646, 9.3827903],
                [105.7852775, 9.3831747],
                [105.7852077, 9.3833203],
                [105.7851139, 9.3834658],
                [105.7849503, 9.3836987],
                [105.7848389, 9.3839871],
                [105.7846847, 9.3843166],
                [105.7845456, 9.384564],
                [105.7845077, 9.3846441],
                [105.7844869, 9.3847168],
                [105.7844748, 9.3847903],
                [105.7844708, 9.3848611],
                [105.7844768, 9.3849451],
                [105.7845493, 9.3853844],
                [105.7845573, 9.385481],
                [105.7845452, 9.3855656],
                [105.7845063, 9.3856569],
                [105.7844004, 9.3858144],
                [105.7843253, 9.3858872],
                [105.7842341, 9.3859348],
                [105.7841174, 9.3859599],
                [105.7836789, 9.3859666],
                [105.7834724, 9.3859838],
                [105.783298, 9.3860089],
                [105.7831384, 9.3860446],
                [105.7829574, 9.3861161],
                [105.7828313, 9.3861769],
                [105.7827212, 9.3862522],
                [105.7826315, 9.3863516],
                [105.7825926, 9.3864482],
                [105.7825685, 9.3865382],
                [105.7825497, 9.3867141],
                [105.782546, 9.3867982],
                [105.7825059, 9.3869033],
                [105.7824558, 9.3869936],
                [105.7822278, 9.3872619],
                [105.7820374, 9.387516],
                [105.7819247, 9.3876284],
                [105.7815734, 9.3878931],
                [105.781395, 9.3880571],
                [105.7812743, 9.3882093],
                [105.7811442, 9.388425],
                [105.7810852, 9.3885096],
                [105.7810181, 9.3885705],
                [105.780935, 9.3886049],
                [105.7808089, 9.3886473],
                [105.7806909, 9.3887108],
                [105.780412, 9.3888907],
                [105.7801491, 9.3890627],
                [105.7799882, 9.3891739],
                [105.7798655, 9.3893055],
                [105.779795, 9.3894067],
                [105.7797655, 9.3895113],
                [105.7797669, 9.3896026],
                [105.7797977, 9.3898196],
                [105.779795, 9.389936],
                [105.7797843, 9.3900524],
                [105.7797414, 9.390145],
                [105.7796556, 9.3902138],
                [105.7795483, 9.3902562],
                [105.7794088, 9.3902774],
                [105.7790467, 9.3903118],
                [105.7787731, 9.3903488],
                [105.7785183, 9.3903911],
                [105.7782481, 9.3904494],
                [105.7780704, 9.3905155],
                [105.7779926, 9.3905605],
                [105.7779711, 9.3906214],
                [105.777998, 9.3907087],
                [105.778065, 9.3908397],
                [105.7783225, 9.3912512],
                [105.7784137, 9.3915132],
                [105.7784942, 9.3918519],
                [105.7785505, 9.392143],
                [105.7785827, 9.3923705],
                [105.7786041, 9.392655],
                [105.7786041, 9.3928072],
                [105.7785915, 9.3929475],
                [105.7785614, 9.3930077],
                [105.7785111, 9.3930629],
                [105.7784558, 9.393098],
                [105.7783702, 9.3931133],
                [105.7782795, 9.3931084],
                [105.7778719, 9.3931168],
                [105.777542, 9.393138],
                [105.7770874, 9.3932068],
                [105.7772067, 9.3936169],
                [105.7778826, 9.3942666],
                [105.7794973, 9.3959006],
                [105.7798782, 9.3962473],
                [105.7810771, 9.397401],
                [105.7827294, 9.3989544],
                [105.7831934, 9.3994386],
                [105.7835367, 9.3997853],
                [105.7837754, 9.4000181],
                [105.7838291, 9.4000419],
                [105.7839847, 9.4000684],
                [105.784159, 9.4001174],
                [105.7848349, 9.4002536],
                [105.7850508, 9.4003079],
                [105.7852091, 9.4003939],
                [105.7853419, 9.4005302],
                [105.7855082, 9.4004997],
                [105.7863665, 9.4006056],
                [105.7863638, 9.4007723],
                [105.7862833, 9.4012486],
                [105.7861868, 9.4014391],
                [105.786117, 9.4017884],
                [105.7861036, 9.4020345],
                [105.7859454, 9.4027887],
                [105.7859105, 9.4029131],
                [105.7858568, 9.4030004],
                [105.785771, 9.4030612],
                [105.7854411, 9.4031883],
                [105.7852721, 9.4032782],
                [105.7851326, 9.4033894],
                [105.7846177, 9.4038921],
                [105.784497, 9.4039874],
                [105.7843548, 9.4040456],
                [105.7841, 9.4041224],
                [105.7839015, 9.4043552],
                [105.7834992, 9.4048421],
                [105.7833356, 9.4050908],
                [105.7827347, 9.405763],
                [105.7826275, 9.4059905],
                [105.7825363, 9.4061228],
                [105.78215, 9.4065039],
                [105.7820696, 9.4066097],
                [105.7819851, 9.4069008],
                [105.7819368, 9.4070635],
                [105.7818604, 9.4071707],
                [105.7817262, 9.4072752],
                [105.781517, 9.4074512],
                [105.7800981, 9.406742],
                [105.7789314, 9.4061572],
                [105.7775608, 9.405501],
                [105.7763672, 9.4048897],
                [105.7757395, 9.4057603],
                [105.7753547, 9.4063504],
                [105.7750207, 9.4068532],
                [105.7739317, 9.4085017],
                [105.7738647, 9.4085758],
                [105.7738164, 9.4086049],
                [105.7737601, 9.4086129],
                [105.7736582, 9.4086155],
                [105.7735991, 9.4086287],
                [105.7735562, 9.4086552],
                [105.7731941, 9.4092373],
                [105.7727301, 9.4100232],
                [105.7723117, 9.4108012],
                [105.7720287, 9.4111849],
                [105.7717819, 9.411472],
                [105.7702102, 9.4100616],
                [105.7694256, 9.4094437],
                [105.7692406, 9.4093511],
                [105.7689562, 9.4090237],
                [105.7683071, 9.4095125],
                [105.7677171, 9.4099174],
                [105.7674301, 9.410137],
                [105.7673013, 9.4102587],
                [105.7672021, 9.4104043],
                [105.7670867, 9.4106848],
                [105.7669473, 9.4108912],
                [105.7667429, 9.4110378],
                [105.7666039, 9.4106901],
                [105.7664323, 9.410399],
                [105.7662606, 9.4101608],
                [105.7660782, 9.4100074],
                [105.7658958, 9.4099174],
                [105.7657081, 9.4098645],
                [105.7654935, 9.4098645],
                [105.7652736, 9.4099015],
                [105.765, 9.4100074],
                [105.7646352, 9.4101873],
                [105.7638037, 9.4107139],
                [105.76327, 9.4110367],
                [105.7629427, 9.4112457],
                [105.7626423, 9.4115474],
                [105.7626075, 9.4118067],
                [105.7625887, 9.4119073],
                [105.7625565, 9.4120078],
                [105.7624251, 9.4121586],
                [105.7623365, 9.4122671],
                [105.7622641, 9.4123809],
                [105.7622105, 9.4125106],
                [105.7621837, 9.4126693],
                [105.7621542, 9.4131615],
                [105.7621381, 9.4132806],
                [105.7621059, 9.4133864],
                [105.7620227, 9.4135796],
                [105.7619624, 9.4136696],
                [105.761892, 9.413741],
                [105.7618142, 9.413786],
                [105.7617384, 9.4138098],
                [105.7616445, 9.4138151],
                [105.7614407, 9.4138045],
                [105.7612154, 9.4138098],
                [105.7610866, 9.4138019],
                [105.7609713, 9.4137595],
                [105.7608774, 9.4136934],
                [105.7608077, 9.4136087],
                [105.7607621, 9.4135187],
                [105.7607339, 9.413397],
                [105.7607326, 9.4132237],
                [105.7607406, 9.4130663],
                [105.7607594, 9.4129313],
                [105.760805, 9.4127778],
                [105.761041, 9.412283],
                [105.7610974, 9.4121639],
                [105.7611322, 9.4120263],
                [105.7611396, 9.4118656],
                [105.7611269, 9.4117472],
                [105.761084, 9.4116374],
                [105.7610008, 9.4115448],
                [105.7608962, 9.4114733],
                [105.7607782, 9.4114177],
                [105.7601532, 9.411218],
                [105.7599869, 9.4111571],
                [105.7598622, 9.4110976],
                [105.7597482, 9.4110208],
                [105.7592681, 9.4106186],
                [105.7589355, 9.4105181],
                [105.758737, 9.4104625],
                [105.7585627, 9.4104016],
                [105.7584339, 9.4103487],
                [105.758324, 9.4102693],
                [105.7582328, 9.4101794],
                [105.7580638, 9.4099677],
                [105.7579431, 9.4098301],
                [105.7578385, 9.4097428],
                [105.7577312, 9.4096819],
                [105.7576078, 9.409629],
                [105.7574281, 9.4095946],
                [105.7572618, 9.4095708],
                [105.7570928, 9.409576],
                [105.7569373, 9.409621],
                [105.7568085, 9.4096978],
                [105.756712, 9.4098248],
                [105.7566369, 9.4099756],
                [105.7564867, 9.4103884],
                [105.7564169, 9.4105789],
                [105.7563633, 9.4107059],
                [105.7563016, 9.4108091],
                [105.7562265, 9.4108779],
                [105.7561406, 9.4109203],
                [105.7560334, 9.4109388],
                [105.7559153, 9.4109309],
                [105.7557946, 9.4108885],
                [105.7556659, 9.4108078],
                [105.7554299, 9.4105988],
                [105.7551844, 9.410395],
                [105.7551268, 9.4103315],
                [105.7550932, 9.4102733],
                [105.7550772, 9.4101992],
                [105.7550745, 9.4101145],
                [105.7550745, 9.4098314],
                [105.7550624, 9.4096581],
                [105.7550396, 9.4095261],
                [105.7550074, 9.4094279],
                [105.7549611, 9.4093313],
                [105.7548639, 9.40924],
                [105.7547647, 9.4091659],
                [105.7544535, 9.4089727],
                [105.754247, 9.4088537],
                [105.7537642, 9.4086605],
                [105.7536569, 9.4086287],
                [105.753555, 9.4086155],
                [105.7534584, 9.4086155],
                [105.7533458, 9.4086446],
                [105.7532439, 9.4087055],
                [105.75315, 9.4087901],
                [105.7529756, 9.4089913],
                [105.7527155, 9.4093088],
                [105.7525894, 9.4094332],
                [105.752466, 9.4095311],
                [105.7523507, 9.4096184],
                [105.7521147, 9.4097454],
                [105.7520154, 9.4098116],
                [105.7519457, 9.409883],
                [105.7518974, 9.4099809],
                [105.7518759, 9.4101026],
                [105.7518665, 9.4102482],
                [105.7518518, 9.4106954],
                [105.7518196, 9.4110976],
                [105.7515138, 9.4110658],
                [105.7513797, 9.4110341],
                [105.7512724, 9.4109891],
                [105.7511705, 9.4109361],
                [105.7509774, 9.4108038],
                [105.7507119, 9.4106332],
                [105.750626, 9.4105644],
                [105.7505643, 9.4104903],
                [105.7505268, 9.4104109],
                [105.750512, 9.4103249],
                [105.7505147, 9.4102468],
                [105.7505321, 9.4101648],
                [105.750567, 9.4100742],
                [105.7506435, 9.4099465],
                [105.7507333, 9.4098407],
                [105.7510552, 9.4095708],
                [105.7511571, 9.4094914],
                [105.7512456, 9.409412],
                [105.7513261, 9.4093088],
                [105.7517284, 9.4086684],
                [105.7520114, 9.4082675],
                [105.7520556, 9.4081762],
                [105.7520717, 9.4081048],
                [105.7520771, 9.4080148],
                [105.752061, 9.4079196],
                [105.7518961, 9.4074737],
                [105.7516707, 9.4068049],
                [105.7516305, 9.4066871],
                [105.7515668, 9.4065813],
                [105.7514776, 9.4065012],
                [105.7513958, 9.4064523],
                [105.7512979, 9.4064258],
                [105.7511799, 9.4064152],
                [105.7508594, 9.4064086],
                [105.7505737, 9.4064232],
                [105.7504731, 9.4064338],
                [105.7503819, 9.4064682],
                [105.7502452, 9.4065317],
                [105.7501566, 9.4065687],
                [105.7500681, 9.4065912],
                [105.7498817, 9.4065978],
                [105.749537, 9.4066044],
                [105.7493386, 9.4066521],
                [105.7490543, 9.4067288],
                [105.7489067, 9.4067817],
                [105.7487565, 9.4068532],
                [105.7486036, 9.4069458],
                [105.7484132, 9.4070569],
                [105.7483032, 9.4071204],
                [105.7481638, 9.4071522],
                [105.7479626, 9.4071522],
                [105.7477319, 9.4070516],
                [105.7475549, 9.4069855],
                [105.7473698, 9.4068929],
                [105.7471418, 9.4067129],
                [105.7470024, 9.40657],
                [105.7467502, 9.4062684],
                [105.746659, 9.4061824],
                [105.7465544, 9.4061334],
                [105.7464337, 9.4061175],
                [105.7461199, 9.4061162],
                [105.7458611, 9.4061242],
                [105.7457264, 9.4061088],
                [105.7456158, 9.4060767],
                [105.7455271, 9.4060315],
                [105.7454596, 9.4059649],
                [105.7454217, 9.405867],
                [105.7454011, 9.4057392],
                [105.7453923, 9.4055861],
                [105.745388, 9.405095],
                [105.7454128, 9.4048763],
                [105.7454696, 9.4047118],
                [105.7455691, 9.404615],
                [105.7456787, 9.4045431],
                [105.7458256, 9.4044857],
                [105.7460971, 9.4044028],
                [105.746232, 9.4043243],
                [105.7463394, 9.4042187],
                [105.7464984, 9.4040085],
                [105.7465598, 9.4038842],
                [105.7465666, 9.4037718],
                [105.7465204, 9.403652],
                [105.7464269, 9.4035546],
                [105.7462276, 9.4034317],
                [105.7459858, 9.40331],
                [105.7457226, 9.4032447],
                [105.7454201, 9.4032147],
                [105.7451641, 9.4032101],
                [105.7450048, 9.4032424],
                [105.744866, 9.4033087],
                [105.7446714, 9.4034003],
                [105.7444596, 9.4034873],
                [105.7443389, 9.4035137],
                [105.7442237, 9.4035066],
                [105.7440866, 9.4034624],
                [105.7439554, 9.4033894],
                [105.743771, 9.4032669],
                [105.743437, 9.4030316],
                [105.7428476, 9.4026087],
                [105.7427293, 9.4024794],
                [105.7426411, 9.4023335],
                [105.7425762, 9.4021518],
                [105.7424229, 9.4015751],
                [105.7422893, 9.4011393],
                [105.7421867, 9.4009137],
                [105.7420697, 9.4007424],
                [105.7419785, 9.4006004],
                [105.7418316, 9.4003946],
                [105.7416617, 9.4002489],
                [105.7415259, 9.4001705],
                [105.7412438, 9.4001011],
                [105.7409915, 9.4000525],
                [105.7407401, 9.4000486],
                [105.7405535, 9.4000771],
                [105.7403013, 9.4001951],
                [105.74012, 9.4003346],
                [105.7399898, 9.4005165],
                [105.7399236, 9.4007922],
                [105.7397833, 9.4014213],
                [105.7397443, 9.4016169],
                [105.7396836, 9.4017547],
                [105.7395679, 9.4018781],
                [105.7393989, 9.4019677],
                [105.7392243, 9.4019809],
                [105.7390355, 9.4019523],
                [105.738777, 9.4018449],
                [105.7384771, 9.4016882],
                [105.7383361, 9.4016323],
                [105.738186, 9.4016139],
                [105.7380121, 9.401623],
                [105.7374032, 9.4017072],
                [105.7371383, 9.4016851],
                [105.7369106, 9.401623],
                [105.7369561, 9.4011428],
                [105.7369736, 9.4009761],
                [105.7370138, 9.4008517],
                [105.7370822, 9.4007445],
                [105.737388, 9.4004217],
                [105.7374631, 9.400333],
                [105.7375154, 9.4002351],
                [105.7375529, 9.4001359],
                [105.7375784, 9.4000247],
                [105.737671, 9.3995074],
                [105.7377541, 9.3988326],
                [105.7377675, 9.3985812],
                [105.7377514, 9.3984066],
                [105.7377246, 9.398224],
                [105.737569, 9.3977239],
                [105.7374644, 9.3972449],
                [105.7372686, 9.396504],
                [105.7372579, 9.3963452],
                [105.7372713, 9.3962261],
                [105.7373035, 9.3961203],
                [105.7374376, 9.3957802],
                [105.7375811, 9.3953046],
                [105.7373652, 9.3951782],
                [105.7371721, 9.3951173],
                [105.7367429, 9.3950221],
                [105.7363969, 9.3949295],
                [105.7362199, 9.3949083],
                [105.7360697, 9.3949136],
                [105.7358739, 9.3949612],
                [105.7356432, 9.3950565],
                [105.7354005, 9.3951756],
                [105.7352181, 9.3953066],
                [105.7350312, 9.3954428],
                [105.7348922, 9.3954997],
                [105.7347809, 9.3955288],
                [105.7346696, 9.3955368],
                [105.7345623, 9.3955288],
                [105.7344523, 9.3954891],
                [105.7343504, 9.3954389],
                [105.7341814, 9.3953396],
                [105.7340996, 9.3953145],
                [105.7340057, 9.3953118],
                [105.7338904, 9.3953396],
                [105.733712, 9.3953978],
                [105.7335967, 9.3954137],
                [105.7334733, 9.3953846],
                [105.7333821, 9.3953238],
                [105.7333124, 9.3952523],
                [105.7332641, 9.3951861],
                [105.7332011, 9.3950552],
                [105.733134, 9.3949215],
                [105.7330857, 9.3948223],
                [105.7330281, 9.3947231],
                [105.7326941, 9.3943407],
                [105.7325184, 9.3941568],
                [105.7323924, 9.3940562],
                [105.7322703, 9.3940112],
                [105.7321885, 9.3939861],
                [105.7320799, 9.3939636],
                [105.7319967, 9.3939543],
                [105.7319123, 9.3939583],
                [105.7318144, 9.3939781],
                [105.7316387, 9.3940245],
                [105.7315153, 9.3940417],
                [105.7313865, 9.3940403],
                [105.7313409, 9.3940443],
                [105.7313007, 9.3940536],
                [105.7312484, 9.3940853],
                [105.7310942, 9.3941726],
                [105.7310285, 9.3942017],
                [105.7309668, 9.3942216],
                [105.7309091, 9.3942309],
                [105.7308514, 9.3942335],
                [105.7306503, 9.3942295],
                [105.730598, 9.3942322],
                [105.7305591, 9.3942481],
                [105.7305363, 9.3942772],
                [105.7305323, 9.3943248],
                [105.7305752, 9.394465],
                [105.7305792, 9.3945114],
                [105.7305658, 9.3945484],
                [105.7305403, 9.3945735],
                [105.7302949, 9.3946781],
                [105.730142, 9.3947826],
                [105.7294781, 9.3951213],
                [105.7291684, 9.3952285],
                [105.7287204, 9.3954825],
                [105.7282349, 9.395771],
                [105.7281518, 9.3957154],
                [105.728078, 9.3956267],
                [105.728015, 9.3955302],
                [105.727903, 9.3953158],
                [105.7278447, 9.3952225],
                [105.7277977, 9.395159],
                [105.7276167, 9.3949334],
                [105.7275188, 9.3948183],
                [105.7272633, 9.3945888],
                [105.7269354, 9.3942666],
                [105.7268871, 9.3942176],
                [105.726867, 9.3941687],
                [105.7268603, 9.3941091],
                [105.7268496, 9.3939199],
                [105.7268442, 9.393394],
                [105.7268462, 9.3932445],
                [105.726859, 9.3930797],
                [105.7269166, 9.392747],
                [105.7263467, 9.3927992],
                [105.7254615, 9.392958],
                [105.7243726, 9.3931194],
                [105.7240534, 9.3919194],
                [105.7236108, 9.3902965],
                [105.7220652, 9.3912631],
                [105.7204834, 9.3922382],
                [105.7203707, 9.3918783],
                [105.7203104, 9.3916005],
                [105.7202889, 9.3914563],
                [105.7202768, 9.3911533],
                [105.7181981, 9.3911347],
                [105.7170917, 9.3911242],
                [105.7161784, 9.3908781],
                [105.7160188, 9.390978],
                [105.7158767, 9.3910924],
                [105.7157546, 9.3912009],
                [105.7156527, 9.3913173],
                [105.7155602, 9.3914285],
                [105.7154676, 9.3915502],
                [105.7153469, 9.3917288],
                [105.7152396, 9.3919075],
                [105.7151189, 9.3921562],
                [105.7149875, 9.392524],
                [105.7148695, 9.3929236],
                [105.7147689, 9.3933629],
                [105.714722, 9.3934899],
                [105.7146616, 9.3935918],
                [105.7145785, 9.3936778],
                [105.7144698, 9.3937559],
                [105.7143304, 9.3938419],
                [105.7135123, 9.3942732],
                [105.7133514, 9.394342],
                [105.7131931, 9.3943811],
                [105.7130922, 9.394379],
                [105.7130201, 9.3943552],
                [105.712948, 9.3943212],
                [105.7124287, 9.3939768],
                [105.7121927, 9.3938392],
                [105.7120854, 9.3937995],
                [105.7119915, 9.3937889],
                [105.7118949, 9.3938048],
                [105.711785, 9.3938498],
                [105.711675, 9.3939292],
                [105.7114685, 9.3941541],
                [105.7111278, 9.3944955],
                [105.7107443, 9.3949718],
                [105.7103151, 9.3954878],
                [105.7100952, 9.3957816],
                [105.7099637, 9.3959985],
                [105.709835, 9.3963293],
                [105.7097492, 9.3966363],
                [105.709725, 9.396885],
                [105.7097197, 9.3971179],
                [105.7097471, 9.397405],
                [105.7094005, 9.3973984],
                [105.7091269, 9.3973534],
                [105.7087111, 9.3972581],
                [105.7066432, 9.3968003],
                [105.7059833, 9.3966442],
                [105.7050955, 9.3964537],
                [105.7057768, 9.3960091],
                [105.7070911, 9.3951094],
                [105.7078797, 9.3945511],
                [105.707413, 9.3924102],
                [105.7073379, 9.3921959],
                [105.7071742, 9.3914338],
                [105.7071072, 9.3909574],
                [105.7070696, 9.3885361],
                [105.7070589, 9.3866176],
                [105.7070133, 9.3849768],
                [105.7071018, 9.3842862],
                [105.707134, 9.3835981],
                [105.7069784, 9.3835915],
                [105.7068604, 9.3835981],
                [105.706668, 9.3836405],
                [105.7062422, 9.381526],
                [105.7058157, 9.3796114],
                [105.705467, 9.3779773],
                [105.7054415, 9.3779005],
                [105.7054067, 9.3778436],
                [105.7053436, 9.3778053],
                [105.7052793, 9.3777868],
                [105.7052055, 9.3777828],
                [105.7048112, 9.3778185],
                [105.704661, 9.3778291],
                [105.7045108, 9.3778212],
                [105.7043901, 9.3777868],
                [105.703918, 9.3776227],
                [105.7037464, 9.3775724],
                [105.7035935, 9.377538],
                [105.7033601, 9.3775089],
                [105.7028049, 9.3774665],
                [105.7024911, 9.3774586],
                [105.7020324, 9.3774586],
                [105.7018152, 9.3774692],
                [105.7016033, 9.3774639],
                [105.7014933, 9.3774613],
                [105.7013753, 9.3774798],
                [105.7012573, 9.3775221],
                [105.7009757, 9.37762],
                [105.7006618, 9.3776809],
                [105.7003453, 9.377755],
                [105.7001871, 9.3777762],
                [105.6999698, 9.3777841],
                [105.6997767, 9.3778079],
                [105.6996238, 9.3778635],
                [105.6989801, 9.378226],
                [105.6987977, 9.3783187],
                [105.6986368, 9.3783901],
                [105.6982291, 9.3785449],
                [105.6978522, 9.3787368],
                [105.6977221, 9.3788056],
                [105.6976095, 9.3788241],
                [105.697411, 9.3788374],
                [105.697234, 9.3788903],
                [105.6970838, 9.3789644],
                [105.6968652, 9.3791271],
                [105.6967337, 9.3791973],
                [105.6966104, 9.379229],
                [105.696487, 9.3792396],
                [105.6963649, 9.379225],
                [105.696196, 9.3791814],
                [105.6953075, 9.3791311],
                [105.6951338, 9.3791629],
                [105.6949877, 9.3792283],
                [105.6947241, 9.3793708],
                [105.6943841, 9.3795267],
                [105.6942996, 9.3796035],
                [105.6942272, 9.3797054],
                [105.6941695, 9.3797556],
                [105.6940874, 9.3797814],
                [105.6930953, 9.3797636],
                [105.6924784, 9.3797662],
                [105.691824, 9.3797636],
                [105.6913841, 9.3797742],
                [105.6903407, 9.3795492],
                [105.6880997, 9.3790477],
                [105.6879115, 9.379009],
                [105.6862128, 9.3786601],
                [105.6851761, 9.378455],
                [105.6844908, 9.3799052],
                [105.6832261, 9.3827354],
                [105.6824657, 9.3844714],
                [105.6820795, 9.3843761],
                [105.6816396, 9.3841882],
                [105.6810576, 9.3839448],
                [105.6806847, 9.3837146],
                [105.6804849, 9.3836285],
                [105.6800169, 9.3834248],
                [105.6798626, 9.3833851],
                [105.679695, 9.3833838],
                [105.6794643, 9.3834023],
                [105.6791505, 9.3834658],
                [105.6788099, 9.3836206],
                [105.6782842, 9.3837278],
                [105.6776485, 9.3840427],
                [105.6773588, 9.384216],
                [105.6773494, 9.3843655],
                [105.677336, 9.3844661],
                [105.6773132, 9.384564],
                [105.6772757, 9.384658],
                [105.677218, 9.38474],
                [105.6769752, 9.3850205],
                [105.6765112, 9.3856609],
                [105.6745116, 9.3886473],
                [105.6732688, 9.3906196],
                [105.6731125, 9.3908265],
                [105.6725737, 9.3905102],
                [105.6710476, 9.3896237],
                [105.6704414, 9.3893174],
                [105.6702644, 9.3895973],
                [105.6699988, 9.3894411],
                [105.6683734, 9.3888563],
                [105.668655, 9.3883853],
                [105.6680569, 9.3878401],
                [105.6680113, 9.3879142],
                [105.6677243, 9.3876919],
                [105.6669706, 9.3890389],
                [105.666512, 9.3887769],
                [105.6663403, 9.3890495],
                [105.6656939, 9.3890257],
                [105.6650099, 9.3889357],
                [105.6644654, 9.3888537],
                [105.6639799, 9.3887663],
                [105.6631941, 9.38847],
                [105.6624055, 9.3881577],
                [105.6623492, 9.3882424],
                [105.6614292, 9.3878613],
                [105.6608632, 9.3875252],
                [105.6609169, 9.3874247],
                [105.6599727, 9.3872871],
                [105.6591372, 9.3871375],
                [105.6582347, 9.3870039],
                [105.6582212, 9.3867155],
                [105.6581676, 9.3867287],
                [105.658114, 9.3867287],
                [105.6580657, 9.3867049],
                [105.6579182, 9.3865911],
                [105.6578243, 9.3864747],
                [105.657666, 9.3863767],
                [105.6576633, 9.3862233],
                [105.6570518, 9.3860856],
                [105.6563866, 9.3859798],
                [105.6561023, 9.3859533],
                [105.6560513, 9.3860116],
                [105.6557858, 9.385993],
                [105.6556463, 9.3859666],
                [105.6555444, 9.3859348],
                [105.655421, 9.3858316],
                [105.6552628, 9.3857602],
                [105.6549189, 9.3864616],
                [105.6538948, 9.3858792],
                [105.6525993, 9.3880016],
                [105.6499493, 9.387381],
                [105.6499976, 9.3899651],
                [105.6499976, 9.3905923],
                [105.6499976, 9.3911586],
                [105.6500566, 9.3916666],
                [105.6500781, 9.3917884],
                [105.650129, 9.3918995],
                [105.6502444, 9.3920398],
                [105.6501558, 9.39218],
                [105.650019, 9.3923176],
                [105.6498997, 9.3924063],
                [105.6497583, 9.3924889],
                [105.6496254, 9.3925444],
                [105.6494538, 9.3925846],
                [105.6491446, 9.3926147],
                [105.6489328, 9.3930004],
                [105.6483494, 9.3939332],
                [105.6477888, 9.394776],
                [105.6475112, 9.3946119],
                [105.6473368, 9.3948871],
                [105.6467977, 9.3950618],
                [105.6467266, 9.395202],
                [105.6460427, 9.3950988],
                [105.6456766, 9.3949969],
                [105.6453305, 9.3948421],
                [105.6449121, 9.3946093],
                [105.6449114, 9.3945264],
                [105.644668, 9.3943618],
                [105.6445152, 9.3942758],
                [105.6438741, 9.3939557],
                [105.643917, 9.3938022],
                [105.6437963, 9.393691],
                [105.6436864, 9.3936355],
                [105.6433913, 9.3935878],
                [105.6429595, 9.3935931],
                [105.6426617, 9.3936249],
                [105.6420609, 9.3937678],
                [105.6415057, 9.3940615],
                [105.640972, 9.3943605],
                [105.6406528, 9.3944849],
                [105.6399178, 9.3945008],
                [105.6394297, 9.3945643],
                [105.6392151, 9.3946066],
                [105.6389952, 9.3947072],
                [105.6387967, 9.3948607],
                [105.6386465, 9.3950433],
                [105.6385231, 9.3952946],
                [105.6382804, 9.3962036],
                [105.6369151, 9.3961679],
                [105.6366808, 9.3961805],
                [105.6375493, 9.3974801],
                [105.6394528, 9.4003549],
                [105.6401197, 9.4013908],
                [105.6403336, 9.4014471],
                [105.6406809, 9.4015079],
                [105.6406246, 9.4018387],
                [105.6407198, 9.401844],
                [105.6407869, 9.4021377],
                [105.6408298, 9.4022515],
                [105.6410095, 9.4024976],
                [105.640811, 9.4025479],
                [105.6410336, 9.4033814],
                [105.6410497, 9.4034687],
                [105.6410417, 9.403564],
                [105.6405978, 9.404555],
                [105.6406957, 9.4049241],
                [105.6402799, 9.4050697],
                [105.6407332, 9.4067447],
                [105.6383689, 9.4075438],
                [105.6387216, 9.409023],
                [105.6380215, 9.4092665],
                [105.6351569, 9.4101714],
                [105.6344944, 9.4103223],
                [105.6313536, 9.411251],
                [105.6317371, 9.4129551],
                [105.6323245, 9.4154028],
                [105.6315064, 9.4156197],
                [105.6313415, 9.4152109],
                [105.6307809, 9.4153816],
                [105.6308097, 9.4155734],
                [105.6308409, 9.415796],
                [105.6308533, 9.4159855],
                [105.6308533, 9.4161238],
                [105.6308332, 9.416227],
                [105.6307997, 9.416317],
                [105.6306981, 9.4165574],
                [105.6306709, 9.4166719],
                [105.6306418, 9.4168495],
                [105.6306146, 9.417086],
                [105.6305848, 9.4173761],
                [105.6305864, 9.417468],
                [105.6306052, 9.4175355],
                [105.6306884, 9.4176731],
                [105.6307849, 9.4178054],
                [105.6307527, 9.4179033],
                [105.6302927, 9.4177948],
                [105.6301452, 9.4177723],
                [105.6300098, 9.4177862],
                [105.629873, 9.4178471],
                [105.6297737, 9.4179198],
                [105.6293915, 9.4182777],
                [105.6292292, 9.4184391],
                [105.6290951, 9.418545],
                [105.6289148, 9.4186217],
                [105.6286559, 9.4186733],
                [105.6283367, 9.4186984],
                [105.6279998, 9.4186961],
                [105.6277151, 9.4186508],
                [105.6274241, 9.4185807],
                [105.6267308, 9.4183306],
                [105.6262238, 9.4181652],
                [105.6259704, 9.4181031],
                [105.6257437, 9.4180607],
                [105.625513, 9.4180369],
                [105.6252495, 9.4180336],
                [105.6243389, 9.4180534],
                [105.6241223, 9.418025],
                [105.6239279, 9.4179747],
                [105.6237334, 9.4179059],
                [105.6235483, 9.4178093],
                [105.6234357, 9.4176995],
                [105.6233552, 9.4175871],
                [105.6232412, 9.4172715],
                [105.6227678, 9.4153697],
                [105.6227001, 9.4152142],
                [105.6226062, 9.4150918],
                [105.6224741, 9.4149489],
                [105.6223172, 9.4148352],
                [105.6220007, 9.4146314],
                [105.6218357, 9.4144568],
                [105.6217345, 9.4143886],
                [105.6216051, 9.4143489],
                [105.6215045, 9.4143536],
                [105.6214039, 9.41438],
                [105.6208232, 9.4146103],
                [105.6206596, 9.4146632],
                [105.6205121, 9.4146949],
                [105.6203458, 9.4147234],
                [105.6202405, 9.4147306],
                [105.6201573, 9.4147201],
                [105.6200943, 9.4146949],
                [105.620042, 9.4146566],
                [105.6199843, 9.4145818],
                [105.6198066, 9.4148868],
                [105.6197235, 9.4150257],
                [105.6195518, 9.4152625],
                [105.6181839, 9.4126879],
                [105.6170332, 9.4105525],
                [105.6167972, 9.4101661],
                [105.6169018, 9.40992],
                [105.6170399, 9.4096038],
                [105.6166778, 9.4094226],
                [105.6170285, 9.4086883],
                [105.6171727, 9.4082596],
                [105.617225, 9.408081],
                [105.617225, 9.4079579],
                [105.6171861, 9.4078137],
                [105.6170654, 9.4075524],
                [105.6169642, 9.4072561],
                [105.616529, 9.4071495],
                [105.6166175, 9.4068373],
                [105.6154561, 9.4064033],
                [105.6149411, 9.4077635],
                [105.6137221, 9.4073454],
                [105.6135812, 9.4077357],
                [105.6130944, 9.4089965],
                [105.6121033, 9.4115844],
                [105.6118619, 9.4122354],
                [105.6110653, 9.4122433],
                [105.6104484, 9.4122592],
                [105.609794, 9.412365],
                [105.6083429, 9.4126323],
                [105.6079808, 9.4127276],
                [105.6076884, 9.4128704],
                [105.6075302, 9.4129631],
                [105.6068382, 9.4134076],
                [105.6067148, 9.4134341],
                [105.6066102, 9.4134923],
                [105.6065029, 9.413577],
                [105.6063929, 9.4137119],
                [105.6060791, 9.4140294],
                [105.6057197, 9.414339],
                [105.6052744, 9.4147412],
                [105.6046039, 9.4153445],
                [105.6043732, 9.415236],
                [105.6042713, 9.4154319],
                [105.604156, 9.4156039],
                [105.6040487, 9.4157414],
                [105.6039092, 9.4158817],
                [105.6037483, 9.4159822],
                [105.6025037, 9.4166504],
                [105.6022918, 9.4167761],
                [105.602147, 9.4168885],
                [105.6020169, 9.4170076],
                [105.6017661, 9.417292],
                [105.6017849, 9.4174826],
                [105.6018305, 9.4180462],
                [105.6018466, 9.4182975],
                [105.6018788, 9.4184881],
                [105.6019378, 9.418668],
                [105.6023857, 9.4198402],
                [105.6027102, 9.420634],
                [105.6028873, 9.4211447],
                [105.6003553, 9.423587],
                [105.5997008, 9.4242696],
                [105.5991214, 9.4240844],
                [105.596855, 9.4203244],
                [105.5969837, 9.4201524],
                [105.5955943, 9.4193718],
                [105.5941392, 9.4201035],
                [105.5938308, 9.4202027],
                [105.5925634, 9.4208483],
                [105.5914021, 9.4214913],
                [105.5902621, 9.422092],
                [105.5884087, 9.423161],
                [105.5876181, 9.423583],
                [105.5866019, 9.4217292],
                [105.5831636, 9.4157305],
                [105.582014, 9.4136702],
                [105.5814075, 9.4125295],
                [105.5811051, 9.411984],
                [105.5810165, 9.4118543],
                [105.5808878, 9.4117247],
                [105.5805287, 9.4113924],
                [105.5786576, 9.4133749],
                [105.5778724, 9.4142729],
                [105.5764563, 9.4157445],
                [105.5757447, 9.4165383],
                [105.5749398, 9.4174641],
                [105.5744246, 9.418149],
                [105.5736877, 9.4189243],
                [105.5729661, 9.4197908],
                [105.5721798, 9.4205886],
                [105.5715372, 9.4213555],
                [105.569372, 9.4237593],
                [105.5680705, 9.4252786],
                [105.5672472, 9.4261482],
                [105.5666313, 9.426856],
                [105.5660495, 9.427592],
                [105.565306, 9.4283268],
                [105.5647421, 9.4289349],
                [105.5641411, 9.4296364],
                [105.5634685, 9.4303921],
                [105.5628344, 9.4311135],
                [105.5620936, 9.4318723],
                [105.5614099, 9.4327058],
                [105.5607617, 9.4334088],
                [105.5600156, 9.4341604],
                [105.5592534, 9.4349978],
                [105.5585331, 9.4358514],
                [105.5579212, 9.4365649],
                [105.5571693, 9.437387],
                [105.5564354, 9.4381531],
                [105.5557282, 9.4388843],
                [105.5550891, 9.4396822],
                [105.5544064, 9.4404015],
                [105.5527376, 9.4422639],
                [105.5525355, 9.4424779],
                [105.5497256, 9.4453594],
                [105.5469758, 9.4484797],
                [105.545267, 9.4505738],
                [105.5450642, 9.4577929],
                [105.5445009, 9.4662407],
                [105.5443373, 9.4691721],
                [105.5458099, 9.4690583],
                [105.5460834, 9.4695134],
                [105.5462994, 9.4700855],
                [105.5460379, 9.4701431],
                [105.5458394, 9.4702118],
                [105.5452359, 9.470483],
                [105.5440061, 9.4710571],
                [105.5441093, 9.4714447],
                [105.5448094, 9.4746684],
                [105.5456007, 9.4781775],
                [105.5459225, 9.4796514],
                [105.5464134, 9.4810086],
                [105.5466387, 9.4815721],
                [105.5466655, 9.4818075],
                [105.5473736, 9.4885881],
                [105.547155, 9.4887263],
                [105.5472495, 9.4892997],
                [105.5472743, 9.4895986],
                [105.5472422, 9.4908632],
                [105.5472368, 9.4915404],
                [105.5472636, 9.4920596],
                [105.5475318, 9.4946095],
                [105.5477853, 9.4970734],
                [105.5481005, 9.4993181],
                [105.5481783, 9.500117],
                [105.5483097, 9.5016143],
                [105.548889, 9.5071194],
                [105.5493101, 9.5115027],
                [105.5499579, 9.5176868],
                [105.5500907, 9.5189307],
                [105.5502677, 9.5203089],
                [105.5503384, 9.5212223],
                [105.5504662, 9.5228722],
                [105.5508417, 9.5261681],
                [105.5511368, 9.5289752],
                [105.5516785, 9.5341275],
                [105.5529419, 9.5460742],
                [105.553304, 9.5487602],
                [105.5535561, 9.5488316],
                [105.5537546, 9.5488555],
                [105.5540228, 9.5488607],
                [105.5544439, 9.5519184],
                [105.5552606, 9.5581103],
                [105.5554363, 9.5598296],
                [105.5554336, 9.5603374],
                [105.5552781, 9.5607394],
                [105.5550098, 9.5616334],
                [105.554873, 9.5620302],
                [105.5547497, 9.562255],
                [105.5545351, 9.5625697],
                [105.5544358, 9.5628104],
                [105.5543983, 9.5630855],
                [105.554409, 9.56335],
                [105.55446, 9.5636092],
                [105.5545592, 9.5639028],
                [105.5547161, 9.5642903],
                [105.5547765, 9.5643908],
                [105.5548596, 9.5644662],
                [105.5549924, 9.5645561],
                [105.555034, 9.5646209],
                [105.5550407, 9.564691],
                [105.5550286, 9.5647743],
                [105.5548891, 9.5651644],
                [105.5548382, 9.565421],
                [105.5548114, 9.5657304],
                [105.5547765, 9.5667355],
                [105.5547872, 9.5668915],
                [105.5548114, 9.567],
                [105.554857, 9.567119],
                [105.5549884, 9.5673941],
                [105.5549964, 9.5675025],
                [105.5549803, 9.5676268],
                [105.5549133, 9.5678278],
                [105.5547523, 9.5682166],
                [105.5545914, 9.5685975],
                [105.5545217, 9.5691],
                [105.5544519, 9.5693857],
                [105.5543393, 9.5697163],
                [105.5541757, 9.5700866],
                [105.554055, 9.570232],
                [105.5538297, 9.5704992],
                [105.5536848, 9.5707531],
                [105.5534971, 9.5710916],
                [105.5532449, 9.5714857],
                [105.5530143, 9.5718428],
                [105.5528587, 9.5720464],
                [105.552738, 9.5721681],
                [105.5526093, 9.572258],
                [105.5524725, 9.5723136],
                [105.5523545, 9.57234],
                [105.5522418, 9.5723374],
                [105.5520111, 9.5723083],
                [105.5519092, 9.572303],
                [105.5518073, 9.5723215],
                [105.5517027, 9.5723744],
                [105.5514827, 9.5725437],
                [105.5513218, 9.5726812],
                [105.551075, 9.5729457],
                [105.5507854, 9.5733027],
                [105.550662, 9.5734773],
                [105.5506164, 9.5736228],
                [105.5506057, 9.5737524],
                [105.5506271, 9.5738978],
                [105.5507961, 9.5748235],
                [105.5508792, 9.5755561],
                [105.5508846, 9.5758629],
                [105.550847, 9.5763919],
                [105.5506164, 9.5773626],
                [105.5505306, 9.5776826],
                [105.5504152, 9.5780079],
                [105.5503187, 9.5781957],
                [105.5501577, 9.5784284],
                [105.5495596, 9.5792377],
                [105.5494416, 9.5794123],
                [105.5493236, 9.5796503],
                [105.5491519, 9.5800576],
                [105.5490097, 9.5803115],
                [105.5487442, 9.5806448],
                [105.5485752, 9.5808378],
                [105.5484089, 9.5811129],
                [105.548358, 9.5812531],
                [105.5483419, 9.5814012],
                [105.5483419, 9.5815678],
                [105.5483687, 9.5819698],
                [105.5483526, 9.5821893],
                [105.5482963, 9.5824353],
                [105.5480924, 9.5832552],
                [105.5480227, 9.5834853],
                [105.54791, 9.5837127],
                [105.5473012, 9.5846622],
                [105.5463061, 9.5859634],
                [105.5460081, 9.5861594],
                [105.5456956, 9.5855413],
                [105.545472, 9.58532],
                [105.5451989, 9.5851233],
                [105.5448515, 9.58505],
                [105.5443555, 9.5851001],
                [105.5438848, 9.5853963],
                [105.5436622, 9.5857662],
                [105.5434154, 9.5866042],
                [105.543045, 9.5875408],
                [105.5425997, 9.5882559],
                [105.5415595, 9.5891936],
                [105.5405687, 9.5899835],
                [105.5378179, 9.5914165],
                [105.5370996, 9.5920089],
                [105.536976, 9.5923046],
                [105.536927, 9.5927482],
                [105.5369779, 9.5934871],
                [105.5369946, 9.5942978],
                [105.5370047, 9.5947926],
                [105.53661, 9.5960741],
                [105.5361646, 9.5967153],
                [105.5357434, 9.5970362],
                [105.5350989, 9.597259],
                [105.5347019, 9.5972349],
                [105.5342056, 9.5970386],
                [105.533066, 9.5959104],
                [105.5329138, 9.5957597],
                [105.5325166, 9.5955633],
                [105.5318466, 9.5953673],
                [105.5302589, 9.5951973],
                [105.5291429, 9.5952483],
                [105.5259677, 9.5950562],
                [105.5224704, 9.5949876],
                [105.5216765, 9.5948163],
                [105.5213537, 9.5946691],
                [105.5212294, 9.5944722],
                [105.5212042, 9.5941765],
                [105.5213755, 9.5927229],
                [105.5216718, 9.5918357],
                [105.5218926, 9.590382],
                [105.521718, 9.5896679],
                [105.5211463, 9.5888805],
                [105.5206002, 9.5885118],
                [105.5198805, 9.5883405],
                [105.5191365, 9.5883662],
                [105.5172037, 9.5895023],
                [105.5161874, 9.5899472],
                [105.5156418, 9.590022],
                [105.5147486, 9.5898015],
                [105.5143514, 9.5896052],
                [105.5140532, 9.5892114],
                [105.5138537, 9.5884974],
                [105.5138748, 9.5861325],
                [105.5138491, 9.5854921],
                [105.5133766, 9.5847045],
                [105.5130788, 9.5844833],
                [105.512855, 9.5842865],
                [105.5124828, 9.5841393],
                [105.5119618, 9.5840662],
                [105.5113666, 9.5840671],
                [105.5109967, 9.584103],
                [105.5104988, 9.5842407],
                [105.5065571, 9.585503],
                [105.5048217, 9.5859982],
                [105.5044252, 9.5862451],
                [105.5042273, 9.5866149],
                [105.5041781, 9.5868614],
                [105.5042286, 9.5874239],
                [105.5050992, 9.5890964],
                [105.5052985, 9.5897362],
                [105.5053242, 9.5902777],
                [105.5050522, 9.5907704],
                [105.504755, 9.5910909],
                [105.5041848, 9.5913133],
                [105.5033416, 9.5913146],
                [105.5003873, 9.5903094],
                [105.4978077, 9.5899803],
                [105.4959453, 9.5902133],
                [105.4944447, 9.5907148],
                [105.493378, 9.590876],
                [105.4928175, 9.590679],
                [105.4925102, 9.5902311],
                [105.4924739, 9.5896757],
                [105.4927814, 9.5886723],
                [105.4933237, 9.5874362],
                [105.4933961, 9.5867913],
                [105.4931249, 9.5860567],
                [105.4916425, 9.5840321],
                [105.4900877, 9.5826167],
                [105.4891476, 9.5821509],
                [105.4883667, 9.5821937],
                [105.4881997, 9.5822985],
                [105.4880373, 9.5824103],
                [105.4878801, 9.5825288],
                [105.4877281, 9.582654],
                [105.4875817, 9.5827855],
                [105.4874408, 9.5829236],
                [105.487306, 9.5830676],
                [105.4871776, 9.5832171],
                [105.487056, 9.5833721],
                [105.4869412, 9.583532],
                [105.4868336, 9.5836967],
                [105.4867333, 9.5838659],
                [105.486634, 9.5839939],
                [105.4865271, 9.5841158],
                [105.486413, 9.5842311],
                [105.4862919, 9.5843393],
                [105.4861645, 9.5844402],
                [105.4860311, 9.5845333],
                [105.4858923, 9.5846183],
                [105.4857485, 9.5846949],
                [105.4856019, 9.5847621],
                [105.4854515, 9.5848206],
                [105.4852978, 9.58487],
                [105.4851414, 9.5849104],
                [105.4849828, 9.5849414],
                [105.4848226, 9.5849631],
                [105.4846614, 9.5849753],
                [105.4844997, 9.584978],
                [105.4843382, 9.5849711],
                [105.4839742, 9.5849452],
                [105.4835349, 9.5849192],
                [105.4827311, 9.584875],
                [105.4823059, 9.5848526],
                [105.4819763, 9.5848291],
                [105.4816273, 9.5848103],
                [105.4813365, 9.5847998],
                [105.4810372, 9.5847971],
                [105.4807851, 9.5847971],
                [105.4805946, 9.584795],
                [105.480245, 9.5848005],
                [105.4798953, 9.5848001],
                [105.4795456, 9.5847938],
                [105.4791961, 9.5847816],
                [105.4788017, 9.5847608],
                [105.4784077, 9.5847324],
                [105.4780143, 9.5846966],
                [105.4766183, 9.5844256],
                [105.4755153, 9.5844793],
                [105.4752442, 9.5850525],
                [105.4751537, 9.5858229],
                [105.475479, 9.5867367],
                [105.476636, 9.5880804],
                [105.4776846, 9.5893168],
                [105.4779015, 9.5901409],
                [105.4775218, 9.5910724],
                [105.4768889, 9.5919503],
                [105.4759668, 9.5923801],
                [105.4743033, 9.5935266],
                [105.47179, 9.596178],
                [105.4707594, 9.5970021],
                [105.4698192, 9.5970199],
                [105.4692044, 9.5964824],
                [105.4689153, 9.5958374],
                [105.4690418, 9.5943325],
                [105.4690419, 9.593347],
                [105.4689879, 9.5919676],
                [105.4680659, 9.590785],
                [105.4666016, 9.5900503],
                [105.4649743, 9.5894948],
                [105.463673, 9.5882499],
                [105.4634197, 9.5880077],
                [105.4624255, 9.5868429],
                [105.4617021, 9.586628],
                [105.460979, 9.5868965],
                [105.4602738, 9.5879355],
                [105.4593153, 9.5894404],
                [105.4580316, 9.590121],
                [105.4567479, 9.5903359],
                [105.4556813, 9.5901924],
                [105.4543976, 9.5895294],
                [105.451686, 9.5875762],
                [105.4501853, 9.5868772],
                [105.4493175, 9.5869667],
                [105.4487027, 9.5874683],
                [105.4482868, 9.588364],
                [105.4482685, 9.5893852],
                [105.4484853, 9.5904781],
                [105.4486297, 9.5923414],
                [105.4482497, 9.5943837],
                [105.4473635, 9.5962648],
                [105.4464413, 9.5968738],
                [105.4457542, 9.5968737],
                [105.4451937, 9.5967661],
                [105.4441995, 9.5959955],
                [105.4427893, 9.5949562],
                [105.4417407, 9.5945977],
                [105.4405836, 9.5948124],
                [105.4387572, 9.5963529],
                [105.4367321, 9.597535],
                [105.4344357, 9.5983228],
                [105.4326455, 9.5992721],
                [105.431637, 9.5999962],
                [105.4313481, 9.6002036],
                [105.4311988, 9.6003108],
                [105.430331, 9.6006869],
                [105.4297885, 9.600651],
                [105.4293727, 9.600203],
                [105.4291469, 9.5994416],
                [105.4295812, 9.5977217],
                [105.4295815, 9.5960734],
                [105.4290753, 9.5955],
                [105.4281354, 9.5948549],
                [105.4256765, 9.5950693],
                [105.4230004, 9.5954987],
                [105.4219157, 9.5952118],
                [105.4203249, 9.5937066],
                [105.4187341, 9.5927029],
                [105.4178073, 9.5926371],
                [105.4177216, 9.592631],
                [105.4168536, 9.5935624],
                [105.4164917, 9.5946373],
                [105.416853, 9.5957123],
                [105.4180908, 9.5985344],
                [105.4177831, 9.6004154],
                [105.4167161, 9.6015797],
                [105.4155227, 9.601884],
                [105.4153737, 9.6018194],
                [105.4146548, 9.6015075],
                [105.4135342, 9.6001636],
                [105.4122147, 9.5986583],
                [105.4107143, 9.5979771],
                [105.4095753, 9.5976007],
                [105.4093428, 9.59763],
                [105.4067365, 9.5979582],
                [105.4062343, 9.5979663],
                [105.4034459, 9.598011],
                [105.4006218, 9.5984732],
                [105.3994132, 9.5984854],
                [105.3987992, 9.5982068],
                [105.3984919, 9.5977229],
                [105.3985948, 9.5968757],
                [105.3991434, 9.5957524],
                [105.3995777, 9.5945343],
                [105.3995417, 9.5938535],
                [105.3991622, 9.5932801],
                [105.397933, 9.5922406],
                [105.3971994, 9.5916813],
                [105.3969555, 9.5914479],
                [105.3967488, 9.5911762],
                [105.3965986, 9.5908668],
                [105.3965037, 9.5905459],
                [105.3963357, 9.5897057],
                [105.3962224, 9.5892434],
                [105.3960729, 9.588915],
                [105.3958717, 9.5886029],
                [105.3956093, 9.588326],
                [105.3953192, 9.5881533],
                [105.3949463, 9.5880819],
                [105.3944904, 9.5880766],
                [105.3940824, 9.5881812],
                [105.3937474, 9.5883569],
                [105.3933195, 9.5887033],
                [105.3922105, 9.5896793],
                [105.3917082, 9.5900764],
                [105.3912342, 9.5902982],
                [105.3908721, 9.5904145],
                [105.3905351, 9.5904733],
                [105.3901345, 9.5904833],
                [105.3896999, 9.5903934],
                [105.3892405, 9.5902139],
                [105.3887504, 9.5898776],
                [105.3883092, 9.5894716],
                [105.3872189, 9.5881691],
                [105.3861827, 9.5872921],
                [105.3854835, 9.5868283],
                [105.3846949, 9.5864474],
                [105.3838186, 9.5861325],
                [105.3824043, 9.5857915],
                [105.3816265, 9.5856751],
                [105.3809465, 9.5856171],
                [105.3801995, 9.5856064],
                [105.3795344, 9.585691],
                [105.3788799, 9.5858656],
                [105.3782977, 9.5861884],
                [105.3778285, 9.5865691],
                [105.377512, 9.5870028],
                [105.3772783, 9.5875678],
                [105.3771472, 9.5881877],
                [105.3771422, 9.5889194],
                [105.3772321, 9.5903168],
                [105.3772652, 9.5910916],
                [105.3772062, 9.5916046],
                [105.3771096, 9.5919908],
                [105.3769663, 9.5923953],
                [105.3767449, 9.5927974],
                [105.376423, 9.5932073],
                [105.3756759, 9.5940828],
                [105.3741905, 9.5957396],
                [105.3736335, 9.5962858],
                [105.3729769, 9.5967593],
                [105.3722868, 9.5968916],
                [105.3717889, 9.5968581],
                [105.3713771, 9.5966883],
                [105.3710989, 9.5964517],
                [105.3708207, 9.5960971],
                [105.3706817, 9.5955652],
                [105.3706025, 9.594797],
                [105.3706624, 9.5941272],
                [105.3709807, 9.5933591],
                [105.3714978, 9.5924532],
                [105.3719157, 9.5913896],
                [105.3720353, 9.5907987],
                [105.3719364, 9.5896562],
                [105.3717179, 9.5890062],
                [105.371241, 9.588415],
                [105.370764, 9.5880405],
                [105.370128, 9.5877251],
                [105.369482, 9.5877008],
                [105.3688335, 9.5877573],
                [105.368255, 9.5879747],
                [105.3678512, 9.5883115],
                [105.3675675, 9.5886154],
                [105.3671751, 9.5892341],
                [105.3668483, 9.589934],
                [105.3664776, 9.5905526],
                [105.3660304, 9.5910954],
                [105.3655722, 9.5915189],
                [105.3651466, 9.5918231],
                [105.3646992, 9.5920948],
                [105.364066, 9.5923448],
                [105.3633234, 9.5924596],
                [105.3626356, 9.5925795],
                [105.3621223, 9.5926236],
                [105.3617526, 9.5926373],
                [105.3594089, 9.5927906],
                [105.3577027, 9.5930437],
                [105.3568042, 9.593177],
                [105.3558651, 9.5933135],
                [105.3551008, 9.593477],
                [105.3542054, 9.5936297],
                [105.35331, 9.5937717],
                [105.3525019, 9.5938756],
                [105.3517047, 9.5939198],
                [105.3508419, 9.5939423],
                [105.3501647, 9.5938781],
                [105.3494656, 9.5937377],
                [105.3489194, 9.593554],
                [105.3484603, 9.5932778],
                [105.3479685, 9.5929637],
                [105.3473238, 9.5925957],
                [105.3467776, 9.5923902],
                [105.3463297, 9.592358],
                [105.3460349, 9.5923909],
                [105.3457074, 9.5925105],
                [105.3453035, 9.5927821],
                [105.345031, 9.5931621],
                [105.3448458, 9.593634],
                [105.3448353, 9.5940462],
                [105.344945, 9.5945233],
                [105.3451202, 9.5950111],
                [105.3455578, 9.5956669],
                [105.3459732, 9.5960678],
                [105.3463777, 9.5964037],
                [105.3467384, 9.5966961],
                [105.3472413, 9.597227],
                [105.3477553, 9.5978612],
                [105.3479851, 9.5982622],
                [105.3480838, 9.5986851],
                [105.3480842, 9.5991189],
                [105.3480302, 9.599607],
                [105.3478559, 9.6000791],
                [105.347616, 9.6004155],
                [105.3471686, 9.600774],
                [105.3466883, 9.6010673],
                [105.3461425, 9.6012848],
                [105.3453127, 9.6015134],
                [105.3445812, 9.6018016],
                [105.3438826, 9.6022036],
                [105.3433589, 9.6026705],
                [105.3429117, 9.603235],
                [105.342432, 9.6040218],
                [105.3421159, 9.6046512],
                [105.3418218, 9.6053456],
                [105.3415712, 9.605975],
                [105.3414843, 9.6064252],
                [105.3414737, 9.6068049],
                [105.3414741, 9.6072496],
                [105.3415293, 9.6077267],
                [105.3417048, 9.6084262],
                [105.3419893, 9.6090115],
                [105.3422629, 9.6094451],
                [105.3424598, 9.6098245],
                [105.3426896, 9.610269],
                [105.3427445, 9.610513],
                [105.3427776, 9.6108275],
                [105.342767, 9.6111312],
                [105.3426909, 9.6114783],
                [105.3425493, 9.6118473],
                [105.3423093, 9.6121404],
                [105.3419929, 9.6123685],
                [105.3414578, 9.6125317],
                [105.3410646, 9.6125483],
                [105.3405184, 9.6124622],
                [105.3400376, 9.6122241],
                [105.339666, 9.6118991],
                [105.3393706, 9.6114438],
                [105.3392063, 9.6109884],
                [105.3391512, 9.6105708],
                [105.3391506, 9.6099417],
                [105.3391718, 9.6093018],
                [105.3392036, 9.6083528],
                [105.3392138, 9.6076694],
                [105.3391479, 9.6072898],
                [105.3389727, 9.6068561],
                [105.3385023, 9.6061679],
                [105.3380978, 9.6057345],
                [105.3374967, 9.6053012],
                [105.3367974, 9.604944],
                [105.3360871, 9.6047061],
                [105.3352678, 9.604555],
                [105.3348528, 9.6045175],
                [105.3340992, 9.6045291],
                [105.333422, 9.6045298],
                [105.3328213, 9.6044978],
                [105.3321877, 9.6043357],
                [105.3317943, 9.6041083],
                [105.3314882, 9.6037941],
                [105.3312583, 9.6033279],
                [105.3310503, 9.6027696],
                [105.3309734, 9.6023249],
                [105.3309292, 9.6018369],
                [105.3308411, 9.6011537],
                [105.3306657, 9.6005193],
                [105.330447, 9.6001183],
                [105.3301408, 9.5998148],
                [105.3297911, 9.5995982],
                [105.3293649, 9.59936],
                [105.3289935, 9.5992736],
                [105.3285348, 9.5993066],
                [105.3279562, 9.5994482],
                [105.3272357, 9.5998339],
                [105.3267664, 9.6001815],
                [105.32633, 9.6006808],
                [105.3260576, 9.6012668],
                [105.3258834, 9.6018255],
                [105.3256765, 9.6024657],
                [105.3254039, 9.6029214],
                [105.3250766, 9.6032254],
                [105.3246509, 9.6035296],
                [105.3240942, 9.6038176],
                [105.3236356, 9.6039698],
                [105.3229585, 9.6040355],
                [105.3222159, 9.6040579],
                [105.3214296, 9.6041129],
                [105.320818, 9.6041731],
                [105.3201957, 9.6043364],
                [105.3194532, 9.6046082],
                [105.3188309, 9.6048475],
                [105.3181541, 9.6052061],
                [105.3171171, 9.6058307],
                [105.316222, 9.6063088],
                [105.3154797, 9.6066783],
                [105.31489, 9.6068416],
                [105.3142566, 9.6068964],
                [105.313503, 9.6069134],
                [105.312662, 9.6068599],
                [105.3117008, 9.6068283],
                [105.3110455, 9.6068288],
                [105.310434, 9.6069054],
                [105.3099099, 9.6070197],
                [105.3092548, 9.6072589],
                [105.3086653, 9.6075848],
                [105.3081195, 9.6079324],
                [105.3077158, 9.6083123],
                [105.3072903, 9.6088225],
                [105.3070722, 9.6092186],
                [105.3068324, 9.6097178],
                [105.3066581, 9.6101844],
                [105.3064186, 9.6109873],
                [105.3061899, 9.6117466],
                [105.3059831, 9.612544],
                [105.3057758, 9.6136141],
                [105.305657, 9.6141876],
                [105.305483, 9.6150012],
                [105.3053088, 9.6156467],
                [105.3051238, 9.6162868],
                [105.3049603, 9.6167099],
                [105.3047532, 9.6171115],
                [105.3045023, 9.6174697],
                [105.3041423, 9.6179526],
                [105.3037387, 9.6186038],
                [105.3034772, 9.6191896],
                [105.3032374, 9.6197756],
                [105.3030632, 9.6203235],
                [105.3029654, 9.6208442],
                [105.3029439, 9.6213106],
                [105.3029554, 9.6219072],
                [105.3029451, 9.62252],
                [105.303044, 9.6232249],
                [105.3031974, 9.6237996],
                [105.3034931, 9.624526],
                [105.3036901, 9.6250519],
                [105.3038763, 9.6256157],
                [105.3039204, 9.6260713],
                [105.3039207, 9.6264183],
                [105.3038993, 9.6268956],
                [105.3038561, 9.6273349],
                [105.303769, 9.6276604],
                [105.3036272, 9.6280185],
                [105.3034746, 9.6283006],
                [105.3031473, 9.6286588],
                [105.3023941, 9.6291476],
                [105.3017389, 9.629365],
                [105.3014003, 9.6293763],
                [105.3009852, 9.6293332],
                [105.3005154, 9.6292306],
                [105.2996962, 9.6291338],
                [105.2989316, 9.6291344],
                [105.2983091, 9.6291892],
                [105.2978722, 9.6292763],
                [105.2974574, 9.6295045],
                [105.2970209, 9.6298953],
                [105.2967372, 9.6302969],
                [105.296432, 9.6309642],
                [105.2962468, 9.631561],
                [105.2961165, 9.6323529],
                [105.2958771, 9.6333021],
                [105.2957358, 9.6340832],
                [105.2953656, 9.6353905],
                [105.2952241, 9.6360414],
                [105.2951578, 9.6363832],
                [105.2949643, 9.6368844],
                [105.2947073, 9.6372594],
                [105.2944839, 9.6373865],
                [105.2942328, 9.6374952],
                [105.2939052, 9.6375498],
                [105.2936211, 9.6375338],
                [105.2931513, 9.6373932],
                [105.2927798, 9.6372308],
                [105.2924519, 9.6369274],
                [105.2921675, 9.6365588],
                [105.2919815, 9.6361469],
                [105.2917624, 9.6354475],
                [105.2916307, 9.6348619],
                [105.2915209, 9.6341461],
                [105.2913017, 9.6332406],
                [105.291148, 9.632449],
                [105.2909507, 9.6316248],
                [105.2908083, 9.6311314],
                [105.2906221, 9.6305676],
                [105.2903048, 9.6299279],
                [105.2899548, 9.6294509],
                [105.2897034, 9.6291475],
                [105.2893098, 9.6288279],
                [105.2888726, 9.6285137],
                [105.2883918, 9.6281562],
                [105.2879107, 9.6277337],
                [105.2875937, 9.6273217],
                [105.2872109, 9.62672],
                [105.2868608, 9.6261347],
                [105.28662, 9.6255275],
                [105.2864775, 9.6249636],
                [105.2864224, 9.6243835],
                [105.2864002, 9.6238845],
                [105.2863996, 9.6232337],
                [105.2864428, 9.6226479],
                [105.2865624, 9.6219808],
                [105.2866493, 9.6215143],
                [105.2868563, 9.6209718],
                [105.2872705, 9.6199357],
                [105.2876412, 9.6191653],
                [105.2879463, 9.6184383],
                [105.2883278, 9.6174456],
                [105.2885783, 9.6166753],
                [105.2887851, 9.6158074],
                [105.2889482, 9.6150316],
                [105.289046, 9.6144459],
                [105.2890673, 9.6138385],
                [105.289023, 9.6132094],
                [105.2889242, 9.6125534],
                [105.2888146, 9.6121087],
                [105.2885408, 9.6113172],
                [105.2882232, 9.6103034],
                [105.288026, 9.6095442],
                [105.2879488, 9.608785],
                [105.2879045, 9.6081071],
                [105.2879258, 9.6074022],
                [105.2879582, 9.6069574],
                [105.2880451, 9.6065017],
                [105.2881209, 9.6058022],
                [105.2880986, 9.6052273],
                [105.287978, 9.6046308],
                [105.2877371, 9.6040453],
                [105.2874637, 9.6035412],
                [105.2870918, 9.6029341],
                [105.2868301, 9.6025018],
                [105.2867325, 9.6023405],
                [105.2865687, 9.6019968],
                [105.2863762, 9.6015162],
                [105.2862382, 9.6009879],
                [105.286216, 9.600565],
                [105.2862921, 9.6001202],
                [105.2864119, 9.5997839],
                [105.2866956, 9.5994148],
                [105.287143, 9.5989426],
                [105.2876013, 9.5985409],
                [105.2880161, 9.5982369],
                [105.2884417, 9.5979544],
                [105.2889547, 9.5975744],
                [105.2893148, 9.5972379],
                [105.2896094, 9.5968851],
                [105.2900022, 9.5963967],
                [105.2903402, 9.5959083],
                [105.2907111, 9.5952464],
                [105.291049, 9.5945682],
                [105.2912125, 9.5941993],
                [105.2915178, 9.5935807],
                [105.2920956, 9.5924902],
                [105.2924118, 9.5919042],
                [105.2927498, 9.5912315],
                [105.2930877, 9.5904448],
                [105.2932838, 9.5899457],
                [105.2934145, 9.5894792],
                [105.2934796, 9.5890995],
                [105.2935338, 9.588644],
                [105.2936207, 9.5880093],
                [105.293773, 9.5873801],
                [105.2939689, 9.5866858],
                [105.2942304, 9.5860294],
                [105.2946448, 9.5853023],
                [105.295092, 9.5846512],
                [105.2954957, 9.584141],
                [105.2960522, 9.583647],
                [105.2965105, 9.5832561],
                [105.2970671, 9.5829085],
                [105.2977877, 9.5825825],
                [105.2985191, 9.5822999],
                [105.2993707, 9.5820009],
                [105.2999056, 9.5817183],
                [105.3004949, 9.5813165],
                [105.3008987, 9.5809908],
                [105.3015317, 9.5804479],
                [105.3020227, 9.5799432],
                [105.302601, 9.5794762],
                [105.3031467, 9.5790093],
                [105.303594, 9.5785968],
                [105.3040305, 9.5782059],
                [105.3045653, 9.5778203],
                [105.3049581, 9.5774838],
                [105.3053838, 9.5771689],
                [105.3063114, 9.5765823],
                [105.3080797, 9.5756208],
                [105.3088874, 9.5751211],
                [105.3098369, 9.5745128],
                [105.3107539, 9.5739805],
                [105.3116051, 9.5734104],
                [105.3120962, 9.5730519],
                [105.3124454, 9.5726937],
                [105.3126853, 9.5723464],
                [105.312816, 9.5719774],
                [105.3128482, 9.5714188],
                [105.3128585, 9.5708658],
                [105.3128145, 9.5704644],
                [105.3127378, 9.5701283],
                [105.3125517, 9.5696729],
                [105.3123766, 9.5693422],
                [105.3120814, 9.5689954],
                [105.3117644, 9.5686486],
                [105.3112397, 9.5680851],
                [105.3107807, 9.5676516],
                [105.3102451, 9.5672021],
                [105.3098734, 9.5667143],
                [105.3095125, 9.5662374],
                [105.309097, 9.5656629],
                [105.3086375, 9.5647902],
                [105.3083967, 9.5641722],
                [105.3081996, 9.5636083],
                [105.3080243, 9.5630445],
                [105.3078927, 9.5622908],
                [105.3077172, 9.5614666],
                [105.3075961, 9.5605557],
                [105.3075303, 9.5602412],
                [105.3074317, 9.5598453],
                [105.3073765, 9.559238],
                [105.3073322, 9.5585439],
                [105.3073238, 9.5579149],
                [105.3073205, 9.5576707],
                [105.3073507, 9.5573962],
                [105.3073914, 9.5570803],
                [105.3074795, 9.5567826],
                [105.3075871, 9.5564898],
                [105.3077999, 9.5561465],
                [105.3080106, 9.5559097],
                [105.3082898, 9.5556973],
                [105.3086508, 9.5555219],
                [105.3090814, 9.5554128],
                [105.3094359, 9.555402],
                [105.3098123, 9.5554394],
                [105.3100813, 9.5555478],
                [105.3104201, 9.555721],
                [105.3107369, 9.5558943],
                [105.3111958, 9.5561759],
                [105.3116765, 9.5564574],
                [105.3123212, 9.5568473],
                [105.3133265, 9.5575677],
                [105.313709, 9.5578601],
                [105.3141571, 9.558261],
                [105.3147036, 9.5587487],
                [105.3154467, 9.5592469],
                [105.3162224, 9.5596637],
                [105.3168779, 9.5599993],
                [105.3174132, 9.5602049],
                [105.3179703, 9.5603454],
                [105.3186912, 9.5604531],
                [105.3192918, 9.5604852],
                [105.3196475, 9.5604734],
                [105.3212118, 9.5603306],
                [105.321312, 9.5603151],
                [105.3219451, 9.560141],
                [105.3225674, 9.5599018],
                [105.3230803, 9.5595759],
                [105.3235386, 9.5592012],
                [105.3239202, 9.5586586],
                [105.3242037, 9.5580726],
                [105.3243669, 9.5575952],
                [105.3244103, 9.5571939],
                [105.3244098, 9.5568143],
                [105.3243657, 9.5563099],
                [105.3243107, 9.5558869],
                [105.3242009, 9.5552688],
                [105.3240254, 9.5545261],
                [105.3238389, 9.553626],
                [105.3236636, 9.5530079],
                [105.3234775, 9.5525472],
                [105.323182, 9.5518098],
                [105.3229301, 9.5511268],
                [105.3228314, 9.5507147],
                [105.3227107, 9.550026],
                [105.3226446, 9.5495272],
                [105.3225566, 9.5487897],
                [105.3224789, 9.5475804],
                [105.3224673, 9.546832],
                [105.3225102, 9.5460836],
                [105.3226185, 9.5451344],
                [105.3227817, 9.5444618],
                [105.3230322, 9.5438],
                [105.3234136, 9.5429699],
                [105.3238497, 9.5422535],
                [105.3241768, 9.5417434],
                [105.3246348, 9.5411899],
                [105.3251585, 9.540685],
                [105.325595, 9.5403158],
                [105.3260314, 9.5399684],
                [105.3264242, 9.5396751],
                [105.3265987, 9.5395014],
                [105.3266424, 9.5393929],
                [105.3266531, 9.5391868],
                [105.3266198, 9.5386608],
                [105.3265207, 9.5378582],
                [105.3262793, 9.5366708],
                [105.3261805, 9.5361285],
                [105.3261032, 9.535326],
                [105.3259493, 9.5341818],
                [105.3259598, 9.5337046],
                [105.3260138, 9.5331731],
                [105.3261334, 9.5326522],
                [105.326362, 9.5318983],
                [105.3267435, 9.531182],
                [105.3271904, 9.5304766],
                [105.3276485, 9.5299013],
                [105.3280849, 9.5295592],
                [105.3286196, 9.5291249],
                [105.3289906, 9.5288317],
                [105.3293069, 9.5285602],
                [105.3295905, 9.5282128],
                [105.3298196, 9.5279848],
                [105.3298958, 9.5278005],
                [105.3298912, 9.5277033],
                [105.3298864, 9.527601],
                [105.3298846, 9.5275617],
                [105.329797, 9.5272745],
                [105.3296002, 9.5269276],
                [105.3292771, 9.5264328],
                [105.3292392, 9.5263748],
                [105.3288566, 9.5259629],
                [105.3284195, 9.5255837],
                [105.328178, 9.5254109],
                [105.3280262, 9.5253022],
                [105.3276673, 9.5251394],
                [105.3275127, 9.5250693],
                [105.3270563, 9.5249313],
                [105.3267263, 9.5248315],
                [105.326465, 9.5247736],
                [105.3260928, 9.5246911],
                [105.3258894, 9.5246726],
                [105.3255032, 9.5246375],
                [105.3252566, 9.5246489],
                [105.3250228, 9.5246597],
                [105.3247439, 9.5247153],
                [105.3244769, 9.5247686],
                [105.3238765, 9.5249482],
                [105.3233222, 9.525167],
                [105.323298, 9.5251765],
                [105.3227742, 9.525459],
                [105.3223158, 9.5257306],
                [105.322118, 9.5258505],
                [105.3218139, 9.5260347],
                [105.3217322, 9.5260609],
                [105.3215082, 9.5261326],
                [105.3213674, 9.5261206],
                [105.321257, 9.5261112],
                [105.3209512, 9.5259813],
                [105.3206779, 9.525743],
                [105.32035, 9.5253691],
                [105.3200548, 9.5249355],
                [105.3199562, 9.524556],
                [105.3199119, 9.5239704],
                [105.3199658, 9.5231731],
                [105.3199789, 9.5231018],
                [105.3200418, 9.5227608],
                [105.3202375, 9.5219689],
                [105.3204987, 9.5210846],
                [105.3207275, 9.5205204],
                [105.3209781, 9.5199669],
                [105.3213597, 9.5193917],
                [105.3217632, 9.5188979],
                [105.3222432, 9.5184093],
                [105.3226142, 9.5180836],
                [105.3229743, 9.5178555],
                [105.3233125, 9.5176599],
                [105.3235089, 9.5174428],
                [105.323596, 9.5172149],
                [105.3235957, 9.5169763],
                [105.3235411, 9.5167595],
                [105.3235238, 9.5167324],
                [105.3233442, 9.5164505],
                [105.3228411, 9.5157026],
                [105.3223164, 9.5149764],
                [105.3216276, 9.5141148],
                [105.3209827, 9.513367],
                [105.3205783, 9.5129552],
                [105.3203399, 9.5127553],
                [105.3202942, 9.5127169],
                [105.31942, 9.5119964],
                [105.3187863, 9.5115631],
                [105.3184039, 9.5113682],
                [105.3180981, 9.5113034],
                [105.3177159, 9.5112496],
                [105.31729, 9.5111849],
                [105.3168532, 9.5110551],
                [105.3165878, 9.5109485],
                [105.316449, 9.5108928],
                [105.3161759, 9.510687],
                [105.31599, 9.5104811],
                [105.3158368, 9.5101341],
                [105.315829, 9.5100963],
                [105.315771, 9.5098143],
                [105.3156613, 9.509207],
                [105.315606, 9.5085128],
                [105.3156381, 9.5078457],
                [105.315747, 9.5074443],
                [105.3159213, 9.5070754],
                [105.3162375, 9.5065978],
                [105.316641, 9.5061419],
                [105.3170119, 9.5056914],
                [105.3171317, 9.5054636],
                [105.3171617, 9.505254],
                [105.3171643, 9.5052357],
                [105.3170984, 9.5048887],
                [105.3170905, 9.5048679],
                [105.3168795, 9.5043141],
                [105.3166169, 9.5037068],
                [105.3162123, 9.5030239],
                [105.3159498, 9.5026446],
                [105.3156657, 9.5023953],
                [105.3153708, 9.5022004],
                [105.315054, 9.5021357],
                [105.3148575, 9.5021467],
                [105.3146393, 9.5022119],
                [105.3143991, 9.5023423],
                [105.3138426, 9.5026737],
                [105.3132425, 9.5030864],
                [105.3124788, 9.5037487],
                [105.3118679, 9.5043783],
                [105.3114971, 9.5047638],
                [105.3112681, 9.5051327],
                [105.3110174, 9.505621],
                [105.3108868, 9.5059899],
                [105.3108215, 9.5062937],
                [105.3107127, 9.5065866],
                [105.3105927, 9.5067711],
                [105.3104073, 9.5069666],
                [105.310189, 9.5070915],
                [105.3098942, 9.5071134],
                [105.3096759, 9.5071136],
                [105.3091625, 9.5069514],
                [105.3085509, 9.5066808],
                [105.3080046, 9.5064101],
                [105.3073583, 9.5059989],
                [105.3072727, 9.5059444],
                [105.3068028, 9.5055706],
                [105.3065078, 9.505289],
                [105.3063437, 9.5050721],
                [105.306289, 9.5048227],
                [105.3062559, 9.5044648],
                [105.3061681, 9.504031],
                [105.3060587, 9.5037491],
                [105.3058838, 9.5035107],
                [105.3056434, 9.5033482],
                [105.3053923, 9.5033376],
                [105.3050211, 9.5034138],
                [105.3044754, 9.5036637],
                [105.3037988, 9.5040982],
                [105.3033298, 9.5044837],
                [105.3030353, 9.5047877],
                [105.3028062, 9.5050481],
                [105.3026317, 9.5052978],
                [105.3024135, 9.505504],
                [105.3018353, 9.5060144],
                [105.3014315, 9.5063401],
                [105.3010605, 9.5065573],
                [105.3008749, 9.5066009],
                [105.3006675, 9.5065685],
                [105.3004476, 9.5064731],
                [105.3004053, 9.5064548],
                [105.3000011, 9.5061516],
                [105.2996294, 9.5057289],
                [105.2993343, 9.505317],
                [105.2991156, 9.5049484],
                [105.298864, 9.5044226],
                [105.2986669, 9.5038479],
                [105.2985571, 9.5031321],
                [105.29848, 9.5024488],
                [105.2984359, 9.5019446],
                [105.298381, 9.5016083],
                [105.2982824, 9.5012396],
                [105.2982275, 9.5009143],
                [105.2982271, 9.5004479],
                [105.2982377, 9.5000899],
                [105.2983845, 9.4997456],
                [105.2984133, 9.4995153],
                [105.2984928, 9.4992345],
                [105.2985846, 9.4989603],
                [105.2986435, 9.4987509],
                [105.2986687, 9.4984819],
                [105.2986603, 9.4981246],
                [105.2986286, 9.4977266],
                [105.2985468, 9.4971293],
                [105.298494, 9.4967341],
                [105.2984668, 9.4959054],
                [105.2984586, 9.4954827],
                [105.2984073, 9.4947926],
                [105.2983649, 9.494525],
                [105.298273, 9.4942763],
                [105.2981325, 9.4939501],
                [105.2979751, 9.4936812],
                [105.2977378, 9.493471],
                [105.2974954, 9.4932681],
                [105.29729, 9.4930678],
                [105.2971387, 9.4928753],
                [105.2969124, 9.4924712],
                [105.2967674, 9.4920811],
                [105.2966817, 9.4918395],
                [105.2966384, 9.4917173],
                [105.2964561, 9.4913884],
                [105.2962333, 9.4911018],
                [105.2960327, 9.490907],
                [105.2959169, 9.4908304],
                [105.2957889, 9.4907457],
                [105.2955284, 9.4906747],
                [105.2952829, 9.4906412],
                [105.295017, 9.4906414],
                [105.2947942, 9.490563],
                [105.2944842, 9.4905073],
                [105.2943742, 9.4904234],
                [105.2941888, 9.4902526],
                [105.2940792, 9.4900289],
                [105.2940594, 9.4897774],
                [105.2940555, 9.4895474],
                [105.2940552, 9.4895291],
                [105.2941507, 9.4891231],
                [105.2942495, 9.4887655],
                [105.2944142, 9.4885551],
                [105.2945777, 9.4883236],
                [105.2946319, 9.4882495],
                [105.2947455, 9.4880942],
                [105.2948101, 9.487886],
                [105.2947926, 9.4876109],
                [105.2947172, 9.4872498],
                [105.2946907, 9.486901],
                [105.2947118, 9.4864672],
                [105.2946562, 9.48611],
                [105.2946273, 9.4858945],
                [105.2946199, 9.4858389],
                [105.2944344, 9.4854325],
                [105.2943494, 9.4850801],
                [105.2943036, 9.4846137],
                [105.2943033, 9.4842665],
                [105.2943235, 9.4840508],
                [105.2943528, 9.4839654],
                [105.2944311, 9.4838917],
                [105.294456, 9.4838838],
                [105.2945694, 9.4838476],
                [105.2947796, 9.4838229],
                [105.2948363, 9.4838162],
                [105.2950774, 9.4837201],
                [105.2951571, 9.4836883],
                [105.2953447, 9.4834511],
                [105.2955261, 9.4831845],
                [105.2956945, 9.4828791],
                [105.2958508, 9.4824353],
                [105.2959442, 9.4821004],
                [105.2959462, 9.4817328],
                [105.2958669, 9.4814923],
                [105.2956649, 9.4811303],
                [105.2952965, 9.4805747],
                [105.2949898, 9.4801869],
                [105.294937, 9.480121],
                [105.294701, 9.4798141],
                [105.2944789, 9.4795255],
                [105.2942131, 9.4792431],
                [105.2939419, 9.4789754],
                [105.2937931, 9.4787346],
                [105.2936211, 9.478511],
                [105.2934087, 9.4782945],
                [105.2932753, 9.4781291],
                [105.2931601, 9.4779116],
                [105.2930732, 9.4776435],
                [105.2929523, 9.4770194],
                [105.2929523, 9.4766943],
                [105.292976, 9.4762756],
                [105.2930003, 9.4760099],
                [105.2929848, 9.4757042],
                [105.2929372, 9.4753415],
                [105.2928421, 9.4745931],
                [105.2927514, 9.4737369],
                [105.2926923, 9.4730547],
                [105.2926469, 9.4725982],
                [105.2926106, 9.4720397],
                [105.292604, 9.4716352],
                [105.2925516, 9.4712731],
                [105.2924567, 9.4708487],
                [105.2923453, 9.4705033],
                [105.2921435, 9.4699138],
                [105.2918978, 9.4693009],
                [105.2916492, 9.4687062],
                [105.2915392, 9.4681986],
                [105.2914365, 9.4676327],
                [105.2913921, 9.4669654],
                [105.2914208, 9.4663415],
                [105.2915442, 9.4656159],
                [105.2915875, 9.4650211],
                [105.2915724, 9.4644118],
                [105.2914331, 9.4636575],
                [105.2913156, 9.4629031],
                [105.2911982, 9.4621342],
                [105.2911611, 9.4614523],
                [105.2911607, 9.4609446],
                [105.2912479, 9.4605527],
                [105.2913644, 9.460219],
                [105.2914297, 9.4598562],
                [105.2914149, 9.459537],
                [105.291327, 9.4591309],
                [105.2913119, 9.4586376],
                [105.2913114, 9.4580717],
                [105.2913033, 9.4572302],
                [105.2912879, 9.4562873],
                [105.2913532, 9.4557359],
                [105.2914259, 9.4553441],
                [105.2916008, 9.4549249],
                [105.2916731, 9.4544545],
                [105.291719, 9.453997],
                [105.2918464, 9.4534401],
                [105.2919367, 9.4528704],
                [105.2920145, 9.4524667],
                [105.2920888, 9.4519966],
                [105.2921149, 9.4516804],
                [105.2921574, 9.4512636],
                [105.2922369, 9.4507745],
                [105.2922338, 9.4504588],
                [105.2922254, 9.4500782],
                [105.2923157, 9.4494761],
                [105.2924214, 9.449022],
                [105.2927328, 9.4469941],
                [105.2929178, 9.4458748],
                [105.2930021, 9.4452095],
                [105.2932188, 9.4439951],
                [105.2932819, 9.4433405],
                [105.2933294, 9.4429815],
                [105.2934243, 9.4421684],
                [105.2935086, 9.4414716],
                [105.2936616, 9.4402889],
                [105.2937885, 9.4396976],
                [105.2940582, 9.438367],
                [105.2942219, 9.4371739],
                [105.2943063, 9.4366142],
                [105.294523, 9.4353893],
                [105.2946287, 9.434819],
                [105.2947926, 9.4339848],
                [105.2949515, 9.4334779],
                [105.2952111, 9.4329008],
                [105.2955222, 9.4323822],
                [105.2958977, 9.4320859],
                [105.2962839, 9.4316943],
                [105.2965629, 9.4313556],
                [105.296874, 9.431091],
                [105.2972495, 9.4307311],
                [105.29755, 9.4303713],
                [105.2978718, 9.4299585],
                [105.2982366, 9.4296092],
                [105.298537, 9.4293023],
                [105.298816, 9.4289636],
                [105.2991378, 9.4286144],
                [105.2994704, 9.4282545],
                [105.2997279, 9.427937],
                [105.3001141, 9.4275771],
                [105.3004038, 9.4272702],
                [105.3006935, 9.4269209],
                [105.3010476, 9.4265611],
                [105.3014016, 9.4262541],
                [105.3017235, 9.4258943],
                [105.3019702, 9.4254921],
                [105.3021955, 9.425037],
                [105.3023565, 9.424656],
                [105.3024745, 9.4243173],
                [105.3026247, 9.4241268],
                [105.3028393, 9.4239468],
                [105.3030646, 9.4238092],
                [105.3031933, 9.4236187],
                [105.3032684, 9.4234282],
                [105.3033435, 9.4232059],
                [105.3035366, 9.423026],
                [105.3036976, 9.4227085],
                [105.3037137, 9.4225444],
                [105.3036869, 9.4223539],
                [105.3036708, 9.4222005],
                [105.3036815, 9.4220258],
                [105.3037673, 9.4218194],
                [105.3038746, 9.4216289],
                [105.3040034, 9.4214543],
                [105.3041053, 9.4212585],
                [105.3042126, 9.4210627],
                [105.3043252, 9.4209092],
                [105.3044647, 9.4207663],
                [105.3047061, 9.420507],
                [105.3049207, 9.4202794],
                [105.3050467, 9.4201471],
                [105.3051889, 9.4200175],
                [105.3053337, 9.4199169],
                [105.3055107, 9.4198296],
                [105.305787, 9.4197449],
                [105.3059694, 9.4197105],
                [105.3061974, 9.4196814],
                [105.3065421, 9.4196761],
                [105.3068492, 9.4196947],
                [105.3069402, 9.4196988],
                [105.3070125, 9.419694],
                [105.3070835, 9.4196802],
                [105.3071523, 9.4196576],
                [105.3072148, 9.4196282],
                [105.3072734, 9.4195916],
                [105.307327, 9.4195483],
                [105.3073749, 9.4194989],
                [105.3075323, 9.4193549],
                [105.3076941, 9.4192157],
                [105.307861, 9.4190809],
                [105.308032, 9.4189511],
                [105.3082251, 9.4188135],
                [105.3082752, 9.4187759],
                [105.3083216, 9.418734],
                [105.308364, 9.4186882],
                [105.3084022, 9.4186389],
                [105.3084445, 9.4185706],
                [105.3084787, 9.418498],
                [105.3085377, 9.4183425],
                [105.3085663, 9.4182613],
                [105.3085899, 9.4181785],
                [105.3086087, 9.4180929],
                [105.3086221, 9.4180064],
                [105.3086302, 9.4179192],
                [105.3086489, 9.417771],
                [105.3087026, 9.4176334],
                [105.3087965, 9.4174984],
                [105.3089279, 9.4173635],
                [105.309121, 9.4172153],
                [105.3093249, 9.4171333],
                [105.3095904, 9.4170539],
                [105.3099176, 9.4169163],
                [105.3102556, 9.4167628],
                [105.3104353, 9.4166623],
                [105.310564, 9.4165353],
                [105.3106284, 9.4164056],
                [105.3106552, 9.4162336],
                [105.310674, 9.4160325],
                [105.3106928, 9.4157388],
                [105.310725, 9.4154213],
                [105.3107732, 9.4150667],
                [105.3108054, 9.4147809],
                [105.3108108, 9.4145163],
                [105.3108001, 9.4142623],
                [105.3107518, 9.4139712],
                [105.3106928, 9.4136854],
                [105.3106338, 9.4133626],
                [105.310607, 9.413098],
                [105.3105909, 9.4128546],
                [105.3105265, 9.4122724],
                [105.3104845, 9.4119733],
                [105.3106016, 9.4118014],
                [105.3106885, 9.411663],
                [105.3107708, 9.4115218],
                [105.3108483, 9.4113781],
                [105.3109825, 9.4110976],
                [105.3110576, 9.410907],
                [105.3111219, 9.4106848],
                [105.3111219, 9.4105154],
                [105.3110897, 9.410309],
                [105.3110415, 9.4100815],
                [105.3110039, 9.4098433],
                [105.3109825, 9.4096316],
                [105.3109878, 9.4094199],
                [105.3110039, 9.4092135],
                [105.3110522, 9.4089965],
                [105.3110951, 9.4088166],
                [105.3111005, 9.4086049],
                [105.311079, 9.4084197],
                [105.3110522, 9.4082133],
                [105.3110093, 9.4079275],
                [105.3110093, 9.4076841],
                [105.3110254, 9.4074036],
                [105.3110415, 9.407139],
                [105.3111005, 9.4069326],
                [105.3111917, 9.4067579],
                [105.3113311, 9.4065092],
                [105.3114814, 9.4062499],
                [105.3116423, 9.4059905],
                [105.3117871, 9.4057206],
                [105.3119051, 9.4054454],
                [105.3120661, 9.4050803],
                [105.3122377, 9.4046833],
                [105.3124309, 9.4042441],
                [105.3125542, 9.4039054],
                [105.3146624, 9.3980308],
                [105.3148292, 9.3975803],
                [105.3149682, 9.397147],
                [105.3150848, 9.3967946],
                [105.3151748, 9.3964854],
                [105.3152418, 9.3962235],
                [105.31532, 9.3958871],
                [105.3154452, 9.3953331],
                [105.3155798, 9.3947813],
                [105.3156919, 9.3943503],
                [105.3158097, 9.3939208],
                [105.3159333, 9.3934928],
                [105.3160626, 9.3930665],
                [105.316305, 9.3922793],
                [105.3165561, 9.3914946],
                [105.3167395, 9.3909393],
                [105.3169273, 9.3903853],
                [105.3171194, 9.3898328],
                [105.31923, 9.3832165],
                [105.3150192, 9.3831747],
                [105.3151265, 9.3838442],
                [105.3123665, 9.3840903],
                [105.3123531, 9.3853235],
                [105.3118086, 9.3853262],
                [105.3117496, 9.385657],
                [105.3095287, 9.3858104],
                [105.3094911, 9.3862921],
                [105.3074688, 9.3863979],
                [105.3065192, 9.3870701],
                [105.3073561, 9.3886049],
                [105.3069323, 9.3891448],
                [105.3058648, 9.3895999],
                [105.3054732, 9.3888219],
                [105.3046256, 9.3892136],
                [105.300672, 9.3905738],
                [105.3002912, 9.3897164],
                [105.2981534, 9.3904467],
                [105.2983197, 9.3907616],
                [105.2978772, 9.391013],
                [105.297836, 9.3909533],
                [105.2977884, 9.3908984],
                [105.297735, 9.390849],
                [105.2976871, 9.3908127],
                [105.2976359, 9.3907809],
                [105.2975821, 9.3907537],
                [105.2968204, 9.3912194],
                [105.2947986, 9.3881386],
                [105.2968559, 9.3868573],
                [105.2827509, 9.3722811],
                [105.2791541, 9.368584],
                [105.2758885, 9.3652057],
                [105.2702531, 9.359394],
                [105.2649343, 9.3539105],
                [105.2623118, 9.3511832],
                [105.2596852, 9.3484598],
                [105.2570237, 9.3457313],
                [105.25632, 9.3450172],
                [105.2556217, 9.3442981],
                [105.2549525, 9.343599],
                [105.2542882, 9.3428954],
                [105.2536154, 9.3432659],
                [105.2534648, 9.3431099],
                [105.2533203, 9.3429483],
                [105.2532165, 9.3428241],
                [105.2531164, 9.342697],
                [105.2530656, 9.3426339],
                [105.2530199, 9.3425672],
                [105.2529754, 9.3424893],
                [105.2529378, 9.3424079],
                [105.2529073, 9.3423237],
                [105.2528657, 9.3422085],
                [105.2528183, 9.3420954],
                [105.2527651, 9.3419849],
                [105.2527062, 9.3418768],
                [105.2526417, 9.3417717],
                [105.252572, 9.34167],
                [105.2524561, 9.3415061],
                [105.2523333, 9.3413471],
                [105.2522334, 9.3412273],
                [105.2521297, 9.3411108],
                [105.251998, 9.3409607],
                [105.2514884, 9.3403308],
                [105.2512845, 9.3403215],
                [105.2512717, 9.3402347],
                [105.2512591, 9.3401605],
                [105.2512416, 9.3400873],
                [105.2512094, 9.3399884],
                [105.2511682, 9.3398929],
                [105.2511182, 9.3398014],
                [105.2510563, 9.3396844],
                [105.2509922, 9.3395685],
                [105.2509236, 9.3394501],
                [105.2509003, 9.339415],
                [105.2508811, 9.3393775],
                [105.2508661, 9.3393383],
                [105.2508525, 9.3392802],
                [105.2508481, 9.3392207],
                [105.2508473, 9.3391371],
                [105.2508549, 9.3390387],
                [105.2508715, 9.3389413],
                [105.2503806, 9.3379726],
                [105.2503153, 9.3378622],
                [105.2502438, 9.3377556],
                [105.2501675, 9.3376542],
                [105.2501243, 9.3376075],
                [105.2500856, 9.3375571],
                [105.2500506, 9.3375016],
                [105.250021, 9.3374433],
                [105.2499944, 9.3373718],
                [105.2499813, 9.337321],
                [105.2499588, 9.3372617],
                [105.24993, 9.3372051],
                [105.2499024, 9.3371618],
                [105.249871, 9.3371211],
                [105.2498361, 9.3370833],
                [105.2497641, 9.3369954],
                [105.249694, 9.336906],
                [105.2495825, 9.3367545],
                [105.2494767, 9.336599],
                [105.2494384, 9.3365382],
                [105.2494052, 9.3364746],
                [105.2493775, 9.3364084],
                [105.2493578, 9.3363487],
                [105.2493426, 9.3362877],
                [105.2493135, 9.3362261],
                [105.2492782, 9.3361676],
                [105.2492375, 9.3361131],
                [105.2491916, 9.3360629],
                [105.2489081, 9.3357997],
                [105.2487371, 9.3356442],
                [105.2485628, 9.3354924],
                [105.2483851, 9.3353444],
                [105.2483354, 9.3352884],
                [105.2482907, 9.3352285],
                [105.2482513, 9.335165],
                [105.2482174, 9.3350986],
                [105.2481893, 9.3350295],
                [105.2481657, 9.3349529],
                [105.2481493, 9.3348745],
                [105.2481401, 9.334795],
                [105.2481382, 9.334715],
                [105.2481437, 9.3346351],
                [105.2481649, 9.3345564],
                [105.2481912, 9.3344792],
                [105.2482226, 9.3344039],
                [105.248259, 9.3343308],
                [105.2483051, 9.3342523],
                [105.2483569, 9.3341774],
                [105.248414, 9.3341063],
                [105.2485125, 9.3339861],
                [105.2485541, 9.3339367],
                [105.2486008, 9.3338917],
                [105.2486519, 9.3338518],
                [105.2487069, 9.3338173],
                [105.2487047, 9.3336293],
                [105.2487123, 9.3334415],
                [105.2487262, 9.3332839],
                [105.2487469, 9.3331271],
                [105.2487906, 9.3329819],
                [105.248803, 9.3328997],
                [105.2488089, 9.3328169],
                [105.2488085, 9.3327381],
                [105.2488022, 9.3326596],
                [105.2487902, 9.3325817],
                [105.2487698, 9.3324732],
                [105.2487445, 9.3321922],
                [105.2487291, 9.3319595],
                [105.2487184, 9.3317266],
                [105.2487177, 9.3314141],
                [105.2487397, 9.3311629],
                [105.2487606, 9.3308318],
                [105.2487736, 9.3305381],
                [105.2487816, 9.3302442],
                [105.2487713, 9.3299478],
                [105.2487518, 9.3296589],
                [105.248723, 9.3293708],
                [105.2487148, 9.3292805],
                [105.2487156, 9.3291898],
                [105.2487255, 9.3290996],
                [105.2487445, 9.3290109],
                [105.2487677, 9.3289366],
                [105.2487973, 9.3288645],
                [105.248833, 9.3287952],
                [105.2488745, 9.3287292],
                [105.2489215, 9.3286668],
                [105.2489765, 9.3286043],
                [105.2490362, 9.3285462],
                [105.2491002, 9.3284928],
                [105.2491683, 9.3284445],
                [105.2492221, 9.3283979],
                [105.2492716, 9.3283468],
                [105.2496618, 9.3278569],
                [105.2498302, 9.3276525],
                [105.2499912, 9.3274425],
                [105.2501446, 9.327227],
                [105.2502594, 9.3270544],
                [105.2503694, 9.3268788],
                [105.2504745, 9.3267003],
                [105.2505416, 9.3265785],
                [105.2506328, 9.3264409],
                [105.2506842, 9.3263623],
                [105.2507397, 9.3262863],
                [105.2507991, 9.3262133],
                [105.2508706, 9.3261344],
                [105.2509099, 9.3260984],
                [105.2509466, 9.3260598],
                [105.2509845, 9.3260131],
                [105.2510186, 9.3259636],
                [105.2510485, 9.3259115],
                [105.2510657, 9.3258689],
                [105.2510873, 9.3258281],
                [105.2511129, 9.3257898],
                [105.2511469, 9.3257492],
                [105.2511856, 9.3257129],
                [105.2512282, 9.3256813],
                [105.2512776, 9.3256431],
                [105.2513242, 9.3256015],
                [105.2513677, 9.3255569],
                [105.2514117, 9.3255045],
                [105.2514515, 9.3254489],
                [105.2507937, 9.3246014],
                [105.2509169, 9.3245224],
                [105.2510244, 9.3244585],
                [105.2510861, 9.3244176],
                [105.2511309, 9.3243891],
                [105.2511713, 9.3243549],
                [105.2512068, 9.3243155],
                [105.251233, 9.3242776],
                [105.2512546, 9.3242368],
                [105.2512711, 9.3241938],
                [105.2513045, 9.3240932],
                [105.2513314, 9.3239907],
                [105.2513516, 9.3238868],
                [105.2513656, 9.3238301],
                [105.2513865, 9.3237756],
                [105.2514127, 9.3237257],
                [105.2514446, 9.3236792],
                [105.2514991, 9.3236366],
                [105.2515621, 9.3236062],
                [105.2517137, 9.3235427],
                [105.251758, 9.3235142],
                [105.2517995, 9.3234818],
                [105.251836, 9.3234476],
                [105.2518693, 9.3234103],
                [105.2522206, 9.3230266],
                [105.2522462, 9.3230024],
                [105.2522756, 9.3229829],
                [105.2523075, 9.3229689],
                [105.2523507, 9.3229644],
                [105.2528805, 9.3229948],
                [105.2529118, 9.3229909],
                [105.2529411, 9.3229791],
                [105.2529663, 9.3229604],
                [105.2529826, 9.3229409],
                [105.2529943, 9.3229185],
                [105.2530682, 9.3212612],
                [105.2530947, 9.3212376],
                [105.2531181, 9.321211],
                [105.2531379, 9.3211817],
                [105.2531559, 9.3211457],
                [105.2531685, 9.3211075],
                [105.2531755, 9.3210679],
                [105.253779, 9.3211023],
                [105.2537776, 9.3208323],
                [105.2537811, 9.3205623],
                [105.2537897, 9.3202924],
                [105.253804, 9.3200115],
                [105.2538236, 9.3197309],
                [105.2538487, 9.3194507],
                [105.2541733, 9.3194957],
                [105.2542075, 9.3188196],
                [105.2542376, 9.3181432],
                [105.2542639, 9.3174591],
                [105.2542859, 9.3167748],
                [105.2546883, 9.3168357],
                [105.2547526, 9.3164598],
                [105.2552247, 9.3165578],
                [105.2551979, 9.3173677],
                [105.2560186, 9.3174021],
                [105.2560405, 9.3166939],
                [105.2560477, 9.3166319],
                [105.2560616, 9.316571],
                [105.2560812, 9.316514],
                [105.2561067, 9.3164593],
                [105.2561378, 9.3164076],
                [105.2561796, 9.316354],
                [105.2563727, 9.3164307],
                [105.256426, 9.3163989],
                [105.2564771, 9.3163635],
                [105.2565256, 9.3163248],
                [105.2565875, 9.3162667],
                [105.2566436, 9.3162031],
                [105.2567394, 9.3160752],
                [105.2568047, 9.3159825],
                [105.2568635, 9.3158855],
                [105.2569104, 9.3157954],
                [105.2569516, 9.3157026],
                [105.2569869, 9.3156076],
                [105.2570342, 9.3155203],
                [105.2570898, 9.315438],
                [105.2571532, 9.3153614],
                [105.25721, 9.3153039],
                [105.2572712, 9.315251],
                [105.2573257, 9.3152123],
                [105.2573919, 9.3151616],
                [105.2575059, 9.3150489],
                [105.2576038, 9.3149353],
                [105.2577536, 9.3147501],
                [105.2578774, 9.3145938],
                [105.2581188, 9.3143],
                [105.2582111, 9.314195],
                [105.2583012, 9.3140883],
                [105.2584171, 9.3139442],
                [105.2584722, 9.3138699],
                [105.2585292, 9.3137971],
                [105.2586238, 9.3136845],
                [105.2587231, 9.3135759],
                [105.2588269, 9.3134716],
                [105.2589476, 9.3133445],
                [105.2595323, 9.3126854],
                [105.2601914, 9.3119433],
                [105.2608466, 9.3111979],
                [105.2614206, 9.3105627],
                [105.2619785, 9.3099327],
                [105.2623486, 9.3095357],
                [105.2625701, 9.3093043],
                [105.2627671, 9.3090936],
                [105.2629172, 9.3089221],
                [105.2630442, 9.3087721],
                [105.2631748, 9.3086251],
                [105.2632979, 9.30849],
                [105.2634135, 9.3083657],
                [105.2635476, 9.3082413],
                [105.2666402, 9.3058512],
                [105.266329, 9.3052583],
                [105.2678391, 9.3043822],
                [105.2679411, 9.3043372],
                [105.2680435, 9.3043051],
                [105.268142, 9.3042865],
                [105.2682388, 9.3042604],
                [105.2683216, 9.3042315],
                [105.2684022, 9.304197],
                [105.2684802, 9.3041572],
                [105.2685553, 9.3041122],
                [105.2686054, 9.3041763],
                [105.2758221, 9.2993148],
                [105.2760482, 9.2991371],
                [105.2762264, 9.2989903],
                [105.2763746, 9.2988602],
                [105.2765188, 9.2987259],
                [105.276659, 9.2985875],
                [105.276795, 9.2984451],
                [105.2770061, 9.2982142],
                [105.2772207, 9.2979865],
                [105.2774388, 9.2977621],
                [105.2776745, 9.2975271],
                [105.2779142, 9.2972959],
                [105.2781576, 9.2970686],
                [105.2783758, 9.2968659],
                [105.2785904, 9.2966594],
                [105.2788013, 9.2964492],
                [105.2790146, 9.2962289],
                [105.2792239, 9.2960048],
                [105.2802976, 9.2948047],
                [105.2811442, 9.293879],
                [105.2820195, 9.2929252],
                [105.2828997, 9.2919758],
                [105.2831144, 9.2917431],
                [105.2833316, 9.2915126],
                [105.2835622, 9.2912731],
                [105.2837956, 9.2910361],
                [105.2840181, 9.2907977],
                [105.2842382, 9.290557],
                [105.284439, 9.2903329],
                [105.2846378, 9.290107],
                [105.2855122, 9.2891435],
                [105.2864081, 9.2881694],
                [105.2872558, 9.2872209],
                [105.2881086, 9.2862767],
                [105.2889658, 9.2853375],
                [105.2898279, 9.2844026],
                [105.2916008, 9.2824623],
                [105.2924216, 9.2815491],
                [105.2932692, 9.2806014],
                [105.2934437, 9.2804067],
                [105.2936125, 9.280207],
                [105.2937988, 9.2799906],
                [105.2940849, 9.2796582],
                [105.2889454, 9.274828],
                [105.288001, 9.2739017],
                [105.2870514, 9.2729805],
                [105.2860966, 9.2720645],
                [105.2851367, 9.2711538],
                [105.2841574, 9.270235],
                [105.2831729, 9.2693216],
                [105.2821833, 9.2684137],
                [105.281226, 9.2675271],
                [105.2809854, 9.2672833],
                [105.2807769, 9.2670603],
                [105.280563, 9.2668422],
                [105.2803438, 9.2666293],
                [105.2801196, 9.2664217],
                [105.2798903, 9.2662194],
                [105.2796724, 9.266036],
                [105.2794504, 9.2658574],
                [105.2792243, 9.2656838],
                [105.2790052, 9.2655417],
                [105.2787354, 9.2653983],
                [105.27848, 9.2652735],
                [105.2782288, 9.265141],
                [105.2779817, 9.265001],
                [105.2777392, 9.2648535],
                [105.2775073, 9.2647027],
                [105.2772801, 9.264545],
                [105.2770578, 9.2643808],
                [105.2768406, 9.26421],
                [105.2767365, 9.2641193],
                [105.2766287, 9.2640328],
                [105.2764926, 9.2639331],
                [105.2763517, 9.2638401],
                [105.2762065, 9.263754],
                [105.2760571, 9.2636749],
                [105.275904, 9.2636032],
                [105.2757475, 9.2635389],
                [105.275588, 9.2634822],
                [105.2753204, 9.263382],
                [105.275055, 9.2632762],
                [105.2747919, 9.2631649],
                [105.2746609, 9.2631078],
                [105.2745312, 9.2630481],
                [105.2743289, 9.2629483],
                [105.2741299, 9.262842],
                [105.2739346, 9.2627294],
                [105.2737431, 9.2626105],
                [105.2735557, 9.2624855],
                [105.2733725, 9.2623545],
                [105.2731486, 9.2622051],
                [105.2729195, 9.2620638],
                [105.2726854, 9.2619306],
                [105.2724466, 9.2618057],
                [105.2721552, 9.2616681],
                [105.2713143, 9.261248],
                [105.270429, 9.2607991],
                [105.2695477, 9.2603426],
                [105.2686998, 9.2598943],
                [105.2678558, 9.2594389],
                [105.2670586, 9.2589978],
                [105.2668178, 9.2588702],
                [105.2666242, 9.2587565],
                [105.2664351, 9.2586356],
                [105.2662508, 9.2585077],
                [105.2660715, 9.258373],
                [105.2658868, 9.2582226],
                [105.2657083, 9.2580649],
                [105.2655364, 9.2579002],
                [105.2653714, 9.2577288],
                [105.2652135, 9.257551],
                [105.2650016, 9.2573214],
                [105.2647841, 9.257097],
                [105.2645405, 9.2568582],
                [105.264312, 9.2566364],
                [105.2641195, 9.2564299],
                [105.2639545, 9.256229],
                [105.2637955, 9.2560234],
                [105.2636429, 9.2558132],
                [105.2634966, 9.2555987],
                [105.2633482, 9.2553658],
                [105.2632074, 9.2551284],
                [105.2630742, 9.2548867],
                [105.2629488, 9.254641],
                [105.2628314, 9.2543915],
                [105.2627818, 9.254264],
                [105.2627253, 9.2541393],
                [105.2626621, 9.2540178],
                [105.2625923, 9.2538999],
                [105.2625162, 9.2537858],
                [105.262434, 9.253676],
                [105.2623458, 9.2535707],
                [105.2622521, 9.2534702],
                [105.2621462, 9.2533688],
                [105.2620346, 9.2532735],
                [105.2619176, 9.2531847],
                [105.2617956, 9.2531027],
                [105.261669, 9.2530277],
                [105.2615383, 9.2529601],
                [105.2612853, 9.2528353],
                [105.2610296, 9.2527159],
                [105.2607715, 9.2526019],
                [105.2604634, 9.2524742],
                [105.2601522, 9.2523542],
                [105.259881, 9.2522578],
                [105.2596833, 9.2522017],
                [105.2595305, 9.2521545],
                [105.2593801, 9.2521005],
                [105.2592323, 9.2520397],
                [105.2590876, 9.2519723],
                [105.2589461, 9.2518985],
                [105.2588081, 9.2518183],
                [105.258663, 9.2517245],
                [105.2585228, 9.2516237],
                [105.2583879, 9.2515162],
                [105.2582585, 9.2514022],
                [105.258135, 9.2512819],
                [105.2580177, 9.2511558],
                [105.2579069, 9.2510241],
                [105.257826, 9.250919],
                [105.2577527, 9.2508087],
                [105.2576874, 9.2506935],
                [105.2576304, 9.2505742],
                [105.2575819, 9.2504512],
                [105.2575421, 9.2503252],
                [105.2575115, 9.2501974],
                [105.2574899, 9.2500677],
                [105.2574774, 9.249937],
                [105.2574742, 9.2498056],
                [105.2574801, 9.2496744],
                [105.2574953, 9.2495439],
                [105.2575196, 9.2494147],
                [105.2575529, 9.2492875],
                [105.25759, 9.2491531],
                [105.2576199, 9.2490171],
                [105.2576426, 9.2488796],
                [105.257658, 9.2487412],
                [105.2576661, 9.2486022],
                [105.2576668, 9.2484629],
                [105.2576601, 9.2483238],
                [105.2576434, 9.2481645],
                [105.257617, 9.2480065],
                [105.257581, 9.2478503],
                [105.2575355, 9.2476966],
                [105.2574808, 9.2475458],
                [105.257417, 9.2473985],
                [105.2573444, 9.2472553],
                [105.2572632, 9.2471167],
                [105.2572033, 9.2470047],
                [105.2571389, 9.2468952],
                [105.2570701, 9.2467884],
                [105.2569936, 9.24668],
                [105.2569126, 9.2465749],
                [105.2568272, 9.2464733],
                [105.2567375, 9.2463754],
                [105.2566309, 9.2462567],
                [105.2565295, 9.2461337],
                [105.2564335, 9.2460065],
                [105.2563431, 9.2458754],
                [105.2562584, 9.2457406],
                [105.2561796, 9.2456024],
                [105.2560988, 9.2454446],
                [105.2560258, 9.2452831],
                [105.2559606, 9.2451185],
                [105.2559033, 9.244951],
                [105.2558542, 9.244781],
                [105.2558133, 9.2446089],
                [105.2557807, 9.244435],
                [105.2557565, 9.2442599],
                [105.2557118, 9.2440566],
                [105.2556605, 9.2438548],
                [105.2556028, 9.2436547],
                [105.2555317, 9.2434362],
                [105.2554528, 9.2432204],
                [105.2553663, 9.2430074],
                [105.2553357, 9.2429426],
                [105.2552998, 9.2428804],
                [105.2552588, 9.2428214],
                [105.2552041, 9.2427561],
                [105.2551431, 9.2426964],
                [105.2550471, 9.2426163],
                [105.2548764, 9.2424937],
                [105.2546718, 9.2423507],
                [105.254463, 9.2422137],
                [105.2542709, 9.2420953],
                [105.2540756, 9.2419821],
                [105.2538773, 9.2418741],
                [105.2536762, 9.2417714],
                [105.2531404, 9.2415375],
                [105.2527844, 9.2412917],
                [105.2524181, 9.2410357],
                [105.2519923, 9.2406258],
                [105.2516775, 9.2403031],
                [105.2513524, 9.2399598],
                [105.2508285, 9.2393194],
                [105.2506581, 9.239094],
                [105.2503706, 9.2387035],
                [105.2501914, 9.2384738],
                [105.2500989, 9.2383763],
                [105.2498016, 9.2380199],
                [105.2493249, 9.2375242],
                [105.2490305, 9.2373433],
                [105.2484137, 9.236831],
                [105.2480687, 9.2364162],
                [105.2475976, 9.2359261],
                [105.2473676, 9.2356308],
                [105.2472611, 9.2354693],
                [105.2471012, 9.2352382],
                [105.2465233, 9.2344639],
                [105.2463355, 9.234216],
                [105.2457493, 9.2334948],
                [105.2455698, 9.2331995],
                [105.2454463, 9.2330214],
                [105.2453369, 9.2327511],
                [105.2447114, 9.231924],
                [105.2445375, 9.2316622],
                [105.2438897, 9.2309632],
                [105.2436485, 9.2306736],
                [105.2435251, 9.2305232],
                [105.2432924, 9.2302085],
                [105.2427174, 9.2293758],
                [105.2424594, 9.2290528],
                [105.2419181, 9.2283982],
                [105.2416938, 9.2280975],
                [105.2415928, 9.2279861],
                [105.2412984, 9.2276659],
                [105.2407038, 9.226875],
                [105.2404542, 9.2265325],
                [105.2399549, 9.2259364],
                [105.2397363, 9.2257416],
                [105.2395821, 9.2255939],
                [105.2393186, 9.2254492],
                [105.2391764, 9.2253909],
                [105.2384778, 9.2251043],
                [105.2382227, 9.2250182],
                [105.2373595, 9.2246899],
                [105.237096, 9.2246064],
                [105.236998, 9.2245871],
                [105.2365944, 9.2244425],
                [105.2358265, 9.224142],
                [105.2356948, 9.2241059],
                [105.2349268, 9.2238612],
                [105.2347587, 9.2238446],
                [105.2345626, 9.2238168],
                [105.2342795, 9.2237808],
                [105.2337416, 9.223898],
                [105.2334839, 9.2239542],
                [105.2332879, 9.2240657],
                [105.2328342, 9.2242834],
                [105.2327864, 9.2243085],
                [105.2327237, 9.2226966],
                [105.2325885, 9.2191343],
                [105.2324769, 9.2166549],
                [105.2323884, 9.2145315],
                [105.2323669, 9.2137108],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.9040968, 21.2251796],
                [105.9041498, 21.224672],
                [105.9042121, 21.2239636],
                [105.9043308, 21.2234007],
                [105.904528, 21.2229623],
                [105.9046105, 21.2228547],
                [105.9047925, 21.2226173],
                [105.9051354, 21.2223967],
                [105.905315, 21.2223462],
                [105.9055123, 21.2222907],
                [105.9059564, 21.2222778],
                [105.9064232, 21.2223378],
                [105.9069796, 21.2225223],
                [105.9073587, 21.2227597],
                [105.9080066, 21.2233706],
                [105.9088891, 21.2242301],
                [105.9092462, 21.2245093],
                [105.9095026, 21.2246641],
                [105.9097361, 21.2247254],
                [105.9100027, 21.2247447],
                [105.9102803, 21.2247224],
                [105.910635, 21.2246163],
                [105.9108673, 21.2244694],
                [105.911165, 21.2241241],
                [105.9114286, 21.2236436],
                [105.9115813, 21.2231847],
                [105.9117967, 21.2220902],
                [105.911959, 21.2214021],
                [105.9121122, 21.2210264],
                [105.9123434, 21.2207023],
                [105.9126192, 21.2204092],
                [105.9129175, 21.2201474],
                [105.9133599, 21.2198638],
                [105.9137579, 21.2195701],
                [105.9139566, 21.2193816],
                [105.9142214, 21.2191094],
                [105.9146615, 21.2184406],
                [105.9150679, 21.2176991],
                [105.9153314, 21.2172083],
                [105.9155162, 21.2165617],
                [105.9156338, 21.2158217],
                [105.9156957, 21.2150717],
                [105.9157245, 21.2143322],
                [105.9157807, 21.2126451],
                [105.9158505, 21.2113535],
                [105.915873, 21.2096144],
                [105.9158777, 21.2085731],
                [105.9157764, 21.2083446],
                [105.9156086, 21.2081581],
                [105.9153968, 21.2080134],
                [105.9152409, 21.2079414],
                [105.9150407, 21.2078904],
                [105.9148186, 21.2078916],
                [105.9145633, 21.2079034],
                [105.9142416, 21.2079468],
                [105.913698, 21.208033],
                [105.9128765, 21.2080582],
                [105.9121654, 21.2079997],
                [105.911443, 21.2079098],
                [105.9110095, 21.2078185],
                [105.9107533, 21.2076949],
                [105.9105081, 21.2075505],
                [105.9103289, 21.2073015],
                [105.9102263, 21.2068647],
                [105.9102463, 21.2065106],
                [105.9103881, 21.2060933],
                [105.9105086, 21.205822],
                [105.9107836, 21.205404],
                [105.9110593, 21.20509],
                [105.9113573, 21.2047865],
                [105.9117992, 21.2044301],
                [105.9125737, 21.2039677],
                [105.9134037, 21.2035258],
                [105.9136911, 21.2033161],
                [105.9138016, 21.2032113],
                [105.9138674, 21.2030756],
                [105.9138555, 21.2029507],
                [105.9138102, 21.2028051],
                [105.9136538, 21.2026603],
                [105.9134307, 21.2024845],
                [105.9130075, 21.2022785],
                [105.9124734, 21.2020939],
                [105.912139, 21.2018875],
                [105.9118933, 21.2016597],
                [105.9116025, 21.2013281],
                [105.9112905, 21.2011423],
                [105.9109936, 21.2010415],
                [105.9102863, 21.2008736],
                [105.9099454, 21.2007283],
                [105.9095131, 21.2003652],
                [105.908232, 21.1985789],
                [105.9070701, 21.1967699],
                [105.9066119, 21.1945402],
                [105.9069468, 21.1937044],
                [105.9071524, 21.1931146],
                [105.9071964, 21.1922871],
                [105.9136556, 21.1915586],
                [105.9135688, 21.1901855],
                [105.915941, 21.1888971],
                [105.9155681, 21.1879231],
                [105.9155103, 21.1875431],
                [105.9156305, 21.1872982],
                [105.9185903, 21.1850938],
                [105.9237736, 21.1827191],
                [105.9236434, 21.182551],
                [105.9212697, 21.1793506],
                [105.9206775, 21.178305],
                [105.9204792, 21.1779399],
                [105.9219236, 21.1765777],
                [105.9215509, 21.1763029],
                [105.9213114, 21.1760677],
                [105.9212287, 21.1758314],
                [105.9212708, 21.1753032],
                [105.9214185, 21.1738061],
                [105.9183831, 21.1735282],
                [105.9173774, 21.1715959],
                [105.9173235, 21.1713509],
                [105.91734, 21.1697808],
                [105.9173968, 21.1692872],
                [105.9174338, 21.1691262],
                [105.9173837, 21.1688333],
                [105.9173675, 21.1685899],
                [105.9173146, 21.1681532],
                [105.9175145, 21.1675172],
                [105.9175814, 21.1670059],
                [105.9176216, 21.1665445],
                [105.9177016, 21.1663076],
                [105.9179412, 21.1659834],
                [105.9180654, 21.1658265],
                [105.9182076, 21.1656468],
                [105.9184353, 21.1652134],
                [105.9188598, 21.1642345],
                [105.9194533, 21.1628448],
                [105.9199024, 21.1624445],
                [105.9206923, 21.1625935],
                [105.9226992, 21.162937],
                [105.9232096, 21.1617199],
                [105.9219848, 21.160461],
                [105.9211585, 21.1595367],
                [105.9205999, 21.1577807],
                [105.920196, 21.1565826],
                [105.919698, 21.1550947],
                [105.9206813, 21.1537604],
                [105.9212078, 21.1529935],
                [105.9218455, 21.1520738],
                [105.9226728, 21.1493644],
                [105.9232171, 21.1475101],
                [105.9241713, 21.1472417],
                [105.9260417, 21.1465439],
                [105.9254443, 21.1451633],
                [105.9264315, 21.1444602],
                [105.9264255, 21.1434968],
                [105.9264197, 21.1425666],
                [105.9255655, 21.1418737],
                [105.9265485, 21.1405062],
                [105.9238471, 21.1381543],
                [105.9255653, 21.1361263],
                [105.9252718, 21.1345],
                [105.924278, 21.1341068],
                [105.9244842, 21.133109],
                [105.9235423, 21.1330378],
                [105.9218729, 21.1352409],
                [105.9210763, 21.1345697],
                [105.9204802, 21.1340674],
                [105.919134, 21.1329331],
                [105.9179923, 21.1320401],
                [105.9193209, 21.1299088],
                [105.9178601, 21.1284322],
                [105.916976, 21.1276122],
                [105.9151601, 21.1264496],
                [105.9142591, 21.1272432],
                [105.9129209, 21.126243],
                [105.9146509, 21.1227404],
                [105.9147176, 21.1219985],
                [105.915387, 21.122021],
                [105.9155875, 21.1200299],
                [105.9156649, 21.1189679],
                [105.9215359, 21.1192693],
                [105.9219746, 21.1205702],
                [105.9223752, 21.1216109],
                [105.9232163, 21.1213132],
                [105.924059, 21.1208225],
                [105.9226817, 21.1188459],
                [105.9217056, 21.1173347],
                [105.9213633, 21.1167354],
                [105.9221826, 21.1163392],
                [105.9235052, 21.1154767],
                [105.9247103, 21.1147518],
                [105.9255362, 21.1142141],
                [105.9249494, 21.112696],
                [105.9266971, 21.1113905],
                [105.9269378, 21.1116239],
                [105.9295772, 21.1119846],
                [105.9307973, 21.1112159],
                [105.9313819, 21.1101822],
                [105.9316954, 21.1098334],
                [105.9318523, 21.1094478],
                [105.9314608, 21.1088784],
                [105.9314815, 21.1084724],
                [105.9314982, 21.1083329],
                [105.9338961, 21.106458],
                [105.9344744, 21.1058413],
                [105.9347198, 21.1048077],
                [105.9347075, 21.1033074],
                [105.9342364, 21.1027735],
                [105.9349314, 21.1017212],
                [105.9364005, 21.1009902],
                [105.9357108, 21.098904],
                [105.9360031, 21.0969518],
                [105.9370013, 21.0940312],
                [105.937673, 21.092203],
                [105.9381125, 21.0911571],
                [105.9404496, 21.0885899],
                [105.9407184, 21.0882946],
                [105.9403297, 21.0877837],
                [105.9400897, 21.0874684],
                [105.940317, 21.0836545],
                [105.9441841, 21.0854093],
                [105.9457189, 21.0850473],
                [105.9534474, 21.0895459],
                [105.9578069, 21.0912181],
                [105.959644, 21.0886674],
                [105.9619415, 21.0778536],
                [105.963773, 21.0774632],
                [105.9666111, 21.0753077],
                [105.9685117, 21.0734931],
                [105.9680626, 21.0722708],
                [105.9716809, 21.0715081],
                [105.9769054, 21.0703957],
                [105.9781424, 21.0702585],
                [105.9784759, 21.0702215],
                [105.9795527, 21.0701021],
                [105.9830379, 21.070056],
                [105.9863822, 21.0700505],
                [105.9933502, 21.0688746],
                [105.9968234, 21.0722749],
                [105.9987118, 21.0739687],
                [105.999125, 21.074385],
                [106.0003377, 21.0724072],
                [106.0009772, 21.0698147],
                [106.0010566, 21.0698247],
                [106.0018961, 21.0699311],
                [106.0021229, 21.0690144],
                [106.0022509, 21.068497],
                [106.0039502, 21.0661819],
                [106.0057953, 21.0635481],
                [106.006276, 21.0629823],
                [106.0083539, 21.0646282],
                [106.0095026, 21.0625587],
                [106.0089769, 21.0620488],
                [106.0084325, 21.061087],
                [106.0081112, 21.0598083],
                [106.007427, 21.0590734],
                [106.0082582, 21.0582336],
                [106.007997, 21.0578037],
                [106.008099, 21.0575949],
                [106.0081739, 21.0565572],
                [106.0082055, 21.0555434],
                [106.0098717, 21.05604],
                [106.0101749, 21.0577393],
                [106.0104776, 21.0578166],
                [106.01069, 21.0578563],
                [106.0109203, 21.0579094],
                [106.011381, 21.0579315],
                [106.0110432, 21.0569611],
                [106.0125162, 21.0565675],
                [106.0133704, 21.0563399],
                [106.0143741, 21.0559555],
                [106.0133751, 21.0536022],
                [106.0134172, 21.0534605],
                [106.0135456, 21.0533585],
                [106.0151328, 21.0524795],
                [106.0151103, 21.0523381],
                [106.0142729, 21.0507093],
                [106.0147883, 21.0504392],
                [106.0161967, 21.0495502],
                [106.0183451, 21.0483309],
                [106.0198989, 21.0474968],
                [106.0197307, 21.0450574],
                [106.019739, 21.0441626],
                [106.0199617, 21.0418836],
                [106.0200725, 21.0412051],
                [106.0196674, 21.0402196],
                [106.0191415, 21.0390866],
                [106.0186602, 21.0380785],
                [106.0179619, 21.0369987],
                [106.0160107, 21.0345188],
                [106.0149465, 21.0331907],
                [106.0126563, 21.0304563],
                [106.0124125, 21.0300622],
                [106.0064733, 21.028832],
                [106.0094404, 21.0246136],
                [106.0069845, 21.024527],
                [106.0045331, 21.0218907],
                [106.00438, 21.0215274],
                [106.0038848, 21.0199518],
                [106.0036866, 21.0193055],
                [106.0037071, 21.0191435],
                [106.0037712, 21.0190622],
                [106.0048233, 21.0185906],
                [106.004801, 21.0184895],
                [106.0045143, 21.0174592],
                [106.0045452, 21.0172567],
                [106.0046305, 21.0171347],
                [106.0046776, 21.0171183],
                [106.0048668, 21.0170524],
                [106.0066922, 21.0162726],
                [106.0068207, 21.0161708],
                [106.0068201, 21.0160695],
                [106.0047382, 21.0139167],
                [106.0056997, 21.0131155],
                [106.0049054, 21.0115754],
                [106.0050213, 21.0115554],
                [106.0053526, 21.0115535],
                [106.0055778, 21.0115397],
                [106.0057633, 21.0115386],
                [106.0062935, 21.0115603],
                [106.0066249, 21.0115833],
                [106.0073024, 21.0118156],
                [106.0084585, 21.0123065],
                [106.0090228, 21.0124548],
                [106.0092217, 21.012491],
                [106.0095014, 21.0125257],
                [106.0096723, 21.0124883],
                [106.009836, 21.0123442],
                [106.009911, 21.011873],
                [106.009987, 21.0114723],
                [106.0100845, 21.0109586],
                [106.0100388, 21.0107875],
                [106.0089044, 21.0104557],
                [106.0087273, 21.0103298],
                [106.0049774, 21.0105118],
                [106.0048108, 21.0078365],
                [106.0045774, 21.0076867],
                [106.0034459, 21.0075954],
                [106.0018606, 21.007398],
                [106.0008809, 21.0067547],
                [105.9987358, 21.006569],
                [105.9995303, 21.0044474],
                [106.0002883, 21.0027339],
                [106.0005146, 21.00201],
                [106.0008188, 21.0010316],
                [106.0012074, 21.0001349],
                [106.001894, 21.0009934],
                [106.0043993, 21.0005856],
                [106.00438, 21.0000743],
                [106.0042582, 20.9994264],
                [106.0041238, 20.9990181],
                [106.0044799, 20.9989427],
                [106.0044037, 20.9983179],
                [106.0075371, 20.9982999],
                [106.0119398, 20.998273],
                [106.0115508, 20.9990357],
                [106.0113017, 20.9999596],
                [106.0109157, 21.000808],
                [106.0118348, 21.0014364],
                [106.0128751, 21.0021182],
                [106.0140464, 21.0017524],
                [106.0149392, 21.0020054],
                [106.0154807, 21.0021123],
                [106.0157765, 21.0020624],
                [106.0164386, 21.0019004],
                [106.016924, 21.0017226],
                [106.0169668, 21.0014198],
                [106.0169879, 21.0010049],
                [106.0171671, 21.0001514],
                [106.0176378, 20.999283],
                [106.0179156, 20.9984552],
                [106.0187037, 20.9982504],
                [106.0189565, 20.9978427],
                [106.0193657, 20.997342],
                [106.019932, 20.9968034],
                [106.0206739, 20.9960793],
                [106.0209472, 20.9958192],
                [106.0205707, 20.9953601],
                [106.0210784, 20.9948772],
                [106.0212742, 20.9947653],
                [106.0219079, 20.9954812],
                [106.022767, 20.9946456],
                [106.0235767, 20.9952313],
                [106.0245354, 20.9957601],
                [106.0269868, 20.9930997],
                [106.0276848, 20.9937788],
                [106.0279691, 20.9935099],
                [106.0287797, 20.993031],
                [106.028906, 20.9929991],
                [106.0289608, 20.9931534],
                [106.0294487, 20.9932608],
                [106.0294285, 20.9934153],
                [106.0296136, 20.9940986],
                [106.0300533, 20.9940335],
                [106.0300977, 20.9941564],
                [106.0303127, 20.9940698],
                [106.0303578, 20.9943089],
                [106.030966, 20.9956646],
                [106.0313966, 20.9953903],
                [106.0315698, 20.9957358],
                [106.0314693, 20.9958017],
                [106.0318374, 20.9962769],
                [106.0319685, 20.996225],
                [106.0322307, 20.996588],
                [106.0319244, 20.9969803],
                [106.0319387, 20.9970486],
                [106.0322135, 20.9970778],
                [106.0323774, 20.9971416],
                [106.0328725, 20.9973736],
                [106.0330877, 20.9969735],
                [106.0337951, 20.9971288],
                [106.0344671, 20.9973509],
                [106.0351637, 20.9976535],
                [106.035199, 20.9977944],
                [106.0360072, 20.9980193],
                [106.0378461, 20.99859],
                [106.0375803, 20.9995218],
                [106.0379343, 20.9998476],
                [106.0390325, 21.0010673],
                [106.0393978, 21.0009437],
                [106.039831, 21.0012647],
                [106.0396821, 21.0015488],
                [106.0412807, 21.0023364],
                [106.0416609, 21.0014269],
                [106.0422041, 21.0016924],
                [106.0426794, 21.0017558],
                [106.0440952, 21.002678],
                [106.0441371, 21.0029486],
                [106.0470582, 21.0038767],
                [106.0477004, 21.0033862],
                [106.0499787, 21.0037784],
                [106.0489414, 21.0052865],
                [106.051142, 21.0069902],
                [106.0520209, 21.0062107],
                [106.0522208, 21.0062082],
                [106.0527073, 21.0059464],
                [106.0527938, 21.0059865],
                [106.0528823, 21.0063301],
                [106.0528647, 21.0064791],
                [106.0552147, 21.0068732],
                [106.0552603, 21.0060918],
                [106.0552784, 21.0057542],
                [106.055236, 21.0055713],
                [106.0551941, 21.0054464],
                [106.0550799, 21.0052639],
                [106.0549453, 21.0051105],
                [106.0546871, 21.0049],
                [106.0545218, 21.0047757],
                [106.0544802, 21.0046988],
                [106.0544795, 21.0045928],
                [106.0544991, 21.0044577],
                [106.0545799, 21.0042836],
                [106.0547872, 21.0040636],
                [106.0558303, 21.0032931],
                [106.0563648, 21.002723],
                [106.0565984, 21.0022357],
                [106.0569469, 21.0012618],
                [106.0571176, 21.0010179],
                [106.0579966, 21.0004253],
                [106.0585987, 21.0002596],
                [106.0616594, 21.0004834],
                [106.0635342, 21.000573],
                [106.0656362, 21.0008027],
                [106.0670715, 21.0008848],
                [106.0675533, 21.0005796],
                [106.0707239, 20.9984342],
                [106.0703873, 20.9980288],
                [106.0711128, 20.9976917],
                [106.0720542, 20.997298],
                [106.0729176, 20.9969969],
                [106.0736436, 20.9967337],
                [106.0742319, 20.9964713],
                [106.0747419, 20.9962649],
                [106.0749759, 20.9962225],
                [106.0751737, 20.9967372],
                [106.0758462, 20.9966763],
                [106.0759392, 20.9961296],
                [106.076975, 20.9954512],
                [106.0771065, 20.9957539],
                [106.0773367, 20.9957221],
                [106.077454, 20.9968593],
                [106.0782946, 20.996854],
                [106.0790374, 20.9967279],
                [106.0802196, 20.9962288],
                [106.0806068, 20.9969152],
                [106.081163, 20.9978221],
                [106.0813794, 20.9980498],
                [106.0824186, 20.9983724],
                [106.0824211, 20.9985153],
                [106.0816717, 20.9987178],
                [106.0819945, 20.9991619],
                [106.0819133, 20.9992171],
                [106.0820619, 20.9995289],
                [106.0826402, 20.9991716],
                [106.0827585, 20.9993596],
                [106.083121, 20.9992562],
                [106.0831857, 20.9993299],
                [106.0831185, 20.9993845],
                [106.0831573, 20.9994912],
                [106.0827019, 20.9998274],
                [106.0822156, 20.9999693],
                [106.0836832, 21.0045516],
                [106.0839574, 21.0044856],
                [106.0843848, 21.0048558],
                [106.0846117, 21.0046626],
                [106.0848428, 21.0048779],
                [106.0849132, 21.0050115],
                [106.0851709, 21.0049173],
                [106.0856603, 21.0056145],
                [106.0878283, 21.0043507],
                [106.0892498, 21.0064317],
                [106.0895627, 21.0063403],
                [106.090519, 21.006461],
                [106.0922144, 21.0048448],
                [106.0932649, 21.0053885],
                [106.0938661, 21.0045526],
                [106.0961654, 21.0027915],
                [106.0960928, 21.0025839],
                [106.0962114, 21.002539],
                [106.0961188, 21.0023237],
                [106.095947, 21.0022327],
                [106.0958653, 21.0017581],
                [106.0971791, 21.0010778],
                [106.0974336, 21.0018569],
                [106.0992083, 21.0005642],
                [106.0997252, 20.9999544],
                [106.1021987, 20.9971409],
                [106.1043664, 20.9980372],
                [106.1051702, 20.9975142],
                [106.1063079, 20.9988116],
                [106.1075988, 20.9993064],
                [106.1082429, 20.9990976],
                [106.1093808, 20.9998743],
                [106.1103398, 20.9990101],
                [106.1118438, 20.9975601],
                [106.1124218, 20.9969821],
                [106.1127695, 20.9967337],
                [106.1132349, 20.996676],
                [106.1158073, 20.9960245],
                [106.1158304, 20.9936537],
                [106.1157911, 20.9913203],
                [106.1157149, 20.9898905],
                [106.1157455, 20.98988],
                [106.1156264, 20.9894437],
                [106.1153391, 20.9889763],
                [106.1150228, 20.9885369],
                [106.1149022, 20.9881148],
                [106.1148582, 20.9874531],
                [106.1148808, 20.9865427],
                [106.1150251, 20.9848868],
                [106.1152291, 20.9833775],
                [106.1160509, 20.9833721],
                [106.1171959, 20.9834106],
                [106.1179006, 20.9834428],
                [106.1180405, 20.983828],
                [106.1188233, 20.9838412],
                [106.1189834, 20.9843182],
                [106.1195307, 20.9842411],
                [106.1197887, 20.9847175],
                [106.120008, 20.9852678],
                [106.1207115, 20.9851344],
                [106.1221674, 20.9848765],
                [106.123594, 20.984628],
                [106.1248637, 20.9843255],
                [106.1261725, 20.9840226],
                [106.1273543, 20.9837454],
                [106.1275774, 20.9843301],
                [106.1279499, 20.9850716],
                [106.1282244, 20.9856323],
                [106.1285573, 20.9862471],
                [106.1288508, 20.9867713],
                [106.1291335, 20.9871233],
                [106.129387, 20.9874482],
                [106.1294463, 20.9876292],
                [106.1293906, 20.9879381],
                [106.1292382, 20.9882113],
                [106.1289712, 20.9886485],
                [106.1288976, 20.989139],
                [106.1289012, 20.9896107],
                [106.1291671, 20.9903075],
                [106.1293664, 20.9911409],
                [106.1294469, 20.9915759],
                [106.1293922, 20.9919936],
                [106.1292218, 20.9924483],
                [106.1290413, 20.9928396],
                [106.1290036, 20.992967],
                [106.1292936, 20.9930194],
                [106.1297382, 20.9930708],
                [106.1301065, 20.99325],
                [106.1304945, 20.9935014],
                [106.1308058, 20.9938079],
                [106.1310983, 20.9941869],
                [106.131294, 20.9945303],
                [106.1315687, 20.9951092],
                [106.1317462, 20.9955979],
                [106.13205, 20.9962037],
                [106.132245, 20.9967695],
                [106.1323084, 20.9971819],
                [106.1323636, 20.9979304],
                [106.1319908, 20.9991456],
                [106.1378646, 21.0012428],
                [106.1391494, 20.9990098],
                [106.1393598, 20.9986148],
                [106.1398416, 20.9985844],
                [106.1408438, 20.9983055],
                [106.1414216, 20.9980838],
                [106.1423653, 20.9977327],
                [106.1428274, 20.9975482],
                [106.1427744, 20.9969224],
                [106.1427896, 20.9963777],
                [106.1431743, 20.9961755],
                [106.1434432, 20.9959922],
                [106.1434985, 20.9956289],
                [106.1435511, 20.9949207],
                [106.1435882, 20.9947209],
                [106.1436937, 20.9946385],
                [106.1443884, 20.9945612],
                [106.1452765, 20.994519],
                [106.1457976, 20.994461],
                [106.146145, 20.9944405],
                [106.1468943, 20.9939092],
                [106.1478352, 20.993195],
                [106.1484971, 20.9926188],
                [106.1490918, 20.9920885],
                [106.1492057, 20.9918332],
                [106.1492216, 20.9913796],
                [106.1493533, 20.9909251],
                [106.149638, 20.9902702],
                [106.1499812, 20.9896873],
                [106.1503829, 20.9891585],
                [106.1506706, 20.9888845],
                [106.150998, 20.9887552],
                [106.1517702, 20.9886957],
                [106.1526194, 20.9885991],
                [106.1534304, 20.9885573],
                [106.1543093, 20.9885633],
                [106.154889, 20.9885774],
                [106.1554491, 20.9885737],
                [106.155797, 20.9885895],
                [106.1562986, 20.9885135],
                [106.1567221, 20.9883291],
                [106.1570691, 20.988236],
                [106.1572441, 20.98838],
                [106.1575355, 20.9885959],
                [106.1577492, 20.9887578],
                [106.1579398, 20.9890699],
                [106.1580771, 20.9893412],
                [106.158166, 20.9895946],
                [106.1582448, 20.9897936],
                [106.1583621, 20.9899652],
                [106.158508, 20.9900822],
                [106.1577459, 20.9903627],
                [106.1590112, 20.9901966],
                [106.1592817, 20.9902129],
                [106.1594265, 20.9901847],
                [106.1599749, 20.9905803],
                [106.1600802, 20.989772],
                [106.1603788, 20.989652],
                [106.1607357, 20.9895951],
                [106.1611605, 20.989556],
                [106.1615228, 20.9895504],
                [106.161735, 20.9895125],
                [106.1620719, 20.9893651],
                [106.1625716, 20.9890166],
                [106.1629271, 20.9887692],
                [106.1633108, 20.9884216],
                [106.163916, 20.9879908],
                [106.1643578, 20.9876428],
                [106.1648768, 20.9873124],
                [106.1651501, 20.9876556],
                [106.1654432, 20.9880893],
                [106.1657161, 20.9884143],
                [106.1660476, 20.9887933],
                [106.1665286, 20.9885357],
                [106.1671053, 20.9881506],
                [106.1674509, 20.9878758],
                [106.167919, 20.9874825],
                [106.1679882, 20.9874244],
                [106.1683759, 20.9871346],
                [106.1686375, 20.986855],
                [106.168883, 20.9865004],
                [106.1695343, 20.9848733],
                [106.1687022, 20.9833582],
                [106.1676129, 20.9815913],
                [106.1649981, 20.9775556],
                [106.1644421, 20.9768603],
                [106.1637986, 20.9761202],
                [106.1632723, 20.9755245],
                [106.1638286, 20.9749941],
                [106.1643075, 20.9744642],
                [106.1648724, 20.9737975],
                [106.1652169, 20.9733774],
                [106.165965, 20.9726642],
                [106.1663105, 20.9723712],
                [106.1667905, 20.9719684],
                [106.1670202, 20.9716944],
                [106.1671728, 20.9714391],
                [106.1671807, 20.9712122],
                [106.167179, 20.9709942],
                [106.1671386, 20.9707584],
                [106.1670206, 20.970505],
                [106.1668837, 20.970288],
                [106.1667658, 20.9700346],
                [106.1667439, 20.9697079],
                [106.1668388, 20.9694893],
                [106.1671463, 20.9692693],
                [106.1675319, 20.9691577],
                [106.1677006, 20.9691433],
                [106.1679953, 20.9691182],
                [106.1684597, 20.9692058],
                [106.1688675, 20.9694754],
                [106.1690821, 20.9697464],
                [106.1692778, 20.9700537],
                [106.1695126, 20.9704335],
                [106.1697659, 20.9707041],
                [106.169997, 20.9709685],
                [106.1700974, 20.9710832],
                [106.1703701, 20.9713719],
                [106.1706818, 20.9716966],
                [106.1710122, 20.9719485],
                [106.1715378, 20.9724352],
                [106.1719552, 20.9726956],
                [106.1723823, 20.9729469],
                [106.1727848, 20.9731668],
                [106.1731344, 20.9734003],
                [106.1733486, 20.9735895],
                [106.1734853, 20.9737792],
                [106.1736027, 20.973969],
                [106.1739143, 20.9742663],
                [106.1743033, 20.9745812],
                [106.1747211, 20.9748779],
                [106.1749103, 20.9750068],
                [106.1750902, 20.9751295],
                [106.1754734, 20.9753235],
                [106.175842, 20.9754844],
                [106.1760649, 20.9755737],
                [106.1763164, 20.9756082],
                [106.1765095, 20.9755888],
                [106.1767116, 20.9754875],
                [106.1769036, 20.9753227],
                [106.1770868, 20.9752579],
                [106.1773867, 20.9753103],
                [106.1775757, 20.9753861],
                [106.1777609, 20.9755847],
                [106.1778979, 20.9758108],
                [106.178093, 20.9760274],
                [106.1783649, 20.9762162],
                [106.1786851, 20.9763865],
                [106.1789572, 20.9765753],
                [106.1791715, 20.9767918],
                [106.1794685, 20.9770849],
                [106.1797017, 20.9772468],
                [106.1799734, 20.9773811],
                [106.1802831, 20.9774517],
                [106.1806217, 20.9775038],
                [106.1808929, 20.9775746],
                [106.1811645, 20.9776999],
                [106.1814269, 20.9778978],
                [106.1816272, 20.9781734],
                [106.1817639, 20.9783631],
                [106.181968, 20.978498],
                [106.1822879, 20.978632],
                [106.1826266, 20.9786931],
                [106.1829166, 20.9787093],
                [106.1833803, 20.9786789],
                [106.1839111, 20.9786025],
                [106.1845921, 20.9785659],
                [106.1850361, 20.9785085],
                [106.1854227, 20.9785057],
                [106.1857712, 20.978585],
                [106.1860529, 20.9787647],
                [106.1862819, 20.9790128],
                [106.1864573, 20.9791933],
                [106.1866807, 20.9793369],
                [106.1869137, 20.9794625],
                [106.1870978, 20.979543],
                [106.1872729, 20.9796871],
                [106.1873614, 20.9798862],
                [106.1873828, 20.9801404],
                [106.1873806, 20.9804718],
                [106.1873824, 20.980708],
                [106.1874223, 20.9808529],
                [106.1875292, 20.980934],
                [106.1877224, 20.9809326],
                [106.1879728, 20.9808219],
                [106.1882035, 20.9806659],
                [106.1885108, 20.9804276],
                [106.1888855, 20.9801435],
                [106.1892025, 20.9799142],
                [106.1895579, 20.9796212],
                [106.1898222, 20.9794605],
                [106.1902456, 20.9792304],
                [106.1905827, 20.9790827],
                [106.1908913, 20.9789988],
                [106.1911905, 20.9789604],
                [106.1915383, 20.9789398],
                [106.1918575, 20.9789739],
                [106.192327, 20.9790841],
                [106.1928789, 20.9792165],
                [106.1936827, 20.979447],
                [106.1940994, 20.9795984],
                [106.1946079, 20.9797357],
                [106.1951891, 20.9799041],
                [106.1956638, 20.9800552],
                [106.1961191, 20.9801973],
                [106.1966422, 20.9803571],
                [106.1972187, 20.9805574],
                [106.1976062, 20.9806727],
                [106.1980909, 20.98086],
                [106.1985272, 20.9810477],
                [106.1988959, 20.9812357],
                [106.1993955, 20.9814638],
                [106.1997548, 20.9816974],
                [106.200114, 20.9818947],
                [106.2004829, 20.9821099],
                [106.2007255, 20.9822445],
                [106.2010936, 20.9823418],
                [106.2014078, 20.9823532],
                [106.2016008, 20.9823246],
                [106.2018135, 20.9823321],
                [106.2020564, 20.982503],
                [106.2024277, 20.983018],
                [106.2026129, 20.9832165],
                [106.2027599, 20.9833396],
                [106.2028172, 20.9833876],
                [106.2030304, 20.9834497],
                [106.203291, 20.9834206],
                [106.2036723, 20.9833679],
                [106.2040297, 20.9833382],
                [106.2044256, 20.9833081],
                [106.2047978, 20.9833282],
                [106.2051167, 20.983335],
                [106.2055137, 20.983423],
                [106.2058429, 20.9835024],
                [106.2062013, 20.9836088],
                [106.2065406, 20.9837427],
                [106.2068223, 20.9839315],
                [106.2072203, 20.9841466],
                [106.2075115, 20.9842988],
                [106.207841, 20.9844146],
                [106.2084461, 20.9845511],
                [106.2088912, 20.9846296],
                [106.2092976, 20.9846904],
                [106.2097817, 20.9848141],
                [106.2100436, 20.9849302],
                [106.2103114, 20.9851872],
                [106.210555, 20.9854306],
                [106.2107795, 20.9857105],
                [106.2110426, 20.9859811],
                [106.2113339, 20.9861516],
                [106.2116921, 20.9862307],
                [106.2121566, 20.9863092],
                [106.2124515, 20.9863208],
                [106.2129059, 20.9863629],
                [106.2131485, 20.9864792],
                [106.2133919, 20.9866954],
                [106.2136165, 20.9869935],
                [106.2138706, 20.9873368],
                [106.2141252, 20.9877527],
                [106.2144233, 20.9881638],
                [106.2147067, 20.9885613],
                [106.214882, 20.9887236],
                [106.2150955, 20.9888311],
                [106.2152695, 20.988848],
                [106.2155591, 20.9887914],
                [106.215829, 20.9887168],
                [106.2161285, 20.9887055],
                [106.2165055, 20.9887211],
                [106.2167818, 20.9888144],
                [106.2171505, 20.9890116],
                [106.2175684, 20.9892992],
                [106.2178696, 20.9894968],
                [106.2181898, 20.989649],
                [106.2185187, 20.9897011],
                [106.2188666, 20.9896986],
                [106.2194224, 20.9897173],
                [106.2198672, 20.9897414],
                [106.2202541, 20.9897839],
                [106.2205832, 20.9898543],
                [106.220816, 20.9899525],
                [106.2210396, 20.9901235],
                [106.2211239, 20.9903816],
                [106.2210289, 20.9905912],
                [106.2208668, 20.9908466],
                [106.2206561, 20.9910843],
                [106.220503, 20.991276],
                [106.2203604, 20.9915677],
                [106.220347, 20.9917086],
                [106.2203875, 20.9919262],
                [106.2205153, 20.9921886],
                [106.2206628, 20.9924964],
                [106.2207621, 20.9928226],
                [106.2208323, 20.99314],
                [106.2208301, 20.9934805],
                [106.2207745, 20.9937624],
                [106.2206515, 20.9940902],
                [106.2204709, 20.9944548],
                [106.2202711, 20.9948467],
                [106.2202395, 20.9951239],
                [106.2202512, 20.995369],
                [106.2203203, 20.995541],
                [106.2204178, 20.9956494],
                [106.2206409, 20.9957568],
                [106.2209316, 20.9958455],
                [106.2212024, 20.9958708],
                [106.2214827, 20.9958778],
                [106.2217046, 20.9958309],
                [106.2219937, 20.9957289],
                [106.222302, 20.9956086],
                [106.2226293, 20.9954428],
                [106.2230004, 20.9953266],
                [106.223309, 20.9952517],
                [106.2235696, 20.9952135],
                [106.2238494, 20.995157],
                [106.2241004, 20.9951098],
                [106.2243216, 20.9949901],
                [106.2245328, 20.9948161],
                [106.2248785, 20.9945321],
                [106.2252098, 20.9942891],
                [106.2255278, 20.9941687],
                [106.2258851, 20.9941389],
                [106.2264842, 20.9941164],
                [106.2269671, 20.9940858],
                [106.2273344, 20.9940921],
                [106.2276679, 20.9941124],
                [106.2279777, 20.9941738],
                [106.2282202, 20.9942719],
                [106.2284726, 20.9944154],
                [106.2287545, 20.9946132],
                [106.228968, 20.9947115],
                [106.2291418, 20.9946921],
                [106.2292569, 20.9946004],
                [106.2294003, 20.9943997],
                [106.2295043, 20.9941173],
                [106.2295925, 20.9936853],
                [106.229687, 20.9934122],
                [106.2297532, 20.9932392],
                [106.2298868, 20.9930203],
                [106.2300497, 20.9928556],
                [106.2302133, 20.9927818],
                [106.2304447, 20.9927166],
                [106.2308265, 20.9927274],
                [106.231098, 20.9928344],
                [106.2313993, 20.993032],
                [106.2316822, 20.993366],
                [106.2318488, 20.9936463],
                [106.231977, 20.9939542],
                [106.2320471, 20.9942534],
                [106.2321721, 20.9947565],
                [106.232223, 20.9950739],
                [106.2323014, 20.9952096],
                [106.2324957, 20.9953353],
                [106.2327673, 20.9954605],
                [106.2330382, 20.9954949],
                [106.2333958, 20.9954922],
                [106.2337142, 20.9954355],
                [106.234032, 20.9952878],
                [106.2343587, 20.9950585],
                [106.2346518, 20.9948702],
                [106.2350177, 20.9946949],
                [106.2353555, 20.9946471],
                [106.2357804, 20.9946077],
                [106.2361183, 20.9945598],
                [106.2363781, 20.9944217],
                [106.2364546, 20.9943304],
                [106.2365781, 20.9940661],
                [106.2366396, 20.9939068],
                [106.2366576, 20.993734],
                [106.2366849, 20.9935341],
                [106.2367793, 20.9932609],
                [106.2369038, 20.9931238],
                [106.2371159, 20.9930588],
                [106.2374161, 20.9931474],
                [106.2377174, 20.993345],
                [106.2379312, 20.9934978],
                [106.2381737, 20.993605],
                [106.2383478, 20.9936128],
                [106.2385981, 20.9934929],
                [106.2388287, 20.9933368],
                [106.2391121, 20.9931305],
                [106.2393521, 20.992929],
                [106.2395148, 20.9927462],
                [106.2397731, 20.9924264],
                [106.2399734, 20.9920981],
                [106.2401258, 20.9918335],
                [106.2402934, 20.9916553],
                [106.2404948, 20.991463],
                [106.2406584, 20.9913802],
                [106.2408418, 20.9913607],
                [106.2411126, 20.9913768],
                [106.2414037, 20.9915291],
                [106.2415506, 20.9917551],
                [106.2417566, 20.9921169],
                [106.2419512, 20.992288],
                [106.2422231, 20.9924494],
                [106.2425328, 20.9924926],
                [106.2429046, 20.9924671],
                [106.2431458, 20.992411],
                [106.2433673, 20.9923185],
                [106.2436174, 20.9921804],
                [106.2438577, 20.9920242],
                [106.2441259, 20.9917317],
                [106.2443653, 20.9914575],
                [106.2446997, 20.991001],
                [106.2448619, 20.9907556],
                [106.2449834, 20.9914265],
                [106.2451651, 20.9923511],
                [106.2453075, 20.9932033],
                [106.2455473, 20.9941456],
                [106.24575, 20.9952697],
                [106.2459834, 20.996606],
                [106.2462443, 20.9977529],
                [106.2466547, 20.9976749],
                [106.2470629, 20.9975634],
                [106.2475074, 20.9973785],
                [106.2478927, 20.997222],
                [106.2482476, 20.9969959],
                [106.2485727, 20.996742],
                [106.2488534, 20.9965443],
                [106.2491494, 20.9963745],
                [106.2493864, 20.996261],
                [106.249713, 20.9962167],
                [106.2500475, 20.9962072],
                [106.2506624, 20.9963155],
                [106.2513352, 20.9966538],
                [106.2517318, 20.9967366],
                [106.2522514, 20.9966719],
                [106.2525167, 20.9966223],
                [106.2529976, 20.9963976],
                [106.2532112, 20.9961523],
                [106.2534277, 20.9955801],
                [106.2537077, 20.995405],
                [106.2550425, 20.9945375],
                [106.2568224, 20.9929658],
                [106.2576977, 20.9928962],
                [106.2578967, 20.9928086],
                [106.2580323, 20.9926831],
                [106.2580839, 20.9925774],
                [106.2581457, 20.9924239],
                [106.2581549, 20.9922706],
                [106.2581613, 20.9917824],
                [106.258189, 20.9913228],
                [106.2583124, 20.9909868],
                [106.2584783, 20.9907272],
                [106.258697, 20.9904767],
                [106.2589058, 20.9903124],
                [106.2592723, 20.9901469],
                [106.2596396, 20.9900676],
                [106.2598812, 20.9900563],
                [106.2601546, 20.9900734],
                [106.2604917, 20.9901571],
                [106.2609344, 20.990307],
                [106.2613451, 20.9903997],
                [106.2615762, 20.9903883],
                [106.2617965, 20.9903197],
                [106.2619844, 20.9901747],
                [106.2621508, 20.989963],
                [106.2623265, 20.9896074],
                [106.2625437, 20.9891846],
                [106.2627514, 20.9888864],
                [106.2629703, 20.9886742],
                [106.263305, 20.9884802],
                [106.2635253, 20.9884117],
                [106.2637875, 20.9883618],
                [106.2642493, 20.9882723],
                [106.2647627, 20.9881776],
                [106.2658394, 20.98792],
                [106.2664557, 20.9878998],
                [106.266833, 20.9879594],
                [106.2672281, 20.9881125],
                [106.2679169, 20.9885441],
                [106.2685544, 20.9890072],
                [106.2693492, 20.9898434],
                [106.2701453, 20.99082],
                [106.2708702, 20.9914852],
                [106.2712834, 20.9917473],
                [106.2716239, 20.9918582],
                [106.2722849, 20.9919002],
                [106.2728186, 20.9918904],
                [106.2725391, 20.9929976],
                [106.2723956, 20.994244],
                [106.2721826, 20.9963854],
                [106.2719351, 20.9990082],
                [106.2719983, 20.9995006],
                [106.2720801, 21.0002121],
                [106.2723919, 21.0013126],
                [106.2729197, 21.0026181],
                [106.2739426, 21.0043334],
                [106.2752553, 21.0060235],
                [106.2761453, 21.0069128],
                [106.2771361, 21.0078013],
                [106.2786338, 21.0090077],
                [106.2795716, 21.009598],
                [106.2799199, 21.0098297],
                [106.2850357, 21.0132325],
                [106.2872294, 21.0147321],
                [106.2898913, 21.016435],
                [106.292401, 21.018024],
                [106.2937601, 21.0192083],
                [106.2945786, 21.0205575],
                [106.2950693, 21.0219091],
                [106.2951741, 21.0223679],
                [106.2951672, 21.0230341],
                [106.2950557, 21.024712],
                [106.2946038, 21.027261],
                [106.2944882, 21.0276109],
                [106.2938272, 21.0299134],
                [106.2929895, 21.032171],
                [106.2927508, 21.033735],
                [106.2927452, 21.0345391],
                [106.2927763, 21.0352281],
                [106.293105, 21.0367878],
                [106.2933132, 21.0375442],
                [106.2936233, 21.0384148],
                [106.2940601, 21.0393535],
                [106.2949155, 21.0405875],
                [106.2961137, 21.0420946],
                [106.2981408, 21.044675],
                [106.2997197, 21.0464548],
                [106.3045147, 21.0512195],
                [106.3076778, 21.053906],
                [106.3098674, 21.0562783],
                [106.3119298, 21.0585367],
                [106.3114971, 21.0594361],
                [106.3107463, 21.0605846],
                [106.3092472, 21.0631674],
                [106.3082495, 21.0644385],
                [106.3070946, 21.0664691],
                [106.306002, 21.0684302],
                [106.3053387, 21.0694619],
                [106.3040735, 21.0719204],
                [106.3033048, 21.0744544],
                [106.3028939, 21.0765022],
                [106.3027532, 21.0777209],
                [106.3026021, 21.0791922],
                [106.3025884, 21.0805248],
                [106.302475, 21.0834202],
                [106.3024433, 21.0855799],
                [106.3021695, 21.0874429],
                [106.3020242, 21.0881331],
                [106.3018286, 21.0888467],
                [106.3011518, 21.0907817],
                [106.3005119, 21.0926244],
                [106.2994161, 21.0956882],
                [106.2985509, 21.0977165],
                [106.2975272, 21.1003892],
                [106.2963334, 21.1037983],
                [106.2957923, 21.1054105],
                [106.2955012, 21.1067452],
                [106.2951578, 21.1083109],
                [106.2951392, 21.1090218],
                [106.2952204, 21.1107217],
                [106.2954131, 21.112581],
                [106.2959634, 21.114943],
                [106.2963522, 21.1161346],
                [106.2967406, 21.1172803],
                [106.2971147, 21.1182382],
                [106.2978321, 21.1194951],
                [106.2983455, 21.1205014],
                [106.2992819, 21.1219509],
                [106.2990063, 21.1223718],
                [106.2980101, 21.1233739],
                [106.2967374, 21.1246921],
                [106.294747, 21.126958],
                [106.2924743, 21.1288596],
                [106.2899723, 21.1300825],
                [106.2870208, 21.1309947],
                [106.2848192, 21.1314564],
                [106.2831483, 21.13194],
                [106.2811383, 21.1319553],
                [106.2777847, 21.1315619],
                [106.2763289, 21.1311018],
                [106.275323, 21.1310047],
                [106.2747093, 21.1310616],
                [106.2741533, 21.1313276],
                [106.2735427, 21.1317509],
                [106.2734321, 21.131861],
                [106.2723246, 21.1329638],
                [106.2714996, 21.1344356],
                [106.2704588, 21.1367987],
                [106.2693903, 21.1391882],
                [106.2686277, 21.1414446],
                [106.2673644, 21.143914],
                [106.2659277, 21.1457044],
                [106.2651907, 21.1463031],
                [106.264042, 21.1472363],
                [106.2622113, 21.1486632],
                [106.2595693, 21.1499129],
                [106.257676, 21.150555],
                [106.2567905, 21.1515037],
                [106.2560255, 21.1534984],
                [106.2557675, 21.1560127],
                [106.2557786, 21.157321],
                [106.255728, 21.1579495],
                [106.2557329, 21.1585252],
                [106.2556818, 21.1591014],
                [106.2557417, 21.1595719],
                [106.2560007, 21.160486],
                [106.2569629, 21.1619967],
                [106.2580363, 21.1634543],
                [106.2580953, 21.1638202],
                [106.2581564, 21.1644478],
                [106.2581595, 21.1648142],
                [106.2581068, 21.165181],
                [106.2579441, 21.165758],
                [106.2577256, 21.1663353],
                [106.2573395, 21.1669139],
                [106.2566232, 21.1680707],
                [106.2550186, 21.1698622],
                [106.2544105, 21.1705995],
                [106.2535209, 21.1710772],
                [106.2518762, 21.1714295],
                [106.2500886, 21.1713905],
                [106.2471524, 21.1706674],
                [106.2467865, 21.1705774],
                [106.2420782, 21.1685708],
                [106.2385946, 21.1660839],
                [106.2347678, 21.1626629],
                [106.2329939, 21.1607698],
                [106.2309242, 21.158812],
                [106.2292505, 21.157476],
                [106.2277386, 21.1565509],
                [106.2256734, 21.1556762],
                [106.2226851, 21.155021],
                [106.2195619, 21.1546905],
                [106.2165493, 21.1545464],
                [106.2135787, 21.1545813],
                [106.2106369, 21.1547501],
                [106.2087543, 21.1549337],
                [106.2077898, 21.1555025],
                [106.2075242, 21.1565052],
                [106.2078586, 21.1575926],
                [106.2088563, 21.1588623],
                [106.2123771, 21.1624236],
                [106.2148975, 21.1653346],
                [106.2178915, 21.1682233],
                [106.220482, 21.1708216],
                [106.2218349, 21.1723297],
                [106.2220071, 21.1729042],
                [106.2220651, 21.1733129],
                [106.2220664, 21.1733225],
                [106.2219036, 21.1738995],
                [106.2214619, 21.1745308],
                [106.2208532, 21.1752155],
                [106.2201297, 21.1755348],
                [106.2195733, 21.1758006],
                [106.218962, 21.1761713],
                [106.2181255, 21.1763344],
                [106.215723, 21.176247],
                [106.2120347, 21.1760118],
                [106.2113273, 21.1760785],
                [106.2096348, 21.1762383],
                [106.2065432, 21.1772811],
                [106.2034833, 21.1787684],
                [106.1997572, 21.1807839],
                [106.1976418, 21.1816886],
                [106.1961906, 21.1818036],
                [106.1940668, 21.1816616],
                [106.1913829, 21.1813141],
                [106.1889485, 21.1807294],
                [106.1877709, 21.1801619],
                [106.1874316, 21.1796409],
                [106.1871486, 21.1791718],
                [106.1869776, 21.1787542],
                [106.1868614, 21.1781793],
                [106.1865775, 21.1776055],
                [106.1862945, 21.1771365],
                [106.1858993, 21.1766158],
                [106.1855613, 21.1762519],
                [106.1849999, 21.1758894],
                [106.1845518, 21.1757355],
                [106.1834339, 21.1756387],
                [106.1819826, 21.1757535],
                [106.1806997, 21.1759718],
                [106.1788611, 21.1765604],
                [106.1776766, 21.1771562],
                [106.1758012, 21.1780996],
                [106.1732982, 21.1794255],
                [106.1715472, 21.1805106],
                [106.1706618, 21.1815636],
                [106.1698335, 21.1827732],
                [106.1685685, 21.1852944],
                [106.1682999, 21.1857702],
                [106.1670259, 21.1880268],
                [106.1654233, 21.1902362],
                [106.1640652, 21.1915802],
                [106.1629592, 21.1930011],
                [106.1610237, 21.1960395],
                [106.1590409, 21.199152],
                [106.1564195, 21.2032788],
                [106.1543778, 21.2065379],
                [106.1525493, 21.208487],
                [106.1516065, 21.2093833],
                [106.1504109, 21.2102144],
                [106.1491059, 21.2111276],
                [106.1468247, 21.212347],
                [106.1422838, 21.2139742],
                [106.1407803, 21.2146124],
                [106.1365436, 21.2158449],
                [106.1347586, 21.2162233],
                [106.132972, 21.2163924],
                [106.1323575, 21.2163965],
                [106.1312944, 21.2161942],
                [106.1305086, 21.2157284],
                [106.1284315, 21.2144338],
                [106.1281537, 21.2141545],
                [106.1265174, 21.2125098],
                [106.1249935, 21.2104787],
                [106.1244285, 21.209645],
                [106.1243365, 21.2085726],
                [106.1243844, 21.2075254],
                [106.1245382, 21.2056924],
                [106.1249067, 21.2027065],
                [106.1249007, 21.2019214],
                [106.1248991, 21.201712],
                [106.1248381, 21.201032],
                [106.124667, 21.200562],
                [106.1243841, 21.2000929],
                [106.1239317, 21.1993631],
                [106.1235371, 21.1988947],
                [106.1231991, 21.1985305],
                [106.1224957, 21.1981299],
                [106.1216417, 21.1979024],
                [106.1214341, 21.1978356],
                [106.1199282, 21.1981596],
                [106.1180915, 21.1990616],
                [106.11503, 21.2005474],
                [106.1130226, 21.2010318],
                [106.1100079, 21.2013133],
                [106.1071597, 21.2014367],
                [106.1046438, 21.2017756],
                [106.1032809, 21.2019593],
                [106.1016077, 21.2023366],
                [106.0968119, 21.2035194],
                [106.0928517, 21.2043825],
                [106.0904489, 21.2054291],
                [106.09029, 21.2054983],
                [106.0882861, 21.2065056],
                [106.0867851, 21.2075099],
                [106.0861197, 21.2081946],
                [106.085707, 21.2090609],
                [106.085658, 21.2093232],
                [106.0855994, 21.2096373],
                [106.0856051, 21.2104225],
                [106.0856649, 21.2109455],
                [106.0860007, 21.2113498],
                [106.0867571, 21.212467],
                [106.0870768, 21.2135492],
                [106.0873815, 21.2142634],
                [106.0872967, 21.2148065],
                [106.0869262, 21.2153342],
                [106.0868175, 21.2157536],
                [106.0865967, 21.2161214],
                [106.0865577, 21.2162081],
                [106.0864317, 21.2164888],
                [106.0860441, 21.2169625],
                [106.085436, 21.2178562],
                [106.0842697, 21.2188058],
                [106.0830459, 21.2195465],
                [106.0817909, 21.2198424],
                [106.0808973, 21.2199005],
                [106.0801703, 21.2198005],
                [106.0791075, 21.2196502],
                [106.07849, 21.2192354],
                [106.0767484, 21.217938],
                [106.07518, 21.2173723],
                [106.0741184, 21.217379],
                [106.0736171, 21.2175916],
                [106.0730603, 21.2178568],
                [106.0722276, 21.2185949],
                [106.0706771, 21.2205414],
                [106.0689014, 21.2222276],
                [106.0684663, 21.2225445],
                [106.0672339, 21.2234421],
                [106.0645332, 21.2247415],
                [106.0615244, 21.225912],
                [106.0574549, 21.2272462],
                [106.055055, 21.2276275],
                [106.0536566, 21.2274269],
                [106.0532651, 21.227377],
                [106.0528729, 21.2272224],
                [106.0523682, 21.2269639],
                [106.0517498, 21.2264443],
                [106.0501758, 21.2250932],
                [106.0495567, 21.2244689],
                [106.0491615, 21.2238956],
                [106.0487652, 21.2231652],
                [106.0486106, 21.2228537],
                [106.0478584, 21.2213389],
                [106.046926, 21.2198529],
                [106.0450112, 21.2177187],
                [106.0429848, 21.2155852],
                [106.0426451, 21.2149592],
                [106.0424154, 21.2140707],
                [106.042408, 21.2130239],
                [106.042568, 21.2119237],
                [106.0427275, 21.2107712],
                [106.0428896, 21.2099851],
                [106.0434986, 21.2091961],
                [106.0442734, 21.2081445],
                [106.0458248, 21.2063029],
                [106.0465999, 21.2053035],
                [106.0472074, 21.2043053],
                [106.0471485, 21.2038869],
                [106.0470339, 21.2034689],
                [106.0464697, 21.2026872],
                [106.0461885, 21.2024272],
                [106.0456273, 21.2020643],
                [106.0451227, 21.2018057],
                [106.043969, 21.2014846],
                [106.0430276, 21.2014078],
                [106.0424281, 21.2015126],
                [106.0416085, 21.2018452],
                [106.0410577, 21.2021465],
                [106.0406024, 21.202514],
                [106.0400032, 21.203325],
                [106.0392263, 21.2048001],
                [106.0381373, 21.2066224],
                [106.0365982, 21.209129],
                [106.0363224, 21.2095658],
                [106.0358417, 21.2104448],
                [106.0351358, 21.2120771],
                [106.0348428, 21.213777],
                [106.034577, 21.2157154],
                [106.0345284, 21.2167626],
                [106.03454, 21.2184375],
                [106.0345824, 21.2205048],
                [106.0350619, 21.2251604],
                [106.0350837, 21.228298],
                [106.0350863, 21.2286672],
                [106.0349793, 21.2293484],
                [106.0349271, 21.2298721],
                [106.0342979, 21.2317864],
                [106.033528, 21.2335708],
                [106.0334235, 21.2346183],
                [106.0333717, 21.2351944],
                [106.0333216, 21.2360322],
                [106.0333253, 21.2365556],
                [106.0333833, 21.2368693],
                [106.0336145, 21.2379671],
                [106.0337299, 21.2384899],
                [106.0341835, 21.2394293],
                [106.0352046, 21.2416214],
                [106.036029, 21.2436315],
                [106.0361459, 21.2443636],
                [106.0361506, 21.2450441],
                [106.035986, 21.2454637],
                [106.035765, 21.2458316],
                [106.0352657, 21.2463581],
                [106.0341532, 21.2470977],
                [106.0319836, 21.2485242],
                [106.0302056, 21.2500007],
                [106.0292946, 21.2506813],
                [106.0284239, 21.2513316],
                [106.0268142, 21.2525339],
                [106.0241152, 21.2541991],
                [106.0217172, 21.2549464],
                [106.0165283, 21.2561004],
                [106.013052, 21.2577629],
                [106.010928, 21.2584175],
                [106.0083099, 21.2589547],
                [106.0061239, 21.2589398],
                [106.0034392, 21.2586418],
                [106.003102, 21.2586311],
                [106.0020416, 21.2585977],
                [105.9997524, 21.2589254],
                [105.9985826, 21.2595081],
                [105.9980284, 21.2601918],
                [105.9963128, 21.2627145],
                [105.9957029, 21.2634509],
                [105.9953697, 21.2637669],
                [105.9944231, 21.2642959],
                [105.9940878, 21.2642979],
                [105.9936958, 21.2641956],
                [105.9933591, 21.2639882],
                [105.9930775, 21.2636758],
                [105.9917749, 21.2611185],
                [105.9905324, 21.2591892],
                [105.9891241, 21.2575224],
                [105.9875193, 21.255726],
                [105.9871225, 21.2548908],
                [105.9869503, 21.2542114],
                [105.9869392, 21.2525364],
                [105.9870974, 21.2511223],
                [105.9871949, 21.2487576],
                [105.9892909, 21.2446006],
                [105.9909436, 21.2424726],
                [105.9916162, 21.2408552],
                [105.9915942, 21.239245],
                [105.9912956, 21.2375368],
                [105.9907419, 21.2363955],
                [105.9898779, 21.2354312],
                [105.9887783, 21.2349219],
                [105.9872663, 21.2345283],
                [105.9837725, 21.2336356],
                [105.9822666, 21.232925],
                [105.9810046, 21.2319464],
                [105.9804457, 21.2308754],
                [105.9796838, 21.2288137],
                [105.9790924, 21.2261275],
                [105.97865, 21.2244239],
                [105.9779541, 21.223577],
                [105.9764756, 21.2230616],
                [105.9748703, 21.2231569],
                [105.9715227, 21.2239678],
                [105.9691819, 21.2248711],
                [105.9680126, 21.2255059],
                [105.9672369, 21.2265048],
                [105.9669612, 21.2270822],
                [105.966578, 21.2282883],
                [105.9661923, 21.229128],
                [105.9658042, 21.2296014],
                [105.9653596, 21.2299703],
                [105.9648028, 21.2302876],
                [105.9635191, 21.2305043],
                [105.9613958, 21.2305164],
                [105.9608918, 21.2303623],
                [105.9603294, 21.2297896],
                [105.9593114, 21.227911],
                [105.9585806, 21.2272347],
                [105.9582443, 21.2270796],
                [105.957965, 21.2270812],
                [105.9574072, 21.2272414],
                [105.9570174, 21.227453],
                [105.9567401, 21.2277686],
                [105.9562985, 21.2286087],
                [105.9547619, 21.2329619],
                [105.9540693, 21.2338818],
                [105.9530123, 21.2346206],
                [105.9522324, 21.2349915],
                [105.9514511, 21.2351529],
                [105.9505574, 21.2352103],
                [105.9497185, 21.2351103],
                [105.9485428, 21.2347505],
                [105.9460761, 21.2335081],
                [105.9441167, 21.2329433],
                [105.9433337, 21.232843],
                [105.9420495, 21.2330072],
                [105.9409905, 21.2334318],
                [105.9398782, 21.2342755],
                [105.9382118, 21.2358551],
                [105.9368208, 21.2368051],
                [105.9362666, 21.237541],
                [105.9359346, 21.2380663],
                [105.9358249, 21.2383809],
                [105.9357308, 21.2391678],
                [105.9359035, 21.2399521],
                [105.9363918, 21.2408767],
                [105.9378728, 21.2432584],
                [105.9374609, 21.2438384],
                [105.9371883, 21.2441914],
                [105.9368903, 21.2444976],
                [105.936617, 21.2447569],
                [105.9363187, 21.2449928],
                [105.9359448, 21.2451589],
                [105.9353956, 21.2452556],
                [105.9347209, 21.2452594],
                [105.9339961, 21.2452399],
                [105.9336706, 21.2451481],
                [105.9339835, 21.2433879],
                [105.9341133, 21.2423131],
                [105.9340872, 21.2419815],
                [105.9323173, 21.2414773],
                [105.9322516, 21.2418825],
                [105.9321332, 21.2427422],
                [105.9316087, 21.2426806],
                [105.9309792, 21.2429137],
                [105.930717, 21.2429258],
                [105.9305599, 21.2423362],
                [105.9299438, 21.2420536],
                [105.9300229, 21.2410219],
                [105.9292497, 21.2404811],
                [105.9290396, 21.2408372],
                [105.9281352, 21.2402842],
                [105.9282403, 21.2400018],
                [105.9282535, 21.2398297],
                [105.9282404, 21.2397069],
                [105.9282143, 21.2395103],
                [105.9281454, 21.2393983],
                [105.9281113, 21.2393451],
                [105.9278589, 21.2389481],
                [105.9276069, 21.2385981],
                [105.9269535, 21.2380159],
                [105.9256243, 21.23725],
                [105.9249227, 21.2369494],
                [105.9234683, 21.2361373],
                [105.9223634, 21.2352765],
                [105.9213354, 21.2347199],
                [105.9212782, 21.2346317],
                [105.9210327, 21.2342529],
                [105.9210284, 21.2335735],
                [105.9210483, 21.2327534],
                [105.9210455, 21.2323082],
                [105.9203525, 21.2318213],
                [105.9200734, 21.2315834],
                [105.9199073, 21.231446],
                [105.9197609, 21.2313248],
                [105.9194487, 21.231139],
                [105.9191376, 21.2311199],
                [105.918849, 21.2311318],
                [105.9183054, 21.2312389],
                [105.9176509, 21.2313571],
                [105.9171632, 21.2315263],
                [105.9167537, 21.2317471],
                [105.9163445, 21.2320202],
                [105.9159124, 21.2321787],
                [105.9135249, 21.2322333],
                [105.9117433, 21.2319327],
                [105.9105805, 21.2320201],
                [105.9099826, 21.2321136],
                [105.9097888, 21.2315194],
                [105.9089973, 21.2310187],
                [105.9066937, 21.2320722],
                [105.9061928, 21.2317389],
                [105.9063255, 21.2314811],
                [105.9054793, 21.2309647],
                [105.9058774, 21.2305541],
                [105.9061266, 21.2301489],
                [105.9062027, 21.2298778],
                [105.9065544, 21.229272],
                [105.9066535, 21.2291257],
                [105.9068405, 21.2288332],
                [105.9070617, 21.2284342],
                [105.9069292, 21.228329],
                [105.906766, 21.2282525],
                [105.9059912, 21.2279368],
                [105.9061444, 21.2274689],
                [105.9057541, 21.22735],
                [105.9043863, 21.2271268],
                [105.9041628, 21.2258883],
                [105.9040968, 21.2251796],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [106.3273346, 11.3436374],
                [106.3273434, 11.3434207],
                [106.327362, 11.3432047],
                [106.3273904, 11.3429898],
                [106.3274287, 11.3427763],
                [106.3274767, 11.3425646],
                [106.3275342, 11.3423553],
                [106.3276013, 11.3421488],
                [106.3276778, 11.3419454],
                [106.3277634, 11.3417455],
                [106.3278581, 11.3415496],
                [106.3279617, 11.3413581],
                [106.3280739, 11.3411713],
                [106.3281945, 11.3409896],
                [106.3283233, 11.3408134],
                [106.32846, 11.3406431],
                [106.3286043, 11.3404789],
                [106.328756, 11.3403212],
                [106.3289148, 11.3401703],
                [106.3290803, 11.3400265],
                [106.3292522, 11.3398902],
                [106.3294301, 11.3397615],
                [106.3296137, 11.3396408],
                [106.3298027, 11.3395282],
                [106.3299966, 11.3394241],
                [106.3301951, 11.3393286],
                [106.3303977, 11.3392419],
                [106.3306041, 11.3391642],
                [106.3308139, 11.3390956],
                [106.3310265, 11.3390363],
                [106.3313763, 11.3389217],
                [106.3317228, 11.338798],
                [106.3320659, 11.3386653],
                [106.3324052, 11.3385237],
                [106.3327405, 11.3383732],
                [106.3330716, 11.3382139],
                [106.3333982, 11.338046],
                [106.3337201, 11.3378697],
                [106.3340371, 11.3376849],
                [106.334349, 11.3374919],
                [106.3346554, 11.3372909],
                [106.3349563, 11.3370818],
                [106.3352514, 11.3368649],
                [106.3355405, 11.3366404],
                [106.3358233, 11.3364084],
                [106.3360997, 11.336169],
                [106.3363695, 11.3359225],
                [106.3366325, 11.335669],
                [106.3368885, 11.3354086],
                [106.3371373, 11.3351417],
                [106.3373787, 11.3348683],
                [106.3376126, 11.3345887],
                [106.3378388, 11.3343031],
                [106.3380571, 11.3340116],
                [106.3382673, 11.3337145],
                [106.3384694, 11.333412],
                [106.3386632, 11.3331043],
                [106.3388485, 11.3327917],
                [106.3390253, 11.3324742],
                [106.3391933, 11.3321523],
                [106.3393525, 11.3318261],
                [106.3395027, 11.3314958],
                [106.3396438, 11.3311617],
                [106.3397758, 11.3308241],
                [106.3398986, 11.330483],
                [106.340012, 11.3301389],
                [106.3401159, 11.329792],
                [106.3402104, 11.3294424],
                [106.3403443, 11.3292001],
                [106.3404229, 11.3290858],
                [106.3406018, 11.3288732],
                [106.3407013, 11.3287758],
                [106.3409185, 11.3286008],
                [106.3411528, 11.3284568],
                [106.3414037, 11.3283429],
                [106.3416674, 11.3282611],
                [106.3418026, 11.3282326],
                [106.34219, 11.3281828],
                [106.3424102, 11.3281226],
                [106.3426193, 11.3280323],
                [106.3427184, 11.3279764],
                [106.3429031, 11.3278444],
                [106.3429877, 11.327769],
                [106.3431394, 11.3276014],
                [106.3443677, 11.3260944],
                [106.3445924, 11.3257787],
                [106.3447866, 11.3254441],
                [106.3449486, 11.3250935],
                [106.3450772, 11.3247297],
                [106.3451711, 11.3243559],
                [106.3452063, 11.3241565],
                [106.3452317, 11.3239556],
                [106.3452471, 11.3237537],
                [106.3452526, 11.3235514],
                [106.3452481, 11.323349],
                [106.3452337, 11.3231471],
                [106.3452093, 11.3229461],
                [106.3451751, 11.3227465],
                [106.345131, 11.3225487],
                [106.3450773, 11.3223533],
                [106.3439909, 11.3192091],
                [106.34359, 11.3181808],
                [106.3433174, 11.3174177],
                [106.3431911, 11.3170329],
                [106.3430012, 11.3166396],
                [106.3426374, 11.3158458],
                [106.3422685, 11.3149784],
                [106.3420807, 11.3145085],
                [106.3420376, 11.3143719],
                [106.3419741, 11.3140931],
                [106.3419431, 11.3138362],
                [106.3419256, 11.3135268],
                [106.3418109, 11.312809],
                [106.3417133, 11.3123682],
                [106.3416344, 11.3121393],
                [106.3412833, 11.3115161],
                [106.3411733, 11.3113016],
                [106.3409722, 11.3108639],
                [106.3399033, 11.3081175],
                [106.3398004, 11.307853],
                [106.339591, 11.3073869],
                [106.3393902, 11.3069171],
                [106.3391979, 11.3064439],
                [106.3390187, 11.3059792],
                [106.3388479, 11.3055115],
                [106.3381197, 11.3034751],
                [106.3380622, 11.3033105],
                [106.3379987, 11.303148],
                [106.3379292, 11.3029879],
                [106.3378643, 11.3028512],
                [106.3377949, 11.3027166],
                [106.3377213, 11.3025841],
                [106.3376345, 11.3024656],
                [106.3375429, 11.3023505],
                [106.3374466, 11.3022392],
                [106.3373458, 11.3021318],
                [106.337222, 11.3020109],
                [106.3370925, 11.3018959],
                [106.3369575, 11.3017871],
                [106.3368174, 11.3016846],
                [106.3366704, 11.301577],
                [106.3365269, 11.3014648],
                [106.3363872, 11.301348],
                [106.3362515, 11.301227],
                [106.3361111, 11.3010931],
                [106.3359755, 11.3009545],
                [106.3358448, 11.3008114],
                [106.3357529, 11.3006778],
                [106.3356687, 11.3005394],
                [106.3355925, 11.3003966],
                [106.3355246, 11.3002498],
                [106.3354769, 11.3001312],
                [106.3354345, 11.3000106],
                [106.3353977, 11.2998883],
                [106.335361, 11.29976],
                [106.3353308, 11.2996306],
                [106.335303, 11.2994948],
                [106.3352831, 11.2993577],
                [106.3352711, 11.2992198],
                [106.3352672, 11.2990817],
                [106.3352712, 11.2989435],
                [106.3352832, 11.2988059],
                [106.3353063, 11.2985997],
                [106.3353237, 11.298393],
                [106.3353352, 11.298186],
                [106.3353409, 11.2979786],
                [106.3353406, 11.2977606],
                [106.3353339, 11.2975427],
                [106.3353208, 11.297325],
                [106.3352941, 11.2971038],
                [106.3352745, 11.296882],
                [106.3352618, 11.2966596],
                [106.3352564, 11.2964643],
                [106.3352564, 11.296269],
                [106.3352682, 11.2961007],
                [106.3352896, 11.2959334],
                [106.3353206, 11.2957675],
                [106.335361, 11.2956035],
                [106.3354099, 11.2954445],
                [106.3354677, 11.2952884],
                [106.3355341, 11.2951357],
                [106.3356024, 11.2949907],
                [106.33566, 11.2948807],
                [106.3357172, 11.2947777],
                [106.3357805, 11.2946782],
                [106.3358496, 11.2945825],
                [106.3359243, 11.2944909],
                [106.3360119, 11.2943961],
                [106.3361055, 11.294307],
                [106.3362047, 11.2942239],
                [106.3363091, 11.2941472],
                [106.337009, 11.2938592],
                [106.3373485, 11.2936849],
                [106.3375746, 11.2934804],
                [106.337656, 11.2932563],
                [106.3376756, 11.2930324],
                [106.3376543, 11.2928902],
                [106.3376536, 11.2927478],
                [106.3376941, 11.2925646],
                [106.3378171, 11.2924012],
                [106.3379403, 11.2922786],
                [106.338043, 11.2921968],
                [106.3383725, 11.2920936],
                [106.338661, 11.2920312],
                [106.3393207, 11.2919469],
                [106.3395476, 11.2919458],
                [106.3396712, 11.291925],
                [106.3397951, 11.2919447],
                [106.3398776, 11.2919443],
                [106.3399398, 11.2920052],
                [106.3399815, 11.2921067],
                [106.3399204, 11.29229],
                [106.339593, 11.2928406],
                [106.3394097, 11.29335],
                [106.3393289, 11.2937166],
                [106.3391035, 11.2940431],
                [106.3385704, 11.2947167],
                [106.3382221, 11.2952471],
                [106.3380384, 11.2956548],
                [106.3379163, 11.2960012],
                [106.3378555, 11.2962454],
                [106.337877, 11.2964488],
                [106.3379399, 11.2966519],
                [106.3381272, 11.2970172],
                [106.3383655, 11.2972501],
                [106.3387591, 11.2976145],
                [106.339049, 11.2978572],
                [106.3393387, 11.2980593],
                [106.3395866, 11.2981396],
                [106.3397724, 11.2981591],
                [106.3401228, 11.2981168],
                [106.3404932, 11.2979117],
                [106.3409869, 11.2976451],
                [106.3413985, 11.2974195],
                [106.3417486, 11.2973161],
                [106.3421191, 11.2971517],
                [106.3425708, 11.2967021],
                [106.3432283, 11.2961297],
                [106.3437923, 11.295466],
                [106.3440993, 11.2949358],
                [106.3444268, 11.2943851],
                [106.3445905, 11.2941199],
                [106.3446107, 11.2940181],
                [106.34461, 11.2938757],
                [106.3446096, 11.2937741],
                [106.3445679, 11.2936928],
                [106.344485, 11.2936119],
                [106.3443819, 11.2936123],
                [106.3442788, 11.2936127],
                [106.3441756, 11.2936132],
                [106.34399, 11.2936141],
                [106.3435367, 11.2937179],
                [106.3431455, 11.293862],
                [106.342734, 11.2940876],
                [106.3425691, 11.2941291],
                [106.3423631, 11.2941707],
                [106.3418888, 11.2942135],
                [106.3417033, 11.2942347],
                [106.3414971, 11.2942356],
                [106.3413939, 11.2942361],
                [106.3412287, 11.2941962],
                [106.3411253, 11.2941153],
                [106.3409802, 11.2939736],
                [106.340918, 11.2938924],
                [106.3407725, 11.293649],
                [106.3407095, 11.2934255],
                [106.3406672, 11.293202],
                [106.3406662, 11.2929783],
                [106.3408388, 11.2923978],
                [106.3410411, 11.2915425],
                [106.3411409, 11.2908451],
                [106.3411809, 11.2905655],
                [106.341095, 11.2898132],
                [106.3410187, 11.2889491],
                [106.340788, 11.2881161],
                [106.3404532, 11.2871006],
                [106.3403897, 11.2867348],
                [106.3404185, 11.286277],
                [106.3405813, 11.2857881],
                [106.3409913, 11.2852777],
                [106.3416476, 11.2844408],
                [106.3425716, 11.2835619],
                [106.3433216, 11.2829584],
                [106.343713, 11.2828347],
                [106.3443104, 11.2826895],
                [106.3445579, 11.2826681],
                [106.3448055, 11.2826872],
                [106.3449501, 11.2827476],
                [106.3450952, 11.2828893],
                [106.3451576, 11.2830315],
                [106.3451584, 11.2831738],
                [106.3449535, 11.2834799],
                [106.3445227, 11.2839904],
                [106.3441947, 11.284419],
                [106.344072, 11.2846433],
                [106.3439288, 11.284888],
                [106.3438681, 11.2851527],
                [106.3438284, 11.2854988],
                [106.344161, 11.2857601],
                [106.3443798, 11.2864437],
                [106.3446098, 11.2868266],
                [106.345094, 11.2876321],
                [106.3457768, 11.2884522],
                [106.3463782, 11.2890513],
                [106.346481, 11.2891513],
                [106.34659, 11.2892449],
                [106.3467046, 11.2893318],
                [106.3468245, 11.2894115],
                [106.3469304, 11.2894735],
                [106.3470395, 11.2895299],
                [106.3471514, 11.2895806],
                [106.3472659, 11.2896255],
                [106.3473827, 11.2896644],
                [106.347486, 11.2896846],
                [106.3475906, 11.2896967],
                [106.3476958, 11.2897005],
                [106.3478011, 11.2896959],
                [106.3479133, 11.2896818],
                [106.3480239, 11.2896582],
                [106.348132, 11.2896254],
                [106.3482368, 11.2895835],
                [106.3483375, 11.2895329],
                [106.3485341, 11.2894443],
                [106.3487339, 11.2893629],
                [106.3489367, 11.289289],
                [106.3491422, 11.2892225],
                [106.3493301, 11.2891688],
                [106.3495198, 11.2891215],
                [106.349711, 11.2890804],
                [106.3499035, 11.2890456],
                [106.3500971, 11.2890173],
                [106.3502777, 11.2889994],
                [106.3504571, 11.2889723],
                [106.3506349, 11.2889361],
                [106.3508105, 11.2888911],
                [106.3509732, 11.2888406],
                [106.3511332, 11.2887825],
                [106.3512901, 11.2887169],
                [106.3514435, 11.2886438],
                [106.3515754, 11.2885557],
                [106.3517025, 11.2884612],
                [106.3518246, 11.2883604],
                [106.3519413, 11.2882538],
                [106.3520524, 11.2881414],
                [106.3521565, 11.2880248],
                [106.3522546, 11.2879033],
                [106.3523462, 11.2877771],
                [106.3524314, 11.2876465],
                [106.3525097, 11.2875119],
                [106.3525532, 11.287402],
                [106.3525876, 11.2872891],
                [106.3526127, 11.2871739],
                [106.3526283, 11.287057],
                [106.3526344, 11.2869394],
                [106.352632, 11.2868386],
                [106.3526226, 11.2867383],
                [106.3526063, 11.2866388],
                [106.3525831, 11.2865407],
                [106.352553, 11.2864443],
                [106.3525164, 11.2863502],
                [106.3524867, 11.2862853],
                [106.352465, 11.2862174],
                [106.3524514, 11.2861476],
                [106.3524462, 11.2860766],
                [106.3524494, 11.2860056],
                [106.3524602, 11.2859388],
                [106.3524785, 11.2858736],
                [106.352504, 11.2858107],
                [106.3525311, 11.2857615],
                [106.3525714, 11.2857222],
                [106.3526049, 11.2857103],
                [106.3526386, 11.2857089],
                [106.3526993, 11.2857308],
                [106.3527563, 11.2857606],
                [106.3528088, 11.2857978],
                [106.3528514, 11.2858372],
                [106.3528888, 11.2858815],
                [106.3529204, 11.28593],
                [106.3529456, 11.2859819],
                [106.3530441, 11.286159],
                [106.3531488, 11.2863326],
                [106.3532594, 11.2865027],
                [106.3533668, 11.2866566],
                [106.3534792, 11.286807],
                [106.3535963, 11.2869539],
                [106.353718, 11.2870972],
                [106.3538188, 11.2871977],
                [106.353915, 11.2873024],
                [106.3540067, 11.287411],
                [106.3540935, 11.2875233],
                [106.3541756, 11.2876393],
                [106.3542525, 11.2877586],
                [106.3543242, 11.287881],
                [106.3543532, 11.2879362],
                [106.3543886, 11.2879876],
                [106.3544299, 11.2880345],
                [106.3544766, 11.2880764],
                [106.3545281, 11.2881125],
                [106.3545883, 11.2881446],
                [106.3546522, 11.2881688],
                [106.3547187, 11.2881849],
                [106.3547868, 11.2881924],
                [106.3548486, 11.2881927],
                [106.3549037, 11.2881914],
                [106.3549517, 11.2881852],
                [106.3549985, 11.2881729],
                [106.3550432, 11.2881547],
                [106.3550851, 11.2881309],
                [106.3551235, 11.288102],
                [106.3551638, 11.2880612],
                [106.3551971, 11.2880148],
                [106.3552225, 11.2879639],
                [106.3552396, 11.2879097],
                [106.3552478, 11.2878536],
                [106.3552469, 11.2877968],
                [106.3552215, 11.2876204],
                [106.3552049, 11.2874429],
                [106.3551973, 11.2872649],
                [106.3551986, 11.2870867],
                [106.3552071, 11.2869304],
                [106.3552225, 11.2867747],
                [106.3552447, 11.2866197],
                [106.3552737, 11.2864659],
                [106.3553029, 11.2863496],
                [106.3553388, 11.2862352],
                [106.3553814, 11.2861229],
                [106.3554304, 11.2860132],
                [106.3554858, 11.2859065],
                [106.3555473, 11.285803],
                [106.3556203, 11.2856955],
                [106.3556999, 11.2855926],
                [106.3557859, 11.2854948],
                [106.3558779, 11.2854023],
                [106.3559755, 11.2853155],
                [106.3560784, 11.2852349],
                [106.3561407, 11.285196],
                [106.3561979, 11.28515],
                [106.356249, 11.2850977],
                [106.3562934, 11.2850398],
                [106.3563305, 11.2849771],
                [106.3563604, 11.2849085],
                [106.3563816, 11.2848368],
                [106.3563935, 11.2847631],
                [106.3564002, 11.2846951],
                [106.3564002, 11.2846154],
                [106.3563996, 11.2845535],
                [106.3563959, 11.2844741],
                [106.3563864, 11.2844045],
                [106.3563686, 11.2843365],
                [106.3563427, 11.2842711],
                [106.3563091, 11.2842091],
                [106.3562674, 11.2841504],
                [106.3562189, 11.2840971],
                [106.3561641, 11.2840498],
                [106.356104, 11.2840094],
                [106.3560393, 11.2839764],
                [106.3559309, 11.2839276],
                [106.3557944, 11.283874],
                [106.3556146, 11.2838042],
                [106.3554319, 11.283742],
                [106.3552466, 11.2836876],
                [106.3550591, 11.2836409],
                [106.3548616, 11.2836006],
                [106.3546625, 11.2835691],
                [106.3544621, 11.2835462],
                [106.3542609, 11.2835321],
                [106.3540592, 11.2835269],
                [106.3538575, 11.2835304],
                [106.3537899, 11.2835255],
                [106.3537235, 11.2835119],
                [106.3536596, 11.2834899],
                [106.3535991, 11.2834599],
                [106.3535432, 11.2834223],
                [106.3534927, 11.2833779],
                [106.3534516, 11.2833311],
                [106.3534166, 11.2832798],
                [106.3533882, 11.2832247],
                [106.3533669, 11.2831666],
                [106.3533529, 11.2831064],
                [106.353333, 11.2829954],
                [106.3533203, 11.2828834],
                [106.3533148, 11.2827708],
                [106.3533167, 11.28251],
                [106.353326, 11.2822493],
                [106.3533425, 11.281989],
                [106.3533635, 11.2817569],
                [106.3533904, 11.2815254],
                [106.353423, 11.2812946],
                [106.3534401, 11.2811976],
                [106.3534649, 11.2811022],
                [106.3534972, 11.2810091],
                [106.353537, 11.2809187],
                [106.3535839, 11.2808317],
                [106.353635, 11.2807524],
                [106.353692, 11.2806771],
                [106.3537545, 11.2806062],
                [106.3538224, 11.2805401],
                [106.3538736, 11.2804871],
                [106.353951, 11.2804344],
                [106.3540111, 11.2803952],
                [106.3540748, 11.2803619],
                [106.3541415, 11.2803349],
                [106.3542105, 11.2803143],
                [106.3542813, 11.2803003],
                [106.3543642, 11.2802927],
                [106.3544475, 11.2802942],
                [106.3545301, 11.2803049],
                [106.3546109, 11.2803245],
                [106.354681, 11.2803477],
                [106.354744, 11.2803634],
                [106.3547999, 11.2803668],
                [106.3548557, 11.2803629],
                [106.3549106, 11.280352],
                [106.3549636, 11.2803341],
                [106.3550137, 11.2803096],
                [106.3550601, 11.2802789],
                [106.3551021, 11.2802424],
                [106.3551406, 11.2801986],
                [106.3551727, 11.2801499],
                [106.3551976, 11.2800973],
                [106.3552149, 11.2800419],
                [106.3552242, 11.2799847],
                [106.3552321, 11.2799005],
                [106.3552327, 11.2797923],
                [106.3552306, 11.2796576],
                [106.3552192, 11.2795234],
                [106.3551986, 11.2793902],
                [106.3551709, 11.2792664],
                [106.3551352, 11.2791446],
                [106.3550916, 11.2790252],
                [106.3550082, 11.2788194],
                [106.3548001, 11.2783116],
                [106.3547324, 11.2781404],
                [106.3546578, 11.277972],
                [106.3545763, 11.2778067],
                [106.3544757, 11.277623],
                [106.3543665, 11.277444],
                [106.3542491, 11.2772701],
                [106.354175, 11.2771811],
                [106.3541066, 11.2770879],
                [106.3540442, 11.2769907],
                [106.3539879, 11.27689],
                [106.353938, 11.2767861],
                [106.3538908, 11.2766688],
                [106.3538517, 11.2765487],
                [106.3538209, 11.2764263],
                [106.3537905, 11.2763047],
                [106.3537696, 11.2761938],
                [106.3537529, 11.2760945],
                [106.3537404, 11.275989],
                [106.3537349, 11.2758829],
                [106.3537364, 11.2757767],
                [106.3537449, 11.2756708],
                [106.3537602, 11.275566],
                [106.3537824, 11.2754624],
                [106.3538113, 11.2753604],
                [106.3538468, 11.2752604],
                [106.3539163, 11.2750971],
                [106.3539772, 11.2749304],
                [106.3540292, 11.2747609],
                [106.3540723, 11.274589],
                [106.3541063, 11.2744152],
                [106.3541311, 11.2742398],
                [106.3541463, 11.2740696],
                [106.3541528, 11.2738988],
                [106.3541506, 11.2737279],
                [106.35414, 11.2736112],
                [106.3541353, 11.2734942],
                [106.3541365, 11.273377],
                [106.3541437, 11.2732579],
                [106.3541571, 11.2731392],
                [106.3541765, 11.2730213],
                [106.3541811, 11.2728978],
                [106.3541784, 11.2727742],
                [106.3541686, 11.272651],
                [106.3541507, 11.2725224],
                [106.354125, 11.2723951],
                [106.3540916, 11.2722694],
                [106.3540184, 11.272117],
                [106.3539184, 11.2719508],
                [106.3537944, 11.2717505],
                [106.3536789, 11.2715453],
                [106.3535722, 11.2713357],
                [106.3534743, 11.2711219],
                [106.3533854, 11.2709044],
                [106.3533158, 11.2707129],
                [106.3532532, 11.2705192],
                [106.3531977, 11.2703233],
                [106.3531493, 11.2701257],
                [106.3531082, 11.2699265],
                [106.3530743, 11.2697259],
                [106.3530361, 11.2694243],
                [106.3530037, 11.269122],
                [106.3529771, 11.2688192],
                [106.3529563, 11.2685159],
                [106.35294, 11.2681805],
                [106.3529308, 11.2678448],
                [106.3529286, 11.267509],
                [106.3529336, 11.2671732],
                [106.3529348, 11.266885],
                [106.3529258, 11.266684],
                [106.3528981, 11.2665316],
                [106.3528626, 11.2663808],
                [106.3528194, 11.2662319],
                [106.3527685, 11.2660853],
                [106.3527128, 11.2659476],
                [106.3526504, 11.2658127],
                [106.3525814, 11.2656808],
                [106.3525059, 11.2655525],
                [106.3524242, 11.2654278],
                [106.3523364, 11.2653072],
                [106.3522214, 11.2651488],
                [106.3521103, 11.2650013],
                [106.3519703, 11.264817],
                [106.351823, 11.264638],
                [106.3516688, 11.2644649],
                [106.3515238, 11.2643137],
                [106.3513733, 11.2641676],
                [106.3512178, 11.2640268],
                [106.3510653, 11.2639125],
                [106.3509683, 11.2638446],
                [106.3508821, 11.263793],
                [106.350799, 11.2637366],
                [106.3507193, 11.2636757],
                [106.3506338, 11.2636019],
                [106.3505532, 11.2635229],
                [106.350486, 11.2634469],
                [106.3504461, 11.2633663],
                [106.3504224, 11.2632906],
                [106.3504072, 11.2632129],
                [106.3504006, 11.263134],
                [106.3504028, 11.2630549],
                [106.3504128, 11.2629813],
                [106.3504303, 11.262909],
                [106.3504553, 11.2628388],
                [106.3504873, 11.2627715],
                [106.3505262, 11.2627077],
                [106.3505667, 11.2626595],
                [106.3506127, 11.2626161],
                [106.3506634, 11.2625783],
                [106.3507183, 11.2625465],
                [106.3507765, 11.2625211],
                [106.3508373, 11.2625025],
                [106.3509034, 11.2624905],
                [106.3509705, 11.2624865],
                [106.3510376, 11.2624906],
                [106.3511037, 11.2625026],
                [106.3511678, 11.2625225],
                [106.351245, 11.2625472],
                [106.3513526, 11.2625638],
                [106.3514771, 11.2625684],
                [106.3516015, 11.2625638],
                [106.3517252, 11.2625499],
                [106.3518393, 11.2625286],
                [106.3519516, 11.2624995],
                [106.3520615, 11.2624627],
                [106.3521685, 11.2624183],
                [106.352272, 11.2623666],
                [106.3523716, 11.2623079],
                [106.3525426, 11.2622194],
                [106.3527172, 11.2621377],
                [106.3528949, 11.262063],
                [106.3530756, 11.2619954],
                [106.3532588, 11.2619349],
                [106.3534445, 11.2618817],
                [106.3536377, 11.2618346],
                [106.3538328, 11.2617954],
                [106.3540294, 11.2617642],
                [106.3542271, 11.2617409],
                [106.3544203, 11.261731],
                [106.3546474, 11.2617259],
                [106.354869, 11.2617195],
                [106.3550904, 11.2617066],
                [106.3553113, 11.261687],
                [106.3555712, 11.2616556],
                [106.3558299, 11.2616151],
                [106.3560869, 11.2615655],
                [106.3563412, 11.2615029],
                [106.3566564, 11.261419],
                [106.3569697, 11.2613286],
                [106.357281, 11.2612318],
                [106.3575901, 11.2611285],
                [106.3578969, 11.2610189],
                [106.3580644, 11.2609715],
                [106.3582342, 11.2609328],
                [106.3584058, 11.2609029],
                [106.3585788, 11.2608818],
                [106.3587526, 11.2608696],
                [106.3589269, 11.2608663],
                [106.3590784, 11.2608707],
                [106.3592296, 11.2608819],
                [106.3593801, 11.2608999],
                [106.3595297, 11.2609245],
                [106.3596779, 11.2609557],
                [106.3598114, 11.2610083],
                [106.359942, 11.2610674],
                [106.3600693, 11.2611329],
                [106.3601932, 11.2612047],
                [106.3603131, 11.2612825],
                [106.3604289, 11.2613661],
                [106.3605344, 11.2614505],
                [106.3606357, 11.2615397],
                [106.3607324, 11.2616335],
                [106.3608245, 11.2617318],
                [106.3610399, 11.2619913],
                [106.3612497, 11.2622552],
                [106.361429, 11.2624904],
                [106.3616037, 11.2627287],
                [106.3616492, 11.2627936],
                [106.3617014, 11.2628534],
                [106.3617598, 11.2629074],
                [106.3618237, 11.262955],
                [106.3618829, 11.2629906],
                [106.3619453, 11.2630208],
                [106.3620102, 11.2630452],
                [106.3620772, 11.2630637],
                [106.3621589, 11.2630707],
                [106.3622691, 11.2630519],
                [106.3623908, 11.2630203],
                [106.3625103, 11.2629813],
                [106.362618, 11.2629389],
                [106.3627229, 11.2628904],
                [106.3628248, 11.2628361],
                [106.3629234, 11.2627761],
                [106.3630391, 11.2627222],
                [106.3631513, 11.2626614],
                [106.3632595, 11.2625941],
                [106.3633634, 11.2625204],
                [106.3634624, 11.2624407],
                [106.3635564, 11.2623552],
                [106.3636525, 11.2622558],
                [106.3637417, 11.2621504],
                [106.3638236, 11.2620395],
                [106.3638979, 11.2619234],
                [106.3639748, 11.2617818],
                [106.3640443, 11.26164],
                [106.3641178, 11.2614741],
                [106.3641844, 11.2613055],
                [106.3642441, 11.2611344],
                [106.3642967, 11.260961],
                [106.3643375, 11.260805],
                [106.3643726, 11.2606476],
                [106.3644019, 11.2604891],
                [106.3644174, 11.2603665],
                [106.3644246, 11.2602463],
                [106.3644169, 11.2601633],
                [106.3644022, 11.2600812],
                [106.3643808, 11.2600005],
                [106.3643527, 11.2599218],
                [106.3643181, 11.2598456],
                [106.364272, 11.2597639],
                [106.3642184, 11.2596866],
                [106.3641578, 11.2596144],
                [106.3640907, 11.259548],
                [106.3640177, 11.2594879],
                [106.3639234, 11.259456],
                [106.3638274, 11.2594295],
                [106.36373, 11.2594086],
                [106.3636315, 11.2593932],
                [106.3635122, 11.2593821],
                [106.3633925, 11.2593792],
                [106.3632728, 11.2593845],
                [106.3631538, 11.259398],
                [106.3630092, 11.2594169],
                [106.3628528, 11.2594247],
                [106.3626911, 11.2594215],
                [106.3625297, 11.2594098],
                [106.3623691, 11.2593899],
                [106.3622099, 11.2593616],
                [106.3620618, 11.2593276],
                [106.3619157, 11.2592863],
                [106.3617718, 11.259238],
                [106.3616306, 11.2591827],
                [106.3615102, 11.2591033],
                [106.3613954, 11.2590164],
                [106.3612866, 11.2589223],
                [106.3611842, 11.2588216],
                [106.3610887, 11.2587145],
                [106.3610063, 11.2586094],
                [106.3609305, 11.2584997],
                [106.3608616, 11.2583856],
                [106.3607998, 11.2582677],
                [106.3607454, 11.2581463],
                [106.3607126, 11.2580278],
                [106.3606869, 11.2579077],
                [106.3606686, 11.2577862],
                [106.3606577, 11.257664],
                [106.3606542, 11.2575412],
                [106.3606589, 11.2574075],
                [106.3606724, 11.2572743],
                [106.3606946, 11.2571422],
                [106.3607255, 11.2570119],
                [106.360765, 11.2568837],
                [106.3608128, 11.2567584],
                [106.3608688, 11.2566363],
                [106.3608975, 11.2565596],
                [106.3609185, 11.2564805],
                [106.3609315, 11.2563997],
                [106.3609364, 11.2563181],
                [106.3609332, 11.2562365],
                [106.360923, 11.2561613],
                [106.3609059, 11.2560874],
                [106.360882, 11.2560152],
                [106.3608516, 11.2559455],
                [106.3608149, 11.2558788],
                [106.3607562, 11.2558392],
                [106.3606918, 11.2558077],
                [106.3605942, 11.2557897],
                [106.3604153, 11.2557704],
                [106.3602358, 11.2557577],
                [106.3600398, 11.2557513],
                [106.3598438, 11.2557526],
                [106.3596479, 11.2557618],
                [106.3594365, 11.2557787],
                [106.3591878, 11.255768],
                [106.3589233, 11.2557513],
                [106.3586592, 11.2557285],
                [106.3583958, 11.2556998],
                [106.3580962, 11.2556598],
                [106.3577977, 11.255612],
                [106.3575006, 11.2555565],
                [106.3573364, 11.2555216],
                [106.357174, 11.2554795],
                [106.3570137, 11.2554302],
                [106.3568558, 11.2553738],
                [106.3567006, 11.2553105],
                [106.3565307, 11.2552316],
                [106.356365, 11.2551443],
                [106.3562041, 11.2550489],
                [106.3560483, 11.2549457],
                [106.355898, 11.2548348],
                [106.3557536, 11.2547167],
                [106.3556155, 11.2545915],
                [106.355484, 11.2544596],
                [106.3553595, 11.2543214],
                [106.3549462, 11.253875],
                [106.3547522, 11.2536869],
                [106.3545525, 11.2535047],
                [106.3543472, 11.2533286],
                [106.3541365, 11.2531586],
                [106.3539317, 11.2530032],
                [106.3537223, 11.2528537],
                [106.3535087, 11.2527102],
                [106.3532909, 11.2525728],
                [106.3530692, 11.2524416],
                [106.3528651, 11.2523273],
                [106.3527001, 11.2522109],
                [106.3525616, 11.2520987],
                [106.3524283, 11.2519806],
                [106.3523005, 11.2518568],
                [106.3521784, 11.2517276],
                [106.3520583, 11.2515881],
                [106.351945, 11.2514434],
                [106.3518385, 11.2512936],
                [106.3517392, 11.2511392],
                [106.3516474, 11.2509804],
                [106.3515611, 11.2508186],
                [106.3514808, 11.2506538],
                [106.3514067, 11.2504863],
                [106.3513388, 11.2503163],
                [106.3512772, 11.2501439],
                [106.3512238, 11.2499753],
                [106.3511765, 11.249805],
                [106.3511353, 11.2496331],
                [106.3511002, 11.2494599],
                [106.351089, 11.2493568],
                [106.3510856, 11.2492532],
                [106.3510898, 11.2491495],
                [106.3511018, 11.2490465],
                [106.3511214, 11.2489446],
                [106.3511485, 11.2488443],
                [106.3511859, 11.2487389],
                [106.3512317, 11.2486367],
                [106.3512855, 11.2485384],
                [106.3513631, 11.2484603],
                [106.3514979, 11.2484054],
                [106.3516503, 11.2483721],
                [106.3518039, 11.2483448],
                [106.3519585, 11.2483235],
                [106.3521298, 11.2483069],
                [106.3523017, 11.2482976],
                [106.3524738, 11.2482957],
                [106.3526459, 11.2483011],
                [106.3528176, 11.2483139],
                [106.3529724, 11.248334],
                [106.3531599, 11.2483074],
                [106.3533302, 11.2482741],
                [106.3534989, 11.2482341],
                [106.3536658, 11.2481873],
                [106.3538307, 11.2481341],
                [106.3540218, 11.248063],
                [106.3542092, 11.2479831],
                [106.3543925, 11.2478946],
                [106.3545713, 11.2477976],
                [106.3547453, 11.2476924],
                [106.3549139, 11.2475792],
                [106.3550768, 11.2474582],
                [106.3552337, 11.2473298],
                [106.3554132, 11.2471712],
                [106.3555978, 11.2469634],
                [106.3557246, 11.2468192],
                [106.3558439, 11.2466691],
                [106.3559555, 11.2465132],
                [106.356059, 11.2463522],
                [106.3561541, 11.2461862],
                [106.356241, 11.2460153],
                [106.3563191, 11.2458403],
                [106.3563882, 11.2456617],
                [106.3564481, 11.2454799],
                [106.3564986, 11.2452955],
                [106.3565558, 11.2450772],
                [106.3566668, 11.2446932],
                [106.3567833, 11.2443109],
                [106.3569055, 11.2439303],
                [106.3570332, 11.2435514],
                [106.3571989, 11.2430853],
                [106.357373, 11.2426222],
                [106.3575554, 11.2421622],
                [106.3577462, 11.2417054],
                [106.3579076, 11.2412889],
                [106.3581115, 11.2408049],
                [106.3583851, 11.2402682],
                [106.3584721, 11.2401091],
                [106.3585672, 11.2399545],
                [106.3586701, 11.2398048],
                [106.3587805, 11.2396604],
                [106.3588981, 11.2395215],
                [106.3590228, 11.2393887],
                [106.3591541, 11.2392621],
                [106.3592917, 11.2391422],
                [106.3594351, 11.2390294],
                [106.3595841, 11.2389237],
                [106.3597383, 11.2388255],
                [106.3598974, 11.238735],
                [106.3600609, 11.2386525],
                [106.3602284, 11.2385781],
                [106.3603995, 11.238512],
                [106.3605952, 11.2384413],
                [106.3608466, 11.2383453],
                [106.3610375, 11.2382718],
                [106.3611861, 11.2382169],
                [106.3613376, 11.2381703],
                [106.3614916, 11.2381321],
                [106.3616475, 11.2381024],
                [106.3618048, 11.2380813],
                [106.3619631, 11.2380689],
                [106.3621263, 11.2380652],
                [106.3622895, 11.2380708],
                [106.362452, 11.2380856],
                [106.3626134, 11.2381095],
                [106.3627188, 11.2381435],
                [106.3628213, 11.2381851],
                [106.3629203, 11.238234],
                [106.3630153, 11.2382901],
                [106.3631058, 11.238353],
                [106.3631935, 11.2384243],
                [106.3632753, 11.238502],
                [106.3633509, 11.2385856],
                [106.3634197, 11.2386747],
                [106.3634813, 11.2387687],
                [106.3635599, 11.2388731],
                [106.3636444, 11.238973],
                [106.3637345, 11.2390681],
                [106.36383, 11.239158],
                [106.3639401, 11.2392501],
                [106.3640557, 11.2393354],
                [106.3641765, 11.2394136],
                [106.364302, 11.2394842],
                [106.3644106, 11.2395635],
                [106.3645141, 11.2396491],
                [106.3646122, 11.2397406],
                [106.3647046, 11.2398376],
                [106.3647908, 11.23994],
                [106.3648707, 11.2400472],
                [106.3649465, 11.2401634],
                [106.3650149, 11.240284],
                [106.3650754, 11.2404086],
                [106.3651279, 11.2405366],
                [106.3651722, 11.2406676],
                [106.3652081, 11.240801],
                [106.3652462, 11.2409469],
                [106.3652941, 11.2410695],
                [106.3653613, 11.2411986],
                [106.3654367, 11.2413233],
                [106.36552, 11.241443],
                [106.365611, 11.2415573],
                [106.3657061, 11.2416625],
                [106.3658078, 11.2417618],
                [106.3659155, 11.2418548],
                [106.3660288, 11.2419411],
                [106.3661474, 11.2420203],
                [106.3665041, 11.2422228],
                [106.3669028, 11.2424207],
                [106.3672784, 11.2426374],
                [106.3676505, 11.24286],
                [106.3680188, 11.2430886],
                [106.3684055, 11.2433375],
                [106.3687879, 11.2435928],
                [106.3691657, 11.2438546],
                [106.3695389, 11.2441227],
                [106.3699073, 11.2443971],
                [106.3702709, 11.2446776],
                [106.3703349, 11.2447222],
                [106.370403, 11.2447606],
                [106.3704746, 11.2447922],
                [106.370549, 11.2448169],
                [106.3706255, 11.2448344],
                [106.3707034, 11.2448445],
                [106.3707819, 11.2448471],
                [106.3708603, 11.2448422],
                [106.3709378, 11.2448299],
                [106.3710153, 11.2448098],
                [106.3710903, 11.2447822],
                [106.3711622, 11.2447475],
                [106.3712302, 11.244706],
                [106.3712937, 11.2446581],
                [106.371352, 11.2446043],
                [106.3714046, 11.244545],
                [106.3714509, 11.2444809],
                [106.3714906, 11.2444127],
                [106.3715232, 11.2443409],
                [106.3715483, 11.2442663],
                [106.3716642, 11.2439161],
                [106.3717888, 11.2435688],
                [106.3719221, 11.2432246],
                [106.3720641, 11.2428837],
                [106.3722145, 11.2425464],
                [106.3723758, 11.2422081],
                [106.3725456, 11.2418738],
                [106.3727239, 11.2415438],
                [106.3729105, 11.2412183],
                [106.3731054, 11.2408974],
                [106.3749724, 11.2381402],
                [106.3750687, 11.2380087],
                [106.3751706, 11.2378815],
                [106.375278, 11.2377586],
                [106.3753906, 11.2376403],
                [106.3755083, 11.2375269],
                [106.3756267, 11.2374221],
                [106.3757494, 11.2373222],
                [106.3758762, 11.2372273],
                [106.3760069, 11.2371376],
                [106.3761359, 11.2370639],
                [106.3762763, 11.236987],
                [106.3764078, 11.2369142],
                [106.3765355, 11.2368351],
                [106.376659, 11.23675],
                [106.3767782, 11.236659],
                [106.3768926, 11.2365624],
                [106.3769969, 11.2364653],
                [106.3770964, 11.2363636],
                [106.377191, 11.2362574],
                [106.3772804, 11.236147],
                [106.3773234, 11.2360905],
                [106.3773644, 11.2360326],
                [106.3774297, 11.2359307],
                [106.3774887, 11.2358252],
                [106.3775414, 11.2357164],
                [106.3775875, 11.2356049],
                [106.3776268, 11.2354908],
                [106.3778407, 11.2348759],
                [106.3779097, 11.234682],
                [106.3779726, 11.2344861],
                [106.3780319, 11.2342787],
                [106.3780844, 11.2340696],
                [106.37813, 11.2338589],
                [106.3781687, 11.2336469],
                [106.3782004, 11.2334338],
                [106.3782251, 11.2332197],
                [106.3782409, 11.2329589],
                [106.378251, 11.2326979],
                [106.3782554, 11.2324367],
                [106.3782526, 11.2320916],
                [106.3782398, 11.2317467],
                [106.3782171, 11.2314023],
                [106.3781846, 11.2310587],
                [106.3781864, 11.2308162],
                [106.3781967, 11.2305738],
                [106.3782154, 11.230332],
                [106.3782425, 11.2300909],
                [106.3782756, 11.2298651],
                [106.3783162, 11.2296404],
                [106.3784641, 11.2292293],
                [106.3787693, 11.2287838],
                [106.3788718, 11.2286409],
                [106.379036, 11.2284978],
                [106.3792213, 11.2284359],
                [106.3793655, 11.2284148],
                [106.3795099, 11.2284142],
                [106.3797573, 11.2283927],
                [106.3799636, 11.2284121],
                [106.3801702, 11.2284722],
                [106.3804182, 11.2286134],
                [106.3808735, 11.2289367],
                [106.381349, 11.2292193],
                [106.3816589, 11.2293196],
                [106.3818858, 11.2293185],
                [106.382607, 11.2292135],
                [106.3828536, 11.2290496],
                [106.3832029, 11.2287835],
                [106.3835412, 11.228365],
                [106.3837041, 11.2279168],
                [106.3837645, 11.2276113],
                [106.3838047, 11.2274078],
                [106.3838242, 11.2271636],
                [106.3838231, 11.2269398],
                [106.3838014, 11.2266958],
                [106.3837384, 11.2264724],
                [106.3835513, 11.2261478],
                [106.3833021, 11.2257829],
                [106.3828672, 11.2253779],
                [106.3826287, 11.2251045],
                [106.3822758, 11.2246179],
                [106.3820883, 11.2242324],
                [106.381942, 11.2238059],
                [106.3816909, 11.2230544],
                [106.3813149, 11.2220189],
                [106.3807218, 11.2208926],
                [106.3803886, 11.2202229],
                [106.3800754, 11.2193904],
                [106.3798662, 11.2187608],
                [106.3798124, 11.2182831],
                [106.3798519, 11.2179168],
                [106.3799537, 11.2176315],
                [106.3800972, 11.2174478],
                [106.3803025, 11.2172638],
                [106.3805287, 11.217161],
                [106.3811261, 11.2169955],
                [106.3815584, 11.2168511],
                [106.3819492, 11.2166459],
                [106.3822778, 11.2163596],
                [106.3828322, 11.2158689],
                [106.3837151, 11.2150918],
                [106.3845687, 11.2146099],
                [106.3854742, 11.2142598],
                [106.3864415, 11.2138485],
                [106.3869768, 11.2136834],
                [106.3873679, 11.2135391],
                [106.387718, 11.2134358],
                [106.3879856, 11.2133532],
                [106.3887881, 11.2130037],
                [106.3890648, 11.2126464],
                [106.3891872, 11.2123611],
                [106.3892064, 11.2120762],
                [106.3891846, 11.2118119],
                [106.3888521, 11.2112845],
                [106.3881874, 11.2102707],
                [106.3875841, 11.2091547],
                [106.3870935, 11.2078857],
                [106.3869874, 11.2072556],
                [106.3869641, 11.2066862],
                [106.3869411, 11.2061981],
                [106.3869376, 11.2054658],
                [106.386881, 11.2043982],
                [106.3868364, 11.2036865],
                [106.3867729, 11.2033613],
                [106.3867096, 11.2030362],
                [106.3866255, 11.2027111],
                [106.386397, 11.2023665],
                [106.3860958, 11.2018898],
                [106.3857641, 11.2015252],
                [106.3855571, 11.2013634],
                [106.3852886, 11.2012426],
                [106.3850819, 11.2011419],
                [106.3848544, 11.2010006],
                [106.3847094, 11.2008588],
                [106.3846055, 11.2006967],
                [106.3844807, 11.2004733],
                [106.3844385, 11.2002701],
                [106.3844165, 11.1999856],
                [106.384415, 11.1996601],
                [106.3844118, 11.1989889],
                [106.3842755, 11.1985114],
                [106.384129, 11.1980647],
                [106.3840442, 11.1975769],
                [106.3840222, 11.1972719],
                [106.3840411, 11.196926],
                [106.3840398, 11.1966411],
                [106.3840584, 11.1962139],
                [106.3841185, 11.1958475],
                [106.3841684, 11.1954913],
                [106.3841674, 11.1952879],
                [106.3841458, 11.1950846],
                [106.3841039, 11.1949425],
                [106.3840208, 11.1948005],
                [106.3839378, 11.1946991],
                [106.3838747, 11.194435],
                [106.3837899, 11.1939472],
                [106.3837873, 11.193398],
                [106.383929, 11.1928482],
                [106.3841742, 11.1923792],
                [106.3847386, 11.1918172],
                [106.3852931, 11.1913671],
                [106.3860119, 11.1907739],
                [106.3862375, 11.1905084],
                [106.386545, 11.1901409],
                [106.3867086, 11.1898553],
                [106.3870156, 11.1893657],
                [106.3873213, 11.1886118],
                [106.3875545, 11.1877868],
                [106.3877155, 11.1869522],
                [106.3877134, 11.186525],
                [106.3877121, 11.1862606],
                [106.3877521, 11.1859959],
                [106.3878338, 11.1858125],
                [106.3879979, 11.1856286],
                [106.3882033, 11.1854853],
                [106.3886871, 11.1853305],
                [106.3894492, 11.185144],
                [106.3904793, 11.1849764],
                [106.3914473, 11.1847685],
                [106.3923332, 11.184622],
                [106.3932813, 11.1845566],
                [106.3942191, 11.184481],
                [106.3949816, 11.1844164],
                [106.3955785, 11.1841899],
                [106.3960513, 11.1838826],
                [106.3963799, 11.1836166],
                [106.3968518, 11.1831465],
                [106.397221, 11.1827177],
                [106.3973436, 11.1825136],
                [106.3976099, 11.1821463],
                [106.398, 11.1817987],
                [106.3983288, 11.1815734],
                [106.3987713, 11.1814188],
                [106.3992868, 11.1814164],
                [106.4003596, 11.1815537],
                [106.4015363, 11.1818534],
                [106.4028171, 11.1823762],
                [106.4038094, 11.1829207],
                [106.4043165, 11.183315],
                [106.404773, 11.183923],
                [106.4049188, 11.1842478],
                [106.4049823, 11.184573],
                [106.4049632, 11.1848782],
                [106.4049446, 11.1853259],
                [106.4048646, 11.1858346],
                [106.4046202, 11.1864664],
                [106.4044691, 11.1871891],
                [106.4044496, 11.187413],
                [106.4044718, 11.1877384],
                [106.4045551, 11.187921],
                [106.4046999, 11.1880221],
                [106.4048859, 11.1881025],
                [106.4050306, 11.1881629],
                [106.4051335, 11.1881421],
                [106.4053598, 11.1880394],
                [106.4055034, 11.1878962],
                [106.4056672, 11.1876514],
                [106.4058304, 11.1873048],
                [106.4059522, 11.1868974],
                [106.4060534, 11.1865105],
                [106.4062167, 11.1861436],
                [106.4064409, 11.185634],
                [106.4068094, 11.1850628],
                [106.4074656, 11.1843478],
                [106.4078144, 11.1839799],
                [106.4081641, 11.1838156],
                [106.4086585, 11.1837319],
                [106.4089474, 11.1837509],
                [106.4092981, 11.1838102],
                [106.4096902, 11.1838694],
                [106.4100511, 11.1838778],
                [106.4102365, 11.1838567],
                [106.410401, 11.1837746],
                [106.4105856, 11.1835906],
                [106.4106877, 11.1833664],
                [106.4106868, 11.1831833],
                [106.4106651, 11.1829597],
                [106.4105403, 11.1827365],
                [106.4101252, 11.1821689],
                [106.4099179, 11.1819258],
                [106.4097309, 11.181642],
                [106.4096468, 11.1812965],
                [106.4096028, 11.1807272],
                [106.409671, 11.1799031],
                [106.4098118, 11.1791702],
                [106.4099547, 11.1788848],
                [106.41018, 11.1785582],
                [106.4103023, 11.1782932],
                [106.4103625, 11.1779472],
                [106.4104014, 11.1774791],
                [106.4102829, 11.1764118],
                [106.4102798, 11.175761],
                [106.4100266, 11.174562],
                [106.4098584, 11.1738916],
                [106.4098835, 11.1734339],
                [106.4099452, 11.173026],
                [106.4100632, 11.1727497],
                [106.4102282, 11.1725248],
                [106.4104334, 11.1723511],
                [106.410664, 11.1722498],
                [106.4109296, 11.1722024],
                [106.4111254, 11.1722142],
                [106.4112729, 11.172334],
                [106.4113842, 11.1724643],
                [106.4119086, 11.1732155],
                [106.4122224, 11.173526],
                [106.4126649, 11.1738589],
                [106.4130753, 11.173997],
                [106.415602, 11.1743733],
                [106.4159688, 11.1743838],
                [106.4164194, 11.1743509],
                [106.4167929, 11.1742562],
                [106.4170772, 11.1740759],
                [106.4175157, 11.1737089],
                [106.4179677, 11.173255],
                [106.4184156, 11.1728971],
                [106.4188662, 11.1726471],
                [106.4193356, 11.1725077],
                [106.420328, 11.172355],
                [106.4209825, 11.1722235],
                [106.4215028, 11.1720156],
                [106.4228198, 11.1711235],
                [106.4238122, 11.1707209],
                [106.42459, 11.170542],
                [106.425215, 11.170471],
                [106.4257944, 11.1704604],
                [106.4263442, 11.1705788],
                [106.426827, 11.1708315],
                [106.427279, 11.1711709],
                [106.4276209, 11.171563],
                [106.4278999, 11.1720051],
                [106.4284591, 11.1734839],
                [106.4286751, 11.1738891],
                [106.4288608, 11.1741674],
                [106.4291123, 11.1744417],
                [106.4293858, 11.1746575],
                [106.429528, 11.1747259],
                [106.4296996, 11.1747601],
                [106.4299518, 11.1747496],
                [106.4303018, 11.1746588],
                [106.4306907, 11.1744707],
                [106.431148, 11.1741075],
                [106.431663, 11.173576],
                [106.4322411, 11.1726905],
                [106.432693, 11.1714367],
                [106.4327627, 11.1706552],
                [106.4327306, 11.1681658],
                [106.4328325, 11.1666607],
                [106.4330015, 11.1658212],
                [106.4334279, 11.1646897],
                [106.4343399, 11.1634556],
                [106.4353269, 11.1625135],
                [106.4357534, 11.161953],
                [106.4363247, 11.1610399],
                [106.4366358, 11.1601873],
                [106.4368987, 11.1590715],
                [106.4369899, 11.1581189],
                [106.4369738, 11.1570769],
                [106.4367512, 11.1560085],
                [106.4365755, 11.1555282],
                [106.4359438, 11.1547427],
                [106.4347422, 11.1535717],
                [106.4341146, 11.152919],
                [106.4337176, 11.1523138],
                [106.4330216, 11.1510138],
                [106.4327735, 11.1506822],
                [106.4324194, 11.1504032],
                [106.4319742, 11.1502217],
                [106.4309013, 11.1500875],
                [106.4304493, 11.1500072],
                [106.4300269, 11.1498454],
                [106.4298096, 11.1496532],
                [106.4296862, 11.1493796],
                [106.4296943, 11.1490796],
                [106.4297882, 11.1488217],
                [106.4300671, 11.1484611],
                [106.431824, 11.1464295],
                [106.4337873, 11.144519],
                [106.434195, 11.1441085],
                [106.4345652, 11.1436348],
                [106.4349192, 11.14314],
                [106.435681, 11.1416926],
                [106.4360028, 11.1412505],
                [106.4365017, 11.1408084],
                [106.440002, 11.1388136],
                [106.4405867, 11.1384478],
                [106.4411688, 11.1379819],
                [106.4424884, 11.1367819],
                [106.4429122, 11.1364924],
                [106.4433092, 11.1363082],
                [106.4437518, 11.1361766],
                [106.4445645, 11.1360819],
                [106.4459967, 11.1360082],
                [106.4477561, 11.1360302],
                [106.4501488, 11.1361082],
                [106.4509213, 11.1362345],
                [106.4516401, 11.1365082],
                [106.4528686, 11.137524],
                [106.4550894, 11.1395873],
                [106.4557224, 11.1402347],
                [106.4561006, 11.1406978],
                [106.4564306, 11.1413229],
                [106.456975, 11.1429505],
                [106.4576697, 11.1444611],
                [106.4578253, 11.1448466],
                [106.4579621, 11.1453716],
                [106.4582545, 11.1476296],
                [106.4582598, 11.1484611],
                [106.4581418, 11.1495427],
                [106.458115, 11.1514032],
                [106.458056, 11.1519375],
                [106.4578843, 11.152498],
                [106.4576831, 11.1528611],
                [106.4573345, 11.1531611],
                [106.4568812, 11.1533506],
                [106.4564627, 11.1533769],
                [106.4560926, 11.1532927],
                [106.4557278, 11.1531138],
                [106.4541238, 11.1518822],
                [106.4537135, 11.1516875],
                [106.453279, 11.1516059],
                [106.4527747, 11.1515927],
                [106.4523455, 11.1516638],
                [106.4519379, 11.1518585],
                [106.4516857, 11.1522927],
                [106.4515516, 11.152848],
                [106.4514846, 11.1536453],
                [106.4515221, 11.1544453],
                [106.4516616, 11.15554],
                [106.4519379, 11.1566177],
                [106.4522812, 11.1574768],
                [106.452941, 11.1584637],
                [106.4537537, 11.1592347],
                [106.4546496, 11.1598084],
                [106.4555857, 11.1602189],
                [106.4560658, 11.1603083],
                [106.4565432, 11.1602741],
                [106.4570555, 11.1601057],
                [106.4575624, 11.1596899],
                [106.4585401, 11.1587255],
                [106.4589867, 11.1584294],
                [106.4596438, 11.1582531],
                [106.4603412, 11.15834],
                [106.4611781, 11.15874],
                [106.4623475, 11.1596426],
                [106.4627954, 11.1598505],
                [106.4633104, 11.1599031],
                [106.4638562, 11.1598189],
                [106.4643055, 11.1595768],
                [106.4646488, 11.15924],
                [106.4659685, 11.1575242],
                [106.466462, 11.1570716],
                [106.4671594, 11.1565979],
                [106.467956, 11.1561979],
                [106.469847, 11.1555821],
                [106.4702761, 11.1553637],
                [106.4706168, 11.1551032],
                [106.4708447, 11.1547585],
                [106.4709896, 11.1543559],
                [106.4710835, 11.1539585],
                [106.4710459, 11.1534295],
                [106.4709091, 11.1529111],
                [106.4705846, 11.1523164],
                [106.4699677, 11.1513454],
                [106.4689431, 11.1501769],
                [106.4675885, 11.1489164],
                [106.4674142, 11.1486138],
                [106.4673337, 11.1482611],
                [106.4673498, 11.1479769],
                [106.4674437, 11.1477032],
                [106.4676315, 11.1474559],
                [106.4679104, 11.147198],
                [106.4691737, 11.146448],
                [106.4696565, 11.1460664],
                [106.4699838, 11.1457085],
                [106.4703378, 11.1452295],
                [106.4709145, 11.1443677],
                [106.4718801, 11.1435005],
                [106.4725667, 11.14319],
                [106.4735363, 11.1430426],
                [106.4757156, 11.1428032],
                [106.4786231, 11.1423558],
                [106.479747, 11.1422979],
                [106.4807877, 11.1423163],
                [106.4821422, 11.1424031],
                [106.4832312, 11.1426137],
                [106.4842987, 11.1430821],
                [106.4857578, 11.1437927],
                [106.4864981, 11.1440558],
                [106.4872438, 11.1442558],
                [106.48855, 11.1444769],
                [106.4900279, 11.1446243],
                [106.491369, 11.1444979],
                [106.4927584, 11.14414],
                [106.4958, 11.1432032],
                [106.4969695, 11.1427426],
                [106.4973557, 11.1425374],
                [106.4977661, 11.1422584],
                [106.4992601, 11.1411689],
                [106.4999279, 11.140782],
                [106.5006548, 11.140482],
                [106.5013817, 11.1402899],
                [106.5021, 11.14032],
                [106.5026, 11.14052],
                [106.5033, 11.14081],
                [106.5047, 11.14159],
                [106.5051, 11.14179],
                [106.5055, 11.14195],
                [106.506, 11.14215],
                [106.5063, 11.14223],
                [106.5067, 11.14224],
                [106.5071, 11.14224],
                [106.5073, 11.14221],
                [106.5075, 11.14208],
                [106.5077, 11.14183],
                [106.5078, 11.14161],
                [106.508, 11.14103],
                [106.508, 11.14043],
                [106.5083, 11.13974],
                [106.5093, 11.13821],
                [106.5102, 11.13707],
                [106.5107, 11.13651],
                [106.5113, 11.13555],
                [106.512, 11.13391],
                [106.5127, 11.13224],
                [106.5132, 11.13043],
                [106.5138, 11.12862],
                [106.5142, 11.12775],
                [106.5159, 11.12542],
                [106.5174, 11.12404],
                [106.5193, 11.12278],
                [106.5212, 11.12181],
                [106.5222, 11.12151],
                [106.5234, 11.12132],
                [106.5244, 11.12124],
                [106.5253, 11.12135],
                [106.5258, 11.12149],
                [106.5263, 11.12134],
                [106.5267, 11.12104],
                [106.5268, 11.12067],
                [106.527, 11.12023],
                [106.527, 11.1196],
                [106.5269, 11.11899],
                [106.5269, 11.11833],
                [106.527, 11.11752],
                [106.5272, 11.11704],
                [106.5275, 11.11626],
                [106.5278, 11.11497],
                [106.5278, 11.11415],
                [106.5276, 11.1133],
                [106.5274, 11.11219],
                [106.527, 11.10967],
                [106.5267, 11.10698],
                [106.5263, 11.10586],
                [106.5253, 11.10449],
                [106.5241, 11.10362],
                [106.5224, 11.10323],
                [106.521, 11.10298],
                [106.5207, 11.10293],
                [106.5197, 11.10279],
                [106.5185, 11.10258],
                [106.5177, 11.10231],
                [106.517, 11.10203],
                [106.5164, 11.10156],
                [106.5163, 11.10124],
                [106.5162, 11.10069],
                [106.5162, 11.10017],
                [106.5164, 11.09928],
                [106.5168, 11.09836],
                [106.5173, 11.09741],
                [106.5183, 11.09611],
                [106.519, 11.09508],
                [106.5193, 11.09463],
                [106.5201, 11.09334],
                [106.5206, 11.09259],
                [106.5211, 11.09169],
                [106.5216, 11.09087],
                [106.5224, 11.09005],
                [106.5235, 11.08954],
                [106.5242, 11.08953],
                [106.5251, 11.08986],
                [106.5262, 11.09079],
                [106.5273, 11.09156],
                [106.5291, 11.09223],
                [106.5304, 11.09224],
                [106.5313, 11.09224],
                [106.5323, 11.092],
                [106.5327, 11.09181],
                [106.533, 11.09158],
                [106.5334, 11.09114],
                [106.5338, 11.09055],
                [106.534, 11.08977],
                [106.534, 11.08922],
                [106.5339, 11.08852],
                [106.5333, 11.0876],
                [106.5325, 11.08636],
                [106.5303, 11.08421],
                [106.5279, 11.08208],
                [106.5268, 11.0807],
                [106.5253, 11.07808],
                [106.5241, 11.07535],
                [106.5238, 11.0743],
                [106.5238, 11.07377],
                [106.5238, 11.07338],
                [106.5241, 11.07226],
                [106.5246, 11.07102],
                [106.5252, 11.06973],
                [106.5254, 11.06931],
                [106.5259, 11.06883],
                [106.5266, 11.06833],
                [106.5276, 11.06774],
                [106.5284, 11.06705],
                [106.5288, 11.06669],
                [106.5291, 11.06628],
                [106.5295, 11.06565],
                [106.53, 11.06504],
                [106.5309, 11.06242],
                [106.5322, 11.06017],
                [106.5329, 11.0591],
                [106.5348, 11.05745],
                [106.5363, 11.05658],
                [106.5402, 11.0549],
                [106.5423, 11.05425],
                [106.5444, 11.05337],
                [106.5462, 11.0521],
                [106.5472, 11.051],
                [106.5481, 11.04879],
                [106.5483, 11.04795],
                [106.5489, 11.04704],
                [106.5495, 11.0465],
                [106.5496, 11.04644],
                [106.55, 11.04616],
                [106.5503, 11.04603],
                [106.551, 11.04588],
                [106.5513, 11.04585],
                [106.552, 11.04577],
                [106.5549, 11.04576],
                [106.5582, 11.04569],
                [106.5584, 11.04566],
                [106.5587004, 11.0456277],
                [106.5597, 11.04552],
                [106.5624, 11.0452],
                [106.5635, 11.04507],
                [106.5653, 11.04506],
                [106.5667, 11.04504],
                [106.5675, 11.04545],
                [106.5681, 11.04593],
                [106.5685, 11.04695],
                [106.5686, 11.04755],
                [106.5686, 11.04901],
                [106.5687, 11.04988],
                [106.569, 11.0509],
                [106.5697, 11.05205],
                [106.571, 11.05381],
                [106.5713, 11.0542],
                [106.5717, 11.05454],
                [106.572, 11.05463],
                [106.5723, 11.05467],
                [106.5727, 11.05461],
                [106.5732, 11.05438],
                [106.574, 11.05373],
                [106.5744, 11.05323],
                [106.5748, 11.05271],
                [106.575, 11.05234],
                [106.5754, 11.05188],
                [106.5777, 11.04973],
                [106.5787, 11.04888],
                [106.5788, 11.04877],
                [106.5795, 11.04803],
                [106.58, 11.047],
                [106.5804, 11.04572],
                [106.581, 11.04261],
                [106.5812, 11.0411],
                [106.5813, 11.0406],
                [106.5816, 11.04012],
                [106.5821, 11.03929],
                [106.5829287, 11.0381436],
                [106.5831, 11.03784],
                [106.5836, 11.03745],
                [106.5843, 11.03719],
                [106.5855, 11.03668],
                [106.5862, 11.03653],
                [106.5868, 11.03638],
                [106.5881, 11.03627],
                [106.5889, 11.03626],
                [106.5897, 11.03641],
                [106.5902, 11.03657],
                [106.5908, 11.03692],
                [106.5912, 11.03751],
                [106.5916, 11.03873],
                [106.5917, 11.04025],
                [106.5921, 11.04195],
                [106.5929, 11.04343],
                [106.5938, 11.04442],
                [106.5946, 11.04507],
                [106.5954, 11.0454],
                [106.5972, 11.04565],
                [106.5983, 11.04559],
                [106.5994, 11.0453],
                [106.6003, 11.04446],
                [106.6012, 11.04339],
                [106.6021, 11.04202],
                [106.6027, 11.04126],
                [106.603215, 11.0404139],
                [106.6034321, 11.0395281],
                [106.6022779, 11.0379704],
                [106.6007443, 11.0361292],
                [106.5987, 11.03381],
                [106.5962, 11.03107],
                [106.5957, 11.03027],
                [106.5954, 11.02922],
                [106.5952, 11.02817],
                [106.595, 11.02549],
                [106.5951, 11.02439],
                [106.5956, 11.02255],
                [106.5968, 11.01863],
                [106.5973, 11.0173],
                [106.5979, 11.01598],
                [106.598, 11.01582],
                [106.5988, 11.01511],
                [106.5998, 11.01453],
                [106.6005, 11.0143],
                [106.6012, 11.01413],
                [106.6015, 11.01406],
                [106.6025, 11.01381],
                [106.6043, 11.01335],
                [106.607, 11.01235],
                [106.6088, 11.01184],
                [106.6104, 11.01166],
                [106.6115, 11.01166],
                [106.6143, 11.01172],
                [106.6149, 11.01172],
                [106.6156, 11.01166],
                [106.6162, 11.01159],
                [106.6163, 11.01159],
                [106.6171, 11.01122],
                [106.6178, 11.01087],
                [106.6184, 11.0104],
                [106.619, 11.00964],
                [106.6196, 11.00892],
                [106.6203, 11.00774],
                [106.6211, 11.00593],
                [106.6213, 11.00501],
                [106.6212, 11.00313],
                [106.6210022, 11.001863],
                [106.6209, 11.00159],
                [106.6205022, 11.000433],
                [106.6199022, 10.999553],
                [106.6185022, 10.997013],
                [106.6175022, 10.995153],
                [106.6168022, 10.993973],
                [106.6167022, 10.993713],
                [106.6163022, 10.992933],
                [106.6160022, 10.991803],
                [106.6159022, 10.991263],
                [106.6160022, 10.990823],
                [106.6162022, 10.989803],
                [106.6168022, 10.988623],
                [106.6178022, 10.987603],
                [106.6194022, 10.986513],
                [106.6223022, 10.984573],
                [106.6239022, 10.982703],
                [106.6251022, 10.981183],
                [106.6261022, 10.980293],
                [106.6270022, 10.979683],
                [106.6279022, 10.979243],
                [106.6280022, 10.979193],
                [106.6305022, 10.978513],
                [106.6312022, 10.978313],
                [106.6337022, 10.977713],
                [106.6360022, 10.977443],
                [106.6374022, 10.977443],
                [106.6386022, 10.977983],
                [106.6406022, 10.979323],
                [106.6415022, 10.979913],
                [106.6431022, 10.980673],
                [106.6433788, 10.9807607],
                [106.6443, 10.98104],
                [106.6459, 10.9813],
                [106.6467, 10.98123],
                [106.6475, 10.98096],
                [106.648, 10.98061],
                [106.6486, 10.98004],
                [106.6492, 10.97888],
                [106.6498, 10.97598],
                [106.6502, 10.97444],
                [106.6501987, 10.9736407],
                [106.6501, 10.9729347],
                [106.6499884, 10.9723419],
                [106.6498, 10.97154],
                [106.6492, 10.96968],
                [106.6488, 10.96706],
                [106.6488, 10.96622],
                [106.6489, 10.96449],
                [106.6496, 10.96138],
                [106.6505, 10.9587],
                [106.6518, 10.95474],
                [106.6521, 10.95353],
                [106.6521831, 10.9526902],
                [106.6522, 10.95252],
                [106.6524, 10.95096],
                [106.6525, 10.94943],
                [106.6524, 10.9472],
                [106.6518, 10.94285],
                [106.6516, 10.94217],
                [106.6508, 10.93998],
                [106.6504, 10.93846],
                [106.6502, 10.93742],
                [106.6502, 10.9362],
                [106.6504, 10.93448],
                [106.6508, 10.93312],
                [106.6515, 10.93187],
                [106.6525, 10.93027],
                [106.6534, 10.92909],
                [106.6553, 10.92746],
                [106.6574, 10.92579],
                [106.6589, 10.92493],
                [106.6632, 10.92268],
                [106.6667, 10.92151],
                [106.669, 10.92103],
                [106.6698, 10.92102],
                [106.6715, 10.92138],
                [106.6765, 10.92308],
                [106.6784, 10.92368],
                [106.6795, 10.92387],
                [106.6803, 10.9239],
                [106.6808, 10.9239],
                [106.6815, 10.92359],
                [106.6827, 10.92291],
                [106.6838, 10.92216],
                [106.6849, 10.92124],
                [106.686, 10.91965],
                [106.6873, 10.91731],
                [106.6885, 10.9148],
                [106.6902, 10.91118],
                [106.690327, 10.9108388],
                [106.6917, 10.90715],
                [106.6929, 10.90424],
                [106.693, 10.90395],
                [106.6934, 10.90271],
                [106.6939, 10.90065],
                [106.6942, 10.89856],
                [106.6944, 10.8975],
                [106.6945, 10.89666],
                [106.6945, 10.89571],
                [106.6942, 10.89412],
                [106.6935, 10.89278],
                [106.6919, 10.88995],
                [106.6917, 10.88948],
                [106.6916, 10.88933],
                [106.6915089, 10.8892131],
                [106.6904, 10.88779],
                [106.6897, 10.88658],
                [106.6894, 10.88591],
                [106.6893, 10.88489],
                [106.6892, 10.88381],
                [106.6892, 10.88297],
                [106.6894, 10.88216],
                [106.6898, 10.88067],
                [106.6904, 10.87965],
                [106.6934, 10.87598],
                [106.6952, 10.87337],
                [106.6966, 10.8717],
                [106.6968659, 10.8714341],
                [106.6969, 10.8714],
                [106.698, 10.87035],
                [106.6995, 10.86896],
                [106.7007142, 10.8679578],
                [106.7023069, 10.8672477],
                [106.7043915, 10.8669246],
                [106.7090249, 10.8665977],
                [106.7108069, 10.8663793],
                [106.712064, 10.8660836],
                [106.7130981, 10.8657225],
                [106.7138366, 10.8666952],
                [106.7143341, 10.8671829],
                [106.715041, 10.8678706],
                [106.7154076, 10.8682705],
                [106.7155141, 10.8685454],
                [106.7155382, 10.8687218],
                [106.7155051, 10.8688964],
                [106.7154322, 10.8691239],
                [106.7152828, 10.8694526],
                [106.7151528, 10.8701953],
                [106.7151708, 10.8708768],
                [106.7151197, 10.8715343],
                [106.7150579, 10.8725458],
                [106.7153743, 10.8727641],
                [106.7158043, 10.8728694],
                [106.7162931, 10.872861],
                [106.7167596, 10.8727678],
                [106.7173511, 10.8725468],
                [106.7179142, 10.8724119],
                [106.7186356, 10.8723771],
                [106.7196322, 10.8723596],
                [106.7200683, 10.872298],
                [106.7204912, 10.8721423],
                [106.720886, 10.8719088],
                [106.7212584, 10.8717613],
                [106.7217687, 10.8716899],
                [106.7224609, 10.8716514],
                [106.7234083, 10.8717308],
                [106.7236453, 10.8717823],
                [106.7235696, 10.8719882],
                [106.7235941, 10.8722896],
                [106.7235698, 10.8724866],
                [106.7234455, 10.8727483],
                [106.7232, 10.87293],
                [106.7227091, 10.8732955],
                [106.7222577, 10.8740173],
                [106.7218061, 10.8747291],
                [106.7216879, 10.8749534],
                [106.7216455, 10.8752015],
                [106.7215, 10.87567],
                [106.7214, 10.87599],
                [106.7213747, 10.8762088],
                [106.7212639, 10.8763863],
                [106.7210755, 10.8764728],
                [106.7207737, 10.8764924],
                [106.7202851, 10.8766102],
                [106.719736, 10.8766923],
                [106.7191858, 10.8768761],
                [106.7186704, 10.8768487],
                [106.7182918, 10.8768729],
                [106.7177043, 10.8771394],
                [106.71737, 10.8772031],
                [106.7167957, 10.8771831],
                [106.7163957, 10.8771609],
                [106.7157766, 10.8772957],
                [106.7151737, 10.8774445],
                [106.7140343, 10.8769561],
                [106.7138223, 10.877743],
                [106.7128507, 10.8774169],
                [106.7125877, 10.8779252],
                [106.7134316, 10.878843],
                [106.7137961, 10.8791176],
                [106.7178992, 10.8809018],
                [106.7170858, 10.8823103],
                [106.7169064, 10.8827826],
                [106.7167665, 10.8834107],
                [106.7166571, 10.8844086],
                [106.7166693, 10.8846968],
                [106.7166998, 10.8849373],
                [106.7167728, 10.8851651],
                [106.7171731, 10.8859974],
                [106.7172872, 10.8863447],
                [106.7173455, 10.8866154],
                [106.7173818, 10.8869447],
                [106.7174, 10.88722],
                [106.7175077, 10.8876087],
                [106.7175863, 10.8877483],
                [106.7174517, 10.8879078],
                [106.7172785, 10.8879831],
                [106.7170399, 10.8879851],
                [106.7167228, 10.8878945],
                [106.7164789, 10.8878277],
                [106.7162137, 10.8877978],
                [106.715807, 10.8877729],
                [106.7155501, 10.8877036],
                [106.7153489, 10.8875499],
                [106.7149396, 10.8871326],
                [106.7147384, 10.8869559],
                [106.7145793, 10.8868723],
                [106.7144266, 10.8868687],
                [106.7141687, 10.8870066],
                [106.7139148, 10.8872283],
                [106.7138562, 10.8873678],
                [106.7138627, 10.8875052],
                [106.7139528, 10.8876209],
                [106.7142579, 10.887832],
                [106.7144549, 10.8879986],
                [106.7145077, 10.8880861],
                [106.7145051, 10.8881524],
                [106.714449, 10.8882287],
                [106.7142, 10.88834],
                [106.7139526, 10.8884363],
                [106.713714, 10.8885739],
                [106.7134711, 10.8887852],
                [106.7133084, 10.8889902],
                [106.7131612, 10.8891961],
                [106.7131098, 10.8893112],
                [106.7131045, 10.8894264],
                [106.7131311, 10.8897508],
                [106.7131068, 10.8899024],
                [106.7153, 10.89262],
                [106.7159, 10.89347],
                [106.7164, 10.89417],
                [106.7177, 10.89642],
                [106.7178504, 10.8962968],
                [106.7179, 10.89623],
                [106.7186, 10.89566],
                [106.7217, 10.89345],
                [106.7233, 10.89252],
                [106.725, 10.89144],
                [106.7257, 10.89113],
                [106.7268, 10.89064],
                [106.727, 10.8905],
                [106.7274826, 10.8902321],
                [106.7285966, 10.8910541],
                [106.728773, 10.8910426],
                [106.7289029, 10.8906171],
                [106.7291678, 10.8899121],
                [106.7295223, 10.8899246],
                [106.7295599, 10.8896664],
                [106.7328295, 10.8907332],
                [106.7327554, 10.8903735],
                [106.7326724, 10.8900156],
                [106.7325806, 10.8896598],
                [106.7324801, 10.8893063],
                [106.7323708, 10.8889553],
                [106.7322541, 10.88861],
                [106.7321289, 10.8882675],
                [106.7320114, 10.8879965],
                [106.7319047, 10.8877491],
                [106.7318224, 10.8875664],
                [106.7317486, 10.8873802],
                [106.7316848, 10.8871956],
                [106.7316293, 10.8870085],
                [106.7315876, 10.8868297],
                [106.7315636, 10.8866832],
                [106.7314616, 10.8859131],
                [106.7365105, 10.8848141],
                [106.7366588, 10.8847818],
                [106.7367133, 10.8847699],
                [106.7368171, 10.8847474],
                [106.7350031, 10.8816853],
                [106.7341768, 10.8805112],
                [106.7352481, 10.8802377],
                [106.7353749, 10.8802261],
                [106.7360653, 10.8796048],
                [106.7366584, 10.8806],
                [106.7380559, 10.8797944],
                [106.7384421, 10.8793914],
                [106.7390013, 10.8799274],
                [106.7394036, 10.8804792],
                [106.7396329, 10.8808625],
                [106.7396477, 10.8808849],
                [106.7396693, 10.8809044],
                [106.739696, 10.8809165],
                [106.7397217, 10.8809199],
                [106.7397474, 10.8809163],
                [106.7397711, 10.8809059],
                [106.7398299, 10.8808626],
                [106.7398917, 10.8808234],
                [106.7399561, 10.8807887],
                [106.740043, 10.8807507],
                [106.7401143, 10.8807285],
                [106.740195, 10.8807206],
                [106.7402565, 10.8807268],
                [106.7403242, 10.880741],
                [106.7403898, 10.8807626],
                [106.7404524, 10.8807913],
                [106.7405114, 10.8808269],
                [106.7406275, 10.88094],
                [106.7407969, 10.8811049],
                [106.7409915, 10.8812944],
                [106.7410393, 10.881341],
                [106.7410603, 10.8813614],
                [106.7411417, 10.8814406],
                [106.7412387, 10.8813955],
                [106.7413558, 10.8813411],
                [106.741866, 10.881104],
                [106.742149, 10.8809725],
                [106.7422829, 10.8809102],
                [106.7423603, 10.8808742],
                [106.7424711, 10.8808228],
                [106.7425146, 10.8808025],
                [106.7427716, 10.8806831],
                [106.7429746, 10.8805887],
                [106.7431145, 10.8805237],
                [106.7432504, 10.8804619],
                [106.7432807, 10.8804482],
                [106.743384, 10.8805885],
                [106.7434309, 10.8806676],
                [106.7434658, 10.8807358],
                [106.743506, 10.8808322],
                [106.7435357, 10.8809347],
                [106.7436254, 10.880873],
                [106.7436831, 10.8808177],
                [106.7437273, 10.8807676],
                [106.7437697, 10.8807094],
                [106.7437957, 10.8807291],
                [106.7442251, 10.8810541],
                [106.7449812, 10.8816265],
                [106.7456581, 10.8807856],
                [106.7446482, 10.8800647],
                [106.7454818, 10.8791418],
                [106.7471082, 10.8774339],
                [106.7474536, 10.8776133],
                [106.7483694, 10.8781803],
                [106.7493258, 10.8767777],
                [106.750346, 10.8772876],
                [106.7505469, 10.8769702],
                [106.7508767, 10.8763745],
                [106.7511604, 10.875901],
                [106.7512859, 10.8756528],
                [106.7513234, 10.8754996],
                [106.7507928, 10.8753258],
                [106.7496652, 10.874944],
                [106.7464238, 10.873882],
                [106.7470569, 10.8720434],
                [106.7459912, 10.8717037],
                [106.7471245, 10.8698075],
                [106.7473067, 10.8694845],
                [106.7479092, 10.8692827],
                [106.7478732, 10.8691666],
                [106.7478, 10.86893],
                [106.7478575, 10.8683813],
                [106.7479731, 10.867279],
                [106.7481, 10.86673],
                [106.748625, 10.8656401],
                [106.7491059, 10.8658226],
                [106.7494, 10.86523],
                [106.7499, 10.86444],
                [106.7500353, 10.8642951],
                [106.7502467, 10.8644149],
                [106.750521, 10.8640428],
                [106.7512102, 10.8644268],
                [106.7514785, 10.8640595],
                [106.7515679, 10.8638639],
                [106.7515923, 10.8637202],
                [106.7516204, 10.8634757],
                [106.7517472, 10.8634587],
                [106.7520051, 10.8635203],
                [106.7522788, 10.8636497],
                [106.7524971, 10.863786],
                [106.7526449, 10.8639954],
                [106.7528282, 10.8644404],
                [106.7529403, 10.8645617],
                [106.7532304, 10.8647105],
                [106.7533102, 10.8647579],
                [106.7533748, 10.8648164],
                [106.753433, 10.8649023],
                [106.753526, 10.8650203],
                [106.7537465, 10.8651952],
                [106.7536704, 10.8653579],
                [106.7536424, 10.8654394],
                [106.7535757, 10.8657096],
                [106.75397, 10.8658993],
                [106.7544616, 10.866196],
                [106.7546015, 10.8662775],
                [106.7549172, 10.8664007],
                [106.7550399, 10.8664175],
                [106.7551951, 10.8664113],
                [106.7555758, 10.8663785],
                [106.7557346, 10.8663688],
                [106.7558844, 10.8663706],
                [106.7560405, 10.8663954],
                [106.7561965, 10.866445],
                [106.7575009, 10.8670868],
                [106.7576214, 10.8670724],
                [106.757721, 10.8670698],
                [106.7580929, 10.8671225],
                [106.7583481, 10.8671125],
                [106.7585872, 10.8670709],
                [106.7591425, 10.8669287],
                [106.7592782, 10.8668992],
                [106.7594118, 10.8668803],
                [106.7595389, 10.8668755],
                [106.7597025, 10.8668882],
                [106.7601483, 10.866958],
                [106.7603199, 10.8670025],
                [106.7606083, 10.8671059],
                [106.7609951, 10.8672298],
                [106.7610992, 10.8672488],
                [106.7612086, 10.8672594],
                [106.7622395, 10.8672623],
                [106.7622687, 10.8674081],
                [106.7623051, 10.867545],
                [106.7623535, 10.8676572],
                [106.7624163, 10.8677711],
                [106.7630843, 10.8687709],
                [106.7631743, 10.8689733],
                [106.7632314, 10.8691565],
                [106.7632764, 10.8693576],
                [106.7632946, 10.8695952],
                [106.7632849, 10.8699203],
                [106.7630409, 10.870043],
                [106.762939, 10.8701099],
                [106.7624925, 10.8705813],
                [106.7624287, 10.8706615],
                [106.7623655, 10.8707542],
                [106.7623164, 10.8708588],
                [106.7622759, 10.8709957],
                [106.7622482, 10.8710479],
                [106.7622139, 10.8710848],
                [106.7621626, 10.8711245],
                [106.7616563, 10.8714822],
                [106.7613581, 10.87167],
                [106.7608589, 10.8720194],
                [106.7603765, 10.8723815],
                [106.7608196, 10.8729965],
                [106.760917, 10.873156],
                [106.7610848, 10.873335],
                [106.7613152, 10.8735821],
                [106.7618149, 10.8743939],
                [106.7614456, 10.8745023],
                [106.7616879, 10.8752012],
                [106.7618957, 10.8755389],
                [106.7615901, 10.8776235],
                [106.7614457, 10.8785209],
                [106.7619415, 10.8787059],
                [106.7618036, 10.8789986],
                [106.7612231, 10.8800662],
                [106.7607924, 10.8807342],
                [106.7604753, 10.8812944],
                [106.7610307, 10.8819342],
                [106.7618778, 10.882589],
                [106.7622051, 10.882892],
                [106.7625908, 10.8831538],
                [106.763295, 10.8836668],
                [106.7635609, 10.883864],
                [106.7640119, 10.8849425],
                [106.764477, 10.8848699],
                [106.7645547, 10.8855154],
                [106.7645532, 10.8858471],
                [106.764729, 10.8863698],
                [106.7649905, 10.8866611],
                [106.7651173, 10.887038],
                [106.7652359, 10.8876008],
                [106.7653966, 10.8878388],
                [106.7657617, 10.8882626],
                [106.7658529, 10.888497],
                [106.7660824, 10.8887779],
                [106.7662, 10.88913],
                [106.7664269, 10.8902927],
                [106.7666661, 10.8910259],
                [106.7668362, 10.8915634],
                [106.7672603, 10.8915413],
                [106.7674971, 10.8915653],
                [106.7676824, 10.8916442],
                [106.7673248, 10.8922561],
                [106.7672351, 10.892517],
                [106.7677341, 10.8924072],
                [106.7680671, 10.8923533],
                [106.7684371, 10.8923118],
                [106.7684504, 10.8940349],
                [106.7690604, 10.8940165],
                [106.7692802, 10.8939507],
                [106.7694708, 10.8938231],
                [106.7696499, 10.8936455],
                [106.7698241, 10.8934305],
                [106.7701485, 10.8931015],
                [106.7703768, 10.8927882],
                [106.7706297, 10.8924474],
                [106.7707906, 10.8922158],
                [106.7709794, 10.8919128],
                [106.7712065, 10.8916156],
                [106.7714849, 10.8912996],
                [106.7719065, 10.8908056],
                [106.7721065, 10.8905356],
                [106.7723584, 10.890171],
                [106.7727065, 10.8897056],
                [106.7730636, 10.8894143],
                [106.7736053, 10.8889007],
                [106.7740065, 10.8885256],
                [106.7744065, 10.8881856],
                [106.7749065, 10.8877156],
                [106.7751065, 10.8875456],
                [106.7753065, 10.8873156],
                [106.7756065, 10.8870056],
                [106.7758065, 10.8867256],
                [106.7759065, 10.8863156],
                [106.7761065, 10.8859556],
                [106.7765065, 10.8855456],
                [106.7769065, 10.8849856],
                [106.7773065, 10.8846156],
                [106.7776065, 10.8843556],
                [106.7781065, 10.8838656],
                [106.7787065, 10.8832856],
                [106.7792065, 10.8828356],
                [106.7794065, 10.8825156],
                [106.7796065, 10.8822656],
                [106.7801065, 10.8817356],
                [106.7805065, 10.8813356],
                [106.7806065, 10.8811856],
                [106.7807065, 10.8808856],
                [106.7809065, 10.8804256],
                [106.7811065, 10.8800856],
                [106.7812065, 10.8797656],
                [106.7812065, 10.8793556],
                [106.7812065, 10.8789056],
                [106.7813065, 10.8785756],
                [106.7813065, 10.8782456],
                [106.7815065, 10.8779856],
                [106.7818065, 10.8777556],
                [106.7820065, 10.8773856],
                [106.7821065, 10.8771956],
                [106.7820065, 10.8767256],
                [106.7820065, 10.8763956],
                [106.7820065, 10.8760556],
                [106.7820045, 10.8755774],
                [106.7819324, 10.8751403],
                [106.7818898, 10.8747285],
                [106.7818633, 10.8744676],
                [106.7819143, 10.8742614],
                [106.7818545, 10.873959],
                [106.7816865, 10.8736117],
                [106.7816304, 10.873262],
                [106.7813588, 10.8726395],
                [106.7812795, 10.8724331],
                [106.7811707, 10.8722431],
                [106.7809946, 10.8719094],
                [106.7809325, 10.8717341],
                [106.7806297, 10.8708237],
                [106.7805384, 10.8705428],
                [106.7803479, 10.8700016],
                [106.7802299, 10.8696588],
                [106.7801744, 10.8694038],
                [106.7801412, 10.869251],
                [106.7801581, 10.8689592],
                [106.7802065, 10.8688357],
                [106.7803166, 10.8686877],
                [106.7803573, 10.8685759],
                [106.7803318, 10.8682665],
                [106.7816848, 10.867825],
                [106.7824434, 10.8676126],
                [106.7830268, 10.8674882],
                [106.7836853, 10.8673943],
                [106.7843628, 10.8673392],
                [106.7877281, 10.8672119],
                [106.7877294, 10.8672361],
                [106.7877317, 10.8672784],
                [106.7877352, 10.8673454],
                [106.7877441, 10.8676881],
                [106.7877491, 10.8678042],
                [106.7877574, 10.8678855],
                [106.7877599, 10.8679654],
                [106.787776, 10.8682281],
                [106.7877896, 10.8684438],
                [106.7878013, 10.8685988],
                [106.7878181, 10.8688223],
                [106.7878668, 10.8693327],
                [106.7879089, 10.8697119],
                [106.7879354, 10.869926],
                [106.7879506, 10.8700758],
                [106.7880259, 10.8703449],
                [106.7880285, 10.8703542],
                [106.7880763, 10.8704868],
                [106.7881332, 10.8705995],
                [106.7882626, 10.8708321],
                [106.7889215, 10.8719524],
                [106.7897405, 10.871951],
                [106.7905629, 10.8719562],
                [106.7913261, 10.8719659],
                [106.7921659, 10.8719756],
                [106.7921713, 10.8725947],
                [106.7935278, 10.8725871],
                [106.794014, 10.8730185],
                [106.7945917, 10.8734009],
                [106.794665, 10.8734465],
                [106.7947482, 10.8734869],
                [106.7948471, 10.8735238],
                [106.7949478, 10.8735439],
                [106.7950543, 10.8735558],
                [106.795159, 10.8735589],
                [106.7958812, 10.8735609],
                [106.7958854, 10.8732205],
                [106.7978258, 10.8732082],
                [106.7977615, 10.8730394],
                [106.7977186, 10.8728808],
                [106.7977151, 10.8727581],
                [106.7977282, 10.8726699],
                [106.7977503, 10.8725919],
                [106.7978005, 10.872478],
                [106.7978832, 10.8723569],
                [106.7979786, 10.8722325],
                [106.7981213, 10.8720987],
                [106.7981744, 10.8720639],
                [106.7982243, 10.8720439],
                [106.7982925, 10.8720308],
                [106.7985452, 10.8720084],
                [106.7986664, 10.8720074],
                [106.7988031, 10.8720363],
                [106.7989507, 10.872085],
                [106.7990718, 10.8721482],
                [106.8001103, 10.8728265],
                [106.8013119, 10.873599],
                [106.8020906, 10.8735599],
                [106.8025701, 10.8735069],
                [106.8030855, 10.8734979],
                [106.8034095, 10.8735145],
                [106.8040945, 10.8736091],
                [106.8042341, 10.8736374],
                [106.8043722, 10.8736903],
                [106.8045717, 10.873802],
                [106.8047477, 10.8739421],
                [106.8048926, 10.8741112],
                [106.8050684, 10.8743472],
                [106.8051187, 10.8744569],
                [106.8051627, 10.8746461],
                [106.8052142, 10.8748681],
                [106.805259, 10.8750194],
                [106.8056049, 10.8749478],
                [106.8065631, 10.8745165],
                [106.8074482, 10.8740319],
                [106.8078606, 10.873769],
                [106.8079928, 10.8735598],
                [106.8084835, 10.8729171],
                [106.8105989, 10.8757698],
                [106.8122256, 10.8780031],
                [106.8128195, 10.878789],
                [106.8130656, 10.8791123],
                [106.8131092, 10.8790767],
                [106.813208, 10.8789894],
                [106.8133317, 10.8788769],
                [106.8133875, 10.8788285],
                [106.8135779, 10.8786527],
                [106.8136053, 10.878647],
                [106.8136476, 10.878632],
                [106.8138355, 10.8784474],
                [106.8138589, 10.8784244],
                [106.814667, 10.8792649],
                [106.8152443, 10.8796043],
                [106.8151507, 10.8797799],
                [106.8162917, 10.881227],
                [106.8165813, 10.8811058],
                [106.8168772, 10.8810003],
                [106.8171052, 10.8810029],
                [106.8171911, 10.8810661],
                [106.8173442, 10.881697],
                [106.8178166, 10.8828694],
                [106.8175924, 10.8828786],
                [106.8175364, 10.8829152],
                [106.8176368, 10.883558],
                [106.8174819, 10.8835977],
                [106.8177208, 10.8840671],
                [106.8180712, 10.8842222],
                [106.8181178, 10.8840762],
                [106.818412, 10.8841542],
                [106.8186906, 10.8841631],
                [106.8186835, 10.8843403],
                [106.8191082, 10.884243],
                [106.8191436, 10.8852127],
                [106.8193736, 10.8852509],
                [106.8193595, 10.8854073],
                [106.8194161, 10.8857027],
                [106.819402, 10.8857514],
                [106.8194444, 10.8857479],
                [106.81954, 10.8856888],
                [106.819632, 10.8856784],
                [106.8197028, 10.8857062],
                [106.8197523, 10.8857861],
                [106.8197842, 10.8858522],
                [106.8199859, 10.885873],
                [106.8200426, 10.8859425],
                [106.8200461, 10.8860642],
                [106.8201024, 10.8862731],
                [106.8204082, 10.886344],
                [106.8203897, 10.8861534],
                [106.8203466, 10.8860082],
                [106.8204329, 10.8859658],
                [106.8203743, 10.8857419],
                [106.8208088, 10.8856179],
                [106.8208581, 10.8856602],
                [106.8208735, 10.8857207],
                [106.8209536, 10.8857268],
                [106.8210398, 10.885748],
                [106.821123, 10.8857298],
                [106.8212093, 10.8856905],
                [106.8213289, 10.8857607],
                [106.8213382, 10.885951],
                [106.8213966, 10.8859647],
                [106.8216418, 10.8858547],
                [106.8217842, 10.8858868],
                [106.8220651, 10.8861701],
                [106.8221712, 10.8861909],
                [106.8223836, 10.8867505],
                [106.822426, 10.8867331],
                [106.8223836, 10.8870598],
                [106.8228345, 10.8872764],
                [106.8231381, 10.8873269],
                [106.8241281, 10.8882349],
                [106.8242309, 10.8891291],
                [106.8235777, 10.8910133],
                [106.8235777, 10.8911233],
                [106.8235777, 10.8925433],
                [106.8238948, 10.8928774],
                [106.8247914, 10.8926898],
                [106.8249443, 10.8928086],
                [106.8250914, 10.8928682],
                [106.8252866, 10.8930164],
                [106.8253451, 10.893156],
                [106.8253466, 10.8932105],
                [106.8249857, 10.8932738],
                [106.825002, 10.8935145],
                [106.8250931, 10.8937553],
                [106.8255076, 10.8936806],
                [106.8257669, 10.8936433],
                [106.8258688, 10.8936644],
                [106.8262569, 10.8944328],
                [106.8267285, 10.8947951],
                [106.8270544, 10.8945862],
                [106.8274513, 10.8944335],
                [106.8276927, 10.8943123],
                [106.8279449, 10.8942913],
                [106.8283579, 10.8945178],
                [106.8286583, 10.8946705],
                [106.8290714, 10.8947443],
                [106.8298537, 10.8949756],
                [106.8304938, 10.8960567],
                [106.8312663, 10.8965585],
                [106.8315225, 10.8967182],
                [106.8315875, 10.8966623],
                [106.831681, 10.8969058],
                [106.8321038, 10.8967102],
                [106.832364, 10.896814],
                [106.8323315, 10.8969498],
                [106.8323112, 10.8971254],
                [106.8323356, 10.8971813],
                [106.8327624, 10.897325],
                [106.8328072, 10.8972292],
                [106.8329169, 10.8972811],
                [106.8328268, 10.897503],
                [106.8330236, 10.8975904],
                [106.8331283, 10.8977003],
                [106.8332706, 10.8979957],
                [106.8333804, 10.8979598],
                [106.8335511, 10.8979718],
                [106.8339115, 10.8979766],
                [106.8339199, 10.8982586],
                [106.83433, 10.8983849],
                [106.8357333, 10.8983771],
                [106.8369689, 10.8983702],
                [106.8382178, 10.8983633],
                [106.838723, 10.898491],
                [106.8401265, 10.8988461],
                [106.8399404, 10.8996004],
                [106.8397869, 10.9002559],
                [106.8396395, 10.9009128],
                [106.8394873, 10.9015656],
                [106.8394404, 10.9017613],
                [106.8394152, 10.9019214],
                [106.8393822, 10.9020984],
                [106.8393487, 10.9023217],
                [106.8393242, 10.9025461],
                [106.8393086, 10.9027713],
                [106.839302, 10.902997],
                [106.835851, 10.9033351],
                [106.8350524, 10.9035094],
                [106.8347489, 10.9035873],
                [106.8345714, 10.9036653],
                [106.8340118, 10.9041743],
                [106.8334665, 10.9046704],
                [106.8331938, 10.9050868],
                [106.8327651, 10.9061241],
                [106.8326707, 10.9063525],
                [106.832498, 10.9065634],
                [106.832115, 10.9067468],
                [106.8319554, 10.9067418],
                [106.8316807, 10.9067331],
                [106.8307841, 10.9062975],
                [106.8304712, 10.9058343],
                [106.8301816, 10.9052657],
                [106.8300135, 10.9050043],
                [106.8298454, 10.904853],
                [106.8292202, 10.9044691],
                [106.8286123, 10.9041012],
                [106.8280829, 10.9039136],
                [106.8273042, 10.9038943],
                [106.8265256, 10.9038206],
                [106.8261344, 10.903703],
                [106.8257537, 10.9036049],
                [106.8253098, 10.9033543],
                [106.8247543, 10.9031724],
                [106.8240236, 10.9029332],
                [106.823553, 10.9027958],
                [106.8231333, 10.9028379],
                [106.8227046, 10.9029541],
                [106.8223179, 10.903154],
                [106.822108, 10.9035586],
                [106.821839, 10.903836],
                [106.8216465, 10.9038357],
                [106.8214979, 10.9037038],
                [106.8213619, 10.9031744],
                [106.8188184, 10.9031667],
                [106.8172129, 10.9031602],
                [106.8164445, 10.9031986],
                [106.8161058, 10.9033521],
                [106.8157943, 10.9036694],
                [106.8155117, 10.9039789],
                [106.8153651, 10.9041374],
                [106.8152486, 10.9042634],
                [106.8149612, 10.9045417],
                [106.8148035, 10.9046944],
                [106.8147212, 10.9048707],
                [106.8146987, 10.9051866],
                [106.81468, 10.905451],
                [106.814695, 10.9056273],
                [106.8147663, 10.9059465],
                [106.8147726, 10.9061636],
                [106.8147266, 10.9063567],
                [106.8146391, 10.9065968],
                [106.8145362, 10.9067899],
                [106.8143675, 10.9069945],
                [106.81405, 10.9073046],
                [106.8139652, 10.9074109],
                [106.813948, 10.9075083],
                [106.8139688, 10.9076022],
                [106.8140491, 10.9077015],
                [106.8141844, 10.9077608],
                [106.8146491, 10.9077866],
                [106.8151445, 10.9078422],
                [106.815273, 10.9079723],
                [106.815196, 10.9081603],
                [106.8151686, 10.908363],
                [106.8151128, 10.9089525],
                [106.8150629, 10.9092266],
                [106.8150573, 10.9095273],
                [106.8151517, 10.909945],
                [106.8151867, 10.9103502],
                [106.8149033, 10.9106558],
                [106.8146322, 10.9110642],
                [106.8142932, 10.9118205],
                [106.8140498, 10.9124256],
                [106.813853, 10.912847],
                [106.8136999, 10.9131015],
                [106.8136169, 10.9131862],
                [106.8134194, 10.9133823],
                [106.8131718, 10.9136371],
                [106.8129821, 10.9137711],
                [106.8126065, 10.9139736],
                [106.8123867, 10.914157],
                [106.8123113, 10.9142923],
                [106.8122817, 10.9144456],
                [106.8121889, 10.9146407],
                [106.8119107, 10.9149979],
                [106.8116468, 10.9151525],
                [106.8115171, 10.9154174],
                [106.8114398, 10.9157008],
                [106.8114398, 10.9157966],
                [106.8115049, 10.9159204],
                [106.8116285, 10.9160761],
                [106.8117442, 10.9161719],
                [106.8117483, 10.9163985],
                [106.8116873, 10.9165105],
                [106.8116192, 10.9167384],
                [106.8116285, 10.9169329],
                [106.8116869, 10.9170299],
                [106.8118017, 10.9171259],
                [106.8119521, 10.9171618],
                [106.8120537, 10.9171938],
                [106.8120984, 10.9172776],
                [106.8121188, 10.9173934],
                [106.8121066, 10.9175251],
                [106.8120253, 10.9176329],
                [106.8118667, 10.9177686],
                [106.811513, 10.9176968],
                [106.8112935, 10.9177606],
                [106.8110333, 10.9178884],
                [106.8108381, 10.9180121],
                [106.8107731, 10.9181399],
                [106.810769, 10.9181997],
                [106.8107853, 10.9182716],
                [106.8108625, 10.9184712],
                [106.8108829, 10.9185271],
                [106.8108463, 10.9185869],
                [106.8107446, 10.9186428],
                [106.8106349, 10.9186069],
                [106.8104153, 10.918543],
                [106.8102121, 10.9185111],
                [106.8100738, 10.9185829],
                [106.8099722, 10.9186588],
                [106.8099031, 10.9187666],
                [106.809834, 10.9188065],
                [106.8098014, 10.9187506],
                [106.8095797, 10.9188695],
                [106.8094811, 10.9189119],
                [106.8093332, 10.9189361],
                [106.8093086, 10.9190147],
                [106.8093271, 10.9190994],
                [106.8091545, 10.9191478],
                [106.8091776, 10.919286],
                [106.809041, 10.9194327],
                [106.8089347, 10.919519],
                [106.8089147, 10.9195658],
                [106.8089163, 10.9196052],
                [106.8089763, 10.91964],
                [106.8091011, 10.919699],
                [106.8091258, 10.9197247],
                [106.8091165, 10.9197685],
                [106.809041, 10.9198396],
                [106.8090041, 10.9198427],
                [106.8089317, 10.9198185],
                [106.8088433, 10.9197193],
                [106.8087637, 10.9198003],
                [106.8086775, 10.9198185],
                [106.8086176, 10.9198163],
                [106.8085604, 10.9197988],
                [106.8085049, 10.9197685],
                [106.8084248, 10.9197443],
                [106.8083709, 10.9197549],
                [106.8083077, 10.9197806],
                [106.8082754, 10.9198033],
                [106.8082569, 10.9198351],
                [106.8081737, 10.9199017],
                [106.8080389, 10.9199218],
                [106.8079227, 10.9199365],
                [106.8077718, 10.9199622],
                [106.8077308, 10.9200278],
                [106.8077177, 10.9200786],
                [106.8077039, 10.9201316],
                [106.8076854, 10.9202163],
                [106.8075834, 10.9203497],
                [106.8075459, 10.9203797],
                [106.8074183, 10.92038],
                [106.80732, 10.9203862],
                [106.8071877, 10.9204182],
                [106.8070084, 10.9204644],
                [106.8069197, 10.9204754],
                [106.8068517, 10.9204403],
                [106.8067403, 10.9204411],
                [106.8066884, 10.9204916],
                [106.8066003, 10.9206064],
                [106.8065054, 10.9206737],
                [106.8063811, 10.9206912],
                [106.8062038, 10.9206909],
                [106.8060554, 10.9206607],
                [106.805859, 10.9206617],
                [106.8056225, 10.9206909],
                [106.8054327, 10.9207418],
                [106.8053152, 10.9207788],
                [106.8052364, 10.9207618],
                [106.8051725, 10.9207188],
                [106.8051689, 10.9206481],
                [106.8051757, 10.9205914],
                [106.805174, 10.920489],
                [106.8051213, 10.9204229],
                [106.8050241, 10.920413],
                [106.804933, 10.9204295],
                [106.804846, 10.9204437],
                [106.8047917, 10.9205081],
                [106.8046582, 10.920526],
                [106.8045414, 10.9204718],
                [106.8043763, 10.9204933],
                [106.8042935, 10.9204934],
                [106.8042139, 10.9204521],
                [106.8041494, 10.9203914],
                [106.8040366, 10.9202974],
                [106.8039428, 10.920294],
                [106.80383, 10.9202312],
                [106.8036318, 10.9201268],
                [106.8034555, 10.9200007],
                [106.8033938, 10.9199549],
                [106.8032507, 10.9199209],
                [106.802971, 10.9199431],
                [106.8028004, 10.9199661],
                [106.8026843, 10.9199737],
                [106.8025498, 10.91987],
                [106.8023806, 10.9197184],
                [106.8022671, 10.9196712],
                [106.802201, 10.9196896],
                [106.8020984, 10.9197558],
                [106.8019873, 10.9198131],
                [106.8018714, 10.9198638],
                [106.8018518, 10.9199083],
                [106.80177, 10.9199364],
                [106.8015833, 10.9199396],
                [106.8014584, 10.9199776],
                [106.8013282, 10.9200262],
                [106.8011304, 10.9200413],
                [106.8009874, 10.9201837],
                [106.8008751, 10.9202691],
                [106.8007159, 10.9204817],
                [106.8006651, 10.9205987],
                [106.8004939, 10.9206894],
                [106.7993854, 10.921396],
                [106.7991128, 10.9216978],
                [106.7987793, 10.9219455],
                [106.7986454, 10.9219704],
                [106.7983255, 10.9219541],
                [106.7981646, 10.9219254],
                [106.797925, 10.921868],
                [106.7976728, 10.9219603],
                [106.7976084, 10.9219833],
                [106.7972135, 10.9220793],
                [106.7970963, 10.922113],
                [106.7969598, 10.9222158],
                [106.7969424, 10.9222553],
                [106.7969195, 10.9222988],
                [106.7968608, 10.9223486],
                [106.7965749, 10.9225911],
                [106.7964408, 10.9226728],
                [106.796367, 10.9227031],
                [106.7962745, 10.922732],
                [106.7960733, 10.9227834],
                [106.7958051, 10.9228756],
                [106.7954296, 10.9229914],
                [106.7950715, 10.9230665],
                [106.7949629, 10.9230573],
                [106.794814, 10.9230217],
                [106.7946517, 10.9230362],
                [106.794578, 10.9230849],
                [106.7945498, 10.9231574],
                [106.7945565, 10.9232285],
                [106.7945606, 10.9233615],
                [106.7944724, 10.9236986],
                [106.788181, 10.9221415],
                [106.7880575, 10.9228611],
                [106.788412, 10.923234],
                [106.7891991, 10.9242344],
                [106.7897203, 10.9247312],
                [106.7912024, 10.9261439],
                [106.7917716, 10.9265979],
                [106.791762, 10.926845],
                [106.7917284, 10.9272107],
                [106.7916852, 10.9275133],
                [106.7916465, 10.9276932],
                [106.791586, 10.9278457],
                [106.7914289, 10.9281009],
                [106.7913902, 10.9281757],
                [106.791373, 10.9282419],
                [106.7913499, 10.9283403],
                [106.7913505, 10.9284262],
                [106.7913716, 10.928491],
                [106.7917503, 10.9295358],
                [106.7917737, 10.9296215],
                [106.7917804, 10.9296978],
                [106.7917697, 10.9298467],
                [106.7917143, 10.9300488],
                [106.7916787, 10.9301751],
                [106.7916093, 10.9305576],
                [106.7914755, 10.9307669],
                [106.7912547, 10.9311121],
                [106.7908685, 10.9316683],
                [106.7907522, 10.9318458],
                [106.7905823, 10.9318597],
                [106.7904595, 10.9318901],
                [106.7903065, 10.9319686],
                [106.7901549, 10.9320904],
                [106.7897809, 10.9324512],
                [106.7895595, 10.9325364],
                [106.7892927, 10.9326329],
                [106.7890714, 10.9327475],
                [106.7888072, 10.93287],
                [106.7886276, 10.9329235],
                [106.788429, 10.9329358],
                [106.7879073, 10.9328884],
                [106.7876606, 10.9328502],
                [106.787453, 10.932811],
                [106.7873749, 10.9328054],
                [106.7872169, 10.9328198],
                [106.786587, 10.9329128],
                [106.7863323, 10.9329726],
                [106.786237, 10.9330188],
                [106.7861639, 10.933061],
                [106.7859902, 10.9331581],
                [106.7858742, 10.933215],
                [106.7857897, 10.9332545],
                [106.7856945, 10.9332926],
                [106.7856167, 10.9333045],
                [106.7855015, 10.9332972],
                [106.7853055, 10.9332312],
                [106.7850287, 10.933107],
                [106.7847065, 10.9329592],
                [106.7845814, 10.93287],
                [106.7836955, 10.9327519],
                [106.7837289, 10.9330855],
                [106.7836628, 10.9332905],
                [106.7835299, 10.9337561],
                [106.7833969, 10.9341985],
                [106.7833865, 10.9342447],
                [106.7833853, 10.9342863],
                [106.7833891, 10.9343407],
                [106.7833932, 10.9344198],
                [106.7834066, 10.9345027],
                [106.7834213, 10.9345449],
                [106.7834441, 10.9345784],
                [106.7835579, 10.9347995],
                [106.7835554, 10.9349478],
                [106.7835367, 10.9351085],
                [106.7834508, 10.935464],
                [106.7833506, 10.9358094],
                [106.7830512, 10.9361467],
                [106.7828995, 10.9363108],
                [106.7826645, 10.936631],
                [106.7825546, 10.936845],
                [106.7824099, 10.9371268],
                [106.7821964, 10.9376518],
                [106.7820358, 10.9382108],
                [106.7811681, 10.9379259],
                [106.7806975, 10.9377104],
                [106.7798089, 10.9370943],
                [106.7795979, 10.9372326],
                [106.7792968, 10.9368205],
                [106.7777732, 10.9377307],
                [106.7777353, 10.9378449],
                [106.7778202, 10.9379743],
                [106.7780205, 10.9382982],
                [106.7783024, 10.9384722],
                [106.7784331, 10.9389077],
                [106.7783206, 10.9391206],
                [106.77785, 10.939256],
                [106.7776854, 10.939127],
                [106.7773665, 10.9392626],
                [106.7769888, 10.9393231],
                [106.776612, 10.9388543],
                [106.7765633, 10.9388198],
                [106.7765293, 10.9387446],
                [106.7765142, 10.9386137],
                [106.7763847, 10.9386308],
                [106.7760347, 10.939322],
                [106.7757208, 10.9398025],
                [106.7754205, 10.9403084],
                [106.7745867, 10.9398988],
                [106.7739378, 10.9403625],
                [106.7734407, 10.9407131],
                [106.773075, 10.9409904],
                [106.7726604, 10.9412035],
                [106.7725681, 10.9413212],
                [106.7725437, 10.9414809],
                [106.772497, 10.9416638],
                [106.772426, 10.9417709],
                [106.7721388, 10.9420887],
                [106.771894, 10.9423195],
                [106.7714514, 10.94264],
                [106.77103, 10.9430974],
                [106.7709313, 10.9432076],
                [106.7708159, 10.9433366],
                [106.7705819, 10.9436315],
                [106.7705642, 10.9436539],
                [106.770353, 10.9439095],
                [106.7702931, 10.9439862],
                [106.7700984, 10.9442358],
                [106.7700045, 10.9445009],
                [106.769975, 10.9447425],
                [106.7699442, 10.9449626],
                [106.7698887, 10.9451965],
                [106.7698552, 10.9454841],
                [106.7698543, 10.9456901],
                [106.7698418, 10.9460524],
                [106.7698297, 10.9462944],
                [106.7698234, 10.9464198],
                [106.7695989, 10.9467795],
                [106.76954, 10.9471171],
                [106.769477, 10.947232],
                [106.7694326, 10.9473035],
                [106.7693645, 10.9474132],
                [106.7693154, 10.9475943],
                [106.7693207, 10.9477513],
                [106.7693606, 10.9478883],
                [106.7693965, 10.9479549],
                [106.7694646, 10.9480646],
                [106.7694841, 10.9481419],
                [106.7694542, 10.9483158],
                [106.7694542, 10.9484001],
                [106.7694694, 10.9485507],
                [106.7695224, 10.949078],
                [106.7696444, 10.9492257],
                [106.7699203, 10.9494889],
                [106.7699988, 10.9496477],
                [106.7699955, 10.9501379],
                [106.7700318, 10.9502718],
                [106.7700106, 10.9504095],
                [106.7698474, 10.9507131],
                [106.7698536, 10.9508757],
                [106.7698652, 10.9508954],
                [106.7699459, 10.9510326],
                [106.7702224, 10.95129],
                [106.7703992, 10.9513519],
                [106.7705842, 10.9513],
                [106.7707488, 10.9511543],
                [106.7708729, 10.9510345],
                [106.7710111, 10.9510126],
                [106.7710701, 10.9512041],
                [106.7710553, 10.9513979],
                [106.7709174, 10.9517086],
                [106.7709089, 10.9519394],
                [106.7708821, 10.9520361],
                [106.7706268, 10.9521641],
                [106.770448, 10.9523138],
                [106.770198, 10.9525134],
                [106.7701158, 10.9524956],
                [106.7700171, 10.9524595],
                [106.7697508, 10.9522719],
                [106.7695129, 10.9520883],
                [106.7693157, 10.9519206],
                [106.7691816, 10.9518428],
                [106.7687364, 10.9517248],
                [106.7684356, 10.9518488],
                [106.7681327, 10.9519047],
                [106.7678477, 10.9519874],
                [106.7674927, 10.9521241],
                [106.7672049, 10.9521242],
                [106.7669939, 10.9521621],
                [106.766799, 10.9521439],
                [106.7666, 10.95203],
                [106.7664, 10.95185],
                [106.7659061, 10.9518379],
                [106.7655166, 10.9519167],
                [106.7649982, 10.9519945],
                [106.7644555, 10.9521881],
                [106.7639086, 10.9526451],
                [106.7634482, 10.9530369],
                [106.7630927, 10.9531939],
                [106.7628212, 10.9534134],
                [106.7628782, 10.9536525],
                [106.7629861, 10.9538376],
                [106.7631149, 10.9543279],
                [106.763244, 10.9544193],
                [106.7633788, 10.954441],
                [106.7635205, 10.954567],
                [106.7637089, 10.9549087],
                [106.7638712, 10.9552686],
                [106.7638768, 10.955585],
                [106.764, 10.9558356],
                [106.7640247, 10.9559669],
                [106.7641327, 10.9560595],
                [106.7643152, 10.9560976],
                [106.7645371, 10.9560918],
                [106.7645432, 10.9562572],
                [106.7645266, 10.956369],
                [106.7645266, 10.9565686],
                [106.7647, 10.95673],
                [106.7648073, 10.9568483],
                [106.7647893, 10.956988],
                [106.7647217, 10.9573245],
                [106.7646486, 10.9576064],
                [106.7645926, 10.9578858],
                [106.7646567, 10.9581492],
                [106.7648412, 10.9583818],
                [106.7650388, 10.9584674],
                [106.7651569, 10.9585618],
                [106.7651518, 10.9589986],
                [106.765137, 10.9590997],
                [106.7649982, 10.9594959],
                [106.7648298, 10.9597913],
                [106.7644453, 10.960097],
                [106.7641987, 10.9602423],
                [106.76397, 10.9603041],
                [106.7637625, 10.9604037],
                [106.763519, 10.9605371],
                [106.7633271, 10.9606848],
                [106.7630976, 10.9608117],
                [106.7626112, 10.9609112],
                [106.76248, 10.9609698],
                [106.7624123, 10.9610318],
                [106.7621973, 10.9615616],
                [106.7620891, 10.9617284],
                [106.7619663, 10.9618707],
                [106.7619675, 10.9621265],
                [106.762024, 10.9622184],
                [106.76214, 10.9623867],
                [106.7622279, 10.962505],
                [106.7622899, 10.9629888],
                [106.7620874, 10.963298],
                [106.7625183, 10.9637131],
                [106.7636232, 10.9647232],
                [106.7641616, 10.9640191],
                [106.7641603, 10.9637659],
                [106.7642162, 10.9635746],
                [106.7642463, 10.963536],
                [106.7643162, 10.9634462],
                [106.7644239, 10.9634095],
                [106.7645202, 10.9634154],
                [106.7646877, 10.9634768],
                [106.7648034, 10.963554],
                [106.7648259, 10.9636387],
                [106.7648047, 10.9637607],
                [106.7650494, 10.9638934],
                [106.7654785, 10.964104],
                [106.7656878, 10.9642989],
                [106.7657789, 10.9644042],
                [106.7657864, 10.9643982],
                [106.7658979, 10.9643096],
                [106.7659842, 10.9641834],
                [106.7660639, 10.9640404],
                [106.7662061, 10.9639547],
                [106.7663047, 10.9640092],
                [106.7663329, 10.9641612],
                [106.766332, 10.9643774],
                [106.7663244, 10.96447],
                [106.7664036, 10.9645426],
                [106.7664924, 10.9645622],
                [106.7665634, 10.964489],
                [106.766619, 10.9644178],
                [106.7666923, 10.9643061],
                [106.7667285, 10.9641548],
                [106.7665354, 10.963849],
                [106.7664361, 10.9636387],
                [106.7665027, 10.9635086],
                [106.7666059, 10.9634518],
                [106.7668166, 10.963571],
                [106.766965, 10.9637458],
                [106.7670429, 10.9639116],
                [106.7673067, 10.9641113],
                [106.7676748, 10.9643267],
                [106.7679289, 10.9644446],
                [106.7681686, 10.9644933],
                [106.7684532, 10.9644354],
                [106.7686611, 10.9642858],
                [106.7687293, 10.9642284],
                [106.7688167, 10.9641806],
                [106.7690807, 10.9641429],
                [106.7692897, 10.9641577],
                [106.7695891, 10.9642621],
                [106.7697708, 10.9643569],
                [106.7698481, 10.9645121],
                [106.7699682, 10.9648409],
                [106.7700319, 10.9650007],
                [106.7702565, 10.9651524],
                [106.7705437, 10.9652438],
                [106.7707717, 10.9652767],
                [106.7709905, 10.9652796],
                [106.7713282, 10.9652616],
                [106.7714637, 10.9651229],
                [106.7715821, 10.9647367],
                [106.7713485, 10.9643823],
                [106.7712082, 10.9639723],
                [106.7711586, 10.9636993],
                [106.7711685, 10.9634296],
                [106.7715184, 10.9632773],
                [106.7717308, 10.9632029],
                [106.7720561, 10.9630891],
                [106.7725093, 10.9626172],
                [106.7727773, 10.9622371],
                [106.772882, 10.9620121],
                [106.7729443, 10.9617851],
                [106.7729805, 10.9611026],
                [106.7729561, 10.9602822],
                [106.7729977, 10.9598653],
                [106.7730766, 10.9597004],
                [106.7732134, 10.9594608],
                [106.7735571, 10.9592533],
                [106.7741918, 10.9592354],
                [106.7749433, 10.9592666],
                [106.7755185, 10.9592452],
                [106.7761495, 10.9595903],
                [106.7766071, 10.9601038],
                [106.777006, 10.9606814],
                [106.7773422, 10.961274],
                [106.777896, 10.9619561],
                [106.7783163, 10.9623321],
                [106.7789048, 10.9627997],
                [106.779758, 10.9632331],
                [106.780566, 10.9636663],
                [106.7806434, 10.9637078],
                [106.7823364, 10.9643168],
                [106.7832499, 10.9646174],
                [106.7834722, 10.9648734],
                [106.7837018, 10.9651231],
                [106.7839386, 10.9653663],
                [106.7841824, 10.9656028],
                [106.7844329, 10.9658323],
                [106.78469, 10.9660547],
                [106.7849534, 10.9662698],
                [106.785223, 10.9664775],
                [106.7854985, 10.9666775],
                [106.7857797, 10.9668698],
                [106.7860664, 10.9670541],
                [106.7863583, 10.9672303],
                [106.7866535, 10.9673972],
                [106.7869534, 10.9675559],
                [106.7872578, 10.9677062],
                [106.7875663, 10.967848],
                [106.7878789, 10.9679811],
                [106.7881951, 10.9681055],
                [106.7885148, 10.968221],
                [106.7888378, 10.9683275],
                [106.7891637, 10.9684251],
                [106.7894922, 10.9685135],
                [106.7898232, 10.9685927],
                [106.7901939, 10.9686785],
                [106.7905895, 10.9687962],
                [106.7910199, 10.9689381],
                [106.7914473, 10.9690885],
                [106.7918715, 10.9692472],
                [106.7922924, 10.9694143],
                [106.7927098, 10.9695896],
                [106.7931269, 10.9697747],
                [106.7935402, 10.9699679],
                [106.7939493, 10.9701694],
                [106.7943543, 10.9703789],
                [106.7947548, 10.9705964],
                [106.7951508, 10.9708218],
                [106.7955421, 10.971055],
                [106.79573, 10.9711801],
                [106.7959017, 10.9712959],
                [106.7960116, 10.9714106],
                [106.7960822, 10.9715247],
                [106.796148, 10.9716456],
                [106.7962059, 10.9717703],
                [106.7962559, 10.9718984],
                [106.7962976, 10.9720292],
                [106.7963311, 10.9721623],
                [106.796356, 10.9722972],
                [106.7963723, 10.9724333],
                [106.79638, 10.9725702],
                [106.7963791, 10.9727073],
                [106.7963699, 10.9728398],
                [106.7963525, 10.9729716],
                [106.7963272, 10.9731021],
                [106.7962939, 10.9732308],
                [106.7962527, 10.9733573],
                [106.7962039, 10.9734812],
                [106.7961476, 10.973602],
                [106.796084, 10.9737192],
                [106.7960133, 10.9738325],
                [106.7948446, 10.9757862],
                [106.7945516, 10.9761454],
                [106.7943284, 10.9763519],
                [106.7941043, 10.9765404],
                [106.7896626, 10.9799514],
                [106.7886522, 10.9807699],
                [106.7883534, 10.9810126],
                [106.7881055, 10.9812382],
                [106.7833369, 10.9854742],
                [106.7829439, 10.9858921],
                [106.782558, 10.9863471],
                [106.7819356, 10.9872361],
                [106.7803172, 10.9898899],
                [106.7789863, 10.9923498],
                [106.7787506, 10.9928127],
                [106.7785675, 10.9932081],
                [106.7758591, 10.9992329],
                [106.7751975, 11.001189],
                [106.7750422, 11.0017856],
                [106.77465, 11.0039408],
                [106.77464, 11.0041792],
                [106.7746, 11.00473],
                [106.774694, 11.0059231],
                [106.77475, 11.00624],
                [106.7748713, 11.0066919],
                [106.7750606, 11.0070403],
                [106.7752241, 11.0073045],
                [106.7753817, 11.0075399],
                [106.7761, 11.00833],
                [106.777, 11.00918],
                [106.7787, 11.00993],
                [106.7792, 11.01015],
                [106.7799828, 11.0104489],
                [106.7802609, 11.0105892],
                [106.7805462, 11.0107574],
                [106.7819077, 11.0119158],
                [106.7820682, 11.0120687],
                [106.782206, 11.0121977],
                [106.7831036, 11.0131418],
                [106.7831887, 11.0132751],
                [106.783739, 11.01467],
                [106.7837947, 11.0148846],
                [106.7838668, 11.0151124],
                [106.7842824, 11.0169432],
                [106.7843297, 11.0171341],
                [106.784397, 11.0173191],
                [106.7844908, 11.0175192],
                [106.7846, 11.01766],
                [106.7849, 11.01801],
                [106.7851592, 11.0182002],
                [106.7854256, 11.0182934],
                [106.7861, 11.01844],
                [106.7864493, 11.0185234],
                [106.7867561, 11.0185662],
                [106.7870304, 11.0186399],
                [106.7872981, 11.018749],
                [106.788318, 11.0192223],
                [106.7888741, 11.0195791],
                [106.7904, 11.02065],
                [106.7919395, 11.0219711],
                [106.7922, 11.02222],
                [106.7926825, 11.0225749],
                [106.7931, 11.0228],
                [106.7951735, 11.0238484],
                [106.7957835, 11.0240975],
                [106.7963372, 11.0242268],
                [106.8001, 11.02486],
                [106.8024, 11.02512],
                [106.8039, 11.02532],
                [106.8048602, 11.0253159],
                [106.8051529, 11.0253435],
                [106.8054447, 11.0253789],
                [106.8057355, 11.0254221],
                [106.8060249, 11.0254731],
                [106.8062376, 11.0255157],
                [106.8064494, 11.0255626],
                [106.8068719, 11.0256609],
                [106.8072978, 11.0257695],
                [106.8077212, 11.025887],
                [106.808142, 11.0260135],
                [106.8085599, 11.0261487],
                [106.8088674, 11.0262612],
                [106.809082, 11.026377],
                [106.8092855, 11.0265097],
                [106.8094938, 11.0266921],
                [106.8097441, 11.0269029],
                [106.8099879, 11.0271208],
                [106.810225, 11.0273458],
                [106.8104553, 11.0275775],
                [106.8106778, 11.0278151],
                [106.8108932, 11.0280591],
                [106.811101, 11.0283092],
                [106.8113013, 11.0285651],
                [106.8114938, 11.0288268],
                [106.8116784, 11.0290939],
                [106.8118423, 11.0293511],
                [106.8119979, 11.0296133],
                [106.8121448, 11.0298802],
                [106.812283, 11.0301515],
                [106.8124124, 11.030427],
                [106.8125327, 11.0307064],
                [106.812644, 11.0309895],
                [106.8127466, 11.0312776],
                [106.8128397, 11.0315689],
                [106.8129233, 11.0318629],
                [106.8129973, 11.0321594],
                [106.8130616, 11.0324581],
                [106.8131161, 11.0327586],
                [106.8131395, 11.0330845],
                [106.8131551, 11.0334109],
                [106.8131626, 11.0337376],
                [106.8131622, 11.0340643],
                [106.8131538, 11.0343909],
                [106.8131375, 11.0347173],
                [106.8131125, 11.0350514],
                [106.8130791, 11.0353848],
                [106.8130375, 11.0357173],
                [106.8129875, 11.0360487],
                [106.8129292, 11.0363788],
                [106.8128627, 11.0367074],
                [106.812788, 11.0370343],
                [106.8127052, 11.0373593],
                [106.8126142, 11.0376822],
                [106.812526, 11.0380291],
                [106.8124303, 11.0384062],
                [106.8123368, 11.0388079],
                [106.8122348, 11.0392076],
                [106.8121244, 11.0396051],
                [106.8120055, 11.0400002],
                [106.8118783, 11.0403928],
                [106.8117428, 11.0407828],
                [106.8116055, 11.0411528],
                [106.8114608, 11.0415201],
                [106.8113086, 11.0418844],
                [106.8111491, 11.0422458],
                [106.8109823, 11.0426039],
                [106.8108082, 11.0429587],
                [106.810627, 11.04331],
                [106.8103761, 11.0437279],
                [106.8101176, 11.0441413],
                [106.8098514, 11.04455],
                [106.8095777, 11.0449539],
                [106.8092966, 11.0453529],
                [106.8089946, 11.0457647],
                [106.8086848, 11.0461709],
                [106.8083671, 11.0465712],
                [106.8080417, 11.0469654],
                [106.8077087, 11.0473536],
                [106.8072773, 11.047719],
                [106.806841, 11.0480787],
                [106.8063998, 11.0484327],
                [106.8059538, 11.0487808],
                [106.8055032, 11.0491232],
                [106.805048, 11.0494596],
                [106.804541, 11.0498234],
                [106.8040286, 11.0501799],
                [106.8035109, 11.050529],
                [106.8032511, 11.0507022],
                [106.802988, 11.0508706],
                [106.8026864, 11.0510553],
                [106.8023809, 11.0512338],
                [106.8020716, 11.0514059],
                [106.8017586, 11.0515715],
                [106.8014422, 11.0517306],
                [106.8011224, 11.0518831],
                [106.8008423, 11.0520131],
                [106.8006171, 11.0521641],
                [106.8004404, 11.0523064],
                [106.8002695, 11.0524553],
                [106.8001048, 11.0526107],
                [106.7999464, 11.0527724],
                [106.7997946, 11.0529401],
                [106.7996497, 11.0531135],
                [106.7995119, 11.0532924],
                [106.799386, 11.0534696],
                [106.7992671, 11.0536514],
                [106.7991553, 11.0538374],
                [106.7990508, 11.0540275],
                [106.7989537, 11.0542214],
                [106.7988642, 11.0544187],
                [106.7988217, 11.0545183],
                [106.7987823, 11.0546192],
                [106.7987263, 11.0547822],
                [106.7986786, 11.0549477],
                [106.7986393, 11.0551153],
                [106.7986085, 11.0552846],
                [106.7985863, 11.0554551],
                [106.7985888, 11.0556292],
                [106.7985987, 11.0557931],
                [106.7986325, 11.0559582],
                [106.7986741, 11.0561216],
                [106.7987233, 11.0562829],
                [106.7987811, 11.0564443],
                [106.7988466, 11.0566029],
                [106.7989196, 11.0567582],
                [106.799, 11.05691],
                [106.7992, 11.05724],
                [106.7994638, 11.0574965],
                [106.7997344, 11.057746],
                [106.8000117, 11.0579883],
                [106.8002954, 11.0582233],
                [106.8005855, 11.0584508],
                [106.8008815, 11.0586707],
                [106.8011835, 11.0588828],
                [106.8014911, 11.0590869],
                [106.8018041, 11.0592829],
                [106.8021223, 11.0594706],
                [106.8024455, 11.05965],
                [106.8027734, 11.0598209],
                [106.8031049, 11.0599827],
                [106.8034407, 11.0601358],
                [106.8037805, 11.0602801],
                [106.804124, 11.0604156],
                [106.8044711, 11.060542],
                [106.8048215, 11.0606594],
                [106.805175, 11.0607676],
                [106.8055312, 11.0608665],
                [106.80589, 11.0609562],
                [106.8062511, 11.0610364],
                [106.8066142, 11.0611073],
                [106.8069791, 11.0611687],
                [106.8079853, 11.061382],
                [106.8089895, 11.0616041],
                [106.8099917, 11.0618348],
                [106.8109917, 11.0620742],
                [106.8118464, 11.062286],
                [106.8126995, 11.0625042],
                [106.8135509, 11.0627287],
                [106.8144005, 11.0629594],
                [106.8152483, 11.0631965],
                [106.8160225, 11.0634553],
                [106.8167995, 11.063706],
                [106.8175792, 11.0639484],
                [106.8183419, 11.0641768],
                [106.8191069, 11.0643972],
                [106.8198742, 11.0646098],
                [106.8206438, 11.0648144],
                [106.8214156, 11.065011],
                [106.8221893, 11.0651997],
                [106.8226325, 11.0652962],
                [106.8229973, 11.0653699],
                [106.8232905, 11.0654025],
                [106.8235305, 11.0654055],
                [106.8238134, 11.0654055],
                [106.8240963, 11.065397],
                [106.8243788, 11.06538],
                [106.8246606, 11.0653547],
                [106.8249415, 11.065321],
                [106.8252212, 11.0652789],
                [106.8254995, 11.0652285],
                [106.8257761, 11.0651698],
                [106.8260546, 11.065102],
                [106.8263308, 11.0650257],
                [106.8266045, 11.0649411],
                [106.8268754, 11.0648483],
                [106.8271433, 11.0647474],
                [106.8274078, 11.0646384],
                [106.8276689, 11.0645215],
                [106.8279261, 11.0643968],
                [106.8281793, 11.0642643],
                [106.8283601, 11.0641134],
                [106.8285356, 11.0639565],
                [106.8287054, 11.0637937],
                [106.8288693, 11.0636253],
                [106.8290273, 11.0634515],
                [106.8291791, 11.0632724],
                [106.8293245, 11.0630883],
                [106.8294634, 11.0628994],
                [106.8295955, 11.0627059],
                [106.8297295, 11.0624939],
                [106.8298553, 11.0622772],
                [106.8299729, 11.062056],
                [106.8300822, 11.0618308],
                [106.8301829, 11.0616017],
                [106.8302749, 11.0613692],
                [106.8303581, 11.0611334],
                [106.8304324, 11.0608949],
                [106.8305457, 11.0604468],
                [106.8306674, 11.0600009],
                [106.8307973, 11.0595573],
                [106.8309355, 11.059116],
                [106.8310818, 11.0586773],
                [106.8312364, 11.0582413],
                [106.831399, 11.0578081],
                [106.8315697, 11.057378],
                [106.831755, 11.0569355],
                [106.8319488, 11.0564965],
                [106.832151, 11.0560612],
                [106.8323616, 11.0556297],
                [106.8325806, 11.0552022],
                [106.8328077, 11.0547789],
                [106.833043, 11.0543599],
                [106.8332863, 11.0539453],
                [106.8335973, 11.0534328],
                [106.8338998, 11.0529154],
                [106.8341937, 11.0523932],
                [106.834479, 11.0518665],
                [106.8347554, 11.0513352],
                [106.8350231, 11.0507996],
                [106.8352818, 11.0502598],
                [106.8355226, 11.0497361],
                [106.835755, 11.0492087],
                [106.8359789, 11.0486778],
                [106.8361944, 11.0481435],
                [106.8364013, 11.0476059],
                [106.8365888, 11.047118],
                [106.8367892, 11.0465849],
                [106.8369728, 11.0460572],
                [106.8371498, 11.0455902],
                [106.8373431, 11.0450556],
                [106.8375276, 11.044518],
                [106.8377034, 11.0439776],
                [106.8378703, 11.0434345],
                [106.8379855, 11.0430889],
                [106.8380606, 11.042873],
                [106.8381765, 11.0426121],
                [106.8383316, 11.042339],
                [106.8384937, 11.0420699],
                [106.8386625, 11.0418048],
                [106.8388379, 11.0415439],
                [106.83902, 11.0412875],
                [106.8392086, 11.0410355],
                [106.839423, 11.0407642],
                [106.8396449, 11.0404987],
                [106.8398741, 11.0402394],
                [106.8401105, 11.0399863],
                [106.8403539, 11.0397396],
                [106.840604, 11.0394996],
                [106.8408608, 11.0392664],
                [106.8410334, 11.039094],
                [106.8412116, 11.0389271],
                [106.8413953, 11.0387661],
                [106.8415842, 11.0386109],
                [106.8417781, 11.0384619],
                [106.8419769, 11.0383192],
                [106.8421802, 11.0381828],
                [106.842388, 11.0380531],
                [106.8426, 11.03793],
                [106.8428407, 11.0377837],
                [106.8431786, 11.0375809],
                [106.8435123, 11.0373708],
                [106.8438498, 11.0371665],
                [106.8441909, 11.0369681],
                [106.8445356, 11.0367758],
                [106.8448809, 11.036591],
                [106.8452294, 11.0364122],
                [106.845581, 11.0362394],
                [106.8459357, 11.0360728],
                [106.8462934, 11.0359124],
                [106.8465886, 11.0357693],
                [106.8468879, 11.0356348],
                [106.8471912, 11.0355089],
                [106.847498, 11.0353918],
                [106.8478082, 11.0352835],
                [106.8481215, 11.0351841],
                [106.8484192, 11.0350911],
                [106.8487726, 11.0350057],
                [106.8492754, 11.034876],
                [106.8498551, 11.0347318],
                [106.8504365, 11.0345944],
                [106.8510194, 11.0344636],
                [106.8516038, 11.0343395],
                [106.8522258, 11.0342151],
                [106.8528492, 11.0340983],
                [106.853474, 11.033989],
                [106.8541002, 11.0338874],
                [106.8547277, 11.0337934],
                [106.8553562, 11.0337071],
                [106.8559, 11.0336284],
                [106.8562895, 11.0335351],
                [106.8565901, 11.0334327],
                [106.8568873, 11.0333212],
                [106.8571809, 11.0332007],
                [106.8574706, 11.0330715],
                [106.8577561, 11.0329335],
                [106.8580371, 11.0327869],
                [106.8583133, 11.0326319],
                [106.8585846, 11.0324687],
                [106.8588506, 11.0322973],
                [106.8591022, 11.0321242],
                [106.8593484, 11.0319439],
                [106.859589, 11.0317565],
                [106.8598239, 11.0315621],
                [106.8600527, 11.031361],
                [106.8602753, 11.0311532],
                [106.8604916, 11.030939],
                [106.8607012, 11.0307187],
                [106.8609041, 11.0304922],
                [106.8611536, 11.0302179],
                [106.8615691, 11.0297608],
                [106.8620327, 11.0292566],
                [106.8624906, 11.0287476],
                [106.8629429, 11.0282336],
                [106.8634403, 11.0276552],
                [106.8639305, 11.0270708],
                [106.8644135, 11.0264807],
                [106.8648892, 11.0258849],
                [106.8653575, 11.0252835],
                [106.8658183, 11.0246766],
                [106.8660307, 11.0243656],
                [106.8662717, 11.0240642],
                [106.8665043, 11.0238004],
                [106.8667437, 11.0235425],
                [106.86699, 11.0232908],
                [106.8672427, 11.0230455],
                [106.8675019, 11.0228066],
                [106.8677673, 11.0225744],
                [106.8680387, 11.0223491],
                [106.8683159, 11.0221307],
                [106.8685989, 11.0219195],
                [106.8688873, 11.0217155],
                [106.869181, 11.0215189],
                [106.8694798, 11.0213298],
                [106.8697923, 11.0211433],
                [106.8701097, 11.020965],
                [106.8704319, 11.0207951],
                [106.8707586, 11.0206337],
                [106.8710896, 11.0204809],
                [106.8714245, 11.0203367],
                [106.8717633, 11.0202014],
                [106.8721057, 11.020075],
                [106.8724513, 11.0199576],
                [106.8727732, 11.0198704],
                [106.873017, 11.0198288],
                [106.8732347, 11.019817],
                [106.8734527, 11.0198138],
                [106.8736707, 11.0198192],
                [106.8738882, 11.0198334],
                [106.874105, 11.0198561],
                [106.8743207, 11.0198875],
                [106.8745349, 11.0199274],
                [106.8747473, 11.0199758],
                [106.8749575, 11.0200326],
                [106.8751333, 11.0200871],
                [106.8753071, 11.0201475],
                [106.8759965, 11.0204035],
                [106.876511, 11.0206022],
                [106.8770219, 11.0208095],
                [106.8775041, 11.0210146],
                [106.8779828, 11.0212274],
                [106.8784579, 11.0214479],
                [106.8789293, 11.021676],
                [106.8793968, 11.0219117],
                [106.8798602, 11.0221549],
                [106.8803196, 11.0224055],
                [106.8819817, 11.0232888],
                [106.8836474, 11.0241655],
                [106.8842091, 11.0244592],
                [106.8847753, 11.0247444],
                [106.885346, 11.0250209],
                [106.8859209, 11.0252887],
                [106.8865, 11.0255477],
                [106.8870831, 11.025798],
                [106.8876701, 11.0260393],
                [106.8882608, 11.0262717],
                [106.8888104, 11.0264787],
                [106.889363, 11.0266779],
                [106.8899183, 11.0268693],
                [106.8904765, 11.0270529],
                [106.8910372, 11.0272287],
                [106.8916005, 11.0273965],
                [106.8921661, 11.0275565],
                [106.8924362, 11.0276297],
                [106.8927085, 11.0276949],
                [106.8929826, 11.027752],
                [106.8932584, 11.0278011],
                [106.8935355, 11.027842],
                [106.8938138, 11.0278747],
                [106.8940929, 11.0278991],
                [106.8943726, 11.0279154],
                [106.8946528, 11.0279234],
                [106.894933, 11.0279231],
                [106.8952131, 11.0279145],
                [106.8955028, 11.0278969],
                [106.8957918, 11.0278705],
                [106.8960799, 11.0278353],
                [106.8963667, 11.0277913],
                [106.8966519, 11.0277386],
                [106.8969353, 11.0276771],
                [106.8972167, 11.0276071],
                [106.8974957, 11.0275285],
                [106.897772, 11.0274414],
                [106.8980455, 11.0273458],
                [106.8983337, 11.0272233],
                [106.8986183, 11.0270927],
                [106.8988988, 11.0269541],
                [106.8991752, 11.0268076],
                [106.8994471, 11.0266534],
                [106.8997144, 11.0264915],
                [106.8999769, 11.0263221],
                [106.9002342, 11.0261453],
                [106.9004952, 11.0259546],
                [106.9007502, 11.0257564],
                [106.9009992, 11.0255507],
                [106.9012417, 11.0253379],
                [106.9014777, 11.025118],
                [106.9017068, 11.0248913],
                [106.901929, 11.024658],
                [106.9021439, 11.0244183],
                [106.9023077, 11.0242428],
                [106.9024777, 11.024073],
                [106.9026536, 11.0239091],
                [106.9028352, 11.0237514],
                [106.9030223, 11.0235999],
                [106.9032147, 11.023455],
                [106.9034121, 11.0233168],
                [106.9036143, 11.0231855],
                [106.9038211, 11.0230611],
                [106.9040322, 11.022944],
                [106.9042543, 11.0228307],
                [106.9044804, 11.0227254],
                [106.9047103, 11.0226281],
                [106.9049436, 11.0225391],
                [106.9051799, 11.0224583],
                [106.9054191, 11.022386],
                [106.9056608, 11.0223222],
                [106.9059047, 11.022267],
                [106.9061505, 11.0222205],
                [106.9063978, 11.0221827],
                [106.9066329, 11.0221523],
                [106.906753, 11.0221425],
                [106.9068601, 11.0221389],
                [106.9069673, 11.0221428],
                [106.9070739, 11.0221541],
                [106.9071837, 11.0221738],
                [106.9072917, 11.0222015],
                [106.9075054, 11.0222721],
                [106.9077095, 11.0223568],
                [106.9078812, 11.0224332],
                [106.9080582, 11.0225148],
                [106.9082517, 11.022601],
                [106.9083883, 11.0226711],
                [106.9085434, 11.0227521],
                [106.9086954, 11.0228387],
                [106.908844, 11.0229308],
                [106.9089889, 11.0230282],
                [106.9091597, 11.0231534],
                [106.9093246, 11.0232859],
                [106.9094833, 11.0234257],
                [106.9096354, 11.0235722],
                [106.9097806, 11.0237254],
                [106.9099187, 11.0238848],
                [106.9100493, 11.0240501],
                [106.9101722, 11.024221],
                [106.9103086, 11.0244077],
                [106.910414, 11.0246031],
                [106.9105333, 11.0248134],
                [106.9106447, 11.0250278],
                [106.9107482, 11.0252459],
                [106.9108436, 11.0254676],
                [106.9109309, 11.0256925],
                [106.9110127, 11.0259294],
                [106.9110854, 11.0261691],
                [106.911149, 11.0264114],
                [106.9112032, 11.0266558],
                [106.9112481, 11.026902],
                [106.9113922, 11.0281129],
                [106.9114928, 11.0290769],
                [106.9115853, 11.0300417],
                [106.9116633, 11.0309295],
                [106.9117345, 11.0318178],
                [106.9117989, 11.0327066],
                [106.9118564, 11.0335959],
                [106.9118965, 11.0341276],
                [106.9119072, 11.0344856],
                [106.9118958, 11.0349666],
                [106.9118919, 11.0354477],
                [106.9118954, 11.0359287],
                [106.9119062, 11.0364097],
                [106.9119245, 11.0368905],
                [106.9119501, 11.0373709],
                [106.9119875, 11.0379076],
                [106.9120341, 11.0384435],
                [106.9120899, 11.0389786],
                [106.9121549, 11.0395127],
                [106.912229, 11.0400456],
                [106.9122707, 11.040235],
                [106.9123209, 11.0404224],
                [106.9123795, 11.0406075],
                [106.9124463, 11.0407898],
                [106.9125212, 11.040969],
                [106.9126041, 11.0411449],
                [106.9126948, 11.041317],
                [106.9127932, 11.041485],
                [106.912899, 11.0416485],
                [106.913012, 11.0418074],
                [106.9131321, 11.0419611],
                [106.913259, 11.0421096],
                [106.9133944, 11.0422544],
                [106.9135362, 11.0423931],
                [106.9136842, 11.0425254],
                [106.9138381, 11.0426511],
                [106.9139975, 11.0427699],
                [106.9141622, 11.0428816],
                [106.9143319, 11.042986],
                [106.9145061, 11.0430828],
                [106.9146846, 11.0431719],
                [106.9151736, 11.0433956],
                [106.9202544, 11.0454155],
                [106.9204742, 11.0455071],
                [106.9206907, 11.0456057],
                [106.9209039, 11.0457114],
                [106.9211133, 11.0458238],
                [106.9213188, 11.0459431],
                [106.9215202, 11.0460689],
                [106.9217324, 11.0462118],
                [106.9219393, 11.046362],
                [106.9221406, 11.0465194],
                [106.922336, 11.0466837],
                [106.9225254, 11.0468548],
                [106.9227084, 11.0470324],
                [106.9228848, 11.0472163],
                [106.9229847, 11.0473325],
                [106.9230598, 11.0474378],
                [106.9231081, 11.0475299],
                [106.9231568, 11.0476362],
                [106.923209, 11.0477743],
                [106.9232753, 11.0479625],
                [106.9233342, 11.048153],
                [106.9233857, 11.0483456],
                [106.9234295, 11.04854],
                [106.9234658, 11.0487359],
                [106.9234943, 11.0489331],
                [106.9235159, 11.0491397],
                [106.923529, 11.0493469],
                [106.9235336, 11.0495546],
                [106.9235297, 11.0497622],
                [106.9235174, 11.0499695],
                [106.9234967, 11.0501762],
                [106.9234568, 11.0504187],
                [106.9234031, 11.0506178],
                [106.923328, 11.0508232],
                [106.9232236, 11.0511133],
                [106.923026, 11.0516436],
                [106.9228373, 11.052177],
                [106.9226575, 11.0527133],
                [106.9225193, 11.0531468],
                [106.922387, 11.0535821],
                [106.9222659, 11.0539716],
                [106.9222152, 11.0541495],
                [106.9221778, 11.0542824],
                [106.9221483, 11.0544172],
                [106.9221269, 11.0545535],
                [106.9221136, 11.0546908],
                [106.9221086, 11.0548286],
                [106.9221117, 11.0549664],
                [106.922123, 11.0551039],
                [106.9221425, 11.0552404],
                [106.9221693, 11.0553725],
                [106.9222038, 11.0555029],
                [106.9222458, 11.0556311],
                [106.9222952, 11.0557568],
                [106.9223517, 11.0558795],
                [106.9224154, 11.0559988],
                [106.9225287, 11.056146],
                [106.9226678, 11.056287],
                [106.9228555, 11.0564535],
                [106.9230489, 11.0566137],
                [106.9232476, 11.0567675],
                [106.9234514, 11.0569147],
                [106.9236635, 11.0570572],
                [106.9238805, 11.0571927],
                [106.924102, 11.0573207],
                [106.9243278, 11.0574414],
                [106.9245577, 11.0575544],
                [106.9247914, 11.0576596],
                [106.9250178, 11.0577518],
                [106.9252653, 11.0578373],
                [106.9254991, 11.0579252],
                [106.9257298, 11.0580207],
                [106.9259572, 11.0581237],
                [106.926181, 11.0582341],
                [106.9264008, 11.0583517],
                [106.9266166, 11.0584765],
                [106.926828, 11.0586082],
                [106.9270348, 11.0587468],
                [106.9272401, 11.0588945],
                [106.9274402, 11.0590489],
                [106.9276348, 11.0592099],
                [106.9278237, 11.0593773],
                [106.9280068, 11.0595508],
                [106.9281838, 11.0597303],
                [106.9283545, 11.0599156],
                [106.9286165, 11.0603033],
                [106.9288738, 11.060694],
                [106.9291262, 11.0610878],
                [106.9293737, 11.0614845],
                [106.9296373, 11.0619192],
                [106.9298951, 11.0623572],
                [106.930147, 11.0627984],
                [106.930393, 11.0632429],
                [106.9305106, 11.0633967],
                [106.9306359, 11.0635444],
                [106.9307688, 11.0636858],
                [106.9309087, 11.0638203],
                [106.9310554, 11.0639477],
                [106.9312084, 11.0640677],
                [106.9313675, 11.06418],
                [106.9315321, 11.0642842],
                [106.9317019, 11.0643801],
                [106.9318688, 11.0644639],
                [106.9320396, 11.0645397],
                [106.932214, 11.0646072],
                [106.9323915, 11.0646664],
                [106.9325718, 11.0647171],
                [106.9327543, 11.0647592],
                [106.9329386, 11.0647926],
                [106.9331405, 11.0648224],
                [106.9333273, 11.0648434],
                [106.9335236, 11.0648595],
                [106.9336935, 11.0648567],
                [106.9338851, 11.0648562],
                [106.9340765, 11.0648474],
                [106.9342672, 11.0648303],
                [106.9344571, 11.0648049],
                [106.9346456, 11.0647714],
                [106.9348324, 11.0647297],
                [106.9350171, 11.06468],
                [106.9351995, 11.0646223],
                [106.9353888, 11.064553],
                [106.9355745, 11.0644751],
                [106.9357564, 11.0643888],
                [106.935934, 11.0642942],
                [106.9361069, 11.0641917],
                [106.9362747, 11.0640814],
                [106.9364372, 11.0639636],
                [106.9365939, 11.0638384],
                [106.9367444, 11.0637062],
                [106.9369433, 11.0635175],
                [106.9371358, 11.0633225],
                [106.9373218, 11.0631216],
                [106.9375011, 11.0629148],
                [106.9376735, 11.0627025],
                [106.9378388, 11.0624848],
                [106.9379837, 11.0622811],
                [106.9381223, 11.0620733],
                [106.9382547, 11.0618616],
                [106.9383806, 11.0616461],
                [106.9384999, 11.0614271],
                [106.9386126, 11.0612047],
                [106.9387185, 11.0609791],
                [106.938831, 11.0607019],
                [106.938949, 11.0604269],
                [106.9390725, 11.0601543],
                [106.9392013, 11.059884],
                [106.9393718, 11.0595463],
                [106.9395506, 11.0592128],
                [106.9397378, 11.0588837],
                [106.9398266, 11.0587229],
                [106.9399219, 11.0585656],
                [106.9400234, 11.0584122],
                [106.9401311, 11.0582628],
                [106.9402446, 11.0581177],
                [106.9403639, 11.0579771],
                [106.9404888, 11.0578413],
                [106.9406274, 11.0577023],
                [106.9407719, 11.0575692],
                [106.940922, 11.0574421],
                [106.9410773, 11.0573213],
                [106.9412377, 11.0572071],
                [106.9414028, 11.0570995],
                [106.9415939, 11.0570042],
                [106.941789, 11.0569448],
                [106.9420077, 11.0568992],
                [106.9422281, 11.0568623],
                [106.9424498, 11.0568342],
                [106.9426724, 11.0568148],
                [106.9428957, 11.0568041],
                [106.9431192, 11.0568023],
                [106.9433427, 11.0568094],
                [106.943569, 11.0568255],
                [106.9437945, 11.0568507],
                [106.9440188, 11.0568849],
                [106.9442415, 11.056928],
                [106.9444622, 11.05698],
                [106.9446805, 11.0570408],
                [106.9448961, 11.0571103],
                [106.9451087, 11.0571884],
                [106.9453178, 11.0572749],
                [106.9455232, 11.0573698],
                [106.9457352, 11.0574727],
                [106.9459105, 11.0575615],
                [106.946092, 11.0576587],
                [106.9462687, 11.0577643],
                [106.94644, 11.0578779],
                [106.9466058, 11.0579993],
                [106.9467655, 11.0581282],
                [106.9469188, 11.0582644],
                [106.9470655, 11.0584075],
                [106.9472051, 11.0585573],
                [106.947326, 11.0586994],
                [106.9474406, 11.0588466],
                [106.9475487, 11.0589984],
                [106.94765, 11.0591546],
                [106.9477443, 11.059315],
                [106.9478316, 11.0594792],
                [106.9479115, 11.059647],
                [106.947984, 11.059818],
                [106.948049, 11.0599919],
                [106.9481063, 11.0601683],
                [106.9481853, 11.0604014],
                [106.9482567, 11.0606368],
                [106.9483206, 11.0608743],
                [106.9483769, 11.0611136],
                [106.9484255, 11.0613545],
                [106.9484664, 11.0615969],
                [106.9484994, 11.0618403],
                [106.9485247, 11.0620847],
                [106.9485419, 11.0623257],
                [106.9485515, 11.0625672],
                [106.9485535, 11.0628088],
                [106.9485479, 11.0630504],
                [106.9485347, 11.0632917],
                [106.9485139, 11.0635325],
                [106.9484855, 11.0637725],
                [106.948455, 11.0640063],
                [106.9484386, 11.0642455],
                [106.9484353, 11.0644796],
                [106.9484398, 11.0647137],
                [106.9484521, 11.0649476],
                [106.9484721, 11.0651809],
                [106.9484998, 11.0654134],
                [106.9485352, 11.065645],
                [106.9485783, 11.0658753],
                [106.9486324, 11.0661181],
                [106.948695, 11.0663589],
                [106.9487661, 11.0665975],
                [106.9488455, 11.0668335],
                [106.9489331, 11.0670667],
                [106.949029, 11.0672967],
                [106.949192, 11.0675533],
                [106.9493627, 11.067805],
                [106.949541, 11.0680516],
                [106.9497266, 11.0682929],
                [106.9499195, 11.0685287],
                [106.9501006, 11.0687378],
                [106.9502875, 11.0689422],
                [106.9504799, 11.0691415],
                [106.9506777, 11.0693356],
                [106.9508807, 11.0695245],
                [106.9510004, 11.0696079],
                [106.9511211, 11.0696764],
                [106.951265, 11.0697507],
                [106.9514041, 11.0698334],
                [106.9515379, 11.0699241],
                [106.9516659, 11.0700225],
                [106.9517877, 11.0701282],
                [106.9519029, 11.0702409],
                [106.952011, 11.0703601],
                [106.9521117, 11.0704855],
                [106.9521982, 11.070607],
                [106.9522777, 11.0707332],
                [106.95235, 11.0708634],
                [106.9524148, 11.0709974],
                [106.9524719, 11.0711348],
                [106.9525211, 11.071275],
                [106.9525481, 11.0714215],
                [106.952567, 11.0715691],
                [106.9525777, 11.0717176],
                [106.9525802, 11.0718664],
                [106.9525738, 11.0720243],
                [106.9525582, 11.0721816],
                [106.9525334, 11.0723377],
                [106.9524994, 11.0724921],
                [106.9524565, 11.0726444],
                [106.9524047, 11.072794],
                [106.9523066, 11.0729562],
                [106.9521568, 11.0731199],
                [106.9519641, 11.0732939],
                [106.9517664, 11.0734623],
                [106.9515637, 11.0736251],
                [106.9513563, 11.0737819],
                [106.9511443, 11.0739328],
                [106.950928, 11.0740775],
                [106.9506935, 11.0742244],
                [106.9504545, 11.0743642],
                [106.9502113, 11.0744965],
                [106.949964, 11.0746215],
                [106.9497128, 11.0747388],
                [106.9494581, 11.0748485],
                [106.9492, 11.0749504],
                [106.9489389, 11.0750444],
                [106.9486856, 11.0751173],
                [106.9484569, 11.0751815],
                [106.948241, 11.0752403],
                [106.9480273, 11.0753065],
                [106.9478162, 11.0753802],
                [106.9476079, 11.0754613],
                [106.9474026, 11.0755496],
                [106.9472008, 11.0756451],
                [106.9470025, 11.0757476],
                [106.9468081, 11.0758569],
                [106.94661, 11.075978],
                [106.9464167, 11.0761062],
                [106.9462283, 11.0762414],
                [106.9460452, 11.0763834],
                [106.9458677, 11.076532],
                [106.9456958, 11.0766869],
                [106.94553, 11.0768481],
                [106.9453704, 11.0770151],
                [106.9452903, 11.0771696],
                [106.9452167, 11.0773271],
                [106.9451499, 11.0774876],
                [106.9450899, 11.0776506],
                [106.9450369, 11.0778159],
                [106.9449909, 11.0779832],
                [106.944952, 11.0781523],
                [106.9449202, 11.0783232],
                [106.9448957, 11.0784953],
                [106.9448785, 11.0786683],
                [106.9448687, 11.0788418],
                [106.9448662, 11.0790156],
                [106.9448726, 11.0791835],
                [106.9448866, 11.079351],
                [106.9449081, 11.0795176],
                [106.9449371, 11.0796832],
                [106.9449735, 11.0798474],
                [106.9450165, 11.0800074],
                [106.9450666, 11.0801655],
                [106.9451237, 11.0803212],
                [106.9452277, 11.0806648],
                [106.9453973, 11.0810687],
                [106.9455421, 11.081353],
                [106.9456618, 11.0815681],
                [106.9457618, 11.0817426],
                [106.9458685, 11.0819131],
                [106.945982, 11.0820795],
                [106.9461025, 11.0822422],
                [106.9462294, 11.0824001],
                [106.9463625, 11.082553],
                [106.9464479, 11.0826422],
                [106.9465275, 11.0827364],
                [106.9466011, 11.0828353],
                [106.9466683, 11.0829384],
                [106.9467289, 11.0830454],
                [106.9467828, 11.0831558],
                [106.9468295, 11.0832693],
                [106.9468698, 11.0833875],
                [106.9469024, 11.083508],
                [106.9469661, 11.0837492],
                [106.9470205, 11.0839927],
                [106.9470656, 11.0842379],
                [106.9470981, 11.0844597],
                [106.9471229, 11.0846825],
                [106.9471401, 11.0849059],
                [106.9471496, 11.0851299],
                [106.9471514, 11.0853539],
                [106.9471363, 11.0855827],
                [106.9471148, 11.085811],
                [106.9470869, 11.0860385],
                [106.9470526, 11.0862653],
                [106.9470119, 11.086491],
                [106.9469592, 11.0867412],
                [106.9468987, 11.0869898],
                [106.9468304, 11.0872363],
                [106.9467544, 11.0874807],
                [106.9467161, 11.0876551],
                [106.9466872, 11.0878312],
                [106.9466678, 11.0880086],
                [106.946658, 11.0881867],
                [106.9466579, 11.0883651],
                [106.9466658, 11.0885238],
                [106.9466814, 11.088682],
                [106.9467045, 11.0888392],
                [106.9467352, 11.0889952],
                [106.9467733, 11.0891497],
                [106.9468188, 11.0893022],
                [106.9468817, 11.0894636],
                [106.946953, 11.0896216],
                [106.9470326, 11.0897757],
                [106.9471203, 11.0899256],
                [106.9472158, 11.0900707],
                [106.947306, 11.0901943],
                [106.947402, 11.0903137],
                [106.9475033, 11.0904286],
                [106.94761, 11.0905389],
                [106.9482023, 11.0911275],
                [106.9485706, 11.0915065],
                [106.9489431, 11.0918816],
                [106.9491196, 11.0920451],
                [106.9492907, 11.0922141],
                [106.9494562, 11.0923883],
                [106.949616, 11.0925677],
                [106.9497698, 11.0927519],
                [106.9499175, 11.092941],
                [106.9500589, 11.0931345],
                [106.950184, 11.0933173],
                [106.9503035, 11.0935035],
                [106.9504173, 11.0936932],
                [106.9505254, 11.0938861],
                [106.9506276, 11.0940821],
                [106.9508312, 11.0944292],
                [106.9510279, 11.0947802],
                [106.9512176, 11.0951349],
                [106.9513939, 11.0954803],
                [106.9515636, 11.0958289],
                [106.9517266, 11.0961806],
                [106.9518828, 11.0965352],
                [106.9519305, 11.096677],
                [106.9519872, 11.0968155],
                [106.9520528, 11.0969503],
                [106.952127, 11.0970807],
                [106.9522094, 11.0972062],
                [106.9522998, 11.0973263],
                [106.9523978, 11.0974406],
                [106.9524944, 11.0975402],
                [106.9525968, 11.0976342],
                [106.9527046, 11.097722],
                [106.9528175, 11.0978036],
                [106.952935, 11.0978786],
                [106.9530568, 11.0979466],
                [106.9531824, 11.0980076],
                [106.9533114, 11.0980613],
                [106.9534434, 11.0981075],
                [106.953578, 11.098146],
                [106.9567859, 11.0986724],
                [106.9569778, 11.0987227],
                [106.9571672, 11.0987816],
                [106.9573537, 11.0988488],
                [106.9575368, 11.0989243],
                [106.9577163, 11.099008],
                [106.9578917, 11.0990995],
                [106.9580626, 11.0991988],
                [106.9582242, 11.0993025],
                [106.958381, 11.0994132],
                [106.9585326, 11.0995305],
                [106.9586788, 11.0996543],
                [106.9588192, 11.0997844],
                [106.9589537, 11.0999204],
                [106.9590819, 11.1000621],
                [106.9591702, 11.1001844],
                [106.9592505, 11.100312],
                [106.9593225, 11.1004443],
                [106.9593858, 11.1005807],
                [106.9594403, 11.1007208],
                [106.9594857, 11.1008639],
                [106.9595218, 11.1010096],
                [106.9595471, 11.1011483],
                [106.959564, 11.1012882],
                [106.9595725, 11.1014288],
                [106.9595725, 11.1015696],
                [106.959564, 11.1017102],
                [106.9595498, 11.1018606],
                [106.9595325, 11.1019993],
                [106.959513, 11.1021152],
                [106.9594896, 11.1022299],
                [106.9594598, 11.102348],
                [106.959421, 11.1024636],
                [106.9593735, 11.1025761],
                [106.9593176, 11.1026847],
                [106.9592535, 11.1027889],
                [106.9591886, 11.1028792],
                [106.9591175, 11.102965],
                [106.9590191, 11.103088],
                [106.9588224, 11.1032986],
                [106.9585991, 11.1035469],
                [106.9583853, 11.1037755],
                [106.9581671, 11.1040001],
                [106.9579661, 11.1041996],
                [106.9578436, 11.1043427],
                [106.9577492, 11.1044697],
                [106.9576619, 11.1046015],
                [106.9575817, 11.1047376],
                [106.957509, 11.1048777],
                [106.957444, 11.1050214],
                [106.9573867, 11.1051682],
                [106.9573374, 11.1053182],
                [106.9572962, 11.1054705],
                [106.9572633, 11.1056247],
                [106.9572387, 11.1057805],
                [106.9572227, 11.1059372],
                [106.9572151, 11.1060947],
                [106.9572371, 11.1062295],
                [106.9572673, 11.1063628],
                [106.9573057, 11.106494],
                [106.957352, 11.1066228],
                [106.9574061, 11.1067486],
                [106.9574678, 11.106871],
                [106.9575369, 11.1069895],
                [106.9576188, 11.1071117],
                [106.9577085, 11.1072284],
                [106.9578057, 11.1073392],
                [106.9579099, 11.1074436],
                [106.9580207, 11.1075413],
                [106.9581377, 11.1076317],
                [106.9583027, 11.107714],
                [106.9584717, 11.1077881],
                [106.9586441, 11.1078539],
                [106.9588197, 11.1079113],
                [106.958998, 11.1079601],
                [106.9591784, 11.1080002],
                [106.9593606, 11.1080315],
                [106.959544, 11.1080539],
                [106.9597284, 11.1080674],
                [106.9599132, 11.1080719],
                [106.960098, 11.1080675],
                [106.9602824, 11.1080541],
                [106.960482, 11.1080213],
                [106.9606907, 11.1079675],
                [106.9609179, 11.1079117],
                [106.9611471, 11.1078644],
                [106.9613779, 11.1078258],
                [106.9616101, 11.1077958],
                [106.9618433, 11.1077745],
                [106.9620771, 11.1077619],
                [106.9623113, 11.1077581],
                [106.9625576, 11.1077635],
                [106.9628035, 11.1077786],
                [106.9652578, 11.1080402],
                [106.9653558, 11.1082803],
                [106.9654573, 11.1085416],
                [106.9655514, 11.1088056],
                [106.9656472, 11.1091016],
                [106.9657177, 11.1093478],
                [106.9657555, 11.1095875],
                [106.9657686, 11.1097754],
                [106.965773, 11.1099636],
                [106.9657686, 11.1101519],
                [106.9657555, 11.1103398],
                [106.9657338, 11.1105269],
                [106.9657031, 11.110714],
                [106.9656638, 11.1108996],
                [106.9656158, 11.1110832],
                [106.9655593, 11.1112645],
                [106.9654945, 11.1114431],
                [106.9654214, 11.1116185],
                [106.9653401, 11.1117905],
                [106.965208, 11.1119902],
                [106.9649958, 11.112285],
                [106.9647479, 11.1126167],
                [106.9645075, 11.1129536],
                [106.9642746, 11.1132957],
                [106.9640631, 11.1136211],
                [106.9638584, 11.1139508],
                [106.9636607, 11.1142845],
                [106.9634914, 11.1145958],
                [106.9633184, 11.1149165],
                [106.9631753, 11.1152148],
                [106.9630408, 11.115517],
                [106.9629415, 11.1157561],
                [106.9628476, 11.1159973],
                [106.9627591, 11.1162405],
                [106.962676, 11.1164855],
                [106.9626012, 11.1166027],
                [106.9625195, 11.1167153],
                [106.9624312, 11.116823],
                [106.9623367, 11.1169255],
                [106.9622362, 11.1170224],
                [106.96213, 11.1171134],
                [106.9620186, 11.1171981],
                [106.9619023, 11.1172763],
                [106.9617816, 11.1173476],
                [106.9616568, 11.1174119],
                [106.9614, 11.1175464],
                [106.961147, 11.1176876],
                [106.9608979, 11.1178354],
                [106.960653, 11.1179897],
                [106.9604124, 11.1181503],
                [106.9601762, 11.1183173],
                [106.9599175, 11.1185115],
                [106.9596648, 11.1187132],
                [106.9594183, 11.1189222],
                [106.9591784, 11.1191384],
                [106.9589998, 11.1193297],
                [106.9588277, 11.1195266],
                [106.9586623, 11.1197289],
                [106.9585038, 11.1199365],
                [106.9583523, 11.1201491],
                [106.9582226, 11.1203438],
                [106.9580989, 11.1205421],
                [106.9579811, 11.120744],
                [106.9578695, 11.1209492],
                [106.9576617, 11.1213101],
                [106.9574601, 11.1216743],
                [106.9572647, 11.1220418],
                [106.9570756, 11.1224125],
                [106.9568764, 11.1228206],
                [106.9566847, 11.1232321],
                [106.9565008, 11.1236471],
                [106.956346, 11.1240547],
                [106.9562067, 11.1244884],
                [106.9560816, 11.1249095],
                [106.9559491, 11.1253285],
                [106.9557926, 11.1257932],
                [106.9556272, 11.1262549],
                [106.9555499, 11.1264188],
                [106.9554649, 11.126579],
                [106.9553724, 11.1267351],
                [106.9552727, 11.1268869],
                [106.9551659, 11.1270339],
                [106.9550609, 11.1271656],
                [106.9549503, 11.1272927],
                [106.9548342, 11.127415],
                [106.9547128, 11.1275324],
                [106.9545865, 11.1276445],
                [106.9544386, 11.12777],
                [106.9542944, 11.1278996],
                [106.9541541, 11.1280332],
                [106.9540179, 11.1281708],
                [106.9538853, 11.1283128],
                [106.953757, 11.1284584],
                [106.9536331, 11.1286076],
                [106.9535136, 11.1287604],
                [106.9534532, 11.128859],
                [106.9534007, 11.1289618],
                [106.9533565, 11.1290684],
                [106.9533209, 11.129178],
                [106.9532939, 11.1292899],
                [106.9532759, 11.1294036],
                [106.9532668, 11.1295183],
                [106.9532666, 11.1296256],
                [106.9532742, 11.1297327],
                [106.9532897, 11.1298389],
                [106.9533129, 11.1299437],
                [106.9533438, 11.1300467],
                [106.9533821, 11.1301472],
                [106.9534278, 11.1302447],
                [106.9534954, 11.1303649],
                [106.9535692, 11.1304817],
                [106.9536489, 11.1305947],
                [106.9537343, 11.1307035],
                [106.9538252, 11.130808],
                [106.9539213, 11.1309079],
                [106.9540332, 11.1310123],
                [106.9541508, 11.1311105],
                [106.9542738, 11.1312021],
                [106.9544018, 11.1312869],
                [106.9545344, 11.1313645],
                [106.9546712, 11.1314347],
                [106.9548118, 11.1314974],
                [106.955004, 11.1316277],
                [106.9551906, 11.1317658],
                [106.9553711, 11.1319113],
                [106.9555454, 11.1320641],
                [106.955713, 11.1322238],
                [106.9558743, 11.1323907],
                [106.9560284, 11.1325641],
                [106.956175, 11.1327437],
                [106.9563138, 11.1329291],
                [106.9564043, 11.1330892],
                [106.9564882, 11.1332527],
                [106.9565656, 11.1334193],
                [106.9566362, 11.1335887],
                [106.9567001, 11.1337607],
                [106.9567573, 11.133936],
                [106.9568075, 11.1341135],
                [106.9568504, 11.1342927],
                [106.9568862, 11.1344735],
                [106.9569147, 11.1346555],
                [106.9569418, 11.1348012],
                [106.956961, 11.1349482],
                [106.9569722, 11.1350959],
                [106.9569754, 11.135244],
                [106.9569705, 11.1353921],
                [106.9569576, 11.1355397],
                [106.9569357, 11.1356922],
                [106.9569052, 11.1358432],
                [106.9568663, 11.1359924],
                [106.9568191, 11.1361392],
                [106.9567636, 11.1362832],
                [106.9567001, 11.136424],
                [106.9566382, 11.1365512],
                [106.9565685, 11.1366744],
                [106.9564913, 11.1367932],
                [106.9564068, 11.1369071],
                [106.9563153, 11.1370158],
                [106.9562173, 11.1371188],
                [106.9561111, 11.1372173],
                [106.9559989, 11.1373091],
                [106.955881, 11.1373939],
                [106.955758, 11.1374714],
                [106.9556303, 11.1375412],
                [106.9554984, 11.137603],
                [106.9553536, 11.1376808],
                [106.955213, 11.1377657],
                [106.9550768, 11.1378575],
                [106.9549456, 11.1379559],
                [106.9548196, 11.1380606],
                [106.9546991, 11.1381714],
                [106.9545902, 11.138282],
                [106.9544867, 11.1383976],
                [106.954389, 11.138518],
                [106.9542973, 11.1386427],
                [106.9542118, 11.1387717],
                [106.9541326, 11.1389045],
                [106.9540703, 11.1390437],
                [106.9540141, 11.1391854],
                [106.9539642, 11.1393294],
                [106.9539355, 11.1394771],
                [106.9539147, 11.1396262],
                [106.9539018, 11.139776],
                [106.9538969, 11.1399264],
                [106.9538998, 11.1400768],
                [106.9539119, 11.1402375],
                [106.953933, 11.1403972],
                [106.9539631, 11.1405556],
                [106.9540022, 11.1407121],
                [106.9540501, 11.1408663],
                [106.954085, 11.1409812],
                [106.9541287, 11.1410932],
                [106.954181, 11.1412016],
                [106.9542414, 11.1413058],
                [106.9543098, 11.1414052],
                [106.9543856, 11.1414992],
                [106.9544685, 11.1415873],
                [106.9545559, 11.1416673],
                [106.9546491, 11.1417407],
                [106.9547476, 11.1418071],
                [106.9548508, 11.1418662],
                [106.9549583, 11.1419176],
                [106.9550693, 11.141961],
                [106.9553548, 11.1420102],
                [106.9556414, 11.1420529],
                [106.955929, 11.1420892],
                [106.9562173, 11.1421189],
                [106.9565653, 11.1421461],
                [106.9569139, 11.1421639],
                [106.9572629, 11.1421721],
                [106.957612, 11.1421709],
                [106.9579609, 11.1421602],
                [106.9583094, 11.14214],
                [106.9585738, 11.1421001],
                [106.9588394, 11.1420691],
                [106.9591059, 11.142047],
                [106.9593731, 11.1420339],
                [106.9596406, 11.1420298],
                [106.959908, 11.1420347],
                [106.9601805, 11.142049],
                [106.9604524, 11.1420726],
                [106.9607232, 11.1421054],
                [106.9615388, 11.1421716],
                [106.9622575, 11.142226],
                [106.9629067, 11.142261],
                [106.9632156, 11.1422814],
                [106.9634545, 11.1422931],
                [106.9636929, 11.1423121],
                [106.9639306, 11.1423382],
                [106.9641673, 11.1423716],
                [106.9644085, 11.1424131],
                [106.9646482, 11.1424621],
                [106.9648862, 11.1425184],
                [106.9651222, 11.1425821],
                [106.9652681, 11.1426587],
                [106.9654091, 11.1427436],
                [106.9655448, 11.1428366],
                [106.9656746, 11.1429372],
                [106.9657981, 11.1430453],
                [106.9659107, 11.1431559],
                [106.9660167, 11.1432727],
                [106.9661157, 11.1433952],
                [106.9662075, 11.143523],
                [106.9662917, 11.1436558],
                [106.9663665, 11.143785],
                [106.9664345, 11.1439177],
                [106.9664954, 11.1440537],
                [106.9665491, 11.1441927],
                [106.9665919, 11.1443222],
                [106.9666284, 11.1444536],
                [106.9666585, 11.1445865],
                [106.9666822, 11.1447207],
                [106.966694, 11.1448769],
                [106.9666766, 11.1450103],
                [106.9666476, 11.1451637],
                [106.9666122, 11.1453158],
                [106.9665706, 11.1454664],
                [106.9665193, 11.1456254],
                [106.9664609, 11.1457822],
                [106.9663958, 11.1459363],
                [106.9663131, 11.1461138],
                [106.9662479, 11.1462978],
                [106.9661778, 11.1465102],
                [106.9661137, 11.1467243],
                [106.9660556, 11.1469401],
                [106.9659991, 11.1471774],
                [106.9659498, 11.1474164],
                [106.9659078, 11.1476566],
                [106.9658732, 11.147898],
                [106.9658567, 11.1480384],
                [106.9658308, 11.1481775],
                [106.9657958, 11.1483146],
                [106.9657517, 11.1484492],
                [106.9656987, 11.1485807],
                [106.9656372, 11.1487085],
                [106.9655685, 11.1488302],
                [106.9654919, 11.1489473],
                [106.9654079, 11.1490593],
                [106.9653167, 11.1491658],
                [106.9652188, 11.1492664],
                [106.9651027, 11.1493714],
                [106.964982, 11.1494711],
                [106.9648568, 11.1495654],
                [106.9647274, 11.1496541],
                [106.9645941, 11.1497369],
                [106.964457, 11.1498138],
                [106.9643092, 11.149888],
                [106.964158, 11.1499552],
                [106.9640037, 11.1500154],
                [106.9638466, 11.1500682],
                [106.9636872, 11.1501137],
                [106.9635258, 11.1501517],
                [106.9633627, 11.1501822],
                [106.9626888, 11.1503457],
                [106.9620135, 11.1505038],
                [106.9613369, 11.1506563],
                [106.960659, 11.1508033],
                [106.9599623, 11.1509483],
                [106.9592643, 11.1510875],
                [106.959002, 11.1511542],
                [106.9587418, 11.1512287],
                [106.9584841, 11.1513109],
                [106.958229, 11.1514007],
                [106.9579768, 11.151498],
                [106.9577454, 11.151595],
                [106.9575169, 11.1516983],
                [106.9572914, 11.1518079],
                [106.9570691, 11.1519236],
                [106.9568503, 11.1520454],
                [106.9567247, 11.152117],
                [106.9566023, 11.1521936],
                [106.9564831, 11.152275],
                [106.9563675, 11.1523611],
                [106.9562299, 11.1524736],
                [106.9560984, 11.1525928],
                [106.9559731, 11.1527183],
                [106.9558543, 11.1528498],
                [106.9557425, 11.152987],
                [106.9556728, 11.1530953],
                [106.9556299, 11.1532059],
                [106.9555851, 11.1533969],
                [106.9555394, 11.1536655],
                [106.9555008, 11.1539351],
                [106.9554692, 11.1542057],
                [106.9554448, 11.1544769],
                [106.9554268, 11.1547629],
                [106.9554167, 11.1550493],
                [106.9554146, 11.1553358],
                [106.9554204, 11.1556223],
                [106.9554502, 11.1558769],
                [106.9554955, 11.1561272],
                [106.9555488, 11.1563479],
                [106.9555941, 11.1565704],
                [106.9556312, 11.1567943],
                [106.9556601, 11.1570193],
                [106.9556808, 11.1572453],
                [106.955694, 11.1574917],
                [106.9556973, 11.1577384],
                [106.9556909, 11.1579851],
                [106.9556746, 11.1582314],
                [106.9556486, 11.1584768],
                [106.9555931, 11.1587265],
                [106.955531, 11.1589746],
                [106.9554623, 11.1592211],
                [106.955387, 11.1594657],
                [106.9553053, 11.1597084],
                [106.9552154, 11.1599536],
                [106.9551189, 11.1601963],
                [106.9550158, 11.1604365],
                [106.9549063, 11.1606739],
                [106.954785, 11.160961],
                [106.9546757, 11.1612195],
                [106.9545552, 11.1615286],
                [106.9544289, 11.1618354],
                [106.9542968, 11.1621399],
                [106.9541462, 11.1624693],
                [106.9539888, 11.1627956],
                [106.9538247, 11.1631188],
                [106.9537266, 11.1632536],
                [106.9536216, 11.1633834],
                [106.9535099, 11.1635078],
                [106.9533919, 11.1636264],
                [106.9532678, 11.1637389],
                [106.9531381, 11.163845],
                [106.9529974, 11.1639485],
                [106.9528513, 11.1640444],
                [106.9527002, 11.1641325],
                [106.9525445, 11.1642127],
                [106.9523847, 11.1642846],
                [106.9522212, 11.1643481],
                [106.9520545, 11.1644029],
                [106.951388, 11.1647001],
                [106.9507241, 11.1650029],
                [106.9499983, 11.1653416],
                [106.9492757, 11.1656871],
                [106.9490676, 11.1657749],
                [106.9488566, 11.1658558],
                [106.9486429, 11.1659297],
                [106.9484268, 11.1659964],
                [106.9482085, 11.1660559],
                [106.9479883, 11.1661081],
                [106.9477759, 11.1661512],
                [106.9475621, 11.1661875],
                [106.9473473, 11.166217],
                [106.9471317, 11.1662397],
                [106.9469154, 11.1662554],
                [106.9465792, 11.1662577],
                [106.946243, 11.1662542],
                [106.9459069, 11.1662449],
                [106.9456098, 11.1662319],
                [106.9453129, 11.1662143],
                [106.9451196, 11.1662081],
                [106.9449453, 11.1662199],
                [106.9447321, 11.1662617],
                [106.9444494, 11.1663375],
                [106.9441686, 11.1664197],
                [106.9438898, 11.1665081],
                [106.9435851, 11.1666127],
                [106.9432831, 11.1667248],
                [106.9423298, 11.1670552],
                [106.9422148, 11.1670981],
                [106.9421029, 11.1671481],
                [106.9419945, 11.1672051],
                [106.94189, 11.1672688],
                [106.94179, 11.167339],
                [106.9416947, 11.1674153],
                [106.9416046, 11.1674975],
                [106.9415188, 11.1675866],
                [106.941439, 11.1676811],
                [106.9413658, 11.1677805],
                [106.9412993, 11.1678843],
                [106.9412398, 11.1679922],
                [106.9411845, 11.1681108],
                [106.9411374, 11.1682327],
                [106.9410986, 11.1683575],
                [106.9410683, 11.1684845],
                [106.9410467, 11.1686132],
                [106.9410348, 11.1687288],
                [106.94103, 11.1688449],
                [106.9410321, 11.1689611],
                [106.9410413, 11.169077],
                [106.9410574, 11.1691921],
                [106.9410825, 11.169282],
                [106.9411144, 11.1693698],
                [106.9411529, 11.169455],
                [106.9411979, 11.1695372],
                [106.941249, 11.1696157],
                [106.941306, 11.1696903],
                [106.9413686, 11.1697605],
                [106.9414274, 11.1698178],
                [106.94149, 11.1698712],
                [106.9415559, 11.1699204],
                [106.9416251, 11.1699653],
                [106.9418752, 11.1701776],
                [106.9421188, 11.1703971],
                [106.9423556, 11.1706236],
                [106.9425365, 11.170806],
                [106.9427129, 11.1709926],
                [106.9428849, 11.1711831],
                [106.9434178, 11.1717393],
                [106.9440749, 11.1724155],
                [106.9447374, 11.1730866],
                [106.9448716, 11.1732404],
                [106.944999, 11.1733998],
                [106.9451194, 11.1735643],
                [106.9452325, 11.1737336],
                [106.9453382, 11.1739075],
                [106.9454287, 11.1740712],
                [106.9455126, 11.1742382],
                [106.9455898, 11.1744083],
                [106.9456601, 11.1745812],
                [106.9457066, 11.1747662],
                [106.9457472, 11.1749525],
                [106.9457817, 11.17514],
                [106.9458103, 11.1753285],
                [106.945833, 11.1755198],
                [106.9458496, 11.1757118],
                [106.9458599, 11.1759042],
                [106.945864, 11.1760968],
                [106.9458557, 11.1762245],
                [106.9458374, 11.1763512],
                [106.9458094, 11.1764761],
                [106.9457716, 11.1765986],
                [106.9457245, 11.1767178],
                [106.9456682, 11.176833],
                [106.9456032, 11.1769437],
                [106.9455297, 11.1770492],
                [106.9454482, 11.1771488],
                [106.9453592, 11.177242],
                [106.9452631, 11.1773283],
                [106.9451259, 11.1773875],
                [106.9449859, 11.1774399],
                [106.9448435, 11.1774856],
                [106.9446989, 11.1775244],
                [106.9445526, 11.1775562],
                [106.9444048, 11.1775809],
                [106.9442588, 11.1775982],
                [106.944112, 11.1776086],
                [106.9439649, 11.1776121],
                [106.9438179, 11.1776086],
                [106.9436711, 11.1775982],
                [106.9435251, 11.1775809],
                [106.9433868, 11.1775377],
                [106.9432465, 11.1775012],
                [106.9431045, 11.1774717],
                [106.9429612, 11.1774491],
                [106.942817, 11.1774336],
                [106.9426536, 11.1774245],
                [106.9424899, 11.1774244],
                [106.9423265, 11.1774334],
                [106.9421639, 11.1774513],
                [106.9420025, 11.1774782],
                [106.9417748, 11.177562],
                [106.9415507, 11.1776548],
                [106.9413306, 11.1777564],
                [106.9411149, 11.1778666],
                [106.9409039, 11.1779854],
                [106.940698, 11.1781124],
                [106.9405928, 11.1781819],
                [106.940489, 11.1782535],
                [106.9402856, 11.1784022],
                [106.9400879, 11.1785582],
                [106.9398962, 11.1787213],
                [106.939711, 11.1788913],
                [106.9395353, 11.1790649],
                [106.9393662, 11.1792447],
                [106.9392039, 11.1794305],
                [106.9390487, 11.179622],
                [106.9389007, 11.1798189],
                [106.9387227, 11.180042],
                [106.9385737, 11.1802491],
                [106.9384124, 11.1804643],
                [106.9382691, 11.1806643],
                [106.9381011, 11.1808908],
                [106.9379407, 11.1811227],
                [106.9377867, 11.1813619],
                [106.9376409, 11.1816059],
                [106.9375035, 11.1818546],
                [106.9373745, 11.1821076],
                [106.9373077, 11.1822515],
                [106.9372353, 11.1824436],
                [106.9371512, 11.1827249],
                [106.9370404, 11.1830828],
                [106.9369215, 11.1834382],
                [106.936803, 11.1837683],
                [106.9366777, 11.184096],
                [106.9365455, 11.184421],
                [106.9364065, 11.1847433],
                [106.9362639, 11.1850261],
                [106.9361162, 11.1853064],
                [106.9359635, 11.185584],
                [106.9358057, 11.185859],
                [106.9356072, 11.1861892],
                [106.9354016, 11.1865152],
                [106.9351887, 11.1868366],
                [106.9349688, 11.1871535],
                [106.9348168, 11.1873737],
                [106.9346581, 11.1875893],
                [106.9344927, 11.1878],
                [106.9343209, 11.1880056],
                [106.9341427, 11.188206],
                [106.9339831, 11.1883756],
                [106.9338189, 11.1885409],
                [106.9336504, 11.188702],
                [106.9335097, 11.1888375],
                [106.9333631, 11.1889692],
                [106.9332367, 11.1890464],
                [106.9331201, 11.1891318],
                [106.9329979, 11.1892092],
                [106.9328706, 11.1892783],
                [106.9327388, 11.1893386],
                [106.9326031, 11.1893901],
                [106.932502, 11.1894218],
                [106.9323995, 11.1894486],
                [106.9322474, 11.1894809],
                [106.9319862, 11.1895427],
                [106.9317351, 11.1895953],
                [106.9314829, 11.1896426],
                [106.9312298, 11.1896848],
                [106.9309451, 11.1897407],
                [106.9306586, 11.1897877],
                [106.9303708, 11.1898256],
                [106.9300819, 11.1898545],
                [106.9297922, 11.1898742],
                [106.929529, 11.1898842],
                [106.9292657, 11.1898867],
                [106.9290024, 11.1898817],
                [106.9287394, 11.1898692],
                [106.9285144, 11.1898544],
                [106.9282955, 11.18984],
                [106.9279089, 11.1898097],
                [106.927757, 11.1897896],
                [106.9276067, 11.1897602],
                [106.9274586, 11.1897216],
                [106.9273233, 11.1896776],
                [106.9271908, 11.1896258],
                [106.9270616, 11.1895665],
                [106.9269361, 11.1895],
                [106.9268149, 11.1894263],
                [106.9266553, 11.189309],
                [106.9264947, 11.1891907],
                [106.9262957, 11.18903],
                [106.9261014, 11.1888638],
                [106.9258992, 11.1886805],
                [106.9257028, 11.1884914],
                [106.9255121, 11.1882965],
                [106.9253276, 11.1880961],
                [106.9251492, 11.1878904],
                [106.9249772, 11.1876795],
                [106.9248118, 11.1874636],
                [106.9246799, 11.1872693],
                [106.9245931, 11.1871644],
                [106.924526, 11.1870915],
                [106.9244525, 11.1870249],
                [106.924373, 11.1869653],
                [106.9242882, 11.186913],
                [106.9241989, 11.1868687],
                [106.9241059, 11.1868325],
                [106.924007, 11.1868042],
                [106.9239058, 11.1867852],
                [106.9238033, 11.1867757],
                [106.9237003, 11.1867757],
                [106.9235978, 11.1867852],
                [106.9234966, 11.1868042],
                [106.9233602, 11.1868509],
                [106.923212, 11.1869157],
                [106.92303, 11.1870067],
                [106.9228522, 11.1871054],
                [106.9226789, 11.1872114],
                [106.9225361, 11.1873068],
                [106.9223969, 11.1874073],
                [106.9222615, 11.1875127],
                [106.9221302, 11.1876229],
                [106.922003, 11.1877377],
                [106.9219206, 11.1878125],
                [106.9218445, 11.1878936],
                [106.9217754, 11.1879805],
                [106.9217137, 11.1880725],
                [106.9216597, 11.1881692],
                [106.9216179, 11.1882599],
                [106.921583, 11.1883533],
                [106.9215551, 11.188449],
                [106.9215304, 11.188536],
                [106.9215129, 11.1886215],
                [106.9214881, 11.1887445],
                [106.9214411, 11.1889719],
                [106.9214156, 11.189148],
                [106.92139, 11.1893423],
                [106.9213781, 11.1894795],
                [106.92137, 11.1896216],
                [106.9213532, 11.1899101],
                [106.9213556, 11.1901251],
                [106.9213502, 11.1903401],
                [106.9213371, 11.1905548],
                [106.9213164, 11.1907688],
                [106.9212909, 11.1909625],
                [106.9212591, 11.1911552],
                [106.9212211, 11.1913468],
                [106.9211769, 11.1915372],
                [106.9211262, 11.191662],
                [106.9210686, 11.1917839],
                [106.9210042, 11.1919025],
                [106.9209333, 11.1920175],
                [106.920856, 11.1921284],
                [106.9207726, 11.1922351],
                [106.9206834, 11.192337],
                [106.9205864, 11.1924361],
                [106.9204838, 11.1925297],
                [106.9203761, 11.1926175],
                [106.9202635, 11.1926992],
                [106.9201464, 11.1927746],
                [106.9200252, 11.1928434],
                [106.9198787, 11.1929054],
                [106.9197461, 11.1929463],
                [106.9195903, 11.19298],
                [106.919433, 11.1930065],
                [106.9192747, 11.1930258],
                [106.9191155, 11.1930377],
                [106.918956, 11.1930422],
                [106.9187938, 11.1930393],
                [106.9186319, 11.1930287],
                [106.9184707, 11.1930106],
                [106.9183106, 11.1929849],
                [106.9181519, 11.1929517],
                [106.917995, 11.1929112],
                [106.9178402, 11.1928633],
                [106.9176027, 11.1928323],
                [106.9173641, 11.1928097],
                [106.9171249, 11.1927955],
                [106.9168854, 11.1927896],
                [106.9166406, 11.1927923],
                [106.916396, 11.1928037],
                [106.9161521, 11.1928239],
                [106.915909, 11.1928527],
                [106.9155983, 11.1928981],
                [106.9152889, 11.1929519],
                [106.9149811, 11.1930139],
                [106.9146752, 11.1930843],
                [106.9144528, 11.1931515],
                [106.9142346, 11.1932164],
                [106.9140556, 11.1932579],
                [106.9138679, 11.193279],
                [106.913674, 11.193284],
                [106.9134241, 11.1932664],
                [106.9131749, 11.1932419],
                [106.9129264, 11.1932106],
                [106.9126459, 11.1931668],
                [106.912367, 11.1931144],
                [106.9120899, 11.1930533],
                [106.911815, 11.1929836],
                [106.9115424, 11.1929133],
                [106.9111825, 11.1928106],
                [106.9108247, 11.1927088],
                [106.9104689, 11.1926],
                [106.9101155, 11.1924844],
                [106.9097987, 11.1923742],
                [106.9094839, 11.1922584],
                [106.9091713, 11.1921371],
                [106.909027, 11.192092],
                [106.9088803, 11.1920546],
                [106.9087318, 11.192025],
                [106.9085819, 11.1920034],
                [106.908431, 11.1919897],
                [106.9082697, 11.191984],
                [106.9081083, 11.1919873],
                [106.9079473, 11.1919998],
                [106.9077873, 11.1920213],
                [106.9076386, 11.1920652],
                [106.907492, 11.1921157],
                [106.907348, 11.1921726],
                [106.9072067, 11.1922359],
                [106.9070685, 11.1923055],
                [106.9069094, 11.1923955],
                [106.9067555, 11.1924939],
                [106.9066071, 11.1926002],
                [106.9064935, 11.1927092],
                [106.906386, 11.1928241],
                [106.9062848, 11.1929444],
                [106.9061904, 11.1930699],
                [106.9061029, 11.1932001],
                [106.9060175, 11.1933437],
                [106.9059406, 11.1934919],
                [106.9058725, 11.1936442],
                [106.9058159, 11.1937763],
                [106.905757, 11.1939213],
                [106.9057095, 11.1940461],
                [106.9056708, 11.1941738],
                [106.9056411, 11.1943038],
                [106.9056206, 11.1944355],
                [106.9056094, 11.1945683],
                [106.9056073, 11.194696],
                [106.9056139, 11.1948235],
                [106.905629, 11.1949504],
                [106.9056525, 11.1950654],
                [106.9056737, 11.1951945],
                [106.9057002, 11.1953324],
                [106.905724, 11.1954638],
                [106.905756, 11.1955935],
                [106.9057959, 11.1957211],
                [106.9058437, 11.1958461],
                [106.905899, 11.1959681],
                [106.9059561, 11.1960763],
                [106.9060191, 11.1961812],
                [106.906088, 11.1962825],
                [106.9061625, 11.19638],
                [106.9062424, 11.1964732],
                [106.9063761, 11.1965952],
                [106.9065031, 11.1967237],
                [106.9066232, 11.1968586],
                [106.9067359, 11.1969995],
                [106.9068386, 11.1971424],
                [106.9069338, 11.1972903],
                [106.9070213, 11.1974428],
                [106.9071007, 11.1975994],
                [106.9071796, 11.1977792],
                [106.9072507, 11.1979622],
                [106.9073138, 11.1981479],
                [106.9073689, 11.1983361],
                [106.9074168, 11.198531],
                [106.9074561, 11.1987278],
                [106.9074866, 11.1989261],
                [106.9075084, 11.1991254],
                [106.9075391, 11.1993458],
                [106.9075641, 11.1995669],
                [106.9075835, 11.1997885],
                [106.9075999, 11.2000724],
                [106.9076071, 11.2003566],
                [106.9076049, 11.200641],
                [106.9075869, 11.2008787],
                [106.907563, 11.2011159],
                [106.9075333, 11.2013524],
                [106.9074976, 11.2015882],
                [106.9074457, 11.2018776],
                [106.9073848, 11.2021653],
                [106.9073152, 11.2024512],
                [106.9072499, 11.2026649],
                [106.9071779, 11.2028766],
                [106.9070996, 11.2030861],
                [106.9070148, 11.2032931],
                [106.9069212, 11.2035031],
                [106.9068211, 11.2037102],
                [106.9067144, 11.2039141],
                [106.9066383, 11.2040417],
                [106.9065549, 11.2041648],
                [106.9064645, 11.204283],
                [106.9063674, 11.2043961],
                [106.9062638, 11.2045034],
                [106.906147, 11.2046111],
                [106.9060238, 11.2047115],
                [106.9058946, 11.2048045],
                [106.9057598, 11.2048896],
                [106.9056201, 11.2049665],
                [106.9054281, 11.2050638],
                [106.9052335, 11.2051559],
                [106.9050363, 11.2052428],
                [106.9048369, 11.2053243],
                [106.9046046, 11.2054115],
                [106.9043697, 11.2054915],
                [106.9041323, 11.2055642],
                [106.9038927, 11.2056295],
                [106.9037246, 11.2056776],
                [106.9035592, 11.2057341],
                [106.9033969, 11.2057988],
                [106.9032383, 11.2058716],
                [106.9030876, 11.2059501],
                [106.9029411, 11.2060359],
                [106.9027991, 11.2061287],
                [106.902662, 11.2062284],
                [106.9025302, 11.2063347],
                [106.9024196, 11.2064262],
                [106.9023136, 11.2065229],
                [106.9022125, 11.2066244],
                [106.9021163, 11.2067305],
                [106.9020255, 11.206841],
                [106.9019401, 11.2069556],
                [106.9018524, 11.2070866],
                [106.9017719, 11.2072219],
                [106.9016987, 11.2073612],
                [106.9016331, 11.2075041],
                [106.9015753, 11.2076502],
                [106.9015224, 11.2078106],
                [106.9014761, 11.207973],
                [106.9014365, 11.2081372],
                [106.9014037, 11.2083027],
                [106.9013738, 11.2084991],
                [106.9013534, 11.2086967],
                [106.9013427, 11.208895],
                [106.9013415, 11.2090936],
                [106.901342, 11.2092604],
                [106.9013467, 11.2094063],
                [106.9013513, 11.2095207],
                [106.901364, 11.2096344],
                [106.9013845, 11.209747],
                [106.9014128, 11.209858],
                [106.9014488, 11.2099668],
                [106.9014923, 11.210073],
                [106.9015431, 11.210176],
                [106.9016015, 11.2102761],
                [106.9016668, 11.2103721],
                [106.9017386, 11.2104634],
                [106.9018167, 11.2105496],
                [106.9019345, 11.2106252],
                [106.9020573, 11.2106928],
                [106.9021845, 11.2107518],
                [106.9023156, 11.2108022],
                [106.9024518, 11.2108441],
                [106.9025906, 11.2108766],
                [106.9027314, 11.2108995],
                [106.9029111, 11.2109232],
                [106.9032415, 11.2109393],
                [106.9036099, 11.2109604],
                [106.9039786, 11.2109758],
                [106.9043969, 11.2109866],
                [106.9048153, 11.2109902],
                [106.9052337, 11.2109865],
                [106.9056519, 11.2109757],
                [106.90607, 11.2109576],
                [106.9064876, 11.2109324],
                [106.9069047, 11.2109],
                [106.9070384, 11.2108879],
                [106.9071725, 11.210885],
                [106.9073066, 11.2108913],
                [106.9074399, 11.2109069],
                [106.9075717, 11.2109316],
                [106.9077015, 11.2109653],
                [106.9078258, 11.2110069],
                [106.9079469, 11.2110567],
                [106.9080642, 11.2111146],
                [106.9081772, 11.2111803],
                [106.9082854, 11.2112534],
                [106.9083881, 11.2113337],
                [106.9086028, 11.2115625],
                [106.9088103, 11.2117976],
                [106.9090104, 11.2120388],
                [106.9092004, 11.2122824],
                [106.9093828, 11.2125316],
                [106.9095576, 11.212786],
                [106.9096929, 11.2129333],
                [106.9098337, 11.2130755],
                [106.9099798, 11.2132125],
                [106.910131, 11.2133441],
                [106.9102871, 11.21347],
                [106.910447, 11.2135895],
                [106.9106114, 11.2137031],
                [106.9107799, 11.2138107],
                [106.9109523, 11.213912],
                [106.9111483, 11.213963],
                [106.9113461, 11.2140064],
                [106.9115455, 11.214042],
                [106.9117463, 11.2140699],
                [106.9119575, 11.2140907],
                [106.9121694, 11.2141029],
                [106.9123816, 11.2141065],
                [106.9125777, 11.2140936],
                [106.9127958, 11.2140656],
                [106.912999, 11.2140371],
                [106.9132031, 11.214016],
                [106.9134079, 11.2140024],
                [106.9136131, 11.2139962],
                [106.9138092, 11.2139973],
                [106.9140051, 11.2140053],
                [106.9142006, 11.21402],
                [106.9143954, 11.2140416],
                [106.9145894, 11.2140699],
                [106.9147217, 11.2141039],
                [106.9148517, 11.214146],
                [106.9149787, 11.2141959],
                [106.9151022, 11.2142534],
                [106.9152219, 11.2143185],
                [106.9153372, 11.2143907],
                [106.9154477, 11.2144698],
                [106.9155497, 11.2145527],
                [106.9156463, 11.2146415],
                [106.9157374, 11.2147358],
                [106.9158224, 11.2148353],
                [106.9159012, 11.2149398],
                [106.9159734, 11.2150486],
                [106.9161009, 11.2152512],
                [106.9162222, 11.2154574],
                [106.9163371, 11.2156671],
                [106.9164455, 11.21588],
                [106.9165569, 11.2161171],
                [106.9166602, 11.2163577],
                [106.9167554, 11.2166015],
                [106.9168424, 11.2168482],
                [106.9169586, 11.2171917],
                [106.9170838, 11.217532],
                [106.917218, 11.217869],
                [106.9173531, 11.2181847],
                [106.9174962, 11.2184969],
                [106.9176471, 11.2188057],
                [106.9177286, 11.2189709],
                [106.9178034, 11.2191391],
                [106.9178714, 11.2193101],
                [106.9179324, 11.2194836],
                [106.9179865, 11.2196593],
                [106.9180334, 11.219837],
                [106.9180728, 11.2200151],
                [106.9181051, 11.2201946],
                [106.9181303, 11.2203753],
                [106.9181481, 11.2205567],
                [106.9181588, 11.2207387],
                [106.9181621, 11.2209209],
                [106.9181769, 11.2211144],
                [106.9181832, 11.2213083],
                [106.9181811, 11.2215023],
                [106.9181704, 11.221696],
                [106.9181514, 11.2218891],
                [106.9181234, 11.2220841],
                [106.9180868, 11.2222778],
                [106.9180417, 11.2224697],
                [106.917988, 11.2226595],
                [106.9179261, 11.2228468],
                [106.9178775, 11.2229862],
                [106.9178209, 11.2231227],
                [106.9177563, 11.2232558],
                [106.917684, 11.2233849],
                [106.9176042, 11.2235098],
                [106.9175197, 11.2236267],
                [106.9174285, 11.2237388],
                [106.9173312, 11.2238457],
                [106.9172278, 11.2239471],
                [106.9171189, 11.2240426],
                [106.9170046, 11.2241319],
                [106.9168612, 11.2242359],
                [106.9164321, 11.224524],
                [106.91607, 11.2247831],
                [106.9158447, 11.2249357],
                [106.9156094, 11.22508],
                [106.9152858, 11.225273],
                [106.9149864, 11.2254356],
                [106.9148726, 11.2254953],
                [106.9147637, 11.2255633],
                [106.9146604, 11.2256391],
                [106.9145631, 11.2257223],
                [106.9144726, 11.2258126],
                [106.9143893, 11.2259092],
                [106.9143136, 11.2260118],
                [106.9142461, 11.2261196],
                [106.9141932, 11.2262193],
                [106.9141473, 11.2263222],
                [106.9141085, 11.2264279],
                [106.914077, 11.226536],
                [106.9140556, 11.2266537],
                [106.9140629, 11.2267613],
                [106.9140797, 11.226876],
                [106.9141033, 11.2269895],
                [106.9141336, 11.2271015],
                [106.9141705, 11.2272115],
                [106.9142139, 11.2273193],
                [106.914267, 11.227431],
                [106.9143271, 11.2275393],
                [106.914394, 11.2276437],
                [106.9144673, 11.2277438],
                [106.9145469, 11.2278392],
                [106.9146323, 11.2279297],
                [106.9147264, 11.2280479],
                [106.9148146, 11.2281704],
                [106.9148967, 11.228297],
                [106.9149725, 11.2284273],
                [106.9150418, 11.228561],
                [106.9151044, 11.2286979],
                [106.9151629, 11.2288448],
                [106.9152137, 11.2289944],
                [106.9152567, 11.2291463],
                [106.9152972, 11.2292817],
                [106.9153216, 11.2294135],
                [106.9153374, 11.2295384],
                [106.9153472, 11.2296226],
                [106.9153482, 11.2297074],
                [106.9153404, 11.2297918],
                [106.9153238, 11.229875],
                [106.9152987, 11.2299562],
                [106.9152653, 11.2300344],
                [106.9152214, 11.2301129],
                [106.9151691, 11.2301863],
                [106.9151091, 11.2302538],
                [106.915042, 11.2303146],
                [106.9149686, 11.2303679],
                [106.9148898, 11.2304132],
                [106.9147799, 11.2304502],
                [106.9146674, 11.2304788],
                [106.914553, 11.2304989],
                [106.9144374, 11.2305104],
                [106.9143212, 11.2305132],
                [106.9142029, 11.230507],
                [106.9140855, 11.2304918],
                [106.9139697, 11.2304677],
                [106.9138561, 11.2304348],
                [106.9137724, 11.2303985],
                [106.9136788, 11.2303566],
                [106.913532, 11.2305983],
                [106.9134163, 11.2308558],
                [106.9133334, 11.2311251],
                [106.9133046, 11.231263],
                [106.9132748, 11.2315107],
                [106.9132724, 11.2317602],
                [106.913377, 11.232139],
                [106.9134059, 11.2322035],
                [106.9134786, 11.2323249],
                [106.9135844, 11.2324487],
                [106.9136536, 11.2325098],
                [106.9137286, 11.2325637],
                [106.9150534, 11.2332887],
                [106.915351, 11.2334755],
                [106.915492, 11.2335799],
                [106.9157553, 11.2338079],
                [106.915877, 11.2339307],
                [106.916154, 11.2342561],
                [106.9162435, 11.234347],
                [106.9164383, 11.2345122],
                [106.9166519, 11.2346536],
                [106.9168829, 11.2347698],
                [106.9170044, 11.2348178],
                [106.917255, 11.2348918],
                [106.917513, 11.2349356],
                [106.9180771, 11.234937],
                [106.9181871, 11.2349661],
                [106.9182823, 11.2350274],
                [106.9183533, 11.2351149],
                [106.9183928, 11.2352197],
                [106.9183993, 11.2352727],
                [106.9183877, 11.2353785],
                [106.9183698, 11.2354289],
                [106.9183121, 11.235519],
                [106.9182735, 11.2355567],
                [106.9181814, 11.2356129],
                [106.9181299, 11.2356301],
                [106.9179046, 11.2356722],
                [106.9176164, 11.2357065],
                [106.9140707, 11.2359313],
                [106.9133837, 11.2359092],
                [106.9130407, 11.2358861],
                [106.9126984, 11.2358551],
                [106.9120076, 11.235771],
                [106.9119087, 11.2357828],
                [106.9118615, 11.2357986],
                [106.9117758, 11.2358485],
                [106.9117414, 11.2358791],
                [106.9116859, 11.2359521],
                [106.9116658, 11.2359932],
                [106.9116547, 11.2360452],
                [106.9116528, 11.2360984],
                [106.9116736, 11.2361929],
                [106.9116927, 11.2362325],
                [106.911747, 11.2363017],
                [106.9117811, 11.2363299],
                [106.9119171, 11.236413],
                [106.9120576, 11.2364885],
                [106.9122022, 11.2365562],
                [106.9126344, 11.2367318],
                [106.912846, 11.2368298],
                [106.9130991, 11.2369595],
                [106.9131386, 11.2369904],
                [106.9132005, 11.2370684],
                [106.9132349, 11.2371612],
                [106.9132386, 11.2372583],
                [106.9132121, 11.237352],
                [106.913116, 11.2374327],
                [106.9130069, 11.2374954],
                [106.9129317, 11.2375251],
                [106.9128536, 11.2375462],
                [106.9126422, 11.2375907],
                [106.9123873, 11.2376795],
                [106.9122643, 11.237736],
                [106.9120306, 11.2378716],
                [106.9114485, 11.2380989],
                [106.9113725, 11.2381358],
                [106.9113001, 11.2381792],
                [106.9111687, 11.2382842],
                [106.9111108, 11.238345],
                [106.9110127, 11.2384806],
                [106.9109382, 11.2386387],
                [106.9109126, 11.2387223],
                [106.910886, 11.2388945],
                [106.9108853, 11.2389817],
                [106.9109061, 11.2391381],
                [106.910917, 11.2392954],
                [106.9109182, 11.2394531],
                [106.9109094, 11.2396105],
                [106.9108917, 11.2397613],
                [106.9108649, 11.2399107],
                [106.9107847, 11.2402037],
                [106.9107357, 11.2402937],
                [106.9106612, 11.2404841],
                [106.9106363, 11.2405831],
                [106.9106123, 11.2407715],
                [106.9106113, 11.2408594],
                [106.9106285, 11.2410344],
                [106.9106466, 11.2411206],
                [106.910709, 11.241259],
                [106.9107968, 11.2413836],
                [106.9109132, 11.2414952],
                [106.9109793, 11.2415419],
                [106.911124, 11.2416151],
                [106.9112449, 11.2416944],
                [106.9114855, 11.2418731],
                [106.9116041, 11.2419729],
                [106.9116661, 11.2420124],
                [106.9117324, 11.2420447],
                [106.911874, 11.2420857],
                [106.9119474, 11.2420939],
                [106.9120891, 11.2420863],
                [106.9122256, 11.2420483],
                [106.9122898, 11.2420184],
                [106.9126993, 11.241737],
                [106.9130409, 11.2415384],
                [106.913235, 11.241441],
                [106.9134334, 11.2413521],
                [106.9136059, 11.241286],
                [106.9137686, 11.2411995],
                [106.9139259, 11.2410883],
                [106.9139986, 11.2410255],
                [106.9141307, 11.2408864],
                [106.9142053, 11.2407835],
                [106.9143043, 11.2407027],
                [106.9144211, 11.2406494],
                [106.9144837, 11.2406343],
                [106.9146103, 11.2406283],
                [106.9147324, 11.240653],
                [106.9148445, 11.2407067],
                [106.9149395, 11.240786],
                [106.9150115, 11.2408859],
                [106.9151008, 11.2411235],
                [106.915158, 11.2413073],
                [106.91527, 11.2417452],
                [106.9153161, 11.2418343],
                [106.9153856, 11.2419061],
                [106.9154723, 11.2419553],
                [106.915686, 11.2420362],
                [106.9158497, 11.2421073],
                [106.9161965, 11.2422787],
                [106.9163783, 11.2423808],
                [106.9167298, 11.2426043],
                [106.9169133, 11.2426938],
                [106.9170095, 11.2427288],
                [106.917207, 11.2427778],
                [106.9174674, 11.2428043],
                [106.9175849, 11.2427909],
                [106.917641, 11.2427723],
                [106.9177422, 11.242714],
                [106.9178229, 11.2426315],
                [106.9178778, 11.2425306],
                [106.9178867, 11.2422937],
                [106.9178824, 11.2421753],
                [106.9178526, 11.2419152],
                [106.9177882, 11.2416358],
                [106.9177437, 11.2414994],
                [106.9176837, 11.2413783],
                [106.9176648, 11.2413134],
                [106.9176507, 11.2411846],
                [106.9176651, 11.2410608],
                [106.9177061, 11.240943],
                [106.9177762, 11.2408075],
                [106.9178697, 11.2406865],
                [106.9179865, 11.2405816],
                [106.9181207, 11.2404993],
                [106.9182554, 11.2404457],
                [106.9183862, 11.2404252],
                [106.9184525, 11.2404267],
                [106.9185896, 11.2404558],
                [106.9187146, 11.2405181],
                [106.9187519, 11.2405463],
                [106.918815, 11.2406145],
                [106.9188617, 11.2406992],
                [106.9188773, 11.2407471],
                [106.918889, 11.2408468],
                [106.9188628, 11.2412692],
                [106.9188579, 11.2414861],
                [106.9188265, 11.2417185],
                [106.9187478, 11.2420264],
                [106.9187397, 11.2421575],
                [106.9187619, 11.2422887],
                [106.9188144, 11.2424132],
                [106.9188943, 11.2425227],
                [106.9189307, 11.2425585],
                [106.9190159, 11.2426148],
                [106.9190633, 11.2426345],
                [106.9191623, 11.2426553],
                [106.9192634, 11.2426512],
                [106.9193128, 11.2426398],
                [106.9194687, 11.2425617],
                [106.9195421, 11.2425148],
                [106.9196816, 11.2424036],
                [106.9198036, 11.2422741],
                [106.9198407, 11.2422094],
                [106.9198906, 11.2420804],
                [106.9199249, 11.2418851],
                [106.9199437, 11.2418173],
                [106.9200063, 11.2416845],
                [106.9200853, 11.2415812],
                [106.9201724, 11.2415191],
                [106.920274, 11.2414872],
                [106.9203807, 11.241488],
                [106.9205646, 11.2415253],
                [106.920698, 11.2415408],
                [106.9208322, 11.2415474],
                [106.9209679, 11.2415448],
                [106.921103, 11.241533],
                [106.9212369, 11.2415119],
                [106.9213691, 11.2414818],
                [106.9214988, 11.2414428],
                [106.9215973, 11.2414241],
                [106.9217968, 11.2414036],
                [106.9220118, 11.2414073],
                [106.922126, 11.24142],
                [106.9223504, 11.2414678],
                [106.9233428, 11.2417388],
                [106.9234706, 11.2418001],
                [106.9235811, 11.241888],
                [106.9236687, 11.2419979],
                [106.9237055, 11.2420659],
                [106.9237333, 11.2421379],
                [106.9237519, 11.2422127],
                [106.9237699, 11.2424838],
                [106.9237884, 11.2426004],
                [106.9238154, 11.2427155],
                [106.923894, 11.2429384],
                [106.9239419, 11.2430388],
                [106.9240578, 11.243229],
                [106.9241252, 11.243318],
                [106.9242775, 11.2434816],
                [106.924385, 11.2435528],
                [106.9245914, 11.2437066],
                [106.9248489, 11.2439354],
                [106.9249214, 11.2439871],
                [106.9250756, 11.2440813],
                [106.9252405, 11.2441617],
                [106.925376, 11.2442477],
                [106.9256445, 11.244437],
                [106.9257769, 11.2445405],
                [106.9262008, 11.2447658],
                [106.9264661, 11.2448921],
                [106.9265601, 11.2449279],
                [106.926657, 11.2449554],
                [106.9268462, 11.2449845],
                [106.9270278, 11.2449832],
                [106.927118, 11.244972],
                [106.9272667, 11.2449027],
                [106.9273996, 11.2448075],
                [106.9275188, 11.2446815],
                [106.9276103, 11.2445349],
                [106.9277644, 11.2441249],
                [106.9278815, 11.243897],
                [106.9279817, 11.2437404],
                [106.9280636, 11.2436591],
                [106.9281119, 11.243627],
                [106.9282203, 11.2435823],
                [106.9283381, 11.2435668],
                [106.9284558, 11.2435823],
                [106.9295803, 11.2439144],
                [106.9297013, 11.2439479],
                [106.9298245, 11.2439724],
                [106.9299492, 11.2439878],
                [106.9301787, 11.2439922],
                [106.9303853, 11.2439696],
                [106.9306524, 11.2439103],
                [106.9307183, 11.2439056],
                [106.9308496, 11.2439183],
                [106.9309736, 11.2439591],
                [106.9310308, 11.2439891],
                [106.9311333, 11.244067],
                [106.9312379, 11.2441814],
                [106.9313398, 11.2443366],
                [106.9314662, 11.2445958],
                [106.9315199, 11.2447295],
                [106.931608, 11.2450035],
                [106.93167, 11.2452864],
                [106.9317044, 11.2455738],
                [106.9317202, 11.2458417],
                [106.9317138, 11.2459033],
                [106.9316796, 11.2460223],
                [106.9316147, 11.2461358],
                [106.9315189, 11.2462348],
                [106.9312766, 11.2463882],
                [106.9311573, 11.2464762],
                [106.9309348, 11.2466714],
                [106.9307613, 11.2468595],
                [106.9306076, 11.2470634],
                [106.9305587, 11.2471455],
                [106.9305018, 11.2472225],
                [106.9304374, 11.2472936],
                [106.9303029, 11.2474056],
                [106.9301657, 11.2474847],
                [106.9294314, 11.2477566],
                [106.9293451, 11.2477823],
                [106.9292569, 11.2478011],
                [106.9290774, 11.2478171],
                [106.928913, 11.2478064],
                [106.9288319, 11.2477921],
                [106.9285693, 11.2477099],
                [106.9284419, 11.2476579],
                [106.9281974, 11.2475331],
                [106.9280809, 11.2474606],
                [106.927857, 11.2472929],
                [106.9277529, 11.2471998],
                [106.9276545, 11.2471009],
                [106.9274357, 11.2468374],
                [106.9273895, 11.2467928],
                [106.9272821, 11.246722],
                [106.9272226, 11.2466968],
                [106.9270965, 11.2466687],
                [106.9269671, 11.2466714],
                [106.9269001, 11.2466853],
                [106.9268355, 11.2467077],
                [106.9267178, 11.2467758],
                [106.9266666, 11.2468205],
                [106.9265637, 11.2469422],
                [106.9265116, 11.2470174],
                [106.9264264, 11.2471788],
                [106.9263938, 11.247264],
                [106.9263498, 11.2474402],
                [106.9263348, 11.2476211],
                [106.9263405, 11.2477004],
                [106.9263551, 11.2477787],
                [106.9264103, 11.247928],
                [106.9264501, 11.2479973],
                [106.9265398, 11.2481086],
                [106.9265926, 11.2481572],
                [106.9267116, 11.248238],
                [106.9268128, 11.2482893],
                [106.9269178, 11.2483324],
                [106.9270261, 11.2483671],
                [106.9272251, 11.2484075],
                [106.9274037, 11.2484195],
                [106.9275357, 11.2484177],
                [106.9276012, 11.2484262],
                [106.9277282, 11.2484616],
                [106.9277968, 11.2484923],
                [106.9278614, 11.2485306],
                [106.9279751, 11.2486276],
                [106.9280632, 11.2487474],
                [106.9281211, 11.2488838],
                [106.9281459, 11.2490297],
                [106.9281453, 11.2491038],
                [106.9281183, 11.2492493],
                [106.9280581, 11.2493849],
                [106.9277228, 11.2499742],
                [106.9277031, 11.2500224],
                [106.9276795, 11.2501236],
                [106.9276789, 11.2502371],
                [106.9276897, 11.2502979],
                [106.9277618, 11.2505192],
                [106.9277832, 11.2506884],
                [106.9277751, 11.2508341],
                [106.927762, 11.2509059],
                [106.9277158, 11.2510711],
                [106.9277085, 11.2511845],
                [106.9277698, 11.2515487],
                [106.927788, 11.2517051],
                [106.9278046, 11.2520196],
                [106.9277985, 11.2521138],
                [106.9277664, 11.2522029],
                [106.9277108, 11.25228],
                [106.9276257, 11.252345],
                [106.9275244, 11.2523815],
                [106.9274707, 11.2523878],
                [106.9266408, 11.2523892],
                [106.9265276, 11.2524009],
                [106.9264157, 11.2524215],
                [106.9263058, 11.2524508],
                [106.9261988, 11.2524888],
                [106.9259957, 11.2525894],
                [106.925901, 11.2526514],
                [106.9258118, 11.2527206],
                [106.9257272, 11.252798],
                [106.9256493, 11.252882],
                [106.9255787, 11.2529719],
                [106.9255158, 11.2530671],
                [106.9254611, 11.2531671],
                [106.925415, 11.2532712],
                [106.9253776, 11.2533786],
                [106.9253494, 11.2534887],
                [106.9252464, 11.2541426],
                [106.9252034, 11.2545756],
                [106.9251938, 11.254793],
                [106.9251981, 11.2552185],
                [106.9252316, 11.255633],
                [106.9252719, 11.2558943],
                [106.9252915, 11.2559473],
                [106.92535, 11.2560442],
                [106.9254322, 11.2561247],
                [106.925481, 11.2561571],
                [106.9255891, 11.2562023],
                [106.9257604, 11.2562375],
                [106.9259351, 11.2562457],
                [106.9260226, 11.2562396],
                [106.9261946, 11.2562071],
                [106.9262781, 11.2561809],
                [106.9263592, 11.2561483],
                [106.9265262, 11.2560598],
                [106.9265759, 11.2560474],
                [106.9266271, 11.256044],
                [106.9266781, 11.2560497],
                [106.9267279, 11.2560645],
                [106.9267741, 11.256088],
                [106.9268152, 11.2561194],
                [106.9268498, 11.2561575],
                [106.9268941, 11.2562307],
                [106.9269653, 11.2563857],
                [106.9270151, 11.2565633],
                [106.9270296, 11.2566616],
                [106.9270351, 11.2569983],
                [106.9270458, 11.2571363],
                [106.9270839, 11.2573787],
                [106.9271368, 11.257586],
                [106.9272494, 11.2579095],
                [106.9273138, 11.2581515],
                [106.9273209, 11.2582881],
                [106.9273116, 11.258356],
                [106.9272678, 11.2584859],
                [106.9271931, 11.2586014],
                [106.9271514, 11.2586458],
                [106.9270546, 11.2587202],
                [106.9269437, 11.2587724],
                [106.926823, 11.2587882],
                [106.9267022, 11.2587729],
                [106.9266444, 11.2587539],
                [106.9265365, 11.2586929],
                [106.9264472, 11.258604],
                [106.9263873, 11.2585128],
                [106.9263174, 11.2584523],
                [106.9262299, 11.2584207],
                [106.9261833, 11.2584172],
                [106.9260975, 11.2584334],
                [106.9260223, 11.2584771],
                [106.925971, 11.2585329],
                [106.9259243, 11.2585924],
                [106.9258453, 11.2587211],
                [106.9258143, 11.2587877],
                [106.9257679, 11.2589269],
                [106.9257528, 11.2589986],
                [106.9257505, 11.2590752],
                [106.9257664, 11.2592275],
                [106.9258091, 11.2593748],
                [106.9258791, 11.2595156],
                [106.9259736, 11.2596419],
                [106.926029, 11.2596983],
                [106.9269589, 11.2603321],
                [106.9270052, 11.2603787],
                [106.9270774, 11.2604876],
                [106.9271171, 11.2606057],
                [106.927126, 11.2607243],
                [106.9271065, 11.2607929],
                [106.9270782, 11.2608586],
                [106.9270416, 11.2609202],
                [106.926948, 11.2610256],
                [106.9268332, 11.2611038],
                [106.9267693, 11.261132],
                [106.9261316, 11.2614878],
                [106.9260604, 11.2615334],
                [106.9259155, 11.2616469],
                [106.9257769, 11.2617896],
                [106.9257239, 11.2618853],
                [106.9256339, 11.2620842],
                [106.925564, 11.2622989],
                [106.9255374, 11.2624127],
                [106.9255043, 11.2626438],
                [106.9254847, 11.2629959],
                [106.9254684, 11.2631128],
                [106.9254282, 11.2633022],
                [106.9253898, 11.2633626],
                [106.9252932, 11.2634687],
                [106.9251747, 11.2635506],
                [106.925109, 11.263581],
                [106.9249848, 11.2636165],
                [106.9248558, 11.2636265],
                [106.9241195, 11.2636191],
                [106.9240529, 11.2636283],
                [106.9239247, 11.2636674],
                [106.9238178, 11.2637254],
                [106.9237331, 11.2637941],
                [106.9236884, 11.2639309],
                [106.9236794, 11.2640743],
                [106.9237057, 11.2642129],
                [106.9237652, 11.2643413],
                [106.9238393, 11.2644432],
                [106.9240063, 11.2646322],
                [106.9241963, 11.264799],
                [106.9244063, 11.264941],
                [106.9246358, 11.2650571],
                [106.9248785, 11.2651435],
                [106.9251305, 11.2651988],
                [106.9252615, 11.2652117],
                [106.9255248, 11.2652107],
                [106.92573, 11.265185],
                [106.9258312, 11.265164],
                [106.925901, 11.2651411],
                [106.926032, 11.265076],
                [106.9261542, 11.2649811],
                [106.9262589, 11.2648563],
                [106.9263335, 11.2647122],
                [106.9263938, 11.2645254],
                [106.9264316, 11.2644695],
                [106.926477, 11.2644193],
                [106.9265291, 11.2643758],
                [106.9265869, 11.26434],
                [106.9267078, 11.2642951],
                [106.9268364, 11.264283],
                [106.9269008, 11.2642896],
                [106.927601, 11.2644739],
                [106.9276597, 11.2645012],
                [106.9277639, 11.264577],
                [106.9278076, 11.2646242],
                [106.9278744, 11.2647332],
                [106.9279096, 11.2648511],
                [106.9279137, 11.2649738],
                [106.9278865, 11.2650937],
                [106.9278617, 11.2651502],
                [106.9277913, 11.2652518],
                [106.9276974, 11.265333],
                [106.9261497, 11.2667956],
                [106.9255294, 11.2672757],
                [106.9254772, 11.2673608],
                [106.9254636, 11.2674088],
                [106.9254591, 11.2674584],
                [106.925472, 11.2675413],
                [106.9254879, 11.2675804],
                [106.9255368, 11.2676492],
                [106.9256397, 11.2676989],
                [106.9257604, 11.2677343],
                [106.9258972, 11.2677489],
                [106.9262418, 11.2677409],
                [106.9265177, 11.2677431],
                [106.9269518, 11.2677623],
                [106.9271513, 11.2677783],
                [106.9271915, 11.2677886],
                [106.9272649, 11.2678264],
                [106.927328, 11.2678904],
                [106.9273682, 11.2679786],
                [106.9273887, 11.2681119],
                [106.9274336, 11.2682969],
                [106.9275069, 11.2684922],
                [106.9275105, 11.2685768],
                [106.9274908, 11.2687448],
                [106.9274705, 11.2688184],
                [106.9274094, 11.2689586],
                [106.9273691, 11.2690238],
                [106.9272709, 11.269142],
                [106.9270532, 11.2693482],
                [106.9268093, 11.269531],
                [106.9266777, 11.2696127],
                [106.9265416, 11.2696866],
                [106.9264011, 11.2697525],
                [106.926257, 11.2698101],
                [106.9249722, 11.2704572],
                [106.9248226, 11.2705489],
                [106.9246888, 11.2706705],
                [106.9246296, 11.2707427],
                [106.9245779, 11.2708202],
                [106.9245492, 11.2709128],
                [106.9245484, 11.2710096],
                [106.9245732, 11.2710973],
                [106.9246209, 11.2711753],
                [106.9248086, 11.2713253],
                [106.9249039, 11.2713904],
                [106.9251037, 11.2715068],
                [106.9252049, 11.2715794],
                [106.9253987, 11.2717356],
                [106.9255016, 11.2718289],
                [106.9256951, 11.2720276],
                [106.925752, 11.2720757],
                [106.9258133, 11.2721184],
                [106.9259467, 11.2721858],
                [106.9260907, 11.2722275],
                [106.9261665, 11.2722384],
                [106.9263195, 11.2722386],
                [106.9264697, 11.2722101],
                [106.9267186, 11.2721112],
                [106.9269038, 11.2720614],
                [106.9270918, 11.2720372],
                [106.9272788, 11.2720379],
                [106.927464, 11.2720631],
                [106.9277237, 11.2721228],
                [106.9279736, 11.2722144],
                [106.9281893, 11.2723244],
                [106.9283905, 11.2724583],
                [106.9285744, 11.2726142],
                [106.9289521, 11.2729036],
                [106.9289974, 11.2729452],
                [106.92907, 11.2730436],
                [106.9291147, 11.273157],
                [106.9291287, 11.2732778],
                [106.9291237, 11.2733385],
                [106.9290823, 11.2735388],
                [106.9290768, 11.2736104],
                [106.9290774, 11.2736822],
                [106.9290953, 11.2738199],
                [106.929135, 11.2739531],
                [106.9291755, 11.2740284],
                [106.9292242, 11.2740988],
                [106.9292807, 11.2741634],
                [106.9293442, 11.2742214],
                [106.9294102, 11.2742697],
                [106.9295559, 11.2743443],
                [106.9297144, 11.2743871],
                [106.929996, 11.2744082],
                [106.9301191, 11.2744422],
                [106.9302294, 11.2745055],
                [106.9303203, 11.2745947],
                [106.9303851, 11.2747036],
                [106.9304064, 11.2747633],
                [106.930429, 11.2748678],
                [106.9304506, 11.2750802],
                [106.9304407, 11.2752939],
                [106.9304239, 11.2753996],
                [106.9303671, 11.2756061],
                [106.9303273, 11.2757057],
                [106.9301086, 11.2761706],
                [106.9299654, 11.2765504],
                [106.9299048, 11.276744],
                [106.9298125, 11.2771063],
                [106.929746, 11.2774741],
                [106.9297138, 11.2777427],
                [106.9297159, 11.27791],
                [106.9297439, 11.278075],
                [106.9297661, 11.2781517],
                [106.9298273, 11.2782994],
                [106.9298659, 11.2783696],
                [106.9299872, 11.2785328],
                [106.9301288, 11.2786794],
                [106.9302754, 11.2787978],
                [106.9304211, 11.2788904],
                [106.9304757, 11.2789083],
                [106.9305899, 11.2789202],
                [106.9307028, 11.2788996],
                [106.9307572, 11.2788769],
                [106.9308528, 11.278809],
                [106.9308919, 11.2787655],
                [106.9310322, 11.2784221],
                [106.9310773, 11.278242],
                [106.9310934, 11.2780621],
                [106.9310908, 11.2778883],
                [106.9311193, 11.2778071],
                [106.9311735, 11.2777396],
                [106.9312491, 11.2776928],
                [106.9313363, 11.2776736],
                [106.931425, 11.2776844],
                [106.9314746, 11.2777267],
                [106.9315544, 11.277829],
                [106.9315831, 11.277887],
                [106.9316154, 11.2780119],
                [106.9316185, 11.2780725],
                [106.9316013, 11.2781925],
                [106.9315145, 11.2784049],
                [106.9314889, 11.2784852],
                [106.9314547, 11.2786421],
                [106.9314404, 11.2787944],
                [106.9314458, 11.2788496],
                [106.9314802, 11.2789549],
                [106.9315086, 11.2790029],
                [106.9315847, 11.2790846],
                [106.9316843, 11.2791432],
                [106.9318003, 11.2791711],
                [106.9318601, 11.2791719],
                [106.9319372, 11.2791601],
                [106.9320864, 11.2791164],
                [106.9322252, 11.2790471],
                [106.9323428, 11.27896],
                [106.9324384, 11.2788611],
                [106.9325114, 11.2787633],
                [106.9325914, 11.2786925],
                [106.9326883, 11.2786462],
                [106.9327408, 11.2786334],
                [106.9328485, 11.27863],
                [106.9329078, 11.278641],
                [106.9329645, 11.2786608],
                [106.9330174, 11.2786892],
                [106.9330651, 11.2787252],
                [106.9331065, 11.2787681],
                [106.9331406, 11.2788169],
                [106.9332328, 11.2789969],
                [106.9333447, 11.2792595],
                [106.9334284, 11.2795321],
                [106.9334593, 11.2796712],
                [106.9334957, 11.2799184],
                [106.9335043, 11.2800254],
                [106.9334903, 11.2800975],
                [106.9334418, 11.2802362],
                [106.9333632, 11.2803699],
                [106.9332536, 11.280491],
                [106.9328646, 11.2808487],
                [106.9322115, 11.2814321],
                [106.9321511, 11.2815175],
                [106.9321318, 11.281566],
                [106.932117, 11.2816645],
                [106.9321311, 11.2817589],
                [106.9321663, 11.2818046],
                [106.9322531, 11.2818812],
                [106.9323446, 11.2819291],
                [106.9324449, 11.2819555],
                [106.9330652, 11.2820477],
                [106.9331712, 11.2820882],
                [106.9332187, 11.2821192],
                [106.9332978, 11.2821995],
                [106.933347, 11.282289],
                [106.933371, 11.2823879],
                [106.9333772, 11.2830619],
                [106.9334087, 11.2832312],
                [106.9334638, 11.2833859],
                [106.9335377, 11.2835237],
                [106.9339442, 11.2840867],
                [106.934289, 11.2846282],
                [106.9343797, 11.2848251],
                [106.9344331, 11.2850182],
                [106.9344553, 11.2852008],
                [106.9344565, 11.2852928],
                [106.9344416, 11.2853936],
                [106.9343918, 11.2855913],
                [106.934311, 11.2857922],
                [106.9342571, 11.2858936],
                [106.9341274, 11.2860835],
                [106.933971, 11.2862528],
                [106.933264, 11.287188],
                [106.9332242, 11.2872764],
                [106.933216, 11.2873727],
                [106.9332242, 11.2874209],
                [106.9332643, 11.2875099],
                [106.9332951, 11.2875483],
                [106.933355, 11.28759],
                [106.9334197, 11.2876239],
                [106.9334882, 11.2876495],
                [106.9336251, 11.287674],
                [106.9337639, 11.2876658],
                [106.9340223, 11.2875964],
                [106.9344096, 11.2875128],
                [106.9348199, 11.2874579],
                [106.9350265, 11.2874431],
                [106.935205, 11.2874058],
                [106.9353765, 11.2873444],
                [106.9354657, 11.2873013],
                [106.9355512, 11.2872517],
                [106.9357398, 11.2871137],
                [106.9358632, 11.2870578],
                [106.9359291, 11.2870406],
                [106.936065, 11.2870291],
                [106.936133, 11.287035],
                [106.9362, 11.2870485],
                [106.936287, 11.2871006],
                [106.9363232, 11.287136],
                [106.9363768, 11.287221],
                [106.9363929, 11.2872686],
                [106.9364017, 11.2873648],
                [106.9363953, 11.2874111],
                [106.9363625, 11.2874985],
                [106.9362, 11.2877298],
                [106.9361699, 11.2878032],
                [106.9361652, 11.2878426],
                [106.9361771, 11.2879209],
                [106.9362175, 11.2879925],
                [106.9362481, 11.2880226],
                [106.9362839, 11.2880466],
                [106.9363659, 11.2880729],
                [106.9364521, 11.2880678],
                [106.9371284, 11.2880733],
                [106.9372088, 11.2880872],
                [106.9372871, 11.2881099],
                [106.9374334, 11.2881803],
                [106.9374996, 11.2882272],
                [106.9376139, 11.2883413],
                [106.9376604, 11.2884071],
                [106.9377238, 11.2885359],
                [106.9377596, 11.2886745],
                [106.9377992, 11.2892017],
                [106.9378402, 11.28931],
                [106.9379091, 11.2894035],
                [106.9380011, 11.2894756],
                [106.9380498, 11.2895003],
                [106.9381546, 11.2895308],
                [106.9382639, 11.2895348],
                [106.9386435, 11.2895184],
                [106.9387139, 11.2895242],
                [106.9387829, 11.2895392],
                [106.9388492, 11.2895632],
                [106.9389116, 11.2895957],
                [106.938969, 11.2896362],
                [106.9390203, 11.289684],
                [106.9390963, 11.2897887],
                [106.9391409, 11.2898992],
                [106.9391584, 11.2900168],
                [106.9391706, 11.2903099],
                [106.9391522, 11.2906027],
                [106.9390991, 11.2909112],
                [106.9390571, 11.2910723],
                [106.9390056, 11.2912307],
                [106.9389449, 11.2913781],
                [106.9389254, 11.2914553],
                [106.938909, 11.2916134],
                [106.9389253, 11.291784],
                [106.9389662, 11.2919381],
                [106.9389931, 11.2920066],
                [106.9390675, 11.2921339],
                [106.9391142, 11.2921913],
                [106.9392242, 11.2922907],
                [106.9392868, 11.292332],
                [106.9394234, 11.2923947],
                [106.9394959, 11.2924155],
                [106.9400074, 11.2925143],
                [106.9405096, 11.2926292],
                [106.9408566, 11.2927232],
                [106.9410418, 11.2928077],
                [106.9411281, 11.2928615],
                [106.9412847, 11.2929902],
                [106.9413538, 11.293064],
                [106.9414186, 11.293147],
                [106.9414754, 11.2932354],
                [106.9415239, 11.2933285],
                [106.9415634, 11.2934255],
                [106.9416659, 11.2937493],
                [106.9416905, 11.2938642],
                [106.9417162, 11.2940974],
                [106.9417109, 11.294326],
                [106.941677, 11.2945462],
                [106.9416541, 11.2946018],
                [106.941589, 11.2947033],
                [106.9415477, 11.2947476],
                [106.941455, 11.2948178],
                [106.9413535, 11.2948641],
                [106.9411728, 11.2949218],
                [106.9409274, 11.2950275],
                [106.9406816, 11.2951672],
                [106.9404015, 11.2953502],
                [106.9400626, 11.2955377],
                [106.9397292, 11.2956917],
                [106.9394049, 11.2958153],
                [106.9392513, 11.2958528],
                [106.9389398, 11.2959086],
                [106.9386488, 11.295937],
                [106.9383746, 11.2959526],
                [106.938121, 11.2959916],
                [106.9378861, 11.2960521],
                [106.9376712, 11.2961296],
                [106.9376068, 11.2961637],
                [106.9375475, 11.2962057],
                [106.9374943, 11.2962549],
                [106.9374481, 11.2963105],
                [106.9374096, 11.2963716],
                [106.9373653, 11.29648],
                [106.9373453, 11.2965951],
                [106.9373536, 11.29694],
                [106.9373746, 11.2970276],
                [106.9374162, 11.2971046],
                [106.937474, 11.2971672],
                [106.9375433, 11.2972027],
                [106.9376913, 11.2972514],
                [106.937851, 11.2972687],
                [106.9380265, 11.2972499],
                [106.938212, 11.2971992],
                [106.9383873, 11.2971212],
                [106.9385448, 11.2970204],
                [106.9386173, 11.2969616],
                [106.9390827, 11.2964965],
                [106.939187, 11.2964319],
                [106.9392444, 11.2964096],
                [106.9393656, 11.2963867],
                [106.9394887, 11.2963937],
                [106.9396077, 11.2964308],
                [106.9397146, 11.2964967],
                [106.9398012, 11.2965868],
                [106.939835, 11.2966392],
                [106.9398716, 11.2967771],
                [106.9399009, 11.2969166],
                [106.9399359, 11.2971801],
                [106.939945, 11.2974266],
                [106.9399294, 11.2974961],
                [106.9398795, 11.2976297],
                [106.9398456, 11.2976927],
                [106.9397621, 11.2978079],
                [106.9396597, 11.2979062],
                [106.9394748, 11.2980454],
                [106.9394179, 11.2981015],
                [106.9393197, 11.2982271],
                [106.939241, 11.298376],
                [106.9392109, 11.2984597],
                [106.9391759, 11.2986336],
                [106.9391632, 11.298807],
                [106.9391601, 11.2989809],
                [106.9391665, 11.2991547],
                [106.9391826, 11.299328],
                [106.9392047, 11.2994804],
                [106.9392712, 11.2997812],
                [106.9393153, 11.299929],
                [106.9396868, 11.3009317],
                [106.9397632, 11.3011069],
                [106.9398119, 11.3012913],
                [106.9398317, 11.3014808],
                [106.9398304, 11.3015809],
                [106.9398035, 11.3017793],
                [106.9397703, 11.3018457],
                [106.939745, 11.3018734],
                [106.9396815, 11.3019132],
                [106.9396041, 11.3019282],
                [106.9395232, 11.3019126],
                [106.9394867, 11.3018934],
                [106.9389059, 11.3014223],
                [106.9388206, 11.3013881],
                [106.9387285, 11.3013828],
                [106.9386388, 11.3014075],
                [106.9385979, 11.3014306],
                [106.9385308, 11.3014952],
                [106.9385042, 11.3015619],
                [106.9384679, 11.3017004],
                [106.9384547, 11.3018505],
                [106.9384583, 11.3019296],
                [106.9384805, 11.3020803],
                [106.9385258, 11.3022191],
                [106.9385996, 11.3023457],
                [106.9386967, 11.302453],
                [106.9388122, 11.3025374],
                [106.9389425, 11.3025973],
                [106.9393811, 11.302713],
                [106.9397056, 11.3028182],
                [106.9397489, 11.302843],
                [106.9397886, 11.3028731],
                [106.9398547, 11.3029468],
                [106.939902, 11.3030416],
                [106.9399214, 11.3031532],
                [106.9399068, 11.3032654],
                [106.9398256, 11.3034749],
                [106.9397137, 11.3036704],
                [106.939583, 11.3038368],
                [106.9394301, 11.3039838],
                [106.9393462, 11.3040492],
                [106.938873, 11.3043171],
                [106.9384382, 11.3045299],
                [106.9379176, 11.3047572],
                [106.9377915, 11.3048263],
                [106.9376703, 11.3049034],
                [106.9374695, 11.3050581],
                [106.9373107, 11.3052102],
                [106.9372373, 11.3052919],
                [106.9372086, 11.3053442],
                [106.9371708, 11.3054567],
                [106.9371607, 11.305577],
                [106.9371667, 11.3056383],
                [106.9372012, 11.3057564],
                [106.9375277, 11.3063716],
                [106.9380351, 11.3074134],
                [106.9380967, 11.3075999],
                [106.938122, 11.3077737],
                [106.9381204, 11.3079286],
                [106.9381043, 11.3080299],
                [106.9380641, 11.3080903],
                [106.9379649, 11.3081969],
                [106.9378448, 11.3082802],
                [106.9377073, 11.3083371],
                [106.9375583, 11.3083636],
                [106.9374445, 11.3083633],
                [106.937361, 11.3083433],
                [106.9372789, 11.3082947],
                [106.9371856, 11.3081967],
                [106.9371107, 11.3081645],
                [106.937029, 11.3081643],
                [106.936954, 11.3081961],
                [106.936898, 11.3082545],
                [106.9368803, 11.3082909],
                [106.9368705, 11.30833],
                [106.9368775, 11.3084583],
                [106.9368719, 11.3085063],
                [106.9368395, 11.3085974],
                [106.9367816, 11.3086753],
                [106.936692, 11.3087591],
                [106.9366019, 11.3088753],
                [106.9365647, 11.3089386],
                [106.9365362, 11.3090423],
                [106.9365408, 11.3091497],
                [106.9365556, 11.3092016],
                [106.9366084, 11.3092957],
                [106.9366946, 11.3093785],
                [106.9368078, 11.3094443],
                [106.9368698, 11.3094664],
                [106.9369997, 11.3094869],
                [106.9371307, 11.3094752],
                [106.9371903, 11.3094757],
                [106.9372491, 11.3094853],
                [106.9373057, 11.3095036],
                [106.9373587, 11.3095302],
                [106.9374578, 11.309591],
                [106.9375119, 11.3096102],
                [106.9376255, 11.3096257],
                [106.9376829, 11.3096218],
                [106.9377932, 11.3095909],
                [106.9378565, 11.3095446],
                [106.9379227, 11.3094589],
                [106.9379798, 11.3094352],
                [106.9380414, 11.3094414],
                [106.9380924, 11.309476],
                [106.9381518, 11.3095563],
                [106.9382487, 11.3096552],
                [106.9383028, 11.3096987],
                [106.9384118, 11.3097586],
                [106.9385317, 11.309793],
                [106.9385938, 11.3098],
                [106.9387186, 11.3097934],
                [106.9388464, 11.3098059],
                [106.9389656, 11.3098528],
                [106.9390669, 11.3099303],
                [106.9391081, 11.3099788],
                [106.939142, 11.3100325],
                [106.9391789, 11.3101201],
                [106.9391866, 11.3101906],
                [106.9391673, 11.3102671],
                [106.9391473, 11.3103014],
                [106.93909, 11.3103566],
                [106.9390546, 11.3103755],
                [106.9389348, 11.310409],
                [106.938768, 11.3104328],
                [106.9386837, 11.3104352],
                [106.9386328, 11.3104469],
                [106.9385844, 11.3104661],
                [106.9384994, 11.3105251],
                [106.9384366, 11.3106066],
                [106.9384021, 11.310703],
                [106.9383989, 11.3108051],
                [106.9384275, 11.3109033],
                [106.9384992, 11.3110451],
                [106.9385093, 11.3110795],
                [106.9385102, 11.3111711],
                [106.938497, 11.3112152],
                [106.938446, 11.3112919],
                [106.9382941, 11.3114223],
                [106.9380748, 11.3116371],
                [106.9379795, 11.3117654],
                [106.9379032, 11.3119054],
                [106.9378654, 11.3119579],
                [106.9377403, 11.3120788],
                [106.937702, 11.3121583],
                [106.9376935, 11.3122016],
                [106.9376989, 11.3122895],
                [106.9377127, 11.3123315],
                [106.9377329, 11.3123628],
                [106.9377919, 11.3124082],
                [106.9378653, 11.3124233],
                [106.938098, 11.3123649],
                [106.9382628, 11.3123544],
                [106.9384268, 11.3123738],
                [106.938771, 11.3124627],
                [106.9388607, 11.3124787],
                [106.9390239, 11.312486],
                [106.9391858, 11.3124654],
                [106.9392648, 11.3124449],
                [106.9393796, 11.3124025],
                [106.9394669, 11.3123508],
                [106.9395129, 11.3123113],
                [106.939587, 11.3122161],
                [106.9396552, 11.3120595],
                [106.9396962, 11.3119951],
                [106.9397402, 11.3119569],
                [106.9397702, 11.3119433],
                [106.9398357, 11.3119357],
                [106.9398986, 11.3119549],
                [106.9399255, 11.3119738],
                [106.9399587, 11.3120196],
                [106.9399695, 11.3120747],
                [106.9399623, 11.3121684],
                [106.939977, 11.3122992],
                [106.94002, 11.3124239],
                [106.9400688, 11.3125103],
                [106.9401267, 11.3125731],
                [106.94017, 11.3126029],
                [106.9402689, 11.3126389],
                [106.9403743, 11.3126404],
                [106.9404256, 11.312628],
                [106.9404741, 11.3126072],
                [106.9405971, 11.3125249],
                [106.9407726, 11.3124289],
                [106.9409683, 11.3123544],
                [106.9410697, 11.3123276],
                [106.9411728, 11.3123078],
                [106.9412382, 11.3123052],
                [106.9413679, 11.3123224],
                [106.9414303, 11.312342],
                [106.9414595, 11.3123647],
                [106.9414817, 11.312394],
                [106.9414955, 11.312428],
                [106.9414999, 11.3124643],
                [106.9414946, 11.3125005],
                [106.94148, 11.3125341],
                [106.9414571, 11.3125629],
                [106.941378, 11.3126314],
                [106.9413321, 11.3126571],
                [106.9411739, 11.3127104],
                [106.9410679, 11.3127726],
                [106.9409802, 11.3128577],
                [106.9409448, 11.3129075],
                [106.9409198, 11.3129588],
                [106.9409034, 11.3130134],
                [106.940896, 11.3130699],
                [106.9408978, 11.3131268],
                [106.9409088, 11.3131827],
                [106.9409287, 11.3132362],
                [106.9409554, 11.3132828],
                [106.9410294, 11.3133608],
                [106.9410749, 11.3133903],
                [106.9412121, 11.313458],
                [106.9412957, 11.3135103],
                [106.9415266, 11.3136875],
                [106.9416095, 11.3137335],
                [106.9416968, 11.3137707],
                [106.9417876, 11.3137988],
                [106.9418809, 11.3138175],
                [106.9419263, 11.313838],
                [106.9420043, 11.3138988],
                [106.942035, 11.3139376],
                [106.9420758, 11.3140267],
                [106.9420849, 11.3140749],
                [106.9420861, 11.3141239],
                [106.9420661, 11.3142154],
                [106.9420462, 11.3142559],
                [106.941989, 11.3143255],
                [106.9417752, 11.3144669],
                [106.9417521, 11.3144908],
                [106.9417248, 11.3145526],
                [106.941729, 11.3146199],
                [106.941743, 11.314651],
                [106.9417639, 11.314678],
                [106.9418219, 11.3147143],
                [106.9418984, 11.3147453],
                [106.9420579, 11.314788],
                [106.9421969, 11.3148463],
                [106.9423184, 11.3149344],
                [106.942416, 11.3150476],
                [106.9425287, 11.3152667],
                [106.9426431, 11.3154433],
                [106.9427155, 11.3155303],
                [106.9427951, 11.3156109],
                [106.9428813, 11.3156848],
                [106.94294, 11.3157492],
                [106.9429912, 11.3158193],
                [106.9430346, 11.3158944],
                [106.9430695, 11.3159736],
                [106.9430955, 11.316056],
                [106.9431125, 11.3161407],
                [106.9431221, 11.3163225],
                [106.943106, 11.3165137],
                [106.9430879, 11.316608],
                [106.9430907, 11.316661],
                [106.9431025, 11.3167129],
                [106.9431231, 11.316762],
                [106.943188, 11.3168467],
                [106.9432303, 11.3168798],
                [106.9432778, 11.3169055],
                [106.9433824, 11.3169317],
                [106.9434969, 11.3169231],
                [106.9436605, 11.3168676],
                [106.9437115, 11.3168604],
                [106.9438142, 11.3168667],
                [106.9439113, 11.3168999],
                [106.9439485, 11.3169259],
                [106.9439797, 11.3169588],
                [106.9440035, 11.3169971],
                [106.9440189, 11.3170393],
                [106.9440255, 11.3170836],
                [106.9440228, 11.3171284],
                [106.9439905, 11.3172118],
                [106.9438764, 11.3173733],
                [106.9438413, 11.317476],
                [106.9438349, 11.3175299],
                [106.9438361, 11.3175842],
                [106.9438611, 11.3176897],
                [106.9439176, 11.3177902],
                [106.9439574, 11.3178366],
                [106.9440034, 11.3178773],
                [106.9441529, 11.3179707],
                [106.9441898, 11.3180092],
                [106.9442412, 11.3181018],
                [106.9442978, 11.3181626],
                [106.9443741, 11.318197],
                [106.9444158, 11.3182024],
                [106.9444579, 11.3181997],
                [106.9445363, 11.3181702],
                [106.9446356, 11.3181007],
                [106.9446785, 11.3180581],
                [106.9447482, 11.3179598],
                [106.9447634, 11.3178795],
                [106.9447577, 11.3178387],
                [106.9447003, 11.3177198],
                [106.944686, 11.3176719],
                [106.944679, 11.3175726],
                [106.9446865, 11.3175232],
                [106.9447016, 11.3174753],
                [106.9447535, 11.3173891],
                [106.9448089, 11.3173532],
                [106.9448745, 11.317343],
                [106.945177, 11.3174332],
                [106.9452993, 11.3174585],
                [106.945696, 11.3175057],
                [106.9459887, 11.3175676],
                [106.9462636, 11.3176563],
                [106.946635, 11.3178138],
                [106.9467542, 11.3178528],
                [106.9469985, 11.3179099],
                [106.9470833, 11.3179367],
                [106.9472438, 11.3180123],
                [106.9473182, 11.3180604],
                [106.9474523, 11.3181754],
                [106.9475636, 11.3183133],
                [106.9476612, 11.3184969],
                [106.9476771, 11.3185542],
                [106.9476781, 11.3185954],
                [106.9476553, 11.3186744],
                [106.9476031, 11.3187385],
                [106.9475296, 11.3187778],
                [106.9474384, 11.3187883],
                [106.947405, 11.3187984],
                [106.9473503, 11.3188402],
                [106.9473214, 11.318902],
                [106.9473251, 11.31897],
                [106.9473702, 11.3190804],
                [106.9474159, 11.3192439],
                [106.9474503, 11.3194665],
                [106.9474528, 11.3195606],
                [106.9474652, 11.3196062],
                [106.9475101, 11.3196878],
                [106.9475782, 11.3197522],
                [106.9476191, 11.3197759],
                [106.9477636, 11.3198215],
                [106.9478155, 11.3198453],
                [106.9479316, 11.3199271],
                [106.9479713, 11.3199803],
                [106.9479917, 11.3200533],
                [106.9479825, 11.3201283],
                [106.947945, 11.3201944],
                [106.9478847, 11.3202416],
                [106.9477648, 11.3202987],
                [106.9476987, 11.3203514],
                [106.9476645, 11.320392],
                [106.9476169, 11.3204861],
                [106.9476006, 11.3205899],
                [106.9476059, 11.3207346],
                [106.9475993, 11.3208266],
                [106.9476259, 11.3209122],
                [106.9476505, 11.3209502],
                [106.9477181, 11.3210104],
                [106.9477591, 11.3210306],
                [106.9478487, 11.3210481],
                [106.9479546, 11.3210446],
                [106.9480447, 11.3210514],
                [106.9481141, 11.3210745],
                [106.9481498, 11.3210986],
                [106.9482028, 11.3211654],
                [106.9482592, 11.3213578],
                [106.9483139, 11.3214248],
                [106.9483902, 11.3214668],
                [106.9484331, 11.3214764],
                [106.9485204, 11.321471],
                [106.9485617, 11.3214562],
                [106.9486725, 11.3213788],
                [106.9487165, 11.3213587],
                [106.9488114, 11.3213394],
                [106.94886, 11.3213406],
                [106.9489538, 11.3213646],
                [106.949089, 11.3214263],
                [106.9491848, 11.3214569],
                [106.9492831, 11.3214783],
                [106.9493831, 11.3214901],
                [106.9494838, 11.3214924],
                [106.9495842, 11.3214851],
                [106.9496834, 11.3214683],
                [106.9497471, 11.321468],
                [106.9498103, 11.3214758],
                [106.9499309, 11.3215148],
                [106.9500374, 11.3215828],
                [106.9500831, 11.3216263],
                [106.9501403, 11.3217012],
                [106.9501748, 11.321777],
                [106.9501862, 11.3218277],
                [106.950185, 11.3219312],
                [106.9502046, 11.3219996],
                [106.9502509, 11.3220542],
                [106.9503159, 11.3220857],
                [106.9503883, 11.3220884],
                [106.9504556, 11.322062],
                [106.9505061, 11.322011],
                [106.9505498, 11.3219044],
                [106.9505766, 11.3218692],
                [106.9506103, 11.3218401],
                [106.9507331, 11.3217838],
                [106.9507699, 11.321756],
                [106.950826, 11.3216839],
                [106.9508595, 11.3216492],
                [106.9508991, 11.3216214],
                [106.9509436, 11.3216018],
                [106.950991, 11.321591],
                [106.9510397, 11.3215894],
                [106.9510878, 11.3215971],
                [106.9511334, 11.3216139],
                [106.9511749, 11.321639],
                [106.9512106, 11.3216715],
                [106.9512391, 11.3217102],
                [106.951498, 11.3221139],
                [106.9516951, 11.3223783],
                [106.9518031, 11.3225369],
                [106.9518319, 11.3225939],
                [106.9518629, 11.3227044],
                [106.9518678, 11.3227615],
                [106.9518656, 11.3228189],
                [106.9518337, 11.3229838],
                [106.9518364, 11.3230373],
                [106.9518681, 11.3231396],
                [106.9518961, 11.3231855],
                [106.9519731, 11.3232611],
                [106.95202, 11.3232887],
                [106.952179, 11.3233388],
                [106.9522789, 11.3233966],
                [106.952343, 11.3234546],
                [106.9523766, 11.3235114],
                [106.952386, 11.3235851],
                [106.9523786, 11.3236218],
                [106.9523411, 11.3236864],
                [106.9523129, 11.3237115],
                [106.9522239, 11.3237544],
                [106.9521653, 11.3237714],
                [106.9520438, 11.3237827],
                [106.9519689, 11.3238136],
                [106.9519134, 11.3238719],
                [106.9518962, 11.3239082],
                [106.9518865, 11.3239873],
                [106.9518945, 11.3240266],
                [106.9519343, 11.3240961],
                [106.9519996, 11.3241435],
                [106.9520731, 11.3241745],
                [106.9521306, 11.3242271],
                [106.9521669, 11.3242955],
                [106.9521875, 11.3244073],
                [106.9522269, 11.3244693],
                [106.9522551, 11.3244934],
                [106.9523233, 11.324523],
                [106.9524492, 11.324528],
                [106.9525462, 11.3245612],
                [106.9526255, 11.3246252],
                [106.9526553, 11.3246664],
                [106.9526733, 11.3247077],
                [106.9526825, 11.3247518],
                [106.9526734, 11.3248408],
                [106.9526554, 11.3248822],
                [106.9526294, 11.3249192],
                [106.9525589, 11.3249945],
                [106.9525024, 11.3250947],
                [106.9524731, 11.3252056],
                [106.9524693, 11.3252628],
                [106.952495, 11.325462],
                [106.9525057, 11.3257505],
                [106.9525194, 11.3258662],
                [106.9525697, 11.3260937],
                [106.9526769, 11.3264039],
                [106.9527067, 11.3265913],
                [106.9527102, 11.3267337],
                [106.9526885, 11.3268291],
                [106.9526656, 11.3268743],
                [106.9525989, 11.3269507],
                [106.9525105, 11.3270018],
                [106.9524009, 11.3270381],
                [106.9523442, 11.3270491],
                [106.9523048, 11.3270666],
                [106.9522401, 11.3271219],
                [106.9522017, 11.3271973],
                [106.9521953, 11.3272813],
                [106.9522292, 11.3274056],
                [106.9522608, 11.3274862],
                [106.9523204, 11.3276018],
                [106.9523867, 11.3276927],
                [106.952488, 11.3277872],
                [106.952661, 11.3278959],
                [106.9527109, 11.3279378],
                [106.9527961, 11.3280356],
                [106.9528592, 11.3281485],
                [106.9529323, 11.3283605],
                [106.9529996, 11.328482],
                [106.9530415, 11.3285377],
                [106.953077, 11.3285723],
                [106.9531596, 11.3286271],
                [106.9532022, 11.3286825],
                [106.9532128, 11.3287158],
                [106.9532097, 11.3287852],
                [106.9531756, 11.328846],
                [106.95311, 11.3289108],
                [106.9530771, 11.3289573],
                [106.9530509, 11.3290076],
                [106.9530023, 11.329163],
                [106.9529777, 11.3292066],
                [106.9529471, 11.3292464],
                [106.9528333, 11.3293475],
                [106.9527808, 11.3294357],
                [106.95276, 11.3295357],
                [106.9527719, 11.3296724],
                [106.9527474, 11.3297386],
                [106.9527255, 11.3297667],
                [106.9526667, 11.329807],
                [106.9525527, 11.3298332],
                [106.9525122, 11.3298533],
                [106.9524471, 11.3299146],
                [106.9524107, 11.3299956],
                [106.9524052, 11.3300397],
                [106.9524195, 11.33014],
                [106.9524627, 11.3302454],
                [106.9525319, 11.3303366],
                [106.9526446, 11.3304418],
                [106.9526905, 11.3305239],
                [106.9527017, 11.330617],
                [106.9526936, 11.3306634],
                [106.9526514, 11.3307474],
                [106.9526189, 11.3307821],
                [106.9525802, 11.33081],
                [106.9525056, 11.3308357],
                [106.9524265, 11.3308332],
                [106.9523886, 11.3308213],
                [106.9522857, 11.3307595],
                [106.9522041, 11.3307456],
                [106.9521235, 11.3307646],
                [106.9520572, 11.3308135],
                [106.952033, 11.3308468],
                [106.9520238, 11.3308727],
                [106.9520239, 11.3309272],
                [106.9520483, 11.3309762],
                [106.9521263, 11.3310392],
                [106.9521538, 11.3310748],
                [106.9521732, 11.3311151],
                [106.9521839, 11.3311585],
                [106.9521853, 11.3312031],
                [106.9521773, 11.3312471],
                [106.9521604, 11.3312885],
                [106.9521352, 11.3313256],
                [106.9521028, 11.331357],
                [106.9520376, 11.3313904],
                [106.9520014, 11.3313978],
                [106.951928, 11.3313928],
                [106.9518614, 11.3313622],
                [106.9518378, 11.3313411],
                [106.9518056, 11.3312873],
                [106.951788, 11.3311876],
                [106.9517711, 11.3311525],
                [106.9517469, 11.3311217],
                [106.9516816, 11.3310783],
                [106.9516039, 11.3310653],
                [106.9515277, 11.331085],
                [106.9514666, 11.3311338],
                [106.9514316, 11.3312031],
                [106.9514289, 11.3312803],
                [106.9514777, 11.3313978],
                [106.951495, 11.3314953],
                [106.9514849, 11.3315937],
                [106.9514416, 11.3317511],
                [106.9514227, 11.331863],
                [106.9514132, 11.3319761],
                [106.9514132, 11.3320896],
                [106.9514227, 11.3322027],
                [106.9514416, 11.3323147],
                [106.9514698, 11.3324247],
                [106.9515317, 11.3325489],
                [106.9516209, 11.332656],
                [106.9517326, 11.3327403],
                [106.9517929, 11.3328167],
                [106.9518114, 11.3328617],
                [106.9518221, 11.3329577],
                [106.9518139, 11.3330055],
                [106.951772, 11.3330929],
                [106.9517008, 11.3331598],
                [106.9516572, 11.3331826],
                [106.9516101, 11.3331972],
                [106.951481, 11.3332056],
                [106.9513228, 11.3331839],
                [106.9512466, 11.3331599],
                [106.9510327, 11.3330545],
                [106.9509574, 11.3330291],
                [106.9508007, 11.3330015],
                [106.9506416, 11.3330059],
                [106.9504867, 11.3330421],
                [106.950413, 11.3330717],
                [106.9503458, 11.3331313],
                [106.9503052, 11.3332107],
                [106.9502965, 11.3332991],
                [106.9503047, 11.333343],
                [106.9503449, 11.3334226],
                [106.9504144, 11.3335079],
                [106.9504465, 11.3335645],
                [106.9504881, 11.3336871],
                [106.9504929, 11.3337952],
                [106.9504842, 11.3338488],
                [106.9504262, 11.3340079],
                [106.9504109, 11.3341282],
                [106.9504262, 11.3342485],
                [106.9504713, 11.3343615],
                [106.9505537, 11.3344777],
                [106.9507155, 11.3346447],
                [106.9507831, 11.3347653],
                [106.9508507, 11.3348377],
                [106.9508932, 11.334864],
                [106.950989, 11.3348924],
                [106.951146, 11.3348847],
                [106.9512585, 11.3349035],
                [106.9513621, 11.3349502],
                [106.9514499, 11.3350216],
                [106.951519, 11.335117],
                [106.9515638, 11.3352309],
                [106.9515749, 11.3352911],
                [106.9515732, 11.3354132],
                [106.9515403, 11.335531],
                [106.9514657, 11.3356653],
                [106.951353, 11.3358116],
                [106.9512176, 11.3359383],
                [106.9511849, 11.3359802],
                [106.9511392, 11.3360754],
                [106.9511272, 11.3361269],
                [106.9511227, 11.3361794],
                [106.9511367, 11.3362838],
                [106.9511801, 11.3363801],
                [106.9512016, 11.3364752],
                [106.9511885, 11.3365717],
                [106.9511578, 11.3366662],
                [106.9511609, 11.3367672],
                [106.9511984, 11.3368613],
                [106.9512921, 11.3369743],
                [106.9513227, 11.3370581],
                [106.9513262, 11.3371027],
                [106.9513091, 11.3371901],
                [106.951289, 11.3372302],
                [106.951262, 11.3372663],
                [106.9511596, 11.3373507],
                [106.951134, 11.3373846],
                [106.9511024, 11.3374627],
                [106.9510998, 11.3375467],
                [106.951137, 11.3376713],
                [106.9511326, 11.3377623],
                [106.9511178, 11.3378057],
                [106.9510656, 11.3378812],
                [106.9509404, 11.3379914],
                [106.950663, 11.3382618],
                [106.9505718, 11.3383775],
                [106.9505066, 11.3385149],
                [106.9504858, 11.3385907],
                [106.9504609, 11.338804],
                [106.9504156, 11.338911],
                [106.9503822, 11.3389589],
                [106.9502971, 11.3390393],
                [106.950193, 11.3390941],
                [106.9500639, 11.3391291],
                [106.9499302, 11.3391362],
                [106.9498815, 11.3391459],
                [106.9497926, 11.3391886],
                [106.9497233, 11.3392579],
                [106.9496986, 11.3393002],
                [106.9496546, 11.3394562],
                [106.9496229, 11.339537],
                [106.9496122, 11.3395562],
                [106.9495727, 11.3395899],
                [106.9495135, 11.3396008],
                [106.9494839, 11.3395936],
                [106.9494082, 11.3395379],
                [106.9493761, 11.3395256],
                [106.9493077, 11.3395235],
                [106.9492451, 11.3395508],
                [106.9492011, 11.3396023],
                [106.9491715, 11.3397212],
                [106.9491518, 11.339773],
                [106.9491107, 11.3398454],
                [106.9490618, 11.3398975],
                [106.9490256, 11.3399223],
                [106.9489434, 11.3399528],
                [106.9488996, 11.3399578],
                [106.9488124, 11.3399464],
                [106.9487715, 11.3399304],
                [106.9487463, 11.3399107],
                [106.9487142, 11.3398564],
                [106.9487078, 11.3397619],
                [106.9486956, 11.3397322],
                [106.9486516, 11.3396854],
                [106.9485905, 11.3396638],
                [106.9485262, 11.3396724],
                [106.9484975, 11.3396877],
                [106.9484165, 11.3397799],
                [106.948353, 11.3398778],
                [106.9483038, 11.3400064],
                [106.948285, 11.3401426],
                [106.9482976, 11.3402794],
                [106.9483155, 11.3403459],
                [106.9483401, 11.3404133],
                [106.9484319, 11.3405959],
                [106.9484735, 11.3407417],
                [106.9484739, 11.340817],
                [106.9484633, 11.3408609],
                [106.9484195, 11.3409398],
                [106.9483877, 11.3409723],
                [106.948309, 11.3410185],
                [106.9482647, 11.3410306],
                [106.94814, 11.3410366],
                [106.9479832, 11.3410183],
                [106.9479069, 11.3409985],
                [106.9478327, 11.3409718],
                [106.9477533, 11.3409222],
                [106.947708, 11.3409087],
                [106.9476135, 11.3409066],
                [106.947524, 11.3409377],
                [106.9474851, 11.3409647],
                [106.9474245, 11.3410363],
                [106.9474037, 11.3410783],
                [106.947384, 11.3411693],
                [106.9473948, 11.3412618],
                [106.9474348, 11.3413461],
                [106.9475453, 11.341461],
                [106.9476201, 11.3415679],
                [106.9476716, 11.3416873],
                [106.9476879, 11.3417503],
                [106.9477074, 11.3418545],
                [106.9477249, 11.3420306],
                [106.9477072, 11.3421068],
                [106.9476869, 11.3421447],
                [106.9476601, 11.3421785],
                [106.9475901, 11.3422292],
                [106.9475063, 11.3422523],
                [106.9474626, 11.3422523],
                [106.9474196, 11.3422447],
                [106.9473492, 11.3422115],
                [106.9472943, 11.342157],
                [106.9472748, 11.3421237],
                [106.9472198, 11.3420805],
                [106.9471507, 11.3420672],
                [106.9470831, 11.3420869],
                [106.946891, 11.3422306],
                [106.9467938, 11.3423288],
                [106.9467538, 11.3423863],
                [106.9466664, 11.3425664],
                [106.9465908, 11.342662],
                [106.9464951, 11.3427384],
                [106.9463843, 11.3427917],
                [106.9462912, 11.3428584],
                [106.9462227, 11.3429494],
                [106.9461851, 11.3430562],
                [106.9461817, 11.3431693],
                [106.9462129, 11.3432781],
                [106.9462407, 11.3433278],
                [106.9463175, 11.3434122],
                [106.9464667, 11.3435029],
                [106.9465523, 11.3435858],
                [106.9465863, 11.3436346],
                [106.9466341, 11.3437428],
                [106.9466525, 11.3438594],
                [106.9466474, 11.3438994],
                [106.9466164, 11.343974],
                [106.9465916, 11.3440062],
                [106.9465268, 11.3440556],
                [106.9464888, 11.3440713],
                [106.9463918, 11.3440864],
                [106.9463346, 11.3440848],
                [106.946175, 11.3440523],
                [106.9460775, 11.3440587],
                [106.9459858, 11.3440915],
                [106.9459444, 11.3441171],
                [106.9459196, 11.3441433],
                [106.9458906, 11.3442085],
                [106.9458931, 11.3442795],
                [106.9459267, 11.3443425],
                [106.9459848, 11.344385],
                [106.9460729, 11.3444189],
                [106.9461682, 11.3444847],
                [106.9462425, 11.3445727],
                [106.9462701, 11.3446231],
                [106.9463038, 11.3447325],
                [106.9463082, 11.3448522],
                [106.9462811, 11.3449689],
                [106.9462242, 11.345075],
                [106.9461526, 11.3451737],
                [106.9461261, 11.3452293],
                [106.9460946, 11.3453479],
                [106.946093, 11.3454705],
                [106.9461214, 11.3455898],
                [106.9461751, 11.3457055],
                [106.9461807, 11.3457388],
                [106.9461701, 11.3458052],
                [106.9461324, 11.3458614],
                [106.9460744, 11.3458972],
                [106.9458333, 11.3459404],
                [106.9456333, 11.3460053],
                [106.9455371, 11.3460488],
                [106.9454445, 11.3460992],
                [106.9452722, 11.3462198],
                [106.9451934, 11.3462893],
                [106.9451236, 11.3463817],
                [106.9450964, 11.3464327],
                [106.9450323, 11.3466043],
                [106.9449965, 11.3466624],
                [106.9449527, 11.346715],
                [106.9449018, 11.346761],
                [106.9447748, 11.3468526],
                [106.9447084, 11.3469099],
                [106.9445388, 11.3470806],
                [106.9444317, 11.3471571],
                [106.9444011, 11.3471894],
                [106.9443773, 11.3472267],
                [106.944353, 11.3473109],
                [106.9443533, 11.3473549],
                [106.9443789, 11.3474389],
                [106.9444032, 11.3474758],
                [106.9444707, 11.3475332],
                [106.9446271, 11.347587],
                [106.9446956, 11.3476201],
                [106.9447916, 11.3476819],
                [106.9448179, 11.3477094],
                [106.9448469, 11.3477739],
                [106.9448499, 11.3478092],
                [106.9448322, 11.3478776],
                [106.9448124, 11.3479072],
                [106.9447556, 11.3479504],
                [106.9446462, 11.3479797],
                [106.9445694, 11.3479883],
                [106.9444922, 11.3479876],
                [106.9444156, 11.3479776],
                [106.9442728, 11.3479543],
                [106.9442046, 11.3479278],
                [106.9441312, 11.347925],
                [106.9440893, 11.3479346],
                [106.9440152, 11.3479767],
                [106.9439859, 11.3480076],
                [106.9439484, 11.3480831],
                [106.9439417, 11.3481248],
                [106.9439406, 11.3482557],
                [106.9439288, 11.3483437],
                [106.9438791, 11.3485141],
                [106.943873, 11.348624],
                [106.9439007, 11.3487307],
                [106.9439876, 11.3488761],
                [106.9440077, 11.3489311],
                [106.9440197, 11.3489884],
                [106.944018, 11.3491051],
                [106.9439827, 11.3492165],
                [106.9438485, 11.3494201],
                [106.9437909, 11.3495539],
                [106.9437709, 11.3496339],
                [106.9437606, 11.3497158],
                [106.94376, 11.3497982],
                [106.9437692, 11.3498801],
                [106.9437879, 11.3499605],
                [106.9437853, 11.3500779],
                [106.9437485, 11.3501897],
                [106.9436539, 11.3503336],
                [106.9436351, 11.3503842],
                [106.9436231, 11.3504908],
                [106.9436457, 11.3505959],
                [106.9437007, 11.3506887],
                [106.9437826, 11.3507599],
                [106.9438587, 11.3508099],
                [106.943914, 11.3508359],
                [106.9439651, 11.3508837],
                [106.9439999, 11.3509543],
                [106.9440055, 11.3510324],
                [106.9439969, 11.3510709],
                [106.9439463, 11.351174],
                [106.9439059, 11.3512378],
                [106.9438094, 11.3513542],
                [106.9437771, 11.3514053],
                [106.9437531, 11.3514606],
                [106.9437379, 11.3515189],
                [106.9437318, 11.3515787],
                [106.9437351, 11.3516387],
                [106.9437475, 11.3516975],
                [106.9437689, 11.3517539],
                [106.9437987, 11.3518064],
                [106.9438362, 11.3518539],
                [106.9439491, 11.3519572],
                [106.944083, 11.3520327],
                [106.9441991, 11.3521179],
                [106.9442921, 11.3522271],
                [106.9443569, 11.3523543],
                [106.94439, 11.3524928],
                [106.9443923, 11.3526437],
                [106.9443687, 11.3528014],
                [106.944319, 11.3529531],
                [106.9443096, 11.3530059],
                [106.9443142, 11.3531127],
                [106.9443282, 11.3531645],
                [106.9443779, 11.3532596],
                [106.9445186, 11.3534036],
                [106.9445782, 11.3534752],
                [106.9446784, 11.3536316],
                [106.944736, 11.3537466],
                [106.9447848, 11.3538656],
                [106.9448245, 11.3539877],
                [106.944855, 11.3541123],
                [106.944876, 11.3542388],
                [106.9446862, 11.3542791],
                [106.9444986, 11.3543287],
                [106.9443137, 11.3543874],
                [106.9441321, 11.354455],
                [106.9439541, 11.3545314],
                [106.9437802, 11.3546164],
                [106.9436109, 11.3547098],
                [106.9432113, 11.3549499],
                [106.9427955, 11.3551621],
                [106.9423984, 11.3553326],
                [106.9419908, 11.3554776],
                [106.9417767, 11.3555849],
                [106.9416762, 11.3556498],
                [106.9415702, 11.3557481],
                [106.9413632, 11.3559168],
                [106.9412452, 11.3560036],
                [106.9410217, 11.356139],
                [106.9409044, 11.3561971],
                [106.9406605, 11.3562928],
                [106.9405719, 11.3563132],
                [106.9403908, 11.3563275],
                [106.9402104, 11.3563064],
                [106.9401226, 11.3562827],
                [106.9399566, 11.3562104],
                [106.9398085, 11.3561072],
                [106.9396082, 11.3559024],
                [106.9395261, 11.355834],
                [106.9393456, 11.355719],
                [106.9392486, 11.3556732],
                [106.9391479, 11.3556357],
                [106.9390444, 11.3556066],
                [106.9389387, 11.3555863],
                [106.9385858, 11.3555616],
                [106.9384486, 11.3555425],
                [106.9383129, 11.355515],
                [106.9379162, 11.3553998],
                [106.9377825, 11.3553716],
                [106.9375113, 11.3553382],
                [106.9373747, 11.355333],
                [106.9343358, 11.3553251],
                [106.9343447, 11.3543351],
                [106.9342311, 11.3542384],
                [106.9340437, 11.3541283],
                [106.9339442, 11.3540839],
                [106.9338336, 11.3540444],
                [106.9337201, 11.3540139],
                [106.9336044, 11.3539925],
                [106.9334874, 11.3539803],
                [106.9331215, 11.3539841],
                [106.9329909, 11.3539768],
                [106.9327322, 11.3539398],
                [106.9325419, 11.3538933],
                [106.9323835, 11.3538364],
                [106.9322903, 11.3537923],
                [106.9322008, 11.3537415],
                [106.9319469, 11.3535643],
                [106.9318544, 11.3535156],
                [106.9317578, 11.3534752],
                [106.9316579, 11.3534436],
                [106.9315555, 11.3534209],
                [106.9314514, 11.3534074],
                [106.9313465, 11.3534031],
                [106.9312417, 11.3534082],
                [106.9311377, 11.3534225],
                [106.9310355, 11.3534459],
                [106.9309358, 11.3534783],
                [106.9308396, 11.3535194],
                [106.9307475, 11.3535689],
                [106.9306604, 11.3536263],
                [106.9305393, 11.3537381],
                [106.9304436, 11.3538714],
                [106.9304064, 11.3539445],
                [106.9303261, 11.3541812],
                [106.9302904, 11.3542603],
                [106.9302002, 11.3544088],
                [106.9301464, 11.3544773],
                [106.9300399, 11.3546],
                [106.929929, 11.3546934],
                [106.9298032, 11.3547549],
                [106.9296661, 11.3547851],
                [106.9295256, 11.3547823],
                [106.9293898, 11.3547466],
                [106.9291545, 11.3546511],
                [106.9289042, 11.3545319],
                [106.9286227, 11.3544258],
                [106.9284753, 11.3543839],
                [106.9283254, 11.3543512],
                [106.9281737, 11.354328],
                [106.9280208, 11.3543143],
                [106.9278674, 11.3543102],
                [106.927714, 11.3543156],
                [106.9275612, 11.3543306],
                [106.9271358, 11.3544083],
                [106.9270104, 11.35442],
                [106.9267586, 11.3544201],
                [106.9265087, 11.3543894],
                [106.9261475, 11.3543159],
                [106.9256676, 11.354242],
                [106.9253053, 11.3542024],
                [106.9250268, 11.3541904],
                [106.9248695, 11.3541938],
                [106.9245562, 11.3542227],
                [106.924401, 11.3542481],
                [106.9240951, 11.3543206],
                [106.9239711, 11.3543423],
                [106.9238458, 11.354355],
                [106.9237198, 11.3543585],
                [106.923594, 11.3543528],
                [106.9234689, 11.3543379],
                [106.9233453, 11.3543139],
                [106.9232238, 11.354281],
                [106.9230467, 11.3542162],
                [106.922918, 11.3541469],
                [106.9228504, 11.3540981],
                [106.9227309, 11.3539828],
                [106.9225134, 11.3536824],
                [106.9223732, 11.3535317],
                [106.9223027, 11.3534721],
                [106.9221471, 11.3533721],
                [106.9219008, 11.3532622],
                [106.92183, 11.3532163],
                [106.9217647, 11.3531632],
                [106.9217056, 11.3531034],
                [106.9216535, 11.3530378],
                [106.921609, 11.352967],
                [106.9215447, 11.3528133],
                [106.9215139, 11.3526608],
                [106.9215041, 11.3525166],
                [106.9214756, 11.3523993],
                [106.9214184, 11.3522925],
                [106.9213361, 11.3522029],
                [106.9212871, 11.3521663],
                [106.9211772, 11.3521123],
                [106.9207692, 11.3520117],
                [106.9206612, 11.3519779],
                [106.9204527, 11.3518907],
                [106.9203657, 11.3518615],
                [106.9201852, 11.3518277],
                [106.9200014, 11.3518277],
                [106.9199103, 11.3518404],
                [106.9197339, 11.3518907],
                [106.919681, 11.3518974],
                [106.919575, 11.3518879],
                [106.9194236, 11.3518318],
                [106.9193688, 11.3518229],
                [106.9193133, 11.351822],
                [106.919205, 11.3518443],
                [106.9191545, 11.351867],
                [106.9190666, 11.351933],
                [106.9188327, 11.3521957],
                [106.9187678, 11.3522824],
                [106.9187107, 11.3523741],
                [106.9186618, 11.3524702],
                [106.9186213, 11.3525701],
                [106.9185897, 11.352673],
                [106.9185671, 11.3527781],
                [106.9185301, 11.3530159],
                [106.9184989, 11.3531456],
                [106.9184602, 11.3532733],
                [106.9183127, 11.3536535],
                [106.9182735, 11.3537886],
                [106.9182434, 11.3539259],
                [106.9182125, 11.3541346],
                [106.918216, 11.3542747],
                [106.9182498, 11.3544105],
                [106.9183123, 11.3545362],
                [106.9183293, 11.3546247],
                [106.9183254, 11.3546697],
                [106.9182933, 11.354754],
                [106.9182328, 11.3548217],
                [106.9181557, 11.3548804],
                [106.918123, 11.3549198],
                [106.9180785, 11.3550112],
                [106.9180653, 11.3551116],
                [106.9180709, 11.3551621],
                [106.9181003, 11.3552295],
                [106.9181514, 11.355283],
                [106.918165, 11.3553068],
                [106.9181702, 11.3553606],
                [106.9181613, 11.3553865],
                [106.918124, 11.3554263],
                [106.9180288, 11.3554526],
                [106.9179542, 11.3554971],
                [106.9179002, 11.3555643],
                [106.9178528, 11.3556597],
                [106.9178164, 11.3557121],
                [106.9177276, 11.3558037],
                [106.9176672, 11.3558483],
                [106.9175322, 11.3559148],
                [106.917385, 11.3559479],
                [106.9173095, 11.3559513],
                [106.9172341, 11.3559457],
                [106.9169229, 11.3559089],
                [106.9167514, 11.3559158],
                [106.9165842, 11.355954],
                [106.9165041, 11.3559845],
                [106.9162875, 11.3560962],
                [106.9161453, 11.35613],
                [106.9160722, 11.3561337],
                [106.9159272, 11.3561147],
                [106.9158576, 11.3560922],
                [106.9157296, 11.356023],
                [106.9155551, 11.3558867],
                [106.9155121, 11.3558616],
                [106.9154656, 11.3558431],
                [106.9153668, 11.3558276],
                [106.9152678, 11.3558417],
                [106.9151776, 11.3558841],
                [106.9151044, 11.355951],
                [106.9150556, 11.3560119],
                [106.9150316, 11.3560583],
                [106.9149835, 11.3560965],
                [106.9149141, 11.35611],
                [106.9148792, 11.3561033],
                [106.9148201, 11.3560653],
                [106.9147999, 11.3560365],
                [106.9147662, 11.3559615],
                [106.9147387, 11.355923],
                [106.9147058, 11.3558886],
                [106.914627, 11.3558353],
                [106.9145664, 11.3557692],
                [106.9145474, 11.3557287],
                [106.9145371, 11.3556853],
                [106.9145264, 11.3555802],
                [106.9145085, 11.3555217],
                [106.9144486, 11.3554148],
                [106.9144078, 11.3553686],
                [106.9143609, 11.3553284],
                [106.9142521, 11.3552693],
                [106.9141409, 11.3552434],
                [106.9140369, 11.3552462],
                [106.9139913, 11.3552408],
                [106.9139078, 11.3552048],
                [106.9138447, 11.3551402],
                [106.9137938, 11.3550249],
                [106.9137407, 11.3549745],
                [106.9137075, 11.3549582],
                [106.9136532, 11.3549461],
                [106.913607, 11.3549523],
                [106.9135601, 11.3549821],
                [106.9135187, 11.3550246],
                [106.9134896, 11.3550384],
                [106.9134258, 11.3550432],
                [106.9133675, 11.3550174],
                [106.9133001, 11.3549324],
                [106.9132655, 11.3549027],
                [106.9131834, 11.3548634],
                [106.9131383, 11.354855],
                [106.9130471, 11.3548618],
                [106.9129022, 11.3549246],
                [106.9128374, 11.3549414],
                [106.9127709, 11.3549491],
                [106.9127039, 11.3549475],
                [106.9126377, 11.3549367],
                [106.9124499, 11.3548682],
                [106.9123177, 11.3548522],
                [106.9122511, 11.3548568],
                [106.9121226, 11.3548911],
                [106.9119877, 11.3549675],
                [106.9119551, 11.3549787],
                [106.9119207, 11.3549823],
                [106.9118541, 11.3549663],
                [106.9118017, 11.3549229],
                [106.9117746, 11.3548611],
                [106.9117725, 11.3548273],
                [106.9118106, 11.3545257],
                [106.9118025, 11.3543245],
                [106.9117863, 11.354225],
                [106.9117499, 11.354078],
                [106.9117302, 11.3540313],
                [106.9116643, 11.3539429],
                [106.9116212, 11.3539078],
                [106.9115205, 11.3538605],
                [106.9114656, 11.3538495],
                [106.9114096, 11.3538475],
                [106.9113004, 11.3538701],
                [106.9111334, 11.3539664],
                [106.9110582, 11.3539986],
                [106.9109799, 11.3540225],
                [106.9108993, 11.3540375],
                [106.9108174, 11.3540437],
                [106.9107354, 11.3540408],
                [106.910575, 11.3540084],
                [106.9104985, 11.3539792],
                [106.9104258, 11.3539418],
                [106.9103579, 11.3538966],
                [106.9102956, 11.3538443],
                [106.9102129, 11.3537548],
                [106.9101687, 11.3536734],
                [106.9101554, 11.3536229],
                [106.9101556, 11.353519],
                [106.9101691, 11.3534685],
                [106.9101586, 11.3534021],
                [106.9101189, 11.3533473],
                [106.9100584, 11.3533156],
                [106.9099634, 11.3533092],
                [106.9099031, 11.3533167],
                [106.909789, 11.3533575],
                [106.909738, 11.3533899],
                [106.9096925, 11.3534295],
                [106.9096535, 11.3534754],
                [106.9095751, 11.3535917],
                [106.9094664, 11.3537105],
                [106.9093603, 11.3537772],
                [106.9092395, 11.3538125],
                [106.9091135, 11.3538138],
                [106.9090516, 11.3538015],
                [106.908936, 11.3537523],
                [106.9088846, 11.3537163],
                [106.9087964, 11.3536237],
                [106.9087315, 11.3535106],
                [106.908703, 11.3534767],
                [106.9086686, 11.3534486],
                [106.9085867, 11.3534139],
                [106.9084976, 11.3534114],
                [106.9084139, 11.3534414],
                [106.9083778, 11.3534674],
                [106.9082884, 11.3535774],
                [106.9082467, 11.3536117],
                [106.9081491, 11.3536579],
                [106.9080415, 11.3536704],
                [106.9079355, 11.3536479],
                [106.9078868, 11.3536242],
                [106.9078046, 11.353555],
                [106.9077134, 11.3534081],
                [106.9076696, 11.3533585],
                [106.9076192, 11.3533152],
                [106.9075632, 11.3532793],
                [106.9073749, 11.3532037],
                [106.9073152, 11.3531679],
                [106.907212, 11.3530758],
                [106.90717, 11.3530209],
                [106.9071086, 11.352898],
                [106.9070803, 11.352764],
                [106.907082, 11.3526127],
                [106.9071007, 11.3524482],
                [106.907094, 11.3524043],
                [106.907055, 11.3523247],
                [106.9070242, 11.3522921],
                [106.9069875, 11.3522661],
                [106.9069022, 11.3522378],
                [106.9068217, 11.3522437],
                [106.9067838, 11.3522579],
                [106.9067198, 11.3523062],
                [106.9066556, 11.3523299],
                [106.9065876, 11.352321],
                [106.906532, 11.3522815],
                [106.9065022, 11.3522209],
                [106.9065062, 11.3521054],
                [106.9064987, 11.3520578],
                [106.9064592, 11.3519697],
                [106.9063916, 11.3519001],
                [106.9062925, 11.3518328],
                [106.9062406, 11.3517845],
                [106.9061946, 11.3517309],
                [106.9061226, 11.35161],
                [106.9060975, 11.3515443],
                [106.9060498, 11.3513112],
                [106.90602, 11.3512179],
                [106.9059348, 11.3510413],
                [106.9058801, 11.3509595],
                [106.9058179, 11.350883],
                [106.9055395, 11.3505915],
                [106.9054269, 11.3504878],
                [106.9053358, 11.3503654],
                [106.9052943, 11.3502883],
                [106.9052614, 11.3502072],
                [106.9052376, 11.3501231],
                [106.9052229, 11.3500371],
                [106.9052178, 11.3499499],
                [106.9052035, 11.3498897],
                [106.9051499, 11.349778],
                [106.9050667, 11.3496853],
                [106.9050159, 11.3496485],
                [106.9048371, 11.3495654],
                [106.9047777, 11.3495255],
                [106.9046763, 11.3494255],
                [106.9046361, 11.3493669],
                [106.9045796, 11.3492373],
                [106.9045643, 11.3491683],
                [106.9045579, 11.3490979],
                [106.9045589, 11.3488333],
                [106.9045418, 11.3487455],
                [106.9044911, 11.3486605],
                [106.9043804, 11.348567],
                [106.9043334, 11.3484904],
                [106.9043205, 11.3484474],
                [106.9043178, 11.3483582],
                [106.904328, 11.3483145],
                [106.9043822, 11.3481992],
                [106.9043855, 11.3481613],
                [106.9043655, 11.3480884],
                [106.9043434, 11.3480572],
                [106.9042805, 11.3480136],
                [106.9042431, 11.3480034],
                [106.9041299, 11.3480074],
                [106.9040632, 11.3479793],
                [106.9040167, 11.3479247],
                [106.9040045, 11.347891],
                [106.9040042, 11.3477769],
                [106.9039794, 11.3476947],
                [106.9039566, 11.347658],
                [106.9038936, 11.3475987],
                [106.9038552, 11.3475779],
                [106.9037785, 11.3475403],
                [106.9036192, 11.347478],
                [106.9035896, 11.3474537],
                [106.9035492, 11.3473897],
                [106.9035393, 11.3473151],
                [106.9035618, 11.3472431],
                [106.9035842, 11.3472123],
                [106.9036835, 11.3471376],
                [106.9037152, 11.3471016],
                [106.9037572, 11.3470164],
                [106.9037662, 11.3469697],
                [106.9037669, 11.3469222],
                [106.9037432, 11.3468304],
                [106.9036564, 11.3467018],
                [106.9036311, 11.3466477],
                [106.9036036, 11.346532],
                [106.9036019, 11.3464725],
                [106.9036227, 11.3463555],
                [106.9036449, 11.3463],
                [106.9037108, 11.3462003],
                [106.9037974, 11.3461023],
                [106.903863, 11.3459775],
                [106.9038835, 11.34591],
                [106.9038954, 11.3458407],
                [106.9038929, 11.3456095],
                [106.9039017, 11.3455192],
                [106.903919, 11.34543],
                [106.9039786, 11.3452584],
                [106.9039952, 11.345188],
                [106.9040023, 11.3451161],
                [106.9039997, 11.3450439],
                [106.9039876, 11.3449726],
                [106.9039205, 11.3447841],
                [106.9039126, 11.3446734],
                [106.9039201, 11.3446183],
                [106.9039572, 11.3445135],
                [106.9040215, 11.3444222],
                [106.9040641, 11.344328],
                [106.9040733, 11.3442255],
                [106.9040481, 11.3441255],
                [106.9040233, 11.3440799],
                [106.9039527, 11.3440037],
                [106.9038057, 11.3439036],
                [106.9036084, 11.3437489],
                [106.9035251, 11.3436701],
                [106.9034967, 11.3436188],
                [106.9034741, 11.3435995],
                [106.9034192, 11.3435788],
                [106.9033614, 11.3435844],
                [106.9033122, 11.3436145],
                [106.9032818, 11.343663],
                [106.9032596, 11.3437345],
                [106.9032046, 11.3438093],
                [106.9031262, 11.3438602],
                [106.9030812, 11.3438744],
                [106.9029717, 11.3438828],
                [106.9029091, 11.3438786],
                [106.9028472, 11.343868],
                [106.9026748, 11.343814],
                [106.9026197, 11.3438067],
                [106.9025087, 11.343812],
                [106.9024706, 11.3438061],
                [106.9024347, 11.3437923],
                [106.9023761, 11.3437438],
                [106.9023438, 11.3436755],
                [106.9023397, 11.3436379],
                [106.9023564, 11.3435645],
                [106.9024031, 11.3435049],
                [106.902507, 11.3434485],
                [106.9025629, 11.3433874],
                [106.9025917, 11.3433103],
                [106.9025917, 11.3431803],
                [106.9026033, 11.3431336],
                [106.9026237, 11.3430899],
                [106.9026521, 11.3430508],
                [106.9026875, 11.3430176],
                [106.9027287, 11.3429915],
                [106.9027697, 11.3429775],
                [106.9028129, 11.3429727],
                [106.902856, 11.3429775],
                [106.9028971, 11.3429915],
                [106.9029339, 11.3430141],
                [106.9029647, 11.3430441],
                [106.902992, 11.3430572],
                [106.903052, 11.3430603],
                [106.9031052, 11.3430328],
                [106.9031365, 11.3429825],
                [106.9031409, 11.3429531],
                [106.9031372, 11.3429236],
                [106.903107, 11.3428726],
                [106.9029486, 11.3427391],
                [106.9029043, 11.3427162],
                [106.9028563, 11.3427021],
                [106.9028065, 11.3426973],
                [106.9027566, 11.3427021],
                [106.9027086, 11.3427162],
                [106.9026643, 11.3427391],
                [106.9025974, 11.3427428],
                [106.9025365, 11.3427153],
                [106.902496, 11.3426628],
                [106.9024856, 11.3425979],
                [106.902511, 11.3424576],
                [106.9025248, 11.3422394],
                [106.9025388, 11.3421794],
                [106.9025921, 11.3420683],
                [106.902675, 11.3419764],
                [106.9026317, 11.3418888],
                [106.9025813, 11.3418048],
                [106.9024605, 11.3416503],
                [106.9023133, 11.3415151],
                [106.9021455, 11.3414054],
                [106.9020092, 11.3413407],
                [106.9018963, 11.3413084],
                [106.9018297, 11.3412997],
                [106.9016956, 11.3413041],
                [106.9015658, 11.3413374],
                [106.9015045, 11.3413644],
                [106.9013981, 11.3414326],
                [106.901353, 11.3414716],
                [106.9012749, 11.3415609],
                [106.9012052, 11.3416047],
                [106.9011652, 11.3416158],
                [106.9010824, 11.3416142],
                [106.9010066, 11.3415816],
                [106.9009492, 11.3415231],
                [106.9009191, 11.3414474],
                [106.9009211, 11.3413023],
                [106.900912, 11.3412389],
                [106.9008483, 11.3410576],
                [106.9008377, 11.3409942],
                [106.9008355, 11.34093],
                [106.9008604, 11.3407446],
                [106.9008549, 11.3406857],
                [106.9008403, 11.3406284],
                [106.9008169, 11.340574],
                [106.9007851, 11.3405238],
                [106.9007459, 11.340479],
                [106.9007001, 11.3404407],
                [106.9006487, 11.3404097],
                [106.9005932, 11.3403869],
                [106.900481, 11.3403622],
                [106.9004116, 11.3403581],
                [106.8893218, 11.3405781],
                [106.8841276, 11.3395939],
                [106.8840074, 11.3394313],
                [106.8839537, 11.3393458],
                [106.8839123, 11.3392728],
                [106.8838501, 11.3391174],
                [106.8838159, 11.3389447],
                [106.8838113, 11.3388522],
                [106.8838159, 11.3387597],
                [106.8838298, 11.3386681],
                [106.8838253, 11.3386169],
                [106.8837909, 11.3385201],
                [106.8837262, 11.3384397],
                [106.8836845, 11.3384085],
                [106.8836382, 11.3383845],
                [106.8834925, 11.3383297],
                [106.8834006, 11.3382827],
                [106.8833135, 11.3382276],
                [106.8832319, 11.3381649],
                [106.8831565, 11.3380952],
                [106.8830879, 11.338019],
                [106.8830267, 11.337937],
                [106.8828745, 11.3376665],
                [106.8827452, 11.3374969],
                [106.8826708, 11.3374201],
                [106.8824092, 11.3372039],
                [106.8823188, 11.3371174],
                [106.8821553, 11.3369288],
                [106.8820167, 11.336722],
                [106.8819445, 11.3366245],
                [106.8818651, 11.3365326],
                [106.8817787, 11.3364469],
                [106.881686, 11.3363679],
                [106.8815875, 11.3362959],
                [106.8814722, 11.3361762],
                [106.8813837, 11.3360362],
                [106.8813256, 11.3358816],
                [106.8813002, 11.3357188],
                [106.8813086, 11.3355543],
                [106.8812949, 11.3354089],
                [106.8812746, 11.3353386],
                [106.8812456, 11.3352712],
                [106.8811883, 11.3351769],
                [106.8811637, 11.3351493],
                [106.8811288, 11.3351209],
                [106.8810464, 11.3350841],
                [106.8809562, 11.3350777],
                [106.880865, 11.335087],
                [106.8808189, 11.3350797],
                [106.8807344, 11.335042],
                [106.8806985, 11.3350128],
                [106.8806685, 11.3349778],
                [106.8805484, 11.334762],
                [106.8804601, 11.3345815],
                [106.8803805, 11.3343972],
                [106.880324, 11.3342992],
                [106.8802594, 11.334206],
                [106.8801873, 11.3341184],
                [106.880108, 11.3340369],
                [106.8800222, 11.3339621],
                [106.8799305, 11.3338944],
                [106.8797193, 11.333755],
                [106.8795068, 11.3335769],
                [106.8794091, 11.3334788],
                [106.879159, 11.3331864],
                [106.8789956, 11.3330427],
                [106.8788133, 11.3329231],
                [106.8784901, 11.3327669],
                [106.8783691, 11.3326962],
                [106.878253, 11.3326179],
                [106.8780379, 11.3324397],
                [106.877807, 11.3321985],
                [106.8776794, 11.3320521],
                [106.8775569, 11.3319015],
                [106.8771882, 11.3313999],
                [106.8770431, 11.3312151],
                [106.8768931, 11.3310342],
                [106.8764484, 11.3305352],
                [106.8758529, 11.3299053],
                [106.8754096, 11.3294793],
                [106.8750649, 11.3291724],
                [106.8747889, 11.3289524],
                [106.8746267, 11.3288367],
                [106.8744602, 11.3287271],
                [106.8742033, 11.3285737],
                [106.8740047, 11.3284758],
                [106.8736331, 11.3283336],
                [106.8734923, 11.3282698],
                [106.8732214, 11.328122],
                [106.8729669, 11.3279486],
                [106.8728499, 11.3278531],
                [106.8727391, 11.3277507],
                [106.872635, 11.3276418],
                [106.8725378, 11.3275269],
                [106.8724059, 11.3273442],
                [106.8723035, 11.3271681],
                [106.872201, 11.3269326],
                [106.8720952, 11.326588],
                [106.8720014, 11.3264018],
                [106.8718778, 11.3262331],
                [106.8716362, 11.3259931],
                [106.8715518, 11.3258936],
                [106.8714747, 11.3257884],
                [106.8714055, 11.3256782],
                [106.8713443, 11.3255634],
                [106.8712917, 11.3254446],
                [106.8712477, 11.3253225],
                [106.8711547, 11.325015],
                [106.8710893, 11.3248347],
                [106.8710673, 11.32466],
                [106.8709982, 11.3243147],
                [106.8708962, 11.3239773],
                [106.8707705, 11.3236688],
                [106.8706173, 11.3233724],
                [106.8704797, 11.3230728],
                [106.8702899, 11.3225412],
                [106.8702004, 11.3223236],
                [106.870102, 11.3221096],
                [106.869995, 11.3218996],
                [106.8699475, 11.321777],
                [106.8699091, 11.3216514],
                [106.8698801, 11.3215233],
                [106.8698605, 11.3213936],
                [106.8698438, 11.3210189],
                [106.8698318, 11.3209067],
                [106.8697911, 11.3206845],
                [106.8697886, 11.3206136],
                [106.8697953, 11.3205428],
                [106.8698113, 11.3204735],
                [106.8698362, 11.3204068],
                [106.8698696, 11.3203438],
                [106.8699109, 11.3202855],
                [106.8699596, 11.3202328],
                [106.8700147, 11.3201868],
                [106.870217, 11.320052],
                [106.8703547, 11.3199507],
                [106.8705551, 11.319791],
                [106.8706173, 11.3197325],
                [106.870734, 11.319588],
                [106.8708215, 11.3194248],
                [106.8708533, 11.319338],
                [106.8709164, 11.3192448],
                [106.8710022, 11.3191712],
                [106.8711495, 11.3190806],
                [106.8712417, 11.3190104],
                [106.871328, 11.3189332],
                [106.8714077, 11.3188495],
                [106.8714803, 11.3187598],
                [106.8716977, 11.318452],
                [106.8717548, 11.3183858],
                [106.8718564, 11.3182439],
                [106.8719512, 11.3180666],
                [106.8719889, 11.3179736],
                [106.8721189, 11.3175204],
                [106.8721712, 11.3174163],
                [106.872248, 11.3173181],
                [106.8722604, 11.3172879],
                [106.8722636, 11.3172232],
                [106.8722542, 11.317192],
                [106.8722038, 11.3171129],
                [106.8722047, 11.3170559],
                [106.8722373, 11.3170087],
                [106.8723272, 11.31697],
                [106.8723589, 11.3169461],
                [106.8724031, 11.3168811],
                [106.8724134, 11.3168432],
                [106.8724082, 11.3167652],
                [106.8723807, 11.3166789],
                [106.8723779, 11.3166274],
                [106.8723846, 11.3165763],
                [106.8724005, 11.3165272],
                [106.8724534, 11.3164168],
                [106.8724752, 11.3163496],
                [106.8724904, 11.3162805],
                [106.8725101, 11.3160603],
                [106.8725288, 11.3159823],
                [106.8725559, 11.3159067],
                [106.8726748, 11.3156901],
                [106.8727092, 11.315611],
                [106.8727589, 11.3154461],
                [106.8728031, 11.3151623],
                [106.8728572, 11.3145716],
                [106.8728946, 11.3142874],
                [106.8729403, 11.3140937],
                [106.8729743, 11.313996],
                [106.8730153, 11.3139009],
                [106.8731171, 11.3137202],
                [106.8732666, 11.3135032],
                [106.8733478, 11.3133993],
                [106.8734527, 11.3132237],
                [106.8734947, 11.3131306],
                [106.8735977, 11.3128158],
                [106.8736456, 11.3126978],
                [106.8737622, 11.312471],
                [106.8738306, 11.312363],
                [106.8739647, 11.3121933],
                [106.874194, 11.3119626],
                [106.8742607, 11.3118771],
                [106.8743198, 11.3117865],
                [106.8743709, 11.3116913],
                [106.8744135, 11.3115921],
                [106.8744939, 11.3113269],
                [106.8745318, 11.3111618],
                [106.8746102, 11.3106675],
                [106.8746475, 11.3105097],
                [106.8746935, 11.310354],
                [106.8748173, 11.3099922],
                [106.874941, 11.3095698],
                [106.8750262, 11.3093646],
                [106.8750781, 11.3092661],
                [106.8751358, 11.3091708],
                [106.8753858, 11.3088206],
                [106.8754462, 11.3086913],
                [106.8754781, 11.3085477],
                [106.8754802, 11.3083638],
                [106.8754735, 11.3082615],
                [106.8754374, 11.3080511],
                [106.8754144, 11.307961],
                [106.8753482, 11.307787],
                [106.8752988, 11.3076924],
                [106.8752413, 11.3076023],
                [106.8751763, 11.3075172],
                [106.8750254, 11.3073648],
                [106.8750235, 11.3074418],
                [106.8750162, 11.3075184],
                [106.8749852, 11.3076693],
                [106.8749208, 11.3078413],
                [106.874828, 11.3080005],
                [106.8747718, 11.3080738],
                [106.8746669, 11.3081879],
                [106.8745842, 11.3083131],
                [106.874521, 11.3084599],
                [106.8744984, 11.3085441],
                [106.874475, 11.3085851],
                [106.8744079, 11.3086518],
                [106.8742278, 11.3087415],
                [106.8741056, 11.3088378],
                [106.8740602, 11.3088594],
                [106.8739, 11.3088971],
                [106.8738408, 11.3089216],
                [106.8737354, 11.308993],
                [106.8736439, 11.3090955],
                [106.8735071, 11.3093078],
                [106.8734428, 11.3093913],
                [106.8733506, 11.3094976],
                [106.8732968, 11.3095435],
                [106.8732143, 11.3095802],
                [106.8731242, 11.309589],
                [106.8730359, 11.3095691],
                [106.8729015, 11.30949],
                [106.8727764, 11.3094481],
                [106.8726445, 11.3094391],
                [106.8725146, 11.3094635],
                [106.8723111, 11.309558],
                [106.8722232, 11.3095879],
                [106.8721769, 11.3095942],
                [106.8720951, 11.3096306],
                [106.872062, 11.3096582],
                [106.8720136, 11.3097286],
                [106.872, 11.3097691],
                [106.8719889, 11.30989],
                [106.8719743, 11.3099239],
                [106.8719249, 11.3099787],
                [106.8718924, 11.3099972],
                [106.8718194, 11.310012],
                [106.8717467, 11.3099957],
                [106.8715925, 11.3098986],
                [106.8713951, 11.3098077],
                [106.871171, 11.3097315],
                [106.8710466, 11.3097005],
                [106.8707935, 11.3096604],
                [106.8706655, 11.3096514],
                [106.8705054, 11.3096684],
                [106.8703519, 11.3097163],
                [106.8702112, 11.3097932],
                [106.8701474, 11.3098416],
                [106.8700206, 11.3099684],
                [106.8699579, 11.3100456],
                [106.8698507, 11.3102126],
                [106.8697145, 11.3105091],
                [106.8696517, 11.3106213],
                [106.8695042, 11.3108323],
                [106.8694494, 11.3108905],
                [106.869326, 11.3109923],
                [106.8691868, 11.3110722],
                [106.8691125, 11.3111032],
                [106.8690355, 11.3111254],
                [106.8689563, 11.3111386],
                [106.8687056, 11.3111393],
                [106.8685271, 11.3111673],
                [106.8683568, 11.311227],
                [106.8682006, 11.3113162],
                [106.868136, 11.311345],
                [106.8679981, 11.3113771],
                [106.8678565, 11.3113736],
                [106.8676548, 11.3113213],
                [106.8675878, 11.3113172],
                [106.867521, 11.3113223],
                [106.8674555, 11.3113365],
                [106.8673927, 11.3113596],
                [106.8673338, 11.3113911],
                [106.86728, 11.3114304],
                [106.8671767, 11.3115392],
                [106.8670443, 11.311723],
                [106.866931, 11.3118215],
                [106.8668566, 11.3118583],
                [106.8668051, 11.3118717],
                [106.8666991, 11.3118733],
                [106.8666473, 11.3118615],
                [106.8665529, 11.3118143],
                [106.8664787, 11.31174],
                [106.8664518, 11.311695],
                [106.8663998, 11.3115645],
                [106.8663857, 11.3114957],
                [106.8663734, 11.3111571],
                [106.8663606, 11.3110585],
                [106.8663427, 11.3109853],
                [106.8662862, 11.3108455],
                [106.8662481, 11.3107801],
                [106.8661805, 11.3106887],
                [106.8661117, 11.310627],
                [106.8660648, 11.3105988],
                [106.8659612, 11.3105636],
                [106.8659066, 11.3105572],
                [106.8657974, 11.3105676],
                [106.8656956, 11.3106078],
                [106.8656096, 11.3106745],
                [106.865521, 11.3107219],
                [106.8653459, 11.3107556],
                [106.8652723, 11.3107794],
                [106.8652019, 11.3108113],
                [106.865164, 11.3108408],
                [106.8651078, 11.3109177],
                [106.8650836, 11.3110093],
                [106.8650847, 11.3110567],
                [106.8651113, 11.3112112],
                [106.8651187, 11.3114293],
                [106.8651382, 11.3116117],
                [106.8651831, 11.3117897],
                [106.8652488, 11.3119386],
                [106.8653389, 11.3120748],
                [106.8655205, 11.3122947],
                [106.8657479, 11.3125887],
                [106.8659747, 11.3128388],
                [106.866196, 11.313064],
                [106.8663874, 11.3132862],
                [106.8664788, 11.31344],
                [106.8665417, 11.313607],
                [106.8665936, 11.3138612],
                [106.8666168, 11.31403],
                [106.8666395, 11.314256],
                [106.8666369, 11.3143698],
                [106.866602, 11.3144969],
                [106.8665354, 11.3146112],
                [106.8664413, 11.3147052],
                [106.866326, 11.3147726],
                [106.8662627, 11.3147949],
                [106.8657222, 11.3149247],
                [106.8656148, 11.3149366],
                [106.8655068, 11.3149393],
                [106.8653989, 11.3149326],
                [106.865292, 11.3149167],
                [106.865187, 11.3148917],
                [106.8650845, 11.3148578],
                [106.8649856, 11.3148152],
                [106.8648908, 11.3147643],
                [106.864797, 11.3147251],
                [106.8646999, 11.3146947],
                [106.864397, 11.3146427],
                [106.8641979, 11.3145838],
                [106.864102, 11.3145443],
                [106.8638292, 11.3144024],
                [106.8636388, 11.314337],
                [106.8635402, 11.3143157],
                [106.8633394, 11.3142965],
                [106.8631094, 11.3142966],
                [106.8627079, 11.3142678],
                [106.862419, 11.3142784],
                [106.8622756, 11.3142963],
                [106.861999, 11.3143531],
                [106.8617345, 11.3144307],
                [106.8614403, 11.3145304],
                [106.8612733, 11.3145764],
                [106.8611046, 11.3146153],
                [106.8606082, 11.3147024],
                [106.8603041, 11.314784],
                [106.8600807, 11.3148606],
                [106.8600091, 11.3148932],
                [106.8598716, 11.3149829],
                [106.8597375, 11.3150928],
                [106.859577, 11.3151816],
                [106.8594024, 11.3152394],
                [106.8593118, 11.3152561],
                [106.8592415, 11.3152599],
                [106.8591021, 11.315244],
                [106.8589698, 11.3151978],
                [106.8588515, 11.3151237],
                [106.8587032, 11.315001],
                [106.8586015, 11.3149319],
                [106.8584948, 11.3148704],
                [106.8582248, 11.3147341],
                [106.8578011, 11.3144885],
                [106.857623, 11.3144017],
                [106.8575603, 11.3143803],
                [106.8575001, 11.3143683],
                [106.8573774, 11.3143677],
                [106.8573171, 11.314379],
                [106.8572034, 11.314424],
                [106.8571052, 11.314496],
                [106.8570641, 11.3145407],
                [106.8570367, 11.3145807],
                [106.8569982, 11.3146692],
                [106.8569836, 11.3147643],
                [106.8569775, 11.3149021],
                [106.8569923, 11.3151775],
                [106.8570408, 11.3155463],
                [106.8570587, 11.3157795],
                [106.8570787, 11.3159183],
                [106.8571055, 11.3160559],
                [106.8572109, 11.3164614],
                [106.8572339, 11.3165978],
                [106.8572545, 11.3168736],
                [106.8572491, 11.3169855],
                [106.8572344, 11.3170967],
                [106.8572107, 11.3172063],
                [106.8571779, 11.3173136],
                [106.8568292, 11.3181308],
                [106.8566537, 11.3186068],
                [106.8565779, 11.3188515],
                [106.8565117, 11.3190989],
                [106.856432, 11.3194707],
                [106.8563694, 11.3197114],
                [106.8562726, 11.3199772],
                [106.8560809, 11.3203788],
                [106.8559617, 11.3206793],
                [106.8559185, 11.3208177],
                [106.8558544, 11.3211001],
                [106.8558259, 11.3211961],
                [106.8557904, 11.3212898],
                [106.8556588, 11.3215565],
                [106.8556262, 11.3216476],
                [106.8556013, 11.321741],
                [106.8555754, 11.3219323],
                [106.8555745, 11.3220288],
                [106.855588, 11.322173],
                [106.8556204, 11.3223077],
                [106.8556527, 11.3223921],
                [106.8557615, 11.3225969],
                [106.8559987, 11.3229734],
                [106.8560792, 11.3231138],
                [106.8562517, 11.3234525],
                [106.8563425, 11.3236512],
                [106.8564768, 11.3238926],
                [106.8565518, 11.3240089],
                [106.8566517, 11.3242012],
                [106.8567122, 11.3243519],
                [106.8567532, 11.324503],
                [106.8567683, 11.3246044],
                [106.8567739, 11.3247067],
                [106.85677, 11.3248091],
                [106.8567566, 11.3249107],
                [106.8567339, 11.3250107],
                [106.856702, 11.3251082],
                [106.8566388, 11.325212],
                [106.8565203, 11.3253365],
                [106.8564778, 11.3254265],
                [106.8564446, 11.3257076],
                [106.8563764, 11.3260081],
                [106.8563525, 11.3262547],
                [106.8563538, 11.3263786],
                [106.8563787, 11.3266169],
                [106.856423, 11.326844],
                [106.8564289, 11.3269242],
                [106.8564254, 11.3270046],
                [106.8564127, 11.327084],
                [106.8563909, 11.3271616],
                [106.8563418, 11.3272721],
                [106.8562761, 11.3273689],
                [106.8560962, 11.3275435],
                [106.8559755, 11.3276955],
                [106.8558056, 11.3279998],
                [106.8555752, 11.3283532],
                [106.8554681, 11.3285482],
                [106.8554126, 11.3287176],
                [106.8553925, 11.3288946],
                [106.8554089, 11.3290718],
                [106.8554306, 11.3291584],
                [106.8555262, 11.3294488],
                [106.8555862, 11.3295897],
                [106.8557822, 11.3299912],
                [106.8558872, 11.3301555],
                [106.8560251, 11.3303159],
                [106.8561026, 11.3303882],
                [106.856228, 11.3304867],
                [106.8563395, 11.3305504],
                [106.8565456, 11.3306266],
                [106.8566609, 11.3307014],
                [106.8567554, 11.3308004],
                [106.8568735, 11.3309886],
                [106.8569469, 11.3311237],
                [106.857164, 11.3316078],
                [106.8573183, 11.3320158],
                [106.8574691, 11.3324878],
                [106.857723, 11.3333915],
                [106.8578733, 11.3338256],
                [106.8580372, 11.3342306],
                [106.8582094, 11.3346075],
                [106.8583814, 11.3350605],
                [106.8584889, 11.3352626],
                [106.8585861, 11.3354045],
                [106.8586896, 11.3355196],
                [106.8587634, 11.3355832],
                [106.8588432, 11.3356396],
                [106.8589282, 11.335688],
                [106.8590176, 11.3357281],
                [106.8592306, 11.3357916],
                [106.8593526, 11.3358153],
                [106.8596003, 11.3358367],
                [106.8597246, 11.3358344],
                [106.8599713, 11.3358035],
                [106.860343, 11.3357107],
                [106.8608379, 11.3355596],
                [106.8610566, 11.3354802],
                [106.8611276, 11.3354491],
                [106.861251, 11.3353807],
                [106.8612922, 11.3353693],
                [106.8613775, 11.3353718],
                [106.861418, 11.3353855],
                [106.8614845, 11.3354325],
                [106.8615281, 11.3354966],
                [106.8617499, 11.3360435],
                [106.8618688, 11.3363075],
                [106.8622649, 11.3369639],
                [106.8624955, 11.3373321],
                [106.8625759, 11.3374788],
                [106.8626475, 11.3376297],
                [106.8627689, 11.3379116],
                [106.8629866, 11.338319],
                [106.8630617, 11.3384839],
                [106.8631285, 11.3386523],
                [106.8631744, 11.3387906],
                [106.8632442, 11.3390731],
                [106.8632845, 11.3393611],
                [106.8632948, 11.3396516],
                [106.8632897, 11.3398871],
                [106.8632754, 11.3401221],
                [106.8632304, 11.3405277],
                [106.8632036, 11.3408719],
                [106.8631986, 11.3410367],
                [106.8632036, 11.3413663],
                [106.8631996, 11.3415464],
                [106.8631858, 11.3417259],
                [106.8631481, 11.3419936],
                [106.8630957, 11.3421918],
                [106.8630542, 11.342299],
                [106.8630042, 11.3424028],
                [106.86287, 11.3426433],
                [106.8627047, 11.3429179],
                [106.8625945, 11.3430821],
                [106.8623556, 11.3433979],
                [106.8621117, 11.3436946],
                [106.8618947, 11.3439961],
                [106.861728, 11.344185],
                [106.8616354, 11.344271],
                [106.8614341, 11.3444241],
                [106.8610853, 11.3446348],
                [106.86072, 11.3448444],
                [106.860391, 11.3450153],
                [106.860182, 11.3451123],
                [106.8597543, 11.3452847],
                [106.8593505, 11.3454181],
                [106.8590427, 11.3455027],
                [106.8587871, 11.3455556],
                [106.85848, 11.3455925],
                [106.8581708, 11.3456013],
                [106.8580162, 11.345595],
                [106.8579134, 11.345581],
                [106.8577127, 11.3455294],
                [106.8576161, 11.3454922],
                [106.8573774, 11.3453703],
                [106.8572968, 11.3453597],
                [106.8572168, 11.3453739],
                [106.8571201, 11.345423],
                [106.8570657, 11.3454635],
                [106.8568253, 11.3457265],
                [106.8567452, 11.3458511],
                [106.856696, 11.3459903],
                [106.8566806, 11.3461368],
                [106.8567052, 11.3463846],
                [106.8567342, 11.3465575],
                [106.8568062, 11.3468143],
                [106.856911, 11.34706],
                [106.8569752, 11.3471774],
                [106.8571258, 11.3473991],
                [106.8572505, 11.3475242],
                [106.8573956, 11.3476261],
                [106.8575953, 11.347739],
                [106.857711, 11.3478183],
                [106.8578211, 11.347905],
                [106.8579995, 11.3480753],
                [106.858217, 11.3483341],
                [106.8583067, 11.3484213],
                [106.8584027, 11.3485017],
                [106.8585045, 11.3485748],
                [106.8586117, 11.3486403],
                [106.858709, 11.3486917],
                [106.8588601, 11.3487587],
                [106.8590001, 11.3488005],
                [106.85909, 11.3488153],
                [106.8592721, 11.3488182],
                [106.8594512, 11.3487857],
                [106.8595374, 11.3487566],
                [106.8597152, 11.3486768],
                [106.8598386, 11.3486463],
                [106.8599884, 11.348642],
                [106.8600993, 11.3486609],
                [106.8601948, 11.3486972],
                [106.860301, 11.3487701],
                [106.8603457, 11.3488164],
                [106.8604141, 11.3489243],
                [106.8604449, 11.3489959],
                [106.8604841, 11.3491463],
                [106.8604923, 11.3493013],
                [106.8604693, 11.3494548],
                [106.8604058, 11.349636],
                [106.8603015, 11.3498395],
                [106.8601485, 11.3500756],
                [106.8599743, 11.3502971],
                [106.8598479, 11.3504393],
                [106.8596494, 11.3506447],
                [106.8594996, 11.3507635],
                [106.8593756, 11.3508372],
                [106.8593305, 11.3508546],
                [106.8592323, 11.3508667],
                [106.8591831, 11.3508605],
                [106.8590911, 11.3508246],
                [106.8590165, 11.3507609],
                [106.8589675, 11.3506765],
                [106.8589379, 11.3505312],
                [106.8589194, 11.3504834],
                [106.8588248, 11.3503334],
                [106.8587928, 11.3502661],
                [106.8587278, 11.350059],
                [106.8586811, 11.3499669],
                [106.85866, 11.3499394],
                [106.8585931, 11.3498907],
                [106.8585121, 11.3498715],
                [106.8584298, 11.349885],
                [106.8583925, 11.3499035],
                [106.8580544, 11.350138],
                [106.8579191, 11.3502511],
                [106.8578188, 11.3503555],
                [106.8577662, 11.3504235],
                [106.8576138, 11.3506736],
                [106.8575422, 11.3507719],
                [106.8571016, 11.3513169],
                [106.8560227, 11.3525758],
                [106.8559152, 11.3527576],
                [106.8558263, 11.3529678],
                [106.8557909, 11.3530854],
                [106.8557644, 11.3532052],
                [106.8557471, 11.3533265],
                [106.8557437, 11.3534152],
                [106.8557598, 11.3535917],
                [106.8558061, 11.3537631],
                [106.8559134, 11.3540523],
                [106.8559482, 11.3541643],
                [106.855976, 11.3542782],
                [106.8560276, 11.3546005],
                [106.8560514, 11.3546897],
                [106.856151, 11.3549646],
                [106.8561757, 11.3550693],
                [106.8562149, 11.3554319],
                [106.8563079, 11.3558711],
                [106.8563695, 11.3563095],
                [106.8563963, 11.3564516],
                [106.8565732, 11.3571817],
                [106.856702, 11.3576445],
                [106.8567275, 11.3578458],
                [106.8567247, 11.3580486],
                [106.8567127, 11.3581494],
                [106.8566639, 11.358296],
                [106.8566201, 11.3583574],
                [106.8565466, 11.3584078],
                [106.856504, 11.3584219],
                [106.8564143, 11.3584256],
                [106.8563706, 11.358415],
                [106.8562931, 11.3583708],
                [106.8561119, 11.358181],
                [106.8560367, 11.3580926],
                [106.8558973, 11.3579075],
                [106.8558016, 11.3578208],
                [106.8556889, 11.3577567],
                [106.8555647, 11.3577181],
                [106.8554419, 11.3577136],
                [106.8553226, 11.3577424],
                [106.8552407, 11.3577842],
                [106.8551799, 11.3578373],
                [106.8551281, 11.3579227],
                [106.8549746, 11.3584334],
                [106.8548083, 11.3590225],
                [106.8546367, 11.3597272],
                [106.8544479, 11.3604227],
                [106.8543945, 11.3606926],
                [106.8543616, 11.3610185],
                [106.8543631, 11.3610841],
                [106.8543725, 11.361151],
                [106.8544149, 11.3612793],
                [106.8544865, 11.3613944],
                [106.8545678, 11.3614596],
                [106.8546665, 11.3614947],
                [106.854719, 11.3614995],
                [106.8548227, 11.3614831],
                [106.8549149, 11.361434],
                [106.8549534, 11.3613987],
                [106.8550487, 11.3612873],
                [106.8551942, 11.3611658],
                [106.8555835, 11.3609183],
                [106.8557878, 11.3607629],
                [106.8559812, 11.3605758],
                [106.8561838, 11.3603501],
                [106.8563633, 11.3601708],
                [106.8564956, 11.3600699],
                [106.8566415, 11.3599956],
                [106.8567985, 11.3599481],
                [106.8569617, 11.3599312],
                [106.857125, 11.3599462],
                [106.8572822, 11.3599926],
                [106.8574853, 11.3600789],
                [106.8577319, 11.3602058],
                [106.8579568, 11.3603586],
                [106.8581611, 11.3605371],
                [106.8582736, 11.360674],
                [106.8583216, 11.3607483],
                [106.8583997, 11.3609064],
                [106.8584737, 11.3611346],
                [106.8585531, 11.361504],
                [106.8585688, 11.3616521],
                [106.858556, 11.3618344],
                [106.8585083, 11.362011],
                [106.8584276, 11.3621757],
                [106.8583757, 11.3622517],
                [106.8582927, 11.3623322],
                [106.8581937, 11.3623928],
                [106.8580597, 11.3624554],
                [106.8579846, 11.362504],
                [106.8579151, 11.3625602],
                [106.8578521, 11.3626233],
                [106.8577962, 11.3626925],
                [106.857748, 11.3627671],
                [106.8577168, 11.3628307],
                [106.8576753, 11.3629657],
                [106.8576629, 11.3631061],
                [106.8576679, 11.3631766],
                [106.8577473, 11.3635024],
                [106.8577627, 11.3635983],
                [106.8577856, 11.3636927],
                [106.8578504, 11.3638693],
                [106.8579697, 11.3640993],
                [106.8580052, 11.3642357],
                [106.8580109, 11.3643764],
                [106.8580013, 11.3644688],
                [106.8579823, 11.3645598],
                [106.8579542, 11.3646485],
                [106.8579171, 11.3647339],
                [106.8578715, 11.3648153],
                [106.8575978, 11.3652284],
                [106.8575292, 11.3653214],
                [106.8573779, 11.3654966],
                [106.857326, 11.3655679],
                [106.8572438, 11.3657231],
                [106.8571925, 11.3658907],
                [106.857174, 11.3660646],
                [106.857175, 11.3661915],
                [106.8571857, 11.366318],
                [106.8572339, 11.3665862],
                [106.8573028, 11.3668693],
                [106.8573234, 11.3669892],
                [106.8573348, 11.3671102],
                [106.8573368, 11.3672318],
                [106.8573153, 11.3676489],
                [106.8573159, 11.3678822],
                [106.8573373, 11.3681507],
                [106.8573564, 11.368284],
                [106.85736, 11.3683824],
                [106.8573406, 11.3685783],
                [106.8572867, 11.3687678],
                [106.8572162, 11.3689005],
                [106.8571205, 11.3690171],
                [106.8570033, 11.3691128],
                [106.8568412, 11.3692014],
                [106.8567409, 11.3692438],
                [106.8565319, 11.3693056],
                [106.8564244, 11.3693247],
                [106.855977, 11.369374],
                [106.8557085, 11.3694303],
                [106.8556344, 11.3694621],
                [106.8555683, 11.369522],
                [106.8555442, 11.3695595],
                [106.8555179, 11.3696439],
                [106.8555172, 11.3698435],
                [106.8555272, 11.3699985],
                [106.8555456, 11.370124],
                [106.8555733, 11.3702479],
                [106.8556099, 11.3703694],
                [106.8556554, 11.3704881],
                [106.8557771, 11.3707267],
                [106.8559354, 11.3709598],
                [106.8562309, 11.3712939],
                [106.8565282, 11.3716595],
                [106.8566288, 11.3717637],
                [106.8568468, 11.3719549],
                [106.8569015, 11.3720147],
                [106.8569916, 11.3721487],
                [106.857053, 11.3722975],
                [106.8570721, 11.3723756],
                [106.8570152, 11.3724297],
                [106.8569824, 11.3725005],
                [106.8569782, 11.3725781],
                [106.8569968, 11.3726747],
                [106.8569962, 11.3727239],
                [106.8569743, 11.3728532],
                [106.856978, 11.3728942],
                [106.856989, 11.3729338],
                [106.8570314, 11.3730043],
                [106.8570614, 11.373033],
                [106.8571084, 11.3730623],
                [106.8571759, 11.3731292],
                [106.8572066, 11.3732023],
                [106.8572051, 11.3732813],
                [106.8571717, 11.3733532],
                [106.8571169, 11.3734032],
                [106.8569607, 11.3734571],
                [106.8569064, 11.3734883],
                [106.8568112, 11.3735687],
                [106.8566943, 11.3737291],
                [106.8566131, 11.3738132],
                [106.8565544, 11.3738902],
                [106.8565134, 11.373979],
                [106.8564928, 11.3740743],
                [106.8565085, 11.3741881],
                [106.8565571, 11.3742925],
                [106.8566289, 11.3743739],
                [106.8567679, 11.3744702],
                [106.8568012, 11.3745095],
                [106.8568184, 11.3745532],
                [106.8568264, 11.3746396],
                [106.8568146, 11.3747369],
                [106.8567717, 11.3748372],
                [106.856701, 11.374921],
                [106.8566086, 11.374981],
                [106.8565566, 11.3750005],
                [106.8565023, 11.3750123],
                [106.8563914, 11.375012],
                [106.8562724, 11.374979],
                [106.8562101, 11.3749517],
                [106.8560958, 11.3748789],
                [106.8559465, 11.3747572],
                [106.8558698, 11.3747183],
                [106.8557763, 11.3747023],
                [106.8556824, 11.3747158],
                [106.8555976, 11.3747576],
                [106.8555614, 11.3747878],
                [106.8555305, 11.3748233],
                [106.8554541, 11.3749562],
                [106.8554091, 11.3750525],
                [106.8553887, 11.3751189],
                [106.8553703, 11.3752563],
                [106.855391, 11.3755046],
                [106.8553883, 11.3756706],
                [106.8553664, 11.3758104],
                [106.855311, 11.375974],
                [106.855272, 11.3760513],
                [106.8551731, 11.3761989],
                [106.8550122, 11.3763935],
                [106.8549436, 11.3764586],
                [106.8547932, 11.3765736],
                [106.8546276, 11.3766664],
                [106.8545401, 11.3767038],
                [106.8541923, 11.376559],
                [106.8541204, 11.3765379],
                [106.8540466, 11.376525],
                [106.8539109, 11.3765233],
                [106.8537777, 11.3765493],
                [106.8535659, 11.3766221],
                [106.853238, 11.3767148],
                [106.8529684, 11.3767784],
                [106.8527712, 11.3768019],
                [106.8525591, 11.3767924],
                [106.8524544, 11.3767742],
                [106.8522518, 11.3767117],
                [106.8520632, 11.376616],
                [106.8518253, 11.3764622],
                [106.8516701, 11.3763751],
                [106.8514241, 11.3762715],
                [106.8512962, 11.3762323],
                [106.8511659, 11.3762018],
                [106.8510337, 11.3761801],
                [106.8504939, 11.376138],
                [106.8502915, 11.3761148],
                [106.8500522, 11.3760798],
                [106.849578, 11.375986],
                [106.8494049, 11.3759599],
                [106.8492307, 11.375943],
                [106.8490557, 11.3759353],
                [106.8488806, 11.3759369],
                [106.8486188, 11.3759563],
                [106.848404, 11.3759889],
                [106.8482772, 11.3760157],
                [106.8480277, 11.3760859],
                [106.8479382, 11.3761033],
                [106.8478476, 11.3761131],
                [106.8476654, 11.3761093],
                [106.8474866, 11.3760747],
                [106.8474001, 11.3760461],
                [106.8473165, 11.3760103],
                [106.8469763, 11.3758203],
                [106.8468965, 11.3757849],
                [106.8467723, 11.3757427],
                [106.8466714, 11.3757278],
                [106.8465554, 11.375739],
                [106.8464593, 11.375732],
                [106.8463111, 11.3756678],
                [106.8462107, 11.375636],
                [106.8461079, 11.375613],
                [106.8457716, 11.375582],
                [106.8455218, 11.3755372],
                [106.8452315, 11.3754533],
                [106.844924, 11.3753502],
                [106.8447798, 11.3753118],
                [106.8444872, 11.3752523],
                [106.8440972, 11.3751592],
                [106.8439361, 11.3751329],
                [106.8434896, 11.3750893],
                [106.8428057, 11.3750354],
                [106.8420076, 11.3749906],
                [106.8417271, 11.3749954],
                [106.84158, 11.3750093],
                [106.8414339, 11.375031],
                [106.8411463, 11.3750974],
                [106.8407693, 11.3752249],
                [106.8405365, 11.375317],
                [106.8403076, 11.3754181],
                [106.8399715, 11.3755847],
                [106.8398631, 11.3756467],
                [106.8397364, 11.3757291],
                [106.8396152, 11.375819],
                [106.8395, 11.3759162],
                [106.8391428, 11.3762772],
                [106.8389902, 11.3764175],
                [106.8387505, 11.376616],
                [106.838556, 11.376751],
                [106.8384412, 11.3768185],
                [106.8383228, 11.3768797],
                [106.8381397, 11.3769604],
                [106.8379897, 11.377006],
                [106.8379009, 11.377022],
                [106.8377208, 11.3770311],
                [106.8376309, 11.3770243],
                [106.8373504, 11.376969],
                [106.8372453, 11.3769578],
                [106.8370339, 11.3769585],
                [106.8368249, 11.3769897],
                [106.836481, 11.3770916],
                [106.8361908, 11.3771469],
                [106.8355179, 11.3771982],
                [106.8355863, 11.3775933],
                [106.8356091, 11.3777924],
                [106.8356251, 11.3780077],
                [106.8356305, 11.3784393],
                [106.8356462, 11.3787793],
                [106.8357015, 11.3792577],
                [106.8357039, 11.3793212],
                [106.8356818, 11.3794032],
                [106.8356288, 11.3794778],
                [106.8342704, 11.3805518],
                [106.8338588, 11.3805587],
                [106.8330644, 11.3805524],
                [106.8317894, 11.3805052],
                [106.8307616, 11.3804529],
                [106.8302695, 11.3804489],
                [106.8295719, 11.38047],
                [106.8281954, 11.3805586],
                [106.8274981, 11.3805902],
                [106.8267371, 11.380636],
                [106.8255347, 11.3826833],
                [106.825465, 11.3828161],
                [106.8253377, 11.3830985],
                [106.8252277, 11.3833984],
                [106.8251806, 11.383551],
                [106.8251343, 11.3837237],
                [106.8250462, 11.38418],
                [106.8246732, 11.3839214],
                [106.8243374, 11.3836521],
                [106.8240374, 11.383377],
                [106.8234995, 11.3828114],
                [106.8232985, 11.3826138],
                [106.8230917, 11.3824221],
                [106.8219689, 11.3814492],
                [106.8215771, 11.3811513],
                [106.8210012, 11.380766],
                [106.8207006, 11.3805345],
                [106.8204311, 11.3802964],
                [106.8201922, 11.3800543],
                [106.8200791, 11.3799276],
                [106.819977, 11.3798283],
                [106.8198687, 11.3797357],
                [106.8197545, 11.37965],
                [106.8196349, 11.3795717],
                [106.8195105, 11.3795011],
                [106.8193817, 11.3794385],
                [106.8191616, 11.3793059],
                [106.8189586, 11.3791492],
                [106.8186855, 11.3788879],
                [106.8184912, 11.378739],
                [106.8184314, 11.3786817],
                [106.8183787, 11.3786181],
                [106.8183336, 11.378549],
                [106.8182968, 11.3784754],
                [106.8182568, 11.3783588],
                [106.8182498, 11.3783183],
                [106.8182482, 11.3782648],
                [106.8182706, 11.3781604],
                [106.8183249, 11.3780679],
                [106.8183625, 11.3780291],
                [106.8183928, 11.3779626],
                [106.8183951, 11.3778898],
                [106.818369, 11.3778215],
                [106.818317, 11.3777649],
                [106.8182465, 11.3777258],
                [106.8182074, 11.3777153],
                [106.8180836, 11.3777084],
                [106.8180043, 11.3776756],
                [106.8179708, 11.3776489],
                [106.8179214, 11.3775799],
                [106.8178856, 11.3774531],
                [106.8178616, 11.377412],
                [106.81783, 11.3773763],
                [106.8177919, 11.3773471],
                [106.8177489, 11.3773256],
                [106.8177025, 11.3773126],
                [106.8173164, 11.3772823],
                [106.817107, 11.3772782],
                [106.8168977, 11.3772835],
                [106.8166888, 11.3772981],
                [106.8164888, 11.3772823],
                [106.8163903, 11.3772636],
                [106.8161988, 11.377205],
                [106.8160182, 11.3771193],
                [106.815832, 11.3770502],
                [106.8156366, 11.3770126],
                [106.8154375, 11.3770075],
                [106.8151301, 11.3770462],
                [106.8150192, 11.3770478],
                [106.8149086, 11.37704],
                [106.8147991, 11.3770228],
                [106.8146915, 11.3769965],
                [106.8145867, 11.3769611],
                [106.8144853, 11.3769169],
                [106.8142003, 11.3767515],
                [106.8141003, 11.3767073],
                [106.8139968, 11.3766715],
                [106.8137825, 11.376626],
                [106.8136731, 11.3766166],
                [106.813454, 11.3766249],
                [106.8132554, 11.3766148],
                [106.8131575, 11.3765973],
                [106.8129681, 11.3765382],
                [106.8127916, 11.3764484],
                [106.8125281, 11.3762543],
                [106.8123041, 11.3761226],
                [106.8120646, 11.3760201],
                [106.8116906, 11.3758863],
                [106.8116004, 11.3758427],
                [106.8115515, 11.3758307],
                [106.8114476, 11.3758278],
                [106.811344, 11.3758559],
                [106.8112537, 11.375913],
                [106.8111516, 11.3760265],
                [106.8111135, 11.3760538],
                [106.8110269, 11.3760896],
                [106.8109333, 11.376098],
                [106.8108866, 11.3760915],
                [106.8106538, 11.3760307],
                [106.8105555, 11.3760172],
                [106.8104563, 11.3760129],
                [106.8103572, 11.3760181],
                [106.810259, 11.3760325],
                [106.8101154, 11.3760711],
                [106.8099851, 11.3761273],
                [106.8098306, 11.3762279],
                [106.8096988, 11.3763558],
                [106.809643, 11.3764285],
                [106.8095541, 11.3765881],
                [106.8095173, 11.3766405],
                [106.8094254, 11.3767298],
                [106.8093138, 11.376794],
                [106.8092528, 11.3768155],
                [106.809038, 11.3768514],
                [106.8089533, 11.3768764],
                [106.8088718, 11.3769101],
                [106.8087944, 11.376952],
                [106.808722, 11.3770018],
                [106.8086554, 11.3770588],
                [106.8085953, 11.3771224],
                [106.8085424, 11.3771919],
                [106.8084973, 11.3772665],
                [106.8084098, 11.3773656],
                [106.8083026, 11.3774439],
                [106.8080904, 11.377541],
                [106.8080047, 11.3775925],
                [106.8079245, 11.377652],
                [106.8078507, 11.3777188],
                [106.8077838, 11.3777924],
                [106.8076634, 11.3778808],
                [106.8075959, 11.3779138],
                [106.8074514, 11.3779547],
                [106.8071994, 11.377967],
                [106.8070987, 11.3779826],
                [106.8070001, 11.3780076],
                [106.8069043, 11.3780415],
                [106.8068122, 11.3780842],
                [106.8067246, 11.3781353],
                [106.8064737, 11.3783304],
                [106.8063763, 11.3783935],
                [106.8061688, 11.3784979],
                [106.805948, 11.3785715],
                [106.8057185, 11.3786128],
                [106.8055158, 11.378663],
                [106.8053216, 11.3787388],
                [106.8050323, 11.3788919],
                [106.8049214, 11.3789363],
                [106.8048072, 11.3789718],
                [106.8046905, 11.3789982],
                [106.804572, 11.3790153],
                [106.8044525, 11.379023],
                [106.8042282, 11.3790621],
                [106.8041188, 11.3790933],
                [106.8039083, 11.3791786],
                [106.8037124, 11.3792926],
                [106.8035352, 11.3794329],
                [106.8032505, 11.3797331],
                [106.8029291, 11.3801113],
                [106.8028232, 11.3802484],
                [106.8026984, 11.3803693],
                [106.8026126, 11.380435],
                [106.8025212, 11.380493],
                [106.8024248, 11.3805428],
                [106.8023244, 11.3805841],
                [106.8022206, 11.3806164],
                [106.8019156, 11.380673],
                [106.8017405, 11.3807336],
                [106.8016572, 11.3807741],
                [106.8013983, 11.3809383],
                [106.8012903, 11.3809954],
                [106.8010636, 11.3810871],
                [106.8006281, 11.3811982],
                [106.800448, 11.3812186],
                [106.8004831, 11.3812429],
                [106.8005377, 11.3813077],
                [106.8005554, 11.3813461],
                [106.800566, 11.3813869],
                [106.8005647, 11.3814703],
                [106.8005529, 11.3815105],
                [106.800509, 11.3815819],
                [106.8003514, 11.3817025],
                [106.8003342, 11.3817302],
                [106.8003235, 11.3817938],
                [106.8003306, 11.3818255],
                [106.8003677, 11.3818788],
                [106.8004594, 11.3819271],
                [106.8005079, 11.3819849],
                [106.8005211, 11.3820203],
                [106.8005221, 11.3820952],
                [106.8005098, 11.3821309],
                [106.8004628, 11.38219],
                [106.8003542, 11.382245],
                [106.8002887, 11.3823078],
                [106.8002655, 11.3823466],
                [106.8002415, 11.3824333],
                [106.8002415, 11.3824783],
                [106.8002656, 11.3825649],
                [106.8002712, 11.382642],
                [106.8002635, 11.38268],
                [106.8002281, 11.382749],
                [106.7985544, 11.3825281],
                [106.798616, 11.381642],
                [106.7974788, 11.3816367],
                [106.797055, 11.3807112],
                [106.7965507, 11.3806954],
                [106.7961752, 11.3795437],
                [106.7936754, 11.3795437],
                [106.7936057, 11.383004],
                [106.7894053, 11.3829252],
                [106.7892873, 11.3859017],
                [106.7884826, 11.3858807],
                [106.7885899, 11.3896091],
                [106.7886541, 11.3915445],
                [106.7887628, 11.3944275],
                [106.7888984, 11.3973026],
                [106.7889601, 11.3992378],
                [106.7890352, 11.4012203],
                [106.7811351, 11.4012588],
                [106.7809188, 11.4012676],
                [106.7807035, 11.401286],
                [106.7747175, 11.4020669],
                [106.7744628, 11.4021221],
                [106.7742758, 11.4021802],
                [106.7700366, 11.4038239],
                [106.7694551, 11.4040494],
                [106.7697228, 11.4046754],
                [106.7699647, 11.4053114],
                [106.7700763, 11.4056343],
                [106.7702794, 11.4062863],
                [106.7703708, 11.4066152],
                [106.7706208, 11.4076169],
                [106.7707162, 11.4079537],
                [106.7709299, 11.4086206],
                [106.7710483, 11.4089503],
                [106.7711177, 11.4091774],
                [106.7711783, 11.4094071],
                [106.7712299, 11.4096388],
                [106.7712724, 11.4098722],
                [106.7713058, 11.4101071],
                [106.7713241, 11.4103001],
                [106.7713361, 11.4106875],
                [106.7713155, 11.4110746],
                [106.7712624, 11.4114587],
                [106.7711546, 11.4119638],
                [106.77113, 11.4122197],
                [106.7711281, 11.4123483],
                [106.7711733, 11.4133433],
                [106.7711946, 11.414082],
                [106.7712161, 11.4155597],
                [106.7712092, 11.4170376],
                [106.7711703, 11.4186234],
                [106.7710988, 11.4202081],
                [106.7710508, 11.4209998],
                [106.7709428, 11.4224365],
                [106.770823, 11.4237261],
                [106.7707461, 11.4248076],
                [106.7707044, 11.4258911],
                [106.7706967, 11.4264331],
                [106.7708068, 11.4265227],
                [106.7710429, 11.4266811],
                [106.7711725, 11.4267515],
                [106.7713063, 11.4268138],
                [106.7714439, 11.4268675],
                [106.7715847, 11.4269125],
                [106.7718208, 11.4270124],
                [106.7719799, 11.4270528],
                [106.7720618, 11.4270608],
                [106.7722259, 11.4270521],
                [106.7723064, 11.4270354],
                [106.7724601, 11.4269782],
                [106.7725986, 11.4268914],
                [106.7727271, 11.4267945],
                [106.7727863, 11.42674],
                [106.7729211, 11.426649],
                [106.773073, 11.4265856],
                [106.7731531, 11.4265656],
                [106.7733175, 11.4265496],
                [106.77341, 11.4265543],
                [106.7736859, 11.4266075],
                [106.773878, 11.4266127],
                [106.7740678, 11.4265828],
                [106.7741597, 11.4265549],
                [106.774304, 11.4265392],
                [106.7744482, 11.4265558],
                [106.7745848, 11.4266039],
                [106.7746481, 11.4266391],
                [106.7747869, 11.4267459],
                [106.7749321, 11.4268914],
                [106.7749783, 11.4269258],
                [106.7750824, 11.4269754],
                [106.775196, 11.4269969],
                [106.7753305, 11.427003],
                [106.7755396, 11.4270473],
                [106.7756611, 11.4270418],
                [106.7757771, 11.4270062],
                [106.7758801, 11.4269428],
                [106.7759246, 11.4269019],
                [106.7759945, 11.4268066],
                [106.7760627, 11.4266432],
                [106.7761346, 11.4265484],
                [106.7761797, 11.426509],
                [106.7762298, 11.4264759],
                [106.776365, 11.4264194],
                [106.776449, 11.4263977],
                [106.7765348, 11.4263851],
                [106.7766216, 11.4263817],
                [106.7767081, 11.4263875],
                [106.7767936, 11.4264024],
                [106.7769376, 11.4264466],
                [106.7770725, 11.4265128],
                [106.7772468, 11.4266213],
                [106.77733, 11.4266811],
                [106.7773938, 11.4267148],
                [106.7775309, 11.4267599],
                [106.7776748, 11.4267734],
                [106.7779374, 11.4267385],
                [106.7780573, 11.4267297],
                [106.7783004, 11.4267361],
                [106.7784222, 11.4267527],
                [106.7785425, 11.4267782],
                [106.7786604, 11.4268126],
                [106.7788668, 11.4268477],
                [106.7789715, 11.426854],
                [106.7792698, 11.4268457],
                [106.7793584, 11.4268555],
                [106.7795306, 11.4268995],
                [106.7796913, 11.4269745],
                [106.7798943, 11.4270997],
                [106.7800268, 11.4271701],
                [106.7805476, 11.4273981],
                [106.7807901, 11.4275119],
                [106.7810227, 11.4275959],
                [106.7812622, 11.4276591],
                [106.7813253, 11.427685],
                [106.7814409, 11.4277564],
                [106.7814919, 11.4278011],
                [106.7815376, 11.427851],
                [106.7816448, 11.4280055],
                [106.7817295, 11.4280715],
                [106.7818302, 11.4281104],
                [106.7818838, 11.4281185],
                [106.7819917, 11.4281113],
                [106.7820935, 11.4281205],
                [106.7821877, 11.4281593],
                [106.7822291, 11.4281887],
                [106.7822961, 11.4282644],
                [106.7823366, 11.4283563],
                [106.7823647, 11.4284685],
                [106.7824252, 11.4285849],
                [106.7825128, 11.4286833],
                [106.7826222, 11.4287579],
                [106.7827468, 11.4288043],
                [106.7829942, 11.4288449],
                [106.7833596, 11.4288895],
                [106.7835017, 11.4289153],
                [106.7836419, 11.4289499],
                [106.783914, 11.4290447],
                [106.784045, 11.4291046],
                [106.784294, 11.4292481],
                [106.7844111, 11.4293312],
                [106.7844876, 11.4293974],
                [106.7846232, 11.4295467],
                [106.7846814, 11.4296289],
                [106.7847768, 11.4298056],
                [106.7848834, 11.4300597],
                [106.7849611, 11.4302169],
                [106.7851389, 11.4305196],
                [106.7852386, 11.4306644],
                [106.7854585, 11.4309393],
                [106.7855781, 11.4310687],
                [106.7858366, 11.4313119],
                [106.7861179, 11.4315325],
                [106.7864182, 11.4317273],
                [106.7867353, 11.4318949],
                [106.7872737, 11.4321241],
                [106.787477, 11.4322227],
                [106.7876761, 11.4323294],
                [106.7878705, 11.4324439],
                [106.78806, 11.4325661],
                [106.7882442, 11.4326959],
                [106.7884229, 11.4328329],
                [106.7885957, 11.4329769],
                [106.7887623, 11.4331278],
                [106.7892003, 11.4335484],
                [106.7897741, 11.4340556],
                [106.7903709, 11.4345366],
                [106.7907513, 11.4348248],
                [106.7911376, 11.4351052],
                [106.7914029, 11.4352815],
                [106.7915296, 11.4353779],
                [106.7916708, 11.4354957],
                [106.7918059, 11.4356203],
                [106.7920566, 11.4358881],
                [106.7922791, 11.4361789],
                [106.7924714, 11.4364897],
                [106.7925556, 11.4366516],
                [106.7927799, 11.4371693],
                [106.7928678, 11.4373486],
                [106.7929626, 11.4375244],
                [106.7931726, 11.4378648],
                [106.7934086, 11.4381884],
                [106.7937483, 11.4386105],
                [106.7939551, 11.4388819],
                [106.7946242, 11.4398163],
                [106.7949032, 11.4401914],
                [106.7956897, 11.4412227],
                [106.7960167, 11.4416684],
                [106.7964106, 11.4421661],
                [106.7968781, 11.4427093],
                [106.7973694, 11.4432321],
                [106.7976236, 11.4434855],
                [106.7980013, 11.4438962],
                [106.7983562, 11.4443259],
                [106.7991841, 11.4453882],
                [106.7993832, 11.4456201],
                [106.7995117, 11.4457512],
                [106.7997881, 11.4459936],
                [106.7999351, 11.4461044],
                [106.8000876, 11.4462079],
                [106.8002452, 11.4463038],
                [106.8004075, 11.4463918],
                [106.8007824, 11.4465655],
                [106.8012071, 11.4467348],
                [106.8015815, 11.4469012],
                [106.8020331, 11.447142],
                [106.8022028, 11.4472602],
                [106.8022818, 11.4473288],
                [106.8024237, 11.4474816],
                [106.8024858, 11.4475652],
                [106.8026709, 11.4478762],
                [106.8027583, 11.4480034],
                [106.8029549, 11.4482418],
                [106.8031784, 11.4484565],
                [106.8032992, 11.4485539],
                [106.803709, 11.4488601],
                [106.804136, 11.4491428],
                [106.8042626, 11.4492385],
                [106.8043832, 11.4493414],
                [106.8044973, 11.4494512],
                [106.8046046, 11.4495674],
                [106.8047047, 11.4496896],
                [106.8049354, 11.44998],
                [106.8053027, 11.4504711],
                [106.8053781, 11.4505746],
                [106.80559, 11.4508785],
                [106.8056994, 11.4510564],
                [106.8058499, 11.4513309],
                [106.8059633, 11.4515795],
                [106.8060746, 11.4518982],
                [106.8061179, 11.4520612],
                [106.8061793, 11.4523927],
                [106.8061973, 11.4525602],
                [106.8062603, 11.4538116],
                [106.806312, 11.454485],
                [106.8063462, 11.454821],
                [106.8063512, 11.4550012],
                [106.806347, 11.4551814],
                [106.8063337, 11.4553612],
                [106.8063112, 11.4555401],
                [106.8062389, 11.4558935],
                [106.8061283, 11.4562865],
                [106.8060633, 11.4564801],
                [106.8060238, 11.4565745],
                [106.805969, 11.4567692],
                [106.8059538, 11.4568683],
                [106.8059477, 11.4570683],
                [106.8059569, 11.4571681],
                [106.8059994, 11.4573639],
                [106.8060324, 11.4574587],
                [106.8061747, 11.4577554],
                [106.80622, 11.4578752],
                [106.8062844, 11.4581225],
                [106.8063322, 11.4583817],
                [106.8063702, 11.4585122],
                [106.8064172, 11.45864],
                [106.8064729, 11.4587643],
                [106.806537, 11.4588846],
                [106.8066094, 11.4590004],
                [106.8069012, 11.4594097],
                [106.8075401, 11.4603512],
                [106.8076685, 11.4605007],
                [106.8078431, 11.4606531],
                [106.8080636, 11.4607633],
                [106.8083042, 11.4608724],
                [106.8085097, 11.4609935],
                [106.808723, 11.4611345],
                [106.8084965, 11.4614098],
                [106.8082777, 11.4616911],
                [106.808067, 11.4619782],
                [106.8077004, 11.462521],
                [106.8073905, 11.463032],
                [106.8072125, 11.4633565],
                [106.8071669, 11.4634774],
                [106.8070564, 11.4638465],
                [106.8069734, 11.4640348],
                [106.8068618, 11.4642082],
                [106.806796, 11.4642881],
                [106.8067241, 11.4643627],
                [106.8065914, 11.4644747],
                [106.8064982, 11.4645781],
                [106.8064615, 11.464637],
                [106.8063749, 11.4648493],
                [106.8063347, 11.4649188],
                [106.806165, 11.465129],
                [106.8061151, 11.4652095],
                [106.8060733, 11.4652944],
                [106.80604, 11.4653828],
                [106.8060155, 11.4654739],
                [106.8059936, 11.4656609],
                [106.8059965, 11.4657551],
                [106.8060085, 11.4658486],
                [106.8060297, 11.4659405],
                [106.8060968, 11.4661604],
                [106.8061176, 11.4662734],
                [106.8061332, 11.4665024],
                [106.806114, 11.4667311],
                [106.8060914, 11.4668437],
                [106.8060208, 11.4670625],
                [106.8059732, 11.4671675],
                [106.8058549, 11.4673655],
                [106.8057078, 11.467544],
                [106.8056102, 11.4676374],
                [106.8055193, 11.4677371],
                [106.8054353, 11.4678424],
                [106.8053587, 11.4679531],
                [106.8052899, 11.4680685],
                [106.8052292, 11.4681882],
                [106.8051767, 11.4683116],
                [106.8050605, 11.4686306],
                [106.804913, 11.4689369],
                [106.8047316, 11.4692338],
                [106.8046275, 11.4693781],
                [106.804238, 11.4699729],
                [106.8040833, 11.4702304],
                [106.8039542, 11.4705011],
                [106.8038517, 11.4707825],
                [106.803777, 11.471071],
                [106.8037303, 11.471365],
                [106.8036869, 11.4715282],
                [106.803616, 11.4717076],
                [106.8035104, 11.4718971],
                [106.8033995, 11.4728251],
                [106.8033336, 11.4732879],
                [106.8031586, 11.4743338],
                [106.8031277, 11.4744556],
                [106.8030413, 11.4746918],
                [106.8029863, 11.4748052],
                [106.8028486, 11.475046],
                [106.8028036, 11.4751899],
                [106.8027931, 11.4752645],
                [106.8027907, 11.4753399],
                [106.8028105, 11.4754891],
                [106.8028325, 11.4755613],
                [106.8029084, 11.475751],
                [106.8029519, 11.4759502],
                [106.8029617, 11.4761537],
                [106.8029376, 11.476356],
                [106.80289, 11.4765253],
                [106.8028601, 11.476593],
                [106.8027805, 11.476718],
                [106.8026743, 11.4768507],
                [106.8025975, 11.4770017],
                [106.802571, 11.4770821],
                [106.8025436, 11.4772486],
                [106.8025428, 11.477333],
                [106.8025514, 11.4774219],
                [106.8025929, 11.4775956],
                [106.8026254, 11.477679],
                [106.8027127, 11.4778356],
                [106.8027667, 11.4779075],
                [106.8029123, 11.4780663],
                [106.8029712, 11.4781762],
                [106.8029894, 11.4782358],
                [106.8030022, 11.4783593],
                [106.8029829, 11.478482],
                [106.8029326, 11.4785959],
                [106.8028621, 11.4786864],
                [106.8028109, 11.4787769],
                [106.8027953, 11.4788264],
                [106.8027859, 11.4789294],
                [106.8028057, 11.4790311],
                [106.8028873, 11.47921],
                [106.8029398, 11.4793991],
                [106.8029886, 11.4797456],
                [106.8030227, 11.4799173],
                [106.8031248, 11.4803442],
                [106.8031556, 11.480606],
                [106.8031592, 11.4807377],
                [106.8031429, 11.4810007],
                [106.8030953, 11.4812601],
                [106.8030612, 11.4813835],
                [106.8029372, 11.4817474],
                [106.802847, 11.4819873],
                [106.8027495, 11.4822245],
                [106.8025749, 11.4826049],
                [106.802352, 11.4830373],
                [106.8020141, 11.4836437],
                [106.8018611, 11.4839798],
                [106.8017362, 11.4843268],
                [106.8016845, 11.4845037],
                [106.8015626, 11.4849833],
                [106.8015055, 11.4851039],
                [106.8014212, 11.4852079],
                [106.8013703, 11.4852519],
                [106.8012543, 11.4853209],
                [106.801118, 11.4853712],
                [106.8010855, 11.4853924],
                [106.8010358, 11.4854439],
                [106.8009192, 11.4856786],
                [106.8008197, 11.4859683],
                [106.800727, 11.4861552],
                [106.8006089, 11.4863278],
                [106.8004358, 11.4865402],
                [106.8002495, 11.4868205],
                [106.8001836, 11.4868961],
                [106.8000329, 11.4870288],
                [106.7998222, 11.4871645],
                [106.7997131, 11.4872662],
                [106.7996033, 11.4873917],
                [106.79954, 11.4874463],
                [106.7993281, 11.4875887],
                [106.7992651, 11.4876465],
                [106.7992088, 11.4877107],
                [106.79916, 11.4877806],
                [106.7991193, 11.4878552],
                [106.7990872, 11.4879338],
                [106.799064, 11.4880153],
                [106.799026, 11.4882084],
                [106.7989594, 11.4883938],
                [106.7988858, 11.4885281],
                [106.7988387, 11.4885888],
                [106.7987855, 11.4886445],
                [106.7986627, 11.488738],
                [106.7985946, 11.488775],
                [106.7984273, 11.4888484],
                [106.79827, 11.4889405],
                [106.7981676, 11.4890215],
                [106.7981246, 11.4890704],
                [106.7980577, 11.4891815],
                [106.7980196, 11.4893049],
                [106.7980124, 11.4894337],
                [106.7980549, 11.4896591],
                [106.7980582, 11.4897416],
                [106.7980522, 11.4898239],
                [106.7980369, 11.489905],
                [106.7980126, 11.489984],
                [106.7979391, 11.4901325],
                [106.7978386, 11.4902648],
                [106.7977146, 11.4903763],
                [106.7975409, 11.4904895],
                [106.7974553, 11.4905778],
                [106.797421, 11.4906287],
                [106.797393, 11.4906832],
                [106.7973577, 11.490797],
                [106.7973497, 11.4909157],
                [106.7973561, 11.4909749],
                [106.7973639, 11.491033],
                [106.797258, 11.4910882],
                [106.797143, 11.4911355],
                [106.7969042, 11.4912057],
                [106.7966578, 11.4912421],
                [106.7964134, 11.4912444],
                [106.7962918, 11.4912331],
                [106.795645, 11.4911368],
                [106.7954564, 11.491092],
                [106.7952497, 11.4910179],
                [106.7951273, 11.4910076],
                [106.7950661, 11.4910145],
                [106.7949491, 11.4910513],
                [106.7948954, 11.4910807],
                [106.7948019, 11.491159],
                [106.7947135, 11.4912565],
                [106.7946601, 11.4912958],
                [106.794602, 11.4913279],
                [106.7944752, 11.491368],
                [106.7943422, 11.491374],
                [106.7942762, 11.491364],
                [106.7940569, 11.4912961],
                [106.7939446, 11.4912717],
                [106.7937536, 11.491245],
                [106.7935202, 11.4912345],
                [106.7933645, 11.4912387],
                [106.7932094, 11.4912521],
                [106.7930553, 11.4912746],
                [106.7929029, 11.4913062],
                [106.7926335, 11.491386],
                [106.7925026, 11.491437],
                [106.7922507, 11.4915602],
                [106.79192, 11.4917625],
                [106.7916922, 11.4918611],
                [106.7915733, 11.4918977],
                [106.791452, 11.4919257],
                [106.791329, 11.4919447],
                [106.7912048, 11.4919548],
                [106.7910802, 11.4919559],
                [106.7909559, 11.4919479],
                [106.7908325, 11.4919309],
                [106.7907107, 11.4919051],
                [106.7905912, 11.4918704],
                [106.7904747, 11.4918273],
                [106.7903617, 11.4917758],
                [106.7902529, 11.4917162],
                [106.7901552, 11.4916533],
                [106.7898772, 11.4914438],
                [106.7896983, 11.4912964],
                [106.7895259, 11.4911417],
                [106.7893602, 11.4909802],
                [106.7890203, 11.4906195],
                [106.7889208, 11.490532],
                [106.788706, 11.4903762],
                [106.7885915, 11.4903085],
                [106.7883506, 11.490195],
                [106.7882252, 11.4901496],
                [106.7880308, 11.490096],
                [106.7878392, 11.4900334],
                [106.7876508, 11.489962],
                [106.7874661, 11.4898821],
                [106.7872854, 11.4897937],
                [106.7871091, 11.4896971],
                [106.7866377, 11.4894015],
                [106.7863193, 11.4892416],
                [106.785986, 11.4891144],
                [106.7858148, 11.4890635],
                [106.7854655, 11.4889878],
                [106.7852884, 11.4889631],
                [106.7851102, 11.4889474],
                [106.784578, 11.4889367],
                [106.7844018, 11.4889228],
                [106.7840524, 11.4888711],
                [106.7837093, 11.4887879],
                [106.783541, 11.4887348],
                [106.783213, 11.4886059],
                [106.7830539, 11.4885305],
                [106.7825984, 11.4882912],
                [106.7821289, 11.4880794],
                [106.7816471, 11.4878961],
                [106.7814022, 11.4878154],
                [106.7809847, 11.4876961],
                [106.7808173, 11.4876414],
                [106.7806531, 11.487578],
                [106.7804926, 11.487506],
                [106.7803362, 11.4874258],
                [106.7801845, 11.4873375],
                [106.7800378, 11.4872413],
                [106.7798966, 11.4871376],
                [106.7797612, 11.4870267],
                [106.779632, 11.4869088],
                [106.7795095, 11.4867843],
                [106.779394, 11.4866536],
                [106.7792857, 11.486517],
                [106.7791851, 11.4863749],
                [106.7790923, 11.4862278],
                [106.7790077, 11.486076],
                [106.7789315, 11.48592],
                [106.778864, 11.4857602],
                [106.7787722, 11.4854907],
                [106.7786821, 11.4852866],
                [106.7785617, 11.4850981],
                [106.7784909, 11.485011],
                [106.7783305, 11.4848537],
                [106.7781477, 11.4847219],
                [106.7779468, 11.4846184],
                [106.7777324, 11.4845457],
                [106.7775422, 11.4845093],
                [106.7771684, 11.4844099],
                [106.7769855, 11.4843473],
                [106.7768058, 11.4842762],
                [106.7766298, 11.4841968],
                [106.7764579, 11.4841093],
                [106.7762904, 11.4840138],
                [106.7761278, 11.4839107],
                [106.7759703, 11.4838],
                [106.7758184, 11.4836822],
                [106.7754294, 11.483343],
                [106.775299, 11.4832461],
                [106.7750226, 11.4830748],
                [106.7748774, 11.4830009],
                [106.7747282, 11.4829351],
                [106.7745755, 11.4828776],
                [106.7742614, 11.4827884],
                [106.773939, 11.4827344],
                [106.773776, 11.4827208],
                [106.7736125, 11.4827163],
                [106.7733012, 11.4827326],
                [106.7729895, 11.4827394],
                [106.7726778, 11.4827366],
                [106.7723663, 11.4827242],
                [106.7720553, 11.4827023],
                [106.7717452, 11.4826709],
                [106.7714363, 11.48263],
                [106.7709427, 11.4825444],
                [106.7705761, 11.4824509],
                [106.7702188, 11.4823275],
                [106.7700445, 11.4822547],
                [106.7698178, 11.4821468],
                [106.7696974, 11.482114],
                [106.769635, 11.4821097],
                [106.7695112, 11.4821258],
                [106.769452, 11.4821459],
                [106.7693446, 11.4822083],
                [106.7692579, 11.4822965],
                [106.7691538, 11.482482],
                [106.7691063, 11.4825415],
                [106.7690517, 11.4825947],
                [106.7689908, 11.4826408],
                [106.7689246, 11.4826792],
                [106.7688539, 11.4827093],
                [106.76878, 11.4827305],
                [106.7687041, 11.4827426],
                [106.7686278, 11.4827453],
                [106.7685518, 11.4827389],
                [106.7684772, 11.4827234],
                [106.7684051, 11.482699],
                [106.7683366, 11.4826661],
                [106.7682727, 11.4826252],
                [106.7682145, 11.4825769],
                [106.7681628, 11.482522],
                [106.7681183, 11.4824612],
                [106.7680442, 11.4823325],
                [106.7678431, 11.4818857],
                [106.7676893, 11.4814878],
                [106.7676202, 11.4812408],
                [106.7675813, 11.4809894],
                [106.7675732, 11.4808625],
                [106.7676251, 11.4808165],
                [106.7677084, 11.4807068],
                [106.7677583, 11.4805864],
                [106.7677711, 11.480526],
                [106.7677746, 11.4804028],
                [106.7677654, 11.4803417],
                [106.7677428, 11.4802669],
                [106.7676742, 11.4801264],
                [106.7675768, 11.4800032],
                [106.7674552, 11.479903],
                [106.7673869, 11.4798629],
                [106.7672404, 11.4798054],
                [106.7670862, 11.4797793],
                [106.7670078, 11.4797783],
                [106.7669297, 11.4797852],
                [106.7667779, 11.4798229],
                [106.7662364, 11.4801245],
                [106.7659274, 11.4802672],
                [106.7657691, 11.4803303],
                [106.7655988, 11.480387],
                [106.7654258, 11.4804357],
                [106.7650738, 11.4805085],
                [106.7647302, 11.4805468],
                [106.7643986, 11.4805543],
                [106.7642328, 11.4805473],
                [106.7638763, 11.4805117],
                [106.7635344, 11.4804442],
                [106.7631738, 11.4803429],
                [106.7629976, 11.4802798],
                [106.7626686, 11.4801361],
                [106.7623668, 11.4799714],
                [106.7620809, 11.4797816],
                [106.7614454, 11.4792963],
                [106.7604321, 11.4785527],
                [106.7593641, 11.4778025],
                [106.7590321, 11.4776049],
                [106.7587762, 11.4774872],
                [106.7586436, 11.4774391],
                [106.7583733, 11.4773657],
                [106.7580986, 11.4773223],
                [106.7579598, 11.4773119],
                [106.7577563, 11.4773142],
                [106.7574933, 11.4773366],
                [106.7568958, 11.4773622],
                [106.7563567, 11.4773581],
                [106.7560874, 11.4773464],
                [106.7558845, 11.4773236],
                [106.7554814, 11.4772591],
                [106.7550834, 11.4771694],
                [106.7546541, 11.4770425],
                [106.7542779, 11.4769127],
                [106.7540734, 11.4767978],
                [106.7539307, 11.4766821],
                [106.7538679, 11.4766152],
                [106.7538141, 11.4765467],
                [106.7537256, 11.4763972],
                [106.7536645, 11.4762351],
                [106.75363, 11.4758895],
                [106.7536217, 11.4755759],
                [106.7535802, 11.4749816],
                [106.7535097, 11.4744219],
                [106.7534632, 11.4741437],
                [106.7534372, 11.4739629],
                [106.7533618, 11.4736054],
                [106.7532555, 11.4732555],
                [106.753119, 11.4729158],
                [106.7529589, 11.4725987],
                [106.7528689, 11.4724453],
                [106.7525344, 11.471951],
                [106.7522951, 11.4716768],
                [106.7520238, 11.4713487],
                [106.7514115, 11.4706436],
                [106.7507641, 11.4699756],
                [106.7505888, 11.469756],
                [106.7504378, 11.4695197],
                [106.7503073, 11.4692564],
                [106.750207, 11.4689806],
                [106.7501685, 11.4688391],
                [106.7501076, 11.4685431],
                [106.7500523, 11.4682746],
                [106.7499888, 11.4678899],
                [106.749974, 11.467722],
                [106.7499689, 11.4675535],
                [106.7499734, 11.4673851],
                [106.750005, 11.4670876],
                [106.7500666, 11.4667946],
                [106.7501084, 11.4666507],
                [106.7502137, 11.46637],
                [106.7502832, 11.4661615],
                [106.7503615, 11.4659561],
                [106.7504485, 11.4657541],
                [106.750544, 11.4655558],
                [106.7506478, 11.4653616],
                [106.7507599, 11.4651718],
                [106.7508799, 11.4649867],
                [106.7510077, 11.4648067],
                [106.7511431, 11.4646321],
                [106.7514194, 11.4643172],
                [106.751546, 11.4641653],
                [106.7516653, 11.4640078],
                [106.7517771, 11.463845],
                [106.751977, 11.4635053],
                [106.7521441, 11.4631489],
                [106.7522257, 11.4629348],
                [106.7522981, 11.4627176],
                [106.7523613, 11.4624976],
                [106.752415, 11.4622752],
                [106.7524592, 11.4620508],
                [106.7524938, 11.4618248],
                [106.7525188, 11.4615976],
                [106.7525341, 11.4613695],
                [106.7525397, 11.4611411],
                [106.7525356, 11.4609126],
                [106.7525217, 11.4606844],
                [106.7524981, 11.4604571],
                [106.7524107, 11.4598412],
                [106.752354, 11.4592219],
                [106.7523288, 11.4586369],
                [106.7523388, 11.458088],
                [106.7523846, 11.4575408],
                [106.752466, 11.4569976],
                [106.752528, 11.4567203],
                [106.7526235, 11.4564522],
                [106.7526834, 11.4563229],
                [106.7528263, 11.4560759],
                [106.7529984, 11.4558475],
                [106.7531971, 11.4556409],
                [106.7534196, 11.4554591],
                [106.7536371, 11.4553191],
                [106.7541476, 11.4550575],
                [106.7547688, 11.4547605],
                [106.7552246, 11.4545613],
                [106.7553614, 11.4544931],
                [106.7554937, 11.4544169],
                [106.7556211, 11.4543329],
                [106.755743, 11.4542415],
                [106.755859, 11.454143],
                [106.7559687, 11.4540378],
                [106.7560716, 11.4539262],
                [106.7561675, 11.4538087],
                [106.7562558, 11.4536857],
                [106.7563364, 11.4535576],
                [106.7564089, 11.453425],
                [106.756473, 11.4532883],
                [106.7565285, 11.4531481],
                [106.7565752, 11.4530047],
                [106.7566129, 11.4528589],
                [106.7566415, 11.4527111],
                [106.7566609, 11.4525619],
                [106.7566709, 11.4524118],
                [106.7566717, 11.4522613],
                [106.7566631, 11.4521111],
                [106.7566452, 11.4519617],
                [106.756618, 11.4518137],
                [106.7565808, 11.4516642],
                [106.7564415, 11.4512237],
                [106.75634, 11.4509328],
                [106.7562298, 11.4506449],
                [106.7561109, 11.4503604],
                [106.7559835, 11.4500795],
                [106.7558477, 11.4498023],
                [106.7557036, 11.4495292],
                [106.7555513, 11.4492604],
                [106.755391, 11.4489962],
                [106.7552228, 11.4487367],
                [106.7550468, 11.4484821],
                [106.7548633, 11.4482328],
                [106.7546723, 11.4479889],
                [106.754474, 11.4477507],
                [106.7540564, 11.4472919],
                [106.7536118, 11.4468581],
                [106.7533799, 11.4466511],
                [106.7529946, 11.4463328],
                [106.7525434, 11.4459902],
                [106.7522365, 11.4457697],
                [106.7516084, 11.4453487],
                [106.7509621, 11.4449551],
                [106.7502987, 11.4445896],
                [106.7496709, 11.4442789],
                [106.7490282, 11.4439991],
                [106.7483721, 11.4437508],
                [106.7480395, 11.4436388],
                [106.7473664, 11.4434391],
                [106.746684, 11.4432724],
                [106.7460829, 11.4431543],
                [106.7455666, 11.4430738],
                [106.7446082, 11.4429048],
                [106.7444236, 11.442862],
                [106.7442413, 11.4428103],
                [106.7440618, 11.4427498],
                [106.7438856, 11.4426808],
                [106.7437131, 11.4426033],
                [106.7435447, 11.4425176],
                [106.7433808, 11.4424238],
                [106.7432218, 11.4423222],
                [106.7430681, 11.4422131],
                [106.7429202, 11.4420966],
                [106.7427782, 11.4419732],
                [106.7426426, 11.441843],
                [106.7425138, 11.4417065],
                [106.742392, 11.4415638],
                [106.7422775, 11.4414155],
                [106.7421706, 11.4412618],
                [106.7420716, 11.4411032],
                [106.7419806, 11.4409399],
                [106.741898, 11.4407724],
                [106.7418133, 11.4405744],
                [106.7416689, 11.4401689],
                [106.7415587, 11.4397532],
                [106.7414834, 11.4393302],
                [106.7414436, 11.4389025],
                [106.7414371, 11.4386878],
                [106.7414509, 11.4382585],
                [106.7415005, 11.4378318],
                [106.7415385, 11.4376203],
                [106.7416408, 11.4372027],
                [106.7417049, 11.4369973],
                [106.7418961, 11.4364592],
                [106.7420292, 11.4360401],
                [106.7420657, 11.4358902],
                [106.7420932, 11.4357385],
                [106.7421118, 11.4355856],
                [106.7421216, 11.4352777],
                [106.7420949, 11.4349709],
                [106.7420681, 11.4348191],
                [106.7420322, 11.4346691],
                [106.7419343, 11.4343764],
                [106.7418725, 11.4342348],
                [106.7417243, 11.4339632],
                [106.741545, 11.4337103],
                [106.7414444, 11.4335919],
                [106.7412232, 11.4333732],
                [106.7409775, 11.4331811],
                [106.7407103, 11.4330179],
                [106.7402878, 11.4328085],
                [106.7400018, 11.432678],
                [106.7397116, 11.4325564],
                [106.7394177, 11.4324439],
                [106.7391202, 11.4323406],
                [106.7388196, 11.4322465],
                [106.7385161, 11.4321619],
                [106.73821, 11.4320867],
                [106.7379017, 11.432021],
                [106.7375913, 11.431965],
                [106.7372793, 11.4319186],
                [106.736966, 11.4318819],
                [106.7360545, 11.4318137],
                [106.7357571, 11.4317807],
                [106.7350278, 11.4316776],
                [106.7348098, 11.4316603],
                [106.7345911, 11.4316522],
                [106.7343724, 11.4316531],
                [106.7341538, 11.4316632],
                [106.7339359, 11.4316824],
                [106.733719, 11.4317107],
                [106.7335036, 11.431748],
                [106.73329, 11.4317943],
                [106.7330785, 11.4318494],
                [106.7328697, 11.4319133],
                [106.7326638, 11.4319859],
                [106.7324613, 11.4320669],
                [106.7322624, 11.4321564],
                [106.7320677, 11.4322541],
                [106.7318773, 11.4323598],
                [106.7314842, 11.4325906],
                [106.7312811, 11.4326946],
                [106.7308633, 11.432879],
                [106.7304322, 11.433031],
                [106.7299903, 11.4331498],
                [106.7295403, 11.4332347],
                [106.7291542, 11.4332796],
                [106.7288348, 11.4332837],
                [106.7285171, 11.433252],
                [106.7282052, 11.4331851],
                [106.727903, 11.4330837],
                [106.7276147, 11.4329492],
                [106.7273438, 11.4327833],
                [106.7272161, 11.4326893],
                [106.7269781, 11.4324805],
                [106.7268686, 11.4323665],
                [106.7266705, 11.432121],
                [106.7265824, 11.4319904],
                [106.7265022, 11.431855],
                [106.7264034, 11.4316587],
                [106.7262247, 11.4312575],
                [106.7259738, 11.4306489],
                [106.725797, 11.4301677],
                [106.7257446, 11.4299918],
                [106.7257013, 11.4298135],
                [106.7256673, 11.4296334],
                [106.7256426, 11.4294518],
                [106.7256272, 11.4292692],
                [106.7256213, 11.429086],
                [106.7256249, 11.4289029],
                [106.7256379, 11.4287201],
                [106.7256603, 11.4285382],
                [106.725692, 11.4283576],
                [106.725733, 11.4281788],
                [106.7257831, 11.4280023],
                [106.7258423, 11.4278285],
                [106.7259104, 11.4276579],
                [106.7261554, 11.4271505],
                [106.7262906, 11.4267963],
                [106.7263914, 11.4264313],
                [106.726457, 11.4260586],
                [106.7264764, 11.4258705],
                [106.7264869, 11.4256816],
                [106.7264807, 11.4253035],
                [106.7264657, 11.4251288],
                [106.7264125, 11.4247823],
                [106.7263397, 11.4244731],
                [106.7262477, 11.424191],
                [106.726158, 11.4239842],
                [106.7260914, 11.4238636],
                [106.7260162, 11.423748],
                [106.7259328, 11.4236379],
                [106.7258417, 11.4235338],
                [106.7257432, 11.4234364],
                [106.725638, 11.4233461],
                [106.7255264, 11.4232633],
                [106.7254092, 11.4231885],
                [106.7252868, 11.4231221],
                [106.7251224, 11.4230485],
                [106.7249323, 11.4229839],
                [106.7244691, 11.4228469],
                [106.7239972, 11.4227424],
                [106.723519, 11.4226707],
                [106.7230368, 11.4226323],
                [106.7228032, 11.4226258],
                [106.7223362, 11.4226361],
                [106.7218709, 11.4226777],
                [106.7214096, 11.4227502],
                [106.7211812, 11.422798],
                [106.7207299, 11.4229163],
                [106.7202876, 11.4212079],
                [106.7198955, 11.4197259],
                [106.7194941, 11.4182465],
                [106.7190194, 11.4165434],
                [106.7189646, 11.4163152],
                [106.7188822, 11.4158533],
                [106.7188547, 11.4156203],
                [106.7188302, 11.4152546],
                [106.7188145, 11.4151234],
                [106.7187896, 11.4149936],
                [106.7187554, 11.4148658],
                [106.7187122, 11.4147407],
                [106.7186601, 11.4146189],
                [106.7185995, 11.4145009],
                [106.7184606, 11.4142879],
                [106.717827, 11.4135482],
                [106.717353, 11.4130366],
                [106.717118, 11.4128017],
                [106.7168764, 11.4125733],
                [106.7166284, 11.4123516],
                [106.7163742, 11.4121368],
                [106.7158186, 11.4116864],
                [106.715547, 11.4114539],
                [106.7148317, 11.4108145],
                [106.7143658, 11.4104324],
                [106.7138065, 11.4100168],
                [106.7131519, 11.4095723],
                [106.7123736, 11.4090806],
                [106.7119475, 11.4087753],
                [106.7115408, 11.4084455],
                [106.7111835, 11.4081198],
                [106.7106091, 11.4076133],
                [106.7101983, 11.407227],
                [106.7100006, 11.4070262],
                [106.7096688, 11.4066649],
                [106.7094084, 11.4063386],
                [106.7092878, 11.4061685],
                [106.7092016, 11.4060381],
                [106.709051, 11.4057646],
                [106.7089309, 11.405477],
                [106.7088828, 11.4053288],
                [106.708811, 11.4050262],
                [106.7087723, 11.4047177],
                [106.7087657, 11.4045624],
                [106.708774, 11.4042943],
                [106.7087896, 11.4041826],
                [106.7088476, 11.4039645],
                [106.7089399, 11.403758],
                [106.7089983, 11.4036608],
                [106.7090643, 11.4035684],
                [106.7091376, 11.4034815],
                [106.7094029, 11.4032143],
                [106.7095258, 11.4030719],
                [106.7096418, 11.4029241],
                [106.709852, 11.4026133],
                [106.7100313, 11.4022846],
                [106.7101089, 11.4021143],
                [106.7102167, 11.4018325],
                [106.7102694, 11.4016093],
                [106.7102833, 11.4014955],
                [106.710289, 11.401381],
                [106.7102751, 11.4011524],
                [106.710228, 11.400928],
                [106.7101923, 11.4008189],
                [106.7101487, 11.4007125],
                [106.7100388, 11.4005104],
                [106.7099785, 11.400423],
                [106.7097791, 11.4001741],
                [106.7094924, 11.3998596],
                [106.7091805, 11.3995691],
                [106.708939, 11.3993765],
                [106.7088121, 11.399288],
                [106.7085475, 11.3991274],
                [106.7083928, 11.399047],
                [106.7076006, 11.3986842],
                [106.7071313, 11.3984543],
                [106.7064347, 11.3981484],
                [106.7060918, 11.3979842],
                [106.7053747, 11.3976133],
                [106.7048782, 11.3973368],
                [106.7047321, 11.3972352],
                [106.7046245, 11.3971454],
                [106.7044774, 11.396979],
                [106.7043541, 11.396802],
                [106.7043017, 11.3967111],
                [106.7042128, 11.3965216],
                [106.7040589, 11.3965508],
                [106.7039202, 11.3966074],
                [106.7038586, 11.3966437],
                [106.7036176, 11.3968188],
                [106.703514, 11.3969131],
                [106.7034706, 11.3969679],
                [106.7034334, 11.397027],
                [106.7033514, 11.397215],
                [106.7033172, 11.3972737],
                [106.7032761, 11.397331],
                [106.7032279, 11.3973826],
                [106.7031734, 11.3974278],
                [106.7031133, 11.3974656],
                [106.7029702, 11.3975385],
                [106.7029041, 11.397584],
                [106.7027154, 11.39774],
                [106.7025511, 11.3978608],
                [106.7024795, 11.3979038],
                [106.7024034, 11.3979385],
                [106.7023238, 11.3979643],
                [106.7022416, 11.397981],
                [106.7021586, 11.3979883],
                [106.7020051, 11.3979866],
                [106.7018664, 11.3980075],
                [106.7017347, 11.3980546],
                [106.7015143, 11.3981658],
                [106.7014461, 11.3981867],
                [106.7013756, 11.3981985],
                [106.7013042, 11.3982009],
                [106.7011233, 11.3981777],
                [106.7010131, 11.3981913],
                [106.7009519, 11.3982128],
                [106.7008385, 11.398275],
                [106.7007458, 11.3983559],
                [106.7006471, 11.3984196],
                [106.700549, 11.3984525],
                [106.7004606, 11.3984622],
                [106.7004491, 11.3985592],
                [106.7004467, 11.3987537],
                [106.7004732, 11.3990051],
                [106.7004543, 11.3991217],
                [106.7004121, 11.3992202],
                [106.7003861, 11.3992606],
                [106.7001746, 11.399461],
                [106.6997856, 11.3997973],
                [106.6995865, 11.39998],
                [106.6993942, 11.4001695],
                [106.699209, 11.4003657],
                [106.699031, 11.4005682],
                [106.6987725, 11.4008909],
                [106.6982618, 11.4015843],
                [106.6979741, 11.4019602],
                [106.6977926, 11.402263],
                [106.6977143, 11.4024656],
                [106.6976537, 11.4026923],
                [106.6976317, 11.4028166],
                [106.6976104, 11.4030679],
                [106.6974607, 11.4030394],
                [106.6971656, 11.4029645],
                [106.6968842, 11.4028685],
                [106.6967467, 11.4028122],
                [106.6962002, 11.4026508],
                [106.6953832, 11.4024298],
                [106.6949257, 11.4022923],
                [106.6944712, 11.4021458],
                [106.6941227, 11.4020478],
                [106.6937685, 11.4019723],
                [106.6935648, 11.4019394],
                [106.6931545, 11.4018958],
                [106.6929483, 11.4018853],
                [106.692985, 11.4017238],
                [106.6930124, 11.4015605],
                [106.6930304, 11.401396],
                [106.6930385, 11.4010802],
                [106.693014, 11.4007802],
                [106.6929272, 11.400315],
                [106.6928834, 11.399974],
                [106.6928645, 11.3996236],
                [106.6928649, 11.3994482],
                [106.6926537, 11.3993046],
                [106.6923844, 11.3991415],
                [106.6918552, 11.3988477],
                [106.691334, 11.3985906],
                [106.6908139, 11.3983716],
                [106.6905592, 11.3982508],
                [106.6900417, 11.3979759],
                [106.6897802, 11.397821],
                [106.6895239, 11.3976578],
                [106.6891267, 11.3973366],
                [106.6889737, 11.397193],
                [106.6888144, 11.397056],
                [106.6886749, 11.3969455],
                [106.6883837, 11.39674],
                [106.6864013, 11.3955296],
                [106.6862351, 11.3959667],
                [106.686094, 11.3962862],
                [106.6859525, 11.3965796],
                [106.6858587, 11.3967491],
                [106.6856791, 11.3969984],
                [106.6855913, 11.3971033],
                [106.6854185, 11.397283],
                [106.6839516, 11.3985566],
                [106.6804057, 11.3977075],
                [106.6798963, 11.398356],
                [106.6774338, 11.3979763],
                [106.6758342, 11.401802],
                [106.6734163, 11.4012617],
                [106.6792039, 11.3849353],
                [106.6788976, 11.3846871],
                [106.6782948, 11.3841794],
                [106.6777284, 11.3836779],
                [106.6774499, 11.3834221],
                [106.677348, 11.3832959],
                [106.6772489, 11.3831258],
                [106.6771891, 11.3830605],
                [106.6771082, 11.3830153],
                [106.6770613, 11.3830027],
                [106.676949, 11.382999],
                [106.6767143, 11.3830219],
                [106.676522, 11.3829936],
                [106.6763599, 11.3829468],
                [106.6761718, 11.3828313],
                [106.6760042, 11.3826885],
                [106.6759295, 11.3826081],
                [106.6758003, 11.3824313],
                [106.6757466, 11.3823361],
                [106.6757843, 11.3821153],
                [106.6758193, 11.3819854],
                [106.6758634, 11.3818583],
                [106.6759165, 11.3817344],
                [106.6759782, 11.3816145],
                [106.6760383, 11.3815305],
                [106.6761044, 11.3814508],
                [106.676253, 11.3813064],
                [106.676421, 11.381184],
                [106.676596, 11.3810902],
                [106.6766844, 11.3810562],
                [106.6767755, 11.3810303],
                [106.6769632, 11.3810035],
                [106.6771526, 11.3810105],
                [106.6772866, 11.3810364],
                [106.6773694, 11.3810324],
                [106.6774087, 11.3810188],
                [106.6774757, 11.3809709],
                [106.6775193, 11.3809018],
                [106.6775331, 11.3808216],
                [106.6775229, 11.3807628],
                [106.6775259, 11.3806438],
                [106.6775595, 11.3805294],
                [106.6776216, 11.3804272],
                [106.6776718, 11.3803736],
                [106.6777542, 11.3802662],
                [106.6778034, 11.3801531],
                [106.6778196, 11.3800311],
                [106.6777951, 11.3798464],
                [106.677806, 11.3797222],
                [106.6778228, 11.379662],
                [106.6779034, 11.3794916],
                [106.6779226, 11.3794316],
                [106.6779585, 11.3791751],
                [106.6779831, 11.3790799],
                [106.6780575, 11.3788977],
                [106.6781067, 11.3788121],
                [106.6782377, 11.3786273],
                [106.6782833, 11.3785086],
                [106.6782972, 11.3783825],
                [106.6782786, 11.3782569],
                [106.6782287, 11.3781399],
                [106.6781574, 11.3780457],
                [106.6781081, 11.3779495],
                [106.6780915, 11.3778432],
                [106.6781092, 11.377737],
                [106.6781596, 11.3776413],
                [106.6781956, 11.3776005],
                [106.6783348, 11.377487],
                [106.6783965, 11.3774218],
                [106.6785014, 11.377277],
                [106.6785719, 11.3771309],
                [106.6786113, 11.3769738],
                [106.678618, 11.3768123],
                [106.6785917, 11.3766526],
                [106.6785665, 11.3765756],
                [106.6784656, 11.3763649],
                [106.6784289, 11.3762174],
                [106.6784278, 11.3761359],
                [106.6784559, 11.3760592],
                [106.6785094, 11.375997],
                [106.6785818, 11.375957],
                [106.6786842, 11.3759278],
                [106.6787316, 11.3759038],
                [106.6788153, 11.375839],
                [106.6788501, 11.3757993],
                [106.6789044, 11.375703],
                [106.6789205, 11.3756501],
                [106.6789286, 11.3755403],
                [106.6789205, 11.3754857],
                [106.6788808, 11.3753827],
                [106.6787615, 11.3752313],
                [106.6786845, 11.3750855],
                [106.6786401, 11.3749271],
                [106.6786307, 11.3748454],
                [106.6786299, 11.3746544],
                [106.6786163, 11.3746015],
                [106.6785639, 11.3745057],
                [106.6785265, 11.3744652],
                [106.6784342, 11.3744048],
                [106.678327, 11.3743767],
                [106.6781828, 11.3743693],
                [106.6780543, 11.3743327],
                [106.6779994, 11.3743048],
                [106.6779488, 11.3742699],
                [106.6778643, 11.3741819],
                [106.6778068, 11.374075],
                [106.6777805, 11.3739569],
                [106.6777872, 11.3738362],
                [106.6778265, 11.3737217],
                [106.6778903, 11.3736276],
                [106.6779327, 11.3735313],
                [106.6779412, 11.3734268],
                [106.6779323, 11.3733749],
                [106.6778895, 11.3732788],
                [106.6778174, 11.3732013],
                [106.677723, 11.3731308],
                [106.6776849, 11.373086],
                [106.677631, 11.3729823],
                [106.6776105, 11.3728677],
                [106.6776323, 11.3726865],
                [106.6776222, 11.3725625],
                [106.6775838, 11.372444],
                [106.6774945, 11.3722847],
                [106.677477, 11.3722272],
                [106.6774686, 11.3721678],
                [106.6774793, 11.3720487],
                [106.6774982, 11.3719917],
                [106.6775267, 11.3719363],
                [106.6776326, 11.3718065],
                [106.6776509, 11.3717678],
                [106.6776675, 11.3716844],
                [106.6776562, 11.3716002],
                [106.6775791, 11.3714513],
                [106.6775604, 11.3713916],
                [106.6775484, 11.3712676],
                [106.6775554, 11.3712056],
                [106.6775948, 11.3710871],
                [106.6777049, 11.3709033],
                [106.6777866, 11.3707058],
                [106.6778382, 11.3704987],
                [106.677858, 11.3703019],
                [106.6778542, 11.3700282],
                [106.6778407, 11.3698463],
                [106.6778177, 11.3696653],
                [106.6777852, 11.3694857],
                [106.6776751, 11.3690499],
                [106.6775704, 11.3686908],
                [106.6775346, 11.368546],
                [106.6774935, 11.3683162],
                [106.6774921, 11.3681475],
                [106.6775034, 11.3680638],
                [106.6775496, 11.3679012],
                [106.677613, 11.3677708],
                [106.6776521, 11.367657],
                [106.6776646, 11.367556],
                [106.6776584, 11.3674309],
                [106.6776447, 11.3673485],
                [106.6775939, 11.3671892],
                [106.6774862, 11.3669834],
                [106.6774596, 11.3669142],
                [106.6774038, 11.3666889],
                [106.6773775, 11.3666142],
                [106.6773047, 11.3664655],
                [106.6772334, 11.3663607],
                [106.6772018, 11.3662798],
                [106.6772003, 11.3661931],
                [106.6772111, 11.3661509],
                [106.6772292, 11.3661112],
                [106.6772964, 11.3660206],
                [106.6773082, 11.3659883],
                [106.6773114, 11.3659227],
                [106.6772852, 11.365832],
                [106.677258, 11.3657753],
                [106.6772226, 11.3657229],
                [106.6771799, 11.3656762],
                [106.6771308, 11.365636],
                [106.6769992, 11.3655497],
                [106.6768912, 11.3654363],
                [106.6768479, 11.3653712],
                [106.6767855, 11.3652287],
                [106.6767672, 11.3651531],
                [106.6767573, 11.3650072],
                [106.6767822, 11.3648722],
                [106.6768405, 11.3647474],
                [106.6768811, 11.3646914],
                [106.6769821, 11.3645964],
                [106.6770408, 11.3645589],
                [106.6771588, 11.3645101],
                [106.6772595, 11.3644537],
                [106.6773039, 11.364417],
                [106.6773775, 11.3643291],
                [106.6774061, 11.3642784],
                [106.6774263, 11.3642239],
                [106.6774376, 11.364167],
                [106.6774327, 11.3640516],
                [106.6773919, 11.3639432],
                [106.6773592, 11.363895],
                [106.6772729, 11.3638163],
                [106.6771583, 11.3637443],
                [106.677067, 11.3636453],
                [106.6770054, 11.3635263],
                [106.6769872, 11.3634618],
                [106.6769766, 11.3633567],
                [106.6769876, 11.3632408],
                [106.6770319, 11.363092],
                [106.6770916, 11.3629809],
                [106.6771181, 11.3628886],
                [106.6771103, 11.362793],
                [106.6770692, 11.3627061],
                [106.6769998, 11.3626385],
                [106.6769108, 11.3625988],
                [106.6767295, 11.3625789],
                [106.6765949, 11.3625516],
                [106.6765523, 11.3625341],
                [106.6764789, 11.3624794],
                [106.6764503, 11.3624439],
                [106.6763986, 11.3623445],
                [106.6763759, 11.3623197],
                [106.6763185, 11.3622845],
                [106.6762117, 11.3622642],
                [106.6761437, 11.3622333],
                [106.6760785, 11.3621742],
                [106.6760376, 11.3620969],
                [106.6760258, 11.3620107],
                [106.6760445, 11.3619256],
                [106.6761439, 11.3617679],
                [106.6761744, 11.3616782],
                [106.6761705, 11.3615837],
                [106.6761435, 11.3614842],
                [106.6761392, 11.3614328],
                [106.6761565, 11.3612485],
                [106.6761526, 11.3611816],
                [106.6761308, 11.3610781],
                [106.6760869, 11.3609698],
                [106.6760013, 11.3608392],
                [106.6758653, 11.360698],
                [106.6758332, 11.3606488],
                [106.6757906, 11.36054],
                [106.6757789, 11.3604241],
                [106.6757897, 11.3602926],
                [106.6757798, 11.3601553],
                [106.6757399, 11.3600233],
                [106.6756851, 11.3599221],
                [106.6756599, 11.3598346],
                [106.6756673, 11.3597439],
                [106.6756832, 11.359701],
                [106.6757515, 11.3595969],
                [106.6757702, 11.3595208],
                [106.6757601, 11.3594432],
                [106.6757338, 11.3593691],
                [106.6757272, 11.3592914],
                [106.6757016, 11.3592186],
                [106.6756482, 11.3591622],
                [106.6756137, 11.3591433],
                [106.675576, 11.3591318],
                [106.6754975, 11.3591327],
                [106.67546, 11.359145],
                [106.6753969, 11.3591908],
                [106.6753325, 11.3592962],
                [106.6753014, 11.3593257],
                [106.6752248, 11.359364],
                [106.6751389, 11.3593702],
                [106.6750574, 11.3593433],
                [106.6749736, 11.3592704],
                [106.6749406, 11.3592259],
                [106.6748958, 11.3591253],
                [106.6748822, 11.3590163],
                [106.6748875, 11.3589615],
                [106.6749218, 11.358857],
                [106.6749501, 11.3588095],
                [106.6750469, 11.3587071],
                [106.6751033, 11.3586643],
                [106.6752287, 11.358598],
                [106.6753789, 11.358548],
                [106.6754477, 11.3584951],
                [106.6754743, 11.358461],
                [106.6755312, 11.3583409],
                [106.6756354, 11.3582437],
                [106.6756815, 11.3581588],
                [106.6756942, 11.3580634],
                [106.6756717, 11.3579697],
                [106.6756171, 11.3578898],
                [106.6755372, 11.357834],
                [106.6754909, 11.3578174],
                [106.6753931, 11.3578098],
                [106.6753447, 11.357819],
                [106.6752002, 11.3578862],
                [106.6751459, 11.3578978],
                [106.6750903, 11.3579],
                [106.6750351, 11.3578927],
                [106.6749821, 11.3578762],
                [106.6749328, 11.3578509],
                [106.6748887, 11.3578176],
                [106.6748512, 11.3577773],
                [106.6748214, 11.3577313],
                [106.6747573, 11.3575776],
                [106.6747155, 11.3575053],
                [106.6746142, 11.3573723],
                [106.6744915, 11.3572579],
                [106.6743928, 11.3571895],
                [106.6742099, 11.3570348],
                [106.6741265, 11.356949],
                [106.6739275, 11.3567061],
                [106.6738287, 11.3566022],
                [106.6737855, 11.3565221],
                [106.6737764, 11.356432],
                [106.6737853, 11.3563873],
                [106.6738027, 11.3563451],
                [106.6738604, 11.3562744],
                [106.6739411, 11.3562303],
                [106.6739864, 11.3562204],
                [106.6740633, 11.3562233],
                [106.6741218, 11.3562068],
                [106.6741628, 11.3561626],
                [106.6741725, 11.356134],
                [106.6741739, 11.3561039],
                [106.6741564, 11.3560544],
                [106.6741132, 11.3560016],
                [106.6740138, 11.3559057],
                [106.6739875, 11.3558676],
                [106.6739669, 11.3558264],
                [106.673927, 11.3556935],
                [106.673902, 11.355654],
                [106.6738316, 11.3555923],
                [106.6737597, 11.3555639],
                [106.6737092, 11.3555317],
                [106.6736777, 11.3554899],
                [106.6736557, 11.3554261],
                [106.6736505, 11.3553815],
                [106.6736642, 11.355293],
                [106.6737083, 11.3552147],
                [106.6737424, 11.3551805],
                [106.6737825, 11.3551531],
                [106.673968, 11.3550954],
                [106.6740509, 11.355045],
                [106.6741202, 11.354971],
                [106.6741676, 11.3548763],
                [106.6741802, 11.3548249],
                [106.6741783, 11.354767],
                [106.6741558, 11.3547183],
                [106.6741201, 11.3546683],
                [106.674031, 11.3545832],
                [106.6739342, 11.3545267],
                [106.6738804, 11.35451],
                [106.6738317, 11.3545085],
                [106.673786, 11.3545099],
                [106.6736689, 11.354525],
                [106.673497, 11.3545331],
                [106.6733062, 11.3545165],
                [106.673093, 11.354476],
                [106.6729017, 11.3544158],
                [106.6727263, 11.3543444],
                [106.6725775, 11.3543063],
                [106.6724238, 11.3543027],
                [106.6723476, 11.3543139],
                [106.6721657, 11.3543667],
                [106.6720627, 11.3543733],
                [106.6720164, 11.3543659],
                [106.6719307, 11.3543292],
                [106.6718618, 11.3542671],
                [106.6718173, 11.3541864],
                [106.6718035, 11.3541247],
                [106.6717532, 11.3540084],
                [106.6716755, 11.3539077],
                [106.6716277, 11.3538652],
                [106.671575, 11.3538287],
                [106.6714579, 11.3537762],
                [106.6713796, 11.3537583],
                [106.6712996, 11.3537498],
                [106.6712192, 11.3537508],
                [106.6711395, 11.3537613],
                [106.6710617, 11.353781],
                [106.6709869, 11.3538098],
                [106.6709161, 11.3538472],
                [106.6707605, 11.3539471],
                [106.6706974, 11.3539776],
                [106.6705812, 11.3540551],
                [106.6705291, 11.3541014],
                [106.6704324, 11.354217],
                [106.6703141, 11.354329],
                [106.6701741, 11.3544135],
                [106.6700292, 11.3544642],
                [106.6699583, 11.3544751],
                [106.6698866, 11.3544766],
                [106.6698152, 11.3544688],
                [106.6697456, 11.3544517],
                [106.6696789, 11.3544257],
                [106.6696163, 11.3543913],
                [106.669559, 11.3543489],
                [106.6694099, 11.3541871],
                [106.6693022, 11.3541047],
                [106.6691908, 11.3540552],
                [106.6691313, 11.3540409],
                [106.6690705, 11.3540338],
                [106.6689485, 11.3540419],
                [106.6688322, 11.3540789],
                [106.6687784, 11.3541076],
                [106.6686982, 11.3541688],
                [106.6686126, 11.3542225],
                [106.6685222, 11.3542682],
                [106.6684279, 11.3543053],
                [106.6681657, 11.3543738],
                [106.6680863, 11.3544035],
                [106.6680096, 11.3544393],
                [106.6678328, 11.3545398],
                [106.667776, 11.3545584],
                [106.6676573, 11.3545723],
                [106.6675976, 11.3545674],
                [106.6675027, 11.3545423],
                [106.6674643, 11.3545387],
                [106.6673901, 11.3545576],
                [106.6673583, 11.3545792],
                [106.6673142, 11.3546406],
                [106.6673042, 11.3546772],
                [106.667308, 11.3547771],
                [106.6673002, 11.3548074],
                [106.6672656, 11.3548597],
                [106.6672221, 11.3548887],
                [106.667174, 11.3549094],
                [106.6671228, 11.3549212],
                [106.6670703, 11.3549236],
                [106.6670182, 11.3549166],
                [106.6669683, 11.3549003],
                [106.6669223, 11.3548754],
                [106.6668254, 11.3547831],
                [106.6667527, 11.3546714],
                [106.6667267, 11.3546102],
                [106.666708, 11.3545464],
                [106.6666825, 11.354365],
                [106.6666378, 11.3542567],
                [106.6665659, 11.3541635],
                [106.6664717, 11.3540919],
                [106.6664183, 11.3540658],
                [106.6663033, 11.3540352],
                [106.6662437, 11.3540313],
                [106.6661214, 11.3540474],
                [106.6660077, 11.3540944],
                [106.6658914, 11.3541746],
                [106.6658143, 11.3541965],
                [106.6657739, 11.3541975],
                [106.6657036, 11.3541825],
                [106.6656479, 11.3541525],
                [106.6655033, 11.3540989],
                [106.6653851, 11.3540843],
                [106.6653256, 11.3540893],
                [106.6652243, 11.3541176],
                [106.6651849, 11.3541405],
                [106.6651226, 11.3542058],
                [106.6650892, 11.354289],
                [106.6650858, 11.3544265],
                [106.6650735, 11.3544714],
                [106.6650531, 11.3545135],
                [106.6649911, 11.3545835],
                [106.6648581, 11.3546641],
                [106.6646609, 11.354811],
                [106.6645876, 11.3548529],
                [106.6644345, 11.3549168],
                [106.6643552, 11.3549366],
                [106.6641926, 11.35495],
                [106.6641111, 11.3549434],
                [106.6639129, 11.3549018],
                [106.6637921, 11.354907],
                [106.6636512, 11.3549339],
                [106.6635792, 11.3549341],
                [106.6634655, 11.3549159],
                [106.6633793, 11.3549202],
                [106.6633003, 11.3549546],
                [106.6632393, 11.3550145],
                [106.6632181, 11.3550516],
                [106.6631779, 11.3551621],
                [106.6631514, 11.3552099],
                [106.6631168, 11.3552525],
                [106.6630754, 11.3552887],
                [106.6630283, 11.3553174],
                [106.6629768, 11.3553377],
                [106.6629225, 11.3553491],
                [106.6628671, 11.3553513],
                [106.6628121, 11.3553441],
                [106.6627591, 11.3553278],
                [106.6627098, 11.3553028],
                [106.6626022, 11.355218],
                [106.6625637, 11.3552027],
                [106.6624813, 11.3551969],
                [106.6624035, 11.3552241],
                [106.6623706, 11.3552489],
                [106.6622867, 11.3553438],
                [106.6622364, 11.355383],
                [106.6621392, 11.3554333],
                [106.6620861, 11.3554478],
                [106.6620314, 11.3554547],
                [106.6618761, 11.3554457],
                [106.6618261, 11.3554513],
                [106.6616621, 11.355493],
                [106.6615781, 11.3555043],
                [106.6612731, 11.3555089],
                [106.6611633, 11.3555184],
                [106.6609463, 11.3555565],
                [106.6607075, 11.355629],
                [106.660479, 11.3557285],
                [106.6603696, 11.3557879],
                [106.6600745, 11.3560052],
                [106.6599295, 11.3560798],
                [106.6597715, 11.3561308],
                [106.6596496, 11.3561359],
                [106.659407, 11.3561162],
                [106.6591445, 11.3560762],
                [106.6589649, 11.3560387],
                [106.6589135, 11.3560349],
                [106.658838, 11.3560428],
                [106.6586898, 11.3560897],
                [106.6585956, 11.3561381],
                [106.6584284, 11.356248],
                [106.6582866, 11.3563718],
                [106.6582384, 11.3564337],
                [106.6581485, 11.3566071],
                [106.6580658, 11.356716],
                [106.6580489, 11.3567891],
                [106.6580705, 11.3569117],
                [106.6580661, 11.3569552],
                [106.658031, 11.357035],
                [106.6579663, 11.3570945],
                [106.6579261, 11.3571133],
                [106.6578686, 11.3571252],
                [106.6578137, 11.3571458],
                [106.6577627, 11.3571745],
                [106.6577169, 11.3572106],
                [106.6576829, 11.3572466],
                [106.6575827, 11.3573961],
                [106.6575345, 11.3574385],
                [106.6575047, 11.3574513],
                [106.6574402, 11.3574571],
                [106.6574085, 11.3574499],
                [106.6573194, 11.3574111],
                [106.6572226, 11.3574003],
                [106.6571268, 11.3574183],
                [106.6570074, 11.3574656],
                [106.6569443, 11.3574785],
                [106.6568218, 11.3574827],
                [106.6567083, 11.3574581],
                [106.6566048, 11.357406],
                [106.656559, 11.3573706],
                [106.6565182, 11.3573298],
                [106.6564589, 11.3572436],
                [106.656389, 11.3571797],
                [106.656346, 11.3571588],
                [106.6562997, 11.3571464],
                [106.6562518, 11.357143],
                [106.6562042, 11.3571487],
                [106.6561061, 11.3571814],
                [106.6560514, 11.357172],
                [106.6560276, 11.3571575],
                [106.6559949, 11.3571133],
                [106.6559879, 11.3570658],
                [106.6559716, 11.3570205],
                [106.6559467, 11.3569792],
                [106.6558735, 11.3569096],
                [106.6557838, 11.356862],
                [106.6557348, 11.3568477],
                [106.6557044, 11.3568423],
                [106.6556442, 11.3568535],
                [106.6555964, 11.3568911],
                [106.6555723, 11.3569463],
                [106.6555712, 11.3569766],
                [106.6555903, 11.3570636],
                [106.6555926, 11.357108],
                [106.6555798, 11.3571913],
                [106.6555359, 11.3572636],
                [106.6555041, 11.3572921],
                [106.6553916, 11.357345],
                [106.655316, 11.3573553],
                [106.6552423, 11.3573358],
                [106.6552099, 11.3573156],
                [106.6551453, 11.3572515],
                [106.6551206, 11.3572407],
                [106.6550939, 11.3572365],
                [106.6550418, 11.3572485],
                [106.6550025, 11.3572842],
                [106.6549623, 11.3573999],
                [106.6549147, 11.357473],
                [106.6548277, 11.357633],
                [106.6547559, 11.3576963],
                [106.6546216, 11.3577539],
                [106.6545451, 11.3578144],
                [106.6544902, 11.3578943],
                [106.6544395, 11.3580116],
                [106.6544044, 11.3580653],
                [106.6543122, 11.3581661],
                [106.6542596, 11.3582101],
                [106.6541707, 11.3582688],
                [106.6541233, 11.3583266],
                [106.6541071, 11.358399],
                [106.6541247, 11.3584921],
                [106.6541103, 11.3585478],
                [106.6540718, 11.3585912],
                [106.6540043, 11.3586327],
                [106.653957, 11.358698],
                [106.6539313, 11.3587763],
                [106.6539202, 11.3588848],
                [106.6538821, 11.3589413],
                [106.6538215, 11.3589737],
                [106.653787, 11.3589783],
                [106.6537197, 11.3589627],
                [106.6536909, 11.3589436],
                [106.6536131, 11.3588517],
                [106.6535168, 11.3587787],
                [106.6534066, 11.3587279],
                [106.6533258, 11.3587073],
                [106.6531603, 11.3586867],
                [106.6530768, 11.3586867],
                [106.6528527, 11.3587104],
                [106.6527489, 11.358707],
                [106.6527167, 11.3587135],
                [106.6526876, 11.3587284],
                [106.6526639, 11.3587507],
                [106.6526473, 11.3587785],
                [106.6526392, 11.3588097],
                [106.6526402, 11.3588419],
                [106.6526774, 11.3589254],
                [106.6526813, 11.3589549],
                [106.6526687, 11.3590128],
                [106.6526393, 11.3590529],
                [106.6525891, 11.3590859],
                [106.6525508, 11.3590986],
                [106.6525107, 11.359103],
                [106.6524073, 11.3590872],
                [106.6523754, 11.3590927],
                [106.65232, 11.3591246],
                [106.6522857, 11.3591779],
                [106.652279, 11.359209],
                [106.652291, 11.3593067],
                [106.6522845, 11.3593398],
                [106.6522704, 11.3593705],
                [106.6522226, 11.3594182],
                [106.6521612, 11.3594394],
                [106.651936, 11.3594366],
                [106.651871, 11.3594636],
                [106.6518229, 11.3595142],
                [106.651808, 11.3595458],
                [106.6517863, 11.3596835],
                [106.6517509, 11.3598],
                [106.6517262, 11.3598458],
                [106.6516944, 11.3598872],
                [106.6516129, 11.3599527],
                [106.6515146, 11.3599898],
                [106.6514621, 11.3599964],
                [106.6513574, 11.3599849],
                [106.6512301, 11.3599415],
                [106.651036, 11.3599007],
                [106.6509179, 11.3598448],
                [106.6508171, 11.3597626],
                [106.6507751, 11.3597131],
                [106.6507396, 11.359659],
                [106.6506672, 11.3595055],
                [106.6506339, 11.3594644],
                [106.650549, 11.359401],
                [106.6504476, 11.3593682],
                [106.6503942, 11.3593645],
                [106.6502943, 11.3593814],
                [106.6501986, 11.3593792],
                [106.6501527, 11.3593652],
                [106.6500826, 11.359323],
                [106.6500503, 11.359311],
                [106.6500158, 11.3593084],
                [106.649982, 11.3593154],
                [106.6499516, 11.3593313],
                [106.6499268, 11.3593549],
                [106.6499098, 11.3593843],
                [106.6499019, 11.3594173],
                [106.6499037, 11.3594511],
                [106.6499268, 11.3595297],
                [106.6499224, 11.3596115],
                [106.6498928, 11.3596777],
                [106.6498356, 11.3597233],
                [106.6497633, 11.359738],
                [106.6497266, 11.3597325],
                [106.6496325, 11.3596979],
                [106.6495321, 11.3596921],
                [106.6494824, 11.3597003],
                [106.6493895, 11.359738],
                [106.6492541, 11.359846],
                [106.6491987, 11.3598741],
                [106.6491396, 11.3598935],
                [106.6490782, 11.3599038],
                [106.6490159, 11.3599048],
                [106.6489541, 11.3598964],
                [106.6488944, 11.3598788],
                [106.6488382, 11.3598525],
                [106.6487867, 11.359818],
                [106.6487413, 11.3597761],
                [106.6487029, 11.359728],
                [106.6486725, 11.3596746],
                [106.6486508, 11.3596171],
                [106.6486207, 11.3595634],
                [106.6485381, 11.3594719],
                [106.6484874, 11.359436],
                [106.6484318, 11.3594078],
                [106.6483727, 11.359388],
                [106.6483112, 11.3593769],
                [106.6481773, 11.3593735],
                [106.6481121, 11.3593449],
                [106.6480636, 11.3592935],
                [106.6480482, 11.3592617],
                [106.6480378, 11.3591968],
                [106.6480134, 11.3591404],
                [106.647966, 11.3591005],
                [106.6479054, 11.3590856],
                [106.6477711, 11.3591083],
                [106.6476354, 11.3590961],
                [106.6475076, 11.3590497],
                [106.6473786, 11.358971],
                [106.6472962, 11.3589573],
                [106.6472147, 11.3589753],
                [106.6471782, 11.3589956],
                [106.6470909, 11.3590811],
                [106.6470195, 11.3591198],
                [106.6468595, 11.3591442],
                [106.646801, 11.3591601],
                [106.6467444, 11.3591819],
                [106.6466493, 11.3592349],
                [106.6465579, 11.3592673],
                [106.6464608, 11.3592721],
                [106.6464127, 11.359264],
                [106.6463664, 11.359249],
                [106.6462609, 11.3591951],
                [106.6461909, 11.3591895],
                [106.6461244, 11.3592118],
                [106.6460945, 11.3592338],
                [106.6460268, 11.3592643],
                [106.6459526, 11.3592751],
                [106.6459169, 11.3592874],
                [106.6458856, 11.3593084],
                [106.6458609, 11.3593365],
                [106.6458445, 11.3593699],
                [106.6458381, 11.3594305],
                [106.6458621, 11.3595279],
                [106.6458566, 11.3595833],
                [106.6458233, 11.3596284],
                [106.6457712, 11.3596506],
                [106.6457425, 11.359651],
                [106.6456899, 11.3596299],
                [106.6456183, 11.3595503],
                [106.6455298, 11.3594893],
                [106.6454807, 11.3594669],
                [106.6453705, 11.3594398],
                [106.6453136, 11.3594379],
                [106.6452018, 11.3594573],
                [106.6450998, 11.3595063],
                [106.6449953, 11.3595801],
                [106.6449378, 11.3596088],
                [106.6448777, 11.3596316],
                [106.6447325, 11.3596687],
                [106.6446936, 11.3596864],
                [106.6445619, 11.3597794],
                [106.6445081, 11.3598049],
                [106.6443925, 11.3598343],
                [106.644284, 11.3598347],
                [106.6441887, 11.3598534],
                [106.6441074, 11.359901],
                [106.644048, 11.3599701],
                [106.6439098, 11.3601],
                [106.6438648, 11.3601291],
                [106.6438154, 11.3601503],
                [106.643763, 11.3601629],
                [106.6437092, 11.3601666],
                [106.6436036, 11.360147],
                [106.6435549, 11.3601243],
                [106.643512, 11.3600947],
                [106.6433703, 11.3599574],
                [106.6433353, 11.3599341],
                [106.6432959, 11.3599189],
                [106.6432541, 11.3599126],
                [106.6432119, 11.3599153],
                [106.6431369, 11.3599455],
                [106.6430479, 11.3600312],
                [106.6430074, 11.3600541],
                [106.642963, 11.3600685],
                [106.6429166, 11.3600736],
                [106.64287, 11.3600694],
                [106.6428253, 11.3600559],
                [106.6427135, 11.3599975],
                [106.6426202, 11.3599684],
                [106.6425624, 11.3599319],
                [106.6425406, 11.3599055],
                [106.6425159, 11.3598426],
                [106.6425189, 11.359778],
                [106.6425061, 11.3597174],
                [106.6424664, 11.3596694],
                [106.6424094, 11.3596446],
                [106.6423479, 11.3596476],
                [106.6422942, 11.3596772],
                [106.6421869, 11.3596918],
                [106.6419706, 11.3597011],
                [106.6418624, 11.3596956],
                [106.6415908, 11.3596546],
                [106.6413662, 11.3595948],
                [106.6412729, 11.3595763],
                [106.6411781, 11.3595668],
                [106.6410829, 11.3595662],
                [106.6408629, 11.3595878],
                [106.6408001, 11.3595825],
                [106.6406368, 11.3595445],
                [106.6405326, 11.3595548],
                [106.6404833, 11.3595729],
                [106.640438, 11.3595991],
                [106.6403245, 11.3597068],
                [106.64028, 11.3597348],
                [106.6401806, 11.3597693],
                [106.6401011, 11.3597748],
                [106.6399423, 11.3597646],
                [106.6396094, 11.3597205],
                [106.6393926, 11.3597208],
                [106.6392245, 11.3597424],
                [106.6391026, 11.3597396],
                [106.6389853, 11.3597069],
                [106.6388196, 11.3596135],
                [106.638697, 11.3595708],
                [106.6385788, 11.3595539],
                [106.6382967, 11.359555],
                [106.6379847, 11.3596083],
                [106.6378257, 11.3596431],
                [106.6375481, 11.3597262],
                [106.6373283, 11.3598125],
                [106.6372409, 11.3598397],
                [106.63706, 11.3598683],
                [106.6368769, 11.3598619],
                [106.6366986, 11.3598208],
                [106.6365527, 11.359758],
                [106.6364235, 11.3597184],
                [106.6363565, 11.3597088],
                [106.6362456, 11.3597082],
                [106.6361624, 11.3596869],
                [106.6360945, 11.3596352],
                [106.6360698, 11.3596004],
                [106.6360527, 11.3595615],
                [106.6360428, 11.3594961],
                [106.6360236, 11.3594327],
                [106.6359954, 11.3593726],
                [106.6359588, 11.359317],
                [106.6358864, 11.3592455],
                [106.6357939, 11.3592016],
                [106.6357434, 11.3591916],
                [106.6356408, 11.359197],
                [106.6355916, 11.3592123],
                [106.6355106, 11.3592607],
                [106.6354553, 11.3593265],
                [106.6354368, 11.3593652],
                [106.6354209, 11.3594395],
                [106.6354076, 11.3594702],
                [106.6353862, 11.3594961],
                [106.6353583, 11.3595151],
                [106.6353261, 11.3595259],
                [106.6352921, 11.3595276],
                [106.635259, 11.35952],
                [106.6352293, 11.3595037],
                [106.6351694, 11.3594507],
                [106.6351336, 11.3594323],
                [106.6350952, 11.3594198],
                [106.6350147, 11.3594143],
                [106.6349076, 11.3594458],
                [106.6348593, 11.3594737],
                [106.634753, 11.3595652],
                [106.6346722, 11.3595925],
                [106.6346293, 11.3595938],
                [106.6345471, 11.3595713],
                [106.6344805, 11.3595189],
                [106.6344402, 11.3594451],
                [106.6344321, 11.3594038],
                [106.6344412, 11.3592839],
                [106.6344181, 11.3592091],
                [106.6343671, 11.359149],
                [106.6342973, 11.3591136],
                [106.6340424, 11.3590941],
                [106.6339802, 11.3590836],
                [106.6338631, 11.3590382],
                [106.6337278, 11.3589449],
                [106.6336308, 11.3589132],
                [106.6335285, 11.3589121],
                [106.6334785, 11.3589232],
                [106.6334308, 11.3589417],
                [106.6333042, 11.3590218],
                [106.6332073, 11.3590437],
                [106.6331574, 11.3590414],
                [106.6330631, 11.3590105],
                [106.6329502, 11.3589243],
                [106.6329082, 11.3589039],
                [106.6328635, 11.3588902],
                [106.6327703, 11.3588841],
                [106.6327241, 11.3588918],
                [106.6326383, 11.3589278],
                [106.6325235, 11.3590115],
                [106.6323441, 11.3591065],
                [106.6322907, 11.3591448],
                [106.6321566, 11.3592658],
                [106.6320532, 11.3593174],
                [106.6319971, 11.3593322],
                [106.6318812, 11.359338],
                [106.6317682, 11.3593123],
                [106.6316405, 11.3592518],
                [106.6315989, 11.3592455],
                [106.6315162, 11.3592579],
                [106.6314452, 11.3593014],
                [106.6314179, 11.3593328],
                [106.6313559, 11.3594409],
                [106.63127, 11.359532],
                [106.6311838, 11.3595908],
                [106.631034, 11.3596623],
                [106.6309937, 11.3596716],
                [106.6309113, 11.3596667],
                [106.6308369, 11.3596317],
                [106.6307814, 11.3595719],
                [106.6307333, 11.3594498],
                [106.6307072, 11.3594123],
                [106.6306749, 11.3593798],
                [106.6305953, 11.3593341],
                [106.6305506, 11.3593222],
                [106.6304584, 11.3593223],
                [106.6303719, 11.3593537],
                [106.6302832, 11.3594188],
                [106.63022, 11.3594316],
                [106.6301574, 11.3594161],
                [106.6301304, 11.3593985],
                [106.6300687, 11.359338],
                [106.6300328, 11.3593148],
                [106.6299934, 11.3592979],
                [106.6299087, 11.3592851],
                [106.629866, 11.3592896],
                [106.6297343, 11.3593373],
                [106.6296859, 11.3593448],
                [106.629637, 11.3593451],
                [106.6295529, 11.3593279],
                [106.6295157, 11.359327],
                [106.6294796, 11.3593354],
                [106.6294468, 11.3593526],
                [106.6294196, 11.3593775],
                [106.6293687, 11.3594458],
                [106.6293325, 11.3594737],
                [106.6292927, 11.3594962],
                [106.6292052, 11.3595233],
                [106.6290392, 11.3595255],
                [106.6288423, 11.3595681],
                [106.6287716, 11.3595728],
                [106.6285844, 11.3595598],
                [106.6284698, 11.3595817],
                [106.6283656, 11.3596335],
                [106.6282207, 11.3597617],
                [106.6281627, 11.3597975],
                [106.6280349, 11.3598453],
                [106.6279673, 11.3598564],
                [106.6278988, 11.3598587],
                [106.627728, 11.3598477],
                [106.6276425, 11.3598544],
                [106.6274972, 11.3598852],
                [106.6274345, 11.3598922],
                [106.6273714, 11.35989],
                [106.6273094, 11.3598788],
                [106.6272497, 11.3598587],
                [106.6271214, 11.3597999],
                [106.6270533, 11.3597805],
                [106.6269836, 11.3597679],
                [106.626842, 11.359764],
                [106.6263178, 11.3598327],
                [106.6261835, 11.3598593],
                [106.6259475, 11.3599243],
                [106.6257503, 11.3600106],
                [106.6255695, 11.3601265],
                [106.6254867, 11.3601946],
                [106.6252386, 11.3604521],
                [106.6251437, 11.3605346],
                [106.6249377, 11.3606791],
                [106.6248262, 11.3607413],
                [106.6245935, 11.3608468],
                [106.624473, 11.3608899],
                [106.624064, 11.3610009],
                [106.6239241, 11.3610489],
                [106.6236529, 11.3611656],
                [106.6234546, 11.3612777],
                [106.6232762, 11.3614182],
                [106.6231958, 11.361498],
                [106.6231218, 11.3615837],
                [106.6230343, 11.3617057],
                [106.6229392, 11.3618221],
                [106.6228368, 11.3619323],
                [106.6226382, 11.3621119],
                [106.6224855, 11.3622335],
                [106.6223574, 11.3623227],
                [106.6222127, 11.3623942],
                [106.6221352, 11.3624175],
                [106.6219746, 11.3624379],
                [106.6218134, 11.3624225],
                [106.6217351, 11.3624016],
                [106.6215427, 11.3623322],
                [106.6214427, 11.3623095],
                [106.6211718, 11.3622674],
                [106.6208026, 11.362184],
                [106.6204274, 11.3621318],
                [106.6202384, 11.3621176],
                [106.6198494, 11.3621078],
                [106.6191698, 11.3621224],
                [106.6191102, 11.3621295],
                [106.618996, 11.3621653],
                [106.6189433, 11.3621935],
                [106.6188076, 11.3622962],
                [106.6187042, 11.3623366],
                [106.6185932, 11.3623459],
                [106.6185379, 11.3623384],
                [106.6183924, 11.3623019],
                [106.6183173, 11.3622963],
                [106.6181678, 11.3623107],
                [106.6180953, 11.3623306],
                [106.6178742, 11.362421],
                [106.6176503, 11.3624847],
                [106.6174559, 11.3625542],
                [106.617372, 11.3625951],
                [106.6172152, 11.3626952],
                [106.6170793, 11.3628145],
                [106.6169661, 11.3629485],
                [106.6169021, 11.3629978],
                [106.6167641, 11.3630824],
                [106.6166355, 11.3631398],
                [106.6164642, 11.3631935],
                [106.6163412, 11.3632204],
                [106.6162164, 11.3632381],
                [106.6160907, 11.3632464],
                [106.6156398, 11.365332],
                [106.614756, 11.3695068],
                [106.6143187, 11.3716171],
                [106.6134652, 11.3758047],
                [106.6133383, 11.3765012],
                [106.61324, 11.3771693],
                [106.6132007, 11.3775068],
                [106.6130903, 11.3787312],
                [106.6130165, 11.3800501],
                [106.6129995, 11.3806409],
                [106.6130099, 11.3812134],
                [106.6132123, 11.387457],
                [106.6120328, 11.3873949],
                [106.6114435, 11.3873557],
                [106.6089424, 11.3871501],
                [106.6048184, 11.3868588],
                [106.6005698, 11.386517],
                [106.5998188, 11.3864644],
                [106.5995091, 11.3864307],
                [106.5992848, 11.386382],
                [106.5990783, 11.3863138],
                [106.5984512, 11.3860354],
                [106.5980472, 11.3858265],
                [106.5978629, 11.3857169],
                [106.5975631, 11.3855135],
                [106.596981, 11.3850576],
                [106.5966831, 11.3848122],
                [106.5965878, 11.3847384],
                [106.5960066, 11.3842508],
                [106.5943927, 11.3829252],
                [106.5934448, 11.3821697],
                [106.5931077, 11.3818604],
                [106.5920269, 11.3807427],
                [106.5918407, 11.380797],
                [106.5915028, 11.380916],
                [106.5912122, 11.3810364],
                [106.5908372, 11.381233],
                [106.5907127, 11.3812896],
                [106.5905009, 11.3813679],
                [106.5903916, 11.3813969],
                [106.5901685, 11.3814341],
                [106.5897884, 11.3814513],
                [106.5896559, 11.3814684],
                [106.5892868, 11.3815391],
                [106.5889167, 11.3815862],
                [106.5885723, 11.3816446],
                [106.5883859, 11.3816651],
                [106.5883026, 11.3816654],
                [106.5881376, 11.3816434],
                [106.5880573, 11.3816213],
                [106.5877843, 11.3815047],
                [106.5876816, 11.381474],
                [106.5875765, 11.3814522],
                [106.5872907, 11.3814246],
                [106.5872029, 11.3814054],
                [106.5870343, 11.3813442],
                [106.5869097, 11.381275],
                [106.5867805, 11.3811824],
                [106.5866982, 11.3811158],
                [106.5865493, 11.3809751],
                [106.5864723, 11.3809123],
                [106.5863894, 11.380857],
                [106.5863015, 11.38081],
                [106.5860848, 11.3807238],
                [106.5860273, 11.3806902],
                [106.5859249, 11.380606],
                [106.5858238, 11.3804991],
                [106.585705, 11.3804114],
                [106.5855718, 11.3803454],
                [106.5855012, 11.3803213],
                [106.5853051, 11.3802745],
                [106.5852479, 11.380247],
                [106.5851954, 11.3802116],
                [106.5850483, 11.3800887],
                [106.5849411, 11.3800351],
                [106.5848833, 11.3800184],
                [106.5847635, 11.3800065],
                [106.5846145, 11.3800151],
                [106.5845399, 11.380011],
                [106.5844162, 11.3799914],
                [106.5843151, 11.3799922],
                [106.5842181, 11.3800199],
                [106.58407, 11.3801103],
                [106.584013, 11.3801342],
                [106.5838921, 11.3801614],
                [106.5837848, 11.3801617],
                [106.5836511, 11.3801377],
                [106.5834808, 11.3800775],
                [106.583401, 11.3800354],
                [106.583232, 11.3799276],
                [106.5830923, 11.3798589],
                [106.5830176, 11.3798361],
                [106.5828628, 11.3798151],
                [106.5827071, 11.3798275],
                [106.5823752, 11.3799239],
                [106.5822025, 11.379938],
                [106.5820306, 11.3799169],
                [106.5818105, 11.3798501],
                [106.5816671, 11.3798414],
                [106.581526, 11.3798676],
                [106.5813115, 11.3799658],
                [106.5811532, 11.3800117],
                [106.5809891, 11.3800294],
                [106.5809065, 11.3800275],
                [106.5807384, 11.3800001],
                [106.5806574, 11.379974],
                [106.5805056, 11.3798981],
                [106.5803727, 11.3797935],
                [106.5802422, 11.3796634],
                [106.5800915, 11.3795564],
                [106.5799248, 11.3794753],
                [106.5797443, 11.3794064],
                [106.5796585, 11.3793624],
                [106.5795246, 11.3792765],
                [106.5794102, 11.3792247],
                [106.5793491, 11.3792096],
                [106.5792396, 11.379201],
                [106.5789402, 11.3792383],
                [106.578872, 11.3792396],
                [106.5787382, 11.3792159],
                [106.5786259, 11.3791652],
                [106.5785193, 11.3791335],
                [106.5784198, 11.3791297],
                [106.5781661, 11.3790947],
                [106.5780877, 11.3790946],
                [106.5778753, 11.3791199],
                [106.57774, 11.3791099],
                [106.5776098, 11.3790726],
                [106.5775484, 11.3790441],
                [106.5773622, 11.3789329],
                [106.5771549, 11.378843],
                [106.576904, 11.3787171],
                [106.5768268, 11.3786896],
                [106.5766664, 11.3786554],
                [106.5765845, 11.3786489],
                [106.5763568, 11.3786551],
                [106.5762845, 11.3786462],
                [106.5761449, 11.3786055],
                [106.5760176, 11.3785363],
                [106.5759204, 11.378454],
                [106.5757466, 11.3782409],
                [106.5754979, 11.3778957],
                [106.5754116, 11.3777975],
                [106.5752202, 11.3776189],
                [106.5750338, 11.377484],
                [106.5749349, 11.3774248],
                [106.5747273, 11.3773237],
                [106.5738379, 11.3769691],
                [106.5736556, 11.3768874],
                [106.573306, 11.3767049],
                [106.5731391, 11.3766049],
                [106.5728127, 11.3763896],
                [106.5726373, 11.3762592],
                [106.572471, 11.3761148],
                [106.5723747, 11.3760156],
                [106.5722853, 11.3759104],
                [106.5722033, 11.3757996],
                [106.5719352, 11.3755945],
                [106.5718451, 11.3754933],
                [106.5717181, 11.3752676],
                [106.5715988, 11.375117],
                [106.5715295, 11.3750501],
                [106.571322, 11.374888],
                [106.5712652, 11.3748272],
                [106.5712157, 11.3747606],
                [106.5711569, 11.374653],
                [106.5710897, 11.3745503],
                [106.5709216, 11.3743339],
                [106.570859, 11.374197],
                [106.5708306, 11.3740567],
                [106.5707873, 11.3739275],
                [106.570714, 11.3738119],
                [106.5706149, 11.3737167],
                [106.5704695, 11.3736304],
                [106.5703306, 11.3735728],
                [106.5702661, 11.3735591],
                [106.5702003, 11.3735548],
                [106.5701346, 11.37356],
                [106.5699601, 11.3736081],
                [106.5699025, 11.3736137],
                [106.5697876, 11.3736022],
                [106.5697324, 11.3735852],
                [106.5696866, 11.3735647],
                [106.569559, 11.3734857],
                [106.5694794, 11.3734256],
                [106.5692833, 11.3732415],
                [106.569143, 11.3731456],
                [106.5690672, 11.3731066],
                [106.5689471, 11.3730622],
                [106.5688842, 11.373049],
                [106.5688204, 11.3730418],
                [106.5686488, 11.3730409],
                [106.5685545, 11.3730122],
                [106.568512, 11.3729872],
                [106.5684741, 11.372956],
                [106.5684157, 11.372878],
                [106.568361, 11.3727095],
                [106.568331, 11.3726522],
                [106.5682492, 11.3725518],
                [106.5681662, 11.3724889],
                [106.5678517, 11.3723051],
                [106.5674315, 11.3720976],
                [106.5669264, 11.371816],
                [106.5666199, 11.3716713],
                [106.5664602, 11.3716128],
                [106.5664233, 11.3715921],
                [106.5663652, 11.3715319],
                [106.5663347, 11.3714547],
                [106.5663314, 11.3714132],
                [106.5663444, 11.3712893],
                [106.5663391, 11.3712271],
                [106.5663087, 11.3711153],
                [106.5662517, 11.3710247],
                [106.5662132, 11.3709868],
                [106.5661693, 11.3709551],
                [106.5660694, 11.3709132],
                [106.5660158, 11.370904],
                [106.5659073, 11.3709099],
                [106.565855, 11.370925],
                [106.5657051, 11.3710068],
                [106.5656501, 11.371027],
                [106.5655348, 11.3710477],
                [106.5654094, 11.3710549],
                [106.5653484, 11.3710699],
                [106.5652898, 11.3710921],
                [106.5651401, 11.3711764],
                [106.5650338, 11.371203],
                [106.5649789, 11.3712049],
                [106.5648709, 11.3711856],
                [106.5648202, 11.3711648],
                [106.564773, 11.3711371],
                [106.5646434, 11.3710369],
                [106.5644956, 11.3709649],
                [106.5643358, 11.3709241],
                [106.5641641, 11.3708991],
                [106.5639404, 11.3708253],
                [106.5637927, 11.3708049],
                [106.563718, 11.3708056],
                [106.5634891, 11.3708389],
                [106.5634106, 11.3708405],
                [106.5633323, 11.3708347],
                [106.5632047, 11.3708089],
                [106.5631138, 11.3707611],
                [106.5630754, 11.3707271],
                [106.563016, 11.3706399],
                [106.5629973, 11.3705888],
                [106.5629874, 11.3705353],
                [106.5629949, 11.3704271],
                [106.5630121, 11.3703754],
                [106.5630777, 11.3702587],
                [106.5630864, 11.3702199],
                [106.5630795, 11.3701412],
                [106.5630415, 11.3700715],
                [106.5630126, 11.3700437],
                [106.5629426, 11.3700082],
                [106.562829, 11.369986],
                [106.5627525, 11.3699762],
                [106.5625211, 11.3699687],
                [106.5624447, 11.3699567],
                [106.5623147, 11.3699196],
                [106.5621867, 11.3698501],
                [106.5621281, 11.3698386],
                [106.5620986, 11.3698448],
                [106.56205, 11.3698789],
                [106.5620264, 11.3699327],
                [106.5620244, 11.3700157],
                [106.5619943, 11.3700932],
                [106.5619456, 11.3701506],
                [106.5618808, 11.3701897],
                [106.5618065, 11.3702062],
                [106.5617262, 11.3701972],
                [106.5616361, 11.370172],
                [106.5615293, 11.3701706],
                [106.5614766, 11.3701799],
                [106.5614101, 11.3702019],
                [106.5612855, 11.3702648],
                [106.5612286, 11.3703052],
                [106.5611436, 11.3703845],
                [106.5610639, 11.370434],
                [106.5609723, 11.370456],
                [106.56084, 11.370459],
                [106.560775, 11.3704722],
                [106.5607122, 11.3704928],
                [106.56057, 11.370556],
                [106.5604489, 11.3705951],
                [106.5601999, 11.370648],
                [106.5600842, 11.3706545],
                [106.5599713, 11.3706293],
                [106.5599187, 11.3706053],
                [106.5598297, 11.3705405],
                [106.5597351, 11.3704939],
                [106.5596304, 11.3704795],
                [106.5595776, 11.370485],
                [106.5595189, 11.3705015],
                [106.5594094, 11.3705545],
                [106.5593085, 11.3706391],
                [106.5592206, 11.3707506],
                [106.5591854, 11.3708122],
                [106.55912, 11.3709572],
                [106.5590583, 11.3710213],
                [106.5589785, 11.3710617],
                [106.5589346, 11.3710716],
                [106.5588448, 11.371069],
                [106.5588015, 11.3710567],
                [106.5586676, 11.3709931],
                [106.5585227, 11.3709601],
                [106.558255, 11.3709557],
                [106.5582173, 11.3709604],
                [106.5581485, 11.3709909],
                [106.55812, 11.3710155],
                [106.5580643, 11.3710909],
                [106.5580125, 11.3711145],
                [106.5579837, 11.3711155],
                [106.5579303, 11.3710959],
                [106.5578716, 11.3710229],
                [106.5577972, 11.3709687],
                [106.5576851, 11.3709287],
                [106.5575659, 11.3709223],
                [106.5574577, 11.3709471],
                [106.5574109, 11.3709681],
                [106.557247, 11.371068],
                [106.5570774, 11.37113],
                [106.5568197, 11.3711811],
                [106.5565804, 11.3712581],
                [106.5563011, 11.3713083],
                [106.5561919, 11.371353],
                [106.5560961, 11.3714211],
                [106.5560493, 11.3714693],
                [106.5559962, 11.3715108],
                [106.5558863, 11.3715666],
                [106.5557767, 11.3715904],
                [106.5556183, 11.3715891],
                [106.5555379, 11.3716052],
                [106.5555115, 11.3716207],
                [106.555476, 11.3716694],
                [106.5554696, 11.371699],
                [106.5554716, 11.3717292],
                [106.5555063, 11.3718073],
                [106.555506, 11.3718352],
                [106.5554971, 11.3718617],
                [106.5554578, 11.371901],
                [106.5554075, 11.3719115],
                [106.5553395, 11.3718964],
                [106.55526, 11.3718559],
                [106.5551763, 11.3718343],
                [106.5550907, 11.3718466],
                [106.5550169, 11.371891],
                [106.5549669, 11.3719601],
                [106.5549305, 11.3720553],
                [106.5548704, 11.3721381],
                [106.5547937, 11.3722013],
                [106.5546084, 11.3723235],
                [106.5545069, 11.3724118],
                [106.5544711, 11.3724556],
                [106.5544416, 11.3725038],
                [106.5544036, 11.3726096],
                [106.5543949, 11.3727059],
                [106.554409, 11.3728016],
                [106.5544041, 11.3728773],
                [106.5543886, 11.3729123],
                [106.5543355, 11.3729674],
                [106.5543007, 11.3729846],
                [106.5542239, 11.3729936],
                [106.5541166, 11.3729699],
                [106.5539342, 11.372962],
                [106.5536714, 11.3729304],
                [106.5533139, 11.3729075],
                [106.5532218, 11.3729101],
                [106.5531304, 11.3729224],
                [106.553041, 11.3729443],
                [106.5529545, 11.3729756],
                [106.5528719, 11.3730158],
                [106.5527386, 11.3731074],
                [106.5526416, 11.3732084],
                [106.5525671, 11.3733263],
                [106.552518, 11.3734564],
                [106.5524748, 11.3736481],
                [106.5524054, 11.3738324],
                [106.5523442, 11.3739507],
                [106.5522744, 11.3740644],
                [106.5521964, 11.3741728],
                [106.5521106, 11.3742754],
                [106.5520173, 11.3743716],
                [106.5517351, 11.3746115],
                [106.5516516, 11.3746949],
                [106.5515015, 11.3748763],
                [106.5514186, 11.3750004],
                [106.5513278, 11.375119],
                [106.5512292, 11.3752315],
                [106.5511233, 11.3753374],
                [106.5510106, 11.3754364],
                [106.5508175, 11.375579],
                [106.5505545, 11.3757912],
                [106.5504188, 11.3759321],
                [106.5503049, 11.3760905],
                [106.5501705, 11.3763444],
                [106.550085, 11.3764758],
                [106.5500355, 11.376588],
                [106.5500218, 11.3766478],
                [106.5500176, 11.37677],
                [106.5500273, 11.3768305],
                [106.5500692, 11.3769457],
                [106.550106, 11.3770061],
                [106.5501346, 11.3770707],
                [106.5501543, 11.3771384],
                [106.550165, 11.377208],
                [106.5501662, 11.3772783],
                [106.5501582, 11.3773483],
                [106.5501409, 11.3774166],
                [106.5500894, 11.3775289],
                [106.5499431, 11.3777504],
                [106.5498647, 11.3778965],
                [106.5497205, 11.3781947],
                [106.5496525, 11.3783657],
                [106.5496282, 11.3784543],
                [106.5496031, 11.3785992],
                [106.5495633, 11.3787023],
                [106.5494936, 11.3787888],
                [106.5494138, 11.3788442],
                [106.5493836, 11.3788725],
                [106.5493605, 11.3789065],
                [106.5493456, 11.3789448],
                [106.5493398, 11.3789853],
                [106.5493432, 11.3790261],
                [106.5493885, 11.3791357],
                [106.5493942, 11.3791742],
                [106.5493835, 11.379251],
                [106.5493673, 11.3792866],
                [106.5493163, 11.3793459],
                [106.5492833, 11.3793675],
                [106.549176, 11.3794148],
                [106.5491385, 11.3794407],
                [106.549079, 11.3795085],
                [106.5490586, 11.3795487],
                [106.5490392, 11.3796362],
                [106.5490408, 11.3796811],
                [106.5490848, 11.379833],
                [106.5490887, 11.3798897],
                [106.5490832, 11.3799462],
                [106.5490558, 11.3800327],
                [106.5490082, 11.3800811],
                [106.5489766, 11.3800948],
                [106.548908, 11.380097],
                [106.5488166, 11.3800532],
                [106.5487495, 11.3800459],
                [106.5486878, 11.380063],
                [106.5486369, 11.3801011],
                [106.5486161, 11.380129],
                [106.5485993, 11.3801955],
                [106.5486181, 11.3802615],
                [106.5486544, 11.3803201],
                [106.5486613, 11.3803884],
                [106.5486529, 11.3804219],
                [106.548641, 11.3804469],
                [106.5485886, 11.3805113],
                [106.5485118, 11.3805843],
                [106.5484815, 11.3806275],
                [106.5484458, 11.3807033],
                [106.5484058, 11.3807511],
                [106.5483491, 11.3807781],
                [106.548282, 11.3807787],
                [106.5482131, 11.3807965],
                [106.54816, 11.3808431],
                [106.548118, 11.3809369],
                [106.5480652, 11.3809801],
                [106.5480323, 11.3809908],
                [106.5479977, 11.3809932],
                [106.5479411, 11.3809782],
                [106.5478191, 11.3808822],
                [106.5477535, 11.3808555],
                [106.5476826, 11.3808602],
                [106.5475878, 11.3809136],
                [106.5474409, 11.3809572],
                [106.5473896, 11.3809846],
                [106.5473291, 11.3810372],
                [106.5472931, 11.3811082],
                [106.5472854, 11.3811682],
                [106.547258, 11.3812536],
                [106.5472188, 11.3812968],
                [106.5471641, 11.3813182],
                [106.5471344, 11.3813191],
                [106.5470766, 11.3813],
                [106.5470137, 11.3812968],
                [106.5469575, 11.3813248],
                [106.5469229, 11.3813764],
                [106.546917, 11.3814223],
                [106.5468816, 11.3815077],
                [106.5468127, 11.3815955],
                [106.546803, 11.3816592],
                [106.5468266, 11.3817194],
                [106.5468773, 11.3817603],
                [106.5469399, 11.3817856],
                [106.5469889, 11.3818315],
                [106.5470148, 11.3818822],
                [106.5470265, 11.3819516],
                [106.5470125, 11.3820442],
                [106.546993, 11.3820871],
                [106.5469203, 11.3821847],
                [106.5469092, 11.3822203],
                [106.5469133, 11.3822941],
                [106.5469654, 11.3823899],
                [106.5469745, 11.382461],
                [106.5469541, 11.3825298],
                [106.5469043, 11.3825873],
                [106.5468675, 11.3826579],
                [106.5468651, 11.3827372],
                [106.5469141, 11.3828528],
                [106.5469202, 11.3828954],
                [106.5469176, 11.3829383],
                [106.5468893, 11.3830529],
                [106.5468863, 11.3831472],
                [106.5468783, 11.3831818],
                [106.5468379, 11.3832397],
                [106.546774, 11.3832715],
                [106.5467024, 11.3832693],
                [106.5466692, 11.3832552],
                [106.5466185, 11.3832056],
                [106.5466041, 11.3831731],
                [106.5465938, 11.3830904],
                [106.5465812, 11.3830695],
                [106.5465408, 11.3830424],
                [106.5464918, 11.3830417],
                [106.5464693, 11.3830517],
                [106.5464374, 11.3830882],
                [106.5464182, 11.3831851],
                [106.5463801, 11.383257],
                [106.5463276, 11.3832931],
                [106.546296, 11.3832997],
                [106.5462332, 11.3832877],
                [106.5462064, 11.38327],
                [106.5461719, 11.3832186],
                [106.5461257, 11.3831789],
                [106.5460653, 11.3831682],
                [106.5460351, 11.383175],
                [106.5459764, 11.383215],
                [106.5459537, 11.3832422],
                [106.5459251, 11.3833064],
                [106.5459074, 11.3833833],
                [106.5458608, 11.3834475],
                [106.5458287, 11.3834715],
                [106.5457924, 11.383489],
                [106.545713, 11.3835013],
                [106.5455949, 11.3834764],
                [106.5455149, 11.3834857],
                [106.5454436, 11.3835224],
                [106.5453505, 11.3836253],
                [106.5452655, 11.3836769],
                [106.545168, 11.3836991],
                [106.5450823, 11.3836927],
                [106.5450465, 11.3836962],
                [106.5450128, 11.3837085],
                [106.5449834, 11.3837288],
                [106.5449602, 11.3837558],
                [106.5449448, 11.3837876],
                [106.544919, 11.3839142],
                [106.5448797, 11.3839803],
                [106.5448187, 11.3840279],
                [106.5447226, 11.3840647],
                [106.5446991, 11.3840865],
                [106.5446736, 11.3841441],
                [106.5446733, 11.3841757],
                [106.5446978, 11.3842338],
                [106.544749, 11.3842714],
                [106.5447822, 11.3842792],
                [106.544812, 11.3842957],
                [106.5448359, 11.3843195],
                [106.5448523, 11.3843489],
                [106.5448599, 11.3843815],
                [106.544858, 11.3844149],
                [106.5448468, 11.3844466],
                [106.5448271, 11.384474],
                [106.5448005, 11.384495],
                [106.5447691, 11.3845081],
                [106.5446538, 11.3845186],
                [106.5445914, 11.3845424],
                [106.5445436, 11.3845882],
                [106.5445006, 11.384679],
                [106.5444482, 11.3847224],
                [106.5443815, 11.3847382],
                [106.5443078, 11.384737],
                [106.54424, 11.3847654],
                [106.5441897, 11.3848184],
                [106.5441388, 11.3849262],
                [106.544094, 11.3849842],
                [106.5439886, 11.3850866],
                [106.5439271, 11.3851311],
                [106.5437926, 11.3852024],
                [106.5436468, 11.3852476],
                [106.5435714, 11.38526],
                [106.5434334, 11.3852693],
                [106.5432604, 11.3852601],
                [106.5431528, 11.3852423],
                [106.543091, 11.3852067],
                [106.5430496, 11.3851493],
                [106.5430361, 11.3850862],
                [106.5430126, 11.3850323],
                [106.5429693, 11.3849919],
                [106.5428879, 11.3849649],
                [106.5428448, 11.3849623],
                [106.5427606, 11.3849794],
                [106.5427221, 11.3849985],
                [106.5426582, 11.385055],
                [106.5425482, 11.3852154],
                [106.5424212, 11.3853451],
                [106.5422386, 11.3854997],
                [106.5420881, 11.3856528],
                [106.5418788, 11.3857991],
                [106.5418053, 11.3858859],
                [106.5416944, 11.3860772],
                [106.5416881, 11.3861146],
                [106.5416912, 11.3861523],
                [106.5417414, 11.3862598],
                [106.5417517, 11.3863398],
                [106.5417329, 11.3864183],
                [106.5417131, 11.3864538],
                [106.5416618, 11.3865078],
                [106.541576, 11.3865623],
                [106.5414389, 11.3866236],
                [106.5413601, 11.3866482],
                [106.5411298, 11.3866981],
                [106.5410583, 11.3867249],
                [106.5409384, 11.3867906],
                [106.5408213, 11.3868909],
                [106.5407623, 11.3869247],
                [106.5406948, 11.386935],
                [106.5406036, 11.3869245],
                [106.5405523, 11.3869308],
                [106.5405301, 11.3869444],
                [106.5405018, 11.3869868],
                [106.540498, 11.3870122],
                [106.5405222, 11.3870949],
                [106.5405211, 11.3871255],
                [106.5404967, 11.3871813],
                [106.5404748, 11.3872031],
                [106.5404233, 11.387227],
                [106.5403713, 11.3872274],
                [106.5402201, 11.3872568],
                [106.540076, 11.3873096],
                [106.5400082, 11.3873452],
                [106.5398899, 11.3874282],
                [106.5397735, 11.3875635],
                [106.5397149, 11.3875977],
                [106.5396389, 11.3876108],
                [106.5395502, 11.3876102],
                [106.5394543, 11.3876305],
                [106.5393539, 11.3876795],
                [106.5392835, 11.3877291],
                [106.5392548, 11.3877393],
                [106.5391941, 11.387741],
                [106.5391344, 11.3877156],
                [106.5391103, 11.3876937],
                [106.5390573, 11.3876033],
                [106.5390278, 11.3875748],
                [106.538993, 11.387553],
                [106.5389223, 11.3875329],
                [106.5388844, 11.3875329],
                [106.5388738, 11.3875694],
                [106.5388709, 11.387645],
                [106.5388786, 11.3876819],
                [106.5389139, 11.3877668],
                [106.5389528, 11.3878319],
                [106.5390758, 11.3879687],
                [106.5391231, 11.3880052],
                [106.5392891, 11.3881087],
                [106.5393645, 11.3881709],
                [106.5394428, 11.3882552],
                [106.5394733, 11.388304],
                [106.5394973, 11.388356],
                [106.5395245, 11.388467],
                [106.5395227, 11.388581],
                [106.539505, 11.3886922],
                [106.5395093, 11.3888045],
                [106.5395371, 11.3889094],
                [106.539595, 11.3890016],
                [106.5396337, 11.3890404],
                [106.5397266, 11.389099],
                [106.540005, 11.3891893],
                [106.5401548, 11.3892237],
                [106.5403948, 11.3892313],
                [106.540476, 11.3892595],
                [106.5405436, 11.3893119],
                [106.5405699, 11.3893456],
                [106.540626, 11.38946],
                [106.5406905, 11.3895161],
                [106.5407302, 11.3895332],
                [106.540816, 11.3895418],
                [106.5408584, 11.3895329],
                [106.5409656, 11.3894874],
                [106.541059, 11.3894655],
                [106.5410965, 11.3894682],
                [106.541132, 11.3894803],
                [106.5411632, 11.3895009],
                [106.5411879, 11.3895287],
                [106.54121, 11.3895833],
                [106.5412119, 11.3896494],
                [106.541204, 11.3896928],
                [106.5411662, 11.3897725],
                [106.5411019, 11.3898356],
                [106.5410732, 11.3898714],
                [106.5410526, 11.3899122],
                [106.5410384, 11.3899816],
                [106.541044, 11.3900579],
                [106.5410562, 11.3901075],
                [106.5411006, 11.3901995],
                [106.5412145, 11.3903314],
                [106.541282, 11.3904542],
                [106.541344, 11.3905038],
                [106.5414208, 11.3905257],
                [106.5415003, 11.3905165],
                [106.5415881, 11.390476],
                [106.5416167, 11.3904745],
                [106.5416701, 11.3904935],
                [106.5417061, 11.3905366],
                [106.5417141, 11.3905636],
                [106.5417139, 11.390657],
                [106.5417416, 11.3907463],
                [106.5417945, 11.3908239],
                [106.541832, 11.3908584],
                [106.54196, 11.3909417],
                [106.5420504, 11.3909889],
                [106.5421448, 11.3910278],
                [106.5423602, 11.3910924],
                [106.5424152, 11.3911196],
                [106.5425134, 11.391192],
                [106.5425518, 11.3912322],
                [106.542583, 11.391278],
                [106.5426062, 11.3913281],
                [106.5426372, 11.3914793],
                [106.5426543, 11.3915262],
                [106.5427078, 11.3916159],
                [106.5427444, 11.3916569],
                [106.5427861, 11.3916929],
                [106.542882, 11.3917475],
                [106.5430525, 11.3917966],
                [106.543107, 11.3918241],
                [106.5432018, 11.3918998],
                [106.5433127, 11.3920545],
                [106.5433845, 11.3921223],
                [106.543428, 11.3921465],
                [106.5435728, 11.3921964],
                [106.5436424, 11.3922355],
                [106.5436922, 11.3922972],
                [106.5437072, 11.3923338],
                [106.543715, 11.3924121],
                [106.5437034, 11.3924629],
                [106.5437074, 11.3925666],
                [106.5437468, 11.392663],
                [106.5437784, 11.3927048],
                [106.5438169, 11.3927407],
                [106.54393, 11.3928211],
                [106.5439776, 11.3928714],
                [106.5440508, 11.392988],
                [106.5440915, 11.3930436],
                [106.5441396, 11.3930934],
                [106.5441942, 11.3931362],
                [106.5442542, 11.3931714],
                [106.5444146, 11.3932337],
                [106.5444574, 11.3932616],
                [106.5445141, 11.3933164],
                [106.544533, 11.3933683],
                [106.5445243, 11.3934227],
                [106.5444902, 11.3934664],
                [106.544436, 11.3935054],
                [106.5444169, 11.3935327],
                [106.5443996, 11.3935963],
                [106.5444124, 11.393661],
                [106.5444445, 11.3937064],
                [106.5444875, 11.3938082],
                [106.5445025, 11.3939689],
                [106.5444821, 11.394129],
                [106.5444016, 11.3943814],
                [106.5443696, 11.3945312],
                [106.5443684, 11.3946841],
                [106.5443795, 11.39476],
                [106.5444245, 11.3949065],
                [106.5444824, 11.3950171],
                [106.5445685, 11.3951333],
                [106.5446347, 11.3952036],
                [106.5447074, 11.3952675],
                [106.5448135, 11.395339],
                [106.5448715, 11.3953666],
                [106.5449944, 11.3954043],
                [106.5451293, 11.395432],
                [106.5452543, 11.3954892],
                [106.5453661, 11.395576],
                [106.5454585, 11.3956886],
                [106.5455235, 11.3958184],
                [106.5455445, 11.3958878],
                [106.545575, 11.3961012],
                [106.5455945, 11.3961702],
                [106.545621, 11.396237],
                [106.545747, 11.3964529],
                [106.5457823, 11.3965345],
                [106.5458578, 11.3967984],
                [106.5459316, 11.3969832],
                [106.5459371, 11.3970886],
                [106.545909, 11.3971906],
                [106.5458831, 11.3972371],
                [106.5457993, 11.3973383],
                [106.5457753, 11.3974121],
                [106.5457746, 11.397451],
                [106.545796, 11.3975256],
                [106.5458173, 11.3975584],
                [106.5458938, 11.3976323],
                [106.5459078, 11.3976629],
                [106.5459098, 11.3977293],
                [106.5458779, 11.3977879],
                [106.5458205, 11.3978232],
                [106.5457868, 11.3978289],
                [106.5457244, 11.3978555],
                [106.5456802, 11.3978925],
                [106.5456389, 11.39795],
                [106.5456049, 11.3980375],
                [106.5455979, 11.3980839],
                [106.5456053, 11.3982203],
                [106.5455915, 11.3983455],
                [106.545618, 11.3984223],
                [106.5456432, 11.3984547],
                [106.5456751, 11.3984809],
                [106.545712, 11.3984998],
                [106.5457522, 11.3985103],
                [106.5458548, 11.3985086],
                [106.5458849, 11.3985147],
                [106.5459272, 11.3985335],
                [106.5459974, 11.3985924],
                [106.5460405, 11.3986725],
                [106.5460689, 11.3988337],
                [106.5461228, 11.3989798],
                [106.5461579, 11.3991229],
                [106.5461625, 11.3992699],
                [106.5461299, 11.3994817],
                [106.5461424, 11.3996217],
                [106.5461765, 11.3997697],
                [106.5461747, 11.3999215],
                [106.5461602, 11.3999961],
                [106.546137, 11.4000687],
                [106.5460815, 11.4001772],
                [106.5460449, 11.4002263],
                [106.5459133, 11.4003587],
                [106.5458785, 11.4004107],
                [106.545832, 11.4005261],
                [106.5458213, 11.4005874],
                [106.5458258, 11.4007114],
                [106.545841, 11.4007718],
                [106.5458644, 11.4008296],
                [106.5459438, 11.400962],
                [106.5459561, 11.4010063],
                [106.545959, 11.4011224],
                [106.5459742, 11.4011854],
                [106.5460115, 11.4012312],
                [106.5460667, 11.4012539],
                [106.5460968, 11.4012545],
                [106.5462045, 11.4012265],
                [106.5462604, 11.4012255],
                [106.5463481, 11.4012449],
                [106.5464223, 11.4012948],
                [106.5464509, 11.4013291],
                [106.5464859, 11.4014103],
                [106.5464911, 11.4014543],
                [106.5464862, 11.4015667],
                [106.5465112, 11.401687],
                [106.5465678, 11.4018081],
                [106.546651, 11.4019134],
                [106.5467563, 11.4019974],
                [106.5468156, 11.4020301],
                [106.5470305, 11.4021157],
                [106.5472341, 11.4022247],
                [106.5473526, 11.4023084],
                [106.5474522, 11.4024131],
                [106.5475291, 11.4025349],
                [106.5475924, 11.402702],
                [106.5476139, 11.4027887],
                [106.5476351, 11.402953],
                [106.5476511, 11.403028],
                [106.5476762, 11.4031005],
                [106.5477098, 11.4031696],
                [106.5478161, 11.403329],
                [106.5478355, 11.4033828],
                [106.5478458, 11.403439],
                [106.5478464, 11.403496],
                [106.5478247, 11.4035935],
                [106.5476895, 11.4038462],
                [106.5476392, 11.4039801],
                [106.5476249, 11.4040501],
                [106.5476184, 11.4041926],
                [106.5476264, 11.4042635],
                [106.5476912, 11.404501],
                [106.5477088, 11.4046747],
                [106.5476939, 11.4048486],
                [106.5476744, 11.4049338],
                [106.5475951, 11.4051607],
                [106.5475817, 11.405236],
                [106.547581, 11.4053886],
                [106.5475937, 11.405464],
                [106.5476533, 11.4056529],
                [106.5476617, 11.405774],
                [106.5476397, 11.4058935],
                [106.5475738, 11.4060507],
                [106.5475633, 11.4061594],
                [106.5475869, 11.406266],
                [106.5476426, 11.4063606],
                [106.5477249, 11.4064341],
                [106.547827, 11.406497],
                [106.5479105, 11.4065823],
                [106.5479437, 11.4066317],
                [106.5479906, 11.4067406],
                [106.5480035, 11.4067985],
                [106.5480083, 11.4069072],
                [106.5479878, 11.4070552],
                [106.5479663, 11.4071525],
                [106.5479376, 11.407248],
                [106.5478455, 11.4074795],
                [106.5478264, 11.4075517],
                [106.547807, 11.4076942],
                [106.5478095, 11.4079312],
                [106.5478013, 11.4080292],
                [106.5477643, 11.4082224],
                [106.5477356, 11.4083166],
                [106.5476779, 11.4084509],
                [106.5475946, 11.4085717],
                [106.5475443, 11.4086255],
                [106.5474407, 11.4087095],
                [106.5473624, 11.4087988],
                [106.5473135, 11.4089063],
                [106.5472981, 11.409023],
                [106.5473175, 11.4091391],
                [106.54737, 11.4092449],
                [106.5474513, 11.4093315],
                [106.5475153, 11.4093734],
                [106.5476285, 11.4094751],
                [106.5476766, 11.4095339],
                [106.5477867, 11.4096965],
                [106.5478782, 11.4098076],
                [106.5479161, 11.4098896],
                [106.5479205, 11.4099796],
                [106.5479098, 11.4100236],
                [106.5478644, 11.4101019],
                [106.5477937, 11.4101777],
                [106.5477674, 11.4102222],
                [106.5477356, 11.4103201],
                [106.5477307, 11.4103779],
                [106.5477448, 11.4105511],
                [106.5477637, 11.4106653],
                [106.5478297, 11.4109181],
                [106.5478385, 11.4110424],
                [106.5478306, 11.4110936],
                [106.5477893, 11.4111887],
                [106.5476855, 11.4113054],
                [106.5476593, 11.4113502],
                [106.547629, 11.411449],
                [106.5476257, 11.4115006],
                [106.5476482, 11.4116305],
                [106.5476713, 11.4116925],
                [106.5477315, 11.4117962],
                [106.5478698, 11.4119403],
                [106.5478879, 11.4119756],
                [106.5479, 11.4120533],
                [106.5478936, 11.4120923],
                [106.5478569, 11.4121622],
                [106.5477944, 11.4122114],
                [106.5477169, 11.4122315],
                [106.5476253, 11.4122336],
                [106.5475814, 11.4122472],
                [106.5475051, 11.412297],
                [106.5474523, 11.412372],
                [106.5474079, 11.4125041],
                [106.5473869, 11.4125943],
                [106.5473738, 11.4127445],
                [106.5473791, 11.4128197],
                [106.5474066, 11.4129465],
                [106.5474115, 11.4130007],
                [106.5474069, 11.4130548],
                [106.5473929, 11.4131074],
                [106.5473699, 11.4131568],
                [106.5472632, 11.413287],
                [106.5472119, 11.4133857],
                [106.5471862, 11.4134914],
                [106.5471047, 11.4137313],
                [106.5470653, 11.4139076],
                [106.5470563, 11.4139974],
                [106.5470571, 11.4141479],
                [106.5470872, 11.4142646],
                [106.5471403, 11.4143785],
                [106.5471584, 11.4144409],
                [106.5471692, 11.4145701],
                [106.5471616, 11.4146346],
                [106.5471217, 11.4147573],
                [106.5470899, 11.4148138],
                [106.5469986, 11.4149153],
                [106.5469714, 11.4149814],
                [106.5469739, 11.4150526],
                [106.5469967, 11.4151042],
                [106.5470184, 11.4152143],
                [106.5470071, 11.4153259],
                [106.5469893, 11.4153794],
                [106.546931, 11.4154759],
                [106.5467727, 11.4156179],
                [106.5466334, 11.4157236],
                [106.5465573, 11.4157673],
                [106.5463669, 11.4158545],
                [106.5462878, 11.4159127],
                [106.5462113, 11.4159951],
                [106.5461694, 11.4160571],
                [106.5461355, 11.4161236],
                [106.54607, 11.4163438],
                [106.5460402, 11.4164158],
                [106.5460044, 11.4164851],
                [106.5459235, 11.4166037],
                [106.5457927, 11.4167506],
                [106.5456992, 11.4168429],
                [106.5453847, 11.4171094],
                [106.5451929, 11.4173137],
                [106.5449544, 11.4176409],
                [106.5447989, 11.4178844],
                [106.5447469, 11.4179538],
                [106.5446411, 11.418063],
                [106.5445655, 11.4181683],
                [106.5445197, 11.4182891],
                [106.5444765, 11.4185474],
                [106.5444722, 11.418615],
                [106.5444772, 11.4186825],
                [106.5444914, 11.4187488],
                [106.5445147, 11.4188126],
                [106.5445781, 11.4189179],
                [106.544655, 11.4189972],
                [106.5447725, 11.4190843],
                [106.5448007, 11.4191198],
                [106.544836, 11.4192026],
                [106.54484, 11.4192921],
                [106.544812, 11.4193775],
                [106.5447132, 11.4195169],
                [106.5446744, 11.4195928],
                [106.544643, 11.419672],
                [106.544564, 11.4199481],
                [106.5444815, 11.4201495],
                [106.5444435, 11.420213],
                [106.544346, 11.4203246],
                [106.5442879, 11.4203712],
                [106.5440782, 11.4204878],
                [106.5440115, 11.4205367],
                [106.5439502, 11.4205918],
                [106.5438029, 11.4207646],
                [106.5437502, 11.4208147],
                [106.5435883, 11.4209412],
                [106.5435431, 11.4209896],
                [106.5434706, 11.4210998],
                [106.5434441, 11.42116],
                [106.5433878, 11.4213362],
                [106.5433489, 11.4214269],
                [106.5433017, 11.4215137],
                [106.5432465, 11.4215958],
                [106.543135, 11.4217239],
                [106.5430232, 11.4218111],
                [106.5428785, 11.4218938],
                [106.5428171, 11.4219661],
                [106.5427968, 11.4220089],
                [106.5427802, 11.4221015],
                [106.5427843, 11.4221486],
                [106.5428052, 11.4222149],
                [106.5428326, 11.422351],
                [106.5428347, 11.4224533],
                [106.5428047, 11.4225513],
                [106.5427456, 11.4226355],
                [106.542663, 11.422698],
                [106.5425676, 11.4227321],
                [106.5422485, 11.4227691],
                [106.542103, 11.4228006],
                [106.5420254, 11.4228361],
                [106.5419668, 11.4228974],
                [106.5419357, 11.4229755],
                [106.5419364, 11.4230595],
                [106.5419689, 11.4231371],
                [106.5420003, 11.4231745],
                [106.5420239, 11.4232171],
                [106.5420389, 11.4232633],
                [106.5420447, 11.4233114],
                [106.542041, 11.4233597],
                [106.5420281, 11.4234065],
                [106.5420064, 11.42345],
                [106.5419766, 11.4234888],
                [106.5418325, 11.4236226],
                [106.5417457, 11.4236718],
                [106.5416977, 11.4236867],
                [106.5415978, 11.4236956],
                [106.5415479, 11.4236893],
                [106.5414128, 11.4236483],
                [106.541367, 11.4236467],
                [106.5412785, 11.4236686],
                [106.5412044, 11.4237208],
                [106.5411403, 11.4238161],
                [106.5410998, 11.4239232],
                [106.5410789, 11.424121],
                [106.5410879, 11.4243196],
                [106.5411036, 11.4244179],
                [106.5411646, 11.424662],
                [106.5411728, 11.4247377],
                [106.5411672, 11.4248895],
                [106.5411266, 11.4250937],
                [106.5411346, 11.4252256],
                [106.5411516, 11.4252896],
                [106.5412103, 11.4254085],
                [106.541251, 11.4254613],
                [106.5413825, 11.4255857],
                [106.5414142, 11.4256331],
                [106.5414531, 11.4257394],
                [106.5414562, 11.4258523],
                [106.541419, 11.4260186],
                [106.5414082, 11.4261901],
                [106.541433, 11.4263602],
                [106.5414924, 11.4265219],
                [106.541584, 11.4266684],
                [106.541704, 11.4267937],
                [106.541926, 11.4269571],
                [106.5420449, 11.4270329],
                [106.5421152, 11.4270669],
                [106.5421975, 11.4270953],
                [106.5422666, 11.4271125],
                [106.5423373, 11.4271208],
                [106.5424086, 11.42712],
                [106.5424791, 11.4271102],
                [106.5425478, 11.4270915],
                [106.5426134, 11.4270643],
                [106.5426749, 11.4270289],
                [106.5427312, 11.4269861],
                [106.5427813, 11.4269365],
                [106.5428449, 11.4268482],
                [106.5428723, 11.4268209],
                [106.5429418, 11.4267869],
                [106.5429806, 11.4267821],
                [106.5430566, 11.426798],
                [106.54309, 11.4268178],
                [106.5431351, 11.4268683],
                [106.5432748, 11.4269814],
                [106.5433095, 11.4270252],
                [106.5433559, 11.4271261],
                [106.5433663, 11.4271806],
                [106.5433639, 11.4272732],
                [106.5433368, 11.427362],
                [106.5433292, 11.4274141],
                [106.5433292, 11.4274668],
                [106.543352, 11.4275693],
                [106.5433744, 11.4276172],
                [106.5434387, 11.4277012],
                [106.5436292, 11.4278405],
                [106.543683, 11.4278942],
                [106.543707, 11.4279658],
                [106.5436962, 11.4280403],
                [106.5436691, 11.4281074],
                [106.5436713, 11.4281796],
                [106.5436836, 11.4282138],
                [106.5437278, 11.4282716],
                [106.5438221, 11.4283282],
                [106.5438478, 11.4283543],
                [106.5438679, 11.4283847],
                [106.5438879, 11.4284483],
                [106.5439458, 11.4285684],
                [106.5440889, 11.4287605],
                [106.5441324, 11.4288349],
                [106.5441992, 11.4289933],
                [106.5442344, 11.4291407],
                [106.5442837, 11.4292627],
                [106.5443197, 11.4293182],
                [106.5444116, 11.4294136],
                [106.5445744, 11.4295185],
                [106.544622, 11.4295603],
                [106.5446894, 11.4296406],
                [106.5447534, 11.4297474],
                [106.5448201, 11.429899],
                [106.5448518, 11.4300137],
                [106.5448637, 11.4301244],
                [106.544861, 11.4301764],
                [106.544836, 11.4303158],
                [106.5448407, 11.4303605],
                [106.5448751, 11.4304435],
                [106.5449382, 11.4305083],
                [106.5449779, 11.4305309],
                [106.5450212, 11.4305458],
                [106.5451511, 11.430565],
                [106.545248, 11.4305942],
                [106.545303, 11.4306381],
                [106.5453215, 11.4306681],
                [106.5453357, 11.4307362],
                [106.5453306, 11.4307708],
                [106.5452975, 11.4308323],
                [106.5452539, 11.4308802],
                [106.5452304, 11.4309401],
                [106.5452318, 11.4310167],
                [106.5452434, 11.4310535],
                [106.5452864, 11.4311176],
                [106.5453493, 11.4311611],
                [106.5453982, 11.4312176],
                [106.5454166, 11.4312895],
                [106.5453964, 11.4313993],
                [106.5454009, 11.4314365],
                [106.545434, 11.4315035],
                [106.5454933, 11.43155],
                [106.5455462, 11.4315655],
                [106.5455956, 11.4315896],
                [106.5456401, 11.4316216],
                [106.5457069, 11.431701],
                [106.5457437, 11.4317924],
                [106.5457507, 11.4318905],
                [106.5457333, 11.4319706],
                [106.5457166, 11.431999],
                [106.5456655, 11.4320406],
                [106.5456339, 11.4320515],
                [106.5455777, 11.4320526],
                [106.5455364, 11.4320708],
                [106.5455169, 11.4321071],
                [106.5455169, 11.4321636],
                [106.5455239, 11.4322006],
                [106.5455569, 11.4322681],
                [106.5456711, 11.4323731],
                [106.5457056, 11.4324206],
                [106.5457321, 11.4324727],
                [106.54575, 11.4325283],
                [106.5457588, 11.4325859],
                [106.545763, 11.4327313],
                [106.5457771, 11.4328028],
                [106.5458403, 11.4329839],
                [106.5458522, 11.4330421],
                [106.5458645, 11.4332071],
                [106.5459211, 11.4333806],
                [106.5459208, 11.4334189],
                [106.5458959, 11.4335107],
                [106.5459015, 11.4335388],
                [106.5459158, 11.433564],
                [106.5459372, 11.4335835],
                [106.5459638, 11.4335956],
                [106.5459929, 11.4335991],
                [106.5460217, 11.4335936],
                [106.5460673, 11.4335586],
                [106.5460959, 11.4335088],
                [106.5461389, 11.4334692],
                [106.5461954, 11.4334513],
                [106.5462253, 11.4334527],
                [106.5462798, 11.4334762],
                [106.5463167, 11.4335218],
                [106.5463419, 11.4336671],
                [106.5463958, 11.4338069],
                [106.5465068, 11.4340064],
                [106.5466332, 11.4342126],
                [106.5466753, 11.4342916],
                [106.5467276, 11.4344189],
                [106.54678, 11.4344999],
                [106.5468542, 11.4345625],
                [106.5469437, 11.4346013],
                [106.5469919, 11.4346106],
                [106.5471572, 11.4346078],
                [106.5472147, 11.4346175],
                [106.5473225, 11.4346603],
                [106.5473707, 11.4346925],
                [106.5474493, 11.4347734],
                [106.5475025, 11.4348694],
                [106.5475291, 11.4349756],
                [106.5475318, 11.4350231],
                [106.5475203, 11.4351585],
                [106.5475277, 11.4351987],
                [106.5475651, 11.4352714],
                [106.5475938, 11.4353011],
                [106.5476658, 11.4353417],
                [106.5477064, 11.4353511],
                [106.5477481, 11.4353526],
                [106.5478589, 11.4353317],
                [106.5479271, 11.4353518],
                [106.547977, 11.4354015],
                [106.5480224, 11.4355085],
                [106.5480569, 11.4355558],
                [106.5481454, 11.4356326],
                [106.5482533, 11.4356802],
                [106.5483763, 11.4357099],
                [106.5484906, 11.4357635],
                [106.5486868, 11.4359144],
                [106.5487647, 11.4359644],
                [106.5488826, 11.4360263],
                [106.5489144, 11.4360511],
                [106.5489587, 11.4361173],
                [106.5489691, 11.4361558],
                [106.5489641, 11.4362349],
                [106.5489367, 11.4363193],
                [106.5489376, 11.4363934],
                [106.5489661, 11.4364456],
                [106.5489896, 11.4364646],
                [106.5490473, 11.436482],
                [106.5490777, 11.4364792],
                [106.5491585, 11.4364417],
                [106.5492177, 11.4364422],
                [106.5492695, 11.4364701],
                [106.5493217, 11.4365423],
                [106.5493756, 11.4365692],
                [106.5494361, 11.4365661],
                [106.5495148, 11.4365298],
                [106.5496242, 11.4365118],
                [106.5497588, 11.4365239],
                [106.5498856, 11.4365699],
                [106.5499959, 11.4366466],
                [106.5500423, 11.436695],
                [106.5501037, 11.4367864],
                [106.5502873, 11.4370197],
                [106.5503535, 11.437137],
                [106.5504116, 11.4371968],
                [106.5504889, 11.4372296],
                [106.550531, 11.437234],
                [106.5505731, 11.4372302],
                [106.5506816, 11.4371897],
                [106.5507578, 11.4371842],
                [106.5507962, 11.4371934],
                [106.5508313, 11.4372111],
                [106.5508613, 11.4372363],
                [106.5508846, 11.4372676],
                [106.5508997, 11.4373034],
                [106.550906, 11.4373417],
                [106.5509048, 11.4374281],
                [106.550914, 11.4374705],
                [106.5509572, 11.4375586],
                [106.5510254, 11.4376299],
                [106.5511593, 11.437705],
                [106.5512674, 11.4377851],
                [106.5513066, 11.4377991],
                [106.5513896, 11.4378035],
                [106.5514679, 11.4377762],
                [106.5515444, 11.437715],
                [106.5515748, 11.4377007],
                [106.5516414, 11.4376922],
                [106.5516745, 11.4376984],
                [106.5517333, 11.4377302],
                [106.5518045, 11.4378201],
                [106.5518302, 11.4378389],
                [106.551917, 11.4378786],
                [106.5520412, 11.4379057],
                [106.5522372, 11.4378994],
                [106.5523675, 11.4379231],
                [106.5524295, 11.4379463],
                [106.5525192, 11.4379966],
                [106.5526052, 11.4380527],
                [106.552784, 11.4381867],
                [106.5528962, 11.4382329],
                [106.5530128, 11.4382637],
                [106.5530983, 11.4383047],
                [106.5531413, 11.4383577],
                [106.5531518, 11.4384247],
                [106.5531269, 11.4384879],
                [106.5530998, 11.4385151],
                [106.5530636, 11.4385818],
                [106.5530558, 11.438619],
                [106.5530621, 11.4386943],
                [106.553098, 11.4387642],
                [106.5531268, 11.4387935],
                [106.5531993, 11.4388323],
                [106.5532491, 11.4388821],
                [106.5532748, 11.4389471],
                [106.5532773, 11.439044],
                [106.5533094, 11.4390973],
                [106.5533645, 11.4391275],
                [106.5533962, 11.439131],
                [106.5534568, 11.4391136],
                [106.5535242, 11.4390671],
                [106.5535641, 11.4390557],
                [106.5536139, 11.4390537],
                [106.5536631, 11.439061],
                [106.5537101, 11.4390775],
                [106.5537529, 11.4391024],
                [106.5537901, 11.4391349],
                [106.5538203, 11.4391737],
                [106.5538424, 11.4392175],
                [106.5538555, 11.4392647],
                [106.5538619, 11.4393752],
                [106.5538951, 11.4394279],
                [106.5539207, 11.4394464],
                [106.5539962, 11.4394715],
                [106.5540556, 11.4395381],
                [106.5541166, 11.4395635],
                [106.5541826, 11.4395569],
                [106.5542372, 11.4395199],
                [106.5542667, 11.4394617],
                [106.5542692, 11.4393778],
                [106.5542961, 11.4393339],
                [106.5543424, 11.43931],
                [106.5543687, 11.4393081],
                [106.5544088, 11.4393194],
                [106.5546002, 11.4394334],
                [106.5547007, 11.4395156],
                [106.5547402, 11.4395602],
                [106.5548, 11.4396623],
                [106.5548462, 11.439826],
                [106.5548724, 11.4398749],
                [106.5549223, 11.4399006],
                [106.5549507, 11.4399015],
                [106.5550022, 11.4398789],
                [106.5550489, 11.4398099],
                [106.5550716, 11.4397932],
                [106.5551262, 11.4397795],
                [106.5551803, 11.4397946],
                [106.5552194, 11.4398343],
                [106.5552297, 11.4398602],
                [106.5552404, 11.4399318],
                [106.555254, 11.4399656],
                [106.5552931, 11.440022],
                [106.5553676, 11.4400846],
                [106.5554239, 11.4401173],
                [106.5554845, 11.4401415],
                [106.5555481, 11.4401566],
                [106.555654, 11.4401614],
                [106.5557749, 11.4401432],
                [106.5558535, 11.4401223],
                [106.5561174, 11.4400099],
                [106.5562156, 11.4399787],
                [106.5564893, 11.4399189],
                [106.556573, 11.4398903],
                [106.556654, 11.4398549],
                [106.5568853, 11.4397321],
                [106.5571291, 11.4396355],
                [106.5572548, 11.4395973],
                [106.5575157, 11.4395403],
                [106.557648, 11.4395213],
                [106.5579146, 11.4395027],
                [106.5580723, 11.4395037],
                [106.558294, 11.4395164],
                [106.5588802, 11.4395079],
                [106.5589452, 11.4396539],
                [106.5591344, 11.4399544],
                [106.5591977, 11.4400763],
                [106.5593039, 11.4403076],
                [106.5593363, 11.4403993],
                [106.5593901, 11.4404922],
                [106.5594976, 11.4406486],
                [106.5595226, 11.4407264],
                [106.5595238, 11.4408153],
                [106.5595412, 11.44091],
                [106.5595609, 11.4409542],
                [106.5596201, 11.4410309],
                [106.559685, 11.4410782],
                [106.5597718, 11.4411166],
                [106.5600169, 11.4411765],
                [106.560105, 11.4412105],
                [106.5601892, 11.441253],
                [106.5602687, 11.4413035],
                [106.5603426, 11.4413616],
                [106.5604102, 11.4414266],
                [106.5605106, 11.4415543],
                [106.560666, 11.4418275],
                [106.5607246, 11.4419185],
                [106.5607909, 11.4420042],
                [106.5608645, 11.442084],
                [106.5609449, 11.4421573],
                [106.5610774, 11.4422544],
                [106.561127, 11.4422793],
                [106.5612339, 11.4423097],
                [106.5613452, 11.442313],
                [106.5614067, 11.4423028],
                [106.5614692, 11.4423016],
                [106.5615311, 11.4423093],
                [106.5615912, 11.4423259],
                [106.5616482, 11.442351],
                [106.5617008, 11.4423839],
                [106.5617885, 11.4424706],
                [106.5618217, 11.4425224],
                [106.5618787, 11.4426551],
                [106.5619383, 11.4427138],
                [106.5620164, 11.4427454],
                [106.5621664, 11.4427442],
                [106.5622195, 11.4427542],
                [106.5623176, 11.4427983],
                [106.5623966, 11.4428703],
                [106.5624407, 11.4429435],
                [106.5625468, 11.443078],
                [106.5626858, 11.4432033],
                [106.5627626, 11.4432572],
                [106.5629284, 11.443346],
                [106.5632171, 11.4434474],
                [106.5633134, 11.4434913],
                [106.5634953, 11.4435985],
                [106.5636599, 11.4437299],
                [106.5638748, 11.4439364],
                [106.5639913, 11.4440299],
                [106.5641894, 11.4441652],
                [106.5643257, 11.4442833],
                [106.5644363, 11.4444249],
                [106.5645108, 11.4445689],
                [106.5645879, 11.4446921],
                [106.5646973, 11.4448109],
                [106.5647605, 11.4448618],
                [106.5649007, 11.4449438],
                [106.5649763, 11.4449742],
                [106.5651136, 11.445009],
                [106.5652264, 11.4450501],
                [106.5653282, 11.4451127],
                [106.5653785, 11.4451559],
                [106.5654234, 11.4452045],
                [106.5654945, 11.4453152],
                [106.5655347, 11.4454253],
                [106.5655509, 11.4455412],
                [106.5653095, 11.4455886],
                [106.5653287, 11.4456984],
                [106.5653894, 11.445913],
                [106.5654789, 11.4461177],
                [106.565534, 11.4462152],
                [106.5655956, 11.4463089],
                [106.565794, 11.4465589],
                [106.565851, 11.4466451],
                [106.5660444, 11.4470187],
                [106.5661022, 11.4471151],
                [106.5661679, 11.4472065],
                [106.5662411, 11.4472923],
                [106.5663213, 11.4473719],
                [106.5664079, 11.4474447],
                [106.5666523, 11.4476089],
                [106.5667666, 11.4477043],
                [106.5668877, 11.4478305],
                [106.5669605, 11.4479214],
                [106.5670264, 11.4480173],
                [106.5671737, 11.4482789],
                [106.5673705, 11.4485347],
                [106.5674324, 11.4486317],
                [106.5676212, 11.4490133],
                [106.5677451, 11.4492199],
                [106.5677975, 11.4492872],
                [106.5678561, 11.4493495],
                [106.5679896, 11.4494566],
                [106.5681412, 11.4495376],
                [106.5682221, 11.4495674],
                [106.5684628, 11.4496243],
                [106.5685383, 11.449652],
                [106.5686802, 11.449727],
                [106.5688483, 11.4498514],
                [106.5689666, 11.449904],
                [106.5690296, 11.44992],
                [106.5692046, 11.4499392],
                [106.5693088, 11.4499769],
                [106.5693998, 11.4500392],
                [106.5694803, 11.4501154],
                [106.5696162, 11.4502254],
                [106.5697609, 11.4503839],
                [106.5698104, 11.4504245],
                [106.5699693, 11.4505234],
                [106.5700156, 11.4505639],
                [106.5700566, 11.4506095],
                [106.5701151, 11.4507016],
                [106.5701492, 11.450791],
                [106.5702757, 11.4510478],
                [106.5703122, 11.4511485],
                [106.5703535, 11.4512072],
                [106.5704146, 11.451246],
                [106.5704497, 11.4512559],
                [106.5705688, 11.4512569],
                [106.5706088, 11.4512678],
                [106.5706458, 11.4512862],
                [106.5707054, 11.4513424],
                [106.5707383, 11.4514167],
                [106.5707519, 11.451507],
                [106.5707996, 11.4516494],
                [106.5708081, 11.451701],
                [106.5708076, 11.4518014],
                [106.5708278, 11.4518955],
                [106.5708504, 11.4519384],
                [106.5709326, 11.4520315],
                [106.5710065, 11.4521685],
                [106.571088, 11.4522576],
                [106.5711353, 11.4522957],
                [106.5712872, 11.4523909],
                [106.5713298, 11.452432],
                [106.5713659, 11.4524787],
                [106.5714114, 11.4525716],
                [106.5714651, 11.4526601],
                [106.5715929, 11.4528439],
                [106.5716777, 11.4530026],
                [106.5717611, 11.4531109],
                [106.57187, 11.4531946],
                [106.5720682, 11.4532791],
                [106.572132, 11.4533151],
                [106.572245, 11.4534011],
                [106.5723379, 11.4535027],
                [106.5724016, 11.453616],
                [106.5724227, 11.4536775],
                [106.5724334, 11.4537537],
                [106.5724311, 11.4540101],
                [106.5724404, 11.4540999],
                [106.5724572, 11.4541887],
                [106.5725133, 11.4543604],
                [106.5725976, 11.4545206],
                [106.5726471, 11.4545845],
                [106.5727838, 11.4547314],
                [106.5728924, 11.4548879],
                [106.5729362, 11.4549724],
                [106.5729998, 11.4551459],
                [106.573033, 11.4553224],
                [106.5730386, 11.4554121],
                [106.5730369, 11.4555019],
                [106.5730153, 11.4556003],
                [106.5729965, 11.4558007],
                [106.5730096, 11.4559961],
                [106.5730519, 11.4561817],
                [106.5731223, 11.456359],
                [106.5731675, 11.4564432],
                [106.5728438, 11.4565034],
                [106.5728678, 11.4566693],
                [106.5728583, 11.4568366],
                [106.5728207, 11.4569851],
                [106.5727561, 11.4571081],
                [106.5726632, 11.4572122],
                [106.5724114, 11.457384],
                [106.5723595, 11.457432],
                [106.5722735, 11.457543],
                [106.5722403, 11.4576049],
                [106.5721537, 11.4578283],
                [106.5720613, 11.4580076],
                [106.5719314, 11.4582156],
                [106.5718379, 11.4583499],
                [106.5714714, 11.4588128],
                [106.5713973, 11.4589489],
                [106.5713287, 11.4591612],
                [106.5712962, 11.4592268],
                [106.5712175, 11.4593379],
                [106.5710448, 11.4595349],
                [106.570907, 11.4596735],
                [106.5704995, 11.4600049],
                [106.5703879, 11.4601118],
                [106.5700709, 11.4604494],
                [106.5697169, 11.4608587],
                [106.5694312, 11.4612255],
                [106.5692049, 11.4615332],
                [106.5691529, 11.4615874],
                [106.5690012, 11.4617188],
                [106.56896, 11.461766],
                [106.5688929, 11.4618713],
                [106.5688644, 11.4619368],
                [106.5688446, 11.4620054],
                [106.5688038, 11.4624381],
                [106.5687745, 11.4626194],
                [106.5687568, 11.4626799],
                [106.5687023, 11.4627938],
                [106.5686247, 11.4628939],
                [106.568471, 11.4630194],
                [106.5683861, 11.4631106],
                [106.5683178, 11.4632247],
                [106.5682604, 11.4633565],
                [106.5682232, 11.4634194],
                [106.5681338, 11.4635353],
                [106.5679533, 11.4637163],
                [106.5677913, 11.4639201],
                [106.567643, 11.4641399],
                [106.5674724, 11.4644331],
                [106.5673721, 11.4645421],
                [106.5672715, 11.4646151],
                [106.5666941, 11.4649051],
                [106.566634, 11.4649407],
                [106.5665269, 11.4650296],
                [106.5664414, 11.4651388],
                [106.5663766, 11.4652769],
                [106.5663571, 11.4653505],
                [106.5663466, 11.465426],
                [106.5663451, 11.465502],
                [106.5663528, 11.4655778],
                [106.5601891, 11.4719444],
                [106.5564, 11.4849987],
                [106.5563642, 11.4849777],
                [106.5562855, 11.4849013],
                [106.5562453, 11.4848184],
                [106.556229, 11.4847258],
                [106.5562335, 11.4846359],
                [106.5562519, 11.4845216],
                [106.5562793, 11.4844091],
                [106.5563156, 11.484299],
                [106.5563606, 11.484192],
                [106.5563974, 11.4841262],
                [106.5564878, 11.4840051],
                [106.5565048, 11.4839371],
                [106.5565022, 11.483902],
                [106.5564752, 11.4838373],
                [106.556452, 11.4838104],
                [106.5563963, 11.4837802],
                [106.5563339, 11.4837636],
                [106.5562839, 11.483722],
                [106.5562569, 11.4836635],
                [106.5562538, 11.4836314],
                [106.5562755, 11.4835316],
                [106.5562652, 11.4834569],
                [106.5562262, 11.483392],
                [106.5560767, 11.4832479],
                [106.5560194, 11.4831606],
                [106.5559906, 11.483069],
                [106.5559856, 11.4830212],
                [106.5559939, 11.4829148],
                [106.5559871, 11.4827979],
                [106.5559485, 11.4826744],
                [106.5558798, 11.4825642],
                [106.5556426, 11.4822497],
                [106.5555414, 11.4821625],
                [106.5554493, 11.4821077],
                [106.5553831, 11.4820943],
                [106.5553212, 11.4821082],
                [106.5552938, 11.4821242],
                [106.5552272, 11.4821915],
                [106.555198, 11.4822053],
                [106.5551338, 11.4822098],
                [106.5550754, 11.4821832],
                [106.5550374, 11.4821323],
                [106.5550224, 11.4820247],
                [106.5549843, 11.481942],
                [106.5549189, 11.4818779],
                [106.5548244, 11.4818176],
                [106.554733, 11.4817234],
                [106.5546686, 11.4816097],
                [106.5546198, 11.481486],
                [106.5545166, 11.4813186],
                [106.5544971, 11.481261],
                [106.5544865, 11.4812013],
                [106.5544851, 11.4811407],
                [106.554493, 11.4810806],
                [106.5545294, 11.4809804],
                [106.5545923, 11.4808936],
                [106.5548268, 11.4806249],
                [106.5548937, 11.4805072],
                [106.5549188, 11.4804444],
                [106.554938, 11.4803796],
                [106.5549411, 11.4803315],
                [106.5549203, 11.4802378],
                [106.5548665, 11.4801576],
                [106.5548001, 11.480107],
                [106.5547619, 11.4800895],
                [106.5546355, 11.4800585],
                [106.5545942, 11.4800381],
                [106.5545248, 11.4799785],
                [106.554498, 11.4799177],
                [106.5544951, 11.4798846],
                [106.5545109, 11.4798203],
                [106.5545758, 11.4797345],
                [106.5545918, 11.4796969],
                [106.5546001, 11.479657],
                [106.5545937, 11.4795266],
                [106.5545693, 11.4793936],
                [106.5545332, 11.4793197],
                [106.5544809, 11.4792666],
                [106.5543672, 11.4792093],
                [106.554327, 11.4791593],
                [106.5543145, 11.4791275],
                [106.5543091, 11.4790938],
                [106.55432, 11.4790267],
                [106.5543795, 11.4789311],
                [106.5543926, 11.4788887],
                [106.5543965, 11.4788446],
                [106.5543912, 11.4788007],
                [106.5543768, 11.4787588],
                [106.5543266, 11.4786643],
                [106.5543055, 11.4786055],
                [106.5542848, 11.4785142],
                [106.5542848, 11.478439],
                [106.5543102, 11.4783546],
                [106.5543115, 11.478322],
                [106.5542877, 11.4782616],
                [106.5542642, 11.4782384],
                [106.5542029, 11.4782144],
                [106.5541155, 11.4782125],
                [106.5540012, 11.4782408],
                [106.5539706, 11.4782369],
                [106.553916, 11.4782093],
                [106.5538796, 11.4781608],
                [106.5538628, 11.4780577],
                [106.5538373, 11.477973],
                [106.5537909, 11.4779158],
                [106.5537247, 11.477882],
                [106.5536503, 11.4778776],
                [106.553614, 11.4778869],
                [106.5535512, 11.4779262],
                [106.5535028, 11.4779784],
                [106.5534363, 11.4780081],
                [106.5533992, 11.4780126],
                [106.5533262, 11.4779988],
                [106.5532266, 11.4779322],
                [106.5531412, 11.4778976],
                [106.5530099, 11.4778698],
                [106.5529093, 11.4778611],
                [106.5528757, 11.4778639],
                [106.5528145, 11.4778856],
                [106.5527326, 11.4779447],
                [106.5526972, 11.4779548],
                [106.5526602, 11.4779558],
                [106.5526242, 11.4779476],
                [106.5525915, 11.4779307],
                [106.5525642, 11.4779063],
                [106.5525441, 11.477876],
                [106.5525324, 11.4778416],
                [106.5525181, 11.4776708],
                [106.552494, 11.4775727],
                [106.5524425, 11.4774731],
                [106.5523679, 11.4773887],
                [106.5522425, 11.4772972],
                [106.5520702, 11.4772007],
                [106.5518862, 11.4771279],
                [106.5517074, 11.4770734],
                [106.5516568, 11.4770473],
                [106.5515704, 11.4769744],
                [106.5515365, 11.4769293],
                [106.5514739, 11.4769149],
                [106.5514213, 11.4768787],
                [106.5513861, 11.4768251],
                [106.5513741, 11.4767625],
                [106.5513887, 11.4766609],
                [106.5514252, 11.4765243],
                [106.5514326, 11.4764693],
                [106.5514238, 11.4763591],
                [106.5513842, 11.4762556],
                [106.5512985, 11.4761365],
                [106.5510982, 11.475937],
                [106.5510317, 11.4758617],
                [106.5509126, 11.4757007],
                [106.5508451, 11.4755919],
                [106.5507298, 11.4753638],
                [106.5506839, 11.4752918],
                [106.5505709, 11.4751632],
                [106.5505008, 11.4750553],
                [106.5504583, 11.4749343],
                [106.5504313, 11.4746924],
                [106.5504035, 11.474607],
                [106.5503788, 11.4745617],
                [106.5503124, 11.4744826],
                [106.5501841, 11.4743882],
                [106.5501467, 11.4743474],
                [106.5500922, 11.4742518],
                [106.5500682, 11.4741449],
                [106.5500655, 11.4739742],
                [106.5500766, 11.4738894],
                [106.5501437, 11.4735627],
                [106.5501461, 11.4734873],
                [106.5501256, 11.4733379],
                [106.5500722, 11.4731966],
                [106.5499492, 11.472959],
                [106.5498735, 11.4727442],
                [106.5498331, 11.4726674],
                [106.5497575, 11.4725612],
                [106.5496822, 11.4724842],
                [106.549632, 11.4724438],
                [106.5495218, 11.4723769],
                [106.5494648, 11.4723351],
                [106.549414, 11.4722862],
                [106.5493705, 11.472231],
                [106.5492197, 11.4719983],
                [106.5491199, 11.4718852],
                [106.5489933, 11.471781],
                [106.5488601, 11.471692],
                [106.5487295, 11.4715736],
                [106.5486098, 11.4714231],
                [106.5485054, 11.4712375],
                [106.5483774, 11.4710411],
                [106.5482954, 11.470938],
                [106.5481467, 11.4707756],
                [106.5480677, 11.4707246],
                [106.5480212, 11.4707062],
                [106.5479726, 11.4706943],
                [106.5478977, 11.4706883],
                [106.5478407, 11.4706981],
                [106.5478103, 11.4707106],
                [106.5477575, 11.470749],
                [106.547696, 11.4707747],
                [106.5476625, 11.4707774],
                [106.5475976, 11.470762],
                [106.5474159, 11.4706201],
                [106.5472117, 11.4704977],
                [106.5471049, 11.4703978],
                [106.5469749, 11.4702097],
                [106.546813, 11.4700158],
                [106.5467164, 11.4698728],
                [106.5466504, 11.4697311],
                [106.5465557, 11.4694154],
                [106.5464898, 11.4692788],
                [106.5464104, 11.4691757],
                [106.5463319, 11.4691288],
                [106.5462872, 11.4691173],
                [106.5462331, 11.4690753],
                [106.5462058, 11.4690133],
                [106.5462111, 11.4689091],
                [106.5461839, 11.4688408],
                [106.5461473, 11.4687892],
                [106.5461033, 11.4687434],
                [106.5459486, 11.4686369],
                [106.5459054, 11.4685945],
                [106.5458682, 11.468547],
                [106.5457889, 11.4683978],
                [106.5457226, 11.4683254],
                [106.5456395, 11.4682722],
                [106.5455589, 11.468236],
                [106.5454747, 11.4682086],
                [106.545388, 11.4681901],
                [106.5452998, 11.4681809],
                [106.5452111, 11.468181],
                [106.5451229, 11.4681904],
                [106.5450434, 11.4682087],
                [106.5449665, 11.4682358],
                [106.544769, 11.4683398],
                [106.5447102, 11.4683572],
                [106.5445883, 11.4683688],
                [106.5444675, 11.4683491],
                [106.5442639, 11.4682593],
                [106.5441684, 11.4682276],
                [106.5439705, 11.4681904],
                [106.5438549, 11.4681914],
                [106.543744, 11.4681593],
                [106.5436574, 11.4681053],
                [106.5435879, 11.4680311],
                [106.5435613, 11.4679879],
                [106.5435461, 11.4679444],
                [106.5435402, 11.4678988],
                [106.5435654, 11.4677554],
                [106.5435655, 11.4677015],
                [106.5435573, 11.4676481],
                [106.5435175, 11.4675479],
                [106.5435126, 11.4675074],
                [106.5434781, 11.4674338],
                [106.54345, 11.4674038],
                [106.54342, 11.4673822],
                [106.5433506, 11.467357],
                [106.5432742, 11.4673603],
                [106.5432381, 11.4673733],
                [106.5431777, 11.4674194],
                [106.5431375, 11.4674869],
                [106.5431245, 11.4675256],
                [106.5431162, 11.4676063],
                [106.543091, 11.4676675],
                [106.5430416, 11.4677124],
                [106.5429774, 11.4677324],
                [106.5429106, 11.4677238],
                [106.5428803, 11.467709],
                [106.5427371, 11.4676091],
                [106.542614, 11.4675505],
                [106.5424852, 11.4675227],
                [106.5423859, 11.4675187],
                [106.5423218, 11.4675373],
                [106.5422709, 11.4675812],
                [106.5421484, 11.4677959],
                [106.5421078, 11.4678951],
                [106.5420473, 11.467984],
                [106.5420026, 11.4680306],
                [106.5419524, 11.4680714],
                [106.5418385, 11.4681333],
                [106.5417126, 11.4681658],
                [106.5416475, 11.4681704],
                [106.5414884, 11.4681554],
                [106.5413958, 11.4681358],
                [106.5412176, 11.4680732],
                [106.5410077, 11.4679745],
                [106.5407479, 11.4678828],
                [106.5406665, 11.467844],
                [106.5405898, 11.4677969],
                [106.5403974, 11.4676401],
                [106.5402714, 11.467582],
                [106.5401346, 11.4675565],
                [106.5400649, 11.4675565],
                [106.5399281, 11.467582],
                [106.5398021, 11.4676401],
                [106.5397457, 11.4676802],
                [106.5396404, 11.4677841],
                [106.5395216, 11.467941],
                [106.5395041, 11.4679762],
                [106.539488, 11.4680536],
                [106.539499, 11.4681319],
                [106.5395765, 11.4682791],
                [106.5395836, 11.4683073],
                [106.5395735, 11.4683687],
                [106.5395563, 11.4683951],
                [106.5395038, 11.46843],
                [106.5392718, 11.4684871],
                [106.5391743, 11.4685215],
                [106.5391043, 11.4685556],
                [106.5390381, 11.4685965],
                [106.5388511, 11.4687507],
                [106.5387773, 11.4687982],
                [106.5385607, 11.4689022],
                [106.5385062, 11.4689371],
                [106.5383459, 11.4690661],
                [106.5382799, 11.4691058],
                [106.5380876, 11.4691896],
                [106.5379996, 11.4692571],
                [106.5379624, 11.4692981],
                [106.5378978, 11.4694006],
                [106.5378714, 11.4694611],
                [106.5378391, 11.4695885],
                [106.537793, 11.4696886],
                [106.5377194, 11.4697715],
                [106.5375664, 11.4698671],
                [106.5375122, 11.4699096],
                [106.5374386, 11.4699818],
                [106.537394, 11.4700527],
                [106.5373707, 11.4701487],
                [106.5373721, 11.4701981],
                [106.537402, 11.470347],
                [106.537402, 11.4703985],
                [106.5373717, 11.4704583],
                [106.5373458, 11.4704805],
                [106.5372813, 11.4705019],
                [106.5371705, 11.4704997],
                [106.5370189, 11.4705193],
                [106.5368524, 11.4705706],
                [106.5367628, 11.4706099],
                [106.5366769, 11.4706565],
                [106.5362965, 11.4709005],
                [106.536194, 11.4709593],
                [106.5359304, 11.4710824],
                [106.5356536, 11.4711735],
                [106.5355379, 11.4712381],
                [106.535439, 11.4713254],
                [106.5353586, 11.4713699],
                [106.5352913, 11.4713864],
                [106.5352336, 11.4713846],
                [106.5351542, 11.471352],
                [106.5350977, 11.4712832],
                [106.5350744, 11.4712677],
                [106.5350199, 11.4712565],
                [106.5349668, 11.4712733],
                [106.5349454, 11.4712911],
                [106.534892, 11.4713646],
                [106.5347922, 11.4715645],
                [106.534724, 11.4716403],
                [106.534551, 11.4717442],
                [106.5344705, 11.4718019],
                [106.5341567, 11.4720938],
                [106.5340959, 11.4721413],
                [106.5340129, 11.4721954],
                [106.5339252, 11.4722416],
                [106.5338333, 11.4722795],
                [106.5337382, 11.4723087],
                [106.5336884, 11.4723332],
                [106.5335991, 11.4723983],
                [106.5335656, 11.4724591],
                [106.5335594, 11.4724934],
                [106.5335607, 11.4725281],
                [106.5335852, 11.4726397],
                [106.5335851, 11.4726949],
                [106.5335757, 11.4727492],
                [106.5335364, 11.4728371],
                [106.533478, 11.4728964],
                [106.5333787, 11.4729489],
                [106.5333118, 11.4729716],
                [106.5330658, 11.4730084],
                [106.5329614, 11.4730331],
                [106.5328594, 11.473066],
                [106.5326653, 11.4731557],
                [106.5324641, 11.4732739],
                [106.5321223, 11.4734955],
                [106.5316182, 11.4737866],
                [106.531339, 11.4739337],
                [106.5307698, 11.4742072],
                [106.5306518, 11.4742746],
                [106.5305374, 11.4743479],
                [106.5302219, 11.4745792],
                [106.5301188, 11.4746412],
                [106.530012, 11.4746968],
                [106.5297887, 11.4747876],
                [106.5296731, 11.4748225],
                [106.5295181, 11.4748575],
                [106.5294628, 11.4748789],
                [106.5294112, 11.4749079],
                [106.5292504, 11.475052],
                [106.529089, 11.4751652],
                [106.5290018, 11.4752562],
                [106.5289421, 11.4753666],
                [106.5289141, 11.4754884],
                [106.5289079, 11.4755824],
                [106.528879, 11.4756878],
                [106.5288196, 11.4757972],
                [106.5287803, 11.4758458],
                [106.528649, 11.4759646],
                [106.5284611, 11.4760959],
                [106.5283333, 11.47621],
                [106.5282291, 11.4763451],
                [106.5281241, 11.4765169],
                [106.5280553, 11.4766108],
                [106.5279008, 11.4767852],
                [106.5278373, 11.4768381],
                [106.5276955, 11.4769237],
                [106.5275392, 11.4769799],
                [106.5274879, 11.4770066],
                [106.5274006, 11.4770814],
                [106.5272965, 11.4772473],
                [106.5272452, 11.4773109],
                [106.5272112, 11.4773976],
                [106.5272071, 11.4774904],
                [106.5272389, 11.4776192],
                [106.5272363, 11.4776591],
                [106.5272068, 11.4777333],
                [106.5271813, 11.4777645],
                [106.5270938, 11.4778429],
                [106.5270437, 11.4779012],
                [106.5268899, 11.4781524],
                [106.5268104, 11.4782696],
                [106.5266802, 11.4784377],
                [106.5265512, 11.4785734],
                [106.5264633, 11.4786499],
                [106.5262716, 11.478783],
                [106.5257948, 11.4790711],
                [106.5256101, 11.4792163],
                [106.5254451, 11.4793827],
                [106.5253956, 11.4794226],
                [106.5252858, 11.4794872],
                [106.525117, 11.4795609],
                [106.5250105, 11.4796165],
                [106.5248248, 11.4797362],
                [106.5246741, 11.4798707],
                [106.5243877, 11.4801735],
                [106.5242681, 11.4802626],
                [106.5241928, 11.4803041],
                [106.5240315, 11.4803642],
                [106.5238117, 11.4804006],
                [106.5237433, 11.4803979],
                [106.5237082, 11.4803888],
                [106.5236136, 11.4803461],
                [106.5235501, 11.4803478],
                [106.5234939, 11.4803766],
                [106.5233924, 11.4805044],
                [106.5233113, 11.4805647],
                [106.5232065, 11.4806094],
                [106.5230822, 11.4806339],
                [106.5230187, 11.4806358],
                [106.522893, 11.4806187],
                [106.5227582, 11.4805826],
                [106.5226377, 11.4805277],
                [106.5226026, 11.4805213],
                [106.5225179, 11.4805273],
                [106.5224266, 11.480563],
                [106.522358, 11.4806203],
                [106.5223107, 11.4806954],
                [106.5222894, 11.4807812],
                [106.5222412, 11.4808947],
                [106.5221201, 11.4810975],
                [106.5220343, 11.4811917],
                [106.5219418, 11.4812576],
                [106.5218755, 11.4813299],
                [106.5218389, 11.4814204],
                [106.5218334, 11.4814696],
                [106.5218064, 11.4815648],
                [106.5217534, 11.4816605],
                [106.5216709, 11.481748],
                [106.5215531, 11.481839],
                [106.5214439, 11.4819087],
                [106.5213649, 11.481931],
                [106.5213237, 11.4819322],
                [106.5212828, 11.4819267],
                [106.5211628, 11.4818788],
                [106.5210874, 11.4818615],
                [106.520988, 11.4818547],
                [106.5208154, 11.4818598],
                [106.520665, 11.4818179],
                [106.5206139, 11.4818108],
                [106.5204862, 11.481818],
                [106.5203198, 11.4818607],
                [106.5202242, 11.4818594],
                [106.5201729, 11.4818473],
                [106.5199153, 11.4817434],
                [106.5197384, 11.4816473],
                [106.5195133, 11.4814915],
                [106.5194556, 11.481473],
                [106.5194251, 11.4814736],
                [106.5193631, 11.4814979],
                [106.5193123, 11.4815492],
                [106.5192973, 11.4815778],
                [106.5192608, 11.4817093],
                [106.5192255, 11.4817811],
                [106.5191996, 11.4818121],
                [106.5191008, 11.4818995],
                [106.5190601, 11.4819514],
                [106.5189663, 11.4820986],
                [106.5188907, 11.4821839],
                [106.5187985, 11.48227],
                [106.5187815, 11.4822999],
                [106.5187553, 11.4823814],
                [106.5187379, 11.4824009],
                [106.5187158, 11.4824149],
                [106.5186641, 11.4824224],
                [106.5186164, 11.4824017],
                [106.5185693, 11.4823299],
                [106.5185189, 11.4822847],
                [106.5184436, 11.4821989],
                [106.5184003, 11.482117],
                [106.5183845, 11.4820618],
                [106.5183724, 11.4819481],
                [106.5183818, 11.4818556],
                [106.5183747, 11.4817847],
                [106.518344, 11.4817201],
                [106.5183239, 11.4816957],
                [106.5182721, 11.4816597],
                [106.5182421, 11.4816491],
                [106.5181791, 11.4816446],
                [106.5181187, 11.4816622],
                [106.5180528, 11.4816698],
                [106.5179202, 11.4816675],
                [106.5177677, 11.481635],
                [106.517598, 11.4815794],
                [106.5174216, 11.4815501],
                [106.5173106, 11.4815469],
                [106.5172782, 11.4815522],
                [106.5171888, 11.481585],
                [106.5171263, 11.481586],
                [106.517058, 11.4815594],
                [106.5169807, 11.4814864],
                [106.5169492, 11.4814703],
                [106.5168849, 11.4814586],
                [106.5168205, 11.4814703],
                [106.5167854, 11.4814882],
                [106.5166944, 11.4815623],
                [106.5166401, 11.481616],
                [106.5165357, 11.4817523],
                [106.5164184, 11.481857],
                [106.5163833, 11.4818988],
                [106.516352, 11.4819456],
                [106.5163131, 11.4820503],
                [106.5163063, 11.4821058],
                [106.5163147, 11.4822417],
                [106.5162933, 11.4823192],
                [106.5162465, 11.4823851],
                [106.5161743, 11.4824337],
                [106.5161324, 11.482447],
                [106.5160449, 11.482449],
                [106.5158943, 11.4824027],
                [106.5157436, 11.4823912],
                [106.515643, 11.482393],
                [106.5153748, 11.4824192],
                [106.5151988, 11.4824178],
                [106.5150261, 11.4823851],
                [106.514929, 11.4823519],
                [106.5148292, 11.4823275],
                [106.5147275, 11.4823123],
                [106.5146248, 11.4823063],
                [106.514522, 11.4823095],
                [106.5143549, 11.4823349],
                [106.5142326, 11.4823849],
                [106.5141774, 11.482421],
                [106.5140858, 11.4825102],
                [106.5139103, 11.4827662],
                [106.5137064, 11.4831139],
                [106.5135524, 11.4833395],
                [106.5134209, 11.4835786],
                [106.513339, 11.4836809],
                [106.5132785, 11.483736],
                [106.5131459, 11.483886],
                [106.5130253, 11.483969],
                [106.5128917, 11.48403],
                [106.5127553, 11.4840659],
                [106.5124442, 11.4841265],
                [106.5122285, 11.4842014],
                [106.5120877, 11.4842771],
                [106.5119677, 11.4843817],
                [106.5118857, 11.4844909],
                [106.5118025, 11.4846409],
                [106.5116774, 11.4849058],
                [106.5116508, 11.4850273],
                [106.5116299, 11.4852185],
                [106.5116094, 11.4852814],
                [106.5115803, 11.485341],
                [106.5114989, 11.485446],
                [106.511448, 11.4854894],
                [106.5113911, 11.4855259],
                [106.5113304, 11.4855561],
                [106.5112007, 11.4855962],
                [106.5110653, 11.4856076],
                [106.51086, 11.4855838],
                [106.5107905, 11.4855851],
                [106.5106539, 11.485609],
                [106.5105883, 11.4856313],
                [106.5104688, 11.4856935],
                [106.5103671, 11.4857756],
                [106.5102839, 11.4858757],
                [106.5102327, 11.4859656],
                [106.5101682, 11.4860309],
                [106.5101344, 11.4860523],
                [106.5100585, 11.4860775],
                [106.5099795, 11.4860778],
                [106.5099031, 11.4860913],
                [106.5098737, 11.486104],
                [106.5098246, 11.4861441],
                [106.5097918, 11.4862046],
                [106.509785, 11.4862414],
                [106.5097609, 11.4866827],
                [106.5097401, 11.4869],
                [106.5097055, 11.4869809],
                [106.5096422, 11.4870429],
                [106.5096027, 11.4870638],
                [106.5094739, 11.4871032],
                [106.5093646, 11.4871626],
                [106.5093153, 11.4872002],
                [106.5092034, 11.4873091],
                [106.5091645, 11.487336],
                [106.5090827, 11.4873739],
                [106.5088862, 11.4874317],
                [106.5088248, 11.4874765],
                [106.5088013, 11.4875042],
                [106.5087685, 11.4875685],
                [106.5087442, 11.4877315],
                [106.5086831, 11.4878431],
                [106.5086719, 11.4878865],
                [106.5086605, 11.4880158],
                [106.5086424, 11.4880784],
                [106.5086, 11.4881595],
                [106.5085312, 11.4882207],
                [106.5084453, 11.4882543],
                [106.5083534, 11.488256],
                [106.5082669, 11.4882256],
                [106.5082132, 11.4881849],
                [106.5081026, 11.4881312],
                [106.5079929, 11.4881113],
                [106.5078977, 11.4881181],
                [106.5077837, 11.4881511],
                [106.5075722, 11.4882605],
                [106.5074982, 11.4882907],
                [106.507315, 11.4883497],
                [106.5071904, 11.4884275],
                [106.5071472, 11.4884424],
                [106.5070563, 11.4884504],
                [106.5069677, 11.4884291],
                [106.5068909, 11.4883807],
                [106.5067923, 11.4882555],
                [106.5066751, 11.4880752],
                [106.5065788, 11.4879792],
                [106.5064677, 11.4879068],
                [106.5063478, 11.4878592],
                [106.5062086, 11.4878351],
                [106.5060552, 11.4878388],
                [106.5059796, 11.4878519],
                [106.5059057, 11.4878722],
                [106.5057887, 11.4879215],
                [106.505574, 11.4880521],
                [106.5054822, 11.4881249],
                [106.505373, 11.4882517],
                [106.5052539, 11.4883552],
                [106.5051856, 11.4884425],
                [106.5051586, 11.4884954],
                [106.5051302, 11.4885972],
                [106.505126, 11.4887246],
                [106.5050992, 11.4888012],
                [106.5050431, 11.4888717],
                [106.5049678, 11.4889221],
                [106.5049267, 11.4889378],
                [106.5047976, 11.4889658],
                [106.5045364, 11.4889932],
                [106.5044508, 11.489011],
                [106.5041471, 11.4891114],
                [106.5040289, 11.4891381],
                [106.5037882, 11.4891658],
                [106.5035739, 11.4891613],
                [106.503388, 11.4891719],
                [106.5032563, 11.4891999],
                [106.5031332, 11.4892533],
                [106.5030594, 11.4893057],
                [106.5030044, 11.4893769],
                [106.5029852, 11.4894187],
                [106.5028982, 11.4897165],
                [106.5028233, 11.4898957],
                [106.5027228, 11.4900866],
                [106.5025065, 11.4904207],
                [106.5023527, 11.4906885],
                [106.5022641, 11.4908694],
                [106.5020354, 11.4914144],
                [106.5019601, 11.4915449],
                [106.5018622, 11.4916681],
                [106.5018049, 11.4917269],
                [106.5016967, 11.4918197],
                [106.5014949, 11.4919574],
                [106.5014375, 11.4919833],
                [106.5013146, 11.4920106],
                [106.5011602, 11.4920165],
                [106.5010121, 11.4920468],
                [106.5008721, 11.4920947],
                [106.5007961, 11.4921287],
                [106.5007246, 11.4921709],
                [106.5006611, 11.4922186],
                [106.5006039, 11.4922734],
                [106.5004966, 11.492397],
                [106.5002712, 11.4926021],
                [106.5002019, 11.4926892],
                [106.5001387, 11.4928249],
                [106.5000851, 11.4928871],
                [106.5000433, 11.4929226],
                [106.4998877, 11.4930225],
                [106.499645, 11.4932253],
                [106.4992436, 11.4935297],
                [106.4991417, 11.4936406],
                [106.4990114, 11.4938107],
                [106.4988833, 11.4939476],
                [106.4987964, 11.4940059],
                [106.4987477, 11.4940261],
                [106.4986598, 11.4940453],
                [106.4985951, 11.4940812],
                [106.4985711, 11.4941093],
                [106.4985477, 11.4941697],
                [106.4985493, 11.4943105],
                [106.4985673, 11.4943859],
                [106.4985761, 11.4944629],
                [106.4985718, 11.4945594],
                [106.4985607, 11.4946066],
                [106.4985197, 11.4946985],
                [106.4983963, 11.4948766],
                [106.4983549, 11.4949198],
                [106.4982678, 11.4949815],
                [106.4980134, 11.4950866],
                [106.4978919, 11.4951557],
                [106.4978331, 11.4951984],
                [106.4977258, 11.4952961],
                [106.4976672, 11.495364],
                [106.4975258, 11.4955784],
                [106.4974758, 11.4956385],
                [106.4973608, 11.4957448],
                [106.4972967, 11.4957902],
                [106.4971714, 11.495858],
                [106.4969105, 11.4960189],
                [106.4967125, 11.4961067],
                [106.4965054, 11.4961714],
                [106.4961227, 11.4962493],
                [106.4960092, 11.4962949],
                [106.4958864, 11.4963563],
                [106.4957528, 11.4963902],
                [106.4956149, 11.4963948],
                [106.4955395, 11.4963847],
                [106.495466, 11.4963655],
                [106.4953955, 11.4963375],
                [106.4953291, 11.4963011],
                [106.4952678, 11.4962569],
                [106.4951238, 11.4961141],
                [106.4950712, 11.4960775],
                [106.4949665, 11.4960296],
                [106.4948655, 11.496015],
                [106.4948145, 11.4960189],
                [106.4947172, 11.4960488],
                [106.494673, 11.4960742],
                [106.4946054, 11.4961346],
                [106.4944625, 11.4963234],
                [106.4943863, 11.4964121],
                [106.4941424, 11.4966418],
                [106.4940312, 11.496772],
                [106.4939498, 11.4968909],
                [106.4938688, 11.4969757],
                [106.4937914, 11.4970266],
                [106.4937091, 11.4970697],
                [106.4936229, 11.4971045],
                [106.4933549, 11.4971706],
                [106.4932205, 11.4972173],
                [106.4931771, 11.4972438],
                [106.4931394, 11.4972775],
                [106.4931086, 11.4973173],
                [106.4930856, 11.497362],
                [106.4930685, 11.4974273],
                [106.4930278, 11.4975296],
                [106.4929732, 11.497595],
                [106.4929378, 11.4976194],
                [106.4928567, 11.4976481],
                [106.4927705, 11.4976471],
                [106.4927289, 11.4976354],
                [106.4926102, 11.4975803],
                [106.4925465, 11.4975644],
                [106.4924744, 11.4975563],
                [106.4923295, 11.4975635],
                [106.4921898, 11.4976012],
                [106.4920604, 11.4976683],
                [106.4919562, 11.4977532],
                [106.4918813, 11.4978509],
                [106.4918055, 11.4980296],
                [106.4917758, 11.4980755],
                [106.4917002, 11.4981486],
                [106.4916084, 11.4981909],
                [106.4915582, 11.4981997],
                [106.4914571, 11.4981912],
                [106.4913261, 11.4981321],
                [106.4912308, 11.4981015],
                [106.4910703, 11.4980702],
                [106.4908071, 11.4980487],
                [106.4907345, 11.4980349],
                [106.4906642, 11.4980123],
                [106.4905974, 11.4979812],
                [106.4905352, 11.4979421],
                [106.4903685, 11.4978036],
                [106.4903166, 11.4977754],
                [106.4902607, 11.497756],
                [106.4901649, 11.4977442],
                [106.4900531, 11.4977535],
                [106.4899544, 11.4977729],
                [106.4899116, 11.4978013],
                [106.4898965, 11.497822],
                [106.4898839, 11.4978586],
                [106.489875, 11.497935],
                [106.4898941, 11.4980285],
                [106.4899619, 11.4981451],
                [106.4899951, 11.4982325],
                [106.4900064, 11.4983319],
                [106.4899946, 11.4984006],
                [106.4899784, 11.4984319],
                [106.4899287, 11.4984817],
                [106.4898649, 11.498508],
                [106.4897739, 11.4985647],
                [106.4897468, 11.4985922],
                [106.48971, 11.4986545],
                [106.4896953, 11.4987209],
                [106.4896703, 11.4990687],
                [106.4896523, 11.4991788],
                [106.4896093, 11.4993392],
                [106.4895853, 11.4993882],
                [106.4895164, 11.4994731],
                [106.4894251, 11.4995342],
                [106.4893734, 11.4995541],
                [106.4892408, 11.4995781],
                [106.4889613, 11.4995855],
                [106.4887936, 11.4995608],
                [106.4887125, 11.4995363],
                [106.4885595, 11.4994646],
                [106.4884239, 11.4993649],
                [106.4882979, 11.4992518],
                [106.4882464, 11.4992173],
                [106.4880822, 11.4991297],
                [106.4877367, 11.4989956],
                [106.4872215, 11.4987386],
                [106.4869026, 11.4986209],
                [106.4868299, 11.4985814],
                [106.4866994, 11.4984811],
                [106.4866113, 11.4983814],
                [106.486527, 11.4982544],
                [106.4864532, 11.4981205],
                [106.4864215, 11.4980798],
                [106.4863474, 11.4980232],
                [106.486308, 11.4980089],
                [106.4862245, 11.498006],
                [106.4860893, 11.4980359],
                [106.4857924, 11.4980501],
                [106.4853045, 11.4981032],
                [106.4851326, 11.4981106],
                [106.4849612, 11.4980953],
                [106.4848618, 11.4980695],
                [106.4848167, 11.4980451],
                [106.4847077, 11.4979543],
                [106.4846662, 11.497935],
                [106.4845949, 11.4979224],
                [106.4845587, 11.4979262],
                [106.4844918, 11.4979534],
                [106.4844486, 11.4979919],
                [106.4843551, 11.4980476],
                [106.4842102, 11.498098],
                [106.4839905, 11.4981542],
                [106.483883, 11.49819],
                [106.4837652, 11.4982397],
                [106.4837099, 11.4982718],
                [106.4836079, 11.4983497],
                [106.4835644, 11.4983974],
                [106.4834994, 11.498508],
                [106.4834754, 11.4985844],
                [106.4834114, 11.4987141],
                [106.4833277, 11.4988129],
                [106.4832758, 11.4988546],
                [106.4831149, 11.498951],
                [106.4828675, 11.4990775],
                [106.4827463, 11.4991313],
                [106.4826214, 11.4991763],
                [106.4824936, 11.4992124],
                [106.4822746, 11.4992522],
                [106.4820889, 11.4992683],
                [106.4820139, 11.4992676],
                [106.4819392, 11.4992602],
                [106.4817935, 11.4992255],
                [106.4817049, 11.4991868],
                [106.4816282, 11.4991282],
                [106.4815331, 11.4990196],
                [106.481391, 11.4988171],
                [106.4813375, 11.498763],
                [106.4811725, 11.4986468],
                [106.4809901, 11.4985506],
                [106.4809168, 11.4984763],
                [106.4808896, 11.4984318],
                [106.4808645, 11.4983666],
                [106.4808484, 11.4982987],
                [106.4808428, 11.4981681],
                [106.4808681, 11.4980482],
                [106.4808916, 11.4979913],
                [106.4809496, 11.4978988],
                [106.4809691, 11.4978553],
                [106.4809797, 11.497809],
                [106.4809775, 11.4977362],
                [106.4809553, 11.4976629],
                [106.480903, 11.4975749],
                [106.4808647, 11.497534],
                [106.4806508, 11.4973658],
                [106.4804739, 11.4972569],
                [106.4804088, 11.4972236],
                [106.4802727, 11.4971702],
                [106.4801917, 11.4971538],
                [106.4801502, 11.497157],
                [106.4800727, 11.4971853],
                [106.4800394, 11.4972096],
                [106.4799482, 11.4973121],
                [106.4798635, 11.4973831],
                [106.479816, 11.4974118],
                [106.4797212, 11.4974518],
                [106.4795828, 11.4974848],
                [106.4794888, 11.4974975],
                [106.4792991, 11.4974987],
                [106.4791929, 11.4974812],
                [106.4791422, 11.4974631],
                [106.4790496, 11.4974093],
                [106.4789672, 11.4973282],
                [106.4789082, 11.4972295],
                [106.4788765, 11.4971208],
                [106.4788305, 11.4970186],
                [106.4787975, 11.496973],
                [106.4787494, 11.496924],
                [106.4786947, 11.4968822],
                [106.4786344, 11.4968484],
                [106.4785699, 11.4968233],
                [106.4785024, 11.4968074],
                [106.4783415, 11.496786],
                [106.4782191, 11.4967452],
                [106.4780914, 11.4966795],
                [106.4780115, 11.4966272],
                [106.4778691, 11.4965053],
                [106.4776014, 11.4962007],
                [106.4774191, 11.496016],
                [106.4772171, 11.4958522],
                [106.4771094, 11.4957788],
                [106.4769414, 11.4956805],
                [106.4768199, 11.4956381],
                [106.4767131, 11.495624],
                [106.4765833, 11.4956282],
                [106.4764134, 11.4956603],
                [106.4762524, 11.4957223],
                [106.476177, 11.495764],
                [106.4761058, 11.4958122],
                [106.4759239, 11.4959708],
                [106.4758641, 11.4960074],
                [106.4757325, 11.4960566],
                [106.4755924, 11.4960712],
                [106.4755221, 11.4960651],
                [106.4753868, 11.4960269],
                [106.4752788, 11.4959657],
                [106.4751555, 11.4958612],
                [106.4750807, 11.4957841],
                [106.4750129, 11.4957009],
                [106.4748913, 11.4955301],
                [106.4747951, 11.4954137],
                [106.4747174, 11.4953644],
                [106.4746264, 11.4953479],
                [106.4745358, 11.4953668],
                [106.4744951, 11.4953887],
                [106.4744595, 11.4954181],
                [106.474409, 11.4954941],
                [106.4743811, 11.4956273],
                [106.4743638, 11.4956693],
                [106.4743168, 11.4957377],
                [106.474263, 11.495801],
                [106.4741611, 11.4958924],
                [106.4740277, 11.4959832],
                [106.4739346, 11.4960373],
                [106.4736497, 11.4961692],
                [106.4733553, 11.4962791],
                [106.47316, 11.4963384],
                [106.4729621, 11.4963888],
                [106.4727621, 11.4964303],
                [106.4725604, 11.4964627],
                [106.4723573, 11.496486],
                [106.4721534, 11.4965001],
                [106.471949, 11.496505],
                [106.4717446, 11.4965007],
                [106.4715406, 11.4964872],
                [106.4713375, 11.4964646],
                [106.4711356, 11.4964328],
                [106.4709355, 11.496392],
                [106.4706109, 11.4963054],
                [106.470482, 11.4962776],
                [106.4703513, 11.4962591],
                [106.4702197, 11.49625],
                [106.4700877, 11.4962503],
                [106.4699561, 11.4962601],
                [106.4698255, 11.4962791],
                [106.4695623, 11.4963464],
                [106.4694347, 11.4963928],
                [106.4691903, 11.4965098],
                [106.468964, 11.4966576],
                [106.4688588, 11.4967422],
                [106.4686668, 11.496931],
                [106.4683972, 11.4972531],
                [106.4681001, 11.4975512],
                [106.467942, 11.4976905],
                [106.4677779, 11.497823],
                [106.4676081, 11.4979484],
                [106.4672665, 11.4981689],
                [106.4669218, 11.4983539],
                [106.466744, 11.4984361],
                [106.4663792, 11.4985792],
                [106.4660038, 11.4986934],
                [106.4656441, 11.4987726],
                [106.4654622, 11.4988019],
                [106.4652792, 11.4988243],
                [106.4635564, 11.4988924],
                [106.4618325, 11.4989285],
                [106.4609235, 11.4989347],
                [106.458548, 11.4989204],
                [106.4579905, 11.4989271],
                [106.4568758, 11.4989601],
                [106.4557591, 11.4990193],
                [106.4546442, 11.4991046],
                [106.4514846, 11.4994332],
                [106.4511195, 11.4994633],
                [106.4503879, 11.4995005],
                [106.4500216, 11.4995075],
                [106.4492522, 11.4994971],
                [106.4488495, 11.4994781],
                [106.4484473, 11.4994498],
                [106.448046, 11.4994122],
                [106.4451706, 11.4989706],
                [106.4446898, 11.4989093],
                [106.4444479, 11.4988917],
                [106.4439631, 11.4988829],
                [106.4437208, 11.4988917],
                [106.4432379, 11.4989356],
                [106.442998, 11.4989706],
                [106.4427529, 11.4990157],
                [106.4425096, 11.4990699],
                [106.4422686, 11.4991331],
                [106.4420302, 11.4992053],
                [106.4417949, 11.4992863],
                [106.4415628, 11.499376],
                [106.4413344, 11.4994743],
                [106.44111, 11.499581],
                [106.4408898, 11.499696],
                [106.4380574, 11.5011995],
                [106.4378334, 11.5012776],
                [106.437377, 11.5014082],
                [106.4369116, 11.5015039],
                [106.4366765, 11.5015384],
                [106.4364401, 11.5015641],
                [106.4362029, 11.5015808],
                [106.4359653, 11.5015885],
                [106.4357175, 11.5015869],
                [106.43547, 11.5015756],
                [106.4352231, 11.5015545],
                [106.4349773, 11.5015237],
                [106.4347329, 11.5014832],
                [106.4344905, 11.5014332],
                [106.4342502, 11.5013736],
                [106.4340126, 11.5013046],
                [106.4337718, 11.5012127],
                [106.4335346, 11.5011121],
                [106.4333013, 11.501003],
                [106.4330723, 11.5008855],
                [106.4328479, 11.5007598],
                [106.4326284, 11.5006261],
                [106.432414, 11.5004845],
                [106.4320126, 11.500187],
                [106.4316341, 11.499862],
                [106.431454, 11.4996897],
                [106.4311133, 11.4993266],
                [106.4309532, 11.4991363],
                [106.4306465, 11.4987446],
                [106.4304578, 11.4985234],
                [106.430254, 11.4983141],
                [106.4300431, 11.4981115],
                [106.4298255, 11.4979158],
                [106.4296015, 11.4977274],
                [106.4293711, 11.4975463],
                [106.4291348, 11.4973728],
                [106.4288927, 11.4972071],
                [106.4286451, 11.4970494],
                [106.4283923, 11.4968998],
                [106.4281346, 11.4967584],
                [106.4278723, 11.4966256],
                [106.4276056, 11.4965013],
                [106.4273349, 11.4963858],
                [106.4270603, 11.4962791],
                [106.4267891, 11.4961837],
                [106.4265149, 11.496097],
                [106.426238, 11.496019],
                [106.4259587, 11.4959498],
                [106.4256772, 11.4958895],
                [106.4253938, 11.4958381],
                [106.4251089, 11.4957958],
                [106.4248228, 11.4957625],
                [106.4245357, 11.4957384],
                [106.424248, 11.4957233],
                [106.4239599, 11.4957174],
                [106.4236718, 11.4957206],
                [106.4233839, 11.495733],
                [106.4233526, 11.4953092],
                [106.4232623, 11.494464],
                [106.4232035, 11.494043],
                [106.4231356, 11.4936233],
                [106.4230586, 11.4932051],
                [106.4228805, 11.4923858],
                [106.4226709, 11.4915863],
                [106.4225535, 11.4911898],
                [106.4222939, 11.4904045],
                [106.4220015, 11.4896303],
                [106.4218432, 11.4892477],
                [106.4215026, 11.4884927],
                [106.4209244, 11.4873076],
                [106.4205194, 11.4864989],
                [106.4201056, 11.4856945],
                [106.4196829, 11.4848946],
                [106.4192515, 11.4840992],
                [106.4188114, 11.4833084],
                [106.4183625, 11.4825222],
                [106.4179051, 11.4817409],
                [106.417439, 11.4809644],
                [106.4169645, 11.4801929],
                [106.4164862, 11.4794339],
                [106.4159998, 11.47868],
                [106.4155051, 11.4779312],
                [106.4150023, 11.4771876],
                [106.4144914, 11.4764493],
                [106.4139725, 11.4757164],
                [106.4134457, 11.474989],
                [106.4129109, 11.4742671],
                [106.4123683, 11.4735509],
                [106.411818, 11.4728404],
                [106.4112599, 11.4721356],
                [106.4106942, 11.4714368],
                [106.4101209, 11.4707439],
                [106.4095401, 11.4700571],
                [106.4085928, 11.4691507],
                [106.4076215, 11.468269],
                [106.4071272, 11.4678375],
                [106.4061215, 11.4669937],
                [106.4050936, 11.466176],
                [106.4040442, 11.4653849],
                [106.4029741, 11.464621],
                [106.4024128, 11.4642367],
                [106.4018463, 11.4638599],
                [106.4012746, 11.4634907],
                [106.4001162, 11.4627751],
                [106.3995297, 11.4624289],
                [106.3989385, 11.4620905],
                [106.3983426, 11.4617599],
                [106.3977423, 11.4614373],
                [106.3971376, 11.4611226],
                [106.3965286, 11.460816],
                [106.3959154, 11.4605175],
                [106.3946769, 11.459945],
                [106.3940519, 11.4596711],
                [106.3934231, 11.4594055],
                [106.3927908, 11.4591483],
                [106.3921549, 11.4588994],
                [106.3915157, 11.458659],
                [106.3900781, 11.4582839],
                [106.38865, 11.4578754],
                [106.3872322, 11.4574336],
                [106.3858255, 11.4569589],
                [106.3851267, 11.4567092],
                [106.3837382, 11.4561856],
                [106.3823629, 11.4556297],
                [106.3816804, 11.4553397],
                [106.3810014, 11.4550419],
                [106.3802924, 11.4547205],
                [106.3795876, 11.4543906],
                [106.378887, 11.454052],
                [106.3781908, 11.4537048],
                [106.3774991, 11.4533492],
                [106.3764376, 11.4527808],
                [106.3754519, 11.4522318],
                [106.3747791, 11.4518427],
                [106.3741114, 11.4514454],
                [106.3734488, 11.4510399],
                [106.3727915, 11.4506264],
                [106.3721394, 11.4502048],
                [106.3718098, 11.4499052],
                [106.3714869, 11.4495986],
                [106.3711708, 11.4492851],
                [106.3708619, 11.448965],
                [106.3705602, 11.4486383],
                [106.3702658, 11.4483053],
                [106.3699789, 11.447966],
                [106.3696996, 11.4476207],
                [106.369428, 11.4472694],
                [106.3691644, 11.4469125],
                [106.3689088, 11.44655],
                [106.3686613, 11.4461821],
                [106.3684221, 11.445809],
                [106.3681912, 11.4454308],
                [106.3679782, 11.4450644],
                [106.3675759, 11.4443189],
                [106.3673867, 11.4439402],
                [106.3670329, 11.4431714],
                [106.366712, 11.4423888],
                [106.3665642, 11.4419928],
                [106.3663569, 11.4412813],
                [106.3661836, 11.440561],
                [106.3660449, 11.4398336],
                [106.365941, 11.4391007],
                [106.365901, 11.4387205],
                [106.3658704, 11.4383395],
                [106.3658492, 11.437958],
                [106.3658374, 11.437576],
                [106.365835, 11.4371938],
                [106.3658536, 11.4364047],
                [106.365864, 11.434826],
                [106.365839, 11.4332475],
                [106.3658133, 11.4324585],
                [106.3657351, 11.4308817],
                [106.3656192, 11.4292787],
                [106.3654666, 11.4276787],
                [106.3653778, 11.4269121],
                [106.3652559, 11.4262734],
                [106.3651872, 11.4259555],
                [106.3650315, 11.4253127],
                [106.3649458, 11.4249933],
                [106.3648607, 11.4247504],
                [106.3647671, 11.4245106],
                [106.3646648, 11.4242742],
                [106.3645542, 11.4240415],
                [106.3643407, 11.4236441],
                [106.3642247, 11.4234504],
                [106.3639748, 11.423074],
                [106.3638299, 11.4229073],
                [106.3636777, 11.4227469],
                [106.3635186, 11.4225931],
                [106.3633529, 11.4224461],
                [106.3631809, 11.4223063],
                [106.3628388, 11.422062],
                [106.3624775, 11.421846],
                [106.3622904, 11.421749],
                [106.3620033, 11.4215628],
                [106.3617377, 11.4213481],
                [106.3616139, 11.4212309],
                [106.3614964, 11.4211075],
                [106.3613822, 11.4209738],
                [106.3612756, 11.4208343],
                [106.3611768, 11.4206893],
                [106.3610861, 11.4205393],
                [106.3610038, 11.4203848],
                [106.3609302, 11.4202261],
                [106.3608655, 11.4200637],
                [106.3608098, 11.4198981],
                [106.3605683, 11.4191941],
                [106.3603579, 11.4184805],
                [106.3601791, 11.4177587],
                [106.3600294, 11.4170141],
                [106.3599659, 11.4166315],
                [106.3598652, 11.4158627],
                [106.3598282, 11.4154768],
                [106.3597806, 11.4147031],
                [106.3597686, 11.413928],
                [106.3597922, 11.4131532],
                [106.3598513, 11.4123802],
                [106.3608505, 11.4030493],
                [106.3608817, 11.4026899],
                [106.3609033, 11.4023298],
                [106.3609153, 11.4019692],
                [106.3609177, 11.4016085],
                [106.3609105, 11.4012478],
                [106.3608937, 11.4008874],
                [106.3608672, 11.4005276],
                [106.3608312, 11.4001686],
                [106.3607852, 11.3998074],
                [106.3607295, 11.3994475],
                [106.3606641, 11.3990891],
                [106.3605891, 11.3987326],
                [106.3605046, 11.3983781],
                [106.3604106, 11.398026],
                [106.3603071, 11.3976764],
                [106.3602241, 11.3974746],
                [106.3600334, 11.3970816],
                [106.359811, 11.396705],
                [106.3595583, 11.3963472],
                [106.359277, 11.3960105],
                [106.3589689, 11.3956973],
                [106.3588023, 11.3955474],
                [106.3586296, 11.3954044],
                [106.3584511, 11.3952684],
                [106.358267, 11.3951398],
                [106.3580776, 11.3950187],
                [106.3578834, 11.3949053],
                [106.3576845, 11.3947998],
                [106.3574814, 11.3947025],
                [106.3572744, 11.3946134],
                [106.3568011, 11.3944319],
                [106.356542, 11.3943226],
                [106.3562867, 11.3942049],
                [106.3560354, 11.3940791],
                [106.3555763, 11.3938215],
                [106.3551342, 11.3935365],
                [106.354711, 11.3932253],
                [106.3543082, 11.392889],
                [106.354115, 11.3927118],
                [106.3539238, 11.3924834],
                [106.3537402, 11.3922492],
                [106.3535643, 11.3920093],
                [106.3533962, 11.3917641],
                [106.3532362, 11.3915137],
                [106.3530844, 11.3912585],
                [106.352941, 11.3909987],
                [106.3528061, 11.3907345],
                [106.3526834, 11.3904742],
                [106.3524629, 11.3899431],
                [106.3523653, 11.3896727],
                [106.3521959, 11.3891238],
                [106.3521243, 11.3888458],
                [106.3520075, 11.3882839],
                [106.3519624, 11.3880006],
                [106.3518824, 11.3872997],
                [106.3518459, 11.3868828],
                [106.3518169, 11.3864652],
                [106.3517815, 11.385629],
                [106.3517761, 11.3847921],
                [106.3517865, 11.3843136],
                [106.3518068, 11.3838354],
                [106.3518368, 11.3833577],
                [106.3518767, 11.3828807],
                [106.3519841, 11.3819051],
                [106.3520007, 11.3816543],
                [106.3520094, 11.3811517],
                [106.3519854, 11.3806508],
                [106.3519612, 11.3804018],
                [106.3518888, 11.3799065],
                [106.3518536, 11.3797644],
                [106.3517578, 11.3794874],
                [106.3516975, 11.3793535],
                [106.3515532, 11.3790975],
                [106.3513792, 11.37886],
                [106.3512765, 11.3787438],
                [106.3511668, 11.378634],
                [106.3510502, 11.3785312],
                [106.3509274, 11.3784357],
                [106.3507988, 11.3783478],
                [106.3506648, 11.3782679],
                [106.350526, 11.3781964],
                [106.350383, 11.3781335],
                [106.3502362, 11.3780794],
                [106.3500863, 11.3780343],
                [106.3499045, 11.3780041],
                [106.3497246, 11.3779645],
                [106.349547, 11.3779158],
                [106.3493722, 11.377858],
                [106.3492008, 11.3777914],
                [106.3490332, 11.377716],
                [106.3488698, 11.3776322],
                [106.348711, 11.3775402],
                [106.3485575, 11.3774401],
                [106.3484154, 11.3773368],
                [106.3481479, 11.3771101],
                [106.347905, 11.3768582],
                [106.347689, 11.3765838],
                [106.3475021, 11.3762895],
                [106.3474202, 11.3761358],
                [106.3472223, 11.3755032],
                [106.3470593, 11.3748611],
                [106.3469354, 11.3742334],
                [106.3468475, 11.3736224],
                [106.3467909, 11.3730079],
                [106.3467744, 11.3726998],
                [106.3467504, 11.371543],
                [106.3466988, 11.370696],
                [106.3466109, 11.3698519],
                [106.3464871, 11.3690121],
                [106.3463268, 11.3681753],
                [106.3461299, 11.3673431],
                [106.3460181, 11.3669303],
                [106.3458975, 11.3665198],
                [106.345768, 11.366112],
                [106.3456319, 11.365778],
                [106.3455517, 11.3656162],
                [106.3453677, 11.365305],
                [106.3451538, 11.3650128],
                [106.3450363, 11.3648746],
                [106.3447815, 11.3646158],
                [106.3445024, 11.3643824],
                [106.3443545, 11.3642759],
                [106.3442016, 11.3641766],
                [106.3440338, 11.3640791],
                [106.3438612, 11.3639902],
                [106.3436841, 11.3639101],
                [106.3435031, 11.3638391],
                [106.3433186, 11.3637773],
                [106.343131, 11.3637249],
                [106.342941, 11.3636821],
                [106.342749, 11.3636488],
                [106.3425555, 11.3636254],
                [106.342361, 11.3636117],
                [106.342166, 11.3636078],
                [106.3376194, 11.3638505],
                [106.3374941, 11.3638659],
                [106.3372416, 11.3638698],
                [106.3369911, 11.363838],
                [106.3368683, 11.3638089],
                [106.3366308, 11.3637249],
                [106.3365173, 11.3636705],
                [106.3363038, 11.3635382],
                [106.3362049, 11.3634611],
                [106.336112, 11.3633772],
                [106.3360233, 11.3632844],
                [106.3359419, 11.3631853],
                [106.3358682, 11.3630807],
                [106.3358026, 11.3629709],
                [106.3357455, 11.3628567],
                [106.3356973, 11.3627386],
                [106.3356581, 11.3626173],
                [106.3356282, 11.3624936],
                [106.3356078, 11.3623679],
                [106.335597, 11.3622412],
                [106.3355825, 11.3614658],
                [106.3355626, 11.3610785],
                [106.3354977, 11.3603056],
                [106.3354527, 11.3599204],
                [106.3353376, 11.3591531],
                [106.3352676, 11.3587715],
                [106.3350987, 11.3579956],
                [106.334999, 11.3576019],
                [106.3348905, 11.3572105],
                [106.3347731, 11.3568215],
                [106.3346469, 11.3564352],
                [106.334512, 11.3560518],
                [106.3343684, 11.3556714],
                [106.3342162, 11.3552942],
                [106.3340554, 11.3549204],
                [106.3338863, 11.3545502],
                [106.3334796, 11.3537299],
                [106.3332422, 11.35328],
                [106.3329967, 11.3528343],
                [106.3327431, 11.352393],
                [106.3324815, 11.3519562],
                [106.332212, 11.351524],
                [106.3319347, 11.3510967],
                [106.3316497, 11.3506742],
                [106.331357, 11.3502568],
                [106.3310567, 11.3498446],
                [106.3307491, 11.3494377],
                [106.330434, 11.3490362],
                [106.3301117, 11.3486403],
                [106.3297822, 11.3482502],
                [106.3294457, 11.3478658],
                [106.3291023, 11.3474874],
                [106.3286028, 11.3469597],
                [106.3284607, 11.346798],
                [106.328326, 11.3466303],
                [106.328199, 11.346457],
                [106.3280799, 11.3462784],
                [106.3279689, 11.3460947],
                [106.3278663, 11.3459065],
                [106.3277723, 11.345714],
                [106.327687, 11.3455176],
                [106.3276106, 11.3453178],
                [106.3275433, 11.3451148],
                [106.3274852, 11.3449091],
                [106.3274364, 11.3447011],
                [106.327397, 11.3444912],
                [106.327367, 11.3442798],
                [106.3273466, 11.3440673],
                [106.3273358, 11.3438541],
                [106.3273346, 11.3436374],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [107.3941993, 11.0495544],
                [107.3948362, 11.0494411],
                [107.3954827, 11.0492911],
                [107.3957815, 11.0492022],
                [107.3959279, 11.0491489],
                [107.3967782, 11.0487751],
                [107.3969635, 11.0487279],
                [107.3970587, 11.0487167],
                [107.3972502, 11.0487198],
                [107.3973468, 11.0487344],
                [107.3975337, 11.0487892],
                [107.3976226, 11.048829],
                [107.3977873, 11.0489316],
                [107.3978618, 11.0489936],
                [107.3987389, 11.0499755],
                [107.3988065, 11.0500286],
                [107.3988798, 11.0500739],
                [107.3989578, 11.050111],
                [107.3990394, 11.0501393],
                [107.3991238, 11.0501585],
                [107.3992098, 11.0501684],
                [107.3992965, 11.0501688],
                [107.3993826, 11.0501598],
                [107.3995428, 11.0501164],
                [107.3996899, 11.0500407],
                [107.3997564, 11.0499916],
                [107.3998717, 11.0498742],
                [107.399919, 11.049807],
                [107.4006379, 11.0485645],
                [107.4006995, 11.0484843],
                [107.40078, 11.0484223],
                [107.4008847, 11.0483801],
                [107.4009405, 11.0483704],
                [107.4010536, 11.048375],
                [107.4017139, 11.048536],
                [107.4018365, 11.0485585],
                [107.4019603, 11.0485738],
                [107.4022096, 11.0485829],
                [107.4024756, 11.0485609],
                [107.4027367, 11.0485063],
                [107.402915, 11.0484458],
                [107.4030054, 11.0483836],
                [107.4030748, 11.0482992],
                [107.4031176, 11.0481992],
                [107.4031305, 11.04809],
                [107.4031117, 11.0479816],
                [107.4030626, 11.0478827],
                [107.4026248, 11.0472313],
                [107.4025813, 11.0471089],
                [107.4025691, 11.0469797],
                [107.40259, 11.0468476],
                [107.4026128, 11.0467845],
                [107.4026817, 11.0466691],
                [107.4027517, 11.0466113],
                [107.4029023, 11.0465197],
                [107.4030622, 11.0464592],
                [107.4031458, 11.0464401],
                [107.4032308, 11.0464286],
                [107.4033235, 11.046424],
                [107.4034163, 11.0464268],
                [107.4035997, 11.0464539],
                [107.4037679, 11.0465059],
                [107.4038486, 11.0465411],
                [107.4039842, 11.0466172],
                [107.40411, 11.0466675],
                [107.4041763, 11.0466821],
                [107.4043119, 11.0466895],
                [107.404453, 11.0466657],
                [107.4045239, 11.0466405],
                [107.4045911, 11.046607],
                [107.4046536, 11.0465658],
                [107.4047106, 11.0465173],
                [107.4048335, 11.0463858],
                [107.4048994, 11.0463037],
                [107.4050093, 11.0461248],
                [107.4050528, 11.0460294],
                [107.4051159, 11.0458258],
                [107.4051343, 11.0457208],
                [107.4052888, 11.0436711],
                [107.405314, 11.0435359],
                [107.4053358, 11.0434706],
                [107.4054045, 11.0433342],
                [107.4054489, 11.0432718],
                [107.405567, 11.0431388],
                [107.4056414, 11.0430702],
                [107.4057219, 11.0430086],
                [107.4058079, 11.0429546],
                [107.4058987, 11.0429087],
                [107.4059934, 11.0428713],
                [107.4060913, 11.0428426],
                [107.4061914, 11.042823],
                [107.40638, 11.042811],
                [107.4065679, 11.0428308],
                [107.4067496, 11.042882],
                [107.4068364, 11.0429189],
                [107.4069987, 11.0430138],
                [107.4072104, 11.043166],
                [107.4076223, 11.0434851],
                [107.4080346, 11.0438381],
                [107.4082339, 11.0440221],
                [107.4084632, 11.0442512],
                [107.4085222, 11.0443393],
                [107.4085534, 11.0444036],
                [107.4087059, 11.0448843],
                [107.4087658, 11.0450066],
                [107.4088541, 11.0451111],
                [107.4089073, 11.0451547],
                [107.4090278, 11.0452212],
                [107.409159, 11.0452569],
                [107.409295, 11.0452608],
                [107.4093624, 11.0452508],
                [107.4094911, 11.0452074],
                [107.4095506, 11.0451747],
                [107.4097398, 11.0450282],
                [107.4099218, 11.044926],
                [107.4100184, 11.0448858],
                [107.4102198, 11.0448283],
                [107.4103234, 11.0448115],
                [107.4105372, 11.0448021],
                [107.4106462, 11.0448103],
                [107.4108603, 11.044853],
                [107.410964, 11.0448871],
                [107.411161, 11.0449797],
                [107.4113399, 11.0451027],
                [107.4117596, 11.0454779],
                [107.4119145, 11.0455789],
                [107.4119983, 11.0456185],
                [107.412163, 11.0456714],
                [107.4123217, 11.0456952],
                [107.4124615, 11.0456927],
                [107.412575, 11.0456573],
                [107.4126265, 11.0456276],
                [107.4127133, 11.0455475],
                [107.4127467, 11.0454989],
                [107.4127733, 11.0454436],
                [107.4127909, 11.0453849],
                [107.4127991, 11.0453242],
                [107.4127976, 11.0452631],
                [107.4127864, 11.0452029],
                [107.4127659, 11.0451451],
                [107.4127366, 11.0450911],
                [107.412652, 11.0449687],
                [107.4126125, 11.0448911],
                [107.4125576, 11.0447263],
                [107.412537, 11.0445729],
                [107.412537, 11.0444954],
                [107.4125725, 11.0442408],
                [107.4125779, 11.0441387],
                [107.4125737, 11.0440366],
                [107.4125423, 11.0438555],
                [107.412481, 11.043682],
                [107.4124396, 11.0435996],
                [107.4123802, 11.0433727],
                [107.412341, 11.0431123],
                [107.4123321, 11.0429665],
                [107.4123323, 11.0428203],
                [107.4124181, 11.0420727],
                [107.4124351, 11.0420143],
                [107.4124609, 11.0419592],
                [107.4124949, 11.0419085],
                [107.4125364, 11.0418635],
                [107.412636, 11.0417954],
                [107.4127497, 11.0417596],
                [107.4128691, 11.0417579],
                [107.4129277, 11.04177],
                [107.413707, 11.0420468],
                [107.4137988, 11.0420697],
                [107.4139864, 11.0420959],
                [107.4141927, 11.0420941],
                [107.4143035, 11.0420799],
                [107.4144126, 11.0420566],
                [107.4145193, 11.0420243],
                [107.4146229, 11.0419832],
                [107.4152854, 11.0416462],
                [107.4153668, 11.0416176],
                [107.4155364, 11.0415846],
                [107.4157092, 11.041585],
                [107.4157953, 11.0415979],
                [107.4159615, 11.0416485],
                [107.4160399, 11.0416858],
                [107.4161893, 11.0417797],
                [107.4162689, 11.0418216],
                [107.4163523, 11.0418556],
                [107.4165273, 11.0418989],
                [107.4166072, 11.0419073],
                [107.4167676, 11.0419034],
                [107.416847, 11.0418913],
                [107.4170785, 11.0418302],
                [107.4171573, 11.0418205],
                [107.4173158, 11.0418226],
                [107.417403, 11.0418361],
                [107.4174882, 11.0418583],
                [107.4176496, 11.0419278],
                [107.4177933, 11.0420279],
                [107.4178838, 11.0420824],
                [107.4179789, 11.0421288],
                [107.4180777, 11.0421669],
                [107.41827, 11.0422149],
                [107.4184539, 11.0422313],
                [107.4187502, 11.0422169],
                [107.4188622, 11.0422222],
                [107.4194263, 11.0422925],
                [107.4197111, 11.042295],
                [107.419994, 11.0422622],
                [107.420265, 11.0421965],
                [107.4203968, 11.0421513],
                [107.4206508, 11.0420376],
                [107.4207719, 11.0419694],
                [107.4211768, 11.0416961],
                [107.4212798, 11.0416361],
                [107.4213874, 11.0415844],
                [107.4215866, 11.0415143],
                [107.4218919, 11.041451],
                [107.4219874, 11.0414202],
                [107.4220794, 11.0413803],
                [107.4222323, 11.041288],
                [107.422302, 11.0412324],
                [107.4223937, 11.041143],
                [107.4224155, 11.0411108],
                [107.4224402, 11.0410375],
                [107.4224424, 11.0409989],
                [107.4224234, 11.0409179],
                [107.4224008, 11.040879],
                [107.4223706, 11.0408454],
                [107.4223341, 11.0408186],
                [107.4222928, 11.0407996],
                [107.4222484, 11.0407893],
                [107.422007, 11.0407669],
                [107.4219067, 11.0407428],
                [107.4217131, 11.0406726],
                [107.4216208, 11.040627],
                [107.4214526, 11.0405196],
                [107.4213063, 11.0403935],
                [107.4211796, 11.0402483],
                [107.4211314, 11.0401363],
                [107.4211081, 11.0400176],
                [107.4211105, 11.0398975],
                [107.4211664, 11.0396763],
                [107.4212471, 11.039476],
                [107.4212989, 11.0393809],
                [107.4214194, 11.0392091],
                [107.421489, 11.0391302],
                [107.4215643, 11.0390566],
                [107.4216794, 11.0389602],
                [107.4217083, 11.0389263],
                [107.4217456, 11.0388461],
                [107.4217522, 11.0387583],
                [107.4217265, 11.0386721],
                [107.4217022, 11.0386338],
                [107.4216347, 11.0385731],
                [107.4215937, 11.0385527],
                [107.4213615, 11.0384798],
                [107.4211829, 11.0383963],
                [107.4209962, 11.0382755],
                [107.4209101, 11.0382053],
                [107.4208, 11.0380946],
                [107.4207579, 11.0380148],
                [107.4207464, 11.0379712],
                [107.4207442, 11.0378765],
                [107.4207718, 11.0377858],
                [107.4208156, 11.0377103],
                [107.4208288, 11.0376731],
                [107.4208349, 11.0375947],
                [107.4208156, 11.0375234],
                [107.4207974, 11.0374909],
                [107.4207742, 11.0374618],
                [107.4206856, 11.0373832],
                [107.4206505, 11.0373107],
                [107.4206464, 11.0372318],
                [107.4206725, 11.0371583],
                [107.4206958, 11.0371265],
                [107.4207585, 11.0370788],
                [107.4207957, 11.0370646],
                [107.4209827, 11.0370402],
                [107.4212544, 11.0370303],
                [107.4215767, 11.0370476],
                [107.4216529, 11.0370401],
                [107.4217276, 11.0370232],
                [107.4217995, 11.0369974],
                [107.4219256, 11.0369241],
                [107.4220264, 11.0368285],
                [107.4220677, 11.0367728],
                [107.4221718, 11.0365973],
                [107.4222638, 11.036498],
                [107.4223745, 11.0364192],
                [107.4224417, 11.0363858],
                [107.4225853, 11.0363421],
                [107.4227352, 11.036331],
                [107.4228566, 11.0363474],
                [107.4229706, 11.0363916],
                [107.4230235, 11.0364239],
                [107.423072, 11.0364623],
                [107.423153, 11.0365548],
                [107.4232188, 11.0366046],
                [107.4232984, 11.0366282],
                [107.4233812, 11.0366225],
                [107.4234205, 11.0366088],
                [107.4234932, 11.0365569],
                [107.4235401, 11.0364817],
                [107.4235515, 11.0364389],
                [107.423564, 11.0361507],
                [107.4236047, 11.0360502],
                [107.4236356, 11.0360053],
                [107.4237147, 11.0359309],
                [107.4238114, 11.0358817],
                [107.4239182, 11.0358609],
                [107.4240267, 11.0358703],
                [107.4244344, 11.0359493],
                [107.4246397, 11.0359694],
                [107.4248495, 11.0359559],
                [107.4249548, 11.0359359],
                [107.4251396, 11.035884],
                [107.4253075, 11.0358594],
                [107.4253924, 11.0358583],
                [107.4255609, 11.0358782],
                [107.4257287, 11.0359296],
                [107.4258082, 11.0359664],
                [107.4259552, 11.0360609],
                [107.4265319, 11.0365851],
                [107.4265881, 11.0366215],
                [107.4267117, 11.0366732],
                [107.4268463, 11.0366945],
                [107.4269842, 11.0366825],
                [107.4270509, 11.0366641],
                [107.4271182, 11.0366108],
                [107.4271652, 11.0365396],
                [107.4271876, 11.0364562],
                [107.4271883, 11.0364121],
                [107.4271679, 11.0363266],
                [107.4271474, 11.0362874],
                [107.4269289, 11.0360454],
                [107.4268833, 11.0359533],
                [107.4268672, 11.0359046],
                [107.4268482, 11.0357875],
                [107.4268591, 11.0353478],
                [107.4268879, 11.03523],
                [107.4269396, 11.03512],
                [107.4269833, 11.0350564],
                [107.4270347, 11.0349987],
                [107.427093, 11.0349476],
                [107.4271573, 11.034904],
                [107.4274442, 11.0347774],
                [107.4275471, 11.0347199],
                [107.4276989, 11.0346145],
                [107.4277697, 11.0345553],
                [107.4279052, 11.0344777],
                [107.4280341, 11.0344348],
                [107.4282079, 11.0343968],
                [107.4283129, 11.0343645],
                [107.4283861, 11.0343222],
                [107.428416, 11.0342923],
                [107.4284577, 11.0342197],
                [107.4284689, 11.0341759],
                [107.4284653, 11.034086],
                [107.4284507, 11.0340432],
                [107.4282242, 11.0336197],
                [107.4282101, 11.0335487],
                [107.4282127, 11.0335125],
                [107.4282381, 11.033442],
                [107.4282608, 11.0334103],
                [107.4283222, 11.033362],
                [107.4283587, 11.0333471],
                [107.428484, 11.0333261],
                [107.4286589, 11.0333246],
                [107.4288782, 11.0333616],
                [107.4289945, 11.03337],
                [107.4290826, 11.0333622],
                [107.4291172, 11.0333466],
                [107.4291471, 11.0333233],
                [107.4291796, 11.0332761],
                [107.4292195, 11.0330719],
                [107.4292797, 11.0329514],
                [107.4293527, 11.0328655],
                [107.4293901, 11.0328399],
                [107.429432, 11.0328223],
                [107.4294767, 11.0328134],
                [107.4295222, 11.0328137],
                [107.4296076, 11.0328407],
                [107.429644, 11.0328659],
                [107.4296981, 11.0329348],
                [107.4297135, 11.0329758],
                [107.4297184, 11.0330626],
                [107.4297084, 11.0331189],
                [107.429681, 11.0332027],
                [107.4296789, 11.0332643],
                [107.4296995, 11.033324],
                [107.4297181, 11.0333507],
                [107.4297686, 11.0333919],
                [107.4298986, 11.0334292],
                [107.4299998, 11.0334467],
                [107.4302133, 11.0334615],
                [107.4304356, 11.0334454],
                [107.4305452, 11.0334253],
                [107.4306073, 11.0334028],
                [107.4306553, 11.033358],
                [107.4306813, 11.0332983],
                [107.4306802, 11.0332293],
                [107.4306684, 11.0331966],
                [107.430625, 11.0331423],
                [107.4305526, 11.0331045],
                [107.4304803, 11.0330463],
                [107.4304338, 11.0329677],
                [107.4304181, 11.0328789],
                [107.4304345, 11.0327902],
                [107.4305807, 11.0323946],
                [107.4306709, 11.0321017],
                [107.4307052, 11.032049],
                [107.4307315, 11.0320309],
                [107.4307925, 11.0320173],
                [107.430823, 11.0320221],
                [107.4308756, 11.0320528],
                [107.4309601, 11.0321814],
                [107.4310675, 11.0324032],
                [107.4311054, 11.0325086],
                [107.4311599, 11.0327256],
                [107.431239, 11.0333607],
                [107.4312539, 11.033411],
                [107.4313036, 11.0335036],
                [107.4313404, 11.0335472],
                [107.4314312, 11.0336163],
                [107.4316426, 11.0337059],
                [107.431789, 11.0337958],
                [107.4318627, 11.033855],
                [107.4319922, 11.0339915],
                [107.4320948, 11.0341486],
                [107.4321571, 11.0342102],
                [107.4321948, 11.0342331],
                [107.4322786, 11.0342606],
                [107.4323688, 11.0342613],
                [107.4324562, 11.0342337],
                [107.4324954, 11.0342099],
                [107.4325596, 11.0341454],
                [107.4326912, 11.033888],
                [107.432819, 11.03368],
                [107.4329907, 11.0334536],
                [107.4330381, 11.0334233],
                [107.4330656, 11.0334161],
                [107.4331221, 11.0334194],
                [107.4331521, 11.0334315],
                [107.4331783, 11.0334504],
                [107.4332133, 11.0335036],
                [107.433283, 11.0339933],
                [107.4332903, 11.0340235],
                [107.4333232, 11.034076],
                [107.4333791, 11.0341119],
                [107.4334137, 11.0341199],
                [107.4334885, 11.0341176],
                [107.4335633, 11.0340949],
                [107.4336264, 11.0340499],
                [107.4336713, 11.0339875],
                [107.4336934, 11.0339143],
                [107.4337029, 11.0335439],
                [107.4336773, 11.0334826],
                [107.4336522, 11.0334562],
                [107.4336211, 11.0334369],
                [107.43354, 11.0334065],
                [107.4334979, 11.0333797],
                [107.4334301, 11.0333075],
                [107.4333904, 11.0332169],
                [107.4333835, 11.0331181],
                [107.4334105, 11.0330227],
                [107.4334359, 11.0329797],
                [107.4335673, 11.0328613],
                [107.4336681, 11.0327996],
                [107.4337814, 11.0327647],
                [107.4338999, 11.0327586],
                [107.4339658, 11.032768],
                [107.4340296, 11.0327864],
                [107.4340902, 11.0328135],
                [107.4341462, 11.0328486],
                [107.4341967, 11.0328911],
                [107.4342984, 11.0330192],
                [107.4344015, 11.0331851],
                [107.4344567, 11.0332926],
                [107.4345735, 11.0335695],
                [107.4346076, 11.033618],
                [107.434683, 11.0336908],
                [107.4347609, 11.0337379],
                [107.4347935, 11.0337467],
                [107.4348606, 11.033744],
                [107.4348924, 11.0337327],
                [107.4349485, 11.0336888],
                [107.4349679, 11.0336589],
                [107.4349849, 11.0335905],
                [107.4350153, 11.0331141],
                [107.4350532, 11.0329756],
                [107.4350855, 11.0329047],
                [107.4352491, 11.0326217],
                [107.4353109, 11.0325704],
                [107.4353886, 11.032548],
                [107.4354626, 11.0325561],
                [107.4355282, 11.0325906],
                [107.4357802, 11.0328376],
                [107.4358886, 11.0329721],
                [107.4359317, 11.0330469],
                [107.4359926, 11.0332037],
                [107.4360522, 11.0334987],
                [107.436066, 11.033535],
                [107.4360863, 11.0335683],
                [107.4361435, 11.0336212],
                [107.4362195, 11.0336512],
                [107.4363048, 11.0336506],
                [107.4363832, 11.0336177],
                [107.4364704, 11.0335537],
                [107.4365889, 11.0334957],
                [107.4367022, 11.0334629],
                [107.4368075, 11.0334433],
                [107.4368937, 11.033402],
                [107.4369308, 11.033372],
                [107.4369845, 11.0333043],
                [107.4370639, 11.0331005],
                [107.4371347, 11.0329876],
                [107.4371789, 11.0329358],
                [107.4372818, 11.0328463],
                [107.4374007, 11.0327784],
                [107.4376872, 11.0326849],
                [107.4377218, 11.0326632],
                [107.4377755, 11.0326027],
                [107.4377927, 11.032566],
                [107.4378048, 11.0324892],
                [107.4377888, 11.0324157],
                [107.4377712, 11.0323822],
                [107.4377194, 11.0323268],
                [107.4375181, 11.0322282],
                [107.4374136, 11.0321873],
                [107.4372289, 11.0321303],
                [107.4370846, 11.0320985],
                [107.4370368, 11.0320808],
                [107.4369925, 11.0320557],
                [107.4369201, 11.0319871],
                [107.4368523, 11.0318634],
                [107.4367887, 11.0316924],
                [107.4367557, 11.0315475],
                [107.4367396, 11.0314395],
                [107.4367465, 11.0313703],
                [107.4367779, 11.031308],
                [107.4368352, 11.0312576],
                [107.4368702, 11.0312416],
                [107.4369077, 11.0312326],
                [107.4369845, 11.0312369],
                [107.4371056, 11.0312676],
                [107.4373439, 11.0313422],
                [107.4376247, 11.0314562],
                [107.4377608, 11.0315226],
                [107.4379478, 11.0316303],
                [107.4380435, 11.0317163],
                [107.4381225, 11.031825],
                [107.4381778, 11.0319534],
                [107.4381942, 11.0320214],
                [107.4382195, 11.0321968],
                [107.4382505, 11.0322584],
                [107.4382819, 11.0322966],
                [107.4383631, 11.0323532],
                [107.4384646, 11.0323924],
                [107.4385622, 11.0324399],
                [107.4387336, 11.0325505],
                [107.4388073, 11.0326113],
                [107.4389687, 11.0327803],
                [107.4390031, 11.032807],
                [107.4390833, 11.0328404],
                [107.4391705, 11.0328441],
                [107.4392503, 11.0328192],
                [107.4392858, 11.0327971],
                [107.4393428, 11.0327368],
                [107.4393625, 11.0327004],
                [107.4393757, 11.0326612],
                [107.4393913, 11.0325656],
                [107.4393985, 11.032469],
                [107.4393918, 11.0323097],
                [107.4393779, 11.0322341],
                [107.4393453, 11.0321581],
                [107.4393208, 11.032121],
                [107.439259, 11.032057],
                [107.4391862, 11.0319987],
                [107.4390259, 11.0319029],
                [107.4389047, 11.0318543],
                [107.4388446, 11.031812],
                [107.4388052, 11.0317506],
                [107.4387952, 11.0317155],
                [107.4387966, 11.0316428],
                [107.438826, 11.0315759],
                [107.4388791, 11.031525],
                [107.4389121, 11.031508],
                [107.4391423, 11.0314415],
                [107.4392455, 11.0313899],
                [107.4392925, 11.0313567],
                [107.4393876, 11.0312623],
                [107.4394575, 11.0311487],
                [107.439525, 11.0310898],
                [107.4396106, 11.0310612],
                [107.439656, 11.0310599],
                [107.4397378, 11.031081],
                [107.4398063, 11.03113],
                [107.4398434, 11.0311808],
                [107.4398587, 11.0312286],
                [107.4398826, 11.0315578],
                [107.4398931, 11.0316079],
                [107.439933, 11.0317023],
                [107.4399974, 11.0317855],
                [107.4400834, 11.0318508],
                [107.4401838, 11.0318918],
                [107.4402916, 11.0319056],
                [107.4404248, 11.0318892],
                [107.4404886, 11.0318685],
                [107.4406038, 11.0318047],
                [107.4406976, 11.0317158],
                [107.4407658, 11.0316067],
                [107.4407888, 11.0315467],
                [107.4408039, 11.0314844],
                [107.4408619, 11.0310666],
                [107.4408807, 11.0310312],
                [107.4409074, 11.0310011],
                [107.4409405, 11.0309781],
                [107.4409783, 11.0309634],
                [107.4410185, 11.0309578],
                [107.4410583, 11.0309616],
                [107.4410961, 11.0309744],
                [107.4411299, 11.0309954],
                [107.4411578, 11.0310236],
                [107.4411783, 11.0310573],
                [107.4411902, 11.0310947],
                [107.4412036, 11.0312869],
                [107.4412563, 11.0313651],
                [107.441291, 11.0313974],
                [107.4413734, 11.0314449],
                [107.4414664, 11.031466],
                [107.4415172, 11.0314657],
                [107.4416152, 11.0314415],
                [107.44166, 11.0314181],
                [107.4417354, 11.031352],
                [107.4417856, 11.0312659],
                [107.4419439, 11.0308789],
                [107.4420215, 11.0307587],
                [107.4421268, 11.0306607],
                [107.4421878, 11.0306221],
                [107.4423221, 11.0305682],
                [107.442459, 11.0305484],
                [107.4425283, 11.0305505],
                [107.4426637, 11.0305784],
                [107.4427888, 11.0306367],
                [107.443169, 11.0308881],
                [107.443258, 11.0309213],
                [107.4433527, 11.0309315],
                [107.4434072, 11.0309267],
                [107.4435112, 11.0308944],
                [107.4436015, 11.0308341],
                [107.4443969, 11.030105],
                [107.4444315, 11.0300475],
                [107.4444397, 11.0299811],
                [107.444416, 11.0299105],
                [107.4443925, 11.0298811],
                [107.4443284, 11.0298416],
                [107.4441086, 11.029836],
                [107.4439996, 11.0298211],
                [107.4437866, 11.0297679],
                [107.4435802, 11.0296824],
                [107.4434825, 11.0296283],
                [107.4433011, 11.0294994],
                [107.4432302, 11.0294211],
                [107.443203, 11.029376],
                [107.443167, 11.0292774],
                [107.4431589, 11.0292256],
                [107.4431631, 11.0291206],
                [107.4431754, 11.0290694],
                [107.4432192, 11.0289737],
                [107.4432501, 11.0289307],
                [107.4433909, 11.0288461],
                [107.4435452, 11.0287885],
                [107.4436351, 11.0287689],
                [107.4437267, 11.0287585],
                [107.4438189, 11.0287574],
                [107.4441159, 11.0287974],
                [107.4442315, 11.0288027],
                [107.4444118, 11.0287961],
                [107.4445378, 11.0287673],
                [107.4446529, 11.0287096],
                [107.4447517, 11.028625],
                [107.4448266, 11.0285193],
                [107.4448535, 11.0284605],
                [107.445066, 11.027846],
                [107.4451542, 11.0277328],
                [107.4452062, 11.0276828],
                [107.4453235, 11.0275986],
                [107.4454706, 11.0275323],
                [107.4455488, 11.027511],
                [107.4457097, 11.0274933],
                [107.4457654, 11.0274991],
                [107.4458712, 11.0275344],
                [107.4459189, 11.0275632],
                [107.4459974, 11.0276378],
                [107.44605, 11.027729],
                [107.4460659, 11.0277791],
                [107.4460756, 11.0278834],
                [107.4460691, 11.0279356],
                [107.4459511, 11.0282251],
                [107.4459482, 11.0283334],
                [107.4459603, 11.0283864],
                [107.44601, 11.028483],
                [107.4460879, 11.0285578],
                [107.4461849, 11.0286039],
                [107.4462378, 11.0286149],
                [107.446415, 11.0286198],
                [107.4466609, 11.0286027],
                [107.4467826, 11.0285832],
                [107.4470275, 11.0285208],
                [107.4471465, 11.0284785],
                [107.4474354, 11.0283437],
                [107.4474987, 11.0283226],
                [107.4476011, 11.0283061],
                [107.4478194, 11.0283108],
                [107.4478805, 11.0282912],
                [107.447928, 11.0282488],
                [107.447944, 11.0282212],
                [107.4479567, 11.0281606],
                [107.4479434, 11.0281002],
                [107.4479276, 11.0280732],
                [107.4479065, 11.02805],
                [107.447826, 11.0279905],
                [107.4477284, 11.0278947],
                [107.4476491, 11.0277822],
                [107.4476172, 11.0277206],
                [107.4475913, 11.0276563],
                [107.4475303, 11.0274223],
                [107.4474946, 11.027325],
                [107.4474509, 11.0272308],
                [107.4473383, 11.0270484],
                [107.4473166, 11.0269977],
                [107.4472922, 11.0268904],
                [107.4472949, 11.0267772],
                [107.4473317, 11.0267072],
                [107.4473593, 11.0266784],
                [107.4474304, 11.0266374],
                [107.4474706, 11.0266276],
                [107.4475531, 11.0266311],
                [107.447842, 11.0266794],
                [107.4479136, 11.0266781],
                [107.4479481, 11.0266681],
                [107.4480092, 11.0266304],
                [107.4480337, 11.026604],
                [107.448066, 11.0265402],
                [107.4481331, 11.0263454],
                [107.4481758, 11.0262872],
                [107.4482048, 11.0262651],
                [107.4482667, 11.0262401],
                [107.4483, 11.0262359],
                [107.4486025, 11.0262519],
                [107.4486963, 11.0262426],
                [107.4487836, 11.0262073],
                [107.4488224, 11.0261809],
                [107.4488878, 11.0261111],
                [107.4489119, 11.02607],
                [107.4489296, 11.0260257],
                [107.4489711, 11.0258475],
                [107.4490464, 11.0255872],
                [107.4491501, 11.0253078],
                [107.4492689, 11.0250362],
                [107.4492967, 11.024892],
                [107.4492985, 11.0248184],
                [107.4492763, 11.0246678],
                [107.4492524, 11.0245953],
                [107.4492203, 11.0245258],
                [107.4491423, 11.0243902],
                [107.44911, 11.0243172],
                [107.4490624, 11.024156],
                [107.4490435, 11.0239802],
                [107.4490464, 11.0238917],
                [107.4490651, 11.0237447],
                [107.4490625, 11.0236706],
                [107.4490375, 11.0235393],
                [107.4489694, 11.0233705],
                [107.4489593, 11.0233236],
                [107.448963, 11.023228],
                [107.4489768, 11.023182],
                [107.4490248, 11.0231014],
                [107.4490943, 11.0230413],
                [107.4491794, 11.0230051],
                [107.4492717, 11.0229966],
                [107.4494661, 11.0230216],
                [107.449569, 11.0230459],
                [107.4496698, 11.0230775],
                [107.4498419, 11.0231495],
                [107.4499986, 11.0231914],
                [107.4500726, 11.0231995],
                [107.4502212, 11.0231941],
                [107.4503007, 11.0231674],
                [107.4503658, 11.0231154],
                [107.4504085, 11.0230444],
                [107.4504197, 11.0230045],
                [107.4504194, 11.0229196],
                [107.4503882, 11.0228403],
                [107.4503303, 11.0227772],
                [107.4502157, 11.0226954],
                [107.4501413, 11.0226321],
                [107.4500039, 11.0224937],
                [107.4499303, 11.0224048],
                [107.4498019, 11.0222133],
                [107.4496626, 11.0219286],
                [107.4496177, 11.021854],
                [107.4495123, 11.0217212],
                [107.4494514, 11.0216618],
                [107.4492583, 11.0214433],
                [107.4492068, 11.0213676],
                [107.4491792, 11.0212807],
                [107.4491788, 11.0211823],
                [107.4492126, 11.0210818],
                [107.4492781, 11.0209977],
                [107.4493681, 11.0209394],
                [107.4499717, 11.0207272],
                [107.4503995, 11.020585],
                [107.4504741, 11.0205697],
                [107.4505604, 11.0205757],
                [107.4506069, 11.0205898],
                [107.4506503, 11.0206113],
                [107.4509253, 11.0207983],
                [107.450976, 11.0208256],
                [107.4510306, 11.0208446],
                [107.4510875, 11.0208549],
                [107.4511468, 11.020856],
                [107.4512054, 11.0208476],
                [107.4512923, 11.02082],
                [107.4513558, 11.0208141],
                [107.4514375, 11.0208346],
                [107.4515157, 11.0208872],
                [107.4516117, 11.0209282],
                [107.451716, 11.0209381],
                [107.4518182, 11.0209157],
                [107.4518654, 11.020893],
                [107.4519454, 11.0208282],
                [107.4520013, 11.0207425],
                [107.4520278, 11.020644],
                [107.452029, 11.0205929],
                [107.4519612, 11.0199428],
                [107.4519599, 11.0198623],
                [107.4519772, 11.0197131],
                [107.452008, 11.0196678],
                [107.452031, 11.0196521],
                [107.4520858, 11.0196395],
                [107.4521142, 11.0196438],
                [107.4521632, 11.0196726],
                [107.4523991, 11.0201032],
                [107.452466, 11.0201736],
                [107.4525516, 11.0202204],
                [107.4525989, 11.0202335],
                [107.4526969, 11.0202375],
                [107.4527499, 11.020227],
                [107.4528003, 11.0202077],
                [107.4528466, 11.0201803],
                [107.4528875, 11.0201455],
                [107.4529218, 11.0201044],
                [107.4529484, 11.0200581],
                [107.4529666, 11.0200081],
                [107.4530053, 11.019632],
                [107.4530542, 11.0194903],
                [107.4531312, 11.0193613],
                [107.4531793, 11.0193032],
                [107.4532923, 11.0192028],
                [107.4533623, 11.019158],
                [107.4534369, 11.0191211],
                [107.4535152, 11.0190926],
                [107.4535963, 11.0190728],
                [107.4536791, 11.019062],
                [107.4537626, 11.0190602],
                [107.4538458, 11.0190676],
                [107.4539276, 11.0190841],
                [107.4543542, 11.0192288],
                [107.4544304, 11.0192307],
                [107.4544676, 11.0192215],
                [107.4545339, 11.0191846],
                [107.454561, 11.0191581],
                [107.4546014, 11.0190843],
                [107.4546096, 11.019043],
                [107.4546002, 11.0189598],
                [107.454583, 11.0189213],
                [107.4543759, 11.0186131],
                [107.4543395, 11.0185351],
                [107.4542863, 11.0183719],
                [107.4542606, 11.0182024],
                [107.4542632, 11.0180275],
                [107.4542755, 11.0179408],
                [107.4543216, 11.0177719],
                [107.45438, 11.017667],
                [107.4544182, 11.0176203],
                [107.4545152, 11.0175384],
                [107.4546357, 11.0174803],
                [107.4547007, 11.0174633],
                [107.4548346, 11.0174547],
                [107.4551447, 11.0174708],
                [107.4553601, 11.0174379],
                [107.4555695, 11.0173783],
                [107.4557607, 11.0172973],
                [107.4559403, 11.017194],
                [107.4563326, 11.0169018],
                [107.456439, 11.0168132],
                [107.4564985, 11.0167773],
                [107.456627, 11.0167239],
                [107.4566947, 11.016707],
                [107.4568422, 11.0166937],
                [107.4569162, 11.0166987],
                [107.4570605, 11.0167319],
                [107.4571291, 11.0167597],
                [107.4572579, 11.0168353],
                [107.4573752, 11.01693],
                [107.4574775, 11.0170407],
                [107.4575222, 11.0171013],
                [107.4576027, 11.017245],
                [107.4576309, 11.0172802],
                [107.4576637, 11.0173112],
                [107.4577408, 11.0173586],
                [107.4577887, 11.0173755],
                [107.4578897, 11.0173868],
                [107.4579403, 11.0173809],
                [107.4580358, 11.0173467],
                [107.4580831, 11.0173377],
                [107.4581793, 11.0173362],
                [107.458234, 11.0173453],
                [107.4583372, 11.0173846],
                [107.4585826, 11.0175616],
                [107.4586484, 11.0175802],
                [107.4587155, 11.0175673],
                [107.4587694, 11.0175257],
                [107.4587874, 11.0174969],
                [107.4588007, 11.0174308],
                [107.4587952, 11.0173974],
                [107.4587613, 11.0173388],
                [107.4585414, 11.0171124],
                [107.4585237, 11.0170572],
                [107.4585253, 11.0170282],
                [107.4585454, 11.0169797],
                [107.4585628, 11.0169597],
                [107.4587882, 11.0167965],
                [107.4588874, 11.0167675],
                [107.4591238, 11.0167646],
                [107.4592252, 11.0167497],
                [107.4593246, 11.0167254],
                [107.4594981, 11.0166579],
                [107.4597211, 11.0165248],
                [107.4597885, 11.0164938],
                [107.4599308, 11.0164516],
                [107.4600929, 11.0164372],
                [107.4601742, 11.0164428],
                [107.4603328, 11.0164791],
                [107.4604082, 11.0165095],
                [107.4604765, 11.0165508],
                [107.4605158, 11.0165599],
                [107.4605957, 11.0165532],
                [107.4606328, 11.0165377],
                [107.460694, 11.0164842],
                [107.4607147, 11.0164483],
                [107.4607269, 11.0164088],
                [107.46073, 11.0163677],
                [107.4607238, 11.0163268],
                [107.4607086, 11.0162883],
                [107.4606281, 11.0161146],
                [107.4606281, 11.0160648],
                [107.4606363, 11.0160411],
                [107.4606722, 11.0159981],
                [107.4607307, 11.0159762],
                [107.4615616, 11.0159658],
                [107.4616563, 11.0159494],
                [107.4617439, 11.0159105],
                [107.4618215, 11.0158494],
                [107.4618807, 11.015771],
                [107.4624494, 11.0148324],
                [107.4625006, 11.0147786],
                [107.4625682, 11.0147466],
                [107.4626431, 11.0147408],
                [107.46268, 11.0147482],
                [107.4627466, 11.014782],
                [107.462774, 11.0148073],
                [107.4628125, 11.0148704],
                [107.4628265, 11.0149553],
                [107.4628195, 11.0150536],
                [107.4627864, 11.0151613],
                [107.4627282, 11.0152583],
                [107.4626539, 11.0153498],
                [107.4626033, 11.0154602],
                [107.4625866, 11.0155802],
                [107.4626034, 11.0156939],
                [107.4626474, 11.0157935],
                [107.4627158, 11.015879],
                [107.462908, 11.0160409],
                [107.4629555, 11.0160655],
                [107.4630059, 11.0160835],
                [107.4631119, 11.0160988],
                [107.4631722, 11.0160948],
                [107.4632313, 11.0160818],
                [107.4632876, 11.0160602],
                [107.4636167, 11.0158934],
                [107.4637224, 11.0158692],
                [107.4638307, 11.0158776],
                [107.4638849, 11.0158949],
                [107.4639355, 11.0159204],
                [107.4640211, 11.0159935],
                [107.4640648, 11.0160153],
                [107.4641115, 11.0160298],
                [107.4642089, 11.0160356],
                [107.4642932, 11.0160145],
                [107.4643321, 11.0159951],
                [107.4643678, 11.0159704],
                [107.4644345, 11.0159033],
                [107.4645201, 11.0158462],
                [107.4645686, 11.0158279],
                [107.4646708, 11.015814],
                [107.4647723, 11.0158307],
                [107.4648641, 11.0158766],
                [107.4649036, 11.0159092],
                [107.465027, 11.0160514],
                [107.4650497, 11.0160738],
                [107.4650779, 11.0160891],
                [107.4651094, 11.016096],
                [107.4651415, 11.0160939],
                [107.4651718, 11.016083],
                [107.4652116, 11.0160479],
                [107.4652306, 11.0159988],
                [107.4652147, 11.0157249],
                [107.4651925, 11.0155688],
                [107.4651611, 11.0154143],
                [107.4651551, 11.0153354],
                [107.4651587, 11.015227],
                [107.4651852, 11.0151747],
                [107.4652335, 11.0151404],
                [107.4652875, 11.0151318],
                [107.4653401, 11.0151468],
                [107.465381, 11.0151826],
                [107.4655339, 11.0152642],
                [107.4656241, 11.0152641],
                [107.4657133, 11.0152348],
                [107.4657909, 11.015176],
                [107.4658681, 11.0150655],
                [107.4659818, 11.0149351],
                [107.4661066, 11.0148349],
                [107.4662419, 11.0147552],
                [107.4662906, 11.0146965],
                [107.4663059, 11.0146615],
                [107.4663158, 11.0145842],
                [107.4663092, 11.0145444],
                [107.4662735, 11.0144724],
                [107.4662125, 11.0144191],
                [107.4659496, 11.0141295],
                [107.4659245, 11.0140861],
                [107.4659078, 11.014039],
                [107.4659, 11.0139897],
                [107.4659014, 11.0139399],
                [107.4659121, 11.0138904],
                [107.4659318, 11.0138437],
                [107.4659599, 11.0138014],
                [107.4659955, 11.0137647],
                [107.4660372, 11.0137351],
                [107.4660838, 11.0137135],
                [107.4665558, 11.0136345],
                [107.466757, 11.0135924],
                [107.46686, 11.0135552],
                [107.4669689, 11.0135423],
                [107.467027, 11.0135456],
                [107.4671398, 11.013573],
                [107.4673212, 11.0136707],
                [107.4674941, 11.0137408],
                [107.4676824, 11.0137819],
                [107.4678029, 11.0137813],
                [107.4679211, 11.0137582],
                [107.4680537, 11.0137022],
                [107.4681435, 11.0136382],
                [107.4681893, 11.0135766],
                [107.4682136, 11.0135111],
                [107.4682188, 11.0134765],
                [107.4682215, 11.0130684],
                [107.468199, 11.0130168],
                [107.4681544, 11.0129815],
                [107.468098, 11.0129709],
                [107.4680431, 11.0129876],
                [107.4679708, 11.0130316],
                [107.4679185, 11.0130522],
                [107.4678641, 11.0130666],
                [107.4677521, 11.0130763],
                [107.4676348, 11.0130588],
                [107.4675255, 11.0130136],
                [107.4672693, 11.0128209],
                [107.4672071, 11.0127292],
                [107.4671872, 11.0126775],
                [107.467172, 11.0125731],
                [107.4671862, 11.0124734],
                [107.4672109, 11.0124304],
                [107.4672527, 11.0124029],
                [107.4672773, 11.0123971],
                [107.4673051, 11.0123972],
                [107.4673559, 11.0124178],
                [107.46739, 11.0124603],
                [107.4674597, 11.012713],
                [107.4674823, 11.0127468],
                [107.46754, 11.0127978],
                [107.4676073, 11.0128262],
                [107.4676306, 11.0128241],
                [107.4676743, 11.0128078],
                [107.4676973, 11.0127905],
                [107.4677289, 11.0127432],
                [107.467736, 11.0127156],
                [107.467721, 11.0126174],
                [107.4677165, 11.0124227],
                [107.467744, 11.0122338],
                [107.4677752, 11.0121051],
                [107.4678266, 11.0119448],
                [107.4678717, 11.0118889],
                [107.4679021, 11.011869],
                [107.467936, 11.0118556],
                [107.4680084, 11.0118507],
                [107.4680437, 11.0118592],
                [107.4681052, 11.0118964],
                [107.4681464, 11.0119548],
                [107.4681785, 11.0120434],
                [107.4682564, 11.0122154],
                [107.4683955, 11.0124439],
                [107.468483, 11.0125484],
                [107.4685654, 11.0126244],
                [107.4686214, 11.0126409],
                [107.468675, 11.0126258],
                [107.4687112, 11.0125876],
                [107.4687204, 11.0125629],
                [107.4687204, 11.012405],
                [107.4687485, 11.0123431],
                [107.4687983, 11.0122855],
                [107.4688706, 11.0122417],
                [107.4690174, 11.0122089],
                [107.4692944, 11.0121311],
                [107.4693418, 11.0120999],
                [107.4693577, 11.0120763],
                [107.4693684, 11.0120201],
                [107.4693475, 11.0119654],
                [107.4693266, 11.0119442],
                [107.4692049, 11.0118974],
                [107.4691476, 11.0118666],
                [107.4690288, 11.0117794],
                [107.4689189, 11.0116572],
                [107.4686909, 11.0113597],
                [107.4686426, 11.0112176],
                [107.4686319, 11.0110913],
                [107.4686515, 11.0109378],
                [107.4687054, 11.0107633],
                [107.4687925, 11.0105999],
                [107.4688481, 11.0105242],
                [107.468942, 11.0104281],
                [107.4690127, 11.0103882],
                [107.4691037, 11.0103643],
                [107.469764, 11.0103571],
                [107.469822, 11.0103277],
                [107.469862, 11.010277],
                [107.4698764, 11.0102144],
                [107.4698728, 11.0101817],
                [107.4698444, 11.0101224],
                [107.4698208, 11.0100988],
                [107.4697611, 11.0100696],
                [107.4696454, 11.0100578],
                [107.4695358, 11.0100196],
                [107.4694305, 11.0099504],
                [107.4693856, 11.0099064],
                [107.4693489, 11.0098406],
                [107.4693388, 11.0098043],
                [107.4693345, 11.0097431],
                [107.4693546, 11.0097057],
                [107.4693886, 11.0096878],
                [107.4694081, 11.0096865],
                [107.4696001, 11.0097168],
                [107.4697785, 11.0097168],
                [107.4698816, 11.0096969],
                [107.4699756, 11.009651],
                [107.470055, 11.0095812],
                [107.4701124, 11.0094931],
                [107.4702078, 11.0091671],
                [107.4702418, 11.0091059],
                [107.4702653, 11.0090797],
                [107.4703382, 11.0090322],
                [107.4705431, 11.0089854],
                [107.4708474, 11.0088585],
                [107.4709233, 11.0088103],
                [107.4709541, 11.0087775],
                [107.4709788, 11.0087401],
                [107.4710064, 11.008662],
                [107.471012, 11.0083284],
                [107.4710433, 11.0082481],
                [107.4710697, 11.0082136],
                [107.4711369, 11.0081632],
                [107.4712149, 11.0081395],
                [107.4712965, 11.0081434],
                [107.4715185, 11.0082002],
                [107.4715908, 11.0081962],
                [107.4716564, 11.0081672],
                [107.4717068, 11.0081177],
                [107.4718924, 11.0077738],
                [107.4719471, 11.0077159],
                [107.4719861, 11.00769],
                [107.4720745, 11.0076593],
                [107.472113, 11.0076611],
                [107.4721844, 11.0076882],
                [107.4722146, 11.0077126],
                [107.4722558, 11.0077773],
                [107.472285, 11.0079449],
                [107.4723166, 11.0080156],
                [107.4723709, 11.0080713],
                [107.4724404, 11.0081051],
                [107.4724785, 11.0081127],
                [107.4725559, 11.0081082],
                [107.4727786, 11.0080634],
                [107.4729013, 11.0080172],
                [107.4730065, 11.0079397],
                [107.4730835, 11.0078414],
                [107.4731314, 11.0077268],
                [107.4731635, 11.007594],
                [107.4732223, 11.0074568],
                [107.4733043, 11.0073315],
                [107.47345, 11.0071801],
                [107.4735131, 11.0071478],
                [107.4735828, 11.0071432],
                [107.4736953, 11.0071819],
                [107.4737951, 11.0071946],
                [107.4738822, 11.0071835],
                [107.4739641, 11.0071524],
                [107.4740248, 11.0071089],
                [107.4740743, 11.0070449],
                [107.4741706, 11.0067891],
                [107.4742087, 11.0067458],
                [107.4742341, 11.0067314],
                [107.4742621, 11.0067229],
                [107.4743203, 11.0067249],
                [107.4743475, 11.0067352],
                [107.4743918, 11.0067722],
                [107.4744067, 11.006797],
                [107.4744791, 11.0070577],
                [107.4745783, 11.0072551],
                [107.4746388, 11.0073434],
                [107.474707, 11.0074261],
                [107.4747822, 11.0075026],
                [107.4748614, 11.0075616],
                [107.4749541, 11.0075972],
                [107.4750531, 11.0076066],
                [107.4751093, 11.0075999],
                [107.4752152, 11.0075613],
                [107.4752623, 11.0075303],
                [107.4753038, 11.0074924],
                [107.4754107, 11.0073477],
                [107.4755532, 11.0070913],
                [107.4755818, 11.0070551],
                [107.4756539, 11.0069971],
                [107.4756971, 11.0069762],
                [107.4757907, 11.006955],
                [107.4758192, 11.0069602],
                [107.4758699, 11.0069871],
                [107.4759054, 11.0070322],
                [107.4759154, 11.0070594],
                [107.475917, 11.0071168],
                [107.4758231, 11.0074876],
                [107.4758185, 11.0075372],
                [107.4758229, 11.0075869],
                [107.475855, 11.0076763],
                [107.4758819, 11.0077159],
                [107.4759482, 11.0077481],
                [107.4760347, 11.0077646],
                [107.4761362, 11.0077524],
                [107.4762279, 11.007708],
                [107.4765336, 11.0074921],
                [107.4766801, 11.0074158],
                [107.4768454, 11.0073622],
                [107.4770258, 11.0073367],
                [107.4771586, 11.0073578],
                [107.477281, 11.0074125],
                [107.4773356, 11.0074513],
                [107.4775319, 11.0076477],
                [107.4775958, 11.0076895],
                [107.4776322, 11.0077043],
                [107.4777093, 11.0077189],
                [107.477815, 11.0077166],
                [107.4779472, 11.0077264],
                [107.4780354, 11.0077434],
                [107.4781596, 11.0077824],
                [107.4781998, 11.0077884],
                [107.4782798, 11.0077764],
                [107.4783488, 11.0077342],
                [107.4783951, 11.0076685],
                [107.4784702, 11.0074789],
                [107.4785338, 11.0074221],
                [107.4786112, 11.0073902],
                [107.4786517, 11.0073843],
                [107.4787331, 11.007392],
                [107.4788355, 11.007435],
                [107.4789431, 11.0075075],
                [107.4790442, 11.0076158],
                [107.4790973, 11.0076501],
                [107.4791603, 11.0076587],
                [107.4791917, 11.0076527],
                [107.479246, 11.007622],
                [107.4792821, 11.0075718],
                [107.479291, 11.0075421],
                [107.4793046, 11.0072388],
                [107.4793502, 11.0071456],
                [107.479358, 11.0071156],
                [107.4793634, 11.0067759],
                [107.47937, 11.0067439],
                [107.4794011, 11.0066863],
                [107.4794244, 11.0066629],
                [107.4794854, 11.0066302],
                [107.4795576, 11.0066239],
                [107.4796262, 11.0066469],
                [107.4802136, 11.0071103],
                [107.4802984, 11.0071605],
                [107.4803936, 11.0071875],
                [107.4804926, 11.0071893],
                [107.480546, 11.0071795],
                [107.4806459, 11.0071381],
                [107.4807298, 11.0070706],
                [107.4809235, 11.006811],
                [107.4809716, 11.0067785],
                [107.4809998, 11.0067703],
                [107.4810625, 11.0067729],
                [107.4811211, 11.006805],
                [107.4811578, 11.0068602],
                [107.481233, 11.0073826],
                [107.4812655, 11.0074744],
                [107.4813249, 11.0075535],
                [107.4813633, 11.0075858],
                [107.4814063, 11.0076121],
                [107.4815242, 11.0076635],
                [107.4815542, 11.0076886],
                [107.4815977, 11.0077527],
                [107.4816103, 11.0077927],
                [107.4816102, 11.007876],
                [107.481577, 11.0079525],
                [107.4815221, 11.0080123],
                [107.4815012, 11.008045],
                [107.4814823, 11.0081196],
                [107.481485, 11.0081582],
                [107.4815107, 11.008224],
                [107.4815326, 11.0082521],
                [107.4815905, 11.0082937],
                [107.4819263, 11.0083701],
                [107.4820751, 11.008411],
                [107.4823524, 11.0085042],
                [107.4825432, 11.0085796],
                [107.4826009, 11.0085921],
                [107.48266, 11.0085956],
                [107.4827188, 11.00859],
                [107.4828291, 11.008553],
                [107.4829826, 11.0084429],
                [107.4830474, 11.0084089],
                [107.4831161, 11.0083832],
                [107.4831876, 11.0083663],
                [107.4832606, 11.0083583],
                [107.4833252, 11.0083588],
                [107.483452, 11.0083808],
                [107.4835709, 11.0084298],
                [107.4836254, 11.0084636],
                [107.484237, 11.008769],
                [107.4846983, 11.0089007],
                [107.4851754, 11.0090695],
                [107.4852312, 11.0090836],
                [107.485341, 11.0090896],
                [107.4856639, 11.009027],
                [107.4857647, 11.0090424],
                [107.485857, 11.009085],
                [107.4859032, 11.0091205],
                [107.4859429, 11.009163],
                [107.4860394, 11.0093061],
                [107.4860647, 11.0093271],
                [107.4860934, 11.0093431],
                [107.4861574, 11.0093588],
                [107.4862258, 11.00935],
                [107.4862859, 11.0093168],
                [107.4863101, 11.0092924],
                [107.4864793, 11.0090428],
                [107.4865367, 11.0090068],
                [107.4866048, 11.0090033],
                [107.4866658, 11.0090332],
                [107.4866885, 11.0090586],
                [107.4868107, 11.0092597],
                [107.4868645, 11.0093015],
                [107.4869299, 11.0093219],
                [107.4870029, 11.0093172],
                [107.4870687, 11.0092858],
                [107.4872571, 11.009085],
                [107.4873166, 11.0090638],
                [107.4873483, 11.0090613],
                [107.4874081, 11.0090716],
                [107.487461, 11.0091008],
                [107.4880145, 11.0095794],
                [107.4880747, 11.0096191],
                [107.4881396, 11.009651],
                [107.4882738, 11.009689],
                [107.4886482, 11.0097173],
                [107.4886855, 11.0097305],
                [107.4887484, 11.0097774],
                [107.4887868, 11.0098423],
                [107.4887972, 11.0099166],
                [107.4886894, 11.0103435],
                [107.4886802, 11.0104196],
                [107.4886995, 11.0104938],
                [107.4887192, 11.0105271],
                [107.4887752, 11.0105804],
                [107.4888477, 11.0106105],
                [107.4889264, 11.0106129],
                [107.4889647, 11.0106034],
                [107.4892849, 11.0104224],
                [107.4893592, 11.0104048],
                [107.4893975, 11.0104066],
                [107.4894345, 11.0104156],
                [107.4895001, 11.0104532],
                [107.4895263, 11.0104804],
                [107.4896711, 11.0106963],
                [107.4897041, 11.0107265],
                [107.489783, 11.0107692],
                [107.4898267, 11.0107805],
                [107.4899145, 11.0107817],
                [107.4899983, 11.0107557],
                [107.4900359, 11.0107331],
                [107.490159, 11.0107049],
                [107.4902223, 11.0107027],
                [107.490347, 11.0107226],
                [107.4904021, 11.0107424],
                [107.4905029, 11.0108007],
                [107.4905473, 11.0108384],
                [107.4906206, 11.010928],
                [107.4907279, 11.0112755],
                [107.4907769, 11.0113476],
                [107.4908093, 11.0113774],
                [107.4908849, 11.0114202],
                [107.490969, 11.0114388],
                [107.4910614, 11.0114391],
                [107.49111, 11.0114464],
                [107.4911571, 11.0114603],
                [107.4912429, 11.0115072],
                [107.4912798, 11.0115391],
                [107.491338, 11.0116167],
                [107.4913716, 11.0117073],
                [107.4914029, 11.0118426],
                [107.4914188, 11.0118671],
                [107.4914655, 11.0119021],
                [107.4914971, 11.0119114],
                [107.4915623, 11.0119051],
                [107.4915915, 11.0118899],
                [107.4917686, 11.0117441],
                [107.4918449, 11.0117054],
                [107.4918866, 11.0116946],
                [107.4919724, 11.0116915],
                [107.4920223, 11.0117012],
                [107.4921133, 11.0117449],
                [107.4921516, 11.0117777],
                [107.4922085, 11.01186],
                [107.4923587, 11.0122338],
                [107.4924123, 11.0123444],
                [107.4925035, 11.0124708],
                [107.4927624, 11.0127697],
                [107.4927834, 11.0127788],
                [107.4928288, 11.0127781],
                [107.4928665, 11.0127532],
                [107.4928842, 11.0127121],
                [107.4928074, 11.0123433],
                [107.4928156, 11.0122668],
                [107.4928308, 11.0122312],
                [107.4928788, 11.0121729],
                [107.4929454, 11.012136],
                [107.4930212, 11.0121259],
                [107.4932921, 11.0122022],
                [107.4933571, 11.0122397],
                [107.4934047, 11.012297],
                [107.493421, 11.0123336],
                [107.4934309, 11.0124124],
                [107.493374, 11.0126146],
                [107.4933295, 11.0128397],
                [107.4933067, 11.0130791],
                [107.4933162, 11.0131632],
                [107.4933294, 11.0132026],
                [107.4933714, 11.0132743],
                [107.493735, 11.0137549],
                [107.4937801, 11.0137917],
                [107.4938083, 11.0138004],
                [107.4938667, 11.0137953],
                [107.4938929, 11.0137819],
                [107.4939268, 11.0137452],
                [107.4939412, 11.0136977],
                [107.4939669, 11.0133653],
                [107.4939805, 11.0133216],
                [107.4940029, 11.0132817],
                [107.4940332, 11.013247],
                [107.4940701, 11.0132192],
                [107.4941121, 11.0131995],
                [107.4941573, 11.0131886],
                [107.4942038, 11.013187],
                [107.4943844, 11.0132301],
                [107.4945232, 11.0132422],
                [107.4946094, 11.0132379],
                [107.4946947, 11.0132247],
                [107.494862, 11.0131884],
                [107.494947, 11.0131816],
                [107.4949729, 11.0131861],
                [107.4949972, 11.0131962],
                [107.4950355, 11.0132311],
                [107.4950544, 11.0132823],
                [107.4950537, 11.0133114],
                [107.49503, 11.0133642],
                [107.4949747, 11.013418],
                [107.4949206, 11.0134962],
                [107.4948862, 11.0135929],
                [107.494879, 11.0136477],
                [107.4948907, 11.0138609],
                [107.4949056, 11.0138987],
                [107.4949577, 11.013961],
                [107.4950261, 11.013995],
                [107.4950641, 11.0140015],
                [107.4952028, 11.0139943],
                [107.4953051, 11.0140137],
                [107.4953552, 11.0140336],
                [107.4954452, 11.014092],
                [107.4956577, 11.0143021],
                [107.4956867, 11.0143216],
                [107.4957541, 11.0143397],
                [107.4958258, 11.0143266],
                [107.4958587, 11.0143074],
                [107.4959061, 11.0142493],
                [107.495918, 11.0142137],
                [107.4959448, 11.0140189],
                [107.4959802, 11.0139512],
                [107.4960066, 11.0139231],
                [107.4960725, 11.0138832],
                [107.4961099, 11.0138726],
                [107.4961926, 11.0138729],
                [107.4962347, 11.0138857],
                [107.4963067, 11.013935],
                [107.4963527, 11.0140084],
                [107.4964035, 11.0143559],
                [107.4964296, 11.0144246],
                [107.4964759, 11.0144822],
                [107.4965104, 11.0145084],
                [107.4965491, 11.014528],
                [107.4966341, 11.0145454],
                [107.4966949, 11.0145315],
                [107.4967207, 11.0145138],
                [107.4967411, 11.0144903],
                [107.4967608, 11.0144347],
                [107.4967526, 11.0143788],
                [107.4967388, 11.0143538],
                [107.4966311, 11.0142633],
                [107.4965902, 11.014191],
                [107.4965805, 11.0141505],
                [107.4965854, 11.0140629],
                [107.4966007, 11.0140214],
                [107.496654, 11.0139509],
                [107.4966901, 11.0139246],
                [107.4967649, 11.0138908],
                [107.4968382, 11.0138774],
                [107.4969161, 11.0138881],
                [107.4969896, 11.0139256],
                [107.4970204, 11.0139531],
                [107.4970454, 11.0140047],
                [107.4970566, 11.0141571],
                [107.4970821, 11.0142185],
                [107.4971324, 11.0142628],
                [107.4971639, 11.0142756],
                [107.4972284, 11.0142795],
                [107.4972887, 11.0142566],
                [107.4973336, 11.0142109],
                [107.4974307, 11.0139452],
                [107.4974668, 11.0138865],
                [107.4975273, 11.0138525],
                [107.497592, 11.0138506],
                [107.4976464, 11.0138746],
                [107.4976855, 11.0139188],
                [107.4977463, 11.0140728],
                [107.4977779, 11.0142297],
                [107.4977823, 11.0143843],
                [107.4977607, 11.0145375],
                [107.4977186, 11.0150335],
                [107.4977308, 11.015103],
                [107.4977691, 11.0151628],
                [107.4978277, 11.0152036],
                [107.4978997, 11.0152195],
                [107.4979722, 11.0152064],
                [107.4980337, 11.0151665],
                [107.4980745, 11.0151062],
                [107.498092, 11.0150208],
                [107.4980932, 11.0149772],
                [107.4980741, 11.0148698],
                [107.4980399, 11.0147852],
                [107.4980366, 11.0147128],
                [107.4980466, 11.0146778],
                [107.4980886, 11.0146163],
                [107.4981546, 11.0145772],
                [107.4981925, 11.0145691],
                [107.4983107, 11.0145834],
                [107.498418, 11.0146304],
                [107.4984646, 11.0146645],
                [107.4985404, 11.0147506],
                [107.4986086, 11.0148878],
                [107.498642, 11.0149778],
                [107.4986678, 11.0150701],
                [107.4986881, 11.0152103],
                [107.4987181, 11.0152974],
                [107.4987792, 11.015368],
                [107.498863, 11.0154116],
                [107.498919, 11.015407],
                [107.4989677, 11.0153797],
                [107.4990015, 11.0153313],
                [107.4990093, 11.0152696],
                [107.4990015, 11.0152392],
                [107.4987085, 11.0146475],
                [107.4987082, 11.0145908],
                [107.4987328, 11.0145395],
                [107.4987811, 11.0145019],
                [107.4988126, 11.0144921],
                [107.498878, 11.0144969],
                [107.4989328, 11.0145323],
                [107.499281, 11.0149577],
                [107.4993189, 11.0149838],
                [107.4993614, 11.0150019],
                [107.4994068, 11.0150113],
                [107.4994955, 11.0150034],
                [107.4995718, 11.0149648],
                [107.4996031, 11.0149357],
                [107.4996465, 11.0148629],
                [107.499677, 11.014714],
                [107.4997282, 11.0146165],
                [107.4998027, 11.0145406],
                [107.4998917, 11.0144899],
                [107.4999913, 11.0144646],
                [107.5000942, 11.0144665],
                [107.5002712, 11.014502],
                [107.5003281, 11.0145226],
                [107.5003809, 11.0145518],
                [107.5004285, 11.0145886],
                [107.5004988, 11.014674],
                [107.500522, 11.0147191],
                [107.5005493, 11.0148162],
                [107.5005509, 11.0149247],
                [107.5005426, 11.0149823],
                [107.500528, 11.0150386],
                [107.500459, 11.0152066],
                [107.5004438, 11.0152664],
                [107.5004321, 11.0153846],
                [107.5004429, 11.0154985],
                [107.5004595, 11.0155559],
                [107.500501, 11.0155995],
                [107.5005582, 11.0156196],
                [107.5006237, 11.0156098],
                [107.5006523, 11.0155929],
                [107.500692, 11.0155408],
                [107.5007004, 11.015509],
                [107.5007218, 11.0152563],
                [107.5007533, 11.0151914],
                [107.5007769, 11.0151638],
                [107.5008355, 11.0151226],
                [107.5009035, 11.0151026],
                [107.50121, 11.0151089],
                [107.5012853, 11.015101],
                [107.5013545, 11.0150726],
                [107.501412, 11.0150268],
                [107.5014353, 11.0149983],
                [107.5014956, 11.0149575],
                [107.5016153, 11.0149111],
                [107.5016501, 11.0148902],
                [107.5017062, 11.0148324],
                [107.5017266, 11.0147955],
                [107.5017457, 11.0147138],
                [107.5017422, 11.0146293],
                [107.5017648, 11.0145475],
                [107.5018183, 11.0144809],
                [107.5018939, 11.0144401],
                [107.5019382, 11.0144315],
                [107.5019834, 11.014432],
                [107.5020275, 11.0144417],
                [107.5020685, 11.0144602],
                [107.5021048, 11.0144867],
                [107.5021346, 11.01452],
                [107.5021568, 11.0145586],
                [107.5022292, 11.0147561],
                [107.5022564, 11.0147977],
                [107.5023271, 11.014868],
                [107.5023691, 11.0148951],
                [107.5024626, 11.0149311],
                [107.5025242, 11.0149232],
                [107.5025516, 11.0149083],
                [107.5025913, 11.0148614],
                [107.5026032, 11.0147989],
                [107.5025965, 11.0147676],
                [107.5025207, 11.0146257],
                [107.5025028, 11.0145639],
                [107.5024932, 11.0144604],
                [107.5024974, 11.0144085],
                [107.5025239, 11.014339],
                [107.5025481, 11.0143102],
                [107.5026128, 11.0142716],
                [107.5026841, 11.0142637],
                [107.5027194, 11.0142709],
                [107.5027817, 11.0143059],
                [107.5028403, 11.0143765],
                [107.5028698, 11.0144242],
                [107.502938, 11.0145861],
                [107.5029699, 11.0146419],
                [107.5030526, 11.0147403],
                [107.5031454, 11.01481],
                [107.5031969, 11.0148372],
                [107.5033371, 11.0148879],
                [107.503514, 11.0149298],
                [107.5035936, 11.0149334],
                [107.5036709, 11.014914],
                [107.5037406, 11.0148719],
                [107.5037571, 11.0148422],
                [107.5037713, 11.0147783],
                [107.503761, 11.0147153],
                [107.5036352, 11.0144142],
                [107.5036203, 11.0143266],
                [107.5036297, 11.0142304],
                [107.5036457, 11.0141804],
                [107.5036991, 11.01409],
                [107.5037877, 11.0140408],
                [107.5038874, 11.0140128],
                [107.50394, 11.0140077],
                [107.5040451, 11.0140162],
                [107.5040799, 11.0140366],
                [107.504137, 11.0140926],
                [107.5041729, 11.0141656],
                [107.5041819, 11.0142479],
                [107.5041741, 11.0142844],
                [107.5041765, 11.0143585],
                [107.5042115, 11.0144436],
                [107.5042409, 11.0144796],
                [107.5042977, 11.0145096],
                [107.5043616, 11.0145191],
                [107.5044399, 11.0145006],
                [107.5044943, 11.0144628],
                [107.5045171, 11.0144164],
                [107.504518, 11.0143897],
                [107.5044972, 11.0143408],
                [107.5044772, 11.0143226],
                [107.5043899, 11.0142872],
                [107.5043313, 11.0142323],
                [107.5043106, 11.0141979],
                [107.5042898, 11.0141164],
                [107.5042918, 11.0140742],
                [107.5043277, 11.014006],
                [107.5043562, 11.0139794],
                [107.5044211, 11.0139489],
                [107.504493, 11.0139463],
                [107.5047049, 11.014011],
                [107.5048062, 11.0140288],
                [107.5049087, 11.0140373],
                [107.5051003, 11.0140201],
                [107.5051425, 11.0139905],
                [107.5051571, 11.0139692],
                [107.5051693, 11.0139198],
                [107.5051571, 11.0138704],
                [107.5051426, 11.0138492],
                [107.5050674, 11.0137811],
                [107.5050294, 11.0137161],
                [107.5050169, 11.0136733],
                [107.5050124, 11.0136289],
                [107.5050361, 11.0133881],
                [107.505064, 11.0133457],
                [107.5050858, 11.0133317],
                [107.5051361, 11.0133236],
                [107.505161, 11.0133298],
                [107.5052789, 11.013416],
                [107.5053495, 11.0134503],
                [107.5054266, 11.0134524],
                [107.505535, 11.01341],
                [107.5055755, 11.0134021],
                [107.5056584, 11.0134044],
                [107.5056987, 11.0134148],
                [107.5060737, 11.0136191],
                [107.5061916, 11.0136484],
                [107.5063132, 11.0136436],
                [107.5063722, 11.0136284],
                [107.5064283, 11.013605],
                [107.5065169, 11.0135484],
                [107.5065766, 11.0134832],
                [107.5066162, 11.0133982],
                [107.5066271, 11.0132994],
                [107.5065907, 11.013132],
                [107.5065905, 11.0130711],
                [107.5065995, 11.0130124],
                [107.5066176, 11.0129558],
                [107.5066441, 11.0129026],
                [107.5067146, 11.0128306],
                [107.506807, 11.0127787],
                [107.5068604, 11.0127626],
                [107.5070429, 11.0127318],
                [107.5071612, 11.0126801],
                [107.5072148, 11.0126442],
                [107.5073065, 11.0125561],
                [107.5073741, 11.0124505],
                [107.507415, 11.0123324],
                [107.507441, 11.0120323],
                [107.50745, 11.0120003],
                [107.5074874, 11.0119414],
                [107.5075863, 11.0118734],
                [107.5076197, 11.0118415],
                [107.5076668, 11.0117674],
                [107.5076875, 11.0116862],
                [107.5077026, 11.0114615],
                [107.5077358, 11.0113808],
                [107.5077837, 11.011321],
                [107.5078132, 11.0112962],
                [107.5079609, 11.0112014],
                [107.5080363, 11.0111501],
                [107.5080844, 11.0110857],
                [107.5081035, 11.0110071],
                [107.5080895, 11.0109267],
                [107.5080704, 11.0108902],
                [107.5079598, 11.0107516],
                [107.507894, 11.0106331],
                [107.5078649, 11.0105592],
                [107.5078431, 11.010483],
                [107.507838, 11.0103019],
                [107.5078163, 11.0102329],
                [107.5077645, 11.0101756],
                [107.5076548, 11.010116],
                [107.5075936, 11.0100565],
                [107.5075464, 11.0099682],
                [107.5075346, 11.0099196],
                [107.5075406, 11.0098762],
                [107.5075775, 11.009797],
                [107.507607, 11.0097642],
                [107.5076722, 11.0097227],
                [107.5077478, 11.0097053],
                [107.5082366, 11.0097081],
                [107.5082889, 11.0096858],
                [107.5083091, 11.0096658],
                [107.5083312, 11.0096142],
                [107.5083314, 11.0095835],
                [107.5083068, 11.0095278],
                [107.5082561, 11.0094931],
                [107.5081315, 11.0094746],
                [107.5080713, 11.009456],
                [107.5079584, 11.0094009],
                [107.5078968, 11.0093569],
                [107.5078413, 11.0093058],
                [107.5077927, 11.0092481],
                [107.5077519, 11.009185],
                [107.5077244, 11.0090747],
                [107.5077192, 11.0090181],
                [107.5077286, 11.0088899],
                [107.5077466, 11.0088202],
                [107.5077733, 11.0087532],
                [107.507821, 11.0087018],
                [107.507886, 11.0086742],
                [107.5079238, 11.0086712],
                [107.5079968, 11.0086901],
                [107.5080281, 11.0087111],
                [107.5082025, 11.0089454],
                [107.5082418, 11.0089827],
                [107.5083339, 11.0090402],
                [107.5084229, 11.009069],
                [107.5084694, 11.0090756],
                [107.5085927, 11.0090752],
                [107.5087443, 11.009056],
                [107.5088079, 11.0090221],
                [107.5088322, 11.0089954],
                [107.5088498, 11.0089641],
                [107.5088613, 11.0088943],
                [107.5088407, 11.0088269],
                [107.5088195, 11.0087983],
                [107.5087604, 11.0087585],
                [107.5086203, 11.008704],
                [107.5085539, 11.008669],
                [107.5084283, 11.0085832],
                [107.5082953, 11.0084541],
                [107.5082705, 11.0083919],
                [107.5082695, 11.0083583],
                [107.5082858, 11.0083034],
                [107.5083244, 11.0082584],
                [107.5083519, 11.008244],
                [107.5084119, 11.0082381],
                [107.5084659, 11.0082612],
                [107.5086418, 11.0084568],
                [107.5086644, 11.0084715],
                [107.5086898, 11.0084804],
                [107.5087435, 11.008479],
                [107.5087913, 11.0084521],
                [107.508809, 11.0084301],
                [107.5088248, 11.0083767],
                [107.5087885, 11.0080991],
                [107.5087899, 11.0075526],
                [107.5088064, 11.0074835],
                [107.5088251, 11.0074529],
                [107.5088793, 11.0074059],
                [107.5089126, 11.0073915],
                [107.508986, 11.0073842],
                [107.509023, 11.0073921],
                [107.5090574, 11.0074076],
                [107.5091125, 11.007458],
                [107.5091306, 11.0074906],
                [107.5092692, 11.00792],
                [107.5092924, 11.0079638],
                [107.5093532, 11.0080424],
                [107.5094013, 11.0080851],
                [107.5094554, 11.0081204],
                [107.5095442, 11.0081615],
                [107.5096102, 11.0081653],
                [107.5096417, 11.0081549],
                [107.5096915, 11.0081139],
                [107.509715, 11.0080555],
                [107.5097047, 11.0079194],
                [107.5096858, 11.0078159],
                [107.5096274, 11.007625],
                [107.5095876, 11.0075333],
                [107.5094909, 11.0073636],
                [107.5093753, 11.0072119],
                [107.509257, 11.0070797],
                [107.5092244, 11.007005],
                [107.5092163, 11.0069608],
                [107.5092221, 11.0068715],
                [107.5092565, 11.0067886],
                [107.5092834, 11.0067522],
                [107.5093634, 11.0066776],
                [107.5094102, 11.0066486],
                [107.5095151, 11.0066093],
                [107.5095716, 11.0066002],
                [107.5096857, 11.0066048],
                [107.5097573, 11.0066514],
                [107.509785, 11.0066838],
                [107.509819, 11.0067593],
                [107.5098225, 11.0068418],
                [107.5097769, 11.0070866],
                [107.509776, 11.0071269],
                [107.5097835, 11.0071664],
                [107.5098225, 11.0072367],
                [107.5098482, 11.0072612],
                [107.509911, 11.0072947],
                [107.5099908, 11.0073096],
                [107.5100363, 11.0073095],
                [107.5101243, 11.007288],
                [107.5101679, 11.007265],
                [107.5102396, 11.0071985],
                [107.5102602, 11.0071454],
                [107.5102624, 11.0071169],
                [107.5102448, 11.0070495],
                [107.5100371, 11.0068207],
                [107.5100193, 11.0067816],
                [107.5100076, 11.006697],
                [107.5100239, 11.0066252],
                [107.5100628, 11.0065624],
                [107.5101203, 11.0065153],
                [107.5105669, 11.006364],
                [107.5106264, 11.0063114],
                [107.5106475, 11.0062778],
                [107.5106684, 11.0062019],
                [107.5106586, 11.0061233],
                [107.5106423, 11.0060864],
                [107.5105896, 11.0060255],
                [107.5105172, 11.0059887],
                [107.5102973, 11.0059677],
                [107.5102661, 11.0059561],
                [107.5102117, 11.0059187],
                [107.5101713, 11.005863],
                [107.5101525, 11.0057939],
                [107.5101659, 11.0055517],
                [107.5101742, 11.005508],
                [107.5102128, 11.0054279],
                [107.510272, 11.0053684],
                [107.510308, 11.0053463],
                [107.5103559, 11.0053492],
                [107.5103991, 11.005378],
                [107.5104139, 11.005402],
                [107.5104207, 11.0054292],
                [107.5104192, 11.0056903],
                [107.5104307, 11.0057176],
                [107.5104501, 11.0057401],
                [107.5104757, 11.0057557],
                [107.5105051, 11.0057629],
                [107.5105354, 11.0057609],
                [107.5105636, 11.0057498],
                [107.5106598, 11.0056798],
                [107.5107372, 11.0056359],
                [107.5108886, 11.0055749],
                [107.5109678, 11.0055543],
                [107.511318, 11.0054892],
                [107.5114436, 11.0054468],
                [107.5115016, 11.0054147],
                [107.5115991, 11.0053364],
                [107.5116404, 11.0052895],
                [107.5117271, 11.0051657],
                [107.5118414, 11.005047],
                [107.5119015, 11.0050004],
                [107.5120696, 11.0048934],
                [107.5121587, 11.0048092],
                [107.5122309, 11.004708],
                [107.512259, 11.0046527],
                [107.5123254, 11.0044467],
                [107.512379, 11.0043706],
                [107.5124142, 11.0043395],
                [107.5124993, 11.0042944],
                [107.5125475, 11.0042822],
                [107.5125971, 11.0042779],
                [107.5126952, 11.0042931],
                [107.5127723, 11.0042962],
                [107.5128494, 11.0042927],
                [107.5130045, 11.0042652],
                [107.5130812, 11.0042408],
                [107.5131553, 11.0042096],
                [107.5135696, 11.0039983],
                [107.5136581, 11.0039271],
                [107.5137252, 11.0038361],
                [107.5137493, 11.003785],
                [107.5137756, 11.0036826],
                [107.5137791, 11.0036298],
                [107.5137761, 11.003577],
                [107.5137513, 11.0035145],
                [107.513701, 11.003469],
                [107.5136679, 11.0034554],
                [107.5136325, 11.0034499],
                [107.5135626, 11.0034636],
                [107.5132732, 11.0036824],
                [107.5131833, 11.0037342],
                [107.5131339, 11.0037508],
                [107.5130305, 11.0037639],
                [107.5129697, 11.0037589],
                [107.5129106, 11.0037447],
                [107.5128543, 11.0037216],
                [107.5128025, 11.0036902],
                [107.5125994, 11.0035385],
                [107.5125675, 11.0034907],
                [107.5125602, 11.003434],
                [107.5125664, 11.0034059],
                [107.5126015, 11.0033532],
                [107.5126278, 11.0033349],
                [107.51269, 11.00332],
                [107.5129768, 11.00334],
                [107.513016, 11.0033375],
                [107.5130886, 11.0033092],
                [107.5131424, 11.0032565],
                [107.5131703, 11.0031893],
                [107.5131738, 11.0031531],
                [107.5131701, 11.0031168],
                [107.5131334, 11.0029847],
                [107.5131319, 11.0028842],
                [107.5131404, 11.0028345],
                [107.513159, 11.0027757],
                [107.5131862, 11.0027202],
                [107.5132214, 11.0026692],
                [107.5132638, 11.0026239],
                [107.5134749, 11.002505],
                [107.5136961, 11.0024137],
                [107.5138089, 11.0023785],
                [107.5140185, 11.002331],
                [107.5141141, 11.0023158],
                [107.5141712, 11.0023256],
                [107.5141956, 11.0023415],
                [107.5142267, 11.0023896],
                [107.5142277, 11.0024455],
                [107.514199, 11.0024939],
                [107.5141758, 11.0025107],
                [107.5138017, 11.0026767],
                [107.5137714, 11.002696],
                [107.5137245, 11.0027494],
                [107.513701, 11.0028161],
                [107.5136994, 11.0028549],
                [107.513706, 11.0028932],
                [107.5137422, 11.0029617],
                [107.5138036, 11.0030102],
                [107.5140208, 11.0030702],
                [107.5142004, 11.0031377],
                [107.5143763, 11.0032273],
                [107.5146908, 11.0034138],
                [107.5147282, 11.0034281],
                [107.5147674, 11.0034368],
                [107.5148475, 11.0034362],
                [107.5148866, 11.0034269],
                [107.5149517, 11.003396],
                [107.5149988, 11.0033546],
                [107.5150208, 11.0033061],
                [107.5150194, 11.0032503],
                [107.5149914, 11.0031988],
                [107.5149424, 11.0031656],
                [107.5146586, 11.0031004],
                [107.5146241, 11.0030857],
                [107.5145679, 11.0030373],
                [107.5145486, 11.0030057],
                [107.5145318, 11.0029406],
                [107.5145406, 11.002874],
                [107.5146183, 11.0026634],
                [107.5146429, 11.0026298],
                [107.5147067, 11.0025765],
                [107.5147444, 11.0025581],
                [107.5148329, 11.00254],
                [107.5148782, 11.0025425],
                [107.514964, 11.0025704],
                [107.5151521, 11.0027134],
                [107.515188, 11.0027295],
                [107.5152262, 11.0027394],
                [107.515305, 11.0027397],
                [107.5153854, 11.0027109],
                [107.5154202, 11.0026861],
                [107.5154714, 11.002632],
                [107.5154962, 11.0025739],
                [107.5154916, 11.0025073],
                [107.5154765, 11.002475],
                [107.5154244, 11.0024265],
                [107.5153908, 11.0024132],
                [107.5152031, 11.0024027],
                [107.5151453, 11.0023785],
                [107.5151044, 11.0023317],
                [107.5150931, 11.0023027],
                [107.5150931, 11.0022357],
                [107.5151057, 11.0022043],
                [107.5151521, 11.0021552],
                [107.5158441, 11.0017392],
                [107.5159047, 11.0017098],
                [107.5160344, 11.0016727],
                [107.5161627, 11.0016657],
                [107.5162831, 11.001685],
                [107.5163966, 11.0017287],
                [107.5164756, 11.0017476],
                [107.5165561, 11.0017592],
                [107.5167185, 11.0017603],
                [107.5168082, 11.0017481],
                [107.5168961, 11.001727],
                [107.517373, 11.0015483],
                [107.5174072, 11.0015288],
                [107.5174362, 11.0015025],
                [107.5174724, 11.0014395],
                [107.5174806, 11.0013725],
                [107.5174661, 11.0012929],
                [107.5174649, 11.001246],
                [107.5174715, 11.0011995],
                [107.5175047, 11.0011163],
                [107.5175589, 11.0010498],
                [107.5179259, 11.000771],
                [107.5179584, 11.0007391],
                [107.5180035, 11.0006602],
                [107.5180143, 11.0006158],
                [107.5180172, 11.0005323],
                [107.5180382, 11.0004596],
                [107.5180847, 11.0003969],
                [107.5181508, 11.0003543],
                [107.518171, 11.0003269],
                [107.5181837, 11.0002956],
                [107.5181853, 11.0002316],
                [107.5181753, 11.0002025],
                [107.5181374, 11.0001542],
                [107.5181063, 11.0000989],
                [107.5180819, 11.0000404],
                [107.5180542, 10.9999172],
                [107.5180558, 10.9997917],
                [107.5180864, 10.9996697],
                [107.518112, 10.9996313],
                [107.518183, 10.9995723],
                [107.5182701, 10.9995441],
                [107.5183154, 10.9995427],
                [107.5184029, 10.9995644],
                [107.5184705, 10.9996239],
                [107.5185102, 10.999704],
                [107.5185172, 10.9997436],
                [107.51851, 10.9998235],
                [107.5184561, 10.9999349],
                [107.5184384, 11.000019],
                [107.5184499, 11.0001044],
                [107.5184897, 11.0001813],
                [107.5185531, 11.0002411],
                [107.5186212, 11.0002702],
                [107.5186953, 11.0002753],
                [107.5187811, 11.0002623],
                [107.518865, 11.000284],
                [107.5189278, 11.0003308],
                [107.5189659, 11.0003895],
                [107.5189826, 11.0004577],
                [107.5189826, 11.0004931],
                [107.5189645, 11.0005647],
                [107.5189215, 11.0006291],
                [107.5188264, 11.0006987],
                [107.5187995, 11.000728],
                [107.5187668, 11.0007996],
                [107.5187666, 11.0008797],
                [107.5188011, 11.000954],
                [107.5189129, 11.0010487],
                [107.5189949, 11.0011457],
                [107.5190274, 11.0012002],
                [107.5190735, 11.001318],
                [107.5190867, 11.0013812],
                [107.5190913, 11.0015101],
                [107.5190668, 11.0016368],
                [107.5190035, 11.0017885],
                [107.5189997, 11.0018235],
                [107.5190145, 11.0018919],
                [107.5190599, 11.0019518],
                [107.5191271, 11.0019867],
                [107.5192199, 11.001996],
                [107.5193095, 11.0019709],
                [107.5193775, 11.0019214],
                [107.519404, 11.0018889],
                [107.5194383, 11.0018129],
                [107.5194489, 11.0017583],
                [107.5194504, 11.0017026],
                [107.5194427, 11.0016474],
                [107.5193342, 11.0013562],
                [107.5193397, 11.001303],
                [107.5193694, 11.001255],
                [107.5194202, 11.0012239],
                [107.5194497, 11.0012181],
                [107.5195363, 11.0012201],
                [107.5196475, 11.0012021],
                [107.5197565, 11.0011559],
                [107.5198382, 11.0011024],
                [107.5199118, 11.0010797],
                [107.5199882, 11.0010837],
                [107.5200242, 11.0010954],
                [107.5200874, 11.0011363],
                [107.5201124, 11.001143],
                [107.5201624, 11.0011317],
                [107.520195, 11.0010928],
                [107.520288, 11.0006585],
                [107.5203341, 11.0005649],
                [107.5203675, 11.0005194],
                [107.5204498, 11.0004418],
                [107.5205487, 11.0003859],
                [107.5206828, 11.000349],
                [107.520886, 11.0003235],
                [107.5209367, 11.0002887],
                [107.520955, 11.0002639],
                [107.5209728, 11.0002015],
                [107.5209695, 11.0001668],
                [107.5209373, 11.0001054],
                [107.5208786, 11.0000673],
                [107.5207177, 11.0000173],
                [107.5206571, 10.9999838],
                [107.5206185, 10.999927],
                [107.52061, 10.999863],
                [107.5206285, 10.9998049],
                [107.5206465, 10.99978],
                [107.520943, 10.9995671],
                [107.52102, 10.9994853],
                [107.5210756, 10.9993843],
                [107.5211216, 10.9992068],
                [107.521162, 10.9991551],
                [107.5212193, 10.9991221],
                [107.5212565, 10.9991136],
                [107.5212948, 10.9991133],
                [107.5213669, 10.999137],
                [107.5213972, 10.9991599],
                [107.5214392, 10.9992221],
                [107.521519, 10.9994557],
                [107.5215344, 10.9994815],
                [107.5215787, 10.9995223],
                [107.5216078, 10.9995365],
                [107.5216392, 10.9995445],
                [107.5217038, 10.9995413],
                [107.5220534, 10.9993986],
                [107.5221375, 10.9993448],
                [107.522173, 10.9993099],
                [107.5222278, 10.9992274],
                [107.5222571, 10.9991348],
                [107.5222599, 10.9990378],
                [107.5222355, 10.9988949],
                [107.5222468, 10.9988573],
                [107.5222622, 10.9988442],
                [107.5223003, 10.9988384],
                [107.5223318, 10.998858],
                [107.5224316, 10.9990273],
                [107.5224665, 10.9990653],
                [107.5224898, 10.999077],
                [107.5225416, 10.9990826],
                [107.5225894, 10.9990642],
                [107.5226232, 10.9990263],
                [107.5226324, 10.9990025],
                [107.5226462, 10.9988483],
                [107.5226786, 10.9987851],
                [107.522732, 10.9987377],
                [107.5227983, 10.9987127],
                [107.5228338, 10.9987094],
                [107.5230832, 10.9987576],
                [107.5231133, 10.9987562],
                [107.5231418, 10.9987467],
                [107.5231844, 10.9987088],
                [107.523201, 10.998657],
                [107.5231987, 10.9986297],
                [107.5231704, 10.9985159],
                [107.5231454, 10.9984625],
                [107.5230759, 10.9983673],
                [107.5229333, 10.9982476],
                [107.5228914, 10.9982],
                [107.5228315, 10.9981041],
                [107.5227725, 10.9979388],
                [107.5227242, 10.9978688],
                [107.522511, 10.9976825],
                [107.5224878, 10.9976549],
                [107.5224584, 10.9975897],
                [107.5224544, 10.9975188],
                [107.5224762, 10.9974511],
                [107.5226929, 10.9971504],
                [107.5227366, 10.9971173],
                [107.522791, 10.9971079],
                [107.5228461, 10.9971259],
                [107.5228684, 10.9971444],
                [107.5228956, 10.9971948],
                [107.5229161, 10.9975296],
                [107.5229273, 10.9975628],
                [107.5229686, 10.9976194],
                [107.523029, 10.9976557],
                [107.5230636, 10.9976643],
                [107.5231343, 10.9976608],
                [107.5232031, 10.9976267],
                [107.5232507, 10.9975674],
                [107.5232685, 10.9974939],
                [107.5232649, 10.997456],
                [107.5232531, 10.9974198],
                [107.5231451, 10.9972185],
                [107.5231298, 10.997148],
                [107.523137, 10.9970763],
                [107.5231742, 10.9969982],
                [107.5232031, 10.9969655],
                [107.5234964, 10.9967709],
                [107.5235305, 10.9967362],
                [107.5235831, 10.9966551],
                [107.5236007, 10.9966102],
                [107.5236171, 10.9965155],
                [107.5236076, 10.9964198],
                [107.5235728, 10.9963299],
                [107.5235152, 10.9962522],
                [107.5233597, 10.9960942],
                [107.5233131, 10.995999],
                [107.5233024, 10.9959471],
                [107.5233061, 10.9958493],
                [107.5233359, 10.9957642],
                [107.5233597, 10.9957256],
                [107.5233882, 10.9956988],
                [107.5234578, 10.9956626],
                [107.5235402, 10.9956537],
                [107.5236237, 10.9956779],
                [107.5236601, 10.9957019],
                [107.5238317, 10.9957807],
                [107.5239175, 10.9957877],
                [107.5239995, 10.9957619],
                [107.5240651, 10.9957072],
                [107.5241026, 10.9956377],
                [107.5241111, 10.9955991],
                [107.5241062, 10.9955207],
                [107.524093, 10.9954834],
                [107.5240512, 10.9954255],
                [107.524028, 10.9953663],
                [107.5240362, 10.9953034],
                [107.5240747, 10.995251],
                [107.5241033, 10.9952332],
                [107.5241693, 10.9952214],
                [107.5242327, 10.9952429],
                [107.5246471, 10.9954623],
                [107.5246885, 10.9954988],
                [107.5247586, 10.9955836],
                [107.5248107, 10.995684],
                [107.5248404, 10.9957966],
                [107.5248497, 10.995895],
                [107.5248814, 10.9959695],
                [107.52494, 10.9960263],
                [107.5250163, 10.9960565],
                [107.5250983, 10.9960555],
                [107.5251733, 10.9960239],
                [107.5252046, 10.995998],
                [107.5252491, 10.9959308],
                [107.5252813, 10.9957637],
                [107.5253233, 10.9956839],
                [107.5253908, 10.9956158],
                [107.525481, 10.9955689],
                [107.5255816, 10.9955523],
                [107.5256326, 10.995556],
                [107.5256824, 10.9955676],
                [107.5258267, 10.995621],
                [107.525975, 10.9956429],
                [107.526048, 10.9956426],
                [107.5262739, 10.99561],
                [107.5263564, 10.9956064],
                [107.5264388, 10.9956097],
                [107.5264943, 10.9956223],
                [107.5265187, 10.9956369],
                [107.5265555, 10.9956795],
                [107.5265676, 10.9957109],
                [107.526566, 10.9957775],
                [107.5265314, 10.9958349],
                [107.5263326, 10.9960388],
                [107.5263199, 10.9960667],
                [107.5263141, 10.9961271],
                [107.5263357, 10.9961859],
                [107.5263812, 10.9962298],
                [107.5264211, 10.9962426],
                [107.5265045, 10.9962495],
                [107.5265906, 10.9962295],
                [107.5266655, 10.9961835],
                [107.5267343, 10.996116],
                [107.5268206, 10.9960565],
                [107.5269209, 10.9960148],
                [107.5269757, 10.9960018],
                [107.5271312, 10.9959883],
                [107.5271725, 10.9959745],
                [107.5272434, 10.9959252],
                [107.5272703, 10.9958915],
                [107.527302, 10.9958132],
                [107.527302, 10.9957291],
                [107.5272701, 10.9956509],
                [107.5271469, 10.9954702],
                [107.527122, 10.9953782],
                [107.5271207, 10.9953305],
                [107.5271407, 10.9952374],
                [107.5271614, 10.9951943],
                [107.527222, 10.99512],
                [107.5272607, 10.9950905],
                [107.5273496, 10.9950509],
                [107.5273976, 10.9950419],
                [107.5274466, 10.9950403],
                [107.5275422, 10.9950595],
                [107.527627, 10.9951068],
                [107.5278014, 10.9953043],
                [107.5278486, 10.9953316],
                [107.5279516, 10.9953675],
                [107.5280113, 10.9953758],
                [107.5280716, 10.9953758],
                [107.5281572, 10.9953602],
                [107.5281986, 10.9953271],
                [107.5282112, 10.9953038],
                [107.5282171, 10.995278],
                [107.5282084, 10.995228],
                [107.5281756, 10.9951888],
                [107.5279382, 10.9950831],
                [107.5279138, 10.9950664],
                [107.5278825, 10.9950172],
                [107.5278778, 10.9949884],
                [107.5278895, 10.9949371],
                [107.5279043, 10.9949151],
                [107.5279476, 10.9948844],
                [107.5281102, 10.9948301],
                [107.5281876, 10.9947935],
                [107.5283387, 10.9946977],
                [107.5284076, 10.9946408],
                [107.5284791, 10.9946046],
                [107.5285578, 10.9945881],
                [107.5286091, 10.9945885],
                [107.528708, 10.9946145],
                [107.5288726, 10.9946218],
                [107.5290327, 10.9946005],
                [107.5291835, 10.994553],
                [107.5292551, 10.9945197],
                [107.5295875, 10.99431],
                [107.5296552, 10.9942305],
                [107.5296783, 10.9941838],
                [107.5297004, 10.9940826],
                [107.5296905, 10.9939869],
                [107.5296747, 10.9939412],
                [107.5295502, 10.9937403],
                [107.5295422, 10.9937139],
                [107.5295451, 10.9936591],
                [107.5295558, 10.9936337],
                [107.5295931, 10.9935929],
                [107.5296498, 10.9935716],
                [107.5296804, 10.9935717],
                [107.5297369, 10.9935936],
                [107.5297594, 10.9936139],
                [107.5299663, 10.9938882],
                [107.5299952, 10.9939143],
                [107.5300296, 10.9939329],
                [107.5300675, 10.9939431],
                [107.5301067, 10.9939442],
                [107.5301451, 10.9939361],
                [107.5301805, 10.9939194],
                [107.5302326, 10.9938673],
                [107.530256, 10.993798],
                [107.5302553, 10.9937613],
                [107.5302292, 10.993693],
                [107.5302051, 10.9936649],
                [107.5301751, 10.9936429],
                [107.5300867, 10.9936008],
                [107.5300175, 10.9935321],
                [107.5299753, 10.9934437],
                [107.5299662, 10.9933949],
                [107.5299659, 10.9933454],
                [107.5299892, 10.9933165],
                [107.5300518, 10.9932769],
                [107.5301287, 10.9932676],
                [107.5301668, 10.993276],
                [107.530255, 10.993321],
                [107.5303682, 10.993362],
                [107.5304876, 10.993381],
                [107.5305484, 10.9933819],
                [107.5306687, 10.9933664],
                [107.5307477, 10.9933465],
                [107.5308993, 10.9932874],
                [107.5309477, 10.993256],
                [107.5309743, 10.9932054],
                [107.5309771, 10.9931769],
                [107.5309628, 10.9931256],
                [107.5309263, 10.9930864],
                [107.530902, 10.9930742],
                [107.5306404, 10.9930284],
                [107.5305115, 10.9929963],
                [107.530266, 10.992917],
                [107.5300357, 10.9928188],
                [107.5299779, 10.9927745],
                [107.5299565, 10.9927451],
                [107.5299347, 10.9926884],
                [107.5299311, 10.9926582],
                [107.529942, 10.9926299],
                [107.529982, 10.9925846],
                [107.5300369, 10.9925621],
                [107.530066, 10.9925605],
                [107.5301217, 10.992576],
                [107.5303522, 10.9927385],
                [107.5304296, 10.9927711],
                [107.5305131, 10.9927819],
                [107.5306097, 10.9927661],
                [107.5306503, 10.9927287],
                [107.5306606, 10.9927029],
                [107.5306593, 10.9926555],
                [107.5306344, 10.9926147],
                [107.5304865, 10.9925395],
                [107.5304585, 10.9925155],
                [107.530418, 10.9924555],
                [107.5304017, 10.9923859],
                [107.5304005, 10.9921816],
                [107.5303897, 10.9921105],
                [107.5303711, 10.9920662],
                [107.5303441, 10.9920262],
                [107.530249, 10.9919029],
                [107.5302417, 10.9918742],
                [107.5302502, 10.991817],
                [107.5302856, 10.9917721],
                [107.5303107, 10.9917576],
                [107.5303878, 10.991734],
                [107.5304756, 10.9916813],
                [107.5305325, 10.9916202],
                [107.5305547, 10.9915849],
                [107.5305809, 10.9915175],
                [107.5306198, 10.9914698],
                [107.5306472, 10.9914549],
                [107.5307082, 10.9914477],
                [107.530738, 10.9914556],
                [107.5307869, 10.991492],
                [107.5309288, 10.9917419],
                [107.5309623, 10.9917768],
                [107.5310407, 10.9918338],
                [107.5311283, 10.9918706],
                [107.5311738, 10.9918811],
                [107.531284, 10.9918901],
                [107.5314387, 10.9918785],
                [107.5314839, 10.991848],
                [107.5315071, 10.9917993],
                [107.5315082, 10.9917722],
                [107.531489, 10.9917218],
                [107.5314701, 10.9917021],
                [107.5314465, 10.9916879],
                [107.5313292, 10.9916587],
                [107.5312212, 10.991605],
                [107.5311168, 10.9915179],
                [107.5310737, 10.9914654],
                [107.5310395, 10.9914061],
                [107.5310312, 10.9913729],
                [107.5310292, 10.9913387],
                [107.5310437, 10.9912728],
                [107.5310805, 10.9912166],
                [107.5311059, 10.9911942],
                [107.5311649, 10.9911689],
                [107.5311971, 10.9911653],
                [107.5312668, 10.9911791],
                [107.5312978, 10.9911968],
                [107.531368, 10.9912664],
                [107.5314229, 10.9912839],
                [107.5314834, 10.9912764],
                [107.5315122, 10.991262],
                [107.5315557, 10.9912156],
                [107.5315726, 10.9911547],
                [107.5315654, 10.9910636],
                [107.5315283, 10.9909798],
                [107.5314388, 10.9908745],
                [107.5314101, 10.9907873],
                [107.531409, 10.9907413],
                [107.531427, 10.9906669],
                [107.531468, 10.9906018],
                [107.5314988, 10.9905761],
                [107.5315699, 10.9905386],
                [107.5316607, 10.9905214],
                [107.531991, 10.9905649],
                [107.5320273, 10.9905626],
                [107.5320954, 10.990538],
                [107.5321246, 10.9905166],
                [107.532168, 10.9904596],
                [107.532187, 10.9903826],
                [107.5321718, 10.9903048],
                [107.5321519, 10.99027],
                [107.5319052, 10.9899435],
                [107.5318837, 10.9899032],
                [107.5318649, 10.9898145],
                [107.5318776, 10.9897319],
                [107.5318944, 10.9896934],
                [107.5319588, 10.9896671],
                [107.5319937, 10.9896655],
                [107.5320598, 10.9896858],
                [107.5320876, 10.9897066],
                [107.5323208, 10.9899711],
                [107.532375, 10.9900004],
                [107.5324367, 10.9900056],
                [107.5324671, 10.9899986],
                [107.5325216, 10.9899658],
                [107.5325428, 10.9899407],
                [107.5325659, 10.9898799],
                [107.5325666, 10.9898472],
                [107.5325462, 10.9897856],
                [107.5323737, 10.9895112],
                [107.5323655, 10.9894518],
                [107.5323871, 10.9893916],
                [107.5324386, 10.9893466],
                [107.5324714, 10.9893353],
                [107.532919, 10.9893063],
                [107.53298, 10.9892776],
                [107.5330206, 10.9892244],
                [107.5330317, 10.9891589],
                [107.5330243, 10.9891237],
                [107.533008, 10.9890916],
                [107.5329839, 10.9890645],
                [107.5329536, 10.9890444],
                [107.532919, 10.9890325],
                [107.5326079, 10.9889956],
                [107.5325437, 10.9889686],
                [107.5325178, 10.9889454],
                [107.5324977, 10.988917],
                [107.532479, 10.9888522],
                [107.5324897, 10.9887867],
                [107.5325273, 10.9887316],
                [107.5325543, 10.9887113],
                [107.5328761, 10.9885691],
                [107.5329723, 10.9885531],
                [107.5330692, 10.9885638],
                [107.5331232, 10.9885823],
                [107.5331733, 10.9886091],
                [107.5332183, 10.9886437],
                [107.5333252, 10.9887482],
                [107.5334072, 10.9887929],
                [107.5334568, 10.9888086],
                [107.5335601, 10.9888192],
                [107.5336188, 10.9887927],
                [107.533662, 10.9887456],
                [107.5336824, 10.9886868],
                [107.5336784, 10.9886263],
                [107.5336676, 10.9885977],
                [107.5335912, 10.9884861],
                [107.533451, 10.9883303],
                [107.5333719, 10.9882612],
                [107.5332873, 10.9881984],
                [107.5331233, 10.9881014],
                [107.5330945, 10.9880699],
                [107.5330735, 10.988033],
                [107.5330612, 10.9879925],
                [107.5330641, 10.9879106],
                [107.5331013, 10.9878373],
                [107.533168, 10.9877781],
                [107.5332095, 10.9877542],
                [107.5332995, 10.9877274],
                [107.5333457, 10.9877245],
                [107.5334367, 10.9877397],
                [107.5335087, 10.9877895],
                [107.5335679, 10.9878641],
                [107.5336223, 10.9880077],
                [107.5336781, 10.9880925],
                [107.5337157, 10.9881137],
                [107.5337377, 10.988114],
                [107.5337753, 10.9880942],
                [107.5337923, 10.9880567],
                [107.5337619, 10.9879255],
                [107.5337559, 10.9878687],
                [107.5337609, 10.987754],
                [107.5337778, 10.9876645],
                [107.5338042, 10.9876028],
                [107.5338504, 10.9875496],
                [107.5338793, 10.987529],
                [107.5339141, 10.9874632],
                [107.5339173, 10.9873977],
                [107.5338846, 10.9872157],
                [107.5338856, 10.9871753],
                [107.5339077, 10.9870977],
                [107.5339544, 10.9870314],
                [107.533991, 10.987001],
                [107.5340328, 10.986978],
                [107.5340784, 10.9869634],
                [107.534126, 10.9869577],
                [107.5341922, 10.9869852],
                [107.5342414, 10.9870367],
                [107.5342566, 10.9870681],
                [107.5342661, 10.9871367],
                [107.5342256, 10.9872748],
                [107.5342172, 10.9873289],
                [107.5342183, 10.9874329],
                [107.534255, 10.9875765],
                [107.5342756, 10.9876159],
                [107.5343353, 10.9876818],
                [107.5344172, 10.9877258],
                [107.5345096, 10.9877397],
                [107.534563, 10.9877267],
                [107.5346133, 10.9877049],
                [107.5346592, 10.9876751],
                [107.5346992, 10.9876381],
                [107.5347322, 10.9875949],
                [107.53477, 10.9875098],
                [107.534779, 10.9874641],
                [107.5347762, 10.9873714],
                [107.5347644, 10.9873263],
                [107.5346189, 10.9870308],
                [107.5346115, 10.9869679],
                [107.5346169, 10.9869366],
                [107.5346469, 10.9868767],
                [107.534698, 10.9868326],
                [107.5347624, 10.9868112],
                [107.5348441, 10.9868036],
                [107.53489, 10.9867895],
                [107.5349719, 10.9867409],
                [107.5350357, 10.9866662],
                [107.5350579, 10.9866204],
                [107.5350768, 10.9865211],
                [107.5350541, 10.9861915],
                [107.5350616, 10.9861235],
                [107.5350782, 10.9860572],
                [107.5351346, 10.9859387],
                [107.5351533, 10.9858439],
                [107.5351499, 10.9857485],
                [107.5351242, 10.9856313],
                [107.5351319, 10.9855595],
                [107.5351683, 10.9854852],
                [107.5353156, 10.9853068],
                [107.5353347, 10.9852517],
                [107.5353357, 10.9852225],
                [107.5353205, 10.9851667],
                [107.5352846, 10.9851209],
                [107.5349468, 10.9849302],
                [107.5349117, 10.9849161],
                [107.5348371, 10.9849041],
                [107.5347993, 10.9849065],
                [107.5347551, 10.9849167],
                [107.5346488, 10.9849715],
                [107.5346197, 10.984978],
                [107.5345606, 10.9849724],
                [107.5345302, 10.9849587],
                [107.5344832, 10.9849127],
                [107.5344694, 10.9848828],
                [107.5344548, 10.9847637],
                [107.5344586, 10.9847038],
                [107.5344889, 10.9845855],
                [107.534515, 10.98453],
                [107.5345404, 10.9845025],
                [107.5346062, 10.9844668],
                [107.5346438, 10.9844603],
                [107.534682, 10.9844621],
                [107.5347655, 10.9844877],
                [107.5348143, 10.9844948],
                [107.5348636, 10.9844931],
                [107.5349442, 10.9844704],
                [107.5350139, 10.9844247],
                [107.5350809, 10.9843128],
                [107.535105, 10.9842523],
                [107.5351225, 10.9841897],
                [107.5351366, 10.9840731],
                [107.5351351, 10.9840143],
                [107.5351191, 10.9839213],
                [107.5350829, 10.9838597],
                [107.5350239, 10.983814],
                [107.5349489, 10.9837934],
                [107.5349098, 10.9837945],
                [107.5345471, 10.9838744],
                [107.534415, 10.9838651],
                [107.5343504, 10.9838502],
                [107.534228, 10.9838006],
                [107.5341681, 10.9837643],
                [107.5340628, 10.9836728],
                [107.5340188, 10.983619],
                [107.5339964, 10.9835784],
                [107.5339705, 10.9834899],
                [107.5339722, 10.983465],
                [107.5339953, 10.9834208],
                [107.5340404, 10.983394],
                [107.5340669, 10.9833909],
                [107.534202, 10.9834125],
                [107.534225, 10.9834092],
                [107.5342621, 10.9833868],
                [107.5342821, 10.9833515],
                [107.5342878, 10.9832101],
                [107.5342977, 10.9831503],
                [107.5343424, 10.9830157],
                [107.534408, 10.9829032],
                [107.5344447, 10.9828566],
                [107.5345114, 10.9827919],
                [107.5345718, 10.9827625],
                [107.5346048, 10.9827553],
                [107.5346829, 10.9827588],
                [107.5347511, 10.9827827],
                [107.5348167, 10.9827895],
                [107.5348873, 10.9827713],
                [107.5349186, 10.9827527],
                [107.5349381, 10.9827132],
                [107.5349396, 10.9826198],
                [107.5349602, 10.9825605],
                [107.5350042, 10.9825148],
                [107.5351068, 10.9824698],
                [107.5351399, 10.9824472],
                [107.5351992, 10.9823828],
                [107.5352204, 10.9823445],
                [107.5353139, 10.9820371],
                [107.5353859, 10.9818958],
                [107.5354913, 10.98176],
                [107.5356764, 10.9815915],
                [107.5357942, 10.981521],
                [107.5359176, 10.9814817],
                [107.5359777, 10.9814729],
                [107.5362825, 10.9814677],
                [107.5363232, 10.98146],
                [107.5363959, 10.981422],
                [107.536448, 10.9813593],
                [107.5364636, 10.9813216],
                [107.5364714, 10.9812477],
                [107.5364519, 10.9811759],
                [107.5364325, 10.9811438],
                [107.5364075, 10.9811157],
                [107.5361685, 10.9809305],
                [107.5361163, 10.9808676],
                [107.5360995, 10.9808305],
                [107.5360865, 10.9807511],
                [107.5361017, 10.9806727],
                [107.5361431, 10.9806041],
                [107.5362182, 10.9805277],
                [107.5362391, 10.9804968],
                [107.536257, 10.9804251],
                [107.536253, 10.9803882],
                [107.5362249, 10.9803275],
                [107.5362019, 10.9803028],
                [107.5361012, 10.9802458],
                [107.5360323, 10.9801795],
                [107.5359891, 10.9800948],
                [107.5359788, 10.9800484],
                [107.5359821, 10.9799537],
                [107.5359971, 10.9799045],
                [107.5360207, 10.9798587],
                [107.5360524, 10.9798177],
                [107.5360909, 10.979783],
                [107.5361351, 10.9797556],
                [107.5361836, 10.9797363],
                [107.5362349, 10.9797259],
                [107.5362872, 10.9797246],
                [107.5363389, 10.9797325],
                [107.5365044, 10.9797792],
                [107.5366169, 10.979831],
                [107.5366915, 10.9798438],
                [107.5367653, 10.9798273],
                [107.5368001, 10.9798075],
                [107.5368534, 10.9797491],
                [107.5368697, 10.9797131],
                [107.536878, 10.9796745],
                [107.5368726, 10.9795752],
                [107.536843, 10.9794587],
                [107.536819, 10.9794034],
                [107.5368163, 10.9793327],
                [107.5368385, 10.9792649],
                [107.5369034, 10.9791818],
                [107.5369316, 10.9791216],
                [107.536981, 10.9787287],
                [107.5369936, 10.978682],
                [107.5370423, 10.978586],
                [107.5370801, 10.97854],
                [107.5372011, 10.9784329],
                [107.5372304, 10.9783913],
                [107.5372678, 10.9782975],
                [107.537275, 10.9782474],
                [107.5372665, 10.9781512],
                [107.5372514, 10.9781051],
                [107.5372296, 10.9780617],
                [107.5370695, 10.9778273],
                [107.5370183, 10.9777181],
                [107.5370039, 10.9776635],
                [107.5369935, 10.9775773],
                [107.5370101, 10.9775191],
                [107.5370524, 10.977474],
                [107.5371118, 10.9774528],
                [107.5371437, 10.9774532],
                [107.537307, 10.9775131],
                [107.5374171, 10.9775286],
                [107.5374831, 10.9775271],
                [107.5376122, 10.9775007],
                [107.5377309, 10.9774443],
                [107.5378648, 10.9774038],
                [107.5380043, 10.9773919],
                [107.5380742, 10.9773969],
                [107.538203, 10.9774261],
                [107.5383233, 10.9774799],
                [107.5383787, 10.9775154],
                [107.5385675, 10.9776929],
                [107.5386036, 10.9777113],
                [107.5386831, 10.9777261],
                [107.5387634, 10.97771],
                [107.5387996, 10.9776909],
                [107.5389656, 10.9775313],
                [107.539129, 10.9774228],
                [107.539217, 10.9773913],
                [107.5393135, 10.977393],
                [107.5393612, 10.9774076],
                [107.5394052, 10.9774308],
                [107.5394438, 10.9774619],
                [107.5394757, 10.9774996],
                [107.539587, 10.9777076],
                [107.5396122, 10.9777329],
                [107.5396728, 10.9777708],
                [107.5397058, 10.9777823],
                [107.5397749, 10.9777902],
                [107.5401664, 10.9777471],
                [107.540267, 10.977748],
                [107.5403649, 10.9777708],
                [107.5404166, 10.9777929],
                [107.5405585, 10.9778877],
                [107.5406653, 10.9779367],
                [107.5407734, 10.9779615],
                [107.5410021, 10.9779767],
                [107.5410663, 10.9780049],
                [107.5411146, 10.9780544],
                [107.5411402, 10.9781176],
                [107.5411434, 10.9781516],
                [107.5411197, 10.9783645],
                [107.5411247, 10.9783973],
                [107.5411386, 10.9784274],
                [107.5411603, 10.9784527],
                [107.5411883, 10.9784712],
                [107.5412466, 10.9784835],
                [107.5413038, 10.9784673],
                [107.5414807, 10.9782896],
                [107.5415315, 10.9782588],
                [107.541591, 10.9782533],
                [107.5416504, 10.9782766],
                [107.5416753, 10.9782997],
                [107.5417033, 10.9783607],
                [107.5417204, 10.9787211],
                [107.5417522, 10.9787753],
                [107.5418079, 10.9788057],
                [107.5418651, 10.9788052],
                [107.5419155, 10.9787786],
                [107.5420198, 10.9786134],
                [107.5420947, 10.9785423],
                [107.5421378, 10.9785134],
                [107.5422183, 10.9784133],
                [107.5423046, 10.9782774],
                [107.5423327, 10.978249],
                [107.5424006, 10.978211],
                [107.542516, 10.9781923],
                [107.5425911, 10.9781605],
                [107.5531322, 10.9529631],
                [107.5550687, 10.9489867],
                [107.5573271, 10.9435092],
                [107.5604117, 10.9370151],
                [107.5604868, 10.934587],
                [107.5604841, 10.9301021],
                [107.5603849, 10.926955],
                [107.5604868, 10.9241423],
                [107.5605941, 10.9211663],
                [107.5609052, 10.9186749],
                [107.5609803, 10.9171211],
                [107.5610071, 10.9102524],
                [107.5609535, 10.9082481],
                [107.5607335, 10.8936805],
                [107.5605726, 10.8900457],
                [107.5604117, 10.888339],
                [107.5603956, 10.8855312],
                [107.5601864, 10.8817857],
                [107.5601904, 10.8795863],
                [107.5601032, 10.8777069],
                [107.5598323, 10.8729038],
                [107.5598377, 10.8685417],
                [107.5702125, 10.8535797],
                [107.5712317, 10.8516462],
                [107.5723743, 10.849876],
                [107.5736511, 10.8472944],
                [107.5740588, 10.8462775],
                [107.5748312, 10.8438645],
                [107.5759095, 10.8407507],
                [107.5777548, 10.8340858],
                [107.577816, 10.8338037],
                [107.5778349, 10.8336606],
                [107.5778493, 10.8333803],
                [107.5778346, 10.8331078],
                [107.5777919, 10.8328382],
                [107.5777602, 10.8327053],
                [107.5770414, 10.8304134],
                [107.5760919, 10.8281847],
                [107.5756627, 10.8274049],
                [107.5748742, 10.8251076],
                [107.5745952, 10.8243673],
                [107.5745684, 10.8237219],
                [107.5743431, 10.8227945],
                [107.5740588, 10.8217671],
                [107.573415, 10.8199334],
                [107.5729751, 10.8190061],
                [107.5729644, 10.8182052],
                [107.5728942, 10.8179123],
                [107.5728464, 10.8176151],
                [107.5728189, 10.817252],
                [107.5728249, 10.8168879],
                [107.5735036, 10.8165224],
                [107.5738699, 10.8139638],
                [107.5736825, 10.8138733],
                [107.5734806, 10.8138061],
                [107.5732681, 10.8137612],
                [107.5731602, 10.8137475],
                [107.572939, 10.8137379],
                [107.5725645, 10.8137555],
                [107.5723033, 10.8137943],
                [107.5720315, 10.8138633],
                [107.571891, 10.8139111],
                [107.5716193, 10.8140294],
                [107.5712317, 10.813679],
                [107.5711904, 10.8136215],
                [107.5711634, 10.8135519],
                [107.5711566, 10.813513],
                [107.5711566, 10.813326],
                [107.5711478, 10.813249],
                [107.5711115, 10.8130983],
                [107.5710533, 10.8129598],
                [107.5709499, 10.8127948],
                [107.5709125, 10.8127042],
                [107.5708991, 10.8126146],
                [107.5709199, 10.8123906],
                [107.5709008, 10.8123356],
                [107.5708542, 10.8122845],
                [107.5707887, 10.8122552],
                [107.5707516, 10.8122512],
                [107.5706094, 10.8122736],
                [107.5705393, 10.8122718],
                [107.5704025, 10.8122431],
                [107.5702769, 10.8121826],
                [107.5702414, 10.8121553],
                [107.5702124, 10.8121214],
                [107.570191, 10.8120825],
                [107.5701798, 10.8120476],
                [107.5701588, 10.8118664],
                [107.5701644, 10.8117255],
                [107.5701887, 10.8115811],
                [107.5701919, 10.8114515],
                [107.5701749, 10.8113448],
                [107.5701425, 10.8112603],
                [107.5701347, 10.8112157],
                [107.5701354, 10.8111345],
                [107.570152, 10.8110589],
                [107.5701547, 10.8109857],
                [107.5701333, 10.8109075],
                [107.5701113, 10.8108686],
                [107.5700489, 10.8108047],
                [107.5700128, 10.8107863],
                [107.5699335, 10.8107704],
                [107.5698924, 10.8107736],
                [107.5698155, 10.810802],
                [107.5697395, 10.8108569],
                [107.5695795, 10.8109548],
                [107.5693972, 10.8110413],
                [107.5693574, 10.8110534],
                [107.5692746, 10.811059],
                [107.5691931, 10.8110392],
                [107.5691211, 10.8109952],
                [107.5690913, 10.8109654],
                [107.5689545, 10.8107098],
                [107.5688951, 10.8106142],
                [107.5687407, 10.8104047],
                [107.5687135, 10.8103824],
                [107.568654, 10.8103572],
                [107.5685262, 10.8103483],
                [107.5684658, 10.810315],
                [107.5684423, 10.8102897],
                [107.5684141, 10.8102277],
                [107.5684105, 10.8101925],
                [107.5684269, 10.8101241],
                [107.5684461, 10.8100942],
                [107.5685018, 10.81005],
                [107.5686004, 10.8100271],
                [107.5686273, 10.8100143],
                [107.568664, 10.8099792],
                [107.5686762, 10.8099569],
                [107.5687104, 10.8098114],
                [107.568769, 10.8096616],
                [107.5687731, 10.8096014],
                [107.5687521, 10.8095381],
                [107.5687304, 10.8095085],
                [107.5686297, 10.8094343],
                [107.5685576, 10.8093609],
                [107.5684849, 10.8092502],
                [107.5684583, 10.8091897],
                [107.5684087, 10.809123],
                [107.5683422, 10.8090697],
                [107.5681792, 10.8089929],
                [107.5681123, 10.8089288],
                [107.5680672, 10.8088454],
                [107.5680552, 10.8087995],
                [107.5680485, 10.808693],
                [107.5680377, 10.8086346],
                [107.5680184, 10.8085784],
                [107.5679725, 10.8085005],
                [107.5679058, 10.8084388],
                [107.5678598, 10.8084126],
                [107.56781, 10.8083943],
                [107.5676811, 10.808377],
                [107.5676424, 10.8083485],
                [107.5676295, 10.8083281],
                [107.5676208, 10.8082797],
                [107.5676262, 10.8082554],
                [107.5676969, 10.8080816],
                [107.5677108, 10.8079798],
                [107.5677082, 10.8079283],
                [107.5676843, 10.807829],
                [107.567637, 10.8077387],
                [107.5676054, 10.8076985],
                [107.5674288, 10.8075453],
                [107.5673589, 10.8075171],
                [107.5673211, 10.807514],
                [107.5672563, 10.807527],
                [107.5671689, 10.8075826],
                [107.5670963, 10.8076042],
                [107.5670202, 10.8075952],
                [107.5669536, 10.8075562],
                [107.5668472, 10.8074071],
                [107.5668294, 10.8073709],
                [107.5668114, 10.8072927],
                [107.5668071, 10.8071245],
                [107.5667953, 10.8070596],
                [107.5667638, 10.8069734],
                [107.5661784, 10.8060703],
                [107.5658458, 10.8050269],
                [107.5656715, 10.8041601],
                [107.5627372, 10.7999684],
                [107.5610769, 10.7985087],
                [107.5562221, 10.7950678],
                [107.5558144, 10.7946726],
                [107.5540924, 10.7933868],
                [107.5493985, 10.7900037],
                [107.5453551, 10.7871511],
                [107.5437069, 10.7859883],
                [107.5388467, 10.7827949],
                [107.5335781, 10.7791265],
                [107.5329459, 10.7787161],
                [107.5321841, 10.7782524],
                [107.5291049, 10.7765977],
                [107.5278119, 10.7758825],
                [107.5268956, 10.7753757],
                [107.5264075, 10.7751057],
                [107.5258507, 10.7748307],
                [107.5255671, 10.774704],
                [107.5250205, 10.774484],
                [107.5244946, 10.7743007],
                [107.5239605, 10.7741419],
                [107.5215733, 10.7735201],
                [107.5187463, 10.772893],
                [107.5185174, 10.7728475],
                [107.5180558, 10.7727789],
                [107.5178236, 10.7727559],
                [107.5174215, 10.7727341],
                [107.5170189, 10.7727349],
                [107.5154263, 10.7727813],
                [107.5107561, 10.7723711],
                [107.5070679, 10.7720656],
                [107.5052501, 10.7715976],
                [107.5019449, 10.761104],
                [107.5029051, 10.7604505],
                [107.5032806, 10.7600289],
                [107.5045037, 10.7591488],
                [107.5048264, 10.7586062],
                [107.5049346, 10.7584733],
                [107.505067, 10.7583636],
                [107.5052135, 10.7582834],
                [107.5054202, 10.7582179],
                [107.5055093, 10.7581755],
                [107.5055908, 10.7581116],
                [107.5056272, 10.7580711],
                [107.5056839, 10.7579788],
                [107.505757, 10.7578964],
                [107.5058479, 10.7578333],
                [107.5058985, 10.7578102],
                [107.5060117, 10.7577817],
                [107.5061286, 10.7577817],
                [107.5062418, 10.7578102],
                [107.5063581, 10.7578556],
                [107.5064195, 10.7578681],
                [107.5065445, 10.7578717],
                [107.506665, 10.7578477],
                [107.5067749, 10.7577988],
                [107.506825, 10.7577655],
                [107.5069123, 10.7576837],
                [107.5071384, 10.7574215],
                [107.507261, 10.757299],
                [107.5073746, 10.7571956],
                [107.5076796, 10.7569459],
                [107.5077371, 10.7568813],
                [107.5077871, 10.7568109],
                [107.5078288, 10.7567355],
                [107.5078841, 10.7565803],
                [107.5078985, 10.7565027],
                [107.5079028, 10.7563743],
                [107.5078833, 10.7562766],
                [107.5078349, 10.75617],
                [107.5077616, 10.7560781],
                [107.507717, 10.7560394],
                [107.5072449, 10.75566],
                [107.5071415, 10.7555348],
                [107.5071001, 10.755465],
                [107.5070423, 10.7553216],
                [107.5070143, 10.7551698],
                [107.5069989, 10.7549409],
                [107.5069821, 10.7548273],
                [107.5069606, 10.7547206],
                [107.5068854, 10.7544363],
                [107.5068694, 10.7542844],
                [107.5068782, 10.7541104],
                [107.5068933, 10.7540244],
                [107.5069445, 10.7538576],
                [107.5068748, 10.7524188],
                [107.5068605, 10.7523378],
                [107.5068074, 10.7521821],
                [107.5067691, 10.752109],
                [107.5066709, 10.7519761],
                [107.5066097, 10.7519154],
                [107.5065423, 10.7518615],
                [107.5064694, 10.7518148],
                [107.5063191, 10.7517463],
                [107.5062873, 10.7517232],
                [107.5062373, 10.7516634],
                [107.5062088, 10.7515873],
                [107.5062087, 10.7515026],
                [107.5062388, 10.7514233],
                [107.5064322, 10.7512028],
                [107.5065416, 10.7510266],
                [107.5065851, 10.7509326],
                [107.506619, 10.7508473],
                [107.5066439, 10.750759],
                [107.5066595, 10.7506687],
                [107.5066657, 10.7505773],
                [107.5066623, 10.7504857],
                [107.5066289, 10.7503113],
                [107.5065637, 10.7501515],
                [107.5065198, 10.750077],
                [107.5064111, 10.749942],
                [107.5062783, 10.7498299],
                [107.5062042, 10.7497837],
                [107.5061428, 10.749732],
                [107.5060878, 10.7496737],
                [107.50604, 10.7496096],
                [107.5059999, 10.7495406],
                [107.5059682, 10.7494675],
                [107.5059336, 10.7493306],
                [107.5059271, 10.7492603],
                [107.5059279, 10.7491897],
                [107.5059717, 10.7488678],
                [107.5059749, 10.7487407],
                [107.505955, 10.7485074],
                [107.5059079, 10.7482985],
                [107.5057647, 10.747919],
                [107.505729, 10.7477314],
                [107.5057241, 10.747636],
                [107.5057402, 10.7474483],
                [107.5057899, 10.7472663],
                [107.5058716, 10.7470958],
                [107.5059575, 10.7468323],
                [107.5059785, 10.7467365],
                [107.5060721, 10.7460233],
                [107.5061197, 10.7458978],
                [107.5061949, 10.7457861],
                [107.5062901, 10.7456966],
                [107.5063447, 10.74566],
                [107.5064643, 10.7456055],
                [107.5079262, 10.7452248],
                [107.508468, 10.7449033],
                [107.5090688, 10.744761],
                [107.510249, 10.7441023],
                [107.5115669, 10.7434827],
                [107.5117223, 10.7433816],
                [107.5118567, 10.7432547],
                [107.5119656, 10.7431061],
                [107.5120438, 10.7429454],
                [107.5120718, 10.7428606],
                [107.5122955, 10.7418044],
                [107.5123225, 10.7415659],
                [107.5123275, 10.7413307],
                [107.5122686, 10.7406355],
                [107.5122718, 10.7405679],
                [107.5123036, 10.7404367],
                [107.5123608, 10.7403269],
                [107.5123986, 10.7402775],
                [107.51249, 10.7401929],
                [107.5125425, 10.7401589],
                [107.5129762, 10.739957],
                [107.5131128, 10.7398759],
                [107.5132428, 10.7397753],
                [107.5135584, 10.7394813],
                [107.5136782, 10.7393991],
                [107.5138073, 10.7393458],
                [107.514185, 10.739264],
                [107.5143071, 10.7392176],
                [107.5144173, 10.7391454],
                [107.5144662, 10.7391002],
                [107.5145473, 10.7389952],
                [107.5146019, 10.7389425],
                [107.5146625, 10.7388966],
                [107.514793, 10.7388296],
                [107.5149301, 10.7387944],
                [107.5153077, 10.7387739],
                [107.5153498, 10.7387602],
                [107.5153881, 10.7387382],
                [107.5154211, 10.738709],
                [107.5154471, 10.7386737],
                [107.5154735, 10.7385997],
                [107.5154757, 10.7385604],
                [107.5154705, 10.7385214],
                [107.5154277, 10.7384067],
                [107.5154232, 10.7383654],
                [107.5154262, 10.738324],
                [107.5154518, 10.738249],
                [107.5154984, 10.7381888],
                [107.5157227, 10.738036],
                [107.5157557, 10.738004],
                [107.5158041, 10.7379267],
                [107.5158253, 10.7378383],
                [107.5158165, 10.7377448],
                [107.5158005, 10.7377004],
                [107.5157019, 10.7375524],
                [107.5156318, 10.7374014],
                [107.515592, 10.7372401],
                [107.5155836, 10.73715],
                [107.5155848, 10.7370595],
                [107.5155955, 10.7369696],
                [107.5156156, 10.7368813],
                [107.5157203, 10.7366154],
                [107.5157499, 10.7365153],
                [107.515786, 10.73631],
                [107.5157922, 10.7362059],
                [107.515769, 10.7354903],
                [107.5157748, 10.7353956],
                [107.5158046, 10.7353053],
                [107.515828, 10.7352637],
                [107.515898, 10.7351836],
                [107.5159409, 10.7351516],
                [107.5160383, 10.7351069],
                [107.5163502, 10.7350161],
                [107.5166126, 10.734911],
                [107.5167914, 10.7348188],
                [107.5168458, 10.734815],
                [107.5168955, 10.7348368],
                [107.516914, 10.7348548],
                [107.5169361, 10.7349009],
                [107.5169384, 10.7349264],
                [107.5169009, 10.7352532],
                [107.5169117, 10.735326],
                [107.5169521, 10.735388],
                [107.5169814, 10.7354113],
                [107.5170441, 10.7354362],
                [107.5171117, 10.7354362],
                [107.5171443, 10.7354267],
                [107.5175661, 10.7352426],
                [107.5176729, 10.7352176],
                [107.5177279, 10.7352144],
                [107.517837, 10.7352268],
                [107.5179408, 10.735264],
                [107.5180329, 10.7353238],
                [107.5181349, 10.7354159],
                [107.5181723, 10.7354344],
                [107.5182539, 10.7354508],
                [107.5183378, 10.7354378],
                [107.5183773, 10.7354201],
                [107.5184128, 10.7353955],
                [107.5184977, 10.7353027],
                [107.5185716, 10.735269],
                [107.5186121, 10.7352637],
                [107.5186483, 10.7352657],
                [107.5187167, 10.735288],
                [107.5188126, 10.7353688],
                [107.5188643, 10.7353849],
                [107.5189201, 10.7353767],
                [107.5189661, 10.7353446],
                [107.5190082, 10.7352988],
                [107.5190711, 10.7352706],
                [107.5191057, 10.7352663],
                [107.5191795, 10.7352809],
                [107.5192122, 10.7352998],
                [107.5192612, 10.7353559],
                [107.5193103, 10.7353866],
                [107.5194185, 10.7354279],
                [107.5195363, 10.7354398],
                [107.5196555, 10.7354199],
                [107.5197118, 10.7353981],
                [107.5199532, 10.7352637],
                [107.5200207, 10.7352055],
                [107.5200659, 10.7351293],
                [107.5200788, 10.7350856],
                [107.5200821, 10.7349252],
                [107.5200944, 10.7348923],
                [107.520139, 10.7348402],
                [107.5202009, 10.7348132],
                [107.5202349, 10.7348104],
                [107.520503, 10.7348236],
                [107.5205862, 10.7348052],
                [107.5206478, 10.7347663],
                [107.5207174, 10.7346802],
                [107.5207767, 10.7346312],
                [107.5208491, 10.7345996],
                [107.5209133, 10.7345935],
                [107.5209591, 10.7345731],
                [107.5209883, 10.734533],
                [107.5209939, 10.7345087],
                [107.5209817, 10.7344527],
                [107.520939, 10.7344137],
                [107.5209108, 10.7344055],
                [107.5208562, 10.7344049],
                [107.5208136, 10.7343801],
                [107.5207955, 10.7343348],
                [107.5207989, 10.734309],
                [107.5208305, 10.7342683],
                [107.5208813, 10.734257],
                [107.5213507, 10.734257],
                [107.5214326, 10.734238],
                [107.5214705, 10.7342197],
                [107.5215358, 10.7341674],
                [107.5215832, 10.7340966],
                [107.5216064, 10.7340149],
                [107.5216135, 10.7338486],
                [107.5216273, 10.733819],
                [107.5216699, 10.7337695],
                [107.5217354, 10.7337356],
                [107.5218093, 10.73373],
                [107.5222506, 10.733751],
                [107.5223342, 10.7337365],
                [107.5224055, 10.7336909],
                [107.5224534, 10.7336207],
                [107.5224656, 10.7335799],
                [107.5224772, 10.7331291],
                [107.5224634, 10.7330451],
                [107.5224263, 10.7329678],
                [107.5223685, 10.7329035],
                [107.5222948, 10.7328577],
                [107.522211, 10.7328266],
                [107.5221419, 10.7327706],
                [107.5221153, 10.7327351],
                [107.5220813, 10.7326537],
                [107.5220749, 10.73261],
                [107.522085, 10.7325191],
                [107.5221017, 10.7324763],
                [107.5221557, 10.732402],
                [107.522232, 10.7323496],
                [107.5222758, 10.7323337],
                [107.5224606, 10.7322998],
                [107.5225269, 10.7322754],
                [107.522651, 10.7322033],
                [107.5227068, 10.732156],
                [107.5227797, 10.7320742],
                [107.5228159, 10.732006],
                [107.5228321, 10.7319261],
                [107.5228324, 10.7318536],
                [107.5228541, 10.7317944],
                [107.5228982, 10.7317488],
                [107.5229573, 10.7317245],
                [107.5229905, 10.7317218],
                [107.5230548, 10.7317372],
                [107.5230829, 10.7317546],
                [107.523125, 10.7318047],
                [107.523137, 10.7318352],
                [107.523227, 10.7322627],
                [107.5232406, 10.7322899],
                [107.5232603, 10.7323133],
                [107.5233132, 10.7323437],
                [107.5233743, 10.7323474],
                [107.5234306, 10.7323236],
                [107.5234699, 10.7322774],
                [107.5235147, 10.7321228],
                [107.5235797, 10.7320144],
                [107.5236676, 10.7319294],
                [107.5237759, 10.7318661],
                [107.5238748, 10.7317857],
                [107.5239483, 10.7316824],
                [107.5239739, 10.7316244],
                [107.5239999, 10.7315047],
                [107.5240007, 10.7314433],
                [107.5239782, 10.731323],
                [107.5239392, 10.7312419],
                [107.5239255, 10.7311865],
                [107.5239382, 10.7311291],
                [107.5239768, 10.7310823],
                [107.5240329, 10.7310578],
                [107.5241965, 10.7310472],
                [107.5242598, 10.7310635],
                [107.5243118, 10.7311026],
                [107.5243311, 10.7311291],
                [107.5243519, 10.7311907],
                [107.5243279, 10.7314663],
                [107.5243382, 10.731539],
                [107.5243532, 10.7315727],
                [107.5244004, 10.7316296],
                [107.5244719, 10.7316685],
                [107.5245121, 10.7316768],
                [107.5246702, 10.7316811],
                [107.5248908, 10.7317101],
                [107.5249937, 10.7317334],
                [107.5251943, 10.7317983],
                [107.5252779, 10.731817],
                [107.5254488, 10.7318302],
                [107.5256191, 10.7318106],
                [107.5257823, 10.7317591],
                [107.5259322, 10.7316775],
                [107.5260005, 10.7316264],
                [107.5261199, 10.7315062],
                [107.5262134, 10.731366],
                [107.5262777, 10.7312107],
                [107.5263103, 10.7310462],
                [107.5263101, 10.7308786],
                [107.5262672, 10.7305676],
                [107.5261861, 10.7302176],
                [107.5261018, 10.7299561],
                [107.5261009, 10.7298614],
                [107.5261276, 10.7297765],
                [107.5261503, 10.729738],
                [107.5264003, 10.7294782],
                [107.5264264, 10.7294121],
                [107.5264281, 10.7293765],
                [107.5264083, 10.7293081],
                [107.5263877, 10.7292786],
                [107.5263298, 10.7292359],
                [107.5262591, 10.729221],
                [107.5260231, 10.7292183],
                [107.5259714, 10.7291953],
                [107.5259351, 10.7291524],
                [107.5259252, 10.7291261],
                [107.5259254, 10.7290654],
                [107.5259547, 10.729012],
                [107.5260061, 10.7289785],
                [107.5263382, 10.7289298],
                [107.5263849, 10.7289122],
                [107.5264032, 10.7288953],
                [107.5264241, 10.7288507],
                [107.5264173, 10.7287955],
                [107.5263805, 10.7287532],
                [107.5262491, 10.728657],
                [107.5262075, 10.7285594],
                [107.5261979, 10.7284539],
                [107.5262055, 10.7284014],
                [107.5262446, 10.7283027],
                [107.5263123, 10.7282202],
                [107.5263549, 10.7281875],
                [107.5264529, 10.7281432],
                [107.5265059, 10.7281326],
                [107.5267783, 10.7281205],
                [107.5268628, 10.7280942],
                [107.5269, 10.7280704],
                [107.5269594, 10.7280038],
                [107.5269908, 10.7279194],
                [107.526994, 10.7278743],
                [107.5269639, 10.7276207],
                [107.5269789, 10.7275417],
                [107.5269967, 10.7275054],
                [107.5270448, 10.7274493],
                [107.5270751, 10.7274276],
                [107.5273964, 10.7273051],
                [107.5274412, 10.7272745],
                [107.5274567, 10.7272522],
                [107.5274695, 10.7272001],
                [107.5274661, 10.7271733],
                [107.5274393, 10.7271246],
                [107.527392, 10.7270945],
                [107.5273221, 10.7270867],
                [107.5272421, 10.7270614],
                [107.5271754, 10.7270111],
                [107.5271496, 10.7269783],
                [107.5271156, 10.7268973],
                [107.5271133, 10.7268098],
                [107.5271427, 10.7267271],
                [107.5271684, 10.7266911],
                [107.5275493, 10.7263827],
                [107.5276747, 10.7262575],
                [107.5277764, 10.726113],
                [107.5278519, 10.7259527],
                [107.5278983, 10.7257812],
                [107.527914, 10.7256044],
                [107.5278893, 10.7251874],
                [107.5278926, 10.7249491],
                [107.527941, 10.7247473],
                [107.5280723, 10.7243983],
                [107.5281196, 10.7242152],
                [107.528158, 10.7241418],
                [107.5282204, 10.7240906],
                [107.5282955, 10.7240671],
                [107.5285819, 10.72409],
                [107.5287362, 10.7240806],
                [107.5288126, 10.7240689],
                [107.5289337, 10.7240319],
                [107.5290459, 10.723974],
                [107.5291805, 10.7238769],
                [107.5292417, 10.7238212],
                [107.529267, 10.7237845],
                [107.5293007, 10.7237026],
                [107.5293149, 10.7235474],
                [107.5293269, 10.7235013],
                [107.5293678, 10.7234153],
                [107.5294306, 10.7233402],
                [107.5295107, 10.7232831],
                [107.5299793, 10.7230622],
                [107.530217, 10.7229854],
                [107.5304543, 10.722936],
                [107.530688, 10.7229122],
                [107.5311076, 10.7228973],
                [107.531151, 10.7228868],
                [107.5311912, 10.7228677],
                [107.5312504, 10.7228143],
                [107.5312831, 10.7227515],
                [107.5312909, 10.7227169],
                [107.5312999, 10.7225143],
                [107.5313365, 10.7224323],
                [107.5313963, 10.7223718],
                [107.5315136, 10.7223147],
                [107.5315833, 10.7222584],
                [107.5316394, 10.7221725],
                [107.5316557, 10.722124],
                [107.5316772, 10.7220133],
                [107.5317197, 10.721934],
                [107.5317818, 10.7218683],
                [107.5318501, 10.7218275],
                [107.531913, 10.7217789],
                [107.5319695, 10.7217234],
                [107.5320503, 10.7216131],
                [107.5321063, 10.7214888],
                [107.5321788, 10.7214309],
                [107.5322616, 10.7214085],
                [107.5325824, 10.721422],
                [107.5326095, 10.7214294],
                [107.532651, 10.7214658],
                [107.5326637, 10.7215189],
                [107.5326433, 10.7215695],
                [107.5324999, 10.7216608],
                [107.5324267, 10.7217486],
                [107.5323863, 10.721847],
                [107.5323762, 10.7219451],
                [107.5323933, 10.7220423],
                [107.5324155, 10.7220905],
                [107.5324806, 10.7221745],
                [107.5325646, 10.7222337],
                [107.5326103, 10.722253],
                [107.5327076, 10.7222719],
                [107.5327573, 10.7222712],
                [107.5329265, 10.72224],
                [107.5330447, 10.7222083],
                [107.5332725, 10.7221219],
                [107.5334856, 10.7220061],
                [107.5335857, 10.7219379],
                [107.5337101, 10.721836],
                [107.5337829, 10.7218036],
                [107.5338635, 10.7218048],
                [107.5340144, 10.7218734],
                [107.5341065, 10.7218835],
                [107.5341564, 10.7218772],
                [107.5343975, 10.7217852],
                [107.5346607, 10.7216592],
                [107.5348878, 10.7215178],
                [107.534976, 10.7214303],
                [107.5350135, 10.7213808],
                [107.5350746, 10.7212699],
                [107.5350972, 10.7212093],
                [107.5351238, 10.721083],
                [107.5351526, 10.7209917],
                [107.5352311, 10.7208168],
                [107.5352861, 10.7207256],
                [107.5353489, 10.7206393],
                [107.5354189, 10.7205586],
                [107.5355242, 10.7204835],
                [107.5355686, 10.7204367],
                [107.5356374, 10.7203283],
                [107.535676, 10.7202064],
                [107.5356817, 10.7200789],
                [107.5356817, 10.7190827],
                [107.5357046, 10.7188829],
                [107.5357032, 10.7187823],
                [107.5356926, 10.7186788],
                [107.5356729, 10.7185767],
                [107.5356294, 10.718424],
                [107.5356209, 10.7183702],
                [107.5356229, 10.7182613],
                [107.5356529, 10.718149],
                [107.5356799, 10.7180932],
                [107.5357549, 10.7179944],
                [107.5358534, 10.7179179],
                [107.5359788, 10.7178681],
                [107.53609, 10.7177924],
                [107.5361813, 10.7176944],
                [107.5362516, 10.7175717],
                [107.5362763, 10.7175013],
                [107.5362919, 10.7174284],
                [107.5362982, 10.7173542],
                [107.5362736, 10.7170941],
                [107.5362718, 10.7169815],
                [107.536289, 10.7167621],
                [107.5363321, 10.7165513],
                [107.5364006, 10.7163472],
                [107.5364651, 10.7162639],
                [107.536507, 10.7162314],
                [107.5365539, 10.7162062],
                [107.5366522, 10.7161809],
                [107.5367008, 10.7161799],
                [107.5367955, 10.7161996],
                [107.5368394, 10.7162199],
                [107.5369155, 10.7162786],
                [107.5369696, 10.7163101],
                [107.5370268, 10.7163356],
                [107.537148, 10.7163675],
                [107.5372792, 10.7163724],
                [107.5373472, 10.7163632],
                [107.5374774, 10.7163213],
                [107.5375378, 10.7162892],
                [107.5380716, 10.7159993],
                [107.5381352, 10.7159359],
                [107.53817, 10.7158538],
                [107.5381708, 10.7157647],
                [107.5381381, 10.7156828],
                [107.5381105, 10.7156478],
                [107.5380378, 10.7155963],
                [107.5379954, 10.7155816],
                [107.5378988, 10.7155666],
                [107.5378014, 10.7155275],
                [107.5377583, 10.7154976],
                [107.5376909, 10.7154247],
                [107.537649, 10.7153409],
                [107.5376313, 10.7152644],
                [107.5376368, 10.7152015],
                [107.5376478, 10.7151718],
                [107.5376873, 10.7151177],
                [107.537744, 10.7150814],
                [107.538026, 10.7149794],
                [107.5380846, 10.7149708],
                [107.5381439, 10.714969],
                [107.5382568, 10.7149849],
                [107.5383594, 10.7150233],
                [107.538499, 10.7150988],
                [107.538601, 10.7151224],
                [107.5387031, 10.7151243],
                [107.5387467, 10.7151014],
                [107.538763, 10.7150829],
                [107.5387801, 10.7150337],
                [107.5387682, 10.7149795],
                [107.5385892, 10.7148002],
                [107.538552, 10.714732],
                [107.5385347, 10.7146486],
                [107.5385455, 10.7145564],
                [107.5385624, 10.7145129],
                [107.5387663, 10.7140833],
                [107.5388328, 10.7139829],
                [107.5388734, 10.7139382],
                [107.5389674, 10.7138619],
                [107.5390817, 10.7138032],
                [107.5393032, 10.7137408],
                [107.5393891, 10.713688],
                [107.5394261, 10.7136537],
                [107.5394882, 10.7135657],
                [107.5395091, 10.713516],
                [107.5395226, 10.713464],
                [107.539539, 10.7133225],
                [107.5395883, 10.7131885],
                [107.5396581, 10.7130811],
                [107.5397701, 10.7129698],
                [107.5397972, 10.7129344],
                [107.5398311, 10.7128526],
                [107.5398343, 10.7127606],
                [107.5398234, 10.7127157],
                [107.5398044, 10.7126735],
                [107.5397453, 10.7126022],
                [107.5396827, 10.7124999],
                [107.539646, 10.7123861],
                [107.5396375, 10.7123174],
                [107.5396385, 10.7122481],
                [107.5396491, 10.7121797],
                [107.5396689, 10.7121132],
                [107.5396978, 10.7120501],
                [107.5397351, 10.7119913],
                [107.5397801, 10.7119381],
                [107.5398102, 10.7118548],
                [107.5398177, 10.7117668],
                [107.5398107, 10.711712],
                [107.5397706, 10.7116093],
                [107.5397386, 10.711564],
                [107.5393747, 10.7112371],
                [107.5393407, 10.71117],
                [107.5393339, 10.7111329],
                [107.5393419, 10.7110584],
                [107.5393563, 10.7110236],
                [107.5394037, 10.7109649],
                [107.5394697, 10.7109276],
                [107.5395451, 10.7109167],
                [107.539583, 10.7109219],
                [107.5396957, 10.7109612],
                [107.5397747, 10.7109806],
                [107.5398552, 10.7109919],
                [107.5399806, 10.7109933],
                [107.5400699, 10.7109839],
                [107.5401221, 10.7109961],
                [107.5401649, 10.7110305],
                [107.5401794, 10.7110547],
                [107.5401952, 10.7111397],
                [107.5402093, 10.7111659],
                [107.5402301, 10.7111887],
                [107.5402855, 10.7112159],
                [107.5405526, 10.711208],
                [107.5406173, 10.7111903],
                [107.5406706, 10.7111501],
                [107.5407072, 10.7110861],
                [107.5407135, 10.711013],
                [107.5406894, 10.7105808],
                [107.5406519, 10.7103251],
                [107.5406384, 10.710167],
                [107.5406203, 10.710108],
                [107.5405777, 10.7100627],
                [107.5405497, 10.7100483],
                [107.5404832, 10.7100401],
                [107.5404485, 10.7100487],
                [107.5404171, 10.7100657],
                [107.5403912, 10.7100901],
                [107.5403725, 10.7101201],
                [107.5403434, 10.7102421],
                [107.540317, 10.710295],
                [107.5402956, 10.7103157],
                [107.5402415, 10.710341],
                [107.5401801, 10.7103403],
                [107.5401253, 10.7103129],
                [107.5401041, 10.7102908],
                [107.540058, 10.7102008],
                [107.5400364, 10.7101336],
                [107.5400242, 10.7100642],
                [107.5400264, 10.7099383],
                [107.540039, 10.7098764],
                [107.5400872, 10.7097563],
                [107.5401635, 10.7096473],
                [107.5402106, 10.7096],
                [107.5404346, 10.7094291],
                [107.5405241, 10.7093854],
                [107.5406224, 10.7093671],
                [107.5407333, 10.7093786],
                [107.5408853, 10.7094387],
                [107.5409884, 10.7094553],
                [107.5410968, 10.7094496],
                [107.5411518, 10.7094375],
                [107.5412559, 10.7093953],
                [107.5413036, 10.7093658],
                [107.5414287, 10.7092715],
                [107.5415854, 10.709136],
                [107.541608, 10.709127],
                [107.5416534, 10.7091284],
                [107.5416895, 10.7091509],
                [107.5417019, 10.7091682],
                [107.5417466, 10.7092938],
                [107.5417689, 10.7093238],
                [107.5418276, 10.7093701],
                [107.5418991, 10.7093935],
                [107.5419427, 10.7093953],
                [107.5420265, 10.7093731],
                [107.5420632, 10.7093499],
                [107.5421187, 10.7092843],
                [107.5421351, 10.7092446],
                [107.5422064, 10.7089124],
                [107.5422481, 10.7088011],
                [107.5423149, 10.7086959],
                [107.5423582, 10.7086471],
                [107.5425018, 10.7085241],
                [107.5425656, 10.7084268],
                [107.5425857, 10.7083723],
                [107.5426004, 10.7082749],
                [107.5425897, 10.708177],
                [107.5425693, 10.7081031],
                [107.5425764, 10.7080494],
                [107.5425889, 10.708025],
                [107.5426286, 10.7079875],
                [107.5426558, 10.7079755],
                [107.5427147, 10.7079721],
                [107.5427685, 10.7079958],
                [107.5428636, 10.7080822],
                [107.542888, 10.7080951],
                [107.5429424, 10.708102],
                [107.5429966, 10.7080809],
                [107.5430342, 10.7080329],
                [107.5430578, 10.7078794],
                [107.5430896, 10.7078139],
                [107.5431363, 10.7077584],
                [107.5432152, 10.7077045],
                [107.5432418, 10.7076665],
                [107.5432426, 10.7076218],
                [107.5432194, 10.7075849],
                [107.5429934, 10.7074472],
                [107.5429763, 10.7074244],
                [107.5429624, 10.7073698],
                [107.5429783, 10.7073154],
                [107.5430201, 10.7072759],
                [107.5430475, 10.7072656],
                [107.5432936, 10.7072653],
                [107.5433593, 10.7072452],
                [107.5433877, 10.7072258],
                [107.5434291, 10.7071743],
                [107.5434473, 10.7071112],
                [107.5434484, 10.7067337],
                [107.5434727, 10.706654],
                [107.5435219, 10.7065763],
                [107.543598, 10.7065102],
                [107.5438738, 10.7063853],
                [107.5440448, 10.7062797],
                [107.5442297, 10.7061279],
                [107.5443365, 10.7060184],
                [107.5443548, 10.7059903],
                [107.5443736, 10.7059266],
                [107.5443734, 10.7058933],
                [107.5443524, 10.7058272],
                [107.5443313, 10.7057975],
                [107.5442728, 10.7057539],
                [107.544238, 10.705742],
                [107.5441473, 10.7057338],
                [107.5440621, 10.7057025],
                [107.5439884, 10.7056503],
                [107.5439313, 10.7055811],
                [107.5438946, 10.7054995],
                [107.5438753, 10.7054245],
                [107.5438538, 10.7052712],
                [107.5438559, 10.7051115],
                [107.5438667, 10.7050298],
                [107.5439206, 10.704831],
                [107.5439608, 10.7047536],
                [107.5439885, 10.7047168],
                [107.5440556, 10.7046535],
                [107.5441342, 10.7045991],
                [107.5441897, 10.7045218],
                [107.5442123, 10.7044525],
                [107.5442165, 10.7044163],
                [107.5442085, 10.7043872],
                [107.5441919, 10.7043618],
                [107.5441422, 10.7043318],
                [107.5440864, 10.7043332],
                [107.5438464, 10.7044901],
                [107.5437592, 10.7045384],
                [107.5435781, 10.7046219],
                [107.5435358, 10.7046273],
                [107.5434513, 10.7046176],
                [107.5434114, 10.7046027],
                [107.5433416, 10.7045549],
                [107.5432911, 10.7044875],
                [107.5432643, 10.7043977],
                [107.543272, 10.7043044],
                [107.5432887, 10.7042604],
                [107.5433451, 10.7041849],
                [107.5433828, 10.704156],
                [107.5436645, 10.7040341],
                [107.5437874, 10.703992],
                [107.5440931, 10.703913],
                [107.5441473, 10.7038929],
                [107.5441976, 10.7038647],
                [107.5442427, 10.703829],
                [107.5443131, 10.703739],
                [107.5443489, 10.703644],
                [107.5443562, 10.7035937],
                [107.544349, 10.7034926],
                [107.5441897, 10.7030643],
                [107.5441251, 10.7029504],
                [107.5440373, 10.7028527],
                [107.5439408, 10.7027818],
                [107.5438048, 10.7027108],
                [107.5437402, 10.7026471],
                [107.5436974, 10.7025676],
                [107.5436854, 10.702524],
                [107.5436824, 10.7024295],
                [107.5436922, 10.7023831],
                [107.5437331, 10.7022975],
                [107.5437632, 10.7022605],
                [107.5439099, 10.7021359],
                [107.5440603, 10.7020471],
                [107.5442058, 10.701989],
                [107.5443079, 10.7019143],
                [107.5443809, 10.7018256],
                [107.5444268, 10.7017328],
                [107.5444418, 10.7016833],
                [107.5444973, 10.7015996],
                [107.5445319, 10.7015629],
                [107.5446077, 10.7015055],
                [107.5447247, 10.7014533],
                [107.5447571, 10.7014323],
                [107.5448054, 10.701373],
                [107.5448249, 10.7012997],
                [107.5448125, 10.7012249],
                [107.5447703, 10.7011616],
                [107.5447402, 10.7011377],
                [107.5446682, 10.7011107],
                [107.5446296, 10.7011087],
                [107.5440878, 10.7009717],
                [107.5440482, 10.7009447],
                [107.5439832, 10.7008755],
                [107.543959, 10.7008346],
                [107.5439313, 10.7007524],
                [107.5439268, 10.7004656],
                [107.5439038, 10.7003811],
                [107.5438829, 10.7003423],
                [107.5438249, 10.7002759],
                [107.5437374, 10.7002242],
                [107.5436373, 10.7002044],
                [107.5435489, 10.7002067],
                [107.5434789, 10.7001824],
                [107.5434494, 10.7001599],
                [107.5434103, 10.7001046],
                [107.5433843, 10.6999748],
                [107.5433645, 10.699913],
                [107.5433035, 10.6998021],
                [107.5431993, 10.6996835],
                [107.5431793, 10.6996185],
                [107.5431794, 10.6995844],
                [107.5432, 10.6995195],
                [107.5432478, 10.6994649],
                [107.5433141, 10.6994344],
                [107.5433507, 10.69943],
                [107.5434226, 10.6994438],
                [107.5437579, 10.6995827],
                [107.5438624, 10.6996066],
                [107.5439161, 10.6996091],
                [107.544018, 10.6995959],
                [107.5440596, 10.6995786],
                [107.5440924, 10.699545],
                [107.5441089, 10.6994991],
                [107.544077, 10.6990951],
                [107.5440405, 10.6989845],
                [107.54401, 10.6989344],
                [107.5439302, 10.6988519],
                [107.5438295, 10.6987956],
                [107.5437263, 10.6987569],
                [107.5436822, 10.6987286],
                [107.5436106, 10.6986585],
                [107.5435621, 10.6985759],
                [107.5435195, 10.6984494],
                [107.5435084, 10.6983835],
                [107.5435215, 10.6983248],
                [107.5435567, 10.6982755],
                [107.5435826, 10.6982558],
                [107.5436439, 10.6982344],
                [107.5436767, 10.6982335],
                [107.5437391, 10.6982518],
                [107.5439767, 10.6982917],
                [107.5440613, 10.6982836],
                [107.544136, 10.6982439],
                [107.5441902, 10.6981769],
                [107.544213, 10.6980944],
                [107.5442004, 10.6978538],
                [107.5442351, 10.6977874],
                [107.5442612, 10.6977601],
                [107.5443265, 10.697722],
                [107.544405, 10.6977101],
                [107.5444823, 10.6977286],
                [107.5445166, 10.6977486],
                [107.5447342, 10.6979487],
                [107.5448101, 10.6979771],
                [107.5448911, 10.6979715],
                [107.5449622, 10.6979329],
                [107.5449901, 10.6979026],
                [107.5450106, 10.697867],
                [107.5450226, 10.6978278],
                [107.5450256, 10.697787],
                [107.5450194, 10.6977466],
                [107.5450042, 10.6977085],
                [107.5449809, 10.6976746],
                [107.5448012, 10.6974769],
                [107.5447785, 10.6974357],
                [107.5447644, 10.6973909],
                [107.5447594, 10.6973443],
                [107.5447637, 10.6972977],
                [107.544777, 10.6972529],
                [107.5447989, 10.6972115],
                [107.5448648, 10.697145],
                [107.5450542, 10.6970574],
                [107.5451436, 10.6969865],
                [107.5451821, 10.6969445],
                [107.5454503, 10.6964965],
                [107.5454633, 10.6964492],
                [107.545453, 10.6964013],
                [107.5454396, 10.6963805],
                [107.5453973, 10.6963495],
                [107.5453717, 10.6963426],
                [107.5453193, 10.6963482],
                [107.545276, 10.6963778],
                [107.5450899, 10.6964765],
                [107.5450248, 10.6964792],
                [107.5449934, 10.69647],
                [107.5449418, 10.6964343],
                [107.5449105, 10.6963828],
                [107.5449029, 10.6963232],
                [107.5449891, 10.6959962],
                [107.5450397, 10.6959249],
                [107.5450748, 10.6958982],
                [107.545148, 10.6958694],
                [107.5452269, 10.6958676],
                [107.5454342, 10.6959193],
                [107.5454854, 10.6959251],
                [107.5455871, 10.6959113],
                [107.5456702, 10.6958712],
                [107.5457753, 10.6957805],
                [107.5458622, 10.6957432],
                [107.545909, 10.6957348],
                [107.5459601, 10.6957337],
                [107.5460594, 10.6957565],
                [107.5461048, 10.6957796],
                [107.5461833, 10.6958577],
                [107.546213, 10.6958739],
                [107.5462799, 10.6958834],
                [107.5463435, 10.6958613],
                [107.5463911, 10.6958093],
                [107.5464067, 10.6957411],
                [107.5463864, 10.6956741],
                [107.5463382, 10.6956342],
                [107.5463105, 10.6955785],
                [107.5463082, 10.6955155],
                [107.5463173, 10.6954846],
                [107.5463543, 10.6954319],
                [107.5464102, 10.6953991],
                [107.5464422, 10.6953922],
                [107.5465123, 10.6953936],
                [107.5465834, 10.6953726],
                [107.5466385, 10.6953236],
                [107.5466673, 10.6952546],
                [107.5466625, 10.6951803],
                [107.5466251, 10.6951154],
                [107.5465849, 10.6950152],
                [107.5465634, 10.694877],
                [107.5465749, 10.6945886],
                [107.54655, 10.6945145],
                [107.5464995, 10.6944611],
                [107.5464669, 10.6944433],
                [107.5463408, 10.6943458],
                [107.5461032, 10.6941354],
                [107.5459278, 10.6940137],
                [107.5456645, 10.6938752],
                [107.5456381, 10.6938503],
                [107.5456043, 10.6937946],
                [107.5455869, 10.6937107],
                [107.5455878, 10.6936575],
                [107.5455978, 10.6936052],
                [107.5456369, 10.6935199],
                [107.5456656, 10.6934824],
                [107.545747, 10.6934048],
                [107.545774, 10.6933542],
                [107.5458103, 10.6933198],
                [107.545834, 10.693311],
                [107.5458838, 10.6933129],
                [107.5459251, 10.693339],
                [107.54594, 10.6933693],
                [107.5459558, 10.6933769],
                [107.5459872, 10.6933716],
                [107.5460042, 10.6933482],
                [107.5460069, 10.6933164],
                [107.5459921, 10.6932546],
                [107.5459701, 10.6932209],
                [107.5459117, 10.693165],
                [107.5458745, 10.6931432],
                [107.5457934, 10.693114],
                [107.5457507, 10.693107],
                [107.5456589, 10.6931098],
                [107.545468, 10.6931655],
                [107.5453631, 10.6931877],
                [107.5451499, 10.6932099],
                [107.5450119, 10.6932084],
                [107.5449825, 10.6931996],
                [107.5449346, 10.6931622],
                [107.5449192, 10.693136],
                [107.5449096, 10.6930861],
                [107.5449217, 10.6930366],
                [107.5450668, 10.6928514],
                [107.5451131, 10.6928031],
                [107.5451501, 10.6927315],
                [107.5451606, 10.6926853],
                [107.5451618, 10.6922685],
                [107.5451558, 10.6922263],
                [107.5451428, 10.6921856],
                [107.5450998, 10.6921163],
                [107.5450401, 10.6920656],
                [107.5450051, 10.6920475],
                [107.5443412, 10.6919117],
                [107.5442779, 10.6918731],
                [107.5442534, 10.6918453],
                [107.5442234, 10.6917783],
                [107.5442192, 10.6917418],
                [107.5442339, 10.6916679],
                [107.5442524, 10.6916347],
                [107.544308, 10.6915829],
                [107.5444172, 10.6915375],
                [107.5444763, 10.6914788],
                [107.5444961, 10.6914423],
                [107.5445127, 10.6913614],
                [107.5445089, 10.6913201],
                [107.5444776, 10.6912437],
                [107.5444193, 10.6911845],
                [107.5443426, 10.6911514],
                [107.5440842, 10.6911033],
                [107.5438314, 10.691071],
                [107.5437166, 10.6910262],
                [107.5436641, 10.6909942],
                [107.5435705, 10.6909112],
                [107.5434986, 10.6908064],
                [107.5434548, 10.6906875],
                [107.5434118, 10.6904371],
                [107.5433829, 10.6903814],
                [107.5433605, 10.6903589],
                [107.5433046, 10.6903291],
                [107.5432289, 10.6903249],
                [107.5431924, 10.6903361],
                [107.5431309, 10.6903707],
                [107.5430667, 10.690381],
                [107.5430041, 10.6903633],
                [107.5429773, 10.6903449],
                [107.5429378, 10.6902903],
                [107.5429273, 10.6902242],
                [107.5429338, 10.6901911],
                [107.5430956, 10.6898151],
                [107.5431376, 10.6896498],
                [107.5431628, 10.6894161],
                [107.5432008, 10.6893389],
                [107.5432596, 10.6892821],
                [107.5433295, 10.6892486],
                [107.5433675, 10.6892403],
                [107.543778, 10.6892603],
                [107.543813, 10.689244],
                [107.5438308, 10.689213],
                [107.5438187, 10.6891424],
                [107.5437792, 10.689058],
                [107.5436291, 10.688861],
                [107.5435081, 10.6886232],
                [107.5434849, 10.6886011],
                [107.5434282, 10.6885738],
                [107.5433382, 10.6885669],
                [107.5433125, 10.6885552],
                [107.5432911, 10.6885368],
                [107.5432707, 10.6884997],
                [107.5432641, 10.6884223],
                [107.5432841, 10.6883594],
                [107.5433059, 10.6883281],
                [107.5434762, 10.6881771],
                [107.5434941, 10.688153],
                [107.5435085, 10.6880956],
                [107.5434923, 10.6880387],
                [107.5434504, 10.6879975],
                [107.5434229, 10.6879859],
                [107.5433636, 10.6879847],
                [107.5432335, 10.688011],
                [107.5430296, 10.6880991],
                [107.542929, 10.6881151],
                [107.5428741, 10.6881134],
                [107.5427675, 10.6880882],
                [107.5427177, 10.6880653],
                [107.5425993, 10.6879846],
                [107.5424409, 10.6879043],
                [107.5422973, 10.6878618],
                [107.5421485, 10.6878436],
                [107.5420873, 10.6878198],
                [107.5420412, 10.6877735],
                [107.5420265, 10.6877443],
                [107.5420171, 10.6876802],
                [107.5420355, 10.6876171],
                [107.5420788, 10.6875671],
                [107.5421393, 10.6875392],
                [107.5423577, 10.6875247],
                [107.5425942, 10.6874994],
                [107.5426427, 10.6874693],
                [107.5426588, 10.6874457],
                [107.5426689, 10.6873903],
                [107.5426618, 10.687362],
                [107.5426255, 10.6873167],
                [107.5424016, 10.6872066],
                [107.5422451, 10.6871057],
                [107.5421561, 10.6870738],
                [107.5421088, 10.6870688],
                [107.5420271, 10.687078],
                [107.5419621, 10.687103],
                [107.5419129, 10.6871373],
                [107.5418589, 10.6871636],
                [107.5417583, 10.6871887],
                [107.5417188, 10.6871827],
                [107.5417022, 10.6871714],
                [107.5416835, 10.6871397],
                [107.5416912, 10.6870898],
                [107.5417368, 10.6870226],
                [107.5417996, 10.6869728],
                [107.5418763, 10.6869421],
                [107.5419452, 10.6868939],
                [107.5419903, 10.6868236],
                [107.542002, 10.6867814],
                [107.5419983, 10.6866945],
                [107.5419698, 10.6866179],
                [107.5418962, 10.6864819],
                [107.5418052, 10.6863677],
                [107.5417795, 10.6863207],
                [107.5417757, 10.6862675],
                [107.5417844, 10.6862363],
                [107.5418239, 10.6861853],
                [107.5418521, 10.6861687],
                [107.5422203, 10.6860235],
                [107.5422838, 10.6859754],
                [107.5423229, 10.6859196],
                [107.5424262, 10.6856675],
                [107.542476, 10.6856121],
                [107.5425433, 10.6855774],
                [107.5425976, 10.6855342],
                [107.5426304, 10.6854735],
                [107.5426362, 10.6854051],
                [107.5426145, 10.6853402],
                [107.5425692, 10.6852891],
                [107.5425397, 10.6852711],
                [107.5424729, 10.6852539],
                [107.5422396, 10.6852646],
                [107.5422049, 10.6852598],
                [107.5421409, 10.6852323],
                [107.5421137, 10.6852106],
                [107.5420701, 10.6851479],
                [107.5420171, 10.6848679],
                [107.5419474, 10.6846887],
                [107.5418951, 10.6846147],
                [107.5418785, 10.6845725],
                [107.5418669, 10.6844831],
                [107.5418842, 10.6843953],
                [107.5419032, 10.6843544],
                [107.5419286, 10.6843171],
                [107.5420131, 10.6842434],
                [107.5420717, 10.6841485],
                [107.5420988, 10.6840443],
                [107.5421006, 10.6839921],
                [107.5420827, 10.6838725],
                [107.5420466, 10.6838137],
                [107.5420186, 10.6837882],
                [107.5419502, 10.683756],
                [107.5419125, 10.6837504],
                [107.541462, 10.6837431],
                [107.5414198, 10.6837361],
                [107.5413396, 10.6837074],
                [107.5412688, 10.6836608],
                [107.5411212, 10.6835237],
                [107.5410263, 10.6834479],
                [107.5409281, 10.6833761],
                [107.5406597, 10.683205],
                [107.5406055, 10.6831465],
                [107.5405697, 10.6830788],
                [107.5405526, 10.6830071],
                [107.5405392, 10.6828411],
                [107.5405154, 10.6827747],
                [107.5404239, 10.6826113],
                [107.5403964, 10.6824984],
                [107.5403951, 10.6824402],
                [107.5404127, 10.6823423],
                [107.5404308, 10.6823049],
                [107.5404792, 10.6822504],
                [107.5405338, 10.6822191],
                [107.5407401, 10.6821865],
                [107.5407917, 10.6821527],
                [107.5408235, 10.6821004],
                [107.5408299, 10.682044],
                [107.5407914, 10.6819306],
                [107.5407645, 10.6818079],
                [107.5407587, 10.6817276],
                [107.5407638, 10.6816055],
                [107.5407565, 10.6815644],
                [107.5407404, 10.6815258],
                [107.5407162, 10.6814916],
                [107.540684, 10.6814625],
                [107.5406461, 10.6814412],
                [107.5406043, 10.6814287],
                [107.5402361, 10.6814046],
                [107.5401151, 10.6813642],
                [107.5400592, 10.6813337],
                [107.5399602, 10.6812542],
                [107.5398831, 10.6811541],
                [107.5398323, 10.6810389],
                [107.5398106, 10.6809149],
                [107.5398193, 10.680789],
                [107.5398351, 10.6807278],
                [107.5398883, 10.6806131],
                [107.539925, 10.6805612],
                [107.5404051, 10.6800577],
                [107.5404231, 10.6800257],
                [107.5404355, 10.6799541],
                [107.5404292, 10.6799181],
                [107.5403914, 10.6798521],
                [107.5403273, 10.67981],
                [107.5400551, 10.6797697],
                [107.5399403, 10.6797273],
                [107.5398344, 10.6796569],
                [107.5397867, 10.679611],
                [107.5396934, 10.6794948],
                [107.5396235, 10.679441],
                [107.5395817, 10.6794199],
                [107.5393821, 10.6793665],
                [107.5392891, 10.6793241],
                [107.5392464, 10.679296],
                [107.5391543, 10.6792082],
                [107.5391176, 10.6791563],
                [107.5389556, 10.6788571],
                [107.5389167, 10.6788248],
                [107.5388922, 10.6788172],
                [107.5388414, 10.6788216],
                [107.5388154, 10.6788356],
                [107.5387817, 10.6788825],
                [107.5387423, 10.6791214],
                [107.5386993, 10.6792747],
                [107.5386348, 10.6794208],
                [107.5385501, 10.6795569],
                [107.5384819, 10.6796456],
                [107.5384276, 10.679678],
                [107.5383642, 10.6796792],
                [107.5383344, 10.6796677],
                [107.5382925, 10.6796297],
                [107.5382727, 10.6795772],
                [107.5383227, 10.6791265],
                [107.538313, 10.6790193],
                [107.5382864, 10.6789456],
                [107.5381983, 10.6788164],
                [107.5381749, 10.6787627],
                [107.5381529, 10.6786481],
                [107.5381547, 10.6785896],
                [107.5381797, 10.6784871],
                [107.5382308, 10.6783944],
                [107.5383046, 10.6783179],
                [107.5384779, 10.678215],
                [107.5385418, 10.6781456],
                [107.5385651, 10.6781047],
                [107.5385926, 10.6780103],
                [107.5385892, 10.6779122],
                [107.5386129, 10.6778031],
                [107.5386348, 10.6777515],
                [107.5386943, 10.6776617],
                [107.5387735, 10.6775883],
                [107.5388193, 10.6775591],
                [107.5394459, 10.6772864],
                [107.5394959, 10.6772455],
                [107.539524, 10.6771877],
                [107.5395247, 10.6771225],
                [107.5395141, 10.6770908],
                [107.5394729, 10.6770382],
                [107.5394445, 10.6770199],
                [107.5390754, 10.6768602],
                [107.5389915, 10.6767951],
                [107.5389567, 10.6767551],
                [107.5389028, 10.6766603],
                [107.5388762, 10.6765548],
                [107.5388736, 10.6762438],
                [107.5389065, 10.6761495],
                [107.5389338, 10.6761073],
                [107.539004, 10.6760398],
                [107.5390885, 10.6759971],
                [107.5393528, 10.675951],
                [107.5394938, 10.6759114],
                [107.5395479, 10.6758859],
                [107.5396449, 10.6758167],
                [107.5396863, 10.6757739],
                [107.5397517, 10.6756751],
                [107.5397747, 10.6756206],
                [107.5398002, 10.6755009],
                [107.5398012, 10.6754374],
                [107.5397778, 10.6753127],
                [107.5397223, 10.6751982],
                [107.5395226, 10.674947],
                [107.5394797, 10.6748495],
                [107.5394721, 10.6747669],
                [107.539477, 10.6747256],
                [107.5395092, 10.6745938],
                [107.5395237, 10.6745678],
                [107.5395711, 10.6745322],
                [107.5396004, 10.6745253],
                [107.5396264, 10.6745259],
                [107.5396742, 10.6745448],
                [107.5397077, 10.6745833],
                [107.5398418, 10.6747968],
                [107.5399464, 10.6748995],
                [107.5400473, 10.6749626],
                [107.5402083, 10.6750388],
                [107.5403112, 10.6750972],
                [107.5403373, 10.6751253],
                [107.5404038, 10.6751637],
                [107.5404802, 10.6751737],
                [107.5405498, 10.675156],
                [107.5406081, 10.6751146],
                [107.5406871, 10.6749994],
                [107.5407687, 10.6749168],
                [107.5408767, 10.6748478],
                [107.5409362, 10.6748231],
                [107.5410218, 10.6748043],
                [107.5410598, 10.6747823],
                [107.5411183, 10.674718],
                [107.5411362, 10.6746784],
                [107.541146, 10.6745985],
                [107.5411271, 10.6745261],
                [107.5410836, 10.6744649],
                [107.5410178, 10.6744149],
                [107.5409557, 10.6743512],
                [107.5409074, 10.6742675],
                [107.5408816, 10.6741663],
                [107.5408798, 10.6741141],
                [107.540895, 10.6740379],
                [107.5409489, 10.6738921],
                [107.5409871, 10.6738241],
                [107.541074, 10.6737119],
                [107.5411251, 10.6736623],
                [107.5412405, 10.6735784],
                [107.5414027, 10.6735004],
                [107.5415048, 10.6734635],
                [107.5417259, 10.6734107],
                [107.5418439, 10.6733967],
                [107.5419627, 10.6733922],
                [107.5420815, 10.6733971],
                [107.5426863, 10.6734499],
                [107.5427631, 10.6734692],
                [107.5427974, 10.673489],
                [107.5428518, 10.6735456],
                [107.5428815, 10.6736183],
                [107.5428854, 10.6736578],
                [107.5428703, 10.6737353],
                [107.542852, 10.6737705],
                [107.5428048, 10.6738317],
                [107.542775, 10.6738997],
                [107.5427672, 10.6739776],
                [107.5427862, 10.6740581],
                [107.5428091, 10.6740857],
                [107.5428707, 10.6741226],
                [107.5429395, 10.6741295],
                [107.5430022, 10.674109],
                [107.5430511, 10.6740653],
                [107.5430995, 10.6739841],
                [107.5431217, 10.6738924],
                [107.5431207, 10.6737885],
                [107.5431383, 10.6736765],
                [107.5431763, 10.6735828],
                [107.5432026, 10.6735395],
                [107.5432038, 10.6734801],
                [107.5431919, 10.6734525],
                [107.5431433, 10.6733927],
                [107.5431202, 10.6733196],
                [107.5431271, 10.6732394],
                [107.5431674, 10.6731648],
                [107.5432348, 10.6731125],
                [107.543385, 10.673094],
                [107.5435287, 10.6730987],
                [107.5435875, 10.6730799],
                [107.543613, 10.6730624],
                [107.5436546, 10.6730078],
                [107.5436653, 10.6729751],
                [107.5436693, 10.6727435],
                [107.5436902, 10.6726285],
                [107.5437133, 10.6725746],
                [107.5437769, 10.6724859],
                [107.5438556, 10.6724219],
                [107.5439006, 10.6723979],
                [107.5439483, 10.6723797],
                [107.5442876, 10.6722971],
                [107.5443987, 10.6722438],
                [107.5444892, 10.6721616],
                [107.5445515, 10.6720577],
                [107.5446135, 10.6718763],
                [107.544643, 10.6718194],
                [107.5447852, 10.6716063],
                [107.5448442, 10.6714824],
                [107.5448549, 10.6714009],
                [107.544836, 10.6713209],
                [107.5447899, 10.6712524],
                [107.5447583, 10.6712252],
                [107.5446758, 10.6711874],
                [107.5446305, 10.6711809],
                [107.5445848, 10.6711832],
                [107.544499, 10.6712131],
                [107.5444311, 10.6712727],
                [107.5441864, 10.6715613],
                [107.5441461, 10.6715977],
                [107.5440515, 10.6716514],
                [107.5439451, 10.6716758],
                [107.543836, 10.6716689],
                [107.5437835, 10.6716537],
                [107.5436862, 10.6716004],
                [107.543608, 10.6715194],
                [107.5435792, 10.6714711],
                [107.5435456, 10.6713645],
                [107.5435463, 10.6712529],
                [107.5435595, 10.6711985],
                [107.5436103, 10.6710987],
                [107.5437307, 10.6709437],
                [107.5438634, 10.6708104],
                [107.5439816, 10.6706757],
                [107.5440883, 10.6705167],
                [107.5441611, 10.6703828],
                [107.544193, 10.6703436],
                [107.5442741, 10.6702829],
                [107.5443701, 10.6702513],
                [107.5444706, 10.6702513],
                [107.5445701, 10.6702771],
                [107.5446223, 10.6702817],
                [107.5446744, 10.6702768],
                [107.5447248, 10.6702627],
                [107.5447718, 10.6702397],
                [107.5448136, 10.6702088],
                [107.544849, 10.6701708],
                [107.5448768, 10.6701271],
                [107.5448959, 10.6700791],
                [107.5449058, 10.6700286],
                [107.5448979, 10.6699299],
                [107.5448808, 10.6698832],
                [107.5448231, 10.6698021],
                [107.5447843, 10.6697703],
                [107.5446927, 10.6697291],
                [107.5446428, 10.6697211],
                [107.5445922, 10.6697218],
                [107.5444955, 10.6697492],
                [107.5443972, 10.6697574],
                [107.5443001, 10.6697404],
                [107.5442541, 10.6697228],
                [107.5441591, 10.6696608],
                [107.5441198, 10.6696199],
                [107.544062, 10.6695234],
                [107.5440448, 10.6694698],
                [107.5438994, 10.668631],
                [107.543899, 10.6684809],
                [107.5439099, 10.6684065],
                [107.5439537, 10.6682626],
                [107.5439894, 10.6681881],
                [107.5440842, 10.6680525],
                [107.5442061, 10.6679397],
                [107.5442755, 10.6678935],
                [107.5443843, 10.6678402],
                [107.5444037, 10.6678212],
                [107.5444211, 10.6677723],
                [107.5444085, 10.6677235],
                [107.5443922, 10.6677038],
                [107.54433, 10.6676598],
                [107.5442738, 10.6676086],
                [107.5442246, 10.6675509],
                [107.544161, 10.6674453],
                [107.5441377, 10.6673883],
                [107.5441204, 10.6673293],
                [107.5441033, 10.6672075],
                [107.5441073, 10.6670845],
                [107.5441173, 10.6670237],
                [107.5441651, 10.6669122],
                [107.5441976, 10.6668607],
                [107.5442813, 10.6667659],
                [107.5443323, 10.6667243],
                [107.5444468, 10.6666591],
                [107.5445089, 10.6666363],
                [107.5447172, 10.6665832],
                [107.5448491, 10.6665155],
                [107.5449625, 10.6664208],
                [107.5450104, 10.6663647],
                [107.545084, 10.6662426],
                [107.5451291, 10.6661077],
                [107.5451403, 10.6660375],
                [107.5451428, 10.6659309],
                [107.545128, 10.6658415],
                [107.5451102, 10.6657891],
                [107.5450366, 10.6656552],
                [107.5450231, 10.6656154],
                [107.5450156, 10.6655232],
                [107.5450249, 10.6654733],
                [107.5450432, 10.6654259],
                [107.5451352, 10.6653016],
                [107.5451767, 10.665205],
                [107.5451889, 10.6651018],
                [107.5451838, 10.66505],
                [107.5451543, 10.6649345],
                [107.5451543, 10.6649008],
                [107.5451714, 10.6648359],
                [107.5452088, 10.6647809],
                [107.5452626, 10.664741],
                [107.5453354, 10.6647253],
                [107.545652, 10.6647931],
                [107.5457936, 10.6648148],
                [107.5459345, 10.66483],
                [107.5462174, 10.6648412],
                [107.5462957, 10.6648276],
                [107.5463318, 10.6648107],
                [107.5463918, 10.6647595],
                [107.5464165, 10.6647218],
                [107.5464328, 10.6646799],
                [107.5464401, 10.6646356],
                [107.5464293, 10.6643404],
                [107.5463851, 10.6642755],
                [107.5463179, 10.664234],
                [107.5462369, 10.6642232],
                [107.5461955, 10.6642311],
                [107.5461568, 10.6642478],
                [107.5461228, 10.6642724],
                [107.5460952, 10.6643038],
                [107.5460598, 10.6643711],
                [107.5460135, 10.664422],
                [107.5459487, 10.6644545],
                [107.5458731, 10.6644608],
                [107.5458009, 10.6644381],
                [107.5457166, 10.6643863],
                [107.5456032, 10.6643404],
                [107.5454155, 10.6643035],
                [107.545383, 10.6642902],
                [107.5453295, 10.6642458],
                [107.5453109, 10.6642165],
                [107.5452934, 10.6641456],
                [107.5452968, 10.6641091],
                [107.5453423, 10.663952],
                [107.5453682, 10.663828],
                [107.5454047, 10.6634943],
                [107.5454164, 10.6634565],
                [107.5454354, 10.6634218],
                [107.5454855, 10.6633712],
                [107.5455425, 10.6633432],
                [107.5456333, 10.6633179],
                [107.5456783, 10.6632755],
                [107.5456999, 10.6632136],
                [107.5456841, 10.6631064],
                [107.5456877, 10.6630637],
                [107.5457095, 10.6629953],
                [107.5457439, 10.6629492],
                [107.5457936, 10.6629196],
                [107.5458279, 10.6629118],
                [107.545897, 10.6629205],
                [107.5459546, 10.6629592],
                [107.5459733, 10.6629926],
                [107.546026, 10.6630482],
                [107.5460941, 10.6630844],
                [107.5461346, 10.6630943],
                [107.5462177, 10.6630928],
                [107.5462578, 10.6630814],
                [107.5463736, 10.6630245],
                [107.5464163, 10.6630143],
                [107.5465006, 10.663013],
                [107.5465403, 10.663021],
                [107.5466144, 10.6630528],
                [107.5466352, 10.663075],
                [107.5466546, 10.6631318],
                [107.5466529, 10.6631566],
                [107.5466327, 10.6632016],
                [107.5465943, 10.6632333],
                [107.5464381, 10.6632955],
                [107.5463734, 10.663346],
                [107.5463489, 10.6633789],
                [107.5463194, 10.6634547],
                [107.5463196, 10.6635412],
                [107.5463321, 10.6635829],
                [107.5463798, 10.6636556],
                [107.5464132, 10.6636841],
                [107.5464681, 10.6637133],
                [107.5465872, 10.6637492],
                [107.5466493, 10.6637552],
                [107.5467627, 10.6637453],
                [107.5468705, 10.6637089],
                [107.5469202, 10.6636814],
                [107.5470677, 10.6635707],
                [107.5471911, 10.6634969],
                [107.5472256, 10.6634585],
                [107.5472363, 10.663435],
                [107.5472418, 10.6633773],
                [107.5472169, 10.6633174],
                [107.5470235, 10.6631658],
                [107.547008, 10.6631378],
                [107.5470017, 10.6630776],
                [107.5470252, 10.6630246],
                [107.5471455, 10.6629223],
                [107.5471972, 10.662856],
                [107.547219, 10.6627856],
                [107.5472204, 10.6626893],
                [107.5472292, 10.6626577],
                [107.5472642, 10.6626024],
                [107.5472892, 10.6625807],
                [107.5473493, 10.6625533],
                [107.5474262, 10.6625519],
                [107.5474629, 10.6625638],
                [107.5475239, 10.6626098],
                [107.5475585, 10.6626771],
                [107.5475818, 10.66278],
                [107.5475838, 10.6628815],
                [107.5475664, 10.6630132],
                [107.5475875, 10.663081],
                [107.5476078, 10.6631104],
                [107.5476643, 10.6631545],
                [107.5477368, 10.6631736],
                [107.547776, 10.6631714],
                [107.5478485, 10.6631427],
                [107.5479018, 10.6630866],
                [107.547918, 10.6630514],
                [107.5480092, 10.6627351],
                [107.548103, 10.662519],
                [107.5481589, 10.6624642],
                [107.5482318, 10.6624346],
                [107.5483064, 10.662434],
                [107.5483761, 10.66246],
                [107.5484429, 10.6625079],
                [107.5484832, 10.6625279],
                [107.548526, 10.6625419],
                [107.5486157, 10.6625502],
                [107.5487038, 10.6625322],
                [107.5487871, 10.6624859],
                [107.5488513, 10.6624163],
                [107.5488742, 10.6623749],
                [107.5488987, 10.6622841],
                [107.5488996, 10.6622369],
                [107.5488889, 10.6615253],
                [107.5488685, 10.6613968],
                [107.548846, 10.6613355],
                [107.5487849, 10.6612322],
                [107.5487456, 10.6611865],
                [107.5487012, 10.6611457],
                [107.5485819, 10.6610638],
                [107.5484866, 10.6609559],
                [107.5484264, 10.6608427],
                [107.5483608, 10.6606708],
                [107.5482938, 10.6605651],
                [107.5482516, 10.6605154],
                [107.548154, 10.6604287],
                [107.5480481, 10.6603767],
                [107.5479616, 10.6602972],
                [107.5479281, 10.6602491],
                [107.5478842, 10.6601434],
                [107.5478721, 10.6600322],
                [107.5478778, 10.6599764],
                [107.5479126, 10.6598699],
                [107.5481272, 10.6595114],
                [107.5482126, 10.659436],
                [107.5483149, 10.6593849],
                [107.548432, 10.6593611],
                [107.5485203, 10.6593637],
                [107.5485721, 10.6593858],
                [107.54861, 10.6594271],
                [107.5486232, 10.6594587],
                [107.5486231, 10.6595265],
                [107.5485885, 10.6595852],
                [107.5485402, 10.6596959],
                [107.5485293, 10.6597266],
                [107.5485279, 10.6597911],
                [107.5485375, 10.6598222],
                [107.5485751, 10.6598752],
                [107.548602, 10.659895],
                [107.5486656, 10.6599158],
                [107.5487321, 10.6599088],
                [107.5487627, 10.659895],
                [107.5487897, 10.6598752],
                [107.5489781, 10.6596854],
                [107.549062, 10.6596352],
                [107.5492028, 10.6595916],
                [107.5492389, 10.6595523],
                [107.5492511, 10.6595004],
                [107.5492361, 10.6594489],
                [107.5489721, 10.659174],
                [107.548937, 10.6591134],
                [107.5489238, 10.6590449],
                [107.5489358, 10.6589696],
                [107.5489741, 10.6589032],
                [107.5490292, 10.6588592],
                [107.549069, 10.6588092],
                [107.5490792, 10.6587788],
                [107.5490776, 10.6587164],
                [107.5490491, 10.6586618],
                [107.5490263, 10.6586405],
                [107.5488939, 10.6585844],
                [107.5488356, 10.6585281],
                [107.5488031, 10.6584544],
                [107.5488007, 10.6583758],
                [107.5488107, 10.6583376],
                [107.5488809, 10.6582177],
                [107.548921, 10.6581275],
                [107.5489781, 10.658074],
                [107.5490525, 10.6580485],
                [107.5491297, 10.658055],
                [107.5491975, 10.6580917],
                [107.5493261, 10.658262],
                [107.549401, 10.6583033],
                [107.5494858, 10.658317],
                [107.5495287, 10.6583129],
                [107.5496075, 10.6582842],
                [107.5496709, 10.6582327],
                [107.5496952, 10.6581999],
                [107.5497258, 10.6581249],
                [107.549736, 10.6578725],
                [107.5497114, 10.6577933],
                [107.5496882, 10.6577585],
                [107.549619, 10.657702],
                [107.5495771, 10.6576851],
                [107.5494014, 10.6576493],
                [107.5493384, 10.6576264],
                [107.5492094, 10.6575561],
                [107.5491463, 10.6575071],
                [107.5490893, 10.6574513],
                [107.5490391, 10.6573895],
                [107.5490238, 10.6573593],
                [107.5490076, 10.657294],
                [107.5490126, 10.6572214],
                [107.5490438, 10.6571491],
                [107.5490685, 10.6571181],
                [107.5490981, 10.6570916],
                [107.5492093, 10.6570268],
                [107.5492591, 10.6569862],
                [107.5493794, 10.6568538],
                [107.5494418, 10.6568178],
                [107.5495185, 10.6568008],
                [107.5495611, 10.6568018],
                [107.5496426, 10.6568254],
                [107.5497084, 10.6568601],
                [107.5497825, 10.6568686],
                [107.5498546, 10.6568497],
                [107.5499173, 10.6568031],
                [107.549958, 10.6567326],
                [107.5499661, 10.656652],
                [107.5499573, 10.6566122],
                [107.5498716, 10.6564427],
                [107.5498446, 10.6563909],
                [107.5498158, 10.6562783],
                [107.5498146, 10.6562202],
                [107.5498223, 10.6561625],
                [107.5498583, 10.6560637],
                [107.54992, 10.6559782],
                [107.5501576, 10.6557895],
                [107.5502193, 10.6557059],
                [107.5502551, 10.6556088],
                [107.5502616, 10.6555015],
                [107.5502347, 10.6553929],
                [107.550209, 10.6553428],
                [107.5501361, 10.655257],
                [107.5501061, 10.6552097],
                [107.5500642, 10.6551061],
                [107.550053, 10.6550514],
                [107.5500517, 10.6549329],
                [107.5500626, 10.6548745],
                [107.5501065, 10.6547642],
                [107.5501798, 10.6546515],
                [107.5502278, 10.6545942],
                [107.5503427, 10.6544978],
                [107.5504685, 10.6544335],
                [107.5506685, 10.6543816],
                [107.5507216, 10.6543447],
                [107.55076, 10.6542876],
                [107.5507707, 10.6542524],
                [107.5507691, 10.6541794],
                [107.5507514, 10.6541038],
                [107.5507544, 10.6540627],
                [107.5507658, 10.654023],
                [107.5508077, 10.6539588],
                [107.5508654, 10.653917],
                [107.550899, 10.6539043],
                [107.5509343, 10.6538975],
                [107.5510623, 10.6538982],
                [107.5511503, 10.6538721],
                [107.5511903, 10.6538494],
                [107.5512548, 10.6537902],
                [107.5512798, 10.6537543],
                [107.5513122, 10.6536736],
                [107.551319, 10.6536306],
                [107.5513201, 10.6534846],
                [107.5513136, 10.6534118],
                [107.5512788, 10.6532378],
                [107.5512625, 10.6532084],
                [107.5512144, 10.6531614],
                [107.5511802, 10.6531439],
                [107.5510562, 10.6531194],
                [107.5509664, 10.6530767],
                [107.5509262, 10.6530475],
                [107.5508526, 10.6529685],
                [107.5508, 10.6528646],
                [107.5507799, 10.6527502],
                [107.550796, 10.6523205],
                [107.5507818, 10.6521979],
                [107.5507638, 10.6521386],
                [107.5507137, 10.6520387],
                [107.5506074, 10.6518963],
                [107.5505564, 10.6517758],
                [107.5505417, 10.651712],
                [107.5505348, 10.6515819],
                [107.5505581, 10.6514537],
                [107.5505808, 10.6513925],
                [107.5506465, 10.6512795],
                [107.5506887, 10.6512292],
                [107.5511358, 10.6508083],
                [107.5511927, 10.6507232],
                [107.5512117, 10.6506757],
                [107.5512289, 10.6505841],
                [107.551201, 10.6501327],
                [107.5512252, 10.6500457],
                [107.5512814, 10.6499745],
                [107.5513479, 10.6499346],
                [107.551424, 10.6499181],
                [107.5515014, 10.649927],
                [107.5516511, 10.6499168],
                [107.5517942, 10.6498722],
                [107.5519226, 10.6497958],
                [107.5520291, 10.6496918],
                [107.5520743, 10.6496278],
                [107.5521111, 10.6495589],
                [107.5521387, 10.6494859],
                [107.5521569, 10.6494101],
                [107.5521654, 10.6493327],
                [107.5521658, 10.6491963],
                [107.5521757, 10.6491385],
                [107.5522188, 10.6490296],
                [107.5522512, 10.6489804],
                [107.5523394, 10.6488933],
                [107.5523947, 10.6488585],
                [107.5524547, 10.6488321],
                [107.5525181, 10.6488147],
                [107.5525833, 10.6488068],
                [107.55321, 10.6488568],
                [107.5533007, 10.648833],
                [107.5533778, 10.6487804],
                [107.5534322, 10.6487051],
                [107.5534487, 10.6486618],
                [107.553458, 10.6485709],
                [107.5534356, 10.6484822],
                [107.5534133, 10.648442],
                [107.55334, 10.6483524],
                [107.5533241, 10.6482851],
                [107.553328, 10.6482505],
                [107.5533582, 10.6481887],
                [107.5533831, 10.6481643],
                [107.5535145, 10.6480985],
                [107.553564, 10.6480397],
                [107.5535863, 10.6479726],
                [107.553588, 10.6479372],
                [107.5535721, 10.6478683],
                [107.5535457, 10.647809],
                [107.5535506, 10.6477444],
                [107.5535647, 10.6477148],
                [107.5536123, 10.6476699],
                [107.5536429, 10.6476571],
                [107.5537088, 10.6476548],
                [107.5538285, 10.6476844],
                [107.553964, 10.6477412],
                [107.5540797, 10.6477768],
                [107.5541507, 10.6477761],
                [107.5541846, 10.6477655],
                [107.5542428, 10.6477256],
                [107.5542806, 10.6476668],
                [107.5542899, 10.647633],
                [107.5542902, 10.6475385],
                [107.5543043, 10.6474202],
                [107.5543402, 10.647311],
                [107.554366, 10.6472594],
                [107.5543771, 10.6472089],
                [107.5543713, 10.6471537],
                [107.5543608, 10.6471258],
                [107.5543258, 10.6470775],
                [107.5539046, 10.6468324],
                [107.5538831, 10.6468138],
                [107.5538593, 10.646763],
                [107.5538591, 10.6467348],
                [107.5538794, 10.6466865],
                [107.5539216, 10.6466546],
                [107.5539476, 10.6466478],
                [107.5540662, 10.6466414],
                [107.5541809, 10.6466109],
                [107.5542453, 10.6465819],
                [107.554307, 10.6465308],
                [107.5543418, 10.6465108],
                [107.554428, 10.6464805],
                [107.5545644, 10.6464674],
                [107.5546557, 10.6464686],
                [107.5548948, 10.6464964],
                [107.5550789, 10.6464977],
                [107.5552285, 10.646481],
                [107.5553377, 10.64644],
                [107.5553868, 10.6464086],
                [107.555469, 10.646327],
                [107.5555247, 10.6462261],
                [107.5555496, 10.6461119],
                [107.5555492, 10.6460533],
                [107.5555231, 10.6459394],
                [107.5554978, 10.6458864],
                [107.5553761, 10.645724],
                [107.5552966, 10.6455732],
                [107.5552672, 10.6454932],
                [107.5552328, 10.6453466],
                [107.5552243, 10.6452718],
                [107.555233, 10.6451846],
                [107.5552484, 10.6451434],
                [107.5552994, 10.6450715],
                [107.5553757, 10.6450187],
                [107.5554661, 10.6449954],
                [107.5555129, 10.6449959],
                [107.5556027, 10.6450212],
                [107.5560355, 10.6452469],
                [107.5560718, 10.6452567],
                [107.5561466, 10.6452523],
                [107.5561815, 10.6452383],
                [107.5562125, 10.6452173],
                [107.5562555, 10.6451618],
                [107.5562721, 10.6450981],
                [107.5562629, 10.645033],
                [107.5562489, 10.6450029],
                [107.5562041, 10.6449478],
                [107.5561672, 10.6448872],
                [107.556139, 10.6448223],
                [107.5561199, 10.6447543],
                [107.5561102, 10.6446843],
                [107.5561102, 10.6446137],
                [107.5561198, 10.6445438],
                [107.5561572, 10.644431],
                [107.5562342, 10.6443114],
                [107.5562382, 10.6442886],
                [107.5562269, 10.6442499],
                [107.5561963, 10.6442233],
                [107.5561765, 10.6442174],
                [107.5560849, 10.6442178],
                [107.5559929, 10.6442386],
                [107.5558539, 10.6443048],
                [107.5557994, 10.644322],
                [107.5557431, 10.644332],
                [107.5556289, 10.6443298],
                [107.5555227, 10.6442997],
                [107.5554309, 10.6442466],
                [107.5553906, 10.6442121],
                [107.5553624, 10.6441683],
                [107.5553236, 10.6440722],
                [107.5553101, 10.6439696],
                [107.555324, 10.6438622],
                [107.5553415, 10.6438107],
                [107.555447, 10.643612],
                [107.5554892, 10.6435038],
                [107.5555223, 10.6433925],
                [107.5555462, 10.643279],
                [107.5555648, 10.6430869],
                [107.5555569, 10.6428941],
                [107.5555329, 10.6427086],
                [107.5555185, 10.6425222],
                [107.555514, 10.6423353],
                [107.5555309, 10.6419937],
                [107.5555515, 10.6418239],
                [107.5555716, 10.6417731],
                [107.555631, 10.6416813],
                [107.5557125, 10.6416077],
                [107.5558212, 10.6415535],
                [107.5558803, 10.6415384],
                [107.5560021, 10.6415339],
                [107.5566338, 10.6416042],
                [107.5566641, 10.6416001],
                [107.5566918, 10.6415872],
                [107.5567143, 10.6415668],
                [107.5567348, 10.6415214],
                [107.5567358, 10.6414964],
                [107.556644, 10.6412236],
                [107.556644, 10.6411641],
                [107.5566539, 10.6411358],
                [107.556696, 10.6410854],
                [107.5567573, 10.6410603],
                [107.5569919, 10.6410594],
                [107.5570225, 10.6410519],
                [107.5570725, 10.641015],
                [107.5570961, 10.6409619],
                [107.5570919, 10.6409077],
                [107.5569009, 10.64046],
                [107.5568612, 10.6402927],
                [107.5568539, 10.6402069],
                [107.556865, 10.6400337],
                [107.5568836, 10.6399478],
                [107.5569668, 10.6397287],
                [107.5569919, 10.6396148],
                [107.5569929, 10.6394719],
                [107.5569516, 10.6392431],
                [107.5569637, 10.6391803],
                [107.5569791, 10.6391519],
                [107.5570252, 10.639107],
                [107.5570854, 10.6390835],
                [107.5572735, 10.6390902],
                [107.5573156, 10.6390809],
                [107.557333, 10.6390679],
                [107.557354, 10.6390274],
                [107.557354, 10.6390028],
                [107.557297, 10.6388041],
                [107.557296, 10.6387701],
                [107.5573164, 10.6387054],
                [107.5573632, 10.6386555],
                [107.5574272, 10.6386301],
                [107.5574619, 10.6386284],
                [107.5578425, 10.6386584],
                [107.5578804, 10.6386547],
                [107.5579511, 10.6386276],
                [107.5580067, 10.6385787],
                [107.5580409, 10.6385149],
                [107.5580507, 10.6384434],
                [107.5580233, 10.6382057],
                [107.5580202, 10.6379909],
                [107.5580272, 10.6378776],
                [107.5580517, 10.637819],
                [107.558101, 10.6377781],
                [107.558164, 10.6377643],
                [107.5581955, 10.6377686],
                [107.5582512, 10.6377978],
                [107.5582874, 10.6378486],
                [107.5583571, 10.6380121],
                [107.5584426, 10.638096],
                [107.5585495, 10.6381512],
                [107.558608, 10.6381664],
                [107.5587262, 10.6381706],
                [107.5588383, 10.6381419],
                [107.5589373, 10.638083],
                [107.5589794, 10.6380437],
                [107.5591676, 10.6378126],
                [107.5592232, 10.6377561],
                [107.5593394, 10.6376712],
                [107.5595278, 10.637581],
                [107.5595856, 10.637527],
                [107.5596278, 10.6374525],
                [107.5596606, 10.6372885],
                [107.5597172, 10.6371348],
                [107.56024, 10.6361141],
                [107.5602603, 10.6360554],
                [107.5602883, 10.6359348],
                [107.5602979, 10.6358481],
                [107.5603044, 10.6354234],
                [107.5603315, 10.6353507],
                [107.5603802, 10.6352869],
                [107.5604123, 10.6352603],
                [107.560531, 10.6351995],
                [107.5605723, 10.6351713],
                [107.5606102, 10.6351387],
                [107.5606716, 10.6350639],
                [107.5607094, 10.6350332],
                [107.5607877, 10.6349914],
                [107.5608301, 10.6349779],
                [107.5609156, 10.6349684],
                [107.5609555, 10.6349521],
                [107.5610229, 10.6348996],
                [107.5610662, 10.6348266],
                [107.5610797, 10.6347463],
                [107.5610649, 10.6346696],
                [107.5610477, 10.6346342],
                [107.5609851, 10.6345525],
                [107.5609782, 10.6345039],
                [107.5609957, 10.6344527],
                [107.5610398, 10.634413],
                [107.5610682, 10.634403],
                [107.5611979, 10.6344077],
                [107.561294, 10.634381],
                [107.5613371, 10.6343558],
                [107.5614041, 10.634289],
                [107.5614282, 10.6342483],
                [107.5614545, 10.6341581],
                [107.5614558, 10.6341111],
                [107.5614803, 10.6340067],
                [107.5615033, 10.633958],
                [107.5615549, 10.6338801],
                [107.5615694, 10.6338435],
                [107.5615741, 10.6337678],
                [107.5615489, 10.6336985],
                [107.5614983, 10.6336442],
                [107.5612615, 10.6335038],
                [107.5612411, 10.6334845],
                [107.5612178, 10.6334345],
                [107.5612222, 10.6333788],
                [107.5612541, 10.6333317],
                [107.5612781, 10.6333157],
                [107.5613344, 10.6333039],
                [107.5614286, 10.6333059],
                [107.5616094, 10.6333362],
                [107.5616944, 10.6333634],
                [107.5618547, 10.633441],
                [107.5618979, 10.6334911],
                [107.5619479, 10.6335347],
                [107.5620036, 10.633571],
                [107.5620639, 10.6335992],
                [107.5621855, 10.6336285],
                [107.5623107, 10.6336255],
                [107.5623667, 10.6336153],
                [107.5624709, 10.6335712],
                [107.5625575, 10.633499],
                [107.5625929, 10.6334523],
                [107.5626398, 10.6333456],
                [107.5626501, 10.6332882],
                [107.5626396, 10.6331048],
                [107.562645, 10.6330377],
                [107.5626779, 10.6329192],
                [107.5627515, 10.6327858],
                [107.5627653, 10.6327061],
                [107.5627586, 10.632666],
                [107.562721, 10.6325961],
                [107.5626573, 10.632548],
                [107.5625789, 10.6325303],
                [107.562441, 10.6325532],
                [107.5623912, 10.6325494],
                [107.5622993, 10.6325168],
                [107.5622259, 10.6324561],
                [107.5621782, 10.6323743],
                [107.5621659, 10.6323286],
                [107.5621641, 10.6320065],
                [107.5621884, 10.6319136],
                [107.5622088, 10.6318699],
                [107.5622713, 10.6317835],
                [107.5623544, 10.6317159],
                [107.5624556, 10.6316652],
                [107.5625514, 10.6315961],
                [107.5626272, 10.6315061],
                [107.5626566, 10.6314538],
                [107.5626944, 10.6313404],
                [107.5627023, 10.6312214],
                [107.5626926, 10.6311068],
                [107.5627184, 10.6309947],
                [107.5627649, 10.6309112],
                [107.5629465, 10.6307213],
                [107.5630362, 10.6305813],
                [107.5630967, 10.6304269],
                [107.5631154, 10.6303462],
                [107.5631285, 10.6301895],
                [107.563124, 10.6301109],
                [107.5630932, 10.6299566],
                [107.5630671, 10.6298822],
                [107.5629008, 10.6296238],
                [107.5628535, 10.6295031],
                [107.5628418, 10.6294393],
                [107.5628416, 10.6293218],
                [107.5628829, 10.6291472],
                [107.5628933, 10.6290242],
                [107.5628793, 10.6289015],
                [107.5628593, 10.6288301],
                [107.5627946, 10.6286964],
                [107.5627508, 10.6286361],
                [107.5627001, 10.6285812],
                [107.5623381, 10.6282432],
                [107.5623022, 10.6281962],
                [107.5622525, 10.6280896],
                [107.5622398, 10.6280322],
                [107.5622399, 10.6279149],
                [107.5622738, 10.6278022],
                [107.5623027, 10.6277506],
                [107.5623814, 10.6276622],
                [107.5624824, 10.6275992],
                [107.562772, 10.6274885],
                [107.5628634, 10.6274133],
                [107.5628993, 10.6273663],
                [107.5629447, 10.627269],
                [107.5629559, 10.6272216],
                [107.5629672, 10.6270747],
                [107.5629833, 10.6270266],
                [107.5630394, 10.6269423],
                [107.563116, 10.6268847],
                [107.5632012, 10.6268554],
                [107.5632462, 10.6268507],
                [107.5633357, 10.6268616],
                [107.5633782, 10.6268769],
                [107.5636231, 10.6270259],
                [107.5636944, 10.6270507],
                [107.5637698, 10.6270456],
                [107.5638329, 10.6270148],
                [107.5638589, 10.6269911],
                [107.563895, 10.6269317],
                [107.5639039, 10.626898],
                [107.5639147, 10.6265448],
                [107.5639014, 10.6264982],
                [107.5638556, 10.626413],
                [107.5637863, 10.626343],
                [107.5636995, 10.6262953],
                [107.5635415, 10.6262469],
                [107.5634458, 10.6261829],
                [107.5634061, 10.6261413],
                [107.5633471, 10.6260435],
                [107.5633192, 10.6259332],
                [107.563318, 10.6258721],
                [107.5633265, 10.6258116],
                [107.5633444, 10.6257532],
                [107.5633714, 10.6256982],
                [107.5634067, 10.625648],
                [107.5634496, 10.6256038],
                [107.563499, 10.6255667],
                [107.5635536, 10.6255376],
                [107.56414, 10.625369],
                [107.5642007, 10.6253423],
                [107.564312, 10.6252709],
                [107.5643614, 10.625227],
                [107.5644448, 10.6251252],
                [107.5645074, 10.6250018],
                [107.5645289, 10.6249321],
                [107.5645464, 10.6247879],
                [107.5645387, 10.6246523],
                [107.5645429, 10.6245894],
                [107.5645745, 10.6244674],
                [107.5646385, 10.6243522],
                [107.5646811, 10.6243015],
                [107.5648173, 10.6241806],
                [107.5648856, 10.6240878],
                [107.5649167, 10.6240246],
                [107.5649389, 10.623958],
                [107.5649519, 10.623889],
                [107.5649536, 10.6236599],
                [107.5649768, 10.6235026],
                [107.5650281, 10.6233418],
                [107.5650637, 10.6232651],
                [107.5651341, 10.6231496],
                [107.5652075, 10.6230793],
                [107.5652509, 10.6230524],
                [107.565347, 10.6230175],
                [107.5654431, 10.6230096],
                [107.565538, 10.6230264],
                [107.5656728, 10.6230732],
                [107.5658566, 10.6231177],
                [107.566092, 10.6231464],
                [107.5662546, 10.6231517],
                [107.5662985, 10.6231474],
                [107.5663816, 10.6231192],
                [107.5664188, 10.6230961],
                [107.566482, 10.6230321],
                [107.5665224, 10.622949],
                [107.5665317, 10.6229038],
                [107.5665271, 10.6228119],
                [107.5663179, 10.6219894],
                [107.5663052, 10.621911],
                [107.5663066, 10.6217525],
                [107.5663206, 10.6216743],
                [107.5663682, 10.6215379],
                [107.5664377, 10.621477],
                [107.5665244, 10.6214437],
                [107.5666151, 10.6214418],
                [107.5667419, 10.6214815],
                [107.5668262, 10.6214847],
                [107.5669063, 10.6214629],
                [107.5669749, 10.6214194],
                [107.5670035, 10.6213907],
                [107.5670964, 10.6212542],
                [107.5671575, 10.6211457],
                [107.5672452, 10.6209443],
                [107.5672993, 10.6207618],
                [107.5673184, 10.6206686],
                [107.5673735, 10.6201593],
                [107.5674302, 10.6197693],
                [107.567478, 10.6195052],
                [107.5675196, 10.6194072],
                [107.5675853, 10.6193228],
                [107.567628, 10.6192859],
                [107.5677267, 10.6192308],
                [107.5678369, 10.6192038],
                [107.568123, 10.6192002],
                [107.5682039, 10.6191643],
                [107.5682386, 10.6191369],
                [107.5682919, 10.6190671],
                [107.5683188, 10.618984],
                [107.568317, 10.6188997],
                [107.5683058, 10.6188589],
                [107.5682642, 10.6187852],
                [107.5682008, 10.6187283],
                [107.5681643, 10.6186671],
                [107.5681096, 10.6185359],
                [107.5680821, 10.6184081],
                [107.5680774, 10.6182775],
                [107.5681378, 10.6175333],
                [107.5681298, 10.6173944],
                [107.5681151, 10.6173262],
                [107.5680671, 10.6171996],
                [107.5679972, 10.6170878],
                [107.5679071, 10.616991],
                [107.5675732, 10.616702],
                [107.5675289, 10.6166431],
                [107.5675155, 10.6166087],
                [107.5675086, 10.6165336],
                [107.5675158, 10.6164964],
                [107.5675858, 10.6162739],
                [107.5676188, 10.6160815],
                [107.5676276, 10.6158429],
                [107.5676014, 10.6156056],
                [107.5675805, 10.6156763],
                [107.5675175, 10.6158094],
                [107.5674759, 10.6158705],
                [107.5673747, 10.6159787],
                [107.567246, 10.6160683],
                [107.5671951, 10.6160843],
                [107.5671423, 10.6160915],
                [107.567089, 10.6160897],
                [107.5670367, 10.6160788],
                [107.5669521, 10.616039],
                [107.5669154, 10.6160101],
                [107.5668266, 10.6159122],
                [107.5667548, 10.615865],
                [107.5667077, 10.6158463],
                [107.5666582, 10.6158349],
                [107.5665251, 10.6158301],
                [107.5663608, 10.6158442],
                [107.5662631, 10.615863],
                [107.5660738, 10.6159233],
                [107.5658405, 10.6157783],
                [107.563464, 10.6151667],
                [107.5636017, 10.615007],
                [107.56368, 10.614936],
                [107.5637652, 10.6148705],
                [107.5640944, 10.6146546],
                [107.5644236, 10.6143389],
                [107.5645446, 10.6142331],
                [107.5646915, 10.6141243],
                [107.5649818, 10.613933],
                [107.5650454, 10.6138624],
                [107.5650693, 10.6138215],
                [107.5650985, 10.6137358],
                [107.5651044, 10.6136908],
                [107.5651096, 10.6130121],
                [107.5650906, 10.6128865],
                [107.5650464, 10.6127662],
                [107.5650151, 10.6127095],
                [107.5648038, 10.6124012],
                [107.5647422, 10.6122464],
                [107.5647233, 10.6121652],
                [107.5647103, 10.6120164],
                [107.5647244, 10.6118677],
                [107.564856, 10.611407],
                [107.5648621, 10.6113084],
                [107.564846, 10.6112333],
                [107.5648313, 10.6111977],
                [107.5646979, 10.6109459],
                [107.5646509, 10.6108266],
                [107.5646347, 10.6107615],
                [107.5645544, 10.6100944],
                [107.5643949, 10.6090355],
                [107.5643764, 10.6087427],
                [107.564379, 10.6083752],
                [107.5644048, 10.6081815],
                [107.5644256, 10.6080888],
                [107.5644524, 10.6080119],
                [107.5645253, 10.6078531],
                [107.5645726, 10.6077726],
                [107.5650705, 10.607084],
                [107.5654502, 10.6065926],
                [107.56553, 10.6064694],
                [107.5655957, 10.6063428],
                [107.5658727, 10.6055336],
                [107.5659437, 10.6053937],
                [107.5660542, 10.6052468],
                [107.5661194, 10.6051815],
                [107.5665553, 10.6048282],
                [107.5666562, 10.6047588],
                [107.5667618, 10.6046964],
                [107.5669253, 10.6046175],
                [107.5675263, 10.6043766],
                [107.5676479, 10.6043018],
                [107.5677026, 10.6042561],
                [107.5678006, 10.6041459],
                [107.5680023, 10.6038013],
                [107.5680524, 10.6036695],
                [107.5680687, 10.603601],
                [107.5680827, 10.60348],
                [107.5680828, 10.6034191],
                [107.5680325, 10.6029122],
                [107.5687319, 10.6029168],
                [107.5688323, 10.6029028],
                [107.5688808, 10.6028878],
                [107.568978, 10.6028387],
                [107.5693448, 10.6025398],
                [107.5694868, 10.6024411],
                [107.5695647, 10.6024027],
                [107.5696987, 10.6023574],
                [107.569768, 10.602343],
                [107.5699644, 10.6023197],
                [107.5700727, 10.6022855],
                [107.5701716, 10.60223],
                [107.5702199, 10.6021917],
                [107.5703019, 10.6021001],
                [107.5704331, 10.601911],
                [107.5705417, 10.6017838],
                [107.5712585, 10.6010726],
                [107.5716716, 10.6006277],
                [107.5717936, 10.6004702],
                [107.5718605, 10.6003719],
                [107.5719225, 10.6002492],
                [107.5719469, 10.600185],
                [107.5710171, 10.5961003],
                [107.5703023, 10.5927177],
                [107.5678468, 10.585433],
                [107.5681458, 10.5852721],
                [107.5681938, 10.5852421],
                [107.5682361, 10.5852047],
                [107.5682716, 10.5851611],
                [107.5682994, 10.5851124],
                [107.5683188, 10.5850599],
                [107.5683292, 10.5850056],
                [107.5683228, 10.5848958],
                [107.5683063, 10.584843],
                [107.5682813, 10.5847936],
                [107.5681525, 10.5846446],
                [107.5681298, 10.5846014],
                [107.5681088, 10.5845124],
                [107.5681093, 10.5844695],
                [107.5682216, 10.5838049],
                [107.568235, 10.5837686],
                [107.5683108, 10.5836335],
                [107.5683325, 10.583524],
                [107.5683301, 10.5834682],
                [107.5682936, 10.5832483],
                [107.5682746, 10.5830824],
                [107.5682558, 10.5827634],
                [107.5682662, 10.5825332],
                [107.5682887, 10.582267],
                [107.5682981, 10.582224],
                [107.5683355, 10.5821515],
                [107.5683607, 10.582123],
                [107.5684723, 10.5820377],
                [107.5685099, 10.5819782],
                [107.5685216, 10.5819418],
                [107.5685718, 10.5816276],
                [107.5686142, 10.5815509],
                [107.5686461, 10.5815203],
                [107.5687184, 10.5814821],
                [107.5687998, 10.581472],
                [107.5690358, 10.5815262],
                [107.569071, 10.5815255],
                [107.5691362, 10.5815012],
                [107.5691635, 10.5814782],
                [107.5691983, 10.581417],
                [107.5692415, 10.5808809],
                [107.5693148, 10.5802871],
                [107.5693347, 10.5802264],
                [107.5693547, 10.5801944],
                [107.5693799, 10.5801665],
                [107.569467, 10.5801059],
                [107.5695015, 10.5800556],
                [107.5695919, 10.5796661],
                [107.5695939, 10.5795756],
                [107.5695855, 10.5795309],
                [107.5695519, 10.5794494],
                [107.5694977, 10.5793793],
                [107.5693224, 10.5792445],
                [107.5692818, 10.5791987],
                [107.5692293, 10.5791125],
                [107.569039, 10.5785804],
                [107.5688124, 10.5781243],
                [107.5702286, 10.5684161],
                [107.5704034, 10.5674135],
                [107.7731742, 10.31234],
                [108.4311207, 9.3780225],
                [109.1806455, 9.7904545],
                [109.2118104, 9.8113191],
                [109.238556, 9.8375339],
                [109.2599589, 9.8682004],
                [109.2752799, 9.9022601],
                [109.2839911, 9.9385424],
                [109.4019255, 10.7630844],
                [109.0035651, 11.1174253],
                [108.8786751, 11.3013359],
                [108.8664468, 11.333286],
                [108.8664734, 11.334647],
                [108.8666188, 11.3346521],
                [108.8667643, 11.3346497],
                [108.8669094, 11.3346396],
                [108.8670538, 11.334622],
                [108.8671943, 11.3345975],
                [108.8673332, 11.3345658],
                [108.8674702, 11.334527],
                [108.867605, 11.3344813],
                [108.8690615, 11.3339198],
                [108.8691978, 11.3338742],
                [108.8693366, 11.3338359],
                [108.8694772, 11.3338051],
                [108.8696193, 11.3337818],
                [108.8697625, 11.3337661],
                [108.8699064, 11.3337581],
                [108.8700462, 11.3337576],
                [108.8701858, 11.3337644],
                [108.8703249, 11.3337784],
                [108.870463, 11.3337996],
                [108.8705998, 11.3338278],
                [108.8707349, 11.3338631],
                [108.8708679, 11.3339054],
                [108.8718814, 11.3342236],
                [108.8719851, 11.3342612],
                [108.8720867, 11.3343041],
                [108.8722, 11.3343597],
                [108.8723098, 11.3344218],
                [108.8726496, 11.3346292],
                [108.8727937, 11.334732],
                [108.8729322, 11.3348419],
                [108.8730647, 11.3349586],
                [108.8731669, 11.3350576],
                [108.8732649, 11.3351607],
                [108.8733584, 11.3352676],
                [108.8743025, 11.3363998],
                [108.8743812, 11.3365023],
                [108.8744558, 11.3366077],
                [108.8745263, 11.3367157],
                [108.874594, 11.3368288],
                [108.8746571, 11.3369444],
                [108.8758562, 11.3394735],
                [108.8759233, 11.339646],
                [108.8759819, 11.3398215],
                [108.8760318, 11.3399995],
                [108.8760728, 11.3401798],
                [108.8761049, 11.3403617],
                [108.8761254, 11.3405194],
                [108.8761392, 11.3406777],
                [108.8761462, 11.3408365],
                [108.8763839, 11.3481801],
                [108.8766053, 11.3546043],
                [108.8764822, 11.3545017],
                [108.8763578, 11.3543876],
                [108.8762391, 11.354268],
                [108.8761528, 11.3541735],
                [108.8760701, 11.354076],
                [108.8760205, 11.3540236],
                [108.8759667, 11.3539753],
                [108.8759091, 11.3539314],
                [108.8758493, 11.3538928],
                [108.8757866, 11.353859],
                [108.8757214, 11.3538301],
                [108.8754505, 11.3537473],
                [108.875311, 11.3537052],
                [108.8751692, 11.3536559],
                [108.8750296, 11.3536006],
                [108.8748926, 11.3535395],
                [108.8748146, 11.353517],
                [108.8747348, 11.353502],
                [108.8746539, 11.3534948],
                [108.8745828, 11.353495],
                [108.874512, 11.3535011],
                [108.874442, 11.3535132],
                [108.8743661, 11.3535278],
                [108.8742891, 11.3535343],
                [108.8742281, 11.3535396],
                [108.8741684, 11.3535527],
                [108.8741102, 11.3535737],
                [108.8740504, 11.3535895],
                [108.8739757, 11.3536015],
                [108.8739002, 11.3536053],
                [108.8734683, 11.3535948],
                [108.8734029, 11.3535939],
                [108.8733375, 11.3535974],
                [108.8732725, 11.3536053],
                [108.8732035, 11.3536186],
                [108.8731357, 11.3536368],
                [108.8730744, 11.3536559],
                [108.8730117, 11.3536699],
                [108.872948, 11.3536789],
                [108.8728872, 11.3536827],
                [108.8728263, 11.3536818],
                [108.8727656, 11.3536763],
                [108.8727183, 11.3536715],
                [108.8726707, 11.3536715],
                [108.8726234, 11.3536763],
                [108.8725604, 11.3536904],
                [108.8725001, 11.3537131],
                [108.8724321, 11.3537437],
                [108.8723622, 11.35377],
                [108.8722908, 11.353792],
                [108.8721924, 11.3538146],
                [108.8720924, 11.3538288],
                [108.8719939, 11.3538422],
                [108.8718971, 11.3538642],
                [108.8718027, 11.3538945],
                [108.8717296, 11.3539246],
                [108.8716588, 11.3539598],
                [108.8715908, 11.3539997],
                [108.8715373, 11.3540426],
                [108.8714799, 11.3540804],
                [108.8714191, 11.3541128],
                [108.8713507, 11.3541412],
                [108.8712797, 11.3541628],
                [108.8711956, 11.3541997],
                [108.8711141, 11.3542418],
                [108.8710356, 11.354289],
                [108.8709529, 11.3543467],
                [108.8708746, 11.35441],
                [108.8705903, 11.3547571],
                [108.8705444, 11.3548007],
                [108.8704932, 11.3548383],
                [108.8704376, 11.3548693],
                [108.8703785, 11.3548932],
                [108.8703167, 11.3549096],
                [108.8702607, 11.3549177],
                [108.8702041, 11.3549197],
                [108.8701476, 11.3549154],
                [108.8700919, 11.354905],
                [108.8700378, 11.3548886],
                [108.869732, 11.3547729],
                [108.869659, 11.3547449],
                [108.8695887, 11.3547109],
                [108.8695217, 11.354671],
                [108.8694584, 11.3546256],
                [108.8693998, 11.3545754],
                [108.8693458, 11.3545204],
                [108.8692976, 11.3544594],
                [108.8692457, 11.3544015],
                [108.8691902, 11.3543469],
                [108.8691275, 11.3542926],
                [108.8690612, 11.3542426],
                [108.8689917, 11.354197],
                [108.8685411, 11.3539471],
                [108.8684957, 11.3539184],
                [108.8684557, 11.3538827],
                [108.8684223, 11.3538411],
                [108.8683963, 11.3537946],
                [108.8683777, 11.3537421],
                [108.8683687, 11.3536872],
                [108.8683695, 11.3536316],
                [108.8683591, 11.3535757],
                [108.8683429, 11.3535212],
                [108.8683212, 11.3534685],
                [108.8682886, 11.3534097],
                [108.8682491, 11.353355],
                [108.8682032, 11.3533055],
                [108.8677499, 11.3528874],
                [108.8676996, 11.3528535],
                [108.8676467, 11.3528236],
                [108.8675916, 11.352798],
                [108.8675138, 11.3527701],
                [108.8674334, 11.3527506],
                [108.8673744, 11.3527403],
                [108.8673169, 11.3527236],
                [108.8672617, 11.3527007],
                [108.8672128, 11.3526739],
                [108.867167, 11.3526422],
                [108.8671249, 11.352606],
                [108.8670027, 11.3524729],
                [108.8668742, 11.3523457],
                [108.8667398, 11.3522244],
                [108.8665998, 11.3521095],
                [108.8664544, 11.3520011],
                [108.8663787, 11.3519488],
                [108.866299, 11.3519027],
                [108.8662157, 11.351863],
                [108.8661294, 11.3518302],
                [108.8660407, 11.3518043],
                [108.8659501, 11.3517855],
                [108.8658502, 11.3517734],
                [108.8657495, 11.35177],
                [108.8656489, 11.3517753],
                [108.8655493, 11.3517892],
                [108.8654512, 11.3518118],
                [108.8653163, 11.3518539],
                [108.8651843, 11.3519041],
                [108.8650557, 11.351962],
                [108.8649309, 11.3520274],
                [108.8648306, 11.3520873],
                [108.8647335, 11.3521521],
                [108.8646399, 11.3522216],
                [108.86455, 11.3522957],
                [108.8644671, 11.352376],
                [108.8643793, 11.3524511],
                [108.864287, 11.3525209],
                [108.8641906, 11.3525849],
                [108.8640801, 11.3526486],
                [108.8639655, 11.3527048],
                [108.8638473, 11.3527533],
                [108.8636839, 11.3528255],
                [108.863523, 11.3529027],
                [108.8633645, 11.3529847],
                [108.8632115, 11.3530696],
                [108.8630613, 11.3531591],
                [108.8629138, 11.3532529],
                [108.8622379, 11.3537052],
                [108.8621135, 11.3537789],
                [108.8619861, 11.3538474],
                [108.8618558, 11.3539105],
                [108.8617229, 11.3539682],
                [108.8615908, 11.3540192],
                [108.8614566, 11.3540648],
                [108.8613206, 11.3541049],
                [108.861215, 11.3541303],
                [108.8611112, 11.3541618],
                [108.8610095, 11.3541996],
                [108.8609232, 11.3542373],
                [108.8608391, 11.3542794],
                [108.8607573, 11.3543258],
                [108.8603282, 11.3546151],
                [108.8602092, 11.3546748],
                [108.8600875, 11.3547292],
                [108.8599634, 11.3547781],
                [108.859833, 11.3548228],
                [108.8597006, 11.3548614],
                [108.8595664, 11.3548938],
                [108.8594514, 11.3549295],
                [108.8593386, 11.3549716],
                [108.8592285, 11.3550201],
                [108.8591348, 11.3550675],
                [108.8590436, 11.3551193],
                [108.8589549, 11.3551752],
                [108.8589126, 11.355204],
                [108.8588673, 11.3552279],
                [108.8588195, 11.3552466],
                [108.8587698, 11.3552597],
                [108.8587189, 11.3552673],
                [108.8586547, 11.3552686],
                [108.8585913, 11.3552777],
                [108.8585294, 11.3552943],
                [108.8584702, 11.3553184],
                [108.8584144, 11.3553494],
                [108.8583629, 11.3553869],
                [108.8583165, 11.3554303],
                [108.8582845, 11.3554677],
                [108.8582485, 11.3555015],
                [108.8582089, 11.3555312],
                [108.8581663, 11.3555565],
                [108.8581181, 11.3555783],
                [108.8580675, 11.3555945],
                [108.8580154, 11.3556048],
                [108.8579625, 11.3556091],
                [108.857705, 11.3556354],
                [108.8576453, 11.3556379],
                [108.8575863, 11.3556476],
                [108.857529, 11.3556644],
                [108.8574743, 11.355688],
                [108.8574288, 11.3557142],
                [108.8573865, 11.3557451],
                [108.8573479, 11.3557803],
                [108.8573134, 11.3558195],
                [108.8571632, 11.3559773],
                [108.8571133, 11.3560161],
                [108.8570594, 11.3560496],
                [108.8570023, 11.3560772],
                [108.8569566, 11.3560943],
                [108.8569096, 11.3561077],
                [108.8568199, 11.3561456],
                [108.856755, 11.3561786],
                [108.8566923, 11.3562154],
                [108.8566321, 11.356256],
                [108.8564519, 11.3563904],
                [108.8562673, 11.356519],
                [108.856138, 11.356604],
                [108.8560068, 11.3566862],
                [108.8557517, 11.3568612],
                [108.8555023, 11.357044],
                [108.8552588, 11.3572343],
                [108.8551731, 11.3573042],
                [108.8550928, 11.3573802],
                [108.8550184, 11.3574616],
                [108.8549502, 11.3575482],
                [108.8548887, 11.3576394],
                [108.8548341, 11.3577348],
                [108.8547867, 11.3578338],
                [108.85475, 11.3579271],
                [108.8547198, 11.3580225],
                [108.8546961, 11.3581197],
                [108.854679, 11.3582183],
                [108.8546687, 11.3583177],
                [108.8546516, 11.3584175],
                [108.8546256, 11.3585155],
                [108.854591, 11.3586109],
                [108.854548, 11.358703],
                [108.8544971, 11.358791],
                [108.8544385, 11.3588744],
                [108.8543727, 11.3589523],
                [108.8543003, 11.3590244],
                [108.8542218, 11.35909],
                [108.8541377, 11.3591487],
                [108.8540803, 11.3592041],
                [108.85403, 11.3592657],
                [108.8539875, 11.3593328],
                [108.8539653, 11.3593765],
                [108.8539464, 11.3594217],
                [108.8538976, 11.3595068],
                [108.8538414, 11.3595873],
                [108.8537782, 11.3596627],
                [108.8537085, 11.3597325],
                [108.8536323, 11.3597964],
                [108.8535506, 11.3598535],
                [108.8534641, 11.3599033],
                [108.8533734, 11.3599455],
                [108.8532793, 11.3599797],
                [108.8532131, 11.3600035],
                [108.8531486, 11.3600316],
                [108.8530862, 11.3600638],
                [108.8530031, 11.3601154],
                [108.8529253, 11.3601742],
                [108.8528845, 11.3602137],
                [108.852839, 11.3602478],
                [108.8527894, 11.360276],
                [108.8527367, 11.3602979],
                [108.8526815, 11.3603132],
                [108.8526249, 11.3603215],
                [108.8525643, 11.3603227],
                [108.852504, 11.360316],
                [108.8524452, 11.3603015],
                [108.8523889, 11.3602794],
                [108.8523443, 11.3602541],
                [108.8522967, 11.3602349],
                [108.8522469, 11.3602222],
                [108.8521957, 11.3602163],
                [108.8521523, 11.3602167],
                [108.8521092, 11.360222],
                [108.852067, 11.3602321],
                [108.8520098, 11.3602526],
                [108.8519547, 11.360278],
                [108.8519021, 11.3603081],
                [108.8518524, 11.3603425],
                [108.8518003, 11.3603864],
                [108.8517528, 11.3604352],
                [108.8517106, 11.3604883],
                [108.8516739, 11.3605453],
                [108.8516432, 11.3606055],
                [108.8516074, 11.3606805],
                [108.8515637, 11.3607514],
                [108.8515127, 11.3608174],
                [108.8514548, 11.3608778],
                [108.8513908, 11.3609318],
                [108.8513213, 11.3609789],
                [108.8512507, 11.3610169],
                [108.8511765, 11.3610478],
                [108.8510996, 11.3610711],
                [108.8510206, 11.3610868],
                [108.8509405, 11.3610946],
                [108.8508706, 11.3611093],
                [108.850803, 11.3611317],
                [108.8507384, 11.3611617],
                [108.8506778, 11.3611987],
                [108.8506219, 11.3612424],
                [108.8505716, 11.361292],
                [108.8505274, 11.3613471],
                [108.8504877, 11.3614109],
                [108.8504564, 11.3614791],
                [108.8504338, 11.3615505],
                [108.8504204, 11.3616241],
                [108.8504163, 11.3616987],
                [108.8504216, 11.3617733],
                [108.8504362, 11.3618467],
                [108.8504447, 11.3619072],
                [108.8504457, 11.3619683],
                [108.8504393, 11.3620291],
                [108.8504255, 11.3620886],
                [108.8504028, 11.3621502],
                [108.8503722, 11.3622083],
                [108.8503343, 11.3622622],
                [108.8502923, 11.3622987],
                [108.8502548, 11.3623395],
                [108.8502222, 11.3623843],
                [108.8501949, 11.3624323],
                [108.8501733, 11.3624831],
                [108.8501563, 11.3625421],
                [108.850147, 11.3626027],
                [108.8501455, 11.362664],
                [108.8501519, 11.362725],
                [108.85016, 11.3627716],
                [108.8501619, 11.3628188],
                [108.8501576, 11.3628659],
                [108.850147, 11.362912],
                [108.8501304, 11.3629564],
                [108.8501036, 11.3630054],
                [108.8500695, 11.3630498],
                [108.8500288, 11.3630886],
                [108.8499826, 11.3631208],
                [108.8499319, 11.3631457],
                [108.8498608, 11.3631854],
                [108.849793, 11.3632304],
                [108.849729, 11.3632804],
                [108.8496691, 11.3633351],
                [108.8496169, 11.3633905],
                [108.8495689, 11.3634495],
                [108.8495254, 11.3635118],
                [108.8494867, 11.363577],
                [108.8494612, 11.3636514],
                [108.8494278, 11.3637228],
                [108.849387, 11.3637903],
                [108.8493392, 11.3638533],
                [108.849285, 11.3639111],
                [108.8492248, 11.3639629],
                [108.8491595, 11.3640083],
                [108.8490964, 11.3640434],
                [108.8490302, 11.3640724],
                [108.8489614, 11.3640952],
                [108.8488908, 11.3641115],
                [108.8488189, 11.3641211],
                [108.8487464, 11.364124],
                [108.8486395, 11.3641327],
                [108.8485338, 11.3641502],
                [108.8484299, 11.3641766],
                [108.8483472, 11.3642044],
                [108.8482666, 11.3642377],
                [108.8481885, 11.3642765],
                [108.8480596, 11.3643385],
                [108.8479271, 11.3643929],
                [108.8477915, 11.3644395],
                [108.8476453, 11.3644802],
                [108.8474966, 11.3645118],
                [108.8473463, 11.3645342],
                [108.8472564, 11.3645433],
                [108.8471674, 11.3645591],
                [108.8470799, 11.3645815],
                [108.8469944, 11.3646103],
                [108.8469114, 11.3646455],
                [108.8468313, 11.3646867],
                [108.8467567, 11.3647325],
                [108.8466858, 11.3647835],
                [108.8466189, 11.3648396],
                [108.8465564, 11.3649003],
                [108.8464987, 11.3649654],
                [108.846449, 11.36502],
                [108.8463948, 11.3650702],
                [108.8463365, 11.3651158],
                [108.8462744, 11.3651563],
                [108.846209, 11.3651916],
                [108.846138, 11.3652224],
                [108.8460645, 11.3652471],
                [108.8459891, 11.3652652],
                [108.8456726, 11.3653546],
                [108.8456103, 11.3653734],
                [108.8455506, 11.3653989],
                [108.8454941, 11.3654307],
                [108.8454415, 11.3654684],
                [108.8453935, 11.3655116],
                [108.8453507, 11.3655597],
                [108.8453157, 11.365609],
                [108.8452861, 11.3656615],
                [108.8452622, 11.3657168],
                [108.8452444, 11.3657743],
                [108.8452327, 11.3658332],
                [108.843806, 11.3770367],
                [108.8015557, 11.3832828],
                [108.7850976, 11.3881262],
                [108.7834132, 11.3937004],
                [108.7570202, 11.4112324],
                [108.7602496, 11.448943],
                [108.761065, 11.469668],
                [108.7512481, 11.4712767],
                [108.7251985, 11.4694051],
                [108.7240398, 11.4676807],
                [108.7239524, 11.4675629],
                [108.7238587, 11.4674498],
                [108.723759, 11.4673418],
                [108.7236535, 11.4672391],
                [108.7235405, 11.4671402],
                [108.7234221, 11.4670475],
                [108.7232987, 11.4669612],
                [108.7231707, 11.4668816],
                [108.7220925, 11.46644],
                [108.7219814, 11.4664011],
                [108.7218729, 11.4663558],
                [108.7217673, 11.4663041],
                [108.7216652, 11.4662462],
                [108.7215668, 11.4661824],
                [108.721471, 11.4661117],
                [108.7213799, 11.4660353],
                [108.7212937, 11.4659537],
                [108.7212127, 11.4658669],
                [108.7204993, 11.464989],
                [108.7202388, 11.464721],
                [108.7199713, 11.4644598],
                [108.7196968, 11.4642056],
                [108.7194157, 11.4639585],
                [108.7193192, 11.4638767],
                [108.7192169, 11.4638018],
                [108.7191094, 11.4637344],
                [108.7189972, 11.4636748],
                [108.718881, 11.4636232],
                [108.7187612, 11.46358],
                [108.7186483, 11.4635477],
                [108.7185334, 11.4635229],
                [108.7184171, 11.4635056],
                [108.7182999, 11.4634959],
                [108.7181999, 11.4634935],
                [108.7181002, 11.4634857],
                [108.7180011, 11.4634724],
                [108.7179029, 11.4634538],
                [108.7177834, 11.4634235],
                [108.7176663, 11.4633852],
                [108.7175522, 11.4633392],
                [108.7174415, 11.4632856],
                [108.7162077, 11.4626126],
                [108.714813, 11.4620343],
                [108.7146851, 11.461999],
                [108.7145552, 11.4619717],
                [108.7144238, 11.4619527],
                [108.7142914, 11.461942],
                [108.7141585, 11.4619397],
                [108.7140206, 11.461946],
                [108.7138835, 11.4619614],
                [108.7137477, 11.4619857],
                [108.7136139, 11.4620188],
                [108.7134826, 11.4620606],
                [108.7003559, 11.4670866],
                [108.678811, 11.4734165],
                [108.6639416, 11.5007578],
                [108.6593932, 11.5049107],
                [108.6438256, 11.5506134],
                [108.5824433, 11.5559269],
                [108.5820031, 11.5559682],
                [108.5815622, 11.5560032],
                [108.581121, 11.556032],
                [108.5807081, 11.5560533],
                [108.5802951, 11.556069],
                [108.5798818, 11.5560793],
                [108.5796638, 11.5560186],
                [108.5794473, 11.5559532],
                [108.5792442, 11.5558871],
                [108.5790438, 11.5558135],
                [108.5788464, 11.5557325],
                [108.5787335, 11.5556821],
                [108.5786216, 11.5556294],
                [108.5783945, 11.5555312],
                [108.5781652, 11.5554381],
                [108.5779828, 11.5553683],
                [108.5778038, 11.5552906],
                [108.5776284, 11.5552052],
                [108.5774571, 11.5551123],
                [108.577287, 11.5550101],
                [108.5771219, 11.5549006],
                [108.5767919, 11.5546813],
                [108.5765502, 11.5545136],
                [108.5763051, 11.5543508],
                [108.5760566, 11.5541929],
                [108.5758048, 11.5540401],
                [108.5755643, 11.5539006],
                [108.5753211, 11.5537657],
                [108.5750753, 11.5536354],
                [108.5748142, 11.5534899],
                [108.5745549, 11.5533411],
                [108.5744106, 11.5532565],
                [108.574264, 11.5531759],
                [108.574115, 11.5530993],
                [108.5739773, 11.5530332],
                [108.5738427, 11.5529611],
                [108.5737115, 11.5528832],
                [108.573584, 11.5527998],
                [108.5734897, 11.5527327],
                [108.5733978, 11.5526626],
                [108.5732153, 11.5525207],
                [108.5730368, 11.5523741],
                [108.5728492, 11.5522112],
                [108.5726666, 11.5520429],
                [108.5725808, 11.5519602],
                [108.5724914, 11.5518813],
                [108.5723984, 11.5518064],
                [108.5723202, 11.5517484],
                [108.5722473, 11.5516841],
                [108.5721802, 11.5516139],
                [108.5721195, 11.5515384],
                [108.5720685, 11.5514629],
                [108.5720253, 11.551383],
                [108.5719904, 11.5512992],
                [108.5719639, 11.5512125],
                [108.5719527, 11.5511621],
                [108.5719445, 11.5511111],
                [108.5719317, 11.5510087],
                [108.5719257, 11.5509057],
                [108.5719264, 11.5508026],
                [108.5719336, 11.5507015],
                [108.5719485, 11.5506013],
                [108.5719709, 11.5505024],
                [108.5720007, 11.5504054],
                [108.5720378, 11.5503108],
                [108.5720819, 11.5502192],
                [108.5721216, 11.5501493],
                [108.5721654, 11.5500817],
                [108.572253, 11.5499468],
                [108.5723448, 11.5498145],
                [108.5724783, 11.549636],
                [108.5726194, 11.5494631],
                [108.5728919, 11.5491102],
                [108.5731756, 11.5487385],
                [108.5734552, 11.5483639],
                [108.5736919, 11.5480403],
                [108.5739219, 11.5477122],
                [108.5740951, 11.5474556],
                [108.5742652, 11.5471971],
                [108.5744249, 11.5469483],
                [108.5747454, 11.546456],
                [108.5745058, 11.5463743],
                [108.5742688, 11.5462858],
                [108.5740346, 11.5461906],
                [108.5738008, 11.5460876],
                [108.5735703, 11.5459779],
                [108.5733941, 11.5458827],
                [108.5732792, 11.5458264],
                [108.5732127, 11.5457958],
                [108.5731441, 11.5457701],
                [108.5730968, 11.5457557],
                [108.5730488, 11.5457436],
                [108.5729519, 11.5457233],
                [108.5728539, 11.5457093],
                [108.5727552, 11.5457018],
                [108.5726867, 11.5457004],
                [108.5726183, 11.5457021],
                [108.5724816, 11.5457071],
                [108.5723822, 11.5457137],
                [108.5722831, 11.5457228],
                [108.5722101, 11.5457276],
                [108.572137, 11.5457243],
                [108.5720648, 11.5457131],
                [108.5719943, 11.545694],
                [108.5719264, 11.5456674],
                [108.571862, 11.5456335],
                [108.5718052, 11.5455953],
                [108.5717515, 11.5455532],
                [108.571701, 11.5455073],
                [108.5716482, 11.5454512],
                [108.5716003, 11.545391],
                [108.5715055, 11.54527],
                [108.5714167, 11.5451447],
                [108.5713561, 11.5450508],
                [108.5712989, 11.5449549],
                [108.5711866, 11.5447619],
                [108.5710802, 11.5445658],
                [108.5709795, 11.5443668],
                [108.5708945, 11.5441864],
                [108.5708158, 11.5440032],
                [108.5707435, 11.5438176],
                [108.5706757, 11.5436235],
                [108.5706149, 11.5434272],
                [108.5705611, 11.5432473],
                [108.5705171, 11.5430825],
                [108.5704795, 11.5429344],
                [108.5704485, 11.5427848],
                [108.5704216, 11.5426158],
                [108.5704024, 11.5424459],
                [108.5703912, 11.5422752],
                [108.5703877, 11.5421043],
                [108.5703921, 11.5419333],
                [108.5704004, 11.5418074],
                [108.570413, 11.5416819],
                [108.5704431, 11.5414314],
                [108.5704827, 11.5411809],
                [108.5705316, 11.5409321],
                [108.5705576, 11.5407891],
                [108.570579, 11.5406454],
                [108.570596, 11.5405011],
                [108.5706106, 11.5403239],
                [108.5706174, 11.5401463],
                [108.5706178, 11.5400357],
                [108.5706232, 11.5399253],
                [108.5706335, 11.5398152],
                [108.5706432, 11.5397419],
                [108.5706473, 11.5396682],
                [108.5706459, 11.5395944],
                [108.5706389, 11.5395208],
                [108.5706311, 11.5394721],
                [108.5706177, 11.5394246],
                [108.5705987, 11.5393789],
                [108.5705696, 11.5393285],
                [108.5705355, 11.5392811],
                [108.5704968, 11.5392374],
                [108.5704538, 11.5391976],
                [108.5704079, 11.5391629],
                [108.5703588, 11.5391327],
                [108.5702908, 11.5391021],
                [108.570191, 11.5390846],
                [108.5699252, 11.5390578],
                [108.5695982, 11.5390241],
                [108.5693056, 11.5389904],
                [108.5690135, 11.5389532],
                [108.5685414, 11.5388901],
                [108.5682768, 11.5388588],
                [108.568005, 11.5388192],
                [108.5677524, 11.538777],
                [108.5675302, 11.5387351],
                [108.5673227, 11.5386997],
                [108.5671464, 11.5386611],
                [108.5669723, 11.5386142],
                [108.5668007, 11.538559],
                [108.5666857, 11.5385169],
                [108.5665741, 11.5384667],
                [108.5664665, 11.5384088],
                [108.5663635, 11.5383435],
                [108.5662907, 11.5382907],
                [108.5662228, 11.5382321],
                [108.5661601, 11.5381681],
                [108.5661033, 11.5380991],
                [108.566073, 11.5380569],
                [108.566045, 11.5380132],
                [108.5659923, 11.5379239],
                [108.5659457, 11.5378315],
                [108.5659051, 11.5377363],
                [108.5658709, 11.5376388],
                [108.5658431, 11.5375393],
                [108.5658187, 11.53742],
                [108.5657865, 11.5373024],
                [108.5657465, 11.5371872],
                [108.5657191, 11.5371198],
                [108.5656891, 11.5370535],
                [108.5656399, 11.5369166],
                [108.565599, 11.5367772],
                [108.5655758, 11.5366798],
                [108.5655567, 11.5365815],
                [108.5655227, 11.5363843],
                [108.5654971, 11.5361859],
                [108.5654843, 11.5360487],
                [108.5654798, 11.535911],
                [108.5654837, 11.5357733],
                [108.5654909, 11.5356809],
                [108.5655051, 11.5355893],
                [108.5655265, 11.5354982],
                [108.565539, 11.5354055],
                [108.5655426, 11.5353121],
                [108.5655373, 11.5352188],
                [108.5655293, 11.5351596],
                [108.5655177, 11.5351009],
                [108.5654914, 11.5349843],
                [108.5654586, 11.5348693],
                [108.5654193, 11.5347562],
                [108.5653712, 11.5346398],
                [108.5653254, 11.5345223],
                [108.5653011, 11.5344569],
                [108.5652826, 11.5343896],
                [108.5652701, 11.534321],
                [108.5652637, 11.5342517],
                [108.5652633, 11.5341872],
                [108.5652696, 11.534123],
                [108.5652826, 11.5340598],
                [108.5653022, 11.5339982],
                [108.5653281, 11.5339389],
                [108.5653532, 11.5338937],
                [108.565382, 11.5338507],
                [108.5654408, 11.5337655],
                [108.5654946, 11.5336956],
                [108.5655431, 11.5336221],
                [108.565586, 11.5335454],
                [108.5656232, 11.5334659],
                [108.5656578, 11.5333735],
                [108.5656847, 11.5332787],
                [108.5657036, 11.533182],
                [108.5656446, 11.5329703],
                [108.5655901, 11.5327574],
                [108.56554, 11.5325434],
                [108.5655008, 11.5323598],
                [108.5654649, 11.5321755],
                [108.5654441, 11.5320643],
                [108.5654304, 11.531952],
                [108.5654239, 11.5318392],
                [108.5654247, 11.5317261],
                [108.5654344, 11.5315975],
                [108.5654381, 11.5314685],
                [108.5654373, 11.5313847],
                [108.5654294, 11.5313011],
                [108.5654144, 11.5312185],
                [108.5653925, 11.5311374],
                [108.5653673, 11.5310669],
                [108.5653368, 11.5309985],
                [108.5653013, 11.5309324],
                [108.5652564, 11.5308246],
                [108.5652198, 11.5307139],
                [108.5651918, 11.5306008],
                [108.5651724, 11.5304859],
                [108.5651618, 11.53037],
                [108.5651602, 11.530249],
                [108.5651538, 11.5301282],
                [108.5651439, 11.5300198],
                [108.5651242, 11.5299126],
                [108.5650948, 11.5298076],
                [108.5650614, 11.5297185],
                [108.5650223, 11.5296315],
                [108.5649848, 11.5295595],
                [108.5649435, 11.5294896],
                [108.5648984, 11.5294219],
                [108.5647675, 11.5292163],
                [108.5646742, 11.5290783],
                [108.564574, 11.528945],
                [108.5644671, 11.5288168],
                [108.564403, 11.5287458],
                [108.564332, 11.5286814],
                [108.5642548, 11.5286243],
                [108.5641721, 11.528575],
                [108.5641197, 11.5285492],
                [108.5640658, 11.5285266],
                [108.563962, 11.5284731],
                [108.563861, 11.5284147],
                [108.5637894, 11.5283693],
                [108.5637196, 11.5283214],
                [108.563582, 11.5282229],
                [108.5634388, 11.5281114],
                [108.5633004, 11.5279942],
                [108.5627827, 11.5275685],
                [108.5625963, 11.5274141],
                [108.5624045, 11.5272662],
                [108.5622301, 11.5271405],
                [108.5620585, 11.5270113],
                [108.5619683, 11.5269411],
                [108.5618842, 11.5268641],
                [108.5618395, 11.5268178],
                [108.561797, 11.5267696],
                [108.5617164, 11.5266696],
                [108.5616428, 11.5265645],
                [108.561573, 11.5264489],
                [108.5614963, 11.5263329],
                [108.5614148, 11.5262202],
                [108.5613633, 11.5261445],
                [108.5613184, 11.5260649],
                [108.5612805, 11.5259819],
                [108.5612499, 11.525896],
                [108.5612266, 11.5258079],
                [108.5612109, 11.5257183],
                [108.5612027, 11.5256206],
                [108.5612034, 11.5255225],
                [108.5612056, 11.5253267],
                [108.561215, 11.5251597],
                [108.561227, 11.5249929],
                [108.5612364, 11.5248824],
                [108.5612485, 11.5247721],
                [108.5612557, 11.5247161],
                [108.5612536, 11.5246596],
                [108.5612421, 11.5246042],
                [108.5612217, 11.5245514],
                [108.5612044, 11.5245202],
                [108.5611841, 11.5244908],
                [108.5611385, 11.5244357],
                [108.5611094, 11.5244074],
                [108.5610782, 11.5243812],
                [108.5610124, 11.5243332],
                [108.5609504, 11.5242967],
                [108.5608852, 11.524266],
                [108.560755, 11.5242045],
                [108.5606343, 11.5241537],
                [108.5605116, 11.5240986],
                [108.5604478, 11.5240683],
                [108.560386, 11.5240259],
                [108.5603103, 11.5239679],
                [108.5602383, 11.5239056],
                [108.5601769, 11.5238523],
                [108.5601243, 11.5238114],
                [108.5600817, 11.5237787],
                [108.5600418, 11.5237401],
                [108.560012, 11.5236907],
                [108.5599972, 11.5236424],
                [108.5599941, 11.5235869],
                [108.5599972, 11.5235313],
                [108.5600066, 11.5234765],
                [108.5600203, 11.5234284],
                [108.5600442, 11.5233608],
                [108.5600698, 11.523285],
                [108.5600944, 11.5231863],
                [108.5601125, 11.5230863],
                [108.5601239, 11.5229854],
                [108.5601286, 11.5228906],
                [108.5601383, 11.5227012],
                [108.5601403, 11.5225115],
                [108.5601347, 11.522322],
                [108.5601214, 11.5221327],
                [108.5601005, 11.5219442],
                [108.5599208, 11.5212425],
                [108.5598932, 11.5211691],
                [108.5598578, 11.521099],
                [108.559815, 11.521033],
                [108.5597652, 11.5209718],
                [108.5597273, 11.520933],
                [108.5596938, 11.5208903],
                [108.5596652, 11.5208444],
                [108.5596418, 11.5207958],
                [108.5596258, 11.5207513],
                [108.5596142, 11.5207057],
                [108.5596043, 11.5206302],
                [108.5595909, 11.5205198],
                [108.5595721, 11.5203332],
                [108.5595547, 11.5200855],
                [108.5595418, 11.519875],
                [108.5595225, 11.5196649],
                [108.559497, 11.5194554],
                [108.5594658, 11.5192501],
                [108.5594285, 11.5190457],
                [108.5593853, 11.5188425],
                [108.5593361, 11.5186407],
                [108.559186, 11.5186489],
                [108.5590357, 11.5186512],
                [108.5589014, 11.5186483],
                [108.5588342, 11.5186487],
                [108.5587674, 11.5186407],
                [108.5587007, 11.5186239],
                [108.5586367, 11.5185987],
                [108.5585767, 11.5185655],
                [108.5585216, 11.518525],
                [108.5584724, 11.5184777],
                [108.5584119, 11.518431],
                [108.5583463, 11.5183913],
                [108.5582766, 11.5183592],
                [108.5582036, 11.518335],
                [108.5581282, 11.5183191],
                [108.5580515, 11.5183118],
                [108.5579745, 11.518313],
                [108.5578981, 11.5183228],
                [108.5578233, 11.518341],
                [108.5578189, 11.5182018],
                [108.5578179, 11.5180624],
                [108.557825, 11.5179522],
                [108.5578233, 11.5178417],
                [108.5578147, 11.5177467],
                [108.5577996, 11.5176526],
                [108.5577781, 11.5175597],
                [108.5577501, 11.5174684],
                [108.557716, 11.5173791],
                [108.5576217, 11.51712],
                [108.5575336, 11.5168587],
                [108.557472, 11.5166948],
                [108.5574184, 11.5165282],
                [108.5573727, 11.5163594],
                [108.5573366, 11.516196],
                [108.5573079, 11.5160312],
                [108.5573011, 11.5159478],
                [108.5572869, 11.5158653],
                [108.5572675, 11.5157912],
                [108.5572421, 11.5157189],
                [108.557211, 11.5156488],
                [108.5571944, 11.5156142],
                [108.5571742, 11.5155814],
                [108.5571427, 11.5155418],
                [108.557106, 11.5155068],
                [108.5570648, 11.515477],
                [108.5570198, 11.5154529],
                [108.556972, 11.5154351],
                [108.5569221, 11.5154237],
                [108.5568525, 11.5154193],
                [108.556788, 11.5154185],
                [108.5566565, 11.5154199],
                [108.5565251, 11.5154185],
                [108.556407, 11.5154145],
                [108.5563478, 11.5154152],
                [108.5562891, 11.515408],
                [108.5562312, 11.5153926],
                [108.5561761, 11.5153695],
                [108.5561249, 11.5153392],
                [108.5560784, 11.5153021],
                [108.5560377, 11.5152591],
                [108.5560034, 11.5152109],
                [108.5559764, 11.5151585],
                [108.555957, 11.5151029],
                [108.5559162, 11.514998],
                [108.5557782, 11.5146316],
                [108.5557423, 11.514563],
                [108.555699, 11.5144986],
                [108.5556529, 11.5144436],
                [108.5556014, 11.5143934],
                [108.5555451, 11.5143485],
                [108.5554844, 11.5143094],
                [108.5553894, 11.5142541],
                [108.5552913, 11.5142042],
                [108.5551332, 11.5141165],
                [108.5549793, 11.5140218],
                [108.5548299, 11.5139204],
                [108.5546462, 11.5137908],
                [108.5545542, 11.5137261],
                [108.5544652, 11.5136576],
                [108.5543758, 11.5135825],
                [108.5542899, 11.5135036],
                [108.5542502, 11.513461],
                [108.5542077, 11.513421],
                [108.5541377, 11.5133656],
                [108.5540624, 11.5133175],
                [108.5539824, 11.5132771],
                [108.5538987, 11.5132449],
                [108.5538121, 11.5132212],
                [108.5537234, 11.5132063],
                [108.5536337, 11.5132002],
                [108.5536166, 11.5132491],
                [108.5535937, 11.5132956],
                [108.5535653, 11.5133391],
                [108.5535318, 11.513379],
                [108.5534902, 11.5134174],
                [108.553444, 11.5134503],
                [108.5533938, 11.513477],
                [108.5533405, 11.5134972],
                [108.553285, 11.5135104],
                [108.5532058, 11.5135227],
                [108.5531257, 11.5135276],
                [108.5530455, 11.5135251],
                [108.5529659, 11.5135152],
                [108.5528876, 11.5134979],
                [108.5528114, 11.5134735],
                [108.5527378, 11.513442],
                [108.5526543, 11.5134007],
                [108.5525735, 11.5133543],
                [108.5524957, 11.5133032],
                [108.5524593, 11.5132744],
                [108.5524213, 11.5132476],
                [108.5523766, 11.5132198],
                [108.5523544, 11.5132055],
                [108.5523301, 11.513195],
                [108.5522921, 11.5131868],
                [108.5522532, 11.5131878],
                [108.5522157, 11.5131979],
                [108.5521816, 11.5132164],
                [108.5521531, 11.5132423],
                [108.5520927, 11.5132901],
                [108.5520282, 11.5133322],
                [108.5519948, 11.5133516],
                [108.55196, 11.5133685],
                [108.5519036, 11.5133895],
                [108.5518451, 11.5134041],
                [108.5517853, 11.513412],
                [108.551725, 11.513413],
                [108.5516408, 11.5133994],
                [108.5483873, 11.5128376],
                [108.5480862, 11.5127917],
                [108.5477865, 11.5127377],
                [108.5474768, 11.5126728],
                [108.5471691, 11.5125992],
                [108.5463616, 11.512392],
                [108.5460725, 11.5123224],
                [108.5458545, 11.512265],
                [108.5457271, 11.5122293],
                [108.5456534, 11.5121969],
                [108.5455833, 11.5121578],
                [108.5455173, 11.5121122],
                [108.5454665, 11.51207],
                [108.5454193, 11.5120241],
                [108.5453188, 11.5119387],
                [108.54519, 11.5118361],
                [108.5450577, 11.5117379],
                [108.5449219, 11.5116443],
                [108.544823, 11.5115705],
                [108.5447297, 11.5114901],
                [108.5446424, 11.5114035],
                [108.5445615, 11.511311],
                [108.5444874, 11.5112133],
                [108.5444191, 11.5111085],
                [108.5443587, 11.5109992],
                [108.5442352, 11.5107823],
                [108.5441932, 11.5107057],
                [108.5441574, 11.5106261],
                [108.544128, 11.5105441],
                [108.5441052, 11.5104601],
                [108.544089, 11.5103746],
                [108.5440797, 11.5102881],
                [108.5440771, 11.5102177],
                [108.5440699, 11.5101475],
                [108.5440582, 11.5100779],
                [108.5440442, 11.5100178],
                [108.5440269, 11.5099586],
                [108.5439874, 11.5098417],
                [108.54394, 11.5097276],
                [108.5438848, 11.5096169],
                [108.5438222, 11.5095102],
                [108.5437642, 11.5094243],
                [108.5437123, 11.5093348],
                [108.5436666, 11.5092421],
                [108.5436261, 11.5091434],
                [108.5435946, 11.5090417],
                [108.5435723, 11.5089376],
                [108.5435593, 11.5088321],
                [108.543556, 11.5087664],
                [108.5435563, 11.5087006],
                [108.5435486, 11.5085693],
                [108.5435373, 11.5084498],
                [108.5435169, 11.5083316],
                [108.5434874, 11.5082152],
                [108.543449, 11.5081013],
                [108.543402, 11.5079906],
                [108.5433609, 11.5079095],
                [108.54329, 11.507776],
                [108.543216, 11.5076441],
                [108.5422398, 11.506054],
                [108.5408664, 11.5059725],
                [108.5406733, 11.506075],
                [108.5334987, 11.5071792],
                [108.5279167, 11.5080383],
                [108.5278399, 11.5080531],
                [108.5277646, 11.5080737],
                [108.5276912, 11.5081002],
                [108.5276201, 11.5081323],
                [108.5275519, 11.5081698],
                [108.5274783, 11.5082187],
                [108.5274095, 11.5082739],
                [108.5272729, 11.5083853],
                [108.5271666, 11.5084808],
                [108.5270647, 11.5085807],
                [108.5269672, 11.5086849],
                [108.5269248, 11.5087273],
                [108.5268782, 11.508765],
                [108.5268277, 11.5087978],
                [108.5267671, 11.5088284],
                [108.5267026, 11.5088503],
                [108.5266356, 11.508863],
                [108.5265675, 11.5088662],
                [108.5265013, 11.5088603],
                [108.5264366, 11.5088456],
                [108.5263746, 11.5088222],
                [108.5262816, 11.5087749],
                [108.5261786, 11.5087217],
                [108.5260877, 11.5086716],
                [108.5259927, 11.5086295],
                [108.5258943, 11.5085955],
                [108.5258435, 11.5085816],
                [108.5257912, 11.508575],
                [108.5257384, 11.5085758],
                [108.5256864, 11.508584],
                [108.525636, 11.5085994],
                [108.5255885, 11.5086218],
                [108.5255401, 11.5086543],
                [108.5254969, 11.5086931],
                [108.5254598, 11.5087375],
                [108.5254321, 11.5087814],
                [108.5254104, 11.5088285],
                [108.5253692, 11.5089234],
                [108.5253364, 11.5090213],
                [108.5253191, 11.509089],
                [108.5253059, 11.5091576],
                [108.5252698, 11.5092925],
                [108.525228, 11.5094259],
                [108.5251808, 11.5095575],
                [108.5251337, 11.509674],
                [108.5250813, 11.5097884],
                [108.5250236, 11.5099003],
                [108.5249609, 11.5100095],
                [108.5248815, 11.5101331],
                [108.5247956, 11.5102523],
                [108.5247034, 11.510367],
                [108.5247567, 11.5104334],
                [108.5248049, 11.5105035],
                [108.5248475, 11.510577],
                [108.5248844, 11.5106534],
                [108.5249153, 11.5107323],
                [108.5249422, 11.5108215],
                [108.5249615, 11.5109125],
                [108.5249732, 11.5110048],
                [108.524975, 11.5110687],
                [108.5249723, 11.511118],
                [108.5249636, 11.511208],
                [108.5249032, 11.5116581],
                [108.524887, 11.5117231],
                [108.5248643, 11.5117863],
                [108.524839, 11.5118397],
                [108.5248221, 11.5118963],
                [108.524814, 11.5119547],
                [108.5248148, 11.5120136],
                [108.5248245, 11.5120718],
                [108.5248429, 11.5121279],
                [108.5248615, 11.5121668],
                [108.5248844, 11.5122035],
                [108.5249112, 11.5122376],
                [108.524977, 11.5123487],
                [108.5250054, 11.5123987],
                [108.5250265, 11.5124521],
                [108.5250396, 11.5125079],
                [108.5250446, 11.5125649],
                [108.5250413, 11.512622],
                [108.5250297, 11.5126789],
                [108.5250099, 11.5127336],
                [108.5249823, 11.512785],
                [108.5249625, 11.5128418],
                [108.5249501, 11.5129007],
                [108.5249453, 11.5129606],
                [108.5249483, 11.5130206],
                [108.5249589, 11.5130798],
                [108.524977, 11.5131372],
                [108.5249919, 11.5131716],
                [108.5250095, 11.5132047],
                [108.5250471, 11.5132697],
                [108.5250896, 11.5133317],
                [108.5251237, 11.5133749],
                [108.5251602, 11.5134163],
                [108.5252372, 11.5134953],
                [108.5253203, 11.5135682],
                [108.5254045, 11.5136315],
                [108.5254932, 11.5136885],
                [108.5255859, 11.513739],
                [108.525414, 11.5137355],
                [108.5252421, 11.5137407],
                [108.5250707, 11.5137544],
                [108.5249002, 11.5137767],
                [108.5247311, 11.5138075],
                [108.5245639, 11.5138468],
                [108.5244468, 11.5138796],
                [108.524331, 11.5139166],
                [108.5240972, 11.5139835],
                [108.5239612, 11.5140209],
                [108.5238231, 11.5140501],
                [108.5236834, 11.514071],
                [108.5235427, 11.5140835],
                [108.5234015, 11.5140877],
                [108.5232603, 11.5140834],
                [108.5231196, 11.5140707],
                [108.52298, 11.5140496],
                [108.5228419, 11.5140203],
                [108.5227477, 11.5139952],
                [108.5226546, 11.5139662],
                [108.5224694, 11.5139052],
                [108.5222868, 11.5138373],
                [108.522107, 11.5137627],
                [108.5219688, 11.5136998],
                [108.5218347, 11.5136287],
                [108.5217054, 11.5135497],
                [108.5215813, 11.5134631],
                [108.5214767, 11.5133807],
                [108.5213667, 11.5133054],
                [108.5212602, 11.5132419],
                [108.5211492, 11.5131864],
                [108.5210343, 11.5131391],
                [108.5209161, 11.5131004],
                [108.52085, 11.5130828],
                [108.5207832, 11.5130679],
                [108.5206488, 11.5130416],
                [108.5205133, 11.5130216],
                [108.520377, 11.5130079],
                [108.5202402, 11.5130005],
                [108.5201542, 11.5129991],
                [108.5200681, 11.5130003],
                [108.5198963, 11.513009],
                [108.519752, 11.5130215],
                [108.5196779, 11.5130333],
                [108.5196322, 11.5130468],
                [108.5195892, 11.5130671],
                [108.5195498, 11.5130936],
                [108.5195151, 11.5131257],
                [108.5194858, 11.5131627],
                [108.5194627, 11.5132036],
                [108.5194462, 11.5132476],
                [108.5194391, 11.5132786],
                [108.5194353, 11.5133101],
                [108.5194165, 11.5133708],
                [108.51939, 11.5134288],
                [108.5193564, 11.513483],
                [108.5193161, 11.5135327],
                [108.5192697, 11.5135771],
                [108.5192181, 11.5136154],
                [108.5191619, 11.513647],
                [108.5191116, 11.5136683],
                [108.5190593, 11.5136842],
                [108.5189519, 11.5137156],
                [108.5188508, 11.5137469],
                [108.5187786, 11.5137732],
                [108.5187096, 11.5138068],
                [108.5186445, 11.5138472],
                [108.5185841, 11.513894],
                [108.5185289, 11.5139467],
                [108.5184872, 11.513995],
                [108.5184431, 11.5140413],
                [108.518363, 11.5141148],
                [108.518278, 11.5141829],
                [108.5181886, 11.5142452],
                [108.518095, 11.5143014],
                [108.5179979, 11.5143514],
                [108.5179282, 11.5143824],
                [108.5178572, 11.5144101],
                [108.5177141, 11.5144627],
                [108.5175687, 11.5145091],
                [108.5175006, 11.5145285],
                [108.5174359, 11.5145567],
                [108.5173756, 11.5145932],
                [108.5173264, 11.5146324],
                [108.5172825, 11.5146772],
                [108.5172446, 11.514727],
                [108.5172132, 11.5147809],
                [108.5171888, 11.5148383],
                [108.5171717, 11.5148981],
                [108.5171632, 11.5149505],
                [108.5171604, 11.5150035],
                [108.5171561, 11.5151093],
                [108.5171589, 11.5152153],
                [108.5171687, 11.5153208],
                [108.5171855, 11.5154255],
                [108.5172093, 11.5155289],
                [108.5172297, 11.5155995],
                [108.5172438, 11.5156716],
                [108.5172513, 11.5157446],
                [108.5172522, 11.515818],
                [108.5172488, 11.5158704],
                [108.517242, 11.5159224],
                [108.5172234, 11.5160258],
                [108.5171964, 11.5161274],
                [108.5171612, 11.5162265],
                [108.5171181, 11.5163226],
                [108.5170897, 11.5163763],
                [108.5170678, 11.5164328],
                [108.5170526, 11.5164913],
                [108.5170443, 11.5165512],
                [108.517043, 11.5166117],
                [108.5170496, 11.5166776],
                [108.5170591, 11.5167431],
                [108.5170712, 11.5168077],
                [108.517074, 11.5168734],
                [108.5170674, 11.5169388],
                [108.5170516, 11.5170027],
                [108.5170269, 11.5170637],
                [108.5170108, 11.5170937],
                [108.5169926, 11.5171224],
                [108.5169639, 11.5171839],
                [108.5169411, 11.5172477],
                [108.5169266, 11.517302],
                [108.5169164, 11.5173572],
                [108.5169105, 11.5174129],
                [108.516889, 11.5175796],
                [108.5168767, 11.5177471],
                [108.5168739, 11.517839],
                [108.5168738, 11.517931],
                [108.5168767, 11.518115],
                [108.5168849, 11.5182897],
                [108.5168983, 11.5184641],
                [108.516917, 11.518638],
                [108.5160608, 11.5185121],
                [108.5159036, 11.5184907],
                [108.5157939, 11.5184844],
                [108.5156841, 11.5184854],
                [108.5155745, 11.5184937],
                [108.5154659, 11.5185092],
                [108.5153754, 11.5185279],
                [108.5152868, 11.5185535],
                [108.5152005, 11.5185859],
                [108.5151172, 11.5186249],
                [108.5150489, 11.5186631],
                [108.514986, 11.5187092],
                [108.5149293, 11.5187626],
                [108.5148798, 11.5188224],
                [108.5148382, 11.5188877],
                [108.5148174, 11.518929],
                [108.5147997, 11.5189718],
                [108.5147546, 11.5190526],
                [108.5147032, 11.5191298],
                [108.5146458, 11.5192027],
                [108.5145827, 11.5192711],
                [108.5145144, 11.5193344],
                [108.5144412, 11.5193923],
                [108.5143831, 11.5194322],
                [108.5143227, 11.5194686],
                [108.5142079, 11.5195502],
                [108.5140972, 11.519637],
                [108.5139906, 11.5197287],
                [108.5138911, 11.5198227],
                [108.5137873, 11.5199121],
                [108.5136795, 11.5199968],
                [108.5136053, 11.5200506],
                [108.5135274, 11.520099],
                [108.513446, 11.5201417],
                [108.5133617, 11.5201784],
                [108.5132749, 11.520209],
                [108.513186, 11.5202333],
                [108.5131052, 11.5202496],
                [108.5130236, 11.5202607],
                [108.5129413, 11.5202665],
                [108.5126978, 11.5203069],
                [108.5125679, 11.5203315],
                [108.51244, 11.5203652],
                [108.5123151, 11.5204079],
                [108.5121936, 11.5204594],
                [108.5121256, 11.5204927],
                [108.5120591, 11.5205289],
                [108.5119282, 11.5206047],
                [108.5118013, 11.5206868],
                [108.5116786, 11.5207747],
                [108.5116004, 11.5208357],
                [108.5115267, 11.5209017],
                [108.5114579, 11.5209727],
                [108.5113943, 11.5210481],
                [108.5113547, 11.5211009],
                [108.5113177, 11.5211554],
                [108.5112335, 11.5212572],
                [108.5111421, 11.5213529],
                [108.5110795, 11.5214113],
                [108.5110143, 11.5214667],
                [108.5108819, 11.5215754],
                [108.5107452, 11.5216788],
                [108.5106259, 11.5217623],
                [108.5105038, 11.5218418],
                [108.5102708, 11.5220005],
                [108.5100437, 11.522167],
                [108.5098225, 11.5223411],
                [108.50962, 11.5225118],
                [108.5094232, 11.5226888],
                [108.5092324, 11.522872],
                [108.5090534, 11.523034],
                [108.5088703, 11.5231914],
                [108.5086832, 11.5233442],
                [108.5084921, 11.5234922],
                [108.5082691, 11.5236556],
                [108.508042, 11.5238133],
                [108.5078108, 11.5239653],
                [108.5076167, 11.5240867],
                [108.5074199, 11.5242041],
                [108.5072207, 11.5243175],
                [108.5066441, 11.5246513],
                [108.5065774, 11.5246892],
                [108.5065145, 11.524733],
                [108.5064561, 11.5247823],
                [108.5064026, 11.5248367],
                [108.5063544, 11.5248957],
                [108.5063153, 11.5249533],
                [108.5062813, 11.5250139],
                [108.5062525, 11.525077],
                [108.5062234, 11.5251615],
                [108.5062037, 11.5252485],
                [108.5061936, 11.5253372],
                [108.5060731, 11.525319],
                [108.5059521, 11.5253057],
                [108.5058446, 11.5252979],
                [108.5057342, 11.5252966],
                [108.5056323, 11.5253115],
                [108.5055296, 11.5253373],
                [108.5054295, 11.5253714],
                [108.5053325, 11.5254134],
                [108.5052454, 11.5254596],
                [108.5051623, 11.5255122],
                [108.5050835, 11.5255709],
                [108.5050095, 11.5256353],
                [108.5049301, 11.5256973],
                [108.5048543, 11.5257674],
                [108.5047645, 11.525825],
                [108.5046716, 11.5258779],
                [108.5045761, 11.5259259],
                [108.5044838, 11.5259665],
                [108.5043882, 11.5259986],
                [108.5042899, 11.5260219],
                [108.5041898, 11.5260363],
                [108.5041022, 11.5260414],
                [108.5040144, 11.5260396],
                [108.503927, 11.526031],
                [108.5036345, 11.5260182],
                [108.5033423, 11.5259995],
                [108.5030367, 11.5259736],
                [108.5027307, 11.5259522],
                [108.5026085, 11.5259449],
                [108.5024869, 11.5259307],
                [108.5023663, 11.5259096],
                [108.5022472, 11.5258817],
                [108.5021299, 11.5258471],
                [108.5020573, 11.5258219],
                [108.5019857, 11.5257942],
                [108.501839, 11.5257486],
                [108.50169, 11.5257104],
                [108.5015929, 11.5256897],
                [108.501495, 11.5256721],
                [108.5012984, 11.5256432],
                [108.5011006, 11.5256235],
                [108.500902, 11.5256133],
                [108.5007031, 11.5256124],
                [108.5005045, 11.525621],
                [108.5003393, 11.5256354],
                [108.5001748, 11.5256563],
                [108.4998458, 11.5256966],
                [108.4995174, 11.5257419],
                [108.4992928, 11.525776],
                [108.4990692, 11.5258163],
                [108.4988469, 11.5258628],
                [108.4985906, 11.5259246],
                [108.4983366, 11.5259947],
                [108.4980851, 11.5260731],
                [108.4980623, 11.5262361],
                [108.4980488, 11.5264001],
                [108.4980445, 11.5265646],
                [108.4980494, 11.5267291],
                [108.4980637, 11.526893],
                [108.4980771, 11.5269942],
                [108.4980825, 11.527096],
                [108.4980798, 11.5271979],
                [108.498072, 11.5272769],
                [108.4980556, 11.5273547],
                [108.4980306, 11.5274302],
                [108.4979974, 11.5275026],
                [108.4979564, 11.5275711],
                [108.4979107, 11.5276316],
                [108.497859, 11.5276871],
                [108.497757, 11.5277993],
                [108.4976506, 11.5279075],
                [108.4975756, 11.5279789],
                [108.4974986, 11.5280483],
                [108.4973412, 11.5281833],
                [108.4971785, 11.5283122],
                [108.4970705, 11.5283924],
                [108.4969605, 11.5284699],
                [108.4967387, 11.5286223],
                [108.4966125, 11.5287056],
                [108.4964839, 11.5287853],
                [108.4964248, 11.5288202],
                [108.4963619, 11.528848],
                [108.496296, 11.5288683],
                [108.4962282, 11.5288807],
                [108.4961593, 11.5288851],
                [108.4960972, 11.5288822],
                [108.4960353, 11.5288875],
                [108.4959747, 11.5289009],
                [108.4959164, 11.5289221],
                [108.4958616, 11.5289508],
                [108.4958293, 11.5289725],
                [108.4958005, 11.5289985],
                [108.4957757, 11.5290283],
                [108.4957556, 11.5290612],
                [108.49574, 11.5290984],
                [108.4957302, 11.5291374],
                [108.4956797, 11.5292649],
                [108.4956239, 11.5293902],
                [108.4955627, 11.5295131],
                [108.4954964, 11.5296334],
                [108.4954443, 11.52972],
                [108.4953895, 11.529805],
                [108.495315, 11.5299241],
                [108.4951508, 11.5301414],
                [108.4951263, 11.5300846],
                [108.495094, 11.5300317],
                [108.4950545, 11.5299838],
                [108.4950086, 11.5299416],
                [108.4949591, 11.5299073],
                [108.4949052, 11.5298797],
                [108.4948519, 11.5298586],
                [108.4947972, 11.5298411],
                [108.4947327, 11.5298257],
                [108.4946671, 11.5298154],
                [108.4946009, 11.5298102],
                [108.494518, 11.5298029],
                [108.4944346, 11.5298021],
                [108.4943515, 11.5298076],
                [108.4942704, 11.5298193],
                [108.4941905, 11.5298371],
                [108.4941123, 11.5298609],
                [108.4940362, 11.5298905],
                [108.4939626, 11.5299259],
                [108.493888, 11.5299677],
                [108.4938164, 11.5300142],
                [108.493748, 11.5300652],
                [108.4936574, 11.5301233],
                [108.4935625, 11.5301742],
                [108.4934637, 11.5302176],
                [108.4933665, 11.5302507],
                [108.4932668, 11.5302758],
                [108.4931653, 11.5302925],
                [108.4930628, 11.5303007],
                [108.4929599, 11.5303005],
                [108.4928573, 11.5302918],
                [108.4927559, 11.5302746],
                [108.4927057, 11.5302634],
                [108.4926563, 11.5302491],
                [108.4925818, 11.5302212],
                [108.4925104, 11.5301864],
                [108.4924427, 11.530145],
                [108.4923794, 11.5300974],
                [108.4923489, 11.5300722],
                [108.4923211, 11.5300441],
                [108.4922893, 11.5300037],
                [108.4922631, 11.5299596],
                [108.4922429, 11.5299126],
                [108.4922291, 11.5298634],
                [108.4922218, 11.5298129],
                [108.4922189, 11.5297561],
                [108.4922111, 11.5296999],
                [108.4921941, 11.5296288],
                [108.4921695, 11.5295599],
                [108.4921375, 11.529494],
                [108.4920984, 11.5294318],
                [108.4920412, 11.5293478],
                [108.4919804, 11.5292663],
                [108.4919495, 11.5292258],
                [108.4919161, 11.5291874],
                [108.4918602, 11.5291326],
                [108.4917992, 11.5290834],
                [108.4917676, 11.5290601],
                [108.4917337, 11.5290402],
                [108.4916815, 11.529018],
                [108.4916265, 11.5290037],
                [108.49157, 11.5289978],
                [108.4915132, 11.5290003],
                [108.4914574, 11.5290113],
                [108.4913799, 11.5290309],
                [108.4913018, 11.5290481],
                [108.4911519, 11.5290743],
                [108.4910006, 11.5290918],
                [108.4908485, 11.5291006],
                [108.4906546, 11.5291066],
                [108.4904605, 11.5291066],
                [108.4902665, 11.5291006],
                [108.4900886, 11.5291084],
                [108.4899106, 11.5291075],
                [108.4897328, 11.5290979],
                [108.4895557, 11.5290796],
                [108.4893789, 11.5290526],
                [108.4892037, 11.5290169],
                [108.4890305, 11.5289726],
                [108.4888598, 11.5289199],
                [108.4887752, 11.5288914],
                [108.488692, 11.5288589],
                [108.4885943, 11.5288145],
                [108.4884993, 11.5287645],
                [108.4884075, 11.5287092],
                [108.4883192, 11.5286486],
                [108.4882271, 11.5285938],
                [108.4881315, 11.5285449],
                [108.4880329, 11.5285021],
                [108.4879318, 11.5284657],
                [108.487878, 11.5284502],
                [108.487819, 11.5284436],
                [108.4877527, 11.5284414],
                [108.4876769, 11.5284425],
                [108.4876011, 11.5284388],
                [108.4875025, 11.528427],
                [108.4874106, 11.5284072],
                [108.4873295, 11.5283753],
                [108.4872436, 11.5283307],
                [108.487162, 11.5282789],
                [108.4870854, 11.5282202],
                [108.4870075, 11.5281505],
                [108.4869357, 11.5280747],
                [108.4868703, 11.5279936],
                [108.4868423, 11.5279497],
                [108.4868118, 11.5279075],
                [108.4867538, 11.5278392],
                [108.4866892, 11.5277768],
                [108.4866187, 11.5277209],
                [108.4865624, 11.527669],
                [108.48651, 11.5276134],
                [108.4864616, 11.5275544],
                [108.4864175, 11.5274922],
                [108.4863573, 11.5274225],
                [108.48629, 11.5273592],
                [108.4862164, 11.527303],
                [108.4861483, 11.52724],
                [108.4860869, 11.5271708],
                [108.4860325, 11.5270961],
                [108.4860107, 11.5270554],
                [108.4859857, 11.5270166],
                [108.4859404, 11.5269603],
                [108.4858883, 11.5269101],
                [108.4858301, 11.5268668],
                [108.4857438, 11.5267966],
                [108.4857043, 11.5267574],
                [108.4856611, 11.5267222],
                [108.485622, 11.5266956],
                [108.4855807, 11.5266723],
                [108.4855259, 11.5266339],
                [108.4854759, 11.5265896],
                [108.4854313, 11.5265401],
                [108.4853928, 11.526486],
                [108.4853607, 11.5264279],
                [108.4853159, 11.5263394],
                [108.4852749, 11.5262492],
                [108.4852338, 11.5261757],
                [108.4851873, 11.5261055],
                [108.4851354, 11.5260389],
                [108.4850594, 11.5259513],
                [108.4849878, 11.5258601],
                [108.4849209, 11.5257656],
                [108.4848416, 11.5256391],
                [108.4847707, 11.525508],
                [108.4846978, 11.5253608],
                [108.4846307, 11.5252109],
                [108.4846021, 11.525134],
                [108.4845695, 11.5250586],
                [108.4845189, 11.5249589],
                [108.4844666, 11.5248681],
                [108.4843969, 11.524771],
                [108.4843262, 11.5246838],
                [108.4841913, 11.524575],
                [108.4840799, 11.5244794],
                [108.4840265, 11.524429],
                [108.4839714, 11.5243806],
                [108.4838757, 11.5243041],
                [108.4838275, 11.5242661],
                [108.4837756, 11.5242334],
                [108.4837152, 11.5242041],
                [108.4836518, 11.5241818],
                [108.4835862, 11.5241669],
                [108.4835192, 11.5241596],
                [108.4834519, 11.5241599],
                [108.483385, 11.5241679],
                [108.4833196, 11.5241834],
                [108.483179, 11.5242237],
                [108.4830414, 11.5242727],
                [108.4829073, 11.5243302],
                [108.4827772, 11.524396],
                [108.4827138, 11.5244319],
                [108.4826517, 11.5244699],
                [108.4825358, 11.5245493],
                [108.4824257, 11.5246361],
                [108.4823717, 11.5246809],
                [108.4823218, 11.5247301],
                [108.4822714, 11.5247897],
                [108.4822271, 11.5248537],
                [108.4821892, 11.5249216],
                [108.4821581, 11.5249928],
                [108.482134, 11.5250665],
                [108.4820884, 11.5251838],
                [108.4820658, 11.5252427],
                [108.4820348, 11.5252978],
                [108.481995, 11.525349],
                [108.4819478, 11.5253939],
                [108.4818944, 11.5254315],
                [108.4818359, 11.525461],
                [108.4817736, 11.5254816],
                [108.4817089, 11.525493],
                [108.4816761, 11.5254952],
                [108.4816432, 11.5254949],
                [108.481585, 11.5254883],
                [108.4815283, 11.5254741],
                [108.481474, 11.5254525],
                [108.4814233, 11.5254239],
                [108.4813252, 11.5253752],
                [108.481224, 11.5253331],
                [108.4811202, 11.5252978],
                [108.4809617, 11.525237],
                [108.4808063, 11.525169],
                [108.4807313, 11.5251306],
                [108.4806533, 11.5250981],
                [108.480573, 11.5250718],
                [108.4804749, 11.5250487],
                [108.480375, 11.5250348],
                [108.4802741, 11.5250302],
                [108.4801733, 11.525035],
                [108.4799185, 11.5250428],
                [108.4797147, 11.525043],
                [108.4795108, 11.5250376],
                [108.4792371, 11.5250266],
                [108.4791006, 11.525015],
                [108.4789637, 11.5250113],
                [108.4788293, 11.5250155],
                [108.4786954, 11.5250274],
                [108.4785625, 11.5250469],
                [108.4784309, 11.525074],
                [108.4783012, 11.5251085],
                [108.4782445, 11.5251312],
                [108.4781863, 11.5251496],
                [108.4781268, 11.5251637],
                [108.4780437, 11.5251759],
                [108.4779598, 11.5251797],
                [108.4778759, 11.525175],
                [108.4777929, 11.525162],
                [108.4777118, 11.5251406],
                [108.4776333, 11.5251112],
                [108.4761836, 11.5246026],
                [108.4760669, 11.524554],
                [108.4759552, 11.5244994],
                [108.4758121, 11.5244226],
                [108.4756701, 11.5243378],
                [108.4755323, 11.5242466],
                [108.475399, 11.5241493],
                [108.4753173, 11.5241862],
                [108.4752381, 11.5242281],
                [108.4751642, 11.5242733],
                [108.4750932, 11.5243227],
                [108.4749752, 11.5243957],
                [108.4748523, 11.5244605],
                [108.4747252, 11.5245169],
                [108.4745943, 11.5245645],
                [108.4743895, 11.5246311],
                [108.474182, 11.524689],
                [108.4739721, 11.524738],
                [108.4734571, 11.5244962],
                [108.4732794, 11.5244161],
                [108.473103, 11.5243333],
                [108.4729334, 11.5242504],
                [108.4728501, 11.5242061],
                [108.4727651, 11.5241651],
                [108.4726477, 11.524115],
                [108.4725278, 11.5240711],
                [108.4724057, 11.5240336],
                [108.4722636, 11.5239049],
                [108.4721267, 11.5237708],
                [108.4720397, 11.5236799],
                [108.4719551, 11.5235869],
                [108.4718564, 11.5234887],
                [108.4717526, 11.5233958],
                [108.4716439, 11.5233083],
                [108.4715513, 11.5232275],
                [108.4714619, 11.5231433],
                [108.4714175, 11.5231009],
                [108.4713757, 11.523056],
                [108.4713219, 11.5229892],
                [108.4712738, 11.5229184],
                [108.4712318, 11.5228439],
                [108.4711748, 11.5227243],
                [108.4709889, 11.5222372],
                [108.4707753, 11.5217105],
                [108.4705555, 11.5211862],
                [108.4703296, 11.5206643],
                [108.4701073, 11.5201665],
                [108.4698794, 11.5196712],
                [108.469646, 11.5191783],
                [108.469407, 11.518688],
                [108.4679639, 11.5174159],
                [108.4678745, 11.5173381],
                [108.4677814, 11.5172645],
                [108.467685, 11.5171951],
                [108.4675611, 11.5171154],
                [108.4674994, 11.517075],
                [108.4674329, 11.5170427],
                [108.4673656, 11.5170197],
                [108.467296, 11.5170049],
                [108.4672251, 11.5169985],
                [108.4671539, 11.5170007],
                [108.4669876, 11.5170059],
                [108.4669041, 11.5170176],
                [108.4668197, 11.5170211],
                [108.4667355, 11.5170164],
                [108.4666584, 11.5170049],
                [108.4665827, 11.5169865],
                [108.466509, 11.5169614],
                [108.466438, 11.5169298],
                [108.4663703, 11.5168919],
                [108.4663063, 11.5168482],
                [108.4662063, 11.5167872],
                [108.4661556, 11.5167577],
                [108.4661025, 11.5167326],
                [108.466035, 11.5167083],
                [108.4659653, 11.5166911],
                [108.4658942, 11.5166812],
                [108.4658224, 11.5166788],
                [108.4657507, 11.5166839],
                [108.46568, 11.5166965],
                [108.465611, 11.5167162],
                [108.4655446, 11.5167431],
                [108.4653897, 11.5168126],
                [108.4652388, 11.5168903],
                [108.4649886, 11.5170217],
                [108.4649311, 11.5170629],
                [108.4648701, 11.5170991],
                [108.4648062, 11.5171301],
                [108.4647399, 11.5171557],
                [108.4646686, 11.5171764],
                [108.4645958, 11.5171908],
                [108.4645219, 11.5171988],
                [108.4644476, 11.5172004],
                [108.4643462, 11.5172093],
                [108.4642458, 11.517226],
                [108.4641961, 11.5172365],
                [108.4641471, 11.5172503],
                [108.4640996, 11.5172672],
                [108.4640533, 11.5172871],
                [108.4640099, 11.5173017],
                [108.4639688, 11.5173082],
                [108.4639355, 11.5173111],
                [108.4638863, 11.5173106],
                [108.4638386, 11.5173007],
                [108.4637971, 11.5172871],
                [108.4637629, 11.5172695],
                [108.4637307, 11.5172549],
                [108.4637029, 11.5172468],
                [108.4636687, 11.5172441],
                [108.4636389, 11.5172487],
                [108.4636061, 11.5172605],
                [108.4635764, 11.5172783],
                [108.4635507, 11.5173015],
                [108.4635302, 11.5173292],
                [108.4634845, 11.5173952],
                [108.4634417, 11.5174632],
                [108.4633836, 11.5175462],
                [108.463321, 11.5176261],
                [108.463289, 11.5176655],
                [108.463254, 11.5177024],
                [108.4631952, 11.5177533],
                [108.4631641, 11.5177769],
                [108.4631306, 11.517797],
                [108.463078, 11.5178195],
                [108.4630224, 11.5178333],
                [108.4629652, 11.5178381],
                [108.462908, 11.5178338],
                [108.4628341, 11.5178212],
                [108.462762, 11.517801],
                [108.4626926, 11.5177734],
                [108.4626265, 11.5177386],
                [108.4625646, 11.5176971],
                [108.4624544, 11.5176271],
                [108.4623992, 11.5175922],
                [108.462342, 11.5175605],
                [108.4622624, 11.5175229],
                [108.4621802, 11.5174914],
                [108.4621362, 11.5174764],
                [108.4620956, 11.5174636],
                [108.4620484, 11.5174539],
                [108.4620161, 11.5174494],
                [108.4619639, 11.5174503],
                [108.4619092, 11.5174572],
                [108.4618576, 11.5174687],
                [108.4618056, 11.517484],
                [108.4617553, 11.5175086],
                [108.4616983, 11.5175394],
                [108.4616541, 11.5175612],
                [108.4616069, 11.5175761],
                [108.461558, 11.5175836],
                [108.4615085, 11.5175837],
                [108.4614596, 11.5175762],
                [108.4613885, 11.5175752],
                [108.4613201, 11.5175818],
                [108.4612544, 11.5175959],
                [108.4611833, 11.5176065],
                [108.4611188, 11.5175992],
                [108.4610398, 11.5175854],
                [108.4609342, 11.5175888],
                [108.4608405, 11.5176054],
                [108.4607527, 11.5176468],
                [108.4606549, 11.5177024],
                [108.4605741, 11.5177522],
                [108.4604746, 11.5178128],
                [108.460378, 11.5178628],
                [108.4603129, 11.5178942],
                [108.4601933, 11.5179597],
                [108.4600816, 11.518015],
                [108.4599661, 11.5180625],
                [108.4599076, 11.5180844],
                [108.4598476, 11.5181019],
                [108.4597691, 11.5181175],
                [108.4596895, 11.5181256],
                [108.4596095, 11.518126],
                [108.4595298, 11.5181187],
                [108.4594512, 11.5181039],
                [108.4593745, 11.5180815],
                [108.4593004, 11.5180519],
                [108.459235, 11.5180327],
                [108.4591682, 11.5180193],
                [108.4591004, 11.5180116],
                [108.4590322, 11.5180099],
                [108.4589575, 11.5179994],
                [108.4588847, 11.51798],
                [108.4588503, 11.5179648],
                [108.4588149, 11.5179521],
                [108.4587506, 11.5179357],
                [108.4586846, 11.5179272],
                [108.4586181, 11.5179269],
                [108.4585521, 11.5179346],
                [108.4584876, 11.5179503],
                [108.4584256, 11.5179738],
                [108.458367, 11.5180046],
                [108.4582767, 11.518048],
                [108.4581846, 11.5180875],
                [108.4580907, 11.5181229],
                [108.4579705, 11.5181622],
                [108.4578484, 11.5181955],
                [108.4577248, 11.5182227],
                [108.4576625, 11.5182345],
                [108.4575999, 11.5182438],
                [108.4574821, 11.5182544],
                [108.4573639, 11.5182561],
                [108.4572458, 11.518249],
                [108.4571384, 11.5182496],
                [108.4570312, 11.5182568],
                [108.4569247, 11.5182707],
                [108.4568717, 11.5182794],
                [108.4568194, 11.5182911],
                [108.4567334, 11.5183173],
                [108.4566926, 11.5183361],
                [108.4566504, 11.5183515],
                [108.4565863, 11.5183681],
                [108.4565207, 11.518377],
                [108.4564545, 11.5183782],
                [108.4563885, 11.5183716],
                [108.4563239, 11.5183574],
                [108.4562615, 11.5183358],
                [108.4561505, 11.5182893],
                [108.4560439, 11.518234],
                [108.4559944, 11.5182001],
                [108.4559423, 11.5181702],
                [108.4558861, 11.5181436],
                [108.4558278, 11.5181217],
                [108.4557679, 11.5181045],
                [108.4556957, 11.5180927],
                [108.4556224, 11.51809],
                [108.4555495, 11.5180967],
                [108.455478, 11.5181124],
                [108.4554092, 11.5181371],
                [108.4553441, 11.5181702],
                [108.4552283, 11.5182258],
                [108.4551108, 11.518278],
                [108.4549903, 11.5183364],
                [108.4549532, 11.5183611],
                [108.4549136, 11.518382],
                [108.4548721, 11.5183989],
                [108.4548175, 11.5184141],
                [108.4547614, 11.5184223],
                [108.4547046, 11.5184233],
                [108.4546483, 11.5184172],
                [108.4546204, 11.5184119],
                [108.4545931, 11.5184041],
                [108.4545522, 11.5183872],
                [108.4545142, 11.5183649],
                [108.4544797, 11.5183375],
                [108.4544495, 11.5183057],
                [108.4544241, 11.5182701],
                [108.4544002, 11.518226],
                [108.4543822, 11.5181793],
                [108.4543705, 11.5181308],
                [108.4543381, 11.5180118],
                [108.4543008, 11.5178942],
                [108.4542581, 11.5177798],
                [108.4542098, 11.5176676],
                [108.4541848, 11.5176118],
                [108.4541559, 11.5175578],
                [108.4541217, 11.5175039],
                [108.4541037, 11.5174775],
                [108.4540835, 11.5174527],
                [108.4540413, 11.5174122],
                [108.4539931, 11.5173786],
                [108.4539402, 11.5173528],
                [108.4538838, 11.5173354],
                [108.4538254, 11.5173269],
                [108.4537663, 11.5173275],
                [108.4536892, 11.5173344],
                [108.4535999, 11.5173443],
                [108.4534916, 11.5173451],
                [108.4533834, 11.5173397],
                [108.4532757, 11.5173279],
                [108.4531689, 11.5173099],
                [108.4530634, 11.5172856],
                [108.4529595, 11.5172552],
                [108.4528577, 11.5172188],
                [108.4527203, 11.5171665],
                [108.4526527, 11.5171375],
                [108.4525841, 11.517111],
                [108.4524793, 11.517076],
                [108.4523726, 11.5170467],
                [108.4522644, 11.5170233],
                [108.452155, 11.5170059],
                [108.4519973, 11.5169851],
                [108.451839, 11.5169694],
                [108.4517595, 11.516966],
                [108.4516802, 11.5169586],
                [108.4515613, 11.5169397],
                [108.4514442, 11.5169116],
                [108.4513298, 11.5168746],
                [108.4512187, 11.5168289],
                [108.4511116, 11.5167746],
                [108.4510692, 11.5167491],
                [108.4510244, 11.516728],
                [108.4509775, 11.5167116],
                [108.450917, 11.5166978],
                [108.4508551, 11.5166919],
                [108.450793, 11.5166938],
                [108.4507317, 11.5167035],
                [108.4506722, 11.5167209],
                [108.4506154, 11.5167457],
                [108.4505068, 11.5167864],
                [108.4503955, 11.5168193],
                [108.4502499, 11.5168574],
                [108.4501031, 11.5168903],
                [108.4496525, 11.5169849],
                [108.4494477, 11.5170286],
                [108.4492421, 11.517069],
                [108.4490091, 11.5171106],
                [108.4488918, 11.5171264],
                [108.4487754, 11.5171478],
                [108.4486512, 11.517177],
                [108.4485286, 11.5172125],
                [108.4484082, 11.5172541],
                [108.44829, 11.5173018],
                [108.4481746, 11.5173555],
                [108.4480413, 11.5174129],
                [108.4479062, 11.5174664],
                [108.4477696, 11.5175158],
                [108.4475899, 11.5175728],
                [108.4474076, 11.5176216],
                [108.4472233, 11.5176622],
                [108.4470373, 11.5176945],
                [108.4468739, 11.5177167],
                [108.4467097, 11.5177333],
                [108.4464781, 11.5177496],
                [108.4462097, 11.5177617],
                [108.4460389, 11.5177687],
                [108.4458679, 11.5177707],
                [108.4456771, 11.517767],
                [108.4454865, 11.5177569],
                [108.4452964, 11.5177406],
                [108.445107, 11.517718],
                [108.4450129, 11.517702],
                [108.4449184, 11.5176892],
                [108.444806, 11.5176784],
                [108.4446933, 11.5176723],
                [108.4445804, 11.5176708],
                [108.4443938, 11.5176621],
                [108.4442077, 11.5176454],
                [108.4440225, 11.5176209],
                [108.4436923, 11.517581],
                [108.4433614, 11.517546],
                [108.4431956, 11.517533],
                [108.4430301, 11.5175158],
                [108.4427888, 11.5174828],
                [108.442549, 11.5174408],
                [108.442311, 11.5173898],
                [108.4420752, 11.5173297],
                [108.4418419, 11.5172608],
                [108.4415313, 11.5171724],
                [108.4413747, 11.5171325],
                [108.4412196, 11.5170874],
                [108.4410629, 11.5170358],
                [108.4409082, 11.5169789],
                [108.4408311, 11.5169495],
                [108.4407556, 11.5169166],
                [108.4406491, 11.5168625],
                [108.4405466, 11.5168014],
                [108.4404488, 11.5167334],
                [108.440356, 11.516659],
                [108.4402706, 11.5165824],
                [108.4402311, 11.5165409],
                [108.4401897, 11.5165013],
                [108.4401151, 11.5164386],
                [108.4400356, 11.5163821],
                [108.4399517, 11.5163319],
                [108.4398639, 11.5162886],
                [108.4397729, 11.5162522],
                [108.4396792, 11.5162232],
                [108.4395835, 11.5162017],
                [108.4394708, 11.5161675],
                [108.439377, 11.5161461],
                [108.439282, 11.5161306],
                [108.4391862, 11.5161211],
                [108.43909, 11.5161176],
                [108.4390296, 11.5161207],
                [108.4389693, 11.5161202],
                [108.4388803, 11.5161127],
                [108.4387925, 11.5160972],
                [108.4387065, 11.5160739],
                [108.438623, 11.5160429],
                [108.4385428, 11.5160046],
                [108.4384447, 11.5159651],
                [108.4383441, 11.5159326],
                [108.4382414, 11.515907],
                [108.4381371, 11.5158885],
                [108.4380318, 11.5158772],
                [108.4379259, 11.5158732],
                [108.4376577, 11.5158674],
                [108.4375236, 11.5158692],
                [108.4373894, 11.5158679],
                [108.4371832, 11.5158596],
                [108.4369775, 11.5158439],
                [108.4367725, 11.5158206],
                [108.4366275, 11.5158009],
                [108.4365548, 11.5157921],
                [108.4364828, 11.5157785],
                [108.4363957, 11.5157554],
                [108.4363109, 11.5157252],
                [108.4362289, 11.5156882],
                [108.4361503, 11.5156447],
                [108.4360756, 11.515595],
                [108.4360054, 11.5155394],
                [108.4359165, 11.5154638],
                [108.4358306, 11.5153849],
                [108.4357479, 11.5153028],
                [108.4356755, 11.5152211],
                [108.4356083, 11.5151351],
                [108.4355468, 11.5150453],
                [108.4354872, 11.5149702],
                [108.4354227, 11.5148992],
                [108.4353536, 11.5148324],
                [108.4352722, 11.5147607],
                [108.4351873, 11.5146931],
                [108.4351088, 11.5146225],
                [108.4350346, 11.5145476],
                [108.4349651, 11.5144685],
                [108.4349003, 11.5143856],
                [108.4348306, 11.5142837],
                [108.4347679, 11.5141776],
                [108.4347423, 11.5141216],
                [108.4347126, 11.5140676],
                [108.434675, 11.5140105],
                [108.4346328, 11.5139566],
                [108.4345862, 11.5139063],
                [108.4345356, 11.5138599],
                [108.4344754, 11.5138147],
                [108.4344108, 11.5137756],
                [108.4343425, 11.5137432],
                [108.4342712, 11.5137178],
                [108.4341976, 11.5136996],
                [108.4340829, 11.5136761],
                [108.4339671, 11.5136581],
                [108.4338506, 11.5136458],
                [108.4337336, 11.5136392],
                [108.4335807, 11.5136376],
                [108.433428, 11.5136441],
                [108.4332758, 11.5136587],
                [108.4331247, 11.5136812],
                [108.4329981, 11.5136943],
                [108.4328711, 11.5137022],
                [108.4305686, 11.5137995],
                [108.4303526, 11.5138857],
                [108.4301394, 11.5139782],
                [108.4299546, 11.5140646],
                [108.4297722, 11.5141557],
                [108.4295922, 11.5142515],
                [108.4292898, 11.5144103],
                [108.4291395, 11.5144917],
                [108.4289861, 11.5145669],
                [108.4288264, 11.5146373],
                [108.428664, 11.5147012],
                [108.4284989, 11.5147583],
                [108.4283316, 11.5148087],
                [108.4281331, 11.5148774],
                [108.4279379, 11.5149547],
                [108.4277545, 11.5150287],
                [108.4276731, 11.5150768],
                [108.4276182, 11.5151144],
                [108.4275363, 11.5151596],
                [108.4274485, 11.5152008],
                [108.4273419, 11.5152731],
                [108.4272397, 11.5153511],
                [108.4271233, 11.5154347],
                [108.4270208, 11.5154915],
                [108.4268966, 11.515542],
                [108.4267882, 11.5155801],
                [108.4266782, 11.5156134],
                [108.4265667, 11.5156419],
                [108.4265114, 11.5156587],
                [108.4264577, 11.5156798],
                [108.4264058, 11.5157049],
                [108.4263496, 11.5157382],
                [108.4262967, 11.5157761],
                [108.4262234, 11.5158533],
                [108.4261537, 11.5159336],
                [108.426093, 11.5160099],
                [108.426066, 11.5160505],
                [108.4260356, 11.5160887],
                [108.4259869, 11.5161383],
                [108.4259324, 11.5161818],
                [108.4258729, 11.5162186],
                [108.4258093, 11.516248],
                [108.4257425, 11.5162696],
                [108.4256735, 11.5162832],
                [108.4255937, 11.5162987],
                [108.4255153, 11.5163199],
                [108.4254242, 11.5163526],
                [108.4253363, 11.516393],
                [108.4252524, 11.5164408],
                [108.4251496, 11.5164906],
                [108.4250959, 11.5165104],
                [108.4250432, 11.5165328],
                [108.4249622, 11.5165737],
                [108.4248847, 11.5166207],
                [108.4248113, 11.5166736],
                [108.4247424, 11.516732],
                [108.4247094, 11.5167629],
                [108.4246784, 11.5167957],
                [108.424627, 11.5168604],
                [108.4245831, 11.5169303],
                [108.4245473, 11.5170045],
                [108.4245202, 11.5170821],
                [108.4244793, 11.5171827],
                [108.4244328, 11.517281],
                [108.4243807, 11.5173765],
                [108.4242783, 11.5175564],
                [108.4241715, 11.5177339],
                [108.424098, 11.5178467],
                [108.4240576, 11.5179006],
                [108.4240213, 11.5179573],
                [108.4239826, 11.5180302],
                [108.4239507, 11.5181061],
                [108.4239339, 11.5181714],
                [108.4239136, 11.5182582],
                [108.4238979, 11.5183384],
                [108.4238881, 11.5183986],
                [108.4238818, 11.5184514],
                [108.4238789, 11.5185203],
                [108.423882, 11.5185891],
                [108.4238911, 11.5186574],
                [108.423906, 11.5187248],
                [108.4239185, 11.518807],
                [108.4239249, 11.5188899],
                [108.4239252, 11.518973],
                [108.4239194, 11.5190559],
                [108.4239031, 11.5191828],
                [108.423878, 11.5193082],
                [108.4238443, 11.5194317],
                [108.4238099, 11.5195732],
                [108.4237799, 11.5197156],
                [108.4237588, 11.519842],
                [108.4237427, 11.5199691],
                [108.4237385, 11.520033],
                [108.4237316, 11.5200967],
                [108.4237178, 11.5201835],
                [108.423699, 11.5202695],
                [108.4236857, 11.5203115],
                [108.4236753, 11.5203542],
                [108.4236641, 11.5204305],
                [108.423662, 11.5205075],
                [108.4236691, 11.5205842],
                [108.4236852, 11.5206596],
                [108.4236989, 11.5206957],
                [108.4237102, 11.5207327],
                [108.4237232, 11.5207957],
                [108.4237289, 11.5208597],
                [108.4237272, 11.520924],
                [108.4237182, 11.5209876],
                [108.4237054, 11.5211178],
                [108.4237, 11.5212485],
                [108.4237021, 11.5213792],
                [108.4237021, 11.5216302],
                [108.4236968, 11.5218812],
                [108.4237032, 11.5219995],
                [108.4237023, 11.5221135],
                [108.4236928, 11.522227],
                [108.4236748, 11.5223396],
                [108.4236483, 11.5224505],
                [108.4236136, 11.5225593],
                [108.4235679, 11.5226717],
                [108.4235251, 11.5227853],
                [108.4234957, 11.5228689],
                [108.4234598, 11.5229502],
                [108.4234177, 11.5230284],
                [108.4233789, 11.5230941],
                [108.4233295, 11.5231703],
                [108.4232802, 11.5232331],
                [108.4232283, 11.5232919],
                [108.4231818, 11.5233346],
                [108.4231179, 11.5233923],
                [108.4230481, 11.5234429],
                [108.422973, 11.5234857],
                [108.4228937, 11.5235203],
                [108.4228109, 11.5235462],
                [108.4227258, 11.5235632],
                [108.4225977, 11.5235949],
                [108.4224715, 11.5236336],
                [108.4223478, 11.5236791],
                [108.4222884, 11.5237075],
                [108.4222269, 11.5237314],
                [108.4221479, 11.5237545],
                [108.4220671, 11.5237702],
                [108.421985, 11.5237781],
                [108.4219026, 11.5237783],
                [108.4218205, 11.5237708],
                [108.4217396, 11.5237556],
                [108.4216605, 11.5237329],
                [108.421584, 11.5237028],
                [108.4215108, 11.5236657],
                [108.4214535, 11.5237599],
                [108.4213885, 11.5238492],
                [108.4213161, 11.5239328],
                [108.4212369, 11.5240103],
                [108.4211513, 11.5240809],
                [108.4210885, 11.5241258],
                [108.4210232, 11.524167],
                [108.4209555, 11.5242045],
                [108.4208532, 11.5242574],
                [108.4208017, 11.5242834],
                [108.420749, 11.524307],
                [108.420648, 11.5243441],
                [108.420544, 11.5243722],
                [108.4204907, 11.5243804],
                [108.4204379, 11.5243911],
                [108.4203605, 11.5244115],
                [108.4202849, 11.5244372],
                [108.4202113, 11.5244681],
                [108.4201401, 11.5245041],
                [108.4200373, 11.5245648],
                [108.4199396, 11.5246333],
                [108.4198478, 11.5247091],
                [108.4197625, 11.5247901],
                [108.4196708, 11.5248641],
                [108.419588, 11.5249213],
                [108.4195014, 11.5249727],
                [108.4194114, 11.5250181],
                [108.4193184, 11.5250573],
                [108.4192228, 11.5250902],
                [108.4188822, 11.5252058],
                [108.418629, 11.5252982],
                [108.4183778, 11.5253954],
                [108.4181285, 11.5254975],
                [108.417984, 11.5255571],
                [108.4178415, 11.525621],
                [108.4176544, 11.5257132],
                [108.417562, 11.5257614],
                [108.4174713, 11.5258129],
                [108.4173543, 11.5258859],
                [108.4172409, 11.5259642],
                [108.4171314, 11.5260476],
                [108.4170395, 11.5261138],
                [108.4169684, 11.5261756],
                [108.4169143, 11.526237],
                [108.4168524, 11.5263057],
                [108.4167766, 11.5263732],
                [108.4166821, 11.526439],
                [108.4165826, 11.5264977],
                [108.4164789, 11.5265488],
                [108.4163666, 11.5265938],
                [108.4162509, 11.5266299],
                [108.4161327, 11.5266568],
                [108.4160126, 11.5266745],
                [108.4158915, 11.5266828],
                [108.4156528, 11.526872],
                [108.4155786, 11.5269148],
                [108.4155015, 11.5269525],
                [108.4154221, 11.526985],
                [108.4153301, 11.5270153],
                [108.4152361, 11.5270386],
                [108.4151405, 11.5270548],
                [108.4150439, 11.5270639],
                [108.4148651, 11.5270743],
                [108.4146566, 11.5270691],
                [108.4143814, 11.5270534],
                [108.4140649, 11.5270192],
                [108.413821, 11.5269801],
                [108.4136267, 11.5269378],
                [108.4133702, 11.5268878],
                [108.4129974, 11.5268247],
                [108.4127989, 11.52678],
                [108.4126353, 11.5267351],
                [108.4125247, 11.5266927],
                [108.4122993, 11.5266191],
                [108.4120721, 11.5265514],
                [108.4119488, 11.5265174],
                [108.4118278, 11.5264761],
                [108.4117096, 11.5264278],
                [108.4115946, 11.5263727],
                [108.4115168, 11.5263304],
                [108.4114407, 11.5262849],
                [108.4112889, 11.526194],
                [108.4112219, 11.5261525],
                [108.4111603, 11.5261037],
                [108.4111049, 11.5260483],
                [108.4110563, 11.525987],
                [108.4110153, 11.5259206],
                [108.4109924, 11.5258741],
                [108.4109732, 11.525826],
                [108.4109357, 11.5257296],
                [108.4109026, 11.5256315],
                [108.4108787, 11.5255494],
                [108.4108581, 11.5254664],
                [108.4108168, 11.5253004],
                [108.4107976, 11.525218],
                [108.4107697, 11.525138],
                [108.4107336, 11.5250612],
                [108.4106951, 11.5249968],
                [108.4106516, 11.5249356],
                [108.4106034, 11.5248778],
                [108.4105608, 11.5248336],
                [108.4105154, 11.5247921],
                [108.4104216, 11.5247123],
                [108.4103225, 11.524639],
                [108.4102187, 11.5245724],
                [108.4101597, 11.5245388],
                [108.4101059, 11.5244977],
                [108.4100582, 11.52445],
                [108.4100175, 11.5243963],
                [108.4099873, 11.5243435],
                [108.4099514, 11.5242943],
                [108.4099102, 11.5242491],
                [108.4098756, 11.5242179],
                [108.4098385, 11.5241896],
                [108.4097707, 11.5241256],
                [108.4097042, 11.5240542],
                [108.4096339, 11.5239863],
                [108.4095723, 11.5239324],
                [108.4095082, 11.5238813],
                [108.4093866, 11.5237717],
                [108.4092692, 11.5236578],
                [108.4091625, 11.5235466],
                [108.40906, 11.5234318],
                [108.4074694, 11.522462],
                [108.4073144, 11.5223638],
                [108.4071543, 11.522274],
                [108.4069894, 11.5221927],
                [108.4068203, 11.5221204],
                [108.4066793, 11.5220679],
                [108.4065362, 11.5220217],
                [108.406391, 11.5219816],
                [108.4062442, 11.5219479],
                [108.4060666, 11.5219232],
                [108.4038591, 11.5216604],
                [108.403658, 11.5216394],
                [108.4035312, 11.5216168],
                [108.4033468, 11.5215816],
                [108.4032772, 11.5215667],
                [108.4032098, 11.5215439],
                [108.4031456, 11.5215135],
                [108.4030855, 11.521476],
                [108.4030303, 11.5214318],
                [108.4029987, 11.5214011],
                [108.4029694, 11.5213682],
                [108.4029032, 11.5213099],
                [108.4028321, 11.5212574],
                [108.4027567, 11.521211],
                [108.4026957, 11.5211795],
                [108.4026326, 11.521152],
                [108.4025056, 11.5210994],
                [108.4023759, 11.5210533],
                [108.4022479, 11.5210149],
                [108.4021217, 11.5209711],
                [108.4019977, 11.5209219],
                [108.401869, 11.5208641],
                [108.4017429, 11.520801],
                [108.4015927, 11.5207169],
                [108.4015371, 11.5206874],
                [108.4014786, 11.5206643],
                [108.4014177, 11.5206478],
                [108.4013553, 11.5206381],
                [108.4012923, 11.5206354],
                [108.40122, 11.520641],
                [108.4011475, 11.5206389],
                [108.4010756, 11.5206291],
                [108.4010053, 11.5206118],
                [108.4009309, 11.5205844],
                [108.4008604, 11.5205487],
                [108.4007853, 11.5205131],
                [108.4007128, 11.5204728],
                [108.4006432, 11.5204278],
                [108.4005831, 11.5203834],
                [108.4005258, 11.5203356],
                [108.4004125, 11.5202386],
                [108.4003188, 11.5201516],
                [108.4002301, 11.5200599],
                [108.4001891, 11.5200139],
                [108.4001417, 11.5199744],
                [108.4000888, 11.5199422],
                [108.4000316, 11.5199179],
                [108.3999764, 11.5199032],
                [108.3999196, 11.5198959],
                [108.3998063, 11.5198812],
                [108.3997224, 11.519875],
                [108.3996382, 11.5198741],
                [108.3995542, 11.5198785],
                [108.3994949, 11.5198848],
                [108.3994352, 11.5198843],
                [108.399376, 11.5198769],
                [108.3993182, 11.5198628],
                [108.399278, 11.5198486],
                [108.3992391, 11.5198312],
                [108.3991572, 11.5198076],
                [108.3991059, 11.5197971],
                [108.3990553, 11.5197839],
                [108.3989901, 11.5197627],
                [108.3989234, 11.5197468],
                [108.3988556, 11.5197363],
                [108.3987871, 11.5197313],
                [108.3987409, 11.5197311],
                [108.398695, 11.5197253],
                [108.3986503, 11.5197139],
                [108.3986074, 11.5196972],
                [108.3985814, 11.5196839],
                [108.3985566, 11.5196688],
                [108.3985081, 11.5196367],
                [108.3984464, 11.5195878],
                [108.3983905, 11.5195324],
                [108.3983411, 11.5194714],
                [108.3982989, 11.5194055],
                [108.398264, 11.5193345],
                [108.3982228, 11.5192668],
                [108.3981755, 11.5192031],
                [108.3981394, 11.5191614],
                [108.3981007, 11.5191219],
                [108.3980192, 11.5190473],
                [108.3979315, 11.5189797],
                [108.3978399, 11.5189207],
                [108.3977035, 11.5188378],
                [108.3960834, 11.5179205],
                [108.3960668, 11.5180333],
                [108.3960539, 11.5181465],
                [108.3960501, 11.5181887],
                [108.3960395, 11.5182296],
                [108.3960225, 11.5182685],
                [108.3959994, 11.5183041],
                [108.3959708, 11.5183358],
                [108.3959445, 11.5183576],
                [108.3959156, 11.5183759],
                [108.395856, 11.5184093],
                [108.3957927, 11.5184355],
                [108.3957267, 11.518454],
                [108.3956681, 11.5184637],
                [108.3956088, 11.5184674],
                [108.3949611, 11.518457],
                [108.394871, 11.5184568],
                [108.3947812, 11.5184638],
                [108.3946924, 11.518478],
                [108.394605, 11.5184993],
                [108.3945197, 11.5185276],
                [108.3944376, 11.5185625],
                [108.3943588, 11.5186037],
                [108.3942836, 11.5186512],
                [108.3942126, 11.5187045],
                [108.3941463, 11.5187633],
                [108.3940852, 11.5188273],
                [108.3929694, 11.5203201],
                [108.3928548, 11.5204817],
                [108.3927342, 11.5206391],
                [108.3926079, 11.520792],
                [108.3925027, 11.5209219],
                [108.3923823, 11.5210563],
                [108.3922811, 11.5211661],
                [108.3921729, 11.5212692],
                [108.3920581, 11.5213652],
                [108.3919372, 11.5214537],
                [108.3918107, 11.5215343],
                [108.3916916, 11.5216002],
                [108.3915761, 11.5216721],
                [108.3914646, 11.5217498],
                [108.3913718, 11.5218214],
                [108.3912823, 11.521897],
                [108.3912234, 11.5219446],
                [108.3911611, 11.5219878],
                [108.3910957, 11.5220263],
                [108.3910274, 11.5220599],
                [108.390943, 11.5220932],
                [108.3908622, 11.5221345],
                [108.390786, 11.5221834],
                [108.3907195, 11.5222356],
                [108.3906577, 11.5222932],
                [108.390601, 11.5223557],
                [108.39055, 11.5224226],
                [108.3904997, 11.5225028],
                [108.3904531, 11.5225852],
                [108.3904105, 11.5226696],
                [108.3903664, 11.5227692],
                [108.3903293, 11.5228715],
                [108.3902992, 11.522976],
                [108.3902764, 11.5230823],
                [108.3902627, 11.5231746],
                [108.3902463, 11.5232666],
                [108.3902225, 11.5233784],
                [108.3901893, 11.5234879],
                [108.3901467, 11.5235942],
                [108.3900952, 11.5236967],
                [108.390035, 11.5237945],
                [108.3899722, 11.5238803],
                [108.3899026, 11.523961],
                [108.3898269, 11.5240361],
                [108.3897455, 11.5241053],
                [108.3896588, 11.524168],
                [108.3895674, 11.524224],
                [108.3894718, 11.5242728],
                [108.3893685, 11.5243262],
                [108.3892699, 11.5243877],
                [108.3891767, 11.5244568],
                [108.3890904, 11.5245323],
                [108.3890104, 11.5246145],
                [108.388769, 11.5248931],
                [108.3887365, 11.5249397],
                [108.3886988, 11.5249825],
                [108.3886564, 11.5250208],
                [108.3886099, 11.5250542],
                [108.3885598, 11.5250823],
                [108.3885037, 11.5251057],
                [108.388445, 11.5251225],
                [108.3883848, 11.5251322],
                [108.3874869, 11.5251703],
                [108.3873354, 11.525182],
                [108.387233, 11.5251951],
                [108.3871323, 11.5252174],
                [108.3870341, 11.5252487],
                [108.3869392, 11.5252888],
                [108.3868486, 11.5253372],
                [108.3867796, 11.5253817],
                [108.386708, 11.5254221],
                [108.386634, 11.5254581],
                [108.3865335, 11.5254987],
                [108.3864301, 11.5255317],
                [108.3863403, 11.5255535],
                [108.3862492, 11.5255695],
                [108.3860667, 11.5255993],
                [108.385883, 11.525621],
                [108.3856826, 11.5256354],
                [108.3854819, 11.5256431],
                [108.3852811, 11.5256441],
                [108.3850803, 11.5256385],
                [108.3848798, 11.5256263],
                [108.3846392, 11.5256027],
                [108.3843995, 11.5255712],
                [108.384161, 11.5255317],
                [108.3839696, 11.525494],
                [108.3837793, 11.5254512],
                [108.3833992, 11.5253635],
                [108.3832487, 11.5253266],
                [108.3831007, 11.5252809],
                [108.3829558, 11.5252266],
                [108.3828145, 11.5251637],
                [108.3827028, 11.5251067],
                [108.3825939, 11.5250446],
                [108.382488, 11.5249777],
                [108.3823854, 11.5249062],
                [108.3822873, 11.524831],
                [108.3821927, 11.5247515],
                [108.382102, 11.5246521],
                [108.3819294, 11.5244489],
                [108.3758912, 11.5169276],
                [108.3759069, 11.5168466],
                [108.3759132, 11.5167643],
                [108.3759103, 11.5166818],
                [108.375898, 11.5166002],
                [108.3758765, 11.5165204],
                [108.3758461, 11.5164435],
                [108.3758203, 11.5163927],
                [108.3757905, 11.5163439],
                [108.3757571, 11.5162976],
                [108.3756728, 11.516149],
                [108.3755934, 11.5159978],
                [108.3755189, 11.5158442],
                [108.3754616, 11.5157166],
                [108.3754125, 11.5155858],
                [108.3753718, 11.5154522],
                [108.3753397, 11.5153163],
                [108.3753163, 11.5151788],
                [108.3753017, 11.5150401],
                [108.3752959, 11.5149008],
                [108.375299, 11.5147614],
                [108.3753108, 11.5146233],
                [108.3753144, 11.5144847],
                [108.3753097, 11.5143462],
                [108.3753036, 11.5142701],
                [108.3752892, 11.514195],
                [108.3752668, 11.5141219],
                [108.3752365, 11.5140516],
                [108.3751987, 11.5139848],
                [108.3751539, 11.5139224],
                [108.3751025, 11.513865],
                [108.3750451, 11.5138133],
                [108.3749825, 11.5137679],
                [108.374917, 11.5137303],
                [108.3748479, 11.5136995],
                [108.374776, 11.5136759],
                [108.3747479, 11.5135236],
                [108.3747281, 11.5133699],
                [108.3747168, 11.5132155],
                [108.374714, 11.5130607],
                [108.3747196, 11.5129059],
                [108.3747331, 11.512756],
                [108.3747546, 11.5126069],
                [108.3747733, 11.5124643],
                [108.374784, 11.5122935],
                [108.3747894, 11.5120701],
                [108.3747947, 11.5118193],
                [108.3747909, 11.5115685],
                [108.374778, 11.5113179],
                [108.374756, 11.511068],
                [108.374725, 11.510819],
                [108.3746921, 11.5106116],
                [108.3746583, 11.5104525],
                [108.3745834, 11.5102396],
                [108.3739069, 11.5097362],
                [108.3735824, 11.5097336],
                [108.3733677, 11.509743],
                [108.3731452, 11.5097493],
                [108.3729384, 11.5097619],
                [108.3727321, 11.5097809],
                [108.3719811, 11.5098597],
                [108.3717483, 11.5098817],
                [108.3715149, 11.5098957],
                [108.3712811, 11.5099017],
                [108.3711057, 11.509901],
                [108.3709304, 11.5098958],
                [108.3694732, 11.5098256],
                [108.3692637, 11.509811],
                [108.3690789, 11.5097993],
                [108.3688932, 11.5097727],
                [108.3687437, 11.5097441],
                [108.3686041, 11.5096968],
                [108.3684575, 11.5096285],
                [108.3682723, 11.5095259],
                [108.3680919, 11.5094156],
                [108.3679548, 11.5093243],
                [108.3678209, 11.5092285],
                [108.3676904, 11.5091284],
                [108.3675635, 11.5090239],
                [108.3674144, 11.5088809],
                [108.3672899, 11.5087532],
                [108.3670603, 11.5085393],
                [108.3667939, 11.5083249],
                [108.3666454, 11.5081914],
                [108.3664593, 11.5080595],
                [108.3663003, 11.5079506],
                [108.3661097, 11.5078228],
                [108.3659377, 11.5077224],
                [108.3657354, 11.5076232],
                [108.3655137, 11.5075357],
                [108.3652943, 11.5074549],
                [108.3647853, 11.5072691],
                [108.3642483, 11.5070869],
                [108.3637405, 11.5069184],
                [108.3632854, 11.5067952],
                [108.3629121, 11.5067038],
                [108.3627359, 11.5066668],
                [108.3625158, 11.5066368],
                [108.3622947, 11.5066139],
                [108.362073, 11.506598],
                [108.3618506, 11.5065893],
                [108.361628, 11.5065877],
                [108.3614055, 11.5065932],
                [108.3611833, 11.5066058],
                [108.3609777, 11.5066203],
                [108.3608212, 11.5066366],
                [108.360686, 11.5066628],
                [108.3606328, 11.5066789],
                [108.3605817, 11.5067005],
                [108.3605454, 11.5067201],
                [108.3605109, 11.5067425],
                [108.3604369, 11.5067789],
                [108.3603602, 11.5068096],
                [108.3602814, 11.5068343],
                [108.3602008, 11.506853],
                [108.3601306, 11.506864],
                [108.3600623, 11.5068834],
                [108.359997, 11.5069108],
                [108.3599543, 11.5069341],
                [108.3599138, 11.5069607],
                [108.3598509, 11.5069951],
                [108.3597845, 11.5070224],
                [108.3597153, 11.5070422],
                [108.3596569, 11.5070528],
                [108.3595977, 11.5070581],
                [108.3595383, 11.507058],
                [108.3594637, 11.5070673],
                [108.3593901, 11.5070831],
                [108.3593184, 11.5071053],
                [108.3592719, 11.5071235],
                [108.3592292, 11.5071493],
                [108.3591916, 11.5071817],
                [108.3591602, 11.50722],
                [108.359136, 11.507263],
                [108.3591247, 11.5072919],
                [108.3591166, 11.5073218],
                [108.3591058, 11.5073826],
                [108.3591038, 11.5074443],
                [108.3591096, 11.5075004],
                [108.3591093, 11.5075567],
                [108.3591029, 11.5076127],
                [108.3590904, 11.5076677],
                [108.3590688, 11.5077288],
                [108.3590396, 11.5077868],
                [108.3590032, 11.5078407],
                [108.3589601, 11.5078897],
                [108.3589112, 11.507933],
                [108.358857, 11.50797],
                [108.3588117, 11.5079941],
                [108.3587642, 11.5080136],
                [108.3586684, 11.5080505],
                [108.35857, 11.5080804],
                [108.3585108, 11.5080947],
                [108.358451, 11.5081065],
                [108.3583326, 11.5081356],
                [108.358235, 11.5081618],
                [108.3581355, 11.5081803],
                [108.3580392, 11.5081905],
                [108.3579518, 11.5081973],
                [108.3578714, 11.5082248],
                [108.3578037, 11.5082626],
                [108.3577399, 11.5083064],
                [108.3576761, 11.5083601],
                [108.3576183, 11.5084199],
                [108.357524, 11.5085272],
                [108.3569085, 11.5073953],
                [108.3567971, 11.5072036],
                [108.3566776, 11.5070165],
                [108.3565503, 11.5068345],
                [108.356422, 11.5066664],
                [108.356287, 11.5065033],
                [108.3561817, 11.5063673],
                [108.3560743, 11.5062328],
                [108.3558905, 11.5060119],
                [108.3557989, 11.5059164],
                [108.3557054, 11.5058227],
                [108.3556188, 11.5057441],
                [108.3555284, 11.5056695],
                [108.3554345, 11.5055993],
                [108.3553358, 11.505537],
                [108.3552322, 11.5054827],
                [108.3551245, 11.5054368],
                [108.3550694, 11.5054169],
                [108.3550134, 11.5053995],
                [108.3549261, 11.5053777],
                [108.3548375, 11.5053619],
                [108.3547925, 11.5053585],
                [108.3547479, 11.5053522],
                [108.3546738, 11.5053353],
                [108.3546021, 11.5053106],
                [108.3545335, 11.5052785],
                [108.3544689, 11.5052392],
                [108.3543849, 11.5051966],
                [108.3542975, 11.5051612],
                [108.3542072, 11.5051333],
                [108.3541149, 11.505113],
                [108.3539684, 11.5050872],
                [108.3538207, 11.5050688],
                [108.3536723, 11.5050578],
                [108.3534211, 11.5050534],
                [108.3531698, 11.5050543],
                [108.3529186, 11.5050605],
                [108.3526746, 11.5050717],
                [108.3524309, 11.5050878],
                [108.3521875, 11.505109],
                [108.3520365, 11.5051261],
                [108.3518867, 11.5051511],
                [108.3517384, 11.505184],
                [108.3515908, 11.505225],
                [108.3514456, 11.5052738],
                [108.3513502, 11.5053126],
                [108.3512587, 11.5053596],
                [108.3511719, 11.5054144],
                [108.351097, 11.5054713],
                [108.3510271, 11.5055341],
                [108.3509627, 11.5056023],
                [108.3509043, 11.5056755],
                [108.350843, 11.5057809],
                [108.3507876, 11.5058894],
                [108.3507382, 11.5060007],
                [108.3506951, 11.5061144],
                [108.3506567, 11.5062354],
                [108.3506254, 11.5063584],
                [108.3506012, 11.5064828],
                [108.3505841, 11.5066084],
                [108.3505743, 11.5067347],
                [108.3495229, 11.5079174],
                [108.349458, 11.5080044],
                [108.3493996, 11.5080957],
                [108.3493478, 11.5081908],
                [108.3492977, 11.5083129],
                [108.3492739, 11.5084145],
                [108.3492524, 11.5085413],
                [108.3492386, 11.5086691],
                [108.3492324, 11.5088129],
                [108.349236, 11.5089568],
                [108.349244, 11.5091212],
                [108.3492504, 11.5093289],
                [108.3492601, 11.5095575],
                [108.3492752, 11.5097529],
                [108.3492829, 11.5099488],
                [108.3492832, 11.5101448],
                [108.3492762, 11.5103407],
                [108.349262, 11.510534],
                [108.3492423, 11.5107267],
                [108.3492172, 11.5109189],
                [108.3491954, 11.5110586],
                [108.3491709, 11.5111979],
                [108.3491206, 11.5114761],
                [108.3490695, 11.5117342],
                [108.3490452, 11.5119105],
                [108.3490397, 11.5120297],
                [108.3490424, 11.5121489],
                [108.3490533, 11.5122677],
                [108.3490723, 11.5123855],
                [108.3490929, 11.5124771],
                [108.3491184, 11.5125676],
                [108.3491488, 11.5126566],
                [108.3492279, 11.5129269],
                [108.3492776, 11.5130917],
                [108.3493335, 11.5132545],
                [108.3493957, 11.5134152],
                [108.3494639, 11.5135735],
                [108.3495536, 11.5137595],
                [108.3496517, 11.5139414],
                [108.349737, 11.5141109],
                [108.3498175, 11.5142827],
                [108.3498931, 11.5144565],
                [108.3499402, 11.5145722],
                [108.3499794, 11.5146906],
                [108.3500105, 11.5148114],
                [108.3500333, 11.5149339],
                [108.3500479, 11.5150576],
                [108.350054, 11.5151819],
                [108.3500532, 11.5152733],
                [108.3500478, 11.5153644],
                [108.3500379, 11.5154552],
                [108.3500176, 11.5157509],
                [108.3500069, 11.5159525],
                [108.3500057, 11.5162017],
                [108.3500126, 11.5164225],
                [108.350027, 11.5166431],
                [108.3500488, 11.516863],
                [108.3500781, 11.5170821],
                [108.3501218, 11.5174009],
                [108.3501559, 11.5177208],
                [108.3501786, 11.5180096],
                [108.3501935, 11.518299],
                [108.3502113, 11.5186773],
                [108.3502203, 11.5190559],
                [108.3502579, 11.5193555],
                [108.3502798, 11.5195036],
                [108.3502945, 11.5196005],
                [108.3503019, 11.5196982],
                [108.3503019, 11.5197961],
                [108.3502945, 11.5198937],
                [108.3502798, 11.5199906],
                [108.3502579, 11.5200862],
                [108.3502257, 11.5201885],
                [108.3501846, 11.5202877],
                [108.3501349, 11.520383],
                [108.3500769, 11.5204737],
                [108.3500111, 11.5205592],
                [108.3499416, 11.5206351],
                [108.349866, 11.5207051],
                [108.3497846, 11.5207687],
                [108.3496981, 11.5208254],
                [108.3496071, 11.5208748],
                [108.3495122, 11.5209167],
                [108.3494131, 11.5209509],
                [108.3493118, 11.5209782],
                [108.3492088, 11.5209983],
                [108.3491045, 11.5210113],
                [108.3489918, 11.5210171],
                [108.3488789, 11.5210144],
                [108.3487531, 11.5209955],
                [108.3471841, 11.5206985],
                [108.3470312, 11.5206801],
                [108.3469218, 11.5206723],
                [108.3468655, 11.520673],
                [108.34681, 11.5206821],
                [108.3467565, 11.5206994],
                [108.3467064, 11.5207245],
                [108.3466608, 11.5207569],
                [108.3466208, 11.5207958],
                [108.3465946, 11.5208292],
                [108.3465726, 11.5208655],
                [108.3465551, 11.520904],
                [108.3464974, 11.5210165],
                [108.3464607, 11.5211007],
                [108.3464285, 11.5211866],
                [108.3464009, 11.5212741],
                [108.3462623, 11.5213911],
                [108.3461183, 11.5215016],
                [108.345969, 11.5216052],
                [108.3458264, 11.5216949],
                [108.3456799, 11.5217784],
                [108.3455297, 11.5218554],
                [108.3453977, 11.521918],
                [108.3453109, 11.5219729],
                [108.3452503, 11.5220256],
                [108.3451951, 11.5220837],
                [108.3451456, 11.5221466],
                [108.3451, 11.5222178],
                [108.3450619, 11.522293],
                [108.3450316, 11.5223716],
                [108.3450093, 11.5224527],
                [108.34499, 11.5225487],
                [108.3449873, 11.5226749],
                [108.3449632, 11.5241913],
                [108.3449575, 11.5242969],
                [108.3449438, 11.5244018],
                [108.3449222, 11.5245054],
                [108.3448929, 11.5246071],
                [108.3448559, 11.5247064],
                [108.3448102, 11.5248053],
                [108.3447571, 11.5249005],
                [108.3446966, 11.5249914],
                [108.3446293, 11.5250776],
                [108.3445555, 11.5251585],
                [108.3443864, 11.5253511],
                [108.3442225, 11.5255479],
                [108.344064, 11.525749],
                [108.3440069, 11.5258333],
                [108.3439552, 11.5259209],
                [108.3439091, 11.5260114],
                [108.3438688, 11.5261046],
                [108.3438388, 11.526187],
                [108.343816, 11.5262716],
                [108.3438005, 11.5263578],
                [108.3437925, 11.526445],
                [108.3437921, 11.5265325],
                [108.3437991, 11.5266197],
                [108.3438159, 11.5267164],
                [108.3438251, 11.5268141],
                [108.3438267, 11.5269122],
                [108.3438208, 11.5270101],
                [108.3438072, 11.5271073],
                [108.3437841, 11.5272107],
                [108.3437524, 11.527312],
                [108.3436945, 11.5275159],
                [108.343656, 11.5276452],
                [108.343614, 11.5277735],
                [108.3435878, 11.5278483],
                [108.3435547, 11.5279205],
                [108.3435151, 11.5279895],
                [108.3434692, 11.5280547],
                [108.3434328, 11.5280986],
                [108.3433922, 11.5281389],
                [108.3433479, 11.5281752],
                [108.3433002, 11.5282071],
                [108.3432539, 11.5282323],
                [108.3431881, 11.5282521],
                [108.3431412, 11.5282606],
                [108.3430937, 11.5282649],
                [108.3420706, 11.5283455],
                [108.3420296, 11.5281577],
                [108.3420063, 11.5280786],
                [108.3419752, 11.5280021],
                [108.3419359, 11.5279276],
                [108.3418893, 11.5278573],
                [108.3418357, 11.5277919],
                [108.3417831, 11.5277318],
                [108.3417284, 11.5276736],
                [108.3416897, 11.5276272],
                [108.3416725, 11.5276023],
                [108.3416533, 11.527579],
                [108.3416104, 11.5275382],
                [108.3415613, 11.5275048],
                [108.3415074, 11.5274797],
                [108.34145, 11.5274635],
                [108.3413906, 11.5274565],
                [108.3413309, 11.5274592],
                [108.3412725, 11.5274712],
                [108.3411872, 11.5274835],
                [108.3411012, 11.5274896],
                [108.341015, 11.5274896],
                [108.3409175, 11.5274819],
                [108.3408211, 11.527466],
                [108.3407264, 11.527442],
                [108.3406342, 11.5274102],
                [108.3405451, 11.5273708],
                [108.3405031, 11.5273462],
                [108.3404598, 11.5273241],
                [108.3404176, 11.5273084],
                [108.3403766, 11.5272899],
                [108.3403275, 11.5272631],
                [108.3402809, 11.5272324],
                [108.3402371, 11.5271979],
                [108.3401648, 11.5271358],
                [108.3400959, 11.52707],
                [108.3392125, 11.5262623],
                [108.3391488, 11.5262165],
                [108.3390812, 11.5261765],
                [108.3390101, 11.5261426],
                [108.3389362, 11.5261151],
                [108.338819, 11.5260771],
                [108.3386999, 11.5260453],
                [108.3385792, 11.5260198],
                [108.3384856, 11.5259995],
                [108.338397, 11.5259724],
                [108.3383109, 11.5259382],
                [108.3382281, 11.525897],
                [108.3376095, 11.525558],
                [108.3375438, 11.5255172],
                [108.3374818, 11.5254712],
                [108.3374228, 11.5254196],
                [108.3373684, 11.5253634],
                [108.3373188, 11.525303],
                [108.3372254, 11.5252035],
                [108.337126, 11.5251097],
                [108.3370212, 11.5250218],
                [108.3369135, 11.5249417],
                [108.3368012, 11.5248679],
                [108.3366848, 11.5248005],
                [108.336586, 11.5247452],
                [108.3364565, 11.5247106],
                [108.3363466, 11.5246898],
                [108.3362353, 11.5246775],
                [108.3361444, 11.5246744],
                [108.3360535, 11.5246768],
                [108.3359523, 11.5246849],
                [108.3358665, 11.524692],
                [108.3357847, 11.5247012],
                [108.3357011, 11.524715],
                [108.3356291, 11.5247327],
                [108.3355353, 11.5247622],
                [108.3354905, 11.5247823],
                [108.335444, 11.5247984],
                [108.3353895, 11.5248118],
                [108.3353339, 11.5248197],
                [108.3353059, 11.5248222],
                [108.3352777, 11.5248221],
                [108.3352249, 11.5248151],
                [108.3351741, 11.5247992],
                [108.3351269, 11.524775],
                [108.3350846, 11.5247432],
                [108.3350311, 11.5246954],
                [108.3349827, 11.5246427],
                [108.3349592, 11.5246156],
                [108.3349398, 11.5245855],
                [108.3349224, 11.5245471],
                [108.3349116, 11.5245065],
                [108.3349076, 11.5244647],
                [108.3349102, 11.5244304],
                [108.3349084, 11.524396],
                [108.3349022, 11.5243622],
                [108.3348855, 11.5243143],
                [108.33486, 11.5242702],
                [108.3348268, 11.5242314],
                [108.3347869, 11.5241992],
                [108.3347405, 11.5241688],
                [108.3346911, 11.5241431],
                [108.3346394, 11.5241225],
                [108.3346121, 11.5241164],
                [108.3345857, 11.5241072],
                [108.3345472, 11.5240872],
                [108.334513, 11.5240607],
                [108.3344842, 11.5240287],
                [108.3344617, 11.5239921],
                [108.3344462, 11.5239522],
                [108.3344342, 11.523904],
                [108.3344288, 11.5238546],
                [108.3344302, 11.523805],
                [108.3344237, 11.5237426],
                [108.3344084, 11.5236818],
                [108.3343846, 11.5236237],
                [108.3343419, 11.5235332],
                [108.3342935, 11.5234454],
                [108.3342397, 11.5233608],
                [108.3341704, 11.5232391],
                [108.3341374, 11.5231774],
                [108.3341083, 11.5231138],
                [108.3340732, 11.5230192],
                [108.3340619, 11.52297],
                [108.3340466, 11.5229219],
                [108.3340212, 11.5228619],
                [108.3339898, 11.5228047],
                [108.3339525, 11.5227509],
                [108.3339098, 11.5227012],
                [108.3337732, 11.5225917],
                [108.3336577, 11.5225146],
                [108.3335611, 11.5224462],
                [108.3334443, 11.5223855],
                [108.3333492, 11.5223516],
                [108.3332637, 11.5223267],
                [108.3331769, 11.5223066],
                [108.3331332, 11.5222974],
                [108.3330891, 11.5222912],
                [108.3330157, 11.5222876],
                [108.3329424, 11.5222922],
                [108.3328701, 11.522305],
                [108.3327998, 11.5223258],
                [108.3327655, 11.5223388],
                [108.3327323, 11.5223543],
                [108.3326824, 11.5223833],
                [108.3326363, 11.5224178],
                [108.3325944, 11.5224572],
                [108.3325574, 11.5225011],
                [108.3325258, 11.5225487],
                [108.3324978, 11.522605],
                [108.3324893, 11.5226353],
                [108.3324775, 11.5226644],
                [108.3324507, 11.5227095],
                [108.3324159, 11.5227491],
                [108.3323743, 11.5227817],
                [108.3323273, 11.5228063],
                [108.3322556, 11.5228349],
                [108.3321809, 11.5228547],
                [108.3321043, 11.5228653],
                [108.3320269, 11.5228667],
                [108.3318954, 11.522866],
                [108.3317641, 11.5228615],
                [108.3317099, 11.5228588],
                [108.3316557, 11.5228615],
                [108.331602, 11.5228694],
                [108.3315495, 11.5228825],
                [108.331496, 11.5229017],
                [108.3314448, 11.5229264],
                [108.3313966, 11.5229561],
                [108.3312492, 11.5229545],
                [108.331102, 11.5229462],
                [108.3309554, 11.5229311],
                [108.3308097, 11.5229093],
                [108.3306652, 11.5228808],
                [108.3305222, 11.5228457],
                [108.3303768, 11.5228028],
                [108.3302337, 11.522753],
                [108.3300932, 11.5226965],
                [108.3299557, 11.5226335],
                [108.3298214, 11.522564],
                [108.3296907, 11.5224883],
                [108.329462, 11.5225219],
                [108.3292346, 11.5225638],
                [108.329009, 11.5226138],
                [108.3287854, 11.5226719],
                [108.3285642, 11.522738],
                [108.3283715, 11.5228027],
                [108.3281812, 11.5228735],
                [108.3279932, 11.5229504],
                [108.3278079, 11.5230331],
                [108.3276254, 11.5231216],
                [108.3276176, 11.5232298],
                [108.3276005, 11.523337],
                [108.3275744, 11.5234424],
                [108.3275407, 11.5235421],
                [108.3274989, 11.5236388],
                [108.3274492, 11.5237319],
                [108.327392, 11.5238208],
                [108.3273319, 11.523912],
                [108.3272788, 11.5240072],
                [108.327233, 11.524106],
                [108.3271946, 11.5242079],
                [108.327164, 11.5243122],
                [108.3271433, 11.5244076],
                [108.3271289, 11.5245041],
                [108.327105, 11.5246197],
                [108.3270708, 11.5247128],
                [108.3270162, 11.5248223],
                [108.3269575, 11.5249298],
                [108.3268763, 11.5250646],
                [108.3267885, 11.5251953],
                [108.3267279, 11.5253074],
                [108.3266723, 11.5254221],
                [108.3266219, 11.5255389],
                [108.3265767, 11.5256579],
                [108.3265347, 11.5257856],
                [108.3264987, 11.5259151],
                [108.3264689, 11.526046],
                [108.3264452, 11.5261782],
                [108.3263475, 11.5266529],
                [108.3263276, 11.5267294],
                [108.3263004, 11.5268037],
                [108.326263, 11.526881],
                [108.3262176, 11.5269541],
                [108.3261649, 11.5270223],
                [108.3261054, 11.5270849],
                [108.3260396, 11.5271412],
                [108.3259682, 11.5271905],
                [108.3258769, 11.5272677],
                [108.3257907, 11.5273503],
                [108.3257135, 11.5274341],
                [108.3256416, 11.5275222],
                [108.3255751, 11.5276144],
                [108.3255145, 11.5277104],
                [108.3254858, 11.5277944],
                [108.3254643, 11.5278804],
                [108.3254501, 11.5279679],
                [108.3254432, 11.5280582],
                [108.325444, 11.5281487],
                [108.3254526, 11.5282389],
                [108.3254689, 11.5283595],
                [108.3255137, 11.5285781],
                [108.3255656, 11.528827],
                [108.3256244, 11.5290744],
                [108.3256987, 11.5293502],
                [108.3257764, 11.5295921],
                [108.3258172, 11.5297229],
                [108.3258478, 11.5298238],
                [108.3258732, 11.529926],
                [108.3258976, 11.5300541],
                [108.3259088, 11.5301624],
                [108.3259052, 11.5302638],
                [108.3258918, 11.5303436],
                [108.3258739, 11.5304226],
                [108.3258452, 11.5305202],
                [108.3258096, 11.5306157],
                [108.325651, 11.5306272],
                [108.3254935, 11.5306479],
                [108.3253375, 11.5306775],
                [108.3251958, 11.5307126],
                [108.3250562, 11.530755],
                [108.3249191, 11.5308047],
                [108.3247849, 11.5308615],
                [108.3247098, 11.530897],
                [108.3246358, 11.5309347],
                [108.3244899, 11.5310139],
                [108.3243652, 11.5310879],
                [108.3242382, 11.531158],
                [108.324109, 11.5312241],
                [108.3239157, 11.531314],
                [108.3237243, 11.5314077],
                [108.323535, 11.5315053],
                [108.3233448, 11.5316085],
                [108.3232024, 11.5316998],
                [108.3230898, 11.5317497],
                [108.3229852, 11.531776],
                [108.322878, 11.531785],
                [108.3227398, 11.5317008],
                [108.3225973, 11.5316238],
                [108.322451, 11.531554],
                [108.3223012, 11.5314917],
                [108.3221483, 11.531437],
                [108.3220177, 11.531397],
                [108.3218855, 11.5313626],
                [108.3217519, 11.5313339],
                [108.3216172, 11.5313108],
                [108.3214456, 11.5312699],
                [108.3212756, 11.5312229],
                [108.3211074, 11.53117],
                [108.3209413, 11.5311111],
                [108.32075, 11.5310348],
                [108.3205621, 11.5309507],
                [108.3203781, 11.5308588],
                [108.3182216, 11.5297734],
                [108.318122, 11.5297265],
                [108.3180245, 11.5296756],
                [108.3179292, 11.529621],
                [108.3178335, 11.5295608],
                [108.3177405, 11.5294968],
                [108.3176503, 11.5294291],
                [108.3169918, 11.528935],
                [108.3168772, 11.5288528],
                [108.3167596, 11.5287748],
                [108.3166391, 11.5287011],
                [108.316475, 11.5286104],
                [108.3163065, 11.5285277],
                [108.3154857, 11.5281519],
                [108.3149707, 11.5276157],
                [108.314882, 11.5275436],
                [108.3147889, 11.5274769],
                [108.3146918, 11.527416],
                [108.3146016, 11.5273664],
                [108.3145088, 11.5273218],
                [108.3144135, 11.5272822],
                [108.3143163, 11.5272478],
                [108.3128571, 11.5268378],
                [108.3118057, 11.5264856],
                [108.3115318, 11.5263845],
                [108.3112615, 11.5262744],
                [108.3109951, 11.5261557],
                [108.3107328, 11.5260283],
                [108.3104846, 11.5258978],
                [108.3102408, 11.5257596],
                [108.3100016, 11.5256138],
                [108.3097672, 11.5254607],
                [108.3085603, 11.5246302],
                [108.3083733, 11.5245149],
                [108.3081828, 11.5244054],
                [108.3079889, 11.5243017],
                [108.3078099, 11.5242126],
                [108.3076283, 11.5241284],
                [108.3074445, 11.5240494],
                [108.3072145, 11.5239683],
                [108.307046, 11.5238937],
                [108.3068885, 11.5238254],
                [108.3067341, 11.5237506],
                [108.3065831, 11.5236693],
                [108.3064359, 11.5235816],
                [108.3063034, 11.5234951],
                [108.3061745, 11.5234034],
                [108.3059102, 11.5232294],
                [108.3058125, 11.5231666],
                [108.3057181, 11.5230991],
                [108.3056272, 11.5230272],
                [108.3055401, 11.5229508],
                [108.30548, 11.5228935],
                [108.305422, 11.5228341],
                [108.3053101, 11.5227118],
                [108.3052045, 11.5225842],
                [108.3051056, 11.5224515],
                [108.3050245, 11.5223314],
                [108.3049499, 11.5222074],
                [108.3048819, 11.5220798],
                [108.3048207, 11.521949],
                [108.3047665, 11.5218152],
                [108.3047193, 11.5216788],
                [108.3046882, 11.5215731],
                [108.3046613, 11.5214662],
                [108.3045951, 11.5212558],
                [108.3045423, 11.5210849],
                [108.3044806, 11.5209118],
                [108.3044358, 11.5207768],
                [108.3043867, 11.5206433],
                [108.3043109, 11.5204597],
                [108.3042268, 11.5202796],
                [108.3041345, 11.5201034],
                [108.3040343, 11.5199314],
                [108.3039263, 11.5197639],
                [108.3038082, 11.5195804],
                [108.3035863, 11.5193266],
                [108.3029989, 11.518358],
                [108.3026604, 11.5181744],
                [108.3023255, 11.5179848],
                [108.3019942, 11.5177891],
                [108.3016722, 11.5175911],
                [108.301354, 11.5173874],
                [108.3010395, 11.5171781],
                [108.300729, 11.5169633],
                [108.3004224, 11.5167431],
                [108.2985556, 11.5155814],
                [108.2960021, 11.5146142],
                [108.2958021, 11.5145442],
                [108.2955993, 11.5144822],
                [108.2953941, 11.5144284],
                [108.2951868, 11.514383],
                [108.2949806, 11.5143463],
                [108.294773, 11.5143178],
                [108.2945645, 11.5142976],
                [108.2943553, 11.5142857],
                [108.2917857, 11.5141832],
                [108.2902408, 11.5142147],
                [108.2879019, 11.5145512],
                [108.2826743, 11.5145222],
                [108.2816496, 11.5146563],
                [108.2799035, 11.5151556],
                [108.2798249, 11.5151794],
                [108.2797441, 11.5151944],
                [108.2796621, 11.5152003],
                [108.2795851, 11.5151977],
                [108.2795089, 11.5151871],
                [108.2794341, 11.5151688],
                [108.2788414, 11.5149454],
                [108.2784015, 11.5143304],
                [108.277393, 11.5124275],
                [108.2768243, 11.5119334],
                [108.2762718, 11.5113973],
                [108.2759365, 11.5111857],
                [108.2755731, 11.510844],
                [108.2753229, 11.5096478],
                [108.2753032, 11.509574],
                [108.2752767, 11.5095023],
                [108.2752511, 11.5094473],
                [108.2752216, 11.5093942],
                [108.2751882, 11.5093433],
                [108.2745552, 11.50838],
                [108.2744751, 11.50827],
                [108.2743889, 11.5081645],
                [108.2743029, 11.5080702],
                [108.2742119, 11.5079805],
                [108.2732597, 11.5072183],
                [108.273257, 11.5070107],
                [108.2730934, 11.5067689],
                [108.273046, 11.5067003],
                [108.2730022, 11.5066296],
                [108.2729569, 11.5065467],
                [108.2729164, 11.5064614],
                [108.2726233, 11.5057287],
                [108.2725628, 11.5056058],
                [108.2724942, 11.505487],
                [108.2724179, 11.5053728],
                [108.272334, 11.5052638],
                [108.2722431, 11.5051603],
                [108.2721549, 11.5050717],
                [108.2720614, 11.5049884],
                [108.2719631, 11.5049107],
                [108.2718602, 11.5048388],
                [108.2717532, 11.504773],
                [108.2716423, 11.5047135],
                [108.2715069, 11.5046391],
                [108.2713761, 11.5045572],
                [108.2712503, 11.504468],
                [108.27113, 11.5043719],
                [108.2710152, 11.5042688],
                [108.2709067, 11.5041594],
                [108.2708048, 11.504044],
                [108.2707099, 11.5039231],
                [108.2702261, 11.5033074],
                [108.2683378, 11.5021194],
                [108.267839, 11.5018618],
                [108.2677385, 11.5018041],
                [108.2676425, 11.5017396],
                [108.2675514, 11.5016684],
                [108.2674659, 11.501591],
                [108.2673862, 11.5015078],
                [108.2673129, 11.5014192],
                [108.2672462, 11.5013256],
                [108.2671888, 11.5012315],
                [108.2671381, 11.5011338],
                [108.2670945, 11.5010329],
                [108.267058, 11.5009293],
                [108.267029, 11.5008234],
                [108.26675, 11.4997013],
                [108.2665086, 11.4990389],
                [108.2663315, 11.4988076],
                [108.2663476, 11.4979087],
                [108.2658488, 11.4974777],
                [108.2655698, 11.4970624],
                [108.2714251, 11.4904204],
                [108.2714753, 11.4903646],
                [108.27152, 11.4903045],
                [108.2715587, 11.4902405],
                [108.2715911, 11.4901732],
                [108.2716168, 11.4901033],
                [108.2716358, 11.4900312],
                [108.2716477, 11.4899578],
                [108.2716526, 11.4898782],
                [108.2716493, 11.4897986],
                [108.2716377, 11.4897197],
                [108.2716182, 11.4896423],
                [108.2715739, 11.4895315],
                [108.2715223, 11.4894237],
                [108.2714637, 11.4893195],
                [108.2713982, 11.4892192],
                [108.2713218, 11.4891177],
                [108.2712384, 11.4890216],
                [108.2711485, 11.4889313],
                [108.2711029, 11.4889],
                [108.2710629, 11.4888623],
                [108.2710291, 11.4888189],
                [108.2710026, 11.4887711],
                [108.2709838, 11.4887198],
                [108.2709732, 11.4886649],
                [108.2709714, 11.4886092],
                [108.2709786, 11.4885538],
                [108.2709946, 11.4885003],
                [108.2711253, 11.4881665],
                [108.2711388, 11.4881098],
                [108.2711446, 11.4880518],
                [108.2711428, 11.4879936],
                [108.271136, 11.4879486],
                [108.2711245, 11.4879044],
                [108.2711086, 11.4878616],
                [108.2709597, 11.4875501],
                [108.2709151, 11.4874681],
                [108.2708627, 11.4873908],
                [108.2708028, 11.4873188],
                [108.2707543, 11.4872696],
                [108.2707024, 11.487224],
                [108.2706472, 11.4871821],
                [108.2698466, 11.4865999],
                [108.2697965, 11.4865591],
                [108.2697501, 11.4865143],
                [108.2697078, 11.4864658],
                [108.2696698, 11.4864141],
                [108.2696364, 11.4863594],
                [108.2696079, 11.4863022],
                [108.2694617, 11.4859888],
                [108.2687107, 11.4856024],
                [108.2686338, 11.4855517],
                [108.2685618, 11.4854947],
                [108.2684952, 11.4854317],
                [108.2684344, 11.4853632],
                [108.2683833, 11.4852946],
                [108.268338, 11.4852222],
                [108.268299, 11.4851463],
                [108.2681957, 11.4849111],
                [108.2681529, 11.4848481],
                [108.2681049, 11.4847888],
                [108.268052, 11.4847338],
                [108.2679945, 11.4846833],
                [108.2679328, 11.4846377],
                [108.267853, 11.4845895],
                [108.2677686, 11.4845494],
                [108.2676805, 11.4845181],
                [108.2675895, 11.4844958],
                [108.2658648, 11.4830948],
                [108.2658212, 11.4830598],
                [108.2657814, 11.4830207],
                [108.2657457, 11.4829779],
                [108.2657146, 11.4829318],
                [108.265684, 11.4828733],
                [108.2656606, 11.4828117],
                [108.2656449, 11.4827478],
                [108.265618, 11.4826958],
                [108.2655838, 11.4826482],
                [108.265543, 11.4826059],
                [108.2654988, 11.4825714],
                [108.2654502, 11.4825432],
                [108.2653981, 11.4825218],
                [108.2652587, 11.4825007],
                [108.2652159, 11.4824973],
                [108.2651743, 11.4824867],
                [108.2651353, 11.4824692],
                [108.2651025, 11.4824475],
                [108.2650737, 11.482421],
                [108.2650495, 11.4823903],
                [108.2649636, 11.4822431],
                [108.2649154, 11.4821756],
                [108.2648635, 11.4821107],
                [108.2648081, 11.4820486],
                [108.2647277, 11.4819694],
                [108.2646417, 11.4818962],
                [108.2616001, 11.4797408],
                [108.260479, 11.4785947],
                [108.2596528, 11.4778062],
                [108.2593067, 11.4774778],
                [108.2589662, 11.4771438],
                [108.2587178, 11.4768929],
                [108.2584727, 11.4766391],
                [108.2577699, 11.4751565],
                [108.2639766, 11.461318],
                [108.2640624, 11.4605307],
                [108.2642877, 11.4597736],
                [108.2643843, 11.4585801],
                [108.2643955, 11.4584371],
                [108.2644152, 11.458295],
                [108.2644433, 11.4581543],
                [108.2644798, 11.4580151],
                [108.2645245, 11.4578782],
                [108.2645774, 11.4577442],
                [108.2646745, 11.4575233],
                [108.2647675, 11.4573007],
                [108.2648563, 11.4570765],
                [108.2649421, 11.4568475],
                [108.2650234, 11.456617],
                [108.2651004, 11.4563851],
                [108.265168, 11.4562256],
                [108.265241, 11.4560685],
                [108.2653196, 11.4559139],
                [108.2654035, 11.4557621],
                [108.2655163, 11.4555756],
                [108.2656372, 11.455394],
                [108.265766, 11.4552178],
                [108.2659024, 11.4550471],
                [108.2668733, 11.4540955],
                [108.2669752, 11.4539917],
                [108.2670699, 11.4538817],
                [108.2671571, 11.4537659],
                [108.2672364, 11.4536447],
                [108.2673075, 11.4535188],
                [108.2673701, 11.4533885],
                [108.267424, 11.4532546],
                [108.2674688, 11.4531175],
                [108.267503, 11.4529842],
                [108.2675288, 11.4528491],
                [108.2675459, 11.4527126],
                [108.2675543, 11.4525754],
                [108.267554, 11.4524379],
                [108.267545, 11.4523007],
                [108.2675273, 11.4521643],
                [108.2675128, 11.452097],
                [108.267501, 11.4520292],
                [108.2674865, 11.4519088],
                [108.2674802, 11.4517877],
                [108.2674822, 11.4516664],
                [108.2675721, 11.4502613],
                [108.2677746, 11.4498946],
                [108.2678258, 11.4497997],
                [108.2678689, 11.449701],
                [108.2679038, 11.4495992],
                [108.2679301, 11.449495],
                [108.2679462, 11.4494012],
                [108.2679553, 11.4493065],
                [108.2679575, 11.4492113],
                [108.2679527, 11.4491163],
                [108.2679409, 11.4490218],
                [108.2678872, 11.448801],
                [108.2678761, 11.4487553],
                [108.2678707, 11.4487087],
                [108.2678711, 11.4486617],
                [108.2678784, 11.44861],
                [108.2678926, 11.4485598],
                [108.2679135, 11.4485119],
                [108.2679409, 11.4484672],
                [108.2680571, 11.4483432],
                [108.2681698, 11.4482161],
                [108.2682788, 11.448086],
                [108.2683929, 11.4479416],
                [108.2685024, 11.4477939],
                [108.2686074, 11.447643],
                [108.268806, 11.447564],
                [108.2690019, 11.4474788],
                [108.2691949, 11.4473873],
                [108.2693847, 11.4472897],
                [108.2695711, 11.447186],
                [108.269754, 11.4470765],
                [108.2699173, 11.4469717],
                [108.2700773, 11.4468623],
                [108.2702339, 11.4467482],
                [108.270387, 11.4466296],
                [108.2707787, 11.4455307],
                [108.2720876, 11.4427389],
                [108.2725382, 11.4419186],
                [108.272565, 11.4412088],
                [108.2727144, 11.4404271],
                [108.2727364, 11.4403569],
                [108.2727649, 11.440289],
                [108.2727997, 11.4402239],
                [108.2728404, 11.4401623],
                [108.2728869, 11.4401047],
                [108.2729451, 11.4400454],
                [108.2730092, 11.4399924],
                [108.2730786, 11.4399461],
                [108.2731526, 11.4399072],
                [108.2743674, 11.4394501],
                [108.2749146, 11.4392371],
                [108.2762374, 11.4398102],
                [108.2763183, 11.4398441],
                [108.2764014, 11.4398722],
                [108.2764864, 11.4398944],
                [108.2765786, 11.4399114],
                [108.2766718, 11.4399215],
                [108.2767655, 11.4399246],
                [108.2768592, 11.4399207],
                [108.2776236, 11.4398839],
                [108.2776822, 11.4398827],
                [108.2777405, 11.4398881],
                [108.2777978, 11.4398999],
                [108.2778533, 11.4399181],
                [108.2779064, 11.4399424],
                [108.2779563, 11.4399725],
                [108.2780088, 11.4400136],
                [108.2780554, 11.4400612],
                [108.2780953, 11.4401143],
                [108.2781277, 11.4401721],
                [108.278152, 11.4402335],
                [108.2783532, 11.4407619],
                [108.2784098, 11.4408996],
                [108.2784734, 11.4410342],
                [108.278544, 11.4411654],
                [108.2786214, 11.441293],
                [108.278704, 11.4414145],
                [108.2787927, 11.4415319],
                [108.2788874, 11.4416447],
                [108.2789877, 11.4417527],
                [108.2790935, 11.4418555],
                [108.2791749, 11.4419291],
                [108.2792523, 11.4420068],
                [108.2793254, 11.4420884],
                [108.2793939, 11.4421736],
                [108.2794727, 11.4422845],
                [108.2795438, 11.4424003],
                [108.2796071, 11.4425203],
                [108.2796621, 11.4426442],
                [108.2796965, 11.4427293],
                [108.2797377, 11.4428115],
                [108.2797853, 11.4428902],
                [108.2798392, 11.4429649],
                [108.2798983, 11.4430347],
                [108.2799628, 11.4430996],
                [108.2800323, 11.4431595],
                [108.2802683, 11.4433435],
                [108.2803115, 11.4433948],
                [108.2803474, 11.4434512],
                [108.2803756, 11.4435117],
                [108.2803929, 11.4435653],
                [108.2804042, 11.4436204],
                [108.2804091, 11.4436764],
                [108.2804078, 11.4437326],
                [108.2803872, 11.4440979],
                [108.2803702, 11.4444634],
                [108.2803537, 11.4449418],
                [108.2803434, 11.4454203],
                [108.2803455, 11.4454722],
                [108.2803561, 11.4455231],
                [108.2803748, 11.4455716],
                [108.2804012, 11.4456166],
                [108.2804346, 11.4456569],
                [108.2804749, 11.4456919],
                [108.2805204, 11.4457201],
                [108.28057, 11.4457406],
                [108.2806223, 11.445753],
                [108.280676, 11.4457568],
                [108.2815343, 11.4458462],
                [108.2819313, 11.4463983],
                [108.2821673, 11.4467085],
                [108.2821888, 11.4467368],
                [108.2822151, 11.446761],
                [108.2822453, 11.4467802],
                [108.2822785, 11.4467939],
                [108.2823137, 11.4468016],
                [108.2823497, 11.4468031],
                [108.2823872, 11.4467979],
                [108.282423, 11.4467859],
                [108.282456, 11.4467676],
                [108.2824849, 11.4467437],
                [108.2825088, 11.4467148],
                [108.2825267, 11.4466822],
                [108.2826072, 11.4464955],
                [108.2826284, 11.4464574],
                [108.2826565, 11.4464237],
                [108.2826903, 11.4463956],
                [108.2827247, 11.446376],
                [108.2827619, 11.4463623],
                [108.2828008, 11.4463547],
                [108.2828405, 11.4463536],
                [108.282903, 11.4463596],
                [108.2829658, 11.4463596],
                [108.2830283, 11.4463536],
                [108.2830851, 11.4463427],
                [108.2831407, 11.4463269],
                [108.2831946, 11.4463062],
                [108.2833448, 11.4462431],
                [108.2833825, 11.4462271],
                [108.2834229, 11.4462194],
                [108.283464, 11.4462205],
                [108.2835038, 11.4462303],
                [108.2835406, 11.4462484],
                [108.2835684, 11.4462699],
                [108.2835916, 11.4462961],
                [108.2836094, 11.446326],
                [108.2836211, 11.4463587],
                [108.2836264, 11.446393],
                [108.2836559, 11.4468189],
                [108.2836661, 11.4468701],
                [108.2836832, 11.4469194],
                [108.2837069, 11.4469661],
                [108.2837399, 11.4470128],
                [108.2837795, 11.4470543],
                [108.2838249, 11.4470896],
                [108.2839058, 11.4471697],
                [108.2839805, 11.4472553],
                [108.2840513, 11.44735],
                [108.2841146, 11.4474498],
                [108.2841409, 11.447483],
                [108.2841736, 11.4475103],
                [108.284207, 11.4475285],
                [108.2842432, 11.4475404],
                [108.284281, 11.4475456],
                [108.2845462, 11.4475173],
                [108.2848103, 11.4474803],
                [108.285073, 11.4474347],
                [108.2851536, 11.4474114],
                [108.2852318, 11.447381],
                [108.2853068, 11.4473437],
                [108.2853781, 11.4473],
                [108.285445, 11.44725],
                [108.2855074, 11.4471939],
                [108.2855643, 11.4471324],
                [108.2856152, 11.447066],
                [108.2856597, 11.4469954],
                [108.2856974, 11.446921],
                [108.285728, 11.4468436],
                [108.2857512, 11.4467638],
                [108.2857669, 11.4466822],
                [108.2857912, 11.4465633],
                [108.2858239, 11.4464463],
                [108.2858648, 11.4463319],
                [108.2859139, 11.4462205],
                [108.2859707, 11.4461128],
                [108.2860351, 11.4460092],
                [108.2861026, 11.4459155],
                [108.2861763, 11.4458264],
                [108.2862559, 11.4457423],
                [108.2863409, 11.4456635],
                [108.2864311, 11.4455904],
                [108.286526, 11.4455232],
                [108.2866252, 11.4454624],
                [108.2874191, 11.4450207],
                [108.2876659, 11.4447263],
                [108.288213, 11.4444529],
                [108.2882774, 11.4444094],
                [108.2883384, 11.4443614],
                [108.2883957, 11.4443092],
                [108.2884491, 11.4442531],
                [108.2885077, 11.4441807],
                [108.2885597, 11.4441036],
                [108.2886046, 11.4440224],
                [108.2886422, 11.4439376],
                [108.2887054, 11.4438223],
                [108.2887768, 11.4437116],
                [108.288856, 11.4436062],
                [108.2889426, 11.4435065],
                [108.2890341, 11.4434151],
                [108.2891318, 11.44333],
                [108.2892353, 11.4432518],
                [108.2893441, 11.4431809],
                [108.2894576, 11.4431174],
                [108.2903373, 11.4428335],
                [108.2904438, 11.4427899],
                [108.2905475, 11.4427401],
                [108.290648, 11.4426845],
                [108.290745, 11.4426232],
                [108.2908567, 11.4425421],
                [108.2909622, 11.4424534],
                [108.2910611, 11.4423576],
                [108.2911527, 11.4422552],
                [108.2912785, 11.4421324],
                [108.291411, 11.4420166],
                [108.2915497, 11.4419081],
                [108.2916913, 11.4418093],
                [108.291838, 11.4417181],
                [108.2919896, 11.4416347],
                [108.2926655, 11.4413718],
                [108.2927609, 11.4413522],
                [108.2928577, 11.4413417],
                [108.2929552, 11.4413403],
                [108.2930517, 11.441348],
                [108.2931471, 11.4413647],
                [108.2932403, 11.4413903],
                [108.293286, 11.441406],
                [108.2933307, 11.4414244],
                [108.2934035, 11.4414616],
                [108.2934721, 11.4415058],
                [108.2935356, 11.4415567],
                [108.2935936, 11.4416137],
                [108.2939208, 11.4417399],
                [108.2939939, 11.4416709],
                [108.2940608, 11.4415962],
                [108.2941212, 11.4415163],
                [108.2941745, 11.4414317],
                [108.2942205, 11.441343],
                [108.2942587, 11.4412509],
                [108.2942876, 11.441161],
                [108.2943092, 11.4410692],
                [108.2943233, 11.4409759],
                [108.2943298, 11.4408819],
                [108.2943288, 11.4407877],
                [108.2943201, 11.4406938],
                [108.2943039, 11.4406009],
                [108.2942802, 11.4405096],
                [108.2945216, 11.4395737],
                [108.294538, 11.4394667],
                [108.2945471, 11.4393588],
                [108.2945488, 11.4392506],
                [108.294543, 11.4391425],
                [108.2945296, 11.4390328],
                [108.2945084, 11.4389242],
                [108.2944797, 11.4388173],
                [108.2944436, 11.4387125],
                [108.2944003, 11.4386105],
                [108.2943499, 11.4385116],
                [108.2943392, 11.4374495],
                [108.2943406, 11.4373764],
                [108.2943511, 11.4373041],
                [108.2943708, 11.4372336],
                [108.2943991, 11.4371661],
                [108.2944358, 11.4371025],
                [108.2944743, 11.4370508],
                [108.2945183, 11.4370034],
                [108.2945672, 11.4369611],
                [108.2946206, 11.4369241],
                [108.2946777, 11.4368931],
                [108.2947379, 11.4368683],
                [108.2948005, 11.4368501],
                [108.2949235, 11.4367963],
                [108.2950423, 11.4367344],
                [108.2951566, 11.4366647],
                [108.2952657, 11.4365875],
                [108.2953692, 11.4365031],
                [108.2954566, 11.4364218],
                [108.2955387, 11.4363354],
                [108.2956154, 11.4362443],
                [108.2956861, 11.4361487],
                [108.2957508, 11.4360491],
                [108.2958091, 11.4359457],
                [108.2958414, 11.4358514],
                [108.2958648, 11.4357546],
                [108.2958791, 11.435656],
                [108.2958842, 11.4355566],
                [108.2958817, 11.4354795],
                [108.2958737, 11.4354027],
                [108.2958602, 11.4353266],
                [108.2958412, 11.4352517],
                [108.2958187, 11.4351762],
                [108.2958044, 11.4350988],
                [108.2957983, 11.4350203],
                [108.2958004, 11.4349451],
                [108.2958101, 11.4348705],
                [108.2958273, 11.4347972],
                [108.2968283, 11.4310769],
                [108.296843, 11.4310212],
                [108.2968632, 11.4309672],
                [108.2968888, 11.4309155],
                [108.2969195, 11.4308665],
                [108.2969568, 11.4308186],
                [108.2969991, 11.4307747],
                [108.2970457, 11.4307354],
                [108.2970962, 11.430701],
                [108.2971502, 11.430672],
                [108.2972399, 11.4306238],
                [108.2973256, 11.430569],
                [108.2974067, 11.4305078],
                [108.2974828, 11.4304406],
                [108.2975571, 11.4303636],
                [108.2976247, 11.4302809],
                [108.2976852, 11.430193],
                [108.2977381, 11.4301006],
                [108.2977832, 11.4300042],
                [108.2979977, 11.4294048],
                [108.2980285, 11.4293518],
                [108.2980644, 11.429302],
                [108.298105, 11.4292558],
                [108.29815, 11.4292137],
                [108.2981989, 11.4291761],
                [108.2982543, 11.4291416],
                [108.2983129, 11.4291128],
                [108.2983742, 11.4290901],
                [108.2984376, 11.4290735],
                [108.2987702, 11.4290315],
                [108.2988185, 11.429025],
                [108.2988652, 11.429011],
                [108.2989089, 11.4289898],
                [108.2989485, 11.428962],
                [108.2989831, 11.4289283],
                [108.2990116, 11.4288895],
                [108.2990332, 11.4288473],
                [108.2990477, 11.4288022],
                [108.2990546, 11.4287554],
                [108.2990538, 11.4287081],
                [108.2990453, 11.4286616],
                [108.2990294, 11.428617],
                [108.2990065, 11.4285755],
                [108.298977, 11.428538],
                [108.2989419, 11.4285057],
                [108.2988818, 11.4284443],
                [108.2988275, 11.4283779],
                [108.2987795, 11.428307],
                [108.298738, 11.4282323],
                [108.2987075, 11.4281641],
                [108.2986824, 11.4280939],
                [108.2986629, 11.4280219],
                [108.298683, 11.4279728],
                [108.29871, 11.4279269],
                [108.2987434, 11.4278852],
                [108.2987836, 11.4278478],
                [108.298829, 11.4278164],
                [108.2988785, 11.4277919],
                [108.2989311, 11.4277748],
                [108.298997, 11.4277591],
                [108.2990613, 11.427738],
                [108.2991235, 11.4277116],
                [108.2991833, 11.4276802],
                [108.2992447, 11.4276406],
                [108.2993021, 11.4275958],
                [108.2993552, 11.4275459],
                [108.2994033, 11.4274915],
                [108.2994461, 11.427433],
                [108.2995156, 11.4273595],
                [108.299591, 11.4272917],
                [108.2996717, 11.4272302],
                [108.2997573, 11.4271754],
                [108.2998468, 11.4271277],
                [108.29994, 11.4270873],
                [108.3000362, 11.4270545],
                [108.3001066, 11.4270545],
                [108.3001765, 11.4270616],
                [108.3002454, 11.4270755],
                [108.3003115, 11.4270957],
                [108.3003752, 11.4271223],
                [108.3004359, 11.427155],
                [108.300493, 11.4271934],
                [108.3005459, 11.4272371],
                [108.3005941, 11.4272858],
                [108.3006753, 11.4273828],
                [108.3007524, 11.427483],
                [108.3008143, 11.42757],
                [108.3008731, 11.4276591],
                [108.3009121, 11.4277066],
                [108.3009564, 11.4277496],
                [108.3010052, 11.4277873],
                [108.3010581, 11.4278195],
                [108.3011185, 11.4278472],
                [108.3011817, 11.4278676],
                [108.301247, 11.4278804],
                [108.3013135, 11.4278854],
                [108.30138, 11.4278826],
                [108.3018923, 11.4278642],
                [108.3019698, 11.4278525],
                [108.3020461, 11.4278345],
                [108.3021206, 11.4278103],
                [108.3021927, 11.4277801],
                [108.3022693, 11.4277399],
                [108.3023417, 11.4276929],
                [108.3024093, 11.4276395],
                [108.3024717, 11.4275803],
                [108.3028364, 11.4272911],
                [108.3028739, 11.4272416],
                [108.3029054, 11.4271884],
                [108.3029304, 11.4271319],
                [108.3029486, 11.427073],
                [108.3029598, 11.4270124],
                [108.3029639, 11.4269521],
                [108.302961, 11.4268918],
                [108.3029512, 11.4268321],
                [108.3029347, 11.4267739],
                [108.3029115, 11.4267179],
                [108.3028987, 11.4266663],
                [108.3028917, 11.4266136],
                [108.3028906, 11.4265605],
                [108.3028955, 11.4265076],
                [108.3029089, 11.4264454],
                [108.3029305, 11.4263855],
                [108.3029598, 11.4263288],
                [108.3029819, 11.4262859],
                [108.3029976, 11.4262402],
                [108.3030063, 11.4261929],
                [108.3030081, 11.4261448],
                [108.3030032, 11.4260989],
                [108.3029919, 11.426054],
                [108.3029744, 11.4260112],
                [108.302951, 11.4259711],
                [108.3029223, 11.4259345],
                [108.3026487, 11.4256348],
                [108.3026226, 11.4255748],
                [108.3026025, 11.4255128],
                [108.3025884, 11.4254491],
                [108.3025805, 11.4253844],
                [108.302579, 11.4253193],
                [108.302585, 11.4252438],
                [108.3025996, 11.4251693],
                [108.3026224, 11.425097],
                [108.3026532, 11.4250275],
                [108.3026916, 11.4249617],
                [108.3027348, 11.4249072],
                [108.3027825, 11.4248563],
                [108.3028344, 11.4248095],
                [108.3028901, 11.4247672],
                [108.3029646, 11.4247208],
                [108.3030437, 11.4246822],
                [108.3031264, 11.424652],
                [108.303212, 11.4246305],
                [108.3033004, 11.4246107],
                [108.3033004, 11.4244819],
                [108.3032602, 11.4244214],
                [108.3032629, 11.4239022],
                [108.3032597, 11.423858],
                [108.3032633, 11.4238139],
                [108.3032736, 11.4237707],
                [108.3032924, 11.4237255],
                [108.3033186, 11.4236839],
                [108.3033514, 11.4236472],
                [108.3033514, 11.4234],
                [108.303389, 11.4231871],
                [108.3033979, 11.4231411],
                [108.3034158, 11.4230977],
                [108.3034373, 11.4230642],
                [108.3034641, 11.4230346],
                [108.3035105, 11.4230179],
                [108.3035586, 11.4230065],
                [108.3036077, 11.4230007],
                [108.3036572, 11.4230004],
                [108.3037179, 11.4230079],
                [108.303777, 11.4230237],
                [108.3038331, 11.4230477],
                [108.3038852, 11.4230793],
                [108.3041051, 11.423308],
                [108.3041291, 11.423336],
                [108.304158, 11.4233591],
                [108.3041908, 11.4233765],
                [108.3042263, 11.4233877],
                [108.3042634, 11.4233921],
                [108.3043031, 11.4233894],
                [108.3043415, 11.4233789],
                [108.304377, 11.4233612],
                [108.3044082, 11.4233369],
                [108.3045208, 11.4231766],
                [108.3045428, 11.4231451],
                [108.3045689, 11.4231169],
                [108.3045986, 11.4230924],
                [108.304632, 11.4230718],
                [108.304668, 11.423056],
                [108.3047059, 11.4230451],
                [108.3051458, 11.4229518],
                [108.3051786, 11.422943],
                [108.3052093, 11.4229289],
                [108.305237, 11.4229097],
                [108.3052579, 11.4228896],
                [108.3052754, 11.4228666],
                [108.3052893, 11.4228413],
                [108.3053175, 11.4226744],
                [108.3053089, 11.4226016],
                [108.3053065, 11.4225284],
                [108.3053102, 11.4224552],
                [108.3053201, 11.4223826],
                [108.3053372, 11.4223071],
                [108.3053609, 11.4222334],
                [108.305391, 11.422162],
                [108.3054274, 11.4220934],
                [108.3055857, 11.4219514],
                [108.3056194, 11.4218922],
                [108.3056602, 11.4218373],
                [108.3057072, 11.4217876],
                [108.30576, 11.4217437],
                [108.3058163, 11.4217071],
                [108.3058764, 11.4216772],
                [108.3059397, 11.4216543],
                [108.3062455, 11.421557],
                [108.3062321, 11.4211154],
                [108.306236, 11.4210481],
                [108.3062473, 11.4209817],
                [108.3062656, 11.4209169],
                [108.3062774, 11.4208852],
                [108.3062829, 11.4208519],
                [108.3062817, 11.4208183],
                [108.3062736, 11.420784],
                [108.3062586, 11.420752],
                [108.3062375, 11.4207236],
                [108.3062148, 11.4207023],
                [108.306197, 11.4206769],
                [108.3061847, 11.4206486],
                [108.3061784, 11.4206185],
                [108.3061785, 11.4205876],
                [108.3061848, 11.4205574],
                [108.3061971, 11.4205291],
                [108.306215, 11.4205037],
                [108.3062377, 11.4204824],
                [108.3062643, 11.420466],
                [108.3064681, 11.420345],
                [108.3067149, 11.4201768],
                [108.3067547, 11.4201464],
                [108.3067891, 11.4201102],
                [108.3068172, 11.4200692],
                [108.3068383, 11.4200243],
                [108.3068523, 11.4199746],
                [108.3068577, 11.4199233],
                [108.3068544, 11.4198718],
                [108.3068034, 11.4195984],
                [108.3067937, 11.4195545],
                [108.3067766, 11.4195129],
                [108.3067524, 11.4194748],
                [108.3067279, 11.4194469],
                [108.3066995, 11.4194226],
                [108.306668, 11.4194025],
                [108.3066347, 11.4193787],
                [108.3066074, 11.4193485],
                [108.3065874, 11.4193132],
                [108.3065757, 11.4192745],
                [108.3065727, 11.4192342],
                [108.3065779, 11.4191972],
                [108.3065906, 11.419162],
                [108.3066101, 11.41913],
                [108.3066358, 11.4191024],
                [108.3066666, 11.4190804],
                [108.3066945, 11.4190662],
                [108.3067175, 11.4190451],
                [108.3067337, 11.4190186],
                [108.3067419, 11.418989],
                [108.3067417, 11.4189582],
                [108.3067095, 11.4188675],
                [108.3066883, 11.4188324],
                [108.3066747, 11.4187939],
                [108.3066702, 11.4187677],
                [108.3066693, 11.4187413],
                [108.3066854, 11.4186308],
                [108.3066851, 11.4185941],
                [108.3066788, 11.4185578],
                [108.3066666, 11.4185231],
                [108.3066472, 11.4184882],
                [108.3066219, 11.4184572],
                [108.3065915, 11.418431],
                [108.3064198, 11.4183285],
                [108.3063955, 11.4183155],
                [108.3063765, 11.4182958],
                [108.3063645, 11.4182713],
                [108.3063608, 11.4182444],
                [108.3063639, 11.4182235],
                [108.306372, 11.418204],
                [108.3063846, 11.418187],
                [108.3064011, 11.4181734],
                [108.3065191, 11.4181077],
                [108.3065524, 11.4180789],
                [108.3065801, 11.418045],
                [108.3066014, 11.4180068],
                [108.3066156, 11.4179657],
                [108.3066221, 11.4179274],
                [108.3066224, 11.4178886],
                [108.3066167, 11.4178502],
                [108.3066049, 11.4178132],
                [108.3065955, 11.4177615],
                [108.3065933, 11.417709],
                [108.3065982, 11.4176567],
                [108.3066103, 11.4176055],
                [108.306629, 11.4175568],
                [108.3066542, 11.4175109],
                [108.3066854, 11.4174688],
                [108.3067013, 11.4174273],
                [108.30671, 11.4173839],
                [108.3067114, 11.4173396],
                [108.3067054, 11.4172957],
                [108.3066921, 11.4172534],
                [108.306672, 11.4172137],
                [108.3066421, 11.4171741],
                [108.3066054, 11.4171405],
                [108.306563, 11.417114],
                [108.3065164, 11.4170954],
                [108.3064788, 11.4170789],
                [108.3064441, 11.4170569],
                [108.3064134, 11.41703],
                [108.3063872, 11.4169988],
                [108.3063662, 11.416964],
                [108.3063507, 11.4169258],
                [108.3063415, 11.4168858],
                [108.3063388, 11.4168448],
                [108.3063425, 11.416804],
                [108.3063528, 11.4167642],
                [108.3063678, 11.4167181],
                [108.3063768, 11.4166705],
                [108.3063796, 11.4166222],
                [108.3063768, 11.4165776],
                [108.3063687, 11.4165335],
                [108.3063555, 11.4164907],
                [108.306335, 11.4164521],
                [108.3063179, 11.4164119],
                [108.3063017, 11.41636],
                [108.3062911, 11.4163067],
                [108.3062723, 11.4160753],
                [108.3062689, 11.4160372],
                [108.3062571, 11.4160007],
                [108.3062375, 11.4159675],
                [108.3062173, 11.4159451],
                [108.3061936, 11.4159263],
                [108.306167, 11.4159117],
                [108.3061382, 11.4159018],
                [108.305921, 11.4158913],
                [108.3058848, 11.4158824],
                [108.3058507, 11.4158677],
                [108.3058196, 11.4158477],
                [108.3057922, 11.4158229],
                [108.305768, 11.4157919],
                [108.3057497, 11.4157571],
                [108.305738, 11.4157198],
                [108.3057332, 11.415681],
                [108.30573, 11.4156534],
                [108.3057219, 11.4156268],
                [108.3057091, 11.4156021],
                [108.3056865, 11.4155743],
                [108.3056581, 11.4155521],
                [108.3055615, 11.4155206],
                [108.305542, 11.4155049],
                [108.3055265, 11.4154853],
                [108.3055159, 11.4154627],
                [108.305511, 11.4154401],
                [108.305511, 11.415417],
                [108.3055159, 11.4153944],
                [108.3055454, 11.4152866],
                [108.3055775, 11.4152084],
                [108.3056125, 11.4151315],
                [108.3056599, 11.4150383],
                [108.3057117, 11.4149474],
                [108.30587, 11.4147818],
                [108.3059105, 11.4147434],
                [108.3059442, 11.4146991],
                [108.3059703, 11.4146502],
                [108.305988, 11.4145977],
                [108.3059966, 11.4145472],
                [108.3059975, 11.414496],
                [108.3059907, 11.4144453],
                [108.3059871, 11.4144225],
                [108.3059892, 11.4143996],
                [108.3059968, 11.4143778],
                [108.3060095, 11.4143585],
                [108.3060298, 11.4143404],
                [108.3060544, 11.4143286],
                [108.3060814, 11.414324],
                [108.3061087, 11.4143269],
                [108.3061326, 11.4143266],
                [108.3061549, 11.4143184],
                [108.3061731, 11.4143033],
                [108.306182, 11.4142898],
                [108.3061875, 11.4142746],
                [108.3061892, 11.4142586],
                [108.3061597, 11.4141587],
                [108.3061616, 11.4141239],
                [108.3061725, 11.4140907],
                [108.3061919, 11.4140614],
                [108.3062139, 11.414041],
                [108.3062401, 11.4140259],
                [108.3062689, 11.4140167],
                [108.3062991, 11.4140141],
                [108.3065459, 11.4139957],
                [108.3065741, 11.4139971],
                [108.3066021, 11.4139936],
                [108.3066291, 11.4139852],
                [108.3066546, 11.4139718],
                [108.3066772, 11.4139541],
                [108.3066961, 11.4139326],
                [108.3067131, 11.4138953],
                [108.3067363, 11.4138616],
                [108.3067649, 11.4138327],
                [108.306798, 11.413809],
                [108.3069026, 11.4137617],
                [108.3069315, 11.4137424],
                [108.3069549, 11.413717],
                [108.3069715, 11.4136869],
                [108.3069804, 11.4136539],
                [108.3069812, 11.4136201],
                [108.3069739, 11.4135871],
                [108.306959, 11.4135566],
                [108.3068678, 11.4134173],
                [108.3068524, 11.4134013],
                [108.3068432, 11.4133813],
                [108.306841, 11.4133594],
                [108.3068441, 11.4133432],
                [108.3068512, 11.4133282],
                [108.3068617, 11.4133154],
                [108.3069885, 11.4132096],
                [108.3028899, 11.4095886],
                [108.3011788, 11.4077776],
                [108.2906806, 11.4004683],
                [108.2868317, 11.3987219],
                [108.278836, 11.395094],
                [108.278832, 11.3942579],
                [108.2788316, 11.3941481],
                [108.2788258, 11.3940384],
                [108.2788146, 11.3939292],
                [108.2787958, 11.3938094],
                [108.2787705, 11.3936908],
                [108.2787387, 11.3935738],
                [108.2787006, 11.3934585],
                [108.2783358, 11.3927473],
                [108.2782768, 11.3926529],
                [108.2782257, 11.3925541],
                [108.2781828, 11.3924517],
                [108.2781483, 11.3923463],
                [108.2781225, 11.3922385],
                [108.2781053, 11.3921266],
                [108.2780973, 11.3920137],
                [108.2780988, 11.3919005],
                [108.2781097, 11.3917878],
                [108.2781299, 11.3916764],
                [108.2781593, 11.391567],
                [108.2781976, 11.3914602],
                [108.2782346, 11.3913482],
                [108.2782641, 11.3912341],
                [108.2782859, 11.3911183],
                [108.2783, 11.3910014],
                [108.2783064, 11.3908838],
                [108.2783049, 11.3907661],
                [108.2782974, 11.390665],
                [108.2782842, 11.3905645],
                [108.2782652, 11.3904648],
                [108.2782406, 11.3903664],
                [108.2778929, 11.3892231],
                [108.2777309, 11.3888151],
                [108.277674, 11.3887017],
                [108.2776227, 11.3885857],
                [108.2775773, 11.3884674],
                [108.2775378, 11.388347],
                [108.2774969, 11.3881939],
                [108.2774655, 11.3880385],
                [108.2774439, 11.3878816],
                [108.2774389, 11.3878103],
                [108.2774406, 11.3877387],
                [108.277449, 11.3876677],
                [108.2774641, 11.3875977],
                [108.27749, 11.3875176],
                [108.2775247, 11.3874409],
                [108.2775676, 11.3873682],
                [108.2776183, 11.3873005],
                [108.2776463, 11.3872589],
                [108.2776692, 11.3872144],
                [108.2776868, 11.3871676],
                [108.2776988, 11.3871191],
                [108.2777053, 11.3870631],
                [108.2777044, 11.3870066],
                [108.2776961, 11.3869508],
                [108.2774466, 11.3864775],
                [108.2771802, 11.3862197],
                [108.2769197, 11.3859562],
                [108.2766651, 11.3856871],
                [108.2764166, 11.3854126],
                [108.2761824, 11.3851423],
                [108.275954, 11.3848672],
                [108.2757317, 11.3845874],
                [108.2755154, 11.384303],
                [108.2744104, 11.3827595],
                [108.2737344, 11.3816998],
                [108.2736822, 11.3816293],
                [108.2736234, 11.381564],
                [108.2735584, 11.3815046],
                [108.2734879, 11.3814515],
                [108.2734126, 11.3814053],
                [108.2733426, 11.3813706],
                [108.2732698, 11.3813419],
                [108.2731948, 11.3813192],
                [108.2731182, 11.3813029],
                [108.2730403, 11.381293],
                [108.272962, 11.3812896],
                [108.2729029, 11.3812803],
                [108.2728453, 11.3812647],
                [108.2727896, 11.3812432],
                [108.2727367, 11.381216],
                [108.2726814, 11.3811792],
                [108.272631, 11.3811363],
                [108.272529, 11.3810521],
                [108.2724309, 11.3809636],
                [108.2723353, 11.3808694],
                [108.272244, 11.3807712],
                [108.2721573, 11.3806691],
                [108.2714707, 11.3798592],
                [108.2705909, 11.3789126],
                [108.2693142, 11.3774296],
                [108.2685766, 11.3763515],
                [108.2685456, 11.3763052],
                [108.2685101, 11.3762623],
                [108.2684701, 11.3762233],
                [108.2684264, 11.3761884],
                [108.2683795, 11.3761584],
                [108.2683298, 11.3761332],
                [108.2682981, 11.3761197],
                [108.2682692, 11.3761011],
                [108.268244, 11.376078],
                [108.2682225, 11.3760502],
                [108.2682062, 11.3760192],
                [108.2681957, 11.375986],
                [108.2681703, 11.3758958],
                [108.2681398, 11.3758072],
                [108.2681045, 11.3757204],
                [108.2680627, 11.3756324],
                [108.268016, 11.3755468],
                [108.2673132, 11.3747737],
                [108.2672657, 11.3747252],
                [108.2672137, 11.3746812],
                [108.2671577, 11.3746423],
                [108.2671026, 11.3746109],
                [108.267045, 11.3745844],
                [108.2669949, 11.3745744],
                [108.2669464, 11.3745586],
                [108.2669002, 11.3745371],
                [108.2668478, 11.3745037],
                [108.2668009, 11.3744631],
                [108.2667607, 11.3744161],
                [108.2661384, 11.3735536],
                [108.2658139, 11.3725925],
                [108.2657039, 11.371613],
                [108.2654678, 11.3713435],
                [108.2652282, 11.3710771],
                [108.2649851, 11.3708137],
                [108.2647061, 11.3705198],
                [108.2644228, 11.37023],
                [108.2641352, 11.3699443],
                [108.2637191, 11.369136],
                [108.2564878, 11.3609737],
                [108.2563578, 11.3608263],
                [108.2562237, 11.3606825],
                [108.2560855, 11.3605424],
                [108.2559563, 11.3604182],
                [108.255824, 11.3602972],
                [108.2556885, 11.3601795],
                [108.2544467, 11.3595867],
                [108.25436, 11.3595543],
                [108.2542704, 11.3595304],
                [108.2541788, 11.3595152],
                [108.2540862, 11.3595089],
                [108.2539934, 11.3595116],
                [108.2539061, 11.3595223],
                [108.2538202, 11.359541],
                [108.2537365, 11.3595673],
                [108.2536556, 11.3596013],
                [108.2535783, 11.3596424],
                [108.2535052, 11.3596904],
                [108.2530868, 11.3600217],
                [108.2525879, 11.360148],
                [108.252089, 11.3604109],
                [108.2519252, 11.3604655],
                [108.2517593, 11.3605139],
                [108.2515917, 11.360556],
                [108.2514226, 11.3605918],
                [108.2512522, 11.3606213],
                [108.2510561, 11.3606471],
                [108.2508591, 11.3606645],
                [108.2506614, 11.3606734],
                [108.2504636, 11.3606739],
                [108.2496482, 11.3600691],
                [108.2478887, 11.358987],
                [108.2461882, 11.3575866],
                [108.2460659, 11.357489],
                [108.2459396, 11.3573964],
                [108.2458096, 11.3573089],
                [108.245676, 11.3572268],
                [108.2455391, 11.3571501],
                [108.2453974, 11.3570783],
                [108.2452529, 11.3570123],
                [108.2451056, 11.3569522],
                [108.244956, 11.3568982],
                [108.2448041, 11.3568503],
                [108.2446137, 11.3567893],
                [108.2444259, 11.3567209],
                [108.244241, 11.356645],
                [108.2440595, 11.3565619],
                [108.2438815, 11.3564717],
                [108.2436904, 11.3563645],
                [108.2435044, 11.3562492],
                [108.2433238, 11.3561259],
                [108.243149, 11.3559948],
                [108.2429802, 11.3558563],
                [108.24274, 11.3556581],
                [108.2424934, 11.3554674],
                [108.2422408, 11.3552845],
                [108.2419825, 11.3551095],
                [108.241756, 11.3549656],
                [108.2415257, 11.3548278],
                [108.2412917, 11.3546962],
                [108.241054, 11.354571],
                [108.240813, 11.3544521],
                [108.2384419, 11.3533265],
                [108.2345795, 11.3514279],
                [108.2327986, 11.3502918],
                [108.2297355, 11.3474359],
                [108.2283176, 11.3461925],
                [108.227026, 11.3450298],
                [108.2265593, 11.3446004],
                [108.2260985, 11.344165],
                [108.2251947, 11.3432763],
                [108.2244504, 11.3425077],
                [108.2238618, 11.3418639],
                [108.2235748, 11.3415356],
                [108.2230891, 11.3409569],
                [108.2224877, 11.3402106],
                [108.2218531, 11.3394046],
                [108.2216199, 11.339096],
                [108.2213917, 11.3387839],
                [108.2210396, 11.3382809],
                [108.2209036, 11.3380985],
                [108.2206682, 11.3378131],
                [108.220583, 11.3376993],
                [108.2205058, 11.3375802],
                [108.2204368, 11.3374563],
                [108.2203763, 11.3373282],
                [108.2203246, 11.3371964],
                [108.2202819, 11.3370616],
                [108.2202485, 11.3369243],
                [108.2202112, 11.3366646],
                [108.2201915, 11.3363017],
                [108.2201814, 11.3358173],
                [108.2201996, 11.335235],
                [108.2202508, 11.3346546],
                [108.2202887, 11.3343656],
                [108.2177932, 11.3329667],
                [108.2165401, 11.3322766],
                [108.2128785, 11.3302968],
                [108.2123116, 11.329978],
                [108.2113, 11.3293876],
                [108.2108464, 11.3291308],
                [108.2103879, 11.3288826],
                [108.2096102, 11.3284864],
                [108.2094962, 11.3284315],
                [108.2086587, 11.3280328],
                [108.2079975, 11.3277296],
                [108.2073323, 11.327435],
                [108.1971788, 11.3230042],
                [108.1970556, 11.3225714],
                [108.1969641, 11.3221311],
                [108.1969305, 11.3219088],
                [108.1968876, 11.3214614],
                [108.1968771, 11.3210402],
                [108.1968843, 11.3208437],
                [108.1969445, 11.3202282],
                [108.1969552, 11.3200175],
                [108.1969535, 11.3195957],
                [108.196945, 11.3194385],
                [108.1969273, 11.319282],
                [108.1968648, 11.3189733],
                [108.1967669, 11.3186735],
                [108.1967051, 11.3185282],
                [108.1966349, 11.3183866],
                [108.1965566, 11.3182491],
                [108.1963828, 11.3179964],
                [108.1961817, 11.317764],
                [108.1907281, 11.3119606],
                [108.1905907, 11.3118355],
                [108.1904666, 11.3117348],
                [108.1902828, 11.3116035],
                [108.1900778, 11.31148],
                [108.1897304, 11.3112947],
                [108.1894688, 11.3111323],
                [108.1892277, 11.3109417],
                [108.189116, 11.3108366],
                [108.1889116, 11.3106084],
                [108.1888198, 11.3104861],
                [108.1887352, 11.3103589],
                [108.1885391, 11.3100331],
                [108.1884311, 11.3098763],
                [108.1881964, 11.309576],
                [108.187768, 11.3091085],
                [108.187598, 11.3089491],
                [108.1873788, 11.3087154],
                [108.1869221, 11.3082653],
                [108.1866737, 11.3080393],
                [108.1864191, 11.3078201],
                [108.1861585, 11.3076078],
                [108.1858921, 11.3074026],
                [108.1827271, 11.305046],
                [108.1823017, 11.3049343],
                [108.1818738, 11.3048319],
                [108.1806571, 11.304581],
                [108.1802943, 11.3044996],
                [108.1799328, 11.3044126],
                [108.1788667, 11.3041344],
                [108.1785109, 11.3040522],
                [108.1777946, 11.3039088],
                [108.177073, 11.303794],
                [108.1763797, 11.3037112],
                [108.1756836, 11.3036546],
                [108.1747861, 11.303601],
                [108.1736903, 11.3035074],
                [108.1703299, 11.301732],
                [108.1587321, 11.3016794],
                [108.1586671, 11.301676],
                [108.1586035, 11.3016632],
                [108.1585424, 11.3016414],
                [108.1584474, 11.3015842],
                [108.1583626, 11.3015466],
                [108.1582707, 11.3015321],
                [108.1581983, 11.3015376],
                [108.1581258, 11.301534],
                [108.1580543, 11.3015213],
                [108.1579851, 11.3014999],
                [108.1579191, 11.30147],
                [108.1577486, 11.3013558],
                [108.1576233, 11.3013091],
                [108.15749, 11.301295],
                [108.1574231, 11.3013006],
                [108.1572219, 11.3013427],
                [108.1570166, 11.3013551],
                [108.1569137, 11.30135],
                [108.1567424, 11.3013246],
                [108.1566099, 11.3012782],
                [108.15649, 11.301206],
                [108.1563878, 11.3011112],
                [108.1562804, 11.3009861],
                [108.1562174, 11.3009323],
                [108.1560762, 11.3008452],
                [108.1559995, 11.3008128],
                [108.1558379, 11.300772],
                [108.1557548, 11.3007641],
                [108.1555223, 11.3007625],
                [108.1552921, 11.3007307],
                [108.1551791, 11.3007037],
                [108.1550021, 11.3006451],
                [108.1548084, 11.3005621],
                [108.1545197, 11.3004136],
                [108.1541833, 11.3002623],
                [108.1538335, 11.3001436],
                [108.1534736, 11.3000586],
                [108.1531071, 11.3000081],
                [108.1529224, 11.299996],
                [108.1524571, 11.2999827],
                [108.1520725, 11.2999482],
                [108.1517714, 11.2998905],
                [108.1514788, 11.2998],
                [108.1511985, 11.2996777],
                [108.1510641, 11.2996052],
                [108.1509341, 11.2995253],
                [108.1504983, 11.2992047],
                [108.1503345, 11.2990996],
                [108.1501655, 11.2990027],
                [108.1499917, 11.2989143],
                [108.1498136, 11.2988346],
                [108.1496316, 11.2987637],
                [108.1494462, 11.298702],
                [108.1492652, 11.2986513],
                [108.1488991, 11.2985652],
                [108.1484415, 11.298471],
                [108.147992, 11.2983448],
                [108.147553, 11.2981873],
                [108.1471843, 11.2980269],
                [108.1470268, 11.2979654],
                [108.146586, 11.2978315],
                [108.1463134, 11.2977288],
                [108.1461509, 11.2976556],
                [108.1455029, 11.2973212],
                [108.1453276, 11.2972352],
                [108.1448595, 11.2970297],
                [108.1445807, 11.2968818],
                [108.1443858, 11.2967568],
                [108.1442943, 11.2966861],
                [108.1442072, 11.2966102],
                [108.143976, 11.296378],
                [108.1438934, 11.2963111],
                [108.1437138, 11.2961963],
                [108.1436179, 11.2961492],
                [108.1434166, 11.296077],
                [108.1432853, 11.2960471],
                [108.1431563, 11.2960085],
                [108.1427571, 11.2958449],
                [108.1424723, 11.2957591],
                [108.1421795, 11.2957047],
                [108.1418824, 11.2956824],
                [108.1417272, 11.2956837],
                [108.1415723, 11.295676],
                [108.141418, 11.2956593],
                [108.1412651, 11.2956337],
                [108.1411139, 11.2955993],
                [108.1409651, 11.2955562],
                [108.1406843, 11.295448],
                [108.1404179, 11.295309],
                [108.1347782, 11.2920993],
                [108.1346632, 11.2920226],
                [108.1344592, 11.2918592],
                [108.1342757, 11.2916739],
                [108.1340964, 11.291521],
                [108.1340003, 11.2914526],
                [108.1338223, 11.2913464],
                [108.1337409, 11.2913099],
                [108.1335698, 11.2912587],
                [108.1333921, 11.2912378],
                [108.1332135, 11.2912478],
                [108.1330104, 11.2912816],
                [108.1328503, 11.291292],
                [108.1327387, 11.2913179],
                [108.1326376, 11.291371],
                [108.1325931, 11.2914067],
                [108.1324962, 11.2915137],
                [108.1324612, 11.2915405],
                [108.132382, 11.2915792],
                [108.1323395, 11.2915905],
                [108.1322085, 11.2916044],
                [108.1321207, 11.291604],
                [108.1319795, 11.291585],
                [108.1318218, 11.29154],
                [108.1317202, 11.2914997],
                [108.1316224, 11.2914513],
                [108.1313915, 11.2913077],
                [108.1313169, 11.2912741],
                [108.1311013, 11.2912067],
                [108.1309747, 11.2911386],
                [108.1308596, 11.2910404],
                [108.1305699, 11.2906654],
                [108.1304572, 11.2905581],
                [108.130393, 11.2905135],
                [108.1303244, 11.2904757],
                [108.1301772, 11.2904219],
                [108.1300494, 11.2904006],
                [108.1299198, 11.2904009],
                [108.1227314, 11.2809947],
                [108.1225904, 11.2811366],
                [108.1225091, 11.2812306],
                [108.1224233, 11.2813552],
                [108.1222715, 11.2816164],
                [108.1220649, 11.2820669],
                [108.1219829, 11.2822188],
                [108.1218935, 11.2823666],
                [108.121693, 11.2826484],
                [108.1214654, 11.2829097],
                [108.1213411, 11.2830329],
                [108.1205023, 11.2837508],
                [108.1202558, 11.2839785],
                [108.12012, 11.284145],
                [108.120063, 11.2842358],
                [108.1199724, 11.2844296],
                [108.1199395, 11.2845312],
                [108.1199005, 11.2847317],
                [108.1198806, 11.2850238],
                [108.1198774, 11.2852189],
                [108.1198835, 11.2854139],
                [108.1199126, 11.2857234],
                [108.1199683, 11.2860664],
                [108.1200154, 11.2862934],
                [108.120175, 11.2869216],
                [108.1202433, 11.2873321],
                [108.1202638, 11.2875391],
                [108.1202748, 11.2877353],
                [108.1202764, 11.2879318],
                [108.1202685, 11.2881282],
                [108.1202513, 11.288324],
                [108.1202246, 11.2885188],
                [108.1201887, 11.2887122],
                [108.1200909, 11.2891426],
                [108.1200332, 11.2894502],
                [108.1200081, 11.2895392],
                [108.1199742, 11.2896253],
                [108.1199318, 11.2897077],
                [108.1198813, 11.2897857],
                [108.1198232, 11.2898583],
                [108.1197581, 11.289925],
                [108.1196865, 11.289985],
                [108.1196094, 11.2900379],
                [108.1194132, 11.2901518],
                [108.1192348, 11.290291],
                [108.1190964, 11.290431],
                [108.118939, 11.2906271],
                [108.1188422, 11.2907638],
                [108.1186594, 11.2910656],
                [108.1184989, 11.2913793],
                [108.1183932, 11.2916232],
                [108.1182218, 11.291976],
                [108.1180239, 11.292286],
                [108.1179944, 11.2923964],
                [108.118, 11.2925103],
                [108.1180123, 11.292555],
                [108.1180953, 11.2927519],
                [108.1181348, 11.2928154],
                [108.1182452, 11.2929464],
                [108.1182663, 11.2929897],
                [108.118285, 11.2930835],
                [108.118282, 11.2931314],
                [108.1182517, 11.2932222],
                [108.1181928, 11.2932977],
                [108.1179994, 11.2934387],
                [108.1178284, 11.2935256],
                [108.1176766, 11.2935776],
                [108.117367, 11.2936624],
                [108.1169936, 11.2937449],
                [108.1168093, 11.2937954],
                [108.1156611, 11.2941792],
                [108.1155948, 11.2941952],
                [108.1155271, 11.2942047],
                [108.1153907, 11.294204],
                [108.1152595, 11.2941778],
                [108.1151981, 11.2941547],
                [108.1151399, 11.2941248],
                [108.1150359, 11.2940463],
                [108.114953, 11.2939465],
                [108.114905, 11.2938547],
                [108.1147814, 11.2935151],
                [108.1147274, 11.2933074],
                [108.1146644, 11.2931022],
                [108.1145924, 11.2928998],
                [108.1145115, 11.2927006],
                [108.1142582, 11.2921677],
                [108.1141403, 11.2918753],
                [108.1140674, 11.2916302],
                [108.1140403, 11.2915054],
                [108.1139898, 11.2911975],
                [108.1139632, 11.2911092],
                [108.1138875, 11.2909407],
                [108.1138389, 11.2908618],
                [108.1137222, 11.2907176],
                [108.1136548, 11.2906534],
                [108.1133149, 11.290384],
                [108.1132382, 11.2903084],
                [108.1131044, 11.2901406],
                [108.1130481, 11.2900494],
                [108.1129582, 11.2898554],
                [108.1128604, 11.2895162],
                [108.112814, 11.2894019],
                [108.1127586, 11.2892915],
                [108.1126946, 11.2891856],
                [108.1126065, 11.289065],
                [108.1124463, 11.2888133],
                [108.1123745, 11.2886826],
                [108.1121641, 11.2882359],
                [108.1120835, 11.2880833],
                [108.1118873, 11.2877592],
                [108.1117853, 11.2875803],
                [108.1116915, 11.287397],
                [108.1115692, 11.2871218],
                [108.1111765, 11.2863148],
                [108.1110945, 11.2860766],
                [108.1110667, 11.2859538],
                [108.1110477, 11.2858294],
                [108.1110363, 11.2856141],
                [108.1110406, 11.2855064],
                [108.1110592, 11.2853665],
                [108.111088, 11.2853059],
                [108.1111092, 11.2852784],
                [108.1112505, 11.2851673],
                [108.1113324, 11.2850938],
                [108.1114076, 11.2850138],
                [108.1114757, 11.2849278],
                [108.1115362, 11.2848364],
                [108.1115885, 11.2847404],
                [108.1116549, 11.2845781],
                [108.1117078, 11.2843868],
                [108.111752, 11.2841262],
                [108.1117623, 11.2839944],
                [108.1117588, 11.2837303],
                [108.1117451, 11.2835988],
                [108.11167, 11.2831516],
                [108.1116072, 11.2826004],
                [108.1115295, 11.2820388],
                [108.1114846, 11.2814882],
                [108.1114962, 11.2813286],
                [108.1115134, 11.2812503],
                [108.1115698, 11.2811003],
                [108.1116086, 11.2810298],
                [108.1117413, 11.2808457],
                [108.111804, 11.2807139],
                [108.1118254, 11.2806441],
                [108.111847, 11.2805002],
                [108.1118326, 11.2801942],
                [108.1118499, 11.2799194],
                [108.1118996, 11.2796484],
                [108.1119811, 11.2793849],
                [108.1120671, 11.2791913],
                [108.1121799, 11.2790113],
                [108.1123172, 11.2788486],
                [108.112486, 11.2786853],
                [108.1125517, 11.278576],
                [108.1125734, 11.2785161],
                [108.1125872, 11.278454],
                [108.1125987, 11.2782841],
                [108.1125927, 11.278166],
                [108.112567, 11.2779789],
                [108.1125095, 11.2777988],
                [108.1124817, 11.2777374],
                [108.1124453, 11.2776806],
                [108.112401, 11.2776293],
                [108.1123498, 11.2775848],
                [108.1122926, 11.2775478],
                [108.1121907, 11.2775059],
                [108.112067, 11.2774805],
                [108.1116861, 11.2774595],
                [108.1114665, 11.2774357],
                [108.1112481, 11.2774023],
                [108.1110315, 11.2773593],
                [108.1105376, 11.2772341],
                [108.1102527, 11.2771854],
                [108.1100305, 11.2771644],
                [108.1098774, 11.2771297],
                [108.1097347, 11.2770654],
                [108.1096691, 11.2770228],
                [108.1095006, 11.2768921],
                [108.1094097, 11.2768359],
                [108.1090581, 11.2766693],
                [108.1087091, 11.2764731],
                [108.1084728, 11.2763652],
                [108.1080864, 11.2762341],
                [108.1080036, 11.2761995],
                [108.107925, 11.2761566],
                [108.1078514, 11.2761059],
                [108.1077835, 11.2760479],
                [108.1077222, 11.2759833],
                [108.107631, 11.2758536],
                [108.1075364, 11.2756669],
                [108.1074434, 11.275443],
                [108.1073465, 11.2751493],
                [108.1072896, 11.2749513],
                [108.1071961, 11.2745502],
                [108.107121, 11.2740767],
                [108.1070629, 11.2734353],
                [108.1070311, 11.2729549],
                [108.1070289, 11.2727956],
                [108.1070571, 11.2722909],
                [108.1070483, 11.2721978],
                [108.1070299, 11.272106],
                [108.1070022, 11.2720166],
                [108.1069654, 11.2719303],
                [108.1069191, 11.2718467],
                [108.1068653, 11.2717676],
                [108.1066711, 11.2715493],
                [108.1066118, 11.2714711],
                [108.1065094, 11.2713042],
                [108.1064595, 11.2711997],
                [108.1063431, 11.2709991],
                [108.1062219, 11.2708313],
                [108.1061554, 11.2707518],
                [108.106007, 11.2706165],
                [108.1059241, 11.2705591],
                [108.1058365, 11.2705091],
                [108.1056678, 11.2704292],
                [108.1055956, 11.2703835],
                [108.1055289, 11.2703305],
                [108.1054685, 11.2702706],
                [108.1053737, 11.270141],
                [108.1053387, 11.2700737],
                [108.1052897, 11.2699307],
                [108.1052681, 11.2698301],
                [108.1052579, 11.2696636],
                [108.1052324, 11.2695331],
                [108.1051882, 11.2693837],
                [108.105116, 11.2691814],
                [108.1050335, 11.2690327],
                [108.1050064, 11.2689472],
                [108.105005, 11.2688487],
                [108.105017, 11.268796],
                [108.1050376, 11.268746],
                [108.1051024, 11.2686486],
                [108.1051373, 11.2685853],
                [108.1051653, 11.2685088],
                [108.1051998, 11.2683498],
                [108.1052059, 11.2682686],
                [108.1051952, 11.2681034],
                [108.1051784, 11.2680222],
                [108.1051223, 11.2678661],
                [108.1050487, 11.2677332],
                [108.1049994, 11.2676926],
                [108.104789, 11.2676335],
                [108.1039935, 11.2675163],
                [108.1031269, 11.2673639],
                [108.1027144, 11.2672374],
                [108.1019997, 11.2669724],
                [108.1018415, 11.2669229],
                [108.1013592, 11.2667972],
                [108.1012022, 11.2667428],
                [108.1009476, 11.2666329],
                [108.1005559, 11.2664279],
                [108.1003548, 11.2663436],
                [108.100144, 11.2662869],
                [108.1000362, 11.2662693],
                [108.0998566, 11.2662561],
                [108.0996444, 11.2662616],
                [108.0993784, 11.266293],
                [108.0991324, 11.2663501],
                [108.0988944, 11.2664337],
                [108.0987095, 11.2665202],
                [108.0986429, 11.2665611],
                [108.0985218, 11.2666588],
                [108.0983706, 11.2668109],
                [108.0981989, 11.2669404],
                [108.0980102, 11.2670448],
                [108.0979107, 11.2670868],
                [108.0977471, 11.2671389],
                [108.0976334, 11.267195],
                [108.0975374, 11.2672769],
                [108.0974979, 11.267326],
                [108.0974469, 11.2674175],
                [108.0973792, 11.2676163],
                [108.0973409, 11.2678047],
                [108.0973286, 11.2679409],
                [108.0973041, 11.2680182],
                [108.0972644, 11.268075],
                [108.0971987, 11.2681303],
                [108.0971487, 11.2681587],
                [108.0970394, 11.2681913],
                [108.0966148, 11.2681939],
                [108.0961891, 11.2681749],
                [108.0954814, 11.2681151],
                [108.095197, 11.2680673],
                [108.095055, 11.2680323],
                [108.0948491, 11.2679648],
                [108.0947432, 11.2679411],
                [108.0945278, 11.2679146],
                [108.0943107, 11.2679162],
                [108.094205, 11.2679272],
                [108.0939967, 11.2679692],
                [108.0938951, 11.268],
                [108.0935606, 11.2681272],
                [108.0933181, 11.2681959],
                [108.0931844, 11.2682239],
                [108.0929135, 11.2682605],
                [108.0923206, 11.2683179],
                [108.0920255, 11.2683581],
                [108.0909741, 11.268537],
                [108.0901158, 11.2686737],
                [108.0899994, 11.2686021],
                [108.0897544, 11.2684804],
                [108.089496, 11.2683889],
                [108.0893631, 11.2683549],
                [108.089092, 11.2683112],
                [108.088955, 11.2683016],
                [108.0888176, 11.2683002],
                [108.0884634, 11.2683132],
                [108.0881095, 11.2682949],
                [108.0879203, 11.2682723],
                [108.0877324, 11.2682407],
                [108.0876065, 11.2682008],
                [108.0875481, 11.2681702],
                [108.0874443, 11.2680898],
                [108.087401, 11.2680418],
                [108.0873329, 11.2679328],
                [108.0873091, 11.2678732],
                [108.0872925, 11.2678112],
                [108.0872849, 11.2674238],
                [108.0872528, 11.2672038],
                [108.0872239, 11.2669014],
                [108.0872047, 11.2668084],
                [108.0871783, 11.2667172],
                [108.0871045, 11.266542],
                [108.0870044, 11.2663799],
                [108.0869422, 11.2663015],
                [108.0868748, 11.2662272],
                [108.0867259, 11.2660925],
                [108.0865472, 11.2659705],
                [108.0864447, 11.2659159],
                [108.0862284, 11.2658311],
                [108.0861158, 11.2658013],
                [108.0860013, 11.2657802],
                [108.0858475, 11.2657658],
                [108.085695, 11.2657422],
                [108.0855442, 11.2657094],
                [108.0853958, 11.2656677],
                [108.0852503, 11.2656171],
                [108.0851072, 11.2655574],
                [108.0848281, 11.2654231],
                [108.084529, 11.2652512],
                [108.0844349, 11.265183],
                [108.0843906, 11.2651357],
                [108.0843692, 11.265087],
                [108.0843258, 11.2648322],
                [108.0842965, 11.2647334],
                [108.0842124, 11.2645452],
                [108.0840962, 11.264374],
                [108.0838852, 11.2641523],
                [108.0838227, 11.264073],
                [108.083716, 11.263902],
                [108.0836144, 11.2637073],
                [108.0835529, 11.263616],
                [108.0834103, 11.2634479],
                [108.0833299, 11.2633721],
                [108.0831313, 11.2632128],
                [108.0830041, 11.2630846],
                [108.0828566, 11.2629784],
                [108.0827207, 11.2628576],
                [108.0826109, 11.2627136],
                [108.0825671, 11.2626345],
                [108.0825312, 11.2625516],
                [108.0824643, 11.2623248],
                [108.0823975, 11.2621943],
                [108.0823545, 11.2621346],
                [108.0822515, 11.262029],
                [108.0820885, 11.2619032],
                [108.0820125, 11.2618339],
                [108.0818866, 11.2617004],
                [108.0818397, 11.261635],
                [108.0818, 11.261565],
                [108.0817442, 11.2614147],
                [108.0817219, 11.2612563],
                [108.0817339, 11.2610968],
                [108.0817798, 11.2609432],
                [108.081812, 11.2608694],
                [108.0819423, 11.2606297],
                [108.0819762, 11.2605398],
                [108.0820012, 11.2604472],
                [108.082017, 11.2603527],
                [108.0820236, 11.2602571],
                [108.0820129, 11.2600909],
                [108.0819962, 11.260022],
                [108.0819711, 11.2599555],
                [108.0818973, 11.259834],
                [108.0817959, 11.2597335],
                [108.0816251, 11.259626],
                [108.0815762, 11.2595833],
                [108.0814341, 11.2594062],
                [108.0813755, 11.2593496],
                [108.0812424, 11.2592549],
                [108.0810928, 11.259188],
                [108.0810143, 11.259166],
                [108.0807734, 11.259123],
                [108.0804491, 11.259088],
                [108.0801457, 11.2590828],
                [108.0798429, 11.2591038],
                [108.0797001, 11.2588567],
                [108.0795332, 11.2586244],
                [108.079344, 11.2584093],
                [108.0792038, 11.2582804],
                [108.0791262, 11.2582246],
                [108.0789585, 11.2581323],
                [108.0787779, 11.2580677],
                [108.0786017, 11.2580338],
                [108.0785185, 11.258027],
                [108.0783516, 11.2580305],
                [108.0783148, 11.2576563],
                [108.0782587, 11.2573961],
                [108.0781693, 11.2571449],
                [108.078048, 11.256907],
                [108.0779142, 11.2567083],
                [108.0777453, 11.2565095],
                [108.0773512, 11.2559735],
                [108.0771259, 11.2556472],
                [108.0769086, 11.2553157],
                [108.0767471, 11.2550515],
                [108.0766397, 11.2548529],
                [108.0766, 11.2547938],
                [108.0765019, 11.2546903],
                [108.0764448, 11.254647],
                [108.0763178, 11.2545803],
                [108.076179, 11.2545423],
                [108.0760904, 11.2545342],
                [108.0759164, 11.2544983],
                [108.0757499, 11.2544371],
                [108.0756351, 11.2543772],
                [108.075396, 11.254277],
                [108.0750552, 11.2541714],
                [108.0748495, 11.2540762],
                [108.0746596, 11.2539535],
                [108.0744892, 11.2538058],
                [108.0742709, 11.2535563],
                [108.0737218, 11.2528355],
                [108.0734914, 11.2525694],
                [108.0733532, 11.2524368],
                [108.0731924, 11.2523317],
                [108.0731052, 11.2522905],
                [108.0727657, 11.2521726],
                [108.0724823, 11.2520434],
                [108.0722353, 11.2519495],
                [108.0719787, 11.2518854],
                [108.0718275, 11.2518623],
                [108.0715281, 11.2518012],
                [108.0714242, 11.2517751],
                [108.0712236, 11.251701],
                [108.0710362, 11.251599],
                [108.0707727, 11.2514048],
                [108.0705802, 11.2512954],
                [108.0704789, 11.2512503],
                [108.0701444, 11.2511376],
                [108.0700329, 11.251091],
                [108.0698555, 11.2510011],
                [108.0696561, 11.2508788],
                [108.0694038, 11.2506963],
                [108.0693387, 11.2506434],
                [108.0692802, 11.2505835],
                [108.0692291, 11.2505173],
                [108.069186, 11.2504459],
                [108.069087, 11.250195],
                [108.0690414, 11.2501131],
                [108.0689877, 11.2500362],
                [108.0688864, 11.2499257],
                [108.0687572, 11.249818],
                [108.0686656, 11.249753],
                [108.0684704, 11.2496413],
                [108.0682619, 11.2495556],
                [108.0680767, 11.2495044],
                [108.0677479, 11.2494445],
                [108.0676551, 11.2494198],
                [108.0675652, 11.2493863],
                [108.0674792, 11.2493441],
                [108.0673979, 11.2492937],
                [108.0673221, 11.2492357],
                [108.067125, 11.2490348],
                [108.0670541, 11.2489739],
                [108.066979, 11.248918],
                [108.0667527, 11.2487716],
                [108.0665392, 11.2486076],
                [108.0662684, 11.2483807],
                [108.0659759, 11.2481814],
                [108.0658629, 11.2481151],
                [108.0656252, 11.2480042],
                [108.0655014, 11.24796],
                [108.0652798, 11.2479017],
                [108.065097, 11.2478339],
                [108.0649288, 11.247736],
                [108.0648519, 11.2476767],
                [108.0647153, 11.2475395],
                [108.0645481, 11.2472978],
                [108.0644219, 11.247073],
                [108.064189, 11.2465715],
                [108.0640769, 11.246382],
                [108.0639631, 11.2462336],
                [108.0638239, 11.2461077],
                [108.0637462, 11.2460545],
                [108.0635264, 11.2459355],
                [108.0633684, 11.2458029],
                [108.0632534, 11.2457439],
                [108.0631914, 11.2457251],
                [108.0630149, 11.2457005],
                [108.0629606, 11.2456845],
                [108.0628592, 11.2456349],
                [108.0627508, 11.2455652],
                [108.0626283, 11.2455234],
                [108.0624061, 11.2455058],
                [108.0622491, 11.2454735],
                [108.0621725, 11.2454465],
                [108.0620295, 11.2453707],
                [108.0619046, 11.2452685],
                [108.0617437, 11.2450749],
                [108.0616149, 11.2449611],
                [108.0614681, 11.2448707],
                [108.0611774, 11.2447555],
                [108.0610761, 11.2447051],
                [108.0608853, 11.2445845],
                [108.0607967, 11.2445148],
                [108.0606353, 11.2443582],
                [108.0604254, 11.2440949],
                [108.0603479, 11.2440132],
                [108.0601776, 11.2438655],
                [108.0599892, 11.2437408],
                [108.0596854, 11.2435953],
                [108.0593679, 11.2434814],
                [108.0590401, 11.2434004],
                [108.0587487, 11.2433572],
                [108.0586235, 11.2433467],
                [108.0584169, 11.2433333],
                [108.0583372, 11.2433174],
                [108.0582596, 11.2432934],
                [108.0581143, 11.2432222],
                [108.0579874, 11.2431227],
                [108.0579326, 11.2430637],
                [108.0578844, 11.2429994],
                [108.0578127, 11.2428643],
                [108.0577421, 11.2426576],
                [108.0577045, 11.2425172],
                [108.0576756, 11.2423747],
                [108.05763, 11.2419937],
                [108.0576074, 11.2418766],
                [108.0575784, 11.2417608],
                [108.0574596, 11.2413991],
                [108.0574303, 11.2412718],
                [108.0573971, 11.2410131],
                [108.0573934, 11.2408826],
                [108.057409, 11.2405525],
                [108.0574022, 11.2404526],
                [108.057364, 11.2402561],
                [108.0572942, 11.2400681],
                [108.057248, 11.2399788],
                [108.057161, 11.2398466],
                [108.0568909, 11.2394947],
                [108.0568084, 11.2393982],
                [108.0567217, 11.2393052],
                [108.0565532, 11.239145],
                [108.0564159, 11.2389862],
                [108.0563059, 11.2388081],
                [108.0562621, 11.2387132],
                [108.0561982, 11.2385146],
                [108.0561672, 11.2383086],
                [108.0561697, 11.2381004],
                [108.0561873, 11.2379774],
                [108.0561955, 11.2378534],
                [108.0561942, 11.2377291],
                [108.0561836, 11.2376053],
                [108.0561635, 11.2374826],
                [108.0561342, 11.2373618],
                [108.0560959, 11.2372433],
                [108.0560486, 11.2371281],
                [108.0558758, 11.2368051],
                [108.0557886, 11.2365803],
                [108.0557342, 11.2363549],
                [108.05571, 11.2361335],
                [108.0557084, 11.2360221],
                [108.0557777, 11.2360201],
                [108.055846, 11.236009],
                [108.0559122, 11.2359889],
                [108.0559751, 11.2359603],
                [108.0560334, 11.2359236],
                [108.0560862, 11.2358795],
                [108.0561324, 11.2358289],
                [108.0561712, 11.2357726],
                [108.0562227, 11.2356521],
                [108.0562604, 11.2354672],
                [108.056276, 11.2353426],
                [108.0562824, 11.2352171],
                [108.0562715, 11.234999],
                [108.0562481, 11.2348126],
                [108.0562594, 11.234656],
                [108.056305, 11.2345056],
                [108.0563401, 11.2344349],
                [108.0563828, 11.2343684],
                [108.0565136, 11.2342133],
                [108.0565677, 11.2341026],
                [108.0565834, 11.2340431],
                [108.056591, 11.2339206],
                [108.0565828, 11.2338596],
                [108.0565417, 11.2336939],
                [108.0565324, 11.2335237],
                [108.0565399, 11.2334387],
                [108.056591, 11.2331614],
                [108.0566075, 11.2328801],
                [108.0565891, 11.2325989],
                [108.0565669, 11.2324597],
                [108.0565362, 11.232322],
                [108.0564546, 11.2320666],
                [108.0563091, 11.2317143],
                [108.0561308, 11.231315],
                [108.0559829, 11.2310086],
                [108.0558083, 11.2306926],
                [108.055741, 11.2305007],
                [108.0557068, 11.2303006],
                [108.0557025, 11.2301992],
                [108.0557194, 11.2299969],
                [108.0557406, 11.2298976],
                [108.0558216, 11.2296653],
                [108.0558733, 11.2295535],
                [108.0559982, 11.2293409],
                [108.0560708, 11.2292409],
                [108.0562349, 11.2290556],
                [108.0564219, 11.2288926],
                [108.0566024, 11.228773],
                [108.0568001, 11.2286835],
                [108.05701, 11.2286264],
                [108.0571178, 11.2286105],
                [108.057427, 11.2285904],
                [108.0575315, 11.2285513],
                [108.0576194, 11.2284833],
                [108.0576825, 11.2283927],
                [108.0577001, 11.2283501],
                [108.0577181, 11.2282602],
                [108.0577632, 11.2281306],
                [108.0578176, 11.2280044],
                [108.0579839, 11.2276957],
                [108.0580243, 11.2275972],
                [108.0580815, 11.2273927],
                [108.0580981, 11.2272878],
                [108.0581066, 11.2270758],
                [108.0580823, 11.226865],
                [108.0579788, 11.2264962],
                [108.0579321, 11.2262235],
                [108.0579183, 11.2259474],
                [108.0579239, 11.2258092],
                [108.0579509, 11.2255847],
                [108.0580273, 11.2251389],
                [108.0581331, 11.2246989],
                [108.0582021, 11.2244653],
                [108.0582807, 11.2242346],
                [108.0583686, 11.2240072],
                [108.0585156, 11.2236773],
                [108.0585569, 11.2235676],
                [108.0585997, 11.2234097],
                [108.0586621, 11.2230886],
                [108.0586931, 11.2227631],
                [108.0586925, 11.2224363],
                [108.0586803, 11.2222732],
                [108.0586527, 11.2220626],
                [108.058616, 11.2218532],
                [108.0585702, 11.2216456],
                [108.0585156, 11.22144],
                [108.0583799, 11.2210367],
                [108.0582403, 11.2207083],
                [108.0579898, 11.2202118],
                [108.0579306, 11.2200222],
                [108.057913, 11.2199245],
                [108.0579036, 11.2198257],
                [108.0579085, 11.2196368],
                [108.0579353, 11.2194863],
                [108.057964, 11.219408],
                [108.0579804, 11.2192308],
                [108.0579794, 11.2191417],
                [108.0579574, 11.218957],
                [108.0579354, 11.2188625],
                [108.0578703, 11.2186794],
                [108.0577655, 11.2186538],
                [108.0576592, 11.2186354],
                [108.0573875, 11.218611],
                [108.0570261, 11.2185188],
                [108.056728, 11.218474],
                [108.056442, 11.2184612],
                [108.0563065, 11.2184654],
                [108.0557779, 11.2184588],
                [108.0547985, 11.2184034],
                [108.0547262, 11.2183928],
                [108.0545879, 11.218347],
                [108.0544642, 11.2182711],
                [108.05441, 11.218223],
                [108.0542987, 11.2180935],
                [108.0542171, 11.2180374],
                [108.0540732, 11.2179915],
                [108.0539982, 11.2179809],
                [108.0538469, 11.2179848],
                [108.0537752, 11.2179987],
                [108.0535658, 11.2180628],
                [108.0532944, 11.218169],
                [108.0529603, 11.2183277],
                [108.052683, 11.2184437],
                [108.0525725, 11.218478],
                [108.0524595, 11.2185034],
                [108.0523448, 11.2185195],
                [108.0522291, 11.2185264],
                [108.0521133, 11.2185239],
                [108.051998, 11.218512],
                [108.0518841, 11.2184909],
                [108.0517724, 11.2184607],
                [108.051578, 11.2183834],
                [108.0511647, 11.2181637],
                [108.0509217, 11.21802],
                [108.050682, 11.2178709],
                [108.0497627, 11.2172622],
                [108.0495982, 11.2171499],
                [108.0493684, 11.2169794],
                [108.0493054, 11.2169196],
                [108.0492492, 11.2168537],
                [108.0492004, 11.2167823],
                [108.0491596, 11.2167062],
                [108.0491271, 11.2166263],
                [108.0491034, 11.2165436],
                [108.0490702, 11.2162992],
                [108.0490496, 11.2162213],
                [108.0489939, 11.2160868],
                [108.0489167, 11.2159814],
                [108.0488151, 11.2158984],
                [108.048765, 11.2158708],
                [108.0486383, 11.2158245],
                [108.0484684, 11.215792],
                [108.0483328, 11.2157866],
                [108.0480481, 11.2158259],
                [108.0478866, 11.2158233],
                [108.0478066, 11.2158116],
                [108.0476485, 11.2157671],
                [108.0473186, 11.2156373],
                [108.047061, 11.2155485],
                [108.0468543, 11.2154843],
                [108.0464359, 11.2153727],
                [108.0464407, 11.215415],
                [108.0464404, 11.2154473],
                [108.046428, 11.2154775],
                [108.046278, 11.2156288],
                [108.0461996, 11.2157257],
                [108.0461287, 11.215828],
                [108.0460659, 11.2159353],
                [108.046024, 11.2160188],
                [108.0459907, 11.216106],
                [108.0459661, 11.216196],
                [108.0459462, 11.2163343],
                [108.0459194, 11.2164238],
                [108.0458701, 11.2165036],
                [108.0457601, 11.2166193],
                [108.0456889, 11.2167233],
                [108.0456464, 11.2168414],
                [108.0456367, 11.2169035],
                [108.0456392, 11.2169978],
                [108.0456673, 11.2170882],
                [108.0457116, 11.2171591],
                [108.0457876, 11.217237],
                [108.0459076, 11.2173192],
                [108.0459787, 11.2173511],
                [108.0460531, 11.2173747],
                [108.0462077, 11.2173957],
                [108.0464623, 11.2173717],
                [108.046597, 11.2173746],
                [108.0467264, 11.2173576],
                [108.0468046, 11.2173612],
                [108.0468759, 11.2173928],
                [108.0469694, 11.2174738],
                [108.0470798, 11.217542],
                [108.0472065, 11.2175909],
                [108.0473201, 11.2176126],
                [108.0473653, 11.2176277],
                [108.0474439, 11.217681],
                [108.0474985, 11.2177586],
                [108.0475193, 11.217828],
                [108.0475205, 11.2179536],
                [108.0474977, 11.2180505],
                [108.047494, 11.2181485],
                [108.0475043, 11.2181967],
                [108.0475473, 11.2182846],
                [108.0476403, 11.2183979],
                [108.0477362, 11.2184989],
                [108.0479274, 11.2187332],
                [108.0480868, 11.2188859],
                [108.0482594, 11.2190672],
                [108.0484047, 11.2192611],
                [108.0484508, 11.2193079],
                [108.0486241, 11.2194473],
                [108.0486511, 11.2194801],
                [108.0486891, 11.2195555],
                [108.048704, 11.219649],
                [108.0486903, 11.2197427],
                [108.0486193, 11.219887],
                [108.048563, 11.2200343],
                [108.0485127, 11.2201213],
                [108.0484774, 11.2201576],
                [108.0483913, 11.2202111],
                [108.0482923, 11.2202346],
                [108.0482411, 11.2202343],
                [108.048156, 11.2202148],
                [108.0480097, 11.2201987],
                [108.04795, 11.2202039],
                [108.0478918, 11.2202181],
                [108.0478366, 11.2202411],
                [108.0477858, 11.2202722],
                [108.0477405, 11.2203107],
                [108.0477019, 11.2203557],
                [108.047671, 11.2204061],
                [108.0476374, 11.2205026],
                [108.0476213, 11.2206318],
                [108.0476215, 11.2208052],
                [108.0476798, 11.2212362],
                [108.0477004, 11.2213478],
                [108.0477499, 11.2215233],
                [108.0477786, 11.2215862],
                [108.0478571, 11.2217001],
                [108.0480182, 11.2218483],
                [108.0480668, 11.2219049],
                [108.0481491, 11.222034],
                [108.0482064, 11.2221756],
                [108.048258, 11.2224363],
                [108.0483136, 11.2226179],
                [108.0483835, 11.2227745],
                [108.048539, 11.2230193],
                [108.048573, 11.2231319],
                [108.0485737, 11.2232493],
                [108.0485536, 11.2233313],
                [108.0484917, 11.2234884],
                [108.0483891, 11.2236684],
                [108.048369, 11.2237262],
                [108.0483376, 11.2238922],
                [108.0483145, 11.2239413],
                [108.0482831, 11.2239858],
                [108.0482442, 11.2240242],
                [108.0481992, 11.2240555],
                [108.0481494, 11.2240786],
                [108.0480962, 11.224093],
                [108.0480413, 11.2240981],
                [108.0479863, 11.2240938],
                [108.0478662, 11.22407],
                [108.0477438, 11.2240762],
                [108.0476269, 11.2241122],
                [108.0474824, 11.2241868],
                [108.047407, 11.2242175],
                [108.0472265, 11.2242702],
                [108.0470398, 11.2242949],
                [108.046885, 11.2242936],
                [108.0468246, 11.2242998],
                [108.0467658, 11.2243149],
                [108.0467101, 11.2243385],
                [108.0466586, 11.2243701],
                [108.0465762, 11.2244484],
                [108.0465159, 11.2245442],
                [108.0464816, 11.2246516],
                [108.0464408, 11.2251344],
                [108.0464412, 11.2253603],
                [108.0464501, 11.2254531],
                [108.0464864, 11.2256358],
                [108.046562, 11.2258745],
                [108.0465752, 11.2259519],
                [108.0465876, 11.2261785],
                [108.0466056, 11.2262507],
                [108.046633, 11.2263201],
                [108.0467401, 11.2264942],
                [108.0467659, 11.2265537],
                [108.0468277, 11.2267575],
                [108.0468678, 11.2268563],
                [108.0469451, 11.2270064],
                [108.0469912, 11.2270773],
                [108.0471271, 11.2272465],
                [108.0471688, 11.2272836],
                [108.047254, 11.227341],
                [108.0473205, 11.2274062],
                [108.0473595, 11.2274769],
                [108.047388, 11.2275759],
                [108.0474025, 11.227712],
                [108.0473989, 11.2277804],
                [108.0473582, 11.2279909],
                [108.047366, 11.2282322],
                [108.0473426, 11.2285085],
                [108.0473394, 11.2286859],
                [108.0472932, 11.2290397],
                [108.0472932, 11.2291772],
                [108.047301, 11.2292165],
                [108.0473341, 11.2292895],
                [108.0473587, 11.2293214],
                [108.0474204, 11.2293766],
                [108.0475735, 11.2294845],
                [108.0476618, 11.2295845],
                [108.0477221, 11.2296995],
                [108.04782, 11.2299396],
                [108.0478751, 11.2301442],
                [108.0479012, 11.2303542],
                [108.0478978, 11.2305658],
                [108.0478792, 11.2307786],
                [108.0478809, 11.2308855],
                [108.047909, 11.2312262],
                [108.0479218, 11.2312748],
                [108.0479662, 11.231365],
                [108.0481479, 11.2315684],
                [108.0482368, 11.2317137],
                [108.0482922, 11.2318557],
                [108.0483073, 11.2319214],
                [108.0483172, 11.2320555],
                [108.0483121, 11.2321227],
                [108.0482697, 11.2323255],
                [108.0482733, 11.2324653],
                [108.0482927, 11.2325921],
                [108.0482855, 11.2327201],
                [108.0482519, 11.2328441],
                [108.0482069, 11.2329368],
                [108.0480376, 11.2332052],
                [108.0477684, 11.2335711],
                [108.0476893, 11.2336619],
                [108.0475143, 11.233828],
                [108.0473223, 11.2339686],
                [108.0471994, 11.2340778],
                [108.0470985, 11.2342069],
                [108.0469864, 11.234389],
                [108.0469197, 11.2344729],
                [108.0467422, 11.2346602],
                [108.0466996, 11.2347222],
                [108.046641, 11.2348432],
                [108.0466164, 11.2349592],
                [108.0466211, 11.2350776],
                [108.0466489, 11.2351769],
                [108.0467473, 11.2353753],
                [108.0468559, 11.23552],
                [108.0469907, 11.2356415],
                [108.0471468, 11.2357354],
                [108.0472545, 11.235779],
                [108.0474626, 11.2358817],
                [108.0478254, 11.2361116],
                [108.0480667, 11.2362521],
                [108.0485415, 11.2365456],
                [108.0491902, 11.2369771],
                [108.0494516, 11.2371752],
                [108.0495643, 11.2372752],
                [108.0496704, 11.2373819],
                [108.0497695, 11.2374949],
                [108.0499073, 11.2376799],
                [108.0500148, 11.2378005],
                [108.0501401, 11.2379031],
                [108.0502816, 11.2380021],
                [108.050403, 11.2381241],
                [108.0505164, 11.2382871],
                [108.0505646, 11.2383738],
                [108.0506852, 11.2386389],
                [108.0507362, 11.2387207],
                [108.0509038, 11.2389312],
                [108.0510185, 11.2391397],
                [108.0511156, 11.2392573],
                [108.0511727, 11.2393082],
                [108.0513012, 11.2393919],
                [108.0513713, 11.2394237],
                [108.0514987, 11.2394618],
                [108.0517446, 11.239562],
                [108.0518621, 11.2396237],
                [108.0522437, 11.2398576],
                [108.0525423, 11.2400129],
                [108.0527499, 11.2400998],
                [108.0530379, 11.2401945],
                [108.0531104, 11.2402292],
                [108.0531784, 11.2402719],
                [108.0532409, 11.2403219],
                [108.0532971, 11.2403787],
                [108.0533476, 11.2404434],
                [108.0533906, 11.2405132],
                [108.0534253, 11.2405872],
                [108.0534515, 11.2406646],
                [108.0534688, 11.2407444],
                [108.0534775, 11.2408445],
                [108.0534746, 11.2410454],
                [108.0534629, 11.2411453],
                [108.0534189, 11.2413443],
                [108.053384, 11.2414418],
                [108.0533402, 11.2415357],
                [108.0532878, 11.2416253],
                [108.0531787, 11.2417676],
                [108.0531036, 11.2418977],
                [108.0530781, 11.2419683],
                [108.0530389, 11.242176],
                [108.0530182, 11.2422413],
                [108.0529637, 11.242348],
                [108.052925, 11.2423944],
                [108.0528292, 11.2424681],
                [108.0527182, 11.2425107],
                [108.0526022, 11.2425225],
                [108.052544, 11.2425171],
                [108.0524555, 11.2424938],
                [108.0523892, 11.2424934],
                [108.0523288, 11.2425201],
                [108.0522786, 11.242576],
                [108.0522519, 11.242642],
                [108.0522484, 11.2427095],
                [108.0522081, 11.2429226],
                [108.0521953, 11.2430652],
                [108.0521947, 11.2433514],
                [108.052208, 11.243503],
                [108.0522537, 11.2438039],
                [108.0523219, 11.244085],
                [108.052458, 11.2444797],
                [108.0525812, 11.2447714],
                [108.0527311, 11.2450508],
                [108.0529205, 11.2453349],
                [108.0530257, 11.2454698],
                [108.0531402, 11.2455994],
                [108.053049, 11.2456257],
                [108.0529566, 11.2456654],
                [108.0527818, 11.2457644],
                [108.0526239, 11.2458874],
                [108.0524468, 11.2460543],
                [108.0523521, 11.246131],
                [108.0519181, 11.2464357],
                [108.0518204, 11.2465385],
                [108.051747, 11.2466592],
                [108.0517205, 11.2467246],
                [108.0517012, 11.2467924],
                [108.0516839, 11.2469379],
                [108.0516955, 11.247084],
                [108.051712, 11.2471555],
                [108.0517354, 11.2472251],
                [108.0518025, 11.2473765],
                [108.0518075, 11.2474682],
                [108.0517869, 11.2475419],
                [108.0517403, 11.2476214],
                [108.0517012, 11.247669],
                [108.0516057, 11.2477471],
                [108.051551, 11.2477763],
                [108.0514398, 11.2478111],
                [108.0513233, 11.2478181],
                [108.0512085, 11.2477971],
                [108.051146, 11.2477684],
                [108.0510357, 11.2476647],
                [108.0509373, 11.2475358],
                [108.0508726, 11.2474175],
                [108.0508408, 11.2473738],
                [108.0508019, 11.2473359],
                [108.0507572, 11.2473049],
                [108.0506552, 11.2472669],
                [108.0506007, 11.2472609],
                [108.050546, 11.2472639],
                [108.0504926, 11.2472758],
                [108.0503956, 11.2473249],
                [108.0503547, 11.2473606],
                [108.0503064, 11.2474251],
                [108.0502509, 11.2474837],
                [108.0501889, 11.2475357],
                [108.0501213, 11.2475804],
                [108.0500489, 11.2476173],
                [108.0499727, 11.2476458],
                [108.0498937, 11.2476655],
                [108.0496851, 11.2476892],
                [108.0495658, 11.247736],
                [108.0494642, 11.2478131],
                [108.0494286, 11.2478534],
                [108.049345, 11.2479845],
                [108.0493129, 11.2480756],
                [108.0493098, 11.2481239],
                [108.0493301, 11.2482182],
                [108.0493758, 11.2482887],
                [108.0494457, 11.2483363],
                [108.0494864, 11.248349],
                [108.0495715, 11.2483498],
                [108.0496564, 11.2483331],
                [108.0497417, 11.2483479],
                [108.0498156, 11.2483921],
                [108.0498664, 11.2484563],
                [108.0499108, 11.2485644],
                [108.0499321, 11.2486391],
                [108.0499503, 11.2487931],
                [108.0499391, 11.2489286],
                [108.0498976, 11.2490992],
                [108.0498143, 11.2493181],
                [108.049699, 11.2495226],
                [108.0496302, 11.249618],
                [108.0494481, 11.2498387],
                [108.0493297, 11.2500017],
                [108.0492045, 11.2501596],
                [108.0490073, 11.2503833],
                [108.0488941, 11.2505388],
                [108.0488061, 11.2507094],
                [108.0487722, 11.2507991],
                [108.0487252, 11.2509879],
                [108.0486605, 11.2511751],
                [108.048565, 11.2513491],
                [108.0484413, 11.2515051],
                [108.0480695, 11.251838],
                [108.0478731, 11.2520539],
                [108.0477845, 11.2521696],
                [108.0476276, 11.2524145],
                [108.0475004, 11.2526731],
                [108.0474036, 11.2529418],
                [108.0473667, 11.2530797],
                [108.0474307, 11.2531664],
                [108.0475373, 11.2533529],
                [108.0476135, 11.2535532],
                [108.0476539, 11.2537477],
                [108.0476593, 11.2539461],
                [108.0476488, 11.254045],
                [108.0475891, 11.2543029],
                [108.0475491, 11.254595],
                [108.0474765, 11.2548703],
                [108.0473675, 11.2551676],
                [108.0472952, 11.2553264],
                [108.0472144, 11.2554811],
                [108.0471253, 11.2556314],
                [108.0469145, 11.2557117],
                [108.0467215, 11.2558191],
                [108.0466336, 11.2558823],
                [108.0464316, 11.2560574],
                [108.0462778, 11.256152],
                [108.0462137, 11.2562284],
                [108.0461919, 11.2562733],
                [108.0461607, 11.2564303],
                [108.0461536, 11.2565473],
                [108.046143, 11.2565831],
                [108.0461234, 11.256615],
                [108.0460961, 11.256641],
                [108.0460629, 11.2566591],
                [108.0460261, 11.2566683],
                [108.0459881, 11.2566679],
                [108.0459601, 11.2566612],
                [108.0458836, 11.2566228],
                [108.0458058, 11.2565576],
                [108.0457268, 11.256515],
                [108.0456374, 11.2565035],
                [108.0455499, 11.2565247],
                [108.0454802, 11.2565719],
                [108.0454337, 11.2566347],
                [108.0452616, 11.2567929],
                [108.0449962, 11.2569778],
                [108.0448327, 11.2571131],
                [108.0446796, 11.2572685],
                [108.0446094, 11.2573518],
                [108.0446623, 11.2574631],
                [108.0446765, 11.257523],
                [108.0446788, 11.2576457],
                [108.0446466, 11.2577643],
                [108.0446182, 11.2578192],
                [108.0445823, 11.2578697],
                [108.0444987, 11.2579482],
                [108.0443634, 11.2580311],
                [108.0442683, 11.2580777],
                [108.0441694, 11.2581162],
                [108.0438821, 11.258193],
                [108.0437066, 11.2582683],
                [108.0436241, 11.2583161],
                [108.0435458, 11.2583702],
                [108.0434281, 11.2584715],
                [108.0432077, 11.2587104],
                [108.0430864, 11.2587904],
                [108.042949, 11.2588394],
                [108.0427218, 11.2588649],
                [108.042646, 11.2588828],
                [108.0425186, 11.258935],
                [108.0424097, 11.2590183],
                [108.042327, 11.2591267],
                [108.0422974, 11.259188],
                [108.0422774, 11.2592484],
                [108.0422419, 11.2594355],
                [108.0422101, 11.2597215],
                [108.0421584, 11.2598742],
                [108.0420443, 11.2600721],
                [108.0420167, 11.2601405],
                [108.0419836, 11.2602837],
                [108.0419812, 11.2604305],
                [108.0420089, 11.2605725],
                [108.0420631, 11.2607046],
                [108.0420996, 11.2607662],
                [108.0422642, 11.2609707],
                [108.042315, 11.2610513],
                [108.0423939, 11.2612241],
                [108.042442, 11.2614118],
                [108.0424579, 11.2616047],
                [108.0424536, 11.2617014],
                [108.0424412, 11.2617975],
                [108.0423709, 11.2620851],
                [108.0423564, 11.2622836],
                [108.0423627, 11.262383],
                [108.0423979, 11.2625648],
                [108.0424244, 11.2626463],
                [108.0424958, 11.2628024],
                [108.0425333, 11.2629032],
                [108.0425865, 11.2631113],
                [108.0426019, 11.2632175],
                [108.0426103, 11.2634319],
                [108.0425802, 11.2637553],
                [108.0425791, 11.2638641],
                [108.042585, 11.2639728],
                [108.0426107, 11.2641555],
                [108.0426148, 11.264231],
                [108.0425963, 11.2643811],
                [108.0425432, 11.2645229],
                [108.0424583, 11.264649],
                [108.0423464, 11.2647729],
                [108.0422979, 11.2648406],
                [108.0422546, 11.2649117],
                [108.0421877, 11.2650551],
                [108.0421552, 11.2652014],
                [108.0421587, 11.2653511],
                [108.0421978, 11.2654959],
                [108.0422302, 11.2655639],
                [108.0422994, 11.2656647],
                [108.0424419, 11.2658198],
                [108.0424834, 11.2658753],
                [108.0425178, 11.2659353],
                [108.0425633, 11.2660652],
                [108.0425738, 11.2661333],
                [108.0425696, 11.2662706],
                [108.0425548, 11.2663378],
                [108.0424808, 11.2665469],
                [108.042455, 11.2666547],
                [108.0424278, 11.2668527],
                [108.0424344, 11.2670308],
                [108.0424736, 11.2672048],
                [108.0425051, 11.2672885],
                [108.0425735, 11.2674195],
                [108.0426721, 11.2675648],
                [108.0428208, 11.2677529],
                [108.0430599, 11.2680329],
                [108.0434136, 11.2684953],
                [108.0435027, 11.2686551],
                [108.0435646, 11.2688268],
                [108.0435848, 11.2689157],
                [108.0436277, 11.2692261],
                [108.0436862, 11.2695345],
                [108.0437136, 11.2698469],
                [108.0437154, 11.2700121],
                [108.0436938, 11.2703418],
                [108.0436703, 11.2705054],
                [108.0435967, 11.2708685],
                [108.043585, 11.2709704],
                [108.0435791, 11.2711333],
                [108.0435858, 11.2711934],
                [108.0436199, 11.2713096],
                [108.04368, 11.2714151],
                [108.0438444, 11.2715892],
                [108.0439289, 11.2717194],
                [108.0439537, 11.2717806],
                [108.043977, 11.2719101],
                [108.0439641, 11.2720409],
                [108.0439442, 11.2721039],
                [108.0439199, 11.2721564],
                [108.0438004, 11.2723361],
                [108.0437548, 11.2724247],
                [108.0436856, 11.2726113],
                [108.0436472, 11.2728061],
                [108.0436405, 11.2730045],
                [108.0436492, 11.2731035],
                [108.0436736, 11.2732362],
                [108.0437423, 11.2734973],
                [108.0438369, 11.2737506],
                [108.0439653, 11.2740071],
                [108.0441363, 11.2742833],
                [108.0442014, 11.2744518],
                [108.0442339, 11.2746291],
                [108.0442529, 11.2749261],
                [108.0442684, 11.2750063],
                [108.0443206, 11.2751612],
                [108.0443997, 11.2753048],
                [108.044504, 11.2754336],
                [108.0446309, 11.2755431],
                [108.0447757, 11.2756287],
                [108.0447489, 11.2761022],
                [108.0447596, 11.2765441],
                [108.0446843, 11.2767295],
                [108.0445778, 11.2768995],
                [108.0445137, 11.2769772],
                [108.0442782, 11.2771988],
                [108.0442035, 11.2772812],
                [108.0441343, 11.2773681],
                [108.044014, 11.2775542],
                [108.0437362, 11.2781504],
                [108.0435998, 11.2785127],
                [108.0434964, 11.2788568],
                [108.0428873, 11.2790463],
                [108.042587, 11.2791535],
                [108.0420061, 11.2793879],
                [108.0414391, 11.2796532],
                [108.0412534, 11.279999],
                [108.0411113, 11.2802409],
                [108.0410028, 11.28037],
                [108.0408695, 11.2804745],
                [108.0407953, 11.280516],
                [108.0405773, 11.2805981],
                [108.0403509, 11.2806545],
                [108.0402356, 11.2806727],
                [108.0398897, 11.2807022],
                [108.0396651, 11.2807526],
                [108.0394563, 11.2808314],
                [108.0392682, 11.2809375],
                [108.0390993, 11.281071],
                [108.0390234, 11.2811471],
                [108.038891, 11.2813156],
                [108.0387272, 11.2815785],
                [108.0385353, 11.2818224],
                [108.0384296, 11.2819364],
                [108.0382009, 11.282146],
                [108.0378176, 11.282529],
                [108.0376864, 11.2827162],
                [108.037583, 11.2829195],
                [108.0375091, 11.2831348],
                [108.0374838, 11.2832457],
                [108.037454, 11.2834678],
                [108.037385, 11.2837973],
                [108.0372167, 11.2843653],
                [108.0371694, 11.2845624],
                [108.0370992, 11.2849613],
                [108.0370624, 11.2853562],
                [108.0370024, 11.2858838],
                [108.0369778, 11.286016],
                [108.036904, 11.2862746],
                [108.0367985, 11.2865224],
                [108.0367343, 11.286641],
                [108.0365843, 11.2868657],
                [108.0364658, 11.2870084],
                [108.0362471, 11.2873075],
                [108.0360539, 11.287623],
                [108.0358006, 11.2881172],
                [108.0357071, 11.2882774],
                [108.0355006, 11.2885859],
                [108.0352715, 11.2889001],
                [108.0350708, 11.2892323],
                [108.0348998, 11.2895802],
                [108.0346319, 11.2902426],
                [108.0344032, 11.2907487],
                [108.0342582, 11.2911168],
                [108.034197, 11.2913048],
                [108.0341044, 11.2916565],
                [108.034074, 11.2918197],
                [108.0340393, 11.2921485],
                [108.0340393, 11.2924777],
                [108.0340522, 11.2926419],
                [108.0336553, 11.293089],
                [108.0335064, 11.2932701],
                [108.0332208, 11.2936414],
                [108.0332247, 11.2937685],
                [108.0332189, 11.2938955],
                [108.0332032, 11.2940217],
                [108.0331778, 11.2941464],
                [108.0331329, 11.2942985],
                [108.0330971, 11.294453],
                [108.0330706, 11.2946093],
                [108.0330534, 11.2947677],
                [108.0329797, 11.2951861],
                [108.0329675, 11.2953183],
                [108.0329643, 11.295523],
                [108.0329567, 11.2955947],
                [108.0329164, 11.295733],
                [108.0328444, 11.2958583],
                [108.0327978, 11.295914],
                [108.0326909, 11.2960044],
                [108.0325713, 11.2960671],
                [108.032507, 11.2960886],
                [108.032265, 11.2962052],
                [108.0319026, 11.2963618],
                [108.0316804, 11.2964798],
                [108.0315757, 11.2965493],
                [108.0313814, 11.2967077],
                [108.0312099, 11.2968898],
                [108.0311337, 11.2969887],
                [108.0310099, 11.2971853],
                [108.0308657, 11.2974743],
                [108.0307785, 11.2976709],
                [108.0306306, 11.2980683],
                [108.0305698, 11.2982683],
                [108.0305117, 11.2985073],
                [108.0303401, 11.2990597],
                [108.0304532, 11.2993473],
                [108.0306715, 11.2997617],
                [108.0307882, 11.3000523],
                [108.0308339, 11.3002019],
                [108.0309392, 11.300685],
                [108.0309838, 11.3008482],
                [108.0310917, 11.3011627],
                [108.0311999, 11.3014361],
                [108.0312663, 11.3016825],
                [108.0313017, 11.3019349],
                [108.0313076, 11.3020622],
                [108.0312984, 11.3022912],
                [108.0312821, 11.3023918],
                [108.0312567, 11.3024906],
                [108.0312224, 11.3025867],
                [108.0311796, 11.3026795],
                [108.0310956, 11.3028173],
                [108.0310544, 11.3029273],
                [108.0310472, 11.3029857],
                [108.0310492, 11.3030444],
                [108.0310603, 11.3031021],
                [108.0310804, 11.3031575],
                [108.0311385, 11.3032487],
                [108.0312203, 11.3033203],
                [108.0312681, 11.3033469],
                [108.0314773, 11.3034337],
                [108.0318502, 11.3035626],
                [108.0319719, 11.3036119],
                [108.0321124, 11.303692],
                [108.032232, 11.3038],
                [108.0323249, 11.3039308],
                [108.0323549, 11.3039913],
                [108.0324257, 11.3041806],
                [108.032483, 11.304385],
                [108.0324937, 11.304462],
                [108.0324906, 11.3046171],
                [108.0324553, 11.3047683],
                [108.0323893, 11.3049092],
                [108.0322444, 11.3051196],
                [108.0320901, 11.3053694],
                [108.0319008, 11.3056261],
                [108.0318214, 11.3057781],
                [108.031791, 11.3058581],
                [108.031767, 11.3059403],
                [108.0317395, 11.3061009],
                [108.031708, 11.3068976],
                [108.0314827, 11.3071343],
                [108.0312361, 11.3074285],
                [108.0309989, 11.3076853],
                [108.0308019, 11.3079305],
                [108.0306332, 11.3081951],
                [108.0305097, 11.3084413],
                [108.0303908, 11.3086472],
                [108.0302489, 11.3088387],
                [108.0300481, 11.3090746],
                [108.0298744, 11.3093303],
                [108.0297983, 11.3094646],
                [108.0295943, 11.3098677],
                [108.0294802, 11.310063],
                [108.0292755, 11.3103745],
                [108.0292007, 11.3105003],
                [108.0291343, 11.3106306],
                [108.0290766, 11.3107648],
                [108.0290278, 11.3109024],
                [108.0289254, 11.3112996],
                [108.0288834, 11.3114251],
                [108.028779, 11.3116687],
                [108.0286533, 11.3119217],
                [108.0286012, 11.312053],
                [108.0285193, 11.3123228],
                [108.0284897, 11.3124606],
                [108.028438, 11.3128179],
                [108.0284133, 11.3129253],
                [108.0282748, 11.3133414],
                [108.0282012, 11.313665],
                [108.0281753, 11.3138289],
                [108.0281469, 11.3141312],
                [108.0281424, 11.3144068],
                [108.0281192, 11.3148195],
                [108.0280191, 11.3162307],
                [108.0279898, 11.3164882],
                [108.0279201, 11.3169376],
                [108.0278811, 11.3173268],
                [108.027875, 11.3175223],
                [108.0278869, 11.3180406],
                [108.0278623, 11.3184253],
                [108.0278778, 11.3186489],
                [108.0279209, 11.3188509],
                [108.0279955, 11.3190439],
                [108.028044, 11.3191356],
                [108.0281621, 11.3193064],
                [108.02823, 11.3193835],
                [108.0283034, 11.3194555],
                [108.0285258, 11.3196374],
                [108.0285894, 11.3197039],
                [108.0286977, 11.319852],
                [108.0287415, 11.3199324],
                [108.0287848, 11.3200347],
                [108.0288191, 11.3201403],
                [108.0288439, 11.3202484],
                [108.0288592, 11.3203582],
                [108.0288649, 11.3204689],
                [108.0288625, 11.3205558],
                [108.0288043, 11.321138],
                [108.0288052, 11.3212235],
                [108.0288138, 11.3213086],
                [108.0288541, 11.3214748],
                [108.0289239, 11.3216314],
                [108.0289853, 11.3217274],
                [108.0291243, 11.3219086],
                [108.0292833, 11.3220732],
                [108.0296266, 11.322352],
                [108.0302376, 11.3228043],
                [108.0302905, 11.3228531],
                [108.0303762, 11.3229675],
                [108.0304313, 11.3230989],
                [108.0304501, 11.3232004],
                [108.0304662, 11.323406],
                [108.0304389, 11.3237929],
                [108.0304378, 11.3242359],
                [108.0304254, 11.3243859],
                [108.0303926, 11.3245997],
                [108.0303929, 11.3247312],
                [108.0304251, 11.3248588],
                [108.0305144, 11.325029],
                [108.0305547, 11.3251353],
                [108.0305897, 11.3252833],
                [108.0306113, 11.3254846],
                [108.0306029, 11.3256868],
                [108.0305646, 11.3258866],
                [108.0305344, 11.3259839],
                [108.0304527, 11.3261707],
                [108.0297309, 11.3273892],
                [108.0020254, 11.3327875],
                [108.0019669, 11.3328927],
                [108.0018747, 11.3331146],
                [108.0018148, 11.3333741],
                [108.0017826, 11.333574],
                [108.0016921, 11.3338492],
                [108.0016565, 11.333979],
                [108.0016229, 11.3340686],
                [108.0014897, 11.3343328],
                [108.0014278, 11.3344763],
                [108.0014016, 11.3345836],
                [108.0014011, 11.3347005],
                [108.0014115, 11.3347582],
                [108.0014706, 11.3349299],
                [108.0014794, 11.3349937],
                [108.0014788, 11.3350581],
                [108.001469, 11.3351218],
                [108.00145, 11.3351835],
                [108.001391, 11.3353124],
                [108.0013825, 11.3353892],
                [108.0013894, 11.3354274],
                [108.001424, 11.3354969],
                [108.0014822, 11.335549],
                [108.0015208, 11.3355685],
                [108.0016447, 11.3355982],
                [108.0016809, 11.3356174],
                [108.001739, 11.335674],
                [108.0017589, 11.3357093],
                [108.001777, 11.3357876],
                [108.0017649, 11.3358649],
                [108.001726, 11.3359307],
                [108.0016656, 11.3359785],
                [108.001357, 11.3361218],
                [108.0011907, 11.336177],
                [108.0011013, 11.336196],
                [108.0010309, 11.3362219],
                [108.0009645, 11.3362565],
                [108.0008815, 11.3362662],
                [108.0008009, 11.3362441],
                [108.000735, 11.3361936],
                [108.0007107, 11.33616],
                [108.0006735, 11.3360705],
                [108.0006453, 11.3360224],
                [108.0005868, 11.3359578],
                [108.0005519, 11.3359314],
                [108.0004787, 11.3358981],
                [108.0004051, 11.3358969],
                [108.0003696, 11.3359071],
                [108.0003081, 11.3359467],
                [108.0002671, 11.3360066],
                [108.0002262, 11.3361142],
                [108.0001952, 11.3362742],
                [108.000149, 11.3363593],
                [108.0000457, 11.3364615],
                [107.9999882, 11.3365379],
                [107.999921, 11.3365924],
                [107.9998383, 11.3366193],
                [107.9997946, 11.3366211],
                [107.9997512, 11.336615],
                [107.9996348, 11.3365734],
                [107.9995975, 11.3365701],
                [107.9995227, 11.3365678],
                [107.9993745, 11.3365863],
                [107.9993026, 11.3366069],
                [107.9991674, 11.3366694],
                [107.9990861, 11.3367257],
                [107.9990107, 11.3367895],
                [107.9989419, 11.3368601],
                [107.9988803, 11.3369368],
                [107.9988266, 11.337019],
                [107.9987176, 11.337237],
                [107.9986753, 11.3372966],
                [107.9986265, 11.3373513],
                [107.9985346, 11.3374286],
                [107.9984762, 11.3375006],
                [107.9984447, 11.3375873],
                [107.9984401, 11.3376332],
                [107.998454, 11.3377566],
                [107.9984388, 11.3378332],
                [107.9983968, 11.3379035],
                [107.9983309, 11.3379588],
                [107.9982501, 11.3379896],
                [107.9982029, 11.3379947],
                [107.9981095, 11.3379795],
                [107.9979685, 11.3379762],
                [107.9975278, 11.3379983],
                [107.9973707, 11.3379727],
                [107.9971811, 11.3379067],
                [107.9971206, 11.3378994],
                [107.9969998, 11.3379119],
                [107.9968883, 11.3379593],
                [107.9968393, 11.3379948],
                [107.9967666, 11.3380759],
                [107.9966303, 11.3382051],
                [107.996445, 11.3383523],
                [107.9961848, 11.3385313],
                [107.9959796, 11.3386475],
                [107.9957614, 11.3387381],
                [107.9955187, 11.3388149],
                [107.9954022, 11.3388845],
                [107.9953509, 11.3389287],
                [107.995269, 11.3390273],
                [107.9952175, 11.3391365],
                [107.9951959, 11.339255],
                [107.9951968, 11.3393153],
                [107.9952221, 11.339433],
                [107.9952815, 11.339547],
                [107.9953269, 11.3396751],
                [107.995336, 11.3397425],
                [107.9953359, 11.3398105],
                [107.9953265, 11.3398778],
                [107.995303, 11.3399567],
                [107.9952724, 11.3400332],
                [107.9951911, 11.3401767],
                [107.995141, 11.3402426],
                [107.9949718, 11.3404206],
                [107.9949262, 11.3404878],
                [107.9948887, 11.3405596],
                [107.9948169, 11.3407772],
                [107.9947818, 11.3408555],
                [107.9946902, 11.3410007],
                [107.9946345, 11.3410665],
                [107.9941056, 11.341065],
                [107.9938352, 11.3410875],
                [107.9936884, 11.34111],
                [107.9935434, 11.3411416],
                [107.9934007, 11.3411822],
                [107.9929744, 11.3413271],
                [107.9928465, 11.3413389],
                [107.9927201, 11.3413164],
                [107.9926156, 11.3412682],
                [107.9925415, 11.3411993],
                [107.9924948, 11.3411102],
                [107.9924699, 11.34096],
                [107.9924518, 11.340912],
                [107.9923948, 11.3408266],
                [107.9923572, 11.3407911],
                [107.9923146, 11.3407615],
                [107.9922183, 11.3407226],
                [107.9920487, 11.3407049],
                [107.991992, 11.3406877],
                [107.9919383, 11.3406626],
                [107.9918451, 11.3405909],
                [107.9918075, 11.3405458],
                [107.9917541, 11.3404417],
                [107.9916319, 11.3402459],
                [107.991491, 11.3400672],
                [107.9912388, 11.3402407],
                [107.991098, 11.3403253],
                [107.9908053, 11.3404754],
                [107.9905708, 11.3405731],
                [107.9904844, 11.340597],
                [107.9903961, 11.340612],
                [107.9903065, 11.3406179],
                [107.9902169, 11.3406147],
                [107.9901281, 11.3406024],
                [107.990041, 11.3405811],
                [107.9899567, 11.3405511],
                [107.9897255, 11.3404288],
                [107.9895369, 11.3403127],
                [107.9894561, 11.3402709],
                [107.9892842, 11.3402105],
                [107.9891947, 11.3401924],
                [107.9890125, 11.3401812],
                [107.9888463, 11.3402003],
                [107.9886942, 11.3402],
                [107.9886191, 11.3401876],
                [107.9884123, 11.3401233],
                [107.9883424, 11.3401137],
                [107.9882016, 11.3401199],
                [107.9880833, 11.3401525],
                [107.9879794, 11.3401651],
                [107.987876, 11.3401489],
                [107.9877113, 11.3400744],
                [107.9876486, 11.3400589],
                [107.9875196, 11.3400544],
                [107.9873945, 11.3400853],
                [107.987283, 11.3401491],
                [107.9872352, 11.3401918],
                [107.9871941, 11.3402407],
                [107.9871194, 11.3403446],
                [107.9870202, 11.3404264],
                [107.9869634, 11.3404572],
                [107.9868401, 11.3404962],
                [107.9866143, 11.3405083],
                [107.9865351, 11.3405237],
                [107.9863841, 11.3405796],
                [107.986258, 11.3406597],
                [107.9860081, 11.3408725],
                [107.9858083, 11.3410108],
                [107.9857012, 11.3410688],
                [107.9854044, 11.3411966],
                [107.9851407, 11.3413503],
                [107.9849495, 11.3414251],
                [107.9847584, 11.3414662],
                [107.984666, 11.3414752],
                [107.9845268, 11.3414761],
                [107.9843999, 11.3414605],
                [107.9843207, 11.3414413],
                [107.9841695, 11.3413817],
                [107.9777473, 11.3434228],
                [107.9775706, 11.3434689],
                [107.9774094, 11.3435017],
                [107.9770429, 11.3435657],
                [107.9767448, 11.3436051],
                [107.9761467, 11.3436684],
                [107.9755889, 11.3437088],
                [107.9752882, 11.3437487],
                [107.9750332, 11.3438002],
                [107.9744885, 11.3439814],
                [107.9739566, 11.344196],
                [107.9734397, 11.3444432],
                [107.9729995, 11.3446865],
                [107.9725738, 11.3449533],
                [107.9723668, 11.3450954],
                [107.9699148, 11.3465635],
                [107.9694963, 11.3468033],
                [107.9690732, 11.3470351],
                [107.9686455, 11.3472587],
                [107.9682134, 11.347474],
                [107.9677771, 11.347681],
                [107.9673367, 11.3478796],
                [107.9668924, 11.3480697],
                [107.9660463, 11.3484041],
                [107.9652595, 11.3487328],
                [107.9644865, 11.3490915],
                [107.9635693, 11.3495516],
                [107.9630179, 11.3497903],
                [107.9624533, 11.3499973],
                [107.9621627, 11.3500899],
                [107.9615736, 11.3502498],
                [107.9612578, 11.3503412],
                [107.9609449, 11.3504419],
                [107.9606352, 11.3505517],
                [107.9599078, 11.3508359],
                [107.9596922, 11.3509053],
                [107.9594737, 11.3509656],
                [107.9592529, 11.3510167],
                [107.95903, 11.3510587],
                [107.9588055, 11.3510913],
                [107.958299, 11.3511498],
                [107.9580473, 11.3511904],
                [107.9576408, 11.3512726],
                [107.9570306, 11.3514231],
                [107.956737, 11.3514727],
                [107.9564394, 11.3514886],
                [107.956142, 11.3514706],
                [107.9559946, 11.3514489],
                [107.9556807, 11.3513883],
                [107.9553771, 11.3513472],
                [107.955233, 11.3513209],
                [107.9550909, 11.3512858],
                [107.9548147, 11.3511903],
                [107.9545526, 11.3510619],
                [107.9543285, 11.3509173],
                [107.9540424, 11.3506941],
                [107.9538582, 11.3505378],
                [107.9536801, 11.3503747],
                [107.9533431, 11.3500296],
                [107.9531847, 11.349848],
                [107.9527546, 11.3493041],
                [107.9526044, 11.3491344],
                [107.9524471, 11.3489709],
                [107.9522833, 11.3488138],
                [107.952113, 11.3486634],
                [107.9519366, 11.3485199],
                [107.9517544, 11.3483836],
                [107.9515666, 11.3482547],
                [107.9513737, 11.3481335],
                [107.9511759, 11.34802],
                [107.9509735, 11.3479145],
                [107.9503793, 11.3476496],
                [107.9500774, 11.347528],
                [107.949465, 11.3473067],
                [107.9491548, 11.3472073],
                [107.9488421, 11.3471153],
                [107.948347, 11.3469862],
                [107.9478033, 11.3468638],
                [107.9470734, 11.3467235],
                [107.9467066, 11.3466635],
                [107.9461206, 11.3465818],
                [107.9459016, 11.3465625],
                [107.9456819, 11.3465523],
                [107.945462, 11.3465511],
                [107.9452423, 11.346559],
                [107.9450231, 11.346576],
                [107.9448047, 11.346602],
                [107.9445878, 11.3466369],
                [107.9443725, 11.3466808],
                [107.9441592, 11.3467336],
                [107.9439485, 11.3467951],
                [107.9437405, 11.3468652],
                [107.9435358, 11.3469439],
                [107.9430164, 11.3471745],
                [107.9426867, 11.3472902],
                [107.9425182, 11.3473373],
                [107.9421758, 11.3474096],
                [107.9419265, 11.3474432],
                [107.9416787, 11.347486],
                [107.9414326, 11.347538],
                [107.9409885, 11.3476563],
                [107.940791, 11.3477224],
                [107.9405967, 11.347797],
                [107.9404061, 11.3478801],
                [107.9402193, 11.3479715],
                [107.940037, 11.3480709],
                [107.9398593, 11.3481782],
                [107.9396868, 11.3482932],
                [107.9391503, 11.3486929],
                [107.9384107, 11.3492181],
                [107.9381906, 11.3494031],
                [107.9379922, 11.3496102],
                [107.9378179, 11.3498373],
                [107.9376697, 11.3500815],
                [107.93751, 11.3503879],
                [107.9374197, 11.3505354],
                [107.9372191, 11.3508178],
                [107.936978, 11.3511244],
                [107.936766, 11.351451],
                [107.9365848, 11.3517951],
                [107.9364359, 11.3521537],
                [107.9362841, 11.3526406],
                [107.9361623, 11.3531356],
                [107.9360711, 11.3536368],
                [107.9360258, 11.35399],
                [107.93599, 11.3543442],
                [107.9359518, 11.354937],
                [107.9359012, 11.3554104],
                [107.9358157, 11.355879],
                [107.9356956, 11.3563402],
                [107.9355452, 11.3567825],
                [107.935458, 11.3569995],
                [107.9353492, 11.3572473],
                [107.935232, 11.3574915],
                [107.9351065, 11.3577317],
                [107.9348474, 11.3581832],
                [107.9347628, 11.358308],
                [107.9346005, 11.3585163],
                [107.9345067, 11.358614],
                [107.9344063, 11.3587052],
                [107.9342997, 11.3587894],
                [107.9340647, 11.3589405],
                [107.9339468, 11.3590014],
                [107.9332113, 11.3591627],
                [107.9320795, 11.3593843],
                [107.9309535, 11.3596326],
                [107.9302989, 11.3597898],
                [107.9296466, 11.359956],
                [107.9289968, 11.3601312],
                [107.9283496, 11.3603153],
                [107.9103119, 11.3634428],
                [107.9096937, 11.3635487],
                [107.9093832, 11.3635928],
                [107.9087843, 11.3636607],
                [107.8876841, 11.3655547],
                [107.860465, 11.3675319],
                [107.8335866, 11.369793],
                [107.8302702, 11.3698676],
                [107.8227803, 11.3708627],
                [107.8040908, 11.3711013],
                [107.799126, 11.3711647],
                [107.798847, 11.3711917],
                [107.7987093, 11.371218],
                [107.7984913, 11.3712786],
                [107.7982512, 11.3713659],
                [107.7980096, 11.3714726],
                [107.7977505, 11.3716094],
                [107.7976262, 11.3716867],
                [107.7974248, 11.371846],
                [107.7972454, 11.372029],
                [107.7970925, 11.3722304],
                [107.7970266, 11.3723365],
                [107.7969156, 11.3725597],
                [107.7954192, 11.3710575],
                [107.7881765, 11.3710224],
                [107.7779227, 11.3703751],
                [107.7693986, 11.3703193],
                [107.7524636, 11.3690104],
                [107.7494632, 11.3670218],
                [107.7492092, 11.3671583],
                [107.7489431, 11.3672752],
                [107.7488056, 11.367326],
                [107.7484927, 11.3674238],
                [107.7483219, 11.3674846],
                [107.7479678, 11.3676266],
                [107.7477854, 11.3676936],
                [107.7475998, 11.367752],
                [107.7474116, 11.3678015],
                [107.7472212, 11.3678422],
                [107.7467332, 11.3679182],
                [107.7465877, 11.3679525],
                [107.7462417, 11.3680553],
                [107.7461371, 11.3680722],
                [107.7460314, 11.3680801],
                [107.7459254, 11.3680788],
                [107.7458199, 11.3680683],
                [107.7457158, 11.3680488],
                [107.7456138, 11.3680204],
                [107.7455147, 11.3679833],
                [107.7454194, 11.3679378],
                [107.7453285, 11.3678843],
                [107.7452269, 11.3678105],
                [107.7450386, 11.3676452],
                [107.7449526, 11.3675543],
                [107.7447114, 11.3672527],
                [107.744621, 11.367158],
                [107.7444222, 11.366987],
                [107.7442345, 11.3668613],
                [107.7441498, 11.366819],
                [107.7440615, 11.3667846],
                [107.7438769, 11.3667411],
                [107.7436872, 11.3667324],
                [107.7435926, 11.3667413],
                [107.7434993, 11.3667588],
                [107.7432189, 11.3668375],
                [107.7430755, 11.366864],
                [107.7429368, 11.3668813],
                [107.7425184, 11.3669099],
                [107.7422392, 11.3669221],
                [107.741684, 11.366925],
                [107.7414086, 11.3669145],
                [107.7408869, 11.3668797],
                [107.74064, 11.3668903],
                [107.740285, 11.3669473],
                [107.74011, 11.3669883],
                [107.7397471, 11.3670926],
                [107.7395511, 11.3671217],
                [107.7394401, 11.3671266],
                [107.7391073, 11.3671161],
                [107.7388859, 11.3671007],
                [107.7385622, 11.3670592],
                [107.7382446, 11.3669853],
                [107.7380891, 11.3669364],
                [107.7378261, 11.3668333],
                [107.7377187, 11.3667806],
                [107.7375437, 11.3666776],
                [107.7374165, 11.36657],
                [107.7373613, 11.3665079],
                [107.73727, 11.3663697],
                [107.7372347, 11.3662949],
                [107.7371912, 11.3661587],
                [107.7371431, 11.3658606],
                [107.7371369, 11.365619],
                [107.7371462, 11.3654985],
                [107.7371893, 11.3652606],
                [107.7373211, 11.3648558],
                [107.737357, 11.3647077],
                [107.7373815, 11.3645737],
                [107.7373969, 11.3644383],
                [107.737403, 11.3643022],
                [107.7373999, 11.364166],
                [107.7373785, 11.363861],
                [107.7373847, 11.3634246],
                [107.7373601, 11.363068],
                [107.7373006, 11.3627154],
                [107.7372579, 11.3625416],
                [107.7371547, 11.3622205],
                [107.7370965, 11.3620732],
                [107.7369842, 11.3618283],
                [107.7366675, 11.3612272],
                [107.7366296, 11.3611834],
                [107.7365362, 11.3611148],
                [107.7364263, 11.3610762],
                [107.7362726, 11.3610663],
                [107.7361239, 11.3610744],
                [107.735922, 11.3611264],
                [107.7357853, 11.3611326],
                [107.7357174, 11.3611241],
                [107.7355868, 11.3610841],
                [107.7353129, 11.3609466],
                [107.7352392, 11.3609035],
                [107.7351708, 11.3608528],
                [107.7351084, 11.3607951],
                [107.7350528, 11.3607311],
                [107.7350046, 11.3606614],
                [107.7349645, 11.3605871],
                [107.7349164, 11.360454],
                [107.7348295, 11.3602626],
                [107.7347913, 11.3602056],
                [107.7347457, 11.3601539],
                [107.734647, 11.3600768],
                [107.7345967, 11.360051],
                [107.7344883, 11.3600189],
                [107.7343752, 11.3600143],
                [107.7342644, 11.3600375],
                [107.7341196, 11.3601059],
                [107.7338995, 11.3602229],
                [107.7337835, 11.3602694],
                [107.7335429, 11.3603373],
                [107.7332913, 11.3603776],
                [107.7328643, 11.3604214],
                [107.7326612, 11.3604285],
                [107.7324893, 11.3604033],
                [107.732406, 11.3603789],
                [107.7322482, 11.3603076],
                [107.7321069, 11.3602084],
                [107.7319872, 11.3600848],
                [107.7318766, 11.3599166],
                [107.731831, 11.359827],
                [107.7317606, 11.3596392],
                [107.7317361, 11.3595419],
                [107.7316902, 11.3592022],
                [107.7316327, 11.3589662],
                [107.7315935, 11.3588511],
                [107.7314929, 11.3586254],
                [107.7314298, 11.3585168],
                [107.7313586, 11.3584131],
                [107.7312796, 11.358315],
                [107.731129, 11.3581628],
                [107.7309839, 11.3580624],
                [107.7309044, 11.3580232],
                [107.7307357, 11.3579688],
                [107.7305594, 11.3579479],
                [107.7304705, 11.3579504],
                [107.7303823, 11.3579614],
                [107.7302113, 11.3580088],
                [107.7300532, 11.3580881],
                [107.7299297, 11.3581819],
                [107.7297894, 11.3583248],
                [107.729704, 11.358427],
                [107.7296259, 11.3585348],
                [107.7295557, 11.3586476],
                [107.7294935, 11.358765],
                [107.7294398, 11.3588862],
                [107.7293948, 11.3590108],
                [107.7293587, 11.3591382],
                [107.7292961, 11.359398],
                [107.7292531, 11.3595247],
                [107.7291441, 11.3597693],
                [107.7290308, 11.3599624],
                [107.7288222, 11.3603583],
                [107.728682, 11.3606651],
                [107.7285958, 11.3608798],
                [107.7285657, 11.3609351],
                [107.7284852, 11.361032],
                [107.728382, 11.3611056],
                [107.7282632, 11.3611509],
                [107.7281364, 11.361165],
                [107.7280102, 11.3611469],
                [107.7279499, 11.3611261],
                [107.72784, 11.3610626],
                [107.7277504, 11.3609738],
                [107.7276439, 11.360818],
                [107.7275214, 11.360599],
                [107.7274023, 11.3603325],
                [107.7273042, 11.3601971],
                [107.7271786, 11.3600829],
                [107.7270301, 11.3599956],
                [107.7268664, 11.3599403],
                [107.7267811, 11.3599254],
                [107.726608, 11.359922],
                [107.7264379, 11.3599537],
                [107.7262782, 11.3600192],
                [107.7262044, 11.3600638],
                [107.7260409, 11.3601975],
                [107.7258932, 11.3603478],
                [107.7257563, 11.3605261],
                [107.725648, 11.3607225],
                [107.7256055, 11.3608261],
                [107.7255449, 11.3610413],
                [107.7255177, 11.3612629],
                [107.7255175, 11.361388],
                [107.725498, 11.3616373],
                [107.7254534, 11.3618835],
                [107.7253761, 11.3621966],
                [107.7253246, 11.3625146],
                [107.7252997, 11.3628587],
                [107.7253105, 11.3632035],
                [107.7253292, 11.3633751],
                [107.7253926, 11.3637118],
                [107.7254367, 11.3638763],
                [107.7255499, 11.3641976],
                [107.7255808, 11.3643613],
                [107.7255827, 11.3645277],
                [107.7255462, 11.3648367],
                [107.7255459, 11.3649817],
                [107.7255543, 11.3651265],
                [107.725595, 11.3654028],
                [107.725664, 11.3656627],
                [107.7257094, 11.3657894],
                [107.7257724, 11.365903],
                [107.7259783, 11.3662125],
                [107.7260343, 11.366322],
                [107.7261167, 11.3665403],
                [107.7261643, 11.3667561],
                [107.7261892, 11.3670052],
                [107.7262366, 11.3673558],
                [107.7262494, 11.3675436],
                [107.7262527, 11.3677318],
                [107.7262432, 11.3677907],
                [107.7262036, 11.3679032],
                [107.7261739, 11.3679552],
                [107.7260971, 11.3680473],
                [107.7260432, 11.3680919],
                [107.7259836, 11.3681288],
                [107.7259194, 11.3681574],
                [107.7258519, 11.3681772],
                [107.7257836, 11.3681905],
                [107.7257054, 11.3680058],
                [107.7255677, 11.3676356],
                [107.7254466, 11.3672391],
                [107.7253461, 11.3668167],
                [107.7253028, 11.366717],
                [107.7252543, 11.3666196],
                [107.7251422, 11.3664328],
                [107.7248274, 11.3660207],
                [107.7246809, 11.3657543],
                [107.7245662, 11.3654775],
                [107.7245205, 11.3653349],
                [107.7244529, 11.3650435],
                [107.7244313, 11.3648956],
                [107.7243993, 11.3644699],
                [107.7243313, 11.3640483],
                [107.7242424, 11.3636843],
                [107.7241444, 11.3633772],
                [107.723477, 11.3618873],
                [107.7233639, 11.3617184],
                [107.7231574, 11.3614943],
                [107.7230953, 11.3614081],
                [107.7230415, 11.3613167],
                [107.7229965, 11.3612209],
                [107.7228355, 11.3607054],
                [107.7223796, 11.3597219],
                [107.7221113, 11.359338],
                [107.7220488, 11.3592294],
                [107.7219946, 11.3591165],
                [107.7219492, 11.359],
                [107.7218909, 11.3587858],
                [107.721864, 11.3585935],
                [107.7218592, 11.3584965],
                [107.7218485, 11.3577918],
                [107.7218588, 11.3576429],
                [107.7218914, 11.3574972],
                [107.7219437, 11.3573619],
                [107.7219772, 11.3572974],
                [107.722224, 11.3569082],
                [107.7222991, 11.356519],
                [107.7225512, 11.3556354],
                [107.7226113, 11.3554856],
                [107.7227497, 11.3551936],
                [107.722842, 11.3550277],
                [107.7230501, 11.3547098],
                [107.7233827, 11.3538998],
                [107.7233076, 11.3533528],
                [107.7230416, 11.3524691],
                [107.723013, 11.3523515],
                [107.7229592, 11.3520426],
                [107.7228999, 11.3518591],
                [107.7228069, 11.3516729],
                [107.7227497, 11.3515857],
                [107.722106, 11.3507231],
                [107.7218794, 11.3503764],
                [107.7217758, 11.3501972],
                [107.7215886, 11.3498286],
                [107.7215052, 11.3496396],
                [107.7213654, 11.3492713],
                [107.7213053, 11.3490839],
                [107.7212047, 11.3487034],
                [107.7209097, 11.347236],
                [107.7209033, 11.347149],
                [107.7209114, 11.3469749],
                [107.7209258, 11.3468889],
                [107.7209792, 11.346711],
                [107.7210733, 11.3465173],
                [107.7210925, 11.3464594],
                [107.7211119, 11.3463438],
                [107.7211082, 11.3462315],
                [107.7208409, 11.3460371],
                [107.7207146, 11.345931],
                [107.7204778, 11.3457018],
                [107.7202738, 11.3454644],
                [107.7201009, 11.3452235],
                [107.7200225, 11.3450979],
                [107.7199495, 11.3449692],
                [107.7195203, 11.3438226],
                [107.7193057, 11.3430757],
                [107.7188015, 11.3423919],
                [107.7182221, 11.3415083],
                [107.7169135, 11.3400428],
                [107.7165492, 11.3398134],
                [107.7162254, 11.339637],
                [107.7159564, 11.3394713],
                [107.715706, 11.3392795],
                [107.715477, 11.3390636],
                [107.7152717, 11.3388259],
                [107.715057, 11.338544],
                [107.7149416, 11.3384093],
                [107.714562, 11.3380081],
                [107.7143276, 11.3377165],
                [107.7141202, 11.337407],
                [107.7140271, 11.3372463],
                [107.7138624, 11.3369141],
                [107.7137911, 11.336743],
                [107.7108453, 11.3351781],
                [107.6933956, 11.3259077],
                [107.6832998, 11.3225308],
                [107.6824907, 11.3226149],
                [107.6817362, 11.3227193],
                [107.681036, 11.3228359],
                [107.6798941, 11.3230447],
                [107.6794374, 11.323141],
                [107.6791766, 11.3232122],
                [107.6789264, 11.3233138],
                [107.6786905, 11.3234442],
                [107.6785791, 11.3235197],
                [107.6781735, 11.3238319],
                [107.6780737, 11.3238945],
                [107.6779691, 11.323949],
                [107.6778604, 11.3239952],
                [107.6777483, 11.3240328],
                [107.6776335, 11.3240614],
                [107.6775167, 11.324081],
                [107.6773987, 11.3240913],
                [107.6772802, 11.3240924],
                [107.677162, 11.3240842],
                [107.6770449, 11.3240667],
                [107.6766586, 11.3239721],
                [107.6761994, 11.3238728],
                [107.6758769, 11.3237879],
                [107.6756815, 11.323761],
                [107.6754844, 11.323769],
                [107.675292, 11.3238118],
                [107.6751995, 11.3238458],
                [107.6750407, 11.3239284],
                [107.6748984, 11.324036],
                [107.6748347, 11.3240983],
                [107.674745, 11.3242077],
                [107.674706, 11.3242666],
                [107.6730416, 11.3243299],
                [107.6723996, 11.3243828],
                [107.6720797, 11.3244208],
                [107.6714199, 11.3245241],
                [107.6710805, 11.3245906],
                [107.6704078, 11.3247497],
                [107.6700748, 11.3248423],
                [107.658486, 11.3314274],
                [107.6574377, 11.3319559],
                [107.6460847, 11.3376799],
                [107.6357176, 11.3377974],
                [107.6295363, 11.3378675],
                [107.629208, 11.3380546],
                [107.6286671, 11.3383797],
                [107.6282216, 11.3386218],
                [107.6277985, 11.3388165],
                [107.6270694, 11.3391138],
                [107.6269262, 11.3391619],
                [107.6266323, 11.3392324],
                [107.6263318, 11.3392678],
                [107.6261805, 11.3392722],
                [107.6257619, 11.3392537],
                [107.6254951, 11.3392752],
                [107.6252337, 11.3393318],
                [107.6251066, 11.3393729],
                [107.6248125, 11.3394848],
                [107.6245096, 11.339571],
                [107.6242229, 11.3396275],
                [107.6238222, 11.3396783],
                [107.6232849, 11.3397169],
                [107.6230156, 11.3397229],
                [107.6225313, 11.3397113],
                [107.6218488, 11.3396583],
                [107.6215948, 11.3396716],
                [107.6213447, 11.3397176],
                [107.6212226, 11.3397527],
                [107.6209867, 11.3398463],
                [107.6205575, 11.3400777],
                [107.6202516, 11.3402221],
                [107.6200938, 11.3402835],
                [107.6197701, 11.340384],
                [107.6194382, 11.340454],
                [107.6191252, 11.3404912],
                [107.6188389, 11.340543],
                [107.6185606, 11.3406265],
                [107.6184255, 11.3406797],
                [107.6181208, 11.3408216],
                [107.6179368, 11.3408746],
                [107.6177466, 11.3408982],
                [107.6174033, 11.3408982],
                [107.6170844, 11.3409302],
                [107.6166582, 11.3410097],
                [107.6163912, 11.3410681],
                [107.6161257, 11.3411327],
                [107.6159904, 11.3411756],
                [107.6157276, 11.3412821],
                [107.6156008, 11.3413453],
                [107.6153475, 11.3414979],
                [107.6151127, 11.3416766],
                [107.6148498, 11.3419133],
                [107.6146138, 11.3420974],
                [107.6143017, 11.3422786],
                [107.6139866, 11.3424548],
                [107.6136686, 11.3426258],
                [107.6133478, 11.3427917],
                [107.6122664, 11.3433885],
                [107.6121424, 11.3434626],
                [107.6120226, 11.3435431],
                [107.6119076, 11.3436299],
                [107.6117974, 11.3437226],
                [107.6117065, 11.3438074],
                [107.6116198, 11.3438963],
                [107.6115374, 11.3439891],
                [107.6114595, 11.3440855],
                [107.6112784, 11.3443256],
                [107.6112234, 11.3443875],
                [107.6111638, 11.3444452],
                [107.6111001, 11.3444984],
                [107.6110281, 11.3445497],
                [107.6109523, 11.3445953],
                [107.610873, 11.3446349],
                [107.6107908, 11.3446682],
                [107.6106712, 11.3447012],
                [107.6105542, 11.344742],
                [107.6104402, 11.3447903],
                [107.6103434, 11.3448387],
                [107.6102496, 11.3448925],
                [107.6101592, 11.3449516],
                [107.6100724, 11.3450158],
                [107.6099896, 11.3450849],
                [107.6064276, 11.3487561],
                [107.6062387, 11.3489699],
                [107.6060433, 11.349178],
                [107.6058416, 11.3493803],
                [107.6056337, 11.3495765],
                [107.605442, 11.3497474],
                [107.6052457, 11.3499132],
                [107.6050449, 11.3500736],
                [107.6048398, 11.3502287],
                [107.6016426, 11.3524903],
                [107.6013673, 11.3525177],
                [107.601093, 11.3525531],
                [107.6008199, 11.3525966],
                [107.6005483, 11.3526481],
                [107.6003208, 11.3526976],
                [107.6000947, 11.3527527],
                [107.59987, 11.3528133],
                [107.599647, 11.3528795],
                [107.5995895, 11.3528999],
                [107.5995352, 11.3529275],
                [107.599485, 11.3529617],
                [107.5994398, 11.353002],
                [107.5994003, 11.3530478],
                [107.5993663, 11.3530996],
                [107.5993102, 11.3532317],
                [107.5992501, 11.3533844],
                [107.5992152, 11.3534784],
                [107.5991829, 11.3535732],
                [107.5991084, 11.3537594],
                [107.5990267, 11.3539427],
                [107.598938, 11.3541229],
                [107.5988424, 11.3542995],
                [107.5987446, 11.354465],
                [107.5986407, 11.3546269],
                [107.5984347, 11.3549517],
                [107.5983662, 11.3550575],
                [107.5982915, 11.3551592],
                [107.5982108, 11.3552564],
                [107.5981244, 11.3553487],
                [107.5980325, 11.3554358],
                [107.5979356, 11.3555175],
                [107.5978339, 11.3555934],
                [107.5977188, 11.3556687],
                [107.5975998, 11.3557378],
                [107.5974771, 11.3558004],
                [107.5973511, 11.3558563],
                [107.5972127, 11.3559087],
                [107.5970777, 11.3559688],
                [107.5969464, 11.3560363],
                [107.5968192, 11.3561111],
                [107.5966966, 11.3561929],
                [107.5966166, 11.356252],
                [107.5965389, 11.3563141],
                [107.5963867, 11.3564418],
                [107.5962403, 11.3565758],
                [107.5960999, 11.356716],
                [107.5959658, 11.3568619],
                [107.5958383, 11.3570134],
                [107.5957509, 11.3571255],
                [107.5956685, 11.3572413],
                [107.5955915, 11.3573605],
                [107.595533, 11.3574596],
                [107.5954665, 11.3575538],
                [107.5953923, 11.3576423],
                [107.5953111, 11.3577245],
                [107.5952232, 11.3578],
                [107.5951294, 11.3578682],
                [107.5950302, 11.3579287],
                [107.5949263, 11.3579811],
                [107.5948242, 11.3580229],
                [107.5947186, 11.3580556],
                [107.5946104, 11.358079],
                [107.5945006, 11.3580927],
                [107.5943899, 11.3580968],
                [107.5943023, 11.3580932],
                [107.5942147, 11.3580977],
                [107.594128, 11.3581103],
                [107.5940429, 11.358131],
                [107.5939601, 11.3581594],
                [107.5938805, 11.3581955],
                [107.5938047, 11.3582387],
                [107.5937335, 11.3582888],
                [107.5936674, 11.3583454],
                [107.5936071, 11.3584078],
                [107.5935531, 11.3584755],
                [107.5935151, 11.3585324],
                [107.5934817, 11.358592],
                [107.5934528, 11.3586539],
                [107.5933597, 11.3588359],
                [107.5932737, 11.3590213],
                [107.5931951, 11.3592098],
                [107.5931239, 11.3594011],
                [107.5930753, 11.3595466],
                [107.5930311, 11.3596935],
                [107.5929912, 11.3598415],
                [107.5928517, 11.3602798],
                [107.5927055, 11.360716],
                [107.5926341, 11.3609212],
                [107.5925704, 11.3611289],
                [107.5925145, 11.3613387],
                [107.5924665, 11.3615504],
                [107.5924264, 11.3617636],
                [107.5923943, 11.3619782],
                [107.5923763, 11.3621322],
                [107.5923512, 11.3622853],
                [107.5923189, 11.3624371],
                [107.5922796, 11.3625873],
                [107.5922334, 11.3627355],
                [107.5921966, 11.3628389],
                [107.5921674, 11.3629446],
                [107.5921457, 11.363052],
                [107.5921319, 11.3631606],
                [107.5921258, 11.3632699],
                [107.5921276, 11.3633794],
                [107.5921372, 11.3634884],
                [107.5921546, 11.3635966],
                [107.5921798, 11.3637032],
                [107.5922056, 11.3637881],
                [107.5922229, 11.3638749],
                [107.5922315, 11.3639631],
                [107.5922313, 11.3640516],
                [107.5922223, 11.3641397],
                [107.5922046, 11.3642265],
                [107.5921783, 11.3643112],
                [107.5921437, 11.364393],
                [107.5921011, 11.3644711],
                [107.592051, 11.3645447],
                [107.5920067, 11.3645989],
                [107.5919583, 11.3646496],
                [107.5918602, 11.3647497],
                [107.591757, 11.3648446],
                [107.5916488, 11.3649341],
                [107.591536, 11.365018],
                [107.5914644, 11.3650669],
                [107.5913911, 11.3651134],
                [107.5912411, 11.365201],
                [107.5910865, 11.3652804],
                [107.5909277, 11.3653514],
                [107.5907652, 11.3654139],
                [107.5905994, 11.3654676],
                [107.590431, 11.3655124],
                [107.5903159, 11.3655376],
                [107.5902031, 11.3655712],
                [107.5900932, 11.3656131],
                [107.5899868, 11.3656631],
                [107.5898847, 11.3657208],
                [107.5897872, 11.3657859],
                [107.589697, 11.3658565],
                [107.5896012, 11.3659197],
                [107.5895005, 11.3659751],
                [107.5893955, 11.3660223],
                [107.589287, 11.366061],
                [107.5891757, 11.3660909],
                [107.5891201, 11.3661024],
                [107.5890667, 11.3661214],
                [107.5890166, 11.3661475],
                [107.5889707, 11.3661802],
                [107.5889299, 11.366219],
                [107.5888951, 11.3662629],
                [107.5888668, 11.3663112],
                [107.5888458, 11.3663629],
                [107.5888324, 11.366417],
                [107.5888274, 11.3664596],
                [107.5888271, 11.3665025],
                [107.5888127, 11.3665869],
                [107.5887895, 11.3666694],
                [107.588772, 11.366716],
                [107.5887516, 11.3667615],
                [107.5887053, 11.3668497],
                [107.5886507, 11.3669332],
                [107.5885885, 11.3670115],
                [107.5885192, 11.3670837],
                [107.5884433, 11.3671494],
                [107.5883615, 11.3672078],
                [107.5882745, 11.3672585],
                [107.5882242, 11.3672831],
                [107.5881727, 11.3673052],
                [107.5880742, 11.3673583],
                [107.5879794, 11.3674174],
                [107.5878887, 11.3674824],
                [107.5878024, 11.367553],
                [107.587711, 11.3676388],
                [107.5876134, 11.3677176],
                [107.58751, 11.3677892],
                [107.5874015, 11.367853],
                [107.5872885, 11.3679087],
                [107.5871716, 11.3679561],
                [107.5870514, 11.3679948],
                [107.5869646, 11.3680168],
                [107.5868767, 11.3680344],
                [107.5867039, 11.3680809],
                [107.5865331, 11.3681344],
                [107.5863647, 11.3681946],
                [107.5861805, 11.3682694],
                [107.586, 11.3683524],
                [107.5859338, 11.3684689],
                [107.585873, 11.3685882],
                [107.5858176, 11.36871],
                [107.5857815, 11.368798],
                [107.5857484, 11.3688871],
                [107.585684, 11.369066],
                [107.5856244, 11.3692464],
                [107.5855592, 11.3694658],
                [107.5854877, 11.3696832],
                [107.5854099, 11.3698986],
                [107.5853392, 11.3700791],
                [107.585264, 11.370258],
                [107.5851846, 11.370435],
                [107.5850266, 11.3703709],
                [107.5848655, 11.3703147],
                [107.5847018, 11.3702667],
                [107.5846177, 11.3702455],
                [107.5845323, 11.3702306],
                [107.584446, 11.3702221],
                [107.5843593, 11.3702201],
                [107.5842726, 11.3702246],
                [107.584207, 11.3702324],
                [107.5841432, 11.3702492],
                [107.5840824, 11.3702747],
                [107.584026, 11.3703084],
                [107.583975, 11.3703496],
                [107.5839304, 11.3703975],
                [107.5838933, 11.3704511],
                [107.5838642, 11.3705093],
                [107.5838439, 11.370571],
                [107.5838327, 11.3706349],
                [107.5838305, 11.3706789],
                [107.5838325, 11.370723],
                [107.583839, 11.3707667],
                [107.5838542, 11.3708978],
                [107.5838729, 11.3710024],
                [107.5838845, 11.371108],
                [107.5838887, 11.3712141],
                [107.5838856, 11.3713202],
                [107.5838752, 11.3714259],
                [107.5838575, 11.3715307],
                [107.5838327, 11.3716341],
                [107.5838064, 11.3717196],
                [107.5837751, 11.3718036],
                [107.583739, 11.3718856],
                [107.5836503, 11.372139],
                [107.5836006, 11.3722884],
                [107.5835419, 11.3724347],
                [107.5834743, 11.3725773],
                [107.5833982, 11.3727157],
                [107.5833137, 11.3728493],
                [107.5832213, 11.3729778],
                [107.5831211, 11.3731006],
                [107.5830137, 11.3732173],
                [107.5828993, 11.3733275],
                [107.5827885, 11.3734227],
                [107.5826726, 11.3735117],
                [107.582452, 11.3737029],
                [107.5822378, 11.3739011],
                [107.5820303, 11.3741059],
                [107.5818444, 11.3743011],
                [107.5816645, 11.3745017],
                [107.5814908, 11.3747074],
                [107.5813234, 11.3749181],
                [107.5811626, 11.3751337],
                [107.5810083, 11.3753538],
                [107.5808608, 11.3755784],
                [107.580766, 11.3757309],
                [107.5806744, 11.3758853],
                [107.5804813, 11.3761883],
                [107.5802816, 11.3764871],
                [107.5800755, 11.3767817],
                [107.5798631, 11.3770719],
                [107.5796153, 11.3773947],
                [107.5793721, 11.3777208],
                [107.5791335, 11.3780501],
                [107.5788983, 11.3783846],
                [107.5786679, 11.3787223],
                [107.5784423, 11.3790631],
                [107.5782215, 11.3794069],
                [107.5780841, 11.3795744],
                [107.577953, 11.3797467],
                [107.5778286, 11.3799236],
                [107.5777092, 11.3801076],
                [107.577597, 11.3802959],
                [107.5774868, 11.3804746],
                [107.5773694, 11.3806488],
                [107.5772449, 11.3808183],
                [107.5771136, 11.3809827],
                [107.5769756, 11.3811418],
                [107.5768312, 11.3812953],
                [107.5766806, 11.3814431],
                [107.5765241, 11.3815848],
                [107.576362, 11.3817202],
                [107.5761944, 11.3818491],
                [107.5760216, 11.3819713],
                [107.575844, 11.3820865],
                [107.5756521, 11.3822002],
                [107.5754555, 11.3823058],
                [107.5752546, 11.3824032],
                [107.5750496, 11.3824921],
                [107.5748409, 11.3825725],
                [107.574629, 11.3826442],
                [107.5744141, 11.382707],
                [107.5741968, 11.3827608],
                [107.5739773, 11.3828056],
                [107.5737561, 11.3828412],
                [107.5735335, 11.3828676],
                [107.57331, 11.3828848],
                [107.573086, 11.3828927],
                [107.5728618, 11.3828913],
                [107.5721949, 11.3828787],
                [107.5717521, 11.3828673],
                [107.5715273, 11.3828579],
                [107.5713029, 11.3828416],
                [107.5710791, 11.3828184],
                [107.5708562, 11.3827884],
                [107.5707116, 11.3827652],
                [107.5705659, 11.3827489],
                [107.5704197, 11.3827394],
                [107.5702732, 11.3827368],
                [107.5701266, 11.3827411],
                [107.5699759, 11.3827528],
                [107.5698246, 11.3827559],
                [107.5696735, 11.3827505],
                [107.5695229, 11.3827365],
                [107.5693734, 11.3827141],
                [107.5692254, 11.3826833],
                [107.5691268, 11.3826578],
                [107.5690263, 11.3826407],
                [107.5689247, 11.3826323],
                [107.5688228, 11.3826324],
                [107.5687212, 11.3826412],
                [107.5686378, 11.3826549],
                [107.5685535, 11.3826607],
                [107.568469, 11.3826585],
                [107.5683851, 11.3826483],
                [107.5683026, 11.3826302],
                [107.5682223, 11.3826044],
                [107.5681693, 11.3825825],
                [107.5681179, 11.3825573],
                [107.5680103, 11.3825178],
                [107.5679001, 11.3824861],
                [107.5677878, 11.3824624],
                [107.5677159, 11.3824515],
                [107.5676454, 11.3824339],
                [107.567577, 11.3824096],
                [107.5675114, 11.3823788],
                [107.5674491, 11.382342],
                [107.5673908, 11.3822994],
                [107.5673338, 11.3822482],
                [107.567272, 11.3822027],
                [107.5672059, 11.3821634],
                [107.5671362, 11.3821307],
                [107.5670636, 11.3821048],
                [107.5670152, 11.3820918],
                [107.5669661, 11.3820818],
                [107.5668704, 11.3820522],
                [107.5668076, 11.3820287],
                [107.5667459, 11.3820023],
                [107.5666245, 11.3819455],
                [107.5665067, 11.3818819],
                [107.5663928, 11.3818117],
                [107.5662833, 11.3817351],
                [107.5661784, 11.3816525],
                [107.5660734, 11.3815591],
                [107.5659749, 11.381459],
                [107.5658834, 11.3813527],
                [107.565812, 11.3812587],
                [107.5657369, 11.3811675],
                [107.5656581, 11.3810793],
                [107.5655854, 11.3810038],
                [107.5655183, 11.3809236],
                [107.5654572, 11.3808388],
                [107.5654023, 11.3807501],
                [107.565354, 11.3806578],
                [107.5653124, 11.3805624],
                [107.5652778, 11.3804643],
                [107.5652504, 11.3803641],
                [107.5652291, 11.3802549],
                [107.5652, 11.3801474],
                [107.5651633, 11.3800422],
                [107.5651192, 11.3799398],
                [107.5650679, 11.3798406],
                [107.5650096, 11.3797452],
                [107.5649446, 11.3796541],
                [107.5648803, 11.3795756],
                [107.564811, 11.3795013],
                [107.5647371, 11.3794315],
                [107.5645316, 11.3792071],
                [107.5643068, 11.3789573],
                [107.5640844, 11.3787053],
                [107.563641, 11.3782005],
                [107.5632012, 11.3776925],
                [107.5627611, 11.3771767],
                [107.5623247, 11.3766578],
                [107.5618923, 11.3761358],
                [107.5603795, 11.374211],
                [107.5601011, 11.3739022],
                [107.5598169, 11.3735984],
                [107.559527, 11.3732999],
                [107.5592315, 11.3730067],
                [107.55897, 11.372756],
                [107.5587043, 11.3725095],
                [107.5584347, 11.3722673],
                [107.5581611, 11.3720293],
                [107.5443042, 11.3665313],
                [107.54502, 11.3619662],
                [107.5450128, 11.3618898],
                [107.544977, 11.361813],
                [107.5449099, 11.3617487],
                [107.5447899, 11.3616779],
                [107.5446832, 11.361589],
                [107.5446048, 11.3615004],
                [107.5445706, 11.3614523],
                [107.5445574, 11.3614132],
                [107.5445545, 11.3613313],
                [107.54458, 11.361259],
                [107.5447078, 11.3611067],
                [107.5447385, 11.3610542],
                [107.5447807, 11.3609409],
                [107.5447959, 11.3608211],
                [107.5447924, 11.3607551],
                [107.5447609, 11.3606269],
                [107.5447332, 11.3605666],
                [107.5446564, 11.3604583],
                [107.5445684, 11.3603753],
                [107.5443804, 11.3602226],
                [107.5442809, 11.3601532],
                [107.544021, 11.3600031],
                [107.5438846, 11.3599399],
                [107.5437444, 11.359885],
                [107.5427896, 11.3534764],
                [107.5431168, 11.3532082],
                [107.5434118, 11.3524955],
                [107.543495, 11.3522559],
                [107.5435245, 11.3521326],
                [107.5435559, 11.3519154],
                [107.543562, 11.3516961],
                [107.5435513, 11.3513069],
                [107.5436031, 11.3511836],
                [107.5436193, 11.3511188],
                [107.5436318, 11.3509861],
                [107.5436262, 11.3509055],
                [107.5436107, 11.3508261],
                [107.5435283, 11.3506032],
                [107.5435277, 11.3505142],
                [107.5435404, 11.3504646],
                [107.5436747, 11.3501025],
                [107.5436749, 11.3500605],
                [107.5436658, 11.3500196],
                [107.5436479, 11.3499815],
                [107.5435931, 11.3499236],
                [107.5435578, 11.3499041],
                [107.5435191, 11.3498921],
                [107.5430524, 11.3498921],
                [107.5428634, 11.3497866],
                [107.5426939, 11.3496529],
                [107.5425545, 11.3495023],
                [107.542445, 11.3493412],
                [107.5423995, 11.3492552],
                [107.5423084, 11.3490408],
                [107.5422252, 11.3487828],
                [107.542171, 11.3485228],
                [107.542145, 11.348264],
                [107.5421458, 11.3480039],
                [107.5421083, 11.3471887],
                [107.5419259, 11.3460106],
                [107.5421298, 11.3448755],
                [107.5421513, 11.3446016],
                [107.5421439, 11.3443278],
                [107.542108, 11.3440569],
                [107.5420794, 11.3439231],
                [107.5418293, 11.3429705],
                [107.5417454, 11.3421913],
                [107.5416868, 11.3419165],
                [107.5415933, 11.341624],
                [107.5411749, 11.3405826],
                [107.5395226, 11.3374478],
                [107.5395387, 11.3372637],
                [107.5390935, 11.3368587],
                [107.5382996, 11.3362328],
                [107.5372267, 11.3353255],
                [107.5363923, 11.3349041],
                [107.536361, 11.3348814],
                [107.5363349, 11.3348532],
                [107.5363019, 11.3347868],
                [107.5362956, 11.3347514],
                [107.5362964, 11.334606],
                [107.5362815, 11.3345426],
                [107.5362691, 11.3345129],
                [107.5361531, 11.3343439],
                [107.5361196, 11.3342611],
                [107.5361095, 11.3340132],
                [107.5360814, 11.3339448],
                [107.5360608, 11.3339183],
                [107.5360078, 11.333877],
                [107.5358883, 11.3338422],
                [107.5358086, 11.3337995],
                [107.5357756, 11.3337686],
                [107.5357354, 11.3337085],
                [107.5357139, 11.3336397],
                [107.535671, 11.3332979],
                [107.5354534, 11.3327037],
                [107.5354361, 11.3326359],
                [107.5354242, 11.332497],
                [107.5354443, 11.3323517],
                [107.535468, 11.3322782],
                [107.5355004, 11.332208],
                [107.5355409, 11.332142],
                [107.5356173, 11.3296843],
                [107.5354591, 11.3287665],
                [107.5353464, 11.3286271],
                [107.5350568, 11.3275541],
                [107.5350568, 11.3268492],
                [107.5350488, 11.3267743],
                [107.5350353, 11.3267388],
                [107.5350027, 11.3266891],
                [107.5348655, 11.3265814],
                [107.5348402, 11.3265531],
                [107.5348072, 11.3264824],
                [107.5348018, 11.3264424],
                [107.5348214, 11.3260578],
                [107.5348301, 11.3260316],
                [107.5348623, 11.3259866],
                [107.5348866, 11.3259683],
                [107.5349443, 11.3259488],
                [107.5352861, 11.3259459],
                [107.5353678, 11.3259316],
                [107.5354061, 11.3259158],
                [107.5354702, 11.3258716],
                [107.5355336, 11.3257953],
                [107.5355534, 11.3257471],
                [107.5355515, 11.32569],
                [107.5355218, 11.3256352],
                [107.5353669, 11.3255094],
                [107.5353386, 11.3254556],
                [107.5353325, 11.3254252],
                [107.5353343, 11.3249404],
                [107.5353292, 11.324899],
                [107.5353004, 11.324821],
                [107.535244, 11.32475],
                [107.5351605, 11.3246979],
                [107.5349763, 11.3246509],
                [107.534867, 11.3246134],
                [107.5345682, 11.3244667],
                [107.5344861, 11.3243934],
                [107.5343023, 11.3241371],
                [107.5340778, 11.3238822],
                [107.5339985, 11.3238289],
                [107.5338783, 11.3237774],
                [107.5337988, 11.3237288],
                [107.5327037, 11.3229082],
                [107.5319302, 11.3218548],
                [107.5317276, 11.3217032],
                [107.5308894, 11.3213066],
                [107.5307628, 11.3211817],
                [107.5306992, 11.3209041],
                [107.5306732, 11.3203588],
                [107.5306725, 11.3199003],
                [107.5306001, 11.3198028],
                [107.5304816, 11.3197081],
                [107.530417, 11.3196678],
                [107.5302072, 11.3195657],
                [107.5301403, 11.3195247],
                [107.5300682, 11.3194724],
                [107.5299175, 11.3193372],
                [107.5298169, 11.3192837],
                [107.5297621, 11.319267],
                [107.5296969, 11.3192565],
                [107.5296308, 11.3192556],
                [107.5295653, 11.3192645],
                [107.5292525, 11.3193445],
                [107.5291966, 11.3193538],
                [107.5290835, 11.3193551],
                [107.5289614, 11.3193301],
                [107.5283943, 11.3190909],
                [107.5282683, 11.3190619],
                [107.5282037, 11.3190566],
                [107.5280757, 11.3190643],
                [107.5280129, 11.3190772],
                [107.5278488, 11.3191317],
                [107.5277944, 11.3191377],
                [107.5276871, 11.3191254],
                [107.5275895, 11.3190829],
                [107.527049, 11.318655],
                [107.5269781, 11.3186165],
                [107.526903, 11.3185866],
                [107.5268248, 11.3185658],
                [107.5267446, 11.3185542],
                [107.5266636, 11.3185521],
                [107.5265829, 11.3185595],
                [107.5265038, 11.3185764],
                [107.5264272, 11.3186023],
                [107.5263543, 11.3186371],
                [107.5262256, 11.3187339],
                [107.5260781, 11.3188001],
                [107.525969, 11.3188261],
                [107.5257471, 11.318859],
                [107.5256351, 11.3188657],
                [107.5254115, 11.3188596],
                [107.525191, 11.318828],
                [107.5249757, 11.3187712],
                [107.5249417, 11.318753],
                [107.5249131, 11.3187276],
                [107.5248914, 11.3186962],
                [107.5248732, 11.3186285],
                [107.5248842, 11.3185648],
                [107.5249428, 11.3184785],
                [107.5249576, 11.3184457],
                [107.5249719, 11.3183608],
                [107.5249301, 11.3180618],
                [107.5249266, 11.3179353],
                [107.5249068, 11.3178854],
                [107.5247249, 11.3177126],
                [107.5245792, 11.317609],
                [107.5245007, 11.3175658],
                [107.5243349, 11.3174975],
                [107.5241603, 11.3174549],
                [107.5241087, 11.3174542],
                [107.5240581, 11.3174445],
                [107.5240101, 11.3174259],
                [107.5239363, 11.3173734],
                [107.5239068, 11.3173391],
                [107.5238694, 11.3172645],
                [107.5238143, 11.3171075],
                [107.5237969, 11.3170262],
                [107.5237831, 11.3168457],
                [107.5237933, 11.3167345],
                [107.523821, 11.3167027],
                [107.5238594, 11.3166937],
                [107.5238788, 11.3166981],
                [107.5239926, 11.3167639],
                [107.5240564, 11.3167671],
                [107.5241144, 11.3167408],
                [107.5241377, 11.3167172],
                [107.5241631, 11.316657],
                [107.5241637, 11.3166242],
                [107.5241559, 11.3165923],
                [107.5240667, 11.3164255],
                [107.5240383, 11.3163529],
                [107.5240007, 11.3162135],
                [107.52395, 11.316103],
                [107.5238862, 11.3160202],
                [107.5237907, 11.3159306],
                [107.523652, 11.3158242],
                [107.523417, 11.3156915],
                [107.5231671, 11.3155882],
                [107.5230378, 11.3155481],
                [107.5227688, 11.3154909],
                [107.5226322, 11.3154748],
                [107.5224947, 11.3154671],
                [107.5221393, 11.3154754],
                [107.5219809, 11.3154491],
                [107.5219044, 11.3154246],
                [107.5218308, 11.3153929],
                [107.5217094, 11.3153198],
                [107.521654, 11.3152758],
                [107.5215561, 11.3151745],
                [107.5215272, 11.3151539],
                [107.5214626, 11.3151267],
                [107.5213936, 11.3151194],
                [107.5208844, 11.315241],
                [107.5208106, 11.3152292],
                [107.5207486, 11.3151881],
                [107.5207257, 11.3151588],
                [107.5207016, 11.3150895],
                [107.5207092, 11.3150167],
                [107.5207771, 11.3148939],
                [107.5208004, 11.3148315],
                [107.5208266, 11.3146994],
                [107.5208236, 11.3145631],
                [107.5207905, 11.3145046],
                [107.5207668, 11.3144804],
                [107.5207052, 11.3144444],
                [107.5206311, 11.3144324],
                [107.520409, 11.314481],
                [107.5203573, 11.3144848],
                [107.5203056, 11.314481],
                [107.5202128, 11.314454],
                [107.5201364, 11.3144073],
                [107.5197261, 11.3139137],
                [107.5196378, 11.3138611],
                [107.5195391, 11.3138312],
                [107.5194805, 11.313825],
                [107.5193638, 11.3138369],
                [107.5192737, 11.3138224],
                [107.5191916, 11.3137833],
                [107.5191557, 11.3137555],
                [107.519096, 11.313683],
                [107.5190376, 11.3135537],
                [107.5187136, 11.3128573],
                [107.5187294, 11.3125653],
                [107.5187345, 11.3125027],
                [107.5185753, 11.3123789],
                [107.5184514, 11.3121314],
                [107.518455, 11.3120643],
                [107.5184856, 11.3119313],
                [107.5186284, 11.3116918],
                [107.5186649, 11.3115981],
                [107.5186791, 11.3114049],
                [107.5186756, 11.3113262],
                [107.5186425, 11.3112753],
                [107.5185647, 11.3112325],
                [107.5183712, 11.3112314],
                [107.5181848, 11.3112406],
                [107.5180904, 11.3112002],
                [107.5180751, 11.3111759],
                [107.5180751, 11.3111354],
                [107.5181459, 11.3109167],
                [107.5182615, 11.3107027],
                [107.5182745, 11.3104864],
                [107.5182438, 11.3103788],
                [107.5182143, 11.3103349],
                [107.5181069, 11.3102481],
                [107.5180232, 11.3101544],
                [107.5176327, 11.3095343],
                [107.5176126, 11.3094719],
                [107.5176303, 11.3094106],
                [107.5177377, 11.3092752],
                [107.5178887, 11.3091699],
                [107.518114, 11.3090774],
                [107.5182957, 11.30906],
                [107.5183735, 11.3090496],
                [107.5183924, 11.3089756],
                [107.5182556, 11.3082468],
                [107.5182615, 11.3081855],
                [107.5182862, 11.308138],
                [107.5183405, 11.308101],
                [107.5189516, 11.3079958],
                [107.5190696, 11.3079113],
                [107.5192182, 11.307629],
                [107.5192356, 11.3074415],
                [107.5192106, 11.307341],
                [107.5189634, 11.3067894],
                [107.5189385, 11.3067124],
                [107.5189625, 11.3065479],
                [107.5190573, 11.3062745],
                [107.5190621, 11.3062172],
                [107.5190477, 11.3061213],
                [107.5190219, 11.3060424],
                [107.5189557, 11.3059672],
                [107.5189548, 11.3059118],
                [107.5188944, 11.3058225],
                [107.5187124, 11.3056994],
                [107.5186625, 11.3056158],
                [107.5187411, 11.3053677],
                [107.5188542, 11.3051215],
                [107.5188772, 11.3050962],
                [107.5189778, 11.3050304],
                [107.5194512, 11.3049101],
                [107.5194933, 11.3049139],
                [107.5195719, 11.3049693],
                [107.5196342, 11.304974],
                [107.5198012, 11.3049109],
                [107.5202812, 11.3044573],
                [107.5203534, 11.3043461],
                [107.5203845, 11.3042179],
                [107.5203927, 11.3039723],
                [107.5204494, 11.3039212],
                [107.5205171, 11.3038907],
                [107.5206131, 11.3039095],
                [107.5207328, 11.3039562],
                [107.5208343, 11.303975],
                [107.5209797, 11.3039418],
                [107.5211296, 11.3038298],
                [107.5212795, 11.3038002],
                [107.5215703, 11.3037643],
                [107.5217138, 11.3037204],
                [107.5218217, 11.3036621],
                [107.5219122, 11.3035725],
                [107.5219533, 11.3034093],
                [107.5219835, 11.3032399],
                [107.5220073, 11.3031556],
                [107.5221197, 11.3030489],
                [107.522245, 11.303031],
                [107.5223492, 11.3030023],
                [107.522376, 11.3029448],
                [107.5223352, 11.3026112],
                [107.5225099, 11.3019647],
                [107.5225554, 11.3017422],
                [107.5229714, 11.3009952],
                [107.5231626, 11.3007915],
                [107.5234534, 11.300329],
                [107.5237644, 11.3000036],
                [107.5239235, 11.2996616],
                [107.5239546, 11.2994403],
                [107.5238784, 11.2991642],
                [107.5236748, 11.2989702],
                [107.5235348, 11.298921],
                [107.5232271, 11.2987818],
                [107.5230856, 11.2986824],
                [107.5229817, 11.2985618],
                [107.5228454, 11.2982113],
                [107.5228759, 11.2976945],
                [107.5229199, 11.297547],
                [107.5230348, 11.2974294],
                [107.5233654, 11.2971048],
                [107.5234524, 11.2969038],
                [107.5234738, 11.2964124],
                [107.5234597, 11.2961686],
                [107.5234404, 11.2959656],
                [107.5232794, 11.2952829],
                [107.5233631, 11.2949547],
                [107.5236688, 11.2945637],
                [107.5237151, 11.2941287],
                [107.5235835, 11.2936587],
                [107.5232135, 11.2930575],
                [107.5230292, 11.2927442],
                [107.5227532, 11.2924542],
                [107.5223925, 11.2920451],
                [107.5222748, 11.2916278],
                [107.5223116, 11.2915365],
                [107.5225492, 11.2913526],
                [107.5226241, 11.2912551],
                [107.5227678, 11.2907601],
                [107.5228323, 11.2906269],
                [107.5228965, 11.2903069],
                [107.522884, 11.2900759],
                [107.5227353, 11.2897703],
                [107.5226466, 11.2896452],
                [107.5224817, 11.289527],
                [107.5224013, 11.2895216],
                [107.5223015, 11.2895977],
                [107.5221325, 11.2898545],
                [107.522005, 11.2899428],
                [107.5218609, 11.2899999],
                [107.521617, 11.2899999],
                [107.5215242, 11.28997],
                [107.5214272, 11.2898925],
                [107.5213302, 11.2898368],
                [107.5212138, 11.2898355],
                [107.5211154, 11.2898708],
                [107.5209172, 11.290004],
                [107.5208438, 11.2900067],
                [107.5207911, 11.2899904],
                [107.5207191, 11.2899252],
                [107.520701, 11.2897567],
                [107.5207786, 11.2896221],
                [107.5208895, 11.2895542],
                [107.5210128, 11.2895542],
                [107.5210516, 11.2894822],
                [107.5210516, 11.28944],
                [107.5210114, 11.289387],
                [107.5209214, 11.2893585],
                [107.520805, 11.2893626],
                [107.5206442, 11.2894591],
                [107.5205777, 11.2894754],
                [107.5205154, 11.2894496],
                [107.5204461, 11.2893055],
                [107.5203879, 11.289209],
                [107.5203034, 11.2891683],
                [107.5200428, 11.2892743],
                [107.5199736, 11.2892811],
                [107.5199181, 11.2892471],
                [107.5199043, 11.2891669],
                [107.5199361, 11.2890894],
                [107.5199971, 11.2889998],
                [107.5204502, 11.2881355],
                [107.5206276, 11.28749],
                [107.5207315, 11.2870715],
                [107.5206844, 11.286835],
                [107.5206179, 11.2866625],
                [107.520532, 11.2866027],
                [107.5204267, 11.286585],
                [107.5203574, 11.2866108],
                [107.5202438, 11.2867372],
                [107.5202133, 11.2868092],
                [107.5202355, 11.286994],
                [107.5203726, 11.2872631],
                [107.5203893, 11.2873963],
                [107.5203477, 11.2874547],
                [107.5202895, 11.2874533],
                [107.5202105, 11.2873868],
                [107.5198757, 11.2870881],
                [107.5197729, 11.2870052],
                [107.5195587, 11.2867482],
                [107.5189776, 11.2860427],
                [107.5186608, 11.2855461],
                [107.5186082, 11.2853112],
                [107.5185821, 11.2851284],
                [107.5185826, 11.2848413],
                [107.5185038, 11.2844183],
                [107.5185365, 11.2840517],
                [107.518657, 11.2838202],
                [107.519181, 11.2831694],
                [107.5192943, 11.2828425],
                [107.5193245, 11.2823428],
                [107.5193342, 11.2821261],
                [107.5194309, 11.2819412],
                [107.5200172, 11.2814951],
                [107.520622, 11.2809861],
                [107.5209547, 11.2806621],
                [107.521201, 11.2804932],
                [107.5216326, 11.2804419],
                [107.5218053, 11.2803477],
                [107.5218456, 11.2802464],
                [107.5218044, 11.2800488],
                [107.5216992, 11.2797782],
                [107.5215573, 11.2796641],
                [107.5213416, 11.279658],
                [107.5209122, 11.2799295],
                [107.5207647, 11.2799262],
                [107.5206201, 11.2798562],
                [107.5205728, 11.2797713],
                [107.5205742, 11.2796687],
                [107.5206866, 11.2794176],
                [107.5209345, 11.2790896],
                [107.5210407, 11.278881],
                [107.5212665, 11.2783202],
                [107.5213998, 11.2777984],
                [107.5214008, 11.2771459],
                [107.521312, 11.2767179],
                [107.5210506, 11.2763156],
                [107.5210357, 11.2762858],
                [107.5210357, 11.2762391],
                [107.5210483, 11.27621],
                [107.5211761, 11.2760556],
                [107.5211865, 11.2760236],
                [107.5211775, 11.2759864],
                [107.5211397, 11.2759362],
                [107.5210845, 11.2758618],
                [107.5208398, 11.2756683],
                [107.5207323, 11.2755255],
                [107.5207942, 11.2752241],
                [107.5210033, 11.274732],
                [107.5210986, 11.2745181],
                [107.5212698, 11.2742775],
                [107.5214841, 11.2741093],
                [107.522077, 11.2736637],
                [107.5222304, 11.2735996],
                [107.5225209, 11.2735782],
                [107.5227705, 11.2735388],
                [107.5228793, 11.2734895],
                [107.523224, 11.2732286],
                [107.5234763, 11.2730125],
                [107.5235257, 11.2729479],
                [107.5235714, 11.2728386],
                [107.5235814, 11.2726682],
                [107.5237231, 11.2721285],
                [107.5237177, 11.2720388],
                [107.523682, 11.2718954],
                [107.5234946, 11.2715753],
                [107.5233227, 11.2713655],
                [107.5233145, 11.2713081],
                [107.5233492, 11.2711691],
                [107.5234013, 11.271083],
                [107.5233849, 11.2710131],
                [107.5233044, 11.2709396],
                [107.5231399, 11.2708912],
                [107.5231097, 11.2708598],
                [107.5230686, 11.2707881],
                [107.5230576, 11.2706518],
                [107.5230676, 11.2706061],
                [107.5230942, 11.2705765],
                [107.5232304, 11.2705684],
                [107.5233328, 11.2705451],
                [107.5237442, 11.2704016],
                [107.5237853, 11.2703658],
                [107.523789, 11.2703299],
                [107.5237378, 11.2702062],
                [107.5236994, 11.2700582],
                [107.5236665, 11.2700251],
                [107.5234461, 11.2699596],
                [107.5233538, 11.269922],
                [107.5232889, 11.2698547],
                [107.5232834, 11.2698171],
                [107.5232944, 11.2697695],
                [107.5235632, 11.2693123],
                [107.5236294, 11.2691925],
                [107.5236383, 11.2689291],
                [107.5236054, 11.2687477],
                [107.5235595, 11.268659],
                [107.5233135, 11.2685965],
                [107.5231077, 11.2684591],
                [107.5226488, 11.26776],
                [107.5226587, 11.2676893],
                [107.5227142, 11.2676211],
                [107.5233628, 11.267378],
                [107.5233977, 11.2672984],
                [107.5233837, 11.267218],
                [107.5232818, 11.2671078],
                [107.5230718, 11.2669298],
                [107.5229579, 11.2667097],
                [107.5229281, 11.2662395],
                [107.5229556, 11.2659898],
                [107.5230189, 11.2655327],
                [107.5230843, 11.2653436],
                [107.5231935, 11.2652302],
                [107.5235115, 11.2650902],
                [107.5237554, 11.2649534],
                [107.5238052, 11.2648673],
                [107.5238831, 11.2644645],
                [107.5239261, 11.264054],
                [107.5239718, 11.2637686],
                [107.5238831, 11.2633582],
                [107.5238776, 11.2631842],
                [107.5239399, 11.2629505],
                [107.5242267, 11.2624653],
                [107.5243127, 11.2622248],
                [107.5243321, 11.262112],
                [107.5243047, 11.2620035],
                [107.5242049, 11.2618809],
                [107.5241284, 11.2617093],
                [107.524081, 11.2614927],
                [107.524114, 11.2613259],
                [107.5242401, 11.2611579],
                [107.5244771, 11.2610625],
                [107.5250637, 11.2609884],
                [107.5251164, 11.260967],
                [107.5251519, 11.2609112],
                [107.5251599, 11.2608015],
                [107.5250584, 11.26058],
                [107.5248774, 11.260302],
                [107.5248847, 11.26025],
                [107.5249871, 11.2599488],
                [107.5249889, 11.2599084],
                [107.5249459, 11.2598412],
                [107.5248353, 11.2597434],
                [107.5246333, 11.2595372],
                [107.524562, 11.2594198],
                [107.5243892, 11.2589401],
                [107.5243352, 11.2588791],
                [107.5241268, 11.2587742],
                [107.5240445, 11.2587087],
                [107.5239293, 11.2585016],
                [107.5239129, 11.2583106],
                [107.5239357, 11.2581053],
                [107.5239668, 11.2580282],
                [107.5240226, 11.257943],
                [107.5241058, 11.2578838],
                [107.524156, 11.2578614],
                [107.5243087, 11.2578659],
                [107.5243709, 11.2578507],
                [107.5244166, 11.2578121],
                [107.5244632, 11.2576982],
                [107.5244705, 11.2575091],
                [107.5244266, 11.2573755],
                [107.5243398, 11.257258],
                [107.5241953, 11.2571325],
                [107.5239083, 11.2569711],
                [107.5235097, 11.2566985],
                [107.5234274, 11.25659],
                [107.5233561, 11.2564161],
                [107.5233442, 11.2563345],
                [107.5233808, 11.2560063],
                [107.5233826, 11.2556772],
                [107.5234119, 11.25554],
                [107.5235327, 11.2553228],
                [107.5236005, 11.2552027],
                [107.5236148, 11.2551088],
                [107.5235654, 11.2549098],
                [107.5235759, 11.2548073],
                [107.5237051, 11.2544747],
                [107.5236842, 11.2543272],
                [107.5237331, 11.2542383],
                [107.5241009, 11.2538878],
                [107.5241997, 11.2537485],
                [107.5242152, 11.2536695],
                [107.524223, 11.2533268],
                [107.524279, 11.253109],
                [107.5243154, 11.2530311],
                [107.5243199, 11.2528226],
                [107.5243362, 11.2527468],
                [107.52438, 11.2526881],
                [107.5246758, 11.2523463],
                [107.524701, 11.2522379],
                [107.5245225, 11.2518413],
                [107.5245099, 11.2517472],
                [107.5239793, 11.2518172],
                [107.5225465, 11.2516909],
                [107.521369, 11.2511552],
                [107.5205237, 11.2499983],
                [107.520103, 11.2491546],
                [107.5198204, 11.2481701],
                [107.519396, 11.2479451],
                [107.518811, 11.2483102],
                [107.5185128, 11.2490315],
                [107.518135, 11.2513849],
                [107.5177668, 11.2518663],
                [107.5173699, 11.2519555],
                [107.5169526, 11.2517467],
                [107.5165519, 11.2510947],
                [107.5162097, 11.2500879],
                [107.5164078, 11.2486351],
                [107.5162621, 11.2474156],
                [107.5156721, 11.2464954],
                [107.5144778, 11.244846],
                [107.5135569, 11.244133],
                [107.5125732, 11.24377],
                [107.5114578, 11.2437509],
                [107.5100315, 11.2440638],
                [107.5094763, 11.2438341],
                [107.5091776, 11.243257],
                [107.5087262, 11.2422887],
                [107.5083497, 11.2406915],
                [107.5082469, 11.2396831],
                [107.5084945, 11.2381325],
                [107.5083213, 11.237423],
                [107.5077812, 11.2371072],
                [107.5071084, 11.2370594],
                [107.5058644, 11.23693],
                [107.5047738, 11.2366191],
                [107.5040655, 11.2361438],
                [107.5030368, 11.2354937],
                [107.5024909, 11.2353377],
                [107.5021917, 11.2353998],
                [107.5018394, 11.2356233],
                [107.5014979, 11.2361562],
                [107.5011166, 11.2389191],
                [107.5008899, 11.241819],
                [107.5005349, 11.242551],
                [107.49981, 11.2430931],
                [107.4987723, 11.2432012],
                [107.4978504, 11.2430384],
                [107.4971582, 11.2426186],
                [107.4968044, 11.2420847],
                [107.4962677, 11.2403853],
                [107.4959571, 11.2391299],
                [107.4958722, 11.2368456],
                [107.4960407, 11.2355239],
                [107.4966209, 11.2342663],
                [107.4983119, 11.2318201],
                [107.4991069, 11.2309934],
                [107.4996678, 11.2294471],
                [107.4996678, 11.2286944],
                [107.4992153, 11.2279417],
                [107.4983853, 11.2270709],
                [107.4972458, 11.2263888],
                [107.4961596, 11.226106],
                [107.4937866, 11.2256257],
                [107.4933874, 11.2252937],
                [107.4932781, 11.2248072],
                [107.4936111, 11.2243688],
                [107.4946823, 11.2234379],
                [107.4956388, 11.2228124],
                [107.4982913, 11.2217362],
                [107.4988133, 11.2210173],
                [107.4988238, 11.2204134],
                [107.4983574, 11.2198543],
                [107.4975966, 11.2197449],
                [107.496305, 11.2201041],
                [107.4945577, 11.2207351],
                [107.4928571, 11.222422],
                [107.4918146, 11.2242813],
                [107.4910662, 11.2249348],
                [107.4903436, 11.2250234],
                [107.4894112, 11.2248876],
                [107.4879819, 11.2241087],
                [107.4871337, 11.2233042],
                [107.4865484, 11.2223598],
                [107.4858409, 11.2214661],
                [107.4851235, 11.2211287],
                [107.4843038, 11.2209181],
                [107.4828411, 11.2210837],
                [107.4825353, 11.2208548],
                [107.4825051, 11.2205656],
                [107.4827352, 11.2203473],
                [107.4842219, 11.2204207],
                [107.4855192, 11.2200599],
                [107.4866427, 11.2193188],
                [107.4874023, 11.2183097],
                [107.4882221, 11.2170704],
                [107.4882528, 11.2165688],
                [107.4880219, 11.2158501],
                [107.4861687, 11.2144938],
                [107.484863, 11.2135657],
                [107.4841828, 11.212935],
                [107.4836709, 11.2120769],
                [107.4832954, 11.2110097],
                [107.4824899, 11.2104026],
                [107.4820985, 11.210232],
                [107.4816136, 11.2100206],
                [107.480483, 11.2099447],
                [107.4788032, 11.2100644],
                [107.4783741, 11.2098287],
                [107.4781164, 11.2092642],
                [107.4784378, 11.2086251],
                [107.4796756, 11.2080409],
                [107.4831099, 11.2062771],
                [107.4837422, 11.2057714],
                [107.4842555, 11.2050451],
                [107.4842771, 11.2044129],
                [107.4832235, 11.201975],
                [107.4821355, 11.2000558],
                [107.4811265, 11.1989434],
                [107.4800914, 11.1982412],
                [107.4792496, 11.1980151],
                [107.4787185, 11.1982861],
                [107.4785275, 11.1987857],
                [107.4789299, 11.2003274],
                [107.4789393, 11.2015813],
                [107.4787322, 11.2022966],
                [107.4781385, 11.2027051],
                [107.4772001, 11.2027864],
                [107.4761242, 11.2026854],
                [107.4743968, 11.2021881],
                [107.4739275, 11.201896],
                [107.4733577, 11.2010756],
                [107.473183, 11.2002907],
                [107.4732012, 11.199602],
                [107.4734138, 11.1991936],
                [107.4747506, 11.1979752],
                [107.4760494, 11.1957081],
                [107.4764265, 11.1948735],
                [107.4765975, 11.1931401],
                [107.4768373, 11.1925338],
                [107.4772904, 11.1918171],
                [107.4779866, 11.191289],
                [107.4790808, 11.1906431],
                [107.479214, 11.1902511],
                [107.4788384, 11.1899862],
                [107.4778698, 11.1900156],
                [107.4769079, 11.1905452],
                [107.4764001, 11.1911775],
                [107.4754652, 11.1928542],
                [107.4747114, 11.1934494],
                [107.4738618, 11.1937272],
                [107.4727034, 11.1935545],
                [107.4716183, 11.1931633],
                [107.4710445, 11.1925915],
                [107.4707853, 11.1919865],
                [107.4711104, 11.1904104],
                [107.4719376, 11.1887314],
                [107.4721016, 11.1872552],
                [107.471927, 11.1860465],
                [107.4713511, 11.1851176],
                [107.4704621, 11.1843972],
                [107.4696079, 11.1839116],
                [107.4677018, 11.1836091],
                [107.4675347, 11.1832267],
                [107.4677086, 11.1828531],
                [107.4682108, 11.1825452],
                [107.4702542, 11.1818676],
                [107.4706836, 11.1810643],
                [107.4710122, 11.179225],
                [107.4707121, 11.1783909],
                [107.4700814, 11.1779219],
                [107.4693448, 11.1779319],
                [107.4689163, 11.1782221],
                [107.4684432, 11.1787792],
                [107.4674708, 11.1793719],
                [107.4664925, 11.1802393],
                [107.4659815, 11.1803838],
                [107.4655745, 11.1801882],
                [107.465408, 11.1797496],
                [107.4651873, 11.1786766],
                [107.4648664, 11.1782602],
                [107.4632597, 11.1777317],
                [107.460618, 11.1767391],
                [107.4602254, 11.1758747],
                [107.4600676, 11.175174],
                [107.4599552, 11.1743195],
                [107.4599218, 11.1738585],
                [107.4600145, 11.1730942],
                [107.4601607, 11.172043],
                [107.4606474, 11.1708545],
                [107.4611668, 11.1700399],
                [107.4614743, 11.1695642],
                [107.4616543, 11.169247],
                [107.4622049, 11.1683704],
                [107.4621536, 11.1680595],
                [107.4619958, 11.1676455],
                [107.4617233, 11.1671278],
                [107.4612458, 11.16652],
                [107.4598812, 11.1650786],
                [107.4594944, 11.1647181],
                [107.4592217, 11.1643354],
                [107.4587899, 11.1636601],
                [107.4582901, 11.1628273],
                [107.458052, 11.1622085],
                [107.457734, 11.1616234],
                [107.4574838, 11.1613531],
                [107.4573018, 11.161173],
                [107.4571197, 11.1610602],
                [107.4568921, 11.1609699],
                [107.4566415, 11.160902],
                [107.4563226, 11.1608115],
                [107.4559354, 11.1607208],
                [107.4555936, 11.1606753],
                [107.4552064, 11.1605397],
                [107.454956, 11.1604268],
                [107.454797, 11.1601791],
                [107.4547063, 11.1599091],
                [107.4546612, 11.1596842],
                [107.4546616, 11.1594145],
                [107.4547306, 11.1590998],
                [107.4548223, 11.1587852],
                [107.4551887, 11.1578415],
                [107.4555443, 11.1565718],
                [107.4556819, 11.1560775],
                [107.4558648, 11.1557405],
                [107.4561161, 11.1554488],
                [107.4564585, 11.1551346],
                [107.4568236, 11.1548655],
                [107.4570747, 11.1546636],
                [107.4574626, 11.1543719],
                [107.4577594, 11.1540801],
                [107.4578964, 11.1539231],
                [107.4580336, 11.1536984],
                [107.4581481, 11.1533614],
                [107.458194, 11.1531816],
                [107.4581946, 11.1528444],
                [107.4580932, 11.1522484],
                [107.4577301, 11.1514158],
                [107.4573664, 11.1509655],
                [107.4571162, 11.1506727],
                [107.4568433, 11.1504024],
                [107.4564792, 11.1501095],
                [107.4562744, 11.1499742],
                [107.45591, 11.1498612],
                [107.4551811, 11.1497023],
                [107.4546346, 11.149499],
                [107.4537695, 11.1490478],
                [107.4525631, 11.1482812],
                [107.4516296, 11.1478523],
                [107.4508442, 11.1474799],
                [107.4500015, 11.147231],
                [107.4495004, 11.1470951],
                [107.4491814, 11.1470946],
                [107.4489535, 11.1470942],
                [107.4486116, 11.1471385],
                [107.4484292, 11.1472281],
                [107.4482465, 11.1474301],
                [107.448041, 11.1476546],
                [107.4475615, 11.1481484],
                [107.4467169, 11.1489563],
                [107.4464296, 11.1490858],
                [107.4459923, 11.1492622],
                [107.4456678, 11.1494039],
                [107.4454399, 11.149381],
                [107.445144, 11.1492456],
                [107.4448938, 11.1489753],
                [107.444803, 11.1487953],
                [107.4447123, 11.1485253],
                [107.4447126, 11.1483454],
                [107.4447359, 11.1481207],
                [107.4448047, 11.1478735],
                [107.4450338, 11.1472218],
                [107.4451257, 11.1468172],
                [107.4452405, 11.1464128],
                [107.4452867, 11.1460532],
                [107.4452648, 11.1456034],
                [107.4451942, 11.1453344],
                [107.4450933, 11.1452146],
                [107.4447093, 11.1450344],
                [107.4436578, 11.144813],
                [107.4434297, 11.1446465],
                [107.4433057, 11.1444419],
                [107.4432342, 11.144154],
                [107.4432554, 11.1436355],
                [107.443378, 11.1430574],
                [107.4433785, 11.142778],
                [107.4429836, 11.140889],
                [107.4427572, 11.140169],
                [107.4424621, 11.1395164],
                [107.4421438, 11.1391561],
                [107.4418707, 11.1389308],
                [107.4415064, 11.1388176],
                [107.4410964, 11.1387719],
                [107.4405725, 11.1386585],
                [107.4395469, 11.1387691],
                [107.4384297, 11.1390817],
                [107.4379168, 11.1391595],
                [107.4376889, 11.1391591],
                [107.437484, 11.1390913],
                [107.4372563, 11.1390009],
                [107.4369604, 11.1388204],
                [107.4367102, 11.1386176],
                [107.43646, 11.1383923],
                [107.4362325, 11.1381895],
                [107.4359138, 11.1380091],
                [107.4355722, 11.137896],
                [107.4352762, 11.1378054],
                [107.4349573, 11.137715],
                [107.4347525, 11.1375797],
                [107.4346161, 11.137422],
                [107.4345481, 11.137242],
                [107.4345031, 11.1369496],
                [107.4345729, 11.1361854],
                [107.4347114, 11.1352637],
                [107.4348488, 11.1349267],
                [107.4349861, 11.1346347],
                [107.4350029, 11.1346139],
                [107.4352532, 11.1341641],
                [107.4354133, 11.1337246],
                [107.4354456, 11.1334735],
                [107.4354464, 11.1330965],
                [107.435448, 11.1322485],
                [107.4354407, 11.1317079],
                [107.4354634, 11.131132],
                [107.435486, 11.1306201],
                [107.4355515, 11.1303002],
                [107.4357904, 11.1297889],
                [107.4367773, 11.1284168],
                [107.4372436, 11.1278719],
                [107.4376554, 11.1274674],
                [107.4379371, 11.1271692],
                [107.438089, 11.1269562],
                [107.4383711, 11.1264875],
                [107.4385883, 11.1260186],
                [107.4387836, 11.1256991],
                [107.4389788, 11.1254862],
                [107.4392602, 11.1253587],
                [107.439455, 11.1253164],
                [107.439628, 11.125338],
                [107.4398226, 11.1253811],
                [107.4400173, 11.1254028],
                [107.440256, 11.1254116],
                [107.4406787, 11.1253603],
                [107.4412486, 11.1253614],
                [107.44175, 11.1253848],
                [107.4421147, 11.1254305],
                [107.4425478, 11.1254762],
                [107.4433684, 11.1254778],
                [107.4437103, 11.1254558],
                [107.444121, 11.1252993],
                [107.4444861, 11.1250527],
                [107.4454912, 11.1239982],
                [107.4462454, 11.1229431],
                [107.446577, 11.1223517],
                [107.4468057, 11.1219697],
                [107.4469658, 11.1217],
                [107.4471258, 11.121453],
                [107.4473087, 11.1212283],
                [107.4475826, 11.120959],
                [107.4478566, 11.120712],
                [107.4489752, 11.1199043],
                [107.4496142, 11.1194781],
                [107.4500936, 11.1191191],
                [107.4502232, 11.1190033],
                [107.4503447, 11.1188947],
                [107.4505732, 11.118625],
                [107.4507332, 11.1184005],
                [107.4508822, 11.1179846],
                [107.4509053, 11.1177597],
                [107.4509288, 11.1173774],
                [107.4509295, 11.1170625],
                [107.45093, 11.1167701],
                [107.4509077, 11.1165],
                [107.4508854, 11.1162302],
                [107.4508404, 11.1159602],
                [107.4508181, 11.1156901],
                [107.4507955, 11.1155552],
                [107.4508416, 11.1153304],
                [107.4511158, 11.114971],
                [107.4516179, 11.1146569],
                [107.4520057, 11.1144327],
                [107.4523937, 11.1142085],
                [107.4527588, 11.1139842],
                [107.4530326, 11.1138272],
                [107.4532838, 11.1135804],
                [107.4534667, 11.1133332],
                [107.4536041, 11.112951],
                [107.4537535, 11.1123328],
                [107.4537768, 11.1119954],
                [107.4538232, 11.1116356],
                [107.4538927, 11.1110509],
                [107.4538932, 11.110736],
                [107.4538708, 11.110511],
                [107.453803, 11.1101959],
                [107.4537349, 11.1100833],
                [107.4535756, 11.1099256],
                [107.4533479, 11.1098128],
                [107.4530746, 11.1096998],
                [107.4527328, 11.1095867],
                [107.4522773, 11.1093834],
                [107.4520269, 11.1092255],
                [107.4518221, 11.1090453],
                [107.4516856, 11.1088875],
                [107.4515265, 11.1086398],
                [107.4513457, 11.1078072],
                [107.4511986, 11.1072333],
                [107.4508583, 11.1063779],
                [107.4505859, 11.1057252],
                [107.4503587, 11.1053648],
                [107.4501314, 11.1050271],
                [107.4499039, 11.1047567],
                [107.4496995, 11.1043965],
                [107.4495632, 11.1041263],
                [107.4494726, 11.1038112],
                [107.4493371, 11.1032036],
                [107.4493381, 11.1026412],
                [107.4493736, 11.1019328],
                [107.4493744, 11.1015359],
                [107.4493748, 11.1013029],
                [107.4493304, 11.1006506],
                [107.4493541, 11.1001783],
                [107.4493548, 11.0998184],
                [107.4493331, 11.099211],
                [107.4493109, 11.098851],
                [107.4492204, 11.098536],
                [107.4491525, 11.0982885],
                [107.4490052, 11.0978496],
                [107.4486415, 11.0972866],
                [107.4484371, 11.0969038],
                [107.4480962, 11.0963633],
                [107.4477096, 11.0959128],
                [107.4474821, 11.0956649],
                [107.4473001, 11.0954622],
                [107.4470955, 11.0951919],
                [107.4469592, 11.0949442],
                [107.4468229, 11.094674],
                [107.4467328, 11.0941565],
                [107.446666, 11.0932791],
                [107.4465761, 11.0926041],
                [107.4463608, 11.0919178],
                [107.4462474, 11.0916251],
                [107.4460162, 11.0914123],
                [107.445929, 11.0913321],
                [107.4455646, 11.0911065],
                [107.4452686, 11.0909485],
                [107.4449726, 11.090813],
                [107.4446536, 11.0907225],
                [107.4442665, 11.0905867],
                [107.444107, 11.0904965],
                [107.444016, 11.0904289],
                [107.4438113, 11.0902485],
                [107.4436748, 11.0900908],
                [107.4435383, 11.0899331],
                [107.4433339, 11.0895503],
                [107.4432436, 11.0890778],
                [107.4432215, 11.0887403],
                [107.443406, 11.087571],
                [107.4434874, 11.0867502],
                [107.4434433, 11.0859628],
                [107.4433528, 11.0855803],
                [107.4432852, 11.0851978],
                [107.4431948, 11.0847927],
                [107.4431042, 11.0844551],
                [107.4429908, 11.0841851],
                [107.4428543, 11.0840272],
                [107.442604, 11.083802],
                [107.4423765, 11.0835991],
                [107.4421259, 11.0835086],
                [107.4418525, 11.0834631],
                [107.441579, 11.0834626],
                [107.4413967, 11.0834623],
                [107.4411914, 11.0835519],
                [107.4409175, 11.0837087],
                [107.4403468, 11.0842027],
                [107.439981, 11.0847642],
                [107.4398209, 11.0850788],
                [107.4396603, 11.0856184],
                [107.4396138, 11.0860682],
                [107.4396126, 11.0867205],
                [107.4395894, 11.0869454],
                [107.4394746, 11.087395],
                [107.4391771, 11.0880017],
                [107.4387653, 11.0888108],
                [107.4386052, 11.0891029],
                [107.438468, 11.0893501],
                [107.4383309, 11.0895073],
                [107.4381483, 11.089642],
                [107.437852, 11.0896639],
                [107.4375331, 11.0895508],
                [107.4371917, 11.0893027],
                [107.4369414, 11.0890323],
                [107.4366685, 11.088762],
                [107.4363499, 11.0884689],
                [107.4360995, 11.0883109],
                [107.435496, 11.0880511],
                [107.4347668, 11.0879598],
                [107.4342653, 11.0879814],
                [107.4339235, 11.0879808],
                [107.4335131, 11.088025],
                [107.4331028, 11.0880467],
                [107.4326469, 11.0880458],
                [107.4322595, 11.0880002],
                [107.4318951, 11.0879095],
                [107.4316445, 11.087819],
                [107.4313712, 11.087706],
                [107.4311436, 11.0875257],
                [107.4308932, 11.0873452],
                [107.4304837, 11.0869396],
                [107.4303701, 11.0867594],
                [107.4303021, 11.0865568],
                [107.4303024, 11.0864219],
                [107.4303255, 11.086287],
                [107.4303943, 11.0860846],
                [107.4304857, 11.0859498],
                [107.4309426, 11.0853884],
                [107.4319707, 11.0841308],
                [107.4320852, 11.0838835],
                [107.4321313, 11.0836137],
                [107.4321889, 11.0832651],
                [107.4321893, 11.0830402],
                [107.4321214, 11.0828151],
                [107.4319397, 11.0824999],
                [107.4311661, 11.0818012],
                [107.4306882, 11.0814403],
                [107.4302329, 11.081147],
                [107.4299142, 11.0809441],
                [107.4296639, 11.0807186],
                [107.4295274, 11.0805834],
                [107.429391, 11.0804256],
                [107.4291868, 11.0799529],
                [107.4289602, 11.0792777],
                [107.4287103, 11.0788049],
                [107.428574, 11.0785797],
                [107.4283239, 11.0783093],
                [107.4280963, 11.0781289],
                [107.4278686, 11.0780161],
                [107.4275043, 11.0778572],
                [107.4268204, 11.0778335],
                [107.4256574, 11.0780111],
                [107.4249961, 11.0780997],
                [107.4246543, 11.0780767],
                [107.4243353, 11.0779861],
                [107.4239937, 11.0777831],
                [107.4236977, 11.0775803],
                [107.4232881, 11.0772197],
                [107.4231062, 11.0770171],
                [107.4229014, 11.0767693],
                [107.4226971, 11.0763643],
                [107.422516, 11.0757345],
                [107.4224709, 11.0754646],
                [107.4224716, 11.0751274],
                [107.4224721, 11.0748577],
                [107.4224958, 11.0743631],
                [107.422542, 11.0740934],
                [107.4226566, 11.0737563],
                [107.4229084, 11.0732397],
                [107.4235941, 11.0722968],
                [107.4240512, 11.0717132],
                [107.4250911, 11.0703355],
                [107.4253655, 11.0698636],
                [107.4255946, 11.0693466],
                [107.4256861, 11.0691217],
                [107.4258467, 11.0686271],
                [107.4259614, 11.0682674],
                [107.4260304, 11.0679525],
                [107.4260765, 11.0677051],
                [107.4260999, 11.0673677],
                [107.4261357, 11.066569],
                [107.4260462, 11.0656915],
                [107.4259496, 11.065106],
                [107.425762, 11.0642116],
                [107.4256702, 11.0639714],
                [107.4253726, 11.0631926],
                [107.4249583, 11.0624497],
                [107.424322, 11.061436],
                [107.4237196, 11.0605237],
                [107.4230829, 11.0597801],
                [107.4219, 11.0585403],
                [107.4213767, 11.0579995],
                [107.4210582, 11.0577288],
                [107.420785, 11.0575033],
                [107.4203979, 11.0573001],
                [107.4200791, 11.0571645],
                [107.4197373, 11.0570514],
                [107.4194184, 11.0569383],
                [107.4191452, 11.0568252],
                [107.418849, 11.0566897],
                [107.4185759, 11.0564867],
                [107.4182121, 11.055991],
                [107.4178261, 11.0552704],
                [107.4174404, 11.0543021],
                [107.4169864, 11.0533789],
                [107.4167597, 11.052726],
                [107.4167376, 11.0523885],
                [107.4167154, 11.0520509],
                [107.4166934, 11.0516909],
                [107.416694, 11.051421],
                [107.4166264, 11.0509934],
                [107.4165558, 11.0507982],
                [107.4161033, 11.0504971],
                [107.4157629, 11.0503194],
                [107.4148957, 11.0501126],
                [107.4143714, 11.0501341],
                [107.4138241, 11.0502455],
                [107.4131626, 11.0504692],
                [107.4126835, 11.0506708],
                [107.4122502, 11.0507824],
                [107.4117258, 11.0508488],
                [107.4111332, 11.0508702],
                [107.4106317, 11.0508692],
                [107.4102442, 11.050801],
                [107.4098568, 11.0507552],
                [107.4094922, 11.0507095],
                [107.4092871, 11.0506867],
                [107.4090819, 11.0507087],
                [107.4087396, 11.0508881],
                [107.4079407, 11.0514714],
                [107.4071419, 11.0519423],
                [107.4064118, 11.0522559],
                [107.4059784, 11.052435],
                [107.4056134, 11.0525468],
                [107.4048379, 11.0527928],
                [107.4039144, 11.0529372],
                [107.4029339, 11.0531152],
                [107.4015201, 11.0533599],
                [107.4008133, 11.0534711],
                [107.4004485, 11.0535153],
                [107.3999925, 11.0535144],
                [107.3996507, 11.0534687],
                [107.3992178, 11.0533554],
                [107.3986485, 11.0531292],
                [107.3979427, 11.0526553],
                [107.3971009, 11.0519337],
                [107.3966345, 11.0514716],
                [107.3963386, 11.0512235],
                [107.39602, 11.0509979],
                [107.3956558, 11.0507722],
                [107.3953142, 11.050569],
                [107.3951322, 11.0504337],
                [107.3947908, 11.0501406],
                [107.3944268, 11.0498023],
                [107.3941993, 11.0495544],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [108.6011946, 14.4597295],
                [108.6012149, 14.4595204],
                [108.6012688, 14.459317],
                [108.6013548, 14.4591243],
                [108.6014759, 14.4589405],
                [108.6015504, 14.4588533],
                [108.6016323, 14.4587725],
                [108.6017209, 14.4586988],
                [108.6018156, 14.4586325],
                [108.6019158, 14.4585742],
                [108.6020207, 14.4585243],
                [108.6021502, 14.4584443],
                [108.6023972, 14.4582675],
                [108.6026445, 14.4580528],
                [108.6027682, 14.457928],
                [108.6028849, 14.457797],
                [108.6029943, 14.4576603],
                [108.6030962, 14.4575182],
                [108.6031902, 14.4573712],
                [108.604424, 14.4553453],
                [108.6057758, 14.4522597],
                [108.606612, 14.4499581],
                [108.6067062, 14.4497379],
                [108.6068093, 14.4495214],
                [108.6069213, 14.4493091],
                [108.6070418, 14.4491013],
                [108.6071716, 14.4488971],
                [108.6073096, 14.448698],
                [108.6074558, 14.4485045],
                [108.607942, 14.4479315],
                [108.6081061, 14.4477229],
                [108.6084139, 14.4472915],
                [108.6086938, 14.4468427],
                [108.6089446, 14.446378],
                [108.6090588, 14.4461403],
                [108.6092142, 14.4458757],
                [108.6095415, 14.4453561],
                [108.6097133, 14.4451013],
                [108.6101147, 14.4445471],
                [108.6103251, 14.4442769],
                [108.6107647, 14.4437506],
                [108.6109174, 14.4433219],
                [108.6110055, 14.4431118],
                [108.6112046, 14.4427013],
                [108.6113197, 14.442494],
                [108.6115732, 14.4420926],
                [108.6117113, 14.441899],
                [108.6120093, 14.4415272],
                [108.612086, 14.4414152],
                [108.6121547, 14.4412983],
                [108.612215, 14.4411772],
                [108.6122668, 14.4410525],
                [108.6123097, 14.4409246],
                [108.6126208, 14.4402908],
                [108.6135113, 14.4388986],
                [108.6136165, 14.4387026],
                [108.6136886, 14.4384931],
                [108.6137259, 14.4382752],
                [108.6137275, 14.4380558],
                [108.6136937, 14.4378388],
                [108.6132967, 14.4371946],
                [108.6129743, 14.4370273],
                [108.6128183, 14.4369348],
                [108.612518, 14.4367326],
                [108.6122256, 14.4365008],
                [108.6119449, 14.4362387],
                [108.6102363, 14.4352491],
                [108.6097079, 14.4351893],
                [108.6093378, 14.4348257],
                [108.609513, 14.4345886],
                [108.6096609, 14.4343347],
                [108.609724, 14.4342023],
                [108.609819, 14.4339558],
                [108.6099226, 14.4337127],
                [108.6100348, 14.4334731],
                [108.6101555, 14.4332375],
                [108.6102844, 14.4330059],
                [108.6105515, 14.4325785],
                [108.6108306, 14.43219],
                [108.6109793, 14.4320021],
                [108.6110427, 14.4319046],
                [108.6110974, 14.4318022],
                [108.6111429, 14.4316956],
                [108.6111982, 14.4315085],
                [108.6112154, 14.4314125],
                [108.6112796, 14.4307837],
                [108.6113167, 14.4306047],
                [108.6113816, 14.4304332],
                [108.6114309, 14.4303396],
                [108.6114885, 14.4302506],
                [108.6115539, 14.4301669],
                [108.6116267, 14.430089],
                [108.6117062, 14.4300176],
                [108.6119632, 14.4298491],
                [108.6124921, 14.4295344],
                [108.6129699, 14.4292842],
                [108.6132136, 14.4291682],
                [108.613208, 14.4287401],
                [108.6131921, 14.4285266],
                [108.6131404, 14.4281358],
                [108.6131036, 14.4279421],
                [108.6112475, 14.42569],
                [108.6111843, 14.4255737],
                [108.6111644, 14.4255107],
                [108.6111494, 14.4254002],
                [108.611151, 14.4253445],
                [108.6113444, 14.4251789],
                [108.611555, 14.4250343],
                [108.6117926, 14.4249065],
                [108.6119229, 14.42485],
                [108.6121926, 14.4247597],
                [108.6123311, 14.4247262],
                [108.6136401, 14.4243002],
                [108.6138591, 14.424203],
                [108.614074, 14.4240973],
                [108.6142843, 14.4239833],
                [108.6144898, 14.4238613],
                [108.61469, 14.4237313],
                [108.6148846, 14.4235937],
                [108.6150749, 14.4234473],
                [108.6152589, 14.4232937],
                [108.6154363, 14.423133],
                [108.6156069, 14.4229654],
                [108.6157703, 14.4227913],
                [108.6159263, 14.4226109],
                [108.6160746, 14.4224245],
                [108.616215, 14.4222325],
                [108.6173952, 14.4199569],
                [108.6174488, 14.419601],
                [108.6175775, 14.4190529],
                [108.6179041, 14.418877],
                [108.6182159, 14.4186774],
                [108.618511, 14.4184554],
                [108.6187924, 14.4182077],
                [108.6190531, 14.4179396],
                [108.6192915, 14.4176527],
                [108.6199057, 14.4167877],
                [108.6197687, 14.4164626],
                [108.6196899, 14.4163045],
                [108.6195127, 14.4159982],
                [108.6194146, 14.4158506],
                [108.6192318, 14.4156067],
                [108.6190552, 14.4154225],
                [108.6189578, 14.4153393],
                [108.618747, 14.4151926],
                [108.6185409, 14.4150846],
                [108.618323, 14.4150012],
                [108.6178597, 14.4148774],
                [108.6176878, 14.4148204],
                [108.6173531, 14.4146853],
                [108.6170327, 14.4145228],
                [108.6168782, 14.4144316],
                [108.61645, 14.4139111],
                [108.6161883, 14.4136373],
                [108.6157061, 14.4131202],
                [108.6150846, 14.4126692],
                [108.6147677, 14.4122396],
                [108.6150296, 14.4119398],
                [108.6153487, 14.4116122],
                [108.6159301, 14.4112486],
                [108.6164364, 14.410976],
                [108.6169139, 14.410741],
                [108.6172821, 14.4106222],
                [108.617497, 14.4104434],
                [108.6179894, 14.4093693],
                [108.6191586, 14.4069824],
                [108.6202665, 14.4045655],
                [108.6211748, 14.4022828],
                [108.6218505, 14.4008794],
                [108.6240047, 14.396436],
                [108.6251737, 14.3942593],
                [108.6272181, 14.391117],
                [108.628478, 14.3895659],
                [108.6287702, 14.3890437],
                [108.6288935, 14.3886556],
                [108.6286793, 14.3883865],
                [108.628036, 14.3879674],
                [108.6270861, 14.3875179],
                [108.6262896, 14.3870986],
                [108.6258608, 14.3867992],
                [108.6255239, 14.3864999],
                [108.6254631, 14.3861714],
                [108.6254637, 14.3858428],
                [108.6256789, 14.3854848],
                [108.6262164, 14.384963],
                [108.6263896, 14.3845155],
                [108.6269114, 14.3838747],
                [108.6276392, 14.3828152],
                [108.6288758, 14.3813322],
                [108.6295309, 14.3803433],
                [108.6296777, 14.3793536],
                [108.6302603, 14.3782939],
                [108.6308631, 14.377533],
                [108.6313866, 14.3765982],
                [108.6326325, 14.3747602],
                [108.6337287, 14.3732124],
                [108.6342772, 14.3721964],
                [108.6342779, 14.3717606],
                [108.6338816, 14.3708885],
                [108.6321449, 14.368949],
                [108.6314749, 14.3682457],
                [108.6309293, 14.3675186],
                [108.6301863, 14.3657743],
                [108.6295422, 14.3645143],
                [108.6288979, 14.3633512],
                [108.627559, 14.3613637],
                [108.6267405, 14.3603215],
                [108.6260956, 14.3594488],
                [108.6257487, 14.3588188],
                [108.6254137, 14.3583777],
                [108.6245844, 14.3572206],
                [108.6237558, 14.3557169],
                [108.6244697, 14.3547934],
                [108.6258971, 14.3532932],
                [108.6272258, 14.3524053],
                [108.6276738, 14.3520187],
                [108.6290169, 14.3514882],
                [108.6308073, 14.3509584],
                [108.6318521, 14.3503789],
                [108.632748, 14.3496055],
                [108.6338439, 14.3482513],
                [108.6342428, 14.3474773],
                [108.6345925, 14.3464125],
                [108.6345948, 14.3450084],
                [108.6348204, 14.3438223],
                [108.6351705, 14.3424186],
                [108.6361703, 14.3389823],
                [108.6371684, 14.3364659],
                [108.6390384, 14.3327645],
                [108.6397364, 14.3314098],
                [108.6398866, 14.3306836],
                [108.6400877, 14.3292797],
                [108.6403131, 14.3281906],
                [108.6406123, 14.3275616],
                [108.6416082, 14.3263525],
                [108.6426037, 14.3255308],
                [108.6442454, 14.3245165],
                [108.6449423, 14.323888],
                [108.6462865, 14.3224858],
                [108.6470332, 14.3217122],
                [108.6474565, 14.321156],
                [108.6478059, 14.3202849],
                [108.6479564, 14.3193652],
                [108.6478582, 14.3185902],
                [108.6474615, 14.3179601],
                [108.6454765, 14.3160688],
                [108.6440876, 14.3142751],
                [108.6433186, 14.3134266],
                [108.6429221, 14.3127965],
                [108.6427741, 14.3120699],
                [108.6430738, 14.3111504],
                [108.6437717, 14.3097472],
                [108.6442205, 14.3087794],
                [108.6447184, 14.3082476],
                [108.6454151, 14.3077159],
                [108.6460123, 14.3071358],
                [108.6464856, 14.3064344],
                [108.6466361, 14.3055146],
                [108.6466401, 14.3029483],
                [108.6465952, 14.2998492],
                [108.6465714, 14.2991954],
                [108.6466722, 14.2983241],
                [108.6469221, 14.2974043],
                [108.6473704, 14.2967272],
                [108.6481668, 14.2959536],
                [108.6495099, 14.295084],
                [108.649597, 14.2950478],
                [108.650026, 14.2948694],
                [108.6526428, 14.2937812],
                [108.6532896, 14.2932979],
                [108.6537377, 14.2928143],
                [108.6548083, 14.2913875],
                [108.6552569, 14.290468],
                [108.6557554, 14.289452],
                [108.6560545, 14.2888229],
                [108.6561052, 14.2881936],
                [108.6556102, 14.2868853],
                [108.6554131, 14.2857714],
                [108.6555383, 14.2851663],
                [108.6559864, 14.2845859],
                [108.6572014, 14.2835794],
                [108.6593202, 14.2817338],
                [108.6602657, 14.280912],
                [108.6604648, 14.2806701],
                [108.6604654, 14.2802828],
                [108.6600697, 14.2790717],
                [108.6592769, 14.2775695],
                [108.6589551, 14.2766732],
                [108.6584601, 14.275462],
                [108.6580136, 14.2749287],
                [108.6572191, 14.2745402],
                [108.6559275, 14.2743446],
                [108.6556794, 14.2741022],
                [108.6555314, 14.2733756],
                [108.6554843, 14.2716323],
                [108.6552874, 14.2704215],
                [108.6549656, 14.2696706],
                [108.6547679, 14.268944],
                [108.6549181, 14.2682178],
                [108.6554174, 14.266669],
                [108.656712, 14.2649277],
                [108.6568619, 14.2643469],
                [108.6566651, 14.2630876],
                [108.6560962, 14.2613678],
                [108.6556013, 14.2600597],
                [108.655602, 14.2596239],
                [108.6559011, 14.2589464],
                [108.6567477, 14.2577372],
                [108.6572459, 14.2568662],
                [108.6578939, 14.2555114],
                [108.6584164, 14.2550037],
                [108.6594611, 14.2541336],
                [108.6612019, 14.2529739],
                [108.6625442, 14.2524432],
                [108.6641845, 14.2520582],
                [108.6649301, 14.2518171],
                [108.6652286, 14.2516238],
                [108.6653286, 14.2511881],
                [108.6655294, 14.2497842],
                [108.6656326, 14.2471695],
                [108.6657574, 14.2468066],
                [108.6660564, 14.2462259],
                [108.6663553, 14.2456937],
                [108.6668526, 14.2453554],
                [108.6679467, 14.2446791],
                [108.6681955, 14.2443888],
                [108.6682956, 14.2438563],
                [108.6680495, 14.2423065],
                [108.6674568, 14.239933],
                [108.667036, 14.2388913],
                [108.66654, 14.2383096],
                [108.6656477, 14.2369041],
                [108.6653021, 14.235451],
                [108.6651541, 14.2347245],
                [108.6651797, 14.2341677],
                [108.6651859, 14.2335656],
                [108.665264, 14.2335182],
                [108.6664806, 14.2329723],
                [108.6670584, 14.2327215],
                [108.6676517, 14.2322931],
                [108.6690057, 14.231333],
                [108.6701012, 14.2303725],
                [108.6702686, 14.2301951],
                [108.6703296, 14.2300324],
                [108.67033, 14.2298252],
                [108.6702544, 14.2295438],
                [108.6701944, 14.2289665],
                [108.6701648, 14.2284337],
                [108.6701277, 14.2278364],
                [108.670083, 14.2271846],
                [108.6700836, 14.2267552],
                [108.6701603, 14.2262813],
                [108.6702824, 14.2259408],
                [108.6704957, 14.2256153],
                [108.6707545, 14.2253046],
                [108.6710282, 14.225142],
                [108.6712715, 14.2250534],
                [108.6719097, 14.2250247],
                [108.673931, 14.224909],
                [108.6748278, 14.2247473],
                [108.6753144, 14.2245554],
                [108.6757861, 14.224171],
                [108.6759992, 14.223875],
                [108.6761519, 14.2234013],
                [108.6762439, 14.2228089],
                [108.6762754, 14.2220387],
                [108.6763216, 14.2216093],
                [108.6763981, 14.2212392],
                [108.6765049, 14.2208986],
                [108.6766422, 14.2205582],
                [108.6767338, 14.2202473],
                [108.6767955, 14.2196401],
                [108.6768872, 14.2192255],
                [108.6769789, 14.2189294],
                [108.6772834, 14.2184115],
                [108.6773522, 14.2181819],
                [108.6774134, 14.2178414],
                [108.6773688, 14.2172044],
                [108.6772026, 14.2165525],
                [108.6769299, 14.2159448],
                [108.6764752, 14.2151],
                [108.676278, 14.2148182],
                [108.6761417, 14.2145366],
                [108.6760206, 14.2142107],
                [108.6760211, 14.2138551],
                [108.6760681, 14.2128926],
                [108.6760686, 14.2125075],
                [108.675993, 14.2121963],
                [108.6757355, 14.2116775],
                [108.6752808, 14.2109068],
                [108.6742348, 14.2090983],
                [108.6739925, 14.20855],
                [108.6738942, 14.2081721],
                [108.6738795, 14.2078167],
                [108.6739103, 14.207565],
                [108.6740475, 14.2072541],
                [108.6742606, 14.2070174],
                [108.6744736, 14.2068251],
                [108.674717, 14.2066329],
                [108.6750059, 14.2065],
                [108.6755837, 14.2062786],
                [108.6766479, 14.2058505],
                [108.6770889, 14.2055993],
                [108.6773324, 14.2053627],
                [108.6775303, 14.2050519],
                [108.6775992, 14.2046743],
                [108.6775702, 14.2037708],
                [108.6776617, 14.2035487],
                [108.6777986, 14.2034009],
                [108.677981, 14.2033122],
                [108.6782395, 14.2032533],
                [108.6785738, 14.2032538],
                [108.6791206, 14.2033434],
                [108.6795916, 14.2033737],
                [108.6798956, 14.2033444],
                [108.6800781, 14.2032558],
                [108.6801694, 14.2031226],
                [108.6802154, 14.2028264],
                [108.6802158, 14.2025598],
                [108.6801102, 14.2019821],
                [108.6799522, 14.2009228],
                [108.6799225, 14.2004044],
                [108.6799382, 14.2000341],
                [108.6800147, 14.199664],
                [108.680167, 14.199442],
                [108.6803953, 14.1992053],
                [108.6806236, 14.1988501],
                [108.6807457, 14.1985393],
                [108.6807765, 14.1982726],
                [108.6807695, 14.1978209],
                [108.6806788, 14.197495],
                [108.6804363, 14.1970207],
                [108.6803457, 14.1966355],
                [108.6803763, 14.1964134],
                [108.6804528, 14.1960728],
                [108.6809556, 14.1950811],
                [108.681108, 14.1947554],
                [108.681253, 14.1943187],
                [108.6813143, 14.1939337],
                [108.6813299, 14.1935782],
                [108.6812546, 14.1931634],
                [108.680497, 14.1916664],
                [108.6802848, 14.191207],
                [108.6798832, 14.1904881],
                [108.6795193, 14.1900136],
                [108.6792157, 14.1897467],
                [108.6787301, 14.1893757],
                [108.6782442, 14.1891085],
                [108.6774245, 14.1886335],
                [108.6772423, 14.1884703],
                [108.6769693, 14.1881589],
                [108.6766433, 14.1876474],
                [108.676401, 14.1870842],
                [108.6761737, 14.1866692],
                [108.6759765, 14.1864468],
                [108.6757034, 14.1861946],
                [108.6751873, 14.1858236],
                [108.6746408, 14.1854971],
                [108.6743525, 14.1852153],
                [108.6741251, 14.1849631],
                [108.6740037, 14.184726],
                [108.6739587, 14.1844298],
                [108.6739513, 14.1841705],
                [108.6739063, 14.1838151],
                [108.6737851, 14.183563],
                [108.6735587, 14.1833706],
                [108.6738475, 14.1830751],
                [108.674035, 14.1828832],
                [108.6747915, 14.1821488],
                [108.6753776, 14.1816015],
                [108.6758292, 14.1811153],
                [108.6760722, 14.1808446],
                [108.6765639, 14.1803012],
                [108.6767593, 14.1800019],
                [108.6771329, 14.1795381],
                [108.6772825, 14.1791994],
                [108.6771052, 14.1782001],
                [108.6765865, 14.1778831],
                [108.6754992, 14.1771286],
                [108.6730011, 14.1749144],
                [108.6722611, 14.1741915],
                [108.6711981, 14.1726108],
                [108.6695337, 14.1705782],
                [108.6675467, 14.167417],
                [108.6661596, 14.165881],
                [108.6662012, 14.1652209],
                [108.6671049, 14.164343],
                [108.670116, 14.1621201],
                [108.67114, 14.1613011],
                [108.6720431, 14.1607748],
                [108.672705, 14.1607172],
                [108.6749905, 14.1609547],
                [108.676374, 14.1610738],
                [108.6772162, 14.1610749],
                [108.6784194, 14.1610179],
                [108.6786603, 14.1608424],
                [108.6787812, 14.1604323],
                [108.6787224, 14.159436],
                [108.6786646, 14.1577948],
                [108.6783658, 14.156388],
                [108.677948, 14.1540431],
                [108.6778298, 14.1525778],
                [108.67756, 14.1518548],
                [108.6768999, 14.1508568],
                [108.6767805, 14.1501529],
                [108.6769621, 14.149332],
                [108.6775652, 14.1482185],
                [108.6800356, 14.1453479],
                [108.6822048, 14.1427115],
                [108.6828979, 14.1417448],
                [108.68332, 14.1409829],
                [108.6835622, 14.1398102],
                [108.6838057, 14.1376991],
                [108.6839275, 14.1366435],
                [108.6837478, 14.1361155],
                [108.6831472, 14.1354696],
                [108.6829673, 14.135],
                [108.6835707, 14.1336519],
                [108.684566, 14.1315711],
                [108.6857713, 14.1299306],
                [108.6869473, 14.1286111],
                [108.6873376, 14.1281731],
                [108.6897474, 14.125361],
                [108.6917956, 14.122959],
                [108.6928504, 14.1214061],
                [108.6932119, 14.1208787],
                [108.6932123, 14.1205855],
                [108.6928523, 14.1199398],
                [108.6924921, 14.1194116],
                [108.6923128, 14.1185902],
                [108.6921338, 14.1174757],
                [108.692015, 14.1164785],
                [108.6918357, 14.1155985],
                [108.6921367, 14.1153056],
                [108.6932797, 14.1151312],
                [108.6967084, 14.1149008],
                [108.6977759, 14.114847],
                [108.6985285, 14.114654],
                [108.6987278, 14.1144817],
                [108.6989276, 14.1140076],
                [108.6989731, 14.113059],
                [108.6988681, 14.1117808],
                [108.6992178, 14.1099527],
                [108.6992564, 14.1097905],
                [108.6995321, 14.1086335],
                [108.699985, 14.1074498],
                [108.7006113, 14.1065369],
                [108.70155, 14.1055231],
                [108.7035658, 14.1041383],
                [108.7047125, 14.103463],
                [108.7053204, 14.1032162],
                [108.7059455, 14.1032169],
                [108.7065701, 14.1035224],
                [108.7068131, 14.1035565],
                [108.7071605, 14.1034892],
                [108.707543, 14.1030156],
                [108.7087614, 14.1005791],
                [108.7098057, 14.098481],
                [108.7103969, 14.0977706],
                [108.7113697, 14.0972638],
                [108.7122385, 14.0966893],
                [108.7127251, 14.0962496],
                [108.7132298, 14.0952514],
                [108.7139575, 14.0931383],
                [108.7151815, 14.0894634],
                [108.7156522, 14.0878556],
                [108.7157591, 14.0855871],
                [108.715761, 14.0840972],
                [108.7161792, 14.0828109],
                [108.7173094, 14.0813393],
                [108.7177284, 14.079342],
                [108.7181146, 14.0759225],
                [108.7184821, 14.073434],
                [108.7191791, 14.0713694],
                [108.7199447, 14.0698804],
                [108.7209364, 14.0679345],
                [108.722502, 14.0652274],
                [108.7233687, 14.0642218],
                [108.7250396, 14.0625552],
                [108.7285682, 14.0584789],
                [108.7307581, 14.0560434],
                [108.7328957, 14.0537601],
                [108.7341209, 14.0527708],
                [108.7345288, 14.0524414],
                [108.7361267, 14.0516304],
                [108.7392872, 14.0503133],
                [108.7414811, 14.0494862],
                [108.7476805, 14.0468008],
                [108.7505286, 14.0454154],
                [108.7514313, 14.045281],
                [108.7528893, 14.0452824],
                [108.7542951, 14.0453685],
                [108.7545035, 14.0452671],
                [108.7545386, 14.0449624],
                [108.7541229, 14.0440817],
                [108.7536388, 14.042388],
                [108.753674, 14.0418463],
                [108.7557949, 14.0387332],
                [108.7569938, 14.0374308],
                [108.7574804, 14.0368556],
                [108.7576201, 14.0360769],
                [108.7575168, 14.0352303],
                [108.7574141, 14.0339097],
                [108.7576234, 14.0327097],
                [108.7576346, 14.0324748],
                [108.7576131, 14.032197],
                [108.7575914, 14.0320154],
                [108.7573516, 14.0307549],
                [108.7570195, 14.0294565],
                [108.7569509, 14.0287792],
                [108.7570906, 14.0280344],
                [108.7575771, 14.0274253],
                [108.7576469, 14.0270529],
                [108.7574394, 14.0264093],
                [108.7567641, 14.0248003],
                [108.756176, 14.0229711],
                [108.7561073, 14.0223277],
                [108.7559344, 14.0216165],
                [108.7555877, 14.0212436],
                [108.7547205, 14.0207349],
                [108.7534022, 14.0201578],
                [108.7526043, 14.0196491],
                [108.7523618, 14.0192425],
                [108.7522236, 14.0186328],
                [108.7523113, 14.0177356],
                [108.7526941, 14.0167878],
                [108.753806, 14.015807],
                [108.754362, 14.0151643],
                [108.7543972, 14.014758],
                [108.7543285, 14.0140129],
                [108.7541211, 14.0132678],
                [108.7540868, 14.0127599],
                [108.7545737, 14.0119476],
                [108.7549391, 14.0110168],
                [108.7549395, 14.0106782],
                [108.7546972, 14.0100346],
                [108.7547672, 14.009459],
                [108.75508, 14.009053],
                [108.7557746, 14.0086812],
                [108.7563651, 14.0082755],
                [108.7566086, 14.0078016],
                [108.7566096, 14.0067858],
                [108.7567502, 14.0051267],
                [108.7572365, 14.0037942],
                [108.7573246, 14.0035528],
                [108.7580205, 14.0017926],
                [108.7601064, 13.9984086],
                [108.7625212, 13.9959561],
                [108.7638762, 13.9943997],
                [108.7640849, 13.9939597],
                [108.7640853, 13.9935196],
                [108.7636697, 13.9927742],
                [108.7624909, 13.9916218],
                [108.7594579, 13.9879109],
                [108.7563374, 13.9850973],
                [108.7527487, 13.982097],
                [108.7520898, 13.9815544],
                [108.7518476, 13.9809109],
                [108.7520925, 13.9790826],
                [108.7524069, 13.977119],
                [108.7529108, 13.9764929],
                [108.7540226, 13.9752412],
                [108.7549269, 13.9734475],
                [108.755623, 13.9714504],
                [108.7559706, 13.9709089],
                [108.7565611, 13.9703677],
                [108.757169, 13.9698434],
                [108.7572735, 13.9694034],
                [108.7570658, 13.9689291],
                [108.7560623, 13.9661177],
                [108.7554168, 13.9638709],
                [108.7546734, 13.9613475],
                [108.7533578, 13.9585018],
                [108.7524916, 13.9571803],
                [108.7524405, 13.9563507],
                [108.7531013, 13.9549292],
                [108.753519, 13.9537444],
                [108.7536587, 13.9528981],
                [108.7539023, 13.9521533],
                [108.7546675, 13.9505287],
                [108.7555537, 13.9491583],
                [108.7556935, 13.9483118],
                [108.7555209, 13.9473974],
                [108.7553478, 13.9470586],
                [108.7551745, 13.9468891],
                [108.7545852, 13.9463467],
                [108.7543077, 13.9462449],
                [108.7535786, 13.9466166],
                [108.7510431, 13.9489843],
                [108.750418, 13.9494577],
                [108.7497932, 13.9495925],
                [108.749481, 13.9494229],
                [108.7492736, 13.9487116],
                [108.7492051, 13.9479327],
                [108.7488591, 13.9469504],
                [108.7476459, 13.945764],
                [108.7468834, 13.9450183],
                [108.746641, 13.9444423],
                [108.7466591, 13.9438837],
                [108.7473205, 13.9419542],
                [108.7480162, 13.9403635],
                [108.7481556, 13.9398556],
                [108.7480866, 13.9393815],
                [108.7463195, 13.9370433],
                [108.7451935, 13.9355352],
                [108.7447092, 13.934248],
                [108.7445715, 13.9332658],
                [108.7447127, 13.9310988],
                [108.745443, 13.9294742],
                [108.7460173, 13.9277987],
                [108.7459495, 13.9264104],
                [108.745673, 13.9254619],
                [108.7456393, 13.9244799],
                [108.7460221, 13.9234306],
                [108.7463007, 13.9226182],
                [108.7463538, 13.9215516],
                [108.7459733, 13.9204676],
                [108.745489, 13.9192143],
                [108.7453858, 13.9183338],
                [108.7455258, 13.9172164],
                [108.745778, 13.915838],
                [108.7459098, 13.9151175],
                [108.7460671, 13.914017],
                [108.7460333, 13.9132382],
                [108.7457219, 13.9124253],
                [108.7448557, 13.9114085],
                [108.7439207, 13.909782],
                [108.743956, 13.9092743],
                [108.7445815, 13.9082929],
                [108.745294, 13.9071254],
                [108.7464052, 13.9062123],
                [108.7473434, 13.9049265],
                [108.7483165, 13.9033361],
                [108.7492194, 13.9024903],
                [108.7501224, 13.9015094],
                [108.7510428, 13.9006469],
                [108.7517717, 13.9002751],
                [108.752986, 13.9001747],
                [108.7537838, 13.9002432],
                [108.7544081, 13.9003455],
                [108.755137, 13.9001769],
                [108.7555189, 13.8998048],
                [108.756491, 13.8991286],
                [108.7577751, 13.8986896],
                [108.7583306, 13.8982838],
                [108.7589907, 13.8973364],
                [108.7596862, 13.8957794],
                [108.760208, 13.8943577],
                [108.7606421, 13.8939009],
                [108.7613016, 13.8934954],
                [108.7631412, 13.8926505],
                [108.764287, 13.8916697],
                [108.7645651, 13.8910605],
                [108.7647741, 13.8902819],
                [108.7648792, 13.8891306],
                [108.7648462, 13.8875053],
                [108.7644149, 13.885253],
                [108.7642421, 13.8845756],
                [108.7642429, 13.883729],
                [108.7644517, 13.8830859],
                [108.7653037, 13.8810381],
                [108.7658233, 13.8800778],
                [108.7664557, 13.8792468],
                [108.7667759, 13.8786126],
                [108.7669387, 13.8782169],
                [108.7672854, 13.877729],
                [108.7675359, 13.8772975],
                [108.7677867, 13.8765279],
                [108.7681636, 13.8746415],
                [108.7685689, 13.8731213],
                [108.7689355, 13.8719389],
                [108.7698316, 13.8702372],
                [108.7704093, 13.8693562],
                [108.7708136, 13.8689063],
                [108.7708715, 13.8686813],
                [108.770891, 13.8684373],
                [108.7709493, 13.8678184],
                [108.7712192, 13.8672182],
                [108.7717391, 13.8665433],
                [108.7727593, 13.8655501],
                [108.7740973, 13.8643774],
                [108.7742382, 13.8642676],
                [108.7748577, 13.8636914],
                [108.7755338, 13.8627582],
                [108.7767869, 13.861702],
                [108.7780543, 13.860165],
                [108.7795189, 13.8584086],
                [108.7816165, 13.8566114],
                [108.7831368, 13.8554593],
                [108.7840377, 13.8548559],
                [108.7849105, 13.8540052],
                [108.7854457, 13.8533739],
                [108.7859804, 13.8531547],
                [108.7862619, 13.8530726],
                [108.7865432, 13.8531003],
                [108.7868244, 13.8532104],
                [108.7876397, 13.8538153],
                [108.7880332, 13.8542277],
                [108.7884268, 13.8545576],
                [108.7886518, 13.8545578],
                [108.789271, 13.8543661],
                [108.7898057, 13.8541193],
                [108.7900452, 13.8537488],
                [108.7900454, 13.8535565],
                [108.7899611, 13.8534191],
                [108.78968, 13.8531991],
                [108.7893708, 13.8529517],
                [108.789174, 13.8526219],
                [108.7891744, 13.8522374],
                [108.7892873, 13.851853],
                [108.7896254, 13.8513314],
                [108.7901322, 13.8508924],
                [108.790695, 13.8506733],
                [108.7914548, 13.8505365],
                [108.7919894, 13.8503722],
                [108.7924398, 13.8500979],
                [108.7928059, 13.8496588],
                [108.7937066, 13.8492201],
                [108.794354, 13.8489185],
                [108.7947622, 13.8484382],
                [108.795213, 13.847697],
                [108.7961991, 13.8459949],
                [108.797016, 13.8447872],
                [108.7970443, 13.8445674],
                [108.7969602, 13.8442653],
                [108.7967354, 13.8440454],
                [108.7965665, 13.8440453],
                [108.7960601, 13.8441272],
                [108.7945685, 13.8448401],
                [108.7941183, 13.8448946],
                [108.7939776, 13.844867],
                [108.7938371, 13.8447296],
                [108.7938091, 13.8445099],
                [108.7938943, 13.8436584],
                [108.7938526, 13.8431229],
                [108.7937684, 13.8427657],
                [108.7929252, 13.8420509],
                [108.7927847, 13.8417762],
                [108.7927849, 13.841529],
                [108.7929826, 13.8406778],
                [108.7931802, 13.8399364],
                [108.7931814, 13.8385906],
                [108.7932946, 13.8379314],
                [108.7934494, 13.8376933],
                [108.7938154, 13.8374466],
                [108.7944346, 13.8370902],
                [108.7948287, 13.8367338],
                [108.7964619, 13.8349238],
                [108.7968279, 13.8345673],
                [108.7969127, 13.8341282],
                [108.7968847, 13.8339087],
                [108.7966037, 13.8336889],
                [108.7963504, 13.8336612],
                [108.7960693, 13.8336885],
                [108.7956752, 13.8338253],
                [108.7948872, 13.8342637],
                [108.7944932, 13.8344282],
                [108.7942119, 13.834428],
                [108.7940433, 13.8342357],
                [108.7940435, 13.8339612],
                [108.7941293, 13.8333867],
                [108.7944243, 13.8322806],
                [108.7951816, 13.8307241],
                [108.7952659, 13.8303963],
                [108.7952241, 13.8301505],
                [108.7951404, 13.8298226],
                [108.794973, 13.8292078],
                [108.7949733, 13.82888],
                [108.7950996, 13.8284704],
                [108.7957728, 13.8271186],
                [108.7960675, 13.8262584],
                [108.7963634, 13.8241688],
                [108.7963646, 13.8227346],
                [108.7960326, 13.822251],
                [108.7958269, 13.8220886],
                [108.7952137, 13.8216044],
                [108.7948044, 13.8211736],
                [108.7946789, 13.8205893],
                [108.7946794, 13.8199742],
                [108.7952479, 13.8185602],
                [108.7955333, 13.8165924],
                [108.7959131, 13.8147169],
                [108.7961974, 13.8140407],
                [108.7964498, 13.8137693],
                [108.7970016, 13.8134775],
                [108.7976636, 13.8131395],
                [108.7979792, 13.8126781],
                [108.7984525, 13.8120015],
                [108.7989887, 13.8115711],
                [108.7996192, 13.811387],
                [108.8004707, 13.81068],
                [108.8006605, 13.8100031],
                [108.8006936, 13.8079413],
                [108.8007729, 13.8074951],
                [108.8011516, 13.8067877],
                [108.8019714, 13.8062959],
                [108.8030749, 13.8057737],
                [108.8038946, 13.8053127],
                [108.804273, 13.8051591],
                [108.8046515, 13.8047901],
                [108.8049357, 13.8041133],
                [108.8053141, 13.8038059],
                [108.8058816, 13.8035294],
                [108.8081357, 13.8026234],
                [108.8089553, 13.8024086],
                [108.8097745, 13.8025324],
                [108.8112551, 13.8033336],
                [108.8126099, 13.8037962],
                [108.8135867, 13.8038892],
                [108.8144693, 13.8037977],
                [108.8156355, 13.8035523],
                [108.8164552, 13.8032145],
                [108.8173067, 13.8023534],
                [108.81778, 13.8016768],
                [108.8183162, 13.8011232],
                [108.8192935, 13.8006931],
                [108.8205858, 13.8004171],
                [108.8216732, 13.8002487],
                [108.8222092, 13.7999105],
                [108.8226193, 13.79948],
                [108.8228717, 13.7989262],
                [108.8229037, 13.7984339],
                [108.8228095, 13.7978799],
                [108.8226523, 13.7973567],
                [108.8226213, 13.7966488],
                [108.8226221, 13.7956026],
                [108.8228435, 13.794618],
                [108.8232063, 13.7940797],
                [108.8234586, 13.7938645],
                [108.8237738, 13.7938032],
                [108.8251289, 13.7937734],
                [108.8264843, 13.7933127],
                [108.8272411, 13.7927285],
                [108.8276828, 13.7919903],
                [108.8279039, 13.7912827],
                [108.8281254, 13.7899904],
                [108.8281108, 13.788467],
                [108.8279538, 13.7876976],
                [108.8276392, 13.7870819],
                [108.827608, 13.7864664],
                [108.8277346, 13.7857279],
                [108.8280816, 13.7851742],
                [108.8281769, 13.7843126],
                [108.8280203, 13.7828354],
                [108.8277689, 13.7820351],
                [108.8262733, 13.7802339],
                [108.8248567, 13.7784171],
                [108.8246999, 13.7773707],
                [108.8243545, 13.7757087],
                [108.8244654, 13.7748626],
                [108.8247813, 13.7738165],
                [108.8253492, 13.7728629],
                [108.8255388, 13.7721553],
                [108.825571, 13.7712936],
                [108.8254771, 13.7704626],
                [108.8255089, 13.7700319],
                [108.8259505, 13.7694475],
                [108.8267704, 13.7685556],
                [108.8270231, 13.7678172],
                [108.8271183, 13.7667402],
                [108.8270244, 13.7660016],
                [108.8268356, 13.765509],
                [108.8268359, 13.7652013],
                [108.8268677, 13.7647397],
                [108.8270571, 13.7644014],
                [108.827467, 13.7639708],
                [108.8276563, 13.7635709],
                [108.8277197, 13.7630785],
                [108.8277201, 13.7625862],
                [108.8278465, 13.7621247],
                [108.8280988, 13.7616633],
                [108.8282881, 13.7613248],
                [108.8282883, 13.7610787],
                [108.8282886, 13.7607094],
                [108.8282888, 13.760494],
                [108.8284465, 13.7603094],
                [108.8287931, 13.7602481],
                [108.8292342, 13.7602792],
                [108.8318808, 13.7606196],
                [108.8325423, 13.7606816],
                [108.832826, 13.7606202],
                [108.8331729, 13.7602818],
                [108.8336147, 13.7592051],
                [108.8340255, 13.7576667],
                [108.8340261, 13.7568775],
                [108.8340265, 13.7563743],
                [108.8337594, 13.7551893],
                [108.8337284, 13.7543891],
                [108.8339496, 13.7535277],
                [108.8343285, 13.7523893],
                [108.8345179, 13.7518355],
                [108.8345185, 13.7511276],
                [108.8342041, 13.7500196],
                [108.8336218, 13.7492345],
                [108.8331504, 13.7476032],
                [108.8330882, 13.7463414],
                [108.8329633, 13.7446488],
                [108.833248, 13.743141],
                [108.8334531, 13.7427565],
                [108.8341782, 13.74208],
                [108.8350924, 13.7413421],
                [108.8353449, 13.7408191],
                [108.8353767, 13.7403575],
                [108.8354409, 13.7386958],
                [108.8354414, 13.7378956],
                [108.8352841, 13.7376186],
                [108.8348432, 13.7374029],
                [108.8343077, 13.7371871],
                [108.8340874, 13.7368792],
                [108.8338356, 13.7364175],
                [108.8338359, 13.7360482],
                [108.8339623, 13.735402],
                [108.8340888, 13.7348174],
                [108.8342154, 13.7340173],
                [108.8342471, 13.7337405],
                [108.8341528, 13.733371],
                [108.8339326, 13.73294],
                [108.833429, 13.7323242],
                [108.8332088, 13.7316471],
                [108.8332731, 13.7299238],
                [108.8331796, 13.7284159],
                [108.8328494, 13.7277232],
                [108.8312435, 13.7264296],
                [108.8299212, 13.725167],
                [108.8288819, 13.7246739],
                [108.8277482, 13.7239961],
                [108.8270242, 13.7232263],
                [108.8265836, 13.7226105],
                [108.8265208, 13.7223335],
                [108.8265526, 13.7218719],
                [108.8269311, 13.721349],
                [108.8279084, 13.7204264],
                [108.8298324, 13.7173504],
                [108.8305576, 13.716397],
                [108.8312514, 13.7154434],
                [108.8314723, 13.7149204],
                [108.8315672, 13.714305],
                [108.8315992, 13.7136281],
                [108.8316317, 13.7122124],
                [108.8317583, 13.7114124],
                [108.8321054, 13.7106126],
                [108.8324524, 13.7099665],
                [108.832642, 13.7091358],
                [108.8326739, 13.7084895],
                [108.8325493, 13.7065507],
                [108.8321246, 13.7056427],
                [108.8312435, 13.7043803],
                [108.8306139, 13.7037951],
                [108.8296692, 13.7033021],
                [108.8270554, 13.7021617],
                [108.8252919, 13.7013295],
                [108.8242847, 13.7002209],
                [108.8228678, 13.6994198],
                [108.8216394, 13.6993267],
                [108.8195602, 13.6994482],
                [108.8183, 13.6995397],
                [108.8173865, 13.6994159],
                [108.8161586, 13.6986457],
                [108.8149621, 13.6978754],
                [108.8142383, 13.6971363],
                [108.8139238, 13.696459],
                [108.8137668, 13.6957511],
                [108.8137988, 13.6951973],
                [108.8141142, 13.6946435],
                [108.8152494, 13.6931673],
                [108.8165742, 13.6907986],
                [108.8178048, 13.6879375],
                [108.8185151, 13.6860455],
                [108.8187362, 13.6852456],
                [108.8187366, 13.6847224],
                [108.8186742, 13.6838608],
                [108.8180766, 13.6827832],
                [108.8180458, 13.6817676],
                [108.8181092, 13.6813061],
                [108.8183932, 13.68066],
                [108.8190239, 13.6797988],
                [108.8198122, 13.6785992],
                [108.8206646, 13.6761072],
                [108.8214537, 13.6738612],
                [108.8215488, 13.6731535],
                [108.8214864, 13.6723225],
                [108.8209837, 13.6705989],
                [108.8208269, 13.6697064],
                [108.820246, 13.6673824],
                [108.820152, 13.6667978],
                [108.8201837, 13.6664285],
                [108.8204357, 13.6663055],
                [108.8208137, 13.6662751],
                [108.8213178, 13.6662139],
                [108.8214123, 13.6660601],
                [108.8214442, 13.6655985],
                [108.8214761, 13.6650138],
                [108.8216341, 13.6643368],
                [108.8219494, 13.6638448],
                [108.8224852, 13.6633836],
                [108.8225799, 13.6631373],
                [108.82258, 13.6630144],
                [108.8222652, 13.6628602],
                [108.8216669, 13.6627675],
                [108.8208796, 13.6623977],
                [108.8203446, 13.6619664],
                [108.8200301, 13.6612584],
                [108.8199682, 13.659812],
                [108.8198746, 13.6586733],
                [108.8199385, 13.6575654],
                [108.8201911, 13.656704],
                [108.8206959, 13.6555349],
                [108.8211377, 13.6543659],
                [108.8216746, 13.6524275],
                [108.8219279, 13.650612],
                [108.8222592, 13.649889],
                [108.8226375, 13.6493662],
                [108.8231731, 13.6489972],
                [108.8237088, 13.6488129],
                [108.8240553, 13.6484748],
                [108.8242763, 13.647921],
                [108.8245289, 13.6469056],
                [108.8249076, 13.6459211],
                [108.8255385, 13.6444752],
                [108.8255863, 13.6436598],
                [108.8254614, 13.6422748],
                [108.8253554, 13.6417855],
                [108.8254307, 13.6413515],
                [108.8258091, 13.6406133],
                [108.827385, 13.6388603],
                [108.8282677, 13.6376299],
                [108.828473, 13.6367837],
                [108.8289149, 13.6354608],
                [108.8298922, 13.6340151],
                [108.830176, 13.6335229],
                [108.8301763, 13.6331228],
                [108.8297987, 13.6326302],
                [108.829138, 13.6316757],
                [108.8290754, 13.6311834],
                [108.8292963, 13.6305064],
                [108.8302104, 13.6293992],
                [108.8315027, 13.6277691],
                [108.8321965, 13.6263847],
                [108.8324176, 13.6256156],
                [108.8327013, 13.6250926],
                [108.8331424, 13.6247543],
                [108.8345286, 13.6238629],
                [108.8352219, 13.6233093],
                [108.8353481, 13.6228786],
                [108.8353485, 13.6223246],
                [108.8351284, 13.6217399],
                [108.8347197, 13.6208779],
                [108.8346254, 13.6205394],
                [108.8347832, 13.620047],
                [108.8354767, 13.6190627],
                [108.8369744, 13.6162787],
                [108.8378255, 13.6149252],
                [108.8383929, 13.6141869],
                [108.8389915, 13.6137873],
                [108.8394639, 13.6135721],
                [108.8398421, 13.6131416],
                [108.8408982, 13.6113727],
                [108.8415288, 13.6101422],
                [108.8416865, 13.6098038],
                [108.8416868, 13.6093422],
                [108.8413726, 13.6081726],
                [108.8399572, 13.6058328],
                [108.8386834, 13.6036624],
                [108.8385578, 13.6030469],
                [108.8385583, 13.602493],
                [108.8389055, 13.6011699],
                [108.8401669, 13.5984319],
                [108.8408128, 13.5977707],
                [108.8420728, 13.5970636],
                [108.8437421, 13.5963568],
                [108.8444669, 13.5954649],
                [108.8461367, 13.5939887],
                [108.8469244, 13.5930968],
                [108.8476496, 13.5916816],
                [108.8488322, 13.588805],
                [108.8500306, 13.5857283],
                [108.8508663, 13.5836208],
                [108.8510241, 13.5830055],
                [108.8510249, 13.5817437],
                [108.8509315, 13.5800511],
                [108.8511841, 13.5787587],
                [108.8519563, 13.5775435],
                [108.8523661, 13.576836],
                [108.8525239, 13.5760975],
                [108.8525243, 13.575482],
                [108.8517071, 13.5731735],
                [108.8510155, 13.5714498],
                [108.8503286, 13.56857],
                [108.8504519, 13.5684347],
                [108.8514904, 13.5672955],
                [108.8518688, 13.566311],
                [108.8525948, 13.5632647],
                [108.8529105, 13.5617571],
                [108.8532888, 13.5609571],
                [108.853919, 13.5601574],
                [108.854297, 13.5598806],
                [108.8550843, 13.559358],
                [108.8557931, 13.5587275],
                [108.8561713, 13.5580506],
                [108.8562662, 13.5572199],
                [108.8561726, 13.5558965],
                [108.8560788, 13.5546963],
                [108.8562366, 13.5541732],
                [108.856473, 13.5536348],
                [108.8568195, 13.5533888],
                [108.8576066, 13.5532046],
                [108.857827, 13.5531739],
                [108.858016, 13.5529894],
                [108.8581107, 13.5525278],
                [108.8582053, 13.552251],
                [108.8583628, 13.5521587],
                [108.8586777, 13.5521589],
                [108.859496, 13.552344],
                [108.8616364, 13.5530529],
                [108.8623919, 13.5531149],
                [108.8628642, 13.5530229],
                [108.8639348, 13.5525619],
                [108.8653207, 13.5513008],
                [108.867242, 13.5496709],
                [108.8679509, 13.548671],
                [108.8681085, 13.548271],
                [108.8684551, 13.547625],
                [108.8689591, 13.5470406],
                [108.8698096, 13.5464255],
                [108.8699356, 13.5461486],
                [108.8699672, 13.5458717],
                [108.8697157, 13.5453791],
                [108.8689605, 13.5446402],
                [108.8687718, 13.5443939],
                [108.8687091, 13.5439322],
                [108.8689614, 13.5430399],
                [108.8690719, 13.5425322],
                [108.8689147, 13.5421629],
                [108.8684427, 13.5418549],
                [108.8677504, 13.5412698],
                [108.8669327, 13.5397922],
                [108.8667128, 13.5388688],
                [108.8667137, 13.5374839],
                [108.867092, 13.5363148],
                [108.8675804, 13.5355611],
                [108.8679898, 13.5352228],
                [108.8683362, 13.5350998],
                [108.8692805, 13.535008],
                [108.8712637, 13.5351629],
                [108.8752928, 13.5356573],
                [108.8786609, 13.5361206],
                [108.8827689, 13.5363842],
                [108.8848778, 13.5366313],
                [108.8878997, 13.5370327],
                [108.8901502, 13.5377877],
                [108.8924792, 13.5388658],
                [108.8941033, 13.5398449],
                [108.8941983, 13.5404346],
                [108.8944453, 13.5408643],
                [108.894665, 13.5410793],
                [108.8949672, 13.5411868],
                [108.8952693, 13.541187],
                [108.8955441, 13.5411065],
                [108.896121, 13.5407845],
                [108.896698, 13.5405968],
                [108.8969452, 13.5405969],
                [108.8974808, 13.5407448],
                [108.8980028, 13.5407182],
                [108.899102, 13.5398593],
                [108.8997066, 13.5394567],
                [108.9003383, 13.5392421],
                [108.9016297, 13.5387055],
                [108.9028935, 13.5382764],
                [108.9034429, 13.538196],
                [108.9038551, 13.5382231],
                [108.9043219, 13.5383037],
                [108.9050634, 13.5386531],
                [108.9057912, 13.5390159],
                [108.9073017, 13.5399027],
                [108.9075488, 13.5402518],
                [108.9076037, 13.5405205],
                [108.9075485, 13.5409232],
                [108.9072187, 13.5415676],
                [108.9068744, 13.542],
                [108.9077472, 13.5420608],
                [108.9084414, 13.5417897],
                [108.9103158, 13.5409309],
                [108.9113571, 13.5406372],
                [108.9117503, 13.54066],
                [108.9123519, 13.5408185],
                [108.9128376, 13.5411127],
                [108.9133696, 13.5413616],
                [108.9141562, 13.5414299],
                [108.9150239, 13.5413058],
                [108.9168982, 13.5406956],
                [108.9181245, 13.5404699],
                [108.9190037, 13.5405154],
                [108.9195357, 13.5407191],
                [108.9205073, 13.5409683],
                [108.9211552, 13.5409458],
                [108.9218725, 13.5405163],
                [108.922833, 13.5396458],
                [108.9238283, 13.5383117],
                [108.9252634, 13.5363218],
                [108.9264435, 13.535621],
                [108.9280169, 13.5350335],
                [108.9293705, 13.5347058],
                [108.9303884, 13.5345025],
                [108.9314529, 13.53396],
                [108.9319389, 13.5334852],
                [108.9321473, 13.5331008],
                [108.9324251, 13.532151],
                [108.9326082, 13.5316322],
                [108.9326602, 13.5315307],
                [108.9328421, 13.5314291],
                [108.933076, 13.5314039],
                [108.93318, 13.5314038],
                [108.9334138, 13.5316069],
                [108.9338555, 13.532064],
                [108.934401, 13.5323434],
                [108.9349858, 13.5324878],
                [108.9354016, 13.5325388],
                [108.9357321, 13.5326356],
                [108.9360028, 13.5325035],
                [108.936375, 13.5322061],
                [108.9366458, 13.5317435],
                [108.9369504, 13.5309834],
                [108.9371873, 13.530686],
                [108.9374918, 13.5304878],
                [108.9385068, 13.5300255],
                [108.9395219, 13.5294638],
                [108.9400294, 13.5291005],
                [108.9406723, 13.5288362],
                [108.9410444, 13.5287041],
                [108.9415857, 13.5286051],
                [108.9421947, 13.5286053],
                [108.942905, 13.5288037],
                [108.943514, 13.5290682],
                [108.943886, 13.5291014],
                [108.9440552, 13.5291014],
                [108.9442921, 13.5289693],
                [108.9444274, 13.5287379],
                [108.9446306, 13.5279779],
                [108.9448002, 13.5259288],
                [108.9468553, 13.5266025],
                [108.9474135, 13.5267048],
                [108.9476926, 13.526773],
                [108.9480591, 13.5267731],
                [108.9485825, 13.5265858],
                [108.9490537, 13.5263301],
                [108.9493337, 13.5259698],
                [108.9500564, 13.5250733],
                [108.9521599, 13.5228879],
                [108.9560273, 13.5186125],
                [108.9581083, 13.5158114],
                [108.9586579, 13.5153509],
                [108.9590702, 13.5152166],
                [108.9595412, 13.5152167],
                [108.9605224, 13.5157925],
                [108.9613859, 13.5170591],
                [108.9618568, 13.5175581],
                [108.9622493, 13.5177885],
                [108.9627204, 13.5178269],
                [108.9650756, 13.5176737],
                [108.9694653, 13.5172515],
                [108.9728874, 13.517099],
                [108.9834272, 13.5167353],
                [108.9871563, 13.5167355],
                [108.9936137, 13.5165247],
                [108.9946735, 13.5168701],
                [108.9964006, 13.5181367],
                [108.997225, 13.5191344],
                [108.9973428, 13.5209383],
                [108.997382, 13.5217058],
                [108.9976175, 13.5221279],
                [108.9981278, 13.5225885],
                [108.9986382, 13.5228955],
                [108.9998551, 13.5230873],
                [109.0045853, 13.5230298],
                [109.0060771, 13.522953],
                [109.0065873, 13.5229913],
                [109.0074117, 13.5233368],
                [109.0079613, 13.5237973],
                [109.0090212, 13.5239891],
                [109.0101989, 13.5239123],
                [109.0115728, 13.5239122],
                [109.0124561, 13.5241616],
                [109.0130842, 13.5247757],
                [109.0135946, 13.5254665],
                [109.0140264, 13.5259653],
                [109.014419, 13.5262723],
                [109.0148901, 13.5262724],
                [109.0163425, 13.5262339],
                [109.0171669, 13.5260419],
                [109.0177557, 13.5259267],
                [109.0183445, 13.52585],
                [109.0188156, 13.525965],
                [109.019326, 13.5264256],
                [109.0208179, 13.5276919],
                [109.0217716, 13.5283838],
                [109.0226215, 13.5284796],
                [109.0245127, 13.5289071],
                [109.0258262, 13.5292919],
                [109.026264, 13.529634],
                [109.0267018, 13.5300616],
                [109.0275337, 13.5307459],
                [109.0288035, 13.5315155],
                [109.0295479, 13.5322853],
                [109.0298544, 13.532713],
                [109.0304236, 13.5332689],
                [109.0310367, 13.5337821],
                [109.0317373, 13.5346802],
                [109.0320876, 13.5353218],
                [109.0332701, 13.5379305],
                [109.0334892, 13.5387859],
                [109.0333143, 13.5411383],
                [109.0332926, 13.5422373],
                [109.0333364, 13.5426653],
                [109.0334678, 13.5430505],
                [109.0336869, 13.5434784],
                [109.0339934, 13.5438636],
                [109.0345626, 13.5444627],
                [109.0351319, 13.5449335],
                [109.0364894, 13.545447],
                [109.0371899, 13.5457464],
                [109.0377153, 13.5460887],
                [109.0403646, 13.5478646],
                [109.0410215, 13.548592],
                [109.0411967, 13.5490628],
                [109.0412844, 13.5499616],
                [109.0414161, 13.5517165],
                [109.0417667, 13.5539421],
                [109.0423362, 13.5550977],
                [109.0427742, 13.5558252],
                [109.0432997, 13.5563388],
                [109.0437376, 13.5568095],
                [109.0441318, 13.5572802],
                [109.0445261, 13.5579222],
                [109.0446574, 13.5584786],
                [109.0446575, 13.5590778],
                [109.044789, 13.5595057],
                [109.0451394, 13.5598481],
                [109.0464532, 13.5607466],
                [109.0484455, 13.5613241],
                [109.0497154, 13.561709],
                [109.050241, 13.5621798],
                [109.0505038, 13.5626505],
                [109.0507668, 13.5638061],
                [109.050986, 13.5650045],
                [109.0513365, 13.5656892],
                [109.0519059, 13.5664168],
                [109.0524315, 13.5672727],
                [109.0526506, 13.5681715],
                [109.052826, 13.5690274],
                [109.0530012, 13.5692842],
                [109.0534828, 13.5693697],
                [109.0554533, 13.5693692],
                [109.0587812, 13.5692828],
                [109.062547, 13.5690251],
                [109.07501, 13.5685163],
                [109.0787922, 13.5681646],
                [109.0820762, 13.5680779],
                [109.0831709, 13.5681203],
                [109.0837403, 13.5683341],
                [109.0842658, 13.5685908],
                [109.0850941, 13.569079],
                [109.0869816, 13.5711151],
                [109.0877701, 13.5718852],
                [109.0884709, 13.5723986],
                [109.0900911, 13.5725693],
                [109.0927499, 13.5725385],
                [109.0935504, 13.5725689],
                [109.0940322, 13.5729109],
                [109.0944267, 13.5732958],
                [109.0949085, 13.5737235],
                [109.0956095, 13.5742366],
                [109.0960477, 13.5747497],
                [109.0962669, 13.5753485],
                [109.0962674, 13.5765035],
                [109.0963114, 13.5770597],
                [109.0964868, 13.5775301],
                [109.0968811, 13.5777011],
                [109.0974506, 13.577872],
                [109.0980638, 13.5782568],
                [109.0986336, 13.5788554],
                [109.0992909, 13.5799246],
                [109.0996418, 13.5808657],
                [109.0997297, 13.5815929],
                [109.099993, 13.5826622],
                [109.1001586, 13.5832078],
                [109.100158, 13.5832748],
                [109.1001365, 13.583407],
                [109.1001177, 13.5834656],
                [109.100064, 13.5835765],
                [109.10005, 13.5836303],
                [109.1000399, 13.5837407],
                [109.1000461, 13.5838122],
                [109.1000623, 13.5838821],
                [109.1000882, 13.5839493],
                [109.1003054, 13.5843247],
                [109.1007775, 13.5844603],
                [109.104597, 13.5845437],
                [109.1047705, 13.5845546],
                [109.1051154, 13.5845976],
                [109.1054553, 13.5846689],
                [109.1056268, 13.5847162],
                [109.1059626, 13.5848324],
                [109.1062868, 13.5849765],
                [109.1103798, 13.5873334],
                [109.1112864, 13.5876567],
                [109.1124129, 13.5870623],
                [109.1125032, 13.5868953],
                [109.1126276, 13.5867326],
                [109.1127047, 13.5866542],
                [109.1127884, 13.5865825],
                [109.1138077, 13.5857378],
                [109.1140652, 13.5854458],
                [109.1141915, 13.5852894],
                [109.1144299, 13.5849661],
                [109.1145599, 13.5848357],
                [109.1147089, 13.5847262],
                [109.1153526, 13.5844603],
                [109.1156176, 13.5843869],
                [109.1158821, 13.5843369],
                [109.1161439, 13.5843091],
                [109.1170853, 13.5843456],
                [109.1171875, 13.5844117],
                [109.1173804, 13.5845594],
                [109.117482, 13.5846515],
                [109.1175779, 13.5847493],
                [109.1177505, 13.5849609],
                [109.1181743, 13.58567],
                [109.1182164, 13.5857564],
                [109.118252, 13.5858454],
                [109.1183031, 13.5860298],
                [109.118327, 13.5862318],
                [109.118319, 13.586435],
                [109.1183031, 13.5865356],
                [109.1182609, 13.5866501],
                [109.1181533, 13.5868696],
                [109.1180885, 13.5869736],
                [109.1179419, 13.5871634],
                [109.1178593, 13.5872509],
                [109.1176774, 13.5874092],
                [109.1175789, 13.5874794],
                [109.1166615, 13.588199],
                [109.1165669, 13.5882951],
                [109.116405, 13.5884954],
                [109.1163372, 13.588598],
                [109.1162217, 13.5888143],
                [109.1161454, 13.5889083],
                [109.1160396, 13.5889963],
                [109.1159732, 13.5890356],
                [109.1157365, 13.5891347],
                [109.1155824, 13.5892251],
                [109.1154414, 13.5893385],
                [109.1153767, 13.5894037],
                [109.1153174, 13.5894735],
                [109.1152158, 13.5896251],
                [109.1150093, 13.5899822],
                [109.1148922, 13.5902364],
                [109.1148215, 13.5903577],
                [109.1146585, 13.5905848],
                [109.1144675, 13.5907904],
                [109.113636, 13.5914683],
                [109.1135736, 13.5915718],
                [109.1135305, 13.5916919],
                [109.1135126, 13.5918255],
                [109.113518, 13.5922608],
                [109.1134088, 13.5925726],
                [109.1133249, 13.5928918],
                [109.1132905, 13.5930651],
                [109.1132443, 13.5934151],
                [109.1132283, 13.5937677],
                [109.1131639, 13.5947689],
                [109.1133678, 13.5954467],
                [109.1133678, 13.5966146],
                [109.1133819, 13.5966926],
                [109.1134317, 13.5968431],
                [109.1135066, 13.5969789],
                [109.1135518, 13.5970398],
                [109.1136575, 13.5971491],
                [109.1141617, 13.5974854],
                [109.1142432, 13.5976866],
                [109.1143508, 13.5978759],
                [109.1144912, 13.5980598],
                [109.1145754, 13.5981487],
                [109.1147625, 13.5983078],
                [109.1152561, 13.5986325],
                [109.1152775, 13.5991122],
                [109.1152335, 13.5993544],
                [109.1152226, 13.5995978],
                [109.1152293, 13.5997183],
                [109.1152668, 13.5999568],
                [109.1152785, 13.6001379],
                [109.1152742, 13.6002286],
                [109.1152453, 13.6004078],
                [109.1152395, 13.6005347],
                [109.115245, 13.6005981],
                [109.1152725, 13.6007244],
                [109.1153265, 13.6008445],
                [109.1154063, 13.6009501],
                [109.1154586, 13.6009991],
                [109.1155165, 13.6010418],
                [109.1156459, 13.6011055],
                [109.1157155, 13.6011258],
                [109.116345, 13.6012499],
                [109.1164563, 13.6013044],
                [109.1166661, 13.601435],
                [109.1167635, 13.6015106],
                [109.1169311, 13.6016696],
                [109.1170762, 13.6018483],
                [109.1171963, 13.6020437],
                [109.1180478, 13.603948],
                [109.1180588, 13.6039898],
                [109.118059, 13.6040759],
                [109.1180465, 13.6041222],
                [109.1180254, 13.6041656],
                [109.1179608, 13.6042374],
                [109.1179195, 13.6042636],
                [109.1173911, 13.6044148],
                [109.1173334, 13.604439],
                [109.1172257, 13.6045018],
                [109.1171294, 13.6045838],
                [109.1170866, 13.6046317],
                [109.1170156, 13.604738],
                [109.1165435, 13.6057391],
                [109.1161841, 13.6060233],
                [109.1161072, 13.6061174],
                [109.1160578, 13.6062277],
                [109.1160446, 13.6062866],
                [109.1160414, 13.6063986],
                [109.1160661, 13.6065081],
                [109.1161439, 13.6067115],
                [109.1158973, 13.6070535],
                [109.115785, 13.6072316],
                [109.1155833, 13.6076004],
                [109.115489, 13.6078024],
                [109.1154038, 13.6080082],
                [109.1152735, 13.6083356],
                [109.1150925, 13.6090629],
                [109.1150469, 13.6095061],
                [109.1150338, 13.6097414],
                [109.1150308, 13.6102126],
                [109.1149718, 13.6106479],
                [109.1149847, 13.6107609],
                [109.1150315, 13.6108658],
                [109.1151085, 13.6109529],
                [109.1155538, 13.6112579],
                [109.1156604, 13.6113585],
                [109.1157039, 13.6114172],
                [109.1157684, 13.6115473],
                [109.1157886, 13.6116178],
                [109.1158028, 13.6117633],
                [109.1157814, 13.611908],
                [109.1154009, 13.6129654],
                [109.1153499, 13.61326],
                [109.1153014, 13.6138215],
                [109.1152517, 13.6140202],
                [109.1152159, 13.614116],
                [109.1151451, 13.6142674],
                [109.1151061, 13.6143904],
                [109.1150905, 13.614506],
                [109.1150898, 13.6145582],
                [109.1151002, 13.6146],
                [109.1151461, 13.6146729],
                [109.1152138, 13.6147199],
                [109.1158462, 13.6148632],
                [109.1158857, 13.6149652],
                [109.1159164, 13.615011],
                [109.1159964, 13.6150874],
                [109.1161006, 13.6151402],
                [109.1162163, 13.6151604],
                [109.1163009, 13.6152351],
                [109.1163624, 13.6153329],
                [109.1163823, 13.6153889],
                [109.1163933, 13.6154471],
                [109.1163975, 13.6155715],
                [109.1164351, 13.6156912],
                [109.1165041, 13.6157977],
                [109.1166242, 13.615919],
                [109.1166937, 13.6159998],
                [109.1168092, 13.6161671],
                [109.1168577, 13.6162561],
                [109.1169351, 13.616443],
                [109.1170313, 13.6166453],
                [109.117099, 13.6168534],
                [109.1171719, 13.6172586],
                [109.1172355, 13.6174466],
                [109.117283, 13.6175449],
                [109.1173388, 13.617639],
                [109.1174028, 13.6177281],
                [109.1175902, 13.617952],
                [109.1177136, 13.6180864],
                [109.1178441, 13.6182143],
                [109.1179812, 13.6183355],
                [109.1181231, 13.6184484],
                [109.1182708, 13.6185539],
                [109.1184239, 13.618652],
                [109.118582, 13.6187422],
                [109.1189006, 13.6188983],
                [109.1190648, 13.6189663],
                [109.1193564, 13.6190682],
                [109.1196549, 13.6191488],
                [109.1197951, 13.6191591],
                [109.1199352, 13.6191475],
                [109.120097, 13.6191054],
                [109.1201741, 13.6190733],
                [109.1205229, 13.6188899],
                [109.1206017, 13.6188741],
                [109.1206419, 13.6188751],
                [109.120723, 13.6188964],
                [109.1207931, 13.6189415],
                [109.1210979, 13.6192844],
                [109.1211693, 13.6193373],
                [109.1213259, 13.6194225],
                [109.1214092, 13.6194538],
                [109.121583, 13.6194931],
                [109.1216719, 13.6195007],
                [109.1222405, 13.6195138],
                [109.12231, 13.6195264],
                [109.1224446, 13.6195682],
                [109.1225874, 13.619642],
                [109.1226607, 13.6196947],
                [109.1227277, 13.6197547],
                [109.123292, 13.6203688],
                [109.1234493, 13.6203776],
                [109.1236225, 13.6203625],
                [109.123807, 13.6203167],
                [109.124617, 13.6201837],
                [109.1251239, 13.6203714],
                [109.1253018, 13.6203782],
                [109.1256577, 13.6203766],
                [109.1261145, 13.6203449],
                [109.1263416, 13.6203167],
                [109.1265723, 13.6208406],
                [109.126553, 13.6210288],
                [109.1265106, 13.6212134],
                [109.1264441, 13.6213953],
                [109.126332, 13.621623],
                [109.1263144, 13.6216799],
                [109.126296, 13.6217973],
                [109.1263012, 13.6219214],
                [109.1263131, 13.6219824],
                [109.126355, 13.6220997],
                [109.1263637, 13.6227946],
                [109.1263806, 13.6229425],
                [109.1264248, 13.623085],
                [109.1264629, 13.6231642],
                [109.1265095, 13.6232389],
                [109.1265641, 13.6233084],
                [109.126626, 13.6233718],
                [109.1271517, 13.623476],
                [109.1267976, 13.6241277],
                [109.1266876, 13.6244484],
                [109.1265938, 13.6247846],
                [109.126457, 13.6251339],
                [109.1264393, 13.6251987],
                [109.1264301, 13.625332],
                [109.1264367, 13.6253871],
                [109.1264677, 13.6254937],
                [109.1265635, 13.6255846],
                [109.1266394, 13.6256918],
                [109.126674, 13.6257619],
                [109.1267001, 13.6258355],
                [109.1267172, 13.6259115],
                [109.1267252, 13.6259889],
                [109.1267091, 13.6263591],
                [109.1266974, 13.6264435],
                [109.1266763, 13.6265261],
                [109.1266461, 13.6266061],
                [109.126567, 13.6267443],
                [109.1265209, 13.6268024],
                [109.1264132, 13.6269048],
                [109.1257381, 13.6273653],
                [109.1256255, 13.6274852],
                [109.1255913, 13.6275362],
                [109.1255374, 13.6276462],
                [109.1252768, 13.6284132],
                [109.1249925, 13.6284236],
                [109.1245955, 13.6286269],
                [109.1245007, 13.6287449],
                [109.1244239, 13.6288862],
                [109.1243942, 13.6289662],
                [109.1243595, 13.6291326],
                [109.1241539, 13.6292106],
                [109.1238269, 13.6292967],
                [109.123764, 13.6293229],
                [109.1236535, 13.6293956],
                [109.123567, 13.6294902],
                [109.1234992, 13.629613],
                [109.1234528, 13.6297582],
                [109.1234368, 13.6299094],
                [109.123426, 13.6304208],
                [109.1234174, 13.6304649],
                [109.1233832, 13.630548],
                [109.1233522, 13.6305933],
                [109.1232708, 13.6306671],
                [109.122895, 13.6308374],
                [109.1228163, 13.6309262],
                [109.1227663, 13.6310329],
                [109.1227491, 13.6311398],
                [109.122761, 13.6312474],
                [109.1227777, 13.6312991],
                [109.1229004, 13.6315464],
                [109.1229221, 13.6316654],
                [109.1229165, 13.6317862],
                [109.1229006, 13.631855],
                [109.1228758, 13.6319212],
                [109.1228425, 13.6319838],
                [109.1228011, 13.6320417],
                [109.1224954, 13.6323127],
                [109.122337, 13.6323878],
                [109.1221763, 13.6324925],
                [109.1217336, 13.6328784],
                [109.121747, 13.6332876],
                [109.121806, 13.6337881],
                [109.1216451, 13.6339132],
                [109.1213917, 13.6340746],
                [109.1213042, 13.6341434],
                [109.1211462, 13.634299],
                [109.12076, 13.6342521],
                [109.1205448, 13.6342516],
                [109.1203308, 13.6342729],
                [109.120137, 13.6343117],
                [109.1197461, 13.634372],
                [109.1192687, 13.6344137],
                [109.1189468, 13.6346744],
                [109.1188922, 13.6347351],
                [109.1188436, 13.6348004],
                [109.118766, 13.6349427],
                [109.1187157, 13.6351001],
                [109.1187019, 13.6351834],
                [109.1186995, 13.6353519],
                [109.1187108, 13.6354355],
                [109.118759, 13.6363999],
                [109.1187875, 13.6366992],
                [109.1188122, 13.6368475],
                [109.1188824, 13.6371402],
                [109.1189318, 13.6372958],
                [109.1190541, 13.637599],
                [109.1190594, 13.6382089],
                [109.119043, 13.6382863],
                [109.1190309, 13.6384438],
                [109.1190353, 13.6385228],
                [109.1190648, 13.6386781],
                [109.1191253, 13.6388337],
                [109.119215, 13.6389752],
                [109.1192754, 13.639045],
                [109.119343, 13.6391082],
                [109.119417, 13.6391642],
                [109.1196148, 13.6392765],
                [109.1196662, 13.6393195],
                [109.1197453, 13.6394176],
                [109.1197944, 13.6395252],
                [109.1197944, 13.6399345],
                [109.1195744, 13.6404245],
                [109.1195279, 13.6405772],
                [109.1195146, 13.640736],
                [109.1195208, 13.6408155],
                [109.1195558, 13.6409636],
                [109.11962, 13.6411022],
                [109.1196683, 13.6412542],
                [109.1197461, 13.6413941],
                [109.1198002, 13.6414638],
                [109.1198616, 13.6415275],
                [109.1199296, 13.6415844],
                [109.1200036, 13.6416339],
                [109.120066, 13.6416915],
                [109.1201806, 13.6418164],
                [109.1202462, 13.6419026],
                [109.1203576, 13.6420875],
                [109.1205347, 13.642442],
                [109.1206992, 13.6426395],
                [109.1209038, 13.6428502],
                [109.1210261, 13.6429612],
                [109.1212857, 13.6431666],
                [109.1218704, 13.643959],
                [109.12203, 13.6441184],
                [109.1221654, 13.6442978],
                [109.1222248, 13.6443966],
                [109.122321, 13.6446054],
                [109.1223103, 13.6449755],
                [109.1227448, 13.644793],
                [109.1230667, 13.6445376],
                [109.1233939, 13.6444855],
                [109.1236476, 13.6444669],
                [109.1237748, 13.6444646],
                [109.1240913, 13.6446106],
                [109.1244668, 13.6443239],
                [109.1248637, 13.644058],
                [109.1254288, 13.6434371],
                [109.1255312, 13.643356],
                [109.1255879, 13.643323],
                [109.1257137, 13.6432726],
                [109.1258472, 13.6432475],
                [109.1260761, 13.6432448],
                [109.1266072, 13.6437035],
                [109.1267569, 13.6436095],
                [109.1270621, 13.6434408],
                [109.1273743, 13.6432969],
                [109.1276103, 13.643177],
                [109.127743, 13.6430593],
                [109.1278034, 13.6429945],
                [109.127876, 13.6429044],
                [109.1279407, 13.6428087],
                [109.1279971, 13.6427082],
                [109.1280448, 13.6426036],
                [109.1282165, 13.6419102],
                [109.1284686, 13.6417382],
                [109.1287834, 13.6414993],
                [109.1289353, 13.6413733],
                [109.129343, 13.6409979],
                [109.1298419, 13.6408155],
                [109.130609, 13.6404349],
                [109.1310275, 13.6403307],
                [109.1311054, 13.6403654],
                [109.1312528, 13.6404506],
                [109.1313361, 13.6405123],
                [109.1316122, 13.6407581],
                [109.1316743, 13.6406023],
                [109.1317132, 13.6405277],
                [109.1318094, 13.6403819],
                [109.1319295, 13.6402457],
                [109.1320682, 13.6401273],
                [109.132272, 13.6396425],
                [109.1323376, 13.6394354],
                [109.1323793, 13.6393349],
                [109.1325004, 13.6391082],
                [109.1325724, 13.6390013],
                [109.1331088, 13.638897],
                [109.1334946, 13.6387731],
                [109.1336828, 13.6386989],
                [109.1340176, 13.6385428],
                [109.1341799, 13.6384551],
                [109.1343384, 13.6383611],
                [109.1347557, 13.6380838],
                [109.1351581, 13.6374999],
                [109.1351915, 13.6372057],
                [109.1352546, 13.636916],
                [109.135357, 13.6366072],
                [109.1354209, 13.6364573],
                [109.1361773, 13.6364938],
                [109.1364457, 13.636496],
                [109.1367129, 13.6364716],
                [109.1368452, 13.6364495],
                [109.1369985, 13.6364152],
                [109.1371495, 13.6363721],
                [109.1372976, 13.6363203],
                [109.1374422, 13.63626],
                [109.1380978, 13.635936],
                [109.1384978, 13.63575],
                [109.138902, 13.6355727],
                [109.1393101, 13.6354042],
                [109.13956, 13.6353321],
                [109.1396816, 13.6352864],
                [109.1399164, 13.6351763],
                [109.1400289, 13.6351123],
                [109.140249, 13.6349622],
                [109.1403523, 13.6348785],
                [109.1405439, 13.6346952],
                [109.1405969, 13.6346075],
                [109.1406111, 13.6345583],
                [109.1406131, 13.6344596],
                [109.1405833, 13.6343684],
                [109.1405252, 13.6342912],
                [109.1404935, 13.6342364],
                [109.1404474, 13.6341191],
                [109.1404244, 13.6339778],
                [109.140434, 13.633835],
                [109.1406834, 13.6336265],
                [109.1413379, 13.6330009],
                [109.1414559, 13.6329082],
                [109.14159, 13.6328393],
                [109.1416626, 13.6328143],
                [109.1418135, 13.6327855],
                [109.1420594, 13.6327741],
                [109.1421165, 13.6327417],
                [109.1421558, 13.63269],
                [109.1421667, 13.6326594],
                [109.1421683, 13.632594],
                [109.1421428, 13.6325334],
                [109.1420658, 13.6324445],
                [109.1420406, 13.6323649],
                [109.1420409, 13.6323215],
                [109.1420503, 13.6322791],
                [109.1420686, 13.6322396],
                [109.1420949, 13.6322045],
                [109.1421281, 13.6321755],
                [109.1425717, 13.6319165],
                [109.1435695, 13.6314786],
                [109.1436552, 13.6314344],
                [109.1437359, 13.6313821],
                [109.1438109, 13.6313222],
                [109.1439242, 13.6312031],
                [109.1439725, 13.6311368],
                [109.144122, 13.6308791],
                [109.1441815, 13.6308132],
                [109.1442479, 13.6307539],
                [109.1443907, 13.6306614],
                [109.1445417, 13.6306012],
                [109.1446209, 13.6305819],
                [109.1447724, 13.6305738],
                [109.1450757, 13.6305764],
                [109.1453968, 13.6306067],
                [109.1455654, 13.6306341],
                [109.1458976, 13.6307123],
                [109.1459137, 13.6318488],
                [109.1459436, 13.6320237],
                [109.1460026, 13.6322081],
                [109.1460961, 13.6323962],
                [109.1461877, 13.6325099],
                [109.1462419, 13.6325594],
                [109.1463643, 13.6326412],
                [109.1472548, 13.6325421],
                [109.1483009, 13.6325578],
                [109.149237, 13.6323623],
                [109.1493924, 13.632301],
                [109.1495562, 13.6322658],
                [109.1497452, 13.6322587],
                [109.1499322, 13.6322862],
                [109.1500229, 13.6323127],
                [109.1503539, 13.6324628],
                [109.1505143, 13.6325475],
                [109.1508238, 13.632736],
                [109.1511282, 13.6329579],
                [109.151423, 13.6332147],
                [109.1517985, 13.6336421],
                [109.1518849, 13.6337193],
                [109.1519765, 13.6337904],
                [109.152174, 13.6339132],
                [109.1523778, 13.634005],
                [109.1525924, 13.6340696],
                [109.152695, 13.6342034],
                [109.1527748, 13.6343511],
                [109.1528051, 13.634428],
                [109.1528468, 13.6345875],
                [109.152858, 13.6346691],
                [109.1528606, 13.6350184],
                [109.1534293, 13.6358734],
                [109.153379, 13.6363603],
                [109.1533425, 13.6366024],
                [109.1532469, 13.6370829],
                [109.1531793, 13.6373527],
                [109.1531022, 13.6376201],
                [109.1530156, 13.6378848],
                [109.1529196, 13.6381464],
                [109.1527635, 13.6386489],
                [109.152698, 13.6389036],
                [109.1526127, 13.6392885],
                [109.1525801, 13.6395504],
                [109.1525801, 13.6398154],
                [109.152613, 13.6400785],
                [109.1526416, 13.6402082],
                [109.1527865, 13.6407162],
                [109.1528636, 13.6411137],
                [109.1528897, 13.6413193],
                [109.1529066, 13.6415259],
                [109.1529173, 13.6419955],
                [109.1529009, 13.6425202],
                [109.1532683, 13.642786],
                [109.1533028, 13.6429344],
                [109.1533542, 13.6432343],
                [109.1533701, 13.6433764],
                [109.1533863, 13.6436618],
                [109.1539121, 13.6443812],
                [109.1539647, 13.6446927],
                [109.1540077, 13.6450056],
                [109.1540616, 13.6455933],
                [109.1540809, 13.6461418],
                [109.1540794, 13.6464162],
                [109.154054, 13.6469645],
                [109.1540301, 13.6472379],
                [109.1539513, 13.6473562],
                [109.153778, 13.647582],
                [109.1535908, 13.6477857],
                [109.1533863, 13.647973],
                [109.153161, 13.6488644],
                [109.1531255, 13.6489693],
                [109.1530296, 13.6491693],
                [109.1529781, 13.6492513],
                [109.1528585, 13.6494042],
                [109.1524637, 13.649834],
                [109.1524529, 13.6508557],
                [109.151938, 13.6520964],
                [109.1518962, 13.6523719],
                [109.1518634, 13.6525075],
                [109.1517789, 13.6527603],
                [109.1516728, 13.6529913],
                [109.1514391, 13.6534048],
                [109.1514283, 13.6541398],
                [109.1511333, 13.654648],
                [109.1511512, 13.6548868],
                [109.1512084, 13.6553622],
                [109.1512942, 13.655834],
                [109.1514176, 13.6566576],
                [109.1515031, 13.6566674],
                [109.1515893, 13.656668],
                [109.1517264, 13.6566495],
                [109.1524002, 13.6564218],
                [109.1525012, 13.6564126],
                [109.1525967, 13.6564284],
                [109.152642, 13.6564451],
                [109.1526664, 13.6568636],
                [109.152682, 13.656914],
                [109.1527373, 13.6570042],
                [109.1527775, 13.6570433],
                [109.1528241, 13.6570752],
                [109.1528755, 13.6570989],
                [109.1532034, 13.6571691],
                [109.153249, 13.6571869],
                [109.1532915, 13.6572108],
                [109.1533636, 13.6572753],
                [109.1534159, 13.6573623],
                [109.1534318, 13.6574133],
                [109.1534374, 13.6575195],
                [109.1534078, 13.6576219],
                [109.1533098, 13.6578277],
                [109.1532381, 13.6580485],
                [109.1531959, 13.6582813],
                [109.1531848, 13.6584602],
                [109.1531461, 13.6587834],
                [109.1530778, 13.6591459],
                [109.1530697, 13.6592215],
                [109.1530698, 13.6593734],
                [109.1530797, 13.6594594],
                [109.1531201, 13.6596278],
                [109.1531875, 13.6597878],
                [109.15332, 13.6600064],
                [109.1535196, 13.6602787],
                [109.1537458, 13.6605306],
                [109.1543841, 13.6615315],
                [109.1544161, 13.6617092],
                [109.1544572, 13.6618852],
                [109.1545074, 13.6620589],
                [109.1545665, 13.6622299],
                [109.1546337, 13.6623961],
                [109.1547932, 13.6627177],
                [109.1548852, 13.6628723],
                [109.155221, 13.6633871],
                [109.154942, 13.6640179],
                [109.1546363, 13.6645079],
                [109.1546309, 13.6648884],
                [109.1546814, 13.6649782],
                [109.1548024, 13.6651454],
                [109.1549548, 13.6652991],
                [109.1550435, 13.6653694],
                [109.1553282, 13.6655464],
                [109.1554154, 13.6656103],
                [109.1554983, 13.6656794],
                [109.1556501, 13.6658318],
                [109.1556914, 13.6659054],
                [109.1557586, 13.6660595],
                [109.1558059, 13.6662274],
                [109.155821, 13.6663167],
                [109.1558317, 13.6664973],
                [109.1558218, 13.6670542],
                [109.1561651, 13.6676093],
                [109.1564977, 13.6682557],
                [109.1568196, 13.6686831],
                [109.1568903, 13.6688265],
                [109.156935, 13.6689774],
                [109.1569537, 13.6691314],
                [109.1569483, 13.6696213],
                [109.156723, 13.6701791],
                [109.1567203, 13.6704762],
                [109.1567454, 13.6706009],
                [109.1567606, 13.6707271],
                [109.1567632, 13.6709544],
                [109.1567391, 13.6711538],
                [109.1566184, 13.6713623],
                [109.1565853, 13.6717946],
                [109.1565621, 13.6722276],
                [109.1565487, 13.6726706],
                [109.1562349, 13.6734368],
                [109.1559988, 13.6735671],
                [109.1557851, 13.6737222],
                [109.1555926, 13.6739017],
                [109.1553765, 13.6741614],
                [109.1548535, 13.6746409],
                [109.1552776, 13.6747139],
                [109.1553939, 13.6747625],
                [109.1554882, 13.6748329],
                [109.1555257, 13.674873],
                [109.1555858, 13.6749641],
                [109.1557977, 13.6753915],
                [109.1560364, 13.6756677],
                [109.1560525, 13.6764287],
                [109.1561544, 13.676731],
                [109.1562377, 13.6770769],
                [109.1563314, 13.6777422],
                [109.1561329, 13.6783416],
                [109.1567659, 13.678055],
                [109.1575009, 13.6777839],
                [109.1582465, 13.6774451],
                [109.1590512, 13.6768978],
                [109.1593462, 13.6767415],
                [109.1596198, 13.6765434],
                [109.1601643, 13.6765278],
                [109.160352, 13.6763922],
                [109.1607839, 13.6763844],
                [109.1610146, 13.6764391],
                [109.1611285, 13.6764753],
                [109.1614536, 13.6766056],
                [109.1615607, 13.6766377],
                [109.1616704, 13.6766605],
                [109.1617817, 13.6766737],
                [109.1619794, 13.6766733],
                [109.1621747, 13.6766427],
                [109.1622698, 13.6766164],
                [109.1627955, 13.6763662],
                [109.1631187, 13.6764587],
                [109.1631911, 13.6765288],
                [109.1633494, 13.6766542],
                [109.163519, 13.6767553],
                [109.1637008, 13.676834],
                [109.1637182, 13.6773774],
                [109.163816, 13.6774305],
                [109.1639174, 13.6774765],
                [109.1641293, 13.6775466],
                [109.1643505, 13.6775862],
                [109.1645764, 13.677594],
                [109.165011, 13.6775702],
                [109.1651324, 13.677678],
                [109.1653902, 13.6778763],
                [109.1656766, 13.678056],
                [109.1658317, 13.6781382],
                [109.1661537, 13.6782791],
                [109.1667438, 13.6784485],
                [109.1672319, 13.6786804],
                [109.1675189, 13.6787821],
                [109.1675296, 13.6789541],
                [109.1675113, 13.6791602],
                [109.1674894, 13.6792616],
                [109.167358, 13.679689],
                [109.1673404, 13.6797687],
                [109.1673322, 13.6798498],
                [109.1673336, 13.6799313],
                [109.1673446, 13.6800122],
                [109.1673646, 13.6800903],
                [109.1673935, 13.6801657],
                [109.167431, 13.6802375],
                [109.1674766, 13.6803048],
                [109.1675961, 13.6804533],
                [109.1677116, 13.6806378],
                [109.1677601, 13.680735],
                [109.1678381, 13.6809373],
                [109.1680205, 13.6813595],
                [109.1681138, 13.6814984],
                [109.1682297, 13.6816201],
                [109.1683645, 13.6817212],
                [109.168438, 13.6817632],
                [109.1685945, 13.6818286],
                [109.1688238, 13.6819394],
                [109.1688765, 13.6819877],
                [109.1689673, 13.682097],
                [109.169011, 13.6821686],
                [109.1690737, 13.6823233],
                [109.1691229, 13.6826026],
                [109.1691746, 13.6827798],
                [109.1692471, 13.6829804],
                [109.1693026, 13.6832151],
                [109.1697399, 13.6831877],
                [109.1698341, 13.6831903],
                [109.1699275, 13.683202],
                [109.1700187, 13.6832226],
                [109.1701073, 13.6832518],
                [109.1701926, 13.6832893],
                [109.1702735, 13.6833349],
                [109.1705015, 13.6834861],
                [109.1708422, 13.6830952],
                [109.1712123, 13.6828111],
                [109.1715369, 13.6825323],
                [109.1718775, 13.6825531],
                [109.1720506, 13.6825756],
                [109.1723925, 13.6826443],
                [109.1724922, 13.682645],
                [109.1726902, 13.6826235],
                [109.1727954, 13.6825991],
                [109.172898, 13.6825661],
                [109.1729973, 13.6825246],
                [109.1730925, 13.6824749],
                [109.176448, 13.6822977],
                [109.1770595, 13.6824958],
                [109.1774707, 13.6824818],
                [109.1776764, 13.6824854],
                [109.1781378, 13.6825192],
                [109.178456, 13.6824602],
                [109.1786171, 13.6824427],
                [109.1789499, 13.6824321],
                [109.1791209, 13.68244],
                [109.1792911, 13.6824569],
                [109.1796598, 13.6825205],
                [109.180054, 13.6826185],
                [109.1804391, 13.682746],
                [109.1814369, 13.6832672],
                [109.181729, 13.6833821],
                [109.1820323, 13.6834652],
                [109.1823518, 13.6835167],
                [109.1825132, 13.6835292],
                [109.182837, 13.6835278],
                [109.1828987, 13.6838301],
                [109.1834968, 13.6844451],
                [109.1835957, 13.6845769],
                [109.1836758, 13.6847357],
                [109.1837062, 13.6848263],
                [109.1837275, 13.6849194],
                [109.1837651, 13.6852217],
                [109.1837235, 13.6858276],
                [109.1840869, 13.6861964],
                [109.1843343, 13.686545],
                [109.1846101, 13.6868778],
                [109.1849134, 13.6871923],
                [109.185074, 13.687341],
                [109.1852403, 13.6874838],
                [109.1853804, 13.6875902],
                [109.1854441, 13.6876506],
                [109.1855564, 13.6877828],
                [109.185648, 13.6879294],
                [109.1859779, 13.6882343],
                [109.1861291, 13.688291],
                [109.1862787, 13.6883147],
                [109.1864196, 13.6883105],
                [109.1865572, 13.6882812],
                [109.1874289, 13.6879581],
                [109.1870239, 13.6892298],
                [109.1869902, 13.6894512],
                [109.186966, 13.6896737],
                [109.1869398, 13.6901958],
                [109.1869515, 13.6903452],
                [109.1869681, 13.690412],
                [109.1870217, 13.6905389],
                [109.187058, 13.6905978],
                [109.1871481, 13.6907035],
                [109.187606, 13.691054],
                [109.1877746, 13.6911937],
                [109.1880995, 13.6914866],
                [109.1881706, 13.691535],
                [109.1883248, 13.6916117],
                [109.188412, 13.691641],
                [109.1885017, 13.6916619],
                [109.1888076, 13.6916951],
                [109.1903418, 13.693144],
                [109.1907281, 13.693905],
                [109.1908626, 13.6939433],
                [109.1911365, 13.6940017],
                [109.1914147, 13.6940353],
                [109.1915794, 13.6940433],
                [109.191909, 13.6940332],
                [109.1924743, 13.6939568],
                [109.1929547, 13.6939203],
                [109.1931957, 13.6939154],
                [109.1937697, 13.6939362],
                [109.1944081, 13.6943532],
                [109.1951215, 13.6943219],
                [109.1951787, 13.6943258],
                [109.1952878, 13.6943584],
                [109.1953566, 13.6944001],
                [109.1955265, 13.6945669],
                [109.1958296, 13.6944444],
                [109.1960222, 13.6944631],
                [109.1961166, 13.6944848],
                [109.196704, 13.694374],
                [109.1969331, 13.6943707],
                [109.1971552, 13.6943937],
                [109.1972621, 13.6944142],
                [109.1974711, 13.6944731],
                [109.1978732, 13.6946717],
                [109.1983829, 13.6948956],
                [109.1991143, 13.6951746],
                [109.199338, 13.6952496],
                [109.1994723, 13.6952845],
                [109.1997456, 13.695333],
                [109.1997589, 13.6951623],
                [109.1997725, 13.6950777],
                [109.1998233, 13.6948795],
                [109.1998583, 13.6947832],
                [109.2000547, 13.6942723],
                [109.2001212, 13.6941864],
                [109.2001985, 13.6941317],
                [109.2002883, 13.6940995],
                [109.2003357, 13.6940926],
                [109.2022133, 13.6940457],
                [109.2025298, 13.693931],
                [109.203093, 13.6938763],
                [109.203153, 13.6938627],
                [109.2032652, 13.6938141],
                [109.2033157, 13.6937799],
                [109.2034012, 13.6936946],
                [109.203435, 13.6936448],
                [109.203482, 13.6935349],
                [109.2037958, 13.692589],
                [109.203814, 13.6923143],
                [109.2038135, 13.6921766],
                [109.2037901, 13.6918781],
                [109.2037311, 13.6915597],
                [109.2036663, 13.6913189],
                [109.2036447, 13.6911463],
                [109.2036538, 13.690975],
                [109.203692, 13.6908099],
                [109.2037217, 13.6907303],
                [109.2037785, 13.6906159],
                [109.2038487, 13.690517],
                [109.2039032, 13.6904599],
                [109.2040305, 13.6903656],
                [109.2041817, 13.6902851],
                [109.2042571, 13.6902335],
                [109.2043912, 13.6901107],
                [109.2044934, 13.6899751],
                [109.2045693, 13.6898241],
                [109.2045966, 13.6897443],
                [109.2046165, 13.6896624],
                [109.204638, 13.6893862],
                [109.2046805, 13.6892749],
                [109.2047507, 13.689181],
                [109.2048418, 13.68911],
                [109.2051101, 13.6890213],
                [109.2052705, 13.6884566],
                [109.2052925, 13.6883334],
                [109.205293, 13.6882667],
                [109.205271, 13.6881353],
                [109.2052702, 13.6880288],
                [109.2052977, 13.6879258],
                [109.2053515, 13.687833],
                [109.2053691, 13.6877049],
                [109.2053676, 13.6876402],
                [109.2053409, 13.6875032],
                [109.2053159, 13.6874377],
                [109.2052052, 13.6872499],
                [109.2051423, 13.6871085],
                [109.2050537, 13.6867176],
                [109.2051154, 13.6866394],
                [109.2051174, 13.6860412],
                [109.2051427, 13.6858682],
                [109.2051659, 13.6857837],
                [109.2052285, 13.6856295],
                [109.2053092, 13.6854936],
                [109.2053905, 13.6853878],
                [109.2054401, 13.6852932],
                [109.2054641, 13.6851905],
                [109.2054722, 13.6850211],
                [109.2059764, 13.6842236],
                [109.2060825, 13.6839897],
                [109.2061446, 13.683877],
                [109.2062975, 13.683646],
                [109.2063897, 13.6835294],
                [109.2064887, 13.6834183],
                [109.2066804, 13.6832288],
                [109.2067677, 13.6831265],
                [109.2069318, 13.6828951],
                [109.2070028, 13.6827727],
                [109.2071217, 13.6825166],
                [109.2071432, 13.6820527],
                [109.2071898, 13.6819076],
                [109.2072633, 13.6817753],
                [109.2073603, 13.6816601],
                [109.2074168, 13.6816097],
                [109.2074679, 13.681458],
                [109.2075435, 13.6813154],
                [109.2076418, 13.6811857],
                [109.2077601, 13.6810729],
                [109.2078673, 13.6810604],
                [109.2079193, 13.6810459],
                [109.2080175, 13.6810005],
                [109.2080625, 13.68097],
                [109.208141, 13.6808956],
                [109.2084897, 13.680609],
                [109.2092138, 13.6803901],
                [109.2095457, 13.6801237],
                [109.2096561, 13.6800582],
                [109.2097154, 13.680033],
                [109.2098406, 13.6799983],
                [109.2099052, 13.6799894],
                [109.2099702, 13.6799861],
                [109.2100139, 13.679999],
                [109.2101044, 13.6800095],
                [109.2102006, 13.6799978],
                [109.210296, 13.6799605],
                [109.2104413, 13.6798564],
                [109.2105818, 13.6797921],
                [109.2106569, 13.6797724],
                [109.2110941, 13.6796942],
                [109.2114776, 13.6793815],
                [109.2115831, 13.6793127],
                [109.2116412, 13.6792877],
                [109.2117599, 13.6792585],
                [109.212065, 13.679246],
                [109.2121495, 13.6791731],
                [109.2122474, 13.6791183],
                [109.2123667, 13.6790814],
                [109.2124915, 13.6790714],
                [109.2125865, 13.6790754],
                [109.2127737, 13.6791066],
                [109.2128646, 13.6791335],
                [109.2135, 13.6793632],
                [109.2138916, 13.6793372],
                [109.2140514, 13.6793603],
                [109.2142028, 13.6794154],
                [109.2143397, 13.6795006],
                [109.2144005, 13.6795533],
                [109.2145032, 13.679676],
                [109.2147821, 13.680291],
                [109.2149645, 13.6805568],
                [109.2150494, 13.6807058],
                [109.2151254, 13.6808592],
                [109.2153776, 13.6812396],
                [109.2155149, 13.6813678],
                [109.2155921, 13.6814221],
                [109.2157597, 13.6815082],
                [109.2159408, 13.6815628],
                [109.2166007, 13.6817869],
                [109.2168768, 13.6817169],
                [109.2170172, 13.6816914],
                [109.2173218, 13.6816582],
                [109.2174856, 13.6816526],
                [109.2177315, 13.6816581],
                [109.217813, 13.681667],
                [109.2179009, 13.681684],
                [109.2180705, 13.68174],
                [109.2181748, 13.6817431],
                [109.218279, 13.6817371],
                [109.2183822, 13.6817222],
                [109.2184836, 13.6816983],
                [109.2186679, 13.6816295],
                [109.2188376, 13.6815315],
                [109.2190514, 13.6814858],
                [109.219155, 13.6814512],
                [109.2193526, 13.6813595],
                [109.2194511, 13.6812992],
                [109.2195443, 13.6812313],
                [109.2196315, 13.6811562],
                [109.2197987, 13.6810406],
                [109.220079, 13.6808988],
                [109.2202645, 13.6807813],
                [109.2203504, 13.6807132],
                [109.2205058, 13.6805608],
                [109.2206375, 13.6803886],
                [109.2206937, 13.6802962],
                [109.2212141, 13.6798167],
                [109.2213438, 13.6797179],
                [109.2214876, 13.6796395],
                [109.2216658, 13.6795769],
                [109.2223084, 13.6794623],
                [109.2225532, 13.6793798],
                [109.2226785, 13.6793476],
                [109.223135, 13.6792703],
                [109.2233223, 13.6792069],
                [109.2235154, 13.6791131],
                [109.223687, 13.6790818],
                [109.2238919, 13.6790206],
                [109.2240873, 13.6789351],
                [109.2241806, 13.6788837],
                [109.225924, 13.6781071],
                [109.226008, 13.6781006],
                [109.2260501, 13.6781045],
                [109.2260967, 13.6781144],
                [109.2261842, 13.6781514],
                [109.2262739, 13.6781781],
                [109.2263655, 13.6781979],
                [109.2265521, 13.6782163],
                [109.2267394, 13.6782061],
                [109.2268427, 13.678188],
                [109.2270424, 13.6781258],
                [109.2272278, 13.6780306],
                [109.2273134, 13.6779716],
                [109.2275597, 13.6777584],
                [109.2276889, 13.6776588],
                [109.2279433, 13.677484],
                [109.2280751, 13.6774034],
                [109.2284775, 13.6770177],
                [109.2287511, 13.6768613],
                [109.2288912, 13.6767697],
                [109.2291588, 13.6765695],
                [109.2293317, 13.6765628],
                [109.2295009, 13.6765274],
                [109.2296683, 13.6764606],
                [109.2298266, 13.676361],
                [109.2299554, 13.676215],
                [109.229989, 13.6761158],
                [109.230013, 13.6760691],
                [109.2300815, 13.6759757],
                [109.230191, 13.6758782],
                [109.2302644, 13.6758283],
                [109.2304715, 13.6757273],
                [109.2305131, 13.6756957],
                [109.2305702, 13.6756234],
                [109.2305987, 13.6755484],
                [109.2306045, 13.6755087],
                [109.230575, 13.6750005],
                [109.2305374, 13.6748803],
                [109.230473, 13.6747712],
                [109.2303909, 13.6746837],
                [109.2302917, 13.6746151],
                [109.230237, 13.6745888],
                [109.2301602, 13.6745214],
                [109.2301091, 13.674434],
                [109.2300889, 13.674338],
                [109.2300991, 13.6742431],
                [109.2301152, 13.6741979],
                [109.2301381, 13.6741556],
                [109.2302715, 13.6739815],
                [109.230355, 13.673833],
                [109.2304197, 13.6736621],
                [109.2304569, 13.6734837],
                [109.2305357, 13.6733371],
                [109.2305857, 13.67327],
                [109.2306939, 13.6731602],
                [109.2308204, 13.6730706],
                [109.2308893, 13.6730344],
                [109.2309612, 13.6730042],
                [109.2313582, 13.6728947],
                [109.2315817, 13.6728252],
                [109.2320234, 13.6726706],
                [109.2321849, 13.6726004],
                [109.2323423, 13.6725217],
                [109.2324951, 13.672435],
                [109.2326428, 13.6723403],
                [109.2327851, 13.672238],
                [109.2327774, 13.6721476],
                [109.2327792, 13.672057],
                [109.2327905, 13.6719669],
                [109.2328113, 13.6718777],
                [109.2328413, 13.6717911],
                [109.2328804, 13.6717078],
                [109.2329279, 13.6716289],
                [109.2329836, 13.6715552],
                [109.2335415, 13.6710235],
                [109.2337845, 13.670628],
                [109.347935, 13.5891905],
                [109.5849213, 13.6232439],
                [109.5539342, 13.9258714],
                [109.4632349, 14.6051722],
                [109.081656, 14.5781971],
                [109.0782495, 14.5804034],
                [109.0776715, 14.5807617],
                [109.0727579, 14.5834996],
                [109.0712339, 14.584523],
                [109.0708135, 14.5847789],
                [109.0705507, 14.5850347],
                [109.0698679, 14.5862623],
                [109.0677662, 14.5891784],
                [109.0668729, 14.5902016],
                [109.0661372, 14.5905598],
                [109.0653752, 14.591097],
                [109.0646393, 14.5912506],
                [109.0632727, 14.591251],
                [109.0615906, 14.5912514],
                [109.0607496, 14.5912006],
                [109.0601532, 14.5915029],
                [109.0586476, 14.5934004],
                [109.0573339, 14.5952421],
                [109.0565983, 14.5963674],
                [109.0561255, 14.5974928],
                [109.0554953, 14.5998457],
                [109.0548719, 14.6019943],
                [109.0547601, 14.6025566],
                [109.0547076, 14.6030169],
                [109.0546026, 14.6032727],
                [109.0540769, 14.6034262],
                [109.0537013, 14.6035435],
                [109.0530781, 14.6034776],
                [109.0518165, 14.6033244],
                [109.0496087, 14.6034272],
                [109.0487678, 14.6036831],
                [109.0484524, 14.6039389],
                [109.0483999, 14.6043481],
                [109.0484002, 14.6050641],
                [109.0484002, 14.6056268],
                [109.0483477, 14.6061895],
                [109.0483479, 14.6068031],
                [109.0482955, 14.6073658],
                [109.0480329, 14.6080307],
                [109.0478225, 14.6083377],
                [109.0476123, 14.6086446],
                [109.0471919, 14.6088492],
                [109.0468764, 14.6088493],
                [109.0461404, 14.6089006],
                [109.0457199, 14.6089518],
                [109.0452994, 14.6091054],
                [109.0449315, 14.6094123],
                [109.0446687, 14.6096169],
                [109.0445636, 14.609975],
                [109.0443009, 14.6105888],
                [109.0441171, 14.6112282],
                [109.0447484, 14.6128587],
                [109.0451693, 14.6146999],
                [109.0455901, 14.6158249],
                [109.0460633, 14.6164898],
                [109.046484, 14.6169501],
                [109.0464841, 14.6175126],
                [109.0464842, 14.6178706],
                [109.0462479, 14.6186634],
                [109.0454596, 14.6200957],
                [109.0428843, 14.6235742],
                [109.0425689, 14.624188],
                [109.0424114, 14.6251087],
                [109.042832, 14.6255177],
                [109.0435155, 14.6257734],
                [109.0446722, 14.6260288],
                [109.0451717, 14.6265145],
                [109.0454873, 14.6269749],
                [109.0456976, 14.6274351],
                [109.045803, 14.6278955],
                [109.0458032, 14.6291742],
                [109.0458033, 14.6296343],
                [109.0453829, 14.6307086],
                [109.0446995, 14.6312714],
                [109.0443317, 14.6319364],
                [109.0441215, 14.6326013],
                [109.0436224, 14.6339568],
                [109.0420982, 14.6365655],
                [109.0412046, 14.6376397],
                [109.0408892, 14.6379979],
                [109.0404161, 14.6382536],
                [109.0399955, 14.6384583],
                [109.0396276, 14.6392256],
                [109.0392071, 14.6395837],
                [109.0383661, 14.6400953],
                [109.0375773, 14.6402999],
                [109.0369991, 14.6404023],
                [109.0367888, 14.640607],
                [109.0367363, 14.6408627],
                [109.0368416, 14.6414253],
                [109.037236, 14.6420555],
                [109.0374465, 14.6434865],
                [109.0373941, 14.6443553],
                [109.0372365, 14.6449687],
                [109.0368159, 14.6453264],
                [109.0357644, 14.6456844],
                [109.0353439, 14.6458889],
                [109.0353438, 14.6460423],
                [109.0352388, 14.6463999],
                [109.0350811, 14.646911],
                [109.0350813, 14.6473711],
                [109.0348184, 14.6480867],
                [109.0345029, 14.6483933],
                [109.0341875, 14.6484445],
                [109.0339247, 14.6483934],
                [109.0333462, 14.6478313],
                [109.0326626, 14.647627],
                [109.0321895, 14.6478826],
                [109.0317689, 14.648087],
                [109.031401, 14.648496],
                [109.0310329, 14.6485983],
                [109.0308752, 14.6485982],
                [109.0306122, 14.648445],
                [109.0304019, 14.6482406],
                [109.0299287, 14.6475762],
                [109.0294027, 14.6462474],
                [109.02935, 14.6457364],
                [109.0292974, 14.6454298],
                [109.0291396, 14.6454298],
                [109.0287717, 14.6454298],
                [109.0284036, 14.6455832],
                [109.0273521, 14.6460433],
                [109.0269316, 14.6461967],
                [109.026879, 14.6462989],
                [109.026879, 14.6464523],
                [109.026879, 14.64681],
                [109.0268791, 14.6472699],
                [109.0268792, 14.6477299],
                [109.0271422, 14.6490076],
                [109.0273526, 14.6500809],
                [109.0272475, 14.6506941],
                [109.0270898, 14.6508987],
                [109.0265116, 14.6513076],
                [109.0261961, 14.6518187],
                [109.0260384, 14.6520743],
                [109.0258807, 14.652432],
                [109.0257755, 14.6527387],
                [109.0254603, 14.653352],
                [109.0249871, 14.6545787],
                [109.0235153, 14.6563165],
                [109.022733, 14.6573549],
                [109.0211859, 14.6583597],
                [109.0211861, 14.6593976],
                [109.0208659, 14.659813],
                [109.0185183, 14.6603321],
                [109.0171312, 14.6608512],
                [109.0175581, 14.6622005],
                [109.0174516, 14.664484],
                [109.0169181, 14.6656259],
                [109.0159614, 14.6658363],
                [109.0148282, 14.6659588],
                [109.013569, 14.666632],
                [109.0130023, 14.6671828],
                [109.0112395, 14.6687741],
                [109.0094767, 14.669998],
                [109.0082804, 14.6704876],
                [109.0073359, 14.6705489],
                [109.005573, 14.6699371],
                [109.003873, 14.6687743],
                [109.0028656, 14.6682847],
                [109.0020471, 14.6682235],
                [109.0004731, 14.6687131],
                [108.9983953, 14.6697534],
                [108.9970416, 14.6704687],
                [108.9960342, 14.6717546],
                [108.9949009, 14.673347],
                [108.9940194, 14.6740206],
                [108.992949, 14.6740206],
                [108.9906824, 14.673163],
                [108.9891713, 14.6725506],
                [108.9865898, 14.6719381],
                [108.9833788, 14.6718154],
                [108.9822139, 14.6717235],
                [108.9805769, 14.6720908],
                [108.9794436, 14.6712333],
                [108.9785622, 14.6708658],
                [108.9777437, 14.6711106],
                [108.976988, 14.6720292],
                [108.9751619, 14.6731314],
                [108.9746747, 14.6734571],
                [108.9741544, 14.6738049],
                [108.9740973, 14.6739049],
                [108.9735247, 14.6749072],
                [108.9713836, 14.6776628],
                [108.9703131, 14.6784589],
                [108.9697629, 14.6791584],
                [108.9686757, 14.6805408],
                [108.9645511, 14.6835717],
                [108.9611505, 14.6852859],
                [108.9593989, 14.6867],
                [108.9586306, 14.6838471],
                [108.9566881, 14.6831253],
                [108.9556554, 14.6819604],
                [108.9554472, 14.6814687],
                [108.9552455, 14.6811266],
                [108.9549499, 14.6809789],
                [108.9546892, 14.6810304],
                [108.9544515, 14.6812194],
                [108.9542881, 14.6814714],
                [108.9541939, 14.6817985],
                [108.9541435, 14.6820924],
                [108.9541823, 14.6823605],
                [108.9543772, 14.6826529],
                [108.9547112, 14.6830809],
                [108.954785, 14.6832593],
                [108.9547709, 14.6834512],
                [108.9547064, 14.6836359],
                [108.9545501, 14.6838106],
                [108.954307, 14.6838979],
                [108.9539063, 14.6840513],
                [108.9535339, 14.6841729],
                [108.9528538, 14.6842624],
                [108.9520509, 14.6844029],
                [108.9511875, 14.6848138],
                [108.9506097, 14.6849645],
                [108.9501632, 14.6850624],
                [108.9500155, 14.6851518],
                [108.9499484, 14.6852924],
                [108.9499359, 14.6854639],
                [108.9503161, 14.6862828],
                [108.9505014, 14.6866232],
                [108.9505542, 14.6868789],
                [108.9504988, 14.687154],
                [108.9503339, 14.6874707],
                [108.9500845, 14.6878304],
                [108.9497927, 14.6881825],
                [108.949622, 14.6882514],
                [108.9493025, 14.6881637],
                [108.9489565, 14.6882354],
                [108.9487135, 14.6883549],
                [108.9484582, 14.6883947],
                [108.9482399, 14.6883708],
                [108.9479846, 14.6882115],
                [108.9475316, 14.6878091],
                [108.9472185, 14.68759],
                [108.9469756, 14.6874705],
                [108.9469467, 14.6876458],
                [108.9469756, 14.6881039],
                [108.9468039, 14.6883998],
                [108.9464031, 14.6889206],
                [108.9460366, 14.6891955],
                [108.9457834, 14.6893917],
                [108.9454641, 14.6894186],
                [108.9452499, 14.6893628],
                [108.9451676, 14.6891835],
                [108.9449369, 14.688821],
                [108.9446857, 14.6885143],
                [108.9444015, 14.6885023],
                [108.9441585, 14.688813],
                [108.943689, 14.6891716],
                [108.9432525, 14.6893031],
                [108.9428365, 14.6893708],
                [108.9425194, 14.6895341],
                [108.9422682, 14.689817],
                [108.9419833, 14.6901597],
                [108.9417792, 14.6904821],
                [108.9416898, 14.6907797],
                [108.9416792, 14.6911794],
                [108.941811, 14.6913587],
                [108.9417822, 14.691785],
                [108.9415475, 14.6922511],
                [108.9412509, 14.6928526],
                [108.9408885, 14.6935418],
                [108.940489, 14.6942748],
                [108.9401595, 14.6946134],
                [108.9399672, 14.6949217],
                [108.9398589, 14.6953066],
                [108.9393482, 14.6952668],
                [108.9386398, 14.6951632],
                [108.9381456, 14.695203],
                [108.9377482, 14.6953181],
                [108.9375279, 14.695446],
                [108.9372313, 14.6956014],
                [108.9369842, 14.6957329],
                [108.9367278, 14.6961363],
                [108.9363212, 14.6966093],
                [108.9359752, 14.696685],
                [108.9355057, 14.6965854],
                [108.9348838, 14.6965416],
                [108.9344596, 14.6967487],
                [108.9341631, 14.6969559],
                [108.9339572, 14.6971869],
                [108.9335494, 14.6973981],
                [108.9331185, 14.6974748],
                [108.9328658, 14.6975056],
                [108.9325404, 14.6974498],
                [108.9319515, 14.6974259],
                [108.9315808, 14.6974976],
                [108.931412, 14.6976172],
                [108.9311237, 14.6978841],
                [108.9307118, 14.6977885],
                [108.9299815, 14.6975234],
                [108.9298002, 14.6974227],
                [108.9287599, 14.6983661],
                [108.9284874, 14.6988071],
                [108.9284508, 14.6992661],
                [108.9286683, 14.7007312],
                [108.9287405, 14.7020905],
                [108.9286858, 14.7028495],
                [108.9285223, 14.7030612],
                [108.928268, 14.7032024],
                [108.9279955, 14.7031141],
                [108.9274509, 14.702443],
                [108.9264342, 14.701066],
                [108.9256081, 14.7001944],
                [108.9251723, 14.6996644],
                [108.9249001, 14.6988871],
                [108.9246641, 14.6984984],
                [108.9243826, 14.6981362],
                [108.9239833, 14.6978181],
                [108.9231479, 14.6974469],
                [108.9216042, 14.6968634],
                [108.9204965, 14.6964214],
                [108.9200941, 14.6962022],
                [108.9194886, 14.6959],
                [108.9184535, 14.6955286],
                [108.9175092, 14.695069],
                [108.9159655, 14.6945739],
                [108.911362, 14.6929735],
                [108.9084292, 14.6919391],
                [108.907394, 14.6917443],
                [108.906831, 14.6915675],
                [108.9066222, 14.6914349],
                [108.9064044, 14.6911168],
                [108.9062638, 14.6902686],
                [108.9062028, 14.6892834],
                [108.9061416, 14.6887461],
                [108.9058349, 14.6880892],
                [108.9054054, 14.6876412],
                [108.9049145, 14.6872529],
                [108.9043007, 14.6870138],
                [108.902137, 14.6866696],
                [108.8994973, 14.6866984],
                [108.8957839, 14.6866004],
                [108.8935661, 14.6862536],
                [108.892647, 14.6862127],
                [108.8925326, 14.6862076],
                [108.8924277, 14.6862029],
                [108.8917219, 14.6859041],
                [108.8912259, 14.6855452],
                [108.8908579, 14.6848883],
                [108.8904287, 14.6839926],
                [108.8900301, 14.6829774],
                [108.8899075, 14.682649],
                [108.8896928, 14.6824398],
                [108.889432, 14.6823352],
                [108.88891, 14.6826634],
                [108.8879889, 14.6834093],
                [108.8876205, 14.6835285],
                [108.8863006, 14.6836473],
                [108.8847657, 14.6840644],
                [108.8802531, 14.6851966],
                [108.8786416, 14.6855091],
                [108.8779047, 14.685867],
                [108.8762464, 14.687329],
                [108.8754172, 14.6881643],
                [108.8751103, 14.688194],
                [108.8747114, 14.6878953],
                [108.8743739, 14.6878355],
                [108.8741895, 14.6881638],
                [108.8740047, 14.6893279],
                [108.8736972, 14.6901039],
                [108.8733134, 14.6904768],
                [108.8723924, 14.6907151],
                [108.871226, 14.6907444],
                [108.8704279, 14.6908036],
                [108.8700288, 14.6909527],
                [108.8693224, 14.6916389],
                [108.8688618, 14.6919969],
                [108.8682785, 14.692116],
                [108.867235, 14.6918467],
                [108.866652, 14.6916971],
                [108.8659, 14.6915922],
                [108.8643652, 14.6918301],
                [108.8615718, 14.6920971],
                [108.8593923, 14.6923048],
                [108.8584562, 14.6922595],
                [108.8581493, 14.6921697],
                [108.857904, 14.691871],
                [108.8577201, 14.6913933],
                [108.8575054, 14.6911543],
                [108.8570758, 14.6910046],
                [108.856247, 14.6911236],
                [108.855111, 14.6916006],
                [108.8541287, 14.6916896],
                [108.8535763, 14.6915101],
                [108.8529935, 14.6909425],
                [108.8525642, 14.6902556],
                [108.8520124, 14.6892701],
                [108.8520284, 14.6882402],
                [108.8518756, 14.6872848],
                [108.8516305, 14.6865682],
                [108.851232, 14.6859112],
                [108.8512936, 14.6854336],
                [108.8519697, 14.684419],
                [108.8521082, 14.6837773],
                [108.8520164, 14.6833593],
                [108.851771, 14.6831203],
                [108.851188, 14.6830005],
                [108.8500828, 14.6831789],
                [108.8490393, 14.6831782],
                [108.8478398, 14.6828498],
                [108.8471708, 14.6828679],
                [108.8468696, 14.682876],
                [108.8463688, 14.6833257],
                [108.8460002, 14.683833],
                [108.8457236, 14.6844299],
                [108.8458152, 14.6848776],
                [108.8462752, 14.6855945],
                [108.8463054, 14.6861318],
                [108.8460903, 14.6866989],
                [108.8454142, 14.6878926],
                [108.8436629, 14.6903394],
                [108.8426333, 14.6921746],
                [108.8418039, 14.6931293],
                [108.8410361, 14.693696],
                [108.8400843, 14.6940835],
                [108.8391633, 14.6943217],
                [108.8385187, 14.6942913],
                [108.8381812, 14.6940224],
                [108.8374143, 14.693395],
                [108.8366476, 14.6926183],
                [108.8357121, 14.6917071],
                [108.8348841, 14.6906616],
                [108.8342711, 14.6895567],
                [108.8338425, 14.6879742],
                [108.833674, 14.687571],
                [108.8329075, 14.6864659],
                [108.8318958, 14.6848531],
                [108.8314977, 14.6836587],
                [108.8306705, 14.6817774],
                [108.8299193, 14.6808365],
                [108.8293059, 14.6801195],
                [108.8285397, 14.6788055],
                [108.8279266, 14.67779],
                [108.8272827, 14.6769836],
                [108.8267766, 14.6765205],
                [108.8261632, 14.6760125],
                [108.825488, 14.6758626],
                [108.8249968, 14.6760713],
                [108.824352, 14.6764888],
                [108.8234309, 14.6768762],
                [108.8228478, 14.6768758],
                [108.8223569, 14.6766962],
                [108.8208536, 14.6757697],
                [108.8190439, 14.6744249],
                [108.8163287, 14.6732137],
                [108.8148561, 14.6725259],
                [108.8142733, 14.6720776],
                [108.8139668, 14.6715698],
                [108.8137833, 14.6708832],
                [108.8134766, 14.6704948],
                [108.8132005, 14.6704349],
                [108.8120646, 14.6707624],
                [108.8115887, 14.6709858],
                [108.8106372, 14.6711044],
                [108.8097164, 14.6710739],
                [108.8090416, 14.6707449],
                [108.8084282, 14.6701175],
                [108.8078761, 14.6697887],
                [108.807201, 14.6696687],
                [108.8066485, 14.6696981],
                [108.8050523, 14.6700252],
                [108.8022127, 14.6707542],
                [108.8014148, 14.6706938],
                [108.8006785, 14.6703051],
                [108.7997891, 14.6695878],
                [108.7991758, 14.66905],
                [108.798777, 14.6688406],
                [108.7981018, 14.66884],
                [108.7933289, 14.6693881],
                [108.7922853, 14.6695065],
                [108.7909348, 14.6695053],
                [108.7894463, 14.669519],
                [108.788556, 14.6696973],
                [108.7879727, 14.6700251],
                [108.7871434, 14.6705915],
                [108.7866215, 14.6708001],
                [108.7859462, 14.6708292],
                [108.7854245, 14.6708288],
                [108.7842584, 14.6706188],
                [108.7831231, 14.6703789],
                [108.7821563, 14.6703929],
                [108.7812966, 14.6706906],
                [108.7802832, 14.6714062],
                [108.7786853, 14.6731659],
                [108.7774875, 14.6740603],
                [108.7763207, 14.6745369],
                [108.7737102, 14.6761464],
                [108.7716679, 14.6773833],
                [108.7704706, 14.6777404],
                [108.7696108, 14.6780977],
                [108.7692118, 14.6781271],
                [108.7689051, 14.6778881],
                [108.7683226, 14.6772904],
                [108.7678015, 14.6767824],
                [108.7674641, 14.6764537],
                [108.7671575, 14.6760952],
                [108.7671275, 14.6754384],
                [108.7672208, 14.6743937],
                [108.7669762, 14.673468],
                [108.7666391, 14.6730198],
                [108.7661328, 14.6728849],
                [108.7654576, 14.6729142],
                [108.7646901, 14.6730328],
                [108.7637072, 14.6730314],
                [108.7632479, 14.6727925],
                [108.7629106, 14.6724339],
                [108.7627885, 14.6718367],
                [108.7626669, 14.6707917],
                [108.762514, 14.6703438],
                [108.7622076, 14.6697465],
                [108.7614719, 14.6689098],
                [108.7600004, 14.6674754],
                [108.7598625, 14.6671617],
                [108.7594948, 14.6667136],
                [108.7593725, 14.6662657],
                [108.7597418, 14.6653406],
                [108.7610638, 14.6632224],
                [108.761556, 14.6621183],
                [108.7616336, 14.6614169],
                [108.7617583, 14.6595065],
                [108.7620978, 14.6577455],
                [108.7621143, 14.6567156],
                [108.762115, 14.6559991],
                [108.7623305, 14.6554619],
                [108.7633444, 14.6544778],
                [108.763621, 14.653911],
                [108.7637446, 14.6531648],
                [108.7642224, 14.65121],
                [108.7645, 14.6499265],
                [108.7646854, 14.6486729],
                [108.7647022, 14.6473444],
                [108.7646574, 14.6472308],
                [108.7645492, 14.6469562],
                [108.7639976, 14.6462092],
                [108.7638756, 14.6455226],
                [108.7641524, 14.6448959],
                [108.7647671, 14.6439711],
                [108.7651821, 14.6432999],
                [108.7653055, 14.642703],
                [108.7653057, 14.6424814],
                [108.765307, 14.6413596],
                [108.7651851, 14.6404937],
                [108.7646049, 14.6378958],
                [108.7644978, 14.6375526],
                [108.7644677, 14.6369853],
                [108.7645611, 14.6357912],
                [108.7658228, 14.6324491],
                [108.7663616, 14.6307031],
                [108.7663934, 14.6297778],
                [108.7663947, 14.6284344],
                [108.7660597, 14.6260159],
                [108.7658317, 14.6239708],
                [108.7656793, 14.6230153],
                [108.7655573, 14.6223883],
                [108.7654041, 14.6221494],
                [108.7643922, 14.6215214],
                [108.7636408, 14.6211475],
                [108.762414, 14.620579],
                [108.7619541, 14.6202203],
                [108.7615251, 14.6197721],
                [108.7614032, 14.6189361],
                [108.7614357, 14.6172345],
                [108.7612529, 14.6160402],
                [108.7612689, 14.6153984],
                [108.7613328, 14.6131296],
                [108.7615202, 14.610055],
                [108.7616613, 14.6072341],
                [108.7616935, 14.605801],
                [108.7617862, 14.6052639],
                [108.7620784, 14.6045328],
                [108.7627236, 14.6037871],
                [108.7630923, 14.6032501],
                [108.7633078, 14.6025936],
                [108.7634931, 14.6014892],
                [108.7635247, 14.6005936],
                [108.76351, 14.599922],
                [108.7633877, 14.5995934],
                [108.7631423, 14.5994737],
                [108.7624062, 14.5993237],
                [108.7612404, 14.5992032],
                [108.7606577, 14.5989936],
                [108.7601366, 14.5986647],
                [108.7596768, 14.5982164],
                [108.759187, 14.5971114],
                [108.758545, 14.5951106],
                [108.7583769, 14.5945283],
                [108.7581012, 14.5940205],
                [108.7577334, 14.5937215],
                [108.7573042, 14.5933926],
                [108.7563534, 14.5931827],
                [108.7547887, 14.5931513],
                [108.7537762, 14.5932099],
                [108.7528865, 14.5931194],
                [108.7519665, 14.5928199],
                [108.7503104, 14.5922509],
                [108.7491907, 14.5921153],
                [108.7479943, 14.5919947],
                [108.7464293, 14.5922318],
                [108.745263, 14.5925888],
                [108.7443418, 14.5933042],
                [108.7424071, 14.5948544],
                [108.7415322, 14.5952566],
                [108.7410721, 14.595256],
                [108.74052, 14.5951062],
                [108.7403362, 14.5948074],
                [108.7402149, 14.5937027],
                [108.7404008, 14.5920908],
                [108.7404018, 14.5912251],
                [108.7402489, 14.5907473],
                [108.7397281, 14.5901795],
                [108.7384255, 14.5890288],
                [108.7379351, 14.5886699],
                [108.737659, 14.5886697],
                [108.7367384, 14.5887581],
                [108.7361858, 14.5891157],
                [108.7351411, 14.5903983],
                [108.7344126, 14.5911055],
                [108.7343425, 14.5911735],
                [108.7337285, 14.5916206],
                [108.733268, 14.5917991],
                [108.7325316, 14.5918581],
                [108.731688, 14.5917825],
                [108.7308287, 14.5919905],
                [108.7301532, 14.5923777],
                [108.729017, 14.5931825],
                [108.7280653, 14.5937187],
                [108.7275432, 14.5941361],
                [108.7268672, 14.5949412],
                [108.7263754, 14.5957169],
                [108.7259144, 14.5964029],
                [108.7256228, 14.596507],
                [108.7242422, 14.5964458],
                [108.7232576, 14.5962933],
                [108.7230765, 14.5962652],
                [108.7224628, 14.5963541],
                [108.7217873, 14.5967712],
                [108.7212653, 14.5971886],
                [108.7205284, 14.597516],
                [108.7197611, 14.5977838],
                [108.7189017, 14.5980514],
                [108.7186097, 14.5983646],
                [108.7183321, 14.5995882],
                [108.7179015, 14.6004534],
                [108.7174406, 14.60102],
                [108.7167956, 14.6014969],
                [108.7157826, 14.6018837],
                [108.7148927, 14.602032],
                [108.7140026, 14.60221],
                [108.7131739, 14.6025373],
                [108.7115927, 14.6032967],
                [108.7097503, 14.6044587],
                [108.7072009, 14.6066346],
                [108.7061561, 14.6077678],
                [108.7056185, 14.6082895],
                [108.7054643, 14.6088266],
                [108.7055862, 14.6094836],
                [108.7061677, 14.6106485],
                [108.7069635, 14.6119929],
                [108.7074536, 14.6126802],
                [108.7076219, 14.6130833],
                [108.707529, 14.6136505],
                [108.707314, 14.6139189],
                [108.7068842, 14.6140975],
                [108.7057488, 14.6141259],
                [108.7039079, 14.614034],
                [108.7026504, 14.6136444],
                [108.7020679, 14.6133152],
                [108.7016692, 14.6131356],
                [108.7014855, 14.6128667],
                [108.7014554, 14.6124785],
                [108.7015482, 14.6118518],
                [108.7021635, 14.6105987],
                [108.702441, 14.6096438],
                [108.7025034, 14.6088976],
                [108.7024432, 14.607972],
                [108.7023978, 14.6075689],
                [108.7024291, 14.6071212],
                [108.7026754, 14.6064946],
                [108.7027373, 14.6060469],
                [108.7025845, 14.6055989],
                [108.7022476, 14.6051507],
                [108.7015736, 14.6044931],
                [108.7012369, 14.603806],
                [108.7008711, 14.6020741],
                [108.7005193, 14.6013721],
                [108.6997537, 14.6002665],
                [108.6985599, 14.5982051],
                [108.6978254, 14.5968907],
                [108.6974732, 14.5963379],
                [108.6967378, 14.5956803],
                [108.6950211, 14.594663],
                [108.6936721, 14.5939747],
                [108.6931819, 14.5935561],
                [108.6925385, 14.5928985],
                [108.6911902, 14.5916727],
                [108.6908378, 14.5913887],
                [108.6895501, 14.59076],
                [108.6885379, 14.5906094],
                [108.6878016, 14.5906084],
                [108.6873717, 14.5908467],
                [108.6870645, 14.5911449],
                [108.6869817, 14.5913226],
                [108.6866337, 14.5920696],
                [108.6865402, 14.5930248],
                [108.6864168, 14.5935022],
                [108.685956, 14.5940091],
                [108.6842363, 14.5950816],
                [108.6821631, 14.5966758],
                [108.6813952, 14.5973017],
                [108.6811496, 14.597391],
                [108.6808429, 14.5972413],
                [108.6805979, 14.5969424],
                [108.6795881, 14.59512],
                [108.6785468, 14.5938648],
                [108.6770767, 14.5920715],
                [108.6756215, 14.5907411],
                [108.6753152, 14.5903526],
                [108.6753462, 14.5901138],
                [108.6757455, 14.5898157],
                [108.6763598, 14.5893689],
                [108.6767591, 14.5889515],
                [108.6772821, 14.5879671],
                [108.6774685, 14.5864448],
                [108.6773935, 14.5852954],
                [108.6770572, 14.5844591],
                [108.6767203, 14.5840705],
                [108.6752805, 14.5826058],
                [108.6731969, 14.5809012],
                [108.672722, 14.5804677],
                [108.6721702, 14.5801684],
                [108.6712196, 14.5798984],
                [108.6702994, 14.5798374],
                [108.6692255, 14.5798657],
                [108.6685506, 14.5798647],
                [108.6679371, 14.5798042],
                [108.6671703, 14.5797135],
                [108.6658519, 14.579234],
                [108.6627405, 14.5775428],
                [108.6609624, 14.5767342],
                [108.6597673, 14.5759265],
                [108.6586949, 14.5750592],
                [108.6570244, 14.5740866],
                [108.6561663, 14.5735478],
                [108.6554309, 14.5730095],
                [108.6548183, 14.5723817],
                [108.6542975, 14.5719331],
                [108.6539605, 14.5716937],
                [108.6531324, 14.5715135],
                [108.651998, 14.5711535],
                [108.6510782, 14.570794],
                [108.6503273, 14.5703002],
                [108.6496537, 14.5695529],
                [108.649133, 14.5689849],
                [108.6486732, 14.5688051],
                [108.6477839, 14.568565],
                [108.6468944, 14.568474],
                [108.6457291, 14.5682334],
                [108.6450855, 14.5677847],
                [108.6441359, 14.5669473],
                [108.6435689, 14.5666629],
                [108.642189, 14.5663324],
                [108.6410237, 14.5660619],
                [108.6405639, 14.5658523],
                [108.6402882, 14.5655831],
                [108.6401661, 14.5652247],
                [108.6403206, 14.5645683],
                [108.6411523, 14.5624201],
                [108.6427671, 14.5597507],
                [108.643536, 14.5584982],
                [108.6437211, 14.5578417],
                [108.6437375, 14.5571999],
                [108.6435854, 14.5564234],
                [108.6431268, 14.5554973],
                [108.6425757, 14.5548397],
                [108.6422392, 14.554272],
                [108.6421477, 14.5538837],
                [108.6422405, 14.5534063],
                [108.6425029, 14.5524066],
                [108.6428113, 14.5513324],
                [108.6428747, 14.5500786],
                [108.6426615, 14.5491827],
                [108.6422028, 14.5482566],
                [108.6417282, 14.5477334],
                [108.6410539, 14.547404],
                [108.64041, 14.5471941],
                [108.6395209, 14.5469837],
                [108.6389073, 14.5470724],
                [108.6383238, 14.5474894],
                [108.6366336, 14.5493974],
                [108.6358964, 14.5500231],
                [108.6346069, 14.5507376],
                [108.6326887, 14.5514062],
                [108.6321058, 14.5514054],
                [108.6312164, 14.5513143],
                [108.6301744, 14.5508052],
                [108.6287961, 14.5495791],
                [108.6280005, 14.5483837],
                [108.627909, 14.5480553],
                [108.6279105, 14.5471895],
                [108.6280351, 14.5460851],
                [108.6284888, 14.5450585],
                [108.6280113, 14.5440979],
                [108.6279729, 14.5437592],
                [108.6279631, 14.5435891],
                [108.627962, 14.543259],
                [108.6279845, 14.5429296],
                [108.6280118, 14.5428356],
                [108.6280843, 14.5426535],
                [108.6281294, 14.5425661],
                [108.6282443, 14.5423893],
                [108.6283108, 14.542307],
                [108.6284602, 14.5421562],
                [108.6285424, 14.5420884],
                [108.628757, 14.5410603],
                [108.6282956, 14.5403645],
                [108.628065, 14.5401049],
                [108.6280761, 14.5398024],
                [108.6281186, 14.5395025],
                [108.6281523, 14.5393513],
                [108.6282437, 14.5390549],
                [108.628301, 14.5389105],
                [108.6283423, 14.5387253],
                [108.6283507, 14.538536],
                [108.6283423, 14.5384415],
                [108.628301, 14.5382563],
                [108.6284941, 14.5379291],
                [108.6285102, 14.537763],
                [108.6285037, 14.5375504],
                [108.628528, 14.5374639],
                [108.6285751, 14.5373878],
                [108.6286796, 14.5372903],
                [108.6287436, 14.5372074],
                [108.6285531, 14.5369373],
                [108.6284995, 14.536801],
                [108.6284745, 14.5366517],
                [108.6284742, 14.5365733],
                [108.6284996, 14.5364186],
                [108.628525, 14.5363441],
                [108.6286906, 14.5359897],
                [108.6289716, 14.5354315],
                [108.6293122, 14.5348191],
                [108.6297393, 14.534123],
                [108.6298361, 14.5339147],
                [108.6298956, 14.5337206],
                [108.6299264, 14.5335465],
                [108.6298981, 14.5328301],
                [108.6299166, 14.5325034],
                [108.6299657, 14.5321797],
                [108.6300768, 14.5317162],
                [108.6301235, 14.5314073],
                [108.6301359, 14.5312516],
                [108.6301393, 14.5309535],
                [108.6301179, 14.5306702],
                [108.6300981, 14.5305295],
                [108.6306748, 14.5305528],
                [108.6308132, 14.5305462],
                [108.6309484, 14.5305165],
                [108.6310204, 14.5304904],
                [108.6311552, 14.5304186],
                [108.6312166, 14.5303737],
                [108.6312461, 14.5301037],
                [108.6315036, 14.5295376],
                [108.631582, 14.5293447],
                [108.6316863, 14.5291636],
                [108.6318147, 14.5289976],
                [108.6318992, 14.5289095],
                [108.6319904, 14.5288281],
                [108.6320879, 14.5287537],
                [108.6321911, 14.5286869],
                [108.6322993, 14.528628],
                [108.6324119, 14.5285774],
                [108.6325282, 14.5285354],
                [108.6326894, 14.5284609],
                [108.6328468, 14.5283788],
                [108.6331481, 14.528193],
                [108.6332914, 14.5280897],
                [108.6334294, 14.5279798],
                [108.6336809, 14.5277482],
                [108.6339086, 14.5274948],
                [108.634013, 14.5273605],
                [108.6341107, 14.5272216],
                [108.635075, 14.5255954],
                [108.6351467, 14.5254198],
                [108.6351711, 14.5253283],
                [108.6351961, 14.525141],
                [108.6351889, 14.5249523],
                [108.6351473, 14.5247594],
                [108.6350722, 14.5245763],
                [108.6350226, 14.5244901],
                [108.6347776, 14.5242015],
                [108.6346456, 14.5240652],
                [108.6345077, 14.5239345],
                [108.6342541, 14.5237211],
                [108.6338995, 14.523469],
                [108.6338099, 14.5234178],
                [108.6336239, 14.5233382],
                [108.6334321, 14.5232879],
                [108.6324665, 14.5231555],
                [108.631804, 14.522657],
                [108.6317216, 14.5226056],
                [108.6316447, 14.5225466],
                [108.6315742, 14.5224806],
                [108.6315106, 14.5224083],
                [108.6314547, 14.5223303],
                [108.6314068, 14.5222475],
                [108.6313676, 14.5221605],
                [108.6313373, 14.5220702],
                [108.631305, 14.5218889],
                [108.6313023, 14.5217968],
                [108.6313239, 14.521614],
                [108.631348, 14.5215249],
                [108.6314335, 14.5213389],
                [108.6314843, 14.5211412],
                [108.6314989, 14.5209381],
                [108.6314924, 14.5208364],
                [108.6314547, 14.5206453],
                [108.6313887, 14.5204713],
                [108.6313453, 14.5203887],
                [108.6312387, 14.5202347],
                [108.6311763, 14.5201643],
                [108.6302268, 14.5193127],
                [108.6300492, 14.5190613],
                [108.6299028, 14.5187916],
                [108.6297897, 14.5185074],
                [108.6297461, 14.518361],
                [108.6296856, 14.518062],
                [108.6296689, 14.5179105],
                [108.6296613, 14.5177551],
                [108.6296632, 14.5175994],
                [108.6296746, 14.5174442],
                [108.6296955, 14.5172899],
                [108.6297259, 14.517137],
                [108.6297655, 14.5169862],
                [108.6299512, 14.5166396],
                [108.6300544, 14.5164719],
                [108.6302809, 14.516149],
                [108.6305314, 14.5158461],
                [108.6308039, 14.5155646],
                [108.6309484, 14.5154318],
                [108.6312529, 14.5151829],
                [108.6314124, 14.5150673],
                [108.6315802, 14.5148903],
                [108.6316735, 14.5148109],
                [108.6318764, 14.5146726],
                [108.6320988, 14.5145453],
                [108.6323041, 14.5143935],
                [108.6323994, 14.5143091],
                [108.6325501, 14.5141524],
                [108.6326192, 14.5140688],
                [108.6327326, 14.5139187],
                [108.6328116, 14.5137808],
                [108.6328635, 14.5136314],
                [108.6328855, 14.5134917],
                [108.6328805, 14.5132257],
                [108.6328674, 14.5130306],
                [108.6328232, 14.512642],
                [108.6327904, 14.5124392],
                [108.6327052, 14.512037],
                [108.6326477, 14.5118235],
                [108.6326231, 14.5116041],
                [108.6326233, 14.5114937],
                [108.6326486, 14.5112744],
                [108.6327067, 14.511061],
                [108.6327964, 14.5108582],
                [108.632856, 14.5107565],
                [108.6329236, 14.5106596],
                [108.6329987, 14.5105681],
                [108.6330809, 14.5104825],
                [108.6331697, 14.5104033],
                [108.6332646, 14.5103311],
                [108.6334602, 14.5102078],
                [108.6335498, 14.5101419],
                [108.6336333, 14.5100688],
                [108.6337107, 14.5099882],
                [108.6337806, 14.5099014],
                [108.6338425, 14.5098091],
                [108.6348778, 14.5079551],
                [108.6352981, 14.5075536],
                [108.6357415, 14.5071761],
                [108.6361571, 14.5068591],
                [108.6363711, 14.5067085],
                [108.636644, 14.5065292],
                [108.636694, 14.5064887],
                [108.6367763, 14.506391],
                [108.6368073, 14.5063355],
                [108.6368309, 14.5062766],
                [108.6368541, 14.5061529],
                [108.6368448, 14.5060278],
                [108.6367399, 14.5057039],
                [108.6366371, 14.5052877],
                [108.6365981, 14.505077],
                [108.6365319, 14.5046183],
                [108.6364653, 14.5043777],
                [108.6363686, 14.5041468],
                [108.6362434, 14.5039291],
                [108.6360868, 14.5037226],
                [108.6359963, 14.5036245],
                [108.6357964, 14.5034462],
                [108.6356879, 14.5033668],
                [108.6354561, 14.5032294],
                [108.6353338, 14.5031719],
                [108.6337888, 14.5027876],
                [108.6336558, 14.5027692],
                [108.6335245, 14.5027412],
                [108.6333957, 14.5027039],
                [108.6332702, 14.5026574],
                [108.6331486, 14.5026019],
                [108.6330316, 14.5025379],
                [108.6329198, 14.5024656],
                [108.6327262, 14.502309],
                [108.632557, 14.5021278],
                [108.6324155, 14.5019255],
                [108.632437, 14.4991522],
                [108.6320776, 14.4986432],
                [108.6319859, 14.4984857],
                [108.6319175, 14.4983174],
                [108.6318925, 14.4982303],
                [108.6318601, 14.4980381],
                [108.6318576, 14.4978434],
                [108.6319059, 14.4975639],
                [108.6319887, 14.4972921],
                [108.6321202, 14.4969742],
                [108.6321452, 14.4968766],
                [108.6321606, 14.4967772],
                [108.6321664, 14.4966769],
                [108.6321625, 14.4965764],
                [108.632149, 14.4964768],
                [108.6320972, 14.496293],
                [108.6320648, 14.4961157],
                [108.6320615, 14.4960256],
                [108.6320788, 14.4958548],
                [108.632099, 14.4957711],
                [108.632378, 14.4952154],
                [108.6324738, 14.4950741],
                [108.6325311, 14.4950102],
                [108.6326623, 14.4948986],
                [108.6328081, 14.4948132],
                [108.6329671, 14.4947538],
                [108.6331344, 14.494722],
                [108.6337674, 14.4946597],
                [108.6338777, 14.4946403],
                [108.6340924, 14.4945774],
                [108.6341953, 14.4945343],
                [108.6343896, 14.494426],
                [108.6346056, 14.4942598],
                [108.6348027, 14.4940728],
                [108.6349871, 14.493856],
                [108.635146, 14.4936209],
                [108.635143, 14.4930441],
                [108.6351317, 14.4927559],
                [108.635091, 14.4921973],
                [108.635028, 14.4916577],
                [108.6345613, 14.4911955],
                [108.6352318, 14.4906865],
                [108.635451, 14.4905406],
                [108.6359027, 14.4902687],
                [108.6361348, 14.490143],
                [108.6363708, 14.4900242],
                [108.636766, 14.4898448],
                [108.6369172, 14.4897685],
                [108.6370636, 14.4896839],
                [108.6372048, 14.4895913],
                [108.6373403, 14.489491],
                [108.6374695, 14.4893833],
                [108.6375922, 14.4892686],
                [108.6377872, 14.4890552],
                [108.6378763, 14.4889418],
                [108.6380365, 14.4887028],
                [108.6381072, 14.4885778],
                [108.6385846, 14.4874196],
                [108.6387002, 14.4871917],
                [108.6387669, 14.4870823],
                [108.6389172, 14.4868742],
                [108.6390988, 14.4866719],
                [108.6393019, 14.4864897],
                [108.6394107, 14.4864068],
                [108.6394689, 14.4862315],
                [108.6395522, 14.485902],
                [108.6396005, 14.4855953],
                [108.6396146, 14.4854407],
                [108.6395341, 14.4839682],
                [108.639282, 14.4835397],
                [108.6391793, 14.4833282],
                [108.6391103, 14.4831043],
                [108.6390534, 14.482736],
                [108.6390222, 14.4826117],
                [108.6389333, 14.4823711],
                [108.6388119, 14.4821464],
                [108.6387395, 14.4820407],
                [108.6386111, 14.4818857],
                [108.6385559, 14.4818376],
                [108.6384949, 14.4817967],
                [108.6383701, 14.4817416],
                [108.6381142, 14.4816897],
                [108.6378551, 14.481614],
                [108.6376067, 14.48151],
                [108.6374875, 14.4814478],
                [108.6372588, 14.4813023],
                [108.6371502, 14.4812192],
                [108.6369491, 14.4810352],
                [108.6368573, 14.480935],
                [108.6387938, 14.4806883],
                [108.6389348, 14.4806539],
                [108.6390731, 14.4806108],
                [108.6392083, 14.4805589],
                [108.6393396, 14.4804986],
                [108.6394666, 14.4804301],
                [108.6395887, 14.4803537],
                [108.6397053, 14.4802697],
                [108.639816, 14.4801786],
                [108.6399203, 14.4800806],
                [108.6400173, 14.4799768],
                [108.640107, 14.4798672],
                [108.6401892, 14.4797521],
                [108.6402634, 14.4796321],
                [108.6403295, 14.4795077],
                [108.6403871, 14.4793794],
                [108.6407304, 14.4783613],
                [108.6411567, 14.4778112],
                [108.6413778, 14.4775421],
                [108.6418354, 14.4770161],
                [108.6423624, 14.4764567],
                [108.6426347, 14.476185],
                [108.64411, 14.4754942],
                [108.6439224, 14.4753088],
                [108.6438383, 14.4752076],
                [108.6436915, 14.4749904],
                [108.6435826, 14.4747739],
                [108.6435014, 14.4745463],
                [108.6434716, 14.4744294],
                [108.6433214, 14.4737074],
                [108.6432241, 14.4736979],
                [108.6431283, 14.473679],
                [108.6430349, 14.4736509],
                [108.642945, 14.4736138],
                [108.6428594, 14.4735681],
                [108.6427789, 14.4735143],
                [108.6426429, 14.4733913],
                [108.6425383, 14.4732531],
                [108.6424962, 14.4731776],
                [108.6424342, 14.4730171],
                [108.6424148, 14.4729335],
                [108.6423478, 14.4723595],
                [108.6423422, 14.472222],
                [108.6423285, 14.4721545],
                [108.6422801, 14.4720251],
                [108.6422458, 14.4719648],
                [108.6421499, 14.4718467],
                [108.6420924, 14.4717962],
                [108.6419615, 14.4717154],
                [108.6414205, 14.4715405],
                [108.6413066, 14.4714877],
                [108.6412082, 14.4714112],
                [108.6411664, 14.4713652],
                [108.6411005, 14.4712609],
                [108.641063, 14.4711541],
                [108.6410497, 14.4710421],
                [108.6410469, 14.470087],
                [108.6407344, 14.4687993],
                [108.6407029, 14.4685607],
                [108.6407016, 14.4683064],
                [108.6407141, 14.4681729],
                [108.6408323, 14.4675678],
                [108.6408492, 14.4674478],
                [108.6408561, 14.4672058],
                [108.6408269, 14.4669653],
                [108.6407651, 14.4667393],
                [108.6406714, 14.4665238],
                [108.6398882, 14.4654122],
                [108.6398935, 14.4632513],
                [108.640051, 14.4630116],
                [108.6401392, 14.462898],
                [108.6403334, 14.4626851],
                [108.6405526, 14.4624906],
                [108.6406699, 14.462402],
                [108.6409181, 14.4622436],
                [108.6402822, 14.4615206],
                [108.6402683, 14.4613022],
                [108.6402736, 14.4611928],
                [108.6403089, 14.4609767],
                [108.6403616, 14.4607577],
                [108.640375, 14.460646],
                [108.640377, 14.4604426],
                [108.6403671, 14.4603413],
                [108.6401565, 14.4600595],
                [108.6400168, 14.4598351],
                [108.6399458, 14.4596731],
                [108.6399203, 14.4595886],
                [108.6398916, 14.4594328],
                [108.6398858, 14.4593538],
                [108.6398856, 14.4592747],
                [108.6399045, 14.4590339],
                [108.6399244, 14.4589382],
                [108.6399535, 14.4588448],
                [108.6399917, 14.4587544],
                [108.6400385, 14.458668],
                [108.6400935, 14.4585862],
                [108.6402948, 14.4583605],
                [108.6403962, 14.4582151],
                [108.6404395, 14.458138],
                [108.640347, 14.4579514],
                [108.6403131, 14.4578531],
                [108.6402711, 14.4576501],
                [108.6402642, 14.4574465],
                [108.6402911, 14.4572478],
                [108.6403168, 14.4571506],
                [108.6405659, 14.4565024],
                [108.6405821, 14.456368],
                [108.640578, 14.4563004],
                [108.6405523, 14.456187],
                [108.6405308, 14.4561328],
                [108.6403011, 14.4557743],
                [108.6402362, 14.455625],
                [108.6402098, 14.4555385],
                [108.6398364, 14.4535237],
                [108.6397646, 14.4532078],
                [108.6396834, 14.4528939],
                [108.639593, 14.4525825],
                [108.6394123, 14.4520437],
                [108.6391505, 14.4513825],
                [108.6390271, 14.4511393],
                [108.6388845, 14.4509205],
                [108.6388046, 14.4508168],
                [108.6384575, 14.4507508],
                [108.6381144, 14.4506665],
                [108.6377745, 14.4505494],
                [108.6376061, 14.4504778],
                [108.6372811, 14.4503117],
                [108.6359668, 14.4495169],
                [108.635746, 14.4494152],
                [108.635541, 14.4492863],
                [108.6353552, 14.4491325],
                [108.6351989, 14.4489647],
                [108.6351292, 14.448874],
                [108.6350655, 14.4487792],
                [108.6347222, 14.4481039],
                [108.63463, 14.4479549],
                [108.6345105, 14.4478252],
                [108.6344419, 14.4477691],
                [108.6342944, 14.447679],
                [108.6341371, 14.4476178],
                [108.6340549, 14.4475975],
                [108.6339712, 14.4475844],
                [108.6336663, 14.4475595],
                [108.633365, 14.4475074],
                [108.6332165, 14.4474713],
                [108.6327233, 14.4473163],
                [108.6325467, 14.4472706],
                [108.6321884, 14.4472003],
                [108.6320074, 14.4471758],
                [108.6316553, 14.4471488],
                [108.6313147, 14.4471563],
                [108.6311453, 14.4471735],
                [108.6308104, 14.4472343],
                [108.6304843, 14.4473299],
                [108.6303249, 14.4473907],
                [108.6301691, 14.4474599],
                [108.6300175, 14.4475371],
                [108.6298704, 14.4476223],
                [108.6297284, 14.4477151],
                [108.6295917, 14.4478152],
                [108.6294609, 14.4479224],
                [108.6293364, 14.4480364],
                [108.6287429, 14.4483246],
                [108.6281459, 14.4485773],
                [108.6278481, 14.4486902],
                [108.6272442, 14.4488935],
                [108.6254203, 14.4493506],
                [108.6252597, 14.4494181],
                [108.6250955, 14.4494768],
                [108.6249282, 14.4495266],
                [108.6247583, 14.4495674],
                [108.6245863, 14.4495991],
                [108.6244128, 14.4496214],
                [108.6242383, 14.4496345],
                [108.6240633, 14.4496382],
                [108.6238884, 14.4496325],
                [108.623714, 14.4496174],
                [108.6235408, 14.449593],
                [108.6233692, 14.4495594],
                [108.6231998, 14.4495167],
                [108.622932, 14.4494286],
                [108.6227227, 14.4493784],
                [108.622508, 14.449359],
                [108.6222881, 14.4493714],
                [108.6220682, 14.4494175],
                [108.6212039, 14.449735],
                [108.6207993, 14.4498446],
                [108.6206982, 14.4498846],
                [108.6205082, 14.4499886],
                [108.6204207, 14.4500519],
                [108.6202797, 14.4501815],
                [108.6202171, 14.4502535],
                [108.6201096, 14.4504104],
                [108.6193746, 14.4520623],
                [108.6192969, 14.4522613],
                [108.6192473, 14.4523563],
                [108.6191279, 14.452535],
                [108.6190553, 14.4526215],
                [108.6188912, 14.4527778],
                [108.618173, 14.4532882],
                [108.6179975, 14.4534055],
                [108.617845, 14.4535498],
                [108.6177199, 14.453717],
                [108.6176253, 14.4539034],
                [108.6175904, 14.4540024],
                [108.6175645, 14.4541039],
                [108.6175476, 14.4542072],
                [108.61754, 14.4543115],
                [108.6175775, 14.4552622],
                [108.6175504, 14.455482],
                [108.6174913, 14.4556959],
                [108.61745, 14.4557992],
                [108.6173453, 14.455996],
                [108.6172824, 14.4560885],
                [108.6171375, 14.4562586],
                [108.6170565, 14.4563355],
                [108.6168785, 14.4564716],
                [108.6167826, 14.4565302],
                [108.616579, 14.456627],
                [108.6164725, 14.4566647],
                [108.6153245, 14.4569556],
                [108.6150022, 14.4570094],
                [108.6146759, 14.4570301],
                [108.6143492, 14.4570173],
                [108.6140257, 14.4569714],
                [108.6137089, 14.4568927],
                [108.6133975, 14.45678],
                [108.6130954, 14.456633],
                [108.6128117, 14.4564551],
                [108.6126777, 14.4563552],
                [108.6124277, 14.4561349],
                [108.6111027, 14.4548518],
                [108.6110118, 14.454788],
                [108.6108155, 14.454683],
                [108.6107113, 14.4546425],
                [108.6104945, 14.4545868],
                [108.6102712, 14.4545661],
                [108.61016, 14.454569],
                [108.6099402, 14.4546007],
                [108.609833, 14.4546294],
                [108.6096275, 14.4547115],
                [108.6092842, 14.4549401],
                [108.6090864, 14.4551057],
                [108.6086786, 14.4554227],
                [108.6082381, 14.4557313],
                [108.6077928, 14.4560673],
                [108.6075696, 14.4562534],
                [108.607353, 14.4564465],
                [108.6057409, 14.4580572],
                [108.6056243, 14.4581601],
                [108.6055016, 14.458256],
                [108.6053731, 14.4583446],
                [108.6052394, 14.4584256],
                [108.605108, 14.4584953],
                [108.6048339, 14.4586124],
                [108.6031198, 14.4591637],
                [108.602752, 14.4593118],
                [108.6024371, 14.4594681],
                [108.6022968, 14.4595311],
                [108.6021529, 14.4595858],
                [108.6020058, 14.459632],
                [108.601856, 14.4596696],
                [108.6017042, 14.4596983],
                [108.6014502, 14.4597262],
                [108.6011946, 14.4597295],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.2256784, 10.2009753],
                [105.2263123, 10.200484],
                [105.2269419, 10.1999873],
                [105.2275672, 10.1994854],
                [105.228188, 10.1989782],
                [105.2287584, 10.1985043],
                [105.229325, 10.1980261],
                [105.2298877, 10.1975434],
                [105.2304465, 10.1970564],
                [105.2316276, 10.1960695],
                [105.2328148, 10.1950897],
                [105.2340241, 10.194104],
                [105.2352556, 10.1931124],
                [105.2365194, 10.1921734],
                [105.2377941, 10.1912145],
                [105.2390635, 10.1902489],
                [105.2403277, 10.1892767],
                [105.2415703, 10.1883177],
                [105.242749, 10.1873842],
                [105.2439522, 10.1864288],
                [105.2451503, 10.1854672],
                [105.2463788, 10.1845274],
                [105.2476358, 10.1835452],
                [105.2488545, 10.1826055],
                [105.2499971, 10.1816712],
                [105.252382, 10.1797159],
                [105.2548197, 10.1777348],
                [105.257195, 10.1757854],
                [105.2583361, 10.1748255],
                [105.2589583, 10.1743107],
                [105.259583, 10.1738077],
                [105.2607527, 10.1728639],
                [105.261973, 10.1718649],
                [105.2631372, 10.1708865],
                [105.2643808, 10.1698937],
                [105.2651711, 10.1692097],
                [105.2659841, 10.1685381],
                [105.2668198, 10.1678633],
                [105.2676406, 10.1671957],
                [105.2684546, 10.1665316],
                [105.2688825, 10.1661846],
                [105.2693371, 10.1658406],
                [105.2701914, 10.1651706],
                [105.2710685, 10.1645002],
                [105.2715787, 10.164126],
                [105.2720857, 10.1637476],
                [105.2725893, 10.1633649],
                [105.2731134, 10.1629594],
                [105.2736338, 10.1625493],
                [105.274137, 10.1621584],
                [105.2745772, 10.161823],
                [105.2749979, 10.1615036],
                [105.2754019, 10.1611851],
                [105.2757838, 10.1608726],
                [105.2761774, 10.1605144],
                [105.2765483, 10.1601624],
                [105.2769043, 10.1598118],
                [105.2772537, 10.1594549],
                [105.2790483, 10.1577453],
                [105.2808391, 10.1560318],
                [105.2826261, 10.1543145],
                [105.2844038, 10.1525986],
                [105.2861776, 10.1508789],
                [105.2879477, 10.1491555],
                [105.2904797, 10.1465416],
                [105.2930492, 10.1438987],
                [105.2943208, 10.1425881],
                [105.2955946, 10.1412795],
                [105.296886, 10.1399572],
                [105.2981796, 10.1386371],
                [105.3033502, 10.1436629],
                [105.3069042, 10.1395858],
                [105.3065831, 10.1392634],
                [105.309505, 10.1360016],
                [105.3106482, 10.1346547],
                [105.3170421, 10.1414295],
                [105.3171422, 10.1412747],
                [105.3172481, 10.1411238],
                [105.3173392, 10.141003],
                [105.317434, 10.140885],
                [105.3175324, 10.14077],
                [105.3236385, 10.1338128],
                [105.3241124, 10.1332416],
                [105.3245923, 10.1326753],
                [105.3250781, 10.1321139],
                [105.3255698, 10.1315574],
                [105.3260672, 10.131006],
                [105.3265781, 10.1304513],
                [105.3270948, 10.129902],
                [105.3276174, 10.129358],
                [105.3281457, 10.1288194],
                [105.3286797, 10.1282863],
                [105.3293204, 10.1276292],
                [105.3299542, 10.1269657],
                [105.3305811, 10.1262957],
                [105.331201, 10.1256195],
                [105.3318484, 10.1249124],
                [105.3324455, 10.1241937],
                [105.3330249, 10.1234966],
                [105.3336982, 10.1227192],
                [105.334323, 10.1220127],
                [105.3350097, 10.1212681],
                [105.3356963, 10.120534],
                [105.3364474, 10.1197155],
                [105.3378904, 10.11811],
                [105.338636, 10.1172545],
                [105.3392355, 10.1165634],
                [105.3403581, 10.1152693],
                [105.3414461, 10.113976],
                [105.3425387, 10.1126864],
                [105.34364, 10.1113957],
                [105.344746, 10.1101089],
                [105.3458565, 10.1088259],
                [105.3475463, 10.106893],
                [105.3492147, 10.1049812],
                [105.3508991, 10.103043],
                [105.3525694, 10.1011358],
                [105.352639, 10.1010659],
                [105.3527117, 10.1009992],
                [105.3527874, 10.1009358],
                [105.3528818, 10.1008643],
                [105.3529799, 10.100798],
                [105.3530816, 10.1007369],
                [105.3565782, 10.0831983],
                [105.3566013, 10.0830907],
                [105.3566287, 10.0829841],
                [105.3566605, 10.0828786],
                [105.3567031, 10.0827567],
                [105.3567514, 10.0826369],
                [105.3568053, 10.0825195],
                [105.3568508, 10.0824342],
                [105.3568992, 10.0823505],
                [105.356959, 10.0822555],
                [105.3570226, 10.082163],
                [105.3570689, 10.0820889],
                [105.3571111, 10.0820124],
                [105.3571501, 10.0819384],
                [105.3571996, 10.0818487],
                [105.3572554, 10.0817628],
                [105.3573096, 10.0817061],
                [105.3573595, 10.0816632],
                [105.3574132, 10.081625],
                [105.3574836, 10.0815705],
                [105.3575939, 10.0814882],
                [105.3577119, 10.0813787],
                [105.3577962, 10.0812929],
                [105.357877, 10.0812039],
                [105.3579542, 10.0811119],
                [105.3589975, 10.0798841],
                [105.360046, 10.0786605],
                [105.3610995, 10.0774412],
                [105.3621612, 10.0762228],
                [105.363228, 10.0750087],
                [105.3645404, 10.0735401],
                [105.3643368, 10.073461],
                [105.3644221, 10.0733365],
                [105.3645131, 10.0732161],
                [105.3646097, 10.0731],
                [105.3647118, 10.0729882],
                [105.364819, 10.0728811],
                [105.3649311, 10.072779],
                [105.365449, 10.0722781],
                [105.3659731, 10.0717835],
                [105.3665033, 10.0712952],
                [105.3670113, 10.0708384],
                [105.3675246, 10.0703875],
                [105.3680432, 10.0699424],
                [105.3681206, 10.0698807],
                [105.3682017, 10.0698236],
                [105.3682862, 10.0697716],
                [105.3683737, 10.0697246],
                [105.3684806, 10.069676],
                [105.3685907, 10.069635],
                [105.3686809, 10.0695979],
                [105.368768, 10.0695543],
                [105.3688478, 10.0695066],
                [105.3689239, 10.0694533],
                [105.3726991, 10.0659827],
                [105.3764793, 10.0625175],
                [105.3802761, 10.0590472],
                [105.384078, 10.0555823],
                [105.3991404, 10.0417562],
                [105.3996347, 10.0426438],
                [105.4052434, 10.0372306],
                [105.4179284, 10.025278],
                [105.4187413, 10.0244915],
                [105.4195583, 10.0237091],
                [105.4204318, 10.0228816],
                [105.4245241, 10.0189901],
                [105.4252392, 10.0183156],
                [105.425968, 10.0176345],
                [105.4267001, 10.0169569],
                [105.4299116, 10.013923],
                [105.4305192, 10.0133604],
                [105.4310789, 10.0128454],
                [105.4316336, 10.012325],
                [105.4321554, 10.0118263],
                [105.4326726, 10.0113229],
                [105.4331851, 10.0108148],
                [105.4342081, 10.0098361],
                [105.4352331, 10.0088595],
                [105.4363002, 10.0078473],
                [105.4373695, 10.0068375],
                [105.4386556, 10.0056265],
                [105.4441789, 10.0005368],
                [105.4444957, 10.0001516],
                [105.4448557, 9.9998273],
                [105.4456271, 9.9991106],
                [105.4585952, 9.9870832],
                [105.4607238, 9.985111],
                [105.4628576, 9.9831443],
                [105.4639378, 9.9821684],
                [105.465014, 9.9811882],
                [105.4660794, 9.9802098],
                [105.4671407, 9.9792271],
                [105.468291, 9.9781999],
                [105.4694391, 9.9771703],
                [105.4705788, 9.9761435],
                [105.4717163, 9.9751142],
                [105.4726499, 9.9742714],
                [105.4736746, 9.9733574],
                [105.4739561, 9.9730746],
                [105.4742432, 9.9727973],
                [105.4745513, 9.9725115],
                [105.4748654, 9.972232],
                [105.4752061, 9.9719552],
                [105.4755413, 9.971672],
                [105.4758609, 9.9713913],
                [105.4761884, 9.9711022],
                [105.4764514, 9.9708416],
                [105.4767057, 9.9705991],
                [105.4769782, 9.9703537],
                [105.4772553, 9.9701134],
                [105.4775541, 9.9698655],
                [105.4777476, 9.9697145],
                [105.4779135, 9.9695804],
                [105.478076, 9.9694424],
                [105.4782907, 9.9692489],
                [105.4784987, 9.9690485],
                [105.4789339, 9.968643],
                [105.4798689, 9.9678297],
                [105.4807184, 9.9670376],
                [105.4816702, 9.9661715],
                [105.4825151, 9.9653583],
                [105.4833572, 9.9645804],
                [105.4838562, 9.9641536],
                [105.4839498, 9.9640551],
                [105.4840393, 9.9639529],
                [105.4841244, 9.9638472],
                [105.4842045, 9.9637391],
                [105.484241, 9.9636827],
                [105.48428, 9.9636279],
                [105.484343, 9.9635488],
                [105.484411, 9.9634739],
                [105.4844838, 9.9634035],
                [105.4851544, 9.9627813],
                [105.4860878, 9.9619187],
                [105.4873281, 9.9608197],
                [105.4885619, 9.9597137],
                [105.4897892, 9.9586006],
                [105.4909954, 9.957494],
                [105.4921952, 9.9563807],
                [105.4933885, 9.9552606],
                [105.4946253, 9.9541273],
                [105.4958825, 9.9529975],
                [105.4971923, 9.9518186],
                [105.4984045, 9.950728],
                [105.4996251, 9.9496028],
                [105.5008825, 9.9484554],
                [105.5020426, 9.9473439],
                [105.5032287, 9.9462104],
                [105.5033485, 9.9461041],
                [105.5034739, 9.9460043],
                [105.503578, 9.9459293],
                [105.5036854, 9.9458588],
                [105.5038024, 9.9457916],
                [105.5038721, 9.9459304],
                [105.5041735, 9.9457128],
                [105.5044704, 9.945471],
                [105.5047613, 9.9452223],
                [105.5050466, 9.9449664],
                [105.5053256, 9.944704],
                [105.506575, 9.943531],
                [105.5078298, 9.9423637],
                [105.5088686, 9.9414053],
                [105.5099111, 9.9404506],
                [105.5109572, 9.9394999],
                [105.5132546, 9.9373587],
                [105.5144173, 9.9362793],
                [105.5155492, 9.9352146],
                [105.5161222, 9.934688],
                [105.5166918, 9.9341578],
                [105.5172508, 9.9336306],
                [105.5178063, 9.9330998],
                [105.5200607, 9.9309821],
                [105.52312, 9.9281156],
                [105.5261841, 9.9252542],
                [105.5292617, 9.9223897],
                [105.5323442, 9.9195304],
                [105.5334086, 9.9218279],
                [105.5344657, 9.9241286],
                [105.5355155, 9.9264325],
                [105.5365761, 9.9287796],
                [105.5376291, 9.93113],
                [105.5386745, 9.9334837],
                [105.538438, 9.9337819],
                [105.5385714, 9.9340878],
                [105.5387127, 9.9343903],
                [105.5388243, 9.9346155],
                [105.5389403, 9.9348386],
                [105.5390606, 9.9350752],
                [105.5393568, 9.9356926],
                [105.5396565, 9.9363242],
                [105.5399549, 9.9369441],
                [105.5402567, 9.9375622],
                [105.5418477, 9.940944],
                [105.5454782, 9.9400364],
                [105.5468996, 9.9431422],
                [105.5483296, 9.9463526],
                [105.5487034, 9.9471457],
                [105.5505753, 9.9512763],
                [105.5507751, 9.9516938],
                [105.5502061, 9.9522169],
                [105.5547802, 9.957455],
                [105.5559447, 9.9588025],
                [105.5554819, 9.9590656],
                [105.5511951, 9.9610614],
                [105.5525201, 9.9639361],
                [105.5528426, 9.9647401],
                [105.5532557, 9.9655854],
                [105.5540879, 9.9672439],
                [105.555131, 9.9695389],
                [105.5563208, 9.9721323],
                [105.5572507, 9.9742416],
                [105.5578831, 9.9755987],
                [105.5582459, 9.976413],
                [105.5593691, 9.9788718],
                [105.5600658, 9.9803766],
                [105.5600557, 9.9804671],
                [105.5600041, 9.9805664],
                [105.5598673, 9.9807315],
                [105.5608007, 9.9818053],
                [105.5607082, 9.9819295],
                [105.5606975, 9.9820008],
                [105.5618682, 9.9845526],
                [105.5629072, 9.9867817],
                [105.5646282, 9.9860662],
                [105.5664372, 9.9852488],
                [105.5678844, 9.9845737],
                [105.5695951, 9.9838727],
                [105.5705006, 9.9859714],
                [105.5715993, 9.9883908],
                [105.5720864, 9.9893985],
                [105.5732527, 9.9917879],
                [105.5738309, 9.9929527],
                [105.5744047, 9.994168],
                [105.5755013, 9.9936657],
                [105.579136, 9.992055],
                [105.5793528, 9.9918619],
                [105.5799651, 9.9915976],
                [105.58162, 9.9909161],
                [105.5827311, 9.9904111],
                [105.5832128, 9.9901992],
                [105.5832387, 9.9901725],
                [105.5832522, 9.9901395],
                [105.5832575, 9.9900999],
                [105.5832508, 9.9900602],
                [105.5832025, 9.9899123],
                [105.5831972, 9.9898489],
                [105.5832227, 9.9898053],
                [105.5832994, 9.9897634],
                [105.583798, 9.9896482],
                [105.5846, 9.9894051],
                [105.5852678, 9.9892572],
                [105.5857125, 9.9891259],
                [105.5847233, 9.9871096],
                [105.5830582, 9.9836834],
                [105.583535, 9.9834407],
                [105.5838276, 9.9832398],
                [105.584032, 9.9830516],
                [105.5844827, 9.982563],
                [105.5873136, 9.9795995],
                [105.5903184, 9.9763473],
                [105.5914481, 9.9751459],
                [105.5938792, 9.9725673],
                [105.5953901, 9.9709415],
                [105.5974543, 9.9687377],
                [105.5988567, 9.9672188],
                [105.6029956, 9.9627872],
                [105.6059895, 9.9595806],
                [105.6070997, 9.9583977],
                [105.6100867, 9.9552157],
                [105.6118956, 9.9533064],
                [105.6130818, 9.952076],
                [105.6177662, 9.9471812],
                [105.6187751, 9.9461534],
                [105.6189462, 9.9459204],
                [105.6190956, 9.9456608],
                [105.6192347, 9.9457625],
                [105.6193386, 9.9458141],
                [105.6194838, 9.9458514],
                [105.6198093, 9.9459199],
                [105.6200782, 9.9459973],
                [105.6207793, 9.9462889],
                [105.6217821, 9.9467334],
                [105.6225883, 9.9471466],
                [105.6251067, 9.9482248],
                [105.6271747, 9.9491323],
                [105.6278264, 9.9493885],
                [105.6284782, 9.9497003],
                [105.6285989, 9.9497835],
                [105.6286935, 9.9498951],
                [105.6287773, 9.9500371],
                [105.6288413, 9.9502106],
                [105.6288806, 9.9504321],
                [105.628898, 9.9507993],
                [105.6289396, 9.9513052],
                [105.628957, 9.9514056],
                [105.6289892, 9.9514954],
                [105.6290415, 9.951576],
                [105.629122, 9.9516619],
                [105.6293124, 9.9519062],
                [105.6293714, 9.952037],
                [105.6293982, 9.9521783],
                [105.6294063, 9.9523302],
                [105.6293915, 9.9525191],
                [105.6293446, 9.9527794],
                [105.6292173, 9.9532959],
                [105.6291568, 9.9536763],
                [105.6291354, 9.9539801],
                [105.6291448, 9.9542324],
                [105.6292051, 9.9545388],
                [105.6295082, 9.9553723],
                [105.6295484, 9.9555625],
                [105.6295685, 9.9557448],
                [105.629594, 9.9560513],
                [105.6296262, 9.9562124],
                [105.6296906, 9.9563683],
                [105.6297737, 9.9564872],
                [105.6299052, 9.9566087],
                [105.6303343, 9.9569442],
                [105.6306642, 9.9572876],
                [105.6307822, 9.9573867],
                [105.6309083, 9.957462],
                [105.6310558, 9.9575122],
                [105.6312168, 9.9575412],
                [105.6318015, 9.9577156],
                [105.6319631, 9.9577777],
                [105.632067, 9.9578523],
                [105.6321388, 9.9579547],
                [105.6321944, 9.958059],
                [105.6322494, 9.9581911],
                [105.6323808, 9.9586244],
                [105.6324559, 9.9588463],
                [105.6325605, 9.9590735],
                [105.6326692, 9.9592809],
                [105.6327912, 9.9594698],
                [105.6329414, 9.9596468],
                [105.6331332, 9.9597887],
                [105.6333288, 9.9598827],
                [105.6335409, 9.9599142],
                [105.6338078, 9.9598977],
                [105.6341109, 9.9598475],
                [105.6344408, 9.9597471],
                [105.6345776, 9.9596732],
                [105.6346902, 9.9595807],
                [105.6347975, 9.9594513],
                [105.6348914, 9.9592769],
                [105.6349933, 9.9590497],
                [105.6350845, 9.9589335],
                [105.6352589, 9.9587776],
                [105.635578, 9.9584804],
                [105.6357095, 9.9584025],
                [105.6359012, 9.958347],
                [105.6361198, 9.958314],
                [105.6362995, 9.9583126],
                [105.6371981, 9.9583813],
                [105.6374073, 9.9583893],
                [105.6376567, 9.9583787],
                [105.6377801, 9.9583945],
                [105.6381744, 9.9585055],
                [105.6383139, 9.9585682],
                [105.6384292, 9.9586587],
                [105.6385016, 9.9587657],
                [105.6385446, 9.9588912],
                [105.6385861, 9.959059],
                [105.6386237, 9.9593839],
                [105.6386304, 9.9596151],
                [105.6386358, 9.9597603],
                [105.6386545, 9.9598819],
                [105.6386974, 9.9599875],
                [105.6387913, 9.9601011],
                [105.6389551, 9.9602223],
                [105.6392232, 9.9603931],
                [105.6393519, 9.9604499],
                [105.6394981, 9.9604776],
                [105.639722, 9.9604763],
                [105.6399688, 9.9604472],
                [105.6403255, 9.9603521],
                [105.6404784, 9.960323],
                [105.6408137, 9.96029],
                [105.6410605, 9.9602762],
                [105.6412234, 9.9602913],
                [105.6413689, 9.9603336],
                [105.6415379, 9.9604261],
                [105.6419617, 9.9607114],
                [105.6423104, 9.9609597],
                [105.6424016, 9.9610469],
                [105.6424606, 9.9611314],
                [105.6425303, 9.9612477],
                [105.6428575, 9.9619081],
                [105.6429353, 9.9620323],
                [105.6430292, 9.9621353],
                [105.6431392, 9.9622119],
                [105.6434892, 9.962418],
                [105.6435898, 9.9625078],
                [105.6436542, 9.9626082],
                [105.6436864, 9.9627165],
                [105.6436864, 9.9628407],
                [105.6436649, 9.9629701],
                [105.6436233, 9.9631207],
                [105.6435388, 9.9633267],
                [105.6435147, 9.9634086],
                [105.6435201, 9.9634852],
                [105.6435522, 9.9635539],
                [105.6436112, 9.9636015],
                [105.6436998, 9.9636305],
                [105.6439412, 9.9636755],
                [105.6440699, 9.9637177],
                [105.644424, 9.9638855],
                [105.6447975, 9.9640592],
                [105.6452125, 9.9643716],
                [105.6453238, 9.9644733],
                [105.6454097, 9.9645829],
                [105.6454794, 9.9647269],
                [105.6455404, 9.9649336],
                [105.6456497, 9.9654639],
                [105.64569, 9.9658972],
                [105.6458898, 9.9664579],
                [105.645926, 9.9666104],
                [105.6459233, 9.9667689],
                [105.6459032, 9.9669129],
                [105.6458522, 9.9670582],
                [105.6457651, 9.967181],
                [105.6456068, 9.9673924],
                [105.6455746, 9.9674848],
                [105.6455639, 9.967572],
                [105.6455827, 9.9676724],
                [105.645647, 9.9677701],
                [105.6457382, 9.9678468],
                [105.6461325, 9.9681109],
                [105.6461486, 9.9682272],
                [105.6461835, 9.968346],
                [105.6462774, 9.9685415],
                [105.6465022, 9.9688721],
                [105.6467293, 9.9692231],
                [105.6469336, 9.9696327],
                [105.6470726, 9.9699535],
                [105.6471142, 9.970145],
                [105.6471303, 9.9703458],
                [105.6471491, 9.9708372],
                [105.6472, 9.9712097],
                [105.6471974, 9.9713417],
                [105.6471705, 9.9714527],
                [105.6471169, 9.9715669],
                [105.6469734, 9.9717631],
                [105.6468473, 9.9718925],
                [105.6466931, 9.9719982],
                [105.6464624, 9.9722016],
                [105.6463069, 9.972409],
                [105.6461942, 9.9726018],
                [105.646103, 9.9728369],
                [105.6460494, 9.9730588],
                [105.6460333, 9.9732517],
                [105.6460547, 9.973426],
                [105.6461111, 9.9735779],
                [105.6462479, 9.9738421],
                [105.6463538, 9.9741142],
                [105.6464919, 9.974591],
                [105.646551, 9.974768],
                [105.6466287, 9.9749265],
                [105.6467468, 9.975081],
                [105.647141, 9.9754601],
                [105.6472497, 9.9756028],
                [105.6473154, 9.975715],
                [105.6477848, 9.9754099],
                [105.6481844, 9.9752461],
                [105.6482354, 9.9752673],
                [105.6482837, 9.9754046],
                [105.6482273, 9.9755288],
                [105.6482273, 9.9756688],
                [105.6483453, 9.9756873],
                [105.6484768, 9.975682],
                [105.6487048, 9.9754548],
                [105.6489032, 9.9751775],
                [105.6494182, 9.9748499],
                [105.6494343, 9.9747548],
                [105.6497991, 9.9743612],
                [105.6499788, 9.9742476],
                [105.6499681, 9.9740864],
                [105.6508237, 9.9737483],
                [105.651572, 9.9737721],
                [105.6525671, 9.9734102],
                [105.6539994, 9.9728501],
                [105.6548256, 9.9725067],
                [105.6561291, 9.9720365],
                [105.6570089, 9.9716667],
                [105.6586182, 9.9711066],
                [105.6586075, 9.9709243],
                [105.6586155, 9.9708081],
                [105.6586397, 9.9707236],
                [105.6586987, 9.9706602],
                [105.6587765, 9.9706232],
                [105.6589213, 9.9705836],
                [105.6589642, 9.9705492],
                [105.6589803, 9.970499],
                [105.6589749, 9.9704171],
                [105.6580844, 9.9662934],
                [105.6588918, 9.9659738],
                [105.6591453, 9.9662327],
                [105.6592365, 9.9662855],
                [105.6593558, 9.9663106],
                [105.6603912, 9.9663608],
                [105.6623009, 9.9665206],
                [105.6650153, 9.9666699],
                [105.6660479, 9.9666712],
                [105.6670779, 9.966514],
                [105.6680958, 9.966411],
                [105.6698245, 9.965773],
                [105.6700471, 9.9657254],
                [105.6703891, 9.9655683],
                [105.6705172, 9.9655253],
                [105.6707565, 9.9654831],
                [105.6708638, 9.9654467],
                [105.670951, 9.9653979],
                [105.6711347, 9.9652803],
                [105.6712461, 9.9652354],
                [105.6713775, 9.9651997],
                [105.6715639, 9.9651324],
                [105.6718039, 9.9650346],
                [105.6724785, 9.9647401],
                [105.6729734, 9.9644336],
                [105.6736363, 9.9640457],
                [105.6708335, 9.9607473],
                [105.6649072, 9.9556854],
                [105.6621766, 9.9533317],
                [105.658752, 9.9502131],
                [105.6591037, 9.9498218],
                [105.6593049, 9.9496527],
                [105.6600237, 9.9492512],
                [105.6602517, 9.9490979],
                [105.660485, 9.9488945],
                [105.6625302, 9.9468893],
                [105.6653398, 9.9443293],
                [105.6673019, 9.94248],
                [105.6685598, 9.9412904],
                [105.6704374, 9.9395382],
                [105.6710985, 9.9401419],
                [105.6751259, 9.9438472],
                [105.6759775, 9.9445816],
                [105.6779499, 9.9433013],
                [105.6781232, 9.9444878],
                [105.6801087, 9.9447362],
                [105.6788327, 9.9475115],
                [105.6822357, 9.9488628],
                [105.6858876, 9.9503138],
                [105.6872803, 9.9509142],
                [105.6894837, 9.9515232],
                [105.6939878, 9.9527463],
                [105.6936224, 9.9545441],
                [105.6940381, 9.9549278],
                [105.6953189, 9.9545164],
                [105.6958956, 9.9543711],
                [105.6968585, 9.9542627],
                [105.6992657, 9.9540923],
                [105.7008979, 9.953984],
                [105.7026252, 9.9539457],
                [105.7035425, 9.953914],
                [105.7038939, 9.9582466],
                [105.7043485, 9.9581951],
                [105.7044344, 9.9582968],
                [105.7045121, 9.9583628],
                [105.7045913, 9.95838],
                [105.7046919, 9.9583536],
                [105.7047924, 9.9583034],
                [105.7049131, 9.9582334],
                [105.7050338, 9.9581462],
                [105.707826, 9.9579217],
                [105.7105887, 9.9576311],
                [105.7110058, 9.9581423],
                [105.7111251, 9.9582651],
                [105.7112566, 9.9583761],
                [105.7127184, 9.9595292],
                [105.7128055, 9.9595767],
                [105.7128867, 9.9595913],
                [105.7130054, 9.9595781],
                [105.7131046, 9.9595252],
                [105.7132119, 9.959409],
                [105.7133111, 9.9593218],
                [105.7134077, 9.9592809],
                [105.7134975, 9.9592743],
                [105.713574, 9.959306],
                [105.713641, 9.9593852],
                [105.7139562, 9.9598687],
                [105.7151484, 9.9617866],
                [105.7152638, 9.9619451],
                [105.7154033, 9.9621089],
                [105.7156688, 9.9623916],
                [105.7160363, 9.9627825],
                [105.716114, 9.9629041],
                [105.7161918, 9.9630467],
                [105.7162669, 9.9632211],
                [105.7171172, 9.965456],
                [105.7181257, 9.9681558],
                [105.7185052, 9.968667],
                [105.7188821, 9.9691016],
                [105.719381, 9.9695269],
                [105.7211673, 9.9708081],
                [105.7224065, 9.9717142],
                [105.7225352, 9.9717776],
                [105.7226506, 9.971775],
                [105.7227525, 9.971775],
                [105.7228263, 9.971804],
                [105.7228746, 9.9718529],
                [105.7229094, 9.9719163],
                [105.7231227, 9.972615],
                [105.7233319, 9.9734524],
                [105.7234874, 9.9738976],
                [105.723533, 9.9740508],
                [105.7235652, 9.9742014],
                [105.7235907, 9.9744312],
                [105.7236122, 9.9754641],
                [105.7238629, 9.9753439],
                [105.7240641, 9.9752197],
                [105.7243618, 9.9750718],
                [105.7247561, 9.9747944],
                [105.7250056, 9.9746201],
                [105.7252255, 9.9744933],
                [105.7254025, 9.9744299],
                [105.7257405, 9.9741948],
                [105.7260543, 9.9739966],
                [105.7262367, 9.9739068],
                [105.7265103, 9.9738038],
                [105.7266417, 9.9737325],
                [105.7267839, 9.9736162],
                [105.7269381, 9.9734419],
                [105.727036, 9.9733679],
                [105.7272197, 9.9732979],
                [105.7272995, 9.9732451],
                [105.7273699, 9.9731691],
                [105.7274155, 9.9730456],
                [105.7274558, 9.972862],
                [105.7274973, 9.9727801],
                [105.7275617, 9.9727194],
                [105.727661, 9.9726705],
                [105.7278554, 9.9725979],
                [105.7279359, 9.9725582],
                [105.728019, 9.9724882],
                [105.7280794, 9.9723997],
                [105.7281263, 9.9722941],
                [105.7282323, 9.9719916],
                [105.7282886, 9.9718938],
                [105.7283838, 9.9718027],
                [105.7286735, 9.9716733],
                [105.7288089, 9.9715874],
                [105.7289082, 9.9715108],
                [105.7290389, 9.9713741],
                [105.729114, 9.9712374],
                [105.729163, 9.9710934],
                [105.7292549, 9.970604],
                [105.7293105, 9.9703894],
                [105.7293454, 9.9702943],
                [105.7294165, 9.9702137],
                [105.7295278, 9.9701543],
                [105.7296444, 9.9701266],
                [105.7297531, 9.9701305],
                [105.729855, 9.9701622],
                [105.7299784, 9.9702309],
                [105.7302131, 9.9704541],
                [105.7302674, 9.9704951],
                [105.7303331, 9.9705175],
                [105.7304169, 9.9705202],
                [105.7305001, 9.970499],
                [105.7306637, 9.9703973],
                [105.7308152, 9.9703168],
                [105.7309601, 9.9702745],
                [105.7310995, 9.9702613],
                [105.7312417, 9.9702771],
                [105.7316306, 9.9703247],
                [105.7318023, 9.9703326],
                [105.7319766, 9.9703194],
                [105.7321751, 9.9702771],
                [105.7323548, 9.9702032],
                [105.7326941, 9.9700381],
                [105.7330669, 9.9699007],
                [105.733197, 9.969828],
                [105.7333271, 9.9697237],
                [105.7334733, 9.9695348],
                [105.7335886, 9.9694265],
                [105.7337227, 9.9693473],
                [105.7338703, 9.9692971],
                [105.7343879, 9.9691729],
                [105.7345757, 9.9690382],
                [105.7347205, 9.9689721],
                [105.7348466, 9.9689589],
                [105.7349405, 9.9689801],
                [105.7350424, 9.9690329],
                [105.7351255, 9.9691148],
                [105.7353508, 9.9695137],
                [105.7354233, 9.9696193],
                [105.7355147, 9.969709],
                [105.7356405, 9.9697778],
                [105.7358846, 9.9698465],
                [105.7359999, 9.9699047],
                [105.7360911, 9.9699839],
                [105.7362123, 9.9701655],
                [105.7363003, 9.9702626],
                [105.7363969, 9.9703286],
                [105.7365538, 9.9703894],
                [105.7368435, 9.97047],
                [105.7368556, 9.9698782],
                [105.7368341, 9.9694239],
                [105.736763, 9.9690382],
                [105.7366303, 9.9686049],
                [105.7368126, 9.9685706],
                [105.736936, 9.9685706],
                [105.737109, 9.968593],
                [105.7372324, 9.9685957],
                [105.7373638, 9.9685785],
                [105.7375482, 9.9685128],
                [105.7378587, 9.9687205],
                [105.7380478, 9.9688916],
                [105.7381866, 9.9690388],
                [105.7383201, 9.9692317],
                [105.7416956, 9.9669644],
                [105.7436885, 9.9656119],
                [105.744701, 9.9649673],
                [105.7464565, 9.9638683],
                [105.7470215, 9.9635202],
                [105.7472143, 9.9633967],
                [105.7474174, 9.9632891],
                [105.747855, 9.9630885],
                [105.7482358, 9.9629249],
                [105.7505777, 9.9619477],
                [105.7518277, 9.9614445],
                [105.752867, 9.9610509],
                [105.7532244, 9.9608943],
                [105.7539526, 9.9605007],
                [105.7568803, 9.9592412],
                [105.757349, 9.9590497],
                [105.7578211, 9.9588925],
                [105.7583106, 9.958631],
                [105.7593311, 9.9580181],
                [105.7595444, 9.95789],
                [105.7596476, 9.9576112],
                [105.7607581, 9.9579018],
                [105.7616419, 9.9580788],
                [105.7619047, 9.9581673],
                [105.7621032, 9.9582572],
                [105.7623178, 9.9584527],
                [105.7626557, 9.9584104],
                [105.7628998, 9.9583453],
                [105.7631419, 9.9582506],
                [105.7633759, 9.9581072],
                [105.763687, 9.9577724],
                [105.7641437, 9.9572984],
                [105.7644582, 9.9569244],
                [105.7653701, 9.9570413],
                [105.7659991, 9.957001],
                [105.7666334, 9.956931],
                [105.7681757, 9.9566562],
                [105.7707547, 9.9562454],
                [105.7732612, 9.9553664],
                [105.7755974, 9.9544345],
                [105.7757945, 9.9543737],
                [105.7759883, 9.9543542],
                [105.7761908, 9.9543671],
                [105.7763954, 9.9544279],
                [105.7765388, 9.9545401],
                [105.7766783, 9.9547105],
                [105.7768594, 9.9550434],
                [105.777435, 9.9561764],
                [105.7775272, 9.9563907],
                [105.7775842, 9.9566327],
                [105.7776707, 9.9571846],
                [105.7777351, 9.9573748],
                [105.7778317, 9.9575227],
                [105.7780298, 9.9576938],
                [105.784104, 9.9587591],
                [105.7896964, 9.9597432],
                [105.7972274, 9.9611526],
                [105.8013235, 9.9618873],
                [105.8037619, 9.9623678],
                [105.8044083, 9.9624272],
                [105.8049139, 9.962587],
                [105.8061343, 9.9627773],
                [105.8090432, 9.9633122],
                [105.8141529, 9.9642674],
                [105.8183397, 9.9650338],
                [105.8189017, 9.9651258],
                [105.8198565, 9.9662987],
                [105.8205646, 9.9670542],
                [105.8214605, 9.9678943],
                [105.8219218, 9.9682642],
                [105.8221766, 9.9684411],
                [105.8225823, 9.9686185],
                [105.8230316, 9.9687674],
                [105.8232428, 9.9689021],
                [105.8234856, 9.9691016],
                [105.8240099, 9.9696137],
                [105.8242285, 9.969795],
                [105.8245383, 9.9699509],
                [105.8255696, 9.9703696],
                [105.8258995, 9.9705096],
                [105.8261597, 9.9706958],
                [105.8278656, 9.9720629],
                [105.8282787, 9.9724645],
                [105.8286864, 9.9729215],
                [105.8290974, 9.9734914],
                [105.8295608, 9.9742872],
                [105.8297418, 9.9745382],
                [105.8299899, 9.9748155],
                [105.8303225, 9.9751722],
                [105.8306658, 9.975608],
                [105.8309019, 9.975793],
                [105.8311835, 9.97596],
                [105.8321471, 9.9763688],
                [105.8324093, 9.9765009],
                [105.832658, 9.9766601],
                [105.8333024, 9.977127],
                [105.8336927, 9.977333],
                [105.8345604, 9.9777821],
                [105.8352491, 9.9782147],
                [105.8355126, 9.9782999],
                [105.8357888, 9.9783289],
                [105.836454, 9.9783131],
                [105.8374143, 9.9784214],
                [105.8378702, 9.9784135],
                [105.8381814, 9.9784108],
                [105.8384469, 9.9784293],
                [105.8386803, 9.9784993],
                [105.8389109, 9.9786525],
                [105.839383, 9.9791795],
                [105.8398234, 9.9794445],
                [105.8454722, 9.9826158],
                [105.8437676, 9.9846685],
                [105.8415444, 9.9874355],
                [105.8362759, 9.9944209],
                [105.8300098, 10.0012254],
                [105.8286679, 10.0034839],
                [105.8267988, 10.0061554],
                [105.8252649, 10.0083478],
                [105.8232191, 10.0118267],
                [105.8230974, 10.0120336],
                [105.8229746, 10.0122424],
                [105.8218179, 10.0142243],
                [105.8198741, 10.0173586],
                [105.8189501, 10.019524],
                [105.8172638, 10.0234172],
                [105.8162462, 10.0253066],
                [105.8149505, 10.0275189],
                [105.8128446, 10.0309068],
                [105.8108989, 10.0333508],
                [105.80951, 10.0354713],
                [105.8054571, 10.0408428],
                [105.8045749, 10.0419892],
                [105.8037894, 10.04301],
                [105.8033258, 10.0434714],
                [105.8009304, 10.0467371],
                [105.7986197, 10.0499467],
                [105.7971375, 10.0519602],
                [105.7954249, 10.0544026],
                [105.7940624, 10.0561961],
                [105.7918705, 10.0591375],
                [105.7876917, 10.0642884],
                [105.7850504, 10.0675164],
                [105.7822627, 10.0696105],
                [105.781455, 10.0702172],
                [105.781066, 10.070456],
                [105.7786704, 10.0719267],
                [105.7762571, 10.0733591],
                [105.7724045, 10.0754392],
                [105.7664396, 10.0786057],
                [105.7648153, 10.079668],
                [105.763238, 10.0809142],
                [105.7609653, 10.0829445],
                [105.7605018, 10.0834979],
                [105.758067, 10.0857817],
                [105.7551907, 10.0880895],
                [105.7504563, 10.0909077],
                [105.7494815, 10.0914622],
                [105.744667, 10.0939578],
                [105.7414413, 10.0955128],
                [105.7388484, 10.096778],
                [105.7367197, 10.0977859],
                [105.7354183, 10.0983691],
                [105.7338092, 10.0992144],
                [105.7315361, 10.1007445],
                [105.7288172, 10.1026711],
                [105.726509, 10.1045619],
                [105.7209923, 10.1092381],
                [105.717479, 10.1121015],
                [105.7153191, 10.1136842],
                [105.7135717, 10.1148153],
                [105.7112027, 10.1163542],
                [105.7087877, 10.1178966],
                [105.70759, 10.1186417],
                [105.7055599, 10.1199047],
                [105.6982048, 10.1245155],
                [105.6915232, 10.1287965],
                [105.6852871, 10.1326264],
                [105.6818716, 10.1347692],
                [105.6771762, 10.1380013],
                [105.6738552, 10.1401246],
                [105.6723888, 10.1410041],
                [105.6708464, 10.1417984],
                [105.6668992, 10.1440084],
                [105.6633629, 10.1464528],
                [105.6607973, 10.1484676],
                [105.6587066, 10.1503531],
                [105.6573919, 10.1516171],
                [105.6568974, 10.1521727],
                [105.6536006, 10.1561783],
                [105.6523035, 10.157896],
                [105.6510229, 10.1596397],
                [105.6495116, 10.161799],
                [105.6486009, 10.1631903],
                [105.6473993, 10.1649672],
                [105.6458729, 10.1669621],
                [105.6439136, 10.169123],
                [105.6414572, 10.1714974],
                [105.6370187, 10.1751179],
                [105.6308173, 10.1808773],
                [105.6277346, 10.183652],
                [105.6247458, 10.1863385],
                [105.6242911, 10.1876274],
                [105.6232569, 10.1924381],
                [105.621725, 10.1982738],
                [105.6204776, 10.2025767],
                [105.6193991, 10.206247],
                [105.6183201, 10.2097174],
                [105.6172206, 10.2129604],
                [105.6164719, 10.214558],
                [105.6149423, 10.2173749],
                [105.612453, 10.2213975],
                [105.6104767, 10.2243673],
                [105.6091078, 10.2263741],
                [105.6078237, 10.2281835],
                [105.6057521, 10.2311175],
                [105.6042649, 10.2331256],
                [105.602761, 10.2351323],
                [105.6009601, 10.2374612],
                [105.5999075, 10.2387817],
                [105.599358, 10.2394784],
                [105.5968651, 10.2423356],
                [105.5936681, 10.2455581],
                [105.590246, 10.2490074],
                [105.5857102, 10.2533618],
                [105.5834542, 10.2555276],
                [105.5793012, 10.2595045],
                [105.5774749, 10.2612468],
                [105.5741579, 10.2646718],
                [105.5718888, 10.2672565],
                [105.5683613, 10.2704994],
                [105.567547, 10.2712242],
                [105.565058, 10.2734396],
                [105.560968, 10.276991],
                [105.5584234, 10.2792885],
                [105.5508758, 10.2858992],
                [105.5450171, 10.2898119],
                [105.5407344, 10.2926719],
                [105.5356676, 10.2957909],
                [105.5336947, 10.2968739],
                [105.5308381, 10.298442],
                [105.5263273, 10.3009182],
                [105.5215098, 10.3033946],
                [105.5179559, 10.3050516],
                [105.5148848, 10.3062226],
                [105.5108098, 10.3102568],
                [105.5102885, 10.3107729],
                [105.5049243, 10.316206],
                [105.5019735, 10.319221],
                [105.4960645, 10.3252088],
                [105.4921486, 10.3246739],
                [105.4913152, 10.3244846],
                [105.4907062, 10.3243426],
                [105.4902896, 10.3243273],
                [105.4895204, 10.3242411],
                [105.4887991, 10.3240198],
                [105.4882222, 10.3239254],
                [105.4877735, 10.3239261],
                [105.487405, 10.323879],
                [105.4872127, 10.3238078],
                [105.4870202, 10.323681],
                [105.4868594, 10.3233476],
                [105.4866018, 10.3225298],
                [105.4860706, 10.3209022],
                [105.4855876, 10.3193857],
                [105.4853139, 10.3185442],
                [105.4850081, 10.3176471],
                [105.4847347, 10.3169962],
                [105.4844455, 10.3164247],
                [105.4841726, 10.3161232],
                [105.4838197, 10.3158457],
                [105.4830177, 10.3152274],
                [105.4818148, 10.3143633],
                [105.4796174, 10.31277],
                [105.4792782, 10.3124996],
                [105.478623, 10.3119772],
                [105.4769924, 10.3106983],
                [105.4765059, 10.3112987],
                [105.4761653, 10.3110218],
                [105.4762632, 10.3108875],
                [105.4754427, 10.3104155],
                [105.4751978, 10.3107537],
                [105.4723504, 10.3107158],
                [105.4687968, 10.3106319],
                [105.4681014, 10.3100763],
                [105.4647432, 10.3094954],
                [105.4608141, 10.3088196],
                [105.4590949, 10.3084936],
                [105.4573533, 10.3082213],
                [105.4565866, 10.3080365],
                [105.4559001, 10.3076973],
                [105.4549801, 10.3072767],
                [105.4544993, 10.3069237],
                [105.4537164, 10.3064281],
                [105.4531673, 10.306252],
                [105.4529064, 10.3061435],
                [105.4522868, 10.3058432],
                [105.4518245, 10.3073048],
                [105.4514758, 10.3082791],
                [105.45107, 10.3078126],
                [105.4507827, 10.3075231],
                [105.4505364, 10.3072986],
                [105.4502831, 10.3071334],
                [105.4499992, 10.3069885],
                [105.4496899, 10.3068644],
                [105.4494165, 10.3067351],
                [105.4491373, 10.3065367],
                [105.4489134, 10.3062406],
                [105.4486396, 10.3059561],
                [105.4480375, 10.3055866],
                [105.4477828, 10.3053664],
                [105.4477117, 10.3051765],
                [105.4476566, 10.3050057],
                [105.4475713, 10.3047135],
                [105.4457904, 10.3053334],
                [105.4443315, 10.3058377],
                [105.4433795, 10.3061697],
                [105.4430297, 10.3058761],
                [105.4401223, 10.3053819],
                [105.4339395, 10.3043503],
                [105.4316928, 10.3039583],
                [105.4296466, 10.3036068],
                [105.4265297, 10.3030676],
                [105.426874, 10.3022908],
                [105.4278177, 10.3001279],
                [105.4290098, 10.2973965],
                [105.4302288, 10.2946635],
                [105.4314188, 10.2920817],
                [105.4325364, 10.289548],
                [105.4345192, 10.2851188],
                [105.4366261, 10.2803388],
                [105.4389447, 10.2752254],
                [105.4403934, 10.2720383],
                [105.441357, 10.2698772],
                [105.4391563, 10.2690026],
                [105.4359417, 10.2677609],
                [105.4360319, 10.2674917],
                [105.4342282, 10.2670119],
                [105.4331864, 10.2666552],
                [105.4299843, 10.265383],
                [105.4298652, 10.2653398],
                [105.4283692, 10.2647972],
                [105.4266955, 10.2642271],
                [105.4234929, 10.2629655],
                [105.4221304, 10.262464],
                [105.4165406, 10.2602787],
                [105.4098888, 10.2577238],
                [105.4069155, 10.2565883],
                [105.4048838, 10.2557918],
                [105.4030326, 10.2550938],
                [105.4012896, 10.2544193],
                [105.3997483, 10.2538012],
                [105.3988663, 10.2534658],
                [105.3980584, 10.2531906],
                [105.3966557, 10.2527238],
                [105.3932966, 10.2517061],
                [105.3881468, 10.2502676],
                [105.3839229, 10.2490279],
                [105.3810733, 10.2481715],
                [105.3754735, 10.2464592],
                [105.3747145, 10.2462175],
                [105.3712773, 10.2450984],
                [105.3661312, 10.2435114],
                [105.3650402, 10.2432852],
                [105.3606284, 10.2419876],
                [105.3570498, 10.2409198],
                [105.3563001, 10.2406349],
                [105.3513886, 10.2390838],
                [105.3475551, 10.2378459],
                [105.3474133, 10.2378001],
                [105.3453949, 10.2371737],
                [105.3444152, 10.2368733],
                [105.336192, 10.2343516],
                [105.3349782, 10.2363869],
                [105.3326073, 10.240282],
                [105.3312036, 10.2425418],
                [105.3309567, 10.2428931],
                [105.3292036, 10.2455825],
                [105.3264614, 10.2501123],
                [105.3251545, 10.2521047],
                [105.3238011, 10.2543575],
                [105.3221312, 10.2571689],
                [105.3214281, 10.2583389],
                [105.3191957, 10.2620935],
                [105.3179651, 10.2641016],
                [105.3158205, 10.2676117],
                [105.3153106, 10.2684848],
                [105.313957, 10.2706851],
                [105.3120201, 10.2740144],
                [105.3065283, 10.2690398],
                [105.301522, 10.2769587],
                [105.3014394, 10.2770344],
                [105.3013065, 10.2770704],
                [105.3012161, 10.2770331],
                [105.2990001, 10.2746962],
                [105.2961404, 10.2717681],
                [105.2957885, 10.2715435],
                [105.2913631, 10.2674413],
                [105.2920157, 10.2666093],
                [105.2874575, 10.2621656],
                [105.2888462, 10.2599806],
                [105.2881065, 10.2592973],
                [105.281801, 10.2533628],
                [105.2758817, 10.2478131],
                [105.2746732, 10.2466094],
                [105.2671215, 10.2389138],
                [105.2609219, 10.2459188],
                [105.253082, 10.2385529],
                [105.2480094, 10.2317643],
                [105.2427289, 10.2248243],
                [105.2431154, 10.2244031],
                [105.2428128, 10.2239612],
                [105.2425057, 10.2235223],
                [105.2422473, 10.2231602],
                [105.2419859, 10.2228002],
                [105.2407569, 10.221115],
                [105.2395445, 10.2194345],
                [105.2383291, 10.2177561],
                [105.2369374, 10.2160204],
                [105.2355909, 10.2143469],
                [105.2342713, 10.2127208],
                [105.2336222, 10.2119447],
                [105.2330267, 10.2112584],
                [105.2330951, 10.2111949],
                [105.2311146, 10.2085429],
                [105.2256784, 10.2009753],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.266713, 22.8664239],
                [105.2687801, 22.8658043],
                [105.2707329, 22.8653616],
                [105.2727364, 22.8651917],
                [105.2738065, 22.8646351],
                [105.2742093, 22.8639458],
                [105.2743617, 22.8624523],
                [105.2753633, 22.8620204],
                [105.2767488, 22.8611619],
                [105.2776483, 22.8602034],
                [105.2792808, 22.858015],
                [105.280144, 22.85741],
                [105.2809994, 22.8566384],
                [105.2822672, 22.8563803],
                [105.2837345, 22.8558308],
                [105.2844955, 22.8557918],
                [105.2852583, 22.8562618],
                [105.2864671, 22.8564662],
                [105.2878116, 22.8561969],
                [105.288954, 22.8556859],
                [105.2904551, 22.8555213],
                [105.2919323, 22.8555794],
                [105.293735, 22.8557762],
                [105.2956085, 22.8560527],
                [105.296708, 22.8560309],
                [105.2975287, 22.8556618],
                [105.2987367, 22.8555625],
                [105.300583, 22.8562679],
                [105.3014378, 22.8561815],
                [105.3018924, 22.8556848],
                [105.3024477, 22.8545132],
                [105.304869, 22.8534235],
                [105.3066922, 22.8520935],
                [105.3091955, 22.8496837],
                [105.312891, 22.8497741],
                [105.3141611, 22.84948],
                [105.3154692, 22.8491204],
                [105.3168141, 22.8485131],
                [105.3183702, 22.8474504],
                [105.3189612, 22.8467447],
                [105.3193381, 22.8460389],
                [105.320303, 22.8452997],
                [105.3213573, 22.8450425],
                [105.3225195, 22.8449569],
                [105.324176, 22.8451245],
                [105.3246251, 22.8446407],
                [105.3251872, 22.8431776],
                [105.3252888, 22.8421545],
                [105.3251472, 22.8410734],
                [105.3250398, 22.8403861],
                [105.3252847, 22.8392959],
                [105.3256312, 22.83809],
                [105.3266437, 22.83568],
                [105.327225, 22.8346479],
                [105.3277278, 22.8339585],
                [105.3284124, 22.833583],
                [105.3292408, 22.8328995],
                [105.3310894, 22.8322453],
                [105.3326833, 22.8318966],
                [105.3343776, 22.8317493],
                [105.3358827, 22.8311487],
                [105.3373482, 22.8303177],
                [105.3393512, 22.8295991],
                [105.3403248, 22.8296986],
                [105.341373, 22.8299899],
                [105.3423211, 22.8298823],
                [105.343253, 22.8292764],
                [105.3442922, 22.827535],
                [105.3445314, 22.8266433],
                [105.3444666, 22.8258276],
                [105.3446568, 22.8243317],
                [105.3446435, 22.823195],
                [105.3445906, 22.8219384],
                [105.3446847, 22.8206808],
                [105.3447957, 22.8195757],
                [105.3451754, 22.8184192],
                [105.3459054, 22.8173041],
                [105.346533, 22.8163367],
                [105.3470554, 22.8154393],
                [105.3480771, 22.8139547],
                [105.348837, 22.8136078],
                [105.3496185, 22.8138078],
                [105.3512435, 22.8151296],
                [105.3523095, 22.8154371],
                [105.3533277, 22.815401],
                [105.354397, 22.815805],
                [105.3551319, 22.8164155],
                [105.356104, 22.8169027],
                [105.3572832, 22.8172261],
                [105.3586997, 22.8171821],
                [105.360197, 22.8169126],
                [105.3618468, 22.8163579],
                [105.363642, 22.8153253],
                [105.36527, 22.8145245],
                [105.3671067, 22.8138413],
                [105.3693896, 22.8126696],
                [105.3705053, 22.812456],
                [105.3719096, 22.8120451],
                [105.3728897, 22.8120159],
                [105.3743917, 22.8119333],
                [105.3760422, 22.812095],
                [105.3776912, 22.8125536],
                [105.3788619, 22.8127598],
                [105.3800036, 22.8127289],
                [105.3809822, 22.8123619],
                [105.3818309, 22.8126989],
                [105.3828261, 22.8125794],
                [105.3838976, 22.8116089],
                [105.3851435, 22.8110451],
                [105.3864506, 22.8107924],
                [105.3883151, 22.809628],
                [105.3891104, 22.8085949],
                [105.390083, 22.807691],
                [105.3909536, 22.8066479],
                [105.3914909, 22.8057476],
                [105.3918764, 22.8046841],
                [105.3930451, 22.8037138],
                [105.3938607, 22.8030756],
                [105.3947081, 22.8021448],
                [105.3948333, 22.8000749],
                [105.3952884, 22.7991141],
                [105.3961465, 22.7980277],
                [105.3969858, 22.7971525],
                [105.3984297, 22.7960387],
                [105.3999259, 22.7951549],
                [105.4009828, 22.7954205],
                [105.4023732, 22.795942],
                [105.4032883, 22.795913],
                [105.4039194, 22.7956989],
                [105.4058373, 22.7947431],
                [105.408121, 22.7938287],
                [105.409295, 22.7924796],
                [105.4102075, 22.7916059],
                [105.4103275, 22.7898556],
                [105.4120472, 22.7885172],
                [105.4128795, 22.7881436],
                [105.4137839, 22.7872533],
                [105.415745, 22.7860405],
                [105.4175814, 22.7857182],
                [105.4197721, 22.7856253],
                [105.421389, 22.7854458],
                [105.4227657, 22.7852014],
                [105.4236234, 22.7849472],
                [105.4232748, 22.7845667],
                [105.4227909, 22.7836024],
                [105.4224787, 22.7826793],
                [105.4224469, 22.7820081],
                [105.422, 22.7807978],
                [105.4222989, 22.7801002],
                [105.4228871, 22.7797061],
                [105.4235314, 22.779016],
                [105.4241152, 22.7772966],
                [105.4246467, 22.776557],
                [105.4256688, 22.7764232],
                [105.4265468, 22.7765011],
                [105.4278411, 22.7770921],
                [105.4290594, 22.777195],
                [105.4303122, 22.7769532],
                [105.4315616, 22.7765088],
                [105.4334055, 22.7756527],
                [105.4353985, 22.7747146],
                [105.4367203, 22.7743481],
                [105.4381502, 22.7743287],
                [105.4394855, 22.774093],
                [105.4407252, 22.7741026],
                [105.4428385, 22.773301],
                [105.4447568, 22.7721674],
                [105.4461567, 22.7707061],
                [105.4477273, 22.7680409],
                [105.4480749, 22.7669581],
                [105.4482437, 22.7646959],
                [105.4486775, 22.7628539],
                [105.4502151, 22.759506],
                [105.452966, 22.7584662],
                [105.4555019, 22.7577025],
                [105.4583118, 22.7572923],
                [105.4602552, 22.757436],
                [105.4620628, 22.7570454],
                [105.4630464, 22.7564702],
                [105.4637825, 22.7558414],
                [105.4645247, 22.7556101],
                [105.4657558, 22.7556438],
                [105.4667587, 22.7559219],
                [105.4677067, 22.7561087],
                [105.468781, 22.7561087],
                [105.4696003, 22.7559918],
                [105.4703148, 22.7554703],
                [105.4708528, 22.7550721],
                [105.470985, 22.754948],
                [105.4697874, 22.7516791],
                [105.4695235, 22.7504759],
                [105.4696394, 22.7494913],
                [105.4707379, 22.7485089],
                [105.4723503, 22.7477393],
                [105.4732498, 22.7468396],
                [105.475558, 22.7438877],
                [105.4763361, 22.7425904],
                [105.4775437, 22.7415692],
                [105.4784126, 22.7406253],
                [105.4790775, 22.7392335],
                [105.4792035, 22.7385317],
                [105.4788145, 22.7377197],
                [105.4778107, 22.736569],
                [105.4772706, 22.7348443],
                [105.4771225, 22.7336701],
                [105.477147, 22.7329366],
                [105.4772498, 22.7320086],
                [105.4772498, 22.7308765],
                [105.4771015, 22.7300351],
                [105.4767681, 22.7295078],
                [105.4758167, 22.7290631],
                [105.4754148, 22.728166],
                [105.4758848, 22.7274478],
                [105.4760633, 22.7263115],
                [105.4760808, 22.7252291],
                [105.4763661, 22.72375],
                [105.4763556, 22.722836],
                [105.4765005, 22.7214925],
                [105.4765614, 22.7202665],
                [105.4766972, 22.7192672],
                [105.4766919, 22.7182762],
                [105.4772422, 22.7176554],
                [105.4777339, 22.7167964],
                [105.4786571, 22.7159271],
                [105.4796245, 22.7151794],
                [105.4805077, 22.714274],
                [105.4813972, 22.7130589],
                [105.4819258, 22.7118264],
                [105.4822072, 22.709315],
                [105.4832112, 22.7090107],
                [105.483942, 22.7088569],
                [105.4846947, 22.7081109],
                [105.4856785, 22.7077501],
                [105.4876823, 22.7077722],
                [105.48991, 22.7082009],
                [105.4911388, 22.7082213],
                [105.4921583, 22.7076019],
                [105.4927151, 22.7066731],
                [105.4951229, 22.7038107],
                [105.4964976, 22.7031866],
                [105.4976021, 22.7029152],
                [105.4994963, 22.701888],
                [105.5004844, 22.7013093],
                [105.5010596, 22.7004879],
                [105.5013439, 22.6995109],
                [105.5018561, 22.6983499],
                [105.5020191, 22.6970775],
                [105.5019439, 22.6959225],
                [105.5011518, 22.6942334],
                [105.5007517, 22.6931898],
                [105.4990331, 22.6921786],
                [105.4977458, 22.6911422],
                [105.4965747, 22.6896036],
                [105.4955938, 22.6881584],
                [105.4948744, 22.6864407],
                [105.4941442, 22.6835015],
                [105.4932694, 22.6822209],
                [105.491903, 22.6806855],
                [105.4891978, 22.6773188],
                [105.488558, 22.6755642],
                [105.487887, 22.6734008],
                [105.4880215, 22.6722176],
                [105.4889293, 22.6707014],
                [105.4910726, 22.6690529],
                [105.4926144, 22.6676485],
                [105.493881, 22.6662535],
                [105.4947668, 22.6649698],
                [105.4952312, 22.6634863],
                [105.4955294, 22.6621316],
                [105.4960233, 22.6608483],
                [105.4965343, 22.6598433],
                [105.4970137, 22.6590649],
                [105.4974289, 22.6578658],
                [105.4977722, 22.6566083],
                [105.4978485, 22.655379],
                [105.4972169, 22.6545725],
                [105.4956464, 22.6537589],
                [105.494955, 22.6533173],
                [105.4946116, 22.65251],
                [105.4943295, 22.6510826],
                [105.4941012, 22.6492264],
                [105.4941245, 22.6481058],
                [105.4936495, 22.6465863],
                [105.4936417, 22.6448333],
                [105.4938679, 22.6442093],
                [105.4943172, 22.6423977],
                [105.4949829, 22.6412502],
                [105.4959623, 22.6400016],
                [105.4966579, 22.6390479],
                [105.4971193, 22.6381098],
                [105.4978175, 22.6370671],
                [105.4986167, 22.635638],
                [105.498849, 22.6339346],
                [105.5019834, 22.6349316],
                [105.5043761, 22.6343986],
                [105.5065637, 22.6341051],
                [105.5083159, 22.6344578],
                [105.5100444, 22.63536],
                [105.5113602, 22.6360486],
                [105.5141934, 22.6362784],
                [105.5169722, 22.6356961],
                [105.5193384, 22.6349719],
                [105.520883, 22.6348713],
                [105.5226852, 22.6348177],
                [105.5243823, 22.6341911],
                [105.5256184, 22.6342349],
                [105.5269085, 22.6349472],
                [105.5285521, 22.6337953],
                [105.5294776, 22.63341],
                [105.5315883, 22.6332117],
                [105.5352304, 22.6335392],
                [105.5397242, 22.6344792],
                [105.5411524, 22.6409014],
                [105.5412862, 22.6491267],
                [105.5435676, 22.6532202],
                [105.5468149, 22.6554356],
                [105.5501268, 22.6573679],
                [105.5541137, 22.6568496],
                [105.5576832, 22.6573335],
                [105.5577338, 22.6590607],
                [105.5627395, 22.6601944],
                [105.568076, 22.6611191],
                [105.5728372, 22.6632386],
                [105.5770365, 22.6655237],
                [105.5780737, 22.6676239],
                [105.5806576, 22.6676977],
                [105.581084, 22.6696477],
                [105.5827807, 22.6692479],
                [105.5843084, 22.6686094],
                [105.5870722, 22.6674783],
                [105.5887063, 22.6679033],
                [105.5917008, 22.6680066],
                [105.5924366, 22.6670934],
                [105.5934035, 22.6671402],
                [105.5947757, 22.6668677],
                [105.5971684, 22.6664829],
                [105.5985634, 22.667957],
                [105.6012439, 22.6682127],
                [105.6009409, 22.6692262],
                [105.6010306, 22.6709332],
                [105.5997911, 22.6733644],
                [105.5997176, 22.6744312],
                [105.5988372, 22.6751975],
                [105.5986708, 22.6772681],
                [105.5978667, 22.6782204],
                [105.5983282, 22.6787865],
                [105.5993095, 22.6798905],
                [105.6005084, 22.6802093],
                [105.6005848, 22.6821702],
                [105.6000496, 22.6825465],
                [105.5996112, 22.6830352],
                [105.5996454, 22.6836133],
                [105.6008332, 22.6842607],
                [105.6023035, 22.685223],
                [105.6044532, 22.6863938],
                [105.6063183, 22.6878216],
                [105.6071761, 22.6891094],
                [105.6080653, 22.6897795],
                [105.6083096, 22.6909158],
                [105.6093367, 22.6915688],
                [105.6105598, 22.691839],
                [105.6124895, 22.6935278],
                [105.6117077, 22.6948472],
                [105.6116593, 22.6954748],
                [105.6131856, 22.6967358],
                [105.6148698, 22.6979135],
                [105.6167302, 22.6991604],
                [105.6171448, 22.7004764],
                [105.6173223, 22.701749],
                [105.6178622, 22.7025321],
                [105.618751, 22.7028314],
                [105.6200187, 22.7027879],
                [105.6207143, 22.7020423],
                [105.6213987, 22.7001446],
                [105.6225314, 22.6995967],
                [105.6232516, 22.7000539],
                [105.6242028, 22.7000995],
                [105.6249832, 22.6993342],
                [105.6260886, 22.6999158],
                [105.6269274, 22.6995878],
                [105.6287249, 22.6993878],
                [105.6301193, 22.6992266],
                [105.6305467, 22.7003526],
                [105.6311948, 22.7008147],
                [105.632287, 22.7006084],
                [105.6338378, 22.701183],
                [105.6350255, 22.7009644],
                [105.6351094, 22.700295],
                [105.6356704, 22.6999541],
                [105.6364597, 22.6991906],
                [105.6380087, 22.6994568],
                [105.6403673, 22.6991641],
                [105.6427856, 22.699632],
                [105.6443264, 22.6995277],
                [105.6463698, 22.6985656],
                [105.6456109, 22.7006672],
                [105.6467643, 22.7023281],
                [105.6467375, 22.7037408],
                [105.6487205, 22.7052413],
                [105.6506337, 22.7064914],
                [105.6519451, 22.7074874],
                [105.6521196, 22.7084651],
                [105.6533029, 22.7085403],
                [105.6534138, 22.7098194],
                [105.6540254, 22.7103802],
                [105.655096, 22.7105885],
                [105.6552202, 22.7118021],
                [105.6542654, 22.7128111],
                [105.6545789, 22.713877],
                [105.6547161, 22.7148019],
                [105.6550034, 22.715941],
                [105.6558068, 22.7165991],
                [105.6571065, 22.7171346],
                [105.6587134, 22.7177332],
                [105.6600711, 22.7186302],
                [105.6608155, 22.7195005],
                [105.6614656, 22.7210019],
                [105.6617087, 22.722588],
                [105.6625659, 22.7235359],
                [105.6638131, 22.7240306],
                [105.6641445, 22.724834],
                [105.6648427, 22.7248594],
                [105.6662548, 22.7249741],
                [105.6674194, 22.725155],
                [105.6685019, 22.7235183],
                [105.6691175, 22.7239166],
                [105.6701442, 22.7243769],
                [105.6702507, 22.7251735],
                [105.6706841, 22.7258853],
                [105.6716684, 22.726099],
                [105.6732722, 22.7260005],
                [105.6738495, 22.7252224],
                [105.6758895, 22.7234138],
                [105.678122, 22.7226269],
                [105.6793401, 22.7215406],
                [105.6804349, 22.7213288],
                [105.6817117, 22.7202562],
                [105.6832027, 22.7198827],
                [105.6849489, 22.7192867],
                [105.6864359, 22.7183013],
                [105.687971, 22.7190467],
                [105.6891022, 22.7189098],
                [105.6912017, 22.7183213],
                [105.6912465, 22.7196602],
                [105.6918462, 22.7208708],
                [105.6925794, 22.7225069],
                [105.6930798, 22.7242818],
                [105.6938322, 22.7267155],
                [105.6947479, 22.7284457],
                [105.6962246, 22.7291475],
                [105.6983633, 22.7242505],
                [105.7005788, 22.7201035],
                [105.7020277, 22.7221505],
                [105.7028996, 22.7234485],
                [105.7035818, 22.7246757],
                [105.704345, 22.7257542],
                [105.705485, 22.7269329],
                [105.7067636, 22.7277763],
                [105.7081271, 22.7280733],
                [105.7092517, 22.7279908],
                [105.709475, 22.7302193],
                [105.7099008, 22.732005],
                [105.7117538, 22.7325882],
                [105.7138978, 22.7334562],
                [105.7164643, 22.732835],
                [105.7188924, 22.7324171],
                [105.7213396, 22.731504],
                [105.7227218, 22.7314176],
                [105.7235291, 22.7321694],
                [105.7243858, 22.7335868],
                [105.7244871, 22.7354717],
                [105.7248398, 22.7366461],
                [105.7258894, 22.738495],
                [105.726455, 22.7388148],
                [105.7276162, 22.7388038],
                [105.7292232, 22.7390743],
                [105.7301787, 22.739573],
                [105.7315956, 22.7398159],
                [105.7328399, 22.7411388],
                [105.7336912, 22.7406256],
                [105.7343258, 22.7396735],
                [105.7355609, 22.7396668],
                [105.7361475, 22.7399728],
                [105.7373977, 22.7403693],
                [105.7384161, 22.7408652],
                [105.7392944, 22.7413755],
                [105.7401911, 22.741218],
                [105.741803, 22.7396915],
                [105.742605, 22.7393424],
                [105.7436563, 22.737504],
                [105.7448899, 22.7360507],
                [105.7457499, 22.7340285],
                [105.7460214, 22.7323994],
                [105.7464081, 22.7317258],
                [105.747075, 22.7297413],
                [105.7478605, 22.7298066],
                [105.7490563, 22.729639],
                [105.7505125, 22.7269317],
                [105.7517268, 22.7266334],
                [105.7527781, 22.7268428],
                [105.7535832, 22.7272374],
                [105.7543164, 22.7277799],
                [105.7547107, 22.7282097],
                [105.7552303, 22.7281066],
                [105.7558215, 22.7279076],
                [105.7567518, 22.7271705],
                [105.7573649, 22.7266771],
                [105.757852, 22.7260856],
                [105.7582632, 22.7252813],
                [105.7585747, 22.7234671],
                [105.7590911, 22.7215627],
                [105.7592322, 22.7205277],
                [105.7589961, 22.720007],
                [105.7578261, 22.7188178],
                [105.7561207, 22.7169326],
                [105.7558082, 22.7164279],
                [105.7550327, 22.7154368],
                [105.7540716, 22.7147524],
                [105.7531687, 22.7139466],
                [105.7518843, 22.7130555],
                [105.751718, 22.7126299],
                [105.7517893, 22.7116429],
                [105.7520152, 22.7104705],
                [105.7525175, 22.7095441],
                [105.7532231, 22.7081769],
                [105.7544333, 22.7061069],
                [105.7550864, 22.7043198],
                [105.755896, 22.7025768],
                [105.7569794, 22.7005402],
                [105.7589456, 22.698754],
                [105.7603568, 22.6974711],
                [105.7617394, 22.6963674],
                [105.7632017, 22.6958475],
                [105.7645406, 22.6958966],
                [105.7661042, 22.6961906],
                [105.7678695, 22.6941278],
                [105.7686571, 22.69374],
                [105.7703822, 22.6936579],
                [105.7720475, 22.6933085],
                [105.7741055, 22.6926301],
                [105.7768494, 22.6919609],
                [105.775314, 22.6900468],
                [105.7748342, 22.6891044],
                [105.7746893, 22.687803],
                [105.7739281, 22.6867516],
                [105.7739185, 22.6854562],
                [105.7737617, 22.6848996],
                [105.7728643, 22.6843539],
                [105.771888, 22.6842961],
                [105.7709867, 22.6838982],
                [105.7698397, 22.6823361],
                [105.7691742, 22.6809929],
                [105.7685374, 22.6798677],
                [105.7678374, 22.6788788],
                [105.76648, 22.6782286],
                [105.7654267, 22.6777112],
                [105.7643465, 22.6768133],
                [105.7631344, 22.6756706],
                [105.7618637, 22.6749165],
                [105.7612255, 22.6742823],
                [105.7606008, 22.6734413],
                [105.7606234, 22.672583],
                [105.7601626, 22.6714954],
                [105.759468, 22.6709989],
                [105.7568634, 22.6699397],
                [105.7549635, 22.6690447],
                [105.7542469, 22.6684411],
                [105.7533566, 22.6673165],
                [105.7526187, 22.6664748],
                [105.7514749, 22.6660278],
                [105.7505522, 22.6652717],
                [105.7497034, 22.664046],
                [105.7490481, 22.6620983],
                [105.7484908, 22.6594759],
                [105.7484703, 22.6588871],
                [105.7473359, 22.6576781],
                [105.7465144, 22.6567611],
                [105.7457904, 22.6555824],
                [105.7447897, 22.65393],
                [105.7440348, 22.6530328],
                [105.7429151, 22.6521057],
                [105.7413907, 22.6510225],
                [105.7406339, 22.6502266],
                [105.7404523, 22.6496134],
                [105.7402886, 22.6484614],
                [105.7400907, 22.646436],
                [105.7401688, 22.6456604],
                [105.7403243, 22.6446182],
                [105.7401404, 22.6436161],
                [105.7393811, 22.6426623],
                [105.7377313, 22.6418836],
                [105.7360601, 22.6413552],
                [105.7346031, 22.6412875],
                [105.7332226, 22.6414385],
                [105.7321853, 22.6415104],
                [105.7313764, 22.6411978],
                [105.729529, 22.6400124],
                [105.7284671, 22.6389121],
                [105.7268923, 22.6369681],
                [105.7268327, 22.6355674],
                [105.7277331, 22.63594],
                [105.7282623, 22.6361206],
                [105.7287007, 22.6362208],
                [105.7289711, 22.6361121],
                [105.7290748, 22.6358801],
                [105.7289412, 22.6356388],
                [105.7287807, 22.6351426],
                [105.7289628, 22.634603],
                [105.7293538, 22.633906],
                [105.729578, 22.6332984],
                [105.7294154, 22.6326428],
                [105.7289206, 22.6318085],
                [105.7283174, 22.631205],
                [105.7282915, 22.6308327],
                [105.7289074, 22.6305454],
                [105.7299397, 22.6304719],
                [105.7310195, 22.630937],
                [105.7320648, 22.6311883],
                [105.7331024, 22.6310977],
                [105.7345566, 22.6305887],
                [105.7357374, 22.6299048],
                [105.7369687, 22.62896],
                [105.7376596, 22.6286128],
                [105.7385492, 22.6284841],
                [105.7391472, 22.6282965],
                [105.7397501, 22.6278398],
                [105.7401459, 22.6271877],
                [105.74059, 22.6260625],
                [105.7409518, 22.6251696],
                [105.7412997, 22.6240127],
                [105.7413517, 22.6235834],
                [105.7412962, 22.6229472],
                [105.7411806, 22.6225373],
                [105.7409432, 22.6222215],
                [105.7406423, 22.6219733],
                [105.7405193, 22.6217911],
                [105.74051, 22.6216219],
                [105.7405927, 22.6214083],
                [105.7407008, 22.6213008],
                [105.7410087, 22.6211753],
                [105.7412097, 22.6210541],
                [105.7414254, 22.6206918],
                [105.7415793, 22.6204181],
                [105.7419011, 22.62014],
                [105.7426143, 22.6197525],
                [105.743108, 22.6196124],
                [105.7435896, 22.6195896],
                [105.7438744, 22.6196254],
                [105.7441796, 22.6195684],
                [105.7446686, 22.6193487],
                [105.7449747, 22.6192915],
                [105.7452448, 22.6191459],
                [105.7454681, 22.618843],
                [105.7458092, 22.6184975],
                [105.7462762, 22.6182434],
                [105.7465802, 22.6180861],
                [105.7467983, 22.617795],
                [105.7470569, 22.6173286],
                [105.7473969, 22.6167337],
                [105.747727, 22.6163422],
                [105.74817, 22.615913],
                [105.748458, 22.6155173],
                [105.748936, 22.6148932],
                [105.7493773, 22.6145002],
                [105.7499065, 22.6140249],
                [105.7501746, 22.6136034],
                [105.7503812, 22.6129374],
                [105.750744, 22.6113534],
                [105.7507942, 22.6109597],
                [105.7507854, 22.6100665],
                [105.7508791, 22.6096215],
                [105.7511358, 22.6092163],
                [105.7514425, 22.6089186],
                [105.7518068, 22.6087663],
                [105.7523365, 22.6086999],
                [105.7527636, 22.608707],
                [105.7530636, 22.6086231],
                [105.7532841, 22.6084142],
                [105.7532831, 22.6080958],
                [105.7532963, 22.6076796],
                [105.7535139, 22.6069468],
                [105.7536764, 22.6065529],
                [105.7538614, 22.6060117],
                [105.7538434, 22.6056427],
                [105.7536688, 22.6053541],
                [105.7532985, 22.605018],
                [105.7528709, 22.6048189],
                [105.7526117, 22.6044886],
                [105.7526671, 22.6038988],
                [105.7527929, 22.6033667],
                [105.7527309, 22.6030968],
                [105.7523569, 22.602667],
                [105.7520976, 22.6024708],
                [105.7518273, 22.6023775],
                [105.7511524, 22.6023028],
                [105.7506407, 22.6021231],
                [105.7498515, 22.6014595],
                [105.7495107, 22.6010809],
                [105.7486846, 22.6005672],
                [105.7480028, 22.6000438],
                [105.74713, 22.5995295],
                [105.7467198, 22.5991381],
                [105.7462996, 22.5986497],
                [105.7461649, 22.5983813],
                [105.7461916, 22.5981905],
                [105.7465027, 22.5977371],
                [105.7466956, 22.5973181],
                [105.7468611, 22.5965748],
                [105.7469481, 22.5961021],
                [105.7468872, 22.5958457],
                [105.7466824, 22.5956346],
                [105.746432, 22.5955492],
                [105.7461378, 22.5955122],
                [105.7458509, 22.5953945],
                [105.7455784, 22.5950957],
                [105.7454195, 22.5947147],
                [105.7454197, 22.5943508],
                [105.7455575, 22.5940573],
                [105.7457959, 22.5937471],
                [105.7461904, 22.593652],
                [105.7464618, 22.593684],
                [105.7467381, 22.5938131],
                [105.747016, 22.5940128],
                [105.7474379, 22.5941429],
                [105.7478642, 22.5941231],
                [105.7482133, 22.5939343],
                [105.7485044, 22.5936728],
                [105.7486143, 22.5934196],
                [105.7486769, 22.59306],
                [105.7488165, 22.5927312],
                [105.7490097, 22.5925246],
                [105.7496009, 22.5923005],
                [105.7499803, 22.5920563],
                [105.7502667, 22.5917411],
                [105.7503958, 22.5914684],
                [105.7506217, 22.5905487],
                [105.7506639, 22.5900651],
                [105.7507158, 22.5896849],
                [105.7509363, 22.58939],
                [105.7513177, 22.5892145],
                [105.7517257, 22.589101],
                [105.7519745, 22.5888704],
                [105.752127, 22.5885092],
                [105.752046, 22.5878807],
                [105.751878, 22.5876469],
                [105.7515406, 22.5874143],
                [105.7510242, 22.5872079],
                [105.7507427, 22.5869903],
                [105.7506734, 22.5867212],
                [105.750809, 22.5864216],
                [105.7510538, 22.586142],
                [105.7513968, 22.5860199],
                [105.7518353, 22.5861028],
                [105.752236, 22.5863071],
                [105.7525643, 22.586499],
                [105.7529365, 22.5866604],
                [105.7531607, 22.586669],
                [105.7534907, 22.5865216],
                [105.753893, 22.5862873],
                [105.7544293, 22.5860071],
                [105.755083, 22.5858515],
                [105.7556922, 22.5858573],
                [105.7561958, 22.5859945],
                [105.7565178, 22.5861942],
                [105.7567443, 22.5864125],
                [105.7569646, 22.5867383],
                [105.7573021, 22.5870084],
                [105.7576466, 22.5871147],
                [105.7583518, 22.5869276],
                [105.7594255, 22.5864426],
                [105.7602358, 22.5860104],
                [105.7608336, 22.5858408],
                [105.7613229, 22.5858427],
                [105.7620188, 22.5860896],
                [105.7625107, 22.5864387],
                [105.7629474, 22.5869218],
                [105.7637309, 22.5875675],
                [105.764553, 22.5878679],
                [105.7653109, 22.5879605],
                [105.7659725, 22.5878761],
                [105.7665455, 22.5873916],
                [105.7672218, 22.5865603],
                [105.7677259, 22.5857735],
                [105.7681807, 22.5851148],
                [105.7684439, 22.5848434],
                [105.7689049, 22.5846224],
                [105.7694641, 22.5843603],
                [105.7711161, 22.5826899],
                [105.7717279, 22.5819389],
                [105.7721521, 22.5814368],
                [105.772423, 22.5809959],
                [105.7727364, 22.5805402],
                [105.7729557, 22.5803239],
                [105.7732985, 22.5800987],
                [105.7737231, 22.5798797],
                [105.7744047, 22.5795206],
                [105.7747768, 22.5792187],
                [105.7752396, 22.5787627],
                [105.7758264, 22.5782363],
                [105.7763924, 22.5776287],
                [105.7770333, 22.5771078],
                [105.7774883, 22.5766374],
                [105.777792, 22.5761096],
                [105.777827, 22.5756591],
                [105.7777039, 22.5752503],
                [105.7774399, 22.5750238],
                [105.7772142, 22.574883],
                [105.7770448, 22.5746156],
                [105.7770475, 22.5742578],
                [105.7771069, 22.5739258],
                [105.7772709, 22.5736636],
                [105.7775865, 22.5735175],
                [105.7780419, 22.5733824],
                [105.7786418, 22.5732516],
                [105.7789819, 22.5731043],
                [105.7790727, 22.5727613],
                [105.7789751, 22.5722229],
                [105.7788251, 22.5717715],
                [105.7784417, 22.5712484],
                [105.7778314, 22.5707202],
                [105.7770444, 22.570188],
                [105.7765388, 22.5699874],
                [105.7762106, 22.5696968],
                [105.775906, 22.5690131],
                [105.7751994, 22.5677994],
                [105.7749658, 22.5673324],
                [105.7745415, 22.5668562],
                [105.7744727, 22.5666012],
                [105.7744675, 22.5662219],
                [105.7743887, 22.5659947],
                [105.7741952, 22.56584],
                [105.7738885, 22.5658022],
                [105.7733741, 22.5658777],
                [105.7727193, 22.5660676],
                [105.7720815, 22.5662444],
                [105.7715683, 22.5662626],
                [105.7711958, 22.5661772],
                [105.7709583, 22.5659637],
                [105.7707839, 22.5656772],
                [105.7705481, 22.5649432],
                [105.7705069, 22.5645497],
                [105.7706793, 22.564009],
                [105.7708747, 22.5636429],
                [105.7710156, 22.5633801],
                [105.771171, 22.5629092],
                [105.7714017, 22.562592],
                [105.7716556, 22.5623546],
                [105.7719469, 22.5622278],
                [105.7724291, 22.5621007],
                [105.7726588, 22.5620111],
                [105.7727819, 22.5618279],
                [105.7727941, 22.5616235],
                [105.7727496, 22.5613274],
                [105.7727908, 22.5610381],
                [105.7729412, 22.5606973],
                [105.7732396, 22.5601382],
                [105.7734624, 22.5597242],
                [105.7735453, 22.5594161],
                [105.7735668, 22.5591102],
                [105.7734749, 22.5586502],
                [105.7733065, 22.5583042],
                [105.7730779, 22.5580093],
                [105.7727868, 22.5578644],
                [105.7724575, 22.5578367],
                [105.7719904, 22.5578332],
                [105.7713229, 22.5577397],
                [105.7702217, 22.557368],
                [105.7691483, 22.5569642],
                [105.7681189, 22.5567386],
                [105.7677434, 22.5565637],
                [105.7676411, 22.5562135],
                [105.7676694, 22.5558696],
                [105.767968, 22.555545],
                [105.7687193, 22.5551647],
                [105.7694097, 22.5549343],
                [105.7699911, 22.5547627],
                [105.7702161, 22.5547711],
                [105.7704031, 22.5548866],
                [105.7705869, 22.5551373],
                [105.7707863, 22.5552928],
                [105.7710311, 22.5553342],
                [105.7712814, 22.5552861],
                [105.7718947, 22.5550137],
                [105.7726342, 22.5547164],
                [105.7730983, 22.5545758],
                [105.7735437, 22.5544803],
                [105.7738696, 22.5543603],
                [105.7740563, 22.5542176],
                [105.7741888, 22.5540235],
                [105.7742569, 22.5537044],
                [105.7741501, 22.5533942],
                [105.7740696, 22.5529956],
                [105.7742142, 22.5526628],
                [105.7744555, 22.5522569],
                [105.7745297, 22.5518746],
                [105.7744523, 22.5514457],
                [105.7742478, 22.5511734],
                [105.7739805, 22.5510161],
                [105.7735956, 22.5510558],
                [105.7733238, 22.5512171],
                [105.7731075, 22.5514759],
                [105.7728602, 22.5517028],
                [105.7725682, 22.5518303],
                [105.7721708, 22.551839],
                [105.7719511, 22.5517871],
                [105.7717732, 22.5516307],
                [105.7717528, 22.5513818],
                [105.7717584, 22.550979],
                [105.7716265, 22.5504401],
                [105.7716382, 22.5501505],
                [105.771767, 22.5499337],
                [105.7720759, 22.5497043],
                [105.7736142, 22.5496148],
                [105.7744504, 22.5496648],
                [105.775049, 22.5498453],
                [105.7754899, 22.5500992],
                [105.7757516, 22.550317],
                [105.7760671, 22.5503558],
                [105.7763841, 22.5502968],
                [105.7767552, 22.5501185],
                [105.7770144, 22.5499249],
                [105.7772452, 22.5495894],
                [105.7773795, 22.5492874],
                [105.7775719, 22.5490358],
                [105.7779337, 22.5488087],
                [105.7783935, 22.5486658],
                [105.7788252, 22.5486441],
                [105.7789818, 22.5486913],
                [105.7791437, 22.5487401],
                [105.7794328, 22.5489346],
                [105.7798851, 22.54918],
                [105.7803406, 22.5492085],
                [105.7809823, 22.5490521],
                [105.7816902, 22.5488837],
                [105.7821696, 22.5489223],
                [105.7825048, 22.5490945],
                [105.7827177, 22.5493465],
                [105.7829029, 22.5497957],
                [105.78315, 22.5505989],
                [105.7832635, 22.5509743],
                [105.7837067, 22.5511988],
                [105.7844728, 22.5512755],
                [105.7854605, 22.5512406],
                [105.7863022, 22.5510694],
                [105.7870957, 22.5506942],
                [105.7874597, 22.5503485],
                [105.7876137, 22.550041],
                [105.7876559, 22.5493093],
                [105.7876176, 22.548929],
                [105.7873167, 22.5483938],
                [105.787272, 22.5479365],
                [105.7875057, 22.54755],
                [105.7878288, 22.5472719],
                [105.7882294, 22.5471566],
                [105.7889587, 22.5470929],
                [105.789711, 22.5469685],
                [105.7903963, 22.5467284],
                [105.7907452, 22.5464605],
                [105.7909914, 22.546112],
                [105.79116, 22.5456401],
                [105.7912162, 22.5451697],
                [105.7912489, 22.5447013],
                [105.7914, 22.5441975],
                [105.7916932, 22.5438018],
                [105.7919237, 22.5432336],
                [105.7922622, 22.5429066],
                [105.7925715, 22.5427032],
                [105.7928127, 22.5423352],
                [105.7930252, 22.5420212],
                [105.7932079, 22.5416518],
                [105.7932211, 22.541254],
                [105.7930614, 22.5407499],
                [105.79286, 22.5403758],
                [105.7928193, 22.5400701],
                [105.7929086, 22.5393489],
                [105.7931215, 22.5389573],
                [105.7937629, 22.5381354],
                [105.7940505, 22.537569],
                [105.794227, 22.5367905],
                [105.794597, 22.5355606],
                [105.7949763, 22.5349632],
                [105.7956297, 22.5342749],
                [105.7960728, 22.533699],
                [105.7965148, 22.533512],
                [105.7969831, 22.5334233],
                [105.7977956, 22.5332987],
                [105.7981717, 22.5329959],
                [105.798335, 22.5325481],
                [105.7984145, 22.5319846],
                [105.798639, 22.5313306],
                [105.7994557, 22.5306432],
                [105.8001357, 22.5300397],
                [105.8006692, 22.5290864],
                [105.8012568, 22.5275902],
                [105.801632, 22.5261936],
                [105.8020213, 22.5255347],
                [105.8025503, 22.5250937],
                [105.8031626, 22.5248328],
                [105.803612, 22.5245726],
                [105.8040308, 22.524183],
                [105.804359, 22.5237394],
                [105.8069585, 22.5230415],
                [105.8090931, 22.522807],
                [105.8109537, 22.5223765],
                [105.8119852, 22.5222152],
                [105.8132312, 22.5218114],
                [105.8147425, 22.5211481],
                [105.8160988, 22.5210853],
                [105.8180586, 22.5211067],
                [105.8189967, 22.5210544],
                [105.820159, 22.5207824],
                [105.8216046, 22.5201735],
                [105.8223822, 22.5201307],
                [105.8237196, 22.5206603],
                [105.825311, 22.5218719],
                [105.8266112, 22.5225967],
                [105.8276022, 22.5230372],
                [105.8287144, 22.5234799],
                [105.8295122, 22.523571],
                [105.8299295, 22.5237747],
                [105.8304243, 22.5243051],
                [105.831219, 22.5251366],
                [105.8319957, 22.5257239],
                [105.8325617, 22.5260091],
                [105.8331276, 22.5260564],
                [105.8336123, 22.525891],
                [105.8343159, 22.5253042],
                [105.8348667, 22.5244485],
                [105.8349932, 22.5238786],
                [105.8352247, 22.5231336],
                [105.8353442, 22.5224504],
                [105.8351246, 22.5216765],
                [105.8343312, 22.5198085],
                [105.8337609, 22.5179093],
                [105.8341428, 22.5160893],
                [105.8346131, 22.5156416],
                [105.8366472, 22.5157749],
                [105.8376665, 22.5156495],
                [105.8388911, 22.5154216],
                [105.8399695, 22.5155908],
                [105.8408216, 22.5155411],
                [105.8418117, 22.5153473],
                [105.8443348, 22.515808],
                [105.8462512, 22.5167309],
                [105.8479342, 22.5177113],
                [105.8486882, 22.5186596],
                [105.8493122, 22.5191975],
                [105.8505386, 22.5195949],
                [105.8521689, 22.5196456],
                [105.8534329, 22.5196747],
                [105.8542892, 22.5200701],
                [105.8557285, 22.520805],
                [105.8572395, 22.5213576],
                [105.8590537, 22.5214385],
                [105.8631113, 22.5209439],
                [105.8666919, 22.5201674],
                [105.8690953, 22.5189314],
                [105.8696667, 22.5179659],
                [105.8707835, 22.517021],
                [105.8719662, 22.5166886],
                [105.8724569, 22.5170799],
                [105.8729363, 22.5171561],
                [105.8734628, 22.5168257],
                [105.8738184, 22.5157349],
                [105.8749357, 22.515054],
                [105.8757966, 22.5147255],
                [105.876836, 22.5142436],
                [105.8777448, 22.5141528],
                [105.8786958, 22.5142308],
                [105.8795767, 22.51415],
                [105.8810837, 22.5135813],
                [105.8822981, 22.513368],
                [105.8837361, 22.5131191],
                [105.8869737, 22.5119104],
                [105.8899109, 22.5110376],
                [105.8942757, 22.5087387],
                [105.8968646, 22.5075601],
                [105.8989679, 22.5067735],
                [105.8981994, 22.5055752],
                [105.8982185, 22.50442],
                [105.8985254, 22.5033425],
                [105.8997337, 22.5019088],
                [105.9007484, 22.5006438],
                [105.9018546, 22.4991683],
                [105.9026531, 22.4980717],
                [105.9030289, 22.4973214],
                [105.9034538, 22.4966036],
                [105.9037365, 22.4958199],
                [105.9040317, 22.4950472],
                [105.905775, 22.4951805],
                [105.9066389, 22.4954724],
                [105.907601, 22.4958341],
                [105.9087267, 22.496035],
                [105.9107629, 22.4957321],
                [105.9119587, 22.4959186],
                [105.9131859, 22.4967265],
                [105.9132152, 22.4981721],
                [105.9134667, 22.4988892],
                [105.9139361, 22.4997666],
                [105.9149754, 22.5010941],
                [105.9162409, 22.5028092],
                [105.917049, 22.504081],
                [105.9179983, 22.5048936],
                [105.9187279, 22.506103],
                [105.9195111, 22.5069511],
                [105.9204259, 22.508163],
                [105.9208057, 22.5088474],
                [105.9207947, 22.5095613],
                [105.9210446, 22.5103482],
                [105.9216278, 22.5114786],
                [105.9226851, 22.5133185],
                [105.9238375, 22.5149228],
                [105.9238852, 22.516132],
                [105.923907, 22.517481],
                [105.9240604, 22.5188641],
                [105.9237283, 22.5195604],
                [105.9237863, 22.5203178],
                [105.9241286, 22.5208667],
                [105.9246956, 22.5213952],
                [105.9261245, 22.5222815],
                [105.9278325, 22.5235847],
                [105.928525, 22.5246487],
                [105.9301469, 22.5257413],
                [105.9312018, 22.5266081],
                [105.9317506, 22.5272898],
                [105.9327685, 22.5278171],
                [105.9333593, 22.5279599],
                [105.9345744, 22.5278451],
                [105.9358657, 22.5278321],
                [105.9370339, 22.5277184],
                [105.9380003, 22.5275218],
                [105.9389154, 22.5269774],
                [105.9396172, 22.5270854],
                [105.9403321, 22.527411],
                [105.9408651, 22.5275077],
                [105.9419935, 22.5274928],
                [105.9429927, 22.5275285],
                [105.9450839, 22.5281078],
                [105.9469616, 22.528396],
                [105.9475756, 22.527759],
                [105.9477931, 22.5271845],
                [105.9484205, 22.5259742],
                [105.9490636, 22.5256091],
                [105.9514263, 22.5239233],
                [105.9518977, 22.5230539],
                [105.952829, 22.5225627],
                [105.9537722, 22.5225415],
                [105.9545034, 22.5229037],
                [105.9557612, 22.523447],
                [105.9564146, 22.5234518],
                [105.9573456, 22.523734],
                [105.9579182, 22.524077],
                [105.9587589, 22.524971],
                [105.9590852, 22.5257768],
                [105.9598547, 22.5266484],
                [105.960855, 22.5276666],
                [105.9617173, 22.5273058],
                [105.9623197, 22.5273895],
                [105.9626408, 22.5273467],
                [105.9632473, 22.5275855],
                [105.9638805, 22.5275651],
                [105.964046, 22.5273715],
                [105.9649568, 22.5277514],
                [105.9655308, 22.5280799],
                [105.9662763, 22.5289796],
                [105.966768, 22.5300328],
                [105.9674739, 22.53132],
                [105.9677934, 22.5318059],
                [105.9684619, 22.5330606],
                [105.9688456, 22.5342029],
                [105.9689825, 22.5350193],
                [105.9701928, 22.5356165],
                [105.9706048, 22.5360592],
                [105.9712301, 22.5364348],
                [105.9721091, 22.5368813],
                [105.9728001, 22.5370129],
                [105.9734941, 22.5367827],
                [105.9740101, 22.5367104],
                [105.9745178, 22.5368876],
                [105.9753078, 22.5369261],
                [105.9766037, 22.5364459],
                [105.9775513, 22.5363778],
                [105.9795765, 22.5382667],
                [105.98103, 22.5386678],
                [105.9826292, 22.5391583],
                [105.9837334, 22.5397225],
                [105.9847033, 22.5406854],
                [105.9850982, 22.5413003],
                [105.985214, 22.541743],
                [105.9852586, 22.5424417],
                [105.9854364, 22.5430038],
                [105.9859187, 22.5434661],
                [105.9864304, 22.543719],
                [105.9868871, 22.5436383],
                [105.9886766, 22.5429705],
                [105.9896886, 22.5429112],
                [105.9913725, 22.5427283],
                [105.9924824, 22.544212],
                [105.9933615, 22.5448902],
                [105.9945092, 22.5452882],
                [105.9972735, 22.5459252],
                [106.000965, 22.5469529],
                [106.0027259, 22.5478212],
                [106.0029537, 22.548308],
                [106.0030257, 22.5488578],
                [106.0030736, 22.550841],
                [106.0038045, 22.5526589],
                [106.0042905, 22.5532617],
                [106.0049665, 22.5537875],
                [106.0067456, 22.5548133],
                [106.008959, 22.5550449],
                [106.0109652, 22.5553524],
                [106.0120461, 22.555782],
                [106.0132526, 22.5560145],
                [106.0145301, 22.5566872],
                [106.0162904, 22.5577169],
                [106.0173479, 22.5584535],
                [106.0183166, 22.5590671],
                [106.0195454, 22.5594386],
                [106.0213378, 22.5599515],
                [106.0226405, 22.5601164],
                [106.0234616, 22.5595997],
                [106.0246285, 22.5583657],
                [106.0257957, 22.557565],
                [106.0261934, 22.5574628],
                [106.0280363, 22.5580368],
                [106.0298772, 22.5589525],
                [106.0310492, 22.5594144],
                [106.0333886, 22.5600623],
                [106.0345366, 22.5603805],
                [106.0363619, 22.5611063],
                [106.037219, 22.5613305],
                [106.0387355, 22.5628482],
                [106.0396899, 22.5634815],
                [106.0406871, 22.5639525],
                [106.0415565, 22.5644591],
                [106.0420343, 22.5650603],
                [106.0420015, 22.5672396],
                [106.0441295, 22.5700366],
                [106.045888, 22.5728857],
                [106.0457325, 22.5741606],
                [106.0460126, 22.5752807],
                [106.046611, 22.5761206],
                [106.0471777, 22.5770185],
                [106.0476135, 22.5773421],
                [106.0482756, 22.5774018],
                [106.0490555, 22.577013],
                [106.0499193, 22.576225],
                [106.0505147, 22.5752514],
                [106.0511631, 22.5747919],
                [106.052454, 22.5743362],
                [106.05361, 22.573693],
                [106.055011, 22.5726589],
                [106.0570876, 22.5710897],
                [106.0584644, 22.5702158],
                [106.0594632, 22.5699116],
                [106.0616903, 22.5699],
                [106.0628025, 22.5698279],
                [106.064012, 22.569465],
                [106.0656722, 22.5690585],
                [106.0666571, 22.5689701],
                [106.0677362, 22.5691476],
                [106.0708157, 22.5722999],
                [106.0717093, 22.5723202],
                [106.0735413, 22.571984],
                [106.0754779, 22.5720909],
                [106.0768574, 22.5723955],
                [106.0783068, 22.5729304],
                [106.0794919, 22.5735179],
                [106.0805379, 22.573395],
                [106.0815315, 22.5729461],
                [106.082359, 22.5719993],
                [106.0825737, 22.5714871],
                [106.0823951, 22.5703336],
                [106.0819671, 22.5694148],
                [106.081966, 22.5683462],
                [106.0824602, 22.5676082],
                [106.0823424, 22.5668516],
                [106.0817673, 22.5661219],
                [106.0808045, 22.5657903],
                [106.0799633, 22.5657322],
                [106.0792016, 22.5653848],
                [106.0774403, 22.5639165],
                [106.0760652, 22.5626994],
                [106.0754256, 22.5619406],
                [106.074605, 22.5605717],
                [106.0743953, 22.5592093],
                [106.0742586, 22.5576452],
                [106.0738928, 22.556346],
                [106.073202, 22.5543855],
                [106.0737315, 22.5542223],
                [106.0749376, 22.5541065],
                [106.0770296, 22.5526791],
                [106.0788147, 22.5523696],
                [106.0806788, 22.5522767],
                [106.0829853, 22.5519919],
                [106.0837875, 22.5520608],
                [106.0848822, 22.552383],
                [106.08609, 22.5525821],
                [106.0870786, 22.5525312],
                [106.0876217, 22.5520559],
                [106.0887059, 22.5504582],
                [106.0895684, 22.5491823],
                [106.0903418, 22.5483983],
                [106.0909224, 22.5479463],
                [106.0916608, 22.5476505],
                [106.0926924, 22.5474908],
                [106.0939626, 22.5475668],
                [106.0950499, 22.5467966],
                [106.0954421, 22.5459811],
                [106.0951437, 22.5445022],
                [106.0945239, 22.5432495],
                [106.0939042, 22.5426671],
                [106.0934758, 22.5420898],
                [106.0927235, 22.5412084],
                [106.0923298, 22.5399042],
                [106.0921812, 22.5389019],
                [106.0921252, 22.5380167],
                [106.0926977, 22.5370927],
                [106.0927271, 22.5363242],
                [106.0931338, 22.5351321],
                [106.0940548, 22.5337765],
                [106.0944537, 22.5329975],
                [106.0948263, 22.5325637],
                [106.0952871, 22.5323876],
                [106.0957577, 22.5324081],
                [106.0966107, 22.5328099],
                [106.0976353, 22.5334156],
                [106.0989908, 22.5343358],
                [106.0997832, 22.5346306],
                [106.10092, 22.5352003],
                [106.1004911, 22.5339696],
                [106.099784, 22.532681],
                [106.0991484, 22.5321223],
                [106.0983844, 22.5318961],
                [106.09659, 22.5312392],
                [106.0958337, 22.5307379],
                [106.0954062, 22.5290089],
                [106.0949504, 22.5277442],
                [106.0952364, 22.5271942],
                [106.0957254, 22.5268161],
                [106.0963592, 22.5264073],
                [106.0970896, 22.5262494],
                [106.0977634, 22.5257917],
                [106.0982955, 22.5250857],
                [106.0988514, 22.5241796],
                [106.0993791, 22.5236412],
                [106.1022891, 22.5220214],
                [106.1022814, 22.5215523],
                [106.1020675, 22.520999],
                [106.1015999, 22.520277],
                [106.1008174, 22.5193626],
                [106.1004497, 22.5187666],
                [106.1003418, 22.5182128],
                [106.1004398, 22.5175842],
                [106.100962, 22.5169182],
                [106.1023171, 22.5160721],
                [106.1055904, 22.5158345],
                [106.1082162, 22.5145691],
                [106.1098465, 22.5141812],
                [106.1119925, 22.5136157],
                [106.1137229, 22.5128605],
                [106.1158792, 22.512624],
                [106.1185948, 22.512082],
                [106.1188137, 22.5114861],
                [106.1189474, 22.510726],
                [106.1189677, 22.5095988],
                [106.1187409, 22.5086721],
                [106.1186716, 22.5077814],
                [106.1182992, 22.5064989],
                [106.1178496, 22.5056037],
                [106.1178098, 22.5044959],
                [106.117962, 22.503548],
                [106.1183169, 22.5025079],
                [106.118987, 22.5012727],
                [106.1184507, 22.4988146],
                [106.1174332, 22.4970593],
                [106.1165754, 22.4956026],
                [106.1163676, 22.4947721],
                [106.11636, 22.49232],
                [106.1164231, 22.4893595],
                [106.1164024, 22.4885085],
                [106.1159075, 22.4872068],
                [106.1151431, 22.4859351],
                [106.114759, 22.4849858],
                [106.1146395, 22.4838944],
                [106.1148688, 22.4827273],
                [106.1150902, 22.4820799],
                [106.1154016, 22.4813876],
                [106.1159252, 22.4805691],
                [106.1164104, 22.4799517],
                [106.1168877, 22.4789861],
                [106.1172029, 22.4777994],
                [106.1176132, 22.4765785],
                [106.118307, 22.4754378],
                [106.1190696, 22.4744363],
                [106.1191998, 22.4738363],
                [106.1196929, 22.4726114],
                [106.1195969, 22.4721054],
                [106.1187422, 22.4713965],
                [106.1178056, 22.4709912],
                [106.1168461, 22.4708573],
                [106.1158818, 22.4705123],
                [106.1155081, 22.4705123],
                [106.1152195, 22.4689748],
                [106.1140239, 22.4678312],
                [106.1118576, 22.4656124],
                [106.1107726, 22.4641496],
                [106.1097155, 22.4636764],
                [106.1090235, 22.4613246],
                [106.1088642, 22.4609622],
                [106.1077048, 22.4596058],
                [106.107021, 22.458279],
                [106.1064016, 22.4562857],
                [106.1060456, 22.4558182],
                [106.105325, 22.4553688],
                [106.1049473, 22.4549004],
                [106.104858, 22.453784],
                [106.1041898, 22.4527872],
                [106.1030861, 22.451698],
                [106.1017178, 22.4510056],
                [106.1008439, 22.4507156],
                [106.1002749, 22.4504277],
                [106.0997483, 22.4499439],
                [106.0993143, 22.4491469],
                [106.0985651, 22.4482903],
                [106.0979465, 22.4478457],
                [106.0962129, 22.4470111],
                [106.0956782, 22.446942],
                [106.095397, 22.4466487],
                [106.0942337, 22.4463039],
                [106.0945656, 22.445133],
                [106.0943789, 22.4427059],
                [106.0947052, 22.4416451],
                [106.09576, 22.4409407],
                [106.0967164, 22.4407656],
                [106.0985465, 22.4398506],
                [106.0994453, 22.4380462],
                [106.1003261, 22.4373926],
                [106.101709, 22.4359296],
                [106.1017195, 22.434262],
                [106.101338, 22.4329656],
                [106.1010136, 22.4325213],
                [106.1008463, 22.4315719],
                [106.1000133, 22.4309988],
                [106.0998811, 22.4305287],
                [106.1005789, 22.4292437],
                [106.1004766, 22.4282069],
                [106.1004828, 22.4273693],
                [106.1011813, 22.4267715],
                [106.1016193, 22.426232],
                [106.1018203, 22.4256888],
                [106.1017372, 22.424996],
                [106.10168, 22.4242259],
                [106.1018909, 22.4233283],
                [106.1019723, 22.4220492],
                [106.1015392, 22.4210697],
                [106.1014403, 22.4204883],
                [106.1011815, 22.4198465],
                [106.1011958, 22.4180825],
                [106.1008024, 22.4172954],
                [106.0998084, 22.4164147],
                [106.0991699, 22.4154487],
                [106.0993577, 22.4147269],
                [106.1007749, 22.413847],
                [106.1016053, 22.4128585],
                [106.1034662, 22.4114441],
                [106.1056792, 22.41001],
                [106.1056892, 22.4091774],
                [106.1055637, 22.4074868],
                [106.1058801, 22.4064923],
                [106.1062993, 22.4059278],
                [106.10638, 22.4053564],
                [106.1062936, 22.4043104],
                [106.1047802, 22.4034595],
                [106.1043437, 22.4027546],
                [106.1043795, 22.4023725],
                [106.1075462, 22.4009812],
                [106.1104464, 22.3996298],
                [106.1115657, 22.3992974],
                [106.1124496, 22.3994202],
                [106.1135009, 22.3999457],
                [106.1151359, 22.4009584],
                [106.1156094, 22.4018702],
                [106.1162896, 22.4023307],
                [106.1172519, 22.4025227],
                [106.1182719, 22.4025658],
                [106.1199439, 22.4030873],
                [106.121908, 22.4030067],
                [106.1226114, 22.4027602],
                [106.1223406, 22.4023344],
                [106.1221093, 22.4013372],
                [106.1221443, 22.3997907],
                [106.1230247, 22.3997723],
                [106.1243439, 22.4000521],
                [106.1265709, 22.4001658],
                [106.1268658, 22.4003166],
                [106.1280403, 22.4006137],
                [106.1307065, 22.3998187],
                [106.1323664, 22.3993573],
                [106.133296, 22.3985053],
                [106.1347608, 22.3956274],
                [106.1361237, 22.3954617],
                [106.1370813, 22.3954258],
                [106.1386173, 22.3951528],
                [106.139517, 22.3947022],
                [106.1402629, 22.3947561],
                [106.1414723, 22.3958858],
                [106.1425368, 22.3972714],
                [106.144847, 22.3992443],
                [106.147647, 22.4015493],
                [106.1491951, 22.4017876],
                [106.1500004, 22.4023254],
                [106.1506836, 22.4032759],
                [106.1512429, 22.4042671],
                [106.1519103, 22.4046694],
                [106.1527791, 22.4048141],
                [106.1542327, 22.4055932],
                [106.1556153, 22.4079404],
                [106.1557857, 22.4091036],
                [106.1560821, 22.4096708],
                [106.1565449, 22.4103215],
                [106.1569769, 22.4104057],
                [106.1575989, 22.4102531],
                [106.1590312, 22.4095641],
                [106.1602941, 22.4094048],
                [106.1642143, 22.4093483],
                [106.1655682, 22.4089695],
                [106.1662577, 22.4089057],
                [106.1667934, 22.4091307],
                [106.1681299, 22.408938],
                [106.1683249, 22.4086701],
                [106.1676242, 22.4080355],
                [106.1671055, 22.4071167],
                [106.1672097, 22.4058576],
                [106.1674252, 22.4055211],
                [106.1679612, 22.405329],
                [106.1680458, 22.4049279],
                [106.1677702, 22.4042879],
                [106.1677072, 22.4037908],
                [106.1681068, 22.4033385],
                [106.1688327, 22.4032623],
                [106.1698383, 22.4035191],
                [106.1706302, 22.4039135],
                [106.1712985, 22.4037667],
                [106.1719718, 22.4040214],
                [106.1724117, 22.403976],
                [106.1727357, 22.4032786],
                [106.1737191, 22.4033134],
                [106.1743508, 22.4025786],
                [106.1752737, 22.4024932],
                [106.1763249, 22.4026328],
                [106.1773002, 22.4018745],
                [106.1773477, 22.4007236],
                [106.1772795, 22.4002238],
                [106.1770657, 22.4000007],
                [106.1767352, 22.3998266],
                [106.1762053, 22.3998216],
                [106.1760495, 22.3996699],
                [106.176187, 22.3992429],
                [106.1759825, 22.3986903],
                [106.1760432, 22.3983587],
                [106.1762365, 22.3978393],
                [106.176141, 22.3973152],
                [106.1759387, 22.3971141],
                [106.1757859, 22.3967438],
                [106.1758757, 22.3961208],
                [106.1763114, 22.3953377],
                [106.177724, 22.393746],
                [106.1781174, 22.392925],
                [106.1784684, 22.3912554],
                [106.179477, 22.3910337],
                [106.1802725, 22.3911228],
                [106.18093, 22.3910911],
                [106.181643, 22.3908435],
                [106.182403, 22.390643],
                [106.1830733, 22.390645],
                [106.1836848, 22.3905427],
                [106.1841389, 22.3902329],
                [106.1844298, 22.3895888],
                [106.1846357, 22.3884556],
                [106.1847986, 22.3870627],
                [106.1852027, 22.3876347],
                [106.1858963, 22.3884053],
                [106.1860173, 22.3886457],
                [106.186131, 22.3893074],
                [106.1864666, 22.3899665],
                [106.1865854, 22.3904636],
                [106.1864422, 22.391675],
                [106.1864582, 22.3919468],
                [106.1865764, 22.3921782],
                [106.1869668, 22.3926532],
                [106.1871054, 22.3930266],
                [106.1871194, 22.3935147],
                [106.1868882, 22.3943341],
                [106.1868608, 22.3949501],
                [106.1869675, 22.3952528],
                [106.1875552, 22.3960524],
                [106.1876529, 22.3964316],
                [106.1876054, 22.3967305],
                [106.1871601, 22.3980589],
                [106.1869495, 22.3988824],
                [106.1869315, 22.3994272],
                [106.1870888, 22.4003326],
                [106.187203, 22.4005291],
                [106.1873665, 22.4006813],
                [106.1883183, 22.4012899],
                [106.1886228, 22.4015824],
                [106.1897428, 22.4035184],
                [106.1905042, 22.4050071],
                [106.1908047, 22.4052369],
                [106.1919972, 22.4058507],
                [106.1925098, 22.406267],
                [106.1927698, 22.4065328],
                [106.1931372, 22.4070284],
                [106.1934176, 22.4075011],
                [106.1935862, 22.4079021],
                [106.1936541, 22.4082634],
                [106.1936264, 22.4085166],
                [106.1928885, 22.4102264],
                [106.1925778, 22.4111105],
                [106.1924419, 22.4116986],
                [106.192463, 22.4119456],
                [106.1927017, 22.4124871],
                [106.1928169, 22.4132592],
                [106.1930843, 22.4137544],
                [106.1931304, 22.413948],
                [106.1931065, 22.4143251],
                [106.1929432, 22.415151],
                [106.1929425, 22.4154247],
                [106.1930093, 22.4157045],
                [106.1932596, 22.4161139],
                [106.1950957, 22.418189],
                [106.1958411, 22.4188297],
                [106.197597, 22.4199965],
                [106.1982945, 22.4205893],
                [106.19914, 22.4215353],
                [106.1997835, 22.4224852],
                [106.1999264, 22.4227832],
                [106.2000827, 22.4233584],
                [106.2001066, 22.4240877],
                [106.2, 22.4245308],
                [106.1993012, 22.426041],
                [106.1992043, 22.4265749],
                [106.1992939, 22.4268568],
                [106.2001608, 22.428183],
                [106.2004929, 22.428812],
                [106.2006065, 22.4293149],
                [106.2008669, 22.4311019],
                [106.2010734, 22.4318075],
                [106.2012833, 22.4322591],
                [106.2016362, 22.4326466],
                [106.2041278, 22.4342671],
                [106.2059101, 22.4356931],
                [106.2062075, 22.4359776],
                [106.2063226, 22.4362193],
                [106.2062459, 22.4365601],
                [106.2061077, 22.4367445],
                [106.2057251, 22.4370968],
                [106.2054799, 22.4375524],
                [106.2054676, 22.4380215],
                [106.2055877, 22.4383007],
                [106.2059516, 22.4386434],
                [106.206482, 22.4388805],
                [106.2079567, 22.4390861],
                [106.2085885, 22.4393079],
                [106.2091899, 22.4396514],
                [106.2097769, 22.4400734],
                [106.2103265, 22.440549],
                [106.2106497, 22.4408903],
                [106.2109027, 22.4412391],
                [106.2110814, 22.4416033],
                [106.2115603, 22.4432396],
                [106.2124042, 22.4449507],
                [106.2125595, 22.4453556],
                [106.2130528, 22.4474734],
                [106.2132676, 22.4477753],
                [106.214048, 22.4484918],
                [106.2143334, 22.4488163],
                [106.2150476, 22.4502159],
                [106.2155103, 22.4508902],
                [106.2161729, 22.4516028],
                [106.2166019, 22.4519314],
                [106.217445, 22.4523729],
                [106.2189307, 22.4527526],
                [106.2192866, 22.4529152],
                [106.2196895, 22.4532556],
                [106.2209749, 22.4547029],
                [106.2213525, 22.4549873],
                [106.2216344, 22.4550906],
                [106.2223092, 22.4551086],
                [106.2238914, 22.4548943],
                [106.2272457, 22.4549297],
                [106.2294347, 22.4548518],
                [106.2302108, 22.4549014],
                [106.2305675, 22.454975],
                [106.231313, 22.4552343],
                [106.2319607, 22.4555619],
                [106.2321879, 22.4557196],
                [106.2324987, 22.4560409],
                [106.2327353, 22.4564194],
                [106.2329137, 22.4568429],
                [106.2331072, 22.4578717],
                [106.2332317, 22.4582344],
                [106.2334615, 22.4585672],
                [106.2337687, 22.4588454],
                [106.2343546, 22.4591603],
                [106.235632, 22.4596291],
                [106.236209, 22.4597791],
                [106.2371505, 22.4599005],
                [106.2374899, 22.4600194],
                [106.23781, 22.4602412],
                [106.2387138, 22.4611225],
                [106.2390821, 22.4613534],
                [106.2394158, 22.4614571],
                [106.239836, 22.4614339],
                [106.2402341, 22.4613061],
                [106.2419188, 22.4605483],
                [106.2435563, 22.4597476],
                [106.2440786, 22.4595393],
                [106.2460283, 22.4591602],
                [106.2469653, 22.4587473],
                [106.2473139, 22.458648],
                [106.2482664, 22.4585457],
                [106.2488336, 22.4583893],
                [106.2496044, 22.4580193],
                [106.2518968, 22.456654],
                [106.2526228, 22.4560456],
                [106.2537047, 22.4547728],
                [106.2541352, 22.4544821],
                [106.2544647, 22.4544221],
                [106.2551051, 22.454532],
                [106.2558685, 22.4545452],
                [106.2562593, 22.4544272],
                [106.2566492, 22.4541944],
                [106.2571499, 22.4536827],
                [106.2573093, 22.4530494],
                [106.2576522, 22.4522038],
                [106.2583434, 22.451068],
                [106.2589266, 22.450575],
                [106.2598944, 22.4503566],
                [106.2609047, 22.4503296],
                [106.2615857, 22.4503626],
                [106.2622078, 22.4507217],
                [106.2626253, 22.4512549],
                [106.2631896, 22.4520933],
                [106.2636202, 22.4529425],
                [106.2640992, 22.4534369],
                [106.2643056, 22.4534544],
                [106.2644497, 22.453415],
                [106.2645306, 22.453242],
                [106.2652861, 22.4523931],
                [106.2657788, 22.4521401],
                [106.2672803, 22.4516686],
                [106.2679582, 22.4513376],
                [106.2685009, 22.4509406],
                [106.268934, 22.4509181],
                [106.2692232, 22.4509732],
                [106.2694719, 22.4510862],
                [106.2702156, 22.4512145],
                [106.2708966, 22.4512474],
                [106.2715136, 22.451051],
                [106.2720878, 22.4506824],
                [106.2724554, 22.4502772],
                [106.2727999, 22.449604],
                [106.2731929, 22.448576],
                [106.2733358, 22.4484216],
                [106.2734999, 22.4483245],
                [106.2738288, 22.4482069],
                [106.2756641, 22.4481542],
                [106.2764061, 22.4480908],
                [106.2768581, 22.4478957],
                [106.2777163, 22.4470076],
                [106.2790356, 22.4457998],
                [106.2797918, 22.4450467],
                [106.2801989, 22.4444496],
                [106.2807297, 22.4438515],
                [106.281145, 22.443034],
                [106.2813542, 22.4411165],
                [106.2815838, 22.4402814],
                [106.281909, 22.4397616],
                [106.2821955, 22.4395294],
                [106.2827904, 22.4391607],
                [106.2841877, 22.438594],
                [106.2845973, 22.4382841],
                [106.2854204, 22.4369462],
                [106.2861452, 22.4350438],
                [106.2863905, 22.4336721],
                [106.2864621, 22.4325029],
                [106.2866389, 22.4315436],
                [106.2868179, 22.4297126],
                [106.2868504, 22.4287545],
                [106.2870793, 22.4278619],
                [106.2873224, 22.427381],
                [106.2878123, 22.4268407],
                [106.2884671, 22.4262798],
                [106.2895764, 22.4258112],
                [106.2912732, 22.4253092],
                [106.291912, 22.4252466],
                [106.2931086, 22.4252945],
                [106.2938945, 22.4255373],
                [106.2947212, 22.4257222],
                [106.2957429, 22.4258194],
                [106.2960106, 22.4257789],
                [106.296379, 22.4254885],
                [106.2966647, 22.4251414],
                [106.2971357, 22.4247929],
                [106.2979764, 22.4242688],
                [106.2985696, 22.4237468],
                [106.2991617, 22.4231099],
                [106.2995683, 22.4224743],
                [106.3001655, 22.4212722],
                [106.300349, 22.42106],
                [106.3005948, 22.4208664],
                [106.3010876, 22.4206518],
                [106.3015817, 22.420552],
                [106.3024042, 22.4202964],
                [106.3029794, 22.4200617],
                [106.303552, 22.4195399],
                [106.3040086, 22.4187411],
                [106.3051288, 22.4172378],
                [106.3058843, 22.4164463],
                [106.3071644, 22.4155067],
                [106.3080641, 22.4146948],
                [106.3092737, 22.4139379],
                [106.3100309, 22.4133188],
                [106.3110579, 22.4117875],
                [106.3116893, 22.4109585],
                [106.3121187, 22.4105719],
                [106.3129691, 22.4099999],
                [106.3140374, 22.4095697],
                [106.3187339, 22.4079509],
                [106.3197225, 22.407828],
                [106.3204863, 22.4079175],
                [106.3216231, 22.4081956],
                [106.322885, 22.4086258],
                [106.324531, 22.4093308],
                [106.3259451, 22.4105836],
                [106.3263834, 22.4111355],
                [106.3267392, 22.4116882],
                [106.3276238, 22.4125526],
                [106.3281, 22.4127594],
                [106.3285337, 22.4128134],
                [106.3289666, 22.4128097],
                [106.3295632, 22.4126516],
                [106.3306307, 22.4121447],
                [106.3319017, 22.4113679],
                [106.3334261, 22.4101197],
                [106.3337733, 22.4097721],
                [106.3340168, 22.4093677],
                [106.334138, 22.4090985],
                [106.3341356, 22.4088303],
                [106.3340281, 22.4083906],
                [106.3335764, 22.4075035],
                [106.3333849, 22.4068921],
                [106.3330175, 22.4051325],
                [106.332793, 22.4043011],
                [106.3326821, 22.4034782],
                [106.3328018, 22.4030558],
                [106.3335327, 22.4018428],
                [106.3340931, 22.4011581],
                [106.3354466, 22.4003806],
                [106.3359994, 22.3999738],
                [106.3365719, 22.399471],
                [106.3374732, 22.3988504],
                [106.3383822, 22.397952],
                [106.3391781, 22.3970834],
                [106.3396242, 22.3963135],
                [106.3400284, 22.3954672],
                [106.34085, 22.3940523],
                [106.3411147, 22.3937053],
                [106.3416461, 22.393222],
                [106.3422402, 22.3928147],
                [106.3426508, 22.3926389],
                [106.3437131, 22.3926971],
                [106.3439388, 22.3925803],
                [106.3443886, 22.3921742],
                [106.3444885, 22.3918477],
                [106.3444852, 22.391503],
                [106.3439713, 22.3895148],
                [106.3435977, 22.3881865],
                [106.3433452, 22.3876522],
                [106.343302, 22.3874611],
                [106.3433151, 22.3866754],
                [106.3435024, 22.3857831],
                [106.3437434, 22.3851105],
                [106.3443748, 22.3843007],
                [106.3445944, 22.3835709],
                [106.3445528, 22.3824504],
                [106.3440805, 22.3805004],
                [106.3439356, 22.3793617],
                [106.3437014, 22.3785974],
                [106.3433245, 22.3779875],
                [106.3416934, 22.3766504],
                [106.3410865, 22.3757168],
                [106.3411456, 22.3754288],
                [106.3420839, 22.3743866],
                [106.3435447, 22.3730047],
                [106.3438516, 22.3727723],
                [106.3443653, 22.3725956],
                [106.3449414, 22.3724758],
                [106.3453513, 22.3722234],
                [106.3456983, 22.3718757],
                [106.3459807, 22.3712221],
                [106.3462513, 22.3704247],
                [106.3464341, 22.3701359],
                [106.3470488, 22.3697475],
                [106.3484672, 22.3693335],
                [106.3491447, 22.3690405],
                [106.349534, 22.3687882],
                [106.3497171, 22.3685375],
                [106.3502681, 22.366895],
                [106.3511645, 22.3657955],
                [106.351306, 22.365507],
                [106.351692, 22.3649291],
                [106.3518999, 22.3640366],
                [106.3521197, 22.3633259],
                [106.352282, 22.3630371],
                [106.3531811, 22.362225],
                [106.3544339, 22.3617548],
                [106.3556983, 22.361409],
                [106.3571752, 22.3606495],
                [106.3589, 22.3599644],
                [106.3592851, 22.3592907],
                [106.3597073, 22.3581951],
                [106.359951, 22.35781],
                [106.3604018, 22.357538],
                [106.3608533, 22.3573427],
                [106.3620049, 22.3570647],
                [106.3634474, 22.3570143],
                [106.364521, 22.3571969],
                [106.3659296, 22.3579129],
                [106.3674178, 22.3583218],
                [106.3677689, 22.358358],
                [106.3780756, 22.3593076],
                [106.3784697, 22.3596198],
                [106.3791388, 22.362165],
                [106.3794585, 22.3625033],
                [106.3822106, 22.3628834],
                [106.382718, 22.363005],
                [106.3832049, 22.363247],
                [106.3849589, 22.364399],
                [106.3851262, 22.364491],
                [106.3853196, 22.3645123],
                [106.3864836, 22.3645497],
                [106.3868566, 22.3646699],
                [106.3873551, 22.3650488],
                [106.3878571, 22.3657725],
                [106.3882792, 22.366765],
                [106.3891775, 22.3679642],
                [106.3902996, 22.3688741],
                [106.392001, 22.3699707],
                [106.3932915, 22.3712238],
                [106.3949819, 22.3732974],
                [106.3953564, 22.373639],
                [106.3961004, 22.3738241],
                [106.3967596, 22.3737802],
                [106.3971287, 22.3735854],
                [106.39762, 22.3732364],
                [106.397862, 22.3726978],
                [106.3989617, 22.3713474],
                [106.3997377, 22.3706127],
                [106.4006821, 22.3702214],
                [106.4024928, 22.3698991],
                [106.404758, 22.3696497],
                [106.4089452, 22.3699006],
                [106.4114229, 22.3703005],
                [106.4127849, 22.3704418],
                [106.4134033, 22.3704364],
                [106.414306, 22.3700071],
                [106.4151031, 22.3693296],
                [106.4153018, 22.3685999],
                [106.4156669, 22.3680221],
                [106.4163196, 22.3673266],
                [106.416933, 22.3668232],
                [106.4181164, 22.3656252],
                [106.4188888, 22.3645455],
                [106.4213099, 22.3614017],
                [106.4223303, 22.3603965],
                [106.4236837, 22.3596951],
                [106.4252054, 22.3593368],
                [106.4266877, 22.3591706],
                [106.4288305, 22.359075],
                [106.4297452, 22.3598332],
                [106.4313091, 22.361601],
                [106.4331787, 22.363002],
                [106.4350399, 22.3635985],
                [106.4361138, 22.3637806],
                [106.4366083, 22.3637762],
                [106.4377979, 22.363191],
                [106.4382482, 22.3628805],
                [106.4385776, 22.3628393],
                [106.4392392, 22.3630249],
                [106.4398818, 22.3633832],
                [106.4401293, 22.363381],
                [106.4403737, 22.3631106],
                [106.4414278, 22.3613771],
                [106.4427326, 22.3599862],
                [106.4433454, 22.3594443],
                [106.4446602, 22.3590111],
                [106.4459778, 22.3588461],
                [106.4473376, 22.3587956],
                [106.4486923, 22.3582472],
                [106.4505166, 22.3572538],
                [106.4513382, 22.3569783],
                [106.4531927, 22.3569233],
                [106.4555994, 22.3584343],
                [106.4567171, 22.3588839],
                [106.4572958, 22.3590321],
                [106.4600344, 22.3587582],
                [106.4602825, 22.3588327],
                [106.4609076, 22.3594783],
                [106.4616184, 22.3604298],
                [106.4620734, 22.3605789],
                [106.4624857, 22.3605752],
                [106.4627734, 22.360496],
                [106.4641269, 22.3598325],
                [106.4649073, 22.3595572],
                [106.4657297, 22.3593581],
                [106.4663892, 22.3593522],
                [106.466637, 22.3593882],
                [106.4669275, 22.3595771],
                [106.4673873, 22.360186],
                [106.4680187, 22.3614063],
                [106.468378, 22.3622651],
                [106.4685444, 22.3624169],
                [106.4687926, 22.3624912],
                [106.4711835, 22.3624695],
                [106.4717618, 22.3625792],
                [106.4732954, 22.3633699],
                [106.4738766, 22.3637476],
                [106.4749084, 22.3638533],
                [106.4757716, 22.3636155],
                [106.476225, 22.3636114],
                [106.4768881, 22.3639501],
                [106.47801, 22.3647827],
                [106.4819341, 22.367448],
                [106.4831756, 22.3678964],
                [106.4852397, 22.3681456],
                [106.4866012, 22.368248],
                [106.4869734, 22.3683596],
                [106.4874747, 22.368968],
                [106.4877592, 22.3705171],
                [106.4877697, 22.3715131],
                [106.4888668, 22.3738785],
                [106.4889955, 22.374337],
                [106.4893305, 22.374832],
                [106.4890304, 22.3756967],
                [106.4888684, 22.3759665],
                [106.4884177, 22.3762389],
                [106.4872259, 22.3765947],
                [106.4855757, 22.3764948],
                [106.4842152, 22.3765073],
                [106.4825258, 22.3765994],
                [106.4810878, 22.3770724],
                [106.4804322, 22.3774615],
                [106.4793141, 22.3789467],
                [106.4791936, 22.3792544],
                [106.4791953, 22.3794076],
                [106.4794851, 22.3795198],
                [106.4797328, 22.3795559],
                [106.4810109, 22.3795442],
                [106.482126, 22.3797256],
                [106.4860605, 22.3813753],
                [106.4890851, 22.382765],
                [106.4896683, 22.3833344],
                [106.4903603, 22.3844199],
                [106.4903439, 22.3867572],
                [106.4902271, 22.3874097],
                [106.4898627, 22.388026],
                [106.4890017, 22.3884936],
                [106.4861643, 22.3892094],
                [106.4844559, 22.3894741],
                [106.4837158, 22.3896724],
                [106.483595, 22.3899418],
                [106.4835999, 22.3904015],
                [106.4837708, 22.3909746],
                [106.4840659, 22.3915849],
                [106.4846501, 22.3922309],
                [106.4851894, 22.3925324],
                [106.4858094, 22.39268],
                [106.4893602, 22.3931071],
                [106.4909742, 22.3936669],
                [106.4922585, 22.3942297],
                [106.4933807, 22.3950623],
                [106.4939168, 22.3969921],
                [106.4939225, 22.3975284],
                [106.493763, 22.3980279],
                [106.4934779, 22.3983754],
                [106.4931076, 22.3984555],
                [106.4905926, 22.3984787],
                [106.4903485, 22.3987874],
                [106.4901485, 22.399364],
                [106.4900369, 22.4005144],
                [106.4900835, 22.401012],
                [106.4908131, 22.4017524],
                [106.491683, 22.4021275],
                [106.4928408, 22.4024233],
                [106.4938312, 22.4024908],
                [106.4946992, 22.4026744],
                [106.494951, 22.4030935],
                [106.4966589, 22.4046869],
                [106.4973206, 22.4048723],
                [106.4977743, 22.4048681],
                [106.498721, 22.404706],
                [106.5003489, 22.4042966],
                [106.5010712, 22.4042333],
                [106.5019332, 22.4042253],
                [106.5025585, 22.4043298],
                [106.5040241, 22.4051717],
                [106.5043526, 22.4053066],
                [106.5046202, 22.4053042],
                [106.504798, 22.4052473],
                [106.505858, 22.4043266],
                [106.5068325, 22.4037105],
                [106.507333, 22.4032642],
                [106.5080383, 22.4025125],
                [106.5086004, 22.4022589],
                [106.510264, 22.4021606],
                [106.5110674, 22.4022359],
                [106.5118429, 22.402477],
                [106.5120204, 22.4023926],
                [106.5121668, 22.402198],
                [106.5123388, 22.4016169],
                [106.5125725, 22.4012283],
                [106.5145242, 22.4002993],
                [106.5150264, 22.399991],
                [106.5156695, 22.3989915],
                [106.5159345, 22.3987682],
                [106.5178525, 22.3988247],
                [106.5182382, 22.3987658],
                [106.5188285, 22.3983736],
                [106.5190948, 22.3982606],
                [106.5196898, 22.3983102],
                [106.5202899, 22.3988293],
                [106.5207421, 22.399405],
                [106.5212297, 22.4005053],
                [106.5222315, 22.4024293],
                [106.5238723, 22.4043334],
                [106.5243804, 22.4045772],
                [106.5251541, 22.4046526],
                [106.5264319, 22.404613],
                [106.5270573, 22.4047176],
                [106.5276272, 22.4051817],
                [106.5283787, 22.405948],
                [106.5293156, 22.4073478],
                [106.5297975, 22.4079232],
                [106.5300972, 22.4081413],
                [106.530782, 22.4082453],
                [106.5315242, 22.4081554],
                [106.5320282, 22.4080401],
                [106.5323109, 22.4080512],
                [106.5320882, 22.4094343],
                [106.5316217, 22.4102674],
                [106.5306514, 22.4112432],
                [106.5296538, 22.4124404],
                [106.5293286, 22.4126092],
                [106.5284692, 22.4128658],
                [106.5283225, 22.4130329],
                [106.528547, 22.4131828],
                [106.5306079, 22.414047],
                [106.5312942, 22.4142891],
                [106.5328125, 22.4144957],
                [106.5332615, 22.4147677],
                [106.5339221, 22.415369],
                [106.5342559, 22.4159735],
                [106.5346234, 22.4169642],
                [106.5348434, 22.4180393],
                [106.5350749, 22.4188243],
                [106.535256, 22.4190711],
                [106.5354657, 22.4192072],
                [106.5358224, 22.4192039],
                [106.537126, 22.4188048],
                [106.5374236, 22.4188296],
                [106.5379643, 22.4193216],
                [106.5385909, 22.4195366],
                [106.5396033, 22.4196926],
                [106.5437077, 22.4198469],
                [106.5449136, 22.4200148],
                [106.5465498, 22.4201096],
                [106.5475626, 22.4202933],
                [106.5485481, 22.4206981],
                [106.550098, 22.4210423],
                [106.5509303, 22.4210343],
                [106.5517608, 22.4208606],
                [106.5528545, 22.4202977],
                [106.5539657, 22.4199694],
                [106.5548876, 22.4199882],
                [106.5554538, 22.4201208],
                [106.5557542, 22.4203941],
                [106.5564477, 22.4212713],
                [106.5567183, 22.4215449],
                [106.5569875, 22.4216804],
                [106.5574043, 22.4217316],
                [106.5588599, 22.4216347],
                [106.5604949, 22.4221368],
                [106.5605485, 22.4224344],
                [106.5608597, 22.4229402],
                [106.561125, 22.423372],
                [106.562133, 22.423727],
                [106.5636384, 22.4236741],
                [106.5640139, 22.4238427],
                [106.5641749, 22.4246757],
                [106.5646952, 22.4256328],
                [106.5650654, 22.4271105],
                [106.5674472, 22.4298278],
                [106.5676188, 22.4311022],
                [106.567297, 22.4313501],
                [106.5655482, 22.4321038],
                [106.5652692, 22.4326492],
                [106.5659559, 22.4354954],
                [106.5659773, 22.4360606],
                [106.5653121, 22.4365168],
                [106.5645289, 22.4371217],
                [106.5645718, 22.437449],
                [106.5666103, 22.4395909],
                [106.5673399, 22.4406619],
                [106.5663957, 22.4422287],
                [106.5660524, 22.4431211],
                [106.5651297, 22.4436765],
                [106.5648079, 22.4456398],
                [106.5627265, 22.4475239],
                [106.5588856, 22.4469884],
                [106.5584349, 22.4500425],
                [106.559143, 22.451748],
                [106.5602588, 22.4529379],
                [106.5606665, 22.4539492],
                [106.5603447, 22.4552581],
                [106.559937, 22.4566462],
                [106.5587997, 22.4567454],
                [106.557809, 22.4574],
                [106.557473, 22.458198],
                [106.557473, 22.45882],
                [106.557857, 22.459263],
                [106.5587139, 22.4599777],
                [106.5594435, 22.4609295],
                [106.5597868, 22.4618615],
                [106.5593362, 22.4621788],
                [106.5588426, 22.4632298],
                [106.5590143, 22.465768],
                [106.5605807, 22.467275],
                [106.5617609, 22.4668982],
                [106.5630913, 22.4658671],
                [106.5650224, 22.4672551],
                [106.5668249, 22.4709433],
                [106.5676403, 22.4731442],
                [106.5688205, 22.4734416],
                [106.5695929, 22.4734615],
                [106.5702581, 22.4746313],
                [106.5718889, 22.4763365],
                [106.5727258, 22.4763762],
                [106.5747428, 22.4759003],
                [106.5735948, 22.4737192],
                [106.5733158, 22.4726981],
                [106.573472, 22.471818],
                [106.574145, 22.47102],
                [106.574865, 22.470443],
                [106.575634, 22.469689],
                [106.576451, 22.469245],
                [106.577123, 22.469112],
                [106.5783625, 22.4693212],
                [106.5783048, 22.469733],
                [106.5782654, 22.4707347],
                [106.5791475, 22.4728988],
                [106.5798187, 22.4735648],
                [106.5807674, 22.4737724],
                [106.5821617, 22.4734408],
                [106.5832808, 22.4742015],
                [106.5842111, 22.4750769],
                [106.5848363, 22.4766441],
                [106.5840754, 22.4788942],
                [106.5839655, 22.4800621],
                [106.5840492, 22.4807845],
                [106.5840515, 22.4811935],
                [106.5830586, 22.4816267],
                [106.5824054, 22.4825069],
                [106.5823506, 22.4840256],
                [106.5829302, 22.4861421],
                [106.5829302, 22.4880101],
                [106.5821025, 22.4915788],
                [106.5822111, 22.4922557],
                [106.5834269, 22.4937365],
                [106.5834548, 22.4954346],
                [106.5816055, 22.4992086],
                [106.5816605, 22.5003257],
                [106.5815225, 22.5023904],
                [106.5817713, 22.5043312],
                [106.5813292, 22.5058629],
                [106.5813292, 22.5066972],
                [106.5824883, 22.510496],
                [106.5825989, 22.5121827],
                [106.5825989, 22.5140718],
                [106.5824332, 22.5158067],
                [106.5823231, 22.517256],
                [106.5826813, 22.5187321],
                [106.5828742, 22.5199795],
                [106.5835335, 22.5215528],
                [106.5851035, 22.5231303],
                [106.5862364, 22.5243554],
                [106.5867022, 22.525794],
                [106.5871703, 22.5265733],
                [106.5872923, 22.527501],
                [106.5878964, 22.5283126],
                [106.5881728, 22.5292062],
                [106.5891826, 22.5310048],
                [106.5892861, 22.5323221],
                [106.5894406, 22.5336997],
                [106.590434, 22.5343763],
                [106.5911567, 22.5362505],
                [106.5907423, 22.5395417],
                [106.5910733, 22.541274],
                [106.5910457, 22.5442805],
                [106.5912659, 22.5449159],
                [106.5913473, 22.5454677],
                [106.5918445, 22.5457993],
                [106.591902, 22.5462506],
                [106.5911532, 22.5482228],
                [106.5904679, 22.5491595],
                [106.5905459, 22.5494953],
                [106.5910922, 22.5498737],
                [106.5916147, 22.5504833],
                [106.5923748, 22.5508092],
                [106.5934172, 22.5507585],
                [106.5945561, 22.5507585],
                [106.5955202, 22.5511655],
                [106.597478, 22.5509109],
                [106.6000888, 22.5516291],
                [106.6009014, 22.5527418],
                [106.6006234, 22.5546671],
                [106.6001833, 22.5557085],
                [106.6002213, 22.5563442],
                [106.6006453, 22.5570379],
                [106.6015025, 22.557523],
                [106.6018385, 22.5584282],
                [106.601866, 22.5592395],
                [106.6026951, 22.5602604],
                [106.6026121, 22.5613583],
                [106.6032423, 22.5623704],
                [106.6039632, 22.5634201],
                [106.6041579, 22.5649106],
                [106.6037128, 22.5661694],
                [106.6031616, 22.5668566],
                [106.6025829, 22.5692487],
                [106.6021136, 22.5704468],
                [106.6019197, 22.5712398],
                [106.6015902, 22.5717215],
                [106.6015902, 22.5739312],
                [106.6015902, 22.575108],
                [106.6012346, 22.5760932],
                [106.6018578, 22.5768938],
                [106.603784, 22.5781134],
                [106.6055517, 22.578904],
                [106.6062409, 22.5794131],
                [106.608953, 22.5804097],
                [106.6097619, 22.5820836],
                [106.609346, 22.584669],
                [106.6084836, 22.5865183],
                [106.60624, 22.5872088],
                [106.6052779, 22.5878941],
                [106.6044878, 22.5884727],
                [106.6043785, 22.5901375],
                [106.6049549, 22.591734],
                [106.606252, 22.5933138],
                [106.6067485, 22.594587],
                [106.607101, 22.5951524],
                [106.6068971, 22.59541],
                [106.6061139, 22.5955388],
                [106.606189, 22.5964203],
                [106.6068327, 22.5983221],
                [106.6073584, 22.5992334],
                [106.6083991, 22.6003824],
                [106.6091072, 22.6020563],
                [106.6098905, 22.603235],
                [106.6111028, 22.6052456],
                [106.6122615, 22.6045424],
                [106.6135597, 22.6040372],
                [106.6150296, 22.6044433],
                [106.6176689, 22.6045127],
                [106.618495, 22.6049584],
                [106.6191709, 22.6051267],
                [106.6196644, 22.6049881],
                [106.620233, 22.6047008],
                [106.6206515, 22.6046513],
                [106.6209733, 22.6046612],
                [106.6213596, 22.604889],
                [106.62166, 22.6052654],
                [106.6217351, 22.605622],
                [106.6217673, 22.6070779],
                [106.6219818, 22.6072166],
                [106.6222715, 22.6072166],
                [106.6235053, 22.6063747],
                [106.6244709, 22.6061964],
                [106.6249108, 22.6054932],
                [106.625458, 22.6045127],
                [106.6260481, 22.604374],
                [106.6269064, 22.6048197],
                [106.6276681, 22.6051366],
                [106.6284084, 22.6052654],
                [106.6291273, 22.6053347],
                [106.6299319, 22.6052951],
                [106.6305327, 22.6050079],
                [106.6312194, 22.6047702],
                [106.6320777, 22.604166],
                [106.6325712, 22.6034529],
                [106.6327107, 22.6023732],
                [106.6330755, 22.6018978],
                [106.6336763, 22.6011351],
                [106.6338479, 22.6004814],
                [106.6335797, 22.5998178],
                [106.6329682, 22.5987579],
                [106.6323674, 22.5977674],
                [106.6322601, 22.5970245],
                [106.6322493, 22.596559],
                [106.6322708, 22.5960241],
                [106.6326034, 22.59541],
                [106.6333008, 22.5946473],
                [106.6339016, 22.5939638],
                [106.6340518, 22.5934487],
                [106.6341162, 22.5929931],
                [106.6339123, 22.5915072],
                [106.6340411, 22.5912992],
                [106.6349101, 22.5910417],
                [106.6356504, 22.590903],
                [106.6359919, 22.5907491],
                [106.6366879, 22.5905251],
                [106.6372789, 22.5902351],
                [106.6380215, 22.5895855],
                [106.6383541, 22.5888129],
                [106.638236, 22.5877233],
                [106.638515, 22.5864454],
                [106.6391587, 22.5857024],
                [106.6397488, 22.5854449],
                [106.6418838, 22.5850487],
                [106.6424847, 22.5850189],
                [106.6432571, 22.5848505],
                [106.6441476, 22.5845434],
                [106.6453814, 22.5836222],
                [106.6461325, 22.5824929],
                [106.6466045, 22.5814725],
                [106.6468942, 22.5798974],
                [106.646825, 22.578834],
                [106.647208, 22.577999],
                [106.647625, 22.577356],
                [106.648356, 22.576682],
                [106.6499519, 22.5765688],
                [106.6519475, 22.5766084],
                [106.653471, 22.5759546],
                [106.6553592, 22.5764103],
                [106.6574192, 22.5757763],
                [106.6610455, 22.5759744],
                [106.6632074, 22.5756227],
                [106.664865, 22.5770245],
                [106.6661739, 22.5775793],
                [106.6669249, 22.5774406],
                [106.6706157, 22.5790257],
                [106.6717529, 22.5794417],
                [106.6731691, 22.5792832],
                [106.6745424, 22.5788671],
                [106.6750145, 22.5782529],
                [106.6758299, 22.5797587],
                [106.6764307, 22.5810466],
                [106.6776752, 22.5818985],
                [106.6807651, 22.5813437],
                [106.682589, 22.5799767],
                [106.6843056, 22.5797191],
                [106.6862368, 22.5800955],
                [106.6873312, 22.5813437],
                [106.6878032, 22.5821164],
                [106.6886401, 22.5825919],
                [106.689434, 22.5827703],
                [106.6920519, 22.5826514],
                [106.6925669, 22.583008],
                [106.6933393, 22.5844939],
                [106.6935003, 22.5847317],
                [106.6940153, 22.5846623],
                [106.6959464, 22.5838104],
                [106.6974699, 22.5835825],
                [106.6984141, 22.5831566],
                [106.6992509, 22.5826216],
                [106.7009354, 22.5814329],
                [106.7033815, 22.580571],
                [106.7045188, 22.5798776],
                [106.70542, 22.5794417],
                [106.7071044, 22.5787384],
                [106.7085206, 22.5781836],
                [106.7087352, 22.5782232],
                [106.708893, 22.578449],
                [106.7093038, 22.5792436],
                [106.7111599, 22.5804522],
                [106.7138958, 22.5820768],
                [106.7152154, 22.5825325],
                [106.7161167, 22.5832061],
                [106.7176616, 22.5844147],
                [106.7188525, 22.5852963],
                [106.7200202, 22.5860175],
                [106.7204082, 22.5862572],
                [106.7210734, 22.5865841],
                [106.7224681, 22.5869308],
                [106.7231548, 22.5874162],
                [106.7234123, 22.5881195],
                [106.7231977, 22.5885752],
                [106.7217493, 22.5897242],
                [106.7217278, 22.5902195],
                [106.7222536, 22.5912695],
                [106.7226398, 22.5918242],
                [106.7231655, 22.5922898],
                [106.7236376, 22.5926563],
                [106.7245388, 22.59331],
                [106.72544, 22.5939737],
                [106.7260301, 22.5942015],
                [106.7270172, 22.594152],
                [106.7281115, 22.5939539],
                [106.7285192, 22.5941421],
                [106.7285085, 22.5944591],
                [106.7282402, 22.5949444],
                [106.7280149, 22.5954199],
                [106.728605, 22.5971731],
                [106.7275643, 22.5983419],
                [106.7276394, 22.5987381],
                [106.7282081, 22.5993225],
                [106.7295277, 22.6004022],
                [106.729753, 22.6010262],
                [106.7302573, 22.6012738],
                [106.7310941, 22.6014323],
                [106.7308044, 22.6020266],
                [106.7289698, 22.6032647],
                [106.7265558, 22.6050871],
                [106.7258048, 22.6053248],
                [106.7254937, 22.606345],
                [106.7249894, 22.6066719],
                [106.7233157, 22.606652],
                [106.7228651, 22.6068699],
                [106.7224896, 22.6073949],
                [106.722566, 22.608036],
                [106.7225861, 22.608415],
                [106.7225861, 22.6088904],
                [106.7224145, 22.6089499],
                [106.7219102, 22.6088409],
                [106.7211056, 22.608524],
                [106.7203331, 22.6077812],
                [106.719625, 22.6072067],
                [106.7190671, 22.6071473],
                [106.7184663, 22.6072859],
                [106.7178869, 22.6074741],
                [106.7173505, 22.6082962],
                [106.7169642, 22.6095243],
                [106.7169857, 22.6103563],
                [106.7158484, 22.6109406],
                [106.7156231, 22.6115349],
                [106.7153978, 22.6122678],
                [106.7148828, 22.6133176],
                [106.714443, 22.6142387],
                [106.7149043, 22.6147933],
                [106.715666, 22.6155658],
                [106.7164063, 22.6161897],
                [106.7182624, 22.6168632],
                [106.7198503, 22.6173386],
                [106.7210626, 22.6178932],
                [106.7225432, 22.6172197],
                [106.7233908, 22.6173881],
                [106.7238092, 22.6181012],
                [106.7234659, 22.6195075],
                [106.7234766, 22.6198838],
                [106.7241204, 22.6209237],
                [106.7242706, 22.622429],
                [106.724925, 22.6239145],
                [106.725558, 22.6251128],
                [106.7261267, 22.6270934],
                [106.7268777, 22.6280243],
                [106.7278862, 22.6289155],
                [106.7285406, 22.6296087],
                [106.729072, 22.630267],
                [106.728968, 22.631456],
                [106.7289162, 22.6326192],
                [106.7286158, 22.6332232],
                [106.7283368, 22.6336391],
                [106.7271674, 22.6346393],
                [106.7260837, 22.635887],
                [106.7251289, 22.636194],
                [106.7242169, 22.6363128],
                [106.7234981, 22.6365406],
                [106.7228758, 22.6367188],
                [106.7225969, 22.637006],
                [106.722184, 22.637656],
                [106.7222428, 22.6380754],
                [106.7237234, 22.6398083],
                [106.7235947, 22.6406104],
                [106.7235947, 22.6410757],
                [106.7244744, 22.6416105],
                [106.7255688, 22.6420164],
                [106.7253005, 22.6448286],
                [106.7255151, 22.6452444],
                [106.7263305, 22.6455019],
                [106.7279291, 22.6457593],
                [106.7297423, 22.6474723],
                [106.7317486, 22.6485813],
                [106.7322957, 22.6490466],
                [106.7326498, 22.6498883],
                [106.7322528, 22.6504823],
                [106.732285, 22.651215],
                [106.7314911, 22.6525022],
                [106.7307615, 22.6542844],
                [106.730783, 22.6551458],
                [106.7309975, 22.6557201],
                [106.7312765, 22.6560765],
                [106.7319846, 22.6562646],
                [106.7345703, 22.6561062],
                [106.7351282, 22.6561557],
                [106.7354125, 22.6562596],
                [106.735627, 22.6565171],
                [106.7356646, 22.6570072],
                [106.735729, 22.6581854],
                [106.7358255, 22.6589873],
                [106.7360616, 22.6600962],
                [106.7362332, 22.6607595],
                [106.7365873, 22.6613041],
                [106.7372095, 22.6619971],
                [106.7371881, 22.6628386],
                [106.736877, 22.6640465],
                [106.7371988, 22.6647296],
                [106.7388618, 22.6654721],
                [106.739997, 22.665895],
                [106.7409324, 22.6677195],
                [106.7409646, 22.6690362],
                [106.7414474, 22.6701648],
                [106.742059, 22.6706301],
                [106.7433572, 22.6707984],
                [106.7471552, 22.6706796],
                [106.7483246, 22.6710162],
                [106.7489791, 22.671432],
                [106.749258, 22.6721843],
                [106.7511248, 22.6737386],
                [106.7527449, 22.6737485],
                [106.7549014, 22.6726496],
                [106.7570257, 22.6743623],
                [106.7577875, 22.6755205],
                [106.7577875, 22.6766392],
                [106.758517, 22.6804207],
                [106.7588925, 22.6811631],
                [106.7603195, 22.6817868],
                [106.760534, 22.6822223],
                [106.759944, 22.6833805],
                [106.759944, 22.6840735],
                [106.7604697, 22.6855088],
                [106.7602892, 22.6862208],
                [106.7597616, 22.6864888],
                [106.7579806, 22.6874093],
                [106.7568004, 22.6886962],
                [106.7561352, 22.6894089],
                [106.7563283, 22.6905571],
                [106.7553413, 22.6905868],
                [106.7543113, 22.6904284],
                [106.7539358, 22.6908243],
                [106.7542148, 22.6916954],
                [106.7528522, 22.6926852],
                [106.7528307, 22.6932791],
                [106.7532921, 22.695912],
                [106.7543328, 22.697565],
                [106.7550838, 22.6976739],
                [106.7581737, 22.6976937],
                [106.7612636, 22.6985548],
                [106.761961, 22.6988121],
                [106.7625618, 22.6997425],
                [106.7635387, 22.701067],
                [106.7640174, 22.7008661],
                [106.765029, 22.7010666],
                [106.7652462, 22.7018682],
                [106.765477, 22.7020937],
                [106.7670385, 22.7027074],
                [106.7671064, 22.7032711],
                [106.7650697, 22.7048932],
                [106.7676766, 22.706177],
                [106.7696997, 22.7069786],
                [106.7705551, 22.7073168],
                [106.7764614, 22.7073294],
                [106.7777649, 22.7074797],
                [106.7791892, 22.708144],
                [106.7795894, 22.7089873],
                [106.7787153, 22.710398],
                [106.7779142, 22.7110618],
                [106.776733, 22.7116756],
                [106.7753752, 22.7121014],
                [106.772766, 22.713428],
                [106.7719265, 22.7139927],
                [106.7707859, 22.7150635],
                [106.7699509, 22.7152702],
                [106.7695504, 22.7155958],
                [106.769306, 22.7163285],
                [106.7692584, 22.7173743],
                [106.7692177, 22.71775],
                [106.7685049, 22.7179942],
                [106.7665157, 22.7177876],
                [106.7654567, 22.7179441],
                [106.7648049, 22.7182698],
                [106.7648457, 22.7185453],
                [106.7654906, 22.7190024],
                [106.7658097, 22.7194283],
                [106.7658165, 22.7201985],
                [106.7644859, 22.7218266],
                [106.7645605, 22.7219957],
                [106.7654295, 22.7226657],
                [106.7658776, 22.7236551],
                [106.7654703, 22.7252268],
                [106.7645321, 22.7279688],
                [106.7622863, 22.7298981],
                [106.7611729, 22.7303865],
                [106.761926, 22.7330665],
                [106.7643501, 22.7328035],
                [106.7644315, 22.7335799],
                [106.7656719, 22.7345513],
                [106.7668045, 22.7356837],
                [106.7688172, 22.7362973],
                [106.7696861, 22.7382508],
                [106.7647438, 22.7415943],
                [106.7660473, 22.7431971],
                [106.7682469, 22.7449627],
                [106.7699034, 22.7450128],
                [106.7715327, 22.7452382],
                [106.7739088, 22.7445996],
                [106.7751444, 22.7447874],
                [106.7757961, 22.745426],
                [106.7770724, 22.7469036],
                [106.7774798, 22.7482309],
                [106.7764886, 22.7498211],
                [106.776733, 22.7500465],
                [106.7797337, 22.7521],
                [106.7824897, 22.7521058],
                [106.7832613, 22.7539172],
                [106.7839979, 22.7545318],
                [106.785401, 22.7542245],
                [106.7870495, 22.75437],
                [106.7883122, 22.7543539],
                [106.7879615, 22.7568768],
                [106.7885753, 22.7578148],
                [106.7881018, 22.7594159],
                [106.7889787, 22.7598526],
                [106.7903817, 22.7604994],
                [106.7881895, 22.7637824],
                [106.7868566, 22.7648497],
                [106.7845942, 22.7654966],
                [106.7846468, 22.7657877],
                [106.7847871, 22.7663375],
                [106.7856289, 22.7672108],
                [106.7862252, 22.767421],
                [106.7868741, 22.7671622],
                [106.7876458, 22.7669358],
                [106.790294, 22.7662405],
                [106.792574, 22.765157],
                [106.7935736, 22.7652863],
                [106.7943453, 22.7660788],
                [106.7942751, 22.7677282],
                [106.7929949, 22.7692483],
                [106.7928721, 22.7697982],
                [106.7938191, 22.7699922],
                [106.7954852, 22.7702186],
                [106.7965024, 22.7710433],
                [106.7974846, 22.7711727],
                [106.7985368, 22.7706552],
                [106.7996593, 22.7695394],
                [106.8015709, 22.7689573],
                [106.8029038, 22.7703156],
                [106.8066218, 22.7696364],
                [106.808551, 22.7697982],
                [106.8100768, 22.7704288],
                [106.8108484, 22.7719651],
                [106.8123216, 22.7717387],
                [106.813181, 22.7711242],
                [106.8139351, 22.7714638],
                [106.8167412, 22.7718842],
                [106.8180741, 22.7720783],
                [106.818464, 22.773439],
                [106.8198629, 22.7743745],
                [106.8220201, 22.7768001],
                [106.8241246, 22.7794035],
                [106.822775, 22.780654],
                [106.8225638, 22.7818936],
                [106.8218096, 22.7829285],
                [106.8218622, 22.7836723],
                [106.8226427, 22.7849416],
                [106.8235108, 22.7860168],
                [106.8241948, 22.7871648],
                [106.8243439, 22.7878116],
                [106.8242737, 22.7883128],
                [106.8238265, 22.7889192],
                [106.8242737, 22.7896548],
                [106.8251155, 22.7901076],
                [106.8264133, 22.7904229],
                [106.827733, 22.791054],
                [106.8287108, 22.7915789],
                [106.829134, 22.792796],
                [106.8302103, 22.7935515],
                [106.8308592, 22.7946266],
                [106.8312099, 22.7952895],
                [106.8326042, 22.7962111],
                [106.8328673, 22.7968902],
                [106.832098, 22.798518],
                [106.831701, 22.7994528],
                [106.8317536, 22.8005522],
                [106.8328936, 22.8009078],
                [106.8344194, 22.801215],
                [106.8355944, 22.8021689],
                [106.8366292, 22.8024114],
                [106.8376464, 22.8028479],
                [106.8375324, 22.8033653],
                [106.8363661, 22.8045374],
                [106.8361732, 22.8058711],
                [106.8352875, 22.8062511],
                [106.8333496, 22.8064047],
                [106.83299, 22.806922],
                [106.8329988, 22.8078192],
                [106.8327562, 22.8082631],
                [106.8299659, 22.8083135],
                [106.8291296, 22.8097329],
                [106.8279416, 22.8110729],
                [106.8283871, 22.812766],
                [106.8278165, 22.813645],
                [106.8264956, 22.8137459],
                [106.8254639, 22.8132127],
                [106.823971, 22.8125427],
                [106.8232363, 22.812939],
                [106.8226032, 22.8138972],
                [106.8225954, 22.8157415],
                [106.822017, 22.8161234],
                [106.8223049, 22.8167956],
                [106.8225306, 22.8172255],
                [106.8225595, 22.8172796],
                [106.8220224, 22.8174809],
                [106.8213242, 22.8179887],
                [106.8208534, 22.8184176],
                [106.8199262, 22.8184793],
                [106.8195588, 22.8193152],
                [106.8186394, 22.8199495],
                [106.8175769, 22.8200015],
                [106.815608, 22.819766],
                [106.814153, 22.819915],
                [106.812967, 22.820313],
                [106.8122271, 22.8194104],
                [106.8116002, 22.8202634],
                [106.809033, 22.820861],
                [106.807309, 22.82111],
                [106.805423, 22.820712],
                [106.80386, 22.820214],
                [106.802674, 22.819617],
                [106.8018879, 22.8189454],
                [106.8010531, 22.8200358],
                [106.8005136, 22.8199043],
                [106.798687, 22.819069],
                [106.79804, 22.81892],
                [106.796854, 22.818124],
                [106.795938, 22.816581],
                [106.795076, 22.815984],
                [106.794214, 22.815686],
                [106.793352, 22.815487],
                [106.7918862, 22.81523],
                [106.7902605, 22.8152876],
                [106.7886973, 22.8151508],
                [106.787306, 22.81456],
                [106.7862118, 22.8143222],
                [106.7851644, 22.8140269],
                [106.7842812, 22.8143655],
                [106.7836403, 22.8145312],
                [106.7829759, 22.8143367],
                [106.781281, 22.815387],
                [106.7806858, 22.8159361],
                [106.7795212, 22.8163539],
                [106.778532, 22.81678],
                [106.776485, 22.816432],
                [106.7757383, 22.8165773],
                [106.7750739, 22.817132],
                [106.774221, 22.817278],
                [106.773581, 22.8170527],
                [106.7725181, 22.816426],
                [106.7714942, 22.8159216],
                [106.770934, 22.815337],
                [106.770395, 22.814491],
                [106.77018, 22.813944],
                [106.769695, 22.813546],
                [106.768617, 22.812451],
                [106.768186, 22.812153],
                [106.767809, 22.812003],
                [106.767108, 22.811605],
                [106.766623, 22.811157],
                [106.766407, 22.810809],
                [106.765976, 22.81061],
                [106.765437, 22.810759],
                [106.764845, 22.811058],
                [106.764575, 22.812202],
                [106.765222, 22.814243],
                [106.765437, 22.815089],
                [106.765545, 22.815835],
                [106.765815, 22.816382],
                [106.766407, 22.81693],
                [106.766839, 22.817875],
                [106.767162, 22.818721],
                [106.767539, 22.819368],
                [106.767593, 22.820363],
                [106.767431, 22.821358],
                [106.767485, 22.822055],
                [106.76797, 22.823598],
                [106.768563, 22.824792],
                [106.768617, 22.825389],
                [106.768832, 22.826484],
                [106.768779, 22.826782],
                [106.768563, 22.82718],
                [106.768024, 22.82738],
                [106.766946, 22.82718],
                [106.766138, 22.827479],
                [106.764952, 22.827827],
                [106.764144, 22.828126],
                [106.762527, 22.829071],
                [106.761665, 22.829469],
                [106.759995, 22.830116],
                [106.759294, 22.830465],
                [106.758432, 22.830763],
                [106.757462, 22.831161],
                [106.756869, 22.831311],
                [106.75563, 22.83151],
                [106.75466, 22.831758],
                [106.753905, 22.832057],
                [106.753259, 22.832754],
                [106.752935, 22.83345],
                [106.752827, 22.834097],
                [106.752181, 22.835341],
                [106.751804, 22.835739],
                [106.750564, 22.836337],
                [106.7485091, 22.8371302],
                [106.7472274, 22.8376839],
                [106.7465501, 22.8380509],
                [106.7445011, 22.8388413],
                [106.7431434, 22.8397997],
                [106.742858, 22.840167],
                [106.7427737, 22.8409766],
                [106.742912, 22.842158],
                [106.7431246, 22.8428645],
                [106.742562, 22.8437238],
                [106.7412224, 22.8444914],
                [106.738789, 22.8449321],
                [106.7380167, 22.8460267],
                [106.7375411, 22.8468885],
                [106.7372635, 22.8479713],
                [106.7361573, 22.8482881],
                [106.7330039, 22.8492542],
                [106.7313833, 22.8502919],
                [106.7295341, 22.8509195],
                [106.7282871, 22.8518835],
                [106.7273793, 22.8529138],
                [106.726492, 22.8535132],
                [106.726445, 22.8535533],
                [106.7257097, 22.8541151],
                [106.7254173, 22.8544473],
                [106.725132, 22.8545742],
                [106.7248339, 22.854785],
                [106.7244425, 22.8551028],
                [106.7233472, 22.8553776],
                [106.7224308, 22.8554876],
                [106.7210322, 22.85572],
                [106.7207165, 22.8561662],
                [106.7202811, 22.8569401],
                [106.7197304, 22.8573404],
                [106.7187505, 22.8590502],
                [106.7182517, 22.8593944],
                [106.7180103, 22.859607],
                [106.7175901, 22.859842],
                [106.7172646, 22.8601062],
                [106.7170072, 22.8604646],
                [106.716865, 22.8605783],
                [106.7163839, 22.8608279],
                [106.7161397, 22.8609729],
                [106.7159906, 22.8612209],
                [106.7156178, 22.8622614],
                [106.7155266, 22.862558],
                [106.7155239, 22.8627779],
                [106.7154546, 22.862953],
                [106.7149955, 22.8631165],
                [106.7146441, 22.8631041],
                [106.7137851, 22.8630473],
                [106.7135015, 22.8631041],
                [106.7133095, 22.8631887],
                [106.7130697, 22.8634106],
                [106.7129045, 22.8636437],
                [106.7122221, 22.8638209],
                [106.711736, 22.8639342],
                [106.7114121, 22.8639815],
                [106.710286, 22.8645051],
                [106.70939, 22.8651245],
                [106.7083973, 22.8662995],
                [106.7077563, 22.8670663],
                [106.7071411, 22.8674732],
                [106.7064071, 22.8683658],
                [106.7074416, 22.8684997],
                [106.7086774, 22.8687984],
                [106.7100463, 22.8694764],
                [106.7113338, 22.8703962],
                [106.7124911, 22.8711026],
                [106.7130264, 22.871076],
                [106.7136629, 22.8707027],
                [106.7141403, 22.8701962],
                [106.7144164, 22.8700911],
                [106.7149876, 22.8702038],
                [106.7164519, 22.8706488],
                [106.7176357, 22.8727873],
                [106.7169659, 22.8739928],
                [106.7170127, 22.8751267],
                [106.7178694, 22.8756577],
                [106.717885, 22.8767197],
                [106.7164519, 22.8788725],
                [106.7145982, 22.8789299],
                [106.7132274, 22.8784993],
                [106.7127445, 22.8783989],
                [106.7121526, 22.8784563],
                [106.7118566, 22.8790303],
                [106.71171, 22.879678],
                [106.7112803, 22.8805516],
                [106.7105014, 22.8811974],
                [106.709411, 22.882202],
                [106.7076508, 22.8856893],
                [106.7063891, 22.8888608],
                [106.7048781, 22.8885021],
                [106.703741, 22.8883155],
                [106.7028687, 22.888703],
                [106.7023858, 22.8880141],
                [106.7019185, 22.8879567],
                [106.6979152, 22.8890904],
                [106.6913728, 22.8916735],
                [106.6889739, 22.890095],
                [106.6869801, 22.8902959],
                [106.6869801, 22.8895497],
                [106.6836777, 22.8877415],
                [106.68293, 22.8883729],
                [106.6797212, 22.8866795],
                [106.6791137, 22.8868517],
                [106.6779142, 22.8873971],
                [106.6772911, 22.8875549],
                [106.6769017, 22.8879424],
                [106.6767615, 22.8886312],
                [106.6772444, 22.8892913],
                [106.6779454, 22.8900663],
                [106.6781323, 22.8906833],
                [106.6775871, 22.8917596],
                [106.6758736, 22.8922618],
                [106.6752973, 22.8922762],
                [106.6735838, 22.8908125],
                [106.6730075, 22.8910708],
                [106.6717924, 22.8919031],
                [106.6697129, 22.8926852],
                [106.6691366, 22.8926852],
                [106.6682876, 22.891573],
                [106.6677969, 22.890999],
                [106.6673063, 22.8903748],
                [106.6669324, 22.8899873],
                [106.6660056, 22.8885164],
                [106.6654526, 22.887641],
                [106.6654682, 22.8871961],
                [106.6664573, 22.8866652],
                [106.6668312, 22.8858543],
                [106.6670492, 22.884369],
                [106.6672362, 22.8833716],
                [106.6669168, 22.8810826],
                [106.6656551, 22.8804655],
                [106.6660134, 22.8794322],
                [106.6659822, 22.8774373],
                [106.6666987, 22.8767197],
                [106.6668234, 22.8759878],
                [106.6653747, 22.8753419],
                [106.6635989, 22.8749401],
                [106.6632562, 22.873835],
                [106.6629135, 22.8728877],
                [106.6620395, 22.8722707],
                [106.6610695, 22.8715247],
                [106.6604765, 22.8713257],
                [106.6598835, 22.8713757],
                [106.6586985, 22.8713257],
                [106.6582135, 22.8712257],
                [106.6577285, 22.8707287],
                [106.6574585, 22.8703797],
                [106.6575125, 22.8697827],
                [106.6569735, 22.8690867],
                [106.6566505, 22.8688378],
                [106.6558955, 22.8686878],
                [106.6552808, 22.868338],
                [106.6548725, 22.8672948],
                [106.6547105, 22.8665488],
                [106.6543335, 22.8658518],
                [106.6539025, 22.8655038],
                [106.6533635, 22.8653048],
                [106.6530299, 22.865532],
                [106.6525081, 22.8657329],
                [106.6516046, 22.8663286],
                [106.6507765, 22.8667478],
                [106.6500755, 22.8669468],
                [106.648606, 22.866996],
                [106.6479745, 22.8677928],
                [106.6475975, 22.8685388],
                [106.6466805, 22.8691857],
                [106.6461425, 22.8696337],
                [106.6452795, 22.8713257],
                [106.6450105, 22.8723207],
                [106.644942, 22.87296],
                [106.644727, 22.873557],
                [106.644403, 22.873955],
                [106.6440497, 22.8747679],
                [106.6425699, 22.874854],
                [106.6411368, 22.8758299],
                [106.6396258, 22.8754567],
                [106.6387068, 22.8751554],
                [106.63756, 22.875349],
                [106.6368998, 22.8763609],
                [106.6366039, 22.8770929],
                [106.6376631, 22.8786285],
                [106.6404358, 22.8801928],
                [106.640467, 22.8815993],
                [106.6389404, 22.8837806],
                [106.6381927, 22.8861916],
                [106.6362923, 22.8869665],
                [106.63461, 22.8877415],
                [106.6338623, 22.8872249],
                [106.6330211, 22.8857324],
                [106.6314946, 22.8867656],
                [106.6293605, 22.8867226],
                [106.6295007, 22.8885451],
                [106.6295319, 22.8899228],
                [106.629859, 22.8905542],
                [106.6303263, 22.8909416],
                [106.6304587, 22.8913219],
                [106.6301238, 22.8917596],
                [106.6292203, 22.8923336],
                [106.6238774, 22.8952754],
                [106.6193445, 22.8974278],
                [106.617522, 22.8992502],
                [106.6164471, 22.9006564],
                [106.6151854, 22.9019909],
                [106.6141262, 22.9034258],
                [106.6138302, 22.9040428],
                [106.613176, 22.9050042],
                [106.6129423, 22.9065826],
                [106.61288, 22.9078452],
                [106.6122102, 22.908534],
                [106.6112444, 22.9086201],
                [106.6107148, 22.9087062],
                [106.6101073, 22.9089788],
                [106.6098736, 22.9094523],
                [106.609453, 22.9119058],
                [106.6092194, 22.9127093],
                [106.6094219, 22.9131398],
                [106.610964, 22.9140724],
                [106.6114936, 22.9149332],
                [106.6113379, 22.9153637],
                [106.6094686, 22.9155932],
                [106.6089702, 22.9163393],
                [106.6089079, 22.917028],
                [106.6088455, 22.9182332],
                [106.6088785, 22.9199374],
                [106.6083935, 22.9207333],
                [106.6078555, 22.9210813],
                [106.6071545, 22.9212803],
                [106.6061305, 22.9213803],
                [106.6055915, 22.9213303],
                [106.6052145, 22.9215293],
                [106.6048578, 22.92182],
                [106.6050136, 22.9226234],
                [106.605011, 22.923617],
                [106.605065, 22.924363],
                [106.6050193, 22.926304],
                [106.6048033, 22.926702],
                [106.6042643, 22.926801],
                [106.6027023, 22.926702],
                [106.6022173, 22.927],
                [106.6020553, 22.928493],
                [106.6016783, 22.92909],
                [106.6011393, 22.929239],
                [106.6002233, 22.92904],
                [106.5982833, 22.928393],
                [106.5973133, 22.928244],
                [106.5966123, 22.928244],
                [106.5960733, 22.928393],
                [106.5956423, 22.928742],
                [106.5951573, 22.929239],
                [106.5948106, 22.9298111],
                [106.593479, 22.931081],
                [106.592994, 22.931927],
                [106.5928003, 22.9328414],
                [106.5918843, 22.9328414],
                [106.5889743, 22.9320454],
                [106.5880043, 22.9319954],
                [106.5873573, 22.9317464],
                [106.5867103, 22.9311995],
                [106.5857403, 22.9305025],
                [106.5852013, 22.9300545],
                [106.5840703, 22.9287615],
                [106.5838543, 22.9282135],
                [106.5830463, 22.9267205],
                [106.5826693, 22.9262235],
                [106.5812683, 22.9253775],
                [106.5807293, 22.9248295],
                [106.5803513, 22.9247305],
                [106.5799203, 22.9247305],
                [106.5795973, 22.9249295],
                [106.5791123, 22.9254765],
                [106.5784113, 22.9259245],
                [106.5776573, 22.9260245],
                [106.5770103, 22.9258255],
                [106.5762563, 22.9254275],
                [106.5756633, 22.9250285],
                [106.5741003, 22.9250285],
                [106.5721603, 22.9245315],
                [106.5717833, 22.9241335],
                [106.5711903, 22.9237845],
                [106.5703823, 22.9225905],
                [106.5696283, 22.9215955],
                [106.5682803, 22.9203515],
                [106.5679033, 22.9198535],
                [106.5673643, 22.9194315],
                [106.5670413, 22.9190835],
                [106.5666103, 22.9188845],
                [106.5660713, 22.9187855],
                [106.5656403, 22.9188345],
                [106.5652093, 22.9189845],
                [106.5648323, 22.9192325],
                [106.5641459, 22.9200355],
                [106.5638759, 22.9205825],
                [106.5634449, 22.9211805],
                [106.5630139, 22.9216275],
                [106.5619359, 22.9219765],
                [106.5614509, 22.9220255],
                [106.5609659, 22.9223745],
                [106.5606969, 22.9228225],
                [106.5608332, 22.922991],
                [106.5603482, 22.923489],
                [106.5599712, 22.923489],
                [106.5593782, 22.922693],
                [106.5589472, 22.922494],
                [106.5583012, 22.922444],
                [106.5576002, 22.922693],
                [106.5569532, 22.923041],
                [106.5561452, 22.923638],
                [106.5531272, 22.924534],
                [106.5524272, 22.924634],
                [106.5516722, 22.924285],
                [106.5511332, 22.924435],
                [106.5504872, 22.924882],
                [106.5500022, 22.92543],
                [106.5493012, 22.925927],
                [106.5484124, 22.926363],
                [106.5480743, 22.9267274],
                [106.5478082, 22.9272044],
                [106.5476859, 22.9281981],
                [106.5474126, 22.9291653],
                [106.5465638, 22.9289334],
                [106.5452835, 22.9287214],
                [106.5442045, 22.92894],
                [106.5438593, 22.929152],
                [106.5435068, 22.9299404],
                [106.5432407, 22.9303511],
                [106.5430536, 22.9308678],
                [106.5427228, 22.9320602],
                [106.5429673, 22.9337561],
                [106.5416007, 22.9344848],
                [106.5411403, 22.9352532],
                [106.5409749, 22.9357632],
                [106.5411187, 22.9370881],
                [106.5407807, 22.9379691],
                [106.5407447, 22.9387839],
                [106.5410468, 22.9396185],
                [106.5413417, 22.9401816],
                [106.5417373, 22.9408771],
                [106.5421761, 22.9416256],
                [106.5428522, 22.9426722],
                [106.5418308, 22.9427385],
                [106.540937, 22.942576],
                [106.5383063, 22.9420893],
                [106.535082, 22.9420843],
                [106.5347781, 22.9421622],
                [106.5341775, 22.9425431],
                [106.5334295, 22.9419966],
                [106.5331777, 22.9405128],
                [106.5319837, 22.9410825],
                [106.5299696, 22.9413739],
                [106.529387, 22.9415196],
                [106.52961, 22.9435665],
                [106.5293439, 22.9445203],
                [106.5284807, 22.944878],
                [106.5274449, 22.9450635],
                [106.5264019, 22.9457258],
                [106.5260351, 22.9466333],
                [106.5265961, 22.9478322],
                [106.5264091, 22.9482098],
                [106.5256754, 22.9485012],
                [106.5245318, 22.9479845],
                [106.5215035, 22.9487529],
                [106.520777, 22.9484416],
                [106.5195326, 22.9475474],
                [106.5191083, 22.946673],
                [106.5182883, 22.9467128],
                [106.5174179, 22.9468718],
                [106.516364, 22.948547],
                [106.515717, 22.948696],
                [106.514747, 22.948647],
                [106.513077, 22.948448],
                [106.512215, 22.948199],
                [106.511568, 22.94795],
                [106.511137, 22.947602],
                [106.510706, 22.947403],
                [106.510059, 22.946656],
                [106.510005, 22.946208],
                [106.510059, 22.945561],
                [106.510221, 22.944865],
                [106.510544, 22.944367],
                [106.510706, 22.94382],
                [106.510706, 22.942924],
                [106.510382, 22.942725],
                [106.509251, 22.942327],
                [106.508658, 22.94168],
                [106.508712, 22.941282],
                [106.508981, 22.941133],
                [106.509682, 22.940436],
                [106.509844, 22.940088],
                [106.509897, 22.939739],
                [106.509682, 22.939242],
                [106.509359, 22.938844],
                [106.508712, 22.938346],
                [106.508065, 22.938147],
                [106.507419, 22.938048],
                [106.505964, 22.937998],
                [106.505425, 22.937898],
                [106.505047, 22.9376],
                [106.5037, 22.936157],
                [106.503107, 22.935261],
                [106.502838, 22.934763],
                [106.502569, 22.934067],
                [106.502838, 22.933171],
                [106.502946, 22.932574],
                [106.503215, 22.931877],
                [106.503862, 22.931131],
                [106.504293, 22.930285],
                [106.504347, 22.929936],
                [106.504239, 22.929588],
                [106.504293, 22.92914],
                [106.504077, 22.928643],
                [106.503862, 22.928344],
                [106.503054, 22.928394],
                [106.502461, 22.928643],
                [106.501167, 22.929091],
                [106.500629, 22.929091],
                [106.500197, 22.928941],
                [106.499928, 22.928294],
                [106.499874, 22.927647],
                [106.499874, 22.926802],
                [106.500036, 22.925956],
                [106.500467, 22.924761],
                [106.50079, 22.924313],
                [106.501329, 22.923866],
                [106.502084, 22.922821],
                [106.502245, 22.922423],
                [106.502245, 22.922024],
                [106.502137, 22.921626],
                [106.500952, 22.920532],
                [106.500575, 22.920333],
                [106.499982, 22.919935],
                [106.499766, 22.919686],
                [106.499605, 22.919238],
                [106.499443, 22.918491],
                [106.498796, 22.917845],
                [106.49885, 22.916849],
                [106.499335, 22.916053],
                [106.499551, 22.914958],
                [106.499551, 22.91471],
                [106.49982, 22.913714],
                [106.499982, 22.912669],
                [106.500197, 22.912122],
                [106.500305, 22.911624],
                [106.500359, 22.911177],
                [106.500359, 22.910629],
                [106.500575, 22.909982],
                [106.500736, 22.909136],
                [106.500736, 22.90834],
                [106.500629, 22.907594],
                [106.500467, 22.907196],
                [106.500144, 22.906847],
                [106.499874, 22.906748],
                [106.498419, 22.905102],
                [106.496989, 22.904021],
                [106.495949, 22.904381],
                [106.495364, 22.905822],
                [106.494518, 22.907323],
                [106.489512, 22.905642],
                [106.489252, 22.905342],
                [106.489317, 22.904802],
                [106.489642, 22.903481],
                [106.489577, 22.90288],
                [106.487497, 22.90168],
                [106.486131, 22.90138],
                [106.485351, 22.90162],
                [106.484376, 22.90264],
                [106.483726, 22.903121],
                [106.48132, 22.903061],
                [106.480345, 22.903961],
                [106.479825, 22.906122],
                [106.478915, 22.907983],
                [106.477225, 22.908223],
                [106.476054, 22.908644],
                [106.475079, 22.909364],
                [106.474429, 22.909364],
                [106.473909, 22.909184],
                [106.473129, 22.907923],
                [106.471828, 22.906182],
                [106.471243, 22.905522],
                [106.471178, 22.904982],
                [106.471243, 22.904561],
                [106.471698, 22.903241],
                [106.472153, 22.9021],
                [106.471633, 22.900539],
                [106.470593, 22.900059],
                [106.469748, 22.899759],
                [106.466822, 22.900719],
                [106.465197, 22.900719],
                [106.464677, 22.900539],
                [106.464222, 22.900239],
                [106.464352, 22.898738],
                [106.464352, 22.898198],
                [106.464612, 22.897478],
                [106.464547, 22.896877],
                [106.463637, 22.895977],
                [106.463181, 22.894836],
                [106.462596, 22.894416],
                [106.461621, 22.894476],
                [106.460646, 22.895316],
                [106.459996, 22.895556],
                [106.459346, 22.895977],
                [106.45837, 22.898438],
                [106.45759, 22.899218],
                [106.45629, 22.899879],
                [106.45551, 22.90168],
                [106.45512, 22.90282],
                [106.454795, 22.903121],
                [106.454144, 22.903361],
                [106.452714, 22.90264],
                [106.451284, 22.90216],
                [106.450504, 22.90234],
                [106.448683, 22.903061],
                [106.447383, 22.9027],
                [106.447188, 22.900599],
                [106.446993, 22.899759],
                [106.446148, 22.898678],
                [106.445693, 22.898018],
                [106.445302, 22.897117],
                [106.444002, 22.896097],
                [106.444132, 22.895617],
                [106.444977, 22.894596],
                [106.445367, 22.893996],
                [106.445042, 22.893695],
                [106.444392, 22.893395],
                [106.443937, 22.892435],
                [106.443742, 22.891654],
                [106.442702, 22.890634],
                [106.441467, 22.889853],
                [106.440491, 22.889493],
                [106.439061, 22.889493],
                [106.437501, 22.889193],
                [106.435485, 22.889073],
                [106.433795, 22.888893],
                [106.432105, 22.888233],
                [106.431194, 22.887812],
                [106.430414, 22.887272],
                [106.430024, 22.886732],
                [106.429764, 22.886251],
                [106.428984, 22.885951],
                [106.428594, 22.885951],
                [106.427814, 22.886251],
                [106.427423, 22.887032],
                [106.427228, 22.887932],
                [106.427228, 22.888893],
                [106.426903, 22.889313],
                [106.426188, 22.889913],
                [106.425083, 22.890994],
                [106.424628, 22.892075],
                [106.424368, 22.893455],
                [106.423523, 22.894956],
                [106.423133, 22.895256],
                [106.422287, 22.895196],
                [106.421312, 22.894896],
                [106.419947, 22.894116],
                [106.419622, 22.893335],
                [106.418972, 22.892195],
                [106.418386, 22.891354],
                [106.416696, 22.890394],
                [106.415981, 22.889793],
                [106.415136, 22.889253],
                [106.4139, 22.888833],
                [106.413055, 22.888473],
                [106.411495, 22.887332],
                [106.40961, 22.886311],
                [106.408829, 22.886131],
                [106.408439, 22.885891],
                [106.408439, 22.885471],
                [106.408634, 22.88439],
                [106.409219, 22.88361],
                [106.41026, 22.88301],
                [106.4113, 22.88277],
                [106.413835, 22.880548],
                [106.414616, 22.880068],
                [106.414876, 22.879588],
                [106.414876, 22.878747],
                [106.415331, 22.876526],
                [106.4152785, 22.8757728],
                [106.414746, 22.875205],
                [106.413965, 22.874905],
                [106.412665, 22.874965],
                [106.410195, 22.875265],
                [106.409414, 22.875506],
                [106.408764, 22.875446],
                [106.408179, 22.875085],
                [106.407399, 22.873945],
                [106.407009, 22.873825],
                [106.406489, 22.873765],
                [106.405904, 22.873945],
                [106.405514, 22.874305],
                [106.404668, 22.876766],
                [106.404343, 22.878087],
                [106.403693, 22.879168],
                [106.402068, 22.880248],
                [106.401028, 22.880548],
                [106.400117, 22.880728],
                [106.398752, 22.880848],
                [106.397972, 22.880548],
                [106.397452, 22.879948],
                [106.396932, 22.879768],
                [106.395956, 22.879528],
                [106.394591, 22.879648],
                [106.393226, 22.880068],
                [106.392381, 22.880788],
                [106.3902075, 22.8839343],
                [106.3890132, 22.8831641],
                [106.388237, 22.882669],
                [106.3862814, 22.8835629],
                [106.3845872, 22.8829028],
                [106.3834279, 22.8831611],
                [106.3829824, 22.8832604],
                [106.3813553, 22.882724],
                [106.3796834, 22.8831366],
                [106.3781607, 22.8842093],
                [106.3765187, 22.8839893],
                [106.3732047, 22.8836317],
                [106.3711148, 22.8827515],
                [106.3700699, 22.8817338],
                [106.3683084, 22.8818163],
                [106.367155, 22.880308],
                [106.36605, 22.879468],
                [106.364555, 22.878867],
                [106.364034, 22.878207],
                [106.363579, 22.877367],
                [106.363579, 22.876526],
                [106.364294, 22.875145],
                [106.364425, 22.874485],
                [106.363904, 22.873705],
                [106.362474, 22.871964],
                [106.361889, 22.871123],
                [106.361759, 22.870703],
                [106.362474, 22.869622],
                [106.362929, 22.869142],
                [106.363189, 22.868302],
                [106.363449, 22.867761],
                [106.363449, 22.866861],
                [106.363189, 22.86608],
                [106.362084, 22.86518],
                [106.361824, 22.86452],
                [106.361759, 22.864159],
                [106.361369, 22.863559],
                [106.360524, 22.863259],
                [106.359873, 22.862899],
                [106.359288, 22.862358],
                [106.359028, 22.861758],
                [106.358573, 22.861338],
                [106.357988, 22.861218],
                [106.356623, 22.861098],
                [106.355648, 22.860137],
                [106.3552167, 22.8588467],
                [106.3542614, 22.85776],
                [106.351858, 22.8558892],
                [106.3486634, 22.8557517],
                [106.3454838, 22.8560818],
                [106.3443563, 22.8570578],
                [106.3445994, 22.8581365],
                [106.3438754, 22.8598365],
                [106.3433117, 22.8603045],
                [106.342385, 22.860197],
                [106.341604, 22.861218],
                [106.3412599, 22.8615504],
                [106.3407365, 22.8615737],
                [106.3402301, 22.8614876],
                [106.3404044, 22.8633385],
                [106.3401464, 22.8635622],
                [106.338322, 22.8638403],
                [106.3366177, 22.8644776],
                [106.3349571, 22.8659358],
                [106.3338467, 22.8673169],
                [106.3334398, 22.8679266],
                [106.3331126, 22.8684074],
                [106.3325288, 22.8693837],
                [106.331787, 22.869622],
                [106.3309255, 22.8695961],
                [106.3302127, 22.8708134],
                [106.328797, 22.872084],
                [106.3281415, 22.8741214],
                [106.3266093, 22.8744328],
                [106.3258299, 22.8744062],
                [106.325423, 22.8742676],
                [106.3247216, 22.8741249],
                [106.3243942, 22.8740119],
                [106.3240026, 22.8735222],
                [106.3238404, 22.8734566],
                [106.3237598, 22.8734239],
                [106.3238717, 22.8730718],
                [106.3232937, 22.8711435],
                [106.3226145, 22.8691835],
                [106.3219698, 22.8679216],
                [106.3210461, 22.8676629],
                [106.3204742, 22.8686259],
                [106.3198082, 22.8684989],
                [106.3195805, 22.8681627],
                [106.3188229, 22.8676789],
                [106.3185647, 22.8671656],
                [106.3182474, 22.8670317],
                [106.3177052, 22.8666017],
                [106.3171042, 22.8659956],
                [106.3163165, 22.8658945],
                [106.3158924, 22.8659126],
                [106.3152099, 22.8667204],
                [106.3147133, 22.8669251],
                [106.3137829, 22.8669376],
                [106.3135426, 22.8668587],
                [106.3131236, 22.8665326],
                [106.3128587, 22.8664448],
                [106.3124551, 22.8663719],
                [106.3119652, 22.8657624],
                [106.3118743, 22.8653655],
                [106.3119559, 22.8651445],
                [106.3118309, 22.8646853],
                [106.3113515, 22.8639774],
                [106.3108439, 22.863826],
                [106.3101498, 22.8636404],
                [106.308837, 22.863859],
                [106.307277, 22.86452],
                [106.306432, 22.86524],
                [106.306042, 22.86578],
                [106.305847, 22.866621],
                [106.305326, 22.867881],
                [106.304806, 22.868842],
                [106.304026, 22.869622],
                [106.302986, 22.870283],
                [106.302206, 22.870943],
                [106.29993, 22.872444],
                [106.29902, 22.872984],
                [106.298175, 22.873284],
                [106.297525, 22.873645],
                [106.296744, 22.873645],
                [106.296159, 22.873765],
                [106.295379, 22.873705],
                [106.294209, 22.873945],
                [106.293494, 22.874365],
                [106.292779, 22.874485],
                [106.291868, 22.874185],
                [106.291023, 22.874065],
                [106.290503, 22.873585],
                [106.290308, 22.873164],
                [106.290243, 22.872444],
                [106.290633, 22.871663],
                [106.290893, 22.870523],
                [106.290893, 22.869742],
                [106.290828, 22.869022],
                [106.290503, 22.868602],
                [106.289983, 22.868362],
                [106.289073, 22.868182],
                [106.287968, 22.867821],
                [106.287187, 22.867641],
                [106.286472, 22.867401],
                [106.285692, 22.867401],
                [106.284912, 22.867821],
                [106.284067, 22.868482],
                [106.283612, 22.868962],
                [106.282571, 22.868842],
                [106.281661, 22.869142],
                [106.280751, 22.869742],
                [106.279906, 22.870463],
                [106.279256, 22.871363],
                [106.278605, 22.871964],
                [106.27763, 22.872084],
                [106.27659, 22.871964],
                [106.275225, 22.871964],
                [106.27425, 22.872264],
                [106.273664, 22.872864],
                [106.273014, 22.873705],
                [106.272624, 22.874425],
                [106.270739, 22.874965],
                [106.269633, 22.874965],
                [106.268723, 22.874665],
                [106.267878, 22.874185],
                [106.266903, 22.873765],
                [106.266383, 22.874125],
                [106.265213, 22.874485],
                [106.264562, 22.874485],
                [106.264042, 22.874305],
                [106.263457, 22.874665],
                [106.261572, 22.875686],
                [106.259816, 22.876466],
                [106.258906, 22.876706],
                [106.258061, 22.877186],
                [106.257086, 22.878327],
                [106.256891, 22.878867],
                [106.257281, 22.879468],
                [106.257866, 22.879828],
                [106.258841, 22.880008],
                [106.2603032, 22.8807983],
                [106.261434, 22.8813406],
                [106.262677, 22.881029],
                [106.2631534, 22.8814833],
                [106.2634632, 22.8818971],
                [106.2636336, 22.8827819],
                [106.2637265, 22.8839664],
                [106.2626267, 22.8843517],
                [106.261572, 22.88439],
                [106.2586536, 22.8845872],
                [106.2568954, 22.8854791],
                [106.2563688, 22.8863211],
                [106.2557182, 22.8868206],
                [106.255002, 22.8882146],
                [106.2530494, 22.8880762],
                [106.2510752, 22.8890646],
                [106.2504744, 22.8911798],
                [106.249187, 22.8938286],
                [106.2520194, 22.8958449],
                [106.253972, 22.8958251],
                [106.2560534, 22.8969222],
                [106.2574375, 22.8965565],
                [106.2586391, 22.8964972],
                [106.2592077, 22.8975943],
                [106.2592935, 22.8995808],
                [106.2609136, 22.900579],
                [106.261702, 22.9012],
                [106.261897, 22.90186],
                [106.261247, 22.903481],
                [106.261247, 22.904381],
                [106.262027, 22.905102],
                [106.262742, 22.905642],
                [106.263457, 22.906062],
                [106.264107, 22.906542],
                [106.264172, 22.907983],
                [106.264107, 22.909424],
                [106.263977, 22.910204],
                [106.261572, 22.915127],
                [106.258971, 22.919149],
                [106.258191, 22.91999],
                [106.256891, 22.92077],
                [106.256045, 22.921431],
                [106.255525, 22.921611],
                [106.255005, 22.921671],
                [106.2539, 22.921311],
                [106.25247, 22.921431],
                [106.251494, 22.921611],
                [106.251104, 22.922511],
                [106.250844, 22.923592],
                [106.250259, 22.924252],
                [106.249934, 22.924432],
                [106.249219, 22.925033],
                [106.248244, 22.925213],
                [106.248114, 22.927134],
                [106.247724, 22.927615],
                [106.247529, 22.929836],
                [106.246359, 22.931337],
                [106.246229, 22.932057],
                [106.246814, 22.933198],
                [106.246891, 22.9352152],
                [106.2444019, 22.9348793],
                [106.2418484, 22.9352745],
                [106.2411618, 22.9381992],
                [106.2421059, 22.9404321],
                [106.2421059, 22.9412621],
                [106.2416982, 22.9416968],
                [106.2405181, 22.9419142],
                [106.2390589, 22.9429022],
                [106.2368059, 22.9426453],
                [106.2335229, 22.9441273],
                [106.2366128, 22.9477631],
                [106.2354541, 22.9502726],
                [106.2363982, 22.9510234],
                [106.2380934, 22.9513791],
                [106.2375998, 22.9520114],
                [106.2358403, 22.9543231],
                [106.2344134, 22.9547776],
                [106.2332225, 22.9556865],
                [106.229639, 22.9576623],
                [106.2275791, 22.9576623],
                [106.2242532, 22.9584329],
                [106.2238669, 22.9587292],
                [106.2236953, 22.9598554],
                [106.223824, 22.9617818],
                [106.2242424, 22.962987],
                [106.2245214, 22.9639551],
                [106.224221, 22.9646071],
                [106.2238562, 22.9649528],
                [106.2222683, 22.9654566],
                [106.2215924, 22.9651109],
                [106.220895, 22.9640934],
                [106.2199724, 22.9638563],
                [106.2190819, 22.9640736],
                [106.2182236, 22.9651109],
                [106.2179661, 22.9657629],
                [106.2176227, 22.96681],
                [106.2174189, 22.9681238],
                [106.2176335, 22.9689042],
                [106.2173867, 22.9695956],
                [106.2168825, 22.9702081],
                [106.2160241, 22.9706328],
                [106.215198, 22.9707909],
                [106.2138247, 22.9706921],
                [106.2127089, 22.9706921],
                [106.2120759, 22.9708995],
                [106.2116575, 22.9712749],
                [106.2114108, 22.9718676],
                [106.2113249, 22.97248],
                [106.2109923, 22.9733987],
                [106.2090182, 22.9748705],
                [106.2082457, 22.9758681],
                [106.2078166, 22.9767769],
                [106.2072372, 22.9774486],
                [106.2063038, 22.9782388],
                [106.2061858, 22.9787129],
                [106.206245, 22.979483],
                [106.2063253, 22.980392],
                [106.2047267, 22.9822786],
                [106.202023, 22.98378],
                [106.2019372, 22.9849059],
                [106.2006068, 22.9863282],
                [106.1999202, 22.9866048],
                [106.1978388, 22.984738],
                [106.1964118, 22.9848565],
                [106.1943197, 22.9846788],
                [106.1922383, 22.9850936],
                [106.190629, 22.9863282],
                [106.189599, 22.9882048],
                [106.1882043, 22.9886592],
                [106.187067, 22.988995],
                [106.1847925, 22.9892518],
                [106.1835694, 22.9861504],
                [106.1830759, 22.9857949],
                [106.1811983, 22.9853405],
                [106.1806941, 22.9840861],
                [106.1794603, 22.9836614],
                [106.1764026, 22.9849059],
                [106.1753297, 22.9842639],
                [106.173282, 22.983265],
                [106.172272, 22.983533],
                [106.1708772, 22.9843429],
                [106.169381, 22.984225],
                [106.168926, 22.984285],
                [106.1676586, 22.9847479],
                [106.1668002, 22.9838293],
                [106.1656523, 22.9839083],
                [106.1651373, 22.9842936],
                [106.1650729, 22.9853899],
                [106.1643541, 22.985864],
                [106.1636567, 22.9863677],
                [106.162498, 22.9874838],
                [106.1609316, 22.9879085],
                [106.159824, 22.988188],
                [106.158849, 22.988127],
                [106.1575949, 22.9879678],
                [106.1565971, 22.988274],
                [106.1559641, 22.9892814],
                [106.1555242, 22.9904962],
                [106.1550951, 22.9912468],
                [106.1521447, 22.9926296],
                [106.1488724, 22.9933999],
                [106.147778, 22.9935086],
                [106.1469626, 22.9938147],
                [106.1463511, 22.9957505],
                [106.1447525, 22.996027],
                [106.1433685, 22.9959381],
                [106.1416733, 22.9959776],
                [106.1403108, 22.9951777],
                [106.1395919, 22.995474],
                [106.1382723, 22.996106],
                [106.1370814, 22.9962739],
                [106.1360621, 22.9965209],
                [106.1349249, 22.9970147],
                [106.1345601, 22.9965999],
                [106.1341953, 22.9956517],
                [106.133573, 22.9948814],
                [106.1322427, 22.9943876],
                [106.130805, 22.993795],
                [106.1306977, 22.9933407],
                [106.1318135, 22.9920962],
                [106.131792, 22.9909506],
                [106.1303329, 22.9898049],
                [106.1293459, 22.9901012],
                [106.1279726, 22.9899629],
                [106.1250972, 22.9881456],
                [106.1220932, 22.9882246],
                [106.1198616, 22.9880271],
                [106.1175441, 22.9847084],
                [106.1122656, 22.9886394],
                [106.1099052, 22.990753],
                [106.1087036, 22.9907925],
                [106.1067939, 22.9892123],
                [106.1051416, 22.9884419],
                [106.104691, 22.9875135],
                [106.1017298, 22.9869011],
                [106.1009145, 22.9867233],
                [106.1000991, 22.9869801],
                [106.0996055, 22.988995],
                [106.0988974, 22.990358],
                [106.0976743, 22.9928271],
                [106.096859, 22.9929061],
                [106.0960865, 22.9949209],
                [106.0961938, 22.995869],
                [106.0961079, 22.9960863],
                [106.094327, 22.9957308],
                [106.0931897, 22.9966196],
                [106.0922456, 22.995474],
                [106.091752, 22.9934789],
                [106.0903144, 22.9931827],
                [106.0897779, 22.9931333],
                [106.0894883, 22.9931431],
                [106.089219, 22.99335],
                [106.089219, 22.994251],
                [106.0894775, 22.9952369],
                [106.0890698, 22.9962048],
                [106.0881472, 22.9970739],
                [106.0866451, 22.9975381],
                [106.0861516, 22.9993652],
                [106.0861516, 23.0006885],
                [106.0851216, 23.0009453],
                [106.0829544, 23.0012021],
                [106.0812163, 22.9990689],
                [106.0794353, 22.9993059],
                [106.0779547, 22.9992269],
                [106.0764956, 22.9986541],
                [106.0749721, 22.9988121],
                [106.0721397, 22.9961258],
                [106.0712385, 22.9952271],
                [106.0703373, 22.9946443],
                [106.0696936, 22.9941802],
                [106.0675585, 22.9937357],
                [106.0661959, 22.9932222],
                [106.0655093, 22.9926098],
                [106.0643721, 22.9925308],
                [106.063385, 22.9916617],
                [106.0629129, 22.9915827],
                [106.0616469, 22.9920172],
                [106.0598016, 22.9921456],
                [106.0587823, 22.9920864],
                [106.0561108, 22.9907826],
                [106.05551, 22.9909012],
                [106.0544693, 22.9933802],
                [106.0527635, 22.9935283],
                [106.0510576, 22.9942295],
                [106.0492766, 22.9936962],
                [106.048826, 22.9930839],
                [106.0489869, 22.9916715],
                [106.0486436, 22.9909901],
                [106.0480213, 22.9908419],
                [106.0457253, 22.9919283],
                [106.044749, 22.9915431],
                [106.0434937, 22.9899036],
                [106.0430217, 22.9896863],
                [106.042496, 22.9896962],
                [106.0415196, 22.9899135],
                [106.0408008, 22.990358],
                [106.0402, 22.9909308],
                [106.0397816, 22.9915234],
                [106.0395563, 22.9922147],
                [106.0390949, 22.9938839],
                [106.0388052, 22.9940024],
                [106.0372388, 22.9934987],
                [106.0355759, 22.9932123],
                [106.0345566, 22.9925604],
                [106.0341918, 22.992274],
                [106.0331833, 22.9916814],
                [106.0321855, 22.9917999],
                [106.0315311, 22.9915431],
                [106.0311663, 22.9915234],
                [106.0308015, 22.9916913],
                [106.0285699, 22.9938444],
                [106.0274219, 22.9933604],
                [106.026392, 22.992837],
                [106.0256624, 22.9924715],
                [106.0245681, 22.9919876],
                [106.0238653, 22.9919086],
                [106.0235703, 22.9919382],
                [106.0231841, 22.9921012],
                [106.0229856, 22.9923431],
                [106.0220575, 22.9924715],
                [106.0189676, 22.9911975],
                [106.0181951, 22.9910987],
                [106.0165, 22.9922938],
                [106.015352, 22.9922543],
                [106.0140103, 22.9927927],
                [106.0131653, 22.9930327],
                [106.0123843, 22.9929127],
                [106.0117993, 22.9926127],
                [106.0112793, 22.9924327],
                [106.0107593, 22.9921327],
                [106.0102393, 22.9920727],
                [106.0093943, 22.9920727],
                [106.0086143, 22.9921327],
                [106.0064683, 22.9924927],
                [106.0051683, 22.9925527],
                [106.0045183, 22.9927327],
                [106.0039983, 22.9927927],
                [106.0034773, 22.9927927],
                [106.0026383, 22.9927283],
                [106.0018873, 22.9926493],
                [106.0012007, 22.9920765],
                [106.000793, 22.9907135],
                [106.000734, 22.989688],
                [106.001059, 22.989268],
                [106.001384, 22.988488],
                [106.001514, 22.987767],
                [106.001774, 22.987047],
                [106.001839, 22.986206],
                [106.001839, 22.985366],
                [106.001644, 22.984345],
                [106.001709, 22.983385],
                [106.001449, 22.982484],
                [106.0009968, 22.9816465],
                [105.9996563, 22.980906],
                [106.0013576, 22.9795855],
                [106.0033464, 22.9784462],
                [106.0044599, 22.9755623],
                [106.0052604, 22.9740882],
                [106.0056607, 22.9722762],
                [106.00613, 22.970238],
                [106.00704, 22.968617],
                [106.007431, 22.967416],
                [106.007236, 22.966035],
                [106.00691, 22.964895],
                [106.0055607, 22.9616032],
                [106.0054106, 22.9602825],
                [106.005544, 22.9588235],
                [106.0052938, 22.9574874],
                [106.0049936, 22.9559209],
                [106.0051604, 22.9544772],
                [106.0048435, 22.9530182],
                [106.0026919, 22.9520199],
                [106.0024488, 22.9510826],
                [106.0017201, 22.9502204],
                [106.0018984, 22.9499419],
                [106.0029588, 22.9473816],
                [106.001908, 22.9459532],
                [105.9991165, 22.9445407],
                [105.9987466, 22.9416694],
                [105.9973213, 22.9409921],
                [105.9967209, 22.9413146],
                [105.9959504, 22.9420674],
                [105.9951614, 22.9426048],
                [105.9945318, 22.9424743],
                [105.9944943, 22.9421287],
                [105.9943442, 22.9408078],
                [105.994611, 22.9401089],
                [105.9940773, 22.9399092],
                [105.9925477, 22.9402562],
                [105.9904465, 22.9404653],
                [105.9888849, 22.9407234],
                [105.9878279, 22.9411197],
                [105.9871113, 22.9423227],
                [105.9858301, 22.9425678],
                [105.9843036, 22.9434804],
                [105.9823725, 22.945541],
                [105.9812807, 22.9466571],
                [105.9799056, 22.9471939],
                [105.977566, 22.9473098],
                [105.9761661, 22.9478468],
                [105.9730745, 22.9491137],
                [105.9687486, 22.9492746],
                [105.966717, 22.949923],
                [105.9643193, 22.9505711],
                [105.9619095, 22.9508227],
                [105.9598686, 22.9502812],
                [105.9583723, 22.9508146],
                [105.9566224, 22.9513988],
                [105.9563112, 22.9502923],
                [105.9558391, 22.949008],
                [105.9561288, 22.9461231],
                [105.9557211, 22.944562],
                [105.9556353, 22.9429318],
                [105.9550976, 22.940211],
                [105.9543586, 22.940027],
                [105.9525883, 22.9400962],
                [105.9500241, 22.9392959],
                [105.9467518, 22.9376063],
                [105.9446812, 22.9394145],
                [105.9426534, 22.9387624],
                [105.9386396, 22.9384119],
                [105.9378089, 22.9394001],
                [105.9362512, 22.9396232],
                [105.9350051, 22.9409939],
                [105.9328937, 22.9400695],
                [105.9318379, 22.9407229],
                [105.9297438, 22.9422689],
                [105.928965, 22.9429543],
                [105.9281169, 22.9439584],
                [105.9260228, 22.9449943],
                [105.924742, 22.9451856],
                [105.9236344, 22.9453768],
                [105.922821, 22.9457115],
                [105.9218345, 22.9463809],
                [105.9208306, 22.9456637],
                [105.9193076, 22.945345],
                [105.9183557, 22.943799],
                [105.9154481, 22.9432571],
                [105.9118656, 22.9411055],
                [105.9110348, 22.9411214],
                [105.909408, 22.9394001],
                [105.9068811, 22.9397507],
                [105.9050985, 22.9392885],
                [105.9030736, 22.9411214],
                [105.901197, 22.940581],
                [105.8996295, 22.940197],
                [105.8963411, 22.9382525],
                [105.8956488, 22.9381888],
                [105.8943335, 22.9393363],
                [105.8902445, 22.9392638],
                [105.8885519, 22.9374015],
                [105.887934, 22.935238],
                [105.887674, 22.933917],
                [105.887804, 22.932476],
                [105.887609, 22.931036],
                [105.887089, 22.930435],
                [105.886374, 22.929355],
                [105.8844492, 22.9274017],
                [105.8831513, 22.9273262],
                [105.8834993, 22.9254373],
                [105.8852646, 22.9242578],
                [105.8844685, 22.9225362],
                [105.8863376, 22.9214842],
                [105.8867876, 22.9196988],
                [105.8848838, 22.9178497],
                [105.8796917, 22.9185192],
                [105.8788956, 22.9168294],
                [105.8757111, 22.9167657],
                [105.8752265, 22.9184235],
                [105.873115, 22.9198263],
                [105.8700344, 22.9242259],
                [105.87025, 22.925452],
                [105.869987, 22.9268559],
                [105.8699012, 22.9283776],
                [105.8715749, 22.9294646],
                [105.8714032, 22.9308282],
                [105.8722615, 22.9333774],
                [105.8699441, 22.9347015],
                [105.8689999, 22.9367764],
                [105.8681845, 22.937468],
                [105.8674121, 22.9383968],
                [105.8669829, 22.9390094],
                [105.8662534, 22.9398986],
                [105.8649659, 22.9405112],
                [105.8615112, 22.941025],
                [105.8593654, 22.9415585],
                [105.8583355, 22.9421118],
                [105.857048, 22.9425465],
                [105.8563185, 22.9432381],
                [105.8540654, 22.9439692],
                [105.8534217, 22.9454117],
                [105.8536148, 22.9467356],
                [105.8531105, 22.947605],
                [105.8526814, 22.9483855],
                [105.8532071, 22.949087],
                [105.854777, 22.949586],
                [105.854322, 22.950367],
                [105.854192, 22.950787],
                [105.853932, 22.951147],
                [105.850681, 22.953789],
                [105.847625, 22.95547],
                [105.846975, 22.95619],
                [105.8462119, 22.9574647],
                [105.8456754, 22.9588873],
                [105.8468771, 22.9611792],
                [105.8511042, 22.9600135],
                [105.8508467, 22.9620485],
                [105.8502245, 22.9639452],
                [105.8506965, 22.9645577],
                [105.8537006, 22.9653085],
                [105.8551168, 22.9677188],
                [105.855031, 22.969813],
                [105.8514261, 22.9725788],
                [105.8498168, 22.9716898],
                [105.8484864, 22.971512],
                [105.8476281, 22.970623],
                [105.8459544, 22.9700698],
                [105.8452678, 22.9691808],
                [105.8429718, 22.9701093],
                [105.8419847, 22.969082],
                [105.8410406, 22.9689042],
                [105.8385944, 22.9708996],
                [105.8385086, 22.9716503],
                [105.8381009, 22.9729937],
                [105.8437013, 22.9753446],
                [105.843358, 22.9762534],
                [105.8430147, 22.9768658],
                [105.8427143, 22.9780116],
                [105.8411479, 22.9783079],
                [105.8377039, 22.9808958],
                [105.8385944, 22.9824959],
                [105.8380365, 22.9831676],
                [105.8364915, 22.9857751],
                [105.837264, 22.9879085],
                [105.8290028, 22.9936567],
                [105.8287453, 22.9952172],
                [105.8282089, 22.995474],
                [105.8264065, 22.9952764],
                [105.8250117, 22.9964023],
                [105.822308, 22.9963628],
                [105.8185959, 22.9959283],
                [105.8181238, 22.9952764],
                [105.8183384, 22.9943283],
                [105.8182525, 22.9926691],
                [105.8192181, 22.9915629],
                [105.8159566, 22.9900222],
                [105.815506, 22.9885209],
                [105.8135104, 22.9891925],
                [105.8118582, 22.9888765],
                [105.8104205, 22.9898049],
                [105.8063864, 22.9901604],
                [105.8059358, 22.9911876],
                [105.8033824, 22.9909308],
                [105.803318, 22.9914641],
                [105.8041549, 22.9929456],
                [105.8031893, 22.9939135],
                [105.8014083, 22.995395],
                [105.7978463, 22.997943],
                [105.7956362, 22.999859],
                [105.791291, 23.0039574],
                [105.7888126, 23.0086483],
                [105.7854867, 23.0143166],
                [105.784564, 23.0172198],
                [105.783298, 23.0187207],
                [105.7813239, 23.0215843],
                [105.7791996, 23.0234999],
                [105.7758522, 23.0241121],
                [105.7723975, 23.0259685],
                [105.7707238, 23.0257512],
                [105.7693934, 23.0267979],
                [105.7675695, 23.0268176],
                [105.7667756, 23.0274693],
                [105.7639646, 23.0273903],
                [105.7629561, 23.0286542],
                [105.7605743, 23.027884],
                [105.758686, 23.028595],
                [105.7579994, 23.0297798],
                [105.7590723, 23.0318928],
                [105.7558322, 23.0328999],
                [105.7510686, 23.0337095],
                [105.7476568, 23.0349141],
                [105.7422495, 23.031794],
                [105.7379579, 23.0340057],
                [105.7370138, 23.0363753],
                [105.7350826, 23.0368492],
                [105.7318639, 23.0362568],
                [105.7319068, 23.0384684],
                [105.7279157, 23.0428915],
                [105.7292461, 23.0436813],
                [105.7299971, 23.0442737],
                [105.7300615, 23.0465641],
                [105.730011, 23.049046],
                [105.730011, 23.049978],
                [105.729819, 23.051799],
                [105.729819, 23.052864],
                [105.729915, 23.054152],
                [105.725727, 23.0531194],
                [105.7240963, 23.0553702],
                [105.7238388, 23.0566733],
                [105.7223367, 23.0575815],
                [105.7244396, 23.0606614],
                [105.7219505, 23.0610563],
                [105.7221221, 23.0637413],
                [105.720827, 23.064365],
                [105.719865, 23.064631],
                [105.719288, 23.06472],
                [105.717797, 23.064675],
                [105.716403, 23.064764],
                [105.715393, 23.065208],
                [105.7149553, 23.0657945],
                [105.7128954, 23.0680451],
                [105.7111573, 23.0707102],
                [105.709784, 23.0705918],
                [105.7091832, 23.0695257],
                [105.7093334, 23.0680056],
                [105.7109213, 23.065064],
                [105.7119512, 23.0620829],
                [105.708901, 23.063565],
                [105.708276, 23.06361],
                [105.707266, 23.06361],
                [105.705728, 23.063832],
                [105.70467, 23.063921],
                [105.703564, 23.063921],
                [105.701256, 23.06361],
                [105.7002568, 23.0628726],
                [105.6994951, 23.0617571],
                [105.697024, 23.06281],
                [105.695774, 23.062722],
                [105.694331, 23.062722],
                [105.692071, 23.062855],
                [105.687455, 23.062899],
                [105.6840992, 23.0635044],
                [105.6805372, 23.0639782],
                [105.6787562, 23.0622408],
                [105.677645, 23.063299],
                [105.676635, 23.063299],
                [105.675962, 23.063565],
                [105.675145, 23.064231],
                [105.674375, 23.065075],
                [105.672211, 23.067162],
                [105.6619978, 23.0730594],
                [105.660382, 23.074621],
                [105.659661, 23.074932],
                [105.658747, 23.07511],
                [105.657256, 23.075199],
                [105.6571313, 23.0751965],
                [105.6533342, 23.0751209],
                [105.652784, 23.07511],
                [105.652304, 23.075154],
                [105.651919, 23.075243],
                [105.65163, 23.075509],
                [105.6514337, 23.0762096],
                [105.651294, 23.076708],
                [105.651149, 23.077952],
                [105.650765, 23.07884],
                [105.650284, 23.07955],
                [105.649563, 23.079994],
                [105.648409, 23.080438],
                [105.647543, 23.080838],
                [105.647062, 23.081193],
                [105.646389, 23.082037],
                [105.645668, 23.082658],
                [105.645091, 23.082925],
                [105.644514, 23.083013],
                [105.644033, 23.083147],
                [105.643408, 23.083147],
                [105.642879, 23.082969],
                [105.641725, 23.082392],
                [105.639657, 23.081681],
                [105.63783, 23.081637],
                [105.636964, 23.081903],
                [105.636098, 23.081948],
                [105.635233, 23.081903],
                [105.634608, 23.08177],
                [105.633935, 23.081371],
                [105.633261, 23.081193],
                [105.631482, 23.080527],
                [105.630568, 23.080127],
                [105.63004, 23.079683],
                [105.629655, 23.079106],
                [105.629414, 23.078174],
                [105.6298113, 23.0765338],
                [105.6293821, 23.0749546],
                [105.6268072, 23.0715591],
                [105.6239748, 23.0700982],
                [105.6200695, 23.068203],
                [105.6187391, 23.065755],
                [105.6170225, 23.0674923],
                [105.6099414, 23.0720329],
                [105.6063366, 23.0763364],
                [105.6055212, 23.0767016],
                [105.6041586, 23.0764252],
                [105.6021416, 23.0769582],
                [105.6006717, 23.0764154],
                [105.5943632, 23.0760996],
                [105.5909729, 23.0724672],
                [105.5902433, 23.0701772],
                [105.5849218, 23.0693481],
                [105.5826902, 23.0674528],
                [105.5771725, 23.0690822],
                [105.5720938, 23.0723119],
                [105.5704831, 23.0741215],
                [105.5693051, 23.0753275],
                [105.5686936, 23.0771434],
                [105.568153, 23.07896],
                [105.5671549, 23.0800871],
                [105.5656957, 23.0808175],
                [105.5651378, 23.082831],
                [105.5660176, 23.0841535],
                [105.5636573, 23.0851997],
                [105.5600953, 23.0876671],
                [105.5621767, 23.0901937],
                [105.5633354, 23.0933124],
                [105.5626487, 23.0974573],
                [105.5648803, 23.0986021],
                [105.5662965, 23.101168],
                [105.566039, 23.1027074],
                [105.5638933, 23.102589],
                [105.5646658, 23.1063784],
                [105.5634641, 23.1080757],
                [105.5653739, 23.1114703],
                [105.5653524, 23.1129504],
                [105.5645799, 23.1141148],
                [105.5633783, 23.1131478],
                [105.5614257, 23.115141],
                [105.561018, 23.1156344],
                [105.562756, 23.1161673],
                [105.5644512, 23.1177263],
                [105.5644972, 23.1187947],
                [105.5608765, 23.1187448],
                [105.5589164, 23.1182484],
                [105.5550704, 23.1152043],
                [105.5541982, 23.1122548],
                [105.5508071, 23.1106499],
                [105.5462083, 23.1092917],
                [105.5404956, 23.109136],
                [105.5379826, 23.1101215],
                [105.5351234, 23.1101329],
                [105.5314649, 23.1106345],
                [105.5307212, 23.1100901],
                [105.5297304, 23.1097941],
                [105.5289342, 23.109112],
                [105.5285252, 23.1080396],
                [105.5283107, 23.1069545],
                [105.5284329, 23.1055325],
                [105.5282456, 23.1049306],
                [105.5276672, 23.1040358],
                [105.5271098, 23.1036029],
                [105.5263776, 23.1028605],
                [105.5261085, 23.1022971],
                [105.5255543, 23.1018512],
                [105.5251601, 23.1013549],
                [105.524934, 23.1003418],
                [105.5248702, 23.099252],
                [105.5249599, 23.0983329],
                [105.5238186, 23.0955408],
                [105.5228003, 23.0937236],
                [105.5218959, 23.0922127],
                [105.5214474, 23.0911934],
                [105.5211759, 23.089928],
                [105.5206611, 23.08854],
                [105.5202516, 23.0877598],
                [105.519136, 23.0861965],
                [105.5182606, 23.0850515],
                [105.5178278, 23.084227],
                [105.517768, 23.0835327],
                [105.5178686, 23.0830837],
                [105.5181798, 23.0826721],
                [105.5185048, 23.0824057],
                [105.5191316, 23.0819724],
                [105.5196994, 23.0810752],
                [105.5201826, 23.0799621],
                [105.5205332, 23.0787012],
                [105.5206505, 23.0774409],
                [105.5206694, 23.0763908],
                [105.5205186, 23.0755987],
                [105.520185, 23.0749452],
                [105.5197531, 23.0743753],
                [105.5185568, 23.0736375],
                [105.5161408, 23.0715585],
                [105.5144912, 23.0703905],
                [105.5136824, 23.0696269],
                [105.5134391, 23.0689254],
                [105.5133842, 23.0676947],
                [105.5132573, 23.0667065],
                [105.5130439, 23.0661635],
                [105.5122489, 23.0653805],
                [105.5116925, 23.0644463],
                [105.5108985, 23.0634151],
                [105.5096749, 23.0622355],
                [105.5088778, 23.0613849],
                [105.507993, 23.0597215],
                [105.5078154, 23.0580541],
                [105.5080748, 23.0558247],
                [105.5086194, 23.0533188],
                [105.5086361, 23.0521246],
                [105.5082769, 23.051451],
                [105.5077244, 23.0511645],
                [105.5065396, 23.0509623],
                [105.5058811, 23.0506828],
                [105.5053838, 23.050174],
                [105.5049708, 23.0491076],
                [105.5047342, 23.0477771],
                [105.504602, 23.0462167],
                [105.5043921, 23.0450614],
                [105.5043601, 23.0438829],
                [105.5042386, 23.0430612],
                [105.5036591, 23.042252],
                [105.5026652, 23.0417007],
                [105.4995982, 23.0405605],
                [105.4982183, 23.039642],
                [105.4973974, 23.0388066],
                [105.496979, 23.0381157],
                [105.4968455, 23.0373439],
                [105.4968678, 23.0364484],
                [105.4967653, 23.0357187],
                [105.4964947, 23.0351552],
                [105.4957542, 23.034487],
                [105.4949803, 23.0339353],
                [105.4945078, 23.0332228],
                [105.4943844, 23.0324766],
                [105.4944072, 23.0318512],
                [105.4945495, 23.0308656],
                [105.4945412, 23.0300013],
                [105.4943726, 23.029395],
                [105.4939772, 23.0289419],
                [105.4931373, 23.0283771],
                [105.4926895, 23.0280211],
                [105.492448, 23.0275907],
                [105.492325, 23.026968],
                [105.4922147, 23.0258899],
                [105.4920071, 23.0246593],
                [105.4917803, 23.023963],
                [105.4914816, 23.0233748],
                [105.4912885, 23.0227267],
                [105.4912257, 23.0219202],
                [105.4913222, 23.0211036],
                [105.4915836, 23.0196258],
                [105.4917766, 23.0188496],
                [105.4917872, 23.0181369],
                [105.4915861, 23.0174368],
                [105.4905543, 23.0153869],
                [105.4901657, 23.0125776],
                [105.4890829, 23.0098667],
                [105.4883248, 23.0085094],
                [105.486456, 23.008587],
                [105.4846209, 23.0085294],
                [105.4811256, 23.0080685],
                [105.4792455, 23.0077417],
                [105.4771018, 23.0069893],
                [105.4745708, 23.0063974],
                [105.4726453, 23.0066924],
                [105.4705707, 23.007509],
                [105.4694978, 23.0074952],
                [105.4684169, 23.006742],
                [105.4670134, 23.0062977],
                [105.4650453, 23.003429],
                [105.4637218, 23.0027593],
                [105.4626001, 23.0027147],
                [105.4612368, 23.0035561],
                [105.4593908, 23.0040501],
                [105.4574793, 23.0034676],
                [105.4553948, 23.0029289],
                [105.4532033, 23.0017768],
                [105.4528054, 23.0004621],
                [105.4524307, 23.0001925],
                [105.4508104, 22.9999448],
                [105.4504444, 22.9996514],
                [105.4500911, 22.998769],
                [105.4492455, 22.9985491],
                [105.4473627, 22.9985323],
                [105.4466787, 22.9987483],
                [105.4462662, 22.99892],
                [105.4460027, 22.9995799],
                [105.4448336, 23.0003085],
                [105.4443912, 23.0005435],
                [105.4442037, 23.0009661],
                [105.4442059, 23.0018638],
                [105.4437298, 23.0024573],
                [105.4429532, 23.0030073],
                [105.4421009, 23.0031702],
                [105.4416166, 23.0031568],
                [105.4396494, 23.0025229],
                [105.4372863, 23.0020677],
                [105.4351845, 23.0019034],
                [105.4326013, 23.001154],
                [105.4318181, 23.0006209],
                [105.4314679, 23.000529],
                [105.4310345, 23.0000687],
                [105.4302108, 22.999706],
                [105.4293813, 22.9993763],
                [105.4283246, 22.9986156],
                [105.4274548, 22.9982256],
                [105.4265043, 22.9980756],
                [105.4256421, 22.9981214],
                [105.4236242, 22.9990865],
                [105.4214879, 22.9999289],
                [105.4205882, 23.0001883],
                [105.4200117, 23.0005519],
                [105.4187433, 23.0010146],
                [105.4186374, 23.0008014],
                [105.4181452, 23.0001433],
                [105.4171931, 22.999267],
                [105.4165603, 22.9989094],
                [105.4152089, 22.997791],
                [105.4148556, 22.9973201],
                [105.4146165, 22.9964804],
                [105.4142397, 22.99552],
                [105.4137169, 22.9949383],
                [105.4121849, 22.9945039],
                [105.410829, 22.9944712],
                [105.4092804, 22.9945756],
                [105.4081078, 22.9948718],
                [105.4067659, 22.9947929],
                [105.4052862, 22.9944181],
                [105.4027896, 22.9936388],
                [105.4014731, 22.992723],
                [105.4000397, 22.9912611],
                [105.3989668, 22.990901],
                [105.397156, 22.990924],
                [105.3944294, 22.9918937],
                [105.3929105, 22.9919717],
                [105.3914851, 22.9919665],
                [105.3907005, 22.9916424],
                [105.3898782, 22.9910571],
                [105.3881598, 22.9900927],
                [105.3864716, 22.9895961],
                [105.3847846, 22.9893752],
                [105.3788713, 22.9894298],
                [105.3764513, 22.9896274],
                [105.3747467, 22.9896225],
                [105.3740932, 22.9886009],
                [105.3732756, 22.9876291],
                [105.372818, 22.9866126],
                [105.3721695, 22.9856972],
                [105.371229, 22.9847948],
                [105.3705344, 22.9839635],
                [105.3697066, 22.9833908],
                [105.3688485, 22.9830738],
                [105.368137, 22.9830897],
                [105.3673073, 22.9829018],
                [105.3667548, 22.9824861],
                [105.3657749, 22.9812824],
                [105.3651247, 22.9807656],
                [105.3642624, 22.9802459],
                [105.3632847, 22.9799362],
                [105.3626799, 22.9795419],
                [105.3618809, 22.9791353],
                [105.3616322, 22.9789601],
                [105.3618384, 22.9770888],
                [105.361659, 22.976462],
                [105.3617857, 22.9755521],
                [105.3615046, 22.9745192],
                [105.3604843, 22.9724676],
                [105.3603972, 22.9713349],
                [105.3614263, 22.9705073],
                [105.3623578, 22.9697052],
                [105.3632337, 22.9687533],
                [105.3641513, 22.9679249],
                [105.3649496, 22.9659611],
                [105.3650179, 22.9652555],
                [105.3649786, 22.9646505],
                [105.3645666, 22.9639297],
                [105.3633199, 22.9626595],
                [105.3627099, 22.9620696],
                [105.3623165, 22.9613409],
                [105.3617711, 22.9607562],
                [105.3617006, 22.9601007],
                [105.361853, 22.9594772],
                [105.3621497, 22.9587269],
                [105.362023, 22.9580301],
                [105.3615156, 22.9573416],
                [105.3613672, 22.9566387],
                [105.3629428, 22.9535918],
                [105.3637078, 22.9526924],
                [105.3641751, 22.9520017],
                [105.3643152, 22.951438],
                [105.3643419, 22.9507764],
                [105.3641009, 22.9499581],
                [105.3628799, 22.9486504],
                [105.3623455, 22.9481728],
                [105.3622874, 22.9477971],
                [105.3627014, 22.947273],
                [105.3630975, 22.9464622],
                [105.3632388, 22.9458429],
                [105.3633945, 22.9451432],
                [105.3636238, 22.9441662],
                [105.3631054, 22.9433898],
                [105.3625218, 22.9426411],
                [105.3625035, 22.9415168],
                [105.3623526, 22.9403463],
                [105.3618905, 22.9393735],
                [105.3612215, 22.9386512],
                [105.3602637, 22.9378014],
                [105.3593758, 22.936961],
                [105.3583165, 22.9362101],
                [105.3574084, 22.935527],
                [105.3567899, 22.9353549],
                [105.3545786, 22.9358788],
                [105.3527108, 22.9357373],
                [105.3506138, 22.9353095],
                [105.3494584, 22.9348305],
                [105.3483857, 22.934177],
                [105.3473747, 22.9334675],
                [105.3462185, 22.9329828],
                [105.3453626, 22.9327262],
                [105.3446068, 22.9327213],
                [105.342399, 22.9332954],
                [105.3414041, 22.933264],
                [105.3404509, 22.9334056],
                [105.3384042, 22.9333829],
                [105.3362028, 22.9329491],
                [105.3350543, 22.9330122],
                [105.333763, 22.9337564],
                [105.3327333, 22.9338095],
                [105.3324825, 22.9327578],
                [105.3318752, 22.9321269],
                [105.3317962, 22.9314984],
                [105.3319939, 22.9309924],
                [105.332504, 22.9305766],
                [105.3329444, 22.9300074],
                [105.3329145, 22.9293618],
                [105.3330818, 22.9284022],
                [105.3327529, 22.9277941],
                [105.3315611, 22.9266068],
                [105.330833, 22.925499],
                [105.3306298, 22.9247288],
                [105.330469, 22.9242435],
                [105.3301096, 22.923572],
                [105.3293626, 22.9228168],
                [105.327586, 22.9221265],
                [105.3261515, 22.9218599],
                [105.3254441, 22.9211512],
                [105.324747, 22.919906],
                [105.3234387, 22.9168341],
                [105.3228614, 22.9156773],
                [105.322188, 22.9152468],
                [105.3214572, 22.9149364],
                [105.3199862, 22.9147318],
                [105.3186788, 22.914905],
                [105.3173211, 22.9149647],
                [105.3151819, 22.9147699],
                [105.3144363, 22.9146629],
                [105.3132422, 22.9148959],
                [105.3126184, 22.915231],
                [105.3121904, 22.9151298],
                [105.3118785, 22.9143847],
                [105.3117338, 22.9136324],
                [105.3112312, 22.9129196],
                [105.3108433, 22.9126044],
                [105.3097398, 22.912427],
                [105.3086648, 22.9122844],
                [105.3077207, 22.9119764],
                [105.3072373, 22.9113972],
                [105.3072111, 22.9105793],
                [105.3075855, 22.9094943],
                [105.3082632, 22.908242],
                [105.3075127, 22.9072801],
                [105.3066109, 22.9064015],
                [105.3054909, 22.9048775],
                [105.304596, 22.902522],
                [105.3045378, 22.9013516],
                [105.3044561, 22.900646],
                [105.3045408, 22.9004927],
                [105.304672, 22.8999602],
                [105.3053232, 22.8994502],
                [105.3056841, 22.8990267],
                [105.3056017, 22.8982006],
                [105.3056903, 22.8978814],
                [105.3061065, 22.8974282],
                [105.3060652, 22.8969129],
                [105.3056746, 22.8963288],
                [105.3050819, 22.8953822],
                [105.3054514, 22.894301],
                [105.3057591, 22.8934956],
                [105.305864, 22.8927878],
                [105.3057899, 22.8918729],
                [105.3056751, 22.8909145],
                [105.305767, 22.8904236],
                [105.3063084, 22.88976],
                [105.3065631, 22.889206],
                [105.3066017, 22.8886579],
                [105.3050674, 22.8863315],
                [105.3046163, 22.8857965],
                [105.3040832, 22.8847081],
                [105.3037552, 22.8838372],
                [105.303534, 22.882902],
                [105.3034369, 22.8814631],
                [105.3036011, 22.8804913],
                [105.3037852, 22.8794438],
                [105.3039806, 22.8783995],
                [105.3039367, 22.8773688],
                [105.3034466, 22.8763005],
                [105.3032021, 22.8760205],
                [105.3018892, 22.8753872],
                [105.3001921, 22.8750216],
                [105.2986797, 22.8749941],
                [105.2971876, 22.8752018],
                [105.2960749, 22.8757443],
                [105.2950175, 22.875991],
                [105.2912982, 22.8761574],
                [105.2898976, 22.8769566],
                [105.288909, 22.8776355],
                [105.2877607, 22.8780677],
                [105.2866646, 22.878549],
                [105.2852774, 22.8785164],
                [105.283726, 22.8784411],
                [105.2828155, 22.8777179],
                [105.2824602, 22.877489],
                [105.2813701, 22.8771514],
                [105.280096, 22.8774565],
                [105.2788623, 22.8784379],
                [105.2779761, 22.8786612],
                [105.2753503, 22.878587],
                [105.2736061, 22.8785807],
                [105.2710205, 22.8796129],
                [105.2673253, 22.881117],
                [105.268213, 22.8788949],
                [105.2686194, 22.877213],
                [105.2686151, 22.8754492],
                [105.2676088, 22.8699978],
                [105.266713, 22.8664239],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [107.4508216, 13.7950168],
                [107.4521142, 13.7940628],
                [107.4529483, 13.7936164],
                [107.4538336, 13.7925231],
                [107.4547208, 13.7913464],
                [107.4559011, 13.7904156],
                [107.4573447, 13.7896781],
                [107.4598753, 13.7882047],
                [107.4633994, 13.785265],
                [107.4667183, 13.7827822],
                [107.4705003, 13.7801844],
                [107.4734644, 13.7778864],
                [107.47755, 13.777405],
                [107.4810619, 13.77699],
                [107.4857494, 13.7764372],
                [107.4878956, 13.7749358],
                [107.49046, 13.7731419],
                [107.4921761, 13.7719408],
                [107.4955306, 13.7695936],
                [107.4980044, 13.7678628],
                [107.5126704, 13.7601901],
                [107.5265397, 13.7489278],
                [107.532569, 13.7442651],
                [107.5506994, 13.6919818],
                [107.5696398, 13.6641482],
                [107.5717601, 13.6230173],
                [107.57384, 13.6187471],
                [107.5835016, 13.5989101],
                [107.585772, 13.5942484],
                [107.6176098, 13.5288666],
                [107.6182138, 13.5213971],
                [107.6312258, 13.3604031],
                [107.6087307, 13.3052001],
                [107.609538, 13.3055749],
                [107.6128307, 13.3069296],
                [107.6148342, 13.3074662],
                [107.6152492, 13.3099122],
                [107.6156831, 13.3114788],
                [107.6169136, 13.3122917],
                [107.6212951, 13.3127713],
                [107.6234865, 13.3148784],
                [107.6256548, 13.315317],
                [107.627203, 13.314696],
                [107.6291762, 13.3143576],
                [107.6297523, 13.3161492],
                [107.6301155, 13.3165055],
                [107.6301106, 13.3197775],
                [107.6301871, 13.3199507],
                [107.6304769, 13.3207335],
                [107.6311309, 13.3211612],
                [107.6324396, 13.3217323],
                [107.6357129, 13.3220215],
                [107.6380409, 13.3220248],
                [107.6384773, 13.3220966],
                [107.6389861, 13.3223818],
                [107.6397069, 13.3241303],
                [107.6399952, 13.325909],
                [107.6397849, 13.327471],
                [107.6398567, 13.3280402],
                [107.6400743, 13.3285384],
                [107.6405828, 13.329037],
                [107.641747, 13.3288963],
                [107.6441997, 13.3266788],
                [107.6465527, 13.3260578],
                [107.6474262, 13.3257746],
                [107.6481537, 13.3257756],
                [107.6486627, 13.3259186],
                [107.6500478, 13.3264274],
                [107.6502769, 13.3280631],
                [107.6502474, 13.3299066],
                [107.6502457, 13.3310446],
                [107.6503895, 13.3321829],
                [107.6506515, 13.3328839],
                [107.6506746, 13.3333445],
                [107.6506272, 13.3339831],
                [107.6505601, 13.3344801],
                [107.6504583, 13.3351193],
                [107.6503561, 13.3357231],
                [107.6502196, 13.3365044],
                [107.6500652, 13.3373036],
                [107.6499384, 13.3381595],
                [107.6498023, 13.3389768],
                [107.6496835, 13.339723],
                [107.649635, 13.3402558],
                [107.6496065, 13.3409656],
                [107.6496936, 13.3414791],
                [107.649733, 13.3417625],
                [107.6498987, 13.3419913],
                [107.6501004, 13.3422021],
                [107.6503376, 13.3423416],
                [107.650683, 13.3424267],
                [107.651191, 13.3424569],
                [107.6518795, 13.3424141],
                [107.6525677, 13.3423537],
                [107.6534554, 13.3422913],
                [107.6540716, 13.342267],
                [107.6543617, 13.342264],
                [107.654634, 13.3422965],
                [107.6549252, 13.3424],
                [107.6553086, 13.3426442],
                [107.655629, 13.3429248],
                [107.6559957, 13.3433111],
                [107.6563273, 13.3437866],
                [107.6566232, 13.3443156],
                [107.6570735, 13.3451187],
                [107.6576358, 13.346126],
                [107.6580801, 13.3465709],
                [107.6583997, 13.3467804],
                [107.6590933, 13.3471988],
                [107.6597683, 13.347582],
                [107.6602614, 13.3479139],
                [107.6607197, 13.3483702],
                [107.6612139, 13.3487907],
                [107.6617078, 13.3491935],
                [107.6621466, 13.3495259],
                [107.6626392, 13.3498046],
                [107.6631856, 13.3500295],
                [107.6638587, 13.3502352],
                [107.6645217, 13.3503525],
                [107.6650845, 13.3504175],
                [107.6657552, 13.3504104],
                [107.6664439, 13.3503854],
                [107.6670055, 13.3503439],
                [107.66753, 13.350232],
                [107.6680722, 13.3500666],
                [107.668613, 13.3497772],
                [107.6690985, 13.3494173],
                [107.6693682, 13.3492193],
                [107.6697653, 13.3490556],
                [107.6701909, 13.3490156],
                [107.6706266, 13.3490642],
                [107.6709358, 13.3491496],
                [107.6712096, 13.3493241],
                [107.6715381, 13.3495157],
                [107.6718498, 13.3498317],
                [107.6721073, 13.350166],
                [107.6723471, 13.350536],
                [107.6725699, 13.3510126],
                [107.6726836, 13.3514549],
                [107.6728335, 13.3518967],
                [107.6729839, 13.3523918],
                [107.6731351, 13.3529401],
                [107.6732962, 13.353577],
                [107.6734113, 13.3541611],
                [107.6735251, 13.3546034],
                [107.6736929, 13.3550273],
                [107.6739165, 13.3555748],
                [107.6741405, 13.3561578],
                [107.6743442, 13.3565459],
                [107.6746108, 13.3568801],
                [107.6749223, 13.3571784],
                [107.6752699, 13.3574585],
                [107.6756532, 13.3576851],
                [107.6761631, 13.3578925],
                [107.6770191, 13.3582383],
                [107.6778747, 13.3585485],
                [107.6788401, 13.3589462],
                [107.6794047, 13.3591885],
                [107.6799701, 13.3594842],
                [107.6806179, 13.3598675],
                [107.6810925, 13.3601463],
                [107.6815482, 13.360372],
                [107.6825283, 13.3607681],
                [107.6834648, 13.3614482],
                [107.6840998, 13.3621482],
                [107.6845756, 13.3630296],
                [107.6848932, 13.3633148],
                [107.6853433, 13.3635486],
                [107.6857671, 13.3636786],
                [107.6861115, 13.363679],
                [107.6865357, 13.3635241],
                [107.6868277, 13.3631101],
                [107.6873585, 13.3623855],
                [107.6883132, 13.3617391],
                [107.6887638, 13.3615842],
                [107.6892142, 13.3615848],
                [107.6896114, 13.3617925],
                [107.6898494, 13.3621295],
                [107.6899546, 13.3627254],
                [107.6899798, 13.3637355],
                [107.690058, 13.3647718],
                [107.6902425, 13.3655749],
                [107.690824, 13.3666894],
                [107.6915906, 13.3680372],
                [107.6924237, 13.3692483],
                [107.6933232, 13.3703381],
                [107.6946997, 13.3714284],
                [107.6962881, 13.3725191],
                [107.6982745, 13.3732212],
                [107.6999301, 13.373651],
                [107.7016253, 13.3740678],
                [107.7033206, 13.3744844],
                [107.7040297, 13.3746738],
                [107.7042794, 13.3746871],
                [107.7045688, 13.3746131],
                [107.7052019, 13.3744821],
                [107.7056713, 13.3742998],
                [107.7060675, 13.3740649],
                [107.7064447, 13.3737416],
                [107.706894, 13.3733821],
                [107.7074322, 13.3728619],
                [107.7078436, 13.3723608],
                [107.7083618, 13.3716812],
                [107.7088422, 13.3708602],
                [107.7091891, 13.3702711],
                [107.7094215, 13.3699671],
                [107.7097267, 13.3696977],
                [107.7099789, 13.3695531],
                [107.7103047, 13.3694965],
                [107.7106314, 13.3695284],
                [107.7110676, 13.3696302],
                [107.7115227, 13.3697849],
                [107.7118691, 13.3699586],
                [107.7121436, 13.3701862],
                [107.7123456, 13.3704146],
                [107.712457, 13.370644],
                [107.712516, 13.3710514],
                [107.7125207, 13.3714771],
                [107.7125442, 13.3719557],
                [107.7126028, 13.3723276],
                [107.712696, 13.3725572],
                [107.7128614, 13.3727505],
                [107.7132088, 13.3730128],
                [107.7141021, 13.3734467],
                [107.7147757, 13.3736877],
                [107.7153118, 13.3738061],
                [107.7157838, 13.3738544],
                [107.7166007, 13.3739342],
                [107.7171809, 13.3739279],
                [107.7175426, 13.3738532],
                [107.7177588, 13.3737266],
                [107.7181002, 13.3734569],
                [107.7185834, 13.3728842],
                [107.7188881, 13.3725792],
                [107.7191205, 13.3722753],
                [107.719298, 13.3719363],
                [107.7194901, 13.371278],
                [107.7195766, 13.3709045],
                [107.7196084, 13.3705139],
                [107.7197123, 13.3700695],
                [107.7198716, 13.3697307],
                [107.720149, 13.3694085],
                [107.720509, 13.369174],
                [107.7209427, 13.3690452],
                [107.7213766, 13.3689341],
                [107.7216842, 13.3688776],
                [107.7221735, 13.3688546],
                [107.7224998, 13.3688511],
                [107.7237888, 13.3689967],
                [107.7243876, 13.3690436],
                [107.7248769, 13.3690206],
                [107.7252023, 13.3689283],
                [107.7254902, 13.3687301],
                [107.7257398, 13.368355],
                [107.7258817, 13.3680874],
                [107.7260778, 13.3677838],
                [107.7263651, 13.3675323],
                [107.7268328, 13.3672079],
                [107.7274091, 13.3668648],
                [107.7279861, 13.3665748],
                [107.7286533, 13.3662483],
                [107.7290316, 13.3660314],
                [107.7293199, 13.3658686],
                [107.7297176, 13.3657756],
                [107.7300795, 13.3657008],
                [107.7304058, 13.3656973],
                [107.730824, 13.3657991],
                [107.7314427, 13.3660053],
                [107.7317887, 13.3661435],
                [107.7320978, 13.3662111],
                [107.7326981, 13.3663997],
                [107.7334253, 13.3665692],
                [107.7341529, 13.3667742],
                [107.7346982, 13.3668925],
                [107.7351338, 13.3669409],
                [107.7357508, 13.3669875],
                [107.7364033, 13.3669627],
                [107.7372176, 13.3668298],
                [107.7375323, 13.3665958],
                [107.7377299, 13.366434],
                [107.7380375, 13.3663774],
                [107.7383258, 13.3662147],
                [107.7386136, 13.3660165],
                [107.7390596, 13.365373],
                [107.7396486, 13.3645508],
                [107.7399355, 13.3642639],
                [107.7403313, 13.3639935],
                [107.7407279, 13.3637942],
                [107.7410534, 13.3637197],
                [107.7414523, 13.3637153],
                [107.7420148, 13.3637624],
                [107.7426508, 13.3638797],
                [107.7433418, 13.3640673],
                [107.744187, 13.3642533],
                [107.7445321, 13.3643028],
                [107.7449309, 13.3642984],
                [107.7452747, 13.3642415],
                [107.7460156, 13.3640206],
                [107.7468994, 13.3636208],
                [107.7477291, 13.3632393],
                [107.7482168, 13.3630744],
                [107.7484338, 13.3630366],
                [107.7489228, 13.362978],
                [107.7492589, 13.3630453],
                [107.7497685, 13.3632171],
                [107.7500609, 13.3634091],
                [107.7503837, 13.3635955],
                [107.7505321, 13.3637184],
                [107.7509533, 13.3640518],
                [107.751138, 13.3642603],
                [107.7513049, 13.3644731],
                [107.7513923, 13.3645887],
                [107.7514942, 13.364748],
                [107.7515452, 13.3648713],
                [107.7516056, 13.3650254],
                [107.7516593, 13.3652378],
                [107.7516898, 13.3654569],
                [107.75175, 13.3657053],
                [107.7517775, 13.3658405],
                [107.7517997, 13.3660425],
                [107.751807, 13.366142],
                [107.7518021, 13.3662463],
                [107.7517836, 13.3664212],
                [107.7517465, 13.3667623],
                [107.7517148, 13.3669086],
                [107.7516808, 13.3670798],
                [107.7516382, 13.367332],
                [107.7516397, 13.3674617],
                [107.7515893, 13.3676989],
                [107.7515681, 13.367844],
                [107.7515549, 13.3680088],
                [107.7515502, 13.3682465],
                [107.7515606, 13.3684968],
                [107.7516111, 13.3687918],
                [107.7516929, 13.3689842],
                [107.7518159, 13.3691471],
                [107.7519489, 13.3692707],
                [107.7520599, 13.3693363],
                [107.7522481, 13.369402],
                [107.7524217, 13.3694166],
                [107.7525219, 13.3694155],
                [107.7526765, 13.3694138],
                [107.7528047, 13.3694124],
                [107.7529405, 13.3693881],
                [107.7531157, 13.3693544],
                [107.7532679, 13.3692757],
                [107.7533856, 13.369197],
                [107.7535902, 13.3690699],
                [107.7536888, 13.3689997],
                [107.7538014, 13.3689414],
                [107.7539155, 13.368872],
                [107.7540531, 13.3687954],
                [107.7541873, 13.3687329],
                [107.7543708, 13.3686635],
                [107.7545058, 13.3685975],
                [107.7546472, 13.3685255],
                [107.7548608, 13.3684768],
                [107.7551, 13.3684456],
                [107.7552713, 13.3684312],
                [107.7554511, 13.3684294],
                [107.7556536, 13.3684333],
                [107.7559207, 13.3684736],
                [107.7562432, 13.3685557],
                [107.756368, 13.3686198],
                [107.7568895, 13.3687683],
                [107.7571311, 13.3688483],
                [107.7572945, 13.3689201],
                [107.7574905, 13.3689489],
                [107.757686, 13.3689732],
                [107.7578508, 13.3690004],
                [107.7579729, 13.369051],
                [107.7582066, 13.3691448],
                [107.7583272, 13.369172],
                [107.7584543, 13.3692107],
                [107.7585781, 13.3692093],
                [107.7586836, 13.3692282],
                [107.7588209, 13.3692267],
                [107.7589526, 13.3692253],
                [107.7590282, 13.3692371],
                [107.7593097, 13.3693758],
                [107.7595051, 13.3695054],
                [107.7596977, 13.3696088],
                [107.7598313, 13.3696641],
                [107.7599354, 13.3697323],
                [107.7600705, 13.3698246],
                [107.7602181, 13.3699199],
                [107.7602869, 13.3699895],
                [107.7604105, 13.3700976],
                [107.7604984, 13.3701794],
                [107.760588, 13.3702684],
                [107.7606705, 13.3703729],
                [107.7607766, 13.3704639],
                [107.7609912, 13.3706467],
                [107.7611713, 13.370793],
                [107.7612912, 13.3708695],
                [107.7613818, 13.3709311],
                [107.7616061, 13.371149],
                [107.7616929, 13.3712297],
                [107.7617729, 13.371321],
                [107.7618855, 13.3714812],
                [107.762017, 13.3716034],
                [107.7621015, 13.3716897],
                [107.7622687, 13.3717904],
                [107.7624287, 13.3718726],
                [107.7626404, 13.3719052],
                [107.7628077, 13.3718958],
                [107.7628323, 13.3718928],
                [107.7629585, 13.3718689],
                [107.7630759, 13.3718276],
                [107.7632288, 13.3717621],
                [107.763344, 13.3716408],
                [107.7634328, 13.3715285],
                [107.7635193, 13.371331],
                [107.7635175, 13.3711792],
                [107.763516, 13.3710449],
                [107.7634564, 13.3708062],
                [107.7633547, 13.3705544],
                [107.7631419, 13.3701409],
                [107.7629486, 13.3697804],
                [107.7628632, 13.3696095],
                [107.7627464, 13.3693802],
                [107.7626358, 13.3691821],
                [107.7625316, 13.3689172],
                [107.7624486, 13.3687093],
                [107.7623705, 13.3685584],
                [107.7623001, 13.3683703],
                [107.7622655, 13.3681858],
                [107.7622363, 13.3680301],
                [107.7621998, 13.3677655],
                [107.762191, 13.3675929],
                [107.7621829, 13.367441],
                [107.7622128, 13.3671853],
                [107.7622292, 13.367079],
                [107.7623887, 13.3666428],
                [107.7625323, 13.366381],
                [107.7627132, 13.3661612],
                [107.7627956, 13.3660688],
                [107.7631452, 13.3657389],
                [107.7634633, 13.365489],
                [107.7636878, 13.3653252],
                [107.7643017, 13.3648213],
                [107.7644429, 13.3646902],
                [107.7645706, 13.3646177],
                [107.764744, 13.3645717],
                [107.7648352, 13.364561],
                [107.764962, 13.3645754],
                [107.7651299, 13.3646189],
                [107.7652557, 13.364677],
                [107.7655193, 13.3648919],
                [107.7659618, 13.3653545],
                [107.7662101, 13.3656554],
                [107.7664535, 13.3659941],
                [107.7666531, 13.3663064],
                [107.7667749, 13.3665512],
                [107.7669146, 13.3667746],
                [107.7671217, 13.3672759],
                [107.7672343, 13.3676728],
                [107.7672897, 13.367932],
                [107.7674185, 13.3681919],
                [107.7676525, 13.3685713],
                [107.7678093, 13.3688182],
                [107.7680122, 13.3690028],
                [107.7683073, 13.3691754],
                [107.7685722, 13.3692908],
                [107.7687776, 13.3693787],
                [107.769031, 13.3694631],
                [107.769224, 13.3694969],
                [107.7693915, 13.369495],
                [107.7696604, 13.3694849],
                [107.7698252, 13.3694546],
                [107.7700215, 13.3694248],
                [107.7701736, 13.3693849],
                [107.7704219, 13.3692917],
                [107.7705945, 13.3692239],
                [107.770702, 13.3691865],
                [107.770798, 13.3691627],
                [107.7709353, 13.3691405],
                [107.7710845, 13.3690963],
                [107.7712636, 13.3690486],
                [107.7714575, 13.3690286],
                [107.7716502, 13.3690265],
                [107.7718291, 13.369042],
                [107.7720157, 13.3690906],
                [107.7722584, 13.3691404],
                [107.7724217, 13.3691902],
                [107.7725747, 13.369232],
                [107.7729005, 13.3692854],
                [107.7730991, 13.3693353],
                [107.7732624, 13.3693724],
                [107.7736664, 13.369465],
                [107.7739519, 13.3695968],
                [107.7740904, 13.3696762],
                [107.7744727, 13.3699134],
                [107.7745982, 13.3700185],
                [107.7747753, 13.370195],
                [107.7749018, 13.3703627],
                [107.7749512, 13.3704931],
                [107.7749939, 13.3705996],
                [107.7750301, 13.3708841],
                [107.7750597, 13.3710544],
                [107.7750844, 13.371315],
                [107.775142, 13.3715386],
                [107.7751868, 13.3716538],
                [107.7753116, 13.3718732],
                [107.7755958, 13.3721748],
                [107.7758312, 13.3723025],
                [107.7760404, 13.3724025],
                [107.7760518, 13.3724074],
                [107.7762741, 13.3724961],
                [107.7764894, 13.372553],
                [107.7766043, 13.3726034],
                [107.7767048, 13.3726792],
                [107.7767812, 13.3727298],
                [107.7769507, 13.3727829],
                [107.777067, 13.3728052],
                [107.7772181, 13.3728223],
                [107.777441, 13.3728922],
                [107.777547, 13.3729339],
                [107.7777291, 13.372975],
                [107.778003, 13.3730404],
                [107.7781455, 13.3730626],
                [107.7782596, 13.3730614],
                [107.7784082, 13.373082],
                [107.7785655, 13.3730553],
                [107.7786735, 13.3730587],
                [107.7788137, 13.3731055],
                [107.7790076, 13.3731442],
                [107.7791301, 13.3731795],
                [107.7793009, 13.3732364],
                [107.77943, 13.3732503],
                [107.7795404, 13.3732666],
                [107.779636, 13.3732941],
                [107.7797148, 13.3733443],
                [107.7797829, 13.3734035],
                [107.7798148, 13.3734893],
                [107.7798458, 13.3736021],
                [107.7799144, 13.373752],
                [107.7799969, 13.373884],
                [107.7801197, 13.3741045],
                [107.7802974, 13.3743766],
                [107.7803836, 13.3744647],
                [107.7806366, 13.3747032],
                [107.7808678, 13.374836],
                [107.7810012, 13.3748977],
                [107.7812525, 13.3749277],
                [107.7815058, 13.3749107],
                [107.7817827, 13.3749007],
                [107.782032, 13.3748573],
                [107.7822624, 13.3748392],
                [107.7823754, 13.3748379],
                [107.7825772, 13.3748357],
                [107.7827208, 13.3748478],
                [107.7831536, 13.3748293],
                [107.7833852, 13.3748204],
                [107.7835503, 13.3748382],
                [107.7838312, 13.3748384],
                [107.7839877, 13.3748553],
                [107.7841097, 13.3748713],
                [107.7842582, 13.3749184],
                [107.7844267, 13.3750143],
                [107.7846714, 13.3751464],
                [107.7848962, 13.3753119],
                [107.785018, 13.3754172],
                [107.7852643, 13.3754988],
                [107.7854257, 13.3755395],
                [107.7856085, 13.3755296],
                [107.7857682, 13.3755214],
                [107.7858979, 13.3755266],
                [107.786012, 13.3755252],
                [107.7861137, 13.3755504],
                [107.7861837, 13.3755951],
                [107.7863358, 13.3757476],
                [107.7864773, 13.3759504],
                [107.7866615, 13.3763003],
                [107.7867882, 13.3766168],
                [107.7869073, 13.376918],
                [107.7871452, 13.3773683],
                [107.7872654, 13.3775007],
                [107.7875433, 13.3779167],
                [107.7876452, 13.3780332],
                [107.7878485, 13.3782535],
                [107.7881047, 13.3784574],
                [107.7883317, 13.3786541],
                [107.7886282, 13.3788056],
                [107.7889331, 13.3789267],
                [107.7891939, 13.3790815],
                [107.7896245, 13.3793552],
                [107.7899967, 13.3796315],
                [107.7903054, 13.37995],
                [107.7904544, 13.3801081],
                [107.7911846, 13.3808694],
                [107.7914785, 13.3811357],
                [107.7917962, 13.381441],
                [107.7920263, 13.3815831],
                [107.7921623, 13.3816647],
                [107.7923975, 13.3817525],
                [107.7925657, 13.3818301],
                [107.7927269, 13.3818378],
                [107.7928481, 13.3818283],
                [107.793203, 13.3817583],
                [107.7934231, 13.3816579],
                [107.7935064, 13.381623],
                [107.7935808, 13.3816033],
                [107.7936242, 13.3816004],
                [107.7941264, 13.3816017],
                [107.794147, 13.3816061],
                [107.7942576, 13.381649],
                [107.7944396, 13.3817424],
                [107.7946249, 13.3818376],
                [107.7950075, 13.3820664],
                [107.7951769, 13.3821709],
                [107.7953603, 13.3822817],
                [107.7955375, 13.3823357],
                [107.7956931, 13.382334],
                [107.7957978, 13.3823328],
                [107.7959292, 13.3823224],
                [107.7960582, 13.3822737],
                [107.7961784, 13.3822067],
                [107.7963717, 13.3820638],
                [107.7968334, 13.3816187],
                [107.7970041, 13.3814462],
                [107.797228, 13.3812483],
                [107.7974101, 13.381091],
                [107.7974953, 13.3810272],
                [107.7976852, 13.3808854],
                [107.79786, 13.3807719],
                [107.7980385, 13.3806816],
                [107.7981691, 13.3806177],
                [107.7983066, 13.3805901],
                [107.7984567, 13.3805827],
                [107.7986018, 13.3805926],
                [107.7988095, 13.3806347],
                [107.799115, 13.3806905],
                [107.7993177, 13.380741],
                [107.7995054, 13.3808099],
                [107.7996446, 13.3808883],
                [107.7997727, 13.3809833],
                [107.7999584, 13.3811386],
                [107.8000746, 13.3813269],
                [107.8001179, 13.38146],
                [107.8001196, 13.3816084],
                [107.8001408, 13.381798],
                [107.8001034, 13.3819695],
                [107.8000101, 13.3822078],
                [107.799926, 13.3823651],
                [107.7998091, 13.3827007],
                [107.7997273, 13.3828729],
                [107.7995841, 13.3831189],
                [107.799439, 13.3834023],
                [107.7993789, 13.3835726],
                [107.7993419, 13.3837014],
                [107.7993115, 13.3838474],
                [107.7992294, 13.3840207],
                [107.7990976, 13.3843475],
                [107.7990274, 13.3845228],
                [107.7989747, 13.3846126],
                [107.7988566, 13.3848674],
                [107.7987803, 13.385011],
                [107.7986438, 13.3851618],
                [107.798504, 13.3853119],
                [107.7983621, 13.3855153],
                [107.7982733, 13.3856618],
                [107.798081, 13.3859287],
                [107.7979589, 13.3861899],
                [107.7978801, 13.3864449],
                [107.797797, 13.3867403],
                [107.797722, 13.3870931],
                [107.7976791, 13.3873316],
                [107.7976765, 13.3877518],
                [107.7976932, 13.387888],
                [107.7977204, 13.3881516],
                [107.7977893, 13.388279],
                [107.7979129, 13.3883849],
                [107.7980948, 13.388517],
                [107.7984536, 13.3886929],
                [107.7988147, 13.388879],
                [107.7991055, 13.3890498],
                [107.7995197, 13.3892576],
                [107.7997536, 13.3893785],
                [107.7999916, 13.3895211],
                [107.8001556, 13.3896121],
                [107.8003544, 13.3897782],
                [107.8005044, 13.3899054],
                [107.8005817, 13.3899991],
                [107.8007872, 13.3902974],
                [107.8008803, 13.3905273],
                [107.8009729, 13.3906702],
                [107.8010852, 13.3908659],
                [107.8011298, 13.3909826],
                [107.8012257, 13.3912626],
                [107.8013611, 13.3915707],
                [107.8014232, 13.3917843],
                [107.8014881, 13.3920199],
                [107.8015597, 13.3923142],
                [107.8016017, 13.3925552],
                [107.8014922, 13.3937169],
                [107.8015805, 13.3937287],
                [107.8027731, 13.3934759],
                [107.8033367, 13.3933233],
                [107.8038614, 13.3931345],
                [107.8043511, 13.3932633],
                [107.8047025, 13.3936375],
                [107.8050927, 13.3940479],
                [107.8053524, 13.3942768],
                [107.8058929, 13.394344],
                [107.8064504, 13.394212],
                [107.8071171, 13.3940946],
                [107.8075933, 13.3941504],
                [107.8080982, 13.3944744],
                [107.8085139, 13.3948605],
                [107.8088224, 13.3948693],
                [107.8092073, 13.3948162],
                [107.809493, 13.3945261],
                [107.8098119, 13.394315],
                [107.8102101, 13.3942984],
                [107.8108154, 13.3944137],
                [107.8113431, 13.3944932],
                [107.8118171, 13.3943659],
                [107.8121743, 13.3941421],
                [107.8126223, 13.3939907],
                [107.8134635, 13.3939507],
                [107.8141177, 13.3938458],
                [107.814578, 13.3936454],
                [107.8149352, 13.3934216],
                [107.8153574, 13.3932582],
                [107.8157019, 13.3930468],
                [107.8161933, 13.3927666],
                [107.8168624, 13.3928446],
                [107.8193098, 13.3933422],
                [107.821623, 13.3939085],
                [107.8237621, 13.3944218],
                [107.8276474, 13.3953611],
                [107.8314557, 13.3963012],
                [107.8330769, 13.3970338],
                [107.8344352, 13.3977999],
                [107.8357543, 13.3985175],
                [107.8392281, 13.4005113],
                [107.8406986, 13.3993532],
                [107.8416777, 13.3984633],
                [107.8435391, 13.3966659],
                [107.8456318, 13.3948659],
                [107.8477085, 13.3928098],
                [107.8491126, 13.3914693],
                [107.8499259, 13.390691],
                [107.8512047, 13.3896205],
                [107.8516763, 13.3892978],
                [107.8534652, 13.389534],
                [107.8565259, 13.3902867],
                [107.8603541, 13.3907501],
                [107.8643156, 13.3916147],
                [107.8682252, 13.392431],
                [107.87216, 13.3932104],
                [107.8755881, 13.3935193],
                [107.8765208, 13.3936125],
                [107.8784512, 13.3927847],
                [107.8791531, 13.3923738],
                [107.8802915, 13.3913902],
                [107.8810208, 13.3911377],
                [107.8818197, 13.391324],
                [107.8822935, 13.3911843],
                [107.883049, 13.3904249],
                [107.8833703, 13.3893591],
                [107.8837731, 13.3881153],
                [107.8838222, 13.3879194],
                [107.8847992, 13.3879571],
                [107.8859059, 13.3881031],
                [107.8859225, 13.3873339],
                [107.8859804, 13.386265],
                [107.8864439, 13.3852708],
                [107.8869696, 13.3846361],
                [107.8882094, 13.3851591],
                [107.8895119, 13.3855593],
                [107.8922834, 13.3857656],
                [107.8936107, 13.3860922],
                [107.8967204, 13.3866487],
                [107.8994486, 13.3875391],
                [107.9006522, 13.3882577],
                [107.9012507, 13.3883424],
                [107.9028179, 13.3883122],
                [107.9038321, 13.3882394],
                [107.9053314, 13.3884358],
                [107.9065554, 13.3887148],
                [107.9071743, 13.3888908],
                [107.908142, 13.3886903],
                [107.9089218, 13.3883519],
                [107.9094165, 13.3878211],
                [107.9098982, 13.3872785],
                [107.9101484, 13.386714],
                [107.9103182, 13.386413],
                [107.9109829, 13.3861245],
                [107.9114022, 13.385729],
                [107.9119625, 13.3853197],
                [107.9127844, 13.3852858],
                [107.9139146, 13.3852361],
                [107.9152166, 13.3850563],
                [107.91643, 13.3844563],
                [107.917295, 13.3837383],
                [107.9191304, 13.3819775],
                [107.9200438, 13.3810025],
                [107.9207165, 13.3798501],
                [107.9209448, 13.3795332],
                [107.922085, 13.3792637],
                [107.9231886, 13.3791533],
                [107.9251135, 13.3784168],
                [107.9269308, 13.3778343],
                [107.9307119, 13.3765391],
                [107.9326991, 13.3758544],
                [107.9330139, 13.3756881],
                [107.933186, 13.3757068],
                [107.9336468, 13.3757565],
                [107.9341485, 13.3757995],
                [107.9345083, 13.3757953],
                [107.9351495, 13.3756903],
                [107.9355978, 13.375563],
                [107.9358667, 13.3754745],
                [107.9361745, 13.3754344],
                [107.9365864, 13.3754906],
                [107.9372918, 13.3756318],
                [107.93782, 13.3757478],
                [107.9384989, 13.3758234],
                [107.9389162, 13.3758003],
                [107.9393384, 13.3756487],
                [107.9395967, 13.375484],
                [107.9398826, 13.3752182],
                [107.9401158, 13.3748491],
                [107.9402226, 13.3746464],
                [107.9404911, 13.3742679],
                [107.9406994, 13.3739602],
                [107.9410053, 13.3737613],
                [107.9413261, 13.3737148],
                [107.9417787, 13.3736881],
                [107.9421501, 13.3735862],
                [107.9424506, 13.3734545],
                [107.9426798, 13.373287],
                [107.9428972, 13.373199],
                [107.9431321, 13.3732237],
                [107.9434829, 13.3735431],
                [107.9438111, 13.3741193],
                [107.944093, 13.3748396],
                [107.9441931, 13.3751497],
                [107.9442383, 13.3754331],
                [107.9442524, 13.3760679],
                [107.9443017, 13.3766808],
                [107.9441784, 13.3771096],
                [107.9440697, 13.3779441],
                [107.9440398, 13.3781339],
                [107.9439012, 13.379152],
                [107.9439299, 13.3796614],
                [107.9439817, 13.3799569],
                [107.9442683, 13.3802771],
                [107.9446834, 13.3805959],
                [107.9450896, 13.3807072],
                [107.9454434, 13.3807457],
                [107.9457768, 13.3806808],
                [107.9464638, 13.3803828],
                [107.9469946, 13.3801751],
                [107.9474996, 13.3799556],
                [107.948112, 13.3798538],
                [107.9485936, 13.3798298],
                [107.9489871, 13.3799595],
                [107.9494106, 13.3804369],
                [107.9497119, 13.3811691],
                [107.9498111, 13.3814061],
                [107.9498929, 13.3820553],
                [107.9498585, 13.3823914],
                [107.9497889, 13.3832683],
                [107.9497271, 13.3834705],
                [107.9495998, 13.3843663],
                [107.949639, 13.3852114],
                [107.9496927, 13.385394],
                [107.9499495, 13.3856444],
                [107.9501363, 13.3856788],
                [107.9504893, 13.3856442],
                [107.9508363, 13.3856524],
                [107.951152, 13.3857158],
                [107.9514417, 13.3857613],
                [107.9516441, 13.3857558],
                [107.9520867, 13.3856957],
                [107.9526052, 13.3855371],
                [107.9529951, 13.3853738],
                [107.9539905, 13.3850599],
                [107.9543688, 13.3849944],
                [107.954733, 13.3848192],
                [107.955182, 13.3844996],
                [107.9554724, 13.3840749],
                [107.9557768, 13.3837417],
                [107.9559387, 13.3835841],
                [107.9563594, 13.3833106],
                [107.9568117, 13.3829878],
                [107.957162, 13.3827273],
                [107.9577045, 13.3821684],
                [107.9578617, 13.3818858],
                [107.9578846, 13.3816657],
                [107.9578916, 13.3814489],
                [107.9579844, 13.3811548],
                [107.9581234, 13.3809517],
                [107.9583137, 13.3807601],
                [107.9587448, 13.3805445],
                [107.9591423, 13.3804728],
                [107.9598356, 13.3804219],
                [107.9610328, 13.3803315],
                [107.9615011, 13.3802711],
                [107.9628061, 13.3800574],
                [107.9632614, 13.3799849],
                [107.9644258, 13.37984],
                [107.9652359, 13.3798733],
                [107.9657925, 13.3799491],
                [107.9664008, 13.3802961],
                [107.9671485, 13.3810199],
                [107.9677589, 13.3818033],
                [107.9680507, 13.3820136],
                [107.9691155, 13.3826512],
                [107.969555, 13.3828598],
                [107.9700062, 13.3829826],
                [107.9704598, 13.3830292],
                [107.9708896, 13.3829753],
                [107.9713751, 13.3827437],
                [107.9717067, 13.3825383],
                [107.972295, 13.38204],
                [107.9727348, 13.381754],
                [107.9730415, 13.3816221],
                [107.9736846, 13.3813979],
                [107.9740318, 13.3814243],
                [107.9746013, 13.3817534],
                [107.9748906, 13.3820216],
                [107.9751399, 13.38244],
                [107.975195, 13.3827446],
                [107.9753315, 13.3831366],
                [107.9755401, 13.3833784],
                [107.9758183, 13.3835339],
                [107.9762173, 13.3835902],
                [107.9766625, 13.3837315],
                [107.9770983, 13.3839065],
                [107.9776028, 13.3841752],
                [107.9778345, 13.3842091],
                [107.9782457, 13.3841982],
                [107.9787174, 13.3841529],
                [107.9797109, 13.383958],
                [107.9801331, 13.3838066],
                [107.9806236, 13.3837183],
                [107.9809518, 13.3837633],
                [107.9813255, 13.3838505],
                [107.9816103, 13.384012],
                [107.9818978, 13.3844053],
                [107.9819891, 13.3847737],
                [107.9819544, 13.3850916],
                [107.9818228, 13.3853556],
                [107.9817638, 13.3855242],
                [107.9814806, 13.3860099],
                [107.9811638, 13.3863677],
                [107.9807481, 13.3867907],
                [107.9804534, 13.3873864],
                [107.9802465, 13.3880573],
                [107.9801994, 13.3884119],
                [107.9801845, 13.3890318],
                [107.980221, 13.3893792],
                [107.9803194, 13.3903275],
                [107.9804872, 13.3906459],
                [107.9810088, 13.3912626],
                [107.9818506, 13.3920493],
                [107.9821746, 13.3922713],
                [107.9830445, 13.3927342],
                [107.9839652, 13.3928759],
                [107.9845524, 13.3928171],
                [107.9851844, 13.39248],
                [107.9854891, 13.3921833],
                [107.9861569, 13.3918854],
                [107.9866062, 13.3918435],
                [107.9868905, 13.3919259],
                [107.9872982, 13.3919385],
                [107.9880689, 13.392117],
                [107.9884356, 13.3921682],
                [107.9888454, 13.3921633],
                [107.9893856, 13.3920458],
                [107.9895962, 13.3919112],
                [107.9901035, 13.3915022],
                [107.9902473, 13.3912921],
                [107.9904498, 13.3910951],
                [107.9909541, 13.3904359],
                [107.9915322, 13.3898315],
                [107.9926133, 13.3890629],
                [107.9930939, 13.3888625],
                [107.9938542, 13.388784],
                [107.9941037, 13.3888367],
                [107.99431, 13.3889455],
                [107.9951597, 13.3895959],
                [107.9958702, 13.3902272],
                [107.9964902, 13.390637],
                [107.9967402, 13.3907313],
                [107.997399, 13.3907375],
                [107.9978358, 13.3905376],
                [107.9981115, 13.3903396],
                [107.9985348, 13.3896602],
                [107.9991571, 13.3886095],
                [107.9993637, 13.3881737],
                [107.9998049, 13.3872252],
                [108.0000408, 13.3865047],
                [108.0002188, 13.3860648],
                [108.0003785, 13.385994],
                [108.0015622, 13.3857501],
                [108.0019054, 13.385559],
                [108.0024713, 13.3852276],
                [108.0031611, 13.3847972],
                [108.0035274, 13.3846061],
                [108.00379, 13.3844815],
                [108.004339, 13.3842398],
                [108.0050994, 13.3838616],
                [108.0053151, 13.3836912],
                [108.0055788, 13.3834913],
                [108.0059866, 13.3831428],
                [108.0064942, 13.3827314],
                [108.0068144, 13.3824344],
                [108.0071434, 13.3820549],
                [108.0075726, 13.3815825],
                [108.0078526, 13.3813301],
                [108.0081401, 13.3811155],
                [108.0082675, 13.3810569],
                [108.0085178, 13.3809733],
                [108.0087083, 13.3809229],
                [108.0089302, 13.3808955],
                [108.0092938, 13.3809224],
                [108.0095542, 13.3809855],
                [108.0099792, 13.3811711],
                [108.0105957, 13.3815431],
                [108.0109885, 13.3817733],
                [108.0113506, 13.3819204],
                [108.0117285, 13.3820828],
                [108.0122045, 13.3823028],
                [108.0124319, 13.3824709],
                [108.01258, 13.3826531],
                [108.0126884, 13.3828499],
                [108.0127569, 13.3830612],
                [108.0127643, 13.383099],
                [108.0128673, 13.3834122],
                [108.0129509, 13.3836914],
                [108.0130275, 13.3838953],
                [108.0131198, 13.3841144],
                [108.0133274, 13.384598],
                [108.0134418, 13.384945],
                [108.0135338, 13.3851941],
                [108.0136499, 13.3853984],
                [108.013681, 13.3854438],
                [108.0139896, 13.3857759],
                [108.0142254, 13.3859067],
                [108.0144618, 13.3859922],
                [108.0145957, 13.386039],
                [108.0147373, 13.3861082],
                [108.0149343, 13.3861934],
                [108.0153675, 13.3863564],
                [108.0161998, 13.3865805],
                [108.0166182, 13.3866683],
                [108.0171081, 13.3867342],
                [108.0174563, 13.3867383],
                [108.017686, 13.3867184],
                [108.0177826, 13.3865919],
                [108.0179596, 13.3863684],
                [108.0181198, 13.3862125],
                [108.0182239, 13.386116],
                [108.0183433, 13.3860648],
                [108.0184702, 13.3860513],
                [108.0186287, 13.3860381],
                [108.0189291, 13.3860717],
                [108.0192291, 13.3861354],
                [108.0194971, 13.3862287],
                [108.0197376, 13.386312],
                [108.0199506, 13.3863747],
                [108.0201555, 13.3864523],
                [108.0202735, 13.3865139],
                [108.0203197, 13.3866123],
                [108.0203272, 13.3866574],
                [108.0207186, 13.3866865],
                [108.0209635, 13.3867346],
                [108.0211923, 13.3867975],
                [108.0213733, 13.3868899],
                [108.0218095, 13.387147],
                [108.0220997, 13.3873687],
                [108.0223815, 13.3876353],
                [108.0225612, 13.3878255],
                [108.0226506, 13.3879582],
                [108.02279, 13.3882157],
                [108.0230228, 13.3885945],
                [108.0231622, 13.3888445],
                [108.0232088, 13.3889203],
                [108.0236041, 13.3896093],
                [108.0238372, 13.3899654],
                [108.0240002, 13.3902379],
                [108.0240471, 13.3902836],
                [108.0243939, 13.3907443],
                [108.0246676, 13.391026],
                [108.0248555, 13.3912013],
                [108.0250047, 13.3913009],
                [108.0252485, 13.3914319],
                [108.0255678, 13.3915184],
                [108.0255933, 13.3916178],
                [108.0257329, 13.3919083],
                [108.0258984, 13.3922755],
                [108.026047, 13.3925662],
                [108.0261065, 13.3928216],
                [108.0261405, 13.3929665],
                [108.0261877, 13.3933334],
                [108.0262468, 13.3935693],
                [108.0263295, 13.3938429],
                [108.026382, 13.3940464],
                [108.0264009, 13.3941904],
                [108.0264047, 13.394439],
                [108.0264327, 13.394678],
                [108.026492, 13.3949011],
                [108.0265793, 13.3950722],
                [108.0266876, 13.3952173],
                [108.0267969, 13.3952971],
                [108.0269574, 13.3953873],
                [108.0271222, 13.3954219],
                [108.0273149, 13.3954307],
                [108.0275492, 13.3954008],
                [108.0280253, 13.3953083],
                [108.0285229, 13.3951506],
                [108.0288927, 13.3950209],
                [108.0291861, 13.3949413],
                [108.0294222, 13.3949019],
                [108.02995, 13.3948947],
                [108.0302169, 13.3949247],
                [108.0304205, 13.3949671],
                [108.0305888, 13.3950159],
                [108.0309036, 13.3951533],
                [108.0312945, 13.3954052],
                [108.0315974, 13.3956595],
                [108.0317787, 13.3957886],
                [108.0319954, 13.3959048],
                [108.0321704, 13.3959737],
                [108.0323738, 13.3960296],
                [108.0325141, 13.396058],
                [108.0327262, 13.3959803],
                [108.0328998, 13.3958719],
                [108.0330268, 13.39584],
                [108.033139, 13.3958748],
                [108.0332506, 13.3959496],
                [108.0334695, 13.3961827],
                [108.0336493, 13.3964455],
                [108.0338355, 13.3967483],
                [108.0339103, 13.3969629],
                [108.0339161, 13.3970631],
                [108.0339801, 13.3972959],
                [108.0340473, 13.3975508],
                [108.0340632, 13.397688],
                [108.0341388, 13.3978428],
                [108.0345601, 13.3984895],
                [108.0348865, 13.3988477],
                [108.0351045, 13.3991345],
                [108.0352633, 13.3993904],
                [108.0353247, 13.3995517],
                [108.0354026, 13.3997966],
                [108.0354283, 13.4000043],
                [108.0354308, 13.4003653],
                [108.0354416, 13.4006396],
                [108.0354493, 13.4014387],
                [108.0354481, 13.4015457],
                [108.0354508, 13.4021843],
                [108.0354556, 13.4023582],
                [108.0354756, 13.4024521],
                [108.0355624, 13.4025501],
                [108.0356881, 13.4026317],
                [108.0358351, 13.402687],
                [108.0359759, 13.4026819],
                [108.0361242, 13.4026435],
                [108.0363791, 13.4025128],
                [108.0367185, 13.4023831],
                [108.0370441, 13.4022332],
                [108.0372452, 13.4021853],
                [108.0373859, 13.402187],
                [108.0375473, 13.402229],
                [108.0376668, 13.4022438],
                [108.0378427, 13.4022459],
                [108.0380614, 13.4021949],
                [108.0382317, 13.4020766],
                [108.0384526, 13.4018585],
                [108.0386812, 13.4015804],
                [108.0387999, 13.4013712],
                [108.038987, 13.4010323],
                [108.0391446, 13.4008068],
                [108.0392659, 13.4006678],
                [108.0394611, 13.4005331],
                [108.0397799, 13.4003563],
                [108.040056, 13.4002258],
                [108.0402405, 13.4000942],
                [108.040305, 13.4000014],
                [108.0403566, 13.3998147],
                [108.0404131, 13.3995044],
                [108.0404573, 13.3993446],
                [108.0405285, 13.3992784],
                [108.0406695, 13.3992533],
                [108.0408594, 13.3992623],
                [108.0411117, 13.3993522],
                [108.0413492, 13.3994953],
                [108.0416278, 13.399726],
                [108.0420863, 13.4002194],
                [108.0423204, 13.4003937],
                [108.0425092, 13.4004962],
                [108.0426777, 13.4005317],
                [108.0428185, 13.4005332],
                [108.0429598, 13.4004948],
                [108.0432426, 13.4003911],
                [108.0434585, 13.4002966],
                [108.0436277, 13.4002652],
                [108.0438035, 13.4002874],
                [108.043958, 13.4003159],
                [108.0442526, 13.4003995],
                [108.0445612, 13.4004902],
                [108.0448341, 13.4006271],
                [108.0450612, 13.4007602],
                [108.0453265, 13.4009372],
                [108.0454588, 13.4010524],
                [108.0455628, 13.4011874],
                [108.0456368, 13.4014625],
                [108.0456429, 13.4015496],
                [108.0457113, 13.4019885],
                [108.045741, 13.4024369],
                [108.0457827, 13.4033503],
                [108.0458218, 13.4039025],
                [108.045869, 13.4040636],
                [108.0459591, 13.4041783],
                [108.0461338, 13.4042807],
                [108.0464453, 13.4044215],
                [108.0467536, 13.4045388],
                [108.0469988, 13.404642],
                [108.0471449, 13.4047775],
                [108.0472134, 13.4049321],
                [108.0472386, 13.4051799],
                [108.0472588, 13.4055379],
                [108.0473229, 13.4060536],
                [108.0473903, 13.4065862],
                [108.0474563, 13.406948],
                [108.0475213, 13.4070993],
                [108.0476455, 13.4073014],
                [108.0478265, 13.4074707],
                [108.0481681, 13.4077356],
                [108.048336, 13.4078246],
                [108.0489158, 13.4081858],
                [108.0494758, 13.4084598],
                [108.0497406, 13.4086053],
                [108.0497954, 13.4087575],
                [108.0499006, 13.4089334],
                [108.0500476, 13.4091231],
                [108.0502894, 13.4094022],
                [108.0505158, 13.4096813],
                [108.0506285, 13.4098571],
                [108.0507411, 13.4100475],
                [108.0508234, 13.4102085],
                [108.0508669, 13.4104052],
                [108.050918, 13.4106026],
                [108.0510153, 13.4107928],
                [108.0511285, 13.4109252],
                [108.0513105, 13.4110728],
                [108.0515267, 13.41125],
                [108.0518689, 13.4114432],
                [108.0521177, 13.4115668],
                [108.0521188, 13.4115672],
                [108.0521192, 13.4115697],
                [108.0521564, 13.4117781],
                [108.0522591, 13.4120202],
                [108.0523911, 13.4121689],
                [108.0526844, 13.4123731],
                [108.0530242, 13.4125278],
                [108.0533402, 13.412605],
                [108.0535937, 13.412608],
                [108.053897, 13.412578],
                [108.0544616, 13.412491],
                [108.0547828, 13.4124378],
                [108.0552131, 13.4123759],
                [108.0554041, 13.4123112],
                [108.0556242, 13.4121733],
                [108.0557812, 13.4120012],
                [108.0560106, 13.4116758],
                [108.0561781, 13.4115139],
                [108.0565407, 13.4112436],
                [108.0567398, 13.4110854],
                [108.0568328, 13.4109727],
                [108.0569051, 13.4108196],
                [108.0569183, 13.4106089],
                [108.0569202, 13.4104484],
                [108.0568797, 13.4102939],
                [108.0567827, 13.4101723],
                [108.0565868, 13.4100562],
                [108.0562156, 13.4098846],
                [108.0558261, 13.409763],
                [108.0556162, 13.4096468],
                [108.0554767, 13.4095314],
                [108.0553586, 13.4094028],
                [108.0552412, 13.409214],
                [108.0552009, 13.409053],
                [108.0552113, 13.408772],
                [108.0552386, 13.4085547],
                [108.0553108, 13.4084083],
                [108.0554182, 13.4082691],
                [108.0556106, 13.4080839],
                [108.0558809, 13.4078729],
                [108.0562171, 13.407733],
                [108.0565779, 13.4076101],
                [108.0569033, 13.4074934],
                [108.0570447, 13.4074548],
                [108.0571925, 13.4074633],
                [108.0574381, 13.407533],
                [108.0576377, 13.4076324],
                [108.0578965, 13.4077827],
                [108.058134, 13.4079461],
                [108.0585888, 13.4081924],
                [108.0590018, 13.4084046],
                [108.0593425, 13.4084788],
                [108.0597508, 13.4085103],
                [108.060061, 13.4084806],
                [108.060548, 13.4083992],
                [108.0609468, 13.4083403],
                [108.0612709, 13.4083306],
                [108.0615873, 13.4083746],
                [108.0619528, 13.408439],
                [108.0621915, 13.408502],
                [108.0625109, 13.4085893],
                [108.0629315, 13.4087549],
                [108.0634706, 13.4090289],
                [108.0636597, 13.4091181],
                [108.06386, 13.4091505],
                [108.0640015, 13.4091054],
                [108.0641365, 13.4090066],
                [108.0642439, 13.4088673],
                [108.0642945, 13.4087541],
                [108.0643105, 13.4086003],
                [108.0642636, 13.4084057],
                [108.064188, 13.4082441],
                [108.0641035, 13.407952],
                [108.0640222, 13.4076901],
                [108.0639688, 13.4074485],
                [108.0639583, 13.4071607],
                [108.0639512, 13.4068627],
                [108.0639892, 13.4066423],
                [108.0640686, 13.4064826],
                [108.0641757, 13.4063634],
                [108.0642891, 13.4063044],
                [108.0645463, 13.4063041],
                [108.0647354, 13.4063799],
                [108.0651413, 13.4065989],
                [108.0656162, 13.406939],
                [108.0658528, 13.4071694],
                [108.0660018, 13.4073751],
                [108.0660771, 13.4075635],
                [108.0661239, 13.4077648],
                [108.0661345, 13.408046],
                [108.0661206, 13.4083235],
                [108.0661393, 13.4085246],
                [108.0662357, 13.4087064],
                [108.0663744, 13.4088887],
                [108.0664791, 13.4089703],
                [108.0667345, 13.4091103],
                [108.0671199, 13.4092756],
                [108.0674426, 13.4093796],
                [108.0678218, 13.4094778],
                [108.0682433, 13.4095697],
                [108.0685988, 13.4095906],
                [108.0687471, 13.4095588],
                [108.0688891, 13.4094667],
                [108.0689608, 13.4093604],
                [108.0690257, 13.4092407],
                [108.0691127, 13.4090344],
                [108.0691786, 13.4088275],
                [108.0692158, 13.4086741],
                [108.0692827, 13.4083805],
                [108.0693276, 13.4081537],
                [108.0693317, 13.4081065],
                [108.069344, 13.4079666],
                [108.0693885, 13.4077798],
                [108.0694464, 13.4076534],
                [108.0696014, 13.4073719],
                [108.0697596, 13.407106],
                [108.0698459, 13.406953],
                [108.0699112, 13.4067998],
                [108.0699946, 13.4066099],
                [108.0700467, 13.4063762],
                [108.0701067, 13.4060824],
                [108.0701301, 13.4058953],
                [108.0702097, 13.4054242],
                [108.0702844, 13.405077],
                [108.0703501, 13.4045992],
                [108.0704087, 13.4044057],
                [108.0704999, 13.4042629],
                [108.0703271, 13.4040558],
                [108.0701887, 13.4038416],
                [108.070108, 13.4036516],
                [108.070094, 13.4034466],
                [108.0700974, 13.403163],
                [108.0701424, 13.4028722],
                [108.0702526, 13.4026765],
                [108.0704114, 13.4025681],
                [108.0705778, 13.4025149],
                [108.0708599, 13.4025024],
                [108.0712164, 13.4024986],
                [108.0714821, 13.4024623],
                [108.0716488, 13.4023407],
                [108.0716451, 13.4023403],
                [108.0717033, 13.402187],
                [108.0718726, 13.4018843],
                [108.0719884, 13.4016314],
                [108.0720758, 13.4013981],
                [108.0721554, 13.4012182],
                [108.0722033, 13.4010481],
                [108.072241, 13.4008477],
                [108.0723215, 13.4006009],
                [108.0724019, 13.4003542],
                [108.0725641, 13.4000514],
                [108.072715, 13.3998055],
                [108.0728729, 13.3995663],
                [108.0729728, 13.3994671],
                [108.0730899, 13.3993982],
                [108.0732238, 13.3993997],
                [108.0733927, 13.3994084],
                [108.0735331, 13.3994568],
                [108.0736729, 13.3995388],
                [108.0738401, 13.3997014],
                [108.0739782, 13.3999373],
                [108.0741496, 13.4003276],
                [108.0741767, 13.4004083],
                [108.0744138, 13.400903],
                [108.0747694, 13.4014996],
                [108.075157, 13.4020732],
                [108.0752817, 13.4022553],
                [108.0757633, 13.4029203],
                [108.0759787, 13.4031639],
                [108.0762359, 13.4034414],
                [108.0765356, 13.403719],
                [108.0767242, 13.4038551],
                [108.0768925, 13.4039306],
                [108.077195, 13.4039743],
                [108.0774487, 13.4039705],
                [108.0779002, 13.4039289],
                [108.0780834, 13.403931],
                [108.0782873, 13.4039802],
                [108.0784728, 13.4040739],
                [108.0787947, 13.4042719],
                [108.078984, 13.4043477],
                [108.0791531, 13.4043564],
                [108.0793223, 13.404345],
                [108.0795349, 13.404247],
                [108.0798972, 13.4040101],
                [108.0801426, 13.4038221],
                [108.0802568, 13.4036962],
                [108.0803574, 13.4035434],
                [108.0804518, 13.4033168],
                [108.0805039, 13.4030897],
                [108.0805243, 13.4028523],
                [108.0805289, 13.4024706],
                [108.0804689, 13.4021887],
                [108.0803634, 13.4018761],
                [108.0802253, 13.4016401],
                [108.0800251, 13.4012964],
                [108.0798144, 13.4009491],
                [108.0797251, 13.4007472],
                [108.0796782, 13.4005525],
                [108.0796811, 13.4003114],
                [108.0797082, 13.4001141],
                [108.0797591, 13.3999808],
                [108.0798664, 13.3998481],
                [108.0799876, 13.3997424],
                [108.0801785, 13.3996844],
                [108.0803686, 13.3996999],
                [108.0805371, 13.3997554],
                [108.0806977, 13.3998711],
                [108.0809965, 13.4002228],
                [108.0812631, 13.4006108],
                [108.0814504, 13.4008608],
                [108.0815333, 13.4010024],
                [108.0816416, 13.401372],
                [108.0817367, 13.4016677],
                [108.081764, 13.4017416],
                [108.0819676, 13.4020955],
                [108.0821963, 13.4024129],
                [108.0823976, 13.402663],
                [108.0826099, 13.4028897],
                [108.0828052, 13.4030527],
                [108.083001, 13.4031822],
                [108.0832038, 13.4033119],
                [108.0833505, 13.4034207],
                [108.0834546, 13.4035557],
                [108.0835336, 13.4037207],
                [108.0836083, 13.4039492],
                [108.083705, 13.404111],
                [108.0838789, 13.4043073],
                [108.0841305, 13.4044843],
                [108.0846663, 13.4047616],
                [108.0852267, 13.405036],
                [108.0856751, 13.4052554],
                [108.086541, 13.4056304],
                [108.0869753, 13.4058564],
                [108.0872757, 13.4060674],
                [108.0876918, 13.4063368],
                [108.0879719, 13.4064806],
                [108.0881889, 13.4066036],
                [108.0883772, 13.4067665],
                [108.0885017, 13.4069688],
                [108.0887265, 13.4073163],
                [108.0888716, 13.407559],
                [108.0890796, 13.4078426],
                [108.0894212, 13.4084526],
                [108.089573, 13.4087088],
                [108.089709, 13.4088342],
                [108.089807, 13.408889],
                [108.0899194, 13.408917],
                [108.0900605, 13.4089119],
                [108.0902235, 13.4088335],
                [108.0903822, 13.408778],
                [108.0905241, 13.4087448],
                [108.0906959, 13.4087176],
                [108.0909292, 13.4087215],
                [108.0912467, 13.4086577],
                [108.0915561, 13.4085609],
                [108.0918686, 13.4084747],
                [108.0921464, 13.4083093],
                [108.092417, 13.4080685],
                [108.0927443, 13.4077837],
                [108.0931875, 13.4075764],
                [108.0934579, 13.4074938],
                [108.0938222, 13.407457],
                [108.0941229, 13.4074673],
                [108.0944511, 13.4075122],
                [108.0950026, 13.4076145],
                [108.0954456, 13.4077258],
                [108.0958221, 13.4078398],
                [108.0962046, 13.4080292],
                [108.0965658, 13.4082527],
                [108.0970717, 13.408656],
                [108.0973997, 13.4090127],
                [108.0980013, 13.4096091],
                [108.0982993, 13.4098386],
                [108.098564, 13.4099377],
                [108.0989062, 13.4099895],
                [108.0995455, 13.4100688],
                [108.1002303, 13.4101316],
                [108.1013033, 13.4102159],
                [108.1018907, 13.4102501],
                [108.1024778, 13.4102979],
                [108.1031302, 13.4104459],
                [108.103527, 13.4106149],
                [108.1038189, 13.4107758],
                [108.1041375, 13.4110467],
                [108.1043871, 13.4112416],
                [108.104575, 13.4113191],
                [108.1048126, 13.4113493],
                [108.1050817, 13.4113685],
                [108.1052848, 13.411357],
                [108.1055763, 13.4112885],
                [108.1058986, 13.4112373],
                [108.106401, 13.4113527],
                [108.1074438, 13.4118854],
                [108.1082898, 13.4125119],
                [108.1086906, 13.4129138],
                [108.1089092, 13.4133549],
                [108.1090894, 13.4137989],
                [108.1092523, 13.4142119],
                [108.1095054, 13.4146944],
                [108.1098109, 13.415181],
                [108.1101209, 13.4155751],
                [108.110695, 13.4161231],
                [108.1110423, 13.4163257],
                [108.1113937, 13.4164839],
                [108.1116376, 13.4165621],
                [108.1120011, 13.4165868],
                [108.112295, 13.4165834],
                [108.1127294, 13.4165198],
                [108.1133221, 13.4163929],
                [108.1137017, 13.4162328],
                [108.1140604, 13.4160725],
                [108.1145255, 13.41579],
                [108.1152975, 13.4152884],
                [108.1157706, 13.4149238],
                [108.1162782, 13.414587],
                [108.1171065, 13.4140654],
                [108.1175012, 13.4138165],
                [108.1180014, 13.4135138],
                [108.1185513, 13.4131569],
                [108.1190196, 13.4129054],
                [108.1194625, 13.4127185],
                [108.1198565, 13.4125312],
                [108.1203704, 13.4122561],
                [108.120751, 13.4120138],
                [108.1211333, 13.4119256],
                [108.1217354, 13.4118846],
                [108.1220647, 13.4118403],
                [108.1224578, 13.4117353],
                [108.1227181, 13.4116148],
                [108.1230894, 13.411273],
                [108.12326, 13.4110489],
                [108.1237287, 13.4104512],
                [108.1242344, 13.4099876],
                [108.1246786, 13.4097048],
                [108.1250161, 13.409558],
                [108.1253598, 13.4094797],
                [108.1256958, 13.4094561],
                [108.126003, 13.4095007],
                [108.126348, 13.4096108],
                [108.1266824, 13.4097381],
                [108.126995, 13.4099129],
                [108.1275847, 13.4103308],
                [108.1281462, 13.410762],
                [108.1283788, 13.4109051],
                [108.1288794, 13.4111712],
                [108.1292904, 13.4113129],
                [108.1296182, 13.411392],
                [108.1298699, 13.4114154],
                [108.1301149, 13.4113908],
                [108.1303576, 13.4112736],
                [108.1306749, 13.4110717],
                [108.1310838, 13.4108091],
                [108.1313878, 13.4105317],
                [108.1316272, 13.4100992],
                [108.131815, 13.4096081],
                [108.1320015, 13.4092265],
                [108.132158, 13.4090089],
                [108.1323062, 13.4088873],
                [108.132492, 13.4088586],
                [108.1326876, 13.4088814],
                [108.1329526, 13.4089461],
                [108.1336782, 13.4091119],
                [108.1343796, 13.4092466],
                [108.134904, 13.4092732],
                [108.1353238, 13.4092642],
                [108.1356672, 13.4092065],
                [108.1359134, 13.4090927],
                [108.1360971, 13.4089441],
                [108.1362178, 13.4087878],
                [108.1363162, 13.4084566],
                [108.1364691, 13.4079514],
                [108.1365998, 13.4075485],
                [108.1367536, 13.4072659],
                [108.1369164, 13.4071033],
                [108.1370293, 13.4070156],
                [108.1371628, 13.406969],
                [108.1373239, 13.4069571],
                [108.1375611, 13.4070147],
                [108.1379658, 13.4071015],
                [108.1382454, 13.4071183],
                [108.1385921, 13.4070777],
                [108.1389785, 13.4069382],
                [108.1394421, 13.406779],
                [108.1397298, 13.4067002],
                [108.1399891, 13.4066688],
                [108.1401985, 13.4066985],
                [108.1403408, 13.4067927],
                [108.1404849, 13.4070341],
                [108.1405793, 13.4073299],
                [108.1406244, 13.4076593],
                [108.1406986, 13.4081844],
                [108.1407515, 13.4084453],
                [108.1408406, 13.408597],
                [108.1409305, 13.4086872],
                [108.141063, 13.408723],
                [108.1412657, 13.408739],
                [108.1414835, 13.4086592],
                [108.1417576, 13.4085527],
                [108.1421306, 13.4083582],
                [108.142444, 13.4081801],
                [108.143112, 13.407893],
                [108.1435684, 13.4077475],
                [108.1438067, 13.4077159],
                [108.1440514, 13.4077187],
                [108.1444045, 13.4077329],
                [108.1446217, 13.4077081],
                [108.144734, 13.4076818],
                [108.1448255, 13.4076281],
                [108.1448893, 13.4075603],
                [108.1449045, 13.4074576],
                [108.1448707, 13.4073545],
                [108.1447879, 13.4072576],
                [108.144489, 13.4071035],
                [108.1442108, 13.4069633],
                [108.1440727, 13.406811],
                [108.1440113, 13.4066801],
                [108.1440092, 13.4065534],
                [108.1440813, 13.4063829],
                [108.1442521, 13.4061312],
                [108.1443809, 13.4058861],
                [108.1444605, 13.4056608],
                [108.1445096, 13.4053564],
                [108.144533, 13.4051579],
                [108.1446361, 13.4047137],
                [108.1447292, 13.4045297],
                [108.144857, 13.4043668],
                [108.1450196, 13.4042315],
                [108.1452586, 13.4041246],
                [108.1455638, 13.4040561],
                [108.1457946, 13.4040588],
                [108.1460669, 13.4040961],
                [108.1464014, 13.4042095],
                [108.1466164, 13.4043626],
                [108.1468242, 13.4045431],
                [108.1470047, 13.4046617],
                [108.147172, 13.4047047],
                [108.1473748, 13.4047139],
                [108.147505, 13.4046503],
                [108.1476109, 13.4045693],
                [108.1477036, 13.4044126],
                [108.1478605, 13.4041677],
                [108.1480027, 13.4039707],
                [108.1482078, 13.403781],
                [108.1487049, 13.4034407],
                [108.1488813, 13.4033125],
                [108.1490444, 13.4031293],
                [108.1491301, 13.4029795],
                [108.1491603, 13.4027949],
                [108.1491649, 13.4024044],
                [108.1491784, 13.4021475],
                [108.1492577, 13.4019497],
                [108.1494362, 13.4016433],
                [108.1496483, 13.401447],
                [108.150008, 13.4011976],
                [108.1504836, 13.4009118],
                [108.1506042, 13.4007692],
                [108.1506551, 13.4006053],
                [108.1506938, 13.4002906],
                [108.1507114, 13.3999823],
                [108.1507484, 13.3998115],
                [108.1508128, 13.3996821],
                [108.1508695, 13.399621],
                [108.1509678, 13.3995947],
                [108.1510378, 13.3995956],
                [108.1511213, 13.3996238],
                [108.1512149, 13.3996968],
                [108.1513183, 13.3998282],
                [108.1514207, 13.400035],
                [108.1516511, 13.4006749],
                [108.1517537, 13.4008678],
                [108.1518368, 13.4009373],
                [108.1518925, 13.4009654],
                [108.1519592, 13.4009479],
                [108.1520227, 13.4009007],
                [108.1521153, 13.4007579],
                [108.1522228, 13.4005331],
                [108.1523224, 13.4003904],
                [108.1524852, 13.4002348],
                [108.1526614, 13.4001203],
                [108.15297, 13.4000553],
                [108.1532153, 13.400017],
                [108.153398, 13.3999506],
                [108.1536441, 13.3998439],
                [108.1539245, 13.3997922],
                [108.1540783, 13.3997939],
                [108.1542491, 13.3998334],
                [108.1546193, 13.3998787],
                [108.1548222, 13.3998673],
                [108.1549907, 13.3998144],
                [108.1551949, 13.3997002],
                [108.1554055, 13.3996341],
                [108.1555944, 13.3996293],
                [108.1557406, 13.399679],
                [108.1559488, 13.3998184],
                [108.1561427, 13.3999918],
                [108.1563185, 13.4002095],
                [108.1565035, 13.4005337],
                [108.1566595, 13.4009396],
                [108.1567274, 13.4011117],
                [108.1567752, 13.401215],
                [108.1568511, 13.4013117],
                [108.156941, 13.4013881],
                [108.1571222, 13.4014449],
                [108.1573948, 13.4014618],
                [108.1577725, 13.4014523],
                [108.1581331, 13.4014255],
                [108.1585262, 13.4013067],
                [108.1590192, 13.4010107],
                [108.1594148, 13.4006726],
                [108.1595345, 13.4006054],
                [108.1596184, 13.4006064],
                [108.159688, 13.4006414],
                [108.1597643, 13.4006903],
                [108.15984, 13.4008007],
                [108.1599206, 13.4010825],
                [108.1599544, 13.4011788],
                [108.1599955, 13.4012547],
                [108.1600299, 13.4012962],
                [108.1600996, 13.4013243],
                [108.1602078, 13.4013427],
                [108.1604248, 13.4013314],
                [108.1606425, 13.4012517],
                [108.1609028, 13.4011175],
                [108.1613259, 13.4008277],
                [108.1618548, 13.4004569],
                [108.1620134, 13.4003525],
                [108.1621131, 13.4002029],
                [108.1621497, 13.4000594],
                [108.1621449, 13.3998744],
                [108.1620775, 13.3996544],
                [108.1619281, 13.3992827],
                [108.1618714, 13.3990457],
                [108.161887, 13.398902],
                [108.1619586, 13.3987659],
                [108.1620651, 13.39863],
                [108.1622629, 13.3984609],
                [108.1626368, 13.3981843],
                [108.1627784, 13.3980352],
                [108.1628759, 13.3977725],
                [108.1629931, 13.3973216],
                [108.163101, 13.3970693],
                [108.1632286, 13.39692],
                [108.16344, 13.3967854],
                [108.1639777, 13.396555],
                [108.1643365, 13.396381],
                [108.1647799, 13.3961462],
                [108.1649564, 13.3960043],
                [108.1650418, 13.395875],
                [108.1650434, 13.3957449],
                [108.165003, 13.3956075],
                [108.1649206, 13.3954695],
                [108.1647028, 13.3952649],
                [108.1644123, 13.3949945],
                [108.1642888, 13.3947807],
                [108.1642835, 13.3946368],
                [108.164327, 13.3945071],
                [108.164483, 13.3943239],
                [108.1647298, 13.3941485],
                [108.1649619, 13.3940415],
                [108.1652947, 13.393987],
                [108.1659382, 13.3939874],
                [108.1665748, 13.393974],
                [108.1668483, 13.3939085],
                [108.1669544, 13.393807],
                [108.1669763, 13.3937318],
                [108.1669771, 13.3936565],
                [108.166923, 13.3935052],
                [108.1668132, 13.393319],
                [108.1667447, 13.3931949],
                [108.1667317, 13.3931125],
                [108.1667674, 13.3930581],
                [108.166852, 13.3929974],
                [108.1669714, 13.3929439],
                [108.1671709, 13.3929427],
                [108.1675414, 13.3929537],
                [108.1681357, 13.3929673],
                [108.1686395, 13.3929456],
                [108.1690071, 13.392905],
                [108.1693097, 13.3927578],
                [108.169437, 13.3926359],
                [108.1695083, 13.3925133],
                [108.1695169, 13.3923763],
                [108.1695131, 13.3921092],
                [108.1695029, 13.3917803],
                [108.1695254, 13.3916434],
                [108.1695542, 13.3915889],
                [108.1695895, 13.3915482],
                [108.1696669, 13.391508],
                [108.169758, 13.3914953],
                [108.1699113, 13.3915382],
                [108.170123, 13.3916809],
                [108.1703926, 13.3919511],
                [108.170641, 13.3922417],
                [108.1708281, 13.3923877],
                [108.1709812, 13.392458],
                [108.1711627, 13.3924874],
                [108.1713724, 13.3924897],
                [108.1715826, 13.3924578],
                [108.1718285, 13.3923647],
                [108.1720713, 13.3922406],
                [108.1723392, 13.3920518],
                [108.1725853, 13.3919381],
                [108.172831, 13.3918655],
                [108.1732804, 13.3917129],
                [108.1736952, 13.3915257],
                [108.1739527, 13.3913334],
                [108.1740661, 13.3912045],
                [108.1742082, 13.3910142],
                [108.1742728, 13.3908779],
                [108.1742755, 13.3906451],
                [108.1742369, 13.3900519],
                [108.1742187, 13.389812],
                [108.1742276, 13.3896476],
                [108.1742441, 13.3894354],
                [108.1742399, 13.3891956],
                [108.1741641, 13.3888008],
                [108.1741238, 13.3886565],
                [108.1741065, 13.3883411],
                [108.1741093, 13.3881014],
                [108.1741457, 13.3879784],
                [108.1742451, 13.3878494],
                [108.1744285, 13.3877213],
                [108.1747375, 13.3876014],
                [108.1751687, 13.3875069],
                [108.1755046, 13.387497],
                [108.1758958, 13.3875356],
                [108.1764746, 13.3876859],
                [108.1767258, 13.387723],
                [108.1768379, 13.3877105],
                [108.1769575, 13.3876502],
                [108.1770042, 13.3875446],
                [108.1770056, 13.3874281],
                [108.1769451, 13.387215],
                [108.1768247, 13.386741],
                [108.176798, 13.386333],
                [108.1768088, 13.3860112],
                [108.1768627, 13.3855869],
                [108.1769489, 13.3850902],
                [108.1769942, 13.3848031],
                [108.1771036, 13.3844209],
                [108.1772544, 13.3840871],
                [108.1772906, 13.3839711],
                [108.1772855, 13.3838204],
                [108.1773004, 13.3837315],
                [108.1773785, 13.3836868],
                [108.1775994, 13.3836474],
                [108.177754, 13.3836299],
                [108.1779488, 13.3836321],
                [108.1781432, 13.3836662],
                [108.1783839, 13.3837582],
                [108.1786243, 13.383882],
                [108.1788449, 13.3839673],
                [108.1791394, 13.3840535],
                [108.179468, 13.3840954],
                [108.1797098, 13.3840996],
                [108.1799252, 13.3840574],
                [108.1802423, 13.3839397],
                [108.1804522, 13.3838017],
                [108.1806492, 13.3836127],
                [108.1808473, 13.3833342],
                [108.1810358, 13.383012],
                [108.1812408, 13.3827142],
                [108.1814514, 13.3825121],
                [108.1817871, 13.3822414],
                [108.1821258, 13.3820025],
                [108.1824443, 13.3817634],
                [108.182725, 13.3816006],
                [108.1829746, 13.3815075],
                [108.1831429, 13.3814775],
                [108.1833648, 13.3814544],
                [108.183647, 13.3814512],
                [108.1838423, 13.381415],
                [108.1840612, 13.38136],
                [108.184278, 13.3812155],
                [108.1844477, 13.3810642],
                [108.1845826, 13.3810146],
                [108.1847372, 13.3810164],
                [108.1849381, 13.3810633],
                [108.1852667, 13.381118],
                [108.1854917, 13.3811142],
                [108.1856804, 13.3810652],
                [108.1858959, 13.3810229],
                [108.1860702, 13.381044],
                [108.1862644, 13.38111],
                [108.1867193, 13.3812748],
                [108.1868863, 13.3813594],
                [108.1870469, 13.3814123],
                [108.1873149, 13.3814854],
                [108.1874688, 13.3815381],
                [108.1875892, 13.3815905],
                [108.1885171, 13.3821115],
                [108.1886248, 13.3821062],
                [108.1887734, 13.3820504],
                [108.1888823, 13.3819367],
                [108.1889511, 13.3818034],
                [108.189001, 13.3815549],
                [108.1890229, 13.3814147],
                [108.1890708, 13.3813449],
                [108.1892064, 13.3812507],
                [108.1894527, 13.3811717],
                [108.1896418, 13.3811163],
                [108.1898512, 13.3810354],
                [108.1900272, 13.3809287],
                [108.190265, 13.3807269],
                [108.1904147, 13.3805814],
                [108.1905164, 13.3805123],
                [108.1906686, 13.3804372],
                [108.1908509, 13.3803689],
                [108.191006, 13.3803387],
                [108.191154, 13.380334],
                [108.1913557, 13.3803363],
                [108.1916175, 13.3803775],
                [108.1918594, 13.3803993],
                [108.192105, 13.3803894],
                [108.192354, 13.3803729],
                [108.1926574, 13.3803123],
                [108.1929342, 13.3802259],
                [108.1932924, 13.3800765],
                [108.1936471, 13.379927],
                [108.1938432, 13.3798397],
                [108.1944115, 13.3795519],
                [108.1948312, 13.3793137],
                [108.1951997, 13.3791388],
                [108.19547, 13.3790331],
                [108.1957737, 13.3789406],
                [108.1961175, 13.3788804],
                [108.1964744, 13.3788333],
                [108.1969422, 13.3788002],
                [108.197191, 13.3788028],
                [108.1974337, 13.3787544],
                [108.1977378, 13.37863],
                [108.1980632, 13.3784098],
                [108.198501, 13.3780631],
                [108.198867, 13.3778179],
                [108.1991785, 13.3776296],
                [108.1994596, 13.3774664],
                [108.1997369, 13.3773288],
                [108.2000685, 13.3771536],
                [108.2002516, 13.3770276],
                [108.2004014, 13.3768568],
                [108.2004941, 13.3766979],
                [108.2005707, 13.3764751],
                [108.2007918, 13.3759597],
                [108.2010087, 13.3755274],
                [108.2012076, 13.3751909],
                [108.2013414, 13.3749622],
                [108.2015071, 13.3745869],
                [108.2016846, 13.3743587],
                [108.2019127, 13.374112],
                [108.2020193, 13.3740129],
                [108.2025386, 13.3736565],
                [108.2029163, 13.373445],
                [108.2032939, 13.3733225],
                [108.2036769, 13.3732931],
                [108.20436, 13.3734027],
                [108.2055814, 13.3739314],
                [108.2069844, 13.3748208],
                [108.2091499, 13.3765008],
                [108.2100657, 13.3740458],
                [108.2120713, 13.3703811],
                [108.2131432, 13.3682078],
                [108.213232, 13.3684047],
                [108.2139311, 13.3690464],
                [108.2159051, 13.3703978],
                [108.217934, 13.3715082],
                [108.2198534, 13.3727527],
                [108.2199868, 13.372798],
                [108.2201057, 13.3728065],
                [108.2201338, 13.3727431],
                [108.2201596, 13.3726438],
                [108.2202285, 13.3725166],
                [108.2203381, 13.3723574],
                [108.2204678, 13.3722115],
                [108.2207503, 13.3719649],
                [108.2210696, 13.3717315],
                [108.2213003, 13.3715932],
                [108.2214698, 13.3715054],
                [108.2215847, 13.3714747],
                [108.2217603, 13.3714318],
                [108.2220208, 13.371331],
                [108.2224611, 13.3711312],
                [108.2227251, 13.3710126],
                [108.2231582, 13.3708574],
                [108.2236244, 13.3707346],
                [108.2241309, 13.3706184],
                [108.2244415, 13.3705514],
                [108.2248395, 13.3704982],
                [108.225611, 13.3704811],
                [108.2260824, 13.3704862],
                [108.2268804, 13.3705141],
                [108.2272038, 13.3704986],
                [108.2275477, 13.3704639],
                [108.2278643, 13.3704674],
                [108.2284392, 13.3705505],
                [108.2286677, 13.3705979],
                [108.2291311, 13.370718],
                [108.2294601, 13.3708113],
                [108.2297986, 13.3709622],
                [108.2301808, 13.3711137],
                [108.2304485, 13.3712574],
                [108.2305958, 13.3713359],
                [108.2306631, 13.3713494],
                [108.2308918, 13.3713711],
                [108.2314192, 13.371492],
                [108.2317688, 13.3715535],
                [108.2319706, 13.3715813],
                [108.2321858, 13.3716029],
                [108.2324619, 13.3716188],
                [108.232657, 13.3716336],
                [108.2329361, 13.3716751],
                [108.2332391, 13.3716849],
                [108.2335684, 13.371746],
                [108.2337901, 13.3717997],
                [108.2340321, 13.3718408],
                [108.234278, 13.371837],
                [108.2346216, 13.371828],
                [108.234844, 13.371824],
                [108.2349993, 13.3717937],
                [108.2351544, 13.3717698],
                [108.2352825, 13.3717712],
                [108.2355113, 13.3717865],
                [108.2356492, 13.3717997],
                [108.2358581, 13.3717956],
                [108.2360464, 13.3718167],
                [108.2361607, 13.3718436],
                [108.2362543, 13.3719022],
                [108.2364216, 13.3719999],
                [108.2366557, 13.3721368],
                [108.2368968, 13.3722609],
                [108.2370403, 13.3723322],
                [108.2372786, 13.3724505],
                [108.237875, 13.3727131],
                [108.2381835, 13.3728251],
                [108.2383516, 13.3728398],
                [108.2385341, 13.3727842],
                [108.2387813, 13.3726589],
                [108.2388699, 13.3725702],
                [108.2389523, 13.372424],
                [108.239008, 13.372271],
                [108.2390777, 13.372067],
                [108.2391982, 13.3718379],
                [108.2392324, 13.3717807],
                [108.2393832, 13.371552],
                [108.2398234, 13.3710385],
                [108.2400818, 13.3708108],
                [108.2405234, 13.3704765],
                [108.2406974, 13.3702736],
                [108.2407994, 13.3701914],
                [108.2409497, 13.3700011],
                [108.2410596, 13.3698104],
                [108.2412485, 13.3694925],
                [108.2415016, 13.3691433],
                [108.2419005, 13.3687125],
                [108.2423149, 13.3683842],
                [108.2425519, 13.3682651],
                [108.2427211, 13.368203],
                [108.242856, 13.3681853],
                [108.242957, 13.3681864],
                [108.2430708, 13.3682388],
                [108.2432213, 13.36833],
                [108.2434484, 13.3684926],
                [108.2436425, 13.3686034],
                [108.2438369, 13.3686823],
                [108.243998, 13.3687289],
                [108.2441255, 13.3687623],
                [108.244546, 13.3687989],
                [108.2450031, 13.3688678],
                [108.2452519, 13.3689026],
                [108.2454739, 13.3689242],
                [108.2456752, 13.3689839],
                [108.2459402, 13.3690765],
                [108.2463419, 13.3692792],
                [108.2467435, 13.3695011],
                [108.2469578, 13.3696059],
                [108.2471259, 13.3696268],
                [108.2472741, 13.3696221],
                [108.2474023, 13.3695979],
                [108.2475345, 13.3695225],
                [108.2475957, 13.369472],
                [108.2478475, 13.3692444],
                [108.2483684, 13.3687306],
                [108.2488567, 13.3684289],
                [108.2491206, 13.3683232],
                [108.2492826, 13.3682866],
                [108.2494982, 13.3682761],
                [108.2497405, 13.3682851],
                [108.2499961, 13.3683134],
                [108.2502244, 13.3683735],
                [108.2503834, 13.3684258],
                [108.250947, 13.3688175],
                [108.2517962, 13.3692091],
                [108.252563, 13.3693978],
                [108.2529293, 13.3696222],
                [108.2530722, 13.3696638],
                [108.2532156, 13.3696654],
                [108.2535039, 13.3695483],
                [108.2539168, 13.3692721],
                [108.2540607, 13.3692336],
                [108.254286, 13.369236],
                [108.2544909, 13.3692383],
                [108.2548176, 13.369342],
                [108.255899, 13.3697547],
                [108.2561241, 13.3697772],
                [108.2564324, 13.3697003],
                [108.2567834, 13.3694636],
                [108.2573846, 13.3688487],
                [108.2575913, 13.3686906],
                [108.25792, 13.368614],
                [108.2581648, 13.3687168],
                [108.2583268, 13.3688789],
                [108.2584273, 13.3690604],
                [108.258423, 13.3694413],
                [108.2584403, 13.3697222],
                [108.2585006, 13.369823],
                [108.2586035, 13.369784],
                [108.2588717, 13.3696266],
                [108.2593137, 13.369501],
                [108.2597027, 13.3695254],
                [108.2604558, 13.3699745],
                [108.2611463, 13.3705232],
                [108.2617546, 13.3710911],
                [108.2620388, 13.3713347],
                [108.2627512, 13.3717634],
                [108.2634018, 13.3722115],
                [108.2636362, 13.3723243],
                [108.263637, 13.3723227],
                [108.2638491, 13.3726095],
                [108.2649082, 13.3719061],
                [108.2701619, 13.3683517],
                [108.2743424, 13.3657858],
                [108.277266, 13.3639725],
                [108.2795314, 13.362627],
                [108.2813207, 13.3612442],
                [108.2821227, 13.3604043],
                [108.2829661, 13.3592327],
                [108.2843407, 13.3578085],
                [108.2868246, 13.355068],
                [108.2872471, 13.3544083],
                [108.2874514, 13.3530085],
                [108.2878051, 13.3517578],
                [108.2881177, 13.3508019],
                [108.2885368, 13.3504374],
                [108.2888764, 13.3504411],
                [108.28989, 13.3509315],
                [108.2919191, 13.3517281],
                [108.2932373, 13.3519635],
                [108.2955401, 13.3519144],
                [108.2966932, 13.3517301],
                [108.2996124, 13.3505428],
                [108.3033261, 13.3491794],
                [108.3048412, 13.3486785],
                [108.3115071, 13.3463917],
                [108.3133072, 13.34587],
                [108.3135713, 13.3458729],
                [108.3141885, 13.3463778],
                [108.3149736, 13.3470508],
                [108.3155765, 13.3471311],
                [108.3161455, 13.3468785],
                [108.3174042, 13.3456734],
                [108.3182819, 13.3447965],
                [108.3189258, 13.3445818],
                [108.3195267, 13.3448466],
                [108.3206888, 13.3455605],
                [108.3211051, 13.3454541],
                [108.3224356, 13.344582],
                [108.3245087, 13.3431085],
                [108.3254558, 13.3427862],
                [108.3273416, 13.3428799],
                [108.3284364, 13.3428545],
                [108.328895, 13.3423424],
                [108.3303086, 13.3407696],
                [108.3309209, 13.3400007],
                [108.3316025, 13.3397863],
                [108.3329062, 13.3396338],
                [108.3344213, 13.3391329],
                [108.336091, 13.3383011],
                [108.337077, 13.3378684],
                [108.3377541, 13.3380601],
                [108.3386545, 13.3385497],
                [108.3394458, 13.3386687],
                [108.3406593, 13.3381276],
                [108.3425278, 13.3380918],
                [108.3440376, 13.3380707],
                [108.3452379, 13.3387479],
                [108.3461768, 13.3391639],
                [108.3466284, 13.3392793],
                [108.347007, 13.3391726],
                [108.3471632, 13.3386942],
                [108.3466923, 13.3368799],
                [108.3460694, 13.3351749],
                [108.3457926, 13.3328827],
                [108.3457538, 13.3324046],
                [108.3457681, 13.3321928],
                [108.3457776, 13.3320529],
                [108.3458431, 13.3319724],
                [108.3459196, 13.3318784],
                [108.3462801, 13.3318235],
                [108.3471799, 13.331833],
                [108.3480601, 13.3318031],
                [108.3491214, 13.3316774],
                [108.3501046, 13.3313748],
                [108.3508898, 13.3308942],
                [108.3516145, 13.330452],
                [108.3522577, 13.3301458],
                [108.3528821, 13.3297418],
                [108.3533282, 13.3291795],
                [108.3537157, 13.3284796],
                [108.3538758, 13.3275553],
                [108.3539232, 13.326871],
                [108.3539887, 13.3263629],
                [108.3538734, 13.3259313],
                [108.3534803, 13.3253011],
                [108.3530191, 13.3245038],
                [108.3528038, 13.3240711],
                [108.3527281, 13.3236789],
                [108.3528523, 13.3232889],
                [108.3534408, 13.3225124],
                [108.3541107, 13.3215801],
                [108.3547345, 13.3203051],
                [108.3549653, 13.3193096],
                [108.3552793, 13.3180214],
                [108.3555275, 13.3163315],
                [108.3555914, 13.31598],
                [108.3557678, 13.3154176],
                [108.3559032, 13.3152593],
                [108.35618, 13.3151839],
                [108.3575899, 13.3151692],
                [108.3587092, 13.3152199],
                [108.3601085, 13.3152541],
                [108.3610891, 13.315186],
                [108.3617203, 13.3150654],
                [108.3620826, 13.3148539],
                [108.3625077, 13.3143692],
                [108.3629131, 13.3138647],
                [108.3630998, 13.3132404],
                [108.3635195, 13.3114251],
                [108.3637156, 13.3108499],
                [108.3639389, 13.3105392],
                [108.3641804, 13.3104046],
                [108.3653201, 13.3104165],
                [108.3664805, 13.3103503],
                [108.3668813, 13.3102762],
                [108.3672641, 13.3100062],
                [108.3677093, 13.3095217],
                [108.3681184, 13.308665],
                [108.3682555, 13.3080109],
                [108.3685013, 13.3074656],
                [108.3692088, 13.3067684],
                [108.3701782, 13.3058784],
                [108.3710704, 13.3047332],
                [108.3716291, 13.3039075],
                [108.3721389, 13.3029931],
                [108.3724639, 13.3025268],
                [108.3724856, 13.3023704],
                [108.3724075, 13.3021935],
                [108.3721307, 13.3018972],
                [108.371843, 13.3016888],
                [108.3714638, 13.3016261],
                [108.3709427, 13.3017382],
                [108.3705603, 13.301969],
                [108.3700583, 13.3021595],
                [108.3694589, 13.3021142],
                [108.3692003, 13.301994],
                [108.3690231, 13.3017378],
                [108.3689672, 13.3013655],
                [108.3689934, 13.3007787],
                [108.3693022, 13.2999601],
                [108.3700548, 13.2987744],
                [108.37033, 13.2982978],
                [108.3704941, 13.2979082],
                [108.3704775, 13.2975949],
                [108.3702614, 13.2972405],
                [108.3694103, 13.2964294],
                [108.3687972, 13.295797],
                [108.3686503, 13.2955312],
                [108.3685932, 13.2952567],
                [108.3687993, 13.2946914],
                [108.3697356, 13.2931554],
                [108.3707313, 13.2916786],
                [108.3723638, 13.2895528],
                [108.3728909, 13.2888734],
                [108.3731546, 13.288524],
                [108.3732679, 13.2882219],
                [108.3732706, 13.2879675],
                [108.3729204, 13.2870638],
                [108.3728469, 13.2864565],
                [108.3728967, 13.2855569],
                [108.3729687, 13.2835129],
                [108.3729543, 13.2829844],
                [108.3729185, 13.2825927],
                [108.3730553, 13.2819582],
                [108.3734057, 13.2809834],
                [108.3737365, 13.2799694],
                [108.373801, 13.2795592],
                [108.3737334, 13.2793334],
                [108.3733572, 13.2789969],
                [108.3728626, 13.2785026],
                [108.3726846, 13.2783247],
                [108.3726074, 13.2780695],
                [108.3726886, 13.2779529],
                [108.373072, 13.2776243],
                [108.373456, 13.2772369],
                [108.3736595, 13.2769063],
                [108.3738858, 13.2763216],
                [108.3740017, 13.2757652],
                [108.3739646, 13.2754909],
                [108.3737704, 13.2749605],
                [108.3734967, 13.2743904],
                [108.373382, 13.2738999],
                [108.3731564, 13.2725671],
                [108.3723801, 13.2694577],
                [108.3721636, 13.2682129],
                [108.3720884, 13.2677621],
                [108.3722326, 13.2673722],
                [108.372576, 13.2670432],
                [108.3730375, 13.2668914],
                [108.3743167, 13.2669242],
                [108.3758936, 13.2671556],
                [108.376414, 13.2671022],
                [108.376936, 13.2668924],
                [108.3786822, 13.2653353],
                [108.3805013, 13.2634951],
                [108.3833879, 13.2599733],
                [108.3838018, 13.259596],
                [108.3842651, 13.2592681],
                [108.3850502, 13.2587674],
                [108.3872036, 13.2574393],
                [108.3878058, 13.2572108],
                [108.3885881, 13.2569644],
                [108.3906597, 13.2567411],
                [108.391801, 13.2565767],
                [108.3929848, 13.2561777],
                [108.3937671, 13.2559314],
                [108.3946785, 13.2557743],
                [108.3955395, 13.2556462],
                [108.3965935, 13.2555278],
                [108.397339, 13.2555223],
                [108.3978707, 13.25558],
                [108.3985352, 13.2556779],
                [108.3988138, 13.2557589],
                [108.3992245, 13.2559455],
                [108.3994226, 13.2560908],
                [108.39954, 13.2563135],
                [108.3995579, 13.2565091],
                [108.399644, 13.2571745],
                [108.3996931, 13.2575528],
                [108.3998785, 13.2576459],
                [108.4001977, 13.2576753],
                [108.4009572, 13.2575917],
                [108.4013564, 13.2575958],
                [108.4017407, 13.257756],
                [108.401991, 13.2580061],
                [108.4021614, 13.2582555],
                [108.4023579, 13.2585572],
                [108.4026735, 13.2589121],
                [108.4030757, 13.2592654],
                [108.4034474, 13.2593603],
                [108.4039236, 13.2596386],
                [108.4044255, 13.2600082],
                [108.4049278, 13.2603387],
                [108.4052064, 13.2604196],
                [108.4052863, 13.2604204],
                [108.4054331, 13.2603829],
                [108.4055271, 13.2603057],
                [108.405675, 13.2601641],
                [108.4059443, 13.2598674],
                [108.4065521, 13.2590405],
                [108.4068765, 13.2585751],
                [108.4070239, 13.2584724],
                [108.4071177, 13.2584213],
                [108.4073175, 13.2584104],
                [108.4076106, 13.2583743],
                [108.4078381, 13.2582594],
                [108.4079593, 13.2581305],
                [108.4080952, 13.2578716],
                [108.4082597, 13.2574176],
                [108.4083025, 13.2571447],
                [108.4083735, 13.2567288],
                [108.4086037, 13.2563537],
                [108.4087781, 13.2562252],
                [108.4088852, 13.2561612],
                [108.4090188, 13.2561235],
                [108.4092439, 13.2561159],
                [108.4092441, 13.2561149],
                [108.4094118, 13.2561201],
                [108.4094759, 13.2557296],
                [108.4096229, 13.255066],
                [108.4097899, 13.2544029],
                [108.4099352, 13.2538958],
                [108.4100995, 13.2534868],
                [108.4102034, 13.2531163],
                [108.4102671, 13.2527648],
                [108.4103134, 13.2521786],
                [108.4102842, 13.2511614],
                [108.4104469, 13.2489711],
                [108.4104484, 13.2489711],
                [108.4104641, 13.2489156],
                [108.4104986, 13.2488049],
                [108.4105848, 13.2485103],
                [108.4106745, 13.2482711],
                [108.4107815, 13.2481124],
                [108.4109103, 13.2479835],
                [108.4110415, 13.2478818],
                [108.4111726, 13.247791],
                [108.411408, 13.2476754],
                [108.4119983, 13.2473832],
                [108.4128707, 13.2470003],
                [108.4133742, 13.2466679],
                [108.4135221, 13.2465663],
                [108.4139461, 13.2462832],
                [108.4142629, 13.2460601],
                [108.4143857, 13.245969],
                [108.415146, 13.2454549],
                [108.4160152, 13.2448469],
                [108.4163139, 13.244633],
                [108.4168373, 13.2442466],
                [108.4171529, 13.2440004],
                [108.4175289, 13.2436693],
                [108.417694, 13.2435219],
                [108.4178837, 13.2433909],
                [108.4180509, 13.2432951],
                [108.4181622, 13.2432473],
                [108.4183429, 13.2431896],
                [108.4185554, 13.2431307],
                [108.4188917, 13.2430093],
                [108.4191475, 13.2429279],
                [108.4193255, 13.2428538],
                [108.4194995, 13.2427755],
                [108.4196749, 13.2426906],
                [108.4198953, 13.2425411],
                [108.4200658, 13.2423936],
                [108.4202915, 13.2421398],
                [108.4204035, 13.2420243],
                [108.4205159, 13.2418627],
                [108.4207537, 13.2415059],
                [108.4208044, 13.2414278],
                [108.4209593, 13.2411853],
                [108.4211164, 13.2408652],
                [108.421199, 13.2406574],
                [108.4212786, 13.2404605],
                [108.4213747, 13.2402826],
                [108.4214168, 13.2401973],
                [108.4212238, 13.2399209],
                [108.4210159, 13.2397522],
                [108.42052, 13.239555],
                [108.4201143, 13.2395253],
                [108.4198001, 13.2394836],
                [108.4193304, 13.2392995],
                [108.4190436, 13.2391556],
                [108.418509, 13.2389067],
                [108.4183146, 13.2387125],
                [108.4181605, 13.2384162],
                [108.4180085, 13.2379148],
                [108.4178828, 13.2374137],
                [108.4177394, 13.2367157],
                [108.4176664, 13.236189],
                [108.4175947, 13.2355343],
                [108.4175484, 13.2343346],
                [108.4175142, 13.2338469],
                [108.4175306, 13.2335391],
                [108.4175925, 13.2332512],
                [108.417779, 13.2329709],
                [108.4181897, 13.232539],
                [108.4187314, 13.2321084],
                [108.4191273, 13.2318303],
                [108.4195245, 13.2314495],
                [108.4197576, 13.2310863],
                [108.4199451, 13.2307033],
                [108.4200695, 13.230089],
                [108.4201287, 13.2294355],
                [108.4201677, 13.2288395],
                [108.42021, 13.2285706],
                [108.4203579, 13.2282],
                [108.420491, 13.228009],
                [108.4206231, 13.2279077],
                [108.4209919, 13.227719],
                [108.421636, 13.2275204],
                [108.4219845, 13.227402],
                [108.4221957, 13.2272502],
                [108.4222757, 13.2271228],
                [108.4222783, 13.2268663],
                [108.4221361, 13.2266853],
                [108.4218502, 13.2264643],
                [108.4209511, 13.2259936],
                [108.4199067, 13.2256367],
                [108.4190661, 13.2252113],
                [108.4185072, 13.2247824],
                [108.4181822, 13.2245355],
                [108.4178719, 13.2241476],
                [108.4175487, 13.2237338],
                [108.417298, 13.223276],
                [108.4170158, 13.2227088],
                [108.4168744, 13.2224508],
                [108.4166799, 13.2222565],
                [108.4165115, 13.2220752],
                [108.4163691, 13.2219071],
                [108.416279, 13.2217521],
                [108.4162482, 13.2215659],
                [108.4162921, 13.2211302],
                [108.4163864, 13.2208875],
                [108.4166284, 13.2203],
                [108.4168547, 13.219956],
                [108.4170023, 13.2196368],
                [108.4170634, 13.2194259],
                [108.4171059, 13.2191184],
                [108.4171086, 13.2188748],
                [108.4170977, 13.2186566],
                [108.4171, 13.2184385],
                [108.4172208, 13.2181704],
                [108.4175656, 13.2177635],
                [108.4178237, 13.2175159],
                [108.4180091, 13.2173383],
                [108.4181285, 13.2171985],
                [108.4181956, 13.2170452],
                [108.4182503, 13.2168278],
                [108.4182272, 13.2165326],
                [108.4181516, 13.2162368],
                [108.4178562, 13.2156823],
                [108.4176844, 13.2151995],
                [108.4176606, 13.2149684],
                [108.4176915, 13.2145198],
                [108.4177873, 13.214136],
                [108.4180014, 13.2137021],
                [108.4182879, 13.2132496],
                [108.4185135, 13.2129826],
                [108.4189877, 13.2127308],
                [108.4193688, 13.2126193],
                [108.4196314, 13.2125706],
                [108.4197235, 13.2125331],
                [108.4198165, 13.2124186],
                [108.4198577, 13.2122394],
                [108.4198854, 13.2120858],
                [108.4198874, 13.2119062],
                [108.4199018, 13.2117781],
                [108.4200672, 13.2116194],
                [108.420186, 13.2115309],
                [108.4204219, 13.2115332],
                [108.4206309, 13.2115867],
                [108.420945, 13.2116283],
                [108.4211413, 13.2116431],
                [108.4212199, 13.211644],
                [108.4213913, 13.211543],
                [108.4215116, 13.2113134],
                [108.4215798, 13.2110575],
                [108.4216345, 13.2108401],
                [108.4216403, 13.2102886],
                [108.4216429, 13.2100322],
                [108.4215737, 13.2097685],
                [108.4214569, 13.2096519],
                [108.4212746, 13.2095474],
                [108.4211448, 13.2094435],
                [108.4210537, 13.2093784],
                [108.4210157, 13.2092498],
                [108.4211096, 13.2090455],
                [108.4213753, 13.2087019],
                [108.4217055, 13.208436],
                [108.421956, 13.2082974],
                [108.4220618, 13.2081958],
                [108.4222074, 13.2080562],
                [108.4222741, 13.2079414],
                [108.422362, 13.2076793],
                [108.4223649, 13.20741],
                [108.4223135, 13.2073069],
                [108.4222105, 13.2071392],
                [108.4221206, 13.2069715],
                [108.4220306, 13.2068038],
                [108.421967, 13.2066236],
                [108.4219299, 13.2064181],
                [108.4219461, 13.2061232],
                [108.4220536, 13.2058549],
                [108.4222594, 13.2056069],
                [108.422378, 13.2055312],
                [108.4227322, 13.2054963],
                [108.422903, 13.2054467],
                [108.4230484, 13.2053199],
                [108.4231283, 13.2051924],
                [108.4231828, 13.2050006],
                [108.4232109, 13.2048213],
                [108.4231093, 13.2045125],
                [108.422812, 13.2041247],
                [108.4225532, 13.203827],
                [108.4223414, 13.2034081],
                [108.4222931, 13.20301],
                [108.4222552, 13.2028814],
                [108.4222442, 13.2026888],
                [108.4223204, 13.2022856],
                [108.4225926, 13.2013135],
                [108.4228364, 13.2005593],
                [108.4230667, 13.1998305],
                [108.4232242, 13.1991843],
                [108.4232534, 13.1988897],
                [108.4232953, 13.1986464],
                [108.4233764, 13.1984163],
                [108.4234305, 13.1982373],
                [108.4235115, 13.1980201],
                [108.4235647, 13.1979437],
                [108.4238635, 13.1975555],
                [108.4242195, 13.197341],
                [108.4247466, 13.1970514],
                [108.424984, 13.1968998],
                [108.4252881, 13.1966206],
                [108.425661, 13.1960472],
                [108.4261198, 13.1953914],
                [108.4262804, 13.1950722],
                [108.4265338, 13.1946516],
                [108.4266284, 13.1943704],
                [108.4266833, 13.1941272],
                [108.4266896, 13.193756],
                [108.4266584, 13.1935249],
                [108.4266327, 13.193325],
                [108.4266373, 13.192889],
                [108.4267311, 13.1926847],
                [108.4268904, 13.192494],
                [108.4270499, 13.1922647],
                [108.4276649, 13.1917129],
                [108.4281267, 13.1913969],
                [108.428483, 13.1911569],
                [108.4287983, 13.1908646],
                [108.4291971, 13.1903411],
                [108.4295652, 13.1897633],
                [108.4300188, 13.1889854],
                [108.4304338, 13.1881302],
                [108.4312268, 13.1868363],
                [108.4318506, 13.1860601],
                [108.4325998, 13.1852018],
                [108.4330106, 13.1847443],
                [108.4334209, 13.1843508],
                [108.4336064, 13.1841475],
                [108.434115, 13.1837356],
                [108.4346814, 13.1834463],
                [108.4350371, 13.1832446],
                [108.4354519, 13.1829387],
                [108.4357472, 13.1826133],
                [108.4360433, 13.1822022],
                [108.4362377, 13.1817474],
                [108.4364486, 13.1811071],
                [108.436584, 13.180723],
                [108.4366887, 13.1804671],
                [108.4368825, 13.1802453],
                [108.4369029, 13.1802219],
                [108.4371526, 13.1800673],
                [108.4377669, 13.1799306],
                [108.4380591, 13.1798907],
                [108.4384829, 13.1798234],
                [108.4387318, 13.1797402],
                [108.4392142, 13.17964],
                [108.439697, 13.1795356],
                [108.4400919, 13.1794538],
                [108.4404307, 13.1793115],
                [108.4408622, 13.1790399],
                [108.4413184, 13.1786731],
                [108.4419084, 13.1780646],
                [108.4424105, 13.1774839],
                [108.442972, 13.1768037],
                [108.443556, 13.1760738],
                [108.4437629, 13.1758186],
                [108.4441312, 13.1754795],
                [108.4445169, 13.1752357],
                [108.4451042, 13.1748844],
                [108.4455109, 13.1746932],
                [108.4459417, 13.1745995],
                [108.4463917, 13.1745161],
                [108.4468942, 13.1742712],
                [108.4475561, 13.1737634],
                [108.4480276, 13.1733109],
                [108.4485153, 13.17273],
                [108.4492227, 13.1720513],
                [108.4506271, 13.1709866],
                [108.4514308, 13.1703376],
                [108.4520771, 13.1698919],
                [108.4535124, 13.1687876],
                [108.4542538, 13.1680866],
                [108.4548576, 13.1675496],
                [108.4556444, 13.1669502],
                [108.4562616, 13.1665277],
                [108.4563699, 13.1664176],
                [108.4564426, 13.1663017],
                [108.4565005, 13.1660614],
                [108.4565579, 13.1658908],
                [108.4566308, 13.1657517],
                [108.4567031, 13.1656669],
                [108.4567991, 13.1656057],
                [108.4569104, 13.1655913],
                [108.4571481, 13.165617],
                [108.4574803, 13.1657445],
                [108.4575671, 13.1657921],
                [108.4578763, 13.165834],
                [108.458416, 13.1658472],
                [108.4590156, 13.1658258],
                [108.4593735, 13.1657594],
                [108.4598995, 13.1655548],
                [108.4602356, 13.1653016],
                [108.4605884, 13.1649553],
                [108.460971, 13.1644189],
                [108.4611717, 13.1641706],
                [108.4613565, 13.1639874],
                [108.4617397, 13.1637813],
                [108.4623224, 13.1634595],
                [108.4630142, 13.162966],
                [108.4634868, 13.1625666],
                [108.4637637, 13.1622623],
                [108.4638605, 13.1621156],
                [108.46391, 13.1619295],
                [108.463905, 13.1616651],
                [108.4638753, 13.1614472],
                [108.4637828, 13.161182],
                [108.4637676, 13.1611534],
                [108.4633784, 13.1608797],
                [108.4629539, 13.1605364],
                [108.4626105, 13.1602339],
                [108.4623484, 13.1599521],
                [108.4621275, 13.1596308],
                [108.4619278, 13.15923],
                [108.4617896, 13.1587899],
                [108.4617766, 13.1580717],
                [108.4617501, 13.1576858],
                [108.4616308, 13.1573852],
                [108.4612887, 13.1569627],
                [108.4609665, 13.1565804],
                [108.4608057, 13.1563593],
                [108.4606455, 13.1560782],
                [108.4604676, 13.1555376],
                [108.4604528, 13.1549986],
                [108.4604973, 13.1546398],
                [108.4606669, 13.1540028],
                [108.4607534, 13.1535246],
                [108.4609946, 13.1528585],
                [108.4611652, 13.1521217],
                [108.4612295, 13.1518229],
                [108.4614187, 13.151266],
                [108.4616056, 13.1509285],
                [108.4618736, 13.1506319],
                [108.4623462, 13.1502572],
                [108.4628585, 13.1500029],
                [108.463453, 13.1496694],
                [108.4639257, 13.149295],
                [108.4643764, 13.1490192],
                [108.4648689, 13.1487048],
                [108.4653206, 13.1483901],
                [108.4656553, 13.1481749],
                [108.4659481, 13.1480304],
                [108.4659078, 13.1479172],
                [108.4657769, 13.1475355],
                [108.4657388, 13.1473836],
                [108.4652099, 13.1455943],
                [108.4648613, 13.1443222],
                [108.464487, 13.1426369],
                [108.4643731, 13.1419868],
                [108.4643463, 13.141662],
                [108.4643795, 13.1413673],
                [108.4645329, 13.1411034],
                [108.4648061, 13.1408995],
                [108.4656231, 13.1405535],
                [108.4665597, 13.1402972],
                [108.4673755, 13.1400693],
                [108.4679178, 13.1400746],
                [108.4683387, 13.1401672],
                [108.468818, 13.1404374],
                [108.4691764, 13.140736],
                [108.469415, 13.1409744],
                [108.4695949, 13.1410646],
                [108.4698057, 13.1410667],
                [108.4699269, 13.1410089],
                [108.4699584, 13.1408618],
                [108.4699013, 13.1405661],
                [108.469638, 13.1397964],
                [108.4690765, 13.1387289],
                [108.4684724, 13.1374101],
                [108.4681181, 13.1366986],
                [108.4677609, 13.136282],
                [108.4672846, 13.1357168],
                [108.4668641, 13.1355945],
                [108.4659627, 13.1353497],
                [108.4641282, 13.1350366],
                [108.4625645, 13.1347556],
                [108.4614821, 13.1345384],
                [108.4607004, 13.1343832],
                [108.4602993, 13.1341424],
                [108.4602233, 13.1339065],
                [108.4601398, 13.1332272],
                [108.4601824, 13.132018],
                [108.4601431, 13.1314422],
                [108.4600256, 13.1311462],
                [108.4598488, 13.1307608],
                [108.4594624, 13.1302555],
                [108.4590714, 13.1297987],
                [108.4585072, 13.1293905],
                [108.4580003, 13.1288839],
                [108.4574939, 13.1283185],
                [108.4572265, 13.1279617],
                [108.4570196, 13.1275761],
                [108.4569918, 13.1273399],
                [108.4569943, 13.1271039],
                [108.4570278, 13.1267797],
                [108.4572122, 13.1264274],
                [108.4577908, 13.1258136],
                [108.4586407, 13.1252025],
                [108.4591419, 13.1248092],
                [108.4594154, 13.1245758],
                [108.4595699, 13.1241938],
                [108.4597874, 13.123547],
                [108.4601279, 13.1226653],
                [108.4604987, 13.1217544],
                [108.4606834, 13.1213727],
                [108.4608374, 13.1210497],
                [108.4609013, 13.1206962],
                [108.4611182, 13.1201084],
                [108.461393, 13.1197571],
                [108.462093, 13.1190559],
                [108.4637512, 13.1174644],
                [108.4641459, 13.1171733],
                [108.4644793, 13.11697],
                [108.4648718, 13.1168854],
                [108.4652938, 13.11686],
                [108.4663751, 13.1171657],
                [108.4670966, 13.1173203],
                [108.4688986, 13.1178691],
                [108.4701908, 13.1181769],
                [108.4712138, 13.1183049],
                [108.4718156, 13.1183698],
                [108.4721168, 13.1183727],
                [108.4725994, 13.1183185],
                [108.4730993, 13.1180431],
                [108.4734629, 13.1178402],
                [108.4743757, 13.1169641],
                [108.4750778, 13.1160564],
                [108.475716, 13.1155021],
                [108.4762326, 13.1150646],
                [108.4770815, 13.1145419],
                [108.4786244, 13.1139079],
                [108.4788972, 13.1137337],
                [108.4794416, 13.1135324],
                [108.4799549, 13.1134194],
                [108.4805278, 13.113366],
                [108.4810712, 13.1132533],
                [108.4830023, 13.1129477],
                [108.4849195, 13.1125387],
                [108.4858867, 13.1122236],
                [108.4866439, 13.1118179],
                [108.487372, 13.1113235],
                [108.4877065, 13.1110022],
                [108.4891352, 13.1097476],
                [108.4900169, 13.1089596],
                [108.4907753, 13.1084359],
                [108.4908094, 13.1083995],
                [108.4913234, 13.1078512],
                [108.4922364, 13.1069455],
                [108.4925113, 13.1065646],
                [108.4928338, 13.105963],
                [108.4928985, 13.1055211],
                [108.4929027, 13.1051081],
                [108.4928484, 13.1045175],
                [108.4925568, 13.1035705],
                [108.4921706, 13.1030358],
                [108.4916943, 13.1024706],
                [108.4912463, 13.1020827],
                [108.4904984, 13.1015739],
                [108.4899604, 13.1011556],
                [108.4894526, 13.1007375],
                [108.4891848, 13.1004105],
                [108.488917, 13.1000833],
                [108.4885337, 13.0992534],
                [108.4884461, 13.0989871],
                [108.4884491, 13.0986921],
                [108.4886937, 13.0983405],
                [108.4894192, 13.098082],
                [108.4909909, 13.0975663],
                [108.4938053, 13.0962955],
                [108.4948043, 13.0958037],
                [108.4955922, 13.0953393],
                [108.4960181, 13.0949304],
                [108.4966875, 13.0942583],
                [108.4971465, 13.0935546],
                [108.4976666, 13.0927632],
                [108.4987101, 13.0908556],
                [108.4996113, 13.0896106],
                [108.4998871, 13.0891412],
                [108.5000428, 13.088641],
                [108.5000484, 13.0880806],
                [108.4999306, 13.0878139],
                [108.4993377, 13.0868641],
                [108.4991636, 13.0862134],
                [108.4991677, 13.0858004],
                [108.4992605, 13.0855652],
                [108.4994146, 13.0852126],
                [108.4999046, 13.0844208],
                [108.5004877, 13.0841233],
                [108.5017931, 13.0839616],
                [108.5024556, 13.0838379],
                [108.5032576, 13.0836592],
                [108.503621, 13.0835135],
                [108.5038703, 13.0833481],
                [108.5040634, 13.083089],
                [108.5043163, 13.0825694],
                [108.5047227, 13.0819394],
                [108.5049918, 13.0816996],
                [108.5052217, 13.0815713],
                [108.5056802, 13.0814452],
                [108.5065968, 13.0812303],
                [108.5072459, 13.0810874],
                [108.5079722, 13.0808521],
                [108.5086232, 13.0805228],
                [108.5097751, 13.0796389],
                [108.5102667, 13.0790593],
                [108.5108081, 13.0782622],
                [108.5112156, 13.0775197],
                [108.5115251, 13.0770562],
                [108.5119103, 13.0766308],
                [108.5128306, 13.0760425],
                [108.513129, 13.0757374],
                [108.5133238, 13.0753102],
                [108.5135796, 13.0744917],
                [108.5144294, 13.0723821],
                [108.5145849, 13.0720664],
                [108.5147392, 13.0718813],
                [108.5148929, 13.0717521],
                [108.5150842, 13.0716794],
                [108.5152556, 13.071681],
                [108.5153697, 13.0717008],
                [108.5154826, 13.0718325],
                [108.5155572, 13.0720011],
                [108.5156111, 13.0723188],
                [108.5156471, 13.0725245],
                [108.5157592, 13.0727494],
                [108.5158907, 13.0729373],
                [108.5160227, 13.0730691],
                [108.5164396, 13.0732971],
                [108.5168011, 13.0733379],
                [108.5172582, 13.0733423],
                [108.5174877, 13.0732511],
                [108.5176797, 13.0731038],
                [108.5177764, 13.0729554],
                [108.5178928, 13.0727513],
                [108.5180278, 13.0725847],
                [108.5182593, 13.0722883],
                [108.5184325, 13.0721221],
                [108.5188939, 13.0716973],
                [108.5191642, 13.0713268],
                [108.5192043, 13.0711406],
                [108.519225, 13.0709728],
                [108.5191891, 13.0707486],
                [108.5179939, 13.0683487],
                [108.5178922, 13.0680399],
                [108.5178192, 13.0677219],
                [108.5178409, 13.067461],
                [108.5179383, 13.067238],
                [108.5181118, 13.0670344],
                [108.5206111, 13.0647446],
                [108.5211408, 13.064162],
                [108.5212954, 13.0639395],
                [108.5213546, 13.0637349],
                [108.5213567, 13.0635297],
                [108.5213586, 13.0633431],
                [108.5212084, 13.0631178],
                [108.5210393, 13.0628736],
                [108.5202657, 13.0621384],
                [108.518696, 13.0610038],
                [108.5183265, 13.0608043],
                [108.5178322, 13.0607064],
                [108.5173758, 13.0606273],
                [108.5170719, 13.0605497],
                [108.5168257, 13.060398],
                [108.5166939, 13.0602476],
                [108.5165819, 13.0600226],
                [108.5165467, 13.0597237],
                [108.5165171, 13.0588838],
                [108.5161316, 13.0574247],
                [108.5152619, 13.055821],
                [108.5149068, 13.0551271],
                [108.5146583, 13.0542758],
                [108.5145116, 13.0531857],
                [108.5142584, 13.0523501],
                [108.5137377, 13.0510949],
                [108.5128253, 13.0489963],
                [108.5122174, 13.0478803],
                [108.5117112, 13.0470731],
                [108.5112327, 13.0463501],
                [108.5111392, 13.0461813],
                [108.511103, 13.0459944],
                [108.5111044, 13.0458451],
                [108.5111257, 13.0456214],
                [108.5111864, 13.0452675],
                [108.5115356, 13.0446364],
                [108.513222, 13.0417045],
                [108.5135427, 13.0410639],
                [108.5136788, 13.0407852],
                [108.5137386, 13.0405247],
                [108.5137412, 13.0402633],
                [108.5137246, 13.0400207],
                [108.5136691, 13.0398522],
                [108.5134805, 13.0396638],
                [108.5131786, 13.0393811],
                [108.5123088, 13.0387383],
                [108.5112671, 13.0381498],
                [108.511031, 13.0379516],
                [108.5108428, 13.0377259],
                [108.5107308, 13.037501],
                [108.5107329, 13.0372957],
                [108.5107534, 13.0371466],
                [108.5108887, 13.0369427],
                [108.5124108, 13.0352034],
                [108.513186, 13.0344325],
                [108.5135018, 13.0342396],
                [108.5137503, 13.0341552],
                [108.5142551, 13.0340106],
                [108.5147687, 13.0338991],
                [108.5152888, 13.0338211],
                [108.5155578, 13.0337941],
                [108.5158276, 13.0337762],
                [108.5160979, 13.0337673],
                [108.5166386, 13.0337764],
                [108.5169084, 13.0337945],
                [108.5174453, 13.0338576],
                [108.5175391, 13.0338891],
                [108.5177332, 13.0339281],
                [108.5179313, 13.0339346],
                [108.5181325, 13.0339073],
                [108.5182329, 13.0338803],
                [108.5183304, 13.0338446],
                [108.5184243, 13.0338006],
                [108.5185138, 13.0337486],
                [108.5185981, 13.0336891],
                [108.5188631, 13.0334482],
                [108.5189833, 13.033354],
                [108.5191092, 13.033267],
                [108.5192403, 13.0331877],
                [108.519376, 13.0331162],
                [108.519516, 13.0330528],
                [108.519782, 13.0329585],
                [108.5200571, 13.0328934],
                [108.520338, 13.0328585],
                [108.5206211, 13.0328542],
                [108.5211818, 13.0328168],
                [108.5217438, 13.0328141],
                [108.5223049, 13.0328461],
                [108.5228695, 13.0329137],
                [108.5231532, 13.0329614],
                [108.5234351, 13.033018],
                [108.5237149, 13.0330834],
                [108.5239925, 13.0331577],
                [108.5242674, 13.0332408],
                [108.5245394, 13.0333325],
                [108.5248082, 13.0334327],
                [108.5250735, 13.0335415],
                [108.5253284, 13.0336011],
                [108.525458, 13.0336205],
                [108.5257094, 13.033638],
                [108.5258303, 13.0336371],
                [108.5261977, 13.0336113],
                [108.5263242, 13.033613],
                [108.5265757, 13.0336399],
                [108.5268217, 13.0336973],
                [108.5270584, 13.0337845],
                [108.5279489, 13.0340353],
                [108.5281603, 13.0340727],
                [108.5283732, 13.0341009],
                [108.5285872, 13.0341198],
                [108.5288018, 13.0341294],
                [108.5290156, 13.0341297],
                [108.5292292, 13.0341208],
                [108.5294421, 13.0341027],
                [108.529654, 13.0340754],
                [108.5298645, 13.034039],
                [108.5300732, 13.0339935],
                [108.5302264, 13.0339493],
                [108.5303768, 13.0338964],
                [108.5305238, 13.0338351],
                [108.5306668, 13.0337656],
                [108.5308055, 13.0336881],
                [108.5309393, 13.0336028],
                [108.5310677, 13.0335101],
                [108.5318756, 13.0328229],
                [108.536253, 13.0326661],
                [108.5400081, 13.0313804],
                [108.5429263, 13.0296557],
                [108.5681713, 13.0197412],
                [108.5683922, 13.0196929],
                [108.568505, 13.0196821],
                [108.5687313, 13.0196874],
                [108.5688434, 13.0197035],
                [108.5690617, 13.0197621],
                [108.5692557, 13.0198482],
                [108.5693471, 13.0199018],
                [108.5695158, 13.0200287],
                [108.5696625, 13.0201793],
                [108.5697266, 13.0202624],
                [108.5698342, 13.0204416],
                [108.5704136, 13.0216959],
                [108.5707468, 13.021938],
                [108.5714006, 13.0224381],
                [108.5720417, 13.0229626],
                [108.5724461, 13.0233147],
                [108.5725423, 13.0233907],
                [108.5726436, 13.02346],
                [108.5728598, 13.0235775],
                [108.572982, 13.0236283],
                [108.5731078, 13.0236702],
                [108.5732364, 13.0237029],
                [108.5733672, 13.0237264],
                [108.5737271, 13.0237524],
                [108.5738212, 13.0237668],
                [108.5740049, 13.0238153],
                [108.5741879, 13.0238937],
                [108.5742782, 13.0239454],
                [108.5744448, 13.0240689],
                [108.5745199, 13.0241401],
                [108.5746515, 13.0242987],
                [108.5754776, 13.0254224],
                [108.5756062, 13.0256435],
                [108.5756611, 13.0257588],
                [108.5757512, 13.0259973],
                [108.5758152, 13.0262491],
                [108.5758502, 13.0265062],
                [108.5758558, 13.0267656],
                [108.5762404, 13.0269921],
                [108.5764267, 13.0271146],
                [108.5767865, 13.0273771],
                [108.5771066, 13.0276433],
                [108.5772602, 13.0277835],
                [108.5775536, 13.0280774],
                [108.5787284, 13.0285112],
                [108.5788483, 13.0285416],
                [108.5790926, 13.0285804],
                [108.57934, 13.0285896],
                [108.5796088, 13.0285655],
                [108.5798719, 13.0285065],
                [108.5799998, 13.0284641],
                [108.5804237, 13.0283926],
                [108.5806376, 13.0283695],
                [108.5810673, 13.0283491],
                [108.581291, 13.0283521],
                [108.5815144, 13.0283644],
                [108.5817371, 13.0283859],
                [108.5819586, 13.0284166],
                [108.5821786, 13.0284565],
                [108.5823966, 13.0285055],
                [108.5826123, 13.0285634],
                [108.5827688, 13.0286299],
                [108.5830911, 13.0287398],
                [108.5834274, 13.0288189],
                [108.5837747, 13.0288653],
                [108.5839497, 13.0288755],
                [108.5844054, 13.0288782],
                [108.5846843, 13.0289057],
                [108.5849748, 13.0289632],
                [108.585273, 13.0290547],
                [108.5854376, 13.0290749],
                [108.5856028, 13.0290607],
                [108.5856833, 13.0290409],
                [108.5857612, 13.0290129],
                [108.5869391, 13.0286841],
                [108.5870679, 13.0286571],
                [108.5871984, 13.0286392],
                [108.5873299, 13.0286306],
                [108.5875845, 13.0286405],
                [108.5877063, 13.0286577],
                [108.5879451, 13.0287161],
                [108.5881735, 13.0288055],
                [108.5882825, 13.0288613],
                [108.5884575, 13.0289645],
                [108.5885506, 13.0290058],
                [108.5886467, 13.02904],
                [108.5888457, 13.0290861],
                [108.5889444, 13.0290975],
                [108.5891429, 13.0290984],
                [108.5893393, 13.0290704],
                [108.5902802, 13.0290271],
                [108.5904799, 13.0290461],
                [108.590692, 13.0290955],
                [108.5909103, 13.0291812],
                [108.5911125, 13.0292986],
                [108.5917532, 13.0297812],
                [108.5937971, 13.0267394],
                [108.5940786, 13.0264527],
                [108.5942289, 13.0263187],
                [108.5945431, 13.0260736],
                [108.5950055, 13.0257666],
                [108.5952453, 13.0255688],
                [108.595357, 13.025461],
                [108.5955626, 13.0252294],
                [108.5961574, 13.0244189],
                [108.5968202, 13.0238105],
                [108.597461, 13.0231803],
                [108.5978128, 13.022815],
                [108.5981572, 13.0224432],
                [108.5984942, 13.0220649],
                [108.5988235, 13.0216803],
                [108.5991295, 13.0212424],
                [108.5992713, 13.0210164],
                [108.5995316, 13.0205513],
                [108.599651, 13.0203106],
                [108.5998648, 13.0198182],
                [108.5999591, 13.0195672],
                [108.6001217, 13.0190566],
                [108.6001762, 13.0188214],
                [108.6002585, 13.018594],
                [108.6003642, 13.0183832],
                [108.6004261, 13.0182825],
                [108.600567, 13.0180923],
                [108.6006646, 13.0180137],
                [108.6007681, 13.0179428],
                [108.6008771, 13.01788],
                [108.6009908, 13.0178257],
                [108.6011055, 13.0177813],
                [108.6012233, 13.0177455],
                [108.6013437, 13.0177186],
                [108.6014657, 13.0177007],
                [108.6015888, 13.017692],
                [108.6017123, 13.0176924],
                [108.6018823, 13.0177076],
                [108.6020456, 13.0177558],
                [108.6021917, 13.0178325],
                [108.6023161, 13.0179325],
                [108.6023704, 13.0179907],
                [108.6024606, 13.018121],
                [108.603555, 13.019297],
                [108.603652, 13.0193767],
                [108.6038625, 13.0195145],
                [108.603975, 13.0195718],
                [108.6040914, 13.019621],
                [108.6043212, 13.0196912],
                [108.6044393, 13.0197145],
                [108.604679, 13.0197369],
                [108.6047995, 13.019736],
                [108.6049462, 13.0197182],
                [108.6052351, 13.019658],
                [108.6053765, 13.0196158],
                [108.6056505, 13.019508],
                [108.6059099, 13.0193702],
                [108.6061509, 13.0192045],
                [108.6063706, 13.0190131],
                [108.6064717, 13.0189084],
                [108.6066545, 13.018683],
                [108.6067355, 13.018563],
                [108.6068758, 13.0183103],
                [108.6069345, 13.0181785],
                [108.6074039, 13.0170093],
                [108.6076139, 13.0165961],
                [108.6078519, 13.0161976],
                [108.6080932, 13.0158474],
                [108.6083563, 13.0155124],
                [108.6084956, 13.0153509],
                [108.6096275, 13.0138666],
                [108.6097081, 13.0137753],
                [108.6098855, 13.0136078],
                [108.6099815, 13.0135321],
                [108.610209, 13.0133856],
                [108.6104543, 13.0132696],
                [108.6107594, 13.0131577],
                [108.6109327, 13.013083],
                [108.6111019, 13.0129998],
                [108.6112665, 13.0129082],
                [108.611426, 13.0128085],
                [108.6115801, 13.012701],
                [108.6118544, 13.0124781],
                [108.6121054, 13.0122304],
                [108.6123306, 13.0119603],
                [108.6125281, 13.0116702],
                [108.6126959, 13.011363],
                [108.6144447, 13.0075266],
                [108.6169848, 13.009011],
                [108.6181354, 13.0102863],
                [108.6186719, 13.0097114],
                [108.6208177, 13.0096591],
                [108.6218181, 13.0089012],
                [108.6220431, 13.0087766],
                [108.62216, 13.0087224],
                [108.6224096, 13.0086282],
                [108.6226762, 13.008557],
                [108.622949, 13.008513],
                [108.6249919, 13.0083323],
                [108.6252917, 13.0082931],
                [108.6254399, 13.0082636],
                [108.6257315, 13.0081852],
                [108.6260283, 13.0080756],
                [108.6263132, 13.0079391],
                [108.6264503, 13.0078611],
                [108.6268419, 13.0076991],
                [108.6269876, 13.0076723],
                [108.6270618, 13.0076711],
                [108.6271357, 13.007678],
                [108.6272788, 13.0077157],
                [108.6274119, 13.0077853],
                [108.6275272, 13.0078846],
                [108.6276157, 13.0080072],
                [108.6276484, 13.0080753],
                [108.6276882, 13.0082201],
                [108.6277807, 13.0088698],
                [108.6278385, 13.0090316],
                [108.6278769, 13.0091087],
                [108.6279717, 13.009253],
                [108.6280985, 13.0093906],
                [108.6282588, 13.009513],
                [108.6283468, 13.0095631],
                [108.6284392, 13.0096052],
                [108.628535, 13.0096391],
                [108.6286336, 13.0096643],
                [108.6288331, 13.0096598],
                [108.6292318, 13.0096741],
                [108.6294304, 13.0096928],
                [108.6298245, 13.0097532],
                [108.6302334, 13.0098497],
                [108.6304344, 13.0099105],
                [108.6308277, 13.0100563],
                [108.6345076, 13.0101399],
                [108.6349475, 13.010349],
                [108.6387563, 13.0107253],
                [108.6393893, 13.0111644],
                [108.639533, 13.0110101],
                [108.6397016, 13.0108819],
                [108.6398901, 13.0107834],
                [108.6400861, 13.0107192],
                [108.6402831, 13.0106877],
                [108.6404828, 13.0106871],
                [108.64068, 13.0107173],
                [108.6409539, 13.0107979],
                [108.6410396, 13.0108096],
                [108.6412125, 13.0108069],
                [108.6413811, 13.0107694],
                [108.6414615, 13.0107381],
                [108.6416919, 13.0106081],
                [108.6418656, 13.010541],
                [108.6420484, 13.0105036],
                [108.6421416, 13.0104965],
                [108.6423282, 13.0105054],
                [108.6426036, 13.010557],
                [108.6427903, 13.0105685],
                [108.6430103, 13.0105511],
                [108.6432248, 13.0105006],
                [108.6432938, 13.0104923],
                [108.6434322, 13.0104995],
                [108.6434999, 13.010515],
                [108.6436272, 13.0105685],
                [108.6438201, 13.0107051],
                [108.6439236, 13.0107629],
                [108.6441421, 13.010856],
                [108.6442487, 13.0108888],
                [108.6444674, 13.0109331],
                [108.6445786, 13.0109444],
                [108.644802, 13.0109449],
                [108.6448099, 13.0107754],
                [108.6448261, 13.0106919],
                [108.6448809, 13.0105341],
                [108.644963, 13.0103913],
                [108.6450136, 13.010326],
                [108.6451618, 13.0101711],
                [108.6451981, 13.0101165],
                [108.645249, 13.0099966],
                [108.6452629, 13.0099329],
                [108.6452687, 13.0098682],
                [108.6452573, 13.0097452],
                [108.6452173, 13.0096282],
                [108.6451507, 13.0095232],
                [108.6451212, 13.0094446],
                [108.6450809, 13.0092821],
                [108.6450658, 13.0091048],
                [108.6450817, 13.0089166],
                [108.6451299, 13.0087338],
                [108.6452091, 13.0085615],
                [108.645317, 13.0084047],
                [108.6476494, 13.0060852],
                [108.648084, 13.0056357],
                [108.6485412, 13.0051203],
                [108.648781, 13.0048304],
                [108.6490134, 13.0045347],
                [108.6494005, 13.0040077],
                [108.6497465, 13.0035715],
                [108.6501203, 13.0031575],
                [108.6503171, 13.0029594],
                [108.6507296, 13.002582],
                [108.6509529, 13.0023966],
                [108.6511824, 13.0022185],
                [108.6514177, 13.0020479],
                [108.6516587, 13.0018848],
                [108.6519051, 13.0017297],
                [108.6521565, 13.0015825],
                [108.6524128, 13.0014434],
                [108.6526737, 13.0013127],
                [108.6529389, 13.0011904],
                [108.653208, 13.0010767],
                [108.6559117, 13.0000522],
                [108.656223, 12.9999472],
                [108.6565204, 12.9998091],
                [108.6567904, 12.9996462],
                [108.6570319, 12.9994636],
                [108.6572528, 12.9992577],
                [108.657453, 12.9992714],
                [108.6576497, 12.99931],
                [108.6578733, 12.9993867],
                [108.6579799, 12.9994373],
                [108.6581799, 12.9995613],
                [108.6587357, 13.0000175],
                [108.6594468, 13.0006472],
                [108.6599886, 13.0010871],
                [108.6607718, 13.0015471],
                [108.6613405, 13.0021429],
                [108.6632717, 13.0030524],
                [108.6642033, 13.0031282],
                [108.66467, 13.0031547],
                [108.6656044, 13.0031848],
                [108.6665792, 13.0031835],
                [108.6670864, 13.0031697],
                [108.6675933, 13.0031469],
                [108.6680996, 13.0031151],
                [108.6685474, 13.0029997],
                [108.6687686, 13.0029327],
                [108.6692047, 13.0027806],
                [108.6696866, 13.0025798],
                [108.6701542, 13.0023494],
                [108.6707022, 13.0017772],
                [108.670983, 13.0014974],
                [108.6716813, 13.0008376],
                [108.6720398, 13.0005174],
                [108.6734801, 12.999404],
                [108.6737031, 12.9992164],
                [108.673901, 12.9990039],
                [108.6740711, 12.9987695],
                [108.6742109, 12.9985168],
                [108.6742687, 12.9983848],
                [108.6743202, 12.9982434],
                [108.6743625, 12.9980992],
                [108.6743952, 12.9979526],
                [108.6744184, 12.9978043],
                [108.6744319, 12.9976549],
                [108.6744357, 12.997505],
                [108.6744243, 12.9973069],
                [108.6744275, 12.9972585],
                [108.6744592, 12.9971669],
                [108.6745225, 12.9970898],
                [108.6747067, 12.9969758],
                [108.6747891, 12.9968951],
                [108.6748596, 12.9967878],
                [108.6749037, 12.9966678],
                [108.6749151, 12.996605],
                [108.6749771, 12.9964845],
                [108.6750613, 12.9963777],
                [108.6751711, 12.996284],
                [108.6753047, 12.9962107],
                [108.6754513, 12.9961664],
                [108.676003, 12.9963594],
                [108.6765645, 12.9965234],
                [108.6771167, 12.9966545],
                [108.6773865, 12.996708],
                [108.6779298, 12.9967951],
                [108.6782029, 12.9968287],
                [108.6798956, 12.997166],
                [108.680731, 12.9976556],
                [108.6819915, 12.9991063],
                [108.682436, 12.9996127],
                [108.6823983, 12.9998505],
                [108.6824556, 13.0003138],
                [108.6827257, 13.0008754],
                [108.6832243, 13.0014277],
                [108.683724, 13.001843],
                [108.6843077, 13.002259],
                [108.6854483, 13.0029678],
                [108.6858645, 13.0033414],
                [108.6867686, 13.0046605],
                [108.6880528, 13.0064675],
                [108.6889845, 13.0078396],
                [108.689467, 13.0086115],
                [108.6896999, 13.0091343],
                [108.6903652, 13.0105726],
                [108.6908997, 13.0117698],
                [108.6911273, 13.0121077],
                [108.691307, 13.0123424],
                [108.691515, 13.0125497],
                [108.6917356, 13.0128944],
                [108.6919275, 13.0133346],
                [108.6920629, 13.0138156],
                [108.6920937, 13.0139958],
                [108.6924008, 13.0148118],
                [108.6925259, 13.015212],
                [108.6927572, 13.0157184],
                [108.6930536, 13.0161622],
                [108.6934377, 13.0163968],
                [108.6938224, 13.0165682],
                [108.6943374, 13.0165728],
                [108.6947657, 13.016537],
                [108.6949686, 13.0165485],
                [108.6958779, 13.0165566],
                [108.6965494, 13.0165626],
                [108.6981683, 13.0165887],
                [108.6998852, 13.0166675],
                [108.700892, 13.0167176],
                [108.7050674, 13.016875],
                [108.7089203, 13.0170392],
                [108.7127321, 13.0171072],
                [108.7134314, 13.0171407],
                [108.7139343, 13.0172137],
                [108.7146325, 13.0173706],
                [108.7161183, 13.0178155],
                [108.7180277, 13.0186273],
                [108.7194767, 13.0192843],
                [108.7239734, 13.0209618],
                [108.7287908, 13.0227654],
                [108.7337346, 13.0245426],
                [108.7381611, 13.0262603],
                [108.7424772, 13.02784],
                [108.7466945, 13.029501],
                [108.7507871, 13.0310512],
                [108.7513776, 13.0312565],
                [108.7517917, 13.0313784],
                [108.753257, 13.0317784],
                [108.7554499, 13.0326361],
                [108.7562284, 13.0337683],
                [108.7559755, 13.0365355],
                [108.7556715, 13.0391907],
                [108.7553942, 13.0417719],
                [108.7551027, 13.0445017],
                [108.7547726, 13.0471567],
                [108.7545078, 13.0497998],
                [108.7542559, 13.0524556],
                [108.7539653, 13.0550737],
                [108.7536746, 13.057692],
                [108.7530152, 13.0629029],
                [108.7526857, 13.065496],
                [108.7524216, 13.0680525],
                [108.7518412, 13.0731649],
                [108.7515492, 13.0759317],
                [108.7513597, 13.0776559],
                [108.7512441, 13.0786985],
                [108.7509401, 13.0813536],
                [108.7509401, 13.0813539],
                [108.7506996, 13.0841683],
                [108.7503403, 13.0877257],
                [108.7503395, 13.0938793],
                [108.7516836, 13.0963114],
                [108.7517923, 13.0965037],
                [108.7526809, 13.098086],
                [108.7528959, 13.0984544],
                [108.7539431, 13.100004],
                [108.7548553, 13.1010411],
                [108.755516, 13.1012898],
                [108.7558042, 13.1022863],
                [108.756333, 13.1043943],
                [108.7569004, 13.1074867],
                [108.7572055, 13.1078683],
                [108.7576436, 13.1084404],
                [108.7578823, 13.1090414],
                [108.758155, 13.1101438],
                [108.7585487, 13.1113756],
                [108.7587831, 13.112453],
                [108.7589752, 13.1132308],
                [108.7590883, 13.1135252],
                [108.7596163, 13.1148131],
                [108.7600235, 13.1159716],
                [108.7602139, 13.1162299],
                [108.7602906, 13.1162795],
                [108.7603804, 13.1162925],
                [108.7609098, 13.1159961],
                [108.7612359, 13.1159461],
                [108.7615136, 13.1160177],
                [108.761718, 13.1161703],
                [108.7622253, 13.1166861],
                [108.7629813, 13.1171756],
                [108.7631174, 13.117303],
                [108.7636351, 13.1180919],
                [108.7638997, 13.1181961],
                [108.7643116, 13.1181386],
                [108.7645569, 13.1180143],
                [108.7658681, 13.1170601],
                [108.7661707, 13.1167571],
                [108.7663085, 13.1166808],
                [108.7670248, 13.1165974],
                [108.7674382, 13.1166071],
                [108.7681351, 13.116768],
                [108.7684352, 13.1169846],
                [108.7685069, 13.1171073],
                [108.7686214, 13.117958],
                [108.7689453, 13.1188552],
                [108.7690072, 13.1191125],
                [108.7690344, 13.1196649],
                [108.7690127, 13.1204043],
                [108.7688758, 13.1215746],
                [108.7688122, 13.121739],
                [108.7684524, 13.1222046],
                [108.7681771, 13.1230273],
                [108.7679408, 13.123337],
                [108.7671556, 13.1239251],
                [108.76696, 13.124011],
                [108.7666595, 13.1240695],
                [108.7660638, 13.1240928],
                [108.7651459, 13.1239524],
                [108.7647449, 13.1237696],
                [108.7642953, 13.1234949],
                [108.7638586, 13.1234584],
                [108.7624419, 13.1235215],
                [108.7621586, 13.1235802],
                [108.7612832, 13.1239698],
                [108.76004, 13.1247434],
                [108.7598758, 13.124905],
                [108.7596523, 13.1252189],
                [108.7592721, 13.125566],
                [108.7591244, 13.1257806],
                [108.7588481, 13.1267297],
                [108.758636, 13.1279055],
                [108.7586112, 13.1289912],
                [108.7586667, 13.1292464],
                [108.7592182, 13.1305653],
                [108.7601042, 13.1320847],
                [108.7606223, 13.1328329],
                [108.7615246, 13.133731],
                [108.7617581, 13.1349229],
                [108.7618211, 13.1350621],
                [108.7621229, 13.1353296],
                [108.7631797, 13.135732],
                [108.7639655, 13.1362584],
                [108.7641453, 13.1362885],
                [108.7645747, 13.1361822],
                [108.7649025, 13.1359447],
                [108.7655498, 13.1351944],
                [108.7658456, 13.1349504],
                [108.766177, 13.1347863],
                [108.7674566, 13.1347219],
                [108.7686706, 13.1348079],
                [108.7714614, 13.1351336],
                [108.7726053, 13.1353901],
                [108.7743849, 13.1359372],
                [108.7746965, 13.1360744],
                [108.7767212, 13.1372574],
                [108.7771297, 13.1375746],
                [108.7773352, 13.1378352],
                [108.7774528, 13.1380969],
                [108.7775022, 13.1383174],
                [108.7774857, 13.1389591],
                [108.7771044, 13.1403861],
                [108.7765723, 13.1419014],
                [108.7762718, 13.143382],
                [108.77553, 13.144867],
                [108.7754945, 13.1450011],
                [108.7755557, 13.1453439],
                [108.7759751, 13.1461157],
                [108.7762477, 13.1465317],
                [108.7765155, 13.1467744],
                [108.7767545, 13.1468783],
                [108.7780613, 13.1471321],
                [108.7783461, 13.147153],
                [108.7790406, 13.1471141],
                [108.7795968, 13.1469742],
                [108.7802376, 13.146719],
                [108.7808689, 13.146323],
                [108.7811108, 13.1461091],
                [108.781505, 13.1456359],
                [108.7817164, 13.1452364],
                [108.7819301, 13.1441196],
                [108.7822263, 13.1433255],
                [108.782529, 13.1427964],
                [108.7828945, 13.1424104],
                [108.7839027, 13.1418037],
                [108.7843689, 13.1416428],
                [108.7857146, 13.1413957],
                [108.7862716, 13.1413882],
                [108.7865219, 13.1414372],
                [108.7868373, 13.1416355],
                [108.7872396, 13.1421606],
                [108.7880967, 13.1435779],
                [108.7889935, 13.1448855],
                [108.7896228, 13.146148],
                [108.7901532, 13.1469655],
                [108.7905204, 13.1473537],
                [108.7906442, 13.1474077],
                [108.7907729, 13.1473967],
                [108.790975, 13.1473087],
                [108.7911481, 13.1471268],
                [108.7915122, 13.1461846],
                [108.7917238, 13.1457607],
                [108.7918619, 13.1449367],
                [108.7921069, 13.144134],
                [108.7922512, 13.1438051],
                [108.7925408, 13.1425648],
                [108.7927197, 13.1422078],
                [108.7929991, 13.1418618],
                [108.7932668, 13.1416441],
                [108.793846, 13.141325],
                [108.794481, 13.1412245],
                [108.7951131, 13.1412238],
                [108.79563, 13.1414035],
                [108.7958599, 13.1415767],
                [108.7960293, 13.1417981],
                [108.7961654, 13.1421477],
                [108.7964677, 13.1447745],
                [108.7966745, 13.1460844],
                [108.7967421, 13.1471484],
                [108.7967772, 13.1482551],
                [108.796768, 13.1492941],
                [108.7967527, 13.149563],
                [108.7966502, 13.1502406],
                [108.7965414, 13.150912],
                [108.7964598, 13.1514002],
                [108.7964834, 13.1516449],
                [108.7966349, 13.1519519],
                [108.7968322, 13.1521797],
                [108.7971008, 13.1523287],
                [108.7972935, 13.1523425],
                [108.7974994, 13.1523198],
                [108.7978347, 13.1522004],
                [108.7981827, 13.1521058],
                [108.7984914, 13.1520839],
                [108.7986712, 13.1521099],
                [108.7988769, 13.1520994],
                [108.7991241, 13.152022],
                [108.7994467, 13.1518944],
                [108.7996355, 13.1518635],
                [108.7997726, 13.1518647],
                [108.8002526, 13.1518769],
                [108.8012639, 13.1519147],
                [108.8022966, 13.1519561],
                [108.8027021, 13.1519135],
                [108.8029768, 13.1518668],
                [108.8032391, 13.1517835],
                [108.8034286, 13.1516913],
                [108.8035711, 13.1515744],
                [108.8037505, 13.1513986],
                [108.8038852, 13.1511959],
                [108.80396, 13.1509805],
                [108.8039741, 13.150838],
                [108.8039741, 13.1505995],
                [108.803946, 13.1503873],
                [108.8038725, 13.1499749],
                [108.8037197, 13.1495599],
                [108.8035475, 13.1491589],
                [108.803234, 13.148506],
                [108.8030948, 13.1482522],
                [108.8029204, 13.1478532],
                [108.8028702, 13.1477101],
                [108.802871, 13.1476286],
                [108.8028758, 13.1475674],
                [108.8029108, 13.1474862],
                [108.8030606, 13.1472776],
                [108.8032467, 13.1470795],
                [108.8034116, 13.1468525],
                [108.8035076, 13.1466578],
                [108.803663, 13.1463064],
                [108.8037894, 13.146067],
                [108.8039064, 13.1459253],
                [108.8040726, 13.1457943],
                [108.804206, 13.1457343],
                [108.804322, 13.1457107],
                [108.8044291, 13.1457117],
                [108.8045318, 13.1457249],
                [108.8046687, 13.1457586],
                [108.8048653, 13.1458378],
                [108.8050996, 13.1459946],
                [108.8052886, 13.1461858],
                [108.8054451, 13.1464317],
                [108.8055964, 13.1467795],
                [108.8058543, 13.1474197],
                [108.8059085, 13.1475914],
                [108.806114, 13.1483452],
                [108.8062513, 13.1490578],
                [108.8062716, 13.1491761],
                [108.8064381, 13.1499826],
                [108.8066471, 13.1508099],
                [108.8068776, 13.1516496],
                [108.8071467, 13.1524895],
                [108.8074053, 13.1530564],
                [108.8075402, 13.1533225],
                [108.8078979, 13.153825],
                [108.8081353, 13.1541205],
                [108.8085796, 13.1545421],
                [108.8088606, 13.1547565],
                [108.8091208, 13.1549014],
                [108.8093454, 13.1549583],
                [108.8097262, 13.1550309],
                [108.8100645, 13.1550704],
                [108.8103727, 13.1551179],
                [108.8105415, 13.1551866],
                [108.8107717, 13.1553354],
                [108.8109247, 13.1554875],
                [108.8111151, 13.1557745],
                [108.8112624, 13.1560733],
                [108.8115042, 13.1566033],
                [108.8116522, 13.1568246],
                [108.8118372, 13.1570117],
                [108.8120669, 13.1572012],
                [108.8123563, 13.1574482],
                [108.8126618, 13.1577973],
                [108.8129957, 13.158334],
                [108.8131861, 13.1586249],
                [108.8133476, 13.1587771],
                [108.8135307, 13.1589172],
                [108.8137935, 13.1590201],
                [108.8140544, 13.1590875],
                [108.8146712, 13.1591702],
                [108.8149242, 13.1591724],
                [108.8153256, 13.1591085],
                [108.815601, 13.1590089],
                [108.8158638, 13.1588643],
                [108.8161315, 13.1586464],
                [108.8164796, 13.1583253],
                [108.8167815, 13.1581281],
                [108.8171738, 13.1578826],
                [108.8175946, 13.1575784],
                [108.8177927, 13.1574741],
                [108.8180681, 13.1573623],
                [108.8183218, 13.1572911],
                [108.8184934, 13.1572763],
                [108.8189716, 13.1572619],
                [108.8195978, 13.1572387],
                [108.8198252, 13.1572202],
                [108.8199475, 13.157207],
                [108.8201494, 13.1571761],
                [108.8204461, 13.1570808],
                [108.8206658, 13.1569522],
                [108.8208216, 13.1567904],
                [108.8211253, 13.1563975],
                [108.8214014, 13.1559616],
                [108.8217108, 13.1554057],
                [108.8219306, 13.1550262],
                [108.8222356, 13.1544784],
                [108.8223255, 13.1542569],
                [108.8224047, 13.154017],
                [108.8225439, 13.1538022],
                [108.8226908, 13.1536689],
                [108.8229601, 13.1535183],
                [108.8231364, 13.1534667],
                [108.8232779, 13.1534558],
                [108.8234795, 13.1534575],
                [108.8236207, 13.1534831],
                [108.8237533, 13.1535168],
                [108.8240354, 13.1536292],
                [108.824321, 13.1538232],
                [108.8244439, 13.1539912],
                [108.8245281, 13.1541713],
                [108.8246974, 13.1546607],
                [108.824759, 13.1549752],
                [108.8248215, 13.1554141],
                [108.8248444, 13.1557447],
                [108.8248206, 13.1562522],
                [108.8248094, 13.1565539],
                [108.8247872, 13.1573672],
                [108.8247736, 13.1581766],
                [108.824803, 13.1584969],
                [108.824865, 13.1587503],
                [108.8249657, 13.1590121],
                [108.8252778, 13.1598448],
                [108.8254559, 13.1603172],
                [108.8255259, 13.1606441],
                [108.8255582, 13.1611235],
                [108.8255894, 13.1614745],
                [108.8256295, 13.1620274],
                [108.8256831, 13.1622684],
                [108.8258202, 13.1625407],
                [108.826146, 13.1630411],
                [108.8265012, 13.1633601],
                [108.8268544, 13.1636771],
                [108.8272244, 13.1640268],
                [108.8275339, 13.1641946],
                [108.8277862, 13.1642783],
                [108.8280685, 13.1643542],
                [108.8284281, 13.1644346],
                [108.8286652, 13.1645487],
                [108.8289082, 13.1647099],
                [108.8291422, 13.1649158],
                [108.8294443, 13.1651874],
                [108.8296982, 13.1653385],
                [108.8298437, 13.1653805],
                [108.8299936, 13.165398],
                [108.830148, 13.1653993],
                [108.8303281, 13.1654009],
                [108.8304999, 13.1653738],
                [108.8308053, 13.1652704],
                [108.8317327, 13.164905],
                [108.832107, 13.1647818],
                [108.8326079, 13.1646331],
                [108.8328231, 13.164537],
                [108.8330346, 13.1643879],
                [108.8332341, 13.1641327],
                [108.8333861, 13.1639138],
                [108.8335366, 13.1636316],
                [108.8337206, 13.1631887],
                [108.8338788, 13.1630085],
                [108.8340773, 13.1628675],
                [108.8343918, 13.1627071],
                [108.8349387, 13.1624425],
                [108.8354921, 13.1621882],
                [108.836004, 13.1620091],
                [108.8363524, 13.1618977],
                [108.8369734, 13.16175],
                [108.8372054, 13.1617072],
                [108.8374585, 13.1616889],
                [108.8376943, 13.1617032],
                [108.8380693, 13.1617328],
                [108.8384159, 13.1618254],
                [108.8388004, 13.1619878],
                [108.8390866, 13.1621248],
                [108.8395624, 13.1623918],
                [108.8399503, 13.1626519],
                [108.8403807, 13.1629818],
                [108.841341, 13.1637585],
                [108.8420844, 13.1643215],
                [108.8423867, 13.1645769],
                [108.8425838, 13.1648416],
                [108.8426423, 13.1650133],
                [108.8426543, 13.1651195],
                [108.8426575, 13.1652419],
                [108.8426393, 13.165364],
                [108.842558, 13.1655938],
                [108.8424097, 13.1658616],
                [108.8422016, 13.1661209],
                [108.842022, 13.1663154],
                [108.8411903, 13.1667771],
                [108.8409667, 13.1669464],
                [108.840685, 13.1671255],
                [108.8401983, 13.1674609],
                [108.8399868, 13.1676488],
                [108.8398573, 13.1677638],
                [108.8396601, 13.1679385],
                [108.8395373, 13.1680774],
                [108.839374, 13.168317],
                [108.8391914, 13.1686369],
                [108.8390401, 13.169009],
                [108.8389388, 13.1694047],
                [108.8388622, 13.1697099],
                [108.838828, 13.1699843],
                [108.8388209, 13.1701865],
                [108.838848, 13.1704174],
                [108.8388992, 13.1706149],
                [108.8389821, 13.1708126],
                [108.8391019, 13.1710053],
                [108.8393244, 13.1712639],
                [108.8396337, 13.1715567],
                [108.8399747, 13.1718604],
                [108.8402581, 13.1720909],
                [108.840513, 13.172277],
                [108.8406969, 13.1723926],
                [108.8408653, 13.1724873],
                [108.8410917, 13.1725981],
                [108.8413709, 13.1727249],
                [108.8418264, 13.172923],
                [108.8421739, 13.1730816],
                [108.8424844, 13.1732449],
                [108.842616, 13.1733237],
                [108.8428102, 13.1734861],
                [108.8429935, 13.1736691],
                [108.8431375, 13.1738387],
                [108.8433519, 13.1740998],
                [108.8435922, 13.1744439],
                [108.8438113, 13.1747672],
                [108.8440016, 13.1750773],
                [108.8442751, 13.1755384],
                [108.8444313, 13.1758248],
                [108.8445349, 13.1760589],
                [108.8446071, 13.1762721],
                [108.8446477, 13.1764695],
                [108.8446959, 13.1767005],
                [108.8447202, 13.176934],
                [108.8447293, 13.1771104],
                [108.8447225, 13.1772814],
                [108.8447078, 13.177455],
                [108.8446794, 13.1776828],
                [108.8446455, 13.1779209],
                [108.844596, 13.1781487],
                [108.8445403, 13.1784696],
                [108.8444439, 13.178912],
                [108.8443365, 13.1793959],
                [108.844291, 13.1797636],
                [108.8442883, 13.1800745],
                [108.8443098, 13.1803314],
                [108.84434, 13.1805131],
                [108.8444015, 13.1807313],
                [108.8444842, 13.1809498],
                [108.8445779, 13.1811164],
                [108.8447058, 13.1813119],
                [108.8449463, 13.1816094],
                [108.8451398, 13.1818547],
                [108.8453599, 13.1820795],
                [108.8455461, 13.1822495],
                [108.8457722, 13.1823914],
                [108.8460038, 13.1825125],
                [108.8461671, 13.1825814],
                [108.8462884, 13.1826135],
                [108.8464152, 13.1826145],
                [108.8465265, 13.1825947],
                [108.8466484, 13.1825543],
                [108.8467337, 13.1824824],
                [108.8467872, 13.1824103],
                [108.8468542, 13.1823098],
                [108.8470959, 13.1818712],
                [108.8472572, 13.1815615],
                [108.8473786, 13.1812904],
                [108.8475461, 13.180877],
                [108.8477483, 13.1804198],
                [108.8478831, 13.1801256],
                [108.8480226, 13.1798934],
                [108.8481989, 13.1796875],
                [108.8483427, 13.1795643],
                [108.8484649, 13.179498],
                [108.8486186, 13.1794526],
                [108.8487614, 13.1794434],
                [108.8488856, 13.1794522],
                [108.8490123, 13.1794792],
                [108.8491334, 13.1795424],
                [108.8492646, 13.1796473],
                [108.8493956, 13.1797883],
                [108.849526, 13.1799915],
                [108.8496405, 13.1801999],
                [108.8497236, 13.1803613],
                [108.8497882, 13.1805251],
                [108.8498813, 13.1807696],
                [108.8499535, 13.1809827],
                [108.8499966, 13.1811878],
                [108.850073, 13.1815203],
                [108.8501078, 13.1817695],
                [108.8501323, 13.1819977],
                [108.8501385, 13.1821922],
                [108.8501298, 13.1825861],
                [108.850097, 13.1830083],
                [108.8500022, 13.1838811],
                [108.8499721, 13.1843007],
                [108.8499255, 13.1850857],
                [108.8499116, 13.1854745],
                [108.8498985, 13.1857646],
                [108.8498673, 13.1860055],
                [108.8498279, 13.1862747],
                [108.8497889, 13.1865077],
                [108.849712, 13.1868284],
                [108.8496519, 13.1870508],
                [108.8495543, 13.1873351],
                [108.8494864, 13.1875446],
                [108.8494214, 13.1877202],
                [108.8493326, 13.1878984],
                [108.8491605, 13.1882287],
                [108.849026, 13.1884919],
                [108.8489341, 13.1887297],
                [108.8488716, 13.1889183],
                [108.8488325, 13.1891669],
                [108.8488252, 13.1893845],
                [108.8488343, 13.1895556],
                [108.848875, 13.1897427],
                [108.8489393, 13.189948],
                [108.8490953, 13.1902499],
                [108.8492938, 13.1905161],
                [108.8494664, 13.19073],
                [108.8496734, 13.1909417],
                [108.8499201, 13.1911564],
                [108.8501409, 13.1913034],
                [108.8503462, 13.1914088],
                [108.8506517, 13.1915305],
                [108.8509549, 13.1916291],
                [108.8513345, 13.1917515],
                [108.8516933, 13.1918425],
                [108.8519626, 13.1918811],
                [108.8521264, 13.1918981],
                [108.8523036, 13.1918969],
                [108.8525946, 13.1918683],
                [108.8529333, 13.1918401],
                [108.8532083, 13.1918319],
                [108.8534671, 13.19186],
                [108.8537123, 13.1919217],
                [108.8539232, 13.1920012],
                [108.8541073, 13.1921064],
                [108.854286, 13.1922324],
                [108.854433, 13.192358],
                [108.8545955, 13.1925252],
                [108.8547707, 13.1927368],
                [108.8549013, 13.1929193],
                [108.8550159, 13.1931224],
                [108.8550934, 13.1933304],
                [108.8551551, 13.1935331],
                [108.8551804, 13.1936629],
                [108.8551977, 13.1937952],
                [108.85518, 13.1940076],
                [108.8551522, 13.1941681],
                [108.8551083, 13.194344],
                [108.8550404, 13.1945482],
                [108.8549477, 13.1948689],
                [108.8548928, 13.1950965],
                [108.8548223, 13.1953033],
                [108.8547363, 13.1954737],
                [108.8546317, 13.1956361],
                [108.8544713, 13.1958369],
                [108.8543378, 13.1959861],
                [108.8541032, 13.1962174],
                [108.8536953, 13.1965951],
                [108.8527967, 13.1974766],
                [108.8525618, 13.1977442],
                [108.8524121, 13.1979244],
                [108.8522146, 13.1981405],
                [108.8520892, 13.1982768],
                [108.8517849, 13.1985905],
                [108.8515558, 13.1988012],
                [108.8513003, 13.198996],
                [108.8511833, 13.1990728],
                [108.8510478, 13.1991468],
                [108.8507079, 13.1993253],
                [108.8503837, 13.1995092],
                [108.8500592, 13.1997346],
                [108.8495989, 13.2000652],
                [108.8492901, 13.2003114],
                [108.8490129, 13.2005631],
                [108.84881, 13.2007792],
                [108.8486041, 13.20106],
                [108.8483413, 13.2014829],
                [108.8481908, 13.2017511],
                [108.848131, 13.2019476],
                [108.8480841, 13.2021727],
                [108.8480274, 13.2026077],
                [108.847998, 13.202934],
                [108.8479669, 13.2031697],
                [108.8479006, 13.2034906],
                [108.8478133, 13.2038009],
                [108.8477423, 13.2040595],
                [108.8477334, 13.2041657],
                [108.8477531, 13.2043421],
                [108.8477991, 13.2045084],
                [108.8478771, 13.2046697],
                [108.8479812, 13.2048624],
                [108.8480512, 13.205016],
                [108.8481391, 13.2052448],
                [108.8482526, 13.2055568],
                [108.8483666, 13.2058325],
                [108.848468, 13.2060165],
                [108.8486563, 13.2062513],
                [108.8488606, 13.2064757],
                [108.8491072, 13.2067057],
                [108.8494116, 13.2069622],
                [108.8495692, 13.2070775],
                [108.8497323, 13.2071669],
                [108.8499063, 13.2072409],
                [108.8500751, 13.2072838],
                [108.8501999, 13.207327],
                [108.8502283, 13.2073306],
                [108.8504908, 13.2076398],
                [108.8507711, 13.2080662],
                [108.8512505, 13.2089183],
                [108.8517091, 13.2098731],
                [108.8517487, 13.2100161],
                [108.8517649, 13.2101705],
                [108.8517529, 13.2105331],
                [108.8517267, 13.2108585],
                [108.8516231, 13.2117229],
                [108.8515963, 13.2121224],
                [108.8514584, 13.2129008],
                [108.8514204, 13.2132489],
                [108.8514072, 13.2134315],
                [108.8513706, 13.2136083],
                [108.8513289, 13.2137222],
                [108.8512487, 13.2138957],
                [108.850906, 13.2144468],
                [108.8503777, 13.2152334],
                [108.8500824, 13.2156878],
                [108.8498549, 13.21606],
                [108.8497598, 13.2162762],
                [108.8497053, 13.2165099],
                [108.8496859, 13.2167382],
                [108.8497101, 13.2169583],
                [108.849807, 13.2171989],
                [108.8499451, 13.2173944],
                [108.8501416, 13.2175845],
                [108.85035, 13.2177289],
                [108.8509214, 13.2180108],
                [108.8512754, 13.2181679],
                [108.8517343, 13.2183204],
                [108.8520482, 13.2183915],
                [108.8523742, 13.2184285],
                [108.8526188, 13.2184363],
                [108.8528724, 13.2184118],
                [108.8532633, 13.2183523],
                [108.8535721, 13.2183435],
                [108.8537698, 13.2183794],
                [108.8539675, 13.2184381],
                [108.8541706, 13.2185197],
                [108.8543271, 13.2186123],
                [108.854519, 13.2187654],
                [108.855148, 13.2194276],
                [108.8552459, 13.2196532],
                [108.8552444, 13.219826],
                [108.8551838, 13.2200444],
                [108.8551107, 13.220332],
                [108.8550624, 13.2204814],
                [108.8550606, 13.2206887],
                [108.8551281, 13.221035],
                [108.8551849, 13.2212661],
                [108.8552822, 13.2215607],
                [108.8556989, 13.2222902],
                [108.8561975, 13.2230895],
                [108.8564532, 13.2234144],
                [108.8566518, 13.2235658],
                [108.8568745, 13.2236369],
                [108.8571737, 13.2236912],
                [108.8576553, 13.2237414],
                [108.8588066, 13.2238202],
                [108.859465, 13.2238141],
                [108.8600351, 13.2238131],
                [108.8604921, 13.2239783],
                [108.8608076, 13.2242],
                [108.8611455, 13.2245369],
                [108.8616698, 13.2250829],
                [108.8619964, 13.2253738],
                [108.862277, 13.2255489],
                [108.8626107, 13.2256958],
                [108.8635248, 13.2260262],
                [108.8641858, 13.2277315],
                [108.8648668, 13.2298518],
                [108.8655478, 13.2319722],
                [108.8662407, 13.2340696],
                [108.8670615, 13.2363409],
                [108.8678927, 13.2387737],
                [108.8682133, 13.2397559],
                [108.8687918, 13.2415067],
                [108.8693187, 13.2430899],
                [108.8695304, 13.2437543],
                [108.8698284, 13.2446442],
                [108.8702251, 13.2456674],
                [108.870419, 13.2463489],
                [108.8704634, 13.2466489],
                [108.87052, 13.247577],
                [108.870549, 13.2483033],
                [108.870591, 13.2488684],
                [108.8706622, 13.249474],
                [108.8708179, 13.2505009],
                [108.8709019, 13.251654],
                [108.8709437, 13.2522536],
                [108.8710465, 13.2532743],
                [108.8710773, 13.2537816],
                [108.8710578, 13.2540063],
                [108.870874, 13.2548575],
                [108.8706009, 13.2558405],
                [108.8704093, 13.2572592],
                [108.8703285, 13.2577657],
                [108.8702801, 13.2579151],
                [108.8701876, 13.2580785],
                [108.8696477, 13.2589815],
                [108.8690417, 13.2600568],
                [108.8685174, 13.2608591],
                [108.8682798, 13.261465],
                [108.8682241, 13.2617988],
                [108.8682169, 13.2619485],
                [108.8683215, 13.262419],
                [108.8684303, 13.2627598],
                [108.8687097, 13.2633989],
                [108.8691095, 13.2644135],
                [108.8694966, 13.2655317],
                [108.8697214, 13.2660405],
                [108.869865, 13.2664279],
                [108.8699751, 13.266619],
                [108.8701058, 13.2667899],
                [108.8704324, 13.2670981],
                [108.8710818, 13.2677864],
                [108.8714309, 13.2682041],
                [108.871223, 13.2687758],
                [108.8710967, 13.2691146],
                [108.8710539, 13.269293],
                [108.8710642, 13.2694716],
                [108.8711212, 13.2696795],
                [108.8712178, 13.2700548],
                [108.8713, 13.2704156],
                [108.8713853, 13.2710761],
                [108.8713568, 13.2719834],
                [108.8713744, 13.2730006],
                [108.8714217, 13.2739892],
                [108.8714593, 13.2743928],
                [108.8715139, 13.2748831],
                [108.8715746, 13.2753215],
                [108.8716397, 13.2759558],
                [108.8716861, 13.2770366],
                [108.871712, 13.2774344],
                [108.871716, 13.2776534],
                [108.8717206, 13.2777975],
                [108.8717434, 13.2778841],
                [108.8718015, 13.2779653],
                [108.8719031, 13.2781121],
                [108.8724439, 13.2787961],
                [108.8727115, 13.279115],
                [108.8733183, 13.279684],
                [108.8731295, 13.2801696],
                [108.8728907, 13.2806895],
                [108.8727101, 13.2810359],
                [108.8725442, 13.2813339],
                [108.8721826, 13.2820824],
                [108.8720005, 13.2826097],
                [108.8718333, 13.2830675],
                [108.8717169, 13.2833867],
                [108.8716051, 13.2835911],
                [108.871497, 13.2837711],
                [108.8712238, 13.2841515],
                [108.8708432, 13.2846494],
                [108.8706422, 13.2849052],
                [108.8704591, 13.2851437],
                [108.869955, 13.2859537],
                [108.8698651, 13.2860818],
                [108.869693, 13.2862751],
                [108.8693705, 13.2866134],
                [108.8692195, 13.2868278],
                [108.8690282, 13.2871835],
                [108.8686299, 13.2880842],
                [108.8684848, 13.2884377],
                [108.8684202, 13.2885206],
                [108.8682349, 13.2886025],
                [108.8680569, 13.2886636],
                [108.8678151, 13.2886963],
                [108.8675095, 13.2887286],
                [108.8671259, 13.2887461],
                [108.8666115, 13.2887383],
                [108.8654413, 13.2886172],
                [108.8649378, 13.2885503],
                [108.8646404, 13.2884714],
                [108.8643857, 13.2883718],
                [108.8641418, 13.288255],
                [108.8624647, 13.2872494],
                [108.8616659, 13.2868461],
                [108.8611923, 13.2866195],
                [108.8608664, 13.2865472],
                [108.8606465, 13.2865384],
                [108.8604547, 13.2865507],
                [108.8603336, 13.2865914],
                [108.8602657, 13.286643],
                [108.8602222, 13.286747],
                [108.8601401, 13.2872055],
                [108.8600876, 13.2875252],
                [108.8599929, 13.2878027],
                [108.859906, 13.2879898],
                [108.859769, 13.288232],
                [108.8596678, 13.28844],
                [108.8595246, 13.2893919],
                [108.8593868, 13.2909388],
                [108.8593762, 13.2913284],
                [108.8593319, 13.2915297],
                [108.8572691, 13.2932065],
                [108.8569048, 13.2934609],
                [108.8566056, 13.2935835],
                [108.8563708, 13.2936442],
                [108.8559942, 13.2936828],
                [108.8555857, 13.2937106],
                [108.8546834, 13.2938074],
                [108.8543919, 13.2938606],
                [108.854299, 13.2939155],
                [108.8542381, 13.293981],
                [108.8534775, 13.295307],
                [108.8526729, 13.2967925],
                [108.8517412, 13.2981935],
                [108.8509086, 13.2996232],
                [108.8502899, 13.3009503],
                [108.850022, 13.3015395],
                [108.8497199, 13.302403],
                [108.8492578, 13.3036689],
                [108.8490833, 13.3041405],
                [108.8489521, 13.3045221],
                [108.8487493, 13.3049865],
                [108.8485978, 13.3052635],
                [108.8484139, 13.3055785],
                [108.8482485, 13.3058207],
                [108.8480912, 13.3059515],
                [108.847841, 13.3061442],
                [108.84743, 13.3064503],
                [108.8468504, 13.3069672],
                [108.8466138, 13.3072364],
                [108.8464981, 13.307479],
                [108.8463368, 13.3080412],
                [108.8462778, 13.3082981],
                [108.8462475, 13.3085136],
                [108.8462538, 13.3090042],
                [108.8462523, 13.3091779],
                [108.8462259, 13.3093483],
                [108.8461665, 13.3096469],
                [108.8460624, 13.3101748],
                [108.8459205, 13.3105737],
                [108.8457246, 13.3110451],
                [108.8455799, 13.31135],
                [108.8454574, 13.3115576],
                [108.8453027, 13.3117964],
                [108.8446984, 13.3126958],
                [108.8441037, 13.3137029],
                [108.8439306, 13.3140076],
                [108.8438581, 13.3141809],
                [108.8438104, 13.314351],
                [108.8437795, 13.314636],
                [108.8437554, 13.3149558],
                [108.8436735, 13.3153796],
                [108.8436363, 13.3155671],
                [108.8436, 13.3156572],
                [108.843528, 13.3157749],
                [108.8433992, 13.315899],
                [108.8431988, 13.3160712],
                [108.8427266, 13.3164777],
                [108.8422185, 13.3169325],
                [108.8410178, 13.3182269],
                [108.8405231, 13.3187792],
                [108.8404225, 13.3189106],
                [108.8404003, 13.3190147],
                [108.8404063, 13.31914],
                [108.8404966, 13.3193635],
                [108.8407268, 13.3198246],
                [108.8408869, 13.3201808],
                [108.8409656, 13.3205189],
                [108.8410284, 13.3210377],
                [108.8410321, 13.3214204],
                [108.8409774, 13.3219904],
                [108.8409147, 13.3222508],
                [108.8408052, 13.3225908],
                [108.8406675, 13.3229026],
                [108.8405014, 13.3232143],
                [108.8403249, 13.3235155],
                [108.8400656, 13.3239307],
                [108.8397421, 13.3243802],
                [108.8395052, 13.3246704],
                [108.8393188, 13.3248775],
                [108.8391039, 13.3250914],
                [108.8389285, 13.3252604],
                [108.838628, 13.3255152],
                [108.8383487, 13.3257841],
                [108.838198, 13.3259637],
                [108.8380575, 13.3262026],
                [108.8377679, 13.3268193],
                [108.8376899, 13.327084],
                [108.8373574, 13.3274837],
                [108.8370593, 13.3278731],
                [108.8366008, 13.3283351],
                [108.8363213, 13.3286247],
                [108.8361638, 13.3287694],
                [108.8359994, 13.3288931],
                [108.8350643, 13.3294553],
                [108.8342717, 13.3299631],
                [108.8340426, 13.3301696],
                [108.8338847, 13.3303629],
                [108.8337693, 13.3305635],
                [108.8336892, 13.3307784],
                [108.8336305, 13.3310075],
                [108.8335043, 13.3316182],
                [108.831992, 13.3316122],
                [108.8312869, 13.3329395],
                [108.83084, 13.3333184],
                [108.8305501, 13.3336707],
                [108.8303482, 13.3339258],
                [108.8300084, 13.3342654],
                [108.8295557, 13.3346774],
                [108.8290901, 13.3351382],
                [108.8286872, 13.335563],
                [108.8283098, 13.3359144],
                [108.8279078, 13.3362413],
                [108.8274803, 13.336629],
                [108.8274187, 13.3368082],
                [108.8274274, 13.3372242],
                [108.8274347, 13.3378115],
                [108.8274922, 13.3383501],
                [108.8275482, 13.3390601],
                [108.8275322, 13.3394636],
                [108.8275161, 13.3398549],
                [108.8273616, 13.340404],
                [108.8270952, 13.3409032],
                [108.8268302, 13.3412435],
                [108.8263409, 13.3415573],
                [108.8259502, 13.3420066],
                [108.8256841, 13.342469],
                [108.8255188, 13.3428346],
                [108.8253781, 13.3432248],
                [108.8250118, 13.3437231],
                [108.8247175, 13.344144],
                [108.8239725, 13.344179],
                [108.8236611, 13.3441274],
                [108.8230255, 13.3440731],
                [108.8224901, 13.3439583],
                [108.8220037, 13.3439542],
                [108.8216907, 13.344086],
                [108.8214767, 13.3443044],
                [108.8212498, 13.3445716],
                [108.821098, 13.3448027],
                [108.8208816, 13.3452901],
                [108.8207292, 13.3455946],
                [108.8206271, 13.3458505],
                [108.8204116, 13.3462401],
                [108.8200086, 13.3466648],
                [108.8196179, 13.347114],
                [108.8194291, 13.347308],
                [108.8192905, 13.3474536],
                [108.8189656, 13.3475242],
                [108.8185659, 13.347582],
                [108.818153, 13.3477374],
                [108.8178872, 13.348151],
                [108.8176345, 13.3485159],
                [108.8174447, 13.3488199],
                [108.817266, 13.349271],
                [108.8170386, 13.3495871],
                [108.8168876, 13.3497325],
                [108.8165244, 13.3499007],
                [108.8160362, 13.3500799],
                [108.8154736, 13.3502341],
                [108.8150848, 13.3504754],
                [108.8147329, 13.3507658],
                [108.8146181, 13.3510462],
                [108.8145895, 13.3514497],
                [108.8146229, 13.3519025],
                [108.8146813, 13.3523434],
                [108.8147768, 13.352809],
                [108.8147841, 13.3533962],
                [108.8147056, 13.3537869],
                [108.8144358, 13.3539032],
                [108.8140113, 13.3539485],
                [108.8134996, 13.3539809],
                [108.8125625, 13.354144],
                [108.8119007, 13.3542239],
                [108.8114638, 13.354269],
                [108.8109637, 13.354387],
                [108.8104894, 13.3544196],
                [108.8102519, 13.3544787],
                [108.8100633, 13.3546484],
                [108.8099109, 13.3549406],
                [108.8097823, 13.3553676],
                [108.809451, 13.3561352],
                [108.8091965, 13.356708],
                [108.8089039, 13.3573292],
                [108.8088879, 13.3577206],
                [108.8090339, 13.3581378],
                [108.8093291, 13.3585929],
                [108.8096744, 13.3590362],
                [108.8099582, 13.3593811],
                [108.8101659, 13.3598599],
                [108.8101734, 13.3604228],
                [108.8100185, 13.3609962],
                [108.8098643, 13.3614963],
                [108.8097237, 13.3618622],
                [108.8096584, 13.3621918],
                [108.8094675, 13.3626061],
                [108.8092141, 13.363032],
                [108.8089486, 13.3634334],
                [108.8085696, 13.3639683],
                [108.8082534, 13.3644425],
                [108.8078749, 13.3649163],
                [108.8075849, 13.3652562],
                [108.8073195, 13.3656454],
                [108.807004, 13.3660463],
                [108.8066503, 13.3665325],
                [108.8064323, 13.3671911],
                [108.8063779, 13.36768],
                [108.8063859, 13.3681815],
                [108.8064188, 13.3686833],
                [108.8064642, 13.369173],
                [108.8064979, 13.3695769],
                [108.8065434, 13.3700788],
                [108.8066878, 13.370655],
                [108.8067458, 13.3711447],
                [108.8068542, 13.3715738],
                [108.8069249, 13.3720269],
                [108.8070436, 13.3727007],
                [108.8071143, 13.3731539],
                [108.8071229, 13.3735821],
                [108.8071176, 13.3741692],
                [108.8070633, 13.374658],
                [108.8070579, 13.3752574],
                [108.8070172, 13.3756117],
                [108.8069503, 13.3761249],
                [108.8068586, 13.3766011],
                [108.8066056, 13.3769903],
                [108.8063515, 13.3774896],
                [108.8061857, 13.3778918],
                [108.8060449, 13.3782942],
                [108.8059171, 13.3786234],
                [108.8055642, 13.379024],
                [108.8052111, 13.379449],
                [108.8050326, 13.3798756],
                [108.8049909, 13.3803401],
                [108.8049369, 13.3807921],
                [108.8049452, 13.3812448],
                [108.8050011, 13.3819669],
                [108.8051074, 13.3826284],
                [108.8052155, 13.3830941],
                [108.8052993, 13.3834863],
                [108.805244, 13.384073],
                [108.8050775, 13.3845486],
                [108.8050113, 13.3849639],
                [108.8050069, 13.3854531],
                [108.8050287, 13.3857957],
                [108.8050361, 13.3863584],
                [108.8051193, 13.386824],
                [108.8051271, 13.3873378],
                [108.8050855, 13.3878022],
                [108.8050927, 13.3883772],
                [108.805026, 13.3888658],
                [108.8049982, 13.3891714],
                [108.8049577, 13.3895014],
                [108.8047922, 13.3898668],
                [108.8046141, 13.3902567],
                [108.8044614, 13.3905857],
                [108.804305, 13.3909372],
                [108.8043558, 13.3912207],
                [108.8046401, 13.3915168],
                [108.8049507, 13.3916541],
                [108.8055354, 13.3918427],
                [108.805932, 13.3921274],
                [108.8061175, 13.3923124],
                [108.8064124, 13.3928016],
                [108.806684, 13.3931214],
                [108.8069936, 13.3933684],
                [108.8074039, 13.3935309],
                [108.8076651, 13.3936186],
                [108.8076887, 13.3937653],
                [108.8076497, 13.3939361],
                [108.8075736, 13.3941673],
                [108.8073201, 13.3946054],
                [108.8071907, 13.39513],
                [108.8071612, 13.3956311],
                [108.8071578, 13.3959979],
                [108.8072171, 13.3963408],
                [108.8073251, 13.3968064],
                [108.8073908, 13.3973246],
                [108.8073939, 13.3974794],
                [108.8073902, 13.397883],
                [108.8073869, 13.3982497],
                [108.8073948, 13.3987512],
                [108.8073787, 13.3991545],
                [108.8073131, 13.3995086],
                [108.8072342, 13.3999603],
                [108.8071551, 13.4004381],
                [108.8071528, 13.4006811],
                [108.8072016, 13.4008038],
                [108.8075109, 13.4010755],
                [108.8076825, 13.4014071],
                [108.8077166, 13.4017742],
                [108.807676, 13.4021284],
                [108.807585, 13.4025311],
                [108.8074941, 13.4029338],
                [108.8073521, 13.4034706],
                [108.8072485, 13.4038855],
                [108.8071062, 13.4044467],
                [108.8069022, 13.4049463],
                [108.8066861, 13.4053846],
                [108.806396, 13.4057367],
                [108.8061689, 13.4060282],
                [108.8058923, 13.4062825],
                [108.8056153, 13.4065614],
                [108.8054502, 13.40689],
                [108.8054105, 13.4071465],
                [108.8054574, 13.4074648],
                [108.805593, 13.4076495],
                [108.8059267, 13.4079825],
                [108.8062731, 13.4083034],
                [108.8066552, 13.4087958],
                [108.8068763, 13.4091768],
                [108.8069725, 13.4095567],
                [108.8070069, 13.4098994],
                [108.8070035, 13.4102784],
                [108.8069619, 13.4107304],
                [108.806896, 13.4111212],
                [108.8067302, 13.4115233],
                [108.8065272, 13.4119005],
                [108.806324, 13.4123145],
                [108.8060563, 13.4129358],
                [108.8059412, 13.4132649],
                [108.8058138, 13.413545],
                [108.8057119, 13.4137887],
                [108.8055445, 13.4143619],
                [108.8055042, 13.4146795],
                [108.8054572, 13.4150459],
                [108.8054359, 13.4153392],
                [108.8053671, 13.4160417],
                [108.8053142, 13.4163836],
                [108.8052678, 13.4166766],
                [108.8052269, 13.4170615],
                [108.8052001, 13.4172568],
                [108.8051664, 13.4175379],
                [108.8050942, 13.4179345],
                [108.8050733, 13.4181728],
                [108.8050708, 13.418454],
                [108.8050922, 13.4188455],
                [108.8051398, 13.4190966],
                [108.8052366, 13.4194275],
                [108.8052536, 13.419611],
                [108.8052493, 13.420088],
                [108.8052415, 13.4202529],
                [108.8052333, 13.4204669],
                [108.8052251, 13.4206929],
                [108.8051855, 13.420925],
                [108.8050587, 13.4211562],
                [108.8049322, 13.4213507],
                [108.8048305, 13.4215577],
                [108.8047857, 13.4216918],
                [108.8047717, 13.4218507],
                [108.8048385, 13.4220591],
                [108.8050109, 13.4222991],
                [108.8052205, 13.4225699],
                [108.8054985, 13.4228536],
                [108.805752, 13.4231003],
                [108.8058379, 13.4232539],
                [108.8059231, 13.4234809],
                [108.806039, 13.4237692],
                [108.8061303, 13.4240207],
                [108.8061533, 13.4242287],
                [108.8061432, 13.4246566],
                [108.8061282, 13.4249439],
                [108.8060695, 13.4252184],
                [108.8059669, 13.4255293],
                [108.8058212, 13.4257788],
                [108.8056175, 13.4262232],
                [108.805459, 13.4265276],
                [108.8054009, 13.4267288],
                [108.8053304, 13.426936],
                [108.8052276, 13.4272713],
                [108.8052008, 13.427479],
                [108.8051729, 13.4278028],
                [108.8051401, 13.4279738],
                [108.8050823, 13.4281628],
                [108.8050368, 13.428358],
                [108.8049782, 13.4286387],
                [108.8049461, 13.4287338],
                [108.804926, 13.4288807],
                [108.8049059, 13.4290272],
                [108.8048795, 13.4291981],
                [108.8048469, 13.4293567],
                [108.8047883, 13.429619],
                [108.8047552, 13.4298326],
                [108.8047224, 13.4300096],
                [108.8047086, 13.4301684],
                [108.8047131, 13.4303517],
                [108.8047114, 13.4305352],
                [108.8047097, 13.4307245],
                [108.8047137, 13.4309629],
                [108.8047122, 13.431134],
                [108.8047227, 13.4313542],
                [108.804739, 13.4316171],
                [108.8047492, 13.4318677],
                [108.8047531, 13.4321184],
                [108.8047948, 13.4323387],
                [108.8048802, 13.4325473],
                [108.8050029, 13.4327684],
                [108.8051444, 13.4329652],
                [108.8052861, 13.4331497],
                [108.8054774, 13.4333714],
                [108.8056747, 13.4336115],
                [108.8058411, 13.4338269],
                [108.8059574, 13.4340662],
                [108.806074, 13.4342812],
                [108.8061349, 13.4344283],
                [108.8061827, 13.4346549],
                [108.8062303, 13.434912],
                [108.8062658, 13.4351201],
                [108.8062573, 13.4353706],
                [108.8062485, 13.4356395],
                [108.8062406, 13.435835],
                [108.8062073, 13.4360669],
                [108.8061552, 13.4363109],
                [108.8060531, 13.4365606],
                [108.8058513, 13.4368216],
                [108.8057004, 13.4369548],
                [108.8055122, 13.4370937],
                [108.8052611, 13.4372748],
                [108.8049852, 13.4374436],
                [108.8046971, 13.4375878],
                [108.8044963, 13.4377388],
                [108.8043951, 13.4378847],
                [108.8043498, 13.4380738],
                [108.804354, 13.4382876],
                [108.8043776, 13.4384467],
                [108.804419, 13.4386916],
                [108.8044355, 13.4389362],
                [108.8044216, 13.4390949],
                [108.8043883, 13.4393269],
                [108.8043789, 13.4394709],
                [108.804285, 13.4397049],
                [108.804246, 13.4398819],
                [108.804207, 13.4400587],
                [108.804118, 13.4402536],
                [108.8039292, 13.4404413],
                [108.8037351, 13.4405314],
                [108.8035163, 13.4406027],
                [108.8032411, 13.4406798],
                [108.8029848, 13.4407631],
                [108.8027659, 13.4408346],
                [108.8025216, 13.4409547],
                [108.8023149, 13.441069],
                [108.8021392, 13.4411958],
                [108.8019631, 13.4413531],
                [108.8018307, 13.441517],
                [108.8017168, 13.4416994],
                [108.8016337, 13.4419248],
                [108.8015699, 13.4420954],
                [108.8015301, 13.4423578],
                [108.8014842, 13.4426019],
                [108.8014448, 13.4428154],
                [108.8014054, 13.4430412],
                [108.8013537, 13.4432241],
                [108.8013022, 13.4434192],
                [108.8012251, 13.4436569],
                [108.8011609, 13.4438642],
                [108.8010967, 13.4440653],
                [108.801032, 13.4443153],
                [108.8009551, 13.444559],
                [108.8008267, 13.4449491],
                [108.8007625, 13.4451624],
                [108.8006734, 13.4453633],
                [108.8005652, 13.4456069],
                [108.8004886, 13.4457956],
                [108.800412, 13.4459966],
                [108.8003351, 13.4462222],
                [108.8002959, 13.4464173],
                [108.8002505, 13.4466125],
                [108.8001867, 13.4467647],
                [108.8000667, 13.4469288],
                [108.7999658, 13.4470562],
                [108.799778, 13.4471462],
                [108.7996532, 13.4471573],
                [108.7994538, 13.4471372],
                [108.7992359, 13.4471047],
                [108.7990428, 13.4470847],
                [108.7988744, 13.4470893],
                [108.7987308, 13.4471248],
                [108.7984995, 13.4471717],
                [108.7982873, 13.4472004],
                [108.7980628, 13.4472046],
                [108.7978199, 13.4471779],
                [108.7975894, 13.4471577],
                [108.797303, 13.4471062],
                [108.7970789, 13.4470676],
                [108.796867, 13.4470657],
                [108.7967109, 13.4470827],
                [108.7965608, 13.4471364],
                [108.7964351, 13.4472453],
                [108.7963719, 13.4473364],
                [108.7963459, 13.4474584],
                [108.7963444, 13.4476234],
                [108.7963795, 13.4478682],
                [108.7964212, 13.4480885],
                [108.7964814, 13.4483152],
                [108.7965295, 13.4485112],
                [108.7965777, 13.4487011],
                [108.7966259, 13.448891],
                [108.7966489, 13.4490867],
                [108.7966598, 13.449264],
                [108.79664, 13.4493861],
                [108.7965453, 13.4495198],
                [108.7963819, 13.4496589],
                [108.7961874, 13.4497855],
                [108.7959496, 13.4498873],
                [108.7956806, 13.4499767],
                [108.7954678, 13.4500665],
                [108.7953862, 13.4501269],
                [108.795354, 13.4502428],
                [108.7953525, 13.4503955],
                [108.7953825, 13.4505241],
                [108.7954377, 13.4506285],
                [108.7955483, 13.4508066],
                [108.795659, 13.4509788],
                [108.7957759, 13.4511509],
                [108.7959236, 13.4513601],
                [108.7960529, 13.4515446],
                [108.7961637, 13.4516983],
                [108.796262, 13.4518642],
                [108.7963352, 13.4520359],
                [108.7963528, 13.4521522],
                [108.7963266, 13.4522925],
                [108.7962943, 13.4524145],
                [108.7962119, 13.4525605],
                [108.7960988, 13.4526573],
                [108.7959546, 13.4527416],
                [108.7957797, 13.4527767],
                [108.7955923, 13.4528178],
                [108.7953552, 13.4528341],
                [108.7951743, 13.4528325],
                [108.794981, 13.4528431],
                [108.7947003, 13.4528589],
                [108.7944756, 13.4528814],
                [108.7942384, 13.452916],
                [108.7940886, 13.452933],
                [108.7939076, 13.4529497],
                [108.7937516, 13.4529605],
                [108.793583, 13.4529897],
                [108.7933584, 13.4530121],
                [108.793121, 13.4530529],
                [108.7929274, 13.4530878],
                [108.792709, 13.4531164],
                [108.792503, 13.453133],
                [108.7922846, 13.4531494],
                [108.7920727, 13.4531414],
                [108.7919106, 13.45314],
                [108.7916675, 13.4531256],
                [108.7914741, 13.4531423],
                [108.7912309, 13.4531462],
                [108.7910314, 13.4531445],
                [108.7908942, 13.4531433],
                [108.7906821, 13.4531537],
                [108.7905198, 13.4531767],
                [108.7903259, 13.4532421],
                [108.7901256, 13.4533321],
                [108.789975, 13.4534408],
                [108.7898803, 13.4535683],
                [108.789823, 13.45369],
                [108.7897906, 13.453818],
                [108.789777, 13.4539401],
                [108.7897819, 13.454093],
                [108.7898428, 13.4542524],
                [108.7899347, 13.4544244],
                [108.7900578, 13.4545966],
                [108.7902059, 13.4547751],
                [108.7903358, 13.4548924],
                [108.7904718, 13.4550159],
                [108.7906137, 13.4551699],
                [108.7907495, 13.4553299],
                [108.7908727, 13.4554961],
                [108.7909526, 13.455619],
                [108.7910572, 13.4557666],
                [108.7911677, 13.4559631],
                [108.7912845, 13.4561476],
                [108.7913823, 13.4563561],
                [108.7914988, 13.4565711],
                [108.7915971, 13.4567308],
                [108.7917139, 13.4569152],
                [108.7918555, 13.4571182],
                [108.7919784, 13.4573026],
                [108.7920643, 13.4574561],
                [108.7921807, 13.4576771],
                [108.7923161, 13.4578738],
                [108.7924204, 13.4580521],
                [108.7925062, 13.4582179],
                [108.7925546, 13.4583894],
                [108.7925719, 13.4585423],
                [108.7925518, 13.458695],
                [108.7925068, 13.4588351],
                [108.7924302, 13.45903],
                [108.7923538, 13.4592066],
                [108.7923089, 13.4593467],
                [108.7922639, 13.459493],
                [108.7922622, 13.4596764],
                [108.7922418, 13.4598657],
                [108.7922403, 13.4600245],
                [108.7922321, 13.4602383],
                [108.7922237, 13.4604766],
                [108.7922223, 13.4606232],
                [108.7922263, 13.4608678],
                [108.7922246, 13.4610573],
                [108.7922355, 13.4612223],
                [108.7922332, 13.4614728],
                [108.7922313, 13.4616868],
                [108.7922305, 13.4617723],
                [108.7922289, 13.4619496],
                [108.7922396, 13.462133],
                [108.792263, 13.4623044],
                [108.7923174, 13.4625004],
                [108.7923784, 13.4626476],
                [108.7924646, 13.4627706],
                [108.7925944, 13.4628879],
                [108.7927062, 13.4629316],
                [108.7928434, 13.4629329],
                [108.7930181, 13.4629283],
                [108.7932554, 13.4628815],
                [108.7934868, 13.4628101],
                [108.7936989, 13.4627998],
                [108.7939171, 13.4628016],
                [108.7940729, 13.4628152],
                [108.7943153, 13.462903],
                [108.7944826, 13.4630083],
                [108.7946125, 13.4631256],
                [108.7947609, 13.4632614],
                [108.7949213, 13.4634522],
                [108.7950757, 13.4636125],
                [108.7951741, 13.4637539],
                [108.7952851, 13.4638954],
                [108.7953895, 13.4640676],
                [108.7955, 13.4642639],
                [108.7955854, 13.4644603],
                [108.7956649, 13.4646322],
                [108.7957441, 13.4648467],
                [108.7957863, 13.4649999],
                [108.795803, 13.465214],
                [108.7958012, 13.4654095],
                [108.7957439, 13.4655373],
                [108.7956116, 13.465689],
                [108.795461, 13.4657977],
                [108.7952605, 13.4659059],
                [108.7950976, 13.4659901],
                [108.7949089, 13.4661657],
                [108.7947891, 13.4663113],
                [108.7947191, 13.4664574],
                [108.794711, 13.4666651],
                [108.7947532, 13.4668243],
                [108.7948263, 13.4670083],
                [108.7950244, 13.467169],
                [108.7952231, 13.4672563],
                [108.7955342, 13.4673385],
                [108.7957578, 13.4674247],
                [108.7959561, 13.4675606],
                [108.7960609, 13.4676957],
                [108.7960659, 13.4678362],
                [108.7960338, 13.4679397],
                [108.7959322, 13.4680753],
                [108.7957752, 13.4682144],
                [108.7956053, 13.4683657],
                [108.7954918, 13.4685296],
                [108.7954273, 13.4687551],
                [108.7953816, 13.4689869],
                [108.7953608, 13.4692005],
                [108.7953464, 13.4694265],
                [108.7953258, 13.4696218],
                [108.7953237, 13.469854],
                [108.7953215, 13.4700982],
                [108.795332, 13.4703183],
                [108.7953552, 13.4705018],
                [108.7953529, 13.4707523],
                [108.7953576, 13.4709234],
                [108.7953554, 13.4711617],
                [108.7953346, 13.4713936],
                [108.795314, 13.4716012],
                [108.7952811, 13.4717841],
                [108.7951921, 13.4719788],
                [108.7951094, 13.4721553],
                [108.7950143, 13.4723255],
                [108.7949188, 13.4725508],
                [108.794805, 13.4727209],
                [108.7946538, 13.4728906],
                [108.7944905, 13.4730297],
                [108.7942645, 13.473211],
                [108.7940697, 13.4733803],
                [108.7939, 13.4735315],
                [108.7937552, 13.473683],
                [108.7936481, 13.4737982],
                [108.7935159, 13.4739436],
                [108.7933528, 13.4740583],
                [108.7932653, 13.4740881],
                [108.7931094, 13.4740867],
                [108.7929538, 13.474067],
                [108.7928668, 13.4740357],
                [108.7927739, 13.4739677],
                [108.7925699, 13.4737765],
                [108.7923911, 13.4735672],
                [108.7922368, 13.4734008],
                [108.7920454, 13.4731976],
                [108.7918355, 13.4729818],
                [108.791644, 13.4727908],
                [108.7914403, 13.4725691],
                [108.791255, 13.4723903],
                [108.7910513, 13.4721686],
                [108.7908974, 13.4719594],
                [108.7907372, 13.4717503],
                [108.7905894, 13.4715596],
                [108.7904416, 13.4713628],
                [108.7902572, 13.4710801],
                [108.7901407, 13.4708652],
                [108.7900302, 13.4706689],
                [108.7899458, 13.4703747],
                [108.7899106, 13.47013],
                [108.7898934, 13.4699711],
                [108.7898822, 13.4698183],
                [108.7898596, 13.4695798],
                [108.7898243, 13.4693473],
                [108.7897762, 13.4691514],
                [108.789728, 13.4689738],
                [108.7896298, 13.4687957],
                [108.7894752, 13.4686722],
                [108.7892892, 13.4685483],
                [108.7890784, 13.4684427],
                [108.788861, 13.4683552],
                [108.7885998, 13.4682917],
                [108.7883572, 13.4682286],
                [108.7881269, 13.4682021],
                [108.7877533, 13.4681499],
                [108.7875042, 13.4681233],
                [108.7872426, 13.4681027],
                [108.7870185, 13.4680701],
                [108.7867633, 13.4680251],
                [108.7864957, 13.4679861],
                [108.786253, 13.4679412],
                [108.7860041, 13.4678962],
                [108.7857301, 13.4678572],
                [108.785506, 13.4678308],
                [108.7852445, 13.4677978],
                [108.7850888, 13.4677782],
                [108.7848398, 13.4677455],
                [108.7846157, 13.467719],
                [108.7843605, 13.4676802],
                [108.7841674, 13.4676539],
                [108.7840181, 13.4676343],
                [108.7838376, 13.4676022],
                [108.7836073, 13.4675635],
                [108.783327, 13.4675427],
                [108.783084, 13.4675222],
                [108.7828223, 13.4675138],
                [108.7825856, 13.4675056],
                [108.7823362, 13.4675034],
                [108.7821182, 13.4674892],
                [108.7818972, 13.4674519],
                [108.7817103, 13.4674442],
                [108.7815238, 13.4673998],
                [108.7813624, 13.4673312],
                [108.7812013, 13.4672443],
                [108.781059, 13.4671209],
                [108.7809234, 13.4669609],
                [108.7807819, 13.4667703],
                [108.7806468, 13.4665493],
                [108.7804866, 13.4663646],
                [108.7803194, 13.4662471],
                [108.780139, 13.4662089],
                [108.7799833, 13.4662075],
                [108.7798161, 13.4662289],
                [108.7797024, 13.46626],
                [108.7795019, 13.4663865],
                [108.779345, 13.4665011],
                [108.7792256, 13.4666161],
                [108.779074, 13.4668407],
                [108.7789849, 13.4670292],
                [108.7789386, 13.467328],
                [108.7789428, 13.4675541],
                [108.7789474, 13.4677312],
                [108.7789395, 13.4679144],
                [108.7789126, 13.4681217],
                [108.7788417, 13.4683716],
                [108.778697, 13.468523],
                [108.7785656, 13.4685889],
                [108.7784348, 13.4685878],
                [108.7783106, 13.4685378],
                [108.7782243, 13.4684394],
                [108.7781817, 13.468323],
                [108.778183, 13.4681825],
                [108.7781908, 13.4680177],
                [108.7782173, 13.467847],
                [108.7782314, 13.4676639],
                [108.778227, 13.4674806],
                [108.7781472, 13.4673394],
                [108.7780735, 13.4672165],
                [108.7779123, 13.4671358],
                [108.7776823, 13.4670727],
                [108.7774523, 13.4670279],
                [108.7772654, 13.4670141],
                [108.7770473, 13.4670121],
                [108.776873, 13.4669984],
                [108.776711, 13.4669969],
                [108.7764494, 13.4669884],
                [108.7762125, 13.4669925],
                [108.775982, 13.4669904],
                [108.7757702, 13.4669947],
                [108.7755832, 13.4670053],
                [108.7754149, 13.4670038],
                [108.7752032, 13.4669897],
                [108.7750039, 13.466988],
                [108.7747859, 13.4669799],
                [108.7745867, 13.4669598],
                [108.7743625, 13.4669395],
                [108.7741696, 13.4669134],
                [108.7739268, 13.466899],
                [108.7736653, 13.4668722],
                [108.7734538, 13.4668337],
                [108.7732114, 13.4667644],
                [108.7729815, 13.4666891],
                [108.7727023, 13.4665706],
                [108.7724791, 13.4664587],
                [108.7722808, 13.4663348],
                [108.7720458, 13.4661434],
                [108.771848, 13.4659645],
                [108.7716874, 13.4658166],
                [108.7714585, 13.4656435],
                [108.7712912, 13.4655382],
                [108.7710745, 13.4653958],
                [108.7708886, 13.4652842],
                [108.7706656, 13.465148],
                [108.7704487, 13.4650177],
                [108.7702751, 13.4649184],
                [108.7700767, 13.464819],
                [108.7698718, 13.4647318],
                [108.7696294, 13.4646685],
                [108.769418, 13.4646178],
                [108.7692626, 13.4645919],
                [108.7690008, 13.4645958],
                [108.7687017, 13.4645931],
                [108.7684712, 13.464591],
                [108.7682033, 13.4645887],
                [108.7679665, 13.4645926],
                [108.7677172, 13.4645966],
                [108.7672563, 13.4645742],
                [108.7670072, 13.4645719],
                [108.7667455, 13.4645696],
                [108.7664651, 13.4645672],
                [108.7661786, 13.4645646],
                [108.7659419, 13.4645503],
                [108.7657238, 13.4645483],
                [108.7655122, 13.4645221],
                [108.7653258, 13.4644716],
                [108.7650838, 13.4643656],
                [108.7648916, 13.4642539],
                [108.7646874, 13.4641056],
                [108.7644897, 13.4639253],
                [108.7643351, 13.463802],
                [108.7641245, 13.463666],
                [108.7639263, 13.4635483],
                [108.7637458, 13.4635102],
                [108.7635153, 13.4635142],
                [108.763353, 13.4635371],
                [108.7631467, 13.4636207],
                [108.7629588, 13.4637227],
                [108.7628019, 13.4638494],
                [108.7626211, 13.4640061],
                [108.7618473, 13.4645679],
                [108.7615643, 13.4647337],
                [108.7610933, 13.464959],
                [108.7609837, 13.4649733],
                [108.7608118, 13.4649718],
                [108.7605629, 13.4648319],
                [108.7603921, 13.4647079],
                [108.7601609, 13.4643692],
                [108.7598532, 13.463831],
                [108.759436, 13.4633072],
                [108.7586789, 13.4623463],
                [108.7582945, 13.4616691],
                [108.7580965, 13.4611008],
                [108.7579282, 13.4607087],
                [108.7578408, 13.4600187],
                [108.7577341, 13.4597268],
                [108.7575025, 13.4594184],
                [108.7572848, 13.4592941],
                [108.7571133, 13.4592466],
                [108.7566285, 13.4592729],
                [108.7562516, 13.4594533],
                [108.7555583, 13.4600444],
                [108.7532986, 13.4626584],
                [108.7523104, 13.4638555],
                [108.7520831, 13.463907],
                [108.751398, 13.4636291],
                [108.7509493, 13.4631196],
                [108.7505936, 13.4627183],
                [108.7503991, 13.4625473],
                [108.7500799, 13.4621998],
                [108.7497889, 13.461823],
                [108.7494986, 13.4613744],
                [108.7491497, 13.4608964],
                [108.7488446, 13.4604475],
                [108.7484529, 13.4598109],
                [108.7482347, 13.4595356],
                [108.7479289, 13.4591586],
                [108.7476734, 13.4589358],
                [108.7473659, 13.4587457],
                [108.7469994, 13.4585696],
                [108.7466447, 13.4585098],
                [108.7462884, 13.4583901],
                [108.7459176, 13.4582121],
                [108.7455477, 13.4579468],
                [108.7453103, 13.4578574],
                [108.7449836, 13.4577379],
                [108.7446125, 13.4576036],
                [108.7442114, 13.4574836],
                [108.7437951, 13.4574071],
                [108.7433934, 13.4573453],
                [108.7429168, 13.4573409],
                [108.7424097, 13.4574092],
                [108.7418869, 13.4575645],
                [108.7415957, 13.4577191],
                [108.7413417, 13.4577896],
                [108.7411082, 13.4578068],
                [108.7409691, 13.4578057],
                [108.7408597, 13.4578144],
                [108.74076, 13.4578523],
                [108.74067, 13.4579291],
                [108.7406097, 13.4579965],
                [108.7405199, 13.4580539],
                [108.74044, 13.4580921],
                [108.7402809, 13.4581101],
                [108.7398836, 13.4581162],
                [108.7396454, 13.4580946],
                [108.7393472, 13.4581114],
                [108.7391086, 13.4581384],
                [108.7389092, 13.4582239],
                [108.7387188, 13.4583873],
                [108.7384391, 13.4585691],
                [108.738299, 13.4586747],
                [108.7381392, 13.4587702],
                [108.7379848, 13.4588046],
                [108.7377266, 13.458812],
                [108.7375133, 13.4587858],
                [108.7373247, 13.4587646],
                [108.737117, 13.4586753],
                [108.7369291, 13.4585862],
                [108.736781, 13.4584878],
                [108.7366128, 13.4584182],
                [108.7364445, 13.4583585],
                [108.7362661, 13.458318],
                [108.7361073, 13.4583068],
                [108.7359185, 13.4583052],
                [108.73572, 13.4582936],
                [108.7355811, 13.4582827],
                [108.7355019, 13.4582529],
                [108.735403, 13.4582033],
                [108.735304, 13.4581734],
                [108.7351748, 13.4581723],
                [108.7351351, 13.4581718],
                [108.7350055, 13.4582193],
                [108.7348952, 13.4583348],
                [108.7347346, 13.4585179],
                [108.7345838, 13.4587011],
                [108.7343834, 13.4588935],
                [108.7341437, 13.4590371],
                [108.7340241, 13.4590748],
                [108.7338749, 13.4590929],
                [108.7336464, 13.4591007],
                [108.7335074, 13.4590994],
                [108.7333581, 13.4591271],
                [108.7331984, 13.4592132],
                [108.7328989, 13.4593755],
                [108.7325695, 13.4595377],
                [108.7323401, 13.4596425],
                [108.7320211, 13.4597658],
                [108.7318219, 13.4598321],
                [108.731657, 13.4599229],
                [108.7314767, 13.4600962],
                [108.7313156, 13.4603277],
                [108.7311645, 13.4605401],
                [108.7310241, 13.4606943],
                [108.7308438, 13.460848],
                [108.730654, 13.4609726],
                [108.730315, 13.4610958],
                [108.7300462, 13.4611614],
                [108.7298767, 13.4612279],
                [108.729667, 13.4613425],
                [108.7294572, 13.4614766],
                [108.7290676, 13.4617062],
                [108.7287724, 13.4619512],
                [108.7285716, 13.4621728],
                [108.7283899, 13.462482],
                [108.7282478, 13.4628013],
                [108.728126, 13.4630721],
                [108.727985, 13.4632943],
                [108.7277438, 13.4633503],
                [108.7275045, 13.4634355],
                [108.7273446, 13.4635312],
                [108.7271948, 13.4636172],
                [108.7270354, 13.4636546],
                [108.7268264, 13.4636819],
                [108.7266276, 13.4636898],
                [108.7263196, 13.463687],
                [108.7258338, 13.4635661],
                [108.7255167, 13.4634758],
                [108.7252194, 13.4633857],
                [108.7249027, 13.4632566],
                [108.7247046, 13.4631869],
                [108.7246154, 13.4631569],
                [108.7244964, 13.4631365],
                [108.724387, 13.4631355],
                [108.7242774, 13.4631733],
                [108.7241774, 13.4632404],
                [108.7240468, 13.4633849],
                [108.7239461, 13.4635314],
                [108.7238455, 13.4636471],
                [108.7237156, 13.4637431],
                [108.7235354, 13.4638678],
                [108.7233555, 13.4639828],
                [108.7231557, 13.4640976],
                [108.7228461, 13.46426],
                [108.7225265, 13.4644223],
                [108.7224892, 13.4646713],
                [108.7216963, 13.4647527],
                [108.7211091, 13.4646035],
                [108.7203539, 13.4640689],
                [108.719819, 13.4635603],
                [108.7191135, 13.4629543],
                [108.7186748, 13.4626625],
                [108.7182831, 13.4625871],
                [108.717719, 13.4625819],
                [108.7171788, 13.462649],
                [108.7160958, 13.4630468],
                [108.7144732, 13.4640579],
                [108.7137734, 13.4643685],
                [108.7132243, 13.4649209],
                [108.7127419, 13.4656294],
                [108.7127089, 13.4660329],
                [108.7128607, 13.4668264],
                [108.7129329, 13.4676192],
                [108.7129799, 13.4682688],
                [108.7131081, 13.4687635],
                [108.7136311, 13.4696641],
                [108.7140357, 13.4700124],
                [108.7145128, 13.4703584],
                [108.7148811, 13.4706822],
                [108.7150084, 13.4710678],
                [108.7150256, 13.4715591],
                [108.7151457, 13.4727136],
                [108.7153804, 13.4732923],
                [108.7154195, 13.4737839],
                [108.7152844, 13.4742098],
                [108.7151837, 13.4744546],
                [108.7146099, 13.47509],
                [108.7139282, 13.475575],
                [108.7133132, 13.4759325],
                [108.7126123, 13.4761397],
                [108.7119569, 13.4761551],
                [108.7113673, 13.4761497],
                [108.7110162, 13.4763174],
                [108.7098495, 13.4772678],
                [108.7097214, 13.4774215],
                [108.7094947, 13.4778893],
                [108.7092713, 13.478717],
                [108.709057, 13.4800057],
                [108.7089576, 13.4805448],
                [108.7087954, 13.4813137],
                [108.7084921, 13.4820484],
                [108.708039, 13.482953],
                [108.707604, 13.4833705],
                [108.7071517, 13.4834914],
                [108.7069625, 13.4835557],
                [108.7066109, 13.4836973],
                [108.7064476, 13.4838802],
                [108.7060127, 13.4842845],
                [108.7055789, 13.4845834],
                [108.7050122, 13.4846836],
                [108.7044722, 13.4848103],
                [108.7040931, 13.4850176],
                [108.7037261, 13.4853698],
                [108.7033423, 13.4860642],
                [108.703272, 13.4863797],
                [108.7033569, 13.4866504],
                [108.703597, 13.4868897],
                [108.7040533, 13.4870652],
                [108.7046169, 13.4872942],
                [108.7047839, 13.487434],
                [108.7049167, 13.4876327],
                [108.7049935, 13.4880549],
                [108.7051185, 13.4890569],
                [108.7052229, 13.4894135],
                [108.7055818, 13.4899172],
                [108.7061401, 13.4906862],
                [108.7066477, 13.4911254],
                [108.7069692, 13.4913128],
                [108.7073987, 13.4914747],
                [108.7076938, 13.4916091],
                [108.7078673, 13.4917687],
                [108.7081874, 13.4921008],
                [108.7088573, 13.4931803],
                [108.7090153, 13.4935637],
                [108.7090782, 13.4940252],
                [108.7089907, 13.4947225],
                [108.7087254, 13.4957077],
                [108.7088535, 13.4971115],
                [108.7089783, 13.4981399],
                [108.7091883, 13.4987213],
                [108.7093544, 13.4989692],
                [108.7100834, 13.4995052],
                [108.710781, 13.4998928],
                [108.7114026, 13.5002145],
                [108.7118097, 13.5004499],
                [108.7121417, 13.5006856],
                [108.7127291, 13.5009531],
                [108.7131824, 13.5012195],
                [108.7135279, 13.5015372],
                [108.7136329, 13.5017742],
                [108.7136432, 13.502102],
                [108.7136117, 13.5025999],
                [108.7134996, 13.5030971],
                [108.7134626, 13.5041716],
                [108.7134817, 13.5049846],
                [108.7135802, 13.5059032],
                [108.7135478, 13.5064797],
                [108.7133371, 13.5074872],
                [108.7128929, 13.5090825],
                [108.7128068, 13.5096848],
                [108.7128045, 13.5099207],
                [108.7129628, 13.5101843],
                [108.7133349, 13.5105286],
                [108.7135693, 13.5112648],
                [108.7135928, 13.5116059],
                [108.7135613, 13.5121039],
                [108.7135037, 13.5125227],
                [108.7135663, 13.5129822],
                [108.7139049, 13.5140341],
                [108.7140596, 13.5146909],
                [108.7141081, 13.5152157],
                [108.7140432, 13.516395],
                [108.7140841, 13.5177326],
                [108.7143202, 13.5182854],
                [108.7145054, 13.5185493],
                [108.714745, 13.5187349],
                [108.7149591, 13.5187893],
                [108.7153079, 13.5187663],
                [108.7157654, 13.5185869],
                [108.7164644, 13.5184097],
                [108.7171615, 13.518416],
                [108.7182336, 13.5184783],
                [108.7189433, 13.5185766],
                [108.7196126, 13.5186876],
                [108.7211917, 13.5190165],
                [108.7229044, 13.5193992],
                [108.7241092, 13.5195937],
                [108.7254763, 13.5196585],
                [108.7263892, 13.5195357],
                [108.7274089, 13.5194664],
                [108.7277826, 13.5196533],
                [108.7278598, 13.5199948],
                [108.7279089, 13.5204672],
                [108.7279192, 13.520795],
                [108.7277516, 13.5215014],
                [108.7273111, 13.5227035],
                [108.7272007, 13.5230434],
                [108.7273318, 13.5233592],
                [108.7274909, 13.5235442],
                [108.7278629, 13.5239146],
                [108.728423, 13.5242344],
                [108.7291969, 13.5246346],
                [108.7299993, 13.5248517],
                [108.7305054, 13.5252233],
                [108.7309299, 13.5256991],
                [108.7311663, 13.5262257],
                [108.7313229, 13.5266728],
                [108.7315475, 13.5270287],
                [108.731946, 13.5274257],
                [108.7322385, 13.5276906],
                [108.7326115, 13.5279561],
                [108.7329033, 13.5282996],
                [108.733085, 13.5289305],
                [108.7331607, 13.5294294],
                [108.7332357, 13.5300069],
                [108.7332288, 13.5307409],
                [108.7333556, 13.5315025],
                [108.7335767, 13.5322256],
                [108.733945, 13.5329892],
                [108.7344187, 13.5339374],
                [108.7347566, 13.5350679],
                [108.7350678, 13.5361982],
                [108.7351418, 13.5368805],
                [108.7350586, 13.5371681],
                [108.7346007, 13.5373738],
                [108.7340636, 13.5374476],
                [108.7333382, 13.5375983],
                [108.7329726, 13.5379751],
                [108.7327257, 13.5385497],
                [108.7326932, 13.5391524],
                [108.7326353, 13.5395977],
                [108.7326865, 13.5398603],
                [108.7329512, 13.5402297],
                [108.7336682, 13.5409705],
                [108.7342249, 13.5416571],
                [108.7345159, 13.5420793],
                [108.734759, 13.543327],
                [108.7357177, 13.5468753],
                [108.7364051, 13.5493329],
                [108.7373073, 13.5503637],
                [108.7378936, 13.5507623],
                [108.7384811, 13.5510298],
                [108.7394448, 13.5512221],
                [108.7398999, 13.5513049],
                [108.740114, 13.5513592],
                [108.7401927, 13.5515435],
                [108.7401369, 13.5517789],
                [108.7399717, 13.5522232],
                [108.7397498, 13.5530077],
                [108.7396075, 13.5538717],
                [108.739823, 13.5551978],
                [108.7401137, 13.5556461],
                [108.7405915, 13.5561748],
                [108.7410968, 13.5566251],
                [108.7415242, 13.5568125],
                [108.7417695, 13.5568147],
                [108.7421947, 13.5568185],
                [108.7424577, 13.5565704],
                [108.7428606, 13.5565217],
                [108.7432891, 13.5566041],
                [108.743691, 13.5566602],
                [108.7438792, 13.5566095],
                [108.7441497, 13.5563761],
                [108.7445298, 13.5559078],
                [108.745017, 13.5554405],
                [108.7454211, 13.5552608],
                [108.7457708, 13.5551591],
                [108.7461202, 13.5550837],
                [108.7466036, 13.5550356],
                [108.7468718, 13.555038],
                [108.7471667, 13.5550669],
                [108.7473805, 13.5551474],
                [108.7476207, 13.5552806],
                [108.7478333, 13.5554921],
                [108.7478981, 13.557133],
                [108.7479308, 13.5577463],
                [108.7480512, 13.5585482],
                [108.748111, 13.5596684],
                [108.7481558, 13.560357],
                [108.7482271, 13.5609457],
                [108.7482216, 13.5615212],
                [108.748144, 13.5622876],
                [108.7481376, 13.5629628],
                [108.7481562, 13.563713],
                [108.7482385, 13.5644764],
                [108.7482976, 13.565002],
                [108.748484, 13.5655913],
                [108.7486582, 13.566118],
                [108.7489621, 13.5664709],
                [108.7492982, 13.5665169],
                [108.7495857, 13.5664884],
                [108.7501119, 13.5663297],
                [108.7502335, 13.5662808],
                [108.7508182, 13.5660603],
                [108.7517957, 13.5656419],
                [108.7521751, 13.5653997],
                [108.7524323, 13.5652362],
                [108.7528365, 13.5651324],
                [108.753289, 13.5651246],
                [108.75365, 13.5651371],
                [108.7538152, 13.5650695],
                [108.7539628, 13.5649634],
                [108.7543485, 13.5646206],
                [108.7548702, 13.5641697],
                [108.7555051, 13.5638668],
                [108.7559573, 13.5637827],
                [108.7573832, 13.5633766],
                [108.75854, 13.5627696],
                [108.7594896, 13.5626018],
                [108.7620441, 13.5621838],
                [108.7629025, 13.5621034],
                [108.7633094, 13.5618944],
                [108.7638283, 13.5620006],
                [108.7649339, 13.5620105],
                [108.7651602, 13.5619464],
                [108.7653869, 13.5618381],
                [108.7656142, 13.5616637],
                [108.7661133, 13.5613813],
                [108.766317, 13.561317],
                [108.7665205, 13.5612748],
                [108.7666559, 13.561276],
                [108.7668137, 13.5612994],
                [108.7670384, 13.5613896],
                [108.7674668, 13.5614376],
                [108.7680299, 13.561553],
                [108.768255, 13.5616211],
                [108.7684797, 13.5617114],
                [108.7686593, 13.5618232],
                [108.7687932, 13.5619789],
                [108.768905, 13.5620902],
                [108.7690834, 13.5623124],
                [108.7695058, 13.563],
                [108.7697471, 13.5637522],
                [108.7701691, 13.5644618],
                [108.7708934, 13.5654459],
                [108.7721193, 13.567088],
                [108.7738153, 13.5691311],
                [108.774643, 13.5699321],
                [108.7750462, 13.5702442],
                [108.7754956, 13.5704465],
                [108.7760839, 13.5706295],
                [108.7763119, 13.5703137],
                [108.7770664, 13.5697267],
                [108.7783286, 13.5689663],
                [108.7789172, 13.5687935],
                [108.7795859, 13.5687203],
                [108.7803554, 13.5686876],
                [108.7806387, 13.5687098],
                [108.7808201, 13.5687906],
                [108.7809607, 13.5689106],
                [108.7810805, 13.5690897],
                [108.7811395, 13.5692684],
                [108.7811778, 13.5695062],
                [108.7811757, 13.5697238],
                [108.7811301, 13.5702774],
                [108.7810856, 13.5707123],
                [108.7810427, 13.5709691],
                [108.7810199, 13.5712459],
                [108.7810382, 13.5714638],
                [108.7810965, 13.5717216],
                [108.7811754, 13.57194],
                [108.7812954, 13.5720993],
                [108.781496, 13.5722989],
                [108.7816769, 13.5724391],
                [108.7820589, 13.5727195],
                [108.7828354, 13.5730286],
                [108.7835416, 13.573272],
                [108.7841475, 13.5734356],
                [108.7845516, 13.5735183],
                [108.784794, 13.5735599],
                [108.7849554, 13.5736207],
                [108.7850357, 13.5737006],
                [108.7851553, 13.5738994],
                [108.7851945, 13.5740381],
                [108.7851728, 13.5741961],
                [108.7850705, 13.5743138],
                [108.7849278, 13.5744114],
                [108.7844209, 13.5745057],
                [108.7841776, 13.5745431],
                [108.784015, 13.5746208],
                [108.7839125, 13.5747582],
                [108.7838703, 13.5749358],
                [108.7838683, 13.5751534],
                [108.7838869, 13.5753315],
                [108.7839451, 13.5756088],
                [108.7840043, 13.5757675],
                [108.7840837, 13.5759462],
                [108.7842033, 13.5761449],
                [108.784644, 13.5766431],
                [108.7851645, 13.5772606],
                [108.7855767, 13.5775533],
                [108.7857783, 13.5776965],
                [108.7861403, 13.5774887],
                [108.7863133, 13.577448],
                [108.7865291, 13.5774499],
                [108.786788, 13.5774521],
                [108.787003, 13.5775385],
                [108.7873471, 13.577668],
                [108.7874758, 13.5777536],
                [108.787648, 13.5777973],
                [108.7879062, 13.577884],
                [108.7884661, 13.5780155],
                [108.7886819, 13.5780174],
                [108.7891567, 13.5780216],
                [108.7902918, 13.5778932],
                [108.7909629, 13.5781185],
                [108.7917356, 13.5782198],
                [108.7926059, 13.5782276],
                [108.7950745, 13.5779815],
                [108.7960107, 13.5778323],
                [108.8009482, 13.5772772],
                [108.805768, 13.5772409],
                [108.8093467, 13.5772093],
                [108.8142805, 13.5770635],
                [108.8145363, 13.5769355],
                [108.8149024, 13.5765551],
                [108.8150859, 13.5754576],
                [108.8157352, 13.5737642],
                [108.8160119, 13.5725748],
                [108.8167119, 13.5719784],
                [108.8177862, 13.5713806],
                [108.8192545, 13.5701722],
                [108.819419, 13.5699126],
                [108.8202119, 13.569133],
                [108.8214732, 13.5684889],
                [108.8232958, 13.5677516],
                [108.8244172, 13.5672452],
                [108.8248746, 13.5668978],
                [108.8252388, 13.5667295],
                [108.8255965, 13.566386],
                [108.8265114, 13.5650391],
                [108.8271578, 13.5648556],
                [108.8279106, 13.5648622],
                [108.8294625, 13.5647144],
                [108.8306788, 13.5646189],
                [108.8322688, 13.5642937],
                [108.8339061, 13.5641512],
                [108.8353098, 13.5641007],
                [108.8367604, 13.5640959],
                [108.8377901, 13.5641381],
                [108.8387225, 13.5643927],
                [108.839979, 13.5640642],
                [108.8415285, 13.5638257],
                [108.8433, 13.5639984],
                [108.8447149, 13.5643885],
                [108.847606, 13.5655155],
                [108.8490155, 13.5665038],
                [108.8503286, 13.56857],
                [108.8510155, 13.5714498],
                [108.8517071, 13.5731735],
                [108.8525243, 13.575482],
                [108.8525239, 13.5760975],
                [108.8523661, 13.576836],
                [108.8519563, 13.5775435],
                [108.8511841, 13.5787587],
                [108.8509315, 13.5800511],
                [108.8510249, 13.5817437],
                [108.8510241, 13.5830055],
                [108.8508663, 13.5836208],
                [108.8500306, 13.5857283],
                [108.8488322, 13.588805],
                [108.8476496, 13.5916816],
                [108.8469244, 13.5930968],
                [108.8461367, 13.5939887],
                [108.8444669, 13.5954649],
                [108.8437421, 13.5963568],
                [108.8420728, 13.5970636],
                [108.8408128, 13.5977707],
                [108.8401669, 13.5984319],
                [108.8389055, 13.6011699],
                [108.8385583, 13.602493],
                [108.8385578, 13.6030469],
                [108.8386834, 13.6036624],
                [108.8399572, 13.6058328],
                [108.8413726, 13.6081726],
                [108.8416868, 13.6093422],
                [108.8416865, 13.6098038],
                [108.8415288, 13.6101422],
                [108.8408982, 13.6113727],
                [108.8398421, 13.6131416],
                [108.8394639, 13.6135721],
                [108.8389915, 13.6137873],
                [108.8383929, 13.6141869],
                [108.8378255, 13.6149252],
                [108.8369744, 13.6162787],
                [108.8354767, 13.6190627],
                [108.8347832, 13.620047],
                [108.8346254, 13.6205394],
                [108.8347197, 13.6208779],
                [108.8351284, 13.6217399],
                [108.8353485, 13.6223246],
                [108.8353481, 13.6228786],
                [108.8352219, 13.6233093],
                [108.8345286, 13.6238629],
                [108.8331424, 13.6247543],
                [108.8327013, 13.6250926],
                [108.8324176, 13.6256156],
                [108.8321965, 13.6263847],
                [108.8315027, 13.6277691],
                [108.8302104, 13.6293992],
                [108.8292963, 13.6305064],
                [108.8290754, 13.6311834],
                [108.829138, 13.6316757],
                [108.8297987, 13.6326302],
                [108.8301763, 13.6331228],
                [108.830176, 13.6335229],
                [108.8298922, 13.6340151],
                [108.8289149, 13.6354608],
                [108.828473, 13.6367837],
                [108.8282677, 13.6376299],
                [108.827385, 13.6388603],
                [108.8258091, 13.6406133],
                [108.8254307, 13.6413515],
                [108.8253554, 13.6417855],
                [108.8254614, 13.6422748],
                [108.8255863, 13.6436598],
                [108.8255385, 13.6444752],
                [108.8249076, 13.6459211],
                [108.8245289, 13.6469056],
                [108.8242763, 13.647921],
                [108.8240553, 13.6484748],
                [108.8237088, 13.6488129],
                [108.8231731, 13.6489972],
                [108.8226375, 13.6493662],
                [108.8222592, 13.649889],
                [108.8219279, 13.650612],
                [108.8216746, 13.6524275],
                [108.8211377, 13.6543659],
                [108.8206959, 13.6555349],
                [108.8201911, 13.656704],
                [108.8199385, 13.6575654],
                [108.8198746, 13.6586733],
                [108.8199682, 13.659812],
                [108.8200301, 13.6612584],
                [108.8203446, 13.6619664],
                [108.8208796, 13.6623977],
                [108.8216669, 13.6627675],
                [108.8222652, 13.6628602],
                [108.82258, 13.6630144],
                [108.8225799, 13.6631373],
                [108.8224852, 13.6633836],
                [108.8219494, 13.6638448],
                [108.8216341, 13.6643368],
                [108.8214761, 13.6650138],
                [108.8214442, 13.6655985],
                [108.8214123, 13.6660601],
                [108.8213178, 13.6662139],
                [108.8208137, 13.6662751],
                [108.8204357, 13.6663055],
                [108.8201837, 13.6664285],
                [108.820152, 13.6667978],
                [108.820246, 13.6673824],
                [108.8208269, 13.6697064],
                [108.8209837, 13.6705989],
                [108.8214864, 13.6723225],
                [108.8215488, 13.6731535],
                [108.8214537, 13.6738612],
                [108.8206646, 13.6761072],
                [108.8198122, 13.6785992],
                [108.8190239, 13.6797988],
                [108.8183932, 13.68066],
                [108.8181092, 13.6813061],
                [108.8180458, 13.6817676],
                [108.8180766, 13.6827832],
                [108.8186742, 13.6838608],
                [108.8187366, 13.6847224],
                [108.8187362, 13.6852456],
                [108.8185151, 13.6860455],
                [108.8178048, 13.6879375],
                [108.8165742, 13.6907986],
                [108.8152494, 13.6931673],
                [108.8141142, 13.6946435],
                [108.8137988, 13.6951973],
                [108.8137668, 13.6957511],
                [108.8139238, 13.696459],
                [108.8142383, 13.6971363],
                [108.8149621, 13.6978754],
                [108.8161586, 13.6986457],
                [108.8173865, 13.6994159],
                [108.8183, 13.6995397],
                [108.8195602, 13.6994482],
                [108.8216394, 13.6993267],
                [108.8228678, 13.6994198],
                [108.8242847, 13.7002209],
                [108.8252919, 13.7013295],
                [108.8270554, 13.7021617],
                [108.8296692, 13.7033021],
                [108.8306139, 13.7037951],
                [108.8312435, 13.7043803],
                [108.8321246, 13.7056427],
                [108.8325493, 13.7065507],
                [108.8326739, 13.7084895],
                [108.832642, 13.7091358],
                [108.8324524, 13.7099665],
                [108.8321054, 13.7106126],
                [108.8317583, 13.7114124],
                [108.8316317, 13.7122124],
                [108.8315992, 13.7136281],
                [108.8315672, 13.714305],
                [108.8314723, 13.7149204],
                [108.8312514, 13.7154434],
                [108.8305576, 13.716397],
                [108.8298324, 13.7173504],
                [108.8279084, 13.7204264],
                [108.8269311, 13.721349],
                [108.8265526, 13.7218719],
                [108.8265208, 13.7223335],
                [108.8265836, 13.7226105],
                [108.8270242, 13.7232263],
                [108.8277482, 13.7239961],
                [108.8288819, 13.7246739],
                [108.8299212, 13.725167],
                [108.8312435, 13.7264296],
                [108.8328494, 13.7277232],
                [108.8331796, 13.7284159],
                [108.8332731, 13.7299238],
                [108.8332088, 13.7316471],
                [108.833429, 13.7323242],
                [108.8339326, 13.73294],
                [108.8341528, 13.733371],
                [108.8342471, 13.7337405],
                [108.8342154, 13.7340173],
                [108.8340888, 13.7348174],
                [108.8339623, 13.735402],
                [108.8338359, 13.7360482],
                [108.8338356, 13.7364175],
                [108.8340874, 13.7368792],
                [108.8343077, 13.7371871],
                [108.8348432, 13.7374029],
                [108.8352841, 13.7376186],
                [108.8354414, 13.7378956],
                [108.8354409, 13.7386958],
                [108.8353767, 13.7403575],
                [108.8353449, 13.7408191],
                [108.8350924, 13.7413421],
                [108.8341782, 13.74208],
                [108.8334531, 13.7427565],
                [108.833248, 13.743141],
                [108.8329633, 13.7446488],
                [108.8330882, 13.7463414],
                [108.8331504, 13.7476032],
                [108.8336218, 13.7492345],
                [108.8342041, 13.7500196],
                [108.8345185, 13.7511276],
                [108.8345179, 13.7518355],
                [108.8343285, 13.7523893],
                [108.8339496, 13.7535277],
                [108.8337284, 13.7543891],
                [108.8337594, 13.7551893],
                [108.8340265, 13.7563743],
                [108.8340261, 13.7568775],
                [108.8340255, 13.7576667],
                [108.8336147, 13.7592051],
                [108.8331729, 13.7602818],
                [108.832826, 13.7606202],
                [108.8325423, 13.7606816],
                [108.8318808, 13.7606196],
                [108.8292342, 13.7602792],
                [108.8287931, 13.7602481],
                [108.8284465, 13.7603094],
                [108.8282888, 13.760494],
                [108.8282886, 13.7607094],
                [108.8282883, 13.7610787],
                [108.8282881, 13.7613248],
                [108.8280988, 13.7616633],
                [108.8278465, 13.7621247],
                [108.8277201, 13.7625862],
                [108.8277197, 13.7630785],
                [108.8276563, 13.7635709],
                [108.827467, 13.7639708],
                [108.8270571, 13.7644014],
                [108.8268677, 13.7647397],
                [108.8268359, 13.7652013],
                [108.8268356, 13.765509],
                [108.8270244, 13.7660016],
                [108.8271183, 13.7667402],
                [108.8270231, 13.7678172],
                [108.8267704, 13.7685556],
                [108.8259505, 13.7694475],
                [108.8255089, 13.7700319],
                [108.8254771, 13.7704626],
                [108.825571, 13.7712936],
                [108.8255388, 13.7721553],
                [108.8253492, 13.7728629],
                [108.8247813, 13.7738165],
                [108.8244654, 13.7748626],
                [108.8243545, 13.7757087],
                [108.8246999, 13.7773707],
                [108.8248567, 13.7784171],
                [108.8262733, 13.7802339],
                [108.8277689, 13.7820351],
                [108.8280203, 13.7828354],
                [108.8281769, 13.7843126],
                [108.8280816, 13.7851742],
                [108.8277346, 13.7857279],
                [108.827608, 13.7864664],
                [108.8276392, 13.7870819],
                [108.8279538, 13.7876976],
                [108.8281108, 13.788467],
                [108.8281254, 13.7899904],
                [108.8279039, 13.7912827],
                [108.8276828, 13.7919903],
                [108.8272411, 13.7927285],
                [108.8264843, 13.7933127],
                [108.8251289, 13.7937734],
                [108.8237738, 13.7938032],
                [108.8234586, 13.7938645],
                [108.8232063, 13.7940797],
                [108.8228435, 13.794618],
                [108.8226221, 13.7956026],
                [108.8226213, 13.7966488],
                [108.8226523, 13.7973567],
                [108.8228095, 13.7978799],
                [108.8229037, 13.7984339],
                [108.8228717, 13.7989262],
                [108.8226193, 13.79948],
                [108.8222092, 13.7999105],
                [108.8216732, 13.8002487],
                [108.8205858, 13.8004171],
                [108.8192935, 13.8006931],
                [108.8183162, 13.8011232],
                [108.81778, 13.8016768],
                [108.8173067, 13.8023534],
                [108.8164552, 13.8032145],
                [108.8156355, 13.8035523],
                [108.8144693, 13.8037977],
                [108.8135867, 13.8038892],
                [108.8126099, 13.8037962],
                [108.8112551, 13.8033336],
                [108.8097745, 13.8025324],
                [108.8089553, 13.8024086],
                [108.8081357, 13.8026234],
                [108.8058816, 13.8035294],
                [108.8053141, 13.8038059],
                [108.8049357, 13.8041133],
                [108.8046515, 13.8047901],
                [108.804273, 13.8051591],
                [108.8038946, 13.8053127],
                [108.8030749, 13.8057737],
                [108.8019714, 13.8062959],
                [108.8011516, 13.8067877],
                [108.8007729, 13.8074951],
                [108.8006936, 13.8079413],
                [108.8006605, 13.8100031],
                [108.8004707, 13.81068],
                [108.7996192, 13.811387],
                [108.7989887, 13.8115711],
                [108.7984525, 13.8120015],
                [108.7979792, 13.8126781],
                [108.7976636, 13.8131395],
                [108.7970016, 13.8134775],
                [108.7964498, 13.8137693],
                [108.7961974, 13.8140407],
                [108.7959131, 13.8147169],
                [108.7955333, 13.8165924],
                [108.7952479, 13.8185602],
                [108.7946794, 13.8199742],
                [108.7946789, 13.8205893],
                [108.7948044, 13.8211736],
                [108.7952137, 13.8216044],
                [108.7958269, 13.8220886],
                [108.7960326, 13.822251],
                [108.7963646, 13.8227346],
                [108.7963634, 13.8241688],
                [108.7960675, 13.8262584],
                [108.7957728, 13.8271186],
                [108.7950996, 13.8284704],
                [108.7949733, 13.82888],
                [108.794973, 13.8292078],
                [108.7951404, 13.8298226],
                [108.7952241, 13.8301505],
                [108.7952659, 13.8303963],
                [108.7951816, 13.8307241],
                [108.7944243, 13.8322806],
                [108.7941293, 13.8333867],
                [108.7940435, 13.8339612],
                [108.7940433, 13.8342357],
                [108.7942119, 13.834428],
                [108.7944932, 13.8344282],
                [108.7948872, 13.8342637],
                [108.7956752, 13.8338253],
                [108.7960693, 13.8336885],
                [108.7963504, 13.8336612],
                [108.7966037, 13.8336889],
                [108.7968847, 13.8339087],
                [108.7969127, 13.8341282],
                [108.7968279, 13.8345673],
                [108.7964619, 13.8349238],
                [108.7948287, 13.8367338],
                [108.7944346, 13.8370902],
                [108.7938154, 13.8374466],
                [108.7934494, 13.8376933],
                [108.7932946, 13.8379314],
                [108.7931814, 13.8385906],
                [108.7931802, 13.8399364],
                [108.7929826, 13.8406778],
                [108.7927849, 13.841529],
                [108.7927847, 13.8417762],
                [108.7929252, 13.8420509],
                [108.7937684, 13.8427657],
                [108.7938526, 13.8431229],
                [108.7938943, 13.8436584],
                [108.7938091, 13.8445099],
                [108.7938371, 13.8447296],
                [108.7939776, 13.844867],
                [108.7941183, 13.8448946],
                [108.7945685, 13.8448401],
                [108.7960601, 13.8441272],
                [108.7965665, 13.8440453],
                [108.7967354, 13.8440454],
                [108.7969602, 13.8442653],
                [108.7970443, 13.8445674],
                [108.797016, 13.8447872],
                [108.7961991, 13.8459949],
                [108.795213, 13.847697],
                [108.7947622, 13.8484382],
                [108.794354, 13.8489185],
                [108.7937066, 13.8492201],
                [108.7928059, 13.8496588],
                [108.7924398, 13.8500979],
                [108.7919894, 13.8503722],
                [108.7914548, 13.8505365],
                [108.790695, 13.8506733],
                [108.7901322, 13.8508924],
                [108.7896254, 13.8513314],
                [108.7892873, 13.851853],
                [108.7891744, 13.8522374],
                [108.789174, 13.8526219],
                [108.7893708, 13.8529517],
                [108.78968, 13.8531991],
                [108.7899611, 13.8534191],
                [108.7900454, 13.8535565],
                [108.7900452, 13.8537488],
                [108.7898057, 13.8541193],
                [108.789271, 13.8543661],
                [108.7886518, 13.8545578],
                [108.7884268, 13.8545576],
                [108.7880332, 13.8542277],
                [108.7876397, 13.8538153],
                [108.7868244, 13.8532104],
                [108.7865432, 13.8531003],
                [108.7862619, 13.8530726],
                [108.7859804, 13.8531547],
                [108.7854457, 13.8533739],
                [108.7849105, 13.8540052],
                [108.7840377, 13.8548559],
                [108.7831368, 13.8554593],
                [108.7816165, 13.8566114],
                [108.7795189, 13.8584086],
                [108.7780543, 13.860165],
                [108.7767869, 13.861702],
                [108.7755338, 13.8627582],
                [108.7748577, 13.8636914],
                [108.7742382, 13.8642676],
                [108.7740973, 13.8643774],
                [108.7727593, 13.8655501],
                [108.7717391, 13.8665433],
                [108.7712192, 13.8672182],
                [108.7709493, 13.8678184],
                [108.770891, 13.8684373],
                [108.7708715, 13.8686813],
                [108.7708136, 13.8689063],
                [108.7704093, 13.8693562],
                [108.7698316, 13.8702372],
                [108.7689355, 13.8719389],
                [108.7685689, 13.8731213],
                [108.7681636, 13.8746415],
                [108.7677867, 13.8765279],
                [108.7675359, 13.8772975],
                [108.7672854, 13.877729],
                [108.7669387, 13.8782169],
                [108.7667759, 13.8786126],
                [108.7664557, 13.8792468],
                [108.7658233, 13.8800778],
                [108.7653037, 13.8810381],
                [108.7644517, 13.8830859],
                [108.7642429, 13.883729],
                [108.7642421, 13.8845756],
                [108.7644149, 13.885253],
                [108.7648462, 13.8875053],
                [108.7648792, 13.8891306],
                [108.7647741, 13.8902819],
                [108.7645651, 13.8910605],
                [108.764287, 13.8916697],
                [108.7631412, 13.8926505],
                [108.7613016, 13.8934954],
                [108.7606421, 13.8939009],
                [108.760208, 13.8943577],
                [108.7596862, 13.8957794],
                [108.7589907, 13.8973364],
                [108.7583306, 13.8982838],
                [108.7577751, 13.8986896],
                [108.756491, 13.8991286],
                [108.7555189, 13.8998048],
                [108.755137, 13.9001769],
                [108.7544081, 13.9003455],
                [108.7537838, 13.9002432],
                [108.752986, 13.9001747],
                [108.7517717, 13.9002751],
                [108.7510428, 13.9006469],
                [108.7501224, 13.9015094],
                [108.7492194, 13.9024903],
                [108.7483165, 13.9033361],
                [108.7473434, 13.9049265],
                [108.7464052, 13.9062123],
                [108.745294, 13.9071254],
                [108.7445815, 13.9082929],
                [108.743956, 13.9092743],
                [108.7439207, 13.909782],
                [108.7448557, 13.9114085],
                [108.7457219, 13.9124253],
                [108.7460333, 13.9132382],
                [108.7460671, 13.914017],
                [108.7459098, 13.9151175],
                [108.745778, 13.915838],
                [108.7455258, 13.9172164],
                [108.7453858, 13.9183338],
                [108.745489, 13.9192143],
                [108.7459733, 13.9204676],
                [108.7463538, 13.9215516],
                [108.7463007, 13.9226182],
                [108.7460221, 13.9234306],
                [108.7456393, 13.9244799],
                [108.745673, 13.9254619],
                [108.7459495, 13.9264104],
                [108.7460173, 13.9277987],
                [108.745443, 13.9294742],
                [108.7447127, 13.9310988],
                [108.7445715, 13.9332658],
                [108.7447092, 13.934248],
                [108.7451935, 13.9355352],
                [108.7463195, 13.9370433],
                [108.7480866, 13.9393815],
                [108.7481556, 13.9398556],
                [108.7480162, 13.9403635],
                [108.7473205, 13.9419542],
                [108.7466591, 13.9438837],
                [108.746641, 13.9444423],
                [108.7468834, 13.9450183],
                [108.7476459, 13.945764],
                [108.7488591, 13.9469504],
                [108.7492051, 13.9479327],
                [108.7492736, 13.9487116],
                [108.749481, 13.9494229],
                [108.7497932, 13.9495925],
                [108.750418, 13.9494577],
                [108.7510431, 13.9489843],
                [108.7535786, 13.9466166],
                [108.7543077, 13.9462449],
                [108.7545852, 13.9463467],
                [108.7551745, 13.9468891],
                [108.7553478, 13.9470586],
                [108.7555209, 13.9473974],
                [108.7556935, 13.9483118],
                [108.7555537, 13.9491583],
                [108.7546675, 13.9505287],
                [108.7539023, 13.9521533],
                [108.7536587, 13.9528981],
                [108.753519, 13.9537444],
                [108.7531013, 13.9549292],
                [108.7524405, 13.9563507],
                [108.7524916, 13.9571803],
                [108.7533578, 13.9585018],
                [108.7546734, 13.9613475],
                [108.7554168, 13.9638709],
                [108.7560623, 13.9661177],
                [108.7570658, 13.9689291],
                [108.7572735, 13.9694034],
                [108.757169, 13.9698434],
                [108.7565611, 13.9703677],
                [108.7559706, 13.9709089],
                [108.755623, 13.9714504],
                [108.7549269, 13.9734475],
                [108.7540226, 13.9752412],
                [108.7529108, 13.9764929],
                [108.7524069, 13.977119],
                [108.7520925, 13.9790826],
                [108.7518476, 13.9809109],
                [108.7520898, 13.9815544],
                [108.7527487, 13.982097],
                [108.7563374, 13.9850973],
                [108.7594579, 13.9879109],
                [108.7624909, 13.9916218],
                [108.7636697, 13.9927742],
                [108.7640853, 13.9935196],
                [108.7640849, 13.9939597],
                [108.7638762, 13.9943997],
                [108.7625212, 13.9959561],
                [108.7601064, 13.9984086],
                [108.7580205, 14.0017926],
                [108.7573246, 14.0035528],
                [108.7572365, 14.0037942],
                [108.7567502, 14.0051267],
                [108.7566096, 14.0067858],
                [108.7566086, 14.0078016],
                [108.7563651, 14.0082755],
                [108.7557746, 14.0086812],
                [108.75508, 14.009053],
                [108.7547672, 14.009459],
                [108.7546972, 14.0100346],
                [108.7549395, 14.0106782],
                [108.7549391, 14.0110168],
                [108.7545737, 14.0119476],
                [108.7540868, 14.0127599],
                [108.7541211, 14.0132678],
                [108.7543285, 14.0140129],
                [108.7543972, 14.014758],
                [108.754362, 14.0151643],
                [108.753806, 14.015807],
                [108.7526941, 14.0167878],
                [108.7523113, 14.0177356],
                [108.7522236, 14.0186328],
                [108.7523618, 14.0192425],
                [108.7526043, 14.0196491],
                [108.7534022, 14.0201578],
                [108.7547205, 14.0207349],
                [108.7555877, 14.0212436],
                [108.7559344, 14.0216165],
                [108.7561073, 14.0223277],
                [108.756176, 14.0229711],
                [108.7567641, 14.0248003],
                [108.7574394, 14.0264093],
                [108.7576469, 14.0270529],
                [108.7575771, 14.0274253],
                [108.7570906, 14.0280344],
                [108.7569509, 14.0287792],
                [108.7570195, 14.0294565],
                [108.7573516, 14.0307549],
                [108.7575914, 14.0320154],
                [108.7576131, 14.032197],
                [108.7576346, 14.0324748],
                [108.7576234, 14.0327097],
                [108.7574141, 14.0339097],
                [108.7575168, 14.0352303],
                [108.7576201, 14.0360769],
                [108.7574804, 14.0368556],
                [108.7569938, 14.0374308],
                [108.7557949, 14.0387332],
                [108.753674, 14.0418463],
                [108.7536388, 14.042388],
                [108.7541229, 14.0440817],
                [108.7545386, 14.0449624],
                [108.7545035, 14.0452671],
                [108.7542951, 14.0453685],
                [108.7528893, 14.0452824],
                [108.7514313, 14.045281],
                [108.7505286, 14.0454154],
                [108.7476805, 14.0468008],
                [108.7414811, 14.0494862],
                [108.7392872, 14.0503133],
                [108.7361267, 14.0516304],
                [108.7345288, 14.0524414],
                [108.7341209, 14.0527708],
                [108.7328957, 14.0537601],
                [108.7307581, 14.0560434],
                [108.7285682, 14.0584789],
                [108.7250396, 14.0625552],
                [108.7233687, 14.0642218],
                [108.722502, 14.0652274],
                [108.7209364, 14.0679345],
                [108.7199447, 14.0698804],
                [108.7191791, 14.0713694],
                [108.7184821, 14.073434],
                [108.7181146, 14.0759225],
                [108.7177284, 14.079342],
                [108.7173094, 14.0813393],
                [108.7161792, 14.0828109],
                [108.715761, 14.0840972],
                [108.7157591, 14.0855871],
                [108.7156522, 14.0878556],
                [108.7151815, 14.0894634],
                [108.7139575, 14.0931383],
                [108.7132298, 14.0952514],
                [108.7127251, 14.0962496],
                [108.7122385, 14.0966893],
                [108.7113697, 14.0972638],
                [108.7103969, 14.0977706],
                [108.7098057, 14.098481],
                [108.7087614, 14.1005791],
                [108.707543, 14.1030156],
                [108.7071605, 14.1034892],
                [108.7068131, 14.1035565],
                [108.7065701, 14.1035224],
                [108.7059455, 14.1032169],
                [108.7053204, 14.1032162],
                [108.7047125, 14.103463],
                [108.7035658, 14.1041383],
                [108.70155, 14.1055231],
                [108.7006113, 14.1065369],
                [108.699985, 14.1074498],
                [108.6995321, 14.1086335],
                [108.6992564, 14.1097905],
                [108.6992178, 14.1099527],
                [108.6988681, 14.1117808],
                [108.6989731, 14.113059],
                [108.6989276, 14.1140076],
                [108.6987278, 14.1144817],
                [108.6985285, 14.114654],
                [108.6977759, 14.114847],
                [108.6967084, 14.1149008],
                [108.6932797, 14.1151312],
                [108.6921367, 14.1153056],
                [108.6918357, 14.1155985],
                [108.692015, 14.1164785],
                [108.6921338, 14.1174757],
                [108.6923128, 14.1185902],
                [108.6924921, 14.1194116],
                [108.6928523, 14.1199398],
                [108.6932123, 14.1205855],
                [108.6932119, 14.1208787],
                [108.6928504, 14.1214061],
                [108.6917956, 14.122959],
                [108.6897474, 14.125361],
                [108.6873376, 14.1281731],
                [108.6869473, 14.1286111],
                [108.6857713, 14.1299306],
                [108.684566, 14.1315711],
                [108.6835707, 14.1336519],
                [108.6829673, 14.135],
                [108.6831472, 14.1354696],
                [108.6837478, 14.1361155],
                [108.6839275, 14.1366435],
                [108.6838057, 14.1376991],
                [108.6835622, 14.1398102],
                [108.68332, 14.1409829],
                [108.6828979, 14.1417448],
                [108.6822048, 14.1427115],
                [108.6800356, 14.1453479],
                [108.6775652, 14.1482185],
                [108.6769621, 14.149332],
                [108.6767805, 14.1501529],
                [108.6768999, 14.1508568],
                [108.67756, 14.1518548],
                [108.6778298, 14.1525778],
                [108.677948, 14.1540431],
                [108.6783658, 14.156388],
                [108.6786646, 14.1577948],
                [108.6787224, 14.159436],
                [108.6787812, 14.1604323],
                [108.6786603, 14.1608424],
                [108.6784194, 14.1610179],
                [108.6772162, 14.1610749],
                [108.676374, 14.1610738],
                [108.6749905, 14.1609547],
                [108.672705, 14.1607172],
                [108.6720431, 14.1607748],
                [108.67114, 14.1613011],
                [108.670116, 14.1621201],
                [108.6671049, 14.164343],
                [108.6662012, 14.1652209],
                [108.6661596, 14.165881],
                [108.6675467, 14.167417],
                [108.6695337, 14.1705782],
                [108.6711981, 14.1726108],
                [108.6722611, 14.1741915],
                [108.6730011, 14.1749144],
                [108.6754992, 14.1771286],
                [108.6765865, 14.1778831],
                [108.6771052, 14.1782001],
                [108.6772825, 14.1791994],
                [108.6771329, 14.1795381],
                [108.6767593, 14.1800019],
                [108.6765639, 14.1803012],
                [108.6760722, 14.1808446],
                [108.6758292, 14.1811153],
                [108.6753776, 14.1816015],
                [108.6747915, 14.1821488],
                [108.674035, 14.1828832],
                [108.6738475, 14.1830751],
                [108.6735587, 14.1833706],
                [108.6737851, 14.183563],
                [108.6739063, 14.1838151],
                [108.6739513, 14.1841705],
                [108.6739587, 14.1844298],
                [108.6740037, 14.184726],
                [108.6741251, 14.1849631],
                [108.6743525, 14.1852153],
                [108.6746408, 14.1854971],
                [108.6751873, 14.1858236],
                [108.6757034, 14.1861946],
                [108.6759765, 14.1864468],
                [108.6761737, 14.1866692],
                [108.676401, 14.1870842],
                [108.6766433, 14.1876474],
                [108.6769693, 14.1881589],
                [108.6772423, 14.1884703],
                [108.6774245, 14.1886335],
                [108.6782442, 14.1891085],
                [108.6787301, 14.1893757],
                [108.6792157, 14.1897467],
                [108.6795193, 14.1900136],
                [108.6798832, 14.1904881],
                [108.6802848, 14.191207],
                [108.680497, 14.1916664],
                [108.6812546, 14.1931634],
                [108.6813299, 14.1935782],
                [108.6813143, 14.1939337],
                [108.681253, 14.1943187],
                [108.681108, 14.1947554],
                [108.6809556, 14.1950811],
                [108.6804528, 14.1960728],
                [108.6803763, 14.1964134],
                [108.6803457, 14.1966355],
                [108.6804363, 14.1970207],
                [108.6806788, 14.197495],
                [108.6807695, 14.1978209],
                [108.6807765, 14.1982726],
                [108.6807457, 14.1985393],
                [108.6806236, 14.1988501],
                [108.6803953, 14.1992053],
                [108.680167, 14.199442],
                [108.6800147, 14.199664],
                [108.6799382, 14.2000341],
                [108.6799225, 14.2004044],
                [108.6799522, 14.2009228],
                [108.6801102, 14.2019821],
                [108.6802158, 14.2025598],
                [108.6802154, 14.2028264],
                [108.6801694, 14.2031226],
                [108.6800781, 14.2032558],
                [108.6798956, 14.2033444],
                [108.6795916, 14.2033737],
                [108.6791206, 14.2033434],
                [108.6785738, 14.2032538],
                [108.6782395, 14.2032533],
                [108.677981, 14.2033122],
                [108.6777986, 14.2034009],
                [108.6776617, 14.2035487],
                [108.6775702, 14.2037708],
                [108.6775992, 14.2046743],
                [108.6775303, 14.2050519],
                [108.6773324, 14.2053627],
                [108.6770889, 14.2055993],
                [108.6766479, 14.2058505],
                [108.6755837, 14.2062786],
                [108.6750059, 14.2065],
                [108.674717, 14.2066329],
                [108.6744736, 14.2068251],
                [108.6742606, 14.2070174],
                [108.6740475, 14.2072541],
                [108.6739103, 14.207565],
                [108.6738795, 14.2078167],
                [108.6738942, 14.2081721],
                [108.6739925, 14.20855],
                [108.6742348, 14.2090983],
                [108.6752808, 14.2109068],
                [108.6757355, 14.2116775],
                [108.675993, 14.2121963],
                [108.6760686, 14.2125075],
                [108.6760681, 14.2128926],
                [108.6760211, 14.2138551],
                [108.6760206, 14.2142107],
                [108.6761417, 14.2145366],
                [108.676278, 14.2148182],
                [108.6764752, 14.2151],
                [108.6769299, 14.2159448],
                [108.6772026, 14.2165525],
                [108.6773688, 14.2172044],
                [108.6774134, 14.2178414],
                [108.6773522, 14.2181819],
                [108.6772834, 14.2184115],
                [108.6769789, 14.2189294],
                [108.6768872, 14.2192255],
                [108.6767955, 14.2196401],
                [108.6767338, 14.2202473],
                [108.6766422, 14.2205582],
                [108.6765049, 14.2208986],
                [108.6763981, 14.2212392],
                [108.6763216, 14.2216093],
                [108.6762754, 14.2220387],
                [108.6762439, 14.2228089],
                [108.6761519, 14.2234013],
                [108.6759992, 14.223875],
                [108.6757861, 14.224171],
                [108.6753144, 14.2245554],
                [108.6748278, 14.2247473],
                [108.673931, 14.224909],
                [108.6719097, 14.2250247],
                [108.6712715, 14.2250534],
                [108.6710282, 14.225142],
                [108.6707545, 14.2253046],
                [108.6704957, 14.2256153],
                [108.6702824, 14.2259408],
                [108.6701603, 14.2262813],
                [108.6700836, 14.2267552],
                [108.670083, 14.2271846],
                [108.6701277, 14.2278364],
                [108.6701648, 14.2284337],
                [108.6701944, 14.2289665],
                [108.6702544, 14.2295438],
                [108.67033, 14.2298252],
                [108.6703296, 14.2300324],
                [108.6702686, 14.2301951],
                [108.6701012, 14.2303725],
                [108.6690057, 14.231333],
                [108.6676517, 14.2322931],
                [108.6670584, 14.2327215],
                [108.6664806, 14.2329723],
                [108.665264, 14.2335182],
                [108.6651859, 14.2335656],
                [108.6651797, 14.2341677],
                [108.6651541, 14.2347245],
                [108.6653021, 14.235451],
                [108.6656477, 14.2369041],
                [108.66654, 14.2383096],
                [108.667036, 14.2388913],
                [108.6674568, 14.239933],
                [108.6680495, 14.2423065],
                [108.6682956, 14.2438563],
                [108.6681955, 14.2443888],
                [108.6679467, 14.2446791],
                [108.6668526, 14.2453554],
                [108.6663553, 14.2456937],
                [108.6660564, 14.2462259],
                [108.6657574, 14.2468066],
                [108.6656326, 14.2471695],
                [108.6655294, 14.2497842],
                [108.6653286, 14.2511881],
                [108.6652286, 14.2516238],
                [108.6649301, 14.2518171],
                [108.6641845, 14.2520582],
                [108.6625442, 14.2524432],
                [108.6612019, 14.2529739],
                [108.6594611, 14.2541336],
                [108.6584164, 14.2550037],
                [108.6578939, 14.2555114],
                [108.6572459, 14.2568662],
                [108.6567477, 14.2577372],
                [108.6559011, 14.2589464],
                [108.655602, 14.2596239],
                [108.6556013, 14.2600597],
                [108.6560962, 14.2613678],
                [108.6566651, 14.2630876],
                [108.6568619, 14.2643469],
                [108.656712, 14.2649277],
                [108.6554174, 14.266669],
                [108.6549181, 14.2682178],
                [108.6547679, 14.268944],
                [108.6549656, 14.2696706],
                [108.6552874, 14.2704215],
                [108.6554843, 14.2716323],
                [108.6555314, 14.2733756],
                [108.6556794, 14.2741022],
                [108.6559275, 14.2743446],
                [108.6572191, 14.2745402],
                [108.6580136, 14.2749287],
                [108.6584601, 14.275462],
                [108.6589551, 14.2766732],
                [108.6592769, 14.2775695],
                [108.6600697, 14.2790717],
                [108.6604654, 14.2802828],
                [108.6604648, 14.2806701],
                [108.6602657, 14.280912],
                [108.6593202, 14.2817338],
                [108.6572014, 14.2835794],
                [108.6559864, 14.2845859],
                [108.6555383, 14.2851663],
                [108.6554131, 14.2857714],
                [108.6556102, 14.2868853],
                [108.6561052, 14.2881936],
                [108.6560545, 14.2888229],
                [108.6557554, 14.289452],
                [108.6552569, 14.290468],
                [108.6548083, 14.2913875],
                [108.6537377, 14.2928143],
                [108.6532896, 14.2932979],
                [108.6526428, 14.2937812],
                [108.650026, 14.2948694],
                [108.649597, 14.2950478],
                [108.6495099, 14.295084],
                [108.6481668, 14.2959536],
                [108.6473704, 14.2967272],
                [108.6469221, 14.2974043],
                [108.6466722, 14.2983241],
                [108.6465714, 14.2991954],
                [108.6465952, 14.2998492],
                [108.6466401, 14.3029483],
                [108.6466361, 14.3055146],
                [108.6464856, 14.3064344],
                [108.6460123, 14.3071358],
                [108.6454151, 14.3077159],
                [108.6447184, 14.3082476],
                [108.6442205, 14.3087794],
                [108.6437717, 14.3097472],
                [108.6430738, 14.3111504],
                [108.6427741, 14.3120699],
                [108.6429221, 14.3127965],
                [108.6433186, 14.3134266],
                [108.6440876, 14.3142751],
                [108.6454765, 14.3160688],
                [108.6474615, 14.3179601],
                [108.6478582, 14.3185902],
                [108.6479564, 14.3193652],
                [108.6478059, 14.3202849],
                [108.6474565, 14.321156],
                [108.6470332, 14.3217122],
                [108.6462865, 14.3224858],
                [108.6449423, 14.323888],
                [108.6442454, 14.3245165],
                [108.6426037, 14.3255308],
                [108.6416082, 14.3263525],
                [108.6406123, 14.3275616],
                [108.6403131, 14.3281906],
                [108.6400877, 14.3292797],
                [108.6398866, 14.3306836],
                [108.6397364, 14.3314098],
                [108.6390384, 14.3327645],
                [108.6371684, 14.3364659],
                [108.6361703, 14.3389823],
                [108.6351705, 14.3424186],
                [108.6348204, 14.3438223],
                [108.6345948, 14.3450084],
                [108.6345925, 14.3464125],
                [108.6342428, 14.3474773],
                [108.6338439, 14.3482513],
                [108.632748, 14.3496055],
                [108.6318521, 14.3503789],
                [108.6308073, 14.3509584],
                [108.6290169, 14.3514882],
                [108.6276738, 14.3520187],
                [108.6272258, 14.3524053],
                [108.6258971, 14.3532932],
                [108.6244697, 14.3547934],
                [108.6237558, 14.3557169],
                [108.6245844, 14.3572206],
                [108.6254137, 14.3583777],
                [108.6257487, 14.3588188],
                [108.6260956, 14.3594488],
                [108.6267405, 14.3603215],
                [108.627559, 14.3613637],
                [108.6288979, 14.3633512],
                [108.6295422, 14.3645143],
                [108.6301863, 14.3657743],
                [108.6309293, 14.3675186],
                [108.6314749, 14.3682457],
                [108.6321449, 14.368949],
                [108.6338816, 14.3708885],
                [108.6342779, 14.3717606],
                [108.6342772, 14.3721964],
                [108.6337287, 14.3732124],
                [108.6326325, 14.3747602],
                [108.6313866, 14.3765982],
                [108.6308631, 14.377533],
                [108.6302603, 14.3782939],
                [108.6296777, 14.3793536],
                [108.6295309, 14.3803433],
                [108.6288758, 14.3813322],
                [108.6276392, 14.3828152],
                [108.6269114, 14.3838747],
                [108.6263896, 14.3845155],
                [108.6262164, 14.384963],
                [108.6256789, 14.3854848],
                [108.6254637, 14.3858428],
                [108.6254631, 14.3861714],
                [108.6255239, 14.3864999],
                [108.6258608, 14.3867992],
                [108.6262896, 14.3870986],
                [108.6270861, 14.3875179],
                [108.628036, 14.3879674],
                [108.6286793, 14.3883865],
                [108.6288935, 14.3886556],
                [108.6287702, 14.3890437],
                [108.628478, 14.3895659],
                [108.6272181, 14.391117],
                [108.6251737, 14.3942593],
                [108.6240047, 14.396436],
                [108.6218505, 14.4008794],
                [108.6211748, 14.4022828],
                [108.6202665, 14.4045655],
                [108.6191586, 14.4069824],
                [108.6179894, 14.4093693],
                [108.617497, 14.4104434],
                [108.6172821, 14.4106222],
                [108.6169139, 14.410741],
                [108.6164364, 14.410976],
                [108.6159301, 14.4112486],
                [108.6153487, 14.4116122],
                [108.6150296, 14.4119398],
                [108.6147677, 14.4122396],
                [108.6150846, 14.4126692],
                [108.6157061, 14.4131202],
                [108.6161883, 14.4136373],
                [108.61645, 14.4139111],
                [108.6168782, 14.4144316],
                [108.6170327, 14.4145228],
                [108.6173531, 14.4146853],
                [108.6176878, 14.4148204],
                [108.6178597, 14.4148774],
                [108.618323, 14.4150012],
                [108.6185409, 14.4150846],
                [108.618747, 14.4151926],
                [108.6189578, 14.4153393],
                [108.6190552, 14.4154225],
                [108.6192318, 14.4156067],
                [108.6194146, 14.4158506],
                [108.6195127, 14.4159982],
                [108.6196899, 14.4163045],
                [108.6197687, 14.4164626],
                [108.6199057, 14.4167877],
                [108.6192915, 14.4176527],
                [108.6190531, 14.4179396],
                [108.6187924, 14.4182077],
                [108.618511, 14.4184554],
                [108.6182159, 14.4186774],
                [108.6179041, 14.418877],
                [108.6175775, 14.4190529],
                [108.6174488, 14.419601],
                [108.6173952, 14.4199569],
                [108.616215, 14.4222325],
                [108.6160746, 14.4224245],
                [108.6159263, 14.4226109],
                [108.6157703, 14.4227913],
                [108.6156069, 14.4229654],
                [108.6154363, 14.423133],
                [108.6152589, 14.4232937],
                [108.6150749, 14.4234473],
                [108.6148846, 14.4235937],
                [108.61469, 14.4237313],
                [108.6144898, 14.4238613],
                [108.6142843, 14.4239833],
                [108.614074, 14.4240973],
                [108.6138591, 14.424203],
                [108.6136401, 14.4243002],
                [108.6123311, 14.4247262],
                [108.6121926, 14.4247597],
                [108.6119229, 14.42485],
                [108.6117926, 14.4249065],
                [108.611555, 14.4250343],
                [108.6113444, 14.4251789],
                [108.611151, 14.4253445],
                [108.6111494, 14.4254002],
                [108.6111644, 14.4255107],
                [108.6111843, 14.4255737],
                [108.6112475, 14.42569],
                [108.6131036, 14.4279421],
                [108.6131404, 14.4281358],
                [108.6131921, 14.4285266],
                [108.613208, 14.4287401],
                [108.6132136, 14.4291682],
                [108.6129699, 14.4292842],
                [108.6124921, 14.4295344],
                [108.6119632, 14.4298491],
                [108.6117062, 14.4300176],
                [108.6116267, 14.430089],
                [108.6115539, 14.4301669],
                [108.6114885, 14.4302506],
                [108.6114309, 14.4303396],
                [108.6113816, 14.4304332],
                [108.6113167, 14.4306047],
                [108.6112796, 14.4307837],
                [108.6112154, 14.4314125],
                [108.6111982, 14.4315085],
                [108.6111429, 14.4316956],
                [108.6110974, 14.4318022],
                [108.6110427, 14.4319046],
                [108.6109793, 14.4320021],
                [108.6108306, 14.43219],
                [108.6105515, 14.4325785],
                [108.6102844, 14.4330059],
                [108.6101555, 14.4332375],
                [108.6100348, 14.4334731],
                [108.6099226, 14.4337127],
                [108.609819, 14.4339558],
                [108.609724, 14.4342023],
                [108.6096609, 14.4343347],
                [108.609513, 14.4345886],
                [108.6093378, 14.4348257],
                [108.6097079, 14.4351893],
                [108.6102363, 14.4352491],
                [108.6119449, 14.4362387],
                [108.6122256, 14.4365008],
                [108.612518, 14.4367326],
                [108.6128183, 14.4369348],
                [108.6129743, 14.4370273],
                [108.6132967, 14.4371946],
                [108.6136937, 14.4378388],
                [108.6137275, 14.4380558],
                [108.6137259, 14.4382752],
                [108.6136886, 14.4384931],
                [108.6136165, 14.4387026],
                [108.6135113, 14.4388986],
                [108.6126208, 14.4402908],
                [108.6123097, 14.4409246],
                [108.6122668, 14.4410525],
                [108.612215, 14.4411772],
                [108.6121547, 14.4412983],
                [108.612086, 14.4414152],
                [108.6120093, 14.4415272],
                [108.6117113, 14.441899],
                [108.6115732, 14.4420926],
                [108.6113197, 14.442494],
                [108.6112046, 14.4427013],
                [108.6110055, 14.4431118],
                [108.6109174, 14.4433219],
                [108.6107647, 14.4437506],
                [108.6103251, 14.4442769],
                [108.6101147, 14.4445471],
                [108.6097133, 14.4451013],
                [108.6095415, 14.4453561],
                [108.6092142, 14.4458757],
                [108.6090588, 14.4461403],
                [108.6089446, 14.446378],
                [108.6086938, 14.4468427],
                [108.6084139, 14.4472915],
                [108.6081061, 14.4477229],
                [108.607942, 14.4479315],
                [108.6074558, 14.4485045],
                [108.6073096, 14.448698],
                [108.6071716, 14.4488971],
                [108.6070418, 14.4491013],
                [108.6069213, 14.4493091],
                [108.6068093, 14.4495214],
                [108.6067062, 14.4497379],
                [108.606612, 14.4499581],
                [108.6057758, 14.4522597],
                [108.604424, 14.4553453],
                [108.6031902, 14.4573712],
                [108.6030962, 14.4575182],
                [108.6029943, 14.4576603],
                [108.6028849, 14.457797],
                [108.6027682, 14.457928],
                [108.6026445, 14.4580528],
                [108.6023972, 14.4582675],
                [108.6021502, 14.4584443],
                [108.6020207, 14.4585243],
                [108.6019158, 14.4585742],
                [108.6018156, 14.4586325],
                [108.6017209, 14.4586988],
                [108.6016323, 14.4587725],
                [108.6015504, 14.4588533],
                [108.6014759, 14.4589405],
                [108.6013548, 14.4591243],
                [108.6012688, 14.459317],
                [108.6012149, 14.4595204],
                [108.6011946, 14.4597295],
                [108.6014502, 14.4597262],
                [108.6017042, 14.4596983],
                [108.601856, 14.4596696],
                [108.6020058, 14.459632],
                [108.6021529, 14.4595858],
                [108.6022968, 14.4595311],
                [108.6024371, 14.4594681],
                [108.602752, 14.4593118],
                [108.6031198, 14.4591637],
                [108.6048339, 14.4586124],
                [108.605108, 14.4584953],
                [108.6052394, 14.4584256],
                [108.6053731, 14.4583446],
                [108.6055016, 14.458256],
                [108.6056243, 14.4581601],
                [108.6057409, 14.4580572],
                [108.607353, 14.4564465],
                [108.6075696, 14.4562534],
                [108.6077928, 14.4560673],
                [108.6082381, 14.4557313],
                [108.6086786, 14.4554227],
                [108.6090864, 14.4551057],
                [108.6092842, 14.4549401],
                [108.6096275, 14.4547115],
                [108.609833, 14.4546294],
                [108.6099402, 14.4546007],
                [108.61016, 14.454569],
                [108.6102712, 14.4545661],
                [108.6104945, 14.4545868],
                [108.6107113, 14.4546425],
                [108.6108155, 14.454683],
                [108.6110118, 14.454788],
                [108.6111027, 14.4548518],
                [108.6124277, 14.4561349],
                [108.6126777, 14.4563552],
                [108.6128117, 14.4564551],
                [108.6130954, 14.456633],
                [108.6133975, 14.45678],
                [108.6137089, 14.4568927],
                [108.6140257, 14.4569714],
                [108.6143492, 14.4570173],
                [108.6146759, 14.4570301],
                [108.6150022, 14.4570094],
                [108.6153245, 14.4569556],
                [108.6164725, 14.4566647],
                [108.616579, 14.456627],
                [108.6167826, 14.4565302],
                [108.6168785, 14.4564716],
                [108.6170565, 14.4563355],
                [108.6171375, 14.4562586],
                [108.6172824, 14.4560885],
                [108.6173453, 14.455996],
                [108.61745, 14.4557992],
                [108.6174913, 14.4556959],
                [108.6175504, 14.455482],
                [108.6175775, 14.4552622],
                [108.61754, 14.4543115],
                [108.6175476, 14.4542072],
                [108.6175645, 14.4541039],
                [108.6175904, 14.4540024],
                [108.6176253, 14.4539034],
                [108.6177199, 14.453717],
                [108.617845, 14.4535498],
                [108.6179975, 14.4534055],
                [108.618173, 14.4532882],
                [108.6188912, 14.4527778],
                [108.6190553, 14.4526215],
                [108.6191279, 14.452535],
                [108.6192473, 14.4523563],
                [108.6192969, 14.4522613],
                [108.6193746, 14.4520623],
                [108.6201096, 14.4504104],
                [108.6202171, 14.4502535],
                [108.6202797, 14.4501815],
                [108.6204207, 14.4500519],
                [108.6205082, 14.4499886],
                [108.6206982, 14.4498846],
                [108.6207993, 14.4498446],
                [108.6212039, 14.449735],
                [108.6220682, 14.4494175],
                [108.6222881, 14.4493714],
                [108.622508, 14.449359],
                [108.6227227, 14.4493784],
                [108.622932, 14.4494286],
                [108.6231998, 14.4495167],
                [108.6233692, 14.4495594],
                [108.6235408, 14.449593],
                [108.623714, 14.4496174],
                [108.6238884, 14.4496325],
                [108.6240633, 14.4496382],
                [108.6242383, 14.4496345],
                [108.6244128, 14.4496214],
                [108.6245863, 14.4495991],
                [108.6247583, 14.4495674],
                [108.6249282, 14.4495266],
                [108.6250955, 14.4494768],
                [108.6252597, 14.4494181],
                [108.6254203, 14.4493506],
                [108.6272442, 14.4488935],
                [108.6278481, 14.4486902],
                [108.6281459, 14.4485773],
                [108.6287429, 14.4483246],
                [108.6293364, 14.4480364],
                [108.6294609, 14.4479224],
                [108.6295917, 14.4478152],
                [108.6297284, 14.4477151],
                [108.6298704, 14.4476223],
                [108.6300175, 14.4475371],
                [108.6301691, 14.4474599],
                [108.6303249, 14.4473907],
                [108.6304843, 14.4473299],
                [108.6308104, 14.4472343],
                [108.6311453, 14.4471735],
                [108.6313147, 14.4471563],
                [108.6316553, 14.4471488],
                [108.6320074, 14.4471758],
                [108.6321884, 14.4472003],
                [108.6325467, 14.4472706],
                [108.6327233, 14.4473163],
                [108.6332165, 14.4474713],
                [108.633365, 14.4475074],
                [108.6336663, 14.4475595],
                [108.6339712, 14.4475844],
                [108.6340549, 14.4475975],
                [108.6341371, 14.4476178],
                [108.6342944, 14.447679],
                [108.6344419, 14.4477691],
                [108.6345105, 14.4478252],
                [108.63463, 14.4479549],
                [108.6347222, 14.4481039],
                [108.6350655, 14.4487792],
                [108.6351292, 14.448874],
                [108.6351989, 14.4489647],
                [108.6353552, 14.4491325],
                [108.635541, 14.4492863],
                [108.635746, 14.4494152],
                [108.6359668, 14.4495169],
                [108.6372811, 14.4503117],
                [108.6376061, 14.4504778],
                [108.6377745, 14.4505494],
                [108.6381144, 14.4506665],
                [108.6384575, 14.4507508],
                [108.6388046, 14.4508168],
                [108.6388845, 14.4509205],
                [108.6390271, 14.4511393],
                [108.6391505, 14.4513825],
                [108.6394123, 14.4520437],
                [108.639593, 14.4525825],
                [108.6396834, 14.4528939],
                [108.6397646, 14.4532078],
                [108.6398364, 14.4535237],
                [108.6402098, 14.4555385],
                [108.6402362, 14.455625],
                [108.6403011, 14.4557743],
                [108.6405308, 14.4561328],
                [108.6405523, 14.456187],
                [108.640578, 14.4563004],
                [108.6405821, 14.456368],
                [108.6405659, 14.4565024],
                [108.6403168, 14.4571506],
                [108.6402911, 14.4572478],
                [108.6402642, 14.4574465],
                [108.6402711, 14.4576501],
                [108.6403131, 14.4578531],
                [108.640347, 14.4579514],
                [108.6404395, 14.458138],
                [108.6403962, 14.4582151],
                [108.6402948, 14.4583605],
                [108.6400935, 14.4585862],
                [108.6400385, 14.458668],
                [108.6399917, 14.4587544],
                [108.6399535, 14.4588448],
                [108.6399244, 14.4589382],
                [108.6399045, 14.4590339],
                [108.6398856, 14.4592747],
                [108.6398858, 14.4593538],
                [108.6398916, 14.4594328],
                [108.6399203, 14.4595886],
                [108.6399458, 14.4596731],
                [108.6400168, 14.4598351],
                [108.6401565, 14.4600595],
                [108.6403671, 14.4603413],
                [108.640377, 14.4604426],
                [108.640375, 14.460646],
                [108.6403616, 14.4607577],
                [108.6403089, 14.4609767],
                [108.6402736, 14.4611928],
                [108.6402683, 14.4613022],
                [108.6402822, 14.4615206],
                [108.6409181, 14.4622436],
                [108.6406699, 14.462402],
                [108.6405526, 14.4624906],
                [108.6403334, 14.4626851],
                [108.6401392, 14.462898],
                [108.640051, 14.4630116],
                [108.6398935, 14.4632513],
                [108.6398882, 14.4654122],
                [108.6406714, 14.4665238],
                [108.6407651, 14.4667393],
                [108.6408269, 14.4669653],
                [108.6408561, 14.4672058],
                [108.6408492, 14.4674478],
                [108.6408323, 14.4675678],
                [108.6407141, 14.4681729],
                [108.6407016, 14.4683064],
                [108.6407029, 14.4685607],
                [108.6407344, 14.4687993],
                [108.6410469, 14.470087],
                [108.6410497, 14.4710421],
                [108.641063, 14.4711541],
                [108.6411005, 14.4712609],
                [108.6411664, 14.4713652],
                [108.6412082, 14.4714112],
                [108.6413066, 14.4714877],
                [108.6414205, 14.4715405],
                [108.6419615, 14.4717154],
                [108.6420924, 14.4717962],
                [108.6421499, 14.4718467],
                [108.6422458, 14.4719648],
                [108.6422801, 14.4720251],
                [108.6423285, 14.4721545],
                [108.6423422, 14.472222],
                [108.6423478, 14.4723595],
                [108.6424148, 14.4729335],
                [108.6424342, 14.4730171],
                [108.6424962, 14.4731776],
                [108.6425383, 14.4732531],
                [108.6426429, 14.4733913],
                [108.6427789, 14.4735143],
                [108.6428594, 14.4735681],
                [108.642945, 14.4736138],
                [108.6430349, 14.4736509],
                [108.6431283, 14.473679],
                [108.6432241, 14.4736979],
                [108.6433214, 14.4737074],
                [108.6434716, 14.4744294],
                [108.6435014, 14.4745463],
                [108.6435826, 14.4747739],
                [108.6436915, 14.4749904],
                [108.6438383, 14.4752076],
                [108.6439224, 14.4753088],
                [108.64411, 14.4754942],
                [108.6426347, 14.476185],
                [108.6423624, 14.4764567],
                [108.6418354, 14.4770161],
                [108.6413778, 14.4775421],
                [108.6411567, 14.4778112],
                [108.6407304, 14.4783613],
                [108.6403871, 14.4793794],
                [108.6403295, 14.4795077],
                [108.6402634, 14.4796321],
                [108.6401892, 14.4797521],
                [108.640107, 14.4798672],
                [108.6400173, 14.4799768],
                [108.6399203, 14.4800806],
                [108.639816, 14.4801786],
                [108.6397053, 14.4802697],
                [108.6395887, 14.4803537],
                [108.6394666, 14.4804301],
                [108.6393396, 14.4804986],
                [108.6392083, 14.4805589],
                [108.6390731, 14.4806108],
                [108.6389348, 14.4806539],
                [108.6387938, 14.4806883],
                [108.6368573, 14.480935],
                [108.6369491, 14.4810352],
                [108.6371502, 14.4812192],
                [108.6372588, 14.4813023],
                [108.6374875, 14.4814478],
                [108.6376067, 14.48151],
                [108.6378551, 14.481614],
                [108.6381142, 14.4816897],
                [108.6383701, 14.4817416],
                [108.6384949, 14.4817967],
                [108.6385559, 14.4818376],
                [108.6386111, 14.4818857],
                [108.6387395, 14.4820407],
                [108.6388119, 14.4821464],
                [108.6389333, 14.4823711],
                [108.6390222, 14.4826117],
                [108.6390534, 14.482736],
                [108.6391103, 14.4831043],
                [108.6391793, 14.4833282],
                [108.639282, 14.4835397],
                [108.6395341, 14.4839682],
                [108.6396146, 14.4854407],
                [108.6396005, 14.4855953],
                [108.6395522, 14.485902],
                [108.6394689, 14.4862315],
                [108.6394107, 14.4864068],
                [108.6393019, 14.4864897],
                [108.6390988, 14.4866719],
                [108.6389172, 14.4868742],
                [108.6387669, 14.4870823],
                [108.6387002, 14.4871917],
                [108.6385846, 14.4874196],
                [108.6381072, 14.4885778],
                [108.6380365, 14.4887028],
                [108.6378763, 14.4889418],
                [108.6377872, 14.4890552],
                [108.6375922, 14.4892686],
                [108.6374695, 14.4893833],
                [108.6373403, 14.489491],
                [108.6372048, 14.4895913],
                [108.6370636, 14.4896839],
                [108.6369172, 14.4897685],
                [108.636766, 14.4898448],
                [108.6363708, 14.4900242],
                [108.6361348, 14.490143],
                [108.6359027, 14.4902687],
                [108.635451, 14.4905406],
                [108.6352318, 14.4906865],
                [108.6345613, 14.4911955],
                [108.635028, 14.4916577],
                [108.635091, 14.4921973],
                [108.6351317, 14.4927559],
                [108.635143, 14.4930441],
                [108.635146, 14.4936209],
                [108.6349871, 14.493856],
                [108.6348027, 14.4940728],
                [108.6346056, 14.4942598],
                [108.6343896, 14.494426],
                [108.6341953, 14.4945343],
                [108.6340924, 14.4945774],
                [108.6338777, 14.4946403],
                [108.6337674, 14.4946597],
                [108.6331344, 14.494722],
                [108.6329671, 14.4947538],
                [108.6328081, 14.4948132],
                [108.6326623, 14.4948986],
                [108.6325311, 14.4950102],
                [108.6324738, 14.4950741],
                [108.632378, 14.4952154],
                [108.632099, 14.4957711],
                [108.6320788, 14.4958548],
                [108.6320615, 14.4960256],
                [108.6320648, 14.4961157],
                [108.6320972, 14.496293],
                [108.632149, 14.4964768],
                [108.6321625, 14.4965764],
                [108.6321664, 14.4966769],
                [108.6321606, 14.4967772],
                [108.6321452, 14.4968766],
                [108.6321202, 14.4969742],
                [108.6319887, 14.4972921],
                [108.6319059, 14.4975639],
                [108.6318576, 14.4978434],
                [108.6318601, 14.4980381],
                [108.6318925, 14.4982303],
                [108.6319175, 14.4983174],
                [108.6319859, 14.4984857],
                [108.6320776, 14.4986432],
                [108.632437, 14.4991522],
                [108.6324155, 14.5019255],
                [108.632557, 14.5021278],
                [108.6327262, 14.502309],
                [108.6329198, 14.5024656],
                [108.6330316, 14.5025379],
                [108.6331486, 14.5026019],
                [108.6332702, 14.5026574],
                [108.6333957, 14.5027039],
                [108.6335245, 14.5027412],
                [108.6336558, 14.5027692],
                [108.6337888, 14.5027876],
                [108.6353338, 14.5031719],
                [108.6354561, 14.5032294],
                [108.6356879, 14.5033668],
                [108.6357964, 14.5034462],
                [108.6359963, 14.5036245],
                [108.6360868, 14.5037226],
                [108.6362434, 14.5039291],
                [108.6363686, 14.5041468],
                [108.6364653, 14.5043777],
                [108.6365319, 14.5046183],
                [108.6365981, 14.505077],
                [108.6366371, 14.5052877],
                [108.6367399, 14.5057039],
                [108.6368448, 14.5060278],
                [108.6368541, 14.5061529],
                [108.6368309, 14.5062766],
                [108.6368073, 14.5063355],
                [108.6367763, 14.506391],
                [108.636694, 14.5064887],
                [108.636644, 14.5065292],
                [108.6363711, 14.5067085],
                [108.6361571, 14.5068591],
                [108.6357415, 14.5071761],
                [108.6352981, 14.5075536],
                [108.6348778, 14.5079551],
                [108.6338425, 14.5098091],
                [108.6337806, 14.5099014],
                [108.6337107, 14.5099882],
                [108.6336333, 14.5100688],
                [108.6335498, 14.5101419],
                [108.6334602, 14.5102078],
                [108.6332646, 14.5103311],
                [108.6331697, 14.5104033],
                [108.6330809, 14.5104825],
                [108.6329987, 14.5105681],
                [108.6329236, 14.5106596],
                [108.632856, 14.5107565],
                [108.6327964, 14.5108582],
                [108.6327067, 14.511061],
                [108.6326486, 14.5112744],
                [108.6326233, 14.5114937],
                [108.6326231, 14.5116041],
                [108.6326477, 14.5118235],
                [108.6327052, 14.512037],
                [108.6327904, 14.5124392],
                [108.6328232, 14.512642],
                [108.6328674, 14.5130306],
                [108.6328805, 14.5132257],
                [108.6328855, 14.5134917],
                [108.6328635, 14.5136314],
                [108.6328116, 14.5137808],
                [108.6327326, 14.5139187],
                [108.6326192, 14.5140688],
                [108.6325501, 14.5141524],
                [108.6323994, 14.5143091],
                [108.6323041, 14.5143935],
                [108.6320988, 14.5145453],
                [108.6318764, 14.5146726],
                [108.6316735, 14.5148109],
                [108.6315802, 14.5148903],
                [108.6314124, 14.5150673],
                [108.6312529, 14.5151829],
                [108.6309484, 14.5154318],
                [108.6308039, 14.5155646],
                [108.6305314, 14.5158461],
                [108.6302809, 14.516149],
                [108.6300544, 14.5164719],
                [108.6299512, 14.5166396],
                [108.6297655, 14.5169862],
                [108.6297259, 14.517137],
                [108.6296955, 14.5172899],
                [108.6296746, 14.5174442],
                [108.6296632, 14.5175994],
                [108.6296613, 14.5177551],
                [108.6296689, 14.5179105],
                [108.6296856, 14.518062],
                [108.6297461, 14.518361],
                [108.6297897, 14.5185074],
                [108.6299028, 14.5187916],
                [108.6300492, 14.5190613],
                [108.6302268, 14.5193127],
                [108.6311763, 14.5201643],
                [108.6312387, 14.5202347],
                [108.6313453, 14.5203887],
                [108.6313887, 14.5204713],
                [108.6314547, 14.5206453],
                [108.6314924, 14.5208364],
                [108.6314989, 14.5209381],
                [108.6314843, 14.5211412],
                [108.6314335, 14.5213389],
                [108.631348, 14.5215249],
                [108.6313239, 14.521614],
                [108.6313023, 14.5217968],
                [108.631305, 14.5218889],
                [108.6313373, 14.5220702],
                [108.6313676, 14.5221605],
                [108.6314068, 14.5222475],
                [108.6314547, 14.5223303],
                [108.6315106, 14.5224083],
                [108.6315742, 14.5224806],
                [108.6316447, 14.5225466],
                [108.6317216, 14.5226056],
                [108.631804, 14.522657],
                [108.6324665, 14.5231555],
                [108.6334321, 14.5232879],
                [108.6336239, 14.5233382],
                [108.6338099, 14.5234178],
                [108.6338995, 14.523469],
                [108.6342541, 14.5237211],
                [108.6345077, 14.5239345],
                [108.6346456, 14.5240652],
                [108.6347776, 14.5242015],
                [108.6350226, 14.5244901],
                [108.6350722, 14.5245763],
                [108.6351473, 14.5247594],
                [108.6351889, 14.5249523],
                [108.6351961, 14.525141],
                [108.6351711, 14.5253283],
                [108.6351467, 14.5254198],
                [108.635075, 14.5255954],
                [108.6341107, 14.5272216],
                [108.634013, 14.5273605],
                [108.6339086, 14.5274948],
                [108.6336809, 14.5277482],
                [108.6334294, 14.5279798],
                [108.6332914, 14.5280897],
                [108.6331481, 14.528193],
                [108.6328468, 14.5283788],
                [108.6326894, 14.5284609],
                [108.6325282, 14.5285354],
                [108.6324119, 14.5285774],
                [108.6322993, 14.528628],
                [108.6321911, 14.5286869],
                [108.6320879, 14.5287537],
                [108.6319904, 14.5288281],
                [108.6318992, 14.5289095],
                [108.6318147, 14.5289976],
                [108.6316863, 14.5291636],
                [108.631582, 14.5293447],
                [108.6315036, 14.5295376],
                [108.6312461, 14.5301037],
                [108.6312166, 14.5303737],
                [108.6311552, 14.5304186],
                [108.6310204, 14.5304904],
                [108.6309484, 14.5305165],
                [108.6308132, 14.5305462],
                [108.6306748, 14.5305528],
                [108.6300981, 14.5305295],
                [108.6301179, 14.5306702],
                [108.6301393, 14.5309535],
                [108.6301359, 14.5312516],
                [108.6301235, 14.5314073],
                [108.6300768, 14.5317162],
                [108.6299657, 14.5321797],
                [108.6299166, 14.5325034],
                [108.6298981, 14.5328301],
                [108.6299264, 14.5335465],
                [108.6298956, 14.5337206],
                [108.6298361, 14.5339147],
                [108.6297393, 14.534123],
                [108.6293122, 14.5348191],
                [108.6289716, 14.5354315],
                [108.6286906, 14.5359897],
                [108.628525, 14.5363441],
                [108.6284996, 14.5364186],
                [108.6284742, 14.5365733],
                [108.6284745, 14.5366517],
                [108.6284995, 14.536801],
                [108.6285531, 14.5369373],
                [108.6287436, 14.5372074],
                [108.6286796, 14.5372903],
                [108.6285751, 14.5373878],
                [108.628528, 14.5374639],
                [108.6285037, 14.5375504],
                [108.6285102, 14.537763],
                [108.6284941, 14.5379291],
                [108.628301, 14.5382563],
                [108.6283423, 14.5384415],
                [108.6283507, 14.538536],
                [108.6283423, 14.5387253],
                [108.628301, 14.5389105],
                [108.6282437, 14.5390549],
                [108.6281523, 14.5393513],
                [108.6281186, 14.5395025],
                [108.6280761, 14.5398024],
                [108.628065, 14.5401049],
                [108.6282956, 14.5403645],
                [108.628757, 14.5410603],
                [108.6285424, 14.5420884],
                [108.6284602, 14.5421562],
                [108.6283108, 14.542307],
                [108.6282443, 14.5423893],
                [108.6281294, 14.5425661],
                [108.6280843, 14.5426535],
                [108.6280118, 14.5428356],
                [108.6279845, 14.5429296],
                [108.627962, 14.543259],
                [108.6279631, 14.5435891],
                [108.6279729, 14.5437592],
                [108.6280113, 14.5440979],
                [108.6284888, 14.5450585],
                [108.6282567, 14.5449359],
                [108.6281355, 14.5448847],
                [108.6279385, 14.5448182],
                [108.6278635, 14.5448037],
                [108.6277109, 14.5447989],
                [108.6276338, 14.5448089],
                [108.6275583, 14.5448271],
                [108.6274159, 14.5448872],
                [108.6269545, 14.544612],
                [108.6256671, 14.5441654],
                [108.6249644, 14.5431425],
                [108.6236072, 14.5431373],
                [108.6231718, 14.5428348],
                [108.6229466, 14.5426942],
                [108.6224948, 14.5424416],
                [108.6220399, 14.5422234],
                [108.6218074, 14.5421248],
                [108.6216752, 14.5420792],
                [108.6216058, 14.5420687],
                [108.6214656, 14.5420734],
                [108.6213352, 14.5421099],
                [108.6212216, 14.5421729],
                [108.6211261, 14.5422598],
                [108.6210503, 14.5424347],
                [108.6210217, 14.5425254],
                [108.620984, 14.5427115],
                [108.6209726, 14.5429064],
                [108.6209893, 14.543101],
                [108.6208116, 14.5430164],
                [108.6207181, 14.5429848],
                [108.6205247, 14.5429439],
                [108.6203221, 14.5429336],
                [108.6202185, 14.5429408],
                [108.6200149, 14.5429805],
                [108.6199164, 14.5430127],
                [108.6187416, 14.5436462],
                [108.6186474, 14.5435763],
                [108.6185593, 14.5434995],
                [108.6184777, 14.5434161],
                [108.6184032, 14.5433267],
                [108.6183362, 14.5432318],
                [108.6182773, 14.5431322],
                [108.6182266, 14.5430283],
                [108.618155, 14.5428232],
                [108.6181157, 14.5426101],
                [108.6181098, 14.5423937],
                [108.6181194, 14.5422857],
                [108.6179607, 14.5422913],
                [108.6178815, 14.542286],
                [108.6177263, 14.5422596],
                [108.6175775, 14.542213],
                [108.6174596, 14.5421142],
                [108.6173665, 14.541993],
                [108.6173025, 14.5418552],
                [108.6172825, 14.5417821],
                [108.6172705, 14.5417038],
                [108.6172677, 14.5416246],
                [108.6172742, 14.5415457],
                [108.6172899, 14.5414681],
                [108.6173747, 14.5412396],
                [108.6175188, 14.5409437],
                [108.6176937, 14.5406637],
                [108.6178886, 14.540413],
                [108.6180982, 14.5401917],
                [108.6183286, 14.5399906],
                [108.6181993, 14.5397808],
                [108.618099, 14.5395565],
                [108.6180297, 14.5393228],
                [108.6179921, 14.5390834],
                [108.6179852, 14.5389625],
                [108.617996, 14.5373424],
                [108.6180032, 14.5372274],
                [108.6180007, 14.5371123],
                [108.6179886, 14.5369977],
                [108.6179669, 14.5368845],
                [108.6179358, 14.5367733],
                [108.6178955, 14.536665],
                [108.6178462, 14.5365602],
                [108.6177882, 14.5364596],
                [108.6177221, 14.5363639],
                [108.6176481, 14.5362737],
                [108.6175668, 14.5361896],
                [108.6173984, 14.5360516],
                [108.6172106, 14.5359397],
                [108.6170075, 14.5358564],
                [108.6169016, 14.5358261],
                [108.6167637, 14.5358073],
                [108.6166276, 14.5357791],
                [108.6164939, 14.5357415],
                [108.6163633, 14.5356948],
                [108.6161268, 14.5355822],
                [108.6159198, 14.5354485],
                [108.6158233, 14.5353723],
                [108.6156464, 14.535203],
                [108.6132109, 14.5326066],
                [108.6123526, 14.5342683],
                [108.6123004, 14.5343803],
                [108.6121718, 14.534592],
                [108.6120131, 14.5347836],
                [108.6119234, 14.5348706],
                [108.6117586, 14.5350024],
                [108.6116703, 14.5350608],
                [108.6114836, 14.5351614],
                [108.6113577, 14.5351946],
                [108.6112298, 14.5352195],
                [108.6109703, 14.5352445],
                [108.6107031, 14.5352355],
                [108.6105674, 14.5352172],
                [108.6104333, 14.5351899],
                [108.6103015, 14.5351535],
                [108.6101726, 14.5351084],
                [108.6100474, 14.5350547],
                [108.6099262, 14.5349926],
                [108.6098099, 14.5349226],
                [108.6084044, 14.5338113],
                [108.6075293, 14.5343921],
                [108.6066341, 14.5349433],
                [108.6061739, 14.5352108],
                [108.6057089, 14.5354705],
                [108.605088, 14.5358064],
                [108.6049411, 14.5358979],
                [108.6046625, 14.5361015],
                [108.6045315, 14.5362132],
                [108.6042876, 14.536455],
                [108.6040699, 14.5367193],
                [108.6039662, 14.5368672],
                [108.6038707, 14.5370202],
                [108.6037838, 14.5371779],
                [108.6037056, 14.5373399],
                [108.6036364, 14.5375056],
                [108.6035764, 14.5376747],
                [108.6025411, 14.538625],
                [108.6024925, 14.5387033],
                [108.6024163, 14.5388704],
                [108.6023893, 14.538958],
                [108.6023583, 14.5391381],
                [108.6023546, 14.5392295],
                [108.6023693, 14.5394032],
                [108.6023863, 14.5394847],
                [108.6024392, 14.5396427],
                [108.6024636, 14.5397662],
                [108.6024669, 14.539829],
                [108.6024553, 14.5399543],
                [108.6024126, 14.5400927],
                [108.6023404, 14.5402193],
                [108.602088, 14.5405343],
                [108.6018887, 14.5407975],
                [108.6015116, 14.5413385],
                [108.6011891, 14.5418552],
                [108.6008453, 14.5424737],
                [108.6007662, 14.5425999],
                [108.600679, 14.5427209],
                [108.6005838, 14.5428362],
                [108.6004811, 14.5429452],
                [108.6003713, 14.5430476],
                [108.600255, 14.543143],
                [108.6001325, 14.5432308],
                [108.5998831, 14.5433765],
                [108.599752, 14.5434379],
                [108.5994791, 14.5435366],
                [108.5993385, 14.5435735],
                [108.5992171, 14.5435848],
                [108.5989778, 14.5436306],
                [108.5987469, 14.5437064],
                [108.598548, 14.5438001],
                [108.5984641, 14.5438514],
                [108.5983097, 14.543972],
                [108.5982402, 14.5440406],
                [108.5981186, 14.5441923],
                [108.5980191, 14.5443674],
                [108.5979479, 14.5445632],
                [108.5979251, 14.5446648],
                [108.5979062, 14.5448716],
                [108.5962178, 14.5467118],
                [108.5960895, 14.5468961],
                [108.595989, 14.5470958],
                [108.5959183, 14.5473072],
                [108.5958777, 14.547536],
                [108.5958703, 14.547657],
                [108.5958725, 14.5477781],
                [108.5958842, 14.5478988],
                [108.5959053, 14.5480182],
                [108.5962164, 14.5486829],
                [108.5962859, 14.5488027],
                [108.5964015, 14.5490537],
                [108.5964847, 14.5493163],
                [108.5965338, 14.5495845],
                [108.596549, 14.5498564],
                [108.5965329, 14.5523487],
                [108.5968172, 14.5528679],
                [108.5968623, 14.5529801],
                [108.5969295, 14.5532116],
                [108.5969661, 14.5534594],
                [108.5969715, 14.5535894],
                [108.5969643, 14.55391],
                [108.5969868, 14.5540927],
                [108.5970065, 14.5541794],
                [108.5973805, 14.5554848],
                [108.5974674, 14.555856],
                [108.5975254, 14.5562325],
                [108.5975437, 14.5564259],
                [108.5975575, 14.556814],
                [108.5958463, 14.5567206],
                [108.595562, 14.5576084],
                [108.5955298, 14.5577322],
                [108.5954868, 14.557984],
                [108.5954725, 14.5582389],
                [108.5954878, 14.5585008],
                [108.5955352, 14.5587663],
                [108.5955642, 14.5588224],
                [108.5955968, 14.5589436],
                [108.5955933, 14.5590687],
                [108.5955548, 14.5591866],
                [108.5954855, 14.5592886],
                [108.5953903, 14.5593686],
                [108.5945572, 14.5597488],
                [108.5944706, 14.5598134],
                [108.594408, 14.5599001],
                [108.5943752, 14.560001],
                [108.5943759, 14.560111],
                [108.5943904, 14.5601664],
                [108.594414, 14.5602188],
                [108.5944462, 14.5602668],
                [108.5950445, 14.5608649],
                [108.595109, 14.5609204],
                [108.5952517, 14.5610139],
                [108.5954091, 14.561082],
                [108.5955843, 14.5611237],
                [108.5958205, 14.5611372],
                [108.595929, 14.5611638],
                [108.5960273, 14.5612157],
                [108.5961121, 14.5612927],
                [108.5961735, 14.5613883],
                [108.5966778, 14.5625565],
                [108.594532, 14.563974],
                [108.5944891, 14.5644205],
                [108.5944744, 14.5645042],
                [108.5944249, 14.564667],
                [108.5943496, 14.5648203],
                [108.594301, 14.5648948],
                [108.5942463, 14.5649652],
                [108.59412, 14.5650919],
                [108.5940492, 14.5651474],
                [108.5926284, 14.5660451],
                [108.5924154, 14.5661501],
                [108.5923044, 14.5661929],
                [108.5920751, 14.5662585],
                [108.5919604, 14.5662805],
                [108.5917279, 14.5663045],
                [108.5914941, 14.5663013],
                [108.5912235, 14.5662721],
                [108.5909133, 14.5662602],
                [108.5907581, 14.5662658],
                [108.5904497, 14.5663],
                [108.5902852, 14.5663311],
                [108.5899629, 14.5664195],
                [108.589806, 14.5664765],
                [108.5896861, 14.5665415],
                [108.5895619, 14.5665984],
                [108.589434, 14.5666469],
                [108.589303, 14.5666868],
                [108.5891694, 14.5667179],
                [108.5890339, 14.56674],
                [108.5888972, 14.566753],
                [108.5887599, 14.5667569],
                [108.5886209, 14.5667515],
                [108.5884825, 14.5667368],
                [108.5883456, 14.5667128],
                [108.5882108, 14.5666797],
                [108.5880786, 14.5666375],
                [108.5879469, 14.5665807],
                [108.5878766, 14.5665646],
                [108.5877325, 14.5665584],
                [108.5875913, 14.5665867],
                [108.5874617, 14.5666479],
                [108.5874018, 14.5666913],
                [108.5873482, 14.5667418],
                [108.5873017, 14.5667986],
                [108.5872632, 14.5668607],
                [108.5872618, 14.5669178],
                [108.587233, 14.5670282],
                [108.5872063, 14.567079],
                [108.5871311, 14.5671665],
                [108.5870328, 14.5672288],
                [108.5869199, 14.5672605],
                [108.5868228, 14.5672991],
                [108.5867297, 14.5673459],
                [108.5866412, 14.5674006],
                [108.5865581, 14.5674627],
                [108.5864811, 14.5675317],
                [108.5864108, 14.5676071],
                [108.5863477, 14.5676882],
                [108.5862482, 14.5678586],
                [108.5861822, 14.5680357],
                [108.5861474, 14.568221],
                [108.5859511, 14.568107],
                [108.5858474, 14.56806],
                [108.5856312, 14.567987],
                [108.5855198, 14.5679614],
                [108.5854259, 14.5679458],
                [108.5851415, 14.5679181],
                [108.5847606, 14.5679104],
                [108.58441, 14.5679348],
                [108.5842504, 14.5679561],
                [108.5839347, 14.5680175],
                [108.5836566, 14.5680886],
                [108.5835368, 14.5681283],
                [108.5834203, 14.5681763],
                [108.5831997, 14.5682963],
                [108.5830035, 14.5684427],
                [108.582914, 14.5685256],
                [108.5827544, 14.5687091],
                [108.5818988, 14.5701368],
                [108.5818215, 14.5702562],
                [108.581645, 14.5704801],
                [108.5815465, 14.5705839],
                [108.5813309, 14.570773],
                [108.581093, 14.5709353],
                [108.5808366, 14.5710684],
                [108.5805667, 14.5711698],
                [108.5802875, 14.5712386],
                [108.5800018, 14.5712743],
                [108.5797137, 14.5712765],
                [108.5794275, 14.571245],
                [108.578619, 14.5710805],
                [108.5779452, 14.5709779],
                [108.5773991, 14.5709201],
                [108.5768509, 14.5708845],
                [108.5767576, 14.5710925],
                [108.5766997, 14.5711912],
                [108.5765628, 14.5713753],
                [108.5764003, 14.5715387],
                [108.5762076, 14.5716829],
                [108.5761036, 14.5717443],
                [108.5758828, 14.5718437],
                [108.5752765, 14.5720436],
                [108.5750351, 14.5721354],
                [108.5745614, 14.5723399],
                [108.5740924, 14.5725765],
                [108.5736305, 14.572846],
                [108.573187, 14.5731429],
                [108.5731063, 14.5731994],
                [108.5730315, 14.573263],
                [108.5729632, 14.5733332],
                [108.5729023, 14.5734093],
                [108.572849, 14.5734908],
                [108.572811, 14.5735621],
                [108.572753, 14.5737125],
                [108.5727203, 14.5738698],
                [108.5726801, 14.5745421],
                [108.5726184, 14.5747991],
                [108.5726291, 14.5753417],
                [108.5728866, 14.575744],
                [108.5729373, 14.5758323],
                [108.5729791, 14.5759249],
                [108.5730119, 14.5760208],
                [108.5730352, 14.5761192],
                [108.5730489, 14.5762194],
                [108.5730529, 14.5763203],
                [108.5730344, 14.5765061],
                [108.5730128, 14.5765971],
                [108.5729456, 14.576772],
                [108.5720873, 14.5779661],
                [108.5699844, 14.5796326],
                [108.5695767, 14.579752],
                [108.5689706, 14.5798504],
                [108.5683604, 14.5799214],
                [108.5677475, 14.5799649],
                [108.5674163, 14.5799769],
                [108.5667536, 14.5799769],
                [108.5664225, 14.5799649],
                [108.5663745, 14.5800201],
                [108.5663015, 14.5801457],
                [108.5662776, 14.5802141],
                [108.5662313, 14.5804349],
                [108.5661542, 14.5806476],
                [108.5660473, 14.580849],
                [108.5659128, 14.5810344],
                [108.5653764, 14.5813095],
                [108.5650921, 14.5817612],
                [108.5645556, 14.5818598],
                [108.5644195, 14.5819418],
                [108.5643586, 14.5819924],
                [108.5642545, 14.5821104],
                [108.5641781, 14.5822447],
                [108.5641316, 14.5823893],
                [108.5641198, 14.5824642],
                [108.5640622, 14.5832253],
                [108.5640246, 14.5833446],
                [108.5640006, 14.5833875],
                [108.5639387, 14.5834641],
                [108.5626298, 14.5843259],
                [108.5623991, 14.584357],
                [108.5623094, 14.5844528],
                [108.562147, 14.5846581],
                [108.5620852, 14.5847506],
                [108.5619754, 14.5849437],
                [108.5618822, 14.585072],
                [108.561827, 14.5851296],
                [108.5617018, 14.5852292],
                [108.5616286, 14.5852726],
                [108.5614711, 14.5853382],
                [108.5609668, 14.5853797],
                [108.5603231, 14.5858781],
                [108.5601541, 14.586174],
                [108.5600098, 14.586083],
                [108.559851, 14.5860183],
                [108.5597055, 14.5859851],
                [108.5595697, 14.5859692],
                [108.5595097, 14.5859548],
                [108.5593968, 14.5859064],
                [108.5592985, 14.585834],
                [108.5592523, 14.585785],
                [108.559213, 14.5857306],
                [108.559138, 14.5855699],
                [108.5591123, 14.5855337],
                [108.5590493, 14.5854772],
                [108.5589766, 14.585442],
                [108.5587832, 14.5854429],
                [108.5583973, 14.585468],
                [108.5580297, 14.5855213],
                [108.5576677, 14.585603],
                [108.5568094, 14.5855563],
                [108.5559511, 14.5859872],
                [108.55583, 14.5860305],
                [108.5555801, 14.5860935],
                [108.5554525, 14.586113],
                [108.5551947, 14.5861273],
                [108.5549352, 14.5861084],
                [108.5548069, 14.5860865],
                [108.5545564, 14.5860183],
                [108.5539287, 14.585577],
                [108.5532421, 14.5855199],
                [108.5530551, 14.5854167],
                [108.5528551, 14.5853396],
                [108.552646, 14.58529],
                [108.5525393, 14.5852759],
                [108.5524182, 14.5852686],
                [108.5522969, 14.5852706],
                [108.5521761, 14.5852817],
                [108.5507047, 14.5855614],
                [108.5501361, 14.5869995],
                [108.5495913, 14.5879073],
                [108.5495182, 14.588012],
                [108.5493559, 14.5882099],
                [108.5491719, 14.588392],
                [108.5490721, 14.5884764],
                [108.5488594, 14.5886296],
                [108.5471682, 14.5895109],
                [108.5464132, 14.5890813],
                [108.5457265, 14.5887023],
                [108.5451472, 14.5883493],
                [108.5449156, 14.5881959],
                [108.5447927, 14.5881303],
                [108.5445351, 14.5880225],
                [108.5442857, 14.5879517],
                [108.5440501, 14.5879121],
                [108.5437085, 14.587885],
                [108.5435068, 14.5878382],
                [108.5433027, 14.5877578],
                [108.5432007, 14.5877032],
                [108.5431036, 14.5876406],
                [108.5430121, 14.5875706],
                [108.5427715, 14.587343],
                [108.5423094, 14.5868697],
                [108.5419571, 14.5864719],
                [108.5416228, 14.5860598],
                [108.5408556, 14.5847879],
                [108.5403546, 14.5843237],
                [108.539829, 14.5838857],
                [108.5392785, 14.5834738],
                [108.538994, 14.583278],
                [108.5384095, 14.5829086],
                [108.536116, 14.5822519],
                [108.5358283, 14.582143],
                [108.535553, 14.5820072],
                [108.5352929, 14.5818458],
                [108.5351694, 14.581756],
                [108.5349361, 14.5815588],
                [108.5347242, 14.5813403],
                [108.5346268, 14.5812237],
                [108.5344505, 14.5809773],
                [108.5306525, 14.5721202],
                [108.530603, 14.5720251],
                [108.5305281, 14.5718252],
                [108.5305033, 14.5717215],
                [108.5304796, 14.5715101],
                [108.5304809, 14.5714037],
                [108.5304918, 14.5712915],
                [108.5305124, 14.5711807],
                [108.5305427, 14.5710719],
                [108.5305823, 14.570966],
                [108.5306762, 14.5707787],
                [108.5307515, 14.5706019],
                [108.5308101, 14.5703987],
                [108.530831, 14.570285],
                [108.5308488, 14.5700549],
                [108.530839, 14.569726],
                [108.5308227, 14.5695128],
                [108.5307969, 14.5693006],
                [108.5307616, 14.5690897],
                [108.5307169, 14.5688804],
                [108.530611, 14.5685035],
                [108.5305465, 14.5683183],
                [108.5304745, 14.5681358],
                [108.5303575, 14.5678797],
                [108.5303012, 14.5677195],
                [108.5302712, 14.5675528],
                [108.5302684, 14.5673786],
                [108.5302953, 14.5672015],
                [108.5303199, 14.5671151],
                [108.5309046, 14.566326],
                [108.5302931, 14.5653551],
                [108.5293221, 14.5632834],
                [108.5292843, 14.5631772],
                [108.5292556, 14.5630683],
                [108.5292361, 14.5629575],
                [108.5292261, 14.5628455],
                [108.5292256, 14.5627331],
                [108.5292344, 14.5626226],
                [108.5292793, 14.5624056],
                [108.5293152, 14.5623004],
                [108.5293597, 14.5621983],
                [108.5294934, 14.5619794],
                [108.5295528, 14.561866],
                [108.5296762, 14.5615804],
                [108.5297525, 14.5611346],
                [108.5297835, 14.5609106],
                [108.5298416, 14.5603236],
                [108.5298583, 14.5600292],
                [108.5298667, 14.5597344],
                [108.5295421, 14.5594153],
                [108.5287294, 14.5588052],
                [108.5287937, 14.5587613],
                [108.5288521, 14.5587103],
                [108.5289038, 14.5586529],
                [108.528948, 14.5585899],
                [108.5290082, 14.5584617],
                [108.5290366, 14.5583351],
                [108.5290406, 14.5582703],
                [108.5290227, 14.5579333],
                [108.5289809, 14.5576571],
                [108.5289493, 14.5575176],
                [108.5289091, 14.5565752],
                [108.5286389, 14.556195],
                [108.5281858, 14.5557436],
                [108.5277304, 14.5554952],
                [108.5272732, 14.5554092],
                [108.5266899, 14.5554233],
                [108.5261485, 14.5554176],
                [108.5254394, 14.5555116],
                [108.5248559, 14.5555459],
                [108.5242111, 14.5554782],
                [108.5234215, 14.5553076],
                [108.5226138, 14.5549133],
                [108.521891, 14.5543575],
                [108.5211065, 14.5537402],
                [108.5205486, 14.5533486],
                [108.5200234, 14.5532271],
                [108.5192482, 14.553017],
                [108.517923, 14.5526978],
                [108.5147995, 14.5521067],
                [108.5138526, 14.5517407],
                [108.5129063, 14.5516508],
                [108.5124327, 14.5513759],
                [108.510727, 14.5501835],
                [108.5081206, 14.548165],
                [108.5059416, 14.5467897],
                [108.5048522, 14.546148],
                [108.5041499, 14.5458855],
                [108.5037961, 14.5458669],
                [108.5034595, 14.5458797],
                [108.502888, 14.5458409],
                [108.5024177, 14.5457704],
                [108.5003865, 14.5446842],
                [108.5001716, 14.5445381],
                [108.4998366, 14.5443655],
                [108.4993894, 14.5441768],
                [108.4989521, 14.544013],
                [108.4985957, 14.5439296],
                [108.4980963, 14.5438448],
                [108.4971066, 14.5437646],
                [108.4964127, 14.5437223],
                [108.4952895, 14.5437103],
                [108.494687, 14.5437139],
                [108.4941354, 14.5437328],
                [108.4935122, 14.5437659],
                [108.4928987, 14.5438289],
                [108.4922953, 14.5439119],
                [108.4891253, 14.5443006],
                [108.4878063, 14.5444505],
                [108.4870906, 14.5445223],
                [108.4864156, 14.5446144],
                [108.4850548, 14.5448534],
                [108.4844412, 14.5449363],
                [108.483766, 14.5450483],
                [108.4823538, 14.5453066],
                [108.4816372, 14.5454579],
                [108.4811257, 14.5455519],
                [108.4801017, 14.5458043],
                [108.479559, 14.5459277],
                [108.4789755, 14.5460506],
                [108.4783608, 14.546233],
                [108.4772641, 14.546594],
                [108.4766283, 14.5468258],
                [108.47548, 14.5472261],
                [108.4750291, 14.5473703],
                [108.4745578, 14.5475045],
                [108.4730607, 14.54805],
                [108.4719744, 14.5483863],
                [108.4706619, 14.5488643],
                [108.4701598, 14.5490179],
                [108.4696168, 14.5491711],
                [108.469187, 14.549256],
                [108.468645, 14.5493198],
                [108.4682664, 14.5493902],
                [108.4677543, 14.5495238],
                [108.4671396, 14.5496961],
                [108.4665351, 14.5498786],
                [108.4654686, 14.5502597],
                [108.4648434, 14.5504717],
                [108.4635513, 14.5509449],
                [108.4625762, 14.5513767],
                [108.4620735, 14.5515801],
                [108.4605678, 14.5519814],
                [108.4602295, 14.552092],
                [108.4598192, 14.5522565],
                [108.4594087, 14.5524311],
                [108.4590091, 14.5525461],
                [108.4586407, 14.5526116],
                [108.4582418, 14.552667],
                [108.4570244, 14.5528675],
                [108.4563288, 14.5529693],
                [108.4557256, 14.5530323],
                [108.4551527, 14.5531205],
                [108.4543854, 14.5532414],
                [108.4535872, 14.5533918],
                [108.4528916, 14.5535036],
                [108.4522677, 14.5535814],
                [108.4517051, 14.5536647],
                [108.450733, 14.553843],
                [108.4503136, 14.553898],
                [108.4496598, 14.5539257],
                [108.4491338, 14.5539859],
                [108.4481947, 14.5541768],
                [108.4477209, 14.5543408],
                [108.4470941, 14.5546043],
                [108.4462318, 14.554882],
                [108.4461313, 14.5549144],
                [108.4453138, 14.5550899],
                [108.4446328, 14.5550499],
                [108.4440356, 14.5549184],
                [108.4435146, 14.5547528],
                [108.4428082, 14.5545977],
                [108.4422281, 14.5545902],
                [108.4411824, 14.5549518],
                [108.4407411, 14.5551457],
                [108.439878, 14.5556035],
                [108.4393641, 14.5558912],
                [108.4370809, 14.557293],
                [108.4364743, 14.5576442],
                [108.4354057, 14.5582191],
                [108.4349327, 14.5584974],
                [108.4345827, 14.5587321],
                [108.4342223, 14.5589965],
                [108.4337074, 14.5593588],
                [108.4332231, 14.5597462],
                [108.4327384, 14.5601485],
                [108.4322536, 14.5605707],
                [108.431831, 14.5609042],
                [108.4308731, 14.5616145],
                [108.4297913, 14.5624426],
                [108.429337, 14.5628652],
                [108.4288624, 14.5632874],
                [108.4282026, 14.5638221],
                [108.4268613, 14.5650004],
                [108.4264699, 14.5652894],
                [108.4261199, 14.5655341],
                [108.42366, 14.5671824],
                [108.4233415, 14.5673479],
                [108.4223878, 14.5676853],
                [108.4218647, 14.5678833],
                [108.4207763, 14.5683884],
                [108.4198416, 14.5688553],
                [108.4192669, 14.5691025],
                [108.4188559, 14.5693166],
                [108.4184653, 14.569531],
                [108.4180231, 14.5697946],
                [108.4173749, 14.570205],
                [108.4170763, 14.5704155],
                [108.4167667, 14.5707004],
                [108.4164768, 14.5710352],
                [108.4159271, 14.5717599],
                [108.415033, 14.5731171],
                [108.4147526, 14.5735217],
                [108.4141901, 14.5744648],
                [108.4135221, 14.5756952],
                [108.4132401, 14.5762389],
                [108.4129031, 14.5771052],
                [108.4126915, 14.5777391],
                [108.4126586, 14.5779376],
                [108.4126625, 14.5784696],
                [108.4126884, 14.5788775],
                [108.4126745, 14.5791955],
                [108.4126192, 14.5795528],
                [108.4124125, 14.5806392],
                [108.4123446, 14.5812051],
                [108.4122157, 14.5826171],
                [108.412135, 14.5834106],
                [108.4120607, 14.5836585],
                [108.4119448, 14.5839752],
                [108.4116835, 14.5844843],
                [108.4112643, 14.585404],
                [108.4111067, 14.5857949],
                [108.4109795, 14.586191],
                [108.4108612, 14.5866967],
                [108.4107224, 14.587217],
                [108.4106056, 14.5876034],
                [108.4102173, 14.5885085],
                [108.4098793, 14.5894589],
                [108.4097221, 14.5898052],
                [108.4095555, 14.5900866],
                [108.4092655, 14.5904312],
                [108.40876, 14.5908828],
                [108.4081216, 14.591328],
                [108.4076979, 14.5917705],
                [108.4073254, 14.5921839],
                [108.4065607, 14.5929557],
                [108.4058767, 14.5938278],
                [108.4054722, 14.59436],
                [108.4050671, 14.5949519],
                [108.404111, 14.5963775],
                [108.4034038, 14.5974681],
                [108.4031241, 14.5978027],
                [108.4028661, 14.5980386],
                [108.4021874, 14.5984434],
                [108.4016739, 14.5986962],
                [108.4011709, 14.5989291],
                [108.4006265, 14.5992014],
                [108.3998362, 14.5995454],
                [108.3993539, 14.5997488],
                [108.3983796, 14.6001056],
                [108.3977442, 14.6003072],
                [108.3959187, 14.6009876],
                [108.395456, 14.6012508],
                [108.395311, 14.6014281],
                [108.3951961, 14.6016455],
                [108.3948184, 14.6025079],
                [108.3946387, 14.6030221],
                [108.3945532, 14.603162],
                [108.3944943, 14.6031582],
                [108.3943532, 14.603076],
                [108.39398, 14.6025999],
                [108.3937698, 14.6023559],
                [108.3935122, 14.6021112],
                [108.3933721, 14.601937],
                [108.3932679, 14.6017402],
                [108.3931299, 14.6013933],
                [108.3930271, 14.601078],
                [108.3928113, 14.6003035],
                [108.3927542, 14.60013],
                [108.3926607, 14.6000252],
                [108.3925549, 14.5999548],
                [108.3923299, 14.5999581],
                [108.3920807, 14.600013],
                [108.3917358, 14.6001474],
                [108.3914493, 14.6003516],
                [108.3905293, 14.6010558],
                [108.3901831, 14.6013055],
                [108.3899324, 14.6014756],
                [108.3895761, 14.6015695],
                [108.3889955, 14.6016207],
                [108.3887104, 14.6016981],
                [108.3884251, 14.6017987],
                [108.3880917, 14.6019562],
                [108.3877449, 14.602252],
                [108.3874949, 14.6023759],
                [108.3872102, 14.6024188],
                [108.3869717, 14.6024321],
                [108.3866481, 14.6024224],
                [108.3862558, 14.6023228],
                [108.383593, 14.6018055],
                [108.3826231, 14.601787],
                [108.381158, 14.6017894],
                [108.3805596, 14.6018104],
                [108.3796723, 14.6018119],
                [108.3792801, 14.6017523],
                [108.3785578, 14.6016933],
                [108.3765438, 14.6012425],
                [108.3760369, 14.6010955],
                [108.3752913, 14.6007831],
                [108.3749424, 14.6004584],
                [108.3748274, 14.6003611],
                [108.3746532, 14.6000824],
                [108.3744104, 14.5995842],
                [108.3742374, 14.5992077],
                [108.3740763, 14.5988141],
                [108.3739392, 14.5983977],
                [108.3738238, 14.5981428],
                [108.3737072, 14.5979859],
                [108.373473, 14.5977759],
                [108.3723322, 14.5971407],
                [108.3720975, 14.5969652],
                [108.3718513, 14.5967665],
                [108.3716646, 14.596534],
                [108.3715251, 14.5963192],
                [108.3714337, 14.5960416],
                [108.3712156, 14.5954628],
                [108.3710771, 14.5951617],
                [108.3708685, 14.5947963],
                [108.3707051, 14.5945986],
                [108.3704947, 14.5943658],
                [108.3700853, 14.5939463],
                [108.3698754, 14.5936788],
                [108.3697012, 14.5934003],
                [108.3695035, 14.5931042],
                [108.3690873, 14.5922582],
                [108.3689022, 14.5918989],
                [108.3688103, 14.5916616],
                [108.3686533, 14.5909222],
                [108.3685866, 14.5905643],
                [108.3684068, 14.5897499],
                [108.3682929, 14.5893798],
                [108.3682252, 14.5891024],
                [108.3681819, 14.5887562],
                [108.3681515, 14.5883238],
                [108.3681065, 14.5871362],
                [108.3681119, 14.5866812],
                [108.3681032, 14.5864159],
                [108.3680478, 14.5860927],
                [108.3679338, 14.5857341],
                [108.3674927, 14.5849974],
                [108.367213, 14.5846139],
                [108.3669433, 14.5844034],
                [108.3665081, 14.5841622],
                [108.3660493, 14.5839266],
                [108.3653541, 14.5836537],
                [108.3647292, 14.5834507],
                [108.364021, 14.5832698],
                [108.3633954, 14.5831246],
                [108.3627351, 14.5829094],
                [108.3621934, 14.5826615],
                [108.3617935, 14.5824382],
                [108.3612876, 14.5821677],
                [108.3607219, 14.5819425],
                [108.3603681, 14.5818234],
                [108.3593767, 14.5815589],
                [108.359023, 14.5814251],
                [108.3585405, 14.5811775],
                [108.3582347, 14.5810012],
                [108.3579182, 14.580744],
                [108.3576493, 14.5804528],
                [108.3574512, 14.5801854],
                [108.3573005, 14.5799071],
                [108.3571856, 14.5796233],
                [108.3571064, 14.5793113],
                [108.3570632, 14.5789649],
                [108.3570326, 14.5785496],
                [108.3570614, 14.5781235],
                [108.3571498, 14.5776577],
                [108.3573113, 14.5770254],
                [108.3573745, 14.5766919],
                [108.3574137, 14.5763869],
                [108.3574412, 14.576053],
                [108.3574339, 14.575684],
                [108.357402, 14.5753725],
                [108.3573351, 14.5750259],
                [108.3571968, 14.5747073],
                [108.357082, 14.5744063],
                [108.3570148, 14.5740828],
                [108.3569726, 14.5736557],
                [108.3569895, 14.5732295],
                [108.3569731, 14.5726241],
                [108.356932, 14.5720934],
                [108.3569131, 14.5716898],
                [108.356907, 14.5712114],
                [108.3569212, 14.5710156],
                [108.3569604, 14.5707048],
                [108.3570595, 14.570337],
                [108.3571946, 14.5699351],
                [108.3574253, 14.5694478],
                [108.35791, 14.5685197],
                [108.3581649, 14.5679865],
                [108.3584072, 14.5675281],
                [108.358662, 14.5670123],
                [108.3588675, 14.5666516],
                [108.3591209, 14.566251],
                [108.3591341, 14.5661474],
                [108.3591009, 14.5660082],
                [108.3590421, 14.5659158],
                [108.3588556, 14.5656717],
                [108.358716, 14.5654626],
                [108.3585886, 14.5652249],
                [108.3585207, 14.5649589],
                [108.3584875, 14.5647626],
                [108.3584912, 14.5644515],
                [108.3586374, 14.5641074],
                [108.3588191, 14.5637636],
                [108.359107, 14.5634498],
                [108.3594538, 14.563154],
                [108.3599556, 14.5627793],
                [108.3603615, 14.5624957],
                [108.3609348, 14.5620757],
                [108.3616041, 14.5615472],
                [108.3620103, 14.5612405],
                [108.3624761, 14.5609001],
                [108.3629535, 14.5605711],
                [108.3637175, 14.5600552],
                [108.3644214, 14.5596021],
                [108.365042, 14.5591826],
                [108.3654718, 14.558882],
                [108.365877, 14.558656],
                [108.3663058, 14.5584418],
                [108.366698, 14.5583079],
                [108.3671014, 14.5582434],
                [108.367362, 14.5582232],
                [108.3675406, 14.5581445],
                [108.3676011, 14.5580299],
                [108.367627, 14.5578458],
                [108.3676311, 14.5575],
                [108.3676345, 14.5572177],
                [108.3676257, 14.5569639],
                [108.367629, 14.5566873],
                [108.3677038, 14.5563654],
                [108.3678493, 14.5560789],
                [108.3680774, 14.5558106],
                [108.3683291, 14.5555484],
                [108.3686749, 14.5553446],
                [108.3690085, 14.555164],
                [108.3692118, 14.5549934],
                [108.3694395, 14.5547539],
                [108.3695966, 14.5544905],
                [108.3696946, 14.5542208],
                [108.3698167, 14.5538994],
                [108.3699976, 14.5536248],
                [108.370226, 14.5533276],
                [108.3706216, 14.5529054],
                [108.3709935, 14.5525064],
                [108.3714369, 14.5520623],
                [108.3736671, 14.5489347],
                [108.3737363, 14.5486162],
                [108.373874, 14.5479412],
                [108.3739649, 14.5469965],
                [108.3739873, 14.546569],
                [108.3739846, 14.5449271],
                [108.3739372, 14.5442748],
                [108.3738905, 14.544005],
                [108.3738197, 14.5431279],
                [108.3733772, 14.5413292],
                [108.3729594, 14.5403853],
                [108.3723097, 14.5391491],
                [108.3714284, 14.537711],
                [108.3707331, 14.5367899],
                [108.3702003, 14.5362734],
                [108.3697357, 14.5355307],
                [108.3695091, 14.5349043],
                [108.3693787, 14.5339958],
                [108.3693457, 14.5334944],
                [108.3693772, 14.533087],
                [108.3694409, 14.5326482],
                [108.3696013, 14.5322406],
                [108.3697941, 14.5318957],
                [108.3701158, 14.5315817],
                [108.3710488, 14.5308283],
                [108.3721433, 14.5302311],
                [108.3727873, 14.5299794],
                [108.3736567, 14.5297273],
                [108.3743333, 14.529695],
                [108.3746876, 14.5296944],
                [108.3750421, 14.5297565],
                [108.3753324, 14.5298814],
                [108.3757512, 14.5299434],
                [108.3761379, 14.5299428],
                [108.3765888, 14.5298794],
                [108.3768465, 14.5298162],
                [108.3770397, 14.5297219],
                [108.377265, 14.5296276],
                [108.3777154, 14.5292508],
                [108.3778545, 14.529082],
                [108.377986, 14.5289472],
                [108.3781776, 14.5287648],
                [108.3784756, 14.5285951],
                [108.3789395, 14.5284159],
                [108.3795812, 14.5282271],
                [108.3801988, 14.5280726],
                [108.3808521, 14.5278954],
                [108.3814228, 14.5277],
                [108.3819932, 14.5275219],
                [108.3823974, 14.5273881],
                [108.3827069, 14.5272416],
                [108.3829456, 14.5270944],
                [108.3832092, 14.5268206],
                [108.3834141, 14.5265116],
                [108.3835726, 14.5261272],
                [108.3837439, 14.5256563],
                [108.3838438, 14.5252193],
                [108.3839198, 14.5247935],
                [108.3839963, 14.524339],
                [108.3840481, 14.523959],
                [108.3840903, 14.5233887],
                [108.3840928, 14.5231813],
                [108.3840632, 14.5226735],
                [108.3840203, 14.5222926],
                [108.3838845, 14.5217607],
                [108.3836892, 14.5212511],
                [108.3834465, 14.520747],
                [108.3833082, 14.5204226],
                [108.3831473, 14.5200057],
                [108.3830689, 14.5196243],
                [108.3830036, 14.5191336],
                [108.3829142, 14.5186828],
                [108.382825, 14.5182207],
                [108.3827478, 14.5177298],
                [108.3827053, 14.5173142],
                [108.382651, 14.5168986],
                [108.3825732, 14.516471],
                [108.3824503, 14.5158528],
                [108.3821893, 14.5148814],
                [108.3819013, 14.5142037],
                [108.3817645, 14.5137525],
                [108.3817046, 14.5128122],
                [108.3817237, 14.5121898],
                [108.3816675, 14.5119355],
                [108.3814258, 14.5113891],
                [108.3810988, 14.5109938],
                [108.3808521, 14.5108183],
                [108.3805342, 14.510665],
                [108.3801323, 14.5106029],
                [108.379753, 14.5106332],
                [108.3784589, 14.5109068],
                [108.37802, 14.5109595],
                [108.3771087, 14.5109147],
                [108.376282, 14.5107179],
                [108.3758456, 14.5105631],
                [108.3754921, 14.5104092],
                [108.3750456, 14.5101159],
                [108.3748945, 14.5098719],
                [108.3745716, 14.509136],
                [108.3742689, 14.5086944],
                [108.3739058, 14.5083559],
                [108.3727425, 14.5075759],
                [108.3722499, 14.5071667],
                [108.3719694, 14.5068522],
                [108.3714715, 14.5058952],
                [108.3711444, 14.5055109],
                [108.3709097, 14.5053353],
                [108.3702033, 14.5049814],
                [108.3700749, 14.5048185],
                [108.3699712, 14.5045809],
                [108.3696651, 14.5034242],
                [108.369526, 14.503169],
                [108.3693385, 14.5029996],
                [108.3691499, 14.5029282],
                [108.3683334, 14.5028731],
                [108.3680972, 14.5028126],
                [108.3679681, 14.5027189],
                [108.3678301, 14.5023714],
                [108.3678043, 14.5015523],
                [108.3677017, 14.5012109],
                [108.3676203, 14.5010831],
                [108.3674914, 14.5009664],
                [108.3672908, 14.5009065],
                [108.3666876, 14.5008421],
                [108.3663215, 14.5007572],
                [108.3659934, 14.5004652],
                [108.3654343, 14.4996857],
                [108.3654748, 14.4988464],
                [108.3654437, 14.4983317],
                [108.3653599, 14.498097],
                [108.3648934, 14.4978229],
                [108.3639321, 14.4975549],
                [108.3637116, 14.4973771],
                [108.3633528, 14.4968704],
                [108.3632084, 14.4965414],
                [108.3631878, 14.4961905],
                [108.3632928, 14.4956774],
                [108.3633043, 14.4952216],
                [108.3632221, 14.4948582],
                [108.362893, 14.4944571],
                [108.362416, 14.4940192],
                [108.3608122, 14.4921541],
                [108.3603725, 14.4916933],
                [108.3593634, 14.4907701],
                [108.3588756, 14.4902035],
                [108.3533495, 14.484425],
                [108.3516705, 14.4826408],
                [108.3498953, 14.4806453],
                [108.3476483, 14.4783053],
                [108.3470628, 14.4776441],
                [108.3457369, 14.4763315],
                [108.3448094, 14.4753157],
                [108.342811, 14.4733877],
                [108.3417553, 14.4722419],
                [108.3404055, 14.4708705],
                [108.339222, 14.4695479],
                [108.3364609, 14.466793],
                [108.3354606, 14.4656711],
                [108.3350343, 14.4651285],
                [108.3345149, 14.4646316],
                [108.3328795, 14.4628596],
                [108.3319329, 14.4619021],
                [108.3314456, 14.4612887],
                [108.329743, 14.4594574],
                [108.3288981, 14.4587816],
                [108.3281169, 14.4584687],
                [108.3276358, 14.4583814],
                [108.3266093, 14.4584165],
                [108.3264246, 14.458356],
                [108.3263151, 14.4582144],
                [108.3260172, 14.4572759],
                [108.3256388, 14.4563599],
                [108.3253706, 14.4560411],
                [108.3250521, 14.4558037],
                [108.3243308, 14.4551293],
                [108.3240697, 14.4547405],
                [108.3240297, 14.4539451],
                [108.3235485, 14.4512861],
                [108.3234345, 14.4499755],
                [108.3234834, 14.4489825],
                [108.323456, 14.4476612],
                [108.3233651, 14.4469938],
                [108.3227153, 14.4444732],
                [108.3226606, 14.4438881],
                [108.3226786, 14.4434208],
                [108.3227942, 14.443048],
                [108.3228108, 14.4426975],
                [108.322672, 14.4424154],
                [108.3218682, 14.4414126],
                [108.3211945, 14.4403879],
                [108.3205241, 14.4395971],
                [108.3202298, 14.439395],
                [108.3194298, 14.4391053],
                [108.3186882, 14.4385825],
                [108.3183354, 14.438111],
                [108.3179534, 14.4369844],
                [108.3175986, 14.4361621],
                [108.3173924, 14.4358324],
                [108.3170263, 14.4354425],
                [108.316598, 14.4350752],
                [108.3161198, 14.4347543],
                [108.3132626, 14.4333657],
                [108.3126859, 14.4330084],
                [108.3122576, 14.4326528],
                [108.3118166, 14.4318062],
                [108.3114178, 14.4305391],
                [108.3111647, 14.4299986],
                [108.3107104, 14.4292334],
                [108.3095927, 14.4271203],
                [108.3092299, 14.4264344],
                [108.308351, 14.4249983],
                [108.3075673, 14.4233644],
                [108.3072866, 14.4230574],
                [108.3068878, 14.4228308],
                [108.3062473, 14.4226364],
                [108.3052634, 14.4222276],
                [108.3029056, 14.4204473],
                [108.3018872, 14.4198277],
                [108.3014929, 14.4197297],
                [108.3012209, 14.4197383],
                [108.3001731, 14.4200186],
                [108.2996532, 14.4200828],
                [108.2991961, 14.4200659],
                [108.2985436, 14.4198597],
                [108.2981156, 14.4194806],
                [108.2978125, 14.4189862],
                [108.2976399, 14.4184349],
                [108.2974468, 14.4170299],
                [108.2973043, 14.4165374],
                [108.2970075, 14.416043],
                [108.2960336, 14.4148161],
                [108.2956816, 14.4142861],
                [108.2953975, 14.4137567],
                [108.2950018, 14.4127469],
                [108.2938522, 14.4102088],
                [108.2934583, 14.4095614],
                [108.29302, 14.4090186],
                [108.292116, 14.4081432],
                [108.2917632, 14.4076716],
                [108.2915636, 14.4073186],
                [108.2915094, 14.4066868],
                [108.291542, 14.4060326],
                [108.291623, 14.4054724],
                [108.2917694, 14.4051117],
                [108.2921351, 14.4045081],
                [108.2928125, 14.4036742],
                [108.2932183, 14.4033281],
                [108.2937815, 14.4027501],
                [108.2940082, 14.402402],
                [108.2942107, 14.4014926],
                [108.2944252, 14.4011209],
                [108.2946508, 14.4008664],
                [108.2952724, 14.4005578],
                [108.2955847, 14.4002809],
                [108.2958362, 14.3999214],
                [108.29602, 14.3995378],
                [108.2962557, 14.3984417],
                [108.2963204, 14.3976943],
                [108.2964653, 14.3969361],
                [108.2965149, 14.395908],
                [108.2966712, 14.3947292],
                [108.2965786, 14.3936877],
                [108.2966588, 14.392169],
                [108.2965099, 14.3917406],
                [108.2963569, 14.3915811],
                [108.2960991, 14.3914379],
                [108.2958219, 14.3913645],
                [108.295231, 14.3911824],
                [108.2946394, 14.3910471],
                [108.2941227, 14.3908541],
                [108.293582, 14.3906025],
                [108.2929368, 14.3903028],
                [108.2923721, 14.3899925],
                [108.291757, 14.3897516],
                [108.2912395, 14.3896287],
                [108.2907956, 14.3895535],
                [108.2902771, 14.3895008],
                [108.2897026, 14.3894943],
                [108.2889973, 14.389568],
                [108.2884777, 14.3896088],
                [108.2879588, 14.3896028],
                [108.2875516, 14.3895515],
                [108.2869849, 14.3894046],
                [108.2863146, 14.3891397],
                [108.2855033, 14.3887797],
                [108.2849126, 14.3885743],
                [108.2844932, 14.3885111],
                [108.283801, 14.3885265],
                [108.2833982, 14.388627],
                [108.2825788, 14.3889449],
                [108.2818084, 14.3892867],
                [108.2812265, 14.3893736],
                [108.2807941, 14.3893686],
                [108.2804862, 14.3892832],
                [108.2801854, 14.389116],
                [108.2796961, 14.3887013],
                [108.2791095, 14.3881569],
                [108.2784603, 14.3876818],
                [108.2779443, 14.3874421],
                [108.2772675, 14.3872004],
                [108.2765603, 14.3869215],
                [108.2756871, 14.3865728],
                [108.2750347, 14.3863667],
                [108.2742561, 14.3863694],
                [108.2738719, 14.3864585],
                [108.2734124, 14.3866517],
                [108.2729401, 14.3868681],
                [108.2722816, 14.3871643],
                [108.271647, 14.3875191],
                [108.2709634, 14.3878499],
                [108.2702427, 14.3881686],
                [108.2697341, 14.3883263],
                [108.269215, 14.3883436],
                [108.2685862, 14.3882196],
                [108.267909, 14.3880132],
                [108.2674656, 14.3878912],
                [108.2671322, 14.3878757],
                [108.2667245, 14.3878711],
                [108.2663545, 14.3878083],
                [108.2658871, 14.3876277],
                [108.2653697, 14.3874932],
                [108.2648513, 14.3874405],
                [108.2643813, 14.3874702],
                [108.2639971, 14.3875708],
                [108.2636118, 14.3877532],
                [108.2630638, 14.3880973],
                [108.262652, 14.3884312],
                [108.2617915, 14.3890637],
                [108.2609057, 14.3897543],
                [108.2602077, 14.3902484],
                [108.259648, 14.3905456],
                [108.2592013, 14.3906923],
                [108.2586198, 14.390744],
                [108.2580882, 14.3907729],
                [108.2572359, 14.3907397],
                [108.2561984, 14.3906925],
                [108.2552838, 14.3906936],
                [108.2543938, 14.3907183],
                [108.2534053, 14.3907068],
                [108.2526881, 14.3907452],
                [108.2521676, 14.3908676],
                [108.2517325, 14.3910728],
                [108.2513455, 14.3913954],
                [108.250994, 14.3918469],
                [108.2507043, 14.392299],
                [108.2503729, 14.3929256],
                [108.250055, 14.3929416],
                [108.2497539, 14.3929686],
                [108.2493124, 14.3930549],
                [108.2490317, 14.3931533],
                [108.248891, 14.3932431],
                [108.2487388, 14.3933937],
                [108.2484543, 14.3938071],
                [108.2483209, 14.3941715],
                [108.2482346, 14.3946642],
                [108.2482483, 14.394708],
                [108.2482117, 14.3950567],
                [108.2481009, 14.39533],
                [108.2479471, 14.3956026],
                [108.2477614, 14.3958445],
                [108.2475988, 14.3959646],
                [108.2473169, 14.3961545],
                [108.2470144, 14.3962832],
                [108.2467718, 14.3963515],
                [108.2461038, 14.396476],
                [108.2457271, 14.3965223],
                [108.2454366, 14.3965495],
                [108.2451141, 14.3965456],
                [108.2446958, 14.3964798],
                [108.2443, 14.3963227],
                [108.2437601, 14.3960928],
                [108.2430124, 14.3957078],
                [108.2420306, 14.3951372],
                [108.241147, 14.3944456],
                [108.2408759, 14.3942087],
                [108.2406207, 14.3939719],
                [108.2404302, 14.3937459],
                [108.2402822, 14.3935409],
                [108.2402087, 14.3933976],
                [108.2401242, 14.3932747],
                [108.2399166, 14.3931198],
                [108.2394468, 14.39286],
                [108.2382915, 14.3924094],
                [108.2375641, 14.3921365],
                [108.2370937, 14.3919277],
                [108.2363883, 14.3916043],
                [108.2355389, 14.3911978],
                [108.2346104, 14.3906684],
                [108.2333714, 14.3900337],
                [108.2328855, 14.3897841],
                [108.2325216, 14.3896577],
                [108.2320837, 14.3901175],
                [108.2311051, 14.3911649],
                [108.2306743, 14.3916345],
                [108.2302965, 14.3919952],
                [108.2300691, 14.3921654],
                [108.2297585, 14.3922835],
                [108.2293983, 14.3922672],
                [108.2285644, 14.3920138],
                [108.2279628, 14.3917265],
                [108.2274885, 14.3915504],
                [108.2268976, 14.391434],
                [108.2264036, 14.3912941],
                [108.2258125, 14.3912019],
                [108.2251434, 14.3911575],
                [108.224577, 14.3911509],
                [108.2240617, 14.3911691],
                [108.2232872, 14.3913184],
                [108.2227648, 14.3913975],
                [108.2221716, 14.3914758],
                [108.2218624, 14.3914844],
                [108.2215919, 14.3914934],
                [108.2213603, 14.3914785],
                [108.2212193, 14.3914281],
                [108.2208343, 14.3913262],
                [108.2202083, 14.390929],
                [108.2195128, 14.3904214],
                [108.2189788, 14.389867],
                [108.2184565, 14.3894224],
                [108.21792, 14.389075],
                [108.2174528, 14.3888503],
                [108.2169008, 14.3887098],
                [108.2165547, 14.3885839],
                [108.2162091, 14.3884215],
                [108.2156472, 14.3880495],
                [108.2145612, 14.3873668],
                [108.2141578, 14.3871915],
                [108.21385, 14.3870904],
                [108.2136442, 14.3870758],
                [108.2133351, 14.3870722],
                [108.212832, 14.3871515],
                [108.212574, 14.3871972],
                [108.211697, 14.3873086],
                [108.2111556, 14.3873631],
                [108.2108399, 14.3873838],
                [108.2105954, 14.3873688],
                [108.21039, 14.3873176],
                [108.2100185, 14.387167],
                [108.2096099, 14.3868942],
                [108.2093042, 14.3866227],
                [108.2089992, 14.3863024],
                [108.2084546, 14.3855773],
                [108.2079103, 14.3848157],
                [108.2075244, 14.3842752],
                [108.2072117, 14.3837278],
                [108.2071246, 14.3834182],
                [108.2069423, 14.3828991],
                [108.2067941, 14.382577],
                [108.2066697, 14.3822736],
                [108.2065176, 14.3820567],
                [108.2062737, 14.3818434],
                [108.2059956, 14.381648],
                [108.2056307, 14.3814331],
                [108.2052391, 14.3812318],
                [108.2049508, 14.3810865],
                [108.2048693, 14.3810215],
                [108.2047309, 14.3808825],
                [108.204702, 14.3808142],
                [108.2046635, 14.3806576],
                [108.2046555, 14.3803235],
                [108.2047121, 14.3798712],
                [108.2048421, 14.3791314],
                [108.2048562, 14.3787748],
                [108.2048557, 14.3784224],
                [108.2048914, 14.3780797],
                [108.2049193, 14.3777872],
                [108.2049187, 14.3774395],
                [108.2049256, 14.3772794],
                [108.2049227, 14.3771238],
                [108.2049037, 14.3770916],
                [108.2046275, 14.3769245],
                [108.2044072, 14.3768718],
                [108.2041862, 14.3768692],
                [108.2038764, 14.3769073],
                [108.2037259, 14.3769306],
                [108.2035315, 14.3769283],
                [108.2033635, 14.3769263],
                [108.2032313, 14.3768997],
                [108.2031086, 14.3768063],
                [108.2030214, 14.3767218],
                [108.203004, 14.3766522],
                [108.2030002, 14.3765385],
                [108.2031119, 14.3763865],
                [108.2033905, 14.3760599],
                [108.2035188, 14.3758388],
                [108.2035946, 14.3756319],
                [108.2037136, 14.3753166],
                [108.203715, 14.3752077],
                [108.2037217, 14.3750891],
                [108.2037099, 14.374985],
                [108.2036226, 14.3748553],
                [108.2035291, 14.3748047],
                [108.2034511, 14.3747641],
                [108.2032787, 14.3747523],
                [108.2031844, 14.3747611],
                [108.2029956, 14.3748033],
                [108.2028061, 14.374905],
                [108.2024111, 14.375128],
                [108.2022089, 14.3751849],
                [108.2018133, 14.3752397],
                [108.2015412, 14.3752611],
                [108.2009437, 14.3753581],
                [108.2006497, 14.3754486],
                [108.2002389, 14.3756763],
                [108.199568, 14.3762127],
                [108.1993113, 14.376462],
                [108.1990999, 14.3766328],
                [108.1988577, 14.3767684],
                [108.198679, 14.3768356],
                [108.1986371, 14.3768351],
                [108.1985279, 14.3767893],
                [108.1984451, 14.3767091],
                [108.198394, 14.3766244],
                [108.1983899, 14.3765254],
                [108.1984076, 14.3763672],
                [108.1985042, 14.3761654],
                [108.198756, 14.3756884],
                [108.1989175, 14.3753242],
                [108.1990767, 14.3749252],
                [108.199115, 14.374792],
                [108.1991559, 14.3744412],
                [108.1991574, 14.3741146],
                [108.1991545, 14.3739315],
                [108.1991371, 14.3736443],
                [108.1990109, 14.3733193],
                [108.1989207, 14.3731765],
                [108.1988205, 14.3730318],
                [108.198681, 14.3728866],
                [108.1985719, 14.3728358],
                [108.1984675, 14.3728148],
                [108.1983158, 14.372818],
                [108.1980852, 14.3728549],
                [108.1977963, 14.3729652],
                [108.1975275, 14.3731303],
                [108.1974016, 14.3731586],
                [108.1972156, 14.373186],
                [108.1969178, 14.3731627],
                [108.1966049, 14.3730897],
                [108.1961384, 14.3729555],
                [108.1958969, 14.3729169],
                [108.1954941, 14.3729213],
                [108.1952899, 14.3729602],
                [108.1951579, 14.3730319],
                [108.1950078, 14.3731985],
                [108.1948045, 14.3733581],
                [108.1945941, 14.3734978],
                [108.1944134, 14.3735828],
                [108.1941816, 14.3736912],
                [108.1940355, 14.3737353],
                [108.1938459, 14.3737606],
                [108.1935349, 14.3737983],
                [108.1932481, 14.3738362],
                [108.1930512, 14.3738615],
                [108.1928862, 14.3738641],
                [108.1926491, 14.3738625],
                [108.1925376, 14.3738049],
                [108.1924562, 14.3737212],
                [108.192347, 14.3735225],
                [108.1923115, 14.3732604],
                [108.1922994, 14.3730582],
                [108.1922709, 14.3728145],
                [108.1922752, 14.3725578],
                [108.1922137, 14.3723501],
                [108.1918741, 14.3715656],
                [108.1916559, 14.3711635],
                [108.191418, 14.3707842],
                [108.1911062, 14.3702984],
                [108.1907554, 14.3698351],
                [108.1905071, 14.3695062],
                [108.1902788, 14.3691922],
                [108.1901312, 14.3690292],
                [108.1899582, 14.3688714],
                [108.1898457, 14.368802],
                [108.1896924, 14.3687272],
                [108.1896204, 14.3687166],
                [108.1895071, 14.3687298],
                [108.1893515, 14.3688253],
                [108.1891275, 14.3690367],
                [108.1890027, 14.3691374],
                [108.1889147, 14.3691801],
                [108.1888349, 14.3691803],
                [108.188686, 14.3691541],
                [108.1885373, 14.3691036],
                [108.1883896, 14.3689849],
                [108.1881616, 14.3686999],
                [108.1880202, 14.3684839],
                [108.1879769, 14.3684493],
                [108.187844, 14.3683796],
                [108.1877671, 14.3683543],
                [108.1876126, 14.3683573],
                [108.1873696, 14.3684469],
                [108.1870851, 14.3685653],
                [108.1867593, 14.3686881],
                [108.1865758, 14.3687541],
                [108.1864106, 14.3687861],
                [108.1862716, 14.3687943],
                [108.186143, 14.3687928],
                [108.186004, 14.368791],
                [108.1858149, 14.3687259],
                [108.1854821, 14.3685511],
                [108.185085, 14.36841],
                [108.184803, 14.3683239],
                [108.1844823, 14.368247],
                [108.1842826, 14.3681716],
                [108.1840778, 14.3680816],
                [108.1839346, 14.3680068],
                [108.1838629, 14.3679864],
                [108.1837548, 14.3679852],
                [108.1836412, 14.3680131],
                [108.1835839, 14.3680707],
                [108.1834689, 14.3682154],
                [108.1834051, 14.3683754],
                [108.1833293, 14.3686716],
                [108.1832502, 14.3688265],
                [108.1830227, 14.3691799],
                [108.182846, 14.3692551],
                [108.1826081, 14.3693398],
                [108.1824741, 14.3693529],
                [108.1823815, 14.3693616],
                [108.1822734, 14.3693602],
                [108.1821144, 14.3693194],
                [108.1819506, 14.3692493],
                [108.1818561, 14.3691896],
                [108.1817701, 14.3690719],
                [108.1815633, 14.3687333],
                [108.181506, 14.3685817],
                [108.1813752, 14.3683415],
                [108.1811774, 14.3681054],
                [108.1810346, 14.3680063],
                [108.180799, 14.367911],
                [108.1807169, 14.3678954],
                [108.180578, 14.3678889],
                [108.1803977, 14.3679062],
                [108.180119, 14.3679711],
                [108.1799201, 14.3680223],
                [108.1798172, 14.3680259],
                [108.1797091, 14.3680246],
                [108.1795188, 14.3680176],
                [108.1794572, 14.368007],
                [108.1792776, 14.3679659],
                [108.1791131, 14.3679396],
                [108.1790207, 14.3679288],
                [108.1788458, 14.3679267],
                [108.1783382, 14.3679791],
                [108.17807, 14.3680197],
                [108.1777966, 14.3680748],
                [108.1776214, 14.3680825],
                [108.1774391, 14.3680511],
                [108.1772601, 14.3679661],
                [108.1771506, 14.3678725],
                [108.1770699, 14.3677496],
                [108.176995, 14.3675637],
                [108.1768809, 14.3672215],
                [108.176786, 14.3669963],
                [108.1766339, 14.3668047],
                [108.1765382, 14.3666476],
                [108.1763693, 14.3666324],
                [108.1762225, 14.3666399],
                [108.1760167, 14.3666744],
                [108.1757611, 14.3667731],
                [108.1755641, 14.3668726],
                [108.1754656, 14.3669315],
                [108.1753232, 14.366976],
                [108.1752008, 14.3669791],
                [108.1750738, 14.3669683],
                [108.1748837, 14.3669107],
                [108.1746202, 14.3668658],
                [108.1744098, 14.366838],
                [108.1739675, 14.3668106],
                [108.173723, 14.3668076],
                [108.1734393, 14.3668089],
                [108.1732876, 14.3668071],
                [108.1731458, 14.36681],
                [108.1728884, 14.3668533],
                [108.1725057, 14.3669518],
                [108.1722152, 14.3671043],
                [108.1718977, 14.3673274],
                [108.1714468, 14.3675667],
                [108.1709394, 14.3678172],
                [108.1705881, 14.3679963],
                [108.1703675, 14.3680548],
                [108.1701355, 14.3680031],
                [108.1697891, 14.3677912],
                [108.1690485, 14.367049],
                [108.1687573, 14.3667908],
                [108.1685311, 14.3665907],
                [108.1683154, 14.366353],
                [108.1680025, 14.3659356],
                [108.1675125, 14.3653469],
                [108.1672773, 14.3650715],
                [108.1669971, 14.3648159],
                [108.166771, 14.3646062],
                [108.1665047, 14.3644242],
                [108.1662875, 14.3643087],
                [108.1660994, 14.3642311],
                [108.1658613, 14.3641907],
                [108.1656924, 14.3641792],
                [108.1654833, 14.364205],
                [108.1652134, 14.3643147],
                [108.1649131, 14.3644522],
                [108.1647282, 14.3645347],
                [108.1645087, 14.3645884],
                [108.1643495, 14.364596],
                [108.16426, 14.3645949],
                [108.1641509, 14.3645748],
                [108.1640522, 14.3645172],
                [108.1639139, 14.3644402],
                [108.1638552, 14.3643642],
                [108.1637968, 14.36426],
                [108.1637788, 14.3641092],
                [108.1637817, 14.363874],
                [108.1638252, 14.3635829],
                [108.1638172, 14.3634324],
                [108.1637695, 14.3632718],
                [108.1635844, 14.3629591],
                [108.1634233, 14.3627219],
                [108.1632672, 14.3624848],
                [108.1631121, 14.3621725],
                [108.1629273, 14.3618409],
                [108.1628095, 14.3617173],
                [108.1626611, 14.361659],
                [108.1625715, 14.3616579],
                [108.1623922, 14.361684],
                [108.1621015, 14.3618688],
                [108.1617597, 14.3621563],
                [108.1614587, 14.3623691],
                [108.161188, 14.3625445],
                [108.1610525, 14.362637],
                [108.1608829, 14.3626821],
                [108.1607634, 14.36269],
                [108.1606442, 14.3626792],
                [108.1604959, 14.3626115],
                [108.1602593, 14.3624487],
                [108.1599654, 14.3620971],
                [108.1595368, 14.3613863],
                [108.1592469, 14.3607148],
                [108.1590513, 14.3600633],
                [108.1588788, 14.3595437],
                [108.1587139, 14.3592219],
                [108.158582, 14.359032],
                [108.1583758, 14.3588226],
                [108.1581797, 14.3585944],
                [108.1579542, 14.3583471],
                [108.1578569, 14.3581765],
                [108.1577899, 14.3579687],
                [108.1577547, 14.3576014],
                [108.1577939, 14.3572538],
                [108.1578989, 14.3568129],
                [108.1579825, 14.356494],
                [108.1580083, 14.356419],
                [108.15801, 14.3562872],
                [108.157952, 14.3561548],
                [108.1577657, 14.3559456],
                [108.1575491, 14.3557737],
                [108.157333, 14.3555547],
                [108.157118, 14.3552604],
                [108.1568445, 14.3548713],
                [108.1567082, 14.3546345],
                [108.1565784, 14.3542848],
                [108.1564527, 14.354001],
                [108.1563077, 14.35367],
                [108.15622, 14.3535278],
                [108.156102, 14.3534229],
                [108.1560427, 14.353394],
                [108.1559633, 14.3533836],
                [108.1558236, 14.3534196],
                [108.1556434, 14.3535115],
                [108.1554836, 14.3535661],
                [108.1553542, 14.353574],
                [108.1552056, 14.3535345],
                [108.1548395, 14.3533795],
                [108.1542944, 14.3532412],
                [108.1540711, 14.353201],
                [108.153773, 14.353188],
                [108.1534544, 14.3532218],
                [108.1528863, 14.3533184],
                [108.1524874, 14.3533984],
                [108.1521882, 14.35347],
                [108.152089, 14.3534501],
                [108.151951, 14.3533542],
                [108.1518098, 14.3531173],
                [108.1514696, 14.3524922],
                [108.1513648, 14.3521334],
                [108.1513287, 14.3518413],
                [108.1513364, 14.3516251],
                [108.1514904, 14.3512505],
                [108.1517244, 14.35083],
                [108.1519359, 14.3506161],
                [108.1521267, 14.3504678],
                [108.152296, 14.3503751],
                [108.1524963, 14.3503398],
                [108.1526731, 14.3503527],
                [108.1528552, 14.3504089],
                [108.1534322, 14.3506909],
                [108.153926, 14.350713],
                [108.1545149, 14.3500004],
                [108.1545724, 14.3493887],
                [108.1545228, 14.3492476],
                [108.1542812, 14.3489368],
                [108.1541002, 14.348805],
                [108.1538219, 14.3486828],
                [108.1532987, 14.3485253],
                [108.1529143, 14.3484396],
                [108.1525495, 14.348392],
                [108.1521328, 14.3483816],
                [108.1516301, 14.3484026],
                [108.1513127, 14.348442],
                [108.1510551, 14.3484929],
                [108.1506086, 14.3485794],
                [108.1500755, 14.3487459],
                [108.1497346, 14.3488443],
                [108.1494827, 14.34889],
                [108.1492826, 14.3489146],
                [108.1491056, 14.3489179],
                [108.1490028, 14.3489166],
                [108.1488889, 14.3488882],
                [108.1487813, 14.3488275],
                [108.1486743, 14.348702],
                [108.1485992, 14.3485553],
                [108.1484765, 14.3483215],
                [108.1482923, 14.3479897],
                [108.1481803, 14.3478155],
                [108.1481044, 14.3477173],
                [108.1479576, 14.3475967],
                [108.1477649, 14.3474809],
                [108.1475599, 14.3474352],
                [108.1473431, 14.3474219],
                [108.1468749, 14.3474162],
                [108.1464915, 14.3474926],
                [108.1463166, 14.3475446],
                [108.146087, 14.3476444],
                [108.1459086, 14.3477502],
                [108.1457582, 14.3479106],
                [108.1456405, 14.3481846],
                [108.1455786, 14.3485675],
                [108.1455197, 14.3489287],
                [108.145457, 14.3493763],
                [108.1454405, 14.349549],
                [108.1454041, 14.3497215],
                [108.1453686, 14.3498182],
                [108.1452806, 14.3500008],
                [108.1452669, 14.3500201],
                [108.1451585, 14.3501723],
                [108.144987, 14.3504187],
                [108.144732, 14.3507128],
                [108.1445405, 14.3509481],
                [108.1443654, 14.3512539],
                [108.1441471, 14.3515809],
                [108.1440363, 14.3517686],
                [108.1439204, 14.3519022],
                [108.1436171, 14.3521849],
                [108.1434387, 14.3522963],
                [108.1432313, 14.3524342],
                [108.1429439, 14.3525927],
                [108.1426849, 14.3527571],
                [108.142483, 14.3529167],
                [108.1424048, 14.3530022],
                [108.1423121, 14.3531037],
                [108.1422654, 14.3531896],
                [108.1422644, 14.3532652],
                [108.1422911, 14.3534168],
                [108.1424876, 14.3536784],
                [108.1426398, 14.3538316],
                [108.1429191, 14.3540943],
                [108.1432247, 14.3543032],
                [108.1434741, 14.3544467],
                [108.1436446, 14.354519],
                [108.1438498, 14.3545377],
                [108.1442151, 14.3545529],
                [108.144469, 14.3545722],
                [108.1447028, 14.3545912],
                [108.1449078, 14.3546368],
                [108.1451013, 14.3546932],
                [108.1452373, 14.3547651],
                [108.1453559, 14.3548691],
                [108.1454403, 14.3549674],
                [108.145661, 14.3553482],
                [108.1458123, 14.3555769],
                [108.1460098, 14.355763],
                [108.1462192, 14.3559114],
                [108.1463668, 14.3559726],
                [108.1464977, 14.3560174],
                [108.1466174, 14.3560297],
                [108.146817, 14.3560428],
                [108.1470458, 14.3560132],
                [108.1472496, 14.3559238],
                [108.147503, 14.3557594],
                [108.1476929, 14.3556428],
                [108.1479228, 14.3555213],
                [108.1480774, 14.3554853],
                [108.1482258, 14.3554926],
                [108.1483505, 14.3555643],
                [108.1483953, 14.3556297],
                [108.1484198, 14.3557326],
                [108.148395, 14.3558836],
                [108.1482128, 14.3562919],
                [108.1479555, 14.3567696],
                [108.1478592, 14.3569359],
                [108.1477875, 14.3571465],
                [108.1477677, 14.3573215],
                [108.1477927, 14.3574386],
                [108.1478875, 14.3576149],
                [108.1480097, 14.3577249],
                [108.1481932, 14.3578605],
                [108.1485699, 14.358082],
                [108.1490074, 14.3583793],
                [108.149295, 14.3586663],
                [108.1496031, 14.3590721],
                [108.1497756, 14.3593915],
                [108.1498868, 14.3596683],
                [108.1499016, 14.3598939],
                [108.1498288, 14.3600685],
                [108.1497216, 14.3601673],
                [108.1495217, 14.3602734],
                [108.1492561, 14.3603454],
                [108.1487792, 14.3603648],
                [108.1480915, 14.3603147],
                [108.1476729, 14.3602679],
                [108.1473035, 14.3601716],
                [108.1469434, 14.3600336],
                [108.1463989, 14.3598267],
                [108.1456869, 14.359601],
                [108.1451321, 14.3595109],
                [108.1442864, 14.3594005],
                [108.1435675, 14.3593667],
                [108.1429055, 14.3593838],
                [108.1421626, 14.3595001],
                [108.1416801, 14.3596279],
                [108.1411748, 14.3598055],
                [108.1406959, 14.3599917],
                [108.1404212, 14.3600802],
                [108.1402618, 14.36012],
                [108.1401558, 14.3601271],
                [108.140024, 14.3600837],
                [108.1398536, 14.3599565],
                [108.1396713, 14.3597121],
                [108.1395852, 14.3595442],
                [108.1395524, 14.3593517],
                [108.1395737, 14.3590597],
                [108.1397051, 14.3584435],
                [108.1397654, 14.3582188],
                [108.1398208, 14.3580191],
                [108.1398511, 14.3577189],
                [108.1398273, 14.3575099],
                [108.1397506, 14.3573002],
                [108.1395864, 14.3570227],
                [108.1394871, 14.3568462],
                [108.1392954, 14.3566602],
                [108.1390409, 14.3565485],
                [108.1387327, 14.3564947],
                [108.138177, 14.3564712],
                [108.1374263, 14.3565207],
                [108.1369976, 14.3565739],
                [108.1368831, 14.3565558],
                [108.1368044, 14.3565048],
                [108.1366209, 14.3563523],
                [108.1361862, 14.3558462],
                [108.1357847, 14.3554989],
                [108.1355574, 14.3553292],
                [108.1354344, 14.3552776],
                [108.1353065, 14.355276],
                [108.1350417, 14.3552813],
                [108.1348025, 14.3553534],
                [108.1343236, 14.3555397],
                [108.1331849, 14.3559099],
                [108.1328485, 14.3559977],
                [108.1324505, 14.3560596],
                [108.1317264, 14.356101],
                [108.1306308, 14.3562046],
                [108.1294862, 14.3563495],
                [108.1282929, 14.3565019],
                [108.1273426, 14.3566324],
                [108.1267498, 14.3567503],
                [108.1263161, 14.3568537],
                [108.1258722, 14.3570571],
                [108.1245079, 14.3578169],
                [108.1234673, 14.3584472],
                [108.1225276, 14.359137],
                [108.1220635, 14.3595405],
                [108.121715, 14.3598786],
                [108.1210671, 14.3605136],
                [108.1205922, 14.3610756],
                [108.1203235, 14.3613811],
                [108.1199618, 14.3617107],
                [108.1195513, 14.3620815],
                [108.1191951, 14.3623192],
                [108.1188039, 14.3625483],
                [108.1183144, 14.3628763],
                [108.1179814, 14.3630391],
                [108.1175819, 14.363218],
                [108.1171919, 14.3633552],
                [108.1168468, 14.3634262],
                [108.1162143, 14.3635437],
                [108.1158433, 14.3635726],
                [108.1154638, 14.3635679],
                [108.1150936, 14.3635383],
                [108.1149003, 14.3634776],
                [108.1146901, 14.3633498],
                [108.1144023, 14.363079],
                [108.113986, 14.3625146],
                [108.1134292, 14.36189],
                [108.1132249, 14.3616538],
                [108.1129719, 14.3614253],
                [108.1128683, 14.3612403],
                [108.1127136, 14.3609211],
                [108.112629, 14.3606362],
                [108.1125931, 14.3603352],
                [108.1125006, 14.3599835],
                [108.1123743, 14.3595143],
                [108.1121362, 14.3588102],
                [108.1120302, 14.3584749],
                [108.1118842, 14.3581559],
                [108.1116275, 14.3578772],
                [108.1113927, 14.3576072],
                [108.1112358, 14.357455],
                [108.1110878, 14.3572946],
                [108.1109401, 14.3571174],
                [108.1108185, 14.3569656],
                [108.1106891, 14.3567303],
                [108.1105424, 14.3564781],
                [108.1101728, 14.3557055],
                [108.1100648, 14.3555288],
                [108.1098824, 14.3553011],
                [108.1095952, 14.3549887],
                [108.1092996, 14.3546429],
                [108.1090738, 14.3543645],
                [108.1087918, 14.3539853],
                [108.1085839, 14.3536824],
                [108.1084892, 14.3535058],
                [108.1084295, 14.3533381],
                [108.108415, 14.3530958],
                [108.1084939, 14.3527878],
                [108.1086587, 14.3523224],
                [108.1089483, 14.3517581],
                [108.1093116, 14.3513033],
                [108.1098849, 14.3506424],
                [108.1103143, 14.3501801],
                [108.1107307, 14.3497093],
                [108.1110353, 14.3493539],
                [108.1112149, 14.349114],
                [108.1114126, 14.3488327],
                [108.1116153, 14.3485095],
                [108.1119632, 14.3478709],
                [108.1121265, 14.3475305],
                [108.1122892, 14.3472236],
                [108.1123804, 14.3469909],
                [108.1124359, 14.3467912],
                [108.1124572, 14.3465076],
                [108.112439, 14.3462069],
                [108.1123731, 14.3458387],
                [108.1122532, 14.3455617],
                [108.1120454, 14.3452503],
                [108.111854, 14.3450392],
                [108.1115311, 14.3447515],
                [108.1112684, 14.344598],
                [108.1109082, 14.3444766],
                [108.1106393, 14.3444483],
                [108.1101807, 14.3444344],
                [108.1097476, 14.3444959],
                [108.1091853, 14.3446393],
                [108.1088489, 14.3447354],
                [108.1086008, 14.3448159],
                [108.1084238, 14.344847],
                [108.1081588, 14.3448688],
                [108.1077532, 14.3448473],
                [108.1073488, 14.3447338],
                [108.106575, 14.3445156],
                [108.1061048, 14.3443679],
                [108.1056836, 14.3441874],
                [108.1050529, 14.3438208],
                [108.1045729, 14.3434058],
                [108.1040816, 14.3428405],
                [108.1038819, 14.3425875],
                [108.1038054, 14.3423612],
                [108.1037899, 14.3422023],
                [108.103801, 14.3420272],
                [108.1038473, 14.3418524],
                [108.1040207, 14.3414036],
                [108.1043512, 14.3407481],
                [108.1045418, 14.3403413],
                [108.104663, 14.3398335],
                [108.1047374, 14.3391915],
                [108.1047428, 14.3387742],
                [108.1047017, 14.33854],
                [108.104618, 14.3381883],
                [108.1045276, 14.3380035],
                [108.1043806, 14.3377763],
                [108.1042671, 14.3376747],
                [108.1041706, 14.3376401],
                [108.1038976, 14.3375951],
                [108.1035977, 14.3375913],
                [108.1032535, 14.3375955],
                [108.1030681, 14.3375932],
                [108.102839, 14.3375738],
                [108.1026018, 14.3374957],
                [108.102086, 14.337122],
                [108.1013869, 14.3365958],
                [108.1007573, 14.3361457],
                [108.1002854, 14.3357892],
                [108.0997421, 14.3354987],
                [108.0992157, 14.3352586],
                [108.0982214, 14.3350461],
                [108.0974203, 14.3349026],
                [108.0968612, 14.3348123],
                [108.0964567, 14.3347071],
                [108.0961311, 14.3346364],
                [108.095849, 14.3346163],
                [108.0953811, 14.3346355],
                [108.0948156, 14.3346954],
                [108.0942816, 14.3347055],
                [108.0939024, 14.3346926],
                [108.0934436, 14.3346869],
                [108.0929326, 14.3346306],
                [108.0918486, 14.3345254],
                [108.0907333, 14.3344617],
                [108.0901071, 14.3344457],
                [108.0893745, 14.3344533],
                [108.0888759, 14.3344639],
                [108.0884966, 14.3344509],
                [108.0882055, 14.3344473],
                [108.0880114, 14.334445],
                [108.0877206, 14.3344164],
                [108.0874124, 14.3343708],
                [108.0868141, 14.3342382],
                [108.086475, 14.3341923],
                [108.0859898, 14.334178],
                [108.0855486, 14.3341892],
                [108.0850188, 14.3342162],
                [108.0843394, 14.3342078],
                [108.0829415, 14.3341655],
                [108.0823017, 14.3341744],
                [108.0818956, 14.3341861],
                [108.0815372, 14.3341347],
                [108.080152, 14.3338062],
                [108.0792542, 14.3334106],
                [108.0790102, 14.3331738],
                [108.0789141, 14.3330193],
                [108.0787237, 14.3326721],
                [108.0785895, 14.3324452],
                [108.0784485, 14.332183],
                [108.0783081, 14.3318786],
                [108.0781891, 14.3315885],
                [108.07812, 14.3313484],
                [108.0780439, 14.3310941],
                [108.077986, 14.3308269],
                [108.0779738, 14.3306577],
                [108.0779755, 14.3305169],
                [108.0780003, 14.3302776],
                [108.0780459, 14.3301091],
                [108.0781138, 14.3298775],
                [108.0782439, 14.3295904],
                [108.0783842, 14.3293455],
                [108.0784874, 14.3291848],
                [108.0786555, 14.3290248],
                [108.0788012, 14.328921],
                [108.0790123, 14.3287828],
                [108.0792854, 14.328603],
                [108.0794752, 14.3284432],
                [108.0796945, 14.3282346],
                [108.079892, 14.3280328],
                [108.0801041, 14.327817],
                [108.0802939, 14.3276504],
                [108.080582, 14.3274285],
                [108.080852, 14.3272063],
                [108.0810707, 14.32704],
                [108.0812749, 14.3268805],
                [108.081457, 14.326756],
                [108.081632, 14.3266243],
                [108.0818245, 14.3265281],
                [108.0819915, 14.3264596],
                [108.0822159, 14.3264131],
                [108.0824184, 14.3263803],
                [108.0825994, 14.3263403],
                [108.0828634, 14.3262585],
                [108.0831243, 14.3261842],
                [108.0834507, 14.3260755],
                [108.0837552, 14.3259738],
                [108.0840525, 14.3258788],
                [108.0843569, 14.325791],
                [108.0846978, 14.3256755],
                [108.0849586, 14.3256013],
                [108.0852412, 14.3255274],
                [108.0854878, 14.3254459],
                [108.085767, 14.325352],
                [108.085985, 14.3252489],
                [108.0862102, 14.325139],
                [108.0864647, 14.3250083],
                [108.0867409, 14.3248636],
                [108.0869589, 14.3247536],
                [108.0871701, 14.3246153],
                [108.0873847, 14.3244911],
                [108.087545, 14.3243803],
                [108.0876692, 14.3242692],
                [108.0877792, 14.3241366],
                [108.0879113, 14.3239691],
                [108.088007, 14.3238365],
                [108.0880662, 14.3237174],
                [108.0881335, 14.323542],
                [108.088212, 14.3233246],
                [108.0882509, 14.3231137],
                [108.0882679, 14.3229166],
                [108.0882702, 14.3227405],
                [108.0882808, 14.32248],
                [108.0882806, 14.3222052],
                [108.088305, 14.3220012],
                [108.0883366, 14.3217902],
                [108.0883611, 14.3215721],
                [108.0883858, 14.3213329],
                [108.0884065, 14.3211288],
                [108.0884022, 14.3208963],
                [108.0883907, 14.3206707],
                [108.0883649, 14.3204308],
                [108.0883242, 14.320233],
                [108.0882372, 14.3199783],
                [108.0881604, 14.3197801],
                [108.0880691, 14.3195816],
                [108.0879779, 14.3193693],
                [108.0878873, 14.3191214],
                [108.0878213, 14.3189164],
                [108.0877734, 14.3187115],
                [108.0877256, 14.3184995],
                [108.087692, 14.3183018],
                [108.0876828, 14.3181819],
                [108.0876852, 14.3179988],
                [108.0876943, 14.3178509],
                [108.0877265, 14.3176968],
                [108.0877278, 14.3176921],
                [108.0877932, 14.3174577],
                [108.0878546, 14.3171765],
                [108.0878943, 14.3168952],
                [108.0879591, 14.3166353],
                [108.0880199, 14.3163965],
                [108.0880732, 14.3161858],
                [108.0881228, 14.3159751],
                [108.0881614, 14.3157782],
                [108.0881781, 14.3156093],
                [108.0881876, 14.3154262],
                [108.0881896, 14.3152783],
                [108.088169, 14.3151935],
                [108.0880907, 14.315101],
                [108.0879693, 14.3150008],
                [108.0877251, 14.3148992],
                [108.0874307, 14.3147758],
                [108.0871111, 14.314638],
                [108.0868747, 14.3145013],
                [108.0866597, 14.3143718],
                [108.0864448, 14.3142353],
                [108.0862374, 14.3140849],
                [108.0860228, 14.3139272],
                [108.0858654, 14.3138196],
                [108.0856504, 14.3136901],
                [108.0853961, 14.313532],
                [108.0851809, 14.3134237],
                [108.0850085, 14.3133582],
                [108.084843, 14.3133209],
                [108.0846843, 14.3133049],
                [108.0844751, 14.3132882],
                [108.0842008, 14.3132778],
                [108.0838902, 14.313295],
                [108.0835536, 14.3133544],
                [108.0831846, 14.3134202],
                [108.0829025, 14.3134591],
                [108.0826348, 14.3135052],
                [108.082338, 14.3135649],
                [108.0820813, 14.3135899],
                [108.0818067, 14.3136147],
                [108.0815826, 14.3136331],
                [108.0813008, 14.3136438],
                [108.0810333, 14.3136686],
                [108.0808528, 14.3136735],
                [108.0809208, 14.3134418],
                [108.0809746, 14.3131888],
                [108.0810431, 14.3129148],
                [108.0810966, 14.3126901],
                [108.0811645, 14.3124584],
                [108.0812885, 14.3120795],
                [108.0813787, 14.3118129],
                [108.0814543, 14.3115461],
                [108.0815299, 14.3112792],
                [108.0815947, 14.3110194],
                [108.081634, 14.3107662],
                [108.0816435, 14.3105973],
                [108.0816312, 14.310428],
                [108.0816036, 14.310336],
                [108.0814245, 14.3102282],
                [108.0811332, 14.31014],
                [108.0808811, 14.3100947],
                [108.0805856, 14.3100488],
                [108.0802686, 14.3099956],
                [108.0799373, 14.3099352],
                [108.0797359, 14.3098692],
                [108.0794883, 14.3097605],
                [108.0793449, 14.3096812],
                [108.0792092, 14.3095668],
                [108.0790739, 14.3094172],
                [108.0788604, 14.309175],
                [108.0786753, 14.3089755],
                [108.0784974, 14.3087761],
                [108.07828, 14.3085549],
                [108.0781227, 14.3084403],
                [108.0779362, 14.3083464],
                [108.0776917, 14.3082659],
                [108.0773106, 14.3081484],
                [108.0769652, 14.3080597],
                [108.0766559, 14.3079713],
                [108.0762353, 14.3078323],
                [108.0759336, 14.3077158],
                [108.0756965, 14.3076213],
                [108.0754307, 14.3075194],
                [108.0751146, 14.3073957],
                [108.0747915, 14.3072579],
                [108.0744004, 14.307077],
                [108.0741852, 14.3069616],
                [108.0739921, 14.3068253],
                [108.073799, 14.306682],
                [108.0736134, 14.3065107],
                [108.0734068, 14.3063038],
                [108.0732361, 14.3061044],
                [108.073169, 14.3059839],
                [108.0730989, 14.3058279],
                [108.0730433, 14.3056653],
                [108.0729882, 14.3054602],
                [108.0729045, 14.3052337],
                [108.0727929, 14.3049294],
                [108.072707, 14.3045972],
                [108.0726464, 14.3042583],
                [108.0726056, 14.3040605],
                [108.0725941, 14.3038349],
                [108.0726152, 14.3035956],
                [108.0726619, 14.3033355],
                [108.0727158, 14.3030826],
                [108.072784, 14.3028298],
                [108.0728598, 14.3025489],
                [108.0729464, 14.3022751],
                [108.0730358, 14.3020649],
                [108.073132, 14.3018759],
                [108.0732429, 14.3016799],
                [108.0734094, 14.301365],
                [108.0734623, 14.3011824],
                [108.0735018, 14.3009222],
                [108.073359, 14.3008007],
                [108.0730507, 14.3006349],
                [108.0726055, 14.3004391],
                [108.0722649, 14.3002658],
                [108.0720212, 14.3001289],
                [108.0717631, 14.2999919],
                [108.07157, 14.2998487],
                [108.0713698, 14.2996912],
                [108.0712272, 14.2995555],
                [108.071028, 14.2993276],
                [108.0707977, 14.2989936],
                [108.0707273, 14.2988519],
                [108.0707217, 14.2987321],
                [108.0707523, 14.2985985],
                [108.070805, 14.2984301],
                [108.0708653, 14.2982266],
                [108.0709665, 14.2979459],
                [108.0710628, 14.2977499],
                [108.071202, 14.2975966],
                [108.0713849, 14.2974156],
                [108.07159, 14.2971857],
                [108.0717767, 14.2969837],
                [108.0719015, 14.2968232],
                [108.0719612, 14.296676],
                [108.0720439, 14.2964162],
                [108.0721345, 14.2961145],
                [108.0722436, 14.2957776],
                [108.0722752, 14.2955666],
                [108.0722931, 14.2952991],
                [108.0722603, 14.2950451],
                [108.0722026, 14.2947625],
                [108.0721546, 14.2945717],
                [108.0720845, 14.2944018],
                [108.0720066, 14.2942951],
                [108.0718781, 14.2941738],
                [108.0717349, 14.2940804],
                [108.071484, 14.2939434],
                [108.0711791, 14.2937918],
                [108.0709786, 14.2936695],
                [108.070728, 14.2935114],
                [108.0704919, 14.2933394],
                [108.0702776, 14.2931677],
                [108.0700847, 14.2930104],
                [108.0698562, 14.2928173],
                [108.0694954, 14.2925239],
                [108.0692882, 14.2923593],
                [108.0690813, 14.2921666],
                [108.0688317, 14.291931],
                [108.0686247, 14.2917453],
                [108.0684145, 14.2915242],
                [108.0682724, 14.2913605],
                [108.0681162, 14.2911541],
                [108.0679743, 14.2909622],
                [108.0678251, 14.2907701],
                [108.0676623, 14.2905216],
                [108.0675817, 14.2903444],
                [108.067533, 14.290196],
                [108.0675273, 14.2900831],
                [108.0675217, 14.2899491],
                [108.067546, 14.2897451],
                [108.0676003, 14.289457],
                [108.0676884, 14.2890635],
                [108.0677567, 14.2888177],
                [108.0678796, 14.2885234],
                [108.0679617, 14.288313],
                [108.0680288, 14.2881518],
                [108.0680596, 14.2879971],
                [108.068011, 14.2878485],
                [108.0678905, 14.287678],
                [108.0676622, 14.2874709],
                [108.0674619, 14.2873274],
                [108.06719, 14.287141],
                [108.0670219, 14.2870262],
                [108.0668935, 14.2869048],
                [108.0667872, 14.2867485],
                [108.0667454, 14.2866283],
                [108.0667327, 14.2864942],
                [108.0667416, 14.2863674],
                [108.0667865, 14.2862483],
                [108.0669191, 14.2860386],
                [108.0671719, 14.2857528],
                [108.067355, 14.2855507],
                [108.0675891, 14.2853211],
                [108.0677936, 14.2851334],
                [108.0680418, 14.2849181],
                [108.0682502, 14.2847163],
                [108.0683603, 14.2845767],
                [108.06842, 14.2844225],
                [108.0684655, 14.284254],
                [108.0684822, 14.284078],
                [108.0684555, 14.2839156],
                [108.0683777, 14.2837879],
                [108.0681686, 14.2834893],
                [108.0679622, 14.2832613],
                [108.0677341, 14.2830331],
                [108.0675272, 14.2828404],
                [108.0672206, 14.2825547],
                [108.0669601, 14.282319],
                [108.0667886, 14.282183],
                [108.0665744, 14.2819973],
                [108.0663533, 14.2817902],
                [108.0661176, 14.281597],
                [108.0659324, 14.2814046],
                [108.0658437, 14.2812837],
                [108.0657945, 14.2811844],
                [108.0657321, 14.2809864],
                [108.0656567, 14.2806755],
                [108.0655605, 14.280308],
                [108.0654538, 14.2798979],
                [108.0653778, 14.2796364],
                [108.0652872, 14.2793886],
                [108.0651476, 14.2790136],
                [108.0649344, 14.2784754],
                [108.0648577, 14.2782772],
                [108.0647881, 14.278072],
                [108.0647331, 14.277853],
                [108.0647039, 14.277606],
                [108.0646634, 14.2773941],
                [108.0646374, 14.2771684],
                [108.0646181, 14.2769849],
                [108.0646142, 14.2767313],
                [108.0646204, 14.2765269],
                [108.0646227, 14.2763509],
                [108.0646115, 14.2761042],
                [108.0644184, 14.2759608],
                [108.0642105, 14.2758526],
                [108.0639805, 14.2757794],
                [108.0637429, 14.2757271],
                [108.0634405, 14.27566],
                [108.0632068, 14.2755936],
                [108.0630847, 14.2755428],
                [108.0629846, 14.2754712],
                [108.0628996, 14.2753504],
                [108.0627795, 14.2751375],
                [108.0626391, 14.2748398],
                [108.0625764, 14.2746559],
                [108.0625504, 14.2744442],
                [108.0625787, 14.274212],
                [108.0626396, 14.2739662],
                [108.0627445, 14.2736645],
                [108.0627977, 14.2734609],
                [108.0628438, 14.2732502],
                [108.0629008, 14.2730324],
                [108.0629388, 14.2728779],
                [108.0629402, 14.2727722],
                [108.0629054, 14.2726732],
                [108.0627985, 14.2725732],
                [108.0626049, 14.2724651],
                [108.0623177, 14.2723418],
                [108.0620595, 14.2722189],
                [108.0617722, 14.2721026],
                [108.0612736, 14.2718639],
                [108.0610154, 14.2717338],
                [108.060779, 14.2715971],
                [108.0605929, 14.2714751],
                [108.0604139, 14.2713601],
                [108.0602352, 14.271224],
                [108.0600999, 14.2710815],
                [108.060004, 14.2709605],
                [108.0598765, 14.2707687],
                [108.0597494, 14.2705557],
                [108.0595938, 14.2703073],
                [108.0595093, 14.2701442],
                [108.0594112, 14.2699175],
                [108.0593527, 14.2696984],
                [108.0593338, 14.2694797],
                [108.0592936, 14.2692397],
                [108.059253, 14.2690278],
                [108.0592132, 14.2687596],
                [108.059171, 14.2683997],
                [108.0591516, 14.2682234],
                [108.0591683, 14.2680475],
                [108.0592002, 14.2678153],
                [108.0592531, 14.2676328],
                [108.0593838, 14.2672963],
                [108.0595027, 14.2670371],
                [108.0596073, 14.2667636],
                [108.0597046, 14.2664971],
                [108.0598419, 14.2662098],
                [108.0599236, 14.2660276],
                [108.0600051, 14.2658737],
                [108.0601591, 14.2656853],
                [108.059979, 14.265655],
                [108.059669, 14.265623],
                [108.0593947, 14.2656266],
                [108.0590045, 14.2656571],
                [108.0586425, 14.265737],
                [108.0583169, 14.2657965],
                [108.0579551, 14.2658624],
                [108.0576331, 14.2659219],
                [108.0572274, 14.2660366],
                [108.0569736, 14.2661251],
                [108.0566542, 14.2662551],
                [108.056393, 14.2663645],
                [108.0561746, 14.2665027],
                [108.055895, 14.2666332],
                [108.0555754, 14.2667912],
                [108.0552555, 14.2669634],
                [108.0550224, 14.2671226],
                [108.054847, 14.2672895],
                [108.0546569, 14.2674774],
                [108.0544885, 14.2676586],
                [108.0543385, 14.2678116],
                [108.0541417, 14.2679642],
                [108.0539225, 14.2681587],
                [108.0537322, 14.2683678],
                [108.053549, 14.2685768],
                [108.0534023, 14.2687511],
                [108.0532153, 14.2689813],
                [108.0530103, 14.2691973],
                [108.0528049, 14.2694554],
                [108.0526434, 14.2696577],
                [108.0524822, 14.2698389],
                [108.052303, 14.2700198],
                [108.0520764, 14.2702425],
                [108.0518502, 14.2704229],
                [108.0516458, 14.2705965],
                [108.0514418, 14.2707419],
                [108.0512015, 14.270908],
                [108.0508846, 14.2711226],
                [108.0506149, 14.2713305],
                [108.0503815, 14.2715108],
                [108.0501412, 14.2716628],
                [108.0498429, 14.2718493],
                [108.0496318, 14.2719806],
                [108.0494533, 14.2721051],
                [108.0492567, 14.2722366],
                [108.0490673, 14.2723752],
                [108.0488633, 14.2725136],
                [108.0487106, 14.2725962],
                [108.0485, 14.2726993],
                [108.0482751, 14.272781],
                [108.048036, 14.2728486],
                [108.0478188, 14.2728951],
                [108.0476779, 14.2729146],
                [108.0474757, 14.2729191],
                [108.0472086, 14.2729157],
                [108.0469634, 14.2728986],
                [108.0467251, 14.2729027],
                [108.0465079, 14.2729493],
                [108.0463124, 14.2729962],
                [108.0461024, 14.27305],
                [108.0459537, 14.2731046],
                [108.0457788, 14.2732221],
                [108.0456544, 14.2733544],
                [108.045507, 14.2735921],
                [108.0453961, 14.273795],
                [108.0452706, 14.2739978],
                [108.0451235, 14.2742074],
                [108.0450242, 14.274354],
                [108.0448844, 14.2745567],
                [108.0447374, 14.2747592],
                [108.0445908, 14.2749265],
                [108.044444, 14.2751149],
                [108.0442897, 14.2753172],
                [108.0440878, 14.2755754],
                [108.0438605, 14.2758473],
                [108.0437064, 14.2760427],
                [108.0436031, 14.2762177],
                [108.0435213, 14.2763997],
                [108.0433955, 14.2766305],
                [108.0432779, 14.2767839],
                [108.0431685, 14.2768742],
                [108.0429797, 14.2769563],
                [108.0427768, 14.2770101],
                [108.0425307, 14.2770563],
                [108.0422631, 14.2770953],
                [108.04201, 14.2771203],
                [108.0417209, 14.2771449],
                [108.0414138, 14.2771634],
                [108.041052, 14.2772153],
                [108.0407408, 14.277282],
                [108.0405308, 14.2773216],
                [108.0402343, 14.2773602],
                [108.0400247, 14.2773717],
                [108.039826, 14.2773832],
                [108.0396965, 14.2773465],
                [108.0395964, 14.2772677],
                [108.0394042, 14.277061],
                [108.0392262, 14.2768684],
                [108.0390122, 14.2766685],
                [108.038827, 14.2764759],
                [108.0385988, 14.2762617],
                [108.0384177, 14.2760269],
                [108.038211, 14.2758199],
                [108.0380115, 14.2756202],
                [108.0378048, 14.2754132],
                [108.0375555, 14.2751494],
                [108.0373883, 14.2749641],
                [108.037253, 14.2748215],
                [108.037103, 14.2746998],
                [108.0369455, 14.2745992],
                [108.0367946, 14.2745339],
                [108.0366218, 14.2744965],
                [108.036355, 14.2744721],
                [108.0360518, 14.2744683],
                [108.0358424, 14.2744657],
                [108.035572, 14.2744412],
                [108.0353054, 14.2744026],
                [108.0350605, 14.2743502],
                [108.0348732, 14.2743197],
                [108.0347222, 14.2742756],
                [108.0345928, 14.2742316],
                [108.0345514, 14.2740902],
                [108.0345116, 14.2738149],
                [108.0345006, 14.273554],
                [108.0344898, 14.273279],
                [108.0344985, 14.2728846],
                [108.0344805, 14.2726095],
                [108.0344546, 14.2723838],
                [108.034422, 14.2721084],
                [108.034395, 14.2716924],
                [108.0343557, 14.2713889],
                [108.0343439, 14.2711843],
                [108.0343041, 14.2709232],
                [108.0342634, 14.2704434],
                [108.0342012, 14.2702242],
                [108.0341103, 14.2699976],
                [108.0339836, 14.2697494],
                [108.0337892, 14.2694298],
                [108.0336692, 14.269224],
                [108.033542, 14.269011],
                [108.0334437, 14.2688054],
                [108.0333312, 14.2685715],
                [108.0331597, 14.2681606],
                [108.0330324, 14.2679548],
                [108.0328909, 14.2677416],
                [108.0327845, 14.2675852],
                [108.0326283, 14.2673858],
                [108.0323972, 14.2671152],
                [108.032176, 14.2669152],
                [108.0320116, 14.2667863],
                [108.0318325, 14.2666854],
                [108.0315813, 14.2665695],
                [108.0312437, 14.2664385],
                [108.0309996, 14.2663298],
                [108.0310198, 14.2661679],
                [108.0310742, 14.2658726],
                [108.0310992, 14.2656193],
                [108.0310941, 14.2654571],
                [108.0310246, 14.2652449],
                [108.0309511, 14.2650819],
                [108.0308163, 14.2648899],
                [108.0306455, 14.2647046],
                [108.0304242, 14.2645116],
                [108.0301812, 14.2643254],
                [108.0299668, 14.2641536],
                [108.0297992, 14.2640035],
                [108.0296135, 14.2638391],
                [108.0293418, 14.2636384],
                [108.0291207, 14.2634313],
                [108.028914, 14.2632314],
                [108.0287217, 14.2630317],
                [108.0285193, 14.2627684],
                [108.0283059, 14.2625332],
                [108.0281061, 14.2623475],
                [108.0279496, 14.2621693],
                [108.0277861, 14.2619771],
                [108.0275762, 14.2617349],
                [108.027384, 14.2615281],
                [108.0272202, 14.2613569],
                [108.0270568, 14.2611576],
                [108.0268726, 14.2608946],
                [108.0267703, 14.260703],
                [108.0266433, 14.260483],
                [108.0265449, 14.2602775],
                [108.0263969, 14.2600007],
                [108.0262559, 14.2597453],
                [108.0261542, 14.2595186],
                [108.0260632, 14.259299],
                [108.025966, 14.2590017],
                [108.0258746, 14.2588174],
                [108.02574, 14.2586184],
                [108.0255252, 14.2582141],
                [108.025334, 14.2579229],
                [108.0251997, 14.2577026],
                [108.0250083, 14.2574324],
                [108.0248412, 14.2572402],
                [108.0246838, 14.2571324],
                [108.0244614, 14.257024],
                [108.024239, 14.2569085],
                [108.0241389, 14.2568437],
                [108.0240539, 14.2567229],
                [108.024034, 14.2565818],
                [108.0240498, 14.2564834],
                [108.0241235, 14.2563715],
                [108.0243906, 14.2560859],
                [108.0247264, 14.2558083],
                [108.0249239, 14.2556204],
                [108.0250559, 14.2554529],
                [108.025167, 14.255243],
                [108.0252901, 14.2549415],
                [108.0253878, 14.2546537],
                [108.0254191, 14.2544569],
                [108.0254448, 14.2541471],
                [108.0254163, 14.2538508],
                [108.0253755, 14.25366],
                [108.0252777, 14.2534121],
                [108.0251647, 14.2532205],
                [108.0250662, 14.2530219],
                [108.0250144, 14.2528451],
                [108.0249879, 14.2526616],
                [108.0249403, 14.2524426],
                [108.0248563, 14.2522371],
                [108.0247432, 14.2520595],
                [108.0245721, 14.2518883],
                [108.0242972, 14.2516524],
                [108.0240684, 14.2514874],
                [108.0238607, 14.251358],
                [108.0236311, 14.2512494],
                [108.0233511, 14.2511332],
                [108.0231071, 14.2510244],
                [108.0228415, 14.2509084],
                [108.0225652, 14.2507781],
                [108.0222563, 14.2506615],
                [108.0220047, 14.2505808],
                [108.0217745, 14.2505216],
                [108.0215154, 14.250455],
                [108.0212925, 14.2503887],
                [108.0210625, 14.2503083],
                [108.020829, 14.2502209],
                [108.0206133, 14.2501547],
                [108.0203973, 14.2501167],
                [108.0202384, 14.2501148],
                [108.0200436, 14.2501124],
                [108.0197977, 14.2501446],
                [108.0195729, 14.2502193],
                [108.0193189, 14.2503218],
                [108.0191121, 14.2503967],
                [108.018873, 14.2504642],
                [108.0185395, 14.2505727],
                [108.018293, 14.2506541],
                [108.0180973, 14.2507082],
                [108.0178656, 14.2507616],
                [108.0176847, 14.2507946],
                [108.0174355, 14.2508056],
                [108.017255, 14.2508033],
                [108.0170747, 14.2507869],
                [108.0169898, 14.250659],
                [108.0168134, 14.2503468],
                [108.0167576, 14.2501981],
                [108.016674, 14.2499716],
                [108.0166259, 14.2497807],
                [108.0165996, 14.2495831],
                [108.01662, 14.2494071],
                [108.0166735, 14.2491823],
                [108.0167486, 14.2489648],
                [108.0168096, 14.2487119],
                [108.0168413, 14.248501],
                [108.0168924, 14.2481844],
                [108.0169173, 14.2479381],
                [108.0169504, 14.2476215],
                [108.0169612, 14.2473467],
                [108.0170071, 14.2468752],
                [108.0170131, 14.2464101],
                [108.017009, 14.2461775],
                [108.0169956, 14.2458251],
                [108.0169844, 14.2455783],
                [108.0169663, 14.2453104],
                [108.0169553, 14.2450425],
                [108.0169107, 14.2448727],
                [108.0167899, 14.2447303],
                [108.0165611, 14.2445512],
                [108.0163036, 14.2443719],
                [108.0160033, 14.2441497],
                [108.0157531, 14.2439703],
                [108.0156137, 14.2438559],
                [108.0154927, 14.2437276],
                [108.0153865, 14.2435712],
                [108.0153163, 14.2434152],
                [108.0152759, 14.2431963],
                [108.0152637, 14.2430271],
                [108.0152455, 14.242766],
                [108.015226, 14.2423289],
                [108.0151935, 14.2420538],
                [108.0151746, 14.2418421],
                [108.0151636, 14.2415812],
                [108.015178, 14.2413136],
                [108.0151891, 14.2410107],
                [108.0151703, 14.240799],
                [108.0151367, 14.2406084],
                [108.0150012, 14.2404728],
                [108.0148688, 14.2403937],
                [108.0146534, 14.2403065],
                [108.0142645, 14.240231],
                [108.0140124, 14.2401928],
                [108.0137527, 14.2401753],
                [108.0134785, 14.2401648],
                [108.0132476, 14.240162],
                [108.0128829, 14.2401715],
                [108.0126664, 14.2401688],
                [108.0123995, 14.2401514],
                [108.0121542, 14.2401342],
                [108.0119237, 14.2401031],
                [108.0116858, 14.240072],
                [108.0113978, 14.240019],
                [108.011077, 14.2399868],
                [108.0107665, 14.23999],
                [108.0104633, 14.2399862],
                [108.0101239, 14.2399961],
                [108.0098638, 14.2400139],
                [108.0096258, 14.2399968],
                [108.009276, 14.2399713],
                [108.0089587, 14.2399462],
                [108.0086199, 14.2399067],
                [108.0082019, 14.2398521],
                [108.0079208, 14.2398204],
                [108.007672, 14.2397961],
                [108.007392, 14.2397683],
                [108.0073477, 14.2397639],
                [108.0071725, 14.2399097],
                [108.0069388, 14.2401111],
                [108.006712, 14.2403338],
                [108.0065507, 14.2405291],
                [108.0064036, 14.2407316],
                [108.006216, 14.240997],
                [108.0060327, 14.2412131],
                [108.0058276, 14.2414432],
                [108.0056079, 14.2416728],
                [108.0053884, 14.2418956],
                [108.0052123, 14.2421118],
                [108.0050469, 14.2423424],
                [108.0048637, 14.2425444],
                [108.0046734, 14.2427463],
                [108.0044543, 14.2429338],
                [108.0042428, 14.2430932],
                [108.0040388, 14.2432387],
                [108.0037731, 14.2434045],
                [108.0035036, 14.2435843],
                [108.0032342, 14.2437641],
                [108.002979, 14.2439512],
                [108.0027749, 14.2440966],
                [108.0025485, 14.244291],
                [108.0024056, 14.2444583],
                [108.0022076, 14.2446885],
                [108.0020239, 14.2449328],
                [108.0019066, 14.2450722],
                [108.0017969, 14.2451765],
                [108.0014768, 14.2452834],
                [108.0010641, 14.2452563],
                [108.0003804, 14.2449769],
                [108.0002535, 14.2449542],
                [107.9997382, 14.2448391],
                [107.9989766, 14.2445447],
                [107.9980613, 14.2442766],
                [107.9975593, 14.2441978],
                [107.9970969, 14.2441914],
                [107.9966734, 14.2442089],
                [107.996327, 14.2442376],
                [107.9959052, 14.2443888],
                [107.9955475, 14.2445392],
                [107.9951262, 14.2447268],
                [107.9946783, 14.2448419],
                [107.9941657, 14.2449335],
                [107.993333, 14.2450898],
                [107.9928206, 14.2451813],
                [107.9924118, 14.2453446],
                [107.9919912, 14.245593],
                [107.9916908, 14.2456304],
                [107.9907321, 14.245582],
                [107.989521, 14.2454891],
                [107.98908, 14.2454141],
                [107.9886969, 14.2454189],
                [107.9882433, 14.2454648],
                [107.9878318, 14.24547],
                [107.9873917, 14.245462],
                [107.986908, 14.2453742],
                [107.9862823, 14.245288],
                [107.9856849, 14.2451882],
                [107.9850445, 14.2450619],
                [107.9843762, 14.2449628],
                [107.983907, 14.2449016],
                [107.9831759, 14.2448546],
                [107.98273, 14.2449408],
                [107.9823257, 14.2449458],
                [107.9821126, 14.2449284],
                [107.9818774, 14.2448507],
                [107.9816412, 14.2446925],
                [107.981383, 14.244474],
                [107.9808212, 14.2438364],
                [107.9802588, 14.2431484],
                [107.9797388, 14.2424496],
                [107.9792204, 14.2418718],
                [107.9789392, 14.2415328],
                [107.977946, 14.2404572],
                [107.9776433, 14.2400984],
                [107.9769755, 14.2394922],
                [107.9765018, 14.2390751],
                [107.9759437, 14.2387193],
                [107.9754296, 14.2384638],
                [107.9744016, 14.237973],
                [107.9737164, 14.2376591],
                [107.9729018, 14.237226],
                [107.9720651, 14.2367126],
                [107.9712706, 14.2361885],
                [107.970584, 14.2357539],
                [107.9700019, 14.2351969],
                [107.9696357, 14.234859],
                [107.9692891, 14.2343999],
                [107.968703, 14.2335207],
                [107.9679886, 14.2325927],
                [107.9675127, 14.2320144],
                [107.9670146, 14.2313556],
                [107.9665388, 14.2307773],
                [107.9657426, 14.2301022],
                [107.9647344, 14.2295103],
                [107.964134, 14.2291651],
                [107.9632773, 14.2287526],
                [107.9626786, 14.2285384],
                [107.9622086, 14.2284032],
                [107.9616104, 14.2282293],
                [107.9610781, 14.2282157],
                [107.96061, 14.2282215],
                [107.9601219, 14.2283284],
                [107.9595712, 14.2285366],
                [107.9592328, 14.228702],
                [107.9587494, 14.2291715],
                [107.9583937, 14.2296393],
                [107.957827, 14.2302608],
                [107.9573248, 14.2309117],
                [107.9568224, 14.2315627],
                [107.9564052, 14.2322126],
                [107.9560512, 14.2328214],
                [107.9554053, 14.2338872],
                [107.9551792, 14.2344945],
                [107.9550995, 14.2349185],
                [107.9551042, 14.2352811],
                [107.9550659, 14.235604],
                [107.9548378, 14.2360702],
                [107.9545456, 14.2365171],
                [107.9537689, 14.2373427],
                [107.9527815, 14.2383422],
                [107.9520104, 14.2390603],
                [107.9515914, 14.2395759],
                [107.9511864, 14.2400779],
                [107.9505207, 14.2412615],
                [107.9502432, 14.2417484],
                [107.9499246, 14.2423434],
                [107.9496765, 14.2429106],
                [107.9493994, 14.2434244],
                [107.9490363, 14.2438722],
                [107.948756, 14.2441442],
                [107.948531, 14.2442948],
                [107.9484415, 14.2442959],
                [107.9483181, 14.2442974],
                [107.9481759, 14.2442723],
                [107.9479467, 14.2441005],
                [107.9475449, 14.2437563],
                [107.9471135, 14.2433231],
                [107.9466221, 14.2426313],
                [107.9463026, 14.2420715],
                [107.9456977, 14.2413811],
                [107.9449519, 14.2407729],
                [107.9443207, 14.2402438],
                [107.9436345, 14.2398497],
                [107.9429219, 14.2396168],
                [107.9421491, 14.239464],
                [107.9414805, 14.2394235],
                [107.940928, 14.2394182],
                [107.9402724, 14.2393897],
                [107.9395912, 14.2393616],
                [107.9384023, 14.2393251],
                [107.937606, 14.2393471],
                [107.9370797, 14.2393779],
                [107.9364515, 14.2394829],
                [107.9359133, 14.2395867],
                [107.9353628, 14.2397393],
                [107.9348504, 14.2398549],
                [107.9343385, 14.240007],
                [107.9337116, 14.2402092],
                [107.9329571, 14.2404858],
                [107.9324717, 14.2406984],
                [107.9322268, 14.2407499],
                [107.9321768, 14.2408851],
                [107.9321145, 14.2410694],
                [107.9319758, 14.2413281],
                [107.9317726, 14.241612],
                [107.9315896, 14.2418396],
                [107.9314547, 14.2420075],
                [107.931169, 14.2424],
                [107.9309786, 14.2426712],
                [107.9307116, 14.2430167],
                [107.9304699, 14.2433132],
                [107.9302023, 14.2435975],
                [107.9297936, 14.2439815],
                [107.9295134, 14.2443028],
                [107.9292842, 14.2445623],
                [107.9290808, 14.2448215],
                [107.9287624, 14.2451922],
                [107.9283412, 14.2456007],
                [107.9282014, 14.2457858],
                [107.927999, 14.2461183],
                [107.9278624, 14.2465601],
                [107.9277861, 14.2469032],
                [107.9277433, 14.2475828],
                [107.9276772, 14.2479601],
                [107.9275677, 14.2482843],
                [107.9274156, 14.2486089],
                [107.9272209, 14.2489341],
                [107.926997, 14.2491924],
                [107.9266812, 14.2494922],
                [107.9261763, 14.249996],
                [107.9256716, 14.2505133],
                [107.9252093, 14.2510166],
                [107.9248443, 14.2513572],
                [107.9244449, 14.2517791],
                [107.9240939, 14.2521061],
                [107.9238135, 14.252392],
                [107.9235334, 14.2527048],
                [107.9232826, 14.2530845],
                [107.9230181, 14.2535047],
                [107.9228525, 14.2538832],
                [107.9226667, 14.2543428],
                [107.922517, 14.2548558],
                [107.922394, 14.2552338],
                [107.9222007, 14.2556666],
                [107.921971, 14.2560325],
                [107.9216913, 14.2563722],
                [107.9213266, 14.2567398],
                [107.9210458, 14.2569854],
                [107.9206535, 14.2574204],
                [107.9202471, 14.2578558],
                [107.9200223, 14.2580469],
                [107.9193986, 14.2598275],
                [107.9189496, 14.2604823],
                [107.9184302, 14.2609866],
                [107.917932, 14.2613606],
                [107.9174097, 14.2616484],
                [107.9166148, 14.2620802],
                [107.9157046, 14.2624377],
                [107.9148173, 14.2627948],
                [107.9139982, 14.2631078],
                [107.9133166, 14.2634625],
                [107.9124762, 14.2639057],
                [107.9114564, 14.2646325],
                [107.910686, 14.265183],
                [107.9100531, 14.2657644],
                [107.909511, 14.2662905],
                [107.9090598, 14.2667722],
                [107.908564, 14.267341],
                [107.9080013, 14.2680405],
                [107.906807, 14.2694296],
                [107.9060856, 14.2702393],
                [107.9052961, 14.271093],
                [107.9044614, 14.2719907],
                [107.9031074, 14.2734033],
                [107.9020219, 14.2743689],
                [107.9010275, 14.2752902],
                [107.9002148, 14.2761226],
                [107.8993562, 14.276934],
                [107.8986303, 14.2773974],
                [107.8978556, 14.2776234],
                [107.8968292, 14.2778414],
                [107.8957134, 14.278223],
                [107.8949859, 14.2785566],
                [107.894031, 14.2790011],
                [107.8932119, 14.2793356],
                [107.8922088, 14.279586],
                [107.891433, 14.2797252],
                [107.8905422, 14.2798118],
                [107.8893536, 14.2798696],
                [107.8882565, 14.2799262],
                [107.8870007, 14.2800929],
                [107.8856097, 14.2804128],
                [107.8837179, 14.2809228],
                [107.8815755, 14.2815115],
                [107.8798893, 14.2820082],
                [107.878544, 14.2823275],
                [107.8772197, 14.282495],
                [107.8765982, 14.2827293],
                [107.8763824, 14.2825612],
                [107.8763239, 14.2819139],
                [107.8762899, 14.2813237],
                [107.8761704, 14.2809142],
                [107.875912, 14.2803765],
                [107.8757007, 14.2799467],
                [107.8753756, 14.2795613],
                [107.8749585, 14.2791336],
                [107.8744496, 14.2786856],
                [107.8739854, 14.2781505],
                [107.8736815, 14.2776351],
                [107.8735168, 14.2772694],
                [107.8733741, 14.2768386],
                [107.87316, 14.2761923],
                [107.8729725, 14.2758268],
                [107.8726961, 14.2756787],
                [107.8724672, 14.2756599],
                [107.8720795, 14.2757511],
                [107.8716013, 14.2759083],
                [107.8707136, 14.2762434],
                [107.8697341, 14.276558],
                [107.8688456, 14.2768283],
                [107.8681623, 14.2770528],
                [107.8677507, 14.2770577],
                [107.8674762, 14.2770611],
                [107.8671558, 14.2769771],
                [107.8666381, 14.2767238],
                [107.8660498, 14.2763092],
                [107.8644583, 14.2752256],
                [107.863249, 14.2745265],
                [107.8631238, 14.2744604],
                [107.8616262, 14.2736702],
                [107.8607296, 14.2732918],
                [107.8600764, 14.2731698],
                [107.8594943, 14.2732418],
                [107.8587778, 14.2735422],
                [107.8578576, 14.2740075],
                [107.85697, 14.2743425],
                [107.8558764, 14.2746475],
                [107.8543707, 14.274925],
                [107.8525919, 14.2753032],
                [107.8512573, 14.2755462],
                [107.849996, 14.2758487],
                [107.8497349, 14.2747135],
                [107.849538, 14.2737818],
                [107.8492855, 14.2733372],
                [107.8490026, 14.2729317],
                [107.8486903, 14.2726433],
                [107.8482561, 14.2724732],
                [107.8476367, 14.2723053],
                [107.8466466, 14.2721127],
                [107.845564, 14.2719211],
                [107.844575, 14.2718161],
                [107.8433469, 14.2716574],
                [107.8427484, 14.2715283],
                [107.8418602, 14.2712664],
                [107.8409501, 14.2709074],
                [107.840163, 14.2705081],
                [107.8392096, 14.2699744],
                [107.8384424, 14.2695165],
                [107.8377579, 14.2690899],
                [107.8373211, 14.2687249],
                [107.8369047, 14.2683403],
                [107.8365272, 14.26778],
                [107.83617, 14.2671998],
                [107.835896, 14.2666772],
                [107.8356839, 14.2661732],
                [107.8354275, 14.2654263],
                [107.835276, 14.2648243],
                [107.8352091, 14.2644161],
                [107.8352608, 14.2636168],
                [107.8353327, 14.2627783],
                [107.8353914, 14.2625217],
                [107.8356254, 14.261499],
                [107.8357396, 14.2607574],
                [107.8359155, 14.2600151],
                [107.8361733, 14.2592328],
                [107.8365205, 14.257377],
                [107.8368356, 14.2548092],
                [107.8372227, 14.252438],
                [107.83747, 14.2500026],
                [107.8377083, 14.2468441],
                [107.8377556, 14.2450685],
                [107.837931, 14.2424368],
                [107.838217, 14.2403132],
                [107.838294, 14.2381428],
                [107.8382719, 14.236368],
                [107.8378331, 14.2345982],
                [107.8374041, 14.2336172],
                [107.8369632, 14.2331086],
                [107.8362772, 14.2325619],
                [107.8356555, 14.2322189],
                [107.8349727, 14.231935],
                [107.8344774, 14.2318241],
                [107.8339831, 14.2318007],
                [107.8334892, 14.2318066],
                [107.832967, 14.2320172],
                [107.8321369, 14.2322899],
                [107.8313379, 14.2325913],
                [107.8304156, 14.2328944],
                [107.8299232, 14.233017],
                [107.8298198, 14.2330603],
                [107.8298138, 14.2330737],
                [107.8295059, 14.2332028],
                [107.8291051, 14.233266],
                [107.8288269, 14.2332401],
                [107.828547, 14.2330973],
                [107.8283283, 14.2328954],
                [107.8281069, 14.2324793],
                [107.8279372, 14.2320723],
                [107.8277052, 14.2316271],
                [107.8275542, 14.2310836],
                [107.8274663, 14.2306367],
                [107.8274458, 14.2302799],
                [107.8274331, 14.2299944],
                [107.8273838, 14.2297093],
                [107.8272888, 14.2294247],
                [107.8271658, 14.2290973],
                [107.8270712, 14.2288561],
                [107.8269769, 14.2286234],
                [107.8268636, 14.2283448],
                [107.8267505, 14.2280862],
                [107.8266466, 14.227819],
                [107.8265798, 14.2276032],
                [107.8265042, 14.2274135],
                [107.8263917, 14.2271983],
                [107.8263167, 14.2270519],
                [107.8261962, 14.2269277],
                [107.8260023, 14.2267827],
                [107.8257629, 14.2266643],
                [107.8254685, 14.2265465],
                [107.8252659, 14.2264449],
                [107.8251091, 14.2263428],
                [107.8249428, 14.2262062],
                [107.824757, 14.2259831],
                [107.8245617, 14.2257429],
                [107.8243108, 14.225434],
                [107.824097, 14.2251637],
                [107.8239017, 14.2249147],
                [107.8237066, 14.2246745],
                [107.8235764, 14.2245115],
                [107.8234468, 14.2243917],
                [107.8232902, 14.2243069],
                [107.823171, 14.2242823],
                [107.8230061, 14.2242669],
                [107.8229238, 14.2242766],
                [107.822815, 14.2243559],
                [107.8226522, 14.2245137],
                [107.8225258, 14.2246538],
                [107.822372, 14.2247855],
                [107.8221903, 14.2248916],
                [107.8219531, 14.2249594],
                [107.821789, 14.2249961],
                [107.8216333, 14.2249979],
                [107.821505, 14.2249734],
                [107.8213759, 14.2248969],
                [107.8212369, 14.2247599],
                [107.8211159, 14.2245967],
                [107.8209474, 14.2242956],
                [107.8208149, 14.223942],
                [107.8207194, 14.223614],
                [107.8206238, 14.2232858],
                [107.8205192, 14.2229709],
                [107.8203315, 14.222592],
                [107.8201724, 14.222308],
                [107.81994, 14.2220161],
                [107.8198005, 14.2218359],
                [107.8197159, 14.221655],
                [107.8196769, 14.2214605],
                [107.819674, 14.2212267],
                [107.8197082, 14.2210357],
                [107.8197967, 14.220792],
                [107.8199402, 14.2205478],
                [107.8201842, 14.2203024],
                [107.8204014, 14.2201093],
                [107.8205636, 14.2199124],
                [107.8206795, 14.2196598],
                [107.8207503, 14.2194684],
                [107.8207934, 14.2192513],
                [107.820818, 14.2190172],
                [107.8208237, 14.2187399],
                [107.8208202, 14.2184627],
                [107.8206987, 14.2180775],
                [107.820425, 14.2175935],
                [107.8199869, 14.2171308],
                [107.8195697, 14.2166875],
                [107.8192764, 14.2162816],
                [107.8189819, 14.2157784],
                [107.8186664, 14.2152363],
                [107.8183106, 14.2147726],
                [107.8179149, 14.214407],
                [107.8176021, 14.2140892],
                [107.8172694, 14.2138201],
                [107.8170003, 14.2137064],
                [107.8167325, 14.21369],
                [107.8164854, 14.213693],
                [107.8161781, 14.2138135],
                [107.8157896, 14.2140325],
                [107.8153395, 14.2142522],
                [107.815011, 14.2143341],
                [107.8147845, 14.2143367],
                [107.8145167, 14.2143204],
                [107.8142479, 14.214226],
                [107.8139565, 14.2139762],
                [107.8137071, 14.2137841],
                [107.8134574, 14.2135727],
                [107.8132487, 14.2133413],
                [107.8132065, 14.2132637],
                [107.8130205, 14.2131672],
                [107.8126903, 14.2131128],
                [107.8122366, 14.2130596],
                [107.8117616, 14.2129483],
                [107.8114106, 14.2128745],
                [107.810977, 14.2127823],
                [107.8106679, 14.2127663],
                [107.8103181, 14.2127899],
                [107.8099269, 14.2127945],
                [107.8096384, 14.2127785],
                [107.8093078, 14.2126849],
                [107.8089156, 14.2126116],
                [107.8085026, 14.2125191],
                [107.8080907, 14.2125239],
                [107.8075348, 14.2125304],
                [107.8071025, 14.2125355],
                [107.8069514, 14.2125373],
                [107.8067874, 14.2125912],
                [107.8064597, 14.2127379],
                [107.8062282, 14.2128965],
                [107.8059574, 14.2131983],
                [107.805647, 14.2136306],
                [107.805364, 14.2140625],
                [107.8050941, 14.2144422],
                [107.8047138, 14.2147844],
                [107.8042381, 14.2151666],
                [107.8038713, 14.2154826],
                [107.8035727, 14.2157548],
                [107.8033008, 14.215979],
                [107.8030293, 14.2162291],
                [107.8026759, 14.2165192],
                [107.8021875, 14.2169928],
                [107.8019293, 14.2172039],
                [107.8016166, 14.2174545],
                [107.8013309, 14.2176658],
                [107.8010591, 14.2178899],
                [107.8007744, 14.2181858],
                [107.8004902, 14.2185139],
                [107.8002333, 14.218842],
                [107.8000438, 14.2190651],
                [107.7996772, 14.2193943],
                [107.7994054, 14.2196184],
                [107.7990788, 14.2198562],
                [107.7988601, 14.2199368],
                [107.7986954, 14.2199388],
                [107.7985436, 14.2198755],
                [107.7983635, 14.2197346],
                [107.798142, 14.2195942],
                [107.7979353, 14.2195316],
                [107.7976748, 14.2195608],
                [107.7973192, 14.2196689],
                [107.7969508, 14.2198617],
                [107.7966927, 14.2200857],
                [107.7963671, 14.2204014],
                [107.7960817, 14.2206387],
                [107.7957681, 14.2208113],
                [107.7954953, 14.2209575],
                [107.7951817, 14.2211431],
                [107.7949778, 14.2213015],
                [107.7947034, 14.2215656],
                [107.7944723, 14.2217632],
                [107.7942815, 14.2218823],
                [107.7941449, 14.2219358],
                [107.7939943, 14.2219766],
                [107.7937475, 14.2220055],
                [107.7935278, 14.222008],
                [107.7932795, 14.22192],
                [107.7929338, 14.2217162],
                [107.7925595, 14.2214348],
                [107.7921028, 14.2211544],
                [107.7917716, 14.2210111],
                [107.7913587, 14.2209378],
                [107.7908909, 14.2208653],
                [107.790424, 14.2208708],
                [107.789999, 14.2209278],
                [107.7895741, 14.2209978],
                [107.7892044, 14.22108],
                [107.788849, 14.2212141],
                [107.7885348, 14.2213479],
                [107.7881806, 14.2215861],
                [107.7880319, 14.2217763],
                [107.7878314, 14.2222206],
                [107.7876868, 14.2227423],
                [107.787515, 14.2232904],
                [107.7873136, 14.2236697],
                [107.7870432, 14.2240109],
                [107.7866221, 14.2243863],
                [107.7862417, 14.2247287],
                [107.78571, 14.2250469],
                [107.7852053, 14.2253258],
                [107.7846992, 14.2254878],
                [107.7842469, 14.225558],
                [107.7839177, 14.2255878],
                [107.783533, 14.2255793],
                [107.7833399, 14.2255036],
                [107.782952, 14.2252286],
                [107.7826312, 14.2248293],
                [107.7822684, 14.2243525],
                [107.7820292, 14.2238873],
                [107.7818454, 14.2234604],
                [107.781591, 14.2228654],
                [107.7812525, 14.2221349],
                [107.7810679, 14.221643],
                [107.7808831, 14.2211251],
                [107.7807681, 14.2207104],
                [107.7806104, 14.2201663],
                [107.7803278, 14.219513],
                [107.780102, 14.2190216],
                [107.7799191, 14.2186598],
                [107.7797524, 14.2185057],
                [107.7795174, 14.2183784],
                [107.7792972, 14.2183419],
                [107.7790226, 14.2183451],
                [107.7788167, 14.2183476],
                [107.7786371, 14.2182587],
                [107.7785527, 14.2181036],
                [107.7785225, 14.21787],
                [107.778517, 14.2174215],
                [107.7785127, 14.2170706],
                [107.7784539, 14.2167593],
                [107.7783547, 14.2165004],
                [107.7782286, 14.2162939],
                [107.7779923, 14.2160626],
                [107.7777709, 14.2159222],
                [107.7775781, 14.2158854],
                [107.777331, 14.2158882],
                [107.7770853, 14.2160081],
                [107.7768416, 14.216297],
                [107.7764495, 14.2167956],
                [107.7760567, 14.2172421],
                [107.7756227, 14.2176892],
                [107.7751739, 14.2180454],
                [107.7746156, 14.218429],
                [107.7742062, 14.2186482],
                [107.7738231, 14.2187696],
                [107.7734668, 14.2188257],
                [107.7731647, 14.2188292],
                [107.7729168, 14.2187671],
                [107.7726957, 14.2186527],
                [107.7725697, 14.2184592],
                [107.7724704, 14.2181874],
                [107.7723704, 14.2178764],
                [107.7722143, 14.2174623],
                [107.7720864, 14.2171127],
                [107.7719597, 14.2168541],
                [107.7717922, 14.2166352],
                [107.771529, 14.2164432],
                [107.7711682, 14.2161354],
                [107.7708004, 14.2158124],
                [107.7705218, 14.2154908],
                [107.7701737, 14.2151051],
                [107.7698417, 14.2149141],
                [107.7694959, 14.2147232],
                [107.7689855, 14.2145328],
                [107.7684856, 14.2144992],
                [107.7678889, 14.214506],
                [107.7670805, 14.2144252],
                [107.7666529, 14.2142742],
                [107.7660366, 14.213963],
                [107.7654773, 14.2136151],
                [107.7647798, 14.213188],
                [107.7642838, 14.2130637],
                [107.7639124, 14.213016],
                [107.7636509, 14.2129671],
                [107.7633479, 14.2129055],
                [107.7631957, 14.2128163],
                [107.7630293, 14.2126881],
                [107.7628067, 14.2124436],
                [107.762708, 14.2122367],
                [107.7626486, 14.2118733],
                [107.7626288, 14.2113729],
                [107.7626506, 14.2109045],
                [107.7626648, 14.2109079],
                [107.7626991, 14.210578],
                [107.7628152, 14.2100906],
                [107.7628947, 14.2096768],
                [107.7630303, 14.209249],
                [107.7630951, 14.2087955],
                [107.7631327, 14.2084088],
                [107.7631707, 14.208062],
                [107.7631924, 14.2075291],
                [107.7631668, 14.2059972],
                [107.7631638, 14.2051672],
                [107.7631595, 14.2048076],
                [107.7631119, 14.2043684],
                [107.7630224, 14.2039565],
                [107.7628913, 14.2035984],
                [107.7627185, 14.2032807],
                [107.7624334, 14.2029909],
                [107.762007, 14.2026628],
                [107.7615675, 14.202428],
                [107.7611151, 14.2022824],
                [107.7607201, 14.2022203],
                [107.7602263, 14.202146],
                [107.7598179, 14.2021374],
                [107.7592828, 14.2021436],
                [107.7586502, 14.2022309],
                [107.7583705, 14.2023272],
                [107.7579786, 14.2025316],
                [107.7575586, 14.2027496],
                [107.7569975, 14.2029293],
                [107.7564076, 14.2030694],
                [107.7558037, 14.2032095],
                [107.7550875, 14.203391],
                [107.754344, 14.2036394],
                [107.7538257, 14.2038719],
                [107.7531392, 14.2041816],
                [107.7524955, 14.2045225],
                [107.7519359, 14.2048355],
                [107.7513059, 14.2051495],
                [107.7506058, 14.205491],
                [107.7503761, 14.2055708],
                [107.7498387, 14.2060181],
                [107.7493781, 14.2064157],
                [107.748691, 14.2068035],
                [107.7479781, 14.2071304],
                [107.7476276, 14.2072693],
                [107.7468879, 14.2074739],
                [107.7460854, 14.2076669],
                [107.7452699, 14.2078357],
                [107.7447048, 14.2079218],
                [107.7441015, 14.2079533],
                [107.7433723, 14.2079739],
                [107.7426177, 14.2079825],
                [107.7419888, 14.2079775],
                [107.7413601, 14.207997],
                [107.7407827, 14.2080894],
                [107.7404569, 14.2081973],
                [107.7398815, 14.2084613],
                [107.7392813, 14.2087501],
                [107.7388565, 14.2089879],
                [107.7382557, 14.2092338],
                [107.7374308, 14.2096599],
                [107.7367694, 14.2100966],
                [107.7362832, 14.2104576],
                [107.7356854, 14.2109547],
                [107.7352611, 14.2112292],
                [107.7349608, 14.2113552],
                [107.7345471, 14.2114765],
                [107.7339839, 14.2117035],
                [107.7334842, 14.2119912],
                [107.7329854, 14.2123522],
                [107.7324622, 14.2127811],
                [107.7319633, 14.21313],
                [107.7314883, 14.2133683],
                [107.731, 14.2135578],
                [107.7306863, 14.2136227],
                [107.730058, 14.2136788],
                [107.7293424, 14.213779],
                [107.7285892, 14.2139101],
                [107.7281752, 14.2140007],
                [107.7277994, 14.2141276],
                [107.7273487, 14.2143043],
                [107.7268237, 14.2145799],
                [107.7262355, 14.2148256],
                [107.7258472, 14.2149527],
                [107.7213652, 14.2150484],
                [107.7174386, 14.2135819],
                [107.7142477, 14.2137119],
                [107.7136836, 14.2138654],
                [107.7128445, 14.2141813],
                [107.7120196, 14.2146196],
                [107.7101472, 14.2158176],
                [107.7096113, 14.2162405],
                [107.7091484, 14.216448],
                [107.7084719, 14.2166763],
                [107.7077321, 14.2168686],
                [107.7072046, 14.2169419],
                [107.7062982, 14.2168787],
                [107.7041276, 14.2162842],
                [107.7033065, 14.215987],
                [107.7023599, 14.2157159],
                [107.7014014, 14.2155061],
                [107.7005076, 14.2154364],
                [107.6997155, 14.21547],
                [107.6991257, 14.215587],
                [107.69726, 14.2163005],
                [107.6962609, 14.2169125],
                [107.6948387, 14.2178968],
                [107.6942779, 14.2183384],
                [107.693345, 14.2192313],
                [107.692685, 14.2197905],
                [107.6913364, 14.220633],
                [107.6906858, 14.2209223],
                [107.6895569, 14.2211924],
                [107.688978, 14.2211744],
                [107.6884483, 14.2210455],
                [107.6881843, 14.220881],
                [107.6881038, 14.2206449],
                [107.6880607, 14.2201857],
                [107.6881215, 14.218947],
                [107.6880617, 14.2181448],
                [107.6874084, 14.2160868],
                [107.6872478, 14.2152673],
                [107.6870376, 14.2145098],
                [107.6855583, 14.2117315],
                [107.6848267, 14.2104895],
                [107.6845275, 14.2096531],
                [107.6842128, 14.2085658],
                [107.6837706, 14.2062724],
                [107.6836281, 14.2059186],
                [107.6833873, 14.205762],
                [107.6828577, 14.2056575],
                [107.6823289, 14.2056145],
                [107.6819631, 14.2055205],
                [107.6817475, 14.2053758],
                [107.6815678, 14.2050653],
                [107.6814739, 14.2045637],
                [107.6811912, 14.2040644],
                [107.6805016, 14.2031773],
                [107.6800946, 14.2027957],
                [107.6794441, 14.2020308],
                [107.6789183, 14.2011664],
                [107.6785832, 14.2004776],
                [107.6783476, 14.1996957],
                [107.6782658, 14.1991572],
                [107.6782552, 14.1982565],
                [107.6782975, 14.1975818],
                [107.6785005, 14.1966602],
                [107.6789104, 14.195154],
                [107.6790745, 14.1941347],
                [107.6791643, 14.1932084],
                [107.6791552, 14.1924361],
                [107.6790579, 14.1916528],
                [107.678884, 14.190766],
                [107.6783769, 14.189362],
                [107.677333, 14.1872225],
                [107.6760596, 14.1858822],
                [107.6742731, 14.1836897],
                [107.673861, 14.1828607],
                [107.6730019, 14.1814667],
                [107.6722265, 14.1796857],
                [107.6720295, 14.1789831],
                [107.671694, 14.1782515],
                [107.6709999, 14.1769783],
                [107.6706155, 14.1763696],
                [107.6701434, 14.1757866],
                [107.6694701, 14.1752179],
                [107.6688242, 14.1748268],
                [107.6680785, 14.1745164],
                [107.6673086, 14.1742797],
                [107.6665378, 14.1739696],
                [107.6659046, 14.1735906],
                [107.6649018, 14.172805],
                [107.6642172, 14.1723345],
                [107.662338, 14.1708047],
                [107.6613688, 14.169651],
                [107.6601654, 14.1678318],
                [107.6592397, 14.1667046],
                [107.6583, 14.1656487],
                [107.6580398, 14.1649992],
                [107.6580254, 14.1637649],
                [107.6579643, 14.1631661],
                [107.6579735, 14.1624077],
                [107.6582468, 14.1610821],
                [107.6588622, 14.1596823],
                [107.6591815, 14.1592026],
                [107.6598934, 14.1583483],
                [107.6603538, 14.1575849],
                [107.6606169, 14.1569295],
                [107.6606852, 14.1565937],
                [107.6607338, 14.1561171],
                [107.6607175, 14.1547241],
                [107.6605309, 14.1526455],
                [107.660457, 14.1509503],
                [107.66048, 14.149823],
                [107.6605777, 14.1487968],
                [107.6610061, 14.1468529],
                [107.6611449, 14.1463755],
                [107.6617791, 14.145064],
                [107.6619894, 14.1445328],
                [107.6645773, 14.1395764],
                [107.6646101, 14.1374588],
                [107.6641248, 14.1359232],
                [107.6625624, 14.1333151],
                [107.6623058, 14.1329654],
                [107.661735, 14.1319668],
                [107.6614945, 14.1314583],
                [107.6611584, 14.1304748],
                [107.6609333, 14.1297369],
                [107.6607462, 14.1291573],
                [107.6606131, 14.1285771],
                [107.6605897, 14.128119],
                [107.6605837, 14.1275902],
                [107.6605615, 14.1272379],
                [107.6605564, 14.1267973],
                [107.6605823, 14.1259331],
                [107.6605773, 14.1254926],
                [107.6605361, 14.1250524],
                [107.6605488, 14.1245939],
                [107.6605781, 14.1240118],
                [107.6605551, 14.123589],
                [107.6605687, 14.123201],
                [107.6605651, 14.1228837],
                [107.6605412, 14.1223905],
                [107.6605365, 14.121985],
                [107.660458, 14.1214394],
                [107.6604181, 14.1211049],
                [107.6603219, 14.1205948],
                [107.6602441, 14.120102],
                [107.6600946, 14.119663],
                [107.6598719, 14.119119],
                [107.6596876, 14.1187861],
                [107.6590258, 14.1177181],
                [107.6587127, 14.1171575],
                [107.6584893, 14.1165606],
                [107.6583952, 14.1162267],
                [107.6582815, 14.1157521],
                [107.6582032, 14.1152239],
                [107.6581071, 14.1147139],
                [107.6580835, 14.1142382],
                [107.6581139, 14.1137442],
                [107.6580546, 14.1133042],
                [107.6580489, 14.1128106],
                [107.6581165, 14.1124221],
                [107.6581115, 14.1119814],
                [107.6580869, 14.1114177],
                [107.658009, 14.1109249],
                [107.6579852, 14.1104316],
                [107.6578522, 14.1098513],
                [107.6577918, 14.1093055],
                [107.6577682, 14.1088298],
                [107.6577074, 14.1082488],
                [107.6576999, 14.1075967],
                [107.6577115, 14.10705],
                [107.6577363, 14.1060801],
                [107.6577835, 14.1054803],
                [107.6577786, 14.1050572],
                [107.657792, 14.1046517],
                [107.6577829, 14.1038762],
                [107.6576704, 14.1035072],
                [107.6574325, 14.1032101],
                [107.6571406, 14.1029313],
                [107.6566863, 14.1026367],
                [107.6559239, 14.102222],
                [107.6554537, 14.1021038],
                [107.654642, 14.1021127],
                [107.6541732, 14.1021356],
                [107.6538847, 14.1021387],
                [107.6533245, 14.1020392],
                [107.6526567, 14.1020112],
                [107.6521691, 14.1019637],
                [107.6511202, 14.1017285],
                [107.650685, 14.1015217],
                [107.6502122, 14.1011921],
                [107.6498292, 14.1008261],
                [107.6495718, 14.1004058],
                [107.649387, 14.10002],
                [107.6492736, 14.0995805],
                [107.6491786, 14.0991585],
                [107.6490288, 14.0986841],
                [107.6489686, 14.0981561],
                [107.6489643, 14.0977858],
                [107.6490662, 14.0972383],
                [107.649222, 14.0966724],
                [107.6493979, 14.0962827],
                [107.6496818, 14.0958742],
                [107.6499843, 14.0955183],
                [107.6503231, 14.0951795],
                [107.6507506, 14.0947165],
                [107.6510882, 14.0942721],
                [107.6513916, 14.0939867],
                [107.6517307, 14.0936832],
                [107.6522123, 14.0932197],
                [107.6525149, 14.0928638],
                [107.6528178, 14.0925431],
                [107.6531027, 14.0922227],
                [107.653495, 14.0918306],
                [107.653869, 14.091421],
                [107.6541711, 14.0910299],
                [107.6544741, 14.0907092],
                [107.6547581, 14.0903183],
                [107.6550958, 14.0898914],
                [107.6553266, 14.0895716],
                [107.6556991, 14.089021],
                [107.6561591, 14.0882579],
                [107.656371, 14.0878678],
                [107.656815, 14.0872635],
                [107.6570972, 14.0867139],
                [107.6572904, 14.0862711],
                [107.6575335, 14.0854575],
                [107.6575832, 14.0850692],
                [107.6575789, 14.084699],
                [107.6575377, 14.0842587],
                [107.657407, 14.0838724],
                [107.6572222, 14.0834866],
                [107.6568177, 14.0828212],
                [107.6564527, 14.082455],
                [107.6557589, 14.0817223],
                [107.6550849, 14.081148],
                [107.6547019, 14.0807821],
                [107.6543917, 14.0804682],
                [107.653936, 14.0800501],
                [107.6537528, 14.0798053],
                [107.6533876, 14.0794216],
                [107.6530401, 14.0790022],
                [107.6526212, 14.0786543],
                [107.6518538, 14.0777989],
                [107.6516158, 14.0774842],
                [107.6513947, 14.0770813],
                [107.651247, 14.0767832],
                [107.650952, 14.0762223],
                [107.6508389, 14.0758005],
                [107.6506889, 14.0753085],
                [107.6506836, 14.0748503],
                [107.6506955, 14.0743213],
                [107.6507622, 14.0738446],
                [107.6507389, 14.0733865],
                [107.6508205, 14.0726453],
                [107.6509066, 14.0722917],
                [107.6509194, 14.0718332],
                [107.6509684, 14.071392],
                [107.6509925, 14.0703517],
                [107.6509349, 14.0700526],
                [107.6509306, 14.0696825],
                [107.6509081, 14.0692949],
                [107.65083, 14.0687669],
                [107.6507525, 14.0683094],
                [107.6507298, 14.0679043],
                [107.6505987, 14.0674826],
                [107.6502312, 14.0668873],
                [107.6499566, 14.0665377],
                [107.6496822, 14.0662058],
                [107.6493718, 14.0658743],
                [107.6486444, 14.0653534],
                [107.6476834, 14.0649056],
                [107.647448, 14.0648201],
                [107.6470312, 14.0646483],
                [107.6467058, 14.0645815],
                [107.6458003, 14.0642564],
                [107.6452579, 14.064139],
                [107.6436474, 14.063663],
                [107.6431208, 14.0633515],
                [107.6427754, 14.0631085],
                [107.6425024, 14.0629],
                [107.6421027, 14.0626398],
                [107.6417382, 14.0623089],
                [107.6408984, 14.0614191],
                [107.6405517, 14.0610703],
                [107.6400953, 14.0605817],
                [107.6394561, 14.0598835],
                [107.6391635, 14.0595342],
                [107.638834, 14.0591147],
                [107.6385415, 14.0587654],
                [107.6382678, 14.0584862],
                [107.6379932, 14.0581367],
                [107.6376997, 14.0576992],
                [107.6373139, 14.0570864],
                [107.6366899, 14.0561412],
                [107.6363058, 14.0556695],
                [107.6359407, 14.0552856],
                [107.6355751, 14.0548489],
                [107.6351551, 14.0543952],
                [107.6347361, 14.0540295],
                [107.6343366, 14.0537872],
                [107.6335406, 14.0535666],
                [107.632981, 14.0535198],
                [107.6322599, 14.0535453],
                [107.631864, 14.0536202],
                [107.6313785, 14.0537488],
                [107.6308755, 14.053913],
                [107.6304267, 14.0540941],
                [107.6292416, 14.0545478],
                [107.6287029, 14.0547476],
                [107.6282718, 14.0548933],
                [107.6277688, 14.055075],
                [107.6273021, 14.0552564],
                [107.6269252, 14.0554192],
                [107.6264229, 14.0556538],
                [107.626029, 14.0559049],
                [107.6255809, 14.0561389],
                [107.6251519, 14.0564785],
                [107.6247046, 14.0568008],
                [107.6244547, 14.0570149],
                [107.6241705, 14.0574058],
                [107.6238141, 14.05778],
                [107.6234222, 14.0582073],
                [107.6230472, 14.0585287],
                [107.6225659, 14.0590276],
                [107.6221905, 14.0593136],
                [107.6217976, 14.0596529],
                [107.6210456, 14.060137],
                [107.6202917, 14.0604449],
                [107.6198611, 14.0606435],
                [107.6193241, 14.0610019],
                [107.6189309, 14.0613058],
                [107.6180913, 14.06202],
                [107.6178059, 14.0622877],
                [107.6171981, 14.0627684],
                [107.61723, 14.0627981],
                [107.616821, 14.0631005],
                [107.6161965, 14.0633878],
                [107.6157502, 14.063568],
                [107.6151763, 14.0635742],
                [107.6145632, 14.0632827],
                [107.6141647, 14.0629364],
                [107.6139086, 14.062501],
                [107.6137409, 14.0619417],
                [107.6136794, 14.0612762],
                [107.6136015, 14.0607336],
                [107.613536, 14.0596999],
                [107.6136034, 14.0593135],
                [107.6136509, 14.0587696],
                [107.6136102, 14.0583317],
                [107.613599, 14.0573501],
                [107.6135739, 14.0567192],
                [107.6135323, 14.0562113],
                [107.6133801, 14.0554414],
                [107.6129777, 14.0547621],
                [107.612701, 14.0540813],
                [107.6124808, 14.0536455],
                [107.6123522, 14.0533663],
                [107.6120402, 14.0527386],
                [107.6117295, 14.0522335],
                [107.6113651, 14.051729],
                [107.6109126, 14.0513658],
                [107.61055, 14.0510191],
                [107.610098, 14.0507084],
                [107.6095037, 14.0504869],
                [107.6089821, 14.0503523],
                [107.608424, 14.0501654],
                [107.6075423, 14.049912],
                [107.6070374, 14.049672],
                [107.6063164, 14.0493467],
                [107.6057027, 14.0490027],
                [107.6050534, 14.0486766],
                [107.6044399, 14.0483501],
                [107.6040592, 14.0479861],
                [107.6030989, 14.0471198],
                [107.6027904, 14.0468076],
                [107.6023017, 14.0464096],
                [107.6018662, 14.0459585],
                [107.6015383, 14.0455061],
                [107.601282, 14.0450531],
                [107.601206, 14.0446682],
                [107.6011425, 14.0438099],
                [107.6011342, 14.0430736],
                [107.601037, 14.0424084],
                [107.6010119, 14.0417776],
                [107.6008984, 14.0412529],
                [107.6008033, 14.040763],
                [107.6007214, 14.0398697],
                [107.6006447, 14.0394323],
                [107.6006017, 14.0388016],
                [107.6006472, 14.0380648],
                [107.6007305, 14.0375028],
                [107.6008322, 14.0369757],
                [107.6009144, 14.0363087],
                [107.6010698, 14.0357811],
                [107.6011711, 14.035219],
                [107.6013085, 14.0346915],
                [107.6013925, 14.0341823],
                [107.6014415, 14.0337609],
                [107.6014698, 14.0330945],
                [107.6015177, 14.0325679],
                [107.6014947, 14.0321123],
                [107.6014347, 14.0315695],
                [107.6013923, 14.0309914],
                [107.6012966, 14.030449],
                [107.6012743, 14.0300636],
                [107.6011801, 14.0296613],
                [107.6010329, 14.0293299],
                [107.6002302, 14.0281287],
                [107.599849, 14.0277121],
                [107.5994502, 14.0273307],
                [107.5990334, 14.0269495],
                [107.5985995, 14.0266385],
                [107.5980204, 14.0261714],
                [107.5975676, 14.0257731],
                [107.5970433, 14.0253929],
                [107.596663, 14.025064],
                [107.596247, 14.0247528],
                [107.5959388, 14.0244581],
                [107.5948723, 14.0236981],
                [107.5944378, 14.0233346],
                [107.5938419, 14.0229728],
                [107.5933901, 14.0226621],
                [107.5929387, 14.0223864],
                [107.5924337, 14.0221288],
                [107.5919637, 14.0218008],
                [107.5914562, 14.0213153],
                [107.5911303, 14.0210384],
                [107.5908946, 14.0208129],
                [107.5903154, 14.0203282],
                [107.5899159, 14.0198943],
                [107.5893898, 14.0193564],
                [107.5889937, 14.0192028],
                [107.5886886, 14.0191885],
                [107.5884386, 14.0192788],
                [107.5881551, 14.0195799],
                [107.5876913, 14.0197953],
                [107.58721, 14.0200634],
                [107.5868916, 14.0204525],
                [107.5865412, 14.0211927],
                [107.5860982, 14.0216531],
                [107.5855822, 14.0220268],
                [107.5852609, 14.022153],
                [107.5848483, 14.0221399],
                [107.5844351, 14.0220742],
                [107.5841463, 14.021902],
                [107.5838206, 14.0216424],
                [107.583565, 14.021242],
                [107.5833614, 14.0206831],
                [107.583249, 14.020246],
                [107.5831354, 14.0197037],
                [107.5829693, 14.0192847],
                [107.5826605, 14.0189374],
                [107.5822994, 14.0187133],
                [107.5816332, 14.0184749],
                [107.5810761, 14.0183758],
                [107.5804299, 14.0183125],
                [107.5796228, 14.0183035],
                [107.5783677, 14.0183169],
                [107.5775977, 14.0184128],
                [107.5770602, 14.0184535],
                [107.5765583, 14.0184764],
                [107.575967, 14.0185178],
                [107.5753928, 14.0184889],
                [107.5745661, 14.0183223],
                [107.5737202, 14.0180508],
                [107.5729661, 14.0179711],
                [107.5725007, 14.0180462],
                [107.5721979, 14.0182248],
                [107.5719141, 14.0185083],
                [107.5716641, 14.0185987],
                [107.5713949, 14.0185839],
                [107.5712322, 14.0184629],
                [107.5708531, 14.018239],
                [107.5704736, 14.0179801],
                [107.570168, 14.0179132],
                [107.5699357, 14.0179859],
                [107.5696344, 14.0183046],
                [107.5694052, 14.0186576],
                [107.569248, 14.0190275],
                [107.5690563, 14.019538],
                [107.5688817, 14.0199606],
                [107.5685088, 14.0202977],
                [107.5676009, 14.0208858],
                [107.56703, 14.0211549],
                [107.5664221, 14.021319],
                [107.5659218, 14.0214821],
                [107.565386, 14.0216807],
                [107.5648136, 14.0218095],
                [107.5637034, 14.0219616],
                [107.5630943, 14.0220206],
                [107.5625924, 14.0220259],
                [107.5621264, 14.0220484],
                [107.5615167, 14.0220548],
                [107.5611758, 14.0220409],
                [107.5607449, 14.0219929],
                [107.5600981, 14.0218945],
                [107.5595585, 14.0217424],
                [107.5591985, 14.0216235],
                [107.5589274, 14.0214335],
                [107.5583519, 14.0212818],
                [107.5577958, 14.0212702],
                [107.5572945, 14.0213456],
                [107.5569201, 14.0215425],
                [107.5565104, 14.0217922],
                [107.5560479, 14.0221303],
                [107.5557466, 14.0224489],
                [107.55541, 14.0228207],
                [107.5551623, 14.0231214],
                [107.5547545, 14.0235464],
                [107.5536399, 14.024921],
                [107.5533571, 14.0252917],
                [107.5529842, 14.0256283],
                [107.552628, 14.0258422],
                [107.5522524, 14.0259337],
                [107.5519296, 14.0259371],
                [107.5516594, 14.0258349],
                [107.5512636, 14.0257165],
                [107.551012, 14.0255965],
                [107.550544, 14.0254263],
                [107.5502001, 14.0251319],
                [107.549838, 14.0248027],
                [107.5494568, 14.0243861],
                [107.5490035, 14.0239176],
                [107.5487127, 14.0235527],
                [107.5483676, 14.0231532],
                [107.5479873, 14.0228067],
                [107.547661, 14.022477],
                [107.5473533, 14.0222174],
                [107.5471172, 14.0219396],
                [107.5468615, 14.021504],
                [107.5462932, 14.0203707],
                [107.5461824, 14.020074],
                [107.5460337, 14.0196023],
                [107.5459232, 14.019323],
                [107.5458117, 14.0189737],
                [107.5457162, 14.018449],
                [107.545604, 14.0180294],
                [107.5455827, 14.0177142],
                [107.5454527, 14.0173125],
                [107.5453055, 14.0169634],
                [107.5451744, 14.0164566],
                [107.5450952, 14.0157738],
                [107.5449639, 14.0152494],
                [107.5445949, 14.0143069],
                [107.5444834, 14.01394],
                [107.5441552, 14.0134351],
                [107.5438643, 14.0130701],
                [107.5435382, 14.0127582],
                [107.5431045, 14.0124472],
                [107.5427788, 14.0121702],
                [107.5423991, 14.0118762],
                [107.5420377, 14.0116171],
                [107.5413701, 14.011221],
                [107.540829, 14.0109251],
                [107.5405227, 14.0107883],
                [107.5402535, 14.010756],
                [107.5398767, 14.0107251],
                [107.5377796, 14.010747],
                [107.5372155, 14.0107876],
                [107.5366779, 14.0107933],
                [107.5364092, 14.0108048],
                [107.5361677, 14.0108336],
                [107.535783, 14.010899],
                [107.5352924, 14.0110969],
                [107.5349263, 14.0112145],
                [107.5346408, 14.0113226],
                [107.5343021, 14.0114839],
                [107.5340881, 14.0115738],
                [107.5338566, 14.0117163],
                [107.5335892, 14.0118418],
                [107.5329655, 14.0121724],
                [107.532645, 14.012351],
                [107.5323246, 14.0125558],
                [107.5319681, 14.012726],
                [107.5316742, 14.0128868],
                [107.5313977, 14.0130124],
                [107.531201, 14.0130406],
                [107.5309681, 14.0130519],
                [107.530672, 14.0130112],
                [107.5304199, 14.0129087],
                [107.5301771, 14.0128236],
                [107.5298889, 14.0126864],
                [107.5296732, 14.0126361],
                [107.5293057, 14.0126224],
                [107.5291175, 14.0126244],
                [107.5285888, 14.0126124],
                [107.5279978, 14.0126449],
                [107.5276572, 14.0126396],
                [107.5273258, 14.0126519],
                [107.5270395, 14.01269],
                [107.5268966, 14.0127352],
                [107.5266918, 14.0128512],
                [107.5264071, 14.0130382],
                [107.5260954, 14.0132079],
                [107.5258907, 14.0133326],
                [107.524135, 14.0142007],
                [107.5237859, 14.0142395],
                [107.5234815, 14.0142601],
                [107.5232034, 14.0142368],
                [107.5229964, 14.0141513],
                [107.522825, 14.014048],
                [107.5225999, 14.0139451],
                [107.5223294, 14.0137903],
                [107.5221129, 14.0136524],
                [107.5219052, 14.0135056],
                [107.5217147, 14.0132974],
                [107.5214977, 14.0131244],
                [107.5212894, 14.0129163],
                [107.5210634, 14.0127348],
                [107.5208364, 14.0124655],
                [107.5207359, 14.0122825],
                [107.520652, 14.0119856],
                [107.5206498, 14.0117841],
                [107.5206655, 14.0115824],
                [107.5206722, 14.011372],
                [107.5207738, 14.0108279],
                [107.520936, 14.0100991],
                [107.5209954, 14.009783],
                [107.5210284, 14.0095286],
                [107.5210259, 14.0093009],
                [107.5210385, 14.0088102],
                [107.5210798, 14.0084944],
                [107.5211811, 14.0079152],
                [107.5212039, 14.007547],
                [107.5212455, 14.0072574],
                [107.5212501, 14.0068632],
                [107.5213133, 14.0060829],
                [107.5213952, 14.0053724],
                [107.521419, 14.0050918],
                [107.5215538, 14.0043107],
                [107.5216573, 14.0039417],
                [107.5217597, 14.0034763],
                [107.5218189, 14.0031516],
                [107.5218877, 14.002888],
                [107.5219388, 14.0026422],
                [107.5221029, 14.0020798],
                [107.5221539, 14.0018253],
                [107.5221736, 14.0018351],
                [107.5222076, 14.0016683],
                [107.5222593, 14.0014826],
                [107.5223784, 14.0009042],
                [107.5224459, 14.0005274],
                [107.5225062, 14.0003081],
                [107.5225939, 13.9999212],
                [107.5229274, 13.998796],
                [107.523062, 13.9984102],
                [107.5231617, 13.9979907],
                [107.5232911, 13.99713],
                [107.5234682, 13.9963931],
                [107.5234991, 13.9960422],
                [107.5235648, 13.9957023],
                [107.5236767, 13.9953505],
                [107.5237527, 13.9948974],
                [107.5239338, 13.9945225],
                [107.5241254, 13.9940569],
                [107.5243049, 13.9935348],
                [107.5244614, 13.9930243],
                [107.5245623, 13.9927405],
                [107.5247087, 13.9923659],
                [107.5248315, 13.9919575],
                [107.5249664, 13.9915942],
                [107.5250673, 13.9912878],
                [107.5251318, 13.9908462],
                [107.5251288, 13.9905748],
                [107.525205, 13.990133],
                [107.5252934, 13.9897476],
                [107.5253241, 13.9893742],
                [107.5253779, 13.9890118],
                [107.5254782, 13.9886601],
                [107.5256015, 13.9882857],
                [107.5257492, 13.9880467],
                [107.5258386, 13.9877518],
                [107.5259051, 13.9874796],
                [107.525925, 13.9871854],
                [107.5259672, 13.9868119],
                [107.5260449, 13.9865171],
                [107.5261679, 13.98612],
                [107.5263025, 13.9857341],
                [107.5263559, 13.9853152],
                [107.5264444, 13.9849524],
                [107.5264423, 13.9847601],
                [107.5263807, 13.9844103],
                [107.5262856, 13.9841851],
                [107.5260977, 13.983927],
                [107.5260368, 13.9836449],
                [107.525952, 13.9833066],
                [107.5257506, 13.9828789],
                [107.5255981, 13.982677],
                [107.5253644, 13.9824759],
                [107.5251432, 13.9823425],
                [107.5247946, 13.9822104],
                [107.5244469, 13.9821575],
                [107.5241692, 13.9821604],
                [107.5229092, 13.9822753],
                [107.522632, 13.9823234],
                [107.5222283, 13.9824385],
                [107.5214088, 13.9826279],
                [107.5207397, 13.9828157],
                [107.5204158, 13.982819],
                [107.5197098, 13.9828038],
                [107.5194091, 13.9828069],
                [107.5190622, 13.9828331],
                [107.5188076, 13.9828357],
                [107.5185535, 13.9828722],
                [107.5182075, 13.9829664],
                [107.5178152, 13.9830609],
                [107.5173774, 13.9832238],
                [107.5169849, 13.9832957],
                [107.5165008, 13.983459],
                [107.5157167, 13.9836932],
                [107.515255, 13.9837884],
                [107.5147007, 13.9838845],
                [107.5142161, 13.9840026],
                [107.5137999, 13.9840295],
                [107.5129902, 13.984038],
                [107.5125273, 13.9840201],
                [107.5120407, 13.9839573],
                [107.5116683, 13.9837577],
                [107.5113878, 13.9834893],
                [107.5110593, 13.9830631],
                [107.5109614, 13.9825667],
                [107.5108408, 13.9821157],
                [107.5106498, 13.981575],
                [107.5103907, 13.9811481],
                [107.5101087, 13.9807439],
                [107.5093812, 13.9798017],
                [107.5088858, 13.9789251],
                [107.5085521, 13.9780241],
                [107.5077948, 13.9764676],
                [107.5076751, 13.9760848],
                [107.5073438, 13.9754104],
                [107.507037, 13.9748487],
                [107.506314, 13.9743139],
                [107.5056373, 13.9737786],
                [107.5051703, 13.9733993],
                [107.5047946, 13.9728835],
                [107.5042577, 13.9724372],
                [107.5037682, 13.9721034],
                [107.5021128, 13.9709229],
                [107.5018033, 13.970726],
                [107.5005992, 13.9696957],
                [107.4995095, 13.9684894],
                [107.4985147, 13.9673298],
                [107.4976612, 13.9666796],
                [107.4958007, 13.966992],
                [107.4952472, 13.9670849],
                [107.4951238, 13.9670188],
                [107.4873991, 13.9610216],
                [107.4825925, 13.9598555],
                [107.4757261, 13.9596889],
                [107.4731512, 13.9555241],
                [107.4698896, 13.9535249],
                [107.469203, 13.951859],
                [107.4701294, 13.9427749],
                [107.4706501, 13.9401144],
                [107.4704568, 13.9381432],
                [107.4702649, 13.9366894],
                [107.470226, 13.9357427],
                [107.4693746, 13.9343656],
                [107.4669323, 13.9328841],
                [107.4669163, 13.9328717],
                [107.4633608, 13.931723],
                [107.46308, 13.9316096],
                [107.4628136, 13.9314671],
                [107.4625647, 13.9312973],
                [107.4623448, 13.9311101],
                [107.4621463, 13.9309016],
                [107.4618742, 13.9305807],
                [107.4616284, 13.9302405],
                [107.4614102, 13.929883],
                [107.4612211, 13.9295102],
                [107.4610781, 13.9291675],
                [107.4609842, 13.9288569],
                [107.4609247, 13.9285383],
                [107.4609005, 13.9282155],
                [107.4609017, 13.9280535],
                [107.4609262, 13.9277636],
                [107.4610117, 13.9270825],
                [107.461029, 13.9268385],
                [107.4610146, 13.9265944],
                [107.4609955, 13.9264735],
                [107.4609339, 13.9262364],
                [107.4608893, 13.9261151],
                [107.4607755, 13.9258824],
                [107.460707, 13.9257721],
                [107.460548, 13.925566],
                [107.4603623, 13.9253821],
                [107.4593165, 13.9244895],
                [107.4592433, 13.9244359],
                [107.4591121, 13.9243117],
                [107.4590041, 13.924168],
                [107.4589591, 13.9240888],
                [107.4588909, 13.9239206],
                [107.4588531, 13.9237437],
                [107.4588468, 13.923563],
                [107.4588788, 13.9216786],
                [107.4594609, 13.9216465],
                [107.4600434, 13.9216232],
                [107.4609868, 13.9216041],
                [107.4619304, 13.9216082],
                [107.462293, 13.9216206],
                [107.463017, 13.921668],
                [107.463352, 13.9217022],
                [107.4636859, 13.9217454],
                [107.4643215, 13.9218496],
                [107.464931, 13.9219319],
                [107.465544, 13.9219849],
                [107.4661589, 13.9220085],
                [107.4668061, 13.9220016],
                [107.4671293, 13.9219859],
                [107.4677741, 13.9219302],
                [107.468415, 13.9218422],
                [107.4687334, 13.9217862],
                [107.4694098, 13.9216407],
                [107.4697671, 13.9215505],
                [107.4701219, 13.9214515],
                [107.470474, 13.9213437],
                [107.4708232, 13.9212274],
                [107.4711692, 13.9211025],
                [107.4714432, 13.9209592],
                [107.4716998, 13.9207885],
                [107.4718208, 13.9206934],
                [107.472045, 13.9204863],
                [107.4722445, 13.9202587],
                [107.4724181, 13.9200119],
                [107.472564, 13.9197487],
                [107.4726448, 13.9195618],
                [107.4727897, 13.9191815],
                [107.4728537, 13.9189885],
                [107.4729858, 13.9185097],
                [107.4730829, 13.9180231],
                [107.4731446, 13.9175311],
                [107.4731621, 13.9172839],
                [107.473079, 13.9163544],
                [107.4730393, 13.9161486],
                [107.4729368, 13.915742],
                [107.4728707, 13.9155314],
                [107.4727137, 13.9151184],
                [107.4718344, 13.9132419],
                [107.4716848, 13.9128939],
                [107.4715439, 13.9125424],
                [107.4714118, 13.9121878],
                [107.4712886, 13.9118302],
                [107.4710764, 13.9111334],
                [107.4708979, 13.9104278],
                [107.4707211, 13.9095601],
                [107.4706823, 13.9092467],
                [107.4706784, 13.9089311],
                [107.4706901, 13.9087674],
                [107.4707113, 13.9086046],
                [107.4707418, 13.9084432],
                [107.4707816, 13.9082837],
                [107.4708304, 13.9081266],
                [107.4708883, 13.9079724],
                [107.4713087, 13.9064851],
                [107.4713618, 13.9060791],
                [107.4713827, 13.9056704],
                [107.4713707, 13.9052497],
                [107.4713512, 13.9050342],
                [107.4712856, 13.9046064],
                [107.4712394, 13.9043948],
                [107.4711845, 13.9041851],
                [107.4706435, 13.9024456],
                [107.4705174, 13.9018937],
                [107.4704185, 13.9013366],
                [107.4703793, 13.9010565],
                [107.4703421, 13.9007263],
                [107.4703143, 13.9003952],
                [107.4702961, 13.9000634],
                [107.4702875, 13.8997313],
                [107.4702884, 13.8993991],
                [107.4702989, 13.899067],
                [107.470319, 13.8987354],
                [107.4708474, 13.8947881],
                [107.4708799, 13.8945066],
                [107.4709032, 13.8942242],
                [107.4709173, 13.8939413],
                [107.470922, 13.893658],
                [107.4709175, 13.8933748],
                [107.4709037, 13.8930918],
                [107.470851, 13.8925488],
                [107.4707641, 13.89201],
                [107.470708, 13.8917427],
                [107.4705705, 13.8912139],
                [107.4704893, 13.8909528],
                [107.4704191, 13.890767],
                [107.470255, 13.8904048],
                [107.4700601, 13.8900571],
                [107.4698419, 13.8897346],
                [107.4697228, 13.8895801],
                [107.4694655, 13.8892858],
                [107.4691844, 13.889013],
                [107.4684293, 13.8883377],
                [107.4676509, 13.8876878],
                [107.4668026, 13.8870134],
                [107.4663573, 13.8866481],
                [107.4659173, 13.8862767],
                [107.4652663, 13.8857099],
                [107.4648246, 13.8852979],
                [107.4634392, 13.8839005],
                [107.462943, 13.8834318],
                [107.4624306, 13.88298],
                [107.4618222, 13.8824821],
                [107.461194, 13.882008],
                [107.4608728, 13.8817801],
                [107.459287, 13.8807354],
                [107.4588495, 13.8804097],
                [107.4584332, 13.8800586],
                [107.4580398, 13.8796835],
                [107.4578426, 13.8794769],
                [107.4576523, 13.8792644],
                [107.4574691, 13.879046],
                [107.4572932, 13.878822],
                [107.4571249, 13.8785926],
                [107.4569643, 13.8783581],
                [107.4565868, 13.8777492],
                [107.4562354, 13.8771258],
                [107.4559005, 13.8764682],
                [107.4555945, 13.8757975],
                [107.455318, 13.8751149],
                [107.4545704, 13.873046],
                [107.4544928, 13.872809],
                [107.4544243, 13.8725693],
                [107.4543649, 13.8723273],
                [107.4543148, 13.8720834],
                [107.4542521, 13.8716743],
                [107.4542328, 13.8713453],
                [107.4542464, 13.8710159],
                [107.4542655, 13.8708522],
                [107.454318, 13.8705704],
                [107.4544107, 13.8702172],
                [107.4544819, 13.8699842],
                [107.4546485, 13.8695259],
                [107.4548465, 13.8690796],
                [107.4550751, 13.8686473],
                [107.4553425, 13.8682149],
                [107.4556363, 13.8677991],
                [107.4559556, 13.8674013],
                [107.4562991, 13.867023],
                [107.4565022, 13.8668195],
                [107.4567125, 13.8666228],
                [107.4569294, 13.8664331],
                [107.4571529, 13.8662508],
                [107.4573827, 13.8660759],
                [107.4576185, 13.8659088],
                [107.45786, 13.8657495],
                [107.4581069, 13.8655983],
                [107.458359, 13.8654554],
                [107.4612418, 13.8640658],
                [107.462157, 13.8635908],
                [107.463062, 13.8630849],
                [107.4664378, 13.8610852],
                [107.4703138, 13.8588175],
                [107.4705258, 13.8586816],
                [107.4707321, 13.8585376],
                [107.4709324, 13.8583858],
                [107.4711263, 13.8582264],
                [107.4713111, 13.858062],
                [107.4714891, 13.8578907],
                [107.47166, 13.8577128],
                [107.4719568, 13.8573682],
                [107.4720832, 13.8572029],
                [107.4723149, 13.8568581],
                [107.4725172, 13.8564963],
                [107.4726949, 13.8561048],
                [107.4727736, 13.8558963],
                [107.4728429, 13.8556847],
                [107.4729164, 13.8552869],
                [107.4729581, 13.8548847],
                [107.4729671, 13.8546827],
                [107.4729609, 13.8542785],
                [107.4729228, 13.8538757],
                [107.472853, 13.8534769],
                [107.4728062, 13.8532797],
                [107.4727517, 13.8530844],
                [107.4726198, 13.8527007],
                [107.4725425, 13.8525129],
                [107.4714877, 13.8499835],
                [107.4709518, 13.8487223],
                [107.4702441, 13.8470804],
                [107.4698889, 13.8463245],
                [107.4696999, 13.8459518],
                [107.4692992, 13.8452176],
                [107.4688783, 13.8445139],
                [107.4684395, 13.8438409],
                [107.4682103, 13.8435105],
                [107.4677308, 13.8428484],
                [107.4674947, 13.8425072],
                [107.4672666, 13.8421609],
                [107.4668605, 13.8414983],
                [107.4664834, 13.8408197],
                [107.4652657, 13.8383716],
                [107.4650122, 13.8379525],
                [107.4648743, 13.8377497],
                [107.4645771, 13.8373587],
                [107.4642525, 13.8369887],
                [107.4640719, 13.8368038],
                [107.4638844, 13.8366254],
                [107.4636904, 13.8364538],
                [107.46349, 13.8362892],
                [107.4632835, 13.8361319],
                [107.4625842, 13.8356708],
                [107.4622279, 13.8354501],
                [107.4611905, 13.834841],
                [107.4608588, 13.834633],
                [107.4602095, 13.8341965],
                [107.4598923, 13.8339682],
                [107.4592734, 13.8334921],
                [107.458676, 13.8329905],
                [107.4581777, 13.832537],
                [107.4577781, 13.8321336],
                [107.4574019, 13.8317096],
                [107.4572229, 13.8314902],
                [107.4568838, 13.8310376],
                [107.4565625, 13.8305546],
                [107.4562697, 13.8300548],
                [107.4560064, 13.8295397],
                [107.455886, 13.829277],
                [107.4553187, 13.827861],
                [107.4551425, 13.8273605],
                [107.4550009, 13.8268497],
                [107.4549262, 13.826485],
                [107.4549013, 13.8263006],
                [107.4548766, 13.8259296],
                [107.4548855, 13.8255578],
                [107.4549266, 13.8251967],
                [107.4549591, 13.8250178],
                [107.4550475, 13.8246648],
                [107.4551034, 13.8244914],
                [107.4552377, 13.8241523],
                [107.455316, 13.8239873],
                [107.4555477, 13.8235595],
                [107.4556737, 13.8233511],
                [107.4559456, 13.8229462],
                [107.4562626, 13.8225349],
                [107.4564416, 13.8223255],
                [107.4566282, 13.8221225],
                [107.456822, 13.8219259],
                [107.4570228, 13.8217361],
                [107.4572303, 13.8215532],
                [107.4574444, 13.8213776],
                [107.4596145, 13.8196723],
                [107.4599308, 13.819389],
                [107.4601195, 13.8192031],
                [107.4603012, 13.8190107],
                [107.4604755, 13.818812],
                [107.4606422, 13.8186072],
                [107.4608968, 13.8182695],
                [107.4610664, 13.8180012],
                [107.4612052, 13.8177167],
                [107.4613116, 13.8174193],
                [107.4613845, 13.8171126],
                [107.4614231, 13.8167986],
                [107.4614265, 13.8164825],
                [107.4613948, 13.8161678],
                [107.4613658, 13.8160122],
                [107.4592399, 13.8072724],
                [107.4591831, 13.8070775],
                [107.4591174, 13.8068853],
                [107.4590429, 13.8066961],
                [107.4588747, 13.8063406],
                [107.4586831, 13.8060122],
                [107.4585769, 13.8058541],
                [107.4583446, 13.8055514],
                [107.4580872, 13.8052685],
                [107.4578065, 13.8050074],
                [107.4575227, 13.8047135],
                [107.4569714, 13.8041114],
                [107.4567041, 13.8038033],
                [107.4561864, 13.8031737],
                [107.4559126, 13.8028214],
                [107.4553863, 13.8021016],
                [107.454889, 13.8013625],
                [107.4546515, 13.8009862],
                [107.4525491, 13.7975259],
                [107.4521145, 13.7968355],
                [107.451607, 13.7960824],
                [107.451317, 13.7956747],
                [107.4508216, 13.7950168],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [104.3343417, 22.690919],
                [104.334475, 22.6902453],
                [104.3354602, 22.6894647],
                [104.3366916, 22.688373],
                [104.3376259, 22.6878286],
                [104.338593, 22.6877076],
                [104.3400928, 22.6870951],
                [104.3413915, 22.6862425],
                [104.3420722, 22.6854633],
                [104.3421459, 22.6850398],
                [104.3425721, 22.6846617],
                [104.3431868, 22.6838299],
                [104.3444899, 22.6824915],
                [104.34508, 22.6818261],
                [104.3459897, 22.6814556],
                [104.3476478, 22.6801447],
                [104.3494754, 22.6795019],
                [104.3504507, 22.6786701],
                [104.3514014, 22.6775887],
                [104.3523357, 22.676583],
                [104.3537453, 22.6760461],
                [104.3544583, 22.6755772],
                [104.3550484, 22.6752672],
                [104.3565482, 22.6747454],
                [104.3577858, 22.6742236],
                [104.3590069, 22.6736186],
                [104.3600314, 22.6730515],
                [104.3615929, 22.6732053],
                [104.3624611, 22.6731481],
                [104.3634573, 22.6730002],
                [104.3640509, 22.6729103],
                [104.364795, 22.6728332],
                [104.3656702, 22.6728827],
                [104.36635, 22.6730538],
                [104.3673901, 22.6734366],
                [104.3683066, 22.673882],
                [104.3689378, 22.6740803],
                [104.3698205, 22.6742537],
                [104.370644, 22.6743494],
                [104.3712473, 22.6745417],
                [104.3719942, 22.6749044],
                [104.3729898, 22.675446],
                [104.3736049, 22.6756457],
                [104.3741081, 22.6757159],
                [104.3750606, 22.6757283],
                [104.3761857, 22.6757916],
                [104.3771462, 22.6759607],
                [104.3788031, 22.6763199],
                [104.3799768, 22.6765293],
                [104.3810677, 22.6767965],
                [104.3820393, 22.6771231],
                [104.382634, 22.6773809],
                [104.3831569, 22.6777615],
                [104.3841559, 22.6787407],
                [104.3847558, 22.6793187],
                [104.3851355, 22.6795995],
                [104.3855481, 22.6797557],
                [104.3860311, 22.6798286],
                [104.3866063, 22.6798707],
                [104.3869739, 22.6798204],
                [104.3873504, 22.6797169],
                [104.3884192, 22.6794008],
                [104.3897614, 22.6790951],
                [104.3904336, 22.6789968],
                [104.39101, 22.6790094],
                [104.391542, 22.6790971],
                [104.3919222, 22.6792758],
                [104.3922654, 22.6795473],
                [104.3926509, 22.6798914],
                [104.3931707, 22.6801589],
                [104.3936362, 22.6802725],
                [104.3941093, 22.6804162],
                [104.3962522, 22.680565],
                [104.3974494, 22.6806024],
                [104.3983766, 22.6805435],
                [104.3997217, 22.6793206],
                [104.4007832, 22.6785076],
                [104.4021391, 22.6770012],
                [104.4035841, 22.6754442],
                [104.4050062, 22.6730088],
                [104.4054599, 22.6718814],
                [104.4056057, 22.6708655],
                [104.4062292, 22.6691994],
                [104.4070148, 22.6669373],
                [104.4074325, 22.6662616],
                [104.4083889, 22.6644002],
                [104.4094686, 22.6623161],
                [104.4103467, 22.6602665],
                [104.4109475, 22.658566],
                [104.4105299, 22.6579153],
                [104.4096041, 22.6570894],
                [104.4084097, 22.6563439],
                [104.4075266, 22.6556489],
                [104.4072494, 22.6549247],
                [104.4070337, 22.6540174],
                [104.408952, 22.6515548],
                [104.4104356, 22.6493381],
                [104.4107219, 22.6478218],
                [104.4110112, 22.6464714],
                [104.4123289, 22.6431641],
                [104.4132812, 22.6410469],
                [104.4133971, 22.6391857],
                [104.4138362, 22.6373307],
                [104.4143689, 22.6365549],
                [104.4155458, 22.6352372],
                [104.4167733, 22.633663],
                [104.4185577, 22.6322349],
                [104.4206166, 22.6307183],
                [104.4230685, 22.6290555],
                [104.4238214, 22.6285128],
                [104.425309, 22.6271363],
                [104.4265484, 22.6259944],
                [104.4276222, 22.6254511],
                [104.4297519, 22.6249012],
                [104.4316927, 22.6240223],
                [104.4329311, 22.6228205],
                [104.4353814, 22.6211324],
                [104.4365351, 22.620406],
                [104.437832, 22.6196293],
                [104.4390193, 22.6194744],
                [104.4399841, 22.619345],
                [104.4416472, 22.6186807],
                [104.4432308, 22.6179346],
                [104.444682, 22.6172328],
                [104.4460951, 22.6167453],
                [104.4482992, 22.6159676],
                [104.4494185, 22.6158251],
                [104.4515173, 22.6155796],
                [104.456164, 22.6146837],
                [104.4584739, 22.6140904],
                [104.4600772, 22.6137399],
                [104.4614196, 22.6134282],
                [104.462749, 22.6132898],
                [104.4619147, 22.6123448],
                [104.4612624, 22.6116643],
                [104.4604803, 22.6107473],
                [104.460328, 22.6098759],
                [104.4605182, 22.6084329],
                [104.4608007, 22.6076041],
                [104.4606976, 22.6070393],
                [104.46001, 22.6062913],
                [104.4595223, 22.6053426],
                [104.4592839, 22.6039826],
                [104.4594313, 22.6031779],
                [104.4605625, 22.6018213],
                [104.4622917, 22.6000458],
                [104.4632913, 22.5994548],
                [104.4638787, 22.5987519],
                [104.4642064, 22.5978315],
                [104.4639883, 22.5970917],
                [104.4634822, 22.5966466],
                [104.46294, 22.5955764],
                [104.4627201, 22.5945309],
                [104.4627724, 22.5929546],
                [104.4630026, 22.5915369],
                [104.4629719, 22.5898644],
                [104.4632941, 22.5887026],
                [104.4634925, 22.5869563],
                [104.463888, 22.585237],
                [104.4643206, 22.5837495],
                [104.4652619, 22.5791476],
                [104.4652163, 22.577811],
                [104.465773, 22.5765512],
                [104.4664152, 22.5758107],
                [104.4666856, 22.5750164],
                [104.4665588, 22.5743026],
                [104.4658647, 22.5739621],
                [104.4652252, 22.5738385],
                [104.4640983, 22.573819],
                [104.4626946, 22.5735837],
                [104.4617719, 22.573228],
                [104.459677, 22.5722691],
                [104.4583993, 22.5726051],
                [104.4567093, 22.5728944],
                [104.4553278, 22.5727619],
                [104.454137, 22.5725703],
                [104.4532639, 22.5725703],
                [104.4524421, 22.573001],
                [104.4518631, 22.5734274],
                [104.4505241, 22.5745972],
                [104.4499359, 22.5748157],
                [104.4478836, 22.5748731],
                [104.445822, 22.575296],
                [104.4440873, 22.5755063],
                [104.4435065, 22.5756466],
                [104.4424471, 22.5757216],
                [104.441288, 22.5756657],
                [104.4397865, 22.5754729],
                [104.4373204, 22.5751729],
                [104.4341587, 22.5750943],
                [104.4326862, 22.5751049],
                [104.431329, 22.5749122],
                [104.4300154, 22.5745531],
                [104.4286449, 22.5742232],
                [104.4280776, 22.5739733],
                [104.4269231, 22.5735358],
                [104.4261185, 22.5733804],
                [104.4253386, 22.5731374],
                [104.4248571, 22.5727159],
                [104.4250948, 22.5719314],
                [104.425103, 22.5701345],
                [104.4251775, 22.5687057],
                [104.4249097, 22.5679113],
                [104.4248573, 22.5675812],
                [104.4246834, 22.5667434],
                [104.4247491, 22.5658439],
                [104.4247411, 22.5647159],
                [104.4246417, 22.563741],
                [104.4239356, 22.5630741],
                [104.4229112, 22.5616574],
                [104.4224013, 22.5606382],
                [104.4217713, 22.5597802],
                [104.4216599, 22.5589336],
                [104.4213258, 22.5579441],
                [104.4214485, 22.5570634],
                [104.4220504, 22.5558772],
                [104.4225459, 22.5551154],
                [104.4226496, 22.553942],
                [104.4227082, 22.5533506],
                [104.4231902, 22.5525043],
                [104.4236219, 22.5513801],
                [104.4236194, 22.550605],
                [104.4234116, 22.5499457],
                [104.4227722, 22.5494103],
                [104.4223461, 22.5487342],
                [104.422159, 22.5476752],
                [104.4220211, 22.5459047],
                [104.4215493, 22.5449712],
                [104.4209295, 22.5442],
                [104.4197796, 22.5418292],
                [104.419142, 22.5412096],
                [104.4177877, 22.5401556],
                [104.416438, 22.5384779],
                [104.4158546, 22.5375619],
                [104.4153598, 22.5362681],
                [104.4150316, 22.5344694],
                [104.4147819, 22.5328516],
                [104.4147523, 22.5314859],
                [104.4145979, 22.5303366],
                [104.4144748, 22.529223],
                [104.4146444, 22.5280793],
                [104.4149184, 22.5272149],
                [104.4155923, 22.5262407],
                [104.4164833, 22.5255284],
                [104.4175536, 22.5252664],
                [104.4185439, 22.5250196],
                [104.4199397, 22.5249416],
                [104.4210105, 22.524744],
                [104.4222206, 22.5246621],
                [104.4237428, 22.5244492],
                [104.4248548, 22.5241512],
                [104.425686, 22.5238069],
                [104.4265104, 22.5232949],
                [104.4279479, 22.5229667],
                [104.4288742, 22.5229534],
                [104.4296601, 22.5227308],
                [104.4304367, 22.5225861],
                [104.4317576, 22.5223596],
                [104.4335339, 22.5225421],
                [104.4341308, 22.5223271],
                [104.4346831, 22.5213995],
                [104.435278, 22.5206329],
                [104.4359758, 22.5202728],
                [104.4370985, 22.519711],
                [104.4378888, 22.5193151],
                [104.438651, 22.5187632],
                [104.4393769, 22.5179608],
                [104.4402074, 22.5169457],
                [104.4411484, 22.5160006],
                [104.4420723, 22.5150027],
                [104.4431238, 22.5136542],
                [104.4439492, 22.5122983],
                [104.4445684, 22.5105045],
                [104.4449743, 22.5095392],
                [104.4451127, 22.5084595],
                [104.445044, 22.506731],
                [104.4449586, 22.5052655],
                [104.445168, 22.5039699],
                [104.4460175, 22.5030703],
                [104.4476411, 22.5015647],
                [104.4480237, 22.5011546],
                [104.4486496, 22.5009291],
                [104.4492316, 22.5002933],
                [104.4498001, 22.4997448],
                [104.4505682, 22.4987302],
                [104.4522235, 22.4970521],
                [104.4527936, 22.4961684],
                [104.4533177, 22.4953823],
                [104.45416, 22.4947658],
                [104.4551554, 22.4947099],
                [104.4560307, 22.4949228],
                [104.4572188, 22.4949117],
                [104.4582947, 22.4948491],
                [104.4587337, 22.4948907],
                [104.4593913, 22.4942902],
                [104.4602261, 22.493394],
                [104.4606349, 22.4926653],
                [104.4613525, 22.4919235],
                [104.4622746, 22.4911841],
                [104.4632215, 22.4910727],
                [104.464148, 22.4907797],
                [104.4651833, 22.4906174],
                [104.4662629, 22.4905342],
                [104.4673633, 22.4903584],
                [104.4691787, 22.4899101],
                [104.4700661, 22.4894229],
                [104.4707941, 22.4888871],
                [104.4712871, 22.4881071],
                [104.4714004, 22.4870632],
                [104.47161, 22.4859602],
                [104.4716363, 22.4855874],
                [104.472021, 22.4848276],
                [104.4727598, 22.4843316],
                [104.4736114, 22.4840055],
                [104.4740626, 22.4835136],
                [104.4738941, 22.4830034],
                [104.4734554, 22.4827053],
                [104.4724966, 22.4822048],
                [104.4717188, 22.4819866],
                [104.4693725, 22.4805937],
                [104.4683627, 22.4801675],
                [104.4669144, 22.4787296],
                [104.4657831, 22.4776791],
                [104.4650341, 22.4771968],
                [104.4645546, 22.4769737],
                [104.4642298, 22.4765228],
                [104.464077, 22.4759287],
                [104.4642115, 22.4751822],
                [104.4642505, 22.4743986],
                [104.4640795, 22.4735279],
                [104.4635254, 22.4720853],
                [104.4630752, 22.4715782],
                [104.4624848, 22.4711679],
                [104.461501, 22.4707699],
                [104.46067, 22.4702486],
                [104.4593083, 22.4692651],
                [104.4587141, 22.4685163],
                [104.4578797, 22.4672451],
                [104.4573506, 22.4665826],
                [104.45698, 22.4658195],
                [104.4565324, 22.4653141],
                [104.4560446, 22.4648604],
                [104.4550488, 22.4644036],
                [104.4538751, 22.4639662],
                [104.452888, 22.4637013],
                [104.4517352, 22.4631977],
                [104.4510268, 22.4629401],
                [104.4502591, 22.4623334],
                [104.4498282, 22.4615774],
                [104.449276, 22.4609064],
                [104.4487659, 22.4602728],
                [104.4482097, 22.4593126],
                [104.4482165, 22.4577866],
                [104.448134, 22.457106],
                [104.4481857, 22.456149],
                [104.4481913, 22.455244],
                [104.4486392, 22.4542245],
                [104.4490222, 22.4536264],
                [104.4495593, 22.4519463],
                [104.4494818, 22.4512108],
                [104.4491416, 22.450675],
                [104.4487903, 22.4499935],
                [104.44892, 22.4491842],
                [104.4493959, 22.4483421],
                [104.4502169, 22.4473006],
                [104.4514995, 22.4459572],
                [104.4519224, 22.4453017],
                [104.4522337, 22.4445781],
                [104.4529667, 22.4436093],
                [104.4538493, 22.4427624],
                [104.4549275, 22.4412108],
                [104.4556885, 22.4396775],
                [104.4568348, 22.437709],
                [104.4579862, 22.4364192],
                [104.4589506, 22.4358915],
                [104.4601039, 22.4356731],
                [104.460837, 22.4357391],
                [104.461485, 22.4353501],
                [104.4616527, 22.4346115],
                [104.4619994, 22.434312],
                [104.4623682, 22.4332386],
                [104.4633496, 22.4314543],
                [104.4641818, 22.4308989],
                [104.4645261, 22.4302157],
                [104.4648023, 22.4293133],
                [104.46524, 22.4272638],
                [104.4657163, 22.4253687],
                [104.4662602, 22.4248636],
                [104.4669092, 22.4242679],
                [104.468273, 22.4237569],
                [104.4691674, 22.423531],
                [104.4704073, 22.4233959],
                [104.4717361, 22.4229556],
                [104.4727723, 22.4229144],
                [104.4738587, 22.4226243],
                [104.4762521, 22.4214773],
                [104.4777615, 22.4207903],
                [104.4786222, 22.4204896],
                [104.4799348, 22.4204205],
                [104.4811373, 22.4205749],
                [104.4820807, 22.4208898],
                [104.48285, 22.4209648],
                [104.4842182, 22.4209533],
                [104.4855844, 22.4207792],
                [104.487043, 22.4205897],
                [104.4888679, 22.41994],
                [104.4899505, 22.4197346],
                [104.4905545, 22.4194889],
                [104.4906683, 22.4183491],
                [104.4906509, 22.4176499],
                [104.491133, 22.4161764],
                [104.4904032, 22.4147742],
                [104.4901935, 22.4136638],
                [104.4904906, 22.4127422],
                [104.4909001, 22.4122724],
                [104.491278, 22.4114857],
                [104.4920865, 22.4105241],
                [104.4931177, 22.4101062],
                [104.4934065, 22.4095751],
                [104.493603, 22.4089503],
                [104.4951502, 22.4073911],
                [104.4963541, 22.4061467],
                [104.4969044, 22.4050965],
                [104.4975018, 22.4047811],
                [104.4979868, 22.4048673],
                [104.4984542, 22.4050371],
                [104.4991762, 22.4050799],
                [104.5002038, 22.4047791],
                [104.5012289, 22.4045261],
                [104.5032074, 22.404158],
                [104.5039772, 22.4043204],
                [104.504219, 22.4044613],
                [104.5048628, 22.4045781],
                [104.5056366, 22.4044962],
                [104.5064577, 22.4042047],
                [104.507962, 22.4039478],
                [104.5084454, 22.4036228],
                [104.5091939, 22.4023783],
                [104.509767, 22.4017605],
                [104.5104667, 22.4013318],
                [104.5109764, 22.4010178],
                [104.5114859, 22.4008745],
                [104.5121849, 22.4003039],
                [104.5127313, 22.3996978],
                [104.5130743, 22.3986294],
                [104.514586, 22.3974803],
                [104.5165294, 22.3964411],
                [104.5177995, 22.3958181],
                [104.518863, 22.3957494],
                [104.5206445, 22.3955259],
                [104.5211815, 22.3951189],
                [104.5224212, 22.3935859],
                [104.5238727, 22.3923628],
                [104.5249183, 22.3913235],
                [104.5255306, 22.3909883],
                [104.5261053, 22.3908275],
                [104.5268281, 22.3907758],
                [104.5270832, 22.3909084],
                [104.5277552, 22.3908625],
                [104.5286841, 22.3901737],
                [104.5304712, 22.3888486],
                [104.5309635, 22.388304],
                [104.5315911, 22.3878939],
                [104.5328986, 22.3872254],
                [104.5338686, 22.3869109],
                [104.5355456, 22.3863059],
                [104.53669, 22.3857271],
                [104.5374521, 22.3855559],
                [104.539511, 22.3844709],
                [104.5405426, 22.3836615],
                [104.5415335, 22.3832133],
                [104.5445709, 22.3818742],
                [104.5463127, 22.3809798],
                [104.5473833, 22.3809109],
                [104.5488505, 22.3803581],
                [104.5503936, 22.3802227],
                [104.5507509, 22.3799473],
                [104.5507711, 22.3795401],
                [104.5504936, 22.3790938],
                [104.550032, 22.3787966],
                [104.5497741, 22.3783823],
                [104.5496734, 22.3778015],
                [104.5504661, 22.3766521],
                [104.5516104, 22.3753881],
                [104.5524881, 22.3746496],
                [104.5531216, 22.3738737],
                [104.5533217, 22.373096],
                [104.5536549, 22.3725105],
                [104.5536943, 22.3718783],
                [104.5523471, 22.3704591],
                [104.5516642, 22.3695522],
                [104.5509655, 22.368967],
                [104.5505031, 22.3681577],
                [104.5492041, 22.3665936],
                [104.5485479, 22.365724],
                [104.5483542, 22.364751],
                [104.5481132, 22.3639605],
                [104.5470732, 22.3628643],
                [104.5463041, 22.3619409],
                [104.5458928, 22.3613552],
                [104.5459353, 22.3608013],
                [104.5463828, 22.3598757],
                [104.5477551, 22.3582866],
                [104.5484385, 22.3577382],
                [104.5494028, 22.3572898],
                [104.5507116, 22.3566679],
                [104.5513329, 22.3561005],
                [104.551913, 22.3551792],
                [104.553897, 22.3536179],
                [104.5560232, 22.3534193],
                [104.5593948, 22.3534285],
                [104.5614451, 22.3540471],
                [104.5672323, 22.3550845],
                [104.5677983, 22.3549846],
                [104.5693223, 22.3547154],
                [104.5744584, 22.3529577],
                [104.5792315, 22.3499727],
                [104.5806293, 22.3483163],
                [104.5810743, 22.3465461],
                [104.5798731, 22.3438521],
                [104.5779706, 22.3428253],
                [104.5742365, 22.3415893],
                [104.5731395, 22.3408371],
                [104.5708763, 22.3379699],
                [104.5663825, 22.3336321],
                [104.5647041, 22.3313113],
                [104.564716, 22.3306207],
                [104.5659654, 22.3294659],
                [104.5694909, 22.3268865],
                [104.571841, 22.3251895],
                [104.5721372, 22.3241685],
                [104.5721074, 22.3219543],
                [104.5740205, 22.3193706],
                [104.5764437, 22.3176738],
                [104.5783533, 22.3158235],
                [104.5793172, 22.312011],
                [104.5794346, 22.3095247],
                [104.5791553, 22.3048914],
                [104.5786485, 22.3028463],
                [104.5765307, 22.3005246],
                [104.5715154, 22.2978706],
                [104.5691749, 22.2965701],
                [104.565963, 22.2927465],
                [104.5653099, 22.290701],
                [104.5648768, 22.288588],
                [104.5625012, 22.2868444],
                [104.5587009, 22.2839048],
                [104.5574568, 22.2834927],
                [104.5556249, 22.283624],
                [104.5530598, 22.2839575],
                [104.5516281, 22.2838949],
                [104.5508406, 22.2835843],
                [104.5492297, 22.2821751],
                [104.5457166, 22.2793528],
                [104.543696, 22.2783447],
                [104.5429556, 22.2776666],
                [104.5423774, 22.2763724],
                [104.5422083, 22.2749122],
                [104.5428844, 22.2737888],
                [104.5438656, 22.2725355],
                [104.5454191, 22.270886],
                [104.5467564, 22.2687],
                [104.5477214, 22.2656108],
                [104.5478159, 22.2631007],
                [104.5476511, 22.2603571],
                [104.5480211, 22.258291],
                [104.5484256, 22.2573331],
                [104.5489467, 22.2560561],
                [104.5492682, 22.2546887],
                [104.5497328, 22.2538422],
                [104.5499095, 22.2529961],
                [104.5498303, 22.2518868],
                [104.5495252, 22.2509969],
                [104.5495169, 22.2494071],
                [104.5497251, 22.2477511],
                [104.5508067, 22.2435364],
                [104.5512494, 22.2424905],
                [104.5521136, 22.2417103],
                [104.55389, 22.2417479],
                [104.5556099, 22.2419993],
                [104.5579327, 22.2426186],
                [104.5604652, 22.2425361],
                [104.5633262, 22.243519],
                [104.5676972, 22.2448587],
                [104.5720145, 22.2453294],
                [104.5733385, 22.2453237],
                [104.5745871, 22.2454425],
                [104.5751738, 22.2458649],
                [104.5752841, 22.2469473],
                [104.5758256, 22.2481675],
                [104.5763599, 22.2500441],
                [104.5771338, 22.2508941],
                [104.5782584, 22.251619],
                [104.5793382, 22.2526212],
                [104.5840689, 22.2517321],
                [104.5860421, 22.2513836],
                [104.5865872, 22.2528563],
                [104.5867556, 22.2539763],
                [104.586438, 22.2568862],
                [104.5862596, 22.2583616],
                [104.5859123, 22.2596722],
                [104.5855657, 22.2607589],
                [104.5855632, 22.2616224],
                [104.5856993, 22.2620387],
                [104.5856615, 22.2631901],
                [104.5857628, 22.2637981],
                [104.5863785, 22.2646313],
                [104.5872342, 22.2656251],
                [104.5882263, 22.2669071],
                [104.589217, 22.2687009],
                [104.5896615, 22.2693416],
                [104.5903812, 22.2698553],
                [104.5918559, 22.2705626],
                [104.5930908, 22.2710455],
                [104.5939815, 22.2718155],
                [104.5946995, 22.2729369],
                [104.5955086, 22.2742273],
                [104.5959523, 22.275188],
                [104.5963947, 22.2765966],
                [104.5967031, 22.2768532],
                [104.5968057, 22.2769814],
                [104.5978681, 22.2777837],
                [104.5985532, 22.2783932],
                [104.5988265, 22.2789376],
                [104.5992364, 22.2797064],
                [104.6002293, 22.2808283],
                [104.6010153, 22.2821736],
                [104.6011496, 22.2832935],
                [104.6016965, 22.2842225],
                [104.60221, 22.2847995],
                [104.6025857, 22.2855361],
                [104.6031624, 22.2870505],
                [104.6049766, 22.2892301],
                [104.6064495, 22.290705],
                [104.6072721, 22.2912828],
                [104.6083702, 22.2916692],
                [104.6092628, 22.2918634],
                [104.6100183, 22.2918652],
                [104.6101935, 22.2917811],
                [104.6109469, 22.2914195],
                [104.6118749, 22.2912299],
                [104.6134199, 22.2914575],
                [104.6141147, 22.2913311],
                [104.614528, 22.2909163],
                [104.6149761, 22.2903416],
                [104.6160115, 22.2885208],
                [104.6167356, 22.287499],
                [104.6174582, 22.2870209],
                [104.6186607, 22.2868958],
                [104.6203431, 22.2870916],
                [104.6218882, 22.2872872],
                [104.6233634, 22.2878983],
                [104.6242562, 22.2880283],
                [104.625665, 22.2878077],
                [104.627521, 22.2873001],
                [104.6289298, 22.2871115],
                [104.6295487, 22.286825],
                [104.6302378, 22.286027],
                [104.6309275, 22.2849729],
                [104.6314301, 22.2842884],
                [104.6321525, 22.2838422],
                [104.6332529, 22.2833329],
                [104.6343539, 22.2825677],
                [104.6355236, 22.2818346],
                [104.6363151, 22.2811966],
                [104.6371756, 22.2804949],
                [104.6378986, 22.2798247],
                [104.6384504, 22.2789624],
                [104.6387953, 22.2783873],
                [104.6393455, 22.2781007],
                [104.640652, 22.2775917],
                [104.6420963, 22.2768273],
                [104.6425085, 22.2767962],
                [104.6428168, 22.2771167],
                [104.6440485, 22.2790067],
                [104.6454198, 22.2800013],
                [104.6461407, 22.2800989],
                [104.6488882, 22.2801688],
                [104.6505019, 22.2803641],
                [104.6509829, 22.2803012],
                [104.6515335, 22.2798866],
                [104.6528075, 22.2785779],
                [104.6533035, 22.278042],
                [104.6537505, 22.277819],
                [104.6544724, 22.2775327],
                [104.6554702, 22.2767991],
                [104.6558835, 22.2763202],
                [104.6565129, 22.275523],
                [104.6574669, 22.2748522],
                [104.6590145, 22.2739918],
                [104.6604581, 22.273515],
                [104.6622786, 22.2733588],
                [104.664476, 22.2736193],
                [104.6652652, 22.2738768],
                [104.6652988, 22.2742287],
                [104.6658791, 22.2757333],
                [104.6662213, 22.2762458],
                [104.6671821, 22.2765677],
                [104.6680746, 22.2767933],
                [104.6693457, 22.276636],
                [104.6701009, 22.2767974],
                [104.6710677, 22.2745923],
                [104.672101, 22.273347],
                [104.6727726, 22.2728158],
                [104.6737703, 22.272082],
                [104.6741148, 22.2716029],
                [104.6747363, 22.2701647],
                [104.6753218, 22.2694622],
                [104.6761823, 22.2686002],
                [104.6764584, 22.2679931],
                [104.6767368, 22.2663943],
                [104.6767748, 22.264827],
                [104.6770479, 22.2648122],
                [104.6779753, 22.2647181],
                [104.679522, 22.2641773],
                [104.6813104, 22.2630293],
                [104.6826162, 22.2626161],
                [104.6841969, 22.2621394],
                [104.6857093, 22.2615345],
                [104.6863964, 22.2614079],
                [104.6887898, 22.2613805],
                [104.6905062, 22.2616717],
                [104.6917405, 22.2626336],
                [104.6924607, 22.2630827],
                [104.6931476, 22.2630521],
                [104.6952087, 22.262704],
                [104.6965479, 22.2628026],
                [104.6978516, 22.2633487],
                [104.6988807, 22.2638624],
                [104.7001685, 22.2640221],
                [104.7020575, 22.2639615],
                [104.7032939, 22.2638998],
                [104.7035685, 22.2639323],
                [104.704427, 22.2639658],
                [104.7052177, 22.2635835],
                [104.705426, 22.2624643],
                [104.7060461, 22.2615378],
                [104.7065961, 22.2612828],
                [104.7084168, 22.2609982],
                [104.7097901, 22.2611288],
                [104.7121245, 22.2614847],
                [104.7131057, 22.2615824],
                [104.7141013, 22.2617121],
                [104.7152007, 22.2614901],
                [104.7164719, 22.2611724],
                [104.7172625, 22.2607581],
                [104.7181569, 22.2600238],
                [104.7188801, 22.2590015],
                [104.7194309, 22.2583307],
                [104.7198092, 22.2580435],
                [104.7204617, 22.2579807],
                [104.7214578, 22.2579184],
                [104.7223171, 22.2574721],
                [104.7233156, 22.2561303],
                [104.7239632, 22.2549592],
                [104.7241017, 22.2543837],
                [104.7235875, 22.2539029],
                [104.7229365, 22.2531342],
                [104.7226638, 22.2521421],
                [104.7229426, 22.2500954],
                [104.7232081, 22.2490226],
                [104.7232359, 22.2489118],
                [104.7233926, 22.248273],
                [104.7234291, 22.2471214],
                [104.7237398, 22.2461472],
                [104.7242554, 22.2458922],
                [104.7251138, 22.2459257],
                [104.7259384, 22.2456392],
                [104.7263168, 22.2452879],
                [104.7266961, 22.2444569],
                [104.7267316, 22.2438812],
                [104.7267328, 22.2433055],
                [104.7268365, 22.2429218],
                [104.7274559, 22.2422191],
                [104.7275945, 22.2415156],
                [104.7276999, 22.2403003],
                [104.7279075, 22.239469],
                [104.7286646, 22.2386066],
                [104.7290311, 22.238241],
                [104.7292378, 22.2378575],
                [104.7292732, 22.2373458],
                [104.7293432, 22.2366423],
                [104.7295854, 22.235651],
                [104.7296898, 22.2349475],
                [104.7296907, 22.2345317],
                [104.7291768, 22.233923],
                [104.7290413, 22.2329632],
                [104.7290881, 22.2318272],
                [104.7290549, 22.2311874],
                [104.7288746, 22.230404],
                [104.7286011, 22.2297637],
                [104.7283651, 22.2293079],
                [104.7289463, 22.2287727],
                [104.7294976, 22.227782],
                [104.7297737, 22.2265226],
                [104.7297757, 22.225467],
                [104.7289886, 22.2241222],
                [104.7278932, 22.222489],
                [104.7268311, 22.2213997],
                [104.7249454, 22.220085],
                [104.7236437, 22.2186113],
                [104.722307, 22.2174896],
                [104.721313, 22.2167201],
                [104.7207065, 22.2156507],
                [104.7205365, 22.2147868],
                [104.7205373, 22.2144029],
                [104.7214326, 22.212997],
                [104.7222937, 22.2115591],
                [104.7227085, 22.2101204],
                [104.7228575, 22.2098863],
                [104.7231561, 22.2094173],
                [104.7240851, 22.2083314],
                [104.7240866, 22.2075317],
                [104.7237461, 22.2061876],
                [104.7231992, 22.2051888],
                [104.7232013, 22.2041652],
                [104.723479, 22.2025983],
                [104.7242741, 22.1997207],
                [104.7249287, 22.1984743],
                [104.7254103, 22.1979634],
                [104.7263032, 22.197709],
                [104.727334, 22.1971989],
                [104.7286053, 22.1964973],
                [104.7296702, 22.1961151],
                [104.7311118, 22.1960856],
                [104.731867, 22.1960549],
                [104.7329297, 22.1968243],
                [104.7339576, 22.1977537],
                [104.7341636, 22.197754],
                [104.7343013, 22.1975303],
                [104.7343019, 22.1972105],
                [104.7342008, 22.1961866],
                [104.7344086, 22.1952593],
                [104.7349931, 22.1947486],
                [104.7363673, 22.194079],
                [104.7380166, 22.1931221],
                [104.7383505, 22.1924931],
                [104.738661, 22.191598],
                [104.7387667, 22.1901267],
                [104.7392494, 22.188944],
                [104.7394915, 22.1879528],
                [104.7399396, 22.1869298],
                [104.7409029, 22.185716],
                [104.7416159, 22.1853503],
                [104.7423022, 22.1853515],
                [104.7441545, 22.186026],
                [104.745972, 22.1868926],
                [104.7465554, 22.1869254],
                [104.7473799, 22.1865428],
                [104.7483415, 22.1861605],
                [104.7493031, 22.1859061],
                [104.7512335, 22.1855489],
                [104.7532502, 22.1854503],
                [104.7541496, 22.1858238],
                [104.7545982, 22.1856325],
                [104.7550853, 22.1854735],
                [104.7565388, 22.1827675],
                [104.7572265, 22.1813581],
                [104.7572561, 22.1807194],
                [104.7571433, 22.179921],
                [104.7563749, 22.1784143],
                [104.7558921, 22.1769233],
                [104.7557807, 22.1752998],
                [104.7558678, 22.1744217],
                [104.756126, 22.1737567],
                [104.7574709, 22.1721355],
                [104.758701, 22.1707269],
                [104.7598449, 22.1697173],
                [104.7616894, 22.1681319],
                [104.7628897, 22.1675216],
                [104.7639751, 22.1672838],
                [104.7652031, 22.167179],
                [104.7661168, 22.1671803],
                [104.7675674, 22.1672603],
                [104.7689662, 22.1674751],
                [104.771137, 22.1678444],
                [104.7736574, 22.166544],
                [104.7744974, 22.16612],
                [104.7754411, 22.1658892],
                [104.7773993, 22.1655603],
                [104.7796201, 22.1657687],
                [104.7797304, 22.165779],
                [104.7791913, 22.1692415],
                [104.7795409, 22.1709594],
                [104.7820823, 22.1721448],
                [104.7831826, 22.1722263],
                [104.784223, 22.1726533],
                [104.7855365, 22.172655],
                [104.7873356, 22.1725242],
                [104.7887065, 22.1723399],
                [104.7898771, 22.1724478],
                [104.7910977, 22.1723967],
                [104.7931533, 22.172559],
                [104.7943526, 22.1725339],
                [104.7953239, 22.1722956],
                [104.7966953, 22.1716587],
                [104.7986101, 22.1704901],
                [104.799353, 22.1701184],
                [104.7997243, 22.1700923],
                [104.8002664, 22.1703324],
                [104.8018356, 22.1712126],
                [104.8028629, 22.1717194],
                [104.8035766, 22.1718267],
                [104.8051588, 22.1717487],
                [104.806729, 22.171857],
                [104.8076712, 22.1719646],
                [104.8088699, 22.1724184],
                [104.8100972, 22.1727391],
                [104.8107825, 22.1727399],
                [104.8114109, 22.1726608],
                [104.8122389, 22.1726352],
                [104.8130097, 22.1727957],
                [104.8135231, 22.1732221],
                [104.8138936, 22.1737814],
                [104.8141209, 22.1746067],
                [104.8143894, 22.1760775],
                [104.8146452, 22.1769294],
                [104.8152155, 22.1775687],
                [104.8158147, 22.1778887],
                [104.8169282, 22.1780497],
                [104.8174705, 22.1781834],
                [104.8181265, 22.1788493],
                [104.8188675, 22.1798881],
                [104.819095, 22.1807133],
                [104.8190651, 22.1817777],
                [104.8190632, 22.1832947],
                [104.8188907, 22.1840929],
                [104.81769, 22.1851825],
                [104.8172895, 22.1857143],
                [104.8193749, 22.1851844],
                [104.8202319, 22.1848926],
                [104.8208322, 22.1844409],
                [104.8214611, 22.1839093],
                [104.8222323, 22.1837239],
                [104.8230606, 22.1835917],
                [104.8234891, 22.1834592],
                [104.824318, 22.1828745],
                [104.8255338, 22.1821062],
                [104.8269336, 22.1816287],
                [104.8277052, 22.1811505],
                [104.8287056, 22.1803532],
                [104.8293347, 22.1796619],
                [104.8297073, 22.1784648],
                [104.8298227, 22.1775069],
                [104.8301084, 22.1773474],
                [104.8303083, 22.1774541],
                [104.830479, 22.1779334],
                [104.8309356, 22.1782265],
                [104.8322196, 22.1790262],
                [104.8333608, 22.1798791],
                [104.834274, 22.1803056],
                [104.834845, 22.180466],
                [104.8360732, 22.1801478],
                [104.8371873, 22.1797498],
                [104.838444, 22.1796446],
                [104.8385483, 22.1796599],
                [104.8393575, 22.1797786],
                [104.8399281, 22.1802049],
                [104.8407705, 22.1816325],
                [104.8413123, 22.1822451],
                [104.8416836, 22.1823254],
                [104.8426544, 22.182406],
                [104.843425, 22.1827261],
                [104.8443097, 22.1832592],
                [104.8448515, 22.1840049],
                [104.8457645, 22.1847509],
                [104.8460783, 22.1849641],
                [104.8467636, 22.1850179],
                [104.8481914, 22.1850725],
                [104.8489613, 22.1851529],
                [104.8490065, 22.1851806],
                [104.8493365, 22.1854549],
                [104.84959, 22.1856593],
                [104.8511026, 22.1865122],
                [104.8518728, 22.1873645],
                [104.853204, 22.1886818],
                [104.8546023, 22.1896411],
                [104.8549729, 22.1902535],
                [104.855115, 22.1909455],
                [104.8551428, 22.1916374],
                [104.8551127, 22.193181],
                [104.8552542, 22.1944585],
                [104.8557094, 22.1960822],
                [104.8566411, 22.1978899],
                [104.8570402, 22.1985556],
                [104.8572973, 22.1985558],
                [104.858012, 22.1978379],
                [104.8588121, 22.1973862],
                [104.859755, 22.196908],
                [104.8600693, 22.196722],
                [104.8606118, 22.1969087],
                [104.8610968, 22.1973349],
                [104.8618098, 22.1984],
                [104.8628654, 22.199492],
                [104.8633789, 22.2000247],
                [104.8637493, 22.2010362],
                [104.8642907, 22.2023673],
                [104.8667947, 22.2019467],
                [104.8683942, 22.2017351],
                [104.8700795, 22.2015768],
                [104.8711935, 22.2013116],
                [104.8725646, 22.2010465],
                [104.8735643, 22.2009142],
                [104.8746496, 22.2009417],
                [104.8753348, 22.2011285],
                [104.8756208, 22.2007562],
                [104.8759852, 22.2002356],
                [104.8764215, 22.1996124],
                [104.8765649, 22.1989739],
                [104.8765654, 22.1983884],
                [104.8762804, 22.1977495],
                [104.8764237, 22.1971109],
                [104.8762812, 22.196818],
                [104.8758528, 22.1967379],
                [104.8749104, 22.1966307],
                [104.8745394, 22.1964176],
                [104.8744253, 22.1961779],
                [104.8744828, 22.1957788],
                [104.8747976, 22.1950073],
                [104.8751984, 22.1939698],
                [104.875713, 22.193358],
                [104.8763703, 22.1927731],
                [104.8767992, 22.1922944],
                [104.8769709, 22.1918954],
                [104.8768238, 22.1912454],
                [104.8768816, 22.1904471],
                [104.877082, 22.1898618],
                [104.8777679, 22.1892236],
                [104.8785969, 22.1882661],
                [104.878969, 22.1873084],
                [104.8789412, 22.1863238],
                [104.8791699, 22.1860312],
                [104.8795126, 22.1860581],
                [104.8804551, 22.1859523],
                [104.881055, 22.1856068],
                [104.8817124, 22.184942],
                [104.8820703, 22.1846752],
                [104.8825845, 22.1845425],
                [104.8830982, 22.1848622],
                [104.8837257, 22.1857941],
                [104.8841537, 22.1861935],
                [104.8845248, 22.1863801],
                [104.8849531, 22.1863804],
                [104.8852673, 22.1862741],
                [104.8855247, 22.1858485],
                [104.8861249, 22.1851571],
                [104.8864678, 22.1849976],
                [104.8869532, 22.1849979],
                [104.8874956, 22.1852911],
                [104.8882948, 22.1858239],
                [104.8886944, 22.1860371],
                [104.8890942, 22.1860639],
                [104.8894941, 22.1859311],
                [104.8904941, 22.1852665],
                [104.891094, 22.1849476],
                [104.8917512, 22.1844424],
                [104.8923799, 22.1837775],
                [104.8930087, 22.1830327],
                [104.8933801, 22.1828201],
                [104.89358, 22.1828469],
                [104.8938083, 22.1830599],
                [104.8952897, 22.1844907],
                [104.8966308, 22.1858754],
                [104.8968874, 22.1864078],
                [104.8968014, 22.1868336],
                [104.8966013, 22.1871527],
                [104.8960296, 22.1878976],
                [104.8958006, 22.1886425],
                [104.8955144, 22.1894407],
                [104.8952572, 22.1897599],
                [104.8948002, 22.1898128],
                [104.8946574, 22.189866],
                [104.8946573, 22.189999],
                [104.8948855, 22.1903717],
                [104.8953392, 22.1911357],
                [104.8960523, 22.1922539],
                [104.8966763, 22.1947207],
                [104.8972935, 22.1963881],
                [104.8980166, 22.1974094],
                [104.8983586, 22.1984741],
                [104.8989576, 22.1994591],
                [104.899043, 22.1998051],
                [104.8989568, 22.2005501],
                [104.8990703, 22.2016147],
                [104.8993267, 22.2025196],
                [104.8999225, 22.2036834],
                [104.9005216, 22.2046152],
                [104.9008641, 22.2049081],
                [104.9015209, 22.2051214],
                [104.9031202, 22.205149],
                [104.9038057, 22.2050696],
                [104.9040056, 22.2051761],
                [104.9040054, 22.2054955],
                [104.9038859, 22.2064155],
                [104.9037422, 22.2076928],
                [104.9037133, 22.2081984],
                [104.9037702, 22.2084912],
                [104.9040842, 22.2087575],
                [104.9041412, 22.2089971],
                [104.9040553, 22.2092897],
                [104.9035409, 22.2096087],
                [104.9031979, 22.2101142],
                [104.9031117, 22.2107795],
                [104.9032257, 22.2111787],
                [104.9041392, 22.2118179],
                [104.9045959, 22.2122972],
                [104.9048811, 22.2129095],
                [104.904995, 22.2134684],
                [104.9051661, 22.2137878],
                [104.905366, 22.2138411],
                [104.9055089, 22.2138146],
                [104.9061661, 22.2133094],
                [104.9072806, 22.2125116],
                [104.907995, 22.2119798],
                [104.9089379, 22.2114747],
                [104.9094808, 22.2111823],
                [104.9100806, 22.2112093],
                [104.9106517, 22.2112894],
                [104.91108, 22.2115558],
                [104.9114511, 22.2118753],
                [104.9117364, 22.2124609],
                [104.9120499, 22.2134457],
                [104.9122781, 22.2139781],
                [104.9130441, 22.2148146],
                [104.9136721, 22.2155866],
                [104.9139574, 22.2160658],
                [104.9139572, 22.2164117],
                [104.9138713, 22.2168108],
                [104.9134418, 22.2185404],
                [104.9130983, 22.2195781],
                [104.912498, 22.2204294],
                [104.911955, 22.2208017],
                [104.9117835, 22.221041],
                [104.9117548, 22.2212805],
                [104.9119817, 22.2220676],
                [104.9123524, 22.2231323],
                [104.9132078, 22.2252016],
                [104.9134075, 22.2257074],
                [104.91335, 22.2262928],
                [104.9130927, 22.226612],
                [104.912807, 22.2267183],
                [104.9124928, 22.2266915],
                [104.9116931, 22.2265314],
                [104.911436, 22.2265312],
                [104.9112645, 22.2266908],
                [104.9111501, 22.2269303],
                [104.9112639, 22.2275691],
                [104.9112351, 22.2280747],
                [104.9113487, 22.2289478],
                [104.9118051, 22.2299327],
                [104.9121478, 22.2301724],
                [104.9124047, 22.2303588],
                [104.9123474, 22.2307048],
                [104.9120044, 22.2310239],
                [104.9114043, 22.2314493],
                [104.9113754, 22.2318751],
                [104.9117178, 22.232514],
                [104.9122029, 22.2333126],
                [104.9122596, 22.2340312],
                [104.9122594, 22.2344037],
                [104.9122304, 22.2350424],
                [104.911973, 22.2356011],
                [104.9119728, 22.2358406],
                [104.9122582, 22.2362399],
                [104.9130474, 22.2372189],
                [104.9136753, 22.2382837],
                [104.9143034, 22.2388163],
                [104.9151888, 22.2390828],
                [104.9160173, 22.2392164],
                [104.9169314, 22.2392966],
                [104.9173312, 22.2395896],
                [104.9180736, 22.2402021],
                [104.9182274, 22.240756],
                [104.9182731, 22.2409207],
                [104.9181525, 22.2416373],
                [104.9180199, 22.2424253],
                [104.9175616, 22.2442347],
                [104.9171034, 22.2461506],
                [104.9166743, 22.247135],
                [104.9159024, 22.2479862],
                [104.9147307, 22.2486508],
                [104.9123424, 22.2493243],
                [104.9099422, 22.2499084],
                [104.9085985, 22.2512117],
                [104.908141, 22.2519033],
                [104.9079112, 22.253766],
                [104.9078814, 22.2555756],
                [104.9075988, 22.2570524],
                [104.9073697, 22.257824],
                [104.9068835, 22.2585954],
                [104.9055967, 22.2602446],
                [104.9041671, 22.2617341],
                [104.8995358, 22.2652706],
                [104.8983634, 22.2665206],
                [104.8965539, 22.2678035],
                [104.8929582, 22.2692683],
                [104.8922404, 22.2712295],
                [104.8920799, 22.2724558],
                [104.8920223, 22.2730146],
                [104.8921362, 22.2735736],
                [104.8924217, 22.2738931],
                [104.8930785, 22.2744258],
                [104.8937924, 22.2749851],
                [104.894792, 22.2757308],
                [104.8954774, 22.2761837],
                [104.8961232, 22.2763704],
                [104.8972661, 22.2764775],
                [104.8984949, 22.2763719],
                [104.8994096, 22.2761329],
                [104.9009531, 22.275442],
                [104.9024965, 22.2749905],
                [104.9042968, 22.2748585],
                [104.9051254, 22.2748856],
                [104.9058681, 22.2752054],
                [104.9063508, 22.2757815],
                [104.9072922, 22.2780972],
                [104.9076342, 22.2795078],
                [104.9080054, 22.2800137],
                [104.9086052, 22.2805995],
                [104.9092426, 22.2809898],
                [104.9095475, 22.2811765],
                [104.9108041, 22.2822416],
                [104.9113182, 22.2827476],
                [104.9113463, 22.2834128],
                [104.9111456, 22.2844772],
                [104.9111447, 22.2859941],
                [104.9113726, 22.2870054],
                [104.9119683, 22.2880153],
                [104.9120007, 22.2880702],
                [104.9126003, 22.2888689],
                [104.913333, 22.2894973],
                [104.9143328, 22.2902429],
                [104.9150756, 22.2906159],
                [104.9156471, 22.2906429],
                [104.9164761, 22.2902973],
                [104.9185627, 22.2895],
                [104.9197918, 22.2892345],
                [104.9208491, 22.2893148],
                [104.9214776, 22.2896079],
                [104.9218203, 22.290087],
                [104.9219914, 22.2907791],
                [104.9218195, 22.2914975],
                [104.9214762, 22.2921626],
                [104.9206755, 22.2930936],
                [104.9191317, 22.2941574],
                [104.9174734, 22.2951411],
                [104.9163873, 22.2958719],
                [104.9156724, 22.29659],
                [104.9153861, 22.2973084],
                [104.9153, 22.2979737],
                [104.9153569, 22.2983729],
                [104.9155852, 22.2988786],
                [104.9164705, 22.3001298],
                [104.9176696, 22.3021263],
                [104.9180977, 22.3031643],
                [104.918126, 22.30367],
                [104.9178397, 22.3045481],
                [104.9172462, 22.3055423],
                [104.9165026, 22.3063669],
                [104.9153018, 22.3069783],
                [104.9136433, 22.3081749],
                [104.9128423, 22.3094253],
                [104.9122125, 22.310995],
                [104.9116974, 22.3119261],
                [104.9111699, 22.3125011],
                [104.9100261, 22.3132989],
                [104.9092828, 22.3134847],
                [104.9083969, 22.313298],
                [104.9070822, 22.3130843],
                [104.9063104, 22.3131903],
                [104.9056528, 22.3134826],
                [104.9033367, 22.3147853],
                [104.9017069, 22.3155294],
                [104.9007921, 22.3157152],
                [104.9007122, 22.3157123],
                [104.8993059, 22.315661],
                [104.8980768, 22.315607],
                [104.8974193, 22.3157929],
                [104.8968187, 22.3162715],
                [104.8963893, 22.3171228],
                [104.8962457, 22.3181074],
                [104.895959, 22.3193047],
                [104.8954439, 22.3200229],
                [104.8939878, 22.321146],
                [104.892243, 22.3226608],
                [104.8918423, 22.3233254],
                [104.8916704, 22.3238838],
                [104.8916699, 22.3244689],
                [104.892041, 22.3251606],
                [104.8931266, 22.3260123],
                [104.8944981, 22.3267313],
                [104.8961553, 22.3278759],
                [104.897012, 22.3289669],
                [104.8974685, 22.330164],
                [104.8976106, 22.3313342],
                [104.8975529, 22.3320257],
                [104.8968427, 22.3332274],
                [104.8966977, 22.3344173],
                [104.8964461, 22.3359487],
                [104.8965169, 22.3369809],
                [104.8964803, 22.3380131],
                [104.8966942, 22.3390787],
                [104.8972305, 22.3399114],
                [104.8979459, 22.3405778],
                [104.8989116, 22.3414441],
                [104.8995553, 22.3423101],
                [104.9001628, 22.3435092],
                [104.900484, 22.3448412],
                [104.9005547, 22.3460732],
                [104.9003743, 22.3481374],
                [104.900122, 22.3493309],
                [104.9054544, 22.3505668],
                [104.9070651, 22.3507677],
                [104.9075662, 22.350768],
                [104.9078169, 22.3505349],
                [104.9087128, 22.3487695],
                [104.9093935, 22.3478703],
                [104.9101817, 22.3468379],
                [104.9106833, 22.3459719],
                [104.9109702, 22.3451391],
                [104.9108993, 22.3441061],
                [104.9108639, 22.3434397],
                [104.9110078, 22.3422737],
                [104.9112939, 22.3412738],
                [104.9117599, 22.3404416],
                [104.9123687, 22.3399757],
                [104.913658, 22.3390772],
                [104.9144817, 22.3385115],
                [104.9148401, 22.3378124],
                [104.9149839, 22.3368467],
                [104.9151273, 22.3363472],
                [104.9152349, 22.3362141],
                [104.9157003, 22.3361144],
                [104.9168817, 22.3359152],
                [104.9176696, 22.3351496],
                [104.9186011, 22.3338513],
                [104.9193173, 22.3334188],
                [104.9201049, 22.3332859],
                [104.9209585, 22.3327957],
                [104.9217264, 22.3325919],
                [104.9235361, 22.3326949],
                [104.9256474, 22.333053],
                [104.9268141, 22.3332551],
                [104.928464, 22.3332546],
                [104.9292396, 22.3329271],
                [104.9307755, 22.3323155],
                [104.9321741, 22.3320865],
                [104.932887, 22.3321888],
                [104.9340658, 22.332725],
                [104.9347239, 22.3329038],
                [104.9356836, 22.3327767],
                [104.9366159, 22.3329301],
                [104.9379481, 22.3332512],
                [104.9386959, 22.3332509],
                [104.9391112, 22.3329055],
                [104.939852, 22.3319619],
                [104.9407687, 22.3300037],
                [104.9414275, 22.3284724],
                [104.9418938, 22.3279365],
                [104.9426345, 22.3271455],
                [104.9439784, 22.3264057],
                [104.9453222, 22.3256914],
                [104.9459531, 22.3252065],
                [104.9463646, 22.3245685],
                [104.94727, 22.3231394],
                [104.9479222, 22.3225351],
                [104.9495951, 22.3215657],
                [104.950747, 22.3208769],
                [104.9514325, 22.3207239],
                [104.9522276, 22.3207497],
                [104.9545308, 22.320878],
                [104.9550244, 22.3207759],
                [104.9557922, 22.3203933],
                [104.9576295, 22.3193727],
                [104.9587812, 22.3190666],
                [104.9605635, 22.3188884],
                [104.9613689, 22.3187677],
                [104.96348, 22.3190999],
                [104.9656186, 22.3192791],
                [104.9677024, 22.3194072],
                [104.968854, 22.3194329],
                [104.9694846, 22.3194586],
                [104.9710374, 22.3199158],
                [104.9738887, 22.3212181],
                [104.9762465, 22.3226225],
                [104.9770963, 22.32298],
                [104.9784946, 22.3237715],
                [104.9792623, 22.3243332],
                [104.979838, 22.3251245],
                [104.9800574, 22.3252522],
                [104.9803041, 22.3252012],
                [104.9809897, 22.3247163],
                [104.9815509, 22.3246313],
                [104.9821687, 22.3245377],
                [104.9833478, 22.3241295],
                [104.984143, 22.3235424],
                [104.9846915, 22.3229043],
                [104.9848561, 22.3222917],
                [104.9848835, 22.3218067],
                [104.9848562, 22.3214493],
                [104.9847191, 22.3211175],
                [104.9847192, 22.3207856],
                [104.9852128, 22.3203772],
                [104.9863918, 22.3196626],
                [104.9868854, 22.3190755],
                [104.9872626, 22.3179544],
                [104.9876191, 22.3170355],
                [104.9881127, 22.3164739],
                [104.988853, 22.3162187],
                [104.9904159, 22.3161422],
                [104.9912657, 22.3163975],
                [104.9917594, 22.3161678],
                [104.9926527, 22.315513],
                [104.9932559, 22.3151047],
                [104.9935301, 22.3147217],
                [104.9936671, 22.3141346],
                [104.9938043, 22.3131391],
                [104.9936399, 22.3122457],
                [104.9936673, 22.3118372],
                [104.9942705, 22.3111225],
                [104.9944624, 22.3105865],
                [104.9944076, 22.3101015],
                [104.993829, 22.3090401],
                [104.9936645, 22.308453],
                [104.9936371, 22.3079935],
                [104.9938291, 22.307534],
                [104.9946789, 22.3067938],
                [104.9955015, 22.3062578],
                [104.9956934, 22.3059004],
                [104.9957756, 22.3053388],
                [104.9957208, 22.3042412],
                [104.9957483, 22.3031946],
                [104.9960173, 22.3023381],
                [104.9962914, 22.3020317],
                [104.996593, 22.3018786],
                [104.9975799, 22.3018275],
                [104.998704, 22.3014446],
                [104.9995812, 22.3009341],
                [104.9999376, 22.3003981],
                [105.0002923, 22.3000935],
                [105.0003925, 22.2995805],
                [105.0005178, 22.2994872],
                [105.0007183, 22.2997437],
                [105.0013697, 22.3001401],
                [105.0016853, 22.3003313],
                [105.0018976, 22.3003602],
                [105.0042776, 22.3003686],
                [105.0069242, 22.2988427],
                [105.0073475, 22.2977937],
                [105.0085072, 22.2955553],
                [105.0085069, 22.2912281],
                [105.0062488, 22.2874256],
                [105.0052609, 22.2861144],
                [105.0048375, 22.284672],
                [105.0049786, 22.2840164],
                [105.0051197, 22.2837542],
                [105.006813, 22.2833607],
                [105.008083, 22.2821805],
                [105.0082241, 22.2819183],
                [105.0079417, 22.2775912],
                [105.0082238, 22.2760176],
                [105.0100579, 22.270248],
                [105.0105488, 22.2674418],
                [105.0109773, 22.2674807],
                [105.0130153, 22.2674513],
                [105.0149908, 22.2676848],
                [105.015712, 22.2679183],
                [105.0164332, 22.2685023],
                [105.0172799, 22.2693782],
                [105.0180953, 22.2706922],
                [105.0186912, 22.2721814],
                [105.0189422, 22.2728238],
                [105.0198204, 22.2741086],
                [105.020134, 22.2749554],
                [105.0206673, 22.2761525],
                [105.0211064, 22.2765906],
                [105.0215767, 22.2768825],
                [105.0233015, 22.2776415],
                [105.0256535, 22.27878],
                [105.0268139, 22.2793638],
                [105.027347, 22.2797726],
                [105.0281127, 22.2806375],
                [105.0287714, 22.2813966],
                [105.0296496, 22.2823893],
                [105.0304023, 22.2829147],
                [105.0316568, 22.2835278],
                [105.0326918, 22.2840823],
                [105.0339149, 22.284929],
                [105.0344167, 22.2851041],
                [105.035577, 22.2852206],
                [105.036706, 22.2852496],
                [105.0375527, 22.2854538],
                [105.0389013, 22.2860083],
                [105.0419616, 22.2871326],
                [105.0428711, 22.2873661],
                [105.043561, 22.2873951],
                [105.0448153, 22.2873071],
                [105.0457248, 22.2874236],
                [105.0466657, 22.2877154],
                [105.0481086, 22.2887078],
                [105.0485821, 22.2893003],
                [105.0487431, 22.2897867],
                [105.0487433, 22.2902358],
                [105.048825, 22.2909052],
                [105.0488045, 22.2916686],
                [105.0488337, 22.2922868],
                [105.0493239, 22.2932006],
                [105.0494238, 22.2940818],
                [105.0494741, 22.2953803],
                [105.0492084, 22.2978843],
                [105.0489895, 22.2997532],
                [105.0489586, 22.3011841],
                [105.0488334, 22.3018266],
                [105.0483633, 22.3027903],
                [105.0480499, 22.3034036],
                [105.0477992, 22.3039586],
                [105.0474544, 22.3044259],
                [105.0469213, 22.304864],
                [105.0461116, 22.305199],
                [105.045174, 22.305704],
                [105.0445266, 22.3059825],
                [105.0441359, 22.3061341],
                [105.0440106, 22.3064845],
                [105.0441048, 22.3068056],
                [105.044544, 22.307156],
                [105.0455165, 22.3077981],
                [105.0457674, 22.3080317],
                [105.0458617, 22.3082944],
                [105.0457363, 22.3086157],
                [105.0455169, 22.3091122],
                [105.0453289, 22.3096963],
                [105.0452979, 22.3106599],
                [105.0452981, 22.3114192],
                [105.0455178, 22.3119447],
                [105.0459257, 22.3123534],
                [105.0466988, 22.3127256],
                [105.0484869, 22.3132507],
                [105.0498671, 22.31363],
                [105.050463, 22.3136589],
                [105.0510276, 22.3136003],
                [105.0520311, 22.3132788],
                [105.0526897, 22.3131034],
                [105.0538816, 22.3129862],
                [105.0567044, 22.3128976],
                [105.0580531, 22.3128388],
                [105.058649, 22.3128093],
                [105.0599873, 22.3127605],
                [105.0600188, 22.3129649],
                [105.0594647, 22.3133639],
                [105.0591513, 22.3138604],
                [105.0589948, 22.3147657],
                [105.0589327, 22.3161382],
                [105.0589643, 22.3167222],
                [105.0591527, 22.3171601],
                [105.0595922, 22.3180652],
                [105.0596551, 22.31862],
                [105.0594988, 22.3196129],
                [105.0592502, 22.3213162],
                [105.0591565, 22.3222506],
                [105.0592195, 22.3227762],
                [105.0593451, 22.3232726],
                [105.0597218, 22.3239149],
                [105.0603181, 22.3245863],
                [105.0605379, 22.3251995],
                [105.0606323, 22.3258418],
                [105.0605073, 22.3268931],
                [105.0601629, 22.3284409],
                [105.060163, 22.3288498],
                [105.0604142, 22.3293168],
                [105.0609477, 22.3299591],
                [105.061042, 22.3303095],
                [105.0609579, 22.3305875],
                [105.0607286, 22.3308352],
                [105.0600995, 22.3314162],
                [105.0597181, 22.3318897],
                [105.0593177, 22.332471],
                [105.0587646, 22.3332149],
                [105.058272, 22.3336195],
                [105.0571544, 22.3342563],
                [105.0554158, 22.3346107],
                [105.0535505, 22.334982],
                [105.0523916, 22.3354037],
                [105.0518664, 22.3358587],
                [105.0508911, 22.3371387],
                [105.0504811, 22.3378717],
                [105.0502193, 22.3385215],
                [105.0503102, 22.3394818],
                [105.0503796, 22.3405565],
                [105.0501506, 22.3414248],
                [105.0495897, 22.3430604],
                [105.0491263, 22.3441448],
                [105.0487794, 22.3446725],
                [105.0477699, 22.3457573],
                [105.0473282, 22.3463437],
                [105.0466658, 22.347575],
                [105.0462958, 22.3483024],
                [105.0453883, 22.3496661],
                [105.0445293, 22.3500304],
                [105.0428629, 22.3504017],
                [105.0416171, 22.3504782],
                [105.0412068, 22.3506249],
                [105.0408707, 22.3510597],
                [105.04086, 22.3515922],
                [105.0409548, 22.352237],
                [105.0411128, 22.3527939],
                [105.0414323, 22.3532303],
                [105.0423019, 22.3536516],
                [105.0434976, 22.354292],
                [105.044032, 22.3548091],
                [105.0445538, 22.3553139],
                [105.0457304, 22.3566489],
                [105.0461324, 22.3569841],
                [105.0467322, 22.3572771],
                [105.0474267, 22.3574527],
                [105.0484051, 22.3574818],
                [105.0500145, 22.3570123],
                [105.0520975, 22.3563375],
                [105.0538017, 22.3561904],
                [105.0548118, 22.356278],
                [105.0559166, 22.3567758],
                [105.0575579, 22.3575028],
                [105.0583933, 22.3569961],
                [105.0593352, 22.3566923],
                [105.0611242, 22.3563636],
                [105.0636805, 22.3560109],
                [105.0667503, 22.3553249],
                [105.0687787, 22.3546328],
                [105.0703362, 22.354059],
                [105.0718701, 22.3533177],
                [105.072643, 22.3538967],
                [105.0736847, 22.3541894],
                [105.0741266, 22.3541598],
                [105.0748839, 22.3538077],
                [105.0757987, 22.3529573],
                [105.0763557, 22.3523054],
                [105.0767903, 22.3522209],
                [105.0784206, 22.3523887],
                [105.0805957, 22.3523688],
                [105.0816373, 22.3526613],
                [105.0824582, 22.3531299],
                [105.0833413, 22.3539762],
                [105.0861539, 22.3579937],
                [105.0870385, 22.3594588],
                [105.0870077, 22.3606313],
                [105.0867874, 22.3615694],
                [105.0863174, 22.3626679],
                [105.0855934, 22.3637806],
                [105.0846519, 22.3649439],
                [105.0836383, 22.3666802],
                [105.0832402, 22.3673713],
                [105.0829148, 22.3687365],
                [105.0828245, 22.3692927],
                [105.0820463, 22.3707929],
                [105.0803083, 22.3733215],
                [105.0787769, 22.3733274],
                [105.0785249, 22.3742361],
                [105.0782413, 22.3750863],
                [105.0779574, 22.3755261],
                [105.07679, 22.3764354],
                [105.076222, 22.376846],
                [105.0758437, 22.3777548],
                [105.0755917, 22.3787515],
                [105.0752451, 22.3798948],
                [105.0745512, 22.3810383],
                [105.0734784, 22.3818888],
                [105.0728788, 22.3823288],
                [105.0726581, 22.3828565],
                [105.0727215, 22.3834427],
                [105.0729112, 22.3838823],
                [105.0731641, 22.3846443],
                [105.0733222, 22.3851132],
                [105.0733541, 22.3857581],
                [105.0736195, 22.3874314],
                [105.0744453, 22.387817],
                [105.0750617, 22.3882549],
                [105.0755657, 22.3890106],
                [105.0757554, 22.3895381],
                [105.0757556, 22.3899484],
                [105.0753762, 22.3917114],
                [105.0753379, 22.3924683],
                [105.0753963, 22.3930808],
                [105.0755323, 22.3936574],
                [105.0761336, 22.3949184],
                [105.0771794, 22.3956928],
                [105.0775898, 22.3957219],
                [105.0788842, 22.3957213],
                [105.080305, 22.3958964],
                [105.0809997, 22.396072],
                [105.0815365, 22.3963063],
                [105.0825784, 22.3963936],
                [105.0829888, 22.3962762],
                [105.084188, 22.3955135],
                [105.085202, 22.395148],
                [105.0866501, 22.394838],
                [105.0884378, 22.394894],
                [105.0897757, 22.3948949],
                [105.0912871, 22.3963159],
                [105.0918495, 22.3970904],
                [105.0921548, 22.3977919],
                [105.0923763, 22.3986125],
                [105.0924452, 22.3992825],
                [105.0922566, 22.4004257],
                [105.0921041, 22.4011444],
                [105.0921044, 22.401649],
                [105.0922113, 22.4019869],
                [105.0927483, 22.4023969],
                [105.0940116, 22.4030411],
                [105.095303, 22.403557],
                [105.0964118, 22.4040069],
                [105.0966015, 22.4043878],
                [105.0965702, 22.4048568],
                [105.096564, 22.4056645],
                [105.0967193, 22.4060788],
                [105.0981742, 22.4082041],
                [105.0986015, 22.4096453],
                [105.099242, 22.4109963],
                [105.0994947, 22.4120953],
                [105.0995534, 22.41287],
                [105.0997863, 22.4133203],
                [105.1006588, 22.4140766],
                [105.1009499, 22.4145989],
                [105.1009504, 22.4152476],
                [105.1007379, 22.4161847],
                [105.1005642, 22.4171398],
                [105.1001773, 22.4182211],
                [105.0999258, 22.4187798],
                [105.099868, 22.4193024],
                [105.0999659, 22.4205997],
                [105.0998695, 22.4213204],
                [105.0986886, 22.4231411],
                [105.0977204, 22.4244029],
                [105.0973142, 22.4255203],
                [105.09685, 22.4268179],
                [105.0965229, 22.4276904],
                [105.0962398, 22.4293614],
                [105.0959559, 22.4298305],
                [105.0949533, 22.430765],
                [105.0930204, 22.4316202],
                [105.0908738, 22.4328818],
                [105.0901794, 22.4333805],
                [105.0893273, 22.4342897],
                [105.0885798, 22.4355436],
                [105.0879701, 22.4369581],
                [105.0869619, 22.4392738],
                [105.0887309, 22.4399177],
                [105.0918265, 22.4409419],
                [105.0935326, 22.4421134],
                [105.0948282, 22.4431092],
                [105.0963447, 22.4438997],
                [105.0973239, 22.4442215],
                [105.098682, 22.4443086],
                [105.0998506, 22.4444545],
                [105.100836, 22.4447437],
                [105.1012822, 22.445194],
                [105.1025044, 22.4465265],
                [105.1036682, 22.4476789],
                [105.1055079, 22.4499028],
                [105.1060768, 22.4504007],
                [105.1068349, 22.4506053],
                [105.1076562, 22.45081],
                [105.1089514, 22.4511316],
                [105.1100571, 22.4514825],
                [105.1108154, 22.4518045],
                [105.112142, 22.4520087],
                [105.1134691, 22.4526526],
                [105.1150173, 22.4535016],
                [105.116408, 22.4545557],
                [105.1171351, 22.4553466],
                [105.1176094, 22.4560498],
                [105.117768, 22.4568703],
                [105.1178007, 22.4581014],
                [105.1176437, 22.4593325],
                [105.1174414, 22.4602823],
                [105.1173603, 22.4604877],
                [105.117306, 22.4606248],
                [105.1162212, 22.4615625],
                [105.1126882, 22.463469],
                [105.1119938, 22.4641143],
                [105.1115836, 22.4646128],
                [105.111363, 22.4652579],
                [105.1112059, 22.4662253],
                [105.1111436, 22.4673684],
                [105.1112072, 22.4678667],
                [105.111493, 22.4698304],
                [105.1115253, 22.470739],
                [105.1113364, 22.4714719],
                [105.1105159, 22.4724984],
                [105.1099167, 22.4737005],
                [105.1098854, 22.4740815],
                [105.1100441, 22.4750194],
                [105.1102025, 22.4756055],
                [105.1113419, 22.4783893],
                [105.1119755, 22.4805872],
                [105.1122935, 22.4832836],
                [105.1123256, 22.4838112],
                [105.1122312, 22.4843389],
                [105.1116319, 22.4854824],
                [105.1103065, 22.4873299],
                [105.10876, 22.4892362],
                [105.108515, 22.4895081],
                [105.1074012, 22.4907443],
                [105.1074451, 22.491172],
                [105.1075609, 22.491586],
                [105.1078464, 22.4924317],
                [105.1089859, 22.4953034],
                [105.110273, 22.4990288],
                [105.1119004, 22.5002187],
                [105.1124182, 22.500901],
                [105.113045, 22.5016843],
                [105.1136175, 22.5026194],
                [105.1145167, 22.5036807],
                [105.1157922, 22.5056615],
                [105.1169375, 22.507835],
                [105.1180827, 22.5099074],
                [105.1188736, 22.5114238],
                [105.1197418, 22.5130236],
                [105.1204509, 22.5144135],
                [105.1211057, 22.5159553],
                [105.1216787, 22.5173455],
                [105.1221972, 22.5187609],
                [105.1226343, 22.5203028],
                [105.1231772, 22.5219036],
                [105.1237505, 22.523597],
                [105.1242965, 22.52524],
                [105.1247059, 22.5263016],
                [105.1250336, 22.5273632],
                [105.1254958, 22.5288845],
                [105.1260416, 22.5302494],
                [105.1264515, 22.5318166],
                [105.1267246, 22.5327518],
                [105.1274087, 22.5351122],
                [105.1274641, 22.5355776],
                [105.1274647, 22.5361846],
                [105.1273562, 22.5367158],
                [105.1271117, 22.5375001],
                [105.1267315, 22.5387651],
                [105.126514, 22.539271],
                [105.1262695, 22.5399542],
                [105.1258071, 22.5407639],
                [105.125617, 22.541447],
                [105.1255362, 22.5424334],
                [105.1254562, 22.5433251],
                [105.1256208, 22.5446656],
                [105.1257579, 22.5456519],
                [105.1257037, 22.5459554],
                [105.1255678, 22.5463602],
                [105.1251596, 22.5468158],
                [105.1246426, 22.5476002],
                [105.1242073, 22.5482076],
                [105.123881, 22.5489161],
                [105.123582, 22.549751],
                [105.1232828, 22.5502064],
                [105.1227113, 22.5508898],
                [105.1221398, 22.5515984],
                [105.121681, 22.552161],
                [105.1210009, 22.553325],
                [105.120566, 22.5545141],
                [105.1202673, 22.5556778],
                [105.1201861, 22.5562849],
                [105.1202138, 22.5568665],
                [105.1203235, 22.5576252],
                [105.1204333, 22.5585357],
                [105.1206521, 22.5596232],
                [105.1208982, 22.5605588],
                [105.1209801, 22.5613435],
                [105.1211451, 22.5631644],
                [105.1211456, 22.5636956],
                [105.1208464, 22.5643786],
                [105.1205475, 22.5651376],
                [105.1204388, 22.5655677],
                [105.1204122, 22.5663012],
                [105.1203309, 22.5668324],
                [105.1202224, 22.5673384],
                [105.1201136, 22.5676419],
                [105.1198958, 22.5678697],
                [105.1193241, 22.5683507],
                [105.1185616, 22.5688571],
                [105.1182349, 22.5692115],
                [105.1180443, 22.5694645],
                [105.1178564, 22.5701297],
                [105.117258, 22.5713695],
                [105.1165776, 22.5723564],
                [105.1152706, 22.5735714],
                [105.1140452, 22.5745334],
                [105.1138274, 22.5747864],
                [105.1138822, 22.5750141],
                [105.1149735, 22.5758678],
                [105.116146, 22.5768281],
                [105.117046, 22.5777127],
                [105.1174006, 22.5780665],
                [105.1176731, 22.5781674],
                [105.1186269, 22.578192],
                [105.1198534, 22.5784188],
                [105.1207255, 22.5786205],
                [105.1214071, 22.5789993],
                [105.1221707, 22.5796058],
                [105.1225252, 22.5799343],
                [105.1226072, 22.5802125],
                [105.1221628, 22.5806998],
                [105.1219179, 22.5811552],
                [105.1219183, 22.5816105],
                [105.1220551, 22.5821667],
                [105.1223556, 22.5830265],
                [105.1227651, 22.5838861],
                [105.1231744, 22.584417],
                [105.1236925, 22.5848719],
                [105.1242924, 22.5852255],
                [105.1247285, 22.5853769],
                [105.1250828, 22.5853513],
                [105.1256005, 22.5853003],
                [105.1263363, 22.5852745],
                [105.1266634, 22.5854006],
                [105.1270179, 22.5856533],
                [105.1273997, 22.5859565],
                [105.1280269, 22.586386],
                [105.1288449, 22.5868912],
                [105.1293798, 22.5872557],
                [105.1300616, 22.5877609],
                [105.1311521, 22.5882153],
                [105.1321062, 22.5885433],
                [105.1331967, 22.5888966],
                [105.1332023, 22.5889155],
                [105.1332787, 22.5891747],
                [105.1334156, 22.5898322],
                [105.1334981, 22.5906162],
                [105.1336894, 22.5911219],
                [105.1339901, 22.592108],
                [105.1341814, 22.5927402],
                [105.1341545, 22.5930437],
                [105.134073, 22.5932462],
                [105.1338823, 22.5932969],
                [105.1335552, 22.5933477],
                [105.132983, 22.5934241],
                [105.132111, 22.5935513],
                [105.1314298, 22.5936277],
                [105.1307238, 22.5940153],
                [105.130043, 22.5945975],
                [105.1296894, 22.5952554],
                [105.1293089, 22.5964191],
                [105.1290372, 22.59733],
                [105.1288744, 22.5980888],
                [105.1287389, 22.5988983],
                [105.1287123, 22.5996065],
                [105.1285765, 22.6001125],
                [105.1284134, 22.6006184],
                [105.1278147, 22.6015547],
                [105.1274632, 22.6021668],
                [105.1270555, 22.6034317],
                [105.126811, 22.6042918],
                [105.1268115, 22.6048736],
                [105.1269209, 22.6052275],
                [105.1271664, 22.6054803],
                [105.1277664, 22.6058339],
                [105.1289117, 22.60644],
                [105.1297299, 22.6070969],
                [105.1301936, 22.6075265],
                [105.1309577, 22.608487],
                [105.1316126, 22.60922],
                [105.1326478, 22.6099034],
                [105.133322, 22.6103841],
                [105.1339493, 22.6107376],
                [105.1347871, 22.6109828],
                [105.1356048, 22.6110579],
                [105.1361848, 22.6112922],
                [105.1368119, 22.6115446],
                [105.1372684, 22.6119165],
                [105.1377257, 22.6123328],
                [105.1385165, 22.6127622],
                [105.139253, 22.6132167],
                [105.1394713, 22.6135707],
                [105.13969, 22.6141269],
                [105.1398543, 22.6149614],
                [105.1399368, 22.6156442],
                [105.1401015, 22.6167316],
                [105.1404027, 22.6180466],
                [105.1405669, 22.6187041],
                [105.1404857, 22.6192353],
                [105.1404319, 22.6199941],
                [105.1403232, 22.6207305],
                [105.1403511, 22.6213881],
                [105.1406517, 22.6220707],
                [105.141225, 22.6229555],
                [105.1419347, 22.6238654],
                [105.1427264, 22.6250787],
                [105.1430268, 22.6255337],
                [105.1445526, 22.6248494],
                [105.1455608, 22.624545],
                [105.1462692, 22.6242155],
                [105.1466233, 22.6239117],
                [105.14695, 22.6235321],
                [105.1473308, 22.622773],
                [105.147712, 22.6224437],
                [105.1482297, 22.6221904],
                [105.1489168, 22.6220486],
                [105.1495436, 22.6218962],
                [105.1501973, 22.6215163],
                [105.1507147, 22.6209846],
                [105.1514769, 22.6200734],
                [105.1518034, 22.6194913],
                [105.1520753, 22.6188841],
                [105.1523749, 22.6186562],
                [105.1528928, 22.6186303],
                [105.1532199, 22.6186554],
                [105.1537107, 22.6187814],
                [105.154147, 22.6189833],
                [105.1545564, 22.6194381],
                [105.1548841, 22.6199437],
                [105.1556753, 22.6206764],
                [105.1559209, 22.6208026],
                [105.1563844, 22.6209033],
                [105.157257, 22.6212059],
                [105.1581293, 22.6213316],
                [105.1589201, 22.6215332],
                [105.1592745, 22.621634],
                [105.15954, 22.6218797],
                [105.1595747, 22.6219119],
                [105.1596569, 22.6222406],
                [105.1596572, 22.6225441],
                [105.1594397, 22.6229743],
                [105.1591694, 22.6232635],
                [105.1582979, 22.6240231],
                [105.1580259, 22.6245039],
                [105.1580264, 22.6249339],
                [105.1581635, 22.6255914],
                [105.158164, 22.6260213],
                [105.1579736, 22.6264009],
                [105.1572382, 22.6270086],
                [105.1559307, 22.6278193],
                [105.1548139, 22.6285538],
                [105.1538877, 22.6290859],
                [105.1537515, 22.6292378],
                [105.153888, 22.6294147],
                [105.1542154, 22.629642],
                [105.1547609, 22.6298944],
                [105.1556608, 22.6302476],
                [105.1564245, 22.6305503],
                [105.1566974, 22.6308031],
                [105.1568655, 22.631111],
                [105.1568662, 22.6316927],
                [105.1567849, 22.6321227],
                [105.1564857, 22.6327048],
                [105.1560229, 22.6332616],
                [105.1553692, 22.6338187],
                [105.154988, 22.6342238],
                [105.1547432, 22.6347045],
                [105.1544169, 22.6354131],
                [105.1541995, 22.6360709],
                [105.1539274, 22.6365011],
                [105.1533283, 22.637134],
                [105.1525385, 22.6377671],
                [105.1518577, 22.6384758],
                [105.15164, 22.638906],
                [105.1516131, 22.6392095],
                [105.1515864, 22.6396649],
                [105.1514777, 22.6399938],
                [105.1511305, 22.6405037],
                [105.1502859, 22.6410103],
                [105.1494961, 22.6416939],
                [105.1493057, 22.6420735],
                [105.1492243, 22.6425288],
                [105.1491705, 22.6431106],
                [105.1491441, 22.6439959],
                [105.1490086, 22.6446789],
                [105.1485186, 22.6454128],
                [105.1479198, 22.6462986],
                [105.1476749, 22.6467288],
                [105.1476479, 22.6470324],
                [105.1477349, 22.6476759],
                [105.1478991, 22.6481817],
                [105.148118, 22.6489908],
                [105.1481184, 22.6493954],
                [105.1480644, 22.6498002],
                [105.1477923, 22.6503568],
                [105.1470298, 22.6511922],
                [105.1461037, 22.6519518],
                [105.1450409, 22.6524587],
                [105.1441689, 22.6530158],
                [105.1435425, 22.6536234],
                [105.1430249, 22.6540285],
                [105.1425147, 22.6542687],
                [105.1413429, 22.6549021],
                [105.1404167, 22.6556617],
                [105.1400353, 22.6560413],
                [105.1398178, 22.6566739],
                [105.1396277, 22.6574328],
                [105.1395196, 22.6583687],
                [105.1395204, 22.6591781],
                [105.1395237, 22.6597947],
                [105.1397425, 22.6604015],
                [105.1399068, 22.6611096],
                [105.1399348, 22.6618178],
                [105.1399355, 22.6625006],
                [105.1396909, 22.6633607],
                [105.1395552, 22.6639932],
                [105.139283, 22.6643729],
                [105.1390378, 22.6646513],
                [105.1386562, 22.6648033],
                [105.1375111, 22.6648802],
                [105.1365026, 22.6652352],
                [105.1356577, 22.6656911],
                [105.1352491, 22.6661468],
                [105.1350587, 22.6665709],
                [105.134808, 22.6670598],
                [105.1341832, 22.6677037],
                [105.1333606, 22.6680415],
                [105.132472, 22.6681034],
                [105.1319783, 22.6681958],
                [105.1314519, 22.6684414],
                [105.1308253, 22.6688513],
                [105.1304653, 22.6694532],
                [105.1304, 22.669974],
                [105.1306968, 22.6706172],
                [105.1310923, 22.6710765],
                [105.1314265, 22.671274],
                [105.1318169, 22.6715048],
                [105.132245, 22.671719],
                [105.132673, 22.6717798],
                [105.1330349, 22.671657],
                [105.1334626, 22.6714115],
                [105.1338246, 22.6712887],
                [105.134055, 22.6712578],
                [105.13445, 22.6712882],
                [105.1348123, 22.6714411],
                [105.1350429, 22.6715634],
                [105.1354382, 22.6719307],
                [105.1354714, 22.6722064],
                [105.1353073, 22.6727274],
                [105.1350778, 22.6736161],
                [105.134848, 22.6743516],
                [105.13475, 22.6750258],
                [105.1348328, 22.6755466],
                [105.1351629, 22.676496],
                [105.1357897, 22.677813],
                [105.136384, 22.679712],
                [105.1368795, 22.6813354],
                [105.1373091, 22.6830201],
                [105.1378032, 22.6843957],
                [105.138118, 22.6854091],
                [105.1386132, 22.6867262],
                [105.1392397, 22.6877367],
                [105.1399647, 22.688502],
                [105.1408875, 22.689359],
                [105.1426667, 22.6907974],
                [105.1443147, 22.6915308],
                [105.1446595, 22.6916842],
                [105.1457466, 22.6922653],
                [105.1461749, 22.6926325],
                [105.1466036, 22.6932756],
                [105.1476261, 22.6950516],
                [105.148302, 22.6959702],
                [105.1487787, 22.6953263],
                [105.1496667, 22.6943757],
                [105.1506209, 22.6939765],
                [105.1516413, 22.6937611],
                [105.1518059, 22.6936997],
                [105.1532213, 22.6934532],
                [105.1549332, 22.6934209],
                [105.1561678, 22.6933278],
                [105.1574843, 22.6930201],
                [105.1585374, 22.6926514],
                [105.1595904, 22.6921908],
                [105.1605773, 22.6915465],
                [105.1615314, 22.6909327],
                [105.1620907, 22.6906564],
                [105.1630784, 22.6906247],
                [105.1641646, 22.6904398],
                [105.1656457, 22.6901319],
                [105.167242, 22.6897932],
                [105.1683611, 22.6895776],
                [105.1693484, 22.6892702],
                [105.1698749, 22.6890551],
                [105.1702368, 22.6889016],
                [105.1704671, 22.6887175],
                [105.1706643, 22.6884722],
                [105.1707792, 22.688118],
                [105.171222, 22.6868785],
                [105.1715502, 22.6860815],
                [105.1720101, 22.6853151],
                [105.1725689, 22.6846098],
                [105.173029, 22.6839965],
                [105.1733908, 22.6837204],
                [105.1740159, 22.6833827],
                [105.1752006, 22.6830444],
                [105.1762044, 22.6828288],
                [105.1773561, 22.6824292],
                [105.1781787, 22.6821833],
                [105.1792978, 22.6819675],
                [105.1801207, 22.6818747],
                [105.1810425, 22.6819349],
                [105.1828047, 22.6823806],
                [105.1837092, 22.6820851],
                [105.1851571, 22.6816239],
                [105.1868021, 22.6809173],
                [105.1878715, 22.6805483],
                [105.1891217, 22.6799954],
                [105.1907334, 22.6789824],
                [105.1917858, 22.6782459],
                [105.1923123, 22.6780614],
                [105.192773, 22.6779689],
                [105.1936946, 22.6778452],
                [105.1947478, 22.6776908],
                [105.1970185, 22.6772591],
                [105.1985326, 22.677104],
                [105.2000961, 22.6770102],
                [105.2008862, 22.6770399],
                [105.2015118, 22.6771003],
                [105.2028948, 22.6774049],
                [105.2039814, 22.6776487],
                [105.2048704, 22.6777088],
                [105.2054958, 22.6776774],
                [105.2057589, 22.6775238],
                [105.2063176, 22.6768798],
                [105.2067444, 22.6761132],
                [105.2069465, 22.6752373],
                [105.2072364, 22.6749484],
                [105.2080586, 22.6744571],
                [105.208913, 22.6734756],
                [105.2094713, 22.6725557],
                [105.2098816, 22.6718198],
                [105.2104388, 22.670226],
                [105.2109305, 22.6689078],
                [105.2116849, 22.6671605],
                [105.2119486, 22.6664809],
                [105.2116286, 22.6658016],
                [105.2128561, 22.6649388],
                [105.2156386, 22.6631113],
                [105.2178488, 22.6620445],
                [105.2195403, 22.6611557],
                [105.2206878, 22.6615341],
                [105.2227097, 22.6623418],
                [105.2234857, 22.6625967],
                [105.2238682, 22.6627228],
                [105.2248513, 22.6627721],
                [105.2263532, 22.6627446],
                [105.2275546, 22.6627429],
                [105.2281009, 22.6628434],
                [105.2290025, 22.6631461],
                [105.2298224, 22.6635755],
                [105.2304785, 22.6640052],
                [105.230779, 22.6640301],
                [105.2309697, 22.6638271],
                [105.2315419, 22.6631171],
                [105.2323599, 22.6623561],
                [105.2335331, 22.6617464],
                [105.2346515, 22.6611369],
                [105.2353996, 22.6606654],
                [105.2366268, 22.6597771],
                [105.2379085, 22.6588126],
                [105.239463, 22.6576959],
                [105.2410452, 22.6568323],
                [105.2418907, 22.6562738],
                [105.2424357, 22.6556144],
                [105.2427618, 22.6547527],
                [105.2428907, 22.6535301],
                [105.243189, 22.6523898],
                [105.2434875, 22.6513002],
                [105.2435952, 22.6504642],
                [105.2437579, 22.6498054],
                [105.2440026, 22.6492477],
                [105.2443022, 22.648842],
                [105.2444114, 22.6488165],
                [105.2445211, 22.6490443],
                [105.2455608, 22.6503092],
                [105.2461682, 22.6508124],
                [105.2470159, 22.6515456],
                [105.2478633, 22.6520762],
                [105.2488747, 22.6527079],
                [105.2496343, 22.6531905],
                [105.250325, 22.6533921],
                [105.2510957, 22.6535131],
                [105.2528946, 22.6526998],
                [105.2573657, 22.6514262],
                [105.2615086, 22.649697],
                [105.2655979, 22.6485758],
                [105.2693618, 22.6484175],
                [105.2711618, 22.6483132],
                [105.2729062, 22.6476517],
                [105.2757937, 22.6457723],
                [105.2774835, 22.6451108],
                [105.2793905, 22.6439424],
                [105.2810235, 22.642217],
                [105.2823291, 22.6404417],
                [105.2835818, 22.6394769],
                [105.2841799, 22.638564],
                [105.2843419, 22.6377532],
                [105.2842317, 22.6371961],
                [105.2837932, 22.6365664],
                [105.2834137, 22.6360699],
                [105.282266, 22.6345801],
                [105.2824662, 22.6340582],
                [105.2826149, 22.6336708],
                [105.2833697, 22.6326235],
                [105.2838668, 22.6319539],
                [105.2843269, 22.6312501],
                [105.285249, 22.6307684],
                [105.2861897, 22.6303552],
                [105.2871844, 22.629256],
                [105.2877721, 22.6277804],
                [105.2885054, 22.6253443],
                [105.287506, 22.6241116],
                [105.2867288, 22.6232556],
                [105.2865061, 22.622673],
                [105.2867605, 22.6210125],
                [105.2870167, 22.619949],
                [105.2867565, 22.6190921],
                [105.2865704, 22.6183723],
                [105.2865318, 22.6175836],
                [105.2866421, 22.6170868],
                [105.2867147, 22.6167603],
                [105.2875626, 22.6161415],
                [105.2884469, 22.6153169],
                [105.2894412, 22.6140806],
                [105.2902151, 22.6134276],
                [105.2909164, 22.6133577],
                [105.2920614, 22.6135957],
                [105.2925788, 22.6138691],
                [105.2939079, 22.6138667],
                [105.2957163, 22.6135548],
                [105.2971188, 22.6133807],
                [105.2981893, 22.6133101],
                [105.2993335, 22.6131708],
                [105.3006988, 22.6128596],
                [105.3020274, 22.6126514],
                [105.3037628, 22.6127166],
                [105.3058439, 22.6128158],
                [105.3075064, 22.613327],
                [105.3083926, 22.6133596],
                [105.3091308, 22.6133239],
                [105.3099056, 22.6131167],
                [105.3111608, 22.6130799],
                [105.3117515, 22.6130788],
                [105.3120828, 22.6126666],
                [105.3121928, 22.6122892],
                [105.3121176, 22.6117406],
                [105.3114848, 22.60941],
                [105.3114827, 22.6081177],
                [105.311961, 22.6073966],
                [105.3131025, 22.6060913],
                [105.3148347, 22.6048533],
                [105.3160148, 22.604268],
                [105.317011, 22.6040602],
                [105.3180813, 22.6039552],
                [105.3183343, 22.6043071],
                [105.3181944, 22.6049495],
                [105.3166498, 22.6075245],
                [105.3156188, 22.6087268],
                [105.3148085, 22.6095514],
                [105.3147745, 22.6108204],
                [105.3169278, 22.6160972],
                [105.3171514, 22.6169884],
                [105.3171941, 22.619526],
                [105.3182647, 22.6225919],
                [105.318821, 22.6236881],
                [105.3191916, 22.6242361],
                [105.319746, 22.6245093],
                [105.3204108, 22.6246108],
                [105.3211493, 22.6246094],
                [105.3220721, 22.624539],
                [105.3226628, 22.6245378],
                [105.3231799, 22.6246053],
                [105.3234389, 22.6248448],
                [105.3234773, 22.625462],
                [105.3226688, 22.6270754],
                [105.3225962, 22.6276242],
                [105.3225974, 22.6281386],
                [105.3229307, 22.6285494],
                [105.3238176, 22.6288562],
                [105.3249998, 22.6291626],
                [105.3255892, 22.6295298],
                [105.3257387, 22.6302839],
                [105.3258596, 22.6346046],
                [105.3251232, 22.6354977],
                [105.3249037, 22.6363897],
                [105.3250898, 22.6370067],
                [105.3259407, 22.6376907],
                [105.3270493, 22.6380314],
                [105.327641, 22.6384075],
                [105.3283448, 22.6393319],
                [105.3293064, 22.6399472],
                [105.3304516, 22.6401849],
                [105.3325361, 22.6401994],
                [105.3335318, 22.6396144],
                [105.33449, 22.6388579],
                [105.3357451, 22.6387181],
                [105.3377395, 22.6388511],
                [105.3392916, 22.6393965],
                [105.3406959, 22.639805],
                [105.3429139, 22.6407948],
                [105.3438349, 22.6399698],
                [105.3448677, 22.6394875],
                [105.3455324, 22.6395203],
                [105.3467152, 22.6399636],
                [105.347196, 22.6402712],
                [105.3500002, 22.6394421],
                [105.3509599, 22.6393028],
                [105.3519936, 22.6391976],
                [105.3534712, 22.6394001],
                [105.3539887, 22.6396391],
                [105.3551384, 22.6394286],
                [105.3558393, 22.6391527],
                [105.3563564, 22.6391858],
                [105.3572428, 22.6392867],
                [105.3577598, 22.6392856],
                [105.3585714, 22.6389751],
                [105.3591987, 22.6388365],
                [105.3597157, 22.6388354],
                [105.3606763, 22.639039],
                [105.3611939, 22.6392779],
                [105.3618586, 22.6393107],
                [105.3625958, 22.6387946],
                [105.3628528, 22.6382453],
                [105.3630358, 22.6376276],
                [105.3631444, 22.6368044],
                [105.3631432, 22.6363243],
                [105.3634372, 22.6358092],
                [105.3638756, 22.6356328],
                [105.3653186, 22.6351191],
                [105.366389, 22.6349795],
                [105.3668687, 22.6348412],
                [105.3678637, 22.6341531],
                [105.3698208, 22.6341486],
                [105.3719245, 22.6338009],
                [105.3739162, 22.6329731],
                [105.3756865, 22.6322489],
                [105.377235, 22.6313879],
                [105.3813116, 22.6296455],
                [105.3828239, 22.6290932],
                [105.3842609, 22.6280267],
                [105.3852191, 22.6273729],
                [105.3879164, 22.6280523],
                [105.3922038, 22.6296193],
                [105.3943841, 22.6302655],
                [105.3951964, 22.6302635],
                [105.3956385, 22.6299195],
                [105.3964481, 22.6289916],
                [105.3972579, 22.628098],
                [105.3982527, 22.6273754],
                [105.3991008, 22.626996],
                [105.4011078, 22.6263832],
                [105.4021024, 22.6256263],
                [105.4033537, 22.6242172],
                [105.4041619, 22.6228435],
                [105.4050425, 22.6209552],
                [105.4057754, 22.6190672],
                [105.4070643, 22.6179323],
                [105.4087955, 22.6165905],
                [105.4095316, 22.6157999],
                [105.4099349, 22.614873],
                [105.4100047, 22.6142041],
                [105.4100718, 22.6119063],
                [105.4153488, 22.6111727],
                [105.418116, 22.6106168],
                [105.4199598, 22.6099261],
                [105.4215815, 22.6090302],
                [105.4219286, 22.6087256],
                [105.424624, 22.60889],
                [105.4276518, 22.6090534],
                [105.42876, 22.6092905],
                [105.4296458, 22.6091853],
                [105.4306045, 22.6088398],
                [105.4318199, 22.6079107],
                [105.4336243, 22.6064655],
                [105.4340399, 22.606114],
                [105.4348738, 22.6057619],
                [105.4351197, 22.6057404],
                [105.4358933, 22.6052283],
                [105.4367917, 22.6045999],
                [105.4374412, 22.6043895],
                [105.4376916, 22.6045047],
                [105.4379428, 22.6048286],
                [105.4379935, 22.6050371],
                [105.4380189, 22.6051762],
                [105.4378208, 22.6058026],
                [105.4373983, 22.6066384],
                [105.4370256, 22.6074044],
                [105.4368527, 22.6080772],
                [105.4368286, 22.6084019],
                [105.4368292, 22.6085641],
                [105.4370054, 22.6089346],
                [105.4375567, 22.6092809],
                [105.4381575, 22.6094647],
                [105.4388581, 22.6095555],
                [105.4398334, 22.6095529],
                [105.440409, 22.6096672],
                [105.4408845, 22.6097818],
                [105.4415104, 22.6099655],
                [105.442236, 22.6100794],
                [105.4424359, 22.6100093],
                [105.4427106, 22.6098695],
                [105.4428598, 22.6096141],
                [105.4428588, 22.6092895],
                [105.4426825, 22.608919],
                [105.442331, 22.6085027],
                [105.4420534, 22.6077153],
                [105.442001, 22.6069735],
                [105.4420707, 22.6052809],
                [105.4421444, 22.6048635],
                [105.442319, 22.6047239],
                [105.4426191, 22.6047462],
                [105.4430198, 22.6049074],
                [105.4431703, 22.6050461],
                [105.4432708, 22.6052081],
                [105.4433464, 22.6053702],
                [105.4434728, 22.6058103],
                [105.4435744, 22.6062969],
                [105.4438508, 22.6067135],
                [105.4449268, 22.6068959],
                [105.4457278, 22.6071023],
                [105.4461037, 22.6073331],
                [105.4461291, 22.6074721],
                [105.4460302, 22.6078201],
                [105.4456064, 22.6082618],
                [105.4452823, 22.6085641],
                [105.4451837, 22.6090281],
                [105.4452101, 22.6094453],
                [105.4453613, 22.6097926],
                [105.4457631, 22.6103015],
                [105.4460903, 22.6109497],
                [105.446242, 22.6114593],
                [105.4463943, 22.6121312],
                [105.4466203, 22.612432],
                [105.4467958, 22.6125706],
                [105.4473212, 22.6126387],
                [105.4481718, 22.6127059],
                [105.4484845, 22.6127166],
                [105.4487971, 22.6127273],
                [105.4492972, 22.6126796],
                [105.4494719, 22.6125631],
                [105.4495462, 22.6123543],
                [105.4493195, 22.6118681],
                [105.4488174, 22.6112667],
                [105.4486662, 22.6109194],
                [105.4486407, 22.6107572],
                [105.4487149, 22.6105251],
                [105.4489637, 22.6101304],
                [105.4494878, 22.6097811],
                [105.449938, 22.6097798],
                [105.4501882, 22.6098255],
                [105.4504637, 22.6099406],
                [105.4511616, 22.6103148],
                [105.4512656, 22.6102471],
                [105.4515946, 22.610033],
                [105.451645, 22.6099748],
                [105.4516539, 22.6098911],
                [105.4516471, 22.6097282],
                [105.4516355, 22.6094488],
                [105.4516009, 22.6092293],
                [105.4515361, 22.6090363],
                [105.4513978, 22.6087839],
                [105.4510519, 22.6082895],
                [105.450595, 22.6077309],
                [105.4505454, 22.6076507],
                [105.4505296, 22.6075845],
                [105.4505493, 22.6075393],
                [105.4506453, 22.6074332],
                [105.4506761, 22.6074172],
                [105.4508048, 22.6073701],
                [105.4511564, 22.6073296],
                [105.4515089, 22.6075902],
                [105.4525618, 22.6082209],
                [105.4528601, 22.6083544],
                [105.4529907, 22.6083743],
                [105.4530825, 22.6084178],
                [105.453487, 22.608412],
                [105.4539125, 22.6083227],
                [105.4544233, 22.6082779],
                [105.4553594, 22.6080984],
                [105.4559091, 22.608132],
                [105.4562081, 22.608181],
                [105.4564414, 22.6082838],
                [105.4569369, 22.6085892],
                [105.4573139, 22.608927],
                [105.4576657, 22.6091845],
                [105.4579568, 22.6093372],
                [105.4581525, 22.6094115],
                [105.4584718, 22.6094106],
                [105.4588122, 22.6093508],
                [105.459195, 22.6092149],
                [105.4597065, 22.6090763],
                [105.4598756, 22.6090574],
                [105.4601317, 22.6091945],
                [105.460777, 22.6096229],
                [105.4610255, 22.6098318],
                [105.4612835, 22.6100913],
                [105.4618996, 22.6106479],
                [105.4624732, 22.6112867],
                [105.4625157, 22.6113964],
                [105.4625624, 22.6114412],
                [105.4626041, 22.6115469],
                [105.4629049, 22.6119937],
                [105.4631084, 22.6123883],
                [105.4632155, 22.6125594],
                [105.463695, 22.6131361],
                [105.4638824, 22.613319],
                [105.46461, 22.6138346],
                [105.4653216, 22.6142135],
                [105.4658546, 22.6144753],
                [105.4662384, 22.6146433],
                [105.4664515, 22.6147056],
                [105.4668054, 22.6147678],
                [105.4673148, 22.6147667],
                [105.4699809, 22.6161],
                [105.4723456, 22.6166417],
                [105.4742184, 22.6163894],
                [105.4745929, 22.6179314],
                [105.4751495, 22.6187528],
                [105.4759269, 22.6193677],
                [105.4761507, 22.6200186],
                [105.4767425, 22.6203598],
                [105.4779226, 22.6199448],
                [105.4790681, 22.6202157],
                [105.4803975, 22.6203146],
                [105.4824858, 22.6198255],
                [105.4831866, 22.619652],
                [105.4840327, 22.6187578],
                [105.4850268, 22.6180004],
                [105.4859119, 22.6176891],
                [105.487095, 22.6181999],
                [105.4901341, 22.6214827],
                [105.4923576, 22.6238078],
                [105.4942431, 22.6245221],
                [105.4949094, 22.6250002],
                [105.4959489, 22.6266087],
                [105.4973171, 22.6287749],
                [105.4979527, 22.6310019],
                [105.4987346, 22.6327827],
                [105.498849, 22.6339346],
                [105.4986167, 22.635638],
                [105.4978175, 22.6370671],
                [105.4971193, 22.6381098],
                [105.4966579, 22.6390479],
                [105.4959623, 22.6400016],
                [105.4949829, 22.6412502],
                [105.4943172, 22.6423977],
                [105.4938679, 22.6442093],
                [105.4936417, 22.6448333],
                [105.4936495, 22.6465863],
                [105.4941245, 22.6481058],
                [105.4941012, 22.6492264],
                [105.4943295, 22.6510826],
                [105.4946116, 22.65251],
                [105.494955, 22.6533173],
                [105.4956464, 22.6537589],
                [105.4972169, 22.6545725],
                [105.4978485, 22.655379],
                [105.4977722, 22.6566083],
                [105.4974289, 22.6578658],
                [105.4970137, 22.6590649],
                [105.4965343, 22.6598433],
                [105.4960233, 22.6608483],
                [105.4955294, 22.6621316],
                [105.4952312, 22.6634863],
                [105.4947668, 22.6649698],
                [105.493881, 22.6662535],
                [105.4926144, 22.6676485],
                [105.4910726, 22.6690529],
                [105.4889293, 22.6707014],
                [105.4880215, 22.6722176],
                [105.487887, 22.6734008],
                [105.488558, 22.6755642],
                [105.4891978, 22.6773188],
                [105.491903, 22.6806855],
                [105.4932694, 22.6822209],
                [105.4941442, 22.6835015],
                [105.4948744, 22.6864407],
                [105.4955938, 22.6881584],
                [105.4965747, 22.6896036],
                [105.4977458, 22.6911422],
                [105.4990331, 22.6921786],
                [105.5007517, 22.6931898],
                [105.5011518, 22.6942334],
                [105.5019439, 22.6959225],
                [105.5020191, 22.6970775],
                [105.5018561, 22.6983499],
                [105.5013439, 22.6995109],
                [105.5010596, 22.7004879],
                [105.5004844, 22.7013093],
                [105.4994963, 22.701888],
                [105.4976021, 22.7029152],
                [105.4964976, 22.7031866],
                [105.4951229, 22.7038107],
                [105.4927151, 22.7066731],
                [105.4921583, 22.7076019],
                [105.4911388, 22.7082213],
                [105.48991, 22.7082009],
                [105.4876823, 22.7077722],
                [105.4856785, 22.7077501],
                [105.4846947, 22.7081109],
                [105.483942, 22.7088569],
                [105.4832112, 22.7090107],
                [105.4822072, 22.709315],
                [105.4819258, 22.7118264],
                [105.4813972, 22.7130589],
                [105.4805077, 22.714274],
                [105.4796245, 22.7151794],
                [105.4786571, 22.7159271],
                [105.4777339, 22.7167964],
                [105.4772422, 22.7176554],
                [105.4766919, 22.7182762],
                [105.4766972, 22.7192672],
                [105.4765614, 22.7202665],
                [105.4765005, 22.7214925],
                [105.4763556, 22.722836],
                [105.4763661, 22.72375],
                [105.4760808, 22.7252291],
                [105.4760633, 22.7263115],
                [105.4758848, 22.7274478],
                [105.4754148, 22.728166],
                [105.4758167, 22.7290631],
                [105.4767681, 22.7295078],
                [105.4771015, 22.7300351],
                [105.4772498, 22.7308765],
                [105.4772498, 22.7320086],
                [105.477147, 22.7329366],
                [105.4771225, 22.7336701],
                [105.4772706, 22.7348443],
                [105.4778107, 22.736569],
                [105.4788145, 22.7377197],
                [105.4792035, 22.7385317],
                [105.4790775, 22.7392335],
                [105.4784126, 22.7406253],
                [105.4775437, 22.7415692],
                [105.4763361, 22.7425904],
                [105.475558, 22.7438877],
                [105.4732498, 22.7468396],
                [105.4723503, 22.7477393],
                [105.4707379, 22.7485089],
                [105.4696394, 22.7494913],
                [105.4695235, 22.7504759],
                [105.4697874, 22.7516791],
                [105.470985, 22.754948],
                [105.4708528, 22.7550721],
                [105.4703148, 22.7554703],
                [105.4696003, 22.7559918],
                [105.468781, 22.7561087],
                [105.4677067, 22.7561087],
                [105.4667587, 22.7559219],
                [105.4657558, 22.7556438],
                [105.4645247, 22.7556101],
                [105.4637825, 22.7558414],
                [105.4630464, 22.7564702],
                [105.4620628, 22.7570454],
                [105.4602552, 22.757436],
                [105.4583118, 22.7572923],
                [105.4555019, 22.7577025],
                [105.452966, 22.7584662],
                [105.4502151, 22.759506],
                [105.4486775, 22.7628539],
                [105.4482437, 22.7646959],
                [105.4480749, 22.7669581],
                [105.4477273, 22.7680409],
                [105.4461567, 22.7707061],
                [105.4447568, 22.7721674],
                [105.4428385, 22.773301],
                [105.4407252, 22.7741026],
                [105.4394855, 22.774093],
                [105.4381502, 22.7743287],
                [105.4367203, 22.7743481],
                [105.4353985, 22.7747146],
                [105.4334055, 22.7756527],
                [105.4315616, 22.7765088],
                [105.4303122, 22.7769532],
                [105.4290594, 22.777195],
                [105.4278411, 22.7770921],
                [105.4265468, 22.7765011],
                [105.4256688, 22.7764232],
                [105.4246467, 22.776557],
                [105.4241152, 22.7772966],
                [105.4235314, 22.779016],
                [105.4228871, 22.7797061],
                [105.4222989, 22.7801002],
                [105.422, 22.7807978],
                [105.4224469, 22.7820081],
                [105.4224787, 22.7826793],
                [105.4227909, 22.7836024],
                [105.4232748, 22.7845667],
                [105.4236234, 22.7849472],
                [105.4227657, 22.7852014],
                [105.421389, 22.7854458],
                [105.4197721, 22.7856253],
                [105.4175814, 22.7857182],
                [105.415745, 22.7860405],
                [105.4137839, 22.7872533],
                [105.4128795, 22.7881436],
                [105.4120472, 22.7885172],
                [105.4103275, 22.7898556],
                [105.4102075, 22.7916059],
                [105.409295, 22.7924796],
                [105.408121, 22.7938287],
                [105.4058373, 22.7947431],
                [105.4039194, 22.7956989],
                [105.4032883, 22.795913],
                [105.4023732, 22.795942],
                [105.4009828, 22.7954205],
                [105.3999259, 22.7951549],
                [105.3984297, 22.7960387],
                [105.3969858, 22.7971525],
                [105.3961465, 22.7980277],
                [105.3952884, 22.7991141],
                [105.3948333, 22.8000749],
                [105.3947081, 22.8021448],
                [105.3938607, 22.8030756],
                [105.3930451, 22.8037138],
                [105.3918764, 22.8046841],
                [105.3914909, 22.8057476],
                [105.3909536, 22.8066479],
                [105.390083, 22.807691],
                [105.3891104, 22.8085949],
                [105.3883151, 22.809628],
                [105.3864506, 22.8107924],
                [105.3851435, 22.8110451],
                [105.3838976, 22.8116089],
                [105.3828261, 22.8125794],
                [105.3818309, 22.8126989],
                [105.3809822, 22.8123619],
                [105.3800036, 22.8127289],
                [105.3788619, 22.8127598],
                [105.3776912, 22.8125536],
                [105.3760422, 22.812095],
                [105.3743917, 22.8119333],
                [105.3728897, 22.8120159],
                [105.3719096, 22.8120451],
                [105.3705053, 22.812456],
                [105.3693896, 22.8126696],
                [105.3671067, 22.8138413],
                [105.36527, 22.8145245],
                [105.363642, 22.8153253],
                [105.3618468, 22.8163579],
                [105.360197, 22.8169126],
                [105.3586997, 22.8171821],
                [105.3572832, 22.8172261],
                [105.356104, 22.8169027],
                [105.3551319, 22.8164155],
                [105.354397, 22.815805],
                [105.3533277, 22.815401],
                [105.3523095, 22.8154371],
                [105.3512435, 22.8151296],
                [105.3496185, 22.8138078],
                [105.348837, 22.8136078],
                [105.3480771, 22.8139547],
                [105.3470554, 22.8154393],
                [105.346533, 22.8163367],
                [105.3459054, 22.8173041],
                [105.3451754, 22.8184192],
                [105.3447957, 22.8195757],
                [105.3446847, 22.8206808],
                [105.3445906, 22.8219384],
                [105.3446435, 22.823195],
                [105.3446568, 22.8243317],
                [105.3444666, 22.8258276],
                [105.3445314, 22.8266433],
                [105.3442922, 22.827535],
                [105.343253, 22.8292764],
                [105.3423211, 22.8298823],
                [105.341373, 22.8299899],
                [105.3403248, 22.8296986],
                [105.3393512, 22.8295991],
                [105.3373482, 22.8303177],
                [105.3358827, 22.8311487],
                [105.3343776, 22.8317493],
                [105.3326833, 22.8318966],
                [105.3310894, 22.8322453],
                [105.3292408, 22.8328995],
                [105.3284124, 22.833583],
                [105.3277278, 22.8339585],
                [105.327225, 22.8346479],
                [105.3266437, 22.83568],
                [105.3256312, 22.83809],
                [105.3252847, 22.8392959],
                [105.3250398, 22.8403861],
                [105.3251472, 22.8410734],
                [105.3252888, 22.8421545],
                [105.3251872, 22.8431776],
                [105.3246251, 22.8446407],
                [105.324176, 22.8451245],
                [105.3225195, 22.8449569],
                [105.3213573, 22.8450425],
                [105.320303, 22.8452997],
                [105.3193381, 22.8460389],
                [105.3189612, 22.8467447],
                [105.3183702, 22.8474504],
                [105.3168141, 22.8485131],
                [105.3154692, 22.8491204],
                [105.3141611, 22.84948],
                [105.312891, 22.8497741],
                [105.3091955, 22.8496837],
                [105.3066922, 22.8520935],
                [105.304869, 22.8534235],
                [105.3024477, 22.8545132],
                [105.3018924, 22.8556848],
                [105.3014378, 22.8561815],
                [105.300583, 22.8562679],
                [105.2987367, 22.8555625],
                [105.2975287, 22.8556618],
                [105.296708, 22.8560309],
                [105.2956085, 22.8560527],
                [105.293735, 22.8557762],
                [105.2919323, 22.8555794],
                [105.2904551, 22.8555213],
                [105.288954, 22.8556859],
                [105.2878116, 22.8561969],
                [105.2864671, 22.8564662],
                [105.2852583, 22.8562618],
                [105.2844955, 22.8557918],
                [105.2837345, 22.8558308],
                [105.2822672, 22.8563803],
                [105.2809994, 22.8566384],
                [105.280144, 22.85741],
                [105.2792808, 22.858015],
                [105.2776483, 22.8602034],
                [105.2767488, 22.8611619],
                [105.2753633, 22.8620204],
                [105.2743617, 22.8624523],
                [105.2742093, 22.8639458],
                [105.2738065, 22.8646351],
                [105.2727364, 22.8651917],
                [105.2707329, 22.8653616],
                [105.2687801, 22.8658043],
                [105.266713, 22.8664239],
                [105.2676088, 22.8699978],
                [105.2686151, 22.8754492],
                [105.2686194, 22.877213],
                [105.268213, 22.8788949],
                [105.2673253, 22.881117],
                [105.2710205, 22.8796129],
                [105.2736061, 22.8785807],
                [105.2753503, 22.878587],
                [105.2779761, 22.8786612],
                [105.2788623, 22.8784379],
                [105.280096, 22.8774565],
                [105.2813701, 22.8771514],
                [105.2824602, 22.877489],
                [105.2828155, 22.8777179],
                [105.283726, 22.8784411],
                [105.2852774, 22.8785164],
                [105.2866646, 22.878549],
                [105.2877607, 22.8780677],
                [105.288909, 22.8776355],
                [105.2898976, 22.8769566],
                [105.2912982, 22.8761574],
                [105.2950175, 22.875991],
                [105.2960749, 22.8757443],
                [105.2971876, 22.8752018],
                [105.2986797, 22.8749941],
                [105.3001921, 22.8750216],
                [105.3018892, 22.8753872],
                [105.3032021, 22.8760205],
                [105.3034466, 22.8763005],
                [105.3039367, 22.8773688],
                [105.3039806, 22.8783995],
                [105.3037852, 22.8794438],
                [105.3036011, 22.8804913],
                [105.3034369, 22.8814631],
                [105.303534, 22.882902],
                [105.3037552, 22.8838372],
                [105.3040832, 22.8847081],
                [105.3046163, 22.8857965],
                [105.3050674, 22.8863315],
                [105.3066017, 22.8886579],
                [105.3065631, 22.889206],
                [105.3063084, 22.88976],
                [105.305767, 22.8904236],
                [105.3056751, 22.8909145],
                [105.3057899, 22.8918729],
                [105.305864, 22.8927878],
                [105.3057591, 22.8934956],
                [105.3054514, 22.894301],
                [105.3050819, 22.8953822],
                [105.3056746, 22.8963288],
                [105.3060652, 22.8969129],
                [105.3061065, 22.8974282],
                [105.3056903, 22.8978814],
                [105.3056017, 22.8982006],
                [105.3056841, 22.8990267],
                [105.3053232, 22.8994502],
                [105.304672, 22.8999602],
                [105.3045408, 22.9004927],
                [105.3044561, 22.900646],
                [105.3045378, 22.9013516],
                [105.304596, 22.902522],
                [105.3054909, 22.9048775],
                [105.3066109, 22.9064015],
                [105.3075127, 22.9072801],
                [105.3082632, 22.908242],
                [105.3075855, 22.9094943],
                [105.3072111, 22.9105793],
                [105.3072373, 22.9113972],
                [105.3077207, 22.9119764],
                [105.3086648, 22.9122844],
                [105.3097398, 22.912427],
                [105.3108433, 22.9126044],
                [105.3112312, 22.9129196],
                [105.3117338, 22.9136324],
                [105.3118785, 22.9143847],
                [105.3121904, 22.9151298],
                [105.3126184, 22.915231],
                [105.3132422, 22.9148959],
                [105.3144363, 22.9146629],
                [105.3151819, 22.9147699],
                [105.3173211, 22.9149647],
                [105.3186788, 22.914905],
                [105.3199862, 22.9147318],
                [105.3214572, 22.9149364],
                [105.322188, 22.9152468],
                [105.3228614, 22.9156773],
                [105.3234387, 22.9168341],
                [105.324747, 22.919906],
                [105.3254441, 22.9211512],
                [105.3261515, 22.9218599],
                [105.327586, 22.9221265],
                [105.3293626, 22.9228168],
                [105.3301096, 22.923572],
                [105.330469, 22.9242435],
                [105.3306298, 22.9247288],
                [105.330833, 22.925499],
                [105.3315611, 22.9266068],
                [105.3327529, 22.9277941],
                [105.3330818, 22.9284022],
                [105.3329145, 22.9293618],
                [105.3329444, 22.9300074],
                [105.332504, 22.9305766],
                [105.3319939, 22.9309924],
                [105.3317962, 22.9314984],
                [105.3318752, 22.9321269],
                [105.3324825, 22.9327578],
                [105.3327333, 22.9338095],
                [105.333763, 22.9337564],
                [105.3350543, 22.9330122],
                [105.3362028, 22.9329491],
                [105.3384042, 22.9333829],
                [105.3404509, 22.9334056],
                [105.3414041, 22.933264],
                [105.342399, 22.9332954],
                [105.3446068, 22.9327213],
                [105.3453626, 22.9327262],
                [105.3462185, 22.9329828],
                [105.3473747, 22.9334675],
                [105.3483857, 22.934177],
                [105.3494584, 22.9348305],
                [105.3506138, 22.9353095],
                [105.3527108, 22.9357373],
                [105.3545786, 22.9358788],
                [105.3567899, 22.9353549],
                [105.3574084, 22.935527],
                [105.3583165, 22.9362101],
                [105.3593758, 22.936961],
                [105.3602637, 22.9378014],
                [105.3612215, 22.9386512],
                [105.3618905, 22.9393735],
                [105.3623526, 22.9403463],
                [105.3625035, 22.9415168],
                [105.3625218, 22.9426411],
                [105.3631054, 22.9433898],
                [105.3636238, 22.9441662],
                [105.3633945, 22.9451432],
                [105.3632388, 22.9458429],
                [105.3630975, 22.9464622],
                [105.3627014, 22.947273],
                [105.3622874, 22.9477971],
                [105.3623455, 22.9481728],
                [105.3628799, 22.9486504],
                [105.3641009, 22.9499581],
                [105.3643419, 22.9507764],
                [105.3643152, 22.951438],
                [105.3641751, 22.9520017],
                [105.3637078, 22.9526924],
                [105.3629428, 22.9535918],
                [105.3613672, 22.9566387],
                [105.3615156, 22.9573416],
                [105.362023, 22.9580301],
                [105.3621497, 22.9587269],
                [105.361853, 22.9594772],
                [105.3617006, 22.9601007],
                [105.3617711, 22.9607562],
                [105.3623165, 22.9613409],
                [105.3627099, 22.9620696],
                [105.3633199, 22.9626595],
                [105.3645666, 22.9639297],
                [105.3649786, 22.9646505],
                [105.3650179, 22.9652555],
                [105.3649496, 22.9659611],
                [105.3641513, 22.9679249],
                [105.3632337, 22.9687533],
                [105.3623578, 22.9697052],
                [105.3614263, 22.9705073],
                [105.3603972, 22.9713349],
                [105.3604843, 22.9724676],
                [105.3615046, 22.9745192],
                [105.3617857, 22.9755521],
                [105.361659, 22.976462],
                [105.3618384, 22.9770888],
                [105.3616322, 22.9789601],
                [105.3618809, 22.9791353],
                [105.3626799, 22.9795419],
                [105.3632847, 22.9799362],
                [105.3642624, 22.9802459],
                [105.3651247, 22.9807656],
                [105.3657749, 22.9812824],
                [105.3667548, 22.9824861],
                [105.3673073, 22.9829018],
                [105.368137, 22.9830897],
                [105.3688485, 22.9830738],
                [105.3697066, 22.9833908],
                [105.3705344, 22.9839635],
                [105.371229, 22.9847948],
                [105.3721695, 22.9856972],
                [105.372818, 22.9866126],
                [105.3732756, 22.9876291],
                [105.3740932, 22.9886009],
                [105.3747467, 22.9896225],
                [105.3764513, 22.9896274],
                [105.3788713, 22.9894298],
                [105.3847846, 22.9893752],
                [105.3864716, 22.9895961],
                [105.3881598, 22.9900927],
                [105.3898782, 22.9910571],
                [105.3907005, 22.9916424],
                [105.3914851, 22.9919665],
                [105.3929105, 22.9919717],
                [105.3944294, 22.9918937],
                [105.397156, 22.990924],
                [105.3989668, 22.990901],
                [105.4000397, 22.9912611],
                [105.4014731, 22.992723],
                [105.4027896, 22.9936388],
                [105.4052862, 22.9944181],
                [105.4067659, 22.9947929],
                [105.4081078, 22.9948718],
                [105.4092804, 22.9945756],
                [105.410829, 22.9944712],
                [105.4121849, 22.9945039],
                [105.4137169, 22.9949383],
                [105.4142397, 22.99552],
                [105.4146165, 22.9964804],
                [105.4148556, 22.9973201],
                [105.4152089, 22.997791],
                [105.4165603, 22.9989094],
                [105.4171931, 22.999267],
                [105.4181452, 23.0001433],
                [105.4186374, 23.0008014],
                [105.4187433, 23.0010146],
                [105.4200117, 23.0005519],
                [105.4205882, 23.0001883],
                [105.4214879, 22.9999289],
                [105.4236242, 22.9990865],
                [105.4256421, 22.9981214],
                [105.4265043, 22.9980756],
                [105.4274548, 22.9982256],
                [105.4283246, 22.9986156],
                [105.4293813, 22.9993763],
                [105.4302108, 22.999706],
                [105.4310345, 23.0000687],
                [105.4314679, 23.000529],
                [105.4318181, 23.0006209],
                [105.4326013, 23.001154],
                [105.4351845, 23.0019034],
                [105.4372863, 23.0020677],
                [105.4396494, 23.0025229],
                [105.4416166, 23.0031568],
                [105.4421009, 23.0031702],
                [105.4429532, 23.0030073],
                [105.4437298, 23.0024573],
                [105.4442059, 23.0018638],
                [105.4442037, 23.0009661],
                [105.4443912, 23.0005435],
                [105.4448336, 23.0003085],
                [105.4460027, 22.9995799],
                [105.4462662, 22.99892],
                [105.4466787, 22.9987483],
                [105.4473627, 22.9985323],
                [105.4492455, 22.9985491],
                [105.4500911, 22.998769],
                [105.4504444, 22.9996514],
                [105.4508104, 22.9999448],
                [105.4524307, 23.0001925],
                [105.4528054, 23.0004621],
                [105.4532033, 23.0017768],
                [105.4553948, 23.0029289],
                [105.4574793, 23.0034676],
                [105.4593908, 23.0040501],
                [105.4612368, 23.0035561],
                [105.4626001, 23.0027147],
                [105.4637218, 23.0027593],
                [105.4650453, 23.003429],
                [105.4670134, 23.0062977],
                [105.4684169, 23.006742],
                [105.4694978, 23.0074952],
                [105.4705707, 23.007509],
                [105.4726453, 23.0066924],
                [105.4745708, 23.0063974],
                [105.4771018, 23.0069893],
                [105.4792455, 23.0077417],
                [105.4811256, 23.0080685],
                [105.4846209, 23.0085294],
                [105.486456, 23.008587],
                [105.4883248, 23.0085094],
                [105.4890829, 23.0098667],
                [105.4901657, 23.0125776],
                [105.4905543, 23.0153869],
                [105.4915861, 23.0174368],
                [105.4917872, 23.0181369],
                [105.4917766, 23.0188496],
                [105.4915836, 23.0196258],
                [105.4913222, 23.0211036],
                [105.4912257, 23.0219202],
                [105.4912885, 23.0227267],
                [105.4914816, 23.0233748],
                [105.4917803, 23.023963],
                [105.4920071, 23.0246593],
                [105.4922147, 23.0258899],
                [105.492325, 23.026968],
                [105.492448, 23.0275907],
                [105.4926895, 23.0280211],
                [105.4931373, 23.0283771],
                [105.4939772, 23.0289419],
                [105.4943726, 23.029395],
                [105.4945412, 23.0300013],
                [105.4945495, 23.0308656],
                [105.4944072, 23.0318512],
                [105.4943844, 23.0324766],
                [105.4945078, 23.0332228],
                [105.4949803, 23.0339353],
                [105.4957542, 23.034487],
                [105.4964947, 23.0351552],
                [105.4967653, 23.0357187],
                [105.4968678, 23.0364484],
                [105.4968455, 23.0373439],
                [105.496979, 23.0381157],
                [105.4973974, 23.0388066],
                [105.4982183, 23.039642],
                [105.4995982, 23.0405605],
                [105.5026652, 23.0417007],
                [105.5036591, 23.042252],
                [105.5042386, 23.0430612],
                [105.5043601, 23.0438829],
                [105.5043921, 23.0450614],
                [105.504602, 23.0462167],
                [105.5047342, 23.0477771],
                [105.5049708, 23.0491076],
                [105.5053838, 23.050174],
                [105.5058811, 23.0506828],
                [105.5065396, 23.0509623],
                [105.5077244, 23.0511645],
                [105.5082769, 23.051451],
                [105.5086361, 23.0521246],
                [105.5086194, 23.0533188],
                [105.5080748, 23.0558247],
                [105.5078154, 23.0580541],
                [105.507993, 23.0597215],
                [105.5088778, 23.0613849],
                [105.5096749, 23.0622355],
                [105.5108985, 23.0634151],
                [105.5116925, 23.0644463],
                [105.5122489, 23.0653805],
                [105.5130439, 23.0661635],
                [105.5132573, 23.0667065],
                [105.5133842, 23.0676947],
                [105.5134391, 23.0689254],
                [105.5136824, 23.0696269],
                [105.5144912, 23.0703905],
                [105.5161408, 23.0715585],
                [105.5185568, 23.0736375],
                [105.5197531, 23.0743753],
                [105.520185, 23.0749452],
                [105.5205186, 23.0755987],
                [105.5206694, 23.0763908],
                [105.5206505, 23.0774409],
                [105.5205332, 23.0787012],
                [105.5201826, 23.0799621],
                [105.5196994, 23.0810752],
                [105.5191316, 23.0819724],
                [105.5185048, 23.0824057],
                [105.5181798, 23.0826721],
                [105.5178686, 23.0830837],
                [105.517768, 23.0835327],
                [105.5178278, 23.084227],
                [105.5182606, 23.0850515],
                [105.519136, 23.0861965],
                [105.5202516, 23.0877598],
                [105.5206611, 23.08854],
                [105.5211759, 23.089928],
                [105.5214474, 23.0911934],
                [105.5218959, 23.0922127],
                [105.5228003, 23.0937236],
                [105.5238186, 23.0955408],
                [105.5249599, 23.0983329],
                [105.5248702, 23.099252],
                [105.524934, 23.1003418],
                [105.5251601, 23.1013549],
                [105.5255543, 23.1018512],
                [105.5261085, 23.1022971],
                [105.5263776, 23.1028605],
                [105.5271098, 23.1036029],
                [105.5276672, 23.1040358],
                [105.5282456, 23.1049306],
                [105.5284329, 23.1055325],
                [105.5283107, 23.1069545],
                [105.5285252, 23.1080396],
                [105.5289342, 23.109112],
                [105.5297304, 23.1097941],
                [105.5307212, 23.1100901],
                [105.5314649, 23.1106345],
                [105.5351234, 23.1101329],
                [105.5379826, 23.1101215],
                [105.5404956, 23.109136],
                [105.5462083, 23.1092917],
                [105.5508071, 23.1106499],
                [105.5541982, 23.1122548],
                [105.5550704, 23.1152043],
                [105.5589164, 23.1182484],
                [105.5608765, 23.1187448],
                [105.5644972, 23.1187947],
                [105.564537, 23.1197195],
                [105.565052, 23.1205089],
                [105.5647516, 23.1210417],
                [105.563872, 23.121954],
                [105.5633139, 23.1235676],
                [105.5624127, 23.1249687],
                [105.5612969, 23.1259949],
                [105.5602455, 23.1268237],
                [105.559752, 23.1276327],
                [105.5597091, 23.1282444],
                [105.5597091, 23.1285207],
                [105.5608248, 23.1299612],
                [105.5637216, 23.1312241],
                [105.5655455, 23.1326646],
                [105.5655885, 23.1337499],
                [105.5664682, 23.1353087],
                [105.5659961, 23.1367886],
                [105.5638289, 23.1377555],
                [105.563271, 23.1399259],
                [105.564301, 23.1412874],
                [105.5633783, 23.1432999],
                [105.5644512, 23.1438721],
                [105.5648374, 23.144543],
                [105.5657816, 23.1450954],
                [105.5674767, 23.1445232],
                [105.5673051, 23.1452335],
                [105.5676484, 23.1460228],
                [105.571382, 23.1485482],
                [105.5718326, 23.1490218],
                [105.5705237, 23.1501464],
                [105.5711031, 23.1508764],
                [105.5731416, 23.152435],
                [105.5737638, 23.154842],
                [105.5730772, 23.1564203],
                [105.5741071, 23.1585905],
                [105.5740213, 23.1604055],
                [105.5734205, 23.161096],
                [105.5707598, 23.1605337],
                [105.569601, 23.1608198],
                [105.5678201, 23.1632661],
                [105.5651378, 23.1647062],
                [105.5637646, 23.1659491],
                [105.5627131, 23.1672116],
                [105.5613184, 23.1674878],
                [105.560503, 23.1682374],
                [105.5600524, 23.1693421],
                [105.558486, 23.1708808],
                [105.5583787, 23.1728239],
                [105.5570054, 23.1743034],
                [105.5566406, 23.175339],
                [105.5577028, 23.1764042],
                [105.5580783, 23.1773412],
                [105.5575418, 23.1781598],
                [105.5562758, 23.1787122],
                [105.5558467, 23.1793828],
                [105.5554604, 23.181671],
                [105.5556321, 23.1825783],
                [105.5535078, 23.1832687],
                [105.5540871, 23.1856159],
                [105.5530572, 23.1870164],
                [105.5497741, 23.1889494],
                [105.5482077, 23.1885746],
                [105.5467057, 23.1904287],
                [105.5429721, 23.1932886],
                [105.5416631, 23.1934858],
                [105.5407619, 23.1926575],
                [105.5335307, 23.1908823],
                [105.5269646, 23.1886732],
                [105.5237889, 23.1901525],
                [105.5211925, 23.1926969],
                [105.5173945, 23.1958724],
                [105.5154848, 23.1966613],
                [105.513103, 23.1958526],
                [105.5088758, 23.1979433],
                [105.5060434, 23.1999944],
                [105.5043912, 23.2001522],
                [105.502181, 23.2014933],
                [105.499928, 23.2019469],
                [105.4987693, 23.2025781],
                [105.4977822, 23.2040966],
                [105.4970312, 23.2061083],
                [105.4973638, 23.2072422],
                [105.4977822, 23.2091848],
                [105.4968059, 23.2109991],
                [105.4958725, 23.2118668],
                [105.4960441, 23.2137995],
                [105.4950785, 23.2156926],
                [105.4946494, 23.2177829],
                [105.4917097, 23.2207606],
                [105.4921174, 23.2227818],
                [105.4918706, 23.224034],
                [105.4912269, 23.2256016],
                [105.492053, 23.2294566],
                [105.4930615, 23.2322763],
                [105.4916561, 23.2326903],
                [105.4907548, 23.2330255],
                [105.4875791, 23.2338734],
                [105.4871499, 23.2344255],
                [105.4869997, 23.2355987],
                [105.4865169, 23.2374816],
                [105.485826, 23.238823],
                [105.48347, 23.242597],
                [105.4823112, 23.2433375],
                [105.4810023, 23.2447471],
                [105.480152, 23.246283],
                [105.4798114, 23.2467286],
                [105.4793501, 23.2468814],
                [105.4788565, 23.2468666],
                [105.4778695, 23.2467089],
                [105.4771828, 23.2469257],
                [105.4740715, 23.2502971],
                [105.4726338, 23.2523474],
                [105.4727626, 23.2540429],
                [105.4725051, 23.2551863],
                [105.4683208, 23.2574337],
                [105.4678058, 23.2604697],
                [105.4656386, 23.2637421],
                [105.464952, 23.2649446],
                [105.4630208, 23.2666793],
                [105.4625273, 23.2687097],
                [105.4607248, 23.2703261],
                [105.4591799, 23.272041],
                [105.4564011, 23.2738052],
                [105.4555964, 23.2739136],
                [105.4548883, 23.2747021],
                [105.4541051, 23.2758158],
                [105.4525065, 23.2768112],
                [105.451777, 23.277442],
                [105.4508114, 23.2785655],
                [105.4502427, 23.2792356],
                [105.4499638, 23.2796397],
                [105.4495776, 23.2806253],
                [105.4492557, 23.2816403],
                [105.44873, 23.2824485],
                [105.4474854, 23.2858583],
                [105.4459619, 23.2867058],
                [105.4468632, 23.2881052],
                [105.4464555, 23.2900367],
                [105.4471635, 23.2917317],
                [105.4448032, 23.2956735],
                [105.4436445, 23.2959494],
                [105.4415845, 23.2981961],
                [105.4417133, 23.2997333],
                [105.4391384, 23.3010143],
                [105.4380073, 23.3009639],
                [105.4369283, 23.3009158],
                [105.4356953, 23.3009384],
                [105.435214, 23.3009473],
                [105.4349862, 23.3009515],
                [105.4347825, 23.3009552],
                [105.4346897, 23.3016367],
                [105.4345035, 23.3030048],
                [105.4308772, 23.3029062],
                [105.4291177, 23.3012311],
                [105.4284096, 23.3001275],
                [105.4273367, 23.298137],
                [105.4271006, 23.2974669],
                [105.4263067, 23.2965406],
                [105.4295682, 23.2925201],
                [105.4296112, 23.2912193],
                [105.4273796, 23.290155],
                [105.4233455, 23.2873957],
                [105.4226589, 23.2855824],
                [105.4214143, 23.2848334],
                [105.4189252, 23.285267],
                [105.4142904, 23.2868438],
                [105.411458, 23.2879081],
                [105.4093551, 23.290628],
                [105.4058361, 23.2935844],
                [105.402832, 23.2953187],
                [105.3993129, 23.2994968],
                [105.3902793, 23.3100795],
                [105.3865349, 23.3141882],
                [105.3859556, 23.3141389],
                [105.3854084, 23.3137054],
                [105.385108, 23.3122964],
                [105.3841853, 23.3113111],
                [105.3832854, 23.3105331],
                [105.3832492, 23.3105623],
                [105.3831473, 23.3106854],
                [105.3829408, 23.310885],
                [105.3827476, 23.3110672],
                [105.3823292, 23.3114047],
                [105.3821897, 23.3114614],
                [105.3820261, 23.3115057],
                [105.3818893, 23.3115648],
                [105.3816211, 23.3117323],
                [105.3812161, 23.3120057],
                [105.3805992, 23.3123974],
                [105.3803202, 23.3125452],
                [105.3800735, 23.312661],
                [105.3798401, 23.3127533],
                [105.3798871, 23.3128839],
                [105.3796121, 23.312991],
                [105.3792393, 23.3133186],
                [105.3788638, 23.3136191],
                [105.3782952, 23.3139862],
                [105.3781986, 23.3140822],
                [105.3780216, 23.3142152],
                [105.3778794, 23.3143384],
                [105.377681, 23.3144468],
                [105.3770158, 23.314836],
                [105.3767985, 23.3149394],
                [105.3766, 23.3150601],
                [105.3759804, 23.3153409],
                [105.3758624, 23.3154173],
                [105.3756317, 23.3155675],
                [105.3747788, 23.3161242],
                [105.3744891, 23.3164937],
                [105.3743684, 23.3165947],
                [105.3742236, 23.3166907],
                [105.3740573, 23.3167523],
                [105.373781, 23.3167769],
                [105.3736523, 23.3168385],
                [105.3733733, 23.3169765],
                [105.3728154, 23.3174248],
                [105.3723192, 23.3178213],
                [105.3718606, 23.3182228],
                [105.3715602, 23.3184962],
                [105.3713375, 23.3186588],
                [105.3710398, 23.3189297],
                [105.3707743, 23.3191957],
                [105.370557, 23.3194519],
                [105.3705355, 23.3195283],
                [105.370506, 23.3196366],
                [105.3704685, 23.3197918],
                [105.3704283, 23.3199667],
                [105.3703693, 23.3200529],
                [105.370195, 23.3202087],
                [105.3701359, 23.3203805],
                [105.3700849, 23.3205578],
                [105.3700581, 23.320681],
                [105.3700259, 23.3207795],
                [105.3699991, 23.320883],
                [105.3699589, 23.3209642],
                [105.3698999, 23.3210332],
                [105.3698301, 23.32108],
                [105.3697577, 23.321181],
                [105.3696826, 23.3212992],
                [105.3694558, 23.3219483],
                [105.3694224, 23.3220628],
                [105.3693849, 23.3223707],
                [105.3692454, 23.3230406],
                [105.3691623, 23.3232426],
                [105.3691193, 23.3233904],
                [105.3690818, 23.3235061],
                [105.3690416, 23.3236416],
                [105.3690281, 23.3237229],
                [105.3690228, 23.3237524],
                [105.3689986, 23.3238731],
                [105.3688243, 23.324213],
                [105.368768, 23.3243756],
                [105.3686902, 23.3245381],
                [105.3685856, 23.3246391],
                [105.3684863, 23.3247228],
                [105.3684139, 23.3248164],
                [105.3682637, 23.3249519],
                [105.367561, 23.3255209],
                [105.3670728, 23.3259149],
                [105.3667885, 23.3261686],
                [105.3666383, 23.3262992],
                [105.3665256, 23.3264149],
                [105.3663701, 23.3265184],
                [105.3661394, 23.3267302],
                [105.3657478, 23.3272154],
                [105.3654804, 23.327629],
                [105.3653455, 23.3278508],
                [105.3650478, 23.3282326],
                [105.3648332, 23.3284986],
                [105.3644013, 23.3290724],
                [105.3640607, 23.3294296],
                [105.3637576, 23.3297202],
                [105.363358, 23.3302054],
                [105.3630039, 23.3307177],
                [105.362733, 23.3311216],
                [105.3625479, 23.3314122],
                [105.362328, 23.3317693],
                [105.3620839, 23.3320599],
                [105.361982, 23.3321757],
                [105.3619203, 23.3322373],
                [105.361762, 23.3323382],
                [105.361357, 23.332789],
                [105.3607482, 23.3336583],
                [105.3607023, 23.3337409],
                [105.3606784, 23.3338677],
                [105.3606033, 23.3340154],
                [105.3604853, 23.3341386],
                [105.3603807, 23.3342494],
                [105.3603378, 23.3344021],
                [105.3603056, 23.3344809],
                [105.3599381, 23.3349636],
                [105.359863, 23.3350621],
                [105.3598086, 23.3351246],
                [105.3597128, 23.3352321],
                [105.3596485, 23.3353281],
                [105.3595948, 23.335402],
                [105.3595331, 23.3354931],
                [105.3594688, 23.3355744],
                [105.3591844, 23.3358453],
                [105.3585649, 23.3364192],
                [105.3581759, 23.3367787],
                [105.3579453, 23.3370102],
                [105.3573042, 23.3375742],
                [105.3567302, 23.3380347],
                [105.3563145, 23.338483],
                [105.3561214, 23.3387218],
                [105.3560328, 23.3388327],
                [105.3559121, 23.3389139],
                [105.3554669, 23.3391553],
                [105.3553623, 23.3392193],
                [105.3553033, 23.3392612],
                [105.3552496, 23.339303],
                [105.3551987, 23.3393597],
                [105.3551638, 23.339404],
                [105.3551075, 23.3394804],
                [105.3550646, 23.3395469],
                [105.3550056, 23.3396281],
                [105.3549466, 23.3397193],
                [105.3549224, 23.3398054],
                [105.3548983, 23.3398769],
                [105.3548473, 23.3399458],
                [105.3547523, 23.340088],
                [105.3542418, 23.3411605],
                [105.3537311, 23.3421333],
                [105.3533663, 23.3426243],
                [105.3527015, 23.3432926],
                [105.3523677, 23.3438088],
                [105.3518674, 23.3443994],
                [105.3514648, 23.3450981],
                [105.3512728, 23.3457152],
                [105.3511995, 23.3462995],
                [105.3511275, 23.3469919],
                [105.3510416, 23.3478127],
                [105.3510815, 23.3483927],
                [105.3511009, 23.3489558],
                [105.3512321, 23.3495388],
                [105.3512376, 23.349571],
                [105.3513256, 23.3500869],
                [105.3513219, 23.3501371],
                [105.351299, 23.3504507],
                [105.3512412, 23.350827],
                [105.3511227, 23.3513911],
                [105.3509396, 23.3517768],
                [105.3507168, 23.3523797],
                [105.3504463, 23.3528441],
                [105.3504237, 23.3528764],
                [105.3502309, 23.3531518],
                [105.3500757, 23.3533374],
                [105.3499094, 23.3535468],
                [105.3498369, 23.3537856],
                [105.3497701, 23.3541979],
                [105.3497649, 23.3542598],
                [105.349727, 23.3547115],
                [105.3497484, 23.3551252],
                [105.3498584, 23.3558171],
                [105.3500425, 23.3566286],
                [105.3500987, 23.3569361],
                [105.3503228, 23.3581629],
                [105.3505347, 23.3585921],
                [105.3508517, 23.3591439],
                [105.3509246, 23.3593936],
                [105.3508899, 23.3595257],
                [105.350835, 23.359615],
                [105.3507643, 23.3598877],
                [105.3507937, 23.3601547],
                [105.350872, 23.3605364],
                [105.3508535, 23.3606557],
                [105.3508081, 23.360751],
                [105.3508243, 23.360886],
                [105.3508455, 23.361062],
                [105.3508642, 23.3613427],
                [105.3508459, 23.3615003],
                [105.3507087, 23.3619657],
                [105.3506577, 23.362143],
                [105.3506736, 23.3623566],
                [105.3507253, 23.3627069],
                [105.3507795, 23.3633873],
                [105.3507462, 23.3643541],
                [105.350765, 23.3647555],
                [105.3507221, 23.3649968],
                [105.3505965, 23.3651589],
                [105.3503998, 23.3653617],
                [105.3501347, 23.366058],
                [105.3499093, 23.3665722],
                [105.3496125, 23.3670489],
                [105.3491058, 23.3677638],
                [105.3488829, 23.3682341],
                [105.3485293, 23.3686767],
                [105.348414, 23.3690585],
                [105.3482543, 23.3693389],
                [105.3479681, 23.3697515],
                [105.347367, 23.3701899],
                [105.3472325, 23.3702979],
                [105.3471547, 23.3703545],
                [105.3470501, 23.3704826],
                [105.3470045, 23.3705614],
                [105.3469214, 23.3706894],
                [105.3466907, 23.370943],
                [105.3465673, 23.3710612],
                [105.3464252, 23.3711793],
                [105.3461865, 23.3713714],
                [105.3460738, 23.3714896],
                [105.3458834, 23.3715708],
                [105.3457198, 23.3716595],
                [105.3454006, 23.3718786],
                [105.3449821, 23.3722282],
                [105.3446442, 23.3724252],
                [105.3438717, 23.3729225],
                [105.3434479, 23.3732475],
                [105.3426164, 23.3738877],
                [105.342375, 23.3741043],
                [105.3421712, 23.374321],
                [105.341903, 23.3746214],
                [105.3414255, 23.3750547],
                [105.3407979, 23.3755127],
                [105.340417, 23.3757786],
                [105.3397626, 23.3760149],
                [105.338518, 23.3765516],
                [105.338282, 23.3766994],
                [105.3381801, 23.3768175],
                [105.3381157, 23.3769013],
                [105.3380674, 23.3769948],
                [105.3380138, 23.3770834],
                [105.3378797, 23.3772065],
                [105.3377402, 23.3773149],
                [105.3373432, 23.3776103],
                [105.3363562, 23.378344],
                [105.3358895, 23.378797],
                [105.3352404, 23.3792894],
                [105.3347629, 23.3795996],
                [105.3344142, 23.3799886],
                [105.3342748, 23.3802003],
                [105.3341514, 23.3803727],
                [105.3339636, 23.3805549],
                [105.3333789, 23.3809882],
                [105.3332233, 23.3811999],
                [105.3331482, 23.3814166],
                [105.3330141, 23.3817071],
                [105.3325581, 23.3822339],
                [105.3321773, 23.3825343],
                [105.3320163, 23.3826278],
                [105.3317696, 23.3827066],
                [105.3315121, 23.3827755],
                [105.33126, 23.3828839],
                [105.3309381, 23.3830858],
                [105.330804, 23.3832335],
                [105.3302193, 23.3839031],
                [105.3301281, 23.3840902],
                [105.3300637, 23.3842822],
                [105.3300261, 23.3844595],
                [105.3299618, 23.3846121],
                [105.3298438, 23.3847205],
                [105.329715, 23.3848337],
                [105.3295755, 23.385011],
                [105.3294897, 23.3851636],
                [105.3293985, 23.3853409],
                [105.3291678, 23.385912],
                [105.3288245, 23.3864881],
                [105.3286689, 23.3872414],
                [105.328567, 23.3874679],
                [105.328567, 23.3876402],
                [105.3285402, 23.3878323],
                [105.3284651, 23.3879701],
                [105.3283524, 23.3881424],
                [105.3278214, 23.3890681],
                [105.3273788, 23.3896638],
                [105.3272152, 23.3899716],
                [105.3271696, 23.3900454],
                [105.3270328, 23.390235],
                [105.3267002, 23.3905796],
                [105.3259331, 23.3913625],
                [105.3255147, 23.3917514],
                [105.3253537, 23.391909],
                [105.325225, 23.3920419],
                [105.3250372, 23.392165],
                [105.3247744, 23.3923521],
                [105.3236586, 23.3926672],
                [105.3233474, 23.3926918],
                [105.322988, 23.3926524],
                [105.3225803, 23.3926032],
                [105.3220653, 23.3924161],
                [105.3219527, 23.3923324],
                [105.321781, 23.3921798],
                [105.321604, 23.3920075],
                [105.3214699, 23.3919139],
                [105.3212768, 23.3917711],
                [105.3211266, 23.3916727],
                [105.3208637, 23.3915348],
                [105.3206223, 23.3914019],
                [105.3203273, 23.391136],
                [105.3200054, 23.3905206],
                [105.3199357, 23.3903975],
                [105.319823, 23.3902301],
                [105.3197372, 23.3901267],
                [105.3196406, 23.3900085],
                [105.3194529, 23.3897426],
                [105.3192437, 23.3895752],
                [105.3188628, 23.3889622],
                [105.3186804, 23.3885856],
                [105.3185195, 23.3883886],
                [105.3183854, 23.3883148],
                [105.318203, 23.3882458],
                [105.3180259, 23.388172],
                [105.3178865, 23.3880538],
                [105.3177845, 23.3878963],
                [105.3176451, 23.3876649],
                [105.3175002, 23.3873891],
                [105.3173125, 23.3870789],
                [105.316481, 23.3865472],
                [105.3161377, 23.3863601],
                [105.3158587, 23.3861287],
                [105.315282, 23.3856535],
                [105.3151104, 23.385555],
                [105.3149575, 23.385496],
                [105.3147885, 23.3854344],
                [105.3146651, 23.3853458],
                [105.3145257, 23.3852325],
                [105.3143647, 23.385075],
                [105.3142896, 23.3848608],
                [105.3142279, 23.3846417],
                [105.314118, 23.384462],
                [105.3138015, 23.3841887],
                [105.3136781, 23.3840139],
                [105.3136405, 23.3839154],
                [105.3136137, 23.3838292],
                [105.3135842, 23.3837406],
                [105.3135198, 23.3836224],
                [105.3134313, 23.383556],
                [105.3132677, 23.3835043],
                [105.3131148, 23.3834403],
                [105.313037, 23.3833689],
                [105.3129807, 23.3832901],
                [105.3128788, 23.3831399],
                [105.3128117, 23.3830267],
                [105.3127018, 23.3828863],
                [105.3124791, 23.382778],
                [105.3122941, 23.3827977],
                [105.3122136, 23.3828002],
                [105.3121063, 23.3827928],
                [105.3119883, 23.3827632],
                [105.3119159, 23.3826721],
                [105.3117818, 23.3824456],
                [105.3116798, 23.3822881],
                [105.3116235, 23.3822019],
                [105.3115001, 23.382064],
                [105.3113151, 23.3819582],
                [105.3112534, 23.3819114],
                [105.3111327, 23.38184],
                [105.3109851, 23.3817908],
                [105.3105533, 23.3817341],
                [105.3103736, 23.3817292],
                [105.3102583, 23.3817194],
                [105.3101295, 23.3817194],
                [105.3100386, 23.3817232],
                [105.3099552, 23.3817268],
                [105.3098157, 23.3817021],
                [105.3097057, 23.3816751],
                [105.3096253, 23.3816332],
                [105.3094912, 23.381611],
                [105.3093141, 23.3816061],
                [105.3089198, 23.3816184],
                [105.3073935, 23.3819605],
                [105.3065354, 23.3797523],
                [105.3042313, 23.3763694],
                [105.3033838, 23.3750793],
                [105.3026649, 23.373996],
                [105.3020641, 23.3725705],
                [105.3026502, 23.3714908],
                [105.3019334, 23.3708334],
                [105.3015733, 23.3707017],
                [105.3000712, 23.3703472],
                [105.2989769, 23.3698547],
                [105.298188, 23.368529],
                [105.297996, 23.367952],
                [105.297707, 23.367374],
                [105.297419, 23.366531],
                [105.296505, 23.364843],
                [105.2961981, 23.364359],
                [105.295608, 23.3636991],
                [105.2948141, 23.3633446],
                [105.2942133, 23.3628029],
                [105.2933979, 23.3616801],
                [105.292802, 23.360403],
                [105.292321, 23.360048],
                [105.290542, 23.359914],
                [105.288859, 23.359248],
                [105.287945, 23.359026],
                [105.287464, 23.358671],
                [105.287464, 23.357739],
                [105.28732, 23.356184],
                [105.287272, 23.354985],
                [105.287368, 23.354142],
                [105.287657, 23.353165],
                [105.287657, 23.352765],
                [105.287753, 23.352366],
                [105.287513, 23.351966],
                [105.286887, 23.351211],
                [105.286118, 23.350545],
                [105.2857361, 23.3499206],
                [105.2857734, 23.3491741],
                [105.285637, 23.34868],
                [105.284579, 23.347792],
                [105.284435, 23.347437],
                [105.284531, 23.346904],
                [105.284291, 23.345128],
                [105.283858, 23.343751],
                [105.283906, 23.342996],
                [105.283858, 23.342685],
                [105.283665, 23.342463],
                [105.28304, 23.34233],
                [105.281165, 23.342153],
                [105.280011, 23.341842],
                [105.278568, 23.341176],
                [105.277414, 23.340865],
                [105.275052, 23.339643],
                [105.2737831, 23.3395195],
                [105.2728728, 23.339691],
                [105.2721866, 23.3399629],
                [105.2719858, 23.3402497],
                [105.2718343, 23.3404461],
                [105.2713957, 23.3404879],
                [105.2707775, 23.3406169],
                [105.2699558, 23.3410232],
                [105.2697915, 23.341181],
                [105.2694105, 23.341325],
                [105.2690048, 23.3412767],
                [105.2684358, 23.3414452],
                [105.2680148, 23.3416251],
                [105.267604, 23.342064],
                [105.266306, 23.342508],
                [105.26419, 23.342863],
                [105.261641, 23.343085],
                [105.258948, 23.343041],
                [105.257746, 23.342907],
                [105.256881, 23.342641],
                [105.256063, 23.342197],
                [105.255486, 23.341664],
                [105.254957, 23.340554],
                [105.254284, 23.339488],
                [105.252793, 23.33789],
                [105.252601, 23.336824],
                [105.252312, 23.336202],
                [105.251831, 23.33527],
                [105.250629, 23.333449],
                [105.2501961, 23.331984],
                [105.2506206, 23.3309333],
                [105.2522643, 23.3300507],
                [105.2534948, 23.3286374],
                [105.2541199, 23.3279102],
                [105.2547869, 23.3269291],
                [105.2549309, 23.326259],
                [105.2552221, 23.3250658],
                [105.2550437, 23.3224666],
                [105.2550068, 23.3219282],
                [105.256597, 23.320969],
                [105.25852, 23.319415],
                [105.258857, 23.31795],
                [105.258136, 23.316351],
                [105.257751, 23.314886],
                [105.257559, 23.313776],
                [105.257078, 23.312888],
                [105.255972, 23.312],
                [105.254805, 23.3113204],
                [105.253273, 23.3105718],
                [105.252365, 23.310046],
                [105.2510566, 23.3098857],
                [105.2501073, 23.3089623],
                [105.2501392, 23.307025],
                [105.2505684, 23.3058426],
                [105.250538, 23.30494],
                [105.2503967, 23.3037733],
                [105.2504611, 23.3022756],
                [105.2511048, 23.3013099],
                [105.2504182, 23.3004034],
                [105.2504182, 23.2999543],
                [105.2504182, 23.2995165],
                [105.2488518, 23.2991224],
                [105.2477789, 23.2977921],
                [105.2475321, 23.2963435],
                [105.2473068, 23.2948654],
                [105.2466845, 23.2938898],
                [105.2463519, 23.292865],
                [105.2456868, 23.2916923],
                [105.2445005, 23.2913591],
                [105.2439809, 23.2907758],
                [105.2428436, 23.2907265],
                [105.2410626, 23.2905393],
                [105.2400112, 23.2900269],
                [105.2385414, 23.2901057],
                [105.2376723, 23.2893075],
                [105.2354514, 23.2886965],
                [105.2340674, 23.2880362],
                [105.2347863, 23.2866368],
                [105.2355266, 23.2858977],
                [105.2355909, 23.2854247],
                [105.2349257, 23.2846166],
                [105.2346682, 23.2824682],
                [105.2358055, 23.2829018],
                [105.2388954, 23.2815024],
                [105.2396893, 23.279689],
                [105.2424574, 23.2772399],
                [105.2424755, 23.2771536],
                [105.2425432, 23.2768309],
                [105.2410197, 23.276141],
                [105.2384019, 23.276279],
                [105.2381873, 23.2744853],
                [105.2389383, 23.2720213],
                [105.2376938, 23.2690251],
                [105.2365779, 23.2676847],
                [105.2364921, 23.2673298],
                [105.2373933, 23.2665807],
                [105.2375006, 23.2650431],
                [105.2361274, 23.2635647],
                [105.234518, 23.2613371],
                [105.23278, 23.2607062],
                [105.2317714, 23.2606274],
                [105.2294326, 23.2617116],
                [105.2279949, 23.2606668],
                [105.2273083, 23.2601542],
                [105.2267504, 23.2603908],
                [105.2265358, 23.2609231],
                [105.2265787, 23.2622439],
                [105.2260744, 23.2641265],
                [105.2255273, 23.2648854],
                [105.2255487, 23.2653388],
                [105.2258277, 23.2659401],
                [105.2264285, 23.2668074],
                [105.2249586, 23.2671031],
                [105.223521, 23.2691828],
                [105.2224374, 23.2697248],
                [105.2214718, 23.270602],
                [105.2176094, 23.2692813],
                [105.2156972, 23.2680319],
                [105.2145624, 23.2672904],
                [105.2129745, 23.2668961],
                [105.2118158, 23.2672608],
                [105.2111184, 23.2671425],
                [105.2104747, 23.2672509],
                [105.2102172, 23.2680493],
                [105.2106035, 23.2689561],
                [105.2107, 23.2693799],
                [105.2095628, 23.2700304],
                [105.2083933, 23.2717749],
                [105.2078462, 23.2723465],
                [105.2069664, 23.2722776],
                [105.2056918, 23.2716921],
                [105.2052498, 23.2714891],
                [105.20437, 23.2712821],
                [105.2035117, 23.2714497],
                [105.2020419, 23.2729872],
                [105.1998103, 23.273618],
                [105.1984906, 23.2745247],
                [105.1982331, 23.2757271],
                [105.1985121, 23.2768112],
                [105.1991772, 23.2782107],
                [105.1984048, 23.2788217],
                [105.1971173, 23.2802606],
                [105.1959801, 23.2812264],
                [105.1955294, 23.2813447],
                [105.1943064, 23.2803789],
                [105.1933408, 23.2792751],
                [105.1919889, 23.2792357],
                [105.1907229, 23.2800044],
                [105.1894569, 23.2794328],
                [105.187633, 23.2789794],
                [105.1865031, 23.2781325],
                [105.1849508, 23.2769689],
                [105.1829123, 23.2769689],
                [105.1822471, 23.2788414],
                [105.1826978, 23.2815024],
                [105.1818824, 23.2826653],
                [105.1806164, 23.2834143],
                [105.1804018, 23.284656],
                [105.1802945, 23.2851488],
                [105.1809811, 23.2866664],
                [105.179007, 23.2878096],
                [105.1759386, 23.2885585],
                [105.1741147, 23.2883812],
                [105.1714754, 23.2891892],
                [105.1708102, 23.28846],
                [105.1704883, 23.2861343],
                [105.1716685, 23.2836114],
                [105.1712394, 23.2826259],
                [105.1705313, 23.2824091],
                [105.1698231, 23.283158],
                [105.1689219, 23.283296],
                [105.1681924, 23.2827638],
                [105.1679563, 23.2820543],
                [105.1690507, 23.2805563],
                [105.1684284, 23.2790386],
                [105.1670766, 23.2779742],
                [105.1660466, 23.2779742],
                [105.164051, 23.2766535],
                [105.1640081, 23.2754117],
                [105.1620769, 23.2727506],
                [105.1600814, 23.2700304],
                [105.159502, 23.2689068],
                [105.1567125, 23.266699],
                [105.154717, 23.2655655],
                [105.1537192, 23.2652994],
                [105.1524961, 23.2653585],
                [105.1502752, 23.2650727],
                [105.1497602, 23.2648756],
                [105.1494491, 23.2643926],
                [105.148787, 23.26382],
                [105.1464021, 23.2617707],
                [105.145694, 23.2616426],
                [105.1445031, 23.2609231],
                [105.142926, 23.2611202],
                [105.1415527, 23.2617116],
                [105.1400506, 23.2607851],
                [105.1384413, 23.2599275],
                [105.1378083, 23.2593755],
                [105.1366389, 23.258794],
                [105.1360702, 23.2581632],
                [105.1351476, 23.255758],
                [105.1319504, 23.25631],
                [105.128839, 23.2566649],
                [105.1266718, 23.2569606],
                [105.125369, 23.257204],
                [105.12484, 23.256849],
                [105.1248693, 23.2560932],
                [105.1255345, 23.2548117],
                [105.1247406, 23.2534712],
                [105.1236677, 23.2526234],
                [105.122195, 23.252098],
                [105.1211302, 23.2513804],
                [105.120416, 23.250899],
                [105.1194596, 23.2505151],
                [105.11931, 23.250455],
                [105.1184749, 23.2501591],
                [105.1174664, 23.2498633],
                [105.1161575, 23.2498436],
                [105.115385, 23.2494493],
                [105.1145267, 23.2489959],
                [105.1132607, 23.2486804],
                [105.1125955, 23.2467286],
                [105.1119733, 23.2460977],
                [105.1111793, 23.2467089],
                [105.110321, 23.2468666],
                [105.1082611, 23.2457428],
                [105.107553, 23.2457625],
                [105.1071024, 23.246078],
                [105.1062012, 23.2483058],
                [105.1049566, 23.2488973],
                [105.0990987, 23.2475566],
                [105.0986266, 23.2478918],
                [105.0982618, 23.249193],
                [105.0980472, 23.249883],
                [105.0995707, 23.2501591],
                [105.1005149, 23.2507505],
                [105.10221, 23.2527811],
                [105.1021457, 23.2546343],
                [105.1025748, 23.25562],
                [105.1024675, 23.2564086],
                [105.1019954, 23.257276],
                [105.1002359, 23.2572169],
                [105.0989485, 23.2578872],
                [105.098176, 23.259484],
                [105.0974893, 23.2601148],
                [105.0964808, 23.2608442],
                [105.094893, 23.2615539],
                [105.0933695, 23.2611399],
                [105.0933051, 23.2627564],
                [105.0926184, 23.2637815],
                [105.0917601, 23.2639392],
                [105.0901723, 23.2632492],
                [105.0884235, 23.2632098],
                [105.0881874, 23.2632492],
                [105.0865888, 23.2653585],
                [105.0849366, 23.26526],
                [105.0837993, 23.2663245],
                [105.0838208, 23.2667779],
                [105.0819111, 23.2669158],
                [105.0798297, 23.2662456],
                [105.0785422, 23.2655557],
                [105.0771904, 23.2647869],
                [105.0761604, 23.2637026],
                [105.0756562, 23.2621354],
                [105.0751734, 23.26182],
                [105.0741756, 23.2616919],
                [105.072577, 23.2615539],
                [105.0714719, 23.2611005],
                [105.071032, 23.2605189],
                [105.0707853, 23.2593953],
                [105.070442, 23.2583406],
                [105.069766, 23.2576604],
                [105.0689185, 23.2574732],
                [105.0671589, 23.257483],
                [105.0661611, 23.2572859],
                [105.0655818, 23.2562903],
                [105.0650132, 23.2556003],
                [105.063833, 23.255275],
                [105.0630712, 23.2556595],
                [105.0621807, 23.2559946],
                [105.0615799, 23.2558467],
                [105.0605983, 23.2553622],
                [105.0602817, 23.255206],
                [105.0598635, 23.2549649],
                [105.0592732, 23.2546244],
                [105.058887, 23.2537866],
                [105.0588977, 23.2514208],
                [105.0588763, 23.2501985],
                [105.0585651, 23.2492423],
                [105.0579536, 23.2486213],
                [105.0571382, 23.2479509],
                [105.0565052, 23.2480199],
                [105.0554967, 23.2487593],
                [105.0544882, 23.2490451],
                [105.0532544, 23.2488677],
                [105.051924, 23.2479214],
                [105.0509584, 23.2477242],
                [105.0505078, 23.2472018],
                [105.0505507, 23.2461174],
                [105.050379, 23.2450232],
                [105.049907, 23.2444218],
                [105.04686, 23.2436726],
                [105.0471175, 23.2430516],
                [105.0470424, 23.2418292],
                [105.047155, 23.2403258],
                [105.04583, 23.239409],
                [105.0450897, 23.2382407],
                [105.0439417, 23.2364958],
                [105.043189, 23.23549],
                [105.0424504, 23.2345536],
                [105.0419891, 23.2336959],
                [105.0414097, 23.233548],
                [105.0390386, 23.2332917],
                [105.0371396, 23.2328579],
                [105.0359166, 23.2325227],
                [105.0356269, 23.2318425],
                [105.0353908, 23.2315319],
                [105.0345325, 23.2308862],
                [105.0338244, 23.2299397],
                [105.0334167, 23.2288749],
                [105.0320756, 23.2282439],
                [105.0314104, 23.2276622],
                [105.0306702, 23.2270017],
                [105.0301766, 23.22642],
                [105.0293612, 23.2257988],
                [105.0290501, 23.225296],
                [105.0287068, 23.2241819],
                [105.0285566, 23.2229889],
                [105.0284171, 23.22221],
                [105.028063, 23.2215691],
                [105.02743, 23.2211353],
                [105.0259495, 23.2202282],
                [105.025177, 23.2190943],
                [105.0223982, 23.2184633],
                [105.0203705, 23.217635],
                [105.0186002, 23.2177731],
                [105.017817, 23.2174181],
                [105.0154674, 23.2169153],
                [105.0146735, 23.2169153],
                [105.0130856, 23.2167871],
                [105.0116479, 23.2163532],
                [105.0084937, 23.2165406],
                [105.0066805, 23.2172406],
                [105.0059831, 23.2173392],
                [105.0048137, 23.2172209],
                [105.0041056, 23.2169448],
                [105.0030219, 23.216018],
                [105.0017667, 23.2151503],
                [105.0005865, 23.2144798],
                [104.9993849, 23.2137699],
                [104.9988699, 23.211788],
                [104.9985802, 23.2106639],
                [104.9989557, 23.2090566],
                [104.9989343, 23.2082086],
                [104.998033, 23.2071732],
                [104.9974108, 23.2045305],
                [104.9966597, 23.2032486],
                [104.9963057, 23.2029035],
                [104.9958551, 23.20279],
                [104.9947125, 23.202726],
                [104.99372, 23.2024351],
                [104.9913168, 23.2023611],
                [104.9895572, 23.2021836],
                [104.9883985, 23.2016314],
                [104.9875188, 23.2008819],
                [104.9869609, 23.200803],
                [104.9859524, 23.2015919],
                [104.9849438, 23.2018483],
                [104.9840426, 23.2026964],
                [104.9832916, 23.2040572],
                [104.9812102, 23.2040178],
                [104.9794292, 23.2050433],
                [104.9788606, 23.2050236],
                [104.9776268, 23.2042741],
                [104.9766183, 23.2038896],
                [104.9743223, 23.2039684],
                [104.9718976, 23.2030415],
                [104.9705887, 23.2028246],
                [104.967531, 23.2001719],
                [104.9660504, 23.1994422],
                [104.9638188, 23.1993239],
                [104.9626815, 23.1986829],
                [104.9617803, 23.1972825],
                [104.9607664, 23.1959266],
                [104.9594468, 23.1948418],
                [104.9592483, 23.1936338],
                [104.9582183, 23.1931505],
                [104.9572098, 23.1924503],
                [104.9569309, 23.1918488],
                [104.956904, 23.1914543],
                [104.9568826, 23.1910302],
                [104.9570757, 23.1904188],
                [104.9578536, 23.1889839],
                [104.9581325, 23.1885154],
                [104.9579823, 23.18747],
                [104.9570703, 23.185754],
                [104.9570489, 23.1836928],
                [104.9567914, 23.1824402],
                [104.9559224, 23.1803494],
                [104.954077, 23.1775582],
                [104.9533796, 23.1764634],
                [104.9526715, 23.1753785],
                [104.9519527, 23.17383],
                [104.9508261, 23.1730113],
                [104.9499678, 23.1717685],
                [104.9487018, 23.1707033],
                [104.9470496, 23.1703482],
                [104.9475002, 23.1684939],
                [104.9471569, 23.1669354],
                [104.948616, 23.1647259],
                [104.951663, 23.1630293],
                [104.9508047, 23.1619838],
                [104.9504828, 23.1608001],
                [104.9495601, 23.1598926],
                [104.9502253, 23.1575646],
                [104.9503112, 23.1550985],
                [104.9485945, 23.1538555],
                [104.9483371, 23.1519812],
                [104.947586, 23.1510145],
                [104.9435305, 23.1518826],
                [104.9421358, 23.1529085],
                [104.9408698, 23.1531847],
                [104.9333596, 23.1572884],
                [104.9317717, 23.1577224],
                [104.9290895, 23.1564992],
                [104.9279737, 23.1564006],
                [104.9273729, 23.156736],
                [104.926579, 23.1585708],
                [104.9246692, 23.1602674],
                [104.9249053, 23.1611946],
                [104.9259567, 23.1626151],
                [104.9258709, 23.1636212],
                [104.9272012, 23.1651402],
                [104.925549, 23.1647654],
                [104.9220514, 23.166896],
                [104.9220514, 23.169638],
                [104.9201631, 23.1698945],
                [104.9170518, 23.169066],
                [104.9163008, 23.1693816],
                [104.917674, 23.1736426],
                [104.9168587, 23.1756941],
                [104.9142837, 23.1770552],
                [104.9090266, 23.180665],
                [104.906769, 23.17981],
                [104.904701, 23.179818],
                [104.904064, 23.179821],
                [104.904037, 23.179821],
                [104.901512, 23.179121],
                [104.90023, 23.179011],
                [104.899134, 23.179555],
                [104.898842, 23.1797],
                [104.897262, 23.179711],
                [104.894639, 23.17812],
                [104.894638, 23.17812],
                [104.892722, 23.176612],
                [104.890893, 23.175631],
                [104.889183, 23.174351],
                [104.888062, 23.17123],
                [104.886222, 23.169072],
                [104.885208, 23.16822],
                [104.884827, 23.167901],
                [104.883881, 23.167601],
                [104.882597, 23.166822],
                [104.881256, 23.16601],
                [104.880707, 23.16518],
                [104.881073, 23.161752],
                [104.8816, 23.1611],
                [104.88205, 23.16053],
                [104.882362, 23.159461],
                [104.882362, 23.15946],
                [104.882569, 23.156081],
                [104.882286, 23.15398],
                [104.880791, 23.152639],
                [104.880531, 23.1524],
                [104.880058, 23.151371],
                [104.880057, 23.15137],
                [104.879837, 23.15016],
                [104.880141, 23.14867],
                [104.880867, 23.14749],
                [104.881835, 23.145921],
                [104.881835, 23.14592],
                [104.881782, 23.14474],
                [104.87999, 23.141741],
                [104.877837, 23.137381],
                [104.8773122, 23.1350522],
                [104.876862, 23.13493],
                [104.8772263, 23.1334933],
                [104.8769045, 23.1322107],
                [104.8769474, 23.1312241],
                [104.8789215, 23.1291324],
                [104.8789, 23.1283036],
                [104.877162, 23.127613],
                [104.8765826, 23.126429],
                [104.8761749, 23.1254226],
                [104.8746943, 23.1247911],
                [104.8736858, 23.12487],
                [104.8726773, 23.1258567],
                [104.871941, 23.125668],
                [104.871125, 23.125546],
                [104.871018, 23.12553],
                [104.870163, 23.125974],
                [104.869117, 23.126521],
                [104.867957, 23.126461],
                [104.867317, 23.126051],
                [104.8667, 23.125242],
                [104.866332, 23.124759],
                [104.86531, 23.124042],
                [104.865279, 23.124021],
                [104.86499, 23.123811],
                [104.864313, 23.123593],
                [104.864085, 23.123519],
                [104.863992, 23.12349],
                [104.862747, 23.123371],
                [104.861763, 23.123799],
                [104.86081, 23.125061],
                [104.860778, 23.125673],
                [104.8602748, 23.1285996],
                [104.860599, 23.128661],
                [104.8589873, 23.1291324],
                [104.856863, 23.1284418],
                [104.856627, 23.1269618],
                [104.855833, 23.1261527],
                [104.8542237, 23.123094],
                [104.8518419, 23.1229756],
                [104.8507261, 23.1214561],
                [104.8486447, 23.1213772],
                [104.8470139, 23.11968],
                [104.844439, 23.1182394],
                [104.8420357, 23.1178052],
                [104.84092, 23.118417],
                [104.8404693, 23.1183578],
                [104.840126, 23.1178842],
                [104.840126, 23.1158712],
                [104.8399758, 23.1115887],
                [104.839611, 23.1114111],
                [104.8389673, 23.1119834],
                [104.8365855, 23.1157331],
                [104.8355341, 23.1158318],
                [104.8335385, 23.1153779],
                [104.8321652, 23.1155949],
                [104.8310494, 23.1152595],
                [104.8306632, 23.1141543],
                [104.8294616, 23.1114111],
                [104.8273802, 23.1083915],
                [104.8261785, 23.1077205],
                [104.8249554, 23.1078192],
                [104.8237324, 23.1088849],
                [104.8225093, 23.1116479],
                [104.8213076, 23.1137399],
                [104.8221445, 23.1166606],
                [104.8216724, 23.1173119],
                [104.8202348, 23.1176868],
                [104.8184323, 23.1175092],
                [104.815042, 23.1173513],
                [104.8119736, 23.1176276],
                [104.8101496, 23.1166606],
                [104.8085403, 23.1166014],
                [104.8060083, 23.1176276],
                [104.8030472, 23.1167593],
                [104.8018241, 23.1162166],
                [104.8012769, 23.1156245],
                [104.8011911, 23.1148943],
                [104.8015237, 23.1138089],
                [104.8017812, 23.1129702],
                [104.801849, 23.110831],
                [104.801849, 23.11083],
                [104.802063, 23.106501],
                [104.802375, 23.104151],
                [104.804013, 23.100002],
                [104.804413, 23.098989],
                [104.804482, 23.098546],
                [104.805351, 23.092991],
                [104.805472, 23.09174],
                [104.805108, 23.08864],
                [104.806183, 23.087339],
                [104.806168, 23.08613],
                [104.805329, 23.084711],
                [104.805977, 23.082951],
                [104.806339, 23.082417],
                [104.806472, 23.082221],
                [104.807373, 23.081271],
                [104.809067, 23.08069],
                [104.813845, 23.080277],
                [104.815422, 23.08014],
                [104.816139, 23.07917],
                [104.816221, 23.078802],
                [104.816299, 23.078452],
                [104.816117, 23.077988],
                [104.815956, 23.077579],
                [104.81604, 23.07667],
                [104.817146, 23.075701],
                [104.817351, 23.075149],
                [104.816513, 23.07424],
                [104.814598, 23.073166],
                [104.811951, 23.07168],
                [104.811927, 23.071091],
                [104.812463, 23.070481],
                [104.813811, 23.069886],
                [104.814026, 23.069791],
                [104.814614, 23.069183],
                [104.81514, 23.06864],
                [104.81516, 23.068489],
                [104.8153, 23.06743],
                [104.815247, 23.06678],
                [104.814509, 23.06516],
                [104.814491, 23.065121],
                [104.814346, 23.063341],
                [104.81385, 23.06259],
                [104.813668, 23.06168],
                [104.814397, 23.061011],
                [104.814583, 23.060841],
                [104.814658, 23.060461],
                [104.814277, 23.059869],
                [104.813737, 23.059031],
                [104.813652, 23.058491],
                [104.814056, 23.058041],
                [104.815063, 23.057874],
                [104.816858, 23.057576],
                [104.818573, 23.057291],
                [104.81961, 23.057021],
                [104.820832, 23.055371],
                [104.820677, 23.054883],
                [104.820533, 23.054429],
                [104.820426, 23.054091],
                [104.820443, 23.05279],
                [104.821036, 23.051189],
                [104.820923, 23.04442],
                [104.821003, 23.043343],
                [104.821121, 23.041741],
                [104.821121, 23.04174],
                [104.820735, 23.039618],
                [104.820648, 23.039141],
                [104.820076, 23.03821],
                [104.820076, 23.038209],
                [104.820061, 23.0368],
                [104.820686, 23.035471],
                [104.821007, 23.03384],
                [104.821677, 23.033111],
                [104.821678, 23.03311],
                [104.822343, 23.032851],
                [104.82236, 23.03285],
                [104.82412, 23.032841],
                [104.825287, 23.03193],
                [104.825782, 23.030519],
                [104.825792, 23.030112],
                [104.825829, 23.02865],
                [104.826256, 23.0277],
                [104.82734, 23.026744],
                [104.827797, 23.02634],
                [104.828346, 23.025619],
                [104.828613, 23.024361],
                [104.828539, 23.023884],
                [104.828148, 23.021371],
                [104.828293, 23.01962],
                [104.82975, 23.015661],
                [104.829956, 23.011445],
                [104.82999, 23.010738],
                [104.830078, 23.00894],
                [104.831032, 23.005351],
                [104.831031, 23.004558],
                [104.8316717, 23.0033748],
                [104.8308348, 23.002644],
                [104.8292255, 22.9990294],
                [104.8295688, 22.9981998],
                [104.8300838, 22.9980418],
                [104.8323798, 22.9989701],
                [104.8327875, 22.9989109],
                [104.8336029, 22.9974887],
                [104.8338389, 22.9975282],
                [104.8344826, 22.9980418],
                [104.8361134, 22.9977455],
                [104.8372507, 22.9972319],
                [104.83783, 22.9953752],
                [104.8369288, 22.9939333],
                [104.8369074, 22.9931827],
                [104.8378944, 22.991958],
                [104.8389673, 22.9916814],
                [104.8388815, 22.9908715],
                [104.8382163, 22.9899234],
                [104.8354053, 22.9887777],
                [104.8359418, 22.9865653],
                [104.8397827, 22.9839775],
                [104.8437953, 22.98538],
                [104.8461771, 22.9834639],
                [104.8480654, 22.9807476],
                [104.8478578, 22.9790515],
                [104.8478079, 22.9786437],
                [104.8478508, 22.9781894],
                [104.8502326, 22.9782486],
                [104.8509514, 22.9789006],
                [104.8527861, 22.9775374],
                [104.8533547, 22.9775276],
                [104.8547387, 22.9788413],
                [104.8556292, 22.9790882],
                [104.8598456, 22.9783869],
                [104.8606825, 22.9780313],
                [104.8608756, 22.9773399],
                [104.8605001, 22.976688],
                [104.8605215, 22.9760854],
                [104.8618626, 22.9751964],
                [104.8616373, 22.9740802],
                [104.8622167, 22.972727],
                [104.8613906, 22.9714527],
                [104.8615837, 22.9702871],
                [104.8606181, 22.9686671],
                [104.8605966, 22.9681534],
                [104.8622274, 22.9662568],
                [104.8623347, 22.9654072],
                [104.8625064, 22.9646762],
                [104.8636007, 22.9639452],
                [104.8646307, 22.9639057],
                [104.864738, 22.9636489],
                [104.8645448, 22.9630957],
                [104.865489, 22.9623646],
                [104.865489, 22.9613175],
                [104.8648453, 22.9596381],
                [104.8646951, 22.9581167],
                [104.8650169, 22.9562595],
                [104.8650813, 22.9542639],
                [104.8642444, 22.9527227],
                [104.8639011, 22.9508061],
                [104.8644161, 22.9492846],
                [104.8642444, 22.9485337],
                [104.8646951, 22.9473087],
                [104.8641157, 22.9450758],
                [104.8630428, 22.9428232],
                [104.8616695, 22.9416375],
                [104.8589444, 22.9408076],
                [104.8567986, 22.9402938],
                [104.8537946, 22.9391675],
                [104.8521638, 22.9384363],
                [104.850462, 22.937469],
                [104.8499388, 22.9372528],
                [104.8496211, 22.9370876],
                [104.8494957, 22.93681],
                [104.8494653, 22.9365205],
                [104.8492429, 22.9363268],
                [104.8484033, 22.9353931],
                [104.8470569, 22.9342074],
                [104.845479, 22.933135],
                [104.8439884, 22.9307293],
                [104.8425293, 22.9296424],
                [104.8416066, 22.9284567],
                [104.8396969, 22.9244646],
                [104.8398042, 22.9220733],
                [104.8394179, 22.9214211],
                [104.8371863, 22.9223302],
                [104.833274, 22.923932],
                [104.8314786, 22.9242868],
                [104.8294616, 22.925018],
                [104.8283458, 22.9244844],
                [104.8270583, 22.9245437],
                [104.8242473, 22.9243065],
                [104.821358, 22.923465],
                [104.820925, 22.923532],
                [104.820203, 22.923399],
                [104.819697, 22.922999],
                [104.817748, 22.921998],
                [104.816448, 22.921131],
                [104.8153531, 22.9208579],
                [104.8141622, 22.9209171],
                [104.8132718, 22.9210456],
                [104.8124349, 22.9209962],
                [104.8109436, 22.9198993],
                [104.8099244, 22.9188518],
                [104.8091119, 22.918183],
                [104.8085018, 22.9169403],
                [104.8082507, 22.915907],
                [104.8087549, 22.915156],
                [104.806049, 22.914863],
                [104.805471, 22.914863],
                [104.804965, 22.914663],
                [104.804532, 22.914196],
                [104.803954, 22.913329],
                [104.802582, 22.911795],
                [104.801499, 22.911329],
                [104.800127, 22.912129],
                [104.799477, 22.912196],
                [104.798755, 22.911595],
                [104.798322, 22.911529],
                [104.797816, 22.911595],
                [104.797166, 22.911595],
                [104.793844, 22.906927],
                [104.792978, 22.906394],
                [104.792039, 22.905994],
                [104.791244, 22.90586],
                [104.790089, 22.905394],
                [104.789367, 22.904993],
                [104.787922, 22.904793],
                [104.786406, 22.904927],
                [104.784528, 22.904927],
                [104.783084, 22.90506],
                [104.782506, 22.904393],
                [104.782073, 22.901926],
                [104.781856, 22.901059],
                [104.781351, 22.901126],
                [104.778462, 22.901059],
                [104.775862, 22.900926],
                [104.77514, 22.900592],
                [104.774129, 22.899592],
                [104.773624, 22.898858],
                [104.772757, 22.897791],
                [104.771168, 22.895657],
                [104.770952, 22.895457],
                [104.771241, 22.89419],
                [104.771457, 22.892857],
                [104.770374, 22.89179],
                [104.769796, 22.891323],
                [104.770013, 22.890723],
                [104.770157, 22.889856],
                [104.770374, 22.889256],
                [104.76958, 22.888389],
                [104.769002, 22.887989],
                [104.766835, 22.887188],
                [104.766258, 22.886721],
                [104.765897, 22.886321],
                [104.766041, 22.883721],
                [104.766185, 22.88212],
                [104.76568, 22.88032],
                [104.765174, 22.879386],
                [104.764813, 22.878986],
                [104.764597, 22.878452],
                [104.764524, 22.877986],
                [104.764669, 22.876719],
                [104.764524, 22.875452],
                [104.764597, 22.874385],
                [104.764597, 22.873584],
                [104.764308, 22.872784],
                [104.763947, 22.871984],
                [104.76373, 22.871184],
                [104.762502, 22.86945],
                [104.761419, 22.868249],
                [104.761202, 22.867316],
                [104.760625, 22.866049],
                [104.760047, 22.865515],
                [104.758314, 22.864115],
                [104.757881, 22.863315],
                [104.757592, 22.862314],
                [104.757592, 22.861781],
                [104.757158, 22.860314],
                [104.7561592, 22.8581997],
                [104.745964, 22.856917],
                [104.7432211, 22.8571916],
                [104.7385905, 22.8587709],
                [104.7363644, 22.8580898],
                [104.7328207, 22.8568501],
                [104.7294785, 22.8523886],
                [104.7264862, 22.849526],
                [104.728, 22.8461078],
                [104.7314851, 22.843938],
                [104.7341772, 22.8418488],
                [104.7341186, 22.8398547],
                [104.735565, 22.834578],
                [104.734987, 22.833311],
                [104.7353581, 22.8301168],
                [104.7355308, 22.8275886],
                [104.7346383, 22.8254653],
                [104.7329406, 22.8233362],
                [104.726682, 22.820507],
                [104.7231365, 22.820305],
                [104.718016, 22.822975],
                [104.7133678, 22.8224706],
                [104.708845, 22.824442],
                [104.703036, 22.826744],
                [104.7005385, 22.8263115],
                [104.6958341, 22.8245714],
                [104.6939261, 22.8243239],
                [104.6919146, 22.8237136],
                [104.6887786, 22.82276],
                [104.6842087, 22.8216713],
                [104.6802879, 22.8197046],
                [104.6789885, 22.8194541],
                [104.6778946, 22.8194637],
                [104.6764194, 22.8196823],
                [104.6734284, 22.8183142],
                [104.6707975, 22.8206366],
                [104.669755, 22.8213328],
                [104.6683726, 22.8233878],
                [104.6674753, 22.8243204],
                [104.6665363, 22.8252905],
                [104.66566, 22.826642],
                [104.664576, 22.827442],
                [104.663565, 22.828509],
                [104.6615019, 22.8290919],
                [104.6600167, 22.8297554],
                [104.6589638, 22.8310795],
                [104.658077, 22.8326873],
                [104.6572409, 22.8336958],
                [104.6556833, 22.8344661],
                [104.6537025, 22.8350465],
                [104.649194, 22.835444],
                [104.647389, 22.835378],
                [104.6445002, 22.836461],
                [104.6405917, 22.8364374],
                [104.6402127, 22.835337],
                [104.6390494, 22.8341624],
                [104.637953, 22.8335192],
                [104.6365724, 22.833637],
                [104.6356424, 22.8345996],
                [104.6343857, 22.835054],
                [104.6324918, 22.8353718],
                [104.6311906, 22.8357052],
                [104.6300727, 22.8364072],
                [104.629021, 22.8373981],
                [104.6281213, 22.8380344],
                [104.6269778, 22.8385221],
                [104.6266014, 22.8390044],
                [104.6266499, 22.8395168],
                [104.6268072, 22.839766],
                [104.6271733, 22.8400392],
                [104.6263515, 22.8414608],
                [104.6259892, 22.8425821],
                [104.6259679, 22.843233],
                [104.6257162, 22.8442779],
                [104.6252744, 22.8452449],
                [104.6247343, 22.8460547],
                [104.6233656, 22.8472997],
                [104.6232451, 22.8471401],
                [104.6235914, 22.8466545],
                [104.6235763, 22.8463285],
                [104.623343, 22.846044],
                [104.6231096, 22.8457249],
                [104.6230644, 22.8451075],
                [104.6227784, 22.8448924],
                [104.6224773, 22.844934],
                [104.6217998, 22.845267],
                [104.6214761, 22.8454405],
                [104.6210771, 22.8454821],
                [104.6208512, 22.8453225],
                [104.6207082, 22.8449132],
                [104.6208286, 22.8444484],
                [104.6212728, 22.8441224],
                [104.6214008, 22.8439212],
                [104.621461, 22.8435604],
                [104.6211147, 22.8430748],
                [104.6212502, 22.8426793],
                [104.621318, 22.8424227],
                [104.6210921, 22.8423186],
                [104.6203318, 22.842832],
                [104.6201135, 22.8428528],
                [104.6199629, 22.842721],
                [104.6199328, 22.8421798],
                [104.6198274, 22.8415069],
                [104.6196693, 22.8412016],
                [104.6192779, 22.8409796],
                [104.6189843, 22.8407368],
                [104.618876, 22.8404341],
                [104.6188691, 22.8400306],
                [104.6191763, 22.8396345],
                [104.6190822, 22.8393978],
                [104.6186519, 22.8390908],
                [104.6184357, 22.8386668],
                [104.618606, 22.838058],
                [104.6187804, 22.8375078],
                [104.6196455, 22.8371497],
                [104.6196814, 22.8364463],
                [104.6196005, 22.836109],
                [104.6190438, 22.8361112],
                [104.6185077, 22.835728],
                [104.6180591, 22.8356272],
                [104.6177419, 22.8350222],
                [104.6182889, 22.8346088],
                [104.6181248, 22.8339534],
                [104.618442, 22.8333585],
                [104.6191532, 22.8331468],
                [104.6196674, 22.833409],
                [104.620838, 22.8336711],
                [104.6213412, 22.8335199],
                [104.6215885, 22.833224],
                [104.6217108, 22.8325537],
                [104.6222165, 22.8315385],
                [104.622474, 22.83091],
                [104.6228578, 22.8307789],
                [104.6236659, 22.831138],
                [104.6241159, 22.8308329],
                [104.62428, 22.830377],
                [104.6243283, 22.8301201],
                [104.6242472, 22.8297302],
                [104.623124, 22.829376],
                [104.6224953, 22.8292726],
                [104.622258, 22.829043],
                [104.62233, 22.828776],
                [104.622908, 22.828576],
                [104.623197, 22.828576],
                [104.623527, 22.8277926],
                [104.6233784, 22.8274687],
                [104.622619, 22.827309],
                [104.622186, 22.8270603],
                [104.621897, 22.826442],
                [104.621752, 22.826042],
                [104.621824, 22.825375],
                [104.621752, 22.825042],
                [104.621608, 22.824775],
                [104.620658, 22.8247282],
                [104.6204611, 22.8245383],
                [104.620597, 22.823842],
                [104.621061, 22.823308],
                [104.6212096, 22.8228777],
                [104.6212096, 22.8223906],
                [104.6211168, 22.8220629],
                [104.6205815, 22.822078],
                [104.619627, 22.8226595],
                [104.6194649, 22.8238174],
                [104.61908, 22.824042],
                [104.618791, 22.824175],
                [104.6184783, 22.8248367],
                [104.617564, 22.825175],
                [104.6169623, 22.8251935],
                [104.6165639, 22.825067],
                [104.6162548, 22.8247697],
                [104.616325, 22.8244221],
                [104.61713, 22.8241954],
                [104.6173734, 22.8227318],
                [104.617564, 22.822108],
                [104.617347, 22.821241],
                [104.6164299, 22.8196276],
                [104.6163597, 22.8190832],
                [104.615831, 22.818574],
                [104.614819, 22.818173],
                [104.6147516, 22.8177425],
                [104.6143359, 22.8174854],
                [104.6139357, 22.816506],
                [104.6129502, 22.8163161],
                [104.6129335, 22.8167083],
                [104.612725, 22.817173],
                [104.6123537, 22.8176259],
                [104.6114347, 22.8177873],
                [104.6110184, 22.8180537],
                [104.6108717, 22.8186347],
                [104.6109313, 22.8191006],
                [104.6107623, 22.819695],
                [104.6102499, 22.8197776],
                [104.6099245, 22.8193658],
                [104.6095416, 22.8189291],
                [104.6092106, 22.8183997],
                [104.609042, 22.81804],
                [104.6081841, 22.8175557],
                [104.60734, 22.8176081],
                [104.6068322, 22.8178484],
                [104.6064967, 22.8184547],
                [104.605792, 22.81864],
                [104.605215, 22.81864],
                [104.604854, 22.818574],
                [104.604204, 22.818374],
                [104.6035431, 22.8182383],
                [104.6028027, 22.8181473],
                [104.602357, 22.8179831],
                [104.601965, 22.817586],
                [104.6014882, 22.8166201],
                [104.600882, 22.8161547],
                [104.6000305, 22.8162152],
                [104.5995291, 22.8163209],
                [104.5990961, 22.8164604],
                [104.5978927, 22.8168638],
                [104.5974897, 22.8172417],
                [104.5973603, 22.8176095],
                [104.597529, 22.8180369],
                [104.5980495, 22.8186066],
                [104.5980742, 22.8189152],
                [104.59821, 22.819174],
                [104.597849, 22.819907],
                [104.598138, 22.820441],
                [104.598643, 22.820574],
                [104.598932, 22.820774],
                [104.598643, 22.821441],
                [104.5981818, 22.8221555],
                [104.598138, 22.822908],
                [104.5978468, 22.8234343],
                [104.5981306, 22.8239949],
                [104.5991526, 22.8242137],
                [104.6007243, 22.8248319],
                [104.601171, 22.824842],
                [104.6017918, 22.8245668],
                [104.6019851, 22.8247044],
                [104.601821, 22.825442],
                [104.601532, 22.825775],
                [104.6012229, 22.8258502],
                [104.6006085, 22.8258823],
                [104.600376, 22.826442],
                [104.6006476, 22.8268123],
                [104.601026, 22.827018],
                [104.6020808, 22.826842],
                [104.6041274, 22.8265714],
                [104.6047838, 22.8267357],
                [104.604854, 22.827109],
                [104.6043526, 22.827509],
                [104.604276, 22.827909],
                [104.6046024, 22.8282163],
                [104.6052231, 22.8283981],
                [104.6052214, 22.8287405],
                [104.604781, 22.829043],
                [104.604637, 22.82931],
                [104.604565, 22.830043],
                [104.6048713, 22.8306577],
                [104.6048886, 22.8311806],
                [104.6044838, 22.8322512],
                [104.6045385, 22.8329936],
                [104.6042851, 22.8332203],
                [104.6039132, 22.8330577],
                [104.603554, 22.83271],
                [104.6030371, 22.8327249],
                [104.6026041, 22.8337129],
                [104.602326, 22.8340001],
                [104.601748, 22.834378],
                [104.601604, 22.834844],
                [104.601243, 22.835645],
                [104.6011393, 22.8360924],
                [104.6007632, 22.8368165],
                [104.6005926, 22.8372596],
                [104.600448, 22.838112],
                [104.5994118, 22.8387505],
                [104.5995109, 22.8399762],
                [104.5994026, 22.8404753],
                [104.598478, 22.8411708],
                [104.599004, 22.841979],
                [104.59951, 22.842646],
                [104.59951, 22.842846],
                [104.599437, 22.843113],
                [104.598932, 22.84338],
                [104.598426, 22.84318],
                [104.59821, 22.843313],
                [104.597993, 22.843647],
                [104.5982042, 22.8440845],
                [104.5989522, 22.8449187],
                [104.5992412, 22.8451044],
                [104.5991847, 22.8453714],
                [104.598932, 22.845514],
                [104.598715, 22.84558],
                [104.598421, 22.8458185],
                [104.5985347, 22.8462994],
                [104.5984314, 22.8466138],
                [104.597921, 22.846714],
                [104.597415, 22.846714],
                [104.597127, 22.8467671],
                [104.5966674, 22.8468669],
                [104.596404, 22.8469044],
                [104.5961517, 22.8470859],
                [104.5960121, 22.8476531],
                [104.5958425, 22.8479956],
                [104.5952958, 22.8482048],
                [104.5949503, 22.8480519],
                [104.5947275, 22.8474292],
                [104.5943104, 22.8478388],
                [104.5940268, 22.8491296],
                [104.5935005, 22.8491058],
                [104.592964, 22.8492722],
                [104.592721, 22.850582],
                [104.592216, 22.850782],
                [104.5907771, 22.8506341],
                [104.5905032, 22.8512398],
                [104.5901268, 22.8516394],
                [104.5901012, 22.8524299],
                [104.5895873, 22.8524414],
                [104.5892741, 22.8523368],
                [104.5890883, 22.852037],
                [104.5890748, 22.8516982],
                [104.5891873, 22.8513249],
                [104.5890085, 22.8511047],
                [104.5885423, 22.8512061],
                [104.5876794, 22.8520092],
                [104.5870556, 22.8521805],
                [104.5863028, 22.8522384],
                [104.5854519, 22.8518476],
                [104.5848931, 22.8515456],
                [104.5841347, 22.8514295],
                [104.5835258, 22.8515241],
                [104.5830191, 22.8517459],
                [104.5802923, 22.8517976],
                [104.5785966, 22.8522167],
                [104.577862, 22.8530508],
                [104.5774075, 22.8534762],
                [104.577195, 22.853182],
                [104.575028, 22.851515],
                [104.57344, 22.849715],
                [104.573079, 22.848781],
                [104.570768, 22.847714],
                [104.570262, 22.847247],
                [104.569901, 22.84538],
                [104.5697123, 22.8441612],
                [104.5694864, 22.8441201],
                [104.569195, 22.8441665],
                [104.5688022, 22.8442288],
                [104.5683473, 22.8441637],
                [104.5679585, 22.8438999],
                [104.5677616, 22.8439034],
                [104.5675171, 22.8443739],
                [104.5671596, 22.8445713],
                [104.5667294, 22.8446219],
                [104.5662248, 22.8444058],
                [104.5656647, 22.8441352],
                [104.5658845, 22.8438101],
                [104.5660429, 22.8434461],
                [104.5659879, 22.8430642],
                [104.5654657, 22.8429664],
                [104.5650938, 22.8428157],
                [104.5647249, 22.8424959],
                [104.5642271, 22.8424959],
                [104.5639624, 22.8425977],
                [104.5638651, 22.8432003],
                [104.5639871, 22.8434115],
                [104.5640283, 22.8436549],
                [104.5636023, 22.8433564],
                [104.5633801, 22.8433571],
                [104.5630424, 22.8434958],
                [104.5628944, 22.8433959],
                [104.5628919, 22.843222],
                [104.5631893, 22.8427405],
                [104.5632119, 22.8424799],
                [104.5630425, 22.842535],
                [104.5627389, 22.8428547],
                [104.5605869, 22.8422069],
                [104.5600635, 22.8417987],
                [104.5595228, 22.8415921],
                [104.5589593, 22.8415739],
                [104.5584381, 22.8417359],
                [104.5578864, 22.8420758],
                [104.5573426, 22.8421907],
                [104.5567941, 22.8422111],
                [104.5558867, 22.8420517],
                [104.5554642, 22.8420196],
                [104.5550493, 22.8420859],
                [104.5542245, 22.8424645],
                [104.5532977, 22.841881],
                [104.5523495, 22.8415625],
                [104.5520243, 22.8412766],
                [104.5516928, 22.8410211],
                [104.5512155, 22.8409867],
                [104.5506206, 22.8409807],
                [104.5502732, 22.8408439],
                [104.5497552, 22.8403576],
                [104.5489602, 22.8402258],
                [104.5481083, 22.8402329],
                [104.5471491, 22.8403389],
                [104.5466787, 22.8404797],
                [104.545638, 22.837784],
                [104.5471849, 22.8365454],
                [104.5479097, 22.836174],
                [104.5486201, 22.8359754],
                [104.5493655, 22.8361903],
                [104.5503261, 22.8361289],
                [104.5514002, 22.8359761],
                [104.5521486, 22.8356635],
                [104.5530483, 22.8356273],
                [104.5535188, 22.835583],
                [104.5539326, 22.835702],
                [104.5544215, 22.8357373],
                [104.5545722, 22.8354887],
                [104.5542594, 22.8354664],
                [104.5541638, 22.835298],
                [104.5540581, 22.834982],
                [104.5537444, 22.8348402],
                [104.5529391, 22.8348442],
                [104.5527219, 22.8345556],
                [104.5521114, 22.8339691],
                [104.5516304, 22.833714],
                [104.5512044, 22.8337036],
                [104.550492, 22.8337974],
                [104.5498987, 22.8338971],
                [104.5495672, 22.8338173],
                [104.5494849, 22.8334849],
                [104.5492241, 22.8329529],
                [104.5487155, 22.8328064],
                [104.5482667, 22.8326088],
                [104.5480814, 22.8322924],
                [104.5480622, 22.8319454],
                [104.5480365, 22.8317161],
                [104.5478903, 22.8315982],
                [104.5470316, 22.8315388],
                [104.5468018, 22.83149],
                [104.5466556, 22.8313002],
                [104.5464835, 22.830961],
                [104.5463778, 22.8305888],
                [104.546263, 22.8304304],
                [104.5459186, 22.8301486],
                [104.5455544, 22.8295809],
                [104.5451517, 22.8293618],
                [104.544812, 22.8288282],
                [104.5431675, 22.8272612],
                [104.5432404, 22.8269773],
                [104.5430997, 22.8266804],
                [104.5425131, 22.8259919],
                [104.5419273, 22.826125],
                [104.5411678, 22.8260342],
                [104.5407465, 22.8261678],
                [104.5402434, 22.8265126],
                [104.539623, 22.826453],
                [104.5391569, 22.8264514],
                [104.53851, 22.8267224],
                [104.5380833, 22.8271202],
                [104.5375846, 22.8272801],
                [104.5367921, 22.8264942],
                [104.535992, 22.8258457],
                [104.5351781, 22.8240531],
                [104.5341202, 22.8216665],
                [104.5299505, 22.8199486],
                [104.5276168, 22.8170739],
                [104.5248442, 22.8119544],
                [104.5245769, 22.8072906],
                [104.5216316, 22.803762],
                [104.5203098, 22.797878],
                [104.5165349, 22.795248],
                [104.5156564, 22.7931728],
                [104.51545, 22.7928737],
                [104.5151979, 22.7928959],
                [104.5145649, 22.792567],
                [104.5139319, 22.7914073],
                [104.5139603, 22.791037],
                [104.5138681, 22.7905876],
                [104.5136545, 22.7898172],
                [104.5131648, 22.789664],
                [104.5129341, 22.7893029],
                [104.512682, 22.7892955],
                [104.5121026, 22.7890037],
                [104.5116091, 22.7887935],
                [104.5103231, 22.7899849],
                [104.5075085, 22.7890251],
                [104.5026716, 22.7873757],
                [104.4979454, 22.7840281],
                [104.496455, 22.7794185],
                [104.4945788, 22.7779914],
                [104.4920075, 22.7780958],
                [104.4887096, 22.7739293],
                [104.4849659, 22.7706891],
                [104.4807804, 22.7693155],
                [104.4778446, 22.7635618],
                [104.472217, 22.7604545],
                [104.4683654, 22.7567576],
                [104.4639804, 22.7558184],
                [104.460274, 22.752423],
                [104.458107, 22.751889],
                [104.456013, 22.750822],
                [104.454641, 22.750022],
                [104.444603, 22.746221],
                [104.435937, 22.742754],
                [104.428065, 22.73942],
                [104.422938, 22.736819],
                [104.42171, 22.736019],
                [104.420988, 22.735419],
                [104.42041, 22.734818],
                [104.419616, 22.733885],
                [104.4179129, 22.7324904],
                [104.4173348, 22.7321151],
                [104.416882, 22.7317295],
                [104.4166075, 22.7307722],
                [104.4163291, 22.7301449],
                [104.4158055, 22.7298649],
                [104.4152405, 22.7296201],
                [104.4145693, 22.7292882],
                [104.4139153, 22.7288763],
                [104.4135341, 22.7285095],
                [104.4133816, 22.7280136],
                [104.4132117, 22.7275822],
                [104.4127498, 22.7272669],
                [104.4123408, 22.727347],
                [104.4118656, 22.7275227],
                [104.4112766, 22.7275485],
                [104.4107557, 22.727427],
                [104.410447, 22.7271104],
                [104.4098462, 22.7265866],
                [104.4091704, 22.7261553],
                [104.4086246, 22.7258337],
                [104.408378, 22.725435],
                [104.4081253, 22.7247904],
                [104.4079258, 22.7239305],
                [104.4078671, 22.7233164],
                [104.4076317, 22.7228437],
                [104.4071664, 22.722503],
                [104.4066974, 22.722201],
                [104.4065444, 22.7220063],
                [104.4065185, 22.7217211],
                [104.4064478, 22.7211297],
                [104.4063102, 22.7200672],
                [104.406039, 22.718014],
                [104.4059662, 22.7175257],
                [104.4056462, 22.7168193],
                [104.4053209, 22.7163071],
                [104.4048414, 22.7161178],
                [104.4045475, 22.716028],
                [104.4045027, 22.7154031],
                [104.4044594, 22.7151322],
                [104.4041554, 22.7149024],
                [104.4039499, 22.7148922],
                [104.4039508, 22.7144967],
                [104.4035718, 22.7141005],
                [104.4033504, 22.7137087],
                [104.4033504, 22.7133227],
                [104.4037328, 22.7128247],
                [104.4046116, 22.7123979],
                [104.4056537, 22.712031],
                [104.4053284, 22.7118708],
                [104.4051249, 22.7116351],
                [104.404974, 22.7111516],
                [104.4042647, 22.7102951],
                [104.4036687, 22.7089881],
                [104.4026689, 22.7091511],
                [104.4020281, 22.7092664],
                [104.4016089, 22.7090964],
                [104.4011911, 22.7086967],
                [104.4009945, 22.7083068],
                [104.4009895, 22.7078312],
                [104.4009659, 22.7074258],
                [104.4006908, 22.7070854],
                [104.4003824, 22.706815],
                [104.4001355, 22.7062291],
                [104.4000156, 22.7054876],
                [104.3999683, 22.7042739],
                [104.399736, 22.7031359],
                [104.399344, 22.7020477],
                [104.3985867, 22.7017236],
                [104.3981116, 22.7014628],
                [104.3975532, 22.7015128],
                [104.3965441, 22.7027847],
                [104.3957027, 22.7034131],
                [104.3947028, 22.7035149],
                [104.3934335, 22.7040101],
                [104.3919057, 22.7045392],
                [104.3910448, 22.7045343],
                [104.3904854, 22.7043469],
                [104.3890993, 22.7044767],
                [104.3881586, 22.7045338],
                [104.3877148, 22.7043668],
                [104.3868215, 22.7038777],
                [104.3855772, 22.7034047],
                [104.3850821, 22.7029396],
                [104.3843227, 22.7024089],
                [104.3838203, 22.7016351],
                [104.3832903, 22.7008695],
                [104.3826264, 22.6997616],
                [104.3824307, 22.6991511],
                [104.3820927, 22.6985941],
                [104.3813952, 22.6978623],
                [104.3806848, 22.6967412],
                [104.3796805, 22.6959281],
                [104.3791654, 22.695192],
                [104.3789351, 22.6948039],
                [104.3789458, 22.6940627],
                [104.3782278, 22.6930691],
                [104.3774355, 22.6911809],
                [104.3768283, 22.6908737],
                [104.3755969, 22.6908287],
                [104.374895, 22.6906939],
                [104.3740489, 22.6903982],
                [104.3733182, 22.6899108],
                [104.3725412, 22.6895981],
                [104.3719324, 22.6889777],
                [104.3714864, 22.6882742],
                [104.3708366, 22.688215],
                [104.3700728, 22.6884605],
                [104.3693699, 22.6885686],
                [104.3681806, 22.6889927],
                [104.3672922, 22.6894086],
                [104.3665154, 22.6894567],
                [104.3665709, 22.6901441],
                [104.366555, 22.6910639],
                [104.367153, 22.6916131],
                [104.3663075, 22.6916612],
                [104.3655562, 22.6916925],
                [104.364678, 22.6920901],
                [104.364209, 22.6921859],
                [104.3636195, 22.6921273],
                [104.363132, 22.6917238],
                [104.362586, 22.6914779],
                [104.3621866, 22.6915769],
                [104.361757, 22.6918727],
                [104.3614633, 22.6920432],
                [104.3614148, 22.6923002],
                [104.3606072, 22.6921923],
                [104.3594667, 22.6922296],
                [104.3593786, 22.6920281],
                [104.3586583, 22.6919671],
                [104.3580327, 22.6920674],
                [104.3578401, 22.6920299],
                [104.3578039, 22.6918731],
                [104.3569978, 22.6919112],
                [104.3562232, 22.6917389],
                [104.3554847, 22.6915255],
                [104.3543616, 22.6921354],
                [104.3537371, 22.6920746],
                [104.3528554, 22.6923558],
                [104.3517821, 22.6934157],
                [104.3512924, 22.69423],
                [104.3510836, 22.6950433],
                [104.3510551, 22.6962632],
                [104.3509555, 22.6966575],
                [104.3506614, 22.6970631],
                [104.3502234, 22.6975153],
                [104.3499387, 22.6979744],
                [104.3498786, 22.7001171],
                [104.3491263, 22.7009059],
                [104.3484411, 22.7017775],
                [104.3479161, 22.702804],
                [104.3475434, 22.7038101],
                [104.3474403, 22.7044212],
                [104.3468155, 22.7046009],
                [104.3464159, 22.7046763],
                [104.3460986, 22.7048668],
                [104.3460516, 22.7051839],
                [104.3458361, 22.7054676],
                [104.3449202, 22.7059433],
                [104.3442902, 22.7064598],
                [104.343965, 22.7069714],
                [104.3437377, 22.7079069],
                [104.3435673, 22.7082254],
                [104.343029, 22.7087248],
                [104.3427597, 22.7092195],
                [104.3425788, 22.7097484],
                [104.342415, 22.710612],
                [104.3419135, 22.7117775],
                [104.3414855, 22.7103607],
                [104.3411193, 22.7094064],
                [104.340765, 22.7085844],
                [104.3411502, 22.7077225],
                [104.3410027, 22.7067472],
                [104.3406175, 22.7057644],
                [104.3407728, 22.7049193],
                [104.3413708, 22.7040806],
                [104.3412322, 22.703467],
                [104.3405542, 22.7032692],
                [104.3401601, 22.7029752],
                [104.3398414, 22.7023015],
                [104.3396154, 22.7015851],
                [104.3392097, 22.7007672],
                [104.3388967, 22.7002112],
                [104.3382245, 22.6997567],
                [104.3373552, 22.6991366],
                [104.3370975, 22.6987545],
                [104.3366192, 22.6981582],
                [104.3364454, 22.6976075],
                [104.3361614, 22.6971851],
                [104.3357326, 22.6968483],
                [104.3356514, 22.696442],
                [104.3360919, 22.6955064],
                [104.3365555, 22.6948594],
                [104.3365381, 22.6944264],
                [104.3362642, 22.6937545],
                [104.3360803, 22.6928919],
                [104.3355819, 22.6921327],
                [104.3347648, 22.6914323],
                [104.3343417, 22.690919],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.7690113, 20.5649591],
                [105.7690868, 20.5642417],
                [105.7693295, 20.5636074],
                [105.7696432, 20.5623539],
                [105.7698578, 20.5609732],
                [105.7703431, 20.5595913],
                [105.7707382, 20.5585765],
                [105.7708273, 20.5583479],
                [105.7711925, 20.5575695],
                [105.7713741, 20.5569774],
                [105.7714213, 20.5565482],
                [105.7715027, 20.5556318],
                [105.7715121, 20.5550404],
                [105.7716329, 20.5546109],
                [105.7719234, 20.5541412],
                [105.7722043, 20.5537111],
                [105.7723009, 20.5533745],
                [105.772312, 20.5531077],
                [105.7722978, 20.5527367],
                [105.7723578, 20.552435],
                [105.7726371, 20.5516917],
                [105.7730969, 20.5507931],
                [105.7732669, 20.5503401],
                [105.7735341, 20.5496433],
                [105.7740838, 20.5488294],
                [105.7750256, 20.5477356],
                [105.7759692, 20.5469315],
                [105.7774092, 20.5459139],
                [105.7776416, 20.5456579],
                [105.7777994, 20.545205],
                [105.7779695, 20.5447985],
                [105.7787277, 20.5438677],
                [105.7800268, 20.5428536],
                [105.7805414, 20.5424224],
                [105.7813603, 20.5414406],
                [105.7828048, 20.5399968],
                [105.7836001, 20.5391122],
                [105.7838445, 20.5387866],
                [105.7840392, 20.5383567],
                [105.7841417, 20.5377613],
                [105.7842624, 20.5373085],
                [105.7845907, 20.5365536],
                [105.7849587, 20.5360078],
                [105.7852258, 20.5356117],
                [105.7854938, 20.5351119],
                [105.7858028, 20.5332865],
                [105.7858987, 20.5327875],
                [105.7858967, 20.5323933],
                [105.7859188, 20.5319062],
                [105.7860525, 20.5315925],
                [105.7864443, 20.5311967],
                [105.7871527, 20.5301966],
                [105.7876857, 20.5291815],
                [105.7883666, 20.5276248],
                [105.7889643, 20.526602],
                [105.789512, 20.5257373],
                [105.7896202, 20.5252382],
                [105.7899108, 20.5243209],
                [105.7901426, 20.5239606],
                [105.7909088, 20.5240273],
                [105.7910856, 20.5231564],
                [105.7917181, 20.5221627],
                [105.7920475, 20.5216164],
                [105.7923384, 20.5207687],
                [105.7924336, 20.5201653],
                [105.7924188, 20.5196552],
                [105.7928062, 20.5192021],
                [105.7929738, 20.5177117],
                [105.793133, 20.5175718],
                [105.7932916, 20.5172929],
                [105.7938151, 20.5162471],
                [105.7944913, 20.5139573],
                [105.7947938, 20.5129936],
                [105.795148, 20.5116971],
                [105.7957477, 20.5115939],
                [105.7959938, 20.5116186],
                [105.7965003, 20.5117452],
                [105.7975959, 20.5122041],
                [105.7998005, 20.5130057],
                [105.8002111, 20.5131197],
                [105.8003888, 20.5131061],
                [105.8008253, 20.5129497],
                [105.8010432, 20.5128073],
                [105.8011786, 20.5125621],
                [105.8015836, 20.5115951],
                [105.8021924, 20.5103953],
                [105.8026381, 20.5093893],
                [105.8028149, 20.5092084],
                [105.803046, 20.5090015],
                [105.8031819, 20.5088464],
                [105.8032084, 20.5086662],
                [105.8033413, 20.5079447],
                [105.8034442, 20.5067177],
                [105.8035633, 20.50597],
                [105.8036419, 20.5053256],
                [105.8036646, 20.5044496],
                [105.8036609, 20.5042746],
                [105.8036586, 20.5041641],
                [105.8037247, 20.5037516],
                [105.8040072, 20.5029001],
                [105.8046022, 20.5016609],
                [105.8051296, 20.5006152],
                [105.8066901, 20.4990739],
                [105.8070215, 20.4988504],
                [105.8074443, 20.498671],
                [105.8080089, 20.4986019],
                [105.8085501, 20.4985772],
                [105.8092096, 20.4986628],
                [105.8095645, 20.4988098],
                [105.8100351, 20.4989786],
                [105.8098719, 20.4998599],
                [105.8097365, 20.5014861],
                [105.8120847, 20.5024826],
                [105.812877, 20.5027833],
                [105.8133814, 20.503029],
                [105.8137252, 20.5032091],
                [105.8142173, 20.5033985],
                [105.8148634, 20.503666],
                [105.815668, 20.5040344],
                [105.8197035, 20.505779],
                [105.8197889, 20.5038164],
                [105.8197094, 20.502396],
                [105.8192677, 20.500436],
                [105.8190979, 20.5000308],
                [105.8191206, 20.4997826],
                [105.8193829, 20.4995558],
                [105.8199288, 20.498606],
                [105.8201027, 20.4975113],
                [105.820007, 20.496845],
                [105.819991, 20.496521],
                [105.8199858, 20.4955412],
                [105.8200678, 20.495001],
                [105.8201711, 20.4944806],
                [105.8203589, 20.4938799],
                [105.820548, 20.493519],
                [105.8213909, 20.4923953],
                [105.8242194, 20.4895425],
                [105.8248948, 20.4888795],
                [105.8252757, 20.4886376],
                [105.8256783, 20.4885558],
                [105.826781, 20.4883905],
                [105.8279897, 20.4882248],
                [105.828477, 20.4880824],
                [105.8292603, 20.4877389],
                [105.8300644, 20.4872952],
                [105.8307412, 20.486852],
                [105.8316071, 20.4860879],
                [105.8326635, 20.4852632],
                [105.8333197, 20.48494],
                [105.8337646, 20.4847979],
                [105.8343794, 20.4846751],
                [105.8352058, 20.484491],
                [105.8361387, 20.4843266],
                [105.8361583, 20.4840266],
                [105.8363036, 20.483426],
                [105.8376259, 20.4807402],
                [105.8379632, 20.4803187],
                [105.8381746, 20.4801776],
                [105.8383655, 20.4801568],
                [105.8388539, 20.4802144],
                [105.8419135, 20.4809394],
                [105.8439132, 20.4780103],
                [105.8454207, 20.4781429],
                [105.8476077, 20.4783923],
                [105.8489448, 20.4784258],
                [105.8499631, 20.4783808],
                [105.8508111, 20.4782367],
                [105.8515102, 20.4780532],
                [105.8521233, 20.4778837],
                [105.8519538, 20.4755896],
                [105.8516263, 20.4717313],
                [105.851575, 20.4693866],
                [105.8513678, 20.4665378],
                [105.8513606, 20.4652391],
                [105.8514042, 20.4634495],
                [105.8517432, 20.4586534],
                [105.8519482, 20.4571594],
                [105.8520902, 20.4552926],
                [105.8522933, 20.4534829],
                [105.8524065, 20.4523305],
                [105.8527075, 20.4512415],
                [105.8527938, 20.4503512],
                [105.8527875, 20.4492029],
                [105.8527794, 20.4477386],
                [105.8528953, 20.4466758],
                [105.8531631, 20.4455261],
                [105.8534308, 20.4443765],
                [105.8540234, 20.441398],
                [105.8541704, 20.4404498],
                [105.8543773, 20.4393001],
                [105.8544041, 20.4386395],
                [105.8543097, 20.4381231],
                [105.8540916, 20.4372341],
                [105.8538714, 20.4360003],
                [105.8538694, 20.435627],
                [105.8539867, 20.4347938],
                [105.8540707, 20.4334724],
                [105.854306, 20.4319494],
                [105.8546049, 20.4309142],
                [105.8551457, 20.4295619],
                [105.8557788, 20.4283815],
                [105.8565659, 20.4274874],
                [105.8571482, 20.4265979],
                [105.857863, 20.4260273],
                [105.8588072, 20.4253688],
                [105.8593366, 20.4249302],
                [105.8598929, 20.4242907],
                [105.860874, 20.4228083],
                [105.8615356, 20.4212138],
                [105.8617841, 20.4201664],
                [105.8624343, 20.4188553],
                [105.8627791, 20.4184831],
                [105.8631019, 20.4183071],
                [105.8649256, 20.4177968],
                [105.8652941, 20.4175552],
                [105.8655003, 20.4172055],
                [105.8656821, 20.4166378],
                [105.8657184, 20.4148721],
                [105.8658327, 20.4146317],
                [105.866177, 20.4141941],
                [105.866821, 20.4135805],
                [105.8674873, 20.4128361],
                [105.8680151, 20.4121361],
                [105.8682669, 20.411677],
                [105.8684026, 20.4111314],
                [105.8685589, 20.4101715],
                [105.8687188, 20.409822],
                [105.8690868, 20.4094714],
                [105.8695937, 20.4091637],
                [105.8709543, 20.4085466],
                [105.872431, 20.4080161],
                [105.8741395, 20.4075934],
                [105.8746005, 20.4073513],
                [105.8750837, 20.4069348],
                [105.8752672, 20.4066723],
                [105.8757447, 20.4052531],
                [105.8762254, 20.4044224],
                [105.8776697, 20.4022572],
                [105.8785623, 20.4006835],
                [105.8791524, 20.3987623],
                [105.8797947, 20.3978653],
                [105.8803002, 20.3973397],
                [105.8810599, 20.3967691],
                [105.8817742, 20.3963513],
                [105.8824877, 20.395781],
                [105.8840263, 20.3940077],
                [105.884716, 20.3933285],
                [105.8863494, 20.3919471],
                [105.8869237, 20.391312],
                [105.8871982, 20.3908092],
                [105.8873801, 20.3902852],
                [105.8875119, 20.3890857],
                [105.8877185, 20.3888449],
                [105.8881801, 20.38869],
                [105.8894051, 20.3886619],
                [105.8904911, 20.3885692],
                [105.8912528, 20.3883691],
                [105.8918061, 20.3881048],
                [105.8923126, 20.3877534],
                [105.8928407, 20.3871186],
                [105.8940812, 20.3857826],
                [105.8936729, 20.3842567],
                [105.8935599, 20.3835711],
                [105.8937808, 20.3828634],
                [105.8941778, 20.3823432],
                [105.8950995, 20.381726],
                [105.8953049, 20.3815478],
                [105.8953961, 20.3813293],
                [105.8959019, 20.3808691],
                [105.8960854, 20.3806283],
                [105.8961981, 20.3801264],
                [105.8964035, 20.3796675],
                [105.8977331, 20.3788623],
                [105.8979298, 20.3782961],
                [105.8979257, 20.377613],
                [105.8980988, 20.3770432],
                [105.8990392, 20.3757961],
                [105.8995215, 20.3752704],
                [105.9001423, 20.3747005],
                [105.9006255, 20.3743274],
                [105.9012015, 20.3739975],
                [105.9016158, 20.3736902],
                [105.9021674, 20.3731424],
                [105.9026052, 20.3729222],
                [105.9028592, 20.3728773],
                [105.9041326, 20.3732412],
                [105.9046876, 20.3732819],
                [105.9054966, 20.373256],
                [105.9059581, 20.373101],
                [105.9066258, 20.3726834],
                [105.9074099, 20.3723741],
                [105.9082877, 20.3722824],
                [105.9090708, 20.372326],
                [105.909049, 20.3728916],
                [105.9093553, 20.3730061],
                [105.9099098, 20.3729496],
                [105.9103059, 20.3735143],
                [105.9105311, 20.374038],
                [105.9098533, 20.3750738],
                [105.9098411, 20.3757611],
                [105.9102462, 20.3765833],
                [105.9107979, 20.3768867],
                [105.9114975, 20.3767888],
                [105.9124947, 20.3761839],
                [105.9128383, 20.3756589],
                [105.9147297, 20.3737569],
                [105.9148804, 20.3731198],
                [105.9151335, 20.3729224],
                [105.9153182, 20.3728995],
                [105.9160125, 20.3730485],
                [105.9165213, 20.3730894],
                [105.9167082, 20.3729826],
                [105.9170495, 20.3722639],
                [105.9177305, 20.3702568],
                [105.9183672, 20.368554],
                [105.9187074, 20.3674182],
                [105.9196887, 20.365011],
                [105.920604, 20.3640011],
                [105.9222675, 20.3631768],
                [105.9241194, 20.36286],
                [105.9257329, 20.3632581],
                [105.9307581, 20.3667721],
                [105.9323372, 20.3674634],
                [105.9346393, 20.3679253],
                [105.934678, 20.368607],
                [105.934747, 20.3692512],
                [105.9349501, 20.3705544],
                [105.9352657, 20.3715961],
                [105.9355131, 20.3721473],
                [105.9359895, 20.3728814],
                [105.9365654, 20.3739063],
                [105.9368633, 20.3747333],
                [105.936931, 20.3751627],
                [105.9368847, 20.3755773],
                [105.9367884, 20.375808],
                [105.9366591, 20.3759621],
                [105.9364154, 20.3760094],
                [105.9361714, 20.3760108],
                [105.9358289, 20.3759052],
                [105.9352736, 20.3755705],
                [105.9347185, 20.375282],
                [105.9341804, 20.3751007],
                [105.9336592, 20.3750421],
                [105.9333665, 20.375059],
                [105.9325051, 20.3752477],
                [105.9319041, 20.3754352],
                [105.9311091, 20.3758076],
                [105.9303139, 20.3761802],
                [105.9297791, 20.3765667],
                [105.9291966, 20.3771068],
                [105.9285649, 20.3776013],
                [105.9279658, 20.3780955],
                [105.9276584, 20.3784041],
                [105.9272871, 20.378897],
                [105.9268844, 20.3795898],
                [105.9264337, 20.3804055],
                [105.9260816, 20.3813895],
                [105.9258602, 20.3824648],
                [105.9256373, 20.3832792],
                [105.92556, 20.3839702],
                [105.9254541, 20.3852905],
                [105.9254079, 20.3857203],
                [105.925329, 20.3861351],
                [105.9250769, 20.3875329],
                [105.9249366, 20.3885618],
                [105.9246855, 20.3900976],
                [105.9244378, 20.3922166],
                [105.9243592, 20.3926928],
                [105.9241532, 20.3935991],
                [105.9239975, 20.3947816],
                [105.9240193, 20.3957021],
                [105.9240702, 20.3960549],
                [105.9241366, 20.3962693],
                [105.9243667, 20.3966364],
                [105.9248752, 20.3972781],
                [105.9262174, 20.3985446],
                [105.927052, 20.3992921],
                [105.9273131, 20.3994134],
                [105.9274378, 20.3994128],
                [105.9274922, 20.3994126],
                [105.9280767, 20.3991639],
                [105.9303977, 20.3981081],
                [105.9311764, 20.3977049],
                [105.9315178, 20.3976417],
                [105.9318272, 20.3976554],
                [105.9322833, 20.3977297],
                [105.9336105, 20.3980234],
                [105.9364942, 20.3984973],
                [105.9383956, 20.3987844],
                [105.9392065, 20.3990459],
                [105.9405441, 20.3998857],
                [105.9414226, 20.4005171],
                [105.9419859, 20.3998006],
                [105.9421966, 20.3983244],
                [105.9426569, 20.3986139],
                [105.9441002, 20.3995841],
                [105.9452788, 20.4003636],
                [105.9471372, 20.401691],
                [105.9474675, 20.4019024],
                [105.9488698, 20.4025925],
                [105.9505818, 20.4035136],
                [105.9500414, 20.4058814],
                [105.9497037, 20.4078022],
                [105.9495654, 20.4087139],
                [105.949363, 20.4092191],
                [105.9490573, 20.4096665],
                [105.9488365, 20.4105206],
                [105.9487379, 20.4115434],
                [105.9486625, 20.4123242],
                [105.948546, 20.4134491],
                [105.9505224, 20.4138647],
                [105.9525603, 20.4142413],
                [105.9529104, 20.4143362],
                [105.9539403, 20.4146214],
                [105.955194, 20.4151564],
                [105.9551281, 20.4159248],
                [105.9557268, 20.4162426],
                [105.9560898, 20.4163611],
                [105.956389, 20.4165],
                [105.9567715, 20.4167215],
                [105.9571569, 20.4171073],
                [105.9573999, 20.4173868],
                [105.9575806, 20.4176339],
                [105.9579391, 20.4181529],
                [105.9580761, 20.4183512],
                [105.9583819, 20.4192498],
                [105.9585494, 20.4198661],
                [105.9593428, 20.4202219],
                [105.9595379, 20.4208894],
                [105.9596205, 20.4210175],
                [105.9602196, 20.4208342],
                [105.960438, 20.4208587],
                [105.9606301, 20.4210378],
                [105.960713, 20.4212173],
                [105.960771, 20.4217571],
                [105.960828, 20.4221425],
                [105.9617829, 20.4221373],
                [105.9622195, 20.4221606],
                [105.9623839, 20.4222625],
                [105.9625496, 20.422596],
                [105.9627471, 20.4236237],
                [105.9628574, 20.423803],
                [105.9634839, 20.423671],
                [105.9637373, 20.4249042],
                [105.9637935, 20.4251611],
                [105.9642565, 20.4250556],
                [105.9643391, 20.425158],
                [105.9645047, 20.4254657],
                [105.9646151, 20.4256708],
                [105.9647522, 20.4257729],
                [105.9657891, 20.4257929],
                [105.9676712, 20.4257311],
                [105.9688712, 20.4256215],
                [105.9689798, 20.4255438],
                [105.9689203, 20.4247725],
                [105.9689708, 20.4241292],
                [105.9694621, 20.4241523],
                [105.9697617, 20.4240734],
                [105.9698155, 20.4239445],
                [105.9698381, 20.4231984],
                [105.9702474, 20.4232219],
                [105.9715023, 20.4231892],
                [105.9715845, 20.4232659],
                [105.9716956, 20.423574],
                [105.9718324, 20.4236504],
                [105.971969, 20.4236495],
                [105.9731939, 20.4232313],
                [105.9738483, 20.4231504],
                [105.9738527, 20.4238448],
                [105.9738068, 20.4252082],
                [105.9740322, 20.4263385],
                [105.9743666, 20.4274425],
                [105.9747276, 20.4284437],
                [105.9753646, 20.4299318],
                [105.9762751, 20.432584],
                [105.9774236, 20.4326857],
                [105.9771506, 20.4348764],
                [105.9770445, 20.4362283],
                [105.976938, 20.4375532],
                [105.9768038, 20.4390134],
                [105.9780436, 20.43933],
                [105.9778933, 20.4414398],
                [105.9787936, 20.4414346],
                [105.9794216, 20.4415082],
                [105.9797218, 20.4415066],
                [105.9798301, 20.4413774],
                [105.9803136, 20.4401916],
                [105.9811069, 20.44047],
                [105.9814073, 20.4405197],
                [105.9814617, 20.4404938],
                [105.9815406, 20.4403431],
                [105.9816952, 20.4392465],
                [105.9817744, 20.4380576],
                [105.9819128, 20.4358849],
                [105.9821838, 20.4340802],
                [105.9824797, 20.4327672],
                [105.9828221, 20.4319047],
                [105.9830155, 20.4315552],
                [105.9831671, 20.4314724],
                [105.9836017, 20.4314699],
                [105.9844721, 20.4316085],
                [105.9853863, 20.4318082],
                [105.9856472, 20.4318068],
                [105.9858639, 20.431703],
                [105.9859716, 20.4315385],
                [105.9860348, 20.4312308],
                [105.9861971, 20.4294064],
                [105.9863056, 20.4294057],
                [105.9869145, 20.4294227],
                [105.987742, 20.4296639],
                [105.9880246, 20.4296827],
                [105.9883506, 20.4296809],
                [105.9884348, 20.4292297],
                [105.9937255, 20.4294858],
                [105.993975, 20.4281344],
                [105.9946516, 20.4283125],
                [105.9946451, 20.4270291],
                [105.9944511, 20.4264209],
                [105.9943538, 20.426081],
                [105.9942387, 20.4259204],
                [105.9940668, 20.4257959],
                [105.9938576, 20.4257791],
                [105.9932262, 20.4255423],
                [105.993092, 20.4253458],
                [105.9929576, 20.4246502],
                [105.9927421, 20.424053],
                [105.9924877, 20.4232448],
                [105.9919921, 20.4217458],
                [105.991686, 20.4206328],
                [105.9913555, 20.4196021],
                [105.9910883, 20.418747],
                [105.9909228, 20.4182082],
                [105.9908591, 20.4177143],
                [105.9907917, 20.4160841],
                [105.9908965, 20.4146677],
                [105.9909484, 20.413861],
                [105.9910977, 20.4134301],
                [105.9913614, 20.4130524],
                [105.991398, 20.4128371],
                [105.9914872, 20.4118313],
                [105.9915377, 20.410809],
                [105.9914605, 20.4106301],
                [105.9912696, 20.4105057],
                [105.9909083, 20.4104719],
                [105.9896716, 20.4104209],
                [105.9897655, 20.4093453],
                [105.9897365, 20.4078006],
                [105.9897391, 20.4064908],
                [105.9895107, 20.4047076],
                [105.9894419, 20.4042269],
                [105.9892991, 20.4039309],
                [105.9914664, 20.403708],
                [105.9915115, 20.4030181],
                [105.9917352, 20.4025094],
                [105.9918983, 20.4019995],
                [105.9921266, 20.4019468],
                [105.9922291, 20.4016505],
                [105.9926083, 20.4015457],
                [105.9928129, 20.4012071],
                [105.9925632, 20.4011262],
                [105.9926469, 20.4008472],
                [105.9927817, 20.400434],
                [105.993457, 20.4003067],
                [105.9935151, 20.400868],
                [105.9954954, 20.4006471],
                [105.995451, 20.3996107],
                [105.996339, 20.399472],
                [105.996132, 20.3984579],
                [105.9968401, 20.3983206],
                [105.9969822, 20.3979135],
                [105.995404, 20.3967217],
                [105.9955617, 20.3953691],
                [105.9975038, 20.3957366],
                [105.9989789, 20.3958951],
                [105.9997266, 20.3960206],
                [106.0003489, 20.396221],
                [105.9989047, 20.3993469],
                [106.0004299, 20.3998297],
                [106.0003212, 20.400192],
                [106.0014019, 20.400504],
                [106.0018223, 20.4006661],
                [106.002061, 20.4007333],
                [106.0022997, 20.4007694],
                [106.0025228, 20.4007252],
                [106.0028387, 20.4005917],
                [106.0030595, 20.4010809],
                [106.0033729, 20.4015594],
                [106.0037401, 20.4019342],
                [106.0041626, 20.4022567],
                [106.0045506, 20.4024399],
                [106.0050162, 20.4024923],
                [106.0055335, 20.4023948],
                [106.0060326, 20.4022209],
                [106.0064796, 20.4020206],
                [106.0068038, 20.4018876],
                [106.0071563, 20.4017282],
                [106.0079804, 20.4013387],
                [106.0089047, 20.4009312],
                [106.0097719, 20.4005589],
                [106.0105202, 20.4002733],
                [106.0112546, 20.4000244],
                [106.0119889, 20.3998192],
                [106.0126757, 20.3996142],
                [106.0131909, 20.3994714],
                [106.0136106, 20.3993729],
                [106.0139067, 20.399345],
                [106.0139405, 20.3993448],
                [106.014203, 20.3993432],
                [106.0143848, 20.3993771],
                [106.0145477, 20.3994374],
                [106.0148045, 20.3995685],
                [106.015084, 20.3997487],
                [106.01547, 20.4000132],
                [106.0159623, 20.4003135],
                [106.0164943, 20.4006013],
                [106.0170263, 20.4009134],
                [106.0173455, 20.4010813],
                [106.0177046, 20.4012732],
                [106.0180704, 20.4014893],
                [106.0192396, 20.4019431],
                [106.0203426, 20.4023854],
                [106.0206742, 20.4024561],
                [106.0211117, 20.4024778],
                [106.0218735, 20.4024469],
                [106.0228404, 20.4023685],
                [106.0234229, 20.4022924],
                [106.0239521, 20.4021804],
                [106.0245876, 20.4020675],
                [106.0253688, 20.4019903],
                [106.026256, 20.4018881],
                [106.0274609, 20.4017356],
                [106.0278847, 20.4016847],
                [106.0280441, 20.4017322],
                [106.0280974, 20.4017925],
                [106.0281203, 20.4019683],
                [106.0280648, 20.4023281],
                [106.0280086, 20.4025611],
                [106.0279058, 20.4027414],
                [106.0277338, 20.4029433],
                [106.0275961, 20.4030815],
                [106.0273833, 20.4032308],
                [106.0271186, 20.4034016],
                [106.0267618, 20.403615],
                [106.0261401, 20.4039782],
                [106.0257721, 20.4042341],
                [106.0252546, 20.4046284],
                [106.024893, 20.4049793],
                [106.0246978, 20.4051814],
                [106.0245725, 20.405436],
                [106.0245163, 20.4056688],
                [106.0244841, 20.4060497],
                [106.0244358, 20.4066209],
                [106.0243943, 20.4073084],
                [106.0243875, 20.4080378],
                [106.0243277, 20.408588],
                [106.024273, 20.4090746],
                [106.0241721, 20.4095299],
                [106.0241046, 20.4098157],
                [106.0240248, 20.4099854],
                [106.0239564, 20.4101127],
                [106.0238419, 20.4102719],
                [106.0237271, 20.4103889],
                [106.0234969, 20.4105488],
                [106.023226, 20.4106456],
                [106.0229029, 20.4107215],
                [106.0223485, 20.4107564],
                [106.0218981, 20.410812],
                [106.021494, 20.4108672],
                [106.021183, 20.4110171],
                [106.0209186, 20.4112194],
                [106.0205745, 20.4116233],
                [106.0203058, 20.4120688],
                [106.0201243, 20.4125775],
                [106.0199661, 20.4131175],
                [106.0198994, 20.4135303],
                [106.0198279, 20.4140594],
                [106.0198104, 20.4149264],
                [106.0198228, 20.4159202],
                [106.0198617, 20.4165543],
                [106.0199567, 20.4169344],
                [106.0202102, 20.4176941],
                [106.020608, 20.4184213],
                [106.0212153, 20.4193869],
                [106.0217468, 20.4202331],
                [106.0225258, 20.4218145],
                [106.0230754, 20.4227522],
                [106.0234152, 20.4234374],
                [106.0235747, 20.4239651],
                [106.0237054, 20.4245247],
                [106.0237311, 20.4248946],
                [106.0237335, 20.425254],
                [106.0236493, 20.4256246],
                [106.0233315, 20.4264829],
                [106.0232637, 20.4267264],
                [106.023265, 20.4269378],
                [106.0232661, 20.4271071],
                [106.0233326, 20.42754],
                [106.023535, 20.4284164],
                [106.0237098, 20.4295149],
                [106.0238056, 20.4300114],
                [106.02403, 20.4307501],
                [106.024245, 20.4313763],
                [106.0245663, 20.4322905],
                [106.0248943, 20.4329442],
                [106.0252277, 20.4335554],
                [106.025391, 20.4337659],
                [106.0258682, 20.43426],
                [106.0261938, 20.4345647],
                [106.0267585, 20.4351851],
                [106.0277825, 20.4362152],
                [106.0284864, 20.4368877],
                [106.0289528, 20.4375087],
                [106.0292914, 20.4380036],
                [106.0296496, 20.4388579],
                [106.0300726, 20.4398916],
                [106.0303083, 20.4405668],
                [106.0303634, 20.4410105],
                [106.0303549, 20.4414652],
                [106.0302644, 20.4417617],
                [106.0301391, 20.4420268],
                [106.0298871, 20.4424089],
                [106.0297679, 20.4427162],
                [106.0297598, 20.4432343],
                [106.0297271, 20.4434988],
                [106.0296703, 20.4436788],
                [106.029533, 20.4438806],
                [106.0290739, 20.4443802],
                [106.0288623, 20.4447092],
                [106.0287599, 20.4449425],
                [106.0287378, 20.4451117],
                [106.0287622, 20.4453019],
                [106.0288794, 20.4455443],
                [106.029194, 20.4459019],
                [106.0295199, 20.4462383],
                [106.029781, 20.4463935],
                [106.0300935, 20.4464551],
                [106.030394, 20.4464427],
                [106.0306718, 20.4464939],
                [106.0308573, 20.4465985],
                [106.0310891, 20.4466711],
                [106.0315169, 20.4466896],
                [106.0320137, 20.446655],
                [106.0326955, 20.4466193],
                [106.033435, 20.4465726],
                [106.0341976, 20.4465258],
                [106.0355349, 20.446399],
                [106.0364685, 20.4463446],
                [106.0371762, 20.4464379],
                [106.037884, 20.4465435],
                [106.038285, 20.4466993],
                [106.038976, 20.447433],
                [106.0397374, 20.4480163],
                [106.0402172, 20.4484491],
                [106.0406587, 20.4488821],
                [106.0411384, 20.4493325],
                [106.0417907, 20.4499385],
                [106.0421935, 20.4502846],
                [106.0423855, 20.4504926],
                [106.0424629, 20.450649],
                [106.0425023, 20.4508403],
                [106.042524, 20.4512237],
                [106.0424715, 20.4519211],
                [106.0423519, 20.4525613],
                [106.042091, 20.4534523],
                [106.0418695, 20.4545347],
                [106.0417594, 20.4551632],
                [106.0416982, 20.4559919],
                [106.0416926, 20.4566814],
                [106.0415959, 20.4577712],
                [106.0416187, 20.4583292],
                [106.0416688, 20.4586863],
                [106.0418257, 20.4593306],
                [106.0420601, 20.4601663],
                [106.0422576, 20.4611592],
                [106.0425215, 20.462108],
                [106.0426576, 20.4625083],
                [106.042774, 20.4626334],
                [106.0430171, 20.4631596],
                [106.0432373, 20.4636208],
                [106.0434699, 20.4641775],
                [106.0435575, 20.4644474],
                [106.0435789, 20.4647785],
                [106.0436211, 20.4653712],
                [106.0436336, 20.4658157],
                [106.0434333, 20.4672372],
                [106.0433381, 20.4686884],
                [106.0434637, 20.4695621],
                [106.0437704, 20.4701528],
                [106.0444013, 20.4708537],
                [106.0454707, 20.4715476],
                [106.0463157, 20.471975],
                [106.0472903, 20.4723974],
                [106.0481225, 20.4725636],
                [106.0489639, 20.4726882],
                [106.0497113, 20.4727977],
                [106.0507022, 20.4729072],
                [106.0515571, 20.4730094],
                [106.0526005, 20.4730899],
                [106.0536219, 20.4731375],
                [106.0552176, 20.4732539],
                [106.0559498, 20.4731998],
                [106.0566946, 20.4731202],
                [106.0571186, 20.4730206],
                [106.0577897, 20.4728226],
                [106.0584252, 20.4725924],
                [106.0589891, 20.4722654],
                [106.0595318, 20.4718056],
                [106.0596786, 20.4716059],
                [106.0605083, 20.4706938],
                [106.061707, 20.4689204],
                [106.0621725, 20.4682905],
                [106.0626549, 20.4677304],
                [106.063318, 20.4669082],
                [106.0639781, 20.466239],
                [106.0645595, 20.4656697],
                [106.0649917, 20.4653154],
                [106.0651582, 20.4652072],
                [106.0653333, 20.465145],
                [106.0655005, 20.4651364],
                [106.0655758, 20.4651435],
                [106.0657013, 20.4651581],
                [106.0658436, 20.4651954],
                [106.0661035, 20.4652931],
                [106.0664851, 20.4654667],
                [106.0668125, 20.4656559],
                [106.0672743, 20.4659435],
                [106.0684037, 20.46664],
                [106.0693855, 20.4671616],
                [106.070388, 20.4676754],
                [106.0711514, 20.4680224],
                [106.0715789, 20.4681802],
                [106.072048, 20.468315],
                [106.0726799, 20.4684106],
                [106.0732153, 20.4684531],
                [106.0739342, 20.468441],
                [106.0743688, 20.4684153],
                [106.074711, 20.4683291],
                [106.0749567, 20.4681962],
                [106.0752226, 20.4679654],
                [106.0753884, 20.4677581],
                [106.0755121, 20.4675205],
                [106.0756023, 20.4672525],
                [106.0756834, 20.4669082],
                [106.0757889, 20.4664491],
                [106.0759194, 20.4659745],
                [106.0760202, 20.4655031],
                [106.0761243, 20.4648337],
                [106.0762004, 20.4644085],
                [106.076307, 20.4641186],
                [106.0764779, 20.4638284],
                [106.0766831, 20.4634836],
                [106.0769769, 20.4631113],
                [106.0772416, 20.4628114],
                [106.077487, 20.4625659],
                [106.0777177, 20.4623566],
                [106.0783769, 20.4619459],
                [106.0788001, 20.4617083],
                [106.0790957, 20.4615799],
                [106.0794607, 20.4614782],
                [106.0797765, 20.461431],
                [106.0802757, 20.461437],
                [106.0808888, 20.4615054],
                [106.0813835, 20.4616108],
                [106.0817998, 20.4617529],
                [106.082127, 20.4619226],
                [106.0823753, 20.4620927],
                [106.0826284, 20.4622628],
                [106.0829068, 20.4624961],
                [106.083195, 20.4627473],
                [106.0834043, 20.4629901],
                [106.0835544, 20.4632512],
                [106.0837101, 20.4636298],
                [106.083821, 20.4639274],
                [106.0839326, 20.4643243],
                [106.0840532, 20.4646308],
                [106.0842031, 20.4648829],
                [106.0843629, 20.4650898],
                [106.084532, 20.4652605],
                [106.0847503, 20.4653946],
                [106.0849881, 20.4654745],
                [106.0851957, 20.4654732],
                [106.0853536, 20.4654541],
                [106.0855407, 20.4653807],
                [106.085708, 20.4652622],
                [106.0859239, 20.4650619],
                [106.0860759, 20.4648787],
                [106.0863305, 20.4645429],
                [106.0865162, 20.4642619],
                [106.0866821, 20.4639627],
                [106.0868279, 20.4636367],
                [106.0869737, 20.4632926],
                [106.0871096, 20.4629305],
                [106.0873132, 20.4623784],
                [106.0875646, 20.461753],
                [106.0878464, 20.4611175],
                [106.0880197, 20.460724],
                [106.088139, 20.4604817],
                [106.0883132, 20.460199],
                [106.088551, 20.4598594],
                [106.0889586, 20.4592615],
                [106.0892115, 20.4589028],
                [106.0895097, 20.4585851],
                [106.0899132, 20.4582668],
                [106.0904206, 20.4577966],
                [106.0908688, 20.4574231],
                [106.0914068, 20.4570352],
                [106.0916164, 20.4568877],
                [106.0917808, 20.456772],
                [106.092125, 20.4565913],
                [106.0924996, 20.4564379],
                [106.0928072, 20.4563764],
                [106.0932274, 20.4563189],
                [106.0936329, 20.4562888],
                [106.0942339, 20.4562851],
                [106.0945641, 20.4562417],
                [106.0949093, 20.4561709],
                [106.0954191, 20.4560302],
                [106.0959888, 20.4558617],
                [106.0965136, 20.4557073],
                [106.0968511, 20.4556297],
                [106.0976466, 20.4555146],
                [106.098367, 20.455414],
                [106.0990724, 20.4552995],
                [106.0995681, 20.4552826],
                [106.1001388, 20.4552515],
                [106.1004772, 20.4552838],
                [106.1006763, 20.4553913],
                [106.1009756, 20.4556654],
                [106.1012485, 20.4560072],
                [106.1013555, 20.4562401],
                [106.1014322, 20.4564731],
                [106.1014644, 20.4567753],
                [106.1015767, 20.4572345],
                [106.1017675, 20.457878],
                [106.1018447, 20.4582115],
                [106.1019658, 20.4586065],
                [106.1022708, 20.4592229],
                [106.1025147, 20.4597298],
                [106.1027435, 20.4602093],
                [106.1029948, 20.4606681],
                [106.1033152, 20.4613394],
                [106.1035743, 20.4618598],
                [106.1038631, 20.4623115],
                [106.1042284, 20.4629687],
                [106.1044798, 20.4634549],
                [106.1046483, 20.4639073],
                [106.1048777, 20.4644693],
                [106.1050163, 20.4649356],
                [106.1051395, 20.4653333],
                [106.105194, 20.4656146],
                [106.1052123, 20.4660679],
                [106.1052603, 20.4664799],
                [106.1052795, 20.4670432],
                [106.1052834, 20.4676065],
                [106.1052654, 20.4682318],
                [106.1051931, 20.4686308],
                [106.1050616, 20.46914],
                [106.1048411, 20.4698285],
                [106.1046712, 20.4702349],
                [106.1044643, 20.4707171],
                [106.1042416, 20.4711034],
                [106.1039442, 20.4715313],
                [106.1034972, 20.4720562],
                [106.1029687, 20.4727397],
                [106.1025066, 20.4732786],
                [106.1022839, 20.4736509],
                [106.102031, 20.4740099],
                [106.1018225, 20.4742723],
                [106.1015317, 20.4745833],
                [106.1011285, 20.4749293],
                [106.1008005, 20.4753024],
                [106.1004879, 20.4757029],
                [106.1001751, 20.4760897],
                [106.0998628, 20.4765588],
                [106.0996029, 20.4769933],
                [106.0992008, 20.4774906],
                [106.0989472, 20.477767],
                [106.0986338, 20.4780575],
                [106.0984105, 20.4783475],
                [106.0988429, 20.478549],
                [106.0997741, 20.4792369],
                [106.09958, 20.4797503],
                [106.0994837, 20.4801126],
                [106.0994631, 20.4806854],
                [106.1017031, 20.4813798],
                [106.102251, 20.4815665],
                [106.1021082, 20.4825782],
                [106.1015429, 20.4845476],
                [106.1013185, 20.4852238],
                [106.1012057, 20.4854806],
                [106.1010672, 20.4856909],
                [106.1007886, 20.4859021],
                [106.1004205, 20.4860789],
                [106.0997418, 20.4863686],
                [106.099822, 20.4866107],
                [106.0999911, 20.4871318],
                [106.1001436, 20.4874605],
                [106.1002961, 20.4877619],
                [106.1004328, 20.4879671],
                [106.1005652, 20.488216],
                [106.1008512, 20.4885343],
                [106.1012496, 20.4888967],
                [106.1017088, 20.4891257],
                [106.1022297, 20.4892531],
                [106.1024016, 20.4892712],
                [106.1028141, 20.4893145],
                [106.103457, 20.4893105],
                [106.1042384, 20.4893056],
                [106.1049447, 20.4892874],
                [106.1056132, 20.4892624],
                [106.1060335, 20.4892048],
                [106.1062591, 20.4892429],
                [106.1067593, 20.4893273],
                [106.1084875, 20.4895384],
                [106.1093465, 20.4898037],
                [106.1098466, 20.4899759],
                [106.110206, 20.4901491],
                [106.1105784, 20.4903454],
                [106.1109, 20.4906007],
                [106.1111957, 20.4908211],
                [106.1114662, 20.4910649],
                [106.1118522, 20.4913899],
                [106.1121486, 20.4917038],
                [106.1124581, 20.4920527],
                [106.1127814, 20.4925301],
                [106.1130335, 20.4929086],
                [106.1131378, 20.4931885],
                [106.1132167, 20.4934688],
                [106.1132572, 20.4937726],
                [106.1132855, 20.494135],
                [106.1133394, 20.4945323],
                [106.113412, 20.4948417],
                [106.1135816, 20.4952967],
                [106.1137506, 20.4956582],
                [106.1139055, 20.495856],
                [106.1142801, 20.4961259],
                [106.1145609, 20.4962729],
                [106.1150096, 20.496422],
                [106.1153104, 20.4964376],
                [106.1157836, 20.4964346],
                [106.1163462, 20.4963842],
                [106.1169468, 20.4963219],
                [106.1175605, 20.4962945],
                [106.1181745, 20.4962906],
                [106.1188719, 20.496327],
                [106.1193452, 20.496324],
                [106.1198822, 20.4963087],
                [106.1202271, 20.4962247],
                [106.1205716, 20.4961172],
                [106.1209424, 20.4960914],
                [106.1213005, 20.4960774],
                [106.122254, 20.496159],
                [106.1228936, 20.4961549],
                [106.1237, 20.4962432],
                [106.1243403, 20.4963326],
                [106.1249615, 20.4964513],
                [106.1255768, 20.4966345],
                [106.1260896, 20.496795],
                [106.1266158, 20.4970371],
                [106.1271421, 20.4972909],
                [106.1274377, 20.4974879],
                [106.1277403, 20.4977491],
                [106.127934, 20.4980051],
                [106.1283755, 20.4989028],
                [106.1291225, 20.4991654],
                [106.1300829, 20.4991935],
                [106.1307531, 20.4990077],
                [106.1332528, 20.4965916],
                [106.1346208, 20.4952755],
                [106.1352276, 20.4951737],
                [106.1405461, 20.4946674],
                [106.1401676, 20.4923911],
                [106.1398736, 20.490319],
                [106.1395753, 20.4889852],
                [106.1392245, 20.4870189],
                [106.1389427, 20.4839976],
                [106.1389182, 20.4832771],
                [106.1389157, 20.4816602],
                [106.1390191, 20.4800425],
                [106.1391764, 20.4792328],
                [106.1394206, 20.4784413],
                [106.1400023, 20.4765559],
                [106.1404641, 20.4753225],
                [106.1408608, 20.4743884],
                [106.1412588, 20.47363],
                [106.1416292, 20.4730475],
                [106.1414456, 20.4729076],
                [106.1423147, 20.4721817],
                [106.1430011, 20.4714214],
                [106.1433832, 20.4704431],
                [106.1437636, 20.4705903],
                [106.145321, 20.4693849],
                [106.1459909, 20.4690114],
                [106.1466811, 20.4687783],
                [106.1473333, 20.4686158],
                [106.1479671, 20.4685589],
                [106.1486781, 20.4685542],
                [106.1495094, 20.4686612],
                [106.1502086, 20.4688956],
                [106.1509995, 20.4692771],
                [106.1540211, 20.4711202],
                [106.154793, 20.4715368],
                [106.1556028, 20.4718831],
                [106.1582221, 20.4726038],
                [106.1597717, 20.4729274],
                [106.1620204, 20.4729299],
                [106.1635959, 20.472849],
                [106.165775, 20.4728385],
                [106.1685498, 20.4728852],
                [106.1702093, 20.4729525],
                [106.171283, 20.4731022],
                [106.1717343, 20.473206],
                [106.1721214, 20.4733342],
                [106.1726095, 20.4735794],
                [106.1732854, 20.4740588],
                [106.1757708, 20.4764745],
                [106.1763189, 20.4770855],
                [106.1766954, 20.4773889],
                [106.1774234, 20.4779612],
                [106.1782669, 20.4781821],
                [106.1789608, 20.478463],
                [106.1800418, 20.4797991],
                [106.1801569, 20.4799571],
                [106.1802778, 20.4801229],
                [106.1810436, 20.4818452],
                [106.1816619, 20.4828143],
                [106.1832245, 20.4847086],
                [106.1820703, 20.4864534],
                [106.1815455, 20.4873724],
                [106.181038, 20.48852],
                [106.1804542, 20.489897],
                [106.1798695, 20.4911597],
                [106.1793477, 20.4924791],
                [106.1788435, 20.4940558],
                [106.1785712, 20.495259],
                [106.1785014, 20.5059855],
                [106.1787641, 20.5078016],
                [106.178985, 20.5093057],
                [106.1792331, 20.5109329],
                [106.1794407, 20.5124001],
                [106.1796374, 20.5141511],
                [106.1797042, 20.5160265],
                [106.179681, 20.5181369],
                [106.1795837, 20.5191001],
                [106.179134, 20.5219168],
                [106.1785085, 20.5247655],
                [106.1783117, 20.5256616],
                [106.1778288, 20.5275654],
                [106.1773135, 20.5303702],
                [106.1767548, 20.5326446],
                [106.1765131, 20.5335719],
                [106.1762321, 20.5344869],
                [106.1759503, 20.5353033],
                [106.1747364, 20.5392235],
                [106.1736603, 20.5423407],
                [106.1726702, 20.5447045],
                [106.1719243, 20.5464865],
                [106.1705684, 20.5489762],
                [106.1701213, 20.5496348],
                [106.1698951, 20.5499679],
                [106.1687546, 20.5515059],
                [106.1661457, 20.5545469],
                [106.1648995, 20.5559868],
                [106.1637703, 20.5573149],
                [106.1627579, 20.5585064],
                [106.1623949, 20.5589778],
                [106.1621362, 20.5593991],
                [106.1618778, 20.5598698],
                [106.1615828, 20.5606738],
                [106.161248, 20.5614413],
                [106.1608866, 20.5621347],
                [106.1602916, 20.5631012],
                [106.1599295, 20.5636961],
                [106.1596959, 20.5639938],
                [106.1590334, 20.5647016],
                [106.1582524, 20.5653239],
                [106.1573924, 20.5658974],
                [106.1565063, 20.5664709],
                [106.154575, 20.567397],
                [106.1525373, 20.568077],
                [106.1509288, 20.5683962],
                [106.148835, 20.5685828],
                [106.146661, 20.5688779],
                [106.1463492, 20.5689202],
                [106.142306, 20.5693788],
                [106.1408147, 20.5695862],
                [106.1397689, 20.5698399],
                [106.1373786, 20.5706824],
                [106.1360214, 20.571333],
                [106.1350824, 20.5718575],
                [106.1340138, 20.5725803],
                [106.1331823, 20.5734742],
                [106.1321571, 20.5747521],
                [106.1316402, 20.5756934],
                [106.1310232, 20.577277],
                [106.1303548, 20.5789844],
                [106.1298032, 20.5805552],
                [106.1295245, 20.5818405],
                [106.129328, 20.5836188],
                [106.1293512, 20.5849884],
                [106.1293385, 20.5868151],
                [106.1292985, 20.5884936],
                [106.1294253, 20.5897144],
                [106.1295535, 20.5907181],
                [106.1295922, 20.5910215],
                [106.1297573, 20.5921065],
                [106.1302437, 20.5941271],
                [106.1306056, 20.5952478],
                [106.1307144, 20.5957901],
                [106.1307446, 20.5963328],
                [106.1307488, 20.5969005],
                [106.1307017, 20.5976165],
                [106.1306609, 20.5991965],
                [106.1306345, 20.6009367],
                [106.1306401, 20.6017017],
                [106.13062, 20.6025163],
                [106.130766, 20.604564],
                [106.1308407, 20.6058099],
                [106.1308429, 20.606106],
                [106.1308188, 20.6064024],
                [106.1307432, 20.6067978],
                [106.1306994, 20.6069591],
                [106.1306157, 20.6072675],
                [106.1298665, 20.6087039],
                [106.1290003, 20.6102522],
                [106.1284034, 20.6110212],
                [106.1275201, 20.612039],
                [106.1254638, 20.6138541],
                [106.1238622, 20.6151849],
                [106.1236024, 20.6154828],
                [106.1233718, 20.6157632],
                [106.1233168, 20.6158302],
                [106.1230307, 20.6161282],
                [106.1226916, 20.6163279],
                [106.1222744, 20.6166021],
                [106.1218567, 20.6168022],
                [106.1213607, 20.6170523],
                [106.1208502, 20.6172283],
                [106.1137054, 20.6182618],
                [106.1111397, 20.6185251],
                [106.1090442, 20.618588],
                [106.1076812, 20.618498],
                [106.1066318, 20.6183073],
                [106.1060105, 20.6181745],
                [106.1041653, 20.6177801],
                [106.0998071, 20.6165245],
                [106.0966029, 20.6154095],
                [106.0939739, 20.6141427],
                [106.0910268, 20.6123349],
                [106.086842, 20.6096215],
                [106.0850491, 20.6080039],
                [106.0826251, 20.6060445],
                [106.080782, 20.6047232],
                [106.0796245, 20.6040393],
                [106.0787046, 20.6036255],
                [106.0766052, 20.6030956],
                [106.0734623, 20.6032139],
                [106.0704263, 20.6036276],
                [106.0704129, 20.6036303],
                [106.0672868, 20.6042394],
                [106.0656936, 20.6046409],
                [106.0638856, 20.605324],
                [106.0614418, 20.606138],
                [106.0597694, 20.6069291],
                [106.0575225, 20.608323],
                [106.056094, 20.6095428],
                [106.054618, 20.6108601],
                [106.052969, 20.6122692],
                [106.0522897, 20.6130638],
                [106.0517176, 20.614012],
                [106.0514129, 20.6145773],
                [106.051165, 20.6150148],
                [106.0509171, 20.615416],
                [106.049778, 20.6166676],
                [106.0485906, 20.6179105],
                [106.0468274, 20.6195564],
                [106.0450931, 20.6212113],
                [106.0435616, 20.6229103],
                [106.0413683, 20.6251676],
                [106.0397218, 20.6269946],
                [106.0390044, 20.6278619],
                [106.0380684, 20.6292303],
                [106.0369442, 20.6312903],
                [106.036725, 20.6316822],
                [106.0367222, 20.6316876],
                [106.035735, 20.6336142],
                [106.0349758, 20.6354539],
                [106.0344557, 20.6369924],
                [106.0337582, 20.6394132],
                [106.0331975, 20.641016],
                [106.0331812, 20.6410627],
                [106.0331054, 20.6412796],
                [106.0323092, 20.643392],
                [106.031569, 20.6452135],
                [106.0313635, 20.646214],
                [106.0325656, 20.6505729],
                [106.0330203, 20.6522216],
                [106.0336636, 20.6537316],
                [106.0353174, 20.6576136],
                [106.0362358, 20.6598153],
                [106.0367409, 20.6619424],
                [106.0372514, 20.6647211],
                [106.0377374, 20.6685804],
                [106.0376116, 20.6719998],
                [106.0370546, 20.6755024],
                [106.0356282, 20.6796778],
                [106.0335671, 20.6841399],
                [106.0323063, 20.6860371],
                [106.0310743, 20.6879069],
                [106.0302529, 20.6891838],
                [106.0292106, 20.6905619],
                [106.0282549, 20.6916404],
                [106.0280614, 20.6918588],
                [106.0279405, 20.6919906],
                [106.0268545, 20.6931742],
                [106.0258487, 20.6942794],
                [106.0249289, 20.6952479],
                [106.0237018, 20.6964545],
                [106.0217156, 20.6981288],
                [106.0202753, 20.6991912],
                [106.0190644, 20.6999252],
                [106.0172078, 20.700781],
                [106.0144064, 20.7017878],
                [106.012057, 20.7025466],
                [106.0089182, 20.7036008],
                [106.0077338, 20.703962],
                [106.0074059, 20.7040184],
                [106.0068467, 20.7040944],
                [106.0062104, 20.7041526],
                [106.0043205, 20.7043816],
                [106.0028888, 20.7043642],
                [106.0029325, 20.7032194],
                [106.0027316, 20.7023361],
                [106.0023838, 20.701872],
                [105.9947996, 20.6975506],
                [105.9974321, 20.6935752],
                [105.9970185, 20.6932234],
                [105.9972926, 20.6924839],
                [105.9911518, 20.6897756],
                [105.9925432, 20.6847094],
                [105.9936505, 20.6851451],
                [105.9930321, 20.683091],
                [105.9952133, 20.6818244],
                [105.9967928, 20.6780949],
                [105.9940512, 20.6764649],
                [105.9911717, 20.678142],
                [105.9938008, 20.6814219],
                [105.9930679, 20.6821704],
                [105.9928696, 20.6819038],
                [105.9924091, 20.6811691],
                [105.9919053, 20.6814474],
                [105.9911336, 20.681853],
                [105.9907661, 20.6820434],
                [105.9901558, 20.6821657],
                [105.9893977, 20.6822394],
                [105.9888183, 20.6822427],
                [105.9884494, 20.6822349],
                [105.9882907, 20.682117],
                [105.9879732, 20.6819009],
                [105.9874388, 20.6815324],
                [105.9870686, 20.6813067],
                [105.9866558, 20.681012],
                [105.9861692, 20.6807275],
                [105.9858202, 20.6805116],
                [105.9856305, 20.6805126],
                [105.9852723, 20.6805148],
                [105.9847665, 20.6804879],
                [105.9845967, 20.6803106],
                [105.9841723, 20.6798375],
                [105.9837319, 20.6793596],
                [105.9834989, 20.6791629],
                [105.9830745, 20.6787097],
                [105.9825552, 20.6782073],
                [105.9821094, 20.6777246],
                [105.9814042, 20.6769906],
                [105.981086, 20.6766754],
                [105.9805559, 20.6761435],
                [105.9801207, 20.6756508],
                [105.979601, 20.675099],
                [105.9791606, 20.6746112],
                [105.9787894, 20.6742171],
                [105.9784601, 20.6738029],
                [105.9783955, 20.6735754],
                [105.9784358, 20.6732979],
                [105.9785174, 20.6728814],
                [105.978652, 20.672524],
                [105.9787816, 20.6721914],
                [105.978864, 20.6718838],
                [105.9789031, 20.671428],
                [105.9788899, 20.6710121],
                [105.9788021, 20.6704479],
                [105.9786877, 20.6698576],
                [105.9786658, 20.6697291],
                [105.9785578, 20.6693139],
                [105.9783968, 20.6688495],
                [105.978139, 20.6680788],
                [105.9779769, 20.6674362],
                [105.9778468, 20.6668629],
                [105.9772625, 20.6654821],
                [105.9720897, 20.6652428],
                [105.9693905, 20.6650195],
                [105.9658722, 20.6642986],
                [105.9635601, 20.6633157],
                [105.9606867, 20.6614401],
                [105.9585796, 20.659516],
                [105.9573903, 20.6580168],
                [105.9566898, 20.6561734],
                [105.9566868, 20.6551739],
                [105.9576993, 20.6519805],
                [105.9584285, 20.6503094],
                [105.9536369, 20.6516473],
                [105.949117, 20.6529379],
                [105.9463266, 20.6539681],
                [105.9449448, 20.654832],
                [105.9447811, 20.6551843],
                [105.9434954, 20.6579511],
                [105.9432264, 20.6589748],
                [105.9429359, 20.6610914],
                [105.9430131, 20.6621374],
                [105.9432402, 20.6637179],
                [105.9448024, 20.665318],
                [105.9460392, 20.6660947],
                [105.9466039, 20.6664492],
                [105.9467649, 20.6687686],
                [105.9466882, 20.6763499],
                [105.946052, 20.6786771],
                [105.9455108, 20.6796552],
                [105.9441304, 20.6811935],
                [105.9431434, 20.6819403],
                [105.9422035, 20.6818962],
                [105.9412623, 20.681387],
                [105.9396783, 20.6809259],
                [105.9386879, 20.6805099],
                [105.9346806, 20.6801479],
                [105.9323559, 20.6801072],
                [105.9305242, 20.6795073],
                [105.9228451, 20.6766861],
                [105.920699, 20.6760646],
                [105.9195311, 20.6758219],
                [105.9191015, 20.6751651],
                [105.918122, 20.6737881],
                [105.9173312, 20.6743096],
                [105.9153545, 20.6729135],
                [105.9116575, 20.6709945],
                [105.9094882, 20.6698106],
                [105.9075955, 20.668775],
                [105.9065539, 20.6677436],
                [105.9055698, 20.6657352],
                [105.9053951, 20.6650851],
                [105.905202, 20.6650647],
                [105.9042865, 20.6649677],
                [105.9029133, 20.6649068],
                [105.9025047, 20.6650451],
                [105.9021386, 20.6653658],
                [105.9013066, 20.6660949],
                [105.9005407, 20.6669156],
                [105.8997735, 20.6675094],
                [105.8987897, 20.6681498],
                [105.8978535, 20.6686765],
                [105.8970601, 20.66893],
                [105.8964708, 20.6691244],
                [105.8961353, 20.6693114],
                [105.8960214, 20.6679895],
                [105.895782, 20.6665477],
                [105.8957057, 20.6656884],
                [105.8957503, 20.6638298],
                [105.8960416, 20.6617849],
                [105.8960054, 20.6573246],
                [105.8961001, 20.6557447],
                [105.8961191, 20.6535376],
                [105.8960653, 20.6518419],
                [105.8953931, 20.6512459],
                [105.8943068, 20.6503199],
                [105.8925506, 20.6488988],
                [105.8913817, 20.6482207],
                [105.8912092, 20.6481207],
                [105.8909584, 20.6479751],
                [105.8894815, 20.6471158],
                [105.8878425, 20.6463228],
                [105.8856456, 20.6452149],
                [105.8828742, 20.6441594],
                [105.8815664, 20.6437044],
                [105.8799261, 20.6432292],
                [105.8784859, 20.6430242],
                [105.877024, 20.6430066],
                [105.8748305, 20.6431424],
                [105.8733918, 20.6433399],
                [105.8715687, 20.6438075],
                [105.8697331, 20.6445525],
                [105.8689895, 20.6448108],
                [105.8675935, 20.6451502],
                [105.8664201, 20.64534],
                [105.8657335, 20.6453831],
                [105.8649581, 20.6453223],
                [105.8638577, 20.6450713],
                [105.8630521, 20.6448268],
                [105.8628088, 20.6447814],
                [105.8620064, 20.6446316],
                [105.8608144, 20.6446232],
                [105.8595743, 20.6446883],
                [105.8588214, 20.6447296],
                [105.8579283, 20.6449094],
                [105.8567633, 20.6452963],
                [105.8556349, 20.645736],
                [105.8548767, 20.6461796],
                [105.8546243, 20.6464197],
                [105.8541296, 20.64689],
                [105.8536024, 20.647389],
                [105.8528832, 20.648064],
                [105.852147, 20.6488712],
                [105.8515553, 20.6495905],
                [105.8509832, 20.6503715],
                [105.8506634, 20.6508221],
                [105.8495684, 20.6525598],
                [105.8399975, 20.6594258],
                [105.8346186, 20.6613343],
                [105.8310629, 20.6620289],
                [105.8290091, 20.6631129],
                [105.8278622, 20.663779],
                [105.8224187, 20.6645602],
                [105.8224591, 20.6626855],
                [105.8223147, 20.6624879],
                [105.8218899, 20.6621541],
                [105.8196213, 20.6603984],
                [105.821584, 20.6588377],
                [105.8223555, 20.6581671],
                [105.8230418, 20.6574783],
                [105.823518, 20.6568919],
                [105.8245927, 20.6555016],
                [105.8263131, 20.653438],
                [105.828041, 20.6521202],
                [105.8291524, 20.6516298],
                [105.8307734, 20.6509525],
                [105.8322709, 20.6503098],
                [105.8337056, 20.6493573],
                [105.8341609, 20.6486439],
                [105.8341603, 20.6476823],
                [105.8339914, 20.6471361],
                [105.8337608, 20.6468958],
                [105.8334936, 20.6463356],
                [105.8331026, 20.6456907],
                [105.8326449, 20.6451883],
                [105.8321013, 20.64465],
                [105.8315101, 20.644217],
                [105.8310618, 20.6439306],
                [105.8306164, 20.6436461],
                [105.8282427, 20.6423084],
                [105.8255619, 20.6408818],
                [105.8250831, 20.6406689],
                [105.8246846, 20.6406489],
                [105.8243968, 20.6406702],
                [105.8241533, 20.6406707],
                [105.8214265, 20.6389265],
                [105.8197304, 20.6377948],
                [105.8148048, 20.6343043],
                [105.8126502, 20.6295675],
                [105.8119705, 20.628295],
                [105.811734, 20.627797],
                [105.8122081, 20.6275246],
                [105.8124843, 20.6273263],
                [105.8126164, 20.6269513],
                [105.812704, 20.6264929],
                [105.8127468, 20.6257016],
                [105.8128566, 20.6253473],
                [105.8130773, 20.6249929],
                [105.8132981, 20.6247218],
                [105.8135412, 20.6244923],
                [105.8135629, 20.624284],
                [105.813507, 20.623999],
                [105.8134607, 20.6230211],
                [105.8133915, 20.6216688],
                [105.8134569, 20.6212109],
                [105.8136114, 20.62094],
                [105.8138988, 20.6207938],
                [105.8144294, 20.6204183],
                [105.814849, 20.6199598],
                [105.815025, 20.6194186],
                [105.8151343, 20.6187733],
                [105.8150887, 20.6180867],
                [105.8149556, 20.6179621],
                [105.8144817, 20.6179119],
                [105.8142463, 20.61794],
                [105.8141372, 20.6182549],
                [105.814071, 20.6186321],
                [105.8138433, 20.6186318],
                [105.8136076, 20.6185493],
                [105.8134226, 20.6182459],
                [105.8134077, 20.6182485],
                [105.8129809, 20.618219],
                [105.8129797, 20.6176102],
                [105.8128363, 20.6167761],
                [105.8125605, 20.6162361],
                [105.812041, 20.6157784],
                [105.8110606, 20.6150385],
                [105.8091817, 20.6138942],
                [105.8076394, 20.6128185],
                [105.8064706, 20.611782],
                [105.8061238, 20.6113776],
                [105.8058185, 20.6106889],
                [105.8055992, 20.6099323],
                [105.8055965, 20.6094317],
                [105.8057228, 20.608863],
                [105.8059502, 20.608402],
                [105.8067877, 20.6070943],
                [105.8073399, 20.6065107],
                [105.8080912, 20.6058644],
                [105.8087102, 20.6054887],
                [105.8099709, 20.6050286],
                [105.81104, 20.6047457],
                [105.8116282, 20.6044403],
                [105.8103307, 20.6033633],
                [105.8097707, 20.6030323],
                [105.8083496, 20.6023058],
                [105.8069296, 20.6005813],
                [105.8069956, 20.6003731],
                [105.8085201, 20.5990387],
                [105.8077641, 20.5973129],
                [105.8071227, 20.5975221],
                [105.8068795, 20.5976474],
                [105.8066809, 20.5979599],
                [105.8066598, 20.5985009],
                [105.8065279, 20.5989381],
                [105.8063071, 20.5992298],
                [105.8058317, 20.5994284],
                [105.8054223, 20.5994603],
                [105.8045222, 20.5980299],
                [105.8030021, 20.5956826],
                [105.8019045, 20.5940831],
                [105.8015145, 20.5936385],
                [105.8012826, 20.59322],
                [105.801122, 20.5927201],
                [105.8009881, 20.5918234],
                [105.8010821, 20.590592],
                [105.8010805, 20.5902807],
                [105.8009034, 20.5893616],
                [105.8008572, 20.5887801],
                [105.8007521, 20.5878673],
                [105.801087, 20.5872173],
                [105.8014856, 20.5864643],
                [105.8017608, 20.5855958],
                [105.8017908, 20.5851618],
                [105.801728, 20.5845543],
                [105.8015113, 20.5838602],
                [105.8008018, 20.5830513],
                [105.8004317, 20.582589],
                [105.8000001, 20.5822136],
                [105.7997668, 20.5820496],
                [105.7996011, 20.5818727],
                [105.7995407, 20.5817883],
                [105.7994275, 20.58163],
                [105.7991129, 20.5802825],
                [105.7986698, 20.5785703],
                [105.7984529, 20.5778184],
                [105.7980813, 20.5766327],
                [105.7978022, 20.5755048],
                [105.7974677, 20.574885],
                [105.7971799, 20.5738263],
                [105.7969908, 20.5733536],
                [105.7968301, 20.5728267],
                [105.7967693, 20.5721775],
                [105.796636, 20.5713799],
                [105.7965875, 20.5703385],
                [105.7963131, 20.5692577],
                [105.7960224, 20.5686839],
                [105.7954586, 20.5679286],
                [105.7949155, 20.567482],
                [105.7946422, 20.567375],
                [105.794221, 20.5673792],
                [105.7921548, 20.567037],
                [105.7909539, 20.5666339],
                [105.7902461, 20.5665193],
                [105.7898152, 20.5664912],
                [105.7872922, 20.5665243],
                [105.7856613, 20.5665559],
                [105.7847071, 20.5663549],
                [105.7836954, 20.5660057],
                [105.7830748, 20.5655475],
                [105.7826126, 20.5651722],
                [105.7821504, 20.5648546],
                [105.7816581, 20.5648843],
                [105.7800567, 20.5660868],
                [105.7796983, 20.5661965],
                [105.7794829, 20.5661974],
                [105.7792816, 20.5661442],
                [105.7787773, 20.5657674],
                [105.7776269, 20.5646594],
                [105.7771343, 20.5645154],
                [105.7706116, 20.5647862],
                [105.7690113, 20.5649591],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.2889615, 21.1570007],
                [105.2902315, 21.1551395],
                [105.2913673, 21.1533533],
                [105.2927702, 21.151339],
                [105.2960807, 21.1470416],
                [105.2960882, 21.1470318],
                [105.2978297, 21.1448353],
                [105.3024852, 21.1395441],
                [105.3040393, 21.137726],
                [105.3069874, 21.1347456],
                [105.308515, 21.1331797],
                [105.3095861, 21.131665],
                [105.3105985, 21.130042],
                [105.3112141, 21.1290575],
                [105.3118169, 21.1278537],
                [105.3121093, 21.1272697],
                [105.3123641, 21.126761],
                [105.3128982, 21.1253229],
                [105.3137003, 21.1236069],
                [105.315438, 21.12077],
                [105.3165089, 21.1192302],
                [105.3180347, 21.1169328],
                [105.3189443, 21.1153428],
                [105.31988, 21.1136129],
                [105.3199579, 21.113163],
                [105.3202249, 21.1116205],
                [105.3202733, 21.1091748],
                [105.3196208, 21.1054324],
                [105.3190758, 21.101702],
                [105.3183438, 21.0983379],
                [105.3182346, 21.0974808],
                [105.3177477, 21.0958177],
                [105.3168032, 21.0935251],
                [105.3150558, 21.0899375],
                [105.3146786, 21.0893331],
                [105.3137066, 21.0878432],
                [105.3123622, 21.0857824],
                [105.310559, 21.0836678],
                [105.3090044, 21.0815661],
                [105.3071735, 21.0789725],
                [105.3060428, 21.0774618],
                [105.3049932, 21.0761526],
                [105.3040517, 21.0748547],
                [105.3031367, 21.0737721],
                [105.3014133, 21.0711532],
                [105.300955, 21.0701707],
                [105.3005771, 21.0691627],
                [105.2995788, 21.0667194],
                [105.2991206, 21.0657597],
                [105.2986123, 21.0646952],
                [105.2971838, 21.0617982],
                [105.2961867, 21.0598081],
                [105.2954074, 21.0584079],
                [105.2953886, 21.0583584],
                [105.29449, 21.055989],
                [105.2943003, 21.0550313],
                [105.2941628, 21.0534178],
                [105.2944772, 21.0495752],
                [105.2949262, 21.045843],
                [105.2951083, 21.0451533],
                [105.2955922, 21.0433206],
                [105.2959834, 21.039958],
                [105.296837, 21.0373596],
                [105.2974779, 21.0356943],
                [105.2984942, 21.0339529],
                [105.2995075, 21.0325751],
                [105.3013812, 21.0300505],
                [105.3023715, 21.0287377],
                [105.3026286, 21.0281632],
                [105.3026298, 21.0281606],
                [105.3034669, 21.0262901],
                [105.3042408, 21.0246647],
                [105.3045328, 21.0230756],
                [105.3048259, 21.0220667],
                [105.3054953, 21.021309],
                [105.3062455, 21.0206774],
                [105.3073444, 21.0200199],
                [105.3102579, 21.0184015],
                [105.3129117, 21.0170108],
                [105.3142517, 21.0161265],
                [105.3154112, 21.0151478],
                [105.3164205, 21.0138547],
                [105.3175069, 21.0121743],
                [105.3195041, 21.0134907],
                [105.3207281, 21.0142758],
                [105.3217637, 21.0149275],
                [105.3219426, 21.0150704],
                [105.3221715, 21.0152534],
                [105.3226849, 21.0157437],
                [105.322974, 21.015969],
                [105.3232151, 21.0161943],
                [105.3235051, 21.0167806],
                [105.3237362, 21.017371],
                [105.3238255, 21.0175991],
                [105.3238721, 21.0180132],
                [105.3239898, 21.01836],
                [105.323991, 21.018992],
                [105.3240397, 21.0192178],
                [105.3241856, 21.0199399],
                [105.3242834, 21.0206621],
                [105.3245688, 21.0215064],
                [105.3247242, 21.0215607],
                [105.3248018, 21.0215687],
                [105.3252955, 21.0213826],
                [105.3257666, 21.0210934],
                [105.3265819, 21.0203049],
                [105.3270997, 21.0200155],
                [105.3275023, 21.0197263],
                [105.3280779, 21.019563],
                [105.3283439, 21.0188353],
                [105.3287044, 21.017764],
                [105.328817, 21.0174382],
                [105.3287657, 21.0172784],
                [105.328815, 21.0171334],
                [105.3288847, 21.0169353],
                [105.328891, 21.0168111],
                [105.3288308, 21.0166396],
                [105.3286946, 21.0165154],
                [105.3285133, 21.0164359],
                [105.3283969, 21.0164976],
                [105.3281941, 21.0167371],
                [105.3280579, 21.0169353],
                [105.3279185, 21.0170535],
                [105.3276049, 21.0170092],
                [105.3272185, 21.0168732],
                [105.3269936, 21.0167726],
                [105.3268257, 21.0166041],
                [105.326699, 21.0163527],
                [105.3266566, 21.0161172],
                [105.3266683, 21.0158377],
                [105.3267422, 21.0152532],
                [105.3268939, 21.014578],
                [105.3269756, 21.0144327],
                [105.3271421, 21.0142637],
                [105.3275561, 21.014114],
                [105.327647, 21.014039],
                [105.3276699, 21.0140077],
                [105.3277372, 21.0138193],
                [105.3277009, 21.0137227],
                [105.3273646, 21.0135392],
                [105.3271214, 21.0134377],
                [105.3269351, 21.0133315],
                [105.3267385, 21.0131189],
                [105.3267902, 21.0129499],
                [105.3271214, 21.012549],
                [105.3271429, 21.0125088],
                [105.3272042, 21.0123944],
                [105.327168, 21.0121625],
                [105.3268368, 21.0117278],
                [105.3265626, 21.0113945],
                [105.326278, 21.011037],
                [105.3260399, 21.0110177],
                [105.3258588, 21.0111433],
                [105.3256725, 21.0112254],
                [105.325538, 21.0112109],
                [105.3253569, 21.011124],
                [105.3252638, 21.0109452],
                [105.3251706, 21.0105395],
                [105.3251166, 21.0103122],
                [105.3253224, 21.0101862],
                [105.325499, 21.0098821],
                [105.3256904, 21.0094674],
                [105.3258669, 21.0092188],
                [105.326058, 21.0090253],
                [105.3264984, 21.0089981],
                [105.3268507, 21.0091367],
                [105.3272471, 21.0092202],
                [105.3276582, 21.0092206],
                [105.3279374, 21.0090272],
                [105.3282166, 21.0087925],
                [105.3285547, 21.0085163],
                [105.3288338, 21.0083506],
                [105.3290542, 21.008254],
                [105.329304, 21.0081436],
                [105.3295389, 21.0081439],
                [105.3298324, 21.0082409],
                [105.330258, 21.0084211],
                [105.3303796, 21.0085783],
                [105.3307423, 21.0086843],
                [105.3308766, 21.0086325],
                [105.3313114, 21.0085042],
                [105.3326612, 21.0081839],
                [105.3333248, 21.0079701],
                [105.3336451, 21.0079489],
                [105.3339195, 21.0079707],
                [105.3343309, 21.0082927],
                [105.3350394, 21.0088295],
                [105.3352679, 21.0090442],
                [105.3353591, 21.0092588],
                [105.3352673, 21.0095804],
                [105.3350383, 21.0098161],
                [105.3346265, 21.0099442],
                [105.334489, 21.0100514],
                [105.334466, 21.0102443],
                [105.3345573, 21.0104159],
                [105.3359057, 21.0113823],
                [105.3362489, 21.0114684],
                [105.3370537, 21.0115536],
                [105.3374839, 21.0116626],
                [105.3377586, 21.0118463],
                [105.3381238, 21.0121136],
                [105.3383859, 21.0124332],
                [105.3391189, 21.0135835],
                [105.3394056, 21.0135094],
                [105.3410997, 21.0129424],
                [105.3420905, 21.0127934],
                [105.3432383, 21.0128646],
                [105.3445505, 21.0130202],
                [105.3459658, 21.0131542],
                [105.3465661, 21.0133491],
                [105.347219, 21.0136908],
                [105.3479499, 21.0139589],
                [105.3482106, 21.0138848],
                [105.3486012, 21.0135899],
                [105.3493293, 21.0127063],
                [105.3504635, 21.0097902],
                [105.3510685, 21.0091003],
                [105.3515109, 21.0086828],
                [105.3517977, 21.0086086],
                [105.3524757, 21.0085828],
                [105.352893, 21.0085573],
                [105.3531534, 21.0084343],
                [105.353335, 21.0079682],
                [105.3534386, 21.0076986],
                [105.3537513, 21.0075508],
                [105.354186, 21.0074531],
                [105.3548036, 21.0074965],
                [105.357395, 21.0084534],
                [105.3611285, 21.0100385],
                [105.3631801, 21.0110128],
                [105.3651435, 21.0122716],
                [105.3665035, 21.0125326],
                [105.3671621, 21.012707],
                [105.3680566, 21.0127621],
                [105.3687419, 21.0131704],
                [105.3709133, 21.0129672],
                [105.3726972, 21.013183],
                [105.3736236, 21.0133874],
                [105.3742523, 21.013613],
                [105.3752583, 21.0142999],
                [105.376584, 21.0155019],
                [105.3772357, 21.0164867],
                [105.3788321, 21.0185661],
                [105.3800347, 21.0197719],
                [105.3818116, 21.0209366],
                [105.3841502, 21.0224767],
                [105.3864025, 21.0237844],
                [105.3872383, 21.0241746],
                [105.3874057, 21.0242734],
                [105.387539, 21.0240964],
                [105.3876285, 21.0237571],
                [105.3876271, 21.0232425],
                [105.3876765, 21.0221887],
                [105.3876507, 21.0217499],
                [105.3874654, 21.0212334],
                [105.3871938, 21.0207847],
                [105.3868054, 21.020334],
                [105.3866013, 21.0199365],
                [105.3866234, 21.0190472],
                [105.3866749, 21.0181229],
                [105.3868074, 21.0177178],
                [105.3870124, 21.0175095],
                [105.3876111, 21.0170426],
                [105.3890694, 21.0161325],
                [105.3911458, 21.0149646],
                [105.3922659, 21.014423],
                [105.3930479, 21.0142495],
                [105.393694, 21.0141685],
                [105.3929396, 21.0133135],
                [105.3925528, 21.0127854],
                [105.3923436, 21.0121733],
                [105.3922221, 21.0110599],
                [105.3923657, 21.0092775],
                [105.3922149, 21.0083312],
                [105.3919157, 21.0072738],
                [105.3919436, 21.0066055],
                [105.3925336, 21.0054626],
                [105.393272, 21.0043472],
                [105.3935078, 21.0038454],
                [105.3935661, 21.0034276],
                [105.393449, 21.0028749],
                [105.3937298, 21.0028512],
                [105.3940042, 21.0028076],
                [105.3944, 21.002635],
                [105.3953128, 21.0019314],
                [105.3963175, 21.0013708],
                [105.3976267, 21.0007093],
                [105.3984946, 21.0003494],
                [105.3991648, 21.0001617],
                [105.3994388, 21.0000322],
                [105.3996364, 20.9998448],
                [105.4011456, 20.99902],
                [105.4020875, 20.9984267],
                [105.4032698, 20.9981623],
                [105.4041743, 20.9981603],
                [105.405706, 20.9984835],
                [105.4072375, 20.9987414],
                [105.4084378, 20.9987712],
                [105.4093428, 20.9988998],
                [105.4098989, 20.9987023],
                [105.4102452, 20.998113],
                [105.4104538, 20.9980471],
                [105.4108017, 20.9980463],
                [105.4115675, 20.9981752],
                [105.4138637, 20.9981697],
                [105.4148373, 20.9979711],
                [105.4159499, 20.9977069],
                [105.4165072, 20.9979671],
                [105.4173419, 20.9978343],
                [105.4178979, 20.9976367],
                [105.4179663, 20.9971789],
                [105.4176151, 20.9960027],
                [105.4177694, 20.9953698],
                [105.4179022, 20.9953695],
                [105.4180794, 20.9953898],
                [105.4182122, 20.9953895],
                [105.4183007, 20.9953476],
                [105.418367, 20.9952645],
                [105.4184108, 20.9951188],
                [105.4184101, 20.9948902],
                [105.4184319, 20.9947447],
                [105.4184539, 20.9946822],
                [105.4186308, 20.9945779],
                [105.4187194, 20.9945777],
                [105.4188076, 20.9944943],
                [105.4188295, 20.9943902],
                [105.4188293, 20.9943071],
                [105.4187626, 20.9942242],
                [105.4185853, 20.9941414],
                [105.41843, 20.9940587],
                [105.4183631, 20.9939133],
                [105.4183847, 20.9936847],
                [105.4184505, 20.9934559],
                [105.4185384, 20.9932478],
                [105.4188255, 20.9929353],
                [105.4193998, 20.9924351],
                [105.420063, 20.9920385],
                [105.420306, 20.9918508],
                [105.4204163, 20.991705],
                [105.4204378, 20.9914763],
                [105.4204154, 20.9913724],
                [105.4203487, 20.9912687],
                [105.4201712, 20.9911444],
                [105.4200601, 20.9910408],
                [105.4200375, 20.9908537],
                [105.4200365, 20.9905004],
                [105.4201233, 20.9898975],
                [105.420255, 20.9894606],
                [105.4204753, 20.9890651],
                [105.4210045, 20.9882532],
                [105.4212022, 20.9877123],
                [105.4212895, 20.987234],
                [105.4212205, 20.9863195],
                [105.4212407, 20.9856336],
                [105.4213063, 20.9853424],
                [105.4214384, 20.9851134],
                [105.4217478, 20.9848632],
                [105.4219464, 20.9846134],
                [105.4219899, 20.9843638],
                [105.4219892, 20.9840936],
                [105.4219219, 20.9838028],
                [105.4221563, 20.9835491],
                [105.4229204, 20.9830895],
                [105.4245885, 20.9824969],
                [105.4261183, 20.9822315],
                [105.4277171, 20.9817045],
                [105.4291766, 20.9811777],
                [105.4301492, 20.9806521],
                [105.4318151, 20.9792749],
                [105.4330636, 20.977964],
                [105.4338266, 20.9771775],
                [105.4351606, 20.9753759],
                [105.4357144, 20.9743938],
                [105.4369614, 20.9725599],
                [105.4385526, 20.969548],
                [105.4391863, 20.9688065],
                [105.4398422, 20.968544],
                [105.4400086, 20.9678443],
                [105.4412028, 20.9658469],
                [105.4422415, 20.9642096],
                [105.442725, 20.9630315],
                [105.442719, 20.9610046],
                [105.4432031, 20.9600225],
                [105.4440319, 20.9579935],
                [105.4442091, 20.9564159],
                [105.4465448, 20.9556565],
                [105.4510266, 20.9543021],
                [105.4532952, 20.9533159],
                [105.455358, 20.9516595],
                [105.4556416, 20.9511225],
                [105.4567808, 20.9505163],
                [105.4579206, 20.9500776],
                [105.4590853, 20.9485131],
                [105.4596272, 20.9477074],
                [105.461064, 20.9469793],
                [105.4616485, 20.9467179],
                [105.4621963, 20.9466024],
                [105.4642979, 20.9462066],
                [105.4654589, 20.9458134],
                [105.466012, 20.9457338],
                [105.4664826, 20.9458365],
                [105.4670644, 20.946095],
                [105.4677589, 20.9469514],
                [105.4685914, 20.9477294],
                [105.469395, 20.9481172],
                [105.4705587, 20.9486082],
                [105.4712785, 20.9487363],
                [105.4726343, 20.9487065],
                [105.474543, 20.9485712],
                [105.4765624, 20.9483835],
                [105.4783328, 20.9482744],
                [105.4794941, 20.9480112],
                [105.4799361, 20.9477758],
                [105.4809987, 20.9464532],
                [105.4813116, 20.9464656],
                [105.4819882, 20.9456317],
                [105.4824393, 20.9451081],
                [105.4830957, 20.9444292],
                [105.4837724, 20.9436535],
                [105.4844694, 20.9428196],
                [105.4853505, 20.9415982],
                [105.4856372, 20.9411718],
                [105.4860865, 20.9400873],
                [105.4862914, 20.9398158],
                [105.4864965, 20.9396024],
                [105.4869275, 20.9392336],
                [105.487338, 20.9388649],
                [105.4876254, 20.9386512],
                [105.487851, 20.9384184],
                [105.4880762, 20.9380114],
                [105.4883013, 20.9376819],
                [105.4887731, 20.9371583],
                [105.4889365, 20.9368096],
                [105.4889766, 20.9364805],
                [105.4889551, 20.9361904],
                [105.4889132, 20.935939],
                [105.4888095, 20.9357073],
                [105.488561, 20.9353978],
                [105.4903229, 20.9343671],
                [105.4908691, 20.9339796],
                [105.4930311, 20.933819],
                [105.4945893, 20.933197],
                [105.4966386, 20.9320847],
                [105.4982239, 20.9313597],
                [105.4990427, 20.9306497],
                [105.4994807, 20.9306484],
                [105.5003261, 20.930955],
                [105.5006894, 20.9310024],
                [105.501122, 20.9309827],
                [105.502539, 20.9311091],
                [105.5043421, 20.929915],
                [105.5057112, 20.9263567],
                [105.5062828, 20.925856],
                [105.5078512, 20.9249731],
                [105.5095675, 20.9238902],
                [105.5112421, 20.9230169],
                [105.5125563, 20.9223143],
                [105.5129168, 20.9221536],
                [105.513808, 20.9219713],
                [105.5151666, 20.9218475],
                [105.5153515, 20.9218412],
                [105.5186439, 20.9244404],
                [105.5207702, 20.9261488],
                [105.5236327, 20.9288883],
                [105.5285494, 20.9271232],
                [105.5302241, 20.9295354],
                [105.530998, 20.9306988],
                [105.5330279, 20.9310153],
                [105.5365202, 20.9305411],
                [105.5366464, 20.9303532],
                [105.5373232, 20.9303717],
                [105.5383637, 20.9303684],
                [105.5402529, 20.9301329],
                [105.541441, 20.9298298],
                [105.5425016, 20.929527],
                [105.5445156, 20.9285825],
                [105.5453004, 20.9283205],
                [105.5465534, 20.9283664],
                [105.5480621, 20.9286211],
                [105.5494224, 20.928976],
                [105.551421, 20.9296681],
                [105.5529299, 20.9299626],
                [105.5553191, 20.9288485],
                [105.5544492, 20.9306232],
                [105.555957, 20.9307792],
                [105.5573168, 20.9309142],
                [105.5590486, 20.9307355],
                [105.5607959, 20.9299542],
                [105.5610623, 20.9298351],
                [105.5627203, 20.9290706],
                [105.5652311, 20.9278568],
                [105.5674444, 20.9271705],
                [105.5702501, 20.927117],
                [105.5712611, 20.9270978],
                [105.5713523, 20.9270963],
                [105.5715666, 20.9270928],
                [105.5720769, 20.9272508],
                [105.5730134, 20.9278514],
                [105.5740553, 20.9290753],
                [105.5746272, 20.929483],
                [105.5758687, 20.9291941],
                [105.5771855, 20.9292095],
                [105.5790949, 20.9287639],
                [105.5801451, 20.9282186],
                [105.5814769, 20.9282042],
                [105.5814046, 20.9253851],
                [105.5815193, 20.924165],
                [105.580929, 20.9233008],
                [105.5803436, 20.9225265],
                [105.5792083, 20.922266],
                [105.5788698, 20.9206657],
                [105.5788725, 20.920617],
                [105.5788908, 20.9202951],
                [105.5789889, 20.9198573],
                [105.579071, 20.9195758],
                [105.5792692, 20.9192471],
                [105.5794512, 20.9189965],
                [105.5795176, 20.9189962],
                [105.5796674, 20.9190113],
                [105.5797841, 20.919089],
                [105.579868, 20.9193074],
                [105.5800014, 20.919354],
                [105.5800677, 20.9193224],
                [105.5801001, 20.9191192],
                [105.5801657, 20.918869],
                [105.5802814, 20.9186968],
                [105.5803469, 20.9184309],
                [105.5804957, 20.918196],
                [105.5806448, 20.9180393],
                [105.5807776, 20.9180076],
                [105.5808939, 20.9179603],
                [105.5809598, 20.9178194],
                [105.5809094, 20.9176635],
                [105.5806926, 20.9175235],
                [105.5803261, 20.9173529],
                [105.5799432, 20.9172292],
                [105.5798927, 20.9170575],
                [105.5800081, 20.9168228],
                [105.5802404, 20.9166657],
                [105.5803729, 20.9165404],
                [105.5802893, 20.9164156],
                [105.5800731, 20.9164007],
                [105.5798238, 20.9164485],
                [105.5795578, 20.9164493],
                [105.5792748, 20.9163566],
                [105.5791245, 20.9161852],
                [105.5790466, 20.9160125],
                [105.577289, 20.9151819],
                [105.5766865, 20.9147005],
                [105.5763938, 20.9144625],
                [105.5761369, 20.9142712],
                [105.574885, 20.9134702],
                [105.5742683, 20.9134627],
                [105.5726314, 20.9137399],
                [105.5724182, 20.9136928],
                [105.5720406, 20.9135931],
                [105.5713123, 20.9130999],
                [105.5676197, 20.9113256],
                [105.5672839, 20.9111539],
                [105.5629105, 20.9093269],
                [105.5619683, 20.9093894],
                [105.5618223, 20.9093089],
                [105.5617242, 20.9091671],
                [105.5613443, 20.9092266],
                [105.5610907, 20.9093498],
                [105.5606587, 20.9097522],
                [105.5604344, 20.9098805],
                [105.5601647, 20.9098813],
                [105.5599121, 20.9097901],
                [105.559681, 20.9096397],
                [105.5585271, 20.9087876],
                [105.5579179, 20.9081797],
                [105.5586916, 20.9075671],
                [105.5588856, 20.9071571],
                [105.560289, 20.905846],
                [105.5600032, 20.9055552],
                [105.5584671, 20.9067519],
                [105.5580484, 20.9069133],
                [105.5575742, 20.9070302],
                [105.5571437, 20.9069199],
                [105.5568023, 20.9066946],
                [105.5564558, 20.9063205],
                [105.5558198, 20.905326],
                [105.5557264, 20.905025],
                [105.5554955, 20.9047452],
                [105.5561847, 20.9040305],
                [105.5578035, 20.9030829],
                [105.5584603, 20.9026042],
                [105.5592037, 20.9023109],
                [105.5596043, 20.9019367],
                [105.5596655, 20.9017186],
                [105.5594713, 20.9016279],
                [105.5593013, 20.9014861],
                [105.5593013, 20.9012933],
                [105.5596594, 20.9010949],
                [105.5600782, 20.9010892],
                [105.5611855, 20.9007876],
                [105.5616907, 20.9005179],
                [105.5624991, 20.8994189],
                [105.5630837, 20.8994391],
                [105.5633796, 20.8993987],
                [105.563625, 20.8992773],
                [105.5638082, 20.8990084],
                [105.563792, 20.898672],
                [105.5633346, 20.8986214],
                [105.5626295, 20.8985522],
                [105.5622308, 20.8986533],
                [105.5618313, 20.8985379],
                [105.561442, 20.8983009],
                [105.5612452, 20.8980797],
                [105.5609758, 20.8973888],
                [105.5604636, 20.8962482],
                [105.5600015, 20.8938443],
                [105.559909, 20.8923265],
                [105.5599021, 20.8913877],
                [105.5598422, 20.8907502],
                [105.5596129, 20.8904764],
                [105.5591134, 20.8903084],
                [105.5585066, 20.8900936],
                [105.557872, 20.8897578],
                [105.5573265, 20.8894526],
                [105.5570199, 20.8891722],
                [105.5568108, 20.8888649],
                [105.5566155, 20.8883233],
                [105.5563422, 20.887853],
                [105.5559331, 20.8876718],
                [105.5555983, 20.8874863],
                [105.5555598, 20.8873814],
                [105.5555686, 20.8872745],
                [105.5556505, 20.8870025],
                [105.555631, 20.886863],
                [105.5555575, 20.8867038],
                [105.5553778, 20.8865193],
                [105.5549762, 20.8863467],
                [105.5550522, 20.8861555],
                [105.5551196, 20.8860486],
                [105.5557308, 20.8852845],
                [105.5558252, 20.8851975],
                [105.5559387, 20.8851176],
                [105.5560389, 20.8850858],
                [105.5561366, 20.884524],
                [105.5561685, 20.883689],
                [105.5528535, 20.8832445],
                [105.5528346, 20.8828745],
                [105.5530417, 20.8828908],
                [105.5532842, 20.8828907],
                [105.5534733, 20.8828906],
                [105.5535344, 20.8828445],
                [105.5535947, 20.8827989],
                [105.5537044, 20.8826951],
                [105.5537191, 20.8826786],
                [105.5538357, 20.8825487],
                [105.5539046, 20.8822558],
                [105.5539218, 20.8820117],
                [105.5539216, 20.8814259],
                [105.5538525, 20.881133],
                [105.5538178, 20.8808727],
                [105.5538177, 20.8806106],
                [105.5538176, 20.880352],
                [105.5538521, 20.880173],
                [105.5539729, 20.8800428],
                [105.5541627, 20.8798962],
                [105.5544907, 20.8797658],
                [105.5550776, 20.8796844],
                [105.555167, 20.8796844],
                [105.5563204, 20.8796839],
                [105.5567477, 20.8796771],
                [105.5571664, 20.8799115],
                [105.5575118, 20.8800741],
                [105.5577365, 20.8801917],
                [105.5585218, 20.8806026],
                [105.5590015, 20.8808426],
                [105.5590743, 20.880879],
                [105.5592839, 20.8809637],
                [105.5592637, 20.8807144],
                [105.5593176, 20.880562],
                [105.5594894, 20.8802419],
                [105.5595647, 20.8801705],
                [105.5597534, 20.8800454],
                [105.5598479, 20.8800095],
                [105.5600368, 20.8799378],
                [105.5601309, 20.8797953],
                [105.5600926, 20.8796709],
                [105.5598652, 20.8795828],
                [105.5596004, 20.8796014],
                [105.5594301, 20.8795665],
                [105.559297, 20.879389],
                [105.5592963, 20.8792113],
                [105.5593335, 20.8790333],
                [105.5593896, 20.8788554],
                [105.559597, 20.8786769],
                [105.5598615, 20.8785872],
                [105.5599935, 20.8784445],
                [105.5599929, 20.8782845],
                [105.5598789, 20.878196],
                [105.5596517, 20.8781255],
                [105.5595942, 20.8779302],
                [105.5595933, 20.8776812],
                [105.5597253, 20.8775563],
                [105.5599897, 20.8774489],
                [105.5603489, 20.8773943],
                [105.5606322, 20.8772868],
                [105.5609719, 20.8770544],
                [105.561235, 20.8765736],
                [105.5613862, 20.8765376],
                [105.5615562, 20.8764836],
                [105.561783, 20.8764117],
                [105.561877, 20.8762692],
                [105.5618197, 20.8760917],
                [105.5616492, 20.8760211],
                [105.5616109, 20.8759145],
                [105.5616103, 20.8757545],
                [105.5616646, 20.8755648],
                [105.562657, 20.8759135],
                [105.5632884, 20.876116],
                [105.5636909, 20.8761862],
                [105.563843, 20.876155],
                [105.5640056, 20.8760215],
                [105.5642439, 20.8757753],
                [105.5643735, 20.8755295],
                [105.5644162, 20.8753453],
                [105.5644156, 20.8751612],
                [105.5644805, 20.8750792],
                [105.564589, 20.8750175],
                [105.564752, 20.8749965],
                [105.5650021, 20.8749956],
                [105.565285, 20.875056],
                [105.5657528, 20.8751466],
                [105.5658724, 20.8751462],
                [105.5659375, 20.875105],
                [105.5660348, 20.8749513],
                [105.5661204, 20.8745932],
                [105.5662656, 20.8739963],
                [105.5663737, 20.8738324],
                [105.5665686, 20.8736272],
                [105.5667529, 20.8734834],
                [105.5669808, 20.8733804],
                [105.5671004, 20.8733596],
                [105.5672413, 20.8732568],
                [105.5673604, 20.8730929],
                [105.56749, 20.8728981],
                [105.5675982, 20.8727444],
                [105.5677281, 20.8726212],
                [105.567956, 20.8724876],
                [105.5680755, 20.8724565],
                [105.5682604, 20.8724558],
                [105.5683691, 20.8724555],
                [105.5684884, 20.8723938],
                [105.5685533, 20.8723219],
                [105.5685964, 20.8722093],
                [105.5685961, 20.8721275],
                [105.5685303, 20.8719641],
                [105.5684646, 20.8718518],
                [105.5684964, 20.8716166],
                [105.568561, 20.8714528],
                [105.5686442, 20.8711592],
                [105.5688605, 20.8708824],
                [105.5691203, 20.8705748],
                [105.5696803, 20.8699831],
                [105.569905, 20.8698353],
                [105.5715188, 20.868834],
                [105.5720319, 20.868694],
                [105.5727056, 20.8688442],
                [105.5732034, 20.8689042],
                [105.5734278, 20.8687837],
                [105.5736569, 20.868364],
                [105.573765, 20.8681187],
                [105.5742292, 20.867519],
                [105.5752876, 20.8672777],
                [105.5754158, 20.8671271],
                [105.5754795, 20.8662843],
                [105.5755111, 20.8650501],
                [105.5754464, 20.8642976],
                [105.5771977, 20.863276],
                [105.5787441, 20.8620601],
                [105.5793768, 20.861511],
                [105.5784152, 20.859991],
                [105.5795665, 20.8586897],
                [105.5801104, 20.8580766],
                [105.5811715, 20.8580505],
                [105.5829409, 20.8595051],
                [105.5844641, 20.8586362],
                [105.5849265, 20.8583296],
                [105.5843923, 20.8575616],
                [105.5840713, 20.8567685],
                [105.5838891, 20.8561218],
                [105.5838878, 20.8557561],
                [105.5839158, 20.8552494],
                [105.5841532, 20.8547139],
                [105.5847446, 20.8526576],
                [105.5847946, 20.850652],
                [105.5847846, 20.8497557],
                [105.5847518, 20.8484014],
                [105.5850403, 20.8481906],
                [105.5853609, 20.8481302],
                [105.5857136, 20.8480399],
                [105.5862265, 20.8478289],
                [105.5863868, 20.8476783],
                [105.5864508, 20.8475278],
                [105.5864186, 20.8473473],
                [105.5858977, 20.8469172],
                [105.5858213, 20.8468458],
                [105.5858971, 20.846738],
                [105.5859773, 20.8466945],
                [105.5861632, 20.8465938],
                [105.5862961, 20.8465037],
                [105.5862768, 20.846432],
                [105.586124, 20.8463251],
                [105.5859717, 20.8463256],
                [105.5857621, 20.8463264],
                [105.5856288, 20.8463268],
                [105.5855331, 20.8462376],
                [105.5855326, 20.8461121],
                [105.5856463, 20.8459326],
                [105.5856833, 20.8456637],
                [105.5856637, 20.8455383],
                [105.5855298, 20.8453596],
                [105.585492, 20.8451769],
                [105.5855285, 20.845055],
                [105.5856425, 20.844965],
                [105.5857757, 20.8449107],
                [105.5860042, 20.844892],
                [105.5861755, 20.8448556],
                [105.5862706, 20.8448194],
                [105.5863083, 20.8447118],
                [105.5862881, 20.8444431],
                [105.5863639, 20.8443174],
                [105.5864399, 20.8442814],
                [105.5866114, 20.8442807],
                [105.5869166, 20.8443872],
                [105.5871464, 20.844709],
                [105.5872424, 20.8449057],
                [105.5873584, 20.8453354],
                [105.5874738, 20.8456217],
                [105.5877791, 20.845764],
                [105.5879505, 20.8457633],
                [105.5880836, 20.8457091],
                [105.5881787, 20.8456372],
                [105.5881588, 20.8454222],
                [105.5881581, 20.845261],
                [105.5881574, 20.8450639],
                [105.5881377, 20.8448847],
                [105.5880418, 20.8447418],
                [105.5878317, 20.8445811],
                [105.5876792, 20.8445638],
                [105.5874697, 20.8445645],
                [105.5873364, 20.8445649],
                [105.5872218, 20.8444936],
                [105.587107, 20.8443506],
                [105.5871062, 20.8441715],
                [105.5873535, 20.844081],
                [105.5875247, 20.8440268],
                [105.5876388, 20.8439726],
                [105.5876577, 20.8439009],
                [105.5876756, 20.8436498],
                [105.5875608, 20.8434891],
                [105.5875032, 20.8433639],
                [105.5874073, 20.8432209],
                [105.5872927, 20.8431316],
                [105.587159, 20.8430425],
                [105.5869874, 20.8430072],
                [105.5863972, 20.8430988],
                [105.586188, 20.8431712],
                [105.5859213, 20.8431721],
                [105.5856544, 20.8431014],
                [105.5854443, 20.8429766],
                [105.5852803, 20.842784],
                [105.5851966, 20.8423588],
                [105.5852026, 20.8419219],
                [105.5853466, 20.8418332],
                [105.5857124, 20.8415988],
                [105.5860783, 20.8414151],
                [105.5862503, 20.8412725],
                [105.5863146, 20.8411607],
                [105.5863464, 20.8410187],
                [105.5863025, 20.8408262],
                [105.5861833, 20.8406644],
                [105.5860104, 20.8405838],
                [105.5858049, 20.8404527],
                [105.5856643, 20.8403417],
                [105.5855668, 20.8402406],
                [105.5855017, 20.8401294],
                [105.5854471, 20.8399673],
                [105.5853817, 20.8397851],
                [105.585295, 20.8396637],
                [105.5851759, 20.8395424],
                [105.5848086, 20.8393814],
                [105.584398, 20.8391902],
                [105.5840537, 20.8390474],
                [105.5837511, 20.8388963],
                [105.5835997, 20.8387954],
                [105.5835129, 20.8386434],
                [105.5835118, 20.8383695],
                [105.5835216, 20.8381158],
                [105.5835427, 20.8379839],
                [105.5835207, 20.8378623],
                [105.5834556, 20.8377915],
                [105.583326, 20.8377106],
                [105.5830127, 20.8376001],
                [105.582689, 20.8375505],
                [105.5823005, 20.8375112],
                [105.5820847, 20.8374613],
                [105.581901, 20.8373502],
                [105.5816413, 20.8371381],
                [105.5814679, 20.8369358],
                [105.5813699, 20.8366825],
                [105.5812398, 20.8364799],
                [105.5810558, 20.8363284],
                [105.5808506, 20.8362784],
                [105.5806457, 20.8362689],
                [105.5804839, 20.8362492],
                [105.580354, 20.8361482],
                [105.5802887, 20.8359759],
                [105.5802878, 20.8357324],
                [105.5804804, 20.8353462],
                [105.5805977, 20.8349907],
                [105.5805997, 20.834855],
                [105.5805447, 20.8345813],
                [105.5804565, 20.8340946],
                [105.5803796, 20.8337296],
                [105.5804101, 20.8331731],
                [105.5805201, 20.8310509],
                [105.580481, 20.8309149],
                [105.5804609, 20.8307244],
                [105.5804411, 20.8305702],
                [105.5803632, 20.8303982],
                [105.5802565, 20.8302442],
                [105.5798688, 20.8297828],
                [105.5795998, 20.8294649],
                [105.5795398, 20.828099],
                [105.5795319, 20.8279207],
                [105.5795902, 20.8275284],
                [105.5796182, 20.8273579],
                [105.579627, 20.8272053],
                [105.5796266, 20.8270707],
                [105.5796068, 20.8268913],
                [105.5795585, 20.826712],
                [105.5794818, 20.8265957],
                [105.5789652, 20.826122],
                [105.5784306, 20.8255701],
                [105.5780475, 20.8251228],
                [105.5779134, 20.8249529],
                [105.5777986, 20.8248367],
                [105.5776934, 20.8247562],
                [105.5774355, 20.8245868],
                [105.5771677, 20.8243903],
                [105.5770339, 20.8243011],
                [105.5768718, 20.8242568],
                [105.576805, 20.8242301],
                [105.5766518, 20.8240962],
                [105.5763455, 20.8237293],
                [105.5760195, 20.8232371],
                [105.5757536, 20.822883],
                [105.5756388, 20.8227667],
                [105.5755337, 20.8226953],
                [105.575018, 20.8224729],
                [105.5746458, 20.8223396],
                [105.5742166, 20.8222873],
                [105.5736157, 20.8221729],
                [105.5733103, 20.8220932],
                [105.5730528, 20.8220672],
                [105.5729316, 20.8220257],
                [105.5728648, 20.8220259],
                [105.5726356, 20.8219371],
                [105.5718978, 20.8217013],
                [105.5705179, 20.8219379],
                [105.5685557, 20.8220622],
                [105.5672609, 20.8219879],
                [105.5665537, 20.821873],
                [105.5653308, 20.821942],
                [105.5638585, 20.8222254],
                [105.5630127, 20.822212],
                [105.5622482, 20.8218964],
                [105.561679, 20.8212926],
                [105.5609696, 20.8201039],
                [105.560521, 20.8191907],
                [105.5601405, 20.8177109],
                [105.5601398, 20.8160954],
                [105.5600121, 20.8151981],
                [105.5597568, 20.813822],
                [105.5593104, 20.8125059],
                [105.558464, 20.8113967],
                [105.555495, 20.8089949],
                [105.5536591, 20.8083598],
                [105.5510221, 20.807929],
                [105.5520462, 20.8069012],
                [105.5530561, 20.8064595],
                [105.5559542, 20.806451],
                [105.5594282, 20.8080935],
                [105.5612385, 20.8083947],
                [105.5632719, 20.8085193],
                [105.5664499, 20.8083638],
                [105.5691754, 20.8083618],
                [105.5703631, 20.8083942],
                [105.5708169, 20.8084077],
                [105.5715136, 20.8084946],
                [105.57362, 20.8082394],
                [105.5749692, 20.8079827],
                [105.5754867, 20.8079185],
                [105.5759694, 20.8080469],
                [105.5770831, 20.8086365],
                [105.5784666, 20.8090077],
                [105.5798602, 20.8102984],
                [105.5809106, 20.8110788],
                [105.582064, 20.8115991],
                [105.5833585, 20.8118179],
                [105.5832839, 20.8132673],
                [105.5833854, 20.8143713],
                [105.5835561, 20.8158012],
                [105.5839458, 20.8171709],
                [105.5846271, 20.8183579],
                [105.5854056, 20.8192708],
                [105.586767, 20.8196354],
                [105.5878369, 20.8202742],
                [105.5880586, 20.8209432],
                [105.5877603, 20.8225111],
                [105.5871297, 20.8255624],
                [105.5872781, 20.8275024],
                [105.5878828, 20.8290827],
                [105.5881, 20.8292855],
                [105.5885173, 20.829861],
                [105.5903075, 20.8308354],
                [105.5912812, 20.8307443],
                [105.5922935, 20.8310176],
                [105.5932379, 20.830777],
                [105.5936351, 20.8307873],
                [105.5948075, 20.8306687],
                [105.5956313, 20.8309885],
                [105.5960475, 20.8312754],
                [105.5965184, 20.8317488],
                [105.597679, 20.8332202],
                [105.5980001, 20.8338451],
                [105.5977941, 20.8342153],
                [105.5973127, 20.8346677],
                [105.596735, 20.8352408],
                [105.596671, 20.8355422],
                [105.5967034, 20.8359641],
                [105.5967679, 20.8364162],
                [105.5966077, 20.8369288],
                [105.5966287, 20.8374298],
                [105.5969457, 20.8384477],
                [105.5969697, 20.8386404],
                [105.600946, 20.8414176],
                [105.6032884, 20.8385827],
                [105.6038668, 20.8389743],
                [105.6042521, 20.838974],
                [105.6047336, 20.8387325],
                [105.6053116, 20.838672],
                [105.6061463, 20.8382796],
                [105.6068528, 20.8381887],
                [105.6074951, 20.8382486],
                [105.6086193, 20.8385191],
                [105.608838, 20.8384677],
                [105.6091331, 20.8383982],
                [105.6095825, 20.8381568],
                [105.6101282, 20.837825],
                [105.6107114, 20.8371311],
                [105.6110907, 20.8366186],
                [105.6114116, 20.8363472],
                [105.6119895, 20.8360755],
                [105.6127765, 20.8358331],
                [105.6136481, 20.835261],
                [105.6140251, 20.8350135],
                [105.6147205, 20.8343318],
                [105.6154484, 20.8331775],
                [105.6157346, 20.8324138],
                [105.6159049, 20.832063],
                [105.6160759, 20.8318602],
                [105.6164184, 20.8315895],
                [105.6166469, 20.8314673],
                [105.6168903, 20.8314664],
                [105.6172196, 20.8314921],
                [105.6174628, 20.8315097],
                [105.618505, 20.8315497],
                [105.619629, 20.8315792],
                [105.6200784, 20.8315184],
                [105.6202824, 20.8314129],
                [105.6207219, 20.8312806],
                [105.620894, 20.8313474],
                [105.6211093, 20.8314813],
                [105.6214723, 20.8317098],
                [105.6218362, 20.83186],
                [105.6225186, 20.8318319],
                [105.6236429, 20.8317456],
                [105.6238661, 20.8317376],
                [105.6240807, 20.8317817],
                [105.6242674, 20.831886],
                [105.6245978, 20.832255],
                [105.6248145, 20.8325438],
                [105.6249009, 20.8325999],
                [105.6249974, 20.8326184],
                [105.6256422, 20.8327148],
                [105.6257527, 20.8327459],
                [105.6258557, 20.832783],
                [105.6259716, 20.8328361],
                [105.6284033, 20.8342912],
                [105.6295342, 20.8346823],
                [105.630607, 20.834961],
                [105.6320147, 20.8352749],
                [105.632156, 20.835341],
                [105.6329896, 20.836147],
                [105.6336193, 20.8367238],
                [105.6337114, 20.8368435],
                [105.6337835, 20.8369666],
                [105.6338253, 20.8370855],
                [105.6338514, 20.8372052],
                [105.6338621, 20.8375803],
                [105.6337691, 20.8385583],
                [105.6351185, 20.8386975],
                [105.6352504, 20.838877],
                [105.6353163, 20.8391226],
                [105.635994, 20.8392079],
                [105.6367098, 20.8393545],
                [105.6366449, 20.8388756],
                [105.6364867, 20.8383438],
                [105.6363412, 20.8380266],
                [105.6360044, 20.837688],
                [105.6357518, 20.837471],
                [105.6356752, 20.8373207],
                [105.6356803, 20.8371251],
                [105.6356803, 20.8369224],
                [105.6356216, 20.8367579],
                [105.6352746, 20.8362976],
                [105.635221, 20.8361473],
                [105.635221, 20.8359828],
                [105.6352746, 20.835823],
                [105.635369, 20.8357157],
                [105.6355323, 20.8356513],
                [105.6357849, 20.8356537],
                [105.6359559, 20.8356155],
                [105.6360733, 20.8355273],
                [105.6361447, 20.8353937],
                [105.6361575, 20.8352125],
                [105.6360963, 20.8349907],
                [105.6360605, 20.834776],
                [105.6360274, 20.8343277],
                [105.6359763, 20.8341345],
                [105.6358385, 20.8338984],
                [105.6356267, 20.8337148],
                [105.6354762, 20.833555],
                [105.6353945, 20.8333785],
                [105.635369, 20.833202],
                [105.6353996, 20.833004],
                [105.6355323, 20.8328299],
                [105.6359406, 20.8325438],
                [105.6360605, 20.8324388],
                [105.6363318, 20.8320591],
                [105.6367188, 20.8321218],
                [105.6416854, 20.8330324],
                [105.6424009, 20.8310516],
                [105.6406408, 20.8305543],
                [105.6407304, 20.8301443],
                [105.6410391, 20.8294873],
                [105.641385, 20.8290075],
                [105.6416156, 20.8286896],
                [105.6418261, 20.8283511],
                [105.6419608, 20.8281447],
                [105.6420537, 20.8278257],
                [105.641944, 20.8272714],
                [105.6418149, 20.8264737],
                [105.6417665, 20.8261276],
                [105.6415737, 20.8258416],
                [105.6409797, 20.825255],
                [105.641085, 20.8250856],
                [105.6411168, 20.8247151],
                [105.6412667, 20.8243896],
                [105.6411842, 20.8241009],
                [105.6407618, 20.8239604],
                [105.6399187, 20.823993],
                [105.639473, 20.8245274],
                [105.638955, 20.8246645],
                [105.6387254, 20.8249001],
                [105.6383606, 20.8249896],
                [105.6376926, 20.8246026],
                [105.6371558, 20.8239876],
                [105.6368336, 20.8236186],
                [105.6361986, 20.8230226],
                [105.6330418, 20.8193358],
                [105.6310203, 20.8166365],
                [105.6309104, 20.8161877],
                [105.6313715, 20.8149267],
                [105.632337, 20.8137432],
                [105.631992, 20.8127438],
                [105.6319525, 20.8116895],
                [105.6312012, 20.8114596],
                [105.6311361, 20.8111957],
                [105.6311084, 20.810908],
                [105.6314225, 20.8104848],
                [105.6319915, 20.8100397],
                [105.6322832, 20.8091964],
                [105.6321519, 20.8080814],
                [105.6321766, 20.8077685],
                [105.6323116, 20.8076305],
                [105.6324476, 20.8074745],
                [105.6325726, 20.8072635],
                [105.6326976, 20.8069705],
                [105.6327166, 20.8067875],
                [105.6327356, 20.8066405],
                [105.6326766, 20.8064575],
                [105.6326176, 20.8063295],
                [105.6325096, 20.8061655],
                [105.6323636, 20.8060385],
                [105.6322076, 20.8059015],
                [105.6321486, 20.8057915],
                [105.6321186, 20.8056735],
                [105.6320996, 20.8055635],
                [105.6320986, 20.8054715],
                [105.6321466, 20.8053155],
                [105.6322046, 20.8052065],
                [105.6323596, 20.8051135],
                [105.6325156, 20.8050675],
                [105.6327476, 20.8049935],
                [105.6328446, 20.8049015],
                [105.6328736, 20.8047895],
                [105.6328816, 20.8045605],
                [105.6328626, 20.8044785],
                [105.6327546, 20.8043785],
                [105.6324236, 20.8041515],
                [105.6322186, 20.8040325],
                [105.6321216, 20.8039785],
                [105.6321016, 20.8038325],
                [105.6320986, 20.8032015],
                [105.6321556, 20.8029265],
                [105.6322616, 20.8027805],
                [105.6325436, 20.8025965],
                [105.6329996, 20.8023755],
                [105.6336106, 20.8021345],
                [105.6339796, 20.8018575],
                [105.6340016, 20.8016685],
                [105.6340066, 20.8012425],
                [105.6339346, 20.8009135],
                [105.6339336, 20.8005365],
                [105.6338896, 20.8003475],
                [105.6337776, 20.8002225],
                [105.6333526, 20.7999705],
                [105.6328396, 20.7999715],
                [105.6325936, 20.7998665],
                [105.6324596, 20.7996575],
                [105.6326156, 20.7989655],
                [105.6326816, 20.7985245],
                [105.6325026, 20.7982735],
                [105.6316096, 20.7980855],
                [105.6310066, 20.7976245],
                [105.6307156, 20.7973105],
                [105.6307826, 20.7970795],
                [105.6318986, 20.7964495],
                [105.6324336, 20.7961765],
                [105.6326126, 20.7959885],
                [105.6326356, 20.7957785],
                [105.6312946, 20.7946055],
                [105.6303996, 20.7937675],
                [105.6304056, 20.7934905],
                [105.6306236, 20.7930965],
                [105.6313966, 20.7928775],
                [105.6333476, 20.7925915],
                [105.6337496, 20.7927375],
                [105.6358266, 20.7937845],
                [105.6360726, 20.7937425],
                [105.6362286, 20.7933225],
                [105.6366526, 20.7928825],
                [105.6372326, 20.7926725],
                [105.6385726, 20.7926495],
                [105.6480136, 20.7922845],
                [105.6482823, 20.7897879],
                [105.6485031, 20.787736],
                [105.6484258, 20.7865118],
                [105.6481683, 20.7864692],
                [105.6482756, 20.7860479],
                [105.6484553, 20.7847239],
                [105.6485626, 20.7846963],
                [105.6485224, 20.7843628],
                [105.6483883, 20.7837459],
                [105.6484151, 20.7835804],
                [105.648694, 20.7828682],
                [105.6484794, 20.7820658],
                [105.6480181, 20.7809423],
                [105.6479108, 20.7804006],
                [105.6479108, 20.7799894],
                [105.6477928, 20.7794678],
                [105.6476914, 20.7790194],
                [105.6477284, 20.7781838],
                [105.6477582, 20.7775124],
                [105.6478143, 20.7768296],
                [105.6481254, 20.776328],
                [105.6481898, 20.776007],
                [105.6482863, 20.7755556],
                [105.6485331, 20.7750139],
                [105.648812, 20.7746127],
                [105.6500673, 20.7737299],
                [105.6498956, 20.7734089],
                [105.6498313, 20.7730879],
                [105.6499171, 20.7726666],
                [105.6498742, 20.7716635],
                [105.6495309, 20.7710616],
                [105.6492734, 20.7706603],
                [105.6491017, 20.7701186],
                [105.6483507, 20.7679719],
                [105.6483936, 20.7668684],
                [105.6483936, 20.7665072],
                [105.6485438, 20.7661461],
                [105.6488013, 20.7657448],
                [105.6489301, 20.7652633],
                [105.6490373, 20.7649422],
                [105.6493163, 20.764561],
                [105.6495738, 20.7641798],
                [105.6496167, 20.7634575],
                [105.6497025, 20.7629158],
                [105.6498313, 20.7625546],
                [105.6500673, 20.7622737],
                [105.6501746, 20.7616718],
                [105.6499386, 20.7611301],
                [105.6498527, 20.7605883],
                [105.6498098, 20.7601068],
                [105.6502819, 20.7595851],
                [105.6504321, 20.7588628],
                [105.6506681, 20.7585016],
                [105.6508612, 20.7582207],
                [105.6510544, 20.7578796],
                [105.6511402, 20.7574382],
                [105.6511187, 20.7570168],
                [105.6510114, 20.756455],
                [105.6510114, 20.7558731],
                [105.65099, 20.755231],
                [105.6510329, 20.754609],
                [105.6511402, 20.7542077],
                [105.651226, 20.7537061],
                [105.6512689, 20.753385],
                [105.6509471, 20.7531041],
                [105.6509042, 20.7527229],
                [105.6509685, 20.7523416],
                [105.6514621, 20.7517999],
                [105.6519985, 20.7514587],
                [105.6530928, 20.7509772],
                [105.6533074, 20.7507966],
                [105.653758, 20.7504354],
                [105.6540155, 20.7500943],
                [105.6541443, 20.7494722],
                [105.6542516, 20.7487499],
                [105.6543159, 20.7483285],
                [105.6544447, 20.7477666],
                [105.6545842, 20.7476261],
                [105.6546378, 20.7474456],
                [105.6547236, 20.7471847],
                [105.6549275, 20.7467834],
                [105.6552923, 20.7462416],
                [105.6554854, 20.7458804],
                [105.655539, 20.7456898],
                [105.6557429, 20.7452483],
                [105.6563008, 20.744546],
                [105.6565797, 20.7442851],
                [105.6568158, 20.7441446],
                [105.6570518, 20.7438737],
                [105.6575775, 20.7430309],
                [105.6575775, 20.7427701],
                [105.6575668, 20.7424891],
                [105.6575775, 20.7421279],
                [105.6576633, 20.7417065],
                [105.6577384, 20.7415259],
                [105.6578565, 20.7410644],
                [105.6579637, 20.7409941],
                [105.6582427, 20.7409741],
                [105.6586826, 20.7409038],
                [105.6590152, 20.7408436],
                [105.6593585, 20.7406229],
                [105.6604206, 20.7400911],
                [105.6606674, 20.7398804],
                [105.6610322, 20.7392784],
                [105.6611931, 20.7389975],
                [105.6615257, 20.7385158],
                [105.6618261, 20.738275],
                [105.6622445, 20.7380443],
                [105.6626522, 20.7377734],
                [105.6630063, 20.7374422],
                [105.6633282, 20.7369907],
                [105.6635535, 20.7366496],
                [105.6644547, 20.7359572],
                [105.6646371, 20.7359071],
                [105.6648731, 20.7357465],
                [105.6650877, 20.7356261],
                [105.6653345, 20.7354455],
                [105.6660533, 20.7352649],
                [105.6670618, 20.7350442],
                [105.6665468, 20.734101],
                [105.6661391, 20.7335792],
                [105.6659245, 20.7331979],
                [105.66571, 20.7323751],
                [105.6656885, 20.732034],
                [105.6648087, 20.7301676],
                [105.6643581, 20.7290237],
                [105.6640577, 20.7285421],
                [105.6633711, 20.7278999],
                [105.6621909, 20.7262542],
                [105.6618047, 20.7259732],
                [105.6617832, 20.7255518],
                [105.6616115, 20.7249296],
                [105.6618476, 20.7238258],
                [105.6619012, 20.7229328],
                [105.6618722, 20.7226182],
                [105.6621883, 20.7218072],
                [105.6629164, 20.7203314],
                [105.6640239, 20.7172345],
                [105.6650106, 20.716938],
                [105.665854, 20.7160077],
                [105.6666561, 20.715201],
                [105.6674593, 20.714662],
                [105.6678605, 20.714281],
                [105.6684605, 20.7138222],
                [105.6691314, 20.7132905],
                [105.669601, 20.7127571],
                [105.6701765, 20.7119015],
                [105.6704212, 20.7105712],
                [105.6705857, 20.7090903],
                [105.671033, 20.7082741],
                [105.671521, 20.7074901],
                [105.6732477, 20.7058838],
                [105.6738625, 20.7051894],
                [105.6744315, 20.7047261],
                [105.6755079, 20.7040599],
                [105.6762768, 20.7034229],
                [105.6773064, 20.7021786],
                [105.6778598, 20.7016574],
                [105.678526, 20.7011121],
                [105.6792339, 20.7004843],
                [105.6803365, 20.6995472],
                [105.6808954, 20.6990415],
                [105.6816111, 20.6984728],
                [105.6813645, 20.6981839],
                [105.6811791, 20.6975479],
                [105.68124, 20.6969695],
                [105.6816086, 20.6963908],
                [105.6825309, 20.6954645],
                [105.6825305, 20.6951754],
                [105.6819542, 20.6942474],
                [105.6815052, 20.6935632],
                [105.6811068, 20.6925385],
                [105.6810417, 20.6919337],
                [105.6808803, 20.6914501],
                [105.6807514, 20.6911478],
                [105.6804939, 20.6910573],
                [105.6799473, 20.6911486],
                [105.67969, 20.6911489],
                [105.6794648, 20.6910281],
                [105.6794324, 20.690877],
                [105.6794318, 20.6903326],
                [105.6795917, 20.6895763],
                [105.6797835, 20.6885783],
                [105.6797677, 20.6881806],
                [105.6797672, 20.6877469],
                [105.679697, 20.6873432],
                [105.6792185, 20.6847171],
                [105.6791303, 20.6840626],
                [105.6789048, 20.6837603],
                [105.6786474, 20.6836699],
                [105.6781328, 20.68361],
                [105.6774253, 20.6836108],
                [105.6769106, 20.6835205],
                [105.6759448, 20.6826143],
                [105.6750758, 20.68198],
                [105.6735671, 20.6812003],
                [105.6732608, 20.681015],
                [105.6729732, 20.6809174],
                [105.6725266, 20.6806698],
                [105.6723726, 20.6805543],
                [105.6719382, 20.6802746],
                [105.6711344, 20.6804266],
                [105.6695266, 20.6806096],
                [105.6689477, 20.6806405],
                [105.6687546, 20.6804592],
                [105.6687101, 20.6802398],
                [105.6691086, 20.6788514],
                [105.6696305, 20.6777521],
                [105.6698147, 20.6773182],
                [105.6699682, 20.6769421],
                [105.669556, 20.6761991],
                [105.6703019, 20.6745577],
                [105.6711341, 20.673818],
                [105.6716907, 20.6726494],
                [105.671981, 20.6714911],
                [105.6724919, 20.6712354],
                [105.6735527, 20.6703217],
                [105.6721945, 20.6678755],
                [105.6737438, 20.664937],
                [105.6745644, 20.6649636],
                [105.6752717, 20.6636473],
                [105.6770886, 20.664331],
                [105.6769967, 20.6654179],
                [105.6775385, 20.6656739],
                [105.6770825, 20.6676214],
                [105.676294, 20.6676816],
                [105.6764388, 20.6715965],
                [105.6769146, 20.6714981],
                [105.6778121, 20.6667581],
                [105.6792225, 20.6641926],
                [105.6794613, 20.6636169],
                [105.6797769, 20.6630166],
                [105.6800295, 20.6625663],
                [105.68006, 20.6622667],
                [105.6800584, 20.6619072],
                [105.6798139, 20.6614719],
                [105.6790129, 20.660836],
                [105.6784895, 20.6606629],
                [105.6783355, 20.6605474],
                [105.6787951, 20.6587236],
                [105.6789916, 20.6581974],
                [105.6793701, 20.6574172],
                [105.6797467, 20.6567554],
                [105.6803307, 20.6560316],
                [105.6798388, 20.6551368],
                [105.6795449, 20.6546023],
                [105.6793743, 20.6542101],
                [105.6802437, 20.6534899],
                [105.6806554, 20.653039],
                [105.6808767, 20.6527086],
                [105.6809072, 20.6524089],
                [105.6808091, 20.6518103],
                [105.6806153, 20.6511821],
                [105.6801662, 20.6503752],
                [105.6799086, 20.6497172],
                [105.6796826, 20.6489693],
                [105.6793621, 20.6484614],
                [105.678913, 20.6476545],
                [105.6786884, 20.647236],
                [105.6785916, 20.6469669],
                [105.6785904, 20.6466674],
                [105.6787158, 20.6462476],
                [105.6789981, 20.6453179],
                [105.6793455, 20.6447175],
                [105.6796617, 20.6442669],
                [105.6798505, 20.643787],
                [105.6799443, 20.6433972],
                [105.6801326, 20.6428273],
                [105.6803536, 20.6424071],
                [105.6806381, 20.6419866],
                [105.6810177, 20.6415059],
                [105.6813348, 20.641235],
                [105.6816838, 20.640994],
                [105.6819369, 20.6406636],
                [105.6820941, 20.6402436],
                [105.6822525, 20.6400932],
                [105.6833467, 20.6398197],
                [105.6843738, 20.639307],
                [105.6852348, 20.6386698],
                [105.6865574, 20.6379162],
                [105.6856023, 20.6371651],
                [105.6835685, 20.6351134],
                [105.6847663, 20.6330004],
                [105.6811927, 20.6301113],
                [105.6800003, 20.6293172],
                [105.6792612, 20.628913],
                [105.6783591, 20.6284076],
                [105.6780822, 20.6283789],
                [105.6777135, 20.6289578],
                [105.6772835, 20.6297394],
                [105.6770066, 20.6298264],
                [105.6764526, 20.6297691],
                [105.6758676, 20.6295672],
                [105.6754982, 20.6294231],
                [105.6751285, 20.6292209],
                [105.6744228, 20.6290559],
                [105.6739588, 20.6289906],
                [105.6732511, 20.6291938],
                [105.6729744, 20.6294834],
                [105.672606, 20.6302647],
                [105.6723814, 20.6311004],
                [105.6721352, 20.6319176],
                [105.6710863, 20.6322227],
                [105.670208, 20.6321304],
                [105.6695773, 20.6307986],
                [105.6693839, 20.6302302],
                [105.6691288, 20.6298119],
                [105.6686039, 20.6292274],
                [105.6676304, 20.6292562],
                [105.6674549, 20.6293573],
                [105.6664919, 20.6295263],
                [105.6661754, 20.6296676],
                [105.6659959, 20.6298431],
                [105.6656995, 20.6301801],
                [105.6635553, 20.6281329],
                [105.6658288, 20.6245148],
                [105.666751, 20.6235303],
                [105.6668116, 20.6226336],
                [105.6660725, 20.6222004],
                [105.6653945, 20.6213332],
                [105.6657317, 20.6201179],
                [105.6658226, 20.6187582],
                [105.6657913, 20.6183243],
                [105.6661089, 20.6171179],
                [105.6661899, 20.6169932],
                [105.6663515, 20.6170075],
                [105.6681754, 20.6174299],
                [105.6691139, 20.6172797],
                [105.6699875, 20.6167872],
                [105.6703486, 20.6165495],
                [105.6703805, 20.616393],
                [105.6708565, 20.6160916],
                [105.6716814, 20.6159804],
                [105.6723586, 20.615922],
                [105.6734358, 20.6158919],
                [105.6737129, 20.6159495],
                [105.6745426, 20.6163718],
                [105.6748217, 20.6165849],
                [105.6751169, 20.6167289],
                [105.6754065, 20.6165843],
                [105.6761222, 20.6162314],
                [105.6767539, 20.6160494],
                [105.6773147, 20.616293],
                [105.6776947, 20.6168684],
                [105.6776233, 20.617074],
                [105.678485, 20.6169283],
                [105.6794695, 20.6165224],
                [105.6800239, 20.6168111],
                [105.6809171, 20.6173021],
                [105.6815944, 20.617388],
                [105.6819329, 20.6173588],
                [105.6821819, 20.6170901],
                [105.682559, 20.6160402],
                [105.6828546, 20.6158822],
                [105.6835938, 20.6162576],
                [105.684148, 20.6164306],
                [105.6848868, 20.6164587],
                [105.6851638, 20.6164005],
                [105.6856869, 20.6162264],
                [105.6864253, 20.6159941],
                [105.6868256, 20.6161383],
                [105.6873497, 20.6167743],
                [105.6874855, 20.6172504],
                [105.6881595, 20.6185356],
                [105.6894498, 20.6201158],
                [105.6898653, 20.6205335],
                [105.6904405, 20.6211003],
                [105.6906488, 20.6213134],
                [105.6909563, 20.6210115],
                [105.6913259, 20.6212835],
                [105.692096, 20.6216878],
                [105.6932291, 20.6210861],
                [105.6941777, 20.6205681],
                [105.6954176, 20.6199181],
                [105.6958438, 20.6198202],
                [105.69613, 20.619789],
                [105.6964163, 20.6197579],
                [105.696989, 20.6197557],
                [105.697403, 20.6198438],
                [105.6977538, 20.620022],
                [105.6980095, 20.6202606],
                [105.6982018, 20.6205893],
                [105.698395, 20.6210977],
                [105.6986522, 20.6216657],
                [105.6986833, 20.6217672],
                [105.6988044, 20.6217299],
                [105.6997585, 20.6214363],
                [105.7012281, 20.6213556],
                [105.7019529, 20.619855],
                [105.7040325, 20.6156276],
                [105.7040306, 20.615193],
                [105.7040607, 20.6146722],
                [105.7039547, 20.6140334],
                [105.7038439, 20.6136888],
                [105.7037298, 20.6133856],
                [105.7037922, 20.6130859],
                [105.7039254, 20.612868],
                [105.7040831, 20.6125678],
                [105.7041138, 20.6123281],
                [105.7041127, 20.6120884],
                [105.703984, 20.6117894],
                [105.7037906, 20.6112211],
                [105.7036615, 20.6108323],
                [105.7036604, 20.6105926],
                [105.7038189, 20.6104722],
                [105.7041361, 20.6102612],
                [105.7047706, 20.6098692],
                [105.7051509, 20.6095381],
                [105.7053085, 20.609208],
                [105.7052754, 20.6089387],
                [105.7050512, 20.6086101],
                [105.7047951, 20.6082816],
                [105.7043799, 20.6079239],
                [105.7043205, 20.6077224],
                [105.7042876, 20.6074828],
                [105.7044719, 20.6071748],
                [105.7047247, 20.6068143],
                [105.7050722, 20.6062737],
                [105.7053245, 20.6057635],
                [105.7053226, 20.605374],
                [105.7052895, 20.6051047],
                [105.705097, 20.604746],
                [105.7049044, 20.6043874],
                [105.7046804, 20.6040888],
                [105.7042967, 20.6036711],
                [105.7041681, 20.6033721],
                [105.7040077, 20.6030733],
                [105.7040067, 20.6028636],
                [105.7040375, 20.6026538],
                [105.7041958, 20.6024735],
                [105.7044488, 20.6021429],
                [105.7052726, 20.6014205],
                [105.7059071, 20.6010286],
                [105.706446, 20.600607],
                [105.7067307, 20.6002463],
                [105.70692, 20.5999161],
                [105.7071418, 20.5997355],
                [105.7075546, 20.599554],
                [105.7082867, 20.5986957],
                [105.7090948, 20.5988444],
                [105.7103184, 20.5990695],
                [105.7109955, 20.5990686],
                [105.7124416, 20.5987197],
                [105.7145952, 20.5981673],
                [105.7169028, 20.5976725],
                [105.7184718, 20.5972944],
                [105.7201941, 20.5964532],
                [105.7228428, 20.5977807],
                [105.7244778, 20.6004406],
                [105.7253414, 20.6017124],
                [105.7258658, 20.6025219],
                [105.7264646, 20.6035086],
                [105.7273474, 20.6053554],
                [105.7279305, 20.6041973],
                [105.7296785, 20.6024258],
                [105.7301215, 20.6019148],
                [105.7304371, 20.6013743],
                [105.730975, 20.6007729],
                [105.731736, 20.6002305],
                [105.7328788, 20.5996192],
                [105.7355042, 20.5986907],
                [105.7396479, 20.5984527],
                [105.7402947, 20.5987411],
                [105.7410648, 20.5992029],
                [105.7416502, 20.5995783],
                [105.7419886, 20.599462],
                [105.7431185, 20.5988633],
                [105.7436895, 20.5985313],
                [105.7443228, 20.5979295],
                [105.7450814, 20.5969078],
                [105.7454914, 20.5958983],
                [105.7460563, 20.5945973],
                [105.7465607, 20.5936366],
                [105.7470021, 20.592826],
                [105.747508, 20.5921948],
                [105.74811, 20.5916829],
                [105.7487436, 20.5911709],
                [105.7493462, 20.5907788],
                [105.7499173, 20.5904769],
                [105.7502997, 20.5902819],
                [105.7512985, 20.5900164],
                [105.7518829, 20.5897841],
                [105.7523441, 20.5895231],
                [105.7526513, 20.5892043],
                [105.7529581, 20.5886831],
                [105.753419, 20.5882194],
                [105.7538181, 20.5876403],
                [105.7540326, 20.5870902],
                [105.754124, 20.5865114],
                [105.7543381, 20.5861136],
                [105.7546233, 20.5857205],
                [105.7549076, 20.5853386],
                [105.7552651, 20.585026],
                [105.7555658, 20.5848625],
                [105.755881, 20.5847259],
                [105.7563976, 20.5842512],
                [105.7598075, 20.580803],
                [105.7614052, 20.5792671],
                [105.7623269, 20.5783977],
                [105.7648151, 20.5758768],
                [105.7651219, 20.5753265],
                [105.7654587, 20.5743422],
                [105.7659493, 20.5733578],
                [105.7663791, 20.5728074],
                [105.7667994, 20.5722134],
                [105.7671266, 20.5716033],
                [105.7676412, 20.5700372],
                [105.7679117, 20.569362],
                [105.76827, 20.567195],
                [105.7685816, 20.5653102],
                [105.7687658, 20.5650784],
                [105.7690113, 20.5649591],
                [105.7706116, 20.5647862],
                [105.7771343, 20.5645154],
                [105.7776269, 20.5646594],
                [105.7787773, 20.5657674],
                [105.7792816, 20.5661442],
                [105.7794829, 20.5661974],
                [105.7796983, 20.5661965],
                [105.7800567, 20.5660868],
                [105.7816581, 20.5648843],
                [105.7821504, 20.5648546],
                [105.7826126, 20.5651722],
                [105.7830748, 20.5655475],
                [105.7836954, 20.5660057],
                [105.7847071, 20.5663549],
                [105.7856613, 20.5665559],
                [105.7872922, 20.5665243],
                [105.7898152, 20.5664912],
                [105.7902461, 20.5665193],
                [105.7909539, 20.5666339],
                [105.7921548, 20.567037],
                [105.794221, 20.5673792],
                [105.7946422, 20.567375],
                [105.7949155, 20.567482],
                [105.7954586, 20.5679286],
                [105.7960224, 20.5686839],
                [105.7963131, 20.5692577],
                [105.7965875, 20.5703385],
                [105.796636, 20.5713799],
                [105.7967693, 20.5721775],
                [105.7968301, 20.5728267],
                [105.7969908, 20.5733536],
                [105.7971799, 20.5738263],
                [105.7974677, 20.574885],
                [105.7978022, 20.5755048],
                [105.7980813, 20.5766327],
                [105.7984529, 20.5778184],
                [105.7986698, 20.5785703],
                [105.7991129, 20.5802825],
                [105.7994275, 20.58163],
                [105.7995407, 20.5817883],
                [105.7996011, 20.5818727],
                [105.7997668, 20.5820496],
                [105.8000001, 20.5822136],
                [105.8004317, 20.582589],
                [105.8008018, 20.5830513],
                [105.8015113, 20.5838602],
                [105.801728, 20.5845543],
                [105.8017908, 20.5851618],
                [105.8017608, 20.5855958],
                [105.8014856, 20.5864643],
                [105.801087, 20.5872173],
                [105.8007521, 20.5878673],
                [105.8008572, 20.5887801],
                [105.8009034, 20.5893616],
                [105.8010805, 20.5902807],
                [105.8010821, 20.590592],
                [105.8009881, 20.5918234],
                [105.801122, 20.5927201],
                [105.8012826, 20.59322],
                [105.8015145, 20.5936385],
                [105.8019045, 20.5940831],
                [105.8030021, 20.5956826],
                [105.8045222, 20.5980299],
                [105.8054223, 20.5994603],
                [105.8058317, 20.5994284],
                [105.8063071, 20.5992298],
                [105.8065279, 20.5989381],
                [105.8066598, 20.5985009],
                [105.8066809, 20.5979599],
                [105.8068795, 20.5976474],
                [105.8071227, 20.5975221],
                [105.8077641, 20.5973129],
                [105.8085201, 20.5990387],
                [105.8069956, 20.6003731],
                [105.8069296, 20.6005813],
                [105.8083496, 20.6023058],
                [105.8097707, 20.6030323],
                [105.8103307, 20.6033633],
                [105.8116282, 20.6044403],
                [105.81104, 20.6047457],
                [105.8099709, 20.6050286],
                [105.8087102, 20.6054887],
                [105.8080912, 20.6058644],
                [105.8073399, 20.6065107],
                [105.8067877, 20.6070943],
                [105.8059502, 20.608402],
                [105.8057228, 20.608863],
                [105.8055965, 20.6094317],
                [105.8055992, 20.6099323],
                [105.8058185, 20.6106889],
                [105.8061238, 20.6113776],
                [105.8064706, 20.611782],
                [105.8076394, 20.6128185],
                [105.8091817, 20.6138942],
                [105.8110606, 20.6150385],
                [105.812041, 20.6157784],
                [105.8125605, 20.6162361],
                [105.8128363, 20.6167761],
                [105.8129797, 20.6176102],
                [105.8129809, 20.618219],
                [105.8134077, 20.6182485],
                [105.8134226, 20.6182459],
                [105.8136076, 20.6185493],
                [105.8138433, 20.6186318],
                [105.814071, 20.6186321],
                [105.8141372, 20.6182549],
                [105.8142463, 20.61794],
                [105.8144817, 20.6179119],
                [105.8149556, 20.6179621],
                [105.8150887, 20.6180867],
                [105.8151343, 20.6187733],
                [105.815025, 20.6194186],
                [105.814849, 20.6199598],
                [105.8144294, 20.6204183],
                [105.8138988, 20.6207938],
                [105.8136114, 20.62094],
                [105.8134569, 20.6212109],
                [105.8133915, 20.6216688],
                [105.8134607, 20.6230211],
                [105.813507, 20.623999],
                [105.8135629, 20.624284],
                [105.8135412, 20.6244923],
                [105.8132981, 20.6247218],
                [105.8130773, 20.6249929],
                [105.8128566, 20.6253473],
                [105.8127468, 20.6257016],
                [105.812704, 20.6264929],
                [105.8126164, 20.6269513],
                [105.8124843, 20.6273263],
                [105.8122081, 20.6275246],
                [105.811734, 20.627797],
                [105.8119705, 20.628295],
                [105.8126502, 20.6295675],
                [105.8148048, 20.6343043],
                [105.8197304, 20.6377948],
                [105.8214265, 20.6389265],
                [105.8241533, 20.6406707],
                [105.8243968, 20.6406702],
                [105.8246846, 20.6406489],
                [105.8250831, 20.6406689],
                [105.8255619, 20.6408818],
                [105.8282427, 20.6423084],
                [105.8306164, 20.6436461],
                [105.8310618, 20.6439306],
                [105.8315101, 20.644217],
                [105.8321013, 20.64465],
                [105.8326449, 20.6451883],
                [105.8331026, 20.6456907],
                [105.8334936, 20.6463356],
                [105.8337608, 20.6468958],
                [105.8339914, 20.6471361],
                [105.8341603, 20.6476823],
                [105.8341609, 20.6486439],
                [105.8337056, 20.6493573],
                [105.8322709, 20.6503098],
                [105.8307734, 20.6509525],
                [105.8291524, 20.6516298],
                [105.828041, 20.6521202],
                [105.8263131, 20.653438],
                [105.8245927, 20.6555016],
                [105.823518, 20.6568919],
                [105.8230418, 20.6574783],
                [105.8223555, 20.6581671],
                [105.821584, 20.6588377],
                [105.8196213, 20.6603984],
                [105.8218899, 20.6621541],
                [105.8223147, 20.6624879],
                [105.8224591, 20.6626855],
                [105.8224187, 20.6645602],
                [105.8278622, 20.663779],
                [105.8290091, 20.6631129],
                [105.8310629, 20.6620289],
                [105.8346186, 20.6613343],
                [105.8399975, 20.6594258],
                [105.8495684, 20.6525598],
                [105.8506634, 20.6508221],
                [105.8509832, 20.6503715],
                [105.8515553, 20.6495905],
                [105.852147, 20.6488712],
                [105.8528832, 20.648064],
                [105.8536024, 20.647389],
                [105.8541296, 20.64689],
                [105.8546243, 20.6464197],
                [105.8548767, 20.6461796],
                [105.8556349, 20.645736],
                [105.8567633, 20.6452963],
                [105.8579283, 20.6449094],
                [105.8588214, 20.6447296],
                [105.8595743, 20.6446883],
                [105.8608144, 20.6446232],
                [105.8620064, 20.6446316],
                [105.8628088, 20.6447814],
                [105.8630521, 20.6448268],
                [105.8638577, 20.6450713],
                [105.8649581, 20.6453223],
                [105.8657335, 20.6453831],
                [105.8664201, 20.64534],
                [105.8675935, 20.6451502],
                [105.8689895, 20.6448108],
                [105.8697331, 20.6445525],
                [105.8715687, 20.6438075],
                [105.8733918, 20.6433399],
                [105.8748305, 20.6431424],
                [105.877024, 20.6430066],
                [105.8784859, 20.6430242],
                [105.8799261, 20.6432292],
                [105.8815664, 20.6437044],
                [105.8828742, 20.6441594],
                [105.8856456, 20.6452149],
                [105.8878425, 20.6463228],
                [105.8894815, 20.6471158],
                [105.8909584, 20.6479751],
                [105.8912092, 20.6481207],
                [105.8913817, 20.6482207],
                [105.8925506, 20.6488988],
                [105.8943068, 20.6503199],
                [105.8953931, 20.6512459],
                [105.8960653, 20.6518419],
                [105.8961191, 20.6535376],
                [105.8961001, 20.6557447],
                [105.8960054, 20.6573246],
                [105.8960416, 20.6617849],
                [105.8957503, 20.6638298],
                [105.8957057, 20.6656884],
                [105.895782, 20.6665477],
                [105.8960214, 20.6679895],
                [105.8961353, 20.6693114],
                [105.8964708, 20.6691244],
                [105.8970601, 20.66893],
                [105.8978535, 20.6686765],
                [105.8987897, 20.6681498],
                [105.8997735, 20.6675094],
                [105.9005407, 20.6669156],
                [105.9013066, 20.6660949],
                [105.9021386, 20.6653658],
                [105.9025047, 20.6650451],
                [105.9029133, 20.6649068],
                [105.9042865, 20.6649677],
                [105.905202, 20.6650647],
                [105.9053951, 20.6650851],
                [105.9055698, 20.6657352],
                [105.9065539, 20.6677436],
                [105.9075955, 20.668775],
                [105.9094882, 20.6698106],
                [105.9116575, 20.6709945],
                [105.9153545, 20.6729135],
                [105.9173312, 20.6743096],
                [105.918122, 20.6737881],
                [105.9191015, 20.6751651],
                [105.9195311, 20.6758219],
                [105.920699, 20.6760646],
                [105.9228451, 20.6766861],
                [105.9305242, 20.6795073],
                [105.9323559, 20.6801072],
                [105.9346806, 20.6801479],
                [105.9386879, 20.6805099],
                [105.9396783, 20.6809259],
                [105.9412623, 20.681387],
                [105.9422035, 20.6818962],
                [105.9431434, 20.6819403],
                [105.9441304, 20.6811935],
                [105.9455108, 20.6796552],
                [105.946052, 20.6786771],
                [105.9466882, 20.6763499],
                [105.9467649, 20.6687686],
                [105.9466039, 20.6664492],
                [105.9460392, 20.6660947],
                [105.9448024, 20.665318],
                [105.9432402, 20.6637179],
                [105.9430131, 20.6621374],
                [105.9429359, 20.6610914],
                [105.9432264, 20.6589748],
                [105.9434954, 20.6579511],
                [105.9447811, 20.6551843],
                [105.9449448, 20.654832],
                [105.9463266, 20.6539681],
                [105.949117, 20.6529379],
                [105.9536369, 20.6516473],
                [105.9584285, 20.6503094],
                [105.9576993, 20.6519805],
                [105.9566868, 20.6551739],
                [105.9566898, 20.6561734],
                [105.9573903, 20.6580168],
                [105.9585796, 20.659516],
                [105.9606867, 20.6614401],
                [105.9635601, 20.6633157],
                [105.9658722, 20.6642986],
                [105.9693905, 20.6650195],
                [105.9720897, 20.6652428],
                [105.9772625, 20.6654821],
                [105.9778468, 20.6668629],
                [105.9779769, 20.6674362],
                [105.978139, 20.6680788],
                [105.9783968, 20.6688495],
                [105.9785578, 20.6693139],
                [105.9786658, 20.6697291],
                [105.9786877, 20.6698576],
                [105.9788021, 20.6704479],
                [105.9788899, 20.6710121],
                [105.9789031, 20.671428],
                [105.978864, 20.6718838],
                [105.9787816, 20.6721914],
                [105.978652, 20.672524],
                [105.9785174, 20.6728814],
                [105.9784358, 20.6732979],
                [105.9783955, 20.6735754],
                [105.9784601, 20.6738029],
                [105.9787894, 20.6742171],
                [105.9791606, 20.6746112],
                [105.979601, 20.675099],
                [105.9801207, 20.6756508],
                [105.9805559, 20.6761435],
                [105.981086, 20.6766754],
                [105.9814042, 20.6769906],
                [105.9821094, 20.6777246],
                [105.9825552, 20.6782073],
                [105.9830745, 20.6787097],
                [105.9834989, 20.6791629],
                [105.9837319, 20.6793596],
                [105.9841723, 20.6798375],
                [105.9845967, 20.6803106],
                [105.9847665, 20.6804879],
                [105.9852723, 20.6805148],
                [105.9856305, 20.6805126],
                [105.9858202, 20.6805116],
                [105.9861692, 20.6807275],
                [105.9866558, 20.681012],
                [105.9870686, 20.6813067],
                [105.9874388, 20.6815324],
                [105.9879732, 20.6819009],
                [105.9882907, 20.682117],
                [105.9884494, 20.6822349],
                [105.9888183, 20.6822427],
                [105.9893977, 20.6822394],
                [105.9901558, 20.6821657],
                [105.9907661, 20.6820434],
                [105.9911336, 20.681853],
                [105.9919053, 20.6814474],
                [105.9924091, 20.6811691],
                [105.9928696, 20.6819038],
                [105.9930679, 20.6821704],
                [105.9938008, 20.6814219],
                [105.9911717, 20.678142],
                [105.9940512, 20.6764649],
                [105.9967928, 20.6780949],
                [105.9952133, 20.6818244],
                [105.9930321, 20.683091],
                [105.9936505, 20.6851451],
                [105.9925432, 20.6847094],
                [105.9911518, 20.6897756],
                [105.9972926, 20.6924839],
                [105.9970185, 20.6932234],
                [105.9974321, 20.6935752],
                [105.9947996, 20.6975506],
                [106.0023838, 20.701872],
                [106.0027316, 20.7023361],
                [106.0029325, 20.7032194],
                [106.0028888, 20.7043642],
                [106.0028137, 20.7043632],
                [106.0018748, 20.7044412],
                [106.0005744, 20.7043286],
                [105.9999426, 20.7043111],
                [105.9987072, 20.704277],
                [105.9971256, 20.7042862],
                [105.9952762, 20.7042511],
                [105.9941812, 20.7042574],
                [105.9917345, 20.7040542],
                [105.9900067, 20.7040184],
                [105.9889836, 20.7038413],
                [105.9881792, 20.7036172],
                [105.9868724, 20.7028355],
                [105.9858709, 20.7022465],
                [105.9844531, 20.7012254],
                [105.9834031, 20.7006596],
                [105.9825486, 20.700207],
                [105.9811263, 20.6998656],
                [105.9811262, 20.6998655],
                [105.9797094, 20.6995026],
                [105.978881, 20.6993243],
                [105.9777375, 20.6993308],
                [105.9765699, 20.6993832],
                [105.9748874, 20.699623],
                [105.9738363, 20.699959],
                [105.973194, 20.700477],
                [105.9731613, 20.7005034],
                [105.9727039, 20.7017268],
                [105.9722977, 20.7028956],
                [105.9718563, 20.7042591],
                [105.9713777, 20.7055197],
                [105.9708987, 20.7067348],
                [105.9702166, 20.7085113],
                [105.969617, 20.7098871],
                [105.9691864, 20.7110561],
                [105.9684576, 20.7131531],
                [105.9679798, 20.714551],
                [105.9671048, 20.7166261],
                [105.9666513, 20.7180239],
                [105.9659695, 20.7198691],
                [105.9653695, 20.7211991],
                [105.9650591, 20.7221386],
                [105.9646409, 20.7233647],
                [105.9644269, 20.7241436],
                [105.9643349, 20.7249905],
                [105.9642444, 20.7260661],
                [105.9644845, 20.7274715],
                [105.9649055, 20.7286355],
                [105.9654218, 20.7294562],
                [105.9661078, 20.7301842],
                [105.9674934, 20.7318348],
                [105.9684252, 20.7329275],
                [105.9692339, 20.7338149],
                [105.9695865, 20.7342931],
                [105.9695865, 20.7342932],
                [105.96957, 20.7350139],
                [105.9692853, 20.7361592],
                [105.9687852, 20.7378776],
                [105.9685144, 20.7393086],
                [105.9683033, 20.7405451],
                [105.968261, 20.7415517],
                [105.9681178, 20.7422953],
                [105.9680119, 20.7433291],
                [105.9679935, 20.7435089],
                [105.9679761, 20.744584],
                [105.9678848, 20.7455453],
                [105.9677597, 20.7469298],
                [105.9675969, 20.7481201],
                [105.9674982, 20.7490106],
                [105.9673623, 20.7508141],
                [105.9674073, 20.7520826],
                [105.967495, 20.7542532],
                [105.9675867, 20.7560307],
                [105.9676623, 20.758052],
                [105.9676666, 20.7594289],
                [105.9676676, 20.7597475],
                [105.9676704, 20.7606605],
                [105.9678827, 20.7619642],
                [105.9682978, 20.7647242],
                [105.9685101, 20.7678086],
                [105.9684458, 20.7695427],
                [105.9684352, 20.7698273],
                [105.9679122, 20.7711997],
                [105.9674933, 20.7720515],
                [105.9669104, 20.7732365],
                [105.9656656, 20.7749368],
                [105.9640389, 20.776736],
                [105.9632078, 20.7774562],
                [105.962099, 20.7782424],
                [105.960296, 20.779161],
                [105.9575902, 20.780082],
                [105.9539122, 20.7810707],
                [105.9535261, 20.7811262],
                [105.9525237, 20.7812702],
                [105.9491919, 20.7819316],
                [105.9470399, 20.7822635],
                [105.9427006, 20.7827642],
                [105.9399238, 20.7832283],
                [105.9376332, 20.7836804],
                [105.9347784, 20.7845401],
                [105.9324192, 20.7853399],
                [105.9297925, 20.7863867],
                [105.9293067, 20.7865185],
                [105.9292121, 20.7865722],
                [105.9284458, 20.7870068],
                [105.9282665, 20.7871085],
                [105.9268457, 20.7881889],
                [105.9253375, 20.7895912],
                [105.924075, 20.7908717],
                [105.9235492, 20.7914269],
                [105.9228977, 20.7921147],
                [105.9219162, 20.7933898],
                [105.9217904, 20.7935532],
                [105.9199229, 20.7964297],
                [105.918955, 20.7980636],
                [105.9173327, 20.8016898],
                [105.9163823, 20.8041828],
                [105.9161241, 20.8048289],
                [105.9158217, 20.8055857],
                [105.9154248, 20.8066382],
                [105.9151487, 20.8073126],
                [105.9144528, 20.809242],
                [105.9126376, 20.8145544],
                [105.9123438, 20.8154963],
                [105.9121104, 20.8163241],
                [105.9118232, 20.8173415],
                [105.9114818, 20.8184883],
                [105.9112118, 20.8192991],
                [105.9109611, 20.820299],
                [105.9109096, 20.8208847],
                [105.9108971, 20.8214638],
                [105.9108953, 20.8215478],
                [105.910828, 20.8225468],
                [105.910797, 20.823494],
                [105.9107142, 20.8249639],
                [105.9107781, 20.8260294],
                [105.9107957, 20.8263229],
                [105.9107809, 20.8269081],
                [105.9108049, 20.8278544],
                [105.910867, 20.8290415],
                [105.9110864, 20.8300843],
                [105.9119274, 20.8326913],
                [105.9126127, 20.8346576],
                [105.9127678, 20.8351027],
                [105.9135369, 20.8367967],
                [105.9142873, 20.8384108],
                [105.9144457, 20.8387514],
                [105.9152135, 20.8399888],
                [105.9159814, 20.8412263],
                [105.9168525, 20.8421182],
                [105.9183843, 20.8431588],
                [105.9186986, 20.8433724],
                [105.9191502, 20.8436794],
                [105.92103, 20.8449149],
                [105.9229096, 20.8460201],
                [105.9244403, 20.8466691],
                [105.9267367, 20.8477079],
                [105.928546, 20.8485521],
                [105.9316077, 20.84985],
                [105.9322345, 20.8503053],
                [105.9332806, 20.851543],
                [105.9340836, 20.8528793],
                [105.9350626, 20.8549658],
                [105.9356239, 20.8567922],
                [105.9358373, 20.858489],
                [105.9359795, 20.8595984],
                [105.9361054, 20.8608634],
                [105.936212, 20.8623293],
                [105.9361711, 20.8636127],
                [105.9361387, 20.8638603],
                [105.9359849, 20.8650343],
                [105.9355079, 20.8667326],
                [105.9346659, 20.86842],
                [105.9345448, 20.8686627],
                [105.9338693, 20.8698121],
                [105.9330501, 20.8713748],
                [105.9323752, 20.8726158],
                [105.9313097, 20.8737673],
                [105.9303876, 20.8744598],
                [105.9284923, 20.8754785],
                [105.9273011, 20.8758255],
                [105.9262046, 20.8760868],
                [105.9250846, 20.8763221],
                [105.9250682, 20.8763294],
                [105.9243552, 20.876647],
                [105.9228034, 20.8780303],
                [105.9216888, 20.8791362],
                [105.9204284, 20.8803346],
                [105.9183406, 20.8817666],
                [105.915051, 20.8838571],
                [105.9147193, 20.8840407],
                [105.911806, 20.8854748],
                [105.9096428, 20.8867171],
                [105.9095238, 20.8867693],
                [105.9078434, 20.887506],
                [105.9059954, 20.8882949],
                [105.9030779, 20.8896395],
                [105.9006466, 20.8907523],
                [105.8985559, 20.8917717],
                [105.8982947, 20.891958],
                [105.8971964, 20.8927413],
                [105.8968954, 20.8931213],
                [105.8962284, 20.8939379],
                [105.8960029, 20.8945061],
                [105.8956999, 20.8952697],
                [105.8953656, 20.8964629],
                [105.8953248, 20.8977921],
                [105.8954291, 20.8989371],
                [105.8956778, 20.8997607],
                [105.8962223, 20.9011325],
                [105.8966667, 20.9020466],
                [105.8974528, 20.9030506],
                [105.898337, 20.9041457],
                [105.899158, 20.9051853],
                [105.9011366, 20.9066935],
                [105.9028145, 20.9078048],
                [105.9043782, 20.9085936],
                [105.9079628, 20.9114968],
                [105.9104029, 20.9141487],
                [105.9118518, 20.9157613],
                [105.9126904, 20.9171944],
                [105.912995, 20.9181616],
                [105.913074, 20.9189575],
                [105.9131088, 20.9193079],
                [105.9131084, 20.9200242],
                [105.9128788, 20.9212777],
                [105.9126875, 20.9221014],
                [105.9121911, 20.9228534],
                [105.911351, 20.9239991],
                [105.911014, 20.9243066],
                [105.9099384, 20.9252878],
                [105.9084591, 20.9263283],
                [105.9116392, 20.9297752],
                [105.9125773, 20.931107],
                [105.9136727, 20.9328615],
                [105.9157999, 20.9357539],
                [105.9145084, 20.936691],
                [105.9147906, 20.9373247],
                [105.9141238, 20.9377244],
                [105.9135641, 20.9380762],
                [105.9122376, 20.9383996],
                [105.9115962, 20.9387601],
                [105.9108922, 20.9401337],
                [105.9104235, 20.9411379],
                [105.9100767, 20.942083],
                [105.9085764, 20.9430936],
                [105.9067854, 20.9444799],
                [105.9051263, 20.9457072],
                [105.9030199, 20.9471529],
                [105.9040882, 20.9489505],
                [105.9047654, 20.9500273],
                [105.9053289, 20.9514501],
                [105.9056479, 20.9534362],
                [105.9053794, 20.9536147],
                [105.9056135, 20.9541026],
                [105.9061749, 20.955437],
                [105.906923, 20.9573643],
                [105.9073081, 20.9581767],
                [105.9074141, 20.9585807],
                [105.9078425, 20.9609357],
                [105.9083044, 20.9622896],
                [105.9088833, 20.9633533],
                [105.9093824, 20.9643447],
                [105.9110117, 20.9671462],
                [105.9122216, 20.9693414],
                [105.9123333, 20.9704553],
                [105.9127085, 20.9704912],
                [105.9128792, 20.9707783],
                [105.9132098, 20.970738],
                [105.9135087, 20.9707635],
                [105.9141017, 20.9706437],
                [105.914037, 20.9723111],
                [105.9197802, 20.9719807],
                [105.9196122, 20.9734053],
                [105.9197945, 20.9746227],
                [105.9224798, 20.9746499],
                [105.9225641, 20.974762],
                [105.9225637, 20.9757279],
                [105.92465, 20.9757632],
                [105.9244717, 20.974568],
                [105.9290699, 20.9729547],
                [105.9303172, 20.9724883],
                [105.9320111, 20.973802],
                [105.9340682, 20.9718112],
                [105.9351037, 20.970805],
                [105.936138, 20.9698034],
                [105.936738, 20.9699151],
                [105.9375012, 20.9700318],
                [105.9385079, 20.9701876],
                [105.9389551, 20.9692312],
                [105.9396183, 20.9690837],
                [105.9397709, 20.9679558],
                [105.9424703, 20.9683646],
                [105.9442147, 20.9685963],
                [105.946267, 20.9689629],
                [105.9477717, 20.9692897],
                [105.9478039, 20.9690925],
                [105.9478896, 20.96876],
                [105.9480031, 20.9682531],
                [105.9485013, 20.9683685],
                [105.9484397, 20.967985],
                [105.9487222, 20.9679331],
                [105.9486822, 20.9678067],
                [105.9486734, 20.967584],
                [105.9488773, 20.967514],
                [105.948737, 20.9671452],
                [105.9485799, 20.9672062],
                [105.9485569, 20.9670352],
                [105.9487773, 20.9669861],
                [105.949634, 20.9669995],
                [105.9496466, 20.966559],
                [105.9499234, 20.9665681],
                [105.9500865, 20.9668064],
                [105.9500705, 20.9671179],
                [105.9504241, 20.9671313],
                [105.9504059, 20.96675],
                [105.9509224, 20.96675],
                [105.9514075, 20.9666943],
                [105.9518537, 20.9666088],
                [105.9520654, 20.9665843],
                [105.9521072, 20.9670083],
                [105.9523501, 20.9669807],
                [105.952382, 20.9678192],
                [105.9528757, 20.9678679],
                [105.9529444, 20.9674564],
                [105.9532966, 20.9675322],
                [105.9533189, 20.9674801],
                [105.9534623, 20.967558],
                [105.9536369, 20.9678308],
                [105.9538602, 20.9681166],
                [105.9539801, 20.9680502],
                [105.9539537, 20.9679686],
                [105.9539659, 20.9679059],
                [105.9543013, 20.9677806],
                [105.9548744, 20.9675727],
                [105.9548913, 20.967605],
                [105.955086, 20.9675954],
                [105.9551033, 20.9677721],
                [105.9553426, 20.9676909],
                [105.9553297, 20.9675629],
                [105.955437, 20.9675334],
                [105.9554151, 20.9672528],
                [105.9556579, 20.9671885],
                [105.9558592, 20.9671461],
                [105.9559442, 20.9676077],
                [105.9560381, 20.9678553],
                [105.9561071, 20.9680167],
                [105.9561924, 20.9681629],
                [105.9563669, 20.9680554],
                [105.9567857, 20.9679596],
                [105.9567754, 20.9661403],
                [105.9592892, 20.9659262],
                [105.9592964, 20.9647943],
                [105.9602107, 20.9643165],
                [105.9615296, 20.9639071],
                [105.9616285, 20.9639756],
                [105.9627397, 20.9635359],
                [105.9640828, 20.9633659],
                [105.9646637, 20.9658465],
                [105.9644128, 20.9658765],
                [105.964473, 20.9673414],
                [105.964483, 20.9679742],
                [105.9652195, 20.9679694],
                [105.9651135, 20.9688962],
                [105.964706, 20.9724594],
                [105.9624185, 20.9734661],
                [105.9631433, 20.9747275],
                [105.9613732, 20.9758723],
                [105.9630605, 20.9803728],
                [105.9622857, 20.9812901],
                [105.9621324, 20.9814201],
                [105.9620637, 20.9814784],
                [105.9624669, 20.9822797],
                [105.9636761, 20.9821179],
                [105.9635765, 20.9825137],
                [105.963366, 20.9830448],
                [105.9651907, 20.984179],
                [105.9652011, 20.985194],
                [105.963975, 20.9844905],
                [105.9632254, 20.9857669],
                [105.9633946, 20.9860082],
                [105.9625518, 20.9869729],
                [105.9613422, 20.9881684],
                [105.9616257, 20.9890839],
                [105.964059, 20.9905504],
                [105.9650793, 20.9909992],
                [105.9656393, 20.990517],
                [105.9664349, 20.9910512],
                [105.9665767, 20.9911464],
                [105.9688289, 20.9886927],
                [105.9706026, 20.9892312],
                [105.9722503, 20.9897198],
                [105.972256, 20.9907844],
                [105.9723142, 20.9915011],
                [105.9723556, 20.9924877],
                [105.972099, 20.9935311],
                [105.971782, 20.993927],
                [105.971025, 20.995466],
                [105.9718009, 20.9961372],
                [105.9720409, 20.9960907],
                [105.9728137, 20.9963885],
                [105.9735226, 20.9963112],
                [105.9741604, 20.9967914],
                [105.9757824, 20.9981405],
                [105.9762456, 20.9978808],
                [105.9776119, 20.9992968],
                [105.9788494, 20.9984133],
                [105.980995, 20.9976613],
                [105.9814641, 20.997537],
                [105.981885, 20.9971572],
                [105.9827106, 20.9968306],
                [105.9833614, 20.9967419],
                [105.9842099, 20.9968278],
                [105.9846513, 20.996784],
                [105.9851755, 20.9967649],
                [105.9859904, 20.9968774],
                [105.9863639, 20.9969746],
                [105.9874776, 20.9973398],
                [105.9879511, 20.997553],
                [105.9879197, 20.9976324],
                [105.9886593, 20.9980881],
                [105.9899473, 20.9990314],
                [105.9908366, 20.9998319],
                [105.9912111, 21.0003848],
                [105.9917131, 21.001202],
                [105.9941918, 21.0006814],
                [105.9957414, 21.0004298],
                [105.9964118, 21.0002746],
                [105.999779, 20.9996505],
                [106.0001854, 21.0006179],
                [106.0012074, 21.0001349],
                [106.0008188, 21.0010316],
                [106.0005146, 21.00201],
                [106.0002883, 21.0027339],
                [105.9995303, 21.0044474],
                [105.9987358, 21.006569],
                [106.0008809, 21.0067547],
                [106.0018606, 21.007398],
                [106.0034459, 21.0075954],
                [106.0045774, 21.0076867],
                [106.0048108, 21.0078365],
                [106.0049774, 21.0105118],
                [106.0087273, 21.0103298],
                [106.0089044, 21.0104557],
                [106.0100388, 21.0107875],
                [106.0100845, 21.0109586],
                [106.009987, 21.0114723],
                [106.009911, 21.011873],
                [106.009836, 21.0123442],
                [106.0096723, 21.0124883],
                [106.0095014, 21.0125257],
                [106.0092217, 21.012491],
                [106.0090228, 21.0124548],
                [106.0084585, 21.0123065],
                [106.0073024, 21.0118156],
                [106.0066249, 21.0115833],
                [106.0062935, 21.0115603],
                [106.0057633, 21.0115386],
                [106.0055778, 21.0115397],
                [106.0053526, 21.0115535],
                [106.0050213, 21.0115554],
                [106.0049054, 21.0115754],
                [106.0056997, 21.0131155],
                [106.0047382, 21.0139167],
                [106.0068201, 21.0160695],
                [106.0068207, 21.0161708],
                [106.0066922, 21.0162726],
                [106.0048668, 21.0170524],
                [106.0046776, 21.0171183],
                [106.0046305, 21.0171347],
                [106.0045452, 21.0172567],
                [106.0045143, 21.0174592],
                [106.004801, 21.0184895],
                [106.0048233, 21.0185906],
                [106.0037712, 21.0190622],
                [106.0037071, 21.0191435],
                [106.0036866, 21.0193055],
                [106.0038848, 21.0199518],
                [106.00438, 21.0215274],
                [106.0045331, 21.0218907],
                [106.0069845, 21.024527],
                [106.0094404, 21.0246136],
                [106.0064733, 21.028832],
                [106.0124125, 21.0300622],
                [106.0126563, 21.0304563],
                [106.0149465, 21.0331907],
                [106.0160107, 21.0345188],
                [106.0179619, 21.0369987],
                [106.0186602, 21.0380785],
                [106.0191415, 21.0390866],
                [106.0196674, 21.0402196],
                [106.0200725, 21.0412051],
                [106.0199617, 21.0418836],
                [106.019739, 21.0441626],
                [106.0197307, 21.0450574],
                [106.0198989, 21.0474968],
                [106.0183451, 21.0483309],
                [106.0161967, 21.0495502],
                [106.0147883, 21.0504392],
                [106.0142729, 21.0507093],
                [106.0151103, 21.0523381],
                [106.0151328, 21.0524795],
                [106.0135456, 21.0533585],
                [106.0134172, 21.0534605],
                [106.0133751, 21.0536022],
                [106.0143741, 21.0559555],
                [106.0133704, 21.0563399],
                [106.0125162, 21.0565675],
                [106.0110432, 21.0569611],
                [106.011381, 21.0579315],
                [106.0109203, 21.0579094],
                [106.01069, 21.0578563],
                [106.0104776, 21.0578166],
                [106.0101749, 21.0577393],
                [106.0098717, 21.05604],
                [106.0082055, 21.0555434],
                [106.0081739, 21.0565572],
                [106.008099, 21.0575949],
                [106.007997, 21.0578037],
                [106.0082582, 21.0582336],
                [106.007427, 21.0590734],
                [106.0081112, 21.0598083],
                [106.0084325, 21.061087],
                [106.0089769, 21.0620488],
                [106.0095026, 21.0625587],
                [106.0083539, 21.0646282],
                [106.006276, 21.0629823],
                [106.0057953, 21.0635481],
                [106.0039502, 21.0661819],
                [106.0022509, 21.068497],
                [106.0021229, 21.0690144],
                [106.0018961, 21.0699311],
                [106.0010566, 21.0698247],
                [106.0009772, 21.0698147],
                [106.0003377, 21.0724072],
                [105.999125, 21.074385],
                [105.9987118, 21.0739687],
                [105.9968234, 21.0722749],
                [105.9933502, 21.0688746],
                [105.9863822, 21.0700505],
                [105.9830379, 21.070056],
                [105.9795527, 21.0701021],
                [105.9784759, 21.0702215],
                [105.9781424, 21.0702585],
                [105.9769054, 21.0703957],
                [105.9716809, 21.0715081],
                [105.9680626, 21.0722708],
                [105.9685117, 21.0734931],
                [105.9666111, 21.0753077],
                [105.963773, 21.0774632],
                [105.9619415, 21.0778536],
                [105.959644, 21.0886674],
                [105.9578069, 21.0912181],
                [105.9534474, 21.0895459],
                [105.9457189, 21.0850473],
                [105.9441841, 21.0854093],
                [105.940317, 21.0836545],
                [105.9400897, 21.0874684],
                [105.9403297, 21.0877837],
                [105.9407184, 21.0882946],
                [105.9404496, 21.0885899],
                [105.9381125, 21.0911571],
                [105.937673, 21.092203],
                [105.9370013, 21.0940312],
                [105.9360031, 21.0969518],
                [105.9357108, 21.098904],
                [105.9364005, 21.1009902],
                [105.9349314, 21.1017212],
                [105.9342364, 21.1027735],
                [105.9347075, 21.1033074],
                [105.9347198, 21.1048077],
                [105.9344744, 21.1058413],
                [105.9338961, 21.106458],
                [105.9314982, 21.1083329],
                [105.9314815, 21.1084724],
                [105.9314608, 21.1088784],
                [105.9318523, 21.1094478],
                [105.9316954, 21.1098334],
                [105.9313819, 21.1101822],
                [105.9307973, 21.1112159],
                [105.9295772, 21.1119846],
                [105.9269378, 21.1116239],
                [105.9266971, 21.1113905],
                [105.9249494, 21.112696],
                [105.9255362, 21.1142141],
                [105.9247103, 21.1147518],
                [105.9235052, 21.1154767],
                [105.9221826, 21.1163392],
                [105.9213633, 21.1167354],
                [105.9217056, 21.1173347],
                [105.9226817, 21.1188459],
                [105.924059, 21.1208225],
                [105.9232163, 21.1213132],
                [105.9223752, 21.1216109],
                [105.9219746, 21.1205702],
                [105.9215359, 21.1192693],
                [105.9156649, 21.1189679],
                [105.9155875, 21.1200299],
                [105.915387, 21.122021],
                [105.9147176, 21.1219985],
                [105.9146509, 21.1227404],
                [105.9129209, 21.126243],
                [105.9142591, 21.1272432],
                [105.9151601, 21.1264496],
                [105.916976, 21.1276122],
                [105.9178601, 21.1284322],
                [105.9193209, 21.1299088],
                [105.9179923, 21.1320401],
                [105.919134, 21.1329331],
                [105.9204802, 21.1340674],
                [105.9210763, 21.1345697],
                [105.9218729, 21.1352409],
                [105.9235423, 21.1330378],
                [105.9244842, 21.133109],
                [105.924278, 21.1341068],
                [105.9252718, 21.1345],
                [105.9255653, 21.1361263],
                [105.9238471, 21.1381543],
                [105.9265485, 21.1405062],
                [105.9255655, 21.1418737],
                [105.9264197, 21.1425666],
                [105.9264255, 21.1434968],
                [105.9264315, 21.1444602],
                [105.9254443, 21.1451633],
                [105.9260417, 21.1465439],
                [105.9241713, 21.1472417],
                [105.9232171, 21.1475101],
                [105.9226728, 21.1493644],
                [105.9218455, 21.1520738],
                [105.9212078, 21.1529935],
                [105.9206813, 21.1537604],
                [105.919698, 21.1550947],
                [105.920196, 21.1565826],
                [105.9205999, 21.1577807],
                [105.9211585, 21.1595367],
                [105.9219848, 21.160461],
                [105.9232096, 21.1617199],
                [105.9226992, 21.162937],
                [105.9206923, 21.1625935],
                [105.9199024, 21.1624445],
                [105.9194533, 21.1628448],
                [105.9188598, 21.1642345],
                [105.9184353, 21.1652134],
                [105.9182076, 21.1656468],
                [105.9180654, 21.1658265],
                [105.9179412, 21.1659834],
                [105.9177016, 21.1663076],
                [105.9176216, 21.1665445],
                [105.9175814, 21.1670059],
                [105.9175145, 21.1675172],
                [105.9173146, 21.1681532],
                [105.9173675, 21.1685899],
                [105.9173837, 21.1688333],
                [105.9174338, 21.1691262],
                [105.9173968, 21.1692872],
                [105.91734, 21.1697808],
                [105.9173235, 21.1713509],
                [105.9173774, 21.1715959],
                [105.9183831, 21.1735282],
                [105.9214185, 21.1738061],
                [105.9212708, 21.1753032],
                [105.9212287, 21.1758314],
                [105.9213114, 21.1760677],
                [105.9215509, 21.1763029],
                [105.9219236, 21.1765777],
                [105.9204792, 21.1779399],
                [105.9206775, 21.178305],
                [105.9212697, 21.1793506],
                [105.9236434, 21.182551],
                [105.9237736, 21.1827191],
                [105.9185903, 21.1850938],
                [105.9156305, 21.1872982],
                [105.9155103, 21.1875431],
                [105.9155681, 21.1879231],
                [105.915941, 21.1888971],
                [105.9135688, 21.1901855],
                [105.9136556, 21.1915586],
                [105.9071964, 21.1922871],
                [105.9071524, 21.1931146],
                [105.9069468, 21.1937044],
                [105.9066119, 21.1945402],
                [105.9070701, 21.1967699],
                [105.908232, 21.1985789],
                [105.9095131, 21.2003652],
                [105.9099454, 21.2007283],
                [105.9102863, 21.2008736],
                [105.9109936, 21.2010415],
                [105.9112905, 21.2011423],
                [105.9116025, 21.2013281],
                [105.9118933, 21.2016597],
                [105.912139, 21.2018875],
                [105.9124734, 21.2020939],
                [105.9130075, 21.2022785],
                [105.9134307, 21.2024845],
                [105.9136538, 21.2026603],
                [105.9138102, 21.2028051],
                [105.9138555, 21.2029507],
                [105.9138674, 21.2030756],
                [105.9138016, 21.2032113],
                [105.9136911, 21.2033161],
                [105.9134037, 21.2035258],
                [105.9125737, 21.2039677],
                [105.9117992, 21.2044301],
                [105.9113573, 21.2047865],
                [105.9110593, 21.20509],
                [105.9107836, 21.205404],
                [105.9105086, 21.205822],
                [105.9103881, 21.2060933],
                [105.9102463, 21.2065106],
                [105.9102263, 21.2068647],
                [105.9103289, 21.2073015],
                [105.9105081, 21.2075505],
                [105.9107533, 21.2076949],
                [105.9110095, 21.2078185],
                [105.911443, 21.2079098],
                [105.9121654, 21.2079997],
                [105.9128765, 21.2080582],
                [105.913698, 21.208033],
                [105.9142416, 21.2079468],
                [105.9145633, 21.2079034],
                [105.9148186, 21.2078916],
                [105.9150407, 21.2078904],
                [105.9152409, 21.2079414],
                [105.9153968, 21.2080134],
                [105.9156086, 21.2081581],
                [105.9157764, 21.2083446],
                [105.9158777, 21.2085731],
                [105.915873, 21.2096144],
                [105.9158505, 21.2113535],
                [105.9157807, 21.2126451],
                [105.9157245, 21.2143322],
                [105.9156957, 21.2150717],
                [105.9156338, 21.2158217],
                [105.9155162, 21.2165617],
                [105.9153314, 21.2172083],
                [105.9150679, 21.2176991],
                [105.9146615, 21.2184406],
                [105.9142214, 21.2191094],
                [105.9139566, 21.2193816],
                [105.9137579, 21.2195701],
                [105.9133599, 21.2198638],
                [105.9129175, 21.2201474],
                [105.9126192, 21.2204092],
                [105.9123434, 21.2207023],
                [105.9121122, 21.2210264],
                [105.911959, 21.2214021],
                [105.9117967, 21.2220902],
                [105.9115813, 21.2231847],
                [105.9114286, 21.2236436],
                [105.911165, 21.2241241],
                [105.9108673, 21.2244694],
                [105.910635, 21.2246163],
                [105.9102803, 21.2247224],
                [105.9100027, 21.2247447],
                [105.9097361, 21.2247254],
                [105.9095026, 21.2246641],
                [105.9092462, 21.2245093],
                [105.9088891, 21.2242301],
                [105.9080066, 21.2233706],
                [105.9073587, 21.2227597],
                [105.9069796, 21.2225223],
                [105.9064232, 21.2223378],
                [105.9059564, 21.2222778],
                [105.9055123, 21.2222907],
                [105.905315, 21.2223462],
                [105.9051354, 21.2223967],
                [105.9047925, 21.2226173],
                [105.9046105, 21.2228547],
                [105.904528, 21.2229623],
                [105.9043308, 21.2234007],
                [105.9042121, 21.2239636],
                [105.9041498, 21.224672],
                [105.9040968, 21.2251796],
                [105.9041628, 21.2258883],
                [105.9043863, 21.2271268],
                [105.9057541, 21.22735],
                [105.9061444, 21.2274689],
                [105.9059912, 21.2279368],
                [105.906766, 21.2282525],
                [105.9069292, 21.228329],
                [105.9070617, 21.2284342],
                [105.9068405, 21.2288332],
                [105.9066535, 21.2291257],
                [105.9065544, 21.229272],
                [105.9062027, 21.2298778],
                [105.9061266, 21.2301489],
                [105.9058774, 21.2305541],
                [105.9054793, 21.2309647],
                [105.9063255, 21.2314811],
                [105.9061928, 21.2317389],
                [105.9066937, 21.2320722],
                [105.9089973, 21.2310187],
                [105.9097888, 21.2315194],
                [105.9099826, 21.2321136],
                [105.9105805, 21.2320201],
                [105.9117433, 21.2319327],
                [105.9135249, 21.2322333],
                [105.9159124, 21.2321787],
                [105.9163445, 21.2320202],
                [105.9167537, 21.2317471],
                [105.9171632, 21.2315263],
                [105.9176509, 21.2313571],
                [105.9183054, 21.2312389],
                [105.918849, 21.2311318],
                [105.9191376, 21.2311199],
                [105.9194487, 21.231139],
                [105.9197609, 21.2313248],
                [105.9199073, 21.231446],
                [105.9200734, 21.2315834],
                [105.9203525, 21.2318213],
                [105.9210455, 21.2323082],
                [105.9210483, 21.2327534],
                [105.9210284, 21.2335735],
                [105.9210327, 21.2342529],
                [105.9212782, 21.2346317],
                [105.9213354, 21.2347199],
                [105.9223634, 21.2352765],
                [105.9234683, 21.2361373],
                [105.9249227, 21.2369494],
                [105.9256243, 21.23725],
                [105.9269535, 21.2380159],
                [105.9276069, 21.2385981],
                [105.9278589, 21.2389481],
                [105.9281113, 21.2393451],
                [105.9281454, 21.2393983],
                [105.9282143, 21.2395103],
                [105.9282404, 21.2397069],
                [105.9282535, 21.2398297],
                [105.9282403, 21.2400018],
                [105.9281352, 21.2402842],
                [105.9290396, 21.2408372],
                [105.9292497, 21.2404811],
                [105.9300229, 21.2410219],
                [105.9299438, 21.2420536],
                [105.9305599, 21.2423362],
                [105.930717, 21.2429258],
                [105.9309792, 21.2429137],
                [105.9316087, 21.2426806],
                [105.9321332, 21.2427422],
                [105.9322516, 21.2418825],
                [105.9323173, 21.2414773],
                [105.9340872, 21.2419815],
                [105.9341133, 21.2423131],
                [105.9339835, 21.2433879],
                [105.9336706, 21.2451481],
                [105.9339961, 21.2452399],
                [105.9347209, 21.2452594],
                [105.9353956, 21.2452556],
                [105.9359448, 21.2451589],
                [105.9363187, 21.2449928],
                [105.936617, 21.2447569],
                [105.9368903, 21.2444976],
                [105.9371883, 21.2441914],
                [105.9374609, 21.2438384],
                [105.9378728, 21.2432584],
                [105.9388364, 21.2446153],
                [105.9391316, 21.245394],
                [105.9392127, 21.2459741],
                [105.9392168, 21.2466093],
                [105.9390457, 21.2471184],
                [105.9386435, 21.2477921],
                [105.9381257, 21.2485571],
                [105.9376727, 21.2490137],
                [105.9375103, 21.2491775],
                [105.9369136, 21.2497253],
                [105.9362984, 21.2504],
                [105.9359908, 21.2507284],
                [105.9338022, 21.2536076],
                [105.9334836, 21.2541307],
                [105.9321162, 21.2563754],
                [105.9308341, 21.2587414],
                [105.9297852, 21.2612697],
                [105.9289222, 21.2625629],
                [105.9286273, 21.2629147],
                [105.927654, 21.264076],
                [105.9254629, 21.2665922],
                [105.9246756, 21.2676491],
                [105.9239032, 21.2688191],
                [105.9238701, 21.2688693],
                [105.923761, 21.2690537],
                [105.9231805, 21.2700345],
                [105.9230108, 21.2707613],
                [105.9229008, 21.2717418],
                [105.9225623, 21.2764617],
                [105.9225546, 21.2779016],
                [105.9225537, 21.2780775],
                [105.9225533, 21.2781314],
                [105.9226808, 21.2830302],
                [105.9229727, 21.2863858],
                [105.9231326, 21.2871833],
                [105.9234655, 21.2877803],
                [105.9242832, 21.2885017],
                [105.9259368, 21.2897446],
                [105.9269863, 21.290374],
                [105.9280147, 21.2907313],
                [105.9289647, 21.2909257],
                [105.9297978, 21.2910118],
                [105.9302246, 21.2911365],
                [105.9306713, 21.2913517],
                [105.931158, 21.2917665],
                [105.9315093, 21.2922001],
                [105.931804, 21.292888],
                [105.9318462, 21.2934503],
                [105.9317335, 21.2939953],
                [105.931523, 21.2943776],
                [105.9311186, 21.2947426],
                [105.9305009, 21.2950365],
                [105.929708, 21.295186],
                [105.928856, 21.2951907],
                [105.9279454, 21.295105],
                [105.9270725, 21.2948558],
                [105.9251128, 21.2942133],
                [105.9241438, 21.2940735],
                [105.9229992, 21.2939891],
                [105.9227683, 21.2939721],
                [105.9197479, 21.2939887],
                [105.9184516, 21.2941591],
                [105.9174658, 21.2944366],
                [105.9168871, 21.2947846],
                [105.9164443, 21.2952044],
                [105.9161567, 21.2956595],
                [105.9160238, 21.2960958],
                [105.915932, 21.2968948],
                [105.9161337, 21.2982002],
                [105.9171408, 21.3013523],
                [105.9177919, 21.3033085],
                [105.9179519, 21.3041242],
                [105.9179545, 21.3045416],
                [105.9178211, 21.3048871],
                [105.9176866, 21.3050693],
                [105.9174563, 21.3053972],
                [105.9169933, 21.305672],
                [105.9165286, 21.3056745],
                [105.9160436, 21.305532],
                [105.9153636, 21.3051727],
                [105.9141782, 21.3044895],
                [105.9117668, 21.3028332],
                [105.9106785, 21.302204],
                [105.9097661, 21.3018278],
                [105.9090678, 21.3016501],
                [105.908138, 21.3015826],
                [105.9072863, 21.3016416],
                [105.9063577, 21.3017918],
                [105.9052748, 21.3020335],
                [105.9027826, 21.3029723],
                [105.9009698, 21.3041979],
                [105.8984846, 21.3062981],
                [105.8980039, 21.3068632],
                [105.8977356, 21.3073182],
                [105.8975445, 21.3077367],
                [105.8974881, 21.3080274],
                [105.8973572, 21.3087901],
                [105.8973806, 21.3094434],
                [105.8975418, 21.3104767],
                [105.8977778, 21.3110744],
                [105.8989983, 21.3143342],
                [105.8992364, 21.3152585],
                [105.8992802, 21.3160929],
                [105.8992089, 21.3171096],
                [105.8991591, 21.3177439],
                [105.8991468, 21.3179003],
                [105.8990651, 21.3189432],
                [105.8990326, 21.3199777],
                [105.8990783, 21.3211025],
                [105.8992974, 21.3220995],
                [105.8995541, 21.3229147],
                [105.8996944, 21.3236942],
                [105.8996982, 21.3243293],
                [105.8995165, 21.3247597],
                [105.8993003, 21.3252336],
                [105.8987263, 21.3259384],
                [105.8984702, 21.3264164],
                [105.8981323, 21.3267545],
                [105.8964403, 21.3267856],
                [105.8923021, 21.3261864],
                [105.8885759, 21.3264833],
                [105.8843473, 21.3273734],
                [105.8760444, 21.327771],
                [105.8739196, 21.3284278],
                [105.8727475, 21.3289624],
                [105.8721112, 21.329465],
                [105.8713963, 21.3297772],
                [105.8711253, 21.3297968],
                [105.8709701, 21.3297613],
                [105.8707563, 21.3296172],
                [105.8705225, 21.3293825],
                [105.8702491, 21.3290029],
                [105.8698378, 21.3282247],
                [105.8695443, 21.3277362],
                [105.8692523, 21.3274655],
                [105.8689221, 21.3273039],
                [105.868664, 21.3272449],
                [105.8685342, 21.3272152],
                [105.8683213, 21.3272526],
                [105.8678964, 21.3274363],
                [105.8675296, 21.3276378],
                [105.8670669, 21.327985],
                [105.8668367, 21.3283854],
                [105.8664155, 21.3292223],
                [105.8661685, 21.3300402],
                [105.8660191, 21.3309665],
                [105.8660061, 21.3320553],
                [105.8659535, 21.3332227],
                [105.8659334, 21.3332819],
                [105.8657237, 21.3336076],
                [105.8655047, 21.3339874],
                [105.8650843, 21.3344585],
                [105.8644816, 21.3350116],
                [105.8635058, 21.3359093],
                [105.863286, 21.3361448],
                [105.8631719, 21.3363709],
                [105.8631444, 21.3365964],
                [105.8632133, 21.3368665],
                [105.8631767, 21.3371914],
                [105.8631783, 21.3374528],
                [105.8631504, 21.3376333],
                [105.8630169, 21.3378414],
                [105.8629025, 21.3380043],
                [105.8627016, 21.3381767],
                [105.862356, 21.3382956],
                [105.8618554, 21.3382169],
                [105.8615568, 21.3381463],
                [105.8611909, 21.3380671],
                [105.8606706, 21.3379165],
                [105.8600547, 21.3378385],
                [105.8597755, 21.3378039],
                [105.8596122, 21.3378498],
                [105.8593147, 21.3379505],
                [105.8589499, 21.3380695],
                [105.8584802, 21.3383335],
                [105.8580593, 21.3387323],
                [105.8578307, 21.3391302],
                [105.8577069, 21.3393473],
                [105.8576739, 21.3394302],
                [105.8576027, 21.3396092],
                [105.857509, 21.3400245],
                [105.8574437, 21.3404035],
                [105.8573396, 21.3406837],
                [105.8571682, 21.3409821],
                [105.8569007, 21.341308],
                [105.8564705, 21.3417339],
                [105.856107, 21.3420875],
                [105.8558782, 21.3424493],
                [105.8558029, 21.3427382],
                [105.8557557, 21.3428917],
                [105.8557481, 21.3432254],
                [105.8557414, 21.3437303],
                [105.8557726, 21.3441269],
                [105.8558811, 21.3446043],
                [105.8561632, 21.3451438],
                [105.85628, 21.3453866],
                [105.8563192, 21.3455037],
                [105.8563967, 21.3455935],
                [105.8565027, 21.345647],
                [105.8565991, 21.3456736],
                [105.8567529, 21.3456548],
                [105.8569159, 21.3455638],
                [105.8571552, 21.3453552],
                [105.8576053, 21.3450193],
                [105.8580659, 21.3448545],
                [105.8583351, 21.3448262],
                [105.8585947, 21.3448158],
                [105.858768, 21.344833],
                [105.8589217, 21.3448052],
                [105.8590275, 21.3448045],
                [105.8591721, 21.3448669],
                [105.8592307, 21.345002],
                [105.8592604, 21.3451461],
                [105.8592231, 21.3453536],
                [105.8590995, 21.3455977],
                [105.8588509, 21.3458605],
                [105.8581419, 21.3463419],
                [105.8573076, 21.34677],
                [105.8565499, 21.3471435],
                [105.8560993, 21.3473893],
                [105.8553692, 21.3475553],
                [105.8545143, 21.347749],
                [105.8539474, 21.34786],
                [105.8532471, 21.3481883],
                [105.853017, 21.3483156],
                [105.8527969, 21.3485061],
                [105.8526156, 21.3487775],
                [105.8525205, 21.3489583],
                [105.8524829, 21.3491028],
                [105.8524548, 21.3492472],
                [105.8524579, 21.3497702],
                [105.8525191, 21.350383],
                [105.852579, 21.3507524],
                [105.8527923, 21.3510489],
                [105.8530822, 21.3512727],
                [105.8535258, 21.351487],
                [105.8541145, 21.3518086],
                [105.8544329, 21.3519693],
                [105.8546458, 21.3521845],
                [105.854715, 21.3525269],
                [105.8546212, 21.352933],
                [105.8544025, 21.3533849],
                [105.8541637, 21.3536568],
                [105.8539438, 21.3539012],
                [105.8536569, 21.3541913],
                [105.853322, 21.3544815],
                [105.8530536, 21.3546541],
                [105.8526891, 21.3548364],
                [105.8522284, 21.355019],
                [105.8519598, 21.3551286],
                [105.8517492, 21.35531],
                [105.8515872, 21.3555723],
                [105.8515701, 21.3559331],
                [105.8515733, 21.3565011],
                [105.8516543, 21.3572039],
                [105.8516559, 21.3574835],
                [105.8517245, 21.3576905],
                [105.8521313, 21.3581934],
                [105.8526333, 21.3585065],
                [105.8528845, 21.3586765],
                [105.8530296, 21.358811],
                [105.8531169, 21.3589548],
                [105.8531564, 21.3591349],
                [105.8531484, 21.3593875],
                [105.8529509, 21.360191],
                [105.8527908, 21.3607778],
                [105.8527446, 21.3611027],
                [105.8527563, 21.3614633],
                [105.8527581, 21.3617879],
                [105.8527796, 21.3621575],
                [105.8529462, 21.3626976],
                [105.8532866, 21.363336],
                [105.8535771, 21.3636772],
                [105.8538981, 21.3642708],
                [105.8541521, 21.3649547],
                [105.8546694, 21.3658063],
                [105.8556666, 21.3665061],
                [105.8577598, 21.3699495],
                [105.8575697, 21.3703562],
                [105.8575146, 21.3708163],
                [105.8575088, 21.3714746],
                [105.8575099, 21.3716819],
                [105.8576171, 21.3719068],
                [105.8578596, 21.3722482],
                [105.8583826, 21.3728497],
                [105.8585975, 21.3734076],
                [105.8587062, 21.3738849],
                [105.8587374, 21.3742815],
                [105.8587383, 21.3744618],
                [105.8586627, 21.3746876],
                [105.8582908, 21.3752486],
                [105.8571313, 21.3760479],
                [105.8564891, 21.376457],
                [105.8559509, 21.3765499],
                [105.8555854, 21.3765517],
                [105.8546306, 21.3761508],
                [105.8530104, 21.3754739],
                [105.8521233, 21.3751266],
                [105.850948, 21.3748441],
                [105.8500229, 21.3745783],
                [105.8496372, 21.3744179],
                [105.8494638, 21.3743918],
                [105.8493484, 21.3744014],
                [105.849185, 21.3744203],
                [105.8490507, 21.374475],
                [105.8488686, 21.3746022],
                [105.8485722, 21.3749283],
                [105.848258, 21.3754888],
                [105.8478876, 21.3763294],
                [105.8473348, 21.3772338],
                [105.8471059, 21.3775776],
                [105.8467782, 21.3774709],
                [105.8466048, 21.3774449],
                [105.846422, 21.3774278],
                [105.8461622, 21.37742],
                [105.8453537, 21.377361],
                [105.8448623, 21.3772282],
                [105.8445925, 21.3771574],
                [105.8444094, 21.3771042],
                [105.8442069, 21.377024],
                [105.8440623, 21.3769708],
                [105.8439177, 21.3769264],
                [105.8437639, 21.3769362],
                [105.8435716, 21.3769552],
                [105.8431964, 21.3769661],
                [105.8427638, 21.3770133],
                [105.8412157, 21.3771744],
                [105.8407347, 21.3771678],
                [105.8399849, 21.3772707],
                [105.839572, 21.377426],
                [105.8394085, 21.3774269],
                [105.839187, 21.377392],
                [105.8387344, 21.377304],
                [105.8381168, 21.3769826],
                [105.8375475, 21.3766789],
                [105.8371126, 21.3763294],
                [105.8369175, 21.3758614],
                [105.8368772, 21.3755461],
                [105.8366133, 21.3748171],
                [105.836322, 21.3743406],
                [105.8357328, 21.3739197],
                [105.8353859, 21.3738223],
                [105.8351067, 21.3737785],
                [105.8347986, 21.373735],
                [105.8346265, 21.3737359],
                [105.8338369, 21.3734231],
                [105.8331758, 21.3732134],
                [105.8324544, 21.3731244],
                [105.8320422, 21.3731562],
                [105.8318188, 21.3732362],
                [105.8316896, 21.3734773],
                [105.8316722, 21.3736863],
                [105.8316806, 21.373863],
                [105.8317319, 21.3740721],
                [105.8317586, 21.374306],
                [105.8317332, 21.3743544],
                [105.8316371, 21.3743728],
                [105.8315793, 21.3743731],
                [105.8315023, 21.3743555],
                [105.8314253, 21.3743559],
                [105.8313387, 21.3743653],
                [105.8312621, 21.3744108],
                [105.8311471, 21.3744925],
                [105.8310416, 21.3745562],
                [105.8308977, 21.3746109],
                [105.8306765, 21.3746481],
                [105.8305424, 21.3747389],
                [105.8305333, 21.3748382],
                [105.8306886, 21.3750809],
                [105.8307283, 21.3752971],
                [105.8307002, 21.3754144],
                [105.830614, 21.375514],
                [105.8304896, 21.3756229],
                [105.8304228, 21.3757133],
                [105.8303365, 21.3757768],
                [105.8302596, 21.3757683],
                [105.8301531, 21.3756606],
                [105.8300851, 21.3755346],
                [105.829911, 21.3753733],
                [105.8296899, 21.3754104],
                [105.8295848, 21.3755371],
                [105.8293359, 21.3757457],
                [105.8290783, 21.3761257],
                [105.828973, 21.3762254],
                [105.828848, 21.3762531],
                [105.8286362, 21.3762181],
                [105.8281645, 21.3761663],
                [105.827953, 21.3761943],
                [105.8277609, 21.3762495],
                [105.8276266, 21.3763222],
                [105.8273774, 21.3764858],
                [105.8271758, 21.3765589],
                [105.826926, 21.3766323],
                [105.826705, 21.3766604],
                [105.8264454, 21.3766978],
                [105.8262242, 21.3767169],
                [105.8260034, 21.3767991],
                [105.8256962, 21.3769088],
                [105.8255039, 21.3769278],
                [105.8253887, 21.3769825],
                [105.8252545, 21.3770643],
                [105.8250432, 21.3771374],
                [105.8248226, 21.3772378],
                [105.8245922, 21.3773291],
                [105.8244771, 21.3774017],
                [105.8242759, 21.377547],
                [105.8239884, 21.3777468],
                [105.8238159, 21.3778738],
                [105.8236342, 21.3780821],
                [105.8233662, 21.3783269],
                [105.8230686, 21.3784365],
                [105.8229148, 21.3784554],
                [105.8227219, 21.3783662],
                [105.822587, 21.3783217],
                [105.8224516, 21.3781961],
                [105.8222779, 21.3781159],
                [105.8221624, 21.3780893],
                [105.8220949, 21.3780716],
                [105.8220084, 21.3780721],
                [105.821787, 21.3780551],
                [105.8216327, 21.3779928],
                [105.8215463, 21.3780292],
                [105.8214697, 21.3780927],
                [105.8213561, 21.3784359],
                [105.8213104, 21.3788509],
                [105.8213024, 21.3791575],
                [105.821188, 21.3793385],
                [105.8208715, 21.3795022],
                [105.8205833, 21.3795939],
                [105.8202569, 21.3797217],
                [105.8198829, 21.3799309],
                [105.8195084, 21.3800679],
                [105.8190388, 21.3803858],
                [105.8186744, 21.380604],
                [105.8184736, 21.3808485],
                [105.8183217, 21.3812008],
                [105.8182163, 21.3812735],
                [105.8180048, 21.3812925],
                [105.8178512, 21.3813655],
                [105.8177365, 21.3815013],
                [105.8176025, 21.3816372],
                [105.8174396, 21.3817552],
                [105.8171226, 21.3818468],
                [105.8169597, 21.3819469],
                [105.816797, 21.3821009],
                [105.8167111, 21.3822365],
                [105.8167222, 21.38248],
                [105.8166365, 21.3826697],
                [105.8163871, 21.3828063],
                [105.8161283, 21.3829699],
                [105.815946, 21.3830519],
                [105.8156865, 21.3831163],
                [105.81535, 21.3831538],
                [105.8151098, 21.3832182],
                [105.8148503, 21.3832554],
                [105.8145225, 21.3831489],
                [105.8142527, 21.3830601],
                [105.8137994, 21.3828549],
                [105.8136737, 21.3827383],
                [105.813576, 21.3824683],
                [105.8135164, 21.3821349],
                [105.8135139, 21.3816931],
                [105.8135225, 21.3814856],
                [105.8135506, 21.3813593],
                [105.8135503, 21.3812872],
                [105.813521, 21.3812332],
                [105.813415, 21.3811976],
                [105.8132321, 21.3811625],
                [105.8130778, 21.3811181],
                [105.8129333, 21.3810828],
                [105.8127988, 21.3811014],
                [105.812626, 21.3811835],
                [105.8121758, 21.3815373],
                [105.81184, 21.3817103],
                [105.8115134, 21.381793],
                [105.8108979, 21.3818591],
                [105.8103306, 21.3819158],
                [105.8099755, 21.3820709],
                [105.8091595, 21.3823904],
                [105.8086784, 21.3825377],
                [105.8081125, 21.382711],
                [105.8074901, 21.382929],
                [105.80719, 21.3829049],
                [105.8069203, 21.3828521],
                [105.8061218, 21.3828558],
                [105.805862, 21.3828481],
                [105.8056502, 21.382822],
                [105.8054766, 21.3827508],
                [105.8053608, 21.3826792],
                [105.8052067, 21.3826619],
                [105.805072, 21.3826626],
                [105.8049376, 21.3827082],
                [105.804765, 21.3828174],
                [105.8045734, 21.3829895],
                [105.8042765, 21.3832164],
                [105.8041425, 21.3833342],
                [105.8040179, 21.3834341],
                [105.8038359, 21.3835881],
                [105.8037211, 21.3837059],
                [105.8037027, 21.3838413],
                [105.8037514, 21.3839673],
                [105.8039072, 21.3843182],
                [105.8038789, 21.3844085],
                [105.8038024, 21.38449],
                [105.8036584, 21.3845448],
                [105.8033989, 21.3846001],
                [105.80311, 21.3845475],
                [105.8028021, 21.3845489],
                [105.8024847, 21.3845594],
                [105.8022155, 21.3845968],
                [105.8020233, 21.3846608],
                [105.8018604, 21.3847517],
                [105.8017072, 21.3848967],
                [105.8015444, 21.3850418],
                [105.8013433, 21.3852141],
                [105.8012378, 21.3852777],
                [105.8010162, 21.3852246],
                [105.8007845, 21.3850815],
                [105.8004553, 21.3846864],
                [105.7999121, 21.3838865],
                [105.7996109, 21.3833379],
                [105.7994456, 21.383014],
                [105.7994635, 21.3827705],
                [105.7995012, 21.3826171],
                [105.799592, 21.3824434],
                [105.7991894, 21.3819843],
                [105.7989125, 21.3816819],
                [105.7987841, 21.3815444],
                [105.7987349, 21.3815355],
                [105.7986957, 21.381554],
                [105.7986075, 21.3816189],
                [105.7985294, 21.3817021],
                [105.798422, 21.3818223],
                [105.7982655, 21.3819427],
                [105.7981186, 21.3820446],
                [105.7979422, 21.3821283],
                [105.7977852, 21.3821659],
                [105.7976968, 21.3821663],
                [105.7976182, 21.3821943],
                [105.7974517, 21.3822872],
                [105.7973147, 21.382389],
                [105.7971284, 21.3824635],
                [105.7969026, 21.3825014],
                [105.796824, 21.3825018],
                [105.7967746, 21.3824468],
                [105.796774, 21.3823456],
                [105.7968031, 21.3822718],
                [105.7969009, 21.3821792],
                [105.7970182, 21.3820591],
                [105.7970863, 21.3819483],
                [105.7970761, 21.3818747],
                [105.7970561, 21.3818288],
                [105.7970068, 21.381783],
                [105.796928, 21.3817558],
                [105.7968297, 21.381747],
                [105.7967705, 21.3817013],
                [105.7967603, 21.3816369],
                [105.7967599, 21.381554],
                [105.7967788, 21.3813976],
                [105.7967977, 21.3812686],
                [105.7967477, 21.3811215],
                [105.7966391, 21.3810116],
                [105.7965898, 21.3809842],
                [105.796521, 21.3809846],
                [105.796413, 21.3810035],
                [105.7962759, 21.3810686],
                [105.7961484, 21.3811244],
                [105.7960304, 21.381125],
                [105.795942, 21.3811162],
                [105.7957847, 21.3811169],
                [105.7957259, 21.3811449],
                [105.795618, 21.3811729],
                [105.7955198, 21.3811734],
                [105.7954312, 21.3811554],
                [105.7953621, 21.3811005],
                [105.795243, 21.380871],
                [105.7948733, 21.3797407],
                [105.7947243, 21.3794377],
                [105.7946155, 21.3793277],
                [105.7945366, 21.3792636],
                [105.7944578, 21.3792365],
                [105.7943301, 21.3792186],
                [105.7941631, 21.3792286],
                [105.793795, 21.3793101],
                [105.7935417, 21.3793245],
                [105.7931943, 21.3793263],
                [105.7930305, 21.3792887],
                [105.7928565, 21.3792321],
                [105.7927439, 21.3792039],
                [105.7926209, 21.3791471],
                [105.7925286, 21.3790805],
                [105.7924771, 21.3790137],
                [105.7924459, 21.3789086],
                [105.7923327, 21.3787559],
                [105.7921375, 21.3785941],
                [105.7919733, 21.3784512],
                [105.791881, 21.3783943],
                [105.791748, 21.3783662],
                [105.7916151, 21.3783573],
                [105.7914717, 21.3782909],
                [105.7912358, 21.3781388],
                [105.7910001, 21.3780252],
                [105.7908363, 21.3779589],
                [105.7907031, 21.3779021],
                [105.7906212, 21.3778738],
                [105.7905191, 21.3778742],
                [105.7904782, 21.3778936],
                [105.7904738, 21.3779061],
                [105.7904433, 21.3778721],
                [105.7904134, 21.3778169],
                [105.7903735, 21.3776971],
                [105.7903581, 21.3775865],
                [105.7903472, 21.3773835],
                [105.7903366, 21.3772543],
                [105.7902868, 21.3771346],
                [105.7901878, 21.3770429],
                [105.790089, 21.3769694],
                [105.78997, 21.3768131],
                [105.7898999, 21.376592],
                [105.7898203, 21.3764446],
                [105.7897016, 21.376353],
                [105.7896225, 21.376298],
                [105.7895633, 21.3762705],
                [105.7894155, 21.3762528],
                [105.7893171, 21.3762533],
                [105.7892481, 21.3762444],
                [105.7891593, 21.3762078],
                [105.7890702, 21.3761159],
                [105.7890108, 21.3760608],
                [105.7889512, 21.3759688],
                [105.7888818, 21.3758862],
                [105.788793, 21.3758497],
                [105.7886944, 21.3758132],
                [105.7885859, 21.375786],
                [105.7884874, 21.375768],
                [105.7883589, 21.3756763],
                [105.7882348, 21.3755017],
                [105.7879969, 21.3752074],
                [105.7878576, 21.3749312],
                [105.7878202, 21.3748407],
                [105.7882928, 21.3743382],
                [105.7891664, 21.373785],
                [105.7901334, 21.3732611],
                [105.7900086, 21.3732421],
                [105.7898297, 21.3732043],
                [105.7894927, 21.3731428],
                [105.7892897, 21.3729723],
                [105.7888937, 21.3726857],
                [105.7887081, 21.3724153],
                [105.7884606, 21.3719719],
                [105.7883063, 21.371755],
                [105.7881714, 21.3716463],
                [105.7879977, 21.3715557],
                [105.7876888, 21.371447],
                [105.7874959, 21.3713383],
                [105.7873802, 21.3712296],
                [105.7873032, 21.3710669],
                [105.7873422, 21.370814],
                [105.7875746, 21.3703987],
                [105.7876136, 21.3700916],
                [105.7875752, 21.369947],
                [105.7874018, 21.3696575],
                [105.7870162, 21.3692415],
                [105.7869971, 21.3690788],
                [105.7870565, 21.3681212],
                [105.7870674, 21.3671635],
                [105.786972, 21.3664405],
                [105.7868762, 21.3658622],
                [105.7867811, 21.3648682],
                [105.7865804, 21.3635127],
                [105.7864075, 21.3628619],
                [105.7860035, 21.3618676],
                [105.7854741, 21.3607465],
                [105.7850503, 21.3600593],
                [105.784819, 21.3597698],
                [105.7847533, 21.359663],
                [105.7846913, 21.3595622],
                [105.7848088, 21.3594827],
                [105.7851225, 21.3592351],
                [105.7853838, 21.3589549],
                [105.7855747, 21.3586421],
                [105.7859225, 21.3581808],
                [105.7862708, 21.3578346],
                [105.7864094, 21.3575384],
                [105.7865654, 21.3572423],
                [105.7865608, 21.3563724],
                [105.7865243, 21.3560935],
                [105.7864689, 21.3555685],
                [105.7864312, 21.3550763],
                [105.7863417, 21.3546827],
                [105.7864269, 21.354245],
                [105.7865375, 21.3538631],
                [105.7871291, 21.3531335],
                [105.7884984, 21.3519861],
                [105.7890905, 21.351534],
                [105.7893489, 21.3510131],
                [105.7894592, 21.3505271],
                [105.7901988, 21.3496238],
                [105.7919747, 21.3479551],
                [105.7926037, 21.3474335],
                [105.792812, 21.3467959],
                [105.7928986, 21.3465309],
                [105.7916727, 21.3451799],
                [105.7909672, 21.3445912],
                [105.790626, 21.3441777],
                [105.7905956, 21.3441408],
                [105.789706, 21.3440382],
                [105.7887792, 21.3440051],
                [105.7873709, 21.344077],
                [105.7868139, 21.3435921],
                [105.7860716, 21.3430382],
                [105.7857377, 21.3429],
                [105.7849963, 21.3428252],
                [105.7844909, 21.3428256],
                [105.7843002, 21.3428257],
                [105.7841735, 21.3428258],
                [105.7840198, 21.3428266],
                [105.7837221, 21.3428114],
                [105.7834771, 21.342829],
                [105.783267, 21.34283],
                [105.7830916, 21.3427816],
                [105.7829158, 21.3426512],
                [105.7828097, 21.3424546],
                [105.7823511, 21.3417873],
                [105.7820583, 21.3415802],
                [105.7816998, 21.3411631],
                [105.7814361, 21.3409509],
                [105.78119, 21.3407552],
                [105.7810138, 21.3405426],
                [105.7809422, 21.3402638],
                [105.7807887, 21.3400045],
                [105.7807656, 21.3399693],
                [105.7805193, 21.3397571],
                [105.780238, 21.3395121],
                [105.7799381, 21.3390867],
                [105.7796924, 21.3389894],
                [105.7791309, 21.3387622],
                [105.7786196, 21.3385181],
                [105.7779726, 21.338226],
                [105.777773, 21.338131],
                [105.7772635, 21.3378782],
                [105.7771474, 21.337803],
                [105.7766901, 21.3374112],
                [105.7762485, 21.3366582],
                [105.7762625, 21.3360016],
                [105.7761908, 21.3356737],
                [105.7760843, 21.3353951],
                [105.7759079, 21.3351333],
                [105.7758622, 21.3350072],
                [105.775632, 21.3348519],
                [105.7753183, 21.3345575],
                [105.7751074, 21.3342715],
                [105.7750214, 21.3341548],
                [105.7749867, 21.3340706],
                [105.7749439, 21.3340265],
                [105.7748238, 21.3336749],
                [105.774763, 21.333397],
                [105.7747254, 21.3332107],
                [105.7746271, 21.3326071],
                [105.774533, 21.3323687],
                [105.7740716, 21.331851],
                [105.7739368, 21.3313797],
                [105.7737919, 21.3309431],
                [105.7737257, 21.3306554],
                [105.7735944, 21.3303075],
                [105.77335, 21.3299451],
                [105.7731381, 21.3296278],
                [105.7729745, 21.3292801],
                [105.7727941, 21.328796],
                [105.772744, 21.3284932],
                [105.772774, 21.3280386],
                [105.7728339, 21.3271139],
                [105.7727988, 21.326614],
                [105.7726666, 21.3260541],
                [105.7724705, 21.325661],
                [105.7717866, 21.3247701],
                [105.7715846, 21.3245282],
                [105.7711361, 21.324061],
                [105.7708264, 21.3236078],
                [105.7705963, 21.3228968],
                [105.7701415, 21.3220781],
                [105.7695123, 21.3210979],
                [105.7687041, 21.3197537],
                [105.7679551, 21.3187571],
                [105.7674674, 21.3182594],
                [105.7668833, 21.3178984],
                [105.7662508, 21.3175376],
                [105.7657796, 21.3171154],
                [105.7646907, 21.3161049],
                [105.7645285, 21.3159544],
                [105.7637787, 21.3150299],
                [105.7635351, 21.3147794],
                [105.7632269, 21.3144148],
                [105.7625269, 21.3134937],
                [105.7621017, 21.3125713],
                [105.7613166, 21.3108475],
                [105.7608079, 21.3093953],
                [105.7604616, 21.3080936],
                [105.7603617, 21.3075335],
                [105.7570863, 21.3006003],
                [105.7570473, 21.3005247],
                [105.7569113, 21.3003984],
                [105.7568201, 21.3001875],
                [105.7567291, 21.30004],
                [105.7565485, 21.2999985],
                [105.7563005, 21.2999997],
                [105.7559395, 21.2999591],
                [105.7557586, 21.2998753],
                [105.7555323, 21.2997073],
                [105.7552372, 21.2993283],
                [105.7546917, 21.2984853],
                [105.754487, 21.2981483],
                [105.7543054, 21.2979166],
                [105.7540336, 21.2976641],
                [105.7537847, 21.2974962],
                [105.7534681, 21.2973286],
                [105.7532644, 21.2971816],
                [105.7531736, 21.2970553],
                [105.7531501, 21.2968651],
                [105.7532167, 21.2966746],
                [105.7533273, 21.2962515],
                [105.7535053, 21.2957647],
                [105.7536163, 21.295426],
                [105.7536154, 21.295278],
                [105.7535246, 21.2951516],
                [105.7531858, 21.2950264],
                [105.7527798, 21.2950071],
                [105.7524642, 21.2950086],
                [105.7519687, 21.2951587],
                [105.7518828, 21.295189],
                [105.7503328, 21.2957361],
                [105.7498946, 21.2959394],
                [105.7496182, 21.2960923],
                [105.7493641, 21.2960935],
                [105.7491324, 21.2959428],
                [105.7488314, 21.2958142],
                [105.7485993, 21.295577],
                [105.7483197, 21.29508],
                [105.7481796, 21.2947991],
                [105.7478782, 21.2945621],
                [105.747392, 21.2943261],
                [105.7472074, 21.2943701],
                [105.7470694, 21.294479],
                [105.7469837, 21.2947383],
                [105.7465156, 21.2945682],
                [105.7461208, 21.2941584],
                [105.7457071, 21.2945501],
                [105.7453996, 21.2931219],
                [105.7453059, 21.2928622],
                [105.7452584, 21.2926026],
                [105.7453023, 21.2921475],
                [105.7453015, 21.2919742],
                [105.7452315, 21.2918445],
                [105.7450234, 21.2918021],
                [105.7448386, 21.2918029],
                [105.7444937, 21.2920861],
                [105.7436199, 21.2928698],
                [105.7433315, 21.2929252],
                [105.7429492, 21.2926994],
                [105.7425078, 21.2921598],
                [105.7423911, 21.2919437],
                [105.7423905, 21.2917922],
                [105.7427114, 21.2913142],
                [105.7429395, 21.2907499],
                [105.7428805, 21.2905012],
                [105.7427873, 21.2903282],
                [105.7426021, 21.2902424],
                [105.7423944, 21.2902866],
                [105.7415666, 21.291005],
                [105.7413126, 21.2910279],
                [105.7410583, 21.290964],
                [105.7407796, 21.2906621],
                [105.7406629, 21.2904243],
                [105.740662, 21.2902294],
                [105.7407304, 21.2900557],
                [105.7409144, 21.2899033],
                [105.7410759, 21.289881],
                [105.7412838, 21.28988],
                [105.7415836, 21.289792],
                [105.7419057, 21.2895523],
                [105.7422278, 21.289291],
                [105.7423881, 21.2890303],
                [105.7425002, 21.2884674],
                [105.7424691, 21.288352],
                [105.7424048, 21.2880009],
                [105.7422616, 21.2876485],
                [105.7419555, 21.2873031],
                [105.7416504, 21.2872025],
                [105.74102, 21.2872257],
                [105.7401514, 21.287515],
                [105.7398691, 21.2875979],
                [105.7397604, 21.2876188],
                [105.7396514, 21.287558],
                [105.7397569, 21.2869049],
                [105.7397341, 21.286701],
                [105.7395596, 21.2865795],
                [105.7393635, 21.2865191],
                [105.738929, 21.2866026],
                [105.7388212, 21.2867866],
                [105.7387785, 21.28695],
                [105.7388468, 21.2875616],
                [105.7388041, 21.2877249],
                [105.7386525, 21.2878683],
                [105.7384354, 21.2879305],
                [105.7381308, 21.2879114],
                [105.7366045, 21.2871227],
                [105.7363428, 21.2869607],
                [105.7362767, 21.2867977],
                [105.7362759, 21.2866142],
                [105.7364481, 21.2862872],
                [105.7367502, 21.2857964],
                [105.7367711, 21.2856331],
                [105.7366397, 21.2854296],
                [105.7362905, 21.2851865],
                [105.7360498, 21.284902],
                [105.7357657, 21.2846177],
                [105.7352641, 21.2843344],
                [105.735067, 21.2840497],
                [105.7349996, 21.2836216],
                [105.7351056, 21.2830909],
                [105.7352139, 21.2827875],
                [105.7354811, 21.2820388],
                [105.7357385, 21.2813239],
                [105.7359733, 21.2804255],
                [105.7359709, 21.2799768],
                [105.7358597, 21.2794674],
                [105.7355762, 21.2793055],
                [105.7350539, 21.2792465],
                [105.7337718, 21.2794357],
                [105.7335758, 21.2793754],
                [105.733488, 21.2791922],
                [105.7333715, 21.2776426],
                [105.7329422, 21.2765942],
                [105.7327859, 21.2757586],
                [105.7327195, 21.2755141],
                [105.7326093, 21.2752291],
                [105.7324563, 21.2750666],
                [105.7324069, 21.2750462],
                [105.7322602, 21.2749858],
                [105.7315647, 21.2750705],
                [105.7307404, 21.275482],
                [105.7298716, 21.2756897],
                [105.7294806, 21.2757933],
                [105.7292415, 21.2757944],
                [105.7290454, 21.2757136],
                [105.7290011, 21.2755507],
                [105.728891, 21.2752656],
                [105.7289762, 21.2749185],
                [105.7289963, 21.2745717],
                [105.7289735, 21.2743679],
                [105.7288416, 21.2740829],
                [105.7285347, 21.2735744],
                [105.7278578, 21.2730062],
                [105.7275203, 21.2729159],
                [105.727016, 21.2731306],
                [105.7268909, 21.2731837],
                [105.7262178, 21.2733905],
                [105.7258047, 21.2733923],
                [105.7256085, 21.2732912],
                [105.7254336, 21.2730881],
                [105.7252801, 21.2728439],
                [105.7252789, 21.2725991],
                [105.7252992, 21.2723135],
                [105.7254281, 21.2719867],
                [105.7257958, 21.2715773],
                [105.7262725, 21.2712489],
                [105.7271194, 21.27098],
                [105.7277049, 21.2706716],
                [105.7281387, 21.2704249],
                [105.7285509, 21.2702396],
                [105.7290934, 21.2700129],
                [105.7293098, 21.269808],
                [105.7293956, 21.2695629],
                [105.7292635, 21.2692371],
                [105.7289777, 21.2685857],
                [105.7286507, 21.2684443],
                [105.7284219, 21.2683331],
                [105.7277474, 21.2682545],
                [105.7273771, 21.2681133],
                [105.7270061, 21.2678497],
                [105.7265472, 21.2673827],
                [105.7260228, 21.2668954],
                [105.7254775, 21.2665306],
                [105.7250851, 21.2663283],
                [105.7246061, 21.266208],
                [105.7244755, 21.2661678],
                [105.7231924, 21.2661326],
                [105.7233155, 21.2646432],
                [105.723501, 21.2625416],
                [105.723596, 21.26196],
                [105.7239641, 21.2616524],
                [105.7241343, 21.2608766],
                [105.7245146, 21.2586111],
                [105.7246835, 21.2575906],
                [105.7243586, 21.2556034],
                [105.7242139, 21.2542656],
                [105.7241535, 21.2537075],
                [105.7240192, 21.2529125],
                [105.7232872, 21.2514475],
                [105.7233396, 21.2512807],
                [105.723483, 21.2511802],
                [105.7245928, 21.2510982],
                [105.7254164, 21.250999],
                [105.7263477, 21.2507154],
                [105.7271898, 21.2502642],
                [105.7280293, 21.2497701],
                [105.728558, 21.2495992],
                [105.7288614, 21.2493734],
                [105.7290774, 21.2490869],
                [105.7291008, 21.2490155],
                [105.7291845, 21.2487602],
                [105.7292029, 21.2480666],
                [105.7290132, 21.2470987],
                [105.7286615, 21.2463252],
                [105.7278282, 21.2448603],
                [105.7271486, 21.243721],
                [105.7270384, 21.243436],
                [105.7266347, 21.2431217],
                [105.7264338, 21.2430406],
                [105.7255404, 21.2429522],
                [105.7253288, 21.2428417],
                [105.7251975, 21.2426588],
                [105.7251643, 21.2423537],
                [105.7253302, 21.2420707],
                [105.7257863, 21.2417559],
                [105.7274124, 21.24091],
                [105.7277646, 21.2408686],
                [105.7284279, 21.2408091],
                [105.7292333, 21.2410096],
                [105.7305624, 21.241575],
                [105.7314989, 21.2419176],
                [105.732261, 21.2421591],
                [105.7331088, 21.2421554],
                [105.7339778, 21.2420088],
                [105.7346938, 21.2417201],
                [105.735192, 21.2413304],
                [105.7356571, 21.24089],
                [105.7360026, 21.2403989],
                [105.7362608, 21.2398676],
                [105.7364305, 21.2390101],
                [105.7368397, 21.2382334],
                [105.7372928, 21.2375176],
                [105.7375498, 21.2367413],
                [105.7375564, 21.2364095],
                [105.7375585, 21.2363028],
                [105.7375264, 21.2361426],
                [105.7374466, 21.2360087],
                [105.7371643, 21.2357335],
                [105.7368374, 21.2355718],
                [105.7363801, 21.2354106],
                [105.7352275, 21.2353544],
                [105.7343368, 21.2354807],
                [105.7336213, 21.2358509],
                [105.732626, 21.2368342],
                [105.7317603, 21.237613],
                [105.7309158, 21.2383101],
                [105.730482, 21.238516],
                [105.7302211, 21.238517],
                [105.7301554, 21.2384154],
                [105.7300894, 21.2382526],
                [105.7300667, 21.2380487],
                [105.7301303, 21.2377425],
                [105.7302379, 21.2375176],
                [105.731341, 21.2363503],
                [105.731903, 21.2356952],
                [105.7323333, 21.2347959],
                [105.7328076, 21.2339985],
                [105.7330239, 21.2337732],
                [105.7341087, 21.2333198],
                [105.7351257, 21.2329919],
                [105.7321552, 21.2310333],
                [105.7317905, 21.2315227],
                [105.7248464, 21.2257856],
                [105.7242632, 21.225358],
                [105.7218741, 21.2279219],
                [105.7210811, 21.2271556],
                [105.7210481, 21.226941],
                [105.721342, 21.2264811],
                [105.7214071, 21.2262358],
                [105.7212103, 21.2259603],
                [105.7207844, 21.2257157],
                [105.72062, 21.2252256],
                [105.7203902, 21.2247663],
                [105.7202911, 21.2242148],
                [105.7201595, 21.2237553],
                [105.7199952, 21.2232654],
                [105.7197328, 21.2229286],
                [105.7193725, 21.2227452],
                [105.7189469, 21.2226539],
                [105.7183249, 21.222624],
                [105.717703, 21.2226555],
                [105.7172452, 21.2229624],
                [105.7168202, 21.2233001],
                [105.7163941, 21.2234837],
                [105.7158172, 21.2240453],
                [105.7154145, 21.2244898],
                [105.7136129, 21.2225699],
                [105.7133174, 21.2224789],
                [105.7123847, 21.2223937],
                [105.7115342, 21.2225717],
                [105.7112032, 21.2224173],
                [105.7104225, 21.221821],
                [105.7109415, 21.2212231],
                [105.7107539, 21.220983],
                [105.7092081, 21.2198519],
                [105.7086177, 21.2197314],
                [105.707669, 21.2201044],
                [105.707113, 21.2203527],
                [105.7036247, 21.2212516],
                [105.7034074, 21.2213152],
                [105.7026302, 21.2196743],
                [105.7016499, 21.2184918],
                [105.7006778, 21.2201411],
                [105.7003718, 21.2200564],
                [105.6990936, 21.2199357],
                [105.6986593, 21.2198915],
                [105.6986068, 21.2192525],
                [105.6985601, 21.2189548],
                [105.6983168, 21.2187473],
                [105.6977721, 21.2186645],
                [105.6975445, 21.2184952],
                [105.6977625, 21.2166217],
                [105.6980268, 21.2149608],
                [105.698688, 21.2134459],
                [105.6987898, 21.2132128],
                [105.6986976, 21.2129152],
                [105.6982549, 21.2124787],
                [105.6973805, 21.2134149],
                [105.6968357, 21.2139577],
                [105.6961774, 21.2149067],
                [105.6958045, 21.2152596],
                [105.6952894, 21.2159916],
                [105.6943488, 21.2181852],
                [105.6937937, 21.2196067],
                [105.693052, 21.2212047],
                [105.6930127, 21.2212784],
                [105.6915401, 21.221215],
                [105.6907333, 21.2202463],
                [105.6904262, 21.2200671],
                [105.6901195, 21.220247],
                [105.6899663, 21.2204986],
                [105.6898221, 21.2210207],
                [105.689369, 21.2208906],
                [105.6890078, 21.2208229],
                [105.6888543, 21.2208949],
                [105.6888163, 21.2211104],
                [105.6888551, 21.2215053],
                [105.6889411, 21.2218075],
                [105.6884725, 21.222224],
                [105.6883191, 21.222332],
                [105.688082, 21.2224068],
                [105.687974, 21.222476],
                [105.6877917, 21.2225539],
                [105.6872596, 21.2236062],
                [105.6864809, 21.2248838],
                [105.6858298, 21.2260221],
                [105.6857156, 21.226429],
                [105.6856012, 21.2270038],
                [105.6856037, 21.2289431],
                [105.6847982, 21.2290876],
                [105.6849143, 21.2299494],
                [105.6845729, 21.2298819],
                [105.6841923, 21.2298066],
                [105.6833755, 21.2297673],
                [105.6827868, 21.2299825],
                [105.6824704, 21.2302391],
                [105.6824272, 21.2307075],
                [105.6824745, 21.2311328],
                [105.6829303, 21.2315992],
                [105.6833847, 21.2317675],
                [105.6856983, 21.2317582],
                [105.6874226, 21.2318362],
                [105.6878774, 21.2320897],
                [105.6883784, 21.2325133],
                [105.6888814, 21.2333624],
                [105.6888824, 21.2335752],
                [105.6888384, 21.2338733],
                [105.6884305, 21.2342167],
                [105.6882511, 21.2344133],
                [105.6882126, 21.2344545],
                [105.6872998, 21.234949],
                [105.6866145, 21.235197],
                [105.6856999, 21.2353235],
                [105.6841965, 21.2353601],
                [105.6829869, 21.2353037],
                [105.6823326, 21.2351838],
                [105.6816606, 21.2347419],
                [105.6809069, 21.2343156],
                [105.680545, 21.2338264],
                [105.6801815, 21.2329692],
                [105.6795817, 21.2304572],
                [105.6790856, 21.2291712],
                [105.6781976, 21.2279789],
                [105.6771152, 21.2271553],
                [105.6765587, 21.2269429],
                [105.6761665, 21.2269444],
                [105.675742, 21.2270381],
                [105.6745693, 21.2279014],
                [105.672712, 21.2291661],
                [105.6715722, 21.2300599],
                [105.6712793, 21.2303677],
                [105.6712151, 21.2306133],
                [105.6714124, 21.2308885],
                [105.6721993, 21.2314067],
                [105.6733141, 21.2321995],
                [105.6744623, 21.2331149],
                [105.6749877, 21.2336648],
                [105.6751859, 21.2340932],
                [105.675253, 21.2344916],
                [105.6752224, 21.2349518],
                [105.6750288, 21.2355044],
                [105.6746395, 21.23615],
                [105.6742485, 21.2364274],
                [105.6739545, 21.2364594],
                [105.6736281, 21.2363666],
                [105.673529, 21.2363384],
                [105.6732337, 21.2360943],
                [105.6731483, 21.23592],
                [105.6729039, 21.235421],
                [105.6723084, 21.2338594],
                [105.6718813, 21.2333705],
                [105.6717164, 21.233287],
                [105.6711606, 21.2330053],
                [105.6705061, 21.2328547],
                [105.6691856, 21.233041],
                [105.6690526, 21.2330598],
                [105.668596, 21.2332762],
                [105.6683026, 21.2334614],
                [105.6682716, 21.2338295],
                [105.6683057, 21.234136],
                [105.6685077, 21.2354537],
                [105.6683804, 21.2362209],
                [105.6679945, 21.237633],
                [105.6673805, 21.2391993],
                [105.6672362, 21.2393662],
                [105.6670879, 21.2395377],
                [105.6667615, 21.239631],
                [105.6663359, 21.2394794],
                [105.6661038, 21.2392303],
                [105.6659089, 21.2390211],
                [105.6654962, 21.2384386],
                [105.6650207, 21.2377674],
                [105.6643881, 21.2367867],
                [105.6641008, 21.2367285],
                [105.6626903, 21.2355994],
                [105.6625513, 21.2354092],
                [105.6619689, 21.235081],
                [105.6611507, 21.2348389],
                [105.6610471, 21.2348211],
                [105.6601039, 21.2346589],
                [105.6587311, 21.2346642],
                [105.6576621, 21.2346932],
                [105.6567538, 21.2347179],
                [105.6560029, 21.2349049],
                [105.6552199, 21.2352452],
                [105.6547315, 21.2356765],
                [105.654341, 21.2360459],
                [105.6539506, 21.2364767],
                [105.6534311, 21.2372761],
                [105.6527805, 21.2379839],
                [105.6524217, 21.2381386],
                [105.6514738, 21.2381423],
                [105.6506231, 21.2379615],
                [105.6493133, 21.2374147],
                [105.6476102, 21.2366545],
                [105.6462992, 21.2358316],
                [105.6446601, 21.234734],
                [105.6443472, 21.2341678],
                [105.6442359, 21.2338462],
                [105.6440824, 21.2334022],
                [105.644078, 21.2323903],
                [105.6442005, 21.2319872],
                [105.6444325, 21.2312238],
                [105.6447861, 21.2298731],
                [105.6448776, 21.2283702],
                [105.6452649, 21.2272342],
                [105.6453831, 21.2270902],
                [105.6454924, 21.2269572],
                [105.6459334, 21.2269097],
                [105.6472402, 21.2267513],
                [105.6486466, 21.2269912],
                [105.6491836, 21.2269639],
                [105.6493002, 21.226958],
                [105.6499195, 21.2265876],
                [105.650701, 21.22591],
                [105.6512884, 21.2256931],
                [105.651746, 21.225722],
                [105.6523689, 21.2261489],
                [105.6534857, 21.2274019],
                [105.6539115, 21.2276149],
                [105.654369, 21.2275824],
                [105.6549228, 21.2271816],
                [105.6563867, 21.225612],
                [105.6580948, 21.2238728],
                [105.6586475, 21.223196],
                [105.6588417, 21.2227659],
                [105.6589046, 21.2222138],
                [105.6587706, 21.2214784],
                [105.6585726, 21.2210498],
                [105.657981, 21.2203161],
                [105.6570955, 21.2196143],
                [105.6557193, 21.2188224],
                [105.6531659, 21.217851],
                [105.6508098, 21.2171855],
                [105.6487972, 21.2165952],
                [105.6472269, 21.2162334],
                [105.6466705, 21.2160514],
                [105.6453607, 21.2154431],
                [105.644184, 21.215417],
                [105.6431394, 21.215697],
                [105.6418346, 21.2162539],
                [105.6406931, 21.2168102],
                [105.6392582, 21.2175516],
                [105.6382461, 21.2178007],
                [105.6372658, 21.2178352],
                [105.6365466, 21.2177765],
                [105.6359567, 21.2174108],
                [105.635628, 21.216952],
                [105.6355607, 21.2164607],
                [105.6357562, 21.2157379],
                [105.6358178, 21.2155101],
                [105.636353, 21.214542],
                [105.6372631, 21.2133734],
                [105.6390543, 21.2119254],
                [105.6392158, 21.2114648],
                [105.6391669, 21.2077239],
                [105.6391337, 21.2076013],
                [105.6389365, 21.2073568],
                [105.6385108, 21.2071438],
                [105.6378738, 21.2071922],
                [105.6363398, 21.207658],
                [105.6341849, 21.2081259],
                [105.6328939, 21.2084152],
                [105.6315404, 21.2087185],
                [105.6310455, 21.2089363],
                [105.630627, 21.2091205],
                [105.6301387, 21.2095823],
                [105.6291958, 21.2106898],
                [105.6288701, 21.210967],
                [105.6286096, 21.210968],
                [105.6282819, 21.2109692],
                [105.6276598, 21.2106956],
                [105.6262685, 21.2099193],
                [105.6257107, 21.2096081],
                [105.6255879, 21.2095314],
                [105.6237119, 21.2083591],
                [105.6234728, 21.2081367],
                [105.6227605, 21.207474],
                [105.6224593, 21.2070403],
                [105.6219428, 21.2063476],
                [105.621873, 21.2062514],
                [105.6210546, 21.2059172],
                [105.6202705, 21.2059507],
                [105.6193563, 21.2061686],
                [105.6189653, 21.2064153],
                [105.6186067, 21.2066617],
                [105.6175648, 21.2075849],
                [105.6168482, 21.2081391],
                [105.6164237, 21.2082326],
                [105.6161296, 21.2082338],
                [105.6157367, 21.2080513],
                [105.6154084, 21.2076848],
                [105.6151775, 21.2071953],
                [105.6151426, 21.2066438],
                [105.6152562, 21.2059265],
                [105.6158193, 21.2060515],
                [105.616017, 21.2059922],
                [105.6161519, 21.2058647],
                [105.6163053, 21.2051509],
                [105.6164707, 21.2038195],
                [105.6164996, 21.2029],
                [105.6164271, 21.2022346],
                [105.616623, 21.2021726],
                [105.6186849, 21.2018808],
                [105.6188853, 21.2017323],
                [105.6189259, 21.2015032],
                [105.6188032, 21.2011597],
                [105.6194148, 21.2009684],
                [105.619875, 21.2014931],
                [105.6202311, 21.2018459],
                [105.6223917, 21.2009662],
                [105.6222272, 21.19921],
                [105.6219011, 21.1976686],
                [105.6217985, 21.1965958],
                [105.6220613, 21.1958501],
                [105.6221014, 21.1951628],
                [105.6218972, 21.1947047],
                [105.6219681, 21.1945911],
                [105.6220186, 21.1945101],
                [105.6236502, 21.1940442],
                [105.6241712, 21.1936438],
                [105.6243489, 21.1931679],
                [105.6245081, 21.1921862],
                [105.6245556, 21.1917994],
                [105.6246661, 21.1908979],
                [105.6248256, 21.1899776],
                [105.6247918, 21.1897323],
                [105.6246919, 21.1892728],
                [105.624654, 21.188016],
                [105.624913, 21.1874631],
                [105.6253354, 21.1869096],
                [105.6258196, 21.1864516],
                [105.6259048, 21.186371],
                [105.6274369, 21.1855682],
                [105.628871, 21.1847657],
                [105.6298155, 21.1840877],
                [105.6289631, 21.1833856],
                [105.6287985, 21.183049],
                [105.6286631, 21.1819458],
                [105.6284975, 21.1814252],
                [105.6283, 21.1810581],
                [105.6281686, 21.1809052],
                [105.6281021, 21.1808565],
                [105.6279487, 21.1808763],
                [105.6274472, 21.1810802],
                [105.6267308, 21.1813859],
                [105.6259422, 21.1816246],
                [105.6255834, 21.1816259],
                [105.62526, 21.1815261],
                [105.624828, 21.1811909],
                [105.6245389, 21.1807204],
                [105.6243571, 21.1801823],
                [105.6243914, 21.179778],
                [105.6245335, 21.1794407],
                [105.6250338, 21.1789337],
                [105.6260706, 21.1780205],
                [105.6265709, 21.1775134],
                [105.6268198, 21.1769737],
                [105.6268536, 21.1762565],
                [105.6266461, 21.1752571],
                [105.6263194, 21.1733562],
                [105.6258469, 21.1755333],
                [105.6220322, 21.1781864],
                [105.6173096, 21.1791248],
                [105.6089401, 21.1765423],
                [105.6049652, 21.1745186],
                [105.6023102, 21.1719646],
                [105.6000641, 21.1696631],
                [105.5951583, 21.1685041],
                [105.5903855, 21.1673751],
                [105.5902792, 21.1673499],
                [105.5901088, 21.1673278],
                [105.5840776, 21.1657556],
                [105.5818439, 21.1652608],
                [105.5797278, 21.1648763],
                [105.5775529, 21.1643264],
                [105.5763338, 21.1640525],
                [105.5759067, 21.1632253],
                [105.5759486, 21.1630547],
                [105.5763925, 21.1629715],
                [105.5750747, 21.1616276],
                [105.5736537, 21.1611292],
                [105.5734172, 21.1616838],
                [105.5731129, 21.161591],
                [105.5706938, 21.1608532],
                [105.5707526, 21.160188],
                [105.5690801, 21.1597313],
                [105.5671771, 21.159252],
                [105.5670285, 21.1592283],
                [105.5667213, 21.1591788],
                [105.5647673, 21.1588676],
                [105.5606531, 21.1582637],
                [105.5580083, 21.1578792],
                [105.5536712, 21.1574948],
                [105.5523094, 21.1570881],
                [105.5462537, 21.156837],
                [105.5429836, 21.1565946],
                [105.537304, 21.1561959],
                [105.5341467, 21.1559042],
                [105.5278885, 21.1555845],
                [105.5267469, 21.1556001],
                [105.5214269, 21.1557544],
                [105.5186449, 21.1560732],
                [105.5165294, 21.1562537],
                [105.5155663, 21.1564173],
                [105.5152901, 21.1564642],
                [105.5102776, 21.1572032],
                [105.5074991, 21.1575366],
                [105.5070458, 21.1575952],
                [105.506302, 21.1577305],
                [105.5046702, 21.1580176],
                [105.5040872, 21.1580918],
                [105.5030158, 21.1582282],
                [105.5018817, 21.1583726],
                [105.5000206, 21.158687],
                [105.4984913, 21.1589081],
                [105.4961935, 21.1595547],
                [105.4943997, 21.1600933],
                [105.492218, 21.1609418],
                [105.4870201, 21.1637921],
                [105.4865039, 21.1640577],
                [105.4836739, 21.1655141],
                [105.4821369, 21.1665155],
                [105.4766063, 21.1709383],
                [105.4748135, 21.1720491],
                [105.4740085, 21.1726708],
                [105.4720175, 21.1742086],
                [105.4698751, 21.1761782],
                [105.4695613, 21.1766479],
                [105.4694716, 21.1773742],
                [105.4693818, 21.1789103],
                [105.4695683, 21.1796168],
                [105.4699563, 21.1804871],
                [105.4699585, 21.1811717],
                [105.4698484, 21.1818564],
                [105.4691242, 21.1836487],
                [105.4686811, 21.1855454],
                [105.4683828, 21.1888634],
                [105.4666854, 21.2005525],
                [105.4652508, 21.2005702],
                [105.4649829, 21.2006059],
                [105.4647342, 21.2007492],
                [105.4644281, 21.2011431],
                [105.463988, 21.2017878],
                [105.4638541, 21.2020743],
                [105.46372, 21.2024862],
                [105.4635668, 21.2031488],
                [105.4641215, 21.2031667],
                [105.4643894, 21.2033281],
                [105.4643892, 21.2041339],
                [105.4640826, 21.2063189],
                [105.4639867, 21.2072144],
                [105.462246, 21.2070527],
                [105.4620928, 21.2077154],
                [105.4618248, 21.2087899],
                [105.462609, 21.2090228],
                [105.4625705, 21.2099004],
                [105.4623789, 21.2109212],
                [105.4616515, 21.2128195],
                [105.4615366, 21.2133746],
                [105.4616418, 21.213715],
                [105.4618999, 21.2139478],
                [105.4623781, 21.214145],
                [105.4620671, 21.2160498],
                [105.4611427, 21.2200548],
                [105.4607599, 21.2211651],
                [105.4603387, 21.2220604],
                [105.4602076, 21.222283],
                [105.4593052, 21.2238154],
                [105.4578027, 21.2261971],
                [105.4564893, 21.2282016],
                [105.4553733, 21.2305476],
                [105.4536154, 21.2341854],
                [105.4528889, 21.2353457],
                [105.4514907, 21.237245],
                [105.4513468, 21.2375536],
                [105.4507654, 21.2388002],
                [105.4502701, 21.2395016],
                [105.4492268, 21.2407261],
                [105.4481062, 21.2416242],
                [105.4474066, 21.2424158],
                [105.4468477, 21.2433124],
                [105.4460114, 21.2453681],
                [105.445567, 21.2470016],
                [105.4451517, 21.2490186],
                [105.4448011, 21.2509985],
                [105.4447492, 21.2512919],
                [105.4445627, 21.2523859],
                [105.4442189, 21.2552605],
                [105.4441717, 21.2563163],
                [105.4441046, 21.2578166],
                [105.4440035, 21.2602582],
                [105.4433957, 21.2644046],
                [105.4431678, 21.2663221],
                [105.4439745, 21.2700524],
                [105.440403, 21.2714997],
                [105.4326187, 21.2741139],
                [105.4270895, 21.2782561],
                [105.4261718, 21.2791347],
                [105.4252599, 21.2801997],
                [105.4211444, 21.2847771],
                [105.4202505, 21.285276],
                [105.4140927, 21.2930874],
                [105.4116546, 21.295162],
                [105.408549, 21.297329],
                [105.4066001, 21.2988004],
                [105.4060857, 21.2996708],
                [105.4011006, 21.3020395],
                [105.3967678, 21.3043122],
                [105.395805, 21.3045067],
                [105.3950882, 21.3043087],
                [105.3946561, 21.3034131],
                [105.3941897, 21.3029653],
                [105.3933598, 21.3024856],
                [105.3914981, 21.302438],
                [105.3904432, 21.3023104],
                [105.3895351, 21.3021833],
                [105.3888121, 21.3020469],
                [105.3881739, 21.3016703],
                [105.3870634, 21.3005795],
                [105.383325, 21.296907],
                [105.3831326, 21.2965047],
                [105.3828133, 21.2961757],
                [105.3821849, 21.2960385],
                [105.3795292, 21.2959651],
                [105.3781271, 21.2959312],
                [105.3775291, 21.2956744],
                [105.3771714, 21.2951936],
                [105.3771467, 21.2938334],
                [105.3763641, 21.293018],
                [105.3744664, 21.2922259],
                [105.3738131, 21.2917579],
                [105.3709845, 21.2902599],
                [105.3686708, 21.2891617],
                [105.3667557, 21.2880849],
                [105.3648481, 21.2868911],
                [105.3633675, 21.285815],
                [105.3620018, 21.2847086],
                [105.3605148, 21.2823372],
                [105.3585631, 21.280465],
                [105.3576025, 21.279577],
                [105.3562961, 21.2785341],
                [105.3562197, 21.2774656],
                [105.3561646, 21.2753153],
                [105.356392, 21.2724229],
                [105.3563452, 21.2682402],
                [105.3562619, 21.2607843],
                [105.3543555, 21.2555082],
                [105.3542497, 21.2547787],
                [105.354261, 21.2521624],
                [105.3539287, 21.2481823],
                [105.3531527, 21.2451188],
                [105.3527842, 21.2432614],
                [105.3521591, 21.2404528],
                [105.3514944, 21.2374964],
                [105.3498371, 21.230034],
                [105.3486331, 21.2263937],
                [105.3481789, 21.2253751],
                [105.3464557, 21.2244267],
                [105.3451799, 21.2219425],
                [105.3449818, 21.2215635],
                [105.344467, 21.220578],
                [105.3437539, 21.2191433],
                [105.3414228, 21.216372],
                [105.3384782, 21.2124656],
                [105.3379652, 21.211197],
                [105.3374958, 21.2100362],
                [105.3359021, 21.2083383],
                [105.3337215, 21.2068697],
                [105.3325197, 21.2056093],
                [105.3312299, 21.204758],
                [105.3268408, 21.2025857],
                [105.323487, 21.2018856],
                [105.3234496, 21.2019017],
                [105.3227973, 21.2017416],
                [105.3167577, 21.1986474],
                [105.3160147, 21.1982667],
                [105.3122152, 21.1960522],
                [105.3072426, 21.1935045],
                [105.3036905, 21.1915845],
                [105.3032963, 21.1897521],
                [105.2980039, 21.1792104],
                [105.2959469, 21.1752606],
                [105.295622, 21.1748674],
                [105.2932485, 21.1719729],
                [105.2922364, 21.1698565],
                [105.2908165, 21.1658811],
                [105.2905331, 21.1645247],
                [105.2889615, 21.1570007],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.1034678, 18.4476316],
                [105.1034693, 18.447354],
                [105.1035801, 18.4470074],
                [105.1038365, 18.4466962],
                [105.1041049, 18.4464792],
                [105.1042242, 18.4458287],
                [105.1058629, 18.4438489],
                [105.1063721, 18.4430245],
                [105.1064499, 18.4420504],
                [105.1065278, 18.4411139],
                [105.1066057, 18.4402523],
                [105.1071933, 18.4391657],
                [105.1080944, 18.4379664],
                [105.1089954, 18.4367297],
                [105.1098607, 18.4346824],
                [105.1113828, 18.4338467],
                [105.1126616, 18.4338265],
                [105.1139609, 18.4339228],
                [105.1150163, 18.4337669],
                [105.1174925, 18.4330277],
                [105.1182839, 18.4326389],
                [105.1186085, 18.4323475],
                [105.1188719, 18.4317067],
                [105.1188918, 18.4310466],
                [105.1187492, 18.4303478],
                [105.1185822, 18.4296581],
                [105.1185423, 18.428834],
                [105.1186662, 18.4276883],
                [105.1191438, 18.4261973],
                [105.119888, 18.4251104],
                [105.1207883, 18.4230496],
                [105.120788, 18.4224877],
                [105.1206714, 18.4213621],
                [105.1201226, 18.4203918],
                [105.1203048, 18.4197706],
                [105.1207104, 18.4191878],
                [105.1222323, 18.4185074],
                [105.1234096, 18.4184484],
                [105.1237342, 18.4181958],
                [105.1239016, 18.4177095],
                [105.1251949, 18.4170717],
                [105.1277821, 18.4164333],
                [105.1291539, 18.415758],
                [105.1296241, 18.4154955],
                [105.1300552, 18.4153828],
                [105.1303298, 18.4153077],
                [105.1305846, 18.4155136],
                [105.1310556, 18.4162625],
                [105.1314088, 18.4165619],
                [105.133687, 18.4162857],
                [105.1343706, 18.4159056],
                [105.134779, 18.4159075],
                [105.1352545, 18.4161369],
                [105.1357973, 18.4164965],
                [105.1363057, 18.4169209],
                [105.1370864, 18.4173789],
                [105.1374594, 18.4176402],
                [105.1376961, 18.417966],
                [105.1379991, 18.4186817],
                [105.1385402, 18.4193658],
                [105.1391171, 18.4197256],
                [105.1393891, 18.4197918],
                [105.1397293, 18.4198257],
                [105.140036, 18.4197622],
                [105.140207, 18.4196006],
                [105.1402764, 18.4193413],
                [105.1402102, 18.4189513],
                [105.1400773, 18.4183014],
                [105.1399098, 18.4177488],
                [105.1398774, 18.4174239],
                [105.1399151, 18.4166775],
                [105.1399871, 18.4158986],
                [105.1400228, 18.4155742],
                [105.1401276, 18.4150227],
                [105.1402664, 18.414504],
                [105.140407, 18.4135955],
                [105.1404767, 18.4132712],
                [105.1404775, 18.4131089],
                [105.1404445, 18.4128815],
                [105.1403099, 18.4125887],
                [105.1401642, 18.4122985],
                [105.1401505, 18.4122439],
                [105.1394363, 18.4120325],
                [105.1389528, 18.4120507],
                [105.1385265, 18.4121675],
                [105.137938, 18.4122455],
                [105.1372883, 18.4119354],
                [105.1369224, 18.4113144],
                [105.137206, 18.410557],
                [105.137388, 18.4097222],
                [105.1375906, 18.4091397],
                [105.1377932, 18.4086908],
                [105.1379105, 18.4082411],
                [105.1380279, 18.4080162],
                [105.1391646, 18.4076407],
                [105.1399485, 18.4074529],
                [105.1410409, 18.4072398],
                [105.1421383, 18.4070517],
                [105.1430485, 18.4068255],
                [105.1436572, 18.4066502],
                [105.1444686, 18.4062226],
                [105.1452799, 18.4053484],
                [105.1459491, 18.4046683],
                [105.1473485, 18.4035802],
                [105.1457634, 18.401213],
                [105.1453725, 18.4006123],
                [105.1460458, 18.3990578],
                [105.1468165, 18.3984361],
                [105.1467143, 18.3975237],
                [105.1467746, 18.396883],
                [105.1470581, 18.3961063],
                [105.1483562, 18.3950958],
                [105.1497744, 18.3923768],
                [105.1508293, 18.3918324],
                [105.1508485, 18.3905705],
                [105.1499949, 18.3892317],
                [105.1505627, 18.3886488],
                [105.1516985, 18.3878519],
                [105.1532412, 18.3881032],
                [105.1564356, 18.3876235],
                [105.1579684, 18.3865268],
                [105.159417, 18.3868211],
                [105.1594906, 18.3868361],
                [105.160911, 18.3865631],
                [105.162007, 18.3867563],
                [105.1640398, 18.3870177],
                [105.1652943, 18.3870916],
                [105.1658824, 18.387241],
                [105.1662745, 18.3874279],
                [105.1664317, 18.3877274],
                [105.1664712, 18.3880271],
                [105.1664714, 18.3882518],
                [105.1665112, 18.3888887],
                [105.1666697, 18.3906866],
                [105.166821, 18.391159],
                [105.1669635, 18.3916053],
                [105.1671684, 18.3920251],
                [105.1673646, 18.3920999],
                [105.1683169, 18.3915093],
                [105.1688655, 18.391284],
                [105.1700417, 18.3914702],
                [105.1709047, 18.3920314],
                [105.1712969, 18.3922183],
                [105.1716105, 18.3922181],
                [105.1727865, 18.3921421],
                [105.1735311, 18.3919541],
                [105.1741184, 18.3913167],
                [105.1746274, 18.3906045],
                [105.1753719, 18.390379],
                [105.1758422, 18.3902289],
                [105.176195, 18.390191],
                [105.1773731, 18.3898876],
                [105.1787116, 18.3889739],
                [105.1797325, 18.3901912],
                [105.1802949, 18.3893606],
                [105.1810149, 18.3885757],
                [105.18219, 18.387638],
                [105.1822683, 18.3875631],
                [105.1827776, 18.3872629],
                [105.1837964, 18.3869248],
                [105.1846188, 18.3862872],
                [105.1846578, 18.3859874],
                [105.1846576, 18.3858376],
                [105.184618, 18.385388],
                [105.1846173, 18.3847512],
                [105.184423, 18.3841702],
                [105.1842629, 18.3838422],
                [105.1841533, 18.3836188],
                [105.1839607, 18.3833248],
                [105.1837892, 18.3832317],
                [105.1830197, 18.3826101],
                [105.1823918, 18.3817959],
                [105.1821258, 18.3804495],
                [105.1819861, 18.3801087],
                [105.1817897, 18.3797343],
                [105.1816825, 18.3793482],
                [105.1815699, 18.378894],
                [105.1814802, 18.3783811],
                [105.1816882, 18.3764192],
                [105.1816875, 18.3757448],
                [105.1814911, 18.375333],
                [105.1809807, 18.3745468],
                [105.1796075, 18.373237],
                [105.1789795, 18.3724884],
                [105.178626, 18.371777],
                [105.1785473, 18.3714399],
                [105.1784687, 18.3711777],
                [105.1785468, 18.3709904],
                [105.1787423, 18.3705032],
                [105.1788205, 18.3702408],
                [105.1789377, 18.3699412],
                [105.1791333, 18.3694914],
                [105.1795639, 18.369004],
                [105.1798378, 18.3686666],
                [105.1805429, 18.3681415],
                [105.1812085, 18.367579],
                [105.1819919, 18.3669414],
                [105.1828281, 18.3654486],
                [105.1834001, 18.3642429],
                [105.1834383, 18.3634562],
                [105.183438, 18.363119],
                [105.1834768, 18.3618855],
                [105.1842066, 18.3612596],
                [105.1850903, 18.3610749],
                [105.1858206, 18.3609271],
                [105.1862173, 18.3598573],
                [105.1862562, 18.3595951],
                [105.186256, 18.3593702],
                [105.1857454, 18.3583968],
                [105.1857059, 18.3580597],
                [105.1856664, 18.3577975],
                [105.1857444, 18.3573479],
                [105.1859396, 18.3567484],
                [105.1862918, 18.356261],
                [105.1865658, 18.3558861],
                [105.187153, 18.3552113],
                [105.1883278, 18.3543111],
                [105.1885625, 18.3538988],
                [105.1888755, 18.353374],
                [105.1890316, 18.3528119],
                [105.1888728, 18.3510514],
                [105.1887218, 18.3500479],
                [105.1888734, 18.3489945],
                [105.1890473, 18.3484664],
                [105.1893665, 18.3479102],
                [105.1898308, 18.347048],
                [105.1901501, 18.3465753],
                [105.190266, 18.3461861],
                [105.1898295, 18.3457696],
                [105.1892764, 18.3452144],
                [105.188927, 18.3447144],
                [105.1883578, 18.3427833],
                [105.1878042, 18.3417832],
                [105.1876293, 18.341311],
                [105.1874834, 18.3408108],
                [105.1873957, 18.3404496],
                [105.187279, 18.3400328],
                [105.1872206, 18.3396994],
                [105.1871911, 18.3393936],
                [105.1871908, 18.3389767],
                [105.1870286, 18.3369063],
                [105.1867661, 18.336295],
                [105.186387, 18.3351836],
                [105.1862411, 18.3346835],
                [105.1860079, 18.3342113],
                [105.1857167, 18.3337114],
                [105.1853092, 18.3332115],
                [105.184449, 18.3309471],
                [105.1843322, 18.3305026],
                [105.184332, 18.3301968],
                [105.1844188, 18.329891],
                [105.1846219, 18.3294461],
                [105.184883, 18.3289734],
                [105.1849991, 18.3286676],
                [105.1850567, 18.3282784],
                [105.1850271, 18.3276947],
                [105.1843408, 18.3247911],
                [105.1847478, 18.324874],
                [105.1849513, 18.3248182],
                [105.1853582, 18.3247345],
                [105.1857069, 18.3244839],
                [105.1862589, 18.3241777],
                [105.1866948, 18.3239549],
                [105.1872468, 18.3235931],
                [105.188322, 18.3230917],
                [105.1890191, 18.3226464],
                [105.1903559, 18.3221448],
                [105.1908351, 18.3216301],
                [105.1912411, 18.3207124],
                [105.1914732, 18.3201842],
                [105.1916473, 18.3198783],
                [105.1918503, 18.3195168],
                [105.1919954, 18.3192943],
                [105.1921986, 18.3190161],
                [105.19246, 18.318738],
                [105.1927795, 18.3184596],
                [105.19307, 18.3183203],
                [105.1933315, 18.3181812],
                [105.193942, 18.3180972],
                [105.1946398, 18.3181243],
                [105.1954534, 18.3178177],
                [105.1960634, 18.3172613],
                [105.1962958, 18.3171498],
                [105.1971677, 18.3169265],
                [105.1976908, 18.3168149],
                [105.1983595, 18.3168141],
                [105.1987663, 18.3167026],
                [105.1993477, 18.3165352],
                [105.2002485, 18.3162007],
                [105.2017305, 18.3156989],
                [105.2024569, 18.3153368],
                [105.2029803, 18.3154752],
                [105.2035912, 18.3156691],
                [105.2039981, 18.3156687],
                [105.2043759, 18.3155293],
                [105.2046955, 18.3153622],
                [105.2056252, 18.3148331],
                [105.2063226, 18.3145823],
                [105.2067877, 18.3145818],
                [105.2072817, 18.3144979],
                [105.2072818, 18.3144979],
                [105.2078341, 18.3144416],
                [105.2085026, 18.314302],
                [105.2091996, 18.3137175],
                [105.2106516, 18.3124374],
                [105.2116237, 18.3109494],
                [105.2124658, 18.3101425],
                [105.2130176, 18.3096972],
                [105.2135981, 18.3089739],
                [105.214237, 18.3084452],
                [105.2151374, 18.3078048],
                [105.2156311, 18.3073596],
                [105.2158632, 18.3069146],
                [105.2168375, 18.3050516],
                [105.2172394, 18.3038192],
                [105.2175688, 18.3028905],
                [105.2185639, 18.3028983],
                [105.2194095, 18.3030812],
                [105.2202176, 18.3040549],
                [105.2206418, 18.3042271],
                [105.2214268, 18.304393],
                [105.2222407, 18.3044477],
                [105.2232291, 18.3043353],
                [105.2241299, 18.3041118],
                [105.2248843, 18.3029437],
                [105.225611, 18.3028872],
                [105.2258725, 18.3028591],
                [105.2271806, 18.3028576],
                [105.2278492, 18.3028291],
                [105.2308725, 18.3028253],
                [105.2313081, 18.3025191],
                [105.2324395, 18.3007946],
                [105.2324974, 18.3005999],
                [105.232846, 18.3004605],
                [105.2329913, 18.3004325],
                [105.2334852, 18.3002374],
                [105.23395, 18.2999589],
                [105.2342403, 18.2997083],
                [105.2345887, 18.2993467],
                [105.2349081, 18.2990684],
                [105.2353435, 18.2985953],
                [105.2361566, 18.2979829],
                [105.2365051, 18.2977879],
                [105.2369991, 18.2976483],
                [105.2372897, 18.2975924],
                [105.2376094, 18.297592],
                [105.2381618, 18.2975356],
                [105.2392078, 18.2973399],
                [105.2396147, 18.2972282],
                [105.2400215, 18.2971442],
                [105.2410387, 18.2969484],
                [105.2416779, 18.2967808],
                [105.242143, 18.2967247],
                [105.2426952, 18.2966406],
                [105.2430727, 18.29639],
                [105.2435073, 18.2954166],
                [105.244029, 18.2942765],
                [105.2447828, 18.2929136],
                [105.2450142, 18.2920518],
                [105.2453174, 18.29062],
                [105.2456352, 18.2893689],
                [105.245809, 18.2888405],
                [105.2460118, 18.2883956],
                [105.2462727, 18.2878951],
                [105.2465338, 18.2875334],
                [105.2467371, 18.2873386],
                [105.2469691, 18.2870047],
                [105.2475787, 18.286448],
                [105.2478925, 18.2861142],
                [105.2482671, 18.2853481],
                [105.2487237, 18.284237],
                [105.2488971, 18.2835419],
                [105.2493626, 18.283014],
                [105.2498229, 18.2827059],
                [105.2507953, 18.2817985],
                [105.251142, 18.2810331],
                [105.2514888, 18.2803427],
                [105.251792, 18.2796025],
                [105.2518487, 18.2795128],
                [105.2520228, 18.279095],
                [105.2522057, 18.2786853],
                [105.2524386, 18.2775446],
                [105.2528251, 18.2769787],
                [105.2532525, 18.2764712],
                [105.2534147, 18.2759251],
                [105.2534752, 18.2754767],
                [105.2535358, 18.2750867],
                [105.2539529, 18.274589],
                [105.2542993, 18.2744132],
                [105.2550332, 18.2744122],
                [105.2552982, 18.2743533],
                [105.2556239, 18.2740605],
                [105.2557866, 18.2737483],
                [105.2561118, 18.2730851],
                [105.2563554, 18.272383],
                [105.2564559, 18.2714665],
                [105.2568319, 18.270696],
                [105.2573408, 18.2701884],
                [105.2577683, 18.2697785],
                [105.2579309, 18.2694273],
                [105.258012, 18.2691348],
                [105.2580725, 18.2687449],
                [105.2581125, 18.2682184],
                [105.2581119, 18.2677507],
                [105.258345, 18.2668633],
                [105.2579977, 18.2663373],
                [105.2573238, 18.2655586],
                [105.2564822, 18.265059],
                [105.2560944, 18.2647281],
                [105.2559521, 18.264477],
                [105.2559356, 18.2641567],
                [105.2559044, 18.2637182],
                [105.2559239, 18.2631917],
                [105.2559636, 18.2624119],
                [105.2560441, 18.2617294],
                [105.256043, 18.2609692],
                [105.256022, 18.2605403],
                [105.2560162, 18.2594204],
                [105.2557791, 18.2576439],
                [105.2553742, 18.2557933],
                [105.2553515, 18.2550763],
                [105.2554437, 18.2549484],
                [105.2560797, 18.2550827],
                [105.2580451, 18.2564854],
                [105.259262, 18.2573087],
                [105.2614534, 18.2582181],
                [105.2622658, 18.2581494],
                [105.262874, 18.2581914],
                [105.2638904, 18.2579444],
                [105.2645927, 18.257924],
                [105.2647693, 18.25789],
                [105.2657625, 18.2579419],
                [105.2671608, 18.2579237],
                [105.2672601, 18.2579224],
                [105.2678925, 18.2582334],
                [105.2685661, 18.2587978],
                [105.2692193, 18.2593623],
                [105.2697294, 18.2597125],
                [105.2700964, 18.2598094],
                [105.2707487, 18.259789],
                [105.2712582, 18.2596713],
                [105.271686, 18.2595342],
                [105.2723381, 18.2593773],
                [105.2728575, 18.2590939],
                [105.2731216, 18.2585477],
                [105.2733242, 18.2577871],
                [105.2734863, 18.257124],
                [105.2736078, 18.256578],
                [105.2737497, 18.2561295],
                [105.2739326, 18.2557587],
                [105.2745121, 18.2548709],
                [105.2748173, 18.2545195],
                [105.2753469, 18.2542069],
                [105.2756931, 18.2540699],
                [105.2761618, 18.2539328],
                [105.2765694, 18.2539127],
                [105.2770587, 18.25399],
                [105.2776096, 18.2542621],
                [105.2781402, 18.2546707],
                [105.2786709, 18.2551378],
                [105.2793446, 18.2557997],
                [105.2801715, 18.256627],
                [105.2806819, 18.2570942],
                [105.2810495, 18.257503],
                [105.2811722, 18.2577953],
                [105.2811323, 18.2582631],
                [105.2808073, 18.2590239],
                [105.2807674, 18.2595113],
                [105.2807679, 18.2598622],
                [105.2808498, 18.2601546],
                [105.2810745, 18.2604466],
                [105.2814113, 18.2607094],
                [105.2817172, 18.2607674],
                [105.282777, 18.2606878],
                [105.2836737, 18.2605501],
                [105.2844277, 18.260393],
                [105.2851814, 18.2600214],
                [105.2857922, 18.2595917],
                [105.286444, 18.2592788],
                [105.2869535, 18.259122],
                [105.2873606, 18.258907],
                [105.2875736, 18.2582925],
                [105.2876948, 18.2576101],
                [105.2877955, 18.2568106],
                [105.2878553, 18.2560307],
                [105.2878952, 18.2554848],
                [105.2879151, 18.2551924],
                [105.2881082, 18.2548315],
                [105.2884547, 18.2548308],
                [105.288781, 18.2549279],
                [105.289271, 18.255395],
                [105.2898019, 18.2559401],
                [105.2902306, 18.2563098],
                [105.2905365, 18.2564654],
                [105.2908627, 18.2564258],
                [105.2911273, 18.2562305],
                [105.2912898, 18.2558794],
                [105.2915134, 18.2554696],
                [105.2917168, 18.2552354],
                [105.2919407, 18.2550401],
                [105.2922463, 18.2549422],
                [105.2926205, 18.2549415],
                [105.2928068, 18.2549641],
                [105.2928577, 18.2552168],
                [105.2929338, 18.2554694],
                [105.2930224, 18.2556377],
                [105.293174, 18.2557218],
                [105.2933507, 18.2557336],
                [105.2937292, 18.2557691],
                [105.2938806, 18.2557327],
                [105.2941957, 18.2555157],
                [105.2944224, 18.2553106],
                [105.2946492, 18.2551538],
                [105.2948258, 18.2551175],
                [105.2949772, 18.255057],
                [105.2952548, 18.2550566],
                [105.2953684, 18.2551046],
                [105.2955201, 18.2552969],
                [105.2956213, 18.2554652],
                [105.2957606, 18.2557419],
                [105.2958491, 18.2558861],
                [105.2959376, 18.2559943],
                [105.2960387, 18.2560785],
                [105.2961018, 18.2561024],
                [105.2965801, 18.2562124],
                [105.2969469, 18.2562313],
                [105.2973138, 18.2561528],
                [105.2976803, 18.2559767],
                [105.2980673, 18.2557227],
                [105.2985559, 18.2553321],
                [105.2989833, 18.255039],
                [105.2992888, 18.2548436],
                [105.2994311, 18.2546679],
                [105.2995329, 18.2545117],
                [105.2994918, 18.2543364],
                [105.2993897, 18.2542],
                [105.2991039, 18.2539861],
                [105.298818, 18.2537331],
                [105.2986546, 18.2534994],
                [105.2984911, 18.2532267],
                [105.2983478, 18.2529149],
                [105.298286, 18.2525252],
                [105.298285, 18.2519599],
                [105.2982025, 18.2514336],
                [105.2977625, 18.2503133],
                [105.2974079, 18.2497429],
                [105.2969823, 18.2492863],
                [105.296761, 18.2490084],
                [105.2963204, 18.2478771],
                [105.2959315, 18.2468162],
                [105.2957235, 18.2459032],
                [105.2957462, 18.244101],
                [105.2958673, 18.2431692],
                [105.2959255, 18.2419608],
                [105.2961286, 18.2413602],
                [105.2966434, 18.2404954],
                [105.2979309, 18.2389133],
                [105.2987623, 18.2379467],
                [105.2991629, 18.2368553],
                [105.2993536, 18.2364947],
                [105.2995851, 18.2362754],
                [105.299908, 18.2362325],
                [105.3002304, 18.2363217],
                [105.3004603, 18.2364987],
                [105.3008735, 18.2369844],
                [105.3017917, 18.2379564],
                [105.3021593, 18.23831],
                [105.3024346, 18.238619],
                [105.3027098, 18.2390163],
                [105.3032586, 18.240163],
                [105.3035774, 18.2411767],
                [105.3036671, 18.2417934],
                [105.3036186, 18.2424096],
                [105.3033396, 18.2429808],
                [105.3030603, 18.2436403],
                [105.3029379, 18.2437562],
                [105.3030303, 18.2443952],
                [105.3032417, 18.2448552],
                [105.3039542, 18.2461085],
                [105.304509, 18.2469823],
                [105.3051161, 18.2479414],
                [105.305871, 18.2490099],
                [105.3059405, 18.2495449],
                [105.3061222, 18.249876],
                [105.3063309, 18.2501547],
                [105.3066217, 18.251003],
                [105.3067473, 18.2514892],
                [105.3068296, 18.2518332],
                [105.3068387, 18.2535157],
                [105.3067175, 18.2541788],
                [105.3063515, 18.2546861],
                [105.3058224, 18.2551158],
                [105.3053542, 18.2555651],
                [105.3050803, 18.2562575],
                [105.3051832, 18.2568227],
                [105.3055918, 18.2573679],
                [105.3061027, 18.2580299],
                [105.306414, 18.2587462],
                [105.3066266, 18.2591851],
                [105.307136, 18.260358],
                [105.3075447, 18.2609031],
                [105.308096, 18.261448],
                [105.3085438, 18.2621181],
                [105.3089988, 18.2628069],
                [105.3094663, 18.2640199],
                [105.3098514, 18.2649245],
                [105.3101344, 18.2655102],
                [105.3103647, 18.2661178],
                [105.3104372, 18.2661669],
                [105.3110184, 18.2656659],
                [105.3113359, 18.2654965],
                [105.3117618, 18.2652143],
                [105.3121688, 18.2648238],
                [105.3124947, 18.2646088],
                [105.3131673, 18.2645882],
                [105.3136974, 18.2645874],
                [105.3142477, 18.264567],
                [105.3153888, 18.2643116],
                [105.3165611, 18.2644169],
                [105.3186831, 18.2655051],
                [105.319357, 18.2661083],
                [105.3198061, 18.2664584],
                [105.3200508, 18.2665555],
                [105.3203363, 18.2665356],
                [105.3206214, 18.2664571],
                [105.3209265, 18.2660861],
                [105.3213536, 18.2655591],
                [105.3217806, 18.2649345],
                [105.3220554, 18.2647294],
                [105.3222796, 18.264729],
                [105.3227283, 18.2648453],
                [105.3230342, 18.2648837],
                [105.3233603, 18.2648442],
                [105.3236249, 18.2646683],
                [105.3239508, 18.2645313],
                [105.3245009, 18.2643549],
                [105.3251126, 18.2644123],
                [105.325561, 18.2643921],
                [105.3259685, 18.264255],
                [105.3265192, 18.2644488],
                [105.3269071, 18.2647602],
                [105.3272744, 18.2649156],
                [105.3276618, 18.2649538],
                [105.3280897, 18.2648946],
                [105.3286089, 18.264611],
                [105.3289955, 18.264201],
                [105.329402, 18.2635375],
                [105.3296453, 18.2628742],
                [105.3299503, 18.2625034],
                [105.3299704, 18.2623083],
                [105.3299088, 18.2620746],
                [105.3297655, 18.2617433],
                [105.3293979, 18.2613735],
                [105.3289786, 18.2606042],
                [105.328753, 18.2598639],
                [105.3285976, 18.2590135],
                [105.328648, 18.2582654],
                [105.3288917, 18.2577972],
                [105.3294914, 18.2568896],
                [105.3301226, 18.2565377],
                [105.3304997, 18.2561717],
                [105.3306876, 18.2558101],
                [105.3307684, 18.25542],
                [105.3308508, 18.2550559],
                [105.3308933, 18.2545088],
                [105.3308925, 18.2540604],
                [105.3313089, 18.2532508],
                [105.3313288, 18.2529972],
                [105.3312875, 18.2527049],
                [105.3309396, 18.2520232],
                [105.3304172, 18.2512813],
                [105.3301308, 18.2507165],
                [105.329888, 18.2501917],
                [105.3298443, 18.2495005],
                [105.3298436, 18.2491106],
                [105.3298336, 18.2480734],
                [105.3296354, 18.2465259],
                [105.3292987, 18.2454857],
                [105.3284871, 18.2441795],
                [105.3284283, 18.242525],
                [105.3282871, 18.2416446],
                [105.3280905, 18.240951],
                [105.3272233, 18.2396449],
                [105.3263848, 18.238819],
                [105.3250158, 18.2377004],
                [105.3248756, 18.237327],
                [105.3249572, 18.2361794],
                [105.3251496, 18.2346846],
                [105.3253927, 18.2328922],
                [105.3240835, 18.2297441],
                [105.3232475, 18.2279801],
                [105.3233084, 18.2276458],
                [105.3235478, 18.2269261],
                [105.3238476, 18.2264364],
                [105.3238764, 18.2261712],
                [105.3237847, 18.2253944],
                [105.3234511, 18.2235312],
                [105.3234202, 18.2230709],
                [105.3239647, 18.2221496],
                [105.3246148, 18.2212485],
                [105.3255183, 18.2204507],
                [105.3250785, 18.2175435],
                [105.3250923, 18.2163067],
                [105.3249397, 18.2150986],
                [105.325029, 18.2146093],
                [105.3257792, 18.2136298],
                [105.325831, 18.2133879],
                [105.3256844, 18.2111557],
                [105.3258337, 18.2105512],
                [105.3258632, 18.2102059],
                [105.3259225, 18.2097742],
                [105.3256803, 18.2088827],
                [105.3255292, 18.2085664],
                [105.3251674, 18.2081642],
                [105.3245952, 18.2077912],
                [105.3234305, 18.2074029],
                [105.3216527, 18.2051556],
                [105.3195461, 18.2013857],
                [105.3199063, 18.2003811],
                [105.3221715, 18.1983869],
                [105.3233132, 18.197637],
                [105.3241895, 18.1967354],
                [105.325234, 18.1953895],
                [105.3255341, 18.1949575],
                [105.3266149, 18.1938911],
                [105.3294715, 18.1934546],
                [105.3288369, 18.191902],
                [105.3301046, 18.1918649],
                [105.3317696, 18.191839],
                [105.3340379, 18.1917196],
                [105.3356641, 18.1915161],
                [105.3369812, 18.191322],
                [105.3376485, 18.1911674],
                [105.3377683, 18.1908508],
                [105.3380646, 18.1899816],
                [105.3383039, 18.1890119],
                [105.3382296, 18.1880427],
                [105.3379138, 18.1868892],
                [105.3377428, 18.1858279],
                [105.3374509, 18.1846513],
                [105.3371595, 18.1836825],
                [105.3362402, 18.1824148],
                [105.3373616, 18.1827378],
                [105.3383046, 18.1829022],
                [105.3396332, 18.1829604],
                [105.3417331, 18.1826102],
                [105.3432195, 18.1824411],
                [105.3439182, 18.1826399],
                [105.344911, 18.182782],
                [105.3455729, 18.1828671],
                [105.3465656, 18.1829803],
                [105.3476786, 18.1830936],
                [105.348611, 18.183063],
                [105.3493624, 18.1828602],
                [105.350298, 18.1844122],
                [105.3518917, 18.1842079],
                [105.3524915, 18.1832861],
                [105.3529421, 18.1830551],
                [105.3534829, 18.1827951],
                [105.3537829, 18.1823918],
                [105.3539923, 18.1818736],
                [105.3540813, 18.1812691],
                [105.3540195, 18.1804349],
                [105.3542599, 18.1803194],
                [105.3550714, 18.1800014],
                [105.3560025, 18.1794243],
                [105.3566028, 18.1788189],
                [105.3574287, 18.1777422],
                [105.358744, 18.1756324],
                [105.3596647, 18.1737852],
                [105.3601477, 18.1727786],
                [105.3604969, 18.1722292],
                [105.3611954, 18.1714074],
                [105.3616084, 18.1707914],
                [105.3619216, 18.1702711],
                [105.362142, 18.1696759],
                [105.3623962, 18.1682532],
                [105.3631574, 18.1667272],
                [105.3633268, 18.1657559],
                [105.363498, 18.1645855],
                [105.3636099, 18.1639308],
                [105.3646618, 18.1636699],
                [105.3655032, 18.1633518],
                [105.3658334, 18.1630635],
                [105.3673332, 18.1611905],
                [105.3679341, 18.1609016],
                [105.3683251, 18.1609296],
                [105.3688967, 18.1610724],
                [105.3693779, 18.1611003],
                [105.3703741, 18.1608636],
                [105.3706703, 18.1607237],
                [105.3725258, 18.1607716],
                [105.3748804, 18.1606868],
                [105.3756618, 18.1604551],
                [105.3764134, 18.1603098],
                [105.3767773, 18.1617476],
                [105.3765981, 18.1622946],
                [105.3765087, 18.1627264],
                [105.3764957, 18.1637342],
                [105.3764406, 18.1647598],
                [105.3763693, 18.1652142],
                [105.3763482, 18.1653852],
                [105.376328, 18.1655122],
                [105.3764817, 18.1655905],
                [105.3772003, 18.1658523],
                [105.3792739, 18.1667917],
                [105.3804644, 18.1672919],
                [105.380862, 18.1677527],
                [105.3810344, 18.1682172],
                [105.3811838, 18.169047],
                [105.381425, 18.1693341],
                [105.3816947, 18.169597],
                [105.3828671, 18.1700048],
                [105.3835101, 18.1700036],
                [105.3839241, 18.1698386],
                [105.3842374, 18.1693458],
                [105.3850542, 18.1681829],
                [105.3856329, 18.1672508],
                [105.3861125, 18.1664644],
                [105.3863647, 18.1659446],
                [105.3870993, 18.164892],
                [105.3876277, 18.1642803],
                [105.3881334, 18.1637946],
                [105.389073, 18.1632043],
                [105.389862, 18.1626451],
                [105.3909348, 18.1619265],
                [105.3914467, 18.1614286],
                [105.3919152, 18.1605276],
                [105.3921775, 18.1596321],
                [105.393624, 18.1591099],
                [105.3959408, 18.1593475],
                [105.3989682, 18.1604081],
                [105.3998708, 18.1605789],
                [105.4005322, 18.1604624],
                [105.4012217, 18.1595403],
                [105.4015218, 18.1592807],
                [105.4018225, 18.1592801],
                [105.4024246, 18.1595953],
                [105.403176, 18.1593348],
                [105.4037767, 18.1590458],
                [105.4038667, 18.1589306],
                [105.4041634, 18.1571461],
                [105.4045531, 18.1566849],
                [105.4063089, 18.1559353],
                [105.4069862, 18.1554714],
                [105.407666, 18.1549914],
                [105.4084272, 18.1544038],
                [105.4089677, 18.1540573],
                [105.4092683, 18.1540855],
                [105.410052, 18.1548031],
                [105.4121332, 18.1564673],
                [105.4124114, 18.1570354],
                [105.4126544, 18.1577684],
                [105.4132163, 18.159692],
                [105.4134792, 18.1608039],
                [105.4136401, 18.1614898],
                [105.4139723, 18.1624198],
                [105.4142596, 18.1631839],
                [105.4143743, 18.1634841],
                [105.4145182, 18.1639755],
                [105.4148913, 18.1648487],
                [105.4152645, 18.1658312],
                [105.415274, 18.1668077],
                [105.4150609, 18.1672315],
                [105.4141867, 18.1686469],
                [105.4139614, 18.169193],
                [105.4140105, 18.1695965],
                [105.4142552, 18.1700464],
                [105.4143555, 18.17062],
                [105.4143293, 18.171617],
                [105.4143167, 18.1723409],
                [105.4147478, 18.1735827],
                [105.4152072, 18.1747426],
                [105.4153649, 18.1750564],
                [105.4166662, 18.1761734],
                [105.4173788, 18.1771357],
                [105.4178211, 18.1779803],
                [105.418543, 18.1789181],
                [105.4189005, 18.1795965],
                [105.4189728, 18.1799925],
                [105.4191559, 18.1811924],
                [105.419556, 18.182319],
                [105.4201735, 18.1832177],
                [105.4204631, 18.1835674],
                [105.4209474, 18.18381],
                [105.4218198, 18.1838944],
                [105.4222105, 18.1837498],
                [105.4233912, 18.1831677],
                [105.4240618, 18.182992],
                [105.4246075, 18.1829062],
                [105.4262173, 18.1836787],
                [105.4264532, 18.1836641],
                [105.4270858, 18.1829572],
                [105.4272774, 18.1828156],
                [105.428206, 18.1824888],
                [105.4285154, 18.1823471],
                [105.4287214, 18.1821491],
                [105.4291474, 18.181372],
                [105.4293971, 18.1809339],
                [105.4297937, 18.1802839],
                [105.4303604, 18.1797535],
                [105.4309275, 18.1793783],
                [105.4313548, 18.1792079],
                [105.4344447, 18.1796791],
                [105.438247, 18.1803975],
                [105.4397192, 18.1821878],
                [105.4393677, 18.1832045],
                [105.4409944, 18.1847815],
                [105.4411429, 18.1852044],
                [105.4413774, 18.1864108],
                [105.4415616, 18.1874614],
                [105.4415351, 18.1886468],
                [105.442038, 18.1892102],
                [105.4425119, 18.1898864],
                [105.4427421, 18.1906994],
                [105.4432612, 18.191663],
                [105.4433342, 18.1917879],
                [105.4435496, 18.1918597],
                [105.4440517, 18.1920561],
                [105.4446127, 18.1922241],
                [105.4449083, 18.1924493],
                [105.4466243, 18.1942516],
                [105.4466542, 18.1944208],
                [105.4462142, 18.1954379],
                [105.4460975, 18.195918],
                [105.4461568, 18.1960871],
                [105.4466895, 18.1966504],
                [105.4469856, 18.1971014],
                [105.447194, 18.1978346],
                [105.4472549, 18.1985683],
                [105.447079, 18.1990203],
                [105.4464608, 18.199558],
                [105.445135, 18.2003514],
                [105.4444867, 18.2006915],
                [105.4438972, 18.2009469],
                [105.4431002, 18.2008358],
                [105.4428053, 18.200893],
                [105.4426285, 18.2010062],
                [105.4421577, 18.2015154],
                [105.4419523, 18.2019675],
                [105.4421015, 18.2026163],
                [105.4424566, 18.2030388],
                [105.4425759, 18.2035466],
                [105.4424596, 18.2042242],
                [105.4427258, 18.2044493],
                [105.4434338, 18.2044195],
                [105.4440219, 18.2043448],
                [105.4449625, 18.2041002],
                [105.4464127, 18.2038481],
                [105.4472381, 18.203564],
                [105.4483578, 18.2029122],
                [105.4496572, 18.2033325],
                [105.4505131, 18.2034153],
                [105.4524406, 18.2049178],
                [105.4526451, 18.204127],
                [105.4528797, 18.2035338],
                [105.4530555, 18.2030818],
                [105.4532858, 18.2030135],
                [105.4540822, 18.2029269],
                [105.4545547, 18.2030669],
                [105.4547942, 18.2043364],
                [105.4549715, 18.2045054],
                [105.4554442, 18.2046736],
                [105.4573918, 18.2047254],
                [105.4582486, 18.2051749],
                [105.4593122, 18.2055958],
                [105.4597846, 18.205764],
                [105.4600501, 18.205735],
                [105.4608449, 18.2050276],
                [105.461257, 18.2046315],
                [105.4618433, 18.2031907],
                [105.4626689, 18.2029347],
                [105.4637597, 18.2025652],
                [105.4664136, 18.2018531],
                [105.467063, 18.2019644],
                [105.4673873, 18.2018789],
                [105.4676522, 18.2016243],
                [105.468779, 18.2004627],
                [105.4695733, 18.1996299],
                [105.4700606, 18.1993833],
                [105.4712734, 18.1991757],
                [105.4723276, 18.1989865],
                [105.4737116, 18.1988653],
                [105.4747194, 18.1990208],
                [105.4750102, 18.1989948],
                [105.4758338, 18.1987186],
                [105.4769035, 18.1981216],
                [105.4781704, 18.1972575],
                [105.4787951, 18.1968511],
                [105.4801067, 18.1961055],
                [105.4805206, 18.1959527],
                [105.48075, 18.1959773],
                [105.4810941, 18.1961284],
                [105.4826821, 18.19691],
                [105.4836334, 18.1976115],
                [105.4844364, 18.1983419],
                [105.4851742, 18.1997463],
                [105.4856464, 18.2005304],
                [105.4866229, 18.2007304],
                [105.4877452, 18.2011943],
                [105.4893699, 18.2015881],
                [105.4903144, 18.2018782],
                [105.4907585, 18.2023851],
                [105.491114, 18.2029204],
                [105.4917313, 18.2021286],
                [105.4929007, 18.20103],
                [105.4936771, 18.2004048],
                [105.4936425, 18.1997207],
                [105.4935012, 18.1987834],
                [105.4929468, 18.1970769],
                [105.4923337, 18.1961419],
                [105.4909396, 18.1939358],
                [105.4903197, 18.1932136],
                [105.4891068, 18.1920877],
                [105.4890471, 18.1918621],
                [105.4891634, 18.1912409],
                [105.4891618, 18.1906764],
                [105.4890429, 18.190338],
                [105.4888652, 18.1900844],
                [105.4880955, 18.1891549],
                [105.4880063, 18.1889294],
                [105.4880339, 18.188252],
                [105.4879744, 18.1880546],
                [105.4877964, 18.1877446],
                [105.4869349, 18.1856583],
                [105.4869638, 18.1854041],
                [105.4872274, 18.1847261],
                [105.4873143, 18.1841332],
                [105.4874002, 18.1831734],
                [105.4875156, 18.1822417],
                [105.4874257, 18.1817621],
                [105.4870995, 18.181142],
                [105.4871874, 18.1809159],
                [105.4873636, 18.1806334],
                [105.4877454, 18.1799832],
                [105.4882734, 18.1789376],
                [105.4886245, 18.1778359],
                [105.4886525, 18.1772997],
                [105.488563, 18.1769048],
                [105.4884442, 18.1766793],
                [105.4891503, 18.1759436],
                [105.4914519, 18.1760788],
                [105.4919239, 18.1760776],
                [105.4926329, 18.1764428],
                [105.4929281, 18.1764702],
                [105.4936068, 18.1765531],
                [105.4937547, 18.1766657],
                [105.493934, 18.1774555],
                [105.4938168, 18.1777662],
                [105.4936117, 18.1782465],
                [105.4936125, 18.1785288],
                [105.4937318, 18.1790366],
                [105.49403, 18.1801082],
                [105.4942963, 18.180418],
                [105.4956559, 18.1812894],
                [105.4958635, 18.1816558],
                [105.495837, 18.1827284],
                [105.4960446, 18.1830383],
                [105.4965774, 18.183686],
                [105.4968433, 18.1838265],
                [105.497463, 18.1838531],
                [105.4979962, 18.1846419],
                [105.4974381, 18.1854902],
                [105.4973505, 18.1858008],
                [105.4974703, 18.1864497],
                [105.4979159, 18.1874928],
                [105.4984494, 18.1883663],
                [105.4988931, 18.1887886],
                [105.4993955, 18.1890413],
                [105.4992217, 18.1901707],
                [105.4994574, 18.1900571],
                [105.5000581, 18.189655],
                [105.5006056, 18.1891791],
                [105.5010764, 18.1887546],
                [105.5013694, 18.1883703],
                [105.501847, 18.1873085],
                [105.5024196, 18.1860466],
                [105.5028191, 18.1847865],
                [105.503687, 18.1828273],
                [105.5040781, 18.1817417],
                [105.5044573, 18.1806109],
                [105.504775, 18.1799988],
                [105.5051546, 18.1790034],
                [105.5052279, 18.1772307],
                [105.5056206, 18.1758888],
                [105.506079, 18.1753794],
                [105.506151, 18.1751713],
                [105.505932, 18.1747329],
                [105.5054945, 18.1741565],
                [105.5050934, 18.1736315],
                [105.5045956, 18.1730268],
                [105.5044495, 18.1727038],
                [105.5045211, 18.172357],
                [105.5046657, 18.1721487],
                [105.5061903, 18.1707238],
                [105.5072706, 18.1693062],
                [105.507908, 18.1681726],
                [105.5081989, 18.166474],
                [105.508342, 18.1648702],
                [105.5087331, 18.1637843],
                [105.5092712, 18.1624152],
                [105.5098604, 18.1616591],
                [105.5104026, 18.160623],
                [105.5106924, 18.1596262],
                [105.5106671, 18.1594958],
                [105.510283, 18.1574233],
                [105.5100646, 18.1564389],
                [105.5098188, 18.1552891],
                [105.5096719, 18.1543162],
                [105.509903, 18.1531199],
                [105.5112746, 18.1514369],
                [105.5120175, 18.150514],
                [105.5125433, 18.149624],
                [105.5124716, 18.1487652],
                [105.5117301, 18.1479183],
                [105.5107668, 18.1471427],
                [105.50973, 18.146438],
                [105.5077326, 18.1458774],
                [105.5064044, 18.1452481],
                [105.5057436, 18.1439293],
                [105.5053142, 18.1427752],
                [105.5047531, 18.1418649],
                [105.5041435, 18.140811],
                [105.5045156, 18.1401716],
                [105.5044949, 18.1398337],
                [105.5040807, 18.1394405],
                [105.5038239, 18.1390469],
                [105.5037569, 18.1384637],
                [105.5041486, 18.1375824],
                [105.5048689, 18.1367316],
                [105.5055562, 18.1359123],
                [105.5061925, 18.1350787],
                [105.5063885, 18.1347966],
                [105.506152, 18.1346657],
                [105.5042708, 18.1343436],
                [105.5033507, 18.1342831],
                [105.5032837, 18.1338432],
                [105.5036155, 18.132823],
                [105.5039307, 18.1318213],
                [105.5037301, 18.1305957],
                [105.5037268, 18.1294166],
                [105.5039516, 18.1287381],
                [105.5050143, 18.1272621],
                [105.505264, 18.1267818],
                [105.5053929, 18.1262525],
                [105.5057687, 18.1244522],
                [105.5065535, 18.1237929],
                [105.5076125, 18.1230955],
                [105.5086161, 18.1228863],
                [105.508989, 18.1226786],
                [105.5093614, 18.1221708],
                [105.5100775, 18.1214533],
                [105.5107714, 18.120064],
                [105.5110265, 18.1198756],
                [105.5112821, 18.1198373],
                [105.5115191, 18.1201747],
                [105.5117373, 18.1208313],
                [105.5120538, 18.1214312],
                [105.5124886, 18.1221436],
                [105.512549, 18.1225942],
                [105.5125508, 18.1232701],
                [105.5127103, 18.124002],
                [105.5129475, 18.1244144],
                [105.5131643, 18.1245452],
                [105.5135579, 18.124657],
                [105.514187, 18.1245426],
                [105.5145411, 18.1245791],
                [105.5149158, 18.1248787],
                [105.5152512, 18.1252345],
                [105.5157237, 18.1254585],
                [105.5163537, 18.1256071],
                [105.5172199, 18.1258676],
                [105.5180466, 18.1260532],
                [105.5189123, 18.1261635],
                [105.5208791, 18.1261206],
                [105.5222038, 18.1258308],
                [105.5226869, 18.1255899],
                [105.5230401, 18.1252886],
                [105.5239624, 18.1245538],
                [105.5248453, 18.123819],
                [105.5254538, 18.1234043],
                [105.5259828, 18.1227645],
                [105.5264523, 18.1218995],
                [105.5269595, 18.1205274],
                [105.5273891, 18.1194747],
                [105.5275653, 18.119155],
                [105.5278203, 18.1189478],
                [105.5283121, 18.1190027],
                [105.5293148, 18.1188498],
                [105.5297662, 18.1185482],
                [105.5300991, 18.1180778],
                [105.5303709, 18.1176846],
                [105.5307462, 18.1174188],
                [105.5312375, 18.1173048],
                [105.5320239, 18.1171899],
                [105.5331051, 18.117018],
                [105.5336942, 18.1167347],
                [105.5350297, 18.1160738],
                [105.5352394, 18.1158603],
                [105.5355175, 18.1154827],
                [105.5356325, 18.1150477],
                [105.5356361, 18.1136876],
                [105.5355881, 18.1125437],
                [105.5355681, 18.1120165],
                [105.5357041, 18.1114716],
                [105.5360566, 18.1110012],
                [105.5362915, 18.1106251],
                [105.5363495, 18.1103057],
                [105.5363672, 18.1096672],
                [105.5362285, 18.1089363],
                [105.536184, 18.1087665],
                [105.5371174, 18.107915],
                [105.5383622, 18.1070046],
                [105.5384496, 18.1069574],
                [105.5394067, 18.1058512],
                [105.5396241, 18.1051412],
                [105.5395951, 18.104616],
                [105.53896, 18.1033828],
                [105.5385684, 18.102527],
                [105.5387994, 18.1013307],
                [105.5392455, 18.0991224],
                [105.5395295, 18.0977423],
                [105.5387026, 18.0941981],
                [105.5391405, 18.0916743],
                [105.5395607, 18.087701],
                [105.539608, 18.0853393],
                [105.5394284, 18.084311],
                [105.5397554, 18.0826322],
                [105.5401294, 18.0819643],
                [105.5408806, 18.0816802],
                [105.5417396, 18.0816008],
                [105.5428142, 18.081726],
                [105.5435476, 18.0816561],
                [105.5436874, 18.0815808],
                [105.5452765, 18.0807881],
                [105.5467067, 18.0806822],
                [105.5495897, 18.0811864],
                [105.5512547, 18.0822221],
                [105.5520619, 18.0819675],
                [105.5533777, 18.0814515],
                [105.554531, 18.0808134],
                [105.5547625, 18.0809227],
                [105.5549507, 18.0809991],
                [105.5555423, 18.0812539],
                [105.5560805, 18.0815857],
                [105.5564566, 18.0816358],
                [105.5569387, 18.0811986],
                [105.5573011, 18.0807672],
                [105.5580675, 18.0805611],
                [105.5594678, 18.0807935],
                [105.5621872, 18.0816132],
                [105.5633823, 18.0820037],
                [105.5645372, 18.0827886],
                [105.5659032, 18.0838545],
                [105.566476, 18.084356],
                [105.5677195, 18.0851727],
                [105.5684374, 18.0858086],
                [105.568746, 18.0860353],
                [105.5699459, 18.0849078],
                [105.5710884, 18.0840082],
                [105.5710688, 18.0828385],
                [105.5708721, 18.0810162],
                [105.5713526, 18.0804335],
                [105.5716783, 18.0793292],
                [105.5730993, 18.0761636],
                [105.5733654, 18.0728074],
                [105.5743284, 18.0714393],
                [105.5748796, 18.0708262],
                [105.5753401, 18.0705971],
                [105.5757849, 18.070383],
                [105.5762452, 18.0700929],
                [105.5764509, 18.0697582],
                [105.5764811, 18.0692872],
                [105.576463, 18.0685733],
                [105.5762867, 18.0681787],
                [105.5759999, 18.0680126],
                [105.574965, 18.0677726],
                [105.5738179, 18.0672291],
                [105.5727985, 18.0667915],
                [105.572535, 18.0666019],
                [105.5729054, 18.0642315],
                [105.5734772, 18.0628505],
                [105.5742977, 18.0620204],
                [105.5754891, 18.0613469],
                [105.5763941, 18.0611866],
                [105.577094, 18.0612633],
                [105.5782363, 18.0611395],
                [105.5787454, 18.061214],
                [105.5802587, 18.0618627],
                [105.5804496, 18.0618469],
                [105.5805512, 18.0612135],
                [105.5815694, 18.0579001],
                [105.5823421, 18.0550997],
                [105.5822056, 18.0511591],
                [105.5823374, 18.0491382],
                [105.5822752, 18.0488056],
                [105.5821592, 18.0485675],
                [105.5821586, 18.0484024],
                [105.5822541, 18.0482002],
                [105.5829058, 18.047813],
                [105.5832508, 18.0475919],
                [105.5834038, 18.0473713],
                [105.5834184, 18.0472799],
                [105.5835355, 18.0465453],
                [105.5838952, 18.0449665],
                [105.5845497, 18.0442837],
                [105.5851074, 18.0433354],
                [105.5851088, 18.0427064],
                [105.585204, 18.0422575],
                [105.5848302, 18.0412683],
                [105.5843621, 18.0404587],
                [105.5841756, 18.0398293],
                [105.5841764, 18.03947],
                [105.5844599, 18.0388416],
                [105.5847431, 18.0383032],
                [105.5847444, 18.0377641],
                [105.5843693, 18.0374039],
                [105.5835246, 18.036863],
                [105.5831496, 18.036413],
                [105.5827553, 18.0354001],
                [105.5826535, 18.0353033],
                [105.5822077, 18.0344556],
                [105.5822027, 18.0329701],
                [105.581874, 18.0310043],
                [105.5818715, 18.0302191],
                [105.5818939, 18.0296417],
                [105.5816749, 18.0292268],
                [105.5811405, 18.0284894],
                [105.5805092, 18.0276599],
                [105.5798308, 18.027177],
                [105.5793937, 18.0266241],
                [105.5795373, 18.0261618],
                [105.5804269, 18.024658],
                [105.5806279, 18.0243819],
                [105.5807991, 18.0235246],
                [105.5812388, 18.0224857],
                [105.5832639, 18.0206739],
                [105.5837405, 18.0203714],
                [105.5839203, 18.0198733],
                [105.5838224, 18.0193976],
                [105.5830588, 18.0181297],
                [105.5829591, 18.0178461],
                [105.5827391, 18.0175428],
                [105.5824307, 18.0166376],
                [105.5821067, 18.0158708],
                [105.5817186, 18.0152117],
                [105.5812344, 18.0146142],
                [105.5811045, 18.0142154],
                [105.5811683, 18.0140309],
                [105.5817623, 18.0137834],
                [105.5823237, 18.0134439],
                [105.5827408, 18.0131355],
                [105.5832051, 18.0125967],
                [105.5836204, 18.0117815],
                [105.5839711, 18.0108898],
                [105.5841626, 18.0104593],
                [105.5843231, 18.010382],
                [105.5847256, 18.0105497],
                [105.5861749, 18.0113131],
                [105.5867064, 18.011604],
                [105.5869634, 18.0115871],
                [105.5873327, 18.0114631],
                [105.5877982, 18.0112622],
                [105.5887613, 18.0108292],
                [105.5892582, 18.0103824],
                [105.5893855, 18.0100134],
                [105.5894004, 18.0096602],
                [105.5895123, 18.0094602],
                [105.5896568, 18.0094136],
                [105.5907505, 18.0095486],
                [105.5914902, 18.0096691],
                [105.5917072, 18.0096908],
                [105.5923857, 18.0094947],
                [105.5933196, 18.0089731],
                [105.5941051, 18.0083104],
                [105.594298, 18.0070837],
                [105.5943924, 18.0058572],
                [105.594537, 18.0049607],
                [105.5953703, 18.0038264],
                [105.5963034, 18.0031161],
                [105.5980731, 18.0022617],
                [105.5984181, 18.002355],
                [105.5986276, 18.0025603],
                [105.5988149, 18.0032498],
                [105.5991638, 18.0044749],
                [105.5997571, 18.0052276],
                [105.6005968, 18.0060268],
                [105.6014362, 18.0066844],
                [105.6024687, 18.0070631],
                [105.6032085, 18.0071836],
                [105.6040187, 18.0090854],
                [105.6046962, 18.0097397],
                [105.6056324, 18.0099253],
                [105.6070618, 18.0103453],
                [105.6081467, 18.0108135],
                [105.6103642, 18.0113253],
                [105.6117445, 18.0117926],
                [105.6132221, 18.0119294],
                [105.6136283, 18.0117958],
                [105.6146259, 18.0111372],
                [105.6151648, 18.0105135],
                [105.6156361, 18.0098856],
                [105.6158254, 18.009257],
                [105.6159228, 18.0077297],
                [105.6161125, 18.0069215],
                [105.6166783, 18.0060241],
                [105.6187058, 18.0040938],
                [105.6190036, 18.0037051],
                [105.6203283, 18.0023804],
                [105.6209165, 18.0015768],
                [105.6215861, 18.0002302],
                [105.6236099, 17.9990038],
                [105.6240202, 17.9987765],
                [105.6242386, 17.9986265],
                [105.6246607, 17.9983063],
                [105.6250428, 17.9979437],
                [105.6254732, 17.9974456],
                [105.6260759, 17.9967677],
                [105.6266071, 17.9963106],
                [105.6271814, 17.9957845],
                [105.6278426, 17.9953409],
                [105.6287907, 17.9946066],
                [105.6305455, 17.9938697],
                [105.6312467, 17.9932215],
                [105.6316797, 17.9928175],
                [105.632039, 17.9925817],
                [105.6323748, 17.9923723],
                [105.6327201, 17.9921613],
                [105.6332467, 17.9919569],
                [105.6338081, 17.9918723],
                [105.6344276, 17.9918564],
                [105.6348885, 17.9918688],
                [105.6353639, 17.9918672],
                [105.6357964, 17.9919347],
                [105.6364157, 17.9919189],
                [105.6370787, 17.9920548],
                [105.6380597, 17.9924654],
                [105.6397625, 17.9932875],
                [105.6413775, 17.9937513],
                [105.642376, 17.9937146],
                [105.6435087, 17.9924965],
                [105.6501122, 17.9931073],
                [105.6550689, 17.9936259],
                [105.6630273, 17.9934396],
                [105.668506, 17.9932975],
                [105.6766568, 17.9929743],
                [105.6815768, 17.9928712],
                [105.6853322, 17.992639],
                [105.6929281, 17.9924229],
                [105.701977, 17.9923686],
                [105.7053678, 17.992516],
                [105.7063571, 17.9928185],
                [105.7072825, 17.9941256],
                [105.7075657, 17.9948813],
                [105.7090661, 17.9957792],
                [105.7106396, 17.9963343],
                [105.7115449, 17.9972436],
                [105.7126968, 17.9982702],
                [105.7131379, 17.999081],
                [105.7128209, 18.0003573],
                [105.7124147, 18.0017181],
                [105.7125465, 18.0020594],
                [105.7138335, 18.0026607],
                [105.714673, 18.003899],
                [105.715116, 18.0043267],
                [105.7164058, 18.0042893],
                [105.7175617, 18.0043791],
                [105.7184939, 18.0047661],
                [105.7197368, 18.0053246],
                [105.7204017, 18.0057957],
                [105.7211531, 18.0068634],
                [105.7220822, 18.0079742],
                [105.7215673, 18.0085165],
                [105.7208716, 18.0092119],
                [105.7204312, 18.0098732],
                [105.7205744, 18.0104673],
                [105.7211181, 18.0111675],
                [105.721334, 18.0118315],
                [105.7212204, 18.0127735],
                [105.7207431, 18.0135395],
                [105.7201552, 18.0146192],
                [105.7200051, 18.0155611],
                [105.7198893, 18.0170265],
                [105.7197742, 18.0183176],
                [105.7197333, 18.0193296],
                [105.7199117, 18.020203],
                [105.7205994, 18.0213576],
                [105.7213614, 18.0221635],
                [105.7227069, 18.0229717],
                [105.7234704, 18.0234634],
                [105.724087, 18.0241989],
                [105.7243532, 18.0256485],
                [105.7248899, 18.0268832],
                [105.7261725, 18.0279911],
                [105.7273221, 18.0285146],
                [105.7284039, 18.0290379],
                [105.7288068, 18.0298828],
                [105.7290016, 18.0318296],
                [105.7297419, 18.0330002],
                [105.729824, 18.0330887],
                [105.7308884, 18.0342372],
                [105.7330528, 18.035154],
                [105.7338849, 18.0352683],
                [105.7344949, 18.0357837],
                [105.7352604, 18.0357867],
                [105.7363329, 18.0355711],
                [105.7376314, 18.0362358],
                [105.7385474, 18.0368256],
                [105.7396956, 18.03683],
                [105.7409226, 18.0363219],
                [105.7423044, 18.0353747],
                [105.7446011, 18.0353104],
                [105.7459037, 18.0350224],
                [105.7484331, 18.0342262],
                [105.7500418, 18.0339393],
                [105.7505801, 18.0333552],
                [105.7507365, 18.0325497],
                [105.751657, 18.0321137],
                [105.752731, 18.0315315],
                [105.7533502, 18.0299218],
                [105.7535074, 18.0289698],
                [105.7548903, 18.0277294],
                [105.7566551, 18.0267104],
                [105.7580367, 18.025763],
                [105.7594171, 18.0251821],
                [105.7622502, 18.0248998],
                [105.7657727, 18.0245467],
                [105.7686834, 18.0240448],
                [105.7697568, 18.0236092],
                [105.7719852, 18.0214925],
                [105.774137, 18.0193755],
                [105.7749899, 18.0183349],
                [105.7757637, 18.0175509],
                [105.7763296, 18.0173071],
                [105.7774134, 18.0170511],
                [105.7787946, 18.0174639],
                [105.7804346, 18.0183553],
                [105.7816131, 18.0190973],
                [105.7829928, 18.0208727],
                [105.7845778, 18.0226982],
                [105.7860105, 18.0240805],
                [105.7872402, 18.0248718],
                [105.7885226, 18.0253192],
                [105.789447, 18.0253718],
                [105.7905268, 18.0251298],
                [105.7916069, 18.0247895],
                [105.7929928, 18.0249913],
                [105.7938662, 18.0249945],
                [105.7952559, 18.0243111],
                [105.7963875, 18.0239218],
                [105.7972104, 18.0236788],
                [105.7979795, 18.0240258],
                [105.7997738, 18.0249176],
                [105.8013113, 18.0258083],
                [105.8031081, 18.0261099],
                [105.8054199, 18.026069],
                [105.8074238, 18.0259287],
                [105.8089139, 18.0258357],
                [105.8099916, 18.0260854],
                [105.8105032, 18.0266281],
                [105.8107587, 18.0269733],
                [105.8106029, 18.0274154],
                [105.8092089, 18.02923],
                [105.808279, 18.0305545],
                [105.807814, 18.0312413],
                [105.8078119, 18.0317822],
                [105.8082216, 18.0320788],
                [105.8104766, 18.0334637],
                [105.8121173, 18.0342565],
                [105.814065, 18.0353945],
                [105.8150896, 18.0361358],
                [105.8157548, 18.0368266],
                [105.8166241, 18.0378624],
                [105.816829, 18.0380107],
                [105.8177548, 18.0377189],
                [105.8188885, 18.0368377],
                [105.8194059, 18.0358559],
                [105.8200789, 18.0345306],
                [105.8206961, 18.034021],
                [105.8227932, 18.0336271],
                [105.8243039, 18.033151],
                [105.8258162, 18.0321933],
                [105.8267392, 18.0319558],
                [105.8277456, 18.0317988],
                [105.8285786, 18.031876],
                [105.8302043, 18.0331292],
                [105.8317541, 18.0352225],
                [105.8335296, 18.0373943],
                [105.8344715, 18.0390997],
                [105.8351772, 18.0405773],
                [105.8350533, 18.0419954],
                [105.8342758, 18.0438651],
                [105.8336191, 18.045111],
                [105.8318377, 18.0459561],
                [105.8298195, 18.0467434],
                [105.8266452, 18.0475267],
                [105.8247473, 18.0478605],
                [105.821656, 18.0502327],
                [105.8184499, 18.0515832],
                [105.8145339, 18.0525906],
                [105.8129918, 18.0528121],
                [105.8168444, 18.0574737],
                [105.8197324, 18.0608522],
                [105.8207471, 18.0592999],
                [105.8219934, 18.059462],
                [105.8230789, 18.0600592],
                [105.8231714, 18.0601309],
                [105.8241837, 18.0609157],
                [105.825564, 18.0620421],
                [105.8270538, 18.0630985],
                [105.8277549, 18.0636056],
                [105.828501, 18.0638185],
                [105.8299952, 18.0637396],
                [105.8321495, 18.0633686],
                [105.8332644, 18.0629647],
                [105.8338787, 18.0623079],
                [105.8360916, 18.0637064],
                [105.8391422, 18.0661327],
                [105.8411309, 18.0661395],
                [105.8431204, 18.0659267],
                [105.8450346, 18.0654207],
                [105.8463382, 18.0645467],
                [105.8485267, 18.0642195],
                [105.8498097, 18.0666985],
                [105.8500696, 18.0669169],
                [105.8502066, 18.0670181],
                [105.8504932, 18.0672039],
                [105.8508113, 18.0673741],
                [105.850976, 18.0674491],
                [105.8513149, 18.0675781],
                [105.8533641, 18.0675718],
                [105.8580261, 18.0671929],
                [105.8653103, 18.0667006],
                [105.8659323, 18.0666329],
                [105.8665531, 18.0665562],
                [105.8674861, 18.0664235],
                [105.8679513, 18.0663494],
                [105.8683301, 18.0663152],
                [105.8685202, 18.0663079],
                [105.8689007, 18.0663133],
                [105.8693265, 18.066351],
                [105.8695379, 18.0663822],
                [105.8699556, 18.0664695],
                [105.8701613, 18.0665252],
                [105.8706269, 18.0666694],
                [105.8708866, 18.0667578],
                [105.8713961, 18.0669586],
                [105.8718908, 18.0671904],
                [105.8723688, 18.0674524],
                [105.8728279, 18.0677434],
                [105.8730498, 18.0678994],
                [105.8734769, 18.0682317],
                [105.8736817, 18.0684075],
                [105.8740728, 18.0687778],
                [105.8742586, 18.0689718],
                [105.8745704, 18.0693729],
                [105.8747148, 18.0695811],
                [105.8749798, 18.0700116],
                [105.8750999, 18.0702333],
                [105.8753149, 18.0706882],
                [105.8754842, 18.0711246],
                [105.8756225, 18.0715707],
                [105.8756798, 18.0717968],
                [105.8757706, 18.0722537],
                [105.875828, 18.0724511],
                [105.8759087, 18.072641],
                [105.8759655, 18.072746],
                [105.8760294, 18.0728472],
                [105.8761775, 18.0730364],
                [105.8763506, 18.0732055],
                [105.8799893, 18.0757194],
                [105.8801128, 18.0758417],
                [105.8803434, 18.0761004],
                [105.8804501, 18.0762364],
                [105.8806452, 18.0765202],
                [105.8807394, 18.0766782],
                [105.8809052, 18.0770054],
                [105.8810398, 18.0773453],
                [105.8811991, 18.0778924],
                [105.8813345, 18.0782808],
                [105.8814986, 18.078659],
                [105.8816958, 18.0790345],
                [105.8818079, 18.0792215],
                [105.8820537, 18.0795829],
                [105.8823272, 18.0799258],
                [105.8826268, 18.0802483],
                [105.8858788, 18.0829131],
                [105.8862703, 18.08344],
                [105.8864748, 18.0836974],
                [105.8869007, 18.0841996],
                [105.8873888, 18.0847257],
                [105.8879018, 18.08523],
                [105.8879817, 18.0852712],
                [105.8881504, 18.085335],
                [105.8882343, 18.0853564],
                [105.8884059, 18.085381],
                [105.8903391, 18.085335],
                [105.8904049, 18.0853403],
                [105.890533, 18.0853704],
                [105.8906516, 18.0854253],
                [105.8907076, 18.0854628],
                [105.890759, 18.0855058],
                [105.8908454, 18.0856067],
                [105.8916855, 18.0870612],
                [105.8920229, 18.0875167],
                [105.8922022, 18.0877371],
                [105.8925811, 18.0881622],
                [105.8927803, 18.0883665],
                [105.8931976, 18.0887578],
                [105.8934301, 18.0889567],
                [105.8936691, 18.0891485],
                [105.8939144, 18.089333],
                [105.8941657, 18.08951],
                [105.8944228, 18.0896793],
                [105.8946855, 18.0898407],
                [105.8947576, 18.0898754],
                [105.8949099, 18.0899261],
                [105.8949986, 18.0899432],
                [105.8951792, 18.0899523],
                [105.8990181, 18.0895684],
                [105.9060997, 18.0889842],
                [105.9116837, 18.0872187],
                [105.9121698, 18.0870119],
                [105.9126406, 18.086775],
                [105.9130937, 18.086509],
                [105.9135348, 18.0862098],
                [105.9137506, 18.0860469],
                [105.9141642, 18.0857007],
                [105.9145518, 18.0853283],
                [105.9149117, 18.0849316],
                [105.917152, 18.0821386],
                [105.9173842, 18.0818683],
                [105.9175094, 18.0817406],
                [105.9177768, 18.0815014],
                [105.9179184, 18.0813904],
                [105.9182252, 18.0811809],
                [105.9185506, 18.0809985],
                [105.9187194, 18.0809178],
                [105.9188822, 18.0808784],
                [105.9189659, 18.0808701],
                [105.91905, 18.0808696],
                [105.9192165, 18.0808916],
                [105.9193837, 18.0809473],
                [105.9195356, 18.0810341],
                [105.9196658, 18.0811484],
                [105.919721, 18.0812144],
                [105.9198165, 18.0813688],
                [105.9198911, 18.0815447],
                [105.9199368, 18.0817293],
                [105.9199554, 18.0819035],
                [105.9199971, 18.0820587],
                [105.9200695, 18.0821954],
                [105.9201144, 18.0822547],
                [105.9202217, 18.0823589],
                [105.9202831, 18.0824028],
                [105.9227861, 18.0839436],
                [105.9229884, 18.0841268],
                [105.9231976, 18.0843028],
                [105.9234133, 18.0844716],
                [105.9238469, 18.0847754],
                [105.9240634, 18.0849112],
                [105.9245103, 18.0851616],
                [105.9247401, 18.085276],
                [105.9267493, 18.086208],
                [105.9273442, 18.08653],
                [105.927923, 18.0868774],
                [105.9282352, 18.0870798],
                [105.9285419, 18.0872895],
                [105.928843, 18.0875065],
                [105.9291382, 18.0877307],
                [105.9301463, 18.0884132],
                [105.9319208, 18.0890892],
                [105.9320208, 18.0891102],
                [105.9321223, 18.0891235],
                [105.932327, 18.0891265],
                [105.9325327, 18.0890975],
                [105.9327337, 18.089036],
                [105.9328294, 18.0889935],
                [105.9330082, 18.0888868],
                [105.9342375, 18.0878319],
                [105.9344675, 18.087592],
                [105.9346712, 18.0873315],
                [105.9348428, 18.0870596],
                [105.9349857, 18.086773],
                [105.9350985, 18.0864746],
                [105.9351433, 18.0863218],
                [105.9356368, 18.0838537],
                [105.9357, 18.0837401],
                [105.9357716, 18.0836311],
                [105.9358514, 18.0835273],
                [105.9360079, 18.0833611],
                [105.936094, 18.0832854],
                [105.936185, 18.0832149],
                [105.936565, 18.0829615],
                [105.9371444, 18.0825994],
                [105.9374392, 18.0824259],
                [105.9377845, 18.0819462],
                [105.9379456, 18.0816991],
                [105.9382439, 18.0811918],
                [105.9383841, 18.0809255],
                [105.9385156, 18.0806552],
                [105.9386382, 18.0803813],
                [105.9387519, 18.0801038],
                [105.9388565, 18.0798231],
                [105.938952, 18.0795395],
                [105.941248, 18.0775507],
                [105.9427929, 18.0760004],
                [105.9429545, 18.0758691],
                [105.9432927, 18.0756241],
                [105.943638, 18.0754104],
                [105.9439869, 18.0752266],
                [105.9447885, 18.0748683],
                [105.9450334, 18.0747268],
                [105.9452612, 18.0745615],
                [105.9453678, 18.0744705],
                [105.9454824, 18.0743611],
                [105.9455898, 18.0742454],
                [105.9456897, 18.0741237],
                [105.9458095, 18.0739037],
                [105.9459014, 18.0736719],
                [105.9459674, 18.0734142],
                [105.9459885, 18.0732741],
                [105.9459996, 18.073133],
                [105.9460008, 18.0729916],
                [105.9459579, 18.0716656],
                [105.9468377, 18.0709517],
                [105.9469795, 18.0707786],
                [105.9470393, 18.0706847],
                [105.947135, 18.070485],
                [105.9471977, 18.0702698],
                [105.9472156, 18.0701573],
                [105.9472239, 18.0700439],
                [105.9472025, 18.0696002],
                [105.9472065, 18.0695078],
                [105.9472416, 18.0693259],
                [105.9472722, 18.0692381],
                [105.9473134, 18.0691493],
                [105.9473636, 18.0690648],
                [105.9474221, 18.0689853],
                [105.9474885, 18.0689115],
                [105.9475622, 18.0688443],
                [105.9490693, 18.067698],
                [105.9503031, 18.0662712],
                [105.9504316, 18.0660758],
                [105.9504845, 18.065972],
                [105.950566, 18.0657549],
                [105.9506127, 18.0655365],
                [105.950624, 18.0654255],
                [105.9506221, 18.0652027],
                [105.9506089, 18.0650919],
                [105.9498739, 18.0600785],
                [105.9498694, 18.0599652],
                [105.9498851, 18.0597389],
                [105.9499334, 18.0595169],
                [105.9500134, 18.0593033],
                [105.9500651, 18.0592007],
                [105.9501901, 18.0590069],
                [105.9503421, 18.0588314],
                [105.9505182, 18.0586775],
                [105.9506142, 18.0586097],
                [105.9515691, 18.0580997],
                [105.9518791, 18.0579865],
                [105.9521998, 18.0579044],
                [105.9525321, 18.0578538],
                [105.9528727, 18.0578365],
                [105.9532132, 18.0578538],
                [105.9533823, 18.0578753],
                [105.9538388, 18.057975],
                [105.9542931, 18.0580838],
                [105.9547448, 18.0582017],
                [105.9556077, 18.0584543],
                [105.9560349, 18.0585931],
                [105.9568799, 18.0588953],
                [105.9574426, 18.0590529],
                [105.9580333, 18.0591821],
                [105.9583411, 18.0592352],
                [105.9586506, 18.059279],
                [105.9602058, 18.0594359],
                [105.9616435, 18.0596705],
                [105.961777, 18.0596861],
                [105.9620458, 18.0596944],
                [105.9623137, 18.0596721],
                [105.9625769, 18.0596195],
                [105.9628448, 18.0595322],
                [105.9629739, 18.0594767],
                [105.9630611, 18.0594296],
                [105.9632251, 18.0593197],
                [105.9633011, 18.0592574],
                [105.9634431, 18.0591153],
                [105.9635063, 18.0590378],
                [105.9636789, 18.0587902],
                [105.9639258, 18.0584687],
                [105.9642092, 18.0581475],
                [105.9643673, 18.0579865],
                [105.9645313, 18.0578311],
                [105.966788, 18.0558301],
                [105.9671345, 18.0558144],
                [105.967308, 18.0558178],
                [105.9674812, 18.0558288],
                [105.967825, 18.0558732],
                [105.968174, 18.0559502],
                [105.9685244, 18.056062],
                [105.9688616, 18.0562057],
                [105.9691826, 18.0563798],
                [105.9693361, 18.0564778],
                [105.971632, 18.0582323],
                [105.9747219, 18.06219],
                [105.9748421, 18.0623033],
                [105.9750983, 18.0625137],
                [105.9753738, 18.0627009],
                [105.9756661, 18.0628632],
                [105.97583, 18.06294],
                [105.976169, 18.0630701],
                [105.9763432, 18.063123],
                [105.9766986, 18.0632038],
                [105.9768791, 18.0632316],
                [105.977567, 18.0633091],
                [105.9780721, 18.0633761],
                [105.9790778, 18.0635364],
                [105.9799494, 18.0637044],
                [105.9803829, 18.0637983],
                [105.9812451, 18.0640056],
                [105.9818603, 18.0640938],
                [105.9821695, 18.0641265],
                [105.98279, 18.0641688],
                [105.9834291, 18.0641802],
                [105.9840679, 18.0641592],
                [105.9843866, 18.0641365],
                [105.9850216, 18.0640668],
                [105.9863091, 18.063516],
                [105.9863423, 18.063304],
                [105.9863856, 18.0630936],
                [105.986439, 18.0628853],
                [105.9865626, 18.0625098],
                [105.9867035, 18.0621775],
                [105.9867838, 18.0620153],
                [105.9869635, 18.0617004],
                [105.9894204, 18.0607212],
                [105.9900072, 18.0602619],
                [105.9905714, 18.0597779],
                [105.991112, 18.0592701],
                [105.9916367, 18.0587298],
                [105.9921431, 18.0581571],
                [105.9923855, 18.0578624],
                [105.9928482, 18.0572572],
                [105.9930682, 18.056947],
                [105.9935862, 18.0558306],
                [105.9940767, 18.0547029],
                [105.9945256, 18.0536001],
                [105.9947402, 18.0530451],
                [105.9951282, 18.0517447],
                [105.997746, 18.0499902],
                [106.0001707, 18.0480316],
                [106.0004071, 18.0478015],
                [106.0006362, 18.0475648],
                [106.0008579, 18.0473218],
                [106.0010719, 18.0470728],
                [106.0014641, 18.0465738],
                [106.0016488, 18.0463165],
                [106.0019946, 18.0457874],
                [106.0025541, 18.0456261],
                [106.003102, 18.045432],
                [106.0036361, 18.0452059],
                [106.0041462, 18.0449532],
                [106.004639, 18.0446713],
                [106.0051128, 18.0443612],
                [106.0065651, 18.0432881],
                [106.0070159, 18.0428618],
                [106.0072334, 18.0426413],
                [106.0076518, 18.0421862],
                [106.0078526, 18.0419518],
                [106.0082688, 18.0414275],
                [106.0084663, 18.041158],
                [106.0088396, 18.0406052],
                [106.0089681, 18.0404322],
                [106.0091044, 18.0402648],
                [106.0092485, 18.0401033],
                [106.0093999, 18.039948],
                [106.0095585, 18.0397993],
                [106.0099714, 18.0394583],
                [106.01017, 18.0392795],
                [106.0105387, 18.0389183],
                [106.0108746, 18.038551],
                [106.0114861, 18.037803],
                [106.0118051, 18.0374562],
                [106.0121622, 18.0371123],
                [106.0123579, 18.0369408],
                [106.0125594, 18.0367755],
                [106.0141048, 18.0355592],
                [106.014719, 18.0349025],
                [106.0148429, 18.0348109],
                [106.0149718, 18.0347258],
                [106.0151052, 18.0346474],
                [106.0152363, 18.0345791],
                [106.0153708, 18.0345173],
                [106.0155085, 18.0344621],
                [106.015649, 18.0344136],
                [106.0157919, 18.034372],
                [106.0158449, 18.0342477],
                [106.0158911, 18.0341209],
                [106.0159305, 18.0339921],
                [106.0159629, 18.0338616],
                [106.0159882, 18.0337296],
                [106.0160065, 18.0335966],
                [106.0160187, 18.0334394],
                [106.0160211, 18.0332817],
                [106.0160135, 18.0331241],
                [106.0159959, 18.0329673],
                [106.0159685, 18.0328118],
                [106.0159314, 18.0326581],
                [106.015749, 18.0318011],
                [106.0157377, 18.0316381],
                [106.0157341, 18.0314748],
                [106.0157382, 18.0313114],
                [106.0157489, 18.0311609],
                [106.015766, 18.0310109],
                [106.0157896, 18.0308617],
                [106.0158197, 18.0307135],
                [106.0158563, 18.0305667],
                [106.0166395, 18.0294751],
                [106.0170901, 18.0284651],
                [106.0172195, 18.0282499],
                [106.017369, 18.0280468],
                [106.0174761, 18.0279223],
                [106.0175908, 18.027804],
                [106.0177126, 18.0276923],
                [106.0178411, 18.0275877],
                [106.0194397, 18.0264144],
                [106.0197095, 18.0261676],
                [106.0198367, 18.026037],
                [106.0200636, 18.0257756],
                [106.0201692, 18.0256391],
                [106.0204009, 18.0254104],
                [106.020654, 18.0251955],
                [106.0207889, 18.0250935],
                [106.0210705, 18.0249045],
                [106.0226959, 18.0241036],
                [106.023067, 18.0239574],
                [106.0234469, 18.0238333],
                [106.0239096, 18.0237148],
                [106.0241442, 18.0236679],
                [106.0243803, 18.0236292],
                [106.0260379, 18.0233946],
                [106.0272024, 18.0231171],
                [106.0277867, 18.0229865],
                [106.0290585, 18.0227216],
                [106.0296965, 18.0225988],
                [106.0303359, 18.0222773],
                [106.0306513, 18.0221091],
                [106.0313863, 18.0216907],
                [106.0317017, 18.0215775],
                [106.0318637, 18.0215326],
                [106.0320378, 18.0214938],
                [106.0322139, 18.0214641],
                [106.0323915, 18.0214436],
                [106.03257, 18.0214325],
                [106.0330115, 18.021435],
                [106.0335356, 18.0214691],
                [106.0340561, 18.0215368],
                [106.0343143, 18.0215833],
                [106.0348248, 18.021701],
                [106.0348785, 18.0218623],
                [106.0349643, 18.0221907],
                [106.0350118, 18.0224553],
                [106.0350611, 18.0228969],
                [106.0351252, 18.0232007],
                [106.0352223, 18.0235019],
                [106.0353506, 18.0237925],
                [106.0363483, 18.0256289],
                [106.0367668, 18.0259961],
                [106.0396528, 18.0256187],
                [106.0420829, 18.0248586],
                [106.0421572, 18.024839],
                [106.042233, 18.0248256],
                [106.0423098, 18.0248184],
                [106.0423869, 18.0248175],
                [106.0424638, 18.0248229],
                [106.0425439, 18.0248353],
                [106.0426224, 18.0248547],
                [106.0426989, 18.0248806],
                [106.0427725, 18.0249131],
                [106.0428427, 18.0249518],
                [106.042909, 18.0249963],
                [106.0436493, 18.0255881],
                [106.0437367, 18.0256247],
                [106.0438274, 18.0256535],
                [106.0439205, 18.0256741],
                [106.0440152, 18.0256863],
                [106.0441107, 18.0256901],
                [106.0442048, 18.0256855],
                [106.0442981, 18.0256726],
                [106.0443897, 18.0256516],
                [106.0444789, 18.0256227],
                [106.044565, 18.0255861],
                [106.0446471, 18.0255421],
                [106.0447618, 18.0254626],
                [106.0448717, 18.0253772],
                [106.0449766, 18.0252863],
                [106.0450762, 18.0251902],
                [106.0451642, 18.0250958],
                [106.045247, 18.0249974],
                [106.0453244, 18.0248951],
                [106.0453963, 18.0247893],
                [106.0454625, 18.02468],
                [106.0458273, 18.0238231],
                [106.0459488, 18.0237401],
                [106.0460759, 18.0236651],
                [106.0462081, 18.0235986],
                [106.0463255, 18.0235483],
                [106.0464457, 18.0235047],
                [106.0465685, 18.0234679],
                [106.0466933, 18.0234379],
                [106.0468197, 18.023415],
                [106.047079, 18.0233622],
                [106.0473366, 18.0233021],
                [106.0475922, 18.0232347],
                [106.0478455, 18.0231601],
                [106.0480964, 18.0230783],
                [106.0485041, 18.022762],
                [106.049341, 18.0224865],
                [106.0493736, 18.0224037],
                [106.0493987, 18.0223184],
                [106.0494161, 18.0222315],
                [106.0494245, 18.0221577],
                [106.0494273, 18.0220835],
                [106.0494245, 18.0220094],
                [106.0494161, 18.0219356],
                [106.0493624, 18.021099],
                [106.0493691, 18.021007],
                [106.0493858, 18.0209162],
                [106.0494123, 18.0208275],
                [106.0494482, 18.0207419],
                [106.0494872, 18.0206703],
                [106.0495329, 18.0206023],
                [106.0495848, 18.0205385],
                [106.0496426, 18.0204795],
                [106.0497057, 18.0204256],
                [106.0502207, 18.0200316],
                [106.0502665, 18.0199851],
                [106.0503077, 18.0199349],
                [106.050344, 18.0198814],
                [106.050375, 18.019825],
                [106.0504022, 18.0197614],
                [106.0504228, 18.0196955],
                [106.0504366, 18.0196281],
                [106.0504434, 18.0195597],
                [106.0504782, 18.0187575],
                [106.0504937, 18.018651],
                [106.0505187, 18.0185461],
                [106.0505529, 18.0184436],
                [106.0505962, 18.0183443],
                [106.0506446, 18.018255],
                [106.0507003, 18.0181696],
                [106.050763, 18.0180886],
                [106.0508323, 18.0180127],
                [106.0517549, 18.0176913],
                [106.0529137, 18.0171659],
                [106.0530472, 18.0171197],
                [106.0531839, 18.0170827],
                [106.053323, 18.017055],
                [106.0534638, 18.0170368],
                [106.0536057, 18.0170282],
                [106.0537304, 18.0170285],
                [106.0538549, 18.0170363],
                [106.0539787, 18.0170514],
                [106.0541012, 18.0170739],
                [106.054222, 18.0171036],
                [106.0543406, 18.0171404],
                [106.0545095, 18.0171847],
                [106.0546807, 18.0172201],
                [106.0548536, 18.0172467],
                [106.0550279, 18.0172643],
                [106.0552028, 18.0172729],
                [106.055378, 18.0172724],
                [106.055553, 18.0172628],
                [106.0567439, 18.0171761],
                [106.0568763, 18.017076],
                [106.0570047, 18.0169711],
                [106.0571286, 18.0168615],
                [106.0572481, 18.0167476],
                [106.0573786, 18.0166124],
                [106.0575027, 18.016472],
                [106.0576202, 18.0163265],
                [106.0577309, 18.0161762],
                [106.0585302, 18.0150131],
                [106.0586283, 18.0148871],
                [106.0587318, 18.0147651],
                [106.0588404, 18.0146472],
                [106.058954, 18.0145336],
                [106.0590719, 18.0144249],
                [106.0591943, 18.014321],
                [106.0593212, 18.0142218],
                [106.0594521, 18.0141277],
                [106.059587, 18.0140387],
                [106.0603541, 18.0137684],
                [106.0604291, 18.013712],
                [106.0604975, 18.0136485],
                [106.0605585, 18.0135786],
                [106.0606116, 18.0135031],
                [106.0606558, 18.0134234],
                [106.0606911, 18.0133398],
                [106.0607171, 18.0132531],
                [106.0607336, 18.0131644],
                [106.0607403, 18.0130745],
                [106.0607312, 18.0128919],
                [106.0607133, 18.0127098],
                [106.0606867, 18.0125287],
                [106.060658, 18.0123788],
                [106.0606234, 18.0122301],
                [106.0605828, 18.0120827],
                [106.0605365, 18.0119369],
                [106.0602388, 18.0115492],
                [106.0601445, 18.0114689],
                [106.060055, 18.0113838],
                [106.0599706, 18.0112941],
                [106.0598867, 18.0111942],
                [106.0598091, 18.0110898],
                [106.059738, 18.0109813],
                [106.0596738, 18.010869],
                [106.0596165, 18.0107534],
                [106.0595699, 18.0106424],
                [106.0595302, 18.0105291],
                [106.0594974, 18.0104137],
                [106.0594718, 18.0102967],
                [106.0594534, 18.0101786],
                [106.0594422, 18.0100596],
                [106.0587877, 18.0087433],
                [106.0587543, 18.0086816],
                [106.0587286, 18.0086166],
                [106.0587109, 18.0085492],
                [106.0587014, 18.0084804],
                [106.0587004, 18.0084109],
                [106.0587077, 18.0083418],
                [106.0587233, 18.008274],
                [106.0587467, 18.008209],
                [106.0587777, 18.008147],
                [106.0588159, 18.0080888],
                [106.0588609, 18.008035],
                [106.0589119, 18.0079865],
                [106.0589684, 18.0079437],
                [106.0590297, 18.0079074],
                [106.0590949, 18.0078779],
                [106.0591632, 18.0078557],
                [106.0593423, 18.0077887],
                [106.0595176, 18.0077132],
                [106.0596886, 18.0076293],
                [106.0598549, 18.0075372],
                [106.0600162, 18.0074373],
                [106.0601637, 18.0073357],
                [106.0603059, 18.0072274],
                [106.0604425, 18.0071127],
                [106.0605732, 18.006992],
                [106.0606976, 18.0068654],
                [106.0608154, 18.0067333],
                [106.0622156, 18.0050089],
                [106.0623494, 18.0048514],
                [106.0624889, 18.0046985],
                [106.0626339, 18.0045503],
                [106.0627842, 18.0044069],
                [106.0629294, 18.0042775],
                [106.0630789, 18.0041526],
                [106.0632326, 18.0040325],
                [106.0633904, 18.0039171],
                [106.065107, 18.0043661],
                [106.0660618, 18.0038355],
                [106.0669094, 18.0028866],
                [106.0669302, 18.0027523],
                [106.0669443, 18.0026172],
                [106.0669517, 18.0024816],
                [106.0669523, 18.0023458],
                [106.0669449, 18.0021911],
                [106.0669288, 18.0020371],
                [106.0669041, 18.0018841],
                [106.0667431, 18.0010959],
                [106.0666949, 17.9995194],
                [106.0666967, 17.9993853],
                [106.0667056, 17.9992515],
                [106.0667233, 17.9991074],
                [106.0667492, 17.9989644],
                [106.0667834, 17.998823],
                [106.067639, 17.9988561],
                [106.067846, 17.9989016],
                [106.0680547, 17.9989398],
                [106.0682648, 17.9989705],
                [106.0684758, 17.9989939],
                [106.0686811, 17.9990094],
                [106.0688868, 17.9990179],
                [106.0690927, 17.9990194],
                [106.0698491, 17.9981419],
                [106.0699284, 17.9980139],
                [106.0700014, 17.9978826],
                [106.070068, 17.9977483],
                [106.0701281, 17.9976113],
                [106.0701863, 17.9974582],
                [106.0702364, 17.9973026],
                [106.0702783, 17.9971449],
                [106.0703118, 17.9969853],
                [106.0703368, 17.9968243],
                [106.0703534, 17.9966623],
                [106.0704475, 17.996459],
                [106.0705496, 17.9962593],
                [106.0706595, 17.9960633],
                [106.0707772, 17.9958715],
                [106.0708972, 17.9956914],
                [106.0710241, 17.9955156],
                [106.0711576, 17.9953442],
                [106.0712975, 17.9951776],
                [106.0712757, 17.9944623],
                [106.0707564, 17.9934173],
                [106.0697861, 17.9925785],
                [106.0677543, 17.9915914],
                [106.067406, 17.9906956],
                [106.0671954, 17.9892698],
                [106.066381, 17.9882158],
                [106.0655547, 17.9880142],
                [106.0646523, 17.9878494],
                [106.0644217, 17.9875331],
                [106.0644636, 17.9866543],
                [106.0635474, 17.9860087],
                [106.0622247, 17.9853371],
                [106.061948, 17.9849591],
                [106.0615723, 17.9839356],
                [106.0619269, 17.9829217],
                [106.0617416, 17.9816646],
                [106.0614109, 17.9811774],
                [106.0609842, 17.9799169],
                [106.0596135, 17.9782273],
                [106.059391, 17.9769594],
                [106.0591248, 17.9757992],
                [106.0584986, 17.9745588],
                [106.0566771, 17.9725069],
                [106.0560544, 17.9721215],
                [106.0551186, 17.9721621],
                [106.0544951, 17.9721179],
                [106.0540072, 17.9712209],
                [106.0537435, 17.9698124],
                [106.0533451, 17.9687876],
                [106.0530784, 17.9685311],
                [106.0517421, 17.9684427],
                [106.0510296, 17.968313],
                [106.0491185, 17.9665168],
                [106.047919, 17.9651915],
                [106.0474771, 17.9637826],
                [106.0477904, 17.9632287],
                [106.0487728, 17.9622925],
                [106.0492209, 17.9612695],
                [106.049315, 17.9593074],
                [106.0491411, 17.9576005],
                [106.0484333, 17.9556364],
                [106.0476359, 17.9539281],
                [106.046929, 17.9516225],
                [106.0469518, 17.9500236],
                [106.0469539, 17.9491933],
                [106.0464683, 17.9483098],
                [106.0456582, 17.94727],
                [106.0454978, 17.9464393],
                [106.0461303, 17.9462831],
                [106.0473826, 17.9464244],
                [106.0485392, 17.9462424],
                [106.0491184, 17.9458746],
                [106.0499873, 17.9451846],
                [106.0509513, 17.9450023],
                [106.0523491, 17.9447748],
                [106.0535544, 17.9444084],
                [106.0546637, 17.9439496],
                [106.0553394, 17.9434896],
                [106.0560152, 17.9429375],
                [106.0569328, 17.9420167],
                [106.0577051, 17.9414648],
                [106.0584287, 17.9410973],
                [106.0589118, 17.9405447],
                [106.0591545, 17.939807],
                [106.059348, 17.9394844],
                [106.06036, 17.9393482],
                [106.0614146, 17.9391468],
                [106.062927, 17.9384267],
                [106.0638345, 17.9379946],
                [106.0645654, 17.9376329],
                [106.0669669, 17.9376459],
                [106.0694289, 17.9371952],
                [106.0703689, 17.9368375],
                [106.0722101, 17.9361368],
                [106.0753903, 17.9341669],
                [106.0769802, 17.9332579],
                [106.0795213, 17.9328832],
                [106.0809503, 17.9328863],
                [106.082931, 17.9325657],
                [106.0862036, 17.9324078],
                [106.0902791, 17.9324713],
                [106.0924605, 17.932476],
                [106.0944763, 17.9319209],
                [106.0964247, 17.9310547],
                [106.0987798, 17.9303447],
                [106.1009048, 17.9299093],
                [106.1024558, 17.9294726],
                [106.1036531, 17.9291489],
                [106.1054428, 17.9285102],
                [106.1061896, 17.9277266],
                [106.107978, 17.9276589],
                [106.110213, 17.9278775],
                [106.1112563, 17.9277368],
                [106.1129719, 17.9269552],
                [106.1146853, 17.9271013],
                [106.1169197, 17.927534],
                [106.1187076, 17.9277516],
                [106.1198263, 17.9272542],
                [106.1207224, 17.9263282],
                [106.1215439, 17.9254733],
                [106.1232161, 17.9248619],
                [106.1261304, 17.9238572],
                [106.1287449, 17.9223463],
                [106.1308053, 17.9208343],
                [106.132352, 17.920618],
                [106.1341232, 17.92093],
                [106.1358403, 17.9213447],
                [106.1371289, 17.9213471],
                [106.1380419, 17.9211944],
                [106.1384185, 17.9208352],
                [106.1386351, 17.9199098],
                [106.1387976, 17.9192416],
                [106.1392816, 17.9188825],
                [106.1433627, 17.9184785],
                [106.14594, 17.918329],
                [106.1482868, 17.9189484],
                [106.1506454, 17.9192351],
                [106.1528601, 17.9178267],
                [106.155811, 17.9168432],
                [106.1589075, 17.9168486],
                [106.1627405, 17.917279],
                [106.1640199, 17.9168985],
                [106.1651011, 17.9165769],
                [106.1673505, 17.9151814],
                [106.1688941, 17.9145177],
                [106.1714853, 17.91446],
                [106.1731036, 17.9150212],
                [106.1750452, 17.9160172],
                [106.1763386, 17.9171362],
                [106.1778539, 17.9185356],
                [106.179146, 17.9187446],
                [106.1803301, 17.9198195],
                [106.1819756, 17.920832],
                [106.1832932, 17.9210236],
                [106.1844714, 17.9209815],
                [106.1856223, 17.9208093],
                [106.187015, 17.9208696],
                [106.1880437, 17.9213933],
                [106.1888293, 17.9223808],
                [106.189797, 17.9231365],
                [106.1909474, 17.9232543],
                [106.1924622, 17.9229087],
                [106.1936144, 17.9220403],
                [106.1941617, 17.9206489],
                [106.1950129, 17.9187359],
                [106.195559, 17.9180987],
                [106.1971353, 17.9170569],
                [106.1987685, 17.9164127],
                [106.199796, 17.91648],
                [106.2009592, 17.9174006],
                [106.201712, 17.9178611],
                [106.2031018, 17.9190855],
                [106.204822, 17.9218843],
                [106.2060132, 17.9237926],
                [106.207216, 17.9250121],
                [106.2091801, 17.9259563],
                [106.2117357, 17.925866],
                [106.2139952, 17.9264342],
                [106.2162542, 17.9273789],
                [106.2181049, 17.9278082],
                [106.2193133, 17.9274794],
                [106.2203483, 17.9276462],
                [106.2215546, 17.9287222],
                [106.2227608, 17.9297982],
                [106.2237957, 17.9300477],
                [106.2252633, 17.9295539],
                [106.227335, 17.9288957],
                [106.230354, 17.9293131],
                [106.2324249, 17.9291506],
                [106.2329705, 17.9290133],
                [106.233958, 17.9286356],
                [106.2350229, 17.9276169],
                [106.2360731, 17.9272704],
                [106.2368804, 17.9273101],
                [106.2374453, 17.9276202],
                [106.2380098, 17.9281237],
                [106.2401062, 17.9302148],
                [106.2407116, 17.9303317],
                [106.2411156, 17.9301775],
                [106.2415199, 17.9297912],
                [106.242491, 17.9283617],
                [106.2429358, 17.9278209],
                [106.2435421, 17.9273577],
                [106.2442697, 17.9268173],
                [106.2455223, 17.9260841],
                [106.2466532, 17.9257376],
                [106.247784, 17.9254296],
                [106.248592, 17.9251213],
                [106.2489155, 17.9247736],
                [106.249118, 17.9243099],
                [106.2493207, 17.9236141],
                [106.2494731, 17.9231784],
                [106.2502756, 17.9227883],
                [106.2512943, 17.921883],
                [106.2522982, 17.9208623],
                [106.2550521, 17.919579],
                [106.2576597, 17.9188074],
                [106.2586504, 17.9173451],
                [106.2598209, 17.9157107],
                [106.261169, 17.9155368],
                [106.2615364, 17.9157143],
                [106.263566, 17.9141915],
                [106.2644138, 17.9147321],
                [106.2658158, 17.9165182],
                [106.2685583, 17.9183167],
                [106.2708394, 17.9197729],
                [106.2740084, 17.9198915],
                [106.2754358, 17.9194471],
                [106.27709, 17.9178374],
                [106.2786823, 17.9160692],
                [106.2802114, 17.9159868],
                [106.2810729, 17.915597],
                [106.2818702, 17.9145906],
                [106.2823255, 17.9126287],
                [106.2827889, 17.9110439],
                [106.2834413, 17.9096017],
                [106.2836571, 17.9108251],
                [106.28469, 17.9122005],
                [106.2854238, 17.9135278],
                [106.2865026, 17.9157254],
                [106.2882479, 17.9174345],
                [106.2896904, 17.9191501],
                [106.2912391, 17.9214564],
                [106.2905311, 17.9227118],
                [106.2888948, 17.9241528],
                [106.2869611, 17.9275925],
                [106.2859733, 17.929425],
                [106.284298, 17.9348348],
                [106.2853432, 17.9377342],
                [106.2858855, 17.939536],
                [106.2873034, 17.9420134],
                [106.288888, 17.9430124],
                [106.2919802, 17.9432244],
                [106.2938316, 17.9436699],
                [106.2953931, 17.9446137],
                [106.2961448, 17.9452243],
                [106.2964915, 17.9457789],
                [106.2969125, 17.9476761],
                [106.297365, 17.9501182],
                [106.2974533, 17.9525601],
                [106.297816, 17.9538685],
                [106.298362, 17.9541306],
                [106.2994544, 17.9543062],
                [106.3012741, 17.9556161],
                [106.3030933, 17.9574492],
                [106.3044569, 17.9595434],
                [106.3052741, 17.9616371],
                [106.3055821, 17.9625856],
                [106.305852, 17.9629094],
                [106.306325, 17.9629099],
                [106.3086903, 17.9625887],
                [106.3111904, 17.9625912],
                [106.313217, 17.963046],
                [106.3156489, 17.9636954],
                [106.3190276, 17.9634398],
                [106.3230823, 17.9629259],
                [106.324839, 17.9630569],
                [106.3253784, 17.9643516],
                [106.3252405, 17.9669397],
                [106.32495, 17.968793],
                [106.325306, 17.969574],
                [106.326011, 17.971175],
                [106.326082, 17.97135],
                [106.327676, 17.976179],
                [106.327736, 17.976359],
                [106.328693, 17.978616],
                [106.329219, 17.979855],
                [106.331514, 17.982316],
                [106.333945, 17.984518],
                [106.335782, 17.985631],
                [106.336073, 17.985864],
                [106.33651, 17.98626],
                [106.336831, 17.986705],
                [106.337085, 17.987215],
                [106.337325, 17.988029],
                [106.337505, 17.98877],
                [106.337628, 17.989521],
                [106.337818, 17.990462],
                [106.338134, 17.991001],
                [106.338318, 17.991162],
                [106.338456, 17.991526],
                [106.339491, 17.991858],
                [106.339883, 17.991804],
                [106.340586, 17.991708],
                [106.341647, 17.991564],
                [106.341905, 17.991529],
                [106.345442, 17.99021],
                [106.347512, 17.989551],
                [106.349295, 17.989446],
                [106.349633, 17.989461],
                [106.351244, 17.989553],
                [106.352046, 17.989676],
                [106.353433, 17.989985],
                [106.354455, 17.990224],
                [106.35622, 17.990714],
                [106.358463, 17.991046],
                [106.360619, 17.990882],
                [106.360909, 17.990752],
                [106.362085, 17.990223],
                [106.363628, 17.989016],
                [106.363707, 17.988954],
                [106.364079, 17.988736],
                [106.364795, 17.98854],
                [106.365479, 17.988391],
                [106.367202, 17.987955],
                [106.368085, 17.987821],
                [106.369219, 17.98767],
                [106.370531, 17.987496],
                [106.371593, 17.987213],
                [106.372425, 17.986888],
                [106.373206, 17.986531],
                [106.373808, 17.986188],
                [106.374074, 17.986103],
                [106.375693, 17.985601],
                [106.375794, 17.985594],
                [106.376395, 17.985653],
                [106.376812, 17.98573],
                [106.378999, 17.986274],
                [106.379212, 17.986261],
                [106.380368, 17.986188],
                [106.380688, 17.986168],
                [106.384069, 17.983798],
                [106.388236, 17.982506],
                [106.395556, 17.981648],
                [106.399047, 17.98165],
                [106.399067, 17.981631],
                [106.399739, 17.98165],
                [106.400292, 17.980617],
                [106.400526, 17.980235],
                [106.401638, 17.979171],
                [106.405694, 17.975831],
                [106.409489, 17.97298],
                [106.409564, 17.972924],
                [106.409563, 17.972915],
                [106.409731, 17.972963],
                [106.413959, 17.973947],
                [106.415695, 17.974535],
                [106.416953, 17.97435],
                [106.418518, 17.972766],
                [106.419833, 17.971342],
                [106.421043, 17.970407],
                [106.421832, 17.969945],
                [106.422447, 17.969669],
                [106.423126, 17.969605],
                [106.423834, 17.969756],
                [106.423934, 17.969813],
                [106.424581, 17.969844],
                [106.427746, 17.971018],
                [106.429236, 17.971465],
                [106.431013, 17.971997],
                [106.433464, 17.972683],
                [106.433844, 17.972706],
                [106.435099, 17.972781],
                [106.435667, 17.972405],
                [106.436426, 17.971902],
                [106.438469, 17.969947],
                [106.441737, 17.969263],
                [106.443473, 17.969068],
                [106.443473, 17.968978],
                [106.443473, 17.968744],
                [106.4440756, 17.9600706],
                [106.4443846, 17.9519545],
                [106.4450268, 17.9518318],
                [106.4470273, 17.951513],
                [106.4487315, 17.950733],
                [106.4503482, 17.9495119],
                [106.4526212, 17.9507694],
                [106.4558437, 17.9523667],
                [106.4575845, 17.9535379],
                [106.4588809, 17.9543186],
                [106.459844, 17.9545672],
                [106.4611063, 17.9547964],
                [106.4635994, 17.9551996],
                [106.4664083, 17.9556532],
                [106.4692677, 17.9562242],
                [106.4696708, 17.9562078],
                [106.4720678, 17.9561105],
                [106.4748679, 17.9554834],
                [106.4765361, 17.9545136],
                [106.4784427, 17.953601],
                [106.4793363, 17.9533157],
                [106.4805874, 17.9536011],
                [106.484936, 17.9558837],
                [106.4888678, 17.9579378],
                [106.4958238, 17.9646834],
                [106.5265162, 17.9865835],
                [106.7527613, 18.1309586],
                [106.6726332, 18.216763],
                [106.528599, 18.3148632],
                [106.3400906, 18.4225928],
                [106.3274137, 18.4389191],
                [106.3155736, 18.4515526],
                [106.3035176, 18.4624859],
                [106.2907046, 18.4723969],
                [106.2771034, 18.4813264],
                [106.2619905, 18.489629],
                [106.2463044, 18.4966696],
                [106.2300515, 18.5024568],
                [106.2133434, 18.506948],
                [106.1961867, 18.5101254],
                [106.1790848, 18.5119234],
                [106.1553867, 18.5120451],
                [106.0813061, 18.6223499],
                [106.1011701, 18.6362319],
                [106.1147514, 18.6481026],
                [106.1268492, 18.6605781],
                [106.1380103, 18.6742581],
                [106.1478848, 18.6887893],
                [106.1564035, 18.7040626],
                [106.1635075, 18.7199645],
                [106.1691478, 18.736378],
                [106.1732858, 18.7531834],
                [106.1758933, 18.7702587],
                [106.1769523, 18.7874806],
                [106.1763504, 18.8094988],
                [106.1744334, 18.8257478],
                [106.1711326, 18.841799],
                [106.1664675, 18.857548],
                [105.9660296, 18.7895502],
                [105.7676355, 18.7679076],
                [105.76748, 18.7678872],
                [105.7673256, 18.76786],
                [105.7671727, 18.7678262],
                [105.7670031, 18.7677803],
                [105.7668362, 18.7677261],
                [105.7666724, 18.7676639],
                [105.7665123, 18.7675937],
                [105.7663562, 18.7675158],
                [105.7662046, 18.7674304],
                [105.7660578, 18.7673377],
                [105.7659162, 18.7672379],
                [105.7657803, 18.7671314],
                [105.7656712, 18.767025],
                [105.7655655, 18.7669016],
                [105.7633551, 18.7637432],
                [105.7631441, 18.763421],
                [105.763015, 18.7632148],
                [105.7628367, 18.7629949],
                [105.7627067, 18.7628252],
                [105.7625282, 18.7626359],
                [105.7623086, 18.7624736],
                [105.7621122, 18.7623398],
                [105.7618516, 18.7621681],
                [105.7547683, 18.7585118],
                [105.753944, 18.7580976],
                [105.753026, 18.7575905],
                [105.752208, 18.7570916],
                [105.7513947, 18.7565858],
                [105.7505862, 18.7560732],
                [105.7497825, 18.7555539],
                [105.749422, 18.7553154],
                [105.7490668, 18.7550699],
                [105.748717, 18.7548176],
                [105.7483727, 18.7545585],
                [105.7480341, 18.7542928],
                [105.7477014, 18.7540205],
                [105.7473746, 18.7537419],
                [105.7470539, 18.7534569],
                [105.7467394, 18.7531658],
                [105.7464314, 18.7528687],
                [105.7461298, 18.7525656],
                [105.7458349, 18.7522567],
                [105.7455467, 18.7519422],
                [105.7452654, 18.7516221],
                [105.7449911, 18.7512966],
                [105.7447165, 18.7509565],
                [105.7444496, 18.750611],
                [105.7441904, 18.7502603],
                [105.7439391, 18.7499045],
                [105.7436958, 18.7495437],
                [105.7434606, 18.7491781],
                [105.7432336, 18.748808],
                [105.7430148, 18.7484333],
                [105.7428045, 18.7480544],
                [105.7426026, 18.7476714],
                [105.7424094, 18.7472844],
                [105.7420955, 18.7466039],
                [105.7420142, 18.7463008],
                [105.7419419, 18.7459956],
                [105.7418788, 18.7456887],
                [105.7418249, 18.7453802],
                [105.7417803, 18.7450703],
                [105.7417449, 18.7447594],
                [105.7417189, 18.7444476],
                [105.7417023, 18.7441353],
                [105.741695, 18.7438226],
                [105.741697, 18.7435099],
                [105.7417085, 18.7431974],
                [105.7417293, 18.7428853],
                [105.7417594, 18.7425739],
                [105.7417989, 18.7422634],
                [105.7418344, 18.7420323],
                [105.741875, 18.741802],
                [105.7419609, 18.7413423],
                [105.7420565, 18.7408842],
                [105.7421618, 18.740428],
                [105.7422767, 18.739974],
                [105.7424012, 18.7395222],
                [105.7425352, 18.7390728],
                [105.7426786, 18.7386261],
                [105.7428201, 18.7382143],
                [105.7429696, 18.737805],
                [105.7431271, 18.7373984],
                [105.7432926, 18.7369947],
                [105.7434955, 18.7365291],
                [105.7438361, 18.735802],
                [105.7440327, 18.735411],
                [105.744237, 18.7350235],
                [105.7458939, 18.7324961],
                [105.7468125, 18.7308814],
                [105.7489309, 18.7271151],
                [105.7518521, 18.7195069],
                [105.7526589, 18.7177627],
                [105.7535147, 18.7162342],
                [105.7538971, 18.7150042],
                [105.754071, 18.7144444],
                [105.7546627, 18.7124997],
                [105.7549692, 18.7114182],
                [105.756287, 18.7052643],
                [105.7576963, 18.701977],
                [105.7608952, 18.6973061],
                [105.763499, 18.6936996],
                [105.7645615, 18.6916724],
                [105.7647953, 18.6912718],
                [105.7666881, 18.687783],
                [105.7667385, 18.6876907],
                [105.7682005, 18.6826014],
                [105.7687532, 18.6798624],
                [105.7689641, 18.6775986],
                [105.7687567, 18.6761889],
                [105.7687073, 18.6758531],
                [105.7680928, 18.6744796],
                [105.7670954, 18.6732512],
                [105.7653775, 18.671608],
                [105.7637259, 18.6706752],
                [105.7614531, 18.6698776],
                [105.7518514, 18.6677896],
                [105.7511106, 18.6676409],
                [105.7490244, 18.6676163],
                [105.7471372, 18.6676729],
                [105.7443182, 18.6679636],
                [105.740748, 18.6687779],
                [105.7331073, 18.6717722],
                [105.7311493, 18.6721683],
                [105.7291903, 18.6723701],
                [105.7275872, 18.6724735],
                [105.7261867, 18.6723089],
                [105.7239949, 18.6715891],
                [105.7216918, 18.6707558],
                [105.7201967, 18.6701956],
                [105.7187683, 18.6694728],
                [105.7168524, 18.667805],
                [105.71514, 18.6660878],
                [105.7144241, 18.6652894],
                [105.7138014, 18.6643459],
                [105.713347, 18.6633756],
                [105.711805, 18.6550724],
                [105.710533, 18.6501243],
                [105.7077402, 18.6440484],
                [105.7068712, 18.6421577],
                [105.7066225, 18.641007],
                [105.7064802, 18.6399014],
                [105.7063251, 18.6393193],
                [105.7059652, 18.6384467],
                [105.7047735, 18.6364118],
                [105.702315, 18.6325853],
                [105.7022721, 18.6325596],
                [105.7015991, 18.6321561],
                [105.7009008, 18.632105],
                [105.699108, 18.6324031],
                [105.6976667, 18.6324266],
                [105.6956094, 18.6321784],
                [105.6938635, 18.6315452],
                [105.6919358, 18.6301877],
                [105.6908578, 18.6288698],
                [105.6903867, 18.6277739],
                [105.6895578, 18.6240088],
                [105.6887726, 18.6211071],
                [105.6885672, 18.6196897],
                [105.688646, 18.6183515],
                [105.6886447, 18.6180208],
                [105.6888724, 18.6166289],
                [105.6890379, 18.6152632],
                [105.6892283, 18.6138402],
                [105.6893923, 18.6121084],
                [105.689356, 18.6106128],
                [105.6893248, 18.6093263],
                [105.6893454, 18.6056843],
                [105.6893376, 18.6037517],
                [105.689625, 18.5977673],
                [105.6895141, 18.5959538],
                [105.6891551, 18.5947215],
                [105.6887889, 18.5937944],
                [105.6877973, 18.5911666],
                [105.681805, 18.5798843],
                [105.677629, 18.5793298],
                [105.6761777, 18.5795918],
                [105.6725724, 18.5802425],
                [105.6687096, 18.5827669],
                [105.6656727, 18.5839158],
                [105.6624782, 18.5842591],
                [105.657582, 18.582478],
                [105.6550069, 18.5812096],
                [105.6532112, 18.5803967],
                [105.6524228, 18.5800398],
                [105.6498827, 18.5790727],
                [105.644238, 18.5769017],
                [105.6390271, 18.5746476],
                [105.6318185, 18.5711123],
                [105.6260817, 18.5676356],
                [105.6186329, 18.5699895],
                [105.6165786, 18.573553],
                [105.616378, 18.5771926],
                [105.6122872, 18.5769548],
                [105.61187, 18.5769306],
                [105.6091567, 18.5767729],
                [105.6085853, 18.5767325],
                [105.6069923, 18.5766198],
                [105.6066539, 18.5766952],
                [105.6062414, 18.576994],
                [105.6038991, 18.5765554],
                [105.6011968, 18.575992],
                [105.5993819, 18.5758023],
                [105.5993945, 18.5761024],
                [105.5997064, 18.576682],
                [105.5997169, 18.5768951],
                [105.5996136, 18.5770465],
                [105.5994091, 18.5771052],
                [105.5986554, 18.5767012],
                [105.5980508, 18.576228],
                [105.5975256, 18.5754933],
                [105.5972874, 18.5753886],
                [105.5967613, 18.5752371],
                [105.5964729, 18.5750057],
                [105.5959686, 18.5747329],
                [105.5958518, 18.574538],
                [105.5958009, 18.5742373],
                [105.5958223, 18.5738749],
                [105.5958651, 18.5736142],
                [105.5958575, 18.5734302],
                [105.5958197, 18.5733083],
                [105.5955566, 18.5732104],
                [105.5953438, 18.5731694],
                [105.5950968, 18.5731343],
                [105.5946585, 18.5729329],
                [105.5940196, 18.5725651],
                [105.5935307, 18.5722504],
                [105.5933305, 18.5721974],
                [105.5931866, 18.5721919],
                [105.5929086, 18.5722763],
                [105.5921529, 18.5724695],
                [105.5917789, 18.5724852],
                [105.5913027, 18.5724722],
                [105.5907654, 18.572629],
                [105.5903249, 18.5727079],
                [105.5897649, 18.5727076],
                [105.5893776, 18.5710607],
                [105.5887876, 18.568523],
                [105.5886913, 18.5683746],
                [105.588233, 18.5676668],
                [105.588017, 18.5675417],
                [105.587825, 18.567485],
                [105.5876331, 18.5674628],
                [105.5874773, 18.5674633],
                [105.5873214, 18.5674523],
                [105.5872494, 18.5674182],
                [105.587153, 18.5673041],
                [105.5870204, 18.5670872],
                [105.5867433, 18.5666762],
                [105.5864906, 18.5663682],
                [105.5858889, 18.5657294],
                [105.5858214, 18.5652606],
                [105.5859751, 18.5646539],
                [105.5859513, 18.5641244],
                [105.5852137, 18.5633704],
                [105.5856821, 18.5630761],
                [105.585657, 18.5627444],
                [105.5855044, 18.5619442],
                [105.5855513, 18.5616123],
                [105.5855419, 18.5615929],
                [105.5854909, 18.5614866],
                [105.5851177, 18.5609295],
                [105.5850934, 18.560893],
                [105.5847901, 18.5598873],
                [105.5846936, 18.559693],
                [105.5845613, 18.5595562],
                [105.5844051, 18.5594651],
                [105.5842251, 18.5593856],
                [105.5840928, 18.559283],
                [105.5839843, 18.5591118],
                [105.5836486, 18.5587356],
                [105.5830537, 18.5586342],
                [105.5828139, 18.558635],
                [105.5823945, 18.5586592],
                [105.581113, 18.5590292],
                [105.579665, 18.55972],
                [105.5791022, 18.5598819],
                [105.5788027, 18.55994],
                [105.578563, 18.5599521],
                [105.5782394, 18.5599531],
                [105.5777537, 18.559886],
                [105.5775495, 18.5597837],
                [105.5768405, 18.5592596],
                [105.5763723, 18.5590324],
                [105.5757636, 18.5587949],
                [105.57488, 18.5580365],
                [105.5743098, 18.5583677],
                [105.5737859, 18.5583466],
                [105.5727575, 18.5580871],
                [105.5725472, 18.5605082],
                [105.5666222, 18.5601433],
                [105.56323, 18.5596859],
                [105.5607866, 18.5594187],
                [105.5599828, 18.5592381],
                [105.5585764, 18.5587415],
                [105.5581885, 18.5584587],
                [105.5575093, 18.5582619],
                [105.5572591, 18.5581831],
                [105.5560928, 18.5580587],
                [105.5555916, 18.5580221],
                [105.5552905, 18.557972],
                [105.5548978, 18.5578474],
                [105.5541554, 18.5578631],
                [105.5538889, 18.5577969],
                [105.5520652, 18.5570665],
                [105.551684, 18.5561447],
                [105.5515235, 18.5557167],
                [105.5510523, 18.5545398],
                [105.5505349, 18.5539579],
                [105.5501629, 18.5538216],
                [105.5496821, 18.5533884],
                [105.5493703, 18.553355],
                [105.5490352, 18.5535161],
                [105.5485561, 18.5536205],
                [105.5480169, 18.553702],
                [105.5477111, 18.5536343],
                [105.5471345, 18.5532586],
                [105.5466896, 18.5528709],
                [105.5461958, 18.5521172],
                [105.5453227, 18.5527146],
                [105.5447965, 18.5531166],
                [105.5445329, 18.5531402],
                [105.5440053, 18.5530731],
                [105.5437417, 18.5531311],
                [105.5436253, 18.5532463],
                [105.5433475, 18.5535212],
                [105.5427264, 18.554255],
                [105.5423975, 18.5544962],
                [105.5416909, 18.5546699],
                [105.5413565, 18.5550483],
                [105.5396482, 18.5569064],
                [105.5391461, 18.5573426],
                [105.5390868, 18.5575601],
                [105.5391595, 18.5578345],
                [105.5391604, 18.558109],
                [105.539041, 18.5582466],
                [105.538395, 18.5586718],
                [105.5358309, 18.5590335],
                [105.5356697, 18.5591004],
                [105.5333886, 18.5600472],
                [105.5331374, 18.5601852],
                [105.5329875, 18.5602956],
                [105.5327785, 18.5604494],
                [105.5322275, 18.5605653],
                [105.5313411, 18.5607623],
                [105.53091, 18.5609236],
                [105.5304817, 18.561943],
                [105.5304765, 18.5621946],
                [105.5309584, 18.5630055],
                [105.5310196, 18.5634286],
                [105.5309247, 18.5637149],
                [105.53053, 18.5640706],
                [105.5304229, 18.5642769],
                [105.5304356, 18.5645056],
                [105.5304845, 18.5648142],
                [105.5304374, 18.5651004],
                [105.5305342, 18.5653518],
                [105.5305357, 18.5658781],
                [105.5304961, 18.5666446],
                [105.5304378, 18.567171],
                [105.5302228, 18.5674232],
                [105.5299123, 18.5678131],
                [105.529709, 18.5679738],
                [105.5293619, 18.5681121],
                [105.5290867, 18.5682959],
                [105.5289143, 18.5687425],
                [105.5287355, 18.5690747],
                [105.5283416, 18.5696593],
                [105.528223, 18.5700714],
                [105.5282487, 18.5706091],
                [105.5281656, 18.5708724],
                [105.5279985, 18.5711474],
                [105.5276521, 18.5715144],
                [105.5274369, 18.5717323],
                [105.5272162, 18.5720876],
                [105.527255, 18.5730141],
                [105.5271604, 18.5734605],
                [105.5271865, 18.5741011],
                [105.5271029, 18.5742043],
                [105.5267679, 18.5744569],
                [105.5266329, 18.5753953],
                [105.5265389, 18.5760019],
                [105.5257356, 18.5759927],
                [105.525532, 18.576039],
                [105.5255249, 18.5760702],
                [105.5253783, 18.5767144],
                [105.52526, 18.5772523],
                [105.5253095, 18.577767],
                [105.5251309, 18.578145],
                [105.524796, 18.5784319],
                [105.5245925, 18.5785126],
                [105.5242684, 18.5783762],
                [105.5234639, 18.5779781],
                [105.523333, 18.5782644],
                [105.5231241, 18.5785967],
                [105.5225018, 18.5789531],
                [105.5217722, 18.5795271],
                [105.5215813, 18.5798251],
                [105.5213556, 18.5805235],
                [105.5212363, 18.5807069],
                [105.5209852, 18.5809135],
                [105.5205424, 18.5811664],
                [105.5202916, 18.5814873],
                [105.5196293, 18.5824844],
                [105.5187804, 18.5832761],
                [105.5181836, 18.5841471],
                [105.5179813, 18.5846511],
                [105.5180005, 18.5850857],
                [105.5179313, 18.5859438],
                [105.5176571, 18.5864823],
                [105.5174679, 18.5873293],
                [105.51677, 18.5884752],
                [105.5161861, 18.5896551],
                [105.5156376, 18.5906862],
                [105.515803, 18.5918411],
                [105.5158646, 18.5923901],
                [105.5158185, 18.5930193],
                [105.5155447, 18.5936607],
                [105.5154634, 18.5945417],
                [105.5156031, 18.5951591],
                [105.5160747, 18.5964619],
                [105.5155588, 18.5983623],
                [105.5151299, 18.5992101],
                [105.5138361, 18.5996597],
                [105.5130578, 18.5999822],
                [105.5124003, 18.6006703],
                [105.5115337, 18.6015421],
                [105.5113788, 18.6018743],
                [105.5110087, 18.6024244],
                [105.5105425, 18.6028832],
                [105.5099441, 18.6032509],
                [105.5096323, 18.6032403],
                [105.5091526, 18.6032187],
                [105.5089363, 18.6030477],
                [105.5088044, 18.6030481],
                [105.5087205, 18.6030598],
                [105.5086248, 18.6031629],
                [105.5085897, 18.6034148],
                [105.508424, 18.6041702],
                [105.5081563, 18.6049145],
                [105.5078231, 18.6057161],
                [105.5075727, 18.606163],
                [105.5074653, 18.6063349],
                [105.5073215, 18.6064153],
                [105.5067225, 18.6065656],
                [105.5060636, 18.6067849],
                [105.50592, 18.606831],
                [105.5058482, 18.6069112],
                [105.5058485, 18.6070028],
                [105.5058608, 18.6071286],
                [105.5058855, 18.6073344],
                [105.5059819, 18.6075286],
                [105.5061987, 18.6078712],
                [105.5063079, 18.6083056],
                [105.5063207, 18.6085573],
                [105.5062731, 18.6086947],
                [105.5059737, 18.6087984],
                [105.5054706, 18.6090172],
                [105.5049931, 18.6096705],
                [105.5043115, 18.6103472],
                [105.5040118, 18.6103937],
                [105.5027292, 18.6105116],
                [105.5022857, 18.6105585],
                [105.501852, 18.6109009],
                [105.5000731, 18.6107836],
                [105.4981383, 18.6108072],
                [105.4958304, 18.6106499],
                [105.4939004, 18.6107076],
                [105.492062, 18.6106017],
                [105.4888778, 18.6114186],
                [105.4868816, 18.6124244],
                [105.4863406, 18.6126842],
                [105.486015, 18.6127771],
                [105.4846402, 18.6130485],
                [105.4837938, 18.6132711],
                [105.4832085, 18.6133799],
                [105.4828087, 18.6133638],
                [105.4822965, 18.6131794],
                [105.481996, 18.6131044],
                [105.4803623, 18.6137317],
                [105.4799723, 18.6138041],
                [105.4782551, 18.6143468],
                [105.4764955, 18.6140754],
                [105.4763743, 18.6122481],
                [105.4764469, 18.6104941],
                [105.4767537, 18.609478],
                [105.476562, 18.6076358],
                [105.4758733, 18.6074609],
                [105.4741674, 18.607502],
                [105.4729585, 18.6074179],
                [105.4718787, 18.6070674],
                [105.4684979, 18.6055633],
                [105.4674279, 18.605364],
                [105.4649956, 18.6052674],
                [105.4632874, 18.6034304],
                [105.4595177, 18.6030348],
                [105.4592412, 18.6022972],
                [105.4593875, 18.6010823],
                [105.4560177, 18.6010859],
                [105.4535229, 18.6014491],
                [105.4508535, 18.6015078],
                [105.4497812, 18.6018676],
                [105.4493073, 18.6019878],
                [105.4489567, 18.6020897],
                [105.448651, 18.6017463],
                [105.4485185, 18.6015818],
                [105.4484061, 18.6013397],
                [105.448365, 18.6011848],
                [105.4483139, 18.6010201],
                [105.4482321, 18.6008748],
                [105.4480077, 18.6005846],
                [105.4478245, 18.6004007],
                [105.4476513, 18.6002752],
                [105.4474379, 18.6001884],
                [105.4472447, 18.6001307],
                [105.4470414, 18.6001118],
                [105.4468178, 18.6001414],
                [105.4466351, 18.6001903],
                [105.4463712, 18.6002686],
                [105.4461375, 18.6002981],
                [105.4456803, 18.6003089],
                [105.445172, 18.6002131],
                [105.4444301, 18.600084],
                [105.4436877, 18.5998434],
                [105.4432606, 18.5996505],
                [105.4429658, 18.5996125],
                [105.4427468, 18.5995086],
                [105.4421502, 18.5988496],
                [105.4417851, 18.5985492],
                [105.4416151, 18.598538],
                [105.4414817, 18.5985847],
                [105.4408477, 18.5984619],
                [105.4405534, 18.5981827],
                [105.4400936, 18.5978431],
                [105.4398239, 18.5974754],
                [105.4397951, 18.5971518],
                [105.4397624, 18.5961279],
                [105.4397218, 18.5953895],
                [105.4395462, 18.5948176],
                [105.439254, 18.5940613],
                [105.438398, 18.5921618],
                [105.4372159, 18.5914445],
                [105.4362631, 18.5895452],
                [105.4348069, 18.5875362],
                [105.4333935, 18.5871149],
                [105.4314611, 18.5879685],
                [105.4293905, 18.5878439],
                [105.4278606, 18.5872199],
                [105.4263724, 18.5878139],
                [105.4259276, 18.5879073],
                [105.4247343, 18.5884997],
                [105.4245885, 18.5881622],
                [105.4237756, 18.586653],
                [105.4235034, 18.5863786],
                [105.4228143, 18.5859862],
                [105.4220855, 18.5856796],
                [105.421767, 18.5855627],
                [105.4215842, 18.5855014],
                [105.4214958, 18.5854989],
                [105.421431, 18.585502],
                [105.421337, 18.5855473],
                [105.42129, 18.5855812],
                [105.4212019, 18.5856601],
                [105.4209378, 18.5859282],
                [105.420698, 18.5863609],
                [105.4205944, 18.5866212],
                [105.4205012, 18.5869015],
                [105.4204706, 18.5870916],
                [105.4204295, 18.5872916],
                [105.4203985, 18.5874618],
                [105.4201914, 18.5880422],
                [105.4198176, 18.5889033],
                [105.4196511, 18.5892138],
                [105.4196306, 18.5893338],
                [105.4196105, 18.5895639],
                [105.4195908, 18.5898839],
                [105.41956, 18.5900441],
                [105.4195293, 18.5902341],
                [105.4194775, 18.5903842],
                [105.4192376, 18.590605],
                [105.4189865, 18.5907258],
                [105.4187877, 18.5907965],
                [105.4186201, 18.5908271],
                [105.4184002, 18.5908279],
                [105.418243, 18.5908184],
                [105.4181067, 18.5907989],
                [105.417876, 18.5907597],
                [105.4176663, 18.5906805],
                [105.4175191, 18.5905611],
                [105.4172565, 18.590382],
                [105.4168884, 18.5900335],
                [105.4137449, 18.5871953],
                [105.4118534, 18.5857722],
                [105.4101629, 18.5847784],
                [105.4098373, 18.5845496],
                [105.4097007, 18.5844301],
                [105.4095538, 18.5843707],
                [105.4093966, 18.5843711],
                [105.4091139, 18.5844322],
                [105.4087286, 18.5846352],
                [105.4084711, 18.5848502],
                [105.4079332, 18.585256],
                [105.4076516, 18.585567],
                [105.4074326, 18.5858477],
                [105.4072555, 18.5861182],
                [105.4070567, 18.5863528],
                [105.4068483, 18.5864795],
                [105.4065445, 18.5864906],
                [105.4063141, 18.5864715],
                [105.4061146, 18.5863921],
                [105.4054216, 18.5859546],
                [105.4046659, 18.5856373],
                [105.4038156, 18.5851604],
                [105.402671, 18.5844845],
                [105.4018003, 18.5841776],
                [105.4005836, 18.5838219],
                [105.3988219, 18.5833682],
                [105.3986748, 18.5832486],
                [105.3986012, 18.583169],
                [105.3984324, 18.5828897],
                [105.3979789, 18.5820914],
                [105.3978938, 18.5817317],
                [105.3977759, 18.5810123],
                [105.3977433, 18.5807025],
                [105.3977313, 18.5803226],
                [105.3976886, 18.5801028],
                [105.3976146, 18.5799231],
                [105.39752, 18.5798334],
                [105.3974151, 18.5798038],
                [105.3972054, 18.579786],
                [105.3969961, 18.5797753],
                [105.3958753, 18.5798292],
                [105.3945766, 18.5799436],
                [105.3935818, 18.5800669],
                [105.3930297, 18.5801862],
                [105.3927627, 18.5802271],
                [105.3925691, 18.5802728],
                [105.3922184, 18.580334],
                [105.3916684, 18.5803659],
                [105.3915009, 18.5803714],
                [105.391307, 18.5803921],
                [105.3911659, 18.5804376],
                [105.3909881, 18.5805231],
                [105.3907789, 18.5806388],
                [105.3905855, 18.5807394],
                [105.3903135, 18.5808254],
                [105.3899367, 18.5809066],
                [105.3896015, 18.5809627],
                [105.3893083, 18.5809937],
                [105.3891985, 18.5810141],
                [105.3890099, 18.5810248],
                [105.388685, 18.5810258],
                [105.388177, 18.5809875],
                [105.3876007, 18.5809796],
                [105.3863853, 18.5809787],
                [105.3861865, 18.5809844],
                [105.3860029, 18.58097],
                [105.3858196, 18.5809506],
                [105.385615, 18.5808813],
                [105.3853579, 18.5807872],
                [105.385169, 18.5806679],
                [105.3849066, 18.5805239],
                [105.3847702, 18.5804693],
                [105.3846129, 18.5804448],
                [105.3845606, 18.580445],
                [105.3833931, 18.5806589],
                [105.3832622, 18.5806392],
                [105.3830525, 18.580575],
                [105.3829317, 18.5805305],
                [105.3828163, 18.5804658],
                [105.3824012, 18.5801423],
                [105.3818919, 18.5798141],
                [105.3812728, 18.5795512],
                [105.3810946, 18.5795118],
                [105.3809899, 18.5795021],
                [105.3805656, 18.5795035],
                [105.3803686, 18.5794665],
                [105.3801107, 18.5793789],
                [105.3792933, 18.5791413],
                [105.3787949, 18.5790297],
                [105.3783601, 18.578893],
                [105.3774526, 18.5785172],
                [105.3773094, 18.5784666],
                [105.3771785, 18.5784495],
                [105.3764498, 18.578637],
                [105.3759482, 18.5788918],
                [105.3753639, 18.5794265],
                [105.3748423, 18.5799811],
                [105.3745012, 18.5802019],
                [105.3740833, 18.5804564],
                [105.3728004, 18.580827],
                [105.3722705, 18.5809619],
                [105.3715723, 18.5808443],
                [105.3702452, 18.580609],
                [105.369407, 18.5804385],
                [105.368345, 18.580149],
                [105.3679122, 18.5801237],
                [105.36734, 18.580139],
                [105.3671307, 18.5801531],
                [105.3669076, 18.580247],
                [105.3662117, 18.5802443],
                [105.3657008, 18.5804141],
                [105.3648362, 18.5808004],
                [105.3637846, 18.5809432],
                [105.3630287, 18.58147],
                [105.3625286, 18.5814201],
                [105.3618992, 18.5811024],
                [105.3615359, 18.5809838],
                [105.3611171, 18.5810118],
                [105.3605035, 18.5811736],
                [105.3596451, 18.5815396],
                [105.3591442, 18.581954],
                [105.3589721, 18.5823108],
                [105.3584014, 18.5832145],
                [105.3578607, 18.5838462],
                [105.3574573, 18.5842337],
                [105.3570119, 18.5845948],
                [105.3565246, 18.5849694],
                [105.3559675, 18.5853041],
                [105.354923, 18.5860401],
                [105.3543652, 18.5861885],
                [105.3534882, 18.5862253],
                [105.3529725, 18.5862078],
                [105.3525261, 18.5863424],
                [105.3491487, 18.5874838],
                [105.348408, 18.588107],
                [105.3482405, 18.5885596],
                [105.3479872, 18.5887219],
                [105.3476321, 18.5888199],
                [105.3472933, 18.5887242],
                [105.3469549, 18.5887737],
                [105.3463806, 18.5890983],
                [105.3452988, 18.5895054],
                [105.3447411, 18.5897009],
                [105.3442258, 18.589969],
                [105.3436348, 18.590326],
                [105.343094, 18.5905699],
                [105.3427389, 18.5906356],
                [105.3423329, 18.5906854],
                [105.341639, 18.5907037],
                [105.3409452, 18.5907059],
                [105.3402687, 18.5908209],
                [105.3390322, 18.590502],
                [105.3387358, 18.5904463],
                [105.3384661, 18.5907701],
                [105.3383661, 18.5912064],
                [105.3382657, 18.5915458],
                [105.3377446, 18.5925969],
                [105.3376451, 18.5931784],
                [105.3369288, 18.5940284],
                [105.3365909, 18.5942231],
                [105.3362863, 18.5942564],
                [105.3358975, 18.5943706],
                [105.3351209, 18.5948897],
                [105.3342595, 18.5954413],
                [105.3336023, 18.5962507],
                [105.3335528, 18.596606],
                [105.3334184, 18.5969455],
                [105.3330488, 18.5977701],
                [105.3324078, 18.5983696],
                [105.3320118, 18.5988794],
                [105.3314888, 18.5993977],
                [105.3312367, 18.5998991],
                [105.3310354, 18.6004487],
                [105.3307665, 18.6009662],
                [105.3304977, 18.6015966],
                [105.3302446, 18.6017912],
                [105.3299825, 18.6018647],
                [105.3293565, 18.601915],
                [105.3288658, 18.6019325],
                [105.3281379, 18.6019188],
                [105.327427, 18.6018886],
                [105.3266988, 18.6018102],
                [105.3260555, 18.6017475],
                [105.3256492, 18.6017327],
                [105.3248372, 18.6018319],
                [105.3241182, 18.6019391],
                [105.3235948, 18.6022959],
                [105.3229534, 18.602863],
                [105.3223453, 18.6031877],
                [105.3218223, 18.6036737],
                [105.3212809, 18.6037399],
                [105.3208572, 18.6035959],
                [105.3205525, 18.6035484],
                [105.3204024, 18.6035173],
                [105.3202374, 18.6035509],
                [105.3197563, 18.603847],
                [105.3191359, 18.6041723],
                [105.3186267, 18.6039583],
                [105.3183995, 18.6035548],
                [105.3183288, 18.603555],
                [105.3173972, 18.6037195],
                [105.3168047, 18.6039235],
                [105.3163542, 18.6043289],
                [105.3161152, 18.6046801],
                [105.3153113, 18.6050327],
                [105.3145498, 18.6054124],
                [105.3138436, 18.605401],
                [105.3126574, 18.605472],
                [105.3118954, 18.6056629],
                [105.3106404, 18.6062325],
                [105.3099135, 18.6064437],
                [105.3093066, 18.6065398],
                [105.308459, 18.6065018],
                [105.3079226, 18.6065709],
                [105.3069202, 18.6066817],
                [105.30644, 18.6066831],
                [105.3059318, 18.6067519],
                [105.3048742, 18.6072671],
                [105.3046347, 18.6074565],
                [105.3041146, 18.6082395],
                [105.3035246, 18.6092115],
                [105.302879, 18.610507],
                [105.3024456, 18.6118693],
                [105.3021886, 18.6132243],
                [105.3020768, 18.6135749],
                [105.3017671, 18.6139127],
                [105.3009641, 18.6145483],
                [105.3002747, 18.6154126],
                [105.2994931, 18.6161224],
                [105.2988869, 18.6164611],
                [105.2983946, 18.6171498],
                [105.2975516, 18.6185266],
                [105.2972987, 18.6189181],
                [105.2956349, 18.6218996],
                [105.295186, 18.6221272],
                [105.2944124, 18.6223441],
                [105.2939755, 18.6223573],
                [105.2934956, 18.6222575],
                [105.2916513, 18.6215516],
                [105.2910251, 18.6211921],
                [105.2907089, 18.6209046],
                [105.2892682, 18.6196611],
                [105.2882076, 18.6196822],
                [105.2864235, 18.6207838],
                [105.2833318, 18.6234101],
                [105.2815519, 18.6237081],
                [105.2786466, 18.6220878],
                [105.275937, 18.6217594],
                [105.2734218, 18.6220216],
                [105.2715604, 18.6196611],
                [105.2701213, 18.6183119],
                [105.2694295, 18.6181783],
                [105.265868, 18.6176016],
                [105.262811, 18.6178274],
                [105.258241, 18.6156183],
                [105.2550251, 18.6131118],
                [105.2535032, 18.6112031],
                [105.2518523, 18.61025],
                [105.2511509, 18.6099243],
                [105.2507479, 18.6097711],
                [105.2502438, 18.6094068],
                [105.2490344, 18.6087936],
                [105.2477248, 18.6085071],
                [105.2472613, 18.6083924],
                [105.2469387, 18.6081624],
                [105.2466361, 18.6077977],
                [105.2461318, 18.6072797],
                [105.2455061, 18.6064351],
                [105.24337, 18.6055733],
                [105.2427653, 18.6053628],
                [105.2421809, 18.6050561],
                [105.2408909, 18.6044623],
                [105.2399026, 18.603426],
                [105.239338, 18.602908],
                [105.2388733, 18.6019096],
                [105.2386906, 18.6008147],
                [105.2382466, 18.6003158],
                [105.2375408, 18.5996826],
                [105.2372786, 18.5993947],
                [105.2368549, 18.5987229],
                [105.236391, 18.5983969],
                [105.2356051, 18.5980136],
                [105.234799, 18.5977457],
                [105.2341339, 18.5973719],
                [105.2337708, 18.5969496],
                [105.2334275, 18.596316],
                [105.2330635, 18.5952213],
                [105.2326186, 18.5939346],
                [105.2326984, 18.5934159],
                [105.232657, 18.5924361],
                [105.2324745, 18.5916102],
                [105.2321514, 18.5909766],
                [105.2321503, 18.5901504],
                [105.2316256, 18.589325],
                [105.2312424, 18.5889796],
                [105.2308594, 18.5887112],
                [105.2303555, 18.5884044],
                [105.230013, 18.5882559],
                [105.22943, 18.5880377],
                [105.2288405, 18.5878671],
                [105.2284717, 18.5878447],
                [105.2280842, 18.5878546],
                [105.2277752, 18.5879029],
                [105.2276387, 18.5879579],
                [105.2274522, 18.5881158],
                [105.2270504, 18.5885893],
                [105.2265265, 18.5891315],
                [105.2253202, 18.5899556],
                [105.2243441, 18.5908891],
                [105.2235982, 18.5920416],
                [105.2220773, 18.5940451],
                [105.2215895, 18.5947039],
                [105.2209676, 18.5954991],
                [105.2195436, 18.5965506],
                [105.2193118, 18.5965923],
                [105.2183855, 18.5973259],
                [105.2178933, 18.5975614],
                [105.217705, 18.5976307],
                [105.2173261, 18.597724],
                [105.2167055, 18.5977562],
                [105.2155468, 18.5980754],
                [105.213867, 18.5987268],
                [105.2132444, 18.5989625],
                [105.2126506, 18.5991566],
                [105.2117091, 18.5993926],
                [105.2114484, 18.599393],
                [105.211231, 18.5993793],
                [105.2108251, 18.5992554],
                [105.2100567, 18.5988278],
                [105.2094334, 18.5985383],
                [105.2088393, 18.5984699],
                [105.208564, 18.5984702],
                [105.2079411, 18.5985123],
                [105.2067388, 18.5987348],
                [105.204334, 18.598917],
                [105.2038847, 18.5987794],
                [105.2032471, 18.5986971],
                [105.2029863, 18.5986974],
                [105.2026097, 18.5988084],
                [105.2018649, 18.5989482],
                [105.2005187, 18.5998491],
                [105.1992217, 18.6006223],
                [105.1977954, 18.6013849],
                [105.1972234, 18.6016057],
                [105.1969772, 18.6016335],
                [105.1969192, 18.6016474],
                [105.1966609, 18.601668],
                [105.1955823, 18.6014636],
                [105.1947171, 18.6013285],
                [105.1941933, 18.6012201],
                [105.1935401, 18.6010131],
                [105.1922337, 18.6004657],
                [105.1910051, 18.5999628],
                [105.1905427, 18.6000092],
                [105.1902316, 18.6000898],
                [105.1897605, 18.6002035],
                [105.1893188, 18.6004682],
                [105.1873856, 18.6043571],
                [105.1869892, 18.6032611],
                [105.1861795, 18.6021977],
                [105.1857422, 18.6021906],
                [105.1844169, 18.6024614],
                [105.1839967, 18.6024756],
                [105.1825332, 18.6023527],
                [105.1818523, 18.6023533],
                [105.181577, 18.6023812],
                [105.18107, 18.6025061],
                [105.1808239, 18.6026169],
                [105.1805827, 18.6027345],
                [105.1798541, 18.6034747],
                [105.1792318, 18.6042215],
                [105.1787108, 18.6048438],
                [105.1782474, 18.6052036],
                [105.1773065, 18.6058816],
                [105.1772196, 18.6059093],
                [105.1770168, 18.6059372],
                [105.1765682, 18.6058334],
                [105.1756543, 18.6054962],
                [105.1745098, 18.6054973],
                [105.173771, 18.60565],
                [105.1731917, 18.605996],
                [105.1719758, 18.6070474],
                [105.1711366, 18.6082505],
                [105.1706198, 18.6090209],
                [105.1704131, 18.6092324],
                [105.1700077, 18.609523],
                [105.1695189, 18.6097419],
                [105.1674589, 18.6108657],
                [105.1666915, 18.6113224],
                [105.1656775, 18.6116964],
                [105.1644738, 18.6116975],
                [105.1632145, 18.6118782],
                [105.1626787, 18.6121414],
                [105.1617157, 18.6130001],
                [105.1610166, 18.6135013],
                [105.1604198, 18.6136081],
                [105.1598689, 18.6135908],
                [105.1593504, 18.6134035],
                [105.1585726, 18.6128408],
                [105.1581989, 18.6122678],
                [105.1578563, 18.6115268],
                [105.1575862, 18.610924],
                [105.157368, 18.6102521],
                [105.1572013, 18.6094021],
                [105.1572318, 18.6086806],
                [105.1570446, 18.607969],
                [105.1568887, 18.6074454],
                [105.1567847, 18.6071291],
                [105.1565772, 18.6068327],
                [105.156349, 18.6065957],
                [105.1560274, 18.6063982],
                [105.1550945, 18.6061024],
                [105.1518791, 18.6056017],
                [105.1509775, 18.6056222],
                [105.1502129, 18.6051906],
                [105.1499736, 18.6049107],
                [105.1496416, 18.6045155],
                [105.1489816, 18.6037844],
                [105.1485911, 18.6031838],
                [105.1481707, 18.6029266],
                [105.1475401, 18.6022974],
                [105.1469394, 18.6016682],
                [105.1462784, 18.6007244],
                [105.1457981, 18.6002955],
                [105.1454377, 18.6000954],
                [105.1449856, 18.5999281],
                [105.143897, 18.599325],
                [105.143636, 18.5991041],
                [105.1431861, 18.5985516],
                [105.1415047, 18.5970648],
                [105.1408141, 18.5966073],
                [105.139373, 18.5954636],
                [105.1388027, 18.595092],
                [105.1382294, 18.5946781],
                [105.1374182, 18.5942112],
                [105.1359466, 18.5930614],
                [105.1352861, 18.5922166],
                [105.1349652, 18.5917854],
                [105.1347797, 18.5914282],
                [105.1346965, 18.5910824],
                [105.1346817, 18.5906706],
                [105.1347942, 18.5899511],
                [105.1349961, 18.5894826],
                [105.1354101, 18.5888299],
                [105.1355134, 18.5884443],
                [105.1355648, 18.5879204],
                [105.1355953, 18.5872185],
                [105.1356588, 18.5866237],
                [105.1357716, 18.5863179],
                [105.1359412, 18.5861381],
                [105.137675, 18.5852197],
                [105.1380894, 18.5848597],
                [105.138372, 18.5845178],
                [105.1386167, 18.58414],
                [105.1386731, 18.5837982],
                [105.1386541, 18.5837443],
                [105.1386133, 18.5835586],
                [105.1384653, 18.5833848],
                [105.1365571, 18.5823866],
                [105.1359869, 18.5821151],
                [105.1359118, 18.5820437],
                [105.1358968, 18.582058],
                [105.1358968, 18.5820293],
                [105.1358518, 18.5820151],
                [105.1357682, 18.5819122],
                [105.1353836, 18.5782234],
                [105.1349767, 18.5753351],
                [105.1348157, 18.5745365],
                [105.1347816, 18.5740185],
                [105.135026, 18.5732452],
                [105.1350067, 18.5725438],
                [105.1347611, 18.5719505],
                [105.1343269, 18.5711956],
                [105.1340428, 18.5707546],
                [105.1339305, 18.5704765],
                [105.1339374, 18.5703541],
                [105.1339302, 18.5702068],
                [105.134117, 18.5698388],
                [105.1345592, 18.5692189],
                [105.135797, 18.568102],
                [105.1374932, 18.5663719],
                [105.1391893, 18.5647076],
                [105.140014, 18.5635847],
                [105.1401829, 18.5626675],
                [105.1401826, 18.5623978],
                [105.1401447, 18.5621101],
                [105.1399307, 18.5618784],
                [105.1396165, 18.5616608],
                [105.1386107, 18.5612512],
                [105.1368256, 18.5603862],
                [105.1356751, 18.5595238],
                [105.1352189, 18.5593919],
                [105.1347989, 18.5593349],
                [105.1340538, 18.5593091],
                [105.1326587, 18.5592203],
                [105.1312627, 18.5579085],
                [105.1303762, 18.5571359],
                [105.1291882, 18.5565433],
                [105.1285162, 18.556093],
                [105.1283466, 18.5558621],
                [105.1282175, 18.5554586],
                [105.1280327, 18.5537299],
                [105.1279758, 18.5522857],
                [105.1281354, 18.5508851],
                [105.1284857, 18.54989],
                [105.1289568, 18.5493683],
                [105.1290615, 18.5491075],
                [105.1290693, 18.5488107],
                [105.1290462, 18.5486496],
                [105.1287295, 18.5481995],
                [105.1279751, 18.5476786],
                [105.1273148, 18.5469238],
                [105.1261636, 18.5449644],
                [105.1244473, 18.5435089],
                [105.1236739, 18.5425204],
                [105.1227417, 18.5413415],
                [105.1219308, 18.5407998],
                [105.120008, 18.5404054],
                [105.1189172, 18.5403406],
                [105.1165833, 18.5397882],
                [105.1154472, 18.5392721],
                [105.1151146, 18.5388237],
                [105.1151848, 18.5381856],
                [105.1156019, 18.5377044],
                [105.1157719, 18.5359901],
                [105.1158383, 18.5346775],
                [105.1158084, 18.5342533],
                [105.1156578, 18.5339621],
                [105.1154687, 18.533714],
                [105.1150274, 18.5329608],
                [105.1148271, 18.5325095],
                [105.1147511, 18.5315565],
                [105.115052, 18.5304953],
                [105.1154458, 18.5276178],
                [105.1156682, 18.5269789],
                [105.1158779, 18.526664],
                [105.1160407, 18.5264705],
                [105.1160466, 18.5264662],
                [105.117296, 18.5255436],
                [105.1183998, 18.5252398],
                [105.1193399, 18.5247971],
                [105.1200126, 18.5242257],
                [105.1202842, 18.5235247],
                [105.1202871, 18.5229501],
                [105.1200217, 18.522438],
                [105.1191919, 18.5218753],
                [105.1184379, 18.5209622],
                [105.1176836, 18.5205312],
                [105.11627, 18.5200646],
                [105.1155315, 18.5194461],
                [105.1149458, 18.5182436],
                [105.1148441, 18.5177461],
                [105.1148914, 18.5158933],
                [105.1145706, 18.5152462],
                [105.1142121, 18.5146709],
                [105.1137031, 18.5142755],
                [105.1130809, 18.5140063],
                [105.1128736, 18.5139345],
                [105.1124777, 18.5137008],
                [105.1117046, 18.5130001],
                [105.110988, 18.5122992],
                [105.1111008, 18.5119035],
                [105.1113955, 18.511476],
                [105.1117409, 18.510932],
                [105.1118728, 18.5107521],
                [105.1120609, 18.5103923],
                [105.1121173, 18.5099966],
                [105.1120605, 18.5097269],
                [105.1120794, 18.5097089],
                [105.1119513, 18.5093297],
                [105.1118151, 18.5091157],
                [105.1113622, 18.508037],
                [105.1108144, 18.5060052],
                [105.1107195, 18.5050881],
                [105.1105493, 18.5040633],
                [105.1101339, 18.5028587],
                [105.1100582, 18.502463],
                [105.1109024, 18.5003247],
                [105.1119238, 18.4995216],
                [105.112415, 18.4991633],
                [105.1125455, 18.4990051],
                [105.1126234, 18.4982933],
                [105.1127015, 18.4978437],
                [105.1129366, 18.4973191],
                [105.1130539, 18.4969071],
                [105.1130539, 18.4966823],
                [105.1128575, 18.4964202],
                [105.1123861, 18.4953341],
                [105.1119543, 18.4947725],
                [105.1116011, 18.4945104],
                [105.1108556, 18.4941737],
                [105.1104633, 18.4940241],
                [105.1103455, 18.4938744],
                [105.1103061, 18.4936496],
                [105.1107766, 18.4932747],
                [105.1118356, 18.4931243],
                [105.1130512, 18.492674],
                [105.1135413, 18.4923928],
                [105.1136586, 18.4917933],
                [105.113619, 18.4911941],
                [105.1133047, 18.4904825],
                [105.1127944, 18.4897711],
                [105.1111064, 18.4876369],
                [105.1104391, 18.4868879],
                [105.1100466, 18.4864013],
                [105.109654, 18.4858396],
                [105.1094577, 18.4852404],
                [105.1093002, 18.4846411],
                [105.1090246, 18.4829181],
                [105.1090243, 18.4825059],
                [105.1095338, 18.4819439],
                [105.1098461, 18.4795462],
                [105.1096888, 18.478947],
                [105.1094531, 18.4783477],
                [105.1091785, 18.4780107],
                [105.1086683, 18.4777488],
                [105.108119, 18.477337],
                [105.1076286, 18.4770939],
                [105.1073144, 18.4763823],
                [105.1073142, 18.4760825],
                [105.1073533, 18.4758953],
                [105.1075492, 18.4755955],
                [105.1078236, 18.4752582],
                [105.1080182, 18.4747911],
                [105.1082152, 18.4742839],
                [105.108302, 18.4740144],
                [105.1084319, 18.4734759],
                [105.1083013, 18.472869],
                [105.1080963, 18.4724485],
                [105.1079963, 18.4720344],
                [105.1076911, 18.4711999],
                [105.1080359, 18.469845],
                [105.1080748, 18.4693206],
                [105.1080351, 18.4686838],
                [105.1079563, 18.4681219],
                [105.1079169, 18.467635],
                [105.1079557, 18.4671105],
                [105.1080731, 18.4668483],
                [105.1085826, 18.4660987],
                [105.1086216, 18.4658365],
                [105.1086211, 18.4650872],
                [105.108464, 18.4645629],
                [105.1077968, 18.4637767],
                [105.107012, 18.4631028],
                [105.106502, 18.4627285],
                [105.1059527, 18.4623542],
                [105.1054819, 18.4620547],
                [105.1051678, 18.4616429],
                [105.1050108, 18.461231],
                [105.1049321, 18.460819],
                [105.1050102, 18.4602195],
                [105.1053893, 18.4588737],
                [105.1054093, 18.4584661],
                [105.1054904, 18.4581942],
                [105.105957, 18.4577087],
                [105.1069313, 18.4574363],
                [105.1078445, 18.4565233],
                [105.1082497, 18.455203],
                [105.1070311, 18.4544078],
                [105.1062994, 18.4532046],
                [105.1054865, 18.4517685],
                [105.1050998, 18.4502933],
                [105.104894, 18.4499277],
                [105.1047331, 18.4492688],
                [105.1045539, 18.4487474],
                [105.1044095, 18.4485037],
                [105.1041198, 18.4482248],
                [105.1037209, 18.4479452],
                [105.1034678, 18.4476316],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [106.1243692, 20.8300845],
                [106.1245709, 20.82986],
                [106.1249368, 20.8295983],
                [106.1252743, 20.8293393],
                [106.1254303, 20.8291592],
                [106.1261947, 20.8293711],
                [106.1267215, 20.8295431],
                [106.1273328, 20.8296824],
                [106.1277741, 20.8297593],
                [106.1282662, 20.8298198],
                [106.1286731, 20.829817],
                [106.1290794, 20.8297507],
                [106.1296457, 20.8295341],
                [106.1302029, 20.8292434],
                [106.1306752, 20.8289213],
                [106.1311303, 20.8285675],
                [106.1315508, 20.8281181],
                [106.1319869, 20.8275093],
                [106.1321875, 20.8271252],
                [106.1324301, 20.826701],
                [106.1326981, 20.8262687],
                [106.1329833, 20.825868],
                [106.1332518, 20.8255155],
                [106.1335709, 20.8251147],
                [106.1339752, 20.8247612],
                [106.1343803, 20.8245352],
                [106.1349803, 20.8243],
                [106.1354703, 20.8240735],
                [106.1359092, 20.8238474],
                [106.136483, 20.8234927],
                [106.1369722, 20.8231864],
                [106.1374612, 20.8228324],
                [106.1383701, 20.8219652],
                [106.1387144, 20.8215403],
                [106.1391002, 20.8209955],
                [106.1395199, 20.8204505],
                [106.1397708, 20.8200023],
                [106.1401018, 20.8192505],
                [106.140292, 20.8186193],
                [106.1404243, 20.8181719],
                [106.1405394, 20.8177086],
                [106.1406373, 20.8171977],
                [106.1406679, 20.8167669],
                [106.1406732, 20.8163443],
                [106.1406185, 20.8158343],
                [106.1405473, 20.8153723],
                [106.1402686, 20.8143854],
                [106.140113, 20.8139719],
                [106.1399662, 20.813614],
                [106.1397089, 20.813201],
                [106.1395196, 20.8128356],
                [106.1393307, 20.812502],
                [106.1391929, 20.8122158],
                [106.1390205, 20.8118343],
                [106.1388647, 20.8113887],
                [106.1386484, 20.8108222],
                [106.1384591, 20.8104568],
                [106.1382532, 20.8101234],
                [106.1379969, 20.8098541],
                [106.1376387, 20.8095696],
                [106.1372984, 20.8093966],
                [106.13684, 20.809304],
                [106.1363311, 20.8092595],
                [106.1357884, 20.8092472],
                [106.1351108, 20.8093153],
                [106.1346872, 20.8093501],
                [106.1342634, 20.8093369],
                [106.1338896, 20.8092437],
                [106.1331755, 20.8089616],
                [106.1325026, 20.8085298],
                [106.1320081, 20.8081343],
                [106.1316324, 20.8077859],
                [106.1313075, 20.8074052],
                [106.1311191, 20.8071513],
                [106.1310838, 20.80696],
                [106.1311326, 20.8066885],
                [106.1313508, 20.8064],
                [106.13173, 20.8061024],
                [106.1322361, 20.8057641],
                [106.1327255, 20.8054579],
                [106.1332317, 20.8051514],
                [106.133687, 20.8048134],
                [106.1341586, 20.8044116],
                [106.134613, 20.803962],
                [106.135143, 20.8034241],
                [106.1356479, 20.8029264],
                [106.1363387, 20.8023635],
                [106.1368943, 20.8018653],
                [106.1369521, 20.8018204],
                [106.1374422, 20.801439],
                [106.1380155, 20.8010364],
                [106.1385042, 20.8006503],
                [106.1389415, 20.8001849],
                [106.139179, 20.7998689],
                [106.1394115, 20.7995597],
                [106.1397793, 20.7988714],
                [106.1399536, 20.7983838],
                [106.1400859, 20.7979522],
                [106.14032, 20.79752],
                [106.1407962, 20.7977241],
                [106.141732, 20.7981806],
                [106.1418985, 20.7977807],
                [106.1419637, 20.7974293],
                [106.1419929, 20.796807],
                [106.1420067, 20.7963762],
                [106.142043, 20.7955706],
                [106.1420566, 20.7951239],
                [106.14205, 20.7942467],
                [106.142098, 20.7938794],
                [106.1422393, 20.7935351],
                [106.1425071, 20.7930708],
                [106.1429273, 20.7925898],
                [106.1434662, 20.792092],
                [106.144694, 20.7907923],
                [106.1452659, 20.7901827],
                [106.145719, 20.7895737],
                [106.1461198, 20.7887578],
                [106.1463754, 20.7877934],
                [106.1465579, 20.7872658],
                [106.1468588, 20.7867054],
                [106.1472785, 20.7861601],
                [106.1475887, 20.7857193],
                [106.1480598, 20.7852217],
                [106.1486656, 20.7846114],
                [106.1492718, 20.7840808],
                [106.1495632, 20.7838571],
                [106.1497308, 20.7837318],
                [106.1498445, 20.7836468],
                [106.1502327, 20.7833565],
                [106.150999, 20.7826892],
                [106.1517236, 20.7821101],
                [106.1522297, 20.7817717],
                [106.1529383, 20.7813202],
                [106.1536648, 20.7809962],
                [106.1544759, 20.7806399],
                [106.1551434, 20.7803561],
                [106.1558197, 20.7801124],
                [106.1564628, 20.7799803],
                [106.1570389, 20.7799286],
                [106.1577347, 20.7800196],
                [106.1584652, 20.7802221],
                [106.1591882, 20.7805443],
                [106.1596482, 20.7808443],
                [106.1599393, 20.7812252],
                [106.1601628, 20.7816225],
                [106.1603544, 20.7822913],
                [106.1605464, 20.7830239],
                [106.1605517, 20.7830351],
                [106.1607792, 20.7835248],
                [106.1610703, 20.7839217],
                [106.1614963, 20.7841901],
                [106.1620915, 20.7844253],
                [106.162788, 20.7846121],
                [106.1638073, 20.7848763],
                [106.1643678, 20.7850161],
                [106.1651903, 20.7850344],
                [106.1655796, 20.784968],
                [106.1660014, 20.7846939],
                [106.1664391, 20.7842921],
                [106.1670953, 20.7836336],
                [106.1677511, 20.7829431],
                [106.1682888, 20.7823173],
                [106.1686324, 20.7817965],
                [106.1688665, 20.78138],
                [106.1691684, 20.7809632],
                [106.169706, 20.7803214],
                [106.1702098, 20.7796957],
                [106.1704702, 20.7793829],
                [106.1706878, 20.7790305],
                [106.1709036, 20.7784387],
                [106.1710695, 20.7779589],
                [106.1714693, 20.7770469],
                [106.1716789, 20.7767503],
                [106.1718649, 20.7766853],
                [106.1721876, 20.7767627],
                [106.1725109, 20.7769201],
                [106.1729194, 20.7771247],
                [106.1732608, 20.7774255],
                [106.1735678, 20.7776787],
                [106.1739593, 20.7778675],
                [106.174265, 20.7779451],
                [106.1745362, 20.7779432],
                [106.1748748, 20.7778771],
                [106.1752627, 20.7776192],
                [106.17586, 20.7770329],
                [106.1762457, 20.7765037],
                [106.1764974, 20.776151],
                [106.1766472, 20.775799],
                [106.1766616, 20.7754639],
                [106.1765737, 20.7750657],
                [106.1763084, 20.7747405],
                [106.1758309, 20.7743608],
                [106.1752862, 20.7740774],
                [106.1748439, 20.773889],
                [106.1746391, 20.7737149],
                [106.1744338, 20.773461],
                [106.1742784, 20.7731111],
                [106.1742414, 20.7726965],
                [106.1744247, 20.7722965],
                [106.1749026, 20.7716152],
                [106.1753554, 20.770974],
                [106.1759767, 20.7702199],
                [106.1764127, 20.7695948],
                [106.17711, 20.7688002],
                [106.1775634, 20.7682388],
                [106.177848, 20.7677742],
                [106.1780974, 20.7671503],
                [106.1784216, 20.7663264],
                [106.1786386, 20.7658942],
                [106.1789902, 20.7653334],
                [106.1793928, 20.7647882],
                [106.1798636, 20.7642904],
                [106.1803435, 20.7638803],
                [106.1810348, 20.763397],
                [106.1814811, 20.7630968],
                [106.1814901, 20.7630907],
                [106.1819806, 20.7629597],
                [106.1826406, 20.7628116],
                [106.1833177, 20.7626794],
                [106.1837583, 20.7626762],
                [106.1842419, 20.7628257],
                [106.1845829, 20.7630626],
                [106.1848912, 20.7634751],
                [106.1852692, 20.7641103],
                [106.1855792, 20.7647302],
                [106.1861276, 20.7654759],
                [106.1867511, 20.7660724],
                [106.187253, 20.7663912],
                [106.1873475, 20.7664512],
                [106.1878748, 20.766671],
                [106.1883671, 20.7667473],
                [106.1887736, 20.7666966],
                [106.1892131, 20.7665181],
                [106.1895843, 20.7662921],
                [106.1900397, 20.7659859],
                [106.1905382, 20.7657829],
                [106.1910972, 20.7657151],
                [106.1921489, 20.7657717],
                [106.1928273, 20.765799],
                [106.1930368, 20.7654863],
                [106.1932712, 20.7651177],
                [106.1933874, 20.7647978],
                [106.1934701, 20.764526],
                [106.1935176, 20.7640949],
                [106.1935489, 20.7637754],
                [106.193699, 20.7634553],
                [106.1940347, 20.7630221],
                [106.1943972, 20.7627563],
                [106.1948181, 20.7623863],
                [106.1950871, 20.7620972],
                [106.1954398, 20.7616799],
                [106.1956748, 20.7613751],
                [106.1958421, 20.7610867],
                [106.1959579, 20.7607189],
                [106.1960398, 20.7603514],
                [106.1961341, 20.7599557],
                [106.1961656, 20.7596603],
                [106.1962395, 20.7593329],
                [106.1963218, 20.7590372],
                [106.196396, 20.7587576],
                [106.1965209, 20.7584696],
                [106.1966628, 20.7581815],
                [106.1969088, 20.7576584],
                [106.1971856, 20.7572894],
                [106.1974293, 20.7570084],
                [106.1977069, 20.7567273],
                [106.1980555, 20.7563137],
                [106.1984248, 20.7558404],
                [106.1986262, 20.7555757],
                [106.1987854, 20.7553352],
                [106.199008, 20.7550662],
                [106.199269, 20.7548331],
                [106.1994968, 20.7546878],
                [106.1998177, 20.7545339],
                [106.2001469, 20.754348],
                [106.2006622, 20.7540971],
                [106.2011722, 20.7537856],
                [106.2018131, 20.7533184],
                [106.2020655, 20.7530854],
                [106.2023431, 20.7527962],
                [106.2027391, 20.7524664],
                [106.2029497, 20.7522815],
                [106.2032023, 20.7520643],
                [106.2035017, 20.751852],
                [106.2037215, 20.7517626],
                [106.2040598, 20.7516485],
                [106.2044748, 20.7515897],
                [106.2047971, 20.7515955],
                [106.2052382, 20.7516163],
                [106.2059638, 20.751687],
                [106.2068287, 20.751681],
                [106.2071678, 20.7516706],
                [106.2077908, 20.7516224],
                [106.2082822, 20.751587],
                [106.2087061, 20.751568],
                [106.2091807, 20.7515327],
                [106.2098629, 20.751468],
                [106.2102354, 20.7514017],
                [106.2106925, 20.7513026],
                [106.2110819, 20.7512121],
                [106.2118481, 20.751059],
                [106.2127283, 20.7508454],
                [106.2131728, 20.7507504],
                [106.213579, 20.7506597],
                [106.2139942, 20.750617],
                [106.2143416, 20.7505745],
                [106.21513, 20.750553],
                [106.2156168, 20.7504498],
                [106.2158785, 20.7503043],
                [106.216013, 20.7501517],
                [106.2160706, 20.7499437],
                [106.2160822, 20.7497959],
                [106.2160938, 20.7496483],
                [106.2160484, 20.7492735],
                [106.2160355, 20.7490078],
                [106.2161638, 20.7487547],
                [106.2162982, 20.7485197],
                [106.2164445, 20.7482958],
                [106.2166784, 20.7479096],
                [106.2167719, 20.7477586],
                [106.2168475, 20.7475852],
                [106.2168704, 20.7474791],
                [106.2168993, 20.7470172],
                [106.2168785, 20.7466492],
                [106.2168585, 20.7463594],
                [106.216848, 20.7461532],
                [106.2168526, 20.7459914],
                [106.2169272, 20.7456785],
                [106.2170895, 20.745248],
                [106.2174059, 20.7444314],
                [106.2177103, 20.7436151],
                [106.2180429, 20.7426033],
                [106.2182364, 20.7419774],
                [106.2183575, 20.7415693],
                [106.2185029, 20.74086],
                [106.2185831, 20.7405193],
                [106.2187673, 20.7394807],
                [106.2188521, 20.738599],
                [106.2189372, 20.7377618],
                [106.2189452, 20.7369086],
                [106.2189452, 20.7369085],
                [106.2189474, 20.7360775],
                [106.2188727, 20.7352638],
                [106.218727, 20.7344506],
                [106.2186296, 20.7337487],
                [106.218528, 20.7325392],
                [106.2184913, 20.7323944],
                [106.2183806, 20.7322391],
                [106.2182735, 20.7321953],
                [106.2181135, 20.7321853],
                [106.2178945, 20.7322035],
                [106.2173265, 20.7322912],
                [106.2167408, 20.7323902],
                [106.2153535, 20.7326119],
                [106.2144155, 20.7327245],
                [106.214143, 20.7327319],
                [106.2137814, 20.7326955],
                [106.2134044, 20.7325921],
                [106.213339, 20.7325536],
                [106.2131665, 20.7324601],
                [106.2128931, 20.7322282],
                [106.2127489, 20.732106],
                [106.2124033, 20.7318518],
                [106.2118463, 20.7314699],
                [106.2117801, 20.7313098],
                [106.2117668, 20.731137],
                [106.211812, 20.7308634],
                [106.2120643, 20.7301868],
                [106.2123682, 20.7296715],
                [106.2125297, 20.7294077],
                [106.2127096, 20.7290334],
                [106.2130077, 20.7285238],
                [106.2136366, 20.7279059],
                [106.214344, 20.7274659],
                [106.2150869, 20.7270368],
                [106.2156172, 20.7266874],
                [106.2163731, 20.726392],
                [106.2168925, 20.7261652],
                [106.2171368, 20.7259687],
                [106.2174548, 20.7257245],
                [106.2178765, 20.7254402],
                [106.2182639, 20.7251759],
                [106.2185541, 20.7249514],
                [106.2188023, 20.7246879],
                [106.2190771, 20.7242739],
                [106.2193269, 20.7237823],
                [106.2195251, 20.7233554],
                [106.2197298, 20.7228761],
                [106.2199793, 20.722331],
                [106.2202191, 20.7218841],
                [106.2205587, 20.7213122],
                [106.2207163, 20.7210361],
                [106.22084, 20.7208454],
                [106.2211744, 20.7204961],
                [106.2216296, 20.7200412],
                [106.2219465, 20.7196723],
                [106.2223845, 20.7192307],
                [106.2227638, 20.7188548],
                [106.2231572, 20.7184854],
                [106.2235534, 20.7180375],
                [106.2237873, 20.7177347],
                [106.2239177, 20.7175177],
                [106.2240303, 20.7172419],
                [106.2240772, 20.7170322],
                [106.2241104, 20.7168355],
                [106.224107, 20.71641],
                [106.2242257, 20.715172],
                [106.2243459, 20.7141303],
                [106.2246014, 20.7121842],
                [106.2246926, 20.7118497],
                [106.2249522, 20.7112849],
                [106.2251846, 20.7107922],
                [106.2254882, 20.7100636],
                [106.225776, 20.7095377],
                [106.2258543, 20.7093343],
                [106.2259082, 20.7091375],
                [106.2259625, 20.7089212],
                [106.2261171, 20.7083047],
                [106.2262837, 20.7078452],
                [106.2267905, 20.7068924],
                [106.2271574, 20.7062548],
                [106.2272943, 20.705992],
                [106.2274578, 20.7055784],
                [106.2275708, 20.7053681],
                [106.2276946, 20.7052035],
                [106.2277281, 20.7051615],
                [106.2278048, 20.7050653],
                [106.2279701, 20.7048875],
                [106.2281634, 20.7047093],
                [106.2284093, 20.7045962],
                [106.2287211, 20.7044631],
                [106.2289637, 20.7043894],
                [106.2291997, 20.7043288],
                [106.2295604, 20.7042411],
                [106.2301643, 20.7041123],
                [106.2310418, 20.7038835],
                [106.2316937, 20.7036891],
                [106.2323316, 20.7034815],
                [106.2332567, 20.7031083],
                [106.233769, 20.7028429],
                [106.2339765, 20.7027104],
                [106.2343881, 20.7024587],
                [106.2348793, 20.7021671],
                [106.2352595, 20.7018894],
                [106.2356256, 20.701612],
                [106.2362307, 20.7012082],
                [106.236929, 20.700745],
                [106.2373161, 20.7004672],
                [106.2378034, 20.7001234],
                [106.2381973, 20.699826],
                [106.2388125, 20.699396],
                [106.2395281, 20.6989065],
                [106.2403097, 20.6984229],
                [106.2409285, 20.6980192],
                [106.2417097, 20.6974833],
                [106.2419514, 20.6972851],
                [106.2425975, 20.6968092],
                [106.2433576, 20.6962407],
                [106.2440653, 20.6956464],
                [106.2442858, 20.6954092],
                [106.2443475, 20.695304],
                [106.2444291, 20.6950743],
                [106.2445524, 20.6944253],
                [106.2445998, 20.6942679],
                [106.2446611, 20.6941299],
                [106.2447501, 20.6939526],
                [106.2449453, 20.6935911],
                [106.2450831, 20.6934396],
                [106.2452486, 20.6932879],
                [106.2454142, 20.6931229],
                [106.2457455, 20.6928391],
                [106.2462049, 20.6924888],
                [106.2463983, 20.6923368],
                [106.2466122, 20.6921323],
                [106.2467013, 20.6919877],
                [106.2467694, 20.6918105],
                [106.2468262, 20.6915221],
                [106.2468384, 20.6913124],
                [106.2468011, 20.6910116],
                [106.2467202, 20.6904623],
                [106.2467143, 20.6901743],
                [106.2467336, 20.6899843],
                [106.2468844, 20.6897149],
                [106.2469671, 20.6896422],
                [106.2472231, 20.6894833],
                [106.247899, 20.6892558],
                [106.2485647, 20.6890481],
                [106.2484203, 20.6883945],
                [106.2483772, 20.6878056],
                [106.2483896, 20.6876288],
                [106.2484672, 20.6873467],
                [106.2485625, 20.6869886],
                [106.2487619, 20.6868209],
                [106.2489973, 20.6867145],
                [106.2492128, 20.6867129],
                [106.2494082, 20.6868163],
                [106.2496389, 20.686952],
                [106.2503096, 20.6869537],
                [106.2507177, 20.686605],
                [106.2511658, 20.6864538],
                [106.2515199, 20.6865754],
                [106.2518739, 20.6867517],
                [106.2526622, 20.6871028],
                [106.2531021, 20.6871888],
                [106.2534913, 20.6870523],
                [106.2536375, 20.6870365],
                [106.2543404, 20.6874618],
                [106.2544714, 20.6875795],
                [106.2561052, 20.686499],
                [106.2564619, 20.6863629],
                [106.2565923, 20.6863916],
                [106.257887, 20.6875843],
                [106.258002, 20.6877171],
                [106.2582298, 20.6877302],
                [106.2602894, 20.6860158],
                [106.2619399, 20.6849944],
                [106.2625073, 20.6847676],
                [106.2639034, 20.6844606],
                [106.2643419, 20.6843831],
                [106.2649112, 20.684379],
                [106.2654646, 20.6844343],
                [106.2672711, 20.6845694],
                [106.2695282, 20.6847149],
                [106.2695188, 20.6853948],
                [106.2694409, 20.6858621],
                [106.2694017, 20.6860772],
                [106.2692718, 20.6864236],
                [106.2687622, 20.6872442],
                [106.2685595, 20.6874605],
                [106.2683364, 20.6876768],
                [106.2680208, 20.6878379],
                [106.2678477, 20.6879419],
                [106.2675623, 20.6880373],
                [106.2673277, 20.6881138],
                [106.2670114, 20.6881816],
                [106.2663318, 20.6882378],
                [106.2646551, 20.6882502],
                [106.263106, 20.6882474],
                [106.2618492, 20.6883593],
                [106.2600058, 20.6886203],
                [106.2590152, 20.688777],
                [106.2586988, 20.6888446],
                [106.258362, 20.6889124],
                [106.2570198, 20.689207],
                [106.2561528, 20.6894748],
                [106.2549302, 20.6900065],
                [106.2542634, 20.6903599],
                [106.2532768, 20.6910015],
                [106.251874, 20.6920095],
                [106.2509727, 20.6930521],
                [106.2503473, 20.6937553],
                [106.2496025, 20.6943723],
                [106.249272, 20.6947197],
                [106.2490414, 20.6950507],
                [106.2488934, 20.695287],
                [106.2488119, 20.695507],
                [106.2487647, 20.6958524],
                [106.2488338, 20.6961498],
                [106.2490031, 20.6964779],
                [106.2492391, 20.6968055],
                [106.2498452, 20.6975538],
                [106.2503159, 20.6980522],
                [106.2518741, 20.6989025],
                [106.2555256, 20.7008612],
                [106.2577161, 20.7019729],
                [106.2593544, 20.7028153],
                [106.2605605, 20.7033953],
                [106.2614484, 20.7037352],
                [106.2628425, 20.7041059],
                [106.2633869, 20.7041827],
                [106.2645511, 20.7042897],
                [106.2652569, 20.7042712],
                [106.2662178, 20.7039772],
                [106.2668402, 20.7035513],
                [106.2674934, 20.7030269],
                [106.2684672, 20.7015188],
                [106.269505, 20.6993463],
                [106.2702835, 20.6979263],
                [106.2708563, 20.6968714],
                [106.2714006, 20.6961689],
                [106.2717009, 20.6957857],
                [106.2719887, 20.6954373],
                [106.2722772, 20.6951465],
                [106.2732312, 20.6943081],
                [106.2742047, 20.6935447],
                [106.2752862, 20.6928324],
                [106.2757212, 20.6926735],
                [106.2764027, 20.6925068],
                [106.2774259, 20.6923723],
                [106.2784122, 20.6923419],
                [106.2793232, 20.6923928],
                [106.2805948, 20.6924719],
                [106.2821767, 20.6925988],
                [106.2831008, 20.6926958],
                [106.2840951, 20.6928789],
                [106.2851084, 20.6930562],
                [106.2860592, 20.6933377],
                [106.2871883, 20.6937449],
                [106.288617, 20.6944446],
                [106.2888981, 20.694585],
                [106.2890499, 20.6956481],
                [106.2887765, 20.6981023],
                [106.2872467, 20.6999904],
                [106.2857579, 20.7019281],
                [106.2856012, 20.7020872],
                [106.2853477, 20.7020313],
                [106.2850047, 20.7018606],
                [106.2848526, 20.7018387],
                [106.2846382, 20.7019095],
                [106.2844495, 20.702038],
                [106.2833459, 20.7031781],
                [106.2831969, 20.7035026],
                [106.2829993, 20.7040817],
                [106.282824, 20.7050358],
                [106.2827393, 20.7062549],
                [106.282767, 20.708045],
                [106.2828165, 20.7086509],
                [106.2829485, 20.7093198],
                [106.2830843, 20.7096711],
                [106.2835975, 20.710522],
                [106.2840322, 20.7110616],
                [106.2842743, 20.7112677],
                [106.284465, 20.7113702],
                [106.2848202, 20.7114946],
                [106.2850482, 20.7115276],
                [106.2854279, 20.7115479],
                [106.2865282, 20.711505],
                [106.2871279, 20.7113677],
                [106.2877713, 20.711155],
                [106.2883909, 20.710744],
                [106.2888281, 20.7104541],
                [106.2896069, 20.7098014],
                [106.2899151, 20.709597],
                [106.2904448, 20.7093377],
                [106.2910488, 20.7090226],
                [106.2914507, 20.7086846],
                [106.2918072, 20.7084282],
                [106.2922473, 20.7081584],
                [106.2933185, 20.7076872],
                [106.2937325, 20.7074579],
                [106.2941104, 20.707156],
                [106.2943993, 20.7068681],
                [106.2946661, 20.7065066],
                [106.2947704, 20.7062796],
                [106.2948305, 20.7060611],
                [106.2949684, 20.7056237],
                [106.2949727, 20.7050904],
                [106.2949204, 20.7041378],
                [106.2948603, 20.7033147],
                [106.2946889, 20.7024162],
                [106.2945042, 20.7014965],
                [106.2943518, 20.7007501],
                [106.2942253, 20.7004522],
                [106.2941566, 20.699382],
                [106.2940775, 20.6985058],
                [106.2936572, 20.6977879],
                [106.2936877, 20.6976193],
                [106.2938159, 20.6970452],
                [106.293903, 20.696883],
                [106.2941616, 20.6965861],
                [106.2947068, 20.6961861],
                [106.2955501, 20.6954363],
                [106.296492, 20.6943261],
                [106.2973082, 20.6935201],
                [106.2987072, 20.6920348],
                [106.2995451, 20.6911922],
                [106.3000899, 20.6907437],
                [106.3005691, 20.6903724],
                [106.3010179, 20.6900862],
                [106.3013482, 20.6898836],
                [106.301669, 20.6897349],
                [106.3020841, 20.6896186],
                [106.3022786, 20.689609],
                [106.3025087, 20.6895912],
                [106.3027211, 20.6895895],
                [106.3029339, 20.6896284],
                [106.3031113, 20.6896755],
                [106.3033244, 20.6897546],
                [106.3035289, 20.6898661],
                [106.303787, 20.6900338],
                [106.3040545, 20.690266],
                [106.3053122, 20.6913873],
                [106.3069745, 20.6927379],
                [106.3084123, 20.6937008],
                [106.3092927, 20.6941871],
                [106.3104082, 20.694716],
                [106.3118297, 20.6953438],
                [106.3133088, 20.6959588],
                [106.3144991, 20.6964428],
                [106.3155866, 20.6968103],
                [106.317133, 20.6975339],
                [106.319609, 20.6987799],
                [106.3219631, 20.7002691],
                [106.3238917, 20.7016645],
                [106.3255814, 20.703078],
                [106.326148, 20.703595],
                [106.3267741, 20.7043337],
                [106.3282147, 20.7060838],
                [106.3293295, 20.7075114],
                [106.3304164, 20.7088005],
                [106.3313673, 20.7097105],
                [106.3319999, 20.7101665],
                [106.3324493, 20.7104256],
                [106.3328051, 20.7106007],
                [106.3331339, 20.7107437],
                [106.3334713, 20.7108543],
                [106.334492, 20.7111454],
                [106.335499, 20.711368],
                [106.3367492, 20.7115765],
                [106.3374944, 20.7116475],
                [106.3382859, 20.7116414],
                [106.3390731, 20.7115464],
                [106.3395899, 20.711417],
                [106.3403047, 20.7111367],
                [106.341072, 20.7107992],
                [106.341459, 20.7105133],
                [106.3418404, 20.7100779],
                [106.3423542, 20.7091119],
                [106.3427934, 20.7081991],
                [106.3433686, 20.707168],
                [106.3439658, 20.7061246],
                [106.3443664, 20.7053698],
                [106.3447031, 20.7048862],
                [106.3451326, 20.7043978],
                [106.3457382, 20.7037948],
                [106.3464271, 20.7031064],
                [106.3469804, 20.7026009],
                [106.3474192, 20.7022868],
                [106.3483496, 20.7017455],
                [106.3490105, 20.7014089],
                [106.3496721, 20.7011289],
                [106.3502059, 20.7009186],
                [106.3505768, 20.7008105],
                [106.3508773, 20.7007516],
                [106.3511956, 20.7007006],
                [106.3522127, 20.7005956],
                [106.3533413, 20.7005747],
                [106.3544128, 20.7005905],
                [106.355755, 20.7006971],
                [106.3563136, 20.7007897],
                [106.3568632, 20.7008823],
                [106.3579766, 20.7011201],
                [106.3590595, 20.701435],
                [106.3601163, 20.7018391],
                [106.3607565, 20.7021331],
                [106.3615751, 20.702571],
                [106.3625371, 20.7032016],
                [106.3634822, 20.7039213],
                [106.3646154, 20.7048121],
                [106.3646906, 20.7048713],
                [106.3655565, 20.7056486],
                [106.3672908, 20.706981],
                [106.3688543, 20.7080317],
                [106.3696113, 20.7085108],
                [106.3709334, 20.7092966],
                [106.3722151, 20.710014],
                [106.3731084, 20.7103828],
                [106.3737923, 20.7106119],
                [106.3748976, 20.7109143],
                [106.3756961, 20.7110697],
                [106.3765742, 20.7112324],
                [106.3771417, 20.7113169],
                [106.3783606, 20.7114485],
                [106.3790695, 20.7115156],
                [106.3797515, 20.7115182],
                [106.381031, 20.7115039],
                [106.3815628, 20.7115563],
                [106.3820465, 20.7116778],
                [106.3823399, 20.7118128],
                [106.3826967, 20.7120849],
                [106.3832885, 20.7128967],
                [106.3837483, 20.7133162],
                [106.3848829, 20.7143916],
                [106.3860549, 20.715211],
                [106.3868574, 20.715819],
                [106.3884749, 20.7169256],
                [106.3890541, 20.7173252],
                [106.3897899, 20.7178892],
                [106.3907009, 20.7187388],
                [106.3921165, 20.7200493],
                [106.3934435, 20.7213443],
                [106.3938314, 20.7217101],
                [106.3939297, 20.7217983],
                [106.3940095, 20.7218138],
                [106.3941421, 20.7217803],
                [106.3946101, 20.721623],
                [106.3948819, 20.7214909],
                [106.3949312, 20.7215492],
                [106.3953593, 20.7221695],
                [106.3958375, 20.7231272],
                [106.3961406, 20.7237676],
                [106.3964324, 20.7237657],
                [106.3965685, 20.7238122],
                [106.3966734, 20.723878],
                [106.3967784, 20.7239533],
                [106.3968314, 20.7240386],
                [106.3970436, 20.7244365],
                [106.3971703, 20.7245878],
                [106.3972544, 20.7246632],
                [106.397349, 20.7247291],
                [106.3974225, 20.7247856],
                [106.3975896, 20.7248033],
                [106.3979318, 20.7248291],
                [106.3987513, 20.7248424],
                [106.3989273, 20.7248931],
                [106.3990872, 20.7249404],
                [106.3992833, 20.7250761],
                [106.3994174, 20.7252044],
                [106.3996232, 20.725437],
                [106.3999113, 20.7259357],
                [106.4000921, 20.7263383],
                [106.4000935, 20.7263412],
                [106.4002458, 20.7266764],
                [106.400981, 20.7281152],
                [106.4011917, 20.7283844],
                [106.401335, 20.7285611],
                [106.4015048, 20.7287215],
                [106.401692, 20.7288494],
                [106.4019408, 20.7289443],
                [106.4021094, 20.7289753],
                [106.4024639, 20.7289887],
                [106.4032252, 20.7289339],
                [106.4046984, 20.7287481],
                [106.4072373, 20.7282926],
                [106.4076589, 20.7284328],
                [106.4082768, 20.7288512],
                [106.4088632, 20.7296717],
                [106.4090969, 20.7300235],
                [106.4095173, 20.730947],
                [106.410226, 20.7321176],
                [106.4106467, 20.7328062],
                [106.4110039, 20.7334205],
                [106.411095, 20.7335772],
                [106.4115391, 20.7340239],
                [106.4116906, 20.734198],
                [106.412127, 20.7346524],
                [106.4125478, 20.7349894],
                [106.4130623, 20.7352827],
                [106.4139512, 20.735415],
                [106.4148711, 20.7354447],
                [106.4156508, 20.7353718],
                [106.4167892, 20.735138],
                [106.4180731, 20.7348038],
                [106.4192476, 20.7345992],
                [106.4203675, 20.7343945],
                [106.4215694, 20.7342154],
                [106.4229077, 20.7340109],
                [106.4247988, 20.7335238],
                [106.4265578, 20.7328088],
                [106.4278771, 20.7321762],
                [106.4290097, 20.7316011],
                [106.4303692, 20.7306355],
                [106.4309557, 20.7300025],
                [106.4321286, 20.7289292],
                [106.4327704, 20.7280612],
                [106.4331256, 20.7275807],
                [106.4337416, 20.7267825],
                [106.4341669, 20.7259054],
                [106.434343, 20.7255474],
                [106.4345161, 20.7247415],
                [106.4346927, 20.7237719],
                [106.4349698, 20.7227078],
                [106.4353478, 20.7217146],
                [106.435675, 20.7210999],
                [106.4361155, 20.7205916],
                [106.4367948, 20.7202371],
                [106.4376901, 20.720144],
                [106.4383924, 20.7203442],
                [106.4390589, 20.7207819],
                [106.4396624, 20.7213497],
                [106.4404923, 20.7220595],
                [106.4409953, 20.7225799],
                [106.4416742, 20.7233842],
                [106.442567, 20.7243423],
                [106.4430196, 20.72491],
                [106.443598, 20.7256197],
                [106.4441664, 20.7261917],
                [106.4446541, 20.7270863],
                [106.4452449, 20.7279497],
                [106.4456221, 20.7285646],
                [106.4459993, 20.7292506],
                [106.4462759, 20.7298182],
                [106.4465273, 20.7305751],
                [106.4466528, 20.7311663],
                [106.4466148, 20.7316749],
                [106.4465392, 20.7322423],
                [106.4464383, 20.7327154],
                [106.4462872, 20.7333302],
                [106.4461108, 20.7341578],
                [106.4460351, 20.7347254],
                [106.4460852, 20.7352457],
                [106.4462864, 20.7356715],
                [106.4466133, 20.7360262],
                [106.4471165, 20.7363338],
                [106.4477454, 20.736405],
                [106.4481733, 20.7363578],
                [106.4488274, 20.7362161],
                [106.4495319, 20.7359799],
                [106.4501863, 20.7356253],
                [106.450828, 20.735117],
                [106.4516082, 20.7343131],
                [106.4521116, 20.7336512],
                [106.4529239, 20.7308892],
                [106.4530598, 20.7302764],
                [106.4530698, 20.7300062],
                [106.4530357, 20.729362],
                [106.453036, 20.7283313],
                [106.4529677, 20.7277837],
                [106.4528649, 20.7273651],
                [106.4526592, 20.7270751],
                [106.4525222, 20.7267207],
                [106.4523165, 20.7264308],
                [106.4522137, 20.7262053],
                [106.4520424, 20.7259798],
                [106.451631, 20.725561],
                [106.4514254, 20.7250134],
                [106.45134, 20.7246922],
                [106.4512884, 20.724498],
                [106.4512886, 20.7237893],
                [106.4513575, 20.7233064],
                [106.4515291, 20.7227266],
                [106.4517643, 20.7223458],
                [106.4520459, 20.7220354],
                [106.4525863, 20.7217605],
                [106.453577, 20.7215704],
                [106.4550855, 20.7215497],
                [106.4561212, 20.7216557],
                [106.4574494, 20.7218887],
                [106.4586202, 20.722185],
                [106.4602861, 20.7226931],
                [106.461817, 20.7233067],
                [106.4624849, 20.7236215],
                [106.4632819, 20.7241638],
                [106.4645528, 20.7255923],
                [106.4647382, 20.7257906],
                [106.4649002, 20.7260442],
                [106.4651645, 20.7264584],
                [106.4652327, 20.7265652],
                [106.4655074, 20.7277013],
                [106.4655072, 20.7283984],
                [106.4653696, 20.7294053],
                [106.465177, 20.7301798],
                [106.4650119, 20.73098],
                [106.4646269, 20.7316771],
                [106.4640702, 20.7324208],
                [106.4636099, 20.7330355],
                [106.4631738, 20.7336728],
                [106.462011, 20.7348564],
                [106.4615264, 20.7352205],
                [106.4609934, 20.7356759],
                [106.4602182, 20.7361766],
                [106.459443, 20.7366317],
                [106.458474, 20.7371323],
                [106.4574081, 20.7375418],
                [106.456536, 20.7379514],
                [106.455567, 20.738361],
                [106.4545496, 20.7388616],
                [106.4535321, 20.7394078],
                [106.4527325, 20.7401052],
                [106.452418, 20.7405819],
                [106.4521762, 20.740968],
                [106.4521035, 20.7414676],
                [106.4521758, 20.7421715],
                [106.4525625, 20.7428302],
                [106.4533359, 20.7433754],
                [106.4545689, 20.7439435],
                [106.4553909, 20.7442389],
                [106.4567207, 20.7445571],
                [106.4576443, 20.7445287],
                [106.4582269, 20.7442405],
                [106.4586801, 20.7439856],
                [106.4592058, 20.7436899],
                [106.4603041, 20.7431019],
                [106.4606574, 20.7429611],
                [106.4613826, 20.7427608],
                [106.4619538, 20.7426791],
                [106.462308, 20.7426356],
                [106.4626627, 20.7426326],
                [106.4630084, 20.7426296],
                [106.464064, 20.7426692],
                [106.4646902, 20.7427731],
                [106.4654199, 20.7430421],
                [106.4659367, 20.7433048],
                [106.4665612, 20.7437041],
                [106.4671742, 20.7442776],
                [106.4678209, 20.7451381],
                [106.4687343, 20.7465103],
                [106.4694879, 20.7478758],
                [106.4700261, 20.7490052],
                [106.470313, 20.7497982],
                [106.4709808, 20.7512328],
                [106.4717539, 20.7523243],
                [106.4721339, 20.752895],
                [106.4723441, 20.7531151],
                [106.4725822, 20.7533609],
                [106.4730177, 20.7535757],
                [106.4735467, 20.7537733],
                [106.4740396, 20.7538343],
                [106.474835, 20.7537485],
                [106.4752142, 20.75361],
                [106.4756478, 20.7534171],
                [106.4761271, 20.7531171],
                [106.4769033, 20.7525813],
                [106.4778392, 20.7519812],
                [106.478798, 20.7514453],
                [106.4798024, 20.7509739],
                [106.4809666, 20.7506095],
                [106.4822002, 20.7503239],
                [106.4827799, 20.7502684],
                [106.4834702, 20.7502119],
                [106.4841102, 20.750223],
                [106.4848323, 20.750243],
                [106.4854158, 20.750264],
                [106.4859994, 20.7503113],
                [106.4866317, 20.7503776],
                [106.487121, 20.7505208],
                [106.4884061, 20.7511074],
                [106.490575, 20.752286],
                [106.4907225, 20.7524041],
                [106.4908685, 20.7525212],
                [106.4912258, 20.7527898],
                [106.4937731, 20.7553793],
                [106.4940832, 20.7557059],
                [106.4952777, 20.7570327],
                [106.496085, 20.7578778],
                [106.4964626, 20.7582731],
                [106.4984059, 20.7601425],
                [106.4974754, 20.7606003],
                [106.4972289, 20.7607509],
                [106.4969543, 20.7608426],
                [106.4966607, 20.7610076],
                [106.4965248, 20.7610562],
                [106.4962502, 20.7610486],
                [106.4955258, 20.760882],
                [106.4948422, 20.7607218],
                [106.4945084, 20.7606884],
                [106.4942748, 20.7607058],
                [106.4940198, 20.7608326],
                [106.4938775, 20.7609577],
                [106.4937145, 20.7611412],
                [106.4935718, 20.7613441],
                [106.4933526, 20.7617582],
                [106.4930983, 20.7622957],
                [106.4929877, 20.7627075],
                [106.4928297, 20.7636638],
                [106.4929193, 20.7642861],
                [106.4930666, 20.7648133],
                [106.4933139, 20.7654274],
                [106.4936411, 20.7662539],
                [106.4940956, 20.7670389],
                [106.4948647, 20.7681957],
                [106.4957279, 20.7691944],
                [106.4967997, 20.7703259],
                [106.4976396, 20.7710411],
                [106.4979097, 20.7713974],
                [106.4980916, 20.7718113],
                [106.4982308, 20.7723786],
                [106.498396, 20.7732468],
                [106.4984107, 20.773648],
                [106.4984329, 20.7742527],
                [106.4983239, 20.7755739],
                [106.4978832, 20.7785841],
                [106.4976911, 20.7801188],
                [106.4974748, 20.7811858],
                [106.4971234, 20.7825444],
                [106.4967673, 20.7834703],
                [106.4961226, 20.7848361],
                [106.4946654, 20.7877656],
                [106.4938454, 20.7892949],
                [106.4923741, 20.7915205],
                [106.4917828, 20.7923608],
                [106.4906179, 20.7938888],
                [106.4886554, 20.7964095],
                [106.4876868, 20.797617],
                [106.4876327, 20.7981608],
                [106.487501, 20.7984512],
                [106.4869443, 20.7991278],
                [106.4866732, 20.7995066],
                [106.4862999, 20.8002012],
                [106.4886853, 20.802989],
                [106.4895221, 20.8038323],
                [106.4909455, 20.8050721],
                [106.4925371, 20.8062869],
                [106.4939164, 20.8069837],
                [106.4952945, 20.8075622],
                [106.497021, 20.8080198],
                [106.4990961, 20.8083797],
                [106.5003402, 20.8084869],
                [106.5009096, 20.8084583],
                [106.5018747, 20.8084303],
                [106.5027028, 20.8081809],
                [106.5032794, 20.807751],
                [106.5035314, 20.8071421],
                [106.5036509, 20.8061701],
                [106.503897, 20.8049543],
                [106.504149, 20.8043454],
                [106.5048516, 20.8036111],
                [106.5060052, 20.802812],
                [106.5076184, 20.8017593],
                [106.5087971, 20.8009434],
                [106.5092948, 20.8024178],
                [106.5101461, 20.8036842],
                [106.5116517, 20.805855],
                [106.5128968, 20.8077854],
                [106.5137506, 20.8092945],
                [106.5146738, 20.8112883],
                [106.5152715, 20.8129817],
                [106.5156779, 20.8149194],
                [106.5158855, 20.8163129],
                [106.516029, 20.8177676],
                [106.5158481, 20.8190432],
                [106.5154105, 20.8204424],
                [106.5143904, 20.8216648],
                [106.5140942, 20.8217933],
                [106.5131054, 20.8222221],
                [106.5114309, 20.8225402],
                [106.5094308, 20.8225579],
                [106.507234, 20.8222739],
                [106.5042596, 20.8216326],
                [106.5018749, 20.8211076],
                [106.5008166, 20.8207574],
                [106.4986424, 20.8202109],
                [106.4953576, 20.8196273],
                [106.492799, 20.8193749],
                [106.4925671, 20.8194241],
                [106.4914845, 20.8195983],
                [106.4901998, 20.820245],
                [106.4894302, 20.820746],
                [106.4888154, 20.8212457],
                [106.4883821, 20.8218144],
                [106.4881811, 20.8223811],
                [106.4881854, 20.8228284],
                [106.4883466, 20.823428],
                [106.4888714, 20.8242948],
                [106.490755, 20.8268219],
                [106.4935186, 20.8295768],
                [106.4947232, 20.8313091],
                [106.4954127, 20.8331559],
                [106.4965687, 20.8363056],
                [106.4969204, 20.8376267],
                [106.4970903, 20.8390644],
                [106.4970495, 20.839678],
                [106.4967462, 20.8408062],
                [106.4963446, 20.8417808],
                [106.4954719, 20.8428256],
                [106.4942005, 20.8439181],
                [106.4930707, 20.8446562],
                [106.4907368, 20.8459566],
                [106.4891117, 20.8467653],
                [106.4888543, 20.8469237],
                [106.4862741, 20.8485114],
                [106.4849778, 20.8495378],
                [106.4835762, 20.8509403],
                [106.4823811, 20.8524293],
                [106.4814255, 20.8536735],
                [106.4798606, 20.855645],
                [106.479119, 20.8565625],
                [106.4773606, 20.858608],
                [106.4818292, 20.8619863],
                [106.4817442, 20.8623575],
                [106.4817369, 20.8628659],
                [106.4818438, 20.8632836],
                [106.4821037, 20.8636388],
                [106.4826114, 20.8640758],
                [106.4832236, 20.8644185],
                [106.4828993, 20.8653408],
                [106.481337, 20.8674998],
                [106.4812188, 20.8679948],
                [106.481588, 20.8680403],
                [106.4819256, 20.868275],
                [106.4823095, 20.8685384],
                [106.482689, 20.8688251],
                [106.4829556, 20.8691702],
                [106.4831571, 20.8693882],
                [106.4833479, 20.8694778],
                [106.4834963, 20.869467],
                [106.4836706, 20.8693445],
                [106.4838006, 20.8690968],
                [106.4838036, 20.8687978],
                [106.4838044, 20.8684742],
                [106.4839658, 20.8682619],
                [106.4842222, 20.8681143],
                [106.4844018, 20.8679351],
                [106.4844956, 20.8677094],
                [106.4844783, 20.8673439],
                [106.4845138, 20.8670919],
                [106.484587, 20.866903],
                [106.4847245, 20.8667781],
                [106.4849619, 20.8667044],
                [106.4852844, 20.866752],
                [106.4856299, 20.8668956],
                [106.485883, 20.8669607],
                [106.4861707, 20.8669983],
                [106.486375, 20.867009],
                [106.4865621, 20.8669691],
                [106.4867291, 20.8668961],
                [106.4868746, 20.8667529],
                [106.4870187, 20.8665075],
                [106.4872035, 20.8661134],
                [106.4898713, 20.8670805],
                [106.4919812, 20.867688],
                [106.4931887, 20.8675374],
                [106.4941239, 20.8669714],
                [106.4943514, 20.8665413],
                [106.4951217, 20.8652223],
                [106.4960428, 20.8637216],
                [106.4967512, 20.8625284],
                [106.4974223, 20.861181],
                [106.4979456, 20.8600336],
                [106.4982295, 20.8591931],
                [106.4983566, 20.8585215],
                [106.4984199, 20.8581706],
                [106.4984775, 20.8572406],
                [106.4985032, 20.8556099],
                [106.4984106, 20.8546965],
                [106.4984455, 20.8540105],
                [106.4985095, 20.8537205],
                [106.4986402, 20.8534298],
                [106.4989549, 20.8531528],
                [106.4992042, 20.8530287],
                [106.4996706, 20.8529027],
                [106.4999711, 20.8528848],
                [106.5003386, 20.8528816],
                [106.5007405, 20.8529848],
                [106.5011938, 20.8532094],
                [106.5018753, 20.8538],
                [106.502227, 20.8541958],
                [106.5024848, 20.8546048],
                [106.5026869, 20.8550916],
                [106.5028614, 20.8556299],
                [106.5029597, 20.8563104],
                [106.5030976, 20.8574274],
                [106.5032555, 20.8584288],
                [106.5034342, 20.8593914],
                [106.5036682, 20.8602377],
                [106.5039353, 20.8608781],
                [106.5045221, 20.8617856],
                [106.5052621, 20.862499],
                [106.5059011, 20.8629818],
                [106.5065528, 20.8633233],
                [106.5074084, 20.8636243],
                [106.5084248, 20.8637824],
                [106.5092851, 20.8638518],
                [106.5101016, 20.8637547],
                [106.5107559, 20.8636461],
                [106.5115148, 20.8634466],
                [106.5121606, 20.8631838],
                [106.5129602, 20.8628167],
                [106.5139782, 20.862435],
                [106.5150744, 20.8621296],
                [106.5159181, 20.8619422],
                [106.5167914, 20.8618958],
                [106.5177992, 20.8619126],
                [106.5188993, 20.8619928],
                [106.5201002, 20.8622907],
                [106.5208641, 20.8626162],
                [106.521475, 20.8627912],
                [106.522708, 20.8629551],
                [106.5241964, 20.8629524],
                [106.5251059, 20.8620889],
                [106.5252179, 20.8610175],
                [106.5243601, 20.8591676],
                [106.5238547, 20.8572568],
                [106.5232473, 20.8550254],
                [106.5230652, 20.8537287],
                [106.5230501, 20.8529319],
                [106.5231574, 20.852391],
                [106.5237642, 20.8513875],
                [106.5240213, 20.8511311],
                [106.5243691, 20.8509538],
                [106.5249017, 20.850944],
                [106.5254545, 20.8509409],
                [106.5269704, 20.8510972],
                [106.5273387, 20.8512027],
                [106.5277709, 20.8513374],
                [106.5282426, 20.8514844],
                [106.5288679, 20.8517525],
                [106.5298081, 20.852306],
                [106.5304511, 20.8527731],
                [106.5311017, 20.8534128],
                [106.5316799, 20.8541532],
                [106.5328771, 20.8561735],
                [106.5336392, 20.8576707],
                [106.5339575, 20.8584905],
                [106.5341416, 20.859273],
                [106.5342189, 20.8599408],
                [106.5342614, 20.8606602],
                [106.5342827, 20.8615725],
                [106.5343244, 20.8627035],
                [106.5343528, 20.8634232],
                [106.534446, 20.8642707],
                [106.5345364, 20.8648355],
                [106.5347179, 20.8653738],
                [106.5350636, 20.8661034],
                [106.535491, 20.8666033],
                [106.5360311, 20.8669946],
                [106.5368879, 20.867411],
                [106.5383735, 20.8679762],
                [106.5404721, 20.8685357],
                [106.5417169, 20.8687893],
                [106.5426603, 20.8688889],
                [106.5443302, 20.8688737],
                [106.5452884, 20.8688522],
                [106.5460523, 20.868792],
                [106.5467192, 20.8686425],
                [106.5477779, 20.8690482],
                [106.5487143, 20.8696567],
                [106.5489982, 20.8698599],
                [106.5492823, 20.870063],
                [106.5495245, 20.8703179],
                [106.5497257, 20.8707016],
                [106.5498492, 20.8710605],
                [106.5500972, 20.871881],
                [106.5502303, 20.8724968],
                [106.5503601, 20.8727784],
                [106.5505594, 20.8729694],
                [106.5509134, 20.8731333],
                [106.55121, 20.8731949],
                [106.5514643, 20.8732569],
                [106.5517332, 20.8733572],
                [106.5519107, 20.8734819],
                [106.5520266, 20.8737892],
                [106.5520756, 20.8744567],
                [106.5521534, 20.8751624],
                [106.552273, 20.8758293],
                [106.5524304, 20.8767087],
                [106.5519436, 20.8768942],
                [106.5513645, 20.8772385],
                [106.550413, 20.8778897],
                [106.5495729, 20.8784114],
                [106.5489766, 20.8786739],
                [106.5482457, 20.8788476],
                [106.5476401, 20.8788916],
                [106.5466663, 20.8787462],
                [106.5453315, 20.878437],
                [106.54481, 20.8784288],
                [106.5445573, 20.8785211],
                [106.5442919, 20.878742],
                [106.5441822, 20.8790514],
                [106.5441846, 20.8792827],
                [106.5442435, 20.8795264],
                [106.5443729, 20.8797822],
                [106.544874, 20.8803109],
                [106.545542, 20.8809864],
                [106.5458343, 20.8812459],
                [106.5467315, 20.882357],
                [106.5469673, 20.8826762],
                [106.5475917, 20.8830947],
                [106.5479043, 20.8833489],
                [106.5480484, 20.883656],
                [106.5480934, 20.8839255],
                [106.5480823, 20.8842212],
                [106.547994, 20.8845432],
                [106.5477296, 20.8848669],
                [106.5471008, 20.8853867],
                [106.5466525, 20.8856478],
                [106.5462033, 20.8858189],
                [106.5454858, 20.8859411],
                [106.5446904, 20.8860254],
                [106.5439856, 20.8860061],
                [106.5434064, 20.885857],
                [106.5429105, 20.8856046],
                [106.5424987, 20.8852998],
                [106.5420452, 20.8850468],
                [106.5417907, 20.884972],
                [106.5415652, 20.8849612],
                [106.5412779, 20.885118],
                [106.5406495, 20.8856892],
                [106.5398126, 20.886532],
                [106.5393813, 20.8870757],
                [106.5391243, 20.8874379],
                [106.5390993, 20.8877594],
                [106.5392154, 20.8880796],
                [106.5394718, 20.8883473],
                [106.5398265, 20.8885883],
                [106.540181, 20.8888036],
                [106.5403243, 20.8890337],
                [106.5403687, 20.8892387],
                [106.5403653, 20.8895987],
                [106.5402992, 20.8900234],
                [106.5402467, 20.8904094],
                [106.5402366, 20.8907951],
                [106.5404786, 20.8910371],
                [106.5408038, 20.891137],
                [106.5411425, 20.8911853],
                [106.5419739, 20.8911778],
                [106.5423409, 20.8912388],
                [106.542723, 20.8913896],
                [106.5431408, 20.8915915],
                [106.543554, 20.8920375],
                [106.5437421, 20.8925113],
                [106.543746, 20.8928969],
                [106.5435524, 20.8932585],
                [106.5431892, 20.8935702],
                [106.5427963, 20.893728],
                [106.5423528, 20.8937834],
                [106.541874, 20.8938134],
                [106.5413106, 20.8938442],
                [106.5408603, 20.8939125],
                [106.5405514, 20.8940181],
                [106.540285, 20.8941619],
                [106.5400619, 20.8943953],
                [106.5399456, 20.8947305],
                [106.5400351, 20.895218],
                [106.5403793, 20.8958061],
                [106.5408071, 20.8962906],
                [106.5411211, 20.8966733],
                [106.5412373, 20.8970064],
                [106.5412543, 20.8973019],
                [106.5411378, 20.8976242],
                [106.5408716, 20.8977808],
                [106.5404776, 20.8978358],
                [106.5400547, 20.8978267],
                [106.5394901, 20.8977419],
                [106.5390105, 20.8977076],
                [106.5385878, 20.8977243],
                [106.5382369, 20.8978559],
                [106.5380276, 20.8980635],
                [106.5378753, 20.8983347],
                [106.5378497, 20.8985921],
                [106.5379089, 20.8988614],
                [106.5380519, 20.8990658],
                [106.5383141, 20.8992047],
                [106.5388509, 20.8993284],
                [106.5392753, 20.8994789],
                [106.5396717, 20.899668],
                [106.5399138, 20.8999101],
                [106.5399869, 20.9001536],
                [106.5399184, 20.9003598],
                [106.5397375, 20.9005799],
                [106.5393732, 20.9007888],
                [106.5387265, 20.9009489],
                [106.5380227, 20.9010452],
                [106.5370441, 20.9011183],
                [106.5362133, 20.9012028],
                [106.5356649, 20.9013234],
                [106.5352031, 20.9016488],
                [106.5348818, 20.9019345],
                [106.5345178, 20.9021691],
                [106.5341248, 20.9023269],
                [106.5338301, 20.902458],
                [106.5335643, 20.9026532],
                [106.5334045, 20.9028731],
                [106.5333796, 20.9032075],
                [106.5334402, 20.9036182],
                [106.5335854, 20.9040282],
                [106.5337445, 20.904438],
                [106.5338402, 20.9048355],
                [106.5337444, 20.9051192],
                [106.5335212, 20.9053525],
                [106.5332689, 20.9054833],
                [106.5329593, 20.9055246],
                [106.5326487, 20.905476],
                [106.5323511, 20.9053116],
                [106.5320246, 20.9050832],
                [106.5317116, 20.9048032],
                [106.5314844, 20.9046254],
                [106.5312018, 20.9045636],
                [106.5309485, 20.9046045],
                [106.5307668, 20.9047474],
                [106.5306624, 20.904877],
                [106.5306784, 20.9050696],
                [106.5309226, 20.9055172],
                [106.5312655, 20.9059768],
                [106.5313535, 20.9063101],
                [106.5313141, 20.9066061],
                [106.5311752, 20.9068001],
                [106.5309715, 20.9068662],
                [106.5303932, 20.9068328],
                [106.5298009, 20.9067867],
                [106.5291944, 20.9067535],
                [106.5286452, 20.9067971],
                [106.5280542, 20.9068923],
                [106.5273593, 20.9071684],
                [106.5269677, 20.9074675],
                [106.5268159, 20.9077773],
                [106.5268329, 20.9080727],
                [106.526962, 20.908303],
                [106.5274434, 20.9085171],
                [106.5277404, 20.9086174],
                [106.527996, 20.9087949],
                [106.5281041, 20.9090381],
                [106.5281074, 20.9093594],
                [106.5280259, 20.9096685],
                [106.5278195, 20.9101589],
                [106.5277242, 20.9104939],
                [106.5277414, 20.9108021],
                [106.5278232, 20.9112255],
                [106.5279282, 20.9118414],
                [106.5279193, 20.9123556],
                [106.5278677, 20.9128316],
                [106.5277251, 20.9133598],
                [106.5272558, 20.9143407],
                [106.5270779, 20.9148693],
                [106.5270803, 20.9151134],
                [106.5271953, 20.9153309],
                [106.5273376, 20.9154582],
                [106.5276492, 20.9156096],
                [106.5280024, 20.9156835],
                [106.5283834, 20.9157315],
                [106.5286653, 20.915729],
                [106.5290029, 20.9156617],
                [106.5293537, 20.9155044],
                [106.5296825, 20.91527],
                [106.5299866, 20.9146762],
                [106.5306113, 20.9130383],
                [106.5310287, 20.9125077],
                [106.5315181, 20.9121048],
                [106.5320089, 20.9118691],
                [106.5324308, 20.9117754],
                [106.532769, 20.9117724],
                [106.5331361, 20.9118334],
                [106.5334333, 20.9119463],
                [106.5337031, 20.9121495],
                [106.5339738, 20.9124299],
                [106.5341038, 20.9127371],
                [106.5341435, 20.9131737],
                [106.534168, 20.9142016],
                [106.5341627, 20.9150628],
                [106.5342381, 20.9155505],
                [106.5343614, 20.9158964],
                [106.5346483, 20.9163821],
                [106.5351757, 20.9169558],
                [106.5355321, 20.917351],
                [106.535901, 20.9175918],
                [106.5363261, 20.9178065],
                [106.5366651, 20.9178806],
                [106.5371655, 20.9178761],
                [106.5378265, 20.9177415],
                [106.5383618, 20.9176982],
                [106.5389689, 20.9177956],
                [106.5396191, 20.9179825],
                [106.5401706, 20.9181574],
                [106.5406102, 20.9184105],
                [106.5408384, 20.9186655],
                [106.5408747, 20.9187645],
                [106.5408487, 20.9190397],
                [106.5406354, 20.9198048],
                [106.5406189, 20.9203479],
                [106.5406967, 20.9206716],
                [106.5408403, 20.9209274],
                [106.5410045, 20.9211315],
                [106.5415157, 20.9214997],
                [106.5422518, 20.9218014],
                [106.5432406, 20.9219982],
                [106.5438465, 20.9219927],
                [106.5442824, 20.921873],
                [106.54464, 20.9217027],
                [106.5450306, 20.9213008],
                [106.545076, 20.9212445],
                [106.5451443, 20.9211597],
                [106.5453646, 20.9208865],
                [106.5456127, 20.9203317],
                [106.5459177, 20.9198405],
                [106.5462388, 20.9195291],
                [106.546786, 20.9192928],
                [106.5470388, 20.9192006],
                [106.5473207, 20.919198],
                [106.5475465, 20.9192216],
                [106.5478434, 20.919309],
                [106.5481628, 20.9195374],
                [106.548462, 20.9198431],
                [106.5486208, 20.9202016],
                [106.5486531, 20.9206125],
                [106.5486585, 20.9211265],
                [106.5485927, 20.9215898],
                [106.548393, 20.9220414],
                [106.5479883, 20.9224435],
                [106.5475259, 20.9227047],
                [106.5469637, 20.9228641],
                [106.5463864, 20.9229336],
                [106.5454901, 20.9228131],
                [106.5447556, 20.9226527],
                [106.5441616, 20.9224782],
                [106.543753, 20.9224818],
                [106.5432745, 20.9225633],
                [106.5428816, 20.9227339],
                [106.5425317, 20.9229813],
                [106.5423516, 20.9232784],
                [106.5422907, 20.923536],
                [106.5423229, 20.9239213],
                [106.5423685, 20.9242551],
                [106.5425137, 20.924665],
                [106.5427433, 20.9250614],
                [106.5430796, 20.9255595],
                [106.5440083, 20.9267721],
                [106.5441519, 20.9270278],
                [106.5441826, 20.9272717],
                [106.5441349, 20.9274392],
                [106.5439265, 20.9277239],
                [106.5433672, 20.9281659],
                [106.5427942, 20.9286594],
                [106.5424044, 20.9291257],
                [106.542092, 20.929604],
                [106.5418856, 20.9300942],
                [106.5418063, 20.930609],
                [106.5418254, 20.9310971],
                [106.5418863, 20.9315336],
                [106.5419681, 20.931957],
                [106.5421868, 20.9326618],
                [106.5424603, 20.9332121],
                [106.5430916, 20.9342987],
                [106.5433376, 20.9349134],
                [106.5434478, 20.935359],
                [106.5434103, 20.9358217],
                [106.5432741, 20.9362853],
                [106.5431098, 20.9367619],
                [106.5429452, 20.9372001],
                [106.5429652, 20.9377777],
                [106.5430271, 20.9383166],
                [106.5432558, 20.9399969],
                [106.5435018, 20.941998],
                [106.5434797, 20.9425891],
                [106.5433073, 20.9429252],
                [106.5426372, 20.943548],
                [106.5439865, 20.9452575],
                [106.5462113, 20.9477687],
                [106.5468542, 20.9472361],
                [106.5472322, 20.9470014],
                [106.5476394, 20.9468435],
                [106.5481249, 20.946762],
                [106.5486323, 20.9467574],
                [106.5490979, 20.9468045],
                [106.5497054, 20.9469403],
                [106.551439, 20.9476185],
                [106.5527141, 20.9482493],
                [106.5534351, 20.9484483],
                [106.553943, 20.9484951],
                [106.554464, 20.9484518],
                [106.5550402, 20.9482795],
                [106.5554039, 20.9480192],
                [106.5556538, 20.9476443],
                [106.5557818, 20.9470777],
                [106.5558864, 20.9462801],
                [106.5559936, 20.9450714],
                [106.5561382, 20.9440549],
                [106.5561707, 20.9434972],
                [106.5562301, 20.9425125],
                [106.5562667, 20.9417151],
                [106.5561924, 20.9407097],
                [106.556081, 20.9393921],
                [106.5559327, 20.9382826],
                [106.5555854, 20.9362784],
                [106.5555381, 20.9350788],
                [106.5556475, 20.9340756],
                [106.5558508, 20.9332899],
                [106.5561828, 20.932683],
                [106.5565305, 20.932243],
                [106.556908, 20.9319439],
                [106.5569852, 20.9319217],
                [106.5572448, 20.9318123],
                [106.5578574, 20.9317554],
                [106.5584635, 20.9317627],
                [106.5591545, 20.9318078],
                [106.5592205, 20.9318233],
                [106.559858, 20.9318716],
                [106.5605133, 20.9320269],
                [106.561197, 20.9322673],
                [106.56202, 20.9326983],
                [106.5634307, 20.9334399],
                [106.5641359, 20.9338007],
                [106.5647773, 20.934282],
                [106.5654686, 20.9348884],
                [106.5663912, 20.9357722],
                [106.5668616, 20.9364133],
                [106.5672252, 20.9369343],
                [106.5676315, 20.9375355],
                [106.5680378, 20.9382771],
                [106.5683803, 20.9396399],
                [106.5685733, 20.9407222],
                [106.5686809, 20.9417899],
                [106.5687025, 20.9425778],
                [106.5686753, 20.9433362],
                [106.5685869, 20.9443392],
                [106.5684355, 20.9453685],
                [106.5681955, 20.9466814],
                [106.567935, 20.9476974],
                [106.567796, 20.9482398],
                [106.567621, 20.949038],
                [106.5674461, 20.9498363],
                [106.5674302, 20.9503376],
                [106.5676187, 20.9508498],
                [106.5680325, 20.9513215],
                [106.5686279, 20.9516501],
                [106.5693901, 20.9517587],
                [106.5703626, 20.9517369],
                [106.5715161, 20.9515206],
                [106.5724503, 20.9512165],
                [106.5733057, 20.9507975],
                [106.5748758, 20.9498557],
                [106.5755304, 20.9493198],
                [106.5764461, 20.9485291],
                [106.5769186, 20.9481011],
                [106.577739, 20.9473179],
                [106.5785532, 20.9460771],
                [106.5787864, 20.9447545],
                [106.5787098, 20.9435236],
                [106.5784729, 20.9426686],
                [106.5779492, 20.9418771],
                [106.5768006, 20.9406742],
                [106.5753989, 20.9396456],
                [106.5738921, 20.9389244],
                [106.5732076, 20.9385661],
                [106.5725535, 20.9381605],
                [106.5720502, 20.9378021],
                [106.5715066, 20.9373966],
                [106.5710235, 20.9368588],
                [106.5706057, 20.9362939],
                [106.5704143, 20.9356896],
                [106.5703234, 20.9351135],
                [106.5704788, 20.934188],
                [106.57071, 20.9336686],
                [106.5709211, 20.933253],
                [106.5714691, 20.9324785],
                [106.5718915, 20.9320911],
                [106.5724749, 20.9316942],
                [106.5738982, 20.9310326],
                [106.5745219, 20.9308435],
                [106.5753972, 20.9306636],
                [106.5767502, 20.930512],
                [106.5775351, 20.9306154],
                [106.57833, 20.9307756],
                [106.5794722, 20.9312285],
                [106.5800962, 20.931653],
                [106.5806909, 20.9321497],
                [106.5813522, 20.9327699],
                [106.582087, 20.9334588],
                [106.5826015, 20.9342169],
                [106.5832629, 20.9351818],
                [106.5836305, 20.935871],
                [106.584292, 20.9369047],
                [106.5848804, 20.9382142],
                [106.5855788, 20.939408],
                [106.5865029, 20.941257],
                [106.5871033, 20.9422289],
                [106.5879039, 20.9433134],
                [106.5886172, 20.9442707],
                [106.5895129, 20.9452383],
                [106.5903043, 20.9457874],
                [106.5916926, 20.9463702],
                [106.5918868, 20.9484421],
                [106.592091, 20.9494018],
                [106.592473, 20.9508228],
                [106.5926396, 20.951717],
                [106.5926396, 20.9524913],
                [106.592416, 20.9533395],
                [106.5920201, 20.9541659],
                [106.5914647, 20.954925],
                [106.5908324, 20.9556506],
                [106.5898777, 20.9564938],
                [106.5889527, 20.9570938],
                [106.5874682, 20.958118],
                [106.5868866, 20.9587076],
                [106.5862828, 20.9594939],
                [106.5857179, 20.9604146],
                [106.5852249, 20.9615163],
                [106.5850199, 20.9624319],
                [106.584908, 20.9634402],
                [106.5849174, 20.9649579],
                [106.5851423, 20.9666998],
                [106.5853694, 20.9698446],
                [106.585491, 20.9718679],
                [106.585477, 20.9726768],
                [106.5853466, 20.9732248],
                [106.5850112, 20.9737293],
                [106.5844988, 20.9741947],
                [106.5838514, 20.9745774],
                [106.5827568, 20.9749123],
                [106.5767937, 20.976092],
                [106.574884, 20.9765791],
                [106.5718658, 20.9773967],
                [106.5696953, 20.9779969],
                [106.5686985, 20.9783187],
                [106.5677017, 20.9788667],
                [106.5667888, 20.9795147],
                [106.5658974, 20.9801882],
                [106.5653743, 20.9808202],
                [106.5646723, 20.9818603],
                [106.5641808, 20.9827636],
                [106.5637921, 20.9836692],
                [106.5633243, 20.9851082],
                [106.5630585, 20.9857081],
                [106.5626874, 20.9864114],
                [106.5622092, 20.9870547],
                [106.5615801, 20.9875867],
                [106.5586348, 20.9896381],
                [106.5573885, 20.9904759],
                [106.5567515, 20.9911637],
                [106.5559913, 20.9924868],
                [106.5552387, 20.9944135],
                [106.5548829, 20.9955155],
                [106.5546739, 20.996785],
                [106.5546182, 20.9976248],
                [106.5546689, 20.9987737],
                [106.5547858, 20.9993737],
                [106.5552114, 21.0010773],
                [106.5557267, 21.002707],
                [106.5565185, 21.0036201],
                [106.5568306, 21.0041071],
                [106.557343, 21.0058377],
                [106.5576592, 21.006576],
                [106.5579293, 21.0070526],
                [106.558453, 21.0075599],
                [106.5591644, 21.0081349],
                [106.5610614, 21.0091987],
                [106.5618255, 21.0096291],
                [106.5624678, 21.0100934],
                [106.5629322, 21.0106407],
                [106.5633044, 21.0112248],
                [106.5635777, 21.0118705],
                [106.5637523, 21.0126699],
                [106.5638511, 21.0132878],
                [106.5640355, 21.0151325],
                [106.5640895, 21.0166903],
                [106.5642012, 21.0176425],
                [106.5644713, 21.0185166],
                [106.5648341, 21.0190259],
                [106.5652274, 21.0192456],
                [106.5656705, 21.0193283],
                [106.5662023, 21.019274],
                [106.5665761, 21.0190853],
                [106.566903, 21.0187415],
                [106.5674424, 21.017842],
                [106.5679098, 21.0168096],
                [106.5687383, 21.0138545],
                [106.5688931, 21.0133897],
                [106.5691929, 21.0128641],
                [106.569817, 21.012125],
                [106.5704785, 21.011638],
                [106.5712008, 21.0112535],
                [106.5721579, 21.0109624],
                [106.57307, 21.0108174],
                [106.5735451, 21.0107956],
                [106.5741064, 21.0108826],
                [106.5746723, 21.0110891],
                [106.5751218, 21.0113957],
                [106.575562, 21.0117914],
                [106.5759136, 21.0122631],
                [106.5771148, 21.0144332],
                [106.5774046, 21.0149158],
                [106.5777924, 21.0154679],
                [106.5784073, 21.016148],
                [106.5789752, 21.0164953],
                [106.5796528, 21.0166598],
                [106.5804753, 21.0166964],
                [106.5814506, 21.0166013],
                [106.5844108, 21.0160187],
                [106.5882466, 21.014911],
                [106.5888971, 21.0147716],
                [106.5895031, 21.0147194],
                [106.5900993, 21.0147624],
                [106.5907053, 21.0149069],
                [106.5912454, 21.0152389],
                [106.5918154, 21.0158696],
                [106.5922527, 21.0164871],
                [106.5925661, 21.0168344],
                [106.5930517, 21.0171488],
                [106.594356, 21.0177631],
                [106.5949788, 21.0178983],
                [106.5956407, 21.0179166],
                [106.5963065, 21.0178216],
                [106.5995848, 21.0167832],
                [106.6009038, 21.0165409],
                [106.6018375, 21.0165073],
                [106.6027345, 21.0166536],
                [106.6038047, 21.0169489],
                [106.6062708, 21.0180551],
                [106.6080308, 21.0187972],
                [106.6095597, 21.0196804],
                [106.6102613, 21.0199786],
                [106.6109628, 21.0201969],
                [106.6115432, 21.0202876],
                [106.6105654, 21.0217856],
                [106.6103325, 21.0221473],
                [106.6101714, 21.0224141],
                [106.6099201, 21.0227589],
                [106.6096499, 21.0230354],
                [106.6094164, 21.0233457],
                [106.6091654, 21.0237075],
                [106.6088628, 21.024378],
                [106.6085893, 21.0252022],
                [106.608359, 21.0258206],
                [106.6082737, 21.0263521],
                [106.6082346, 21.0269516],
                [106.6082589, 21.0275162],
                [106.6083229, 21.0283887],
                [106.6083311, 21.0291589],
                [106.6082826, 21.0297241],
                [106.608135, 21.0303931],
                [106.607851, 21.0310976],
                [106.6074937, 21.0317516],
                [106.6071805, 21.0322681],
                [106.6069828, 21.0325267],
                [106.6064809, 21.0332846],
                [106.6059851, 21.0339414],
                [106.6052957, 21.0348187],
                [106.604502, 21.0357933],
                [106.6038756, 21.036456],
                [106.6032078, 21.0370012],
                [106.6024985, 21.037527],
                [106.6017753, 21.0378651],
                [106.6013051, 21.0381949],
                [106.6006897, 21.0385773],
                [106.6000192, 21.0389088],
                [106.5994302, 21.0391882],
                [106.5982703, 21.039764],
                [106.5973101, 21.0402524],
                [106.596409, 21.0404922],
                [106.595873, 21.0408706],
                [106.59532, 21.0415548],
                [106.594797, 21.0420562],
                [106.5941934, 21.0426952],
                [106.5937084, 21.0433331],
                [106.5931866, 21.0439543],
                [106.5926999, 21.0444382],
                [106.5920947, 21.0449231],
                [106.5907579, 21.0459969],
                [106.5896379, 21.0468976],
                [106.5886075, 21.0476603],
                [106.5874865, 21.0484582],
                [106.5864739, 21.0491866],
                [106.5855344, 21.0499143],
                [106.5844124, 21.0506438],
                [106.5832901, 21.0513389],
                [106.582314, 21.052067],
                [106.5811332, 21.0531008],
                [106.5802407, 21.0539648],
                [106.5796814, 21.0544662],
                [106.5789959, 21.0551058],
                [106.5783113, 21.0558309],
                [106.5777066, 21.0563499],
                [106.5769828, 21.0568016],
                [106.5763672, 21.0571496],
                [106.5760773, 21.0573064],
                [106.575787, 21.0574116],
                [106.5754593, 21.0574318],
                [106.5749855, 21.057402],
                [106.5742185, 21.0572209],
                [106.5733139, 21.0569383],
                [106.5728747, 21.0567541],
                [106.5724531, 21.0565014],
                [106.5717, 21.0559093],
                [106.5711201, 21.0553157],
                [106.5706221, 21.0547214],
                [106.5701248, 21.0542126],
                [106.5694813, 21.0536366],
                [106.5690229, 21.05335],
                [106.5685476, 21.0531832],
                [106.5680369, 21.0531195],
                [106.5676001, 21.0531577],
                [106.5671452, 21.0531962],
                [106.5665408, 21.053313],
                [106.5662417, 21.0534527],
                [106.5657806, 21.053782],
                [106.5651162, 21.0542588],
                [106.5646285, 21.0546569],
                [106.5641956, 21.0550545],
                [106.5637077, 21.0554354],
                [106.5633093, 21.0556445],
                [106.5628918, 21.0557852],
                [106.5622564, 21.0559965],
                [106.5616657, 21.0561216],
                [106.5611023, 21.0562467],
                [106.560647, 21.0562509],
                [106.5601369, 21.0562384],
                [106.5598085, 21.0561901],
                [106.559387, 21.0559545],
                [106.5587079, 21.0554643],
                [106.5579273, 21.0548383],
                [106.5571192, 21.0541954],
                [106.5564582, 21.053688],
                [106.5559814, 21.0533843],
                [106.5554145, 21.0531671],
                [106.5545472, 21.0529696],
                [106.5539451, 21.0528725],
                [106.5534529, 21.0528427],
                [106.5527431, 21.0528835],
                [106.5519435, 21.0530619],
                [106.5508627, 21.0533627],
                [106.5496835, 21.0538185],
                [106.5486489, 21.0541701],
                [106.5478858, 21.0543654],
                [106.5473134, 21.0544903],
                [106.5468409, 21.0545974],
                [106.5464587, 21.054635],
                [106.5460763, 21.0546385],
                [106.5456025, 21.0546257],
                [106.5447273, 21.054531],
                [106.5436684, 21.0542839],
                [106.542791, 21.054001],
                [106.5416109, 21.0534982],
                [106.5406423, 21.0531819],
                [106.539692, 21.0528824],
                [106.5389935, 21.0527091],
                [106.5385283, 21.0526363],
                [106.5381723, 21.0525711],
                [106.537971, 21.0524702],
                [106.5377509, 21.0523182],
                [106.537585, 21.0521315],
                [106.5375386, 21.0520463],
                [106.5374871, 21.0519039],
                [106.5373367, 21.0514437],
                [106.5368032, 21.050677],
                [106.5343197, 21.048641],
                [106.5335307, 21.0479842],
                [106.5331126, 21.0478084],
                [106.5326943, 21.0476763],
                [106.5320429, 21.0476309],
                [106.531065, 21.0478902],
                [106.5293421, 21.0483218],
                [106.5286438, 21.0484507],
                [106.5278989, 21.0485797],
                [106.5275728, 21.048666],
                [106.5271535, 21.048883],
                [106.526733, 21.0494056],
                [106.5264991, 21.0497978],
                [106.5259855, 21.0503201],
                [106.5257058, 21.0505374],
                [106.5254264, 21.0505804],
                [106.5249145, 21.050579],
                [106.5243339, 21.0502719],
                [106.5238236, 21.0497903],
                [106.5236853, 21.0493971],
                [106.5235941, 21.0487858],
                [106.5235497, 21.048131],
                [106.5235507, 21.0478255],
                [106.5237387, 21.0472151],
                [106.52374, 21.0468222],
                [106.5236484, 21.0463856],
                [106.5234165, 21.046123],
                [106.5231841, 21.0460352],
                [106.5229052, 21.0459907],
                [106.5225319, 21.0462951],
                [106.5218309, 21.0472533],
                [106.5206653, 21.047992],
                [106.5199202, 21.0482082],
                [106.5195013, 21.0482506],
                [106.519129, 21.0482496],
                [106.5187571, 21.0481612],
                [106.518432, 21.0479857],
                [106.5175808, 21.0472111],
                [106.517133, 21.0468036],
                [106.5167615, 21.0465408],
                [106.5163898, 21.0463651],
                [106.5160178, 21.0463203],
                [106.5157387, 21.0463196],
                [106.5152726, 21.0465365],
                [106.5150391, 21.0468413],
                [106.5148979, 21.047321],
                [106.5148959, 21.047932],
                [106.5151258, 21.0487618],
                [106.5156345, 21.0497671],
                [106.5169306, 21.0518221],
                [106.5176726, 21.0526098],
                [106.5179976, 21.0528289],
                [106.5185086, 21.0531359],
                [106.518973, 21.0533991],
                [106.5192514, 21.0536181],
                [106.5192972, 21.0538364],
                [106.519157, 21.0540543],
                [106.5187376, 21.0542275],
                [106.5182256, 21.0542699],
                [106.5174343, 21.0543549],
                [106.5169686, 21.0544844],
                [106.5165021, 21.0548323],
                [106.5162683, 21.0552244],
                [106.5161272, 21.0556605],
                [106.516126, 21.0560097],
                [106.5165883, 21.0569711],
                [106.5171636, 21.0589804],
                [106.5172081, 21.0595478],
                [106.5169741, 21.0599836],
                [106.5166474, 21.0602882],
                [106.5161816, 21.0604178],
                [106.5158095, 21.0603731],
                [106.5152985, 21.0601097],
                [106.514695, 21.0596716],
                [106.5133028, 21.0585328],
                [106.5129778, 21.0583137],
                [106.5126057, 21.0582253],
                [106.5123267, 21.0581809],
                [106.5120472, 21.0582675],
                [106.5117204, 21.058572],
                [106.511068, 21.0588755],
                [106.5096708, 21.0592644],
                [106.5089727, 21.0592624],
                [106.5083218, 21.0591295],
                [106.5058124, 21.0581184],
                [106.5039778, 21.0570656],
                [106.5035387, 21.0568154],
                [106.502914, 21.0568419],
                [106.5017771, 21.0569986],
                [106.5001732, 21.0573271],
                [106.4992829, 21.0591916],
                [106.4987849, 21.0598643],
                [106.4978313, 21.0608754],
                [106.496261, 21.0621842],
                [106.4934795, 21.063984],
                [106.4885554, 21.0672438],
                [106.487757, 21.0677312],
                [106.4873215, 21.0679342],
                [106.4870719, 21.0680505],
                [106.4853088, 21.0684418],
                [106.4838655, 21.0686842],
                [106.4812064, 21.0689789],
                [106.4774229, 21.0692625],
                [106.4756341, 21.0693826],
                [106.4747317, 21.0693094],
                [106.4730521, 21.0688849],
                [106.4707946, 21.0680059],
                [106.4685376, 21.0671685],
                [106.4670637, 21.0666378],
                [106.4655931, 21.0664415],
                [106.4649487, 21.066259],
                [106.4623901, 21.0657595],
                [106.4599056, 21.0651331],
                [106.4585271, 21.0649569],
                [106.4568533, 21.0650966],
                [106.4560514, 21.065229],
                [106.4547703, 21.065637],
                [106.4550314, 21.0665335],
                [106.4550559, 21.0666246],
                [106.4552219, 21.0672424],
                [106.4552532, 21.0680991],
                [106.4550993, 21.0687901],
                [106.4548979, 21.0693144],
                [106.4546474, 21.0695256],
                [106.4541681, 21.0697804],
                [106.4534108, 21.0697869],
                [106.4517801, 21.0696337],
                [106.4505518, 21.0695402],
                [106.4494729, 21.0695076],
                [106.4487616, 21.0695137],
                [106.4482799, 21.0695388],
                [106.4478902, 21.0695838],
                [106.4476159, 21.0696906],
                [106.4473152, 21.0699643],
                [106.4470982, 21.0702867],
                [106.4468969, 21.0707158],
                [106.4468009, 21.0714112],
                [106.446853, 21.0722387],
                [106.4467763, 21.0734148],
                [106.446701, 21.0739789],
                [106.446588, 21.0744342],
                [106.4463291, 21.0749709],
                [106.4459227, 21.0754289],
                [106.4455952, 21.0757123],
                [106.4451429, 21.0759835],
                [106.4444847, 21.0762028],
                [106.4434458, 21.0765057],
                [106.4428458, 21.0766712],
                [106.4423779, 21.0768489],
                [106.4419622, 21.0771063],
                [106.4416123, 21.0773499],
                [106.4413072, 21.0776733],
                [106.4410176, 21.0780766],
                [106.4407298, 21.0786672],
                [106.4405018, 21.0793773],
                [106.440468, 21.0796717],
                [106.4405141, 21.0798851],
                [106.4407228, 21.0802174],
                [106.4419061, 21.0811829],
                [106.4428682, 21.0820435],
                [106.4436772, 21.0829855],
                [106.4439607, 21.0834776],
                [106.4441715, 21.0840371],
                [106.4444419, 21.085438],
                [106.4447072, 21.0870796],
                [106.4449591, 21.0880797],
                [106.4452597, 21.0888123],
                [106.4455438, 21.0893578],
                [106.4460561, 21.0899682],
                [106.4466046, 21.0905515],
                [106.4472699, 21.0910403],
                [106.4484805, 21.0917917],
                [106.4496984, 21.0925298],
                [106.4501721, 21.0929534],
                [106.4504986, 21.0933248],
                [106.4507099, 21.0939244],
                [106.4508119, 21.0946051],
                [106.4507888, 21.0952467],
                [106.4507046, 21.0956485],
                [106.4505752, 21.0959168],
                [106.4503292, 21.0962798],
                [106.4500017, 21.0965631],
                [106.4489805, 21.0972],
                [106.4467754, 21.0983281],
                [106.4452652, 21.0992364],
                [106.444522, 21.0997907],
                [106.443926, 21.1003838],
                [106.4433825, 21.1010968],
                [106.4420052, 21.1028593],
                [106.4405341, 21.1047828],
                [106.439679, 21.1058994],
                [106.4393682, 21.1063964],
                [106.4391952, 21.1067186],
                [106.439154, 21.1070264],
                [106.4391577, 21.1074005],
                [106.4391896, 21.1076676],
                [106.4394134, 21.1080399],
                [106.4398364, 21.1085308],
                [106.440902, 21.1094439],
                [106.4418638, 21.1102509],
                [106.4421984, 21.1106891],
                [106.442304, 21.1109955],
                [106.4423078, 21.1113964],
                [106.4422532, 21.1118112],
                [106.4417671, 21.1131518],
                [106.4416732, 21.1132995],
                [106.4415844, 21.1133972],
                [106.440976, 21.1140673],
                [106.4407456, 21.1145236],
                [106.4407253, 21.1147109],
                [106.4407897, 21.1152984],
                [106.4411842, 21.1166315],
                [106.4413819, 21.1173381],
                [106.4417851, 21.1173477],
                [106.4422091, 21.1171571],
                [106.4425443, 21.1169137],
                [106.4427782, 21.116805],
                [106.4430425, 21.1168027],
                [106.4433373, 21.1169204],
                [106.4435752, 21.1172389],
                [106.4437111, 21.1176251],
                [106.4438442, 21.1177175],
                [106.4439912, 21.1177429],
                [106.4442696, 21.1176872],
                [106.444648, 21.11735],
                [106.4452143, 21.116704],
                [106.4455641, 21.1164339],
                [106.4457106, 21.1164059],
                [106.4458723, 21.1164312],
                [106.4459907, 21.1165371],
                [106.446036, 21.1166569],
                [106.4460368, 21.1167371],
                [106.4459232, 21.1171388],
                [106.4457224, 21.1176213],
                [106.4456519, 21.1179292],
                [106.4456533, 21.1180761],
                [106.4456837, 21.1181693],
                [106.4457734, 21.1183423],
                [106.4458916, 21.1184214],
                [106.4459946, 21.1184472],
                [106.4461857, 21.1184723],
                [106.4465086, 21.1184563],
                [106.4469763, 21.1182385],
                [106.4476178, 21.1177788],
                [106.4479912, 21.1176842],
                [106.4485051, 21.1176799],
                [106.4491669, 21.1178079],
                [106.4498287, 21.1179226],
                [106.4501666, 21.1179464],
                [106.4505921, 21.117916],
                [106.4510019, 21.1177922],
                [106.4517219, 21.1173338],
                [106.4520699, 21.1171252],
                [106.4522613, 21.1170619],
                [106.4524307, 21.1170605],
                [106.4525443, 21.1171212],
                [106.4526015, 21.1171926],
                [106.452659, 21.1173052],
                [106.4526713, 21.1174078],
                [106.4526722, 21.1175003],
                [106.4526502, 21.1175622],
                [106.452561, 21.117676],
                [106.4524602, 21.1177591],
                [106.4520109, 21.1180199],
                [106.4518989, 21.1181236],
                [106.4518776, 21.1182574],
                [106.4519014, 21.1183702],
                [106.4520036, 21.118431],
                [106.4521732, 21.1184502],
                [106.4525233, 21.1184471],
                [106.4529185, 21.1184335],
                [106.4533924, 21.1183678],
                [106.4537196, 21.1183341],
                [106.4539568, 21.118332],
                [106.4542118, 21.1181935],
                [106.4545908, 21.117895],
                [106.4549689, 21.1175269],
                [106.4553118, 21.1174545],
                [106.4559606, 21.1174316],
                [106.4562905, 21.117521],
                [106.4565866, 21.1177723],
                [106.4568686, 21.1180905],
                [106.4570923, 21.118436],
                [106.4572001, 21.1189428],
                [106.457396, 21.1194621],
                [106.4575442, 21.1195944],
                [106.4577204, 21.119593],
                [106.4578221, 21.1194852],
                [106.4579526, 21.1193238],
                [106.458025, 21.1192162],
                [106.4581397, 21.1189347],
                [106.4581809, 21.1186404],
                [106.4582075, 21.1183595],
                [106.4582496, 21.1181588],
                [106.4583214, 21.1179845],
                [106.4584081, 21.11785],
                [106.4585097, 21.1177291],
                [106.4586551, 21.1175808],
                [106.4587716, 21.1174729],
                [106.4588732, 21.1173518],
                [106.4589301, 21.1171643],
                [106.4590277, 21.1166423],
                [106.4589634, 21.1160684],
                [106.4590278, 21.1158962],
                [106.4592464, 21.1157205],
                [106.4600513, 21.1154462],
                [106.4609445, 21.1151845],
                [106.4613111, 21.1151412],
                [106.461458, 21.11514],
                [106.4615317, 21.1151794],
                [106.4615764, 21.1152459],
                [106.4615769, 21.115286],
                [106.4615628, 21.1153529],
                [106.4614756, 21.1154473],
                [106.4613743, 21.1155952],
                [106.4612728, 21.1157298],
                [106.4612445, 21.115837],
                [106.4612307, 21.1159307],
                [106.4612609, 21.1160107],
                [106.4613492, 21.1160366],
                [106.461452, 21.1160358],
                [106.4617016, 21.1160336],
                [106.4619362, 21.1159915],
                [106.4622446, 21.1160021],
                [106.4625023, 21.1160802],
                [106.4628721, 21.1163444],
                [106.4631228, 21.1164625],
                [106.4633434, 21.1165008],
                [106.4635784, 21.1164988],
                [106.4639301, 21.1164288],
                [106.4643994, 21.1163713],
                [106.4646637, 21.116369],
                [106.4647964, 21.1164213],
                [106.4649004, 21.1165541],
                [106.4651847, 21.1170865],
                [106.4653718, 21.1174459],
                [106.4656239, 21.1176977],
                [106.4657269, 21.1177235],
                [106.4659325, 21.1177218],
                [106.4661379, 21.1177066],
                [106.4664805, 21.1176592],
                [106.4666951, 21.1176573],
                [106.4669329, 21.1177067],
                [106.4672275, 21.1177967],
                [106.4674768, 21.1178767],
                [106.4677707, 21.1178947],
                [106.4681774, 21.1178912],
                [106.4685157, 21.1178163],
                [106.4688088, 21.1177522],
                [106.4689104, 21.1177513],
                [106.4690802, 21.1177908],
                [106.4692851, 21.1179433],
                [106.4695471, 21.1181672],
                [106.4696722, 21.1182381],
                [106.4697514, 21.1182579],
                [106.4698622, 21.1182222],
                [106.469983, 21.1181263],
                [106.4701813, 21.1179664],
                [106.4703628, 21.117862],
                [106.4705019, 21.1178687],
                [106.4706849, 21.1179145],
                [106.4710165, 21.1180619],
                [106.4714801, 21.1183584],
                [106.4717854, 21.1184822],
                [106.4722045, 21.1186762],
                [106.4724494, 21.1188323],
                [106.4725768, 21.1189748],
                [106.4726653, 21.1191402],
                [106.4726754, 21.1192746],
                [106.4726878, 21.1196543],
                [106.4726732, 21.1199392],
                [106.4727183, 21.120105],
                [106.4728342, 21.1203682],
                [106.472927, 21.120604],
                [106.4730082, 21.1208192],
                [106.4730544, 21.1209215],
                [106.4731344, 21.1210134],
                [106.4732369, 21.1210948],
                [106.4733731, 21.1211553],
                [106.4736906, 21.1212657],
                [106.4738836, 21.1213565],
                [106.4739408, 21.1214279],
                [106.4739648, 21.1215716],
                [106.4739656, 21.121654],
                [106.4739323, 21.1217159],
                [106.4738764, 21.121778],
                [106.4737641, 21.1218407],
                [106.4736402, 21.1218829],
                [106.4732901, 21.1219065],
                [106.472816, 21.1219518],
                [106.4727261, 21.122004],
                [106.4726929, 21.1220762],
                [106.4726937, 21.1221584],
                [106.4727175, 21.1222817],
                [106.4728781, 21.1225269],
                [106.4731282, 21.122679],
                [106.4734057, 21.1227401],
                [106.4735866, 21.1227487],
                [106.4738012, 21.1227367],
                [106.4740262, 21.122642],
                [106.4743177, 21.1224029],
                [106.4744972, 21.1222676],
                [106.4746551, 21.1222354],
                [106.4747684, 21.1222755],
                [106.4748595, 21.1223467],
                [106.474895, 21.1225007],
                [106.4748872, 21.1228609],
                [106.4748581, 21.1233448],
                [106.4749398, 21.1236012],
                [106.4750775, 21.1238264],
                [106.4752366, 21.1239175],
                [106.4754181, 21.1239777],
                [106.475667, 21.1240167],
                [106.4758755, 21.1239531],
                [106.4760771, 21.1237764],
                [106.4762552, 21.1234971],
                [106.4764107, 21.1232284],
                [106.4764997, 21.1230835],
                [106.4766235, 21.123031],
                [106.4767364, 21.12303],
                [106.4768616, 21.1231113],
                [106.4770216, 21.123295],
                [106.47716, 21.1235818],
                [106.4772303, 21.1238282],
                [106.4772807, 21.1243524],
                [106.4773287, 21.1246401],
                [106.4774093, 21.1247938],
                [106.4775681, 21.1248438],
                [106.4777606, 21.1248833],
                [106.4779078, 21.1249231],
                [106.4780561, 21.1250555],
                [106.4781019, 21.1251169],
                [106.4782043, 21.125188],
                [106.4783686, 21.125238],
                [106.4786746, 21.1253176],
                [106.4788562, 21.1254086],
                [106.4789594, 21.1255518],
                [106.4790167, 21.1256337],
                [106.4790863, 21.1258182],
                [106.4791783, 21.125982],
                [106.479293, 21.1261559],
                [106.4794072, 21.126268],
                [106.4794964, 21.1263177],
                [106.4795498, 21.1263475],
                [106.4795889, 21.1263693],
                [106.4798152, 21.1263879],
                [106.4804934, 21.1264129],
                [106.4810014, 21.1263467],
                [106.4812219, 21.1263711],
                [106.481376, 21.1262787],
                [106.481502, 21.1262488],
                [106.481586, 21.1262385],
                [106.4817439, 21.1262564],
                [106.4818813, 21.1263222],
                [106.4821139, 21.1264447],
                [106.4822626, 21.126587],
                [106.4824012, 21.1267869],
                [106.4824347, 21.1269782],
                [106.4824359, 21.1271026],
                [106.4824157, 21.127189],
                [106.4823432, 21.127295],
                [106.4821867, 21.1274209],
                [106.4820822, 21.1274983],
                [106.4819355, 21.1275476],
                [106.4817575, 21.1276257],
                [106.481548, 21.1277138],
                [106.481391, 21.1277917],
                [106.481255, 21.1278695],
                [106.4811719, 21.1279756],
                [106.4811312, 21.12811],
                [106.4811429, 21.1282344],
                [106.4811547, 21.1283685],
                [106.4812297, 21.1285114],
                [106.4813998, 21.1286919],
                [106.4816543, 21.1289003],
                [106.4818977, 21.1290611],
                [106.4821091, 21.1291646],
                [106.4822885, 21.1292204],
                [106.4825093, 21.1292185],
                [106.4826036, 21.1291794],
                [106.4827817, 21.1291012],
                [106.4829381, 21.1289658],
                [106.4830724, 21.1287252],
                [106.4832569, 21.1282255],
                [106.4833192, 21.1281484],
                [106.4834139, 21.1281476],
                [106.4834983, 21.128185],
                [106.4835621, 21.1282516],
                [106.4836156, 21.1283469],
                [106.4836277, 21.1285],
                [106.4836325, 21.1289885],
                [106.4836554, 21.1291797],
                [106.4836983, 21.1292655],
                [106.483974, 21.129493],
                [106.4840701, 21.1296262],
                [106.4841662, 21.1297786],
                [106.4841989, 21.1298933],
                [106.4842039, 21.1304104],
                [106.484216, 21.1305731],
                [106.4842488, 21.1306878],
                [106.4843001, 21.1307702],
                [106.4843343, 21.1308687],
                [106.4842786, 21.1312068],
                [106.4842309, 21.1314913],
                [106.483751, 21.1324602],
                [106.4827467, 21.1339707],
                [106.4820871, 21.1347358],
                [106.4833104, 21.136598],
                [106.4838907, 21.1376197],
                [106.4841389, 21.1383431],
                [106.4842022, 21.1391187],
                [106.4842972, 21.1412102],
                [106.4838451, 21.1432727],
                [106.4837237, 21.1441173],
                [106.4835532, 21.1446757],
                [106.4832413, 21.1449991],
                [106.4826873, 21.1452064],
                [106.4820211, 21.1453134],
                [106.4811691, 21.1453884],
                [106.4805024, 21.1454449],
                [106.4795392, 21.1455388],
                [106.4798266, 21.1463667],
                [106.480304, 21.1473861],
                [106.4806014, 21.1486597],
                [106.4808021, 21.1497613],
                [106.4810153, 21.1513679],
                [106.4811706, 21.152297],
                [106.4814829, 21.1535971],
                [106.4816314, 21.1546165],
                [106.4818077, 21.1548689],
                [106.4822822, 21.1553916],
                [106.4825952, 21.1557341],
                [106.4827474, 21.1559689],
                [106.4828206, 21.1562953],
                [106.4827274, 21.1569701],
                [106.4825988, 21.1580985],
                [106.4826016, 21.1583712],
                [106.4826841, 21.1586341],
                [106.4828668, 21.1589416],
                [106.4834372, 21.1600457],
                [106.4838012, 21.160497],
                [106.4842313, 21.1608984],
                [106.4848757, 21.161411],
                [106.4855085, 21.1617691],
                [106.4860302, 21.1622442],
                [106.4865152, 21.1627945],
                [106.4872031, 21.1636703],
                [106.4876762, 21.1643247],
                [106.4881309, 21.1648479],
                [106.4885045, 21.1652538],
                [106.4891685, 21.1657389],
                [106.489662, 21.1661436],
                [106.4900149, 21.1664771],
                [106.4901071, 21.1667924],
                [106.490003, 21.1672896],
                [106.4897118, 21.1681855],
                [106.4892635, 21.1697278],
                [106.4888114, 21.1708981],
                [106.4883001, 21.1715973],
                [106.4873262, 21.1724249],
                [106.4865711, 21.1733],
                [106.4869344, 21.1741652],
                [106.4885665, 21.176409],
                [106.4895077, 21.1777654],
                [106.4897044, 21.1783345],
                [106.4896327, 21.1793525],
                [106.4895053, 21.1802469],
                [106.4894821, 21.1804792],
                [106.4894557, 21.1807436],
                [106.4896503, 21.1811142],
                [106.4897612, 21.1812869],
                [106.490446, 21.1815538],
                [106.491541, 21.1819164],
                [106.491954, 21.1822848],
                [106.4921485, 21.1826306],
                [106.4923703, 21.1830008],
                [106.4927834, 21.1833694],
                [106.4936055, 21.1837342],
                [106.4958509, 21.1845333],
                [106.4960711, 21.1847299],
                [106.4961022, 21.1851018],
                [106.4961107, 21.1859455],
                [106.4959203, 21.1887263],
                [106.4957716, 21.1902164],
                [106.495674, 21.1913587],
                [106.4955739, 21.1922529],
                [106.4952885, 21.1937195],
                [106.4943544, 21.1985168],
                [106.4943861, 21.1990037],
                [106.4943867, 21.1990128],
                [106.4947182, 21.199407],
                [106.4949934, 21.1996526],
                [106.4953227, 21.1998234],
                [106.4969096, 21.200256],
                [106.4980581, 21.200494],
                [106.5001355, 21.2008477],
                [106.5004153, 21.2010441],
                [106.5006363, 21.2015408],
                [106.5007401, 21.2019479],
                [106.5007437, 21.2023104],
                [106.5007299, 21.2025827],
                [106.5006357, 21.2031274],
                [106.500491, 21.2036238],
                [106.5000947, 21.2049176],
                [106.4996482, 21.2066337],
                [106.4992244, 21.207903],
                [106.4985334, 21.2097454],
                [106.4982466, 21.211063],
                [106.4983056, 21.2115092],
                [106.4985015, 21.2119789],
                [106.4987232, 21.2123244],
                [106.4992732, 21.2127413],
                [106.4997692, 21.2132084],
                [106.4999639, 21.2135788],
                [106.500051, 21.2140991],
                [106.5000361, 21.2153152],
                [106.4999337, 21.215986],
                [106.4998636, 21.2171528],
                [106.4998996, 21.2180211],
                [106.5001012, 21.2190615],
                [106.5004137, 21.2202745],
                [106.5007985, 21.2214697],
                [106.5008253, 21.2215407],
                [106.5013087, 21.2227733],
                [106.5013912, 21.223177],
                [106.5017393, 21.2239587],
                [106.5018799, 21.2244007],
                [106.5008618, 21.2245042],
                [106.5002603, 21.224737],
                [106.5000767, 21.2249072],
                [106.4998552, 21.225415],
                [106.4996957, 21.2259408],
                [106.4995759, 21.2263165],
                [106.4994355, 21.2266923],
                [106.4992326, 21.2269938],
                [106.4987414, 21.2273166],
                [106.4983523, 21.2275448],
                [106.4975925, 21.227795],
                [106.4962979, 21.228125],
                [106.4952486, 21.2282466],
                [106.4946922, 21.2282328],
                [106.49329, 21.2281141],
                [106.4924856, 21.2280089],
                [106.4919165, 21.2287629],
                [106.4919163, 21.2287632],
                [106.4912662, 21.2296679],
                [106.4904104, 21.2306121],
                [106.4901263, 21.2310453],
                [106.4899651, 21.2314027],
                [106.4898279, 21.2320968],
                [106.4897747, 21.232959],
                [106.4897485, 21.2344576],
                [106.4897417, 21.235825],
                [106.4897041, 21.2361813],
                [106.4895223, 21.2365387],
                [106.4892983, 21.2368029],
                [106.4891554, 21.2369354],
                [106.4889706, 21.2369932],
                [106.4889086, 21.2369749],
                [106.4887827, 21.2367512],
                [106.4885699, 21.2360789],
                [106.4884641, 21.2337385],
                [106.4883646, 21.2320349],
                [106.4883143, 21.2311363],
                [106.4882491, 21.2307996],
                [106.4879546, 21.2301842],
                [106.4875367, 21.2296072],
                [106.4873283, 21.2293656],
                [106.4870374, 21.2291246],
                [106.4866847, 21.2288842],
                [106.4862095, 21.2287386],
                [106.4851578, 21.2286354],
                [106.483735, 21.2284982],
                [106.48151, 21.2284989],
                [106.4801098, 21.2285674],
                [106.4792463, 21.2287435],
                [106.4779313, 21.2290923],
                [106.4771093, 21.2293055],
                [106.4757342, 21.2298232],
                [106.4746259, 21.230245],
                [106.4742755, 21.2302293],
                [106.474048, 21.2301377],
                [106.473715, 21.2298034],
                [106.4731323, 21.2292092],
                [106.4724479, 21.2287468],
                [106.4715781, 21.2282861],
                [106.4710619, 21.2281782],
                [106.4706911, 21.2281815],
                [106.4702388, 21.2282792],
                [106.4699712, 21.2283001],
                [106.4682307, 21.2272851],
                [106.4675457, 21.2267666],
                [106.4668201, 21.2263047],
                [106.4663446, 21.2261402],
                [106.4659533, 21.2261437],
                [106.4656457, 21.2262961],
                [106.4649307, 21.2269017],
                [106.4642952, 21.2272257],
                [106.4637608, 21.2273427],
                [106.4630392, 21.2272928],
                [106.4624822, 21.2272226],
                [106.4615632, 21.2280361],
                [106.4603359, 21.228927],
                [106.4599455, 21.2290241],
                [106.4594101, 21.2290475],
                [106.4589988, 21.2291259],
                [106.4585476, 21.2293358],
                [106.4582615, 21.2295631],
                [106.4578346, 21.2301474],
                [106.4572263, 21.2311454],
                [106.4562487, 21.2322964],
                [106.4556152, 21.2328263],
                [106.4553272, 21.2328663],
                [106.4550586, 21.2327936],
                [106.4548306, 21.2326458],
                [106.4539784, 21.2318852],
                [106.4526071, 21.2306982],
                [106.4521726, 21.2305147],
                [106.4517604, 21.2304994],
                [106.451493, 21.2305392],
                [106.4503222, 21.2309052],
                [106.4488431, 21.2313299],
                [106.4481642, 21.2314295],
                [106.4475656, 21.2314486],
                [106.447361, 21.2314551],
                [106.4465153, 21.2313499],
                [106.4457501, 21.2310568],
                [106.4452938, 21.2307423],
                [106.4440881, 21.2296288],
                [106.4435477, 21.2291464],
                [106.4431549, 21.2289999],
                [106.4423503, 21.2288944],
                [106.4413404, 21.2288469],
                [106.4403521, 21.2289115],
                [106.439878, 21.2288781],
                [106.4393616, 21.2287326],
                [106.4386998, 21.228476],
                [106.4383045, 21.2284175],
                [106.4381015, 21.2283874],
                [106.4368237, 21.2283422],
                [106.4358763, 21.228369],
                [106.4350548, 21.2286382],
                [106.4343579, 21.2289999],
                [106.4337437, 21.2293986],
                [106.4332732, 21.2297398],
                [106.4325399, 21.2306076],
                [106.4319886, 21.2311181],
                [106.4314757, 21.2313471],
                [106.4312282, 21.2313118],
                [106.4309589, 21.2311642],
                [106.4304, 21.230888],
                [106.4296353, 21.2306323],
                [106.4293259, 21.2305974],
                [106.4287911, 21.2306956],
                [106.4283808, 21.2308676],
                [106.4273954, 21.2312319],
                [106.4268606, 21.23133],
                [106.42614, 21.2313736],
                [106.4258515, 21.2313573],
                [106.4254176, 21.2312298],
                [106.4248585, 21.2309349],
                [106.4242179, 21.2307343],
                [106.4240898, 21.2300985],
                [106.4239126, 21.2292181],
                [106.4233933, 21.2278037],
                [106.4233156, 21.2270479],
                [106.4233161, 21.2261213],
                [106.4231774, 21.2253673],
                [106.4229862, 21.2247507],
                [106.422563, 21.223593],
                [106.4217403, 21.223731],
                [106.4210194, 21.2237371],
                [106.420483, 21.2236667],
                [106.4194505, 21.2234131],
                [106.4189557, 21.2233611],
                [106.418194, 21.2234237],
                [106.4176781, 21.2233344],
                [106.417429, 21.2231304],
                [106.4172002, 21.2229076],
                [106.4169909, 21.2225722],
                [106.4168227, 21.222199],
                [106.4166312, 21.2215637],
                [106.4165217, 21.2208903],
                [106.4164694, 21.2197294],
                [106.4162973, 21.218963],
                [106.4160612, 21.2179722],
                [106.4158649, 21.2168312],
                [106.4156286, 21.2158031],
                [106.4152879, 21.2146445],
                [106.4149051, 21.2133928],
                [106.4145034, 21.2123098],
                [106.4137648, 21.2104615],
                [106.4131921, 21.2087244],
                [106.4128748, 21.2078466],
                [106.41275, 21.2077166],
                [106.412523, 21.207681],
                [106.4122561, 21.2077582],
                [106.4115389, 21.2081763],
                [106.4111277, 21.2082546],
                [106.4108389, 21.2082008],
                [106.4104457, 21.2080169],
                [106.4099892, 21.2076647],
                [106.4091591, 21.2070161],
                [106.4083918, 21.2064793],
                [106.4078952, 21.2062399],
                [106.4073787, 21.2060756],
                [106.4069042, 21.2060046],
                [106.4057288, 21.2058647],
                [106.4046974, 21.2057046],
                [106.4038099, 21.2055247],
                [106.4028594, 21.2052142],
                [106.401557, 21.2047192],
                [106.4012257, 21.2045346],
                [106.4011007, 21.2043858],
                [106.4008286, 21.2039198],
                [106.4006388, 21.2034531],
                [106.4005735, 21.2030791],
                [106.4005488, 21.2026484],
                [106.4003396, 21.2023131],
                [106.4000903, 21.2020903],
                [106.3994545, 21.2023766],
                [106.3992911, 21.2025277],
                [106.3990857, 21.2025856],
                [106.3987364, 21.2026822],
                [106.3970272, 21.2027337],
                [106.3947218, 21.2029027],
                [106.3933633, 21.2030076],
                [106.3927544, 21.2017764],
                [106.3924601, 21.2011419],
                [106.3921462, 21.20062],
                [106.391709, 21.2001179],
                [106.3909834, 21.1996181],
                [106.3901956, 21.1990814],
                [106.3894702, 21.1986003],
                [106.388601, 21.198158],
                [106.3871123, 21.1975521],
                [106.3863879, 21.1971834],
                [106.3856218, 21.1965531],
                [106.3855991, 21.1965343],
                [106.38498, 21.1964082],
                [106.383473, 21.1960459],
                [106.3824416, 21.1958858],
                [106.3814539, 21.1959875],
                [106.3801168, 21.1961671],
                [106.3792334, 21.1964178],
                [106.3786585, 21.1966285],
                [106.3780822, 21.1966707],
                [106.3774636, 21.1966008],
                [106.3761626, 21.1962367],
                [106.3741364, 21.1954103],
                [106.3742321, 21.1946229],
                [106.374275, 21.1940536],
                [106.3742872, 21.1938918],
                [106.3741994, 21.1933119],
                [106.3740703, 21.1927135],
                [106.3739594, 21.1918528],
                [106.3739076, 21.1907107],
                [106.3737553, 21.1898315],
                [106.373544, 21.1892525],
                [106.3731449, 21.1884128],
                [106.3727891, 21.1877977],
                [106.3725177, 21.1874065],
                [106.3717081, 21.18672],
                [106.3712503, 21.186218],
                [106.3708326, 21.1855844],
                [106.3705598, 21.1850435],
                [106.3703887, 21.1843519],
                [106.3702021, 21.1842222],
                [106.3697899, 21.1841881],
                [106.3695213, 21.1840966],
                [106.3694578, 21.1839098],
                [106.3694759, 21.1836288],
                [106.3697597, 21.1831394],
                [106.3702096, 21.1827986],
                [106.3702899, 21.1825731],
                [106.3702024, 21.1820119],
                [106.3709845, 21.1815504],
                [106.3692753, 21.179753],
                [106.3684368, 21.1803966],
                [106.367415, 21.1812478],
                [106.3658373, 21.1798931],
                [106.365195, 21.1794862],
                [106.3644507, 21.1791739],
                [106.364481, 21.1779748],
                [106.364498, 21.1776001],
                [106.3647408, 21.177111],
                [106.3650663, 21.1766776],
                [106.365309, 21.1762073],
                [106.3653455, 21.1756825],
                [106.3652152, 21.1749531],
                [106.3651481, 21.174373],
                [106.3651884, 21.174279],
                [106.3652908, 21.174222],
                [106.3655376, 21.1741825],
                [106.3661755, 21.1741211],
                [106.3667197, 21.1738358],
                [106.3667682, 21.1736293],
                [106.3662103, 21.1734278],
                [106.3648875, 21.172914],
                [106.364331, 21.1728623],
                [106.3640429, 21.1728834],
                [106.3637337, 21.1728485],
                [106.3634234, 21.172701],
                [106.3628636, 21.1722934],
                [106.3621377, 21.1717373],
                [106.3618485, 21.1716273],
                [106.3615189, 21.17163],
                [106.3611076, 21.1716895],
                [106.3604713, 21.1719194],
                [106.3601427, 21.1720157],
                [106.359546, 21.1720767],
                [106.3587434, 21.1721393],
                [106.3582908, 21.1721991],
                [106.3579217, 21.1723707],
                [106.3575127, 21.1726924],
                [106.3570626, 21.1730145],
                [106.3567957, 21.1731103],
                [106.35659, 21.1731306],
                [106.3562602, 21.1731145],
                [106.3559091, 21.1729862],
                [106.3553086, 21.1726352],
                [106.3541894, 21.1718574],
                [106.3536103, 21.171581],
                [106.3530119, 21.1714547],
                [106.3523529, 21.17146],
                [106.3518801, 21.1715574],
                [106.3515105, 21.1716728],
                [106.3506692, 21.1720167],
                [106.3494397, 21.1727008],
                [106.3484143, 21.1731774],
                [106.3481058, 21.1732172],
                [106.3476528, 21.1732208],
                [106.3469725, 21.1731514],
                [106.3464577, 21.1731554],
                [106.3460059, 21.1733088],
                [106.3456569, 21.173424],
                [106.3453487, 21.1735014],
                [106.3449575, 21.1735046],
                [106.3446475, 21.1733946],
                [106.3444196, 21.1732466],
                [106.3442533, 21.1730793],
                [106.3441892, 21.1728176],
                [106.3441872, 21.1725928],
                [106.3442469, 21.1723676],
                [106.3443271, 21.1721235],
                [106.3445101, 21.1718597],
                [106.3449593, 21.171444],
                [106.3455331, 21.1711211],
                [106.3457784, 21.1709131],
                [106.34594, 21.1705746],
                [106.3462209, 21.1697482],
                [106.3465861, 21.1691458],
                [106.346912, 21.1687499],
                [106.3473203, 21.1683532],
                [106.3475448, 21.1681267],
                [106.3475843, 21.167939],
                [106.3475625, 21.1678081],
                [106.3474998, 21.1677151],
                [106.3473136, 21.1676041],
                [106.3469819, 21.1673632],
                [106.346753, 21.1671028],
                [106.3461262, 21.1659795],
                [106.3456984, 21.1652263],
                [106.3452341, 21.1656866],
                [106.3457573, 21.1664523],
                [106.3452037, 21.1671619],
                [106.3427051, 21.1705437],
                [106.3410055, 21.1726215],
                [106.339255, 21.1726648],
                [106.3388293, 21.1721963],
                [106.3386329, 21.1719914],
                [106.3384048, 21.1718754],
                [106.3378869, 21.1719679],
                [106.3364322, 21.1724512],
                [106.3358516, 21.1727802],
                [106.3354341, 21.1732259],
                [106.3352459, 21.1739351],
                [106.3350223, 21.1743203],
                [106.3343423, 21.1744141],
                [106.3324308, 21.1745766],
                [106.3320259, 21.1764377],
                [106.3318048, 21.1770883],
                [106.3313808, 21.1776817],
                [106.3313914, 21.1778864],
                [106.3313319, 21.178302],
                [106.3312604, 21.1787889],
                [106.3310649, 21.1795496],
                [106.3309541, 21.1798825],
                [106.330906, 21.1801557],
                [106.3308469, 21.1806187],
                [106.3308503, 21.1809983],
                [106.3308767, 21.1813379],
                [106.3293128, 21.1812365],
                [106.3283735, 21.1813619],
                [106.328054, 21.1818953],
                [106.3278302, 21.1822509],
                [106.3271823, 21.182315],
                [106.326767, 21.1830261],
                [106.3263168, 21.1834423],
                [106.3255752, 21.1839201],
                [106.324413, 21.1844895],
                [106.3230882, 21.1852158],
                [106.3235793, 21.1869724],
                [106.3237981, 21.1878221],
                [106.3238918, 21.1881784],
                [106.3240785, 21.1888087],
                [106.3245993, 21.1905417],
                [106.3245966, 21.1905469],
                [106.3247308, 21.191034],
                [106.3248628, 21.1920448],
                [106.3164084, 21.1929745],
                [106.31423, 21.1929914],
                [106.3135593, 21.1929443],
                [106.3132825, 21.192803],
                [106.3130543, 21.1926865],
                [106.3119279, 21.1916485],
                [106.3105117, 21.1894089],
                [106.3098793, 21.1873725],
                [106.3094994, 21.1854651],
                [106.309367, 21.1831108],
                [106.309674, 21.1799157],
                [106.3103361, 21.1782805],
                [106.310822, 21.1770805],
                [106.312238, 21.172987],
                [106.3139143, 21.1699122],
                [106.315352, 21.1682784],
                [106.3167906, 21.1667494],
                [106.3184114, 21.1637273],
                [106.3201198, 21.1611493],
                [106.3213243, 21.1584182],
                [106.3227441, 21.1547957],
                [106.3232017, 21.1528817],
                [106.3231394, 21.1521495],
                [106.3229579, 21.1505807],
                [106.3228277, 21.1484881],
                [106.3225364, 21.1471296],
                [106.3205657, 21.1421465],
                [106.3199929, 21.1405285],
                [106.3175991, 21.1358967],
                [106.3173796, 21.1354719],
                [106.3138538, 21.1313906],
                [106.3126711, 21.1302483],
                [106.3122212, 21.1298854],
                [106.3114908, 21.1293677],
                [106.310424, 21.1286955],
                [106.3096378, 21.1281782],
                [106.3089092, 21.1278697],
                [106.3072301, 21.1274117],
                [106.3053268, 21.1268507],
                [106.3024584, 21.1244651],
                [106.2992819, 21.1219509],
                [106.2983455, 21.1205014],
                [106.2978321, 21.1194951],
                [106.2971147, 21.1182382],
                [106.2967406, 21.1172803],
                [106.2963522, 21.1161346],
                [106.2959634, 21.114943],
                [106.2954131, 21.112581],
                [106.2952204, 21.1107217],
                [106.2951392, 21.1090218],
                [106.2951578, 21.1083109],
                [106.2955012, 21.1067452],
                [106.2957923, 21.1054105],
                [106.2963334, 21.1037983],
                [106.2975272, 21.1003892],
                [106.2985509, 21.0977165],
                [106.2994161, 21.0956882],
                [106.3005119, 21.0926244],
                [106.3011518, 21.0907817],
                [106.3018286, 21.0888467],
                [106.3020242, 21.0881331],
                [106.3021695, 21.0874429],
                [106.3024433, 21.0855799],
                [106.302475, 21.0834202],
                [106.3025884, 21.0805248],
                [106.3026021, 21.0791922],
                [106.3027532, 21.0777209],
                [106.3028939, 21.0765022],
                [106.3033048, 21.0744544],
                [106.3040735, 21.0719204],
                [106.3053387, 21.0694619],
                [106.306002, 21.0684302],
                [106.3070946, 21.0664691],
                [106.3082495, 21.0644385],
                [106.3092472, 21.0631674],
                [106.3107463, 21.0605846],
                [106.3114971, 21.0594361],
                [106.3119298, 21.0585367],
                [106.3098674, 21.0562783],
                [106.3076778, 21.053906],
                [106.3045147, 21.0512195],
                [106.2997197, 21.0464548],
                [106.2981408, 21.044675],
                [106.2961137, 21.0420946],
                [106.2949155, 21.0405875],
                [106.2940601, 21.0393535],
                [106.2936233, 21.0384148],
                [106.2933132, 21.0375442],
                [106.293105, 21.0367878],
                [106.2927763, 21.0352281],
                [106.2927452, 21.0345391],
                [106.2927508, 21.033735],
                [106.2929895, 21.032171],
                [106.2938272, 21.0299134],
                [106.2944882, 21.0276109],
                [106.2946038, 21.027261],
                [106.2950557, 21.024712],
                [106.2951672, 21.0230341],
                [106.2951741, 21.0223679],
                [106.2950693, 21.0219091],
                [106.2945786, 21.0205575],
                [106.2937601, 21.0192083],
                [106.292401, 21.018024],
                [106.2898913, 21.016435],
                [106.2872294, 21.0147321],
                [106.2850357, 21.0132325],
                [106.2799199, 21.0098297],
                [106.2795716, 21.009598],
                [106.2786338, 21.0090077],
                [106.2771361, 21.0078013],
                [106.2761453, 21.0069128],
                [106.2752553, 21.0060235],
                [106.2739426, 21.0043334],
                [106.2729197, 21.0026181],
                [106.2723919, 21.0013126],
                [106.2720801, 21.0002121],
                [106.2719983, 20.9995006],
                [106.2719351, 20.9990082],
                [106.2721826, 20.9963854],
                [106.2723956, 20.994244],
                [106.2725391, 20.9929976],
                [106.2728186, 20.9918904],
                [106.2722849, 20.9919002],
                [106.2716239, 20.9918582],
                [106.2712834, 20.9917473],
                [106.2708702, 20.9914852],
                [106.2701453, 20.99082],
                [106.2693492, 20.9898434],
                [106.2685544, 20.9890072],
                [106.2679169, 20.9885441],
                [106.2672281, 20.9881125],
                [106.266833, 20.9879594],
                [106.2664557, 20.9878998],
                [106.2658394, 20.98792],
                [106.2647627, 20.9881776],
                [106.2642493, 20.9882723],
                [106.2637875, 20.9883618],
                [106.2635253, 20.9884117],
                [106.263305, 20.9884802],
                [106.2629703, 20.9886742],
                [106.2627514, 20.9888864],
                [106.2625437, 20.9891846],
                [106.2623265, 20.9896074],
                [106.2621508, 20.989963],
                [106.2619844, 20.9901747],
                [106.2617965, 20.9903197],
                [106.2615762, 20.9903883],
                [106.2613451, 20.9903997],
                [106.2609344, 20.990307],
                [106.2604917, 20.9901571],
                [106.2601546, 20.9900734],
                [106.2598812, 20.9900563],
                [106.2596396, 20.9900676],
                [106.2592723, 20.9901469],
                [106.2589058, 20.9903124],
                [106.258697, 20.9904767],
                [106.2584783, 20.9907272],
                [106.2583124, 20.9909868],
                [106.258189, 20.9913228],
                [106.2581613, 20.9917824],
                [106.2581549, 20.9922706],
                [106.2581457, 20.9924239],
                [106.2580839, 20.9925774],
                [106.2580323, 20.9926831],
                [106.2578967, 20.9928086],
                [106.2576977, 20.9928962],
                [106.2568224, 20.9929658],
                [106.2550425, 20.9945375],
                [106.2537077, 20.995405],
                [106.2534277, 20.9955801],
                [106.2532112, 20.9961523],
                [106.2529976, 20.9963976],
                [106.2525167, 20.9966223],
                [106.2522514, 20.9966719],
                [106.2517318, 20.9967366],
                [106.2513352, 20.9966538],
                [106.2506624, 20.9963155],
                [106.2500475, 20.9962072],
                [106.249713, 20.9962167],
                [106.2493864, 20.996261],
                [106.2491494, 20.9963745],
                [106.2488534, 20.9965443],
                [106.2485727, 20.996742],
                [106.2482476, 20.9969959],
                [106.2478927, 20.997222],
                [106.2475074, 20.9973785],
                [106.2470629, 20.9975634],
                [106.2466547, 20.9976749],
                [106.2462443, 20.9977529],
                [106.2459834, 20.996606],
                [106.24575, 20.9952697],
                [106.2455473, 20.9941456],
                [106.2453075, 20.9932033],
                [106.2451651, 20.9923511],
                [106.2449834, 20.9914265],
                [106.2448619, 20.9907556],
                [106.2446997, 20.991001],
                [106.2443653, 20.9914575],
                [106.2441259, 20.9917317],
                [106.2438577, 20.9920242],
                [106.2436174, 20.9921804],
                [106.2433673, 20.9923185],
                [106.2431458, 20.992411],
                [106.2429046, 20.9924671],
                [106.2425328, 20.9924926],
                [106.2422231, 20.9924494],
                [106.2419512, 20.992288],
                [106.2417566, 20.9921169],
                [106.2415506, 20.9917551],
                [106.2414037, 20.9915291],
                [106.2411126, 20.9913768],
                [106.2408418, 20.9913607],
                [106.2406584, 20.9913802],
                [106.2404948, 20.991463],
                [106.2402934, 20.9916553],
                [106.2401258, 20.9918335],
                [106.2399734, 20.9920981],
                [106.2397731, 20.9924264],
                [106.2395148, 20.9927462],
                [106.2393521, 20.992929],
                [106.2391121, 20.9931305],
                [106.2388287, 20.9933368],
                [106.2385981, 20.9934929],
                [106.2383478, 20.9936128],
                [106.2381737, 20.993605],
                [106.2379312, 20.9934978],
                [106.2377174, 20.993345],
                [106.2374161, 20.9931474],
                [106.2371159, 20.9930588],
                [106.2369038, 20.9931238],
                [106.2367793, 20.9932609],
                [106.2366849, 20.9935341],
                [106.2366576, 20.993734],
                [106.2366396, 20.9939068],
                [106.2365781, 20.9940661],
                [106.2364546, 20.9943304],
                [106.2363781, 20.9944217],
                [106.2361183, 20.9945598],
                [106.2357804, 20.9946077],
                [106.2353555, 20.9946471],
                [106.2350177, 20.9946949],
                [106.2346518, 20.9948702],
                [106.2343587, 20.9950585],
                [106.234032, 20.9952878],
                [106.2337142, 20.9954355],
                [106.2333958, 20.9954922],
                [106.2330382, 20.9954949],
                [106.2327673, 20.9954605],
                [106.2324957, 20.9953353],
                [106.2323014, 20.9952096],
                [106.232223, 20.9950739],
                [106.2321721, 20.9947565],
                [106.2320471, 20.9942534],
                [106.231977, 20.9939542],
                [106.2318488, 20.9936463],
                [106.2316822, 20.993366],
                [106.2313993, 20.993032],
                [106.231098, 20.9928344],
                [106.2308265, 20.9927274],
                [106.2304447, 20.9927166],
                [106.2302133, 20.9927818],
                [106.2300497, 20.9928556],
                [106.2298868, 20.9930203],
                [106.2297532, 20.9932392],
                [106.229687, 20.9934122],
                [106.2295925, 20.9936853],
                [106.2295043, 20.9941173],
                [106.2294003, 20.9943997],
                [106.2292569, 20.9946004],
                [106.2291418, 20.9946921],
                [106.228968, 20.9947115],
                [106.2287545, 20.9946132],
                [106.2284726, 20.9944154],
                [106.2282202, 20.9942719],
                [106.2279777, 20.9941738],
                [106.2276679, 20.9941124],
                [106.2273344, 20.9940921],
                [106.2269671, 20.9940858],
                [106.2264842, 20.9941164],
                [106.2258851, 20.9941389],
                [106.2255278, 20.9941687],
                [106.2252098, 20.9942891],
                [106.2248785, 20.9945321],
                [106.2245328, 20.9948161],
                [106.2243216, 20.9949901],
                [106.2241004, 20.9951098],
                [106.2238494, 20.995157],
                [106.2235696, 20.9952135],
                [106.223309, 20.9952517],
                [106.2230004, 20.9953266],
                [106.2226293, 20.9954428],
                [106.222302, 20.9956086],
                [106.2219937, 20.9957289],
                [106.2217046, 20.9958309],
                [106.2214827, 20.9958778],
                [106.2212024, 20.9958708],
                [106.2209316, 20.9958455],
                [106.2206409, 20.9957568],
                [106.2204178, 20.9956494],
                [106.2203203, 20.995541],
                [106.2202512, 20.995369],
                [106.2202395, 20.9951239],
                [106.2202711, 20.9948467],
                [106.2204709, 20.9944548],
                [106.2206515, 20.9940902],
                [106.2207745, 20.9937624],
                [106.2208301, 20.9934805],
                [106.2208323, 20.99314],
                [106.2207621, 20.9928226],
                [106.2206628, 20.9924964],
                [106.2205153, 20.9921886],
                [106.2203875, 20.9919262],
                [106.220347, 20.9917086],
                [106.2203604, 20.9915677],
                [106.220503, 20.991276],
                [106.2206561, 20.9910843],
                [106.2208668, 20.9908466],
                [106.2210289, 20.9905912],
                [106.2211239, 20.9903816],
                [106.2210396, 20.9901235],
                [106.220816, 20.9899525],
                [106.2205832, 20.9898543],
                [106.2202541, 20.9897839],
                [106.2198672, 20.9897414],
                [106.2194224, 20.9897173],
                [106.2188666, 20.9896986],
                [106.2185187, 20.9897011],
                [106.2181898, 20.989649],
                [106.2178696, 20.9894968],
                [106.2175684, 20.9892992],
                [106.2171505, 20.9890116],
                [106.2167818, 20.9888144],
                [106.2165055, 20.9887211],
                [106.2161285, 20.9887055],
                [106.215829, 20.9887168],
                [106.2155591, 20.9887914],
                [106.2152695, 20.988848],
                [106.2150955, 20.9888311],
                [106.214882, 20.9887236],
                [106.2147067, 20.9885613],
                [106.2144233, 20.9881638],
                [106.2141252, 20.9877527],
                [106.2138706, 20.9873368],
                [106.2136165, 20.9869935],
                [106.2133919, 20.9866954],
                [106.2131485, 20.9864792],
                [106.2129059, 20.9863629],
                [106.2124515, 20.9863208],
                [106.2121566, 20.9863092],
                [106.2116921, 20.9862307],
                [106.2113339, 20.9861516],
                [106.2110426, 20.9859811],
                [106.2107795, 20.9857105],
                [106.210555, 20.9854306],
                [106.2103114, 20.9851872],
                [106.2100436, 20.9849302],
                [106.2097817, 20.9848141],
                [106.2092976, 20.9846904],
                [106.2088912, 20.9846296],
                [106.2084461, 20.9845511],
                [106.207841, 20.9844146],
                [106.2075115, 20.9842988],
                [106.2072203, 20.9841466],
                [106.2068223, 20.9839315],
                [106.2065406, 20.9837427],
                [106.2062013, 20.9836088],
                [106.2058429, 20.9835024],
                [106.2055137, 20.983423],
                [106.2051167, 20.983335],
                [106.2047978, 20.9833282],
                [106.2044256, 20.9833081],
                [106.2040297, 20.9833382],
                [106.2036723, 20.9833679],
                [106.203291, 20.9834206],
                [106.2030304, 20.9834497],
                [106.2028172, 20.9833876],
                [106.2027599, 20.9833396],
                [106.2026129, 20.9832165],
                [106.2024277, 20.983018],
                [106.2020564, 20.982503],
                [106.2018135, 20.9823321],
                [106.2016008, 20.9823246],
                [106.2014078, 20.9823532],
                [106.2010936, 20.9823418],
                [106.2007255, 20.9822445],
                [106.2004829, 20.9821099],
                [106.200114, 20.9818947],
                [106.1997548, 20.9816974],
                [106.1993955, 20.9814638],
                [106.1988959, 20.9812357],
                [106.1985272, 20.9810477],
                [106.1980909, 20.98086],
                [106.1976062, 20.9806727],
                [106.1972187, 20.9805574],
                [106.1966422, 20.9803571],
                [106.1961191, 20.9801973],
                [106.1956638, 20.9800552],
                [106.1951891, 20.9799041],
                [106.1946079, 20.9797357],
                [106.1940994, 20.9795984],
                [106.1936827, 20.979447],
                [106.1928789, 20.9792165],
                [106.192327, 20.9790841],
                [106.1918575, 20.9789739],
                [106.1915383, 20.9789398],
                [106.1911905, 20.9789604],
                [106.1908913, 20.9789988],
                [106.1905827, 20.9790827],
                [106.1902456, 20.9792304],
                [106.1898222, 20.9794605],
                [106.1895579, 20.9796212],
                [106.1892025, 20.9799142],
                [106.1888855, 20.9801435],
                [106.1885108, 20.9804276],
                [106.1882035, 20.9806659],
                [106.1879728, 20.9808219],
                [106.1877224, 20.9809326],
                [106.1875292, 20.980934],
                [106.1874223, 20.9808529],
                [106.1873824, 20.980708],
                [106.1873806, 20.9804718],
                [106.1873828, 20.9801404],
                [106.1873614, 20.9798862],
                [106.1872729, 20.9796871],
                [106.1870978, 20.979543],
                [106.1869137, 20.9794625],
                [106.1866807, 20.9793369],
                [106.1864573, 20.9791933],
                [106.1862819, 20.9790128],
                [106.1860529, 20.9787647],
                [106.1857712, 20.978585],
                [106.1854227, 20.9785057],
                [106.1850361, 20.9785085],
                [106.1845921, 20.9785659],
                [106.1839111, 20.9786025],
                [106.1833803, 20.9786789],
                [106.1829166, 20.9787093],
                [106.1826266, 20.9786931],
                [106.1822879, 20.978632],
                [106.181968, 20.978498],
                [106.1817639, 20.9783631],
                [106.1816272, 20.9781734],
                [106.1814269, 20.9778978],
                [106.1811645, 20.9776999],
                [106.1808929, 20.9775746],
                [106.1806217, 20.9775038],
                [106.1802831, 20.9774517],
                [106.1799734, 20.9773811],
                [106.1797017, 20.9772468],
                [106.1794685, 20.9770849],
                [106.1791715, 20.9767918],
                [106.1789572, 20.9765753],
                [106.1786851, 20.9763865],
                [106.1783649, 20.9762162],
                [106.178093, 20.9760274],
                [106.1778979, 20.9758108],
                [106.1777609, 20.9755847],
                [106.1775757, 20.9753861],
                [106.1773867, 20.9753103],
                [106.1770868, 20.9752579],
                [106.1769036, 20.9753227],
                [106.1767116, 20.9754875],
                [106.1765095, 20.9755888],
                [106.1763164, 20.9756082],
                [106.1760649, 20.9755737],
                [106.175842, 20.9754844],
                [106.1754734, 20.9753235],
                [106.1750902, 20.9751295],
                [106.1749103, 20.9750068],
                [106.1747211, 20.9748779],
                [106.1743033, 20.9745812],
                [106.1739143, 20.9742663],
                [106.1736027, 20.973969],
                [106.1734853, 20.9737792],
                [106.1733486, 20.9735895],
                [106.1731344, 20.9734003],
                [106.1727848, 20.9731668],
                [106.1723823, 20.9729469],
                [106.1719552, 20.9726956],
                [106.1715378, 20.9724352],
                [106.1710122, 20.9719485],
                [106.1706818, 20.9716966],
                [106.1703701, 20.9713719],
                [106.1700974, 20.9710832],
                [106.169997, 20.9709685],
                [106.1697659, 20.9707041],
                [106.1695126, 20.9704335],
                [106.1692778, 20.9700537],
                [106.1690821, 20.9697464],
                [106.1688675, 20.9694754],
                [106.1684597, 20.9692058],
                [106.1679953, 20.9691182],
                [106.1677006, 20.9691433],
                [106.1675319, 20.9691577],
                [106.1671463, 20.9692693],
                [106.1668388, 20.9694893],
                [106.1667439, 20.9697079],
                [106.1667658, 20.9700346],
                [106.1668837, 20.970288],
                [106.1670206, 20.970505],
                [106.1671386, 20.9707584],
                [106.167179, 20.9709942],
                [106.1671807, 20.9712122],
                [106.1671728, 20.9714391],
                [106.1670202, 20.9716944],
                [106.1667905, 20.9719684],
                [106.1663105, 20.9723712],
                [106.165965, 20.9726642],
                [106.1652169, 20.9733774],
                [106.1648724, 20.9737975],
                [106.1643075, 20.9744642],
                [106.1638286, 20.9749941],
                [106.1632723, 20.9755245],
                [106.1637986, 20.9761202],
                [106.1644421, 20.9768603],
                [106.1649981, 20.9775556],
                [106.1676129, 20.9815913],
                [106.1687022, 20.9833582],
                [106.1695343, 20.9848733],
                [106.168883, 20.9865004],
                [106.1686375, 20.986855],
                [106.1683759, 20.9871346],
                [106.1679882, 20.9874244],
                [106.167919, 20.9874825],
                [106.1674509, 20.9878758],
                [106.1671053, 20.9881506],
                [106.1665286, 20.9885357],
                [106.1660476, 20.9887933],
                [106.1657161, 20.9884143],
                [106.1654432, 20.9880893],
                [106.1651501, 20.9876556],
                [106.1648768, 20.9873124],
                [106.1643578, 20.9876428],
                [106.163916, 20.9879908],
                [106.1633108, 20.9884216],
                [106.1629271, 20.9887692],
                [106.1625716, 20.9890166],
                [106.1620719, 20.9893651],
                [106.161735, 20.9895125],
                [106.1615228, 20.9895504],
                [106.1611605, 20.989556],
                [106.1607357, 20.9895951],
                [106.1603788, 20.989652],
                [106.1600802, 20.989772],
                [106.1599749, 20.9905803],
                [106.1594265, 20.9901847],
                [106.1592817, 20.9902129],
                [106.1590112, 20.9901966],
                [106.1577459, 20.9903627],
                [106.158508, 20.9900822],
                [106.1583621, 20.9899652],
                [106.1582448, 20.9897936],
                [106.158166, 20.9895946],
                [106.1580771, 20.9893412],
                [106.1579398, 20.9890699],
                [106.1577492, 20.9887578],
                [106.1575355, 20.9885959],
                [106.1572441, 20.98838],
                [106.1570691, 20.988236],
                [106.1567221, 20.9883291],
                [106.1562986, 20.9885135],
                [106.155797, 20.9885895],
                [106.1554491, 20.9885737],
                [106.154889, 20.9885774],
                [106.1543093, 20.9885633],
                [106.1534304, 20.9885573],
                [106.1526194, 20.9885991],
                [106.1517702, 20.9886957],
                [106.150998, 20.9887552],
                [106.1506706, 20.9888845],
                [106.1503829, 20.9891585],
                [106.1499812, 20.9896873],
                [106.149638, 20.9902702],
                [106.1493533, 20.9909251],
                [106.1492216, 20.9913796],
                [106.1492057, 20.9918332],
                [106.1490918, 20.9920885],
                [106.1484971, 20.9926188],
                [106.1478352, 20.993195],
                [106.1468943, 20.9939092],
                [106.146145, 20.9944405],
                [106.1457976, 20.994461],
                [106.1452765, 20.994519],
                [106.1443884, 20.9945612],
                [106.1436937, 20.9946385],
                [106.1435882, 20.9947209],
                [106.1435511, 20.9949207],
                [106.1434985, 20.9956289],
                [106.1434432, 20.9959922],
                [106.1431743, 20.9961755],
                [106.1427896, 20.9963777],
                [106.1427744, 20.9969224],
                [106.1428274, 20.9975482],
                [106.1423653, 20.9977327],
                [106.1414216, 20.9980838],
                [106.1408438, 20.9983055],
                [106.1398416, 20.9985844],
                [106.1393598, 20.9986148],
                [106.1391494, 20.9990098],
                [106.1378646, 21.0012428],
                [106.1319908, 20.9991456],
                [106.1323636, 20.9979304],
                [106.1323084, 20.9971819],
                [106.132245, 20.9967695],
                [106.13205, 20.9962037],
                [106.1317462, 20.9955979],
                [106.1315687, 20.9951092],
                [106.131294, 20.9945303],
                [106.1310983, 20.9941869],
                [106.1308058, 20.9938079],
                [106.1304945, 20.9935014],
                [106.1301065, 20.99325],
                [106.1297382, 20.9930708],
                [106.1292936, 20.9930194],
                [106.1290036, 20.992967],
                [106.1290413, 20.9928396],
                [106.1292218, 20.9924483],
                [106.1293922, 20.9919936],
                [106.1294469, 20.9915759],
                [106.1293664, 20.9911409],
                [106.1291671, 20.9903075],
                [106.1289012, 20.9896107],
                [106.1288976, 20.989139],
                [106.1289712, 20.9886485],
                [106.1292382, 20.9882113],
                [106.1293906, 20.9879381],
                [106.1294463, 20.9876292],
                [106.129387, 20.9874482],
                [106.1291335, 20.9871233],
                [106.1288508, 20.9867713],
                [106.1285573, 20.9862471],
                [106.1282244, 20.9856323],
                [106.1279499, 20.9850716],
                [106.1275774, 20.9843301],
                [106.1273543, 20.9837454],
                [106.1273113, 20.9836344],
                [106.1288375, 20.9809155],
                [106.1290975, 20.9810539],
                [106.1295245, 20.9813414],
                [106.1298144, 20.9813757],
                [106.1300644, 20.9812471],
                [106.1302939, 20.9809552],
                [106.1305605, 20.9804636],
                [106.1308464, 20.9799717],
                [106.1310945, 20.9795708],
                [106.1313814, 20.9792061],
                [106.1315925, 20.9790414],
                [106.1320261, 20.9789387],
                [106.1324121, 20.978918],
                [106.1328751, 20.9788605],
                [106.1332606, 20.9787672],
                [106.1336648, 20.9786013],
                [106.1341069, 20.9783441],
                [106.1347409, 20.9779226],
                [106.1354126, 20.9773738],
                [106.1363614, 20.9764692],
                [106.1369169, 20.9758668],
                [106.1372416, 20.9754111],
                [106.1373368, 20.975229],
                [106.1379856, 20.974652],
                [106.137663, 20.9740594],
                [106.1374105, 20.9734956],
                [106.1370785, 20.9729896],
                [106.1366302, 20.9724302],
                [106.1361824, 20.9719432],
                [106.1355403, 20.9712944],
                [106.1350347, 20.9708077],
                [106.1345585, 20.9703817],
                [106.1340921, 20.9699859],
                [106.1336254, 20.9695355],
                [106.1337716, 20.9684465],
                [106.1338038, 20.9675941],
                [106.1338746, 20.9667412],
                [106.1337957, 20.9659813],
                [106.1336248, 20.9655725],
                [106.1335635, 20.9651138],
                [106.134168, 20.9633693],
                [106.1347367, 20.9619876],
                [106.1352681, 20.9607873],
                [106.1355777, 20.960047],
                [106.135924, 20.9599178],
                [106.1364826, 20.9598236],
                [106.1370131, 20.9598472],
                [106.1378043, 20.9599145],
                [106.1388076, 20.9599622],
                [106.1398879, 20.9600092],
                [106.1405055, 20.9600595],
                [106.1411032, 20.9600374],
                [106.1415552, 20.9598803],
                [106.1421506, 20.95955],
                [106.1426891, 20.959347],
                [106.1434019, 20.9592516],
                [106.1441535, 20.9591741],
                [106.1451748, 20.9590585],
                [106.1457335, 20.9589822],
                [106.1457986, 20.9586645],
                [106.1458151, 20.9583018],
                [106.1464132, 20.9583341],
                [106.1470308, 20.9583843],
                [106.1476478, 20.9583802],
                [106.1483606, 20.9582665],
                [106.1485928, 20.9583738],
                [106.1486528, 20.9586453],
                [106.148656, 20.9590622],
                [106.1486221, 20.9596787],
                [106.1486069, 20.9602227],
                [106.1495524, 20.9602707],
                [106.1498995, 20.9602684],
                [106.1499744, 20.9599778],
                [106.1500366, 20.9592794],
                [106.1502199, 20.9580275],
                [106.1504597, 20.956612],
                [106.1506093, 20.9559946],
                [106.1507241, 20.955867],
                [106.1528288, 20.9562153],
                [106.152971, 20.9558944],
                [106.1534293, 20.9552934],
                [106.1538297, 20.9546929],
                [106.154385, 20.9541819],
                [106.1550368, 20.9536701],
                [106.1556497, 20.9531226],
                [106.1565114, 20.9523196],
                [106.1571057, 20.9518627],
                [106.1581977, 20.9509314],
                [106.1581377, 20.950642],
                [106.1578809, 20.9499541],
                [106.1576904, 20.9489858],
                [106.157433, 20.9481177],
                [106.1564287, 20.9478888],
                [106.156195, 20.9475822],
                [106.1555189, 20.9474238],
                [106.1546308, 20.9472667],
                [106.1546046, 20.9463607],
                [106.1545514, 20.9456996],
                [106.1543776, 20.9456825],
                [106.1534906, 20.9456705],
                [106.1527002, 20.9456758],
                [106.1518907, 20.9456994],
                [106.1518857, 20.9450652],
                [106.1519198, 20.9444668],
                [106.1517831, 20.9442503],
                [106.1512435, 20.9442902],
                [106.1502994, 20.944369],
                [106.1502088, 20.9438685],
                [106.1501019, 20.9424927],
                [106.1501341, 20.9416592],
                [106.1511942, 20.9416158],
                [106.1520036, 20.9415742],
                [106.1526976, 20.9415695],
                [106.1525976, 20.9410811],
                [106.1524768, 20.9404481],
                [106.1520893, 20.9389378],
                [106.1517903, 20.9376532],
                [106.1516287, 20.9366759],
                [106.1515652, 20.9359334],
                [106.1515417, 20.93539],
                [106.1516832, 20.9349786],
                [106.1520465, 20.9345958],
                [106.1522376, 20.9343772],
                [106.1526743, 20.9341375],
                [106.1529676, 20.9334687],
                [106.1512094, 20.9334431],
                [106.1500657, 20.9334524],
                [106.1498151, 20.9304454],
                [106.1484792, 20.9288676],
                [106.1488669, 20.9269913],
                [106.1491482, 20.9259749],
                [106.1491938, 20.9257946],
                [106.1493914, 20.9250131],
                [106.149579, 20.9243416],
                [106.1498778, 20.9243485],
                [106.1508994, 20.9243961],
                [106.1516321, 20.9244454],
                [106.1518772, 20.9237372],
                [106.1520432, 20.9231269],
                [106.1521537, 20.922927],
                [106.1540525, 20.923395],
                [106.1546604, 20.9220019],
                [106.154874, 20.9212622],
                [106.1539662, 20.9209967],
                [106.154276, 20.9199166],
                [106.1546156, 20.9189722],
                [106.1553345, 20.9173017],
                [106.1547748, 20.9171234],
                [106.1538971, 20.9169934],
                [106.1531054, 20.9167813],
                [106.1523324, 20.9164967],
                [106.1515394, 20.9161036],
                [106.1510164, 20.9157447],
                [106.1506301, 20.9155585],
                [106.1505716, 20.9155303],
                [106.1499924, 20.9153892],
                [106.1493647, 20.9152004],
                [106.1488423, 20.9149143],
                [106.1482613, 20.914538],
                [106.1475442, 20.9139815],
                [106.1468847, 20.9134066],
                [106.14638, 20.9129211],
                [106.1459968, 20.9132135],
                [106.1454795, 20.9135971],
                [106.1445881, 20.9129332],
                [106.1441033, 20.9125382],
                [106.1438581, 20.9123438],
                [106.1430175, 20.9116401],
                [106.1425915, 20.9113533],
                [106.1428781, 20.9109886],
                [106.1434116, 20.9102061],
                [106.1437446, 20.9096696],
                [106.1435283, 20.9090915],
                [106.1435061, 20.9090582],
                [106.1431192, 20.9084783],
                [106.1427291, 20.907847],
                [106.1422816, 20.9072703],
                [106.1418933, 20.9068745],
                [106.1413606, 20.9064886],
                [106.1408186, 20.9061481],
                [106.1401233, 20.9058992],
                [106.1393893, 20.9056505],
                [106.1384049, 20.9053854],
                [106.1375169, 20.9051377],
                [106.1369074, 20.9047885],
                [106.1365194, 20.9044288],
                [106.1362853, 20.9040319],
                [106.1362056, 20.9036883],
                [106.1361818, 20.9030908],
                [106.1363125, 20.9025285],
                [106.1364339, 20.9020295],
                [106.136623, 20.9015573],
                [106.1369408, 20.9009907],
                [106.1370412, 20.9008118],
                [106.1374798, 20.9002113],
                [106.1378602, 20.8995747],
                [106.1380981, 20.8992019],
                [106.1382293, 20.898712],
                [106.1383022, 20.8981681],
                [106.1383168, 20.8975521],
                [106.1383301, 20.8967551],
                [106.1383621, 20.8958855],
                [106.1384524, 20.8950879],
                [106.1385242, 20.8943991],
                [106.1385969, 20.8937917],
                [106.1388819, 20.8934356],
                [106.1392369, 20.8930776],
                [106.1396477, 20.8929167],
                [106.1408064, 20.8927312],
                [106.1421163, 20.8924754],
                [106.1428297, 20.8922039],
                [106.1435874, 20.8920408],
                [106.1441293, 20.8920273],
                [106.1446812, 20.8918853],
                [106.1450681, 20.8914282],
                [106.1458385, 20.8900992],
                [106.1468065, 20.889085],
                [106.147193, 20.8885785],
                [106.147287, 20.8881037],
                [106.1472824, 20.887511],
                [106.147242, 20.8864739],
                [106.1472981, 20.8852879],
                [106.1472922, 20.8850764],
                [106.1472481, 20.8838676],
                [106.1472686, 20.8826289],
                [106.1470217, 20.8816733],
                [106.1466562, 20.8808595],
                [106.1459316, 20.8801044],
                [106.1450886, 20.8795189],
                [106.1438525, 20.8784575],
                [106.1435175, 20.8777279],
                [106.1436587, 20.876601],
                [106.1437235, 20.8750816],
                [106.1437465, 20.874543],
                [106.1435671, 20.873977],
                [106.1433104, 20.8734666],
                [106.1431712, 20.8730833],
                [106.1432351, 20.872525],
                [106.1433684, 20.8721399],
                [106.1434812, 20.8716269],
                [106.1434586, 20.8712063],
                [106.1432609, 20.8707869],
                [106.1429863, 20.8704778],
                [106.1425755, 20.8701696],
                [106.1423008, 20.8698604],
                [106.1421712, 20.8694314],
                [106.1421277, 20.868828],
                [106.1421209, 20.8679316],
                [106.1421588, 20.8665137],
                [106.1421495, 20.865288],
                [106.1421043, 20.8631572],
                [106.142135, 20.8620777],
                [106.1420877, 20.8609621],
                [106.1420435, 20.8601548],
                [106.1420426, 20.8601392],
                [106.1419139, 20.859516],
                [106.1416927, 20.8585838],
                [106.141552, 20.857999],
                [106.1408331, 20.8568751],
                [106.1403805, 20.8562376],
                [106.1395163, 20.8552005],
                [106.138888, 20.8544911],
                [106.1381999, 20.8535808],
                [106.137787, 20.8530163],
                [106.1374513, 20.8523966],
                [106.1371349, 20.8517399],
                [106.1367889, 20.8509818],
                [106.1363021, 20.8509301],
                [106.1355048, 20.8510086],
                [106.1342599, 20.85109],
                [106.1333065, 20.8511147],
                [106.1326056, 20.8510643],
                [106.1322447, 20.8509478],
                [106.1319511, 20.85073],
                [106.1313461, 20.8504961],
                [106.129923, 20.8501577],
                [106.1292017, 20.8499977],
                [106.1283237, 20.8496923],
                [106.127542, 20.8492399],
                [106.1270534, 20.8489686],
                [106.1262607, 20.8483816],
                [106.127421, 20.8473484],
                [106.1281172, 20.8467212],
                [106.1286613, 20.8463038],
                [106.1290376, 20.8458526],
                [106.1296627, 20.843827],
                [106.129811, 20.84337],
                [106.1287659, 20.8430064],
                [106.1286676, 20.8422131],
                [106.128531, 20.8382494],
                [106.1285261, 20.8375904],
                [106.1284451, 20.8371697],
                [106.1278373, 20.8365877],
                [106.1261016, 20.8348504],
                [106.1251213, 20.833923],
                [106.124748, 20.8334493],
                [106.1247384, 20.8334042],
                [106.1246799, 20.8331293],
                [106.1246771, 20.8327441],
                [106.1247072, 20.8322338],
                [106.1247208, 20.8317875],
                [106.1247181, 20.8314209],
                [106.1246471, 20.8309911],
                [106.1245424, 20.8305774],
                [106.1243692, 20.8300845],
            ],
        ],
    },
    {
        type: 'MultiPolygon',
        coordinates: [
            [
                [
                    [106.4005479, 20.6888857],
                    [106.4006382, 20.6883948],
                    [106.4009265, 20.6880564],
                    [106.4012866, 20.6878703],
                    [106.4016468, 20.687752],
                    [106.4021151, 20.6877015],
                    [106.4028893, 20.6877019],
                    [106.4037175, 20.6876855],
                    [106.4048428, 20.6875591],
                    [106.4059233, 20.6872042],
                    [106.4064047, 20.686991],
                    [106.4068777, 20.6867815],
                    [106.4075081, 20.6863925],
                    [106.4081747, 20.685851],
                    [106.4092464, 20.6849459],
                    [106.4097328, 20.684506],
                    [106.4106154, 20.6838123],
                    [106.4109446, 20.6835335],
                    [106.4117142, 20.6828817],
                    [106.4127319, 20.6821459],
                    [106.4135243, 20.6816553],
                    [106.4143709, 20.6811139],
                    [106.4149673, 20.6806518],
                    [106.4150034, 20.6806252],
                    [106.4164872, 20.6795321],
                    [106.4174417, 20.6788723],
                    [106.4182702, 20.6782631],
                    [106.4191706, 20.6776033],
                    [106.4200982, 20.6768504],
                    [106.420853, 20.67626],
                    [106.4213591, 20.6756151],
                    [106.4220616, 20.674769],
                    [106.4227192, 20.6739651],
                    [106.4232776, 20.6732543],
                    [106.423764, 20.672628],
                    [106.4241965, 20.6719342],
                    [106.4246199, 20.6711641],
                    [106.4258602, 20.6691368],
                    [106.4269818, 20.667657],
                    [106.428148, 20.6662214],
                    [106.4294135, 20.6650175],
                    [106.4303147, 20.6641745],
                    [106.4319104, 20.663176],
                    [106.4357668, 20.6607357],
                    [106.4372493, 20.6598953],
                    [106.4387825, 20.658422],
                    [106.4403527, 20.6571656],
                    [106.4409393, 20.6564789],
                    [106.4427673, 20.6538369],
                    [106.4436995, 20.652389],
                    [106.4437294, 20.6523426],
                    [106.4437618, 20.6522899],
                    [106.4445471, 20.6511691],
                    [106.4452134, 20.6502552],
                    [106.4453271, 20.650111],
                    [106.4461949, 20.6490111],
                    [106.446645, 20.6484695],
                    [106.4473246, 20.647467],
                    [106.4484907, 20.64621],
                    [106.449157, 20.6453806],
                    [106.4499673, 20.6445174],
                    [106.4510385, 20.6434766],
                    [106.4517048, 20.6429011],
                    [106.4523709, 20.642275],
                    [106.4530551, 20.641564],
                    [106.4537629, 20.6408065],
                    [106.4541625, 20.6403201],
                    [106.4545405, 20.6397106],
                    [106.4547746, 20.6393552],
                    [106.4549728, 20.638932],
                    [106.4550184, 20.6385053],
                    [106.4550719, 20.6381617],
                    [106.4550902, 20.6375184],
                    [106.4551084, 20.6367227],
                    [106.4551267, 20.6359947],
                    [106.4550998, 20.6354614],
                    [106.4550818, 20.6350382],
                    [106.4550642, 20.634327],
                    [106.4550198, 20.6333973],
                    [106.4548577, 20.6326595],
                    [106.4547318, 20.6321685],
                    [106.4545701, 20.6314913],
                    [106.4543183, 20.6306955],
                    [106.4540036, 20.6298574],
                    [106.4538058, 20.6294002],
                    [106.4534461, 20.6288076],
                    [106.4531042, 20.6281811],
                    [106.4526816, 20.6273598],
                    [106.4524477, 20.6269873],
                    [106.4521418, 20.6264455],
                    [106.4517822, 20.6258359],
                    [106.4515484, 20.6252433],
                    [106.4512516, 20.6246084],
                    [106.4509998, 20.6241004],
                    [106.4506762, 20.6232538],
                    [106.4503165, 20.6224242],
                    [106.4500917, 20.6218231],
                    [106.4499838, 20.6214675],
                    [106.449912, 20.6209935],
                    [106.4499301, 20.6205872],
                    [106.4500743, 20.6201809],
                    [106.4504342, 20.6198594],
                    [106.4507583, 20.6196732],
                    [106.4514422, 20.6193856],
                    [106.4520811, 20.619208],
                    [106.4527831, 20.6190389],
                    [106.453341, 20.6189374],
                    [106.4541148, 20.6187853],
                    [106.4549068, 20.6185994],
                    [106.4561216, 20.6184049],
                    [106.4567335, 20.6183543],
                    [106.4573094, 20.6182698],
                    [106.4582453, 20.6180499],
                    [106.4590551, 20.6177792],
                    [106.4605761, 20.6172293],
                    [106.461242, 20.6169586],
                    [106.4616559, 20.6167387],
                    [106.4621599, 20.6164679],
                    [106.4626458, 20.6161463],
                    [106.4631858, 20.6158078],
                    [106.4638158, 20.6154525],
                    [106.4641322, 20.6152285],
                    [106.4643377, 20.6150632],
                    [106.4649407, 20.6145469],
                    [106.4654987, 20.614073],
                    [106.4660386, 20.6135822],
                    [106.4664527, 20.6130913],
                    [106.4668848, 20.6125496],
                    [106.4672897, 20.6120333],
                    [106.4675777, 20.6114577],
                    [106.4680818, 20.6105606],
                    [106.468721, 20.6093502],
                    [106.4689371, 20.6087916],
                    [106.469207, 20.6080636],
                    [106.4694052, 20.6074034],
                    [106.4695403, 20.6067855],
                    [106.4696303, 20.6063283],
                    [106.4697564, 20.6056004],
                    [106.4698466, 20.6047879],
                    [106.4698917, 20.6040175],
                    [106.4698919, 20.6027309],
                    [106.4699102, 20.601749],
                    [106.4699193, 20.6012293],
                    [106.4702229, 20.6001066],
                    [106.4703443, 20.5994217],
                    [106.4706045, 20.5989162],
                    [106.4708993, 20.5986879],
                    [106.4717056, 20.5981335],
                    [106.472139, 20.5980357],
                    [106.4729886, 20.5980033],
                    [106.4751731, 20.5980525],
                    [106.4774354, 20.5982649],
                    [106.4780421, 20.5982975],
                    [106.4790013, 20.5983442],
                    [106.4799455, 20.598511],
                    [106.4807127, 20.5987887],
                    [106.4812439, 20.5990664],
                    [106.4818634, 20.599455],
                    [106.4823061, 20.599927],
                    [106.4826895, 20.6003157],
                    [106.4829847, 20.6006211],
                    [106.4833958, 20.601067],
                    [106.4846646, 20.6024838],
                    [106.4853586, 20.6033413],
                    [106.4855956, 20.6036345],
                    [106.486112, 20.6042733],
                    [106.488164, 20.6069996],
                    [106.4886628, 20.6077388],
                    [106.4889661, 20.6081345],
                    [106.4893, 20.608766],
                    [106.4896911, 20.6095547],
                    [106.4899732, 20.6103835],
                    [106.4901848, 20.6111792],
                    [106.4904669, 20.6119087],
                    [106.4906785, 20.6125055],
                    [106.4909606, 20.6134338],
                    [106.491137, 20.6138317],
                    [106.491278, 20.61413],
                    [106.4914895, 20.614528],
                    [106.4916964, 20.6150671],
                    [106.4919832, 20.6156443],
                    [106.4922635, 20.6160279],
                    [106.4925183, 20.6163636],
                    [106.4926968, 20.6164954],
                    [106.4931173, 20.6166392],
                    [106.4935251, 20.6166273],
                    [106.4938437, 20.6165554],
                    [106.4942388, 20.6163996],
                    [106.4947231, 20.6163037],
                    [106.4959401, 20.616076],
                    [106.496276, 20.6160365],
                    [106.4967557, 20.6159801],
                    [106.4976222, 20.6158124],
                    [106.4989668, 20.6157044],
                    [106.5002285, 20.6156206],
                    [106.5005853, 20.6155846],
                    [106.5015984, 20.6155246],
                    [106.5020628, 20.6154788],
                    [106.5040102, 20.6152867],
                    [106.5060032, 20.6150243],
                    [106.5078228, 20.6147633],
                    [106.508684, 20.6145479],
                    [106.510064, 20.6141484],
                    [106.5108638, 20.6138516],
                    [106.5117477, 20.6135152],
                    [106.5126105, 20.6131195],
                    [106.5137154, 20.6125586],
                    [106.5143258, 20.6122616],
                    [106.5150833, 20.6118656],
                    [106.5156937, 20.6114497],
                    [106.5161988, 20.6110141],
                    [106.5165775, 20.6106182],
                    [106.5170194, 20.6101825],
                    [106.5175772, 20.6094598],
                    [106.5178717, 20.6089846],
                    [106.5182716, 20.6083313],
                    [106.5184819, 20.6077768],
                    [106.5186292, 20.6073412],
                    [106.5186923, 20.6068067],
                    [106.5187463, 20.6063614],
                    [106.5188121, 20.6054402],
                    [106.5189316, 20.604416],
                    [106.5191288, 20.6031665],
                    [106.5192604, 20.6027388],
                    [106.5194519, 20.6025362],
                    [106.519781, 20.6024461],
                    [106.5200083, 20.6024348],
                    [106.5202955, 20.6025136],
                    [106.5205826, 20.6026261],
                    [106.521145, 20.602671],
                    [106.5215638, 20.6026822],
                    [106.521875, 20.6026597],
                    [106.5230954, 20.602547],
                    [106.5247408, 20.6024005],
                    [106.5254946, 20.6024116],
                    [106.5266673, 20.6024902],
                    [106.5276176, 20.6026195],
                    [106.5288153, 20.6027825],
                    [106.5296163, 20.6029821],
                    [106.5298084, 20.6030299],
                    [106.5317291, 20.6037162],
                    [106.5322198, 20.6040088],
                    [106.5330591, 20.6043478],
                    [106.5339558, 20.6048019],
                    [106.5348297, 20.6052127],
                    [106.5358645, 20.60571],
                    [106.5364625, 20.6060127],
                    [106.5371064, 20.6062938],
                    [106.5379573, 20.606856],
                    [106.5391646, 20.607602],
                    [106.5396245, 20.607948],
                    [106.5400385, 20.6083589],
                    [106.5403837, 20.6088563],
                    [106.5407058, 20.6096133],
                    [106.5410509, 20.6105217],
                    [106.5414077, 20.6117222],
                    [106.5416148, 20.6126524],
                    [106.5417991, 20.6137339],
                    [106.5419259, 20.6148694],
                    [106.5418801, 20.6158429],
                    [106.5418804, 20.616773],
                    [106.5418116, 20.6176816],
                    [106.5417314, 20.6185144],
                    [106.5416166, 20.6191416],
                    [106.5415018, 20.6196392],
                    [106.5414388, 20.6201066],
                    [106.5415082, 20.6218241],
                    [106.5417114, 20.6226878],
                    [106.5423705, 20.623185],
                    [106.5430256, 20.6235089],
                    [106.5434738, 20.6237033],
                    [106.5438188, 20.6240273],
                    [106.5443017, 20.6248372],
                    [106.5446754, 20.6253953],
                    [106.5452463, 20.6259702],
                    [106.5457354, 20.6264302],
                    [106.5463739, 20.6268133],
                    [106.5471145, 20.6271517],
                    [106.5476715, 20.6273433],
                    [106.5482149, 20.6274709],
                    [106.5491253, 20.6275857],
                    [106.5498181, 20.6276238],
                    [106.5509525, 20.6276553],
                    [106.5515095, 20.6276297],
                    [106.5519578, 20.6275273],
                    [106.5522838, 20.6274122],
                    [106.5528679, 20.6272842],
                    [106.5538324, 20.6271689],
                    [106.5555646, 20.6271109],
                    [106.5562573, 20.6271234],
                    [106.5569367, 20.6270849],
                    [106.5577041, 20.6269504],
                    [106.5582507, 20.6267936],
                    [106.5593473, 20.6265355],
                    [106.5602247, 20.6261744],
                    [106.5610196, 20.6255814],
                    [106.5618987, 20.6248768],
                    [106.5626094, 20.6240086],
                    [106.5627945, 20.6237515],
                    [106.5631848, 20.6232095],
                    [106.5637056, 20.6226423],
                    [106.5643359, 20.6218688],
                    [106.5648291, 20.6213016],
                    [106.5655965, 20.6205023],
                    [106.5659973, 20.6202207],
                    [106.566341, 20.6200892],
                    [106.5667279, 20.6199981],
                    [106.5671792, 20.6199172],
                    [106.5675982, 20.6198665],
                    [106.5682107, 20.6198562],
                    [106.568619, 20.6198762],
                    [106.5689628, 20.6199468],
                    [106.5691753, 20.6200174],
                    [106.5701831, 20.6203055],
                    [106.5711069, 20.6206716],
                    [106.5712294, 20.6207202],
                    [106.5719397, 20.6210717],
                    [106.5727459, 20.6214592],
                    [106.5737876, 20.6221281],
                    [106.57419, 20.6223775],
                    [106.5751028, 20.6228406],
                    [106.5764691, 20.6235428],
                    [106.5772156, 20.6238858],
                    [106.5780257, 20.6242287],
                    [106.578804, 20.6246314],
                    [106.5822767, 20.6257619],
                    [106.5829916, 20.6261017],
                    [106.584081, 20.6265641],
                    [106.5852094, 20.6272253],
                    [106.5861522, 20.6279059],
                    [106.5871383, 20.6288216],
                    [106.5884816, 20.6309404],
                    [106.588898, 20.6316071],
                    [106.5892408, 20.6321589],
                    [106.5896083, 20.6327107],
                    [106.5899022, 20.6332626],
                    [106.5901471, 20.6335844],
                    [106.5906245, 20.6341521],
                    [106.5909919, 20.6345893],
                    [106.5913102, 20.6349804],
                    [106.5916332, 20.6353188],
                    [106.5928294, 20.636396],
                    [106.5938199, 20.6370677],
                    [106.5952444, 20.6377624],
                    [106.5969945, 20.6384142],
                    [106.5982761, 20.638875],
                    [106.6002175, 20.639449],
                    [106.6012813, 20.6397658],
                    [106.6021197, 20.6388062],
                    [106.6027587, 20.6379472],
                    [106.6037382, 20.6363962],
                    [106.6044423, 20.6350888],
                    [106.6053507, 20.6334388],
                    [106.6060436, 20.6323191],
                    [106.6067367, 20.6313859],
                    [106.6078179, 20.6299578],
                    [106.6089402, 20.6288131],
                    [106.6099959, 20.6279609],
                    [106.6110848, 20.6272781],
                    [106.6118855, 20.6268325],
                    [106.6130914, 20.6262845],
                    [106.6148668, 20.6257089],
                    [106.6166369, 20.6252709],
                    [106.6189382, 20.6249857],
                    [106.6204972, 20.6249606],
                    [106.622138, 20.6250943],
                    [106.623574, 20.6255488],
                    [106.6249127, 20.6260087],
                    [106.6262264, 20.6264777],
                    [106.6277541, 20.6270059],
                    [106.629397, 20.627469],
                    [106.630917, 20.6276186],
                    [106.6345119, 20.6274074],
                    [106.636016, 20.6271638],
                    [106.6390881, 20.6257991],
                    [106.6433599, 20.6234745],
                    [106.6523761, 20.6157388],
                    [106.6568545, 20.6108188],
                    [106.6622599, 20.6032427],
                    [106.8355086, 20.4654291],
                    [106.8524265, 20.4673859],
                    [106.8709606, 20.4711276],
                    [106.8890604, 20.4764022],
                    [106.9065814, 20.4831647],
                    [106.9233847, 20.4913579],
                    [106.9393383, 20.5009128],
                    [106.9541685, 20.5003669],
                    [106.9654389, 20.4864757],
                    [106.9779606, 20.473548],
                    [106.9916422, 20.4616846],
                    [107.0063826, 20.4509793],
                    [107.0220719, 20.4415176],
                    [107.0385915, 20.433376],
                    [107.0558154, 20.4266208],
                    [107.0744444, 20.4210299],
                    [107.0926736, 20.4172028],
                    [107.1289725, 20.4121158],
                    [107.1484717, 20.411344],
                    [107.186704, 20.4131974],
                    [107.2073622, 20.4121774],
                    [107.2276938, 20.4129492],
                    [107.2462143, 20.4152583],
                    [107.2644434, 20.4190853],
                    [107.2822388, 20.4243985],
                    [107.3759991, 20.4646312],
                    [107.1149996, 20.7180273],
                    [107.1150149, 20.7358871],
                    [107.1150259, 20.7487033],
                    [107.1134981, 20.7616573],
                    [107.1128801, 20.7664245],
                    [107.1129123, 20.7716088],
                    [107.1124293, 20.7734911],
                    [107.1118724, 20.7768384],
                    [107.1117959, 20.7781614],
                    [107.1117173, 20.7801719],
                    [107.1119241, 20.7815936],
                    [107.1121995, 20.7834164],
                    [107.1125442, 20.7853217],
                    [107.1128021, 20.786785],
                    [107.1128882, 20.7884088],
                    [107.1126826, 20.7898561],
                    [107.1121508, 20.7926193],
                    [107.1120099, 20.7954314],
                    [107.111766, 20.7975695],
                    [107.1114388, 20.7990269],
                    [107.1107327, 20.8004572],
                    [107.1096849, 20.8017934],
                    [107.1087058, 20.8032258],
                    [107.1079149, 20.8047428],
                    [107.1073364, 20.8067773],
                    [107.1070998, 20.8088239],
                    [107.1064826, 20.8111013],
                    [107.1061045, 20.8119458],
                    [107.1058508, 20.81269],
                    [107.1057241, 20.8134857],
                    [107.1053193, 20.81441],
                    [107.1046397, 20.8153748],
                    [107.1039601, 20.81573],
                    [107.1027554, 20.8159107],
                    [107.1016506, 20.8157907],
                    [107.1002727, 20.8155894],
                    [107.0989291, 20.8154202],
                    [107.0976496, 20.8155632],
                    [107.0966923, 20.8162664],
                    [107.0955291, 20.8169053],
                    [107.0943658, 20.8175925],
                    [107.0930309, 20.8180549],
                    [107.0923947, 20.8183965],
                    [107.0918787, 20.8190911],
                    [107.0912426, 20.8192883],
                    [107.0903974, 20.8194673],
                    [107.0892901, 20.8198146],
                    [107.0869504, 20.8213031],
                    [107.085609, 20.8223041],
                    [107.0831002, 20.8241073],
                    [107.0810094, 20.8259801],
                    [107.0792904, 20.8273508],
                    [107.0774855, 20.8287856],
                    [107.0754379, 20.8299696],
                    [107.0736134, 20.8309931],
                    [107.0721942, 20.8319324],
                    [107.0710554, 20.8326587],
                    [107.0696763, 20.8331926],
                    [107.0683014, 20.8335581],
                    [107.0669951, 20.8340199],
                    [107.0658299, 20.8344535],
                    [107.065142, 20.8349443],
                    [107.0643842, 20.8355408],
                    [107.0634181, 20.8358034],
                    [107.0623935, 20.8363106],
                    [107.0615352, 20.837006],
                    [107.0611497, 20.8380842],
                    [107.0604552, 20.839002],
                    [107.0595673, 20.8399992],
                    [107.0582674, 20.8408681],
                    [107.0568024, 20.8419667],
                    [107.0554337, 20.8429854],
                    [107.0541023, 20.8438048],
                    [107.0527459, 20.8444496],
                    [107.0513475, 20.845344],
                    [107.0502261, 20.8459627],
                    [107.0486147, 20.8475081],
                    [107.0476585, 20.8485728],
                    [107.046338, 20.8494807],
                    [107.0450519, 20.8506773],
                    [107.0443335, 20.851691],
                    [107.0432484, 20.8525626],
                    [107.0421119, 20.8538353],
                    [107.040784, 20.8555659],
                    [107.040692, 20.8571681],
                    [107.0406043, 20.8589381],
                    [107.0401219, 20.8601949],
                    [107.0395313, 20.8612092],
                    [107.0389029, 20.8618623],
                    [107.0382274, 20.8623435],
                    [107.0372092, 20.8625517],
                    [107.0361705, 20.8627046],
                    [107.0351988, 20.8630343],
                    [107.0339525, 20.8635244],
                    [107.032715, 20.86389],
                    [107.0310998, 20.8641915],
                    [107.0296179, 20.8644134],
                    [107.0284794, 20.8642825],
                    [107.0277354, 20.8638436],
                    [107.0269056, 20.8638379],
                    [107.0258624, 20.8639651],
                    [107.0248162, 20.8640491],
                    [107.0239761, 20.8636839],
                    [107.0212658, 20.863563],
                    [107.0188902, 20.8632843],
                    [107.0169491, 20.8630993],
                    [107.0144425, 20.862843],
                    [107.0117633, 20.8626913],
                    [107.0094274, 20.8624113],
                    [107.0080369, 20.8622103],
                    [107.0070977, 20.8622661],
                    [107.0054265, 20.8626148],
                    [107.003824, 20.8628994],
                    [107.001771, 20.863138],
                    [107.0005186, 20.8630989],
                    [106.9995078, 20.8628489],
                    [106.9981654, 20.8627719],
                    [106.9966272, 20.8630553],
                    [106.9952625, 20.8635408],
                    [106.9935242, 20.8644025],
                    [106.9920801, 20.8652449],
                    [106.9903943, 20.8660605],
                    [106.9886353, 20.8668929],
                    [106.9869679, 20.867685],
                    [106.9856576, 20.8681522],
                    [106.9840325, 20.8687988],
                    [106.9828138, 20.8692806],
                    [106.9811599, 20.86982],
                    [106.9798647, 20.8706274],
                    [106.9784663, 20.8715103],
                    [106.9766056, 20.872019],
                    [106.9750693, 20.8726153],
                    [106.9730244, 20.8734031],
                    [106.9706828, 20.8738668],
                    [106.9690353, 20.874265],
                    [106.9667912, 20.8753677],
                    [106.9652928, 20.8760111],
                    [106.9644334, 20.8766404],
                    [106.9634372, 20.8775332],
                    [106.9622696, 20.8782334],
                    [106.9611733, 20.8785953],
                    [106.959948, 20.8790877],
                    [106.9592685, 20.8794567],
                    [106.9573758, 20.8801676],
                    [106.956215, 20.8803335],
                    [106.9548783, 20.8804117],
                    [106.9538795, 20.8805934],
                    [106.9526085, 20.8807013],
                    [106.9510337, 20.8808281],
                    [106.9495375, 20.8809016],
                    [106.9481838, 20.8809568],
                    [106.947139, 20.8810076],
                    [106.9461825, 20.8807781],
                    [106.9451067, 20.8805312],
                    [106.9442329, 20.8800524],
                    [106.9436476, 20.8795158],
                    [106.9428379, 20.8783981],
                    [106.9418674, 20.8771327],
                    [106.9402794, 20.8750868],
                    [106.9391633, 20.8737418],
                    [106.9383568, 20.8728683],
                    [106.9356334, 20.8702107],
                    [106.9341989, 20.8688418],
                    [106.9328362, 20.8674316],
                    [106.9316341, 20.8661417],
                    [106.9303147, 20.8647172],
                    [106.9298737, 20.864152],
                    [106.9282976, 20.8618751],
                    [106.9277059, 20.8608226],
                    [106.9267628, 20.8594211],
                    [106.9246264, 20.8562813],
                    [106.9232716, 20.8543413],
                    [106.9225075, 20.8533723],
                    [106.920582, 20.850882],
                    [106.9191719, 20.8491326],
                    [106.9163043, 20.8452949],
                    [106.9153922, 20.8440559],
                    [106.9137475, 20.8420375],
                    [106.9117085, 20.8396841],
                    [106.9105078, 20.8384754],
                    [106.9090415, 20.8368352],
                    [106.9059688, 20.8340152],
                    [106.9033424, 20.8320578],
                    [106.9016258, 20.8304053],
                    [106.9006817, 20.8283035],
                    [106.9001667, 20.8267151],
                    [106.899892, 20.8242602],
                    [106.8986732, 20.8224953],
                    [106.8952572, 20.8197195],
                    [106.885833, 20.8280468],
                    [106.8798591, 20.8324268],
                    [106.8770954, 20.8356356],
                    [106.8698645, 20.8317809],
                    [106.8679444, 20.832756],
                    [106.8672429, 20.8332683],
                    [106.8666444, 20.8338769],
                    [106.8659418, 20.834937],
                    [106.8657034, 20.8352143],
                    [106.8653755, 20.8354442],
                    [106.865035, 20.8355544],
                    [106.8640806, 20.8356954],
                    [106.8630922, 20.8358983],
                    [106.8622606, 20.8362332],
                    [106.8618554, 20.8365289],
                    [106.861361, 20.8366928],
                    [106.8608323, 20.8366963],
                    [106.8602966, 20.8365498],
                    [106.8585044, 20.8357434],
                    [106.8578199, 20.835581],
                    [106.8572135, 20.8353938],
                    [106.856704, 20.8351446],
                    [106.8554078, 20.8340726],
                    [106.8549942, 20.8335086],
                    [106.8548553, 20.8329125],
                    [106.8548586, 20.8322158],
                    [106.854689, 20.8312213],
                    [106.8544164, 20.8301305],
                    [106.8540266, 20.8293857],
                    [106.8533878, 20.8289325],
                    [106.8526055, 20.8286037],
                    [106.850399, 20.8283384],
                    [106.8498733, 20.8281904],
                    [106.8494616, 20.827924],
                    [106.8492653, 20.8275274],
                    [106.8492336, 20.8270775],
                    [106.8495693, 20.8254614],
                    [106.849791, 20.823715],
                    [106.8495313, 20.8215246],
                    [106.8432659, 20.8179987],
                    [106.8357165, 20.8138179],
                    [106.8325938, 20.8120886],
                    [106.8303428, 20.8169799],
                    [106.8287366, 20.8201637],
                    [106.8271477, 20.8232031],
                    [106.8257064, 20.8255708],
                    [106.8242889, 20.8270515],
                    [106.8223433, 20.8287879],
                    [106.8192961, 20.8303287],
                    [106.8160966, 20.8311226],
                    [106.8008891, 20.8311829],
                    [106.7968408, 20.831697],
                    [106.792411, 20.8328418],
                    [106.7889097, 20.8342155],
                    [106.7819745, 20.8377771],
                    [106.776653, 20.8410339],
                    [106.7711938, 20.8446586],
                    [106.767743, 20.8472372],
                    [106.765699, 20.848691],
                    [106.7639879, 20.8504777],
                    [106.7625429, 20.8521075],
                    [106.7608515, 20.8548551],
                    [106.759127, 20.8585486],
                    [106.7578168, 20.8619208],
                    [106.7567651, 20.8651467],
                    [106.7560595, 20.8675393],
                    [106.7551214, 20.870845],
                    [106.7546287, 20.8726389],
                    [106.7542089, 20.8743943],
                    [106.7538127, 20.8765201],
                    [106.7534226, 20.8789968],
                    [106.7532279, 20.8810715],
                    [106.7532287, 20.8824226],
                    [106.7533543, 20.8837733],
                    [106.7536746, 20.8851378],
                    [106.7541836, 20.8866687],
                    [106.7547914, 20.8881464],
                    [106.7558251, 20.8899509],
                    [106.756984, 20.8914869],
                    [106.7610189, 20.896447],
                    [106.7647613, 20.9008805],
                    [106.7657666, 20.9021652],
                    [106.7671288, 20.9042703],
                    [106.7674561, 20.9049257],
                    [106.7683666, 20.9064551],
                    [106.7685902, 20.9069253],
                    [106.7689236, 20.9076266],
                    [106.7693582, 20.9090348],
                    [106.7699419, 20.9106966],
                    [106.7700761, 20.9114409],
                    [106.7704285, 20.9129676],
                    [106.7705659, 20.9139866],
                    [106.7706205, 20.9150848],
                    [106.7706708, 20.9176133],
                    [106.7707033, 20.9192459],
                    [106.7706246, 20.922847],
                    [106.7705353, 20.9279936],
                    [106.770459, 20.9317059],
                    [106.7702712, 20.9353333],
                    [106.7702638, 20.9354758],
                    [106.7701224, 20.9392256],
                    [106.7698739, 20.941892],
                    [106.7698421, 20.9432163],
                    [106.7698097, 20.9452823],
                    [106.7696833, 20.9475896],
                    [106.7696993, 20.9489585],
                    [106.7696398, 20.9506305],
                    [106.7694685, 20.9554468],
                    [106.7691381, 20.9599901],
                    [106.7690766, 20.9612879],
                    [106.769091, 20.9625128],
                    [106.7689501, 20.9635953],
                    [106.7688102, 20.9647498],
                    [106.7686277, 20.966101],
                    [106.7685276, 20.9668426],
                    [106.768467, 20.9682124],
                    [106.7682359, 20.9714216],
                    [106.7681484, 20.9732053],
                    [106.7680918, 20.9742078],
                    [106.7679789, 20.975676],
                    [106.7671554, 20.9774862],
                    [106.7661759, 20.9790817],
                    [106.7647398, 20.9810424],
                    [106.7627308, 20.9832611],
                    [106.7621866, 20.9840528],
                    [106.7609149, 20.9855859],
                    [106.7604374, 20.9862055],
                    [106.758116, 20.989218],
                    [106.7575029, 20.9898551],
                    [106.7553844, 20.9920566],
                    [106.7526443, 20.9941747],
                    [106.750164, 20.9955335],
                    [106.7491724, 20.9961202],
                    [106.7473395, 20.9970038],
                    [106.7427552, 20.9990031],
                    [106.7417426, 20.9993211],
                    [106.7403226, 20.999652],
                    [106.738548, 20.9997047],
                    [106.7362417, 20.9995346],
                    [106.7334328, 20.9989487],
                    [106.7333754, 20.9989367],
                    [106.7322213, 20.998696],
                    [106.7297724, 20.9980624],
                    [106.7292067, 20.9979199],
                    [106.727044, 20.9973751],
                    [106.7244054, 20.9972001],
                    [106.7237535, 20.997199],
                    [106.7229951, 20.9971978],
                    [106.7197642, 20.9976621],
                    [106.7169881, 20.9980843],
                    [106.7140296, 20.9987624],
                    [106.71151, 20.9990034],
                    [106.7112626, 20.9990361],
                    [106.7103158, 20.9991278],
                    [106.7086959, 20.9991872],
                    [106.7076313, 20.9994206],
                    [106.706756, 20.9996075],
                    [106.7060423, 20.9998702],
                    [106.7041833, 21.0005908],
                    [106.7030764, 21.0009785],
                    [106.7020424, 21.0013655],
                    [106.700346, 21.0019817],
                    [106.6992571, 21.0023351],
                    [106.6982826, 21.0027127],
                    [106.6892172, 21.0045752],
                    [106.6865516, 21.0056287],
                    [106.6860074, 21.0058912],
                    [106.6842221, 21.0067137],
                    [106.6830985, 21.0072384],
                    [106.6814311, 21.0080083],
                    [106.679909, 21.0087424],
                    [106.6781325, 21.0095475],
                    [106.67661, 21.0102474],
                    [106.6752859, 21.0107741],
                    [106.674351, 21.0110744],
                    [106.6729447, 21.0115819],
                    [106.6718195, 21.0119693],
                    [106.6706037, 21.0124089],
                    [106.6689713, 21.0130232],
                    [106.6679379, 21.0134613],
                    [106.6659344, 21.0142855],
                    [106.66501, 21.0146882],
                    [106.6639223, 21.015161],
                    [106.6628524, 21.0155823],
                    [106.6616188, 21.0160394],
                    [106.6601227, 21.016636],
                    [106.6587356, 21.0172145],
                    [106.6585288, 21.0173002],
                    [106.6579742, 21.0175301],
                    [106.6563579, 21.0179737],
                    [106.6552489, 21.0183636],
                    [106.6550062, 21.018449],
                    [106.6540816, 21.0188346],
                    [106.6531923, 21.0191342],
                    [106.6522115, 21.0194005],
                    [106.6512203, 21.0195299],
                    [106.6504383, 21.0196402],
                    [106.6497462, 21.0196469],
                    [106.6487436, 21.019571],
                    [106.6476403, 21.019462],
                    [106.6469281, 21.0192976],
                    [106.6463795, 21.0190976],
                    [106.6455379, 21.0187634],
                    [106.6450619, 21.0185455],
                    [106.6446397, 21.0182585],
                    [106.6441243, 21.01775],
                    [106.6434509, 21.0169691],
                    [106.6428966, 21.0162555],
                    [106.6421948, 21.0153722],
                    [106.6412004, 21.014389],
                    [106.6403554, 21.0137467],
                    [106.6396586, 21.0133083],
                    [106.638633, 21.0128047],
                    [106.6373533, 21.0123719],
                    [106.6361844, 21.0120751],
                    [106.6348344, 21.0118827],
                    [106.6337941, 21.0116872],
                    [106.6318171, 21.0116206],
                    [106.6304512, 21.0116337],
                    [106.6284753, 21.0116697],
                    [106.6278377, 21.0116587],
                    [106.6270547, 21.0116833],
                    [106.6261809, 21.0117259],
                    [106.6249364, 21.0120117],
                    [106.6239488, 21.012356],
                    [106.6238657, 21.0123814],
                    [106.6232862, 21.012695],
                    [106.6229061, 21.0129041],
                    [106.6224716, 21.013165],
                    [106.6215621, 21.0141151],
                    [106.6208655, 21.0148916],
                    [106.6200846, 21.015695],
                    [106.6192545, 21.0165495],
                    [106.6171278, 21.0184256],
                    [106.6161607, 21.0190796],
                    [106.6144806, 21.0197261],
                    [106.6128805, 21.0200877],
                    [106.6115432, 21.0202876],
                    [106.6109628, 21.0201969],
                    [106.6102613, 21.0199786],
                    [106.6095597, 21.0196804],
                    [106.6080308, 21.0187972],
                    [106.6062708, 21.0180551],
                    [106.6038047, 21.0169489],
                    [106.6027345, 21.0166536],
                    [106.6018375, 21.0165073],
                    [106.6009038, 21.0165409],
                    [106.5995848, 21.0167832],
                    [106.5963065, 21.0178216],
                    [106.5956407, 21.0179166],
                    [106.5949788, 21.0178983],
                    [106.594356, 21.0177631],
                    [106.5930517, 21.0171488],
                    [106.5925661, 21.0168344],
                    [106.5922527, 21.0164871],
                    [106.5918154, 21.0158696],
                    [106.5912454, 21.0152389],
                    [106.5907053, 21.0149069],
                    [106.5900993, 21.0147624],
                    [106.5895031, 21.0147194],
                    [106.5888971, 21.0147716],
                    [106.5882466, 21.014911],
                    [106.5844108, 21.0160187],
                    [106.5814506, 21.0166013],
                    [106.5804753, 21.0166964],
                    [106.5796528, 21.0166598],
                    [106.5789752, 21.0164953],
                    [106.5784073, 21.016148],
                    [106.5777924, 21.0154679],
                    [106.5774046, 21.0149158],
                    [106.5771148, 21.0144332],
                    [106.5759136, 21.0122631],
                    [106.575562, 21.0117914],
                    [106.5751218, 21.0113957],
                    [106.5746723, 21.0110891],
                    [106.5741064, 21.0108826],
                    [106.5735451, 21.0107956],
                    [106.57307, 21.0108174],
                    [106.5721579, 21.0109624],
                    [106.5712008, 21.0112535],
                    [106.5704785, 21.011638],
                    [106.569817, 21.012125],
                    [106.5691929, 21.0128641],
                    [106.5688931, 21.0133897],
                    [106.5687383, 21.0138545],
                    [106.5679098, 21.0168096],
                    [106.5674424, 21.017842],
                    [106.566903, 21.0187415],
                    [106.5665761, 21.0190853],
                    [106.5662023, 21.019274],
                    [106.5656705, 21.0193283],
                    [106.5652274, 21.0192456],
                    [106.5648341, 21.0190259],
                    [106.5644713, 21.0185166],
                    [106.5642012, 21.0176425],
                    [106.5640895, 21.0166903],
                    [106.5640355, 21.0151325],
                    [106.5638511, 21.0132878],
                    [106.5637523, 21.0126699],
                    [106.5635777, 21.0118705],
                    [106.5633044, 21.0112248],
                    [106.5629322, 21.0106407],
                    [106.5624678, 21.0100934],
                    [106.5618255, 21.0096291],
                    [106.5610614, 21.0091987],
                    [106.5591644, 21.0081349],
                    [106.558453, 21.0075599],
                    [106.5579293, 21.0070526],
                    [106.5576592, 21.006576],
                    [106.557343, 21.0058377],
                    [106.5568306, 21.0041071],
                    [106.5565185, 21.0036201],
                    [106.5557267, 21.002707],
                    [106.5552114, 21.0010773],
                    [106.5547858, 20.9993737],
                    [106.5546689, 20.9987737],
                    [106.5546182, 20.9976248],
                    [106.5546739, 20.996785],
                    [106.5548829, 20.9955155],
                    [106.5552387, 20.9944135],
                    [106.5559913, 20.9924868],
                    [106.5567515, 20.9911637],
                    [106.5573885, 20.9904759],
                    [106.5586348, 20.9896381],
                    [106.5615801, 20.9875867],
                    [106.5622092, 20.9870547],
                    [106.5626874, 20.9864114],
                    [106.5630585, 20.9857081],
                    [106.5633243, 20.9851082],
                    [106.5637921, 20.9836692],
                    [106.5641808, 20.9827636],
                    [106.5646723, 20.9818603],
                    [106.5653743, 20.9808202],
                    [106.5658974, 20.9801882],
                    [106.5667888, 20.9795147],
                    [106.5677017, 20.9788667],
                    [106.5686985, 20.9783187],
                    [106.5696953, 20.9779969],
                    [106.5718658, 20.9773967],
                    [106.574884, 20.9765791],
                    [106.5767937, 20.976092],
                    [106.5827568, 20.9749123],
                    [106.5838514, 20.9745774],
                    [106.5844988, 20.9741947],
                    [106.5850112, 20.9737293],
                    [106.5853466, 20.9732248],
                    [106.585477, 20.9726768],
                    [106.585491, 20.9718679],
                    [106.5853694, 20.9698446],
                    [106.5851423, 20.9666998],
                    [106.5849174, 20.9649579],
                    [106.584908, 20.9634402],
                    [106.5850199, 20.9624319],
                    [106.5852249, 20.9615163],
                    [106.5857179, 20.9604146],
                    [106.5862828, 20.9594939],
                    [106.5868866, 20.9587076],
                    [106.5874682, 20.958118],
                    [106.5889527, 20.9570938],
                    [106.5898777, 20.9564938],
                    [106.5908324, 20.9556506],
                    [106.5914647, 20.954925],
                    [106.5920201, 20.9541659],
                    [106.592416, 20.9533395],
                    [106.5926396, 20.9524913],
                    [106.5926396, 20.951717],
                    [106.592473, 20.9508228],
                    [106.592091, 20.9494018],
                    [106.5918868, 20.9484421],
                    [106.5916926, 20.9463702],
                    [106.5903043, 20.9457874],
                    [106.5895129, 20.9452383],
                    [106.5886172, 20.9442707],
                    [106.5879039, 20.9433134],
                    [106.5871033, 20.9422289],
                    [106.5865029, 20.941257],
                    [106.5855788, 20.939408],
                    [106.5848804, 20.9382142],
                    [106.584292, 20.9369047],
                    [106.5836305, 20.935871],
                    [106.5832629, 20.9351818],
                    [106.5826015, 20.9342169],
                    [106.582087, 20.9334588],
                    [106.5813522, 20.9327699],
                    [106.5806909, 20.9321497],
                    [106.5800962, 20.931653],
                    [106.5794722, 20.9312285],
                    [106.57833, 20.9307756],
                    [106.5775351, 20.9306154],
                    [106.5767502, 20.930512],
                    [106.5753972, 20.9306636],
                    [106.5745219, 20.9308435],
                    [106.5738982, 20.9310326],
                    [106.5724749, 20.9316942],
                    [106.5718915, 20.9320911],
                    [106.5714691, 20.9324785],
                    [106.5709211, 20.933253],
                    [106.57071, 20.9336686],
                    [106.5704788, 20.934188],
                    [106.5703234, 20.9351135],
                    [106.5704143, 20.9356896],
                    [106.5706057, 20.9362939],
                    [106.5710235, 20.9368588],
                    [106.5715066, 20.9373966],
                    [106.5720502, 20.9378021],
                    [106.5725535, 20.9381605],
                    [106.5732076, 20.9385661],
                    [106.5738921, 20.9389244],
                    [106.5753989, 20.9396456],
                    [106.5768006, 20.9406742],
                    [106.5779492, 20.9418771],
                    [106.5784729, 20.9426686],
                    [106.5787098, 20.9435236],
                    [106.5787864, 20.9447545],
                    [106.5785532, 20.9460771],
                    [106.577739, 20.9473179],
                    [106.5769186, 20.9481011],
                    [106.5764461, 20.9485291],
                    [106.5755304, 20.9493198],
                    [106.5748758, 20.9498557],
                    [106.5733057, 20.9507975],
                    [106.5724503, 20.9512165],
                    [106.5715161, 20.9515206],
                    [106.5703626, 20.9517369],
                    [106.5693901, 20.9517587],
                    [106.5686279, 20.9516501],
                    [106.5680325, 20.9513215],
                    [106.5676187, 20.9508498],
                    [106.5674302, 20.9503376],
                    [106.5674461, 20.9498363],
                    [106.567621, 20.949038],
                    [106.567796, 20.9482398],
                    [106.567935, 20.9476974],
                    [106.5681955, 20.9466814],
                    [106.5684355, 20.9453685],
                    [106.5685869, 20.9443392],
                    [106.5686753, 20.9433362],
                    [106.5687025, 20.9425778],
                    [106.5686809, 20.9417899],
                    [106.5685733, 20.9407222],
                    [106.5683803, 20.9396399],
                    [106.5680378, 20.9382771],
                    [106.5676315, 20.9375355],
                    [106.5672252, 20.9369343],
                    [106.5668616, 20.9364133],
                    [106.5663912, 20.9357722],
                    [106.5654686, 20.9348884],
                    [106.5647773, 20.934282],
                    [106.5641359, 20.9338007],
                    [106.5634307, 20.9334399],
                    [106.56202, 20.9326983],
                    [106.561197, 20.9322673],
                    [106.5605133, 20.9320269],
                    [106.559858, 20.9318716],
                    [106.5592205, 20.9318233],
                    [106.5591545, 20.9318078],
                    [106.5584635, 20.9317627],
                    [106.5578574, 20.9317554],
                    [106.5572448, 20.9318123],
                    [106.5569852, 20.9319217],
                    [106.556908, 20.9319439],
                    [106.5565305, 20.932243],
                    [106.5561828, 20.932683],
                    [106.5558508, 20.9332899],
                    [106.5556475, 20.9340756],
                    [106.5555381, 20.9350788],
                    [106.5555854, 20.9362784],
                    [106.5559327, 20.9382826],
                    [106.556081, 20.9393921],
                    [106.5561924, 20.9407097],
                    [106.5562667, 20.9417151],
                    [106.5562301, 20.9425125],
                    [106.5561707, 20.9434972],
                    [106.5561382, 20.9440549],
                    [106.5559936, 20.9450714],
                    [106.5558864, 20.9462801],
                    [106.5557818, 20.9470777],
                    [106.5556538, 20.9476443],
                    [106.5554039, 20.9480192],
                    [106.5550402, 20.9482795],
                    [106.554464, 20.9484518],
                    [106.553943, 20.9484951],
                    [106.5534351, 20.9484483],
                    [106.5527141, 20.9482493],
                    [106.551439, 20.9476185],
                    [106.5497054, 20.9469403],
                    [106.5490979, 20.9468045],
                    [106.5486323, 20.9467574],
                    [106.5481249, 20.946762],
                    [106.5476394, 20.9468435],
                    [106.5472322, 20.9470014],
                    [106.5468542, 20.9472361],
                    [106.5462113, 20.9477687],
                    [106.5439865, 20.9452575],
                    [106.5426372, 20.943548],
                    [106.5433073, 20.9429252],
                    [106.5434797, 20.9425891],
                    [106.5435018, 20.941998],
                    [106.5432558, 20.9399969],
                    [106.5430271, 20.9383166],
                    [106.5429652, 20.9377777],
                    [106.5429452, 20.9372001],
                    [106.5431098, 20.9367619],
                    [106.5432741, 20.9362853],
                    [106.5434103, 20.9358217],
                    [106.5434478, 20.935359],
                    [106.5433376, 20.9349134],
                    [106.5430916, 20.9342987],
                    [106.5424603, 20.9332121],
                    [106.5421868, 20.9326618],
                    [106.5419681, 20.931957],
                    [106.5418863, 20.9315336],
                    [106.5418254, 20.9310971],
                    [106.5418063, 20.930609],
                    [106.5418856, 20.9300942],
                    [106.542092, 20.929604],
                    [106.5424044, 20.9291257],
                    [106.5427942, 20.9286594],
                    [106.5433672, 20.9281659],
                    [106.5439265, 20.9277239],
                    [106.5441349, 20.9274392],
                    [106.5441826, 20.9272717],
                    [106.5441519, 20.9270278],
                    [106.5440083, 20.9267721],
                    [106.5430796, 20.9255595],
                    [106.5427433, 20.9250614],
                    [106.5425137, 20.924665],
                    [106.5423685, 20.9242551],
                    [106.5423229, 20.9239213],
                    [106.5422907, 20.923536],
                    [106.5423516, 20.9232784],
                    [106.5425317, 20.9229813],
                    [106.5428816, 20.9227339],
                    [106.5432745, 20.9225633],
                    [106.543753, 20.9224818],
                    [106.5441616, 20.9224782],
                    [106.5447556, 20.9226527],
                    [106.5454901, 20.9228131],
                    [106.5463864, 20.9229336],
                    [106.5469637, 20.9228641],
                    [106.5475259, 20.9227047],
                    [106.5479883, 20.9224435],
                    [106.548393, 20.9220414],
                    [106.5485927, 20.9215898],
                    [106.5486585, 20.9211265],
                    [106.5486531, 20.9206125],
                    [106.5486208, 20.9202016],
                    [106.548462, 20.9198431],
                    [106.5481628, 20.9195374],
                    [106.5478434, 20.919309],
                    [106.5475465, 20.9192216],
                    [106.5473207, 20.919198],
                    [106.5470388, 20.9192006],
                    [106.546786, 20.9192928],
                    [106.5462388, 20.9195291],
                    [106.5459177, 20.9198405],
                    [106.5456127, 20.9203317],
                    [106.5453646, 20.9208865],
                    [106.5451443, 20.9211597],
                    [106.545076, 20.9212445],
                    [106.5450306, 20.9213008],
                    [106.54464, 20.9217027],
                    [106.5442824, 20.921873],
                    [106.5438465, 20.9219927],
                    [106.5432406, 20.9219982],
                    [106.5422518, 20.9218014],
                    [106.5415157, 20.9214997],
                    [106.5410045, 20.9211315],
                    [106.5408403, 20.9209274],
                    [106.5406967, 20.9206716],
                    [106.5406189, 20.9203479],
                    [106.5406354, 20.9198048],
                    [106.5408487, 20.9190397],
                    [106.5408747, 20.9187645],
                    [106.5408384, 20.9186655],
                    [106.5406102, 20.9184105],
                    [106.5401706, 20.9181574],
                    [106.5396191, 20.9179825],
                    [106.5389689, 20.9177956],
                    [106.5383618, 20.9176982],
                    [106.5378265, 20.9177415],
                    [106.5371655, 20.9178761],
                    [106.5366651, 20.9178806],
                    [106.5363261, 20.9178065],
                    [106.535901, 20.9175918],
                    [106.5355321, 20.917351],
                    [106.5351757, 20.9169558],
                    [106.5346483, 20.9163821],
                    [106.5343614, 20.9158964],
                    [106.5342381, 20.9155505],
                    [106.5341627, 20.9150628],
                    [106.534168, 20.9142016],
                    [106.5341435, 20.9131737],
                    [106.5341038, 20.9127371],
                    [106.5339738, 20.9124299],
                    [106.5337031, 20.9121495],
                    [106.5334333, 20.9119463],
                    [106.5331361, 20.9118334],
                    [106.532769, 20.9117724],
                    [106.5324308, 20.9117754],
                    [106.5320089, 20.9118691],
                    [106.5315181, 20.9121048],
                    [106.5310287, 20.9125077],
                    [106.5306113, 20.9130383],
                    [106.5299866, 20.9146762],
                    [106.5296825, 20.91527],
                    [106.5293537, 20.9155044],
                    [106.5290029, 20.9156617],
                    [106.5286653, 20.915729],
                    [106.5283834, 20.9157315],
                    [106.5280024, 20.9156835],
                    [106.5276492, 20.9156096],
                    [106.5273376, 20.9154582],
                    [106.5271953, 20.9153309],
                    [106.5270803, 20.9151134],
                    [106.5270779, 20.9148693],
                    [106.5272558, 20.9143407],
                    [106.5277251, 20.9133598],
                    [106.5278677, 20.9128316],
                    [106.5279193, 20.9123556],
                    [106.5279282, 20.9118414],
                    [106.5278232, 20.9112255],
                    [106.5277414, 20.9108021],
                    [106.5277242, 20.9104939],
                    [106.5278195, 20.9101589],
                    [106.5280259, 20.9096685],
                    [106.5281074, 20.9093594],
                    [106.5281041, 20.9090381],
                    [106.527996, 20.9087949],
                    [106.5277404, 20.9086174],
                    [106.5274434, 20.9085171],
                    [106.526962, 20.908303],
                    [106.5268329, 20.9080727],
                    [106.5268159, 20.9077773],
                    [106.5269677, 20.9074675],
                    [106.5273593, 20.9071684],
                    [106.5280542, 20.9068923],
                    [106.5286452, 20.9067971],
                    [106.5291944, 20.9067535],
                    [106.5298009, 20.9067867],
                    [106.5303932, 20.9068328],
                    [106.5309715, 20.9068662],
                    [106.5311752, 20.9068001],
                    [106.5313141, 20.9066061],
                    [106.5313535, 20.9063101],
                    [106.5312655, 20.9059768],
                    [106.5309226, 20.9055172],
                    [106.5306784, 20.9050696],
                    [106.5306624, 20.904877],
                    [106.5307668, 20.9047474],
                    [106.5309485, 20.9046045],
                    [106.5312018, 20.9045636],
                    [106.5314844, 20.9046254],
                    [106.5317116, 20.9048032],
                    [106.5320246, 20.9050832],
                    [106.5323511, 20.9053116],
                    [106.5326487, 20.905476],
                    [106.5329593, 20.9055246],
                    [106.5332689, 20.9054833],
                    [106.5335212, 20.9053525],
                    [106.5337444, 20.9051192],
                    [106.5338402, 20.9048355],
                    [106.5337445, 20.904438],
                    [106.5335854, 20.9040282],
                    [106.5334402, 20.9036182],
                    [106.5333796, 20.9032075],
                    [106.5334045, 20.9028731],
                    [106.5335643, 20.9026532],
                    [106.5338301, 20.902458],
                    [106.5341248, 20.9023269],
                    [106.5345178, 20.9021691],
                    [106.5348818, 20.9019345],
                    [106.5352031, 20.9016488],
                    [106.5356649, 20.9013234],
                    [106.5362133, 20.9012028],
                    [106.5370441, 20.9011183],
                    [106.5380227, 20.9010452],
                    [106.5387265, 20.9009489],
                    [106.5393732, 20.9007888],
                    [106.5397375, 20.9005799],
                    [106.5399184, 20.9003598],
                    [106.5399869, 20.9001536],
                    [106.5399138, 20.8999101],
                    [106.5396717, 20.899668],
                    [106.5392753, 20.8994789],
                    [106.5388509, 20.8993284],
                    [106.5383141, 20.8992047],
                    [106.5380519, 20.8990658],
                    [106.5379089, 20.8988614],
                    [106.5378497, 20.8985921],
                    [106.5378753, 20.8983347],
                    [106.5380276, 20.8980635],
                    [106.5382369, 20.8978559],
                    [106.5385878, 20.8977243],
                    [106.5390105, 20.8977076],
                    [106.5394901, 20.8977419],
                    [106.5400547, 20.8978267],
                    [106.5404776, 20.8978358],
                    [106.5408716, 20.8977808],
                    [106.5411378, 20.8976242],
                    [106.5412543, 20.8973019],
                    [106.5412373, 20.8970064],
                    [106.5411211, 20.8966733],
                    [106.5408071, 20.8962906],
                    [106.5403793, 20.8958061],
                    [106.5400351, 20.895218],
                    [106.5399456, 20.8947305],
                    [106.5400619, 20.8943953],
                    [106.540285, 20.8941619],
                    [106.5405514, 20.8940181],
                    [106.5408603, 20.8939125],
                    [106.5413106, 20.8938442],
                    [106.541874, 20.8938134],
                    [106.5423528, 20.8937834],
                    [106.5427963, 20.893728],
                    [106.5431892, 20.8935702],
                    [106.5435524, 20.8932585],
                    [106.543746, 20.8928969],
                    [106.5437421, 20.8925113],
                    [106.543554, 20.8920375],
                    [106.5431408, 20.8915915],
                    [106.542723, 20.8913896],
                    [106.5423409, 20.8912388],
                    [106.5419739, 20.8911778],
                    [106.5411425, 20.8911853],
                    [106.5408038, 20.891137],
                    [106.5404786, 20.8910371],
                    [106.5402366, 20.8907951],
                    [106.5402467, 20.8904094],
                    [106.5402992, 20.8900234],
                    [106.5403653, 20.8895987],
                    [106.5403687, 20.8892387],
                    [106.5403243, 20.8890337],
                    [106.540181, 20.8888036],
                    [106.5398265, 20.8885883],
                    [106.5394718, 20.8883473],
                    [106.5392154, 20.8880796],
                    [106.5390993, 20.8877594],
                    [106.5391243, 20.8874379],
                    [106.5393813, 20.8870757],
                    [106.5398126, 20.886532],
                    [106.5406495, 20.8856892],
                    [106.5412779, 20.885118],
                    [106.5415652, 20.8849612],
                    [106.5417907, 20.884972],
                    [106.5420452, 20.8850468],
                    [106.5424987, 20.8852998],
                    [106.5429105, 20.8856046],
                    [106.5434064, 20.885857],
                    [106.5439856, 20.8860061],
                    [106.5446904, 20.8860254],
                    [106.5454858, 20.8859411],
                    [106.5462033, 20.8858189],
                    [106.5466525, 20.8856478],
                    [106.5471008, 20.8853867],
                    [106.5477296, 20.8848669],
                    [106.547994, 20.8845432],
                    [106.5480823, 20.8842212],
                    [106.5480934, 20.8839255],
                    [106.5480484, 20.883656],
                    [106.5479043, 20.8833489],
                    [106.5475917, 20.8830947],
                    [106.5469673, 20.8826762],
                    [106.5467315, 20.882357],
                    [106.5458343, 20.8812459],
                    [106.545542, 20.8809864],
                    [106.544874, 20.8803109],
                    [106.5443729, 20.8797822],
                    [106.5442435, 20.8795264],
                    [106.5441846, 20.8792827],
                    [106.5441822, 20.8790514],
                    [106.5442919, 20.878742],
                    [106.5445573, 20.8785211],
                    [106.54481, 20.8784288],
                    [106.5453315, 20.878437],
                    [106.5466663, 20.8787462],
                    [106.5476401, 20.8788916],
                    [106.5482457, 20.8788476],
                    [106.5489766, 20.8786739],
                    [106.5495729, 20.8784114],
                    [106.550413, 20.8778897],
                    [106.5513645, 20.8772385],
                    [106.5519436, 20.8768942],
                    [106.5524304, 20.8767087],
                    [106.552273, 20.8758293],
                    [106.5521534, 20.8751624],
                    [106.5520756, 20.8744567],
                    [106.5520266, 20.8737892],
                    [106.5519107, 20.8734819],
                    [106.5517332, 20.8733572],
                    [106.5514643, 20.8732569],
                    [106.55121, 20.8731949],
                    [106.5509134, 20.8731333],
                    [106.5505594, 20.8729694],
                    [106.5503601, 20.8727784],
                    [106.5502303, 20.8724968],
                    [106.5500972, 20.871881],
                    [106.5498492, 20.8710605],
                    [106.5497257, 20.8707016],
                    [106.5495245, 20.8703179],
                    [106.5492823, 20.870063],
                    [106.5489982, 20.8698599],
                    [106.5487143, 20.8696567],
                    [106.5477779, 20.8690482],
                    [106.5467192, 20.8686425],
                    [106.5460523, 20.868792],
                    [106.5452884, 20.8688522],
                    [106.5443302, 20.8688737],
                    [106.5426603, 20.8688889],
                    [106.5417169, 20.8687893],
                    [106.5404721, 20.8685357],
                    [106.5383735, 20.8679762],
                    [106.5368879, 20.867411],
                    [106.5360311, 20.8669946],
                    [106.535491, 20.8666033],
                    [106.5350636, 20.8661034],
                    [106.5347179, 20.8653738],
                    [106.5345364, 20.8648355],
                    [106.534446, 20.8642707],
                    [106.5343528, 20.8634232],
                    [106.5343244, 20.8627035],
                    [106.5342827, 20.8615725],
                    [106.5342614, 20.8606602],
                    [106.5342189, 20.8599408],
                    [106.5341416, 20.859273],
                    [106.5339575, 20.8584905],
                    [106.5336392, 20.8576707],
                    [106.5328771, 20.8561735],
                    [106.5316799, 20.8541532],
                    [106.5311017, 20.8534128],
                    [106.5304511, 20.8527731],
                    [106.5298081, 20.852306],
                    [106.5288679, 20.8517525],
                    [106.5282426, 20.8514844],
                    [106.5277709, 20.8513374],
                    [106.5273387, 20.8512027],
                    [106.5269704, 20.8510972],
                    [106.5254545, 20.8509409],
                    [106.5249017, 20.850944],
                    [106.5243691, 20.8509538],
                    [106.5240213, 20.8511311],
                    [106.5237642, 20.8513875],
                    [106.5231574, 20.852391],
                    [106.5230501, 20.8529319],
                    [106.5230652, 20.8537287],
                    [106.5232473, 20.8550254],
                    [106.5238547, 20.8572568],
                    [106.5243601, 20.8591676],
                    [106.5252179, 20.8610175],
                    [106.5251059, 20.8620889],
                    [106.5241964, 20.8629524],
                    [106.522708, 20.8629551],
                    [106.521475, 20.8627912],
                    [106.5208641, 20.8626162],
                    [106.5201002, 20.8622907],
                    [106.5188993, 20.8619928],
                    [106.5177992, 20.8619126],
                    [106.5167914, 20.8618958],
                    [106.5159181, 20.8619422],
                    [106.5150744, 20.8621296],
                    [106.5139782, 20.862435],
                    [106.5129602, 20.8628167],
                    [106.5121606, 20.8631838],
                    [106.5115148, 20.8634466],
                    [106.5107559, 20.8636461],
                    [106.5101016, 20.8637547],
                    [106.5092851, 20.8638518],
                    [106.5084248, 20.8637824],
                    [106.5074084, 20.8636243],
                    [106.5065528, 20.8633233],
                    [106.5059011, 20.8629818],
                    [106.5052621, 20.862499],
                    [106.5045221, 20.8617856],
                    [106.5039353, 20.8608781],
                    [106.5036682, 20.8602377],
                    [106.5034342, 20.8593914],
                    [106.5032555, 20.8584288],
                    [106.5030976, 20.8574274],
                    [106.5029597, 20.8563104],
                    [106.5028614, 20.8556299],
                    [106.5026869, 20.8550916],
                    [106.5024848, 20.8546048],
                    [106.502227, 20.8541958],
                    [106.5018753, 20.8538],
                    [106.5011938, 20.8532094],
                    [106.5007405, 20.8529848],
                    [106.5003386, 20.8528816],
                    [106.4999711, 20.8528848],
                    [106.4996706, 20.8529027],
                    [106.4992042, 20.8530287],
                    [106.4989549, 20.8531528],
                    [106.4986402, 20.8534298],
                    [106.4985095, 20.8537205],
                    [106.4984455, 20.8540105],
                    [106.4984106, 20.8546965],
                    [106.4985032, 20.8556099],
                    [106.4984775, 20.8572406],
                    [106.4984199, 20.8581706],
                    [106.4983566, 20.8585215],
                    [106.4982295, 20.8591931],
                    [106.4979456, 20.8600336],
                    [106.4974223, 20.861181],
                    [106.4967512, 20.8625284],
                    [106.4960428, 20.8637216],
                    [106.4951217, 20.8652223],
                    [106.4943514, 20.8665413],
                    [106.4941239, 20.8669714],
                    [106.4931887, 20.8675374],
                    [106.4919812, 20.867688],
                    [106.4898713, 20.8670805],
                    [106.4872035, 20.8661134],
                    [106.4870187, 20.8665075],
                    [106.4868746, 20.8667529],
                    [106.4867291, 20.8668961],
                    [106.4865621, 20.8669691],
                    [106.486375, 20.867009],
                    [106.4861707, 20.8669983],
                    [106.485883, 20.8669607],
                    [106.4856299, 20.8668956],
                    [106.4852844, 20.866752],
                    [106.4849619, 20.8667044],
                    [106.4847245, 20.8667781],
                    [106.484587, 20.866903],
                    [106.4845138, 20.8670919],
                    [106.4844783, 20.8673439],
                    [106.4844956, 20.8677094],
                    [106.4844018, 20.8679351],
                    [106.4842222, 20.8681143],
                    [106.4839658, 20.8682619],
                    [106.4838044, 20.8684742],
                    [106.4838036, 20.8687978],
                    [106.4838006, 20.8690968],
                    [106.4836706, 20.8693445],
                    [106.4834963, 20.869467],
                    [106.4833479, 20.8694778],
                    [106.4831571, 20.8693882],
                    [106.4829556, 20.8691702],
                    [106.482689, 20.8688251],
                    [106.4823095, 20.8685384],
                    [106.4819256, 20.868275],
                    [106.481588, 20.8680403],
                    [106.4812188, 20.8679948],
                    [106.481337, 20.8674998],
                    [106.4828993, 20.8653408],
                    [106.4832236, 20.8644185],
                    [106.4826114, 20.8640758],
                    [106.4821037, 20.8636388],
                    [106.4818438, 20.8632836],
                    [106.4817369, 20.8628659],
                    [106.4817442, 20.8623575],
                    [106.4818292, 20.8619863],
                    [106.4773606, 20.858608],
                    [106.479119, 20.8565625],
                    [106.4798606, 20.855645],
                    [106.4814255, 20.8536735],
                    [106.4823811, 20.8524293],
                    [106.4835762, 20.8509403],
                    [106.4849778, 20.8495378],
                    [106.4862741, 20.8485114],
                    [106.4888543, 20.8469237],
                    [106.4891117, 20.8467653],
                    [106.4907368, 20.8459566],
                    [106.4930707, 20.8446562],
                    [106.4942005, 20.8439181],
                    [106.4954719, 20.8428256],
                    [106.4963446, 20.8417808],
                    [106.4967462, 20.8408062],
                    [106.4970495, 20.839678],
                    [106.4970903, 20.8390644],
                    [106.4969204, 20.8376267],
                    [106.4965687, 20.8363056],
                    [106.4954127, 20.8331559],
                    [106.4947232, 20.8313091],
                    [106.4935186, 20.8295768],
                    [106.490755, 20.8268219],
                    [106.4888714, 20.8242948],
                    [106.4883466, 20.823428],
                    [106.4881854, 20.8228284],
                    [106.4881811, 20.8223811],
                    [106.4883821, 20.8218144],
                    [106.4888154, 20.8212457],
                    [106.4894302, 20.820746],
                    [106.4901998, 20.820245],
                    [106.4914845, 20.8195983],
                    [106.4925671, 20.8194241],
                    [106.492799, 20.8193749],
                    [106.4953576, 20.8196273],
                    [106.4986424, 20.8202109],
                    [106.5008166, 20.8207574],
                    [106.5018749, 20.8211076],
                    [106.5042596, 20.8216326],
                    [106.507234, 20.8222739],
                    [106.5094308, 20.8225579],
                    [106.5114309, 20.8225402],
                    [106.5131054, 20.8222221],
                    [106.5140942, 20.8217933],
                    [106.5143904, 20.8216648],
                    [106.5154105, 20.8204424],
                    [106.5158481, 20.8190432],
                    [106.516029, 20.8177676],
                    [106.5158855, 20.8163129],
                    [106.5156779, 20.8149194],
                    [106.5152715, 20.8129817],
                    [106.5146738, 20.8112883],
                    [106.5137506, 20.8092945],
                    [106.5128968, 20.8077854],
                    [106.5116517, 20.805855],
                    [106.5101461, 20.8036842],
                    [106.5092948, 20.8024178],
                    [106.5087971, 20.8009434],
                    [106.5076184, 20.8017593],
                    [106.5060052, 20.802812],
                    [106.5048516, 20.8036111],
                    [106.504149, 20.8043454],
                    [106.503897, 20.8049543],
                    [106.5036509, 20.8061701],
                    [106.5035314, 20.8071421],
                    [106.5032794, 20.807751],
                    [106.5027028, 20.8081809],
                    [106.5018747, 20.8084303],
                    [106.5009096, 20.8084583],
                    [106.5003402, 20.8084869],
                    [106.4990961, 20.8083797],
                    [106.497021, 20.8080198],
                    [106.4952945, 20.8075622],
                    [106.4939164, 20.8069837],
                    [106.4925371, 20.8062869],
                    [106.4909455, 20.8050721],
                    [106.4895221, 20.8038323],
                    [106.4886853, 20.802989],
                    [106.4862999, 20.8002012],
                    [106.4866732, 20.7995066],
                    [106.4869443, 20.7991278],
                    [106.487501, 20.7984512],
                    [106.4876327, 20.7981608],
                    [106.4876868, 20.797617],
                    [106.4886554, 20.7964095],
                    [106.4906179, 20.7938888],
                    [106.4917828, 20.7923608],
                    [106.4923741, 20.7915205],
                    [106.4938454, 20.7892949],
                    [106.4946654, 20.7877656],
                    [106.4961226, 20.7848361],
                    [106.4967673, 20.7834703],
                    [106.4971234, 20.7825444],
                    [106.4974748, 20.7811858],
                    [106.4976911, 20.7801188],
                    [106.4978832, 20.7785841],
                    [106.4983239, 20.7755739],
                    [106.4984329, 20.7742527],
                    [106.4984107, 20.773648],
                    [106.498396, 20.7732468],
                    [106.4982308, 20.7723786],
                    [106.4980916, 20.7718113],
                    [106.4979097, 20.7713974],
                    [106.4976396, 20.7710411],
                    [106.4967997, 20.7703259],
                    [106.4957279, 20.7691944],
                    [106.4948647, 20.7681957],
                    [106.4940956, 20.7670389],
                    [106.4936411, 20.7662539],
                    [106.4933139, 20.7654274],
                    [106.4930666, 20.7648133],
                    [106.4929193, 20.7642861],
                    [106.4928297, 20.7636638],
                    [106.4929877, 20.7627075],
                    [106.4930983, 20.7622957],
                    [106.4933526, 20.7617582],
                    [106.4935718, 20.7613441],
                    [106.4937145, 20.7611412],
                    [106.4938775, 20.7609577],
                    [106.4940198, 20.7608326],
                    [106.4942748, 20.7607058],
                    [106.4945084, 20.7606884],
                    [106.4948422, 20.7607218],
                    [106.4955258, 20.760882],
                    [106.4962502, 20.7610486],
                    [106.4965248, 20.7610562],
                    [106.4966607, 20.7610076],
                    [106.4969543, 20.7608426],
                    [106.4972289, 20.7607509],
                    [106.4974754, 20.7606003],
                    [106.4984059, 20.7601425],
                    [106.4964626, 20.7582731],
                    [106.496085, 20.7578778],
                    [106.4952777, 20.7570327],
                    [106.4940832, 20.7557059],
                    [106.4937731, 20.7553793],
                    [106.4912258, 20.7527898],
                    [106.4908685, 20.7525212],
                    [106.4907225, 20.7524041],
                    [106.490575, 20.752286],
                    [106.4884061, 20.7511074],
                    [106.487121, 20.7505208],
                    [106.4866317, 20.7503776],
                    [106.4859994, 20.7503113],
                    [106.4854158, 20.750264],
                    [106.4848323, 20.750243],
                    [106.4841102, 20.750223],
                    [106.4834702, 20.7502119],
                    [106.4827799, 20.7502684],
                    [106.4822002, 20.7503239],
                    [106.4809666, 20.7506095],
                    [106.4798024, 20.7509739],
                    [106.478798, 20.7514453],
                    [106.4778392, 20.7519812],
                    [106.4769033, 20.7525813],
                    [106.4761271, 20.7531171],
                    [106.4756478, 20.7534171],
                    [106.4752142, 20.75361],
                    [106.474835, 20.7537485],
                    [106.4740396, 20.7538343],
                    [106.4735467, 20.7537733],
                    [106.4730177, 20.7535757],
                    [106.4725822, 20.7533609],
                    [106.4723441, 20.7531151],
                    [106.4721339, 20.752895],
                    [106.4717539, 20.7523243],
                    [106.4709808, 20.7512328],
                    [106.470313, 20.7497982],
                    [106.4700261, 20.7490052],
                    [106.4694879, 20.7478758],
                    [106.4687343, 20.7465103],
                    [106.4678209, 20.7451381],
                    [106.4671742, 20.7442776],
                    [106.4665612, 20.7437041],
                    [106.4659367, 20.7433048],
                    [106.4654199, 20.7430421],
                    [106.4646902, 20.7427731],
                    [106.464064, 20.7426692],
                    [106.4630084, 20.7426296],
                    [106.4626627, 20.7426326],
                    [106.462308, 20.7426356],
                    [106.4619538, 20.7426791],
                    [106.4613826, 20.7427608],
                    [106.4606574, 20.7429611],
                    [106.4603041, 20.7431019],
                    [106.4592058, 20.7436899],
                    [106.4586801, 20.7439856],
                    [106.4582269, 20.7442405],
                    [106.4576443, 20.7445287],
                    [106.4567207, 20.7445571],
                    [106.4553909, 20.7442389],
                    [106.4545689, 20.7439435],
                    [106.4533359, 20.7433754],
                    [106.4525625, 20.7428302],
                    [106.4521758, 20.7421715],
                    [106.4521035, 20.7414676],
                    [106.4521762, 20.740968],
                    [106.452418, 20.7405819],
                    [106.4527325, 20.7401052],
                    [106.4535321, 20.7394078],
                    [106.4545496, 20.7388616],
                    [106.455567, 20.738361],
                    [106.456536, 20.7379514],
                    [106.4574081, 20.7375418],
                    [106.458474, 20.7371323],
                    [106.459443, 20.7366317],
                    [106.4602182, 20.7361766],
                    [106.4609934, 20.7356759],
                    [106.4615264, 20.7352205],
                    [106.462011, 20.7348564],
                    [106.4631738, 20.7336728],
                    [106.4636099, 20.7330355],
                    [106.4640702, 20.7324208],
                    [106.4646269, 20.7316771],
                    [106.4650119, 20.73098],
                    [106.465177, 20.7301798],
                    [106.4653696, 20.7294053],
                    [106.4655072, 20.7283984],
                    [106.4655074, 20.7277013],
                    [106.4652327, 20.7265652],
                    [106.4651645, 20.7264584],
                    [106.4649002, 20.7260442],
                    [106.4647382, 20.7257906],
                    [106.4645528, 20.7255923],
                    [106.4632819, 20.7241638],
                    [106.4624849, 20.7236215],
                    [106.461817, 20.7233067],
                    [106.4602861, 20.7226931],
                    [106.4586202, 20.722185],
                    [106.4574494, 20.7218887],
                    [106.4561212, 20.7216557],
                    [106.4550855, 20.7215497],
                    [106.453577, 20.7215704],
                    [106.4525863, 20.7217605],
                    [106.4520459, 20.7220354],
                    [106.4517643, 20.7223458],
                    [106.4515291, 20.7227266],
                    [106.4513575, 20.7233064],
                    [106.4512886, 20.7237893],
                    [106.4512884, 20.724498],
                    [106.45134, 20.7246922],
                    [106.4514254, 20.7250134],
                    [106.451631, 20.725561],
                    [106.4520424, 20.7259798],
                    [106.4522137, 20.7262053],
                    [106.4523165, 20.7264308],
                    [106.4525222, 20.7267207],
                    [106.4526592, 20.7270751],
                    [106.4528649, 20.7273651],
                    [106.4529677, 20.7277837],
                    [106.453036, 20.7283313],
                    [106.4530357, 20.729362],
                    [106.4530698, 20.7300062],
                    [106.4530598, 20.7302764],
                    [106.4529239, 20.7308892],
                    [106.4521116, 20.7336512],
                    [106.4516082, 20.7343131],
                    [106.450828, 20.735117],
                    [106.4501863, 20.7356253],
                    [106.4495319, 20.7359799],
                    [106.4488274, 20.7362161],
                    [106.4481733, 20.7363578],
                    [106.4477454, 20.736405],
                    [106.4471165, 20.7363338],
                    [106.4466133, 20.7360262],
                    [106.4462864, 20.7356715],
                    [106.4460852, 20.7352457],
                    [106.4460351, 20.7347254],
                    [106.4461108, 20.7341578],
                    [106.4462872, 20.7333302],
                    [106.4464383, 20.7327154],
                    [106.4465392, 20.7322423],
                    [106.4466148, 20.7316749],
                    [106.4466528, 20.7311663],
                    [106.4465273, 20.7305751],
                    [106.4462759, 20.7298182],
                    [106.4459993, 20.7292506],
                    [106.4456221, 20.7285646],
                    [106.4452449, 20.7279497],
                    [106.4446541, 20.7270863],
                    [106.4441664, 20.7261917],
                    [106.443598, 20.7256197],
                    [106.4430196, 20.72491],
                    [106.442567, 20.7243423],
                    [106.4416742, 20.7233842],
                    [106.4409953, 20.7225799],
                    [106.4404923, 20.7220595],
                    [106.4396624, 20.7213497],
                    [106.4390589, 20.7207819],
                    [106.4383924, 20.7203442],
                    [106.4376901, 20.720144],
                    [106.4367948, 20.7202371],
                    [106.4361155, 20.7205916],
                    [106.435675, 20.7210999],
                    [106.4353478, 20.7217146],
                    [106.4349698, 20.7227078],
                    [106.4346927, 20.7237719],
                    [106.4345161, 20.7247415],
                    [106.434343, 20.7255474],
                    [106.4341669, 20.7259054],
                    [106.4337416, 20.7267825],
                    [106.4331256, 20.7275807],
                    [106.4327704, 20.7280612],
                    [106.4321286, 20.7289292],
                    [106.4309557, 20.7300025],
                    [106.4303692, 20.7306355],
                    [106.4290097, 20.7316011],
                    [106.4278771, 20.7321762],
                    [106.4265578, 20.7328088],
                    [106.4247988, 20.7335238],
                    [106.4229077, 20.7340109],
                    [106.4215694, 20.7342154],
                    [106.4203675, 20.7343945],
                    [106.4192476, 20.7345992],
                    [106.4180731, 20.7348038],
                    [106.4167892, 20.735138],
                    [106.4156508, 20.7353718],
                    [106.4148711, 20.7354447],
                    [106.4139512, 20.735415],
                    [106.4130623, 20.7352827],
                    [106.4125478, 20.7349894],
                    [106.412127, 20.7346524],
                    [106.4116906, 20.734198],
                    [106.4115391, 20.7340239],
                    [106.411095, 20.7335772],
                    [106.4110039, 20.7334205],
                    [106.4106467, 20.7328062],
                    [106.410226, 20.7321176],
                    [106.4095173, 20.730947],
                    [106.4090969, 20.7300235],
                    [106.4088632, 20.7296717],
                    [106.4082768, 20.7288512],
                    [106.4076589, 20.7284328],
                    [106.4072373, 20.7282926],
                    [106.4076258, 20.7278846],
                    [106.4078136, 20.7275352],
                    [106.4079381, 20.7271588],
                    [106.4080018, 20.7267947],
                    [106.4082398, 20.7249191],
                    [106.408566, 20.7233577],
                    [106.4087462, 20.7227315],
                    [106.4090554, 20.7221627],
                    [106.4095425, 20.7216971],
                    [106.4103772, 20.721097],
                    [106.4113256, 20.7206274],
                    [106.4131663, 20.7197031],
                    [106.4151787, 20.7182592],
                    [106.4153081, 20.7181664],
                    [106.4167786, 20.7170951],
                    [106.415188, 20.7160001],
                    [106.4160594, 20.7130452],
                    [106.4122963, 20.7123701],
                    [106.412872, 20.7102915],
                    [106.4133058, 20.7087734],
                    [106.4139289, 20.7064871],
                    [106.4128535, 20.7057437],
                    [106.4120075, 20.7050767],
                    [106.4111975, 20.7045345],
                    [106.4107569, 20.7041501],
                    [106.4106036, 20.7039924],
                    [106.4105508, 20.7039243],
                    [106.4102618, 20.7035521],
                    [106.4096231, 20.7025783],
                    [106.4091555, 20.7017316],
                    [106.4086698, 20.7009187],
                    [106.4083503, 20.7004451],
                    [106.4078872, 20.6997586],
                    [106.4073474, 20.699115],
                    [106.4064838, 20.6980818],
                    [106.4053953, 20.6969046],
                    [106.4048553, 20.6963964],
                    [106.4041176, 20.6956341],
                    [106.403011, 20.6943215],
                    [106.4023094, 20.6934746],
                    [106.40168, 20.6924415],
                    [106.4012214, 20.6915694],
                    [106.4009157, 20.6908752],
                    [106.4006282, 20.6899439],
                    [106.4006086, 20.6898095],
                    [106.4005566, 20.6894528],
                    [106.4005479, 20.6888857],
                ],
            ],
            [
                [
                    [107.5039571, 20.1429792],
                    [107.5046228, 20.107764],
                    [107.5117957, 20.0732012],
                    [107.5252507, 20.0403553],
                    [107.5449489, 20.0097485],
                    [107.5679249, 19.9847528],
                    [107.5949294, 19.9636665],
                    [107.6252274, 19.9470621],
                    [107.657995, 19.9353902],
                    [107.6835269, 19.9299017],
                    [107.7066271, 19.9273647],
                    [107.744168, 19.9265816],
                    [107.7812677, 19.9320623],
                    [107.818309, 19.9439662],
                    [107.8509701, 19.9611488],
                    [107.8799432, 19.9834781],
                    [107.9043307, 20.0102632],
                    [107.9243767, 20.0412249],
                    [107.9357598, 20.0659083],
                    [107.9444854, 20.0909535],
                    [107.950044, 20.1198417],
                    [107.9511219, 20.1574305],
                    [107.9455712, 20.1925397],
                    [107.9335492, 20.2261752],
                    [107.9154288, 20.2572827],
                    [107.8917743, 20.2848864],
                    [107.8633252, 20.3081201],
                    [107.8463512, 20.3192403],
                    [107.8437031, 20.3191185],
                    [107.8309723, 20.3262539],
                    [107.7957307, 20.3387177],
                    [107.7587069, 20.3451196],
                    [107.7210646, 20.3452576],
                    [107.6839871, 20.3391271],
                    [107.6486401, 20.3269201],
                    [107.6304582, 20.3181082],
                    [107.5953683, 20.2959737],
                    [107.5658515, 20.2712013],
                    [107.5402486, 20.2417822],
                    [107.5220451, 20.2110381],
                    [107.5098234, 20.1777615],
                    [107.5039571, 20.1429792],
                ],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.3285245, 9.7001876],
                [105.3285807, 9.699569],
                [105.3286652, 9.6990348],
                [105.3289483, 9.6985004],
                [105.3293161, 9.6977128],
                [105.3301094, 9.6968968],
                [105.3319373, 9.6955878],
                [105.3328158, 9.6949403],
                [105.3337507, 9.6940397],
                [105.3340906, 9.6935896],
                [105.3342037, 9.6933365],
                [105.3344866, 9.6926615],
                [105.3346842, 9.6917054],
                [105.3347968, 9.6909181],
                [105.3350409, 9.6899374],
                [105.3352063, 9.6899456],
                [105.3353754, 9.6899341],
                [105.3355436, 9.6899136],
                [105.3357104, 9.6898841],
                [105.3358753, 9.6898458],
                [105.3360379, 9.6897987],
                [105.3361977, 9.689743],
                [105.3363542, 9.6896789],
                [105.3365069, 9.6896065],
                [105.3366554, 9.6895261],
                [105.3367993, 9.6894378],
                [105.3369381, 9.689342],
                [105.3370715, 9.6892389],
                [105.3371989, 9.6891289],
                [105.3373202, 9.6890122],
                [105.3374348, 9.6888892],
                [105.3375426, 9.6887602],
                [105.3376431, 9.6886257],
                [105.337736, 9.688486],
                [105.3378211, 9.6883416],
                [105.3378982, 9.6881928],
                [105.3379644, 9.6880463],
                [105.3380228, 9.6878966],
                [105.3380732, 9.6877441],
                [105.3381155, 9.6875892],
                [105.3381495, 9.6874323],
                [105.3381753, 9.6872739],
                [105.3381819, 9.6870192],
                [105.3381746, 9.6864522],
                [105.3381654, 9.6857898],
                [105.338165, 9.6851273],
                [105.3381735, 9.6844649],
                [105.3381908, 9.6838026],
                [105.338215, 9.6831825],
                [105.3382469, 9.6825627],
                [105.3382812, 9.6819804],
                [105.338334, 9.6813721],
                [105.3383678, 9.6807938],
                [105.3383715, 9.6804957],
                [105.3383469, 9.6802328],
                [105.3383152, 9.6799972],
                [105.3382766, 9.6797626],
                [105.338225, 9.6795007],
                [105.3381646, 9.6792513],
                [105.3380795, 9.6789775],
                [105.3380179, 9.6787272],
                [105.3380033, 9.6785641],
                [105.3379983, 9.6784005],
                [105.3380028, 9.6782369],
                [105.3380169, 9.6780738],
                [105.3380406, 9.6779118],
                [105.3380679, 9.6777765],
                [105.3381018, 9.6776427],
                [105.3381423, 9.6775107],
                [105.3381892, 9.6773808],
                [105.3382426, 9.6772533],
                [105.3383228, 9.6771168],
                [105.3384098, 9.6769844],
                [105.3385033, 9.6768565],
                [105.3386032, 9.6767332],
                [105.3387092, 9.6766151],
                [105.338821, 9.6765022],
                [105.3389384, 9.676395],
                [105.339061, 9.6762936],
                [105.3391886, 9.6761984],
                [105.3393286, 9.6761046],
                [105.3394734, 9.6760182],
                [105.3396227, 9.6759394],
                [105.3397759, 9.6758684],
                [105.3399328, 9.6758055],
                [105.3400927, 9.6757508],
                [105.3402554, 9.6757044],
                [105.3404203, 9.6756666],
                [105.340587, 9.6756373],
                [105.340755, 9.6756167],
                [105.3410947, 9.6755754],
                [105.3414354, 9.675542],
                [105.3417768, 9.6755166],
                [105.3421187, 9.6754992],
                [105.3424609, 9.6754898],
                [105.3428249, 9.6754886],
                [105.3431888, 9.6754965],
                [105.3435524, 9.6755134],
                [105.3439155, 9.6755393],
                [105.3442778, 9.6755743],
                [105.344639, 9.6756182],
                [105.3449991, 9.6756712],
                [105.3453576, 9.6757331],
                [105.3457926, 9.6758149],
                [105.3462261, 9.6759038],
                [105.346658, 9.6759999],
                [105.3470883, 9.6761031],
                [105.3479278, 9.676291],
                [105.3484012, 9.6763945],
                [105.348866, 9.6764946],
                [105.3492257, 9.6765658],
                [105.3495865, 9.6766318],
                [105.3499482, 9.6766926],
                [105.350131, 9.6767231],
                [105.3503151, 9.6767448],
                [105.3505, 9.6767576],
                [105.3506853, 9.6767615],
                [105.3508706, 9.6767564],
                [105.3510555, 9.6767424],
                [105.3512394, 9.6767194],
                [105.351422, 9.6766877],
                [105.3516027, 9.6766471],
                [105.3517812, 9.6765979],
                [105.3519571, 9.6765401],
                [105.3521299, 9.6764739],
                [105.3522992, 9.6763994],
                [105.3524615, 9.6763184],
                [105.3526198, 9.6762299],
                [105.3527735, 9.676134],
                [105.3529224, 9.6760308],
                [105.353066, 9.6759208],
                [105.3532042, 9.6758041],
                [105.3533365, 9.675681],
                [105.3534626, 9.6755517],
                [105.3535823, 9.6754166],
                [105.3536952, 9.675276],
                [105.3538066, 9.6751038],
                [105.3539088, 9.6748805],
                [105.3540078, 9.6746273],
                [105.3540981, 9.6743709],
                [105.3541797, 9.6741118],
                [105.3542524, 9.6738501],
                [105.3543162, 9.6735861],
                [105.354367, 9.6733414],
                [105.3544101, 9.6730953],
                [105.3544455, 9.672848],
                [105.3544731, 9.6725998],
                [105.354493, 9.6723508],
                [105.3545051, 9.6721013],
                [105.3545093, 9.6718516],
                [105.3545149, 9.671665],
                [105.3545289, 9.6714789],
                [105.3545514, 9.6712936],
                [105.3545822, 9.6711095],
                [105.3546214, 9.6709269],
                [105.3546689, 9.6707462],
                [105.3547246, 9.6705678],
                [105.3547883, 9.6703921],
                [105.3548657, 9.6702063],
                [105.354952, 9.6700245],
                [105.3550472, 9.669847],
                [105.355151, 9.6696742],
                [105.3552631, 9.6695066],
                [105.3553832, 9.6693445],
                [105.3555112, 9.6691882],
                [105.3556412, 9.6690594],
                [105.3558013, 9.6689535],
                [105.3559599, 9.6688761],
                [105.3561221, 9.6688062],
                [105.3562874, 9.6687439],
                [105.3564556, 9.6686894],
                [105.3566262, 9.6686429],
                [105.3567988, 9.6686044],
                [105.3569731, 9.668574],
                [105.3571486, 9.6685518],
                [105.357332, 9.6685374],
                [105.3575159, 9.6685319],
                [105.3576998, 9.6685354],
                [105.3578833, 9.6685479],
                [105.358125, 9.6685798],
                [105.3584559, 9.6686406],
                [105.3588446, 9.6687177],
                [105.3592322, 9.6688004],
                [105.3596838, 9.6689044],
                [105.3601334, 9.669016],
                [105.3608715, 9.6692301],
                [105.3615643, 9.6694109],
                [105.3622545, 9.6696008],
                [105.3629422, 9.6697998],
                [105.3635524, 9.6699846],
                [105.3641604, 9.6701765],
                [105.3647661, 9.6703755],
                [105.3653693, 9.6705815],
                [105.36597, 9.6707946],
                [105.3665681, 9.6710146],
                [105.3671635, 9.6712416],
                [105.3679138, 9.6715318],
                [105.3686607, 9.6718304],
                [105.3692003, 9.6720523],
                [105.3697381, 9.6722786],
                [105.3702739, 9.6725093],
                [105.3704206, 9.6725673],
                [105.37057, 9.6726179],
                [105.3707219, 9.6726607],
                [105.3708759, 9.6726958],
                [105.3710315, 9.6727231],
                [105.3711883, 9.6727424],
                [105.3713459, 9.6727537],
                [105.3715038, 9.672757],
                [105.3716618, 9.6727523],
                [105.3718193, 9.6727396],
                [105.3719759, 9.6727189],
                [105.3721296, 9.6726906],
                [105.3722816, 9.6726546],
                [105.3724316, 9.672611],
                [105.3725791, 9.67256],
                [105.3727239, 9.6725016],
                [105.3728654, 9.672436],
                [105.3730033, 9.6723634],
                [105.3731373, 9.672284],
                [105.373267, 9.6721979],
                [105.3733787, 9.6721028],
                [105.3734864, 9.6719835],
                [105.3735732, 9.6718563],
                [105.3736522, 9.6717243],
                [105.3737232, 9.6715879],
                [105.3737859, 9.6714476],
                [105.3738401, 9.6713039],
                [105.3738856, 9.6711573],
                [105.3739223, 9.6710083],
                [105.37395, 9.6708574],
                [105.373968, 9.670712],
                [105.3739776, 9.6705658],
                [105.3739788, 9.6704194],
                [105.3739717, 9.6702731],
                [105.3739562, 9.6701274],
                [105.3739324, 9.6699828],
                [105.3739004, 9.6698398],
                [105.3738603, 9.6696987],
                [105.3738121, 9.6695601],
                [105.3737562, 9.6694245],
                [105.3736925, 9.6692921],
                [105.3735716, 9.6690851],
                [105.3734446, 9.6688816],
                [105.3733115, 9.668682],
                [105.3731725, 9.6684863],
                [105.3730276, 9.6682948],
                [105.3728771, 9.6681076],
                [105.3726989, 9.6678998],
                [105.3725137, 9.6676981],
                [105.3723217, 9.6675026],
                [105.3721232, 9.6673136],
                [105.3719182, 9.6671314],
                [105.3717072, 9.666956],
                [105.3714903, 9.6667878],
                [105.3712946, 9.666648],
                [105.3710685, 9.66651],
                [105.3708743, 9.6663853],
                [105.3706853, 9.666253],
                [105.3705019, 9.6661132],
                [105.3703244, 9.6659663],
                [105.3701531, 9.6658123],
                [105.3699882, 9.6656517],
                [105.3698301, 9.6654846],
                [105.3696803, 9.6653128],
                [105.3695376, 9.6651353],
                [105.3694022, 9.6649523],
                [105.3692744, 9.6647641],
                [105.3691543, 9.6645709],
                [105.3690422, 9.6643732],
                [105.3689383, 9.6641712],
                [105.3688426, 9.6639652],
                [105.3687554, 9.6637556],
                [105.3686769, 9.6635428],
                [105.368607, 9.663327],
                [105.3685629, 9.6631462],
                [105.3685283, 9.6629634],
                [105.3685033, 9.6627791],
                [105.3684879, 9.6625938],
                [105.3684821, 9.662408],
                [105.3684861, 9.6622221],
                [105.3684997, 9.6620366],
                [105.3685221, 9.6618581],
                [105.3685534, 9.6616809],
                [105.3685937, 9.6615055],
                [105.3686427, 9.6613323],
                [105.3687004, 9.6611616],
                [105.3687666, 9.660994],
                [105.3688412, 9.6608299],
                [105.3689293, 9.660659],
                [105.3690551, 9.6604151],
                [105.3691575, 9.6602361],
                [105.3692411, 9.6601041],
                [105.3693326, 9.6599773],
                [105.3694315, 9.659856],
                [105.3695375, 9.6597407],
                [105.3696503, 9.6596318],
                [105.3697694, 9.6595297],
                [105.3698945, 9.6594347],
                [105.3700245, 9.6593476],
                [105.3701596, 9.6592682],
                [105.3702992, 9.6591967],
                [105.3704428, 9.6591336],
                [105.3705899, 9.6590789],
                [105.3707401, 9.6590328],
                [105.3708927, 9.6589956],
                [105.3710474, 9.6589674],
                [105.3712034, 9.6589482],
                [105.3713817, 9.6589422],
                [105.3715601, 9.6589454],
                [105.3717381, 9.6589578],
                [105.3719152, 9.6589795],
                [105.3720908, 9.6590103],
                [105.3722646, 9.6590502],
                [105.372436, 9.6590991],
                [105.3726046, 9.6591568],
                [105.3727698, 9.6592232],
                [105.3729278, 9.6592963],
                [105.3730817, 9.6593774],
                [105.3732311, 9.6594661],
                [105.3733758, 9.6595623],
                [105.3735152, 9.6596658],
                [105.373649, 9.6597761],
                [105.3737769, 9.6598931],
                [105.3738985, 9.6600165],
                [105.3740135, 9.6601459],
                [105.3741216, 9.6602809],
                [105.3742137, 9.6604023],
                [105.3743132, 9.6605178],
                [105.3744198, 9.6606269],
                [105.3745332, 9.6607293],
                [105.3746528, 9.6608245],
                [105.3747782, 9.6609122],
                [105.3749088, 9.660992],
                [105.3750443, 9.6610636],
                [105.3751808, 9.6611253],
                [105.3753209, 9.6611788],
                [105.375464, 9.6612237],
                [105.3756096, 9.66126],
                [105.3757571, 9.6612875],
                [105.3760958, 9.6613332],
                [105.3763832, 9.6613513],
                [105.3766376, 9.6613623],
                [105.376802, 9.6613563],
                [105.3769604, 9.6613433],
                [105.3771318, 9.6613201],
                [105.3772836, 9.6612922],
                [105.3774611, 9.6612621],
                [105.3776306, 9.6612262],
                [105.3777985, 9.6611835],
                [105.3779645, 9.6611341],
                [105.3781284, 9.6610781],
                [105.3782898, 9.6610156],
                [105.3784485, 9.6609467],
                [105.3788649, 9.6607838],
                [105.3792787, 9.6606147],
                [105.3796899, 9.6604395],
                [105.3801257, 9.6602459],
                [105.3805583, 9.6600453],
                [105.3809876, 9.659838],
                [105.3814134, 9.6596238],
                [105.3818357, 9.659403],
                [105.3820683, 9.6592566],
                [105.3823055, 9.6591175],
                [105.3825469, 9.6589856],
                [105.3827924, 9.6588612],
                [105.3830416, 9.6587444],
                [105.3832944, 9.6586353],
                [105.3835506, 9.658534],
                [105.3838098, 9.6584405],
                [105.384085, 9.658351],
                [105.3843631, 9.6582704],
                [105.3846437, 9.6581987],
                [105.3849265, 9.6581361],
                [105.3852112, 9.6580827],
                [105.3854975, 9.6580384],
                [105.3857852, 9.6580034],
                [105.3860738, 9.6579776],
                [105.3863632, 9.6579611],
                [105.3866529, 9.657954],
                [105.3868772, 9.6579702],
                [105.3871006, 9.6579955],
                [105.3873227, 9.6580299],
                [105.3875433, 9.6580732],
                [105.3877618, 9.6581255],
                [105.387978, 9.6581866],
                [105.3881914, 9.6582564],
                [105.3884017, 9.6583348],
                [105.3886009, 9.6584182],
                [105.3887966, 9.6585094],
                [105.3889885, 9.6586081],
                [105.3891763, 9.6587143],
                [105.3893596, 9.6588277],
                [105.3895383, 9.6589482],
                [105.3897119, 9.6590756],
                [105.3898804, 9.6592096],
                [105.3900432, 9.6593501],
                [105.3902084, 9.6594822],
                [105.3903793, 9.659607],
                [105.3905555, 9.6597243],
                [105.3907368, 9.659834],
                [105.3909227, 9.6599357],
                [105.391113, 9.6600293],
                [105.3913073, 9.6601146],
                [105.3915051, 9.6601915],
                [105.3917062, 9.6602597],
                [105.3918927, 9.6603145],
                [105.3920812, 9.6603619],
                [105.3922716, 9.6604019],
                [105.3924634, 9.6604343],
                [105.3926563, 9.6604591],
                [105.3928501, 9.6604763],
                [105.3930445, 9.6604858],
                [105.3932391, 9.6604877],
                [105.3933364, 9.6604862],
                [105.3934336, 9.6604818],
                [105.3936027, 9.6604672],
                [105.3937709, 9.6604437],
                [105.3939375, 9.6604114],
                [105.3941022, 9.6603705],
                [105.3942644, 9.6603209],
                [105.3944237, 9.6602629],
                [105.3945796, 9.6601966],
                [105.3947317, 9.6601222],
                [105.3949172, 9.6600239],
                [105.3950988, 9.659919],
                [105.3952765, 9.6598076],
                [105.3954499, 9.6596899],
                [105.3956188, 9.659566],
                [105.3957831, 9.6594362],
                [105.3959799, 9.6592872],
                [105.3963265, 9.6590585],
                [105.3967544, 9.6587286],
                [105.397135, 9.6584405],
                [105.3975458, 9.6581189],
                [105.3979511, 9.6577905],
                [105.3983506, 9.6574553],
                [105.3991198, 9.6568328],
                [105.40031, 9.6557769],
                [105.4006807, 9.6554566],
                [105.4010471, 9.6551316],
                [105.4014231, 9.6547892],
                [105.4017945, 9.6544419],
                [105.4020645, 9.6541531],
                [105.4023016, 9.6539111],
                [105.4024489, 9.6537379],
                [105.4026026, 9.6535702],
                [105.4027626, 9.6534083],
                [105.4029285, 9.6532524],
                [105.4031003, 9.6531027],
                [105.4032777, 9.6529595],
                [105.4034603, 9.6528229],
                [105.403648, 9.6526931],
                [105.4038405, 9.6525704],
                [105.4040362, 9.6524555],
                [105.4042361, 9.6523479],
                [105.4044399, 9.6522478],
                [105.4046473, 9.6521551],
                [105.4048581, 9.6520701],
                [105.4050719, 9.6519929],
                [105.4052885, 9.6519235],
                [105.4055076, 9.6518621],
                [105.4057288, 9.6518088],
                [105.4058517, 9.6517795],
                [105.4059761, 9.6517574],
                [105.4061015, 9.6517425],
                [105.4062277, 9.6517348],
                [105.4063681, 9.6517348],
                [105.4065081, 9.6517438],
                [105.4066339, 9.6517644],
                [105.4067957, 9.6518309],
                [105.406796, 9.651712],
                [105.4067881, 9.6515933],
                [105.4067722, 9.6514755],
                [105.4067483, 9.6513589],
                [105.4067164, 9.6512442],
                [105.4066768, 9.6511319],
                [105.4066296, 9.6510225],
                [105.4065754, 9.6509172],
                [105.4065143, 9.6508157],
                [105.4064463, 9.6507184],
                [105.406372, 9.6506258],
                [105.4062915, 9.6505384],
                [105.4062053, 9.6504564],
                [105.4061137, 9.6503804],
                [105.4059649, 9.6502923],
                [105.4054539, 9.6500854],
                [105.4048866, 9.6498693],
                [105.4046613, 9.6497529],
                [105.404494, 9.6496184],
                [105.4043207, 9.6494343],
                [105.4041939, 9.649269],
                [105.4036313, 9.6484043],
                [105.4034305, 9.6481429],
                [105.4032934, 9.6479998],
                [105.4031415, 9.6478993],
                [105.4029849, 9.6478319],
                [105.4023077, 9.6476097],
                [105.4017695, 9.6470419],
                [105.4012831, 9.6465765],
                [105.4012328, 9.6465216],
                [105.4012063, 9.6464661],
                [105.4011912, 9.6464039],
                [105.4011758, 9.6462612],
                [105.4011764, 9.6460199],
                [105.4011959, 9.645846],
                [105.4012267, 9.6456662],
                [105.4012777, 9.6454758],
                [105.4013441, 9.6452649],
                [105.4014011, 9.6451162],
                [105.4014721, 9.6449826],
                [105.4015566, 9.6448676],
                [105.4016384, 9.6447803],
                [105.4017122, 9.6447301],
                [105.4017833, 9.6446984],
                [105.4018537, 9.6446878],
                [105.4019127, 9.6446904],
                [105.4019704, 9.644703],
                [105.4022339, 9.6447995],
                [105.4027053, 9.6449357],
                [105.4030929, 9.6450507],
                [105.4031955, 9.6450785],
                [105.403288, 9.6450805],
                [105.4033752, 9.6450633],
                [105.4034563, 9.6450283],
                [105.4035274, 9.6449747],
                [105.4035837, 9.6449093],
                [105.4037574, 9.6446713],
                [105.4038399, 9.6446065],
                [105.403912, 9.6445731],
                [105.403981, 9.6445543],
                [105.4040561, 9.6445549],
                [105.4041312, 9.6445673],
                [105.4042556, 9.6446085],
                [105.4047418, 9.6447691],
                [105.4059474, 9.6449945],
                [105.406276, 9.6450871],
                [105.4064155, 9.6451069],
                [105.4065442, 9.645095],
                [105.406673, 9.6450435],
                [105.4067856, 9.6449655],
                [105.406858, 9.6448359],
                [105.4069117, 9.644619],
                [105.4069579, 9.644344],
                [105.4070149, 9.6439434],
                [105.4070243, 9.6436816],
                [105.4070015, 9.6434661],
                [105.4069452, 9.6432605],
                [105.4068862, 9.6431329],
                [105.4065865, 9.6425545],
                [105.4064886, 9.6423919],
                [105.4063806, 9.642257],
                [105.4062974, 9.6421797],
                [105.4062083, 9.6421208],
                [105.4058857, 9.6419946],
                [105.4057878, 9.6419404],
                [105.4057248, 9.6418835],
                [105.4056738, 9.6418068],
                [105.4056416, 9.6417182],
                [105.4055907, 9.6414961],
                [105.405537, 9.6412965],
                [105.4054911, 9.6412019],
                [105.4054344, 9.6411239],
                [105.4053124, 9.641034],
                [105.4051951, 9.6409699],
                [105.4050194, 9.640876],
                [105.4048695, 9.6407676],
                [105.4047203, 9.6405997],
                [105.4046435, 9.6404498],
                [105.4045949, 9.6402639],
                [105.4045473, 9.6399783],
                [105.4045245, 9.6393754],
                [105.4045084, 9.639259],
                [105.4044762, 9.6391585],
                [105.4044333, 9.6390792],
                [105.4043649, 9.6389985],
                [105.4041503, 9.6388372],
                [105.4040642, 9.6387447],
                [105.403976, 9.6386563],
                [105.4038566, 9.6385847],
                [105.4036783, 9.6384974],
                [105.4033417, 9.6383332],
                [105.4031995, 9.6382423],
                [105.4031056, 9.6381524],
                [105.4030345, 9.6380453],
                [105.4029621, 9.6378813],
                [105.4022842, 9.6363998],
                [105.4021172, 9.6360382],
                [105.4020636, 9.6359152],
                [105.402022, 9.6358385],
                [105.401967, 9.6357764],
                [105.4018966, 9.6357328],
                [105.4017994, 9.6356905],
                [105.4015124, 9.6355728],
                [105.4011235, 9.6354009],
                [105.4009585, 9.6353017],
                [105.4008593, 9.6351821],
                [105.400815, 9.6350928],
                [105.4003208, 9.6337078],
                [105.3995906, 9.6316855],
                [105.3991936, 9.6306172],
                [105.3991306, 9.6304083],
                [105.3990957, 9.630247],
                [105.3990836, 9.6300758],
                [105.399085, 9.6293657],
                [105.3991373, 9.6272707],
                [105.3991869, 9.6254923],
                [105.3992191, 9.6235989],
                [105.3993505, 9.6228161],
                [105.3994337, 9.6224379],
                [105.3994739, 9.6222079],
                [105.3994685, 9.6220439],
                [105.3994471, 9.6219064],
                [105.3991494, 9.6219937],
                [105.3990421, 9.6220545],
                [105.3989616, 9.6221471],
                [105.3987819, 9.6224009],
                [105.3985888, 9.6226574],
                [105.3984144, 9.6228478],
                [105.3982267, 9.6230171],
                [105.3980416, 9.623144],
                [105.3978726, 9.6232286],
                [105.3977117, 9.6232472],
                [105.3973335, 9.6232234],
                [105.3969607, 9.623226],
                [105.3966737, 9.6232577],
                [105.3963974, 9.6233159],
                [105.3962418, 9.6233847],
                [105.3961158, 9.6234878],
                [105.3959656, 9.6236795],
                [105.395873, 9.6237351],
                [105.395755, 9.6237681],
                [105.3956343, 9.6237734],
                [105.3955042, 9.6237615],
                [105.3953755, 9.6237258],
                [105.3951569, 9.6236187],
                [105.3950375, 9.6235698],
                [105.3949101, 9.6235539],
                [105.3947908, 9.6235539],
                [105.3946593, 9.6235751],
                [105.394308, 9.623665],
                [105.3941993, 9.6236875],
                [105.3940974, 9.6236848],
                [105.3939365, 9.6236623],
                [105.3937072, 9.6236491],
                [105.3934818, 9.6236438],
                [105.3931734, 9.6236914],
                [105.3928395, 9.6237509],
                [105.3926061, 9.623825],
                [105.392358, 9.623977],
                [105.3920016, 9.6243833],
                [105.3917518, 9.6247439],
                [105.3916579, 9.6249237],
                [105.3915882, 9.6250057],
                [105.3915185, 9.625056],
                [105.391438, 9.6250903],
                [105.3908882, 9.6251961],
                [105.3907728, 9.6252437],
                [105.3906736, 9.6253019],
                [105.3905931, 9.625368],
                [105.3905234, 9.6254791],
                [105.3904295, 9.6256642],
                [105.3902712, 9.6258367],
                [105.3899574, 9.6255346],
                [105.3896986, 9.625171],
                [105.3896503, 9.625089],
                [105.3896128, 9.6249978],
                [105.3895826, 9.6248794],
                [105.3895711, 9.6247488],
                [105.3896034, 9.6243182],
                [105.3896812, 9.6235394],
                [105.3896919, 9.6233926],
                [105.3896919, 9.6232921],
                [105.3896624, 9.6232075],
                [105.3896087, 9.6231282],
                [105.3895283, 9.6230568],
                [105.3892788, 9.6228954],
                [105.3890079, 9.6226495],
                [105.3886887, 9.6222713],
                [105.3885037, 9.6219937],
                [105.3884339, 9.6218905],
                [105.3883696, 9.6218191],
                [105.388273, 9.6217662],
                [105.3881711, 9.621753],
                [105.3878251, 9.6217292],
                [105.3877097, 9.6217107],
                [105.3874013, 9.6216076],
                [105.387015, 9.6215468],
                [105.3868756, 9.6214939],
                [105.3866664, 9.6213564],
                [105.3865162, 9.6213035],
                [105.3861004, 9.6212294],
                [105.3857115, 9.6210919],
                [105.38499, 9.6209782],
                [105.384247, 9.6208222],
                [105.3838755, 9.6207442],
                [105.3836623, 9.6206794],
                [105.3835188, 9.6206159],
                [105.3834075, 9.6205233],
                [105.3832787, 9.6203647],
                [105.3831661, 9.6202126],
                [105.3830574, 9.6201108],
                [105.3829086, 9.6200262],
                [105.3826578, 9.6199482],
                [105.3824593, 9.6199059],
                [105.3821482, 9.6198596],
                [105.3819068, 9.6197842],
                [105.3815916, 9.619652],
                [105.381365, 9.6195317],
                [105.3811598, 9.6193518],
                [105.3810149, 9.6191747],
                [105.3808956, 9.6189895],
                [105.3808017, 9.6187568],
                [105.3805804, 9.617974],
                [105.3804436, 9.6174716],
                [105.3803015, 9.6171146],
                [105.3798683, 9.6172852],
                [105.3796269, 9.6173328],
                [105.3793788, 9.6173407],
                [105.3791159, 9.6173209],
                [105.3788678, 9.6172673],
                [105.3786546, 9.6171847],
                [105.378261, 9.6169361],
                [105.3771271, 9.6162141],
                [105.3769413, 9.616154],
                [105.3767288, 9.6161202],
                [105.3766859, 9.6159999],
                [105.3765973, 9.6158161],
                [105.3763787, 9.6154499],
                [105.3762782, 9.6152343],
                [105.3762017, 9.6150347],
                [105.3760985, 9.6146565],
                [105.3760609, 9.6145573],
                [105.3760193, 9.6144846],
                [105.3757752, 9.6142129],
                [105.3755996, 9.6139451],
                [105.3754051, 9.6136463],
                [105.3752522, 9.613325],
                [105.3748633, 9.6119551],
                [105.3747466, 9.611725],
                [105.3746138, 9.6115558],
                [105.3745374, 9.6114143],
                [105.3744838, 9.6112635],
                [105.374414, 9.6111677],
                [105.3743248, 9.6110824],
                [105.3740546, 9.6109138],
                [105.3739929, 9.6108688],
                [105.3739581, 9.6108166],
                [105.373944, 9.6107551],
                [105.3739366, 9.6107042],
                [105.3739527, 9.6104133],
                [105.3739607, 9.6102467],
                [105.3739768, 9.6100854],
                [105.3740144, 9.6099082],
                [105.37406, 9.6097495],
                [105.3741217, 9.6096252],
                [105.3742598, 9.6094563],
                [105.3743054, 9.6093846],
                [105.3743269, 9.6093238],
                [105.3743564, 9.6091565],
                [105.3744261, 9.6088795],
                [105.3744435, 9.6088001],
                [105.3744435, 9.6087234],
                [105.3744211, 9.6084643],
                [105.3744149, 9.608356],
                [105.3744328, 9.6082633],
                [105.3744918, 9.608102],
                [105.3745133, 9.6080187],
                [105.3745119, 9.6079353],
                [105.3744516, 9.6077039],
                [105.374406, 9.6074725],
                [105.3743956, 9.6072636],
                [105.3744234, 9.6071645],
                [105.3737529, 9.6021794],
                [105.3732043, 9.5977033],
                [105.3729769, 9.5967593],
                [105.3736335, 9.5962858],
                [105.3741905, 9.5957396],
                [105.3756759, 9.5940828],
                [105.376423, 9.5932073],
                [105.3767449, 9.5927974],
                [105.3769663, 9.5923953],
                [105.3771096, 9.5919908],
                [105.3772062, 9.5916046],
                [105.3772652, 9.5910916],
                [105.3772321, 9.5903168],
                [105.3771422, 9.5889194],
                [105.3771472, 9.5881877],
                [105.3772783, 9.5875678],
                [105.377512, 9.5870028],
                [105.3778285, 9.5865691],
                [105.3782977, 9.5861884],
                [105.3788799, 9.5858656],
                [105.3795344, 9.585691],
                [105.3801995, 9.5856064],
                [105.3809465, 9.5856171],
                [105.3816265, 9.5856751],
                [105.3824043, 9.5857915],
                [105.3838186, 9.5861325],
                [105.3846949, 9.5864474],
                [105.3854835, 9.5868283],
                [105.3861827, 9.5872921],
                [105.3872189, 9.5881691],
                [105.3883092, 9.5894716],
                [105.3887504, 9.5898776],
                [105.3892405, 9.5902139],
                [105.3896999, 9.5903934],
                [105.3901345, 9.5904833],
                [105.3905351, 9.5904733],
                [105.3908721, 9.5904145],
                [105.3912342, 9.5902982],
                [105.3917082, 9.5900764],
                [105.3922105, 9.5896793],
                [105.3933195, 9.5887033],
                [105.3937474, 9.5883569],
                [105.3940824, 9.5881812],
                [105.3944904, 9.5880766],
                [105.3949463, 9.5880819],
                [105.3953192, 9.5881533],
                [105.3956093, 9.588326],
                [105.3958717, 9.5886029],
                [105.3960729, 9.588915],
                [105.3962224, 9.5892434],
                [105.3963357, 9.5897057],
                [105.3965037, 9.5905459],
                [105.3965986, 9.5908668],
                [105.3967488, 9.5911762],
                [105.3969555, 9.5914479],
                [105.3971994, 9.5916813],
                [105.397933, 9.5922406],
                [105.3991622, 9.5932801],
                [105.3995417, 9.5938535],
                [105.3995777, 9.5945343],
                [105.3991434, 9.5957524],
                [105.3985948, 9.5968757],
                [105.3984919, 9.5977229],
                [105.3987992, 9.5982068],
                [105.3994132, 9.5984854],
                [105.4006218, 9.5984732],
                [105.4034459, 9.598011],
                [105.4062343, 9.5979663],
                [105.4067365, 9.5979582],
                [105.4093428, 9.59763],
                [105.4095753, 9.5976007],
                [105.4107143, 9.5979771],
                [105.4122147, 9.5986583],
                [105.4135342, 9.6001636],
                [105.4146548, 9.6015075],
                [105.4153737, 9.6018194],
                [105.4155227, 9.601884],
                [105.4167161, 9.6015797],
                [105.4177831, 9.6004154],
                [105.4180908, 9.5985344],
                [105.416853, 9.5957123],
                [105.4164917, 9.5946373],
                [105.4168536, 9.5935624],
                [105.4177216, 9.592631],
                [105.4178073, 9.5926371],
                [105.4187341, 9.5927029],
                [105.4203249, 9.5937066],
                [105.4219157, 9.5952118],
                [105.4230004, 9.5954987],
                [105.4256765, 9.5950693],
                [105.4281354, 9.5948549],
                [105.4290753, 9.5955],
                [105.4295815, 9.5960734],
                [105.4295812, 9.5977217],
                [105.4291469, 9.5994416],
                [105.4293727, 9.600203],
                [105.4297885, 9.600651],
                [105.430331, 9.6006869],
                [105.4311988, 9.6003108],
                [105.4313481, 9.6002036],
                [105.431637, 9.5999962],
                [105.4326455, 9.5992721],
                [105.4344357, 9.5983228],
                [105.4367321, 9.597535],
                [105.4387572, 9.5963529],
                [105.4405836, 9.5948124],
                [105.4417407, 9.5945977],
                [105.4427893, 9.5949562],
                [105.4441995, 9.5959955],
                [105.4451937, 9.5967661],
                [105.4457542, 9.5968737],
                [105.4464413, 9.5968738],
                [105.4473635, 9.5962648],
                [105.4482497, 9.5943837],
                [105.4486297, 9.5923414],
                [105.4484853, 9.5904781],
                [105.4482685, 9.5893852],
                [105.4482868, 9.588364],
                [105.4487027, 9.5874683],
                [105.4493175, 9.5869667],
                [105.4501853, 9.5868772],
                [105.451686, 9.5875762],
                [105.4543976, 9.5895294],
                [105.4556813, 9.5901924],
                [105.4567479, 9.5903359],
                [105.4580316, 9.590121],
                [105.4593153, 9.5894404],
                [105.4602738, 9.5879355],
                [105.460979, 9.5868965],
                [105.4617021, 9.586628],
                [105.4624255, 9.5868429],
                [105.4634197, 9.5880077],
                [105.463673, 9.5882499],
                [105.4649743, 9.5894948],
                [105.4666016, 9.5900503],
                [105.4680659, 9.590785],
                [105.4689879, 9.5919676],
                [105.4690419, 9.593347],
                [105.4690418, 9.5943325],
                [105.4689153, 9.5958374],
                [105.4692044, 9.5964824],
                [105.4698192, 9.5970199],
                [105.4707594, 9.5970021],
                [105.47179, 9.596178],
                [105.4743033, 9.5935266],
                [105.4759668, 9.5923801],
                [105.4768889, 9.5919503],
                [105.4775218, 9.5910724],
                [105.4779015, 9.5901409],
                [105.4776846, 9.5893168],
                [105.476636, 9.5880804],
                [105.475479, 9.5867367],
                [105.4751537, 9.5858229],
                [105.4752442, 9.5850525],
                [105.4755153, 9.5844793],
                [105.4766183, 9.5844256],
                [105.4780143, 9.5846966],
                [105.4784077, 9.5847324],
                [105.4788017, 9.5847608],
                [105.4791961, 9.5847816],
                [105.4795456, 9.5847938],
                [105.4798953, 9.5848001],
                [105.480245, 9.5848005],
                [105.4805946, 9.584795],
                [105.4807851, 9.5847971],
                [105.4810372, 9.5847971],
                [105.4813365, 9.5847998],
                [105.4816273, 9.5848103],
                [105.4819763, 9.5848291],
                [105.4823059, 9.5848526],
                [105.4827311, 9.584875],
                [105.4835349, 9.5849192],
                [105.4839742, 9.5849452],
                [105.4843382, 9.5849711],
                [105.4844997, 9.584978],
                [105.4846614, 9.5849753],
                [105.4848226, 9.5849631],
                [105.4849828, 9.5849414],
                [105.4851414, 9.5849104],
                [105.4852978, 9.58487],
                [105.4854515, 9.5848206],
                [105.4856019, 9.5847621],
                [105.4857485, 9.5846949],
                [105.4858923, 9.5846183],
                [105.4860311, 9.5845333],
                [105.4861645, 9.5844402],
                [105.4862919, 9.5843393],
                [105.486413, 9.5842311],
                [105.4865271, 9.5841158],
                [105.486634, 9.5839939],
                [105.4867333, 9.5838659],
                [105.4868336, 9.5836967],
                [105.4869412, 9.583532],
                [105.487056, 9.5833721],
                [105.4871776, 9.5832171],
                [105.487306, 9.5830676],
                [105.4874408, 9.5829236],
                [105.4875817, 9.5827855],
                [105.4877281, 9.582654],
                [105.4878801, 9.5825288],
                [105.4880373, 9.5824103],
                [105.4881997, 9.5822985],
                [105.4883667, 9.5821937],
                [105.4891476, 9.5821509],
                [105.4900877, 9.5826167],
                [105.4916425, 9.5840321],
                [105.4931249, 9.5860567],
                [105.4933961, 9.5867913],
                [105.4933237, 9.5874362],
                [105.4927814, 9.5886723],
                [105.4924739, 9.5896757],
                [105.4925102, 9.5902311],
                [105.4928175, 9.590679],
                [105.493378, 9.590876],
                [105.4944447, 9.5907148],
                [105.4959453, 9.5902133],
                [105.4978077, 9.5899803],
                [105.5003873, 9.5903094],
                [105.5033416, 9.5913146],
                [105.5041848, 9.5913133],
                [105.504755, 9.5910909],
                [105.5050522, 9.5907704],
                [105.5053242, 9.5902777],
                [105.5052985, 9.5897362],
                [105.5050992, 9.5890964],
                [105.5042286, 9.5874239],
                [105.5041781, 9.5868614],
                [105.5042273, 9.5866149],
                [105.5044252, 9.5862451],
                [105.5048217, 9.5859982],
                [105.5065571, 9.585503],
                [105.5104988, 9.5842407],
                [105.5109967, 9.584103],
                [105.5113666, 9.5840671],
                [105.5119618, 9.5840662],
                [105.5124828, 9.5841393],
                [105.512855, 9.5842865],
                [105.5130788, 9.5844833],
                [105.5133766, 9.5847045],
                [105.5138491, 9.5854921],
                [105.5138748, 9.5861325],
                [105.5138537, 9.5884974],
                [105.5140532, 9.5892114],
                [105.5143514, 9.5896052],
                [105.5147486, 9.5898015],
                [105.5156418, 9.590022],
                [105.5161874, 9.5899472],
                [105.5172037, 9.5895023],
                [105.5191365, 9.5883662],
                [105.5198805, 9.5883405],
                [105.5206002, 9.5885118],
                [105.5211463, 9.5888805],
                [105.521718, 9.5896679],
                [105.5218926, 9.590382],
                [105.5216718, 9.5918357],
                [105.5213755, 9.5927229],
                [105.5212042, 9.5941765],
                [105.5212294, 9.5944722],
                [105.5213537, 9.5946691],
                [105.5216765, 9.5948163],
                [105.5224704, 9.5949876],
                [105.5259677, 9.5950562],
                [105.5291429, 9.5952483],
                [105.5302589, 9.5951973],
                [105.5318466, 9.5953673],
                [105.5325166, 9.5955633],
                [105.5329138, 9.5957597],
                [105.533066, 9.5959104],
                [105.5342056, 9.5970386],
                [105.5347019, 9.5972349],
                [105.5350989, 9.597259],
                [105.5357434, 9.5970362],
                [105.5361646, 9.5967153],
                [105.53661, 9.5960741],
                [105.5370047, 9.5947926],
                [105.5369946, 9.5942978],
                [105.5369779, 9.5934871],
                [105.536927, 9.5927482],
                [105.536976, 9.5923046],
                [105.5370996, 9.5920089],
                [105.5378179, 9.5914165],
                [105.5405687, 9.5899835],
                [105.5415595, 9.5891936],
                [105.5425997, 9.5882559],
                [105.543045, 9.5875408],
                [105.5434154, 9.5866042],
                [105.5436622, 9.5857662],
                [105.5438848, 9.5853963],
                [105.5443555, 9.5851001],
                [105.5448515, 9.58505],
                [105.5451989, 9.5851233],
                [105.545472, 9.58532],
                [105.5456956, 9.5855413],
                [105.5460081, 9.5861594],
                [105.5460689, 9.5862797],
                [105.5478106, 9.5896763],
                [105.5489793, 9.5914234],
                [105.5492029, 9.5917433],
                [105.5492282, 9.5920882],
                [105.5490803, 9.592581],
                [105.5488334, 9.5933205],
                [105.5473485, 9.5952442],
                [105.5470768, 9.5960822],
                [105.5470672, 9.5962088],
                [105.5470524, 9.5964024],
                [105.5470525, 9.5964497],
                [105.5471522, 9.5967223],
                [105.5474071, 9.5969631],
                [105.5478054, 9.5970633],
                [105.5484921, 9.5971558],
                [105.5496463, 9.5973594],
                [105.5512868, 9.5974726],
                [105.5522302, 9.597413],
                [105.5533354, 9.5971473],
                [105.5560576, 9.5962155],
                [105.556777, 9.5961691],
                [105.5571245, 9.5962671],
                [105.5574722, 9.5964883],
                [105.5576711, 9.596759],
                [105.5579449, 9.5972266],
                [105.5585942, 9.5999112],
                [105.558657, 9.6005027],
                [105.5586449, 9.6006875],
                [105.5582262, 9.6025969],
                [105.558177, 9.6028924],
                [105.5582275, 9.6033233],
                [105.5584888, 9.6038154],
                [105.559123, 9.6046296],
                [105.5603649, 9.6053423],
                [105.5624757, 9.6062999],
                [105.5638163, 9.6066674],
                [105.5648584, 9.6066164],
                [105.5656398, 9.6064056],
                [105.5658504, 9.6062327],
                [105.5672863, 9.6042219],
                [105.567955, 9.6034814],
                [105.5684259, 9.6032096],
                [105.5688477, 9.6031597],
                [105.5691582, 9.6032947],
                [105.5694439, 9.6035159],
                [105.5697173, 9.6037866],
                [105.5702413, 9.6054862],
                [105.5705904, 9.6065576],
                [105.5708144, 9.6068899],
                [105.5716096, 9.6076032],
                [105.5720056, 9.6078148],
                [105.5735579, 9.6084059],
                [105.5746385, 9.6089339],
                [105.5750113, 9.6092784],
                [105.5753224, 9.6097462],
                [105.5754098, 9.6100418],
                [105.5753978, 9.6102883],
                [105.575077, 9.611398],
                [105.5749049, 9.6123227],
                [105.574856, 9.6127788],
                [105.5749691, 9.6136044],
                [105.575231, 9.6143064],
                [105.5756289, 9.6146755],
                [105.5761131, 9.6149582],
                [105.5764484, 9.6150439],
                [105.5769199, 9.6150061],
                [105.5791649, 9.6142382],
                [105.5825252, 9.6126179],
                [105.5835295, 9.6120616],
                [105.5840256, 9.6119129],
                [105.5844103, 9.6119369],
                [105.5846711, 9.612035],
                [105.5868317, 9.6127215],
                [105.5873156, 9.6126837],
                [105.5875015, 9.6125232],
                [105.5875881, 9.6123504],
                [105.5875874, 9.6120546],
                [105.5875367, 9.6114386],
                [105.587113, 9.6103423],
                [105.5870379, 9.6100221],
                [105.5870501, 9.6098988],
                [105.5871488, 9.6095165],
                [105.5874457, 9.6090354],
                [105.5878174, 9.6086897],
                [105.5882143, 9.6084918],
                [105.5902368, 9.6083158],
                [105.5908445, 9.6081299],
                [105.5914519, 9.6077714],
                [105.5925919, 9.606845],
                [105.5928023, 9.6064996],
                [105.5928638, 9.6061298],
                [105.5928008, 9.6057232],
                [105.5925391, 9.6050088],
                [105.5924767, 9.6047871],
                [105.5924512, 9.6044544],
                [105.592488, 9.6041709],
                [105.5925495, 9.6038873],
                [105.5926856, 9.6036776],
                [105.5931692, 9.6033932],
                [105.5950051, 9.6029956],
                [105.5950944, 9.6032267],
                [105.5951853, 9.6034542],
                [105.5953438, 9.6051025],
                [105.5955319, 9.6061745],
                [105.5955571, 9.6063716],
                [105.5955079, 9.6066429],
                [105.5952608, 9.6072595],
                [105.5951997, 9.6078142],
                [105.5952499, 9.6080729],
                [105.5955613, 9.608738],
                [105.5959095, 9.6091563],
                [105.596092, 9.6092752],
                [105.5964025, 9.6094775],
                [105.5964189, 9.6094883],
                [105.5985808, 9.610951],
                [105.6000468, 9.6118358],
                [105.6006444, 9.6123548],
                [105.6018249, 9.6136813],
                [105.6033915, 9.6154164],
                [105.60388, 9.6159364],
                [105.6042669, 9.6162715],
                [105.6047313, 9.6166364],
                [105.6058547, 9.6174145],
                [105.6059747, 9.6175275],
                [105.6060595, 9.61766],
                [105.6061172, 9.6178041],
                [105.6061542, 9.617966],
                [105.6062169, 9.6185612],
                [105.6062974, 9.6191059],
                [105.60635, 9.6193323],
                [105.6064305, 9.6195396],
                [105.6065324, 9.6197491],
                [105.6066515, 9.6199194],
                [105.6067974, 9.6200336],
                [105.6069776, 9.6201055],
                [105.607189, 9.6201542],
                [105.607484, 9.6201817],
                [105.6077909, 9.6201658],
                [105.6081074, 9.620133],
                [105.6084807, 9.6200675],
                [105.6087758, 9.619986],
                [105.6091234, 9.6198263],
                [105.6099077, 9.6193862],
                [105.6102692, 9.619217],
                [105.6105836, 9.619107],
                [105.6108572, 9.6190361],
                [105.6110374, 9.6190213],
                [105.6112026, 9.6190456],
                [105.6116919, 9.6192519],
                [105.6118732, 9.619346],
                [105.6120635, 9.6194873],
                [105.6123342, 9.6197342],
                [105.6131535, 9.6206228],
                [105.6138556, 9.6213518],
                [105.6143129, 9.6217811],
                [105.6145741, 9.6219409],
                [105.6148278, 9.6220281],
                [105.6150403, 9.6220363],
                [105.6152278, 9.6220035],
                [105.6154069, 9.6219461],
                [105.6155734, 9.6218662],
                [105.6157776, 9.6217184],
                [105.6165372, 9.6210852],
                [105.6175769, 9.6203004],
                [105.6177292, 9.6201449],
                [105.6178204, 9.6200137],
                [105.6178762, 9.6198783],
                [105.6179202, 9.619726],
                [105.6179427, 9.6195726],
                [105.6179373, 9.6194203],
                [105.6179031, 9.6192757],
                [105.6178119, 9.6190631],
                [105.6171303, 9.6179591],
                [105.6170782, 9.6178297],
                [105.6170438, 9.6176795],
                [105.6169668, 9.6171044],
                [105.6170393, 9.6158629],
                [105.6170583, 9.6157173],
                [105.6170952, 9.6156009],
                [105.6171475, 9.6155093],
                [105.6172188, 9.615431],
                [105.6173162, 9.6153767],
                [105.6174453, 9.6153483],
                [105.6175734, 9.6153376],
                [105.6177105, 9.6153545],
                [105.6178693, 9.6153963],
                [105.618019, 9.6154684],
                [105.6186641, 9.6159093],
                [105.6197359, 9.6164946],
                [105.62008, 9.6166211],
                [105.6204487, 9.6167094],
                [105.6232602, 9.617037],
                [105.6235136, 9.6170355],
                [105.6237503, 9.6169861],
                [105.6239749, 9.6168874],
                [105.6246999, 9.61637],
                [105.6249347, 9.6162492],
                [105.6252026, 9.6161687],
                [105.6254425, 9.6161486],
                [105.6256721, 9.6161788],
                [105.6260294, 9.616297],
                [105.6268663, 9.6166769],
                [105.6285862, 9.617721],
                [105.6298923, 9.6184018],
                [105.6304158, 9.6186068],
                [105.6306783, 9.6187205],
                [105.6309484, 9.6188686],
                [105.6311911, 9.6190436],
                [105.6322441, 9.6199442],
                [105.6323898, 9.6200249],
                [105.6325355, 9.6200653],
                [105.632675, 9.6200653],
                [105.6328162, 9.6200234],
                [105.6329876, 9.6199262],
                [105.6332531, 9.6197302],
                [105.6337842, 9.619247],
                [105.6342318, 9.618879],
                [105.6344199, 9.6187788],
                [105.6346566, 9.618701],
                [105.6348721, 9.6186531],
                [105.635083, 9.6186442],
                [105.6352984, 9.6186756],
                [105.6355124, 9.6187788],
                [105.6358598, 9.619081],
                [105.636048, 9.6192859],
                [105.6365623, 9.6199382],
                [105.6370372, 9.6204244],
                [105.6375076, 9.6209614],
                [105.6378952, 9.6215199],
                [105.6384016, 9.6224317],
                [105.63882, 9.6230109],
                [105.6391874, 9.6234411],
                [105.6394031, 9.6236172],
                [105.6396684, 9.6237795],
                [105.6400755, 9.6239229],
                [105.6410107, 9.6241481],
                [105.6413143, 9.6242449],
                [105.6421626, 9.6246653],
                [105.6424107, 9.6247409],
                [105.6426453, 9.6247453],
                [105.6428708, 9.6247026],
                [105.6431803, 9.6246288],
                [105.6433779, 9.6246012],
                [105.6436, 9.6246107],
                [105.6439075, 9.6246724],
                [105.6444753, 9.6248108],
                [105.6446756, 9.6249038],
                [105.6451477, 9.6252108],
                [105.6453582, 9.6252963],
                [105.6457435, 9.6253831],
                [105.6460395, 9.6253995],
                [105.6463432, 9.6253806],
                [105.6465958, 9.6253366],
                [105.6467502, 9.6255718],
                [105.6470628, 9.6259102],
                [105.6472325, 9.6262234],
                [105.6473549, 9.6265945],
                [105.6475254, 9.6273451],
                [105.6476604, 9.6277304],
                [105.6478115, 9.6282981],
                [105.6479604, 9.6285807],
                [105.6485738, 9.6294929],
                [105.6487063, 9.6297205],
                [105.6488107, 9.6299735],
                [105.6488666, 9.6302339],
                [105.6489468, 9.6308205],
                [105.6489823, 9.6312373],
                [105.6489597, 9.6330185],
                [105.6489827, 9.6334871],
                [105.6490293, 9.6338415],
                [105.6491177, 9.6340891],
                [105.6492572, 9.6342805],
                [105.649438, 9.6344086],
                [105.6496793, 9.6344998],
                [105.6499418, 9.6345385],
                [105.6518064, 9.6346782],
                [105.653825, 9.6350826],
                [105.6547724, 9.6352704],
                [105.6554861, 9.6353191],
                [105.6562428, 9.6353255],
                [105.6570105, 9.6353376],
                [105.6586659, 9.6352364],
                [105.6589091, 9.6352458],
                [105.6591263, 9.6352864],
                [105.6593456, 9.6353486],
                [105.6595498, 9.6354347],
                [105.6597492, 9.6355762],
                [105.659903, 9.6357716],
                [105.6609872, 9.6373578],
                [105.6611415, 9.6376446],
                [105.661256, 9.6379518],
                [105.6613082, 9.6381798],
                [105.6613685, 9.6388991],
                [105.6614476, 9.6391534],
                [105.6616032, 9.6393855],
                [105.6618275, 9.6396075],
                [105.6620882, 9.6397802],
                [105.6624068, 9.6399718],
                [105.6638171, 9.6406275],
                [105.6651947, 9.6410473],
                [105.6662204, 9.6414111],
                [105.6671624, 9.6417916],
                [105.6673785, 9.6411428],
                [105.66739, 9.6410543],
                [105.6673582, 9.640955],
                [105.66736, 9.6408483],
                [105.6674981, 9.6404792],
                [105.6674982, 9.6404146],
                [105.6674467, 9.640359],
                [105.6666303, 9.6400107],
                [105.6665728, 9.6399448],
                [105.6665665, 9.6398594],
                [105.6669386, 9.6386851],
                [105.668483, 9.6344298],
                [105.6690007, 9.6332115],
                [105.6730983, 9.6371095],
                [105.6845418, 9.6479132],
                [105.6908681, 9.6539502],
                [105.6945088, 9.6573984],
                [105.6964161, 9.6589691],
                [105.6971996, 9.659645],
                [105.7009417, 9.6621748],
                [105.7058147, 9.6653676],
                [105.707294, 9.6662881],
                [105.7077849, 9.6666967],
                [105.7081066, 9.6669265],
                [105.7086615, 9.6673752],
                [105.709072, 9.6677145],
                [105.7095046, 9.6680318],
                [105.710159, 9.6684803],
                [105.710758, 9.6688412],
                [105.7116673, 9.6693441],
                [105.7134086, 9.6704487],
                [105.7141184, 9.670886],
                [105.7152233, 9.6715675],
                [105.7163936, 9.6722829],
                [105.7176342, 9.6731023],
                [105.7188746, 9.6738754],
                [105.7196354, 9.6743601],
                [105.7206562, 9.6750101],
                [105.7216708, 9.6756429],
                [105.7226543, 9.6762657],
                [105.7236171, 9.6768372],
                [105.7260177, 9.6783646],
                [105.7272258, 9.6791324],
                [105.7286086, 9.6799794],
                [105.7302194, 9.6809455],
                [105.7319951, 9.682011],
                [105.7336082, 9.6829651],
                [105.734612, 9.6836132],
                [105.735604, 9.6843321],
                [105.7367324, 9.6850178],
                [105.7381979, 9.6858922],
                [105.7396152, 9.6867477],
                [105.7405505, 9.6873274],
                [105.7415952, 9.6880246],
                [105.7427493, 9.688783],
                [105.7439383, 9.6896452],
                [105.74497, 9.690352],
                [105.7460366, 9.6910415],
                [105.7471903, 9.6917135],
                [105.7486589, 9.6926269],
                [105.7495532, 9.693213],
                [105.7504078, 9.6938057],
                [105.7527357, 9.6952657],
                [105.7553566, 9.6968805],
                [105.757039, 9.6978873],
                [105.7577545, 9.6983156],
                [105.7601691, 9.6998325],
                [105.7625671, 9.701284],
                [105.7657576, 9.7032084],
                [105.7673923, 9.7043015],
                [105.7718419, 9.7070414],
                [105.7766545, 9.7098786],
                [105.7776533, 9.7104657],
                [105.777904, 9.7104215],
                [105.7796705, 9.7097736],
                [105.7817452, 9.7111601],
                [105.7848317, 9.7129789],
                [105.7864232, 9.7138993],
                [105.7882592, 9.7149884],
                [105.7894981, 9.7156168],
                [105.7920274, 9.7170085],
                [105.7954777, 9.7190404],
                [105.7977771, 9.7204926],
                [105.7988204, 9.7211515],
                [105.8000654, 9.721967],
                [105.8036581, 9.7240506],
                [105.8059453, 9.7254069],
                [105.806369, 9.7257245],
                [105.8094877, 9.7276334],
                [105.8097822, 9.7278423],
                [105.8104141, 9.728169],
                [105.8130346, 9.7297849],
                [105.8159173, 9.7314577],
                [105.818948, 9.733288],
                [105.8210697, 9.7345692],
                [105.8219788, 9.7351181],
                [105.8241567, 9.7364544],
                [105.8255126, 9.7372879],
                [105.8292878, 9.7397353],
                [105.8311875, 9.7408434],
                [105.8334754, 9.7421343],
                [105.835161, 9.7433747],
                [105.8359792, 9.7440277],
                [105.8380281, 9.7453983],
                [105.839102, 9.7460833],
                [105.8412752, 9.74765],
                [105.8424647, 9.7484004],
                [105.8428862, 9.7486941],
                [105.8433525, 9.7490181],
                [105.8434609, 9.7511456],
                [105.843497, 9.7522237],
                [105.843589, 9.7531226],
                [105.8436659, 9.7543674],
                [105.8439106, 9.7586707],
                [105.8444245, 9.7623582],
                [105.8445116, 9.7632666],
                [105.845475, 9.7663616],
                [105.8468218, 9.7706993],
                [105.8474479, 9.7727822],
                [105.8476764, 9.7732937],
                [105.8479835, 9.7737705],
                [105.8475128, 9.7743761],
                [105.8481646, 9.7748493],
                [105.8482289, 9.7749233],
                [105.8482665, 9.7750026],
                [105.8482933, 9.7751057],
                [105.8483604, 9.7756449],
                [105.848422, 9.7762542],
                [105.8485655, 9.7769863],
                [105.8498362, 9.7831794],
                [105.8504706, 9.7864735],
                [105.8509983, 9.7891064],
                [105.851634, 9.7922954],
                [105.8521691, 9.7951899],
                [105.8534105, 9.8013305],
                [105.853704, 9.8022443],
                [105.8538924, 9.8026831],
                [105.855318, 9.8055395],
                [105.8503992, 9.8067431],
                [105.8485729, 9.8071974],
                [105.849224, 9.8101826],
                [105.850136, 9.8140202],
                [105.851455, 9.8196926],
                [105.8523998, 9.8226553],
                [105.8529174, 9.8240891],
                [105.8529912, 9.8244518],
                [105.8538978, 9.8290583],
                [105.8546394, 9.8328772],
                [105.8547353, 9.8335967],
                [105.8547749, 9.8342475],
                [105.8548406, 9.8346743],
                [105.8557056, 9.8387633],
                [105.8561173, 9.8405756],
                [105.8561475, 9.8407368],
                [105.8561606, 9.8409916],
                [105.8563352, 9.8411511],
                [105.8563976, 9.8413711],
                [105.8569173, 9.8426938],
                [105.8578017, 9.8450735],
                [105.859977, 9.8506945],
                [105.8606127, 9.8522907],
                [105.8610177, 9.8532738],
                [105.8610539, 9.8533279],
                [105.8611035, 9.8533583],
                [105.8611666, 9.8533676],
                [105.8612403, 9.8533517],
                [105.8631621, 9.8525966],
                [105.8635537, 9.8524836],
                [105.8653039, 9.8615874],
                [105.8654433, 9.862441],
                [105.8655775, 9.8632284],
                [105.8662775, 9.8664682],
                [105.8671439, 9.8706249],
                [105.8673304, 9.8716217],
                [105.8676615, 9.8732066],
                [105.8677393, 9.8733388],
                [105.8686486, 9.8739808],
                [105.8690938, 9.8743257],
                [105.8692118, 9.874364],
                [105.8694351, 9.8742973],
                [105.8695035, 9.8742867],
                [105.8695646, 9.8743019],
                [105.8696625, 9.8743719],
                [105.8697215, 9.8744063],
                [105.8698046, 9.8744327],
                [105.8699025, 9.8744261],
                [105.8699977, 9.874401],
                [105.8701909, 9.874512],
                [105.8703142, 9.8746124],
                [105.8703786, 9.8747287],
                [105.8703786, 9.8748344],
                [105.870325, 9.8749137],
                [105.8699736, 9.8749586],
                [105.8697832, 9.8750088],
                [105.869869, 9.8753127],
                [105.8697027, 9.8753602],
                [105.869633, 9.8754237],
                [105.8695713, 9.8755426],
                [105.8693969, 9.8757328],
                [105.8692923, 9.8759099],
                [105.8692494, 9.8761609],
                [105.8691126, 9.8764423],
                [105.8691019, 9.8765467],
                [105.8691502, 9.8767422],
                [105.8691072, 9.8768162],
                [105.8687746, 9.8769272],
                [105.8686352, 9.8770091],
                [105.8685225, 9.8771228],
                [105.8685025, 9.8772097],
                [105.8691421, 9.8805236],
                [105.8699146, 9.8802646],
                [105.8700621, 9.880727],
                [105.8709982, 9.8804337],
                [105.8711484, 9.8808909],
                [105.8714569, 9.8808169],
                [105.8714582, 9.8811736],
                [105.8710948, 9.8814537],
                [105.8711779, 9.8818752],
                [105.8712812, 9.8823204],
                [105.871017, 9.8824248],
                [105.8711725, 9.8828833],
                [105.8712396, 9.882952],
                [105.871245, 9.8830841],
                [105.8712074, 9.8832083],
                [105.8711296, 9.8832849],
                [105.8709285, 9.8834434],
                [105.8710445, 9.8838623],
                [105.8703008, 9.8842679],
                [105.870215, 9.8842916],
                [105.8701318, 9.8842943],
                [105.8697081, 9.8842018],
                [105.8696142, 9.8842177],
                [105.8695176, 9.884252],
                [105.8685802, 9.884799],
                [105.8718538, 9.8900197],
                [105.8701345, 9.8906135],
                [105.8687277, 9.8910957],
                [105.8688819, 9.8921078],
                [105.8694318, 9.8928318],
                [105.8721328, 9.8961347],
                [105.8725727, 9.8982908],
                [105.8733371, 9.8991812],
                [105.8736455, 9.8995036],
                [105.8737045, 9.8995855],
                [105.8737314, 9.8996727],
                [105.8737421, 9.8997731],
                [105.8737166, 9.9001166],
                [105.8737257, 9.9002034],
                [105.8737569, 9.9002949],
                [105.8738239, 9.9003914],
                [105.8740734, 9.9006728],
                [105.8737689, 9.9008934],
                [105.8734927, 9.9010546],
                [105.8731869, 9.9011973],
                [105.8714072, 9.9019648],
                [105.8715333, 9.9021234],
                [105.8718579, 9.9023731],
                [105.8727457, 9.9029279],
                [105.8729026, 9.90306],
                [105.8730474, 9.9032133],
                [105.8731815, 9.9033718],
                [105.8733103, 9.90357],
                [105.8735704, 9.9039584],
                [105.8736992, 9.9041196],
                [105.8739835, 9.9044287],
                [105.8748659, 9.9053059],
                [105.8744609, 9.9055081],
                [105.8736724, 9.9058661],
                [105.8734739, 9.9059824],
                [105.8733505, 9.9060986],
                [105.8732298, 9.9062519],
                [105.8731601, 9.9064474],
                [105.8730032, 9.9073035],
                [105.872924, 9.9076932],
                [105.8727846, 9.9082282],
                [105.8727926, 9.9090579],
                [105.8730045, 9.9091213],
                [105.8731111, 9.9091907],
                [105.8732204, 9.9093353],
                [105.8733264, 9.909482],
                [105.873439, 9.9096061],
                [105.8735758, 9.9096788],
                [105.8737117, 9.9097341],
                [105.8738172, 9.9098321],
                [105.8738762, 9.909914],
                [105.8739232, 9.9099827],
                [105.8739983, 9.9100395],
                [105.8740894, 9.9100738],
                [105.8741766, 9.9100791],
                [105.8742718, 9.9100659],
                [105.874359, 9.9100434],
                [105.8744435, 9.9100355],
                [105.8745266, 9.9100527],
                [105.8746098, 9.9101002],
                [105.874595, 9.9102561],
                [105.8745454, 9.9103037],
                [105.8745213, 9.9103446],
                [105.8745293, 9.9103856],
                [105.8745468, 9.9104325],
                [105.8745025, 9.9106115],
                [105.8744697, 9.910768],
                [105.8744596, 9.9111631],
                [105.8746836, 9.9111822],
                [105.8746648, 9.91142],
                [105.8755311, 9.9115178],
                [105.8754989, 9.9117661],
                [105.8744824, 9.9116525],
                [105.8744717, 9.9117318],
                [105.8748901, 9.9117767],
                [105.8748311, 9.9124425],
                [105.8740103, 9.9123553],
                [105.8739594, 9.9129604],
                [105.8733881, 9.9129128],
                [105.8731279, 9.9132959],
                [105.8734471, 9.91351],
                [105.8738226, 9.9137874],
                [105.8741122, 9.9140199],
                [105.8743201, 9.9142498],
                [105.874418, 9.9143845],
                [105.8744931, 9.9144506],
                [105.874587, 9.9144664],
                [105.8746809, 9.9144374],
                [105.8749679, 9.9143343],
                [105.8750993, 9.9143079],
                [105.8752066, 9.9143396],
                [105.8752844, 9.9143977],
                [105.8753353, 9.9144876],
                [105.8753782, 9.9146117],
                [105.8755445, 9.9151692],
                [105.8755714, 9.9153885],
                [105.8756089, 9.9154942],
                [105.8756733, 9.9155735],
                [105.8757699, 9.9156422],
                [105.875904, 9.9156977],
                [105.875676, 9.9164903],
                [105.8771995, 9.9174626],
                [105.8780256, 9.9179937],
                [105.8783823, 9.9180941],
                [105.8787766, 9.918176],
                [105.8790448, 9.9182103],
                [105.879541, 9.9183424],
                [105.8796497, 9.9183689],
                [105.8797355, 9.9183741],
                [105.8798173, 9.9183556],
                [105.8799729, 9.9182539],
                [105.880056, 9.9182288],
                [105.8801284, 9.9182262],
                [105.8802116, 9.9182447],
                [105.8802988, 9.9182777],
                [105.8807762, 9.9184574],
                [105.8806461, 9.9188946],
                [105.8801955, 9.9216269],
                [105.8804161, 9.921898],
                [105.880514, 9.9220638],
                [105.8805918, 9.9223029],
                [105.8807239, 9.9227521],
                [105.8807775, 9.9229053],
                [105.8808634, 9.9230493],
                [105.8809968, 9.9231821],
                [105.8818477, 9.9237838],
                [105.882706, 9.9244331],
                [105.8829166, 9.9245236],
                [105.8831392, 9.9245751],
                [105.8834222, 9.9245844],
                [105.8837374, 9.9245738],
                [105.8837038, 9.9248975],
                [105.8836368, 9.9252066],
                [105.8835134, 9.9257429],
                [105.8834785, 9.9258539],
                [105.8834524, 9.9259173],
                [105.8833806, 9.9259305],
                [105.8832639, 9.9270217],
                [105.8832023, 9.9278671],
                [105.8834705, 9.9278196],
                [105.8837058, 9.9278031],
                [105.883968, 9.9278262],
                [105.8846024, 9.9279675],
                [105.884833, 9.9280045],
                [105.8850557, 9.9280098],
                [105.8852676, 9.9279808],
                [105.8856639, 9.9278863],
                [105.8858134, 9.9278685],
                [105.8860333, 9.9278665],
                [105.8864035, 9.9278685],
                [105.887074, 9.9278804],
                [105.8875756, 9.9279107],
                [105.8877607, 9.9279424],
                [105.8879253, 9.9279959],
                [105.888106, 9.9281062],
                [105.8885747, 9.9285845],
                [105.8891916, 9.9292489],
                [105.8894907, 9.9296267],
                [105.8895705, 9.9297034],
                [105.8896601, 9.9297676],
                [105.8897582, 9.9297991],
                [105.8898608, 9.9298183],
                [105.8899668, 9.9298236],
                [105.8900714, 9.9298104],
                [105.8904, 9.929743],
                [105.8911402, 9.9295713],
                [105.8915627, 9.9303797],
                [105.8919221, 9.930998],
                [105.8923888, 9.9317113],
                [105.8929038, 9.9323084],
                [105.8939043, 9.9333229],
                [105.8968064, 9.9380667],
                [105.8890746, 9.9436597],
                [105.8823266, 9.9479807],
                [105.8780142, 9.9510756],
                [105.8757898, 9.9531524],
                [105.8731935, 9.9551842],
                [105.8684898, 9.9596837],
                [105.8659877, 9.9622675],
                [105.865154, 9.9632361],
                [105.8597526, 9.9675532],
                [105.8597141, 9.9675893],
                [105.8573889, 9.9697684],
                [105.8559061, 9.971291],
                [105.8504144, 9.9767127],
                [105.8486541, 9.9787422],
                [105.848191, 9.9793416],
                [105.8454722, 9.9826158],
                [105.8398234, 9.9794445],
                [105.839383, 9.9791795],
                [105.8389109, 9.9786525],
                [105.8386803, 9.9784993],
                [105.8384469, 9.9784293],
                [105.8381814, 9.9784108],
                [105.8378702, 9.9784135],
                [105.8374143, 9.9784214],
                [105.836454, 9.9783131],
                [105.8357888, 9.9783289],
                [105.8355126, 9.9782999],
                [105.8352491, 9.9782147],
                [105.8345604, 9.9777821],
                [105.8336927, 9.977333],
                [105.8333024, 9.977127],
                [105.832658, 9.9766601],
                [105.8324093, 9.9765009],
                [105.8321471, 9.9763688],
                [105.8311835, 9.97596],
                [105.8309019, 9.975793],
                [105.8306658, 9.975608],
                [105.8303225, 9.9751722],
                [105.8299899, 9.9748155],
                [105.8297418, 9.9745382],
                [105.8295608, 9.9742872],
                [105.8290974, 9.9734914],
                [105.8286864, 9.9729215],
                [105.8282787, 9.9724645],
                [105.8278656, 9.9720629],
                [105.8261597, 9.9706958],
                [105.8258995, 9.9705096],
                [105.8255696, 9.9703696],
                [105.8245383, 9.9699509],
                [105.8242285, 9.969795],
                [105.8240099, 9.9696137],
                [105.8234856, 9.9691016],
                [105.8232428, 9.9689021],
                [105.8230316, 9.9687674],
                [105.8225823, 9.9686185],
                [105.8221766, 9.9684411],
                [105.8219218, 9.9682642],
                [105.8214605, 9.9678943],
                [105.8205646, 9.9670542],
                [105.8198565, 9.9662987],
                [105.8189017, 9.9651258],
                [105.8183397, 9.9650338],
                [105.8141529, 9.9642674],
                [105.8090432, 9.9633122],
                [105.8061343, 9.9627773],
                [105.8049139, 9.962587],
                [105.8044083, 9.9624272],
                [105.8037619, 9.9623678],
                [105.8013235, 9.9618873],
                [105.7972274, 9.9611526],
                [105.7896964, 9.9597432],
                [105.784104, 9.9587591],
                [105.7780298, 9.9576938],
                [105.7778317, 9.9575227],
                [105.7777351, 9.9573748],
                [105.7776707, 9.9571846],
                [105.7775842, 9.9566327],
                [105.7775272, 9.9563907],
                [105.777435, 9.9561764],
                [105.7768594, 9.9550434],
                [105.7766783, 9.9547105],
                [105.7765388, 9.9545401],
                [105.7763954, 9.9544279],
                [105.7761908, 9.9543671],
                [105.7759883, 9.9543542],
                [105.7757945, 9.9543737],
                [105.7755974, 9.9544345],
                [105.7732612, 9.9553664],
                [105.7707547, 9.9562454],
                [105.7681757, 9.9566562],
                [105.7666334, 9.956931],
                [105.7659991, 9.957001],
                [105.7653701, 9.9570413],
                [105.7644582, 9.9569244],
                [105.7641437, 9.9572984],
                [105.763687, 9.9577724],
                [105.7633759, 9.9581072],
                [105.7631419, 9.9582506],
                [105.7628998, 9.9583453],
                [105.7626557, 9.9584104],
                [105.7623178, 9.9584527],
                [105.7621032, 9.9582572],
                [105.7619047, 9.9581673],
                [105.7616419, 9.9580788],
                [105.7607581, 9.9579018],
                [105.7596476, 9.9576112],
                [105.7595444, 9.95789],
                [105.7593311, 9.9580181],
                [105.7583106, 9.958631],
                [105.7578211, 9.9588925],
                [105.757349, 9.9590497],
                [105.7568803, 9.9592412],
                [105.7539526, 9.9605007],
                [105.7532244, 9.9608943],
                [105.752867, 9.9610509],
                [105.7518277, 9.9614445],
                [105.7505777, 9.9619477],
                [105.7482358, 9.9629249],
                [105.747855, 9.9630885],
                [105.7474174, 9.9632891],
                [105.7472143, 9.9633967],
                [105.7470215, 9.9635202],
                [105.7464565, 9.9638683],
                [105.744701, 9.9649673],
                [105.7436885, 9.9656119],
                [105.7416956, 9.9669644],
                [105.7383201, 9.9692317],
                [105.7381866, 9.9690388],
                [105.7380478, 9.9688916],
                [105.7378587, 9.9687205],
                [105.7375482, 9.9685128],
                [105.7373638, 9.9685785],
                [105.7372324, 9.9685957],
                [105.737109, 9.968593],
                [105.736936, 9.9685706],
                [105.7368126, 9.9685706],
                [105.7366303, 9.9686049],
                [105.736763, 9.9690382],
                [105.7368341, 9.9694239],
                [105.7368556, 9.9698782],
                [105.7368435, 9.97047],
                [105.7365538, 9.9703894],
                [105.7363969, 9.9703286],
                [105.7363003, 9.9702626],
                [105.7362123, 9.9701655],
                [105.7360911, 9.9699839],
                [105.7359999, 9.9699047],
                [105.7358846, 9.9698465],
                [105.7356405, 9.9697778],
                [105.7355147, 9.969709],
                [105.7354233, 9.9696193],
                [105.7353508, 9.9695137],
                [105.7351255, 9.9691148],
                [105.7350424, 9.9690329],
                [105.7349405, 9.9689801],
                [105.7348466, 9.9689589],
                [105.7347205, 9.9689721],
                [105.7345757, 9.9690382],
                [105.7343879, 9.9691729],
                [105.7338703, 9.9692971],
                [105.7337227, 9.9693473],
                [105.7335886, 9.9694265],
                [105.7334733, 9.9695348],
                [105.7333271, 9.9697237],
                [105.733197, 9.969828],
                [105.7330669, 9.9699007],
                [105.7326941, 9.9700381],
                [105.7323548, 9.9702032],
                [105.7321751, 9.9702771],
                [105.7319766, 9.9703194],
                [105.7318023, 9.9703326],
                [105.7316306, 9.9703247],
                [105.7312417, 9.9702771],
                [105.7310995, 9.9702613],
                [105.7309601, 9.9702745],
                [105.7308152, 9.9703168],
                [105.7306637, 9.9703973],
                [105.7305001, 9.970499],
                [105.7304169, 9.9705202],
                [105.7303331, 9.9705175],
                [105.7302674, 9.9704951],
                [105.7302131, 9.9704541],
                [105.7299784, 9.9702309],
                [105.729855, 9.9701622],
                [105.7297531, 9.9701305],
                [105.7296444, 9.9701266],
                [105.7295278, 9.9701543],
                [105.7294165, 9.9702137],
                [105.7293454, 9.9702943],
                [105.7293105, 9.9703894],
                [105.7292549, 9.970604],
                [105.729163, 9.9710934],
                [105.729114, 9.9712374],
                [105.7290389, 9.9713741],
                [105.7289082, 9.9715108],
                [105.7288089, 9.9715874],
                [105.7286735, 9.9716733],
                [105.7283838, 9.9718027],
                [105.7282886, 9.9718938],
                [105.7282323, 9.9719916],
                [105.7281263, 9.9722941],
                [105.7280794, 9.9723997],
                [105.728019, 9.9724882],
                [105.7279359, 9.9725582],
                [105.7278554, 9.9725979],
                [105.727661, 9.9726705],
                [105.7275617, 9.9727194],
                [105.7274973, 9.9727801],
                [105.7274558, 9.972862],
                [105.7274155, 9.9730456],
                [105.7273699, 9.9731691],
                [105.7272995, 9.9732451],
                [105.7272197, 9.9732979],
                [105.727036, 9.9733679],
                [105.7269381, 9.9734419],
                [105.7267839, 9.9736162],
                [105.7266417, 9.9737325],
                [105.7265103, 9.9738038],
                [105.7262367, 9.9739068],
                [105.7260543, 9.9739966],
                [105.7257405, 9.9741948],
                [105.7254025, 9.9744299],
                [105.7252255, 9.9744933],
                [105.7250056, 9.9746201],
                [105.7247561, 9.9747944],
                [105.7243618, 9.9750718],
                [105.7240641, 9.9752197],
                [105.7238629, 9.9753439],
                [105.7236122, 9.9754641],
                [105.7235907, 9.9744312],
                [105.7235652, 9.9742014],
                [105.723533, 9.9740508],
                [105.7234874, 9.9738976],
                [105.7233319, 9.9734524],
                [105.7231227, 9.972615],
                [105.7229094, 9.9719163],
                [105.7228746, 9.9718529],
                [105.7228263, 9.971804],
                [105.7227525, 9.971775],
                [105.7226506, 9.971775],
                [105.7225352, 9.9717776],
                [105.7224065, 9.9717142],
                [105.7211673, 9.9708081],
                [105.719381, 9.9695269],
                [105.7188821, 9.9691016],
                [105.7185052, 9.968667],
                [105.7181257, 9.9681558],
                [105.7171172, 9.965456],
                [105.7162669, 9.9632211],
                [105.7161918, 9.9630467],
                [105.716114, 9.9629041],
                [105.7160363, 9.9627825],
                [105.7156688, 9.9623916],
                [105.7154033, 9.9621089],
                [105.7152638, 9.9619451],
                [105.7151484, 9.9617866],
                [105.7139562, 9.9598687],
                [105.713641, 9.9593852],
                [105.713574, 9.959306],
                [105.7134975, 9.9592743],
                [105.7134077, 9.9592809],
                [105.7133111, 9.9593218],
                [105.7132119, 9.959409],
                [105.7131046, 9.9595252],
                [105.7130054, 9.9595781],
                [105.7128867, 9.9595913],
                [105.7128055, 9.9595767],
                [105.7127184, 9.9595292],
                [105.7112566, 9.9583761],
                [105.7111251, 9.9582651],
                [105.7110058, 9.9581423],
                [105.7105887, 9.9576311],
                [105.707826, 9.9579217],
                [105.7050338, 9.9581462],
                [105.7049131, 9.9582334],
                [105.7047924, 9.9583034],
                [105.7046919, 9.9583536],
                [105.7045913, 9.95838],
                [105.7045121, 9.9583628],
                [105.7044344, 9.9582968],
                [105.7043485, 9.9581951],
                [105.7038939, 9.9582466],
                [105.7035425, 9.953914],
                [105.7026252, 9.9539457],
                [105.7008979, 9.953984],
                [105.6992657, 9.9540923],
                [105.6968585, 9.9542627],
                [105.6958956, 9.9543711],
                [105.6953189, 9.9545164],
                [105.6940381, 9.9549278],
                [105.6936224, 9.9545441],
                [105.6939878, 9.9527463],
                [105.6894837, 9.9515232],
                [105.6872803, 9.9509142],
                [105.6858876, 9.9503138],
                [105.6822357, 9.9488628],
                [105.6788327, 9.9475115],
                [105.6801087, 9.9447362],
                [105.6781232, 9.9444878],
                [105.6779499, 9.9433013],
                [105.6759775, 9.9445816],
                [105.6751259, 9.9438472],
                [105.6710985, 9.9401419],
                [105.6704374, 9.9395382],
                [105.6685598, 9.9412904],
                [105.6673019, 9.94248],
                [105.6653398, 9.9443293],
                [105.6625302, 9.9468893],
                [105.660485, 9.9488945],
                [105.6602517, 9.9490979],
                [105.6600237, 9.9492512],
                [105.6593049, 9.9496527],
                [105.6591037, 9.9498218],
                [105.658752, 9.9502131],
                [105.6621766, 9.9533317],
                [105.6649072, 9.9556854],
                [105.6708335, 9.9607473],
                [105.6736363, 9.9640457],
                [105.6729734, 9.9644336],
                [105.6724785, 9.9647401],
                [105.6718039, 9.9650346],
                [105.6715639, 9.9651324],
                [105.6713775, 9.9651997],
                [105.6712461, 9.9652354],
                [105.6711347, 9.9652803],
                [105.670951, 9.9653979],
                [105.6708638, 9.9654467],
                [105.6707565, 9.9654831],
                [105.6705172, 9.9655253],
                [105.6703891, 9.9655683],
                [105.6700471, 9.9657254],
                [105.6698245, 9.965773],
                [105.6680958, 9.966411],
                [105.6670779, 9.966514],
                [105.6660479, 9.9666712],
                [105.6650153, 9.9666699],
                [105.6623009, 9.9665206],
                [105.6603912, 9.9663608],
                [105.6593558, 9.9663106],
                [105.6592365, 9.9662855],
                [105.6591453, 9.9662327],
                [105.6588918, 9.9659738],
                [105.6580844, 9.9662934],
                [105.6589749, 9.9704171],
                [105.6589803, 9.970499],
                [105.6589642, 9.9705492],
                [105.6589213, 9.9705836],
                [105.6587765, 9.9706232],
                [105.6586987, 9.9706602],
                [105.6586397, 9.9707236],
                [105.6586155, 9.9708081],
                [105.6586075, 9.9709243],
                [105.6586182, 9.9711066],
                [105.6570089, 9.9716667],
                [105.6561291, 9.9720365],
                [105.6548256, 9.9725067],
                [105.6539994, 9.9728501],
                [105.6525671, 9.9734102],
                [105.651572, 9.9737721],
                [105.6508237, 9.9737483],
                [105.6499681, 9.9740864],
                [105.6499788, 9.9742476],
                [105.6497991, 9.9743612],
                [105.6494343, 9.9747548],
                [105.6494182, 9.9748499],
                [105.6489032, 9.9751775],
                [105.6487048, 9.9754548],
                [105.6484768, 9.975682],
                [105.6483453, 9.9756873],
                [105.6482273, 9.9756688],
                [105.6482273, 9.9755288],
                [105.6482837, 9.9754046],
                [105.6482354, 9.9752673],
                [105.6481844, 9.9752461],
                [105.6477848, 9.9754099],
                [105.6473154, 9.975715],
                [105.6472497, 9.9756028],
                [105.647141, 9.9754601],
                [105.6467468, 9.975081],
                [105.6466287, 9.9749265],
                [105.646551, 9.974768],
                [105.6464919, 9.974591],
                [105.6463538, 9.9741142],
                [105.6462479, 9.9738421],
                [105.6461111, 9.9735779],
                [105.6460547, 9.973426],
                [105.6460333, 9.9732517],
                [105.6460494, 9.9730588],
                [105.646103, 9.9728369],
                [105.6461942, 9.9726018],
                [105.6463069, 9.972409],
                [105.6464624, 9.9722016],
                [105.6466931, 9.9719982],
                [105.6468473, 9.9718925],
                [105.6469734, 9.9717631],
                [105.6471169, 9.9715669],
                [105.6471705, 9.9714527],
                [105.6471974, 9.9713417],
                [105.6472, 9.9712097],
                [105.6471491, 9.9708372],
                [105.6471303, 9.9703458],
                [105.6471142, 9.970145],
                [105.6470726, 9.9699535],
                [105.6469336, 9.9696327],
                [105.6467293, 9.9692231],
                [105.6465022, 9.9688721],
                [105.6462774, 9.9685415],
                [105.6461835, 9.968346],
                [105.6461486, 9.9682272],
                [105.6461325, 9.9681109],
                [105.6457382, 9.9678468],
                [105.645647, 9.9677701],
                [105.6455827, 9.9676724],
                [105.6455639, 9.967572],
                [105.6455746, 9.9674848],
                [105.6456068, 9.9673924],
                [105.6457651, 9.967181],
                [105.6458522, 9.9670582],
                [105.6459032, 9.9669129],
                [105.6459233, 9.9667689],
                [105.645926, 9.9666104],
                [105.6458898, 9.9664579],
                [105.64569, 9.9658972],
                [105.6456497, 9.9654639],
                [105.6455404, 9.9649336],
                [105.6454794, 9.9647269],
                [105.6454097, 9.9645829],
                [105.6453238, 9.9644733],
                [105.6452125, 9.9643716],
                [105.6447975, 9.9640592],
                [105.644424, 9.9638855],
                [105.6440699, 9.9637177],
                [105.6439412, 9.9636755],
                [105.6436998, 9.9636305],
                [105.6436112, 9.9636015],
                [105.6435522, 9.9635539],
                [105.6435201, 9.9634852],
                [105.6435147, 9.9634086],
                [105.6435388, 9.9633267],
                [105.6436233, 9.9631207],
                [105.6436649, 9.9629701],
                [105.6436864, 9.9628407],
                [105.6436864, 9.9627165],
                [105.6436542, 9.9626082],
                [105.6435898, 9.9625078],
                [105.6434892, 9.962418],
                [105.6431392, 9.9622119],
                [105.6430292, 9.9621353],
                [105.6429353, 9.9620323],
                [105.6428575, 9.9619081],
                [105.6425303, 9.9612477],
                [105.6424606, 9.9611314],
                [105.6424016, 9.9610469],
                [105.6423104, 9.9609597],
                [105.6419617, 9.9607114],
                [105.6415379, 9.9604261],
                [105.6413689, 9.9603336],
                [105.6412234, 9.9602913],
                [105.6410605, 9.9602762],
                [105.6408137, 9.96029],
                [105.6404784, 9.960323],
                [105.6403255, 9.9603521],
                [105.6399688, 9.9604472],
                [105.639722, 9.9604763],
                [105.6394981, 9.9604776],
                [105.6393519, 9.9604499],
                [105.6392232, 9.9603931],
                [105.6389551, 9.9602223],
                [105.6387913, 9.9601011],
                [105.6386974, 9.9599875],
                [105.6386545, 9.9598819],
                [105.6386358, 9.9597603],
                [105.6386304, 9.9596151],
                [105.6386237, 9.9593839],
                [105.6385861, 9.959059],
                [105.6385446, 9.9588912],
                [105.6385016, 9.9587657],
                [105.6384292, 9.9586587],
                [105.6383139, 9.9585682],
                [105.6381744, 9.9585055],
                [105.6377801, 9.9583945],
                [105.6376567, 9.9583787],
                [105.6374073, 9.9583893],
                [105.6371981, 9.9583813],
                [105.6362995, 9.9583126],
                [105.6361198, 9.958314],
                [105.6359012, 9.958347],
                [105.6357095, 9.9584025],
                [105.635578, 9.9584804],
                [105.6352589, 9.9587776],
                [105.6350845, 9.9589335],
                [105.6349933, 9.9590497],
                [105.6348914, 9.9592769],
                [105.6347975, 9.9594513],
                [105.6346902, 9.9595807],
                [105.6345776, 9.9596732],
                [105.6344408, 9.9597471],
                [105.6341109, 9.9598475],
                [105.6338078, 9.9598977],
                [105.6335409, 9.9599142],
                [105.6333288, 9.9598827],
                [105.6331332, 9.9597887],
                [105.6329414, 9.9596468],
                [105.6327912, 9.9594698],
                [105.6326692, 9.9592809],
                [105.6325605, 9.9590735],
                [105.6324559, 9.9588463],
                [105.6323808, 9.9586244],
                [105.6322494, 9.9581911],
                [105.6321944, 9.958059],
                [105.6321388, 9.9579547],
                [105.632067, 9.9578523],
                [105.6319631, 9.9577777],
                [105.6318015, 9.9577156],
                [105.6312168, 9.9575412],
                [105.6310558, 9.9575122],
                [105.6309083, 9.957462],
                [105.6307822, 9.9573867],
                [105.6306642, 9.9572876],
                [105.6303343, 9.9569442],
                [105.6299052, 9.9566087],
                [105.6297737, 9.9564872],
                [105.6296906, 9.9563683],
                [105.6296262, 9.9562124],
                [105.629594, 9.9560513],
                [105.6295685, 9.9557448],
                [105.6295484, 9.9555625],
                [105.6295082, 9.9553723],
                [105.6292051, 9.9545388],
                [105.6291448, 9.9542324],
                [105.6291354, 9.9539801],
                [105.6291568, 9.9536763],
                [105.6292173, 9.9532959],
                [105.6293446, 9.9527794],
                [105.6293915, 9.9525191],
                [105.6294063, 9.9523302],
                [105.6293982, 9.9521783],
                [105.6293714, 9.952037],
                [105.6293124, 9.9519062],
                [105.629122, 9.9516619],
                [105.6290415, 9.951576],
                [105.6289892, 9.9514954],
                [105.628957, 9.9514056],
                [105.6289396, 9.9513052],
                [105.628898, 9.9507993],
                [105.6288806, 9.9504321],
                [105.6288413, 9.9502106],
                [105.6287773, 9.9500371],
                [105.6286935, 9.9498951],
                [105.6285989, 9.9497835],
                [105.6284782, 9.9497003],
                [105.6278264, 9.9493885],
                [105.6271747, 9.9491323],
                [105.6251067, 9.9482248],
                [105.6225883, 9.9471466],
                [105.6217821, 9.9467334],
                [105.6207793, 9.9462889],
                [105.6200782, 9.9459973],
                [105.6198093, 9.9459199],
                [105.6194838, 9.9458514],
                [105.6193386, 9.9458141],
                [105.6192347, 9.9457625],
                [105.6190956, 9.9456608],
                [105.6189462, 9.9459204],
                [105.6187751, 9.9461534],
                [105.6177662, 9.9471812],
                [105.6130818, 9.952076],
                [105.6118956, 9.9533064],
                [105.6100867, 9.9552157],
                [105.6070997, 9.9583977],
                [105.6059895, 9.9595806],
                [105.6029956, 9.9627872],
                [105.5988567, 9.9672188],
                [105.5974543, 9.9687377],
                [105.5953901, 9.9709415],
                [105.5938792, 9.9725673],
                [105.5914481, 9.9751459],
                [105.5903184, 9.9763473],
                [105.5873136, 9.9795995],
                [105.5844827, 9.982563],
                [105.584032, 9.9830516],
                [105.5838276, 9.9832398],
                [105.583535, 9.9834407],
                [105.5830582, 9.9836834],
                [105.5847233, 9.9871096],
                [105.5857125, 9.9891259],
                [105.5852678, 9.9892572],
                [105.5846, 9.9894051],
                [105.583798, 9.9896482],
                [105.5832994, 9.9897634],
                [105.5832227, 9.9898053],
                [105.5831972, 9.9898489],
                [105.5832025, 9.9899123],
                [105.5832508, 9.9900602],
                [105.5832575, 9.9900999],
                [105.5832522, 9.9901395],
                [105.5832387, 9.9901725],
                [105.5832128, 9.9901992],
                [105.5827311, 9.9904111],
                [105.58162, 9.9909161],
                [105.5799651, 9.9915976],
                [105.5793528, 9.9918619],
                [105.579136, 9.992055],
                [105.5755013, 9.9936657],
                [105.5744047, 9.994168],
                [105.5738309, 9.9929527],
                [105.5732527, 9.9917879],
                [105.5720864, 9.9893985],
                [105.5715993, 9.9883908],
                [105.5705006, 9.9859714],
                [105.5695951, 9.9838727],
                [105.5678844, 9.9845737],
                [105.5664372, 9.9852488],
                [105.5646282, 9.9860662],
                [105.5629072, 9.9867817],
                [105.5618682, 9.9845526],
                [105.5606975, 9.9820008],
                [105.5607082, 9.9819295],
                [105.5608007, 9.9818053],
                [105.5598673, 9.9807315],
                [105.5600041, 9.9805664],
                [105.5600557, 9.9804671],
                [105.5600658, 9.9803766],
                [105.5593691, 9.9788718],
                [105.5582459, 9.976413],
                [105.5578831, 9.9755987],
                [105.5572507, 9.9742416],
                [105.5563208, 9.9721323],
                [105.555131, 9.9695389],
                [105.5540879, 9.9672439],
                [105.5532557, 9.9655854],
                [105.5528426, 9.9647401],
                [105.5525201, 9.9639361],
                [105.5511951, 9.9610614],
                [105.5554819, 9.9590656],
                [105.5559447, 9.9588025],
                [105.5547802, 9.957455],
                [105.5502061, 9.9522169],
                [105.5507751, 9.9516938],
                [105.5505753, 9.9512763],
                [105.5487034, 9.9471457],
                [105.5483296, 9.9463526],
                [105.5468996, 9.9431422],
                [105.5454782, 9.9400364],
                [105.5418477, 9.940944],
                [105.5402567, 9.9375622],
                [105.5399549, 9.9369441],
                [105.5396565, 9.9363242],
                [105.5393568, 9.9356926],
                [105.5390606, 9.9350752],
                [105.5389403, 9.9348386],
                [105.5388243, 9.9346155],
                [105.5387127, 9.9343903],
                [105.5385714, 9.9340878],
                [105.538438, 9.9337819],
                [105.5386745, 9.9334837],
                [105.5376291, 9.93113],
                [105.5365761, 9.9287796],
                [105.5355155, 9.9264325],
                [105.5344657, 9.9241286],
                [105.5334086, 9.9218279],
                [105.5323442, 9.9195304],
                [105.5332175, 9.9187156],
                [105.5340886, 9.9178986],
                [105.5349561, 9.9170806],
                [105.5358213, 9.9162604],
                [105.5372751, 9.9149447],
                [105.5383348, 9.9139906],
                [105.5393966, 9.9130388],
                [105.5390706, 9.9123102],
                [105.5387396, 9.9115838],
                [105.5384253, 9.9109064],
                [105.5381066, 9.910231],
                [105.5379148, 9.9098341],
                [105.5377203, 9.9094384],
                [105.5375105, 9.9090371],
                [105.5373073, 9.9086325],
                [105.5371251, 9.9082552],
                [105.5369487, 9.9078753],
                [105.536778, 9.9074928],
                [105.5366132, 9.9071079],
                [105.5361459, 9.9061137],
                [105.5356872, 9.9051157],
                [105.5352324, 9.9040897],
                [105.5348021, 9.9031235],
                [105.5340908, 9.9014603],
                [105.5339093, 9.9010683],
                [105.5337211, 9.9006794],
                [105.5335302, 9.9003094],
                [105.53341, 9.9000954],
                [105.5332863, 9.8998639],
                [105.5330994, 9.8994671],
                [105.5327145, 9.8985733],
                [105.5323103, 9.8976513],
                [105.5318949, 9.8967273],
                [105.5314332, 9.8957542],
                [105.5306549, 9.8940185],
                [105.5298722, 9.8922848],
                [105.5289102, 9.8901695],
                [105.5285235, 9.889309],
                [105.5281314, 9.8884508],
                [105.5277408, 9.8876092],
                [105.5273452, 9.8867699],
                [105.5260899, 9.8839632],
                [105.5249852, 9.8815287],
                [105.5165189, 9.8631676],
                [105.5188833, 9.8622899],
                [105.5189466, 9.862264],
                [105.5190067, 9.8622314],
                [105.5190629, 9.8621926],
                [105.5191118, 9.8621503],
                [105.5191561, 9.8621033],
                [105.5191951, 9.862052],
                [105.5192285, 9.861997],
                [105.5208982, 9.859146],
                [105.509828, 9.8523726],
                [105.507111, 9.8507236],
                [105.5062088, 9.8501601],
                [105.505304, 9.8496007],
                [105.5043966, 9.8490455],
                [105.5034712, 9.8484853],
                [105.5025431, 9.8479294],
                [105.5016124, 9.847378],
                [105.5012407, 9.8471532],
                [105.5008721, 9.8469234],
                [105.5005449, 9.8467133],
                [105.5002096, 9.8465098],
                [105.4998688, 9.8463216],
                [105.4995391, 9.8461385],
                [105.4991764, 9.845929],
                [105.4988176, 9.8457131],
                [105.4959986, 9.8440825],
                [105.4957138, 9.8439118],
                [105.4954272, 9.8437442],
                [105.4951705, 9.8435976],
                [105.4948935, 9.843443],
                [105.4946403, 9.8433048],
                [105.4943705, 9.8431523],
                [105.4941204, 9.8430062],
                [105.4938723, 9.8428568],
                [105.4928765, 9.8422352],
                [105.4926623, 9.8421094],
                [105.4924762, 9.8420054],
                [105.4922944, 9.8418943],
                [105.4921387, 9.8417911],
                [105.4919947, 9.8416828],
                [105.4917754, 9.8415376],
                [105.4914978, 9.8413896],
                [105.4912958, 9.8412851],
                [105.4907567, 9.8409944],
                [105.4902211, 9.8406973],
                [105.4896893, 9.8403939],
                [105.4891873, 9.8400996],
                [105.4886888, 9.8397997],
                [105.4881939, 9.8394941],
                [105.4876931, 9.8391829],
                [105.4858457, 9.8380982],
                [105.4839914, 9.8370094],
                [105.4821396, 9.8359164],
                [105.4803021, 9.8348262],
                [105.478467, 9.8337319],
                [105.4775369, 9.8331653],
                [105.4770648, 9.8328745],
                [105.4766345, 9.8326336],
                [105.4765845, 9.8326429],
                [105.4765338, 9.8326473],
                [105.4764854, 9.8326469],
                [105.4750237, 9.8324676],
                [105.4747152, 9.8324279],
                [105.4743047, 9.832366],
                [105.4738579, 9.8322937],
                [105.4734124, 9.8322137],
                [105.4729684, 9.8321261],
                [105.4725261, 9.8320308],
                [105.4720855, 9.8319279],
                [105.4716468, 9.8318174],
                [105.4712375, 9.831707],
                [105.4708301, 9.8315901],
                [105.4704247, 9.8314665],
                [105.470075, 9.8313523],
                [105.4699024, 9.8312904],
                [105.4697869, 9.8312366],
                [105.4696754, 9.8311752],
                [105.4695723, 9.8311093],
                [105.4694738, 9.831037],
                [105.4693803, 9.8309585],
                [105.4688063, 9.8304855],
                [105.4687106, 9.8304878],
                [105.4686148, 9.8304851],
                [105.4685193, 9.8304775],
                [105.4684237, 9.8304649],
                [105.4683288, 9.8304472],
                [105.4682189, 9.8304233],
                [105.4680847, 9.830395],
                [105.4679359, 9.8303586],
                [105.4678152, 9.8303238],
                [105.4677549, 9.8303054],
                [105.4676958, 9.8302833],
                [105.4676345, 9.8302556],
                [105.4675604, 9.8302106],
                [105.4671016, 9.8299118],
                [105.4664768, 9.8295023],
                [105.4660852, 9.8292475],
                [105.4657191, 9.8290319],
                [105.4656601, 9.8289903],
                [105.4656037, 9.8289454],
                [105.4655501, 9.8288971],
                [105.4654916, 9.8288369],
                [105.4654391, 9.8287811],
                [105.4653838, 9.828728],
                [105.4652967, 9.8286545],
                [105.4652041, 9.8285879],
                [105.4625067, 9.8269603],
                [105.4624746, 9.826897],
                [105.4624325, 9.8268238],
                [105.4623757, 9.826759],
                [105.4623122, 9.8266926],
                [105.462245, 9.8266255],
                [105.4621839, 9.8265529],
                [105.4621333, 9.8264812],
                [105.4620887, 9.8264058],
                [105.4620503, 9.826327],
                [105.4620185, 9.8262455],
                [105.4620002, 9.8261776],
                [105.461981, 9.8260815],
                [105.4619612, 9.8260026],
                [105.4619342, 9.8259258],
                [105.4619003, 9.8258517],
                [105.4618598, 9.825781],
                [105.4618129, 9.8257141],
                [105.4617602, 9.8256517],
                [105.4617072, 9.825599],
                [105.46165, 9.8255508],
                [105.4615889, 9.8255074],
                [105.4615245, 9.8254692],
                [105.4614554, 9.8254362],
                [105.4613832, 9.8254106],
                [105.4613086, 9.8253927],
                [105.4612325, 9.8253827],
                [105.4611558, 9.8253807],
                [105.4610792, 9.8253867],
                [105.4610038, 9.8254006],
                [105.4609252, 9.8254242],
                [105.4608499, 9.8254563],
                [105.4607787, 9.8254967],
                [105.4607127, 9.8255447],
                [105.4606525, 9.8255998],
                [105.4605991, 9.8256613],
                [105.4605532, 9.8257283],
                [105.4572625, 9.8236919],
                [105.4557774, 9.8227728],
                [105.4556281, 9.8226835],
                [105.4554741, 9.8226021],
                [105.4553159, 9.822529],
                [105.4551539, 9.8224644],
                [105.4550032, 9.8224129],
                [105.4548501, 9.8223688],
                [105.454695, 9.8223322],
                [105.450573, 9.8212883],
                [105.4492894, 9.8209622],
                [105.4475658, 9.820518],
                [105.4458761, 9.8200567],
                [105.4456219, 9.819986],
                [105.4453976, 9.8199113],
                [105.4451711, 9.8198433],
                [105.4449427, 9.8197818],
                [105.4446693, 9.8197176],
                [105.4443939, 9.819663],
                [105.4440629, 9.8196074],
                [105.4436568, 9.8195088],
                [105.4431993, 9.8193907],
                [105.4427892, 9.8192767],
                [105.4423785, 9.8191528],
                [105.4420674, 9.8190603],
                [105.441791, 9.8189744],
                [105.4414048, 9.8188457],
                [105.441016, 9.8187247],
                [105.4406176, 9.8186092],
                [105.440217, 9.8185017],
                [105.4398143, 9.8184022],
                [105.4396681, 9.8183588],
                [105.4395232, 9.8183112],
                [105.4393798, 9.8182595],
                [105.4392014, 9.8181887],
                [105.4390445, 9.8181194],
                [105.4389429, 9.818062],
                [105.4388816, 9.8180192],
                [105.4388156, 9.8179835],
                [105.438746, 9.8179555],
                [105.4386736, 9.8179355],
                [105.4385993, 9.8179239],
                [105.4385271, 9.8179206],
                [105.4384551, 9.8179251],
                [105.4383839, 9.8179374],
                [105.4383146, 9.8179573],
                [105.4382291, 9.8179846],
                [105.4381025, 9.818004],
                [105.4379564, 9.8180175],
                [105.4378099, 9.8180251],
                [105.4376632, 9.8180269],
                [105.4375068, 9.8180224],
                [105.4373508, 9.8180111],
                [105.4371954, 9.8179933],
                [105.4370275, 9.8179622],
                [105.4368848, 9.8179285],
                [105.4367307, 9.8178816],
                [105.4365788, 9.817828],
                [105.4364294, 9.8177679],
                [105.4362706, 9.8176955],
                [105.4361156, 9.8176158],
                [105.4359645, 9.8175288],
                [105.4358393, 9.8174428],
                [105.4357071, 9.8173458],
                [105.435592, 9.8172624],
                [105.4354728, 9.8171847],
                [105.4353498, 9.817113],
                [105.4352233, 9.8170474],
                [105.4350936, 9.8169883],
                [105.4349444, 9.8169294],
                [105.4347921, 9.816879],
                [105.4346371, 9.8168371],
                [105.4344799, 9.8168039],
                [105.4343212, 9.8167795],
                [105.4342077, 9.8167804],
                [105.4340948, 9.8167904],
                [105.433983, 9.8168096],
                [105.4338732, 9.8168376],
                [105.4337858, 9.8168669],
                [105.4337005, 9.8169019],
                [105.4336178, 9.8169424],
                [105.4335473, 9.8169883],
                [105.4334711, 9.8170409],
                [105.4333998, 9.8170877],
                [105.4333246, 9.8171281],
                [105.4332462, 9.817162],
                [105.4331651, 9.8171891],
                [105.4330772, 9.8172101],
                [105.4329877, 9.817223],
                [105.4328973, 9.8172278],
                [105.4328069, 9.8172244],
                [105.4327172, 9.8172129],
                [105.4326283, 9.8171861],
                [105.4325415, 9.8171534],
                [105.432457, 9.8171151],
                [105.4323877, 9.8170784],
                [105.4323205, 9.8170378],
                [105.4322559, 9.8169935],
                [105.4321408, 9.8168959],
                [105.4320214, 9.8168035],
                [105.4318977, 9.8167166],
                [105.4317702, 9.8166354],
                [105.431639, 9.8165601],
                [105.4315063, 9.8164917],
                [105.4313706, 9.8164293],
                [105.4312322, 9.816373],
                [105.4311235, 9.8163335],
                [105.4310166, 9.8163037],
                [105.4309443, 9.8162773],
                [105.4308901, 9.8162519],
                [105.430838, 9.8162223],
                [105.4307885, 9.8161889],
                [105.4308761, 9.8160265],
                [105.4309557, 9.8158603],
                [105.4310274, 9.8156906],
                [105.4310894, 9.815522],
                [105.4311435, 9.8153509],
                [105.4311869, 9.815151],
                [105.4312367, 9.8148125],
                [105.4312915, 9.8144486],
                [105.4313538, 9.8140859],
                [105.4315251, 9.8129953],
                [105.4317033, 9.8119059],
                [105.4318876, 9.8108233],
                [105.4320787, 9.809742],
                [105.4322929, 9.8086407],
                [105.4325712, 9.8072343],
                [105.4336229, 9.8016531],
                [105.4338179, 9.8004162],
                [105.4341763, 9.7985242],
                [105.4342219, 9.7982176],
                [105.4342246, 9.7981277],
                [105.4342058, 9.7980643],
                [105.4341495, 9.7980141],
                [105.4339778, 9.7978925],
                [105.4339403, 9.797837],
                [105.4339457, 9.7977762],
                [105.434069, 9.7975436],
                [105.4341388, 9.7973507],
                [105.4341924, 9.7970996],
                [105.4342246, 9.796904],
                [105.4342139, 9.796711],
                [105.4341495, 9.7964599],
                [105.4339671, 9.7964626],
                [105.4339403, 9.7961613],
                [105.4339537, 9.7960608],
                [105.4340127, 9.7959736],
                [105.43412, 9.795889],
                [105.4342836, 9.7958071],
                [105.4347436, 9.7955771],
                [105.4349381, 9.7954979],
                [105.4350507, 9.7954397],
                [105.4351473, 9.7953551],
                [105.4352546, 9.795223],
                [105.4353216, 9.7951569],
                [105.4353914, 9.7951278],
                [105.4354691, 9.7951172],
                [105.4356649, 9.7951225],
                [105.4357615, 9.7951067],
                [105.4358581, 9.7950538],
                [105.4359332, 9.7949824],
                [105.4359868, 9.794882],
                [105.4361182, 9.7945093],
                [105.4362041, 9.7943613],
                [105.4365528, 9.7938274],
                [105.4366708, 9.7936794],
                [105.4367942, 9.7935737],
                [105.4370517, 9.7934111],
                [105.4371428, 9.7933252],
                [105.437222, 9.7932182],
                [105.4372796, 9.7930741],
                [105.4373199, 9.7929314],
                [105.4373279, 9.7927992],
                [105.4373118, 9.7927094],
                [105.4372716, 9.7926459],
                [105.4370597, 9.7925164],
                [105.4367378, 9.7922151],
                [105.4366037, 9.7921279],
                [105.4364455, 9.7920664],
                [105.4363181, 9.7922177],
                [105.4362336, 9.7923552],
                [105.4359627, 9.7928124],
                [105.4357937, 9.793045],
                [105.4356891, 9.793164],
                [105.4355845, 9.7932591],
                [105.4354557, 9.7933193],
                [105.4353417, 9.7933246],
                [105.4352432, 9.793312],
                [105.4351446, 9.7932757],
                [105.4350682, 9.7932221],
                [105.4350021, 9.7931473],
                [105.4349501, 9.7930516],
                [105.4348952, 9.7929221],
                [105.4348496, 9.7927635],
                [105.4348147, 9.7925561],
                [105.4347765, 9.7923314],
                [105.4347589, 9.7922405],
                [105.4347325, 9.7921434],
                [105.4346913, 9.7920354],
                [105.4346256, 9.7918907],
                [105.4342977, 9.7913237],
                [105.4339899, 9.7907594],
                [105.4339088, 9.7906352],
                [105.4338038, 9.7905199],
                [105.4336801, 9.7904079],
                [105.4335393, 9.790316],
                [105.4333945, 9.790246],
                [105.433261, 9.7902037],
                [105.4331544, 9.7901772],
                [105.4330518, 9.7901686],
                [105.4329536, 9.7901799],
                [105.432849, 9.7902063],
                [105.4327139, 9.7902598],
                [105.4325938, 9.7903305],
                [105.4324165, 9.7904521],
                [105.4319957, 9.7908037],
                [105.4318026, 9.7909378],
                [105.4317057, 9.79099],
                [105.4315994, 9.7910217],
                [105.4314867, 9.7910316],
                [105.4313801, 9.7910171],
                [105.4312826, 9.7909771],
                [105.4312115, 9.790918],
                [105.4311575, 9.7908275],
                [105.431123, 9.7907376],
                [105.4311005, 9.7906325],
                [105.4310945, 9.7905242],
                [105.4311055, 9.7903892],
                [105.4311381, 9.7902309],
                [105.4311719, 9.7901065],
                [105.4312691, 9.7897979],
                [105.4312903, 9.7896988],
                [105.4313023, 9.7896027],
                [105.4313084, 9.7894887],
                [105.4313077, 9.789303],
                [105.4312849, 9.7891345],
                [105.4312502, 9.7889459],
                [105.4312165, 9.7888074],
                [105.4311602, 9.7886151],
                [105.4311206, 9.7885147],
                [105.4310569, 9.7884314],
                [105.4309644, 9.7883667],
                [105.4308517, 9.7883231],
                [105.4307418, 9.7883145],
                [105.4306395, 9.7883402],
                [105.430498, 9.7884334],
                [105.4300125, 9.7888745],
                [105.4296695, 9.78912],
                [105.4293993, 9.7892957],
                [105.4291646, 9.7894107],
                [105.4289567, 9.7894596],
                [105.4287542, 9.7894662],
                [105.4285477, 9.7894411],
                [105.4283532, 9.7893816],
                [105.4282165, 9.7893004],
                [105.4280703, 9.7891715],
                [105.427957, 9.7890387],
                [105.4278584, 9.7889019],
                [105.4275553, 9.7884407],
                [105.4274748, 9.7883297],
                [105.4273501, 9.7881869],
                [105.4272053, 9.7880654],
                [105.4268485, 9.7878222],
                [105.4263597, 9.787542],
                [105.4258615, 9.7872724],
                [105.4255128, 9.787057],
                [105.4254015, 9.7869724],
                [105.4253009, 9.7868581],
                [105.4252446, 9.7867411],
                [105.4252198, 9.7866334],
                [105.4252271, 9.7865214],
                [105.4252647, 9.7864167],
                [105.4253438, 9.7863017],
                [105.4254511, 9.7862224],
                [105.4256335, 9.7861398],
                [105.42584, 9.7860883],
                [105.4260814, 9.7860618],
                [105.4265656, 9.786046],
                [105.4267493, 9.7860156],
                [105.4269209, 9.7859634],
                [105.4271141, 9.7858662],
                [105.4273072, 9.7857354],
                [105.4274628, 9.7855887],
                [105.4275788, 9.7854466],
                [105.4276855, 9.7852731],
                [105.4277578, 9.7850812],
                [105.4277994, 9.7848922],
                [105.4278181, 9.7846728],
                [105.4278128, 9.7844944],
                [105.4277752, 9.7843134],
                [105.4277001, 9.7841125],
                [105.4276237, 9.7839512],
                [105.4275191, 9.7837821],
                [105.4273799, 9.7836526],
                [105.4271945, 9.7835045],
                [105.4269853, 9.7833565],
                [105.4267037, 9.7832098],
                [105.4263731, 9.7830658],
                [105.4260016, 9.782931],
                [105.425665, 9.7828299],
                [105.4255061, 9.7828015],
                [105.4253331, 9.7827896],
                [105.4251426, 9.7828094],
                [105.4249884, 9.7828563],
                [105.4248101, 9.78296],
                [105.424633, 9.7831266],
                [105.4244547, 9.7833671],
                [105.4243507, 9.7835984],
                [105.4241958, 9.7840239],
                [105.4240265, 9.7843751],
                [105.4238659, 9.7846715],
                [105.4236849, 9.7849385],
                [105.4235062, 9.785177],
                [105.4233623, 9.7853131],
                [105.4231987, 9.7854308],
                [105.4229721, 9.785516],
                [105.4227769, 9.7855239],
                [105.422612, 9.7854949],
                [105.4224671, 9.7854235],
                [105.4223471, 9.7853112],
                [105.4222625, 9.7851788],
                [105.4221694, 9.7849973],
                [105.4220727, 9.7847424],
                [105.4220045, 9.7844772],
                [105.421928, 9.7841138],
                [105.4218274, 9.7835957],
                [105.4216987, 9.7829931],
                [105.4216652, 9.782775],
                [105.4216504, 9.7825649],
                [105.4216491, 9.7823389],
                [105.4216665, 9.7820984],
                [105.421696, 9.7818591],
                [105.4217698, 9.7814296],
                [105.4219347, 9.7805706],
                [105.4219984, 9.7801047],
                [105.4220279, 9.7797003],
                [105.4220309, 9.7793481],
                [105.4220092, 9.7790983],
                [105.4219562, 9.7788551],
                [105.4218864, 9.7786635],
                [105.4217724, 9.7784996],
                [105.4216196, 9.7783757],
                [105.4214425, 9.7783159],
                [105.4212481, 9.7783066],
                [105.4210201, 9.7783463],
                [105.4207733, 9.7784494],
                [105.4205316, 9.7785924],
                [105.4203475, 9.7787395],
                [105.420139, 9.7789899],
                [105.4197836, 9.7794954],
                [105.4195556, 9.7798424],
                [105.4193665, 9.7802058],
                [105.4192404, 9.780527],
                [105.419172, 9.780819],
                [105.4191432, 9.7810635],
                [105.4191291, 9.7814362],
                [105.4191104, 9.7818301],
                [105.4190768, 9.7820878],
                [105.4190017, 9.7823574],
                [105.4188864, 9.7826006],
                [105.4187576, 9.7827935],
                [105.4186222, 9.7829429],
                [105.4185015, 9.7830367],
                [105.4183848, 9.7830988],
                [105.4182571, 9.7831342],
                [105.4180777, 9.7831464],
                [105.4179248, 9.7831252],
                [105.4177428, 9.7830642],
                [105.4175547, 9.7829455],
                [105.4173998, 9.7828094],
                [105.4172878, 9.7826581],
                [105.4171865, 9.7824697],
                [105.4170779, 9.7822285],
                [105.4170223, 9.7820429],
                [105.4170008, 9.7818856],
                [105.4170021, 9.7816635],
                [105.4170316, 9.7813398],
                [105.4170652, 9.7810001],
                [105.4170987, 9.780749],
                [105.4171577, 9.7805164],
                [105.4172516, 9.7802811],
                [105.4173669, 9.7800737],
                [105.4175305, 9.7798899],
                [105.4177169, 9.7797446],
                [105.4179637, 9.7795873],
                [105.418472, 9.7793084],
                [105.4187184, 9.7791479],
                [105.4189213, 9.7789476],
                [105.4190661, 9.778752],
                [105.4192029, 9.7785472],
                [105.4192995, 9.7783575],
                [105.4193564, 9.7781514],
                [105.4193772, 9.777971],
                [105.4193652, 9.7777833],
                [105.4193303, 9.777622],
                [105.4192579, 9.7774833],
                [105.4191479, 9.7773577],
                [105.4189897, 9.7772414],
                [105.4188341, 9.7771912],
                [105.4186034, 9.7771621],
                [105.4183272, 9.7771634],
                [105.4180093, 9.7772137],
                [105.4177263, 9.7772996],
                [105.4174729, 9.7774185],
                [105.4172167, 9.7775533],
                [105.4169753, 9.7777304],
                [105.4167339, 9.7779868],
                [105.4157147, 9.7791076],
                [105.4154652, 9.7793243],
                [105.4152279, 9.7794829],
                [105.4149583, 9.7796032],
                [105.4147129, 9.779656],
                [105.4145251, 9.7796613],
                [105.4143508, 9.7796362],
                [105.414167, 9.7795708],
                [105.4140075, 9.779471],
                [105.4138921, 9.779352],
                [105.4138063, 9.7791842],
                [105.4137513, 9.7789542],
                [105.4137366, 9.7787415],
                [105.413756, 9.7785075],
                [105.4138341, 9.7782204],
                [105.4139471, 9.7779802],
                [105.4141375, 9.7776749],
                [105.4144299, 9.7773736],
                [105.4148054, 9.7771212],
                [105.415478, 9.7767293],
                [105.4158354, 9.7764881],
                [105.4160902, 9.7762872],
                [105.416294, 9.7760546],
                [105.4164912, 9.775781],
                [105.4166977, 9.7754453],
                [105.4168565, 9.775092],
                [105.4169565, 9.7747647],
                [105.4169726, 9.7744634],
                [105.4169083, 9.774199],
                [105.4167795, 9.7739241],
                [105.4165488, 9.7736915],
                [105.4162699, 9.7735276],
                [105.4159695, 9.7734166],
                [105.4156154, 9.7733532],
                [105.4152775, 9.7733638],
                [105.4149771, 9.7734166],
                [105.4146445, 9.7735646],
                [105.4142985, 9.7738263],
                [105.4139793, 9.7741303],
                [105.4136869, 9.7744448],
                [105.4133758, 9.7748281],
                [105.4130915, 9.7752484],
                [105.4122466, 9.7767524],
                [105.4120696, 9.77703],
                [105.4118872, 9.7772546],
                [105.4116458, 9.7774291],
                [105.4114044, 9.7774952],
                [105.4111871, 9.7774925],
                [105.4110128, 9.7774264],
                [105.4108894, 9.777293],
                [105.4107794, 9.7770947],
                [105.410652, 9.7767907],
                [105.4105568, 9.7765092],
                [105.410471, 9.7761788],
                [105.4103804, 9.775642],
                [105.410302, 9.7749365],
                [105.4102483, 9.7746219],
                [105.4101652, 9.7743127],
                [105.4100512, 9.7740338],
                [105.4097226, 9.7735488],
                [105.4095107, 9.7732157],
                [105.4093766, 9.772925],
                [105.4092693, 9.7726342],
                [105.409221, 9.7723223],
                [105.4092747, 9.7720474],
                [105.4094195, 9.7718359],
                [105.4096556, 9.7716879],
                [105.4099613, 9.7716298],
                [105.41031, 9.7716456],
                [105.4106533, 9.7717091],
                [105.4111013, 9.771869],
                [105.4122198, 9.7724677],
                [105.4125296, 9.7725866],
                [105.4128796, 9.7726739],
                [105.4132444, 9.7727109],
                [105.4135448, 9.7726818],
                [105.413813, 9.7725972],
                [105.4140651, 9.7724465],
                [105.4142636, 9.7722245],
                [105.4144219, 9.7719549],
                [105.4145211, 9.7716298],
                [105.4145426, 9.7712808],
                [105.4145104, 9.7709425],
                [105.4143092, 9.7707548],
                [105.4140276, 9.7705883],
                [105.4132122, 9.7701812],
                [105.412865, 9.7700596],
                [105.4125228, 9.7699698],
                [105.4122385, 9.7699261],
                [105.4118872, 9.7698984],
                [105.4115385, 9.7698878],
                [105.4109967, 9.7699328],
                [105.4102322, 9.7700834],
                [105.4084325, 9.770509],
                [105.407837, 9.7706306],
                [105.4073247, 9.770694],
                [105.4068071, 9.7707152],
                [105.4062921, 9.7706676],
                [105.4058629, 9.7705566],
                [105.4055518, 9.7704085],
                [105.4052433, 9.7701706],
                [105.4050207, 9.7699063],
                [105.4048759, 9.7695944],
                [105.4048169, 9.7692878],
                [105.4048061, 9.7689389],
                [105.4048866, 9.7685582],
                [105.405018, 9.7681829],
                [105.4052192, 9.7677705],
                [105.405482, 9.7673476],
                [105.4063457, 9.7661528],
                [105.4065388, 9.765825],
                [105.4067051, 9.7654655],
                [105.4068232, 9.7650373],
                [105.4068661, 9.7646038],
                [105.4068822, 9.7640593],
                [105.4068392, 9.7634936],
                [105.4066569, 9.7624733],
                [105.4065764, 9.7619604],
                [105.4065335, 9.7614476],
                [105.4065227, 9.7610141],
                [105.4065657, 9.7606705],
                [105.4066729, 9.7602898],
                [105.4068661, 9.7599462],
                [105.4071021, 9.7596607],
                [105.4073596, 9.7595074],
                [105.4076332, 9.7594281],
                [105.4079121, 9.7594122],
                [105.4081964, 9.7594545],
                [105.408529, 9.7595602],
                [105.4088858, 9.759744],
                [105.4101545, 9.7605753],
                [105.4104763, 9.7607656],
                [105.4107955, 9.7609242],
                [105.4110986, 9.7610326],
                [105.4114339, 9.761104],
                [105.4118335, 9.7611489],
                [105.4122813, 9.7611513],
                [105.4127267, 9.76103],
                [105.4130137, 9.7608925],
                [105.4133248, 9.7606863],
                [105.4135743, 9.7604458],
                [105.4137835, 9.7602079],
                [105.4139766, 9.7599145],
                [105.4142153, 9.7594915],
                [105.4144084, 9.7590316],
                [105.4145586, 9.7585663],
                [105.4147035, 9.7580376],
                [105.4152339, 9.7556847],
                [105.4152614, 9.7554431],
                [105.4152694, 9.755196],
                [105.4152049, 9.7548032],
                [105.4145157, 9.7534487],
                [105.4136896, 9.7519472],
                [105.4129768, 9.7508712],
                [105.4121825, 9.7496721],
                [105.4116007, 9.7488553],
                [105.4113238, 9.7485647],
                [105.4110544, 9.7483983],
                [105.4105158, 9.7481989],
                [105.4101792, 9.7480659],
                [105.4098091, 9.7479996],
                [105.4093043, 9.7479336],
                [105.408497, 9.7481346],
                [105.4078919, 9.7485354],
                [105.4068502, 9.7496371],
                [105.4062452, 9.750138],
                [105.4057073, 9.7505387],
                [105.4051021, 9.7508062],
                [105.4047657, 9.7508734],
                [105.4040254, 9.7507742],
                [105.4035203, 9.7505081],
                [105.4027122, 9.7499421],
                [105.4007585, 9.7481271],
                [105.4002134, 9.7477652],
                [105.3989738, 9.7468287],
                [105.3987034, 9.7466636],
                [105.3984289, 9.7465054],
                [105.3981503, 9.7463541],
                [105.3978679, 9.7462098],
                [105.3975819, 9.7460727],
                [105.3972924, 9.7459429],
                [105.3970071, 9.7458233],
                [105.3967188, 9.7457108],
                [105.3964278, 9.7456053],
                [105.3961342, 9.745507],
                [105.3958383, 9.745416],
                [105.3955401, 9.7453322],
                [105.3946996, 9.7451059],
                [105.3945706, 9.745083],
                [105.3944434, 9.7450522],
                [105.3943184, 9.7450134],
                [105.3941962, 9.7449669],
                [105.3940933, 9.7449207],
                [105.3939931, 9.744869],
                [105.3938959, 9.744812],
                [105.3938021, 9.7447498],
                [105.3937157, 9.744672],
                [105.393634, 9.7445895],
                [105.3935571, 9.7445025],
                [105.3934855, 9.7444113],
                [105.3934192, 9.7443163],
                [105.3933585, 9.7442177],
                [105.3932964, 9.7441015],
                [105.3932422, 9.7439816],
                [105.3931959, 9.7438584],
                [105.3931578, 9.7437326],
                [105.3931281, 9.7436047],
                [105.3931069, 9.743475],
                [105.3930943, 9.7433443],
                [105.3930902, 9.7432131],
                [105.3928026, 9.7388516],
                [105.3926034, 9.7383182],
                [105.3922054, 9.7375601],
                [105.3919781, 9.7371671],
                [105.3918075, 9.7368863],
                [105.3916087, 9.7366619],
                [105.3912963, 9.736269],
                [105.3911, 9.7360597],
                [105.390842, 9.7358481],
                [105.3906763, 9.7357054],
                [105.3904425, 9.7355392],
                [105.3901041, 9.7353994],
                [105.3885198, 9.7348278],
                [105.3876925, 9.7346156],
                [105.3872994, 9.7345477],
                [105.3845574, 9.734074],
                [105.3826289, 9.7337408],
                [105.3809835, 9.7334053],
                [105.3803595, 9.7332654],
                [105.3799054, 9.7330973],
                [105.3793664, 9.7329572],
                [105.3787138, 9.7326768],
                [105.3754369, 9.7317106],
                [105.3742452, 9.7312058],
                [105.3732235, 9.7305041],
                [105.3723719, 9.7298585],
                [105.3706117, 9.7282298],
                [105.3691071, 9.7269383],
                [105.3664516, 9.7234691],
                [105.3650311, 9.7211935],
                [105.3642215, 9.7200276],
                [105.3631992, 9.7187635],
                [105.3625461, 9.7180614],
                [105.3611547, 9.7164042],
                [105.3597353, 9.7151125],
                [105.3582732, 9.7137224],
                [105.3570243, 9.7126273],
                [105.355804, 9.7118977],
                [105.3542718, 9.7111683],
                [105.3510944, 9.7101595],
                [105.3496767, 9.7097218],
                [105.3476273, 9.7087293],
                [105.346358, 9.7079726],
                [105.3448094, 9.7068625],
                [105.3428231, 9.7057681],
                [105.3413759, 9.7048981],
                [105.340014, 9.7042809],
                [105.3382977, 9.7036501],
                [105.3364254, 9.7030896],
                [105.334355, 9.7029511],
                [105.3334759, 9.7029801],
                [105.3325684, 9.702981],
                [105.3318878, 9.7029535],
                [105.3312355, 9.7028698],
                [105.3307105, 9.7026033],
                [105.3300579, 9.7022946],
                [105.3296607, 9.702042],
                [105.3293486, 9.7018454],
                [105.3291215, 9.7016489],
                [105.3289227, 9.7013679],
                [105.3286387, 9.7010028],
                [105.328525, 9.7006936],
                [105.3285245, 9.7001876],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [106.3567007, 10.9915971],
                [106.3618592, 10.9846905],
                [106.3636619, 10.9822497],
                [106.3679502, 10.9765155],
                [106.3712059, 10.9722566],
                [106.3726911, 10.9701444],
                [106.3730725, 10.9696889],
                [106.3731933, 10.9696228],
                [106.3732176, 10.9696228],
                [106.373408, 10.9696228],
                [106.3776218, 10.9700091],
                [106.3868159, 10.9710137],
                [106.3914766, 10.9715032],
                [106.3924154, 10.9716018],
                [106.3976003, 10.9720919],
                [106.4025006, 10.9726611],
                [106.4109339, 10.9736832],
                [106.411248, 10.9736951],
                [106.4115791, 10.9736578],
                [106.4118982, 10.973542],
                [106.4154834, 10.9708971],
                [106.4222714, 10.9658091],
                [106.4366692, 10.9547162],
                [106.4400903, 10.9521032],
                [106.443695, 10.9493397],
                [106.4471167, 10.9467409],
                [106.4505243, 10.944158],
                [106.4507237, 10.9440068],
                [106.4533261, 10.9420509],
                [106.4535763, 10.9419218],
                [106.453609, 10.9419049],
                [106.456995, 10.9401582],
                [106.4592582, 10.9390665],
                [106.4642779, 10.9366321],
                [106.4673236, 10.9350956],
                [106.4713285, 10.9332035],
                [106.4743177, 10.9316629],
                [106.4793619, 10.9291689],
                [106.4831155, 10.9272983],
                [106.486191, 10.9257231],
                [106.4871887, 10.9252411],
                [106.4873707, 10.9251532],
                [106.4883389, 10.9246855],
                [106.4890819, 10.9243108],
                [106.4896076, 10.9240457],
                [106.4921272, 10.9227751],
                [106.4962415, 10.9207229],
                [106.500437, 10.9185069],
                [106.5036729, 10.9168143],
                [106.5039168, 10.9166866],
                [106.5053085, 10.9159587],
                [106.5110411, 10.9129849],
                [106.5159775, 10.9104121],
                [106.5190829, 10.9088703],
                [106.5195891, 10.9086013],
                [106.5250273, 10.9057091],
                [106.5281797, 10.9040208],
                [106.5307472, 10.9030537],
                [106.5332267, 10.9012996],
                [106.5322349, 10.897744],
                [106.5321781, 10.8975358],
                [106.5321231, 10.8973344],
                [106.5320612, 10.8971212],
                [106.5320003, 10.896933],
                [106.5306742, 10.8922333],
                [106.5292296, 10.8863862],
                [106.527992, 10.8817066],
                [106.5265907, 10.877284],
                [106.526006, 10.8743612],
                [106.5258774, 10.8737182],
                [106.5257732, 10.8732353],
                [106.5253413, 10.8718268],
                [106.5247046, 10.8694461],
                [106.5242041, 10.8674542],
                [106.5227851, 10.8625355],
                [106.5223911, 10.8610169],
                [106.5214826, 10.8575156],
                [106.5201795, 10.8523127],
                [106.5201298, 10.8521182],
                [106.5188492, 10.8471074],
                [106.5180024, 10.8437424],
                [106.516243, 10.8367517],
                [106.5157244, 10.8348271],
                [106.5152215, 10.8329608],
                [106.5137691, 10.8268056],
                [106.513592, 10.8261456],
                [106.5130631, 10.8236797],
                [106.511447, 10.8171478],
                [106.511257, 10.8164818],
                [106.5100956, 10.8111705],
                [106.509515, 10.8092876],
                [106.5093506, 10.8084099],
                [106.5087259, 10.8060045],
                [106.5078134, 10.8022988],
                [106.5071802, 10.7993437],
                [106.5062217, 10.7949845],
                [106.505652, 10.7937568],
                [106.5055375, 10.7933273],
                [106.5054201, 10.7928872],
                [106.5049526, 10.7909377],
                [106.5046416, 10.7896632],
                [106.5044774, 10.7889841],
                [106.5038684, 10.7883308],
                [106.5028119, 10.78754],
                [106.5014309, 10.7862305],
                [106.5004348, 10.7853752],
                [106.4994896, 10.7846399],
                [106.4972207, 10.7827529],
                [106.4965049, 10.7821591],
                [106.4949844, 10.780898],
                [106.4928029, 10.779107],
                [106.490708, 10.7773264],
                [106.4900987, 10.7768468],
                [106.4894363, 10.7762833],
                [106.4885044, 10.7754906],
                [106.4869667, 10.774357],
                [106.4828578, 10.7712496],
                [106.4794953, 10.7685706],
                [106.4771195, 10.7667263],
                [106.4770895, 10.7667069],
                [106.4765931, 10.7663864],
                [106.4746657, 10.7649376],
                [106.4721531, 10.7630037],
                [106.4719495, 10.7628495],
                [106.4635618, 10.7565046],
                [106.4637111, 10.7563181],
                [106.4659004, 10.753584],
                [106.4670105, 10.7521769],
                [106.4698171, 10.7484476],
                [106.4722331, 10.7455255],
                [106.4735079, 10.7464922],
                [106.476624, 10.7426171],
                [106.4776517, 10.74125],
                [106.4791087, 10.7393705],
                [106.4800788, 10.7381167],
                [106.482108, 10.7354428],
                [106.4824246, 10.7356846],
                [106.4843356, 10.7333672],
                [106.4846448, 10.7329689],
                [106.4858499, 10.7314319],
                [106.4871569, 10.7298149],
                [106.4889865, 10.7312615],
                [106.4911731, 10.7297864],
                [106.4933096, 10.7285484],
                [106.4953548, 10.7273631],
                [106.4957672, 10.7276392],
                [106.4976268, 10.7262419],
                [106.4996255, 10.7249923],
                [106.5016856, 10.7265365],
                [106.5034367, 10.7244005],
                [106.5043261, 10.7233277],
                [106.5057832, 10.7215178],
                [106.5071619, 10.7198101],
                [106.5087283, 10.7179442],
                [106.5093718, 10.7170518],
                [106.5109689, 10.7179871],
                [106.5186719, 10.7227409],
                [106.5202276, 10.7236952],
                [106.5238332, 10.7209711],
                [106.5248644, 10.7204004],
                [106.5258247, 10.7198259],
                [106.5263635, 10.7195943],
                [106.5278175, 10.718777],
                [106.5291828, 10.7180075],
                [106.5302547, 10.7172794],
                [106.5301779, 10.7171431],
                [106.5298694, 10.7168426],
                [106.5284255, 10.7149252],
                [106.52843, 10.714401],
                [106.5272973, 10.7096247],
                [106.5272428, 10.7092927],
                [106.527255, 10.7090329],
                [106.5275976, 10.7074711],
                [106.528797, 10.7027991],
                [106.5303326, 10.6982404],
                [106.5319006, 10.6941008],
                [106.5321707, 10.693611],
                [106.5322544, 10.6930899],
                [106.5337771, 10.6883974],
                [106.5338665, 10.6881621],
                [106.5339848, 10.6879482],
                [106.5343165, 10.6875621],
                [106.5345856, 10.6873334],
                [106.5351185, 10.6870425],
                [106.5352991, 10.6865864],
                [106.5354947, 10.686407],
                [106.5356108, 10.6862032],
                [106.5356728, 10.6860015],
                [106.5357105, 10.6855624],
                [106.5362099, 10.6856203],
                [106.5365597, 10.6857233],
                [106.5372773, 10.6859921],
                [106.5375781, 10.6861055],
                [106.5378709, 10.6861646],
                [106.538285, 10.6861554],
                [106.5386506, 10.6861182],
                [106.5398751, 10.6860736],
                [106.5402099, 10.6860981],
                [106.5407431, 10.6861777],
                [106.5461397, 10.6869831],
                [106.5462635, 10.6870019],
                [106.5468897, 10.6871215],
                [106.5470613, 10.6871315],
                [106.5471528, 10.6871444],
                [106.5474885, 10.6869002],
                [106.5477009, 10.6866887],
                [106.5479091, 10.6864791],
                [106.5481245, 10.6862807],
                [106.5482288, 10.6862695],
                [106.5484588, 10.6862447],
                [106.5490012, 10.686345],
                [106.5494624, 10.6863542],
                [106.5496751, 10.6862272],
                [106.5499239, 10.6860534],
                [106.5501589, 10.6858959],
                [106.5503892, 10.6857036],
                [106.5506169, 10.6854183],
                [106.5507228, 10.6850455],
                [106.5507772, 10.6847446],
                [106.5508026, 10.6844881],
                [106.5508522, 10.6840235],
                [106.5510109, 10.6836184],
                [106.5511049, 10.683346],
                [106.5511285, 10.6831032],
                [106.5512363, 10.6825878],
                [106.5513036, 10.6822328],
                [106.5513475, 10.681798],
                [106.5514902, 10.6812666],
                [106.5515205, 10.6804859],
                [106.5516196, 10.6801282],
                [106.5515095, 10.6796467],
                [106.5516632, 10.6792513],
                [106.5520287, 10.6785632],
                [106.5523145, 10.6780699],
                [106.5524514, 10.6777946],
                [106.552554, 10.6775654],
                [106.5528083, 10.6773436],
                [106.5528827, 10.6770924],
                [106.5529219, 10.6768055],
                [106.5529879, 10.676013],
                [106.5530928, 10.6756942],
                [106.553392, 10.6756097],
                [106.5536761, 10.6753994],
                [106.5536367, 10.6751759],
                [106.5534096, 10.6747847],
                [106.5533279, 10.6745565],
                [106.5533049, 10.6743208],
                [106.5533738, 10.6734027],
                [106.5533751, 10.6730566],
                [106.5532947, 10.6723181],
                [106.5532335, 10.6720611],
                [106.5531659, 10.6718818],
                [106.5530676, 10.6717364],
                [106.5529592, 10.671626],
                [106.5527984, 10.6714981],
                [106.5525841, 10.6713903],
                [106.5523927, 10.6713364],
                [106.5522727, 10.6712662],
                [106.5521898, 10.6711458],
                [106.5521477, 10.6710254],
                [106.5520992, 10.6707584],
                [106.5520597, 10.6705916],
                [106.5520673, 10.6704687],
                [106.5521135, 10.6703584],
                [106.5525869, 10.6697649],
                [106.5526582, 10.6696203],
                [106.5527798, 10.6692344],
                [106.5528314, 10.6691163],
                [106.55292, 10.6690084],
                [106.5530717, 10.6689282],
                [106.5533001, 10.6688605],
                [106.5534468, 10.6688154],
                [106.553531, 10.6687502],
                [106.5535705, 10.6686812],
                [106.5535808, 10.6685847],
                [106.5535565, 10.6684693],
                [106.553469, 10.6681984],
                [106.5533266, 10.6677106],
                [106.5533004, 10.6675446],
                [106.5532411, 10.6665442],
                [106.5532468, 10.6662732],
                [106.5532825, 10.666045],
                [106.5532732, 10.6658413],
                [106.553235, 10.6656959],
                [106.5531701, 10.6655602],
                [106.5530525, 10.6654188],
                [106.5529249, 10.6653134],
                [106.5525217, 10.665104],
                [106.5519948, 10.664847],
                [106.5518927, 10.6647467],
                [106.5518169, 10.6646336],
                [106.5517137, 10.6643905],
                [106.5516212, 10.6642593],
                [106.551519, 10.6641393],
                [106.5514922, 10.6639835],
                [106.5515064, 10.6638263],
                [106.5515681, 10.6635943],
                [106.5515465, 10.6634152],
                [106.5514745, 10.6632539],
                [106.5514158, 10.6622258],
                [106.5513644, 10.6617862],
                [106.5513947, 10.6614582],
                [106.5513735, 10.6612822],
                [106.5512824, 10.6610616],
                [106.5509638, 10.6606142],
                [106.5508728, 10.6601818],
                [106.5505539, 10.6592268],
                [106.5504895, 10.6590349],
                [106.550393, 10.6589991],
                [106.5502685, 10.6590117],
                [106.5501219, 10.6590867],
                [106.5497364, 10.6593175],
                [106.5492686, 10.659657],
                [106.5490948, 10.6592142],
                [106.5490004, 10.6588219],
                [106.548974, 10.658528],
                [106.5489702, 10.6574327],
                [106.5499123, 10.6574725],
                [106.5505936, 10.6574704],
                [106.5512227, 10.6573656],
                [106.551732, 10.6573352],
                [106.5522728, 10.6573953],
                [106.55272, 10.6574903],
                [106.5532101, 10.6574053],
                [106.5540801, 10.6571716],
                [106.5559217, 10.6569738],
                [106.5559, 10.6565235],
                [106.5559506, 10.6562617],
                [106.5560088, 10.655402],
                [106.555749, 10.6552591],
                [106.5555305, 10.6550981],
                [106.5553727, 10.6548833],
                [106.5553029, 10.6546835],
                [106.5551694, 10.6535175],
                [106.5552058, 10.652581],
                [106.5552789, 10.6523528],
                [106.5554127, 10.6519353],
                [106.5556683, 10.6509266],
                [106.5557916, 10.6508827],
                [106.5563712, 10.6508857],
                [106.5565107, 10.6507604],
                [106.5569927, 10.6507232],
                [106.5572657, 10.6505125],
                [106.5573397, 10.6503921],
                [106.5575127, 10.6497486],
                [106.5577401, 10.6492237],
                [106.5579277, 10.648603],
                [106.5585265, 10.648638],
                [106.5586492, 10.6491948],
                [106.5588441, 10.6495406],
                [106.55918, 10.6499646],
                [106.5590065, 10.6502718],
                [106.558777, 10.6506093],
                [106.5587753, 10.6507101],
                [106.5588863, 10.6511526],
                [106.5590288, 10.6518814],
                [106.559774, 10.6516331],
                [106.5608331, 10.6516303],
                [106.5616829, 10.651567],
                [106.5630729, 10.6514923],
                [106.5631335, 10.6522051],
                [106.563711, 10.6521552],
                [106.5644638, 10.6521552],
                [106.5648159, 10.6521803],
                [106.5652959, 10.6523024],
                [106.5651651, 10.6525984],
                [106.5650451, 10.6530313],
                [106.5655954, 10.6534463],
                [106.5658803, 10.6537659],
                [106.565981, 10.6542313],
                [106.5660053, 10.6551498],
                [106.5660311, 10.6552974],
                [106.5662728, 10.6564275],
                [106.5672389, 10.6562395],
                [106.5685221, 10.6560476],
                [106.5686163, 10.656583],
                [106.5690213, 10.656514],
                [106.5695974, 10.6563635],
                [106.5697294, 10.656319],
                [106.5698411, 10.6562645],
                [106.5706201, 10.655792],
                [106.5718896, 10.6549938],
                [106.5728599, 10.6543657],
                [106.5732597, 10.6541615],
                [106.5734877, 10.6541309],
                [106.5735057, 10.65389],
                [106.5734943, 10.6537938],
                [106.5734032, 10.6535642],
                [106.5732919, 10.6533366],
                [106.5732356, 10.6532257],
                [106.5731893, 10.6531124],
                [106.5731681, 10.6530326],
                [106.573152, 10.6529495],
                [106.5730781, 10.65231],
                [106.5730677, 10.6520628],
                [106.5730741, 10.6519945],
                [106.5730876, 10.6519271],
                [106.5731486, 10.6516676],
                [106.5732699, 10.6511935],
                [106.5733002, 10.6510407],
                [106.5733154, 10.6509065],
                [106.5733184, 10.6505689],
                [106.5733555, 10.6498562],
                [106.5741794, 10.6498591],
                [106.5747072, 10.6498913],
                [106.5751194, 10.6498703],
                [106.5751555, 10.6497213],
                [106.5754631, 10.6491116],
                [106.5760391, 10.6492703],
                [106.5760959, 10.6492402],
                [106.576141, 10.6492329],
                [106.5761823, 10.6492355],
                [106.5764945, 10.6493214],
                [106.5766742, 10.6493515],
                [106.5766914, 10.6493114],
                [106.5767139, 10.6492861],
                [106.576807, 10.6492418],
                [106.576841, 10.6492088],
                [106.5768593, 10.6491724],
                [106.5768805, 10.6490848],
                [106.5768957, 10.6490283],
                [106.5769205, 10.6489742],
                [106.5769639, 10.648885],
                [106.577813, 10.6491039],
                [106.5786197, 10.6491884],
                [106.5786036, 10.649432],
                [106.5789715, 10.6496043],
                [106.5791718, 10.6500139],
                [106.5793738, 10.6503774],
                [106.5794731, 10.6508243],
                [106.5794587, 10.6510335],
                [106.5794027, 10.6511967],
                [106.5793937, 10.6512942],
                [106.5795486, 10.6519005],
                [106.5780058, 10.651892],
                [106.5780618, 10.6523796],
                [106.5780654, 10.6526492],
                [106.5780347, 10.6528442],
                [106.5779066, 10.6532379],
                [106.5779319, 10.6533159],
                [106.5782548, 10.653644],
                [106.5784046, 10.6538408],
                [106.5784551, 10.6539649],
                [106.5788246, 10.6544057],
                [106.5788075, 10.6544953],
                [106.5791339, 10.6547819],
                [106.5793893, 10.6549274],
                [106.5794043, 10.655075],
                [106.579651, 10.6556802],
                [106.5797445, 10.6560322],
                [106.5797761, 10.6560809],
                [106.5798284, 10.6560951],
                [106.5805042, 10.6560978],
                [106.5805785, 10.6560603],
                [106.5806321, 10.6560101],
                [106.5806525, 10.6559387],
                [106.58065, 10.6554804],
                [106.5811314, 10.6554418],
                [106.5814854, 10.6553786],
                [106.5831997, 10.6553672],
                [106.5841286, 10.6536567],
                [106.5843474, 10.6536585],
                [106.5845432, 10.6536181],
                [106.5849847, 10.6534705],
                [106.5855467, 10.653364],
                [106.5856912, 10.6533281],
                [106.5861658, 10.6532287],
                [106.5865062, 10.6531845],
                [106.586855, 10.6532116],
                [106.5869821, 10.6524906],
                [106.5887249, 10.6526091],
                [106.5905312, 10.6527818],
                [106.5907354, 10.6518203],
                [106.5909105, 10.6510949],
                [106.5914942, 10.6511666],
                [106.591663, 10.6501114],
                [106.5923216, 10.6502391],
                [106.5927656, 10.6504001],
                [106.5933278, 10.6504127],
                [106.5949769, 10.6506025],
                [106.5950103, 10.6503684],
                [106.5959254, 10.6503934],
                [106.5958353, 10.6506886],
                [106.595771, 10.6509585],
                [106.5956508, 10.6510809],
                [106.595565, 10.6518527],
                [106.5954791, 10.652325],
                [106.5955006, 10.6526161],
                [106.5955505, 10.6529725],
                [106.5952794, 10.6531131],
                [106.5952297, 10.6534997],
                [106.5975102, 10.6533844],
                [106.5974935, 10.6531399],
                [106.5980397, 10.6530355],
                [106.5984494, 10.6528953],
                [106.5988773, 10.6526866],
                [106.5990925, 10.6525164],
                [106.5993451, 10.6525879],
                [106.5996118, 10.6525942],
                [106.5996118, 10.6523346],
                [106.599659, 10.6522581],
                [106.5997662, 10.652228],
                [106.600007, 10.6522379],
                [106.6002194, 10.6521052],
                [106.6003105, 10.6520023],
                [106.6003454, 10.6518935],
                [106.6003332, 10.6517399],
                [106.6003712, 10.6515922],
                [106.6003575, 10.6515386],
                [106.6003029, 10.6514476],
                [106.6002953, 10.6513686],
                [106.600318, 10.651288],
                [106.6003742, 10.6511807],
                [106.6004015, 10.6510405],
                [106.6004243, 10.6509376],
                [106.6004849, 10.6508482],
                [106.6006523, 10.6506916],
                [106.6009531, 10.650882],
                [106.6010851, 10.6509327],
                [106.6013218, 10.6509745],
                [106.6015009, 10.6510311],
                [106.601648, 10.65114],
                [106.6017542, 10.6512846],
                [106.6018028, 10.651465],
                [106.6018316, 10.6516857],
                [106.6018347, 10.6520198],
                [106.6018589, 10.6521719],
                [106.6019211, 10.6523284],
                [106.6020896, 10.6525342],
                [106.602173, 10.6527012],
                [106.602302, 10.6528787],
                [106.6024401, 10.6530248],
                [106.6025872, 10.6531277],
                [106.6027481, 10.6532842],
                [106.6028497, 10.6534363],
                [106.6028907, 10.6535705],
                [106.6029119, 10.6537331],
                [106.6029423, 10.6538852],
                [106.603182, 10.6546263],
                [106.6032408, 10.6547621],
                [106.6034921, 10.655137],
                [106.6038354, 10.6557013],
                [106.6039106, 10.6558818],
                [106.6039285, 10.6559997],
                [106.6039706, 10.6564749],
                [106.604014, 10.6566229],
                [106.6041939, 10.6570103],
                [106.6045537, 10.6576523],
                [106.6046237, 10.6578162],
                [106.6047259, 10.6579783],
                [106.6049824, 10.658224],
                [106.6053836, 10.6586959],
                [106.6054877, 10.6586611],
                [106.6055605, 10.6585985],
                [106.605573, 10.6585426],
                [106.6055583, 10.6584756],
                [106.6054968, 10.6583537],
                [106.6054934, 10.6583146],
                [106.6055241, 10.6582743],
                [106.6056183, 10.658226],
                [106.6057924, 10.6581396],
                [106.6059856, 10.6580963],
                [106.6069861, 10.6580456],
                [106.607428, 10.6579333],
                [106.608902, 10.6575716],
                [106.6092494, 10.6574768],
                [106.6095519, 10.6573345],
                [106.6097224, 10.6572048],
                [106.6101374, 10.6568697],
                [106.6105133, 10.6566013],
                [106.6108306, 10.6568979],
                [106.6114274, 10.6565987],
                [106.6115279, 10.6561105],
                [106.6117696, 10.6560561],
                [106.6123175, 10.6558074],
                [106.6126003, 10.6552527],
                [106.6127072, 10.6551163],
                [106.6128194, 10.6550257],
                [106.6132164, 10.6548245],
                [106.6133039, 10.6547373],
                [106.6133426, 10.6546613],
                [106.613445, 10.6543595],
                [106.6137798, 10.6545106],
                [106.6140141, 10.654122],
                [106.6141904, 10.6538525],
                [106.6143015, 10.6538219],
                [106.6143992, 10.6538355],
                [106.6144817, 10.6537645],
                [106.6145946, 10.6537296],
                [106.6149266, 10.653872],
                [106.6156273, 10.6541118],
                [106.615655, 10.6540319],
                [106.6154639, 10.6538729],
                [106.6155775, 10.6536691],
                [106.6158426, 10.6537853],
                [106.6160647, 10.6532955],
                [106.6163857, 10.6531765],
                [106.6167353, 10.6533377],
                [106.6167453, 10.6529354],
                [106.6167226, 10.6525509],
                [106.6167431, 10.6523608],
                [106.6167909, 10.6521968],
                [106.6169172, 10.6520699],
                [106.6170033, 10.6520332],
                [106.617141, 10.6520482],
                [106.6171822, 10.6519702],
                [106.6172338, 10.6519251],
                [106.6173467, 10.6518969],
                [106.6174662, 10.65188],
                [106.6175877, 10.6519007],
                [106.6177159, 10.6519567],
                [106.6177562, 10.6516494],
                [106.6177575, 10.6512745],
                [106.6177305, 10.6512254],
                [106.6176128, 10.6511576],
                [106.617395, 10.6510153],
                [106.6172732, 10.6509276],
                [106.6172854, 10.650792],
                [106.6173612, 10.6505141],
                [106.6173612, 10.6503572],
                [106.6173422, 10.6501711],
                [106.6180154, 10.6501043],
                [106.6180516, 10.6498385],
                [106.6184884, 10.6497278],
                [106.618984, 10.6496845],
                [106.6189639, 10.6490133],
                [106.6198603, 10.648899],
                [106.6207792, 10.6487789],
                [106.6211348, 10.6487236],
                [106.6215741, 10.6485667],
                [106.6220935, 10.6484548],
                [106.6223469, 10.6483823],
                [106.6223239, 10.6481443],
                [106.6226675, 10.6480087],
                [106.6230706, 10.647921],
                [106.6236104, 10.6477282],
                [106.6240585, 10.6476632],
                [106.6240334, 10.647406],
                [106.6245614, 10.6474547],
                [106.624712, 10.6470826],
                [106.6240839, 10.6465574],
                [106.6239614, 10.6464561],
                [106.6239118, 10.6463356],
                [106.6238926, 10.6460911],
                [106.6239309, 10.6459765],
                [106.6240284, 10.6458373],
                [106.624134, 10.6457306],
                [106.6242504, 10.6456302],
                [106.6243244, 10.6455306],
                [106.6243823, 10.6453693],
                [106.6244177, 10.6452112],
                [106.6244225, 10.6450358],
                [106.6243293, 10.6444613],
                [106.6234376, 10.6444323],
                [106.6233554, 10.6440829],
                [106.6224976, 10.6441336],
                [106.6224293, 10.6440883],
                [106.6223508, 10.6440497],
                [106.6222484, 10.6440295],
                [106.62213, 10.6440259],
                [106.6220105, 10.6440344],
                [106.6218987, 10.6440571],
                [106.6217719, 10.6441002],
                [106.6215383, 10.6432687],
                [106.6220632, 10.6431575],
                [106.6221253, 10.6426575],
                [106.6225062, 10.6425918],
                [106.6221889, 10.641505],
                [106.621942, 10.6414814],
                [106.6217452, 10.6415248],
                [106.6216929, 10.6414104],
                [106.6213834, 10.6415169],
                [106.6212058, 10.6409296],
                [106.6215431, 10.6408111],
                [106.6218814, 10.6407452],
                [106.6220611, 10.6406686],
                [106.6222466, 10.6406435],
                [106.6224388, 10.6405853],
                [106.6227943, 10.6404098],
                [106.6229023, 10.6403277],
                [106.6230637, 10.6401522],
                [106.6233975, 10.6399703],
                [106.6237183, 10.6396887],
                [106.6237668, 10.6396223],
                [106.6240628, 10.639447],
                [106.6241101, 10.6394356],
                [106.6241558, 10.6394428],
                [106.624335, 10.6395725],
                [106.6243896, 10.639648],
                [106.6244881, 10.6398568],
                [106.6247857, 10.6398015],
                [106.6250197, 10.6397738],
                [106.6251307, 10.6397548],
                [106.6251991, 10.6397129],
                [106.625249, 10.6396544],
                [106.625805, 10.6397168],
                [106.6261098, 10.640349],
                [106.6261724, 10.6405754],
                [106.6263003, 10.640937],
                [106.6271793, 10.6423959],
                [106.6275792, 10.6421382],
                [106.6281483, 10.6418075],
                [106.6283344, 10.6419452],
                [106.6284295, 10.6419749],
                [106.6285812, 10.6419913],
                [106.6291929, 10.6419954],
                [106.629483, 10.64203],
                [106.6295545, 10.6418067],
                [106.6296574, 10.6414698],
                [106.6297295, 10.6411321],
                [106.6297181, 10.6409577],
                [106.6295293, 10.6403921],
                [106.629897, 10.6403018],
                [106.6296754, 10.6393398],
                [106.6299326, 10.6392764],
                [106.6300039, 10.6392854],
                [106.630101, 10.6393435],
                [106.6301572, 10.6393405],
                [106.6302254, 10.6392973],
                [106.6303744, 10.6391541],
                [106.6304826, 10.6391027],
                [106.6305332, 10.6390601],
                [106.6305584, 10.6390016],
                [106.630544, 10.6389413],
                [106.6304285, 10.6387888],
                [106.6303581, 10.6387037],
                [106.6303311, 10.6386044],
                [106.6303581, 10.6384944],
                [106.6304754, 10.6382621],
                [106.6305079, 10.6380794],
                [106.6305628, 10.6379166],
                [106.6307798, 10.6375348],
                [106.6309771, 10.6373171],
                [106.6310074, 10.637159],
                [106.6310408, 10.6370919],
                [106.6311333, 10.636913],
                [106.6311629, 10.636743],
                [106.6310082, 10.6366848],
                [106.6304832, 10.6365715],
                [106.6300963, 10.6365059],
                [106.6296942, 10.6364164],
                [106.6295121, 10.6363135],
                [106.6294331, 10.635773],
                [106.6293385, 10.6352493],
                [106.6293401, 10.6351394],
                [106.629356, 10.6350553],
                [106.6293897, 10.6349538],
                [106.6297903, 10.6342603],
                [106.6298566, 10.633242],
                [106.6298491, 10.6330965],
                [106.629832, 10.6329679],
                [106.6296785, 10.632041],
                [106.6296305, 10.6316508],
                [106.6297555, 10.6315881],
                [106.6299035, 10.6315178],
                [106.6299903, 10.63142],
                [106.6300311, 10.6313347],
                [106.6300413, 10.6311542],
                [106.6301434, 10.6308131],
                [106.6302506, 10.6307078],
                [106.6305848, 10.6304745],
                [106.6307099, 10.6303391],
                [106.630766, 10.6302011],
                [106.6307456, 10.6300306],
                [106.6306027, 10.629835],
                [106.629955, 10.6291446],
                [106.6298286, 10.6290346],
                [106.6297287, 10.6289796],
                [106.6296789, 10.6289406],
                [106.6296624, 10.6288789],
                [106.629663, 10.6285976],
                [106.6295871, 10.628189],
                [106.6295621, 10.6275909],
                [106.6296632, 10.6272859],
                [106.6298361, 10.6269214],
                [106.6301368, 10.6264266],
                [106.6303256, 10.6267627],
                [106.6307996, 10.6275256],
                [106.6312727, 10.6280616],
                [106.6316328, 10.628275],
                [106.6321728, 10.6282499],
                [106.6328824, 10.6279942],
                [106.6335571, 10.6271058],
                [106.6341996, 10.6263073],
                [106.6346271, 10.6256414],
                [106.6349407, 10.6253147],
                [106.6350606, 10.625716],
                [106.635178, 10.6259518],
                [106.6353235, 10.6261223],
                [106.6355863, 10.6262527],
                [106.6358976, 10.6262954],
                [106.6362778, 10.6262452],
                [106.6366163, 10.6261818],
                [106.6368773, 10.6261848],
                [106.6373021, 10.6262847],
                [106.6376162, 10.626416],
                [106.6378878, 10.6265681],
                [106.6382368, 10.6268663],
                [106.6387147, 10.6272347],
                [106.6388936, 10.6274694],
                [106.6391347, 10.6269983],
                [106.6392895, 10.6271444],
                [106.6410251, 10.6266209],
                [106.6412375, 10.6269646],
                [106.6415701, 10.6273885],
                [106.6420202, 10.6278423],
                [106.642746, 10.6285757],
                [106.6437069, 10.6295284],
                [106.6437827, 10.6296189],
                [106.6438377, 10.6297093],
                [106.6451931, 10.6323573],
                [106.6453087, 10.6326507],
                [106.645722, 10.633482],
                [106.6461068, 10.6341056],
                [106.6461988, 10.6342395],
                [106.6465976, 10.6341428],
                [106.6467861, 10.6341632],
                [106.6468358, 10.6341508],
                [106.6470955, 10.6338759],
                [106.6474792, 10.6337],
                [106.6477242, 10.6335195],
                [106.6481044, 10.6331658],
                [106.6483169, 10.6330445],
                [106.6483819, 10.6329541],
                [106.6484198, 10.632775],
                [106.6484757, 10.6327058],
                [106.6486255, 10.6326757],
                [106.6488186, 10.6327218],
                [106.6489936, 10.6328317],
                [106.6491307, 10.6328708],
                [106.6492642, 10.63283],
                [106.6494772, 10.6327094],
                [106.6497189, 10.6326597],
                [106.6499715, 10.632665],
                [106.6503956, 10.6327236],
                [106.6505489, 10.6327165],
                [106.6507709, 10.632626],
                [106.6508593, 10.6326278],
                [106.6509712, 10.6326863],
                [106.6511101, 10.6327147],
                [106.6513717, 10.6326934],
                [106.6515576, 10.6326349],
                [106.6518012, 10.6324788],
                [106.6520015, 10.6323547],
                [106.6521368, 10.632321],
                [106.6523258, 10.6323484],
                [106.652476, 10.6323463],
                [106.6525897, 10.6322872],
                [106.6527271, 10.6322535],
                [106.6528386, 10.6322999],
                [106.6529545, 10.6324475],
                [106.6531433, 10.6326457],
                [106.6533429, 10.6328524],
                [106.6534867, 10.6333058],
                [106.653455, 10.6338531],
                [106.653191, 10.6338911],
                [106.653176, 10.6341062],
                [106.6531138, 10.634353],
                [106.6531631, 10.6356162],
                [106.6532713, 10.6362563],
                [106.6532849, 10.6365519],
                [106.6531658, 10.6367967],
                [106.6529674, 10.6370875],
                [106.652888, 10.6374244],
                [106.6528951, 10.6377192],
                [106.6529438, 10.6378948],
                [106.6530376, 10.6379781],
                [106.6531821, 10.6380451],
                [106.6533046, 10.6381504],
                [106.6533633, 10.6382858],
                [106.6534092, 10.6386294],
                [106.6534501, 10.6388877],
                [106.6535726, 10.6391109],
                [106.6538048, 10.6393642],
                [106.6539451, 10.6394395],
                [106.6541339, 10.6394695],
                [106.6542735, 10.639454],
                [106.6544062, 10.6393838],
                [106.6544827, 10.639286],
                [106.6545618, 10.6392434],
                [106.6550208, 10.6391054],
                [106.6554242, 10.6389768],
                [106.6557246, 10.6389156],
                [106.6559778, 10.6388903],
                [106.6560551, 10.6388292],
                [106.6560787, 10.6387553],
                [106.6561171, 10.6385568],
                [106.6561417, 10.6384915],
                [106.6561804, 10.6384725],
                [106.6562233, 10.6384862],
                [106.6562758, 10.6385611],
                [106.6565033, 10.638811],
                [106.6565977, 10.6389681],
                [106.6567172, 10.6392813],
                [106.6567668, 10.6396396],
                [106.6568453, 10.6399056],
                [106.6568471, 10.640246],
                [106.6568107, 10.6405952],
                [106.6567357, 10.6408512],
                [106.6565491, 10.6411837],
                [106.6563791, 10.6414268],
                [106.6563055, 10.6416734],
                [106.6562901, 10.6420446],
                [106.656419, 10.6424441],
                [106.6565951, 10.6428855],
                [106.6567458, 10.6432106],
                [106.6569372, 10.6433228],
                [106.6572823, 10.6433466],
                [106.6575534, 10.6433266],
                [106.6577946, 10.6432833],
                [106.6581125, 10.6431966],
                [106.6585198, 10.6430336],
                [106.6592146, 10.6426198],
                [106.6595121, 10.6424907],
                [106.6597739, 10.6424357],
                [106.6599201, 10.642363],
                [106.6601131, 10.642301],
                [106.660317, 10.6421697],
                [106.6605245, 10.6421095],
                [106.6607203, 10.6419874],
                [106.6604625, 10.6413805],
                [106.6601793, 10.6412425],
                [106.6600925, 10.6411397],
                [106.6600236, 10.6409817],
                [106.6600083, 10.6406105],
                [106.6599001, 10.6403547],
                [106.6598983, 10.6402222],
                [106.6599668, 10.6400325],
                [106.6600715, 10.6398587],
                [106.6601906, 10.6398143],
                [106.6603349, 10.639848],
                [106.6605713, 10.6400023],
                [106.660712, 10.6400626],
                [106.6608582, 10.6400786],
                [106.661044, 10.640013],
                [106.6612924, 10.6399094],
                [106.6614972, 10.6398721],
                [106.6617097, 10.6398915],
                [106.6618417, 10.6399034],
                [106.6619236, 10.6398467],
                [106.6621466, 10.6395798],
                [106.6624213, 10.6393546],
                [106.6626216, 10.6392726],
                [106.6627991, 10.639289],
                [106.6629068, 10.6393695],
                [106.662966, 10.6395082],
                [106.6630267, 10.6398139],
                [106.6630835, 10.6399635],
                [106.6631479, 10.6400394],
                [106.663238, 10.6400763],
                [106.6633378, 10.6400752],
                [106.6634354, 10.640051],
                [106.6636069, 10.6399078],
                [106.6638794, 10.6396471],
                [106.6640346, 10.6395496],
                [106.6641789, 10.6395283],
                [106.6644279, 10.6395567],
                [106.6645687, 10.6395301],
                [106.664639, 10.6394361],
                [106.6646697, 10.6392534],
                [106.6646986, 10.6390956],
                [106.6648171, 10.6389618],
                [106.6648975, 10.6389066],
                [106.6649995, 10.6388865],
                [106.6651029, 10.6389154],
                [106.6651909, 10.6390232],
                [106.6652878, 10.639173],
                [106.6653997, 10.6394425],
                [106.6654863, 10.6395631],
                [106.6656054, 10.6396021],
                [106.6657263, 10.6395578],
                [106.665802, 10.6394142],
                [106.6658255, 10.6392475],
                [106.6658093, 10.6390045],
                [106.6657917, 10.6387082],
                [106.6658399, 10.638537],
                [106.6659295, 10.6384574],
                [106.6660405, 10.6384323],
                [106.6661916, 10.6385226],
                [106.6664596, 10.6387884],
                [106.666545, 10.6391372],
                [106.6665171, 10.6393755],
                [106.6664227, 10.6395906],
                [106.6663047, 10.6398374],
                [106.6662831, 10.6399912],
                [106.666324, 10.6401448],
                [106.6665953, 10.6404273],
                [106.667314, 10.6396524],
                [106.6679206, 10.6392361],
                [106.6685368, 10.6391269],
                [106.6691805, 10.63915],
                [106.6696371, 10.6392799],
                [106.6701758, 10.6397009],
                [106.670689, 10.6403867],
                [106.6710948, 10.6410466],
                [106.6715263, 10.6414946],
                [106.6721987, 10.6418617],
                [106.6730046, 10.6421223],
                [106.6732467, 10.6422534],
                [106.6733823, 10.6425176],
                [106.6733874, 10.6434445],
                [106.6735509, 10.6439203],
                [106.6737402, 10.6442371],
                [106.6740096, 10.6444475],
                [106.674601, 10.6447092],
                [106.6750842, 10.6448125],
                [106.6763717, 10.6448586],
                [106.6774446, 10.6449059],
                [106.6785185, 10.645112],
                [106.6792711, 10.6454257],
                [106.6799714, 10.6460046],
                [106.6803778, 10.6467441],
                [106.680703, 10.6473514],
                [106.6812152, 10.6478519],
                [106.6817279, 10.6484318],
                [106.6826975, 10.6491945],
                [106.6841775, 10.6501136],
                [106.6850937, 10.6509296],
                [106.6853915, 10.6514312],
                [106.6855553, 10.65196],
                [106.6857185, 10.6523829],
                [106.68583, 10.6531504],
                [106.6858336, 10.6538123],
                [106.6856284, 10.6555084],
                [106.6853929, 10.6565691],
                [106.6859018, 10.6564604],
                [106.6865713, 10.6563164],
                [106.6869725, 10.6561024],
                [106.6872126, 10.6558707],
                [106.6875585, 10.6553656],
                [106.6877701, 10.6548348],
                [106.6881147, 10.6540914],
                [106.6884597, 10.6534274],
                [106.6889388, 10.6527628],
                [106.6893389, 10.6523898],
                [106.6899253, 10.6517245],
                [106.6901917, 10.6514053],
                [106.6903773, 10.6510071],
                [106.6903753, 10.6506627],
                [106.6903999, 10.6502654],
                [106.6902898, 10.6497364],
                [106.6902333, 10.6492069],
                [106.6903122, 10.6489417],
                [106.6904456, 10.6488086],
                [106.6907933, 10.6486478],
                [106.691437, 10.6486444],
                [106.692162, 10.6488257],
                [106.6928873, 10.6490602],
                [106.6938008, 10.649373],
                [106.6944991, 10.6495547],
                [106.6948478, 10.6495793],
                [106.6953039, 10.6496033],
                [106.6957868, 10.6496537],
                [106.6962707, 10.6498628],
                [106.6965405, 10.6501528],
                [106.6967465, 10.6504042],
                [106.6968121, 10.6507869],
                [106.6969216, 10.6511836],
                [106.6971114, 10.6515533],
                [106.6976775, 10.6520799],
                [106.6987812, 10.652842],
                [106.6995354, 10.653447],
                [106.700074, 10.6538414],
                [106.7003966, 10.653972],
                [106.7006379, 10.6539706],
                [106.7009582, 10.6537041],
                [106.7012628, 10.6534337],
                [106.7014385, 10.6532777],
                [106.7017587, 10.6529847],
                [106.7020533, 10.6529036],
                [106.702456, 10.6529809],
                [106.703208, 10.6531888],
                [106.7035568, 10.6532133],
                [106.7037444, 10.6531858],
                [106.7040112, 10.652946],
                [106.704249, 10.6523091],
                [106.7045395, 10.6514865],
                [106.7047792, 10.6511939],
                [106.7051531, 10.650927],
                [106.7055277, 10.6507661],
                [106.7062241, 10.6506034],
                [106.7068657, 10.6502555],
                [106.7073205, 10.6500677],
                [106.7080169, 10.6499051],
                [106.7087409, 10.6499011],
                [106.7093853, 10.65003],
                [106.7103793, 10.6503424],
                [106.7106482, 10.6504733],
                [106.7111849, 10.6505498],
                [106.7117476, 10.6504673],
                [106.7123094, 10.6502259],
                [106.7134586, 10.6495046],
                [106.7148484, 10.6486759],
                [106.7157034, 10.6481152],
                [106.7167182, 10.6473415],
                [106.7174114, 10.6466227],
                [106.7179719, 10.6461694],
                [106.7186937, 10.6457417],
                [106.719363, 10.6455527],
                [106.719953, 10.6455759],
                [106.7203293, 10.6457327],
                [106.720894, 10.6459945],
                [106.721565, 10.6460967],
                [106.7220206, 10.6460412],
                [106.7224484, 10.6458269],
                [106.722928, 10.6452683],
                [106.7231644, 10.644393],
                [106.7232934, 10.6435205],
                [106.7233263, 10.6432096],
                [106.7234404, 10.6423387],
                [106.7235546, 10.6417238],
                [106.7237035, 10.6412777],
                [106.7240827, 10.6407597],
                [106.7246701, 10.6403063],
                [106.7251494, 10.639721],
                [106.7253875, 10.6391371],
                [106.7255195, 10.6387655],
                [106.725705, 10.6383672],
                [106.7261317, 10.6379676],
                [106.7268535, 10.6375664],
                [106.7278439, 10.6372432],
                [106.7288073, 10.6368937],
                [106.7297702, 10.6364382],
                [106.7301703, 10.6360651],
                [106.7307295, 10.6353735],
                [106.7311004, 10.6345769],
                [106.731205, 10.6340997],
                [106.7311956, 10.633955],
                [106.7313606, 10.6333339],
                [106.7313075, 10.6333284],
                [106.7312834, 10.6333113],
                [106.7312551, 10.6332929],
                [106.7312317, 10.6332859],
                [106.7311871, 10.6332812],
                [106.7311543, 10.6332859],
                [106.7311404, 10.6332916],
                [106.7311483, 10.6332186],
                [106.7311764, 10.6330838],
                [106.7312185, 10.6329492],
                [106.7312607, 10.6328424],
                [106.7313272, 10.6327156],
                [106.7323673, 10.6309683],
                [106.7325265, 10.6306628],
                [106.7326848, 10.6301984],
                [106.7328161, 10.6297077],
                [106.7330147, 10.6292697],
                [106.7331876, 10.6290304],
                [106.7332404, 10.6288844],
                [106.7331861, 10.6287524],
                [106.7331747, 10.6287402],
                [106.7329163, 10.6284625],
                [106.7324987, 10.6281073],
                [106.7322158, 10.6278837],
                [106.7319335, 10.627753],
                [106.7314375, 10.6277556],
                [106.7311969, 10.6278893],
                [106.7307431, 10.6282494],
                [106.7303291, 10.628543],
                [106.730075, 10.6286636],
                [106.729767, 10.6287315],
                [106.7292776, 10.6285698],
                [106.7290657, 10.6282855],
                [106.7289655, 10.6279787],
                [106.7288736, 10.6275549],
                [106.7288088, 10.627184],
                [106.7286526, 10.62646],
                [106.7283315, 10.6250651],
                [106.7283024, 10.6246674],
                [106.7283726, 10.6244284],
                [106.7287376, 10.6240728],
                [106.7289965, 10.6239653],
                [106.7294523, 10.6238567],
                [106.7299621, 10.6237919],
                [106.730508, 10.6237802],
                [106.7310012, 10.6237948],
                [106.7312096, 10.6237759],
                [106.731399, 10.6237009],
                [106.7315316, 10.6235697],
                [106.7316617, 10.6233822],
                [106.7317523, 10.6231975],
                [106.7317917, 10.6229457],
                [106.7317806, 10.6227848],
                [106.7317184, 10.6225837],
                [106.731533, 10.6222426],
                [106.7311839, 10.6217915],
                [106.7307735, 10.6213105],
                [106.730466, 10.6209902],
                [106.7302816, 10.62082],
                [106.7300869, 10.6206198],
                [106.729943, 10.6204193],
                [106.7298501, 10.6202085],
                [106.7297979, 10.6199873],
                [106.7297966, 10.6197559],
                [106.7298458, 10.6194335],
                [106.7299152, 10.619111],
                [106.7299435, 10.618688],
                [106.7299609, 10.6181645],
                [106.7299495, 10.617933],
                [106.729887, 10.6177019],
                [106.729692, 10.6174613],
                [106.7295175, 10.6172409],
                [106.7294045, 10.6170906],
                [106.7293116, 10.6168797],
                [106.7292082, 10.6165984],
                [106.7290643, 10.6163878],
                [106.7289107, 10.6162477],
                [106.7287575, 10.6162083],
                [106.7286354, 10.6162391],
                [106.728463, 10.6163811],
                [106.7283008, 10.616543],
                [106.7281386, 10.6166848],
                [106.7279559, 10.6168268],
                [106.7278847, 10.6168472],
                [106.7277826, 10.6168076],
                [106.72768, 10.6166874],
                [106.7275976, 10.6165368],
                [106.727556, 10.6163861],
                [106.7275447, 10.6161848],
                [106.7275737, 10.6159128],
                [106.7276029, 10.6156711],
                [106.7276931, 10.6153988],
                [106.7278542, 10.6150456],
                [106.7280254, 10.6146723],
                [106.7281761, 10.614289],
                [106.7283167, 10.6139157],
                [106.7284463, 10.6134218],
                [106.7285665, 10.6130286],
                [106.7288489, 10.6124936],
                [106.7291316, 10.6120491],
                [106.7292736, 10.6119074],
                [106.7294059, 10.6118764],
                [106.7295284, 10.6119161],
                [106.7297738, 10.6120657],
                [106.7301932, 10.6123453],
                [106.7303975, 10.6124347],
                [106.7305913, 10.6124538],
                [106.7307645, 10.6124428],
                [106.7310392, 10.6123506],
                [106.7312832, 10.6122586],
                [106.731446, 10.6122075],
                [106.7316087, 10.6121361],
                [106.7316897, 10.6120552],
                [106.7317399, 10.611924],
                [106.7317495, 10.6118133],
                [106.7318297, 10.6115813],
                [106.7319716, 10.6114295],
                [106.7321739, 10.6111566],
                [106.7323958, 10.6107527],
                [106.7325973, 10.6103289],
                [106.7327945, 10.6099603],
                [106.7320783, 10.6093569],
                [106.7317408, 10.6091035],
                [106.7316046, 10.6090276],
                [106.7314036, 10.6089201],
                [106.7312547, 10.6088699],
                [106.7310865, 10.6088389],
                [106.730886, 10.6088273],
                [106.7306212, 10.6088543],
                [106.7303178, 10.6089134],
                [106.730079, 10.6089657],
                [106.7295953, 10.6091472],
                [106.7292341, 10.6092769],
                [106.7290111, 10.6093886],
                [106.7288575, 10.6091992],
                [106.728702, 10.6090824],
                [106.7285229, 10.6090246],
                [106.7283681, 10.6090137],
                [106.7282967, 10.6090376],
                [106.7282019, 10.6091087],
                [106.7280715, 10.6092154],
                [106.7278345, 10.6094401],
                [106.7272896, 10.6099844],
                [106.7271596, 10.6101497],
                [106.7269709, 10.6104919],
                [106.7269009, 10.6107512],
                [106.7267594, 10.610999],
                [106.7266884, 10.6111384],
                [106.7265892, 10.6112651],
                [106.7264952, 10.6113314],
                [106.7264066, 10.6113703],
                [106.72629, 10.6113874],
                [106.7260956, 10.6113774],
                [106.7258677, 10.6113404],
                [106.7256121, 10.6113033],
                [106.7254342, 10.6112879],
                [106.7252843, 10.6112996],
                [106.7251402, 10.6113499],
                [106.7249408, 10.6114332],
                [106.7246584, 10.6115773],
                [106.7244481, 10.6116992],
                [106.724293, 10.6117878],
                [106.7240897, 10.6119105],
                [106.7239525, 10.6119298],
                [106.7238446, 10.6118696],
                [106.7236747, 10.6117188],
                [106.7234887, 10.6114163],
                [106.7232717, 10.6110684],
                [106.7231015, 10.610857],
                [106.7229147, 10.610433],
                [106.7228198, 10.6099631],
                [106.7226624, 10.609281],
                [106.7225217, 10.6088417],
                [106.7223657, 10.6084176],
                [106.7223489, 10.6081749],
                [106.7223464, 10.6077045],
                [106.7223305, 10.607567],
                [106.7222246, 10.607188],
                [106.722, 10.606911],
                [106.7217753, 10.6067669],
                [106.7214946, 10.6066895],
                [106.7211577, 10.6066343],
                [106.7205852, 10.6066457],
                [106.7199565, 10.6066129],
                [106.7195972, 10.6065134],
                [106.7192828, 10.6063805],
                [106.7191143, 10.6062586],
                [106.7190132, 10.606159],
                [106.7189999, 10.6061174],
                [106.7189372, 10.6059224],
                [106.718954, 10.6050714],
                [106.7191153, 10.6040818],
                [106.7195144, 10.6029732],
                [106.7203887, 10.6016266],
                [106.7210282, 10.6009169],
                [106.7217392, 10.6002068],
                [106.7218323, 10.5998061],
                [106.7217826, 10.5994298],
                [106.7214215, 10.5987961],
                [106.7208222, 10.5981637],
                [106.7203649, 10.5973659],
                [106.7202664, 10.5967779],
                [106.7202879, 10.5963776],
                [106.7203811, 10.5960004],
                [106.7206412, 10.5956223],
                [106.7211868, 10.5951721],
                [106.7220182, 10.5946732],
                [106.7228727, 10.5940564],
                [106.72337, 10.5934888],
                [106.7235586, 10.5931111],
                [106.7235787, 10.5924518],
                [106.723575, 10.5917802],
                [106.7235735, 10.5915103],
                [106.7235966, 10.5912893],
                [106.7236404, 10.5908712],
                [106.7236594, 10.5907518],
                [106.7236587, 10.5906319],
                [106.7236507, 10.5904909],
                [106.7236066, 10.5902513],
                [106.7235193, 10.5899626],
                [106.7233817, 10.5896318],
                [106.7233027, 10.5894048],
                [106.7232472, 10.5892256],
                [106.7232096, 10.5890104],
                [106.7231995, 10.58884],
                [106.7232074, 10.5886065],
                [106.7232157, 10.5884629],
                [106.7232422, 10.5883371],
                [106.723314, 10.5881751],
                [106.723413, 10.588004],
                [106.7235303, 10.5878598],
                [106.7236477, 10.5877335],
                [106.7238196, 10.587589],
                [106.7239278, 10.5874627],
                [106.7240362, 10.5873275],
                [106.7241082, 10.5872194],
                [106.7241574, 10.5871275],
                [106.7241711, 10.5871023],
                [106.724207, 10.5870034],
                [106.7242604, 10.5868236],
                [106.7243495, 10.586509],
                [106.72443, 10.5862842],
                [106.724538, 10.586086],
                [106.7246368, 10.5858881],
                [106.7247541, 10.5857438],
                [106.7249168, 10.5855904],
                [106.725134, 10.5854276],
                [106.7253151, 10.5853189],
                [106.7254419, 10.5852554],
                [106.7256144, 10.5852275],
                [106.7258143, 10.5852264],
                [106.7259689, 10.5852436],
                [106.7261873, 10.5853141],
                [106.7263877, 10.5854118],
                [106.7265699, 10.5854826],
                [106.7267156, 10.5855536],
                [106.7268522, 10.5855887],
                [106.7269521, 10.5855882],
                [106.7270246, 10.5855519],
                [106.7270696, 10.5854799],
                [106.7270782, 10.58539],
                [106.7270593, 10.5852644],
                [106.7270041, 10.5851391],
                [106.7269308, 10.5850319],
                [106.7267846, 10.58488],
                [106.7266931, 10.5847639],
                [106.726656, 10.5846473],
                [106.726646, 10.584468],
                [106.7266632, 10.5842973],
                [106.7267166, 10.5840995],
                [106.7267792, 10.5839197],
                [106.7268302, 10.5837974],
                [106.7270172, 10.5835392],
                [106.7271508, 10.5833524],
                [106.727257, 10.5831036],
                [106.7273638, 10.5829435],
                [106.727408, 10.582828],
                [106.7274255, 10.5827392],
                [106.7274335, 10.5825797],
                [106.7274594, 10.5823845],
                [106.7275125, 10.5822512],
                [106.7277083, 10.5819665],
                [106.7279667, 10.5816371],
                [106.7280732, 10.5814327],
                [106.7282329, 10.5811127],
                [106.7283211, 10.5808463],
                [106.7283828, 10.580651],
                [106.7284535, 10.5804378],
                [106.7285244, 10.5802957],
                [106.7286221, 10.5801267],
                [106.7287025, 10.5800465],
                [106.7288636, 10.5799835],
                [106.729016, 10.579965],
                [106.7292671, 10.5799281],
                [106.7295002, 10.5798914],
                [106.7297511, 10.5798102],
                [106.7300196, 10.5797113],
                [106.7301901, 10.5797103],
                [106.7302067, 10.5797163],
                [106.7305314, 10.5797616],
                [106.7307286, 10.5797162],
                [106.7309708, 10.5797061],
                [106.7312581, 10.5797488],
                [106.7317615, 10.5798967],
                [106.7320312, 10.5799839],
                [106.7322921, 10.5801153],
                [106.7324901, 10.5802206],
                [106.7325891, 10.5802643],
                [106.7326968, 10.5802726],
                [106.7327506, 10.5802724],
                [106.7327542, 10.5802693],
                [106.7328242, 10.5803583],
                [106.7329231, 10.580479],
                [106.7330303, 10.58064],
                [106.7331131, 10.5808012],
                [106.7331959, 10.5809706],
                [106.7333031, 10.5811074],
                [106.7334431, 10.5812682],
                [106.7335992, 10.5813887],
                [106.733788, 10.5815089],
                [106.7340342, 10.5816288],
                [106.7343052, 10.581797],
                [106.7345765, 10.5820218],
                [106.7347, 10.5821665],
                [106.734799, 10.5823035],
                [106.7348405, 10.5824084],
                [106.7349072, 10.58261],
                [106.7349079, 10.5827555],
                [106.7348761, 10.5829173],
                [106.734771, 10.5831281],
                [106.7346902, 10.5833225],
                [106.7346503, 10.5835005],
                [106.7346509, 10.5836218],
                [106.7346682, 10.5837753],
                [106.7347929, 10.5841141],
                [106.734851, 10.5842674],
                [106.7348683, 10.5844208],
                [106.7348608, 10.5845582],
                [106.7348533, 10.5846796],
                [106.7348055, 10.5849142],
                [106.7347516, 10.5852228],
                [106.7348498, 10.5854283],
                [106.7350753, 10.5858103],
                [106.7352949, 10.586141],
                [106.7354335, 10.5862778],
                [106.7355549, 10.586369],
                [106.7357858, 10.5864431],
                [106.7359705, 10.5864771],
                [106.7362014, 10.5864827],
                [106.7365419, 10.5864482],
                [106.736842, 10.5863966],
                [106.7371421, 10.586305],
                [106.7375229, 10.5861508],
                [106.737921, 10.5859565],
                [106.738221, 10.5857566],
                [106.7384979, 10.585511],
                [106.7387142, 10.5852969],
                [106.7387389, 10.5852702],
                [106.7388237, 10.5851568],
                [106.7390244, 10.5848145],
                [106.7392715, 10.5842953],
                [106.7394004, 10.5839063],
                [106.7395159, 10.5832702],
                [106.7395014, 10.5827996],
                [106.7394519, 10.5824704],
                [106.7393666, 10.5821414],
                [106.7391621, 10.5817778],
                [106.7389339, 10.5814495],
                [106.7386703, 10.5811803],
                [106.7382878, 10.5809589],
                [106.7378819, 10.5807846],
                [106.7375832, 10.5806333],
                [106.7373797, 10.5804697],
                [106.7370201, 10.5800598],
                [106.7366368, 10.5796971],
                [106.736135, 10.5794292],
                [106.7355738, 10.579197],
                [106.7352749, 10.5789986],
                [106.7350589, 10.5787291],
                [106.7350098, 10.5784705],
                [106.7350676, 10.5781525],
                [106.7352444, 10.5778103],
                [106.7355638, 10.5774085],
                [106.7360268, 10.5771353],
                [106.7363123, 10.577063],
                [106.7367652, 10.5770841],
                [106.7369921, 10.5772005],
                [106.737196, 10.5774465],
                [106.7374243, 10.5777982],
                [106.7376167, 10.578115],
                [106.7378203, 10.5783021],
                [106.7380474, 10.5784538],
                [106.7386442, 10.5786624],
                [106.7394196, 10.578858],
                [106.7401355, 10.5790542],
                [106.7404136, 10.5790776],
                [106.7406599, 10.5790984],
                [106.7412316, 10.5790717],
                [106.7417552, 10.5789864],
                [106.7419636, 10.5789013],
                [106.7421101, 10.5788364],
                [106.7422456, 10.5787753],
                [106.742372, 10.578711],
                [106.7424982, 10.5786407],
                [106.7425599, 10.5786011],
                [106.7431094, 10.5782964],
                [106.7447004, 10.5770623],
                [106.7457018, 10.5762762],
                [106.7457147, 10.5762661],
                [106.745422, 10.5759802],
                [106.7445393, 10.5742958],
                [106.7438371, 10.5723589],
                [106.7431745, 10.5709969],
                [106.7425864, 10.569343],
                [106.7421276, 10.5669712],
                [106.7418966, 10.5639214],
                [106.741955, 10.5628074],
                [106.7420092, 10.561775],
                [106.7423493, 10.558386],
                [106.7430324, 10.5551096],
                [106.7430803, 10.5549196],
                [106.7433503, 10.5536451],
                [106.7434274, 10.553281],
                [106.747241, 10.5280244],
                [106.7468118, 10.5153662],
                [106.7455304, 10.5033889],
                [106.7452324, 10.4978311],
                [106.7503853, 10.4784896],
                [106.7512321, 10.475321],
                [106.7536353, 10.4673029],
                [106.7562254, 10.4609118],
                [106.7583561, 10.4576386],
                [106.7611567, 10.4537878],
                [106.7714627, 10.4409249],
                [106.7775822, 10.4343757],
                [106.7925066, 10.4184025],
                [106.7946597, 10.4144888],
                [106.810131, 10.3863656],
                [106.8509964, 10.3271059],
                [106.9933717, 10.1399458],
                [107.0085458, 10.4568771],
                [107.0065458, 10.4671571],
                [106.9965458, 10.4910871],
                [106.9979458, 10.5004771],
                [106.9983458, 10.5027771],
                [106.9984031, 10.5044165],
                [106.9984889, 10.5051339],
                [106.9987464, 10.5057668],
                [106.9991648, 10.5062679],
                [106.999712, 10.5067162],
                [107.0022869, 10.5080454],
                [107.0058274, 10.5098492],
                [107.0076882, 10.5114154],
                [107.0088111, 10.5129079],
                [107.0105551, 10.5158971],
                [107.0114386, 10.5175498],
                [107.0119321, 10.5186468],
                [107.012328, 10.5197312],
                [107.0125544, 10.5209675],
                [107.0126188, 10.5223388],
                [107.0154726, 10.5250392],
                [107.0240235, 10.5330874],
                [107.0251983, 10.5344692],
                [107.026062, 10.5357877],
                [107.0267379, 10.5370324],
                [107.0272314, 10.5382138],
                [107.0275803, 10.5394019],
                [107.0276712, 10.5407773],
                [107.0276167, 10.5416883],
                [107.0273441, 10.5425457],
                [107.0271443, 10.5434031],
                [107.0267082, 10.5444213],
                [107.0254909, 10.5454215],
                [107.0250344, 10.5455893],
                [107.0236921, 10.5460825],
                [107.0200401, 10.5469398],
                [107.0184049, 10.5472971],
                [107.0172057, 10.5476007],
                [107.0160429, 10.5480294],
                [107.0149164, 10.5484938],
                [107.0134265, 10.5494584],
                [107.0122637, 10.5508338],
                [107.0117913, 10.5521556],
                [107.0114824, 10.5535309],
                [107.0113007, 10.5546741],
                [107.0112462, 10.5568353],
                [107.011864, 10.5589073],
                [107.0126998, 10.5609792],
                [107.0132812, 10.5621581],
                [107.0143531, 10.5639263],
                [107.0154978, 10.5654624],
                [107.0176963, 10.5682309],
                [107.0206579, 10.5719102],
                [107.0246006, 10.5785186],
                [107.0259087, 10.5801975],
                [107.0266719, 10.5817513],
                [107.0269081, 10.5829301],
                [107.0270584, 10.5848303],
                [107.0271988, 10.5859663],
                [107.0271732, 10.5871076],
                [107.0268288, 10.5884412],
                [107.0259731, 10.5898876],
                [107.0253458, 10.5907771],
                [107.0233458, 10.5942071],
                [107.0223458, 10.5959671],
                [107.0220458, 10.5967371],
                [107.0217458, 10.5976171],
                [107.0213458, 10.5989571],
                [107.0209458, 10.6004171],
                [107.0205458, 10.6015371],
                [107.0201458, 10.6023171],
                [107.0191458, 10.6038971],
                [107.0179458, 10.6052671],
                [107.0177179, 10.6055087],
                [107.0164458, 10.6068571],
                [107.0140281, 10.6091322],
                [107.0131742, 10.6097573],
                [107.0114821, 10.6108825],
                [107.0082867, 10.6130075],
                [107.0071184, 10.610515],
                [107.0070344, 10.6103618],
                [107.0069439, 10.610225],
                [107.0068303, 10.6101227],
                [107.0067344, 10.6100437],
                [107.0064605, 10.6099432],
                [107.0061942, 10.6098732],
                [107.0059494, 10.6098554],
                [107.0057363, 10.6098816],
                [107.0053748, 10.6099712],
                [107.0048659, 10.6102206],
                [107.0036269, 10.6108498],
                [107.00329, 10.6109797],
                [107.0030034, 10.6110529],
                [107.0027911, 10.6110412],
                [107.0025715, 10.6110157],
                [107.0023636, 10.6109303],
                [107.0022063, 10.6108245],
                [107.0021329, 10.610686],
                [107.0021126, 10.61056],
                [107.002102, 10.6104337],
                [107.0021205, 10.6102879],
                [107.0021781, 10.6101225],
                [107.0024421, 10.609468],
                [107.0025121, 10.6091597],
                [107.0025232, 10.6089839],
                [107.0024645, 10.6087938],
                [107.0023288, 10.6085962],
                [107.0021871, 10.6084907],
                [107.0016811, 10.6081929],
                [107.0013074, 10.6079428],
                [107.0010416, 10.6077113],
                [107.0008936, 10.6075277],
                [107.0007255, 10.6072568],
                [107.000607, 10.6071119],
                [107.0005059, 10.6070001],
                [107.0004197, 10.6069719],
                [107.0002961, 10.6069837],
                [106.9999845, 10.6071528],
                [106.9998081, 10.607281],
                [106.9993417, 10.6075647],
                [106.9990204, 10.6077064],
                [106.998644, 10.6078117],
                [106.9981438, 10.6077893],
                [106.9976256, 10.6075983],
                [106.9972958, 10.6073604],
                [106.9970537, 10.607054],
                [106.9967115, 10.606321],
                [106.996499, 10.6058795],
                [106.9962237, 10.6056106],
                [106.9959167, 10.6054216],
                [106.995586, 10.6053463],
                [106.9951279, 10.6053629],
                [106.9941641, 10.6055919],
                [106.9929152, 10.6057225],
                [106.9920265, 10.605717],
                [106.9913783, 10.6055535],
                [106.9913481, 10.605546],
                [106.9907667, 10.6051994],
                [106.9903366, 10.6046794],
                [106.9902792, 10.6044916],
                [106.9901955, 10.6041376],
                [106.9903196, 10.6032076],
                [106.9905114, 10.6026746],
                [106.9906869, 10.6021578],
                [106.9907499, 10.601819],
                [106.9907308, 10.601384],
                [106.9906464, 10.6009172],
                [106.9905627, 10.6005472],
                [106.9903814, 10.6002099],
                [106.9901027, 10.5998733],
                [106.9895668, 10.5993529],
                [106.9883231, 10.5982489],
                [106.9871145, 10.5971998],
                [106.986606, 10.5965585],
                [106.9863267, 10.5961251],
                [106.9862101, 10.5957231],
                [106.9862208, 10.5948907],
                [106.986361, 10.5939229],
                [106.9868485, 10.5914446],
                [106.9870997, 10.5900411],
                [106.9871892, 10.5887602],
                [106.987199, 10.587777],
                [106.9870643, 10.5870689],
                [106.9863868, 10.5850511],
                [106.9858388, 10.5833465],
                [106.9850002, 10.5814723],
                [106.9840947, 10.5798181],
                [106.9838641, 10.5793685],
                [106.9837336, 10.5792092],
                [106.9837328, 10.5792082],
                [106.9835542, 10.5792577],
                [106.9832467, 10.5795013],
                [106.9826966, 10.5799883],
                [106.9819033, 10.5806056],
                [106.9804859, 10.5814791],
                [106.9796263, 10.5819196],
                [106.9789932, 10.5821331],
                [106.9784405, 10.582201],
                [106.9778224, 10.5822371],
                [106.9773504, 10.5822079],
                [106.9766989, 10.5820991],
                [106.976292, 10.5820695],
                [106.9757061, 10.5820409],
                [106.9752676, 10.5821565],
                [106.9749271, 10.5823359],
                [106.9746033, 10.5825957],
                [106.973349, 10.5839155],
                [106.9720572, 10.5854544],
                [106.9702223, 10.5877144],
                [106.9694637, 10.5886699],
                [106.9687519, 10.5893028],
                [106.9680718, 10.5898066],
                [106.9676343, 10.5900994],
                [106.9666688, 10.5905204],
                [106.9658728, 10.5907187],
                [106.9650763, 10.5908526],
                [106.9643287, 10.5909701],
                [106.9636957, 10.5912158],
                [106.9630801, 10.5916386],
                [106.9620508, 10.5925251],
                [106.9611932, 10.5932718],
                [106.9605119, 10.5935822],
                [106.9597807, 10.5937479],
                [106.9591627, 10.5938001],
                [106.95791, 10.593824],
                [106.9553752, 10.5942063],
                [106.9537565, 10.5946652],
                [106.9498601, 10.596027],
                [106.9484245, 10.5966608],
                [106.9473843, 10.597156],
                [106.9466067, 10.5973811],
                [106.9460114, 10.5973527],
                [106.9449192, 10.5970373],
                [106.9438925, 10.5967857],
                [106.9427979, 10.5966517],
                [106.9421773, 10.5966184],
                [106.9413844, 10.5967216],
                [106.9408675, 10.5968927],
                [106.9405574, 10.5970635],
                [106.9400409, 10.5975069],
                [106.9394901, 10.5981207],
                [106.939077, 10.5986322],
                [106.9386019, 10.5993595],
                [106.9378418, 10.5999071],
                [106.9371973, 10.6001282],
                [106.936433, 10.6002134],
                [106.9358959, 10.6001845],
                [106.9355217, 10.600203],
                [106.9351997, 10.6003821],
                [106.9346558, 10.6006845],
                [106.9333229, 10.6013987],
                [106.9321515, 10.6019793],
                [106.9312713, 10.6022815],
                [106.9294287, 10.6027664],
                [106.9280846, 10.6031088],
                [106.9269131, 10.6036894],
                [106.9259143, 10.6044741],
                [106.9247097, 10.6062159],
                [106.923535, 10.6076838],
                [106.9225992, 10.6089466],
                [106.9220334, 10.6095462],
                [106.9212549, 10.60997],
                [106.9205073, 10.6101196],
                [106.9194422, 10.6101745],
                [106.9184, 10.6100519],
                [106.9175522, 10.6097993],
                [106.916638, 10.6093375],
                [106.9157729, 10.6088916],
                [106.9149564, 10.6084453],
                [106.9137821, 10.6080174],
                [106.9129738, 10.6078089],
                [106.9120304, 10.6078791],
                [106.9112835, 10.6081576],
                [106.9107656, 10.6085959],
                [106.9107185, 10.6086668],
                [106.9097999, 10.6099664],
                [106.9085223, 10.6127291],
                [106.9056206, 10.6203833],
                [106.9043434, 10.6234336],
                [106.9034546, 10.6246678],
                [106.9024374, 10.6256247],
                [106.9016003, 10.6264049],
                [106.8997058, 10.627974],
                [106.8982476, 10.6290284],
                [106.8980001, 10.6291765],
                [106.8972622, 10.6298742],
                [106.8969032, 10.6308984],
                [106.8962752, 10.6329025],
                [106.8955564, 10.6343724],
                [106.8945218, 10.6354421],
                [106.8934871, 10.6364673],
                [106.8925618, 10.6369195],
                [106.8912743, 10.6373509],
                [106.8901036, 10.6377084],
                [106.8892573, 10.6380091],
                [106.8887879, 10.6382049],
                [106.8876851, 10.6387513],
                [106.8862716, 10.6394565],
                [106.8848701, 10.6407423],
                [106.8844211, 10.6421616],
                [106.8843289, 10.6430485],
                [106.884302, 10.6439673],
                [106.8841603, 10.6447577],
                [106.883969, 10.6454035],
                [106.8835695, 10.6463331],
                [106.8834745, 10.6467688],
                [106.8831685, 10.6472864],
                [106.882845, 10.6476106],
                [106.8819904, 10.6479856],
                [106.8807228, 10.6482995],
                [106.879324, 10.6484368],
                [106.8754274, 10.6484296],
                [106.8742381, 10.6482433],
                [106.8730977, 10.6480568],
                [106.8727718, 10.6479942],
                [106.8722517, 10.6476288],
                [106.8722488, 10.6476268],
                [106.871626, 10.6469214],
                [106.8714977, 10.6467303],
                [106.8712811, 10.6464078],
                [106.8709849, 10.6458779],
                [106.8707222, 10.6448589],
                [106.8703904, 10.6438294],
                [106.8700124, 10.6432194],
                [106.8693094, 10.6427079],
                [106.8687712, 10.6425178],
                [106.8674919, 10.64259],
                [106.8664671, 10.6426928],
                [106.8657017, 10.6426169],
                [106.8646572, 10.6421235],
                [106.8637306, 10.6416352],
                [106.8636807, 10.6415783],
                [106.8633372, 10.6411864],
                [106.8628932, 10.6404155],
                [106.8624474, 10.6392466],
                [106.8618861, 10.6379447],
                [106.8612791, 10.6371425],
                [106.860336, 10.6365542],
                [106.8595541, 10.636446],
                [106.8589205, 10.636627],
                [106.8576729, 10.6370996],
                [106.8556939, 10.6381749],
                [106.8539605, 10.6389954],
                [106.8524998, 10.6394632],
                [106.8513656, 10.639602],
                [106.8499302, 10.6396105],
                [106.8483601, 10.6394217],
                [106.8467889, 10.6390677],
                [106.8445488, 10.6384865],
                [106.8431314, 10.6379857],
                [106.8412837, 10.6367649],
                [106.8410216, 10.6364764],
                [106.840511, 10.6354641],
                [106.8400975, 10.6342653],
                [106.8396639, 10.6322912],
                [106.8392663, 10.6307692],
                [106.838705, 10.6294349],
                [106.8380342, 10.6286041],
                [106.8367084, 10.6275249],
                [106.8366992, 10.6275174],
                [106.8354265, 10.6269608],
                [106.8344154, 10.6266284],
                [106.8336668, 10.6266326],
                [106.8322736, 10.6266887],
                [106.8302733, 10.626942],
                [106.8279845, 10.6273561],
                [106.8262785, 10.6278335],
                [106.8248346, 10.6286154],
                [106.8231005, 10.6298342],
                [106.8192127, 10.6341587],
                [106.8167113, 10.6370661],
                [106.8154857, 10.6385342],
                [106.8132138, 10.6419869],
                [106.8127962, 10.6429403],
                [106.8121898, 10.6448996],
                [106.8117597, 10.6464816],
                [106.8112341, 10.6484346],
                [106.8109029, 10.6496853],
                [106.8098994, 10.6527804],
                [106.8082433, 10.6551022],
                [106.8057998, 10.657218],
                [106.8017263, 10.6600398],
                [106.7969393, 10.662661],
                [106.7944189, 10.6645665],
                [106.7934881, 10.6653708],
                [106.7925166, 10.6662628],
                [106.7893853, 10.6688841],
                [106.7875207, 10.670253],
                [106.7860503, 10.6710477],
                [106.7842303, 10.6719367],
                [106.7793989, 10.6740643],
                [106.7761539, 10.6751854],
                [106.7760775, 10.6752118],
                [106.7743365, 10.6761478],
                [106.7739876, 10.6763353],
                [106.7731778, 10.6769316],
                [106.7724854, 10.6775182],
                [106.7696395, 10.6802772],
                [106.7677139, 10.682144],
                [106.7657553, 10.6840916],
                [106.7625865, 10.6877582],
                [106.7614913, 10.6890194],
                [106.7601793, 10.6902818],
                [106.7601264, 10.6903284],
                [106.7598401, 10.6905808],
                [106.7595858, 10.6908051],
                [106.7586695, 10.6917427],
                [106.7577455, 10.6929042],
                [106.7572016, 10.6936341],
                [106.7564167, 10.6946874],
                [106.7559878, 10.6952629],
                [106.7544018, 10.6975937],
                [106.7537771, 10.6989778],
                [106.7527559, 10.7021122],
                [106.7520953, 10.7043535],
                [106.7519936, 10.7046983],
                [106.751422, 10.7074537],
                [106.7512155, 10.7092477],
                [106.7511678, 10.7103776],
                [106.7512245, 10.7123764],
                [106.7512311, 10.7127242],
                [106.7512531, 10.713473],
                [106.7517273, 10.7166237],
                [106.7519171, 10.7185809],
                [106.752304, 10.7204199],
                [106.7528834, 10.7219417],
                [106.7536556, 10.7234634],
                [106.7550705, 10.7253651],
                [106.764866, 10.7377245],
                [106.7656121, 10.7388033],
                [106.7686561, 10.7431427],
                [106.7697018, 10.7442035],
                [106.7708469, 10.7452907],
                [106.7726629, 10.7466967],
                [106.774151, 10.7476922],
                [106.7773151, 10.7493683],
                [106.7834293, 10.7521969],
                [106.7834782, 10.7522195],
                [106.7853359, 10.7530425],
                [106.788688, 10.7543858],
                [106.790432, 10.7550895],
                [106.791652, 10.7556011],
                [106.7928651, 10.7561097],
                [106.7968881, 10.7577716],
                [106.7991459, 10.7589081],
                [106.7993177, 10.7589946],
                [106.7997835, 10.7592974],
                [106.8003974, 10.7596965],
                [106.8013778, 10.760408],
                [106.8018691, 10.760943],
                [106.8027078, 10.7621394],
                [106.8032183, 10.7628535],
                [106.8034011, 10.7631931],
                [106.8038509, 10.7643648],
                [106.8046622, 10.7670314],
                [106.8052142, 10.7686419],
                [106.8056713, 10.7695715],
                [106.8060649, 10.7703311],
                [106.8066849, 10.7712239],
                [106.807322, 10.7719553],
                [106.8096023, 10.7741724],
                [106.8100933, 10.7746693],
                [106.8107136, 10.775297],
                [106.8128045, 10.7772928],
                [106.8145391, 10.7786541],
                [106.8163172, 10.7797015],
                [106.8179665, 10.7803911],
                [106.8194156, 10.7808846],
                [106.8203384, 10.7810046],
                [106.8209711, 10.7810009],
                [106.8217297, 10.7808709],
                [106.8221987, 10.7807068],
                [106.8222278, 10.7806902],
                [106.8225319, 10.7805166],
                [106.8234677, 10.7798119],
                [106.8243479, 10.7788743],
                [106.8250649, 10.7778482],
                [106.8252961, 10.7772193],
                [106.8256227, 10.7759176],
                [106.8257376, 10.7739807],
                [106.8256022, 10.7725292],
                [106.8253325, 10.7712668],
                [106.8246696, 10.7692807],
                [106.8238146, 10.7668923],
                [106.8231484, 10.7643324],
                [106.8230167, 10.7634726],
                [106.8230374, 10.7624235],
                [106.8231949, 10.7615441],
                [106.8234613, 10.7607717],
                [106.8238554, 10.7601599],
                [106.8242511, 10.7598168],
                [106.8248281, 10.7595803],
                [106.8257854, 10.7594491],
                [106.8266356, 10.7595517],
                [106.8283027, 10.7602232],
                [106.8289198, 10.7606319],
                [106.8293199, 10.761024],
                [106.8296395, 10.7615509],
                [106.8304975, 10.7629443],
                [106.8311907, 10.7639801],
                [106.8313625, 10.7642026],
                [106.8355853, 10.7691959],
                [106.8363335, 10.7699108],
                [106.8377052, 10.7710232],
                [106.8390248, 10.771997],
                [106.8407189, 10.7731639],
                [106.8412183, 10.7734612],
                [106.8416745, 10.7737327],
                [106.8426174, 10.7741932],
                [106.8434153, 10.7745058],
                [106.8450574, 10.7750983],
                [106.8465768, 10.7754044],
                [106.8487105, 10.7754991],
                [106.8501931, 10.7753008],
                [106.8512722, 10.7751564],
                [106.8521205, 10.7749362],
                [106.8530668, 10.7744513],
                [106.8536416, 10.7741461],
                [106.8542618, 10.7735777],
                [106.8584194, 10.7691155],
                [106.8609354, 10.766716],
                [106.8624117, 10.765739],
                [106.8638538, 10.7650669],
                [106.8652882, 10.764637],
                [106.8668961, 10.7644838],
                [106.8682886, 10.7645651],
                [106.8694652, 10.7648269],
                [106.8707164, 10.7654647],
                [106.8717511, 10.7661577],
                [106.8726326, 10.7669322],
                [106.8731978, 10.7676997],
                [106.8738005, 10.7687001],
                [106.874022, 10.7694337],
                [106.8741548, 10.7704548],
                [106.8741142, 10.7711991],
                [106.873868, 10.7722942],
                [106.8733521, 10.7736419],
                [106.8722001, 10.7758989],
                [106.871809, 10.7765179],
                [106.8713587, 10.7772307],
                [106.8704794, 10.7782938],
                [106.8687108, 10.7802497],
                [106.8666452, 10.7823956],
                [106.8654422, 10.7837295],
                [106.8649947, 10.7844494],
                [106.8646648, 10.7851774],
                [106.8643661, 10.7865777],
                [106.8643611, 10.7866344],
                [106.8642654, 10.7884521],
                [106.8643995, 10.7891759],
                [106.8647338, 10.7899654],
                [106.8657355, 10.7912147],
                [106.8685382, 10.7931859],
                [106.875679, 10.7985086],
                [106.8786841, 10.8021248],
                [106.8806878, 10.8048208],
                [106.8815567, 10.8065311],
                [106.8817591, 10.8083737],
                [106.8816459, 10.8100813],
                [106.8815533, 10.8106588],
                [106.8810585, 10.8124723],
                [106.8794978, 10.8158428],
                [106.8781227, 10.81854],
                [106.8771366, 10.8198366],
                [106.8723797, 10.8246828],
                [106.8721138, 10.824932],
                [106.8718532, 10.8251866],
                [106.8715983, 10.8254466],
                [106.871349, 10.8257119],
                [106.8711054, 10.8259823],
                [106.8708442, 10.8262855],
                [106.8705903, 10.8265947],
                [106.8703438, 10.8269096],
                [106.8701048, 10.8272301],
                [106.8692181, 10.8283488],
                [106.8683245, 10.8294623],
                [106.8674243, 10.8305705],
                [106.8665172, 10.8316734],
                [106.8656035, 10.832771],
                [106.8646831, 10.8338632],
                [106.8637561, 10.8349499],
                [106.8627693, 10.8360924],
                [106.8617751, 10.8372288],
                [106.8607737, 10.838359],
                [106.8597649, 10.8394829],
                [106.858749, 10.8406005],
                [106.8577259, 10.8417119],
                [106.8575177, 10.8419335],
                [106.8573153, 10.8421602],
                [106.8571188, 10.8423919],
                [106.8569283, 10.8426284],
                [106.856744, 10.8428695],
                [106.8565537, 10.8431324],
                [106.8563708, 10.8434004],
                [106.8561952, 10.843673],
                [106.8557027, 10.8445337],
                [106.8552183, 10.8453988],
                [106.8547418, 10.8462681],
                [106.8542735, 10.8471417],
                [106.8538133, 10.8480194],
                [106.8533613, 10.8489013],
                [106.8529174, 10.8497871],
                [106.8524818, 10.8506768],
                [106.8520545, 10.8515705],
                [106.8516355, 10.8524679],
                [106.8512249, 10.853369],
                [106.8508067, 10.85431],
                [106.8503976, 10.8552548],
                [106.8499977, 10.8562035],
                [106.8496069, 10.8571557],
                [106.8492254, 10.8581116],
                [106.8488531, 10.859071],
                [106.8484901, 10.8600338],
                [106.8481364, 10.861],
                [106.847792, 10.8619694],
                [106.847457, 10.8629419],
                [106.8471315, 10.8639175],
                [106.8468153, 10.8648961],
                [106.8471247, 10.8653968],
                [106.8474257, 10.8659023],
                [106.8477183, 10.8664126],
                [106.8480023, 10.8669276],
                [106.8482777, 10.867447],
                [106.8485445, 10.8679708],
                [106.8488025, 10.8684988],
                [106.8490517, 10.8690308],
                [106.849292, 10.8695668],
                [106.8495233, 10.8701066],
                [106.8497456, 10.87065],
                [106.8499589, 10.8711969],
                [106.8501528, 10.871719],
                [106.8503385, 10.8722441],
                [106.8505158, 10.8727719],
                [106.8506848, 10.8733023],
                [106.8508455, 10.8738247],
                [106.8510747, 10.8745264],
                [106.8513221, 10.8753108],
                [106.8509472, 10.8757975],
                [106.8505759, 10.8762317],
                [106.8502112, 10.8766712],
                [106.8498531, 10.8771158],
                [106.8495016, 10.8775655],
                [106.8491569, 10.8780203],
                [106.8488189, 10.8784799],
                [106.8484879, 10.8789443],
                [106.8481637, 10.8794134],
                [106.8478466, 10.8798872],
                [106.8475366, 10.8803654],
                [106.8472337, 10.880848],
                [106.8469238, 10.8813588],
                [106.8466219, 10.8818741],
                [106.8463281, 10.882394],
                [106.8460424, 10.8829182],
                [106.845765, 10.8834466],
                [106.8454958, 10.8839792],
                [106.845235, 10.8845157],
                [106.8449825, 10.8850561],
                [106.8447386, 10.8856003],
                [106.8445031, 10.886148],
                [106.8442762, 10.8866992],
                [106.844058, 10.8872538],
                [106.843734, 10.8880122],
                [106.8434185, 10.8887739],
                [106.8431115, 10.889539],
                [106.8428129, 10.8903073],
                [106.8425229, 10.8910788],
                [106.8422415, 10.8918533],
                [106.8419687, 10.8926308],
                [106.8417045, 10.8934112],
                [106.841449, 10.8941943],
                [106.8412022, 10.8949801],
                [106.8409641, 10.8957685],
                [106.8407348, 10.8965594],
                [106.8405143, 10.8973527],
                [106.8403026, 10.8981484],
                [106.8401265, 10.8988461],
                [106.838723, 10.898491],
                [106.8382178, 10.8983633],
                [106.8369689, 10.8983702],
                [106.8357333, 10.8983771],
                [106.83433, 10.8983849],
                [106.8339199, 10.8982586],
                [106.8339115, 10.8979766],
                [106.8335511, 10.8979718],
                [106.8333804, 10.8979598],
                [106.8332706, 10.8979957],
                [106.8331283, 10.8977003],
                [106.8330236, 10.8975904],
                [106.8328268, 10.897503],
                [106.8329169, 10.8972811],
                [106.8328072, 10.8972292],
                [106.8327624, 10.897325],
                [106.8323356, 10.8971813],
                [106.8323112, 10.8971254],
                [106.8323315, 10.8969498],
                [106.832364, 10.896814],
                [106.8321038, 10.8967102],
                [106.831681, 10.8969058],
                [106.8315875, 10.8966623],
                [106.8315225, 10.8967182],
                [106.8312663, 10.8965585],
                [106.8304938, 10.8960567],
                [106.8298537, 10.8949756],
                [106.8290714, 10.8947443],
                [106.8286583, 10.8946705],
                [106.8283579, 10.8945178],
                [106.8279449, 10.8942913],
                [106.8276927, 10.8943123],
                [106.8274513, 10.8944335],
                [106.8270544, 10.8945862],
                [106.8267285, 10.8947951],
                [106.8262569, 10.8944328],
                [106.8258688, 10.8936644],
                [106.8257669, 10.8936433],
                [106.8255076, 10.8936806],
                [106.8250931, 10.8937553],
                [106.825002, 10.8935145],
                [106.8249857, 10.8932738],
                [106.8253466, 10.8932105],
                [106.8253451, 10.893156],
                [106.8252866, 10.8930164],
                [106.8250914, 10.8928682],
                [106.8249443, 10.8928086],
                [106.8247914, 10.8926898],
                [106.8238948, 10.8928774],
                [106.8235777, 10.8925433],
                [106.8235777, 10.8911233],
                [106.8235777, 10.8910133],
                [106.8242309, 10.8891291],
                [106.8241281, 10.8882349],
                [106.8231381, 10.8873269],
                [106.8228345, 10.8872764],
                [106.8223836, 10.8870598],
                [106.822426, 10.8867331],
                [106.8223836, 10.8867505],
                [106.8221712, 10.8861909],
                [106.8220651, 10.8861701],
                [106.8217842, 10.8858868],
                [106.8216418, 10.8858547],
                [106.8213966, 10.8859647],
                [106.8213382, 10.885951],
                [106.8213289, 10.8857607],
                [106.8212093, 10.8856905],
                [106.821123, 10.8857298],
                [106.8210398, 10.885748],
                [106.8209536, 10.8857268],
                [106.8208735, 10.8857207],
                [106.8208581, 10.8856602],
                [106.8208088, 10.8856179],
                [106.8203743, 10.8857419],
                [106.8204329, 10.8859658],
                [106.8203466, 10.8860082],
                [106.8203897, 10.8861534],
                [106.8204082, 10.886344],
                [106.8201024, 10.8862731],
                [106.8200461, 10.8860642],
                [106.8200426, 10.8859425],
                [106.8199859, 10.885873],
                [106.8197842, 10.8858522],
                [106.8197523, 10.8857861],
                [106.8197028, 10.8857062],
                [106.819632, 10.8856784],
                [106.81954, 10.8856888],
                [106.8194444, 10.8857479],
                [106.819402, 10.8857514],
                [106.8194161, 10.8857027],
                [106.8193595, 10.8854073],
                [106.8193736, 10.8852509],
                [106.8191436, 10.8852127],
                [106.8191082, 10.884243],
                [106.8186835, 10.8843403],
                [106.8186906, 10.8841631],
                [106.818412, 10.8841542],
                [106.8181178, 10.8840762],
                [106.8180712, 10.8842222],
                [106.8177208, 10.8840671],
                [106.8174819, 10.8835977],
                [106.8176368, 10.883558],
                [106.8175364, 10.8829152],
                [106.8175924, 10.8828786],
                [106.8178166, 10.8828694],
                [106.8173442, 10.881697],
                [106.8171911, 10.8810661],
                [106.8171052, 10.8810029],
                [106.8168772, 10.8810003],
                [106.8165813, 10.8811058],
                [106.8162917, 10.881227],
                [106.8151507, 10.8797799],
                [106.8152443, 10.8796043],
                [106.814667, 10.8792649],
                [106.8138589, 10.8784244],
                [106.8138355, 10.8784474],
                [106.8136476, 10.878632],
                [106.8136053, 10.878647],
                [106.8135779, 10.8786527],
                [106.8133875, 10.8788285],
                [106.8133317, 10.8788769],
                [106.813208, 10.8789894],
                [106.8131092, 10.8790767],
                [106.8130656, 10.8791123],
                [106.8128195, 10.878789],
                [106.8122256, 10.8780031],
                [106.8105989, 10.8757698],
                [106.8084835, 10.8729171],
                [106.8079928, 10.8735598],
                [106.8078606, 10.873769],
                [106.8074482, 10.8740319],
                [106.8065631, 10.8745165],
                [106.8056049, 10.8749478],
                [106.805259, 10.8750194],
                [106.8052142, 10.8748681],
                [106.8051627, 10.8746461],
                [106.8051187, 10.8744569],
                [106.8050684, 10.8743472],
                [106.8048926, 10.8741112],
                [106.8047477, 10.8739421],
                [106.8045717, 10.873802],
                [106.8043722, 10.8736903],
                [106.8042341, 10.8736374],
                [106.8040945, 10.8736091],
                [106.8034095, 10.8735145],
                [106.8030855, 10.8734979],
                [106.8025701, 10.8735069],
                [106.8020906, 10.8735599],
                [106.8013119, 10.873599],
                [106.8001103, 10.8728265],
                [106.7990718, 10.8721482],
                [106.7989507, 10.872085],
                [106.7988031, 10.8720363],
                [106.7986664, 10.8720074],
                [106.7985452, 10.8720084],
                [106.7982925, 10.8720308],
                [106.7982243, 10.8720439],
                [106.7981744, 10.8720639],
                [106.7981213, 10.8720987],
                [106.7979786, 10.8722325],
                [106.7978832, 10.8723569],
                [106.7978005, 10.872478],
                [106.7977503, 10.8725919],
                [106.7977282, 10.8726699],
                [106.7977151, 10.8727581],
                [106.7977186, 10.8728808],
                [106.7977615, 10.8730394],
                [106.7978258, 10.8732082],
                [106.7958854, 10.8732205],
                [106.7958812, 10.8735609],
                [106.795159, 10.8735589],
                [106.7950543, 10.8735558],
                [106.7949478, 10.8735439],
                [106.7948471, 10.8735238],
                [106.7947482, 10.8734869],
                [106.794665, 10.8734465],
                [106.7945917, 10.8734009],
                [106.794014, 10.8730185],
                [106.7935278, 10.8725871],
                [106.7921713, 10.8725947],
                [106.7921659, 10.8719756],
                [106.7913261, 10.8719659],
                [106.7905629, 10.8719562],
                [106.7897405, 10.871951],
                [106.7889215, 10.8719524],
                [106.7882626, 10.8708321],
                [106.7881332, 10.8705995],
                [106.7880763, 10.8704868],
                [106.7880285, 10.8703542],
                [106.7880259, 10.8703449],
                [106.7879506, 10.8700758],
                [106.7879354, 10.869926],
                [106.7879089, 10.8697119],
                [106.7878668, 10.8693327],
                [106.7878181, 10.8688223],
                [106.7878013, 10.8685988],
                [106.7877896, 10.8684438],
                [106.787776, 10.8682281],
                [106.7877599, 10.8679654],
                [106.7877574, 10.8678855],
                [106.7877491, 10.8678042],
                [106.7877441, 10.8676881],
                [106.7877352, 10.8673454],
                [106.7877317, 10.8672784],
                [106.7877294, 10.8672361],
                [106.7877281, 10.8672119],
                [106.7843628, 10.8673392],
                [106.7836853, 10.8673943],
                [106.7830268, 10.8674882],
                [106.7824434, 10.8676126],
                [106.7816848, 10.867825],
                [106.7803318, 10.8682665],
                [106.7803573, 10.8685759],
                [106.7803166, 10.8686877],
                [106.7802065, 10.8688357],
                [106.7801581, 10.8689592],
                [106.7801412, 10.869251],
                [106.7801744, 10.8694038],
                [106.7802299, 10.8696588],
                [106.7803479, 10.8700016],
                [106.7805384, 10.8705428],
                [106.7806297, 10.8708237],
                [106.7809325, 10.8717341],
                [106.7809946, 10.8719094],
                [106.7811707, 10.8722431],
                [106.7812795, 10.8724331],
                [106.7813588, 10.8726395],
                [106.7816304, 10.873262],
                [106.7816865, 10.8736117],
                [106.7818545, 10.873959],
                [106.7819143, 10.8742614],
                [106.7818633, 10.8744676],
                [106.7818898, 10.8747285],
                [106.7819324, 10.8751403],
                [106.7820045, 10.8755774],
                [106.7820065, 10.8760556],
                [106.7820065, 10.8763956],
                [106.7820065, 10.8767256],
                [106.7821065, 10.8771956],
                [106.7820065, 10.8773856],
                [106.7818065, 10.8777556],
                [106.7815065, 10.8779856],
                [106.7813065, 10.8782456],
                [106.7813065, 10.8785756],
                [106.7812065, 10.8789056],
                [106.7812065, 10.8793556],
                [106.7812065, 10.8797656],
                [106.7811065, 10.8800856],
                [106.7809065, 10.8804256],
                [106.7807065, 10.8808856],
                [106.7806065, 10.8811856],
                [106.7805065, 10.8813356],
                [106.7801065, 10.8817356],
                [106.7796065, 10.8822656],
                [106.7794065, 10.8825156],
                [106.7792065, 10.8828356],
                [106.7787065, 10.8832856],
                [106.7781065, 10.8838656],
                [106.7776065, 10.8843556],
                [106.7773065, 10.8846156],
                [106.7769065, 10.8849856],
                [106.7765065, 10.8855456],
                [106.7761065, 10.8859556],
                [106.7759065, 10.8863156],
                [106.7758065, 10.8867256],
                [106.7756065, 10.8870056],
                [106.7753065, 10.8873156],
                [106.7751065, 10.8875456],
                [106.7749065, 10.8877156],
                [106.7744065, 10.8881856],
                [106.7740065, 10.8885256],
                [106.7736053, 10.8889007],
                [106.7730636, 10.8894143],
                [106.7727065, 10.8897056],
                [106.7723584, 10.890171],
                [106.7721065, 10.8905356],
                [106.7719065, 10.8908056],
                [106.7714849, 10.8912996],
                [106.7712065, 10.8916156],
                [106.7709794, 10.8919128],
                [106.7707906, 10.8922158],
                [106.7706297, 10.8924474],
                [106.7703768, 10.8927882],
                [106.7701485, 10.8931015],
                [106.7698241, 10.8934305],
                [106.7696499, 10.8936455],
                [106.7694708, 10.8938231],
                [106.7692802, 10.8939507],
                [106.7690604, 10.8940165],
                [106.7684504, 10.8940349],
                [106.7684371, 10.8923118],
                [106.7680671, 10.8923533],
                [106.7677341, 10.8924072],
                [106.7672351, 10.892517],
                [106.7673248, 10.8922561],
                [106.7676824, 10.8916442],
                [106.7674971, 10.8915653],
                [106.7672603, 10.8915413],
                [106.7668362, 10.8915634],
                [106.7666661, 10.8910259],
                [106.7664269, 10.8902927],
                [106.7662, 10.88913],
                [106.7660824, 10.8887779],
                [106.7658529, 10.888497],
                [106.7657617, 10.8882626],
                [106.7653966, 10.8878388],
                [106.7652359, 10.8876008],
                [106.7651173, 10.887038],
                [106.7649905, 10.8866611],
                [106.764729, 10.8863698],
                [106.7645532, 10.8858471],
                [106.7645547, 10.8855154],
                [106.764477, 10.8848699],
                [106.7640119, 10.8849425],
                [106.7635609, 10.883864],
                [106.763295, 10.8836668],
                [106.7625908, 10.8831538],
                [106.7622051, 10.882892],
                [106.7618778, 10.882589],
                [106.7610307, 10.8819342],
                [106.7604753, 10.8812944],
                [106.7607924, 10.8807342],
                [106.7612231, 10.8800662],
                [106.7618036, 10.8789986],
                [106.7619415, 10.8787059],
                [106.7614457, 10.8785209],
                [106.7615901, 10.8776235],
                [106.7618957, 10.8755389],
                [106.7616879, 10.8752012],
                [106.7614456, 10.8745023],
                [106.7618149, 10.8743939],
                [106.7613152, 10.8735821],
                [106.7610848, 10.873335],
                [106.760917, 10.873156],
                [106.7608196, 10.8729965],
                [106.7603765, 10.8723815],
                [106.7608589, 10.8720194],
                [106.7613581, 10.87167],
                [106.7616563, 10.8714822],
                [106.7621626, 10.8711245],
                [106.7622139, 10.8710848],
                [106.7622482, 10.8710479],
                [106.7622759, 10.8709957],
                [106.7623164, 10.8708588],
                [106.7623655, 10.8707542],
                [106.7624287, 10.8706615],
                [106.7624925, 10.8705813],
                [106.762939, 10.8701099],
                [106.7630409, 10.870043],
                [106.7632849, 10.8699203],
                [106.7632946, 10.8695952],
                [106.7632764, 10.8693576],
                [106.7632314, 10.8691565],
                [106.7631743, 10.8689733],
                [106.7630843, 10.8687709],
                [106.7624163, 10.8677711],
                [106.7623535, 10.8676572],
                [106.7623051, 10.867545],
                [106.7622687, 10.8674081],
                [106.7622395, 10.8672623],
                [106.7612086, 10.8672594],
                [106.7610992, 10.8672488],
                [106.7609951, 10.8672298],
                [106.7606083, 10.8671059],
                [106.7603199, 10.8670025],
                [106.7601483, 10.866958],
                [106.7597025, 10.8668882],
                [106.7595389, 10.8668755],
                [106.7594118, 10.8668803],
                [106.7592782, 10.8668992],
                [106.7591425, 10.8669287],
                [106.7585872, 10.8670709],
                [106.7583481, 10.8671125],
                [106.7580929, 10.8671225],
                [106.757721, 10.8670698],
                [106.7576214, 10.8670724],
                [106.7575009, 10.8670868],
                [106.7561965, 10.866445],
                [106.7560405, 10.8663954],
                [106.7558844, 10.8663706],
                [106.7557346, 10.8663688],
                [106.7555758, 10.8663785],
                [106.7551951, 10.8664113],
                [106.7550399, 10.8664175],
                [106.7549172, 10.8664007],
                [106.7546015, 10.8662775],
                [106.7544616, 10.866196],
                [106.75397, 10.8658993],
                [106.7535757, 10.8657096],
                [106.7536424, 10.8654394],
                [106.7536704, 10.8653579],
                [106.7537465, 10.8651952],
                [106.753526, 10.8650203],
                [106.753433, 10.8649023],
                [106.7533748, 10.8648164],
                [106.7533102, 10.8647579],
                [106.7532304, 10.8647105],
                [106.7529403, 10.8645617],
                [106.7528282, 10.8644404],
                [106.7526449, 10.8639954],
                [106.7524971, 10.863786],
                [106.7522788, 10.8636497],
                [106.7520051, 10.8635203],
                [106.7517472, 10.8634587],
                [106.7516204, 10.8634757],
                [106.7515923, 10.8637202],
                [106.7515679, 10.8638639],
                [106.7514785, 10.8640595],
                [106.7512102, 10.8644268],
                [106.750521, 10.8640428],
                [106.7502467, 10.8644149],
                [106.7500353, 10.8642951],
                [106.7499, 10.86444],
                [106.7494, 10.86523],
                [106.7491059, 10.8658226],
                [106.748625, 10.8656401],
                [106.7481, 10.86673],
                [106.7479731, 10.867279],
                [106.7478575, 10.8683813],
                [106.7478, 10.86893],
                [106.7478732, 10.8691666],
                [106.7479092, 10.8692827],
                [106.7473067, 10.8694845],
                [106.7471245, 10.8698075],
                [106.7459912, 10.8717037],
                [106.7470569, 10.8720434],
                [106.7464238, 10.873882],
                [106.7496652, 10.874944],
                [106.7507928, 10.8753258],
                [106.7513234, 10.8754996],
                [106.7512859, 10.8756528],
                [106.7511604, 10.875901],
                [106.7508767, 10.8763745],
                [106.7505469, 10.8769702],
                [106.750346, 10.8772876],
                [106.7493258, 10.8767777],
                [106.7483694, 10.8781803],
                [106.7474536, 10.8776133],
                [106.7471082, 10.8774339],
                [106.7454818, 10.8791418],
                [106.7446482, 10.8800647],
                [106.7456581, 10.8807856],
                [106.7449812, 10.8816265],
                [106.7442251, 10.8810541],
                [106.7437957, 10.8807291],
                [106.7437697, 10.8807094],
                [106.7437273, 10.8807676],
                [106.7436831, 10.8808177],
                [106.7436254, 10.880873],
                [106.7435357, 10.8809347],
                [106.743506, 10.8808322],
                [106.7434658, 10.8807358],
                [106.7434309, 10.8806676],
                [106.743384, 10.8805885],
                [106.7432807, 10.8804482],
                [106.7432504, 10.8804619],
                [106.7431145, 10.8805237],
                [106.7429746, 10.8805887],
                [106.7427716, 10.8806831],
                [106.7425146, 10.8808025],
                [106.7424711, 10.8808228],
                [106.7423603, 10.8808742],
                [106.7422829, 10.8809102],
                [106.742149, 10.8809725],
                [106.741866, 10.881104],
                [106.7413558, 10.8813411],
                [106.7412387, 10.8813955],
                [106.7411417, 10.8814406],
                [106.7410603, 10.8813614],
                [106.7410393, 10.881341],
                [106.7409915, 10.8812944],
                [106.7407969, 10.8811049],
                [106.7406275, 10.88094],
                [106.7405114, 10.8808269],
                [106.7404524, 10.8807913],
                [106.7403898, 10.8807626],
                [106.7403242, 10.880741],
                [106.7402565, 10.8807268],
                [106.740195, 10.8807206],
                [106.7401143, 10.8807285],
                [106.740043, 10.8807507],
                [106.7399561, 10.8807887],
                [106.7398917, 10.8808234],
                [106.7398299, 10.8808626],
                [106.7397711, 10.8809059],
                [106.7397474, 10.8809163],
                [106.7397217, 10.8809199],
                [106.739696, 10.8809165],
                [106.7396693, 10.8809044],
                [106.7396477, 10.8808849],
                [106.7396329, 10.8808625],
                [106.7394036, 10.8804792],
                [106.7390013, 10.8799274],
                [106.7384421, 10.8793914],
                [106.7380559, 10.8797944],
                [106.7366584, 10.8806],
                [106.7360653, 10.8796048],
                [106.7353749, 10.8802261],
                [106.7352481, 10.8802377],
                [106.7341768, 10.8805112],
                [106.7350031, 10.8816853],
                [106.7368171, 10.8847474],
                [106.7367133, 10.8847699],
                [106.7366588, 10.8847818],
                [106.7365105, 10.8848141],
                [106.7314616, 10.8859131],
                [106.7315636, 10.8866832],
                [106.7315876, 10.8868297],
                [106.7316293, 10.8870085],
                [106.7316848, 10.8871956],
                [106.7317486, 10.8873802],
                [106.7318224, 10.8875664],
                [106.7319047, 10.8877491],
                [106.7320114, 10.8879965],
                [106.7321289, 10.8882675],
                [106.7322541, 10.88861],
                [106.7323708, 10.8889553],
                [106.7324801, 10.8893063],
                [106.7325806, 10.8896598],
                [106.7326724, 10.8900156],
                [106.7327554, 10.8903735],
                [106.7328295, 10.8907332],
                [106.7295599, 10.8896664],
                [106.7295223, 10.8899246],
                [106.7291678, 10.8899121],
                [106.7289029, 10.8906171],
                [106.728773, 10.8910426],
                [106.7285966, 10.8910541],
                [106.7274826, 10.8902321],
                [106.727, 10.8905],
                [106.7268, 10.89064],
                [106.7257, 10.89113],
                [106.725, 10.89144],
                [106.7233, 10.89252],
                [106.7217, 10.89345],
                [106.7186, 10.89566],
                [106.7179, 10.89623],
                [106.7178504, 10.8962968],
                [106.7177, 10.89642],
                [106.7164, 10.89417],
                [106.7159, 10.89347],
                [106.7153, 10.89262],
                [106.7131068, 10.8899024],
                [106.7131311, 10.8897508],
                [106.7131045, 10.8894264],
                [106.7131098, 10.8893112],
                [106.7131612, 10.8891961],
                [106.7133084, 10.8889902],
                [106.7134711, 10.8887852],
                [106.713714, 10.8885739],
                [106.7139526, 10.8884363],
                [106.7142, 10.88834],
                [106.714449, 10.8882287],
                [106.7145051, 10.8881524],
                [106.7145077, 10.8880861],
                [106.7144549, 10.8879986],
                [106.7142579, 10.887832],
                [106.7139528, 10.8876209],
                [106.7138627, 10.8875052],
                [106.7138562, 10.8873678],
                [106.7139148, 10.8872283],
                [106.7141687, 10.8870066],
                [106.7144266, 10.8868687],
                [106.7145793, 10.8868723],
                [106.7147384, 10.8869559],
                [106.7149396, 10.8871326],
                [106.7153489, 10.8875499],
                [106.7155501, 10.8877036],
                [106.715807, 10.8877729],
                [106.7162137, 10.8877978],
                [106.7164789, 10.8878277],
                [106.7167228, 10.8878945],
                [106.7170399, 10.8879851],
                [106.7172785, 10.8879831],
                [106.7174517, 10.8879078],
                [106.7175863, 10.8877483],
                [106.7175077, 10.8876087],
                [106.7174, 10.88722],
                [106.7173818, 10.8869447],
                [106.7173455, 10.8866154],
                [106.7172872, 10.8863447],
                [106.7171731, 10.8859974],
                [106.7167728, 10.8851651],
                [106.7166998, 10.8849373],
                [106.7166693, 10.8846968],
                [106.7166571, 10.8844086],
                [106.7167665, 10.8834107],
                [106.7169064, 10.8827826],
                [106.7170858, 10.8823103],
                [106.7178992, 10.8809018],
                [106.7137961, 10.8791176],
                [106.7134316, 10.878843],
                [106.7125877, 10.8779252],
                [106.7128507, 10.8774169],
                [106.7138223, 10.877743],
                [106.7140343, 10.8769561],
                [106.7151737, 10.8774445],
                [106.7157766, 10.8772957],
                [106.7163957, 10.8771609],
                [106.7167957, 10.8771831],
                [106.71737, 10.8772031],
                [106.7177043, 10.8771394],
                [106.7182918, 10.8768729],
                [106.7186704, 10.8768487],
                [106.7191858, 10.8768761],
                [106.719736, 10.8766923],
                [106.7202851, 10.8766102],
                [106.7207737, 10.8764924],
                [106.7210755, 10.8764728],
                [106.7212639, 10.8763863],
                [106.7213747, 10.8762088],
                [106.7214, 10.87599],
                [106.7215, 10.87567],
                [106.7216455, 10.8752015],
                [106.7216879, 10.8749534],
                [106.7218061, 10.8747291],
                [106.7222577, 10.8740173],
                [106.7227091, 10.8732955],
                [106.7232, 10.87293],
                [106.7234455, 10.8727483],
                [106.7235698, 10.8724866],
                [106.7235941, 10.8722896],
                [106.7235696, 10.8719882],
                [106.7236453, 10.8717823],
                [106.7234083, 10.8717308],
                [106.7224609, 10.8716514],
                [106.7217687, 10.8716899],
                [106.7212584, 10.8717613],
                [106.720886, 10.8719088],
                [106.7204912, 10.8721423],
                [106.7200683, 10.872298],
                [106.7196322, 10.8723596],
                [106.7186356, 10.8723771],
                [106.7179142, 10.8724119],
                [106.7173511, 10.8725468],
                [106.7167596, 10.8727678],
                [106.7162931, 10.872861],
                [106.7158043, 10.8728694],
                [106.7153743, 10.8727641],
                [106.7150579, 10.8725458],
                [106.7151197, 10.8715343],
                [106.7151708, 10.8708768],
                [106.7151528, 10.8701953],
                [106.7152828, 10.8694526],
                [106.7154322, 10.8691239],
                [106.7155051, 10.8688964],
                [106.7155382, 10.8687218],
                [106.7155141, 10.8685454],
                [106.7154076, 10.8682705],
                [106.715041, 10.8678706],
                [106.7143341, 10.8671829],
                [106.7138366, 10.8666952],
                [106.7130981, 10.8657225],
                [106.712064, 10.8660836],
                [106.7108069, 10.8663793],
                [106.7090249, 10.8665977],
                [106.7043915, 10.8669246],
                [106.7023069, 10.8672477],
                [106.7007142, 10.8679578],
                [106.6995, 10.86896],
                [106.698, 10.87035],
                [106.6969, 10.8714],
                [106.6968659, 10.8714341],
                [106.6966, 10.8717],
                [106.6952, 10.87337],
                [106.6934, 10.87598],
                [106.6904, 10.87965],
                [106.6898, 10.88067],
                [106.6894, 10.88216],
                [106.6892, 10.88297],
                [106.6892, 10.88381],
                [106.6893, 10.88489],
                [106.6894, 10.88591],
                [106.6897, 10.88658],
                [106.6904, 10.88779],
                [106.6915089, 10.8892131],
                [106.6916, 10.88933],
                [106.6917, 10.88948],
                [106.6919, 10.88995],
                [106.6935, 10.89278],
                [106.6942, 10.89412],
                [106.6945, 10.89571],
                [106.6945, 10.89666],
                [106.6944, 10.8975],
                [106.6942, 10.89856],
                [106.6939, 10.90065],
                [106.6934, 10.90271],
                [106.693, 10.90395],
                [106.6929, 10.90424],
                [106.6917, 10.90715],
                [106.690327, 10.9108388],
                [106.6902, 10.91118],
                [106.6885, 10.9148],
                [106.6873, 10.91731],
                [106.686, 10.91965],
                [106.6849, 10.92124],
                [106.6838, 10.92216],
                [106.6827, 10.92291],
                [106.6815, 10.92359],
                [106.6808, 10.9239],
                [106.6803, 10.9239],
                [106.6795, 10.92387],
                [106.6784, 10.92368],
                [106.6765, 10.92308],
                [106.6715, 10.92138],
                [106.6698, 10.92102],
                [106.669, 10.92103],
                [106.6667, 10.92151],
                [106.6632, 10.92268],
                [106.6589, 10.92493],
                [106.6574, 10.92579],
                [106.6553, 10.92746],
                [106.6534, 10.92909],
                [106.6525, 10.93027],
                [106.6515, 10.93187],
                [106.6508, 10.93312],
                [106.6504, 10.93448],
                [106.6502, 10.9362],
                [106.6502, 10.93742],
                [106.6504, 10.93846],
                [106.6508, 10.93998],
                [106.6516, 10.94217],
                [106.6518, 10.94285],
                [106.6524, 10.9472],
                [106.6525, 10.94943],
                [106.6524, 10.95096],
                [106.6522, 10.95252],
                [106.6521831, 10.9526902],
                [106.6521, 10.95353],
                [106.6518, 10.95474],
                [106.6505, 10.9587],
                [106.6496, 10.96138],
                [106.6489, 10.96449],
                [106.6488, 10.96622],
                [106.6488, 10.96706],
                [106.6492, 10.96968],
                [106.6498, 10.97154],
                [106.6499884, 10.9723419],
                [106.6501, 10.9729347],
                [106.6501987, 10.9736407],
                [106.6502, 10.97444],
                [106.6498, 10.97598],
                [106.6492, 10.97888],
                [106.6486, 10.98004],
                [106.648, 10.98061],
                [106.6475, 10.98096],
                [106.6467, 10.98123],
                [106.6459, 10.9813],
                [106.6443, 10.98104],
                [106.6433788, 10.9807607],
                [106.6431022, 10.980673],
                [106.6415022, 10.979913],
                [106.6406022, 10.979323],
                [106.6386022, 10.977983],
                [106.6374022, 10.977443],
                [106.6360022, 10.977443],
                [106.6337022, 10.977713],
                [106.6312022, 10.978313],
                [106.6305022, 10.978513],
                [106.6280022, 10.979193],
                [106.6279022, 10.979243],
                [106.6270022, 10.979683],
                [106.6261022, 10.980293],
                [106.6251022, 10.981183],
                [106.6239022, 10.982703],
                [106.6223022, 10.984573],
                [106.6194022, 10.986513],
                [106.6178022, 10.987603],
                [106.6168022, 10.988623],
                [106.6162022, 10.989803],
                [106.6160022, 10.990823],
                [106.6159022, 10.991263],
                [106.6160022, 10.991803],
                [106.6163022, 10.992933],
                [106.6167022, 10.993713],
                [106.6168022, 10.993973],
                [106.6175022, 10.995153],
                [106.6185022, 10.997013],
                [106.6199022, 10.999553],
                [106.6205022, 11.000433],
                [106.6209, 11.00159],
                [106.6210022, 11.001863],
                [106.6212, 11.00313],
                [106.6213, 11.00501],
                [106.6211, 11.00593],
                [106.6203, 11.00774],
                [106.6196, 11.00892],
                [106.619, 11.00964],
                [106.6184, 11.0104],
                [106.6178, 11.01087],
                [106.6171, 11.01122],
                [106.6163, 11.01159],
                [106.6162, 11.01159],
                [106.6156, 11.01166],
                [106.6149, 11.01172],
                [106.6143, 11.01172],
                [106.6115, 11.01166],
                [106.6104, 11.01166],
                [106.6088, 11.01184],
                [106.607, 11.01235],
                [106.6043, 11.01335],
                [106.6025, 11.01381],
                [106.6015, 11.01406],
                [106.6012, 11.01413],
                [106.6005, 11.0143],
                [106.5998, 11.01453],
                [106.5988, 11.01511],
                [106.598, 11.01582],
                [106.5979, 11.01598],
                [106.5973, 11.0173],
                [106.5968, 11.01863],
                [106.5956, 11.02255],
                [106.5951, 11.02439],
                [106.595, 11.02549],
                [106.5952, 11.02817],
                [106.5954, 11.02922],
                [106.5957, 11.03027],
                [106.5962, 11.03107],
                [106.5987, 11.03381],
                [106.6007443, 11.0361292],
                [106.6022779, 11.0379704],
                [106.6034321, 11.0395281],
                [106.603215, 11.0404139],
                [106.6027, 11.04126],
                [106.6021, 11.04202],
                [106.6012, 11.04339],
                [106.6003, 11.04446],
                [106.5994, 11.0453],
                [106.5983, 11.04559],
                [106.5972, 11.04565],
                [106.5954, 11.0454],
                [106.5946, 11.04507],
                [106.5938, 11.04442],
                [106.5929, 11.04343],
                [106.5921, 11.04195],
                [106.5917, 11.04025],
                [106.5916, 11.03873],
                [106.5912, 11.03751],
                [106.5908, 11.03692],
                [106.5902, 11.03657],
                [106.5897, 11.03641],
                [106.5889, 11.03626],
                [106.5881, 11.03627],
                [106.5868, 11.03638],
                [106.5862, 11.03653],
                [106.5855, 11.03668],
                [106.5843, 11.03719],
                [106.5836, 11.03745],
                [106.5831, 11.03784],
                [106.5829287, 11.0381436],
                [106.5821, 11.03929],
                [106.5816, 11.04012],
                [106.5813, 11.0406],
                [106.5812, 11.0411],
                [106.581, 11.04261],
                [106.5804, 11.04572],
                [106.58, 11.047],
                [106.5795, 11.04803],
                [106.5788, 11.04877],
                [106.5787, 11.04888],
                [106.5777, 11.04973],
                [106.5754, 11.05188],
                [106.575, 11.05234],
                [106.5748, 11.05271],
                [106.5744, 11.05323],
                [106.574, 11.05373],
                [106.5732, 11.05438],
                [106.5727, 11.05461],
                [106.5723, 11.05467],
                [106.572, 11.05463],
                [106.5717, 11.05454],
                [106.5713, 11.0542],
                [106.571, 11.05381],
                [106.5697, 11.05205],
                [106.569, 11.0509],
                [106.5687, 11.04988],
                [106.5686, 11.04901],
                [106.5686, 11.04755],
                [106.5685, 11.04695],
                [106.5681, 11.04593],
                [106.5675, 11.04545],
                [106.5667, 11.04504],
                [106.5653, 11.04506],
                [106.5635, 11.04507],
                [106.5624, 11.0452],
                [106.5597, 11.04552],
                [106.5587004, 11.0456277],
                [106.5584, 11.04566],
                [106.5582, 11.04569],
                [106.5549, 11.04576],
                [106.552, 11.04577],
                [106.5513, 11.04585],
                [106.551, 11.04588],
                [106.5503, 11.04603],
                [106.55, 11.04616],
                [106.5496, 11.04644],
                [106.5495, 11.0465],
                [106.5489, 11.04704],
                [106.5483, 11.04795],
                [106.5481, 11.04879],
                [106.5472, 11.051],
                [106.5462, 11.0521],
                [106.5444, 11.05337],
                [106.5423, 11.05425],
                [106.5402, 11.0549],
                [106.5363, 11.05658],
                [106.5348, 11.05745],
                [106.5329, 11.0591],
                [106.5322, 11.06017],
                [106.5309, 11.06242],
                [106.53, 11.06504],
                [106.5295, 11.06565],
                [106.5291, 11.06628],
                [106.5288, 11.06669],
                [106.5284, 11.06705],
                [106.5276, 11.06774],
                [106.5266, 11.06833],
                [106.5259, 11.06883],
                [106.5254, 11.06931],
                [106.5252, 11.06973],
                [106.5246, 11.07102],
                [106.5241, 11.07226],
                [106.5238, 11.07338],
                [106.5238, 11.07377],
                [106.5238, 11.0743],
                [106.5241, 11.07535],
                [106.5253, 11.07808],
                [106.5268, 11.0807],
                [106.5279, 11.08208],
                [106.5303, 11.08421],
                [106.5325, 11.08636],
                [106.5333, 11.0876],
                [106.5339, 11.08852],
                [106.534, 11.08922],
                [106.534, 11.08977],
                [106.5338, 11.09055],
                [106.5334, 11.09114],
                [106.533, 11.09158],
                [106.5327, 11.09181],
                [106.5323, 11.092],
                [106.5313, 11.09224],
                [106.5304, 11.09224],
                [106.5291, 11.09223],
                [106.5273, 11.09156],
                [106.5262, 11.09079],
                [106.5251, 11.08986],
                [106.5242, 11.08953],
                [106.5235, 11.08954],
                [106.5224, 11.09005],
                [106.5216, 11.09087],
                [106.5211, 11.09169],
                [106.5206, 11.09259],
                [106.5201, 11.09334],
                [106.5193, 11.09463],
                [106.519, 11.09508],
                [106.5183, 11.09611],
                [106.5173, 11.09741],
                [106.5168, 11.09836],
                [106.5164, 11.09928],
                [106.5162, 11.10017],
                [106.5162, 11.10069],
                [106.5163, 11.10124],
                [106.5164, 11.10156],
                [106.517, 11.10203],
                [106.5177, 11.10231],
                [106.5185, 11.10258],
                [106.5197, 11.10279],
                [106.5207, 11.10293],
                [106.521, 11.10298],
                [106.5224, 11.10323],
                [106.5241, 11.10362],
                [106.5253, 11.10449],
                [106.5263, 11.10586],
                [106.5267, 11.10698],
                [106.527, 11.10967],
                [106.5274, 11.11219],
                [106.5276, 11.1133],
                [106.5278, 11.11415],
                [106.5278, 11.11497],
                [106.5275, 11.11626],
                [106.5272, 11.11704],
                [106.527, 11.11752],
                [106.5269, 11.11833],
                [106.5269, 11.11899],
                [106.527, 11.1196],
                [106.527, 11.12023],
                [106.5268, 11.12067],
                [106.5267, 11.12104],
                [106.5263, 11.12134],
                [106.5258, 11.12149],
                [106.5253, 11.12135],
                [106.5244, 11.12124],
                [106.5234, 11.12132],
                [106.5222, 11.12151],
                [106.5212, 11.12181],
                [106.5193, 11.12278],
                [106.5174, 11.12404],
                [106.5159, 11.12542],
                [106.5142, 11.12775],
                [106.5138, 11.12862],
                [106.5132, 11.13043],
                [106.5127, 11.13224],
                [106.512, 11.13391],
                [106.5113, 11.13555],
                [106.5107, 11.13651],
                [106.5102, 11.13707],
                [106.5093, 11.13821],
                [106.5083, 11.13974],
                [106.508, 11.14043],
                [106.508, 11.14103],
                [106.5078, 11.14161],
                [106.5077, 11.14183],
                [106.5075, 11.14208],
                [106.5073, 11.14221],
                [106.5071, 11.14224],
                [106.5067, 11.14224],
                [106.5063, 11.14223],
                [106.506, 11.14215],
                [106.5055, 11.14195],
                [106.5051, 11.14179],
                [106.5047, 11.14159],
                [106.5033, 11.14081],
                [106.5026, 11.14052],
                [106.5021, 11.14032],
                [106.5013817, 11.1402899],
                [106.5006548, 11.140482],
                [106.4999279, 11.140782],
                [106.4992601, 11.1411689],
                [106.4977661, 11.1422584],
                [106.4973557, 11.1425374],
                [106.4969695, 11.1427426],
                [106.4958, 11.1432032],
                [106.4927584, 11.14414],
                [106.491369, 11.1444979],
                [106.4900279, 11.1446243],
                [106.48855, 11.1444769],
                [106.4872438, 11.1442558],
                [106.4864981, 11.1440558],
                [106.4857578, 11.1437927],
                [106.4842987, 11.1430821],
                [106.4832312, 11.1426137],
                [106.4821422, 11.1424031],
                [106.4807877, 11.1423163],
                [106.479747, 11.1422979],
                [106.4786231, 11.1423558],
                [106.4757156, 11.1428032],
                [106.4735363, 11.1430426],
                [106.4725667, 11.14319],
                [106.4718801, 11.1435005],
                [106.4709145, 11.1443677],
                [106.4703378, 11.1452295],
                [106.4699838, 11.1457085],
                [106.4696565, 11.1460664],
                [106.4691737, 11.146448],
                [106.4679104, 11.147198],
                [106.4676315, 11.1474559],
                [106.4674437, 11.1477032],
                [106.4673498, 11.1479769],
                [106.4673337, 11.1482611],
                [106.4674142, 11.1486138],
                [106.4675885, 11.1489164],
                [106.4689431, 11.1501769],
                [106.4699677, 11.1513454],
                [106.4705846, 11.1523164],
                [106.4709091, 11.1529111],
                [106.4710459, 11.1534295],
                [106.4710835, 11.1539585],
                [106.4709896, 11.1543559],
                [106.4708447, 11.1547585],
                [106.4706168, 11.1551032],
                [106.4702761, 11.1553637],
                [106.469847, 11.1555821],
                [106.467956, 11.1561979],
                [106.4671594, 11.1565979],
                [106.466462, 11.1570716],
                [106.4659685, 11.1575242],
                [106.4646488, 11.15924],
                [106.4643055, 11.1595768],
                [106.4638562, 11.1598189],
                [106.4633104, 11.1599031],
                [106.4627954, 11.1598505],
                [106.4623475, 11.1596426],
                [106.4611781, 11.15874],
                [106.4603412, 11.15834],
                [106.4596438, 11.1582531],
                [106.4589867, 11.1584294],
                [106.4585401, 11.1587255],
                [106.4575624, 11.1596899],
                [106.4570555, 11.1601057],
                [106.4565432, 11.1602741],
                [106.4560658, 11.1603083],
                [106.4555857, 11.1602189],
                [106.4546496, 11.1598084],
                [106.4537537, 11.1592347],
                [106.452941, 11.1584637],
                [106.4522812, 11.1574768],
                [106.4519379, 11.1566177],
                [106.4516616, 11.15554],
                [106.4515221, 11.1544453],
                [106.4514846, 11.1536453],
                [106.4515516, 11.152848],
                [106.4516857, 11.1522927],
                [106.4519379, 11.1518585],
                [106.4523455, 11.1516638],
                [106.4527747, 11.1515927],
                [106.453279, 11.1516059],
                [106.4537135, 11.1516875],
                [106.4541238, 11.1518822],
                [106.4557278, 11.1531138],
                [106.4560926, 11.1532927],
                [106.4564627, 11.1533769],
                [106.4568812, 11.1533506],
                [106.4573345, 11.1531611],
                [106.4576831, 11.1528611],
                [106.4578843, 11.152498],
                [106.458056, 11.1519375],
                [106.458115, 11.1514032],
                [106.4581418, 11.1495427],
                [106.4582598, 11.1484611],
                [106.4582545, 11.1476296],
                [106.4579621, 11.1453716],
                [106.4578253, 11.1448466],
                [106.4576697, 11.1444611],
                [106.456975, 11.1429505],
                [106.4564306, 11.1413229],
                [106.4561006, 11.1406978],
                [106.4557224, 11.1402347],
                [106.4550894, 11.1395873],
                [106.4528686, 11.137524],
                [106.4516401, 11.1365082],
                [106.4509213, 11.1362345],
                [106.4501488, 11.1361082],
                [106.4477561, 11.1360302],
                [106.4479038, 11.1327922],
                [106.4480755, 11.1322685],
                [106.4479467, 11.1322343],
                [106.4479467, 11.1321501],
                [106.447818, 11.1321053],
                [106.447928, 11.1314974],
                [106.4480218, 11.1315],
                [106.4484858, 11.1307276],
                [106.4481962, 11.1305763],
                [106.447708, 11.1301552],
                [106.4470696, 11.129692],
                [106.4468604, 11.1293236],
                [106.446442, 11.1286761],
                [106.4462435, 11.1281761],
                [106.4460772, 11.1276392],
                [106.4455193, 11.1275129],
                [106.4453584, 11.1274024],
                [106.4452404, 11.1272129],
                [106.4451331, 11.1268286],
                [106.4448917, 11.1266339],
                [106.4448112, 11.1264865],
                [106.444779, 11.1262707],
                [106.44483, 11.1257628],
                [106.444838, 11.1252706],
                [106.4446986, 11.1247232],
                [106.4444303, 11.1240652],
                [106.4443606, 11.1238047],
                [106.4444464, 11.1232599],
                [106.444425, 11.1230862],
                [106.4443392, 11.1229283],
                [106.4441299, 11.1227546],
                [106.4440119, 11.1225809],
                [106.4438242, 11.1219177],
                [106.4437866, 11.1214387],
                [106.4435774, 11.1208123],
                [106.4435774, 11.1201754],
                [106.4434755, 11.1198648],
                [106.4432931, 11.1197122],
                [106.4431643, 11.1195069],
                [106.4430731, 11.1192542],
                [106.4430302, 11.1190437],
                [106.4430892, 11.1188121],
                [106.4429712, 11.1182015],
                [106.4429069, 11.1176172],
                [106.4426547, 11.1170487],
                [106.4426064, 11.1168435],
                [106.4426386, 11.1164592],
                [106.442644, 11.1162486],
                [106.4425528, 11.1159539],
                [106.4423275, 11.1155644],
                [106.4420298, 11.1151117],
                [106.441901, 11.114988],
                [106.4417052, 11.1149459],
                [106.4415309, 11.1148011],
                [106.4415041, 11.1147195],
                [106.4414665, 11.1146906],
                [106.4413941, 11.1146985],
                [106.4413512, 11.1147274],
                [106.4412358, 11.1148537],
                [106.4411473, 11.1149064],
                [106.4410561, 11.1148959],
                [106.4409167, 11.1148116],
                [106.4407718, 11.1148011],
                [106.440568, 11.1148011],
                [106.4404285, 11.1147274],
                [106.44023, 11.1145274],
                [106.4395326, 11.1136589],
                [106.4385456, 11.112664],
                [106.4382023, 11.112385],
                [106.4380252, 11.1123113],
                [106.4378697, 11.1123166],
                [106.4377463, 11.1122429],
                [106.4376605, 11.1121166],
                [106.4375371, 11.111806],
                [106.4374351, 11.1116849],
                [106.43736, 11.1115323],
                [106.4373439, 11.1113796],
                [106.4373815, 11.1108322],
                [106.43736, 11.1106585],
                [106.4373171, 11.1105006],
                [106.437124, 11.1101294],
                [106.4373922, 11.1099057],
                [106.4368129, 11.1083529],
                [106.4358902, 11.1061526],
                [106.4352679, 11.1043997],
                [106.4338812, 11.1002358],
                [106.4331034, 11.1003358],
                [106.4322987, 11.0983342],
                [106.4315987, 11.0983355],
                [106.4311212, 11.0954297],
                [106.4308543, 11.0937136],
                [106.4308825, 11.0935109],
                [106.4308879, 11.0920896],
                [106.430338, 11.0920843],
                [106.430338, 11.0919264],
                [106.4296809, 11.0919185],
                [106.4277229, 11.0919369],
                [106.4269075, 11.091979],
                [106.4268941, 11.0918843],
                [106.4260116, 11.09165],
                [106.4260197, 11.0911578],
                [106.4260518, 11.0907182],
                [106.4261162, 11.0905708],
                [106.4263013, 11.0893101],
                [106.4264059, 11.0891706],
                [106.4264059, 11.08886],
                [106.4263523, 11.0886757],
                [106.426422, 11.0878334],
                [106.4264998, 11.0878255],
                [106.426481, 11.0868174],
                [106.4265454, 11.0847696],
                [106.4271274, 11.0839799],
                [106.4269476, 11.0838286],
                [106.4268069, 11.0837101],
                [106.4271958, 11.0832245],
                [106.4269611, 11.083056],
                [106.4265615, 11.0830482],
                [106.4265642, 11.0817873],
                [106.4259124, 11.0813478],
                [106.4260679, 11.0798421],
                [106.4260518, 11.0780101],
                [106.4264059, 11.0780838],
                [106.4266661, 11.0763623],
                [106.4272079, 11.0756174],
                [106.4274198, 11.075062],
                [106.4274359, 11.0749093],
                [106.4274412, 11.0742934],
                [106.427393, 11.0740144],
                [106.4269531, 11.0727456],
                [106.4263147, 11.0711031],
                [106.4260572, 11.070645],
                [106.4256656, 11.0701712],
                [106.4253759, 11.069371],
                [106.4253116, 11.0689025],
                [106.4253116, 11.0685234],
                [106.4254403, 11.0678074],
                [106.4257783, 11.0666229],
                [106.4252418, 11.0666176],
                [106.424405, 11.0664754],
                [106.4227956, 11.0661385],
                [106.4227956, 11.0658858],
                [106.4225006, 11.06587],
                [106.4224899, 11.0655594],
                [106.4217389, 11.0651803],
                [106.4215994, 11.0648118],
                [106.4213097, 11.0649066],
                [106.420216, 11.0633818],
                [106.4216302, 11.0619221],
                [106.4207397, 11.0611304],
                [106.4216208, 11.0599209],
                [106.4230887, 11.058192],
                [106.421008, 11.0574319],
                [106.4211903, 11.0570844],
                [106.4210093, 11.0567843],
                [106.4211903, 11.0563237],
                [106.421244, 11.0560249],
                [106.4212668, 11.055788],
                [106.4212681, 11.0555919],
                [106.4212212, 11.0553418],
                [106.4210013, 11.0548061],
                [106.4208108, 11.0535504],
                [106.4203428, 11.0519564],
                [106.4216423, 11.0504178],
                [106.4223719, 11.0494911],
                [106.4225972, 11.0492437],
                [106.4232516, 11.0487698],
                [106.4236593, 11.0484171],
                [106.4243634, 11.0477642],
                [106.4247771, 11.0473832],
                [106.4246363, 11.0472687],
                [106.4245297, 11.0472153],
                [106.4243487, 11.0471469],
                [106.423206, 11.0467928],
                [106.4226454, 11.046627],
                [106.4227957, 11.046319],
                [106.4233911, 11.0454516],
                [106.422286, 11.0446105],
                [106.4229137, 11.0436733],
                [106.4224255, 11.043339],
                [106.4228064, 11.0428098],
                [106.4216799, 11.0420121],
                [106.4222002, 11.0415251],
                [106.4210629, 11.0397508],
                [106.4213633, 11.0395402],
                [106.4205694, 11.038008],
                [106.4201564, 11.0369234],
                [106.4201403, 11.0361915],
                [106.4196414, 11.0361547],
                [106.4187455, 11.036102],
                [106.4187026, 11.0356966],
                [106.4184558, 11.0354807],
                [106.4179623, 11.0354175],
                [106.4178979, 11.0352806],
                [106.4175278, 11.035228],
                [106.41734, 11.0350595],
                [106.4172059, 11.0350279],
                [106.4171094, 11.0347857],
                [106.4167285, 11.0345488],
                [106.4161572, 11.0340407],
                [106.4155698, 11.0337458],
                [106.4153016, 11.0336958],
                [106.4149743, 11.0336629],
                [106.414399, 11.0331495],
                [106.4139417, 11.0326559],
                [106.4137459, 11.0324005],
                [106.4132711, 11.0319451],
                [106.4133355, 11.0318372],
                [106.4133623, 11.0316581],
                [106.4133275, 11.0314791],
                [106.4132054, 11.0312961],
                [106.4131263, 11.0311843],
                [106.4128929, 11.0309579],
                [106.4120185, 11.0302628],
                [106.4118576, 11.0299022],
                [106.4114875, 11.0293941],
                [106.4113426, 11.0289623],
                [106.4113989, 11.0289044],
                [106.4112031, 11.0282831],
                [106.4110529, 11.0276907],
                [106.410892, 11.0274274],
                [106.4108062, 11.0272195],
                [106.4107954, 11.0269641],
                [106.4106989, 11.0267561],
                [106.4106452, 11.0264402],
                [106.4105648, 11.025861],
                [106.4104629, 11.0257873],
                [106.4102536, 11.0253713],
                [106.4099264, 11.0249185],
                [106.4096877, 11.0244393],
                [106.4093953, 11.0239128],
                [106.4093846, 11.0238101],
                [106.4094168, 11.0237285],
                [106.4094624, 11.023639],
                [106.4096823, 11.0234758],
                [106.4091405, 11.0228281],
                [106.4090037, 11.0226333],
                [106.4089742, 11.0224306],
                [106.4089957, 11.0222489],
                [106.4090681, 11.0220962],
                [106.409103, 11.0219962],
                [106.4090976, 11.0218988],
                [106.4089018, 11.0207746],
                [106.4089259, 11.0200137],
                [106.4090762, 11.0192107],
                [106.4092934, 11.0186473],
                [106.4094329, 11.0185947],
                [106.4095563, 11.0179681],
                [106.4095402, 11.0176995],
                [106.40939, 11.0169966],
                [106.4093605, 11.0169176],
                [106.4092988, 11.0168544],
                [106.4090896, 11.016757],
                [106.4090037, 11.0166885],
                [106.4089608, 11.0165569],
                [106.408993, 11.0161567],
                [106.4090735, 11.0156723],
                [106.4092009, 11.0153603],
                [106.4092116, 11.0152668],
                [106.4091781, 11.0151589],
                [106.409103, 11.0149456],
                [106.4091271, 11.0148219],
                [106.4091808, 11.0147271],
                [106.40928, 11.0146639],
                [106.4094087, 11.0146771],
                [106.4095241, 11.014706],
                [106.4096206, 11.0146534],
                [106.4097387, 11.014498],
                [106.4098674, 11.0142374],
                [106.4100552, 11.0137003],
                [106.4101598, 11.0135976],
                [106.410318, 11.0135239],
                [106.410723, 11.0134897],
                [106.410884, 11.0134449],
                [106.4110422, 11.0133554],
                [106.4111495, 11.0132343],
                [106.4113668, 11.0127762],
                [106.4122814, 11.0105752],
                [106.4125228, 11.0097642],
                [106.4128983, 11.0089007],
                [106.4122345, 11.008565],
                [106.4112179, 11.0080542],
                [106.4112139, 11.0079581],
                [106.4109631, 11.0078001],
                [106.4106323, 11.0076742],
                [106.4105898, 11.0076448],
                [106.4105671, 11.0076125],
                [106.4105427, 11.0074592],
                [106.4104112, 11.0074506],
                [106.4096461, 11.0072002],
                [106.4089078, 11.0069043],
                [106.4073726, 11.0064689],
                [106.4065421, 11.0062803],
                [106.4064436, 11.0062642],
                [106.4063514, 11.0062708],
                [106.4063537, 11.0057175],
                [106.4062089, 11.0057096],
                [106.4062357, 11.0053463],
                [106.4056027, 11.0052805],
                [106.4055893, 11.0049408],
                [106.4054632, 11.0049382],
                [106.4054418, 11.0045538],
                [106.4053398, 11.0045564],
                [106.4053184, 11.0037613],
                [106.4048678, 11.0037876],
                [106.4044118, 11.003756],
                [106.4033228, 11.0035665],
                [106.4033416, 11.0034295],
                [106.4028695, 11.0032531],
                [106.4022043, 11.0031294],
                [106.3979343, 11.0018761],
                [106.3928461, 11.0003622],
                [106.3920414, 10.9999646],
                [106.3915747, 10.9998435],
                [106.3911831, 10.9997303],
                [106.3907701, 10.9997566],
                [106.3905609, 10.9997487],
                [106.3902859, 10.9996869],
                [106.3901277, 10.9996026],
                [106.3900459, 10.999575],
                [106.3899533, 10.9995802],
                [106.3897991, 10.999625],
                [106.3896972, 10.9996474],
                [106.3895993, 10.9996487],
                [106.3894893, 10.9996316],
                [106.3889234, 10.9995368],
                [106.3887195, 10.9994657],
                [106.3885331, 10.9994663],
                [106.3884661, 10.999467],
                [106.3884151, 10.9994459],
                [106.3883816, 10.9994065],
                [106.3883413, 10.9993314],
                [106.3882957, 10.9992853],
                [106.3882421, 10.9992537],
                [106.3881858, 10.9992379],
                [106.3881241, 10.9992445],
                [106.3880262, 10.999288],
                [106.3879779, 10.9993024],
                [106.3879162, 10.9992959],
                [106.3877808, 10.9992669],
                [106.3877057, 10.9992643],
                [106.387652, 10.9992722],
                [106.3875058, 10.9993196],
                [106.3874254, 10.9993419],
                [106.3873596, 10.9993446],
                [106.3872939, 10.9993314],
                [106.3871437, 10.9992485],
                [106.3870753, 10.9992274],
                [106.3869989, 10.9992235],
                [106.3869077, 10.9992432],
                [106.3868004, 10.9992485],
                [106.3866676, 10.9992327],
                [106.3864182, 10.9991774],
                [106.3861634, 10.9991695],
                [106.385588, 10.9992274],
                [106.385285, 10.9993051],
                [106.3851522, 10.9993196],
                [106.3850275, 10.9993156],
                [106.384373, 10.9992314],
                [106.3839465, 10.9991484],
                [106.3824753, 10.998814],
                [106.3819778, 10.9986534],
                [106.3807949, 10.9984099],
                [106.3807131, 10.9983967],
                [106.3806488, 10.998402],
                [106.3805924, 10.998427],
                [106.3805482, 10.9984658],
                [106.3804878, 10.9985837],
                [106.3782683, 11.0002543],
                [106.3782039, 11.0002753],
                [106.3781556, 11.0002701],
                [106.3781127, 11.0002437],
                [106.3779893, 11.0001173],
                [106.377925, 11.0001121],
                [106.3772973, 11.0003938],
                [106.3772303, 11.0003885],
                [106.3771847, 11.0003517],
                [106.3769701, 10.9999304],
                [106.3754439, 10.9998488],
                [106.3740304, 10.9995539],
                [106.3734886, 10.9994828],
                [106.3715132, 10.9992959],
                [106.3685185, 10.9988588],
                [106.3669681, 10.9987219],
                [106.3635081, 10.9972264],
                [106.3632452, 10.9975634],
                [106.3629247, 10.9973435],
                [106.3629717, 10.9972395],
                [106.3628992, 10.9971816],
                [106.3628241, 10.9972448],
                [106.3626766, 10.9971263],
                [106.3626149, 10.9971763],
                [106.3622381, 10.9965971],
                [106.3623615, 10.9964628],
                [106.3620946, 10.9962653],
                [106.3613704, 10.9959152],
                [106.3599193, 10.995299],
                [106.3602519, 10.9946698],
                [106.3597181, 10.9944697],
                [106.3597959, 10.9942722],
                [106.3583234, 10.9934691],
                [106.3583824, 10.9933664],
                [106.358192, 10.9932611],
                [106.3581839, 10.9931716],
                [106.3581061, 10.9931268],
                [106.3580123, 10.9931716],
                [106.3578272, 10.9930584],
                [106.3581866, 10.9923527],
                [106.3567007, 10.9915971],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [104.8364751, 20.697002],
                [104.8365304, 20.696258],
                [104.8366401, 20.6958477],
                [104.8372276, 20.6950529],
                [104.8382386, 20.6937711],
                [104.8388125, 20.6931046],
                [104.839878, 20.6918485],
                [104.8404245, 20.6911564],
                [104.8402756, 20.6900787],
                [104.8402759, 20.6897452],
                [104.8403589, 20.6886678],
                [104.8406052, 20.6879754],
                [104.8409061, 20.6872829],
                [104.841289, 20.6864879],
                [104.84159, 20.6857185],
                [104.8415224, 20.6850771],
                [104.8414693, 20.6836147],
                [104.8413233, 20.6796894],
                [104.8411059, 20.6788169],
                [104.840316, 20.6775335],
                [104.8399344, 20.6770713],
                [104.8397986, 20.6765581],
                [104.8398539, 20.6758141],
                [104.8397874, 20.6741466],
                [104.8396302, 20.6732371],
                [104.8395434, 20.6727352],
                [104.839517, 20.6718886],
                [104.839327, 20.6708621],
                [104.8391644, 20.6699385],
                [104.8388928, 20.6686811],
                [104.8385124, 20.6671671],
                [104.8383767, 20.6665034],
                [104.8379872, 20.6630851],
                [104.8371358, 20.6607513],
                [104.8370015, 20.6601995],
                [104.83916, 20.6590563],
                [104.8411844, 20.6570644],
                [104.8464027, 20.6522179],
                [104.8472399, 20.6512743],
                [104.8477542, 20.651509],
                [104.8483658, 20.6528342],
                [104.8486796, 20.6538456],
                [104.8497122, 20.6546914],
                [104.8498767, 20.654875],
                [104.8499484, 20.6549743],
                [104.8500656, 20.6551782],
                [104.8501116, 20.6552815],
                [104.8501818, 20.6554954],
                [104.8502571, 20.6558734],
                [104.8502983, 20.6561432],
                [104.8503293, 20.6564143],
                [104.8503502, 20.6566862],
                [104.8503609, 20.6569587],
                [104.8503613, 20.6572313],
                [104.850547, 20.6574181],
                [104.8507252, 20.657611],
                [104.8508959, 20.6578099],
                [104.8511923, 20.6581945],
                [104.8513198, 20.6583785],
                [104.8515562, 20.6587571],
                [104.8516647, 20.6589514],
                [104.8519261, 20.6594709],
                [104.8521101, 20.6597812],
                [104.8523241, 20.6600865],
                [104.8524402, 20.6602333],
                [104.8526895, 20.6605141],
                [104.8532367, 20.6620901],
                [104.8536551, 20.6626322],
                [104.8539342, 20.6625426],
                [104.8542098, 20.662444],
                [104.8544817, 20.6623366],
                [104.8547494, 20.6622206],
                [104.8552243, 20.6619883],
                [104.8558904, 20.6616122],
                [104.8563165, 20.6613972],
                [104.8567557, 20.6612067],
                [104.8572456, 20.6610287],
                [104.8577471, 20.6608812],
                [104.8582578, 20.660765],
                [104.8587967, 20.6607056],
                [104.8593306, 20.6606144],
                [104.8598501, 20.6604939],
                [104.8603606, 20.6603434],
                [104.8605148, 20.6603074],
                [104.8608285, 20.6602604],
                [104.860987, 20.6602495],
                [104.8613047, 20.660253],
                [104.8618765, 20.6602941],
                [104.8624601, 20.6603047],
                [104.8630535, 20.6602832],
                [104.8646843, 20.6601928],
                [104.8650504, 20.6602105],
                [104.8657809, 20.6602675],
                [104.8665082, 20.6603534],
                [104.8671921, 20.6604611],
                [104.8678708, 20.6605944],
                [104.8684772, 20.6607345],
                [104.8691353, 20.660857],
                [104.8698449, 20.6609558],
                [104.8722696, 20.6613172],
                [104.8725418, 20.6613698],
                [104.8730811, 20.6614953],
                [104.8736533, 20.6616605],
                [104.8739556, 20.6617616],
                [104.8752761, 20.6622648],
                [104.8760131, 20.6625142],
                [104.8767596, 20.6627376],
                [104.877174, 20.6628494],
                [104.8780096, 20.6630488],
                [104.8782042, 20.6631084],
                [104.8783954, 20.6631769],
                [104.8785828, 20.6632542],
                [104.8787659, 20.66334],
                [104.8811424, 20.6645396],
                [104.882285, 20.6657492],
                [104.8824688, 20.6658218],
                [104.8828446, 20.6659474],
                [104.883242, 20.6660489],
                [104.8836596, 20.6661231],
                [104.8840821, 20.6661658],
                [104.8841342, 20.6662819],
                [104.8841951, 20.6663942],
                [104.8843333, 20.6665943],
                [104.8844892, 20.6667653],
                [104.8846966, 20.6669624],
                [104.8849185, 20.6672169],
                [104.885112, 20.6674909],
                [104.8860991, 20.668821],
                [104.8865175, 20.6695437],
                [104.8865881, 20.669619],
                [104.8866657, 20.669688],
                [104.8867497, 20.6697501],
                [104.8868394, 20.6698047],
                [104.8870199, 20.6698855],
                [104.8872124, 20.6699365],
                [104.8873115, 20.6699503],
                [104.8894089, 20.66987],
                [104.8909485, 20.6699804],
                [104.8912815, 20.6699877],
                [104.8914479, 20.6699802],
                [104.8917811, 20.6699427],
                [104.8921114, 20.6698748],
                [104.8922735, 20.6698298],
                [104.8928894, 20.6696342],
                [104.8932016, 20.6695487],
                [104.8938095, 20.6694076],
                [104.8944247, 20.6692978],
                [104.8948742, 20.669239],
                [104.8957765, 20.6691472],
                [104.89662, 20.6690928],
                [104.8970425, 20.6690769],
                [104.8997033, 20.6690368],
                [104.9012804, 20.6679225],
                [104.9039626, 20.6661959],
                [104.9040877, 20.6660419],
                [104.9042204, 20.6658935],
                [104.9043604, 20.6657511],
                [104.9045074, 20.6656149],
                [104.904661, 20.6654854],
                [104.9048209, 20.6653627],
                [104.9051229, 20.6651605],
                [104.9052804, 20.6650685],
                [104.9056071, 20.6649034],
                [104.9063374, 20.6645863],
                [104.907514, 20.664101],
                [104.9087484, 20.6636295],
                [104.9093707, 20.663406],
                [104.9106252, 20.6629836],
                [104.9115962, 20.6621855],
                [104.9119528, 20.6619145],
                [104.9123257, 20.6616635],
                [104.912552, 20.6615256],
                [104.913019, 20.661272],
                [104.9132591, 20.6611565],
                [104.9151528, 20.6604237],
                [104.9168265, 20.6596206],
                [104.918586, 20.6590584],
                [104.9189615, 20.6583055],
                [104.9190328, 20.6581949],
                [104.9191922, 20.6579842],
                [104.9192817, 20.6578828],
                [104.9193766, 20.6577857],
                [104.9195909, 20.6575886],
                [104.919803, 20.657365],
                [104.9199949, 20.6571186],
                [104.9201633, 20.6568508],
                [104.9202367, 20.6567114],
                [104.9203949, 20.6563748],
                [104.9205655, 20.6559814],
                [104.9207596, 20.6554586],
                [104.9208444, 20.6551934],
                [104.9211869, 20.6548682],
                [104.9213666, 20.6547137],
                [104.9217418, 20.6544218],
                [104.9221367, 20.6541536],
                [104.9225595, 20.6539051],
                [104.9230093, 20.6536792],
                [104.9234747, 20.6534828],
                [104.9239535, 20.653317],
                [104.9255185, 20.6528818],
                [104.925772, 20.6527953],
                [104.9260128, 20.6526814],
                [104.9262376, 20.6525416],
                [104.926451, 20.652371],
                [104.9265525, 20.6522731],
                [104.9267345, 20.6520602],
                [104.9268861, 20.6518272],
                [104.9270047, 20.6515781],
                [104.9271374, 20.6512331],
                [104.9272931, 20.6507963],
                [104.9274292, 20.6503391],
                [104.9275432, 20.6498623],
                [104.9276304, 20.6493806],
                [104.9298298, 20.6483365],
                [104.9307012, 20.6478801],
                [104.9311433, 20.6476629],
                [104.9315894, 20.647453],
                [104.9320785, 20.6472333],
                [104.9325721, 20.6470224],
                [104.9330699, 20.6468205],
                [104.9335771, 20.6465075],
                [104.9340999, 20.6462181],
                [104.9346228, 20.6459595],
                [104.934889, 20.6458389],
                [104.9354303, 20.6456157],
                [104.9370074, 20.6456358],
                [104.9372711, 20.6456257],
                [104.9375341, 20.6456065],
                [104.9377962, 20.6455784],
                [104.9380571, 20.6455412],
                [104.9383163, 20.6454952],
                [104.9387797, 20.6453896],
                [104.9390083, 20.6453261],
                [104.9392345, 20.6452555],
                [104.9395693, 20.6451376],
                [104.9398234, 20.6450292],
                [104.9399646, 20.6449587],
                [104.9402359, 20.6448],
                [104.9407394, 20.644447],
                [104.9411273, 20.6442001],
                [104.9416175, 20.6439245],
                [104.9421251, 20.643678],
                [104.9439812, 20.6438085],
                [104.9458587, 20.6437884],
                [104.9466268, 20.6424452],
                [104.9468136, 20.642182],
                [104.9470074, 20.6419627],
                [104.9472235, 20.6417624],
                [104.9480045, 20.6411579],
                [104.9480831, 20.6410343],
                [104.9481547, 20.6409071],
                [104.9482739, 20.640648],
                [104.9483626, 20.6403841],
                [104.9483961, 20.6402493],
                [104.9484122, 20.6394963],
                [104.9488927, 20.6391435],
                [104.949367, 20.6387834],
                [104.9498275, 20.6384221],
                [104.9502818, 20.638054],
                [104.9507296, 20.637679],
                [104.9508863, 20.6373649],
                [104.9510193, 20.6370414],
                [104.9510896, 20.6368365],
                [104.9511505, 20.6366288],
                [104.9512017, 20.6364189],
                [104.9522209, 20.635445],
                [104.9525052, 20.6346418],
                [104.9533528, 20.6340644],
                [104.9534859, 20.6339411],
                [104.9535459, 20.6338737],
                [104.9536471, 20.6337358],
                [104.9543023, 20.6326738],
                [104.9543853, 20.6325717],
                [104.9544758, 20.6324755],
                [104.9545735, 20.6323855],
                [104.9546778, 20.6323023],
                [104.9547905, 20.6322248],
                [104.954909, 20.6321552],
                [104.9550326, 20.6320939],
                [104.9553253, 20.6319749],
                [104.9556434, 20.6318204],
                [104.9559374, 20.6316422],
                [104.956212, 20.6314388],
                [104.9588084, 20.6299528],
                [104.9589343, 20.6298524],
                [104.9590535, 20.629745],
                [104.9591654, 20.6296311],
                [104.9592698, 20.629511],
                [104.9593668, 20.6293841],
                [104.9594553, 20.6292518],
                [104.959535, 20.6291148],
                [104.9596056, 20.6289734],
                [104.9597407, 20.6286582],
                [104.9598695, 20.6283116],
                [104.9599242, 20.6281354],
                [104.9600205, 20.6277466],
                [104.9600851, 20.6273522],
                [104.960289, 20.6257858],
                [104.9606323, 20.6250427],
                [104.9606323, 20.6226027],
                [104.9605258, 20.6221688],
                [104.9604821, 20.62195],
                [104.9604197, 20.6215557],
                [104.9603963, 20.6213576],
                [104.9603608, 20.6212073],
                [104.9602722, 20.6209108],
                [104.9601558, 20.6206101],
                [104.9600096, 20.6203077],
                [104.9599271, 20.6201608],
                [104.9595089, 20.6194811],
                [104.9591278, 20.6189255],
                [104.9589131, 20.6186376],
                [104.958524, 20.618157],
                [104.9581754, 20.6177727],
                [104.9568343, 20.6164773],
                [104.9579501, 20.6148606],
                [104.9589586, 20.6130832],
                [104.9596882, 20.6120238],
                [104.9595058, 20.6104622],
                [104.9594879, 20.6102289],
                [104.9594977, 20.6099953],
                [104.9595359, 20.6097608],
                [104.959602, 20.6095318],
                [104.9596453, 20.6094203],
                [104.9613485, 20.6075098],
                [104.961418, 20.6075959],
                [104.9614952, 20.607676],
                [104.9615795, 20.6077496],
                [104.9617508, 20.6078659],
                [104.9619224, 20.6079484],
                [104.9620125, 20.6079808],
                [104.9623657, 20.6080798],
                [104.9626172, 20.6081776],
                [104.9628899, 20.6083187],
                [104.9631429, 20.6084889],
                [104.9637009, 20.6084966],
                [104.9642587, 20.608514],
                [104.9653208, 20.6085743],
                [104.9654148, 20.6085681],
                [104.9656003, 20.6085379],
                [104.965691, 20.608514],
                [104.9658741, 20.6084455],
                [104.966045, 20.6083534],
                [104.966217, 20.6082347],
                [104.9663722, 20.6080973],
                [104.9664599, 20.6080026],
                [104.9665396, 20.6079019],
                [104.9666108, 20.6077959],
                [104.9666732, 20.607685],
                [104.9667263, 20.6075701],
                [104.9668336, 20.6072788],
                [104.9678635, 20.6064453],
                [104.9679762, 20.6063337],
                [104.9681856, 20.6060975],
                [104.968282, 20.6059733],
                [104.9684522, 20.6057221],
                [104.9685287, 20.6055917],
                [104.9685815, 20.6054359],
                [104.9685985, 20.6053557],
                [104.9686131, 20.605199],
                [104.9685609, 20.6042561],
                [104.9685721, 20.6040312],
                [104.9686157, 20.6037851],
                [104.9686533, 20.6036527],
                [104.9688036, 20.6032597],
                [104.9690344, 20.6027423],
                [104.9692939, 20.6022438],
                [104.9695802, 20.6017655],
                [104.9713826, 20.5989861],
                [104.9739176, 20.5965601],
                [104.974761, 20.5958584],
                [104.9763501, 20.5947053],
                [104.977061, 20.5945803],
                [104.9774184, 20.5945287],
                [104.977777, 20.5944843],
                [104.9784411, 20.5944216],
                [104.9791074, 20.5943839],
                [104.9798313, 20.5944109],
                [104.9801936, 20.5944117],
                [104.9809113, 20.5943882],
                [104.9816206, 20.594332],
                [104.982326, 20.5942433],
                [104.982605, 20.5937411],
                [104.9827813, 20.59363],
                [104.9829376, 20.5934951],
                [104.9830628, 20.5933494],
                [104.9831167, 20.5932707],
                [104.9832058, 20.5931034],
                [104.9832571, 20.5929378],
                [104.9832702, 20.5928523],
                [104.9832932, 20.5925199],
                [104.9833238, 20.5924004],
                [104.9833882, 20.5922662],
                [104.9834311, 20.5922045],
                [104.9835604, 20.5920522],
                [104.9836575, 20.5918823],
                [104.9837202, 20.5917007],
                [104.9837647, 20.5914226],
                [104.9837898, 20.5913353],
                [104.9838817, 20.5910897],
                [104.9838879, 20.5910242],
                [104.9838704, 20.5909626],
                [104.9838334, 20.5909114],
                [104.9837802, 20.5908838],
                [104.9837595, 20.5908628],
                [104.9837342, 20.590811],
                [104.983734, 20.5907538],
                [104.983759, 20.5907016],
                [104.9838237, 20.5906426],
                [104.9839235, 20.590582],
                [104.9840231, 20.590542],
                [104.9840636, 20.5905167],
                [104.9840985, 20.5904849],
                [104.9841446, 20.5904112],
                [104.9841611, 20.5903328],
                [104.984159, 20.5902928],
                [104.9841099, 20.5900338],
                [104.9841208, 20.5899512],
                [104.9841365, 20.5899121],
                [104.984194, 20.5898364],
                [104.9842325, 20.5898068],
                [104.984276, 20.589784],
                [104.9843485, 20.5897662],
                [104.9844178, 20.5897392],
                [104.9845378, 20.5896633],
                [104.9846297, 20.5895646],
                [104.9846649, 20.5895078],
                [104.9846856, 20.5894561],
                [104.9847005, 20.5893469],
                [104.9846546, 20.5890222],
                [104.9846627, 20.5889448],
                [104.9846956, 20.5888706],
                [104.9847215, 20.5888367],
                [104.9847526, 20.588807],
                [104.9848205, 20.5887621],
                [104.9848704, 20.5887072],
                [104.9848956, 20.5886391],
                [104.9848975, 20.5885986],
                [104.9848748, 20.5885209],
                [104.984732, 20.5883528],
                [104.9847101, 20.5883093],
                [104.9846897, 20.5882152],
                [104.9846917, 20.588167],
                [104.9847158, 20.5880836],
                [104.9847642, 20.5880099],
                [104.9848512, 20.5879304],
                [104.9849103, 20.5878867],
                [104.9850537, 20.5878088],
                [104.9851381, 20.5877778],
                [104.9854133, 20.5877025],
                [104.985446, 20.5876871],
                [104.9854969, 20.5876382],
                [104.9855203, 20.5875743],
                [104.9855126, 20.5875078],
                [104.9854756, 20.5874505],
                [104.9851732, 20.5871589],
                [104.9851418, 20.5865574],
                [104.9851446, 20.5860091],
                [104.9851728, 20.5855156],
                [104.9852335, 20.5849028],
                [104.9852238, 20.5847984],
                [104.985194, 20.5846917],
                [104.9851423, 20.5845864],
                [104.9849788, 20.5843969],
                [104.9849517, 20.5843529],
                [104.9849237, 20.5842553],
                [104.9849237, 20.5842045],
                [104.9849398, 20.584109],
                [104.9849361, 20.5840631],
                [104.9849224, 20.584019],
                [104.9848832, 20.5839589],
                [104.9847577, 20.5838572],
                [104.9846341, 20.5837327],
                [104.9845791, 20.5836649],
                [104.9844879, 20.5835268],
                [104.9844525, 20.5834887],
                [104.9843657, 20.583429],
                [104.9841811, 20.583361],
                [104.9841177, 20.5833284],
                [104.9839911, 20.583238],
                [104.9838871, 20.5831251],
                [104.9838079, 20.5829867],
                [104.9836998, 20.5828489],
                [104.9835598, 20.5827183],
                [104.9834638, 20.5826704],
                [104.9834231, 20.5826364],
                [104.9833614, 20.5825526],
                [104.9833306, 20.5824541],
                [104.9833333, 20.5822901],
                [104.9833225, 20.5822295],
                [104.9832192, 20.581975],
                [104.9831959, 20.5818801],
                [104.9831825, 20.5817836],
                [104.983179, 20.5816863],
                [104.9832536, 20.5807255],
                [104.9832387, 20.5806281],
                [104.9832219, 20.5805814],
                [104.9831622, 20.580485],
                [104.9831223, 20.5804436],
                [104.9830766, 20.5804077],
                [104.9829213, 20.5803147],
                [104.982833, 20.5802324],
                [104.9827624, 20.5801301],
                [104.9826964, 20.5800041],
                [104.9825967, 20.5798756],
                [104.9824737, 20.5797664],
                [104.9823314, 20.5796799],
                [104.9822322, 20.5795813],
                [104.9821911, 20.5795255],
                [104.9821275, 20.5794037],
                [104.9820894, 20.5792615],
                [104.9820849, 20.5791149],
                [104.9821084, 20.5789991],
                [104.9821111, 20.57891],
                [104.9820825, 20.5788243],
                [104.982057, 20.5787857],
                [104.9819874, 20.578723],
                [104.9818144, 20.57863],
                [104.9817, 20.5785378],
                [104.9816052, 20.5784267],
                [104.9815664, 20.578365],
                [104.9815339, 20.5783001],
                [104.9814654, 20.5781068],
                [104.9813978, 20.5780034],
                [104.981355, 20.5779578],
                [104.9807204, 20.5774154],
                [104.9806047, 20.5772819],
                [104.9805103, 20.5771268],
                [104.9804727, 20.5770412],
                [104.980443, 20.5769529],
                [104.9803993, 20.576724],
                [104.9803787, 20.5766566],
                [104.9803455, 20.576577],
                [104.9802578, 20.5764286],
                [104.9801852, 20.576275],
                [104.980138, 20.5761053],
                [104.9801254, 20.5760147],
                [104.9801183, 20.5758063],
                [104.9801069, 20.5757486],
                [104.9800891, 20.5756924],
                [104.9800357, 20.5755881],
                [104.9799603, 20.5754965],
                [104.9795526, 20.57515],
                [104.9793518, 20.5750136],
                [104.979259, 20.5749364],
                [104.9790908, 20.5747657],
                [104.9789438, 20.5745688],
                [104.9788796, 20.5744602],
                [104.9787763, 20.5742317],
                [104.9787379, 20.5741129],
                [104.9792047, 20.5740181],
                [104.979435, 20.5739588],
                [104.9798879, 20.5738166],
                [104.9803149, 20.5736518],
                [104.9804978, 20.5735573],
                [104.9806821, 20.5734238],
                [104.9810304, 20.5730851],
                [104.9811734, 20.5729639],
                [104.9813229, 20.5728498],
                [104.9816346, 20.5726463],
                [104.9817983, 20.5725558],
                [104.9822077, 20.5723639],
                [104.982453, 20.5722635],
                [104.9827023, 20.572172],
                [104.9829551, 20.5720894],
                [104.9832112, 20.5720161],
                [104.9833496, 20.5719644],
                [104.9834846, 20.5719051],
                [104.9837422, 20.5717649],
                [104.9838473, 20.5717177],
                [104.9840675, 20.5716467],
                [104.9841813, 20.5716233],
                [104.984413, 20.5716014],
                [104.9845295, 20.571603],
                [104.9847524, 20.5716297],
                [104.9848617, 20.5716546],
                [104.9850728, 20.5717269],
                [104.9852701, 20.5718276],
                [104.9854963, 20.5719686],
                [104.9857514, 20.5721471],
                [104.9859952, 20.5723545],
                [104.9861124, 20.5724701],
                [104.9867264, 20.5731773],
                [104.9872551, 20.5737435],
                [104.9878085, 20.5742886],
                [104.988405, 20.5748287],
                [104.9887891, 20.5751553],
                [104.9889596, 20.575266],
                [104.9891484, 20.5753467],
                [104.9892478, 20.575375],
                [104.9894527, 20.5754061],
                [104.989652, 20.5754035],
                [104.9897508, 20.5753902],
                [104.989943, 20.5753403],
                [104.9901232, 20.5752604],
                [104.9905711, 20.5749943],
                [104.9907619, 20.5749104],
                [104.9909705, 20.5748452],
                [104.9911949, 20.5748024],
                [104.9913087, 20.5747909],
                [104.9916514, 20.5747346],
                [104.9919955, 20.5746872],
                [104.9926873, 20.5746193],
                [104.9930345, 20.5745989],
                [104.9933821, 20.5745875],
                [104.9937392, 20.5745851],
                [104.9940963, 20.5745923],
                [104.994453, 20.574609],
                [104.994809, 20.5746352],
                [104.9958417, 20.5747511],
                [104.9969196, 20.5748415],
                [104.9974805, 20.5748762],
                [104.9976984, 20.5748708],
                [104.9978064, 20.5748561],
                [104.998017, 20.5748034],
                [104.9982127, 20.5747225],
                [104.998305, 20.5746717],
                [104.9984759, 20.5745511],
                [104.9988913, 20.5741756],
                [104.9990027, 20.5741135],
                [104.9991189, 20.5740599],
                [104.9992395, 20.5740152],
                [104.9994785, 20.5739557],
                [104.9997127, 20.5739315],
                [104.9998306, 20.5739315],
                [105.0001054, 20.5739531],
                [105.0004211, 20.5739544],
                [105.0007202, 20.5739236],
                [105.0009995, 20.5738658],
                [105.0018409, 20.5736329],
                [105.0021905, 20.5735278],
                [105.0029252, 20.5732868],
                [105.0038305, 20.5729525],
                [105.0042661, 20.5727678],
                [105.0047686, 20.5725216],
                [105.005254, 20.5722467],
                [105.0054896, 20.5720989],
                [105.0056771, 20.5719701],
                [105.0060386, 20.5716936],
                [105.0065022, 20.5712914],
                [105.0067599, 20.5711029],
                [105.0068951, 20.5710166],
                [105.0071677, 20.5708651],
                [105.007453, 20.5707354],
                [105.0090569, 20.5699167],
                [105.0091608, 20.5698542],
                [105.0093543, 20.5697107],
                [105.0094432, 20.5696304],
                [105.0095959, 20.5694635],
                [105.0097253, 20.56928],
                [105.0097806, 20.569183],
                [105.009904, 20.5689276],
                [105.0100731, 20.568626],
                [105.0102625, 20.5683455],
                [105.0104692, 20.5680866],
                [105.0105804, 20.567963],
                [105.0108172, 20.5677375],
                [105.0109446, 20.5676336],
                [105.0112154, 20.5674447],
                [105.0124097, 20.5667274],
                [105.0131756, 20.5662975],
                [105.0143575, 20.5656694],
                [105.0151371, 20.5652255],
                [105.0159244, 20.5647432],
                [105.0167169, 20.5642211],
                [105.0174898, 20.5636737],
                [105.0200164, 20.56174],
                [105.0201454, 20.5616338],
                [105.0203897, 20.5614076],
                [105.020629, 20.561147],
                [105.0208572, 20.5608504],
                [105.0209606, 20.5606953],
                [105.0226879, 20.5616848],
                [105.0227647, 20.5617553],
                [105.0228966, 20.5619142],
                [105.0229508, 20.5620012],
                [105.0230306, 20.5621793],
                [105.0230581, 20.5622724],
                [105.0231385, 20.5627044],
                [105.024147, 20.563518],
                [105.0243025, 20.5637547],
                [105.0244857, 20.5639733],
                [105.0246942, 20.564171],
                [105.0248179, 20.5642692],
                [105.0249479, 20.5643601],
                [105.0250837, 20.5644432],
                [105.0252247, 20.5645183],
                [105.0253704, 20.564585],
                [105.0255203, 20.5646431],
                [105.0264108, 20.5649344],
                [105.0266025, 20.5649651],
                [105.0266998, 20.5649688],
                [105.0268922, 20.5649527],
                [105.0270777, 20.5649064],
                [105.0271666, 20.5648724],
                [105.0276845, 20.5645974],
                [105.0280484, 20.5644344],
                [105.0345433, 20.561961],
                [105.035305, 20.5611172],
                [105.0354516, 20.5610046],
                [105.0356161, 20.5609163],
                [105.0358115, 20.5608503],
                [105.0360165, 20.5608185],
                [105.0361204, 20.5608158],
                [105.0363286, 20.5607655],
                [105.0365339, 20.5607061],
                [105.0367361, 20.5606376],
                [105.0369346, 20.5605603],
                [105.0371289, 20.5604743],
                [105.037492, 20.5602838],
                [105.0376661, 20.5601771],
                [105.0378349, 20.560063],
                [105.0385451, 20.55953],
                [105.0391459, 20.5594195],
                [105.0398648, 20.5591885],
                [105.0400306, 20.5591865],
                [105.0401129, 20.5591966],
                [105.0402725, 20.5592387],
                [105.0403503, 20.5592712],
                [105.040494, 20.5593568],
                [105.0405585, 20.559409],
                [105.0406694, 20.55953],
                [105.0412059, 20.5604442],
                [105.0414172, 20.5604997],
                [105.041635, 20.5605245],
                [105.0418619, 20.5605173],
                [105.0420848, 20.5604767],
                [105.0428232, 20.5602227],
                [105.0431424, 20.5601227],
                [105.0437449, 20.5599556],
                [105.044049, 20.5598816],
                [105.044049, 20.5591182],
                [105.0440721, 20.5589794],
                [105.0441424, 20.5587064],
                [105.0441895, 20.558573],
                [105.0443065, 20.5583145],
                [105.0444516, 20.5580717],
                [105.0446238, 20.557845],
                [105.0447196, 20.5577386],
                [105.0449288, 20.557541],
                [105.0459695, 20.5569483],
                [105.0467527, 20.5579629],
                [105.0474822, 20.5592186],
                [105.047548, 20.5593063],
                [105.0476986, 20.5594674],
                [105.0477826, 20.5595401],
                [105.047957, 20.5596622],
                [105.0481474, 20.5597611],
                [105.0497782, 20.5603136],
                [105.0506902, 20.5604442],
                [105.051144, 20.5605828],
                [105.0517476, 20.5607523],
                [105.0523456, 20.560889],
                [105.05264, 20.5609452],
                [105.0532329, 20.5610368],
                [105.0532921, 20.5610798],
                [105.0533938, 20.5611825],
                [105.0534381, 20.5612456],
                [105.0535208, 20.561413],
                [105.0536329, 20.5616038],
                [105.0537693, 20.5617802],
                [105.0538549, 20.561871],
                [105.0540448, 20.5620352],
                [105.0541484, 20.5621078],
                [105.0543702, 20.5622322],
                [105.0544271, 20.5622737],
                [105.0544796, 20.5623199],
                [105.0545695, 20.5624252],
                [105.0546383, 20.5625478],
                [105.0546635, 20.562615],
                [105.054692, 20.5627546],
                [105.0548637, 20.5645326],
                [105.0548594, 20.565236],
                [105.0548851, 20.5659389],
                [105.0549402, 20.5666333],
                [105.0550246, 20.5673251],
                [105.055038, 20.5676879],
                [105.0550416, 20.5680509],
                [105.0550353, 20.5684139],
                [105.0549989, 20.5690718],
                [105.0549388, 20.5696606],
                [105.0549442, 20.5699669],
                [105.0549717, 20.5700273],
                [105.0550458, 20.5701385],
                [105.0551426, 20.5702331],
                [105.0552011, 20.5702747],
                [105.055264, 20.5703102],
                [105.0554002, 20.5703613],
                [105.055472, 20.5703762],
                [105.0557427, 20.5704028],
                [105.0559366, 20.5704441],
                [105.0560452, 20.5704776],
                [105.0562538, 20.5705653],
                [105.0563527, 20.5706192],
                [105.0565374, 20.5707454],
                [105.0594342, 20.5722672],
                [105.0596987, 20.5724816],
                [105.0598213, 20.5725984],
                [105.0600457, 20.5728498],
                [105.0601432, 20.5729786],
                [105.0603163, 20.5732494],
                [105.0603915, 20.5733908],
                [105.0605178, 20.5736835],
                [105.0608063, 20.5736438],
                [105.0610961, 20.5736137],
                [105.0613868, 20.5735931],
                [105.061866, 20.5735799],
                [105.0621056, 20.573583],
                [105.0654852, 20.5738743],
                [105.0657567, 20.5738925],
                [105.0663006, 20.5739145],
                [105.0666333, 20.5739184],
                [105.0674495, 20.5739046],
                [105.0677504, 20.5739298],
                [105.0680282, 20.5739832],
                [105.0681555, 20.5740179],
                [105.0684035, 20.5741053],
                [105.0706565, 20.5748386],
                [105.0709492, 20.5749474],
                [105.0715256, 20.5751851],
                [105.0720358, 20.5754231],
                [105.0725341, 20.5756823],
                [105.0746584, 20.5768274],
                [105.0766218, 20.5779122],
                [105.0768067, 20.5779727],
                [105.0769946, 20.5780247],
                [105.077185, 20.5780679],
                [105.0773498, 20.5780978],
                [105.0776827, 20.578138],
                [105.0792181, 20.5781934],
                [105.0804412, 20.5783139],
                [105.080732, 20.5782952],
                [105.081019, 20.5782478],
                [105.0813001, 20.5781718],
                [105.0815721, 20.5780679],
                [105.0818306, 20.5779373],
                [105.0840461, 20.5768776],
                [105.0843947, 20.576679],
                [105.084722, 20.5764507],
                [105.0850258, 20.5761943],
                [105.0853026, 20.5759124],
                [105.0856781, 20.5754868],
                [105.0859339, 20.5752172],
                [105.0861973, 20.5749541],
                [105.0864622, 20.5747031],
                [105.0867341, 20.5744586],
                [105.0872878, 20.57399],
                [105.0878227, 20.5735127],
                [105.0883605, 20.5729909],
                [105.0888741, 20.572448],
                [105.0900344, 20.5714102],
                [105.0906054, 20.5708823],
                [105.091665, 20.569871],
                [105.0930657, 20.5684822],
                [105.0935089, 20.5680785],
                [105.0937946, 20.5678347],
                [105.0940866, 20.5675976],
                [105.0943848, 20.5673675],
                [105.0946891, 20.5671443],
                [105.0962019, 20.5661499],
                [105.0994849, 20.5703386],
                [105.1012015, 20.5708208],
                [105.1042682, 20.572515],
                [105.1045352, 20.572671],
                [105.1048175, 20.5728015],
                [105.1051122, 20.572905],
                [105.1054336, 20.572984],
                [105.105762, 20.5730311],
                [105.1060939, 20.5730456],
                [105.1094305, 20.5675662],
                [105.1095442, 20.5673629],
                [105.1097525, 20.5669475],
                [105.1099348, 20.5665215],
                [105.1100906, 20.5660859],
                [105.1101583, 20.5658651],
                [105.1102538, 20.5654946],
                [105.1103465, 20.5652043],
                [105.1104696, 20.5649241],
                [105.1105438, 20.5647858],
                [105.1107141, 20.5645206],
                [105.1109124, 20.5642729],
                [105.1112723, 20.5639],
                [105.1115642, 20.5636272],
                [105.1118813, 20.5633801],
                [105.1122215, 20.5631604],
                [105.1125824, 20.5629699],
                [105.1127694, 20.5628863],
                [105.1131546, 20.5627434],
                [105.1133519, 20.5626843],
                [105.1139342, 20.5625327],
                [105.1142286, 20.5624686],
                [105.1148225, 20.5623642],
                [105.1151217, 20.5623239],
                [105.1159956, 20.562241],
                [105.116269, 20.5622228],
                [105.1168169, 20.5622109],
                [105.1170909, 20.5622172],
                [105.1176334, 20.5622539],
                [105.1181724, 20.5623226],
                [105.1184399, 20.5623689],
                [105.1192152, 20.5625035],
                [105.1193494, 20.5625153],
                [105.1196189, 20.5625187],
                [105.1198872, 20.5624952],
                [105.1201738, 20.5624393],
                [105.1203252, 20.5623962],
                [105.1204734, 20.5623443],
                [105.1206177, 20.5622838],
                [105.1207578, 20.562215],
                [105.1208929, 20.562138],
                [105.1210227, 20.5620532],
                [105.1214782, 20.5616948],
                [105.1216507, 20.5615692],
                [105.1220387, 20.5613153],
                [105.1234317, 20.5605245],
                [105.1238072, 20.5594446],
                [105.1238716, 20.5590428],
                [105.1239095, 20.5587182],
                [105.1239224, 20.558671],
                [105.1239682, 20.5585839],
                [105.1240003, 20.5585456],
                [105.1240885, 20.5584782],
                [105.1241943, 20.5584386],
                [105.1242507, 20.5584305],
                [105.1243078, 20.5584305],
                [105.1244187, 20.5584546],
                [105.1245168, 20.5585087],
                [105.1245582, 20.5585456],
                [105.1249444, 20.5588017],
                [105.1250176, 20.5588321],
                [105.1251714, 20.5588732],
                [105.1253307, 20.5588871],
                [105.1254182, 20.5588829],
                [105.1255896, 20.5588498],
                [105.1256718, 20.5588213],
                [105.1258247, 20.5587416],
                [105.1258939, 20.5586912],
                [105.1274335, 20.5573702],
                [105.1277407, 20.5570798],
                [105.1280665, 20.5568077],
                [105.1282748, 20.5566505],
                [105.1284892, 20.5565009],
                [105.1287096, 20.556359],
                [105.1289356, 20.556225],
                [105.1305127, 20.5555218],
                [105.1312852, 20.5554515],
                [105.1314704, 20.5554207],
                [105.1316474, 20.5553609],
                [105.1317311, 20.5553205],
                [105.131886, 20.5552204],
                [105.1319598, 20.555158],
                [105.1320878, 20.5550158],
                [105.132141, 20.5549371],
                [105.1322235, 20.5547679],
                [105.1322522, 20.5546788],
                [105.1322722, 20.5545876],
                [105.1324332, 20.5531811],
                [105.1329053, 20.5528505],
                [105.1331479, 20.5526937],
                [105.1336455, 20.5523976],
                [105.1341959, 20.5521069],
                [105.1347623, 20.5518447],
                [105.1351465, 20.5516842],
                [105.1353271, 20.5515852],
                [105.1354909, 20.5514633],
                [105.1355742, 20.5513857],
                [105.1357197, 20.5512132],
                [105.1357811, 20.5511194],
                [105.1359045, 20.5509007],
                [105.1360236, 20.5506498],
                [105.1361132, 20.5503884],
                [105.1361496, 20.5502401],
                [105.1361765, 20.55009],
                [105.1361938, 20.5499388],
                [105.1362014, 20.5497868],
                [105.1361992, 20.5496347],
                [105.1361873, 20.5494829],
                [105.1361658, 20.5493321],
                [105.1361148, 20.5490783],
                [105.1361033, 20.5489728],
                [105.1361051, 20.5487607],
                [105.1361398, 20.5485525],
                [105.136206, 20.5483524],
                [105.1363024, 20.5481634],
                [105.136427, 20.5479894],
                [105.1365772, 20.5478341],
                [105.1370032, 20.5474983],
                [105.1372096, 20.5473232],
                [105.1376199, 20.5469481],
                [105.137823, 20.5467479],
                [105.1382124, 20.546333],
                [105.1383984, 20.5461187],
                [105.1387758, 20.5456608],
                [105.1389753, 20.5454398],
                [105.1393786, 20.5450305],
                [105.1397892, 20.5446579],
                [105.1400026, 20.5444797],
                [105.1405171, 20.5440819],
                [105.1406729, 20.5439788],
                [105.1407812, 20.5439218],
                [105.1408941, 20.5438731],
                [105.1410804, 20.5438119],
                [105.1411524, 20.5438001],
                [105.1412255, 20.543798],
                [105.1412965, 20.5438053],
                [105.1413658, 20.5438218],
                [105.1414319, 20.5438472],
                [105.1421052, 20.54423],
                [105.1422017, 20.5442606],
                [105.1424012, 20.5442984],
                [105.1426046, 20.5443043],
                [105.142706, 20.5442953],
                [105.1429047, 20.5442533],
                [105.1430005, 20.5442207],
                [105.1431815, 20.5441335],
                [105.1432655, 20.5440794],
                [105.1440149, 20.5435217],
                [105.1441277, 20.5434692],
                [105.1443633, 20.5433864],
                [105.1446088, 20.5433347],
                [105.1448701, 20.5433148],
                [105.1450066, 20.5433183],
                [105.1451424, 20.5433313],
                [105.1452768, 20.5433537],
                [105.1454091, 20.5433854],
                [105.14574, 20.5434982],
                [105.1459377, 20.543579],
                [105.1461311, 20.5436685],
                [105.1463197, 20.5437665],
                [105.1465031, 20.5438729],
                [105.1466809, 20.5439872],
                [105.1468527, 20.5441094],
                [105.1471634, 20.5442953],
                [105.1473259, 20.5443768],
                [105.1476633, 20.5445161],
                [105.1478372, 20.5445735],
                [105.1481933, 20.544663],
                [105.1483745, 20.5446948],
                [105.148741, 20.5447323],
                [105.1488992, 20.5447141],
                [105.1489766, 20.544696],
                [105.1491253, 20.5446423],
                [105.1492625, 20.5445667],
                [105.1493847, 20.5444711],
                [105.1502001, 20.5436674],
                [105.1503336, 20.5435647],
                [105.1504071, 20.5435222],
                [105.1505649, 20.5434564],
                [105.1507502, 20.5434156],
                [105.1509404, 20.5434097],
                [105.1510351, 20.5434199],
                [105.1512922, 20.5434664],
                [105.1516235, 20.5435006],
                [105.1519568, 20.5435067],
                [105.1521233, 20.5434991],
                [105.152214, 20.5434838],
                [105.1523025, 20.5434594],
                [105.1523877, 20.5434264],
                [105.1524686, 20.5433851],
                [105.1525444, 20.5433358],
                [105.1526132, 20.5432801],
                [105.1526754, 20.5432179],
                [105.1527302, 20.5431498],
                [105.152777, 20.5430768],
                [105.1528154, 20.5429995],
                [105.1529331, 20.5426537],
                [105.1530497, 20.5423983],
                [105.1531935, 20.5421554],
                [105.1533684, 20.5419207],
                [105.1535685, 20.5417044],
                [105.1536774, 20.541604],
                [105.1539113, 20.5414198],
                [105.1542095, 20.541212],
                [105.1543781, 20.5410814],
                [105.1546986, 20.5408024],
                [105.1548501, 20.5406544],
                [105.1551343, 20.5403429],
                [105.1552667, 20.5401797],
                [105.1555109, 20.5398396],
                [105.157662, 20.5373831],
                [105.1577975, 20.5372481],
                [105.1578724, 20.5371875],
                [105.1580349, 20.5370817],
                [105.1581261, 20.5370349],
                [105.1583184, 20.5369606],
                [105.1584184, 20.5369335],
                [105.1602799, 20.5365643],
                [105.1617014, 20.5359213],
                [105.161822, 20.5358443],
                [105.16192, 20.5357431],
                [105.16199, 20.535624],
                [105.1620286, 20.5354938],
                [105.1621842, 20.533289],
                [105.1624047, 20.5327094],
                [105.1625913, 20.5321196],
                [105.1627435, 20.5315211],
                [105.1628065, 20.5312192],
                [105.1629068, 20.5306066],
                [105.1629435, 20.5302986],
                [105.1629899, 20.5296803],
                [105.1630068, 20.5290036],
                [105.1630038, 20.5286366],
                [105.1629907, 20.5282699],
                [105.1629372, 20.527568],
                [105.1628512, 20.5268996],
                [105.1627672, 20.5264006],
                [105.1626823, 20.5260523],
                [105.162624, 20.5258714],
                [105.1625564, 20.5256934],
                [105.1624799, 20.5255185],
                [105.1623946, 20.5253473],
                [105.1623006, 20.52518],
                [105.1621982, 20.5250172],
                [105.1619119, 20.5246279],
                [105.1618323, 20.5245071],
                [105.1616907, 20.5242562],
                [105.1616168, 20.5240992],
                [105.1615519, 20.5239388],
                [105.1614962, 20.5237753],
                [105.16145, 20.5236093],
                [105.1614133, 20.5234412],
                [105.1613862, 20.5232715],
                [105.1613688, 20.5231007],
                [105.1623344, 20.523161],
                [105.1625016, 20.5231507],
                [105.1626677, 20.5231311],
                [105.1628323, 20.523102],
                [105.1629948, 20.5230637],
                [105.1631544, 20.5230164],
                [105.1634516, 20.5229003],
                [105.1637216, 20.5227593],
                [105.1639731, 20.5225911],
                [105.164091, 20.5224974],
                [105.1643092, 20.5222923],
                [105.1644088, 20.5221816],
                [105.1654512, 20.5208048],
                [105.166567, 20.5202069],
                [105.1667392, 20.5201243],
                [105.1669069, 20.5200339],
                [105.1670695, 20.5199357],
                [105.1672268, 20.5198301],
                [105.1674947, 20.5196223],
                [105.1679351, 20.5192112],
                [105.1681495, 20.5190514],
                [105.1683959, 20.5189087],
                [105.1686591, 20.5187951],
                [105.1687895, 20.5187603],
                [105.1688513, 20.518734],
                [105.1689649, 20.5186645],
                [105.1690717, 20.5185633],
                [105.1691153, 20.518505],
                [105.1691803, 20.5183764],
                [105.1693046, 20.5178418],
                [105.1694384, 20.5173826],
                [105.1696018, 20.5169318],
                [105.1696944, 20.5167101],
                [105.1698939, 20.5162893],
                [105.1701194, 20.5158802],
                [105.1703703, 20.5154842],
                [105.1704788, 20.5153806],
                [105.1705404, 20.5153366],
                [105.1706788, 20.515265],
                [105.1708324, 20.5152196],
                [105.1709121, 20.5152079],
                [105.1711649, 20.5151424],
                [105.171294, 20.5151205],
                [105.1715552, 20.5150989],
                [105.1716864, 20.5150993],
                [105.1719475, 20.5151225],
                [105.1720895, 20.5151479],
                [105.1722294, 20.5151821],
                [105.1723666, 20.515225],
                [105.1725003, 20.5152765],
                [105.1726301, 20.5153361],
                [105.1727554, 20.5154038],
                [105.1729657, 20.5155449],
                [105.1731321, 20.5156913],
                [105.1733966, 20.5159892],
                [105.1736519, 20.5162407],
                [105.173788, 20.5163585],
                [105.1740758, 20.516577],
                [105.17802, 20.5165896],
                [105.1782841, 20.5168725],
                [105.1785151, 20.5171666],
                [105.1787137, 20.5174683],
                [105.1789904, 20.5179385],
                [105.1791852, 20.5182485],
                [105.1795971, 20.5188554],
                [105.1810884, 20.518971],
                [105.1812997, 20.5190082],
                [105.1815092, 20.5190537],
                [105.1819209, 20.5191695],
                [105.1823208, 20.5193174],
                [105.1827026, 20.5194947],
                [105.1830641, 20.5196994],
                [105.1834062, 20.5199315],
                [105.1837266, 20.5201894],
                [105.1839739, 20.520414],
                [105.1840641, 20.5205058],
                [105.1842256, 20.5207041],
                [105.1843595, 20.5209183],
                [105.1844642, 20.521145],
                [105.1845054, 20.5212624],
                [105.1845646, 20.5215031],
                [105.1847418, 20.5214477],
                [105.1849156, 20.5213836],
                [105.1850856, 20.5213111],
                [105.1852513, 20.5212303],
                [105.1855551, 20.521053],
                [105.1858264, 20.5208575],
                [105.1860764, 20.5206385],
                [105.1861926, 20.5205207],
                [105.1863027, 20.5203978],
                [105.1865825, 20.5200444],
                [105.1868901, 20.5197117],
                [105.1872135, 20.5194104],
                [105.1875488, 20.5191401],
                [105.187724, 20.5190134],
                [105.1880882, 20.518778],
                [105.1885119, 20.5185346],
                [105.1887521, 20.5184076],
                [105.188997, 20.5182889],
                [105.1892464, 20.5181785],
                [105.1897374, 20.5179903],
                [105.190221, 20.51784],
                [105.1904665, 20.5177762],
                [105.1909637, 20.5176719],
                [105.1914673, 20.5175989],
                [105.1920075, 20.5175452],
                [105.192583, 20.5175098],
                [105.1928714, 20.5175036],
                [105.1934481, 20.517514],
                [105.1940425, 20.5175569],
                [105.1946332, 20.5176322],
                [105.1949266, 20.5176819],
                [105.195508, 20.5178054],
                [105.2014518, 20.5187499],
                [105.2016775, 20.518762],
                [105.2021297, 20.5187595],
                [105.2025801, 20.5187217],
                [105.2029914, 20.5186557],
                [105.2033626, 20.5185693],
                [105.2036365, 20.5184894],
                [105.2038653, 20.5184028],
                [105.2040002, 20.5183388],
                [105.2041304, 20.5182669],
                [105.2042555, 20.5181872],
                [105.2043749, 20.5181003],
                [105.204488, 20.5180064],
                [105.2047256, 20.5178447],
                [105.2052149, 20.5175406],
                [105.2054663, 20.5173983],
                [105.2059561, 20.5171462],
                [105.2065532, 20.5168761],
                [105.2066767, 20.5168307],
                [105.2069042, 20.5167724],
                [105.2070206, 20.5167542],
                [105.2073741, 20.5167273],
                [105.2074907, 20.5167061],
                [105.2077174, 20.5166398],
                [105.2078323, 20.5165923],
                [105.2079429, 20.5165364],
                [105.2080485, 20.5164727],
                [105.2081485, 20.5164013],
                [105.2082421, 20.5163229],
                [105.2084126, 20.516152],
                [105.2085987, 20.5159982],
                [105.2088064, 20.5158711],
                [105.2090112, 20.5157806],
                [105.2092269, 20.515716],
                [105.2094498, 20.5156785],
                [105.2119553, 20.5154189],
                [105.2128887, 20.5140172],
                [105.2129552, 20.5138807],
                [105.2130122, 20.5137405],
                [105.2130596, 20.5135973],
                [105.2130972, 20.5134515],
                [105.2131247, 20.5133037],
                [105.2131488, 20.5130387],
                [105.2131488, 20.5129056],
                [105.2131247, 20.5126405],
                [105.2131398, 20.5124477],
                [105.2131592, 20.5123526],
                [105.2132213, 20.5121682],
                [105.213319, 20.5119858],
                [105.2134472, 20.5118208],
                [105.2135217, 20.5117462],
                [105.2136733, 20.5116741],
                [105.213837, 20.5116308],
                [105.214014, 20.5116182],
                [105.2141063, 20.511625],
                [105.2141974, 20.5116409],
                [105.2142861, 20.5116658],
                [105.2150506, 20.5119019],
                [105.2150435, 20.5116269],
                [105.2150745, 20.5113534],
                [105.2151389, 20.511099],
                [105.2152305, 20.5108664],
                [105.2152871, 20.5107543],
                [105.2154207, 20.5105403],
                [105.2157001, 20.5101989],
                [105.2160046, 20.5098768],
                [105.2163327, 20.5095757],
                [105.2166962, 20.509287],
                [105.2170814, 20.5090242],
                [105.2174862, 20.5087885],
                [105.218121, 20.5084566],
                [105.2185321, 20.508209],
                [105.2189158, 20.5079477],
                [105.2192831, 20.5076664],
                [105.2196556, 20.5073992],
                [105.2200448, 20.5071539],
                [105.2218258, 20.505938],
                [105.2220256, 20.5058103],
                [105.2222307, 20.5056903],
                [105.2224408, 20.505578],
                [105.2226555, 20.5054738],
                [105.2228746, 20.5053777],
                [105.2232623, 20.5052314],
                [105.22346, 20.5051678],
                [105.2238618, 20.50506],
                [105.2240655, 20.505016],
                [105.2244055, 20.5049631],
                [105.2247488, 20.5049352],
                [105.2249211, 20.5049305],
                [105.2257955, 20.505325],
                [105.2260836, 20.505395],
                [105.2263547, 20.5054368],
                [105.2266055, 20.5054556],
                [105.2274531, 20.5054506],
                [105.2300978, 20.5042246],
                [105.2317393, 20.5031292],
                [105.230205, 20.4995668],
                [105.2301588, 20.4994411],
                [105.2301219, 20.4993128],
                [105.2300946, 20.4991824],
                [105.2300769, 20.4990505],
                [105.2300691, 20.4989178],
                [105.2300711, 20.4987849],
                [105.2300829, 20.4986524],
                [105.2301045, 20.4985211],
                [105.2301357, 20.4983914],
                [105.2301765, 20.4982641],
                [105.2302265, 20.4981398],
                [105.2302866, 20.4980171],
                [105.2303557, 20.4978987],
                [105.2304336, 20.4977851],
                [105.2305198, 20.497677],
                [105.2306139, 20.4975748],
                [105.2307154, 20.497479],
                [105.2308238, 20.4973902],
                [105.2309387, 20.4973087],
                [105.2310594, 20.497235],
                [105.2311853, 20.4971694],
                [105.2313158, 20.4971122],
                [105.2314503, 20.4970638],
                [105.2315881, 20.4970244],
                [105.2335095, 20.4965921],
                [105.2342069, 20.4957881],
                [105.2345678, 20.4955173],
                [105.2347397, 20.4953723],
                [105.2349056, 20.4952214],
                [105.2352004, 20.4949218],
                [105.2354549, 20.494624],
                [105.235574, 20.4944693],
                [105.2357326, 20.4942385],
                [105.2358459, 20.4941092],
                [105.2359859, 20.4940053],
                [105.2361461, 20.4939314],
                [105.2363118, 20.493892],
                [105.236397, 20.4938842],
                [105.2365677, 20.4938933],
                [105.2367329, 20.4939344],
                [105.2368113, 20.4939666],
                [105.2368992, 20.4940191],
                [105.2369921, 20.4940635],
                [105.2370889, 20.4940995],
                [105.237189, 20.4941268],
                [105.2372914, 20.494145],
                [105.2374826, 20.4941545],
                [105.2376726, 20.4941328],
                [105.2377654, 20.4941104],
                [105.2378557, 20.4940805],
                [105.2380264, 20.4939993],
                [105.2382157, 20.4939269],
                [105.2384135, 20.4938785],
                [105.2385145, 20.4938636],
                [105.2387517, 20.4938532],
                [105.2388702, 20.4938608],
                [105.2389878, 20.4938768],
                [105.2392173, 20.493934],
                [105.2397752, 20.4940395],
                [105.2399306, 20.4940549],
                [105.2400861, 20.4940392],
                [105.2401615, 20.4940197],
                [105.2403037, 20.4939589],
                [105.2403688, 20.4939181],
                [105.2404833, 20.4938184],
                [105.2405323, 20.4937593],
                [105.2406083, 20.4936279],
                [105.2406521, 20.4934842],
                [105.2406617, 20.493335],
                [105.2406366, 20.4931874],
                [105.240578, 20.4930484],
                [105.2401292, 20.4923009],
                [105.2401032, 20.4922313],
                [105.240077, 20.4920861],
                [105.2400863, 20.4919391],
                [105.2401263, 20.4918075],
                [105.2401571, 20.4917454],
                [105.2402387, 20.491632],
                [105.2403438, 20.4915371],
                [105.2410519, 20.491115],
                [105.2411857, 20.4910498],
                [105.2412573, 20.4910275],
                [105.241406, 20.4910044],
                [105.241561, 20.4910115],
                [105.2417107, 20.4910496],
                [105.2418956, 20.4911312],
                [105.2419569, 20.4911467],
                [105.2420831, 20.4911562],
                [105.2422116, 20.4911359],
                [105.2422744, 20.4911138],
                [105.2423883, 20.491048],
                [105.2424805, 20.490957],
                [105.2426536, 20.490702],
                [105.2427991, 20.4905052],
                [105.2429527, 20.490314],
                [105.2431143, 20.4901285],
                [105.2432835, 20.4899492],
                [105.243457, 20.4897791],
                [105.2436373, 20.4896154],
                [105.2438243, 20.4894584],
                [105.2440176, 20.4893083],
                [105.2443117, 20.4890966],
                [105.2444852, 20.4889422],
                [105.2446348, 20.4887671],
                [105.2446997, 20.4886728],
                [105.244763, 20.4885646],
                [105.2448176, 20.4884523],
                [105.244863, 20.4883365],
                [105.244899, 20.4882178],
                [105.2449255, 20.488097],
                [105.2449423, 20.4879746],
                [105.2449493, 20.4878514],
                [105.2449343, 20.4875345],
                [105.2449128, 20.4873416],
                [105.244842, 20.4869595],
                [105.2447929, 20.4867711],
                [105.2446675, 20.4864015],
                [105.2437556, 20.484311],
                [105.243704, 20.4842171],
                [105.2435803, 20.4840408],
                [105.2435088, 20.4839592],
                [105.2433517, 20.483814],
                [105.2431746, 20.4836904],
                [105.2428891, 20.4835244],
                [105.2427034, 20.4834042],
                [105.2425229, 20.4832774],
                [105.2421784, 20.4830044],
                [105.2420658, 20.4828968],
                [105.2419769, 20.4827711],
                [105.2419167, 20.4826372],
                [105.2418844, 20.4825008],
                [105.2418736, 20.4822871],
                [105.2418467, 20.4821409],
                [105.2417951, 20.4819977],
                [105.2417189, 20.4818614],
                [105.2416723, 20.4817979],
                [105.2396572, 20.4794716],
                [105.2398272, 20.4793074],
                [105.2399198, 20.4792328],
                [105.2401185, 20.4790997],
                [105.2403271, 20.4789925],
                [105.2406528, 20.4788621],
                [105.2407533, 20.4788072],
                [105.2408484, 20.4787444],
                [105.2409374, 20.4786742],
                [105.2410197, 20.4785972],
                [105.2410675, 20.4785416],
                [105.2411453, 20.4784191],
                [105.2411972, 20.4782851],
                [105.2412215, 20.4781424],
                [105.2412226, 20.4780691],
                [105.241202, 20.4779239],
                [105.2411806, 20.4778534],
                [105.2409883, 20.4774013],
                [105.2408802, 20.47718],
                [105.2406647, 20.4767882],
                [105.2405476, 20.4765971],
                [105.2405262, 20.4765234],
                [105.24051, 20.4763717],
                [105.2405155, 20.4762955],
                [105.2405525, 20.4761493],
                [105.2406227, 20.4760141],
                [105.2407595, 20.475833],
                [105.2409215, 20.4756712],
                [105.2410109, 20.4755985],
                [105.241216, 20.4754648],
                [105.2413316, 20.4754061],
                [105.2414516, 20.4753558],
                [105.2415894, 20.4753487],
                [105.2416581, 20.4753558],
                [105.2417972, 20.475393],
                [105.2419236, 20.4754588],
                [105.2421868, 20.4756766],
                [105.2423257, 20.4757773],
                [105.2426168, 20.4759613],
                [105.2429329, 20.4761252],
                [105.2432728, 20.4762654],
                [105.2433653, 20.4762767],
                [105.2435517, 20.4762754],
                [105.2437276, 20.4762444],
                [105.243895, 20.476185],
                [105.2451718, 20.4751397],
                [105.2445388, 20.4742552],
                [105.2444864, 20.4741405],
                [105.2444702, 20.4740799],
                [105.2444588, 20.4739556],
                [105.2444637, 20.4738933],
                [105.2445017, 20.4737537],
                [105.2445341, 20.4736882],
                [105.2446232, 20.4735707],
                [105.2446786, 20.4735205],
                [105.245461, 20.4730183],
                [105.2461344, 20.4726232],
                [105.2468259, 20.4722566],
                [105.2471781, 20.4720841],
                [105.2475577, 20.4719085],
                [105.2479418, 20.4717414],
                [105.2483299, 20.4715828],
                [105.248722, 20.4714329],
                [105.2491177, 20.4712917],
                [105.2495169, 20.4711594],
                [105.2511048, 20.4707071],
                [105.2525321, 20.4705221],
                [105.2527322, 20.4704742],
                [105.2528292, 20.4704407],
                [105.2530146, 20.4703553],
                [105.2531819, 20.4702495],
                [105.2533326, 20.4701236],
                [105.2535515, 20.4699056],
                [105.2537221, 20.4697777],
                [105.2538139, 20.4697221],
                [105.2540271, 20.4696214],
                [105.254139, 20.4695824],
                [105.254459, 20.4694928],
                [105.2546517, 20.4694047],
                [105.2547419, 20.4693502],
                [105.2548328, 20.4692847],
                [105.2549175, 20.4692122],
                [105.2549953, 20.4691332],
                [105.2550657, 20.4690483],
                [105.2551756, 20.4688875],
                [105.2552311, 20.4688222],
                [105.2552941, 20.4687632],
                [105.2553638, 20.4687111],
                [105.2554393, 20.4686667],
                [105.2555822, 20.468609],
                [105.2557343, 20.4685784],
                [105.255812, 20.4685737],
                [105.256598, 20.4685863],
                [105.2568872, 20.4685332],
                [105.2571666, 20.4684455],
                [105.257404, 20.4683393],
                [105.2575174, 20.4682767],
                [105.2577317, 20.4681336],
                [105.2578318, 20.4680535],
                [105.2584809, 20.4674756],
                [105.2586597, 20.4673604],
                [105.2587562, 20.4673136],
                [105.2588564, 20.4672745],
                [105.2590351, 20.4672261],
                [105.2592194, 20.467202],
                [105.2594673, 20.4671883],
                [105.2596213, 20.4671697],
                [105.2599252, 20.4671097],
                [105.2603134, 20.4670113],
                [105.2605435, 20.4669271],
                [105.2606539, 20.4668753],
                [105.2608741, 20.466746],
                [105.260982, 20.4666679],
                [105.2611797, 20.4664919],
                [105.2612685, 20.4663949],
                [105.2613502, 20.4662925],
                [105.2615681, 20.4659563],
                [105.2616522, 20.4658431],
                [105.2617426, 20.4657343],
                [105.2619409, 20.4655306],
                [105.2620142, 20.4654443],
                [105.2620796, 20.4653526],
                [105.2621365, 20.4652561],
                [105.2621848, 20.4651556],
                [105.2622239, 20.4650516],
                [105.2622536, 20.4649449],
                [105.2622738, 20.4648363],
                [105.2622843, 20.4647265],
                [105.2622772, 20.4646424],
                [105.2622803, 20.4645581],
                [105.2622933, 20.4644746],
                [105.2623162, 20.464393],
                [105.262385, 20.4642479],
                [105.2624778, 20.4641267],
                [105.2625333, 20.4640731],
                [105.2625941, 20.464025],
                [105.262854, 20.4638558],
                [105.2629445, 20.4637843],
                [105.2631106, 20.4636263],
                [105.2631855, 20.4635403],
                [105.2633283, 20.4633394],
                [105.2633886, 20.4632326],
                [105.2634856, 20.4630089],
                [105.263522, 20.462893],
                [105.2635694, 20.4626557],
                [105.2635802, 20.4625353],
                [105.2637648, 20.4590874],
                [105.2637666, 20.4588909],
                [105.2637451, 20.4584985],
                [105.2637219, 20.4583033],
                [105.2636878, 20.4580945],
                [105.2636442, 20.4578873],
                [105.2634638, 20.4572421],
                [105.2634279, 20.4569998],
                [105.2634215, 20.4567553],
                [105.2634312, 20.4566149],
                [105.2634507, 20.4564753],
                [105.2634799, 20.4563373],
                [105.2635186, 20.4562014],
                [105.2635666, 20.4560681],
                [105.2636239, 20.455938],
                [105.26369, 20.4558117],
                [105.2637648, 20.4556897],
                [105.2639014, 20.4555201],
                [105.2640636, 20.4553715],
                [105.2641531, 20.4553062],
                [105.2642476, 20.4552474],
                [105.264354, 20.4551944],
                [105.2644646, 20.4551496],
                [105.2645787, 20.4551131],
                [105.2646955, 20.4550853],
                [105.2648143, 20.4550664],
                [105.2650518, 20.4550553],
                [105.2652852, 20.4550789],
                [105.2655119, 20.4551362],
                [105.2657855, 20.4552358],
                [105.2659533, 20.4552857],
                [105.266124, 20.4553264],
                [105.2664556, 20.4553786],
                [105.2666154, 20.4553913],
                [105.2669359, 20.4553934],
                [105.2672548, 20.4553642],
                [105.2674127, 20.4553379],
                [105.2674356, 20.455205],
                [105.2674556, 20.4549367],
                [105.2674525, 20.4548021],
                [105.2674203, 20.4545348],
                [105.2673912, 20.454403],
                [105.2673116, 20.4541554],
                [105.2672017, 20.4539181],
                [105.2671358, 20.4538044],
                [105.2669835, 20.4535888],
                [105.2659911, 20.4527443],
                [105.2657613, 20.4525752],
                [105.2655547, 20.4523816],
                [105.265461, 20.4522765],
                [105.2652932, 20.4520493],
                [105.2651547, 20.4518025],
                [105.2650492, 20.4515418],
                [105.2650094, 20.4514074],
                [105.2648882, 20.4506334],
                [105.2647987, 20.4498556],
                [105.264766, 20.4494656],
                [105.2647038, 20.4483443],
                [105.2646768, 20.4479794],
                [105.2645914, 20.4471208],
                [105.2645373, 20.4466926],
                [105.2645374, 20.4452892],
                [105.2645129, 20.4448555],
                [105.2644883, 20.4446395],
                [105.2644147, 20.4442107],
                [105.2643118, 20.4437988],
                [105.2641826, 20.4434052],
                [105.2640254, 20.4430206],
                [105.2639365, 20.4428323],
                [105.2634643, 20.4419531],
                [105.2629636, 20.4410879],
                [105.2627027, 20.4406608],
                [105.262179, 20.4398465],
                [105.2619076, 20.439445],
                [105.2616298, 20.4390472],
                [105.261502, 20.4388813],
                [105.2613823, 20.4387102],
                [105.2612711, 20.4385341],
                [105.2611685, 20.4383535],
                [105.2609984, 20.4379998],
                [105.2609257, 20.4378182],
                [105.2607179, 20.4371421],
                [105.2590227, 20.4349806],
                [105.2584219, 20.4343874],
                [105.2591309, 20.4338965],
                [105.2594938, 20.433662],
                [105.2602353, 20.4332152],
                [105.2606137, 20.4330032],
                [105.2609968, 20.4327989],
                [105.2614003, 20.4325947],
                [105.2618087, 20.432399],
                [105.2622216, 20.4322119],
                [105.2626389, 20.4320337],
                [105.2630604, 20.4318642],
                [105.2634858, 20.4317037],
                [105.263915, 20.4315522],
                [105.2644987, 20.4313771],
                [105.2650922, 20.4312339],
                [105.2656935, 20.4311229],
                [105.2662691, 20.4310432],
                [105.2664025, 20.4310108],
                [105.2665329, 20.4309691],
                [105.2667531, 20.430874],
                [105.2669582, 20.4307527],
                [105.2671444, 20.4306072],
                [105.2672487, 20.4304986],
                [105.2673604, 20.4303966],
                [105.2674789, 20.4303017],
                [105.2676038, 20.4302142],
                [105.2677345, 20.4301346],
                [105.2680026, 20.4300037],
                [105.2682862, 20.4299056],
                [105.2685807, 20.4298419],
                [105.2688815, 20.4298136],
                [105.2700305, 20.429833],
                [105.2702097, 20.4298213],
                [105.2702979, 20.4298053],
                [105.2703845, 20.4297827],
                [105.2705404, 20.429723],
                [105.2708758, 20.4295375],
                [105.2710712, 20.4294409],
                [105.2714443, 20.4292829],
                [105.2718296, 20.4291531],
                [105.2721617, 20.429066],
                [105.2722991, 20.4290401],
                [105.2725774, 20.4290098],
                [105.2728575, 20.4290086],
                [105.2730157, 20.4290208],
                [105.2731728, 20.4290423],
                [105.2733281, 20.429073],
                [105.2734811, 20.4291129],
                [105.273631, 20.4291616],
                [105.2737774, 20.4292191],
                [105.2739197, 20.429285],
                [105.2742786, 20.4294214],
                [105.2746405, 20.4295335],
                [105.2750033, 20.4296219],
                [105.2751667, 20.4296906],
                [105.2753259, 20.4297678],
                [105.2754802, 20.429853],
                [105.2756293, 20.4299461],
                [105.2757727, 20.4300468],
                [105.2760338, 20.4302633],
                [105.2761514, 20.4303778],
                [105.2763668, 20.4306234],
                [105.2766468, 20.4310169],
                [105.2767455, 20.4311414],
                [105.2769586, 20.4313788],
                [105.2769992, 20.4314005],
                [105.277089, 20.4314235],
                [105.2771819, 20.4314172],
                [105.2772263, 20.4314032],
                [105.2773043, 20.4313553],
                [105.2773358, 20.4313228],
                [105.2774203, 20.4311874],
                [105.2775139, 20.4309791],
                [105.2775575, 20.4308165],
                [105.277589, 20.4307378],
                [105.2776847, 20.4305662],
                [105.2778103, 20.4304124],
                [105.2779622, 20.430281],
                [105.2781359, 20.430176],
                [105.2783262, 20.4301003],
                [105.2793217, 20.4298431],
                [105.2795486, 20.429754],
                [105.2797596, 20.4296354],
                [105.2799502, 20.4294897],
                [105.2801165, 20.4293197],
                [105.2802551, 20.4291292],
                [105.2803659, 20.4289152],
                [105.2804083, 20.428803],
                [105.2804659, 20.4285713],
                [105.2804807, 20.4284531],
                [105.2804822, 20.4282152],
                [105.2804688, 20.4280968],
                [105.2804461, 20.4279797],
                [105.2803731, 20.4277518],
                [105.2803461, 20.4276188],
                [105.2803192, 20.4273493],
                [105.2803294, 20.4270739],
                [105.2803491, 20.4269348],
                [105.2803784, 20.4267973],
                [105.2804174, 20.4266618],
                [105.2804658, 20.426529],
                [105.2806216, 20.4261991],
                [105.2807292, 20.4260029],
                [105.280846, 20.4258114],
                [105.2809717, 20.4256249],
                [105.2811061, 20.4254438],
                [105.2812489, 20.4252684],
                [105.2816199, 20.4249172],
                [105.2823472, 20.4242315],
                [105.2828668, 20.4239152],
                [105.2836835, 20.4234232],
                [105.2841289, 20.4231771],
                [105.2846859, 20.422966],
                [105.2853914, 20.4227195],
                [105.2857998, 20.4225436],
                [105.2863195, 20.4222274],
                [105.2868763, 20.421911],
                [105.2873958, 20.4215245],
                [105.2879154, 20.4211732],
                [105.288398, 20.4209271],
                [105.2889918, 20.4205405],
                [105.2894741, 20.4201192],
                [105.2897708, 20.419768],
                [105.290216, 20.4194168],
                [105.2907728, 20.4191004],
                [105.2916637, 20.4186433],
                [105.2925548, 20.4182563],
                [105.2934087, 20.4178694],
                [105.2941512, 20.4175528],
                [105.2945965, 20.4172716],
                [105.2951022, 20.4170301],
                [105.2956221, 20.4169241],
                [105.296453, 20.4166918],
                [105.2993329, 20.4164376],
                [105.3003834, 20.4165124],
                [105.301199, 20.4177643],
                [105.3015872, 20.4185374],
                [105.3020144, 20.4192002],
                [105.302286, 20.4197892],
                [105.3025188, 20.4202677],
                [105.3027258, 20.4207705],
                [105.3029494, 20.4211908],
                [105.3061005, 20.4172941],
                [105.3063969, 20.4168028],
                [105.3067304, 20.4163816],
                [105.3072497, 20.4158899],
                [105.3076949, 20.4155386],
                [105.3083891, 20.4150245],
                [105.3091683, 20.4144973],
                [105.3101702, 20.4137945],
                [105.3110978, 20.4131619],
                [105.311877, 20.4125647],
                [105.3123963, 20.412143],
                [105.3132499, 20.4116509],
                [105.3137693, 20.4112644],
                [105.3146601, 20.4107721],
                [105.3154764, 20.4102098],
                [105.316107, 20.4097179],
                [105.3163664, 20.4093318],
                [105.3165512, 20.4088407],
                [105.3166618, 20.4084198],
                [105.3169583, 20.4079985],
                [105.3173664, 20.4077174],
                [105.3182943, 20.407225],
                [105.3193585, 20.4070236],
                [105.3202495, 20.4066715],
                [105.3209547, 20.4063198],
                [105.3215487, 20.4060735],
                [105.3221793, 20.4056165],
                [105.3226243, 20.4051601],
                [105.3229949, 20.4047387],
                [105.3231428, 20.4043527],
                [105.3232539, 20.4041772],
                [105.3235508, 20.4040014],
                [105.3239225, 20.4041061],
                [105.3245176, 20.4044206],
                [105.3251869, 20.4047351],
                [105.3258931, 20.4048391],
                [105.3264477, 20.4047004],
                [105.3268561, 20.4045244],
                [105.3272643, 20.4042783],
                [105.3275611, 20.4040674],
                [105.3279693, 20.4038563],
                [105.3283406, 20.4037155],
                [105.3285258, 20.4034698],
                [105.3285996, 20.4032592],
                [105.3285985, 20.4026983],
                [105.3287091, 20.4022774],
                [105.3289316, 20.4019965],
                [105.3291913, 20.4018908],
                [105.3295629, 20.4018902],
                [105.3300463, 20.4020998],
                [105.3304184, 20.4023797],
                [105.331385, 20.4027638],
                [105.3316451, 20.4027633],
                [105.3319214, 20.4026394],
                [105.3320326, 20.4025339],
                [105.3321066, 20.4023936],
                [105.3321433, 20.4021481],
                [105.3321797, 20.4017624],
                [105.3323278, 20.4015166],
                [105.3328097, 20.401025],
                [105.3331807, 20.4007438],
                [105.333589, 20.4005678],
                [105.3341826, 20.4001812],
                [105.3349246, 20.3996891],
                [105.335481, 20.3992674],
                [105.335889, 20.3989161],
                [105.3362229, 20.3987051],
                [105.3365567, 20.3984591],
                [105.3368537, 20.3983184],
                [105.3371508, 20.3982477],
                [105.3374479, 20.3982472],
                [105.3378939, 20.3983516],
                [105.3382285, 20.3984211],
                [105.3387857, 20.3984202],
                [105.3390826, 20.3982794],
                [105.3391567, 20.3981391],
                [105.3391933, 20.3978936],
                [105.339193, 20.3977884],
                [105.3392667, 20.3974727],
                [105.3396376, 20.3971565],
                [105.3400459, 20.3970156],
                [105.3418291, 20.3970124],
                [105.3420147, 20.396942],
                [105.3423486, 20.3967311],
                [105.342571, 20.3964853],
                [105.3430891, 20.3955026],
                [105.3436078, 20.3948005],
                [105.3438295, 20.3942742],
                [105.3441257, 20.3937828],
                [105.3443848, 20.3933615],
                [105.344644, 20.3929753],
                [105.3447548, 20.3926246],
                [105.3447539, 20.3922038],
                [105.3448272, 20.3917479],
                [105.3449748, 20.3912568],
                [105.3451228, 20.391011],
                [105.3453745, 20.3908402],
                [105.3458944, 20.3907342],
                [105.34686, 20.3905922],
                [105.3477514, 20.3905206],
                [105.3483457, 20.3904494],
                [105.3490512, 20.3903078],
                [105.3497936, 20.3900611],
                [105.3505361, 20.3898494],
                [105.3510557, 20.3896381],
                [105.3516127, 20.3894968],
                [105.352207, 20.3894958],
                [105.3526899, 20.3894599],
                [105.3532846, 20.389564],
                [105.3539537, 20.3898433],
                [105.3544377, 20.3902982],
                [105.354848, 20.3911039],
                [105.3552951, 20.3916992],
                [105.3557789, 20.392049],
                [105.3563364, 20.3921881],
                [105.3567447, 20.3920822],
                [105.3574873, 20.3918706],
                [105.3581553, 20.3915888],
                [105.3588234, 20.391272],
                [105.3597337, 20.3907851],
                [105.3603274, 20.3904685],
                [105.3610696, 20.3901164],
                [105.361738, 20.3899398],
                [105.3624435, 20.3898334],
                [105.3630379, 20.3898323],
                [105.3640778, 20.3897603],
                [105.3647835, 20.3896889],
                [105.3653407, 20.3896878],
                [105.3659721, 20.3896165],
                [105.3665658, 20.3892999],
                [105.3669365, 20.3889835],
                [105.3670845, 20.3887028],
                [105.3673805, 20.3881763],
                [105.3677876, 20.3875093],
                [105.3680462, 20.3868427],
                [105.3682306, 20.3862814],
                [105.3684897, 20.3858601],
                [105.3688232, 20.3854738],
                [105.3693424, 20.3851222],
                [105.3698991, 20.3848406],
                [105.3704928, 20.384594],
                [105.3710868, 20.3844176],
                [105.3716438, 20.3843465],
                [105.3723497, 20.384345],
                [105.3730924, 20.3843086],
                [105.3753213, 20.3843043],
                [105.3759898, 20.384268],
                [105.376829, 20.3843035],
                [105.3774234, 20.3843024],
                [105.3782401, 20.3840905],
                [105.3787593, 20.3837038],
                [105.3791299, 20.3833525],
                [105.379463, 20.3828259],
                [105.3801286, 20.3814922],
                [105.3804886, 20.3811118],
                [105.3811934, 20.3806896],
                [105.3815647, 20.3805837],
                [105.3820843, 20.3804426],
                [105.3824929, 20.3804066],
                [105.3834207, 20.3800542],
                [105.3840883, 20.3796321],
                [105.384793, 20.3791749],
                [105.3857577, 20.3786822],
                [105.3864258, 20.3784705],
                [105.387391, 20.378223],
                [105.3880963, 20.3780464],
                [105.3889133, 20.3779396],
                [105.3907696, 20.3775502],
                [105.391735, 20.3773729],
                [105.3934056, 20.3769838],
                [105.3945564, 20.376701],
                [105.3957814, 20.3763829],
                [105.3965237, 20.376136],
                [105.3970434, 20.3759946],
                [105.3976375, 20.3758883],
                [105.3984172, 20.3757465],
                [105.3993827, 20.3756392],
                [105.3999768, 20.3755329],
                [105.4004962, 20.3753214],
                [105.4010899, 20.3750747],
                [105.4023514, 20.3744761],
                [105.4028832, 20.3741344],
                [105.4039591, 20.3736062],
                [105.4056289, 20.3729014],
                [105.4061487, 20.3727951],
                [105.4066642, 20.3729059],
                [105.4089923, 20.373464],
                [105.4095216, 20.3735909],
                [105.4108801, 20.3739169],
                [105.4127356, 20.3745497],
                [105.4149443, 20.3754496],
                [105.4178316, 20.3772019],
                [105.4213357, 20.3793281],
                [105.4226807, 20.3800875],
                [105.423495, 20.3806482],
                [105.424655, 20.3813024],
                [105.427049, 20.3824006],
                [105.4295169, 20.3837792],
                [105.4299488, 20.3840479],
                [105.4302944, 20.384048],
                [105.4307239, 20.3840275],
                [105.431277, 20.3840529],
                [105.4322438, 20.3844364],
                [105.4331363, 20.384785],
                [105.4337129, 20.385097],
                [105.4341961, 20.3852013],
                [105.434679, 20.3852001],
                [105.4355325, 20.3849177],
                [105.4363859, 20.3845301],
                [105.4377213, 20.3838257],
                [105.4385003, 20.3834032],
                [105.4396501, 20.3827695],
                [105.4403918, 20.382312],
                [105.4410226, 20.3820651],
                [105.4422841, 20.3815362],
                [105.4432864, 20.3812885],
                [105.4445484, 20.3809349],
                [105.4459977, 20.3805476],
                [105.4480751, 20.3801203],
                [105.4498197, 20.3797305],
                [105.4515441, 20.3793182],
                [105.4526576, 20.379035],
                [105.4537339, 20.3786818],
                [105.4548475, 20.3784337],
                [105.4557382, 20.3781511],
                [105.4563317, 20.3778692],
                [105.4568506, 20.3774472],
                [105.4578885, 20.3766733],
                [105.4591491, 20.3758288],
                [105.4599276, 20.3753361],
                [105.4604101, 20.3751597],
                [105.4614875, 20.3752622],
                [105.4623795, 20.3754353],
                [105.4634945, 20.3756781],
                [105.464424, 20.3759914],
                [105.4649071, 20.3760604],
                [105.4654644, 20.376094],
                [105.4663184, 20.3759868],
                [105.4670234, 20.3757397],
                [105.4680626, 20.3754216],
                [105.4687522, 20.3753525],
                [105.4697558, 20.3755253],
                [105.4706473, 20.3755582],
                [105.4717243, 20.3754854],
                [105.4725041, 20.3754133],
                [105.473581, 20.3753406],
                [105.4745097, 20.3753383],
                [105.4751784, 20.3753716],
                [105.4757357, 20.3754403],
                [105.4766272, 20.375403],
                [105.4771465, 20.3751915],
                [105.4779988, 20.3745231],
                [105.4789996, 20.3737843],
                [105.480186, 20.3730449],
                [105.48241, 20.3714965],
                [105.4831803, 20.3709794],
                [105.4839298, 20.3704759],
                [105.4851164, 20.3698068],
                [105.4864506, 20.3688217],
                [105.487692, 20.3680717],
                [105.4889156, 20.3674024],
                [105.4896204, 20.3670851],
                [105.490399, 20.3666272],
                [105.4909923, 20.3663102],
                [105.4918081, 20.3658523],
                [105.4924383, 20.3654649],
                [105.4926235, 20.365289],
                [105.4933652, 20.3649015],
                [105.4941073, 20.3646541],
                [105.4945085, 20.3645872],
                [105.4968748, 20.3636722],
                [105.5017887, 20.3611142],
                [105.5021733, 20.3610703],
                [105.5042876, 20.360823],
                [105.5053331, 20.3604085],
                [105.505972, 20.3601598],
                [105.5069024, 20.360157],
                [105.5101304, 20.3602571],
                [105.5131548, 20.3603852],
                [105.5147255, 20.3605449],
                [105.5165863, 20.3604845],
                [105.5190564, 20.3600108],
                [105.5200446, 20.3598981],
                [105.5208295, 20.3598408],
                [105.5215278, 20.3599758],
                [105.5221683, 20.3602266],
                [105.522839, 20.3608008],
                [105.5238322, 20.3621152],
                [105.5243597, 20.3633485],
                [105.524105, 20.3654076],
                [105.5241969, 20.3664781],
                [105.5244328, 20.3674101],
                [105.5247839, 20.3680398],
                [105.5254269, 20.3689706],
                [105.5264194, 20.3700372],
                [105.5272648, 20.3706107],
                [105.5292164, 20.3714824],
                [105.5296532, 20.3716515],
                [105.5310498, 20.3717844],
                [105.5321547, 20.3716986],
                [105.5329101, 20.3714767],
                [105.5344763, 20.370209],
                [105.5351151, 20.3698776],
                [105.5366859, 20.369955],
                [105.5372383, 20.3698985],
                [105.5390387, 20.3690692],
                [105.5402578, 20.3684067],
                [105.5407504, 20.367856],
                [105.5410097, 20.367169],
                [105.5413219, 20.3649172],
                [105.5416957, 20.3637357],
                [105.5457336, 20.3624052],
                [105.5467795, 20.3620724],
                [105.5479121, 20.3616022],
                [105.5496552, 20.361075],
                [105.5510794, 20.3608509],
                [105.551719, 20.3607938],
                [105.553464, 20.3607882],
                [105.5541905, 20.3606212],
                [105.5548865, 20.3600974],
                [105.5561324, 20.3587758],
                [105.5567132, 20.3585267],
                [105.5585453, 20.3585208],
                [105.5598545, 20.3586538],
                [105.561104, 20.3583752],
                [105.562179, 20.3580697],
                [105.5628476, 20.3580126],
                [105.563808, 20.3582016],
                [105.5663127, 20.3591815],
                [105.5673025, 20.3594802],
                [105.5691049, 20.359282],
                [105.5697445, 20.3592251],
                [105.5702974, 20.3593056],
                [105.5710257, 20.3596599],
                [105.5720751, 20.3603152],
                [105.5744977, 20.3626677],
                [105.5762773, 20.3641715],
                [105.5771827, 20.3652114],
                [105.579726, 20.3686889],
                [105.5803102, 20.3693732],
                [105.5807476, 20.3696737],
                [105.5811552, 20.3697821],
                [105.5817366, 20.3696978],
                [105.5843599, 20.3686254],
                [105.5862078, 20.3676512],
                [105.591348, 20.3656846],
                [105.5928292, 20.3651305],
                [105.5967513, 20.3640464],
                [105.5973034, 20.3639072],
                [105.5982637, 20.3640686],
                [105.5986423, 20.3641496],
                [105.5991074, 20.3641204],
                [105.6001225, 20.3634031],
                [105.6014567, 20.3624652],
                [105.6043026, 20.3613573],
                [105.6054641, 20.360914],
                [105.6060733, 20.3605275],
                [105.6063625, 20.3601147],
                [105.6068517, 20.358768],
                [105.6072277, 20.3582451],
                [105.6078358, 20.3575842],
                [105.6093733, 20.3565905],
                [105.6102724, 20.3559835],
                [105.6115468, 20.3546613],
                [105.6122714, 20.3540548],
                [105.6132275, 20.3531456],
                [105.6158954, 20.351242],
                [105.6164753, 20.3508007],
                [105.617024, 20.3498655],
                [105.6182134, 20.34912],
                [105.6219302, 20.3477341],
                [105.6226558, 20.3474296],
                [105.6232084, 20.3474275],
                [105.6236165, 20.3476731],
                [105.6244923, 20.3485209],
                [105.62493, 20.3489036],
                [105.6253083, 20.3489571],
                [105.6255697, 20.3488738],
                [105.6258599, 20.3487354],
                [105.6265839, 20.3479642],
                [105.6284973, 20.3465298],
                [105.6340998, 20.3441212],
                [105.6342739, 20.3440107],
                [105.6354637, 20.343375],
                [105.6361301, 20.3428234],
                [105.6369696, 20.3419145],
                [105.6381843, 20.3402905],
                [105.6390816, 20.3392716],
                [105.6398065, 20.3387748],
                [105.6403871, 20.338553],
                [105.642364, 20.3384358],
                [105.6425964, 20.3384075],
                [105.6430862, 20.3373077],
                [105.6434613, 20.3365926],
                [105.6442437, 20.3359309],
                [105.6443881, 20.3357108],
                [105.6444161, 20.3354635],
                [105.6443861, 20.3352166],
                [105.644005, 20.3345044],
                [105.6440042, 20.3342848],
                [105.6442069, 20.3340919],
                [105.6449365, 20.3335013],
                [105.6470504, 20.3323892],
                [105.647568, 20.3318149],
                [105.6485804, 20.3302377],
                [105.6493568, 20.328652],
                [105.6509858, 20.3269123],
                [105.6525706, 20.3256244],
                [105.6534021, 20.3251405],
                [105.6549895, 20.3245082],
                [105.6555902, 20.3241854],
                [105.6566605, 20.3230955],
                [105.6579843, 20.3222019],
                [105.6603092, 20.32078],
                [105.6613816, 20.3198038],
                [105.6628623, 20.3183482],
                [105.6632263, 20.3179903],
                [105.6648727, 20.3167459],
                [105.6651793, 20.3162639],
                [105.6656953, 20.3145513],
                [105.6659394, 20.3138803],
                [105.666569, 20.3131204],
                [105.6675374, 20.3121697],
                [105.6690182, 20.3106714],
                [105.6704638, 20.3094276],
                [105.6711724, 20.3091189],
                [105.671666, 20.3090734],
                [105.6724075, 20.309158],
                [105.6730557, 20.3091408],
                [105.6735797, 20.308964],
                [105.6742568, 20.3084952],
                [105.6749786, 20.307633],
                [105.6756229, 20.3067126],
                [105.6762999, 20.3062438],
                [105.6774234, 20.3060273],
                [105.6837676, 20.3053594],
                [105.686191, 20.3053645],
                [105.6868242, 20.305464],
                [105.6873653, 20.3056366],
                [105.6878448, 20.3058679],
                [105.6883402, 20.306201],
                [105.6895293, 20.3074548],
                [105.6906784, 20.3090236],
                [105.6913607, 20.309691],
                [105.6919181, 20.3100967],
                [105.6926606, 20.3104579],
                [105.6927426, 20.3105001],
                [105.6931402, 20.3107037],
                [105.6946658, 20.3112908],
                [105.6952848, 20.3116525],
                [105.6956875, 20.3119714],
                [105.695905, 20.3122764],
                [105.696061, 20.3126546],
                [105.6964493, 20.3136677],
                [105.6965747, 20.3141042],
                [105.6969482, 20.3147728],
                [105.6980054, 20.3164731],
                [105.6996495, 20.3191267],
                [105.7004415, 20.3201724],
                [105.7008452, 20.3206952],
                [105.7010002, 20.3208402],
                [105.7015419, 20.3211295],
                [105.7018975, 20.3212737],
                [105.7032202, 20.3217302],
                [105.7056017, 20.322653],
                [105.7095994, 20.3241777],
                [105.7117786, 20.3247662],
                [105.7122138, 20.3249405],
                [105.7135417, 20.3254728],
                [105.7158143, 20.3261919],
                [105.7173757, 20.3266954],
                [105.7181984, 20.3269373],
                [105.7185717, 20.3270265],
                [105.7198173, 20.3275341],
                [105.7228578, 20.3286277],
                [105.7245574, 20.3295399],
                [105.7248215, 20.3298884],
                [105.7250241, 20.3303392],
                [105.7251806, 20.3307757],
                [105.7253058, 20.3311394],
                [105.725477, 20.3314154],
                [105.7256941, 20.3316331],
                [105.7265894, 20.3323611],
                [105.727193, 20.3331783],
                [105.7273319, 20.3341704],
                [105.7272951, 20.3351902],
                [105.7273653, 20.3353861],
                [105.7275046, 20.3355555],
                [105.7277825, 20.3356981],
                [105.7281706, 20.3357096],
                [105.7285867, 20.3357862],
                [105.7287677, 20.3359423],
                [105.7289084, 20.3364124],
                [105.7290514, 20.3373532],
                [105.7290998, 20.3376993],
                [105.7291518, 20.3380717],
                [105.7295724, 20.3390898],
                [105.729157, 20.3391838],
                [105.7283676, 20.3393179],
                [105.7277451, 20.3395299],
                [105.727455, 20.3397274],
                [105.7271652, 20.3399772],
                [105.726338, 20.3408701],
                [105.726062, 20.3411198],
                [105.7256749, 20.3412915],
                [105.7249136, 20.341504],
                [105.72443, 20.3417938],
                [105.7240713, 20.3421223],
                [105.7238789, 20.3424866],
                [105.7236731, 20.3429189],
                [105.7233993, 20.3436259],
                [105.7230156, 20.3445556],
                [105.7225608, 20.3451065],
                [105.7218436, 20.3458284],
                [105.7214571, 20.3461438],
                [105.7206555, 20.3466177],
                [105.720338, 20.3468805],
                [105.7194696, 20.3478007],
                [105.7194047, 20.3478803],
                [105.7189461, 20.3484438],
                [105.7187127, 20.3489155],
                [105.7184962, 20.350015],
                [105.7183062, 20.350892],
                [105.7180062, 20.3519396],
                [105.7178144, 20.3523981],
                [105.7176352, 20.352595],
                [105.7174554, 20.3527005],
                [105.7171232, 20.3527411],
                [105.7166101, 20.3526517],
                [105.7162777, 20.3526792],
                [105.7159319, 20.3528245],
                [105.71549, 20.3531271],
                [105.7150449, 20.3535828],
                [105.7149384, 20.3536918],
                [105.7146905, 20.3540198],
                [105.71443, 20.3546225],
                [105.7142683, 20.3556302],
                [105.7140928, 20.3566249],
                [105.7139572, 20.3572662],
                [105.7137376, 20.3577511],
                [105.7134351, 20.3582493],
                [105.713173, 20.3584858],
                [105.7128961, 20.3585524],
                [105.7123423, 20.3586593],
                [105.7119273, 20.3587919],
                [105.7117204, 20.359015],
                [105.7114047, 20.3596571],
                [105.7112119, 20.3599195],
                [105.7109907, 20.360025],
                [105.7107138, 20.3600785],
                [105.7103675, 20.3601061],
                [105.7099107, 20.3601864],
                [105.7096618, 20.3603314],
                [105.7095657, 20.3605279],
                [105.7095261, 20.3609465],
                [105.7095822, 20.3610771],
                [105.7105586, 20.3624987],
                [105.7109071, 20.3629418],
                [105.71152, 20.3636586],
                [105.7119532, 20.3644415],
                [105.7122051, 20.3649897],
                [105.7122627, 20.3654472],
                [105.7122647, 20.3658919],
                [105.7121697, 20.366337],
                [105.7119498, 20.3667302],
                [105.7117016, 20.3669929],
                [105.7114386, 20.3670593],
                [105.7112861, 20.3670469],
                [105.710814, 20.3668265],
                [105.7105226, 20.3667362],
                [105.7102593, 20.3667373],
                [105.7099552, 20.3668823],
                [105.7095825, 20.367237],
                [105.7091284, 20.367932],
                [105.7081769, 20.3689169],
                [105.7071153, 20.3700982],
                [105.7068125, 20.3705703],
                [105.7064699, 20.3714088],
                [105.7056447, 20.3727855],
                [105.7053803, 20.3731571],
                [105.7053327, 20.3732243],
                [105.7050664, 20.3735986],
                [105.7040991, 20.3742304],
                [105.7039901, 20.3746101],
                [105.7039825, 20.3759833],
                [105.7039289, 20.376389],
                [105.7036954, 20.376858],
                [105.7036412, 20.3771197],
                [105.7036979, 20.37742],
                [105.7040187, 20.37785],
                [105.7042008, 20.3782805],
                [105.7042013, 20.3783981],
                [105.704188, 20.3785288],
                [105.7041055, 20.378686],
                [105.7038569, 20.37887],
                [105.7034837, 20.3790546],
                [105.7018935, 20.3798321],
                [105.7007051, 20.3805819],
                [105.7002218, 20.380976],
                [105.7000716, 20.3814731],
                [105.7000735, 20.3818914],
                [105.700024, 20.3823491],
                [105.6998848, 20.3831127],
                [105.6996645, 20.3835176],
                [105.6994754, 20.3837728],
                [105.6992543, 20.3839533],
                [105.6990327, 20.3840738],
                [105.6986367, 20.3841353],
                [105.6979708, 20.3841381],
                [105.6971313, 20.3843209],
                [105.6968623, 20.3844417],
                [105.6966733, 20.3847269],
                [105.6964375, 20.3851917],
                [105.6962485, 20.3854618],
                [105.6960271, 20.3855973],
                [105.6953139, 20.3856452],
                [105.6950762, 20.3857059],
                [105.6949661, 20.3859009],
                [105.6949196, 20.3861255],
                [105.69486, 20.3869937],
                [105.6948139, 20.3872931],
                [105.6947197, 20.3875029],
                [105.6944982, 20.3876385],
                [105.6940709, 20.3877898],
                [105.6939763, 20.3879249],
                [105.693977, 20.3880895],
                [105.6940251, 20.3882239],
                [105.6940733, 20.3883285],
                [105.6941687, 20.3884029],
                [105.6942639, 20.3884175],
                [105.6951196, 20.3883093],
                [105.69577, 20.3883665],
                [105.6964689, 20.388618],
                [105.6966761, 20.3888866],
                [105.6967087, 20.3890809],
                [105.6966777, 20.3892306],
                [105.6963615, 20.3894564],
                [105.6958399, 20.3898027],
                [105.6956347, 20.3900129],
                [105.6951932, 20.3905833],
                [105.6948295, 20.3907943],
                [105.6934837, 20.3912487],
                [105.6928507, 20.3915205],
                [105.6924561, 20.3918961],
                [105.6920778, 20.3924364],
                [105.6919849, 20.3929306],
                [105.6920185, 20.3933494],
                [105.692242, 20.3936927],
                [105.692576, 20.3939008],
                [105.6930202, 20.3939588],
                [105.6936223, 20.3938516],
                [105.6942196, 20.3935397],
                [105.6951411, 20.3930973],
                [105.6956009, 20.3930587],
                [105.6958553, 20.3932291],
                [105.6960784, 20.3934975],
                [105.6962076, 20.3940057],
                [105.6964551, 20.3954665],
                [105.6966943, 20.3957947],
                [105.6969298, 20.395918],
                [105.6972947, 20.3959914],
                [105.6979611, 20.3960934],
                [105.6981679, 20.3962122],
                [105.6983748, 20.3964059],
                [105.6985032, 20.3967315],
                [105.6985052, 20.3971651],
                [105.6984432, 20.3974793],
                [105.698317, 20.3976293],
                [105.6981588, 20.3977197],
                [105.6979052, 20.3977507],
                [105.6971983, 20.3975022],
                [105.6970162, 20.3975301],
                [105.6968897, 20.3976053],
                [105.696685, 20.3979201],
                [105.6962857, 20.3987008],
                [105.6958304, 20.3996157],
                [105.6952313, 20.4009155],
                [105.6950697, 20.4014611],
                [105.6945448, 20.4026215],
                [105.6944033, 20.4027928],
                [105.6942955, 20.4028541],
                [105.6941451, 20.4028426],
                [105.6939053, 20.4027134],
                [105.6934737, 20.4023508],
                [105.6932478, 20.4023001],
                [105.6930258, 20.402316],
                [105.6928103, 20.4023464],
                [105.692629, 20.4026748],
                [105.6920375, 20.4036761],
                [105.6918321, 20.4038486],
                [105.691669, 20.4038466],
                [105.6914784, 20.4037876],
                [105.6911285, 20.4035647],
                [105.6900304, 20.402687],
                [105.6892679, 20.4023611],
                [105.6888237, 20.4023329],
                [105.6884438, 20.402484],
                [105.6879699, 20.4029045],
                [105.6875908, 20.4032351],
                [105.6859019, 20.4050212],
                [105.6849717, 20.4062211],
                [105.684831, 20.4066702],
                [105.6848484, 20.4070589],
                [105.6851061, 20.4079101],
                [105.6853976, 20.4093145],
                [105.6853706, 20.4103911],
                [105.6862901, 20.4105811],
                [105.6869402, 20.4105785],
                [105.6882877, 20.4105282],
                [105.6887, 20.4105564],
                [105.6895417, 20.4108373],
                [105.6906851, 20.4112815],
                [105.6921464, 20.411844],
                [105.6933358, 20.4118841],
                [105.6953176, 20.4118611],
                [105.6975004, 20.41183],
                [105.6979959, 20.411566],
                [105.6992608, 20.4107978],
                [105.7010005, 20.4098333],
                [105.7013968, 20.4098018],
                [105.702762, 20.4101552],
                [105.7039059, 20.4106741],
                [105.7059098, 20.4120123],
                [105.7062112, 20.412041],
                [105.7073029, 20.4115428],
                [105.7092637, 20.4104126],
                [105.7101633, 20.4094814],
                [105.7110951, 20.4086996],
                [105.7122181, 20.4080816],
                [105.7138343, 20.4078803],
                [105.7190512, 20.4080082],
                [105.7195881, 20.4075421],
                [105.72164, 20.4055438],
                [105.7234713, 20.4038605],
                [105.7256345, 20.4019514],
                [105.7259351, 20.4018305],
                [105.727901, 20.4018222],
                [105.7281217, 20.4015519],
                [105.7281504, 20.4009085],
                [105.7282416, 20.4000853],
                [105.7284462, 20.3997554],
                [105.7290631, 20.3994536],
                [105.7302829, 20.399224],
                [105.7318037, 20.3990079],
                [105.7321038, 20.3987524],
                [105.7322293, 20.3984975],
                [105.7322448, 20.3983928],
                [105.7320993, 20.3978099],
                [105.7316956, 20.3962408],
                [105.7308929, 20.394105],
                [105.7304241, 20.3921921],
                [105.7303035, 20.390158],
                [105.7303595, 20.3885689],
                [105.7304273, 20.3861467],
                [105.7305219, 20.3860268],
                [105.731249, 20.3855901],
                [105.7325304, 20.3850166],
                [105.7352559, 20.3847807],
                [105.7356859, 20.3845102],
                [105.7360537, 20.3842787],
                [105.7376393, 20.3840991],
                [105.7394711, 20.3843058],
                [105.7419103, 20.3843035],
                [105.7434602, 20.3835938],
                [105.7455627, 20.382448],
                [105.7457507, 20.3819834],
                [105.7459158, 20.3800832],
                [105.7458153, 20.3789618],
                [105.7456984, 20.3776909],
                [105.7456618, 20.3766889],
                [105.7458787, 20.3756559],
                [105.7458773, 20.3753568],
                [105.745606, 20.3749691],
                [105.7454772, 20.3745508],
                [105.7453466, 20.3737586],
                [105.7451979, 20.3725029],
                [105.7448779, 20.371861],
                [105.744876, 20.3714722],
                [105.7450459, 20.3705409],
                [105.7453089, 20.3692245],
                [105.7461992, 20.3665004],
                [105.7465638, 20.3664987],
                [105.7518712, 20.3668114],
                [105.7568353, 20.367207],
                [105.7567961, 20.3682999],
                [105.7567113, 20.3692109],
                [105.7566557, 20.3699957],
                [105.7566893, 20.3707662],
                [105.7568125, 20.3716622],
                [105.7569488, 20.3721799],
                [105.7578646, 20.3741375],
                [105.7585397, 20.3755074],
                [105.759695, 20.3778141],
                [105.7598315, 20.3783739],
                [105.7598632, 20.3787661],
                [105.7598199, 20.3790324],
                [105.7595859, 20.379804],
                [105.7593798, 20.3801972],
                [105.7589979, 20.3810814],
                [105.7586074, 20.38184],
                [105.7581737, 20.3826821],
                [105.7577606, 20.3832864],
                [105.7573766, 20.3837504],
                [105.7572291, 20.3839892],
                [105.7571716, 20.3843817],
                [105.7571756, 20.3851943],
                [105.7571656, 20.386189],
                [105.7570913, 20.3871116],
                [105.7559915, 20.3878534],
                [105.7551491, 20.3882884],
                [105.7546855, 20.3887634],
                [105.7543897, 20.3895559],
                [105.7543887, 20.3901903],
                [105.7545138, 20.3908644],
                [105.7544707, 20.3915779],
                [105.7543857, 20.3921328],
                [105.7546372, 20.3926881],
                [105.7549732, 20.3930852],
                [105.7550777, 20.3935809],
                [105.7549501, 20.3943735],
                [105.7546548, 20.3948885],
                [105.7539804, 20.3955615],
                [105.7535167, 20.3960762],
                [105.753389, 20.3969879],
                [105.7532187, 20.3981769],
                [105.7530493, 20.3989696],
                [105.7531957, 20.3994257],
                [105.7541213, 20.3996649],
                [105.7553415, 20.3997459],
                [105.755903, 20.399938],
                [105.7558082, 20.4002351],
                [105.7554582, 20.4011177],
                [105.7549666, 20.4027298],
                [105.7547113, 20.4032473],
                [105.7544067, 20.4035524],
                [105.7538614, 20.4040712],
                [105.7533162, 20.4046052],
                [105.7525645, 20.4057173],
                [105.7521656, 20.4064936],
                [105.7521348, 20.4067975],
                [105.752169, 20.4072227],
                [105.7523326, 20.4077232],
                [105.7529993, 20.4089811],
                [105.7532919, 20.4095264],
                [105.7533253, 20.4097542],
                [105.7532785, 20.4100734],
                [105.7531526, 20.4106815],
                [105.752725, 20.4122174],
                [105.752615, 20.4127799],
                [105.7526322, 20.4130076],
                [105.7527139, 20.4132502],
                [105.7529566, 20.4134467],
                [105.7532794, 20.4135516],
                [105.7547755, 20.4134528],
                [105.7561403, 20.4134181],
                [105.7569981, 20.4134559],
                [105.759129, 20.4134719],
                [105.7591642, 20.4161495],
                [105.7589905, 20.4165867],
                [105.7571434, 20.4171067],
                [105.7566144, 20.4188278],
                [105.7563138, 20.4201499],
                [105.7561451, 20.421001],
                [105.7555989, 20.4231203],
                [105.7552229, 20.4247141],
                [105.7550344, 20.4254205],
                [105.7547335, 20.4267064],
                [105.7544518, 20.4279742],
                [105.7535776, 20.4297692],
                [105.7533124, 20.4304759],
                [105.7530676, 20.4314539],
                [105.7529202, 20.4327211],
                [105.7526985, 20.4344952],
                [105.7525686, 20.4354004],
                [105.7523993, 20.4361249],
                [105.7520781, 20.4371756],
                [105.7518721, 20.4376804],
                [105.7513679, 20.4389154],
                [105.7518769, 20.4391862],
                [105.7527523, 20.4394808],
                [105.7531446, 20.4396881],
                [105.7538209, 20.4402017],
                [105.7544236, 20.4407983],
                [105.7548937, 20.4414638],
                [105.7552023, 20.4417553],
                [105.7554376, 20.4418803],
                [105.7562766, 20.4419784],
                [105.7574834, 20.4421325],
                [105.7589114, 20.4420929],
                [105.7601336, 20.4419016],
                [105.7602591, 20.4418985],
                [105.7607953, 20.4416428],
                [105.7610436, 20.4413885],
                [105.7612334, 20.4409354],
                [105.7614049, 20.4406994],
                [105.7620171, 20.4402805],
                [105.7623811, 20.4401523],
                [105.76265, 20.4401692],
                [105.7636873, 20.4403093],
                [105.7647439, 20.4404857],
                [105.7663388, 20.4408405],
                [105.7674152, 20.4411253],
                [105.7691449, 20.4415699],
                [105.771317, 20.4422117],
                [105.7725667, 20.4425681],
                [105.7728545, 20.4425486],
                [105.7732182, 20.4423661],
                [105.773466, 20.4420032],
                [105.7738178, 20.4413973],
                [105.7738626, 20.4407483],
                [105.7738046, 20.4400556],
                [105.7735706, 20.4390578],
                [105.7736604, 20.4381159],
                [105.7738082, 20.4376174],
                [105.7739268, 20.4370911],
                [105.7738982, 20.4365923],
                [105.7739281, 20.4362045],
                [105.774282, 20.4357339],
                [105.7748124, 20.4354853],
                [105.7759897, 20.4356807],
                [105.7786337, 20.4359382],
                [105.7787902, 20.4359625],
                [105.7795018, 20.4360598],
                [105.7806928, 20.4362716],
                [105.7819805, 20.4365915],
                [105.7826347, 20.4369142],
                [105.782866, 20.4370941],
                [105.7829629, 20.4372927],
                [105.7830606, 20.437618],
                [105.7832571, 20.4385036],
                [105.7835675, 20.4391716],
                [105.7839161, 20.4397672],
                [105.7843993, 20.4404344],
                [105.7849614, 20.4414993],
                [105.7858716, 20.4430692],
                [105.7865117, 20.4444274],
                [105.787119, 20.4456034],
                [105.7875308, 20.446157],
                [105.7877248, 20.4464735],
                [105.7881123, 20.4470045],
                [105.7887056, 20.4477839],
                [105.7888027, 20.4479421],
                [105.7888159, 20.4481688],
                [105.7887922, 20.448271],
                [105.7886855, 20.448566],
                [105.7884473, 20.4490206],
                [105.7882571, 20.4494408],
                [105.7881274, 20.4499742],
                [105.7880452, 20.4503486],
                [105.7879876, 20.4508476],
                [105.7878467, 20.4515397],
                [105.787682, 20.4522319],
                [105.7876724, 20.452708],
                [105.787722, 20.4529911],
                [105.7878198, 20.4533195],
                [105.7879654, 20.4535681],
                [105.7880381, 20.4536698],
                [105.7881105, 20.4537375],
                [105.7883151, 20.4537479],
                [105.7892051, 20.4537438],
                [105.789783, 20.4538546],
                [105.7905656, 20.4540551],
                [105.7916983, 20.454492],
                [105.7923136, 20.4547086],
                [105.7923713, 20.4543869],
                [105.7924627, 20.453457],
                [105.7928377, 20.4515511],
                [105.7932363, 20.4495542],
                [105.7935418, 20.448136],
                [105.7937552, 20.4475343],
                [105.7939578, 20.4471706],
                [105.7943399, 20.4466362],
                [105.7948419, 20.4460557],
                [105.7955461, 20.4450323],
                [105.7958564, 20.4445663],
                [105.7965258, 20.4437584],
                [105.7972047, 20.4424858],
                [105.7978004, 20.4414062],
                [105.7981336, 20.4407472],
                [105.7986699, 20.4397699],
                [105.7993974, 20.4385991],
                [105.7997667, 20.43794],
                [105.8002447, 20.437371],
                [105.8010454, 20.436404],
                [105.8017271, 20.4356753],
                [105.8020738, 20.4352996],
                [105.8032107, 20.4342177],
                [105.8044782, 20.4328629],
                [105.8049205, 20.4323735],
                [105.8058297, 20.4314624],
                [105.8068698, 20.4302901],
                [105.8073486, 20.4298572],
                [105.8077195, 20.4295268],
                [105.8082227, 20.4291617],
                [105.8098155, 20.4279188],
                [105.8118175, 20.4267532],
                [105.8134714, 20.4257253],
                [105.8146098, 20.4249719],
                [105.8153652, 20.4245944],
                [105.8158936, 20.4244559],
                [105.816326, 20.4243745],
                [105.8169511, 20.4243488],
                [105.8175163, 20.4243462],
                [105.8179608, 20.4242761],
                [105.8186214, 20.424137],
                [105.8191373, 20.4239419],
                [105.8194728, 20.4237136],
                [105.8197119, 20.4234178],
                [105.8201295, 20.4228038],
                [105.8205569, 20.4218155],
                [105.820723, 20.4213614],
                [105.8207584, 20.4212592],
                [105.8208562, 20.4210407],
                [105.8211767, 20.420325],
                [105.8217628, 20.4192655],
                [105.8222575, 20.4186174],
                [105.8225052, 20.418352],
                [105.8227844, 20.41816],
                [105.8235609, 20.4178041],
                [105.8244317, 20.4175983],
                [105.8255176, 20.4181449],
                [105.8265472, 20.4187421],
                [105.8271065, 20.4187843],
                [105.8281079, 20.4183416],
                [105.8287338, 20.4179886],
                [105.8294407, 20.4176704],
                [105.829897, 20.4176848],
                [105.8301175, 20.4179068],
                [105.8303084, 20.418309],
                [105.8304541, 20.4195848],
                [105.8306154, 20.4202088],
                [105.8308064, 20.4204309],
                [105.8309315, 20.4204934],
                [105.8315497, 20.420494],
                [105.8324624, 20.4203978],
                [105.8337281, 20.4204821],
                [105.834361, 20.4205522],
                [105.8346774, 20.4206842],
                [105.8350003, 20.421447],
                [105.8351914, 20.4216967],
                [105.8356624, 20.4216833],
                [105.8364081, 20.421533],
                [105.8376271, 20.4207624],
                [105.8378183, 20.4207615],
                [105.8388386, 20.4207566],
                [105.8399226, 20.4207514],
                [105.8408225, 20.4204899],
                [105.8417877, 20.4205611],
                [105.8427535, 20.420708],
                [105.8438467, 20.4211639],
                [105.84445, 20.4214973],
                [105.8451704, 20.4222466],
                [105.8458469, 20.4227603],
                [105.8469132, 20.4236138],
                [105.8477072, 20.4245157],
                [105.8482805, 20.4253204],
                [105.8486698, 20.4261595],
                [105.8491113, 20.4261877],
                [105.8501712, 20.4261053],
                [105.8513928, 20.4261481],
                [105.8522761, 20.4260933],
                [105.8542558, 20.4261435],
                [105.8552712, 20.4264494],
                [105.85611, 20.426658],
                [105.8566326, 20.4266516],
                [105.8571482, 20.4265979],
                [105.8565659, 20.4274874],
                [105.8557788, 20.4283815],
                [105.8551457, 20.4295619],
                [105.8546049, 20.4309142],
                [105.854306, 20.4319494],
                [105.8540707, 20.4334724],
                [105.8539867, 20.4347938],
                [105.8538694, 20.435627],
                [105.8538714, 20.4360003],
                [105.8540916, 20.4372341],
                [105.8543097, 20.4381231],
                [105.8544041, 20.4386395],
                [105.8543773, 20.4393001],
                [105.8541704, 20.4404498],
                [105.8540234, 20.441398],
                [105.8534308, 20.4443765],
                [105.8531631, 20.4455261],
                [105.8528953, 20.4466758],
                [105.8527794, 20.4477386],
                [105.8527875, 20.4492029],
                [105.8527938, 20.4503512],
                [105.8527075, 20.4512415],
                [105.8524065, 20.4523305],
                [105.8522933, 20.4534829],
                [105.8520902, 20.4552926],
                [105.8519482, 20.4571594],
                [105.8517432, 20.4586534],
                [105.8514042, 20.4634495],
                [105.8513606, 20.4652391],
                [105.8513678, 20.4665378],
                [105.851575, 20.4693866],
                [105.8516263, 20.4717313],
                [105.8519538, 20.4755896],
                [105.8521233, 20.4778837],
                [105.8515102, 20.4780532],
                [105.8508111, 20.4782367],
                [105.8499631, 20.4783808],
                [105.8489448, 20.4784258],
                [105.8476077, 20.4783923],
                [105.8454207, 20.4781429],
                [105.8439132, 20.4780103],
                [105.8419135, 20.4809394],
                [105.8388539, 20.4802144],
                [105.8383655, 20.4801568],
                [105.8381746, 20.4801776],
                [105.8379632, 20.4803187],
                [105.8376259, 20.4807402],
                [105.8363036, 20.483426],
                [105.8361583, 20.4840266],
                [105.8361387, 20.4843266],
                [105.8352058, 20.484491],
                [105.8343794, 20.4846751],
                [105.8337646, 20.4847979],
                [105.8333197, 20.48494],
                [105.8326635, 20.4852632],
                [105.8316071, 20.4860879],
                [105.8307412, 20.486852],
                [105.8300644, 20.4872952],
                [105.8292603, 20.4877389],
                [105.828477, 20.4880824],
                [105.8279897, 20.4882248],
                [105.826781, 20.4883905],
                [105.8256783, 20.4885558],
                [105.8252757, 20.4886376],
                [105.8248948, 20.4888795],
                [105.8242194, 20.4895425],
                [105.8213909, 20.4923953],
                [105.820548, 20.493519],
                [105.8203589, 20.4938799],
                [105.8201711, 20.4944806],
                [105.8200678, 20.495001],
                [105.8199858, 20.4955412],
                [105.819991, 20.496521],
                [105.820007, 20.496845],
                [105.8201027, 20.4975113],
                [105.8199288, 20.498606],
                [105.8193829, 20.4995558],
                [105.8191206, 20.4997826],
                [105.8190979, 20.5000308],
                [105.8192677, 20.500436],
                [105.8197094, 20.502396],
                [105.8197889, 20.5038164],
                [105.8197035, 20.505779],
                [105.815668, 20.5040344],
                [105.8148634, 20.503666],
                [105.8142173, 20.5033985],
                [105.8137252, 20.5032091],
                [105.8133814, 20.503029],
                [105.812877, 20.5027833],
                [105.8120847, 20.5024826],
                [105.8097365, 20.5014861],
                [105.8098719, 20.4998599],
                [105.8100351, 20.4989786],
                [105.8095645, 20.4988098],
                [105.8092096, 20.4986628],
                [105.8085501, 20.4985772],
                [105.8080089, 20.4986019],
                [105.8074443, 20.498671],
                [105.8070215, 20.4988504],
                [105.8066901, 20.4990739],
                [105.8051296, 20.5006152],
                [105.8046022, 20.5016609],
                [105.8040072, 20.5029001],
                [105.8037247, 20.5037516],
                [105.8036586, 20.5041641],
                [105.8036609, 20.5042746],
                [105.8036646, 20.5044496],
                [105.8036419, 20.5053256],
                [105.8035633, 20.50597],
                [105.8034442, 20.5067177],
                [105.8033413, 20.5079447],
                [105.8032084, 20.5086662],
                [105.8031819, 20.5088464],
                [105.803046, 20.5090015],
                [105.8028149, 20.5092084],
                [105.8026381, 20.5093893],
                [105.8021924, 20.5103953],
                [105.8015836, 20.5115951],
                [105.8011786, 20.5125621],
                [105.8010432, 20.5128073],
                [105.8008253, 20.5129497],
                [105.8003888, 20.5131061],
                [105.8002111, 20.5131197],
                [105.7998005, 20.5130057],
                [105.7975959, 20.5122041],
                [105.7965003, 20.5117452],
                [105.7959938, 20.5116186],
                [105.7957477, 20.5115939],
                [105.795148, 20.5116971],
                [105.7947938, 20.5129936],
                [105.7944913, 20.5139573],
                [105.7938151, 20.5162471],
                [105.7932916, 20.5172929],
                [105.793133, 20.5175718],
                [105.7929738, 20.5177117],
                [105.7928062, 20.5192021],
                [105.7924188, 20.5196552],
                [105.7924336, 20.5201653],
                [105.7923384, 20.5207687],
                [105.7920475, 20.5216164],
                [105.7917181, 20.5221627],
                [105.7910856, 20.5231564],
                [105.7909088, 20.5240273],
                [105.7901426, 20.5239606],
                [105.7899108, 20.5243209],
                [105.7896202, 20.5252382],
                [105.789512, 20.5257373],
                [105.7889643, 20.526602],
                [105.7883666, 20.5276248],
                [105.7876857, 20.5291815],
                [105.7871527, 20.5301966],
                [105.7864443, 20.5311967],
                [105.7860525, 20.5315925],
                [105.7859188, 20.5319062],
                [105.7858967, 20.5323933],
                [105.7858987, 20.5327875],
                [105.7858028, 20.5332865],
                [105.7854938, 20.5351119],
                [105.7852258, 20.5356117],
                [105.7849587, 20.5360078],
                [105.7845907, 20.5365536],
                [105.7842624, 20.5373085],
                [105.7841417, 20.5377613],
                [105.7840392, 20.5383567],
                [105.7838445, 20.5387866],
                [105.7836001, 20.5391122],
                [105.7828048, 20.5399968],
                [105.7813603, 20.5414406],
                [105.7805414, 20.5424224],
                [105.7800268, 20.5428536],
                [105.7787277, 20.5438677],
                [105.7779695, 20.5447985],
                [105.7777994, 20.545205],
                [105.7776416, 20.5456579],
                [105.7774092, 20.5459139],
                [105.7759692, 20.5469315],
                [105.7750256, 20.5477356],
                [105.7740838, 20.5488294],
                [105.7735341, 20.5496433],
                [105.7732669, 20.5503401],
                [105.7730969, 20.5507931],
                [105.7726371, 20.5516917],
                [105.7723578, 20.552435],
                [105.7722978, 20.5527367],
                [105.772312, 20.5531077],
                [105.7723009, 20.5533745],
                [105.7722043, 20.5537111],
                [105.7719234, 20.5541412],
                [105.7716329, 20.5546109],
                [105.7715121, 20.5550404],
                [105.7715027, 20.5556318],
                [105.7714213, 20.5565482],
                [105.7713741, 20.5569774],
                [105.7711925, 20.5575695],
                [105.7708273, 20.5583479],
                [105.7707382, 20.5585765],
                [105.7703431, 20.5595913],
                [105.7698578, 20.5609732],
                [105.7696432, 20.5623539],
                [105.7693295, 20.5636074],
                [105.7690868, 20.5642417],
                [105.7690113, 20.5649591],
                [105.7687658, 20.5650784],
                [105.7685816, 20.5653102],
                [105.76827, 20.567195],
                [105.7679117, 20.569362],
                [105.7676412, 20.5700372],
                [105.7671266, 20.5716033],
                [105.7667994, 20.5722134],
                [105.7663791, 20.5728074],
                [105.7659493, 20.5733578],
                [105.7654587, 20.5743422],
                [105.7651219, 20.5753265],
                [105.7648151, 20.5758768],
                [105.7623269, 20.5783977],
                [105.7614052, 20.5792671],
                [105.7598075, 20.580803],
                [105.7563976, 20.5842512],
                [105.755881, 20.5847259],
                [105.7555658, 20.5848625],
                [105.7552651, 20.585026],
                [105.7549076, 20.5853386],
                [105.7546233, 20.5857205],
                [105.7543381, 20.5861136],
                [105.754124, 20.5865114],
                [105.7540326, 20.5870902],
                [105.7538181, 20.5876403],
                [105.753419, 20.5882194],
                [105.7529581, 20.5886831],
                [105.7526513, 20.5892043],
                [105.7523441, 20.5895231],
                [105.7518829, 20.5897841],
                [105.7512985, 20.5900164],
                [105.7502997, 20.5902819],
                [105.7499173, 20.5904769],
                [105.7493462, 20.5907788],
                [105.7487436, 20.5911709],
                [105.74811, 20.5916829],
                [105.747508, 20.5921948],
                [105.7470021, 20.592826],
                [105.7465607, 20.5936366],
                [105.7460563, 20.5945973],
                [105.7454914, 20.5958983],
                [105.7450814, 20.5969078],
                [105.7443228, 20.5979295],
                [105.7436895, 20.5985313],
                [105.7431185, 20.5988633],
                [105.7419886, 20.599462],
                [105.7416502, 20.5995783],
                [105.7410648, 20.5992029],
                [105.7402947, 20.5987411],
                [105.7396479, 20.5984527],
                [105.7355042, 20.5986907],
                [105.7328788, 20.5996192],
                [105.731736, 20.6002305],
                [105.730975, 20.6007729],
                [105.7304371, 20.6013743],
                [105.7301215, 20.6019148],
                [105.7296785, 20.6024258],
                [105.7279305, 20.6041973],
                [105.7273474, 20.6053554],
                [105.7264646, 20.6035086],
                [105.7258658, 20.6025219],
                [105.7253414, 20.6017124],
                [105.7244778, 20.6004406],
                [105.7228428, 20.5977807],
                [105.7201941, 20.5964532],
                [105.7184718, 20.5972944],
                [105.7169028, 20.5976725],
                [105.7145952, 20.5981673],
                [105.7124416, 20.5987197],
                [105.7109955, 20.5990686],
                [105.7103184, 20.5990695],
                [105.7090948, 20.5988444],
                [105.7082867, 20.5986957],
                [105.7075546, 20.599554],
                [105.7071418, 20.5997355],
                [105.70692, 20.5999161],
                [105.7067307, 20.6002463],
                [105.706446, 20.600607],
                [105.7059071, 20.6010286],
                [105.7052726, 20.6014205],
                [105.7044488, 20.6021429],
                [105.7041958, 20.6024735],
                [105.7040375, 20.6026538],
                [105.7040067, 20.6028636],
                [105.7040077, 20.6030733],
                [105.7041681, 20.6033721],
                [105.7042967, 20.6036711],
                [105.7046804, 20.6040888],
                [105.7049044, 20.6043874],
                [105.705097, 20.604746],
                [105.7052895, 20.6051047],
                [105.7053226, 20.605374],
                [105.7053245, 20.6057635],
                [105.7050722, 20.6062737],
                [105.7047247, 20.6068143],
                [105.7044719, 20.6071748],
                [105.7042876, 20.6074828],
                [105.7043205, 20.6077224],
                [105.7043799, 20.6079239],
                [105.7047951, 20.6082816],
                [105.7050512, 20.6086101],
                [105.7052754, 20.6089387],
                [105.7053085, 20.609208],
                [105.7051509, 20.6095381],
                [105.7047706, 20.6098692],
                [105.7041361, 20.6102612],
                [105.7038189, 20.6104722],
                [105.7036604, 20.6105926],
                [105.7036615, 20.6108323],
                [105.7037906, 20.6112211],
                [105.703984, 20.6117894],
                [105.7041127, 20.6120884],
                [105.7041138, 20.6123281],
                [105.7040831, 20.6125678],
                [105.7039254, 20.612868],
                [105.7037922, 20.6130859],
                [105.7037298, 20.6133856],
                [105.7038439, 20.6136888],
                [105.7039547, 20.6140334],
                [105.7040607, 20.6146722],
                [105.7040306, 20.615193],
                [105.7040325, 20.6156276],
                [105.7019529, 20.619855],
                [105.7012281, 20.6213556],
                [105.6997585, 20.6214363],
                [105.6988044, 20.6217299],
                [105.6986833, 20.6217672],
                [105.6986522, 20.6216657],
                [105.698395, 20.6210977],
                [105.6982018, 20.6205893],
                [105.6980095, 20.6202606],
                [105.6977538, 20.620022],
                [105.697403, 20.6198438],
                [105.696989, 20.6197557],
                [105.6964163, 20.6197579],
                [105.69613, 20.619789],
                [105.6958438, 20.6198202],
                [105.6954176, 20.6199181],
                [105.6941777, 20.6205681],
                [105.6932291, 20.6210861],
                [105.692096, 20.6216878],
                [105.6913259, 20.6212835],
                [105.6909563, 20.6210115],
                [105.6906488, 20.6213134],
                [105.6904405, 20.6211003],
                [105.6898653, 20.6205335],
                [105.6894498, 20.6201158],
                [105.6881595, 20.6185356],
                [105.6874855, 20.6172504],
                [105.6873497, 20.6167743],
                [105.6868256, 20.6161383],
                [105.6864253, 20.6159941],
                [105.6856869, 20.6162264],
                [105.6851638, 20.6164005],
                [105.6848868, 20.6164587],
                [105.684148, 20.6164306],
                [105.6835938, 20.6162576],
                [105.6828546, 20.6158822],
                [105.682559, 20.6160402],
                [105.6821819, 20.6170901],
                [105.6819329, 20.6173588],
                [105.6815944, 20.617388],
                [105.6809171, 20.6173021],
                [105.6800239, 20.6168111],
                [105.6794695, 20.6165224],
                [105.678485, 20.6169283],
                [105.6776233, 20.617074],
                [105.6776947, 20.6168684],
                [105.6773147, 20.616293],
                [105.6767539, 20.6160494],
                [105.6761222, 20.6162314],
                [105.6754065, 20.6165843],
                [105.6751169, 20.6167289],
                [105.6748217, 20.6165849],
                [105.6745426, 20.6163718],
                [105.6737129, 20.6159495],
                [105.6734358, 20.6158919],
                [105.6723586, 20.615922],
                [105.6716814, 20.6159804],
                [105.6708565, 20.6160916],
                [105.6703805, 20.616393],
                [105.6703486, 20.6165495],
                [105.6699875, 20.6167872],
                [105.6691139, 20.6172797],
                [105.6681754, 20.6174299],
                [105.6663515, 20.6170075],
                [105.6661899, 20.6169932],
                [105.6661089, 20.6171179],
                [105.6657913, 20.6183243],
                [105.6658226, 20.6187582],
                [105.6657317, 20.6201179],
                [105.6653945, 20.6213332],
                [105.6660725, 20.6222004],
                [105.6668116, 20.6226336],
                [105.666751, 20.6235303],
                [105.6658288, 20.6245148],
                [105.6635553, 20.6281329],
                [105.6656995, 20.6301801],
                [105.6659959, 20.6298431],
                [105.6661754, 20.6296676],
                [105.6664919, 20.6295263],
                [105.6674549, 20.6293573],
                [105.6676304, 20.6292562],
                [105.6686039, 20.6292274],
                [105.6691288, 20.6298119],
                [105.6693839, 20.6302302],
                [105.6695773, 20.6307986],
                [105.670208, 20.6321304],
                [105.6710863, 20.6322227],
                [105.6721352, 20.6319176],
                [105.6723814, 20.6311004],
                [105.672606, 20.6302647],
                [105.6729744, 20.6294834],
                [105.6732511, 20.6291938],
                [105.6739588, 20.6289906],
                [105.6744228, 20.6290559],
                [105.6751285, 20.6292209],
                [105.6754982, 20.6294231],
                [105.6758676, 20.6295672],
                [105.6764526, 20.6297691],
                [105.6770066, 20.6298264],
                [105.6772835, 20.6297394],
                [105.6777135, 20.6289578],
                [105.6780822, 20.6283789],
                [105.6783591, 20.6284076],
                [105.6792612, 20.628913],
                [105.6800003, 20.6293172],
                [105.6811927, 20.6301113],
                [105.6847663, 20.6330004],
                [105.6835685, 20.6351134],
                [105.6856023, 20.6371651],
                [105.6865574, 20.6379162],
                [105.6852348, 20.6386698],
                [105.6843738, 20.639307],
                [105.6833467, 20.6398197],
                [105.6822525, 20.6400932],
                [105.6820941, 20.6402436],
                [105.6819369, 20.6406636],
                [105.6816838, 20.640994],
                [105.6813348, 20.641235],
                [105.6810177, 20.6415059],
                [105.6806381, 20.6419866],
                [105.6803536, 20.6424071],
                [105.6801326, 20.6428273],
                [105.6799443, 20.6433972],
                [105.6798505, 20.643787],
                [105.6796617, 20.6442669],
                [105.6793455, 20.6447175],
                [105.6789981, 20.6453179],
                [105.6787158, 20.6462476],
                [105.6785904, 20.6466674],
                [105.6785916, 20.6469669],
                [105.6786884, 20.647236],
                [105.678913, 20.6476545],
                [105.6793621, 20.6484614],
                [105.6796826, 20.6489693],
                [105.6799086, 20.6497172],
                [105.6801662, 20.6503752],
                [105.6806153, 20.6511821],
                [105.6808091, 20.6518103],
                [105.6809072, 20.6524089],
                [105.6808767, 20.6527086],
                [105.6806554, 20.653039],
                [105.6802437, 20.6534899],
                [105.6793743, 20.6542101],
                [105.6795449, 20.6546023],
                [105.6798388, 20.6551368],
                [105.6803307, 20.6560316],
                [105.6797467, 20.6567554],
                [105.6793701, 20.6574172],
                [105.6789916, 20.6581974],
                [105.6787951, 20.6587236],
                [105.6783355, 20.6605474],
                [105.6784895, 20.6606629],
                [105.6790129, 20.660836],
                [105.6798139, 20.6614719],
                [105.6800584, 20.6619072],
                [105.68006, 20.6622667],
                [105.6800295, 20.6625663],
                [105.6797769, 20.6630166],
                [105.6794613, 20.6636169],
                [105.6792225, 20.6641926],
                [105.6778121, 20.6667581],
                [105.6769146, 20.6714981],
                [105.6764388, 20.6715965],
                [105.676294, 20.6676816],
                [105.6770825, 20.6676214],
                [105.6775385, 20.6656739],
                [105.6769967, 20.6654179],
                [105.6770886, 20.664331],
                [105.6752717, 20.6636473],
                [105.6745644, 20.6649636],
                [105.6737438, 20.664937],
                [105.6721945, 20.6678755],
                [105.6735527, 20.6703217],
                [105.6724919, 20.6712354],
                [105.671981, 20.6714911],
                [105.6716907, 20.6726494],
                [105.6711341, 20.673818],
                [105.6703019, 20.6745577],
                [105.669556, 20.6761991],
                [105.6699682, 20.6769421],
                [105.6698147, 20.6773182],
                [105.6696305, 20.6777521],
                [105.6691086, 20.6788514],
                [105.6687101, 20.6802398],
                [105.6687546, 20.6804592],
                [105.6689477, 20.6806405],
                [105.6695266, 20.6806096],
                [105.6711344, 20.6804266],
                [105.6719382, 20.6802746],
                [105.6723726, 20.6805543],
                [105.6725266, 20.6806698],
                [105.6729732, 20.6809174],
                [105.6732608, 20.681015],
                [105.6735671, 20.6812003],
                [105.6750758, 20.68198],
                [105.6759448, 20.6826143],
                [105.6769106, 20.6835205],
                [105.6774253, 20.6836108],
                [105.6781328, 20.68361],
                [105.6786474, 20.6836699],
                [105.6789048, 20.6837603],
                [105.6791303, 20.6840626],
                [105.6792185, 20.6847171],
                [105.679697, 20.6873432],
                [105.6797672, 20.6877469],
                [105.6797677, 20.6881806],
                [105.6797835, 20.6885783],
                [105.6795917, 20.6895763],
                [105.6794318, 20.6903326],
                [105.6794324, 20.690877],
                [105.6794648, 20.6910281],
                [105.67969, 20.6911489],
                [105.6799473, 20.6911486],
                [105.6804939, 20.6910573],
                [105.6807514, 20.6911478],
                [105.6808803, 20.6914501],
                [105.6810417, 20.6919337],
                [105.6811068, 20.6925385],
                [105.6815052, 20.6935632],
                [105.6819542, 20.6942474],
                [105.6825305, 20.6951754],
                [105.6825309, 20.6954645],
                [105.6816086, 20.6963908],
                [105.68124, 20.6969695],
                [105.6811791, 20.6975479],
                [105.6813645, 20.6981839],
                [105.6816111, 20.6984728],
                [105.6808954, 20.6990415],
                [105.6803365, 20.6995472],
                [105.6792339, 20.7004843],
                [105.678526, 20.7011121],
                [105.6778598, 20.7016574],
                [105.6773064, 20.7021786],
                [105.6762768, 20.7034229],
                [105.6755079, 20.7040599],
                [105.6744315, 20.7047261],
                [105.6738625, 20.7051894],
                [105.6732477, 20.7058838],
                [105.671521, 20.7074901],
                [105.671033, 20.7082741],
                [105.6705857, 20.7090903],
                [105.6704212, 20.7105712],
                [105.6701765, 20.7119015],
                [105.669601, 20.7127571],
                [105.6691314, 20.7132905],
                [105.6684605, 20.7138222],
                [105.6678605, 20.714281],
                [105.6674593, 20.714662],
                [105.6666561, 20.715201],
                [105.665854, 20.7160077],
                [105.6650106, 20.716938],
                [105.6640239, 20.7172345],
                [105.6629164, 20.7203314],
                [105.6621883, 20.7218072],
                [105.6618722, 20.7226182],
                [105.6619012, 20.7229328],
                [105.6618476, 20.7238258],
                [105.6616115, 20.7249296],
                [105.6617832, 20.7255518],
                [105.6618047, 20.7259732],
                [105.6621909, 20.7262542],
                [105.6633711, 20.7278999],
                [105.6640577, 20.7285421],
                [105.6643581, 20.7290237],
                [105.6648087, 20.7301676],
                [105.6656885, 20.732034],
                [105.66571, 20.7323751],
                [105.6659245, 20.7331979],
                [105.6661391, 20.7335792],
                [105.6665468, 20.734101],
                [105.6670618, 20.7350442],
                [105.6660533, 20.7352649],
                [105.6653345, 20.7354455],
                [105.6650877, 20.7356261],
                [105.6648731, 20.7357465],
                [105.6646371, 20.7359071],
                [105.6644547, 20.7359572],
                [105.6635535, 20.7366496],
                [105.6633282, 20.7369907],
                [105.6630063, 20.7374422],
                [105.6626522, 20.7377734],
                [105.6622445, 20.7380443],
                [105.6618261, 20.738275],
                [105.6615257, 20.7385158],
                [105.6611931, 20.7389975],
                [105.6610322, 20.7392784],
                [105.6606674, 20.7398804],
                [105.6604206, 20.7400911],
                [105.6593585, 20.7406229],
                [105.6590152, 20.7408436],
                [105.6586826, 20.7409038],
                [105.6582427, 20.7409741],
                [105.6579637, 20.7409941],
                [105.6578565, 20.7410644],
                [105.6577384, 20.7415259],
                [105.6576633, 20.7417065],
                [105.6575775, 20.7421279],
                [105.6575668, 20.7424891],
                [105.6575775, 20.7427701],
                [105.6575775, 20.7430309],
                [105.6570518, 20.7438737],
                [105.6568158, 20.7441446],
                [105.6565797, 20.7442851],
                [105.6563008, 20.744546],
                [105.6557429, 20.7452483],
                [105.655539, 20.7456898],
                [105.6554854, 20.7458804],
                [105.6552923, 20.7462416],
                [105.6549275, 20.7467834],
                [105.6547236, 20.7471847],
                [105.6546378, 20.7474456],
                [105.6545842, 20.7476261],
                [105.6544447, 20.7477666],
                [105.6543159, 20.7483285],
                [105.6542516, 20.7487499],
                [105.6541443, 20.7494722],
                [105.6540155, 20.7500943],
                [105.653758, 20.7504354],
                [105.6533074, 20.7507966],
                [105.6530928, 20.7509772],
                [105.6519985, 20.7514587],
                [105.6514621, 20.7517999],
                [105.6509685, 20.7523416],
                [105.6509042, 20.7527229],
                [105.6509471, 20.7531041],
                [105.6512689, 20.753385],
                [105.651226, 20.7537061],
                [105.6511402, 20.7542077],
                [105.6510329, 20.754609],
                [105.65099, 20.755231],
                [105.6510114, 20.7558731],
                [105.6510114, 20.756455],
                [105.6511187, 20.7570168],
                [105.6511402, 20.7574382],
                [105.6510544, 20.7578796],
                [105.6508612, 20.7582207],
                [105.6506681, 20.7585016],
                [105.6504321, 20.7588628],
                [105.6502819, 20.7595851],
                [105.6498098, 20.7601068],
                [105.6498527, 20.7605883],
                [105.6499386, 20.7611301],
                [105.6501746, 20.7616718],
                [105.6500673, 20.7622737],
                [105.6498313, 20.7625546],
                [105.6497025, 20.7629158],
                [105.6496167, 20.7634575],
                [105.6495738, 20.7641798],
                [105.6493163, 20.764561],
                [105.6490373, 20.7649422],
                [105.6489301, 20.7652633],
                [105.6488013, 20.7657448],
                [105.6485438, 20.7661461],
                [105.6483936, 20.7665072],
                [105.6483936, 20.7668684],
                [105.6483507, 20.7679719],
                [105.6491017, 20.7701186],
                [105.6492734, 20.7706603],
                [105.6495309, 20.7710616],
                [105.6498742, 20.7716635],
                [105.6499171, 20.7726666],
                [105.6498313, 20.7730879],
                [105.6498956, 20.7734089],
                [105.6500673, 20.7737299],
                [105.648812, 20.7746127],
                [105.6485331, 20.7750139],
                [105.6482863, 20.7755556],
                [105.6481898, 20.776007],
                [105.6481254, 20.776328],
                [105.6478143, 20.7768296],
                [105.6477582, 20.7775124],
                [105.6477284, 20.7781838],
                [105.6476914, 20.7790194],
                [105.6477928, 20.7794678],
                [105.6479108, 20.7799894],
                [105.6479108, 20.7804006],
                [105.6480181, 20.7809423],
                [105.6484794, 20.7820658],
                [105.648694, 20.7828682],
                [105.6484151, 20.7835804],
                [105.6483883, 20.7837459],
                [105.6485224, 20.7843628],
                [105.6485626, 20.7846963],
                [105.6484553, 20.7847239],
                [105.6482756, 20.7860479],
                [105.6481683, 20.7864692],
                [105.6484258, 20.7865118],
                [105.6485031, 20.787736],
                [105.6482823, 20.7897879],
                [105.6480136, 20.7922845],
                [105.6385726, 20.7926495],
                [105.6372326, 20.7926725],
                [105.6366526, 20.7928825],
                [105.6362286, 20.7933225],
                [105.6360726, 20.7937425],
                [105.6358266, 20.7937845],
                [105.6337496, 20.7927375],
                [105.6333476, 20.7925915],
                [105.6313966, 20.7928775],
                [105.6306236, 20.7930965],
                [105.6304056, 20.7934905],
                [105.6303996, 20.7937675],
                [105.6312946, 20.7946055],
                [105.6326356, 20.7957785],
                [105.6326126, 20.7959885],
                [105.6324336, 20.7961765],
                [105.6318986, 20.7964495],
                [105.6307826, 20.7970795],
                [105.6307156, 20.7973105],
                [105.6310066, 20.7976245],
                [105.6316096, 20.7980855],
                [105.6325026, 20.7982735],
                [105.6326816, 20.7985245],
                [105.6326156, 20.7989655],
                [105.6324596, 20.7996575],
                [105.6325936, 20.7998665],
                [105.6328396, 20.7999715],
                [105.6333526, 20.7999705],
                [105.6337776, 20.8002225],
                [105.6338896, 20.8003475],
                [105.6339336, 20.8005365],
                [105.6339346, 20.8009135],
                [105.6340066, 20.8012425],
                [105.6340016, 20.8016685],
                [105.6339796, 20.8018575],
                [105.6336106, 20.8021345],
                [105.6329996, 20.8023755],
                [105.6325436, 20.8025965],
                [105.6322616, 20.8027805],
                [105.6321556, 20.8029265],
                [105.6320986, 20.8032015],
                [105.6321016, 20.8038325],
                [105.6321216, 20.8039785],
                [105.6322186, 20.8040325],
                [105.6324236, 20.8041515],
                [105.6327546, 20.8043785],
                [105.6328626, 20.8044785],
                [105.6328816, 20.8045605],
                [105.6328736, 20.8047895],
                [105.6328446, 20.8049015],
                [105.6327476, 20.8049935],
                [105.6325156, 20.8050675],
                [105.6323596, 20.8051135],
                [105.6322046, 20.8052065],
                [105.6321466, 20.8053155],
                [105.6320986, 20.8054715],
                [105.6320996, 20.8055635],
                [105.6321186, 20.8056735],
                [105.6321486, 20.8057915],
                [105.6322076, 20.8059015],
                [105.6323636, 20.8060385],
                [105.6325096, 20.8061655],
                [105.6326176, 20.8063295],
                [105.6326766, 20.8064575],
                [105.6327356, 20.8066405],
                [105.6327166, 20.8067875],
                [105.6326976, 20.8069705],
                [105.6325726, 20.8072635],
                [105.6324476, 20.8074745],
                [105.6323116, 20.8076305],
                [105.6321766, 20.8077685],
                [105.6321519, 20.8080814],
                [105.6322832, 20.8091964],
                [105.6319915, 20.8100397],
                [105.6314225, 20.8104848],
                [105.6311084, 20.810908],
                [105.6311361, 20.8111957],
                [105.6312012, 20.8114596],
                [105.6319525, 20.8116895],
                [105.631992, 20.8127438],
                [105.632337, 20.8137432],
                [105.6313715, 20.8149267],
                [105.6309104, 20.8161877],
                [105.6310203, 20.8166365],
                [105.6330418, 20.8193358],
                [105.6361986, 20.8230226],
                [105.6368336, 20.8236186],
                [105.6371558, 20.8239876],
                [105.6376926, 20.8246026],
                [105.6383606, 20.8249896],
                [105.6387254, 20.8249001],
                [105.638955, 20.8246645],
                [105.639473, 20.8245274],
                [105.6399187, 20.823993],
                [105.6407618, 20.8239604],
                [105.6411842, 20.8241009],
                [105.6412667, 20.8243896],
                [105.6411168, 20.8247151],
                [105.641085, 20.8250856],
                [105.6409797, 20.825255],
                [105.6415737, 20.8258416],
                [105.6417665, 20.8261276],
                [105.6418149, 20.8264737],
                [105.641944, 20.8272714],
                [105.6420537, 20.8278257],
                [105.6419608, 20.8281447],
                [105.6418261, 20.8283511],
                [105.6416156, 20.8286896],
                [105.641385, 20.8290075],
                [105.6410391, 20.8294873],
                [105.6407304, 20.8301443],
                [105.6406408, 20.8305543],
                [105.6424009, 20.8310516],
                [105.6416854, 20.8330324],
                [105.6367188, 20.8321218],
                [105.6363318, 20.8320591],
                [105.6360605, 20.8324388],
                [105.6359406, 20.8325438],
                [105.6355323, 20.8328299],
                [105.6353996, 20.833004],
                [105.635369, 20.833202],
                [105.6353945, 20.8333785],
                [105.6354762, 20.833555],
                [105.6356267, 20.8337148],
                [105.6358385, 20.8338984],
                [105.6359763, 20.8341345],
                [105.6360274, 20.8343277],
                [105.6360605, 20.834776],
                [105.6360963, 20.8349907],
                [105.6361575, 20.8352125],
                [105.6361447, 20.8353937],
                [105.6360733, 20.8355273],
                [105.6359559, 20.8356155],
                [105.6357849, 20.8356537],
                [105.6355323, 20.8356513],
                [105.635369, 20.8357157],
                [105.6352746, 20.835823],
                [105.635221, 20.8359828],
                [105.635221, 20.8361473],
                [105.6352746, 20.8362976],
                [105.6356216, 20.8367579],
                [105.6356803, 20.8369224],
                [105.6356803, 20.8371251],
                [105.6356752, 20.8373207],
                [105.6357518, 20.837471],
                [105.6360044, 20.837688],
                [105.6363412, 20.8380266],
                [105.6364867, 20.8383438],
                [105.6366449, 20.8388756],
                [105.6367098, 20.8393545],
                [105.635994, 20.8392079],
                [105.6353163, 20.8391226],
                [105.6352504, 20.838877],
                [105.6351185, 20.8386975],
                [105.6337691, 20.8385583],
                [105.6338621, 20.8375803],
                [105.6338514, 20.8372052],
                [105.6338253, 20.8370855],
                [105.6337835, 20.8369666],
                [105.6337114, 20.8368435],
                [105.6336193, 20.8367238],
                [105.6329896, 20.836147],
                [105.632156, 20.835341],
                [105.6320147, 20.8352749],
                [105.630607, 20.834961],
                [105.6295342, 20.8346823],
                [105.6284033, 20.8342912],
                [105.6259716, 20.8328361],
                [105.6258557, 20.832783],
                [105.6257527, 20.8327459],
                [105.6256422, 20.8327148],
                [105.6249974, 20.8326184],
                [105.6249009, 20.8325999],
                [105.6248145, 20.8325438],
                [105.6245978, 20.832255],
                [105.6242674, 20.831886],
                [105.6240807, 20.8317817],
                [105.6238661, 20.8317376],
                [105.6236429, 20.8317456],
                [105.6225186, 20.8318319],
                [105.6218362, 20.83186],
                [105.6214723, 20.8317098],
                [105.6211093, 20.8314813],
                [105.620894, 20.8313474],
                [105.6207219, 20.8312806],
                [105.6202824, 20.8314129],
                [105.6200784, 20.8315184],
                [105.619629, 20.8315792],
                [105.618505, 20.8315497],
                [105.6174628, 20.8315097],
                [105.6172196, 20.8314921],
                [105.6168903, 20.8314664],
                [105.6166469, 20.8314673],
                [105.6164184, 20.8315895],
                [105.6160759, 20.8318602],
                [105.6159049, 20.832063],
                [105.6157346, 20.8324138],
                [105.6154484, 20.8331775],
                [105.6147205, 20.8343318],
                [105.6140251, 20.8350135],
                [105.6136481, 20.835261],
                [105.6127765, 20.8358331],
                [105.6119895, 20.8360755],
                [105.6114116, 20.8363472],
                [105.6110907, 20.8366186],
                [105.6107114, 20.8371311],
                [105.6101282, 20.837825],
                [105.6095825, 20.8381568],
                [105.6091331, 20.8383982],
                [105.608838, 20.8384677],
                [105.6086193, 20.8385191],
                [105.6074951, 20.8382486],
                [105.6068528, 20.8381887],
                [105.6061463, 20.8382796],
                [105.6053116, 20.838672],
                [105.6047336, 20.8387325],
                [105.6042521, 20.838974],
                [105.6038668, 20.8389743],
                [105.6032884, 20.8385827],
                [105.600946, 20.8414176],
                [105.5969697, 20.8386404],
                [105.5969457, 20.8384477],
                [105.5966287, 20.8374298],
                [105.5966077, 20.8369288],
                [105.5967679, 20.8364162],
                [105.5967034, 20.8359641],
                [105.596671, 20.8355422],
                [105.596735, 20.8352408],
                [105.5973127, 20.8346677],
                [105.5977941, 20.8342153],
                [105.5980001, 20.8338451],
                [105.597679, 20.8332202],
                [105.5965184, 20.8317488],
                [105.5960475, 20.8312754],
                [105.5956313, 20.8309885],
                [105.5948075, 20.8306687],
                [105.5936351, 20.8307873],
                [105.5932379, 20.830777],
                [105.5922935, 20.8310176],
                [105.5912812, 20.8307443],
                [105.5903075, 20.8308354],
                [105.5885173, 20.829861],
                [105.5881, 20.8292855],
                [105.5878828, 20.8290827],
                [105.5872781, 20.8275024],
                [105.5871297, 20.8255624],
                [105.5877603, 20.8225111],
                [105.5880586, 20.8209432],
                [105.5878369, 20.8202742],
                [105.586767, 20.8196354],
                [105.5854056, 20.8192708],
                [105.5846271, 20.8183579],
                [105.5839458, 20.8171709],
                [105.5835561, 20.8158012],
                [105.5833854, 20.8143713],
                [105.5832839, 20.8132673],
                [105.5833585, 20.8118179],
                [105.582064, 20.8115991],
                [105.5809106, 20.8110788],
                [105.5798602, 20.8102984],
                [105.5784666, 20.8090077],
                [105.5770831, 20.8086365],
                [105.5759694, 20.8080469],
                [105.5754867, 20.8079185],
                [105.5749692, 20.8079827],
                [105.57362, 20.8082394],
                [105.5715136, 20.8084946],
                [105.5708169, 20.8084077],
                [105.5703631, 20.8083942],
                [105.5691754, 20.8083618],
                [105.5664499, 20.8083638],
                [105.5632719, 20.8085193],
                [105.5612385, 20.8083947],
                [105.5594282, 20.8080935],
                [105.5559542, 20.806451],
                [105.5530561, 20.8064595],
                [105.5520462, 20.8069012],
                [105.5510221, 20.807929],
                [105.5536591, 20.8083598],
                [105.555495, 20.8089949],
                [105.558464, 20.8113967],
                [105.5593104, 20.8125059],
                [105.5597568, 20.813822],
                [105.5600121, 20.8151981],
                [105.5601398, 20.8160954],
                [105.5601405, 20.8177109],
                [105.560521, 20.8191907],
                [105.5609696, 20.8201039],
                [105.561679, 20.8212926],
                [105.5622482, 20.8218964],
                [105.5630127, 20.822212],
                [105.5638585, 20.8222254],
                [105.5653308, 20.821942],
                [105.5665537, 20.821873],
                [105.5672609, 20.8219879],
                [105.5685557, 20.8220622],
                [105.5705179, 20.8219379],
                [105.5718978, 20.8217013],
                [105.5726356, 20.8219371],
                [105.5728648, 20.8220259],
                [105.5729316, 20.8220257],
                [105.5730528, 20.8220672],
                [105.5733103, 20.8220932],
                [105.5736157, 20.8221729],
                [105.5742166, 20.8222873],
                [105.5746458, 20.8223396],
                [105.575018, 20.8224729],
                [105.5755337, 20.8226953],
                [105.5756388, 20.8227667],
                [105.5757536, 20.822883],
                [105.5760195, 20.8232371],
                [105.5763455, 20.8237293],
                [105.5766518, 20.8240962],
                [105.576805, 20.8242301],
                [105.5768718, 20.8242568],
                [105.5770339, 20.8243011],
                [105.5771677, 20.8243903],
                [105.5774355, 20.8245868],
                [105.5776934, 20.8247562],
                [105.5777986, 20.8248367],
                [105.5779134, 20.8249529],
                [105.5780475, 20.8251228],
                [105.5784306, 20.8255701],
                [105.5789652, 20.826122],
                [105.5794818, 20.8265957],
                [105.5795585, 20.826712],
                [105.5796068, 20.8268913],
                [105.5796266, 20.8270707],
                [105.579627, 20.8272053],
                [105.5796182, 20.8273579],
                [105.5795902, 20.8275284],
                [105.5795319, 20.8279207],
                [105.5795398, 20.828099],
                [105.5795998, 20.8294649],
                [105.5798688, 20.8297828],
                [105.5802565, 20.8302442],
                [105.5803632, 20.8303982],
                [105.5804411, 20.8305702],
                [105.5804609, 20.8307244],
                [105.580481, 20.8309149],
                [105.5805201, 20.8310509],
                [105.5804101, 20.8331731],
                [105.5803796, 20.8337296],
                [105.5804565, 20.8340946],
                [105.5805447, 20.8345813],
                [105.5805997, 20.834855],
                [105.5805977, 20.8349907],
                [105.5804804, 20.8353462],
                [105.5802878, 20.8357324],
                [105.5802887, 20.8359759],
                [105.580354, 20.8361482],
                [105.5804839, 20.8362492],
                [105.5806457, 20.8362689],
                [105.5808506, 20.8362784],
                [105.5810558, 20.8363284],
                [105.5812398, 20.8364799],
                [105.5813699, 20.8366825],
                [105.5814679, 20.8369358],
                [105.5816413, 20.8371381],
                [105.581901, 20.8373502],
                [105.5820847, 20.8374613],
                [105.5823005, 20.8375112],
                [105.582689, 20.8375505],
                [105.5830127, 20.8376001],
                [105.583326, 20.8377106],
                [105.5834556, 20.8377915],
                [105.5835207, 20.8378623],
                [105.5835427, 20.8379839],
                [105.5835216, 20.8381158],
                [105.5835118, 20.8383695],
                [105.5835129, 20.8386434],
                [105.5835997, 20.8387954],
                [105.5837511, 20.8388963],
                [105.5840537, 20.8390474],
                [105.584398, 20.8391902],
                [105.5848086, 20.8393814],
                [105.5851759, 20.8395424],
                [105.585295, 20.8396637],
                [105.5853817, 20.8397851],
                [105.5854471, 20.8399673],
                [105.5855017, 20.8401294],
                [105.5855668, 20.8402406],
                [105.5856643, 20.8403417],
                [105.5858049, 20.8404527],
                [105.5860104, 20.8405838],
                [105.5861833, 20.8406644],
                [105.5863025, 20.8408262],
                [105.5863464, 20.8410187],
                [105.5863146, 20.8411607],
                [105.5862503, 20.8412725],
                [105.5860783, 20.8414151],
                [105.5857124, 20.8415988],
                [105.5853466, 20.8418332],
                [105.5852026, 20.8419219],
                [105.5851966, 20.8423588],
                [105.5852803, 20.842784],
                [105.5854443, 20.8429766],
                [105.5856544, 20.8431014],
                [105.5859213, 20.8431721],
                [105.586188, 20.8431712],
                [105.5863972, 20.8430988],
                [105.5869874, 20.8430072],
                [105.587159, 20.8430425],
                [105.5872927, 20.8431316],
                [105.5874073, 20.8432209],
                [105.5875032, 20.8433639],
                [105.5875608, 20.8434891],
                [105.5876756, 20.8436498],
                [105.5876577, 20.8439009],
                [105.5876388, 20.8439726],
                [105.5875247, 20.8440268],
                [105.5873535, 20.844081],
                [105.5871062, 20.8441715],
                [105.587107, 20.8443506],
                [105.5872218, 20.8444936],
                [105.5873364, 20.8445649],
                [105.5874697, 20.8445645],
                [105.5876792, 20.8445638],
                [105.5878317, 20.8445811],
                [105.5880418, 20.8447418],
                [105.5881377, 20.8448847],
                [105.5881574, 20.8450639],
                [105.5881581, 20.845261],
                [105.5881588, 20.8454222],
                [105.5881787, 20.8456372],
                [105.5880836, 20.8457091],
                [105.5879505, 20.8457633],
                [105.5877791, 20.845764],
                [105.5874738, 20.8456217],
                [105.5873584, 20.8453354],
                [105.5872424, 20.8449057],
                [105.5871464, 20.844709],
                [105.5869166, 20.8443872],
                [105.5866114, 20.8442807],
                [105.5864399, 20.8442814],
                [105.5863639, 20.8443174],
                [105.5862881, 20.8444431],
                [105.5863083, 20.8447118],
                [105.5862706, 20.8448194],
                [105.5861755, 20.8448556],
                [105.5860042, 20.844892],
                [105.5857757, 20.8449107],
                [105.5856425, 20.844965],
                [105.5855285, 20.845055],
                [105.585492, 20.8451769],
                [105.5855298, 20.8453596],
                [105.5856637, 20.8455383],
                [105.5856833, 20.8456637],
                [105.5856463, 20.8459326],
                [105.5855326, 20.8461121],
                [105.5855331, 20.8462376],
                [105.5856288, 20.8463268],
                [105.5857621, 20.8463264],
                [105.5859717, 20.8463256],
                [105.586124, 20.8463251],
                [105.5862768, 20.846432],
                [105.5862961, 20.8465037],
                [105.5861632, 20.8465938],
                [105.5859773, 20.8466945],
                [105.5858971, 20.846738],
                [105.5858213, 20.8468458],
                [105.5858977, 20.8469172],
                [105.5864186, 20.8473473],
                [105.5864508, 20.8475278],
                [105.5863868, 20.8476783],
                [105.5862265, 20.8478289],
                [105.5857136, 20.8480399],
                [105.5853609, 20.8481302],
                [105.5850403, 20.8481906],
                [105.5847518, 20.8484014],
                [105.5847846, 20.8497557],
                [105.5847946, 20.850652],
                [105.5847446, 20.8526576],
                [105.5841532, 20.8547139],
                [105.5839158, 20.8552494],
                [105.5838878, 20.8557561],
                [105.5838891, 20.8561218],
                [105.5840713, 20.8567685],
                [105.5843923, 20.8575616],
                [105.5849265, 20.8583296],
                [105.5844641, 20.8586362],
                [105.5829409, 20.8595051],
                [105.5811715, 20.8580505],
                [105.5801104, 20.8580766],
                [105.5795665, 20.8586897],
                [105.5784152, 20.859991],
                [105.5793768, 20.861511],
                [105.5787441, 20.8620601],
                [105.5771977, 20.863276],
                [105.5754464, 20.8642976],
                [105.5755111, 20.8650501],
                [105.5754795, 20.8662843],
                [105.5754158, 20.8671271],
                [105.5752876, 20.8672777],
                [105.5742292, 20.867519],
                [105.573765, 20.8681187],
                [105.5736569, 20.868364],
                [105.5734278, 20.8687837],
                [105.5732034, 20.8689042],
                [105.5727056, 20.8688442],
                [105.5720319, 20.868694],
                [105.5715188, 20.868834],
                [105.569905, 20.8698353],
                [105.5696803, 20.8699831],
                [105.5691203, 20.8705748],
                [105.5688605, 20.8708824],
                [105.5686442, 20.8711592],
                [105.568561, 20.8714528],
                [105.5684964, 20.8716166],
                [105.5684646, 20.8718518],
                [105.5685303, 20.8719641],
                [105.5685961, 20.8721275],
                [105.5685964, 20.8722093],
                [105.5685533, 20.8723219],
                [105.5684884, 20.8723938],
                [105.5683691, 20.8724555],
                [105.5682604, 20.8724558],
                [105.5680755, 20.8724565],
                [105.567956, 20.8724876],
                [105.5677281, 20.8726212],
                [105.5675982, 20.8727444],
                [105.56749, 20.8728981],
                [105.5673604, 20.8730929],
                [105.5672413, 20.8732568],
                [105.5671004, 20.8733596],
                [105.5669808, 20.8733804],
                [105.5667529, 20.8734834],
                [105.5665686, 20.8736272],
                [105.5663737, 20.8738324],
                [105.5662656, 20.8739963],
                [105.5661204, 20.8745932],
                [105.5660348, 20.8749513],
                [105.5659375, 20.875105],
                [105.5658724, 20.8751462],
                [105.5657528, 20.8751466],
                [105.565285, 20.875056],
                [105.5650021, 20.8749956],
                [105.564752, 20.8749965],
                [105.564589, 20.8750175],
                [105.5644805, 20.8750792],
                [105.5644156, 20.8751612],
                [105.5644162, 20.8753453],
                [105.5643735, 20.8755295],
                [105.5642439, 20.8757753],
                [105.5640056, 20.8760215],
                [105.563843, 20.876155],
                [105.5636909, 20.8761862],
                [105.5632884, 20.876116],
                [105.562657, 20.8759135],
                [105.5616646, 20.8755648],
                [105.5616103, 20.8757545],
                [105.5616109, 20.8759145],
                [105.5616492, 20.8760211],
                [105.5618197, 20.8760917],
                [105.561877, 20.8762692],
                [105.561783, 20.8764117],
                [105.5615562, 20.8764836],
                [105.5613862, 20.8765376],
                [105.561235, 20.8765736],
                [105.5609719, 20.8770544],
                [105.5606322, 20.8772868],
                [105.5603489, 20.8773943],
                [105.5599897, 20.8774489],
                [105.5597253, 20.8775563],
                [105.5595933, 20.8776812],
                [105.5595942, 20.8779302],
                [105.5596517, 20.8781255],
                [105.5598789, 20.878196],
                [105.5599929, 20.8782845],
                [105.5599935, 20.8784445],
                [105.5598615, 20.8785872],
                [105.559597, 20.8786769],
                [105.5593896, 20.8788554],
                [105.5593335, 20.8790333],
                [105.5592963, 20.8792113],
                [105.559297, 20.879389],
                [105.5594301, 20.8795665],
                [105.5596004, 20.8796014],
                [105.5598652, 20.8795828],
                [105.5600926, 20.8796709],
                [105.5601309, 20.8797953],
                [105.5600368, 20.8799378],
                [105.5598479, 20.8800095],
                [105.5597534, 20.8800454],
                [105.5595647, 20.8801705],
                [105.5594894, 20.8802419],
                [105.5593176, 20.880562],
                [105.5592637, 20.8807144],
                [105.5592839, 20.8809637],
                [105.5590743, 20.880879],
                [105.5590015, 20.8808426],
                [105.5585218, 20.8806026],
                [105.5577365, 20.8801917],
                [105.5575118, 20.8800741],
                [105.5571664, 20.8799115],
                [105.5567477, 20.8796771],
                [105.5563204, 20.8796839],
                [105.555167, 20.8796844],
                [105.5550776, 20.8796844],
                [105.5544907, 20.8797658],
                [105.5541627, 20.8798962],
                [105.5539729, 20.8800428],
                [105.5538521, 20.880173],
                [105.5538176, 20.880352],
                [105.5538177, 20.8806106],
                [105.5538178, 20.8808727],
                [105.5538525, 20.881133],
                [105.5539216, 20.8814259],
                [105.5539218, 20.8820117],
                [105.5539046, 20.8822558],
                [105.5538357, 20.8825487],
                [105.5537191, 20.8826786],
                [105.5537044, 20.8826951],
                [105.5535947, 20.8827989],
                [105.5535344, 20.8828445],
                [105.5534733, 20.8828906],
                [105.5532842, 20.8828907],
                [105.5530417, 20.8828908],
                [105.5528346, 20.8828745],
                [105.5528535, 20.8832445],
                [105.5561685, 20.883689],
                [105.5561366, 20.884524],
                [105.5560389, 20.8850858],
                [105.5559387, 20.8851176],
                [105.5558252, 20.8851975],
                [105.5557308, 20.8852845],
                [105.5551196, 20.8860486],
                [105.5550522, 20.8861555],
                [105.5549762, 20.8863467],
                [105.5553778, 20.8865193],
                [105.5555575, 20.8867038],
                [105.555631, 20.886863],
                [105.5556505, 20.8870025],
                [105.5555686, 20.8872745],
                [105.5555598, 20.8873814],
                [105.5555983, 20.8874863],
                [105.5559331, 20.8876718],
                [105.5563422, 20.887853],
                [105.5566155, 20.8883233],
                [105.5568108, 20.8888649],
                [105.5570199, 20.8891722],
                [105.5573265, 20.8894526],
                [105.557872, 20.8897578],
                [105.5585066, 20.8900936],
                [105.5591134, 20.8903084],
                [105.5596129, 20.8904764],
                [105.5598422, 20.8907502],
                [105.5599021, 20.8913877],
                [105.559909, 20.8923265],
                [105.5600015, 20.8938443],
                [105.5604636, 20.8962482],
                [105.5609758, 20.8973888],
                [105.5612452, 20.8980797],
                [105.561442, 20.8983009],
                [105.5618313, 20.8985379],
                [105.5622308, 20.8986533],
                [105.5626295, 20.8985522],
                [105.5633346, 20.8986214],
                [105.563792, 20.898672],
                [105.5638082, 20.8990084],
                [105.563625, 20.8992773],
                [105.5633796, 20.8993987],
                [105.5630837, 20.8994391],
                [105.5624991, 20.8994189],
                [105.5616907, 20.9005179],
                [105.5611855, 20.9007876],
                [105.5600782, 20.9010892],
                [105.5596594, 20.9010949],
                [105.5593013, 20.9012933],
                [105.5593013, 20.9014861],
                [105.5594713, 20.9016279],
                [105.5596655, 20.9017186],
                [105.5596043, 20.9019367],
                [105.5592037, 20.9023109],
                [105.5584603, 20.9026042],
                [105.5578035, 20.9030829],
                [105.5561847, 20.9040305],
                [105.5554955, 20.9047452],
                [105.5557264, 20.905025],
                [105.5558198, 20.905326],
                [105.5564558, 20.9063205],
                [105.5568023, 20.9066946],
                [105.5571437, 20.9069199],
                [105.5575742, 20.9070302],
                [105.5580484, 20.9069133],
                [105.5584671, 20.9067519],
                [105.5600032, 20.9055552],
                [105.560289, 20.905846],
                [105.5588856, 20.9071571],
                [105.5586916, 20.9075671],
                [105.5579179, 20.9081797],
                [105.5585271, 20.9087876],
                [105.559681, 20.9096397],
                [105.5599121, 20.9097901],
                [105.5601647, 20.9098813],
                [105.5604344, 20.9098805],
                [105.5606587, 20.9097522],
                [105.5610907, 20.9093498],
                [105.5613443, 20.9092266],
                [105.5617242, 20.9091671],
                [105.5618223, 20.9093089],
                [105.5619683, 20.9093894],
                [105.5629105, 20.9093269],
                [105.5672839, 20.9111539],
                [105.5676197, 20.9113256],
                [105.5713123, 20.9130999],
                [105.5720406, 20.9135931],
                [105.5724182, 20.9136928],
                [105.5726314, 20.9137399],
                [105.5742683, 20.9134627],
                [105.574885, 20.9134702],
                [105.5761369, 20.9142712],
                [105.5763938, 20.9144625],
                [105.5766865, 20.9147005],
                [105.577289, 20.9151819],
                [105.5790466, 20.9160125],
                [105.5791245, 20.9161852],
                [105.5792748, 20.9163566],
                [105.5795578, 20.9164493],
                [105.5798238, 20.9164485],
                [105.5800731, 20.9164007],
                [105.5802893, 20.9164156],
                [105.5803729, 20.9165404],
                [105.5802404, 20.9166657],
                [105.5800081, 20.9168228],
                [105.5798927, 20.9170575],
                [105.5799432, 20.9172292],
                [105.5803261, 20.9173529],
                [105.5806926, 20.9175235],
                [105.5809094, 20.9176635],
                [105.5809598, 20.9178194],
                [105.5808939, 20.9179603],
                [105.5807776, 20.9180076],
                [105.5806448, 20.9180393],
                [105.5804957, 20.918196],
                [105.5803469, 20.9184309],
                [105.5802814, 20.9186968],
                [105.5801657, 20.918869],
                [105.5801001, 20.9191192],
                [105.5800677, 20.9193224],
                [105.5800014, 20.919354],
                [105.579868, 20.9193074],
                [105.5797841, 20.919089],
                [105.5796674, 20.9190113],
                [105.5795176, 20.9189962],
                [105.5794512, 20.9189965],
                [105.5792692, 20.9192471],
                [105.579071, 20.9195758],
                [105.5789889, 20.9198573],
                [105.5788908, 20.9202951],
                [105.5788725, 20.920617],
                [105.5788698, 20.9206657],
                [105.5792083, 20.922266],
                [105.5803436, 20.9225265],
                [105.580929, 20.9233008],
                [105.5815193, 20.924165],
                [105.5814046, 20.9253851],
                [105.5814769, 20.9282042],
                [105.5801451, 20.9282186],
                [105.5790949, 20.9287639],
                [105.5771855, 20.9292095],
                [105.5758687, 20.9291941],
                [105.5746272, 20.929483],
                [105.5740553, 20.9290753],
                [105.5730134, 20.9278514],
                [105.5720769, 20.9272508],
                [105.5715666, 20.9270928],
                [105.5713523, 20.9270963],
                [105.5712611, 20.9270978],
                [105.5702501, 20.927117],
                [105.5674444, 20.9271705],
                [105.5652311, 20.9278568],
                [105.5627203, 20.9290706],
                [105.5610623, 20.9298351],
                [105.5607959, 20.9299542],
                [105.5590486, 20.9307355],
                [105.5573168, 20.9309142],
                [105.555957, 20.9307792],
                [105.5544492, 20.9306232],
                [105.5553191, 20.9288485],
                [105.5529299, 20.9299626],
                [105.551421, 20.9296681],
                [105.5494224, 20.928976],
                [105.5480621, 20.9286211],
                [105.5465534, 20.9283664],
                [105.5453004, 20.9283205],
                [105.5445156, 20.9285825],
                [105.5425016, 20.929527],
                [105.541441, 20.9298298],
                [105.5402529, 20.9301329],
                [105.5383637, 20.9303684],
                [105.5373232, 20.9303717],
                [105.5366464, 20.9303532],
                [105.5365202, 20.9305411],
                [105.5330279, 20.9310153],
                [105.530998, 20.9306988],
                [105.5302241, 20.9295354],
                [105.5285494, 20.9271232],
                [105.5236327, 20.9288883],
                [105.5207702, 20.9261488],
                [105.5186439, 20.9244404],
                [105.5153515, 20.9218412],
                [105.5151666, 20.9218475],
                [105.513808, 20.9219713],
                [105.5129168, 20.9221536],
                [105.5125563, 20.9223143],
                [105.5112421, 20.9230169],
                [105.5095675, 20.9238902],
                [105.5078512, 20.9249731],
                [105.5062828, 20.925856],
                [105.5057112, 20.9263567],
                [105.5043421, 20.929915],
                [105.502539, 20.9311091],
                [105.501122, 20.9309827],
                [105.5006894, 20.9310024],
                [105.5003261, 20.930955],
                [105.4994807, 20.9306484],
                [105.4990427, 20.9306497],
                [105.4982239, 20.9313597],
                [105.4966386, 20.9320847],
                [105.4945893, 20.933197],
                [105.4930311, 20.933819],
                [105.4908691, 20.9339796],
                [105.4903229, 20.9343671],
                [105.488561, 20.9353978],
                [105.4888095, 20.9357073],
                [105.4889132, 20.935939],
                [105.4889551, 20.9361904],
                [105.4889766, 20.9364805],
                [105.4889365, 20.9368096],
                [105.4887731, 20.9371583],
                [105.4883013, 20.9376819],
                [105.4880762, 20.9380114],
                [105.487851, 20.9384184],
                [105.4876254, 20.9386512],
                [105.487338, 20.9388649],
                [105.4869275, 20.9392336],
                [105.4864965, 20.9396024],
                [105.4862914, 20.9398158],
                [105.4860865, 20.9400873],
                [105.4856372, 20.9411718],
                [105.4853505, 20.9415982],
                [105.4844694, 20.9428196],
                [105.4837724, 20.9436535],
                [105.4830957, 20.9444292],
                [105.4824393, 20.9451081],
                [105.4819882, 20.9456317],
                [105.4813116, 20.9464656],
                [105.4809987, 20.9464532],
                [105.4799361, 20.9477758],
                [105.4794941, 20.9480112],
                [105.4783328, 20.9482744],
                [105.4765624, 20.9483835],
                [105.474543, 20.9485712],
                [105.4726343, 20.9487065],
                [105.4712785, 20.9487363],
                [105.4705587, 20.9486082],
                [105.469395, 20.9481172],
                [105.4685914, 20.9477294],
                [105.4677589, 20.9469514],
                [105.4670644, 20.946095],
                [105.4664826, 20.9458365],
                [105.466012, 20.9457338],
                [105.4654589, 20.9458134],
                [105.4642979, 20.9462066],
                [105.4621963, 20.9466024],
                [105.4616485, 20.9467179],
                [105.461064, 20.9469793],
                [105.4596272, 20.9477074],
                [105.4590853, 20.9485131],
                [105.4579206, 20.9500776],
                [105.4567808, 20.9505163],
                [105.4556416, 20.9511225],
                [105.455358, 20.9516595],
                [105.4532952, 20.9533159],
                [105.4510266, 20.9543021],
                [105.4465448, 20.9556565],
                [105.4442091, 20.9564159],
                [105.4440319, 20.9579935],
                [105.4432031, 20.9600225],
                [105.442719, 20.9610046],
                [105.442725, 20.9630315],
                [105.4422415, 20.9642096],
                [105.4412028, 20.9658469],
                [105.4400086, 20.9678443],
                [105.4398422, 20.968544],
                [105.4391863, 20.9688065],
                [105.4385526, 20.969548],
                [105.4369614, 20.9725599],
                [105.4357144, 20.9743938],
                [105.4351606, 20.9753759],
                [105.4338266, 20.9771775],
                [105.4330636, 20.977964],
                [105.4318151, 20.9792749],
                [105.4301492, 20.9806521],
                [105.4291766, 20.9811777],
                [105.4277171, 20.9817045],
                [105.4261183, 20.9822315],
                [105.4245885, 20.9824969],
                [105.4229204, 20.9830895],
                [105.4221563, 20.9835491],
                [105.4219219, 20.9838028],
                [105.4219892, 20.9840936],
                [105.4219899, 20.9843638],
                [105.4219464, 20.9846134],
                [105.4217478, 20.9848632],
                [105.4214384, 20.9851134],
                [105.4213063, 20.9853424],
                [105.4212407, 20.9856336],
                [105.4212205, 20.9863195],
                [105.4212895, 20.987234],
                [105.4212022, 20.9877123],
                [105.4210045, 20.9882532],
                [105.4204753, 20.9890651],
                [105.420255, 20.9894606],
                [105.4201233, 20.9898975],
                [105.4200365, 20.9905004],
                [105.4200375, 20.9908537],
                [105.4200601, 20.9910408],
                [105.4201712, 20.9911444],
                [105.4203487, 20.9912687],
                [105.4204154, 20.9913724],
                [105.4204378, 20.9914763],
                [105.4204163, 20.991705],
                [105.420306, 20.9918508],
                [105.420063, 20.9920385],
                [105.4193998, 20.9924351],
                [105.4188255, 20.9929353],
                [105.4185384, 20.9932478],
                [105.4184505, 20.9934559],
                [105.4183847, 20.9936847],
                [105.4183631, 20.9939133],
                [105.41843, 20.9940587],
                [105.4185853, 20.9941414],
                [105.4187626, 20.9942242],
                [105.4188293, 20.9943071],
                [105.4188295, 20.9943902],
                [105.4188076, 20.9944943],
                [105.4187194, 20.9945777],
                [105.4186308, 20.9945779],
                [105.4184539, 20.9946822],
                [105.4184319, 20.9947447],
                [105.4184101, 20.9948902],
                [105.4184108, 20.9951188],
                [105.418367, 20.9952645],
                [105.4183007, 20.9953476],
                [105.4182122, 20.9953895],
                [105.4180794, 20.9953898],
                [105.4179022, 20.9953695],
                [105.4177694, 20.9953698],
                [105.4176151, 20.9960027],
                [105.4179663, 20.9971789],
                [105.4178979, 20.9976367],
                [105.4173419, 20.9978343],
                [105.4165072, 20.9979671],
                [105.4159499, 20.9977069],
                [105.4148373, 20.9979711],
                [105.4138637, 20.9981697],
                [105.4115675, 20.9981752],
                [105.4108017, 20.9980463],
                [105.4104538, 20.9980471],
                [105.4102452, 20.998113],
                [105.4098989, 20.9987023],
                [105.4093428, 20.9988998],
                [105.4084378, 20.9987712],
                [105.4072375, 20.9987414],
                [105.405706, 20.9984835],
                [105.4041743, 20.9981603],
                [105.4032698, 20.9981623],
                [105.4020875, 20.9984267],
                [105.4011456, 20.99902],
                [105.3996364, 20.9998448],
                [105.3994388, 21.0000322],
                [105.3991648, 21.0001617],
                [105.3984946, 21.0003494],
                [105.3976267, 21.0007093],
                [105.3963175, 21.0013708],
                [105.3953128, 21.0019314],
                [105.3944, 21.002635],
                [105.3940042, 21.0028076],
                [105.3937298, 21.0028512],
                [105.393449, 21.0028749],
                [105.3935661, 21.0034276],
                [105.3935078, 21.0038454],
                [105.393272, 21.0043472],
                [105.3925336, 21.0054626],
                [105.3919436, 21.0066055],
                [105.3919157, 21.0072738],
                [105.3922149, 21.0083312],
                [105.3923657, 21.0092775],
                [105.3922221, 21.0110599],
                [105.3923436, 21.0121733],
                [105.3925528, 21.0127854],
                [105.3929396, 21.0133135],
                [105.393694, 21.0141685],
                [105.3930479, 21.0142495],
                [105.3922659, 21.014423],
                [105.3911458, 21.0149646],
                [105.3890694, 21.0161325],
                [105.3876111, 21.0170426],
                [105.3870124, 21.0175095],
                [105.3868074, 21.0177178],
                [105.3866749, 21.0181229],
                [105.3866234, 21.0190472],
                [105.3866013, 21.0199365],
                [105.3868054, 21.020334],
                [105.3871938, 21.0207847],
                [105.3874654, 21.0212334],
                [105.3876507, 21.0217499],
                [105.3876765, 21.0221887],
                [105.3876271, 21.0232425],
                [105.3876285, 21.0237571],
                [105.387539, 21.0240964],
                [105.3874057, 21.0242734],
                [105.3872383, 21.0241746],
                [105.3864025, 21.0237844],
                [105.3841502, 21.0224767],
                [105.3818116, 21.0209366],
                [105.3800347, 21.0197719],
                [105.3788321, 21.0185661],
                [105.3772357, 21.0164867],
                [105.376584, 21.0155019],
                [105.3752583, 21.0142999],
                [105.3742523, 21.013613],
                [105.3736236, 21.0133874],
                [105.3726972, 21.013183],
                [105.3709133, 21.0129672],
                [105.3687419, 21.0131704],
                [105.3680566, 21.0127621],
                [105.3671621, 21.012707],
                [105.3665035, 21.0125326],
                [105.3651435, 21.0122716],
                [105.3631801, 21.0110128],
                [105.3611285, 21.0100385],
                [105.357395, 21.0084534],
                [105.3548036, 21.0074965],
                [105.354186, 21.0074531],
                [105.3537513, 21.0075508],
                [105.3534386, 21.0076986],
                [105.353335, 21.0079682],
                [105.3531534, 21.0084343],
                [105.352893, 21.0085573],
                [105.3524757, 21.0085828],
                [105.3517977, 21.0086086],
                [105.3515109, 21.0086828],
                [105.3510685, 21.0091003],
                [105.3504635, 21.0097902],
                [105.3493293, 21.0127063],
                [105.3486012, 21.0135899],
                [105.3482106, 21.0138848],
                [105.3479499, 21.0139589],
                [105.347219, 21.0136908],
                [105.3465661, 21.0133491],
                [105.3459658, 21.0131542],
                [105.3445505, 21.0130202],
                [105.3432383, 21.0128646],
                [105.3420905, 21.0127934],
                [105.3410997, 21.0129424],
                [105.3394056, 21.0135094],
                [105.3391189, 21.0135835],
                [105.3383859, 21.0124332],
                [105.3381238, 21.0121136],
                [105.3377586, 21.0118463],
                [105.3374839, 21.0116626],
                [105.3370537, 21.0115536],
                [105.3362489, 21.0114684],
                [105.3359057, 21.0113823],
                [105.3345573, 21.0104159],
                [105.334466, 21.0102443],
                [105.334489, 21.0100514],
                [105.3346265, 21.0099442],
                [105.3350383, 21.0098161],
                [105.3352673, 21.0095804],
                [105.3353591, 21.0092588],
                [105.3352679, 21.0090442],
                [105.3350394, 21.0088295],
                [105.3343309, 21.0082927],
                [105.3339195, 21.0079707],
                [105.3336451, 21.0079489],
                [105.3333248, 21.0079701],
                [105.3326612, 21.0081839],
                [105.3313114, 21.0085042],
                [105.3308766, 21.0086325],
                [105.3307423, 21.0086843],
                [105.3303796, 21.0085783],
                [105.330258, 21.0084211],
                [105.3298324, 21.0082409],
                [105.3295389, 21.0081439],
                [105.329304, 21.0081436],
                [105.3290542, 21.008254],
                [105.3288338, 21.0083506],
                [105.3285547, 21.0085163],
                [105.3282166, 21.0087925],
                [105.3279374, 21.0090272],
                [105.3276582, 21.0092206],
                [105.3272471, 21.0092202],
                [105.3268507, 21.0091367],
                [105.3264984, 21.0089981],
                [105.326058, 21.0090253],
                [105.3258669, 21.0092188],
                [105.3256904, 21.0094674],
                [105.325499, 21.0098821],
                [105.3253224, 21.0101862],
                [105.3251166, 21.0103122],
                [105.3251706, 21.0105395],
                [105.3252638, 21.0109452],
                [105.3253569, 21.011124],
                [105.325538, 21.0112109],
                [105.3256725, 21.0112254],
                [105.3258588, 21.0111433],
                [105.3260399, 21.0110177],
                [105.326278, 21.011037],
                [105.3265626, 21.0113945],
                [105.3268368, 21.0117278],
                [105.327168, 21.0121625],
                [105.3272042, 21.0123944],
                [105.3271429, 21.0125088],
                [105.3271214, 21.012549],
                [105.3267902, 21.0129499],
                [105.3267385, 21.0131189],
                [105.3269351, 21.0133315],
                [105.3271214, 21.0134377],
                [105.3273646, 21.0135392],
                [105.3277009, 21.0137227],
                [105.3277372, 21.0138193],
                [105.3276699, 21.0140077],
                [105.327647, 21.014039],
                [105.3275561, 21.014114],
                [105.3271421, 21.0142637],
                [105.3269756, 21.0144327],
                [105.3268939, 21.014578],
                [105.3267422, 21.0152532],
                [105.3266683, 21.0158377],
                [105.3266566, 21.0161172],
                [105.326699, 21.0163527],
                [105.3268257, 21.0166041],
                [105.3269936, 21.0167726],
                [105.3272185, 21.0168732],
                [105.3276049, 21.0170092],
                [105.3279185, 21.0170535],
                [105.3280579, 21.0169353],
                [105.3281941, 21.0167371],
                [105.3283969, 21.0164976],
                [105.3285133, 21.0164359],
                [105.3286946, 21.0165154],
                [105.3288308, 21.0166396],
                [105.328891, 21.0168111],
                [105.3288847, 21.0169353],
                [105.328815, 21.0171334],
                [105.3287657, 21.0172784],
                [105.328817, 21.0174382],
                [105.3287044, 21.017764],
                [105.3283439, 21.0188353],
                [105.3280779, 21.019563],
                [105.3275023, 21.0197263],
                [105.3270997, 21.0200155],
                [105.3265819, 21.0203049],
                [105.3257666, 21.0210934],
                [105.3252955, 21.0213826],
                [105.3248018, 21.0215687],
                [105.3247242, 21.0215607],
                [105.3245688, 21.0215064],
                [105.3242834, 21.0206621],
                [105.3241856, 21.0199399],
                [105.3240397, 21.0192178],
                [105.323991, 21.018992],
                [105.3239898, 21.01836],
                [105.3238721, 21.0180132],
                [105.3238255, 21.0175991],
                [105.3237362, 21.017371],
                [105.3235051, 21.0167806],
                [105.3232151, 21.0161943],
                [105.322974, 21.015969],
                [105.3226849, 21.0157437],
                [105.3221715, 21.0152534],
                [105.3219426, 21.0150704],
                [105.3217637, 21.0149275],
                [105.3207281, 21.0142758],
                [105.3195041, 21.0134907],
                [105.3175069, 21.0121743],
                [105.3176881, 21.0118405],
                [105.3183238, 21.0109621],
                [105.3208385, 21.0074234],
                [105.3231541, 21.0042297],
                [105.3246975, 21.0019521],
                [105.3254921, 20.9998447],
                [105.3259154, 20.9990245],
                [105.3302942, 20.9889609],
                [105.33142, 20.9857616],
                [105.3361945, 20.9721918],
                [105.3364652, 20.9716978],
                [105.3370073, 20.9699237],
                [105.3380329, 20.9670006],
                [105.338456, 20.9652605],
                [105.3397152, 20.9608206],
                [105.340422, 20.9586339],
                [105.3418238, 20.9543709],
                [105.3422951, 20.9529877],
                [105.3427661, 20.9514261],
                [105.3437065, 20.9471315],
                [105.3443626, 20.9436077],
                [105.3447756, 20.9409031],
                [105.3423236, 20.9411096],
                [105.3407437, 20.9411127],
                [105.3384462, 20.9413195],
                [105.3372965, 20.9409848],
                [105.3366496, 20.9407165],
                [105.3365049, 20.940245],
                [105.3364323, 20.9399081],
                [105.3361443, 20.9395717],
                [105.3352817, 20.9391689],
                [105.3346347, 20.9388332],
                [105.3337723, 20.9384979],
                [105.3329107, 20.938567],
                [105.3316182, 20.9385695],
                [105.3295351, 20.938304],
                [105.327595, 20.9377012],
                [105.3263727, 20.9370295],
                [105.3260114, 20.9360192],
                [105.325579, 20.9352786],
                [105.3247171, 20.9352129],
                [105.3227066, 20.9352167],
                [105.32084, 20.9354224],
                [105.3192601, 20.9353579],
                [105.3181826, 20.9352252],
                [105.3172485, 20.9349573],
                [105.3163859, 20.9344871],
                [105.3162035, 20.9337094],
                [105.316341, 20.9324134],
                [105.3168226, 20.9304237],
                [105.3168611, 20.930017],
                [105.316763, 20.9293041],
                [105.3166883, 20.9289571],
                [105.3164647, 20.9284651],
                [105.3157576, 20.9270678],
                [105.3137355, 20.9252436],
                [105.3126561, 20.9241303],
                [105.3119548, 20.9241062],
                [105.3091584, 20.92401],
                [105.307263, 20.9240133],
                [105.3056632, 20.9240393],
                [105.3038418, 20.9240658],
                [105.3032018, 20.9240669],
                [105.301192, 20.9240244],
                [105.2997148, 20.9239576],
                [105.2965783, 20.9241856],
                [105.2946405, 20.9255815],
                [105.2934307, 20.9262609],
                [105.2924617, 20.9276215],
                [105.2894012, 20.931427],
                [105.2786054, 20.9247704],
                [105.2780815, 20.9246193],
                [105.2761864, 20.924715],
                [105.2757265, 20.9247452],
                [105.2753826, 20.9247467],
                [105.2747864, 20.9261315],
                [105.273657, 20.9286674],
                [105.2722859, 20.9320097],
                [105.2714568, 20.934014],
                [105.2707943, 20.9352182],
                [105.2707947, 20.9354033],
                [105.2710435, 20.9369532],
                [105.2712931, 20.9387807],
                [105.2714429, 20.9400068],
                [105.2713949, 20.9407119],
                [105.2711243, 20.9408511],
                [105.2695004, 20.9413166],
                [105.2679005, 20.9413885],
                [105.2671372, 20.9413665],
                [105.2669894, 20.9412973],
                [105.2666431, 20.940303],
                [105.2663705, 20.9393547],
                [105.2652099, 20.9372742],
                [105.2651227, 20.9370475],
                [105.2649748, 20.9369321],
                [105.2648024, 20.9369091],
                [105.2646305, 20.9371177],
                [105.2640651, 20.9375118],
                [105.2637206, 20.9376512],
                [105.2635483, 20.9376515],
                [105.2633759, 20.9375592],
                [105.2630553, 20.9373051],
                [105.2627838, 20.936866],
                [105.2626605, 20.9367737],
                [105.2624634, 20.9367045],
                [105.2620942, 20.9366588],
                [105.2619709, 20.9365664],
                [105.261798, 20.9361734],
                [105.2615513, 20.9359192],
                [105.2609109, 20.9356888],
                [105.2603692, 20.9355508],
                [105.2598769, 20.9356209],
                [105.2589666, 20.9359],
                [105.2585733, 20.9362477],
                [105.2580821, 20.9369656],
                [105.2579357, 20.9377063],
                [105.2580352, 20.9382615],
                [105.2580081, 20.9387428],
                [105.2575676, 20.9390254],
                [105.256883, 20.9396461],
                [105.2562168, 20.9399067],
                [105.2557457, 20.9400905],
                [105.2550312, 20.9405191],
                [105.25456, 20.9407335],
                [105.2543772, 20.9407767],
                [105.2536023, 20.9412855],
                [105.252724, 20.9417801],
                [105.2520064, 20.9422816],
                [105.2516266, 20.9427931],
                [105.2513565, 20.9437644],
                [105.2511946, 20.9445311],
                [105.2513586, 20.9450929],
                [105.251359, 20.9453483],
                [105.2510333, 20.9456044],
                [105.2506789, 20.9456999],
                [105.2502964, 20.9458031],
                [105.25007, 20.9458035],
                [105.2498489, 20.9457726],
                [105.2495834, 20.9456691],
                [105.2493286, 20.94542],
                [105.2491844, 20.94515],
                [105.2489625, 20.9446929],
                [105.24873, 20.9444335],
                [105.248575, 20.944309],
                [105.2483538, 20.9442678],
                [105.2481437, 20.9442577],
                [105.2479447, 20.9443099],
                [105.2477681, 20.9444453],
                [105.247658, 20.9446949],
                [105.2475482, 20.9452147],
                [105.2474379, 20.9454227],
                [105.2473167, 20.9455788],
                [105.2470957, 20.9456831],
                [105.2468524, 20.9456939],
                [105.2466533, 20.945611],
                [105.2464541, 20.9454554],
                [105.2460335, 20.9451961],
                [105.2456132, 20.9450721],
                [105.2446731, 20.9449279],
                [105.2439874, 20.9447833],
                [105.243358, 20.9447179],
                [105.2428042, 20.9446603],
                [105.2424945, 20.9446088],
                [105.2424342, 20.9445276],
                [105.242509, 20.94447],
                [105.2427152, 20.9442185],
                [105.2428848, 20.9439328],
                [105.2430789, 20.9437043],
                [105.2432972, 20.9435328],
                [105.2436131, 20.9434067],
                [105.2440258, 20.9431436],
                [105.2442684, 20.9429149],
                [105.2444503, 20.9426407],
                [105.2445106, 20.942458],
                [105.2445347, 20.9422754],
                [105.2444981, 20.9421612],
                [105.2443885, 20.9420358],
                [105.2442181, 20.9419104],
                [105.2440845, 20.9418764],
                [105.2439265, 20.9418424],
                [105.2437563, 20.9418427],
                [105.2435741, 20.9418658],
                [105.2433676, 20.9419346],
                [105.2430277, 20.942072],
                [105.2426998, 20.9422094],
                [105.2424811, 20.9422668],
                [105.2421652, 20.9422673],
                [105.2419586, 20.9422333],
                [105.241837, 20.9421878],
                [105.2417762, 20.9421194],
                [105.2417031, 20.9420396],
                [105.2415812, 20.9418229],
                [105.2414959, 20.9416518],
                [105.241362, 20.9415265],
                [105.2412769, 20.941458],
                [105.2410702, 20.9413785],
                [105.2408149, 20.9413331],
                [105.2406205, 20.9413106],
                [105.2404504, 20.9412994],
                [105.2402439, 20.941334],
                [105.2400495, 20.9413799],
                [105.2396973, 20.9414946],
                [105.2395394, 20.941529],
                [105.23943, 20.9415291],
                [105.2393206, 20.9415085],
                [105.2392476, 20.9414514],
                [105.2391989, 20.9413487],
                [105.2391378, 20.9411661],
                [105.23904, 20.9407778],
                [105.2389547, 20.9406067],
                [105.2387965, 20.9404926],
                [105.2386872, 20.9404699],
                [105.2385291, 20.9404816],
                [105.2383956, 20.9405275],
                [105.2382257, 20.9406419],
                [105.2379952, 20.9408821],
                [105.2378616, 20.9409508],
                [105.2377765, 20.9409624],
                [105.2376794, 20.940951],
                [105.2375699, 20.9408941],
                [105.2374969, 20.9408028],
                [105.2373507, 20.9405746],
                [105.237241, 20.9404148],
                [105.2371316, 20.9403008],
                [105.2370099, 20.9402096],
                [105.236791, 20.94013],
                [105.2366087, 20.9400845],
                [105.2362562, 20.9400392],
                [105.236001, 20.9399711],
                [105.2357213, 20.9398687],
                [105.2353567, 20.9397664],
                [105.2350649, 20.9396869],
                [105.2347611, 20.9396302],
                [105.2344329, 20.9396192],
                [105.2339471, 20.9397341],
                [105.2336433, 20.9397574],
                [105.2334489, 20.9397234],
                [105.2333394, 20.9396207],
                [105.2332176, 20.9394725],
                [105.2331323, 20.9393012],
                [105.2330954, 20.9390499],
                [105.2330584, 20.938696],
                [105.2330096, 20.9385361],
                [105.2328757, 20.9383993],
                [105.2327297, 20.938308],
                [105.2325596, 20.9382626],
                [105.2323652, 20.9382628],
                [105.2322194, 20.9382859],
                [105.2316615, 20.9384608],
                [105.2312243, 20.9385871],
                [105.230957, 20.9386217],
                [105.2307505, 20.9386334],
                [105.2305195, 20.9385995],
                [105.2303614, 20.9384969],
                [105.230264, 20.9383714],
                [105.230276, 20.9382457],
                [105.2303243, 20.9380972],
                [105.2305548, 20.9378569],
                [105.2314653, 20.9373304],
                [105.2316596, 20.9371816],
                [105.2317079, 20.9370674],
                [105.2317079, 20.9370103],
                [105.2316713, 20.9368961],
                [105.2315739, 20.9368162],
                [105.2313793, 20.9367137],
                [105.2312576, 20.936611],
                [105.2311481, 20.9365199],
                [105.2309901, 20.9364402],
                [105.2307712, 20.936349],
                [105.2306496, 20.9362464],
                [105.2305279, 20.936121],
                [105.2303331, 20.9358699],
                [105.2302357, 20.9357787],
                [105.2300776, 20.9356419],
                [105.2299681, 20.935562],
                [105.2298587, 20.935528],
                [105.2296473, 20.9355472],
                [105.2295927, 20.9355859],
                [105.2291145, 20.9357921],
                [105.2288002, 20.9358954],
                [105.2283492, 20.9359602],
                [105.2280211, 20.9359735],
                [105.2275017, 20.9360255],
                [105.2269413, 20.936142],
                [105.2263401, 20.9363356],
                [105.2259301, 20.9364518],
                [105.2256157, 20.9364522],
                [105.2253422, 20.936414],
                [105.2250686, 20.9363116],
                [105.2247677, 20.9361577],
                [105.2244668, 20.9361195],
                [105.2240977, 20.93612],
                [105.2237149, 20.9361463],
                [105.2233323, 20.9362238],
                [105.2229906, 20.9362757],
                [105.2226898, 20.9362889],
                [105.2224983, 20.9362378],
                [105.2223752, 20.9361351],
                [105.222252, 20.9360196],
                [105.2221314, 20.9358693],
                [105.2219944, 20.9356639],
                [105.2217068, 20.9352788],
                [105.221474, 20.9350605],
                [105.2211729, 20.9348553],
                [105.2208446, 20.9346887],
                [105.2204888, 20.9345092],
                [105.2202562, 20.9342912],
                [105.2198181, 20.9338932],
                [105.2189971, 20.933406],
                [105.218887, 20.9327909],
                [105.2188997, 20.9326192],
                [105.2189126, 20.9324598],
                [105.2189905, 20.9322268],
                [105.2192637, 20.9316379],
                [105.219498, 20.9312331],
                [105.2197452, 20.9307792],
                [105.2198492, 20.9304848],
                [105.2200051, 20.9300188],
                [105.2201222, 20.9298346],
                [105.2202264, 20.9296752],
                [105.2206957, 20.9293436],
                [105.2210217, 20.9291839],
                [105.2214519, 20.9289504],
                [105.2219081, 20.9286064],
                [105.2224295, 20.9282012],
                [105.2226641, 20.9280661],
                [105.2228337, 20.9280045],
                [105.2230425, 20.927992],
                [105.2233033, 20.9279427],
                [105.223486, 20.9279056],
                [105.2237599, 20.9278071],
                [105.2239399, 20.9276676],
                [105.2243177, 20.9272868],
                [105.2245, 20.9269922],
                [105.224578, 20.9267959],
                [105.2245777, 20.9266364],
                [105.2245514, 20.9264769],
                [105.2244599, 20.926379],
                [105.2243162, 20.9262932],
                [105.2240683, 20.9262936],
                [105.2237683, 20.9263308],
                [105.2231029, 20.9264175],
                [105.2224247, 20.9265778],
                [105.2217854, 20.9266523],
                [105.2214593, 20.9266773],
                [105.2212896, 20.9266529],
                [105.2211198, 20.9265551],
                [105.2209237, 20.9263713],
                [105.2208453, 20.9262365],
                [105.2208059, 20.9260525],
                [105.2208187, 20.9258317],
                [105.2209357, 20.9256108],
                [105.2210527, 20.9252795],
                [105.2211175, 20.9249973],
                [105.2211042, 20.9248132],
                [105.2208948, 20.9244579],
                [105.220725, 20.924274],
                [105.2206467, 20.9242374],
                [105.2204509, 20.9241885],
                [105.2202813, 20.9241887],
                [105.2201638, 20.9242502],
                [105.2197989, 20.9244959],
                [105.2196424, 20.924533],
                [105.2194336, 20.9245578],
                [105.2191988, 20.9245704],
                [105.2189248, 20.9245707],
                [105.2187291, 20.9245832],
                [105.2185464, 20.924608],
                [105.2183377, 20.9246206],
                [105.218155, 20.9246208],
                [105.2179854, 20.9246577],
                [105.2178028, 20.9246702],
                [105.2176592, 20.9246582],
                [105.2175548, 20.9246215],
                [105.2174241, 20.924499],
                [105.2172542, 20.924303],
                [105.2170974, 20.924156],
                [105.2166275, 20.9240094],
                [105.2162881, 20.9239607],
                [105.2161185, 20.9239732],
                [105.215975, 20.9239979],
                [105.2157012, 20.9240719],
                [105.2154794, 20.924158],
                [105.2152446, 20.9242073],
                [105.2149185, 20.9242201],
                [105.2144879, 20.924282],
                [105.2142922, 20.9242823],
                [105.2141095, 20.9242334],
                [105.2139006, 20.9241478],
                [105.2136785, 20.9239763],
                [105.2134565, 20.9237927],
                [105.2130908, 20.9235722],
                [105.2128818, 20.9234253],
                [105.2126075, 20.9232171],
                [105.2124378, 20.9231438],
                [105.2120332, 20.9230706],
                [105.2116156, 20.9229975],
                [105.2114065, 20.9228506],
                [105.2111192, 20.9226302],
                [105.2107533, 20.9222749],
                [105.210335, 20.9217725],
                [105.2099952, 20.9213681],
                [105.2098906, 20.9211842],
                [105.2098121, 20.9209759],
                [105.2097857, 20.9207673],
                [105.209681, 20.9205098],
                [105.2095367, 20.9199949],
                [105.2095363, 20.919676],
                [105.2095489, 20.9194183],
                [105.2095485, 20.9190381],
                [105.2095351, 20.9188173],
                [105.2094564, 20.9185721],
                [105.2092731, 20.9180694],
                [105.209245, 20.9179698],
                [105.2091294, 20.917936],
                [105.2089988, 20.9178993],
                [105.2087638, 20.9178015],
                [105.2085419, 20.9176914],
                [105.2083461, 20.9176057],
                [105.2080981, 20.9175692],
                [105.2079676, 20.9175571],
                [105.2077457, 20.9175697],
                [105.2073415, 20.9176806],
                [105.2070153, 20.9177423],
                [105.2067675, 20.9177672],
                [105.2064413, 20.9177431],
                [105.2062324, 20.9177065],
                [105.2060105, 20.9175963],
                [105.2057233, 20.917474],
                [105.2043892, 20.9171862],
                [105.2038877, 20.9170274],
                [105.2034639, 20.9170818],
                [105.202996, 20.9172045],
                [105.202788, 20.9172781],
                [105.2023464, 20.9175721],
                [105.2014374, 20.9183554],
                [105.1996446, 20.9193721],
                [105.1982939, 20.920376],
                [105.1977351, 20.9205783],
                [105.1968824, 20.9207423],
                [105.1962141, 20.9207208],
                [105.1961881, 20.9207207],
                [105.1961881, 20.9207452],
                [105.1962221, 20.9209277],
                [105.1968597, 20.9214584],
                [105.1974674, 20.9222054],
                [105.1979072, 20.9229925],
                [105.1981685, 20.9239456],
                [105.1982735, 20.9246911],
                [105.1979882, 20.9252781],
                [105.1973913, 20.9261346],
                [105.1964047, 20.9272848],
                [105.1952356, 20.9281418],
                [105.1940409, 20.9292432],
                [105.1938332, 20.9294635],
                [105.1938855, 20.9297569],
                [105.194068, 20.9300623],
                [105.1945367, 20.930624],
                [105.1949798, 20.9314302],
                [105.1950856, 20.9327259],
                [105.1951905, 20.9334592],
                [105.1956337, 20.9342899],
                [105.1964676, 20.9356702],
                [105.197327, 20.9366227],
                [105.1983943, 20.9374771],
                [105.1993843, 20.9387227],
                [105.2003738, 20.939724],
                [105.2014152, 20.9406518],
                [105.2026646, 20.9415427],
                [105.203758, 20.9423481],
                [105.2043052, 20.9431298],
                [105.2044363, 20.9439119],
                [105.204516, 20.9451342],
                [105.2049093, 20.9475174],
                [105.2048846, 20.9484954],
                [105.2049371, 20.9488375],
                [105.2049896, 20.9491553],
                [105.2057452, 20.950279],
                [105.2059064, 20.9504221],
                [105.2063324, 20.9507824],
                [105.2055903, 20.9510126],
                [105.2050444, 20.9512821],
                [105.2043428, 20.9516741],
                [105.2024727, 20.9534978],
                [105.2012519, 20.9546971],
                [105.2000572, 20.9559454],
                [105.1993042, 20.9568264],
                [105.1988883, 20.9570469],
                [105.1986803, 20.9571205],
                [105.1981341, 20.9571211],
                [105.1975359, 20.9570973],
                [105.1964436, 20.9571964],
                [105.1954036, 20.9574665],
                [105.1940431, 20.9578796],
                [105.1930899, 20.9582393],
                [105.192518, 20.9584681],
                [105.1921076, 20.95853],
                [105.1917872, 20.9574546],
                [105.1915109, 20.9562741],
                [105.1911556, 20.9547061],
                [105.190742, 20.9536089],
                [105.1902306, 20.9527053],
                [105.189798, 20.9520785],
                [105.1893659, 20.9517837],
                [105.1878929, 20.9511766],
                [105.1869503, 20.9507717],
                [105.1853987, 20.9498878],
                [105.1837883, 20.9490778],
                [105.1828846, 20.9484237],
                [105.1822756, 20.9479262],
                [105.1805277, 20.9469871],
                [105.1793885, 20.9462687],
                [105.1780333, 20.9454768],
                [105.1775421, 20.9450714],
                [105.1769334, 20.9448968],
                [105.1758736, 20.944824],
                [105.1751671, 20.9448248],
                [105.1736164, 20.9447525],
                [105.1728901, 20.9446796],
                [105.1721245, 20.9445143],
                [105.1708875, 20.9441465],
                [105.1694935, 20.9436035],
                [105.1687474, 20.9433275],
                [105.1685315, 20.9433093],
                [105.1680606, 20.9433652],
                [105.1674133, 20.9436979],
                [105.1667658, 20.9439752],
                [105.1658828, 20.9440869],
                [105.1648032, 20.9440325],
                [105.1634097, 20.9439785],
                [105.1622713, 20.9438873],
                [105.161859, 20.9437586],
                [105.1617019, 20.9436112],
                [105.1615838, 20.943436],
                [105.1614265, 20.9431041],
                [105.1613667, 20.9422923],
                [105.161404, 20.9403733],
                [105.1614228, 20.9396907],
                [105.1613049, 20.9394878],
                [105.1611083, 20.9392851],
                [105.1606961, 20.939101],
                [105.1600287, 20.9390279],
                [105.1592633, 20.9390286],
                [105.1586551, 20.9392874],
                [105.1576546, 20.9395836],
                [105.1571443, 20.9396301],
                [105.1565161, 20.9394462],
                [105.1560056, 20.9392621],
                [105.1555934, 20.9391888],
                [105.1546711, 20.9392634],
                [105.153788, 20.9393933],
                [105.1528855, 20.9396524],
                [105.1513946, 20.9402812],
                [105.1499034, 20.9406606],
                [105.1487457, 20.9409569],
                [105.1479217, 20.9413081],
                [105.1475293, 20.9413638],
                [105.1473722, 20.9413086],
                [105.1471365, 20.9411244],
                [105.1467829, 20.9407003],
                [105.1462522, 20.9399074],
                [105.1456624, 20.9389115],
                [105.1453677, 20.9385796],
                [105.1452892, 20.9385614],
                [105.1448771, 20.9386539],
                [105.1435431, 20.9391901],
                [105.1426013, 20.9394492],
                [105.1420322, 20.9394773],
                [105.1413257, 20.9394779],
                [105.1404227, 20.9393864],
                [105.1392254, 20.9392029],
                [105.1381264, 20.9392038],
                [105.1370863, 20.9392415],
                [105.1360263, 20.9390763],
                [105.1350447, 20.9387728],
                [105.1338274, 20.9383308],
                [105.132885, 20.9379257],
                [105.1323551, 20.9378154],
                [105.1320607, 20.9378156],
                [105.1316094, 20.9378712],
                [105.1311385, 20.9380377],
                [105.1306677, 20.9383149],
                [105.1298046, 20.9388321],
                [105.1276469, 20.9402636],
                [105.1263524, 20.941187],
                [105.125764, 20.9415749],
                [105.125352, 20.9417044],
                [105.1239388, 20.9416686],
                [105.1229575, 20.9416693],
                [105.1220353, 20.9418913],
                [105.120623, 20.9429532],
                [105.1194855, 20.9440057],
                [105.1185442, 20.9449842],
                [105.1173085, 20.9460367],
                [105.1159157, 20.9469787],
                [105.1152686, 20.9476526],
                [105.1145238, 20.9490368],
                [105.1139747, 20.9496644],
                [105.1128371, 20.9506246],
                [105.1117189, 20.9514741],
                [105.1112091, 20.9521478],
                [105.1112486, 20.9524061],
                [105.1113279, 20.9535316],
                [105.1111713, 20.9540852],
                [105.1105436, 20.9546575],
                [105.1095431, 20.9553409],
                [105.1083659, 20.955978],
                [105.1074631, 20.9561448],
                [105.1066387, 20.9561267],
                [105.1054609, 20.955943],
                [105.1045775, 20.9558697],
                [105.1042634, 20.9558699],
                [105.1035963, 20.9560548],
                [105.1024777, 20.956443],
                [105.1007116, 20.957136],
                [105.0997108, 20.9573395],
                [105.0990435, 20.9574874],
                [105.098239, 20.9578569],
                [105.0962178, 20.9587436],
                [105.09453, 20.959206],
                [105.0931565, 20.9598985],
                [105.0921557, 20.9603972],
                [105.0914495, 20.9610803],
                [105.0910572, 20.9615232],
                [105.0903904, 20.9625937],
                [105.0895081, 20.9640796],
                [105.0890206, 20.9651521],
                [105.0889788, 20.9653897],
                [105.0887437, 20.9661463],
                [105.0887244, 20.9666261],
                [105.0887834, 20.966755],
                [105.0890386, 20.9674661],
                [105.089559, 20.9681436],
                [105.0898051, 20.968535],
                [105.0897271, 20.9693285],
                [105.0894135, 20.9701589],
                [105.0890998, 20.9707125],
                [105.0887074, 20.9711002],
                [105.0878243, 20.9716356],
                [105.0869807, 20.9722819],
                [105.0861764, 20.9732878],
                [105.0846854, 20.9748938],
                [105.0840184, 20.9757797],
                [105.0825469, 20.9773026],
                [105.0817033, 20.9782807],
                [105.0811345, 20.9791666],
                [105.0805659, 20.9803662],
                [105.0798598, 20.981575],
                [105.0789573, 20.9828485],
                [105.0780158, 20.9842512],
                [105.0775254, 20.985137],
                [105.0772117, 20.9858844],
                [105.076898, 20.9865671],
                [105.0762899, 20.9876006],
                [105.0755836, 20.9884312],
                [105.0748771, 20.9891327],
                [105.0738372, 20.9902125],
                [105.0731504, 20.9910614],
                [105.0723067, 20.9920581],
                [105.07162, 20.992907],
                [105.0711686, 20.9933869],
                [105.0710509, 20.9937007],
                [105.0710118, 20.9940513],
                [105.071012, 20.9945125],
                [105.070973, 20.9949092],
                [105.0701687, 20.9961826],
                [105.0699725, 20.9967178],
                [105.069953, 20.9968838],
                [105.0700906, 20.9971421],
                [105.070562, 20.9976953],
                [105.070621, 20.9979167],
                [105.0706015, 20.998212],
                [105.0705428, 20.9985809],
                [105.0701503, 20.9990424],
                [105.0691657, 20.9998575],
                [105.0687239, 21.0002726],
                [105.0684756, 21.0005439],
                [105.0678745, 21.001247],
                [105.0671947, 21.001765],
                [105.0665857, 21.0021811],
                [105.0656965, 21.0020212],
                [105.0645336, 21.0013654],
                [105.0640236, 21.0013656],
                [105.0631019, 21.001705],
                [105.0627751, 21.001927],
                [105.0612749, 21.0030892],
                [105.0598437, 21.0046493],
                [105.0589772, 21.00528],
                [105.058259, 21.0064101],
                [105.0571546, 21.0079329],
                [105.0561549, 21.0094311],
                [105.0557366, 21.0099492],
                [105.0552137, 21.0102945],
                [105.0546906, 21.0104796],
                [105.0543768, 21.0105536],
                [105.0539976, 21.0105661],
                [105.0535399, 21.0103814],
                [105.0526569, 21.009685],
                [105.052134, 21.0089167],
                [105.0513225, 21.0081198],
                [105.0509171, 21.0079965],
                [105.0500786, 21.0079602],
                [105.0489346, 21.0082379],
                [105.0482574, 21.0084472],
                [105.047107, 21.0091132],
                [105.0465696, 21.0095327],
                [105.0456608, 21.0095391],
                [105.0447191, 21.0094162],
                [105.0440672, 21.0094163],
                [105.0428231, 21.0097124],
                [105.0421629, 21.0100763],
                [105.0416661, 21.0105079],
                [105.0410908, 21.0108903],
                [105.0399663, 21.0114329],
                [105.0395152, 21.0115872],
                [105.0386915, 21.0120312],
                [105.0383515, 21.0122779],
                [105.0375785, 21.013144],
                [105.0374681, 21.0140087],
                [105.0371934, 21.0143693],
                [105.0370217, 21.0145535],
                [105.0366915, 21.015422],
                [105.0362275, 21.0163282],
                [105.0358615, 21.0170557],
                [105.0354564, 21.018264],
                [105.0351753, 21.0188497],
                [105.0346851, 21.019497],
                [105.0341285, 21.0199393],
                [105.0327433, 21.0205135],
                [105.0319718, 21.0212297],
                [105.0310972, 21.0218335],
                [105.0304172, 21.0221049],
                [105.0300758, 21.0221424],
                [105.0299057, 21.0221177],
                [105.029618, 21.0220314],
                [105.0293323, 21.0217415],
                [105.0287395, 21.0216455],
                [105.0281393, 21.0217591],
                [105.0271518, 21.0214572],
                [105.0263171, 21.0209987],
                [105.0247664, 21.0194795],
                [105.0232525, 21.0178154],
                [105.0212007, 21.0161574],
                [105.0201941, 21.0156227],
                [105.0185976, 21.0150383],
                [105.0188968, 21.0175545],
                [105.0191749, 21.018219],
                [105.0198574, 21.0186935],
                [105.0205399, 21.0193342],
                [105.0211719, 21.0204496],
                [105.0213736, 21.0212865],
                [105.0212756, 21.0219214],
                [105.0207416, 21.0226464],
                [105.0205501, 21.0232994],
                [105.0204462, 21.0240134],
                [105.0203574, 21.0253741],
                [105.0193395, 21.0274081],
                [105.0181954, 21.0289431],
                [105.0172735, 21.0304536],
                [105.0168158, 21.031292],
                [105.0165673, 21.0314399],
                [105.016175, 21.0314523],
                [105.0142926, 21.0315198],
                [105.0121415, 21.0340984],
                [105.0114977, 21.0354603],
                [105.0114193, 21.0356091],
                [105.0113334, 21.0357543],
                [105.0112401, 21.0358954],
                [105.0111397, 21.0360322],
                [105.0110323, 21.0361643],
                [105.0109184, 21.0362915],
                [105.0107868, 21.0364242],
                [105.0106479, 21.0365504],
                [105.0105022, 21.0366696],
                [105.01035, 21.0367815],
                [105.0101917, 21.0368859],
                [105.0100279, 21.0369824],
                [105.0071043, 21.03883],
                [105.0046849, 21.0401017],
                [104.999923, 21.0404876],
                [104.9994853, 21.0405696],
                [104.9992058, 21.0406654],
                [104.998941, 21.0407927],
                [104.998695, 21.0409493],
                [104.9983703, 21.041233],
                [104.9981875, 21.0414506],
                [104.9979701, 21.0418119],
                [104.9978662, 21.0420709],
                [104.997797, 21.0423397],
                [104.9976887, 21.0429084],
                [104.9976093, 21.0434812],
                [104.9959195, 21.0444425],
                [104.9952347, 21.0445021],
                [104.9947845, 21.0445837],
                [104.9943426, 21.0446983],
                [104.9937014, 21.0449304],
                [104.9930874, 21.0452354],
                [104.9926996, 21.0454774],
                [104.9923339, 21.0457477],
                [104.9885809, 21.0488933],
                [104.9869501, 21.0496993],
                [104.983753, 21.0501099],
                [104.9825728, 21.0510311],
                [104.9823099, 21.0512008],
                [104.98203, 21.0513448],
                [104.9817359, 21.0514616],
                [104.9812667, 21.0515849],
                [104.9807827, 21.0516393],
                [104.9797775, 21.0516551],
                [104.9785132, 21.0517193],
                [104.9743625, 21.0521149],
                [104.972831, 21.0516719],
                [104.9722631, 21.0517557],
                [104.9714245, 21.0519424],
                [104.9706079, 21.0522009],
                [104.969858, 21.0525115],
                [104.9693877, 21.0527459],
                [104.9687111, 21.0531437],
                [104.9676061, 21.0568083],
                [104.9670696, 21.0571788],
                [104.9670052, 21.0578997],
                [104.9669524, 21.0581146],
                [104.9668157, 21.0584195],
                [104.9666081, 21.0587],
                [104.9665201, 21.0587886],
                [104.966324, 21.0589461],
                [104.9662171, 21.0590142],
                [104.9659884, 21.0591271],
                [104.965868, 21.0591713],
                [104.9640012, 21.0596919],
                [104.9626386, 21.0602426],
                [104.9620911, 21.0600266],
                [104.9615566, 21.059784],
                [104.9610366, 21.0595153],
                [104.9605172, 21.0592119],
                [104.9597514, 21.0586924],
                [104.9592682, 21.0583134],
                [104.9588084, 21.0579097],
                [104.9582275, 21.0574811],
                [104.9575866, 21.0570894],
                [104.9568987, 21.0567483],
                [104.9552143, 21.0566982],
                [104.9518776, 21.0571187],
                [104.9515772, 21.0573991],
                [104.9504721, 21.0573991],
                [104.9465239, 21.0560374],
                [104.9451506, 21.0551463],
                [104.9432945, 21.0553866],
                [104.941932, 21.0569385],
                [104.9412453, 21.0571888],
                [104.9392927, 21.0558672],
                [104.939097, 21.0557758],
                [104.9388886, 21.0557133],
                [104.9386725, 21.0556813],
                [104.9383495, 21.055691],
                [104.9381405, 21.0557347],
                [104.9379408, 21.0558071],
                [104.936353, 21.0570286],
                [104.934454, 21.0584504],
                [104.9341643, 21.0593715],
                [104.932394, 21.0611837],
                [104.9322205, 21.0614543],
                [104.9320823, 21.0617422],
                [104.9319815, 21.0620432],
                [104.9319455, 21.0621974],
                [104.9319031, 21.0625103],
                [104.9319005, 21.0628257],
                [104.9319145, 21.0629863],
                [104.9319736, 21.0633036],
                [104.9320731, 21.063612],
                [104.9322116, 21.063907],
                [104.927448, 21.0654288],
                [104.9247444, 21.0651685],
                [104.9233711, 21.0659794],
                [104.9232919, 21.0660709],
                [104.9230921, 21.0663699],
                [104.9229919, 21.0665876],
                [104.9229526, 21.0667003],
                [104.9227703, 21.0675312],
                [104.919852, 21.0663499],
                [104.9182212, 21.0663398],
                [104.9166655, 21.0656691],
                [104.9163332, 21.0655483],
                [104.9158303, 21.0654286],
                [104.9153459, 21.0653787],
                [104.9145305, 21.0653887],
                [104.9143839, 21.0654041],
                [104.9139511, 21.0654888],
                [104.9136613, 21.0655808],
                [104.9133825, 21.0656991],
                [104.910357, 21.067281],
                [104.9110436, 21.0686826],
                [104.9112582, 21.0708851],
                [104.9126959, 21.0721265],
                [104.9159145, 21.074349],
                [104.9178135, 21.0738685],
                [104.9209476, 21.07418],
                [104.9211797, 21.0742464],
                [104.9215023, 21.0744029],
                [104.9217843, 21.0746241],
                [104.9220085, 21.0748997],
                [104.9227488, 21.0767517],
                [104.922791, 21.0769706],
                [104.922791, 21.0773037],
                [104.9227164, 21.0776263],
                [104.9225743, 21.0779216],
                [104.9223969, 21.0781582],
                [104.9223102, 21.0783201],
                [104.9222553, 21.0784936],
                [104.9222338, 21.078674],
                [104.9222467, 21.0788551],
                [104.9226884, 21.0809206],
                [104.9227425, 21.0810788],
                [104.9228794, 21.0813018],
                [104.9230866, 21.0814951],
                [104.9251628, 21.0827382],
                [104.9263859, 21.0842999],
                [104.925195, 21.0856113],
                [104.9249697, 21.0862519],
                [104.92468, 21.0867224],
                [104.9244202, 21.088067],
                [104.9243436, 21.0883241],
                [104.9242334, 21.0885776],
                [104.9240887, 21.0888232],
                [104.923816, 21.0891575],
                [104.9237115, 21.0892583],
                [104.9235182, 21.0894026],
                [104.9231189, 21.0896755],
                [104.9228706, 21.0898295],
                [104.9223577, 21.0901134],
                [104.9218253, 21.0903639],
                [104.9215525, 21.0904763],
                [104.920742, 21.0907604],
                [104.9199603, 21.0911077],
                [104.9192379, 21.0915007],
                [104.9185807, 21.0919266],
                [104.9184029, 21.0920938],
                [104.9182555, 21.092285],
                [104.9181467, 21.0924855],
                [104.9180742, 21.0926883],
                [104.9180335, 21.0928987],
                [104.9180335, 21.0936795],
                [104.9160701, 21.0950608],
                [104.9159139, 21.0956823],
                [104.9157313, 21.0962975],
                [104.9153943, 21.0972425],
                [104.9149865, 21.0981839],
                [104.9138921, 21.1004461],
                [104.913829, 21.100614],
                [104.9137308, 21.100958],
                [104.9136962, 21.1011331],
                [104.9136561, 21.101487],
                [104.9136527, 21.1017842],
                [104.9136768, 21.1020806],
                [104.913699, 21.1022277],
                [104.9140102, 21.103559],
                [104.9136814, 21.1049628],
                [104.9136407, 21.1054661],
                [104.9136776, 21.1059612],
                [104.9135463, 21.1060482],
                [104.9133021, 21.1062441],
                [104.9130855, 21.1064664],
                [104.9128997, 21.1067118],
                [104.9126881, 21.1071077],
                [104.9125564, 21.1075326],
                [104.9124596, 21.1077827],
                [104.912256, 21.1081331],
                [104.9120958, 21.1083323],
                [104.9119127, 21.1085135],
                [104.9114138, 21.1088638],
                [104.9112691, 21.1089941],
                [104.9111025, 21.1092281],
                [104.9110061, 21.1094943],
                [104.9109849, 21.1096622],
                [104.9110061, 21.1099147],
                [104.9110762, 21.1101748],
                [104.9111767, 21.110426],
                [104.9113065, 21.1106654],
                [104.9114858, 21.1109172],
                [104.9116975, 21.1111463],
                [104.9119381, 21.1113489],
                [104.911537, 21.1115694],
                [104.9111098, 21.1118665],
                [104.9107778, 21.1120772],
                [104.9102333, 21.1124119],
                [104.9097687, 21.1126472],
                [104.908788, 21.1127685],
                [104.9076505, 21.1123157],
                [104.9069629, 21.1120141],
                [104.9064076, 21.1117379],
                [104.906302, 21.1116751],
                [104.905998, 21.1114995],
                [104.9056413, 21.1112614],
                [104.9053229, 21.1110132],
                [104.9050345, 21.1108256],
                [104.9043244, 21.1105544],
                [104.9040582, 21.1103876],
                [104.9036811, 21.1100959],
                [104.9033261, 21.1097833],
                [104.9028824, 21.1095122],
                [104.9019062, 21.1090327],
                [104.9007079, 21.1086779],
                [104.8996426, 21.1084482],
                [104.8993542, 21.1083022],
                [104.899088, 21.1081563],
                [104.8985777, 21.1077811],
                [104.8981951, 21.1076121],
                [104.8977791, 21.1075068],
                [104.8972031, 21.1074764],
                [104.8967231, 21.1075211],
                [104.8958591, 21.1076257],
                [104.895283, 21.1076403],
                [104.894787, 21.107595],
                [104.8934592, 21.107384],
                [104.8925792, 21.1073084],
                [104.8924033, 21.1072482],
                [104.8922754, 21.1070979],
                [104.8917321, 21.1061818],
                [104.8912846, 21.1054008],
                [104.8911088, 21.1052054],
                [104.8909488, 21.1050702],
                [104.890677, 21.10495],
                [104.8897652, 21.1046941],
                [104.8877096, 21.1041925],
                [104.886718, 21.1038613],
                [104.8858382, 21.1034851],
                [104.884047, 21.1025823],
                [104.8826236, 21.1019202],
                [104.8810164, 21.1009499],
                [104.8793372, 21.1000021],
                [104.8780261, 21.0991598],
                [104.8768106, 21.0985579],
                [104.8765627, 21.0984149],
                [104.8764348, 21.0982645],
                [104.8762431, 21.0979189],
                [104.8760197, 21.0972575],
                [104.8757004, 21.0963708],
                [104.8754768, 21.0959799],
                [104.8745978, 21.0948524],
                [104.8741983, 21.0943712],
                [104.8729594, 21.0932058],
                [104.8721602, 21.0923188],
                [104.871505, 21.0914918],
                [104.8713453, 21.091161],
                [104.8712975, 21.0909357],
                [104.8713137, 21.0906953],
                [104.8714499, 21.0903873],
                [104.8718024, 21.0897865],
                [104.8725071, 21.0888705],
                [104.8731318, 21.0879394],
                [104.8734364, 21.0872485],
                [104.8735167, 21.0867527],
                [104.8735253, 21.0861442],
                [104.8734297, 21.0856933],
                [104.8732061, 21.0851822],
                [104.8725511, 21.084175],
                [104.871992, 21.0831828],
                [104.8714249, 21.0822584],
                [104.8706898, 21.0813563],
                [104.8696029, 21.0803187],
                [104.8692994, 21.0799127],
                [104.8691237, 21.0795821],
                [104.869012, 21.0792513],
                [104.8689643, 21.0789208],
                [104.8688612, 21.0778614],
                [104.8687021, 21.0768996],
                [104.8685745, 21.0764938],
                [104.8683988, 21.0761932],
                [104.8680951, 21.0758623],
                [104.8678075, 21.0755917],
                [104.8676155, 21.0755013],
                [104.8673437, 21.0755011],
                [104.8669276, 21.0755309],
                [104.8664318, 21.0756207],
                [104.8660157, 21.0757556],
                [104.8654076, 21.0760706],
                [104.8644791, 21.0766559],
                [104.8637911, 21.0769408],
                [104.8632311, 21.0770606],
                [104.8625113, 21.077075],
                [104.8618233, 21.0770745],
                [104.8613354, 21.0771266],
                [104.8607114, 21.0772764],
                [104.8597992, 21.0776813],
                [104.858743, 21.0782064],
                [104.8583427, 21.0785215],
                [104.8579265, 21.0788969],
                [104.857585, 21.0792421],
                [104.8574464, 21.0793132],
                [104.8573788, 21.0793164],
                [104.8573234, 21.079294],
                [104.8571492, 21.0791673],
                [104.8568165, 21.0789587],
                [104.8563254, 21.0786757],
                [104.8556678, 21.0783032],
                [104.8552956, 21.0780722],
                [104.8549391, 21.0778116],
                [104.8547507, 21.0776811],
                [104.854384, 21.0774028],
                [104.8540319, 21.0771959],
                [104.8534746, 21.0769061],
                [104.8530493, 21.0766165],
                [104.8528587, 21.076451],
                [104.8527268, 21.0762855],
                [104.8526682, 21.076134],
                [104.8526391, 21.0758585],
                [104.8526249, 21.0753626],
                [104.8527137, 21.0745776],
                [104.8528317, 21.0740542],
                [104.8530375, 21.0736688],
                [104.8532726, 21.0733383],
                [104.8533608, 21.0731732],
                [104.8534049, 21.0730078],
                [104.8534052, 21.0728563],
                [104.8533614, 21.0725395],
                [104.8533627, 21.0721668],
                [104.8534071, 21.0718636],
                [104.8535837, 21.0712986],
                [104.8536721, 21.0709678],
                [104.8536869, 21.0707887],
                [104.8536871, 21.0706232],
                [104.8536287, 21.0703061],
                [104.8534825, 21.0697407],
                [104.8534388, 21.0693961],
                [104.8534537, 21.0691618],
                [104.8535274, 21.0689136],
                [104.8537625, 21.0685141],
                [104.8541153, 21.0679355],
                [104.8542477, 21.0675495],
                [104.854438, 21.0669253],
                [104.8545556, 21.0667048],
                [104.8547173, 21.0664568],
                [104.8549963, 21.0662365],
                [104.8555249, 21.0658786],
                [104.8557305, 21.0657133],
                [104.8558922, 21.0655067],
                [104.8559658, 21.0652724],
                [104.8560985, 21.0646108],
                [104.8563342, 21.0637011],
                [104.8564671, 21.0627639],
                [104.856671, 21.0618211],
                [104.8568625, 21.0610769],
                [104.8570804, 21.0602039],
                [104.8572039, 21.0598925],
                [104.8574728, 21.0594724],
                [104.8578132, 21.0590522],
                [104.8584761, 21.0583803],
                [104.8587846, 21.0580647],
                [104.8590674, 21.0577755],
                [104.8593003, 21.0574562],
                [104.8593542, 21.0572377],
                [104.8593276, 21.056977],
                [104.8591669, 21.0564557],
                [104.8587383, 21.0554298],
                [104.8583455, 21.0544374],
                [104.8582386, 21.0539161],
                [104.8582211, 21.0536134],
                [104.8582571, 21.0533108],
                [104.8582931, 21.0531091],
                [104.8584365, 21.0529243],
                [104.8588037, 21.0525967],
                [104.8592695, 21.05216],
                [104.8602188, 21.0513201],
                [104.8608458, 21.050564],
                [104.8613655, 21.0499255],
                [104.8615986, 21.0493708],
                [104.8619666, 21.0481018],
                [104.8620925, 21.0474797],
                [104.8623619, 21.0464038],
                [104.8625952, 21.0456642],
                [104.8628284, 21.0451265],
                [104.8631761, 21.044422],
                [104.863723, 21.0433043],
                [104.8643503, 21.0422623],
                [104.8650672, 21.0410524],
                [104.8656769, 21.0397414],
                [104.8658653, 21.039111],
                [104.8659194, 21.0386403],
                [104.8660274, 21.037867],
                [104.866028, 21.0372113],
                [104.8659038, 21.0360678],
                [104.8657173, 21.0346265],
                [104.8657896, 21.0336858],
                [104.8658616, 21.0332491],
                [104.8660409, 21.0328124],
                [104.8664352, 21.0321239],
                [104.8670721, 21.0312061],
                [104.8673848, 21.0308141],
                [104.8677737, 21.0302333],
                [104.868683, 21.0296331],
                [104.8687188, 21.0296094],
                [104.8703222, 21.0288636],
                [104.8715678, 21.0282302],
                [104.8729785, 21.0275829],
                [104.8749444, 21.0265977],
                [104.8760549, 21.0260628],
                [104.8772806, 21.0254893],
                [104.8783624, 21.0249637],
                [104.8792838, 21.024344],
                [104.8802658, 21.023461],
                [104.8813478, 21.0224842],
                [104.8818435, 21.0218974],
                [104.8824703, 21.0208305],
                [104.8836735, 21.0185753],
                [104.8842549, 21.0175794],
                [104.8849669, 21.0161069],
                [104.8855282, 21.0151665],
                [104.8861695, 21.0143015],
                [104.8874019, 21.0128441],
                [104.8880833, 21.0120167],
                [104.8886845, 21.0112457],
                [104.8899273, 21.0094027],
                [104.8903884, 21.0084247],
                [104.8907694, 21.0076723],
                [104.8911803, 21.0068918],
                [104.8915815, 21.0058384],
                [104.8918024, 21.0049166],
                [104.8919632, 21.0040324],
                [104.8920745, 21.0024051],
                [104.8920903, 21.0014997],
                [104.8918547, 20.9998461],
                [104.8921881, 20.9989381],
                [104.8922091, 20.9986649],
                [104.8918562, 20.9976887],
                [104.8916903, 20.997064],
                [104.8917115, 20.996615],
                [104.892628, 20.9946833],
                [104.8936484, 20.9932004],
                [104.8938567, 20.9927515],
                [104.8939822, 20.9918148],
                [104.8945243, 20.9898436],
                [104.8949205, 20.9884971],
                [104.8951289, 20.9877945],
                [104.8952332, 20.9874238],
                [104.8955454, 20.9870921],
                [104.8963155, 20.9863899],
                [104.8974811, 20.9853366],
                [104.89848, 20.9844978],
                [104.8993127, 20.9833466],
                [104.8998956, 20.9826053],
                [104.900541, 20.9815907],
                [104.9015402, 20.9802835],
                [104.9042463, 20.9769667],
                [104.9055366, 20.9756401],
                [104.9068683, 20.9745868],
                [104.9074508, 20.9742943],
                [104.9094464, 20.9731775],
                [104.9137159, 20.9707878],
                [104.9161262, 20.9694385],
                [104.9182274, 20.9682684],
                [104.9183265, 20.9681845],
                [104.9193094, 20.9673515],
                [104.9205162, 20.9663761],
                [104.9217439, 20.9649517],
                [104.923825, 20.9622005],
                [104.9246991, 20.9607955],
                [104.9263644, 20.9575951],
                [104.92676, 20.9566583],
                [104.9286331, 20.9533409],
                [104.9294448, 20.9519943],
                [104.9313386, 20.9487744],
                [104.9321502, 20.9476035],
                [104.9340856, 20.9439152],
                [104.9354384, 20.9409878],
                [104.9362913, 20.9399732],
                [104.9371237, 20.938607],
                [104.9389763, 20.9336303],
                [104.9395797, 20.9324788],
                [104.9400374, 20.9320301],
                [104.9406197, 20.9316203],
                [104.9426368, 20.9308598],
                [104.9444876, 20.9302162],
                [104.9458809, 20.9293384],
                [104.9473743, 20.9284823],
                [104.9492498, 20.927407],
                [104.9503104, 20.9268998],
                [104.9608337, 20.9158351],
                [104.9669763, 20.9110096],
                [104.973371, 20.910743],
                [104.9783403, 20.9060394],
                [104.9780292, 20.9013547],
                [104.97778, 20.8991881],
                [104.9778217, 20.8982121],
                [104.977905, 20.897197],
                [104.9781546, 20.896104],
                [104.9786745, 20.8947962],
                [104.979735, 20.8922003],
                [104.9800884, 20.8915367],
                [104.9806706, 20.8904046],
                [104.981232, 20.8894873],
                [104.9821052, 20.8882966],
                [104.9827704, 20.8874769],
                [104.9839761, 20.8862083],
                [104.9846206, 20.8854666],
                [104.986346, 20.8834953],
                [104.9877179, 20.8820509],
                [104.9884039, 20.8811336],
                [104.9890275, 20.880021],
                [104.9896512, 20.8788889],
                [104.9903371, 20.8776983],
                [104.9907737, 20.8769566],
                [104.9913973, 20.8760196],
                [104.9916051, 20.8756098],
                [104.9913766, 20.8746143],
                [104.9914805, 20.8740482],
                [104.9918131, 20.8730723],
                [104.9921873, 20.8714911],
                [104.992416, 20.8707884],
                [104.9925823, 20.8697345],
                [104.9926447, 20.869266],
                [104.9925201, 20.8677825],
                [104.9926032, 20.8672359],
                [104.9929149, 20.8669236],
                [104.9936009, 20.866299],
                [104.9942036, 20.8655964],
                [104.9957, 20.8637811],
                [104.9961364, 20.863215],
                [104.9967599, 20.8628052],
                [104.997612, 20.8621024],
                [104.9979446, 20.8616535],
                [104.9989006, 20.8602091],
                [104.9993994, 20.8594869],
                [104.9998566, 20.8589207],
                [105.000335, 20.858371],
                [105.000312, 20.8583547],
                [105.0013608, 20.8560531],
                [105.0002686, 20.8545558],
                [104.9992193, 20.8524075],
                [104.9977687, 20.8487255],
                [104.9974062, 20.8477667],
                [104.9970853, 20.8465016],
                [104.9985071, 20.8465824],
                [105.0000515, 20.846472],
                [105.0018695, 20.8457949],
                [105.003416, 20.8456897],
                [105.0049793, 20.8455312],
                [105.0057489, 20.8454181],
                [105.0061577, 20.8451692],
                [105.006302, 20.8448524],
                [105.006326, 20.8440154],
                [105.006326, 20.8427937],
                [105.0060374, 20.841821],
                [105.0051235, 20.840441],
                [105.0046183, 20.8395248],
                [105.003969, 20.8381899],
                [105.0036082, 20.8375113],
                [105.003488, 20.8366289],
                [105.0035361, 20.8358371],
                [105.0038727, 20.8345928],
                [105.0044018, 20.8336765],
                [105.0049263, 20.8333166],
                [105.0051448, 20.8324262],
                [105.0053148, 20.831741],
                [105.0052176, 20.8304396],
                [105.0052707, 20.8296634],
                [105.0051233, 20.8292537],
                [105.005031, 20.8289435],
                [105.0049102, 20.8277257],
                [105.0042722, 20.8264018],
                [105.0040031, 20.8260556],
                [105.0035174, 20.8256674],
                [105.0029588, 20.8250965],
                [105.0018666, 20.823459],
                [105.000588, 20.8220415],
                [104.9991185, 20.8209499],
                [104.9979109, 20.8202545],
                [104.995967, 20.819458],
                [104.9940753, 20.8188099],
                [104.991921, 20.8180127],
                [104.990976, 20.8174663],
                [104.9898731, 20.8169194],
                [104.9884126, 20.8157902],
                [104.9874803, 20.8151745],
                [104.9861436, 20.8140595],
                [104.9878116, 20.8132983],
                [104.9887478, 20.8127648],
                [104.9902931, 20.8123097],
                [104.9915129, 20.8119301],
                [104.9926922, 20.8115506],
                [104.9935054, 20.8113614],
                [104.9948447, 20.8117102],
                [104.9953721, 20.8119416],
                [104.9966212, 20.8125901],
                [104.9971983, 20.8130365],
                [104.9977761, 20.8132854],
                [104.9993522, 20.8139325],
                [105.0002986, 20.8140834],
                [105.0012455, 20.8140862],
                [105.0018089, 20.8139664],
                [105.0020477, 20.8138926],
                [105.003946, 20.8125466],
                [105.0048173, 20.8120492],
                [105.0057509, 20.8112298],
                [105.0069022, 20.8100301],
                [105.0077113, 20.809123],
                [105.0089871, 20.808128],
                [105.0108256, 20.8069678],
                [105.0118195, 20.8062927],
                [105.0125847, 20.8058739],
                [105.0135576, 20.8054476],
                [105.0138214, 20.8053795],
                [105.0140529, 20.8053536],
                [105.0142598, 20.8053424],
                [105.0144819, 20.8053226],
                [105.0159107, 20.8057353],
                [105.0174044, 20.805911],
                [105.0185246, 20.8056473],
                [105.0213562, 20.8046515],
                [105.0239388, 20.8035969],
                [105.0246855, 20.8030112],
                [105.0253074, 20.8022416],
                [105.0250432, 20.801957],
                [105.0244208, 20.80143],
                [105.023985, 20.800903],
                [105.0236426, 20.7999661],
                [105.0227711, 20.7983264],
                [105.0222732, 20.7978286],
                [105.0215574, 20.797331],
                [105.0205616, 20.7968626],
                [105.0198459, 20.7961599],
                [105.0190677, 20.7950474],
                [105.0181032, 20.7946082],
                [105.0173253, 20.7941398],
                [105.0165628, 20.7931736],
                [105.0157226, 20.7920025],
                [105.0151002, 20.7910069],
                [105.014571, 20.7900114],
                [105.0141043, 20.7890158],
                [105.013513, 20.7875811],
                [105.0131084, 20.7864977],
                [105.0125182, 20.7851095],
                [105.0117238, 20.7860586],
                [105.0106037, 20.7870835],
                [105.0092659, 20.7879914],
                [105.0077724, 20.7886063],
                [105.0062168, 20.7891627],
                [105.0053456, 20.7891627],
                [105.0050655, 20.7882843],
                [105.0050654, 20.7875522],
                [105.005221, 20.786381],
                [105.0055321, 20.7851219],
                [105.0058432, 20.7841263],
                [105.0058742, 20.7830136],
                [105.0058431, 20.7819302],
                [105.0056253, 20.7814616],
                [105.0051586, 20.781081],
                [105.0044118, 20.780554],
                [105.0038829, 20.780144],
                [105.0033229, 20.7795877],
                [105.0031362, 20.7790021],
                [105.0028095, 20.7771281],
                [105.0024361, 20.776396],
                [105.0018138, 20.7751369],
                [105.0001816, 20.7700451],
                [104.9989849, 20.7666851],
                [104.9983298, 20.7650978],
                [104.9979288, 20.7645714],
                [104.9974499, 20.763671],
                [104.9969709, 20.762827],
                [104.9966716, 20.7626019],
                [104.9961926, 20.7624893],
                [104.9952347, 20.7625456],
                [104.9945972, 20.7625191],
                [104.993856, 20.7621707],
                [104.9935884, 20.7619579],
                [104.9933, 20.7616482],
                [104.9931354, 20.7611451],
                [104.9930737, 20.7608936],
                [104.9927236, 20.760371],
                [104.9924972, 20.7599841],
                [104.9923325, 20.7595196],
                [104.9921678, 20.7593454],
                [104.9916325, 20.7586681],
                [104.991509, 20.7582037],
                [104.9914266, 20.7572362],
                [104.991262, 20.7566362],
                [104.9910768, 20.7561525],
                [104.9908091, 20.7555912],
                [104.9906239, 20.7549914],
                [104.9906033, 20.7546818],
                [104.9907474, 20.7542368],
                [104.9910769, 20.7538303],
                [104.9914887, 20.753424],
                [104.9918593, 20.753037],
                [104.9924358, 20.7523792],
                [104.9929093, 20.7518566],
                [104.9937435, 20.7507564],
                [104.9938292, 20.7506522],
                [104.9969655, 20.7502527],
                [104.9997085, 20.7490773],
                [105.0017778, 20.7490607],
                [105.0023659, 20.7487135],
                [105.0028485, 20.7483851],
                [105.0034995, 20.7477175],
                [105.0039298, 20.7471811],
                [105.0044414, 20.7466121],
                [105.0049181, 20.7460867],
                [105.0052786, 20.7457254],
                [105.0055634, 20.7455503],
                [105.0060052, 20.7454299],
                [105.0066332, 20.7452657],
                [105.0072261, 20.7450468],
                [105.0076912, 20.7448279],
                [105.0080865, 20.7445214],
                [105.0085982, 20.7441384],
                [105.0092493, 20.7437004],
                [105.0096562, 20.7435035],
                [105.0102027, 20.7430875],
                [105.0104353, 20.7428248],
                [105.0105689, 20.7424965],
                [105.0106038, 20.7420915],
                [105.0106386, 20.7415771],
                [105.01072, 20.7413034],
                [105.0109641, 20.7409642],
                [105.0115105, 20.7405263],
                [105.0118012, 20.7404935],
                [105.0120919, 20.7405044],
                [105.0124059, 20.7406356],
                [105.0134239, 20.741137],
                [105.0133365, 20.7397971],
                [105.0132551, 20.7389436],
                [105.0131037, 20.738309],
                [105.0130339, 20.7377291],
                [105.0130339, 20.73739],
                [105.0130862, 20.737184],
                [105.0132025, 20.736976],
                [105.0134351, 20.7367789],
                [105.0136212, 20.7365599],
                [105.0137259, 20.7363737],
                [105.0137375, 20.7361657],
                [105.0137374, 20.7360233],
                [105.0136566, 20.7353868],
                [105.0135084, 20.7348378],
                [105.013482, 20.7347402],
                [105.0133144, 20.7340487],
                [105.0132951, 20.733526],
                [105.0131499, 20.733319],
                [105.0129928, 20.7332861],
                [105.0128567, 20.7333325],
                [105.0126904, 20.7334286],
                [105.0123644, 20.7336811],
                [105.0119196, 20.7338624],
                [105.0114804, 20.7339105],
                [105.0112191, 20.7339097],
                [105.011011, 20.7338996],
                [105.0108269, 20.7339776],
                [105.0105904, 20.7341485],
                [105.0102259, 20.7344279],
                [105.0100049, 20.7344718],
                [105.0096664, 20.7343703],
                [105.0095978, 20.7343045],
                [105.0095143, 20.7342032],
                [105.0093398, 20.7339624],
                [105.0092307, 20.7335236],
                [105.0091106, 20.7330507],
                [105.0089103, 20.7325528],
                [105.0087475, 20.7322025],
                [105.0085788, 20.7319945],
                [105.0083811, 20.7318631],
                [105.0080903, 20.7317317],
                [105.0079042, 20.7317317],
                [105.0074739, 20.7317755],
                [105.0072064, 20.7318085],
                [105.0069389, 20.7318084],
                [105.0067412, 20.7317865],
                [105.0064238, 20.7317816],
                [105.0061147, 20.7317391],
                [105.005519, 20.7315289],
                [105.0047638, 20.7312063],
                [105.0045197, 20.7311077],
                [105.0043801, 20.7310311],
                [105.004258, 20.7309107],
                [105.0041067, 20.7306808],
                [105.0039206, 20.729991],
                [105.0038624, 20.7294546],
                [105.0037694, 20.7292465],
                [105.0036066, 20.7291698],
                [105.0033159, 20.7291151],
                [105.0020714, 20.7291151],
                [105.0018773, 20.7290348],
                [105.0013012, 20.7288603],
                [105.0008549, 20.7286227],
                [105.0005696, 20.7283688],
                [105.0003118, 20.72824],
                [104.9998498, 20.7281627],
                [104.999624, 20.7281048],
                [104.999234, 20.7280469],
                [104.9989055, 20.7280083],
                [104.9984333, 20.7280084],
                [104.9979407, 20.728047],
                [104.9974685, 20.7280663],
                [104.99714, 20.7281241],
                [104.9968937, 20.7282399],
                [104.9961751, 20.7285101],
                [104.9958671, 20.7286066],
                [104.9955592, 20.7287224],
                [104.9953128, 20.7288575],
                [104.9949638, 20.7290504],
                [104.994697, 20.729089],
                [104.9944095, 20.7291277],
                [104.9941426, 20.7291083],
                [104.9939168, 20.7290697],
                [104.9937013, 20.7289925],
                [104.9935371, 20.7288188],
                [104.9932702, 20.7286258],
                [104.9930854, 20.7283941],
                [104.9929418, 20.728124],
                [104.9927365, 20.7278344],
                [104.9926133, 20.7276995],
                [104.9925723, 20.7276608],
                [104.992367, 20.7274485],
                [104.9921206, 20.727352],
                [104.9918332, 20.7273134],
                [104.991402, 20.7273327],
                [104.9911967, 20.7273712],
                [104.9909093, 20.7274292],
                [104.9906671, 20.7275116],
                [104.9904076, 20.7275913],
                [104.9903049, 20.7276491],
                [104.9898613, 20.7278408],
                [104.9897318, 20.7278841],
                [104.9895187, 20.7279707],
                [104.9892683, 20.7280793],
                [104.989037, 20.7281962],
                [104.9888491, 20.728265],
                [104.9886849, 20.7283808],
                [104.9884717, 20.7285349],
                [104.988351, 20.7286392],
                [104.9882397, 20.7287523],
                [104.9880967, 20.7288765],
                [104.9880146, 20.7289729],
                [104.9879119, 20.7290115],
                [104.9877272, 20.7290115],
                [104.9876245, 20.7289729],
                [104.9875321, 20.7288764],
                [104.9874501, 20.7287413],
                [104.9873679, 20.7284904],
                [104.9873064, 20.7282588],
                [104.9872247, 20.728025],
                [104.9871144, 20.7278327],
                [104.9869801, 20.7276889],
                [104.9867317, 20.7275061],
                [104.9865174, 20.7274013],
                [104.9864166, 20.7273519],
                [104.986006, 20.7273133],
                [104.9857257, 20.7274866],
                [104.9855203, 20.7277182],
                [104.9853356, 20.7279498],
                [104.9851919, 20.72822],
                [104.9850686, 20.7284324],
                [104.9848839, 20.7286253],
                [104.984658, 20.7287217],
                [104.9843911, 20.7287411],
                [104.9837136, 20.7287409],
                [104.9829746, 20.728683],
                [104.9827693, 20.7287409],
                [104.9825024, 20.7288373],
                [104.9822766, 20.7289531],
                [104.981948, 20.729146],
                [104.9817017, 20.7292618],
                [104.9814964, 20.7294548],
                [104.9813116, 20.7296092],
                [104.9809933, 20.7298793],
                [104.980747, 20.7301109],
                [104.9803979, 20.7303618],
                [104.9801515, 20.7304969],
                [104.9796384, 20.7305161],
                [104.9793509, 20.7304389],
                [104.9791866, 20.730381],
                [104.978961, 20.7301107],
                [104.9787967, 20.7298984],
                [104.978571, 20.7296088],
                [104.9784478, 20.7293386],
                [104.9783041, 20.7290878],
                [104.9781193, 20.7288755],
                [104.9779141, 20.7286051],
                [104.9776472, 20.7282771],
                [104.977442, 20.7280648],
                [104.9771752, 20.7279103],
                [104.9768262, 20.7277559],
                [104.9763746, 20.7276979],
                [104.9762514, 20.7276593],
                [104.9760872, 20.7275821],
                [104.9758408, 20.727447],
                [104.975574, 20.7272154],
                [104.9753481, 20.7269644],
                [104.9751635, 20.7267714],
                [104.9749992, 20.7266555],
                [104.9747837, 20.7265976],
                [104.9744552, 20.7265783],
                [104.9736751, 20.7266553],
                [104.9729565, 20.7267518],
                [104.9724843, 20.7269254],
                [104.9719916, 20.7270989],
                [104.9716836, 20.727234],
                [104.9713962, 20.727369],
                [104.9711293, 20.7275235],
                [104.9709034, 20.7277164],
                [104.9704517, 20.7282373],
                [104.9702463, 20.7285655],
                [104.9699793, 20.7289321],
                [104.9697741, 20.7291637],
                [104.969529, 20.7294116],
                [104.9624648, 20.7349376],
                [104.9610494, 20.7342352],
                [104.9605224, 20.7344796],
                [104.9598844, 20.7350776],
                [104.9584082, 20.7360533],
                [104.9568926, 20.73683],
                [104.9561348, 20.737283],
                [104.9555376, 20.7378225],
                [104.9552619, 20.7384699],
                [104.9548714, 20.7389446],
                [104.9545727, 20.7392898],
                [104.9540446, 20.7397214],
                [104.9539522, 20.7397631],
                [104.9536499, 20.7396312],
                [104.9533754, 20.7393974],
                [104.9526066, 20.7388774],
                [104.9512335, 20.7379934],
                [104.949118, 20.736874],
                [104.9475505, 20.7362582],
                [104.9471574, 20.7361798],
                [104.9469238, 20.7361524],
                [104.9467901, 20.7361365],
                [104.946262, 20.7360932],
                [104.9458028, 20.7362009],
                [104.9453664, 20.7364167],
                [104.9452286, 20.7366324],
                [104.9448839, 20.7374309],
                [104.9447689, 20.7379488],
                [104.9430231, 20.7400202],
                [104.9426785, 20.7405382],
                [104.9408347, 20.7427195],
                [104.9407948, 20.7427823],
                [104.9401057, 20.7433],
                [104.9391796, 20.7441358],
                [104.9361675, 20.7441415],
                [104.9334696, 20.7441843],
                [104.9308823, 20.7441241],
                [104.9281844, 20.7441669],
                [104.9250129, 20.7438345],
                [104.9237266, 20.7437381],
                [104.9227871, 20.7446664],
                [104.9169318, 20.7531201],
                [104.915776, 20.7533973],
                [104.9128959, 20.7541029],
                [104.9095903, 20.754609],
                [104.9080256, 20.7540385],
                [104.9079369, 20.7540203],
                [104.9064623, 20.7537168],
                [104.9035004, 20.7537178],
                [104.9004752, 20.7551077],
                [104.8995918, 20.7557369],
                [104.8986778, 20.7567093],
                [104.8974919, 20.7572777],
                [104.8959669, 20.7583103],
                [104.8949809, 20.7586398],
                [104.8940481, 20.759082],
                [104.8936641, 20.7596052],
                [104.8931032, 20.7607411],
                [104.8928163, 20.7634861],
                [104.8891595, 20.7629551],
                [104.8864961, 20.7632294],
                [104.8856712, 20.7636687],
                [104.8850739, 20.7640784],
                [104.8839936, 20.7653295],
                [104.8832579, 20.7662786],
                [104.8824534, 20.7672709],
                [104.8822007, 20.7674866],
                [104.8819212, 20.7675267],
                [104.8817872, 20.7675079],
                [104.8808201, 20.7674869],
                [104.8800557, 20.7673837],
                [104.8786772, 20.7671006],
                [104.8783223, 20.7670746],
                [104.8779126, 20.7671513],
                [104.877148, 20.7674073],
                [104.8766017, 20.7676122],
                [104.8760829, 20.7677657],
                [104.8755642, 20.7677653],
                [104.8747997, 20.7677135],
                [104.8743083, 20.7676106],
                [104.8740902, 20.7673795],
                [104.8735172, 20.7668659],
                [104.8722893, 20.7660183],
                [104.871266, 20.7654019],
                [104.8700381, 20.7646827],
                [104.8689351, 20.7641952],
                [104.8684006, 20.7639374],
                [104.8677732, 20.7632956],
                [104.8671733, 20.7625254],
                [104.8663824, 20.7615243],
                [104.8654144, 20.7602922],
                [104.865278, 20.7600612],
                [104.8648144, 20.7595733],
                [104.8644787, 20.7593405],
                [104.8643103, 20.7591576],
                [104.8592787, 20.7536897],
                [104.8570827, 20.7545922],
                [104.8562246, 20.7543406],
                [104.8544771, 20.7539853],
                [104.8534183, 20.7536824],
                [104.8514058, 20.7533261],
                [104.8493919, 20.7532687],
                [104.8476427, 20.7533119],
                [104.8452199, 20.7528418],
                [104.8437107, 20.7525124],
                [104.8428638, 20.7522601],
                [104.8418854, 20.7517581],
                [104.8410132, 20.7512068],
                [104.8399037, 20.7504054],
                [104.8389794, 20.7496796],
                [104.8378121, 20.7480942],
                [104.8374931, 20.7473426],
                [104.8372951, 20.746876],
                [104.8369009, 20.745362],
                [104.8367233, 20.7438692],
                [104.8365224, 20.7419752],
                [104.8364834, 20.740128],
                [104.8365125, 20.7384091],
                [104.8365148, 20.7363311],
                [104.8367486, 20.7346381],
                [104.8370494, 20.7342023],
                [104.8380599, 20.7336388],
                [104.8391248, 20.7332549],
                [104.8418826, 20.7324109],
                [104.8444221, 20.7315153],
                [104.8447225, 20.7313103],
                [104.8447893, 20.7311382],
                [104.844832, 20.7310282],
                [104.8448049, 20.7307973],
                [104.844028, 20.7297705],
                [104.8434834, 20.7286154],
                [104.8427215, 20.7262802],
                [104.8422851, 20.725895],
                [104.8414264, 20.724945],
                [104.8403362, 20.7234045],
                [104.8394098, 20.7218644],
                [104.8391922, 20.7212229],
                [104.8391382, 20.7206071],
                [104.8390166, 20.7194525],
                [104.8389087, 20.718221],
                [104.8388827, 20.7169895],
                [104.8387479, 20.7154501],
                [104.8393903, 20.7144759],
                [104.8399916, 20.7136297],
                [104.8402655, 20.7127578],
                [104.8402387, 20.7122959],
                [104.840403, 20.711706],
                [104.8406765, 20.7111675],
                [104.8411958, 20.7105267],
                [104.8419053, 20.7104503],
                [104.8425878, 20.7102457],
                [104.8431887, 20.7099384],
                [104.8439395, 20.7096055],
                [104.8447316, 20.7090162],
                [104.8448683, 20.7087854],
                [104.8455242, 20.7078624],
                [104.8465903, 20.7061958],
                [104.8477515, 20.7047858],
                [104.8492266, 20.7034787],
                [104.849336, 20.7032222],
                [104.8493089, 20.7029399],
                [104.8489271, 20.7026318],
                [104.848136, 20.7023745],
                [104.8470721, 20.7019888],
                [104.84609, 20.7015518],
                [104.8455174, 20.7011921],
                [104.8448083, 20.7006527],
                [104.8439903, 20.6999849],
                [104.8431448, 20.6994198],
                [104.8427085, 20.6991628],
                [104.8417264, 20.698931],
                [104.8410305, 20.6988791],
                [104.8402663, 20.6989297],
                [104.8393655, 20.6991341],
                [104.8386286, 20.6992873],
                [104.8378919, 20.6991071],
                [104.8375921, 20.6987732],
                [104.8368016, 20.6979515],
                [104.8364751, 20.697002],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.8953248, 20.8977921],
                [105.8953656, 20.8964629],
                [105.8956999, 20.8952697],
                [105.8960029, 20.8945061],
                [105.8962284, 20.8939379],
                [105.8968954, 20.8931213],
                [105.8971964, 20.8927413],
                [105.8982947, 20.891958],
                [105.8985559, 20.8917717],
                [105.9006466, 20.8907523],
                [105.9030779, 20.8896395],
                [105.9059954, 20.8882949],
                [105.9078434, 20.887506],
                [105.9095238, 20.8867693],
                [105.9096428, 20.8867171],
                [105.911806, 20.8854748],
                [105.9147193, 20.8840407],
                [105.915051, 20.8838571],
                [105.9183406, 20.8817666],
                [105.9204284, 20.8803346],
                [105.9216888, 20.8791362],
                [105.9228034, 20.8780303],
                [105.9243552, 20.876647],
                [105.9250682, 20.8763294],
                [105.9250846, 20.8763221],
                [105.9262046, 20.8760868],
                [105.9273011, 20.8758255],
                [105.9284923, 20.8754785],
                [105.9303876, 20.8744598],
                [105.9313097, 20.8737673],
                [105.9323752, 20.8726158],
                [105.9330501, 20.8713748],
                [105.9338693, 20.8698121],
                [105.9345448, 20.8686627],
                [105.9346659, 20.86842],
                [105.9355079, 20.8667326],
                [105.9359849, 20.8650343],
                [105.9361387, 20.8638603],
                [105.9361711, 20.8636127],
                [105.936212, 20.8623293],
                [105.9361054, 20.8608634],
                [105.9359795, 20.8595984],
                [105.9358373, 20.858489],
                [105.9356239, 20.8567922],
                [105.9350626, 20.8549658],
                [105.9340836, 20.8528793],
                [105.9332806, 20.851543],
                [105.9322345, 20.8503053],
                [105.9316077, 20.84985],
                [105.928546, 20.8485521],
                [105.9267367, 20.8477079],
                [105.9244403, 20.8466691],
                [105.9229096, 20.8460201],
                [105.92103, 20.8449149],
                [105.9191502, 20.8436794],
                [105.9186986, 20.8433724],
                [105.9183843, 20.8431588],
                [105.9168525, 20.8421182],
                [105.9159814, 20.8412263],
                [105.9152135, 20.8399888],
                [105.9144457, 20.8387514],
                [105.9142873, 20.8384108],
                [105.9135369, 20.8367967],
                [105.9127678, 20.8351027],
                [105.9126127, 20.8346576],
                [105.9119274, 20.8326913],
                [105.9110864, 20.8300843],
                [105.910867, 20.8290415],
                [105.9108049, 20.8278544],
                [105.9107809, 20.8269081],
                [105.9107957, 20.8263229],
                [105.9107781, 20.8260294],
                [105.9107142, 20.8249639],
                [105.910797, 20.823494],
                [105.910828, 20.8225468],
                [105.9108953, 20.8215478],
                [105.9108971, 20.8214638],
                [105.9109096, 20.8208847],
                [105.9109611, 20.820299],
                [105.9112118, 20.8192991],
                [105.9114818, 20.8184883],
                [105.9118232, 20.8173415],
                [105.9121104, 20.8163241],
                [105.9123438, 20.8154963],
                [105.9126376, 20.8145544],
                [105.9144528, 20.809242],
                [105.9151487, 20.8073126],
                [105.9154248, 20.8066382],
                [105.9158217, 20.8055857],
                [105.9161241, 20.8048289],
                [105.9163823, 20.8041828],
                [105.9173327, 20.8016898],
                [105.918955, 20.7980636],
                [105.9199229, 20.7964297],
                [105.9217904, 20.7935532],
                [105.9219162, 20.7933898],
                [105.9228977, 20.7921147],
                [105.9235492, 20.7914269],
                [105.924075, 20.7908717],
                [105.9253375, 20.7895912],
                [105.9268457, 20.7881889],
                [105.9282665, 20.7871085],
                [105.9284458, 20.7870068],
                [105.9292121, 20.7865722],
                [105.9293067, 20.7865185],
                [105.9297925, 20.7863867],
                [105.9324192, 20.7853399],
                [105.9347784, 20.7845401],
                [105.9376332, 20.7836804],
                [105.9399238, 20.7832283],
                [105.9427006, 20.7827642],
                [105.9470399, 20.7822635],
                [105.9491919, 20.7819316],
                [105.9525237, 20.7812702],
                [105.9535261, 20.7811262],
                [105.9539122, 20.7810707],
                [105.9575902, 20.780082],
                [105.960296, 20.779161],
                [105.962099, 20.7782424],
                [105.9632078, 20.7774562],
                [105.9640389, 20.776736],
                [105.9656656, 20.7749368],
                [105.9669104, 20.7732365],
                [105.9674933, 20.7720515],
                [105.9679122, 20.7711997],
                [105.9684352, 20.7698273],
                [105.9684458, 20.7695427],
                [105.9685101, 20.7678086],
                [105.9682978, 20.7647242],
                [105.9678827, 20.7619642],
                [105.9676704, 20.7606605],
                [105.9676676, 20.7597475],
                [105.9676666, 20.7594289],
                [105.9676623, 20.758052],
                [105.9675867, 20.7560307],
                [105.967495, 20.7542532],
                [105.9674073, 20.7520826],
                [105.9673623, 20.7508141],
                [105.9674982, 20.7490106],
                [105.9675969, 20.7481201],
                [105.9677597, 20.7469298],
                [105.9678848, 20.7455453],
                [105.9679761, 20.744584],
                [105.9679935, 20.7435089],
                [105.9680119, 20.7433291],
                [105.9681178, 20.7422953],
                [105.968261, 20.7415517],
                [105.9683033, 20.7405451],
                [105.9685144, 20.7393086],
                [105.9687852, 20.7378776],
                [105.9692853, 20.7361592],
                [105.96957, 20.7350139],
                [105.9695865, 20.7342932],
                [105.9695865, 20.7342931],
                [105.9692339, 20.7338149],
                [105.9684252, 20.7329275],
                [105.9674934, 20.7318348],
                [105.9661078, 20.7301842],
                [105.9654218, 20.7294562],
                [105.9649055, 20.7286355],
                [105.9644845, 20.7274715],
                [105.9642444, 20.7260661],
                [105.9643349, 20.7249905],
                [105.9644269, 20.7241436],
                [105.9646409, 20.7233647],
                [105.9650591, 20.7221386],
                [105.9653695, 20.7211991],
                [105.9659695, 20.7198691],
                [105.9666513, 20.7180239],
                [105.9671048, 20.7166261],
                [105.9679798, 20.714551],
                [105.9684576, 20.7131531],
                [105.9691864, 20.7110561],
                [105.969617, 20.7098871],
                [105.9702166, 20.7085113],
                [105.9708987, 20.7067348],
                [105.9713777, 20.7055197],
                [105.9718563, 20.7042591],
                [105.9722977, 20.7028956],
                [105.9727039, 20.7017268],
                [105.9731613, 20.7005034],
                [105.973194, 20.700477],
                [105.9738363, 20.699959],
                [105.9748874, 20.699623],
                [105.9765699, 20.6993832],
                [105.9777375, 20.6993308],
                [105.978881, 20.6993243],
                [105.9797094, 20.6995026],
                [105.9811262, 20.6998655],
                [105.9811263, 20.6998656],
                [105.9825486, 20.700207],
                [105.9834031, 20.7006596],
                [105.9844531, 20.7012254],
                [105.9858709, 20.7022465],
                [105.9868724, 20.7028355],
                [105.9881792, 20.7036172],
                [105.9889836, 20.7038413],
                [105.9900067, 20.7040184],
                [105.9917345, 20.7040542],
                [105.9941812, 20.7042574],
                [105.9952762, 20.7042511],
                [105.9971256, 20.7042862],
                [105.9987072, 20.704277],
                [105.9999426, 20.7043111],
                [106.0005744, 20.7043286],
                [106.0018748, 20.7044412],
                [106.0028137, 20.7043632],
                [106.0028888, 20.7043642],
                [106.0043205, 20.7043816],
                [106.0062104, 20.7041526],
                [106.0068467, 20.7040944],
                [106.0074059, 20.7040184],
                [106.0077338, 20.703962],
                [106.0089182, 20.7036008],
                [106.012057, 20.7025466],
                [106.0144064, 20.7017878],
                [106.0172078, 20.700781],
                [106.0190644, 20.6999252],
                [106.0202753, 20.6991912],
                [106.0217156, 20.6981288],
                [106.0237018, 20.6964545],
                [106.0249289, 20.6952479],
                [106.0258487, 20.6942794],
                [106.0268545, 20.6931742],
                [106.0279405, 20.6919906],
                [106.0280614, 20.6918588],
                [106.0282549, 20.6916404],
                [106.0292106, 20.6905619],
                [106.0302529, 20.6891838],
                [106.0310743, 20.6879069],
                [106.0323063, 20.6860371],
                [106.0335671, 20.6841399],
                [106.0356282, 20.6796778],
                [106.0370546, 20.6755024],
                [106.0376116, 20.6719998],
                [106.0377374, 20.6685804],
                [106.0372514, 20.6647211],
                [106.0367409, 20.6619424],
                [106.0362358, 20.6598153],
                [106.0353174, 20.6576136],
                [106.0336636, 20.6537316],
                [106.0330203, 20.6522216],
                [106.0325656, 20.6505729],
                [106.0313635, 20.646214],
                [106.031569, 20.6452135],
                [106.0323092, 20.643392],
                [106.0331054, 20.6412796],
                [106.0331812, 20.6410627],
                [106.0331975, 20.641016],
                [106.0337582, 20.6394132],
                [106.0344557, 20.6369924],
                [106.0349758, 20.6354539],
                [106.035735, 20.6336142],
                [106.0367222, 20.6316876],
                [106.036725, 20.6316822],
                [106.0369442, 20.6312903],
                [106.0380684, 20.6292303],
                [106.0390044, 20.6278619],
                [106.0397218, 20.6269946],
                [106.0413683, 20.6251676],
                [106.0435616, 20.6229103],
                [106.0450931, 20.6212113],
                [106.0468274, 20.6195564],
                [106.0485906, 20.6179105],
                [106.049778, 20.6166676],
                [106.0509171, 20.615416],
                [106.051165, 20.6150148],
                [106.0514129, 20.6145773],
                [106.0517176, 20.614012],
                [106.0522897, 20.6130638],
                [106.052969, 20.6122692],
                [106.054618, 20.6108601],
                [106.056094, 20.6095428],
                [106.0575225, 20.608323],
                [106.0597694, 20.6069291],
                [106.0614418, 20.606138],
                [106.0638856, 20.605324],
                [106.0656936, 20.6046409],
                [106.0672868, 20.6042394],
                [106.0704129, 20.6036303],
                [106.0704263, 20.6036276],
                [106.0734623, 20.6032139],
                [106.0766052, 20.6030956],
                [106.0787046, 20.6036255],
                [106.0796245, 20.6040393],
                [106.080782, 20.6047232],
                [106.0826251, 20.6060445],
                [106.0850491, 20.6080039],
                [106.086842, 20.6096215],
                [106.0910268, 20.6123349],
                [106.0939739, 20.6141427],
                [106.0966029, 20.6154095],
                [106.0998071, 20.6165245],
                [106.1041653, 20.6177801],
                [106.1060105, 20.6181745],
                [106.1066318, 20.6183073],
                [106.1076812, 20.618498],
                [106.1090442, 20.618588],
                [106.1111397, 20.6185251],
                [106.1137054, 20.6182618],
                [106.1208502, 20.6172283],
                [106.1216925, 20.6180636],
                [106.1237458, 20.6194323],
                [106.1267395, 20.6216091],
                [106.1277952, 20.6226553],
                [106.1282878, 20.6231455],
                [106.1286928, 20.6235871],
                [106.1291609, 20.6242997],
                [106.1292523, 20.6248586],
                [106.1292208, 20.6253358],
                [106.1291186, 20.6256655],
                [106.1287385, 20.6274202],
                [106.1286266, 20.6278654],
                [106.1285369, 20.6280942],
                [106.12767, 20.630965],
                [106.1246048, 20.6330095],
                [106.122336, 20.6343928],
                [106.1210122, 20.6353269],
                [106.1187529, 20.6364269],
                [106.1183698, 20.6365939],
                [106.117846, 20.6366138],
                [106.1147774, 20.6361812],
                [106.1130296, 20.6360114],
                [106.112314, 20.6360984],
                [106.111652, 20.6363329],
                [106.1109038, 20.6367161],
                [106.1103749, 20.6372543],
                [106.1101337, 20.6377164],
                [106.10986, 20.6385079],
                [106.1097264, 20.6393643],
                [106.1096698, 20.6399816],
                [106.1097803, 20.6407706],
                [106.1098352, 20.6411157],
                [106.1101029, 20.6418873],
                [106.1104147, 20.6427326],
                [106.1111032, 20.6437482],
                [106.1117367, 20.6443857],
                [106.1124918, 20.6449238],
                [106.1134384, 20.6453784],
                [106.1143309, 20.6456194],
                [106.1155549, 20.6457925],
                [106.1168573, 20.6459403],
                [106.1190502, 20.646033],
                [106.1207095, 20.6460058],
                [106.1212336, 20.6460188],
                [106.1220734, 20.6462108],
                [106.1226163, 20.6463882],
                [106.1240088, 20.6469139],
                [106.1252532, 20.6474816],
                [106.1257092, 20.6477419],
                [106.1260603, 20.64797],
                [106.1264989, 20.6482302],
                [106.1270255, 20.6485724],
                [106.1280371, 20.6495611],
                [106.129041, 20.6506733],
                [106.1298863, 20.6515891],
                [106.1302036, 20.6519654],
                [106.1311646, 20.6531848],
                [106.1328236, 20.6554772],
                [106.1343647, 20.6571698],
                [106.1351211, 20.6578722],
                [106.1357357, 20.658296],
                [106.1362624, 20.6586545],
                [106.1367884, 20.6589143],
                [106.1373491, 20.6591409],
                [106.1381191, 20.6593332],
                [106.1390377, 20.6595328],
                [106.1407332, 20.6596533],
                [106.1427948, 20.6596889],
                [106.1435195, 20.6596595],
                [106.1439038, 20.6596405],
                [106.144392, 20.6595221],
                [106.1451413, 20.6592702],
                [106.1460812, 20.6588199],
                [106.1475862, 20.6580119],
                [106.1487689, 20.6573131],
                [106.149758, 20.6564345],
                [106.1506076, 20.6555898],
                [106.1525504, 20.6535266],
                [106.1526265, 20.6534458],
                [106.155081, 20.6511509],
                [106.1552715, 20.6509884],
                [106.1554292, 20.6508499],
                [106.1561154, 20.6502477],
                [106.1568041, 20.649679],
                [106.1580295, 20.648613],
                [106.1586832, 20.6480927],
                [106.1594463, 20.64752],
                [106.1599914, 20.6471294],
                [106.1610273, 20.6464001],
                [106.1611663, 20.6462484],
                [106.1624173, 20.645368],
                [106.1629742, 20.645101],
                [106.163288, 20.6450166],
                [106.1636457, 20.6449567],
                [106.1651475, 20.6449137],
                [106.1659859, 20.644908],
                [106.1665799, 20.6449204],
                [106.1671747, 20.645048],
                [106.1675077, 20.6451774],
                [106.1683047, 20.6454763],
                [106.1685502, 20.6456063],
                [106.1688494, 20.6459004],
                [106.169466, 20.6465872],
                [106.169784, 20.6470457],
                [106.1699788, 20.6473899],
                [106.1701328, 20.6481209],
                [106.170208, 20.6488279],
                [106.1702713, 20.6513857],
                [106.1702192, 20.6530342],
                [106.170215, 20.6531629],
                [106.1703447, 20.6541327],
                [106.1705869, 20.6549619],
                [106.1710658, 20.6559128],
                [106.1714538, 20.6563873],
                [106.1719995, 20.6569265],
                [106.1723163, 20.657237],
                [106.1727383, 20.6575961],
                [106.1731068, 20.6578075],
                [106.17358, 20.658018],
                [106.1742627, 20.6581943],
                [106.1749527, 20.6581979],
                [106.176454, 20.6580725],
                [106.177571, 20.6579332],
                [106.1783906, 20.6577467],
                [106.1784042, 20.657742],
                [106.1799673, 20.6572013],
                [106.1816228, 20.6566799],
                [106.1821451, 20.6564459],
                [106.1830146, 20.6559464],
                [106.185457, 20.6543915],
                [106.1892165, 20.6521529],
                [106.1904215, 20.6514865],
                [106.1922466, 20.6503222],
                [106.1951936, 20.6485662],
                [106.1970806, 20.647405],
                [106.1971406, 20.6473681],
                [106.1982004, 20.6466369],
                [106.1998602, 20.6455642],
                [106.201493, 20.6444012],
                [106.2019622, 20.6441018],
                [106.2024669, 20.643868],
                [106.2029722, 20.6437],
                [106.2039928, 20.6435366],
                [106.2065754, 20.6432224],
                [106.209325, 20.6430469],
                [106.21252, 20.64286],
                [106.213724, 20.6427035],
                [106.214814, 20.64249],
                [106.2156154, 20.6422376],
                [106.2167829, 20.641884],
                [106.2180552, 20.6415294],
                [106.2192503, 20.6413648],
                [106.221416, 20.6413331],
                [106.2232154, 20.6413861],
                [106.2241251, 20.6415607],
                [106.2242508, 20.6416277],
                [106.224704, 20.6418692],
                [106.2247197, 20.6418896],
                [106.2248804, 20.6420982],
                [106.2249693, 20.6422785],
                [106.2250868, 20.6427795],
                [106.2251075, 20.6431742],
                [106.2250583, 20.6435858],
                [106.2249223, 20.6440475],
                [106.2247681, 20.6444269],
                [106.224407, 20.645137],
                [106.2240356, 20.6456578],
                [106.223585, 20.6461051],
                [106.2224586, 20.6472154],
                [106.2209238, 20.6485999],
                [106.2193993, 20.6501901],
                [106.2184114, 20.6511677],
                [106.2177978, 20.65197],
                [106.2167431, 20.6533429],
                [106.216571, 20.6536566],
                [106.2164858, 20.653937],
                [106.2164711, 20.6542826],
                [106.2165786, 20.6546273],
                [106.2167992, 20.6548971],
                [106.217835, 20.6555479],
                [106.2190796, 20.6560985],
                [106.2203405, 20.6565008],
                [106.2216789, 20.6567628],
                [106.2244756, 20.6569734],
                [106.2266181, 20.6573118],
                [106.2299798, 20.6582585],
                [106.2317055, 20.6588796],
                [106.2325296, 20.6592686],
                [106.2328635, 20.659513],
                [106.2332507, 20.6598721],
                [106.2334449, 20.6601174],
                [106.2336741, 20.6603955],
                [106.2343998, 20.6615585],
                [106.2344619, 20.6616813],
                [106.2354376, 20.6635169],
                [106.2361724, 20.6647373],
                [106.2368783, 20.6656206],
                [106.2380582, 20.6667803],
                [106.2395104, 20.6681435],
                [106.2413733, 20.6695121],
                [106.2430431, 20.6707503],
                [106.2449661, 20.671976],
                [106.2464755, 20.6728366],
                [106.2474578, 20.6733227],
                [106.2482467, 20.6736623],
                [106.2495079, 20.6740642],
                [106.249714, 20.674155],
                [106.2502547, 20.6743933],
                [106.2524806, 20.6750925],
                [106.2533615, 20.6753472],
                [106.254057, 20.6755815],
                [106.25466, 20.6757889],
                [106.2551467, 20.6759218],
                [106.2560271, 20.676133],
                [106.2569545, 20.676431],
                [106.2577703, 20.6766673],
                [106.2592968, 20.6770482],
                [106.2595004, 20.6771252],
                [106.2606148, 20.6775474],
                [106.2617428, 20.6779593],
                [106.2617847, 20.6779777],
                [106.2626252, 20.6783449],
                [106.2641485, 20.6789359],
                [106.2652873, 20.6794039],
                [106.2660565, 20.6797063],
                [106.2669766, 20.6803111],
                [106.2676352, 20.6808104],
                [106.2685488, 20.6818634],
                [106.2689205, 20.6823088],
                [106.2691901, 20.682755],
                [106.2694505, 20.6833319],
                [106.2695096, 20.6836816],
                [106.2695282, 20.6847149],
                [106.2672711, 20.6845694],
                [106.2654646, 20.6844343],
                [106.2649112, 20.684379],
                [106.2643419, 20.6843831],
                [106.2639034, 20.6844606],
                [106.2625073, 20.6847676],
                [106.2619399, 20.6849944],
                [106.2602894, 20.6860158],
                [106.2582298, 20.6877302],
                [106.258002, 20.6877171],
                [106.257887, 20.6875843],
                [106.2565923, 20.6863916],
                [106.2564619, 20.6863629],
                [106.2561052, 20.686499],
                [106.2544714, 20.6875795],
                [106.2543404, 20.6874618],
                [106.2536375, 20.6870365],
                [106.2534913, 20.6870523],
                [106.2531021, 20.6871888],
                [106.2526622, 20.6871028],
                [106.2518739, 20.6867517],
                [106.2515199, 20.6865754],
                [106.2511658, 20.6864538],
                [106.2507177, 20.686605],
                [106.2503096, 20.6869537],
                [106.2496389, 20.686952],
                [106.2494082, 20.6868163],
                [106.2492128, 20.6867129],
                [106.2489973, 20.6867145],
                [106.2487619, 20.6868209],
                [106.2485625, 20.6869886],
                [106.2484672, 20.6873467],
                [106.2483896, 20.6876288],
                [106.2483772, 20.6878056],
                [106.2484203, 20.6883945],
                [106.2485647, 20.6890481],
                [106.247899, 20.6892558],
                [106.2472231, 20.6894833],
                [106.2469671, 20.6896422],
                [106.2468844, 20.6897149],
                [106.2467336, 20.6899843],
                [106.2467143, 20.6901743],
                [106.2467202, 20.6904623],
                [106.2468011, 20.6910116],
                [106.2468384, 20.6913124],
                [106.2468262, 20.6915221],
                [106.2467694, 20.6918105],
                [106.2467013, 20.6919877],
                [106.2466122, 20.6921323],
                [106.2463983, 20.6923368],
                [106.2462049, 20.6924888],
                [106.2457455, 20.6928391],
                [106.2454142, 20.6931229],
                [106.2452486, 20.6932879],
                [106.2450831, 20.6934396],
                [106.2449453, 20.6935911],
                [106.2447501, 20.6939526],
                [106.2446611, 20.6941299],
                [106.2445998, 20.6942679],
                [106.2445524, 20.6944253],
                [106.2444291, 20.6950743],
                [106.2443475, 20.695304],
                [106.2442858, 20.6954092],
                [106.2440653, 20.6956464],
                [106.2433576, 20.6962407],
                [106.2425975, 20.6968092],
                [106.2419514, 20.6972851],
                [106.2417097, 20.6974833],
                [106.2409285, 20.6980192],
                [106.2403097, 20.6984229],
                [106.2395281, 20.6989065],
                [106.2388125, 20.699396],
                [106.2381973, 20.699826],
                [106.2378034, 20.7001234],
                [106.2373161, 20.7004672],
                [106.236929, 20.700745],
                [106.2362307, 20.7012082],
                [106.2356256, 20.701612],
                [106.2352595, 20.7018894],
                [106.2348793, 20.7021671],
                [106.2343881, 20.7024587],
                [106.2339765, 20.7027104],
                [106.233769, 20.7028429],
                [106.2332567, 20.7031083],
                [106.2323316, 20.7034815],
                [106.2316937, 20.7036891],
                [106.2310418, 20.7038835],
                [106.2301643, 20.7041123],
                [106.2295604, 20.7042411],
                [106.2291997, 20.7043288],
                [106.2289637, 20.7043894],
                [106.2287211, 20.7044631],
                [106.2284093, 20.7045962],
                [106.2281634, 20.7047093],
                [106.2279701, 20.7048875],
                [106.2278048, 20.7050653],
                [106.2277281, 20.7051615],
                [106.2276946, 20.7052035],
                [106.2275708, 20.7053681],
                [106.2274578, 20.7055784],
                [106.2272943, 20.705992],
                [106.2271574, 20.7062548],
                [106.2267905, 20.7068924],
                [106.2262837, 20.7078452],
                [106.2261171, 20.7083047],
                [106.2259625, 20.7089212],
                [106.2259082, 20.7091375],
                [106.2258543, 20.7093343],
                [106.225776, 20.7095377],
                [106.2254882, 20.7100636],
                [106.2251846, 20.7107922],
                [106.2249522, 20.7112849],
                [106.2246926, 20.7118497],
                [106.2246014, 20.7121842],
                [106.2243459, 20.7141303],
                [106.2242257, 20.715172],
                [106.224107, 20.71641],
                [106.2241104, 20.7168355],
                [106.2240772, 20.7170322],
                [106.2240303, 20.7172419],
                [106.2239177, 20.7175177],
                [106.2237873, 20.7177347],
                [106.2235534, 20.7180375],
                [106.2231572, 20.7184854],
                [106.2227638, 20.7188548],
                [106.2223845, 20.7192307],
                [106.2219465, 20.7196723],
                [106.2216296, 20.7200412],
                [106.2211744, 20.7204961],
                [106.22084, 20.7208454],
                [106.2207163, 20.7210361],
                [106.2205587, 20.7213122],
                [106.2202191, 20.7218841],
                [106.2199793, 20.722331],
                [106.2197298, 20.7228761],
                [106.2195251, 20.7233554],
                [106.2193269, 20.7237823],
                [106.2190771, 20.7242739],
                [106.2188023, 20.7246879],
                [106.2185541, 20.7249514],
                [106.2182639, 20.7251759],
                [106.2178765, 20.7254402],
                [106.2174548, 20.7257245],
                [106.2171368, 20.7259687],
                [106.2168925, 20.7261652],
                [106.2163731, 20.726392],
                [106.2156172, 20.7266874],
                [106.2150869, 20.7270368],
                [106.214344, 20.7274659],
                [106.2136366, 20.7279059],
                [106.2130077, 20.7285238],
                [106.2127096, 20.7290334],
                [106.2125297, 20.7294077],
                [106.2123682, 20.7296715],
                [106.2120643, 20.7301868],
                [106.211812, 20.7308634],
                [106.2117668, 20.731137],
                [106.2117801, 20.7313098],
                [106.2118463, 20.7314699],
                [106.2124033, 20.7318518],
                [106.2127489, 20.732106],
                [106.2128931, 20.7322282],
                [106.2131665, 20.7324601],
                [106.213339, 20.7325536],
                [106.2134044, 20.7325921],
                [106.2137814, 20.7326955],
                [106.214143, 20.7327319],
                [106.2144155, 20.7327245],
                [106.2153535, 20.7326119],
                [106.2167408, 20.7323902],
                [106.2173265, 20.7322912],
                [106.2178945, 20.7322035],
                [106.2181135, 20.7321853],
                [106.2182735, 20.7321953],
                [106.2183806, 20.7322391],
                [106.2184913, 20.7323944],
                [106.218528, 20.7325392],
                [106.2186296, 20.7337487],
                [106.218727, 20.7344506],
                [106.2188727, 20.7352638],
                [106.2189474, 20.7360775],
                [106.2189452, 20.7369085],
                [106.2189452, 20.7369086],
                [106.2189372, 20.7377618],
                [106.2188521, 20.738599],
                [106.2187673, 20.7394807],
                [106.2185831, 20.7405193],
                [106.2185029, 20.74086],
                [106.2183575, 20.7415693],
                [106.2182364, 20.7419774],
                [106.2180429, 20.7426033],
                [106.2177103, 20.7436151],
                [106.2174059, 20.7444314],
                [106.2170895, 20.745248],
                [106.2169272, 20.7456785],
                [106.2168526, 20.7459914],
                [106.216848, 20.7461532],
                [106.2168585, 20.7463594],
                [106.2168785, 20.7466492],
                [106.2168993, 20.7470172],
                [106.2168704, 20.7474791],
                [106.2168475, 20.7475852],
                [106.2167719, 20.7477586],
                [106.2166784, 20.7479096],
                [106.2164445, 20.7482958],
                [106.2162982, 20.7485197],
                [106.2161638, 20.7487547],
                [106.2160355, 20.7490078],
                [106.2160484, 20.7492735],
                [106.2160938, 20.7496483],
                [106.2160822, 20.7497959],
                [106.2160706, 20.7499437],
                [106.216013, 20.7501517],
                [106.2158785, 20.7503043],
                [106.2156168, 20.7504498],
                [106.21513, 20.750553],
                [106.2143416, 20.7505745],
                [106.2139942, 20.750617],
                [106.213579, 20.7506597],
                [106.2131728, 20.7507504],
                [106.2127283, 20.7508454],
                [106.2118481, 20.751059],
                [106.2110819, 20.7512121],
                [106.2106925, 20.7513026],
                [106.2102354, 20.7514017],
                [106.2098629, 20.751468],
                [106.2091807, 20.7515327],
                [106.2087061, 20.751568],
                [106.2082822, 20.751587],
                [106.2077908, 20.7516224],
                [106.2071678, 20.7516706],
                [106.2068287, 20.751681],
                [106.2059638, 20.751687],
                [106.2052382, 20.7516163],
                [106.2047971, 20.7515955],
                [106.2044748, 20.7515897],
                [106.2040598, 20.7516485],
                [106.2037215, 20.7517626],
                [106.2035017, 20.751852],
                [106.2032023, 20.7520643],
                [106.2029497, 20.7522815],
                [106.2027391, 20.7524664],
                [106.2023431, 20.7527962],
                [106.2020655, 20.7530854],
                [106.2018131, 20.7533184],
                [106.2011722, 20.7537856],
                [106.2006622, 20.7540971],
                [106.2001469, 20.754348],
                [106.1998177, 20.7545339],
                [106.1994968, 20.7546878],
                [106.199269, 20.7548331],
                [106.199008, 20.7550662],
                [106.1987854, 20.7553352],
                [106.1986262, 20.7555757],
                [106.1984248, 20.7558404],
                [106.1980555, 20.7563137],
                [106.1977069, 20.7567273],
                [106.1974293, 20.7570084],
                [106.1971856, 20.7572894],
                [106.1969088, 20.7576584],
                [106.1966628, 20.7581815],
                [106.1965209, 20.7584696],
                [106.196396, 20.7587576],
                [106.1963218, 20.7590372],
                [106.1962395, 20.7593329],
                [106.1961656, 20.7596603],
                [106.1961341, 20.7599557],
                [106.1960398, 20.7603514],
                [106.1959579, 20.7607189],
                [106.1958421, 20.7610867],
                [106.1956748, 20.7613751],
                [106.1954398, 20.7616799],
                [106.1950871, 20.7620972],
                [106.1948181, 20.7623863],
                [106.1943972, 20.7627563],
                [106.1940347, 20.7630221],
                [106.193699, 20.7634553],
                [106.1935489, 20.7637754],
                [106.1935176, 20.7640949],
                [106.1934701, 20.764526],
                [106.1933874, 20.7647978],
                [106.1932712, 20.7651177],
                [106.1930368, 20.7654863],
                [106.1928273, 20.765799],
                [106.1921489, 20.7657717],
                [106.1910972, 20.7657151],
                [106.1905382, 20.7657829],
                [106.1900397, 20.7659859],
                [106.1895843, 20.7662921],
                [106.1892131, 20.7665181],
                [106.1887736, 20.7666966],
                [106.1883671, 20.7667473],
                [106.1878748, 20.766671],
                [106.1873475, 20.7664512],
                [106.187253, 20.7663912],
                [106.1867511, 20.7660724],
                [106.1861276, 20.7654759],
                [106.1855792, 20.7647302],
                [106.1852692, 20.7641103],
                [106.1848912, 20.7634751],
                [106.1845829, 20.7630626],
                [106.1842419, 20.7628257],
                [106.1837583, 20.7626762],
                [106.1833177, 20.7626794],
                [106.1826406, 20.7628116],
                [106.1819806, 20.7629597],
                [106.1814901, 20.7630907],
                [106.1814811, 20.7630968],
                [106.1810348, 20.763397],
                [106.1803435, 20.7638803],
                [106.1798636, 20.7642904],
                [106.1793928, 20.7647882],
                [106.1789902, 20.7653334],
                [106.1786386, 20.7658942],
                [106.1784216, 20.7663264],
                [106.1780974, 20.7671503],
                [106.177848, 20.7677742],
                [106.1775634, 20.7682388],
                [106.17711, 20.7688002],
                [106.1764127, 20.7695948],
                [106.1759767, 20.7702199],
                [106.1753554, 20.770974],
                [106.1749026, 20.7716152],
                [106.1744247, 20.7722965],
                [106.1742414, 20.7726965],
                [106.1742784, 20.7731111],
                [106.1744338, 20.773461],
                [106.1746391, 20.7737149],
                [106.1748439, 20.773889],
                [106.1752862, 20.7740774],
                [106.1758309, 20.7743608],
                [106.1763084, 20.7747405],
                [106.1765737, 20.7750657],
                [106.1766616, 20.7754639],
                [106.1766472, 20.775799],
                [106.1764974, 20.776151],
                [106.1762457, 20.7765037],
                [106.17586, 20.7770329],
                [106.1752627, 20.7776192],
                [106.1748748, 20.7778771],
                [106.1745362, 20.7779432],
                [106.174265, 20.7779451],
                [106.1739593, 20.7778675],
                [106.1735678, 20.7776787],
                [106.1732608, 20.7774255],
                [106.1729194, 20.7771247],
                [106.1725109, 20.7769201],
                [106.1721876, 20.7767627],
                [106.1718649, 20.7766853],
                [106.1716789, 20.7767503],
                [106.1714693, 20.7770469],
                [106.1710695, 20.7779589],
                [106.1709036, 20.7784387],
                [106.1706878, 20.7790305],
                [106.1704702, 20.7793829],
                [106.1702098, 20.7796957],
                [106.169706, 20.7803214],
                [106.1691684, 20.7809632],
                [106.1688665, 20.78138],
                [106.1686324, 20.7817965],
                [106.1682888, 20.7823173],
                [106.1677511, 20.7829431],
                [106.1670953, 20.7836336],
                [106.1664391, 20.7842921],
                [106.1660014, 20.7846939],
                [106.1655796, 20.784968],
                [106.1651903, 20.7850344],
                [106.1643678, 20.7850161],
                [106.1638073, 20.7848763],
                [106.162788, 20.7846121],
                [106.1620915, 20.7844253],
                [106.1614963, 20.7841901],
                [106.1610703, 20.7839217],
                [106.1607792, 20.7835248],
                [106.1605517, 20.7830351],
                [106.1605464, 20.7830239],
                [106.1603544, 20.7822913],
                [106.1601628, 20.7816225],
                [106.1599393, 20.7812252],
                [106.1596482, 20.7808443],
                [106.1591882, 20.7805443],
                [106.1584652, 20.7802221],
                [106.1577347, 20.7800196],
                [106.1570389, 20.7799286],
                [106.1564628, 20.7799803],
                [106.1558197, 20.7801124],
                [106.1551434, 20.7803561],
                [106.1544759, 20.7806399],
                [106.1536648, 20.7809962],
                [106.1529383, 20.7813202],
                [106.1522297, 20.7817717],
                [106.1517236, 20.7821101],
                [106.150999, 20.7826892],
                [106.1502327, 20.7833565],
                [106.1498445, 20.7836468],
                [106.1497308, 20.7837318],
                [106.1495632, 20.7838571],
                [106.1492718, 20.7840808],
                [106.1486656, 20.7846114],
                [106.1480598, 20.7852217],
                [106.1475887, 20.7857193],
                [106.1472785, 20.7861601],
                [106.1468588, 20.7867054],
                [106.1465579, 20.7872658],
                [106.1463754, 20.7877934],
                [106.1461198, 20.7887578],
                [106.145719, 20.7895737],
                [106.1452659, 20.7901827],
                [106.144694, 20.7907923],
                [106.1434662, 20.792092],
                [106.1429273, 20.7925898],
                [106.1425071, 20.7930708],
                [106.1422393, 20.7935351],
                [106.142098, 20.7938794],
                [106.14205, 20.7942467],
                [106.1420566, 20.7951239],
                [106.142043, 20.7955706],
                [106.1420067, 20.7963762],
                [106.1419929, 20.796807],
                [106.1419637, 20.7974293],
                [106.1418985, 20.7977807],
                [106.141732, 20.7981806],
                [106.1407962, 20.7977241],
                [106.14032, 20.79752],
                [106.1400859, 20.7979522],
                [106.1399536, 20.7983838],
                [106.1397793, 20.7988714],
                [106.1394115, 20.7995597],
                [106.139179, 20.7998689],
                [106.1389415, 20.8001849],
                [106.1385042, 20.8006503],
                [106.1380155, 20.8010364],
                [106.1374422, 20.801439],
                [106.1369521, 20.8018204],
                [106.1368943, 20.8018653],
                [106.1363387, 20.8023635],
                [106.1356479, 20.8029264],
                [106.135143, 20.8034241],
                [106.134613, 20.803962],
                [106.1341586, 20.8044116],
                [106.133687, 20.8048134],
                [106.1332317, 20.8051514],
                [106.1327255, 20.8054579],
                [106.1322361, 20.8057641],
                [106.13173, 20.8061024],
                [106.1313508, 20.8064],
                [106.1311326, 20.8066885],
                [106.1310838, 20.80696],
                [106.1311191, 20.8071513],
                [106.1313075, 20.8074052],
                [106.1316324, 20.8077859],
                [106.1320081, 20.8081343],
                [106.1325026, 20.8085298],
                [106.1331755, 20.8089616],
                [106.1338896, 20.8092437],
                [106.1342634, 20.8093369],
                [106.1346872, 20.8093501],
                [106.1351108, 20.8093153],
                [106.1357884, 20.8092472],
                [106.1363311, 20.8092595],
                [106.13684, 20.809304],
                [106.1372984, 20.8093966],
                [106.1376387, 20.8095696],
                [106.1379969, 20.8098541],
                [106.1382532, 20.8101234],
                [106.1384591, 20.8104568],
                [106.1386484, 20.8108222],
                [106.1388647, 20.8113887],
                [106.1390205, 20.8118343],
                [106.1391929, 20.8122158],
                [106.1393307, 20.812502],
                [106.1395196, 20.8128356],
                [106.1397089, 20.813201],
                [106.1399662, 20.813614],
                [106.140113, 20.8139719],
                [106.1402686, 20.8143854],
                [106.1405473, 20.8153723],
                [106.1406185, 20.8158343],
                [106.1406732, 20.8163443],
                [106.1406679, 20.8167669],
                [106.1406373, 20.8171977],
                [106.1405394, 20.8177086],
                [106.1404243, 20.8181719],
                [106.140292, 20.8186193],
                [106.1401018, 20.8192505],
                [106.1397708, 20.8200023],
                [106.1395199, 20.8204505],
                [106.1391002, 20.8209955],
                [106.1387144, 20.8215403],
                [106.1383701, 20.8219652],
                [106.1374612, 20.8228324],
                [106.1369722, 20.8231864],
                [106.136483, 20.8234927],
                [106.1359092, 20.8238474],
                [106.1354703, 20.8240735],
                [106.1349803, 20.8243],
                [106.1343803, 20.8245352],
                [106.1339752, 20.8247612],
                [106.1335709, 20.8251147],
                [106.1332518, 20.8255155],
                [106.1329833, 20.825868],
                [106.1326981, 20.8262687],
                [106.1324301, 20.826701],
                [106.1321875, 20.8271252],
                [106.1319869, 20.8275093],
                [106.1315508, 20.8281181],
                [106.1311303, 20.8285675],
                [106.1306752, 20.8289213],
                [106.1302029, 20.8292434],
                [106.1296457, 20.8295341],
                [106.1290794, 20.8297507],
                [106.1286731, 20.829817],
                [106.1282662, 20.8298198],
                [106.1277741, 20.8297593],
                [106.1273328, 20.8296824],
                [106.1267215, 20.8295431],
                [106.1261947, 20.8293711],
                [106.1254303, 20.8291592],
                [106.1252743, 20.8293393],
                [106.1249368, 20.8295983],
                [106.1245709, 20.82986],
                [106.1243692, 20.8300845],
                [106.1245424, 20.8305774],
                [106.1246471, 20.8309911],
                [106.1247181, 20.8314209],
                [106.1247208, 20.8317875],
                [106.1247072, 20.8322338],
                [106.1246771, 20.8327441],
                [106.1246799, 20.8331293],
                [106.1247384, 20.8334042],
                [106.124748, 20.8334493],
                [106.1251213, 20.833923],
                [106.1261016, 20.8348504],
                [106.1278373, 20.8365877],
                [106.1284451, 20.8371697],
                [106.1285261, 20.8375904],
                [106.128531, 20.8382494],
                [106.1286676, 20.8422131],
                [106.1287659, 20.8430064],
                [106.129811, 20.84337],
                [106.1296627, 20.843827],
                [106.1290376, 20.8458526],
                [106.1286613, 20.8463038],
                [106.1281172, 20.8467212],
                [106.127421, 20.8473484],
                [106.1262607, 20.8483816],
                [106.1270534, 20.8489686],
                [106.127542, 20.8492399],
                [106.1283237, 20.8496923],
                [106.1292017, 20.8499977],
                [106.129923, 20.8501577],
                [106.1313461, 20.8504961],
                [106.1319511, 20.85073],
                [106.1322447, 20.8509478],
                [106.1326056, 20.8510643],
                [106.1333065, 20.8511147],
                [106.1342599, 20.85109],
                [106.1355048, 20.8510086],
                [106.1363021, 20.8509301],
                [106.1367889, 20.8509818],
                [106.1371349, 20.8517399],
                [106.1374513, 20.8523966],
                [106.137787, 20.8530163],
                [106.1381999, 20.8535808],
                [106.138888, 20.8544911],
                [106.1395163, 20.8552005],
                [106.1403805, 20.8562376],
                [106.1408331, 20.8568751],
                [106.141552, 20.857999],
                [106.1416927, 20.8585838],
                [106.1419139, 20.859516],
                [106.1420426, 20.8601392],
                [106.1420435, 20.8601548],
                [106.1420877, 20.8609621],
                [106.142135, 20.8620777],
                [106.1421043, 20.8631572],
                [106.1421495, 20.865288],
                [106.1421588, 20.8665137],
                [106.1421209, 20.8679316],
                [106.1421277, 20.868828],
                [106.1421712, 20.8694314],
                [106.1423008, 20.8698604],
                [106.1425755, 20.8701696],
                [106.1429863, 20.8704778],
                [106.1432609, 20.8707869],
                [106.1434586, 20.8712063],
                [106.1434812, 20.8716269],
                [106.1433684, 20.8721399],
                [106.1432351, 20.872525],
                [106.1431712, 20.8730833],
                [106.1433104, 20.8734666],
                [106.1435671, 20.873977],
                [106.1437465, 20.874543],
                [106.1437235, 20.8750816],
                [106.1436587, 20.876601],
                [106.1435175, 20.8777279],
                [106.1438525, 20.8784575],
                [106.1450886, 20.8795189],
                [106.1459316, 20.8801044],
                [106.1466562, 20.8808595],
                [106.1470217, 20.8816733],
                [106.1472686, 20.8826289],
                [106.1472481, 20.8838676],
                [106.1472922, 20.8850764],
                [106.1472981, 20.8852879],
                [106.147242, 20.8864739],
                [106.1472824, 20.887511],
                [106.147287, 20.8881037],
                [106.147193, 20.8885785],
                [106.1468065, 20.889085],
                [106.1458385, 20.8900992],
                [106.1450681, 20.8914282],
                [106.1446812, 20.8918853],
                [106.1441293, 20.8920273],
                [106.1435874, 20.8920408],
                [106.1428297, 20.8922039],
                [106.1421163, 20.8924754],
                [106.1408064, 20.8927312],
                [106.1396477, 20.8929167],
                [106.1392369, 20.8930776],
                [106.1388819, 20.8934356],
                [106.1385969, 20.8937917],
                [106.1385242, 20.8943991],
                [106.1384524, 20.8950879],
                [106.1383621, 20.8958855],
                [106.1383301, 20.8967551],
                [106.1383168, 20.8975521],
                [106.1383022, 20.8981681],
                [106.1382293, 20.898712],
                [106.1380981, 20.8992019],
                [106.1378602, 20.8995747],
                [106.1374798, 20.9002113],
                [106.1370412, 20.9008118],
                [106.1369408, 20.9009907],
                [106.136623, 20.9015573],
                [106.1364339, 20.9020295],
                [106.1363125, 20.9025285],
                [106.1361818, 20.9030908],
                [106.1362056, 20.9036883],
                [106.1362853, 20.9040319],
                [106.1365194, 20.9044288],
                [106.1369074, 20.9047885],
                [106.1375169, 20.9051377],
                [106.1384049, 20.9053854],
                [106.1393893, 20.9056505],
                [106.1401233, 20.9058992],
                [106.1408186, 20.9061481],
                [106.1413606, 20.9064886],
                [106.1418933, 20.9068745],
                [106.1422816, 20.9072703],
                [106.1427291, 20.907847],
                [106.1431192, 20.9084783],
                [106.1435061, 20.9090582],
                [106.1435283, 20.9090915],
                [106.1437446, 20.9096696],
                [106.1434116, 20.9102061],
                [106.1428781, 20.9109886],
                [106.1425915, 20.9113533],
                [106.1430175, 20.9116401],
                [106.1438581, 20.9123438],
                [106.1441033, 20.9125382],
                [106.1445881, 20.9129332],
                [106.1454795, 20.9135971],
                [106.1459968, 20.9132135],
                [106.14638, 20.9129211],
                [106.1468847, 20.9134066],
                [106.1475442, 20.9139815],
                [106.1482613, 20.914538],
                [106.1488423, 20.9149143],
                [106.1493647, 20.9152004],
                [106.1499924, 20.9153892],
                [106.1505716, 20.9155303],
                [106.1506301, 20.9155585],
                [106.1510164, 20.9157447],
                [106.1515394, 20.9161036],
                [106.1523324, 20.9164967],
                [106.1531054, 20.9167813],
                [106.1538971, 20.9169934],
                [106.1547748, 20.9171234],
                [106.1553345, 20.9173017],
                [106.1546156, 20.9189722],
                [106.154276, 20.9199166],
                [106.1539662, 20.9209967],
                [106.154874, 20.9212622],
                [106.1546604, 20.9220019],
                [106.1540525, 20.923395],
                [106.1521537, 20.922927],
                [106.1520432, 20.9231269],
                [106.1518772, 20.9237372],
                [106.1516321, 20.9244454],
                [106.1508994, 20.9243961],
                [106.1498778, 20.9243485],
                [106.149579, 20.9243416],
                [106.1493914, 20.9250131],
                [106.1491938, 20.9257946],
                [106.1491482, 20.9259749],
                [106.1488669, 20.9269913],
                [106.1484792, 20.9288676],
                [106.1498151, 20.9304454],
                [106.1500657, 20.9334524],
                [106.1512094, 20.9334431],
                [106.1529676, 20.9334687],
                [106.1526743, 20.9341375],
                [106.1522376, 20.9343772],
                [106.1520465, 20.9345958],
                [106.1516832, 20.9349786],
                [106.1515417, 20.93539],
                [106.1515652, 20.9359334],
                [106.1516287, 20.9366759],
                [106.1517903, 20.9376532],
                [106.1520893, 20.9389378],
                [106.1524768, 20.9404481],
                [106.1525976, 20.9410811],
                [106.1526976, 20.9415695],
                [106.1520036, 20.9415742],
                [106.1511942, 20.9416158],
                [106.1501341, 20.9416592],
                [106.1501019, 20.9424927],
                [106.1502088, 20.9438685],
                [106.1502994, 20.944369],
                [106.1512435, 20.9442902],
                [106.1517831, 20.9442503],
                [106.1519198, 20.9444668],
                [106.1518857, 20.9450652],
                [106.1518907, 20.9456994],
                [106.1527002, 20.9456758],
                [106.1534906, 20.9456705],
                [106.1543776, 20.9456825],
                [106.1545514, 20.9456996],
                [106.1546046, 20.9463607],
                [106.1546308, 20.9472667],
                [106.1555189, 20.9474238],
                [106.156195, 20.9475822],
                [106.1564287, 20.9478888],
                [106.157433, 20.9481177],
                [106.1576904, 20.9489858],
                [106.1578809, 20.9499541],
                [106.1581377, 20.950642],
                [106.1581977, 20.9509314],
                [106.1571057, 20.9518627],
                [106.1565114, 20.9523196],
                [106.1556497, 20.9531226],
                [106.1550368, 20.9536701],
                [106.154385, 20.9541819],
                [106.1538297, 20.9546929],
                [106.1534293, 20.9552934],
                [106.152971, 20.9558944],
                [106.1528288, 20.9562153],
                [106.1507241, 20.955867],
                [106.1506093, 20.9559946],
                [106.1504597, 20.956612],
                [106.1502199, 20.9580275],
                [106.1500366, 20.9592794],
                [106.1499744, 20.9599778],
                [106.1498995, 20.9602684],
                [106.1495524, 20.9602707],
                [106.1486069, 20.9602227],
                [106.1486221, 20.9596787],
                [106.148656, 20.9590622],
                [106.1486528, 20.9586453],
                [106.1485928, 20.9583738],
                [106.1483606, 20.9582665],
                [106.1476478, 20.9583802],
                [106.1470308, 20.9583843],
                [106.1464132, 20.9583341],
                [106.1458151, 20.9583018],
                [106.1457986, 20.9586645],
                [106.1457335, 20.9589822],
                [106.1451748, 20.9590585],
                [106.1441535, 20.9591741],
                [106.1434019, 20.9592516],
                [106.1426891, 20.959347],
                [106.1421506, 20.95955],
                [106.1415552, 20.9598803],
                [106.1411032, 20.9600374],
                [106.1405055, 20.9600595],
                [106.1398879, 20.9600092],
                [106.1388076, 20.9599622],
                [106.1378043, 20.9599145],
                [106.1370131, 20.9598472],
                [106.1364826, 20.9598236],
                [106.135924, 20.9599178],
                [106.1355777, 20.960047],
                [106.1352681, 20.9607873],
                [106.1347367, 20.9619876],
                [106.134168, 20.9633693],
                [106.1335635, 20.9651138],
                [106.1336248, 20.9655725],
                [106.1337957, 20.9659813],
                [106.1338746, 20.9667412],
                [106.1338038, 20.9675941],
                [106.1337716, 20.9684465],
                [106.1336254, 20.9695355],
                [106.1340921, 20.9699859],
                [106.1345585, 20.9703817],
                [106.1350347, 20.9708077],
                [106.1355403, 20.9712944],
                [106.1361824, 20.9719432],
                [106.1366302, 20.9724302],
                [106.1370785, 20.9729896],
                [106.1374105, 20.9734956],
                [106.137663, 20.9740594],
                [106.1379856, 20.974652],
                [106.1373368, 20.975229],
                [106.1372416, 20.9754111],
                [106.1369169, 20.9758668],
                [106.1363614, 20.9764692],
                [106.1354126, 20.9773738],
                [106.1347409, 20.9779226],
                [106.1341069, 20.9783441],
                [106.1336648, 20.9786013],
                [106.1332606, 20.9787672],
                [106.1328751, 20.9788605],
                [106.1324121, 20.978918],
                [106.1320261, 20.9789387],
                [106.1315925, 20.9790414],
                [106.1313814, 20.9792061],
                [106.1310945, 20.9795708],
                [106.1308464, 20.9799717],
                [106.1305605, 20.9804636],
                [106.1302939, 20.9809552],
                [106.1300644, 20.9812471],
                [106.1298144, 20.9813757],
                [106.1295245, 20.9813414],
                [106.1290975, 20.9810539],
                [106.1288375, 20.9809155],
                [106.1273113, 20.9836344],
                [106.1273543, 20.9837454],
                [106.1261725, 20.9840226],
                [106.1248637, 20.9843255],
                [106.123594, 20.984628],
                [106.1221674, 20.9848765],
                [106.1207115, 20.9851344],
                [106.120008, 20.9852678],
                [106.1197887, 20.9847175],
                [106.1195307, 20.9842411],
                [106.1189834, 20.9843182],
                [106.1188233, 20.9838412],
                [106.1180405, 20.983828],
                [106.1179006, 20.9834428],
                [106.1171959, 20.9834106],
                [106.1160509, 20.9833721],
                [106.1152291, 20.9833775],
                [106.1150251, 20.9848868],
                [106.1148808, 20.9865427],
                [106.1148582, 20.9874531],
                [106.1149022, 20.9881148],
                [106.1150228, 20.9885369],
                [106.1153391, 20.9889763],
                [106.1156264, 20.9894437],
                [106.1157455, 20.98988],
                [106.1157149, 20.9898905],
                [106.1157911, 20.9913203],
                [106.1158304, 20.9936537],
                [106.1158073, 20.9960245],
                [106.1132349, 20.996676],
                [106.1127695, 20.9967337],
                [106.1124218, 20.9969821],
                [106.1118438, 20.9975601],
                [106.1103398, 20.9990101],
                [106.1093808, 20.9998743],
                [106.1082429, 20.9990976],
                [106.1075988, 20.9993064],
                [106.1063079, 20.9988116],
                [106.1051702, 20.9975142],
                [106.1043664, 20.9980372],
                [106.1021987, 20.9971409],
                [106.0997252, 20.9999544],
                [106.0992083, 21.0005642],
                [106.0974336, 21.0018569],
                [106.0971791, 21.0010778],
                [106.0958653, 21.0017581],
                [106.095947, 21.0022327],
                [106.0961188, 21.0023237],
                [106.0962114, 21.002539],
                [106.0960928, 21.0025839],
                [106.0961654, 21.0027915],
                [106.0938661, 21.0045526],
                [106.0932649, 21.0053885],
                [106.0922144, 21.0048448],
                [106.090519, 21.006461],
                [106.0895627, 21.0063403],
                [106.0892498, 21.0064317],
                [106.0878283, 21.0043507],
                [106.0856603, 21.0056145],
                [106.0851709, 21.0049173],
                [106.0849132, 21.0050115],
                [106.0848428, 21.0048779],
                [106.0846117, 21.0046626],
                [106.0843848, 21.0048558],
                [106.0839574, 21.0044856],
                [106.0836832, 21.0045516],
                [106.0822156, 20.9999693],
                [106.0827019, 20.9998274],
                [106.0831573, 20.9994912],
                [106.0831185, 20.9993845],
                [106.0831857, 20.9993299],
                [106.083121, 20.9992562],
                [106.0827585, 20.9993596],
                [106.0826402, 20.9991716],
                [106.0820619, 20.9995289],
                [106.0819133, 20.9992171],
                [106.0819945, 20.9991619],
                [106.0816717, 20.9987178],
                [106.0824211, 20.9985153],
                [106.0824186, 20.9983724],
                [106.0813794, 20.9980498],
                [106.081163, 20.9978221],
                [106.0806068, 20.9969152],
                [106.0802196, 20.9962288],
                [106.0790374, 20.9967279],
                [106.0782946, 20.996854],
                [106.077454, 20.9968593],
                [106.0773367, 20.9957221],
                [106.0771065, 20.9957539],
                [106.076975, 20.9954512],
                [106.0759392, 20.9961296],
                [106.0758462, 20.9966763],
                [106.0751737, 20.9967372],
                [106.0749759, 20.9962225],
                [106.0747419, 20.9962649],
                [106.0742319, 20.9964713],
                [106.0736436, 20.9967337],
                [106.0729176, 20.9969969],
                [106.0720542, 20.997298],
                [106.0711128, 20.9976917],
                [106.0703873, 20.9980288],
                [106.0707239, 20.9984342],
                [106.0675533, 21.0005796],
                [106.0670715, 21.0008848],
                [106.0656362, 21.0008027],
                [106.0635342, 21.000573],
                [106.0616594, 21.0004834],
                [106.0585987, 21.0002596],
                [106.0579966, 21.0004253],
                [106.0571176, 21.0010179],
                [106.0569469, 21.0012618],
                [106.0565984, 21.0022357],
                [106.0563648, 21.002723],
                [106.0558303, 21.0032931],
                [106.0547872, 21.0040636],
                [106.0545799, 21.0042836],
                [106.0544991, 21.0044577],
                [106.0544795, 21.0045928],
                [106.0544802, 21.0046988],
                [106.0545218, 21.0047757],
                [106.0546871, 21.0049],
                [106.0549453, 21.0051105],
                [106.0550799, 21.0052639],
                [106.0551941, 21.0054464],
                [106.055236, 21.0055713],
                [106.0552784, 21.0057542],
                [106.0552603, 21.0060918],
                [106.0552147, 21.0068732],
                [106.0528647, 21.0064791],
                [106.0528823, 21.0063301],
                [106.0527938, 21.0059865],
                [106.0527073, 21.0059464],
                [106.0522208, 21.0062082],
                [106.0520209, 21.0062107],
                [106.051142, 21.0069902],
                [106.0489414, 21.0052865],
                [106.0499787, 21.0037784],
                [106.0477004, 21.0033862],
                [106.0470582, 21.0038767],
                [106.0441371, 21.0029486],
                [106.0440952, 21.002678],
                [106.0426794, 21.0017558],
                [106.0422041, 21.0016924],
                [106.0416609, 21.0014269],
                [106.0412807, 21.0023364],
                [106.0396821, 21.0015488],
                [106.039831, 21.0012647],
                [106.0393978, 21.0009437],
                [106.0390325, 21.0010673],
                [106.0379343, 20.9998476],
                [106.0375803, 20.9995218],
                [106.0378461, 20.99859],
                [106.0360072, 20.9980193],
                [106.035199, 20.9977944],
                [106.0351637, 20.9976535],
                [106.0344671, 20.9973509],
                [106.0337951, 20.9971288],
                [106.0330877, 20.9969735],
                [106.0328725, 20.9973736],
                [106.0323774, 20.9971416],
                [106.0322135, 20.9970778],
                [106.0319387, 20.9970486],
                [106.0319244, 20.9969803],
                [106.0322307, 20.996588],
                [106.0319685, 20.996225],
                [106.0318374, 20.9962769],
                [106.0314693, 20.9958017],
                [106.0315698, 20.9957358],
                [106.0313966, 20.9953903],
                [106.030966, 20.9956646],
                [106.0303578, 20.9943089],
                [106.0303127, 20.9940698],
                [106.0300977, 20.9941564],
                [106.0300533, 20.9940335],
                [106.0296136, 20.9940986],
                [106.0294285, 20.9934153],
                [106.0294487, 20.9932608],
                [106.0289608, 20.9931534],
                [106.028906, 20.9929991],
                [106.0287797, 20.993031],
                [106.0279691, 20.9935099],
                [106.0276848, 20.9937788],
                [106.0269868, 20.9930997],
                [106.0245354, 20.9957601],
                [106.0235767, 20.9952313],
                [106.022767, 20.9946456],
                [106.0219079, 20.9954812],
                [106.0212742, 20.9947653],
                [106.0210784, 20.9948772],
                [106.0205707, 20.9953601],
                [106.0209472, 20.9958192],
                [106.0206739, 20.9960793],
                [106.019932, 20.9968034],
                [106.0193657, 20.997342],
                [106.0189565, 20.9978427],
                [106.0187037, 20.9982504],
                [106.0179156, 20.9984552],
                [106.0176378, 20.999283],
                [106.0171671, 21.0001514],
                [106.0169879, 21.0010049],
                [106.0169668, 21.0014198],
                [106.016924, 21.0017226],
                [106.0164386, 21.0019004],
                [106.0157765, 21.0020624],
                [106.0154807, 21.0021123],
                [106.0149392, 21.0020054],
                [106.0140464, 21.0017524],
                [106.0128751, 21.0021182],
                [106.0118348, 21.0014364],
                [106.0109157, 21.000808],
                [106.0113017, 20.9999596],
                [106.0115508, 20.9990357],
                [106.0119398, 20.998273],
                [106.0075371, 20.9982999],
                [106.0044037, 20.9983179],
                [106.0044799, 20.9989427],
                [106.0041238, 20.9990181],
                [106.0042582, 20.9994264],
                [106.00438, 21.0000743],
                [106.0043993, 21.0005856],
                [106.001894, 21.0009934],
                [106.0012074, 21.0001349],
                [106.0001854, 21.0006179],
                [105.999779, 20.9996505],
                [105.9964118, 21.0002746],
                [105.9957414, 21.0004298],
                [105.9941918, 21.0006814],
                [105.9917131, 21.001202],
                [105.9912111, 21.0003848],
                [105.9908366, 20.9998319],
                [105.9899473, 20.9990314],
                [105.9886593, 20.9980881],
                [105.9879197, 20.9976324],
                [105.9879511, 20.997553],
                [105.9874776, 20.9973398],
                [105.9863639, 20.9969746],
                [105.9859904, 20.9968774],
                [105.9851755, 20.9967649],
                [105.9846513, 20.996784],
                [105.9842099, 20.9968278],
                [105.9833614, 20.9967419],
                [105.9827106, 20.9968306],
                [105.981885, 20.9971572],
                [105.9814641, 20.997537],
                [105.980995, 20.9976613],
                [105.9788494, 20.9984133],
                [105.9776119, 20.9992968],
                [105.9762456, 20.9978808],
                [105.9757824, 20.9981405],
                [105.9741604, 20.9967914],
                [105.9735226, 20.9963112],
                [105.9728137, 20.9963885],
                [105.9720409, 20.9960907],
                [105.9718009, 20.9961372],
                [105.971025, 20.995466],
                [105.971782, 20.993927],
                [105.972099, 20.9935311],
                [105.9723556, 20.9924877],
                [105.9723142, 20.9915011],
                [105.972256, 20.9907844],
                [105.9722503, 20.9897198],
                [105.9706026, 20.9892312],
                [105.9688289, 20.9886927],
                [105.9665767, 20.9911464],
                [105.9664349, 20.9910512],
                [105.9656393, 20.990517],
                [105.9650793, 20.9909992],
                [105.964059, 20.9905504],
                [105.9616257, 20.9890839],
                [105.9613422, 20.9881684],
                [105.9625518, 20.9869729],
                [105.9633946, 20.9860082],
                [105.9632254, 20.9857669],
                [105.963975, 20.9844905],
                [105.9652011, 20.985194],
                [105.9651907, 20.984179],
                [105.963366, 20.9830448],
                [105.9635765, 20.9825137],
                [105.9636761, 20.9821179],
                [105.9624669, 20.9822797],
                [105.9620637, 20.9814784],
                [105.9621324, 20.9814201],
                [105.9622857, 20.9812901],
                [105.9630605, 20.9803728],
                [105.9613732, 20.9758723],
                [105.9631433, 20.9747275],
                [105.9624185, 20.9734661],
                [105.964706, 20.9724594],
                [105.9651135, 20.9688962],
                [105.9652195, 20.9679694],
                [105.964483, 20.9679742],
                [105.964473, 20.9673414],
                [105.9644128, 20.9658765],
                [105.9646637, 20.9658465],
                [105.9640828, 20.9633659],
                [105.9627397, 20.9635359],
                [105.9616285, 20.9639756],
                [105.9615296, 20.9639071],
                [105.9602107, 20.9643165],
                [105.9592964, 20.9647943],
                [105.9592892, 20.9659262],
                [105.9567754, 20.9661403],
                [105.9567857, 20.9679596],
                [105.9563669, 20.9680554],
                [105.9561924, 20.9681629],
                [105.9561071, 20.9680167],
                [105.9560381, 20.9678553],
                [105.9559442, 20.9676077],
                [105.9558592, 20.9671461],
                [105.9556579, 20.9671885],
                [105.9554151, 20.9672528],
                [105.955437, 20.9675334],
                [105.9553297, 20.9675629],
                [105.9553426, 20.9676909],
                [105.9551033, 20.9677721],
                [105.955086, 20.9675954],
                [105.9548913, 20.967605],
                [105.9548744, 20.9675727],
                [105.9543013, 20.9677806],
                [105.9539659, 20.9679059],
                [105.9539537, 20.9679686],
                [105.9539801, 20.9680502],
                [105.9538602, 20.9681166],
                [105.9536369, 20.9678308],
                [105.9534623, 20.967558],
                [105.9533189, 20.9674801],
                [105.9532966, 20.9675322],
                [105.9529444, 20.9674564],
                [105.9528757, 20.9678679],
                [105.952382, 20.9678192],
                [105.9523501, 20.9669807],
                [105.9521072, 20.9670083],
                [105.9520654, 20.9665843],
                [105.9518537, 20.9666088],
                [105.9514075, 20.9666943],
                [105.9509224, 20.96675],
                [105.9504059, 20.96675],
                [105.9504241, 20.9671313],
                [105.9500705, 20.9671179],
                [105.9500865, 20.9668064],
                [105.9499234, 20.9665681],
                [105.9496466, 20.966559],
                [105.949634, 20.9669995],
                [105.9487773, 20.9669861],
                [105.9485569, 20.9670352],
                [105.9485799, 20.9672062],
                [105.948737, 20.9671452],
                [105.9488773, 20.967514],
                [105.9486734, 20.967584],
                [105.9486822, 20.9678067],
                [105.9487222, 20.9679331],
                [105.9484397, 20.967985],
                [105.9485013, 20.9683685],
                [105.9480031, 20.9682531],
                [105.9478896, 20.96876],
                [105.9478039, 20.9690925],
                [105.9477717, 20.9692897],
                [105.946267, 20.9689629],
                [105.9442147, 20.9685963],
                [105.9424703, 20.9683646],
                [105.9397709, 20.9679558],
                [105.9396183, 20.9690837],
                [105.9389551, 20.9692312],
                [105.9385079, 20.9701876],
                [105.9375012, 20.9700318],
                [105.936738, 20.9699151],
                [105.936138, 20.9698034],
                [105.9351037, 20.970805],
                [105.9340682, 20.9718112],
                [105.9320111, 20.973802],
                [105.9303172, 20.9724883],
                [105.9290699, 20.9729547],
                [105.9244717, 20.974568],
                [105.92465, 20.9757632],
                [105.9225637, 20.9757279],
                [105.9225641, 20.974762],
                [105.9224798, 20.9746499],
                [105.9197945, 20.9746227],
                [105.9196122, 20.9734053],
                [105.9197802, 20.9719807],
                [105.914037, 20.9723111],
                [105.9141017, 20.9706437],
                [105.9135087, 20.9707635],
                [105.9132098, 20.970738],
                [105.9128792, 20.9707783],
                [105.9127085, 20.9704912],
                [105.9123333, 20.9704553],
                [105.9122216, 20.9693414],
                [105.9110117, 20.9671462],
                [105.9093824, 20.9643447],
                [105.9088833, 20.9633533],
                [105.9083044, 20.9622896],
                [105.9078425, 20.9609357],
                [105.9074141, 20.9585807],
                [105.9073081, 20.9581767],
                [105.906923, 20.9573643],
                [105.9061749, 20.955437],
                [105.9056135, 20.9541026],
                [105.9053794, 20.9536147],
                [105.9056479, 20.9534362],
                [105.9053289, 20.9514501],
                [105.9047654, 20.9500273],
                [105.9040882, 20.9489505],
                [105.9030199, 20.9471529],
                [105.9051263, 20.9457072],
                [105.9067854, 20.9444799],
                [105.9085764, 20.9430936],
                [105.9100767, 20.942083],
                [105.9104235, 20.9411379],
                [105.9108922, 20.9401337],
                [105.9115962, 20.9387601],
                [105.9122376, 20.9383996],
                [105.9135641, 20.9380762],
                [105.9141238, 20.9377244],
                [105.9147906, 20.9373247],
                [105.9145084, 20.936691],
                [105.9157999, 20.9357539],
                [105.9136727, 20.9328615],
                [105.9125773, 20.931107],
                [105.9116392, 20.9297752],
                [105.9084591, 20.9263283],
                [105.9099384, 20.9252878],
                [105.911014, 20.9243066],
                [105.911351, 20.9239991],
                [105.9121911, 20.9228534],
                [105.9126875, 20.9221014],
                [105.9128788, 20.9212777],
                [105.9131084, 20.9200242],
                [105.9131088, 20.9193079],
                [105.913074, 20.9189575],
                [105.912995, 20.9181616],
                [105.9126904, 20.9171944],
                [105.9118518, 20.9157613],
                [105.9104029, 20.9141487],
                [105.9079628, 20.9114968],
                [105.9043782, 20.9085936],
                [105.9028145, 20.9078048],
                [105.9011366, 20.9066935],
                [105.899158, 20.9051853],
                [105.898337, 20.9041457],
                [105.8974528, 20.9030506],
                [105.8966667, 20.9020466],
                [105.8962223, 20.9011325],
                [105.8956778, 20.8997607],
                [105.8954291, 20.8989371],
                [105.8953248, 20.8977921],
            ],
        ],
    },
    {
        type: 'MultiPolygon',
        coordinates: [
            [
                [
                    [114.5524841, 10.101774],
                    [114.5526782, 10.1022248],
                    [114.5532246, 10.1035189],
                    [114.5537618, 10.1048167],
                    [114.5542897, 10.1061181],
                    [114.5548083, 10.1074233],
                    [114.5553176, 10.1087319],
                    [114.5558175, 10.110044],
                    [114.556308, 10.1113596],
                    [114.5567892, 10.1126785],
                    [114.5572609, 10.1140007],
                    [114.5577231, 10.1153262],
                    [114.5581759, 10.1166548],
                    [114.5586192, 10.1179864],
                    [114.5590529, 10.1193212],
                    [114.5594772, 10.1206588],
                    [114.5598918, 10.1219994],
                    [114.5602969, 10.1233428],
                    [114.5606924, 10.124689],
                    [114.5610783, 10.1260378],
                    [114.5614545, 10.1273893],
                    [114.5618211, 10.1287434],
                    [114.5621779, 10.1301],
                    [114.5625251, 10.131459],
                    [114.5628626, 10.1328203],
                    [114.5631904, 10.1341839],
                    [114.5635084, 10.1355498],
                    [114.5638167, 10.1369179],
                    [114.5641151, 10.138288],
                    [114.5644038, 10.1396601],
                    [114.5646827, 10.1410342],
                    [114.5649518, 10.1424102],
                    [114.565211, 10.1437881],
                    [114.5654604, 10.1451676],
                    [114.5656999, 10.1465489],
                    [114.5659296, 10.1479318],
                    [114.5661494, 10.1493162],
                    [114.5663593, 10.1507021],
                    [114.5665593, 10.1520894],
                    [114.5667494, 10.1534781],
                    [114.5669296, 10.154868],
                    [114.5670998, 10.1562592],
                    [114.5672601, 10.1576514],
                    [114.5674105, 10.1590448],
                    [114.5675509, 10.1604391],
                    [114.5676813, 10.1618344],
                    [114.5678018, 10.1632306],
                    [114.5679123, 10.1646275],
                    [114.5680129, 10.1660252],
                    [114.5681034, 10.1674235],
                    [114.568184, 10.1688224],
                    [114.5682545, 10.1702218],
                    [114.5683151, 10.1716216],
                    [114.5683657, 10.1730219],
                    [114.5684063, 10.1744224],
                    [114.5684369, 10.1758232],
                    [114.5684575, 10.1772242],
                    [114.568468, 10.1786252],
                    [114.5684686, 10.1800263],
                    [114.5684592, 10.1814274],
                    [114.5684397, 10.1828283],
                    [114.5684103, 10.1842291],
                    [114.5683709, 10.1856297],
                    [114.5683214, 10.1870299],
                    [114.568262, 10.1884297],
                    [114.5681926, 10.1898291],
                    [114.5681131, 10.191228],
                    [114.5680237, 10.1926262],
                    [114.5679243, 10.1940238],
                    [114.5678377, 10.1951302],
                    [114.5680072, 10.1952099],
                    [114.5692872, 10.195823],
                    [114.5705628, 10.1964449],
                    [114.5718339, 10.1970756],
                    [114.5731004, 10.1977151],
                    [114.5743624, 10.1983634],
                    [114.5756197, 10.1990203],
                    [114.5768723, 10.1996859],
                    [114.5781201, 10.2003602],
                    [114.579363, 10.2010431],
                    [114.5806011, 10.2017346],
                    [114.5818341, 10.2024346],
                    [114.5830622, 10.2031431],
                    [114.5842851, 10.2038601],
                    [114.585503, 10.2045855],
                    [114.5867155, 10.2053194],
                    [114.5879229, 10.2060616],
                    [114.5891249, 10.2068121],
                    [114.5903215, 10.2075709],
                    [114.5915126, 10.208338],
                    [114.5926983, 10.2091133],
                    [114.5938784, 10.2098968],
                    [114.5950528, 10.2106884],
                    [114.5958382, 10.2112259],
                    [114.6861371, 10.5840865],
                    [114.5340593, 10.5477463],
                    [114.4486999, 10.5478291],
                    [114.4470356, 10.5269494],
                    [114.4342908, 10.3670054],
                    [114.428539, 10.2947949],
                    [114.3465047, 10.3267657],
                    [114.2857874, 10.3504266],
                    [114.309055, 10.1852084],
                    [114.327639, 10.0531851],
                    [114.1556718, 9.9873735],
                    [114.1554007, 9.9869247],
                    [114.154683, 9.9857177],
                    [114.1539739, 9.9845056],
                    [114.1532734, 9.9832888],
                    [114.1525817, 9.9820671],
                    [114.1518986, 9.9808406],
                    [114.1512243, 9.9796095],
                    [114.1505588, 9.9783737],
                    [114.149902, 9.9771334],
                    [114.1492542, 9.9758885],
                    [114.1486152, 9.9746393],
                    [114.1479851, 9.9733856],
                    [114.147364, 9.9721276],
                    [114.1467518, 9.9708653],
                    [114.1461487, 9.9695988],
                    [114.1455546, 9.9683282],
                    [114.1449695, 9.9670535],
                    [114.1443935, 9.9657748],
                    [114.1438267, 9.9644922],
                    [114.143269, 9.9632056],
                    [114.1427204, 9.9619152],
                    [114.1421811, 9.9606211],
                    [114.141651, 9.9593233],
                    [114.1411301, 9.9580218],
                    [114.1406185, 9.9567168],
                    [114.1401163, 9.9554082],
                    [114.1396233, 9.9540962],
                    [114.1391397, 9.9527809],
                    [114.1386655, 9.9514622],
                    [114.1382007, 9.9501403],
                    [114.1377453, 9.9488152],
                    [114.1372994, 9.947487],
                    [114.1368629, 9.9461557],
                    [114.1364359, 9.9448214],
                    [114.1360184, 9.9434842],
                    [114.1356105, 9.9421442],
                    [114.1352121, 9.9408013],
                    [114.1348233, 9.9394558],
                    [114.134444, 9.9381076],
                    [114.1340744, 9.9367568],
                    [114.1337144, 9.9354034],
                    [114.133364, 9.9340476],
                    [114.1330591, 9.932832],
                    [114.1327545, 9.9326047],
                    [114.1316284, 9.9317521],
                    [114.1305085, 9.9308917],
                    [114.1293946, 9.9300236],
                    [114.128287, 9.9291478],
                    [114.1271857, 9.9282644],
                    [114.1260906, 9.9273734],
                    [114.125002, 9.9264748],
                    [114.1239197, 9.9255688],
                    [114.122844, 9.9246553],
                    [114.1217747, 9.9237344],
                    [114.1207121, 9.9228061],
                    [114.119656, 9.9218705],
                    [114.1186067, 9.9209276],
                    [114.1175641, 9.9199775],
                    [114.1165283, 9.9190202],
                    [114.1154993, 9.9180558],
                    [114.1144773, 9.9170843],
                    [114.1134621, 9.9161057],
                    [114.112454, 9.9151202],
                    [114.1114529, 9.9141277],
                    [114.1104589, 9.9131283],
                    [114.109472, 9.9121221],
                    [114.1084923, 9.9111091],
                    [114.1075199, 9.9100893],
                    [114.1065547, 9.9090629],
                    [114.1055969, 9.9080298],
                    [114.1046464, 9.9069901],
                    [114.1037034, 9.9059438],
                    [114.1027678, 9.9048911],
                    [114.1018397, 9.9038319],
                    [114.1009193, 9.9027664],
                    [114.1000064, 9.9016945],
                    [114.0991012, 9.9006163],
                    [114.0982036, 9.8995319],
                    [114.0973138, 9.8984413],
                    [114.0964318, 9.8973446],
                    [114.0955577, 9.8962418],
                    [114.0946914, 9.895133],
                    [114.093833, 9.8940183],
                    [114.0929826, 9.8928976],
                    [114.0921401, 9.8917711],
                    [114.0913057, 9.8906388],
                    [114.0904794, 9.8895007],
                    [114.0896612, 9.888357],
                    [114.0888512, 9.8872076],
                    [114.0880494, 9.8860527],
                    [114.0872558, 9.8848922],
                    [114.0864705, 9.8837263],
                    [114.0856935, 9.882555],
                    [114.0849248, 9.8813784],
                    [114.0841646, 9.8801964],
                    [114.0834128, 9.8790093],
                    [114.0826695, 9.8778169],
                    [114.0819346, 9.8766195],
                    [114.0812083, 9.875417],
                    [114.0804906, 9.8742095],
                    [114.0797815, 9.8729971],
                    [114.0790811, 9.8717798],
                    [114.0783893, 9.8705577],
                    [114.0777062, 9.8693308],
                    [114.0770319, 9.8680993],
                    [114.0763664, 9.8668631],
                    [114.0757097, 9.8656223],
                    [114.0750618, 9.8643771],
                    [114.0744228, 9.8631274],
                    [114.0737928, 9.8618732],
                    [114.0731716, 9.8606148],
                    [114.0725595, 9.8593521],
                    [114.0719563, 9.8580852],
                    [114.0713622, 9.8568142],
                    [114.0707771, 9.855539],
                    [114.0702012, 9.8542599],
                    [114.0696343, 9.8529768],
                    [114.0690766, 9.8516898],
                    [114.0685281, 9.850399],
                    [114.0679887, 9.8491044],
                    [114.0674586, 9.8478062],
                    [114.0669378, 9.8465043],
                    [114.0664262, 9.8451988],
                    [114.0659239, 9.8438898],
                    [114.065431, 9.8425774],
                    [114.0649474, 9.8412616],
                    [114.0644732, 9.8399424],
                    [114.0640084, 9.8386201],
                    [114.063553, 9.8372945],
                    [114.063107, 9.8359658],
                    [114.0626705, 9.8346341],
                    [114.0622436, 9.8332994],
                    [114.0618261, 9.8319617],
                    [114.0614181, 9.8306212],
                    [114.0610197, 9.8292779],
                    [114.0606309, 9.8279319],
                    [114.0602517, 9.8265833],
                    [114.059882, 9.825232],
                    [114.059522, 9.8238782],
                    [114.0591717, 9.8225219],
                    [114.058831, 9.8211633],
                    [114.0584999, 9.8198023],
                    [114.0581786, 9.8184391],
                    [114.057867, 9.8170737],
                    [114.0575651, 9.8157061],
                    [114.0572729, 9.8143365],
                    [114.0569905, 9.8129649],
                    [114.0567179, 9.8115914],
                    [114.056455, 9.810216],
                    [114.056202, 9.8088389],
                    [114.0559587, 9.80746],
                    [114.0557253, 9.8060794],
                    [114.0555017, 9.8046973],
                    [114.0552879, 9.8033137],
                    [114.055084, 9.8019286],
                    [114.05489, 9.8005421],
                    [114.0547058, 9.7991543],
                    [114.0545315, 9.7977653],
                    [114.0543671, 9.7963751],
                    [114.0542126, 9.7949838],
                    [114.054068, 9.7935914],
                    [114.0539333, 9.7921981],
                    [114.0538085, 9.7908039],
                    [114.0536937, 9.7894088],
                    [114.0535887, 9.788013],
                    [114.0534937, 9.7866165],
                    [114.0534087, 9.7852194],
                    [114.0533336, 9.7838217],
                    [114.0532684, 9.7824235],
                    [114.0532132, 9.7810248],
                    [114.053168, 9.7796259],
                    [114.0531327, 9.7782266],
                    [114.0531074, 9.7768271],
                    [114.053092, 9.7754275],
                    [114.0530866, 9.7740278],
                    [114.0530911, 9.7726281],
                    [114.0531056, 9.7712285],
                    [114.0531301, 9.769829],
                    [114.0531645, 9.7684297],
                    [114.0532089, 9.7670306],
                    [114.0532632, 9.7656319],
                    [114.0533275, 9.7642336],
                    [114.0534017, 9.7628357],
                    [114.0534859, 9.7614384],
                    [114.05358, 9.7600417],
                    [114.053684, 9.7586457],
                    [114.053798, 9.7572505],
                    [114.0539219, 9.755856],
                    [114.0540558, 9.7544625],
                    [114.0541995, 9.7530699],
                    [114.0543531, 9.7516783],
                    [114.0545167, 9.7502878],
                    [114.0546901, 9.7488985],
                    [114.0548734, 9.7475103],
                    [114.0550666, 9.7461235],
                    [114.0552696, 9.7447381],
                    [114.0554825, 9.7433541],
                    [114.0557053, 9.7419715],
                    [114.0559379, 9.7405906],
                    [114.0561802, 9.7392113],
                    [114.0564324, 9.7378337],
                    [114.0566944, 9.7364578],
                    [114.0569662, 9.7350838],
                    [114.0572478, 9.7337117],
                    [114.0575391, 9.7323416],
                    [114.0578401, 9.7309735],
                    [114.0581509, 9.7296076],
                    [114.0584713, 9.7282438],
                    [114.0588015, 9.7268822],
                    [114.0591414, 9.725523],
                    [114.0594909, 9.7241661],
                    [114.0598501, 9.7228117],
                    [114.0602188, 9.7214598],
                    [114.0605972, 9.7201104],
                    [114.0609852, 9.7187637],
                    [114.0613828, 9.7174198],
                    [114.0617899, 9.7160785],
                    [114.0622065, 9.7147402],
                    [114.0626327, 9.7134047],
                    [114.0630683, 9.7120722],
                    [114.0635135, 9.7107428],
                    [114.063968, 9.7094164],
                    [114.064432, 9.7080933],
                    [114.0649054, 9.7067733],
                    [114.0653882, 9.7054567],
                    [114.0658803, 9.7041434],
                    [114.0663817, 9.7028336],
                    [114.0668925, 9.7015272],
                    [114.0674125, 9.7002244],
                    [114.0679418, 9.6989253],
                    [114.0684804, 9.6976298],
                    [114.0690281, 9.696338],
                    [114.069585, 9.6950501],
                    [114.070151, 9.693766],
                    [114.0707262, 9.6924859],
                    [114.0713105, 9.6912098],
                    [114.0719038, 9.6899378],
                    [114.0725062, 9.6886699],
                    [114.0731176, 9.6874061],
                    [114.0737379, 9.6861467],
                    [114.0743672, 9.6848915],
                    [114.0750054, 9.6836407],
                    [114.0756525, 9.6823944],
                    [114.0763084, 9.6811525],
                    [114.0769732, 9.6799153],
                    [114.0776467, 9.6786826],
                    [114.078329, 9.6774546],
                    [114.07902, 9.6762314],
                    [114.0797197, 9.6750129],
                    [114.080428, 9.6737993],
                    [114.081145, 9.6725907],
                    [114.0818705, 9.671387],
                    [114.0826046, 9.6701884],
                    [114.0833472, 9.6689949],
                    [114.0840983, 9.6678065],
                    [114.0848578, 9.6666233],
                    [114.0856257, 9.6654454],
                    [114.086402, 9.6642729],
                    [114.0871865, 9.6631057],
                    [114.0879794, 9.661944],
                    [114.0887805, 9.6607878],
                    [114.0895898, 9.6596372],
                    [114.0904073, 9.6584922],
                    [114.0912329, 9.6573528],
                    [114.0920666, 9.6562192],
                    [114.0929083, 9.6550914],
                    [114.093758, 9.6539694],
                    [114.0946157, 9.6528533],
                    [114.0949161, 9.6524681],
                    [114.3201492, 9.7798576],
                    [114.4500656, 9.5960865],
                    [114.4513007, 9.5966157],
                    [114.4525993, 9.5971828],
                    [114.4538939, 9.5977589],
                    [114.4551844, 9.5983439],
                    [114.4564706, 9.5989378],
                    [114.4577526, 9.5995406],
                    [114.4590303, 9.6001523],
                    [114.4603036, 9.6007728],
                    [114.4615725, 9.601402],
                    [114.4628368, 9.6020401],
                    [114.4640966, 9.6026868],
                    [114.4653518, 9.6033422],
                    [114.4666023, 9.6040063],
                    [114.467848, 9.6046791],
                    [114.4690889, 9.6053604],
                    [114.4703249, 9.6060503],
                    [114.471556, 9.6067487],
                    [114.4727821, 9.6074556],
                    [114.4740031, 9.608171],
                    [114.475219, 9.6088947],
                    [114.4764297, 9.6096269],
                    [114.4776352, 9.6103674],
                    [114.4788354, 9.6111163],
                    [114.4800303, 9.6118734],
                    [114.4812197, 9.6126388],
                    [114.4824037, 9.6134123],
                    [114.4835821, 9.6141941],
                    [114.4847549, 9.6149839],
                    [114.4859221, 9.6157819],
                    [114.4870836, 9.6165878],
                    [114.4882393, 9.6174018],
                    [114.4893892, 9.6182238],
                    [114.4905333, 9.6190537],
                    [114.4916714, 9.6198915],
                    [114.4928035, 9.6207371],
                    [114.4939296, 9.6215906],
                    [114.4950495, 9.6224517],
                    [114.4961634, 9.6233207],
                    [114.497271, 9.6241973],
                    [114.4983723, 9.6250815],
                    [114.4994674, 9.6259733],
                    [114.500556, 9.6268727],
                    [114.5016383, 9.6277795],
                    [114.502714, 9.6286938],
                    [114.5037833, 9.6296156],
                    [114.5048459, 9.6305447],
                    [114.505902, 9.6314811],
                    [114.5069513, 9.6324248],
                    [114.5079939, 9.6333757],
                    [114.5090297, 9.6343338],
                    [114.5100587, 9.6352991],
                    [114.5110807, 9.6362714],
                    [114.5120959, 9.6372508],
                    [114.513104, 9.6382372],
                    [114.5141051, 9.6392305],
                    [114.5150991, 9.6402307],
                    [114.516086, 9.6412377],
                    [114.5170657, 9.6422515],
                    [114.5180381, 9.6432721],
                    [114.5190033, 9.6442994],
                    [114.5199612, 9.6453333],
                    [114.5209116, 9.6463738],
                    [114.5218546, 9.6474209],
                    [114.5227902, 9.6484744],
                    [114.5237183, 9.6495344],
                    [114.5246387, 9.6506008],
                    [114.5255516, 9.6516735],
                    [114.5264568, 9.6527525],
                    [114.5273544, 9.6538377],
                    [114.5282442, 9.6549291],
                    [114.5291262, 9.6560266],
                    [114.5300003, 9.6571302],
                    [114.5308666, 9.6582398],
                    [114.531725, 9.6593553],
                    [114.5325755, 9.6604768],
                    [114.5334179, 9.6616041],
                    [114.5342523, 9.6627372],
                    [114.5350786, 9.663876],
                    [114.5358968, 9.6650205],
                    [114.5367068, 9.6661706],
                    [114.5375086, 9.6673263],
                    [114.5383022, 9.6684875],
                    [114.5390875, 9.6696542],
                    [114.5398645, 9.6708262],
                    [114.5406332, 9.6720036],
                    [114.5413934, 9.6731863],
                    [114.5421452, 9.6743742],
                    [114.5428885, 9.6755673],
                    [114.5436234, 9.6767654],
                    [114.5443497, 9.6779687],
                    [114.5450674, 9.6791769],
                    [114.5457765, 9.68039],
                    [114.5464769, 9.681608],
                    [114.5471687, 9.6828308],
                    [114.5478518, 9.6840583],
                    [114.5485261, 9.6852906],
                    [114.5491916, 9.6865274],
                    [114.5498483, 9.6877689],
                    [114.5504962, 9.6890148],
                    [114.5511352, 9.6902652],
                    [114.5517652, 9.6915199],
                    [114.5523864, 9.692779],
                    [114.5529985, 9.6940423],
                    [114.5536017, 9.6953099],
                    [114.5541958, 9.6965815],
                    [114.5547809, 9.6978573],
                    [114.5553569, 9.699137],
                    [114.5559237, 9.7004207],
                    [114.5564814, 9.7017082],
                    [114.5570299, 9.7029996],
                    [114.5570927, 9.7031504],
                    [114.5581741, 9.7031164],
                    [114.5595939, 9.7030816],
                    [114.561014, 9.7030567],
                    [114.5624343, 9.7030415],
                    [114.5638546, 9.7030362],
                    [114.5652749, 9.7030406],
                    [114.5666952, 9.7030549],
                    [114.5681153, 9.703079],
                    [114.5695352, 9.703113],
                    [114.5709548, 9.7031567],
                    [114.5723741, 9.7032103],
                    [114.573793, 9.7032736],
                    [114.5752114, 9.7033468],
                    [114.5766292, 9.7034298],
                    [114.5780464, 9.7035225],
                    [114.5794629, 9.7036251],
                    [114.5808786, 9.7037374],
                    [114.5822935, 9.7038596],
                    [114.5837075, 9.7039915],
                    [114.5851206, 9.7041332],
                    [114.5865326, 9.7042846],
                    [114.5879434, 9.7044458],
                    [114.5893531, 9.7046168],
                    [114.5907616, 9.7047974],
                    [114.5921687, 9.7049879],
                    [114.5935744, 9.705188],
                    [114.5949787, 9.7053978],
                    [114.5963815, 9.7056174],
                    [114.5977826, 9.7058466],
                    [114.5991821, 9.7060856],
                    [114.6005799, 9.7063342],
                    [114.6019758, 9.7065924],
                    [114.6033699, 9.7068603],
                    [114.604762, 9.7071378],
                    [114.6061522, 9.7074249],
                    [114.6075402, 9.7077217],
                    [114.6089261, 9.708028],
                    [114.6103098, 9.7083439],
                    [114.6116912, 9.7086693],
                    [114.6130703, 9.7090043],
                    [114.614447, 9.7093488],
                    [114.6158211, 9.7097028],
                    [114.6171927, 9.7100663],
                    [114.6185617, 9.7104393],
                    [114.619928, 9.7108217],
                    [114.6212916, 9.7112136],
                    [114.6226523, 9.7116149],
                    [114.6240101, 9.7120255],
                    [114.625365, 9.7124456],
                    [114.6267169, 9.712875],
                    [114.6280657, 9.7133137],
                    [114.6294113, 9.7137618],
                    [114.6307537, 9.7142191],
                    [114.6320928, 9.7146857],
                    [114.6334286, 9.7151616],
                    [114.6347609, 9.7156466],
                    [114.6360898, 9.7161409],
                    [114.6374151, 9.7166443],
                    [114.6387368, 9.7171569],
                    [114.6400548, 9.7176786],
                    [114.641369, 9.7182094],
                    [114.6426795, 9.7187493],
                    [114.6439861, 9.7192982],
                    [114.6452887, 9.7198561],
                    [114.6465874, 9.720423],
                    [114.647882, 9.7209989],
                    [114.6491724, 9.7215837],
                    [114.6504587, 9.7221774],
                    [114.6517407, 9.72278],
                    [114.6530184, 9.7233914],
                    [114.6542917, 9.7240117],
                    [114.6555606, 9.7246407],
                    [114.6568249, 9.7252785],
                    [114.6580847, 9.725925],
                    [114.6593399, 9.7265802],
                    [114.6605903, 9.7272441],
                    [114.6618361, 9.7279166],
                    [114.663077, 9.7285976],
                    [114.664313, 9.7292873],
                    [114.6655441, 9.7299854],
                    [114.6667701, 9.7306921],
                    [114.6679912, 9.7314072],
                    [114.6692071, 9.7321307],
                    [114.6704178, 9.7328626],
                    [114.6716233, 9.7336029],
                    [114.6728235, 9.7343514],
                    [114.6740184, 9.7351083],
                    [114.6752078, 9.7358733],
                    [114.6763917, 9.7366466],
                    [114.6775702, 9.7374281],
                    [114.678743, 9.7382176],
                    [114.6799102, 9.7390153],
                    [114.6810717, 9.739821],
                    [114.6822274, 9.7406347],
                    [114.6833773, 9.7414563],
                    [114.6845214, 9.7422859],
                    [114.6856595, 9.7431234],
                    [114.6867916, 9.7439687],
                    [114.6879176, 9.7448218],
                    [114.6890376, 9.7456827],
                    [114.6901514, 9.7465513],
                    [114.6912591, 9.7474276],
                    [114.6923604, 9.7483115],
                    [114.6934555, 9.749203],
                    [114.6945441, 9.750102],
                    [114.6956264, 9.7510085],
                    [114.6967021, 9.7519225],
                    [114.6977714, 9.7528439],
                    [114.698834, 9.7537727],
                    [114.69989, 9.7547088],
                    [114.7009394, 9.7556521],
                    [114.701982, 9.7566027],
                    [114.7030178, 9.7575605],
                    [114.7040467, 9.7585253],
                    [114.7050688, 9.7594973],
                    [114.706084, 9.7604763],
                    [114.7070921, 9.7614623],
                    [114.7080932, 9.7624553],
                    [114.7090872, 9.7634551],
                    [114.7100741, 9.7644618],
                    [114.7110538, 9.7654752],
                    [114.7120262, 9.7664954],
                    [114.7129914, 9.7675223],
                    [114.7139492, 9.7685559],
                    [114.7148997, 9.769596],
                    [114.7158427, 9.7706427],
                    [114.7167783, 9.7716958],
                    [114.7177063, 9.7727554],
                    [114.7186268, 9.7738214],
                    [114.7195397, 9.7748937],
                    [114.7204449, 9.7759723],
                    [114.7213425, 9.7770571],
                    [114.7222323, 9.7781481],
                    [114.7231143, 9.7792452],
                    [114.7239884, 9.7803484],
                    [114.7248547, 9.7814576],
                    [114.7257131, 9.7825727],
                    [114.7265635, 9.7836938],
                    [114.727406, 9.7848206],
                    [114.7282404, 9.7859533],
                    [114.7290667, 9.7870917],
                    [114.7298849, 9.7882358],
                    [114.7306949, 9.7893855],
                    [114.7314967, 9.7905408],
                    [114.7322903, 9.7917016],
                    [114.7330756, 9.7928678],
                    [114.7338526, 9.7940394],
                    [114.7346213, 9.7952164],
                    [114.7353815, 9.7963986],
                    [114.7361333, 9.7975861],
                    [114.7368766, 9.7987787],
                    [114.7376115, 9.7999764],
                    [114.7383378, 9.8011792],
                    [114.7390555, 9.802387],
                    [114.7397646, 9.8035996],
                    [114.740465, 9.8048172],
                    [114.7411568, 9.8060395],
                    [114.7418399, 9.8072666],
                    [114.7425142, 9.8084984],
                    [114.7431797, 9.8097348],
                    [114.7438364, 9.8109758],
                    [114.7444843, 9.8122213],
                    [114.7451233, 9.8134712],
                    [114.7457533, 9.8147255],
                    [114.7463745, 9.8159841],
                    [114.7469866, 9.8172469],
                    [114.7475898, 9.818514],
                    [114.7481839, 9.8197852],
                    [114.748769, 9.8210605],
                    [114.7493449, 9.8223397],
                    [114.7499118, 9.8236229],
                    [114.7504695, 9.82491],
                    [114.751018, 9.8262009],
                    [114.7515574, 9.8274956],
                    [114.7520875, 9.8287939],
                    [114.7526083, 9.8300959],
                    [114.7531199, 9.8314015],
                    [114.7536222, 9.8327105],
                    [114.7541151, 9.834023],
                    [114.7545987, 9.8353388],
                    [114.7550729, 9.8366579],
                    [114.7555377, 9.8379803],
                    [114.7559931, 9.8393059],
                    [114.7564391, 9.8406345],
                    [114.7568756, 9.8419662],
                    [114.7573025, 9.8433009],
                    [114.75772, 9.8446385],
                    [114.758128, 9.845979],
                    [114.7585264, 9.8473222],
                    [114.7589152, 9.8486681],
                    [114.7592944, 9.8500167],
                    [114.7596641, 9.8513679],
                    [114.7600241, 9.8527216],
                    [114.7603744, 9.8540777],
                    [114.7607151, 9.8554362],
                    [114.7610462, 9.856797],
                    [114.7613675, 9.8581601],
                    [114.7616791, 9.8595253],
                    [114.761981, 9.8608927],
                    [114.7622732, 9.8622621],
                    [114.7625556, 9.8636335],
                    [114.7628282, 9.8650068],
                    [114.7630911, 9.866382],
                    [114.7633441, 9.8677589],
                    [114.7635874, 9.8691375],
                    [114.7638208, 9.8705178],
                    [114.7640444, 9.8718997],
                    [114.7642582, 9.873283],
                    [114.7644621, 9.8746678],
                    [114.7646561, 9.876054],
                    [114.7648403, 9.8774414],
                    [114.7650146, 9.8788301],
                    [114.765179, 9.88022],
                    [114.7653335, 9.8816109],
                    [114.7654781, 9.8830029],
                    [114.7656128, 9.8843958],
                    [114.7657376, 9.8857897],
                    [114.7658524, 9.8871843],
                    [114.7659574, 9.8885797],
                    [114.7660523, 9.8899758],
                    [114.7661374, 9.8913725],
                    [114.7662125, 9.8927697],
                    [114.7662776, 9.8941675],
                    [114.7663329, 9.8955656],
                    [114.7663781, 9.8969641],
                    [114.7664134, 9.8983628],
                    [114.7664387, 9.8997618],
                    [114.7664541, 9.9011609],
                    [114.7664595, 9.90256],
                    [114.766455, 9.9039592],
                    [114.7664405, 9.9053583],
                    [114.766416, 9.9067572],
                    [114.7663816, 9.908156],
                    [114.7663372, 9.9095544],
                    [114.7662829, 9.9109525],
                    [114.7662186, 9.9123502],
                    [114.7661444, 9.9137474],
                    [114.7660602, 9.9151441],
                    [114.7659661, 9.9165401],
                    [114.765862, 9.9179354],
                    [114.7657481, 9.91933],
                    [114.7656242, 9.9207238],
                    [114.7654903, 9.9221166],
                    [114.7653466, 9.9235085],
                    [114.765193, 9.9248994],
                    [114.7650294, 9.9262892],
                    [114.764856, 9.9276777],
                    [114.7646727, 9.9290651],
                    [114.7644795, 9.9304511],
                    [114.7642765, 9.9318358],
                    [114.7640636, 9.933219],
                    [114.7638408, 9.9346008],
                    [114.7636082, 9.9359809],
                    [114.7633659, 9.9373594],
                    [114.7631137, 9.9387362],
                    [114.7628517, 9.9401112],
                    [114.7625799, 9.9414843],
                    [114.7622983, 9.9428556],
                    [114.762007, 9.9442248],
                    [114.761706, 9.945592],
                    [114.7613952, 9.9469571],
                    [114.7610747, 9.94832],
                    [114.7607446, 9.9496806],
                    [114.7604047, 9.9510389],
                    [114.7600552, 9.9523949],
                    [114.759696, 9.9537484],
                    [114.7593273, 9.9550993],
                    [114.7589489, 9.9564477],
                    [114.7585609, 9.9577934],
                    [114.7581633, 9.9591364],
                    [114.7577562, 9.9604766],
                    [114.7573396, 9.961814],
                    [114.7569134, 9.9631485],
                    [114.7564778, 9.9644799],
                    [114.7560326, 9.9658084],
                    [114.7555781, 9.9671337],
                    [114.7551141, 9.9684558],
                    [114.7546407, 9.9697747],
                    [114.7541579, 9.9710903],
                    [114.7536658, 9.9724025],
                    [114.7531644, 9.9737113],
                    [114.7526536, 9.9750166],
                    [114.7521336, 9.9763183],
                    [114.7516043, 9.9776164],
                    [114.7510657, 9.9789108],
                    [114.750518, 9.9802014],
                    [114.7499611, 9.9814882],
                    [114.749395, 9.9827711],
                    [114.7488199, 9.9840501],
                    [114.7482356, 9.9853251],
                    [114.7476423, 9.986596],
                    [114.7470399, 9.9878628],
                    [114.7464285, 9.9891254],
                    [114.7458082, 9.9903837],
                    [114.7451789, 9.9916377],
                    [114.7445407, 9.9928873],
                    [114.7438936, 9.9941325],
                    [114.7432377, 9.9953731],
                    [114.7425729, 9.9966092],
                    [114.7418994, 9.9978407],
                    [114.7412171, 9.9990675],
                    [114.7405261, 10.0002896],
                    [114.7398264, 10.0015068],
                    [114.739118, 10.0027192],
                    [114.7384011, 10.0039266],
                    [114.7376755, 10.0051291],
                    [114.7369415, 10.0063265],
                    [114.7361989, 10.0075188],
                    [114.7354478, 10.008706],
                    [114.7346883, 10.0098879],
                    [114.7339204, 10.0110645],
                    [114.7331441, 10.0122359],
                    [114.7323596, 10.0134018],
                    [114.7315667, 10.0145623],
                    [114.7307656, 10.0157172],
                    [114.7299563, 10.0168666],
                    [114.7291388, 10.0180104],
                    [114.7283132, 10.0191485],
                    [114.7274795, 10.0202809],
                    [114.7266378, 10.0214075],
                    [114.7257881, 10.0225282],
                    [114.7249304, 10.023643],
                    [114.7240648, 10.0247519],
                    [114.7231913, 10.0258548],
                    [114.72231, 10.0269516],
                    [114.7214209, 10.0280423],
                    [114.720524, 10.0291268],
                    [114.7196195, 10.0302051],
                    [114.7187072, 10.0312772],
                    [114.7177874, 10.0323429],
                    [114.71686, 10.0334022],
                    [114.7159251, 10.0344551],
                    [114.7149828, 10.0355015],
                    [114.7140329, 10.0365413],
                    [114.7130757, 10.0375746],
                    [114.7121112, 10.0386012],
                    [114.7111394, 10.0396212],
                    [114.7101604, 10.0406344],
                    [114.7091741, 10.0416408],
                    [114.7081807, 10.0426404],
                    [114.7071802, 10.0436331],
                    [114.7061727, 10.0446188],
                    [114.7051582, 10.0455976],
                    [114.7041367, 10.0465694],
                    [114.7031083, 10.047534],
                    [114.7020731, 10.0484916],
                    [114.7010311, 10.0494419],
                    [114.6999824, 10.0503851],
                    [114.698927, 10.0513209],
                    [114.6978649, 10.0522495],
                    [114.6967962, 10.0531707],
                    [114.695721, 10.0540845],
                    [114.6946393, 10.0549908],
                    [114.6935512, 10.0558897],
                    [114.6924568, 10.056781],
                    [114.691356, 10.0576648],
                    [114.6902489, 10.0585409],
                    [114.6891356, 10.0594093],
                    [114.6880162, 10.06027],
                    [114.6868906, 10.061123],
                    [114.685759, 10.0619682],
                    [114.6846215, 10.0628056],
                    [114.6834779, 10.063635],
                    [114.6823286, 10.0644566],
                    [114.6811733, 10.0652702],
                    [114.6800124, 10.0660758],
                    [114.6788457, 10.0668733],
                    [114.6776733, 10.0676628],
                    [114.6764954, 10.0684442],
                    [114.6753119, 10.0692174],
                    [114.6741229, 10.0699824],
                    [114.6729286, 10.0707392],
                    [114.6717288, 10.0714877],
                    [114.6705238, 10.072228],
                    [114.6693135, 10.0729598],
                    [114.6680981, 10.0736833],
                    [114.6668775, 10.0743984],
                    [114.6656519, 10.0751051],
                    [114.6644212, 10.0758033],
                    [114.6631856, 10.0764929],
                    [114.6619451, 10.077174],
                    [114.6606999, 10.0778465],
                    [114.6594498, 10.0785104],
                    [114.6581951, 10.0791657],
                    [114.6569357, 10.0798123],
                    [114.6556717, 10.0804501],
                    [114.6544032, 10.0810792],
                    [114.6531303, 10.0816996],
                    [114.651853, 10.0823111],
                    [114.6505714, 10.0829138],
                    [114.6492855, 10.0835077],
                    [114.6479954, 10.0840926],
                    [114.6467012, 10.0846686],
                    [114.6454029, 10.0852357],
                    [114.6441006, 10.0857938],
                    [114.6427943, 10.0863429],
                    [114.6414842, 10.086883],
                    [114.6401703, 10.087414],
                    [114.6388526, 10.0879359],
                    [114.6375312, 10.0884487],
                    [114.6362062, 10.0889523],
                    [114.6348776, 10.0894468],
                    [114.6335456, 10.0899322],
                    [114.6322101, 10.0904083],
                    [114.6308713, 10.0908752],
                    [114.6295292, 10.0913328],
                    [114.6281839, 10.0917811],
                    [114.6268354, 10.0922202],
                    [114.6254838, 10.0926499],
                    [114.6241291, 10.0930703],
                    [114.6227716, 10.0934813],
                    [114.6214111, 10.0938829],
                    [114.6200478, 10.0942752],
                    [114.6186817, 10.094658],
                    [114.617313, 10.0950313],
                    [114.6159416, 10.0953952],
                    [114.6145676, 10.0957497],
                    [114.6131912, 10.0960946],
                    [114.6118124, 10.09643],
                    [114.6104311, 10.0967559],
                    [114.6090476, 10.0970723],
                    [114.6076619, 10.0973791],
                    [114.6062741, 10.0976763],
                    [114.6048841, 10.0979639],
                    [114.6034921, 10.098242],
                    [114.6020982, 10.0985104],
                    [114.6007024, 10.0987692],
                    [114.5993048, 10.0990183],
                    [114.5979055, 10.0992578],
                    [114.5965045, 10.0994876],
                    [114.5951019, 10.0997077],
                    [114.5936977, 10.0999182],
                    [114.5922921, 10.1001189],
                    [114.5908851, 10.10031],
                    [114.5894768, 10.1004913],
                    [114.5880672, 10.1006629],
                    [114.5866564, 10.1008247],
                    [114.5852445, 10.1009769],
                    [114.5838316, 10.1011192],
                    [114.5824177, 10.1012518],
                    [114.5810028, 10.1013747],
                    [114.5795872, 10.1014877],
                    [114.5781707, 10.101591],
                    [114.5767536, 10.1016846],
                    [114.5753358, 10.1017683],
                    [114.5739175, 10.1018422],
                    [114.5724986, 10.1019064],
                    [114.5710794, 10.1019607],
                    [114.5696598, 10.1020053],
                    [114.5682399, 10.10204],
                    [114.5668198, 10.1020649],
                    [114.5653995, 10.1020801],
                    [114.5639792, 10.1020854],
                    [114.5625589, 10.1020809],
                    [114.5611387, 10.1020667],
                    [114.5597186, 10.1020426],
                    [114.5582986, 10.1020087],
                    [114.556879, 10.101965],
                    [114.5554597, 10.1019115],
                    [114.5540409, 10.1018482],
                    [114.5524841, 10.101774],
                ],
                [
                    [114.5524841, 10.101774],
                    [114.5162868, 9.745982],
                    [114.5129686, 9.7094563],
                    [114.5117851, 9.7093706],
                    [114.5103679, 9.7092779],
                    [114.5089501, 9.7091949],
                    [114.5075317, 9.7091218],
                    [114.5061128, 9.7090584],
                    [114.5046935, 9.7090048],
                    [114.5032739, 9.7089611],
                    [114.501854, 9.7089272],
                    [114.5004339, 9.7089031],
                    [114.4990136, 9.7088888],
                    [114.4975933, 9.7088843],
                    [114.496173, 9.7088896],
                    [114.4947527, 9.7089048],
                    [114.4933327, 9.7089298],
                    [114.4919128, 9.7089645],
                    [114.4904932, 9.7090091],
                    [114.4890739, 9.7090636],
                    [114.4876551, 9.7091278],
                    [114.4862367, 9.7092018],
                    [114.484819, 9.7092856],
                    [114.4834018, 9.7093793],
                    [114.4819854, 9.7094827],
                    [114.4805697, 9.7095959],
                    [114.4791549, 9.7097189],
                    [114.477741, 9.7098516],
                    [114.476328, 9.7099942],
                    [114.4749161, 9.7101465],
                    [114.4735053, 9.7103085],
                    [114.4720958, 9.7104803],
                    [114.4706874, 9.7106619],
                    [114.4692804, 9.7108531],
                    [114.4678748, 9.7110541],
                    [114.4664707, 9.7112648],
                    [114.465068, 9.7114852],
                    [114.463667, 9.7117153],
                    [114.4622677, 9.7119551],
                    [114.4608701, 9.7122045],
                    [114.4594743, 9.7124636],
                    [114.4580804, 9.7127323],
                    [114.4566884, 9.7130107],
                    [114.4552985, 9.7132986],
                    [114.4539106, 9.7135962],
                    [114.4525249, 9.7139034],
                    [114.4511414, 9.7142201],
                    [114.4497602, 9.7145464],
                    [114.4483813, 9.7148822],
                    [114.4470049, 9.7152275],
                    [114.445631, 9.7155823],
                    [114.4442596, 9.7159467],
                    [114.4428908, 9.7163205],
                    [114.4415248, 9.7167037],
                    [114.4401614, 9.7170964],
                    [114.4390405, 9.7174277],
                    [114.3928512, 10.0451442],
                    [114.5524841, 10.101774],
                ],
            ],
            [
                [
                    [108.6685932, 12.42458],
                    [108.668737, 12.4243415],
                    [108.6687983, 12.4242166],
                    [108.6688988, 12.4239577],
                    [108.6689687, 12.4236894],
                    [108.6690313, 12.4233411],
                    [108.669072, 12.4231687],
                    [108.6691394, 12.4229299],
                    [108.6691686, 12.4228651],
                    [108.6692061, 12.4228044],
                    [108.6692513, 12.422749],
                    [108.6693033, 12.4226996],
                    [108.6693614, 12.4226571],
                    [108.6694245, 12.4226222],
                    [108.6694918, 12.4225955],
                    [108.6695619, 12.4225775],
                    [108.6697763, 12.4225598],
                    [108.6698458, 12.4225434],
                    [108.6699765, 12.4224875],
                    [108.6700359, 12.4224488],
                    [108.6701389, 12.4223523],
                    [108.6702703, 12.4221522],
                    [108.6703244, 12.4220867],
                    [108.6704492, 12.4219711],
                    [108.6706647, 12.421817],
                    [108.6707646, 12.4217304],
                    [108.6709469, 12.4215399],
                    [108.6710922, 12.421346],
                    [108.6712374, 12.421178],
                    [108.6714856, 12.4209512],
                    [108.6715593, 12.4208665],
                    [108.6716256, 12.4207763],
                    [108.6716841, 12.420681],
                    [108.6717344, 12.4205814],
                    [108.6717763, 12.4204781],
                    [108.6718093, 12.4203718],
                    [108.6718659, 12.4200781],
                    [108.6719239, 12.419899],
                    [108.672015, 12.4197137],
                    [108.6721444, 12.4195298],
                    [108.6723517, 12.4195627],
                    [108.6725617, 12.4195647],
                    [108.6727696, 12.4195356],
                    [108.6728713, 12.4195096],
                    [108.6730671, 12.4194355],
                    [108.673435, 12.4192391],
                    [108.6738694, 12.4190419],
                    [108.6740971, 12.4189011],
                    [108.6743615, 12.4186868],
                    [108.6746065, 12.4184515],
                    [108.6748304, 12.4181969],
                    [108.6750245, 12.4179351],
                    [108.6751074, 12.4178023],
                    [108.6751822, 12.417665],
                    [108.6752486, 12.4175237],
                    [108.6753065, 12.4173789],
                    [108.6753556, 12.417231],
                    [108.6753958, 12.4170806],
                    [108.6754269, 12.4169281],
                    [108.6754842, 12.4164516],
                    [108.6755157, 12.4162923],
                    [108.6755562, 12.416135],
                    [108.6756542, 12.4158516],
                    [108.6757815, 12.4155796],
                    [108.6762121, 12.4155629],
                    [108.6766421, 12.4155367],
                    [108.6770715, 12.4155011],
                    [108.6775, 12.415456],
                    [108.6780548, 12.4153834],
                    [108.678305, 12.415323],
                    [108.678545, 12.4152315],
                    [108.67866, 12.4151744],
                    [108.6788772, 12.4150391],
                    [108.6789787, 12.4149614],
                    [108.679124, 12.4148247],
                    [108.6791871, 12.4147477],
                    [108.6792916, 12.4145792],
                    [108.6793323, 12.414489],
                    [108.6793649, 12.4143956],
                    [108.6793967, 12.4142591],
                    [108.6794379, 12.4139822],
                    [108.6794471, 12.4138425],
                    [108.6794426, 12.4135627],
                    [108.679429, 12.4134234],
                    [108.6793863, 12.4131787],
                    [108.6793038, 12.4128639],
                    [108.6792387, 12.4126568],
                    [108.6790825, 12.4122515],
                    [108.6788928, 12.4118599],
                    [108.6786986, 12.411528],
                    [108.6785924, 12.4113675],
                    [108.6787192, 12.4112141],
                    [108.6787924, 12.4111461],
                    [108.6789214, 12.4110509],
                    [108.6790045, 12.4109646],
                    [108.6790596, 12.410859],
                    [108.6790752, 12.4108016],
                    [108.6790827, 12.4107135],
                    [108.6790806, 12.4106251],
                    [108.679069, 12.4105374],
                    [108.679048, 12.4104514],
                    [108.6790177, 12.410368],
                    [108.6789787, 12.4102882],
                    [108.6788434, 12.4100689],
                    [108.6787874, 12.4099532],
                    [108.6787394, 12.409834],
                    [108.6786766, 12.4096236],
                    [108.6785934, 12.4091751],
                    [108.6785196, 12.4086941],
                    [108.6784989, 12.4084821],
                    [108.6784874, 12.4082694],
                    [108.6784869, 12.4079699],
                    [108.6784806, 12.4078835],
                    [108.6784445, 12.4077143],
                    [108.678378, 12.4075541],
                    [108.6782832, 12.4074081],
                    [108.6782262, 12.4073419],
                    [108.678097, 12.4072273],
                    [108.677952, 12.4071365],
                    [108.6777939, 12.40707],
                    [108.6777112, 12.4070464],
                    [108.6776268, 12.4070295],
                    [108.6772896, 12.4069951],
                    [108.6770513, 12.4069521],
                    [108.6769161, 12.4068996],
                    [108.6768541, 12.4068624],
                    [108.6767452, 12.4067682],
                    [108.6766612, 12.4066523],
                    [108.6765432, 12.4063694],
                    [108.6764312, 12.4061382],
                    [108.6762967, 12.4059187],
                    [108.6761603, 12.4057365],
                    [108.6761068, 12.4056538],
                    [108.676024, 12.4054758],
                    [108.6759758, 12.4052861],
                    [108.6759653, 12.4051888],
                    [108.6759758, 12.404854],
                    [108.6759679, 12.4047355],
                    [108.6759508, 12.404618],
                    [108.6759245, 12.4045021],
                    [108.6758893, 12.4043885],
                    [108.6758453, 12.4042778],
                    [108.6757928, 12.4041707],
                    [108.675732, 12.4040679],
                    [108.6756635, 12.4039698],
                    [108.6755167, 12.4038038],
                    [108.675345, 12.4036624],
                    [108.6751528, 12.4035492],
                    [108.6750504, 12.403504],
                    [108.6747459, 12.403405],
                    [108.6745596, 12.4033135],
                    [108.6744099, 12.4032105],
                    [108.6742938, 12.4031064],
                    [108.6741113, 12.4029596],
                    [108.6737897, 12.4027176],
                    [108.673507, 12.4025343],
                    [108.6732588, 12.4023975],
                    [108.6731315, 12.4023352],
                    [108.6727238, 12.4013502],
                    [108.6730846, 12.4010278],
                    [108.6732565, 12.4008579],
                    [108.6735392, 12.4005591],
                    [108.673664, 12.4004037],
                    [108.6737523, 12.4003115],
                    [108.6738468, 12.4002252],
                    [108.6740867, 12.400036],
                    [108.6742043, 12.3999042],
                    [108.6742806, 12.3997839],
                    [108.6744111, 12.3995313],
                    [108.6744648, 12.3993999],
                    [108.6745484, 12.3991291],
                    [108.6745992, 12.3988506],
                    [108.674612, 12.3987096],
                    [108.6746159, 12.3985102],
                    [108.6746292, 12.3983111],
                    [108.6746521, 12.3981129],
                    [108.6746844, 12.3979159],
                    [108.6747261, 12.3977207],
                    [108.6747772, 12.3975275],
                    [108.6749539, 12.3969982],
                    [108.6750021, 12.3968258],
                    [108.675078, 12.3964762],
                    [108.6751181, 12.3961988],
                    [108.675139, 12.3960993],
                    [108.6752051, 12.3959071],
                    [108.6753021, 12.3957277],
                    [108.6753614, 12.3956444],
                    [108.6754274, 12.395566],
                    [108.6755167, 12.3954777],
                    [108.6756124, 12.3953962],
                    [108.6757142, 12.395322],
                    [108.6758214, 12.3952554],
                    [108.6759335, 12.3951969],
                    [108.6761189, 12.3951217],
                    [108.6762458, 12.3950484],
                    [108.6763514, 12.394948],
                    [108.6763943, 12.3948893],
                    [108.6764574, 12.3947591],
                    [108.6764898, 12.394604],
                    [108.6764924, 12.3945247],
                    [108.6764703, 12.3943678],
                    [108.6764458, 12.3942922],
                    [108.6763716, 12.3941514],
                    [108.6762168, 12.3939538],
                    [108.6760819, 12.3937427],
                    [108.6759622, 12.393507],
                    [108.6758673, 12.3932607],
                    [108.6760604, 12.3930511],
                    [108.6761999, 12.3926739],
                    [108.6774953, 12.3920762],
                    [108.6777433, 12.391969],
                    [108.677995, 12.3918703],
                    [108.67825, 12.3917802],
                    [108.6785081, 12.391699],
                    [108.678769, 12.3916265],
                    [108.6793261, 12.3914924],
                    [108.6796105, 12.3913911],
                    [108.6798819, 12.3912605],
                    [108.6801374, 12.391102],
                    [108.6803381, 12.3909482],
                    [108.6805236, 12.3907772],
                    [108.6810666, 12.3907393],
                    [108.6813372, 12.39071],
                    [108.6818755, 12.3906305],
                    [108.6824375, 12.3905161],
                    [108.6827155, 12.3904464],
                    [108.683069, 12.3903449],
                    [108.6832297, 12.3903211],
                    [108.683311, 12.3903212],
                    [108.6834717, 12.3903451],
                    [108.6836243, 12.3903999],
                    [108.6837041, 12.3904435],
                    [108.6837882, 12.3904784],
                    [108.6838757, 12.3905043],
                    [108.6839656, 12.3905208],
                    [108.6840567, 12.3905278],
                    [108.6841481, 12.3905251],
                    [108.6842386, 12.3905129],
                    [108.6843273, 12.3904911],
                    [108.6844131, 12.3904602],
                    [108.684495, 12.3904205],
                    [108.684572, 12.3903723],
                    [108.6846432, 12.3903164],
                    [108.6847079, 12.3902532],
                    [108.6847727, 12.3901733],
                    [108.684883, 12.3900003],
                    [108.6849654, 12.3898131],
                    [108.6850654, 12.3894868],
                    [108.6851964, 12.389171],
                    [108.6853571, 12.3888686],
                    [108.6855917, 12.3884976],
                    [108.6856508, 12.3883778],
                    [108.6857011, 12.3882543],
                    [108.6857423, 12.3881276],
                    [108.6857742, 12.3879984],
                    [108.6857967, 12.3878673],
                    [108.6858096, 12.3877349],
                    [108.6858139, 12.3873867],
                    [108.685829, 12.38728],
                    [108.6858537, 12.3871749],
                    [108.6858877, 12.3870725],
                    [108.6859958, 12.3868325],
                    [108.6860274, 12.3866812],
                    [108.686024, 12.3865268],
                    [108.6859858, 12.3863769],
                    [108.6859148, 12.3862389],
                    [108.685868, 12.3861766],
                    [108.6857547, 12.3860689],
                    [108.6856895, 12.3860249],
                    [108.6855532, 12.3859617],
                    [108.6854316, 12.385887],
                    [108.6853308, 12.3857871],
                    [108.6852561, 12.3856673],
                    [108.6852116, 12.3855339],
                    [108.6852014, 12.3854643],
                    [108.6851949, 12.385261],
                    [108.685182, 12.38516],
                    [108.685137, 12.3849613],
                    [108.6850757, 12.3847546],
                    [108.6850405, 12.3845421],
                    [108.6850325, 12.3842897],
                    [108.6850414, 12.3841636],
                    [108.6850848, 12.3839146],
                    [108.6851616, 12.3836734],
                    [108.6853268, 12.3833212],
                    [108.685416, 12.3830751],
                    [108.6854846, 12.3827897],
                    [108.6855061, 12.3826446],
                    [108.6855233, 12.382352],
                    [108.6854274, 12.3823535],
                    [108.6853318, 12.3823458],
                    [108.6852375, 12.3823289],
                    [108.6851453, 12.3823031],
                    [108.6850562, 12.3822685],
                    [108.684971, 12.3822255],
                    [108.6848905, 12.3821746],
                    [108.6848156, 12.3821161],
                    [108.6847469, 12.3820508],
                    [108.6846851, 12.3819791],
                    [108.6846309, 12.3819019],
                    [108.6845847, 12.3818198],
                    [108.6844878, 12.3815838],
                    [108.6843966, 12.3814502],
                    [108.6842777, 12.3813392],
                    [108.6841369, 12.3812562],
                    [108.683981, 12.3812053],
                    [108.6838996, 12.3811926],
                    [108.6838174, 12.3811888],
                    [108.6836832, 12.3812016],
                    [108.6834137, 12.3812056],
                    [108.6831454, 12.3811806],
                    [108.6828606, 12.3811215],
                    [108.6827112, 12.3810762],
                    [108.682565, 12.3810217],
                    [108.6824226, 12.3809582],
                    [108.6823046, 12.3805181],
                    [108.6831844, 12.3787366],
                    [108.6837915, 12.3786467],
                    [108.6844182, 12.3785704],
                    [108.6847348, 12.3785006],
                    [108.6850433, 12.378402],
                    [108.6851936, 12.3783422],
                    [108.6856498, 12.3781281],
                    [108.6858101, 12.3780672],
                    [108.6859737, 12.3780152],
                    [108.6861399, 12.3779721],
                    [108.6863084, 12.3779382],
                    [108.6864785, 12.3779136],
                    [108.6867603, 12.3778934],
                    [108.6868699, 12.3778789],
                    [108.6869777, 12.3778549],
                    [108.6870829, 12.3778218],
                    [108.6871847, 12.3777796],
                    [108.6872823, 12.3777287],
                    [108.6873749, 12.3776696],
                    [108.6874617, 12.3776027],
                    [108.6875421, 12.3775285],
                    [108.6876692, 12.3773777],
                    [108.6878118, 12.3771551],
                    [108.687897, 12.3770012],
                    [108.6879734, 12.3768429],
                    [108.6880405, 12.3766806],
                    [108.6881416, 12.3763648],
                    [108.6882084, 12.3760596],
                    [108.6882318, 12.3759052],
                    [108.6882584, 12.3755886],
                    [108.6883022, 12.3752683],
                    [108.6883358, 12.37511],
                    [108.6884442, 12.3747105],
                    [108.6884658, 12.3745863],
                    [108.6884792, 12.3744611],
                    [108.6884903, 12.3741801],
                    [108.6885266, 12.3740289],
                    [108.6885918, 12.3738873],
                    [108.6886346, 12.3738217],
                    [108.6886953, 12.3737476],
                    [108.6888353, 12.3736161],
                    [108.6890265, 12.3734808],
                    [108.6891169, 12.3733778],
                    [108.6891795, 12.3732567],
                    [108.6892108, 12.3731246],
                    [108.689214, 12.3730567],
                    [108.6892036, 12.37288],
                    [108.6892079, 12.3727915],
                    [108.6892185, 12.3727036],
                    [108.6892724, 12.372445],
                    [108.6892738, 12.3722696],
                    [108.6892395, 12.3720975],
                    [108.6892094, 12.3720148],
                    [108.6891444, 12.37189],
                    [108.6890522, 12.3717613],
                    [108.6889081, 12.3716064],
                    [108.6887419, 12.3714743],
                    [108.6884956, 12.371318],
                    [108.6882462, 12.3711314],
                    [108.6879991, 12.3709108],
                    [108.6878834, 12.3707927],
                    [108.6877733, 12.3706695],
                    [108.687502, 12.3703283],
                    [108.6873364, 12.3701494],
                    [108.6872681, 12.3700658],
                    [108.687154, 12.3698835],
                    [108.6871089, 12.369786],
                    [108.6870444, 12.3695818],
                    [108.6870155, 12.36937],
                    [108.6870146, 12.3692631],
                    [108.6870206, 12.3691755],
                    [108.6870586, 12.3689148],
                    [108.6870919, 12.3687423],
                    [108.6871719, 12.3684311],
                    [108.6872747, 12.3681583],
                    [108.6874086, 12.3678987],
                    [108.6875657, 12.3676638],
                    [108.6877412, 12.3674545],
                    [108.6878373, 12.367357],
                    [108.6880445, 12.3671776],
                    [108.6879387, 12.3670539],
                    [108.6878782, 12.3669991],
                    [108.6878132, 12.3669493],
                    [108.6876123, 12.3668257],
                    [108.6875008, 12.3667208],
                    [108.6874147, 12.3665951],
                    [108.6873824, 12.3665263],
                    [108.6873154, 12.3663066],
                    [108.687281, 12.3662374],
                    [108.6872384, 12.3661727],
                    [108.6871883, 12.3661134],
                    [108.6871313, 12.3660604],
                    [108.6869791, 12.3659529],
                    [108.6869118, 12.3658692],
                    [108.6868881, 12.3658211],
                    [108.6868633, 12.3657175],
                    [108.6868626, 12.3656641],
                    [108.6868848, 12.3655599],
                    [108.6869073, 12.3655113],
                    [108.6869369, 12.365466],
                    [108.6871189, 12.3652658],
                    [108.6871988, 12.3651923],
                    [108.6873373, 12.365083],
                    [108.6874287, 12.3649837],
                    [108.6874922, 12.3648655],
                    [108.6875123, 12.3648016],
                    [108.6875272, 12.3646688],
                    [108.6875219, 12.3646022],
                    [108.6875081, 12.3645366],
                    [108.6874783, 12.3644557],
                    [108.6873976, 12.3643032],
                    [108.6873472, 12.3642327],
                    [108.6871218, 12.3639843],
                    [108.6869155, 12.3637294],
                    [108.6868239, 12.363598],
                    [108.6867401, 12.3634618],
                    [108.6866643, 12.3633211],
                    [108.6865968, 12.3631765],
                    [108.6865377, 12.3630284],
                    [108.6864874, 12.3628773],
                    [108.6864459, 12.3627236],
                    [108.6863908, 12.3624165],
                    [108.6863708, 12.3621053],
                    [108.6862458, 12.3620648],
                    [108.6861807, 12.3620542],
                    [108.6861149, 12.3620502],
                    [108.6859076, 12.3620644],
                    [108.685837, 12.3620568],
                    [108.685768, 12.3620403],
                    [108.6857017, 12.3620152],
                    [108.6856393, 12.3619819],
                    [108.6855819, 12.3619411],
                    [108.6855303, 12.3618933],
                    [108.6854855, 12.3618395],
                    [108.6853737, 12.3616371],
                    [108.6853246, 12.3615725],
                    [108.6852683, 12.3615137],
                    [108.6852056, 12.3614615],
                    [108.6851372, 12.3614166],
                    [108.6850641, 12.3613795],
                    [108.6849872, 12.3613507],
                    [108.6849075, 12.3613305],
                    [108.6848259, 12.3613193],
                    [108.6845679, 12.3613129],
                    [108.6841338, 12.3612529],
                    [108.6839793, 12.3612446],
                    [108.6838246, 12.3612459],
                    [108.6836703, 12.3612568],
                    [108.6834546, 12.3612885],
                    [108.6833282, 12.3612877],
                    [108.6832058, 12.3612564],
                    [108.6831333, 12.3612212],
                    [108.6830004, 12.3611308],
                    [108.6829414, 12.3610766],
                    [108.6828411, 12.3609525],
                    [108.6827722, 12.3608232],
                    [108.6826214, 12.3604647],
                    [108.682576, 12.360379],
                    [108.6824623, 12.3602212],
                    [108.6823948, 12.3601506],
                    [108.6822417, 12.3600287],
                    [108.6821573, 12.3599784],
                    [108.6818541, 12.3598478],
                    [108.6817531, 12.3597912],
                    [108.6816572, 12.3597268],
                    [108.6815669, 12.3596549],
                    [108.681483, 12.359576],
                    [108.681406, 12.3594908],
                    [108.6813363, 12.3593996],
                    [108.6812745, 12.3593033],
                    [108.681221, 12.3592023],
                    [108.6811126, 12.3589619],
                    [108.6810493, 12.3588459],
                    [108.6809048, 12.3586234],
                    [108.6807382, 12.3584162],
                    [108.680706, 12.3577141],
                    [108.6803627, 12.3574625],
                    [108.6803671, 12.3573062],
                    [108.6803617, 12.3571499],
                    [108.6803466, 12.3569943],
                    [108.6803219, 12.3568398],
                    [108.6802532, 12.3565659],
                    [108.6801602, 12.3563317],
                    [108.6801021, 12.3562195],
                    [108.6799638, 12.3560077],
                    [108.6798843, 12.3559089],
                    [108.6797061, 12.3557278],
                    [108.6795051, 12.355571],
                    [108.6792179, 12.3553947],
                    [108.6790576, 12.3552613],
                    [108.6789858, 12.3551861],
                    [108.6788607, 12.3550206],
                    [108.6787289, 12.3548016],
                    [108.6786059, 12.3546258],
                    [108.6785268, 12.3544729],
                    [108.6784805, 12.3543076],
                    [108.6784702, 12.3542224],
                    [108.678476, 12.3540512],
                    [108.6785166, 12.3538845],
                    [108.6786272, 12.3536433],
                    [108.6786724, 12.3534704],
                    [108.6786835, 12.3532922],
                    [108.6786601, 12.3531151],
                    [108.6786032, 12.3529455],
                    [108.6784704, 12.3526829],
                    [108.6783714, 12.3524064],
                    [108.6783145, 12.352162],
                    [108.6782872, 12.3519552],
                    [108.6782301, 12.3516476],
                    [108.6781638, 12.3513418],
                    [108.6780882, 12.3510381],
                    [108.6779874, 12.3506962],
                    [108.6778606, 12.3503627],
                    [108.677627, 12.3498592],
                    [108.6775561, 12.349685],
                    [108.6773599, 12.3491093],
                    [108.6772525, 12.3489025],
                    [108.6771238, 12.3487256],
                    [108.6770683, 12.3486403],
                    [108.6770208, 12.3485505],
                    [108.6769818, 12.3484568],
                    [108.6769516, 12.3483602],
                    [108.6769304, 12.3482612],
                    [108.6769184, 12.3481608],
                    [108.6769157, 12.3480597],
                    [108.6769223, 12.3479589],
                    [108.6769381, 12.347859],
                    [108.6769631, 12.3477609],
                    [108.676997, 12.3476653],
                    [108.6770395, 12.3475732],
                    [108.6772177, 12.3472927],
                    [108.6773152, 12.3470843],
                    [108.6773522, 12.3469755],
                    [108.6774015, 12.3467515],
                    [108.6774583, 12.3462773],
                    [108.6775335, 12.3459415],
                    [108.6775839, 12.3457768],
                    [108.6777077, 12.3454609],
                    [108.6778629, 12.3451584],
                    [108.6777349, 12.3449347],
                    [108.6776608, 12.3448289],
                    [108.6774938, 12.3446312],
                    [108.6773549, 12.344498],
                    [108.6772804, 12.3443979],
                    [108.6772348, 12.3442824],
                    [108.6772239, 12.3442213],
                    [108.6772268, 12.3440975],
                    [108.6772544, 12.3439974],
                    [108.677309, 12.3438839],
                    [108.6774015, 12.3437435],
                    [108.6776123, 12.3435012],
                    [108.6777985, 12.3432404],
                    [108.6781481, 12.3426548],
                    [108.6781788, 12.3425891],
                    [108.6782156, 12.3424496],
                    [108.6782211, 12.3423776],
                    [108.678218, 12.3423055],
                    [108.6781771, 12.3421035],
                    [108.6781809, 12.3419698],
                    [108.6782174, 12.3418408],
                    [108.6782843, 12.3417241],
                    [108.678328, 12.3416725],
                    [108.6784146, 12.3415987],
                    [108.6786148, 12.3414847],
                    [108.6788038, 12.3414121],
                    [108.6789104, 12.3413843],
                    [108.6791289, 12.3413539],
                    [108.6791932, 12.3409032],
                    [108.6802769, 12.3401381],
                    [108.6802341, 12.3395204],
                    [108.6802232, 12.3389013],
                    [108.6802311, 12.338555],
                    [108.680249, 12.3382091],
                    [108.6802769, 12.3378637],
                    [108.6804762, 12.3377535],
                    [108.6805685, 12.3376871],
                    [108.6807355, 12.337534],
                    [108.6808756, 12.337357],
                    [108.6809853, 12.3371606],
                    [108.6810279, 12.3370567],
                    [108.6810841, 12.3368558],
                    [108.6811494, 12.3366575],
                    [108.6812237, 12.3364623],
                    [108.6813768, 12.3361255],
                    [108.6814948, 12.3358263],
                    [108.6815826, 12.3355173],
                    [108.6816666, 12.3350416],
                    [108.6817263, 12.3348903],
                    [108.6817676, 12.3348199],
                    [108.6818712, 12.3346932],
                    [108.6819989, 12.3345897],
                    [108.6821454, 12.3345135],
                    [108.6822238, 12.3344867],
                    [108.6823887, 12.334457],
                    [108.6824735, 12.3344547],
                    [108.6825581, 12.3344611],
                    [108.682723, 12.3344993],
                    [108.6826983, 12.3341263],
                    [108.6826983, 12.3339394],
                    [108.682723, 12.3335665],
                    [108.6827496, 12.3333693],
                    [108.6827854, 12.3331735],
                    [108.6828875, 12.3326745],
                    [108.6829805, 12.3320611],
                    [108.6830449, 12.3314442],
                    [108.6830728, 12.3310135],
                    [108.6830696, 12.3308921],
                    [108.6830567, 12.3307713],
                    [108.6830341, 12.3306519],
                    [108.6830022, 12.3305345],
                    [108.682961, 12.33042],
                    [108.6829107, 12.3303089],
                    [108.6827605, 12.3300431],
                    [108.6827277, 12.3299577],
                    [108.6827039, 12.3298695],
                    [108.6826895, 12.3297794],
                    [108.6826844, 12.3296883],
                    [108.6826889, 12.3295972],
                    [108.6827027, 12.329507],
                    [108.6827259, 12.3294187],
                    [108.6827582, 12.3293331],
                    [108.6827991, 12.3292511],
                    [108.6828484, 12.3291736],
                    [108.6829054, 12.3291014],
                    [108.6830416, 12.3289502],
                    [108.6831491, 12.3287784],
                    [108.6831911, 12.3286863],
                    [108.6832501, 12.3284934],
                    [108.6832743, 12.3282934],
                    [108.683263, 12.3280923],
                    [108.6832441, 12.3279932],
                    [108.6832166, 12.3278961],
                    [108.6831678, 12.3277731],
                    [108.6830463, 12.3275377],
                    [108.6829741, 12.3274262],
                    [108.6827873, 12.3271769],
                    [108.6827105, 12.3270165],
                    [108.6826844, 12.3269315],
                    [108.6826584, 12.3267563],
                    [108.6826678, 12.3265794],
                    [108.6826858, 12.3264925],
                    [108.6827805, 12.3262293],
                    [108.6828032, 12.3261367],
                    [108.682825, 12.3259475],
                    [108.682824, 12.3258522],
                    [108.682815, 12.3257573],
                    [108.682774, 12.3255732],
                    [108.6827041, 12.3253997],
                    [108.6826587, 12.3253176],
                    [108.6828039, 12.325114],
                    [108.6829197, 12.3248932],
                    [108.6830042, 12.3246592],
                    [108.6830341, 12.3245385],
                    [108.6830807, 12.324279],
                    [108.683138, 12.3241514],
                    [108.6832243, 12.3240405],
                    [108.6833348, 12.3239524],
                    [108.6833972, 12.3239186],
                    [108.6834633, 12.3238921],
                    [108.683637, 12.3238418],
                    [108.6837208, 12.3238083],
                    [108.6838738, 12.3237291],
                    [108.6851263, 12.3228754],
                    [108.6849513, 12.3228251],
                    [108.6847791, 12.322766],
                    [108.6846103, 12.3226983],
                    [108.6844453, 12.3226223],
                    [108.6842846, 12.3225379],
                    [108.6841285, 12.3224456],
                    [108.6839831, 12.322342],
                    [108.6838603, 12.3222134],
                    [108.6838089, 12.3221412],
                    [108.683728, 12.3219841],
                    [108.6836796, 12.3218182],
                    [108.6836634, 12.3216498],
                    [108.6836672, 12.3215652],
                    [108.6832909, 12.321436],
                    [108.682924, 12.3212833],
                    [108.6825794, 12.3211138],
                    [108.6822574, 12.3209303],
                    [108.6821383, 12.3208419],
                    [108.6820211, 12.3207286],
                    [108.6818433, 12.320538],
                    [108.6817569, 12.3204603],
                    [108.6815719, 12.3203191],
                    [108.6813986, 12.3202145],
                    [108.6813068, 12.3201711],
                    [108.6810108, 12.3200545],
                    [108.6808668, 12.3199873],
                    [108.680588, 12.3198357],
                    [108.6802891, 12.3196374],
                    [108.6801472, 12.3195281],
                    [108.6799067, 12.3193168],
                    [108.6798036, 12.3191569],
                    [108.6797355, 12.319019],
                    [108.6796759, 12.3188774],
                    [108.6796249, 12.3187326],
                    [108.6795827, 12.3185851],
                    [108.6795496, 12.3184355],
                    [108.6795255, 12.3182842],
                    [108.6795106, 12.3181317],
                    [108.6795049, 12.3179787],
                    [108.6795174, 12.3176729],
                    [108.6795356, 12.3175209],
                    [108.6795956, 12.3172204],
                    [108.6796938, 12.3169731],
                    [108.6797777, 12.3167895],
                    [108.6798322, 12.3165955],
                    [108.6798554, 12.316407],
                    [108.6798566, 12.316312],
                    [108.679838, 12.316123],
                    [108.679976, 12.3161656],
                    [108.680112, 12.316214],
                    [108.6802458, 12.3162681],
                    [108.680377, 12.3163278],
                    [108.6805142, 12.3163976],
                    [108.6806479, 12.3164734],
                    [108.6807779, 12.3165552],
                    [108.6808345, 12.316602],
                    [108.6808879, 12.3166521],
                    [108.680938, 12.3167054],
                    [108.6811137, 12.3168379],
                    [108.6812298, 12.3169106],
                    [108.6813507, 12.3169755],
                    [108.6814757, 12.3170323],
                    [108.6816043, 12.3170809],
                    [108.6820477, 12.3172186],
                    [108.6822528, 12.3172922],
                    [108.6824818, 12.3173448],
                    [108.6826069, 12.3173613],
                    [108.6828589, 12.3173696],
                    [108.6831098, 12.3173448],
                    [108.683355, 12.3172873],
                    [108.6836478, 12.317196],
                    [108.683824, 12.3171546],
                    [108.6840023, 12.3171225],
                    [108.6843606, 12.3170835],
                    [108.6845395, 12.3170731],
                    [108.6846291, 12.3170748],
                    [108.6848154, 12.3170524],
                    [108.68491, 12.3170269],
                    [108.6850016, 12.3169923],
                    [108.6850891, 12.3169489],
                    [108.6851718, 12.3168971],
                    [108.6852487, 12.3168374],
                    [108.685319, 12.3167705],
                    [108.6853822, 12.3166971],
                    [108.685504, 12.316511],
                    [108.6856577, 12.3163075],
                    [108.6858372, 12.3161213],
                    [108.6859352, 12.3160364],
                    [108.6862207, 12.3158234],
                    [108.6864067, 12.3156941],
                    [108.6869423, 12.3153508],
                    [108.6871802, 12.315216],
                    [108.6874077, 12.3150542],
                    [108.6876211, 12.3148656],
                    [108.6877198, 12.3147631],
                    [108.6878996, 12.3145436],
                    [108.6879802, 12.314427],
                    [108.6880856, 12.314234],
                    [108.6881286, 12.314133],
                    [108.6882445, 12.3137714],
                    [108.6883017, 12.3136215],
                    [108.6883662, 12.3134744],
                    [108.6885908, 12.3130404],
                    [108.688657, 12.3128871],
                    [108.6887646, 12.3125715],
                    [108.6888792, 12.3120788],
                    [108.6889268, 12.3119126],
                    [108.6890087, 12.3116663],
                    [108.6890423, 12.3115864],
                    [108.6891234, 12.3114412],
                    [108.6892247, 12.3113086],
                    [108.6893215, 12.3112284],
                    [108.6894343, 12.3111715],
                    [108.6895573, 12.3111409],
                    [108.6898044, 12.3111155],
                    [108.6899286, 12.3111155],
                    [108.6901757, 12.3111407],
                    [108.6902973, 12.3111658],
                    [108.6905336, 12.3112405],
                    [108.6906042, 12.311275],
                    [108.6907342, 12.311362],
                    [108.6907926, 12.3114138],
                    [108.6908939, 12.3115317],
                    [108.6909791, 12.3116779],
                    [108.6910146, 12.3117624],
                    [108.6910616, 12.311939],
                    [108.6910856, 12.3122225],
                    [108.6911031, 12.312323],
                    [108.6911762, 12.3125676],
                    [108.6912326, 12.3126634],
                    [108.6912735, 12.3127077],
                    [108.6913733, 12.3127762],
                    [108.6914298, 12.3127988],
                    [108.6915501, 12.3128183],
                    [108.6917653, 12.3127898],
                    [108.6918605, 12.3127856],
                    [108.6920501, 12.3128024],
                    [108.692245, 12.3128371],
                    [108.692348, 12.3128418],
                    [108.6924509, 12.3128372],
                    [108.692553, 12.3128235],
                    [108.6926534, 12.3128008],
                    [108.6929523, 12.3126914],
                    [108.6930614, 12.312661],
                    [108.6932845, 12.3126224],
                    [108.6935109, 12.3126137],
                    [108.6936719, 12.3125848],
                    [108.6938233, 12.3125239],
                    [108.6938933, 12.3124823],
                    [108.6940182, 12.3123789],
                    [108.6940717, 12.3123183],
                    [108.694158, 12.3121823],
                    [108.6942449, 12.3119557],
                    [108.6942828, 12.3118825],
                    [108.6943271, 12.3118128],
                    [108.6944756, 12.3116282],
                    [108.6945399, 12.3115014],
                    [108.6945757, 12.3113642],
                    [108.6945824, 12.3112936],
                    [108.6945815, 12.3112228],
                    [108.6945435, 12.3109924],
                    [108.6945383, 12.3109007],
                    [108.694553, 12.3107178],
                    [108.6945728, 12.310628],
                    [108.6946364, 12.3104554],
                    [108.6946797, 12.3103739],
                    [108.6948799, 12.3100968],
                    [108.6950089, 12.3099001],
                    [108.6950451, 12.3098306],
                    [108.6950763, 12.3097514],
                    [108.6950984, 12.3096693],
                    [108.6951113, 12.3095853],
                    [108.6951148, 12.3095005],
                    [108.6952213, 12.3095212],
                    [108.6954367, 12.3095476],
                    [108.6957086, 12.3095524],
                    [108.6958442, 12.309543],
                    [108.6962291, 12.3094884],
                    [108.6964632, 12.309491],
                    [108.6967262, 12.3095175],
                    [108.6968735, 12.3095218],
                    [108.6971679, 12.3095077],
                    [108.6974591, 12.3094638],
                    [108.6976257, 12.309541],
                    [108.6979687, 12.3096734],
                    [108.6982741, 12.3097636],
                    [108.6985067, 12.3098189],
                    [108.6985856, 12.3098306],
                    [108.6987481, 12.3098326],
                    [108.6989081, 12.3098051],
                    [108.6990601, 12.309749],
                    [108.6991314, 12.3097108],
                    [108.6993699, 12.3095363],
                    [108.6995995, 12.3093956],
                    [108.6996752, 12.3093389],
                    [108.6997448, 12.309275],
                    [108.699836, 12.3091673],
                    [108.6999025, 12.3090566],
                    [108.6999581, 12.3089026],
                    [108.699979, 12.3087407],
                    [108.6999643, 12.308578],
                    [108.700887, 12.3077552],
                    [108.7010407, 12.3077462],
                    [108.7011176, 12.3077499],
                    [108.7011919, 12.3077588],
                    [108.7013376, 12.3077919],
                    [108.701393, 12.3076532],
                    [108.701478, 12.3075129],
                    [108.7015341, 12.3074431],
                    [108.7017383, 12.3072483],
                    [108.7018064, 12.3071717],
                    [108.7019255, 12.3070054],
                    [108.7020206, 12.3068262],
                    [108.702094, 12.3066388],
                    [108.702131, 12.3064661],
                    [108.7021384, 12.3063781],
                    [108.7021306, 12.3062018],
                    [108.7021088, 12.306045],
                    [108.702106, 12.3058292],
                    [108.7020945, 12.3057541],
                    [108.7020492, 12.305609],
                    [108.7019533, 12.3054231],
                    [108.7019277, 12.3053128],
                    [108.7019208, 12.3052273],
                    [108.7019345, 12.3050566],
                    [108.7019839, 12.3048923],
                    [108.7020668, 12.3047414],
                    [108.7021198, 12.3046731],
                    [108.7021798, 12.3046105],
                    [108.7022542, 12.3046437],
                    [108.7023944, 12.3047258],
                    [108.702455, 12.3047708],
                    [108.702566, 12.3048725],
                    [108.7026182, 12.3049094],
                    [108.7026754, 12.3049382],
                    [108.7027363, 12.3049585],
                    [108.7027997, 12.3049697],
                    [108.702864, 12.3049715],
                    [108.702928, 12.3049641],
                    [108.70299, 12.3049474],
                    [108.7030489, 12.3049219],
                    [108.7031032, 12.3048882],
                    [108.7031518, 12.304847],
                    [108.7032545, 12.3047389],
                    [108.7033905, 12.3046342],
                    [108.7036802, 12.3044781],
                    [108.7038132, 12.3043954],
                    [108.703941, 12.3043051],
                    [108.704063, 12.3042076],
                    [108.7041788, 12.3041031],
                    [108.704288, 12.303992],
                    [108.7043438, 12.3037841],
                    [108.7043802, 12.3036826],
                    [108.704472, 12.3034813],
                    [108.7045891, 12.3032867],
                    [108.7047279, 12.3031063],
                    [108.7044758, 12.3024459],
                    [108.7049854, 12.3020423],
                    [108.7049732, 12.3018955],
                    [108.7049854, 12.3017488],
                    [108.705028, 12.3015874],
                    [108.7051, 12.3014363],
                    [108.7052071, 12.3012855],
                    [108.7052733, 12.3012091],
                    [108.7054208, 12.3010702],
                    [108.7055015, 12.3010084],
                    [108.7057278, 12.3008643],
                    [108.7058729, 12.3007819],
                    [108.7061725, 12.3006344],
                    [108.7063265, 12.3005695],
                    [108.7064801, 12.3004726],
                    [108.7065499, 12.3004148],
                    [108.7066729, 12.3002826],
                    [108.7067251, 12.3002092],
                    [108.7068748, 12.2999315],
                    [108.7069488, 12.2998171],
                    [108.7073372, 12.2993292],
                    [108.7074737, 12.2991393],
                    [108.7075335, 12.299039],
                    [108.7075861, 12.2988989],
                    [108.7076094, 12.2987513],
                    [108.7076025, 12.2986022],
                    [108.7075657, 12.2984572],
                    [108.7070507, 12.2982895],
                    [108.7069905, 12.298258],
                    [108.7069347, 12.2982193],
                    [108.7068423, 12.2981261],
                    [108.7067359, 12.297956],
                    [108.7066874, 12.2979005],
                    [108.7065716, 12.2978087],
                    [108.7065059, 12.2977738],
                    [108.7064364, 12.297747],
                    [108.7062278, 12.2976893],
                    [108.7059617, 12.2975924],
                    [108.7058143, 12.2975669],
                    [108.7057394, 12.2975656],
                    [108.7056647, 12.2975719],
                    [108.7055194, 12.2976071],
                    [108.7054504, 12.2976356],
                    [108.7052549, 12.2977493],
                    [108.7051827, 12.2977777],
                    [108.7050308, 12.2978097],
                    [108.7048754, 12.2978071],
                    [108.7047246, 12.2977703],
                    [108.7046534, 12.2977395],
                    [108.7044513, 12.297611],
                    [108.7042951, 12.2975426],
                    [108.7040873, 12.2974887],
                    [108.7039603, 12.2974645],
                    [108.7037033, 12.2974352],
                    [108.7036282, 12.2974199],
                    [108.7034834, 12.297371],
                    [108.7033492, 12.2972989],
                    [108.7032687, 12.2972208],
                    [108.7032376, 12.2971743],
                    [108.703197, 12.2970708],
                    [108.7031883, 12.2970159],
                    [108.7031858, 12.2969462],
                    [108.7031928, 12.2968768],
                    [108.7032091, 12.2968089],
                    [108.7032345, 12.2967437],
                    [108.7032686, 12.2966824],
                    [108.7034048, 12.2965196],
                    [108.7034761, 12.2963959],
                    [108.7035019, 12.2963294],
                    [108.7035208, 12.2962607],
                    [108.7035486, 12.2960323],
                    [108.7035668, 12.2959461],
                    [108.7036534, 12.2956789],
                    [108.7036768, 12.295577],
                    [108.703693, 12.2954738],
                    [108.7037141, 12.2951616],
                    [108.7037342, 12.2950592],
                    [108.7037635, 12.294959],
                    [108.7038016, 12.2948616],
                    [108.7039033, 12.2946786],
                    [108.7041646, 12.294348],
                    [108.7042149, 12.2942699],
                    [108.7042935, 12.2941023],
                    [108.7043211, 12.2940141],
                    [108.7043521, 12.2938322],
                    [108.7043498, 12.2936479],
                    [108.7042982, 12.2933417],
                    [108.7042404, 12.2930564],
                    [108.7041968, 12.2929171],
                    [108.7041619, 12.2928455],
                    [108.7040701, 12.2927149],
                    [108.7040142, 12.2926573],
                    [108.7038294, 12.2925123],
                    [108.7036673, 12.2923186],
                    [108.7035922, 12.2922451],
                    [108.7035107, 12.2921785],
                    [108.7034235, 12.2921191],
                    [108.7033313, 12.2920676],
                    [108.7030145, 12.2919393],
                    [108.7027858, 12.2918162],
                    [108.7026783, 12.2917437],
                    [108.7024795, 12.2915783],
                    [108.7022641, 12.2913678],
                    [108.7021344, 12.2912544],
                    [108.7018613, 12.2910435],
                    [108.7017184, 12.2909463],
                    [108.7017196, 12.2907938],
                    [108.7017303, 12.2906416],
                    [108.7017506, 12.2904903],
                    [108.7018127, 12.2902161],
                    [108.7019062, 12.2899505],
                    [108.7018295, 12.289911],
                    [108.7017577, 12.2898636],
                    [108.7016916, 12.2898089],
                    [108.7016325, 12.2897482],
                    [108.7015361, 12.2896098],
                    [108.7015092, 12.2895502],
                    [108.7014768, 12.2894242],
                    [108.7014717, 12.2893594],
                    [108.7014839, 12.28923],
                    [108.7015471, 12.2890292],
                    [108.7015595, 12.28895],
                    [108.7015625, 12.2888698],
                    [108.7015559, 12.2887898],
                    [108.7014979, 12.2885641],
                    [108.7014871, 12.2884924],
                    [108.7014656, 12.2882116],
                    [108.7013896, 12.2875056],
                    [108.7013885, 12.2873001],
                    [108.7014156, 12.2870853],
                    [108.7014398, 12.2869796],
                    [108.7015436, 12.2866617],
                    [108.7015899, 12.2864316],
                    [108.7016216, 12.2859439],
                    [108.7016466, 12.2856909],
                    [108.701681, 12.285439],
                    [108.7017246, 12.2851884],
                    [108.7017774, 12.2849396],
                    [108.7020397, 12.2848247],
                    [108.7022848, 12.2846781],
                    [108.7023997, 12.2845936],
                    [108.7025097, 12.2845014],
                    [108.7026134, 12.2844024],
                    [108.7027104, 12.2842972],
                    [108.7028312, 12.2841497],
                    [108.7028825, 12.2840695],
                    [108.7029281, 12.2839422],
                    [108.7029386, 12.2838078],
                    [108.7029133, 12.2836753],
                    [108.702854, 12.2835536],
                    [108.7028127, 12.2834994],
                    [108.7026766, 12.2833719],
                    [108.7024846, 12.2832333],
                    [108.7023815, 12.2831743],
                    [108.7020407, 12.2830215],
                    [108.7019216, 12.2829568],
                    [108.7017499, 12.2828484],
                    [108.701642, 12.2827514],
                    [108.701594, 12.2826906],
                    [108.7015537, 12.2826247],
                    [108.7015218, 12.2825546],
                    [108.7014985, 12.2824812],
                    [108.7014753, 12.2823323],
                    [108.7014826, 12.2821818],
                    [108.7016165, 12.2816793],
                    [108.701685, 12.2815438],
                    [108.7017289, 12.2814815],
                    [108.7018341, 12.2813708],
                    [108.7018944, 12.2813233],
                    [108.7020277, 12.2812466],
                    [108.7021815, 12.2811766],
                    [108.7023317, 12.2810717],
                    [108.7024587, 12.2809407],
                    [108.7025119, 12.2808669],
                    [108.7025955, 12.280706],
                    [108.7026496, 12.2805271],
                    [108.7026668, 12.2804321],
                    [108.7026787, 12.2802398],
                    [108.7026739, 12.2800276],
                    [108.7026982, 12.2797971],
                    [108.7027218, 12.2796835],
                    [108.7028203, 12.2793553],
                    [108.7028552, 12.2791373],
                    [108.7028611, 12.279027],
                    [108.7028978, 12.2788451],
                    [108.7029671, 12.2786725],
                    [108.7030133, 12.2785915],
                    [108.703127, 12.2784431],
                    [108.7032421, 12.2783333],
                    [108.7033278, 12.2782355],
                    [108.7034086, 12.2781029],
                    [108.7034629, 12.2779579],
                    [108.7034794, 12.2778824],
                    [108.7035048, 12.27762],
                    [108.703512, 12.2774338],
                    [108.7034994, 12.2770613],
                    [108.7028986, 12.2766839],
                    [108.7027587, 12.2766147],
                    [108.7026304, 12.2765267],
                    [108.7025537, 12.2764595],
                    [108.7024837, 12.2763856],
                    [108.7024211, 12.2763056],
                    [108.7023665, 12.2762203],
                    [108.7023204, 12.2761303],
                    [108.702283, 12.2760364],
                    [108.7022549, 12.2759396],
                    [108.7022206, 12.2757587],
                    [108.702212, 12.275667],
                    [108.7021733, 12.2755202],
                    [108.702143, 12.2754503],
                    [108.7020318, 12.2752638],
                    [108.7019932, 12.275141],
                    [108.7019847, 12.2750129],
                    [108.7019929, 12.2748856],
                    [108.7019669, 12.2746818],
                    [108.7019639, 12.274603],
                    [108.7019759, 12.2744457],
                    [108.7020194, 12.2743235],
                    [108.7020535, 12.2742679],
                    [108.7021436, 12.274173],
                    [108.7022567, 12.2741057],
                    [108.7023939, 12.2740519],
                    [108.7024656, 12.274014],
                    [108.702567, 12.2739472],
                    [108.7026437, 12.273875],
                    [108.702714, 12.2737666],
                    [108.7027844, 12.273609],
                    [108.7028395, 12.2735157],
                    [108.7029028, 12.2734275],
                    [108.7029737, 12.2733449],
                    [108.7030575, 12.2732023],
                    [108.7031085, 12.2730456],
                    [108.7031208, 12.2729642],
                    [108.7031194, 12.2727189],
                    [108.70314, 12.2725587],
                    [108.7031411, 12.2724962],
                    [108.703119, 12.2723735],
                    [108.7030659, 12.2722602],
                    [108.7029852, 12.2721636],
                    [108.7029362, 12.2721236],
                    [108.7040144, 12.2708131],
                    [108.7040648, 12.2707359],
                    [108.7041485, 12.270572],
                    [108.7042116, 12.2703842],
                    [108.7042414, 12.2702387],
                    [108.7042451, 12.2701893],
                    [108.704229, 12.2700635],
                    [108.7042329, 12.2699573],
                    [108.7042676, 12.2698566],
                    [108.7042958, 12.2698109],
                    [108.7043707, 12.2697338],
                    [108.7045497, 12.2696348],
                    [108.7047072, 12.2695246],
                    [108.70494, 12.269304],
                    [108.7050384, 12.26922],
                    [108.7051409, 12.269141],
                    [108.7052135, 12.2690978],
                    [108.7053697, 12.2690328],
                    [108.7055357, 12.2689982],
                    [108.7057054, 12.2689951],
                    [108.7059049, 12.2690079],
                    [108.7056874, 12.2688169],
                    [108.7052382, 12.2684508],
                    [108.7050068, 12.268276],
                    [108.7045373, 12.2679475],
                    [108.7042965, 12.2677918],
                    [108.7039125, 12.2675579],
                    [108.7038215, 12.2674737],
                    [108.7037525, 12.2673715],
                    [108.7037092, 12.2672566],
                    [108.7036942, 12.2671437],
                    [108.7037043, 12.2669756],
                    [108.7036812, 12.2668513],
                    [108.7036574, 12.2667924],
                    [108.7036259, 12.266737],
                    [108.7024319, 12.2649579],
                    [108.7027538, 12.2647325],
                    [108.7025928, 12.2641139],
                    [108.7031561, 12.2637889],
                    [108.7031823, 12.2637321],
                    [108.7032098, 12.2636107],
                    [108.7032039, 12.2634927],
                    [108.7031673, 12.2633801],
                    [108.7031025, 12.2632805],
                    [108.7030898, 12.2632117],
                    [108.7030967, 12.2631771],
                    [108.7031535, 12.2630842],
                    [108.7031653, 12.2630476],
                    [108.7031693, 12.2629215],
                    [108.7031952, 12.2628256],
                    [108.7032947, 12.2626621],
                    [108.7033648, 12.2624935],
                    [108.7034151, 12.2622731],
                    [108.7034565, 12.2620066],
                    [108.7035172, 12.2617654],
                    [108.7036121, 12.2615349],
                    [108.7037412, 12.2613072],
                    [108.7038159, 12.2611994],
                    [108.7038421, 12.261137],
                    [108.7038591, 12.2610717],
                    [108.7038665, 12.2610046],
                    [108.7038693, 12.2608646],
                    [108.7039029, 12.2607229],
                    [108.704027, 12.2604704],
                    [108.7041317, 12.2602211],
                    [108.7042442, 12.2598691],
                    [108.7043041, 12.2596425],
                    [108.7043587, 12.2594766],
                    [108.704493, 12.2591538],
                    [108.7045723, 12.2589977],
                    [108.704507, 12.2587785],
                    [108.7044838, 12.2586454],
                    [108.7044928, 12.2585067],
                    [108.7045313, 12.2583828],
                    [108.7045924, 12.2582789],
                    [108.7054034, 12.2571069],
                    [108.7054627, 12.2569964],
                    [108.7055013, 12.2568718],
                    [108.7055115, 12.2568046],
                    [108.705511, 12.2566691],
                    [108.7054943, 12.256515],
                    [108.705508, 12.2563413],
                    [108.7055556, 12.2561734],
                    [108.7056723, 12.2559104],
                    [108.7056935, 12.2558472],
                    [108.7057133, 12.2557193],
                    [108.7057064, 12.2555444],
                    [108.7057306, 12.2554558],
                    [108.705752, 12.2554149],
                    [108.7058115, 12.2553439],
                    [108.7058912, 12.2552877],
                    [108.7060178, 12.2552424],
                    [108.7062514, 12.2551867],
                    [108.7062865, 12.2549567],
                    [108.7062943, 12.2548407],
                    [108.7062877, 12.2545738],
                    [108.7062715, 12.2544411],
                    [108.7062138, 12.2541802],
                    [108.7061015, 12.2538045],
                    [108.7060368, 12.2536192],
                    [108.7059563, 12.2534463],
                    [108.7059013, 12.2532765],
                    [108.7058796, 12.2530997],
                    [108.7058969, 12.2528301],
                    [108.7058959, 12.2527381],
                    [108.7058383, 12.2522825],
                    [108.705757, 12.2521963],
                    [108.7056186, 12.2520112],
                    [108.7055119, 12.2518126],
                    [108.705436, 12.251601],
                    [108.7053058, 12.2516138],
                    [108.7050444, 12.2516167],
                    [108.7048604, 12.2516041],
                    [108.704778, 12.2515786],
                    [108.7046953, 12.2515251],
                    [108.7046609, 12.25149],
                    [108.7045772, 12.2513606],
                    [108.704538, 12.2513192],
                    [108.7044932, 12.2512837],
                    [108.7042344, 12.2511239],
                    [108.704089, 12.2510007],
                    [108.7039652, 12.2508567],
                    [108.7037623, 12.2505473],
                    [108.7035719, 12.2503108],
                    [108.7034859, 12.2501637],
                    [108.7033893, 12.2499642],
                    [108.7032435, 12.2497326],
                    [108.7031172, 12.2495701],
                    [108.7030014, 12.2494577],
                    [108.7029266, 12.2494025],
                    [108.7028465, 12.2493549],
                    [108.702762, 12.2493152],
                    [108.7026739, 12.2492839],
                    [108.7025831, 12.2492613],
                    [108.7024904, 12.2492475],
                    [108.7023968, 12.2492428],
                    [108.7022898, 12.2492498],
                    [108.7023541, 12.249166],
                    [108.7024265, 12.2491004],
                    [108.7024848, 12.2490626],
                    [108.7025478, 12.2490327],
                    [108.7026143, 12.2490113],
                    [108.7026574, 12.2489874],
                    [108.7027286, 12.2489205],
                    [108.7027738, 12.2488347],
                    [108.702785, 12.2487875],
                    [108.702783, 12.248691],
                    [108.7027448, 12.2485798],
                    [108.7025392, 12.2482366],
                    [108.7023869, 12.2479251],
                    [108.7022173, 12.2475277],
                    [108.7021386, 12.2473083],
                    [108.7020457, 12.2471241],
                    [108.7019383, 12.2469827],
                    [108.7018045, 12.2468647],
                    [108.7016497, 12.2467747],
                    [108.7014798, 12.2467162],
                    [108.7013912, 12.2466994],
                    [108.7012517, 12.246689],
                    [108.7011844, 12.2466767],
                    [108.7011194, 12.2466554],
                    [108.7010582, 12.2466255],
                    [108.7010018, 12.2465875],
                    [108.7009513, 12.2465422],
                    [108.7009066, 12.2464633],
                    [108.700894, 12.246374],
                    [108.7009152, 12.2462863],
                    [108.7009881, 12.2461743],
                    [108.7010054, 12.2460909],
                    [108.7010013, 12.2460484],
                    [108.7009617, 12.2459452],
                    [108.7009259, 12.2458873],
                    [108.7008821, 12.2458349],
                    [108.7008313, 12.245789],
                    [108.7006931, 12.2457042],
                    [108.70052, 12.2456328],
                    [108.7003368, 12.2455916],
                    [108.7001299, 12.2455665],
                    [108.7000176, 12.2455441],
                    [108.6997977, 12.2454814],
                    [108.6996907, 12.2454413],
                    [108.6997642, 12.2452504],
                    [108.6998735, 12.2450432],
                    [108.6999462, 12.2449328],
                    [108.7000267, 12.2448277],
                    [108.7001553, 12.2446864],
                    [108.7001896, 12.2446392],
                    [108.7002361, 12.2445317],
                    [108.7002493, 12.2444158],
                    [108.700228, 12.2443009],
                    [108.7001739, 12.2441969],
                    [108.7001359, 12.2441517],
                    [108.7003621, 12.2441256],
                    [108.7005882, 12.2440769],
                    [108.7008119, 12.2440049],
                    [108.7009419, 12.2439314],
                    [108.7010543, 12.243834],
                    [108.7012169, 12.2436327],
                    [108.701383, 12.2434018],
                    [108.7014234, 12.2433338],
                    [108.7014758, 12.2432104],
                    [108.7014985, 12.2431137],
                    [108.7015023, 12.2430556],
                    [108.7014864, 12.2429406],
                    [108.7014669, 12.2428857],
                    [108.7014066, 12.2427857],
                    [108.7013215, 12.2427048],
                    [108.7012494, 12.2426128],
                    [108.7012062, 12.242505],
                    [108.7011967, 12.2424477],
                    [108.7012025, 12.242332],
                    [108.7012725, 12.2421305],
                    [108.7012948, 12.242036],
                    [108.7013075, 12.2419398],
                    [108.7013106, 12.2418429],
                    [108.7013041, 12.2417461],
                    [108.701288, 12.2416504],
                    [108.7012625, 12.2415567],
                    [108.7013546, 12.2415483],
                    [108.7014395, 12.2415121],
                    [108.7015119, 12.2414475],
                    [108.7015545, 12.241371],
                    [108.7015674, 12.2412935],
                    [108.7015368, 12.2411156],
                    [108.701528, 12.2410141],
                    [108.7015436, 12.2408955],
                    [108.7015864, 12.2407834],
                    [108.7016173, 12.2407317],
                    [108.7017372, 12.2405868],
                    [108.7017887, 12.2404856],
                    [108.7018056, 12.2404315],
                    [108.7018354, 12.2402183],
                    [108.7018597, 12.240119],
                    [108.7019738, 12.2398286],
                    [108.7020301, 12.2396219],
                    [108.702084, 12.2392043],
                    [108.7021047, 12.2388725],
                    [108.7021081, 12.2386786],
                    [108.7021019, 12.2384848],
                    [108.702086, 12.2382914],
                    [108.7020606, 12.2380991],
                    [108.7020257, 12.2379082],
                    [108.7019813, 12.2377192],
                    [108.702175, 12.2374522],
                    [108.7022817, 12.237326],
                    [108.7024963, 12.2371058],
                    [108.7026693, 12.2369679],
                    [108.7027619, 12.2369068],
                    [108.7030967, 12.2367268],
                    [108.7031826, 12.2366578],
                    [108.7032191, 12.2366132],
                    [108.7032707, 12.2365111],
                    [108.703313, 12.2363308],
                    [108.7033546, 12.2360783],
                    [108.7033766, 12.235982],
                    [108.703421, 12.2358407],
                    [108.7034747, 12.2357318],
                    [108.7035159, 12.2356726],
                    [108.7036169, 12.2355689],
                    [108.7037385, 12.2354893],
                    [108.7039276, 12.2354129],
                    [108.7041807, 12.2353447],
                    [108.7045509, 12.2352919],
                    [108.7046394, 12.2352657],
                    [108.7047499, 12.2352128],
                    [108.7050076, 12.2350554],
                    [108.7052951, 12.2348528],
                    [108.7055791, 12.2346188],
                    [108.7058437, 12.2343639],
                    [108.7063407, 12.2338498],
                    [108.7065464, 12.233609],
                    [108.7066457, 12.2334724],
                    [108.7067372, 12.2333307],
                    [108.7068207, 12.2331844],
                    [108.706896, 12.2330339],
                    [108.7069628, 12.2328796],
                    [108.707335, 12.2320886],
                    [108.7074411, 12.2320506],
                    [108.7075438, 12.2320047],
                    [108.7076426, 12.2319511],
                    [108.7077369, 12.2318902],
                    [108.707826, 12.2318223],
                    [108.7078822, 12.2317733],
                    [108.7079358, 12.2317217],
                    [108.7082791, 12.2313389],
                    [108.7086633, 12.2308675],
                    [108.708975, 12.2304537],
                    [108.7091766, 12.2301707],
                    [108.7092824, 12.230074],
                    [108.7093471, 12.2300326],
                    [108.7094164, 12.229999],
                    [108.7094893, 12.2299738],
                    [108.7095648, 12.2299573],
                    [108.7100065, 12.2299129],
                    [108.7103174, 12.2298715],
                    [108.7106268, 12.2298208],
                    [108.7113107, 12.2296824],
                    [108.7116855, 12.2295984],
                    [108.7121691, 12.2295127],
                    [108.7125341, 12.2294623],
                    [108.7126565, 12.2294516],
                    [108.7129465, 12.2294502],
                    [108.7132734, 12.2294673],
                    [108.7134017, 12.2294418],
                    [108.7134623, 12.2294174],
                    [108.7135717, 12.2293472],
                    [108.7137305, 12.2291743],
                    [108.7138868, 12.2290323],
                    [108.7140606, 12.2289112],
                    [108.7141532, 12.2288592],
                    [108.7141718, 12.2287051],
                    [108.7141893, 12.2286294],
                    [108.7142484, 12.228464],
                    [108.7142926, 12.2283764],
                    [108.7143445, 12.228293],
                    [108.7145695, 12.2280201],
                    [108.714739, 12.227782],
                    [108.7152755, 12.2268697],
                    [108.7153763, 12.2267149],
                    [108.7155801, 12.2264475],
                    [108.7156587, 12.2262944],
                    [108.7156858, 12.2262128],
                    [108.7157307, 12.2259671],
                    [108.7157846, 12.2258197],
                    [108.7158215, 12.2257502],
                    [108.7158963, 12.2256425],
                    [108.7159909, 12.2255452],
                    [108.7161081, 12.2254674],
                    [108.7162396, 12.2254161],
                    [108.7164545, 12.2253832],
                    [108.716669, 12.2253661],
                    [108.717227, 12.2253832],
                    [108.7182679, 12.2254474],
                    [108.7183787, 12.2252643],
                    [108.7186217, 12.2249114],
                    [108.7187447, 12.2247531],
                    [108.7190635, 12.2243801],
                    [108.719155, 12.2242275],
                    [108.7192193, 12.2240581],
                    [108.7192525, 12.2238765],
                    [108.7192564, 12.2237843],
                    [108.7192214, 12.2234352],
                    [108.7192125, 12.223269],
                    [108.7192118, 12.2231026],
                    [108.7192316, 12.2228442],
                    [108.719251, 12.222716],
                    [108.7193466, 12.2222843],
                    [108.719377, 12.2221042],
                    [108.7194264, 12.2216189],
                    [108.7194689, 12.2214533],
                    [108.7195413, 12.2212978],
                    [108.7195879, 12.2212254],
                    [108.7197454, 12.2210313],
                    [108.720077, 12.2206688],
                    [108.7201813, 12.2205433],
                    [108.7203759, 12.220282],
                    [108.7205592, 12.21999],
                    [108.7206591, 12.2198493],
                    [108.7208748, 12.2195794],
                    [108.7211419, 12.2192952],
                    [108.7214327, 12.2190342],
                    [108.721717, 12.2188087],
                    [108.7218913, 12.2186372],
                    [108.7219689, 12.218543],
                    [108.7221033, 12.21834],
                    [108.7221862, 12.2181955],
                    [108.7222856, 12.2179803],
                    [108.7218761, 12.2176205],
                    [108.7215709, 12.2173605],
                    [108.7212229, 12.2170683],
                    [108.7203982, 12.2164514],
                    [108.7202249, 12.2162921],
                    [108.7201431, 12.2162037],
                    [108.7199265, 12.215933],
                    [108.7196688, 12.2156753],
                    [108.7195688, 12.2155616],
                    [108.7193883, 12.2153191],
                    [108.71922, 12.2150391],
                    [108.7191368, 12.2148844],
                    [108.7189865, 12.2145672],
                    [108.7188116, 12.2142331],
                    [108.7187558, 12.2141058],
                    [108.7187243, 12.2139497],
                    [108.7187222, 12.2138701],
                    [108.7187487, 12.2136509],
                    [108.7187329, 12.2135287],
                    [108.7186872, 12.2134138],
                    [108.7186028, 12.2132727],
                    [108.7185144, 12.2130887],
                    [108.7184947, 12.213027],
                    [108.718434, 12.2127374],
                    [108.7184169, 12.2125839],
                    [108.7184295, 12.21243],
                    [108.7184469, 12.2123547],
                    [108.7185369, 12.2121006],
                    [108.7186861, 12.2117464],
                    [108.7187544, 12.2115326],
                    [108.7187975, 12.2113126],
                    [108.7188095, 12.2112012],
                    [108.7183803, 12.2103675],
                    [108.7186754, 12.2098799],
                    [108.7187351, 12.209726],
                    [108.7187564, 12.2096463],
                    [108.7187822, 12.209475],
                    [108.7187851, 12.2093843],
                    [108.7187808, 12.2092935],
                    [108.7187307, 12.2089816],
                    [108.7187205, 12.2088478],
                    [108.7187039, 12.2086926],
                    [108.71867, 12.2085377],
                    [108.7185681, 12.2084014],
                    [108.718447, 12.2082822],
                    [108.7181635, 12.2080135],
                    [108.7178814, 12.2077722],
                    [108.7175435, 12.20751],
                    [108.7174244, 12.2074059],
                    [108.717312, 12.2072949],
                    [108.7172067, 12.2071774],
                    [108.717109, 12.2070539],
                    [108.7169693, 12.2068431],
                    [108.7163715, 12.2056761],
                    [108.716315, 12.2055858],
                    [108.7161806, 12.205422],
                    [108.7161033, 12.205349],
                    [108.71602, 12.2052826],
                    [108.7159312, 12.2052233],
                    [108.7158376, 12.2051716],
                    [108.7157531, 12.2049823],
                    [108.7156744, 12.2047906],
                    [108.7156015, 12.2045967],
                    [108.7155345, 12.2044009],
                    [108.7154898, 12.204258],
                    [108.715454, 12.2041128],
                    [108.7154272, 12.2039657],
                    [108.7153498, 12.2036876],
                    [108.7151579, 12.2032206],
                    [108.7150845, 12.2030177],
                    [108.7149351, 12.2025222],
                    [108.7148586, 12.2022302],
                    [108.7147613, 12.2019705],
                    [108.7147032, 12.2018444],
                    [108.7146051, 12.2016604],
                    [108.7145023, 12.2015024],
                    [108.7144298, 12.2014078],
                    [108.7142681, 12.2012322],
                    [108.7140861, 12.2010767],
                    [108.7137571, 12.2008213],
                    [108.7135534, 12.2006805],
                    [108.7133899, 12.2005381],
                    [108.7133054, 12.2004504],
                    [108.7131541, 12.2002603],
                    [108.7129373, 12.1999044],
                    [108.7128399, 12.1997597],
                    [108.712539, 12.1993648],
                    [108.712412, 12.1991843],
                    [108.7123387, 12.1990422],
                    [108.7123113, 12.1989601],
                    [108.712293, 12.1988756],
                    [108.7122841, 12.1987897],
                    [108.7122845, 12.1987034],
                    [108.7123096, 12.198551],
                    [108.7123836, 12.1983474],
                    [108.7123999, 12.1982691],
                    [108.7124075, 12.1981895],
                    [108.7123922, 12.1979345],
                    [108.7123966, 12.1978386],
                    [108.712431, 12.1976499],
                    [108.7124607, 12.1975584],
                    [108.7124963, 12.1974819],
                    [108.7125405, 12.1974097],
                    [108.7125927, 12.1973428],
                    [108.7127647, 12.1971783],
                    [108.7128377, 12.1970642],
                    [108.7128808, 12.1969364],
                    [108.7128903, 12.1968697],
                    [108.7128922, 12.1967541],
                    [108.7128898, 12.1966041],
                    [108.7128519, 12.1962032],
                    [108.712788, 12.1958379],
                    [108.7127414, 12.1956504],
                    [108.7126859, 12.1954652],
                    [108.7125691, 12.19511],
                    [108.7124864, 12.1947587],
                    [108.7124563, 12.1945808],
                    [108.712391, 12.1939877],
                    [108.7123749, 12.1936783],
                    [108.7123596, 12.193569],
                    [108.7123352, 12.1934612],
                    [108.7123019, 12.1933557],
                    [108.71226, 12.1932532],
                    [108.7122097, 12.1931544],
                    [108.7121513, 12.19306],
                    [108.7120852, 12.1929705],
                    [108.7119374, 12.1928231],
                    [108.7118203, 12.1926712],
                    [108.7117289, 12.1925033],
                    [108.7116366, 12.1922481],
                    [108.7116005, 12.1921677],
                    [108.7115028, 12.1919976],
                    [108.7114394, 12.19191],
                    [108.7112935, 12.1917498],
                    [108.7112535, 12.191713],
                    [108.7112118, 12.1916781],
                    [108.7111426, 12.1916268],
                    [108.7110696, 12.1915808],
                    [108.7109933, 12.1915402],
                    [108.7109142, 12.1915052],
                    [108.7108727, 12.1914926],
                    [108.7108326, 12.1914761],
                    [108.7107814, 12.1914481],
                    [108.7107343, 12.1914139],
                    [108.7106922, 12.1913741],
                    [108.7106556, 12.1913293],
                    [108.7106198, 12.1912733],
                    [108.7105913, 12.1912134],
                    [108.7105706, 12.1911506],
                    [108.710558, 12.1910858],
                    [108.7105536, 12.1910199],
                    [108.7105388, 12.1908831],
                    [108.7105266, 12.1908386],
                    [108.7105191, 12.1907932],
                    [108.7105161, 12.1907473],
                    [108.7105197, 12.1906829],
                    [108.7105324, 12.1906197],
                    [108.7105539, 12.1905588],
                    [108.7105838, 12.1905013],
                    [108.7106215, 12.1904485],
                    [108.7106663, 12.1904012],
                    [108.7107394, 12.1903313],
                    [108.7108167, 12.1902659],
                    [108.7108979, 12.1902053],
                    [108.7109828, 12.1901495],
                    [108.7111833, 12.1900099],
                    [108.7113783, 12.189863],
                    [108.7115675, 12.1897091],
                    [108.7118091, 12.1895223],
                    [108.7120559, 12.1893422],
                    [108.7123078, 12.189169],
                    [108.7125537, 12.1889899],
                    [108.7127944, 12.1888043],
                    [108.7130299, 12.1886124],
                    [108.71326, 12.1884143],
                    [108.7134737, 12.1882364],
                    [108.7137052, 12.1880128],
                    [108.7138123, 12.1879025],
                    [108.7140095, 12.1876675],
                    [108.7143109, 12.1872298],
                    [108.7144454, 12.1870488],
                    [108.7147328, 12.1867008],
                    [108.7148854, 12.1865341],
                    [108.715035, 12.1863314],
                    [108.7151606, 12.1861138],
                    [108.7153266, 12.1857437],
                    [108.7154007, 12.1856075],
                    [108.715483, 12.1854759],
                    [108.7155732, 12.1853494],
                    [108.7156709, 12.1852283],
                    [108.7157759, 12.1851131],
                    [108.7160388, 12.1848509],
                    [108.7161237, 12.1847535],
                    [108.7162013, 12.1846505],
                    [108.7162712, 12.1845423],
                    [108.7163332, 12.1844296],
                    [108.7163868, 12.1843128],
                    [108.7164318, 12.1841926],
                    [108.7165238, 12.1838571],
                    [108.716572, 12.1836427],
                    [108.7166328, 12.1833189],
                    [108.7166449, 12.1832097],
                    [108.7166479, 12.1829016],
                    [108.7166612, 12.1827623],
                    [108.7166922, 12.182665],
                    [108.7167565, 12.1825741],
                    [108.7168465, 12.1825071],
                    [108.7168984, 12.1824847],
                    [108.7169533, 12.1824706],
                    [108.7171648, 12.1824448],
                    [108.717471, 12.1823818],
                    [108.7178144, 12.1822797],
                    [108.7180468, 12.1822145],
                    [108.7182811, 12.1821557],
                    [108.7184934, 12.1821082],
                    [108.7187068, 12.1820658],
                    [108.7189212, 12.1820287],
                    [108.7189929, 12.1820171],
                    [108.7191297, 12.1819702],
                    [108.7193701, 12.1818254],
                    [108.7194781, 12.1817269],
                    [108.719565, 12.1816101],
                    [108.7196276, 12.1814793],
                    [108.719768, 12.1810577],
                    [108.7199655, 12.1805354],
                    [108.7200503, 12.1803845],
                    [108.7201022, 12.1803148],
                    [108.720223, 12.1801894],
                    [108.7204054, 12.1800321],
                    [108.7204433, 12.1799845],
                    [108.7204976, 12.1798767],
                    [108.720513, 12.1798183],
                    [108.7205188, 12.1796983],
                    [108.7205091, 12.1796387],
                    [108.7204643, 12.1794795],
                    [108.7204134, 12.1791959],
                    [108.7203686, 12.1786741],
                    [108.7203191, 12.1783623],
                    [108.7202445, 12.1780552],
                    [108.720105, 12.17762],
                    [108.7200804, 12.1775105],
                    [108.7200552, 12.177288],
                    [108.7200593, 12.1769621],
                    [108.7200266, 12.1767608],
                    [108.7199585, 12.1765681],
                    [108.7198301, 12.1763279],
                    [108.7196503, 12.1760393],
                    [108.7193571, 12.1756072],
                    [108.7193043, 12.1754774],
                    [108.7192805, 12.1753402],
                    [108.7192797, 12.1752705],
                    [108.7193005, 12.1751328],
                    [108.7193484, 12.1750092],
                    [108.7194214, 12.1749062],
                    [108.719525, 12.1747979],
                    [108.7195793, 12.1747307],
                    [108.7196612, 12.1745943],
                    [108.7196926, 12.1745213],
                    [108.7197349, 12.1743687],
                    [108.7197456, 12.1742902],
                    [108.7197509, 12.1741434],
                    [108.7197793, 12.1740321],
                    [108.7198393, 12.1739335],
                    [108.7199277, 12.1738321],
                    [108.7200039, 12.1737002],
                    [108.7200314, 12.1736293],
                    [108.7201747, 12.1731261],
                    [108.7202908, 12.172814],
                    [108.7203196, 12.1727537],
                    [108.7204118, 12.1726129],
                    [108.7205295, 12.1724915],
                    [108.7206685, 12.172394],
                    [108.7209287, 12.1722765],
                    [108.7211302, 12.1721663],
                    [108.7213935, 12.1719863],
                    [108.7217127, 12.1717322],
                    [108.7221376, 12.1713369],
                    [108.7223124, 12.1711856],
                    [108.7224063, 12.1711177],
                    [108.7225411, 12.1710539],
                    [108.7226129, 12.1710327],
                    [108.7227612, 12.1710127],
                    [108.7229106, 12.1710233],
                    [108.7230637, 12.1710163],
                    [108.7232111, 12.1709754],
                    [108.7232802, 12.1709428],
                    [108.7237742, 12.1706405],
                    [108.7243117, 12.1702938],
                    [108.7247028, 12.1700229],
                    [108.7248552, 12.169896],
                    [108.724933, 12.1698172],
                    [108.7249783, 12.1697552],
                    [108.7250158, 12.1696884],
                    [108.7250448, 12.1696177],
                    [108.725065, 12.1695441],
                    [108.7250761, 12.1694686],
                    [108.7250755, 12.1692659],
                    [108.7250666, 12.1691396],
                    [108.724992, 12.1686268],
                    [108.7249812, 12.1684926],
                    [108.7249806, 12.1682235],
                    [108.7250081, 12.1679556],
                    [108.7250691, 12.1676395],
                    [108.7253186, 12.1666291],
                    [108.7253621, 12.1664926],
                    [108.7254003, 12.1664061],
                    [108.7254983, 12.166244],
                    [108.7255574, 12.1661695],
                    [108.7256227, 12.1661002],
                    [108.7258291, 12.1659241],
                    [108.725932, 12.165801],
                    [108.7260114, 12.1656621],
                    [108.7261145, 12.1653743],
                    [108.7261729, 12.1652399],
                    [108.7262397, 12.1651094],
                    [108.7263146, 12.1649831],
                    [108.7263974, 12.1648617],
                    [108.726546, 12.1646899],
                    [108.726629, 12.1646119],
                    [108.7269178, 12.1643886],
                    [108.7270691, 12.1642503],
                    [108.727189, 12.1641159],
                    [108.7272554, 12.164026],
                    [108.7273153, 12.1639319],
                    [108.7274263, 12.1637111],
                    [108.7275186, 12.1634563],
                    [108.7275543, 12.1633818],
                    [108.7276469, 12.1632445],
                    [108.7277647, 12.1631271],
                    [108.7279034, 12.1630341],
                    [108.727979, 12.1629978],
                    [108.7282174, 12.1629143],
                    [108.7283662, 12.162833],
                    [108.7284359, 12.1627851],
                    [108.7285844, 12.1626561],
                    [108.7287369, 12.1625267],
                    [108.7295651, 12.1621007],
                    [108.7301083, 12.1616709],
                    [108.7303752, 12.1614505],
                    [108.7310749, 12.1608415],
                    [108.7314159, 12.1605275],
                    [108.731613, 12.1602416],
                    [108.7317193, 12.160104],
                    [108.7319469, 12.1598406],
                    [108.7322243, 12.1595653],
                    [108.7323713, 12.1594361],
                    [108.7326808, 12.1591957],
                    [108.7328427, 12.1590849],
                    [108.7332883, 12.1588052],
                    [108.7338595, 12.158475],
                    [108.7341512, 12.1583204],
                    [108.7347371, 12.1580367],
                    [108.7353273, 12.1577849],
                    [108.7356269, 12.1576695],
                    [108.736328, 12.1577503],
                    [108.7370324, 12.1577954],
                    [108.73739, 12.1578046],
                    [108.7377477, 12.1578046],
                    [108.7381938, 12.1577925],
                    [108.7382818, 12.1577828],
                    [108.738454, 12.1577429],
                    [108.7386368, 12.1576669],
                    [108.7388028, 12.1575603],
                    [108.7389495, 12.157431],
                    [108.7390275, 12.157373],
                    [108.7391112, 12.157323],
                    [108.739278, 12.1572531],
                    [108.7394407, 12.1572167],
                    [108.7395238, 12.1572089],
                    [108.7397373, 12.1572127],
                    [108.7399954, 12.1572434],
                    [108.7403236, 12.1573164],
                    [108.7404764, 12.157326],
                    [108.7406415, 12.1573087],
                    [108.7408009, 12.1572631],
                    [108.7410508, 12.1571661],
                    [108.7411791, 12.1571267],
                    [108.7414087, 12.1570729],
                    [108.7415251, 12.1570533],
                    [108.7416378, 12.1570166],
                    [108.7417033, 12.1569826],
                    [108.7417638, 12.1569406],
                    [108.7418619, 12.1568603],
                    [108.7419752, 12.1568008],
                    [108.7420362, 12.1567797],
                    [108.7422149, 12.156744],
                    [108.7423311, 12.1566988],
                    [108.7424414, 12.1566271],
                    [108.7425336, 12.1565342],
                    [108.7430164, 12.1565158],
                    [108.7434134, 12.156487],
                    [108.7438291, 12.1565184],
                    [108.7441277, 12.1565065],
                    [108.7442771, 12.1565132],
                    [108.7444332, 12.1565293],
                    [108.7445881, 12.1565547],
                    [108.7447411, 12.1565892],
                    [108.74502, 12.1567046],
                    [108.7451284, 12.1567292],
                    [108.7452381, 12.1567471],
                    [108.7454599, 12.1567623],
                    [108.7456894, 12.1567489],
                    [108.7458029, 12.1567313],
                    [108.7460252, 12.1566743],
                    [108.7461331, 12.1566352],
                    [108.7463397, 12.1565368],
                    [108.7464264, 12.1564816],
                    [108.7465838, 12.1563506],
                    [108.7466299, 12.1563207],
                    [108.7467312, 12.1562772],
                    [108.7467849, 12.1562641],
                    [108.7470532, 12.1562983],
                    [108.7472329, 12.1563427],
                    [108.747949, 12.156348],
                    [108.7482977, 12.1563952],
                    [108.7484999, 12.1564352],
                    [108.7487, 12.1564843],
                    [108.7488287, 12.1564964],
                    [108.7489451, 12.1564776],
                    [108.7491278, 12.1563966],
                    [108.7492847, 12.1562956],
                    [108.7493587, 12.1562333],
                    [108.7494886, 12.156091],
                    [108.7498131, 12.1559704],
                    [108.7500948, 12.1558288],
                    [108.7519938, 12.1561173],
                    [108.7522809, 12.1560028],
                    [108.7524285, 12.1559566],
                    [108.7527045, 12.1558917],
                    [108.7529594, 12.155855],
                    [108.7536638, 12.1558073],
                    [108.7539861, 12.15576],
                    [108.7541449, 12.1557239],
                    [108.7543071, 12.1556779],
                    [108.7544664, 12.1556233],
                    [108.7546225, 12.1555602],
                    [108.7547747, 12.1554888],
                    [108.7549227, 12.1554093],
                    [108.7552178, 12.155231],
                    [108.7553626, 12.1551853],
                    [108.7554377, 12.1551733],
                    [108.7555135, 12.1551688],
                    [108.7556644, 12.1551819],
                    [108.7561911, 12.1553352],
                    [108.7565643, 12.155425],
                    [108.7566776, 12.1555256],
                    [108.7568915, 12.1557397],
                    [108.7570953, 12.1559809],
                    [108.7576076, 12.1567492],
                    [108.7576576, 12.1568339],
                    [108.7577417, 12.1570114],
                    [108.7577794, 12.1571151],
                    [108.7578329, 12.1573286],
                    [108.7579644, 12.1580628],
                    [108.7584311, 12.1593581],
                    [108.7586027, 12.159657],
                    [108.7591309, 12.1598162],
                    [108.7592679, 12.1598353],
                    [108.7593434, 12.1598366],
                    [108.7614895, 12.1595608],
                    [108.7616994, 12.1595003],
                    [108.7617999, 12.1594577],
                    [108.761971, 12.1593614],
                    [108.7621259, 12.1592417],
                    [108.7622613, 12.1591011],
                    [108.7623842, 12.1589244],
                    [108.7625148, 12.158753],
                    [108.7626529, 12.1585872],
                    [108.7629433, 12.1582804],
                    [108.7630981, 12.1581362],
                    [108.7639618, 12.157722],
                    [108.7646055, 12.1571556],
                    [108.7648201, 12.156584],
                    [108.7648845, 12.1560229],
                    [108.7649512, 12.1558668],
                    [108.7649918, 12.1557921],
                    [108.7650522, 12.1556977],
                    [108.7651203, 12.1556083],
                    [108.7651956, 12.1555247],
                    [108.7656246, 12.1552109],
                    [108.765834, 12.1550474],
                    [108.7663275, 12.1546332],
                    [108.7673038, 12.153642],
                    [108.7675881, 12.153406],
                    [108.7678081, 12.1532487],
                    [108.7681299, 12.1532172],
                    [108.7686449, 12.153836],
                    [108.7686653, 12.153933],
                    [108.7687244, 12.1541221],
                    [108.7688169, 12.1543203],
                    [108.768879, 12.1544226],
                    [108.7690258, 12.1546122],
                    [108.7693316, 12.1549478],
                    [108.7694959, 12.1550562],
                    [108.769809, 12.1552939],
                    [108.7699583, 12.1554239],
                    [108.7702382, 12.155703],
                    [108.7703966, 12.1557942],
                    [108.7707026, 12.1559936],
                    [108.7710175, 12.1562354],
                    [108.7711792, 12.1563764],
                    [108.7713343, 12.1565241],
                    [108.7714827, 12.1566784],
                    [108.7717026, 12.1572972],
                    [108.7717456, 12.157895],
                    [108.771818, 12.1580666],
                    [108.7718816, 12.1582416],
                    [108.771936, 12.1584194],
                    [108.7719749, 12.1585722],
                    [108.7720327, 12.1588819],
                    [108.7720637, 12.1591506],
                    [108.7720696, 12.1592634],
                    [108.7720621, 12.1594892],
                    [108.7720206, 12.1597496],
                    [108.7719336, 12.1600857],
                    [108.7719185, 12.1601922],
                    [108.7719119, 12.1604069],
                    [108.7719202, 12.1605121],
                    [108.7719593, 12.1607194],
                    [108.77199, 12.1608205],
                    [108.7720728, 12.1610152],
                    [108.7725502, 12.1618595],
                    [108.7726157, 12.1619963],
                    [108.7726548, 12.1620614],
                    [108.7727441, 12.1621831],
                    [108.7727943, 12.1622397],
                    [108.7729591, 12.1622916],
                    [108.7730378, 12.1623269],
                    [108.7731775, 12.1624095],
                    [108.7732959, 12.1625045],
                    [108.7735426, 12.1627458],
                    [108.7738495, 12.1630775],
                    [108.7739955, 12.1632496],
                    [108.7742722, 12.1636058],
                    [108.7745136, 12.1639571],
                    [108.7745857, 12.1641392],
                    [108.7746321, 12.1642257],
                    [108.7747433, 12.1643853],
                    [108.774876, 12.1645251],
                    [108.7750286, 12.1646441],
                    [108.775108, 12.1646873],
                    [108.7752778, 12.1647505],
                    [108.7753665, 12.1647699],
                    [108.7755306, 12.1647836],
                    [108.7756947, 12.1647693],
                    [108.7757751, 12.1647517],
                    [108.7760454, 12.1646579],
                    [108.7761636, 12.1646282],
                    [108.7762839, 12.1646074],
                    [108.7766486, 12.1644501],
                    [108.7769383, 12.1643032],
                    [108.7770966, 12.1642365],
                    [108.7771797, 12.1642141],
                    [108.7773503, 12.1641921],
                    [108.7774364, 12.1641926],
                    [108.7776066, 12.1642168],
                    [108.7776893, 12.1642403],
                    [108.7777753, 12.1642937],
                    [108.7778659, 12.1643392],
                    [108.7779602, 12.1643766],
                    [108.7781175, 12.1644188],
                    [108.7782794, 12.1644382],
                    [108.7787005, 12.1642613],
                    [108.778818, 12.1642436],
                    [108.7789375, 12.1642478],
                    [108.7789968, 12.1642582],
                    [108.7791109, 12.1642954],
                    [108.779422, 12.1644881],
                    [108.779569, 12.1644912],
                    [108.779725, 12.1645156],
                    [108.7798861, 12.1645654],
                    [108.7800577, 12.1646467],
                    [108.780216, 12.1646152],
                    [108.7803568, 12.1645593],
                    [108.7804227, 12.1645223],
                    [108.7804851, 12.1644796],
                    [108.7806134, 12.1643737],
                    [108.7807363, 12.1642403],
                    [108.7808287, 12.1640952],
                    [108.7808651, 12.1640174],
                    [108.7809565, 12.1638834],
                    [108.7811755, 12.1635992],
                    [108.781305, 12.1634511],
                    [108.7816126, 12.1631625],
                    [108.7819421, 12.1628822],
                    [108.7822935, 12.1626121],
                    [108.7824744, 12.1624836],
                    [108.7831664, 12.1614583],
                    [108.7832627, 12.1612531],
                    [108.7832991, 12.1611458],
                    [108.783346, 12.1609334],
                    [108.7833619, 12.1607253],
                    [108.7833589, 12.160621],
                    [108.7833367, 12.1604164],
                    [108.7833367, 12.1602139],
                    [108.7833488, 12.1601133],
                    [108.7833957, 12.1599198],
                    [108.7834488, 12.1597799],
                    [108.7835092, 12.1596824],
                    [108.7836035, 12.1595928],
                    [108.7837189, 12.1595312],
                    [108.783841, 12.1595063],
                    [108.7839033, 12.1595027],
                    [108.7840254, 12.1595127],
                    [108.7841412, 12.1595443],
                    [108.7848777, 12.1599035],
                    [108.7851942, 12.1600923],
                    [108.7855021, 12.160239],
                    [108.7856609, 12.160302],
                    [108.7857925, 12.1603195],
                    [108.7860526, 12.1603284],
                    [108.7861799, 12.1603204],
                    [108.7862778, 12.1602693],
                    [108.7863395, 12.160226],
                    [108.7864239, 12.1602003],
                    [108.7866114, 12.1601986],
                    [108.7868276, 12.1602312],
                    [108.7868868, 12.160233],
                    [108.7870051, 12.1602138],
                    [108.7870622, 12.1601927],
                    [108.787116, 12.1601644],
                    [108.7872166, 12.1600885],
                    [108.7873331, 12.1599624],
                    [108.7873783, 12.1598851],
                    [108.7873971, 12.1598352],
                    [108.787415, 12.1597304],
                    [108.7874933, 12.1596576],
                    [108.7875384, 12.1596284],
                    [108.7876423, 12.1595851],
                    [108.7877589, 12.1595677],
                    [108.7878762, 12.1595798],
                    [108.7883136, 12.1597173],
                    [108.7884924, 12.1597598],
                    [108.7888554, 12.1598196],
                    [108.7897727, 12.1598196],
                    [108.7898581, 12.1598129],
                    [108.7900248, 12.159775],
                    [108.7901046, 12.159744],
                    [108.7902528, 12.1596596],
                    [108.7907294, 12.159344],
                    [108.7908724, 12.1592763],
                    [108.7910065, 12.1592375],
                    [108.7912352, 12.1590441],
                    [108.7913096, 12.1588921],
                    [108.7913352, 12.1588115],
                    [108.7913613, 12.1586488],
                    [108.7913559, 12.1584882],
                    [108.7913418, 12.1584089],
                    [108.7911111, 12.158034],
                    [108.7911004, 12.1576826],
                    [108.7910611, 12.1575388],
                    [108.7910336, 12.1574694],
                    [108.7909562, 12.1573254],
                    [108.7909048, 12.1572527],
                    [108.7907839, 12.1571215],
                    [108.7903896, 12.1568567],
                    [108.7902712, 12.1567403],
                    [108.7901804, 12.1566023],
                    [108.7901278, 12.1564722],
                    [108.7901107, 12.1564041],
                    [108.7900935, 12.1562495],
                    [108.7900936, 12.156164],
                    [108.7901133, 12.155994],
                    [108.7901341, 12.1559059],
                    [108.7901965, 12.1557357],
                    [108.7903494, 12.1554958],
                    [108.790395, 12.155408],
                    [108.7904191, 12.1553123],
                    [108.7904226, 12.1552614],
                    [108.7904111, 12.1551602],
                    [108.7902904, 12.1548796],
                    [108.7901864, 12.1546917],
                    [108.7900561, 12.1545202],
                    [108.7897888, 12.1542582],
                    [108.7896873, 12.1541444],
                    [108.789593, 12.1540248],
                    [108.7895204, 12.1539153],
                    [108.7894926, 12.1538559],
                    [108.7894566, 12.153736],
                    [108.7894441, 12.1536171],
                    [108.7894535, 12.1534165],
                    [108.7894855, 12.1531396],
                    [108.7895393, 12.1528659],
                    [108.7895855, 12.1527638],
                    [108.7896198, 12.152719],
                    [108.7896979, 12.1526525],
                    [108.7897915, 12.1526089],
                    [108.7897807, 12.1522366],
                    [108.7895286, 12.151366],
                    [108.7893951, 12.1512696],
                    [108.7893357, 12.1512129],
                    [108.7892416, 12.1510961],
                    [108.7891746, 12.150978],
                    [108.7889868, 12.1508049],
                    [108.788868, 12.1506612],
                    [108.7888151, 12.1505846],
                    [108.7886567, 12.150428],
                    [108.7885201, 12.1502647],
                    [108.7882787, 12.1499553],
                    [108.7878978, 12.1482142],
                    [108.7877798, 12.1472126],
                    [108.7875974, 12.1464888],
                    [108.7874971, 12.1462962],
                    [108.7874365, 12.1462057],
                    [108.7873041, 12.1460471],
                    [108.7872299, 12.1459749],
                    [108.7872859, 12.1457911],
                    [108.7873171, 12.1456117],
                    [108.7873265, 12.14544],
                    [108.7873211, 12.1446691],
                    [108.7873962, 12.1443439],
                    [108.7874281, 12.1440717],
                    [108.7874338, 12.1439348],
                    [108.7874661, 12.1437391],
                    [108.787533, 12.143552],
                    [108.7876325, 12.1433513],
                    [108.7876752, 12.1432478],
                    [108.787761, 12.1429804],
                    [108.788309, 12.1423173],
                    [108.788364, 12.1422322],
                    [108.788394, 12.142136],
                    [108.7884101, 12.1419603],
                    [108.7884318, 12.1418667],
                    [108.7884624, 12.1417755],
                    [108.7883927, 12.1416974],
                    [108.7883488, 12.141668],
                    [108.7882492, 12.1416326],
                    [108.7881532, 12.1416175],
                    [108.7880633, 12.1415828],
                    [108.7879837, 12.1415303],
                    [108.7877918, 12.1413808],
                    [108.78769, 12.141314],
                    [108.7874967, 12.141207],
                    [108.7873962, 12.1411606],
                    [108.7873358, 12.1411167],
                    [108.7872956, 12.1410544],
                    [108.7872809, 12.1409822],
                    [108.7872915, 12.1409161],
                    [108.7873051, 12.1408852],
                    [108.7875201, 12.1406773],
                    [108.787571, 12.1405957],
                    [108.7875992, 12.1405039],
                    [108.7876034, 12.1404558],
                    [108.787591, 12.1403604],
                    [108.7875746, 12.1403149],
                    [108.7875161, 12.1402249],
                    [108.7874767, 12.1401877],
                    [108.787234, 12.1402638],
                    [108.7870972, 12.140235],
                    [108.7869568, 12.1401754],
                    [108.7868868, 12.1401315],
                    [108.7867703, 12.1400304],
                    [108.7866855, 12.139915],
                    [108.7866373, 12.1398085],
                    [108.7866211, 12.1397524],
                    [108.7865031, 12.1389631],
                    [108.7862548, 12.1383751],
                    [108.7862349, 12.138242],
                    [108.7862383, 12.1381733],
                    [108.7862509, 12.1381057],
                    [108.7864147, 12.1379758],
                    [108.7865031, 12.1379195],
                    [108.7867053, 12.1378193],
                    [108.786875, 12.1377574],
                    [108.7869899, 12.1376879],
                    [108.7870394, 12.1376428],
                    [108.7871163, 12.1375391],
                    [108.7871629, 12.1374239],
                    [108.787187, 12.1371223],
                    [108.7871815, 12.1370399],
                    [108.7871437, 12.136879],
                    [108.7871119, 12.1368024],
                    [108.7870251, 12.1366623],
                    [108.7869102, 12.136543],
                    [108.7868437, 12.136493],
                    [108.7864146, 12.1362045],
                    [108.7860391, 12.1356093],
                    [108.7859577, 12.1354411],
                    [108.7859086, 12.1352599],
                    [108.7858828, 12.1350233],
                    [108.7858298, 12.1347413],
                    [108.7857886, 12.1345951],
                    [108.7857385, 12.1344516],
                    [108.7856796, 12.1343112],
                    [108.78575, 12.1334477],
                    [108.7857406, 12.1332204],
                    [108.7857252, 12.1331076],
                    [108.7856791, 12.1329051],
                    [108.7856474, 12.1328061],
                    [108.7853712, 12.1322606],
                    [108.7853214, 12.132123],
                    [108.7853095, 12.1320508],
                    [108.785311, 12.1319145],
                    [108.7853233, 12.1318474],
                    [108.7853705, 12.1317191],
                    [108.785457, 12.1315605],
                    [108.7854832, 12.1314974],
                    [108.7855182, 12.1313658],
                    [108.7855293, 12.1312298],
                    [108.785516, 12.1310937],
                    [108.7853935, 12.1308653],
                    [108.785339, 12.1307279],
                    [108.7853149, 12.1306413],
                    [108.7852455, 12.1304897],
                    [108.7851995, 12.1304198],
                    [108.7847823, 12.1298937],
                    [108.7846753, 12.1297204],
                    [108.784596, 12.1295334],
                    [108.7845507, 12.1293612],
                    [108.784529, 12.1291846],
                    [108.7844914, 12.1278761],
                    [108.7844565, 12.127785],
                    [108.7844304, 12.1276912],
                    [108.7844134, 12.1275954],
                    [108.7844056, 12.1274985],
                    [108.7844161, 12.1273152],
                    [108.7844707, 12.1270813],
                    [108.7844753, 12.1269688],
                    [108.7844678, 12.1269096],
                    [108.7844324, 12.1267957],
                    [108.7842333, 12.1264707],
                    [108.7841452, 12.126302],
                    [108.7839928, 12.1259536],
                    [108.7838732, 12.1255933],
                    [108.7838192, 12.125364],
                    [108.783792, 12.1251301],
                    [108.7837935, 12.1248735],
                    [108.7838078, 12.1247352],
                    [108.7838316, 12.1245981],
                    [108.7840462, 12.123995],
                    [108.7840402, 12.123721],
                    [108.7840501, 12.1235842],
                    [108.7840957, 12.1233138],
                    [108.7841312, 12.1231812],
                    [108.7842221, 12.122934],
                    [108.7843365, 12.122709],
                    [108.7844032, 12.1226015],
                    [108.7845547, 12.1223985],
                    [108.7846389, 12.1223035],
                    [108.7847769, 12.1221795],
                    [108.7848364, 12.1221086],
                    [108.784934, 12.1219521],
                    [108.7849716, 12.1218668],
                    [108.7850215, 12.1216877],
                    [108.7850332, 12.1215955],
                    [108.7850788, 12.120911],
                    [108.7874001, 12.1198827],
                    [108.7875012, 12.1198194],
                    [108.7875465, 12.1197808],
                    [108.7876353, 12.1196752],
                    [108.7876698, 12.1196156],
                    [108.7877262, 12.1193533],
                    [108.7879407, 12.1187659],
                    [108.7885657, 12.1180814],
                    [108.7887011, 12.1176986],
                    [108.7886569, 12.1171138],
                    [108.7885618, 12.1164026],
                    [108.7885576, 12.1161697],
                    [108.7885679, 12.1160304],
                    [108.7885876, 12.1158921],
                    [108.7886167, 12.1157553],
                    [108.7887229, 12.1155238],
                    [108.7888205, 12.1152885],
                    [108.7893194, 12.1139248],
                    [108.7895621, 12.1136508],
                    [108.7897378, 12.1134397],
                    [108.7902401, 12.1132201],
                    [108.7905832, 12.1130571],
                    [108.7909219, 12.1128855],
                    [108.7911425, 12.1127616],
                    [108.7914218, 12.1126318],
                    [108.7915912, 12.1125664],
                    [108.7926078, 12.1122543],
                    [108.7927955, 12.1120996],
                    [108.7927105, 12.1115304],
                    [108.7926453, 12.1108644],
                    [108.7926144, 12.1106361],
                    [108.7925488, 12.1102507],
                    [108.7926235, 12.109923],
                    [108.7926722, 12.1096359],
                    [108.7928175, 12.1083336],
                    [108.7928526, 12.1082243],
                    [108.7929042, 12.1081239],
                    [108.7934755, 12.1072492],
                    [108.7936646, 12.1069149],
                    [108.7937923, 12.1066457],
                    [108.7938866, 12.1064093],
                    [108.7939153, 12.1063077],
                    [108.7939453, 12.1061223],
                    [108.793946, 12.1044311],
                    [108.7939301, 12.1041376],
                    [108.7938791, 12.1037599],
                    [108.7936252, 12.1024708],
                    [108.7936244, 12.1023248],
                    [108.793654, 12.1021816],
                    [108.7937129, 12.1020474],
                    [108.7938071, 12.101918],
                    [108.7939284, 12.1018123],
                    [108.7940706, 12.1017354],
                    [108.7964393, 12.1009655],
                    [108.7965555, 12.1009155],
                    [108.7966096, 12.1008829],
                    [108.7967144, 12.1007976],
                    [108.7967598, 12.1007479],
                    [108.796816, 12.1006414],
                    [108.7968476, 12.1005256],
                    [108.7968525, 12.1003959],
                    [108.7968424, 12.1003267],
                    [108.7967961, 12.1001945],
                    [108.7963897, 12.0994326],
                    [108.7963446, 12.0992851],
                    [108.7963326, 12.0992089],
                    [108.7963307, 12.099055],
                    [108.7963563, 12.0989087],
                    [108.7964081, 12.0987693],
                    [108.7969241, 12.0978739],
                    [108.7969798, 12.0977646],
                    [108.797043, 12.0976149],
                    [108.7971464, 12.0973073],
                    [108.7971864, 12.0971501],
                    [108.7972426, 12.096831],
                    [108.7972594, 12.0966596],
                    [108.7972654, 12.0963156],
                    [108.7972547, 12.0961438],
                    [108.7972204, 12.0959957],
                    [108.7971936, 12.0959243],
                    [108.7971164, 12.0957806],
                    [108.7970066, 12.0956455],
                    [108.7962502, 12.0950213],
                    [108.796041, 12.0950108],
                    [108.7958687, 12.0949273],
                    [108.7957781, 12.0948954],
                    [108.7956006, 12.0948541],
                    [108.7955099, 12.094843],
                    [108.7950379, 12.0946437],
                    [108.7943459, 12.0945807],
                    [108.7939101, 12.0943609],
                    [108.7938067, 12.0942817],
                    [108.7937264, 12.0941875],
                    [108.7936553, 12.0940523],
                    [108.7936014, 12.0938784],
                    [108.7935808, 12.0937069],
                    [108.7935895, 12.0935434],
                    [108.7937021, 12.0930648],
                    [108.7936941, 12.0926294],
                    [108.7938033, 12.0924186],
                    [108.7938496, 12.0923095],
                    [108.7939677, 12.0919475],
                    [108.7940305, 12.0916814],
                    [108.7940736, 12.0915515],
                    [108.7941632, 12.0913396],
                    [108.7942734, 12.0911371],
                    [108.7945088, 12.0908882],
                    [108.7945899, 12.0907909],
                    [108.7946973, 12.090637],
                    [108.7947448, 12.0905562],
                    [108.7946597, 12.0899517],
                    [108.7945899, 12.0897422],
                    [108.7945464, 12.0895261],
                    [108.7945305, 12.0893432],
                    [108.7945232, 12.0890677],
                    [108.7945306, 12.0887003],
                    [108.7945672, 12.0883158],
                    [108.794637, 12.0879169],
                    [108.7946838, 12.0877197],
                    [108.7948457, 12.0875586],
                    [108.7950245, 12.0874155],
                    [108.7952405, 12.0872796],
                    [108.7953544, 12.0872214],
                    [108.7961242, 12.087085],
                    [108.7966955, 12.0868962],
                    [108.7967991, 12.0868721],
                    [108.7970093, 12.0868385],
                    [108.7971278, 12.0868282],
                    [108.7975316, 12.0868116],
                    [108.7979722, 12.0866103],
                    [108.7985945, 12.0861041],
                    [108.7988538, 12.0857584],
                    [108.7989741, 12.0855792],
                    [108.7991831, 12.0852311],
                    [108.7993575, 12.0848933],
                    [108.7994367, 12.0847206],
                    [108.7994969, 12.0845077],
                    [108.7995174, 12.084399],
                    [108.7995386, 12.084179],
                    [108.799539, 12.0840572],
                    [108.7995159, 12.0838148],
                    [108.799461, 12.0835773],
                    [108.7993599, 12.0832972],
                    [108.7992156, 12.0829765],
                    [108.7990265, 12.0826337],
                    [108.7990102, 12.0825577],
                    [108.7990233, 12.0824799],
                    [108.7990652, 12.0824113],
                    [108.7990953, 12.082384],
                    [108.7991302, 12.0823628],
                    [108.799328, 12.0822792],
                    [108.7994927, 12.0821835],
                    [108.7996567, 12.0820573],
                    [108.7997317, 12.0819864],
                    [108.800388, 12.0812306],
                    [108.8009401, 12.0803891],
                    [108.8009991, 12.0802684],
                    [108.8011533, 12.0799091],
                    [108.8011962, 12.0797255],
                    [108.8012083, 12.079617],
                    [108.801211, 12.0795078],
                    [108.8018829, 12.0780993],
                    [108.8022195, 12.07688],
                    [108.8022718, 12.0766023],
                    [108.8022902, 12.0763818],
                    [108.8022974, 12.075757],
                    [108.8022771, 12.0752431],
                    [108.8022543, 12.0749524],
                    [108.8021838, 12.0743735],
                    [108.8021363, 12.0740857],
                    [108.802017, 12.0735146],
                    [108.8016066, 12.0727172],
                    [108.8014644, 12.0724025],
                    [108.8013786, 12.0722399],
                    [108.8012762, 12.0720997],
                    [108.8009521, 12.0717048],
                    [108.8008921, 12.0715987],
                    [108.8008575, 12.0714822],
                    [108.8008502, 12.0713612],
                    [108.800871, 12.0712397],
                    [108.8008918, 12.0711814],
                    [108.8009529, 12.0710736],
                    [108.8009924, 12.0710255],
                    [108.8012311, 12.0708917],
                    [108.8013783, 12.0708613],
                    [108.8015048, 12.0708595],
                    [108.8016098, 12.0708445],
                    [108.8018534, 12.0707632],
                    [108.8019365, 12.0707238],
                    [108.8019927, 12.0706864],
                    [108.8023549, 12.0703383],
                    [108.8027491, 12.069992],
                    [108.8027921, 12.0699448],
                    [108.802866, 12.0698424],
                    [108.8031837, 12.0692734],
                    [108.8038141, 12.0682111],
                    [108.8040266, 12.0678278],
                    [108.8041674, 12.0675521],
                    [108.8050345, 12.0655816],
                    [108.8054837, 12.0647304],
                    [108.8065821, 12.0630937],
                    [108.8067242, 12.0627055],
                    [108.8068302, 12.0623855],
                    [108.8072445, 12.0609109],
                    [108.8073881, 12.0604449],
                    [108.8074779, 12.0601795],
                    [108.8081029, 12.0585821],
                    [108.8084918, 12.0582438],
                    [108.8095952, 12.057578],
                    [108.8094817, 12.0574049],
                    [108.8094145, 12.0573257],
                    [108.8092922, 12.0572096],
                    [108.8092254, 12.0571578],
                    [108.8091339, 12.0571551],
                    [108.8090433, 12.0571428],
                    [108.8089545, 12.0571211],
                    [108.8088214, 12.0570687],
                    [108.8087587, 12.0570345],
                    [108.8084221, 12.0567591],
                    [108.808323, 12.0566391],
                    [108.8082504, 12.0565021],
                    [108.8082241, 12.0564254],
                    [108.8081968, 12.056266],
                    [108.8078307, 12.0554728],
                    [108.8077033, 12.0552246],
                    [108.8076013, 12.0550505],
                    [108.8071936, 12.0544403],
                    [108.8067725, 12.0534488],
                    [108.8060832, 12.0526147],
                    [108.805449, 12.0521887],
                    [108.8043103, 12.0513556],
                    [108.8040337, 12.051259],
                    [108.8038918, 12.0512218],
                    [108.8036015, 12.0511703],
                    [108.8034546, 12.0511562],
                    [108.80264, 12.0513841],
                    [108.8025464, 12.0513885],
                    [108.8024598, 12.0513687],
                    [108.8024193, 12.0513503],
                    [108.8022155, 12.0511379],
                    [108.8022105, 12.0503641],
                    [108.8021869, 12.050177],
                    [108.8021313, 12.0499965],
                    [108.8020446, 12.0498262],
                    [108.8019285, 12.0496716],
                    [108.801789, 12.0495588],
                    [108.8016962, 12.0494649],
                    [108.801628, 12.0493527],
                    [108.8016042, 12.0492916],
                    [108.8015878, 12.0492283],
                    [108.8015776, 12.0491075],
                    [108.8015825, 12.049047],
                    [108.801629, 12.0488301],
                    [108.8016402, 12.0487301],
                    [108.8016455, 12.0485292],
                    [108.8016314, 12.0483719],
                    [108.801616, 12.0482944],
                    [108.8015708, 12.0481484],
                    [108.8013142, 12.0475495],
                    [108.8012923, 12.047385],
                    [108.801232, 12.0470786],
                    [108.8011533, 12.0467966],
                    [108.8011333, 12.0467521],
                    [108.8010755, 12.0466733],
                    [108.8010339, 12.0466366],
                    [108.800936, 12.0465841],
                    [108.8003687, 12.0465037],
                    [108.800201, 12.0464488],
                    [108.8000478, 12.0463626],
                    [108.7999196, 12.0462531],
                    [108.7998187, 12.0461272],
                    [108.7997431, 12.0459854],
                    [108.7996434, 12.0457046],
                    [108.7995984, 12.0454918],
                    [108.7995864, 12.0453838],
                    [108.7995842, 12.0451577],
                    [108.7996142, 12.0449248],
                    [108.7996415, 12.0448104],
                    [108.7996882, 12.0446627],
                    [108.7997277, 12.0445997],
                    [108.7997571, 12.0445718],
                    [108.7998283, 12.0445328],
                    [108.8000174, 12.0445158],
                    [108.800186, 12.0444781],
                    [108.8002682, 12.0444515],
                    [108.8003835, 12.0443911],
                    [108.8004453, 12.0443699],
                    [108.8005739, 12.0443466],
                    [108.8007136, 12.0443507],
                    [108.800849, 12.0443844],
                    [108.8009736, 12.0444463],
                    [108.801621, 12.0449502],
                    [108.8016517, 12.0449672],
                    [108.8017192, 12.0449866],
                    [108.8018015, 12.0449834],
                    [108.8020384, 12.0448948],
                    [108.8021323, 12.0448922],
                    [108.8022142, 12.0449143],
                    [108.8022866, 12.0449578],
                    [108.8025588, 12.0452358],
                    [108.8026193, 12.0452846],
                    [108.8027511, 12.0453675],
                    [108.8028216, 12.0454011],
                    [108.8029635, 12.0454499],
                    [108.8031113, 12.0454772],
                    [108.8033017, 12.0454273],
                    [108.8034982, 12.0454627],
                    [108.803894, 12.0455152],
                    [108.8043396, 12.0455448],
                    [108.8045865, 12.045548],
                    [108.8048735, 12.0452372],
                    [108.8050184, 12.0451099],
                    [108.8053671, 12.0451139],
                    [108.8057506, 12.0447873],
                    [108.8058737, 12.0447118],
                    [108.8059928, 12.0446701],
                    [108.8060993, 12.0446535],
                    [108.8064373, 12.0444856],
                    [108.8065249, 12.0444553],
                    [108.8067037, 12.0444118],
                    [108.8068852, 12.0443912],
                    [108.8069811, 12.0443274],
                    [108.8070225, 12.0442874],
                    [108.8070586, 12.0442429],
                    [108.8071124, 12.0441448],
                    [108.8071297, 12.0440927],
                    [108.8071574, 12.0439142],
                    [108.8071764, 12.0438453],
                    [108.8072019, 12.0437785],
                    [108.8072852, 12.0436257],
                    [108.8073036, 12.043578],
                    [108.807326, 12.0434686],
                    [108.8073277, 12.0432449],
                    [108.807376, 12.0431898],
                    [108.8074567, 12.0431318],
                    [108.8075642, 12.0430918],
                    [108.8076228, 12.0430639],
                    [108.807676, 12.0430284],
                    [108.8077234, 12.0429858],
                    [108.8077641, 12.0429369],
                    [108.8078397, 12.0427967],
                    [108.8079017, 12.0426153],
                    [108.8079221, 12.0425132],
                    [108.8079329, 12.0424098],
                    [108.807942, 12.0421444],
                    [108.8079974, 12.0420848],
                    [108.808075, 12.0420365],
                    [108.8081728, 12.0420099],
                    [108.8083064, 12.042011],
                    [108.8083886, 12.0420215],
                    [108.8085481, 12.0420644],
                    [108.808988, 12.0419464],
                    [108.8091222, 12.0419719],
                    [108.809393, 12.0420067],
                    [108.8097176, 12.0420198],
                    [108.8098228, 12.0420009],
                    [108.8099315, 12.0419544],
                    [108.8101653, 12.041774],
                    [108.810313, 12.0416972],
                    [108.8103927, 12.0416685],
                    [108.8105583, 12.0416329],
                    [108.8112003, 12.0415683],
                    [108.811351, 12.0415288],
                    [108.8114235, 12.0415004],
                    [108.81157, 12.041421],
                    [108.8116376, 12.0413723],
                    [108.8120887, 12.0409496],
                    [108.8121926, 12.0408841],
                    [108.8123144, 12.040842],
                    [108.812381, 12.0408315],
                    [108.812572, 12.0408676],
                    [108.8127646, 12.0408945],
                    [108.8129192, 12.0409094],
                    [108.8132295, 12.0409215],
                    [108.8133848, 12.0409187],
                    [108.8146823, 12.0408451],
                    [108.8148777, 12.0408488],
                    [108.8151081, 12.0408663],
                    [108.8152188, 12.040863],
                    [108.8154344, 12.0408298],
                    [108.8155393, 12.0408],
                    [108.8158126, 12.0407023],
                    [108.8160744, 12.0405784],
                    [108.816152, 12.0405604],
                    [108.8162463, 12.0405611],
                    [108.8162997, 12.0405724],
                    [108.8164107, 12.0406132],
                    [108.8164733, 12.0406264],
                    [108.8165371, 12.0406309],
                    [108.8166457, 12.040618],
                    [108.8167595, 12.0405763],
                    [108.8168718, 12.0405058],
                    [108.8169655, 12.0404129],
                    [108.8170648, 12.0402556],
                    [108.8171044, 12.0402098],
                    [108.8171898, 12.04014],
                    [108.8172378, 12.0401124],
                    [108.8172887, 12.0400901],
                    [108.8177422, 12.0399425],
                    [108.8181397, 12.0398032],
                    [108.8185045, 12.0396249],
                    [108.8186211, 12.0395797],
                    [108.818766, 12.0395475],
                    [108.8189736, 12.0395257],
                    [108.8190901, 12.0394877],
                    [108.8191449, 12.0394602],
                    [108.8192567, 12.0393788],
                    [108.8193046, 12.0393293],
                    [108.8193816, 12.0392156],
                    [108.8190919, 12.0388694],
                    [108.8190072, 12.0387127],
                    [108.8189732, 12.0386304],
                    [108.8189451, 12.0385461],
                    [108.8189068, 12.0383729],
                    [108.8188931, 12.0381963],
                    [108.8189042, 12.0380194],
                    [108.8192689, 12.0368233],
                    [108.8195252, 12.0358874],
                    [108.8196444, 12.0354172],
                    [108.8197349, 12.0352441],
                    [108.8197893, 12.0351627],
                    [108.8199263, 12.0350007],
                    [108.8200039, 12.0349279],
                    [108.8207273, 12.0343385],
                    [108.820817, 12.0342551],
                    [108.8209001, 12.0341654],
                    [108.8209761, 12.0340699],
                    [108.8211037, 12.0338665],
                    [108.8211984, 12.0336498],
                    [108.82126, 12.033422],
                    [108.8213239, 12.0328723],
                    [108.8213611, 12.0326575],
                    [108.8214425, 12.032308],
                    [108.8214926, 12.0321356],
                    [108.8215962, 12.0318289],
                    [108.8217151, 12.0315662],
                    [108.8218425, 12.0313521],
                    [108.8219914, 12.0311518],
                    [108.8342893, 12.0231978],
                    [108.8346702, 12.0226417],
                    [108.8349169, 12.0224108],
                    [108.8350069, 12.0222596],
                    [108.8351147, 12.022134],
                    [108.8352331, 12.0220341],
                    [108.8352978, 12.0219911],
                    [108.8357109, 12.0220225],
                    [108.8372987, 12.0216867],
                    [108.837446, 12.0216705],
                    [108.8377547, 12.0216568],
                    [108.8379157, 12.0216605],
                    [108.8390368, 12.0217235],
                    [108.8441223, 12.0205062],
                    [108.8460267, 12.0195303],
                    [108.8495875, 12.0187176],
                    [108.8507152, 12.0186803],
                    [108.851026, 12.018588],
                    [108.8513428, 12.0185177],
                    [108.8516647, 12.0184696],
                    [108.8519892, 12.0184442],
                    [108.8520402, 12.0183551],
                    [108.8520994, 12.0182709],
                    [108.8521662, 12.0181924],
                    [108.8522909, 12.0180776],
                    [108.8524318, 12.0179825],
                    [108.8526472, 12.0179034],
                    [108.8528001, 12.0178873],
                    [108.8530756, 12.0178941],
                    [108.8532742, 12.01789],
                    [108.8536702, 12.0178593],
                    [108.8540626, 12.0177989],
                    [108.8558221, 12.0175654],
                    [108.8583648, 12.0020474],
                    [108.8584077, 12.0017745],
                    [108.8584389, 12.0012867],
                    [108.8584365, 12.000798],
                    [108.8584006, 12.0003105],
                    [108.8583314, 11.9998265],
                    [108.8582269, 11.9993394],
                    [108.8581607, 11.9990944],
                    [108.8580489, 11.9987408],
                    [108.8579544, 11.9985265],
                    [108.8578048, 11.9982496],
                    [108.8577406, 11.9981062],
                    [108.8576334, 11.9978093],
                    [108.8575555, 11.9975019],
                    [108.857528, 11.9973459],
                    [108.8574951, 11.9970108],
                    [108.8574975, 11.9966741],
                    [108.8575119, 11.9965063],
                    [108.8577255, 11.9960582],
                    [108.8578793, 11.9957617],
                    [108.8579115, 11.9956825],
                    [108.8579481, 11.9955359],
                    [108.8579562, 11.9954608],
                    [108.8579518, 11.9953099],
                    [108.856194, 11.991149],
                    [108.8561015, 11.9909807],
                    [108.8560453, 11.9909024],
                    [108.8559171, 11.9907621],
                    [108.8557706, 11.9906447],
                    [108.8556909, 11.9905946],
                    [108.8556075, 11.9905506],
                    [108.8550818, 11.9903355],
                    [108.8548535, 11.9902673],
                    [108.8547434, 11.9902223],
                    [108.8545452, 11.9901184],
                    [108.8543732, 11.9899988],
                    [108.854203, 11.9898443],
                    [108.8541196, 11.9897509],
                    [108.8540436, 11.9896517],
                    [108.8539752, 11.9895473],
                    [108.853915, 11.9894382],
                    [108.8533062, 11.988861],
                    [108.853156, 11.9885933],
                    [108.8530504, 11.9884914],
                    [108.8528234, 11.9883047],
                    [108.8526123, 11.9881637],
                    [108.8523889, 11.9880424],
                    [108.8515225, 11.9874153],
                    [108.8512179, 11.9869498],
                    [108.8510246, 11.9866872],
                    [108.8508171, 11.9864524],
                    [108.8505996, 11.9862449],
                    [108.8504845, 11.986148],
                    [108.8476306, 11.9839178],
                    [108.847469, 11.9837371],
                    [108.847398, 11.983639],
                    [108.8472774, 11.9834298],
                    [108.8472283, 11.9833196],
                    [108.8471572, 11.9831041],
                    [108.8471149, 11.9828815],
                    [108.8471049, 11.9827686],
                    [108.8467738, 11.9820399],
                    [108.8466423, 11.9818067],
                    [108.8465631, 11.981685],
                    [108.8462412, 11.9810055],
                    [108.84534, 11.9799822],
                    [108.8453317, 11.9799161],
                    [108.845333, 11.9797831],
                    [108.8453587, 11.9796512],
                    [108.8454085, 11.9795247],
                    [108.8454419, 11.9794653],
                    [108.8455954, 11.9793684],
                    [108.8456672, 11.9793131],
                    [108.8458094, 11.9791793],
                    [108.8460052, 11.9791137],
                    [108.846112, 11.9790659],
                    [108.8463137, 11.9789484],
                    [108.8464441, 11.9787183],
                    [108.846598, 11.9785024],
                    [108.8467925, 11.9782837],
                    [108.846899, 11.9781827],
                    [108.847011, 11.9780878],
                    [108.8474804, 11.9779094],
                    [108.8476426, 11.9777943],
                    [108.8477169, 11.9777284],
                    [108.847856, 11.9775741],
                    [108.8479744, 11.9773925],
                    [108.8480222, 11.9772954],
                    [108.8482851, 11.9764243],
                    [108.8485157, 11.9759573],
                    [108.8486718, 11.9757772],
                    [108.8488032, 11.9755792],
                    [108.8489124, 11.9753556],
                    [108.8489572, 11.9752334],
                    [108.8489931, 11.9751084],
                    [108.84902, 11.9749812],
                    [108.8490951, 11.9744249],
                    [108.8490721, 11.9740614],
                    [108.8490736, 11.9738792],
                    [108.8491013, 11.9735285],
                    [108.8491273, 11.9733544],
                    [108.8491182, 11.9730744],
                    [108.8491273, 11.9729346],
                    [108.8491682, 11.9726768],
                    [108.8492399, 11.9724256],
                    [108.8482195, 11.9698922],
                    [108.848147, 11.9696689],
                    [108.8481188, 11.969555],
                    [108.8480723, 11.9692681],
                    [108.8480617, 11.9691231],
                    [108.848034, 11.9677722],
                    [108.8480575, 11.9675559],
                    [108.8480799, 11.9674493],
                    [108.8481455, 11.9672414],
                    [108.8481885, 11.9671411],
                    [108.848288, 11.9669586],
                    [108.8484097, 11.9667895],
                    [108.8484782, 11.9667107],
                    [108.8489342, 11.9663014],
                    [108.8495967, 11.9657399],
                    [108.8503611, 11.9650734],
                    [108.8505328, 11.964811],
                    [108.8512194, 11.9641183],
                    [108.85167, 11.9638979],
                    [108.8520563, 11.9642757],
                    [108.8521753, 11.9643428],
                    [108.8522989, 11.9644015],
                    [108.8525391, 11.9644874],
                    [108.8526539, 11.9645165],
                    [108.8528877, 11.9645539],
                    [108.8529798, 11.964577],
                    [108.8531585, 11.9646401],
                    [108.8533295, 11.9647259],
                    [108.8534112, 11.9647772],
                    [108.8534894, 11.9648337],
                    [108.8536984, 11.9650132],
                    [108.8537737, 11.9650626],
                    [108.8538533, 11.9651049],
                    [108.8540152, 11.9651646],
                    [108.8540995, 11.9651837],
                    [108.8542718, 11.9651993],
                    [108.8555968, 11.9652151],
                    [108.8557547, 11.96516],
                    [108.8558361, 11.9651403],
                    [108.8560239, 11.9651155],
                    [108.8562351, 11.9651206],
                    [108.8564926, 11.9651626],
                    [108.8566098, 11.9652778],
                    [108.8567448, 11.9653725],
                    [108.8568277, 11.965416],
                    [108.8570044, 11.9654792],
                    [108.85719, 11.965509],
                    [108.8577533, 11.965509],
                    [108.8578705, 11.9655613],
                    [108.8580966, 11.9656822],
                    [108.858338, 11.9658448],
                    [108.8588101, 11.966144],
                    [108.8596952, 11.9664483],
                    [108.8601619, 11.966674],
                    [108.8604355, 11.9668419],
                    [108.8606076, 11.9669327],
                    [108.8609936, 11.9671231],
                    [108.8612133, 11.9672093],
                    [108.8613541, 11.9672515],
                    [108.8614973, 11.9672848],
                    [108.8616425, 11.967309],
                    [108.8624203, 11.9673667],
                    [108.8634691, 11.9675897],
                    [108.8649496, 11.9677682],
                    [108.8653868, 11.9680227],
                    [108.8657355, 11.9673772],
                    [108.866154, 11.9669574],
                    [108.8662642, 11.9667314],
                    [108.8663256, 11.9666215],
                    [108.8664882, 11.9663695],
                    [108.8666827, 11.9661162],
                    [108.866905, 11.9658606],
                    [108.8675916, 11.9653253],
                    [108.8678438, 11.96516],
                    [108.867976, 11.9650871],
                    [108.868112, 11.9650209],
                    [108.868373, 11.9649166],
                    [108.868507, 11.9648738],
                    [108.8689311, 11.9647634],
                    [108.8690722, 11.9647165],
                    [108.8693799, 11.9645879],
                    [108.8695282, 11.9645119],
                    [108.8697535, 11.9643125],
                    [108.8698922, 11.9642292],
                    [108.8700399, 11.9641106],
                    [108.8701161, 11.9640333],
                    [108.8701853, 11.9639499],
                    [108.870247, 11.9638611],
                    [108.8702622, 11.9635607],
                    [108.8702806, 11.9634113],
                    [108.8703419, 11.963102],
                    [108.8703861, 11.9629432],
                    [108.8704991, 11.9626331],
                    [108.8707888, 11.9619351],
                    [108.870836, 11.9617048],
                    [108.8709176, 11.9614838],
                    [108.8710218, 11.9612926],
                    [108.8710837, 11.9612028],
                    [108.8712257, 11.9610365],
                    [108.8713051, 11.9609609],
                    [108.871698, 11.9606287],
                    [108.8720012, 11.9603608],
                    [108.8721156, 11.960215],
                    [108.8721656, 11.9601372],
                    [108.8722515, 11.9599692],
                    [108.8722868, 11.9598796],
                    [108.8723387, 11.9596945],
                    [108.8723678, 11.959497],
                    [108.8723928, 11.9589281],
                    [108.8724089, 11.9588661],
                    [108.8724629, 11.9587498],
                    [108.8725001, 11.9586972],
                    [108.8725846, 11.9586124],
                    [108.8726335, 11.9585774],
                    [108.8727415, 11.958524],
                    [108.8728488, 11.9584935],
                    [108.8730687, 11.9584558],
                    [108.8732975, 11.9584497],
                    [108.8736341, 11.9584904],
                    [108.8738546, 11.9584957],
                    [108.8739645, 11.9584872],
                    [108.8741767, 11.9584495],
                    [108.8743817, 11.9583844],
                    [108.8745761, 11.9582931],
                    [108.8747713, 11.9582917],
                    [108.8749471, 11.9583133],
                    [108.875649, 11.9584872],
                    [108.8757098, 11.958514],
                    [108.8758176, 11.9585856],
                    [108.8758635, 11.9586289],
                    [108.8760057, 11.9587969],
                    [108.8761394, 11.9589103],
                    [108.8769263, 11.9593319],
                    [108.8770483, 11.9593879],
                    [108.8773012, 11.9594791],
                    [108.8775424, 11.9595378],
                    [108.8777886, 11.9595715],
                    [108.8779128, 11.9595788],
                    [108.8783848, 11.9595831],
                    [108.8788569, 11.9595788],
                    [108.8795795, 11.9595558],
                    [108.8799405, 11.9595368],
                    [108.8805735, 11.9592954],
                    [108.8806411, 11.9591475],
                    [108.8806647, 11.9590698],
                    [108.8806915, 11.9588966],
                    [108.8806808, 11.957805],
                    [108.8808288, 11.9575655],
                    [108.8810027, 11.9573432],
                    [108.8811082, 11.9572295],
                    [108.8812206, 11.9571223],
                    [108.8813393, 11.9570219],
                    [108.881464, 11.9569286],
                    [108.8819955, 11.9567819],
                    [108.8822579, 11.9566977],
                    [108.8827461, 11.9565192],
                    [108.8835025, 11.9562568],
                    [108.8837331, 11.9561361],
                    [108.8839585, 11.9559839],
                    [108.8842853, 11.9554906],
                    [108.8843705, 11.955326],
                    [108.884436, 11.9551785],
                    [108.8844752, 11.9551112],
                    [108.8845727, 11.9549894],
                    [108.8846911, 11.9548892],
                    [108.8848268, 11.9548131],
                    [108.8850839, 11.9547269],
                    [108.8851906, 11.9546844],
                    [108.8853961, 11.9545827],
                    [108.8856002, 11.954452],
                    [108.8857877, 11.9542993],
                    [108.8863027, 11.9539267],
                    [108.886623, 11.9537238],
                    [108.8867885, 11.9536308],
                    [108.8871558, 11.9534501],
                    [108.8873581, 11.9533646],
                    [108.8877726, 11.9532182],
                    [108.8879737, 11.9528718],
                    [108.8880542, 11.9526855],
                    [108.8880902, 11.9525361],
                    [108.8881287, 11.9522638],
                    [108.8882044, 11.9518747],
                    [108.8887864, 11.9512764],
                    [108.8889903, 11.9507726],
                    [108.8890382, 11.9506846],
                    [108.8891458, 11.9505154],
                    [108.8892175, 11.9504188],
                    [108.8893759, 11.9502375],
                    [108.8894623, 11.9501533],
                    [108.8897252, 11.9495602],
                    [108.8899988, 11.9492349],
                    [108.8900511, 11.949148],
                    [108.890095, 11.9490567],
                    [108.8901302, 11.9489619],
                    [108.890169, 11.9487959],
                    [108.8901812, 11.9486261],
                    [108.8901758, 11.9483269],
                    [108.8902117, 11.9482517],
                    [108.8903033, 11.9481121],
                    [108.8903582, 11.9480488],
                    [108.890498, 11.9479277],
                    [108.8905763, 11.9478778],
                    [108.8907459, 11.9478015],
                    [108.8908356, 11.9477759],
                    [108.8915706, 11.9477916],
                    [108.8923967, 11.9476184],
                    [108.8925853, 11.9475887],
                    [108.8929653, 11.9475502],
                    [108.8932764, 11.9475397],
                    [108.8935929, 11.9476552],
                    [108.8937635, 11.9477039],
                    [108.8941118, 11.9477794],
                    [108.8942885, 11.9478086],
                    [108.8946444, 11.9478493],
                    [108.8949823, 11.9478493],
                    [108.8951404, 11.9478692],
                    [108.8952935, 11.9479123],
                    [108.8954533, 11.9479861],
                    [108.8955981, 11.9480851],
                    [108.8961625, 11.9486418],
                    [108.8962232, 11.9486898],
                    [108.896355, 11.9487714],
                    [108.8965018, 11.9488335],
                    [108.8965804, 11.9488564],
                    [108.8967419, 11.9488832],
                    [108.8978228, 11.9488439],
                    [108.8980001, 11.948882],
                    [108.8983512, 11.9489724],
                    [108.8987904, 11.9491186],
                    [108.899982, 11.949597],
                    [108.9001626, 11.9496321],
                    [108.9003468, 11.9496337],
                    [108.9004997, 11.949609],
                    [108.9005742, 11.9495879],
                    [108.9006467, 11.9495611],
                    [108.9018434, 11.9490197],
                    [108.9019253, 11.9489897],
                    [108.9020948, 11.9489476],
                    [108.9021814, 11.9489357],
                    [108.9023442, 11.9489303],
                    [108.9024255, 11.9489357],
                    [108.9034443, 11.949582],
                    [108.9036286, 11.9496906],
                    [108.9038175, 11.9497912],
                    [108.9041686, 11.9499519],
                    [108.9043486, 11.9500221],
                    [108.9045324, 11.9500766],
                    [108.9048626, 11.9501501],
                    [108.9051533, 11.95019],
                    [108.9054859, 11.9501953],
                    [108.9056329, 11.9501846],
                    [108.9057051, 11.9501695],
                    [108.9058515, 11.9501167],
                    [108.9059917, 11.9500323],
                    [108.9061137, 11.9499302],
                    [108.9062187, 11.949817],
                    [108.9063054, 11.9496846],
                    [108.9063694, 11.9495358],
                    [108.9064061, 11.9493783],
                    [108.9064256, 11.9491135],
                    [108.9064677, 11.9489334],
                    [108.9064997, 11.9488465],
                    [108.9065901, 11.9486735],
                    [108.9066463, 11.9485932],
                    [108.9067786, 11.9484483],
                    [108.9068538, 11.9483847],
                    [108.9071365, 11.9481805],
                    [108.9074199, 11.9480026],
                    [108.9077014, 11.9478493],
                    [108.9079481, 11.9478598],
                    [108.9081627, 11.9480698],
                    [108.9081853, 11.9481751],
                    [108.9082539, 11.9483794],
                    [108.9083351, 11.9485424],
                    [108.9084363, 11.9486943],
                    [108.9087128, 11.9489449],
                    [108.9089807, 11.9492263],
                    [108.9092356, 11.9495393],
                    [108.9096862, 11.9501375],
                    [108.9097751, 11.9502191],
                    [108.9099686, 11.9503637],
                    [108.9100724, 11.9504262],
                    [108.9102649, 11.9505191],
                    [108.9103651, 11.950557],
                    [108.9105713, 11.9506151],
                    [108.9114243, 11.9505049],
                    [108.911786, 11.9506879],
                    [108.9125025, 11.9510665],
                    [108.9129553, 11.9513169],
                    [108.913849, 11.9518379],
                    [108.9148575, 11.9526396],
                    [108.9168987, 11.9510035],
                    [108.917993, 11.9504367],
                    [108.918626, 11.9499066],
                    [108.9204285, 11.9479832],
                    [108.9207905, 11.9461043],
                    [108.9228049, 11.9426719],
                    [108.9262596, 11.9406513],
                    [108.9304706, 11.9404781],
                    [108.931978, 11.939187],
                    [108.9331582, 11.9370877],
                    [108.9335337, 11.9370404],
                    [108.9337328, 11.9371238],
                    [108.9341409, 11.9372656],
                    [108.9343491, 11.9373239],
                    [108.9347485, 11.9374103],
                    [108.9349505, 11.9374418],
                    [108.9353576, 11.9374813],
                    [108.935496, 11.9374898],
                    [108.9356346, 11.9374891],
                    [108.9357729, 11.9374793],
                    [108.9360355, 11.9374348],
                    [108.93628, 11.9373612],
                    [108.9363983, 11.9373134],
                    [108.9367846, 11.9371297],
                    [108.9369721, 11.9370758],
                    [108.9371601, 11.9370557],
                    [108.9374666, 11.9370726],
                    [108.9377147, 11.9370613],
                    [108.9378377, 11.9370442],
                    [108.9392254, 11.9367885],
                    [108.9398906, 11.9362269],
                    [108.9400691, 11.9361654],
                    [108.9401615, 11.9361453],
                    [108.9402553, 11.9361325],
                    [108.9404413, 11.9361287],
                    [108.9406255, 11.9361535],
                    [108.9422777, 11.9368515],
                    [108.9435866, 11.9372819],
                    [108.9442733, 11.9375627],
                    [108.9462608, 11.9381741],
                    [108.9488572, 11.9378303],
                    [108.9497477, 11.9361561],
                    [108.950547, 11.9342456],
                    [108.9506671, 11.9341258],
                    [108.9507338, 11.9340729],
                    [108.9508806, 11.9339819],
                    [108.951042, 11.9339135],
                    [108.951216, 11.9338564],
                    [108.9513881, 11.9337748],
                    [108.9514697, 11.933726],
                    [108.9528565, 11.9332841],
                    [108.9533687, 11.93286],
                    [108.9533391, 11.9326353],
                    [108.9533344, 11.932522],
                    [108.9533447, 11.9323012],
                    [108.9533794, 11.9320885],
                    [108.9534111, 11.9319617],
                    [108.9534971, 11.9317146],
                    [108.953551, 11.9315952],
                    [108.9537925, 11.9313039],
                    [108.9540097, 11.9310808],
                    [108.9541636, 11.9308887],
                    [108.9543047, 11.9306649],
                    [108.9543998, 11.9304749],
                    [108.9544254, 11.930409],
                    [108.9544642, 11.9302563],
                    [108.9547044, 11.9287977],
                    [108.9549565, 11.9283568],
                    [108.9550477, 11.9280891],
                    [108.9551035, 11.9278488],
                    [108.9552516, 11.9269607],
                    [108.9553185, 11.9267818],
                    [108.9554109, 11.9266141],
                    [108.9554661, 11.9265355],
                    [108.9555934, 11.9263908],
                    [108.9557723, 11.9262348],
                    [108.956059, 11.9260298],
                    [108.956196, 11.9259605],
                    [108.9563549, 11.925913],
                    [108.9567375, 11.9258742],
                    [108.9578654, 11.9255855],
                    [108.9590013, 11.9252234],
                    [108.9592509, 11.9251737],
                    [108.9595443, 11.9251372],
                    [108.9598811, 11.9251237],
                    [108.9603102, 11.9251394],
                    [108.9605843, 11.9250662],
                    [108.9607179, 11.9250187],
                    [108.9609659, 11.9249078],
                    [108.9612007, 11.924772],
                    [108.9613175, 11.9247206],
                    [108.9615601, 11.9246408],
                    [108.9617756, 11.9245974],
                    [108.9619946, 11.9245778],
                    [108.9622169, 11.9245981],
                    [108.9624399, 11.9246093],
                    [108.9628483, 11.9246062],
                    [108.9630521, 11.9245932],
                    [108.9632553, 11.9245725],
                    [108.9638293, 11.9244308],
                    [108.9639516, 11.9243524],
                    [108.9640545, 11.9242542],
                    [108.9641353, 11.9241409],
                    [108.964203, 11.9239978],
                    [108.9642565, 11.9238288],
                    [108.964274, 11.9237419],
                    [108.9643335, 11.9232551],
                    [108.9645696, 11.9225518],
                    [108.9647895, 11.9224468],
                    [108.9649181, 11.922372],
                    [108.9650416, 11.9222894],
                    [108.965302, 11.9221095],
                    [108.9655566, 11.921922],
                    [108.9659536, 11.9216044],
                    [108.9661762, 11.9215257],
                    [108.9662962, 11.9214948],
                    [108.966541, 11.9214548],
                    [108.9668602, 11.9211819],
                    [108.9670211, 11.9209877],
                    [108.9672035, 11.9208092],
                    [108.9677807, 11.9203556],
                    [108.9678781, 11.9202591],
                    [108.9679172, 11.920203],
                    [108.9679699, 11.92009],
                    [108.9680484, 11.9197437],
                    [108.9688879, 11.9194761],
                    [108.9696228, 11.9193291],
                    [108.969875, 11.9192189],
                    [108.9699823, 11.918799],
                    [108.9700166, 11.9187109],
                    [108.9700596, 11.9186267],
                    [108.970111, 11.918547],
                    [108.9702257, 11.918415],
                    [108.9702919, 11.9183572],
                    [108.9704686, 11.9182411],
                    [108.9706888, 11.9181281],
                    [108.9709376, 11.9180314],
                    [108.9710749, 11.9179906],
                    [108.9713556, 11.9179329],
                    [108.9714431, 11.9179265],
                    [108.9716184, 11.9179329],
                    [108.9718173, 11.9179719],
                    [108.9720705, 11.9180595],
                    [108.9721522, 11.9180773],
                    [108.9722459, 11.9180889],
                    [108.9723403, 11.9180916],
                    [108.9724345, 11.9180852],
                    [108.9725277, 11.9180699],
                    [108.9726189, 11.9180458],
                    [108.9727294, 11.9180048],
                    [108.9729407, 11.9179014],
                    [108.9731464, 11.9177631],
                    [108.9732411, 11.9176836],
                    [108.9739305, 11.9179119],
                    [108.9740776, 11.9179311],
                    [108.9741518, 11.9179292],
                    [108.9742942, 11.9179039],
                    [108.9744249, 11.9178524],
                    [108.9745658, 11.9177639],
                    [108.9746414, 11.9177046],
                    [108.9747775, 11.9175695],
                    [108.9749152, 11.9173883],
                    [108.9750899, 11.9171898],
                    [108.9752991, 11.9170029],
                    [108.9754186, 11.9169148],
                    [108.9756071, 11.9167955],
                    [108.9756739, 11.9167624],
                    [108.9758157, 11.9167149],
                    [108.975964, 11.9166936],
                    [108.9761138, 11.9166994],
                    [108.97672, 11.9170564],
                    [108.9770257, 11.9170879],
                    [108.9776856, 11.9174553],
                    [108.9787665, 11.917492],
                    [108.9790081, 11.9175105],
                    [108.9792466, 11.9175524],
                    [108.979369, 11.9175833],
                    [108.9794896, 11.9176203],
                    [108.979724, 11.9177125],
                    [108.9800432, 11.9175156],
                    [108.9800713, 11.9174784],
                    [108.9801452, 11.917345],
                    [108.980357, 11.9168149],
                    [108.9808237, 11.9161378],
                    [108.9809903, 11.9159917],
                    [108.9811692, 11.9158747],
                    [108.9813548, 11.9157862],
                    [108.9816423, 11.9156825],
                    [108.9819389, 11.9156078],
                    [108.9820898, 11.9155814],
                    [108.9823057, 11.9155722],
                    [108.9827378, 11.9155775],
                    [108.9831904, 11.9156171],
                    [108.9834261, 11.9156517],
                    [108.9836602, 11.9156959],
                    [108.9838922, 11.9157494],
                    [108.9840449, 11.9157941],
                    [108.984343, 11.9159045],
                    [108.984686, 11.9160623],
                    [108.9848953, 11.9161273],
                    [108.98513, 11.9161653],
                    [108.9853678, 11.9161688],
                    [108.9854862, 11.9161576],
                    [108.9858256, 11.9161016],
                    [108.9860413, 11.9160382],
                    [108.9862472, 11.9159489],
                    [108.9864482, 11.9158292],
                    [108.9865422, 11.9157599],
                    [108.9869231, 11.9153557],
                    [108.9870417, 11.9152838],
                    [108.9871055, 11.915256],
                    [108.9872329, 11.9152191],
                    [108.9872986, 11.9152088],
                    [108.9876264, 11.91521],
                    [108.9879531, 11.9151825],
                    [108.9881309, 11.9151553],
                    [108.9884445, 11.9150881],
                    [108.9885148, 11.9150817],
                    [108.9886558, 11.9150881],
                    [108.9887389, 11.9151039],
                    [108.9888197, 11.9151287],
                    [108.9888972, 11.9151619],
                    [108.9889705, 11.9152034],
                    [108.9890388, 11.9152524],
                    [108.989101, 11.9153085],
                    [108.9899164, 11.9162218],
                    [108.9903939, 11.9166784],
                    [108.9906192, 11.9172296],
                    [108.9906542, 11.9173062],
                    [108.9907426, 11.91745],
                    [108.9907921, 11.9175126],
                    [108.9909051, 11.9176257],
                    [108.9914238, 11.9180221],
                    [108.9917519, 11.9185464],
                    [108.9918245, 11.9186515],
                    [108.9919817, 11.9188378],
                    [108.9920646, 11.9189192],
                    [108.9922446, 11.9190666],
                    [108.9923427, 11.9190941],
                    [108.9925335, 11.9191651],
                    [108.9927289, 11.9192648],
                    [108.9928284, 11.9193278],
                    [108.9930136, 11.9194721],
                    [108.993178, 11.9196388],
                    [108.9934677, 11.9196912],
                    [108.9936232, 11.9197071],
                    [108.9939357, 11.9197134],
                    [108.9942471, 11.919686],
                    [108.9944011, 11.9196598],
                    [108.9954525, 11.9195233],
                    [108.9956078, 11.9195399],
                    [108.9958978, 11.9195521],
                    [108.9967239, 11.9195075],
                    [108.9973193, 11.9199169],
                    [108.9976224, 11.9206675],
                    [108.9976629, 11.9207425],
                    [108.9977113, 11.9208127],
                    [108.9978187, 11.9209263],
                    [108.9978744, 11.9209705],
                    [108.9979967, 11.9210435],
                    [108.9992022, 11.9215178],
                    [109.001584, 11.9222894],
                    [109.002614, 11.9229402],
                    [109.0029681, 11.9229612],
                    [109.0036502, 11.9232252],
                    [109.0039283, 11.9232971],
                    [109.0040721, 11.9233202],
                    [109.0042172, 11.9233342],
                    [109.0043628, 11.9233391],
                    [109.004859, 11.9233024],
                    [109.0192679, 11.9104323],
                    [109.0333629, 11.9097394],
                    [109.0472963, 11.8982919],
                    [109.0507731, 11.8850053],
                    [109.0580982, 11.8607204],
                    [109.0755165, 11.8344854],
                    [109.0893513, 11.8257329],
                    [109.0911698, 11.8193219],
                    [109.0922856, 11.81656],
                    [109.1061694, 11.8096906],
                    [109.1062298, 11.8096331],
                    [109.1063204, 11.8095206],
                    [109.10647, 11.8092591],
                    [109.1075199, 11.8069218],
                    [109.1076003, 11.806781],
                    [109.107648, 11.8067121],
                    [109.1080195, 11.8066648],
                    [109.1080746, 11.8066653],
                    [109.1081271, 11.8066851],
                    [109.108281, 11.8068171],
                    [109.1083154, 11.8068364],
                    [109.108391, 11.8068591],
                    [109.1084312, 11.8068619],
                    [109.1085107, 11.8068496],
                    [109.1085481, 11.8068347],
                    [109.1088483, 11.8066323],
                    [109.1095644, 11.8073002],
                    [109.1096798, 11.8072214],
                    [109.109787, 11.8071085],
                    [109.1098782, 11.8071925],
                    [109.1105354, 11.8067935],
                    [109.110927, 11.8064863],
                    [109.1111684, 11.8067383],
                    [109.1115493, 11.8064206],
                    [109.1113052, 11.8061555],
                    [109.1115224, 11.8060312],
                    [109.111636, 11.805978],
                    [109.1118711, 11.8058903],
                    [109.1119939, 11.8058556],
                    [109.1122443, 11.8058066],
                    [109.1123712, 11.8057925],
                    [109.1168044, 11.8053992],
                    [109.1171795, 11.8053656],
                    [109.1279885, 11.8043964],
                    [109.1501945, 11.8064942],
                    [109.1652149, 11.8099703],
                    [109.1756649, 11.8112266],
                    [109.1834271, 11.8121599],
                    [109.1851222, 11.813063],
                    [109.1857487, 11.8130171],
                    [109.1864144, 11.812939],
                    [109.1871174, 11.8128235],
                    [109.1874665, 11.8127532],
                    [109.1877216, 11.8126701],
                    [109.1878451, 11.8126179],
                    [109.1880841, 11.812492],
                    [109.1883087, 11.8123384],
                    [109.1883266, 11.8123333],
                    [109.1883624, 11.812341],
                    [109.1883861, 11.8123698],
                    [109.1883994, 11.812473],
                    [109.1884348, 11.812551],
                    [109.1884652, 11.8125899],
                    [109.1885019, 11.812623],
                    [109.18859, 11.8126686],
                    [109.1886964, 11.8126862],
                    [109.1887545, 11.8126859],
                    [109.1888686, 11.8126644],
                    [109.1889227, 11.8126437],
                    [109.1891938, 11.8124907],
                    [109.1892411, 11.8124717],
                    [109.1892906, 11.8124594],
                    [109.1893911, 11.8124553],
                    [109.1894402, 11.8124633],
                    [109.1895329, 11.8124984],
                    [109.1902077, 11.8129055],
                    [109.1902933, 11.8129477],
                    [109.1903824, 11.8129822],
                    [109.1905684, 11.8130269],
                    [109.190761, 11.8130381],
                    [109.190858, 11.8130307],
                    [109.1910479, 11.8129899],
                    [109.191227, 11.812916],
                    [109.1914826, 11.8127791],
                    [109.1917258, 11.8126219],
                    [109.1924554, 11.8120968],
                    [109.19267, 11.8117555],
                    [109.1939843, 11.81143],
                    [109.1949795, 11.8114695],
                    [109.2175571, 11.7685254],
                    [109.5373734, 11.7072872],
                    [109.6661765, 12.6021925],
                    [109.6692365, 12.6218716],
                    [109.6709846, 12.6605498],
                    [109.6665477, 12.7425287],
                    [109.3903602, 12.8222785],
                    [109.3904819, 12.8435912],
                    [109.3894698, 12.8443993],
                    [109.3891303, 12.8446791],
                    [109.3882568, 12.8455133],
                    [109.3880976, 12.8456768],
                    [109.3863682, 12.8474516],
                    [109.3852373, 12.848563],
                    [109.3850448, 12.8487523],
                    [109.3830504, 12.8507123],
                    [109.3814385, 12.8522793],
                    [109.3812745, 12.8524387],
                    [109.3804922, 12.8531991],
                    [109.3800955, 12.8535674],
                    [109.3800192, 12.8536382],
                    [109.3793901, 12.8542221],
                    [109.3784108, 12.8549022],
                    [109.3782459, 12.8549912],
                    [109.3777857, 12.8552567],
                    [109.3766949, 12.8557291],
                    [109.3762056, 12.8562703],
                    [109.3761232, 12.8564734],
                    [109.3759615, 12.8568719],
                    [109.375881, 12.8572805],
                    [109.3758819, 12.8578817],
                    [109.3758826, 12.8583697],
                    [109.3757684, 12.8589709],
                    [109.3756636, 12.8592275],
                    [109.3756151, 12.8593463],
                    [109.3751178, 12.8600981],
                    [109.3745943, 12.8605554],
                    [109.3744226, 12.8607757],
                    [109.3742758, 12.8609634],
                    [109.3739696, 12.8613018],
                    [109.3737399, 12.8614523],
                    [109.3735096, 12.8616028],
                    [109.3729663, 12.8619368],
                    [109.3728968, 12.8619796],
                    [109.3726485, 12.862191],
                    [109.3723226, 12.8624686],
                    [109.3717105, 12.8633706],
                    [109.3710606, 12.8645994],
                    [109.3705251, 12.8654647],
                    [109.3703921, 12.865661],
                    [109.3699895, 12.8662548],
                    [109.3695814, 12.866656],
                    [109.3694535, 12.8667816],
                    [109.3689177, 12.8674213],
                    [109.3687147, 12.8676808],
                    [109.3685351, 12.8679105],
                    [109.3683233, 12.8681191],
                    [109.3678206, 12.8685237],
                    [109.3669634, 12.8681758],
                    [109.3664641, 12.8674623],
                    [109.3658493, 12.8666364],
                    [109.3651191, 12.8653595],
                    [109.3648669, 12.8648892],
                    [109.3646965, 12.8645709],
                    [109.3643886, 12.8638193],
                    [109.364156, 12.8631554],
                    [109.3641181, 12.8625045],
                    [109.3641551, 12.8616024],
                    [109.3641538, 12.8607003],
                    [109.3640836, 12.8605222],
                    [109.3639616, 12.8602124],
                    [109.3636154, 12.8597618],
                    [109.3631, 12.8594498],
                    [109.3619274, 12.8589371],
                    [109.3612405, 12.8586472],
                    [109.3610837, 12.8585811],
                    [109.3599801, 12.8581926],
                    [109.3592813, 12.8582829],
                    [109.3585531, 12.8583966],
                    [109.3579013, 12.8584352],
                    [109.3572714, 12.8584017],
                    [109.3572116, 12.8583985],
                    [109.3570196, 12.8583779],
                    [109.356521, 12.8583243],
                    [109.355848, 12.8581744],
                    [109.3551787, 12.8580254],
                    [109.3546572, 12.8579139],
                    [109.3545047, 12.8578814],
                    [109.353453, 12.8577271],
                    [109.3528259, 12.857588],
                    [109.3525968, 12.857537],
                    [109.3518418, 12.8572026],
                    [109.3508157, 12.8566842],
                    [109.35032, 12.8562099],
                    [109.3499762, 12.8558809],
                    [109.3486941, 12.8540694],
                    [109.3478487, 12.8529424],
                    [109.3475513, 12.8523426],
                    [109.3473622, 12.8521284],
                    [109.3471195, 12.8518533],
                    [109.346667, 12.8513336],
                    [109.3464662, 12.8511031],
                    [109.3458908, 12.8506527],
                    [109.345276, 12.8498455],
                    [109.3446615, 12.8490571],
                    [109.3444647, 12.8487818],
                    [109.3441239, 12.8483058],
                    [109.343804, 12.8478889],
                    [109.343663, 12.8477053],
                    [109.3433775, 12.8474724],
                    [109.3431717, 12.8473178],
                    [109.3430109, 12.8472551],
                    [109.342589, 12.8472557],
                    [109.342244, 12.847444],
                    [109.3422019, 12.847506],
                    [109.3420145, 12.8477824],
                    [109.3415937, 12.8483843],
                    [109.3413259, 12.8487604],
                    [109.3411344, 12.8488922],
                    [109.3409425, 12.8490241],
                    [109.3403291, 12.8490998],
                    [109.3399548, 12.8491198],
                    [109.3396012, 12.8491385],
                    [109.3387193, 12.8490646],
                    [109.3380288, 12.8488623],
                    [109.3379521, 12.8488398],
                    [109.3373386, 12.8486153],
                    [109.3366415, 12.8482568],
                    [109.3366098, 12.8482404],
                    [109.3358809, 12.8480534],
                    [109.3356894, 12.8480677],
                    [109.3351143, 12.8481107],
                    [109.3344901, 12.8482978],
                    [109.3342678, 12.8483644],
                    [109.3337348, 12.848601],
                    [109.3330833, 12.8487146],
                    [109.3325885, 12.8486868],
                    [109.3324318, 12.8486779],
                    [109.3320562, 12.8485069],
                    [109.331856, 12.8484156],
                    [109.3313187, 12.847815],
                    [109.3310875, 12.8470635],
                    [109.3309281, 12.8463095],
                    [109.3308174, 12.8457859],
                    [109.3307071, 12.8452639],
                    [109.3305471, 12.8445087],
                    [109.3304631, 12.8442648],
                    [109.3303755, 12.8440119],
                    [109.3300087, 12.8430057],
                    [109.3297395, 12.8423296],
                    [109.3292015, 12.8413153],
                    [109.3288172, 12.8406582],
                    [109.328717, 12.8405602],
                    [109.3282409, 12.8400952],
                    [109.3275506, 12.8396449],
                    [109.3273758, 12.8395645],
                    [109.3268987, 12.8393451],
                    [109.3263144, 12.8391761],
                    [109.3258634, 12.8390457],
                    [109.3254415, 12.8389711],
                    [109.3244446, 12.8387093],
                    [109.3243677, 12.8386341],
                    [109.3241306, 12.8385781],
                    [109.323996, 12.8385463],
                    [109.32337, 12.8380715],
                    [109.3229095, 12.8375084],
                    [109.3223874, 12.836607],
                    [109.3219877, 12.8361565],
                    [109.3217187, 12.8360149],
                    [109.3214889, 12.835894],
                    [109.3209523, 12.8358946],
                    [109.320796, 12.8359523],
                    [109.3203396, 12.8361213],
                    [109.3197192, 12.836489],
                    [109.3193363, 12.8369013],
                    [109.318938, 12.8377252],
                    [109.3186862, 12.8381987],
                    [109.3184186, 12.8388005],
                    [109.318113, 12.8397029],
                    [109.3178463, 12.8409434],
                    [109.3176173, 12.8417706],
                    [109.3173883, 12.8426353],
                    [109.3172354, 12.8429362],
                    [109.3170442, 12.8432746],
                    [109.3168142, 12.8433313],
                    [109.3160857, 12.8432194],
                    [109.3155295, 12.8429946],
                    [109.3149737, 12.8427697],
                    [109.3142546, 12.8423277],
                    [109.3132331, 12.8417297],
                    [109.3125228, 12.8408555],
                    [109.3116671, 12.8398895],
                    [109.3110205, 12.8394294],
                    [109.3103302, 12.8391295],
                    [109.3095248, 12.8389426],
                    [109.3085663, 12.838831],
                    [109.3071866, 12.8387955],
                    [109.3061511, 12.838815],
                    [109.3052695, 12.8389663],
                    [109.3046632, 12.8392645],
                    [109.3042734, 12.8394561],
                    [109.303699, 12.8400206],
                    [109.3031631, 12.8406601],
                    [109.3028184, 12.8409236],
                    [109.3023203, 12.841112],
                    [109.3019368, 12.841075],
                    [109.3013612, 12.8405874],
                    [109.3003636, 12.8398365],
                    [109.2995576, 12.8391233],
                    [109.2989434, 12.8383347],
                    [109.298367, 12.8372454],
                    [109.2980599, 12.8368323],
                    [109.2972924, 12.8362318],
                    [109.2966788, 12.8359883],
                    [109.2953752, 12.8357267],
                    [109.293841, 12.8353524],
                    [109.292691, 12.8350155],
                    [109.2925559, 12.8349597],
                    [109.2916935, 12.8346032],
                    [109.2905047, 12.8339658],
                    [109.2895074, 12.8334029],
                    [109.2888931, 12.8329713],
                    [109.2884327, 12.8326336],
                    [109.288118, 12.8323917],
                    [109.2878957, 12.8322207],
                    [109.2876041, 12.8321172],
                    [109.2874737, 12.8320709],
                    [109.2873889, 12.8320448],
                    [109.2871245, 12.8319642],
                    [109.2868602, 12.8318837],
                    [109.2862084, 12.8318468],
                    [109.2854037, 12.8318477],
                    [109.2848667, 12.8319237],
                    [109.2840618, 12.8320747],
                    [109.2832193, 12.8324516],
                    [109.2824913, 12.8329785],
                    [109.2817255, 12.8335803],
                    [109.2816762, 12.8336142],
                    [109.2813417, 12.8338439],
                    [109.2809981, 12.8339634],
                    [109.2806906, 12.8340702],
                    [109.2803389, 12.8341198],
                    [109.2798857, 12.8341834],
                    [109.2790418, 12.8341844],
                    [109.2783139, 12.833997],
                    [109.2778378, 12.8337982],
                    [109.2777768, 12.8337726],
                    [109.277666, 12.8337245],
                    [109.2770864, 12.8334727],
                    [109.2765877, 12.8331161],
                    [109.2762915, 12.8330357],
                    [109.2758975, 12.8329294],
                    [109.2752451, 12.8328171],
                    [109.2744021, 12.83263],
                    [109.274066, 12.8325314],
                    [109.2736341, 12.8324049],
                    [109.2729832, 12.8322555],
                    [109.272561, 12.8321058],
                    [109.2720129, 12.8320724],
                    [109.2719483, 12.8320685],
                    [109.2718497, 12.8320788],
                    [109.2712197, 12.8321445],
                    [109.2710489, 12.8322389],
                    [109.2706067, 12.8324838],
                    [109.2702021, 12.8328575],
                    [109.2699552, 12.8330856],
                    [109.269381, 12.8336873],
                    [109.2691226, 12.8338992],
                    [109.2689216, 12.8340637],
                    [109.2687352, 12.8341823],
                    [109.2682701, 12.8344776],
                    [109.2678668, 12.8346762],
                    [109.2676573, 12.8347792],
                    [109.2674825, 12.834825],
                    [109.2670825, 12.83493],
                    [109.2660859, 12.8351002],
                    [109.265487, 12.8352716],
                    [109.2650266, 12.8354035],
                    [109.2646376, 12.8354909],
                    [109.2643613, 12.835553],
                    [109.2637098, 12.8358167],
                    [109.2629282, 12.8360089],
                    [109.2622257, 12.8365283],
                    [109.2616794, 12.8371718],
                    [109.2612199, 12.837736],
                    [109.2608753, 12.8380746],
                    [109.2604245, 12.8381171],
                    [109.2599551, 12.8379627],
                    [109.2597248, 12.8377375],
                    [109.2591875, 12.8374751],
                    [109.2586515, 12.8374756],
                    [109.2583654, 12.8375458],
                    [109.258038, 12.8376264],
                    [109.257758, 12.8378098],
                    [109.2576932, 12.8378522],
                    [109.2572332, 12.8378526],
                    [109.2565426, 12.8373083],
                    [109.2558135, 12.8366701],
                    [109.2556841, 12.8366159],
                    [109.2552766, 12.8364451],
                    [109.2547438, 12.8362258],
                    [109.254548, 12.8361452],
                    [109.254011, 12.8359578],
                    [109.2539086, 12.8357824],
                    [109.2538577, 12.8356949],
                    [109.2539723, 12.8352438],
                    [109.2542008, 12.834944],
                    [109.2543166, 12.8347924],
                    [109.2548145, 12.8343409],
                    [109.2550228, 12.8340679],
                    [109.255159, 12.8338895],
                    [109.2552053, 12.8337071],
                    [109.2552353, 12.8335886],
                    [109.2551584, 12.8333258],
                    [109.2548159, 12.8330576],
                    [109.2547747, 12.8330254],
                    [109.2538159, 12.8325754],
                    [109.2530107, 12.8323882],
                    [109.2524354, 12.8321257],
                    [109.2520212, 12.8316555],
                    [109.2514762, 12.8310366],
                    [109.2507856, 12.8302672],
                    [109.2499795, 12.829215],
                    [109.2495141, 12.8285328],
                    [109.249211, 12.8280884],
                    [109.2488776, 12.8276802],
                    [109.2487507, 12.8275249],
                    [109.2486653, 12.8273711],
                    [109.2484702, 12.8270199],
                    [109.2478683, 12.8265865],
                    [109.2469476, 12.8260613],
                    [109.2462963, 12.8257653],
                    [109.2458741, 12.8255733],
                    [109.2452049, 12.8252367],
                    [109.2442631, 12.8248227],
                    [109.2436492, 12.8243722],
                    [109.2431504, 12.8239406],
                    [109.2427196, 12.8235193],
                    [109.2425366, 12.8233402],
                    [109.2420929, 12.8228819],
                    [109.2418458, 12.8226267],
                    [109.2412703, 12.8220635],
                    [109.2403951, 12.8213423],
                    [109.240096, 12.8211486],
                    [109.2385081, 12.8202244],
                    [109.2378231, 12.819889],
                    [109.237511, 12.8197362],
                    [109.2364843, 12.8190882],
                    [109.2354023, 12.8184229],
                    [109.2343278, 12.8179727],
                    [109.2335609, 12.8174285],
                    [109.2334406, 12.8173193],
                    [109.2330238, 12.8169406],
                    [109.2323004, 12.8159783],
                    [109.2319878, 12.8155506],
                    [109.231604, 12.8149119],
                    [109.2313004, 12.8142451],
                    [109.2312108, 12.8140335],
                    [109.2294165, 12.8125087],
                    [109.2287267, 12.8127348],
                    [109.2281519, 12.8131116],
                    [109.2278374, 12.813335],
                    [109.2277179, 12.81342],
                    [109.2269265, 12.8143901],
                    [109.2267631, 12.8145506],
                    [109.2265052, 12.8148038],
                    [109.2263016, 12.8149818],
                    [109.2261604, 12.8151046],
                    [109.2256238, 12.8154811],
                    [109.2251466, 12.8157282],
                    [109.2248963, 12.8158577],
                    [109.2244889, 12.8160473],
                    [109.2241681, 12.8161964],
                    [109.2229803, 12.8166673],
                    [109.2223291, 12.8171941],
                    [109.2215261, 12.8180071],
                    [109.2204907, 12.8189997],
                    [109.2200071, 12.8194385],
                    [109.2194568, 12.8202033],
                    [109.2191506, 12.8207673],
                    [109.2190044, 12.8211355],
                    [109.2188066, 12.8219704],
                    [109.218654, 12.8227974],
                    [109.2185015, 12.8237183],
                    [109.2183181, 12.8243796],
                    [109.2179277, 12.8251094],
                    [109.2174683, 12.8258991],
                    [109.2167789, 12.8266513],
                    [109.2161277, 12.8272157],
                    [109.2142954, 12.8281361],
                    [109.2136954, 12.8283077],
                    [109.2129795, 12.8285618],
                    [109.2121841, 12.8288355],
                    [109.2114283, 12.8291092],
                    [109.2105734, 12.8295389],
                    [109.2100563, 12.8297929],
                    [109.2096387, 12.8298712],
                    [109.2091214, 12.8298131],
                    [109.2086439, 12.8296868],
                    [109.2079674, 12.8294338],
                    [109.2073107, 12.8291613],
                    [109.2066143, 12.8289668],
                    [109.2060572, 12.8288112],
                    [109.2056195, 12.8287725],
                    [109.2050426, 12.828734],
                    [109.2044259, 12.8287344],
                    [109.2037165, 12.828666],
                    [109.2028412, 12.828696],
                    [109.2020455, 12.8286966],
                    [109.2011504, 12.8286973],
                    [109.2002552, 12.8286395],
                    [109.1995987, 12.8285813],
                    [109.1991013, 12.8285038],
                    [109.1980274, 12.8284948],
                    [109.1972456, 12.8284189],
                    [109.1965152, 12.828379],
                    [109.195819, 12.8283795],
                    [109.194904, 12.8283802],
                    [109.1942276, 12.8283416],
                    [109.1934319, 12.8282837],
                    [109.1927716, 12.82829],
                    [109.1923212, 12.8282865],
                    [109.1918915, 12.8282668],
                    [109.1916318, 12.8282554],
                    [109.1909097, 12.8282474],
                    [109.1888234, 12.8274844],
                    [109.1874517, 12.8269873],
                    [109.1852928, 12.8274178],
                    [109.1821347, 12.8294934],
                    [109.1812878, 12.8282067],
                    [109.1800517, 12.8269291],
                    [109.1791478, 12.8263339],
                    [109.1786305, 12.8261391],
                    [109.1780933, 12.8260712],
                    [109.177626, 12.8260301],
                    [109.1767805, 12.8260722],
                    [109.1761164, 12.8262815],
                    [109.1753684, 12.8264631],
                    [109.1744535, 12.8266977],
                    [109.1737319, 12.8268399],
                    [109.1733, 12.8269911],
                    [109.1725641, 12.8271769],
                    [109.1719276, 12.8272943],
                    [109.1713782, 12.8272667],
                    [109.1706346, 12.8271782],
                    [109.1698984, 12.8269641],
                    [109.1693016, 12.8268085],
                    [109.168625, 12.8265358],
                    [109.168048, 12.8262436],
                    [109.1674908, 12.8259222],
                    [109.1668341, 12.8255911],
                    [109.1662967, 12.8251233],
                    [109.1657592, 12.8246165],
                    [109.1652019, 12.8239928],
                    [109.1648833, 12.8235445],
                    [109.1646443, 12.8231155],
                    [109.164425, 12.8223452],
                    [109.1644058, 12.8218145],
                    [109.164325, 12.8213312],
                    [109.164265, 12.8209412],
                    [109.1641652, 12.8203951],
                    [109.1641051, 12.8198101],
                    [109.1639912, 12.8193243],
                    [109.1637916, 12.8183201],
                    [109.1636321, 12.8176766],
                    [109.1633532, 12.8170137],
                    [109.1630345, 12.8163117],
                    [109.1624969, 12.815454],
                    [109.1621982, 12.8150348],
                    [109.1618599, 12.814762],
                    [109.1614221, 12.8145867],
                    [109.1610147, 12.8144245],
                    [109.160328, 12.8142753],
                    [109.1598505, 12.8141586],
                    [109.1593332, 12.8139833],
                    [109.158995, 12.8137885],
                    [109.1586566, 12.8135352],
                    [109.1583381, 12.8131844],
                    [109.1581658, 12.8127789],
                    [109.1579864, 12.8120574],
                    [109.1578264, 12.8108093],
                    [109.1578302, 12.8106944],
                    [109.1578584, 12.8098309],
                    [109.1578256, 12.8094051],
                    [109.1577457, 12.8089566],
                    [109.157626, 12.808235],
                    [109.1574864, 12.8076988],
                    [109.1572673, 12.8071723],
                    [109.1570265, 12.8066955],
                    [109.15665, 12.8060025],
                    [109.156192, 12.8051837],
                    [109.1560465, 12.8047507],
                    [109.1558472, 12.8042145],
                    [109.1555884, 12.803844],
                    [109.15525, 12.8033177],
                    [109.1549116, 12.8029083],
                    [109.1545135, 12.8025185],
                    [109.1541087, 12.8020579],
                    [109.1535184, 12.8014756],
                    [109.153001, 12.8009298],
                    [109.1524037, 12.8001305],
                    [109.1520056, 12.7994677],
                    [109.1516272, 12.7987268],
                    [109.1513283, 12.7978883],
                    [109.1508504, 12.7968647],
                    [109.1505317, 12.7961042],
                    [109.1500937, 12.7953439],
                    [109.1497948, 12.7945541],
                    [109.1495549, 12.7940507],
                    [109.1492572, 12.7934038],
                    [109.1488788, 12.7926628],
                    [109.1484607, 12.7918049],
                    [109.1480823, 12.7910835],
                    [109.1477638, 12.7905864],
                    [109.1473656, 12.789943],
                    [109.1469276, 12.7891826],
                    [109.1465494, 12.7886563],
                    [109.1461514, 12.7881689],
                    [109.1456738, 12.7877207],
                    [109.1453355, 12.787282],
                    [109.144977, 12.7866385],
                    [109.1446385, 12.7860731],
                    [109.1442603, 12.7853322],
                    [109.1437425, 12.7842793],
                    [109.1434439, 12.7836261],
                    [109.1430457, 12.7828657],
                    [109.1426276, 12.7821248],
                    [109.1424485, 12.7818519],
                    [109.1421898, 12.781618],
                    [109.1418515, 12.7814621],
                    [109.1414446, 12.7813173],
                    [109.1410757, 12.7811895],
                    [109.1407575, 12.7811507],
                    [109.1404194, 12.7811703],
                    [109.1399223, 12.7812875],
                    [109.1394451, 12.7815804],
                    [109.1390277, 12.7820097],
                    [109.1385905, 12.7826341],
                    [109.1381335, 12.7834047],
                    [109.1377558, 12.783951],
                    [109.137279, 12.7847703],
                    [109.1366231, 12.785697],
                    [109.1360945, 12.7864011],
                    [109.1355697, 12.7870239],
                    [109.1350728, 12.7875117],
                    [109.1344833, 12.7879803],
                    [109.1338004, 12.788585],
                    [109.1332239, 12.7890924],
                    [109.1326863, 12.789545],
                    [109.1317321, 12.7905304],
                    [109.1310563, 12.7912328],
                    [109.13044, 12.7917597],
                    [109.1298237, 12.7923452],
                    [109.1293068, 12.792872],
                    [109.1280942, 12.7942084],
                    [109.127334, 12.7950289],
                    [109.1268021, 12.7955158],
                    [109.1264045, 12.7958477],
                    [109.1259471, 12.7961307],
                    [109.1253903, 12.7963649],
                    [109.1249727, 12.7965212],
                    [109.1243164, 12.7966775],
                    [109.1237198, 12.7968728],
                    [109.1231828, 12.7969705],
                    [109.1227453, 12.7971073],
                    [109.1219499, 12.7974002],
                    [109.1212209, 12.7976914],
                    [109.1204385, 12.7980445],
                    [109.1198618, 12.7982301],
                    [109.1194043, 12.7982888],
                    [109.1188474, 12.7983281],
                    [109.1185292, 12.7982696],
                    [109.1182904, 12.7980943],
                    [109.1180915, 12.7978603],
                    [109.1179719, 12.7974898],
                    [109.1179319, 12.7968853],
                    [109.1179614, 12.7966187],
                    [109.1179914, 12.7963473],
                    [109.1180109, 12.7956955],
                    [109.1180107, 12.7953445],
                    [109.1179506, 12.7946229],
                    [109.1178907, 12.7938914],
                    [109.1178971, 12.7930381],
                    [109.11789, 12.7923702],
                    [109.1178897, 12.7918534],
                    [109.1178895, 12.7915024],
                    [109.1178893, 12.7911123],
                    [109.1178296, 12.7908198],
                    [109.1176902, 12.7905662],
                    [109.1173931, 12.7902075],
                    [109.1170138, 12.7900205],
                    [109.1164169, 12.7897087],
                    [109.1161582, 12.7895138],
                    [109.1158598, 12.7892408],
                    [109.1153226, 12.788734],
                    [109.1149644, 12.7883246],
                    [109.1145265, 12.7877592],
                    [109.1141086, 12.7872425],
                    [109.1136709, 12.7866381],
                    [109.1133325, 12.7860532],
                    [109.1130538, 12.7855658],
                    [109.1128746, 12.7850197],
                    [109.1126953, 12.7843761],
                    [109.1125757, 12.7838594],
                    [109.1125158, 12.7833719],
                    [109.1124559, 12.7829232],
                    [109.1123564, 12.7825722],
                    [109.1120777, 12.7821823],
                    [109.1117195, 12.7817339],
                    [109.1112419, 12.7810709],
                    [109.1109832, 12.7806128],
                    [109.110625, 12.7801448],
                    [109.1103065, 12.7795794],
                    [109.1101273, 12.7790138],
                    [109.1100873, 12.7785263],
                    [109.1101084, 12.7778937],
                    [109.1101464, 12.777356],
                    [109.1101262, 12.7767222],
                    [109.1101061, 12.7761177],
                    [109.1100661, 12.775435],
                    [109.110026, 12.7748499],
                    [109.1099463, 12.7744989],
                    [109.1098268, 12.7740504],
                    [109.1097073, 12.7735726],
                    [109.1094684, 12.7731436],
                    [109.1091898, 12.7727537],
                    [109.1088176, 12.7722897],
                    [109.108752, 12.7722078],
                    [109.1083741, 12.7719349],
                    [109.1079563, 12.7717596],
                    [109.1075585, 12.7715647],
                    [109.1071606, 12.7713113],
                    [109.106723, 12.7711554],
                    [109.1064247, 12.7710971],
                    [109.1060666, 12.7710094],
                    [109.1057485, 12.7708925],
                    [109.1055097, 12.7706976],
                    [109.1052311, 12.7703076],
                    [109.1049922, 12.7698007],
                    [109.1047733, 12.7694106],
                    [109.1044946, 12.7688452],
                    [109.104196, 12.7681432],
                    [109.1040169, 12.7676946],
                    [109.1038576, 12.7672657],
                    [109.1035591, 12.7668074],
                    [109.1032209, 12.7664956],
                    [109.1029026, 12.7662616],
                    [109.1023655, 12.7660083],
                    [109.1018086, 12.765755],
                    [109.1013908, 12.7655406],
                    [109.1009731, 12.7654238],
                    [109.100277, 12.7651119],
                    [109.0995411, 12.7648002],
                    [109.0986858, 12.764547],
                    [109.0977156, 12.7640977],
                    [109.0971144, 12.7637772],
                    [109.0964978, 12.7634653],
                    [109.0958413, 12.763056],
                    [109.0950456, 12.7624517],
                    [109.0947842, 12.762309],
                    [109.0941561, 12.7619123],
                    [109.0935763, 12.7613943],
                    [109.092944, 12.7608246],
                    [109.092575, 12.7603584],
                    [109.0922587, 12.7597886],
                    [109.0921004, 12.7590631],
                    [109.0919947, 12.7581824],
                    [109.0920471, 12.7572498],
                    [109.09231, 12.756058],
                    [109.092889, 12.7546587],
                    [109.0935206, 12.7529488],
                    [109.0938889, 12.7518087],
                    [109.0939415, 12.751446],
                    [109.093994, 12.7509795],
                    [109.0939412, 12.7506687],
                    [109.0937303, 12.7502543],
                    [109.0934141, 12.7499953],
                    [109.0927292, 12.7497365],
                    [109.0922549, 12.7493222],
                    [109.0920968, 12.7490631],
                    [109.0917802, 12.7476125],
                    [109.0912002, 12.7461619],
                    [109.0906731, 12.7454886],
                    [109.0898825, 12.7446079],
                    [109.0890922, 12.7439346],
                    [109.0881964, 12.7433131],
                    [109.0874588, 12.7429507],
                    [109.0862996, 12.7424848],
                    [109.0853513, 12.7423297],
                    [109.0843504, 12.7423818],
                    [109.0829807, 12.742434],
                    [109.0817167, 12.7428489],
                    [109.0797678, 12.743834],
                    [109.0778189, 12.7449226],
                    [109.0766602, 12.7458038],
                    [109.0759229, 12.7464776],
                    [109.0752385, 12.7477213],
                    [109.0743958, 12.7488097],
                    [109.0737638, 12.7492762],
                    [109.0725525, 12.7504165],
                    [109.0714991, 12.7514011],
                    [109.0707091, 12.7520231],
                    [109.070077, 12.752386],
                    [109.0693922, 12.7525935],
                    [109.0691815, 12.7525417],
                    [109.0683911, 12.7523865],
                    [109.0677588, 12.7520239],
                    [109.0671265, 12.7510915],
                    [109.0661779, 12.7499],
                    [109.0652294, 12.7490193],
                    [109.0645971, 12.7484495],
                    [109.0642809, 12.7479315],
                    [109.0641226, 12.7469989],
                    [109.0634373, 12.7455482],
                    [109.0622254, 12.7444604],
                    [109.0618567, 12.7441496],
                    [109.0613298, 12.744098],
                    [109.0609611, 12.7441498],
                    [109.060487, 12.7444091],
                    [109.0597496, 12.7450828],
                    [109.059223, 12.7456011],
                    [109.0587489, 12.7459638],
                    [109.0582221, 12.7460158],
                    [109.0578534, 12.7459122],
                    [109.0570104, 12.7453942],
                    [109.0561674, 12.7452908],
                    [109.0556933, 12.7453427],
                    [109.0543237, 12.7458612],
                    [109.0527961, 12.746276],
                    [109.0511103, 12.7464835],
                    [109.050004, 12.7464837],
                    [109.0491085, 12.7463285],
                    [109.0481601, 12.7461215],
                    [109.0476333, 12.7458625],
                    [109.0468956, 12.745189],
                    [109.046316, 12.7446191],
                    [109.0458946, 12.7442048],
                    [109.0455257, 12.7439457],
                    [109.0448408, 12.7438422],
                    [109.0443142, 12.7438942],
                    [109.0438927, 12.7442051],
                    [109.0430498, 12.7447234],
                    [109.0426284, 12.7449307],
                    [109.0421017, 12.7450862],
                    [109.0415749, 12.7450344],
                    [109.0408373, 12.74462],
                    [109.0402578, 12.7441538],
                    [109.039889, 12.7437912],
                    [109.0397836, 12.7434802],
                    [109.0396781, 12.7431177],
                    [109.0397307, 12.7424959],
                    [109.0400466, 12.7414077],
                    [109.0404679, 12.7403714],
                    [109.0409418, 12.739335],
                    [109.041047, 12.7384542],
                    [109.0410996, 12.7377288],
                    [109.0409942, 12.7373142],
                    [109.0407834, 12.7367443],
                    [109.0400985, 12.7361227],
                    [109.0394136, 12.7358119],
                    [109.0379912, 12.7356567],
                    [109.0368323, 12.7358123],
                    [109.0360422, 12.7357088],
                    [109.03541, 12.735398],
                    [109.0349358, 12.7349836],
                    [109.034409, 12.7344655],
                    [109.0340929, 12.7342582],
                    [109.0337241, 12.734051],
                    [109.0331447, 12.7339993],
                    [109.0321174, 12.7342159],
                    [109.0311166, 12.734216],
                    [109.0303791, 12.7342161],
                    [109.0297996, 12.7345273],
                    [109.0293255, 12.7347866],
                    [109.0285354, 12.7351495],
                    [109.027798, 12.7354089],
                    [109.0271131, 12.735409],
                    [109.0266917, 12.7352016],
                    [109.0259015, 12.7346313],
                    [109.0245844, 12.7332834],
                    [109.0235307, 12.7321947],
                    [109.0222664, 12.7312615],
                    [109.0208967, 12.73038],
                    [109.0200011, 12.7298098],
                    [109.0187632, 12.7293433],
                    [109.0173409, 12.7291878],
                    [109.016182, 12.7289806],
                    [109.0152865, 12.7287213],
                    [109.0147597, 12.7284621],
                    [109.0144436, 12.7282547],
                    [109.0142856, 12.7279954],
                    [109.0142328, 12.7276845],
                    [109.0143381, 12.7272696],
                    [109.0149175, 12.7265955],
                    [109.0162871, 12.725714],
                    [109.0195529, 12.7241064],
                    [109.0212385, 12.7231212],
                    [109.0205536, 12.7216176],
                    [109.0187889, 12.7212547],
                    [109.0176301, 12.7211511],
                    [109.0167873, 12.7210994],
                    [109.0163658, 12.720892],
                    [109.0161024, 12.7205291],
                    [109.0159444, 12.7199587],
                    [109.0158916, 12.7192328],
                    [109.015839, 12.7187143],
                    [109.0156809, 12.7182995],
                    [109.0155229, 12.7179884],
                    [109.0152068, 12.7178848],
                    [109.014522, 12.7180922],
                    [109.0136793, 12.7183515],
                    [109.0127838, 12.7184553],
                    [109.0115196, 12.7183516],
                    [109.0099393, 12.7177295],
                    [109.0088858, 12.7170036],
                    [109.0077796, 12.715863],
                    [109.0064363, 12.7138928],
                    [109.0053829, 12.7130113],
                    [109.003592, 12.7121299],
                    [109.001485, 12.7106262],
                    [108.9979032, 12.7084485],
                    [108.9961124, 12.7072041],
                    [108.9916988, 12.7049632],
                    [108.9898035, 12.7048871],
                    [108.989394, 12.7048588],
                    [108.9890303, 12.7048077],
                    [108.9888503, 12.7047718],
                    [108.9885, 12.7046809],
                    [108.9881625, 12.7045656],
                    [108.9879974, 12.7044986],
                    [108.987835, 12.7044255],
                    [108.9877486, 12.7043769],
                    [108.9876672, 12.7043206],
                    [108.9875914, 12.7042573],
                    [108.987522, 12.7041874],
                    [108.9874595, 12.7041115],
                    [108.9872203, 12.7037334],
                    [108.9870846, 12.7035622],
                    [108.9869611, 12.7034536],
                    [108.9868917, 12.7034086],
                    [108.9867412, 12.7033395],
                    [108.9866615, 12.7033161],
                    [108.9865798, 12.7033004],
                    [108.9863395, 12.7032776],
                    [108.9862204, 12.7032585],
                    [108.9859829, 12.7032043],
                    [108.9858663, 12.7031695],
                    [108.9858723, 12.7030363],
                    [108.985887, 12.7029711],
                    [108.9859318, 12.7028602],
                    [108.9860239, 12.7027092],
                    [108.9860712, 12.7025752],
                    [108.9860838, 12.7025053],
                    [108.9860888, 12.7024346],
                    [108.9860786, 12.7023069],
                    [108.986033, 12.7021333],
                    [108.986025, 12.702069],
                    [108.986033, 12.7019399],
                    [108.9860487, 12.701877],
                    [108.9861093, 12.7017456],
                    [108.9861507, 12.7016858],
                    [108.9862761, 12.70155],
                    [108.9863066, 12.7015033],
                    [108.986331, 12.7014533],
                    [108.9863782, 12.7012972],
                    [108.9864404, 12.7011955],
                    [108.9865365, 12.7011062],
                    [108.986593, 12.701072],
                    [108.9867178, 12.7010278],
                    [108.9867836, 12.7010187],
                    [108.9868984, 12.7010215],
                    [108.9870378, 12.7010481],
                    [108.987189, 12.701066],
                    [108.9873236, 12.7010554],
                    [108.9874526, 12.7010167],
                    [108.9875732, 12.7009495],
                    [108.9876891, 12.7008637],
                    [108.9878511, 12.7007152],
                    [108.9880013, 12.7005477],
                    [108.9878458, 12.7003123],
                    [108.9878551, 12.7001972],
                    [108.9878551, 12.7000819],
                    [108.9878316, 12.6998759],
                    [108.9878085, 12.6997868],
                    [108.9877765, 12.6997002],
                    [108.987736, 12.6996171],
                    [108.9876874, 12.6995383],
                    [108.9875774, 12.6994058],
                    [108.9873002, 12.699162],
                    [108.9870894, 12.6990092],
                    [108.9869999, 12.6987861],
                    [108.9869659, 12.6986709],
                    [108.9869268, 12.6984837],
                    [108.9868819, 12.6983482],
                    [108.9868115, 12.6982235],
                    [108.9866495, 12.6980306],
                    [108.9866017, 12.6979544],
                    [108.9865623, 12.6978739],
                    [108.986514, 12.6977237],
                    [108.9865022, 12.6975879],
                    [108.986508, 12.6975199],
                    [108.9865427, 12.6973878],
                    [108.9866066, 12.6972665],
                    [108.9866525, 12.6972073],
                    [108.9870231, 12.6968364],
                    [108.9871018, 12.6967105],
                    [108.9871497, 12.6965707],
                    [108.9871645, 12.696424],
                    [108.9871479, 12.696298],
                    [108.9870987, 12.6961804],
                    [108.9870627, 12.6961272],
                    [108.9870201, 12.6960791],
                    [108.9869384, 12.6960136],
                    [108.9868903, 12.6959503],
                    [108.9868724, 12.6958734],
                    [108.986876, 12.6958339],
                    [108.9869073, 12.6957612],
                    [108.986966, 12.695707],
                    [108.9870229, 12.695684],
                    [108.9872333, 12.6956417],
                    [108.9874986, 12.6956223],
                    [108.9875734, 12.695589],
                    [108.9876319, 12.6955325],
                    [108.9876527, 12.6954977],
                    [108.9876909, 12.6953467],
                    [108.9877166, 12.6952767],
                    [108.9877509, 12.6952104],
                    [108.9877932, 12.6951486],
                    [108.987843, 12.6950923],
                    [108.9878994, 12.6950424],
                    [108.9880818, 12.6949168],
                    [108.9882749, 12.6947441],
                    [108.9884341, 12.6947591],
                    [108.9885928, 12.6947403],
                    [108.9887436, 12.6946888],
                    [108.9888797, 12.6946069],
                    [108.9889767, 12.6945185],
                    [108.9890762, 12.6943982],
                    [108.9891922, 12.694226],
                    [108.9892469, 12.6941258],
                    [108.9893357, 12.693916],
                    [108.9894377, 12.6935908],
                    [108.9894772, 12.6934251],
                    [108.9895247, 12.6931538],
                    [108.9895356, 12.6929438],
                    [108.9895206, 12.6927512],
                    [108.9894515, 12.6923265],
                    [108.9893857, 12.6920988],
                    [108.9892862, 12.6918836],
                    [108.9892646, 12.6918202],
                    [108.9892459, 12.6917559],
                    [108.9892278, 12.6916795],
                    [108.9891481, 12.691278],
                    [108.9891039, 12.6911136],
                    [108.9890507, 12.6909517],
                    [108.9889884, 12.6907929],
                    [108.9889155, 12.6906341],
                    [108.9888337, 12.6904796],
                    [108.9887431, 12.6903297],
                    [108.9886441, 12.690185],
                    [108.988537, 12.6900459],
                    [108.9884099, 12.6899025],
                    [108.9882826, 12.6899868],
                    [108.9881028, 12.6900928],
                    [108.9880503, 12.6901121],
                    [108.987939, 12.6901247],
                    [108.9878833, 12.6901178],
                    [108.987828, 12.6901017],
                    [108.9877274, 12.6900468],
                    [108.9876473, 12.6899661],
                    [108.9875729, 12.6898548],
                    [108.987529, 12.6898039],
                    [108.9874283, 12.6897128],
                    [108.9873084, 12.6896453],
                    [108.9871754, 12.6896081],
                    [108.9871064, 12.6896015],
                    [108.9869685, 12.6896132],
                    [108.9866871, 12.6897044],
                    [108.9864571, 12.6897421],
                    [108.9862241, 12.6897491],
                    [108.9861077, 12.689741],
                    [108.9858618, 12.6896978],
                    [108.9856249, 12.6896202],
                    [108.9855115, 12.6895689],
                    [108.9852977, 12.6894427],
                    [108.9851766, 12.6893507],
                    [108.9849776, 12.6892188],
                    [108.9847517, 12.6890908],
                    [108.984597, 12.6890126],
                    [108.9842808, 12.6888749],
                    [108.9839566, 12.6887624],
                    [108.9838481, 12.6885143],
                    [108.9837127, 12.6882791],
                    [108.9835522, 12.6880596],
                    [108.983463, 12.6879564],
                    [108.9832973, 12.6877911],
                    [108.9826906, 12.6872395],
                    [108.9818551, 12.6865164],
                    [108.9817561, 12.6864147],
                    [108.9816643, 12.6863066],
                    [108.9815801, 12.6861928],
                    [108.9813754, 12.6858785],
                    [108.9812637, 12.6857275],
                    [108.9810222, 12.6854392],
                    [108.980891, 12.6853002],
                    [108.9806118, 12.6850383],
                    [108.9804263, 12.6848805],
                    [108.9799856, 12.684536],
                    [108.9799355, 12.6844847],
                    [108.9798477, 12.6843648],
                    [108.9798118, 12.6842967],
                    [108.979783, 12.6842254],
                    [108.979708, 12.683911],
                    [108.9796296, 12.6837295],
                    [108.9795792, 12.6836441],
                    [108.9792355, 12.6831544],
                    [108.9790535, 12.6829166],
                    [108.9786775, 12.6824648],
                    [108.9784804, 12.6822464],
                    [108.9783336, 12.6823244],
                    [108.9781916, 12.6824103],
                    [108.9780548, 12.6825038],
                    [108.9779235, 12.6826046],
                    [108.9777411, 12.6827659],
                    [108.9776784, 12.682813],
                    [108.9775393, 12.682886],
                    [108.9774645, 12.6829112],
                    [108.9773087, 12.682937],
                    [108.9770565, 12.6829286],
                    [108.9769702, 12.6829379],
                    [108.9768854, 12.6829563],
                    [108.9768032, 12.6829835],
                    [108.9767245, 12.6830193],
                    [108.9764825, 12.6831799],
                    [108.9763914, 12.683227],
                    [108.9761982, 12.6832972],
                    [108.9760197, 12.6833309],
                    [108.9758623, 12.6833381],
                    [108.975628, 12.683366],
                    [108.975395, 12.6834033],
                    [108.974954, 12.6834966],
                    [108.9746189, 12.6835801],
                    [108.9745006, 12.6836013],
                    [108.974381, 12.6836135],
                    [108.9742607, 12.6836165],
                    [108.9741406, 12.6836104],
                    [108.9740213, 12.6835951],
                    [108.9739037, 12.6835708],
                    [108.9736336, 12.6835],
                    [108.9734958, 12.6834769],
                    [108.9733567, 12.6834621],
                    [108.9729818, 12.6834492],
                    [108.9728653, 12.6834331],
                    [108.9725253, 12.6833512],
                    [108.9724103, 12.6833341],
                    [108.9722943, 12.6833248],
                    [108.971905, 12.6833147],
                    [108.9718213, 12.6833187],
                    [108.9716578, 12.6833529],
                    [108.9715798, 12.6833828],
                    [108.971436, 12.6834661],
                    [108.9713431, 12.6835464],
                    [108.971209, 12.6836796],
                    [108.971008, 12.6838387],
                    [108.9707893, 12.6839736],
                    [108.9705891, 12.6840689],
                    [108.970327, 12.6841658],
                    [108.9701486, 12.6842198],
                    [108.9696892, 12.6843364],
                    [108.9692814, 12.68446],
                    [108.9687444, 12.6846445],
                    [108.968389, 12.6847745],
                    [108.9681752, 12.6848649],
                    [108.9679781, 12.6849861],
                    [108.9678024, 12.6851353],
                    [108.9676563, 12.6853034],
                    [108.9674393, 12.6856052],
                    [108.9673058, 12.6857585],
                    [108.9672528, 12.6858058],
                    [108.967195, 12.6858477],
                    [108.9670679, 12.6859131],
                    [108.9669999, 12.685936],
                    [108.9668792, 12.6859592],
                    [108.9667256, 12.6859664],
                    [108.9666232, 12.6859633],
                    [108.9664203, 12.6859363],
                    [108.966163, 12.6858644],
                    [108.965916, 12.6857636],
                    [108.9658131, 12.6857113],
                    [108.9655145, 12.6855371],
                    [108.9653206, 12.685413],
                    [108.9649948, 12.6851807],
                    [108.9645791, 12.684853],
                    [108.964299, 12.6846779],
                    [108.964152, 12.6846018],
                    [108.9638922, 12.6844907],
                    [108.9635603, 12.6843764],
                    [108.9633364, 12.6843078],
                    [108.9629378, 12.6842036],
                    [108.9627633, 12.684169],
                    [108.9624103, 12.6841235],
                    [108.9620546, 12.6841099],
                    [108.9617733, 12.6841218],
                    [108.9614644, 12.684154],
                    [108.9610559, 12.6842198],
                    [108.9606357, 12.6843164],
                    [108.9602233, 12.6844406],
                    [108.9597987, 12.6846007],
                    [108.9593288, 12.6848153],
                    [108.9590806, 12.6849409],
                    [108.9585957, 12.6852123],
                    [108.9583594, 12.685358],
                    [108.95775, 12.6857594],
                    [108.9571237, 12.6861352],
                    [108.9564926, 12.6864792],
                    [108.9558474, 12.6867973],
                    [108.9555198, 12.6869464],
                    [108.9553866, 12.6867787],
                    [108.9552462, 12.6866167],
                    [108.9548263, 12.686171],
                    [108.9546239, 12.6859416],
                    [108.9544113, 12.6856783],
                    [108.9542282, 12.6853946],
                    [108.9541484, 12.6852461],
                    [108.9539576, 12.6848223],
                    [108.9538882, 12.6846912],
                    [108.9537303, 12.6844393],
                    [108.9536422, 12.6843192],
                    [108.9534783, 12.6841266],
                    [108.9533884, 12.6840372],
                    [108.9530734, 12.6837704],
                    [108.9529702, 12.6836718],
                    [108.9527786, 12.6834609],
                    [108.9526904, 12.6833488],
                    [108.9525363, 12.6831097],
                    [108.952471, 12.6829836],
                    [108.9522276, 12.6823832],
                    [108.9520585, 12.6820484],
                    [108.9519633, 12.6818865],
                    [108.9517655, 12.6815927],
                    [108.9512097, 12.6808681],
                    [108.9509493, 12.6805458],
                    [108.9504155, 12.6802212],
                    [108.9501554, 12.6800486],
                    [108.9497007, 12.6797224],
                    [108.9494795, 12.6795514],
                    [108.9472331, 12.6777263],
                    [108.9470991, 12.6775898],
                    [108.946985, 12.677437],
                    [108.9469017, 12.6772893],
                    [108.9467653, 12.6770079],
                    [108.9467279, 12.6769003],
                    [108.9466999, 12.6767901],
                    [108.9466815, 12.6766779],
                    [108.9466728, 12.6765646],
                    [108.9466738, 12.6764511],
                    [108.9466846, 12.676338],
                    [108.946721, 12.6761632],
                    [108.946748, 12.6759868],
                    [108.9467653, 12.6758093],
                    [108.946773, 12.6756311],
                    [108.9467711, 12.6754527],
                    [108.9467595, 12.6752747],
                    [108.9467262, 12.675022],
                    [108.9466831, 12.6748749],
                    [108.9466061, 12.6747209],
                    [108.9465519, 12.674642],
                    [108.9464903, 12.6745685],
                    [108.9462731, 12.6743703],
                    [108.9461661, 12.6742444],
                    [108.9460999, 12.6741294],
                    [108.9460264, 12.6739532],
                    [108.9459799, 12.6738695],
                    [108.9458686, 12.6737132],
                    [108.9457351, 12.6735746],
                    [108.9456389, 12.6734962],
                    [108.945549, 12.673411],
                    [108.9454659, 12.6733194],
                    [108.9453901, 12.673222],
                    [108.9452856, 12.6730564],
                    [108.9452415, 12.6729985],
                    [108.9451903, 12.6729463],
                    [108.9451328, 12.6729009],
                    [108.9450699, 12.6728628],
                    [108.9449478, 12.672815],
                    [108.9448833, 12.6728013],
                    [108.9446876, 12.6727878],
                    [108.9445634, 12.6727489],
                    [108.9444534, 12.6726807],
                    [108.9444059, 12.6726369],
                    [108.94433, 12.6725334],
                    [108.9442514, 12.6723321],
                    [108.9442157, 12.6722645],
                    [108.9441258, 12.6721405],
                    [108.9439823, 12.6719884],
                    [108.9438575, 12.6718213],
                    [108.9433776, 12.6709999],
                    [108.9432489, 12.6708381],
                    [108.9429817, 12.6705841],
                    [108.9428934, 12.6704856],
                    [108.9427468, 12.6702896],
                    [108.9426404, 12.6700916],
                    [108.9425666, 12.66988],
                    [108.9425425, 12.6697706],
                    [108.9425272, 12.6696597],
                    [108.9425148, 12.6693299],
                    [108.9424999, 12.6691996],
                    [108.9424771, 12.6690704],
                    [108.9423834, 12.6687097],
                    [108.942361, 12.6685912],
                    [108.9423202, 12.6682802],
                    [108.9422653, 12.6680948],
                    [108.9421161, 12.6677831],
                    [108.942074, 12.6676662],
                    [108.9420411, 12.6675466],
                    [108.9420175, 12.6674249],
                    [108.9419984, 12.6671924],
                    [108.9420005, 12.6670757],
                    [108.9420105, 12.6669594],
                    [108.9420628, 12.6666421],
                    [108.9420657, 12.6665397],
                    [108.9420591, 12.6664374],
                    [108.942043, 12.6663362],
                    [108.9420175, 12.6662368],
                    [108.9419849, 12.666145],
                    [108.9419432, 12.6660568],
                    [108.941893, 12.6659729],
                    [108.9418346, 12.6658942],
                    [108.9417686, 12.6658215],
                    [108.9416957, 12.6657553],
                    [108.9415597, 12.6656609],
                    [108.9413351, 12.6654876],
                    [108.9411644, 12.6653244],
                    [108.9410867, 12.6652358],
                    [108.940831, 12.6648838],
                    [108.9405858, 12.6645884],
                    [108.9404648, 12.6643998],
                    [108.9403685, 12.664197],
                    [108.9403151, 12.6640976],
                    [108.9402535, 12.6640028],
                    [108.9401843, 12.6639131],
                    [108.9400119, 12.6637276],
                    [108.9399375, 12.66361],
                    [108.9398888, 12.6634854],
                    [108.9398459, 12.6632971],
                    [108.9398121, 12.6630421],
                    [108.9398086, 12.6627851],
                    [108.9398181, 12.6626568],
                    [108.9398787, 12.6621874],
                    [108.9398658, 12.6620444],
                    [108.9398178, 12.6619087],
                    [108.9397377, 12.6617883],
                    [108.9396302, 12.6616903],
                    [108.9395681, 12.6616516],
                    [108.9394022, 12.6615791],
                    [108.9393164, 12.6615149],
                    [108.9392495, 12.6614321],
                    [108.9391763, 12.6612997],
                    [108.9391315, 12.6612384],
                    [108.9389587, 12.6610616],
                    [108.938764, 12.660908],
                    [108.9384308, 12.6607034],
                    [108.9383243, 12.6606235],
                    [108.9382238, 12.6605366],
                    [108.9381297, 12.6604431],
                    [108.9379282, 12.6602042],
                    [108.9377336, 12.6600046],
                    [108.9376783, 12.6599285],
                    [108.937631, 12.6598475],
                    [108.9375919, 12.6597624],
                    [108.9375539, 12.6596305],
                    [108.9375476, 12.6595622],
                    [108.9375613, 12.6594258],
                    [108.937581, 12.6593599],
                    [108.9376445, 12.6592376],
                    [108.9376873, 12.6591832],
                    [108.9378174, 12.6590591],
                    [108.9378472, 12.6590129],
                    [108.9378688, 12.6589625],
                    [108.9378853, 12.6588637],
                    [108.9378752, 12.6587271],
                    [108.9378354, 12.6585489],
                    [108.9378034, 12.6584632],
                    [108.9376929, 12.6582461],
                    [108.9376506, 12.6581006],
                    [108.9376406, 12.6580255],
                    [108.9376435, 12.6578743],
                    [108.9377254, 12.6575054],
                    [108.9377432, 12.6573721],
                    [108.9377528, 12.6571035],
                    [108.9377247, 12.6567852],
                    [108.9377356, 12.6566406],
                    [108.9377785, 12.6565017],
                    [108.9378113, 12.6564365],
                    [108.9378512, 12.6563751],
                    [108.9380022, 12.6562035],
                    [108.9380428, 12.6561376],
                    [108.9380747, 12.6560672],
                    [108.9381315, 12.6558628],
                    [108.9381619, 12.6557807],
                    [108.9382708, 12.6555474],
                    [108.9383106, 12.655383],
                    [108.9383177, 12.6552986],
                    [108.9383065, 12.655134],
                    [108.9383131, 12.6549732],
                    [108.9383539, 12.6548172],
                    [108.9384269, 12.6546728],
                    [108.9384746, 12.654607],
                    [108.9385897, 12.6544917],
                    [108.9387112, 12.6543936],
                    [108.9388115, 12.6542749],
                    [108.9388525, 12.6542091],
                    [108.9389706, 12.6539683],
                    [108.9390283, 12.6538718],
                    [108.9391655, 12.6536929],
                    [108.9393287, 12.6535362],
                    [108.939419, 12.6534674],
                    [108.9396143, 12.6533507],
                    [108.9398172, 12.6532501],
                    [108.9400043, 12.6531237],
                    [108.9400908, 12.6530516],
                    [108.9402261, 12.652916],
                    [108.9402738, 12.6528531],
                    [108.9403483, 12.6527148],
                    [108.9403744, 12.6526408],
                    [108.9403926, 12.6525646],
                    [108.9404052, 12.6524311],
                    [108.940395, 12.6522636],
                    [108.9403777, 12.6521531],
                    [108.940317, 12.6519375],
                    [108.9402655, 12.6518157],
                    [108.9401265, 12.6513998],
                    [108.940034, 12.6511735],
                    [108.9400061, 12.6510871],
                    [108.9399762, 12.6509083],
                    [108.9399815, 12.6507272],
                    [108.940022, 12.6505504],
                    [108.9401206, 12.6503322],
                    [108.9402513, 12.6501306],
                    [108.9404138, 12.6499347],
                    [108.940478, 12.6498176],
                    [108.9405388, 12.6496712],
                    [108.9406128, 12.6495576],
                    [108.9406598, 12.6494566],
                    [108.9406797, 12.6493473],
                    [108.9406789, 12.6492917],
                    [108.9406405, 12.6491007],
                    [108.9406387, 12.649031],
                    [108.9406622, 12.6488937],
                    [108.9407092, 12.6487854],
                    [108.9407363, 12.6486931],
                    [108.9407385, 12.6485029],
                    [108.9407805, 12.6482748],
                    [108.9407684, 12.6481861],
                    [108.9407513, 12.6481444],
                    [108.9406667, 12.6480274],
                    [108.9406461, 12.6479824],
                    [108.9406266, 12.6478858],
                    [108.9406282, 12.6478365],
                    [108.9406616, 12.6477185],
                    [108.9406905, 12.647664],
                    [108.940727, 12.647614],
                    [108.9408347, 12.6475093],
                    [108.9408803, 12.6474303],
                    [108.9402492, 12.6474436],
                    [108.939836, 12.6474804],
                    [108.9394265, 12.647546],
                    [108.9390679, 12.6476281],
                    [108.9385524, 12.647733],
                    [108.9382485, 12.647819],
                    [108.9381008, 12.6478747],
                    [108.9378705, 12.6479816],
                    [108.9377873, 12.64803],
                    [108.9376316, 12.6481422],
                    [108.9374089, 12.6483442],
                    [108.9373244, 12.6484029],
                    [108.9372348, 12.6484538],
                    [108.9371408, 12.6484966],
                    [108.9370432, 12.6485308],
                    [108.9369428, 12.6485562],
                    [108.9368496, 12.6485715],
                    [108.9367553, 12.6485774],
                    [108.9366609, 12.6485741],
                    [108.9365673, 12.6485614],
                    [108.9364193, 12.6485191],
                    [108.9362844, 12.6484461],
                    [108.936224, 12.6483992],
                    [108.9361386, 12.6483104],
                    [108.935591, 12.6475826],
                    [108.9350549, 12.6469459],
                    [108.9347418, 12.6465536],
                    [108.9345701, 12.6463074],
                    [108.9343626, 12.6459725],
                    [108.9342052, 12.6457849],
                    [108.9340223, 12.6456209],
                    [108.9339224, 12.6455488],
                    [108.9337081, 12.6454261],
                    [108.9333974, 12.6452849],
                    [108.9332479, 12.645203],
                    [108.9329624, 12.6450178],
                    [108.9326982, 12.6448074],
                    [108.9324543, 12.6445747],
                    [108.9323407, 12.6444506],
                    [108.9318359, 12.6438192],
                    [108.9317145, 12.64369],
                    [108.9316452, 12.6436341],
                    [108.9314926, 12.6435417],
                    [108.9313852, 12.6434969],
                    [108.9312746, 12.6434607],
                    [108.9311613, 12.6434332],
                    [108.9310461, 12.6434146],
                    [108.9309298, 12.6434051],
                    [108.9308131, 12.6434047],
                    [108.9306967, 12.6434134],
                    [108.9305814, 12.6434312],
                    [108.9302917, 12.643504],
                    [108.9302011, 12.6435147],
                    [108.9300187, 12.6435108],
                    [108.9298403, 12.6434737],
                    [108.9295569, 12.6433715],
                    [108.9293333, 12.643315],
                    [108.9291841, 12.6432599],
                    [108.9290476, 12.6431793],
                    [108.9289856, 12.6431303],
                    [108.9288141, 12.642956],
                    [108.92868, 12.6428575],
                    [108.9285302, 12.6427836],
                    [108.9283391, 12.6427199],
                    [108.9282405, 12.6426566],
                    [108.9281617, 12.642571],
                    [108.9281313, 12.6425216],
                    [108.9280905, 12.6424107],
                    [108.9280725, 12.6422358],
                    [108.9280562, 12.6421827],
                    [108.9280007, 12.6420861],
                    [108.9279628, 12.6420447],
                    [108.9278831, 12.6419869],
                    [108.9277911, 12.6419505],
                    [108.9273902, 12.6417573],
                    [108.927142, 12.6416207],
                    [108.9270615, 12.6415625],
                    [108.9270056, 12.6414813],
                    [108.9269829, 12.6414041],
                    [108.926979, 12.64131],
                    [108.9269999, 12.6411863],
                    [108.9270277, 12.6411137],
                    [108.9271061, 12.6409791],
                    [108.9272118, 12.6408636],
                    [108.9273052, 12.6407932],
                    [108.927331, 12.6407651],
                    [108.9273588, 12.6406953],
                    [108.9273558, 12.6406379],
                    [108.9273137, 12.6405477],
                    [108.9272553, 12.6404891],
                    [108.9271286, 12.6403226],
                    [108.9270467, 12.6402533],
                    [108.9269471, 12.6402113],
                    [108.9268936, 12.6402019],
                    [108.9268392, 12.6402006],
                    [108.9266566, 12.6402296],
                    [108.9266111, 12.6402259],
                    [108.9265253, 12.640196],
                    [108.9264371, 12.6401339],
                    [108.9263867, 12.6401204],
                    [108.9263663, 12.640123],
                    [108.926332, 12.6401446],
                    [108.9262944, 12.6402127],
                    [108.9262652, 12.6402438],
                    [108.9261916, 12.6402875],
                    [108.9261067, 12.6403017],
                    [108.9259594, 12.6402839],
                    [108.9258119, 12.6402999],
                    [108.9256722, 12.6403488],
                    [108.9256022, 12.6403883],
                    [108.9255375, 12.6404357],
                    [108.925441, 12.6405336],
                    [108.9253406, 12.6406745],
                    [108.9252486, 12.6408315],
                    [108.9251653, 12.6409364],
                    [108.925115, 12.6409812],
                    [108.9250004, 12.6410527],
                    [108.9249376, 12.6410784],
                    [108.924805, 12.6411081],
                    [108.924669, 12.6411078],
                    [108.9245332, 12.641092],
                    [108.9243973, 12.641106],
                    [108.9242679, 12.6411492],
                    [108.9240686, 12.6412683],
                    [108.9239931, 12.6413014],
                    [108.9239081, 12.6413289],
                    [108.9238207, 12.6413474],
                    [108.9237317, 12.641357],
                    [108.9236423, 12.6413573],
                    [108.9235532, 12.6413485],
                    [108.9234664, 12.6413309],
                    [108.9232994, 12.6412731],
                    [108.9232206, 12.6412334],
                    [108.9229739, 12.6410764],
                    [108.9227331, 12.6409501],
                    [108.9224788, 12.6408523],
                    [108.9222145, 12.6407841],
                    [108.9220798, 12.6407615],
                    [108.9218662, 12.6407416],
                    [108.9215617, 12.6407456],
                    [108.9214907, 12.6407408],
                    [108.9214208, 12.6407271],
                    [108.9213535, 12.6407046],
                    [108.9212896, 12.6406739],
                    [108.9212304, 12.6406353],
                    [108.9211371, 12.6405466],
                    [108.9211029, 12.6404992],
                    [108.9210533, 12.6403944],
                    [108.920952, 12.6399519],
                    [108.9209054, 12.639793],
                    [108.9207378, 12.6393558],
                    [108.9206133, 12.6389343],
                    [108.9205545, 12.6388099],
                    [108.9205078, 12.6387374],
                    [108.9203949, 12.6386067],
                    [108.9202593, 12.6384984],
                    [108.9201844, 12.6384539],
                    [108.9200425, 12.6383934],
                    [108.9199678, 12.6383734],
                    [108.9198142, 12.6383545],
                    [108.9195592, 12.6383583],
                    [108.919432, 12.6383478],
                    [108.9193058, 12.6383291],
                    [108.9191048, 12.6382813],
                    [108.9189592, 12.6382201],
                    [108.9188912, 12.6381803],
                    [108.9187708, 12.6380866],
                    [108.9186716, 12.6379776],
                    [108.9185091, 12.6380009],
                    [108.9183482, 12.6380332],
                    [108.9181894, 12.6380743],
                    [108.9179485, 12.6381553],
                    [108.9176984, 12.6382588],
                    [108.9174295, 12.6383844],
                    [108.9172306, 12.6385051],
                    [108.9170387, 12.6386455],
                    [108.9169833, 12.6386717],
                    [108.9168648, 12.638703],
                    [108.916742, 12.6387049],
                    [108.9166814, 12.6386947],
                    [108.9164756, 12.6386296],
                    [108.9163703, 12.6386057],
                    [108.9161491, 12.6385732],
                    [108.9159035, 12.6385588],
                    [108.9156353, 12.6385691],
                    [108.9154816, 12.6387274],
                    [108.9151582, 12.6390283],
                    [108.9148146, 12.6393071],
                    [108.914526, 12.6395093],
                    [108.9139843, 12.6398168],
                    [108.9137139, 12.6399894],
                    [108.9136279, 12.6400362],
                    [108.9134448, 12.6401069],
                    [108.9132519, 12.6401452],
                    [108.9131536, 12.6401518],
                    [108.9129837, 12.6401432],
                    [108.9127717, 12.6401093],
                    [108.9122678, 12.6400023],
                    [108.9120841, 12.6399719],
                    [108.9117628, 12.639935],
                    [108.9112608, 12.6399074],
                    [108.9109236, 12.6398584],
                    [108.9105928, 12.6397782],
                    [108.9103505, 12.639698],
                    [108.9101055, 12.6396264],
                    [108.9094154, 12.6394575],
                    [108.9086071, 12.6391978],
                    [108.9084698, 12.6391476],
                    [108.9083361, 12.6390888],
                    [108.9082068, 12.6390214],
                    [108.9080086, 12.6388959],
                    [108.9078761, 12.6387778],
                    [108.9078183, 12.6387108],
                    [108.9077218, 12.6385633],
                    [108.9076838, 12.6384839],
                    [108.9075903, 12.6382004],
                    [108.9074935, 12.6380125],
                    [108.9073879, 12.6378689],
                    [108.9072397, 12.63771],
                    [108.9070243, 12.6375157],
                    [108.9069092, 12.637427],
                    [108.906513, 12.6371656],
                    [108.9063245, 12.6370205],
                    [108.9062275, 12.6369107],
                    [108.906189, 12.6368486],
                    [108.9061343, 12.6367138],
                    [108.9060987, 12.6365091],
                    [108.9060795, 12.6364441],
                    [108.9059604, 12.6361882],
                    [108.9058989, 12.6359833],
                    [108.905874, 12.6358114],
                    [108.9058702, 12.6357246],
                    [108.9058775, 12.6350934],
                    [108.9058191, 12.6350145],
                    [108.9057531, 12.6349415],
                    [108.9056802, 12.6348752],
                    [108.905601, 12.6348161],
                    [108.9055163, 12.6347648],
                    [108.9051264, 12.6345961],
                    [108.9049463, 12.6344909],
                    [108.9047842, 12.6343609],
                    [108.904711, 12.6342874],
                    [108.9045407, 12.6340829],
                    [108.9044813, 12.634027],
                    [108.9043486, 12.6339313],
                    [108.9041609, 12.6338371],
                    [108.9040242, 12.6337792],
                    [108.9037424, 12.6336853],
                    [108.9037189, 12.6335876],
                    [108.9036317, 12.6333225],
                    [108.9036137, 12.6332351],
                    [108.9035995, 12.6330608],
                    [108.903603, 12.6329734],
                    [108.9015305, 12.6311919],
                    [108.9014008, 12.6310595],
                    [108.9013432, 12.6309871],
                    [108.9011407, 12.6306597],
                    [108.9010074, 12.6304812],
                    [108.9008529, 12.6303199],
                    [108.9007683, 12.6302464],
                    [108.9005426, 12.6300771],
                    [108.9004801, 12.6300198],
                    [108.9003834, 12.6299124],
                    [108.9002803, 12.629766],
                    [108.900221, 12.6296626],
                    [108.9001, 12.6294014],
                    [108.9000034, 12.6292621],
                    [108.8998877, 12.629125],
                    [108.8997972, 12.6289709],
                    [108.8997342, 12.6288043],
                    [108.8996991, 12.6286123],
                    [108.899656, 12.6284387],
                    [108.8995954, 12.628307],
                    [108.8995098, 12.6281891],
                    [108.8994587, 12.6281368],
                    [108.8993487, 12.628052],
                    [108.8991772, 12.6279545],
                    [108.8988116, 12.6277783],
                    [108.8986945, 12.6277074],
                    [108.8983887, 12.6274928],
                    [108.897981, 12.6272258],
                    [108.8977402, 12.6270464],
                    [108.8975197, 12.6268437],
                    [108.8973843, 12.6266904],
                    [108.897325, 12.6266073],
                    [108.8972247, 12.6264301],
                    [108.8971415, 12.6262258],
                    [108.8970423, 12.6259229],
                    [108.8969519, 12.6257344],
                    [108.8968974, 12.6256449],
                    [108.8967526, 12.6254512],
                    [108.896668, 12.6253541],
                    [108.896484, 12.6251733],
                    [108.8963851, 12.62509],
                    [108.8965818, 12.6250701],
                    [108.8966807, 12.6250719],
                    [108.8967791, 12.6250817],
                    [108.8969789, 12.625127],
                    [108.8971807, 12.6251631],
                    [108.897384, 12.6251899],
                    [108.8975883, 12.6252075],
                    [108.8979144, 12.6252161],
                    [108.8980351, 12.6252263],
                    [108.8981546, 12.6252461],
                    [108.898272, 12.6252754],
                    [108.8983865, 12.625314],
                    [108.8984973, 12.6253616],
                    [108.8986038, 12.625418],
                    [108.8987052, 12.6254827],
                    [108.8989407, 12.6256559],
                    [108.8991091, 12.6257599],
                    [108.8992083, 12.6257872],
                    [108.8993112, 12.6257793],
                    [108.8994048, 12.6257371],
                    [108.8994445, 12.6257046],
                    [108.8994965, 12.6256358],
                    [108.8995624, 12.6254724],
                    [108.8995903, 12.6253418],
                    [108.8996298, 12.6252215],
                    [108.8996923, 12.625111],
                    [108.8997332, 12.6250586],
                    [108.8999673, 12.6248218],
                    [108.9002929, 12.6245494],
                    [108.900527, 12.6243267],
                    [108.9007632, 12.6240621],
                    [108.9008725, 12.6239227],
                    [108.9026105, 12.6237447],
                    [108.903022, 12.6233211],
                    [108.9032185, 12.6231011],
                    [108.9035332, 12.6227344],
                    [108.9036678, 12.6225466],
                    [108.9038349, 12.6223616],
                    [108.9040214, 12.6221952],
                    [108.9042007, 12.6220653],
                    [108.904295, 12.6220068],
                    [108.9047752, 12.6220003],
                    [108.9053455, 12.6220231],
                    [108.9054358, 12.6220196],
                    [108.9055253, 12.6220068],
                    [108.905613, 12.6219849],
                    [108.9056977, 12.6219541],
                    [108.9057787, 12.6219147],
                    [108.905855, 12.6218673],
                    [108.9059257, 12.6218123],
                    [108.9059901, 12.6217503],
                    [108.906062, 12.6216549],
                    [108.9060871, 12.6216008],
                    [108.9061131, 12.6214851],
                    [108.9061135, 12.6214257],
                    [108.9060823, 12.6211538],
                    [108.9060789, 12.6210333],
                    [108.9060838, 12.6204126],
                    [108.906115, 12.6201366],
                    [108.9061435, 12.6200006],
                    [108.9062262, 12.6197349],
                    [108.9063867, 12.619379],
                    [108.9065695, 12.6190334],
                    [108.9067649, 12.6187129],
                    [108.9068699, 12.618557],
                    [108.9077657, 12.6182272],
                    [108.9078329, 12.6179775],
                    [108.9078779, 12.617856],
                    [108.9079903, 12.6176222],
                    [108.9081308, 12.6174034],
                    [108.9083044, 12.6171954],
                    [108.9084047, 12.6170964],
                    [108.9089334, 12.6166479],
                    [108.9090955, 12.6165211],
                    [108.909595, 12.6161699],
                    [108.9097414, 12.6160585],
                    [108.9100163, 12.6158153],
                    [108.9101443, 12.6156842],
                    [108.9103189, 12.615482],
                    [108.9104058, 12.6153389],
                    [108.9104386, 12.615262],
                    [108.9104813, 12.615101],
                    [108.9105064, 12.6147089],
                    [108.9105251, 12.6145836],
                    [108.9105876, 12.614338],
                    [108.9106826, 12.6141026],
                    [108.9107418, 12.6139899],
                    [108.9108823, 12.6137772],
                    [108.9109629, 12.6136781],
                    [108.9110968, 12.613539],
                    [108.9113785, 12.6132745],
                    [108.9115262, 12.6131494],
                    [108.9116709, 12.6130348],
                    [108.9119714, 12.6128196],
                    [108.9128351, 12.6121652],
                    [108.9128929, 12.6119365],
                    [108.9130604, 12.6115056],
                    [108.913113, 12.6113464],
                    [108.9131565, 12.6111847],
                    [108.913191, 12.6110209],
                    [108.9132162, 12.6108555],
                    [108.913237, 12.6105919],
                    [108.9132693, 12.6104003],
                    [108.9132965, 12.6103068],
                    [108.9133405, 12.6101929],
                    [108.9134323, 12.6098886],
                    [108.9135199, 12.6097127],
                    [108.9136371, 12.6095542],
                    [108.9137804, 12.6094175],
                    [108.9139456, 12.6093069],
                    [108.914122, 12.6092094],
                    [108.9142628, 12.6090984],
                    [108.9144283, 12.60893],
                    [108.9146971, 12.6086274],
                    [108.9149565, 12.60838],
                    [108.9150646, 12.6082682],
                    [108.9151655, 12.6081501],
                    [108.9152587, 12.6080261],
                    [108.9153439, 12.6078968],
                    [108.9154208, 12.6077626],
                    [108.9156085, 12.6073856],
                    [108.915879, 12.6069245],
                    [108.9161771, 12.60648],
                    [108.9166015, 12.6058865],
                    [108.9167082, 12.6057181],
                    [108.9168082, 12.6055459],
                    [108.9170572, 12.6050683],
                    [108.9171464, 12.6049235],
                    [108.917243, 12.6047831],
                    [108.9175087, 12.6044451],
                    [108.91765, 12.6042321],
                    [108.9177089, 12.6041207],
                    [108.9177589, 12.6040053],
                    [108.9177997, 12.6038864],
                    [108.917831, 12.6037649],
                    [108.9178528, 12.6036414],
                    [108.9178648, 12.6035166],
                    [108.9178628, 12.6033063],
                    [108.9178299, 12.6031394],
                    [108.9178013, 12.603059],
                    [108.9177214, 12.602908],
                    [108.9176136, 12.6027745],
                    [108.9174144, 12.6026021],
                    [108.9173549, 12.6025367],
                    [108.9172554, 12.6023913],
                    [108.9171818, 12.6022227],
                    [108.9171423, 12.6020436],
                    [108.9171359, 12.6019521],
                    [108.9171499, 12.6017693],
                    [108.9171993, 12.6015925],
                    [108.9172822, 12.6014281],
                    [108.9173906, 12.6012874],
                    [108.9179148, 12.6007268],
                    [108.9181352, 12.6005358],
                    [108.9183766, 12.6003705],
                    [108.9185379, 12.600285],
                    [108.9186233, 12.6002518],
                    [108.9188006, 12.6002054],
                    [108.918999, 12.6001864],
                    [108.9191066, 12.6001899],
                    [108.9192134, 12.600203],
                    [108.918596, 12.599585],
                    [108.9184491, 12.599456],
                    [108.9182961, 12.599334],
                    [108.9179706, 12.5990883],
                    [108.9178165, 12.5989552],
                    [108.9174983, 12.5986555],
                    [108.9173065, 12.5985204],
                    [108.9172038, 12.5984632],
                    [108.9169872, 12.5983707],
                    [108.9167687, 12.5982956],
                    [108.916562, 12.5981938],
                    [108.916464, 12.5981334],
                    [108.9162807, 12.5979946],
                    [108.9161962, 12.5979167],
                    [108.9160173, 12.5977718],
                    [108.9156544, 12.5974939],
                    [108.9154944, 12.5973343],
                    [108.9154225, 12.5972473],
                    [108.9153564, 12.5971561],
                    [108.9152025, 12.5969143],
                    [108.9150414, 12.5967021],
                    [108.9149541, 12.5966012],
                    [108.9145464, 12.5961823],
                    [108.9140815, 12.5956835],
                    [108.9134091, 12.5950096],
                    [108.9132017, 12.5950121],
                    [108.9129968, 12.594981],
                    [108.9128, 12.5949172],
                    [108.9127064, 12.5948735],
                    [108.9125409, 12.594772],
                    [108.9123916, 12.594649],
                    [108.9123238, 12.5945803],
                    [108.9122611, 12.594507],
                    [108.9121439, 12.5943345],
                    [108.9119325, 12.5940569],
                    [108.9117386, 12.5938536],
                    [108.9115208, 12.5936746],
                    [108.9113962, 12.5935901],
                    [108.9112677, 12.5935112],
                    [108.9111357, 12.5934381],
                    [108.9109476, 12.5933467],
                    [108.9108995, 12.5933143],
                    [108.9108574, 12.5932747],
                    [108.9108226, 12.5932289],
                    [108.910796, 12.5931781],
                    [108.9107782, 12.5931238],
                    [108.9107698, 12.5930673],
                    [108.9107784, 12.5928824],
                    [108.9107752, 12.5927898],
                    [108.9107473, 12.5925847],
                    [108.9107203, 12.5924845],
                    [108.9106848, 12.5923869],
                    [108.9106003, 12.5922195],
                    [108.9104981, 12.5920868],
                    [108.9104377, 12.5920282],
                    [108.9103011, 12.5919294],
                    [108.9101475, 12.5918579],
                    [108.9099665, 12.5918103],
                    [108.9097591, 12.5917845],
                    [108.9096455, 12.5917646],
                    [108.909534, 12.5917356],
                    [108.9094253, 12.5916977],
                    [108.9093202, 12.5916511],
                    [108.9092195, 12.5915962],
                    [108.9090103, 12.5914496],
                    [108.9084309, 12.5909679],
                    [108.9078999, 12.59057],
                    [108.9080162, 12.5903351],
                    [108.9080849, 12.5901642],
                    [108.9080962, 12.5901122],
                    [108.9080959, 12.5900063],
                    [108.9080843, 12.5899544],
                    [108.9080393, 12.589858],
                    [108.9079374, 12.5897376],
                    [108.9077944, 12.5895981],
                    [108.9076316, 12.5894811],
                    [108.9075269, 12.5894231],
                    [108.9074275, 12.589357],
                    [108.907334, 12.5892832],
                    [108.907247, 12.589202],
                    [108.9071671, 12.5891142],
                    [108.9070949, 12.5890203],
                    [108.9070308, 12.5889209],
                    [108.906925, 12.5887364],
                    [108.9067859, 12.5885481],
                    [108.9062262, 12.5879209],
                    [108.9059913, 12.5876267],
                    [108.905881, 12.5874744],
                    [108.9056786, 12.5871659],
                    [108.9055892, 12.5870087],
                    [108.9055073, 12.5868476],
                    [108.9053661, 12.5865197],
                    [108.9053234, 12.5864419],
                    [108.9052725, 12.586369],
                    [108.9052139, 12.5863018],
                    [108.9051483, 12.586241],
                    [108.9050764, 12.5861874],
                    [108.9049991, 12.5861417],
                    [108.9049172, 12.5861042],
                    [108.9046712, 12.5860184],
                    [108.9045534, 12.5859632],
                    [108.9042834, 12.5858096],
                    [108.9041998, 12.5857744],
                    [108.9040251, 12.5857255],
                    [108.9039351, 12.5857121],
                    [108.9037689, 12.5857072],
                    [108.9036939, 12.585699],
                    [108.9036205, 12.585682],
                    [108.9035497, 12.5856563],
                    [108.9034828, 12.5856223],
                    [108.9033663, 12.585534],
                    [108.9030766, 12.5852082],
                    [108.9028396, 12.5849871],
                    [108.9025185, 12.5846318],
                    [108.9023851, 12.5845011],
                    [108.9021452, 12.5842933],
                    [108.9015481, 12.5838424],
                    [108.9013796, 12.5836802],
                    [108.9013032, 12.5835919],
                    [108.9011675, 12.5834026],
                    [108.9010328, 12.5831507],
                    [108.9009779, 12.5830191],
                    [108.9008641, 12.5826756],
                    [108.9007902, 12.5825208],
                    [108.9007127, 12.5824246],
                    [108.900665, 12.5823846],
                    [108.900556, 12.5823241],
                    [108.9004002, 12.5822847],
                    [108.90032, 12.5822769],
                    [108.9000912, 12.5822836],
                    [108.8999449, 12.5822589],
                    [108.8998747, 12.5822351],
                    [108.8997609, 12.582177],
                    [108.8995778, 12.5820991],
                    [108.8994389, 12.5820674],
                    [108.8992964, 12.5820639],
                    [108.8992256, 12.5820728],
                    [108.8989805, 12.5821301],
                    [108.8987248, 12.5821537],
                    [108.8985938, 12.5821522],
                    [108.8984634, 12.5821417],
                    [108.898334, 12.5821222],
                    [108.8982063, 12.5820937],
                    [108.8980999, 12.5820628],
                    [108.8977881, 12.5819488],
                    [108.8975841, 12.5818634],
                    [108.8970619, 12.581609],
                    [108.8966942, 12.5814571],
                    [108.8960451, 12.5812468],
                    [108.8958192, 12.5811618],
                    [108.8952641, 12.580935],
                    [108.8948314, 12.5807801],
                    [108.8945224, 12.5806996],
                    [108.8942064, 12.5806508],
                    [108.893887, 12.5806343],
                    [108.8936171, 12.5806457],
                    [108.893397, 12.580638],
                    [108.8931801, 12.5806003],
                    [108.8930742, 12.5805705],
                    [108.892867, 12.5804881],
                    [108.8927676, 12.5804343],
                    [108.8926732, 12.5803725],
                    [108.892522, 12.5802465],
                    [108.8924141, 12.5801178],
                    [108.8923697, 12.5800467],
                    [108.8923014, 12.5798944],
                    [108.8922782, 12.5798143],
                    [108.8922388, 12.5795537],
                    [108.8921864, 12.5793808],
                    [108.8921499, 12.5792979],
                    [108.8920158, 12.5790466],
                    [108.8919162, 12.5787966],
                    [108.8918774, 12.5786679],
                    [108.8918225, 12.578405],
                    [108.891779, 12.5779366],
                    [108.8917511, 12.5777706],
                    [108.8917137, 12.5776064],
                    [108.8916233, 12.5773169],
                    [108.8913421, 12.5765325],
                    [108.8912594, 12.5763345],
                    [108.8911292, 12.5760665],
                    [108.8911135, 12.5760206],
                    [108.8911051, 12.5759245],
                    [108.8911277, 12.5758306],
                    [108.89115, 12.5757874],
                    [108.8912032, 12.5757232],
                    [108.8912858, 12.5756591],
                    [108.8914004, 12.575595],
                    [108.8916904, 12.5754908],
                    [108.891931, 12.5754357],
                    [108.8921489, 12.5754095],
                    [108.8924366, 12.5753976],
                    [108.8928205, 12.5754026],
                    [108.8932604, 12.5754235],
                    [108.8935367, 12.5754164],
                    [108.8938106, 12.57538],
                    [108.8939456, 12.575351],
                    [108.8940789, 12.5753149],
                    [108.8942858, 12.575243],
                    [108.8944274, 12.575165],
                    [108.894551, 12.5750618],
                    [108.8946519, 12.5749373],
                    [108.8946927, 12.5748685],
                    [108.8947268, 12.5747951],
                    [108.8948055, 12.574566],
                    [108.8948486, 12.5744105],
                    [108.8948825, 12.5742529],
                    [108.8949198, 12.5739718],
                    [108.8949287, 12.5737271],
                    [108.8947678, 12.5736224],
                    [108.8945759, 12.5734528],
                    [108.8944049, 12.5732632],
                    [108.8943279, 12.5731617],
                    [108.8942097, 12.5729716],
                    [108.8941622, 12.5728705],
                    [108.8941228, 12.5727661],
                    [108.8940567, 12.5725409],
                    [108.8940275, 12.5724863],
                    [108.8939233, 12.5723371],
                    [108.8938071, 12.5720782],
                    [108.8937592, 12.5719941],
                    [108.8936666, 12.5718708],
                    [108.8935477, 12.5717714],
                    [108.8934803, 12.5717323],
                    [108.8933408, 12.5716747],
                    [108.8930404, 12.5715857],
                    [108.8926005, 12.571481],
                    [108.8928022, 12.5714627],
                    [108.8929989, 12.5714156],
                    [108.8931969, 12.5713354],
                    [108.8932955, 12.5712812],
                    [108.8933891, 12.5712192],
                    [108.893406, 12.5708312],
                    [108.8934696, 12.5703029],
                    [108.8934832, 12.5701029],
                    [108.8934886, 12.5697021],
                    [108.8934803, 12.5695018],
                    [108.8934589, 12.569289],
                    [108.8934116, 12.5690802],
                    [108.8933784, 12.5689783],
                    [108.8932526, 12.5686776],
                    [108.8931987, 12.5684663],
                    [108.8931807, 12.5682494],
                    [108.8931853, 12.5681405],
                    [108.8932171, 12.5679435],
                    [108.8932443, 12.5678473],
                    [108.8939255, 12.567219],
                    [108.8939202, 12.5667949],
                    [108.8946122, 12.5660933],
                    [108.8956261, 12.5650199],
                    [108.8959354, 12.5647083],
                    [108.8960855, 12.5645931],
                    [108.8961668, 12.5645442],
                    [108.8963395, 12.5644649],
                    [108.8964651, 12.5644254],
                    [108.8965932, 12.5643947],
                    [108.8967232, 12.5643729],
                    [108.8968545, 12.5643602],
                    [108.897067, 12.5643588],
                    [108.8972783, 12.5643811],
                    [108.8969604, 12.5640518],
                    [108.8966168, 12.563748],
                    [108.896282, 12.563494],
                    [108.8958076, 12.5631739],
                    [108.895328, 12.5628688],
                    [108.8950229, 12.5626543],
                    [108.8947356, 12.5624176],
                    [108.8944399, 12.5621352],
                    [108.8942985, 12.5619878],
                    [108.8940661, 12.5617254],
                    [108.8938924, 12.5614902],
                    [108.8938146, 12.5613667],
                    [108.8936999, 12.5611545],
                    [108.8935825, 12.5608937],
                    [108.89345, 12.5605367],
                    [108.8933945, 12.5603547],
                    [108.8933116, 12.5600142],
                    [108.893255, 12.5596687],
                    [108.8932039, 12.5592211],
                    [108.8931692, 12.5589985],
                    [108.8930082, 12.5582131],
                    [108.8929413, 12.5579501],
                    [108.8929009, 12.5578204],
                    [108.8928357, 12.5576381],
                    [108.8927615, 12.5574591],
                    [108.8927507, 12.5571083],
                    [108.8926577, 12.55704],
                    [108.8925706, 12.5569647],
                    [108.8924899, 12.5568829],
                    [108.8924161, 12.556795],
                    [108.8923498, 12.5567016],
                    [108.8922914, 12.5566034],
                    [108.8922411, 12.5565009],
                    [108.8921695, 12.5562972],
                    [108.8921303, 12.5560853],
                    [108.8921231, 12.5559777],
                    [108.8921243, 12.55587],
                    [108.8921472, 12.5557626],
                    [108.892237, 12.5555066],
                    [108.8923123, 12.5552474],
                    [108.8923606, 12.5549879],
                    [108.8923752, 12.5548568],
                    [108.8924409, 12.5548659],
                    [108.8925697, 12.5549013],
                    [108.8926642, 12.5549127],
                    [108.8927603, 12.5549024],
                    [108.8928594, 12.5548672],
                    [108.8929352, 12.5543483],
                    [108.8930553, 12.5536163],
                    [108.8931902, 12.5529891],
                    [108.8932032, 12.5529003],
                    [108.8932051, 12.5527211],
                    [108.8931939, 12.5526321],
                    [108.8931477, 12.5524586],
                    [108.8930923, 12.5523372],
                    [108.8929545, 12.5521389],
                    [108.8929129, 12.5520624],
                    [108.8928499, 12.5519005],
                    [108.8928151, 12.5517308],
                    [108.8928086, 12.551641],
                    [108.8928118, 12.5513711],
                    [108.8928332, 12.5510398],
                    [108.8928783, 12.5507388],
                    [108.8929876, 12.5502829],
                    [108.8929906, 12.5501189],
                    [108.8929788, 12.5500376],
                    [108.8929291, 12.5498809],
                    [108.8928918, 12.5498072],
                    [108.8927944, 12.5496736],
                    [108.8926703, 12.549563],
                    [108.8924852, 12.5494392],
                    [108.8923212, 12.5492897],
                    [108.8921821, 12.5491179],
                    [108.8920765, 12.5489402],
                    [108.8920329, 12.5488467],
                    [108.8919648, 12.5486524],
                    [108.8919407, 12.5485524],
                    [108.8918823, 12.5482178],
                    [108.8917908, 12.5478904],
                    [108.8917329, 12.5477303],
                    [108.8916671, 12.5475732],
                    [108.8915699, 12.5473739],
                    [108.8913533, 12.5469864],
                    [108.8911081, 12.5466155],
                    [108.8907064, 12.5460943],
                    [108.8903033, 12.5455383],
                    [108.8901546, 12.5453563],
                    [108.8899444, 12.5451218],
                    [108.8898203, 12.5450208],
                    [108.8897517, 12.5449789],
                    [108.8896043, 12.5449144],
                    [108.8895267, 12.5448922],
                    [108.8893986, 12.5448712],
                    [108.8892723, 12.5448415],
                    [108.8891485, 12.544803],
                    [108.8888398, 12.5446775],
                    [108.8886417, 12.5446279],
                    [108.8885403, 12.5446144],
                    [108.8883358, 12.5446104],
                    [108.888014, 12.5446513],
                    [108.8878828, 12.5446577],
                    [108.8877515, 12.5446546],
                    [108.8876208, 12.544642],
                    [108.8874914, 12.5446201],
                    [108.887364, 12.5445889],
                    [108.8872394, 12.5445486],
                    [108.8871181, 12.5444995],
                    [108.8867854, 12.5443206],
                    [108.8866246, 12.5442219],
                    [108.8862437, 12.5439706],
                    [108.8860283, 12.5438373],
                    [108.8856107, 12.5435517],
                    [108.8850119, 12.543083],
                    [108.8844749, 12.5426265],
                    [108.8842059, 12.5423782],
                    [108.8840026, 12.5421813],
                    [108.8835679, 12.5417384],
                    [108.8833394, 12.5415487],
                    [108.8830207, 12.5413263],
                    [108.8828411, 12.5411684],
                    [108.8827587, 12.5410821],
                    [108.8825273, 12.5407975],
                    [108.882441, 12.5407083],
                    [108.8822971, 12.5405815],
                    [108.8822071, 12.5404817],
                    [108.8821385, 12.5403669],
                    [108.8820602, 12.5401568],
                    [108.8820205, 12.5400883],
                    [108.8819728, 12.5400249],
                    [108.8819176, 12.5399675],
                    [108.8818559, 12.5399169],
                    [108.8817884, 12.5398739],
                    [108.8815576, 12.5397767],
                    [108.8814835, 12.5397349],
                    [108.8813491, 12.5396318],
                    [108.88129, 12.5395715],
                    [108.8811904, 12.5394358],
                    [108.8811566, 12.5393736],
                    [108.8810651, 12.5391598],
                    [108.8810182, 12.5390814],
                    [108.8809036, 12.5389384],
                    [108.8807646, 12.5388179],
                    [108.8806873, 12.5387673],
                    [108.8804631, 12.5386528],
                    [108.8803985, 12.5386064],
                    [108.8802868, 12.5384946],
                    [108.8802409, 12.5384304],
                    [108.8801652, 12.5382764],
                    [108.8801207, 12.5381096],
                    [108.8801099, 12.537936],
                    [108.8801175, 12.5378492],
                    [108.8798158, 12.5374054],
                    [108.8795446, 12.5369681],
                    [108.8793085, 12.5365359],
                    [108.8792002, 12.5363149],
                    [108.8791049, 12.5361053],
                    [108.8790185, 12.5358921],
                    [108.8789411, 12.5356756],
                    [108.8787655, 12.5350912],
                    [108.8786988, 12.5349127],
                    [108.8786237, 12.5347375],
                    [108.8784532, 12.5344055],
                    [108.8783125, 12.5340965],
                    [108.8782079, 12.5338004],
                    [108.8781364, 12.5335332],
                    [108.8780669, 12.533181],
                    [108.878031, 12.5329445],
                    [108.8779952, 12.5326067],
                    [108.8779772, 12.5325077],
                    [108.8779502, 12.5324106],
                    [108.8779144, 12.5323164],
                    [108.87787, 12.5322256],
                    [108.8778175, 12.5321391],
                    [108.8777572, 12.5320576],
                    [108.8775694, 12.5318638],
                    [108.8773822, 12.5317073],
                    [108.8772829, 12.531636],
                    [108.876743, 12.531182],
                    [108.8764891, 12.5309568],
                    [108.8760836, 12.5305809],
                    [108.8757899, 12.5302716],
                    [108.8755202, 12.529942],
                    [108.8753325, 12.5296793],
                    [108.8750388, 12.529245],
                    [108.8748375, 12.5288965],
                    [108.8747477, 12.5287166],
                    [108.8746565, 12.5285117],
                    [108.8745724, 12.5283039],
                    [108.8743937, 12.527815],
                    [108.874259, 12.5275502],
                    [108.8741799, 12.527424],
                    [108.8740019, 12.5271893],
                    [108.8737999, 12.5269789],
                    [108.873452, 12.5265897],
                    [108.8734018, 12.5265211],
                    [108.8733225, 12.5263716],
                    [108.8731947, 12.5259673],
                    [108.8731812, 12.5258247],
                    [108.8732002, 12.5256827],
                    [108.8732218, 12.5256142],
                    [108.8733278, 12.5254043],
                    [108.8733549, 12.5253274],
                    [108.8733851, 12.5251678],
                    [108.873388, 12.5250865],
                    [108.8733692, 12.5249252],
                    [108.8733476, 12.5248467],
                    [108.8733254, 12.5247871],
                    [108.8731961, 12.5245175],
                    [108.8730746, 12.5241968],
                    [108.8729812, 12.5238672],
                    [108.8729333, 12.5236343],
                    [108.8729321, 12.5235016],
                    [108.8729612, 12.5233718],
                    [108.8730191, 12.5232517],
                    [108.8731205, 12.5231138],
                    [108.8731552, 12.5230162],
                    [108.8731588, 12.522913],
                    [108.8731488, 12.5228622],
                    [108.8731311, 12.5228134],
                    [108.87305, 12.5226797],
                    [108.8730253, 12.5225788],
                    [108.8730308, 12.5224925],
                    [108.8730848, 12.5223278],
                    [108.8731702, 12.5221798],
                    [108.8732243, 12.5221131],
                    [108.8740128, 12.5213799],
                    [108.8740938, 12.520688],
                    [108.8741522, 12.52044],
                    [108.8741938, 12.5203193],
                    [108.8742882, 12.5201112],
                    [108.874436, 12.5198468],
                    [108.8746691, 12.5194637],
                    [108.8747925, 12.5192507],
                    [108.8749674, 12.5189172],
                    [108.8751052, 12.5186952],
                    [108.8752688, 12.5184905],
                    [108.8753596, 12.5183955],
                    [108.8754559, 12.5183059],
                    [108.8755797, 12.5182048],
                    [108.8758429, 12.5180226],
                    [108.8759816, 12.5179421],
                    [108.8761245, 12.5178688],
                    [108.876348, 12.5177723],
                    [108.8764908, 12.5176899],
                    [108.8766147, 12.5175823],
                    [108.8767087, 12.5174639],
                    [108.8767698, 12.51734],
                    [108.8767898, 12.5172739],
                    [108.8768023, 12.5172061],
                    [108.8768196, 12.5170182],
                    [108.8768665, 12.5168352],
                    [108.8769007, 12.5167469],
                    [108.8770354, 12.5164828],
                    [108.8770689, 12.5163879],
                    [108.8770933, 12.5162903],
                    [108.8771081, 12.5161909],
                    [108.8771106, 12.5160075],
                    [108.8771267, 12.5158419],
                    [108.8771737, 12.5156759],
                    [108.877209, 12.5155936],
                    [108.8772998, 12.5154391],
                    [108.8773549, 12.515368],
                    [108.877482, 12.5152403],
                    [108.8775533, 12.5151847],
                    [108.8782561, 12.5141582],
                    [108.8782453, 12.5136031],
                    [108.8778967, 12.5132941],
                    [108.8766632, 12.51331],
                    [108.8765144, 12.5132952],
                    [108.8764423, 12.5132751],
                    [108.8763199, 12.5132185],
                    [108.8760918, 12.5130509],
                    [108.8759655, 12.5129485],
                    [108.8751662, 12.5131894],
                    [108.8750102, 12.5132322],
                    [108.8746935, 12.5132993],
                    [108.8745333, 12.5133235],
                    [108.8742378, 12.5133512],
                    [108.8740242, 12.5133802],
                    [108.8739433, 12.5133837],
                    [108.8738034, 12.5133676],
                    [108.8736917, 12.5133246],
                    [108.8735957, 12.5132542],
                    [108.8735558, 12.5132103],
                    [108.8734956, 12.5131089],
                    [108.8734764, 12.5130532],
                    [108.8734645, 12.512992],
                    [108.8734563, 12.5128056],
                    [108.8734635, 12.5125657],
                    [108.8734394, 12.5124524],
                    [108.8734174, 12.5123986],
                    [108.8733613, 12.5123081],
                    [108.8733262, 12.5122677],
                    [108.8729238, 12.5118906],
                    [108.8729185, 12.5115292],
                    [108.8726782, 12.511262],
                    [108.8725652, 12.5111225],
                    [108.872379, 12.5108691],
                    [108.8723088, 12.5107539],
                    [108.872247, 12.5106342],
                    [108.8721937, 12.5105106],
                    [108.8721492, 12.5103838],
                    [108.8721139, 12.5102542],
                    [108.8720877, 12.5101226],
                    [108.8720709, 12.5099896],
                    [108.8720644, 12.5098861],
                    [108.8720683, 12.509679],
                    [108.8720594, 12.5093685],
                    [108.8720441, 12.5090583],
                    [108.8720054, 12.5085491],
                    [108.8719422, 12.5079523],
                    [108.8718852, 12.5075392],
                    [108.8717421, 12.5068525],
                    [108.8717108, 12.5065702],
                    [108.8717075, 12.5063207],
                    [108.8716881, 12.5061064],
                    [108.8716393, 12.5058967],
                    [108.8715824, 12.5057407],
                    [108.8715688, 12.5056843],
                    [108.8715672, 12.5055687],
                    [108.8715996, 12.5054575],
                    [108.8716632, 12.50536],
                    [108.8717116, 12.5053136],
                    [108.8718224, 12.5052377],
                    [108.8719475, 12.5051871],
                    [108.8721581, 12.5051538],
                    [108.8722646, 12.5051483],
                    [108.8725656, 12.5051595],
                    [108.8727587, 12.5051368],
                    [108.8729452, 12.5050829],
                    [108.8730344, 12.5050447],
                    [108.8732013, 12.5049472],
                    [108.8732779, 12.5048886],
                    [108.8734952, 12.5046909],
                    [108.8736099, 12.5045989],
                    [108.8737969, 12.5044644],
                    [108.8739174, 12.5043513],
                    [108.8740121, 12.5042168],
                    [108.8740486, 12.5041432],
                    [108.874118, 12.5039451],
                    [108.8741491, 12.5038889],
                    [108.8742306, 12.503789],
                    [108.8743337, 12.5037106],
                    [108.8743916, 12.5036808],
                    [108.8745176, 12.5036419],
                    [108.8746381, 12.5035855],
                    [108.874739, 12.5035001],
                    [108.8748027, 12.503412],
                    [108.8748926, 12.5032179],
                    [108.8749466, 12.5030451],
                    [108.8749355, 12.5022805],
                    [108.8748018, 12.5021737],
                    [108.8747428, 12.5021119],
                    [108.8746431, 12.5019741],
                    [108.8745993, 12.5018908],
                    [108.8745641, 12.5018036],
                    [108.874538, 12.5017134],
                    [108.8745065, 12.5015152],
                    [108.8743812, 12.5006205],
                    [108.873412, 12.5006151],
                    [108.8720662, 12.4990199],
                    [108.8719693, 12.4988578],
                    [108.871872, 12.4986311],
                    [108.8717858, 12.4985157],
                    [108.8716862, 12.4984237],
                    [108.871631, 12.498384],
                    [108.8714023, 12.4982659],
                    [108.8711604, 12.4981765],
                    [108.8710356, 12.4981431],
                    [108.8708598, 12.498109],
                    [108.8707001, 12.4980944],
                    [108.8704991, 12.4981012],
                    [108.8691902, 12.4982164],
                    [108.8668781, 12.4970013],
                    [108.8664758, 12.4967499],
                    [108.866378, 12.4966784],
                    [108.8662748, 12.4966146],
                    [108.8661668, 12.4965588],
                    [108.8660547, 12.4965115],
                    [108.8659391, 12.4964729],
                    [108.8658207, 12.4964432],
                    [108.8657004, 12.4964227],
                    [108.8655787, 12.4964114],
                    [108.8653341, 12.4964075],
                    [108.8652156, 12.4963774],
                    [108.8651599, 12.4963523],
                    [108.8651077, 12.496321],
                    [108.8650194, 12.4962446],
                    [108.8649139, 12.4961139],
                    [108.8648044, 12.4959456],
                    [108.8646817, 12.4957086],
                    [108.8645393, 12.495383],
                    [108.8644793, 12.4952003],
                    [108.8644614, 12.4951059],
                    [108.8644506, 12.4949144],
                    [108.8644732, 12.4947238],
                    [108.8644969, 12.4946307],
                    [108.8646132, 12.4943351],
                    [108.8646442, 12.4942289],
                    [108.8646828, 12.4940114],
                    [108.8646895, 12.4937908],
                    [108.8646665, 12.4935898],
                    [108.8646135, 12.4933942],
                    [108.8645316, 12.4932084],
                    [108.8643911, 12.4929725],
                    [108.8643593, 12.4928924],
                    [108.8643366, 12.4928094],
                    [108.8643232, 12.4927244],
                    [108.8643193, 12.4926386],
                    [108.8643241, 12.4924462],
                    [108.8643128, 12.4923505],
                    [108.8642465, 12.4920852],
                    [108.8642284, 12.4919091],
                    [108.8642301, 12.4918206],
                    [108.8642737, 12.4915168],
                    [108.8642933, 12.4911715],
                    [108.8643378, 12.4909389],
                    [108.8643721, 12.4908254],
                    [108.8644642, 12.4906065],
                    [108.8646376, 12.4902914],
                    [108.8647163, 12.4901299],
                    [108.8648611, 12.4897894],
                    [108.8649625, 12.4897993],
                    [108.8650644, 12.4897995],
                    [108.8651658, 12.48979],
                    [108.8652658, 12.4897708],
                    [108.8653633, 12.4897422],
                    [108.8654576, 12.4897044],
                    [108.8655476, 12.4896578],
                    [108.8656325, 12.4896028],
                    [108.8657115, 12.48954],
                    [108.8657838, 12.4894699],
                    [108.8659538, 12.4892773],
                    [108.8661486, 12.4891086],
                    [108.8662331, 12.4890481],
                    [108.8665458, 12.4888574],
                    [108.8666764, 12.4887683],
                    [108.8669099, 12.4885874],
                    [108.8670113, 12.4884948],
                    [108.867196, 12.4882925],
                    [108.8673544, 12.4880701],
                    [108.8674231, 12.4879522],
                    [108.8675845, 12.487623],
                    [108.8677166, 12.4874332],
                    [108.8678772, 12.4872657],
                    [108.8681121, 12.4870785],
                    [108.8682349, 12.4869428],
                    [108.8682868, 12.4868676],
                    [108.8683684, 12.4867091],
                    [108.8685244, 12.486231],
                    [108.8685528, 12.4861688],
                    [108.8686311, 12.4860563],
                    [108.868734, 12.4859647],
                    [108.8688559, 12.485899],
                    [108.868922, 12.485877],
                    [108.8689829, 12.4858639],
                    [108.8691688, 12.4858456],
                    [108.8692975, 12.4858425],
                    [108.8694257, 12.4858303],
                    [108.8695527, 12.4858093],
                    [108.8696778, 12.4857793],
                    [108.8698003, 12.4857408],
                    [108.8699872, 12.4856627],
                    [108.87005, 12.4856236],
                    [108.8701072, 12.4855769],
                    [108.8701579, 12.4855236],
                    [108.8702012, 12.4854644],
                    [108.8702365, 12.4854003],
                    [108.8703052, 12.4852029],
                    [108.8703802, 12.4850885],
                    [108.8704285, 12.4850395],
                    [108.870483, 12.4849971],
                    [108.8705974, 12.4849382],
                    [108.8710195, 12.4847824],
                    [108.8714291, 12.4846594],
                    [108.8718469, 12.4845664],
                    [108.8722706, 12.4845037],
                    [108.8727935, 12.4844585],
                    [108.8730998, 12.4844082],
                    [108.8736466, 12.4842704],
                    [108.8739388, 12.4842093],
                    [108.8741161, 12.484148],
                    [108.8742781, 12.4840547],
                    [108.8743515, 12.4839972],
                    [108.8744796, 12.4838628],
                    [108.8745332, 12.4837872],
                    [108.8745794, 12.4837063],
                    [108.8746941, 12.483452],
                    [108.8748236, 12.4831054],
                    [108.8748765, 12.4829283],
                    [108.8751787, 12.482854],
                    [108.8754575, 12.4827616],
                    [108.8757246, 12.482688],
                    [108.8758605, 12.4826608],
                    [108.8761787, 12.4826135],
                    [108.8763536, 12.4825608],
                    [108.8764375, 12.482525],
                    [108.8765477, 12.4824662],
                    [108.8766527, 12.4823991],
                    [108.8767521, 12.4823243],
                    [108.8769122, 12.4821743],
                    [108.877033, 12.4820274],
                    [108.877275, 12.4819849],
                    [108.8773937, 12.4819533],
                    [108.8776242, 12.4818698],
                    [108.877843, 12.4817603],
                    [108.8780525, 12.4816207],
                    [108.8782397, 12.4814538],
                    [108.8783238, 12.481361],
                    [108.8784629, 12.4811718],
                    [108.8785688, 12.4809795],
                    [108.8786124, 12.480879],
                    [108.8786799, 12.4806708],
                    [108.8787445, 12.4805122],
                    [108.87878, 12.4803451],
                    [108.8787864, 12.4802599],
                    [108.8787764, 12.4800895],
                    [108.878751, 12.4799249],
                    [108.8787509, 12.4797585],
                    [108.8787603, 12.4796757],
                    [108.8788212, 12.4794259],
                    [108.8789174, 12.4791385],
                    [108.8789381, 12.4789658],
                    [108.8789236, 12.4787925],
                    [108.8789032, 12.4787078],
                    [108.8788374, 12.4785462],
                    [108.8787054, 12.4783408],
                    [108.8786707, 12.4782707],
                    [108.8786202, 12.4781231],
                    [108.8785961, 12.4779705],
                    [108.8785941, 12.477587],
                    [108.8786048, 12.477434],
                    [108.8786569, 12.4769805],
                    [108.8786789, 12.4766814],
                    [108.8786648, 12.4765388],
                    [108.8786464, 12.4764694],
                    [108.8785935, 12.476347],
                    [108.8784932, 12.4761963],
                    [108.8784107, 12.4761151],
                    [108.8783074, 12.4760611],
                    [108.8782507, 12.476046],
                    [108.8781535, 12.4760394],
                    [108.8780576, 12.4760564],
                    [108.8778391, 12.4747097],
                    [108.877789, 12.4743727],
                    [108.877694, 12.4736143],
                    [108.8776134, 12.4731981],
                    [108.8775051, 12.4727881],
                    [108.8774298, 12.4725541],
                    [108.8771043, 12.4716656],
                    [108.8770385, 12.4714676],
                    [108.8769808, 12.4712672],
                    [108.8769021, 12.470927],
                    [108.8768215, 12.4706568],
                    [108.876723, 12.4704203],
                    [108.8766677, 12.4703192],
                    [108.876534, 12.4701309],
                    [108.8764564, 12.4700448],
                    [108.876282, 12.4698915],
                    [108.8761851, 12.4698245],
                    [108.875981, 12.4697065],
                    [108.8756292, 12.469548],
                    [108.8755119, 12.4694839],
                    [108.8753985, 12.4694132],
                    [108.8751377, 12.4692248],
                    [108.8750568, 12.4691759],
                    [108.8747883, 12.4690395],
                    [108.8747031, 12.4689813],
                    [108.8746236, 12.4689159],
                    [108.8745506, 12.4688437],
                    [108.8744845, 12.4687654],
                    [108.8743536, 12.4685761],
                    [108.8742562, 12.4684922],
                    [108.8742, 12.46846],
                    [108.8740773, 12.4684182],
                    [108.8740128, 12.4684092],
                    [108.8738366, 12.4684188],
                    [108.873659, 12.4684618],
                    [108.873571, 12.4684968],
                    [108.8734871, 12.4685402],
                    [108.8733967, 12.4685751],
                    [108.8733031, 12.4686009],
                    [108.8732073, 12.4686173],
                    [108.8731102, 12.4686242],
                    [108.873013, 12.4686214],
                    [108.8729166, 12.4686091],
                    [108.8727581, 12.4685669],
                    [108.8724515, 12.4684346],
                    [108.8721602, 12.4682992],
                    [108.8720554, 12.4682389],
                    [108.8719557, 12.468171],
                    [108.8718614, 12.4680958],
                    [108.8717733, 12.468014],
                    [108.8716918, 12.4679258],
                    [108.8716174, 12.4678318],
                    [108.8715505, 12.4677326],
                    [108.8714915, 12.4676288],
                    [108.8713651, 12.467328],
                    [108.8712764, 12.467032],
                    [108.8712214, 12.4667454],
                    [108.871017, 12.4659038],
                    [108.8707888, 12.4650779],
                    [108.8704314, 12.4637053],
                    [108.8703653, 12.4634206],
                    [108.8702543, 12.462847],
                    [108.8702095, 12.4625584],
                    [108.8701471, 12.4620409],
                    [108.8701075, 12.4615213],
                    [108.8696676, 12.460327],
                    [108.8696623, 12.4585827],
                    [108.8694392, 12.4581707],
                    [108.869319, 12.4579694],
                    [108.869193, 12.4577715],
                    [108.8689687, 12.4574466],
                    [108.8687675, 12.4571971],
                    [108.8686594, 12.4570785],
                    [108.8683403, 12.4567599],
                    [108.8681893, 12.4565773],
                    [108.8681402, 12.4564995],
                    [108.8680993, 12.4564173],
                    [108.8680671, 12.4563315],
                    [108.8680437, 12.4562431],
                    [108.8680296, 12.4561527],
                    [108.8680248, 12.4560615],
                    [108.8680293, 12.4559703],
                    [108.8680432, 12.4558799],
                    [108.8680662, 12.4557913],
                    [108.8680982, 12.4557055],
                    [108.8681388, 12.4556232],
                    [108.8682089, 12.455516],
                    [108.8683671, 12.4553142],
                    [108.8686216, 12.4550254],
                    [108.8689005, 12.4547589],
                    [108.8691293, 12.4545711],
                    [108.8699573, 12.453947],
                    [108.8706077, 12.4534855],
                    [108.8701041, 12.4534595],
                    [108.8698532, 12.4534347],
                    [108.8694994, 12.4533863],
                    [108.8692968, 12.4533319],
                    [108.869105, 12.4532482],
                    [108.8690145, 12.453196],
                    [108.8689283, 12.4531372],
                    [108.8686885, 12.4529371],
                    [108.8685087, 12.4528307],
                    [108.8683266, 12.4527602],
                    [108.8680411, 12.4526933],
                    [108.8678327, 12.4526128],
                    [108.8676399, 12.4525012],
                    [108.8674221, 12.4523345],
                    [108.8673332, 12.4522798],
                    [108.8671425, 12.4521942],
                    [108.8669394, 12.4521422],
                    [108.8667558, 12.4521255],
                    [108.8666763, 12.4521296],
                    [108.8665207, 12.4521613],
                    [108.866375, 12.4522232],
                    [108.8663077, 12.4522647],
                    [108.8662045, 12.4523498],
                    [108.8661094, 12.4524059],
                    [108.8660568, 12.4524238],
                    [108.8659466, 12.4524376],
                    [108.8658297, 12.4524191],
                    [108.8657132, 12.4523724],
                    [108.8656095, 12.4523028],
                    [108.8654692, 12.4521597],
                    [108.8653571, 12.4520782],
                    [108.8652335, 12.4520193],
                    [108.8651031, 12.451984],
                    [108.8649684, 12.4519722],
                    [108.8644803, 12.4519722],
                    [108.8643055, 12.4520049],
                    [108.8642203, 12.4520302],
                    [108.8640565, 12.4520979],
                    [108.8639938, 12.4521312],
                    [108.8639361, 12.4521721],
                    [108.8638843, 12.4522199],
                    [108.8638392, 12.4522739],
                    [108.8638016, 12.452333],
                    [108.8637721, 12.4523965],
                    [108.8637481, 12.452477],
                    [108.8637212, 12.4526426],
                    [108.8637275, 12.4529429],
                    [108.8637199, 12.4531073],
                    [108.8636851, 12.453214],
                    [108.8636552, 12.453262],
                    [108.8635741, 12.4533412],
                    [108.863525, 12.4533705],
                    [108.8634286, 12.4534023],
                    [108.86334, 12.4534078],
                    [108.8632089, 12.4534284],
                    [108.8628763, 12.4535017],
                    [108.862761, 12.4536109],
                    [108.8626391, 12.453713],
                    [108.8625111, 12.4538077],
                    [108.8621707, 12.4540196],
                    [108.8620716, 12.4540884],
                    [108.8619519, 12.4541802],
                    [108.8617283, 12.4543817],
                    [108.8613635, 12.4543451],
                    [108.8612373, 12.4544873],
                    [108.8610914, 12.4546104],
                    [108.860929, 12.4547117],
                    [108.8607697, 12.4547834],
                    [108.8604609, 12.4549459],
                    [108.8601971, 12.4551125],
                    [108.8596415, 12.4555184],
                    [108.8589495, 12.4560422],
                    [108.8587026, 12.4559521],
                    [108.8585756, 12.4559174],
                    [108.8583165, 12.4558694],
                    [108.8580591, 12.4558505],
                    [108.8579299, 12.4558515],
                    [108.8576728, 12.4558746],
                    [108.8574836, 12.4562237],
                    [108.8572632, 12.4565548],
                    [108.8570719, 12.4567973],
                    [108.8569145, 12.4569507],
                    [108.8568278, 12.4570187],
                    [108.8566405, 12.4571357],
                    [108.8563102, 12.4572837],
                    [108.8559467, 12.4574964],
                    [108.8555437, 12.4577574],
                    [108.8553232, 12.4579122],
                    [108.8550301, 12.4578515],
                    [108.854882, 12.4578294],
                    [108.8546006, 12.4578026],
                    [108.8544676, 12.4578015],
                    [108.8543349, 12.4578093],
                    [108.8540727, 12.4578517],
                    [108.853676, 12.4579704],
                    [108.8533995, 12.4580247],
                    [108.8532593, 12.4580404],
                    [108.8529834, 12.4580488],
                    [108.852714, 12.4580301],
                    [108.8525804, 12.4580111],
                    [108.8524479, 12.4579856],
                    [108.8519574, 12.4578624],
                    [108.8517098, 12.4578106],
                    [108.851221, 12.4577286],
                    [108.8509798, 12.4577006],
                    [108.8507378, 12.4576815],
                    [108.850405, 12.4576698],
                    [108.8503151, 12.4576776],
                    [108.8502266, 12.4576946],
                    [108.8501404, 12.4577206],
                    [108.8500575, 12.4577554],
                    [108.8499787, 12.4577984],
                    [108.8499051, 12.4578494],
                    [108.8498374, 12.4579076],
                    [108.8497764, 12.4579724],
                    [108.8496624, 12.45811],
                    [108.8495953, 12.4581705],
                    [108.8495222, 12.4582239],
                    [108.849444, 12.4582698],
                    [108.8493613, 12.4583076],
                    [108.8492751, 12.4583369],
                    [108.8491863, 12.4583575],
                    [108.8490578, 12.4583701],
                    [108.8489564, 12.4583642],
                    [108.8489197, 12.4583687],
                    [108.8488851, 12.4583813],
                    [108.848829, 12.4584278],
                    [108.8488106, 12.4584591],
                    [108.8487979, 12.4585293],
                    [108.8488228, 12.4587194],
                    [108.8488878, 12.4590617],
                    [108.8488968, 12.4592472],
                    [108.8488698, 12.4594311],
                    [108.8487679, 12.4597194],
                    [108.8487151, 12.4599487],
                    [108.8486885, 12.4601661],
                    [108.8486747, 12.46047],
                    [108.8486715, 12.4607523],
                    [108.8486629, 12.4608314],
                    [108.8486196, 12.4609846],
                    [108.8485436, 12.4611251],
                    [108.8484383, 12.4612464],
                    [108.8483088, 12.4613426],
                    [108.8481003, 12.4614414],
                    [108.8480374, 12.4614818],
                    [108.8479254, 12.4615799],
                    [108.8478301, 12.4617062],
                    [108.8476145, 12.4620922],
                    [108.8475116, 12.4622409],
                    [108.8473814, 12.4623676],
                    [108.8473076, 12.4624212],
                    [108.8471459, 12.4625065],
                    [108.8470595, 12.4625373],
                    [108.8468796, 12.4625741],
                    [108.8466827, 12.4625784],
                    [108.8465845, 12.4625699],
                    [108.8463914, 12.4625322],
                    [108.8462359, 12.4624798],
                    [108.8461385, 12.4624358],
                    [108.8460375, 12.4624004],
                    [108.8459337, 12.462374],
                    [108.8458279, 12.4623568],
                    [108.8456419, 12.4623491],
                    [108.8454848, 12.4623646],
                    [108.8446802, 12.4626003],
                    [108.8445557, 12.4625329],
                    [108.8444271, 12.4624737],
                    [108.8442947, 12.4624227],
                    [108.8441592, 12.4623803],
                    [108.8440211, 12.4623466],
                    [108.8438811, 12.4623218],
                    [108.8437398, 12.462306],
                    [108.8435976, 12.4622992],
                    [108.8434554, 12.4623015],
                    [108.8433135, 12.4623128],
                    [108.8431728, 12.4623332],
                    [108.8429387, 12.4623879],
                    [108.8427025, 12.4624333],
                    [108.8424647, 12.4624694],
                    [108.8423492, 12.4624834],
                    [108.8421167, 12.4624872],
                    [108.8418859, 12.4624588],
                    [108.8417062, 12.4624134],
                    [108.8415106, 12.4623444],
                    [108.8413829, 12.4622918],
                    [108.8411678, 12.4621877],
                    [108.840978, 12.4621186],
                    [108.840779, 12.4620812],
                    [108.8405764, 12.4620765],
                    [108.840417, 12.4620979],
                    [108.8402639, 12.4621461],
                    [108.8401217, 12.4622195],
                    [108.8399971, 12.4623139],
                    [108.8398667, 12.4623948],
                    [108.8397234, 12.4624511],
                    [108.8394978, 12.4624978],
                    [108.8393533, 12.4625479],
                    [108.8391294, 12.4626664],
                    [108.8389295, 12.4627897],
                    [108.8388333, 12.46284],
                    [108.8387329, 12.4628817],
                    [108.8386291, 12.4629146],
                    [108.8384697, 12.4629466],
                    [108.8380989, 12.4630458],
                    [108.837989, 12.4630608],
                    [108.8378781, 12.4630665],
                    [108.837736, 12.46306],
                    [108.8375268, 12.4630646],
                    [108.8374608, 12.4630764],
                    [108.8373364, 12.4631247],
                    [108.837229, 12.4632027],
                    [108.8370579, 12.4634053],
                    [108.8368328, 12.4636413],
                    [108.8367746, 12.4637182],
                    [108.8366765, 12.4638836],
                    [108.836619, 12.464019],
                    [108.8365005, 12.4642431],
                    [108.8363275, 12.4645113],
                    [108.8361142, 12.464804],
                    [108.8360182, 12.4649187],
                    [108.8358102, 12.4651341],
                    [108.8356985, 12.4652344],
                    [108.8355246, 12.465373],
                    [108.8354258, 12.4654773],
                    [108.8353857, 12.4655368],
                    [108.8353266, 12.4656667],
                    [108.8353083, 12.4657355],
                    [108.8352857, 12.4659403],
                    [108.8352466, 12.4660696],
                    [108.8351923, 12.4661732],
                    [108.8351032, 12.4662916],
                    [108.835035, 12.4663635],
                    [108.8349602, 12.4664288],
                    [108.8347973, 12.4665352],
                    [108.8347107, 12.4665755],
                    [108.8346204, 12.4666072],
                    [108.8345274, 12.4666301],
                    [108.8344326, 12.4666439],
                    [108.8343368, 12.4666486],
                    [108.8341906, 12.4666379],
                    [108.8340901, 12.4666494],
                    [108.8340426, 12.4666668],
                    [108.833959, 12.4667225],
                    [108.8339251, 12.4667594],
                    [108.8338833, 12.4668315],
                    [108.83384, 12.4669927],
                    [108.8338333, 12.4671259],
                    [108.8338414, 12.4672736],
                    [108.8338215, 12.4674202],
                    [108.8338012, 12.4674915],
                    [108.8337236, 12.4676827],
                    [108.8337106, 12.4677475],
                    [108.8337063, 12.4678134],
                    [108.8337238, 12.467944],
                    [108.8337453, 12.4680066],
                    [108.8338119, 12.4681211],
                    [108.8339132, 12.4682216],
                    [108.8339728, 12.4682616],
                    [108.8340372, 12.468294],
                    [108.8341813, 12.46835],
                    [108.8343161, 12.4684249],
                    [108.8343976, 12.4684832],
                    [108.8344733, 12.4685486],
                    [108.8345426, 12.4686204],
                    [108.8346048, 12.4686981],
                    [108.8347023, 12.4688607],
                    [108.8347382, 12.4689435],
                    [108.8347882, 12.4691163],
                    [108.8347885, 12.4691961],
                    [108.8347762, 12.4692344],
                    [108.8347292, 12.4692997],
                    [108.8346735, 12.4693357],
                    [108.8344104, 12.4694444],
                    [108.8341205, 12.4695388],
                    [108.8338591, 12.4696027],
                    [108.8337478, 12.4696371],
                    [108.8336398, 12.4696802],
                    [108.8335357, 12.4697318],
                    [108.8334363, 12.4697915],
                    [108.8333422, 12.4698588],
                    [108.833254, 12.4699334],
                    [108.833164, 12.4700238],
                    [108.8330676, 12.4701075],
                    [108.832965, 12.470184],
                    [108.8327668, 12.4703025],
                    [108.8326738, 12.4703468],
                    [108.8324805, 12.470419],
                    [108.8321006, 12.4705777],
                    [108.8317535, 12.4707092],
                    [108.8313967, 12.4708135],
                    [108.8310955, 12.4708789],
                    [108.8309743, 12.4708952],
                    [108.8307298, 12.4709058],
                    [108.8306076, 12.4708999],
                    [108.830329, 12.4708699],
                    [108.830172, 12.4708854],
                    [108.8300217, 12.4709325],
                    [108.8298847, 12.4710091],
                    [108.8297671, 12.471112],
                    [108.829574, 12.4713634],
                    [108.8294583, 12.4715001],
                    [108.8293355, 12.4716308],
                    [108.8292059, 12.471755],
                    [108.8290699, 12.4718726],
                    [108.8289278, 12.4719831],
                    [108.82878, 12.4720862],
                    [108.8282865, 12.4723795],
                    [108.8281092, 12.4724807],
                    [108.8279274, 12.4725739],
                    [108.8277414, 12.472659],
                    [108.8274088, 12.472787],
                    [108.8272697, 12.4728471],
                    [108.8271347, 12.4729156],
                    [108.8270044, 12.4729923],
                    [108.8266869, 12.4732015],
                    [108.8263877, 12.4733663],
                    [108.8261085, 12.4734838],
                    [108.8259643, 12.4735307],
                    [108.8256687, 12.4735999],
                    [108.8252664, 12.4736471],
                    [108.824578, 12.4736843],
                    [108.8244067, 12.473721],
                    [108.8242461, 12.4737898],
                    [108.8241024, 12.473888],
                    [108.8239812, 12.4740118],
                    [108.8238883, 12.4741545],
                    [108.823765, 12.4744349],
                    [108.8236871, 12.4745499],
                    [108.8236397, 12.4746011],
                    [108.8235323, 12.4746873],
                    [108.8234108, 12.4747485],
                    [108.8233456, 12.4747691],
                    [108.8232105, 12.4747895],
                    [108.823142, 12.4747889],
                    [108.8230117, 12.4747675],
                    [108.822155, 12.4744694],
                    [108.8217684, 12.4745931],
                    [108.8215792, 12.4746664],
                    [108.8212805, 12.4748003],
                    [108.8211638, 12.474845],
                    [108.821044, 12.4748808],
                    [108.8209218, 12.4749075],
                    [108.8207133, 12.4749316],
                    [108.820548, 12.4749679],
                    [108.8203926, 12.4750337],
                    [108.8201428, 12.4751997],
                    [108.8200468, 12.4752498],
                    [108.8198752, 12.4753163],
                    [108.8196957, 12.4753583],
                    [108.820189, 12.4765254],
                    [108.8202572, 12.4767483],
                    [108.8202794, 12.4768627],
                    [108.8203093, 12.4771397],
                    [108.820374, 12.477413],
                    [108.8203879, 12.4775786],
                    [108.8203661, 12.4777434],
                    [108.8203421, 12.4778232],
                    [108.8202836, 12.4779492],
                    [108.8202505, 12.477994],
                    [108.820166, 12.4780672],
                    [108.8201165, 12.4780939],
                    [108.8199641, 12.4781525],
                    [108.8198264, 12.4782391],
                    [108.8197088, 12.4783506],
                    [108.8196527, 12.4784235],
                    [108.8195586, 12.478581],
                    [108.819486, 12.478767],
                    [108.8194536, 12.4789239],
                    [108.819446, 12.4790838],
                    [108.8178882, 12.4815045],
                    [108.8178294, 12.4815894],
                    [108.8177626, 12.4816685],
                    [108.8176884, 12.4817411],
                    [108.8176076, 12.4818064],
                    [108.8175208, 12.4818641],
                    [108.8174288, 12.4819134],
                    [108.8173324, 12.4819541],
                    [108.8171884, 12.4819964],
                    [108.8169529, 12.4820518],
                    [108.8168617, 12.4820635],
                    [108.816678, 12.4820644],
                    [108.8165331, 12.4820436],
                    [108.8164241, 12.4820454],
                    [108.8163708, 12.482057],
                    [108.8162715, 12.4821008],
                    [108.8161939, 12.4821627],
                    [108.8161052, 12.482265],
                    [108.8159457, 12.4824756],
                    [108.8158166, 12.4825936],
                    [108.8157442, 12.4826434],
                    [108.8155868, 12.4827221],
                    [108.8155032, 12.4827505],
                    [108.8152815, 12.4828013],
                    [108.8151583, 12.4828647],
                    [108.815056, 12.482957],
                    [108.815015, 12.4830121],
                    [108.814921, 12.4831735],
                    [108.8148005, 12.4833172],
                    [108.8146569, 12.4834391],
                    [108.8145778, 12.4834907],
                    [108.8144075, 12.4835734],
                    [108.8142526, 12.483621],
                    [108.813921, 12.4836761],
                    [108.8136524, 12.483693],
                    [108.813343, 12.4836967],
                    [108.8131888, 12.4837095],
                    [108.8128971, 12.4837541],
                    [108.8127575, 12.4837699],
                    [108.8125514, 12.4837769],
                    [108.8124228, 12.4838015],
                    [108.8123046, 12.4838568],
                    [108.8122045, 12.4839394],
                    [108.8121289, 12.4840439],
                    [108.8120455, 12.4842249],
                    [108.8119505, 12.4843767],
                    [108.8118452, 12.4844956],
                    [108.8117849, 12.4845478],
                    [108.8116515, 12.4846357],
                    [108.8115673, 12.4846759],
                    [108.8114794, 12.4847074],
                    [108.8113886, 12.48473],
                    [108.8109718, 12.4848631],
                    [108.810323, 12.4850841],
                    [108.8100381, 12.4851899],
                    [108.8099832, 12.4852013],
                    [108.8098713, 12.4851976],
                    [108.8097665, 12.4851592],
                    [108.8097203, 12.485128],
                    [108.8096001, 12.4850156],
                    [108.8095334, 12.4849669],
                    [108.80932, 12.4848417],
                    [108.8091989, 12.4847312],
                    [108.8091485, 12.4846669],
                    [108.8090707, 12.484524],
                    [108.8090444, 12.4844472],
                    [108.8089893, 12.4841735],
                    [108.8089247, 12.4839456],
                    [108.8088874, 12.4838557],
                    [108.8088417, 12.4837695],
                    [108.8087879, 12.4836878],
                    [108.8087265, 12.4836115],
                    [108.8086008, 12.4834911],
                    [108.8081868, 12.4832083],
                    [108.8079727, 12.4830962],
                    [108.807746, 12.4830112],
                    [108.807629, 12.4829792],
                    [108.8074245, 12.4829413],
                    [108.8072586, 12.4828926],
                    [108.8071044, 12.4828157],
                    [108.8070332, 12.4827674],
                    [108.8069057, 12.482653],
                    [108.8068503, 12.4825878],
                    [108.8067677, 12.4824612],
                    [108.8066772, 12.4823398],
                    [108.8065792, 12.4822242],
                    [108.806474, 12.4821146],
                    [108.8063621, 12.4820117],
                    [108.806075, 12.4817928],
                    [108.8059233, 12.4816942],
                    [108.8055341, 12.4814724],
                    [108.805424, 12.4813975],
                    [108.8052181, 12.4812294],
                    [108.8048818, 12.4808803],
                    [108.8046746, 12.4806918],
                    [108.8045029, 12.4805745],
                    [108.8043147, 12.4804847],
                    [108.8041145, 12.4804247],
                    [108.8039428, 12.4803985],
                    [108.8037454, 12.4806636],
                    [108.8035248, 12.4809108],
                    [108.8032734, 12.4811461],
                    [108.8030373, 12.4813469],
                    [108.8028526, 12.4815327],
                    [108.8027695, 12.4816336],
                    [108.8025999, 12.481872],
                    [108.8025466, 12.4819332],
                    [108.8024521, 12.4820237],
                    [108.8024101, 12.4820503],
                    [108.8023155, 12.4820817],
                    [108.8022157, 12.4820814],
                    [108.8021672, 12.4820693],
                    [108.8020999, 12.482037],
                    [108.8020374, 12.4819965],
                    [108.8019806, 12.4819486],
                    [108.8019307, 12.481894],
                    [108.8018882, 12.4818336],
                    [108.8017808, 12.481621],
                    [108.8016522, 12.4814198],
                    [108.8014334, 12.4811491],
                    [108.801262, 12.4809697],
                    [108.8012041, 12.4809238],
                    [108.8010741, 12.4808526],
                    [108.8010037, 12.4808283],
                    [108.8008568, 12.4808037],
                    [108.8007081, 12.4808123],
                    [108.8005387, 12.4808571],
                    [108.8003634, 12.480923],
                    [108.8001713, 12.4809782],
                    [108.7999731, 12.4810061],
                    [108.799846, 12.4810093],
                    [108.7997194, 12.4810221],
                    [108.7995942, 12.4810442],
                    [108.7994711, 12.4810756],
                    [108.7993509, 12.481116],
                    [108.799255, 12.4811557],
                    [108.7990719, 12.4812522],
                    [108.7990665, 12.4818336],
                    [108.798883, 12.482014],
                    [108.7985904, 12.4823707],
                    [108.7984376, 12.4825079],
                    [108.7982367, 12.4826442],
                    [108.7981372, 12.4827413],
                    [108.798058, 12.4828549],
                    [108.7980232, 12.4829253],
                    [108.7979555, 12.4831313],
                    [108.7978926, 12.4832349],
                    [108.7978073, 12.4833219],
                    [108.7976235, 12.4834363],
                    [108.7974803, 12.4835499],
                    [108.7973553, 12.4836825],
                    [108.7973015, 12.4837535],
                    [108.7972101, 12.4839058],
                    [108.7971729, 12.4839863],
                    [108.7971944, 12.4847614],
                    [108.7971815, 12.4848382],
                    [108.7971593, 12.4849128],
                    [108.797128, 12.4849844],
                    [108.7970882, 12.4850517],
                    [108.7970403, 12.4851139],
                    [108.7969851, 12.4851699],
                    [108.7969246, 12.4852184],
                    [108.7966376, 12.4853952],
                    [108.7963616, 12.4855425],
                    [108.7960794, 12.4856821],
                    [108.7959884, 12.4857365],
                    [108.7959029, 12.4857988],
                    [108.7958237, 12.4858686],
                    [108.7957043, 12.4860034],
                    [108.7956226, 12.4861284],
                    [108.7952739, 12.4869193],
                    [108.7959552, 12.4876316],
                    [108.7960356, 12.4881815],
                    [108.797189, 12.4892814],
                    [108.7967867, 12.4900565],
                    [108.7975967, 12.4909574],
                    [108.7976596, 12.4910647],
                    [108.7976794, 12.4911237],
                    [108.7976936, 12.4912466],
                    [108.7976796, 12.4913479],
                    [108.7976128, 12.4915387],
                    [108.7975764, 12.4916157],
                    [108.7974487, 12.491837],
                    [108.797354, 12.4919789],
                    [108.7972512, 12.4921153],
                    [108.7971407, 12.4922458],
                    [108.7969457, 12.4924441],
                    [108.7967329, 12.492624],
                    [108.7959879, 12.4931521],
                    [108.7956659, 12.4933669],
                    [108.795011, 12.4937804],
                    [108.7949834, 12.49465],
                    [108.7949359, 12.4954825],
                    [108.7949213, 12.4959199],
                    [108.7948753, 12.4965323],
                    [108.7948626, 12.4966428],
                    [108.7948216, 12.4967863],
                    [108.7947519, 12.4969236],
                    [108.7947048, 12.4969876],
                    [108.7946504, 12.4970459],
                    [108.7945894, 12.4970976],
                    [108.7945227, 12.4971421],
                    [108.7944512, 12.4971787],
                    [108.7943758, 12.497207],
                    [108.7941374, 12.4972542],
                    [108.7940596, 12.4972775],
                    [108.793984, 12.497307],
                    [108.7938416, 12.4973836],
                    [108.7937752, 12.4974306],
                    [108.7936554, 12.4975399],
                    [108.7932676, 12.4978341],
                    [108.7930578, 12.4979774],
                    [108.7926239, 12.4982426],
                    [108.7922484, 12.498981],
                    [108.7922403, 12.4992403],
                    [108.7917572, 12.4997485],
                    [108.7914324, 12.4997296],
                    [108.7907549, 12.4996692],
                    [108.7904969, 12.4996182],
                    [108.7903709, 12.499581],
                    [108.7901104, 12.4994868],
                    [108.7899659, 12.4994656],
                    [108.7898928, 12.4994659],
                    [108.7897485, 12.4994882],
                    [108.7896788, 12.49951],
                    [108.7894918, 12.4995871],
                    [108.7893948, 12.4996165],
                    [108.7892961, 12.4996397],
                    [108.788989, 12.4996866],
                    [108.7887902, 12.4997504],
                    [108.7886957, 12.4997944],
                    [108.7885197, 12.499905],
                    [108.7884396, 12.4999709],
                    [108.788335, 12.5000767],
                    [108.7882239, 12.500176],
                    [108.7881066, 12.5002682],
                    [108.7879152, 12.5003958],
                    [108.7877916, 12.500498],
                    [108.7876886, 12.5006203],
                    [108.787603, 12.5007738],
                    [108.7874361, 12.5010393],
                    [108.7873547, 12.5011506],
                    [108.7871749, 12.5013604],
                    [108.7870044, 12.5015248],
                    [108.7869257, 12.5015847],
                    [108.7867533, 12.5016821],
                    [108.786661, 12.5017188],
                    [108.786468, 12.5017668],
                    [108.786369, 12.5017777],
                    [108.7862689, 12.5017797],
                    [108.7860697, 12.5017617],
                    [108.7858754, 12.5017149],
                    [108.7855912, 12.5016119],
                    [108.7851841, 12.501492],
                    [108.7848073, 12.5013392],
                    [108.7846748, 12.5013164],
                    [108.7845404, 12.501323],
                    [108.7844747, 12.5013373],
                    [108.7842768, 12.5014163],
                    [108.7841964, 12.5014413],
                    [108.7840298, 12.5014672],
                    [108.7838613, 12.5014605],
                    [108.7837784, 12.501445],
                    [108.7835138, 12.501355],
                    [108.7833638, 12.5013232],
                    [108.7833083, 12.5013207],
                    [108.7831985, 12.5013368],
                    [108.7830967, 12.5013797],
                    [108.78294, 12.5015021],
                    [108.7826068, 12.5018214],
                    [108.7823296, 12.5020556],
                    [108.7820684, 12.5022475],
                    [108.7817126, 12.5024847],
                    [108.7811858, 12.5028202],
                    [108.780908, 12.5030085],
                    [108.7806914, 12.5031767],
                    [108.7804693, 12.5033766],
                    [108.7802524, 12.5035821],
                    [108.7798351, 12.5040088],
                    [108.7796267, 12.5042392],
                    [108.7792289, 12.5047158],
                    [108.7792418, 12.5048603],
                    [108.7792256, 12.5050044],
                    [108.779181, 12.5051428],
                    [108.7791485, 12.5052081],
                    [108.7790665, 12.5053259],
                    [108.7789633, 12.5054266],
                    [108.7788427, 12.5055066],
                    [108.778774, 12.5055392],
                    [108.7786285, 12.505584],
                    [108.7785262, 12.5055956],
                    [108.7777591, 12.5056113],
                    [108.7774538, 12.5053616],
                    [108.7771426, 12.5051189],
                    [108.7766595, 12.5047644],
                    [108.7764878, 12.5046532],
                    [108.7763108, 12.5045502],
                    [108.7761291, 12.5044554],
                    [108.7759429, 12.5043691],
                    [108.7757528, 12.5042916],
                    [108.7755587, 12.5042228],
                    [108.7751636, 12.5041058],
                    [108.774963, 12.5040578],
                    [108.7745573, 12.5039831],
                    [108.7741843, 12.5039399],
                    [108.7738501, 12.5038876],
                    [108.7735212, 12.5038098],
                    [108.7733783, 12.5037674],
                    [108.7730974, 12.5036683],
                    [108.770898, 12.5035846],
                    [108.7708577, 12.5035476],
                    [108.7707629, 12.5034923],
                    [108.7707104, 12.5034752],
                    [108.7706007, 12.5034638],
                    [108.7704921, 12.5034833],
                    [108.7704411, 12.5035043],
                    [108.7703937, 12.5035322],
                    [108.7702484, 12.5036504],
                    [108.7700899, 12.5037397],
                    [108.7700073, 12.5037712],
                    [108.7699218, 12.5037938],
                    [108.7698343, 12.5038074],
                    [108.7697459, 12.5038116],
                    [108.7696574, 12.5038066],
                    [108.7695701, 12.5037923],
                    [108.7694848, 12.5037689],
                    [108.7694026, 12.5037367],
                    [108.7693244, 12.503696],
                    [108.7691978, 12.5036041],
                    [108.7690766, 12.5035387],
                    [108.7690108, 12.5035176],
                    [108.7688735, 12.5035002],
                    [108.7688043, 12.5035041],
                    [108.7686808, 12.5035331],
                    [108.7684408, 12.5036396],
                    [108.76827, 12.5036959],
                    [108.7680914, 12.5037205],
                    [108.7680012, 12.5037207],
                    [108.7679078, 12.5037073],
                    [108.7678638, 12.5036901],
                    [108.7677866, 12.5036369],
                    [108.7677477, 12.5035918],
                    [108.7677157, 12.5035416],
                    [108.7676756, 12.5034305],
                    [108.7676694, 12.5033129],
                    [108.7676793, 12.5032546],
                    [108.7677319, 12.503087],
                    [108.7677458, 12.5030003],
                    [108.7677482, 12.502825],
                    [108.7677367, 12.5027379],
                    [108.7677169, 12.5026523],
                    [108.7676478, 12.5024407],
                    [108.7675987, 12.50223],
                    [108.7675505, 12.5019185],
                    [108.7675277, 12.5017097],
                    [108.7675092, 12.5014096],
                    [108.7674781, 12.501231],
                    [108.7674165, 12.5010602],
                    [108.7673265, 12.5009025],
                    [108.7672716, 12.5008298],
                    [108.7661183, 12.4998452],
                    [108.7660235, 12.4997658],
                    [108.7659228, 12.4996938],
                    [108.7658166, 12.4996296],
                    [108.7657057, 12.4995735],
                    [108.7655907, 12.499526],
                    [108.7654723, 12.4994873],
                    [108.7652655, 12.4994424],
                    [108.765182, 12.499418],
                    [108.7651018, 12.499385],
                    [108.7650258, 12.4993435],
                    [108.7649548, 12.4992942],
                    [108.7648898, 12.4992377],
                    [108.764747, 12.4990674],
                    [108.7646839, 12.4989763],
                    [108.7645754, 12.4987834],
                    [108.7645304, 12.4986825],
                    [108.7644545, 12.4984532],
                    [108.7643695, 12.4982269],
                    [108.7643068, 12.4980961],
                    [108.7642638, 12.4980373],
                    [108.7641576, 12.4979369],
                    [108.76403, 12.4978646],
                    [108.7638888, 12.4978244],
                    [108.7638155, 12.497817],
                    [108.7637231, 12.4978183],
                    [108.7616652, 12.4979989],
                    [108.761569, 12.4980145],
                    [108.7613822, 12.4980688],
                    [108.7612072, 12.4981526],
                    [108.7610489, 12.4982635],
                    [108.760972, 12.4983336],
                    [108.7608888, 12.4983966],
                    [108.7608001, 12.4984519],
                    [108.7607065, 12.4984991],
                    [108.760609, 12.4985377],
                    [108.7605083, 12.4985675],
                    [108.7604052, 12.4985882],
                    [108.7599239, 12.4986265],
                    [108.7597872, 12.4986179],
                    [108.7597204, 12.4986025],
                    [108.7596558, 12.4985801],
                    [108.7595361, 12.4985149],
                    [108.7593913, 12.4983892],
                    [108.7592631, 12.49825],
                    [108.759086, 12.4980774],
                    [108.7589849, 12.4980199],
                    [108.7588706, 12.4979954],
                    [108.7588119, 12.4979964],
                    [108.7586559, 12.498035],
                    [108.7585029, 12.4981044],
                    [108.7584273, 12.4981492],
                    [108.7582862, 12.498253],
                    [108.7580637, 12.4984725],
                    [108.757782, 12.4987872],
                    [108.7576898, 12.4988758],
                    [108.7574923, 12.4990386],
                    [108.7568057, 12.4990282],
                    [108.7558454, 12.4997038],
                    [108.754795, 12.5008491],
                    [108.7542468, 12.5003689],
                    [108.7541206, 12.5000722],
                    [108.7540291, 12.4997637],
                    [108.7539732, 12.4994471],
                    [108.7539539, 12.4991366],
                    [108.7539571, 12.498981],
                    [108.7537601, 12.4988097],
                    [108.753584, 12.498677],
                    [108.7535165, 12.498618],
                    [108.7533992, 12.4984835],
                    [108.7533185, 12.4983502],
                    [108.7532866, 12.4982792],
                    [108.7529183, 12.4980842],
                    [108.7527286, 12.4979974],
                    [108.7525356, 12.4979179],
                    [108.7522524, 12.4978163],
                    [108.7516782, 12.4976356],
                    [108.7513876, 12.4975565],
                    [108.7511103, 12.4974885],
                    [108.7505507, 12.4973732],
                    [108.7504401, 12.4972146],
                    [108.7503606, 12.4970391],
                    [108.7503147, 12.4968525],
                    [108.750304, 12.4966609],
                    [108.750316, 12.4965371],
                    [108.7503644, 12.4962931],
                    [108.750435, 12.4960803],
                    [108.7506366, 12.4956239],
                    [108.7508247, 12.4952548],
                    [108.7509263, 12.495074],
                    [108.7505186, 12.494325],
                    [108.7505293, 12.4927119],
                    [108.7504383, 12.4926069],
                    [108.7500222, 12.4922163],
                    [108.7499069, 12.4920685],
                    [108.7498582, 12.4919886],
                    [108.7498158, 12.4919054],
                    [108.7497624, 12.4917681],
                    [108.7496133, 12.4912756],
                    [108.7495583, 12.4910621],
                    [108.7494511, 12.4905437],
                    [108.7494095, 12.4902823],
                    [108.7493759, 12.4900199],
                    [108.7492955, 12.4891609],
                    [108.7491885, 12.4882309],
                    [108.7491506, 12.4879458],
                    [108.7490755, 12.4874797],
                    [108.748702, 12.4869994],
                    [108.7486405, 12.4869076],
                    [108.7485379, 12.4867125],
                    [108.7484973, 12.4866103],
                    [108.7484404, 12.4864095],
                    [108.7484161, 12.4862137],
                    [108.7484154, 12.486115],
                    [108.7484225, 12.4860166],
                    [108.748456, 12.4858356],
                    [108.7485152, 12.4856753],
                    [108.7485552, 12.4855994],
                    [108.7486281, 12.4854917],
                    [108.7486927, 12.4853791],
                    [108.7487488, 12.4852622],
                    [108.748796, 12.4851417],
                    [108.7488341, 12.4850181],
                    [108.7488781, 12.4847973],
                    [108.7488931, 12.4845729],
                    [108.74892, 12.4834206],
                    [108.7479061, 12.4826769],
                    [108.7482172, 12.4814251],
                    [108.7482494, 12.4805556],
                    [108.7481403, 12.4804268],
                    [108.7480242, 12.4803039],
                    [108.7479015, 12.4801873],
                    [108.7477726, 12.4800773],
                    [108.7475647, 12.4799229],
                    [108.747338, 12.4797792],
                    [108.7471819, 12.4796914],
                    [108.7468632, 12.479538],
                    [108.7466991, 12.4794714],
                    [108.7458897, 12.4795174],
                    [108.745079, 12.479529],
                    [108.7442735, 12.4795067],
                    [108.7434697, 12.4794505],
                    [108.7413186, 12.4778949],
                    [108.7407017, 12.4772716],
                    [108.7401952, 12.4767338],
                    [108.740037, 12.4765378],
                    [108.7399375, 12.476401],
                    [108.7398434, 12.4762607],
                    [108.7397264, 12.4760597],
                    [108.7396737, 12.4759562],
                    [108.7389421, 12.474307],
                    [108.7389406, 12.4753558],
                    [108.7389381, 12.4754304],
                    [108.7389039, 12.4755596],
                    [108.7388366, 12.4756758],
                    [108.7387481, 12.4757653],
                    [108.7386034, 12.4758656],
                    [108.7385023, 12.4759255],
                    [108.7382117, 12.4760606],
                    [108.737983, 12.4761477],
                    [108.7378176, 12.4761809],
                    [108.7377332, 12.4761855],
                    [108.7375651, 12.4761703],
                    [108.7374232, 12.476131],
                    [108.7372993, 12.4761073],
                    [108.7371599, 12.4761064],
                    [108.7370841, 12.4761152],
                    [108.7369358, 12.4761507],
                    [108.7368562, 12.4761806],
                    [108.7365596, 12.4763171],
                    [108.7364205, 12.4763533],
                    [108.7363486, 12.4763588],
                    [108.7362765, 12.4763557],
                    [108.7361362, 12.4763243],
                    [108.7360078, 12.4762607],
                    [108.735887, 12.476181],
                    [108.7357507, 12.4761305],
                    [108.7356062, 12.4761119],
                    [108.7355333, 12.4761149],
                    [108.7354612, 12.4761262],
                    [108.7353258, 12.4761714],
                    [108.7349402, 12.4763708],
                    [108.7345323, 12.4765595],
                    [108.7342065, 12.4766862],
                    [108.7339657, 12.4767514],
                    [108.7337186, 12.4767878],
                    [108.7332752, 12.4767987],
                    [108.7327365, 12.4768514],
                    [108.7325457, 12.4768628],
                    [108.7321944, 12.4768683],
                    [108.7318747, 12.4768909],
                    [108.7315588, 12.4769434],
                    [108.7312496, 12.4770254],
                    [108.7304713, 12.4773169],
                    [108.7299728, 12.4775282],
                    [108.7295165, 12.4777453],
                    [108.7290716, 12.4779839],
                    [108.7287004, 12.478209],
                    [108.7283474, 12.4784605],
                    [108.7280685, 12.4786858],
                    [108.7276661, 12.4790367],
                    [108.726604, 12.47922],
                    [108.7262002, 12.4793186],
                    [108.725662, 12.4794863],
                    [108.725371, 12.4795463],
                    [108.7250751, 12.4795762],
                    [108.7249262, 12.4795797],
                    [108.7243509, 12.4795676],
                    [108.7242223, 12.4795728],
                    [108.7239674, 12.4796082],
                    [108.7238424, 12.4796384],
                    [108.7235999, 12.4797228],
                    [108.7233665, 12.4798409],
                    [108.7223446, 12.4805294],
                    [108.7210303, 12.4806289],
                    [108.7210285, 12.4809515],
                    [108.7210035, 12.4812732],
                    [108.7209646, 12.4815082],
                    [108.7208907, 12.481735],
                    [108.7207836, 12.4819488],
                    [108.7206719, 12.4821114],
                    [108.7206084, 12.4821871],
                    [108.72054, 12.4822587],
                    [108.7204125, 12.482371],
                    [108.7202895, 12.4824415],
                    [108.7201534, 12.4824831],
                    [108.7200825, 12.4824923],
                    [108.7199444, 12.4824875],
                    [108.7197721, 12.4824492],
                    [108.7194178, 12.4823466],
                    [108.7192087, 12.4823136],
                    [108.7189969, 12.4823114],
                    [108.7188915, 12.482322],
                    [108.7186845, 12.482366],
                    [108.718522, 12.4824241],
                    [108.718342, 12.4825123],
                    [108.7182273, 12.4825804],
                    [108.717981, 12.4827508],
                    [108.7178281, 12.4828177],
                    [108.7176647, 12.482854],
                    [108.717581, 12.4828602],
                    [108.7173247, 12.4828552],
                    [108.7170696, 12.4828797],
                    [108.7169436, 12.482903],
                    [108.7166989, 12.4829705],
                    [108.7164661, 12.4830656],
                    [108.7162469, 12.4831876],
                    [108.716069, 12.4833149],
                    [108.7156309, 12.4836873],
                    [108.7154138, 12.4838867],
                    [108.7144911, 12.4839182],
                    [108.7142079, 12.4835078],
                    [108.7138982, 12.4831162],
                    [108.7135631, 12.4827449],
                    [108.7133675, 12.4825491],
                    [108.7131654, 12.4823597],
                    [108.7129569, 12.482177],
                    [108.7127423, 12.4820012],
                    [108.7123716, 12.4817239],
                    [108.7117841, 12.4813342],
                    [108.7116894, 12.4812596],
                    [108.7115139, 12.4810951],
                    [108.7113503, 12.4809007],
                    [108.7112778, 12.4807965],
                    [108.7105644, 12.4796547],
                    [108.7102876, 12.4792423],
                    [108.7101317, 12.4790616],
                    [108.7099507, 12.4789048],
                    [108.7097484, 12.4787751],
                    [108.7096406, 12.4787213],
                    [108.7092712, 12.4785836],
                    [108.7091464, 12.4785279],
                    [108.7089242, 12.4784081],
                    [108.7086332, 12.4782185],
                    [108.7082055, 12.4779094],
                    [108.7079465, 12.4777639],
                    [108.7078065, 12.4777006],
                    [108.7076707, 12.4776291],
                    [108.7075396, 12.4775496],
                    [108.7074137, 12.4774624],
                    [108.7072935, 12.4773679],
                    [108.7070166, 12.4771147],
                    [108.7068361, 12.4769835],
                    [108.706618, 12.4768639],
                    [108.7063855, 12.476774],
                    [108.7051678, 12.4760564],
                    [108.7048828, 12.4758211],
                    [108.7045777, 12.4756112],
                    [108.7042992, 12.4754513],
                    [108.7041555, 12.4753793],
                    [108.7039715, 12.4753022],
                    [108.703336, 12.4746162],
                    [108.7011015, 12.4744275],
                    [108.6999153, 12.4736788],
                    [108.700026, 12.4733425],
                    [108.7000707, 12.473172],
                    [108.7001343, 12.4729173],
                    [108.7001681, 12.4726571],
                    [108.7001737, 12.4725236],
                    [108.7001575, 12.4718472],
                    [108.7001146, 12.471297],
                    [108.7000823, 12.4710229],
                    [108.6995137, 12.4704887],
                    [108.6994835, 12.4703294],
                    [108.6994441, 12.4701721],
                    [108.6993137, 12.4697757],
                    [108.6992599, 12.4695267],
                    [108.6992437, 12.4694004],
                    [108.6992234, 12.4690366],
                    [108.6991815, 12.4688029],
                    [108.6991098, 12.4685763],
                    [108.6989527, 12.4682296],
                    [108.6989106, 12.4681059],
                    [108.6988775, 12.4679796],
                    [108.6988536, 12.4678513],
                    [108.698839, 12.4677217],
                    [108.6988337, 12.4675915],
                    [108.6988377, 12.4674612],
                    [108.6988562, 12.4672982],
                    [108.6988654, 12.4671346],
                    [108.6988654, 12.4669706],
                    [108.6988562, 12.466807],
                    [108.69882, 12.4665339],
                    [108.6987503, 12.4662066],
                    [108.6986944, 12.4659906],
                    [108.6986306, 12.4657766],
                    [108.6985227, 12.4654675],
                    [108.6984944, 12.4653676],
                    [108.6984616, 12.4651629],
                    [108.6984611, 12.4649557],
                    [108.698523, 12.4645686],
                    [108.6985333, 12.4644247],
                    [108.6985337, 12.4642805],
                    [108.6985244, 12.4641366],
                    [108.6984813, 12.463874],
                    [108.6984575, 12.4636316],
                    [108.6984656, 12.4633881],
                    [108.6984817, 12.4632673],
                    [108.6985056, 12.4631477],
                    [108.6985709, 12.4629299],
                    [108.6986899, 12.4626366],
                    [108.6987765, 12.4624441],
                    [108.6989221, 12.4621557],
                    [108.6990033, 12.4619479],
                    [108.6990523, 12.4617308],
                    [108.6990598, 12.4616102],
                    [108.6990366, 12.4614915],
                    [108.698984, 12.4613822],
                    [108.6989054, 12.4612889],
                    [108.6988056, 12.4612175],
                    [108.6985379, 12.4611126],
                    [108.6984076, 12.4610523],
                    [108.6981264, 12.4608997],
                    [108.6979771, 12.4608058],
                    [108.6976922, 12.4605986],
                    [108.6975572, 12.4604859],
                    [108.6973035, 12.4602432],
                    [108.6971604, 12.4600849],
                    [108.6970244, 12.4599206],
                    [108.6968958, 12.4597508],
                    [108.6966047, 12.4593357],
                    [108.6960915, 12.4586736],
                    [108.6959122, 12.458397],
                    [108.6958321, 12.4582532],
                    [108.6956515, 12.4578876],
                    [108.6955178, 12.4576836],
                    [108.6953596, 12.4574971],
                    [108.695272, 12.4574112],
                    [108.6950681, 12.4572465],
                    [108.6948306, 12.4570995],
                    [108.6947052, 12.4570373],
                    [108.6944434, 12.456937],
                    [108.6943081, 12.4568992],
                    [108.6941707, 12.4568699],
                    [108.6936235, 12.4567965],
                    [108.6933452, 12.456746],
                    [108.6929764, 12.4566897],
                    [108.6928021, 12.4566455],
                    [108.692718, 12.456614],
                    [108.6925653, 12.4565376],
                    [108.6924355, 12.4564415],
                    [108.6923778, 12.4563852],
                    [108.6922794, 12.4562584],
                    [108.6922127, 12.4561318],
                    [108.6921006, 12.4558381],
                    [108.6918478, 12.455264],
                    [108.6917725, 12.4550583],
                    [108.6917064, 12.4548497],
                    [108.6916065, 12.454448],
                    [108.6915431, 12.4540631],
                    [108.6914992, 12.4536851],
                    [108.69048, 12.4536536],
                    [108.6898115, 12.4530885],
                    [108.6894852, 12.4527972],
                    [108.6886483, 12.4520096],
                    [108.6884288, 12.4518428],
                    [108.6882239, 12.4517159],
                    [108.6881402, 12.4516563],
                    [108.6880623, 12.4515896],
                    [108.68784, 12.4513429],
                    [108.6877549, 12.451265],
                    [108.6874522, 12.4510212],
                    [108.6872082, 12.4507714],
                    [108.687114, 12.4506903],
                    [108.6868485, 12.450495],
                    [108.6867054, 12.4503491],
                    [108.6865893, 12.4501818],
                    [108.6865425, 12.4500916],
                    [108.6864077, 12.4497599],
                    [108.6862182, 12.4493822],
                    [108.6861401, 12.4491524],
                    [108.6861135, 12.4490341],
                    [108.6860856, 12.4487936],
                    [108.6860844, 12.4486725],
                    [108.6861392, 12.4480909],
                    [108.6861455, 12.4476925],
                    [108.6859126, 12.4475277],
                    [108.6857899, 12.4474541],
                    [108.685534, 12.4473258],
                    [108.6851305, 12.4471728],
                    [108.684629, 12.4470181],
                    [108.6844586, 12.4469453],
                    [108.6843045, 12.4468437],
                    [108.684235, 12.4467831],
                    [108.6841144, 12.4466449],
                    [108.6840642, 12.4465683],
                    [108.6839882, 12.4464097],
                    [108.6839117, 12.4461678],
                    [108.6838706, 12.4460039],
                    [108.6837909, 12.4455477],
                    [108.6837119, 12.4452638],
                    [108.683661, 12.4451252],
                    [108.683498, 12.4447588],
                    [108.6834557, 12.4446395],
                    [108.6834204, 12.4445181],
                    [108.68334, 12.4441745],
                    [108.683247, 12.4438635],
                    [108.6831375, 12.443588],
                    [108.6830729, 12.4434543],
                    [108.6829537, 12.4432426],
                    [108.6827977, 12.443006],
                    [108.6825683, 12.442705],
                    [108.6823153, 12.4424227],
                    [108.68183, 12.441965],
                    [108.681759, 12.4418884],
                    [108.6816954, 12.4418059],
                    [108.6816395, 12.4417182],
                    [108.681592, 12.4416259],
                    [108.6815531, 12.4415299],
                    [108.6815232, 12.4414308],
                    [108.6815025, 12.4413295],
                    [108.6814911, 12.4412268],
                    [108.681494, 12.4410461],
                    [108.6814803, 12.4408918],
                    [108.6814361, 12.440743],
                    [108.681403, 12.4406725],
                    [108.6813165, 12.4405426],
                    [108.6812639, 12.4404845],
                    [108.6811572, 12.4403947],
                    [108.6810568, 12.4402983],
                    [108.6809631, 12.4401956],
                    [108.6808767, 12.440087],
                    [108.6807977, 12.4399731],
                    [108.6807267, 12.4398544],
                    [108.6806638, 12.4397313],
                    [108.680501, 12.4393196],
                    [108.680434, 12.4391824],
                    [108.6803588, 12.4390494],
                    [108.6802757, 12.4389209],
                    [108.6801849, 12.4387974],
                    [108.6800869, 12.4386793],
                    [108.6799818, 12.4385672],
                    [108.6799079, 12.4384956],
                    [108.6798277, 12.4384308],
                    [108.6797419, 12.4383732],
                    [108.6796512, 12.4383233],
                    [108.6795563, 12.4382814],
                    [108.679458, 12.438248],
                    [108.679357, 12.4382233],
                    [108.6792542, 12.4382074],
                    [108.679088, 12.4382007],
                    [108.6789661, 12.4381767],
                    [108.6788552, 12.4381219],
                    [108.6788064, 12.438084],
                    [108.6787265, 12.4379909],
                    [108.6786276, 12.4377998],
                    [108.6784967, 12.4376012],
                    [108.6783273, 12.4374029],
                    [108.6781328, 12.437228],
                    [108.6780272, 12.4371502],
                    [108.6777277, 12.4369647],
                    [108.6776404, 12.4368974],
                    [108.6774822, 12.4367448],
                    [108.6773712, 12.4366042],
                    [108.6772614, 12.4364273],
                    [108.6770979, 12.4361025],
                    [108.6770359, 12.4360082],
                    [108.6769663, 12.4359192],
                    [108.6768895, 12.4358359],
                    [108.6768061, 12.4357589],
                    [108.6767166, 12.4356888],
                    [108.6766216, 12.4356259],
                    [108.6763337, 12.4354762],
                    [108.6762458, 12.4354186],
                    [108.6760849, 12.4352845],
                    [108.6760128, 12.4352088],
                    [108.6758876, 12.4350424],
                    [108.6757894, 12.4348579],
                    [108.6756756, 12.4346806],
                    [108.6755347, 12.434523],
                    [108.6753062, 12.4343179],
                    [108.6752008, 12.4342067],
                    [108.675009, 12.4339684],
                    [108.6748853, 12.4337811],
                    [108.6747899, 12.4336737],
                    [108.6746723, 12.4335897],
                    [108.6745385, 12.4335335],
                    [108.6743953, 12.4335077],
                    [108.6741658, 12.433514],
                    [108.6740878, 12.4335048],
                    [108.6739375, 12.4334614],
                    [108.673867, 12.4334277],
                    [108.6737397, 12.4333384],
                    [108.6736844, 12.433284],
                    [108.6735439, 12.4330942],
                    [108.673428, 12.4329845],
                    [108.6733623, 12.4329386],
                    [108.6731259, 12.4328187],
                    [108.6730482, 12.4327689],
                    [108.6729064, 12.4326517],
                    [108.6728432, 12.4325851],
                    [108.6727345, 12.4324381],
                    [108.6726921, 12.4323635],
                    [108.6725846, 12.4321302],
                    [108.6724663, 12.4318094],
                    [108.6723616, 12.4314737],
                    [108.6722246, 12.4311493],
                    [108.6720647, 12.4308524],
                    [108.6718869, 12.4305836],
                    [108.6719716, 12.4303802],
                    [108.6720039, 12.430275],
                    [108.6720293, 12.430168],
                    [108.6720559, 12.429991],
                    [108.6720651, 12.4297838],
                    [108.6720598, 12.4296457],
                    [108.6720452, 12.4295081],
                    [108.6720214, 12.4293718],
                    [108.6719885, 12.4292373],
                    [108.6719465, 12.4291053],
                    [108.6718958, 12.4289762],
                    [108.6718365, 12.4288506],
                    [108.6717689, 12.4287291],
                    [108.6716773, 12.4285896],
                    [108.6715941, 12.4284452],
                    [108.6715196, 12.4282963],
                    [108.671454, 12.4281435],
                    [108.6713976, 12.4279872],
                    [108.671282, 12.4275749],
                    [108.6711842, 12.4273311],
                    [108.6711248, 12.4272137],
                    [108.6710585, 12.4270998],
                    [108.6709324, 12.4269174],
                    [108.6707611, 12.4267085],
                    [108.6706402, 12.4265748],
                    [108.6703821, 12.4263224],
                    [108.6702454, 12.426204],
                    [108.6697399, 12.4258046],
                    [108.6693337, 12.4254546],
                    [108.6691798, 12.4253058],
                    [108.669033, 12.4251504],
                    [108.6688936, 12.4249886],
                    [108.6686882, 12.4247197],
                    [108.6685932, 12.42458],
                ],
            ],
            [
                [
                    [111.4717312, 8.669554],
                    [111.4717374, 8.6681545],
                    [111.4717536, 8.6667551],
                    [111.4717796, 8.6653558],
                    [111.4718156, 8.6639567],
                    [111.4718615, 8.6625579],
                    [111.4719174, 8.6611594],
                    [111.4719831, 8.6597614],
                    [111.4720588, 8.6583639],
                    [111.4721444, 8.6569669],
                    [111.4722399, 8.6555705],
                    [111.4723453, 8.6541748],
                    [111.4724606, 8.6527799],
                    [111.4725858, 8.6513858],
                    [111.4727208, 8.6499926],
                    [111.4728658, 8.6486004],
                    [111.4730206, 8.6472092],
                    [111.4731853, 8.6458191],
                    [111.4733598, 8.6444301],
                    [111.4735442, 8.6430424],
                    [111.4737385, 8.6416561],
                    [111.4739425, 8.6402711],
                    [111.4741564, 8.6388875],
                    [111.4743801, 8.6375054],
                    [111.4746135, 8.636125],
                    [111.4748568, 8.6347462],
                    [111.4751098, 8.6333691],
                    [111.4753726, 8.6319937],
                    [111.4756452, 8.6306203],
                    [111.4759275, 8.6292487],
                    [111.4762195, 8.6278791],
                    [111.4765212, 8.6265116],
                    [111.4768326, 8.6251462],
                    [111.4771537, 8.623783],
                    [111.4774844, 8.6224221],
                    [111.4778248, 8.6210634],
                    [111.4781748, 8.6197072],
                    [111.4785344, 8.6183534],
                    [111.4789037, 8.6170021],
                    [111.4792824, 8.6156535],
                    [111.4796708, 8.6143074],
                    [111.4800687, 8.6129641],
                    [111.4804761, 8.6116236],
                    [111.480893, 8.610286],
                    [111.4813194, 8.6089512],
                    [111.4817552, 8.6076195],
                    [111.4822005, 8.6062908],
                    [111.4826551, 8.6049652],
                    [111.4831192, 8.6036428],
                    [111.4835926, 8.6023236],
                    [111.4840754, 8.6010077],
                    [111.4845675, 8.5996953],
                    [111.4850689, 8.5983862],
                    [111.4855796, 8.5970807],
                    [111.4860995, 8.5957787],
                    [111.4866286, 8.5944804],
                    [111.487167, 8.5931857],
                    [111.4877145, 8.5918949],
                    [111.4882711, 8.5906078],
                    [111.4888369, 8.5893246],
                    [111.4894117, 8.5880454],
                    [111.4899956, 8.5867702],
                    [111.4905885, 8.5854991],
                    [111.4911905, 8.5842321],
                    [111.4918014, 8.5829693],
                    [111.4924212, 8.5817108],
                    [111.49305, 8.5804566],
                    [111.4936876, 8.5792067],
                    [111.4943341, 8.5779614],
                    [111.4949894, 8.5767205],
                    [111.4956534, 8.5754842],
                    [111.4963263, 8.5742526],
                    [111.4970078, 8.5730256],
                    [111.4976981, 8.5718034],
                    [111.4983969, 8.570586],
                    [111.4991044, 8.5693734],
                    [111.4998205, 8.5681658],
                    [111.5005451, 8.5669632],
                    [111.5012783, 8.5657657],
                    [111.5020199, 8.5645732],
                    [111.5027699, 8.5633859],
                    [111.5035284, 8.5622039],
                    [111.5042952, 8.5610271],
                    [111.5050703, 8.5598557],
                    [111.5058538, 8.5586897],
                    [111.5066454, 8.5575291],
                    [111.5074453, 8.556374],
                    [111.5082534, 8.5552246],
                    [111.5090696, 8.5540807],
                    [111.5098939, 8.5529425],
                    [111.5107262, 8.5518101],
                    [111.5115665, 8.5506835],
                    [111.5124148, 8.5495627],
                    [111.5132711, 8.5484478],
                    [111.5141352, 8.5473389],
                    [111.5150072, 8.546236],
                    [111.5158869, 8.5451392],
                    [111.5167745, 8.5440485],
                    [111.5176697, 8.542964],
                    [111.5185726, 8.5418857],
                    [111.5194831, 8.5408137],
                    [111.5204012, 8.539748],
                    [111.5213269, 8.5386887],
                    [111.52226, 8.5376359],
                    [111.5232006, 8.5365896],
                    [111.5241485, 8.5355498],
                    [111.5251038, 8.5345166],
                    [111.5260665, 8.53349],
                    [111.5270363, 8.5324702],
                    [111.5280134, 8.531457],
                    [111.5289977, 8.5304507],
                    [111.529989, 8.5294513],
                    [111.5309874, 8.5284587],
                    [111.5319929, 8.5274731],
                    [111.5330053, 8.5264945],
                    [111.5340246, 8.5255229],
                    [111.5350507, 8.5245584],
                    [111.5360837, 8.5236011],
                    [111.5371235, 8.5226509],
                    [111.5381699, 8.521708],
                    [111.5392231, 8.5207723],
                    [111.5402828, 8.519844],
                    [111.5413491, 8.518923],
                    [111.5424219, 8.5180094],
                    [111.5435011, 8.5171034],
                    [111.5445867, 8.5162048],
                    [111.5456787, 8.5153137],
                    [111.546777, 8.5144303],
                    [111.5478815, 8.5135545],
                    [111.5489922, 8.5126864],
                    [111.550109, 8.511826],
                    [111.5512319, 8.5109733],
                    [111.5523609, 8.5101285],
                    [111.5534957, 8.5092915],
                    [111.5546365, 8.5084624],
                    [111.5557832, 8.5076413],
                    [111.5569356, 8.5068281],
                    [111.5580938, 8.5060229],
                    [111.5592576, 8.5052257],
                    [111.5604271, 8.5044367],
                    [111.5616021, 8.5036558],
                    [111.5627827, 8.502883],
                    [111.5639687, 8.5021185],
                    [111.56516, 8.5013622],
                    [111.5663568, 8.5006142],
                    [111.5675588, 8.4998745],
                    [111.568766, 8.4991431],
                    [111.5699783, 8.4984202],
                    [111.5711958, 8.4977056],
                    [111.5724183, 8.4969995],
                    [111.5736458, 8.496302],
                    [111.5748782, 8.4956129],
                    [111.5761154, 8.4949324],
                    [111.5773574, 8.4942605],
                    [111.5786042, 8.4935973],
                    [111.5798557, 8.4929427],
                    [111.5811117, 8.4922968],
                    [111.5823723, 8.4916596],
                    [111.5836374, 8.4910311],
                    [111.5849069, 8.4904115],
                    [111.5861808, 8.4898007],
                    [111.587459, 8.4891987],
                    [111.5887414, 8.4886056],
                    [111.590028, 8.4880215],
                    [111.5913187, 8.4874462],
                    [111.5926134, 8.4868799],
                    [111.5939121, 8.4863227],
                    [111.5952148, 8.4857744],
                    [111.5965213, 8.4852352],
                    [111.5978316, 8.484705],
                    [111.5991456, 8.484184],
                    [111.6004633, 8.4836721],
                    [111.6017846, 8.4831693],
                    [111.6031094, 8.4826757],
                    [111.6044377, 8.4821913],
                    [111.6057693, 8.4817162],
                    [111.6071044, 8.4812502],
                    [111.6084426, 8.4807936],
                    [111.6097841, 8.4803462],
                    [111.6111288, 8.4799082],
                    [111.6124765, 8.4794795],
                    [111.6138272, 8.4790601],
                    [111.6151808, 8.4786502],
                    [111.6165373, 8.4782496],
                    [111.6178967, 8.4778584],
                    [111.6192587, 8.4774767],
                    [111.6206235, 8.4771045],
                    [111.6219908, 8.4767417],
                    [111.6233607, 8.4763884],
                    [111.6247331, 8.4760446],
                    [111.6261078, 8.4757103],
                    [111.6274849, 8.4753856],
                    [111.6288643, 8.4750705],
                    [111.6302458, 8.4747649],
                    [111.6316295, 8.4744689],
                    [111.6330153, 8.4741825],
                    [111.6344031, 8.4739057],
                    [111.6357927, 8.4736386],
                    [111.6371843, 8.4733811],
                    [111.6385776, 8.4731333],
                    [111.6399727, 8.4728951],
                    [111.6413694, 8.4726666],
                    [111.6427677, 8.4724478],
                    [111.6441675, 8.4722387],
                    [111.6455688, 8.4720394],
                    [111.6469714, 8.4718497],
                    [111.6483754, 8.4716698],
                    [111.6497806, 8.4714996],
                    [111.6511869, 8.4713392],
                    [111.6525944, 8.4711885],
                    [111.6540029, 8.4710476],
                    [111.6554124, 8.4709165],
                    [111.6568228, 8.4707952],
                    [111.6582339, 8.4706836],
                    [111.6596459, 8.4705818],
                    [111.6610585, 8.4704899],
                    [111.6624718, 8.4704077],
                    [111.6638856, 8.4703353],
                    [111.6652998, 8.4702728],
                    [111.6667145, 8.47022],
                    [111.6681295, 8.4701771],
                    [111.6695448, 8.4701439],
                    [111.6709603, 8.4701206],
                    [111.672376, 8.4701072],
                    [111.6737916, 8.4701035],
                    [111.6752073, 8.4701096],
                    [111.6766229, 8.4701256],
                    [111.6780384, 8.4701514],
                    [111.6794536, 8.470187],
                    [111.6808685, 8.4702324],
                    [111.6822831, 8.4702876],
                    [111.6836973, 8.4703527],
                    [111.6851109, 8.4704275],
                    [111.686524, 8.4705122],
                    [111.6879365, 8.4706066],
                    [111.6893483, 8.4707109],
                    [111.6907593, 8.4708249],
                    [111.6921694, 8.4709487],
                    [111.6935786, 8.4710823],
                    [111.6949869, 8.4712257],
                    [111.6963941, 8.4713788],
                    [111.6978001, 8.4715417],
                    [111.699205, 8.4717144],
                    [111.7006087, 8.4718967],
                    [111.702011, 8.4720888],
                    [111.7034119, 8.4722907],
                    [111.7048113, 8.4725022],
                    [111.7062092, 8.4727235],
                    [111.7076055, 8.4729544],
                    [111.7090002, 8.473195],
                    [111.710393, 8.4734453],
                    [111.7117841, 8.4737052],
                    [111.7131733, 8.4739748],
                    [111.7145606, 8.474254],
                    [111.7159458, 8.4745428],
                    [111.717329, 8.4748412],
                    [111.71871, 8.4751492],
                    [111.7200888, 8.4754668],
                    [111.7214653, 8.4757939],
                    [111.7228395, 8.4761306],
                    [111.7242112, 8.4764768],
                    [111.7255805, 8.4768325],
                    [111.7269472, 8.4771976],
                    [111.7283112, 8.4775723],
                    [111.7296726, 8.4779564],
                    [111.7310312, 8.4783499],
                    [111.732387, 8.4787529],
                    [111.73374, 8.4791652],
                    [111.7350899, 8.4795869],
                    [111.7364368, 8.480018],
                    [111.7377807, 8.4804584],
                    [111.7391214, 8.4809081],
                    [111.7404588, 8.4813671],
                    [111.741793, 8.4818354],
                    [111.7431238, 8.4823129],
                    [111.7444513, 8.4827996],
                    [111.7457752, 8.4832955],
                    [111.7470956, 8.4838006],
                    [111.7484123, 8.4843148],
                    [111.7497254, 8.4848382],
                    [111.7510347, 8.4853706],
                    [111.7523403, 8.4859121],
                    [111.7536419, 8.4864627],
                    [111.7549397, 8.4870222],
                    [111.7562334, 8.4875908],
                    [111.7575231, 8.4881683],
                    [111.7588086, 8.4887547],
                    [111.7600899, 8.48935],
                    [111.761367, 8.4899542],
                    [111.7626398, 8.4905673],
                    [111.7639082, 8.4911891],
                    [111.7651722, 8.4918198],
                    [111.7664316, 8.4924592],
                    [111.7676865, 8.4931073],
                    [111.7689368, 8.4937641],
                    [111.7701824, 8.4944295],
                    [111.7714232, 8.4951036],
                    [111.7726592, 8.4957863],
                    [111.7738904, 8.4964775],
                    [111.7751166, 8.4971772],
                    [111.7763379, 8.4978854],
                    [111.777554, 8.4986021],
                    [111.7787651, 8.4993272],
                    [111.779971, 8.5000606],
                    [111.7812352, 8.5008423],
                    [111.782217, 8.4999064],
                    [111.7832434, 8.4989416],
                    [111.7842767, 8.4979839],
                    [111.7853167, 8.4970334],
                    [111.7863634, 8.4960901],
                    [111.7874168, 8.4951542],
                    [111.7884768, 8.4942255],
                    [111.7895434, 8.4933042],
                    [111.7906164, 8.4923904],
                    [111.7916959, 8.491484],
                    [111.7927818, 8.4905851],
                    [111.7938741, 8.4896937],
                    [111.7949726, 8.48881],
                    [111.7960774, 8.4879339],
                    [111.7971884, 8.4870654],
                    [111.7983055, 8.4862047],
                    [111.7994287, 8.4853518],
                    [111.8005579, 8.4845067],
                    [111.8016931, 8.4836694],
                    [111.8028341, 8.48284],
                    [111.803981, 8.4820186],
                    [111.8051338, 8.4812051],
                    [111.8062922, 8.4803996],
                    [111.8074564, 8.4796022],
                    [111.8086261, 8.4788129],
                    [111.8098015, 8.4780317],
                    [111.8109823, 8.4772587],
                    [111.8121686, 8.4764939],
                    [111.8133603, 8.4757373],
                    [111.8145573, 8.474989],
                    [111.8157596, 8.4742491],
                    [111.8169671, 8.4735175],
                    [111.8181798, 8.4727942],
                    [111.8193976, 8.4720794],
                    [111.8206204, 8.4713731],
                    [111.8218482, 8.4706753],
                    [111.8230809, 8.469986],
                    [111.8243184, 8.4693053],
                    [111.8255608, 8.4686331],
                    [111.8268079, 8.4679696],
                    [111.8280596, 8.4673148],
                    [111.829316, 8.4666687],
                    [111.8305769, 8.4660313],
                    [111.8318423, 8.4654026],
                    [111.8331122, 8.4647827],
                    [111.8343864, 8.4641717],
                    [111.8356649, 8.4635695],
                    [111.8369476, 8.4629762],
                    [111.8382345, 8.4623918],
                    [111.8395256, 8.4618164],
                    [111.8408206, 8.4612499],
                    [111.8421197, 8.4606924],
                    [111.8434227, 8.460144],
                    [111.8447295, 8.4596045],
                    [111.8460401, 8.4590742],
                    [111.8473545, 8.458553],
                    [111.8486725, 8.4580409],
                    [111.8499941, 8.4575379],
                    [111.8513193, 8.4570441],
                    [111.8526479, 8.4565596],
                    [111.8539799, 8.4560842],
                    [111.8553153, 8.4556181],
                    [111.8566539, 8.4551613],
                    [111.8579957, 8.4547138],
                    [111.8593407, 8.4542756],
                    [111.8606888, 8.4538467],
                    [111.8620398, 8.4534272],
                    [111.8633938, 8.4530171],
                    [111.8647507, 8.4526164],
                    [111.8661104, 8.4522251],
                    [111.8674728, 8.4518432],
                    [111.8688379, 8.4514708],
                    [111.8702056, 8.4511079],
                    [111.8715758, 8.4507545],
                    [111.8729485, 8.4504105],
                    [111.8743236, 8.4500762],
                    [111.8757011, 8.4497513],
                    [111.8770808, 8.449436],
                    [111.8784627, 8.4491303],
                    [111.8798468, 8.4488342],
                    [111.8812329, 8.4485477],
                    [111.882621, 8.4482708],
                    [111.8840111, 8.4480036],
                    [111.885403, 8.447746],
                    [111.8867967, 8.4474981],
                    [111.8881921, 8.4472598],
                    [111.8895892, 8.4470312],
                    [111.8909878, 8.4468123],
                    [111.892388, 8.4466032],
                    [111.8937897, 8.4464037],
                    [111.8951927, 8.446214],
                    [111.896597, 8.446034],
                    [111.8980025, 8.4458637],
                    [111.8994093, 8.4457032],
                    [111.9008171, 8.4455525],
                    [111.902226, 8.4454116],
                    [111.9036358, 8.4452804],
                    [111.9050465, 8.445159],
                    [111.9064581, 8.4450473],
                    [111.9078704, 8.4449455],
                    [111.9092834, 8.4448535],
                    [111.910697, 8.4447713],
                    [111.9121112, 8.4446989],
                    [111.9135258, 8.4446362],
                    [111.9149409, 8.4445835],
                    [111.9163563, 8.4445405],
                    [111.9177719, 8.4445073],
                    [111.9191878, 8.444484],
                    [111.9206038, 8.4444705],
                    [111.9220199, 8.4444668],
                    [111.9234359, 8.4444729],
                    [111.9248519, 8.4444889],
                    [111.9262677, 8.4445147],
                    [111.9276833, 8.4445503],
                    [111.9290986, 8.4445957],
                    [111.9305136, 8.4446509],
                    [111.9319281, 8.4447159],
                    [111.9333421, 8.4447908],
                    [111.9347556, 8.4448755],
                    [111.9361684, 8.4449699],
                    [111.9375806, 8.4450742],
                    [111.9389919, 8.4451882],
                    [111.9404024, 8.4453121],
                    [111.941812, 8.4454457],
                    [111.9432207, 8.4455891],
                    [111.9446282, 8.4457422],
                    [111.9460347, 8.4459052],
                    [111.9474399, 8.4460778],
                    [111.9488439, 8.4462603],
                    [111.9502466, 8.4464524],
                    [111.9516479, 8.4466543],
                    [111.9530477, 8.4468659],
                    [111.954446, 8.4470872],
                    [111.9558426, 8.4473181],
                    [111.9572376, 8.4475588],
                    [111.9586309, 8.4478092],
                    [111.9600223, 8.4480692],
                    [111.9614119, 8.4483388],
                    [111.9627995, 8.4486181],
                    [111.9641852, 8.448907],
                    [111.9655687, 8.4492055],
                    [111.9669501, 8.4495135],
                    [111.9683292, 8.4498312],
                    [111.9697061, 8.4501584],
                    [111.9710806, 8.4504952],
                    [111.9724527, 8.4508415],
                    [111.9738223, 8.4511972],
                    [111.9751894, 8.4515625],
                    [111.9765538, 8.4519373],
                    [111.9779156, 8.4523215],
                    [111.9792746, 8.4527151],
                    [111.9806307, 8.4531182],
                    [111.981984, 8.4535307],
                    [111.9833343, 8.4539525],
                    [111.9846816, 8.4543837],
                    [111.9860258, 8.4548242],
                    [111.9873668, 8.4552741],
                    [111.9887047, 8.4557332],
                    [111.9900392, 8.4562016],
                    [111.9913704, 8.4566792],
                    [111.9926981, 8.4571661],
                    [111.9940224, 8.4576621],
                    [111.9953431, 8.4581674],
                    [111.9966603, 8.4586817],
                    [111.9979737, 8.4592052],
                    [111.9992834, 8.4597378],
                    [112.0005893, 8.4602795],
                    [112.0018913, 8.4608302],
                    [112.0031894, 8.4613899],
                    [112.0044834, 8.4619586],
                    [112.0057734, 8.4625363],
                    [112.0070593, 8.4631229],
                    [112.008341, 8.4637184],
                    [112.0096185, 8.4643228],
                    [112.0108916, 8.4649361],
                    [112.0121603, 8.4655581],
                    [112.0134246, 8.4661889],
                    [112.0146844, 8.4668285],
                    [112.0159397, 8.4674768],
                    [112.0171903, 8.4681338],
                    [112.0184362, 8.4687995],
                    [112.0196774, 8.4694737],
                    [112.0209137, 8.4701566],
                    [112.0221452, 8.470848],
                    [112.0233717, 8.471548],
                    [112.0245933, 8.4722564],
                    [112.0258098, 8.4729733],
                    [112.0270212, 8.4736986],
                    [112.0282274, 8.4744323],
                    [112.0294284, 8.4751743],
                    [112.0306241, 8.4759247],
                    [112.0318145, 8.4766833],
                    [112.0329994, 8.4774501],
                    [112.0341789, 8.4782252],
                    [112.0353529, 8.4790084],
                    [112.0365212, 8.4797997],
                    [112.037684, 8.4805992],
                    [112.038841, 8.4814066],
                    [112.0399923, 8.4822221],
                    [112.0411378, 8.4830455],
                    [112.0422774, 8.4838769],
                    [112.0434111, 8.4847161],
                    [112.0445388, 8.4855632],
                    [112.0456604, 8.486418],
                    [112.046776, 8.4872807],
                    [112.0478855, 8.488151],
                    [112.0489887, 8.489029],
                    [112.0500857, 8.4899146],
                    [112.0511764, 8.4908079],
                    [112.0522607, 8.4917086],
                    [112.0533386, 8.4926169],
                    [112.0544101, 8.4935326],
                    [112.055475, 8.4944557],
                    [112.0565333, 8.4953862],
                    [112.0575851, 8.496324],
                    [112.0586301, 8.4972691],
                    [112.0596685, 8.4982214],
                    [112.0607, 8.4991808],
                    [112.0617247, 8.5001474],
                    [112.0627426, 8.5011211],
                    [112.0637535, 8.5021018],
                    [112.0647574, 8.5030895],
                    [112.0657544, 8.5040841],
                    [112.0667442, 8.5050856],
                    [112.0677269, 8.5060939],
                    [112.0687024, 8.5071091],
                    [112.0696707, 8.508131],
                    [112.0706318, 8.5091595],
                    [112.0715855, 8.5101947],
                    [112.0725319, 8.5112365],
                    [112.0734708, 8.5122848],
                    [112.0744023, 8.5133396],
                    [112.0753263, 8.5144009],
                    [112.0762428, 8.5154685],
                    [112.0771516, 8.5165424],
                    [112.0780529, 8.5176226],
                    [112.0789464, 8.518709],
                    [112.0798322, 8.5198016],
                    [112.0807102, 8.5209004],
                    [112.0815805, 8.5220051],
                    [112.0824429, 8.5231159],
                    [112.0832973, 8.5242326],
                    [112.0841439, 8.5253553],
                    [112.0849824, 8.5264837],
                    [112.085813, 8.527618],
                    [112.0866354, 8.5287579],
                    [112.0874498, 8.5299036],
                    [112.088256, 8.5310548],
                    [112.0890541, 8.5322117],
                    [112.0898439, 8.533374],
                    [112.0906254, 8.5345418],
                    [112.0913987, 8.5357149],
                    [112.0921636, 8.5368934],
                    [112.0929202, 8.5380772],
                    [112.0936683, 8.5392661],
                    [112.094408, 8.5404603],
                    [112.0951392, 8.5416595],
                    [112.0958619, 8.5428637],
                    [112.096576, 8.544073],
                    [112.0972815, 8.5452871],
                    [112.0979784, 8.5465062],
                    [112.0986666, 8.54773],
                    [112.0993462, 8.5489585],
                    [112.100017, 8.5501918],
                    [112.100679, 8.5514296],
                    [112.1013323, 8.552672],
                    [112.1019767, 8.5539189],
                    [112.1026123, 8.5551702],
                    [112.103239, 8.556426],
                    [112.1038568, 8.557686],
                    [112.1044656, 8.5589502],
                    [112.1050654, 8.5602187],
                    [112.1056562, 8.5614913],
                    [112.106238, 8.5627679],
                    [112.1068107, 8.5640485],
                    [112.1073743, 8.5653331],
                    [112.1079288, 8.5666216],
                    [112.1084742, 8.5679138],
                    [112.1090104, 8.5692098],
                    [112.1095373, 8.5705095],
                    [112.1100551, 8.5718128],
                    [112.1105635, 8.5731196],
                    [112.1110627, 8.57443],
                    [112.1115526, 8.5757438],
                    [112.1120332, 8.5770609],
                    [112.1125044, 8.5783813],
                    [112.1129663, 8.579705],
                    [112.1134187, 8.5810318],
                    [112.1138617, 8.5823617],
                    [112.1142953, 8.5836946],
                    [112.1147194, 8.5850306],
                    [112.115134, 8.5863694],
                    [112.1155392, 8.587711],
                    [112.1159348, 8.5890555],
                    [112.1163208, 8.5904026],
                    [112.1166973, 8.5917524],
                    [112.1170642, 8.5931047],
                    [112.1174215, 8.5944596],
                    [112.1177692, 8.5958169],
                    [112.1181073, 8.5971765],
                    [112.1184357, 8.5985385],
                    [112.1187544, 8.5999027],
                    [112.1190635, 8.6012691],
                    [112.1193628, 8.6026376],
                    [112.1196525, 8.6040081],
                    [112.1199324, 8.6053806],
                    [112.1202026, 8.606755],
                    [112.120463, 8.6081312],
                    [112.1207136, 8.6095092],
                    [112.1209545, 8.6108889],
                    [112.1211856, 8.6122703],
                    [112.1214069, 8.6136531],
                    [112.1216184, 8.6150375],
                    [112.12182, 8.6164233],
                    [112.1220118, 8.6178105],
                    [112.1221938, 8.619199],
                    [112.1223659, 8.6205887],
                    [112.1225281, 8.6219795],
                    [112.1226805, 8.6233714],
                    [112.122823, 8.6247644],
                    [112.1229557, 8.6261582],
                    [112.1230784, 8.627553],
                    [112.1231912, 8.6289486],
                    [112.1232942, 8.6303449],
                    [112.1233872, 8.6317419],
                    [112.1234703, 8.6331395],
                    [112.1235436, 8.6345377],
                    [112.1236068, 8.6359363],
                    [112.1236602, 8.6373353],
                    [112.1237037, 8.6387346],
                    [112.1237372, 8.6401342],
                    [112.1237608, 8.641534],
                    [112.1237744, 8.6429339],
                    [112.1237782, 8.6443339],
                    [112.123772, 8.6457338],
                    [112.1237558, 8.6471337],
                    [112.1237298, 8.6485334],
                    [112.1236938, 8.6499329],
                    [112.1236479, 8.6513322],
                    [112.123592, 8.652731],
                    [112.1235263, 8.6541294],
                    [112.1234506, 8.6555274],
                    [112.123365, 8.6569247],
                    [112.1232695, 8.6583215],
                    [112.1232382, 8.6587357],
                    [112.1238607, 8.6584987],
                    [112.1251863, 8.6580046],
                    [112.1265154, 8.6575197],
                    [112.1278479, 8.6570441],
                    [112.1291837, 8.6565777],
                    [112.1305227, 8.6561205],
                    [112.131865, 8.6556727],
                    [112.1332105, 8.6552341],
                    [112.134559, 8.654805],
                    [112.1359105, 8.6543851],
                    [112.137265, 8.6539747],
                    [112.1386223, 8.6535736],
                    [112.1399825, 8.653182],
                    [112.1413454, 8.6527998],
                    [112.142711, 8.652427],
                    [112.1440792, 8.6520638],
                    [112.1454499, 8.65171],
                    [112.1468231, 8.6513657],
                    [112.1481987, 8.651031],
                    [112.1495767, 8.6507058],
                    [112.1509569, 8.6503902],
                    [112.1523393, 8.6500841],
                    [112.1537239, 8.6497877],
                    [112.1551106, 8.6495008],
                    [112.1564992, 8.6492236],
                    [112.1578898, 8.648956],
                    [112.1592822, 8.648698],
                    [112.1606764, 8.6484497],
                    [112.1620724, 8.6482111],
                    [112.16347, 8.6479822],
                    [112.1648692, 8.6477629],
                    [112.16627, 8.6475534],
                    [112.1676721, 8.6473536],
                    [112.1690757, 8.6471635],
                    [112.1704806, 8.6469831],
                    [112.1718867, 8.6468125],
                    [112.173294, 8.6466516],
                    [112.1747024, 8.6465005],
                    [112.1761119, 8.6463592],
                    [112.1775223, 8.6462276],
                    [112.1789336, 8.6461058],
                    [112.1803457, 8.6459938],
                    [112.1817586, 8.6458916],
                    [112.1831722, 8.6457992],
                    [112.1845864, 8.6457166],
                    [112.1860012, 8.6456438],
                    [112.1874164, 8.6455809],
                    [112.1888321, 8.6455277],
                    [112.190248, 8.6454843],
                    [112.1916643, 8.6454508],
                    [112.1930808, 8.6454271],
                    [112.1944974, 8.6454132],
                    [112.1959141, 8.6454091],
                    [112.1973307, 8.6454148],
                    [112.1987473, 8.6454304],
                    [112.2001637, 8.6454558],
                    [112.20158, 8.645491],
                    [112.2029959, 8.645536],
                    [112.2044115, 8.6455909],
                    [112.2058267, 8.6456555],
                    [112.2072413, 8.64573],
                    [112.2086554, 8.6458142],
                    [112.2100689, 8.6459083],
                    [112.2114817, 8.6460122],
                    [112.2128937, 8.6461258],
                    [112.2143048, 8.6462493],
                    [112.2157151, 8.6463825],
                    [112.2171244, 8.6465255],
                    [112.2185326, 8.6466782],
                    [112.2199397, 8.6468408],
                    [112.2213456, 8.647013],
                    [112.2227503, 8.647195],
                    [112.2241536, 8.6473868],
                    [112.2255555, 8.6475883],
                    [112.226956, 8.6477994],
                    [112.228355, 8.6480203],
                    [112.2297523, 8.6482509],
                    [112.231148, 8.6484912],
                    [112.2325419, 8.6487411],
                    [112.233934, 8.6490007],
                    [112.2353243, 8.6492699],
                    [112.2367126, 8.6495488],
                    [112.2380989, 8.6498373],
                    [112.2394831, 8.6501354],
                    [112.2408652, 8.6504431],
                    [112.242245, 8.6507603],
                    [112.2436226, 8.6510871],
                    [112.2449978, 8.6514235],
                    [112.2463706, 8.6517693],
                    [112.2477409, 8.6521247],
                    [112.2491087, 8.6524896],
                    [112.2504738, 8.652864],
                    [112.2518362, 8.6532478],
                    [112.2531959, 8.653641],
                    [112.2545528, 8.6540436],
                    [112.2559068, 8.6544557],
                    [112.2572578, 8.6548771],
                    [112.2586058, 8.6553079],
                    [112.2599507, 8.655748],
                    [112.2612924, 8.6561974],
                    [112.262631, 8.6566562],
                    [112.2639662, 8.6571241],
                    [112.2652981, 8.6576014],
                    [112.2666266, 8.6580878],
                    [112.2679516, 8.6585835],
                    [112.269273, 8.6590883],
                    [112.2705908, 8.6596023],
                    [112.271905, 8.6601253],
                    [112.2732154, 8.6606575],
                    [112.274522, 8.6611988],
                    [112.2758247, 8.6617491],
                    [112.2771235, 8.6623084],
                    [112.2784183, 8.6628767],
                    [112.279709, 8.663454],
                    [112.2809957, 8.6640402],
                    [112.2822781, 8.6646353],
                    [112.2835562, 8.6652393],
                    [112.2848301, 8.6658521],
                    [112.2860995, 8.6664737],
                    [112.2873646, 8.6671042],
                    [112.2886251, 8.6677433],
                    [112.2898811, 8.6683912],
                    [112.2911324, 8.6690478],
                    [112.292379, 8.6697131],
                    [112.2936209, 8.6703869],
                    [112.2941509, 8.6706793],
                    [112.295207, 8.6708767],
                    [112.2965971, 8.6711464],
                    [112.2979854, 8.6714259],
                    [112.2993716, 8.6717149],
                    [112.3007557, 8.6720135],
                    [112.3021377, 8.6723218],
                    [112.3035175, 8.6726395],
                    [112.304895, 8.6729669],
                    [112.3062701, 8.6733038],
                    [112.3076428, 8.6736502],
                    [112.309013, 8.6740061],
                    [112.3103806, 8.6743715],
                    [112.3117456, 8.6747464],
                    [112.313108, 8.6751308],
                    [112.3144675, 8.6755245],
                    [112.3158243, 8.6759277],
                    [112.3171781, 8.6763403],
                    [112.318529, 8.6767623],
                    [112.3198769, 8.6771936],
                    [112.3212216, 8.6776342],
                    [112.3225632, 8.6780842],
                    [112.3239016, 8.6785434],
                    [112.3252367, 8.6790119],
                    [112.3265684, 8.6794897],
                    [112.3278968, 8.6799766],
                    [112.3292216, 8.6804728],
                    [112.3305429, 8.6809781],
                    [112.3318605, 8.6814926],
                    [112.3331745, 8.6820162],
                    [112.3344847, 8.6825489],
                    [112.3357912, 8.6830907],
                    [112.3370937, 8.6836415],
                    [112.3383923, 8.6842013],
                    [112.3396869, 8.6847701],
                    [112.3409774, 8.6853479],
                    [112.3422638, 8.6859346],
                    [112.3435461, 8.6865302],
                    [112.344824, 8.6871347],
                    [112.3460976, 8.687748],
                    [112.3473669, 8.6883701],
                    [112.3486317, 8.689001],
                    [112.349892, 8.6896407],
                    [112.3511477, 8.6902891],
                    [112.3523988, 8.6909462],
                    [112.3536452, 8.6916119],
                    [112.3548869, 8.6922862],
                    [112.3561237, 8.6929692],
                    [112.3573557, 8.6936607],
                    [112.3585827, 8.6943607],
                    [112.3598048, 8.6950692],
                    [112.3610218, 8.6957861],
                    [112.3622336, 8.6965115],
                    [112.3634403, 8.6972453],
                    [112.3646418, 8.6979874],
                    [112.3658379, 8.6987378],
                    [112.3670287, 8.6994964],
                    [112.3682141, 8.7002634],
                    [112.3693941, 8.7010385],
                    [112.3705685, 8.7018217],
                    [112.3717373, 8.7026131],
                    [112.3729004, 8.7034126],
                    [112.3740579, 8.7042201],
                    [112.3752096, 8.7050356],
                    [112.3763555, 8.7058591],
                    [112.3774955, 8.7066905],
                    [112.3786296, 8.7075297],
                    [112.3797578, 8.7083768],
                    [112.3808799, 8.7092318],
                    [112.3819958, 8.7100944],
                    [112.3831057, 8.7109648],
                    [112.3842093, 8.7118428],
                    [112.3851013, 8.7125627],
                    [112.3855828, 8.7118899],
                    [112.3864092, 8.7107523],
                    [112.3872436, 8.7096205],
                    [112.388086, 8.7084944],
                    [112.3889364, 8.7073743],
                    [112.3897947, 8.70626],
                    [112.3906608, 8.7051517],
                    [112.3915349, 8.7040495],
                    [112.3924167, 8.7029533],
                    [112.3933063, 8.7018633],
                    [112.3942036, 8.7007794],
                    [112.3951085, 8.6997018],
                    [112.3960211, 8.6986305],
                    [112.3969412, 8.6975656],
                    [112.3978689, 8.696507],
                    [112.3988041, 8.6954549],
                    [112.3997467, 8.6944093],
                    [112.4006967, 8.6933703],
                    [112.4016541, 8.6923379],
                    [112.4026187, 8.6913121],
                    [112.4035906, 8.690293],
                    [112.4045697, 8.6892807],
                    [112.405556, 8.6882752],
                    [112.4065494, 8.6872766],
                    [112.4075498, 8.6862849],
                    [112.4085573, 8.6853001],
                    [112.4095717, 8.6843224],
                    [112.410593, 8.6833516],
                    [112.4116212, 8.682388],
                    [112.4126562, 8.6814316],
                    [112.4136979, 8.6804823],
                    [112.4147464, 8.6795403],
                    [112.4158015, 8.6786056],
                    [112.4168632, 8.6776782],
                    [112.4179315, 8.6767581],
                    [112.4190063, 8.6758455],
                    [112.4200875, 8.6749404],
                    [112.4211751, 8.6740428],
                    [112.4222691, 8.6731528],
                    [112.4233693, 8.6722703],
                    [112.4244758, 8.6713955],
                    [112.4255884, 8.6705284],
                    [112.4267072, 8.6696691],
                    [112.4278321, 8.6688175],
                    [112.4289629, 8.6679737],
                    [112.4300997, 8.6671377],
                    [112.4312425, 8.6663097],
                    [112.432391, 8.6654896],
                    [112.4335454, 8.6646775],
                    [112.4347055, 8.6638735],
                    [112.4358712, 8.6630774],
                    [112.4370426, 8.6622895],
                    [112.4382195, 8.6615097],
                    [112.439402, 8.6607381],
                    [112.4405898, 8.6599747],
                    [112.4417831, 8.6592196],
                    [112.4429817, 8.6584727],
                    [112.4441856, 8.6577342],
                    [112.4453947, 8.657004],
                    [112.4466089, 8.6562823],
                    [112.4478282, 8.6555689],
                    [112.4490525, 8.6548641],
                    [112.4502818, 8.6541677],
                    [112.4515161, 8.6534799],
                    [112.4527551, 8.6528007],
                    [112.453999, 8.65213],
                    [112.4552476, 8.651468],
                    [112.4565008, 8.6508147],
                    [112.4577587, 8.6501701],
                    [112.4590211, 8.6495342],
                    [112.4602879, 8.648907],
                    [112.4615592, 8.6482887],
                    [112.4628349, 8.6476792],
                    [112.4641148, 8.6470786],
                    [112.465399, 8.6464868],
                    [112.4666873, 8.645904],
                    [112.4679797, 8.6453301],
                    [112.4692762, 8.6447651],
                    [112.4705767, 8.6442092],
                    [112.471881, 8.6436623],
                    [112.4731892, 8.6431245],
                    [112.4745012, 8.6425957],
                    [112.4758169, 8.6420761],
                    [112.4771363, 8.6415656],
                    [112.4784592, 8.6410642],
                    [112.4797857, 8.640572],
                    [112.4811156, 8.6400891],
                    [112.482449, 8.6396154],
                    [112.4837856, 8.6391509],
                    [112.4851255, 8.6386957],
                    [112.4864686, 8.6382498],
                    [112.4878149, 8.6378132],
                    [112.4891642, 8.637386],
                    [112.4905165, 8.6369681],
                    [112.4918717, 8.6365596],
                    [112.4932298, 8.6361605],
                    [112.4945907, 8.6357709],
                    [112.4959543, 8.6353907],
                    [112.4973206, 8.6350199],
                    [112.4986895, 8.6346586],
                    [112.5000609, 8.6343069],
                    [112.5014347, 8.6339646],
                    [112.502811, 8.6336319],
                    [112.5041896, 8.6333087],
                    [112.5055704, 8.6329951],
                    [112.5069535, 8.6326911],
                    [112.5083386, 8.6323967],
                    [112.5097259, 8.6321119],
                    [112.5111151, 8.6318367],
                    [112.5125062, 8.6315711],
                    [112.5138991, 8.6313152],
                    [112.5152939, 8.631069],
                    [112.5166904, 8.6308324],
                    [112.5180885, 8.6306056],
                    [112.5194881, 8.6303884],
                    [112.5208893, 8.6301809],
                    [112.522292, 8.6299832],
                    [112.5236959, 8.6297951],
                    [112.5251012, 8.6296169],
                    [112.5265078, 8.6294483],
                    [112.5279154, 8.6292895],
                    [112.5293242, 8.6291405],
                    [112.530734, 8.6290013],
                    [112.5321447, 8.6288718],
                    [112.5335564, 8.6287521],
                    [112.5349688, 8.6286422],
                    [112.536382, 8.6285421],
                    [112.5377959, 8.6284518],
                    [112.5392103, 8.6283714],
                    [112.5406254, 8.6283007],
                    [112.5420408, 8.6282398],
                    [112.5434567, 8.6281887],
                    [112.5448729, 8.6281475],
                    [112.5462894, 8.6281161],
                    [112.547706, 8.6280945],
                    [112.5491228, 8.6280827],
                    [112.5505396, 8.6280807],
                    [112.5519564, 8.6280886],
                    [112.5533731, 8.6281063],
                    [112.5547896, 8.6281338],
                    [112.5562059, 8.6281712],
                    [112.5576219, 8.6282183],
                    [112.5590376, 8.6282753],
                    [112.5604528, 8.6283421],
                    [112.5618674, 8.6284186],
                    [112.5632816, 8.628505],
                    [112.564695, 8.6286012],
                    [112.5661078, 8.6287072],
                    [112.5675197, 8.628823],
                    [112.5689308, 8.6289486],
                    [112.570341, 8.629084],
                    [112.5717502, 8.6292291],
                    [112.5731583, 8.629384],
                    [112.5745653, 8.6295486],
                    [112.5759711, 8.629723],
                    [112.5773756, 8.6299072],
                    [112.5787788, 8.6301011],
                    [112.5801806, 8.6303047],
                    [112.5815809, 8.630518],
                    [112.5829796, 8.630741],
                    [112.5843767, 8.6309737],
                    [112.5857722, 8.6312161],
                    [112.5871658, 8.6314682],
                    [112.5885577, 8.6317299],
                    [112.5899477, 8.6320012],
                    [112.5913357, 8.6322822],
                    [112.5927217, 8.6325728],
                    [112.5941056, 8.632873],
                    [112.5950337, 8.6330811],
                    [112.5957378, 8.6328474],
                    [112.597084, 8.6324108],
                    [112.5984334, 8.6319836],
                    [112.5997856, 8.6315657],
                    [112.6011409, 8.6311572],
                    [112.602499, 8.6307581],
                    [112.6038599, 8.6303684],
                    [112.6052235, 8.6299882],
                    [112.6065898, 8.6296175],
                    [112.6079586, 8.6292562],
                    [112.6093301, 8.6289044],
                    [112.6107039, 8.6285622],
                    [112.6120802, 8.6282295],
                    [112.6134588, 8.6279063],
                    [112.6148396, 8.6275927],
                    [112.6162227, 8.6272886],
                    [112.6176078, 8.6269942],
                    [112.618995, 8.6267094],
                    [112.6203842, 8.6264342],
                    [112.6217754, 8.6261686],
                    [112.6231683, 8.6259127],
                    [112.6245631, 8.6256665],
                    [112.6259595, 8.6254299],
                    [112.6273576, 8.625203],
                    [112.6287573, 8.6249859],
                    [112.6301585, 8.6247784],
                    [112.6315611, 8.6245806],
                    [112.6329651, 8.6243926],
                    [112.6343704, 8.6242143],
                    [112.6357769, 8.6240458],
                    [112.6371846, 8.623887],
                    [112.6385934, 8.623738],
                    [112.6400032, 8.6235988],
                    [112.6414139, 8.6234693],
                    [112.6428256, 8.6233496],
                    [112.644238, 8.6232397],
                    [112.6456512, 8.6231396],
                    [112.6470651, 8.6230493],
                    [112.6484795, 8.6229688],
                    [112.6498945, 8.6228981],
                    [112.65131, 8.6228373],
                    [112.6527259, 8.6227862],
                    [112.6541421, 8.622745],
                    [112.6555585, 8.6227135],
                    [112.6569752, 8.6226919],
                    [112.6583919, 8.6226802],
                    [112.6598088, 8.6226782],
                    [112.6612255, 8.6226861],
                    [112.6626422, 8.6227038],
                    [112.6640588, 8.6227313],
                    [112.6654751, 8.6227686],
                    [112.6668911, 8.6228158],
                    [112.6683067, 8.6228727],
                    [112.6697219, 8.6229395],
                    [112.6711366, 8.6230161],
                    [112.6725507, 8.6231025],
                    [112.6739642, 8.6231987],
                    [112.675377, 8.6233047],
                    [112.6767889, 8.6234205],
                    [112.6782, 8.6235461],
                    [112.6796102, 8.6236814],
                    [112.6810194, 8.6238266],
                    [112.6824275, 8.6239815],
                    [112.6838345, 8.6241461],
                    [112.6852403, 8.6243205],
                    [112.6866448, 8.6245047],
                    [112.688048, 8.6246985],
                    [112.6894498, 8.6249022],
                    [112.69085, 8.6251155],
                    [112.6922488, 8.6253385],
                    [112.6936459, 8.6255712],
                    [112.6950413, 8.6258136],
                    [112.696435, 8.6260657],
                    [112.6978269, 8.6263274],
                    [112.6992169, 8.6265987],
                    [112.7006049, 8.6268797],
                    [112.7019909, 8.6271703],
                    [112.7033748, 8.6274706],
                    [112.7047565, 8.6277804],
                    [112.706136, 8.6280997],
                    [112.7075132, 8.6284286],
                    [112.708888, 8.6287671],
                    [112.7102604, 8.6291151],
                    [112.7116303, 8.6294726],
                    [112.7129976, 8.6298396],
                    [112.7143623, 8.630216],
                    [112.7157243, 8.6306019],
                    [112.7170835, 8.6309973],
                    [112.7184399, 8.631402],
                    [112.7197934, 8.6318162],
                    [112.7211439, 8.6322397],
                    [112.7224913, 8.6326726],
                    [112.7238357, 8.6331148],
                    [112.7251769, 8.6335663],
                    [112.7265148, 8.6340271],
                    [112.7278495, 8.6344971],
                    [112.7291808, 8.6349764],
                    [112.7305086, 8.6354649],
                    [112.731833, 8.6359626],
                    [112.7331538, 8.6364695],
                    [112.734471, 8.6369856],
                    [112.7357844, 8.6375107],
                    [112.7370942, 8.6380449],
                    [112.7384001, 8.6385882],
                    [112.7397021, 8.6391406],
                    [112.7410001, 8.6397019],
                    [112.7422942, 8.6402723],
                    [112.7435841, 8.6408516],
                    [112.74487, 8.6414398],
                    [112.7461516, 8.6420369],
                    [112.747429, 8.6426429],
                    [112.748702, 8.6432577],
                    [112.7499706, 8.6438814],
                    [112.7512348, 8.6445138],
                    [112.7524945, 8.6451549],
                    [112.7537496, 8.6458048],
                    [112.755, 8.6464634],
                    [112.7562458, 8.6471306],
                    [112.7574867, 8.6478064],
                    [112.7587229, 8.6484909],
                    [112.7599542, 8.6491838],
                    [112.7611805, 8.6498853],
                    [112.7624018, 8.6505953],
                    [112.7636181, 8.6513137],
                    [112.7648292, 8.6520406],
                    [112.7660351, 8.6527758],
                    [112.7672358, 8.6535193],
                    [112.7684312, 8.6542712],
                    [112.7696213, 8.6550313],
                    [112.7708059, 8.6557997],
                    [112.771985, 8.6565762],
                    [112.7731586, 8.6573609],
                    [112.7743266, 8.6581537],
                    [112.775489, 8.6589546],
                    [112.7766456, 8.6597636],
                    [112.7777965, 8.6605805],
                    [112.7789415, 8.6614054],
                    [112.7800807, 8.6622382],
                    [112.7812139, 8.6630788],
                    [112.7823412, 8.6639273],
                    [112.7834624, 8.6647836],
                    [112.7845775, 8.6656477],
                    [112.7856864, 8.6665194],
                    [112.7867891, 8.6673989],
                    [112.7878856, 8.6682859],
                    [112.7889757, 8.6691805],
                    [112.7891848, 8.6693546],
                    [112.7011591, 9.0176673],
                    [112.6997694, 9.0179523],
                    [112.6983802, 9.0182272],
                    [112.696989, 9.0184925],
                    [112.6955961, 9.0187481],
                    [112.6942013, 9.0189941],
                    [112.6928049, 9.0192304],
                    [112.6914068, 9.0194571],
                    [112.6900071, 9.019674],
                    [112.6886059, 9.0198813],
                    [112.6872033, 9.0200788],
                    [112.6857993, 9.0202666],
                    [112.684394, 9.0204447],
                    [112.6829875, 9.0206131],
                    [112.6815798, 9.0207717],
                    [112.6807648, 9.0208548],
                    [112.6799748, 9.0209399],
                    [112.6791788, 9.0213064],
                    [112.6778904, 9.0218886],
                    [112.676598, 9.0224619],
                    [112.6753015, 9.0230263],
                    [112.6740011, 9.0235816],
                    [112.6726967, 9.0241279],
                    [112.6713885, 9.0246652],
                    [112.6700765, 9.0251934],
                    [112.6687608, 9.0257125],
                    [112.6674415, 9.0262225],
                    [112.6661185, 9.0267233],
                    [112.664792, 9.027215],
                    [112.6634621, 9.0276975],
                    [112.6621288, 9.0281707],
                    [112.6607921, 9.0286347],
                    [112.6594522, 9.0290894],
                    [112.6581091, 9.0295348],
                    [112.6567629, 9.0299709],
                    [112.6554136, 9.0303977],
                    [112.6540613, 9.0308151],
                    [112.652706, 9.0312232],
                    [112.6513479, 9.0316218],
                    [112.6499871, 9.0320111],
                    [112.6486234, 9.0323909],
                    [112.6472572, 9.0327612],
                    [112.6458883, 9.0331221],
                    [112.6445169, 9.0334735],
                    [112.643143, 9.0338154],
                    [112.6417667, 9.0341477],
                    [112.6403881, 9.0344705],
                    [112.6390073, 9.0347838],
                    [112.6376243, 9.0350875],
                    [112.6362391, 9.0353816],
                    [112.6348519, 9.0356661],
                    [112.6334627, 9.035941],
                    [112.6320716, 9.0362063],
                    [112.6306786, 9.0364619],
                    [112.6292838, 9.0367079],
                    [112.6278874, 9.0369442],
                    [112.6264893, 9.0371708],
                    [112.6250896, 9.0373877],
                    [112.6236884, 9.037595],
                    [112.6222858, 9.0377925],
                    [112.6208818, 9.0379803],
                    [112.6194765, 9.0381584],
                    [112.61807, 9.0383267],
                    [112.6166623, 9.0384853],
                    [112.6152535, 9.0386342],
                    [112.6138437, 9.0387733],
                    [112.612433, 9.0389026],
                    [112.6110214, 9.0390221],
                    [112.6096089, 9.0391319],
                    [112.6081957, 9.0392319],
                    [112.6067819, 9.0393221],
                    [112.6053674, 9.0394025],
                    [112.6039524, 9.0394731],
                    [112.6025369, 9.0395339],
                    [112.601121, 9.0395849],
                    [112.5997048, 9.0396261],
                    [112.5982884, 9.0396575],
                    [112.5968717, 9.0396791],
                    [112.595455, 9.0396908],
                    [112.5940382, 9.0396928],
                    [112.5926214, 9.0396849],
                    [112.5912047, 9.0396673],
                    [112.5897881, 9.0396398],
                    [112.5883718, 9.0396025],
                    [112.5869558, 9.0395554],
                    [112.5855402, 9.0394985],
                    [112.584125, 9.0394318],
                    [112.5827103, 9.0393553],
                    [112.5812962, 9.039269],
                    [112.5798827, 9.0391729],
                    [112.57847, 9.039067],
                    [112.577058, 9.0389513],
                    [112.5756469, 9.0388259],
                    [112.5742367, 9.0386907],
                    [112.5728275, 9.0385457],
                    [112.5714194, 9.038391],
                    [112.5700124, 9.0382265],
                    [112.5686066, 9.0380523],
                    [112.5672021, 9.0378684],
                    [112.5657989, 9.0376747],
                    [112.5643972, 9.0374713],
                    [112.5629969, 9.0372583],
                    [112.5615981, 9.0370355],
                    [112.560201, 9.036803],
                    [112.5588056, 9.0365609],
                    [112.5574119, 9.0363091],
                    [112.55602, 9.0360477],
                    [112.5546301, 9.0357766],
                    [112.553242, 9.035496],
                    [112.5518561, 9.0352057],
                    [112.5504722, 9.0349058],
                    [112.5490904, 9.0345963],
                    [112.5477109, 9.0342773],
                    [112.5463337, 9.0339488],
                    [112.5449589, 9.0336107],
                    [112.5435865, 9.0332631],
                    [112.5422166, 9.0329059],
                    [112.5408493, 9.0325394],
                    [112.5394846, 9.0321633],
                    [112.5381226, 9.0317778],
                    [112.5367634, 9.0313829],
                    [112.535407, 9.0309786],
                    [112.5340536, 9.0305649],
                    [112.5327031, 9.0301419],
                    [112.5313556, 9.0297095],
                    [112.5300112, 9.0292677],
                    [112.5294168, 9.0290678],
                    [112.529289, 9.0292934],
                    [112.5285883, 9.0305103],
                    [112.5278789, 9.0317223],
                    [112.5271609, 9.0329293],
                    [112.5264344, 9.0341313],
                    [112.5256994, 9.0353283],
                    [112.5249559, 9.0365202],
                    [112.5242039, 9.0377068],
                    [112.5234436, 9.0388883],
                    [112.5226748, 9.0400644],
                    [112.5218977, 9.0412352],
                    [112.5211124, 9.0424005],
                    [112.5203188, 9.0435604],
                    [112.5195169, 9.0447148],
                    [112.5187069, 9.0458636],
                    [112.5178888, 9.0470067],
                    [112.5170625, 9.0481441],
                    [112.5162282, 9.0492758],
                    [112.5153859, 9.0504017],
                    [112.5145356, 9.0515217],
                    [112.5136774, 9.0526358],
                    [112.5128113, 9.0537439],
                    [112.5119373, 9.054846],
                    [112.5110555, 9.055942],
                    [112.510166, 9.0570319],
                    [112.5092688, 9.0581156],
                    [112.5083639, 9.059193],
                    [112.5074513, 9.0602642],
                    [112.5065312, 9.0613289],
                    [112.5056035, 9.0623873],
                    [112.5046684, 9.0634393],
                    [112.5037258, 9.0644847],
                    [112.5027757, 9.0655236],
                    [112.5018184, 9.0665559],
                    [112.5008537, 9.0675815],
                    [112.4998818, 9.0686004],
                    [112.4989026, 9.0696125],
                    [112.4979163, 9.0706179],
                    [112.4969229, 9.0716163],
                    [112.4959224, 9.0726079],
                    [112.4949149, 9.0735925],
                    [112.4939005, 9.0745701],
                    [112.4928791, 9.0755407],
                    [112.4918508, 9.0765042],
                    [112.4908158, 9.0774605],
                    [112.4897739, 9.0784096],
                    [112.4887254, 9.0793515],
                    [112.4876702, 9.0802861],
                    [112.4866083, 9.0812133],
                    [112.48554, 9.0821332],
                    [112.4844651, 9.0830457],
                    [112.4833837, 9.0839507],
                    [112.482296, 9.0848482],
                    [112.4812019, 9.0857381],
                    [112.4801015, 9.0866204],
                    [112.4789949, 9.0874951],
                    [112.4778821, 9.0883621],
                    [112.4767632, 9.0892214],
                    [112.4756382, 9.0900729],
                    [112.4745071, 9.0909166],
                    [112.4733701, 9.0917524],
                    [112.4722272, 9.0925803],
                    [112.4710785, 9.0934003],
                    [112.4699239, 9.0942124],
                    [112.4687637, 9.0950164],
                    [112.4675977, 9.0958123],
                    [112.4664261, 9.0966002],
                    [112.4652489, 9.0973799],
                    [112.4640663, 9.0981515],
                    [112.4628782, 9.0989148],
                    [112.4616847, 9.0996699],
                    [112.4604858, 9.1004167],
                    [112.4592817, 9.1011552],
                    [112.4580724, 9.1018853],
                    [112.4568579, 9.1026071],
                    [112.4556383, 9.1033204],
                    [112.4544137, 9.1040252],
                    [112.4531841, 9.1047216],
                    [112.4519496, 9.1054094],
                    [112.4507102, 9.1060886],
                    [112.4494661, 9.1067593],
                    [112.4482172, 9.1074213],
                    [112.4469636, 9.1080747],
                    [112.4457055, 9.1087193],
                    [112.4444427, 9.1093553],
                    [112.4431755, 9.1099824],
                    [112.4419039, 9.1106008],
                    [112.4406279, 9.1112104],
                    [112.4393476, 9.1118111],
                    [112.4380631, 9.1124029],
                    [112.4367744, 9.1129858],
                    [112.4354816, 9.1135598],
                    [112.4341848, 9.1141248],
                    [112.432884, 9.1146808],
                    [112.4315792, 9.1152278],
                    [112.4302706, 9.1157658],
                    [112.4289583, 9.1162946],
                    [112.4276422, 9.1168144],
                    [112.4263224, 9.1173251],
                    [112.424999, 9.1178266],
                    [112.4236722, 9.1183189],
                    [112.4223418, 9.118802],
                    [112.4210081, 9.1192759],
                    [112.419671, 9.1197406],
                    [112.4183306, 9.1201959],
                    [112.4169871, 9.120642],
                    [112.4156404, 9.1210788],
                    [112.4142906, 9.1215062],
                    [112.4129379, 9.1219243],
                    [112.4115822, 9.122333],
                    [112.4102236, 9.1227324],
                    [112.4088623, 9.1231222],
                    [112.4074982, 9.1235027],
                    [112.4061314, 9.1238737],
                    [112.404762, 9.1242352],
                    [112.4033901, 9.1245873],
                    [112.4020157, 9.1249298],
                    [112.400639, 9.1252628],
                    [112.3992599, 9.1255863],
                    [112.3978785, 9.1259002],
                    [112.3964949, 9.1262045],
                    [112.3951093, 9.1264993],
                    [112.3937215, 9.1267844],
                    [112.3923318, 9.12706],
                    [112.3909401, 9.1273259],
                    [112.3895466, 9.1275821],
                    [112.3881513, 9.1278288],
                    [112.3867543, 9.1280657],
                    [112.3853556, 9.128293],
                    [112.3839554, 9.1285105],
                    [112.3825536, 9.1287184],
                    [112.3811504, 9.1289166],
                    [112.3797458, 9.129105],
                    [112.3783399, 9.1292837],
                    [112.3769328, 9.1294527],
                    [112.3755246, 9.1296119],
                    [112.3741152, 9.1297614],
                    [112.3727048, 9.1299011],
                    [112.3712934, 9.1300311],
                    [112.3698812, 9.1301512],
                    [112.3684681, 9.1302616],
                    [112.3670543, 9.1303622],
                    [112.3656398, 9.130453],
                    [112.3642247, 9.1305341],
                    [112.3628091, 9.1306053],
                    [112.3613929, 9.1306667],
                    [112.3599764, 9.1307183],
                    [112.3585596, 9.1307601],
                    [112.3571425, 9.1307921],
                    [112.3557252, 9.1308143],
                    [112.3543077, 9.1308266],
                    [112.3528903, 9.1308292],
                    [112.3514728, 9.1308219],
                    [112.3500554, 9.1308048],
                    [112.3486382, 9.1307779],
                    [112.3472212, 9.1307412],
                    [112.3458045, 9.1306947],
                    [112.3443882, 9.1306384],
                    [112.3429723, 9.1305723],
                    [112.3415569, 9.1304963],
                    [112.3401421, 9.1304106],
                    [112.3387279, 9.1303151],
                    [112.3373144, 9.1302098],
                    [112.3359017, 9.1300947],
                    [112.3344899, 9.1299698],
                    [112.333079, 9.1298352],
                    [112.3316691, 9.1296908],
                    [112.3302602, 9.1295366],
                    [112.3288525, 9.1293727],
                    [112.327446, 9.129199],
                    [112.3260407, 9.1290157],
                    [112.3246368, 9.1288225],
                    [112.3232343, 9.1286197],
                    [112.3218332, 9.1284072],
                    [112.3204338, 9.1281849],
                    [112.3190359, 9.127953],
                    [112.3176397, 9.1277114],
                    [112.3162452, 9.1274602],
                    [112.3148526, 9.1271993],
                    [112.3134618, 9.1269287],
                    [112.312073, 9.1266486],
                    [112.3106863, 9.1263588],
                    [112.3093016, 9.1260595],
                    [112.3079191, 9.1257505],
                    [112.3065388, 9.125432],
                    [112.3051608, 9.125104],
                    [112.3037852, 9.1247664],
                    [112.302412, 9.1244193],
                    [112.3010413, 9.1240627],
                    [112.2996732, 9.1236966],
                    [112.2983077, 9.123321],
                    [112.2969449, 9.122936],
                    [112.2955849, 9.1225416],
                    [112.2942277, 9.1221378],
                    [112.2928734, 9.1217246],
                    [112.291522, 9.121302],
                    [112.2901738, 9.12087],
                    [112.2888286, 9.1204288],
                    [112.2874866, 9.1199782],
                    [112.2861478, 9.1195184],
                    [112.2848123, 9.1190493],
                    [112.2834802, 9.118571],
                    [112.2821515, 9.1180834],
                    [112.2808263, 9.1175867],
                    [112.2795046, 9.1170808],
                    [112.2781866, 9.1165657],
                    [112.2768723, 9.1160416],
                    [112.2755617, 9.1155083],
                    [112.274255, 9.114966],
                    [112.2729521, 9.1144147],
                    [112.2716532, 9.1138544],
                    [112.2703583, 9.113285],
                    [112.2690675, 9.1127068],
                    [112.2677808, 9.1121196],
                    [112.2664983, 9.1115235],
                    [112.26522, 9.1109185],
                    [112.2639462, 9.1103047],
                    [112.2626766, 9.1096821],
                    [112.2614116, 9.1090507],
                    [112.2601511, 9.1084106],
                    [112.2588951, 9.1077617],
                    [112.2576438, 9.1071042],
                    [112.2563972, 9.106438],
                    [112.2551553, 9.1057632],
                    [112.2539183, 9.1050799],
                    [112.2526861, 9.1043879],
                    [112.2514589, 9.1036875],
                    [112.2502367, 9.1029786],
                    [112.2490196, 9.1022612],
                    [112.2478075, 9.1015354],
                    [112.2466007, 9.1008013],
                    [112.2453991, 9.1000588],
                    [112.2442028, 9.099308],
                    [112.2430119, 9.0985489],
                    [112.2418264, 9.0977816],
                    [112.2406464, 9.0970062],
                    [112.2394719, 9.0962225],
                    [112.238303, 9.0954308],
                    [112.2371398, 9.0946309],
                    [112.2359822, 9.0938231],
                    [112.2348305, 9.0930072],
                    [112.2336845, 9.0921834],
                    [112.2325444, 9.0913517],
                    [112.2314103, 9.0905121],
                    [112.2302822, 9.0896646],
                    [112.2291601, 9.0888094],
                    [112.2280441, 9.0879464],
                    [112.2269342, 9.0870757],
                    [112.2258306, 9.0861973],
                    [112.2247332, 9.0853113],
                    [112.2236422, 9.0844178],
                    [112.2225575, 9.0835167],
                    [112.2214793, 9.0826081],
                    [112.2204075, 9.081692],
                    [112.2193423, 9.0807686],
                    [112.2182836, 9.0798378],
                    [112.2172316, 9.0788997],
                    [112.2161863, 9.0779543],
                    [112.2151477, 9.0770017],
                    [112.2141159, 9.076042],
                    [112.2130909, 9.0750751],
                    [112.2124558, 9.0744675],
                    [112.2121863, 9.0744441],
                    [112.2107754, 9.0743123],
                    [112.2093656, 9.0741707],
                    [112.2079567, 9.0740193],
                    [112.206549, 9.0738582],
                    [112.2051425, 9.0736873],
                    [112.2037372, 9.0735067],
                    [112.2023332, 9.0733164],
                    [112.2009306, 9.0731163],
                    [112.1995295, 9.0729066],
                    [112.1981299, 9.0726871],
                    [112.1967319, 9.072458],
                    [112.1953355, 9.0722191],
                    [112.1939409, 9.0719707],
                    [112.1925481, 9.0717125],
                    [112.1911571, 9.0714448],
                    [112.1897681, 9.0711674],
                    [112.1883811, 9.0708804],
                    [112.1869962, 9.0705837],
                    [112.1856134, 9.0702776],
                    [112.1842328, 9.0699618],
                    [112.1828544, 9.0696365],
                    [112.1814785, 9.0693016],
                    [112.1801049, 9.0689573],
                    [112.1787338, 9.0686034],
                    [112.1773653, 9.06824],
                    [112.1759993, 9.0678672],
                    [112.1746361, 9.0674849],
                    [112.1732756, 9.0670932],
                    [112.1719179, 9.0666921],
                    [112.1705631, 9.0662816],
                    [112.1692113, 9.0658617],
                    [112.1678625, 9.0654325],
                    [112.1665167, 9.0649939],
                    [112.1651741, 9.064546],
                    [112.1638347, 9.0640889],
                    [112.1624986, 9.0636224],
                    [112.1611658, 9.0631468],
                    [112.1598365, 9.0626619],
                    [112.1585106, 9.0621678],
                    [112.1571882, 9.0616646],
                    [112.1558695, 9.0611522],
                    [112.1545544, 9.0606306],
                    [112.1532431, 9.0601],
                    [112.1519356, 9.0595604],
                    [112.1506319, 9.0590116],
                    [112.1493322, 9.0584539],
                    [112.1480364, 9.0578872],
                    [112.1467448, 9.0573115],
                    [112.1454572, 9.0567269],
                    [112.1441738, 9.0561334],
                    [112.1428947, 9.055531],
                    [112.1416198, 9.0549197],
                    [112.1403494, 9.0542997],
                    [112.1390834, 9.0536708],
                    [112.1378218, 9.0530332],
                    [112.1365649, 9.0523869],
                    [112.1353125, 9.0517319],
                    [112.1340649, 9.0510683],
                    [112.1328219, 9.050396],
                    [112.1315838, 9.0497151],
                    [112.1303506, 9.0490257],
                    [112.1291222, 9.0483277],
                    [112.1278989, 9.0476213],
                    [112.1266806, 9.0469064],
                    [112.1254674, 9.046183],
                    [112.1242594, 9.0454513],
                    [112.1230566, 9.0447113],
                    [112.1218591, 9.0439629],
                    [112.1206669, 9.0432063],
                    [112.1194801, 9.0424414],
                    [112.1182988, 9.0416683],
                    [112.117123, 9.040887],
                    [112.1159528, 9.0400976],
                    [112.1147883, 9.0393002],
                    [112.1136294, 9.0384947],
                    [112.1124762, 9.0376811],
                    [112.1113289, 9.0368597],
                    [112.1101874, 9.0360302],
                    [112.1090519, 9.0351929],
                    [112.1079223, 9.0343478],
                    [112.1067987, 9.0334948],
                    [112.1056813, 9.0326341],
                    [112.1045699, 9.0317657],
                    [112.1034648, 9.0308896],
                    [112.1023659, 9.0300058],
                    [112.1012733, 9.0291145],
                    [112.1001871, 9.0282156],
                    [112.0991073, 9.0273092],
                    [112.0980339, 9.0263954],
                    [112.0969671, 9.0254741],
                    [112.0959068, 9.0245455],
                    [112.0948531, 9.0236095],
                    [112.0938062, 9.0226663],
                    [112.0927659, 9.0217159],
                    [112.0917324, 9.0207582],
                    [112.0907057, 9.0197934],
                    [112.0896859, 9.0188216],
                    [112.0886731, 9.0178427],
                    [112.0876672, 9.0168568],
                    [112.0866683, 9.0158639],
                    [112.0856765, 9.0148642],
                    [112.0846919, 9.0138576],
                    [112.0837144, 9.0128442],
                    [112.0827441, 9.0118241],
                    [112.0817811, 9.0107972],
                    [112.0808254, 9.0097638],
                    [112.0798771, 9.0087237],
                    [112.0794243, 9.0082201],
                    [112.0787915, 9.0077793],
                    [112.0776395, 9.006965],
                    [112.0764933, 9.0061427],
                    [112.0753529, 9.0053124],
                    [112.0742185, 9.0044743],
                    [112.0730901, 9.0036284],
                    [112.0719677, 9.0027746],
                    [112.0708514, 9.0019131],
                    [112.0697412, 9.0010439],
                    [112.0686372, 9.000167],
                    [112.0675395, 8.9992825],
                    [112.0664481, 8.9983904],
                    [112.0653631, 8.9974908],
                    [112.0642844, 8.9965836],
                    [112.0632123, 8.9956691],
                    [112.0621466, 8.9947471],
                    [112.0610876, 8.9938177],
                    [112.0600351, 8.9928811],
                    [112.0589893, 8.9919371],
                    [112.0579503, 8.990986],
                    [112.056918, 8.9900277],
                    [112.0558926, 8.9890622],
                    [112.054874, 8.9880897],
                    [112.0538624, 8.9871101],
                    [112.0528577, 8.9861235],
                    [112.0518601, 8.98513],
                    [112.0508696, 8.9841296],
                    [112.0498861, 8.9831224],
                    [112.0489099, 8.9821084],
                    [112.0479409, 8.9810877],
                    [112.0469791, 8.9800602],
                    [112.0460247, 8.9790261],
                    [112.0450776, 8.9779855],
                    [112.044138, 8.9769383],
                    [112.0432058, 8.9758846],
                    [112.0422811, 8.9748244],
                    [112.0413639, 8.9737579],
                    [112.0404544, 8.9726851],
                    [112.0395525, 8.971606],
                    [112.0386582, 8.9705206],
                    [112.0377717, 8.9694291],
                    [112.036893, 8.9683315],
                    [112.0360221, 8.9672278],
                    [112.035159, 8.9661181],
                    [112.0343039, 8.9650025],
                    [112.0334566, 8.9638809],
                    [112.0326174, 8.9627535],
                    [112.0317862, 8.9616203],
                    [112.030963, 8.9604814],
                    [112.030148, 8.9593368],
                    [112.0293411, 8.9581866],
                    [112.0285424, 8.9570308],
                    [112.0277519, 8.9558695],
                    [112.0269697, 8.9547028],
                    [112.0261958, 8.9535306],
                    [112.0254302, 8.9523532],
                    [112.024673, 8.9511704],
                    [112.0239242, 8.9499825],
                    [112.0231838, 8.9487893],
                    [112.022452, 8.9475911],
                    [112.0217287, 8.9463878],
                    [112.0210139, 8.9451796],
                    [112.0203077, 8.9439664],
                    [112.0196102, 8.9427483],
                    [112.0189213, 8.9415255],
                    [112.0182411, 8.9402979],
                    [112.0175697, 8.9390656],
                    [112.016907, 8.9378286],
                    [112.0162531, 8.9365872],
                    [112.015608, 8.9353412],
                    [112.0149718, 8.9340908],
                    [112.0143445, 8.9328359],
                    [112.0137262, 8.9315768],
                    [112.0131167, 8.9303134],
                    [112.0125163, 8.9290459],
                    [112.0119248, 8.9277741],
                    [112.0113424, 8.9264984],
                    [112.0107691, 8.9252186],
                    [112.0102049, 8.9239348],
                    [112.0096498, 8.9226472],
                    [112.0091038, 8.9213558],
                    [112.0085671, 8.9200606],
                    [112.0080395, 8.9187617],
                    [112.0075212, 8.9174592],
                    [112.0070121, 8.9161531],
                    [112.0065123, 8.9148436],
                    [112.0060219, 8.9135306],
                    [112.0055407, 8.9122142],
                    [112.0050689, 8.9108945],
                    [112.0046065, 8.9095716],
                    [112.0041535, 8.9082455],
                    [112.0037099, 8.9069163],
                    [112.0032758, 8.905584],
                    [112.0028511, 8.9042488],
                    [112.0024359, 8.9029106],
                    [112.0020303, 8.9015696],
                    [112.0016341, 8.9002259],
                    [112.0012475, 8.8988794],
                    [112.0008705, 8.8975302],
                    [112.000503, 8.8961785],
                    [112.0001452, 8.8948242],
                    [111.999797, 8.8934675],
                    [111.9994584, 8.8921085],
                    [111.9991295, 8.8907471],
                    [111.9988102, 8.8893834],
                    [111.9985006, 8.8880176],
                    [111.9982008, 8.8866496],
                    [111.9979106, 8.8852796],
                    [111.9976302, 8.8839077],
                    [111.9973595, 8.8825338],
                    [111.9970986, 8.881158],
                    [111.9968474, 8.8797805],
                    [111.9966061, 8.8784013],
                    [111.9963745, 8.8770204],
                    [111.9961527, 8.8756379],
                    [111.9959408, 8.874254],
                    [111.9957387, 8.8728686],
                    [111.9955464, 8.8714818],
                    [111.9953639, 8.8700937],
                    [111.9951914, 8.8687044],
                    [111.9950286, 8.867314],
                    [111.9948758, 8.8659224],
                    [111.9947328, 8.8645298],
                    [111.9945998, 8.8631362],
                    [111.9944766, 8.8617418],
                    [111.9943633, 8.8603465],
                    [111.9942599, 8.8589504],
                    [111.9941665, 8.8575537],
                    [111.9940829, 8.8561564],
                    [111.9940093, 8.8547585],
                    [111.9939456, 8.8533601],
                    [111.9938918, 8.8519613],
                    [111.9938479, 8.8505622],
                    [111.993814, 8.8491628],
                    [111.99379, 8.8477631],
                    [111.993776, 8.8463634],
                    [111.9937718, 8.8449636],
                    [111.9937776, 8.8435638],
                    [111.9937934, 8.842164],
                    [111.9938191, 8.8407644],
                    [111.9938547, 8.839365],
                    [111.9939002, 8.8379659],
                    [111.9939557, 8.8365671],
                    [111.9940211, 8.8351687],
                    [111.9940964, 8.8337708],
                    [111.9941816, 8.8323735],
                    [111.9942768, 8.8309768],
                    [111.9943162, 8.8304526],
                    [111.9936935, 8.8306894],
                    [111.9923683, 8.8311827],
                    [111.9910397, 8.8316667],
                    [111.9897077, 8.8321416],
                    [111.9883723, 8.8326072],
                    [111.9870337, 8.8330636],
                    [111.9856918, 8.8335107],
                    [111.9843469, 8.8339484],
                    [111.9829988, 8.8343769],
                    [111.9816478, 8.834796],
                    [111.9802938, 8.8352057],
                    [111.9789369, 8.835606],
                    [111.9775772, 8.8359969],
                    [111.9762148, 8.8363784],
                    [111.9748497, 8.8367504],
                    [111.973482, 8.8371129],
                    [111.9721118, 8.837466],
                    [111.9707391, 8.8378096],
                    [111.9693639, 8.8381436],
                    [111.9679865, 8.8384681],
                    [111.9666068, 8.8387831],
                    [111.9652249, 8.8390884],
                    [111.9638408, 8.8393843],
                    [111.9624547, 8.8396705],
                    [111.9610666, 8.839947],
                    [111.9596765, 8.840214],
                    [111.9582846, 8.8404713],
                    [111.9568909, 8.840719],
                    [111.9554955, 8.840957],
                    [111.9540984, 8.8411854],
                    [111.9526997, 8.841404],
                    [111.9512996, 8.841613],
                    [111.9498979, 8.8418122],
                    [111.9484949, 8.8420018],
                    [111.9470906, 8.8421816],
                    [111.9456851, 8.8423516],
                    [111.9442783, 8.842512],
                    [111.9428705, 8.8426625],
                    [111.9414616, 8.8428034],
                    [111.9400518, 8.8429344],
                    [111.938641, 8.8430557],
                    [111.9372295, 8.8431672],
                    [111.9358172, 8.8432689],
                    [111.9344042, 8.8433608],
                    [111.9329906, 8.843443],
                    [111.9315764, 8.8435153],
                    [111.9301618, 8.8435778],
                    [111.9287467, 8.8436306],
                    [111.9273313, 8.8436735],
                    [111.9259156, 8.8437066],
                    [111.9244998, 8.8437299],
                    [111.9230838, 8.8437434],
                    [111.9216677, 8.8437471],
                    [111.9202517, 8.843741],
                    [111.9188357, 8.843725],
                    [111.9174199, 8.8436993],
                    [111.9160043, 8.8436637],
                    [111.914589, 8.8436184],
                    [111.913174, 8.8435632],
                    [111.9117595, 8.8434982],
                    [111.9103455, 8.8434235],
                    [111.908932, 8.8433389],
                    [111.9075192, 8.8432445],
                    [111.906107, 8.8431404],
                    [111.9046957, 8.8430264],
                    [111.9032852, 8.8429027],
                    [111.9018756, 8.8427692],
                    [111.9004669, 8.842626],
                    [111.8990594, 8.842473],
                    [111.8976529, 8.8423103],
                    [111.8962477, 8.8421378],
                    [111.8948437, 8.8419555],
                    [111.893441, 8.8417636],
                    [111.8920397, 8.8415619],
                    [111.8906399, 8.8413506],
                    [111.8892416, 8.8411295],
                    [111.887845, 8.8408988],
                    [111.88645, 8.8406583],
                    [111.8850567, 8.8404083],
                    [111.8836653, 8.8401485],
                    [111.8822757, 8.8398792],
                    [111.8808881, 8.8396002],
                    [111.8795024, 8.8393116],
                    [111.8781189, 8.8390134],
                    [111.8767375, 8.8387056],
                    [111.8753584, 8.8383883],
                    [111.8739815, 8.8380614],
                    [111.872607, 8.837725],
                    [111.8712349, 8.8373791],
                    [111.8698653, 8.8370237],
                    [111.8684982, 8.8366588],
                    [111.8671338, 8.8362844],
                    [111.865772, 8.8359006],
                    [111.864413, 8.8355073],
                    [111.8630569, 8.8351047],
                    [111.8617036, 8.8346926],
                    [111.8603533, 8.8342712],
                    [111.859006, 8.8338405],
                    [111.8576618, 8.8334004],
                    [111.8563207, 8.832951],
                    [111.8549829, 8.8324923],
                    [111.8536484, 8.8320244],
                    [111.8523172, 8.8315472],
                    [111.8509894, 8.8310609],
                    [111.8496652, 8.8305653],
                    [111.8483444, 8.8300606],
                    [111.8470273, 8.8295467],
                    [111.8457139, 8.8290237],
                    [111.8444042, 8.8284916],
                    [111.8430983, 8.8279505],
                    [111.8417963, 8.8274003],
                    [111.8404982, 8.8268411],
                    [111.8392042, 8.826273],
                    [111.8379142, 8.8256959],
                    [111.8366283, 8.8251098],
                    [111.8353466, 8.8245149],
                    [111.8340691, 8.8239111],
                    [111.832796, 8.8232984],
                    [111.8315273, 8.822677],
                    [111.830263, 8.8220467],
                    [111.8290032, 8.8214077],
                    [111.8277479, 8.82076],
                    [111.8264973, 8.8201037],
                    [111.8252514, 8.8194386],
                    [111.8240102, 8.818765],
                    [111.8227739, 8.8180828],
                    [111.8215424, 8.817392],
                    [111.8203158, 8.8166927],
                    [111.8190943, 8.8159849],
                    [111.8178778, 8.8152686],
                    [111.8166664, 8.814544],
                    [111.8154602, 8.813811],
                    [111.8142592, 8.8130696],
                    [111.8141363, 8.8129926],
                    [111.8132009, 8.8138835],
                    [111.8121747, 8.8148472],
                    [111.8111417, 8.8158039],
                    [111.810102, 8.8167533],
                    [111.8090555, 8.8176955],
                    [111.8080024, 8.8186304],
                    [111.8069427, 8.819558],
                    [111.8058764, 8.8204782],
                    [111.8048036, 8.821391],
                    [111.8037244, 8.8222964],
                    [111.8026387, 8.8231942],
                    [111.8015467, 8.8240845],
                    [111.8004485, 8.8249673],
                    [111.7993439, 8.8258423],
                    [111.7982332, 8.8267097],
                    [111.7971164, 8.8275694],
                    [111.7959935, 8.8284213],
                    [111.7948646, 8.8292655],
                    [111.7937297, 8.8301017],
                    [111.7925889, 8.8309301],
                    [111.7914423, 8.8317506],
                    [111.7902899, 8.8325631],
                    [111.7891317, 8.8333675],
                    [111.7879679, 8.834164],
                    [111.7867984, 8.8349523],
                    [111.7856234, 8.8357326],
                    [111.7844428, 8.8365046],
                    [111.7832568, 8.8372685],
                    [111.7820654, 8.8380241],
                    [111.7808687, 8.8387715],
                    [111.7796667, 8.8395105],
                    [111.7784595, 8.8402412],
                    [111.7772471, 8.8409635],
                    [111.7760297, 8.8416774],
                    [111.7748072, 8.8423828],
                    [111.7735797, 8.8430797],
                    [111.7723473, 8.8437682],
                    [111.7711101, 8.844448],
                    [111.769868, 8.8451193],
                    [111.7686213, 8.8457819],
                    [111.7673698, 8.8464359],
                    [111.7661138, 8.8470812],
                    [111.7648531, 8.8477178],
                    [111.7635881, 8.8483456],
                    [111.7623185, 8.8489647],
                    [111.7610447, 8.8495749],
                    [111.7597665, 8.8501763],
                    [111.7584841, 8.8507688],
                    [111.7571975, 8.8513524],
                    [111.7559068, 8.8519271],
                    [111.754612, 8.8524928],
                    [111.7533133, 8.8530496],
                    [111.7520107, 8.8535973],
                    [111.7507042, 8.854136],
                    [111.7493939, 8.8546656],
                    [111.7480799, 8.8551862],
                    [111.7467622, 8.8556976],
                    [111.7454409, 8.8561998],
                    [111.7441161, 8.8566929],
                    [111.7427878, 8.8571768],
                    [111.7414561, 8.8576515],
                    [111.7401211, 8.858117],
                    [111.7387828, 8.8585732],
                    [111.7374413, 8.8590201],
                    [111.7360967, 8.8594577],
                    [111.734749, 8.859886],
                    [111.7333983, 8.8603049],
                    [111.7320446, 8.8607144],
                    [111.7306881, 8.8611146],
                    [111.7293288, 8.8615054],
                    [111.7279667, 8.8618867],
                    [111.726602, 8.8622586],
                    [111.7252346, 8.862621],
                    [111.7238648, 8.8629739],
                    [111.7224924, 8.8633173],
                    [111.7211176, 8.8636513],
                    [111.7197405, 8.8639756],
                    [111.7183612, 8.8642905],
                    [111.7169796, 8.8645957],
                    [111.7155959, 8.8648914],
                    [111.7142102, 8.8651775],
                    [111.7128224, 8.865454],
                    [111.7114327, 8.8657208],
                    [111.7100412, 8.8659781],
                    [111.7086478, 8.8662256],
                    [111.7072528, 8.8664636],
                    [111.7058561, 8.8666918],
                    [111.7044578, 8.8669104],
                    [111.7030579, 8.8671192],
                    [111.7016567, 8.8673184],
                    [111.700254, 8.8675078],
                    [111.6988501, 8.8676876],
                    [111.6974449, 8.8678576],
                    [111.6960385, 8.8680178],
                    [111.694631, 8.8681683],
                    [111.6932225, 8.8683091],
                    [111.6918131, 8.8684401],
                    [111.6904027, 8.8685613],
                    [111.6889915, 8.8686727],
                    [111.6875796, 8.8687744],
                    [111.6861669, 8.8688663],
                    [111.6847537, 8.8689483],
                    [111.6833399, 8.8690206],
                    [111.6819256, 8.8690831],
                    [111.6805109, 8.8691358],
                    [111.6790959, 8.8691787],
                    [111.6776806, 8.8692118],
                    [111.6762651, 8.8692351],
                    [111.6748495, 8.8692486],
                    [111.6734338, 8.8692522],
                    [111.6720182, 8.8692461],
                    [111.6706026, 8.8692301],
                    [111.6691871, 8.8692044],
                    [111.6677719, 8.8691688],
                    [111.6663569, 8.8691234],
                    [111.6649423, 8.8690683],
                    [111.6635282, 8.8690033],
                    [111.6621145, 8.8689285],
                    [111.6607014, 8.868844],
                    [111.659289, 8.8687496],
                    [111.6578772, 8.8686455],
                    [111.6564662, 8.8685316],
                    [111.6550561, 8.8684079],
                    [111.6536468, 8.8682744],
                    [111.6522386, 8.8681312],
                    [111.6508314, 8.8679782],
                    [111.6494253, 8.8678155],
                    [111.6480204, 8.8676431],
                    [111.6466168, 8.8674609],
                    [111.6452145, 8.867269],
                    [111.6438136, 8.8670674],
                    [111.6424142, 8.866856],
                    [111.6410162, 8.866635],
                    [111.6396199, 8.8664043],
                    [111.6382253, 8.866164],
                    [111.6368324, 8.865914],
                    [111.6354413, 8.8656543],
                    [111.6340521, 8.865385],
                    [111.6326649, 8.8651061],
                    [111.6312796, 8.8648176],
                    [111.6298965, 8.8645195],
                    [111.6285154, 8.8642118],
                    [111.6271366, 8.8638946],
                    [111.6257601, 8.8635678],
                    [111.624386, 8.8632314],
                    [111.6230142, 8.8628856],
                    [111.621645, 8.8625303],
                    [111.6202783, 8.8621655],
                    [111.6189142, 8.8617912],
                    [111.6175528, 8.8614075],
                    [111.6161942, 8.8610144],
                    [111.6148384, 8.8606118],
                    [111.6134855, 8.8601999],
                    [111.6121355, 8.8597786],
                    [111.6107886, 8.859348],
                    [111.6094448, 8.858908],
                    [111.6081041, 8.8584588],
                    [111.6067666, 8.8580002],
                    [111.6054324, 8.8575324],
                    [111.6041016, 8.8570554],
                    [111.6027742, 8.8565692],
                    [111.6014503, 8.8560738],
                    [111.6001299, 8.8555692],
                    [111.5988132, 8.8550555],
                    [111.5975001, 8.8545326],
                    [111.5961907, 8.8540007],
                    [111.5948852, 8.8534597],
                    [111.5935835, 8.8529097],
                    [111.5922858, 8.8523507],
                    [111.5909921, 8.8517827],
                    [111.5897024, 8.8512058],
                    [111.5884169, 8.8506199],
                    [111.5871355, 8.8500251],
                    [111.5858584, 8.8494215],
                    [111.5845856, 8.848809],
                    [111.5833172, 8.8481878],
                    [111.5820533, 8.8475577],
                    [111.5807938, 8.8469189],
                    [111.5795389, 8.8462714],
                    [111.5782887, 8.8456153],
                    [111.5770431, 8.8449504],
                    [111.5758022, 8.844277],
                    [111.5745662, 8.843595],
                    [111.5733351, 8.8429044],
                    [111.5721088, 8.8422053],
                    [111.5708876, 8.8414977],
                    [111.5696714, 8.8407817],
                    [111.5684604, 8.8400573],
                    [111.5672545, 8.8393245],
                    [111.5660538, 8.8385834],
                    [111.5648584, 8.8378339],
                    [111.5636684, 8.8370762],
                    [111.5624838, 8.8363103],
                    [111.5613046, 8.8355361],
                    [111.560131, 8.8347539],
                    [111.5589629, 8.8339635],
                    [111.5578005, 8.833165],
                    [111.5566438, 8.8323585],
                    [111.5554928, 8.831544],
                    [111.5543476, 8.8307215],
                    [111.5532083, 8.8298911],
                    [111.552075, 8.8290529],
                    [111.5509476, 8.8282068],
                    [111.5498262, 8.8273529],
                    [111.5487109, 8.8264912],
                    [111.5476018, 8.8256219],
                    [111.5464988, 8.8247449],
                    [111.5454021, 8.8238602],
                    [111.5443118, 8.822968],
                    [111.5432277, 8.8220683],
                    [111.5421501, 8.821161],
                    [111.541079, 8.8202463],
                    [111.5400143, 8.8193242],
                    [111.5389563, 8.8183948],
                    [111.5379048, 8.817458],
                    [111.53686, 8.816514],
                    [111.535822, 8.8155628],
                    [111.5347907, 8.8146043],
                    [111.5337663, 8.8136388],
                    [111.5327487, 8.8126662],
                    [111.5317381, 8.8116865],
                    [111.5307344, 8.8106999],
                    [111.5297378, 8.8097063],
                    [111.5287482, 8.8087059],
                    [111.5277658, 8.8076986],
                    [111.5267905, 8.8066845],
                    [111.5258225, 8.8056637],
                    [111.5248617, 8.8046362],
                    [111.5239082, 8.8036021],
                    [111.5229621, 8.8025613],
                    [111.5220234, 8.8015141],
                    [111.5210922, 8.8004604],
                    [111.5201685, 8.7994002],
                    [111.5192523, 8.7983337],
                    [111.5183437, 8.7972608],
                    [111.5174427, 8.7961817],
                    [111.5165494, 8.7950963],
                    [111.5156638, 8.7940048],
                    [111.514786, 8.7929071],
                    [111.5139161, 8.7918034],
                    [111.5130539, 8.7906937],
                    [111.5121997, 8.7895781],
                    [111.5113534, 8.7884565],
                    [111.5105151, 8.7873291],
                    [111.5096848, 8.7861959],
                    [111.5088625, 8.785057],
                    [111.5080484, 8.7839124],
                    [111.5072424, 8.7827622],
                    [111.5064446, 8.7816065],
                    [111.505655, 8.7804452],
                    [111.5048736, 8.7792785],
                    [111.5041006, 8.7781064],
                    [111.5033359, 8.776929],
                    [111.5025796, 8.7757463],
                    [111.5018316, 8.7745583],
                    [111.5010922, 8.7733653],
                    [111.5003612, 8.7721671],
                    [111.4996387, 8.7709639],
                    [111.4989248, 8.7697557],
                    [111.4982195, 8.7685425],
                    [111.4975228, 8.7673245],
                    [111.4968347, 8.7661017],
                    [111.4961554, 8.7648742],
                    [111.4954848, 8.763642],
                    [111.4948229, 8.7624052],
                    [111.4941698, 8.7611638],
                    [111.4935256, 8.7599179],
                    [111.4928902, 8.7586675],
                    [111.4922637, 8.7574128],
                    [111.4916461, 8.7561538],
                    [111.4910375, 8.7548905],
                    [111.4904378, 8.753623],
                    [111.4898472, 8.7523514],
                    [111.4892656, 8.7510758],
                    [111.488693, 8.7497961],
                    [111.4881296, 8.7485125],
                    [111.4875752, 8.747225],
                    [111.48703, 8.7459337],
                    [111.486494, 8.7446386],
                    [111.4859672, 8.7433399],
                    [111.4854496, 8.7420375],
                    [111.4849413, 8.7407316],
                    [111.4844423, 8.7394221],
                    [111.4839525, 8.7381093],
                    [111.4834721, 8.7367931],
                    [111.483001, 8.7354735],
                    [111.4825393, 8.7341508],
                    [111.482087, 8.7328248],
                    [111.4816441, 8.7314958],
                    [111.4812107, 8.7301637],
                    [111.4807867, 8.7288286],
                    [111.4803722, 8.7274907],
                    [111.4799672, 8.7261498],
                    [111.4795717, 8.7248062],
                    [111.4791858, 8.7234599],
                    [111.4788094, 8.722111],
                    [111.4784426, 8.7207594],
                    [111.4780854, 8.7194054],
                    [111.4777379, 8.7180489],
                    [111.4773999, 8.71669],
                    [111.4770716, 8.7153288],
                    [111.476753, 8.7139654],
                    [111.476444, 8.7125998],
                    [111.4761448, 8.711232],
                    [111.4758552, 8.7098623],
                    [111.4755754, 8.7084905],
                    [111.4753053, 8.7071168],
                    [111.475045, 8.7057413],
                    [111.4747944, 8.704364],
                    [111.4745536, 8.702985],
                    [111.4743226, 8.7016044],
                    [111.4741014, 8.7002222],
                    [111.47389, 8.6988385],
                    [111.4736884, 8.6974533],
                    [111.4734967, 8.6960668],
                    [111.4733148, 8.694679],
                    [111.4731427, 8.69329],
                    [111.4729806, 8.6918997],
                    [111.4728282, 8.6905084],
                    [111.4726858, 8.6891161],
                    [111.4725532, 8.6877228],
                    [111.4724305, 8.6863286],
                    [111.4723177, 8.6849336],
                    [111.4722148, 8.6835379],
                    [111.4721218, 8.6821414],
                    [111.4720388, 8.6807444],
                    [111.4719656, 8.6793468],
                    [111.4719023, 8.6779487],
                    [111.471849, 8.6765502],
                    [111.4718056, 8.6751514],
                    [111.4717721, 8.6737523],
                    [111.4717485, 8.6723529],
                    [111.4717349, 8.6709535],
                    [111.4717312, 8.669554],
                ],
            ],
            [
                [
                    [112.7197411, 7.891562],
                    [112.7197468, 7.8901628],
                    [112.7197623, 7.8887637],
                    [112.7197878, 7.8873647],
                    [112.7198232, 7.8859659],
                    [112.7198685, 7.8845674],
                    [112.7199236, 7.8831693],
                    [112.7199887, 7.8817716],
                    [112.7200637, 7.8803743],
                    [112.7201485, 7.8789776],
                    [112.7202433, 7.8775815],
                    [112.7203479, 7.8761861],
                    [112.7204624, 7.8747915],
                    [112.7205867, 7.8733977],
                    [112.720721, 7.8720047],
                    [112.7208651, 7.8706128],
                    [112.721019, 7.8692219],
                    [112.7211828, 7.867832],
                    [112.7213564, 7.8664434],
                    [112.7215398, 7.8650559],
                    [112.7217331, 7.8636698],
                    [112.7219362, 7.8622851],
                    [112.722149, 7.8609017],
                    [112.7223717, 7.8595199],
                    [112.7226041, 7.8581397],
                    [112.7228463, 7.8567611],
                    [112.7230982, 7.8553842],
                    [112.7233599, 7.8540092],
                    [112.7236313, 7.8526359],
                    [112.7239125, 7.8512646],
                    [112.7242033, 7.8498952],
                    [112.7245038, 7.8485279],
                    [112.724814, 7.8471627],
                    [112.7251338, 7.8457997],
                    [112.7254633, 7.844439],
                    [112.7258024, 7.8430806],
                    [112.7261511, 7.8417245],
                    [112.7265094, 7.8403709],
                    [112.7268773, 7.8390198],
                    [112.7272547, 7.8376714],
                    [112.7276417, 7.8363255],
                    [112.7280382, 7.8349824],
                    [112.7284442, 7.833642],
                    [112.7288596, 7.8323046],
                    [112.7292845, 7.83097],
                    [112.7297189, 7.8296384],
                    [112.7301627, 7.8283098],
                    [112.7306158, 7.8269844],
                    [112.7310784, 7.8256621],
                    [112.7315502, 7.8243431],
                    [112.7320314, 7.8230274],
                    [112.7325219, 7.821715],
                    [112.7330217, 7.8204061],
                    [112.7335308, 7.8191007],
                    [112.734049, 7.8177989],
                    [112.7345765, 7.8165007],
                    [112.7351131, 7.8152061],
                    [112.7356589, 7.8139154],
                    [112.7362138, 7.8126284],
                    [112.7367778, 7.8113454],
                    [112.7373509, 7.8100662],
                    [112.737933, 7.8087911],
                    [112.7385242, 7.8075201],
                    [112.7391243, 7.8062532],
                    [112.7397333, 7.8049905],
                    [112.7403513, 7.803732],
                    [112.7409782, 7.8024779],
                    [112.7416139, 7.8012282],
                    [112.7422585, 7.7999829],
                    [112.7429119, 7.7987421],
                    [112.743574, 7.7975058],
                    [112.7442449, 7.7962742],
                    [112.7449244, 7.7950473],
                    [112.7456127, 7.7938251],
                    [112.7463095, 7.7926078],
                    [112.747015, 7.7913953],
                    [112.747729, 7.7901877],
                    [112.7484516, 7.7889852],
                    [112.7491826, 7.7877876],
                    [112.7499222, 7.7865952],
                    [112.7506701, 7.7854079],
                    [112.7514264, 7.7842259],
                    [112.7521911, 7.7830491],
                    [112.752964, 7.7818777],
                    [112.7537453, 7.7807117],
                    [112.7545348, 7.7795511],
                    [112.7553325, 7.7783961],
                    [112.7561383, 7.7772466],
                    [112.7569522, 7.7761027],
                    [112.7577742, 7.7749645],
                    [112.7586043, 7.7738321],
                    [112.7594424, 7.7727054],
                    [112.7602884, 7.7715846],
                    [112.7611423, 7.7704697],
                    [112.7620041, 7.7693607],
                    [112.7628737, 7.7682578],
                    [112.7637511, 7.7671609],
                    [112.7646362, 7.7660702],
                    [112.7655291, 7.7649856],
                    [112.7664295, 7.7639072],
                    [112.7673376, 7.7628351],
                    [112.7682533, 7.7617694],
                    [112.7691765, 7.7607101],
                    [112.7701072, 7.7596571],
                    [112.7710452, 7.7586107],
                    [112.7719907, 7.7575708],
                    [112.7729435, 7.7565375],
                    [112.7739036, 7.7555109],
                    [112.774871, 7.7544909],
                    [112.7758455, 7.7534777],
                    [112.7768272, 7.7524713],
                    [112.777816, 7.7514717],
                    [112.7788118, 7.7504791],
                    [112.7798146, 7.7494933],
                    [112.7808244, 7.7485146],
                    [112.7818411, 7.7475429],
                    [112.7828646, 7.7465782],
                    [112.783895, 7.7456207],
                    [112.784932, 7.7446704],
                    [112.7859758, 7.7437273],
                    [112.7870263, 7.7427915],
                    [112.7880833, 7.741863],
                    [112.7891469, 7.7409419],
                    [112.790217, 7.7400282],
                    [112.7912935, 7.7391219],
                    [112.7923764, 7.7382232],
                    [112.7934656, 7.737332],
                    [112.7945611, 7.7364483],
                    [112.7956628, 7.7355724],
                    [112.7967707, 7.734704],
                    [112.7978848, 7.7338434],
                    [112.7990049, 7.7329906],
                    [112.800131, 7.7321456],
                    [112.801263, 7.7313084],
                    [112.802401, 7.7304791],
                    [112.8035448, 7.7296577],
                    [112.8046943, 7.7288443],
                    [112.8058496, 7.7280389],
                    [112.8070106, 7.7272415],
                    [112.8081772, 7.7264522],
                    [112.8093493, 7.7256711],
                    [112.810527, 7.7248981],
                    [112.8117101, 7.7241333],
                    [112.8128985, 7.7233768],
                    [112.8140923, 7.7226285],
                    [112.8152914, 7.7218885],
                    [112.8164956, 7.7211569],
                    [112.817705, 7.7204337],
                    [112.8189195, 7.7197189],
                    [112.8201391, 7.7190125],
                    [112.8213636, 7.7183147],
                    [112.822593, 7.7176253],
                    [112.8238272, 7.7169446],
                    [112.8250663, 7.7162724],
                    [112.82631, 7.7156088],
                    [112.8275585, 7.7149539],
                    [112.8288115, 7.7143077],
                    [112.8300691, 7.7136702],
                    [112.8313311, 7.7130415],
                    [112.8325976, 7.7124215],
                    [112.8338684, 7.7118104],
                    [112.8351436, 7.7112081],
                    [112.8364229, 7.7106147],
                    [112.8377064, 7.7100302],
                    [112.8389941, 7.7094546],
                    [112.8402857, 7.708888],
                    [112.8415814, 7.7083304],
                    [112.842881, 7.7077817],
                    [112.8441844, 7.7072422],
                    [112.8454916, 7.7067117],
                    [112.8468025, 7.7061903],
                    [112.8481171, 7.705678],
                    [112.8494352, 7.7051749],
                    [112.8507569, 7.7046809],
                    [112.8520821, 7.7041961],
                    [112.8534106, 7.7037206],
                    [112.8547425, 7.7032543],
                    [112.8560777, 7.7027973],
                    [112.8574161, 7.7023495],
                    [112.8587575, 7.7019111],
                    [112.8601021, 7.701482],
                    [112.8614497, 7.7010622],
                    [112.8628002, 7.7006519],
                    [112.8641536, 7.7002509],
                    [112.8655097, 7.6998593],
                    [112.8668687, 7.6994772],
                    [112.8682302, 7.6991045],
                    [112.8695944, 7.6987413],
                    [112.8709612, 7.6983876],
                    [112.8723304, 7.6980434],
                    [112.873702, 7.6977087],
                    [112.8750759, 7.6973835],
                    [112.8764521, 7.6970679],
                    [112.8778305, 7.6967619],
                    [112.879211, 7.6964655],
                    [112.8805936, 7.6961786],
                    [112.8819782, 7.6959014],
                    [112.8833647, 7.6956338],
                    [112.8847531, 7.6953759],
                    [112.8861433, 7.6951276],
                    [112.8875352, 7.6948889],
                    [112.8889287, 7.69466],
                    [112.8903239, 7.6944407],
                    [112.8917205, 7.6942312],
                    [112.8931186, 7.6940313],
                    [112.8945181, 7.6938412],
                    [112.8959189, 7.6936608],
                    [112.8973209, 7.6934901],
                    [112.8987241, 7.6933292],
                    [112.9001284, 7.6931781],
                    [112.9015338, 7.6930367],
                    [112.9029401, 7.692905],
                    [112.9043473, 7.6927832],
                    [112.9057553, 7.6926711],
                    [112.9071641, 7.6925688],
                    [112.9085736, 7.6924763],
                    [112.9099837, 7.6923936],
                    [112.9113943, 7.6923208],
                    [112.9128055, 7.6922577],
                    [112.914217, 7.6922044],
                    [112.9156289, 7.6921609],
                    [112.9170411, 7.6921273],
                    [112.9184534, 7.6921034],
                    [112.9198659, 7.6920894],
                    [112.9212785, 7.6920852],
                    [112.922691, 7.6920908],
                    [112.9241035, 7.6921062],
                    [112.9255159, 7.6921315],
                    [112.926928, 7.6921665],
                    [112.9283398, 7.6922114],
                    [112.9297513, 7.6922661],
                    [112.9311624, 7.6923306],
                    [112.932573, 7.6924048],
                    [112.933983, 7.6924889],
                    [112.9353924, 7.6925828],
                    [112.936801, 7.6926865],
                    [112.938209, 7.6928],
                    [112.939616, 7.6929232],
                    [112.9410222, 7.6930562],
                    [112.9424274, 7.693199],
                    [112.9438316, 7.6933516],
                    [112.9452346, 7.6935139],
                    [112.9466365, 7.6936859],
                    [112.9480371, 7.6938677],
                    [112.9494364, 7.6940592],
                    [112.9508343, 7.6942605],
                    [112.9522307, 7.6944714],
                    [112.9536256, 7.6946921],
                    [112.9550189, 7.6949224],
                    [112.9564106, 7.6951624],
                    [112.9578005, 7.6954121],
                    [112.9591886, 7.6956714],
                    [112.9605749, 7.6959404],
                    [112.9619592, 7.696219],
                    [112.9633415, 7.6965072],
                    [112.9647217, 7.696805],
                    [112.9660998, 7.6971124],
                    [112.9674757, 7.6974293],
                    [112.9688493, 7.6977559],
                    [112.9702205, 7.6980919],
                    [112.9715894, 7.6984375],
                    [112.9729558, 7.6987926],
                    [112.9743196, 7.6991571],
                    [112.9756808, 7.6995311],
                    [112.9770393, 7.6999146],
                    [112.9783951, 7.7003075],
                    [112.9797481, 7.7007099],
                    [112.9810982, 7.7011216],
                    [112.9824453, 7.7015426],
                    [112.9837894, 7.7019731],
                    [112.9851305, 7.7024128],
                    [112.9864684, 7.7028619],
                    [112.9878031, 7.7033203],
                    [112.9891345, 7.7037879],
                    [112.9904626, 7.7042648],
                    [112.9917873, 7.7047508],
                    [112.9931085, 7.7052461],
                    [112.9944261, 7.7057505],
                    [112.9957402, 7.7062641],
                    [112.9970506, 7.7067868],
                    [112.9983572, 7.7073186],
                    [112.9996601, 7.7078595],
                    [113.0009591, 7.7084094],
                    [113.0022542, 7.7089683],
                    [113.0035453, 7.7095362],
                    [113.0048323, 7.710113],
                    [113.0061153, 7.7106988],
                    [113.007394, 7.7112935],
                    [113.0086685, 7.7118971],
                    [113.0099388, 7.7125095],
                    [113.0112046, 7.7131307],
                    [113.012466, 7.7137607],
                    [113.013723, 7.7143994],
                    [113.0149753, 7.7150468],
                    [113.0162231, 7.715703],
                    [113.0174662, 7.7163678],
                    [113.0187046, 7.7170412],
                    [113.0199381, 7.7177232],
                    [113.0211668, 7.7184137],
                    [113.0223906, 7.7191128],
                    [113.0236094, 7.7198204],
                    [113.0248232, 7.7205364],
                    [113.0260319, 7.7212608],
                    [113.0272354, 7.7219936],
                    [113.0284337, 7.7227348],
                    [113.0296268, 7.7234842],
                    [113.0308145, 7.7242419],
                    [113.0319968, 7.7250079],
                    [113.0331736, 7.7257821],
                    [113.034345, 7.7265644],
                    [113.0355108, 7.7273548],
                    [113.036671, 7.7281533],
                    [113.0378254, 7.7289599],
                    [113.0389742, 7.7297744],
                    [113.0401172, 7.7305969],
                    [113.0412543, 7.7314274],
                    [113.0423855, 7.7322657],
                    [113.0435107, 7.7331118],
                    [113.0446299, 7.7339658],
                    [113.0457431, 7.7348275],
                    [113.0468501, 7.7356969],
                    [113.047951, 7.736574],
                    [113.0490456, 7.7374587],
                    [113.0501339, 7.738351],
                    [113.0512159, 7.7392508],
                    [113.0522915, 7.7401581],
                    [113.0533607, 7.7410729],
                    [113.0544233, 7.7419951],
                    [113.0554794, 7.7429246],
                    [113.0565289, 7.7438615],
                    [113.0575717, 7.7448056],
                    [113.0586079, 7.7457569],
                    [113.0596372, 7.7467155],
                    [113.0606598, 7.7476811],
                    [113.0616755, 7.7486538],
                    [113.0626843, 7.7496336],
                    [113.0636861, 7.7506203],
                    [113.0646809, 7.751614],
                    [113.0656687, 7.7526145],
                    [113.0666494, 7.7536219],
                    [113.0676229, 7.7546361],
                    [113.0685892, 7.755657],
                    [113.0695483, 7.7566846],
                    [113.0705, 7.7577188],
                    [113.0714444, 7.7587596],
                    [113.0723815, 7.759807],
                    [113.0733111, 7.7608608],
                    [113.0742332, 7.7619211],
                    [113.0751478, 7.7629878],
                    [113.0760548, 7.7640607],
                    [113.0769542, 7.76514],
                    [113.0778459, 7.7662254],
                    [113.07873, 7.7673171],
                    [113.0796063, 7.7684148],
                    [113.0804748, 7.7695186],
                    [113.0813354, 7.7706284],
                    [113.0821882, 7.7717442],
                    [113.0830331, 7.7728658],
                    [113.08387, 7.7739933],
                    [113.0846989, 7.7751266],
                    [113.0855198, 7.7762656],
                    [113.0863326, 7.7774103],
                    [113.0871372, 7.7785606],
                    [113.0879338, 7.7797164],
                    [113.0887221, 7.7808778],
                    [113.0895021, 7.7820446],
                    [113.0902739, 7.7832167],
                    [113.0910374, 7.7843943],
                    [113.0917925, 7.7855771],
                    [113.0925393, 7.7867651],
                    [113.0932776, 7.7879582],
                    [113.0940074, 7.7891565],
                    [113.0947287, 7.7903598],
                    [113.0954416, 7.791568],
                    [113.0961458, 7.7927812],
                    [113.0968414, 7.7939993],
                    [113.0975284, 7.7952221],
                    [113.0982068, 7.7964497],
                    [113.0988764, 7.797682],
                    [113.0995373, 7.7989189],
                    [113.1001894, 7.8001603],
                    [113.1008327, 7.8014062],
                    [113.1014672, 7.8026566],
                    [113.1020928, 7.8039114],
                    [113.1027095, 7.8051704],
                    [113.1033173, 7.8064337],
                    [113.1039161, 7.8077012],
                    [113.1045059, 7.8089729],
                    [113.1050868, 7.8102485],
                    [113.1056586, 7.8115282],
                    [113.1062213, 7.8128119],
                    [113.1067749, 7.8140993],
                    [113.1073194, 7.8153907],
                    [113.1078547, 7.8166857],
                    [113.1083809, 7.8179844],
                    [113.1088978, 7.8192868],
                    [113.1094055, 7.8205927],
                    [113.109904, 7.8219021],
                    [113.1103932, 7.8232149],
                    [113.110873, 7.8245311],
                    [113.1113436, 7.8258506],
                    [113.1118048, 7.8271733],
                    [113.1122566, 7.8284992],
                    [113.112699, 7.8298282],
                    [113.113132, 7.8311603],
                    [113.1135556, 7.8324952],
                    [113.1139697, 7.8338332],
                    [113.1143743, 7.8351739],
                    [113.1147695, 7.8365174],
                    [113.1151551, 7.8378636],
                    [113.1155311, 7.8392125],
                    [113.1158977, 7.8405639],
                    [113.1162546, 7.8419179],
                    [113.1166019, 7.8432743],
                    [113.1169397, 7.844633],
                    [113.1172678, 7.8459941],
                    [113.1175862, 7.8473574],
                    [113.117895, 7.8487229],
                    [113.1181942, 7.8500905],
                    [113.1184836, 7.8514602],
                    [113.1187634, 7.8528318],
                    [113.1190334, 7.8542053],
                    [113.1192937, 7.8555806],
                    [113.1195442, 7.8569578],
                    [113.119785, 7.8583366],
                    [113.1200161, 7.859717],
                    [113.1202373, 7.8610991],
                    [113.1204488, 7.8624826],
                    [113.1206505, 7.8638675],
                    [113.1208423, 7.8652538],
                    [113.1210244, 7.8666415],
                    [113.1211966, 7.8680303],
                    [113.1213589, 7.8694203],
                    [113.1215115, 7.8708114],
                    [113.1216542, 7.8722035],
                    [113.121787, 7.8735965],
                    [113.1219099, 7.8749905],
                    [113.122023, 7.8763852],
                    [113.1221262, 7.8777807],
                    [113.1222196, 7.8791769],
                    [113.122303, 7.8805737],
                    [113.1223766, 7.881971],
                    [113.1224402, 7.8833688],
                    [113.122494, 7.884767],
                    [113.1225378, 7.8861655],
                    [113.1225718, 7.8875643],
                    [113.1225958, 7.8889633],
                    [113.12261, 7.8903625],
                    [113.1226142, 7.8917617],
                    [113.1226086, 7.8931608],
                    [113.122593, 7.8945599],
                    [113.1225676, 7.8959589],
                    [113.1225322, 7.8973576],
                    [113.1224869, 7.8987561],
                    [113.1224317, 7.9001542],
                    [113.1223667, 7.9015518],
                    [113.1222917, 7.902949],
                    [113.1222068, 7.9043456],
                    [113.1221227, 7.9055856],
                    [113.1231094, 7.9049937],
                    [113.1243238, 7.9042767],
                    [113.1255433, 7.9035681],
                    [113.1267677, 7.902868],
                    [113.1279971, 7.9021764],
                    [113.1292313, 7.9014934],
                    [113.1304703, 7.900819],
                    [113.1317141, 7.9001532],
                    [113.1329626, 7.899496],
                    [113.1342157, 7.8988476],
                    [113.1354733, 7.8982078],
                    [113.1367355, 7.8975768],
                    [113.1380021, 7.8969546],
                    [113.139273, 7.8963412],
                    [113.1405483, 7.8957366],
                    [113.1418278, 7.8951409],
                    [113.1431115, 7.8945542],
                    [113.1443993, 7.8939763],
                    [113.1456912, 7.8934074],
                    [113.1469871, 7.8928475],
                    [113.1482869, 7.8922966],
                    [113.1495906, 7.8917547],
                    [113.1508981, 7.8912219],
                    [113.1522093, 7.8906982],
                    [113.1535242, 7.8901837],
                    [113.1548427, 7.8896782],
                    [113.1561648, 7.889182],
                    [113.1574903, 7.8886949],
                    [113.1588193, 7.8882171],
                    [113.1601516, 7.8877485],
                    [113.1614871, 7.8872891],
                    [113.162826, 7.8868391],
                    [113.1641679, 7.8863983],
                    [113.165513, 7.8859669],
                    [113.166861, 7.8855449],
                    [113.1682121, 7.8851322],
                    [113.169566, 7.8847289],
                    [113.1709227, 7.884335],
                    [113.1722822, 7.8839506],
                    [113.1736444, 7.8835756],
                    [113.1750092, 7.8832101],
                    [113.1763765, 7.8828541],
                    [113.1777463, 7.8825075],
                    [113.1791186, 7.8821705],
                    [113.1804932, 7.8818431],
                    [113.1818701, 7.8815252],
                    [113.1832492, 7.8812168],
                    [113.1846305, 7.8809181],
                    [113.1860138, 7.880629],
                    [113.1873992, 7.8803494],
                    [113.1887865, 7.8800795],
                    [113.1901756, 7.8798193],
                    [113.1915666, 7.8795687],
                    [113.1929593, 7.8793277],
                    [113.1943537, 7.8790965],
                    [113.1957497, 7.8788749],
                    [113.1971473, 7.8786631],
                    [113.1985463, 7.8784609],
                    [113.1999466, 7.8782685],
                    [113.2013484, 7.8780858],
                    [113.2027513, 7.8779129],
                    [113.2041555, 7.8777497],
                    [113.2055608, 7.8775962],
                    [113.2069671, 7.8774526],
                    [113.2083745, 7.8773187],
                    [113.2097827, 7.8771945],
                    [113.2111918, 7.8770802],
                    [113.2126016, 7.8769756],
                    [113.2140122, 7.8768809],
                    [113.2154234, 7.8767959],
                    [113.2168352, 7.8767208],
                    [113.2182474, 7.8766555],
                    [113.2196601, 7.8765999],
                    [113.2210732, 7.8765542],
                    [113.2224865, 7.8765183],
                    [113.2239001, 7.8764922],
                    [113.2253138, 7.876476],
                    [113.2267276, 7.8764695],
                    [113.2281414, 7.8764729],
                    [113.2295551, 7.8764861],
                    [113.2309687, 7.8765091],
                    [113.2323821, 7.876542],
                    [113.2337953, 7.8765846],
                    [113.2352081, 7.8766371],
                    [113.2366205, 7.8766994],
                    [113.2380324, 7.8767715],
                    [113.2394438, 7.8768534],
                    [113.2408545, 7.8769451],
                    [113.2422646, 7.8770466],
                    [113.243674, 7.8771579],
                    [113.2450825, 7.877279],
                    [113.2464901, 7.8774098],
                    [113.2478967, 7.8775505],
                    [113.2493024, 7.8777009],
                    [113.2507069, 7.877861],
                    [113.2521102, 7.8780309],
                    [113.2535124, 7.8782106],
                    [113.2549132, 7.8784],
                    [113.2563126, 7.8785991],
                    [113.2577106, 7.8788079],
                    [113.2591071, 7.8790265],
                    [113.260502, 7.8792547],
                    [113.2618952, 7.8794926],
                    [113.2632868, 7.8797402],
                    [113.2646765, 7.8799975],
                    [113.2660644, 7.8802644],
                    [113.2674504, 7.8805409],
                    [113.2688343, 7.880827],
                    [113.2702163, 7.8811228],
                    [113.2715961, 7.8814282],
                    [113.2729736, 7.8817431],
                    [113.274349, 7.8820676],
                    [113.275722, 7.8824016],
                    [113.2770926, 7.8827452],
                    [113.2784607, 7.8830982],
                    [113.2798263, 7.8834608],
                    [113.2811893, 7.8838328],
                    [113.2825496, 7.8842143],
                    [113.2839072, 7.8846053],
                    [113.285262, 7.8850056],
                    [113.2866139, 7.8854154],
                    [113.2879629, 7.8858345],
                    [113.2893089, 7.886263],
                    [113.2906519, 7.8867009],
                    [113.2919917, 7.887148],
                    [113.2933283, 7.8876045],
                    [113.2946616, 7.8880702],
                    [113.2959916, 7.8885452],
                    [113.2973182, 7.8890294],
                    [113.2986413, 7.8895228],
                    [113.299961, 7.8900253],
                    [113.301277, 7.8905371],
                    [113.3025894, 7.8910579],
                    [113.303898, 7.8915879],
                    [113.3052029, 7.8921269],
                    [113.3065039, 7.892675],
                    [113.307801, 7.8932321],
                    [113.3090942, 7.8937982],
                    [113.3103833, 7.8943733],
                    [113.3116683, 7.8949573],
                    [113.3129491, 7.8955502],
                    [113.3142257, 7.896152],
                    [113.315498, 7.8967627],
                    [113.3167659, 7.8973821],
                    [113.3180295, 7.8980104],
                    [113.3192885, 7.8986474],
                    [113.320543, 7.8992932],
                    [113.321793, 7.8999476],
                    [113.3230382, 7.9006108],
                    [113.3242787, 7.9012825],
                    [113.3255145, 7.9019629],
                    [113.3267454, 7.9026518],
                    [113.3279713, 7.9033492],
                    [113.3291924, 7.9040552],
                    [113.3304083, 7.9047696],
                    [113.3316192, 7.9054924],
                    [113.332825, 7.9062236],
                    [113.3340255, 7.9069632],
                    [113.3352208, 7.9077111],
                    [113.3364108, 7.9084673],
                    [113.3375954, 7.9092317],
                    [113.3387745, 7.9100043],
                    [113.3399481, 7.9107851],
                    [113.3411162, 7.9115741],
                    [113.3422787, 7.9123711],
                    [113.3434354, 7.9131762],
                    [113.3445865, 7.9139892],
                    [113.3457318, 7.9148103],
                    [113.3468712, 7.9156393],
                    [113.3480048, 7.9164762],
                    [113.3491323, 7.9173209],
                    [113.3502539, 7.9181735],
                    [113.3513694, 7.9190338],
                    [113.3524788, 7.9199018],
                    [113.3535821, 7.9207776],
                    [113.3546791, 7.9216609],
                    [113.3557698, 7.9225519],
                    [113.3568541, 7.9234504],
                    [113.3579321, 7.9243564],
                    [113.3590037, 7.9252699],
                    [113.3600687, 7.9261908],
                    [113.3611273, 7.9271191],
                    [113.3621792, 7.9280547],
                    [113.3632244, 7.9289975],
                    [113.364263, 7.9299476],
                    [113.3652948, 7.9309049],
                    [113.3663198, 7.9318694],
                    [113.367338, 7.9328409],
                    [113.3683492, 7.9338195],
                    [113.3693535, 7.9348051],
                    [113.3703508, 7.9357976],
                    [113.371341, 7.936797],
                    [113.3723242, 7.9378033],
                    [113.3733002, 7.9388164],
                    [113.374269, 7.9398362],
                    [113.3752305, 7.9408627],
                    [113.3761848, 7.9418959],
                    [113.3771317, 7.9429357],
                    [113.3780712, 7.943982],
                    [113.3790033, 7.9450348],
                    [113.3799279, 7.9460941],
                    [113.380845, 7.9471597],
                    [113.3817546, 7.9482317],
                    [113.3826565, 7.94931],
                    [113.3835508, 7.9503946],
                    [113.3844373, 7.9514853],
                    [113.3853161, 7.9525821],
                    [113.3861872, 7.953685],
                    [113.3870504, 7.9547939],
                    [113.3879057, 7.9559088],
                    [113.3887531, 7.9570296],
                    [113.3895926, 7.9581562],
                    [113.390424, 7.9592887],
                    [113.3912475, 7.9604269],
                    [113.3920628, 7.9615708],
                    [113.39287, 7.9627203],
                    [113.3936691, 7.9638754],
                    [113.3944599, 7.965036],
                    [113.3952426, 7.966202],
                    [113.3960169, 7.9673735],
                    [113.3967829, 7.9685503],
                    [113.3975406, 7.9697324],
                    [113.3982899, 7.9709198],
                    [113.3990308, 7.9721123],
                    [113.3997632, 7.9733099],
                    [113.4004871, 7.9745125],
                    [113.4012025, 7.9757202],
                    [113.4019093, 7.9769328],
                    [113.4026075, 7.9781503],
                    [113.4032971, 7.9793726],
                    [113.403978, 7.9805996],
                    [113.4046501, 7.9818313],
                    [113.4053136, 7.9830677],
                    [113.4059683, 7.9843087],
                    [113.4066141, 7.9855541],
                    [113.4072512, 7.986804],
                    [113.4078794, 7.9880583],
                    [113.4084986, 7.9893169],
                    [113.409109, 7.9905798],
                    [113.4097104, 7.9918469],
                    [113.4103028, 7.9931182],
                    [113.4108862, 7.9943935],
                    [113.4114605, 7.9956728],
                    [113.4120258, 7.9969561],
                    [113.4125819, 7.9982432],
                    [113.413129, 7.9995342],
                    [113.4136669, 8.000829],
                    [113.4141014, 8.0018963],
                    [113.4149833, 8.0026479],
                    [113.4160483, 8.0035686],
                    [113.4171068, 8.0044967],
                    [113.4181587, 8.0054321],
                    [113.419204, 8.0063748],
                    [113.4202426, 8.0073248],
                    [113.4212744, 8.0082819],
                    [113.4222994, 8.0092462],
                    [113.4233175, 8.0102175],
                    [113.4243288, 8.0111959],
                    [113.4253331, 8.0121813],
                    [113.4263304, 8.0131736],
                    [113.4273206, 8.0141729],
                    [113.4283037, 8.0151789],
                    [113.4292797, 8.0161918],
                    [113.4302485, 8.0172115],
                    [113.4312101, 8.0182378],
                    [113.4321643, 8.0192708],
                    [113.4331112, 8.0203104],
                    [113.4340508, 8.0213565],
                    [113.4349829, 8.0224091],
                    [113.4359075, 8.0234682],
                    [113.4368246, 8.0245336],
                    [113.4377341, 8.0256054],
                    [113.4386361, 8.0266835],
                    [113.4395303, 8.0277678],
                    [113.4404169, 8.0288583],
                    [113.4412957, 8.0299549],
                    [113.4421667, 8.0310576],
                    [113.4430299, 8.0321663],
                    [113.4438853, 8.033281],
                    [113.4447327, 8.0344016],
                    [113.4455721, 8.035528],
                    [113.4464036, 8.0366603],
                    [113.447227, 8.0377983],
                    [113.4480424, 8.0389419],
                    [113.4488496, 8.0400912],
                    [113.4496486, 8.0412461],
                    [113.4504395, 8.0424065],
                    [113.4512221, 8.0435723],
                    [113.4519965, 8.0447436],
                    [113.4527625, 8.0459202],
                    [113.4535202, 8.047102],
                    [113.4542695, 8.0482891],
                    [113.4550104, 8.0494814],
                    [113.4557428, 8.0506788],
                    [113.4564667, 8.0518812],
                    [113.457182, 8.0530887],
                    [113.4578889, 8.054301],
                    [113.4585871, 8.0555183],
                    [113.4592766, 8.0567403],
                    [113.4599575, 8.0579671],
                    [113.4606297, 8.0591986],
                    [113.4612932, 8.0604348],
                    [113.4619478, 8.0616755],
                    [113.4625937, 8.0629207],
                    [113.4632307, 8.0641704],
                    [113.4638589, 8.0654244],
                    [113.4644782, 8.0666828],
                    [113.4650885, 8.0679455],
                    [113.4656899, 8.0692123],
                    [113.4662823, 8.0704833],
                    [113.4668657, 8.0717584],
                    [113.4674401, 8.0730375],
                    [113.4680053, 8.0743205],
                    [113.4685615, 8.0756074],
                    [113.4691085, 8.0768982],
                    [113.4696464, 8.0781927],
                    [113.4701751, 8.0794909],
                    [113.4706946, 8.0807927],
                    [113.4712049, 8.0820982],
                    [113.4717059, 8.0834071],
                    [113.4721976, 8.0847194],
                    [113.47268, 8.0860352],
                    [113.4731531, 8.0873542],
                    [113.4736168, 8.0886765],
                    [113.4740712, 8.090002],
                    [113.4745161, 8.0913306],
                    [113.4749516, 8.0926623],
                    [113.4753777, 8.0939969],
                    [113.4757943, 8.0953345],
                    [113.4762015, 8.0966749],
                    [113.4765991, 8.0980181],
                    [113.4769872, 8.099364],
                    [113.4773658, 8.1007126],
                    [113.4777348, 8.1020638],
                    [113.4780942, 8.1034175],
                    [113.478444, 8.1047737],
                    [113.4787843, 8.1061322],
                    [113.4791148, 8.1074931],
                    [113.4794358, 8.1088562],
                    [113.479747, 8.1102215],
                    [113.4800486, 8.111589],
                    [113.4803405, 8.1129585],
                    [113.4804785, 8.1136288],
                    [113.4814956, 8.1144358],
                    [113.4825926, 8.1153187],
                    [113.4836833, 8.1162093],
                    [113.4847677, 8.1171074],
                    [113.4858457, 8.1180129],
                    [113.4869172, 8.118926],
                    [113.4879823, 8.1198465],
                    [113.4890408, 8.1207743],
                    [113.4900927, 8.1217094],
                    [113.491138, 8.1226519],
                    [113.4921765, 8.1236015],
                    [113.4932083, 8.1245584],
                    [113.4942333, 8.1255223],
                    [113.4952515, 8.1264934],
                    [113.4962627, 8.1274715],
                    [113.497267, 8.1284566],
                    [113.4982643, 8.1294487],
                    [113.4992546, 8.1304476],
                    [113.5002377, 8.1314534],
                    [113.5012137, 8.132466],
                    [113.5021825, 8.1334854],
                    [113.503144, 8.1345114],
                    [113.5040983, 8.1355441],
                    [113.5050452, 8.1365834],
                    [113.5059847, 8.1376292],
                    [113.5069168, 8.1386815],
                    [113.5078415, 8.1397403],
                    [113.5087586, 8.1408054],
                    [113.5096681, 8.1418769],
                    [113.51057, 8.1429547],
                    [113.5114643, 8.1440387],
                    [113.5123509, 8.1451289],
                    [113.5132297, 8.1462252],
                    [113.5141007, 8.1473275],
                    [113.5149639, 8.1484359],
                    [113.5158192, 8.1495503],
                    [113.5166666, 8.1506705],
                    [113.5175061, 8.1517967],
                    [113.5183376, 8.1529286],
                    [113.519161, 8.1540662],
                    [113.5199763, 8.1552096],
                    [113.5207835, 8.1563585],
                    [113.5215826, 8.1575131],
                    [113.5223735, 8.1586731],
                    [113.5231561, 8.1598386],
                    [113.5239304, 8.1610095],
                    [113.5246965, 8.1621858],
                    [113.5254542, 8.1633673],
                    [113.5262035, 8.1645541],
                    [113.5269443, 8.165746],
                    [113.5276767, 8.166943],
                    [113.5284006, 8.1681451],
                    [113.529116, 8.1693522],
                    [113.5298228, 8.1705642],
                    [113.530521, 8.1717811],
                    [113.5312106, 8.1730028],
                    [113.5318915, 8.1742293],
                    [113.5325637, 8.1754604],
                    [113.5332271, 8.1766962],
                    [113.5338818, 8.1779365],
                    [113.5345277, 8.1791814],
                    [113.5351647, 8.1804307],
                    [113.5357929, 8.1816844],
                    [113.5364122, 8.1829424],
                    [113.5370225, 8.1842047],
                    [113.5376239, 8.1854712],
                    [113.5382163, 8.1867418],
                    [113.5387997, 8.1880165],
                    [113.539374, 8.1892952],
                    [113.5399393, 8.1905779],
                    [113.5404955, 8.1918644],
                    [113.5410425, 8.1931548],
                    [113.5415804, 8.194449],
                    [113.5421091, 8.1957468],
                    [113.5426286, 8.1970483],
                    [113.5431388, 8.1983533],
                    [113.5436398, 8.1996618],
                    [113.5441316, 8.2009738],
                    [113.544614, 8.2022892],
                    [113.545087, 8.2036079],
                    [113.5455508, 8.2049298],
                    [113.5460051, 8.2062549],
                    [113.5464501, 8.2075831],
                    [113.5468856, 8.2089144],
                    [113.5473117, 8.2102486],
                    [113.5477283, 8.2115858],
                    [113.5481354, 8.2129258],
                    [113.5485331, 8.2142686],
                    [113.5489212, 8.2156142],
                    [113.5492997, 8.2169624],
                    [113.5496687, 8.2183131],
                    [113.5500282, 8.2196664],
                    [113.550378, 8.2210222],
                    [113.5507182, 8.2223804],
                    [113.5510488, 8.2237408],
                    [113.5513697, 8.2251036],
                    [113.551681, 8.2264685],
                    [113.5519826, 8.2278355],
                    [113.5522745, 8.2292046],
                    [113.5525567, 8.2305757],
                    [113.5528292, 8.2319487],
                    [113.5530919, 8.2333236],
                    [113.5533449, 8.2347002],
                    [113.5535881, 8.2360786],
                    [113.5538216, 8.2374586],
                    [113.5540452, 8.2388402],
                    [113.5542591, 8.2402233],
                    [113.5544632, 8.2416078],
                    [113.5546574, 8.2429938],
                    [113.5548419, 8.244381],
                    [113.5550165, 8.2457695],
                    [113.5551812, 8.2471591],
                    [113.5553361, 8.2485499],
                    [113.5554812, 8.2499417],
                    [113.5556163, 8.2513344],
                    [113.5557416, 8.2527281],
                    [113.5558571, 8.2541226],
                    [113.5559626, 8.2555178],
                    [113.5560583, 8.2569138],
                    [113.556144, 8.2583103],
                    [113.5562199, 8.2597075],
                    [113.5562859, 8.2611051],
                    [113.5563419, 8.2625031],
                    [113.5563881, 8.2639015],
                    [113.5564243, 8.2653001],
                    [113.5564506, 8.266699],
                    [113.556467, 8.268098],
                    [113.5564735, 8.2694971],
                    [113.5564701, 8.2708962],
                    [113.5564568, 8.2722952],
                    [113.5564336, 8.2736941],
                    [113.5564004, 8.2750928],
                    [113.5563573, 8.2764913],
                    [113.5563044, 8.2778894],
                    [113.5562415, 8.2792871],
                    [113.5561687, 8.2806843],
                    [113.556086, 8.2820809],
                    [113.5559935, 8.283477],
                    [113.555891, 8.2848723],
                    [113.5557786, 8.286267],
                    [113.5556564, 8.2876608],
                    [113.5555243, 8.2890537],
                    [113.5553823, 8.2904456],
                    [113.5552305, 8.2918366],
                    [113.5550688, 8.2932264],
                    [113.5548973, 8.2946151],
                    [113.5547159, 8.2960025],
                    [113.5545247, 8.2973887],
                    [113.5543237, 8.2987735],
                    [113.5541129, 8.3001568],
                    [113.5538922, 8.3015387],
                    [113.5536618, 8.302919],
                    [113.5534217, 8.3042976],
                    [113.5531717, 8.3056746],
                    [113.552912, 8.3070497],
                    [113.5526426, 8.308423],
                    [113.5523634, 8.3097945],
                    [113.5520745, 8.3111639],
                    [113.5517759, 8.3125313],
                    [113.5514677, 8.3138966],
                    [113.5511497, 8.3152597],
                    [113.5508222, 8.3166206],
                    [113.550485, 8.3179791],
                    [113.5501381, 8.3193353],
                    [113.5497817, 8.320689],
                    [113.5494157, 8.3220403],
                    [113.5490401, 8.3233889],
                    [113.5486549, 8.3247349],
                    [113.5482603, 8.3260782],
                    [113.5478561, 8.3274187],
                    [113.5474424, 8.3287564],
                    [113.5470193, 8.3300911],
                    [113.5465867, 8.3314229],
                    [113.5461447, 8.3327517],
                    [113.5456932, 8.3340773],
                    [113.5452324, 8.3353998],
                    [113.5447623, 8.3367191],
                    [113.5442827, 8.338035],
                    [113.5437939, 8.3393476],
                    [113.5432958, 8.3406567],
                    [113.5427884, 8.3419624],
                    [113.5422718, 8.3432645],
                    [113.541746, 8.344563],
                    [113.5412109, 8.3458578],
                    [113.5406668, 8.3471488],
                    [113.5401134, 8.3484361],
                    [113.539551, 8.3497194],
                    [113.5389795, 8.3509989],
                    [113.5383989, 8.3522743],
                    [113.5378093, 8.3535457],
                    [113.5372107, 8.3548129],
                    [113.5366031, 8.3560759],
                    [113.5359866, 8.3573347],
                    [113.5353612, 8.3585892],
                    [113.5347269, 8.3598393],
                    [113.5340838, 8.361085],
                    [113.5334319, 8.3623262],
                    [113.5327712, 8.3635628],
                    [113.5321017, 8.3647948],
                    [113.5314235, 8.3660221],
                    [113.5307366, 8.3672447],
                    [113.5300411, 8.3684625],
                    [113.529337, 8.3696754],
                    [113.5286243, 8.3708835],
                    [113.527903, 8.3720865],
                    [113.5271733, 8.3732845],
                    [113.526435, 8.3744774],
                    [113.5256883, 8.3756651],
                    [113.5249333, 8.3768476],
                    [113.5241698, 8.3780249],
                    [113.5233981, 8.3791968],
                    [113.522618, 8.3803634],
                    [113.5218297, 8.3815245],
                    [113.5210332, 8.3826801],
                    [113.5202285, 8.3838301],
                    [113.5194157, 8.3849745],
                    [113.5185948, 8.3861133],
                    [113.5177658, 8.3872463],
                    [113.5169289, 8.3883736],
                    [113.5160839, 8.389495],
                    [113.515231, 8.3906105],
                    [113.5143703, 8.3917201],
                    [113.5135017, 8.3928236],
                    [113.5126253, 8.3939212],
                    [113.5117411, 8.3950126],
                    [113.5108493, 8.3960978],
                    [113.5099497, 8.3971768],
                    [113.5090426, 8.3982496],
                    [113.5081278, 8.399316],
                    [113.5072055, 8.4003761],
                    [113.5062757, 8.4014297],
                    [113.5053385, 8.4024768],
                    [113.5043939, 8.4035175],
                    [113.5034419, 8.4045515],
                    [113.5024826, 8.4055789],
                    [113.5015161, 8.4065996],
                    [113.5005423, 8.4076136],
                    [113.4995614, 8.4086208],
                    [113.4985734, 8.4096212],
                    [113.4975783, 8.4106147],
                    [113.4965762, 8.4116013],
                    [113.4955671, 8.4125809],
                    [113.4945511, 8.4135534],
                    [113.4935282, 8.4145189],
                    [113.4924985, 8.4154773],
                    [113.491462, 8.4164285],
                    [113.4904188, 8.4173724],
                    [113.489369, 8.4183092],
                    [113.4883125, 8.4192386],
                    [113.4872495, 8.4201606],
                    [113.48618, 8.4210753],
                    [113.485104, 8.4219825],
                    [113.4840216, 8.4228822],
                    [113.4829328, 8.4237744],
                    [113.4818378, 8.424659],
                    [113.4807365, 8.425536],
                    [113.479629, 8.4264054],
                    [113.4785154, 8.427267],
                    [113.4773957, 8.4281209],
                    [113.47627, 8.4289669],
                    [113.4751383, 8.4298052],
                    [113.4740007, 8.4306356],
                    [113.4728572, 8.4314581],
                    [113.471708, 8.4322726],
                    [113.4705529, 8.4330791],
                    [113.4693922, 8.4338776],
                    [113.4682259, 8.434668],
                    [113.467054, 8.4354503],
                    [113.4658766, 8.4362245],
                    [113.4646937, 8.4369905],
                    [113.4635054, 8.4377482],
                    [113.4623118, 8.4384977],
                    [113.4611129, 8.4392389],
                    [113.4599087, 8.4399717],
                    [113.4586994, 8.4406962],
                    [113.457485, 8.4414123],
                    [113.4562656, 8.4421199],
                    [113.4550411, 8.442819],
                    [113.4538117, 8.4435097],
                    [113.4525775, 8.4441917],
                    [113.4513385, 8.4448653],
                    [113.4500947, 8.4455302],
                    [113.4488462, 8.4461864],
                    [113.4475931, 8.446834],
                    [113.4463355, 8.4474729],
                    [113.4450733, 8.448103],
                    [113.4438067, 8.4487243],
                    [113.4425358, 8.4493369],
                    [113.4412605, 8.4499406],
                    [113.439981, 8.4505355],
                    [113.4386973, 8.4511214],
                    [113.4374095, 8.4516985],
                    [113.4361176, 8.4522666],
                    [113.4348217, 8.4528257],
                    [113.4335219, 8.4533759],
                    [113.4322182, 8.453917],
                    [113.4309107, 8.454449],
                    [113.4295995, 8.4549719],
                    [113.4282846, 8.4554858],
                    [113.4269661, 8.4559905],
                    [113.425644, 8.4564861],
                    [113.4243185, 8.4569724],
                    [113.4229896, 8.4574496],
                    [113.4216572, 8.4579175],
                    [113.4203217, 8.4583762],
                    [113.4189829, 8.4588256],
                    [113.4176409, 8.4592657],
                    [113.4162958, 8.4596965],
                    [113.4149478, 8.460118],
                    [113.4135968, 8.4605301],
                    [113.4122428, 8.4609328],
                    [113.4108861, 8.4613261],
                    [113.4095266, 8.4617099],
                    [113.4081645, 8.4620844],
                    [113.4067997, 8.4624494],
                    [113.4054323, 8.4628049],
                    [113.4040625, 8.4631509],
                    [113.4026902, 8.4634874],
                    [113.4013156, 8.4638144],
                    [113.3999387, 8.4641318],
                    [113.3985596, 8.4644397],
                    [113.3971783, 8.464738],
                    [113.395795, 8.4650267],
                    [113.3944096, 8.4653059],
                    [113.3930223, 8.4655754],
                    [113.3916332, 8.4658352],
                    [113.3902422, 8.4660855],
                    [113.3888495, 8.466326],
                    [113.3874551, 8.466557],
                    [113.3860591, 8.4667782],
                    [113.3846615, 8.4669897],
                    [113.3832626, 8.4671916],
                    [113.3818622, 8.4673837],
                    [113.3804604, 8.4675661],
                    [113.3790575, 8.4677388],
                    [113.3776533, 8.4679018],
                    [113.376248, 8.468055],
                    [113.3748417, 8.4681985],
                    [113.3734343, 8.4683322],
                    [113.3720261, 8.4684561],
                    [113.370617, 8.4685703],
                    [113.3692072, 8.4686747],
                    [113.3677966, 8.4687693],
                    [113.3663854, 8.4688541],
                    [113.3649736, 8.4689291],
                    [113.3635614, 8.4689944],
                    [113.3621487, 8.4690498],
                    [113.3607356, 8.4690955],
                    [113.3593223, 8.4691313],
                    [113.3579087, 8.4691574],
                    [113.356495, 8.4691736],
                    [113.3550813, 8.46918],
                    [113.3536675, 8.4691767],
                    [113.3522537, 8.4691635],
                    [113.3508401, 8.4691405],
                    [113.3494267, 8.4691077],
                    [113.3480135, 8.4690651],
                    [113.3466007, 8.4690127],
                    [113.3451883, 8.4689505],
                    [113.3437764, 8.4688785],
                    [113.342365, 8.4687967],
                    [113.3409543, 8.4687052],
                    [113.3395442, 8.4686038],
                    [113.3381348, 8.4684927],
                    [113.3367263, 8.4683718],
                    [113.3353187, 8.4682411],
                    [113.3339121, 8.4681007],
                    [113.3325064, 8.4679505],
                    [113.3311019, 8.4677906],
                    [113.3296986, 8.4676209],
                    [113.3282964, 8.4674416],
                    [113.3268956, 8.4672524],
                    [113.3254962, 8.4670536],
                    [113.3240982, 8.4668451],
                    [113.3227017, 8.4666269],
                    [113.3213068, 8.466399],
                    [113.3199136, 8.4661614],
                    [113.318522, 8.4659142],
                    [113.3171323, 8.4656573],
                    [113.3157444, 8.4653908],
                    [113.3143584, 8.4651147],
                    [113.3129745, 8.4648289],
                    [113.3115925, 8.4645336],
                    [113.3102128, 8.4642287],
                    [113.3088352, 8.4639143],
                    [113.3074598, 8.4635902],
                    [113.3060868, 8.4632567],
                    [113.3047162, 8.4629136],
                    [113.3033481, 8.4625611],
                    [113.3019825, 8.462199],
                    [113.3006195, 8.4618275],
                    [113.2992592, 8.4614466],
                    [113.2979016, 8.4610562],
                    [113.2965468, 8.4606564],
                    [113.2951949, 8.4602473],
                    [113.2938459, 8.4598287],
                    [113.2924999, 8.4594008],
                    [113.2911569, 8.4589636],
                    [113.2898172, 8.4585171],
                    [113.2884806, 8.4580613],
                    [113.2871472, 8.4575963],
                    [113.2858172, 8.457122],
                    [113.2844906, 8.4566385],
                    [113.2831675, 8.4561458],
                    [113.2818478, 8.4556439],
                    [113.2805318, 8.4551329],
                    [113.2792194, 8.4546128],
                    [113.2779108, 8.4540836],
                    [113.2766059, 8.4535453],
                    [113.2753049, 8.452998],
                    [113.2740078, 8.4524416],
                    [113.2727146, 8.4518763],
                    [113.2714255, 8.4513021],
                    [113.2701405, 8.4507189],
                    [113.2688597, 8.4501268],
                    [113.2675831, 8.4495258],
                    [113.2663108, 8.448916],
                    [113.2650429, 8.4482974],
                    [113.2637793, 8.44767],
                    [113.2625203, 8.4470338],
                    [113.2612658, 8.446389],
                    [113.2600158, 8.4457354],
                    [113.2587706, 8.4450732],
                    [113.2575301, 8.4444024],
                    [113.2562943, 8.4437229],
                    [113.2550634, 8.443035],
                    [113.2538375, 8.4423385],
                    [113.2526165, 8.4416335],
                    [113.2514005, 8.44092],
                    [113.2501896, 8.4401982],
                    [113.2489838, 8.4394679],
                    [113.2477833, 8.4387293],
                    [113.246588, 8.4379824],
                    [113.245398, 8.4372272],
                    [113.2442135, 8.4364638],
                    [113.2430343, 8.4356922],
                    [113.2418607, 8.4349124],
                    [113.2406926, 8.4341246],
                    [113.2395302, 8.4333286],
                    [113.2383734, 8.4325245],
                    [113.2372223, 8.4317125],
                    [113.236077, 8.4308925],
                    [113.2349376, 8.4300646],
                    [113.233804, 8.4292288],
                    [113.2326765, 8.4283851],
                    [113.2315549, 8.4275337],
                    [113.2304394, 8.4266744],
                    [113.22933, 8.4258075],
                    [113.2282268, 8.4249329],
                    [113.2271298, 8.4240506],
                    [113.226039, 8.4231608],
                    [113.2249547, 8.4222634],
                    [113.2238767, 8.4213585],
                    [113.2228051, 8.4204462],
                    [113.2217401, 8.4195264],
                    [113.2206816, 8.4185993],
                    [113.2196296, 8.4176648],
                    [113.2185844, 8.4167231],
                    [113.2175458, 8.4157742],
                    [113.216514, 8.414818],
                    [113.215489, 8.4138548],
                    [113.2144709, 8.4128844],
                    [113.2134596, 8.411907],
                    [113.2124553, 8.4109226],
                    [113.211458, 8.4099313],
                    [113.2104678, 8.408933],
                    [113.2094846, 8.4079279],
                    [113.2085086, 8.406916],
                    [113.2075399, 8.4058974],
                    [113.2065783, 8.4048721],
                    [113.205624, 8.4038401],
                    [113.2046771, 8.4028015],
                    [113.2037376, 8.4017564],
                    [113.2028055, 8.4007048],
                    [113.2018809, 8.3996467],
                    [113.2009638, 8.3985823],
                    [113.2000542, 8.3975115],
                    [113.1991523, 8.3964344],
                    [113.198258, 8.3953511],
                    [113.1973715, 8.3942616],
                    [113.1964927, 8.393166],
                    [113.1956216, 8.3920643],
                    [113.1947584, 8.3909566],
                    [113.1939031, 8.3898429],
                    [113.1930557, 8.3887233],
                    [113.1922162, 8.3875979],
                    [113.1913848, 8.3864667],
                    [113.1905614, 8.3853297],
                    [113.189746, 8.384187],
                    [113.1889388, 8.3830387],
                    [113.1881397, 8.3818848],
                    [113.1873489, 8.3807255],
                    [113.1865662, 8.3795606],
                    [113.1857919, 8.3783903],
                    [113.1850259, 8.3772147],
                    [113.1842682, 8.3760338],
                    [113.1835189, 8.3748477],
                    [113.182778, 8.3736564],
                    [113.1820456, 8.37246],
                    [113.1813217, 8.3712585],
                    [113.1806063, 8.3700521],
                    [113.1798995, 8.3688407],
                    [113.1792013, 8.3676244],
                    [113.1785118, 8.3664033],
                    [113.1778309, 8.3651774],
                    [113.1771587, 8.3639469],
                    [113.1764952, 8.3627117],
                    [113.1758405, 8.3614719],
                    [113.1751947, 8.3602276],
                    [113.1745576, 8.3589789],
                    [113.1739295, 8.3577258],
                    [113.1733102, 8.3564683],
                    [113.1726998, 8.3552066],
                    [113.1720984, 8.3539406],
                    [113.171506, 8.3526706],
                    [113.1709227, 8.3513964],
                    [113.1703483, 8.3501182],
                    [113.169783, 8.3488361],
                    [113.1692269, 8.34755],
                    [113.1686798, 8.3462601],
                    [113.168142, 8.3449665],
                    [113.1676133, 8.3436692],
                    [113.1670938, 8.3423682],
                    [113.1665835, 8.3410636],
                    [113.1660825, 8.3397555],
                    [113.1655908, 8.338444],
                    [113.1651084, 8.3371291],
                    [113.1646353, 8.3358109],
                    [113.1641716, 8.3344894],
                    [113.1637172, 8.3331647],
                    [113.1632723, 8.3318369],
                    [113.1628367, 8.3305061],
                    [113.1624107, 8.3291722],
                    [113.161994, 8.3278355],
                    [113.1615869, 8.3264958],
                    [113.1611893, 8.3251534],
                    [113.1608012, 8.3238082],
                    [113.1604226, 8.3224604],
                    [113.1600536, 8.32111],
                    [113.1596942, 8.319757],
                    [113.1593443, 8.3184016],
                    [113.1590041, 8.3170438],
                    [113.1586735, 8.3156836],
                    [113.1583526, 8.3143212],
                    [113.1580413, 8.3129566],
                    [113.1577397, 8.3115898],
                    [113.1574478, 8.310221],
                    [113.1573099, 8.309551],
                    [113.1562928, 8.3087444],
                    [113.1551958, 8.3078619],
                    [113.1541051, 8.3069718],
                    [113.1530207, 8.3060742],
                    [113.1519427, 8.305169],
                    [113.1508712, 8.3042564],
                    [113.1498061, 8.3033363],
                    [113.1487476, 8.3024089],
                    [113.1476957, 8.3014742],
                    [113.1466504, 8.3005322],
                    [113.1456119, 8.299583],
                    [113.14458, 8.2986266],
                    [113.143555, 8.297663],
                    [113.1425369, 8.2966923],
                    [113.1415256, 8.2957147],
                    [113.1405213, 8.29473],
                    [113.1395241, 8.2937383],
                    [113.1385338, 8.2927398],
                    [113.1375507, 8.2917344],
                    [113.1365747, 8.2907222],
                    [113.1356059, 8.2897033],
                    [113.1346443, 8.2886776],
                    [113.1336901, 8.2876454],
                    [113.1327432, 8.2866065],
                    [113.1318036, 8.285561],
                    [113.1308715, 8.2845091],
                    [113.1299469, 8.2834507],
                    [113.1290298, 8.282386],
                    [113.1281203, 8.2813149],
                    [113.1272183, 8.2802375],
                    [113.1263241, 8.2791538],
                    [113.1254375, 8.278064],
                    [113.1245587, 8.2769681],
                    [113.1236877, 8.2758661],
                    [113.1228245, 8.274758],
                    [113.1219691, 8.273644],
                    [113.1211217, 8.2725241],
                    [113.1202823, 8.2713983],
                    [113.1194508, 8.2702668],
                    [113.1186274, 8.2691294],
                    [113.1178121, 8.2679864],
                    [113.1170048, 8.2668378],
                    [113.1162058, 8.2656836],
                    [113.1154149, 8.2645238],
                    [113.1146323, 8.2633586],
                    [113.1138579, 8.262188],
                    [113.1130919, 8.2610121],
                    [113.1123342, 8.2598308],
                    [113.1115849, 8.2586444],
                    [113.1108441, 8.2574527],
                    [113.1101116, 8.2562559],
                    [113.1093877, 8.2550541],
                    [113.1086724, 8.2538473],
                    [113.1079656, 8.2526355],
                    [113.1072674, 8.2514189],
                    [113.1065778, 8.2501974],
                    [113.1058969, 8.2489712],
                    [113.1052247, 8.2477403],
                    [113.1045613, 8.2465047],
                    [113.1039066, 8.2452646],
                    [113.1032607, 8.2440199],
                    [113.1026237, 8.2427708],
                    [113.1019955, 8.2415173],
                    [113.1013762, 8.2402595],
                    [113.1007659, 8.2389974],
                    [113.1001645, 8.2377311],
                    [113.0995721, 8.2364606],
                    [113.0989887, 8.2351861],
                    [113.0984144, 8.2339075],
                    [113.0978491, 8.232625],
                    [113.0972929, 8.2313386],
                    [113.0967459, 8.2300483],
                    [113.096208, 8.2287543],
                    [113.0957734, 8.2276876],
                    [113.0948916, 8.2269364],
                    [113.0938265, 8.2260162],
                    [113.092768, 8.2250886],
                    [113.0917161, 8.2241537],
                    [113.0906708, 8.2232115],
                    [113.0896323, 8.2222621],
                    [113.0886005, 8.2213055],
                    [113.0875755, 8.2203417],
                    [113.0865573, 8.2193709],
                    [113.0855461, 8.218393],
                    [113.0845418, 8.2174081],
                    [113.0835445, 8.2164163],
                    [113.0825543, 8.2154176],
                    [113.0815711, 8.214412],
                    [113.0805951, 8.2133996],
                    [113.0796263, 8.2123805],
                    [113.0786648, 8.2113546],
                    [113.0777105, 8.2103221],
                    [113.0767636, 8.2092831],
                    [113.0758241, 8.2082374],
                    [113.074892, 8.2071853],
                    [113.0739673, 8.2061267],
                    [113.0730502, 8.2050617],
                    [113.0721407, 8.2039904],
                    [113.0712388, 8.2029128],
                    [113.0703445, 8.201829],
                    [113.069458, 8.2007389],
                    [113.0685791, 8.1996428],
                    [113.0677081, 8.1985405],
                    [113.0668449, 8.1974323],
                    [113.0659896, 8.1963181],
                    [113.0651422, 8.1951979],
                    [113.0643027, 8.1940719],
                    [113.0634712, 8.1929401],
                    [113.0626478, 8.1918026],
                    [113.0618325, 8.1906594],
                    [113.0610253, 8.1895105],
                    [113.0602262, 8.1883561],
                    [113.0594353, 8.1871961],
                    [113.0586527, 8.1860307],
                    [113.0578784, 8.1848598],
                    [113.0571123, 8.1836837],
                    [113.0563547, 8.1825022],
                    [113.0556054, 8.1813155],
                    [113.0548645, 8.1801236],
                    [113.0541321, 8.1789266],
                    [113.0534082, 8.1777245],
                    [113.0526928, 8.1765175],
                    [113.051986, 8.1753055],
                    [113.0512878, 8.1740886],
                    [113.0505982, 8.1728669],
                    [113.0499173, 8.1716404],
                    [113.0492451, 8.1704093],
                    [113.0485817, 8.1691735],
                    [113.047927, 8.1679331],
                    [113.0472811, 8.1666882],
                    [113.0466441, 8.1654389],
                    [113.0460159, 8.1641851],
                    [113.0453967, 8.162927],
                    [113.0447863, 8.1616647],
                    [113.0441849, 8.1603981],
                    [113.0435925, 8.1591274],
                    [113.0430091, 8.1578526],
                    [113.0424348, 8.1565738],
                    [113.0418695, 8.155291],
                    [113.0413134, 8.1540044],
                    [113.0407663, 8.1527139],
                    [113.0402284, 8.1514196],
                    [113.0396997, 8.1501216],
                    [113.0391802, 8.14882],
                    [113.03867, 8.1475148],
                    [113.038169, 8.1462061],
                    [113.0376773, 8.1448939],
                    [113.0371948, 8.1435784],
                    [113.0367218, 8.1422595],
                    [113.036258, 8.1409374],
                    [113.0358037, 8.1396121],
                    [113.0353587, 8.1382836],
                    [113.0349232, 8.1369521],
                    [113.0344971, 8.1356176],
                    [113.0340805, 8.1342802],
                    [113.0336734, 8.1329399],
                    [113.0332757, 8.1315968],
                    [113.0328876, 8.130251],
                    [113.0325091, 8.1289025],
                    [113.0321401, 8.1275514],
                    [113.0317806, 8.1261978],
                    [113.0314308, 8.1248417],
                    [113.0310906, 8.1234832],
                    [113.03076, 8.1221224],
                    [113.0304391, 8.1207593],
                    [113.0301278, 8.119394],
                    [113.0298262, 8.1180266],
                    [113.0295343, 8.1166571],
                    [113.0292521, 8.1152857],
                    [113.0289797, 8.1139123],
                    [113.0287169, 8.112537],
                    [113.0284639, 8.1111599],
                    [113.0282207, 8.1097811],
                    [113.0279872, 8.1084007],
                    [113.0277636, 8.1070186],
                    [113.0275497, 8.1056351],
                    [113.0273456, 8.10425],
                    [113.0271514, 8.1028636],
                    [113.0269669, 8.1014759],
                    [113.0267924, 8.1000869],
                    [113.0266276, 8.0986968],
                    [113.0264727, 8.0973055],
                    [113.0263277, 8.0959132],
                    [113.0261925, 8.0945199],
                    [113.0260672, 8.0931257],
                    [113.0259517, 8.0917306],
                    [113.0258462, 8.0903348],
                    [113.0257505, 8.0889383],
                    [113.0256648, 8.0875411],
                    [113.0255889, 8.0861434],
                    [113.0255229, 8.0847452],
                    [113.0254669, 8.0833465],
                    [113.0254207, 8.0819475],
                    [113.0253845, 8.0805482],
                    [113.0253582, 8.0791487],
                    [113.0253418, 8.077749],
                    [113.0253353, 8.0763492],
                    [113.0253387, 8.0749494],
                    [113.025352, 8.0735497],
                    [113.0253752, 8.0721501],
                    [113.0254084, 8.0707507],
                    [113.0254515, 8.0693516],
                    [113.0255044, 8.0679527],
                    [113.0255673, 8.0665543],
                    [113.0256401, 8.0651563],
                    [113.0257228, 8.0637589],
                    [113.0258154, 8.0623621],
                    [113.0258337, 8.0621119],
                    [113.0246503, 8.062819],
                    [113.0234358, 8.0635333],
                    [113.0222163, 8.064239],
                    [113.0209918, 8.0649363],
                    [113.0197624, 8.0656251],
                    [113.0185281, 8.0663053],
                    [113.0172891, 8.0669769],
                    [113.0160453, 8.0676399],
                    [113.0147969, 8.0682942],
                    [113.0135439, 8.0689399],
                    [113.0122863, 8.0695768],
                    [113.0110242, 8.070205],
                    [113.0097578, 8.0708244],
                    [113.0084869, 8.071435],
                    [113.0072118, 8.0720368],
                    [113.0059324, 8.0726297],
                    [113.0046489, 8.0732137],
                    [113.0033613, 8.0737887],
                    [113.0020696, 8.0743549],
                    [113.000774, 8.074912],
                    [112.9994744, 8.0754601],
                    [112.998171, 8.0759992],
                    [112.9968638, 8.0765292],
                    [112.9955529, 8.0770502],
                    [112.9942383, 8.077562],
                    [112.9929201, 8.0780647],
                    [112.9915984, 8.0785582],
                    [112.9902733, 8.0790425],
                    [112.9889447, 8.0795176],
                    [112.9876128, 8.0799835],
                    [112.9862777, 8.0804401],
                    [112.9849393, 8.0808874],
                    [112.9835978, 8.0813255],
                    [112.9822532, 8.0817542],
                    [112.9809057, 8.0821735],
                    [112.9795552, 8.0825835],
                    [112.9782018, 8.0829842],
                    [112.9768456, 8.0833754],
                    [112.9754867, 8.0837571],
                    [112.9741251, 8.0841295],
                    [112.9727609, 8.0844923],
                    [112.9713942, 8.0848457],
                    [112.970025, 8.0851896],
                    [112.9686534, 8.085524],
                    [112.9672795, 8.0858488],
                    [112.9659033, 8.0861641],
                    [112.9645249, 8.0864699],
                    [112.9631443, 8.086766],
                    [112.9617617, 8.0870526],
                    [112.9603771, 8.0873295],
                    [112.9589906, 8.0875969],
                    [112.9576023, 8.0878546],
                    [112.9562121, 8.0881027],
                    [112.9548202, 8.0883411],
                    [112.9534266, 8.0885698],
                    [112.9520315, 8.0887888],
                    [112.9506349, 8.0889982],
                    [112.9492368, 8.0891979],
                    [112.9478373, 8.0893878],
                    [112.9464365, 8.089568],
                    [112.9450345, 8.0897385],
                    [112.9436313, 8.0898993],
                    [112.942227, 8.0900503],
                    [112.9408216, 8.0901916],
                    [112.9394153, 8.0903231],
                    [112.9380081, 8.0904448],
                    [112.9366001, 8.0905568],
                    [112.9351913, 8.090659],
                    [112.9337818, 8.0907514],
                    [112.9323717, 8.090834],
                    [112.930961, 8.0909068],
                    [112.9295499, 8.0909698],
                    [112.9281384, 8.091023],
                    [112.9267265, 8.0910665],
                    [112.9253143, 8.0911001],
                    [112.9239019, 8.0911239],
                    [112.9224894, 8.0911379],
                    [112.9210769, 8.0911421],
                    [112.9196643, 8.0911365],
                    [112.9182518, 8.0911211],
                    [112.9168395, 8.0910959],
                    [112.9154274, 8.0910609],
                    [112.9140155, 8.091016],
                    [112.9126041, 8.0909614],
                    [112.911193, 8.090897],
                    [112.9097824, 8.0908228],
                    [112.9083724, 8.0907388],
                    [112.906963, 8.090645],
                    [112.9055543, 8.0905414],
                    [112.9041464, 8.090428],
                    [112.9027393, 8.0903049],
                    [112.9013331, 8.090172],
                    [112.8999279, 8.0900294],
                    [112.8985238, 8.089877],
                    [112.8971207, 8.0897148],
                    [112.8957189, 8.0895429],
                    [112.8943183, 8.0893613],
                    [112.892919, 8.08917],
                    [112.8915211, 8.0889689],
                    [112.8901247, 8.0887582],
                    [112.8887297, 8.0885377],
                    [112.8873364, 8.0883076],
                    [112.8859448, 8.0880678],
                    [112.8845549, 8.0878184],
                    [112.8831667, 8.0875593],
                    [112.8817805, 8.0872906],
                    [112.8803962, 8.0870123],
                    [112.8790139, 8.0867243],
                    [112.8776337, 8.0864268],
                    [112.8762556, 8.0861197],
                    [112.8748797, 8.0858031],
                    [112.8735061, 8.0854768],
                    [112.8721348, 8.0851411],
                    [112.870766, 8.0847959],
                    [112.8693996, 8.0844411],
                    [112.8680358, 8.0840769],
                    [112.8666746, 8.0837032],
                    [112.865316, 8.0833201],
                    [112.8639602, 8.0829276],
                    [112.8626073, 8.0825256],
                    [112.8612572, 8.0821143],
                    [112.85991, 8.0816936],
                    [112.8585659, 8.0812635],
                    [112.8572249, 8.0808242],
                    [112.855887, 8.0803755],
                    [112.8545523, 8.0799176],
                    [112.8532208, 8.0794504],
                    [112.8518928, 8.078974],
                    [112.8505681, 8.0784883],
                    [112.8492469, 8.0779935],
                    [112.8479292, 8.0774895],
                    [112.8466152, 8.0769764],
                    [112.8453048, 8.0764541],
                    [112.8439981, 8.0759228],
                    [112.8426953, 8.0753825],
                    [112.8413962, 8.074833],
                    [112.8401012, 8.0742746],
                    [112.8388101, 8.0737072],
                    [112.837523, 8.0731309],
                    [112.8362401, 8.0725456],
                    [112.8349613, 8.0719514],
                    [112.8336868, 8.0713484],
                    [112.8324166, 8.0707366],
                    [112.8311508, 8.0701159],
                    [112.8298893, 8.0694864],
                    [112.8286324, 8.0688483],
                    [112.82738, 8.0682014],
                    [112.8261322, 8.0675458],
                    [112.8248892, 8.0668816],
                    [112.8236508, 8.0662087],
                    [112.8224172, 8.0655273],
                    [112.8211885, 8.0648373],
                    [112.8199647, 8.0641388],
                    [112.8187459, 8.0634319],
                    [112.8175321, 8.0627164],
                    [112.8163235, 8.0619926],
                    [112.8151199, 8.0612604],
                    [112.8139216, 8.0605199],
                    [112.8127286, 8.059771],
                    [112.8115409, 8.0590139],
                    [112.8103586, 8.0582486],
                    [112.8091817, 8.057475],
                    [112.8080104, 8.0566933],
                    [112.8068446, 8.0559035],
                    [112.8056844, 8.0551057],
                    [112.8045299, 8.0542997],
                    [112.8033812, 8.0534858],
                    [112.8022382, 8.052664],
                    [112.8011011, 8.0518342],
                    [112.7999699, 8.0509965],
                    [112.7988447, 8.050151],
                    [112.7977254, 8.0492977],
                    [112.7966123, 8.0484367],
                    [112.7955052, 8.0475679],
                    [112.7944044, 8.0466915],
                    [112.7933098, 8.0458074],
                    [112.7922214, 8.0449158],
                    [112.7911394, 8.0440166],
                    [112.7900638, 8.04311],
                    [112.7889947, 8.0421959],
                    [112.7879321, 8.0412744],
                    [112.786876, 8.0403455],
                    [112.7858265, 8.0394093],
                    [112.7847836, 8.0384659],
                    [112.7837475, 8.0375152],
                    [112.7827181, 8.0365574],
                    [112.7816956, 8.0355924],
                    [112.7806799, 8.0346204],
                    [112.7796711, 8.0336413],
                    [112.7786692, 8.0326552],
                    [112.7776744, 8.0316622],
                    [112.7766867, 8.0306624],
                    [112.775706, 8.0296556],
                    [112.7747325, 8.0286421],
                    [112.7737662, 8.0276219],
                    [112.7728071, 8.026595],
                    [112.7718553, 8.0255614],
                    [112.7709109, 8.0245213],
                    [112.7699739, 8.0234746],
                    [112.7690443, 8.0224214],
                    [112.7681222, 8.0213618],
                    [112.7672076, 8.0202958],
                    [112.7663006, 8.0192235],
                    [112.7654012, 8.0181449],
                    [112.7645094, 8.0170601],
                    [112.7636254, 8.0159692],
                    [112.7627491, 8.0148721],
                    [112.7618806, 8.0137689],
                    [112.7610199, 8.0126598],
                    [112.7601671, 8.0115447],
                    [112.7593223, 8.0104237],
                    [112.7584854, 8.0092968],
                    [112.7576564, 8.0081642],
                    [112.7568356, 8.0070258],
                    [112.7560228, 8.0058818],
                    [112.7552181, 8.0047321],
                    [112.7544216, 8.0035769],
                    [112.7536333, 8.0024162],
                    [112.7528532, 8.00125],
                    [112.7520814, 8.0000784],
                    [112.751318, 7.9989015],
                    [112.7505628, 7.9977194],
                    [112.7498161, 7.996532],
                    [112.7490778, 7.9953394],
                    [112.748348, 7.9941417],
                    [112.7476266, 7.9929391],
                    [112.7469138, 7.9917314],
                    [112.7462096, 7.9905188],
                    [112.7455139, 7.9893013],
                    [112.7448269, 7.988079],
                    [112.7441486, 7.986852],
                    [112.743479, 7.9856203],
                    [112.7428181, 7.984384],
                    [112.742166, 7.9831431],
                    [112.7415227, 7.9818977],
                    [112.7408882, 7.9806478],
                    [112.7402626, 7.9793936],
                    [112.7396459, 7.9781351],
                    [112.7390381, 7.9768723],
                    [112.7384393, 7.9756053],
                    [112.7378494, 7.9743342],
                    [112.7372686, 7.9730591],
                    [112.7366968, 7.9717799],
                    [112.7361341, 7.9704967],
                    [112.7355805, 7.9692097],
                    [112.735036, 7.9679189],
                    [112.7345007, 7.9666243],
                    [112.7339745, 7.9653261],
                    [112.7334576, 7.9640242],
                    [112.7329498, 7.9627187],
                    [112.7324514, 7.9614097],
                    [112.7319622, 7.9600974],
                    [112.7314823, 7.9587816],
                    [112.7310118, 7.9574625],
                    [112.7305506, 7.9561402],
                    [112.7300988, 7.9548147],
                    [112.7296563, 7.9534862],
                    [112.7292233, 7.9521545],
                    [112.7287998, 7.9508199],
                    [112.7283856, 7.9494824],
                    [112.727981, 7.948142],
                    [112.7275859, 7.9467989],
                    [112.7272003, 7.945453],
                    [112.7268242, 7.9441045],
                    [112.7264577, 7.9427534],
                    [112.7261008, 7.9413998],
                    [112.7257534, 7.9400437],
                    [112.7254157, 7.9386852],
                    [112.7250876, 7.9373245],
                    [112.7247691, 7.9359614],
                    [112.7244603, 7.9345962],
                    [112.7241612, 7.9332289],
                    [112.7238717, 7.9318596],
                    [112.723592, 7.9304882],
                    [112.723322, 7.929115],
                    [112.7230617, 7.9277398],
                    [112.7228111, 7.926363],
                    [112.7225703, 7.9249844],
                    [112.7223393, 7.9236041],
                    [112.722118, 7.9222223],
                    [112.7219066, 7.920839],
                    [112.7217049, 7.9194542],
                    [112.721513, 7.9180681],
                    [112.721331, 7.9166807],
                    [112.7211588, 7.915292],
                    [112.7209964, 7.9139021],
                    [112.7208439, 7.9125112],
                    [112.7207012, 7.9111192],
                    [112.7205684, 7.9097263],
                    [112.7204454, 7.9083325],
                    [112.7203323, 7.9069379],
                    [112.7202291, 7.9055425],
                    [112.7201358, 7.9041464],
                    [112.7200524, 7.9027497],
                    [112.7199788, 7.9013524],
                    [112.7199152, 7.8999547],
                    [112.7198614, 7.8985565],
                    [112.7198175, 7.897158],
                    [112.7197836, 7.8957592],
                    [112.7197595, 7.8943603],
                    [112.7197454, 7.8929612],
                    [112.7197411, 7.891562],
                ],
            ],
            [
                [
                    [113.4513068, 8.9585797],
                    [113.4513154, 8.9571798],
                    [113.4513338, 8.9557799],
                    [113.4513622, 8.9543802],
                    [113.4514005, 8.9529808],
                    [113.4514488, 8.9515816],
                    [113.451507, 8.9501828],
                    [113.4515751, 8.9487844],
                    [113.4516532, 8.9473866],
                    [113.4517411, 8.9459893],
                    [113.451839, 8.9445926],
                    [113.4519468, 8.9431966],
                    [113.4520645, 8.9418014],
                    [113.4521921, 8.9404071],
                    [113.4523296, 8.9390137],
                    [113.452477, 8.9376212],
                    [113.4526343, 8.9362298],
                    [113.4528015, 8.9348396],
                    [113.4529785, 8.9334505],
                    [113.4531653, 8.9320626],
                    [113.453362, 8.9306761],
                    [113.4535686, 8.929291],
                    [113.453785, 8.9279074],
                    [113.4540112, 8.9265252],
                    [113.4542472, 8.9251447],
                    [113.4544929, 8.9237658],
                    [113.4547485, 8.9223887],
                    [113.4550139, 8.9210133],
                    [113.455289, 8.9196399],
                    [113.4555738, 8.9182683],
                    [113.4558684, 8.9168988],
                    [113.4561727, 8.9155313],
                    [113.4564866, 8.914166],
                    [113.4568103, 8.9128029],
                    [113.4571436, 8.911442],
                    [113.4574866, 8.9100835],
                    [113.4578392, 8.9087274],
                    [113.4582015, 8.9073737],
                    [113.4585733, 8.9060226],
                    [113.4589547, 8.9046741],
                    [113.4593457, 8.9033283],
                    [113.4597462, 8.9019852],
                    [113.4601563, 8.9006449],
                    [113.4605758, 8.8993075],
                    [113.4610049, 8.897973],
                    [113.4614434, 8.8966415],
                    [113.4618913, 8.8953131],
                    [113.4623486, 8.8939878],
                    [113.4628154, 8.8926657],
                    [113.4632915, 8.8913469],
                    [113.463777, 8.8900313],
                    [113.4642718, 8.8887192],
                    [113.4647759, 8.8874106],
                    [113.4652892, 8.8861054],
                    [113.4658118, 8.8848039],
                    [113.4663437, 8.883506],
                    [113.4668847, 8.8822118],
                    [113.467435, 8.8809213],
                    [113.4679943, 8.8796348],
                    [113.4685628, 8.8783521],
                    [113.4691404, 8.8770734],
                    [113.469727, 8.8757987],
                    [113.4703227, 8.8745281],
                    [113.4709273, 8.8732617],
                    [113.471541, 8.8719994],
                    [113.4721636, 8.8707415],
                    [113.4727951, 8.8694879],
                    [113.4734354, 8.8682387],
                    [113.4740847, 8.8669939],
                    [113.4747427, 8.8657537],
                    [113.4754095, 8.8645181],
                    [113.4760851, 8.8632871],
                    [113.4767694, 8.8620609],
                    [113.4774624, 8.8608393],
                    [113.4781641, 8.8596227],
                    [113.4788743, 8.8584109],
                    [113.4795932, 8.857204],
                    [113.4803206, 8.8560022],
                    [113.4810565, 8.8548054],
                    [113.4818008, 8.8536138],
                    [113.4825537, 8.8524273],
                    [113.4833149, 8.8512461],
                    [113.4840845, 8.8500702],
                    [113.4848624, 8.8488996],
                    [113.4856486, 8.8477344],
                    [113.486443, 8.8465748],
                    [113.4872457, 8.8454206],
                    [113.4880565, 8.844272],
                    [113.4888755, 8.8431291],
                    [113.4897025, 8.8419919],
                    [113.4905376, 8.8408604],
                    [113.4913807, 8.8397348],
                    [113.4922318, 8.838615],
                    [113.4930908, 8.8375011],
                    [113.4939577, 8.8363932],
                    [113.4948324, 8.8352914],
                    [113.495715, 8.8341956],
                    [113.4966053, 8.833106],
                    [113.4975033, 8.8320225],
                    [113.4984089, 8.8309453],
                    [113.4993222, 8.8298744],
                    [113.5002431, 8.8288099],
                    [113.5011714, 8.8277517],
                    [113.5021073, 8.8267001],
                    [113.5030506, 8.8256549],
                    [113.5040014, 8.8246163],
                    [113.5049594, 8.8235842],
                    [113.5059248, 8.8225589],
                    [113.5068974, 8.8215403],
                    [113.5078772, 8.8205284],
                    [113.5088642, 8.8195233],
                    [113.5098583, 8.8185251],
                    [113.5108594, 8.8175338],
                    [113.5118675, 8.8165495],
                    [113.5128827, 8.8155722],
                    [113.5139047, 8.8146019],
                    [113.5149336, 8.8136388],
                    [113.5159692, 8.8126827],
                    [113.5170117, 8.8117339],
                    [113.5180608, 8.8107924],
                    [113.5191167, 8.8098581],
                    [113.5201791, 8.8089311],
                    [113.521248, 8.8080116],
                    [113.5223235, 8.8070994],
                    [113.5234054, 8.8061948],
                    [113.5244937, 8.8052976],
                    [113.5255884, 8.8044081],
                    [113.5266893, 8.8035261],
                    [113.5277965, 8.8026518],
                    [113.5289098, 8.8017851],
                    [113.5300293, 8.8009262],
                    [113.5311548, 8.8000751],
                    [113.5322863, 8.7992318],
                    [113.5334238, 8.7983964],
                    [113.5345672, 8.7975688],
                    [113.5357165, 8.7967492],
                    [113.5368715, 8.7959376],
                    [113.5380323, 8.795134],
                    [113.5391987, 8.7943385],
                    [113.5403707, 8.793551],
                    [113.5415483, 8.7927718],
                    [113.5427314, 8.7920006],
                    [113.54392, 8.7912377],
                    [113.5451139, 8.7904831],
                    [113.5463132, 8.7897367],
                    [113.5475177, 8.7889987],
                    [113.5487275, 8.7882691],
                    [113.5499423, 8.7875478],
                    [113.5511623, 8.786835],
                    [113.5523873, 8.7861306],
                    [113.5536173, 8.7854348],
                    [113.5548521, 8.7847475],
                    [113.5560919, 8.7840687],
                    [113.5573364, 8.7833986],
                    [113.5585856, 8.7827371],
                    [113.5598395, 8.7820843],
                    [113.561098, 8.7814402],
                    [113.562361, 8.7808048],
                    [113.5636285, 8.7801782],
                    [113.5649005, 8.7795604],
                    [113.5661767, 8.7789514],
                    [113.5674573, 8.7783513],
                    [113.5687421, 8.7777601],
                    [113.570031, 8.7771778],
                    [113.5713241, 8.7766044],
                    [113.5726212, 8.77604],
                    [113.5739223, 8.7754846],
                    [113.5752272, 8.7749383],
                    [113.5765361, 8.7744009],
                    [113.5778487, 8.7738727],
                    [113.579165, 8.7733536],
                    [113.5804849, 8.7728436],
                    [113.5818085, 8.7723428],
                    [113.5831356, 8.7718512],
                    [113.5844661, 8.7713688],
                    [113.5858, 8.7708956],
                    [113.5871373, 8.7704316],
                    [113.5884778, 8.769977],
                    [113.5898215, 8.7695316],
                    [113.5911683, 8.7690956],
                    [113.5925182, 8.7686689],
                    [113.5938711, 8.7682516],
                    [113.5952269, 8.7678436],
                    [113.5965855, 8.7674451],
                    [113.597947, 8.767056],
                    [113.5993112, 8.7666763],
                    [113.600678, 8.7663061],
                    [113.6020475, 8.7659454],
                    [113.6034194, 8.7655942],
                    [113.6047939, 8.7652525],
                    [113.6061707, 8.7649203],
                    [113.6075498, 8.7645977],
                    [113.6089312, 8.7642846],
                    [113.6103148, 8.7639812],
                    [113.6117005, 8.7636873],
                    [113.6130883, 8.763403],
                    [113.614478, 8.7631284],
                    [113.6158697, 8.7628634],
                    [113.6172632, 8.762608],
                    [113.6186584, 8.7623624],
                    [113.6200554, 8.7621264],
                    [113.621454, 8.7619],
                    [113.6228542, 8.7616834],
                    [113.6242559, 8.7614765],
                    [113.6256591, 8.7612793],
                    [113.6270636, 8.7610918],
                    [113.6284693, 8.7609141],
                    [113.6298764, 8.7607461],
                    [113.6312845, 8.7605879],
                    [113.6326938, 8.7604394],
                    [113.6341041, 8.7603008],
                    [113.6355153, 8.7601718],
                    [113.6369274, 8.7600527],
                    [113.6383403, 8.7599434],
                    [113.639754, 8.7598438],
                    [113.6411683, 8.7597541],
                    [113.6425833, 8.7596741],
                    [113.6439987, 8.759604],
                    [113.6454147, 8.7595437],
                    [113.646831, 8.7594932],
                    [113.6482476, 8.7594525],
                    [113.6496645, 8.7594216],
                    [113.6510816, 8.7594006],
                    [113.6524988, 8.7593894],
                    [113.6539161, 8.759388],
                    [113.6553333, 8.7593964],
                    [113.6567504, 8.7594146],
                    [113.6581674, 8.7594427],
                    [113.6595841, 8.7594806],
                    [113.6610005, 8.7595283],
                    [113.6624166, 8.7595858],
                    [113.6638322, 8.7596531],
                    [113.6652473, 8.7597303],
                    [113.6666618, 8.7598172],
                    [113.6680757, 8.7599139],
                    [113.6694888, 8.7600205],
                    [113.6709012, 8.7601368],
                    [113.6723126, 8.7602629],
                    [113.6737232, 8.7603988],
                    [113.6751328, 8.7605445],
                    [113.6765413, 8.7606999],
                    [113.6779486, 8.7608651],
                    [113.6793548, 8.7610401],
                    [113.6807596, 8.7612248],
                    [113.6821632, 8.7614192],
                    [113.6835653, 8.7616233],
                    [113.6849659, 8.7618372],
                    [113.686365, 8.7620607],
                    [113.6877624, 8.762294],
                    [113.6891582, 8.7625369],
                    [113.6905522, 8.7627895],
                    [113.6919444, 8.7630517],
                    [113.6933347, 8.7633236],
                    [113.694723, 8.7636051],
                    [113.6961093, 8.7638963],
                    [113.6974935, 8.764197],
                    [113.6988755, 8.7645073],
                    [113.7002553, 8.7648272],
                    [113.7016328, 8.7651566],
                    [113.7030079, 8.7654956],
                    [113.7043806, 8.7658441],
                    [113.7057508, 8.7662021],
                    [113.7070117, 8.7664998],
                    [113.7082708, 8.7668046],
                    [113.7095281, 8.7671166],
                    [113.7107836, 8.7674358],
                    [113.7120372, 8.767762],
                    [113.7133071, 8.7681003],
                    [113.7145751, 8.7684459],
                    [113.7158409, 8.7687988],
                    [113.7171047, 8.7691589],
                    [113.7183663, 8.7695264],
                    [113.7243249, 8.7711179],
                    [113.7266715, 8.7717378],
                    [113.7290159, 8.7723656],
                    [113.7313755, 8.7730099],
                    [113.731768, 8.7717885],
                    [113.7322065, 8.7704566],
                    [113.7326544, 8.7691277],
                    [113.7331118, 8.7678019],
                    [113.7335785, 8.7664794],
                    [113.7340547, 8.7651601],
                    [113.7345401, 8.7638442],
                    [113.7350349, 8.7625316],
                    [113.735539, 8.7612225],
                    [113.7360524, 8.7599169],
                    [113.736575, 8.7586149],
                    [113.7371069, 8.7573165],
                    [113.7376479, 8.7560219],
                    [113.7381981, 8.754731],
                    [113.7387575, 8.753444],
                    [113.739326, 8.7521609],
                    [113.7399035, 8.7508818],
                    [113.7404902, 8.7496066],
                    [113.7410858, 8.7483356],
                    [113.7416905, 8.7470687],
                    [113.7423041, 8.7458061],
                    [113.7429267, 8.7445477],
                    [113.7435582, 8.7432937],
                    [113.7441986, 8.7420441],
                    [113.7448478, 8.7407989],
                    [113.7455059, 8.7395583],
                    [113.7461727, 8.7383222],
                    [113.7468483, 8.7370908],
                    [113.7475326, 8.7358641],
                    [113.7482256, 8.7346422],
                    [113.7489272, 8.7334251],
                    [113.7496375, 8.7322129],
                    [113.7503563, 8.7310056],
                    [113.7510837, 8.7298034],
                    [113.7518196, 8.7286062],
                    [113.752564, 8.7274141],
                    [113.7533168, 8.7262273],
                    [113.754078, 8.7250457],
                    [113.7548476, 8.7238693],
                    [113.7556255, 8.7226984],
                    [113.7564117, 8.7215328],
                    [113.7572062, 8.7203727],
                    [113.7580088, 8.7192182],
                    [113.7588197, 8.7180692],
                    [113.7596386, 8.7169259],
                    [113.7604657, 8.7157883],
                    [113.7613008, 8.7146565],
                    [113.7621439, 8.7135304],
                    [113.762995, 8.7124103],
                    [113.763854, 8.711296],
                    [113.7647209, 8.7101878],
                    [113.7655956, 8.7090855],
                    [113.7664781, 8.7079894],
                    [113.7673684, 8.7068994],
                    [113.7682664, 8.7058156],
                    [113.7691721, 8.704738],
                    [113.7700854, 8.7036667],
                    [113.7710062, 8.7026018],
                    [113.7719346, 8.7015433],
                    [113.7728705, 8.7004913],
                    [113.7738138, 8.6994457],
                    [113.7747645, 8.6984068],
                    [113.7757226, 8.6973744],
                    [113.7766879, 8.6963487],
                    [113.7776605, 8.6953297],
                    [113.7786404, 8.6943175],
                    [113.7796273, 8.6933121],
                    [113.7806214, 8.6923136],
                    [113.7816226, 8.691322],
                    [113.7826307, 8.6903373],
                    [113.7836458, 8.6893596],
                    [113.7846678, 8.688389],
                    [113.7856967, 8.6874255],
                    [113.7867324, 8.6864692],
                    [113.7877749, 8.6855201],
                    [113.788824, 8.6845782],
                    [113.7898798, 8.6836436],
                    [113.7909422, 8.6827163],
                    [113.7920112, 8.6817965],
                    [113.7930867, 8.680884],
                    [113.7941686, 8.6799791],
                    [113.7952569, 8.6790816],
                    [113.7963515, 8.6781917],
                    [113.7974525, 8.6773095],
                    [113.7985596, 8.6764348],
                    [113.799673, 8.6755679],
                    [113.8007924, 8.6747087],
                    [113.8019179, 8.6738573],
                    [113.8030495, 8.6730137],
                    [113.804187, 8.672178],
                    [113.8053304, 8.6713502],
                    [113.8064796, 8.6705303],
                    [113.8076347, 8.6697184],
                    [113.8087954, 8.6689145],
                    [113.8099618, 8.6681187],
                    [113.8111339, 8.667331],
                    [113.8123115, 8.6665515],
                    [113.8134946, 8.6657801],
                    [113.8146832, 8.665017],
                    [113.8158771, 8.6642621],
                    [113.8170764, 8.6635154],
                    [113.8182809, 8.6627772],
                    [113.8194906, 8.6620473],
                    [113.8207055, 8.6613258],
                    [113.8219255, 8.6606127],
                    [113.8231505, 8.6599081],
                    [113.8243804, 8.659212],
                    [113.8256153, 8.6585245],
                    [113.826855, 8.6578455],
                    [113.8280995, 8.6571752],
                    [113.8293488, 8.6565135],
                    [113.8306026, 8.6558604],
                    [113.8318611, 8.6552161],
                    [113.8331242, 8.6545805],
                    [113.8343917, 8.6539537],
                    [113.8356636, 8.6533357],
                    [113.8369399, 8.6527265],
                    [113.8382205, 8.6521262],
                    [113.8395052, 8.6515347],
                    [113.8407942, 8.6509522],
                    [113.8420873, 8.6503787],
                    [113.8433844, 8.6498141],
                    [113.8446854, 8.6492585],
                    [113.8459904, 8.648712],
                    [113.8472992, 8.6481745],
                    [113.8486118, 8.6476461],
                    [113.8499281, 8.6471268],
                    [113.8512481, 8.6466166],
                    [113.8525717, 8.6461156],
                    [113.8538987, 8.6456238],
                    [113.8552293, 8.6451413],
                    [113.8565632, 8.6446679],
                    [113.8579004, 8.6442038],
                    [113.8592409, 8.643749],
                    [113.8605846, 8.6433035],
                    [113.8619315, 8.6428673],
                    [113.8632813, 8.6424405],
                    [113.8646342, 8.642023],
                    [113.86599, 8.6416149],
                    [113.8673487, 8.6412163],
                    [113.8687102, 8.640827],
                    [113.8700743, 8.6404472],
                    [113.8714412, 8.6400769],
                    [113.8728106, 8.6397161],
                    [113.8741826, 8.6393647],
                    [113.875557, 8.6390229],
                    [113.8769339, 8.6386906],
                    [113.878313, 8.6383679],
                    [113.8796944, 8.6380548],
                    [113.881078, 8.6377512],
                    [113.8824637, 8.6374572],
                    [113.8838514, 8.6371728],
                    [113.8852412, 8.6368981],
                    [113.8866328, 8.636633],
                    [113.8880263, 8.6363776],
                    [113.8894216, 8.6361318],
                    [113.8908186, 8.6358957],
                    [113.8922172, 8.6356693],
                    [113.8936174, 8.6354526],
                    [113.8950191, 8.6352457],
                    [113.8964222, 8.6350484],
                    [113.8978267, 8.6348609],
                    [113.8992325, 8.6346831],
                    [113.9006395, 8.634515],
                    [113.9020477, 8.6343568],
                    [113.903457, 8.6342083],
                    [113.9048672, 8.6340695],
                    [113.9062785, 8.6339406],
                    [113.9076906, 8.6338214],
                    [113.9091035, 8.633712],
                    [113.9105172, 8.6336124],
                    [113.9119315, 8.6335227],
                    [113.9133464, 8.6334427],
                    [113.914773, 8.6333721],
                    [113.9152213, 8.6329581],
                    [113.9162637, 8.6320088],
                    [113.9173129, 8.6310668],
                    [113.9183687, 8.6301321],
                    [113.9194311, 8.6292047],
                    [113.9205001, 8.6282847],
                    [113.9215755, 8.6273721],
                    [113.9226574, 8.626467],
                    [113.9237457, 8.6255694],
                    [113.9248404, 8.6246794],
                    [113.9259413, 8.623797],
                    [113.9270485, 8.6229223],
                    [113.9281618, 8.6220552],
                    [113.9292813, 8.6211959],
                    [113.9304068, 8.6203444],
                    [113.9315383, 8.6195007],
                    [113.9326758, 8.6186648],
                    [113.9338192, 8.6178369],
                    [113.9349685, 8.6170169],
                    [113.9361235, 8.6162049],
                    [113.9372843, 8.6154009],
                    [113.9384507, 8.614605],
                    [113.9396227, 8.6138172],
                    [113.9408003, 8.6130375],
                    [113.9419835, 8.612266],
                    [113.943172, 8.6115028],
                    [113.9443659, 8.6107478],
                    [113.9455652, 8.6100011],
                    [113.9467697, 8.6092627],
                    [113.9479795, 8.6085327],
                    [113.9491943, 8.6078111],
                    [113.9504143, 8.6070979],
                    [113.9516393, 8.6063932],
                    [113.9528693, 8.605697],
                    [113.9541042, 8.6050094],
                    [113.9553439, 8.6043303],
                    [113.9565884, 8.6036599],
                    [113.9578376, 8.6029981],
                    [113.9590915, 8.602345],
                    [113.96035, 8.6017005],
                    [113.961613, 8.6010649],
                    [113.9628805, 8.6004379],
                    [113.9641525, 8.5998198],
                    [113.9654287, 8.5992106],
                    [113.9667093, 8.5986102],
                    [113.9679941, 8.5980187],
                    [113.9692831, 8.5974361],
                    [113.9705761, 8.5968624],
                    [113.9718732, 8.5962978],
                    [113.9731743, 8.5957421],
                    [113.9744793, 8.5951955],
                    [113.9757881, 8.5946579],
                    [113.9771007, 8.5941294],
                    [113.978417, 8.5936101],
                    [113.979737, 8.5930999],
                    [113.9810605, 8.5925988],
                    [113.9823876, 8.5921069],
                    [113.9837181, 8.5916243],
                    [113.985052, 8.5911508],
                    [113.9863893, 8.5906867],
                    [113.9877298, 8.5902318],
                    [113.9890735, 8.5897862],
                    [113.9904203, 8.58935],
                    [113.9917702, 8.5889231],
                    [113.9931231, 8.5885056],
                    [113.9944789, 8.5880974],
                    [113.9958375, 8.5876987],
                    [113.997199, 8.5873094],
                    [113.9985632, 8.5869296],
                    [113.99993, 8.5865592],
                    [114.0012995, 8.5861983],
                    [114.0026715, 8.5858469],
                    [114.0040459, 8.5855051],
                    [114.0054227, 8.5851727],
                    [114.0068018, 8.58485],
                    [114.0081832, 8.5845368],
                    [114.0095668, 8.5842331],
                    [114.0109525, 8.5839391],
                    [114.0123403, 8.5836547],
                    [114.01373, 8.58338],
                    [114.0151217, 8.5831148],
                    [114.0165152, 8.5828594],
                    [114.0179104, 8.5826136],
                    [114.0193074, 8.5823774],
                    [114.020706, 8.582151],
                    [114.0221062, 8.5819343],
                    [114.0235079, 8.5817273],
                    [114.0249111, 8.58153],
                    [114.0263156, 8.5813424],
                    [114.0277214, 8.5811646],
                    [114.0291284, 8.5809966],
                    [114.0305365, 8.5808383],
                    [114.0319458, 8.5806897],
                    [114.0333561, 8.580551],
                    [114.033806, 8.5805098],
                    [114.0347493, 8.5794713],
                    [114.0357074, 8.5784386],
                    [114.0366727, 8.5774126],
                    [114.0376454, 8.5763933],
                    [114.0386252, 8.5753808],
                    [114.0396121, 8.574375],
                    [114.0406062, 8.5733762],
                    [114.0416074, 8.5723843],
                    [114.0426155, 8.5713993],
                    [114.0436306, 8.5704213],
                    [114.0446526, 8.5694504],
                    [114.0456815, 8.5684866],
                    [114.0467172, 8.56753],
                    [114.0477597, 8.5665805],
                    [114.0488088, 8.5656384],
                    [114.0498646, 8.5647035],
                    [114.050927, 8.5637759],
                    [114.051996, 8.5628558],
                    [114.0530715, 8.561943],
                    [114.0541534, 8.5610378],
                    [114.0552417, 8.56014],
                    [114.0563363, 8.5592499],
                    [114.0574373, 8.5583673],
                    [114.0585444, 8.5574924],
                    [114.0596578, 8.5566252],
                    [114.0607772, 8.5557658],
                    [114.0619028, 8.5549141],
                    [114.0630343, 8.5540702],
                    [114.0641718, 8.5532343],
                    [114.0653152, 8.5524062],
                    [114.0664644, 8.551586],
                    [114.0676195, 8.5507739],
                    [114.0687802, 8.5499698],
                    [114.0699467, 8.5491737],
                    [114.0711187, 8.5483858],
                    [114.0722963, 8.547606],
                    [114.0734794, 8.5468344],
                    [114.074668, 8.546071],
                    [114.0758619, 8.5453158],
                    [114.0770612, 8.544569],
                    [114.0782657, 8.5438305],
                    [114.0794754, 8.5431004],
                    [114.0806903, 8.5423786],
                    [114.0819103, 8.5416653],
                    [114.0831353, 8.5409605],
                    [114.0843652, 8.5402642],
                    [114.0856001, 8.5395765],
                    [114.0868398, 8.5388973],
                    [114.0880843, 8.5382267],
                    [114.0893336, 8.5375648],
                    [114.0905875, 8.5369116],
                    [114.091846, 8.536267],
                    [114.093109, 8.5356312],
                    [114.0943765, 8.5350042],
                    [114.0956484, 8.534386],
                    [114.0969247, 8.5337766],
                    [114.0982053, 8.5331761],
                    [114.0994901, 8.5325845],
                    [114.100779, 8.5320018],
                    [114.1020721, 8.5314281],
                    [114.1033692, 8.5308633],
                    [114.1046702, 8.5303076],
                    [114.1059752, 8.5297609],
                    [114.107284, 8.5292232],
                    [114.1085966, 8.5286946],
                    [114.109913, 8.5281752],
                    [114.1112329, 8.5276649],
                    [114.1125565, 8.5271637],
                    [114.1138836, 8.5266718],
                    [114.1152141, 8.526189],
                    [114.116548, 8.5257155],
                    [114.1178852, 8.5252513],
                    [114.1192257, 8.5247963],
                    [114.1205694, 8.5243507],
                    [114.1219163, 8.5239144],
                    [114.1232662, 8.5234874],
                    [114.124619, 8.5230698],
                    [114.1259748, 8.5226616],
                    [114.1273335, 8.5222628],
                    [114.128695, 8.5218735],
                    [114.1300592, 8.5214935],
                    [114.131426, 8.5211231],
                    [114.1327955, 8.5207621],
                    [114.1341674, 8.5204107],
                    [114.1355419, 8.5200688],
                    [114.1369187, 8.5197364],
                    [114.1382978, 8.5194136],
                    [114.1396792, 8.5191003],
                    [114.1410628, 8.5187966],
                    [114.1424485, 8.5185026],
                    [114.1438362, 8.5182181],
                    [114.145226, 8.5179433],
                    [114.1466176, 8.5176781],
                    [114.1480111, 8.5174226],
                    [114.1494064, 8.5171768],
                    [114.1508034, 8.5169406],
                    [114.152202, 8.5167141],
                    [114.1536022, 8.5164974],
                    [114.1550039, 8.5162903],
                    [114.156407, 8.516093],
                    [114.1578115, 8.5159054],
                    [114.1592173, 8.5157276],
                    [114.1606243, 8.5155595],
                    [114.1620325, 8.5154012],
                    [114.1634418, 8.5152526],
                    [114.1648521, 8.5151138],
                    [114.1662633, 8.5149848],
                    [114.1676754, 8.5148656],
                    [114.1690883, 8.5147562],
                    [114.170502, 8.5146566],
                    [114.1719163, 8.5145668],
                    [114.1733312, 8.5144868],
                    [114.1747467, 8.5144166],
                    [114.1761626, 8.5143563],
                    [114.177579, 8.5143057],
                    [114.1789956, 8.514265],
                    [114.1804125, 8.5142341],
                    [114.1818296, 8.5142131],
                    [114.1832468, 8.5142018],
                    [114.1846641, 8.5142004],
                    [114.1860813, 8.5142089],
                    [114.1874984, 8.5142271],
                    [114.1889154, 8.5142552],
                    [114.1903321, 8.5142931],
                    [114.1917485, 8.5143408],
                    [114.1931646, 8.5143984],
                    [114.1945802, 8.5144658],
                    [114.1959953, 8.514543],
                    [114.1974098, 8.51463],
                    [114.1988236, 8.5147268],
                    [114.2002368, 8.5148334],
                    [114.2016491, 8.5149498],
                    [114.2030606, 8.515076],
                    [114.2044712, 8.515212],
                    [114.2058807, 8.5153577],
                    [114.2072892, 8.5155133],
                    [114.2086966, 8.5156786],
                    [114.2101027, 8.5158536],
                    [114.2115076, 8.5160384],
                    [114.2129111, 8.516233],
                    [114.2143132, 8.5164372],
                    [114.2157139, 8.5166512],
                    [114.217113, 8.5168749],
                    [114.2185104, 8.5171083],
                    [114.2199062, 8.5173514],
                    [114.2213002, 8.5176042],
                    [114.2226924, 8.5178666],
                    [114.2240827, 8.5181386],
                    [114.225471, 8.5184203],
                    [114.2268573, 8.5187117],
                    [114.2282415, 8.5190126],
                    [114.2296235, 8.5193231],
                    [114.2310033, 8.5196432],
                    [114.2323808, 8.5199729],
                    [114.2337559, 8.5203121],
                    [114.2351286, 8.5206608],
                    [114.2364988, 8.521019],
                    [114.2378664, 8.5213868],
                    [114.2392313, 8.521764],
                    [114.2405936, 8.5221506],
                    [114.241953, 8.5225467],
                    [114.2433097, 8.5229522],
                    [114.2446634, 8.5233672],
                    [114.2460141, 8.5237915],
                    [114.2473618, 8.5242251],
                    [114.2487064, 8.5246681],
                    [114.2500479, 8.5251204],
                    [114.251386, 8.525582],
                    [114.2527209, 8.5260528],
                    [114.2540524, 8.5265329],
                    [114.2553805, 8.5270223],
                    [114.2567051, 8.5275208],
                    [114.2580261, 8.5280285],
                    [114.2593434, 8.5285453],
                    [114.2606571, 8.5290713],
                    [114.261967, 8.5296063],
                    [114.2632731, 8.5301505],
                    [114.2645753, 8.5307036],
                    [114.2658735, 8.5312658],
                    [114.2671677, 8.531837],
                    [114.2684579, 8.5324172],
                    [114.2697438, 8.5330062],
                    [114.2710256, 8.5336042],
                    [114.2723031, 8.534211],
                    [114.2735763, 8.5348267],
                    [114.2748451, 8.5354512],
                    [114.2761094, 8.5360845],
                    [114.2773692, 8.5367266],
                    [114.2786244, 8.5373773],
                    [114.279875, 8.5380368],
                    [114.2811208, 8.5387049],
                    [114.2823619, 8.5393816],
                    [114.2835982, 8.5400669],
                    [114.2848296, 8.5407607],
                    [114.286056, 8.5414631],
                    [114.2872774, 8.542174],
                    [114.2884938, 8.5428933],
                    [114.289705, 8.5436211],
                    [114.290911, 8.5443572],
                    [114.2921118, 8.5451016],
                    [114.2933072, 8.5458544],
                    [114.2944973, 8.5466155],
                    [114.295682, 8.5473847],
                    [114.2968612, 8.5481622],
                    [114.2980348, 8.5489478],
                    [114.2992029, 8.5497416],
                    [114.3003652, 8.5505434],
                    [114.3015219, 8.5513532],
                    [114.3026728, 8.5521711],
                    [114.3038179, 8.5529969],
                    [114.3049571, 8.5538306],
                    [114.3060903, 8.5546722],
                    [114.3072176, 8.5555217],
                    [114.3083388, 8.5563789],
                    [114.3094539, 8.5572439],
                    [114.3105628, 8.5581166],
                    [114.3116655, 8.558997],
                    [114.312762, 8.559885],
                    [114.3138521, 8.5607806],
                    [114.3149358, 8.5616837],
                    [114.3160131, 8.5625943],
                    [114.317084, 8.5635123],
                    [114.3181483, 8.5644378],
                    [114.319206, 8.5653705],
                    [114.320257, 8.5663107],
                    [114.3213014, 8.567258],
                    [114.322339, 8.5682126],
                    [114.3233698, 8.5691744],
                    [114.3243938, 8.5701432],
                    [114.3254109, 8.5711192],
                    [114.3264211, 8.5721022],
                    [114.3274242, 8.5730921],
                    [114.3284203, 8.574089],
                    [114.3294093, 8.5750928],
                    [114.3303912, 8.5761034],
                    [114.3313659, 8.5771207],
                    [114.3323333, 8.5781448],
                    [114.3332934, 8.5791756],
                    [114.3342463, 8.580213],
                    [114.3351917, 8.581257],
                    [114.3361297, 8.5823076],
                    [114.3370602, 8.5833646],
                    [114.3379833, 8.584428],
                    [114.3388987, 8.5854978],
                    [114.3398065, 8.5865739],
                    [114.3407067, 8.5876563],
                    [114.3415992, 8.5887448],
                    [114.342484, 8.5898396],
                    [114.3433609, 8.5909404],
                    [114.3442301, 8.5920473],
                    [114.3450913, 8.5931602],
                    [114.3459447, 8.5942791],
                    [114.3467901, 8.5954038],
                    [114.3476275, 8.5965343],
                    [114.3484568, 8.5976707],
                    [114.3492781, 8.5988127],
                    [114.3500913, 8.5999604],
                    [114.3508962, 8.6011137],
                    [114.351693, 8.6022726],
                    [114.3524816, 8.603437],
                    [114.3532619, 8.6046068],
                    [114.3540339, 8.6057819],
                    [114.3547975, 8.6069624],
                    [114.3555527, 8.6081482],
                    [114.3562995, 8.6093391],
                    [114.3570378, 8.6105352],
                    [114.3577676, 8.6117364],
                    [114.3584889, 8.6129426],
                    [114.3592016, 8.6141538],
                    [114.3599058, 8.6153699],
                    [114.3606012, 8.6165908],
                    [114.361288, 8.6178166],
                    [114.3619661, 8.619047],
                    [114.3626354, 8.6202821],
                    [114.363296, 8.6215219],
                    [114.3639477, 8.6227661],
                    [114.3645907, 8.6240149],
                    [114.3652247, 8.6252681],
                    [114.3658498, 8.6265256],
                    [114.366466, 8.6277874],
                    [114.3670733, 8.6290535],
                    [114.3676715, 8.6303238],
                    [114.3682607, 8.6315981],
                    [114.3688409, 8.6328765],
                    [114.3694119, 8.6341589],
                    [114.3699739, 8.6354452],
                    [114.3705267, 8.6367354],
                    [114.3710704, 8.6380294],
                    [114.3716049, 8.6393271],
                    [114.3721302, 8.6406285],
                    [114.3726462, 8.6419335],
                    [114.3731529, 8.643242],
                    [114.3736504, 8.644554],
                    [114.3741385, 8.6458694],
                    [114.3746173, 8.6471882],
                    [114.3750867, 8.6485102],
                    [114.3755468, 8.6498355],
                    [114.3759974, 8.6511639],
                    [114.3764386, 8.6524954],
                    [114.3768703, 8.6538299],
                    [114.3772926, 8.6551674],
                    [114.3777053, 8.6565077],
                    [114.3781086, 8.6578509],
                    [114.3785023, 8.6591969],
                    [114.3788864, 8.6605455],
                    [114.379261, 8.6618968],
                    [114.379626, 8.6632506],
                    [114.3799814, 8.6646069],
                    [114.3803271, 8.6659656],
                    [114.3806632, 8.6673267],
                    [114.3809896, 8.6686901],
                    [114.3813064, 8.6700557],
                    [114.3816134, 8.6714235],
                    [114.3819108, 8.6727934],
                    [114.3821984, 8.6741653],
                    [114.3824763, 8.6755391],
                    [114.3827444, 8.6769148],
                    [114.3830028, 8.6782924],
                    [114.3832514, 8.6796717],
                    [114.3834902, 8.6810526],
                    [114.3837192, 8.6824352],
                    [114.3839383, 8.6838194],
                    [114.3841477, 8.685205],
                    [114.3843472, 8.686592],
                    [114.3845369, 8.6879804],
                    [114.3847167, 8.6893701],
                    [114.3848867, 8.690761],
                    [114.3850468, 8.692153],
                    [114.385197, 8.693546],
                    [114.3853373, 8.6949401],
                    [114.3854677, 8.6963351],
                    [114.3855883, 8.697731],
                    [114.3856989, 8.6991277],
                    [114.3857996, 8.7005251],
                    [114.3858904, 8.7019231],
                    [114.3859713, 8.7033218],
                    [114.3860423, 8.7047209],
                    [114.3861033, 8.7061205],
                    [114.3861544, 8.7075205],
                    [114.3861956, 8.7089209],
                    [114.3862268, 8.7103214],
                    [114.3862481, 8.7117222],
                    [114.3862595, 8.713123],
                    [114.3862609, 8.7145239],
                    [114.3862524, 8.7159248],
                    [114.3862339, 8.7173255],
                    [114.3862055, 8.7187261],
                    [114.3861672, 8.7201265],
                    [114.3861189, 8.7215265],
                    [114.3860607, 8.7229262],
                    [114.3859926, 8.7243254],
                    [114.3859146, 8.7257241],
                    [114.3858266, 8.7271223],
                    [114.3857287, 8.7285198],
                    [114.3856209, 8.7299165],
                    [114.3855032, 8.7313125],
                    [114.3853756, 8.7327076],
                    [114.3852381, 8.7341018],
                    [114.3850907, 8.7354951],
                    [114.3849334, 8.7368872],
                    [114.3847663, 8.7382782],
                    [114.3845893, 8.7396681],
                    [114.3844024, 8.7410566],
                    [114.3842057, 8.7424438],
                    [114.3839991, 8.7438297],
                    [114.3837828, 8.745214],
                    [114.3835566, 8.7465968],
                    [114.3833206, 8.747978],
                    [114.3830748, 8.7493576],
                    [114.3828192, 8.7507353],
                    [114.3825539, 8.7521113],
                    [114.3822788, 8.7534854],
                    [114.3819939, 8.7548576],
                    [114.3816994, 8.7562277],
                    [114.3813951, 8.7575958],
                    [114.3810811, 8.7589617],
                    [114.3807574, 8.7603254],
                    [114.3804241, 8.7616868],
                    [114.3800811, 8.7630459],
                    [114.3797285, 8.7644025],
                    [114.3793663, 8.7657567],
                    [114.3789944, 8.7671083],
                    [114.378613, 8.7684573],
                    [114.378222, 8.7698036],
                    [114.3778215, 8.7711472],
                    [114.3774114, 8.772488],
                    [114.3769919, 8.7738259],
                    [114.3765629, 8.7751608],
                    [114.3761244, 8.7764927],
                    [114.3756764, 8.7778216],
                    [114.3752191, 8.7791473],
                    [114.3747523, 8.7804698],
                    [114.3742762, 8.781789],
                    [114.3737908, 8.7831049],
                    [114.373296, 8.7844174],
                    [114.3727919, 8.7857264],
                    [114.3722785, 8.7870319],
                    [114.3717559, 8.7883338],
                    [114.371224, 8.7896321],
                    [114.370683, 8.7909266],
                    [114.3701328, 8.7922173],
                    [114.3695734, 8.7935042],
                    [114.3690049, 8.7947872],
                    [114.3684274, 8.7960662],
                    [114.3678407, 8.7973411],
                    [114.3672451, 8.798612],
                    [114.3666404, 8.7998787],
                    [114.3660268, 8.8011412],
                    [114.3654042, 8.8023994],
                    [114.3647727, 8.8036532],
                    [114.3641323, 8.8049026],
                    [114.3634831, 8.8061476],
                    [114.362825, 8.807388],
                    [114.3621582, 8.8086238],
                    [114.3614826, 8.809855],
                    [114.3607983, 8.8110814],
                    [114.3601053, 8.8123031],
                    [114.3594037, 8.8135199],
                    [114.3586934, 8.8147318],
                    [114.3579746, 8.8159388],
                    [114.3572472, 8.8171408],
                    [114.3565113, 8.8183377],
                    [114.3557669, 8.8195295],
                    [114.3550141, 8.820716],
                    [114.3542528, 8.8218974],
                    [114.3534833, 8.8230734],
                    [114.3527053, 8.824244],
                    [114.3519191, 8.8254092],
                    [114.3511247, 8.826569],
                    [114.350322, 8.8277232],
                    [114.3495112, 8.8288718],
                    [114.3486922, 8.8300148],
                    [114.3478652, 8.831152],
                    [114.3470301, 8.8322835],
                    [114.346187, 8.8334092],
                    [114.3453359, 8.834529],
                    [114.3444769, 8.8356429],
                    [114.34361, 8.8367508],
                    [114.3427353, 8.8378526],
                    [114.3418528, 8.8389484],
                    [114.3409625, 8.840038],
                    [114.3400645, 8.8411214],
                    [114.3391588, 8.8421986],
                    [114.3382455, 8.8432695],
                    [114.3373247, 8.844334],
                    [114.3363963, 8.8453921],
                    [114.3354604, 8.8464437],
                    [114.3345171, 8.8474888],
                    [114.3335664, 8.8485274],
                    [114.3326083, 8.8495593],
                    [114.331643, 8.8505846],
                    [114.3306703, 8.8516031],
                    [114.3296905, 8.8526149],
                    [114.3287036, 8.8536199],
                    [114.3277095, 8.854618],
                    [114.3267083, 8.8556092],
                    [114.3257002, 8.8565934],
                    [114.3246851, 8.8575706],
                    [114.3236631, 8.8585408],
                    [114.3226342, 8.8595038],
                    [114.3215985, 8.8604597],
                    [114.320556, 8.8614084],
                    [114.3195069, 8.8623499],
                    [114.3184511, 8.863284],
                    [114.3173887, 8.8642108],
                    [114.3163197, 8.8651302],
                    [114.3152442, 8.8660422],
                    [114.3141623, 8.8669467],
                    [114.313074, 8.8678437],
                    [114.3119794, 8.8687331],
                    [114.3108784, 8.869615],
                    [114.3097713, 8.8704891],
                    [114.3086579, 8.8713556],
                    [114.3075385, 8.8722143],
                    [114.3064129, 8.8730653],
                    [114.3052814, 8.8739084],
                    [114.3041439, 8.8747437],
                    [114.3030005, 8.8755711],
                    [114.3018513, 8.8763905],
                    [114.3006962, 8.8772019],
                    [114.2995355, 8.8780053],
                    [114.298369, 8.8788007],
                    [114.297197, 8.8795879],
                    [114.2960194, 8.8803671],
                    [114.2948363, 8.881138],
                    [114.2936477, 8.8819007],
                    [114.2924538, 8.8826551],
                    [114.2912545, 8.8834013],
                    [114.29005, 8.8841391],
                    [114.2888403, 8.8848686],
                    [114.2876254, 8.8855897],
                    [114.2864054, 8.8863023],
                    [114.2851804, 8.8870065],
                    [114.2839505, 8.8877021],
                    [114.2827156, 8.8883893],
                    [114.2814759, 8.8890678],
                    [114.2802314, 8.8897377],
                    [114.2789821, 8.890399],
                    [114.2777282, 8.8910516],
                    [114.2764697, 8.8916956],
                    [114.2752067, 8.8923308],
                    [114.2739392, 8.8929572],
                    [114.2726673, 8.8935748],
                    [114.271391, 8.8941836],
                    [114.2701104, 8.8947835],
                    [114.2688256, 8.8953745],
                    [114.2675367, 8.8959567],
                    [114.2662436, 8.8965299],
                    [114.2649465, 8.8970941],
                    [114.2636455, 8.8976493],
                    [114.2623405, 8.8981955],
                    [114.2610317, 8.8987326],
                    [114.2597191, 8.8992606],
                    [114.2584027, 8.8997796],
                    [114.2570828, 8.9002894],
                    [114.2557592, 8.90079],
                    [114.2544322, 8.9012815],
                    [114.2531016, 8.9017637],
                    [114.2517677, 8.9022367],
                    [114.2504305, 8.9027005],
                    [114.24909, 8.903155],
                    [114.2477463, 8.9036002],
                    [114.2463994, 8.9040361],
                    [114.2450495, 8.9044626],
                    [114.2436967, 8.9048798],
                    [114.2423409, 8.9052876],
                    [114.2409822, 8.905686],
                    [114.2396207, 8.9060749],
                    [114.2382565, 8.9064544],
                    [114.2368897, 8.9068245],
                    [114.2355202, 8.9071851],
                    [114.2341483, 8.9075362],
                    [114.2327739, 8.9078777],
                    [114.231397, 8.9082098],
                    [114.2300179, 8.9085323],
                    [114.2286365, 8.9088452],
                    [114.2272529, 8.9091485],
                    [114.2258672, 8.9094423],
                    [114.2244795, 8.9097264],
                    [114.2230897, 8.910001],
                    [114.2216981, 8.9102659],
                    [114.2203046, 8.9105211],
                    [114.2189093, 8.9107667],
                    [114.2175123, 8.9110026],
                    [114.2161137, 8.9112289],
                    [114.2147135, 8.9114454],
                    [114.2133118, 8.9116522],
                    [114.2119087, 8.9118493],
                    [114.2105042, 8.9120367],
                    [114.2090984, 8.9122144],
                    [114.2076914, 8.9123823],
                    [114.2062832, 8.9125404],
                    [114.2048739, 8.9126888],
                    [114.2034637, 8.9128275],
                    [114.203021, 8.9128679],
                    [114.2020704, 8.9139062],
                    [114.2011124, 8.9149379],
                    [114.200147, 8.915963],
                    [114.1991744, 8.9169814],
                    [114.1981946, 8.917993],
                    [114.1972076, 8.9189978],
                    [114.1962135, 8.9199957],
                    [114.1952124, 8.9209867],
                    [114.1942042, 8.9219708],
                    [114.1931891, 8.9229478],
                    [114.1921671, 8.9239178],
                    [114.1911382, 8.9248807],
                    [114.1901025, 8.9258364],
                    [114.1890601, 8.9267849],
                    [114.1880109, 8.9277262],
                    [114.1869551, 8.9286602],
                    [114.1858927, 8.9295868],
                    [114.1848237, 8.9305061],
                    [114.1837483, 8.9314179],
                    [114.1826663, 8.9323222],
                    [114.181578, 8.9332191],
                    [114.1804834, 8.9341083],
                    [114.1793825, 8.93499],
                    [114.1782753, 8.935864],
                    [114.177162, 8.9367303],
                    [114.1760425, 8.9375889],
                    [114.174917, 8.9384397],
                    [114.1737854, 8.9392827],
                    [114.1726479, 8.9401178],
                    [114.1715045, 8.940945],
                    [114.1703553, 8.9417643],
                    [114.1692003, 8.9425756],
                    [114.1680395, 8.9433789],
                    [114.1668731, 8.9441741],
                    [114.165701, 8.9449612],
                    [114.1645234, 8.9457402],
                    [114.1633403, 8.946511],
                    [114.1621518, 8.9472735],
                    [114.1609578, 8.9480279],
                    [114.1597586, 8.9487739],
                    [114.158554, 8.9495116],
                    [114.1573443, 8.9502409],
                    [114.1561294, 8.9509619],
                    [114.1549095, 8.9516744],
                    [114.1536845, 8.9523784],
                    [114.1524545, 8.9530739],
                    [114.1512196, 8.9537609],
                    [114.1499799, 8.9544393],
                    [114.1487354, 8.9551092],
                    [114.1474862, 8.9557703],
                    [114.1462323, 8.9564228],
                    [114.1449738, 8.9570667],
                    [114.1445704, 8.9572695],
                    [114.1442347, 8.9576466],
                    [114.1432914, 8.9586914],
                    [114.1423407, 8.9597296],
                    [114.1413826, 8.9607612],
                    [114.1404173, 8.9617862],
                    [114.1394447, 8.9628044],
                    [114.1384649, 8.9638159],
                    [114.1374779, 8.9648206],
                    [114.1364838, 8.9658184],
                    [114.1354827, 8.9668093],
                    [114.1344745, 8.9677932],
                    [114.1334594, 8.9687701],
                    [114.1324374, 8.96974],
                    [114.1314085, 8.9707027],
                    [114.1303728, 8.9716583],
                    [114.1293304, 8.9726067],
                    [114.1282812, 8.9735479],
                    [114.1272254, 8.9744817],
                    [114.126163, 8.9754083],
                    [114.125094, 8.9763274],
                    [114.1240185, 8.9772391],
                    [114.1229366, 8.9781433],
                    [114.1218483, 8.9790401],
                    [114.1207537, 8.9799292],
                    [114.1196528, 8.9808108],
                    [114.1185456, 8.9816847],
                    [114.1174323, 8.9825509],
                    [114.1163128, 8.9834094],
                    [114.1151873, 8.98426],
                    [114.1140557, 8.9851029],
                    [114.1129182, 8.9859379],
                    [114.1117748, 8.9867651],
                    [114.1106256, 8.9875842],
                    [114.1094706, 8.9883954],
                    [114.1083098, 8.9891986],
                    [114.1071434, 8.9899937],
                    [114.1059713, 8.9907807],
                    [114.1047937, 8.9915596],
                    [114.1036106, 8.9923303],
                    [114.1024221, 8.9930928],
                    [114.1012281, 8.993847],
                    [114.1000289, 8.9945929],
                    [114.0988243, 8.9953305],
                    [114.0976146, 8.9960598],
                    [114.0963997, 8.9967806],
                    [114.0951797, 8.997493],
                    [114.0939548, 8.998197],
                    [114.0927248, 8.9988924],
                    [114.0914899, 8.9995793],
                    [114.0902502, 9.0002577],
                    [114.0890057, 9.0009274],
                    [114.0877565, 9.0015885],
                    [114.0865026, 9.0022409],
                    [114.0852441, 9.0028846],
                    [114.083981, 9.0035196],
                    [114.0827135, 9.0041458],
                    [114.0814416, 9.0047633],
                    [114.0801653, 9.0053719],
                    [114.0788848, 9.0059716],
                    [114.0776, 9.0065625],
                    [114.076311, 9.0071444],
                    [114.075018, 9.0077174],
                    [114.0737209, 9.0082815],
                    [114.0724198, 9.0088365],
                    [114.0711148, 9.0093825],
                    [114.069806, 9.0099195],
                    [114.0684934, 9.0104474],
                    [114.0671771, 9.0109662],
                    [114.0658571, 9.0114758],
                    [114.0645336, 9.0119763],
                    [114.0632065, 9.0124676],
                    [114.061876, 9.0129497],
                    [114.060542, 9.0134226],
                    [114.0592048, 9.0138862],
                    [114.0578643, 9.0143406],
                    [114.0565206, 9.0147856],
                    [114.0551738, 9.0152214],
                    [114.0538239, 9.0156478],
                    [114.052471, 9.0160648],
                    [114.0511152, 9.0164725],
                    [114.0497565, 9.0168707],
                    [114.0483951, 9.0172596],
                    [114.0470309, 9.017639],
                    [114.045664, 9.0180089],
                    [114.0442946, 9.0183694],
                    [114.0429226, 9.0187204],
                    [114.0415482, 9.0190618],
                    [114.0401714, 9.0193938],
                    [114.0387922, 9.0197162],
                    [114.0374108, 9.020029],
                    [114.0360272, 9.0203323],
                    [114.0346415, 9.0206259],
                    [114.0332538, 9.02091],
                    [114.0318641, 9.0211844],
                    [114.0304724, 9.0214493],
                    [114.0290789, 9.0217044],
                    [114.0276836, 9.0219499],
                    [114.0262866, 9.0221858],
                    [114.024888, 9.0224119],
                    [114.0234878, 9.0226284],
                    [114.0220861, 9.0228352],
                    [114.020683, 9.0230322],
                    [114.0192785, 9.0232196],
                    [114.0178727, 9.0233972],
                    [114.0164657, 9.023565],
                    [114.0150575, 9.0237231],
                    [114.0136483, 9.0238715],
                    [114.012238, 9.0240101],
                    [114.0108267, 9.0241389],
                    [114.0094146, 9.0242579],
                    [114.0080017, 9.0243672],
                    [114.0065881, 9.0244667],
                    [114.0051737, 9.0245564],
                    [114.0037588, 9.0246362],
                    [114.0023433, 9.0247063],
                    [114.0009274, 9.0247666],
                    [113.9995111, 9.0248171],
                    [113.9980944, 9.0248577],
                    [113.9966775, 9.0248886],
                    [113.9952604, 9.0249096],
                    [113.9938432, 9.0249208],
                    [113.992426, 9.0249222],
                    [113.9910088, 9.0249138],
                    [113.9895916, 9.0248956],
                    [113.9881747, 9.0248675],
                    [113.9867579, 9.0248297],
                    [113.9853415, 9.024782],
                    [113.9839255, 9.0247245],
                    [113.9825099, 9.0246573],
                    [113.9808315, 9.024564],
                    [113.9796359, 9.0249055],
                    [113.9782717, 9.0252849],
                    [113.9769049, 9.0256548],
                    [113.9755354, 9.0260153],
                    [113.9741635, 9.0263662],
                    [113.972789, 9.0267077],
                    [113.9714122, 9.0270396],
                    [113.9700331, 9.027362],
                    [113.9686517, 9.0276748],
                    [113.9672681, 9.0279781],
                    [113.9658824, 9.0282717],
                    [113.9644946, 9.0285558],
                    [113.9631049, 9.0288302],
                    [113.9617133, 9.0290951],
                    [113.9603198, 9.0293502],
                    [113.9589245, 9.0295957],
                    [113.9575275, 9.0298316],
                    [113.9561289, 9.0300577],
                    [113.9547287, 9.0302742],
                    [113.953327, 9.0304809],
                    [113.9519239, 9.030678],
                    [113.9505194, 9.0308653],
                    [113.9491136, 9.0310429],
                    [113.9477066, 9.0312108],
                    [113.9462984, 9.0313689],
                    [113.9448891, 9.0315172],
                    [113.9434788, 9.0316558],
                    [113.9420676, 9.0317846],
                    [113.9406555, 9.0319037],
                    [113.9392426, 9.0320129],
                    [113.9378289, 9.0321124],
                    [113.9364146, 9.0322021],
                    [113.9349996, 9.032282],
                    [113.9335842, 9.032352],
                    [113.9321682, 9.0324123],
                    [113.9307519, 9.0324628],
                    [113.9293353, 9.0325034],
                    [113.9279184, 9.0325343],
                    [113.9265013, 9.0325553],
                    [113.9250841, 9.0325665],
                    [113.9236668, 9.0325679],
                    [113.9222496, 9.0325595],
                    [113.9208325, 9.0325413],
                    [113.9194155, 9.0325132],
                    [113.9179988, 9.0324754],
                    [113.9165824, 9.0324277],
                    [113.9151663, 9.0323703],
                    [113.9137507, 9.032303],
                    [113.9123356, 9.0322259],
                    [113.9109211, 9.032139],
                    [113.9095073, 9.0320423],
                    [113.9080941, 9.0319359],
                    [113.9066818, 9.0318196],
                    [113.9052703, 9.0316936],
                    [113.9038597, 9.0315578],
                    [113.9024502, 9.0314123],
                    [113.9010417, 9.0312569],
                    [113.9002982, 9.0311697],
                    [113.8998792, 9.0325396],
                    [113.8994596, 9.0338766],
                    [113.8990306, 9.0352105],
                    [113.8985921, 9.0365415],
                    [113.8981442, 9.0378694],
                    [113.8976868, 9.0391942],
                    [113.8972201, 9.0405158],
                    [113.8967439, 9.0418341],
                    [113.8962585, 9.043149],
                    [113.8957637, 9.0444606],
                    [113.8952596, 9.0457687],
                    [113.8947462, 9.0470732],
                    [113.8942236, 9.0483742],
                    [113.8936917, 9.0496715],
                    [113.8931507, 9.0509651],
                    [113.8926005, 9.0522549],
                    [113.8920411, 9.0535409],
                    [113.8914726, 9.054823],
                    [113.8908951, 9.0561011],
                    [113.8903084, 9.0573751],
                    [113.8897128, 9.0586451],
                    [113.8891081, 9.0599108],
                    [113.8884945, 9.0611724],
                    [113.8878719, 9.0624297],
                    [113.8872404, 9.0636826],
                    [113.8866, 9.0649312],
                    [113.8859508, 9.0661752],
                    [113.8852927, 9.0674148],
                    [113.8846259, 9.0686497],
                    [113.8839503, 9.06988],
                    [113.883266, 9.0711056],
                    [113.882573, 9.0723264],
                    [113.8818714, 9.0735423],
                    [113.8811611, 9.0747534],
                    [113.8804423, 9.0759595],
                    [113.8797149, 9.0771606],
                    [113.878979, 9.0783567],
                    [113.8782346, 9.0795476],
                    [113.8774818, 9.0807333],
                    [113.8767206, 9.0819138],
                    [113.875951, 9.083089],
                    [113.8751731, 9.0842588],
                    [113.8743869, 9.0854232],
                    [113.8735924, 9.0865821],
                    [113.8727898, 9.0877355],
                    [113.8719789, 9.0888833],
                    [113.87116, 9.0900254],
                    [113.8703329, 9.0911618],
                    [113.8694978, 9.0922925],
                    [113.8686547, 9.0934174],
                    [113.8678036, 9.0945364],
                    [113.8669446, 9.0956495],
                    [113.8660777, 9.0967566],
                    [113.865203, 9.0978576],
                    [113.8643205, 9.0989526],
                    [113.8634302, 9.1000415],
                    [113.8625322, 9.1011241],
                    [113.8616265, 9.1022005],
                    [113.8607132, 9.1032706],
                    [113.8597924, 9.1043343],
                    [113.858864, 9.1053917],
                    [113.8579281, 9.1064426],
                    [113.8569848, 9.1074869],
                    [113.8560341, 9.1085247],
                    [113.855076, 9.1095559],
                    [113.8541107, 9.1105805],
                    [113.8531381, 9.1115983],
                    [113.8521582, 9.1126094],
                    [113.8511713, 9.1136136],
                    [113.8501772, 9.114611],
                    [113.849176, 9.1156015],
                    [113.8481679, 9.116585],
                    [113.8471528, 9.1175615],
                    [113.8461308, 9.118531],
                    [113.8451019, 9.1194933],
                    [113.8440662, 9.1204485],
                    [113.8430237, 9.1213966],
                    [113.8419746, 9.1223373],
                    [113.8409188, 9.1232708],
                    [113.8398564, 9.1241969],
                    [113.8387874, 9.1251157],
                    [113.8377119, 9.126027],
                    [113.83663, 9.1269309],
                    [113.8355417, 9.1278272],
                    [113.8344471, 9.128716],
                    [113.8333461, 9.1295972],
                    [113.832239, 9.1304707],
                    [113.8311256, 9.1313366],
                    [113.8300062, 9.1321947],
                    [113.8288807, 9.1330451],
                    [113.8277491, 9.1338876],
                    [113.8266116, 9.1347223],
                    [113.8254682, 9.135549],
                    [113.824319, 9.1363679],
                    [113.8231639, 9.1371787],
                    [113.8220032, 9.1379816],
                    [113.8208368, 9.1387763],
                    [113.8196647, 9.139563],
                    [113.8184871, 9.1403416],
                    [113.817304, 9.1411119],
                    [113.8161154, 9.1418741],
                    [113.8149215, 9.142628],
                    [113.8137222, 9.1433736],
                    [113.8125177, 9.1441109],
                    [113.811308, 9.1448399],
                    [113.8100931, 9.1455604],
                    [113.8088731, 9.1462726],
                    [113.8076481, 9.1469762],
                    [113.8064182, 9.1476714],
                    [113.8051833, 9.148358],
                    [113.8039436, 9.149036],
                    [113.8026991, 9.1497055],
                    [113.8014498, 9.1503663],
                    [113.800196, 9.1510185],
                    [113.7989375, 9.1516619],
                    [113.7976744, 9.1522967],
                    [113.7964069, 9.1529226],
                    [113.795135, 9.1535398],
                    [113.7938587, 9.1541481],
                    [113.7925781, 9.1547476],
                    [113.7912933, 9.1553383],
                    [113.7900044, 9.15592],
                    [113.7887113, 9.1564927],
                    [113.7874142, 9.1570565],
                    [113.7861132, 9.1576113],
                    [113.7848082, 9.1581571],
                    [113.7834994, 9.1586939],
                    [113.7821868, 9.1592215],
                    [113.7808705, 9.1597401],
                    [113.7795505, 9.1602495],
                    [113.7782269, 9.1607498],
                    [113.7768999, 9.1612409],
                    [113.7755693, 9.1617228],
                    [113.7742354, 9.1621955],
                    [113.7728982, 9.1626589],
                    [113.7715577, 9.1631131],
                    [113.770214, 9.163558],
                    [113.7688671, 9.1639935],
                    [113.7675173, 9.1644198],
                    [113.7661644, 9.1648366],
                    [113.7648086, 9.1652441],
                    [113.7634499, 9.1656422],
                    [113.7620884, 9.1660309],
                    [113.7607243, 9.1664102],
                    [113.7593574, 9.16678],
                    [113.757988, 9.1671403],
                    [113.756616, 9.1674911],
                    [113.7552416, 9.1678324],
                    [113.7538647, 9.1681642],
                    [113.7524856, 9.1684865],
                    [113.7511042, 9.1687992],
                    [113.7497206, 9.1691023],
                    [113.7483349, 9.1693959],
                    [113.7469472, 9.1696798],
                    [113.7455574, 9.1699541],
                    [113.7441658, 9.1702188],
                    [113.7427723, 9.1704739],
                    [113.741377, 9.1707193],
                    [113.73998, 9.1709551],
                    [113.7385814, 9.1711811],
                    [113.7371812, 9.1713975],
                    [113.7357795, 9.1716042],
                    [113.7343764, 9.1718012],
                    [113.7329719, 9.1719884],
                    [113.7315661, 9.1721659],
                    [113.7301591, 9.1723337],
                    [113.7287509, 9.1724918],
                    [113.7273416, 9.1726401],
                    [113.7259314, 9.1727786],
                    [113.7245201, 9.1729074],
                    [113.723108, 9.1730264],
                    [113.7216951, 9.1731356],
                    [113.7202814, 9.173235],
                    [113.7188671, 9.1733246],
                    [113.7174522, 9.1734045],
                    [113.7160367, 9.1734745],
                    [113.7146208, 9.1735348],
                    [113.7132044, 9.1735852],
                    [113.7117878, 9.1736259],
                    [113.7103709, 9.1736567],
                    [113.7089538, 9.1736777],
                    [113.7075366, 9.173689],
                    [113.7061194, 9.1736904],
                    [113.7047021, 9.1736819],
                    [113.703285, 9.1736637],
                    [113.7018681, 9.1736357],
                    [113.7004513, 9.1735979],
                    [113.6990349, 9.1735502],
                    [113.6976189, 9.1734928],
                    [113.6962032, 9.1734255],
                    [113.6947882, 9.1733485],
                    [113.6933736, 9.1732616],
                    [113.6919598, 9.173165],
                    [113.6905466, 9.1730586],
                    [113.6891343, 9.1729424],
                    [113.6877228, 9.1728164],
                    [113.6863122, 9.1726806],
                    [113.6849027, 9.1725351],
                    [113.6834942, 9.1723799],
                    [113.6820868, 9.1722149],
                    [113.6806807, 9.1720401],
                    [113.6792758, 9.1718556],
                    [113.6778723, 9.1716615],
                    [113.6764702, 9.1714575],
                    [113.6750695, 9.1712439],
                    [113.6736705, 9.1710206],
                    [113.672273, 9.1707877],
                    [113.6708772, 9.170545],
                    [113.6694832, 9.1702927],
                    [113.668091, 9.1700307],
                    [113.6667007, 9.1697592],
                    [113.6653124, 9.169478],
                    [113.6639261, 9.1691872],
                    [113.6625419, 9.1688868],
                    [113.6611599, 9.1685768],
                    [113.6597801, 9.1682573],
                    [113.6584026, 9.1679282],
                    [113.6570275, 9.1675896],
                    [113.6351242, 9.1615853],
                    [113.6301072, 9.1601786],
                    [113.6250926, 9.1587634],
                    [113.6202833, 9.1573974],
                    [113.6154762, 9.1560236],
                    [113.6106715, 9.1546419],
                    [113.6058691, 9.1532524],
                    [113.6012476, 9.1519432],
                    [113.59988, 9.1515761],
                    [113.5985151, 9.1511996],
                    [113.5971528, 9.1508136],
                    [113.5956147, 9.1503299],
                    [113.5942581, 9.1499251],
                    [113.5929043, 9.1495109],
                    [113.5915536, 9.1490873],
                    [113.5902059, 9.1486544],
                    [113.5888613, 9.1482122],
                    [113.5875199, 9.1477607],
                    [113.5861817, 9.1472998],
                    [113.5848468, 9.1468298],
                    [113.5835153, 9.1463505],
                    [113.5821872, 9.145862],
                    [113.5808627, 9.1453643],
                    [113.5795417, 9.1448574],
                    [113.5782243, 9.1443415],
                    [113.5769106, 9.1438164],
                    [113.5756007, 9.1432822],
                    [113.5742946, 9.142739],
                    [113.5729925, 9.1421867],
                    [113.5716942, 9.1416255],
                    [113.5704, 9.1410552],
                    [113.5691099, 9.1404761],
                    [113.5678239, 9.139888],
                    [113.5665421, 9.139291],
                    [113.5652646, 9.1386851],
                    [113.5639914, 9.1380704],
                    [113.5627226, 9.1374469],
                    [113.5614583, 9.1368147],
                    [113.5601985, 9.1361737],
                    [113.5589433, 9.135524],
                    [113.5576927, 9.1348656],
                    [113.5564469, 9.1341986],
                    [113.5552058, 9.133523],
                    [113.5539695, 9.1328388],
                    [113.5527382, 9.132146],
                    [113.5515117, 9.1314448],
                    [113.5502903, 9.130735],
                    [113.549074, 9.1300169],
                    [113.5478628, 9.1292903],
                    [113.5466567, 9.1285553],
                    [113.545456, 9.127812],
                    [113.5442605, 9.1270605],
                    [113.5430704, 9.1263006],
                    [113.5418857, 9.1255326],
                    [113.5407066, 9.1247563],
                    [113.5395329, 9.1239719],
                    [113.5383649, 9.1231794],
                    [113.5372025, 9.1223788],
                    [113.5360458, 9.1215702],
                    [113.5348949, 9.1207536],
                    [113.5337498, 9.1199291],
                    [113.5326107, 9.1190966],
                    [113.5314774, 9.1182563],
                    [113.5303502, 9.1174082],
                    [113.529229, 9.1165522],
                    [113.5281139, 9.1156885],
                    [113.5270049, 9.1148172],
                    [113.5259022, 9.1139381],
                    [113.5248058, 9.1130515],
                    [113.5237157, 9.1121572],
                    [113.5226319, 9.1112555],
                    [113.5215546, 9.1103462],
                    [113.5204838, 9.1094295],
                    [113.5194195, 9.1085055],
                    [113.5183618, 9.107574],
                    [113.5173107, 9.1066353],
                    [113.5162663, 9.1056893],
                    [113.5152287, 9.1047361],
                    [113.5141979, 9.1037758],
                    [113.5131739, 9.1028083],
                    [113.5121568, 9.1018338],
                    [113.5111467, 9.1008522],
                    [113.5101435, 9.0998637],
                    [113.5091474, 9.0988682],
                    [113.5081584, 9.0978659],
                    [113.5071765, 9.0968567],
                    [113.5062019, 9.0958408],
                    [113.5052344, 9.0948181],
                    [113.5042743, 9.0937887],
                    [113.5033215, 9.0927528],
                    [113.502376, 9.0917102],
                    [113.501438, 9.0906612],
                    [113.5005075, 9.0896056],
                    [113.4995845, 9.0885436],
                    [113.498669, 9.0874753],
                    [113.4977612, 9.0864007],
                    [113.496861, 9.0853198],
                    [113.4959685, 9.0842326],
                    [113.4950838, 9.0831394],
                    [113.4942068, 9.08204],
                    [113.4933377, 9.0809346],
                    [113.4924764, 9.0798231],
                    [113.4916231, 9.0787058],
                    [113.4907777, 9.0775825],
                    [113.4899403, 9.0764534],
                    [113.4891109, 9.0753186],
                    [113.4882896, 9.074178],
                    [113.4874765, 9.0730318],
                    [113.4866715, 9.0718799],
                    [113.4858747, 9.0707226],
                    [113.4850861, 9.0695597],
                    [113.4843058, 9.0683914],
                    [113.4835339, 9.0672177],
                    [113.4827703, 9.0660386],
                    [113.482015, 9.0648544],
                    [113.4812682, 9.0636649],
                    [113.4805299, 9.0624702],
                    [113.4798001, 9.0612705],
                    [113.4790788, 9.0600658],
                    [113.4783661, 9.0588561],
                    [113.477662, 9.0576414],
                    [113.4769665, 9.056422],
                    [113.4762797, 9.0551977],
                    [113.4756016, 9.0539687],
                    [113.4749323, 9.052735],
                    [113.4742717, 9.0514968],
                    [113.47362, 9.0502539],
                    [113.4729771, 9.0490066],
                    [113.472343, 9.0477549],
                    [113.4717179, 9.0464988],
                    [113.4711017, 9.0452384],
                    [113.4704945, 9.0439737],
                    [113.4698962, 9.0427049],
                    [113.469307, 9.041432],
                    [113.4687269, 9.040155],
                    [113.4681558, 9.038874],
                    [113.4675938, 9.0375891],
                    [113.467041, 9.0363003],
                    [113.4664973, 9.0350077],
                    [113.4659628, 9.0337114],
                    [113.4654376, 9.0324114],
                    [113.4649216, 9.0311078],
                    [113.4644148, 9.0298007],
                    [113.4639174, 9.02849],
                    [113.4634292, 9.027176],
                    [113.4629504, 9.0258586],
                    [113.462481, 9.0245379],
                    [113.462021, 9.023214],
                    [113.4615703, 9.0218869],
                    [113.4611292, 9.0205567],
                    [113.4606974, 9.0192236],
                    [113.4602752, 9.0178874],
                    [113.4598624, 9.0165483],
                    [113.4594592, 9.0152065],
                    [113.4590654, 9.0138618],
                    [113.4586813, 9.0125145],
                    [113.4583067, 9.0111645],
                    [113.4579417, 9.009812],
                    [113.4575864, 9.0084569],
                    [113.4572406, 9.0070994],
                    [113.4569045, 9.0057396],
                    [113.4565781, 9.0043774],
                    [113.4562614, 9.0030131],
                    [113.4559543, 9.0016465],
                    [113.455657, 9.0002779],
                    [113.4553693, 8.9989072],
                    [113.4550914, 8.9975346],
                    [113.4548233, 8.9961601],
                    [113.454565, 8.9947837],
                    [113.4543164, 8.9934056],
                    [113.4540776, 8.9920258],
                    [113.4538486, 8.9906443],
                    [113.4536294, 8.9892613],
                    [113.45342, 8.9878769],
                    [113.4532205, 8.986491],
                    [113.4530308, 8.9851037],
                    [113.452851, 8.9837152],
                    [113.452681, 8.9823254],
                    [113.452521, 8.9809345],
                    [113.4523707, 8.9795425],
                    [113.4522304, 8.9781495],
                    [113.4521, 8.9767556],
                    [113.4519794, 8.9753607],
                    [113.4518688, 8.9739651],
                    [113.4517681, 8.9725688],
                    [113.4516773, 8.9711717],
                    [113.4515964, 8.9697741],
                    [113.4515255, 8.9683759],
                    [113.4514644, 8.9669773],
                    [113.4514133, 8.9655783],
                    [113.4513722, 8.9641789],
                    [113.4513409, 8.9627794],
                    [113.4513196, 8.9613796],
                    [113.4513083, 8.9599797],
                    [113.4513068, 8.9585797],
                ],
            ],
            [
                [
                    [113.6422392, 10.012864],
                    [113.6422408, 10.0114623],
                    [113.6422524, 10.0100606],
                    [113.642274, 10.008659],
                    [113.6423056, 10.0072576],
                    [113.6423472, 10.0058565],
                    [113.6423989, 10.0044556],
                    [113.6424605, 10.0030552],
                    [113.6425321, 10.0016552],
                    [113.6426137, 10.0002557],
                    [113.6427053, 9.9988568],
                    [113.6428068, 9.9974586],
                    [113.6429183, 9.9960611],
                    [113.6430399, 9.9946644],
                    [113.6431713, 9.9932686],
                    [113.6433128, 9.9918737],
                    [113.6434641, 9.9904798],
                    [113.6436255, 9.989087],
                    [113.6437967, 9.9876954],
                    [113.6439779, 9.9863049],
                    [113.644169, 9.9849158],
                    [113.64437, 9.983528],
                    [113.6445809, 9.9821416],
                    [113.6448017, 9.9807567],
                    [113.6450324, 9.9793734],
                    [113.645273, 9.9779916],
                    [113.6455234, 9.9766116],
                    [113.6457836, 9.9752334],
                    [113.6460537, 9.9738569],
                    [113.6463335, 9.9724824],
                    [113.6466232, 9.9711098],
                    [113.6469227, 9.9697393],
                    [113.6472319, 9.9683708],
                    [113.6475509, 9.9670046],
                    [113.6478797, 9.9656406],
                    [113.6482182, 9.9642788],
                    [113.6485663, 9.9629195],
                    [113.6489242, 9.9615626],
                    [113.6492917, 9.9602081],
                    [113.6496689, 9.9588563],
                    [113.6500558, 9.9575071],
                    [113.6504522, 9.9561606],
                    [113.6508583, 9.9548169],
                    [113.6512739, 9.953476],
                    [113.6516991, 9.952138],
                    [113.6521338, 9.950803],
                    [113.6525781, 9.949471],
                    [113.6530318, 9.9481422],
                    [113.653495, 9.9468165],
                    [113.6539677, 9.945494],
                    [113.6544497, 9.9441748],
                    [113.6549412, 9.942859],
                    [113.6554421, 9.9415466],
                    [113.6559523, 9.9402378],
                    [113.6564718, 9.9389324],
                    [113.6570006, 9.9376308],
                    [113.6575387, 9.9363327],
                    [113.6580861, 9.9350385],
                    [113.6586427, 9.933748],
                    [113.6592084, 9.9324615],
                    [113.6597834, 9.9311789],
                    [113.6603674, 9.9299003],
                    [113.6609606, 9.9286257],
                    [113.6615629, 9.9273553],
                    [113.6621742, 9.9260891],
                    [113.6627945, 9.9248271],
                    [113.6634238, 9.9235695],
                    [113.6640621, 9.9223163],
                    [113.6647093, 9.9210674],
                    [113.6653654, 9.9198231],
                    [113.6660304, 9.9185834],
                    [113.6667042, 9.9173483],
                    [113.6673867, 9.9161179],
                    [113.6680781, 9.9148922],
                    [113.6687782, 9.9136714],
                    [113.6694869, 9.9124554],
                    [113.6702043, 9.9112444],
                    [113.6709304, 9.9100383],
                    [113.671665, 9.9088373],
                    [113.6724082, 9.9076415],
                    [113.6731599, 9.9064507],
                    [113.6739201, 9.9052652],
                    [113.6746887, 9.9040851],
                    [113.6754657, 9.9029102],
                    [113.6762511, 9.9017408],
                    [113.6770448, 9.9005768],
                    [113.6778467, 9.8994183],
                    [113.678657, 9.8982654],
                    [113.6794754, 9.8971182],
                    [113.680302, 9.8959766],
                    [113.6811367, 9.8948408],
                    [113.6819795, 9.8937108],
                    [113.6828304, 9.8925866],
                    [113.6836892, 9.8914684],
                    [113.684556, 9.8903561],
                    [113.6854307, 9.8892499],
                    [113.6863133, 9.8881497],
                    [113.6872037, 9.8870557],
                    [113.6881019, 9.8859678],
                    [113.6890079, 9.8848862],
                    [113.6899215, 9.8838109],
                    [113.6908428, 9.882742],
                    [113.6917716, 9.8816794],
                    [113.6927081, 9.8806233],
                    [113.693652, 9.8795737],
                    [113.6946034, 9.8785307],
                    [113.6955623, 9.8774943],
                    [113.6965285, 9.8764645],
                    [113.697502, 9.8754414],
                    [113.6984828, 9.8744252],
                    [113.6994708, 9.8734157],
                    [113.700466, 9.8724131],
                    [113.7014683, 9.8714174],
                    [113.7024777, 9.8704286],
                    [113.7034942, 9.8694469],
                    [113.7045176, 9.8684722],
                    [113.7055479, 9.8675047],
                    [113.7065851, 9.8665443],
                    [113.7076292, 9.8655911],
                    [113.70868, 9.8646451],
                    [113.7097375, 9.8637065],
                    [113.7108017, 9.8627752],
                    [113.7118726, 9.8618512],
                    [113.71295, 9.8609347],
                    [113.7140339, 9.8600257],
                    [113.7151242, 9.8591242],
                    [113.716221, 9.8582303],
                    [113.7173241, 9.857344],
                    [113.7184335, 9.8564654],
                    [113.7195491, 9.8555945],
                    [113.720671, 9.8547313],
                    [113.7217989, 9.8538759],
                    [113.722933, 9.8530283],
                    [113.724073, 9.8521886],
                    [113.725219, 9.8513568],
                    [113.726371, 9.8505329],
                    [113.7275287, 9.8497171],
                    [113.7286923, 9.8489093],
                    [113.7298616, 9.8481095],
                    [113.7310366, 9.8473179],
                    [113.7322171, 9.8465344],
                    [113.7334033, 9.8457592],
                    [113.7345949, 9.8449921],
                    [113.735792, 9.8442334],
                    [113.7369945, 9.8434829],
                    [113.7382023, 9.8427408],
                    [113.7394153, 9.842007],
                    [113.7406336, 9.8412817],
                    [113.741857, 9.8405648],
                    [113.7430855, 9.8398564],
                    [113.744319, 9.8391566],
                    [113.7455575, 9.8384653],
                    [113.7468009, 9.8377825],
                    [113.7480491, 9.8371085],
                    [113.7493021, 9.836443],
                    [113.7505598, 9.8357863],
                    [113.7518222, 9.8351383],
                    [113.7530891, 9.834499],
                    [113.7543606, 9.8338685],
                    [113.7556365, 9.8332469],
                    [113.7569169, 9.8326341],
                    [113.7582016, 9.8320302],
                    [113.7594905, 9.8314352],
                    [113.7607837, 9.8308491],
                    [113.762081, 9.830272],
                    [113.7633824, 9.8297039],
                    [113.7646879, 9.8291448],
                    [113.7659973, 9.8285948],
                    [113.7673105, 9.8280538],
                    [113.7686276, 9.827522],
                    [113.7699485, 9.8269993],
                    [113.7712731, 9.8264857],
                    [113.7726012, 9.8259814],
                    [113.773933, 9.8254862],
                    [113.7752683, 9.8250003],
                    [113.7766069, 9.8245236],
                    [113.777949, 9.8240562],
                    [113.7792943, 9.8235982],
                    [113.7806429, 9.8231494],
                    [113.7819947, 9.82271],
                    [113.7833495, 9.82228],
                    [113.7847074, 9.8218594],
                    [113.7860683, 9.8214481],
                    [113.787432, 9.8210464],
                    [113.7887986, 9.820654],
                    [113.790168, 9.8202712],
                    [113.79154, 9.8198978],
                    [113.7929147, 9.8195339],
                    [113.7942919, 9.8191796],
                    [113.7956717, 9.8188348],
                    [113.7970538, 9.8184996],
                    [113.7984384, 9.818174],
                    [113.7998252, 9.8178579],
                    [113.8012142, 9.8175515],
                    [113.8026054, 9.8172547],
                    [113.8039986, 9.8169675],
                    [113.8053939, 9.81669],
                    [113.8067911, 9.8164221],
                    [113.8081902, 9.816164],
                    [113.8095911, 9.8159155],
                    [113.8109937, 9.8156768],
                    [113.812398, 9.8154477],
                    [113.8138039, 9.8152284],
                    [113.8152114, 9.8150188],
                    [113.8166203, 9.814819],
                    [113.8180305, 9.814629],
                    [113.8194421, 9.8144487],
                    [113.820855, 9.8142782],
                    [113.822269, 9.8141175],
                    [113.8236842, 9.8139666],
                    [113.8251004, 9.8138255],
                    [113.8265175, 9.8136942],
                    [113.8279356, 9.8135727],
                    [113.8293545, 9.813461],
                    [113.8307741, 9.8133592],
                    [113.8321945, 9.8132672],
                    [113.8336155, 9.813185],
                    [113.835037, 9.8131127],
                    [113.836459, 9.8130502],
                    [113.8378814, 9.8129976],
                    [113.8393041, 9.8129549],
                    [113.8407271, 9.8129219],
                    [113.8421504, 9.8128989],
                    [113.8435737, 9.8128857],
                    [113.8449971, 9.8128823],
                    [113.8464205, 9.8128888],
                    [113.8478438, 9.8129052],
                    [113.849267, 9.8129314],
                    [113.8506899, 9.8129675],
                    [113.8521126, 9.8130135],
                    [113.8535349, 9.8130692],
                    [113.8549567, 9.8131349],
                    [113.8563781, 9.8132104],
                    [113.8577988, 9.8132957],
                    [113.859219, 9.8133908],
                    [113.8606384, 9.8134958],
                    [113.862057, 9.8136106],
                    [113.8634748, 9.8137353],
                    [113.8648917, 9.8138697],
                    [113.8663075, 9.814014],
                    [113.8677223, 9.8141681],
                    [113.869136, 9.8143319],
                    [113.8705484, 9.8145056],
                    [113.8719596, 9.814689],
                    [113.8733695, 9.8148822],
                    [113.8747779, 9.8150851],
                    [113.8761849, 9.8152978],
                    [113.8775903, 9.8155203],
                    [113.878994, 9.8157524],
                    [113.8803961, 9.8159943],
                    [113.8817964, 9.8162459],
                    [113.8831949, 9.8165072],
                    [113.8845915, 9.8167781],
                    [113.8859862, 9.8170587],
                    [113.8873788, 9.817349],
                    [113.8887693, 9.8176489],
                    [113.8901576, 9.8179584],
                    [113.8915436, 9.8182776],
                    [113.8929274, 9.8186063],
                    [113.8943088, 9.8189446],
                    [113.8956877, 9.8192924],
                    [113.8970642, 9.8196498],
                    [113.898438, 9.8200167],
                    [113.8998092, 9.8203932],
                    [113.9011777, 9.8207791],
                    [113.9025433, 9.8211744],
                    [113.9039062, 9.8215793],
                    [113.9052661, 9.8219935],
                    [113.906623, 9.8224172],
                    [113.9079769, 9.8228502],
                    [113.9093276, 9.8232926],
                    [113.9106752, 9.8237444],
                    [113.9120195, 9.8242054],
                    [113.9133605, 9.8246758],
                    [113.914698, 9.8251554],
                    [113.9160322, 9.8256443],
                    [113.9173628, 9.8261424],
                    [113.9186898, 9.8266498],
                    [113.9200132, 9.8271663],
                    [113.9213329, 9.8276919],
                    [113.9226487, 9.8282267],
                    [113.9239608, 9.8287705],
                    [113.9252689, 9.8293235],
                    [113.926573, 9.8298855],
                    [113.9278731, 9.8304565],
                    [113.9291691, 9.8310365],
                    [113.930461, 9.8316254],
                    [113.9317486, 9.8322233],
                    [113.9330319, 9.8328301],
                    [113.9343108, 9.8334457],
                    [113.9355853, 9.8340702],
                    [113.9368554, 9.8347035],
                    [113.9381208, 9.8353456],
                    [113.9393817, 9.8359964],
                    [113.9406379, 9.8366559],
                    [113.9418894, 9.8373241],
                    [113.943136, 9.838001],
                    [113.9443779, 9.8386865],
                    [113.9456147, 9.8393805],
                    [113.9468466, 9.8400832],
                    [113.9480735, 9.8407943],
                    [113.9492953, 9.8415139],
                    [113.9505119, 9.8422419],
                    [113.9517232, 9.8429783],
                    [113.9529293, 9.8437232],
                    [113.9541301, 9.8444763],
                    [113.9553254, 9.8452377],
                    [113.9565153, 9.8460074],
                    [113.9576997, 9.8467853],
                    [113.9588785, 9.8475714],
                    [113.9600516, 9.8483657],
                    [113.9612191, 9.849168],
                    [113.9623808, 9.8499784],
                    [113.9635367, 9.8507968],
                    [113.9646867, 9.8516232],
                    [113.9658308, 9.8524576],
                    [113.9669689, 9.8532998],
                    [113.968101, 9.8541499],
                    [113.969227, 9.8550078],
                    [113.9703469, 9.8558735],
                    [113.9714605, 9.8567469],
                    [113.9725679, 9.857628],
                    [113.973669, 9.8585168],
                    [113.9747637, 9.8594131],
                    [113.975852, 9.860317],
                    [113.9769338, 9.8612285],
                    [113.9780091, 9.8621473],
                    [113.9790778, 9.8630736],
                    [113.9801399, 9.8640073],
                    [113.9811952, 9.8649483],
                    [113.9822439, 9.8658966],
                    [113.9832857, 9.8668521],
                    [113.9843208, 9.8678148],
                    [113.9853489, 9.8687847],
                    [113.98637, 9.8697616],
                    [113.9873842, 9.8707456],
                    [113.9883914, 9.8717366],
                    [113.9893914, 9.8727345],
                    [113.9903843, 9.8737394],
                    [113.99137, 9.874751],
                    [113.9923485, 9.8757695],
                    [113.9933196, 9.8767947],
                    [113.9942835, 9.8778266],
                    [113.9952399, 9.8788652],
                    [113.9961889, 9.8799103],
                    [113.9971305, 9.880962],
                    [113.9980645, 9.8820202],
                    [113.9989909, 9.8830848],
                    [113.9999098, 9.8841558],
                    [114.0008209, 9.8852331],
                    [114.0017244, 9.8863167],
                    [114.0026201, 9.8874066],
                    [114.003508, 9.8885026],
                    [114.004388, 9.8896047],
                    [114.0052602, 9.8907129],
                    [114.0061245, 9.8918271],
                    [114.0069807, 9.8929472],
                    [114.007829, 9.8940733],
                    [114.0086692, 9.8952052],
                    [114.0095013, 9.8963428],
                    [114.0103253, 9.8974862],
                    [114.0111411, 9.8986353],
                    [114.0119487, 9.89979],
                    [114.012748, 9.9009502],
                    [114.013539, 9.902116],
                    [114.0143217, 9.9032872],
                    [114.0150961, 9.9044638],
                    [114.015862, 9.9056457],
                    [114.0166194, 9.9068328],
                    [114.0173684, 9.9080252],
                    [114.0181088, 9.9092227],
                    [114.0188407, 9.9104254],
                    [114.019564, 9.911633],
                    [114.0202786, 9.9128457],
                    [114.0209846, 9.9140632],
                    [114.0216818, 9.9152856],
                    [114.0223704, 9.9165128],
                    [114.0230501, 9.9177447],
                    [114.0237211, 9.9189813],
                    [114.0243832, 9.9202225],
                    [114.0250364, 9.9214683],
                    [114.0256808, 9.9227185],
                    [114.0263162, 9.9239732],
                    [114.0269426, 9.9252322],
                    [114.02756, 9.9264956],
                    [114.0281685, 9.9277631],
                    [114.0287678, 9.9290349],
                    [114.0293581, 9.9303107],
                    [114.0299392, 9.9315906],
                    [114.0305112, 9.9328745],
                    [114.031074, 9.9341623],
                    [114.0316276, 9.935454],
                    [114.032172, 9.9367495],
                    [114.0327071, 9.9380487],
                    [114.033233, 9.9393515],
                    [114.0337495, 9.940658],
                    [114.0342567, 9.941968],
                    [114.0347546, 9.9432815],
                    [114.035243, 9.9445984],
                    [114.0357221, 9.9459186],
                    [114.0361917, 9.9472422],
                    [114.0366519, 9.9485689],
                    [114.0371025, 9.9498988],
                    [114.0375437, 9.9512317],
                    [114.0379754, 9.9525677],
                    [114.0383975, 9.9539066],
                    [114.0388101, 9.9552484],
                    [114.039213, 9.956593],
                    [114.0396064, 9.9579404],
                    [114.0399901, 9.9592905],
                    [114.0403642, 9.9606431],
                    [114.0407287, 9.9619984],
                    [114.0410834, 9.9633561],
                    [114.0414285, 9.9647162],
                    [114.0417638, 9.9660787],
                    [114.0420894, 9.9674434],
                    [114.0424053, 9.9688104],
                    [114.0427114, 9.9701795],
                    [114.0430077, 9.9715507],
                    [114.0432943, 9.9729239],
                    [114.043571, 9.9742991],
                    [114.0438379, 9.9756761],
                    [114.044095, 9.9770549],
                    [114.0443422, 9.9784355],
                    [114.0445796, 9.9798177],
                    [114.0448071, 9.9812016],
                    [114.0450247, 9.982587],
                    [114.0584877, 10.0640116],
                    [114.058735, 10.0653918],
                    [114.0589723, 10.0667737],
                    [114.0591998, 10.0681571],
                    [114.0594175, 10.0695421],
                    [114.0596252, 10.0709286],
                    [114.059823, 10.0723165],
                    [114.0600109, 10.0737057],
                    [114.0601889, 10.0750962],
                    [114.060357, 10.0764878],
                    [114.0605151, 10.0778806],
                    [114.0606633, 10.0792744],
                    [114.0608015, 10.0806692],
                    [114.0609298, 10.082065],
                    [114.0610481, 10.0834616],
                    [114.0611564, 10.0848589],
                    [114.061329, 10.0874321],
                    [114.02102, 10.2191637],
                    [114.0202403, 10.2201779],
                    [114.0193735, 10.221289],
                    [114.0184988, 10.2223941],
                    [114.0176162, 10.2234931],
                    [114.0167258, 10.224586],
                    [114.0158276, 10.2256727],
                    [114.0149217, 10.2267532],
                    [114.014008, 10.2278274],
                    [114.0130868, 10.2288952],
                    [114.0121579, 10.2299566],
                    [114.0112214, 10.2310115],
                    [114.0102775, 10.23206],
                    [114.0093261, 10.2331019],
                    [114.0083673, 10.2341372],
                    [114.0074011, 10.2351658],
                    [114.0064275, 10.2361877],
                    [114.0054467, 10.2372028],
                    [114.0044587, 10.2382112],
                    [114.0034635, 10.2392127],
                    [114.0024612, 10.2402072],
                    [114.0014518, 10.2411948],
                    [114.0004354, 10.2421754],
                    [113.9994119, 10.243149],
                    [113.9983816, 10.2441154],
                    [113.9973444, 10.2450747],
                    [113.9963003, 10.2460268],
                    [113.9952495, 10.2469716],
                    [113.994192, 10.2479092],
                    [113.9931278, 10.2488394],
                    [113.992057, 10.2497622],
                    [113.9909796, 10.2506776],
                    [113.9898957, 10.2515855],
                    [113.9888053, 10.2524859],
                    [113.9877086, 10.2533787],
                    [113.9866054, 10.2542639],
                    [113.985496, 10.2551415],
                    [113.9843804, 10.2560113],
                    [113.9832586, 10.2568734],
                    [113.9821306, 10.2577278],
                    [113.9809966, 10.2585743],
                    [113.9798565, 10.259413],
                    [113.9787105, 10.2602437],
                    [113.9775586, 10.2610665],
                    [113.9764008, 10.2618813],
                    [113.9752372, 10.2626881],
                    [113.9740679, 10.2634868],
                    [113.972893, 10.2642774],
                    [113.9717124, 10.2650599],
                    [113.9705262, 10.2658341],
                    [113.9693346, 10.2666002],
                    [113.9681375, 10.267358],
                    [113.966935, 10.2681074],
                    [113.9657272, 10.2688486],
                    [113.9645142, 10.2695814],
                    [113.9632959, 10.2703057],
                    [113.9620725, 10.2710217],
                    [113.960844, 10.2717291],
                    [113.9596105, 10.272428],
                    [113.958372, 10.2731184],
                    [113.9571287, 10.2738002],
                    [113.9558804, 10.2744734],
                    [113.9546274, 10.2751379],
                    [113.9533697, 10.2757938],
                    [113.9521074, 10.2764409],
                    [113.9508404, 10.2770793],
                    [113.9495689, 10.2777089],
                    [113.948293, 10.2783297],
                    [113.9470127, 10.2789416],
                    [113.945728, 10.2795447],
                    [113.944439, 10.2801389],
                    [113.9431458, 10.2807242],
                    [113.9418485, 10.2813005],
                    [113.9405471, 10.2818678],
                    [113.9392417, 10.2824261],
                    [113.9379323, 10.2829753],
                    [113.936619, 10.2835155],
                    [113.9353019, 10.2840466],
                    [113.933981, 10.2845686],
                    [113.9326565, 10.2850814],
                    [113.9313283, 10.285585],
                    [113.9299965, 10.2860795],
                    [113.9286613, 10.2865647],
                    [113.9273226, 10.2870407],
                    [113.9259805, 10.2875074],
                    [113.9246352, 10.2879648],
                    [113.9232866, 10.2884129],
                    [113.9219348, 10.2888517],
                    [113.92058, 10.2892811],
                    [113.9192221, 10.2897011],
                    [113.9178612, 10.2901117],
                    [113.9164975, 10.290513],
                    [113.9151309, 10.2909047],
                    [113.9137616, 10.291287],
                    [113.9123895, 10.2916598],
                    [113.9110148, 10.2920232],
                    [113.9096376, 10.292377],
                    [113.9082579, 10.2927213],
                    [113.9068757, 10.293056],
                    [113.9054912, 10.2933812],
                    [113.9041044, 10.2936967],
                    [113.9027154, 10.2940027],
                    [113.9013242, 10.2942991],
                    [113.8999309, 10.2945858],
                    [113.8985357, 10.2948629],
                    [113.8971384, 10.2951304],
                    [113.8957393, 10.2953882],
                    [113.8943384, 10.2956363],
                    [113.8929358, 10.2958747],
                    [113.8915315, 10.2961034],
                    [113.8901256, 10.2963223],
                    [113.8887182, 10.2965316],
                    [113.8873093, 10.2967311],
                    [113.885899, 10.2969209],
                    [113.8844874, 10.2971009],
                    [113.8830745, 10.2972711],
                    [113.8816605, 10.2974316],
                    [113.8802453, 10.2975823],
                    [113.8788292, 10.2977232],
                    [113.877412, 10.2978543],
                    [113.8759939, 10.2979756],
                    [113.874575, 10.2980871],
                    [113.8731554, 10.2981888],
                    [113.871735, 10.2982806],
                    [113.8703141, 10.2983627],
                    [113.8688926, 10.2984349],
                    [113.8674706, 10.2984973],
                    [113.8660481, 10.2985498],
                    [113.8646254, 10.2985925],
                    [113.8632024, 10.2986254],
                    [113.8617792, 10.2986484],
                    [113.8603558, 10.2986616],
                    [113.8589324, 10.2986649],
                    [113.857509, 10.2986584],
                    [113.8560857, 10.2986421],
                    [113.8546625, 10.2986159],
                    [113.8532396, 10.2985798],
                    [113.8518169, 10.298534],
                    [113.8503947, 10.2984783],
                    [113.8489728, 10.2984127],
                    [113.8475515, 10.2983374],
                    [113.8461307, 10.2982522],
                    [113.8447106, 10.2981572],
                    [113.8432911, 10.2980523],
                    [113.8418725, 10.2979377],
                    [113.8404547, 10.2978132],
                    [113.8390379, 10.297679],
                    [113.837622, 10.2975349],
                    [113.8362072, 10.2973811],
                    [113.8347935, 10.2972175],
                    [113.8333811, 10.2970441],
                    [113.8319699, 10.296861],
                    [113.8305601, 10.296668],
                    [113.8291516, 10.2964654],
                    [113.8277447, 10.296253],
                    [113.8263393, 10.2960309],
                    [113.8249355, 10.2957991],
                    [113.8235334, 10.2955576],
                    [113.8221331, 10.2953064],
                    [113.8207346, 10.2950455],
                    [113.819338, 10.2947749],
                    [113.8179434, 10.2944947],
                    [113.8165508, 10.2942049],
                    [113.8151603, 10.2939054],
                    [113.813772, 10.2935964],
                    [113.8123859, 10.2932777],
                    [113.8110021, 10.2929495],
                    [113.8096207, 10.2926117],
                    [113.8082418, 10.2922643],
                    [113.8068654, 10.2919074],
                    [113.8054915, 10.2915411],
                    [113.8041203, 10.2911652],
                    [113.8027519, 10.2907798],
                    [113.8013862, 10.290385],
                    [113.8000234, 10.2899808],
                    [113.7986634, 10.2895672],
                    [113.7973065, 10.2891441],
                    [113.7959527, 10.2887117],
                    [113.7946019, 10.2882699],
                    [113.7932544, 10.2878188],
                    [113.7919101, 10.2873584],
                    [113.7905691, 10.2868887],
                    [113.7892315, 10.2864098],
                    [113.7878974, 10.2859216],
                    [113.7865667, 10.2854242],
                    [113.7852397, 10.2849176],
                    [113.7839163, 10.2844018],
                    [113.7825967, 10.2838769],
                    [113.7812808, 10.2833429],
                    [113.7799688, 10.2827998],
                    [113.7786606, 10.2822476],
                    [113.7773565, 10.2816864],
                    [113.7760564, 10.2811162],
                    [113.7747604, 10.280537],
                    [113.7734686, 10.2799489],
                    [113.772181, 10.2793519],
                    [113.7708977, 10.2787459],
                    [113.7696187, 10.2781311],
                    [113.7683442, 10.2775075],
                    [113.7670742, 10.2768751],
                    [113.7658087, 10.2762339],
                    [113.7645478, 10.2755839],
                    [113.7632916, 10.2749253],
                    [113.7620402, 10.274258],
                    [113.7607935, 10.273582],
                    [113.7595517, 10.2728975],
                    [113.7583148, 10.2722043],
                    [113.7570829, 10.2715027],
                    [113.755856, 10.2707925],
                    [113.7546343, 10.2700739],
                    [113.7534177, 10.2693468],
                    [113.7522063, 10.2686113],
                    [113.7510002, 10.2678675],
                    [113.7497994, 10.2671153],
                    [113.7486041, 10.2663549],
                    [113.7474142, 10.2655862],
                    [113.7462299, 10.2648093],
                    [113.7450511, 10.2640242],
                    [113.7438779, 10.263231],
                    [113.7427105, 10.2624297],
                    [113.7415487, 10.2616203],
                    [113.7403929, 10.2608029],
                    [113.7392428, 10.2599776],
                    [113.7380987, 10.2591443],
                    [113.7369606, 10.2583031],
                    [113.7358285, 10.2574541],
                    [113.7347025, 10.2565972],
                    [113.7335827, 10.2557326],
                    [113.732469, 10.2548603],
                    [113.7313616, 10.2539803],
                    [113.7302605, 10.2530926],
                    [113.7291658, 10.2521973],
                    [113.7280775, 10.2512945],
                    [113.7269957, 10.2503842],
                    [113.7259204, 10.2494664],
                    [113.7248517, 10.2485412],
                    [113.7237897, 10.2476087],
                    [113.7227343, 10.2466688],
                    [113.7216856, 10.2457216],
                    [113.7206438, 10.2447672],
                    [113.7196088, 10.2438056],
                    [113.7185807, 10.2428369],
                    [113.7175595, 10.2418611],
                    [113.7165453, 10.2408782],
                    [113.7155382, 10.2398884],
                    [113.7145381, 10.2388916],
                    [113.7135452, 10.2378879],
                    [113.7125595, 10.2368774],
                    [113.7115811, 10.23586],
                    [113.7106099, 10.2348359],
                    [113.7096461, 10.2338052],
                    [113.7086896, 10.2327678],
                    [113.7077406, 10.2317238],
                    [113.706799, 10.2306732],
                    [113.705865, 10.2296162],
                    [113.7049386, 10.2285527],
                    [113.7040198, 10.2274828],
                    [113.7031086, 10.2264066],
                    [113.7022052, 10.2253242],
                    [113.7013095, 10.2242355],
                    [113.7004216, 10.2231406],
                    [113.6995415, 10.2220396],
                    [113.6986693, 10.2209326],
                    [113.6978051, 10.2198195],
                    [113.6969488, 10.2187005],
                    [113.6961005, 10.2175756],
                    [113.6952603, 10.2164448],
                    [113.6944282, 10.2153083],
                    [113.6936042, 10.214166],
                    [113.6927884, 10.2130181],
                    [113.6919808, 10.2118645],
                    [113.6911815, 10.2107054],
                    [113.6903905, 10.2095407],
                    [113.6896078, 10.2083706],
                    [113.6888335, 10.2071952],
                    [113.6880676, 10.2060144],
                    [113.6873101, 10.2048283],
                    [113.6865612, 10.203637],
                    [113.6858207, 10.2024406],
                    [113.6850888, 10.201239],
                    [113.6843656, 10.2000324],
                    [113.6836509, 10.1988209],
                    [113.682945, 10.1976044],
                    [113.6822477, 10.1963831],
                    [113.6815592, 10.195157],
                    [113.6808794, 10.1939261],
                    [113.6802084, 10.1926905],
                    [113.6795463, 10.1914504],
                    [113.6788931, 10.1902057],
                    [113.6782487, 10.1889565],
                    [113.6776133, 10.1877028],
                    [113.6769869, 10.1864448],
                    [113.6763695, 10.1851825],
                    [113.6757611, 10.183916],
                    [113.6751617, 10.1826452],
                    [113.6745715, 10.1813703],
                    [113.6739903, 10.1800914],
                    [113.6734183, 10.1788085],
                    [113.6728555, 10.1775217],
                    [113.6723019, 10.176231],
                    [113.6717575, 10.1749365],
                    [113.6712224, 10.1736382],
                    [113.6706965, 10.1723363],
                    [113.67018, 10.1710308],
                    [113.6696728, 10.1697217],
                    [113.669175, 10.1684091],
                    [113.6686865, 10.1670931],
                    [113.6682075, 10.1657738],
                    [113.6677378, 10.1644512],
                    [113.6672777, 10.1631253],
                    [113.666827, 10.1617963],
                    [113.6663858, 10.1604643],
                    [113.6659541, 10.1591291],
                    [113.665532, 10.1577911],
                    [113.6651195, 10.1564501],
                    [113.6647165, 10.1551063],
                    [113.6643231, 10.1537598],
                    [113.6639394, 10.1524105],
                    [113.6635653, 10.1510587],
                    [113.6632009, 10.1497042],
                    [113.6628461, 10.1483473],
                    [113.6625011, 10.1469879],
                    [113.6621657, 10.1456262],
                    [113.6618401, 10.1442622],
                    [113.6615242, 10.142896],
                    [113.6612181, 10.1415276],
                    [113.6609218, 10.1401571],
                    [113.6606353, 10.1387846],
                    [113.6603585, 10.1374102],
                    [113.6600916, 10.1360339],
                    [113.6598345, 10.1346557],
                    [113.6595873, 10.1332758],
                    [113.6593499, 10.1318942],
                    [113.6591224, 10.130511],
                    [113.6456989, 10.0490966],
                    [113.6454418, 10.0477181],
                    [113.6451946, 10.0463378],
                    [113.6449572, 10.0449558],
                    [113.6447297, 10.0435722],
                    [113.6445121, 10.0421871],
                    [113.6443043, 10.0408005],
                    [113.6441065, 10.0394125],
                    [113.6439186, 10.0380232],
                    [113.6437406, 10.0366325],
                    [113.6435725, 10.0352407],
                    [113.6434144, 10.0338477],
                    [113.6432662, 10.0324537],
                    [113.643128, 10.0310587],
                    [113.6429997, 10.0296627],
                    [113.6428814, 10.0282659],
                    [113.6427731, 10.0268683],
                    [113.6426747, 10.02547],
                    [113.6425864, 10.024071],
                    [113.642508, 10.0226715],
                    [113.6424396, 10.0212714],
                    [113.6423812, 10.0198709],
                    [113.6423328, 10.01847],
                    [113.6422944, 10.0170688],
                    [113.642266, 10.0156674],
                    [113.6422476, 10.0142657],
                    [113.6422392, 10.012864],
                ],
            ],
            [
                [
                    [114.0970326, 11.3873651],
                    [114.097036, 11.3859698],
                    [114.0970494, 11.3845745],
                    [114.0970728, 11.3831794],
                    [114.0971063, 11.3817844],
                    [114.0971498, 11.3803897],
                    [114.0972034, 11.3789954],
                    [114.097267, 11.3776014],
                    [114.0973406, 11.3762079],
                    [114.0974243, 11.374815],
                    [114.097518, 11.3734226],
                    [114.0976217, 11.3720309],
                    [114.0977355, 11.37064],
                    [114.0978592, 11.3692499],
                    [114.097993, 11.3678606],
                    [114.0981368, 11.3664724],
                    [114.0982905, 11.3650851],
                    [114.0984543, 11.363699],
                    [114.098628, 11.362314],
                    [114.0988117, 11.3609302],
                    [114.0990053, 11.3595477],
                    [114.0992089, 11.3581666],
                    [114.0994225, 11.3567869],
                    [114.0996459, 11.3554088],
                    [114.0998793, 11.3540322],
                    [114.1001226, 11.3526573],
                    [114.1003758, 11.351284],
                    [114.1006389, 11.3499126],
                    [114.1009118, 11.348543],
                    [114.1011946, 11.3471753],
                    [114.1014873, 11.3458096],
                    [114.1017897, 11.3444459],
                    [114.102102, 11.3430844],
                    [114.1024241, 11.3417251],
                    [114.1027559, 11.340368],
                    [114.1030975, 11.3390132],
                    [114.1034489, 11.3376609],
                    [114.10381, 11.336311],
                    [114.1041808, 11.3349636],
                    [114.1045613, 11.3336188],
                    [114.1049514, 11.3322767],
                    [114.1053513, 11.3309373],
                    [114.1057607, 11.3296007],
                    [114.1061798, 11.328267],
                    [114.1066084, 11.3269361],
                    [114.1070467, 11.3256083],
                    [114.1074944, 11.3242836],
                    [114.1079517, 11.3229619],
                    [114.1084186, 11.3216435],
                    [114.1088948, 11.3203283],
                    [114.1093806, 11.3190165],
                    [114.1098758, 11.317708],
                    [114.1103804, 11.316403],
                    [114.1108943, 11.3151015],
                    [114.1114177, 11.3138036],
                    [114.1119503, 11.3125093],
                    [114.1124923, 11.3112188],
                    [114.1130435, 11.309932],
                    [114.113604, 11.3086491],
                    [114.1141737, 11.3073701],
                    [114.1147526, 11.306095],
                    [114.1153407, 11.304824],
                    [114.1159379, 11.3035571],
                    [114.1165442, 11.3022943],
                    [114.1171596, 11.3010358],
                    [114.1177841, 11.2997816],
                    [114.1184175, 11.2985317],
                    [114.11906, 11.2972862],
                    [114.1197113, 11.2960451],
                    [114.1203716, 11.2948087],
                    [114.1210408, 11.2935768],
                    [114.1217189, 11.2923496],
                    [114.1224057, 11.2911271],
                    [114.1231014, 11.2899093],
                    [114.1238058, 11.2886964],
                    [114.1245189, 11.2874885],
                    [114.1252406, 11.2862854],
                    [114.1259711, 11.2850874],
                    [114.1267101, 11.2838945],
                    [114.1274577, 11.2827068],
                    [114.1282138, 11.2815242],
                    [114.1289785, 11.2803469],
                    [114.1297515, 11.2791749],
                    [114.130533, 11.2780083],
                    [114.1313229, 11.2768471],
                    [114.1321211, 11.2756914],
                    [114.1329277, 11.2745412],
                    [114.1337424, 11.2733967],
                    [114.1345654, 11.2722578],
                    [114.1353966, 11.2711247],
                    [114.1362359, 11.2699973],
                    [114.1370833, 11.2688758],
                    [114.1379388, 11.2677601],
                    [114.1388022, 11.2666504],
                    [114.1396737, 11.2655468],
                    [114.140553, 11.2644491],
                    [114.1414403, 11.2633576],
                    [114.1423353, 11.2622722],
                    [114.1432382, 11.2611931],
                    [114.1441488, 11.2601202],
                    [114.1450671, 11.2590537],
                    [114.1459931, 11.2579935],
                    [114.1469266, 11.2569398],
                    [114.1478678, 11.2558926],
                    [114.1488164, 11.2548519],
                    [114.1497725, 11.2538178],
                    [114.1507361, 11.2527903],
                    [114.151707, 11.2517695],
                    [114.1526852, 11.2507555],
                    [114.1536707, 11.2497483],
                    [114.1546634, 11.2487479],
                    [114.1556633, 11.2477544],
                    [114.1566704, 11.2467678],
                    [114.1576845, 11.2457883],
                    [114.1587056, 11.2448158],
                    [114.1597337, 11.2438504],
                    [114.1607687, 11.2428921],
                    [114.1618106, 11.241941],
                    [114.1628594, 11.2409971],
                    [114.1639149, 11.2400606],
                    [114.1649771, 11.2391313],
                    [114.1660459, 11.2382094],
                    [114.1671214, 11.237295],
                    [114.1682034, 11.236388],
                    [114.169292, 11.2354885],
                    [114.1703869, 11.2345966],
                    [114.1714883, 11.2337123],
                    [114.172596, 11.2328356],
                    [114.17371, 11.2319667],
                    [114.1748302, 11.2311054],
                    [114.1759566, 11.230252],
                    [114.1770891, 11.2294063],
                    [114.1782277, 11.2285685],
                    [114.1793722, 11.2277386],
                    [114.1805227, 11.2269167],
                    [114.1816791, 11.2261027],
                    [114.1828414, 11.2252968],
                    [114.183725, 11.2246932],
                    [114.4001558, 11.2163171],
                    [114.4008842, 11.2167341],
                    [114.4021084, 11.2174463],
                    [114.4033275, 11.218167],
                    [114.4045413, 11.2188961],
                    [114.4057498, 11.2196336],
                    [114.406953, 11.2203795],
                    [114.4081508, 11.2211336],
                    [114.4093432, 11.2218961],
                    [114.41053, 11.2226667],
                    [114.4117112, 11.2234456],
                    [114.4128869, 11.2242326],
                    [114.4140568, 11.2250278],
                    [114.415221, 11.225831],
                    [114.4163793, 11.2266423],
                    [114.4175318, 11.2274615],
                    [114.4186784, 11.2282888],
                    [114.419819, 11.2291239],
                    [114.4209535, 11.2299669],
                    [114.4220819, 11.2308178],
                    [114.4232042, 11.2316764],
                    [114.4243203, 11.2325428],
                    [114.4254302, 11.2334169],
                    [114.4265337, 11.2342986],
                    [114.4276308, 11.235188],
                    [114.4287215, 11.2360849],
                    [114.4298057, 11.2369894],
                    [114.4308834, 11.2379013],
                    [114.4319545, 11.2388207],
                    [114.4330189, 11.2397475],
                    [114.4340767, 11.2406816],
                    [114.4351277, 11.241623],
                    [114.4361719, 11.2425717],
                    [114.4372092, 11.2435275],
                    [114.4382397, 11.2444906],
                    [114.4392632, 11.2454607],
                    [114.4402796, 11.2464379],
                    [114.4412891, 11.2474221],
                    [114.4422914, 11.2484133],
                    [114.4432865, 11.2494113],
                    [114.4442744, 11.2504163],
                    [114.4452551, 11.251428],
                    [114.4462285, 11.2524465],
                    [114.4471945, 11.2534718],
                    [114.4481531, 11.2545036],
                    [114.4491043, 11.2555421],
                    [114.4500479, 11.2565872],
                    [114.450984, 11.2576387],
                    [114.4519126, 11.2586967],
                    [114.4528335, 11.2597611],
                    [114.4537467, 11.2608319],
                    [114.4546521, 11.2619089],
                    [114.4555498, 11.2629922],
                    [114.4564397, 11.2640816],
                    [114.4573217, 11.2651772],
                    [114.4581958, 11.2662789],
                    [114.459062, 11.2673866],
                    [114.4599201, 11.2685002],
                    [114.4607702, 11.2696198],
                    [114.4616123, 11.2707452],
                    [114.4624462, 11.2718764],
                    [114.4632719, 11.2730134],
                    [114.4640895, 11.274156],
                    [114.4648988, 11.2753043],
                    [114.4656998, 11.2764581],
                    [114.4664925, 11.2776174],
                    [114.4672768, 11.2787822],
                    [114.4680527, 11.2799524],
                    [114.4688202, 11.281128],
                    [114.4695792, 11.2823088],
                    [114.4703296, 11.2834948],
                    [114.471091, 11.2847177],
                    [114.4716738, 11.2852701],
                    [114.4726903, 11.2862472],
                    [114.4736997, 11.2872312],
                    [114.474702, 11.2882223],
                    [114.4756972, 11.2892202],
                    [114.4766851, 11.290225],
                    [114.4776658, 11.2912366],
                    [114.4786392, 11.292255],
                    [114.4796052, 11.2932801],
                    [114.4805638, 11.2943118],
                    [114.4815149, 11.2953501],
                    [114.4824586, 11.296395],
                    [114.4833947, 11.2974464],
                    [114.4843232, 11.2985043],
                    [114.4852487, 11.2995739],
                    [114.4826139, 11.3025274],
                    [114.4799717, 11.3054746],
                    [114.4773221, 11.3084153],
                    [114.4746651, 11.3113496],
                    [114.4720008, 11.3142774],
                    [114.469329, 11.3171987],
                    [114.46665, 11.3201135],
                    [114.4639636, 11.3230218],
                    [114.4612699, 11.3259236],
                    [114.4585688, 11.3288188],
                    [114.4558606, 11.3317074],
                    [114.453145, 11.3345894],
                    [114.4504222, 11.3374649],
                    [114.4476922, 11.3403337],
                    [114.444955, 11.3431958],
                    [114.4422105, 11.3460513],
                    [114.4394589, 11.3489001],
                    [114.4367001, 11.3517422],
                    [114.4339342, 11.3545776],
                    [114.4311612, 11.3574063],
                    [114.428381, 11.3602283],
                    [114.4255938, 11.3630434],
                    [114.4227994, 11.3658518],
                    [114.419998, 11.3686534],
                    [114.4171896, 11.3714482],
                    [114.4143741, 11.3742361],
                    [114.4115517, 11.3770172],
                    [114.4087222, 11.3797915],
                    [114.4058858, 11.3825588],
                    [114.4030424, 11.3853193],
                    [114.400192, 11.3880728],
                    [114.3973348, 11.3908194],
                    [114.3944706, 11.3935591],
                    [114.3915995, 11.3962918],
                    [114.3887216, 11.3990175],
                    [114.3858368, 11.4017362],
                    [114.3829452, 11.4044479],
                    [114.3800468, 11.4071526],
                    [114.3771415, 11.4098502],
                    [114.3742295, 11.4125408],
                    [114.3713107, 11.4152243],
                    [114.3683852, 11.4179007],
                    [114.3654529, 11.42057],
                    [114.362514, 11.4232322],
                    [114.3595683, 11.4258872],
                    [114.356616, 11.428535],
                    [114.353657, 11.4311757],
                    [114.3506913, 11.4338092],
                    [114.3477191, 11.4364355],
                    [114.3447402, 11.4390546],
                    [114.3417547, 11.4416664],
                    [114.3387638, 11.4442701],
                    [114.3357663, 11.4468664],
                    [114.3327623, 11.4494555],
                    [114.3297518, 11.4520373],
                    [114.3267348, 11.4546118],
                    [114.3237113, 11.457179],
                    [114.3206814, 11.4597388],
                    [114.317645, 11.4622913],
                    [114.3146023, 11.4648364],
                    [114.3115531, 11.4673741],
                    [114.3084975, 11.4699044],
                    [114.3054356, 11.4724272],
                    [114.3023674, 11.4749427],
                    [114.2992928, 11.4774507],
                    [114.2962119, 11.4799512],
                    [114.2931247, 11.4824443],
                    [114.2900313, 11.4849299],
                    [114.2869316, 11.4874079],
                    [114.2838257, 11.4898785],
                    [114.2807136, 11.4923415],
                    [114.2775953, 11.4947969],
                    [114.2744708, 11.4972448],
                    [114.2713401, 11.4996851],
                    [114.2682034, 11.5021178],
                    [114.2650605, 11.5045429],
                    [114.2619115, 11.5069603],
                    [114.2587564, 11.5093702],
                    [114.2555953, 11.5117723],
                    [114.2524282, 11.5141668],
                    [114.249255, 11.5165536],
                    [114.2460758, 11.5189327],
                    [114.2428907, 11.5213041],
                    [114.2396995, 11.5236678],
                    [114.2365025, 11.5260238],
                    [114.2332995, 11.5283719],
                    [114.2300906, 11.5307124],
                    [114.2268759, 11.533045],
                    [114.2236552, 11.5353698],
                    [114.2204288, 11.5376868],
                    [114.2171965, 11.539996],
                    [114.2139584, 11.5422974],
                    [114.2107145, 11.5445909],
                    [114.2074648, 11.5468765],
                    [114.2042094, 11.5491543],
                    [114.2009483, 11.5514241],
                    [114.1976815, 11.5536861],
                    [114.194409, 11.5559401],
                    [114.1911308, 11.5581862],
                    [114.187847, 11.5604244],
                    [114.1845575, 11.5626546],
                    [114.1844455, 11.5625392],
                    [114.1834722, 11.5615218],
                    [114.1825062, 11.5604976],
                    [114.1815476, 11.5594669],
                    [114.1805964, 11.5584295],
                    [114.1796527, 11.5573856],
                    [114.1787166, 11.5563351],
                    [114.1777881, 11.5552782],
                    [114.1768672, 11.5542149],
                    [114.175954, 11.5531453],
                    [114.1750485, 11.5520694],
                    [114.1741508, 11.5509872],
                    [114.173261, 11.5498988],
                    [114.172379, 11.5488043],
                    [114.1715049, 11.5477038],
                    [114.1706387, 11.5465972],
                    [114.1697806, 11.5454846],
                    [114.1689304, 11.5443661],
                    [114.1680884, 11.5432418],
                    [114.1672545, 11.5421117],
                    [114.1664287, 11.5409758],
                    [114.1656112, 11.5398342],
                    [114.1648019, 11.5386871],
                    [114.1640009, 11.5375343],
                    [114.1632082, 11.536376],
                    [114.1624239, 11.5352123],
                    [114.161648, 11.5340431],
                    [114.1608805, 11.5328686],
                    [114.1601215, 11.5316889],
                    [114.159371, 11.5305039],
                    [114.1586291, 11.5293137],
                    [114.1586097, 11.529282],
                    [114.1580268, 11.5287301],
                    [114.1570104, 11.5277539],
                    [114.1560009, 11.5267706],
                    [114.1549986, 11.5257805],
                    [114.1540035, 11.5247833],
                    [114.1530156, 11.5237794],
                    [114.1520349, 11.5227686],
                    [114.1510615, 11.521751],
                    [114.1500955, 11.5207268],
                    [114.1491369, 11.5196959],
                    [114.1481857, 11.5186584],
                    [114.1472421, 11.5176143],
                    [114.1463059, 11.5165637],
                    [114.1453774, 11.5155066],
                    [114.1444565, 11.5144432],
                    [114.1435433, 11.5133734],
                    [114.1426379, 11.5122973],
                    [114.1417402, 11.511215],
                    [114.1408503, 11.5101265],
                    [114.1399683, 11.5090318],
                    [114.1390942, 11.5079311],
                    [114.138228, 11.5068244],
                    [114.1373699, 11.5057116],
                    [114.1365198, 11.504593],
                    [114.1356777, 11.5034685],
                    [114.1348438, 11.5023382],
                    [114.1340181, 11.5012022],
                    [114.1332005, 11.5000605],
                    [114.1323912, 11.4989131],
                    [114.1315902, 11.4977602],
                    [114.1307975, 11.4966017],
                    [114.1300132, 11.4954378],
                    [114.1292373, 11.4942685],
                    [114.1284698, 11.4930939],
                    [114.1277108, 11.4919139],
                    [114.1269604, 11.4907288],
                    [114.1262185, 11.4895384],
                    [114.1254851, 11.488343],
                    [114.1247604, 11.4871425],
                    [114.1240444, 11.485937],
                    [114.1233371, 11.4847266],
                    [114.1226385, 11.4835113],
                    [114.1219487, 11.4822912],
                    [114.1212677, 11.4810664],
                    [114.1205955, 11.4798369],
                    [114.1199322, 11.4786028],
                    [114.1192778, 11.477364],
                    [114.1186324, 11.4761208],
                    [114.1179959, 11.4748732],
                    [114.1173685, 11.4736212],
                    [114.11675, 11.4723648],
                    [114.1161406, 11.4711042],
                    [114.1155404, 11.4698394],
                    [114.1149492, 11.4685705],
                    [114.1143672, 11.4672976],
                    [114.1137944, 11.4660206],
                    [114.1132308, 11.4647397],
                    [114.1126765, 11.4634549],
                    [114.1121314, 11.4621663],
                    [114.1115956, 11.4608739],
                    [114.1110691, 11.4595779],
                    [114.110552, 11.4582783],
                    [114.1100443, 11.4569751],
                    [114.1095459, 11.4556684],
                    [114.109057, 11.4543583],
                    [114.1085775, 11.4530449],
                    [114.1081075, 11.4517281],
                    [114.107647, 11.4504081],
                    [114.1071961, 11.449085],
                    [114.1067546, 11.4477588],
                    [114.1063228, 11.4464295],
                    [114.1059005, 11.4450973],
                    [114.1054878, 11.4437622],
                    [114.1050847, 11.4424243],
                    [114.1046913, 11.4410836],
                    [114.1043076, 11.4397402],
                    [114.1039335, 11.4383942],
                    [114.1035692, 11.4370456],
                    [114.1032145, 11.4356945],
                    [114.1028696, 11.434341],
                    [114.1025345, 11.4329851],
                    [114.1022091, 11.431627],
                    [114.1018936, 11.4302666],
                    [114.1015878, 11.4289041],
                    [114.1012919, 11.4275394],
                    [114.1010058, 11.4261728],
                    [114.1007295, 11.4248042],
                    [114.1004631, 11.4234337],
                    [114.1002066, 11.4220614],
                    [114.09996, 11.4206874],
                    [114.0997233, 11.4193116],
                    [114.0994965, 11.4179343],
                    [114.0992796, 11.4165554],
                    [114.0990726, 11.4151751],
                    [114.0988756, 11.4137933],
                    [114.0986886, 11.4124102],
                    [114.0985115, 11.4110259],
                    [114.0983444, 11.4096403],
                    [114.0981873, 11.4082537],
                    [114.0980402, 11.4068659],
                    [114.0979031, 11.4054772],
                    [114.0977759, 11.4040875],
                    [114.0976588, 11.402697],
                    [114.0975517, 11.4013057],
                    [114.0974546, 11.3999137],
                    [114.0973676, 11.3985211],
                    [114.0972906, 11.3971279],
                    [114.0972236, 11.3957342],
                    [114.0971667, 11.39434],
                    [114.0971198, 11.3929455],
                    [114.0970829, 11.3915506],
                    [114.0970561, 11.3901556],
                    [114.0970394, 11.3887604],
                    [114.0970326, 11.3873651],
                ],
            ],
            [
                [
                    [114.4530517, 8.8567992],
                    [114.4530603, 8.8553989],
                    [114.4530787, 8.8539987],
                    [114.4531071, 8.8525986],
                    [114.4531454, 8.8511987],
                    [114.4531937, 8.8497992],
                    [114.4532519, 8.8484],
                    [114.45332, 8.8470012],
                    [114.4533981, 8.8456029],
                    [114.453486, 8.8442053],
                    [114.4535839, 8.8428082],
                    [114.4536917, 8.8414119],
                    [114.4538094, 8.8400163],
                    [114.453937, 8.8386216],
                    [114.4540745, 8.8372277],
                    [114.4542219, 8.8358349],
                    [114.4543792, 8.8344431],
                    [114.4545463, 8.8330525],
                    [114.4547233, 8.831663],
                    [114.4549102, 8.8302748],
                    [114.4551069, 8.8288879],
                    [114.4553135, 8.8275024],
                    [114.4555298, 8.8261183],
                    [114.455756, 8.8247358],
                    [114.455992, 8.8233549],
                    [114.4562378, 8.8219757],
                    [114.4564934, 8.8205981],
                    [114.4567587, 8.8192224],
                    [114.4570338, 8.8178486],
                    [114.4573187, 8.8164766],
                    [114.4576132, 8.8151067],
                    [114.4579175, 8.8137389],
                    [114.4582315, 8.8123732],
                    [114.4585552, 8.8110097],
                    [114.4588885, 8.8096484],
                    [114.4592315, 8.8082895],
                    [114.4595841, 8.806933],
                    [114.4599464, 8.805579],
                    [114.4603182, 8.8042275],
                    [114.4606996, 8.8028787],
                    [114.4610906, 8.8015324],
                    [114.4614911, 8.800189],
                    [114.4619012, 8.7988483],
                    [114.4623207, 8.7975105],
                    [114.4627497, 8.7961757],
                    [114.4631882, 8.7948438],
                    [114.4636362, 8.793515],
                    [114.4640935, 8.7921894],
                    [114.4645603, 8.7908669],
                    [114.4650364, 8.7895477],
                    [114.4655219, 8.7882318],
                    [114.4660166, 8.7869194],
                    [114.4665207, 8.7856103],
                    [114.4670341, 8.7843048],
                    [114.4675567, 8.7830029],
                    [114.4680886, 8.7817047],
                    [114.4686296, 8.7804101],
                    [114.4691798, 8.7791193],
                    [114.4697392, 8.7778324],
                    [114.4703077, 8.7765494],
                    [114.4708853, 8.7752703],
                    [114.4714719, 8.7739953],
                    [114.4720675, 8.7727243],
                    [114.4726722, 8.7714575],
                    [114.4732859, 8.770195],
                    [114.4739084, 8.7689367],
                    [114.4745399, 8.7676827],
                    [114.4751803, 8.7664332],
                    [114.4758295, 8.7651881],
                    [114.4764876, 8.7639475],
                    [114.4771544, 8.7627116],
                    [114.47783, 8.7614802],
                    [114.4785143, 8.7602536],
                    [114.4792073, 8.7590318],
                    [114.479909, 8.7578148],
                    [114.4806192, 8.7566026],
                    [114.4813381, 8.7553955],
                    [114.4820654, 8.7541933],
                    [114.4828014, 8.7529962],
                    [114.4835457, 8.7518042],
                    [114.4842985, 8.7506174],
                    [114.4850598, 8.7494359],
                    [114.4858294, 8.7482596],
                    [114.4866073, 8.7470887],
                    [114.4873935, 8.7459233],
                    [114.4881879, 8.7447633],
                    [114.4889906, 8.7436088],
                    [114.4898014, 8.7424599],
                    [114.4906204, 8.7413167],
                    [114.4914474, 8.7401792],
                    [114.4922825, 8.7390474],
                    [114.4931256, 8.7379214],
                    [114.4939767, 8.7368013],
                    [114.4948357, 8.7356871],
                    [114.4957026, 8.734579],
                    [114.4965773, 8.7334768],
                    [114.4974599, 8.7323807],
                    [114.4983501, 8.7312908],
                    [114.4992481, 8.730207],
                    [114.5001538, 8.7291295],
                    [114.5010671, 8.7280584],
                    [114.5019879, 8.7269935],
                    [114.5029163, 8.7259351],
                    [114.5038522, 8.7248831],
                    [114.5047955, 8.7238376],
                    [114.5057462, 8.7227987],
                    [114.5067043, 8.7217664],
                    [114.5076697, 8.7207408],
                    [114.5086423, 8.7197219],
                    [114.5096221, 8.7187098],
                    [114.5106091, 8.7177044],
                    [114.5116031, 8.7167059],
                    [114.5126043, 8.7157144],
                    [114.5136124, 8.7147298],
                    [114.5146275, 8.7137522],
                    [114.5156496, 8.7127817],
                    [114.5166784, 8.7118182],
                    [114.5177141, 8.7108619],
                    [114.5187566, 8.7099129],
                    [114.5198057, 8.708971],
                    [114.5208615, 8.7080365],
                    [114.521924, 8.7071093],
                    [114.5229929, 8.7061895],
                    [114.5240684, 8.7052771],
                    [114.5251503, 8.7043722],
                    [114.5262386, 8.7034748],
                    [114.5273333, 8.702585],
                    [114.5284342, 8.7017028],
                    [114.5295413, 8.7008282],
                    [114.5306547, 8.6999614],
                    [114.5317741, 8.6991022],
                    [114.5328997, 8.6982509],
                    [114.5340312, 8.6974073],
                    [114.5351687, 8.6965717],
                    [114.5363121, 8.6957439],
                    [114.5374613, 8.6949241],
                    [114.5386164, 8.6941122],
                    [114.5397771, 8.6933084],
                    [114.5409436, 8.6925127],
                    [114.5421156, 8.691725],
                    [114.5432932, 8.6909455],
                    [114.5444763, 8.6901742],
                    [114.5456649, 8.6894111],
                    [114.5468588, 8.6886562],
                    [114.5480581, 8.6879097],
                    [114.5492626, 8.6871715],
                    [114.5504723, 8.6864416],
                    [114.5516872, 8.6857201],
                    [114.5529072, 8.6850071],
                    [114.5541322, 8.6843026],
                    [114.5553622, 8.6836065],
                    [114.556597, 8.682919],
                    [114.5578367, 8.6822401],
                    [114.5590813, 8.6815698],
                    [114.5603305, 8.6809081],
                    [114.5615844, 8.6802552],
                    [114.5628429, 8.6796109],
                    [114.5641059, 8.6789753],
                    [114.5653734, 8.6783485],
                    [114.5666453, 8.6777306],
                    [114.5679216, 8.6771214],
                    [114.5692022, 8.6765211],
                    [114.570487, 8.6759297],
                    [114.5717759, 8.6753473],
                    [114.573069, 8.6747737],
                    [114.5743661, 8.6742092],
                    [114.5756671, 8.6736537],
                    [114.5769721, 8.6731071],
                    [114.5782809, 8.6725697],
                    [114.5795935, 8.6720413],
                    [114.5809099, 8.6715221],
                    [114.5822298, 8.671012],
                    [114.5835534, 8.670511],
                    [114.5848805, 8.6700192],
                    [114.586211, 8.6695367],
                    [114.5875449, 8.6690634],
                    [114.5888822, 8.6685993],
                    [114.5902227, 8.6681445],
                    [114.5915664, 8.667699],
                    [114.5929132, 8.6672629],
                    [114.5942631, 8.6668361],
                    [114.5956159, 8.6664186],
                    [114.5969717, 8.6660106],
                    [114.5983304, 8.6656119],
                    [114.5996919, 8.6652227],
                    [114.6010561, 8.664843],
                    [114.6024229, 8.6644727],
                    [114.6037924, 8.6641118],
                    [114.6051643, 8.6637605],
                    [114.6065388, 8.6634187],
                    [114.6079156, 8.6630865],
                    [114.6092947, 8.6627638],
                    [114.6106761, 8.6624506],
                    [114.6120597, 8.6621471],
                    [114.6134454, 8.6618531],
                    [114.6148332, 8.6615688],
                    [114.6162229, 8.6612941],
                    [114.6176145, 8.661029],
                    [114.619008, 8.6607736],
                    [114.6204033, 8.6605278],
                    [114.6218003, 8.6602918],
                    [114.6231989, 8.6600654],
                    [114.6245991, 8.6598487],
                    [114.6260008, 8.6596417],
                    [114.6274039, 8.6594445],
                    [114.6288084, 8.659257],
                    [114.6302142, 8.6590792],
                    [114.6316212, 8.6589112],
                    [114.6330294, 8.6587529],
                    [114.6344387, 8.6586044],
                    [114.635849, 8.6584657],
                    [114.6372602, 8.6583367],
                    [114.6386723, 8.6582175],
                    [114.6400852, 8.6581082],
                    [114.6414989, 8.6580086],
                    [114.6429132, 8.6579188],
                    [114.6443282, 8.6578389],
                    [114.6457436, 8.6577687],
                    [114.6471596, 8.6577084],
                    [114.6485759, 8.6576579],
                    [114.6499925, 8.6576172],
                    [114.6514094, 8.6575863],
                    [114.6528265, 8.6575652],
                    [114.6542437, 8.657554],
                    [114.655661, 8.6575526],
                    [114.6570782, 8.657561],
                    [114.6584953, 8.6575793],
                    [114.6599123, 8.6576074],
                    [114.661329, 8.6576452],
                    [114.6627454, 8.657693],
                    [114.6641615, 8.6577505],
                    [114.6655771, 8.6578178],
                    [114.6669922, 8.657895],
                    [114.6684067, 8.657982],
                    [114.6698205, 8.6580787],
                    [114.6712337, 8.6581853],
                    [114.672646, 8.6583017],
                    [114.6740575, 8.6584278],
                    [114.6754681, 8.6585638],
                    [114.6768776, 8.6587095],
                    [114.6782861, 8.6588649],
                    [114.6796935, 8.6590302],
                    [114.6810996, 8.6592052],
                    [114.6825045, 8.6593899],
                    [114.683908, 8.6595844],
                    [114.6853102, 8.6597886],
                    [114.6867108, 8.6600025],
                    [114.6881099, 8.6602261],
                    [114.6895073, 8.6604594],
                    [114.6909031, 8.6607024],
                    [114.6922971, 8.6609551],
                    [114.6936893, 8.6612174],
                    [114.6950796, 8.6614893],
                    [114.6964679, 8.6617709],
                    [114.6978542, 8.6620621],
                    [114.6992384, 8.6623629],
                    [114.7006204, 8.6626733],
                    [114.7020002, 8.6629933],
                    [114.7033777, 8.6633229],
                    [114.7047528, 8.6636619],
                    [114.7061255, 8.6640105],
                    [114.7074957, 8.6643686],
                    [114.7088633, 8.6647362],
                    [114.7102282, 8.6651133],
                    [114.7115905, 8.6654998],
                    [114.7129499, 8.6658957],
                    [114.7143066, 8.6663011],
                    [114.7156603, 8.6667159],
                    [114.717011, 8.66714],
                    [114.7183587, 8.6675735],
                    [114.7197033, 8.6680163],
                    [114.7210448, 8.6684684],
                    [114.722383, 8.6689298],
                    [114.7237178, 8.6694005],
                    [114.7250493, 8.6698804],
                    [114.7263774, 8.6703696],
                    [114.727702, 8.6708679],
                    [114.729023, 8.6713754],
                    [114.7303403, 8.6718921],
                    [114.731654, 8.6724178],
                    [114.7329639, 8.6729527],
                    [114.73427, 8.6734966],
                    [114.7355722, 8.6740496],
                    [114.7368704, 8.6746116],
                    [114.7381646, 8.6751825],
                    [114.7394548, 8.6757624],
                    [114.7407408, 8.6763513],
                    [114.7420225, 8.676949],
                    [114.7433, 8.6775557],
                    [114.7445732, 8.6781711],
                    [114.745842, 8.6787954],
                    [114.7471063, 8.6794284],
                    [114.7483661, 8.6800702],
                    [114.7496213, 8.6807207],
                    [114.7508719, 8.6813799],
                    [114.7521178, 8.6820478],
                    [114.7533588, 8.6827242],
                    [114.7545951, 8.6834093],
                    [114.7558265, 8.6841029],
                    [114.7570529, 8.684805],
                    [114.7582743, 8.6855156],
                    [114.7594907, 8.6862346],
                    [114.7607019, 8.6869621],
                    [114.7619079, 8.6876979],
                    [114.7631087, 8.6884421],
                    [114.7643041, 8.6891946],
                    [114.7654942, 8.6899554],
                    [114.7666789, 8.6907243],
                    [114.7678581, 8.6915015],
                    [114.7690317, 8.6922868],
                    [114.7701998, 8.6930803],
                    [114.7713621, 8.6938818],
                    [114.7725188, 8.6946913],
                    [114.7736697, 8.6955089],
                    [114.7748148, 8.6963344],
                    [114.775954, 8.6971678],
                    [114.7770872, 8.6980091],
                    [114.7782145, 8.6988582],
                    [114.7793357, 8.6997151],
                    [114.7804508, 8.7005798],
                    [114.7815597, 8.7014522],
                    [114.7826624, 8.7023322],
                    [114.7837589, 8.7032199],
                    [114.784849, 8.7041151],
                    [114.7859327, 8.7050179],
                    [114.78701, 8.7059281],
                    [114.7880809, 8.7068458],
                    [114.7891452, 8.7077709],
                    [114.7902029, 8.7087033],
                    [114.7912539, 8.7096431],
                    [114.7922983, 8.7105901],
                    [114.7933359, 8.7115443],
                    [114.7943668, 8.7125057],
                    [114.7953907, 8.7134742],
                    [114.7964078, 8.7144498],
                    [114.797418, 8.7154324],
                    [114.7984211, 8.716422],
                    [114.7994172, 8.7174185],
                    [114.8004062, 8.7184219],
                    [114.8013881, 8.7194321],
                    [114.8023628, 8.7204491],
                    [114.8033302, 8.7214728],
                    [114.8042904, 8.7225032],
                    [114.8052432, 8.7235402],
                    [114.8061886, 8.7245838],
                    [114.8071266, 8.7256339],
                    [114.8080571, 8.7266905],
                    [114.8089802, 8.7277535],
                    [114.8098956, 8.7288229],
                    [114.8108035, 8.7298986],
                    [114.8117036, 8.7309806],
                    [114.8125961, 8.7320687],
                    [114.8134809, 8.7331631],
                    [114.8143578, 8.7342635],
                    [114.815227, 8.73537],
                    [114.8160882, 8.7364824],
                    [114.8169416, 8.7376009],
                    [114.817787, 8.7387251],
                    [114.8186244, 8.7398553],
                    [114.8194537, 8.7409912],
                    [114.820275, 8.7421328],
                    [114.8210882, 8.74328],
                    [114.8218932, 8.7444329],
                    [114.82269, 8.7455913],
                    [114.8234785, 8.7467553],
                    [114.8242588, 8.7479246],
                    [114.8250308, 8.7490993],
                    [114.8257944, 8.7502794],
                    [114.8265496, 8.7514647],
                    [114.8272964, 8.7526552],
                    [114.8280347, 8.7538508],
                    [114.8287645, 8.7550515],
                    [114.8294858, 8.7562573],
                    [114.8301986, 8.757468],
                    [114.8309027, 8.7586836],
                    [114.8315981, 8.7599041],
                    [114.8322849, 8.7611294],
                    [114.832963, 8.7623593],
                    [114.8336323, 8.763594],
                    [114.8342929, 8.7648332],
                    [114.8349446, 8.766077],
                    [114.8355876, 8.7673253],
                    [114.8362216, 8.768578],
                    [114.8368467, 8.7698351],
                    [114.8374629, 8.7710964],
                    [114.8380702, 8.772362],
                    [114.8386684, 8.7736318],
                    [114.8392576, 8.7749056],
                    [114.8398378, 8.7761836],
                    [114.8404089, 8.7774655],
                    [114.8409708, 8.7787513],
                    [114.8415237, 8.780041],
                    [114.8420673, 8.7813345],
                    [114.8426018, 8.7826317],
                    [114.8431271, 8.7839326],
                    [114.8436431, 8.785237],
                    [114.8441498, 8.7865451],
                    [114.8446473, 8.7878566],
                    [114.8451354, 8.7891715],
                    [114.8456142, 8.7904897],
                    [114.8460836, 8.7918113],
                    [114.8465437, 8.793136],
                    [114.8469943, 8.7944639],
                    [114.8474355, 8.7957949],
                    [114.8478672, 8.7971289],
                    [114.8482895, 8.7984659],
                    [114.8487022, 8.7998057],
                    [114.8491055, 8.8011484],
                    [114.8494992, 8.8024938],
                    [114.8498833, 8.8038419],
                    [114.8502579, 8.8051927],
                    [114.8506229, 8.806546],
                    [114.8509783, 8.8079018],
                    [114.851324, 8.80926],
                    [114.8516601, 8.8106206],
                    [114.8519865, 8.8119834],
                    [114.8523033, 8.8133485],
                    [114.8526103, 8.8147158],
                    [114.8529077, 8.8160851],
                    [114.8531953, 8.8174565],
                    [114.8534732, 8.8188298],
                    [114.8537413, 8.820205],
                    [114.8539997, 8.821582],
                    [114.8542483, 8.8229607],
                    [114.8544871, 8.8243412],
                    [114.8547161, 8.8257233],
                    [114.8549352, 8.8271069],
                    [114.8551446, 8.828492],
                    [114.8553441, 8.8298785],
                    [114.8555338, 8.8312663],
                    [114.8557136, 8.8326554],
                    [114.8558836, 8.8340458],
                    [114.8560437, 8.8354372],
                    [114.8561939, 8.8368298],
                    [114.8563342, 8.8382233],
                    [114.8564647, 8.8396178],
                    [114.8565852, 8.8410131],
                    [114.8566958, 8.8424092],
                    [114.8567965, 8.8438061],
                    [114.8568873, 8.8452036],
                    [114.8569682, 8.8466017],
                    [114.8570392, 8.8480004],
                    [114.8571002, 8.8493994],
                    [114.8571513, 8.8507989],
                    [114.8571925, 8.8521987],
                    [114.8572237, 8.8535987],
                    [114.857245, 8.8549989],
                    [114.8572564, 8.8563992],
                    [114.8572578, 8.8577995],
                    [114.8572493, 8.8591999],
                    [114.8572308, 8.8606001],
                    [114.8572024, 8.8620001],
                    [114.8571641, 8.8633999],
                    [114.8571158, 8.8647994],
                    [114.8570576, 8.8661986],
                    [114.8569895, 8.8675973],
                    [114.8569115, 8.8689954],
                    [114.8568235, 8.870393],
                    [114.8567256, 8.87179],
                    [114.8566178, 8.8731862],
                    [114.8565001, 8.8745817],
                    [114.8563725, 8.8759762],
                    [114.856235, 8.8773699],
                    [114.8560876, 8.8787626],
                    [114.8559303, 8.8801542],
                    [114.8557632, 8.8815447],
                    [114.8555862, 8.882934],
                    [114.8553993, 8.884322],
                    [114.8552026, 8.8857087],
                    [114.8549961, 8.887094],
                    [114.8547797, 8.8884778],
                    [114.8545535, 8.88986],
                    [114.8543175, 8.8912407],
                    [114.8540717, 8.8926197],
                    [114.8538161, 8.893997],
                    [114.8535508, 8.8953724],
                    [114.8532757, 8.896746],
                    [114.8529908, 8.8981176],
                    [114.8526963, 8.8994872],
                    [114.852392, 8.9008547],
                    [114.852078, 8.9022201],
                    [114.8517543, 8.9035833],
                    [114.851421, 8.9049442],
                    [114.851078, 8.9063027],
                    [114.8507254, 8.9076588],
                    [114.8503632, 8.9090125],
                    [114.8499913, 8.9103636],
                    [114.8496099, 8.911712],
                    [114.8492189, 8.9130578],
                    [114.8488184, 8.9144009],
                    [114.8484084, 8.9157411],
                    [114.8479888, 8.9170785],
                    [114.8475598, 8.9184129],
                    [114.8471213, 8.9197443],
                    [114.8466733, 8.9210727],
                    [114.846216, 8.9223979],
                    [114.8457493, 8.9237199],
                    [114.8452731, 8.9250386],
                    [114.8447877, 8.9263539],
                    [114.8442929, 8.9276659],
                    [114.8437888, 8.9289744],
                    [114.8432754, 8.9302794],
                    [114.8427528, 8.9315808],
                    [114.8422209, 8.9328786],
                    [114.8416799, 8.9341726],
                    [114.8411297, 8.9354628],
                    [114.8405703, 8.9367492],
                    [114.8400018, 8.9380317],
                    [114.8394243, 8.9393102],
                    [114.8388376, 8.9405846],
                    [114.838242, 8.941855],
                    [114.8376373, 8.9431212],
                    [114.8370237, 8.9443832],
                    [114.8364011, 8.9456409],
                    [114.8357696, 8.9468942],
                    [114.8351292, 8.9481431],
                    [114.83448, 8.9493876],
                    [114.8338219, 8.9506275],
                    [114.8331551, 8.9518629],
                    [114.8324795, 8.9530936],
                    [114.8317952, 8.9543195],
                    [114.8311022, 8.9555407],
                    [114.8304006, 8.9567571],
                    [114.8296903, 8.9579685],
                    [114.8289715, 8.9591751],
                    [114.8282441, 8.9603766],
                    [114.8275082, 8.961573],
                    [114.8267638, 8.9627643],
                    [114.826011, 8.9639504],
                    [114.8252497, 8.9651312],
                    [114.8244802, 8.9663068],
                    [114.8237023, 8.967477],
                    [114.8229161, 8.9686418],
                    [114.8221216, 8.9698011],
                    [114.8213189, 8.9709548],
                    [114.8205081, 8.972103],
                    [114.8196892, 8.9732455],
                    [114.8188621, 8.9743823],
                    [114.818027, 8.9755134],
                    [114.8171839, 8.9766386],
                    [114.8163328, 8.977758],
                    [114.8154738, 8.9788714],
                    [114.8146069, 8.9799789],
                    [114.8137322, 8.9810803],
                    [114.8128497, 8.9821756],
                    [114.8119594, 8.9832648],
                    [114.8110614, 8.9843478],
                    [114.8101557, 8.9854245],
                    [114.8092424, 8.986495],
                    [114.8083216, 8.9875591],
                    [114.8073932, 8.9886168],
                    [114.8064573, 8.989668],
                    [114.805514, 8.9907127],
                    [114.8045633, 8.9917508],
                    [114.8036052, 8.9927824],
                    [114.8026399, 8.9938072],
                    [114.8016673, 8.9948254],
                    [114.8006874, 8.9958368],
                    [114.7997005, 8.9968414],
                    [114.7987064, 8.9978391],
                    [114.7977052, 8.9988299],
                    [114.7966971, 8.9998137],
                    [114.795682, 9.0007906],
                    [114.79466, 9.0017603],
                    [114.7936311, 9.002723],
                    [114.7925954, 9.0036785],
                    [114.7915529, 9.0046268],
                    [114.7905038, 9.0055679],
                    [114.789448, 9.0065017],
                    [114.7883856, 9.0074281],
                    [114.7873166, 9.0083472],
                    [114.7862411, 9.0092588],
                    [114.7851592, 9.010163],
                    [114.7840709, 9.0110596],
                    [114.7829763, 9.0119487],
                    [114.7818753, 9.0128301],
                    [114.7807682, 9.013704],
                    [114.7796548, 9.0145701],
                    [114.7785354, 9.0154285],
                    [114.7774098, 9.0162791],
                    [114.7762783, 9.0171219],
                    [114.7751408, 9.0179569],
                    [114.7739974, 9.0187839],
                    [114.7728482, 9.019603],
                    [114.7716931, 9.0204141],
                    [114.7705324, 9.0212172],
                    [114.769366, 9.0220123],
                    [114.7681939, 9.0227992],
                    [114.7670163, 9.023578],
                    [114.7658332, 9.0243486],
                    [114.7646446, 9.025111],
                    [114.7634507, 9.0258652],
                    [114.7622514, 9.0266111],
                    [114.7610469, 9.0273486],
                    [114.7598372, 9.0280778],
                    [114.7586223, 9.0287986],
                    [114.7574023, 9.0295109],
                    [114.7561773, 9.0302148],
                    [114.7549474, 9.0309102],
                    [114.7537125, 9.031597],
                    [114.7524728, 9.0322753],
                    [114.7512283, 9.032945],
                    [114.749979, 9.033606],
                    [114.7487251, 9.0342584],
                    [114.7474667, 9.0349021],
                    [114.7462036, 9.035537],
                    [114.7449361, 9.0361632],
                    [114.7436642, 9.0367805],
                    [114.7423879, 9.0373891],
                    [114.7411073, 9.0379888],
                    [114.7398225, 9.0385796],
                    [114.7385336, 9.0391615],
                    [114.7372405, 9.0397344],
                    [114.7359434, 9.0402984],
                    [114.7346424, 9.0408534],
                    [114.7333374, 9.0413994],
                    [114.7320286, 9.0419363],
                    [114.730716, 9.0424641],
                    [114.7293997, 9.0429829],
                    [114.7280797, 9.0434925],
                    [114.7267561, 9.0439929],
                    [114.7254291, 9.0444842],
                    [114.7240985, 9.0449662],
                    [114.7227646, 9.0454391],
                    [114.7214274, 9.0459027],
                    [114.7200869, 9.046357],
                    [114.7187432, 9.046802],
                    [114.7173963, 9.0472377],
                    [114.7160465, 9.0476641],
                    [114.7146936, 9.0480811],
                    [114.7133378, 9.0484887],
                    [114.7119791, 9.0488869],
                    [114.7106176, 9.0492757],
                    [114.7092534, 9.0496551],
                    [114.7078866, 9.050025],
                    [114.7065172, 9.0503855],
                    [114.7051452, 9.0507364],
                    [114.7037708, 9.0510778],
                    [114.7023939, 9.0514097],
                    [114.7010148, 9.0517321],
                    [114.6996334, 9.0520449],
                    [114.6982498, 9.0523481],
                    [114.6968641, 9.0526418],
                    [114.6954764, 9.0529258],
                    [114.6940866, 9.0532002],
                    [114.692695, 9.053465],
                    [114.6913015, 9.0537202],
                    [114.6899062, 9.0539657],
                    [114.6885092, 9.0542015],
                    [114.6871106, 9.0544276],
                    [114.6857104, 9.0546441],
                    [114.6843087, 9.0548508],
                    [114.6829056, 9.0550479],
                    [114.6815011, 9.0552352],
                    [114.6800953, 9.0554128],
                    [114.6786883, 9.0555806],
                    [114.6772801, 9.0557387],
                    [114.6758708, 9.0558871],
                    [114.6744606, 9.0560256],
                    [114.6730493, 9.0561544],
                    [114.6716372, 9.0562735],
                    [114.6702243, 9.0563827],
                    [114.6688106, 9.0564822],
                    [114.6673963, 9.0565719],
                    [114.6659814, 9.0566517],
                    [114.6645659, 9.0567218],
                    [114.66315, 9.0567821],
                    [114.6617336, 9.0568325],
                    [114.660317, 9.0568732],
                    [114.6589001, 9.056904],
                    [114.657483, 9.0569251],
                    [114.6560658, 9.0569363],
                    [114.6546486, 9.0569377],
                    [114.6532313, 9.0569293],
                    [114.6518142, 9.056911],
                    [114.6503973, 9.056883],
                    [114.6489805, 9.0568452],
                    [114.6475641, 9.0567975],
                    [114.6461481, 9.05674],
                    [114.6447324, 9.0566727],
                    [114.6433173, 9.0565957],
                    [114.6419028, 9.0565088],
                    [114.640489, 9.0564121],
                    [114.6390758, 9.0563057],
                    [114.6376635, 9.0561894],
                    [114.636252, 9.0560634],
                    [114.6348414, 9.0559276],
                    [114.6334319, 9.0557821],
                    [114.6320234, 9.0556268],
                    [114.630616, 9.0554617],
                    [114.6292099, 9.0552869],
                    [114.627805, 9.0551024],
                    [114.6264015, 9.0549081],
                    [114.6249994, 9.0547041],
                    [114.6235987, 9.0544905],
                    [114.6221997, 9.0542671],
                    [114.6208022, 9.054034],
                    [114.6194064, 9.0537913],
                    [114.6180124, 9.0535389],
                    [114.6166202, 9.0532769],
                    [114.6152299, 9.0530052],
                    [114.6138416, 9.0527239],
                    [114.6124553, 9.052433],
                    [114.6110711, 9.0521325],
                    [114.6096891, 9.0518224],
                    [114.6083093, 9.0515028],
                    [114.6069318, 9.0511736],
                    [114.6055567, 9.0508349],
                    [114.604184, 9.0504867],
                    [114.6028139, 9.0501289],
                    [114.6014463, 9.0497617],
                    [114.6000813, 9.0493851],
                    [114.598719, 9.048999],
                    [114.5973596, 9.0486034],
                    [114.5960029, 9.0481985],
                    [114.5946492, 9.0477841],
                    [114.5932985, 9.0473605],
                    [114.5919508, 9.0469274],
                    [114.5906062, 9.0464851],
                    [114.5892648, 9.0460334],
                    [114.5879266, 9.0455725],
                    [114.5865917, 9.0451023],
                    [114.5852602, 9.0446228],
                    [114.5839321, 9.0441342],
                    [114.5826076, 9.0436364],
                    [114.5812866, 9.0431294],
                    [114.5799692, 9.0426132],
                    [114.5786555, 9.042088],
                    [114.5773456, 9.0415537],
                    [114.5760395, 9.0410103],
                    [114.5747373, 9.0404579],
                    [114.5734391, 9.0398965],
                    [114.5721449, 9.0393261],
                    [114.5708548, 9.0387467],
                    [114.5695688, 9.0381585],
                    [114.568287, 9.0375613],
                    [114.5670095, 9.0369553],
                    [114.5657363, 9.0363404],
                    [114.5644675, 9.0357168],
                    [114.5632032, 9.0350843],
                    [114.5619434, 9.0344432],
                    [114.5606882, 9.0337933],
                    [114.5594376, 9.0331347],
                    [114.5581918, 9.0324675],
                    [114.5569507, 9.0317917],
                    [114.5557144, 9.0311073],
                    [114.554483, 9.0304143],
                    [114.5532566, 9.0297129],
                    [114.5520352, 9.0290029],
                    [114.5508189, 9.0282846],
                    [114.5496076, 9.0275578],
                    [114.5484016, 9.0268226],
                    [114.5472009, 9.0260791],
                    [114.5460054, 9.0253273],
                    [114.5448153, 9.0245673],
                    [114.5436306, 9.023799],
                    [114.5424514, 9.0230225],
                    [114.5412778, 9.0222379],
                    [114.5401098, 9.0214452],
                    [114.5389474, 9.0206444],
                    [114.5377907, 9.0198355],
                    [114.5366398, 9.0190187],
                    [114.5354947, 9.0181939],
                    [114.5343555, 9.0173612],
                    [114.5332223, 9.0165207],
                    [114.532095, 9.0156723],
                    [114.5309738, 9.0148161],
                    [114.5298588, 9.0139522],
                    [114.5287498, 9.0130805],
                    [114.5276471, 9.0122013],
                    [114.5265507, 9.0113143],
                    [114.5254605, 9.0104199],
                    [114.5243768, 9.0095178],
                    [114.5232995, 9.0086084],
                    [114.5222287, 9.0076914],
                    [114.5211644, 9.0067671],
                    [114.5201067, 9.0058354],
                    [114.5190556, 9.0048964],
                    [114.5180112, 9.0039501],
                    [114.5169736, 9.0029967],
                    [114.5159428, 9.002036],
                    [114.5149188, 9.0010683],
                    [114.5139017, 9.0000935],
                    [114.5128915, 8.9991116],
                    [114.5118884, 8.9981228],
                    [114.5108923, 8.9971271],
                    [114.5099033, 8.9961244],
                    [114.5089214, 8.995115],
                    [114.5079468, 8.9940988],
                    [114.5069793, 8.9930758],
                    [114.5060192, 8.9920462],
                    [114.5050664, 8.9910099],
                    [114.5041209, 8.9899671],
                    [114.5031829, 8.9889177],
                    [114.5022524, 8.9878619],
                    [114.5013294, 8.9867996],
                    [114.5004139, 8.985731],
                    [114.4995061, 8.984656],
                    [114.4986059, 8.9835748],
                    [114.4977134, 8.9824874],
                    [114.4968286, 8.9813938],
                    [114.4959517, 8.9802941],
                    [114.4950825, 8.9791884],
                    [114.4942213, 8.9780766],
                    [114.4933679, 8.976959],
                    [114.4925225, 8.9758354],
                    [114.4916851, 8.974706],
                    [114.4908558, 8.9735708],
                    [114.4900345, 8.9724299],
                    [114.4892214, 8.9712834],
                    [114.4884164, 8.9701312],
                    [114.4876196, 8.9689735],
                    [114.486831, 8.9678103],
                    [114.4860507, 8.9666416],
                    [114.4852788, 8.9654676],
                    [114.4845151, 8.9642882],
                    [114.4837599, 8.9631036],
                    [114.4830131, 8.9619138],
                    [114.4822748, 8.9607188],
                    [114.481545, 8.9595188],
                    [114.4808237, 8.9583137],
                    [114.480111, 8.9571036],
                    [114.4794069, 8.9558887],
                    [114.4787114, 8.9546689],
                    [114.4780246, 8.9534442],
                    [114.4773465, 8.9522149],
                    [114.4766772, 8.9509809],
                    [114.4760166, 8.9497423],
                    [114.4753649, 8.9484991],
                    [114.474722, 8.9472514],
                    [114.4740879, 8.9459993],
                    [114.4734628, 8.9447429],
                    [114.4728466, 8.9434821],
                    [114.4722394, 8.9422171],
                    [114.4716411, 8.9409479],
                    [114.4710519, 8.9396746],
                    [114.4704717, 8.9383973],
                    [114.4699007, 8.937116],
                    [114.4693387, 8.9358307],
                    [114.4687859, 8.9345415],
                    [114.4682422, 8.9332486],
                    [114.4677077, 8.9319519],
                    [114.4671825, 8.9306516],
                    [114.4666664, 8.9293476],
                    [114.4661597, 8.9280401],
                    [114.4656622, 8.9267291],
                    [114.4651741, 8.9254147],
                    [114.4646953, 8.9240969],
                    [114.4642259, 8.9227758],
                    [114.4637659, 8.9214515],
                    [114.4633152, 8.9201241],
                    [114.462874, 8.9187936],
                    [114.4624423, 8.91746],
                    [114.46202, 8.9161235],
                    [114.4616073, 8.914784],
                    [114.461204, 8.9134418],
                    [114.4608103, 8.9120968],
                    [114.4604262, 8.910749],
                    [114.4600516, 8.9093987],
                    [114.4596866, 8.9080458],
                    [114.4593312, 8.9066903],
                    [114.4589855, 8.9053325],
                    [114.4586494, 8.9039723],
                    [114.458323, 8.9026097],
                    [114.4580062, 8.901245],
                    [114.4576992, 8.8998781],
                    [114.4574018, 8.898509],
                    [114.4571142, 8.897138],
                    [114.4568363, 8.895765],
                    [114.4565682, 8.8943901],
                    [114.4563098, 8.8930133],
                    [114.4560612, 8.8916348],
                    [114.4558224, 8.8902546],
                    [114.4555935, 8.8888728],
                    [114.4553743, 8.8874894],
                    [114.4551649, 8.8861045],
                    [114.4549654, 8.8847182],
                    [114.4547757, 8.8833306],
                    [114.4545959, 8.8819417],
                    [114.4544259, 8.8805515],
                    [114.4542658, 8.8791602],
                    [114.4541156, 8.8777678],
                    [114.4539753, 8.8763745],
                    [114.4538449, 8.8749801],
                    [114.4537243, 8.8735849],
                    [114.4536137, 8.8721889],
                    [114.453513, 8.8707922],
                    [114.4534222, 8.8693947],
                    [114.4533413, 8.8679967],
                    [114.4532703, 8.8665982],
                    [114.4532093, 8.8651992],
                    [114.4531582, 8.8637997],
                    [114.453117, 8.8624],
                    [114.4530858, 8.861],
                    [114.4530645, 8.8595999],
                    [114.4530532, 8.8581996],
                    [114.4530517, 8.8567992],
                ],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [103.0522427, 9.4110961],
                [103.2943996, 9.1216644],
                [103.2964435, 9.1192566],
                [103.2985252, 9.116881],
                [103.3006441, 9.114538],
                [103.3027996, 9.1122284],
                [103.3049913, 9.1099526],
                [103.3072186, 9.1077111],
                [103.3094809, 9.1055047],
                [103.3117778, 9.1033338],
                [103.3141086, 9.1011989],
                [103.3164727, 9.0991005],
                [103.3188696, 9.0970393],
                [103.3212987, 9.0950156],
                [103.3237594, 9.09303],
                [103.3262511, 9.0910829],
                [103.3287732, 9.0891749],
                [103.3313251, 9.0873065],
                [103.333906, 9.0854779],
                [103.3365155, 9.0836899],
                [103.3391529, 9.0819426],
                [103.3418174, 9.0802367],
                [103.3445085, 9.0785724],
                [103.4821147, 8.9945558],
                [104.6318304, 9.474868],
                [104.8368502, 9.5308101],
                [104.8370714, 9.5307833],
                [104.8372936, 9.5307655],
                [104.8374741, 9.5307578],
                [104.8376548, 9.530756],
                [104.8377925, 9.5307682],
                [104.8379868, 9.5307728],
                [104.8381385, 9.5307814],
                [104.8383314, 9.5307719],
                [104.8383948, 9.530774],
                [104.8384322, 9.530775],
                [104.8384684, 9.530784],
                [104.8385035, 9.5308019],
                [104.8385334, 9.5308273],
                [104.8385631, 9.5308694],
                [104.8385851, 9.5309269],
                [104.8387237, 9.5313618],
                [104.8389623, 9.531292],
                [104.8392034, 9.5312311],
                [104.8394024, 9.5311879],
                [104.8396028, 9.5311509],
                [104.8398203, 9.53112],
                [104.8400572, 9.5310933],
                [104.8403094, 9.5310598],
                [104.8405606, 9.5310194],
                [104.8407614, 9.5309848],
                [104.8409825, 9.5309419],
                [104.8412694, 9.530875],
                [104.8418828, 9.5306975],
                [104.8425236, 9.5305128],
                [104.8431623, 9.5303211],
                [104.8437929, 9.5301242],
                [104.8444213, 9.5299204],
                [104.8449777, 9.5297414],
                [104.845671, 9.5294921],
                [104.8457368, 9.5294629],
                [104.8458002, 9.5294288],
                [104.8458606, 9.52939],
                [104.8459274, 9.5293387],
                [104.8459892, 9.5292816],
                [104.8460455, 9.5292192],
                [104.8463105, 9.5287882],
                [104.8465833, 9.5283619],
                [104.8468637, 9.5279404],
                [104.8471116, 9.527581],
                [104.8473651, 9.5272254],
                [104.8476241, 9.5268737],
                [104.8478455, 9.5265629],
                [104.8480875, 9.5262195],
                [104.8482799, 9.5259089],
                [104.8484311, 9.525654],
                [104.8485777, 9.5253965],
                [104.8487198, 9.5251365],
                [104.8488183, 9.5249198],
                [104.8489255, 9.5247072],
                [104.8490411, 9.5244989],
                [104.8491651, 9.5242953],
                [104.8492808, 9.5241204],
                [104.8494026, 9.5239497],
                [104.8495305, 9.5237832],
                [104.8496641, 9.5236212],
                [104.8498989, 9.5233126],
                [104.8501414, 9.5230097],
                [104.8503149, 9.5228022],
                [104.8504919, 9.5225976],
                [104.8506725, 9.522396],
                [104.8508622, 9.5222146],
                [104.8510482, 9.5220294],
                [104.8512304, 9.5218405],
                [104.8514209, 9.5216346],
                [104.8516069, 9.5214247],
                [104.8517883, 9.521211],
                [104.8521706, 9.5206975],
                [104.8525604, 9.5201896],
                [104.8529577, 9.5196873],
                [104.8533141, 9.5192492],
                [104.8536761, 9.5188157],
                [104.8540437, 9.5183868],
                [104.8544168, 9.5179626],
                [104.8548394, 9.5175225],
                [104.8552664, 9.5170865],
                [104.8556978, 9.5166548],
                [104.8561335, 9.5162273],
                [104.8565776, 9.5158],
                [104.8570261, 9.5153772],
                [104.8574789, 9.5149588],
                [104.8579359, 9.5145448],
                [104.8590386, 9.5136215],
                [104.860146, 9.5127037],
                [104.8610831, 9.5119346],
                [104.8620236, 9.5111694],
                [104.8625167, 9.5107391],
                [104.8630155, 9.5103152],
                [104.8635199, 9.5098978],
                [104.8640299, 9.509487],
                [104.8645059, 9.5091133],
                [104.8649865, 9.5087453],
                [104.8654716, 9.5083831],
                [104.8659611, 9.5080267],
                [104.8668331, 9.5073533],
                [104.8677022, 9.5066761],
                [104.8685682, 9.5059951],
                [104.8697944, 9.5050201],
                [104.8710143, 9.5040375],
                [104.8715419, 9.5036203],
                [104.8720647, 9.5031972],
                [104.8725827, 9.5027684],
                [104.8730957, 9.5023339],
                [104.8736239, 9.501876],
                [104.8741467, 9.5014121],
                [104.8746639, 9.5009422],
                [104.8751755, 9.5004664],
                [104.8756814, 9.4999847],
                [104.8760544, 9.4996368],
                [104.8764228, 9.499284],
                [104.8767865, 9.4989266],
                [104.8771367, 9.4985733],
                [104.8774824, 9.4982156],
                [104.8778234, 9.4978537],
                [104.8781841, 9.4974616],
                [104.8785355, 9.4970887],
                [104.8789168, 9.496695],
                [104.8793033, 9.4963064],
                [104.8796952, 9.495923],
                [104.8800601, 9.4955131],
                [104.8806102, 9.4948793],
                [104.881328, 9.4941311],
                [104.8820158, 9.4954156],
                [104.8826417, 9.4966144],
                [104.8832963, 9.4978429],
                [104.8839425, 9.4990755],
                [104.8849859, 9.5010393],
                [104.886024, 9.5030058],
                [104.8870377, 9.5049383],
                [104.8880464, 9.5068734],
                [104.8920912, 9.5146917],
                [104.9001807, 9.5302894],
                [104.9015632, 9.5329812],
                [104.9029186, 9.5356175],
                [104.9042952, 9.5382778],
                [104.9056937, 9.5409678],
                [104.9070974, 9.5436552],
                [104.9085063, 9.54634],
                [104.9104928, 9.5501402],
                [104.9124867, 9.5539366],
                [104.9144548, 9.5576765],
                [104.9154612, 9.5595809],
                [104.9163524, 9.5612484],
                [104.9164516, 9.561434],
                [104.9182069, 9.5597181],
                [104.9199593, 9.5579993],
                [104.921801, 9.5562182],
                [104.9236929, 9.5543174],
                [104.9255158, 9.5525284],
                [104.9273354, 9.5507361],
                [104.9291798, 9.5489127],
                [104.9310207, 9.5470859],
                [104.9328307, 9.5452861],
                [104.934645, 9.5434906],
                [104.9364637, 9.5416993],
                [104.9382868, 9.5399124],
                [104.94013, 9.5381146],
                [104.9419775, 9.5363212],
                [104.9438295, 9.5345323],
                [104.9456859, 9.5327478],
                [104.9457215, 9.5327257],
                [104.9457609, 9.5327111],
                [104.9458024, 9.5327044],
                [104.94584, 9.5327055],
                [104.9458767, 9.5327131],
                [104.9459115, 9.5327271],
                [104.9459433, 9.5327469],
                [104.9476886, 9.5351349],
                [104.9494287, 9.5375265],
                [104.9511573, 9.5399132],
                [104.9528807, 9.5423036],
                [104.9540542, 9.543858],
                [104.9552228, 9.5454159],
                [104.9563864, 9.5469775],
                [104.9572528, 9.5481469],
                [104.9576336, 9.5486677],
                [104.9580735, 9.5492601],
                [104.9585436, 9.5498594],
                [104.9589747, 9.5503975],
                [104.9594337, 9.5509617],
                [104.9601641, 9.5519037],
                [104.9606663, 9.5525996],
                [104.9614423, 9.5536721],
                [104.9622518, 9.5547759],
                [104.9630684, 9.5558747],
                [104.963351, 9.5554823],
                [104.9636416, 9.5550955],
                [104.96394, 9.5547147],
                [104.9642461, 9.5543398],
                [104.9645598, 9.553971],
                [104.9648809, 9.5536086],
                [104.9651798, 9.553284],
                [104.9654847, 9.552965],
                [104.9657954, 9.5526515],
                [104.9661119, 9.5523436],
                [104.966434, 9.5520415],
                [104.9667617, 9.5517453],
                [104.9670949, 9.551455],
                [104.967659, 9.5509613],
                [104.968229, 9.5504743],
                [104.968805, 9.5499942],
                [104.9693869, 9.5495209],
                [104.9699745, 9.5490546],
                [104.9705677, 9.5485954],
                [104.9711212, 9.5481771],
                [104.9716793, 9.5477649],
                [104.9722421, 9.5473589],
                [104.9728094, 9.5469592],
                [104.9733121, 9.5466195],
                [104.9738194, 9.5462866],
                [104.9743314, 9.5459606],
                [104.9748478, 9.5456416],
                [104.9753686, 9.5453297],
                [104.9758937, 9.5450248],
                [104.976423, 9.544727],
                [104.9769564, 9.5444365],
                [104.9774777, 9.5441615],
                [104.9780027, 9.5438934],
                [104.9785312, 9.5436323],
                [104.9790632, 9.5433781],
                [104.9795986, 9.5431309],
                [104.9801373, 9.5428908],
                [104.9804539, 9.5427473],
                [104.9807732, 9.5426099],
                [104.9810952, 9.5424786],
                [104.9814197, 9.5423536],
                [104.9817467, 9.5422348],
                [104.9820815, 9.5421205],
                [104.9824186, 9.5420128],
                [104.9827578, 9.5419117],
                [104.9830989, 9.5418172],
                [104.9834419, 9.5417295],
                [104.9837866, 9.5416484],
                [104.9840925, 9.5415781],
                [104.9843999, 9.5415148],
                [104.9847088, 9.5414586],
                [104.985019, 9.5414096],
                [104.9853329, 9.5413674],
                [104.9856477, 9.5413325],
                [104.9859634, 9.541305],
                [104.9862795, 9.5412848],
                [104.9865961, 9.541272],
                [104.9869614, 9.5412574],
                [104.9873265, 9.5412366],
                [104.9876911, 9.5412097],
                [104.9880552, 9.5411768],
                [104.988478, 9.5411309],
                [104.9888998, 9.5410768],
                [104.9893204, 9.5410145],
                [104.9896914, 9.5409546],
                [104.9899272, 9.540909],
                [104.9901129, 9.5408654],
                [104.9902963, 9.5408134],
                [104.9904771, 9.5407529],
                [104.9906548, 9.5406843],
                [104.9908291, 9.5406075],
                [104.9909995, 9.5405228],
                [104.9911658, 9.5404304],
                [104.9913275, 9.5403304],
                [104.9914857, 9.5402221],
                [104.9916386, 9.5401065],
                [104.9917858, 9.5399841],
                [104.991927, 9.5398549],
                [104.9920619, 9.5397193],
                [104.9921902, 9.5395776],
                [104.9923116, 9.5394302],
                [104.9924258, 9.5392772],
                [104.9925326, 9.5391192],
                [104.9926318, 9.5389563],
                [104.9945697, 9.5399531],
                [104.9947612, 9.5400589],
                [104.9949485, 9.5401717],
                [104.9951153, 9.5402801],
                [104.9952783, 9.5403941],
                [104.9954373, 9.5405133],
                [104.9955774, 9.5406193],
                [104.9957266, 9.5407421],
                [104.9958568, 9.5408514],
                [104.9959825, 9.5409657],
                [104.9961036, 9.5410849],
                [104.9962199, 9.5412085],
                [104.9963303, 9.5413356],
                [104.9964356, 9.5414669],
                [104.9965356, 9.541602],
                [104.9966302, 9.541741],
                [104.996879, 9.5420868],
                [104.9970324, 9.5422948],
                [104.9971793, 9.5425074],
                [104.9973196, 9.5427242],
                [104.997468, 9.5429705],
                [104.9976078, 9.5432217],
                [104.997739, 9.5434774],
                [104.9980546, 9.5441173],
                [104.9982424, 9.5445],
                [104.9984246, 9.5448852],
                [104.9985848, 9.5452359],
                [104.9987404, 9.5455886],
                [104.9988913, 9.5459432],
                [104.9990857, 9.5463603],
                [104.9992742, 9.5467799],
                [104.9994566, 9.5472022],
                [104.999633, 9.5476269],
                [104.9998033, 9.548054],
                [104.9999989, 9.5485678],
                [105.0001858, 9.5490848],
                [105.0003638, 9.5496048],
                [105.0005436, 9.5501648],
                [105.0011671, 9.5522241],
                [105.0017935, 9.5543227],
                [105.0024082, 9.5564103],
                [105.0030151, 9.5585],
                [105.0079556, 9.555477],
                [105.0129003, 9.5524605],
                [105.0178492, 9.5494506],
                [105.0228349, 9.5464275],
                [105.0278247, 9.5434111],
                [105.0328187, 9.5404013],
                [105.0335192, 9.5399729],
                [105.0342214, 9.5395474],
                [105.0349634, 9.5391021],
                [105.0356591, 9.5386866],
                [105.0364138, 9.5382378],
                [105.0371182, 9.5378122],
                [105.0379027, 9.5373329],
                [105.0386846, 9.5368494],
                [105.0401115, 9.5359871],
                [105.0416926, 9.5350377],
                [105.0431371, 9.5341725],
                [105.0447035, 9.5332308],
                [105.0461859, 9.532338],
                [105.04767, 9.531448],
                [105.0492006, 9.5305339],
                [105.0499928, 9.5300566],
                [105.0506902, 9.529644],
                [105.0512553, 9.5293081],
                [105.05179, 9.529003],
                [105.052321, 9.5286917],
                [105.052827, 9.528387],
                [105.0533295, 9.5280766],
                [105.0538284, 9.5277606],
                [105.0541892, 9.5275469],
                [105.0545525, 9.5273373],
                [105.0549481, 9.5271154],
                [105.055384, 9.5268824],
                [105.0557849, 9.5266998],
                [105.0562209, 9.5264962],
                [105.0566396, 9.5262946],
                [105.0570559, 9.5260882],
                [105.0681359, 9.5193274],
                [105.0687024, 9.5189627],
                [105.0758234, 9.5143785],
                [105.0811629, 9.5112574],
                [105.0865024, 9.5083949],
                [105.0983047, 9.5009688],
                [105.1101392, 9.493467],
                [105.1106456, 9.4931746],
                [105.1369614, 9.4779798],
                [105.1370399, 9.4779345],
                [105.1406084, 9.4757285],
                [105.1442456, 9.4734871],
                [105.1478868, 9.4712546],
                [105.1514251, 9.4690132],
                [105.1548296, 9.4668783],
                [105.1582843, 9.4646965],
                [105.164094, 9.4610736],
                [105.1700109, 9.4573855],
                [105.1720773, 9.4560816],
                [105.1739959, 9.4548452],
                [105.176003, 9.4536126],
                [105.1780245, 9.4523497],
                [105.1800486, 9.4510909],
                [105.1820754, 9.4498363],
                [105.182527, 9.4495539],
                [105.1863554, 9.4471598],
                [105.1869902, 9.4467646],
                [105.1875955, 9.4463843],
                [105.1882194, 9.4459983],
                [105.188846, 9.4456167],
                [105.1894755, 9.4452395],
                [105.1895991, 9.4451645],
                [105.1897128, 9.4450895],
                [105.1898236, 9.4450104],
                [105.1899281, 9.4449011],
                [105.1900375, 9.4447966],
                [105.1901517, 9.444697],
                [105.1902702, 9.4446026],
                [105.190393, 9.4445136],
                [105.1905186, 9.444431],
                [105.1906478, 9.4443539],
                [105.1907536, 9.4442985],
                [105.1908609, 9.4442445],
                [105.1909402, 9.4442041],
                [105.1910461, 9.4441453],
                [105.1911494, 9.4440824],
                [105.1940086, 9.4422647],
                [105.2012318, 9.4377323],
                [105.2024019, 9.4369986],
                [105.2035695, 9.4362613],
                [105.2047348, 9.4355203],
                [105.2059348, 9.4347516],
                [105.2071323, 9.4339791],
                [105.207728, 9.4335883],
                [105.2083271, 9.4332026],
                [105.20895, 9.4328093],
                [105.2095763, 9.4324215],
                [105.2102062, 9.4320392],
                [105.2108395, 9.4316625],
                [105.2134839, 9.4299888],
                [105.2196557, 9.4261368],
                [105.2208536, 9.4253518],
                [105.2272855, 9.4212645],
                [105.2277542, 9.4209541],
                [105.2358005, 9.4158447],
                [105.2439247, 9.4107868],
                [105.2480337, 9.4081974],
                [105.2521099, 9.4056189],
                [105.2561742, 9.4030427],
                [105.2581108, 9.4018202],
                [105.2600543, 9.4005877],
                [105.2622923, 9.3991369],
                [105.2623701, 9.3992534],
                [105.2646579, 9.3985474],
                [105.2664039, 9.397991],
                [105.2681476, 9.3974275],
                [105.2699152, 9.3968481],
                [105.2716803, 9.3962612],
                [105.2734429, 9.395667],
                [105.2738017, 9.3960462],
                [105.2749336, 9.3951147],
                [105.2779492, 9.398781],
                [105.2815479, 9.3964283],
                [105.2822924, 9.3959272],
                [105.2828128, 9.3956042],
                [105.2840916, 9.3948026],
                [105.2857848, 9.3937304],
                [105.287237, 9.3928225],
                [105.2878289, 9.3924633],
                [105.2888595, 9.391829],
                [105.2893501, 9.3915056],
                [105.2899302, 9.3911072],
                [105.2902954, 9.3909134],
                [105.2917179, 9.3900259],
                [105.2931103, 9.3891712],
                [105.2936456, 9.3888433],
                [105.2947986, 9.3881386],
                [105.2968204, 9.3912194],
                [105.2975821, 9.3907537],
                [105.2976359, 9.3907809],
                [105.2976871, 9.3908127],
                [105.297735, 9.390849],
                [105.2977884, 9.3908984],
                [105.297836, 9.3909533],
                [105.2978772, 9.391013],
                [105.2983197, 9.3907616],
                [105.2981534, 9.3904467],
                [105.3002912, 9.3897164],
                [105.300672, 9.3905738],
                [105.3046256, 9.3892136],
                [105.3054732, 9.3888219],
                [105.3058648, 9.3895999],
                [105.3069323, 9.3891448],
                [105.3073561, 9.3886049],
                [105.3065192, 9.3870701],
                [105.3074688, 9.3863979],
                [105.3094911, 9.3862921],
                [105.3095287, 9.3858104],
                [105.3117496, 9.385657],
                [105.3118086, 9.3853262],
                [105.3123531, 9.3853235],
                [105.3123665, 9.3840903],
                [105.3151265, 9.3838442],
                [105.3150192, 9.3831747],
                [105.31923, 9.3832165],
                [105.3171194, 9.3898328],
                [105.3169273, 9.3903853],
                [105.3167395, 9.3909393],
                [105.3165561, 9.3914946],
                [105.316305, 9.3922793],
                [105.3160626, 9.3930665],
                [105.3159333, 9.3934928],
                [105.3158097, 9.3939208],
                [105.3156919, 9.3943503],
                [105.3155798, 9.3947813],
                [105.3154452, 9.3953331],
                [105.31532, 9.3958871],
                [105.3152418, 9.3962235],
                [105.3151748, 9.3964854],
                [105.3150848, 9.3967946],
                [105.3149682, 9.397147],
                [105.3148292, 9.3975803],
                [105.3146624, 9.3980308],
                [105.3125542, 9.4039054],
                [105.3124309, 9.4042441],
                [105.3122377, 9.4046833],
                [105.3120661, 9.4050803],
                [105.3119051, 9.4054454],
                [105.3117871, 9.4057206],
                [105.3116423, 9.4059905],
                [105.3114814, 9.4062499],
                [105.3113311, 9.4065092],
                [105.3111917, 9.4067579],
                [105.3111005, 9.4069326],
                [105.3110415, 9.407139],
                [105.3110254, 9.4074036],
                [105.3110093, 9.4076841],
                [105.3110093, 9.4079275],
                [105.3110522, 9.4082133],
                [105.311079, 9.4084197],
                [105.3111005, 9.4086049],
                [105.3110951, 9.4088166],
                [105.3110522, 9.4089965],
                [105.3110039, 9.4092135],
                [105.3109878, 9.4094199],
                [105.3109825, 9.4096316],
                [105.3110039, 9.4098433],
                [105.3110415, 9.4100815],
                [105.3110897, 9.410309],
                [105.3111219, 9.4105154],
                [105.3111219, 9.4106848],
                [105.3110576, 9.410907],
                [105.3109825, 9.4110976],
                [105.3108483, 9.4113781],
                [105.3107708, 9.4115218],
                [105.3106885, 9.411663],
                [105.3106016, 9.4118014],
                [105.3104845, 9.4119733],
                [105.3105265, 9.4122724],
                [105.3105909, 9.4128546],
                [105.310607, 9.413098],
                [105.3106338, 9.4133626],
                [105.3106928, 9.4136854],
                [105.3107518, 9.4139712],
                [105.3108001, 9.4142623],
                [105.3108108, 9.4145163],
                [105.3108054, 9.4147809],
                [105.3107732, 9.4150667],
                [105.310725, 9.4154213],
                [105.3106928, 9.4157388],
                [105.310674, 9.4160325],
                [105.3106552, 9.4162336],
                [105.3106284, 9.4164056],
                [105.310564, 9.4165353],
                [105.3104353, 9.4166623],
                [105.3102556, 9.4167628],
                [105.3099176, 9.4169163],
                [105.3095904, 9.4170539],
                [105.3093249, 9.4171333],
                [105.309121, 9.4172153],
                [105.3089279, 9.4173635],
                [105.3087965, 9.4174984],
                [105.3087026, 9.4176334],
                [105.3086489, 9.417771],
                [105.3086302, 9.4179192],
                [105.3086221, 9.4180064],
                [105.3086087, 9.4180929],
                [105.3085899, 9.4181785],
                [105.3085663, 9.4182613],
                [105.3085377, 9.4183425],
                [105.3084787, 9.418498],
                [105.3084445, 9.4185706],
                [105.3084022, 9.4186389],
                [105.308364, 9.4186882],
                [105.3083216, 9.418734],
                [105.3082752, 9.4187759],
                [105.3082251, 9.4188135],
                [105.308032, 9.4189511],
                [105.307861, 9.4190809],
                [105.3076941, 9.4192157],
                [105.3075323, 9.4193549],
                [105.3073749, 9.4194989],
                [105.307327, 9.4195483],
                [105.3072734, 9.4195916],
                [105.3072148, 9.4196282],
                [105.3071523, 9.4196576],
                [105.3070835, 9.4196802],
                [105.3070125, 9.419694],
                [105.3069402, 9.4196988],
                [105.3068492, 9.4196947],
                [105.3065421, 9.4196761],
                [105.3061974, 9.4196814],
                [105.3059694, 9.4197105],
                [105.305787, 9.4197449],
                [105.3055107, 9.4198296],
                [105.3053337, 9.4199169],
                [105.3051889, 9.4200175],
                [105.3050467, 9.4201471],
                [105.3049207, 9.4202794],
                [105.3047061, 9.420507],
                [105.3044647, 9.4207663],
                [105.3043252, 9.4209092],
                [105.3042126, 9.4210627],
                [105.3041053, 9.4212585],
                [105.3040034, 9.4214543],
                [105.3038746, 9.4216289],
                [105.3037673, 9.4218194],
                [105.3036815, 9.4220258],
                [105.3036708, 9.4222005],
                [105.3036869, 9.4223539],
                [105.3037137, 9.4225444],
                [105.3036976, 9.4227085],
                [105.3035366, 9.423026],
                [105.3033435, 9.4232059],
                [105.3032684, 9.4234282],
                [105.3031933, 9.4236187],
                [105.3030646, 9.4238092],
                [105.3028393, 9.4239468],
                [105.3026247, 9.4241268],
                [105.3024745, 9.4243173],
                [105.3023565, 9.424656],
                [105.3021955, 9.425037],
                [105.3019702, 9.4254921],
                [105.3017235, 9.4258943],
                [105.3014016, 9.4262541],
                [105.3010476, 9.4265611],
                [105.3006935, 9.4269209],
                [105.3004038, 9.4272702],
                [105.3001141, 9.4275771],
                [105.2997279, 9.427937],
                [105.2994704, 9.4282545],
                [105.2991378, 9.4286144],
                [105.298816, 9.4289636],
                [105.298537, 9.4293023],
                [105.2982366, 9.4296092],
                [105.2978718, 9.4299585],
                [105.29755, 9.4303713],
                [105.2972495, 9.4307311],
                [105.296874, 9.431091],
                [105.2965629, 9.4313556],
                [105.2962839, 9.4316943],
                [105.2958977, 9.4320859],
                [105.2955222, 9.4323822],
                [105.2952111, 9.4329008],
                [105.2949515, 9.4334779],
                [105.2947926, 9.4339848],
                [105.2946287, 9.434819],
                [105.294523, 9.4353893],
                [105.2943063, 9.4366142],
                [105.2942219, 9.4371739],
                [105.2940582, 9.438367],
                [105.2937885, 9.4396976],
                [105.2936616, 9.4402889],
                [105.2935086, 9.4414716],
                [105.2934243, 9.4421684],
                [105.2933294, 9.4429815],
                [105.2932819, 9.4433405],
                [105.2932188, 9.4439951],
                [105.2930021, 9.4452095],
                [105.2929178, 9.4458748],
                [105.2927328, 9.4469941],
                [105.2924214, 9.449022],
                [105.2923157, 9.4494761],
                [105.2922254, 9.4500782],
                [105.2922338, 9.4504588],
                [105.2922369, 9.4507745],
                [105.2921574, 9.4512636],
                [105.2921149, 9.4516804],
                [105.2920888, 9.4519966],
                [105.2920145, 9.4524667],
                [105.2919367, 9.4528704],
                [105.2918464, 9.4534401],
                [105.291719, 9.453997],
                [105.2916731, 9.4544545],
                [105.2916008, 9.4549249],
                [105.2914259, 9.4553441],
                [105.2913532, 9.4557359],
                [105.2912879, 9.4562873],
                [105.2913033, 9.4572302],
                [105.2913114, 9.4580717],
                [105.2913119, 9.4586376],
                [105.291327, 9.4591309],
                [105.2914149, 9.459537],
                [105.2914297, 9.4598562],
                [105.2913644, 9.460219],
                [105.2912479, 9.4605527],
                [105.2911607, 9.4609446],
                [105.2911611, 9.4614523],
                [105.2911982, 9.4621342],
                [105.2913156, 9.4629031],
                [105.2914331, 9.4636575],
                [105.2915724, 9.4644118],
                [105.2915875, 9.4650211],
                [105.2915442, 9.4656159],
                [105.2914208, 9.4663415],
                [105.2913921, 9.4669654],
                [105.2914365, 9.4676327],
                [105.2915392, 9.4681986],
                [105.2916492, 9.4687062],
                [105.2918978, 9.4693009],
                [105.2921435, 9.4699138],
                [105.2923453, 9.4705033],
                [105.2924567, 9.4708487],
                [105.2925516, 9.4712731],
                [105.292604, 9.4716352],
                [105.2926106, 9.4720397],
                [105.2926469, 9.4725982],
                [105.2926923, 9.4730547],
                [105.2927514, 9.4737369],
                [105.2928421, 9.4745931],
                [105.2929372, 9.4753415],
                [105.2929848, 9.4757042],
                [105.2930003, 9.4760099],
                [105.292976, 9.4762756],
                [105.2929523, 9.4766943],
                [105.2929523, 9.4770194],
                [105.2930732, 9.4776435],
                [105.2931601, 9.4779116],
                [105.2932753, 9.4781291],
                [105.2934087, 9.4782945],
                [105.2936211, 9.478511],
                [105.2937931, 9.4787346],
                [105.2939419, 9.4789754],
                [105.2942131, 9.4792431],
                [105.2944789, 9.4795255],
                [105.294701, 9.4798141],
                [105.294937, 9.480121],
                [105.2949898, 9.4801869],
                [105.2952965, 9.4805747],
                [105.2956649, 9.4811303],
                [105.2958669, 9.4814923],
                [105.2959462, 9.4817328],
                [105.2959442, 9.4821004],
                [105.2958508, 9.4824353],
                [105.2956945, 9.4828791],
                [105.2955261, 9.4831845],
                [105.2953447, 9.4834511],
                [105.2951571, 9.4836883],
                [105.2950774, 9.4837201],
                [105.2948363, 9.4838162],
                [105.2947796, 9.4838229],
                [105.2945694, 9.4838476],
                [105.294456, 9.4838838],
                [105.2944311, 9.4838917],
                [105.2943528, 9.4839654],
                [105.2943235, 9.4840508],
                [105.2943033, 9.4842665],
                [105.2943036, 9.4846137],
                [105.2943494, 9.4850801],
                [105.2944344, 9.4854325],
                [105.2946199, 9.4858389],
                [105.2946273, 9.4858945],
                [105.2946562, 9.48611],
                [105.2947118, 9.4864672],
                [105.2946907, 9.486901],
                [105.2947172, 9.4872498],
                [105.2947926, 9.4876109],
                [105.2948101, 9.487886],
                [105.2947455, 9.4880942],
                [105.2946319, 9.4882495],
                [105.2945777, 9.4883236],
                [105.2944142, 9.4885551],
                [105.2942495, 9.4887655],
                [105.2941507, 9.4891231],
                [105.2940552, 9.4895291],
                [105.2940555, 9.4895474],
                [105.2940594, 9.4897774],
                [105.2940792, 9.4900289],
                [105.2941888, 9.4902526],
                [105.2943742, 9.4904234],
                [105.2944842, 9.4905073],
                [105.2947942, 9.490563],
                [105.295017, 9.4906414],
                [105.2952829, 9.4906412],
                [105.2955284, 9.4906747],
                [105.2957889, 9.4907457],
                [105.2959169, 9.4908304],
                [105.2960327, 9.490907],
                [105.2962333, 9.4911018],
                [105.2964561, 9.4913884],
                [105.2966384, 9.4917173],
                [105.2966817, 9.4918395],
                [105.2967674, 9.4920811],
                [105.2969124, 9.4924712],
                [105.2971387, 9.4928753],
                [105.29729, 9.4930678],
                [105.2974954, 9.4932681],
                [105.2977378, 9.493471],
                [105.2979751, 9.4936812],
                [105.2981325, 9.4939501],
                [105.298273, 9.4942763],
                [105.2983649, 9.494525],
                [105.2984073, 9.4947926],
                [105.2984586, 9.4954827],
                [105.2984668, 9.4959054],
                [105.298494, 9.4967341],
                [105.2985468, 9.4971293],
                [105.2986286, 9.4977266],
                [105.2986603, 9.4981246],
                [105.2986687, 9.4984819],
                [105.2986435, 9.4987509],
                [105.2985846, 9.4989603],
                [105.2984928, 9.4992345],
                [105.2984133, 9.4995153],
                [105.2983845, 9.4997456],
                [105.2982377, 9.5000899],
                [105.2982271, 9.5004479],
                [105.2982275, 9.5009143],
                [105.2982824, 9.5012396],
                [105.298381, 9.5016083],
                [105.2984359, 9.5019446],
                [105.29848, 9.5024488],
                [105.2985571, 9.5031321],
                [105.2986669, 9.5038479],
                [105.298864, 9.5044226],
                [105.2991156, 9.5049484],
                [105.2993343, 9.505317],
                [105.2996294, 9.5057289],
                [105.3000011, 9.5061516],
                [105.3004053, 9.5064548],
                [105.3004476, 9.5064731],
                [105.3006675, 9.5065685],
                [105.3008749, 9.5066009],
                [105.3010605, 9.5065573],
                [105.3014315, 9.5063401],
                [105.3018353, 9.5060144],
                [105.3024135, 9.505504],
                [105.3026317, 9.5052978],
                [105.3028062, 9.5050481],
                [105.3030353, 9.5047877],
                [105.3033298, 9.5044837],
                [105.3037988, 9.5040982],
                [105.3044754, 9.5036637],
                [105.3050211, 9.5034138],
                [105.3053923, 9.5033376],
                [105.3056434, 9.5033482],
                [105.3058838, 9.5035107],
                [105.3060587, 9.5037491],
                [105.3061681, 9.504031],
                [105.3062559, 9.5044648],
                [105.306289, 9.5048227],
                [105.3063437, 9.5050721],
                [105.3065078, 9.505289],
                [105.3068028, 9.5055706],
                [105.3072727, 9.5059444],
                [105.3073583, 9.5059989],
                [105.3080046, 9.5064101],
                [105.3085509, 9.5066808],
                [105.3091625, 9.5069514],
                [105.3096759, 9.5071136],
                [105.3098942, 9.5071134],
                [105.310189, 9.5070915],
                [105.3104073, 9.5069666],
                [105.3105927, 9.5067711],
                [105.3107127, 9.5065866],
                [105.3108215, 9.5062937],
                [105.3108868, 9.5059899],
                [105.3110174, 9.505621],
                [105.3112681, 9.5051327],
                [105.3114971, 9.5047638],
                [105.3118679, 9.5043783],
                [105.3124788, 9.5037487],
                [105.3132425, 9.5030864],
                [105.3138426, 9.5026737],
                [105.3143991, 9.5023423],
                [105.3146393, 9.5022119],
                [105.3148575, 9.5021467],
                [105.315054, 9.5021357],
                [105.3153708, 9.5022004],
                [105.3156657, 9.5023953],
                [105.3159498, 9.5026446],
                [105.3162123, 9.5030239],
                [105.3166169, 9.5037068],
                [105.3168795, 9.5043141],
                [105.3170905, 9.5048679],
                [105.3170984, 9.5048887],
                [105.3171643, 9.5052357],
                [105.3171617, 9.505254],
                [105.3171317, 9.5054636],
                [105.3170119, 9.5056914],
                [105.316641, 9.5061419],
                [105.3162375, 9.5065978],
                [105.3159213, 9.5070754],
                [105.315747, 9.5074443],
                [105.3156381, 9.5078457],
                [105.315606, 9.5085128],
                [105.3156613, 9.509207],
                [105.315771, 9.5098143],
                [105.315829, 9.5100963],
                [105.3158368, 9.5101341],
                [105.31599, 9.5104811],
                [105.3161759, 9.510687],
                [105.316449, 9.5108928],
                [105.3165878, 9.5109485],
                [105.3168532, 9.5110551],
                [105.31729, 9.5111849],
                [105.3177159, 9.5112496],
                [105.3180981, 9.5113034],
                [105.3184039, 9.5113682],
                [105.3187863, 9.5115631],
                [105.31942, 9.5119964],
                [105.3202942, 9.5127169],
                [105.3203399, 9.5127553],
                [105.3205783, 9.5129552],
                [105.3209827, 9.513367],
                [105.3216276, 9.5141148],
                [105.3223164, 9.5149764],
                [105.3228411, 9.5157026],
                [105.3233442, 9.5164505],
                [105.3235238, 9.5167324],
                [105.3235411, 9.5167595],
                [105.3235957, 9.5169763],
                [105.323596, 9.5172149],
                [105.3235089, 9.5174428],
                [105.3233125, 9.5176599],
                [105.3229743, 9.5178555],
                [105.3226142, 9.5180836],
                [105.3222432, 9.5184093],
                [105.3217632, 9.5188979],
                [105.3213597, 9.5193917],
                [105.3209781, 9.5199669],
                [105.3207275, 9.5205204],
                [105.3204987, 9.5210846],
                [105.3202375, 9.5219689],
                [105.3200418, 9.5227608],
                [105.3199789, 9.5231018],
                [105.3199658, 9.5231731],
                [105.3199119, 9.5239704],
                [105.3199562, 9.524556],
                [105.3200548, 9.5249355],
                [105.32035, 9.5253691],
                [105.3206779, 9.525743],
                [105.3209512, 9.5259813],
                [105.321257, 9.5261112],
                [105.3213674, 9.5261206],
                [105.3215082, 9.5261326],
                [105.3217322, 9.5260609],
                [105.3218139, 9.5260347],
                [105.322118, 9.5258505],
                [105.3223158, 9.5257306],
                [105.3227742, 9.525459],
                [105.323298, 9.5251765],
                [105.3233222, 9.525167],
                [105.3238765, 9.5249482],
                [105.3244769, 9.5247686],
                [105.3247439, 9.5247153],
                [105.3250228, 9.5246597],
                [105.3252566, 9.5246489],
                [105.3255032, 9.5246375],
                [105.3258894, 9.5246726],
                [105.3260928, 9.5246911],
                [105.326465, 9.5247736],
                [105.3267263, 9.5248315],
                [105.3270563, 9.5249313],
                [105.3275127, 9.5250693],
                [105.3276673, 9.5251394],
                [105.3280262, 9.5253022],
                [105.328178, 9.5254109],
                [105.3284195, 9.5255837],
                [105.3288566, 9.5259629],
                [105.3292392, 9.5263748],
                [105.3292771, 9.5264328],
                [105.3296002, 9.5269276],
                [105.329797, 9.5272745],
                [105.3298846, 9.5275617],
                [105.3298864, 9.527601],
                [105.3298912, 9.5277033],
                [105.3298958, 9.5278005],
                [105.3298196, 9.5279848],
                [105.3295905, 9.5282128],
                [105.3293069, 9.5285602],
                [105.3289906, 9.5288317],
                [105.3286196, 9.5291249],
                [105.3280849, 9.5295592],
                [105.3276485, 9.5299013],
                [105.3271904, 9.5304766],
                [105.3267435, 9.531182],
                [105.326362, 9.5318983],
                [105.3261334, 9.5326522],
                [105.3260138, 9.5331731],
                [105.3259598, 9.5337046],
                [105.3259493, 9.5341818],
                [105.3261032, 9.535326],
                [105.3261805, 9.5361285],
                [105.3262793, 9.5366708],
                [105.3265207, 9.5378582],
                [105.3266198, 9.5386608],
                [105.3266531, 9.5391868],
                [105.3266424, 9.5393929],
                [105.3265987, 9.5395014],
                [105.3264242, 9.5396751],
                [105.3260314, 9.5399684],
                [105.325595, 9.5403158],
                [105.3251585, 9.540685],
                [105.3246348, 9.5411899],
                [105.3241768, 9.5417434],
                [105.3238497, 9.5422535],
                [105.3234136, 9.5429699],
                [105.3230322, 9.5438],
                [105.3227817, 9.5444618],
                [105.3226185, 9.5451344],
                [105.3225102, 9.5460836],
                [105.3224673, 9.546832],
                [105.3224789, 9.5475804],
                [105.3225566, 9.5487897],
                [105.3226446, 9.5495272],
                [105.3227107, 9.550026],
                [105.3228314, 9.5507147],
                [105.3229301, 9.5511268],
                [105.323182, 9.5518098],
                [105.3234775, 9.5525472],
                [105.3236636, 9.5530079],
                [105.3238389, 9.553626],
                [105.3240254, 9.5545261],
                [105.3242009, 9.5552688],
                [105.3243107, 9.5558869],
                [105.3243657, 9.5563099],
                [105.3244098, 9.5568143],
                [105.3244103, 9.5571939],
                [105.3243669, 9.5575952],
                [105.3242037, 9.5580726],
                [105.3239202, 9.5586586],
                [105.3235386, 9.5592012],
                [105.3230803, 9.5595759],
                [105.3225674, 9.5599018],
                [105.3219451, 9.560141],
                [105.321312, 9.5603151],
                [105.3212118, 9.5603306],
                [105.3196475, 9.5604734],
                [105.3192918, 9.5604852],
                [105.3186912, 9.5604531],
                [105.3179703, 9.5603454],
                [105.3174132, 9.5602049],
                [105.3168779, 9.5599993],
                [105.3162224, 9.5596637],
                [105.3154467, 9.5592469],
                [105.3147036, 9.5587487],
                [105.3141571, 9.558261],
                [105.313709, 9.5578601],
                [105.3133265, 9.5575677],
                [105.3123212, 9.5568473],
                [105.3116765, 9.5564574],
                [105.3111958, 9.5561759],
                [105.3107369, 9.5558943],
                [105.3104201, 9.555721],
                [105.3100813, 9.5555478],
                [105.3098123, 9.5554394],
                [105.3094359, 9.555402],
                [105.3090814, 9.5554128],
                [105.3086508, 9.5555219],
                [105.3082898, 9.5556973],
                [105.3080106, 9.5559097],
                [105.3077999, 9.5561465],
                [105.3075871, 9.5564898],
                [105.3074795, 9.5567826],
                [105.3073914, 9.5570803],
                [105.3073507, 9.5573962],
                [105.3073205, 9.5576707],
                [105.3073238, 9.5579149],
                [105.3073322, 9.5585439],
                [105.3073765, 9.559238],
                [105.3074317, 9.5598453],
                [105.3075303, 9.5602412],
                [105.3075961, 9.5605557],
                [105.3077172, 9.5614666],
                [105.3078927, 9.5622908],
                [105.3080243, 9.5630445],
                [105.3081996, 9.5636083],
                [105.3083967, 9.5641722],
                [105.3086375, 9.5647902],
                [105.309097, 9.5656629],
                [105.3095125, 9.5662374],
                [105.3098734, 9.5667143],
                [105.3102451, 9.5672021],
                [105.3107807, 9.5676516],
                [105.3112397, 9.5680851],
                [105.3117644, 9.5686486],
                [105.3120814, 9.5689954],
                [105.3123766, 9.5693422],
                [105.3125517, 9.5696729],
                [105.3127378, 9.5701283],
                [105.3128145, 9.5704644],
                [105.3128585, 9.5708658],
                [105.3128482, 9.5714188],
                [105.312816, 9.5719774],
                [105.3126853, 9.5723464],
                [105.3124454, 9.5726937],
                [105.3120962, 9.5730519],
                [105.3116051, 9.5734104],
                [105.3107539, 9.5739805],
                [105.3098369, 9.5745128],
                [105.3088874, 9.5751211],
                [105.3080797, 9.5756208],
                [105.3063114, 9.5765823],
                [105.3053838, 9.5771689],
                [105.3049581, 9.5774838],
                [105.3045653, 9.5778203],
                [105.3040305, 9.5782059],
                [105.303594, 9.5785968],
                [105.3031467, 9.5790093],
                [105.302601, 9.5794762],
                [105.3020227, 9.5799432],
                [105.3015317, 9.5804479],
                [105.3008987, 9.5809908],
                [105.3004949, 9.5813165],
                [105.2999056, 9.5817183],
                [105.2993707, 9.5820009],
                [105.2985191, 9.5822999],
                [105.2977877, 9.5825825],
                [105.2970671, 9.5829085],
                [105.2965105, 9.5832561],
                [105.2960522, 9.583647],
                [105.2954957, 9.584141],
                [105.295092, 9.5846512],
                [105.2946448, 9.5853023],
                [105.2942304, 9.5860294],
                [105.2939689, 9.5866858],
                [105.293773, 9.5873801],
                [105.2936207, 9.5880093],
                [105.2935338, 9.588644],
                [105.2934796, 9.5890995],
                [105.2934145, 9.5894792],
                [105.2932838, 9.5899457],
                [105.2930877, 9.5904448],
                [105.2927498, 9.5912315],
                [105.2924118, 9.5919042],
                [105.2920956, 9.5924902],
                [105.2915178, 9.5935807],
                [105.2912125, 9.5941993],
                [105.291049, 9.5945682],
                [105.2907111, 9.5952464],
                [105.2903402, 9.5959083],
                [105.2900022, 9.5963967],
                [105.2896094, 9.5968851],
                [105.2893148, 9.5972379],
                [105.2889547, 9.5975744],
                [105.2884417, 9.5979544],
                [105.2880161, 9.5982369],
                [105.2876013, 9.5985409],
                [105.287143, 9.5989426],
                [105.2866956, 9.5994148],
                [105.2864119, 9.5997839],
                [105.2862921, 9.6001202],
                [105.286216, 9.600565],
                [105.2862382, 9.6009879],
                [105.2863762, 9.6015162],
                [105.2865687, 9.6019968],
                [105.2867325, 9.6023405],
                [105.2868301, 9.6025018],
                [105.2870918, 9.6029341],
                [105.2874637, 9.6035412],
                [105.2877371, 9.6040453],
                [105.287978, 9.6046308],
                [105.2880986, 9.6052273],
                [105.2881209, 9.6058022],
                [105.2880451, 9.6065017],
                [105.2879582, 9.6069574],
                [105.2879258, 9.6074022],
                [105.2879045, 9.6081071],
                [105.2879488, 9.608785],
                [105.288026, 9.6095442],
                [105.2882232, 9.6103034],
                [105.2885408, 9.6113172],
                [105.2888146, 9.6121087],
                [105.2889242, 9.6125534],
                [105.289023, 9.6132094],
                [105.2890673, 9.6138385],
                [105.289046, 9.6144459],
                [105.2889482, 9.6150316],
                [105.2887851, 9.6158074],
                [105.2885783, 9.6166753],
                [105.2883278, 9.6174456],
                [105.2879463, 9.6184383],
                [105.2876412, 9.6191653],
                [105.2872705, 9.6199357],
                [105.2868563, 9.6209718],
                [105.2866493, 9.6215143],
                [105.2865624, 9.6219808],
                [105.2864428, 9.6226479],
                [105.2863996, 9.6232337],
                [105.2864002, 9.6238845],
                [105.2864224, 9.6243835],
                [105.2864775, 9.6249636],
                [105.28662, 9.6255275],
                [105.2868608, 9.6261347],
                [105.2872109, 9.62672],
                [105.2875937, 9.6273217],
                [105.2879107, 9.6277337],
                [105.2883918, 9.6281562],
                [105.2888726, 9.6285137],
                [105.2893098, 9.6288279],
                [105.2897034, 9.6291475],
                [105.2899548, 9.6294509],
                [105.2903048, 9.6299279],
                [105.2906221, 9.6305676],
                [105.2908083, 9.6311314],
                [105.2909507, 9.6316248],
                [105.291148, 9.632449],
                [105.2913017, 9.6332406],
                [105.2915209, 9.6341461],
                [105.2916307, 9.6348619],
                [105.2917624, 9.6354475],
                [105.2919815, 9.6361469],
                [105.2921675, 9.6365588],
                [105.2924519, 9.6369274],
                [105.2927798, 9.6372308],
                [105.2931513, 9.6373932],
                [105.2936211, 9.6375338],
                [105.2939052, 9.6375498],
                [105.2942328, 9.6374952],
                [105.2944839, 9.6373865],
                [105.2947073, 9.6372594],
                [105.2949643, 9.6368844],
                [105.2951578, 9.6363832],
                [105.2952241, 9.6360414],
                [105.2953656, 9.6353905],
                [105.2957358, 9.6340832],
                [105.2958771, 9.6333021],
                [105.2961165, 9.6323529],
                [105.2962468, 9.631561],
                [105.296432, 9.6309642],
                [105.2967372, 9.6302969],
                [105.2970209, 9.6298953],
                [105.2974574, 9.6295045],
                [105.2978722, 9.6292763],
                [105.2983091, 9.6291892],
                [105.2989316, 9.6291344],
                [105.2996962, 9.6291338],
                [105.3005154, 9.6292306],
                [105.3009852, 9.6293332],
                [105.3014003, 9.6293763],
                [105.3017389, 9.629365],
                [105.3023941, 9.6291476],
                [105.3031473, 9.6286588],
                [105.3034746, 9.6283006],
                [105.3036272, 9.6280185],
                [105.303769, 9.6276604],
                [105.3038561, 9.6273349],
                [105.3038993, 9.6268956],
                [105.3039207, 9.6264183],
                [105.3039204, 9.6260713],
                [105.3038763, 9.6256157],
                [105.3036901, 9.6250519],
                [105.3034931, 9.624526],
                [105.3031974, 9.6237996],
                [105.303044, 9.6232249],
                [105.3029451, 9.62252],
                [105.3029554, 9.6219072],
                [105.3029439, 9.6213106],
                [105.3029654, 9.6208442],
                [105.3030632, 9.6203235],
                [105.3032374, 9.6197756],
                [105.3034772, 9.6191896],
                [105.3037387, 9.6186038],
                [105.3041423, 9.6179526],
                [105.3045023, 9.6174697],
                [105.3047532, 9.6171115],
                [105.3049603, 9.6167099],
                [105.3051238, 9.6162868],
                [105.3053088, 9.6156467],
                [105.305483, 9.6150012],
                [105.305657, 9.6141876],
                [105.3057758, 9.6136141],
                [105.3059831, 9.612544],
                [105.3061899, 9.6117466],
                [105.3064186, 9.6109873],
                [105.3066581, 9.6101844],
                [105.3068324, 9.6097178],
                [105.3070722, 9.6092186],
                [105.3072903, 9.6088225],
                [105.3077158, 9.6083123],
                [105.3081195, 9.6079324],
                [105.3086653, 9.6075848],
                [105.3092548, 9.6072589],
                [105.3099099, 9.6070197],
                [105.310434, 9.6069054],
                [105.3110455, 9.6068288],
                [105.3117008, 9.6068283],
                [105.312662, 9.6068599],
                [105.313503, 9.6069134],
                [105.3142566, 9.6068964],
                [105.31489, 9.6068416],
                [105.3154797, 9.6066783],
                [105.316222, 9.6063088],
                [105.3171171, 9.6058307],
                [105.3181541, 9.6052061],
                [105.3188309, 9.6048475],
                [105.3194532, 9.6046082],
                [105.3201957, 9.6043364],
                [105.320818, 9.6041731],
                [105.3214296, 9.6041129],
                [105.3222159, 9.6040579],
                [105.3229585, 9.6040355],
                [105.3236356, 9.6039698],
                [105.3240942, 9.6038176],
                [105.3246509, 9.6035296],
                [105.3250766, 9.6032254],
                [105.3254039, 9.6029214],
                [105.3256765, 9.6024657],
                [105.3258834, 9.6018255],
                [105.3260576, 9.6012668],
                [105.32633, 9.6006808],
                [105.3267664, 9.6001815],
                [105.3272357, 9.5998339],
                [105.3279562, 9.5994482],
                [105.3285348, 9.5993066],
                [105.3289935, 9.5992736],
                [105.3293649, 9.59936],
                [105.3297911, 9.5995982],
                [105.3301408, 9.5998148],
                [105.330447, 9.6001183],
                [105.3306657, 9.6005193],
                [105.3308411, 9.6011537],
                [105.3309292, 9.6018369],
                [105.3309734, 9.6023249],
                [105.3310503, 9.6027696],
                [105.3312583, 9.6033279],
                [105.3314882, 9.6037941],
                [105.3317943, 9.6041083],
                [105.3321877, 9.6043357],
                [105.3328213, 9.6044978],
                [105.333422, 9.6045298],
                [105.3340992, 9.6045291],
                [105.3348528, 9.6045175],
                [105.3352678, 9.604555],
                [105.3360871, 9.6047061],
                [105.3367974, 9.604944],
                [105.3374967, 9.6053012],
                [105.3380978, 9.6057345],
                [105.3385023, 9.6061679],
                [105.3389727, 9.6068561],
                [105.3391479, 9.6072898],
                [105.3392138, 9.6076694],
                [105.3392036, 9.6083528],
                [105.3391718, 9.6093018],
                [105.3391506, 9.6099417],
                [105.3391512, 9.6105708],
                [105.3392063, 9.6109884],
                [105.3393706, 9.6114438],
                [105.339666, 9.6118991],
                [105.3400376, 9.6122241],
                [105.3405184, 9.6124622],
                [105.3410646, 9.6125483],
                [105.3414578, 9.6125317],
                [105.3419929, 9.6123685],
                [105.3423093, 9.6121404],
                [105.3425493, 9.6118473],
                [105.3426909, 9.6114783],
                [105.342767, 9.6111312],
                [105.3427776, 9.6108275],
                [105.3427445, 9.610513],
                [105.3426896, 9.610269],
                [105.3424598, 9.6098245],
                [105.3422629, 9.6094451],
                [105.3419893, 9.6090115],
                [105.3417048, 9.6084262],
                [105.3415293, 9.6077267],
                [105.3414741, 9.6072496],
                [105.3414737, 9.6068049],
                [105.3414843, 9.6064252],
                [105.3415712, 9.605975],
                [105.3418218, 9.6053456],
                [105.3421159, 9.6046512],
                [105.342432, 9.6040218],
                [105.3429117, 9.603235],
                [105.3433589, 9.6026705],
                [105.3438826, 9.6022036],
                [105.3445812, 9.6018016],
                [105.3453127, 9.6015134],
                [105.3461425, 9.6012848],
                [105.3466883, 9.6010673],
                [105.3471686, 9.600774],
                [105.347616, 9.6004155],
                [105.3478559, 9.6000791],
                [105.3480302, 9.599607],
                [105.3480842, 9.5991189],
                [105.3480838, 9.5986851],
                [105.3479851, 9.5982622],
                [105.3477553, 9.5978612],
                [105.3472413, 9.597227],
                [105.3467384, 9.5966961],
                [105.3463777, 9.5964037],
                [105.3459732, 9.5960678],
                [105.3455578, 9.5956669],
                [105.3451202, 9.5950111],
                [105.344945, 9.5945233],
                [105.3448353, 9.5940462],
                [105.3448458, 9.593634],
                [105.345031, 9.5931621],
                [105.3453035, 9.5927821],
                [105.3457074, 9.5925105],
                [105.3460349, 9.5923909],
                [105.3463297, 9.592358],
                [105.3467776, 9.5923902],
                [105.3473238, 9.5925957],
                [105.3479685, 9.5929637],
                [105.3484603, 9.5932778],
                [105.3489194, 9.593554],
                [105.3494656, 9.5937377],
                [105.3501647, 9.5938781],
                [105.3508419, 9.5939423],
                [105.3517047, 9.5939198],
                [105.3525019, 9.5938756],
                [105.35331, 9.5937717],
                [105.3542054, 9.5936297],
                [105.3551008, 9.593477],
                [105.3558651, 9.5933135],
                [105.3568042, 9.593177],
                [105.3577027, 9.5930437],
                [105.3594089, 9.5927906],
                [105.3617526, 9.5926373],
                [105.3621223, 9.5926236],
                [105.3626356, 9.5925795],
                [105.3633234, 9.5924596],
                [105.364066, 9.5923448],
                [105.3646992, 9.5920948],
                [105.3651466, 9.5918231],
                [105.3655722, 9.5915189],
                [105.3660304, 9.5910954],
                [105.3664776, 9.5905526],
                [105.3668483, 9.589934],
                [105.3671751, 9.5892341],
                [105.3675675, 9.5886154],
                [105.3678512, 9.5883115],
                [105.368255, 9.5879747],
                [105.3688335, 9.5877573],
                [105.369482, 9.5877008],
                [105.370128, 9.5877251],
                [105.370764, 9.5880405],
                [105.371241, 9.588415],
                [105.3717179, 9.5890062],
                [105.3719364, 9.5896562],
                [105.3720353, 9.5907987],
                [105.3719157, 9.5913896],
                [105.3714978, 9.5924532],
                [105.3709807, 9.5933591],
                [105.3706624, 9.5941272],
                [105.3706025, 9.594797],
                [105.3706817, 9.5955652],
                [105.3708207, 9.5960971],
                [105.3710989, 9.5964517],
                [105.3713771, 9.5966883],
                [105.3717889, 9.5968581],
                [105.3722868, 9.5968916],
                [105.3729769, 9.5967593],
                [105.3732043, 9.5977033],
                [105.3737529, 9.6021794],
                [105.3744234, 9.6071645],
                [105.3743956, 9.6072636],
                [105.374406, 9.6074725],
                [105.3744516, 9.6077039],
                [105.3745119, 9.6079353],
                [105.3745133, 9.6080187],
                [105.3744918, 9.608102],
                [105.3744328, 9.6082633],
                [105.3744149, 9.608356],
                [105.3744211, 9.6084643],
                [105.3744435, 9.6087234],
                [105.3744435, 9.6088001],
                [105.3744261, 9.6088795],
                [105.3743564, 9.6091565],
                [105.3743269, 9.6093238],
                [105.3743054, 9.6093846],
                [105.3742598, 9.6094563],
                [105.3741217, 9.6096252],
                [105.37406, 9.6097495],
                [105.3740144, 9.6099082],
                [105.3739768, 9.6100854],
                [105.3739607, 9.6102467],
                [105.3739527, 9.6104133],
                [105.3739366, 9.6107042],
                [105.373944, 9.6107551],
                [105.3739581, 9.6108166],
                [105.3739929, 9.6108688],
                [105.3740546, 9.6109138],
                [105.3743248, 9.6110824],
                [105.374414, 9.6111677],
                [105.3744838, 9.6112635],
                [105.3745374, 9.6114143],
                [105.3746138, 9.6115558],
                [105.3747466, 9.611725],
                [105.3748633, 9.6119551],
                [105.3752522, 9.613325],
                [105.3754051, 9.6136463],
                [105.3755996, 9.6139451],
                [105.3757752, 9.6142129],
                [105.3760193, 9.6144846],
                [105.3760609, 9.6145573],
                [105.3760985, 9.6146565],
                [105.3762017, 9.6150347],
                [105.3762782, 9.6152343],
                [105.3763787, 9.6154499],
                [105.3765973, 9.6158161],
                [105.3766859, 9.6159999],
                [105.3767288, 9.6161202],
                [105.3769413, 9.616154],
                [105.3771271, 9.6162141],
                [105.378261, 9.6169361],
                [105.3786546, 9.6171847],
                [105.3788678, 9.6172673],
                [105.3791159, 9.6173209],
                [105.3793788, 9.6173407],
                [105.3796269, 9.6173328],
                [105.3798683, 9.6172852],
                [105.3803015, 9.6171146],
                [105.3804436, 9.6174716],
                [105.3805804, 9.617974],
                [105.3808017, 9.6187568],
                [105.3808956, 9.6189895],
                [105.3810149, 9.6191747],
                [105.3811598, 9.6193518],
                [105.381365, 9.6195317],
                [105.3815916, 9.619652],
                [105.3819068, 9.6197842],
                [105.3821482, 9.6198596],
                [105.3824593, 9.6199059],
                [105.3826578, 9.6199482],
                [105.3829086, 9.6200262],
                [105.3830574, 9.6201108],
                [105.3831661, 9.6202126],
                [105.3832787, 9.6203647],
                [105.3834075, 9.6205233],
                [105.3835188, 9.6206159],
                [105.3836623, 9.6206794],
                [105.3838755, 9.6207442],
                [105.384247, 9.6208222],
                [105.38499, 9.6209782],
                [105.3857115, 9.6210919],
                [105.3861004, 9.6212294],
                [105.3865162, 9.6213035],
                [105.3866664, 9.6213564],
                [105.3868756, 9.6214939],
                [105.387015, 9.6215468],
                [105.3874013, 9.6216076],
                [105.3877097, 9.6217107],
                [105.3878251, 9.6217292],
                [105.3881711, 9.621753],
                [105.388273, 9.6217662],
                [105.3883696, 9.6218191],
                [105.3884339, 9.6218905],
                [105.3885037, 9.6219937],
                [105.3886887, 9.6222713],
                [105.3890079, 9.6226495],
                [105.3892788, 9.6228954],
                [105.3895283, 9.6230568],
                [105.3896087, 9.6231282],
                [105.3896624, 9.6232075],
                [105.3896919, 9.6232921],
                [105.3896919, 9.6233926],
                [105.3896812, 9.6235394],
                [105.3896034, 9.6243182],
                [105.3895711, 9.6247488],
                [105.3895826, 9.6248794],
                [105.3896128, 9.6249978],
                [105.3896503, 9.625089],
                [105.3896986, 9.625171],
                [105.3899574, 9.6255346],
                [105.3902712, 9.6258367],
                [105.3904295, 9.6256642],
                [105.3905234, 9.6254791],
                [105.3905931, 9.625368],
                [105.3906736, 9.6253019],
                [105.3907728, 9.6252437],
                [105.3908882, 9.6251961],
                [105.391438, 9.6250903],
                [105.3915185, 9.625056],
                [105.3915882, 9.6250057],
                [105.3916579, 9.6249237],
                [105.3917518, 9.6247439],
                [105.3920016, 9.6243833],
                [105.392358, 9.623977],
                [105.3926061, 9.623825],
                [105.3928395, 9.6237509],
                [105.3931734, 9.6236914],
                [105.3934818, 9.6236438],
                [105.3937072, 9.6236491],
                [105.3939365, 9.6236623],
                [105.3940974, 9.6236848],
                [105.3941993, 9.6236875],
                [105.394308, 9.623665],
                [105.3946593, 9.6235751],
                [105.3947908, 9.6235539],
                [105.3949101, 9.6235539],
                [105.3950375, 9.6235698],
                [105.3951569, 9.6236187],
                [105.3953755, 9.6237258],
                [105.3955042, 9.6237615],
                [105.3956343, 9.6237734],
                [105.395755, 9.6237681],
                [105.395873, 9.6237351],
                [105.3959656, 9.6236795],
                [105.3961158, 9.6234878],
                [105.3962418, 9.6233847],
                [105.3963974, 9.6233159],
                [105.3966737, 9.6232577],
                [105.3969607, 9.623226],
                [105.3973335, 9.6232234],
                [105.3977117, 9.6232472],
                [105.3978726, 9.6232286],
                [105.3980416, 9.623144],
                [105.3982267, 9.6230171],
                [105.3984144, 9.6228478],
                [105.3985888, 9.6226574],
                [105.3987819, 9.6224009],
                [105.3989616, 9.6221471],
                [105.3990421, 9.6220545],
                [105.3991494, 9.6219937],
                [105.3994471, 9.6219064],
                [105.3994685, 9.6220439],
                [105.3994739, 9.6222079],
                [105.3994337, 9.6224379],
                [105.3993505, 9.6228161],
                [105.3992191, 9.6235989],
                [105.3991869, 9.6254923],
                [105.3991373, 9.6272707],
                [105.399085, 9.6293657],
                [105.3990836, 9.6300758],
                [105.3990957, 9.630247],
                [105.3991306, 9.6304083],
                [105.3991936, 9.6306172],
                [105.3995906, 9.6316855],
                [105.4003208, 9.6337078],
                [105.400815, 9.6350928],
                [105.4008593, 9.6351821],
                [105.4009585, 9.6353017],
                [105.4011235, 9.6354009],
                [105.4015124, 9.6355728],
                [105.4017994, 9.6356905],
                [105.4018966, 9.6357328],
                [105.401967, 9.6357764],
                [105.402022, 9.6358385],
                [105.4020636, 9.6359152],
                [105.4021172, 9.6360382],
                [105.4022842, 9.6363998],
                [105.4029621, 9.6378813],
                [105.4030345, 9.6380453],
                [105.4031056, 9.6381524],
                [105.4031995, 9.6382423],
                [105.4033417, 9.6383332],
                [105.4036783, 9.6384974],
                [105.4038566, 9.6385847],
                [105.403976, 9.6386563],
                [105.4040642, 9.6387447],
                [105.4041503, 9.6388372],
                [105.4043649, 9.6389985],
                [105.4044333, 9.6390792],
                [105.4044762, 9.6391585],
                [105.4045084, 9.639259],
                [105.4045245, 9.6393754],
                [105.4045473, 9.6399783],
                [105.4045949, 9.6402639],
                [105.4046435, 9.6404498],
                [105.4047203, 9.6405997],
                [105.4048695, 9.6407676],
                [105.4050194, 9.640876],
                [105.4051951, 9.6409699],
                [105.4053124, 9.641034],
                [105.4054344, 9.6411239],
                [105.4054911, 9.6412019],
                [105.405537, 9.6412965],
                [105.4055907, 9.6414961],
                [105.4056416, 9.6417182],
                [105.4056738, 9.6418068],
                [105.4057248, 9.6418835],
                [105.4057878, 9.6419404],
                [105.4058857, 9.6419946],
                [105.4062083, 9.6421208],
                [105.4062974, 9.6421797],
                [105.4063806, 9.642257],
                [105.4064886, 9.6423919],
                [105.4065865, 9.6425545],
                [105.4068862, 9.6431329],
                [105.4069452, 9.6432605],
                [105.4070015, 9.6434661],
                [105.4070243, 9.6436816],
                [105.4070149, 9.6439434],
                [105.4069579, 9.644344],
                [105.4069117, 9.644619],
                [105.406858, 9.6448359],
                [105.4067856, 9.6449655],
                [105.406673, 9.6450435],
                [105.4065442, 9.645095],
                [105.4064155, 9.6451069],
                [105.406276, 9.6450871],
                [105.4059474, 9.6449945],
                [105.4047418, 9.6447691],
                [105.4042556, 9.6446085],
                [105.4041312, 9.6445673],
                [105.4040561, 9.6445549],
                [105.403981, 9.6445543],
                [105.403912, 9.6445731],
                [105.4038399, 9.6446065],
                [105.4037574, 9.6446713],
                [105.4035837, 9.6449093],
                [105.4035274, 9.6449747],
                [105.4034563, 9.6450283],
                [105.4033752, 9.6450633],
                [105.403288, 9.6450805],
                [105.4031955, 9.6450785],
                [105.4030929, 9.6450507],
                [105.4027053, 9.6449357],
                [105.4022339, 9.6447995],
                [105.4019704, 9.644703],
                [105.4019127, 9.6446904],
                [105.4018537, 9.6446878],
                [105.4017833, 9.6446984],
                [105.4017122, 9.6447301],
                [105.4016384, 9.6447803],
                [105.4015566, 9.6448676],
                [105.4014721, 9.6449826],
                [105.4014011, 9.6451162],
                [105.4013441, 9.6452649],
                [105.4012777, 9.6454758],
                [105.4012267, 9.6456662],
                [105.4011959, 9.645846],
                [105.4011764, 9.6460199],
                [105.4011758, 9.6462612],
                [105.4011912, 9.6464039],
                [105.4012063, 9.6464661],
                [105.4012328, 9.6465216],
                [105.4012831, 9.6465765],
                [105.4017695, 9.6470419],
                [105.4023077, 9.6476097],
                [105.4029849, 9.6478319],
                [105.4031415, 9.6478993],
                [105.4032934, 9.6479998],
                [105.4034305, 9.6481429],
                [105.4036313, 9.6484043],
                [105.4041939, 9.649269],
                [105.4043207, 9.6494343],
                [105.404494, 9.6496184],
                [105.4046613, 9.6497529],
                [105.4048866, 9.6498693],
                [105.4054539, 9.6500854],
                [105.4059649, 9.6502923],
                [105.4061137, 9.6503804],
                [105.4062053, 9.6504564],
                [105.4062915, 9.6505384],
                [105.406372, 9.6506258],
                [105.4064463, 9.6507184],
                [105.4065143, 9.6508157],
                [105.4065754, 9.6509172],
                [105.4066296, 9.6510225],
                [105.4066768, 9.6511319],
                [105.4067164, 9.6512442],
                [105.4067483, 9.6513589],
                [105.4067722, 9.6514755],
                [105.4067881, 9.6515933],
                [105.406796, 9.651712],
                [105.4067957, 9.6518309],
                [105.4066339, 9.6517644],
                [105.4065081, 9.6517438],
                [105.4063681, 9.6517348],
                [105.4062277, 9.6517348],
                [105.4061015, 9.6517425],
                [105.4059761, 9.6517574],
                [105.4058517, 9.6517795],
                [105.4057288, 9.6518088],
                [105.4055076, 9.6518621],
                [105.4052885, 9.6519235],
                [105.4050719, 9.6519929],
                [105.4048581, 9.6520701],
                [105.4046473, 9.6521551],
                [105.4044399, 9.6522478],
                [105.4042361, 9.6523479],
                [105.4040362, 9.6524555],
                [105.4038405, 9.6525704],
                [105.403648, 9.6526931],
                [105.4034603, 9.6528229],
                [105.4032777, 9.6529595],
                [105.4031003, 9.6531027],
                [105.4029285, 9.6532524],
                [105.4027626, 9.6534083],
                [105.4026026, 9.6535702],
                [105.4024489, 9.6537379],
                [105.4023016, 9.6539111],
                [105.4020645, 9.6541531],
                [105.4017945, 9.6544419],
                [105.4014231, 9.6547892],
                [105.4010471, 9.6551316],
                [105.4006807, 9.6554566],
                [105.40031, 9.6557769],
                [105.3991198, 9.6568328],
                [105.3983506, 9.6574553],
                [105.3979511, 9.6577905],
                [105.3975458, 9.6581189],
                [105.397135, 9.6584405],
                [105.3967544, 9.6587286],
                [105.3963265, 9.6590585],
                [105.3959799, 9.6592872],
                [105.3957831, 9.6594362],
                [105.3956188, 9.659566],
                [105.3954499, 9.6596899],
                [105.3952765, 9.6598076],
                [105.3950988, 9.659919],
                [105.3949172, 9.6600239],
                [105.3947317, 9.6601222],
                [105.3945796, 9.6601966],
                [105.3944237, 9.6602629],
                [105.3942644, 9.6603209],
                [105.3941022, 9.6603705],
                [105.3939375, 9.6604114],
                [105.3937709, 9.6604437],
                [105.3936027, 9.6604672],
                [105.3934336, 9.6604818],
                [105.3933364, 9.6604862],
                [105.3932391, 9.6604877],
                [105.3930445, 9.6604858],
                [105.3928501, 9.6604763],
                [105.3926563, 9.6604591],
                [105.3924634, 9.6604343],
                [105.3922716, 9.6604019],
                [105.3920812, 9.6603619],
                [105.3918927, 9.6603145],
                [105.3917062, 9.6602597],
                [105.3915051, 9.6601915],
                [105.3913073, 9.6601146],
                [105.391113, 9.6600293],
                [105.3909227, 9.6599357],
                [105.3907368, 9.659834],
                [105.3905555, 9.6597243],
                [105.3903793, 9.659607],
                [105.3902084, 9.6594822],
                [105.3900432, 9.6593501],
                [105.3898804, 9.6592096],
                [105.3897119, 9.6590756],
                [105.3895383, 9.6589482],
                [105.3893596, 9.6588277],
                [105.3891763, 9.6587143],
                [105.3889885, 9.6586081],
                [105.3887966, 9.6585094],
                [105.3886009, 9.6584182],
                [105.3884017, 9.6583348],
                [105.3881914, 9.6582564],
                [105.387978, 9.6581866],
                [105.3877618, 9.6581255],
                [105.3875433, 9.6580732],
                [105.3873227, 9.6580299],
                [105.3871006, 9.6579955],
                [105.3868772, 9.6579702],
                [105.3866529, 9.657954],
                [105.3863632, 9.6579611],
                [105.3860738, 9.6579776],
                [105.3857852, 9.6580034],
                [105.3854975, 9.6580384],
                [105.3852112, 9.6580827],
                [105.3849265, 9.6581361],
                [105.3846437, 9.6581987],
                [105.3843631, 9.6582704],
                [105.384085, 9.658351],
                [105.3838098, 9.6584405],
                [105.3835506, 9.658534],
                [105.3832944, 9.6586353],
                [105.3830416, 9.6587444],
                [105.3827924, 9.6588612],
                [105.3825469, 9.6589856],
                [105.3823055, 9.6591175],
                [105.3820683, 9.6592566],
                [105.3818357, 9.659403],
                [105.3814134, 9.6596238],
                [105.3809876, 9.659838],
                [105.3805583, 9.6600453],
                [105.3801257, 9.6602459],
                [105.3796899, 9.6604395],
                [105.3792787, 9.6606147],
                [105.3788649, 9.6607838],
                [105.3784485, 9.6609467],
                [105.3782898, 9.6610156],
                [105.3781284, 9.6610781],
                [105.3779645, 9.6611341],
                [105.3777985, 9.6611835],
                [105.3776306, 9.6612262],
                [105.3774611, 9.6612621],
                [105.3772836, 9.6612922],
                [105.3771318, 9.6613201],
                [105.3769604, 9.6613433],
                [105.376802, 9.6613563],
                [105.3766376, 9.6613623],
                [105.3763832, 9.6613513],
                [105.3760958, 9.6613332],
                [105.3757571, 9.6612875],
                [105.3756096, 9.66126],
                [105.375464, 9.6612237],
                [105.3753209, 9.6611788],
                [105.3751808, 9.6611253],
                [105.3750443, 9.6610636],
                [105.3749088, 9.660992],
                [105.3747782, 9.6609122],
                [105.3746528, 9.6608245],
                [105.3745332, 9.6607293],
                [105.3744198, 9.6606269],
                [105.3743132, 9.6605178],
                [105.3742137, 9.6604023],
                [105.3741216, 9.6602809],
                [105.3740135, 9.6601459],
                [105.3738985, 9.6600165],
                [105.3737769, 9.6598931],
                [105.373649, 9.6597761],
                [105.3735152, 9.6596658],
                [105.3733758, 9.6595623],
                [105.3732311, 9.6594661],
                [105.3730817, 9.6593774],
                [105.3729278, 9.6592963],
                [105.3727698, 9.6592232],
                [105.3726046, 9.6591568],
                [105.372436, 9.6590991],
                [105.3722646, 9.6590502],
                [105.3720908, 9.6590103],
                [105.3719152, 9.6589795],
                [105.3717381, 9.6589578],
                [105.3715601, 9.6589454],
                [105.3713817, 9.6589422],
                [105.3712034, 9.6589482],
                [105.3710474, 9.6589674],
                [105.3708927, 9.6589956],
                [105.3707401, 9.6590328],
                [105.3705899, 9.6590789],
                [105.3704428, 9.6591336],
                [105.3702992, 9.6591967],
                [105.3701596, 9.6592682],
                [105.3700245, 9.6593476],
                [105.3698945, 9.6594347],
                [105.3697694, 9.6595297],
                [105.3696503, 9.6596318],
                [105.3695375, 9.6597407],
                [105.3694315, 9.659856],
                [105.3693326, 9.6599773],
                [105.3692411, 9.6601041],
                [105.3691575, 9.6602361],
                [105.3690551, 9.6604151],
                [105.3689293, 9.660659],
                [105.3688412, 9.6608299],
                [105.3687666, 9.660994],
                [105.3687004, 9.6611616],
                [105.3686427, 9.6613323],
                [105.3685937, 9.6615055],
                [105.3685534, 9.6616809],
                [105.3685221, 9.6618581],
                [105.3684997, 9.6620366],
                [105.3684861, 9.6622221],
                [105.3684821, 9.662408],
                [105.3684879, 9.6625938],
                [105.3685033, 9.6627791],
                [105.3685283, 9.6629634],
                [105.3685629, 9.6631462],
                [105.368607, 9.663327],
                [105.3686769, 9.6635428],
                [105.3687554, 9.6637556],
                [105.3688426, 9.6639652],
                [105.3689383, 9.6641712],
                [105.3690422, 9.6643732],
                [105.3691543, 9.6645709],
                [105.3692744, 9.6647641],
                [105.3694022, 9.6649523],
                [105.3695376, 9.6651353],
                [105.3696803, 9.6653128],
                [105.3698301, 9.6654846],
                [105.3699882, 9.6656517],
                [105.3701531, 9.6658123],
                [105.3703244, 9.6659663],
                [105.3705019, 9.6661132],
                [105.3706853, 9.666253],
                [105.3708743, 9.6663853],
                [105.3710685, 9.66651],
                [105.3712946, 9.666648],
                [105.3714903, 9.6667878],
                [105.3717072, 9.666956],
                [105.3719182, 9.6671314],
                [105.3721232, 9.6673136],
                [105.3723217, 9.6675026],
                [105.3725137, 9.6676981],
                [105.3726989, 9.6678998],
                [105.3728771, 9.6681076],
                [105.3730276, 9.6682948],
                [105.3731725, 9.6684863],
                [105.3733115, 9.668682],
                [105.3734446, 9.6688816],
                [105.3735716, 9.6690851],
                [105.3736925, 9.6692921],
                [105.3737562, 9.6694245],
                [105.3738121, 9.6695601],
                [105.3738603, 9.6696987],
                [105.3739004, 9.6698398],
                [105.3739324, 9.6699828],
                [105.3739562, 9.6701274],
                [105.3739717, 9.6702731],
                [105.3739788, 9.6704194],
                [105.3739776, 9.6705658],
                [105.373968, 9.670712],
                [105.37395, 9.6708574],
                [105.3739223, 9.6710083],
                [105.3738856, 9.6711573],
                [105.3738401, 9.6713039],
                [105.3737859, 9.6714476],
                [105.3737232, 9.6715879],
                [105.3736522, 9.6717243],
                [105.3735732, 9.6718563],
                [105.3734864, 9.6719835],
                [105.3733787, 9.6721028],
                [105.373267, 9.6721979],
                [105.3731373, 9.672284],
                [105.3730033, 9.6723634],
                [105.3728654, 9.672436],
                [105.3727239, 9.6725016],
                [105.3725791, 9.67256],
                [105.3724316, 9.672611],
                [105.3722816, 9.6726546],
                [105.3721296, 9.6726906],
                [105.3719759, 9.6727189],
                [105.3718193, 9.6727396],
                [105.3716618, 9.6727523],
                [105.3715038, 9.672757],
                [105.3713459, 9.6727537],
                [105.3711883, 9.6727424],
                [105.3710315, 9.6727231],
                [105.3708759, 9.6726958],
                [105.3707219, 9.6726607],
                [105.37057, 9.6726179],
                [105.3704206, 9.6725673],
                [105.3702739, 9.6725093],
                [105.3697381, 9.6722786],
                [105.3692003, 9.6720523],
                [105.3686607, 9.6718304],
                [105.3679138, 9.6715318],
                [105.3671635, 9.6712416],
                [105.3665681, 9.6710146],
                [105.36597, 9.6707946],
                [105.3653693, 9.6705815],
                [105.3647661, 9.6703755],
                [105.3641604, 9.6701765],
                [105.3635524, 9.6699846],
                [105.3629422, 9.6697998],
                [105.3622545, 9.6696008],
                [105.3615643, 9.6694109],
                [105.3608715, 9.6692301],
                [105.3601334, 9.669016],
                [105.3596838, 9.6689044],
                [105.3592322, 9.6688004],
                [105.3588446, 9.6687177],
                [105.3584559, 9.6686406],
                [105.358125, 9.6685798],
                [105.3578833, 9.6685479],
                [105.3576998, 9.6685354],
                [105.3575159, 9.6685319],
                [105.357332, 9.6685374],
                [105.3571486, 9.6685518],
                [105.3569731, 9.668574],
                [105.3567988, 9.6686044],
                [105.3566262, 9.6686429],
                [105.3564556, 9.6686894],
                [105.3562874, 9.6687439],
                [105.3561221, 9.6688062],
                [105.3559599, 9.6688761],
                [105.3558013, 9.6689535],
                [105.3556412, 9.6690594],
                [105.3555112, 9.6691882],
                [105.3553832, 9.6693445],
                [105.3552631, 9.6695066],
                [105.355151, 9.6696742],
                [105.3550472, 9.669847],
                [105.354952, 9.6700245],
                [105.3548657, 9.6702063],
                [105.3547883, 9.6703921],
                [105.3547246, 9.6705678],
                [105.3546689, 9.6707462],
                [105.3546214, 9.6709269],
                [105.3545822, 9.6711095],
                [105.3545514, 9.6712936],
                [105.3545289, 9.6714789],
                [105.3545149, 9.671665],
                [105.3545093, 9.6718516],
                [105.3545051, 9.6721013],
                [105.354493, 9.6723508],
                [105.3544731, 9.6725998],
                [105.3544455, 9.672848],
                [105.3544101, 9.6730953],
                [105.354367, 9.6733414],
                [105.3543162, 9.6735861],
                [105.3542524, 9.6738501],
                [105.3541797, 9.6741118],
                [105.3540981, 9.6743709],
                [105.3540078, 9.6746273],
                [105.3539088, 9.6748805],
                [105.3538066, 9.6751038],
                [105.3536952, 9.675276],
                [105.3535823, 9.6754166],
                [105.3534626, 9.6755517],
                [105.3533365, 9.675681],
                [105.3532042, 9.6758041],
                [105.353066, 9.6759208],
                [105.3529224, 9.6760308],
                [105.3527735, 9.676134],
                [105.3526198, 9.6762299],
                [105.3524615, 9.6763184],
                [105.3522992, 9.6763994],
                [105.3521299, 9.6764739],
                [105.3519571, 9.6765401],
                [105.3517812, 9.6765979],
                [105.3516027, 9.6766471],
                [105.351422, 9.6766877],
                [105.3512394, 9.6767194],
                [105.3510555, 9.6767424],
                [105.3508706, 9.6767564],
                [105.3506853, 9.6767615],
                [105.3505, 9.6767576],
                [105.3503151, 9.6767448],
                [105.350131, 9.6767231],
                [105.3499482, 9.6766926],
                [105.3495865, 9.6766318],
                [105.3492257, 9.6765658],
                [105.348866, 9.6764946],
                [105.3484012, 9.6763945],
                [105.3479278, 9.676291],
                [105.3470883, 9.6761031],
                [105.346658, 9.6759999],
                [105.3462261, 9.6759038],
                [105.3457926, 9.6758149],
                [105.3453576, 9.6757331],
                [105.3449991, 9.6756712],
                [105.344639, 9.6756182],
                [105.3442778, 9.6755743],
                [105.3439155, 9.6755393],
                [105.3435524, 9.6755134],
                [105.3431888, 9.6754965],
                [105.3428249, 9.6754886],
                [105.3424609, 9.6754898],
                [105.3421187, 9.6754992],
                [105.3417768, 9.6755166],
                [105.3414354, 9.675542],
                [105.3410947, 9.6755754],
                [105.340755, 9.6756167],
                [105.340587, 9.6756373],
                [105.3404203, 9.6756666],
                [105.3402554, 9.6757044],
                [105.3400927, 9.6757508],
                [105.3399328, 9.6758055],
                [105.3397759, 9.6758684],
                [105.3396227, 9.6759394],
                [105.3394734, 9.6760182],
                [105.3393286, 9.6761046],
                [105.3391886, 9.6761984],
                [105.339061, 9.6762936],
                [105.3389384, 9.676395],
                [105.338821, 9.6765022],
                [105.3387092, 9.6766151],
                [105.3386032, 9.6767332],
                [105.3385033, 9.6768565],
                [105.3384098, 9.6769844],
                [105.3383228, 9.6771168],
                [105.3382426, 9.6772533],
                [105.3381892, 9.6773808],
                [105.3381423, 9.6775107],
                [105.3381018, 9.6776427],
                [105.3380679, 9.6777765],
                [105.3380406, 9.6779118],
                [105.3380169, 9.6780738],
                [105.3380028, 9.6782369],
                [105.3379983, 9.6784005],
                [105.3380033, 9.6785641],
                [105.3380179, 9.6787272],
                [105.3380795, 9.6789775],
                [105.3381646, 9.6792513],
                [105.338225, 9.6795007],
                [105.3382766, 9.6797626],
                [105.3383152, 9.6799972],
                [105.3383469, 9.6802328],
                [105.3383715, 9.6804957],
                [105.3383678, 9.6807938],
                [105.338334, 9.6813721],
                [105.3382812, 9.6819804],
                [105.3382469, 9.6825627],
                [105.338215, 9.6831825],
                [105.3381908, 9.6838026],
                [105.3381735, 9.6844649],
                [105.338165, 9.6851273],
                [105.3381654, 9.6857898],
                [105.3381746, 9.6864522],
                [105.3381819, 9.6870192],
                [105.3381753, 9.6872739],
                [105.3381495, 9.6874323],
                [105.3381155, 9.6875892],
                [105.3380732, 9.6877441],
                [105.3380228, 9.6878966],
                [105.3379644, 9.6880463],
                [105.3378982, 9.6881928],
                [105.3378211, 9.6883416],
                [105.337736, 9.688486],
                [105.3376431, 9.6886257],
                [105.3375426, 9.6887602],
                [105.3374348, 9.6888892],
                [105.3373202, 9.6890122],
                [105.3371989, 9.6891289],
                [105.3370715, 9.6892389],
                [105.3369381, 9.689342],
                [105.3367993, 9.6894378],
                [105.3366554, 9.6895261],
                [105.3365069, 9.6896065],
                [105.3363542, 9.6896789],
                [105.3361977, 9.689743],
                [105.3360379, 9.6897987],
                [105.3358753, 9.6898458],
                [105.3357104, 9.6898841],
                [105.3355436, 9.6899136],
                [105.3353754, 9.6899341],
                [105.3352063, 9.6899456],
                [105.3350409, 9.6899374],
                [105.3347968, 9.6909181],
                [105.3346842, 9.6917054],
                [105.3344866, 9.6926615],
                [105.3342037, 9.6933365],
                [105.3340906, 9.6935896],
                [105.3337507, 9.6940397],
                [105.3328158, 9.6949403],
                [105.3319373, 9.6955878],
                [105.3301094, 9.6968968],
                [105.3293161, 9.6977128],
                [105.3289483, 9.6985004],
                [105.3286652, 9.6990348],
                [105.3285807, 9.699569],
                [105.3285245, 9.7001876],
                [105.328525, 9.7006936],
                [105.3286387, 9.7010028],
                [105.3289227, 9.7013679],
                [105.3291215, 9.7016489],
                [105.3293486, 9.7018454],
                [105.3296607, 9.702042],
                [105.3300579, 9.7022946],
                [105.3307105, 9.7026033],
                [105.3312355, 9.7028698],
                [105.3318878, 9.7029535],
                [105.3325684, 9.702981],
                [105.3334759, 9.7029801],
                [105.334355, 9.7029511],
                [105.3364254, 9.7030896],
                [105.3382977, 9.7036501],
                [105.340014, 9.7042809],
                [105.3413759, 9.7048981],
                [105.3428231, 9.7057681],
                [105.3448094, 9.7068625],
                [105.346358, 9.7079726],
                [105.3476273, 9.7087293],
                [105.3496767, 9.7097218],
                [105.3510944, 9.7101595],
                [105.3542718, 9.7111683],
                [105.355804, 9.7118977],
                [105.3570243, 9.7126273],
                [105.3582732, 9.7137224],
                [105.3597353, 9.7151125],
                [105.3611547, 9.7164042],
                [105.3625461, 9.7180614],
                [105.3631992, 9.7187635],
                [105.3642215, 9.7200276],
                [105.3650311, 9.7211935],
                [105.3664516, 9.7234691],
                [105.3691071, 9.7269383],
                [105.3706117, 9.7282298],
                [105.3723719, 9.7298585],
                [105.3732235, 9.7305041],
                [105.3742452, 9.7312058],
                [105.3754369, 9.7317106],
                [105.3787138, 9.7326768],
                [105.3793664, 9.7329572],
                [105.3799054, 9.7330973],
                [105.3803595, 9.7332654],
                [105.3809835, 9.7334053],
                [105.3826289, 9.7337408],
                [105.3845574, 9.734074],
                [105.3872994, 9.7345477],
                [105.3876925, 9.7346156],
                [105.3885198, 9.7348278],
                [105.3901041, 9.7353994],
                [105.3904425, 9.7355392],
                [105.3906763, 9.7357054],
                [105.390842, 9.7358481],
                [105.3911, 9.7360597],
                [105.3912963, 9.736269],
                [105.3916087, 9.7366619],
                [105.3918075, 9.7368863],
                [105.3919781, 9.7371671],
                [105.3922054, 9.7375601],
                [105.3926034, 9.7383182],
                [105.3928026, 9.7388516],
                [105.3930902, 9.7432131],
                [105.3930943, 9.7433443],
                [105.3931069, 9.743475],
                [105.3931281, 9.7436047],
                [105.3931578, 9.7437326],
                [105.3931959, 9.7438584],
                [105.3932422, 9.7439816],
                [105.3932964, 9.7441015],
                [105.3933585, 9.7442177],
                [105.3934192, 9.7443163],
                [105.3934855, 9.7444113],
                [105.3935571, 9.7445025],
                [105.393634, 9.7445895],
                [105.3937157, 9.744672],
                [105.3938021, 9.7447498],
                [105.3938959, 9.744812],
                [105.3939931, 9.744869],
                [105.3940933, 9.7449207],
                [105.3941962, 9.7449669],
                [105.3943184, 9.7450134],
                [105.3944434, 9.7450522],
                [105.3945706, 9.745083],
                [105.3946996, 9.7451059],
                [105.3955401, 9.7453322],
                [105.3958383, 9.745416],
                [105.3961342, 9.745507],
                [105.3964278, 9.7456053],
                [105.3967188, 9.7457108],
                [105.3970071, 9.7458233],
                [105.3972924, 9.7459429],
                [105.3975819, 9.7460727],
                [105.3978679, 9.7462098],
                [105.3981503, 9.7463541],
                [105.3984289, 9.7465054],
                [105.3987034, 9.7466636],
                [105.3989738, 9.7468287],
                [105.4002134, 9.7477652],
                [105.4007585, 9.7481271],
                [105.4027122, 9.7499421],
                [105.4035203, 9.7505081],
                [105.4040254, 9.7507742],
                [105.4047657, 9.7508734],
                [105.4051021, 9.7508062],
                [105.4057073, 9.7505387],
                [105.4062452, 9.750138],
                [105.4068502, 9.7496371],
                [105.4078919, 9.7485354],
                [105.408497, 9.7481346],
                [105.4093043, 9.7479336],
                [105.4098091, 9.7479996],
                [105.4101792, 9.7480659],
                [105.4105158, 9.7481989],
                [105.4110544, 9.7483983],
                [105.4113238, 9.7485647],
                [105.4116007, 9.7488553],
                [105.4121825, 9.7496721],
                [105.4129768, 9.7508712],
                [105.4136896, 9.7519472],
                [105.4145157, 9.7534487],
                [105.4152049, 9.7548032],
                [105.4152694, 9.755196],
                [105.4152614, 9.7554431],
                [105.4152339, 9.7556847],
                [105.4147035, 9.7580376],
                [105.4145586, 9.7585663],
                [105.4144084, 9.7590316],
                [105.4142153, 9.7594915],
                [105.4139766, 9.7599145],
                [105.4137835, 9.7602079],
                [105.4135743, 9.7604458],
                [105.4133248, 9.7606863],
                [105.4130137, 9.7608925],
                [105.4127267, 9.76103],
                [105.4122813, 9.7611513],
                [105.4118335, 9.7611489],
                [105.4114339, 9.761104],
                [105.4110986, 9.7610326],
                [105.4107955, 9.7609242],
                [105.4104763, 9.7607656],
                [105.4101545, 9.7605753],
                [105.4088858, 9.759744],
                [105.408529, 9.7595602],
                [105.4081964, 9.7594545],
                [105.4079121, 9.7594122],
                [105.4076332, 9.7594281],
                [105.4073596, 9.7595074],
                [105.4071021, 9.7596607],
                [105.4068661, 9.7599462],
                [105.4066729, 9.7602898],
                [105.4065657, 9.7606705],
                [105.4065227, 9.7610141],
                [105.4065335, 9.7614476],
                [105.4065764, 9.7619604],
                [105.4066569, 9.7624733],
                [105.4068392, 9.7634936],
                [105.4068822, 9.7640593],
                [105.4068661, 9.7646038],
                [105.4068232, 9.7650373],
                [105.4067051, 9.7654655],
                [105.4065388, 9.765825],
                [105.4063457, 9.7661528],
                [105.405482, 9.7673476],
                [105.4052192, 9.7677705],
                [105.405018, 9.7681829],
                [105.4048866, 9.7685582],
                [105.4048061, 9.7689389],
                [105.4048169, 9.7692878],
                [105.4048759, 9.7695944],
                [105.4050207, 9.7699063],
                [105.4052433, 9.7701706],
                [105.4055518, 9.7704085],
                [105.4058629, 9.7705566],
                [105.4062921, 9.7706676],
                [105.4068071, 9.7707152],
                [105.4073247, 9.770694],
                [105.407837, 9.7706306],
                [105.4084325, 9.770509],
                [105.4102322, 9.7700834],
                [105.4109967, 9.7699328],
                [105.4115385, 9.7698878],
                [105.4118872, 9.7698984],
                [105.4122385, 9.7699261],
                [105.4125228, 9.7699698],
                [105.412865, 9.7700596],
                [105.4132122, 9.7701812],
                [105.4140276, 9.7705883],
                [105.4143092, 9.7707548],
                [105.4145104, 9.7709425],
                [105.4145426, 9.7712808],
                [105.4145211, 9.7716298],
                [105.4144219, 9.7719549],
                [105.4142636, 9.7722245],
                [105.4140651, 9.7724465],
                [105.413813, 9.7725972],
                [105.4135448, 9.7726818],
                [105.4132444, 9.7727109],
                [105.4128796, 9.7726739],
                [105.4125296, 9.7725866],
                [105.4122198, 9.7724677],
                [105.4111013, 9.771869],
                [105.4106533, 9.7717091],
                [105.41031, 9.7716456],
                [105.4099613, 9.7716298],
                [105.4096556, 9.7716879],
                [105.4094195, 9.7718359],
                [105.4092747, 9.7720474],
                [105.409221, 9.7723223],
                [105.4092693, 9.7726342],
                [105.4093766, 9.772925],
                [105.4095107, 9.7732157],
                [105.4097226, 9.7735488],
                [105.4100512, 9.7740338],
                [105.4101652, 9.7743127],
                [105.4102483, 9.7746219],
                [105.410302, 9.7749365],
                [105.4103804, 9.775642],
                [105.410471, 9.7761788],
                [105.4105568, 9.7765092],
                [105.410652, 9.7767907],
                [105.4107794, 9.7770947],
                [105.4108894, 9.777293],
                [105.4110128, 9.7774264],
                [105.4111871, 9.7774925],
                [105.4114044, 9.7774952],
                [105.4116458, 9.7774291],
                [105.4118872, 9.7772546],
                [105.4120696, 9.77703],
                [105.4122466, 9.7767524],
                [105.4130915, 9.7752484],
                [105.4133758, 9.7748281],
                [105.4136869, 9.7744448],
                [105.4139793, 9.7741303],
                [105.4142985, 9.7738263],
                [105.4146445, 9.7735646],
                [105.4149771, 9.7734166],
                [105.4152775, 9.7733638],
                [105.4156154, 9.7733532],
                [105.4159695, 9.7734166],
                [105.4162699, 9.7735276],
                [105.4165488, 9.7736915],
                [105.4167795, 9.7739241],
                [105.4169083, 9.774199],
                [105.4169726, 9.7744634],
                [105.4169565, 9.7747647],
                [105.4168565, 9.775092],
                [105.4166977, 9.7754453],
                [105.4164912, 9.775781],
                [105.416294, 9.7760546],
                [105.4160902, 9.7762872],
                [105.4158354, 9.7764881],
                [105.415478, 9.7767293],
                [105.4148054, 9.7771212],
                [105.4144299, 9.7773736],
                [105.4141375, 9.7776749],
                [105.4139471, 9.7779802],
                [105.4138341, 9.7782204],
                [105.413756, 9.7785075],
                [105.4137366, 9.7787415],
                [105.4137513, 9.7789542],
                [105.4138063, 9.7791842],
                [105.4138921, 9.779352],
                [105.4140075, 9.779471],
                [105.414167, 9.7795708],
                [105.4143508, 9.7796362],
                [105.4145251, 9.7796613],
                [105.4147129, 9.779656],
                [105.4149583, 9.7796032],
                [105.4152279, 9.7794829],
                [105.4154652, 9.7793243],
                [105.4157147, 9.7791076],
                [105.4167339, 9.7779868],
                [105.4169753, 9.7777304],
                [105.4172167, 9.7775533],
                [105.4174729, 9.7774185],
                [105.4177263, 9.7772996],
                [105.4180093, 9.7772137],
                [105.4183272, 9.7771634],
                [105.4186034, 9.7771621],
                [105.4188341, 9.7771912],
                [105.4189897, 9.7772414],
                [105.4191479, 9.7773577],
                [105.4192579, 9.7774833],
                [105.4193303, 9.777622],
                [105.4193652, 9.7777833],
                [105.4193772, 9.777971],
                [105.4193564, 9.7781514],
                [105.4192995, 9.7783575],
                [105.4192029, 9.7785472],
                [105.4190661, 9.778752],
                [105.4189213, 9.7789476],
                [105.4187184, 9.7791479],
                [105.418472, 9.7793084],
                [105.4179637, 9.7795873],
                [105.4177169, 9.7797446],
                [105.4175305, 9.7798899],
                [105.4173669, 9.7800737],
                [105.4172516, 9.7802811],
                [105.4171577, 9.7805164],
                [105.4170987, 9.780749],
                [105.4170652, 9.7810001],
                [105.4170316, 9.7813398],
                [105.4170021, 9.7816635],
                [105.4170008, 9.7818856],
                [105.4170223, 9.7820429],
                [105.4170779, 9.7822285],
                [105.4171865, 9.7824697],
                [105.4172878, 9.7826581],
                [105.4173998, 9.7828094],
                [105.4175547, 9.7829455],
                [105.4177428, 9.7830642],
                [105.4179248, 9.7831252],
                [105.4180777, 9.7831464],
                [105.4182571, 9.7831342],
                [105.4183848, 9.7830988],
                [105.4185015, 9.7830367],
                [105.4186222, 9.7829429],
                [105.4187576, 9.7827935],
                [105.4188864, 9.7826006],
                [105.4190017, 9.7823574],
                [105.4190768, 9.7820878],
                [105.4191104, 9.7818301],
                [105.4191291, 9.7814362],
                [105.4191432, 9.7810635],
                [105.419172, 9.780819],
                [105.4192404, 9.780527],
                [105.4193665, 9.7802058],
                [105.4195556, 9.7798424],
                [105.4197836, 9.7794954],
                [105.420139, 9.7789899],
                [105.4203475, 9.7787395],
                [105.4205316, 9.7785924],
                [105.4207733, 9.7784494],
                [105.4210201, 9.7783463],
                [105.4212481, 9.7783066],
                [105.4214425, 9.7783159],
                [105.4216196, 9.7783757],
                [105.4217724, 9.7784996],
                [105.4218864, 9.7786635],
                [105.4219562, 9.7788551],
                [105.4220092, 9.7790983],
                [105.4220309, 9.7793481],
                [105.4220279, 9.7797003],
                [105.4219984, 9.7801047],
                [105.4219347, 9.7805706],
                [105.4217698, 9.7814296],
                [105.421696, 9.7818591],
                [105.4216665, 9.7820984],
                [105.4216491, 9.7823389],
                [105.4216504, 9.7825649],
                [105.4216652, 9.782775],
                [105.4216987, 9.7829931],
                [105.4218274, 9.7835957],
                [105.421928, 9.7841138],
                [105.4220045, 9.7844772],
                [105.4220727, 9.7847424],
                [105.4221694, 9.7849973],
                [105.4222625, 9.7851788],
                [105.4223471, 9.7853112],
                [105.4224671, 9.7854235],
                [105.422612, 9.7854949],
                [105.4227769, 9.7855239],
                [105.4229721, 9.785516],
                [105.4231987, 9.7854308],
                [105.4233623, 9.7853131],
                [105.4235062, 9.785177],
                [105.4236849, 9.7849385],
                [105.4238659, 9.7846715],
                [105.4240265, 9.7843751],
                [105.4241958, 9.7840239],
                [105.4243507, 9.7835984],
                [105.4244547, 9.7833671],
                [105.424633, 9.7831266],
                [105.4248101, 9.78296],
                [105.4249884, 9.7828563],
                [105.4251426, 9.7828094],
                [105.4253331, 9.7827896],
                [105.4255061, 9.7828015],
                [105.425665, 9.7828299],
                [105.4260016, 9.782931],
                [105.4263731, 9.7830658],
                [105.4267037, 9.7832098],
                [105.4269853, 9.7833565],
                [105.4271945, 9.7835045],
                [105.4273799, 9.7836526],
                [105.4275191, 9.7837821],
                [105.4276237, 9.7839512],
                [105.4277001, 9.7841125],
                [105.4277752, 9.7843134],
                [105.4278128, 9.7844944],
                [105.4278181, 9.7846728],
                [105.4277994, 9.7848922],
                [105.4277578, 9.7850812],
                [105.4276855, 9.7852731],
                [105.4275788, 9.7854466],
                [105.4274628, 9.7855887],
                [105.4273072, 9.7857354],
                [105.4271141, 9.7858662],
                [105.4269209, 9.7859634],
                [105.4267493, 9.7860156],
                [105.4265656, 9.786046],
                [105.4260814, 9.7860618],
                [105.42584, 9.7860883],
                [105.4256335, 9.7861398],
                [105.4254511, 9.7862224],
                [105.4253438, 9.7863017],
                [105.4252647, 9.7864167],
                [105.4252271, 9.7865214],
                [105.4252198, 9.7866334],
                [105.4252446, 9.7867411],
                [105.4253009, 9.7868581],
                [105.4254015, 9.7869724],
                [105.4255128, 9.787057],
                [105.4258615, 9.7872724],
                [105.4263597, 9.787542],
                [105.4268485, 9.7878222],
                [105.4272053, 9.7880654],
                [105.4273501, 9.7881869],
                [105.4274748, 9.7883297],
                [105.4275553, 9.7884407],
                [105.4278584, 9.7889019],
                [105.427957, 9.7890387],
                [105.4280703, 9.7891715],
                [105.4282165, 9.7893004],
                [105.4283532, 9.7893816],
                [105.4285477, 9.7894411],
                [105.4287542, 9.7894662],
                [105.4289567, 9.7894596],
                [105.4291646, 9.7894107],
                [105.4293993, 9.7892957],
                [105.4296695, 9.78912],
                [105.4300125, 9.7888745],
                [105.430498, 9.7884334],
                [105.4306395, 9.7883402],
                [105.4307418, 9.7883145],
                [105.4308517, 9.7883231],
                [105.4309644, 9.7883667],
                [105.4310569, 9.7884314],
                [105.4311206, 9.7885147],
                [105.4311602, 9.7886151],
                [105.4312165, 9.7888074],
                [105.4312502, 9.7889459],
                [105.4312849, 9.7891345],
                [105.4313077, 9.789303],
                [105.4313084, 9.7894887],
                [105.4313023, 9.7896027],
                [105.4312903, 9.7896988],
                [105.4312691, 9.7897979],
                [105.4311719, 9.7901065],
                [105.4311381, 9.7902309],
                [105.4311055, 9.7903892],
                [105.4310945, 9.7905242],
                [105.4311005, 9.7906325],
                [105.431123, 9.7907376],
                [105.4311575, 9.7908275],
                [105.4312115, 9.790918],
                [105.4312826, 9.7909771],
                [105.4313801, 9.7910171],
                [105.4314867, 9.7910316],
                [105.4315994, 9.7910217],
                [105.4317057, 9.79099],
                [105.4318026, 9.7909378],
                [105.4319957, 9.7908037],
                [105.4324165, 9.7904521],
                [105.4325938, 9.7903305],
                [105.4327139, 9.7902598],
                [105.432849, 9.7902063],
                [105.4329536, 9.7901799],
                [105.4330518, 9.7901686],
                [105.4331544, 9.7901772],
                [105.433261, 9.7902037],
                [105.4333945, 9.790246],
                [105.4335393, 9.790316],
                [105.4336801, 9.7904079],
                [105.4338038, 9.7905199],
                [105.4339088, 9.7906352],
                [105.4339899, 9.7907594],
                [105.4342977, 9.7913237],
                [105.4346256, 9.7918907],
                [105.4346913, 9.7920354],
                [105.4347325, 9.7921434],
                [105.4347589, 9.7922405],
                [105.4347765, 9.7923314],
                [105.4348147, 9.7925561],
                [105.4348496, 9.7927635],
                [105.4348952, 9.7929221],
                [105.4349501, 9.7930516],
                [105.4350021, 9.7931473],
                [105.4350682, 9.7932221],
                [105.4351446, 9.7932757],
                [105.4352432, 9.793312],
                [105.4353417, 9.7933246],
                [105.4354557, 9.7933193],
                [105.4355845, 9.7932591],
                [105.4356891, 9.793164],
                [105.4357937, 9.793045],
                [105.4359627, 9.7928124],
                [105.4362336, 9.7923552],
                [105.4363181, 9.7922177],
                [105.4364455, 9.7920664],
                [105.4366037, 9.7921279],
                [105.4367378, 9.7922151],
                [105.4370597, 9.7925164],
                [105.4372716, 9.7926459],
                [105.4373118, 9.7927094],
                [105.4373279, 9.7927992],
                [105.4373199, 9.7929314],
                [105.4372796, 9.7930741],
                [105.437222, 9.7932182],
                [105.4371428, 9.7933252],
                [105.4370517, 9.7934111],
                [105.4367942, 9.7935737],
                [105.4366708, 9.7936794],
                [105.4365528, 9.7938274],
                [105.4362041, 9.7943613],
                [105.4361182, 9.7945093],
                [105.4359868, 9.794882],
                [105.4359332, 9.7949824],
                [105.4358581, 9.7950538],
                [105.4357615, 9.7951067],
                [105.4356649, 9.7951225],
                [105.4354691, 9.7951172],
                [105.4353914, 9.7951278],
                [105.4353216, 9.7951569],
                [105.4352546, 9.795223],
                [105.4351473, 9.7953551],
                [105.4350507, 9.7954397],
                [105.4349381, 9.7954979],
                [105.4347436, 9.7955771],
                [105.4342836, 9.7958071],
                [105.43412, 9.795889],
                [105.4340127, 9.7959736],
                [105.4339537, 9.7960608],
                [105.4339403, 9.7961613],
                [105.4339671, 9.7964626],
                [105.4341495, 9.7964599],
                [105.4342139, 9.796711],
                [105.4342246, 9.796904],
                [105.4341924, 9.7970996],
                [105.4341388, 9.7973507],
                [105.434069, 9.7975436],
                [105.4339457, 9.7977762],
                [105.4339403, 9.797837],
                [105.4339778, 9.7978925],
                [105.4341495, 9.7980141],
                [105.4342058, 9.7980643],
                [105.4342246, 9.7981277],
                [105.4342219, 9.7982176],
                [105.4341763, 9.7985242],
                [105.4338179, 9.8004162],
                [105.4336229, 9.8016531],
                [105.4325712, 9.8072343],
                [105.4322929, 9.8086407],
                [105.4320787, 9.809742],
                [105.4318876, 9.8108233],
                [105.4317033, 9.8119059],
                [105.4315251, 9.8129953],
                [105.4313538, 9.8140859],
                [105.4312915, 9.8144486],
                [105.4312367, 9.8148125],
                [105.4311869, 9.815151],
                [105.4311435, 9.8153509],
                [105.4310894, 9.815522],
                [105.4310274, 9.8156906],
                [105.4309557, 9.8158603],
                [105.4308761, 9.8160265],
                [105.4307885, 9.8161889],
                [105.430838, 9.8162223],
                [105.4308901, 9.8162519],
                [105.4309443, 9.8162773],
                [105.4310166, 9.8163037],
                [105.4311235, 9.8163335],
                [105.4312322, 9.816373],
                [105.4313706, 9.8164293],
                [105.4315063, 9.8164917],
                [105.431639, 9.8165601],
                [105.4317702, 9.8166354],
                [105.4318977, 9.8167166],
                [105.4320214, 9.8168035],
                [105.4321408, 9.8168959],
                [105.4322559, 9.8169935],
                [105.4323205, 9.8170378],
                [105.4323877, 9.8170784],
                [105.432457, 9.8171151],
                [105.4325415, 9.8171534],
                [105.4326283, 9.8171861],
                [105.4327172, 9.8172129],
                [105.4328069, 9.8172244],
                [105.4328973, 9.8172278],
                [105.4329877, 9.817223],
                [105.4330772, 9.8172101],
                [105.4331651, 9.8171891],
                [105.4332462, 9.817162],
                [105.4333246, 9.8171281],
                [105.4333998, 9.8170877],
                [105.4334711, 9.8170409],
                [105.4335473, 9.8169883],
                [105.4336178, 9.8169424],
                [105.4337005, 9.8169019],
                [105.4337858, 9.8168669],
                [105.4338732, 9.8168376],
                [105.433983, 9.8168096],
                [105.4340948, 9.8167904],
                [105.4342077, 9.8167804],
                [105.4343212, 9.8167795],
                [105.4344799, 9.8168039],
                [105.4346371, 9.8168371],
                [105.4347921, 9.816879],
                [105.4349444, 9.8169294],
                [105.4350936, 9.8169883],
                [105.4352233, 9.8170474],
                [105.4353498, 9.817113],
                [105.4354728, 9.8171847],
                [105.435592, 9.8172624],
                [105.4357071, 9.8173458],
                [105.4358393, 9.8174428],
                [105.4359645, 9.8175288],
                [105.4361156, 9.8176158],
                [105.4362706, 9.8176955],
                [105.4364294, 9.8177679],
                [105.4365788, 9.817828],
                [105.4367307, 9.8178816],
                [105.4368848, 9.8179285],
                [105.4370275, 9.8179622],
                [105.4371954, 9.8179933],
                [105.4373508, 9.8180111],
                [105.4375068, 9.8180224],
                [105.4376632, 9.8180269],
                [105.4378099, 9.8180251],
                [105.4379564, 9.8180175],
                [105.4381025, 9.818004],
                [105.4382291, 9.8179846],
                [105.4383146, 9.8179573],
                [105.4383839, 9.8179374],
                [105.4384551, 9.8179251],
                [105.4385271, 9.8179206],
                [105.4385993, 9.8179239],
                [105.4386736, 9.8179355],
                [105.438746, 9.8179555],
                [105.4388156, 9.8179835],
                [105.4388816, 9.8180192],
                [105.4389429, 9.818062],
                [105.4390445, 9.8181194],
                [105.4392014, 9.8181887],
                [105.4393798, 9.8182595],
                [105.4395232, 9.8183112],
                [105.4396681, 9.8183588],
                [105.4398143, 9.8184022],
                [105.440217, 9.8185017],
                [105.4406176, 9.8186092],
                [105.441016, 9.8187247],
                [105.4414048, 9.8188457],
                [105.441791, 9.8189744],
                [105.4420674, 9.8190603],
                [105.4423785, 9.8191528],
                [105.4427892, 9.8192767],
                [105.4431993, 9.8193907],
                [105.4436568, 9.8195088],
                [105.4440629, 9.8196074],
                [105.4443939, 9.819663],
                [105.4446693, 9.8197176],
                [105.4449427, 9.8197818],
                [105.4451711, 9.8198433],
                [105.4453976, 9.8199113],
                [105.4456219, 9.819986],
                [105.4458761, 9.8200567],
                [105.4475658, 9.820518],
                [105.4492894, 9.8209622],
                [105.450573, 9.8212883],
                [105.454695, 9.8223322],
                [105.4548501, 9.8223688],
                [105.4550032, 9.8224129],
                [105.4551539, 9.8224644],
                [105.4553159, 9.822529],
                [105.4554741, 9.8226021],
                [105.4556281, 9.8226835],
                [105.4557774, 9.8227728],
                [105.4572625, 9.8236919],
                [105.4605532, 9.8257283],
                [105.4605991, 9.8256613],
                [105.4606525, 9.8255998],
                [105.4607127, 9.8255447],
                [105.4607787, 9.8254967],
                [105.4608499, 9.8254563],
                [105.4609252, 9.8254242],
                [105.4610038, 9.8254006],
                [105.4610792, 9.8253867],
                [105.4611558, 9.8253807],
                [105.4612325, 9.8253827],
                [105.4613086, 9.8253927],
                [105.4613832, 9.8254106],
                [105.4614554, 9.8254362],
                [105.4615245, 9.8254692],
                [105.4615889, 9.8255074],
                [105.46165, 9.8255508],
                [105.4617072, 9.825599],
                [105.4617602, 9.8256517],
                [105.4618129, 9.8257141],
                [105.4618598, 9.825781],
                [105.4619003, 9.8258517],
                [105.4619342, 9.8259258],
                [105.4619612, 9.8260026],
                [105.461981, 9.8260815],
                [105.4620002, 9.8261776],
                [105.4620185, 9.8262455],
                [105.4620503, 9.826327],
                [105.4620887, 9.8264058],
                [105.4621333, 9.8264812],
                [105.4621839, 9.8265529],
                [105.462245, 9.8266255],
                [105.4623122, 9.8266926],
                [105.4623757, 9.826759],
                [105.4624325, 9.8268238],
                [105.4624746, 9.826897],
                [105.4625067, 9.8269603],
                [105.4652041, 9.8285879],
                [105.4652967, 9.8286545],
                [105.4653838, 9.828728],
                [105.4654391, 9.8287811],
                [105.4654916, 9.8288369],
                [105.4655501, 9.8288971],
                [105.4656037, 9.8289454],
                [105.4656601, 9.8289903],
                [105.4657191, 9.8290319],
                [105.4660852, 9.8292475],
                [105.4664768, 9.8295023],
                [105.4671016, 9.8299118],
                [105.4675604, 9.8302106],
                [105.4676345, 9.8302556],
                [105.4676958, 9.8302833],
                [105.4677549, 9.8303054],
                [105.4678152, 9.8303238],
                [105.4679359, 9.8303586],
                [105.4680847, 9.830395],
                [105.4682189, 9.8304233],
                [105.4683288, 9.8304472],
                [105.4684237, 9.8304649],
                [105.4685193, 9.8304775],
                [105.4686148, 9.8304851],
                [105.4687106, 9.8304878],
                [105.4688063, 9.8304855],
                [105.4693803, 9.8309585],
                [105.4694738, 9.831037],
                [105.4695723, 9.8311093],
                [105.4696754, 9.8311752],
                [105.4697869, 9.8312366],
                [105.4699024, 9.8312904],
                [105.470075, 9.8313523],
                [105.4704247, 9.8314665],
                [105.4708301, 9.8315901],
                [105.4712375, 9.831707],
                [105.4716468, 9.8318174],
                [105.4720855, 9.8319279],
                [105.4725261, 9.8320308],
                [105.4729684, 9.8321261],
                [105.4734124, 9.8322137],
                [105.4738579, 9.8322937],
                [105.4743047, 9.832366],
                [105.4747152, 9.8324279],
                [105.4750237, 9.8324676],
                [105.4764854, 9.8326469],
                [105.4765338, 9.8326473],
                [105.4765845, 9.8326429],
                [105.4766345, 9.8326336],
                [105.4770648, 9.8328745],
                [105.4775369, 9.8331653],
                [105.478467, 9.8337319],
                [105.4803021, 9.8348262],
                [105.4821396, 9.8359164],
                [105.4839914, 9.8370094],
                [105.4858457, 9.8380982],
                [105.4876931, 9.8391829],
                [105.4881939, 9.8394941],
                [105.4886888, 9.8397997],
                [105.4891873, 9.8400996],
                [105.4896893, 9.8403939],
                [105.4902211, 9.8406973],
                [105.4907567, 9.8409944],
                [105.4912958, 9.8412851],
                [105.4914978, 9.8413896],
                [105.4917754, 9.8415376],
                [105.4919947, 9.8416828],
                [105.4921387, 9.8417911],
                [105.4922944, 9.8418943],
                [105.4924762, 9.8420054],
                [105.4926623, 9.8421094],
                [105.4928765, 9.8422352],
                [105.4938723, 9.8428568],
                [105.4941204, 9.8430062],
                [105.4943705, 9.8431523],
                [105.4946403, 9.8433048],
                [105.4948935, 9.843443],
                [105.4951705, 9.8435976],
                [105.4954272, 9.8437442],
                [105.4957138, 9.8439118],
                [105.4959986, 9.8440825],
                [105.4988176, 9.8457131],
                [105.4991764, 9.845929],
                [105.4995391, 9.8461385],
                [105.4998688, 9.8463216],
                [105.5002096, 9.8465098],
                [105.5005449, 9.8467133],
                [105.5008721, 9.8469234],
                [105.5012407, 9.8471532],
                [105.5016124, 9.847378],
                [105.5025431, 9.8479294],
                [105.5034712, 9.8484853],
                [105.5043966, 9.8490455],
                [105.505304, 9.8496007],
                [105.5062088, 9.8501601],
                [105.507111, 9.8507236],
                [105.509828, 9.8523726],
                [105.5208982, 9.859146],
                [105.5192285, 9.861997],
                [105.5191951, 9.862052],
                [105.5191561, 9.8621033],
                [105.5191118, 9.8621503],
                [105.5190629, 9.8621926],
                [105.5190067, 9.8622314],
                [105.5189466, 9.862264],
                [105.5188833, 9.8622899],
                [105.5165189, 9.8631676],
                [105.5249852, 9.8815287],
                [105.5260899, 9.8839632],
                [105.5273452, 9.8867699],
                [105.5277408, 9.8876092],
                [105.5281314, 9.8884508],
                [105.5285235, 9.889309],
                [105.5289102, 9.8901695],
                [105.5298722, 9.8922848],
                [105.5306549, 9.8940185],
                [105.5314332, 9.8957542],
                [105.5318949, 9.8967273],
                [105.5323103, 9.8976513],
                [105.5327145, 9.8985733],
                [105.5330994, 9.8994671],
                [105.5332863, 9.8998639],
                [105.53341, 9.9000954],
                [105.5335302, 9.9003094],
                [105.5337211, 9.9006794],
                [105.5339093, 9.9010683],
                [105.5340908, 9.9014603],
                [105.5348021, 9.9031235],
                [105.5352324, 9.9040897],
                [105.5356872, 9.9051157],
                [105.5361459, 9.9061137],
                [105.5366132, 9.9071079],
                [105.536778, 9.9074928],
                [105.5369487, 9.9078753],
                [105.5371251, 9.9082552],
                [105.5373073, 9.9086325],
                [105.5375105, 9.9090371],
                [105.5377203, 9.9094384],
                [105.5379148, 9.9098341],
                [105.5381066, 9.910231],
                [105.5384253, 9.9109064],
                [105.5387396, 9.9115838],
                [105.5390706, 9.9123102],
                [105.5393966, 9.9130388],
                [105.5383348, 9.9139906],
                [105.5372751, 9.9149447],
                [105.5358213, 9.9162604],
                [105.5349561, 9.9170806],
                [105.5340886, 9.9178986],
                [105.5332175, 9.9187156],
                [105.5323442, 9.9195304],
                [105.5292617, 9.9223897],
                [105.5261841, 9.9252542],
                [105.52312, 9.9281156],
                [105.5200607, 9.9309821],
                [105.5178063, 9.9330998],
                [105.5172508, 9.9336306],
                [105.5166918, 9.9341578],
                [105.5161222, 9.934688],
                [105.5155492, 9.9352146],
                [105.5144173, 9.9362793],
                [105.5132546, 9.9373587],
                [105.5109572, 9.9394999],
                [105.5099111, 9.9404506],
                [105.5088686, 9.9414053],
                [105.5078298, 9.9423637],
                [105.506575, 9.943531],
                [105.5053256, 9.944704],
                [105.5050466, 9.9449664],
                [105.5047613, 9.9452223],
                [105.5044704, 9.945471],
                [105.5041735, 9.9457128],
                [105.5038721, 9.9459304],
                [105.5038024, 9.9457916],
                [105.5036854, 9.9458588],
                [105.503578, 9.9459293],
                [105.5034739, 9.9460043],
                [105.5033485, 9.9461041],
                [105.5032287, 9.9462104],
                [105.5020426, 9.9473439],
                [105.5008825, 9.9484554],
                [105.4996251, 9.9496028],
                [105.4984045, 9.950728],
                [105.4971923, 9.9518186],
                [105.4958825, 9.9529975],
                [105.4946253, 9.9541273],
                [105.4933885, 9.9552606],
                [105.4921952, 9.9563807],
                [105.4909954, 9.957494],
                [105.4897892, 9.9586006],
                [105.4885619, 9.9597137],
                [105.4873281, 9.9608197],
                [105.4860878, 9.9619187],
                [105.4851544, 9.9627813],
                [105.4844838, 9.9634035],
                [105.484411, 9.9634739],
                [105.484343, 9.9635488],
                [105.48428, 9.9636279],
                [105.484241, 9.9636827],
                [105.4842045, 9.9637391],
                [105.4841244, 9.9638472],
                [105.4840393, 9.9639529],
                [105.4839498, 9.9640551],
                [105.4838562, 9.9641536],
                [105.4833572, 9.9645804],
                [105.4825151, 9.9653583],
                [105.4816702, 9.9661715],
                [105.4807184, 9.9670376],
                [105.4798689, 9.9678297],
                [105.4789339, 9.968643],
                [105.4784987, 9.9690485],
                [105.4782907, 9.9692489],
                [105.478076, 9.9694424],
                [105.4779135, 9.9695804],
                [105.4777476, 9.9697145],
                [105.4775541, 9.9698655],
                [105.4772553, 9.9701134],
                [105.4769782, 9.9703537],
                [105.4767057, 9.9705991],
                [105.4764514, 9.9708416],
                [105.4761884, 9.9711022],
                [105.4758609, 9.9713913],
                [105.4755413, 9.971672],
                [105.4752061, 9.9719552],
                [105.4748654, 9.972232],
                [105.4745513, 9.9725115],
                [105.4742432, 9.9727973],
                [105.4739561, 9.9730746],
                [105.4736746, 9.9733574],
                [105.4726499, 9.9742714],
                [105.4717163, 9.9751142],
                [105.4705788, 9.9761435],
                [105.4694391, 9.9771703],
                [105.468291, 9.9781999],
                [105.4671407, 9.9792271],
                [105.4660794, 9.9802098],
                [105.465014, 9.9811882],
                [105.4639378, 9.9821684],
                [105.4628576, 9.9831443],
                [105.4607238, 9.985111],
                [105.4585952, 9.9870832],
                [105.4456271, 9.9991106],
                [105.4448557, 9.9998273],
                [105.4444957, 10.0001516],
                [105.4441789, 10.0005368],
                [105.4386556, 10.0056265],
                [105.4373695, 10.0068375],
                [105.4363002, 10.0078473],
                [105.4352331, 10.0088595],
                [105.4342081, 10.0098361],
                [105.4331851, 10.0108148],
                [105.4326726, 10.0113229],
                [105.4321554, 10.0118263],
                [105.4316336, 10.012325],
                [105.4310789, 10.0128454],
                [105.4305192, 10.0133604],
                [105.4299116, 10.013923],
                [105.4267001, 10.0169569],
                [105.425968, 10.0176345],
                [105.4252392, 10.0183156],
                [105.4245241, 10.0189901],
                [105.4204318, 10.0228816],
                [105.4195583, 10.0237091],
                [105.4187413, 10.0244915],
                [105.4179284, 10.025278],
                [105.4052434, 10.0372306],
                [105.3996347, 10.0426438],
                [105.3991404, 10.0417562],
                [105.384078, 10.0555823],
                [105.3802761, 10.0590472],
                [105.3764793, 10.0625175],
                [105.3726991, 10.0659827],
                [105.3689239, 10.0694533],
                [105.3688478, 10.0695066],
                [105.368768, 10.0695543],
                [105.3686809, 10.0695979],
                [105.3685907, 10.069635],
                [105.3684806, 10.069676],
                [105.3683737, 10.0697246],
                [105.3682862, 10.0697716],
                [105.3682017, 10.0698236],
                [105.3681206, 10.0698807],
                [105.3680432, 10.0699424],
                [105.3675246, 10.0703875],
                [105.3670113, 10.0708384],
                [105.3665033, 10.0712952],
                [105.3659731, 10.0717835],
                [105.365449, 10.0722781],
                [105.3649311, 10.072779],
                [105.364819, 10.0728811],
                [105.3647118, 10.0729882],
                [105.3646097, 10.0731],
                [105.3645131, 10.0732161],
                [105.3644221, 10.0733365],
                [105.3643368, 10.073461],
                [105.3645404, 10.0735401],
                [105.363228, 10.0750087],
                [105.3621612, 10.0762228],
                [105.3610995, 10.0774412],
                [105.360046, 10.0786605],
                [105.3589975, 10.0798841],
                [105.3579542, 10.0811119],
                [105.357877, 10.0812039],
                [105.3577962, 10.0812929],
                [105.3577119, 10.0813787],
                [105.3575939, 10.0814882],
                [105.3574836, 10.0815705],
                [105.3574132, 10.081625],
                [105.3573595, 10.0816632],
                [105.3573096, 10.0817061],
                [105.3572554, 10.0817628],
                [105.3571996, 10.0818487],
                [105.3571501, 10.0819384],
                [105.3571111, 10.0820124],
                [105.3570689, 10.0820889],
                [105.3570226, 10.082163],
                [105.356959, 10.0822555],
                [105.3568992, 10.0823505],
                [105.3568508, 10.0824342],
                [105.3568053, 10.0825195],
                [105.3567514, 10.0826369],
                [105.3567031, 10.0827567],
                [105.3566605, 10.0828786],
                [105.3566287, 10.0829841],
                [105.3566013, 10.0830907],
                [105.3565782, 10.0831983],
                [105.3530816, 10.1007369],
                [105.3529799, 10.100798],
                [105.3528818, 10.1008643],
                [105.3527874, 10.1009358],
                [105.3527117, 10.1009992],
                [105.352639, 10.1010659],
                [105.3525694, 10.1011358],
                [105.3508991, 10.103043],
                [105.3492147, 10.1049812],
                [105.3475463, 10.106893],
                [105.3458565, 10.1088259],
                [105.344746, 10.1101089],
                [105.34364, 10.1113957],
                [105.3425387, 10.1126864],
                [105.3414461, 10.113976],
                [105.3403581, 10.1152693],
                [105.3392355, 10.1165634],
                [105.338636, 10.1172545],
                [105.3378904, 10.11811],
                [105.3364474, 10.1197155],
                [105.3356963, 10.120534],
                [105.3350097, 10.1212681],
                [105.334323, 10.1220127],
                [105.3336982, 10.1227192],
                [105.3330249, 10.1234966],
                [105.3324455, 10.1241937],
                [105.3318484, 10.1249124],
                [105.331201, 10.1256195],
                [105.3305811, 10.1262957],
                [105.3299542, 10.1269657],
                [105.3293204, 10.1276292],
                [105.3286797, 10.1282863],
                [105.3281457, 10.1288194],
                [105.3276174, 10.129358],
                [105.3270948, 10.129902],
                [105.3265781, 10.1304513],
                [105.3260672, 10.131006],
                [105.3255698, 10.1315574],
                [105.3250781, 10.1321139],
                [105.3245923, 10.1326753],
                [105.3241124, 10.1332416],
                [105.3236385, 10.1338128],
                [105.3175324, 10.14077],
                [105.317434, 10.140885],
                [105.3173392, 10.141003],
                [105.3172481, 10.1411238],
                [105.3171422, 10.1412747],
                [105.3170421, 10.1414295],
                [105.3106482, 10.1346547],
                [105.309505, 10.1360016],
                [105.3065831, 10.1392634],
                [105.3069042, 10.1395858],
                [105.3033502, 10.1436629],
                [105.2981796, 10.1386371],
                [105.296886, 10.1399572],
                [105.2955946, 10.1412795],
                [105.2943208, 10.1425881],
                [105.2930492, 10.1438987],
                [105.2904797, 10.1465416],
                [105.2879477, 10.1491555],
                [105.2861776, 10.1508789],
                [105.2844038, 10.1525986],
                [105.2826261, 10.1543145],
                [105.2808391, 10.1560318],
                [105.2790483, 10.1577453],
                [105.2772537, 10.1594549],
                [105.2769043, 10.1598118],
                [105.2765483, 10.1601624],
                [105.2761774, 10.1605144],
                [105.2757838, 10.1608726],
                [105.2754019, 10.1611851],
                [105.2749979, 10.1615036],
                [105.2745772, 10.161823],
                [105.274137, 10.1621584],
                [105.2736338, 10.1625493],
                [105.2731134, 10.1629594],
                [105.2725893, 10.1633649],
                [105.2720857, 10.1637476],
                [105.2715787, 10.164126],
                [105.2710685, 10.1645002],
                [105.2701914, 10.1651706],
                [105.2693371, 10.1658406],
                [105.2688825, 10.1661846],
                [105.2684546, 10.1665316],
                [105.2676406, 10.1671957],
                [105.2668198, 10.1678633],
                [105.2659841, 10.1685381],
                [105.2651711, 10.1692097],
                [105.2643808, 10.1698937],
                [105.2631372, 10.1708865],
                [105.261973, 10.1718649],
                [105.2607527, 10.1728639],
                [105.259583, 10.1738077],
                [105.2589583, 10.1743107],
                [105.2583361, 10.1748255],
                [105.257195, 10.1757854],
                [105.2548197, 10.1777348],
                [105.252382, 10.1797159],
                [105.2499971, 10.1816712],
                [105.2488545, 10.1826055],
                [105.2476358, 10.1835452],
                [105.2463788, 10.1845274],
                [105.2451503, 10.1854672],
                [105.2439522, 10.1864288],
                [105.242749, 10.1873842],
                [105.2415703, 10.1883177],
                [105.2403277, 10.1892767],
                [105.2390635, 10.1902489],
                [105.2377941, 10.1912145],
                [105.2365194, 10.1921734],
                [105.2352556, 10.1931124],
                [105.2340241, 10.194104],
                [105.2328148, 10.1950897],
                [105.2316276, 10.1960695],
                [105.2304465, 10.1970564],
                [105.2298877, 10.1975434],
                [105.229325, 10.1980261],
                [105.2287584, 10.1985043],
                [105.228188, 10.1989782],
                [105.2275672, 10.1994854],
                [105.2269419, 10.1999873],
                [105.2263123, 10.200484],
                [105.2256784, 10.2009753],
                [105.2311146, 10.2085429],
                [105.2308083, 10.2087979],
                [105.2305055, 10.2090569],
                [105.2302347, 10.2092945],
                [105.2299668, 10.2095353],
                [105.2298875, 10.2096105],
                [105.2298122, 10.2096895],
                [105.229741, 10.2097722],
                [105.2296771, 10.2098546],
                [105.2296173, 10.2099399],
                [105.2281597, 10.2120354],
                [105.2280386, 10.2122045],
                [105.2279121, 10.2123696],
                [105.2277803, 10.2125308],
                [105.2276533, 10.2126767],
                [105.2275218, 10.2128188],
                [105.2273862, 10.2129571],
                [105.2168432, 10.1985253],
                [105.2137686, 10.1942762],
                [105.2058959, 10.1834603],
                [105.2021038, 10.1859497],
                [105.1983148, 10.1884438],
                [105.194529, 10.1909424],
                [105.1907358, 10.1934527],
                [105.1869459, 10.1959676],
                [105.1831591, 10.1984872],
                [105.1605505, 10.2133591],
                [105.1651832, 10.218608],
                [105.1645523, 10.2192355],
                [105.1609728, 10.2223573],
                [105.1574436, 10.2255124],
                [105.1537796, 10.2286008],
                [105.1529205, 10.2295355],
                [105.1501155, 10.2318226],
                [105.1488014, 10.2330245],
                [105.1463187, 10.2351976],
                [105.1433072, 10.2379185],
                [105.1415653, 10.2384255],
                [105.1413333, 10.2385651],
                [105.1355426, 10.2447608],
                [105.1267093, 10.2526063],
                [105.1260932, 10.252958],
                [105.1256233, 10.2532762],
                [105.1253835, 10.2534524],
                [105.123304, 10.2546637],
                [105.1209419, 10.2560069],
                [105.1183027, 10.2575233],
                [105.1174025, 10.2580609],
                [105.1143169, 10.2597466],
                [105.1113084, 10.2615236],
                [105.1099586, 10.2622594],
                [105.1085138, 10.2631256],
                [105.1078732, 10.2634737],
                [105.1077388, 10.2635468],
                [105.1066249, 10.264151],
                [105.0974885, 10.2696418],
                [105.0953255, 10.2708118],
                [105.091385, 10.2729156],
                [105.0889331, 10.2743838],
                [105.0877207, 10.2751122],
                [105.0865545, 10.2757856],
                [105.0845396, 10.2769702],
                [105.0822227, 10.2783196],
                [105.0802836, 10.2794439],
                [105.0781604, 10.2806796],
                [105.0761443, 10.2818421],
                [105.074873, 10.2825652],
                [105.0736821, 10.2832567],
                [105.0712755, 10.2846184],
                [105.0707441, 10.2848212],
                [105.0705889, 10.2849282],
                [105.0698689, 10.2853863],
                [105.0667719, 10.2871858],
                [105.0638033, 10.2889609],
                [105.0616695, 10.29023],
                [105.0587519, 10.2919461],
                [105.0576871, 10.2925616],
                [105.0566602, 10.293021],
                [105.0563843, 10.2931575],
                [105.0515346, 10.2960009],
                [105.0476043, 10.2981849],
                [105.0445015, 10.3003005],
                [105.0425678, 10.30143],
                [105.0408919, 10.3024089],
                [105.0374913, 10.3043952],
                [105.0292167, 10.3092178],
                [105.0274849, 10.3101872],
                [105.0187923, 10.3152175],
                [105.0172319, 10.3161375],
                [105.0191306, 10.3187981],
                [105.0143044, 10.3198831],
                [105.0085616, 10.3211889],
                [105.0054548, 10.3219096],
                [104.9997358, 10.3232173],
                [104.9968915, 10.3238642],
                [104.9886071, 10.3258013],
                [104.9832745, 10.3269851],
                [104.9796243, 10.327743],
                [104.9784732, 10.3280749],
                [104.9699521, 10.3300287],
                [104.9645245, 10.3312685],
                [104.9589829, 10.3325646],
                [104.9544812, 10.3336095],
                [104.9471247, 10.3353434],
                [104.944686, 10.3359108],
                [104.939643, 10.3370737],
                [104.9302247, 10.3392788],
                [104.9205675, 10.3414574],
                [104.9115204, 10.3435569],
                [104.9034901, 10.3452904],
                [104.8936646, 10.3475978],
                [104.8868989, 10.349172],
                [104.8779044, 10.3512705],
                [104.8683616, 10.3535129],
                [104.8682428, 10.353547],
                [104.8681265, 10.3535884],
                [104.868013, 10.3536369],
                [104.8679113, 10.3536878],
                [104.8678128, 10.3537445],
                [104.8677178, 10.3538068],
                [104.8676267, 10.3538744],
                [104.8675224, 10.3539448],
                [104.8674148, 10.3540102],
                [104.8673042, 10.3540706],
                [104.8669333, 10.3542852],
                [104.8665592, 10.3544944],
                [104.8661668, 10.3547063],
                [104.86579, 10.3549018],
                [104.8654938, 10.3550548],
                [104.86522, 10.3552041],
                [104.8649865, 10.3553384],
                [104.8647561, 10.3554778],
                [104.8645288, 10.3556219],
                [104.8607087, 10.3606161],
                [104.8541516, 10.3693084],
                [104.8489482, 10.3762148],
                [104.8437445, 10.3830424],
                [104.8385938, 10.3896597],
                [104.8330802, 10.3965693],
                [104.8305176, 10.3998915],
                [104.8256067, 10.4063574],
                [104.8278128, 10.4089263],
                [104.8238983, 10.4156021],
                [104.8190036, 10.4238036],
                [104.8139742, 10.4324905],
                [104.8101745, 10.4390047],
                [104.8080385, 10.4426665],
                [104.8044594, 10.4486789],
                [104.7976569, 10.4661384],
                [104.7911061, 10.473276],
                [104.7857286, 10.4792016],
                [104.7784534, 10.4872499],
                [104.7815325, 10.4989926],
                [104.7825219, 10.502098],
                [104.7829132, 10.5033884],
                [104.7823616, 10.5063325],
                [104.7815071, 10.507001],
                [104.7814136, 10.5071011],
                [104.7814334, 10.5091295],
                [104.7804583, 10.5091353],
                [104.7804563, 10.5093279],
                [104.7805848, 10.5096585],
                [104.7804281, 10.5096451],
                [104.7803495, 10.5096468],
                [104.7801969, 10.5096662],
                [104.7798945, 10.509746],
                [104.7798906, 10.509779],
                [104.7794634, 10.5098782],
                [104.7791429, 10.5099687],
                [104.7789956, 10.5117695],
                [104.7789194, 10.512879],
                [104.7787019, 10.5145067],
                [104.7784892, 10.5163552],
                [104.7784783, 10.516607],
                [104.7784833, 10.5195006],
                [104.7777711, 10.5194497],
                [104.7768414, 10.5194275],
                [104.775565, 10.5193928],
                [104.7745003, 10.5193647],
                [104.7732869, 10.5193286],
                [104.7721778, 10.5192972],
                [104.7704314, 10.5192208],
                [104.7698933, 10.5192094],
                [104.7692161, 10.5192056],
                [104.7682717, 10.5192253],
                [104.7671283, 10.5192589],
                [104.766465, 10.5192692],
                [104.7660125, 10.5192175],
                [104.7654903, 10.5191944],
                [104.7651672, 10.5192058],
                [104.7649086, 10.5192244],
                [104.7643414, 10.5192517],
                [104.7638006, 10.5192506],
                [104.7637847, 10.5193886],
                [104.7632475, 10.5193575],
                [104.7626322, 10.5193458],
                [104.762485, 10.5193533],
                [104.7623161, 10.5193586],
                [104.7619828, 10.5193467],
                [104.7613097, 10.5193064],
                [104.760045, 10.5192339],
                [104.7597794, 10.5192336],
                [104.7586506, 10.5191586],
                [104.7581936, 10.5191347],
                [104.7575356, 10.5190911],
                [104.7564069, 10.5190372],
                [104.755235, 10.5190039],
                [104.7533494, 10.5189092],
                [104.7522139, 10.5193431],
                [104.7503167, 10.5199917],
                [104.7484325, 10.5206317],
                [104.7475397, 10.5209394],
                [104.7461628, 10.5214267],
                [104.7451606, 10.5217533],
                [104.7437247, 10.5222386],
                [104.7420767, 10.5227958],
                [104.7405053, 10.5233453],
                [104.7396806, 10.5236272],
                [104.7388697, 10.5239203],
                [104.7379931, 10.5242331],
                [104.7364933, 10.5247656],
                [104.7352103, 10.5252294],
                [104.7345198, 10.5254676],
                [104.7340169, 10.5256411],
                [104.7323175, 10.5262047],
                [104.7305711, 10.5267811],
                [104.7284189, 10.5274797],
                [104.726955, 10.5279681],
                [104.7253211, 10.5285336],
                [104.7230703, 10.5292942],
                [104.7204311, 10.5301933],
                [104.7186569, 10.5307842],
                [104.7177183, 10.5310294],
                [104.7154539, 10.53158],
                [104.7149705, 10.5316957],
                [104.7147842, 10.5317403],
                [104.7137528, 10.5319831],
                [104.7121717, 10.5324622],
                [104.7114789, 10.5326678],
                [104.7109169, 10.5327983],
                [104.7096508, 10.5329922],
                [104.7077394, 10.5332939],
                [104.706585, 10.5334656],
                [104.7050228, 10.5336967],
                [104.7031775, 10.5342033],
                [104.7018244, 10.5345722],
                [104.7006756, 10.5348772],
                [104.6992481, 10.5352664],
                [104.6976811, 10.5356875],
                [104.6962222, 10.53609],
                [104.6949022, 10.5364422],
                [104.6952031, 10.5377136],
                [104.6890058, 10.5380044],
                [104.6843778, 10.5382242],
                [104.6796028, 10.5384514],
                [104.6719553, 10.5388156],
                [104.6645686, 10.5391692],
                [104.6609586, 10.5393392],
                [104.6606353, 10.5393546],
                [104.6569556, 10.5395297],
                [104.6515056, 10.5397911],
                [104.6468244, 10.5400147],
                [104.6468161, 10.5395118],
                [104.6439193, 10.5395763],
                [104.6446198, 10.5296299],
                [104.6415153, 10.5295523],
                [104.6410647, 10.5295545],
                [104.6405863, 10.5295865],
                [104.6403339, 10.5296158],
                [104.6398329, 10.5297001],
                [104.6395505, 10.5297395],
                [104.639267, 10.5297699],
                [104.6389826, 10.5297911],
                [104.6384006, 10.5298066],
                [104.6380994, 10.5297962],
                [104.6379152, 10.5297815],
                [104.6375487, 10.5297334],
                [104.637367, 10.5297001],
                [104.6329058, 10.5286625],
                [104.6325536, 10.5285909],
                [104.632207, 10.5285539],
                [104.6302091, 10.52847],
                [104.6297943, 10.5284375],
                [104.6290129, 10.5283578],
                [104.6282807, 10.528261],
                [104.6273757, 10.5281565],
                [104.6269831, 10.528137],
                [104.6267866, 10.5281356],
                [104.626597, 10.5281394],
                [104.6262187, 10.5281624],
                [104.626019, 10.52816],
                [104.6258192, 10.5281352],
                [104.6254126, 10.5280356],
                [104.6251659, 10.5280023],
                [104.6213521, 10.5279549],
                [104.6160968, 10.5280549],
                [104.6133819, 10.5297359],
                [104.6095273, 10.5321688],
                [104.6091971, 10.5323872],
                [104.6085958, 10.5328112],
                [104.6069832, 10.5331545],
                [104.6059234, 10.5333337],
                [104.6054137, 10.5334077],
                [104.6035911, 10.5336472],
                [104.6034184, 10.5336652],
                [104.6030718, 10.533683],
                [104.602709, 10.5336757],
                [104.5990606, 10.5334419],
                [104.598676, 10.5333873],
                [104.598486, 10.533347],
                [104.5981126, 10.5332411],
                [104.5977778, 10.5331138],
                [104.5976149, 10.5330398],
                [104.5972992, 10.5328718],
                [104.5969992, 10.5326782],
                [104.5957877, 10.5318297],
                [104.5931295, 10.5305396],
                [104.5930835, 10.5305172],
                [104.5930311, 10.5305003],
                [104.5912497, 10.5299444],
                [104.5908834, 10.5298191],
                [104.5905277, 10.5296672],
                [104.5901824, 10.5294884],
                [104.5898517, 10.5292844],
                [104.589345, 10.5289175],
                [104.5890981, 10.5287258],
                [104.5886466, 10.5283514],
                [104.5884269, 10.5281572],
                [104.5878911, 10.5276288],
                [104.5877992, 10.5275136],
                [104.5877448, 10.5273874],
                [104.5877304, 10.5273201],
                [104.5877025, 10.5267509],
                [104.5876792, 10.5257485],
                [104.5876837, 10.5247794],
                [104.5876958, 10.524295],
                [104.5876417, 10.5228768],
                [104.5875719, 10.5216874],
                [104.5874147, 10.5200708],
                [104.5874123, 10.5197834],
                [104.5874326, 10.5194981],
                [104.5876819, 10.5176895],
                [104.5877406, 10.5172108],
                [104.5877756, 10.5167039],
                [104.5877558, 10.5163431],
                [104.5873456, 10.5119508],
                [104.5872522, 10.5110581],
                [104.5870844, 10.5101733],
                [104.5870128, 10.5099261],
                [104.5862608, 10.5084681],
                [104.5858431, 10.5076769],
                [104.5850875, 10.5066458],
                [104.5839309, 10.5050144],
                [104.5838139, 10.5048494],
                [104.5829275, 10.5040694],
                [104.5809239, 10.5028564],
                [104.5793894, 10.5020475],
                [104.5785642, 10.5016967],
                [104.5778756, 10.5014469],
                [104.5769428, 10.5011764],
                [104.5739689, 10.5005131],
                [104.5733222, 10.5003261],
                [104.5722992, 10.4999075],
                [104.5700592, 10.4988106],
                [104.5695631, 10.4985486],
                [104.5690869, 10.498215],
                [104.5667872, 10.4965139],
                [104.5649178, 10.4952403],
                [104.5644967, 10.4950172],
                [104.5628039, 10.4943625],
                [104.5613833, 10.4939836],
                [104.5598417, 10.4929911],
                [104.5596447, 10.4924947],
                [104.5598483, 10.4900458],
                [104.5597114, 10.4873281],
                [104.5594894, 10.4838425],
                [104.5590442, 10.4788294],
                [104.5583375, 10.4728497],
                [104.558173, 10.4723716],
                [104.5578969, 10.4715692],
                [104.5572489, 10.4701539],
                [104.5562217, 10.4679581],
                [104.5559817, 10.4667006],
                [104.5559114, 10.4661069],
                [104.5555847, 10.4637072],
                [104.5554506, 10.4634267],
                [104.5552311, 10.4628569],
                [104.5551042, 10.4624903],
                [104.5540819, 10.4604731],
                [104.5525542, 10.4575536],
                [104.5515906, 10.4563333],
                [104.5507033, 10.4553578],
                [104.5500578, 10.4546328],
                [104.5491294, 10.4536322],
                [104.5476444, 10.452195],
                [104.5460031, 10.4506258],
                [104.5443969, 10.4490783],
                [104.5430611, 10.4481769],
                [104.5418769, 10.4476164],
                [104.5418389, 10.4476103],
                [104.5405994, 10.4475856],
                [104.5384419, 10.4475553],
                [104.5363236, 10.4475089],
                [104.5339239, 10.4472883],
                [104.532615, 10.4471775],
                [104.5312831, 10.4471117],
                [104.5297594, 10.4470528],
                [104.5282169, 10.4468747],
                [104.5281044, 10.4468617],
                [104.5263253, 10.4458456],
                [104.5241147, 10.4445069],
                [104.5225358, 10.4434872],
                [104.5212808, 10.4427008],
                [104.5202417, 10.4420714],
                [104.5182653, 10.4402697],
                [104.5167261, 10.4379797],
                [104.5156717, 10.4358875],
                [104.5143256, 10.4331756],
                [104.5134928, 10.4315631],
                [104.5123267, 10.4293744],
                [104.5112394, 10.4273967],
                [104.5101756, 10.4250328],
                [104.5090644, 10.4221136],
                [104.5087506, 10.4213906],
                [104.5083294, 10.4206108],
                [104.5069064, 10.4180269],
                [104.5068875, 10.4179953],
                [104.5061353, 10.4166728],
                [104.5045644, 10.4138772],
                [104.5035853, 10.4121533],
                [104.5032789, 10.4116347],
                [104.5016608, 10.4089461],
                [104.5013286, 10.4083931],
                [104.5012939, 10.4083064],
                [104.5008933, 10.4077558],
                [104.5005736, 10.4072894],
                [104.5001003, 10.4066642],
                [104.4999147, 10.4064367],
                [104.499605, 10.4061239],
                [104.4994642, 10.4059864],
                [104.4987017, 10.405195],
                [104.4979608, 10.4044544],
                [104.4978222, 10.4044575],
                [104.4976917, 10.4045353],
                [104.4970483, 10.4050136],
                [104.4965669, 10.4053825],
                [104.4962889, 10.4056425],
                [104.4959753, 10.4059811],
                [104.4957781, 10.4062244],
                [104.4955736, 10.4064797],
                [104.4953717, 10.4067758],
                [104.4952114, 10.4069494],
                [104.4949069, 10.4072911],
                [104.4946331, 10.4076281],
                [104.4942778, 10.4080786],
                [104.4938906, 10.4086014],
                [104.4938511, 10.4086547],
                [104.4934814, 10.4091992],
                [104.4930294, 10.4099422],
                [104.4919975, 10.4116014],
                [104.49179, 10.4119967],
                [104.4916883, 10.4126725],
                [104.4914128, 10.4132642],
                [104.4909575, 10.4139467],
                [104.4903664, 10.41471],
                [104.4889189, 10.4167883],
                [104.4889475, 10.4168059],
                [104.4890115, 10.4168261],
                [104.4890513, 10.4168279],
                [104.4890906, 10.4168221],
                [104.4891931, 10.4167885],
                [104.4892575, 10.416796],
                [104.4893109, 10.4168263],
                [104.4893709, 10.4168894],
                [104.4894041, 10.4169132],
                [104.4894833, 10.4169489],
                [104.489471, 10.4170291],
                [104.4894279, 10.4171855],
                [104.4893568, 10.4173412],
                [104.488872, 10.4181266],
                [104.4888139, 10.4182354],
                [104.4887624, 10.4184028],
                [104.4887365, 10.4185737],
                [104.4887259, 10.4187287],
                [104.4886997, 10.4187624],
                [104.4886641, 10.4187769],
                [104.4886398, 10.4187776],
                [104.4886175, 10.4187874],
                [104.4886007, 10.4188047],
                [104.4885913, 10.4188475],
                [104.4886103, 10.4188833],
                [104.488707, 10.4189206],
                [104.488906, 10.4189683],
                [104.4889653, 10.4189944],
                [104.4891362, 10.4190921],
                [104.4892622, 10.4191378],
                [104.4891377, 10.4203719],
                [104.4855212, 10.4240784],
                [104.4818969, 10.427793],
                [104.4783131, 10.431466],
                [104.4747118, 10.4308586],
                [104.4711266, 10.430254],
                [104.4675322, 10.4296478],
                [104.4639377, 10.4290416],
                [104.4626481, 10.4287073],
                [104.4613677, 10.4283755],
                [104.4600834, 10.4280426],
                [104.4588083, 10.4277121],
                [104.4573469, 10.4273332],
                [104.4558535, 10.4269461],
                [104.4547599, 10.4266627],
                [104.4537385, 10.4263979],
                [104.4536647, 10.4263788],
                [104.4536067, 10.4263663],
                [104.4519953, 10.4260181],
                [104.4504243, 10.4256787],
                [104.4485806, 10.4252804],
                [104.4467948, 10.4248946],
                [104.4455791, 10.4246319],
                [104.443927, 10.424275],
                [104.4423629, 10.423937],
                [104.4406882, 10.4235752],
                [104.4392374, 10.4231988],
                [104.3482117, 10.3568737],
                [104.0710119, 10.1548084],
                [104.0787051, 10.1829446],
                [104.079567, 10.1852489],
                [104.079735, 10.1856142],
                [104.079937, 10.186076],
                [104.0800506, 10.1863426],
                [104.0801926, 10.1866259],
                [104.0803719, 10.1869975],
                [104.0805454, 10.1873718],
                [104.0805971, 10.1874879],
                [104.0809476, 10.1877048],
                [104.0813078, 10.1879355],
                [104.0816642, 10.1881717],
                [104.0820169, 10.1884135],
                [104.0823657, 10.1886607],
                [104.0827106, 10.1889133],
                [104.0830514, 10.1891712],
                [104.0833882, 10.1894344],
                [104.0837207, 10.1897027],
                [104.0840489, 10.1899762],
                [104.0843728, 10.1902548],
                [104.0846922, 10.1905383],
                [104.0850071, 10.1908267],
                [104.0853174, 10.19112],
                [104.0856231, 10.1914181],
                [104.085924, 10.1917209],
                [104.08622, 10.1920283],
                [104.0865112, 10.1923403],
                [104.0867974, 10.1926567],
                [104.0870786, 10.1929776],
                [104.0873546, 10.1933028],
                [104.0876255, 10.1936322],
                [104.0876494, 10.1936622],
                [104.0877191, 10.1937269],
                [104.0880275, 10.1940228],
                [104.0886841, 10.1946625],
                [104.088983, 10.1949583],
                [104.0892773, 10.1952587],
                [104.0895669, 10.1955635],
                [104.0898518, 10.1958727],
                [104.0901317, 10.1961862],
                [104.0904068, 10.1965039],
                [104.0909692, 10.1971639],
                [104.0911762, 10.1974098],
                [104.0913803, 10.197658],
                [104.0919275, 10.1983318],
                [104.0921899, 10.19866],
                [104.0924472, 10.1989922],
                [104.0926992, 10.1993283],
                [104.092946, 10.1996682],
                [104.0931874, 10.2000118],
                [104.0934235, 10.2003591],
                [104.0936541, 10.2007099],
                [104.0938793, 10.2010642],
                [104.0940988, 10.201422],
                [104.0943128, 10.201783],
                [104.0945212, 10.2021473],
                [104.0947238, 10.2025147],
                [104.0949207, 10.2028851],
                [104.0951118, 10.2032586],
                [104.0952971, 10.2036349],
                [104.0953027, 10.2036467],
                [104.0954257, 10.2038738],
                [104.0956241, 10.2042543],
                [104.0958164, 10.2046378],
                [104.0960026, 10.2050243],
                [104.0961825, 10.2054137],
                [104.0963563, 10.2058058],
                [104.0965238, 10.2062007],
                [104.0966849, 10.206598],
                [104.0968398, 10.2069979],
                [104.0969455, 10.2072844],
                [104.0970281, 10.2074154],
                [104.0972489, 10.2077781],
                [104.0974639, 10.2081442],
                [104.0976731, 10.2085136],
                [104.0978765, 10.2088861],
                [104.098074, 10.2092617],
                [104.0982309, 10.2095718],
                [104.098261, 10.209627],
                [104.0984567, 10.2099996],
                [104.0986465, 10.2103751],
                [104.0988304, 10.2107535],
                [104.0990084, 10.2111347],
                [104.0991805, 10.2115186],
                [104.0993465, 10.211905],
                [104.0995064, 10.212294],
                [104.0996603, 10.2126854],
                [104.099808, 10.213079],
                [104.0999495, 10.2134749],
                [104.1002123, 10.2142283],
                [104.100348, 10.2146274],
                [104.1004774, 10.2150286],
                [104.1006006, 10.2154317],
                [104.1007174, 10.2158366],
                [104.1007648, 10.2160111],
                [104.1008105, 10.2161424],
                [104.1009448, 10.2165492],
                [104.1010727, 10.216958],
                [104.101194, 10.2173687],
                [104.1013087, 10.2177813],
                [104.1014169, 10.2181956],
                [104.1015185, 10.2186115],
                [104.1016134, 10.219029],
                [104.1017017, 10.219448],
                [104.1017833, 10.2198682],
                [104.1018582, 10.2202897],
                [104.1019264, 10.2207123],
                [104.1019371, 10.2207822],
                [104.1019979, 10.2212016],
                [104.1020522, 10.221622],
                [104.1020998, 10.2220431],
                [104.1021114, 10.222163],
                [104.102127, 10.2222245],
                [104.1022236, 10.2226338],
                [104.1025245, 10.2239546],
                [104.1026039, 10.2243147],
                [104.1027578, 10.2250353],
                [104.1029363, 10.2254096],
                [104.1031147, 10.2257993],
                [104.1032868, 10.2261918],
                [104.1034527, 10.2265869],
                [104.1036123, 10.2269846],
                [104.1037655, 10.2273847],
                [104.1038268, 10.2275487],
                [104.1039727, 10.2279485],
                [104.1041123, 10.2283505],
                [104.1042454, 10.2287547],
                [104.1043722, 10.2291608],
                [104.1044925, 10.2295689],
                [104.1046064, 10.2299788],
                [104.1047137, 10.2303904],
                [104.1048145, 10.2308036],
                [104.1049088, 10.2312183],
                [104.1049965, 10.2316345],
                [104.1049973, 10.2316384],
                [104.1050119, 10.2316627],
                [104.1052245, 10.2320307],
                [104.1054313, 10.2324018],
                [104.1056322, 10.2327761],
                [104.1058272, 10.2331535],
                [104.1060162, 10.2335339],
                [104.1061992, 10.2339171],
                [104.1063761, 10.2343031],
                [104.1065469, 10.2346918],
                [104.1067116, 10.2350831],
                [104.10687, 10.2354769],
                [104.1070223, 10.2358731],
                [104.1071682, 10.2362716],
                [104.1073079, 10.2366723],
                [104.1074412, 10.2370752],
                [104.1075681, 10.23748],
                [104.1076887, 10.2378868],
                [104.1078028, 10.2382954],
                [104.1079105, 10.2387057],
                [104.1080116, 10.2391176],
                [104.1081063, 10.239531],
                [104.1081944, 10.2399459],
                [104.108276, 10.240362],
                [104.108351, 10.2407794],
                [104.1084194, 10.2411979],
                [104.1084812, 10.2416173],
                [104.1085364, 10.2420377],
                [104.1085849, 10.2424589],
                [104.1086268, 10.2428808],
                [104.108662, 10.2433033],
                [104.1086906, 10.2437263],
                [104.1087125, 10.2441497],
                [104.1087276, 10.2445733],
                [104.1087362, 10.2449972],
                [104.108738, 10.2454211],
                [104.1087331, 10.245845],
                [104.1087216, 10.2462688],
                [104.1087033, 10.2466923],
                [104.1086784, 10.2471155],
                [104.1086468, 10.2475383],
                [104.1086086, 10.2479605],
                [104.1085637, 10.2483821],
                [104.1085121, 10.248803],
                [104.1084539, 10.249223],
                [104.1083891, 10.249642],
                [104.1083177, 10.25006],
                [104.1082397, 10.2504768],
                [104.1081876, 10.2507329],
                [104.108202, 10.2508377],
                [104.1082536, 10.2512612],
                [104.1082984, 10.2516854],
                [104.1083365, 10.2521103],
                [104.1083678, 10.2525357],
                [104.1083924, 10.2529615],
                [104.1084102, 10.2533877],
                [104.1084213, 10.2538141],
                [104.1084256, 10.2542406],
                [104.1084231, 10.2546671],
                [104.1084138, 10.2550935],
                [104.1083978, 10.2555198],
                [104.108375, 10.2559457],
                [104.1083454, 10.2563712],
                [104.1083092, 10.2567962],
                [104.1082661, 10.2572206],
                [104.1082163, 10.2576443],
                [104.1081598, 10.2580671],
                [104.1080967, 10.258489],
                [104.1080268, 10.2589099],
                [104.1079502, 10.2593297],
                [104.107867, 10.2597481],
                [104.1077772, 10.2601653],
                [104.1076807, 10.260581],
                [104.1075777, 10.2609951],
                [104.1074681, 10.2614076],
                [104.1073519, 10.2618183],
                [104.1072293, 10.2622272],
                [104.1071883, 10.2623563],
                [104.10711, 10.2626765],
                [104.1070916, 10.2627801],
                [104.1070096, 10.2632042],
                [104.1069207, 10.2636271],
                [104.106825, 10.2640484],
                [104.1067225, 10.2644683],
                [104.1066134, 10.2648864],
                [104.1064975, 10.2653028],
                [104.1063749, 10.2657173],
                [104.1062457, 10.2661298],
                [104.1061099, 10.2665402],
                [104.1059675, 10.2669484],
                [104.1058692, 10.2672162],
                [104.1057922, 10.2674355],
                [104.1056456, 10.2678331],
                [104.1054927, 10.2682284],
                [104.1053336, 10.2686213],
                [104.1051683, 10.2690117],
                [104.1051419, 10.2690713],
                [104.1051146, 10.2692503],
                [104.1050435, 10.269671],
                [104.1049657, 10.2700906],
                [104.1048813, 10.2705089],
                [104.1047902, 10.2709258],
                [104.1046926, 10.2713413],
                [104.1045883, 10.2717552],
                [104.1044775, 10.2721674],
                [104.1043602, 10.2725778],
                [104.1042364, 10.2729864],
                [104.104106, 10.273393],
                [104.1039693, 10.2737975],
                [104.1038261, 10.2741998],
                [104.1036766, 10.2745999],
                [104.1035208, 10.2749975],
                [104.1033586, 10.2753927],
                [104.1031902, 10.2757854],
                [104.1030156, 10.2761753],
                [104.1028348, 10.2765625],
                [104.1027742, 10.2766872],
                [104.1027629, 10.2768254],
                [104.1027219, 10.2772475],
                [104.1026742, 10.277669],
                [104.1026199, 10.2780897],
                [104.1025806, 10.2783605],
                [104.1025783, 10.2783995],
                [104.1025469, 10.2788244],
                [104.1025086, 10.2792487],
                [104.1024925, 10.279401],
                [104.10258, 10.2797805],
                [104.1026694, 10.2801983],
                [104.1027522, 10.2806174],
                [104.1028282, 10.2810377],
                [104.1028976, 10.2814592],
                [104.1029603, 10.2818818],
                [104.1030163, 10.2823052],
                [104.1030655, 10.2827295],
                [104.103098, 10.2830552],
                [104.103222, 10.283387],
                [104.1033629, 10.2837826],
                [104.1034976, 10.2841803],
                [104.1036261, 10.2845801],
                [104.1037483, 10.2849818],
                [104.1038463, 10.2853229],
                [104.1038522, 10.2853381],
                [104.1040029, 10.2857449],
                [104.104147, 10.2861541],
                [104.1042845, 10.2865654],
                [104.1043593, 10.286802],
                [104.1043784, 10.2868468],
                [104.1045405, 10.2872439],
                [104.1046961, 10.2876435],
                [104.1048454, 10.2880454],
                [104.1049883, 10.2884497],
                [104.1051247, 10.2888561],
                [104.1052546, 10.2892646],
                [104.105378, 10.2896751],
                [104.1054948, 10.2900875],
                [104.105605, 10.2905016],
                [104.1057086, 10.2909174],
                [104.1058056, 10.2913348],
                [104.1058959, 10.2917536],
                [104.1059795, 10.2921738],
                [104.1060565, 10.2925953],
                [104.1061267, 10.2930179],
                [104.106332, 10.294318],
                [104.1064249, 10.2946188],
                [104.1065451, 10.2950308],
                [104.1066587, 10.2954447],
                [104.1067657, 10.2958602],
                [104.106866, 10.2962774],
                [104.1069597, 10.2966961],
                [104.1070467, 10.2971163],
                [104.1071269, 10.2975377],
                [104.1072004, 10.2979603],
                [104.1072672, 10.2983841],
                [104.1073272, 10.2988088],
                [104.1073804, 10.2992344],
                [104.1074268, 10.2996608],
                [104.1074664, 10.3000878],
                [104.1074991, 10.3005155],
                [104.1075251, 10.3009436],
                [104.1075442, 10.301372],
                [104.1075564, 10.3018007],
                [104.1075619, 10.3022295],
                [104.1075604, 10.3026584],
                [104.1075522, 10.3030872],
                [104.1075391, 10.3034574],
                [104.1075538, 10.3035859],
                [104.1075946, 10.3039991],
                [104.107654, 10.3046529],
                [104.107689, 10.3050739],
                [104.1077173, 10.3054955],
                [104.107739, 10.3059175],
                [104.1077541, 10.3063397],
                [104.1077625, 10.3067621],
                [104.1077643, 10.3071846],
                [104.1077594, 10.3076071],
                [104.1077479, 10.3080295],
                [104.1077478, 10.3080315],
                [104.1077491, 10.3080905],
                [104.1077513, 10.3085256],
                [104.1077464, 10.3089608],
                [104.1077345, 10.3093958],
                [104.1077155, 10.3098305],
                [104.1076895, 10.310265],
                [104.1076565, 10.3106989],
                [104.1076165, 10.3111322],
                [104.1075694, 10.3115649],
                [104.1075153, 10.3119968],
                [104.1074543, 10.3124277],
                [104.1073862, 10.3128576],
                [104.1073113, 10.3132864],
                [104.1072293, 10.3137139],
                [104.1071405, 10.3141401],
                [104.1070448, 10.3145648],
                [104.1069791, 10.3148457],
                [104.1069326, 10.3150934],
                [104.1068521, 10.3155043],
                [104.1067652, 10.3159139],
                [104.1065755, 10.3167749],
                [104.106473, 10.3172223],
                [104.1063628, 10.3176679],
                [104.106245, 10.3181117],
                [104.1061197, 10.3185534],
                [104.1059868, 10.3189929],
                [104.1058464, 10.3194302],
                [104.1055686, 10.320271],
                [104.1054334, 10.3206696],
                [104.105292, 10.3210661],
                [104.1051719, 10.321387],
                [104.1050476, 10.321746],
                [104.1049012, 10.3221485],
                [104.1047484, 10.3225485],
                [104.1045892, 10.3229462],
                [104.1044237, 10.3233413],
                [104.104252, 10.3237338],
                [104.1042504, 10.3237373],
                [104.1041944, 10.324038],
                [104.1041051, 10.3244773],
                [104.1040085, 10.324915],
                [104.1039045, 10.3253511],
                [104.1037933, 10.3257855],
                [104.1036749, 10.3262179],
                [104.1035493, 10.3266484],
                [104.1034165, 10.3270768],
                [104.1032977, 10.3274493],
                [104.1032559, 10.3275767],
                [104.1031616, 10.3279596],
                [104.1030535, 10.3283707],
                [104.1029389, 10.3287801],
                [104.1029069, 10.328888],
                [104.1029089, 10.3290433],
                [104.1029079, 10.3294687],
                [104.1029001, 10.329894],
                [104.1028856, 10.3303191],
                [104.1028643, 10.330744],
                [104.1028364, 10.3311685],
                [104.1028017, 10.3315925],
                [104.1027603, 10.3320159],
                [104.1027122, 10.3324386],
                [104.1026574, 10.3328605],
                [104.1025959, 10.3332816],
                [104.1025278, 10.3337016],
                [104.1024531, 10.3341205],
                [104.1023717, 10.3345382],
                [104.1022837, 10.3349545],
                [104.1021891, 10.3353695],
                [104.102088, 10.3357829],
                [104.1019803, 10.3361948],
                [104.1018661, 10.3366049],
                [104.1017564, 10.3369762],
                [104.1018934, 10.3371469],
                [104.1021575, 10.3374868],
                [104.1024161, 10.3378307],
                [104.1026691, 10.3381786],
                [104.1029166, 10.3385305],
                [104.1031585, 10.3388862],
                [104.1033946, 10.3392456],
                [104.103625, 10.3396087],
                [104.1038495, 10.3399753],
                [104.1040682, 10.3403454],
                [104.1042809, 10.3407189],
                [104.1044877, 10.3410957],
                [104.1046884, 10.3414756],
                [104.104883, 10.3418587],
                [104.1050715, 10.3422447],
                [104.1052538, 10.3426337],
                [104.1054298, 10.3430254],
                [104.1055996, 10.3434199],
                [104.1057631, 10.3438169],
                [104.1059202, 10.3442165],
                [104.1060709, 10.3446185],
                [104.1062152, 10.3450228],
                [104.106353, 10.3454292],
                [104.1064843, 10.3458378],
                [104.1066091, 10.3462485],
                [104.1067273, 10.346661],
                [104.1068389, 10.3470753],
                [104.1069439, 10.3474913],
                [104.1070422, 10.3479088],
                [104.1071338, 10.3483279],
                [104.1072188, 10.3487484],
                [104.107297, 10.3491701],
                [104.1073685, 10.349593],
                [104.1074332, 10.350017],
                [104.1074911, 10.3504419],
                [104.1074953, 10.3504765],
                [104.1075209, 10.3506185],
                [104.1075904, 10.3510434],
                [104.1076531, 10.3514694],
                [104.1077089, 10.3518962],
                [104.1077579, 10.3523239],
                [104.1078001, 10.3527523],
                [104.1078353, 10.3531813],
                [104.1078637, 10.3536109],
                [104.1078642, 10.3536198],
                [104.1078853, 10.3540408],
                [104.1078999, 10.354471],
                [104.1079076, 10.3549013],
                [104.1079084, 10.3553318],
                [104.1079024, 10.3557622],
                [104.1078894, 10.3561924],
                [104.1078695, 10.3566224],
                [104.1078428, 10.357052],
                [104.1078092, 10.3574812],
                [104.1077687, 10.3579097],
                [104.1077213, 10.3583376],
                [104.1076671, 10.3587647],
                [104.1076061, 10.3591908],
                [104.1075382, 10.359616],
                [104.1074635, 10.36004],
                [104.1073821, 10.3604628],
                [104.1072939, 10.3608843],
                [104.1071989, 10.3613044],
                [104.1070972, 10.3617229],
                [104.1069888, 10.3621397],
                [104.1069506, 10.362282],
                [104.1065288, 10.3639793],
                [104.1064218, 10.3643958],
                [104.1063081, 10.3648105],
                [104.1061878, 10.3652234],
                [104.1060609, 10.3656344],
                [104.1059274, 10.3660433],
                [104.1059174, 10.3660724],
                [104.1059429, 10.3662201],
                [104.1060094, 10.3666479],
                [104.1060691, 10.3670767],
                [104.1061219, 10.3675064],
                [104.1061677, 10.3679369],
                [104.1062066, 10.368368],
                [104.1062385, 10.3687997],
                [104.1062634, 10.3692318],
                [104.1062814, 10.3696643],
                [104.1062925, 10.370097],
                [104.1062965, 10.3705298],
                [104.1062936, 10.3709627],
                [104.1062837, 10.3713954],
                [104.1062669, 10.3718279],
                [104.106243, 10.3722601],
                [104.1062122, 10.3726919],
                [104.1061745, 10.3731231],
                [104.1061298, 10.3735537],
                [104.1060782, 10.3739835],
                [104.1060454, 10.374239],
                [104.1059886, 10.3746559],
                [104.1059252, 10.3750719],
                [104.1058554, 10.3754868],
                [104.1057791, 10.3759006],
                [104.1056963, 10.3763133],
                [104.105607, 10.3767245],
                [104.1055114, 10.3771344],
                [104.1054093, 10.3775428],
                [104.1053008, 10.3779495],
                [104.105186, 10.3783545],
                [104.1050648, 10.3787577],
                [104.1049374, 10.379159],
                [104.1048036, 10.3795583],
                [104.1046637, 10.3799555],
                [104.1045175, 10.3803505],
                [104.1043652, 10.3807432],
                [104.1042067, 10.3811335],
                [104.1040421, 10.3815214],
                [104.1038715, 10.3819066],
                [104.1036949, 10.3822892],
                [104.1035123, 10.3826691],
                [104.1033237, 10.3830461],
                [104.1031293, 10.3834201],
                [104.1029685, 10.3837182],
                [104.1029138, 10.3838581],
                [104.1027539, 10.3842497],
                [104.1025878, 10.3846387],
                [104.1024156, 10.3850252],
                [104.1022373, 10.3854089],
                [104.102053, 10.3857899],
                [104.1018628, 10.3861679],
                [104.1016666, 10.386543],
                [104.1014646, 10.386915],
                [104.1012567, 10.3872839],
                [104.1010431, 10.3876495],
                [104.1008237, 10.3880118],
                [104.1005987, 10.3883706],
                [104.100368, 10.388726],
                [104.1001318, 10.3890777],
                [104.0998901, 10.3894258],
                [104.0996429, 10.38977],
                [104.0993904, 10.3901105],
                [104.0991325, 10.390447],
                [104.0991291, 10.3904513],
                [104.0990258, 10.3906744],
                [104.0988407, 10.3910584],
                [104.0986495, 10.3914394],
                [104.0984523, 10.3918174],
                [104.0982492, 10.3921923],
                [104.0980401, 10.392564],
                [104.0978252, 10.3929324],
                [104.0976045, 10.3932974],
                [104.097378, 10.393659],
                [104.0971458, 10.394017],
                [104.0969079, 10.3943713],
                [104.0966645, 10.3947219],
                [104.0964156, 10.3950687],
                [104.0961612, 10.3954116],
                [104.0959014, 10.3957505],
                [104.0956362, 10.3960853],
                [104.0953658, 10.396416],
                [104.0950902, 10.3967424],
                [104.0948094, 10.3970645],
                [104.0945236, 10.3973822],
                [104.0942328, 10.3976954],
                [104.0939371, 10.3980041],
                [104.0936365, 10.3983081],
                [104.0933738, 10.3985656],
                [104.0931542, 10.3988663],
                [104.0928883, 10.3992184],
                [104.0926167, 10.3995661],
                [104.0923395, 10.3999095],
                [104.0920566, 10.4002483],
                [104.0911217, 10.40135],
                [104.0908328, 10.4016849],
                [104.0905384, 10.4020151],
                [104.0902386, 10.4023406],
                [104.0899335, 10.4026612],
                [104.0896233, 10.4029768],
                [104.0893078, 10.4032875],
                [104.0889874, 10.403593],
                [104.0886619, 10.4038933],
                [104.0883316, 10.4041883],
                [104.0879965, 10.404478],
                [104.0876566, 10.4047623],
                [104.0873122, 10.4050411],
                [104.0870655, 10.4052342],
                [104.087016, 10.4053685],
                [104.0868638, 10.4057623],
                [104.0867053, 10.4061537],
                [104.0865408, 10.4065426],
                [104.0863702, 10.406929],
                [104.0861935, 10.4073127],
                [104.0860109, 10.4076936],
                [104.0858222, 10.4080717],
                [104.0856277, 10.4084468],
                [104.0854273, 10.4088189],
                [104.0852211, 10.4091879],
                [104.0850091, 10.4095536],
                [104.0847914, 10.409916],
                [104.084568, 10.4102751],
                [104.084339, 10.4106306],
                [104.0841045, 10.4109826],
                [104.0836753, 10.4116159],
                [104.0834276, 10.4119751],
                [104.0831742, 10.4123304],
                [104.0829356, 10.4126536],
                [104.0827849, 10.4130238],
                [104.0826171, 10.4134179],
                [104.0824431, 10.4138093],
                [104.0822629, 10.414198],
                [104.0820765, 10.4145838],
                [104.081884, 10.4149666],
                [104.0816854, 10.4153464],
                [104.0814808, 10.415723],
                [104.0812702, 10.4160964],
                [104.0810537, 10.4164665],
                [104.0808313, 10.4168332],
                [104.0806031, 10.4171963],
                [104.0803692, 10.4175558],
                [104.0801295, 10.4179116],
                [104.0798843, 10.4182637],
                [104.0796334, 10.4186119],
                [104.079377, 10.4189561],
                [104.0791152, 10.4192963],
                [104.078848, 10.4196323],
                [104.0785755, 10.4199641],
                [104.0782978, 10.4202917],
                [104.0780148, 10.4206148],
                [104.0777267, 10.4209335],
                [104.0774336, 10.4212477],
                [104.0771356, 10.4215573],
                [104.0768326, 10.4218622],
                [104.0765248, 10.4221623],
                [104.0762123, 10.4224575],
                [104.0758951, 10.4227479],
                [104.0755734, 10.4230332],
                [104.0752471, 10.4233135],
                [104.0749164, 10.4235887],
                [104.0745814, 10.4238587],
                [104.0742421, 10.4241234],
                [104.0738986, 10.4243828],
                [104.0735511, 10.4246369],
                [104.0731995, 10.4248854],
                [104.072844, 10.4251284],
                [104.0724847, 10.4253659],
                [104.0721217, 10.4255977],
                [104.071755, 10.4258238],
                [104.0713848, 10.4260442],
                [104.0710111, 10.4262588],
                [104.070634, 10.4264675],
                [104.0702537, 10.4266703],
                [104.0698701, 10.4268672],
                [104.0694835, 10.427058],
                [104.0690939, 10.4272428],
                [104.068948, 10.4273106],
                [104.0685552, 10.4274893],
                [104.0681597, 10.427662],
                [104.0677615, 10.4278284],
                [104.0673606, 10.4279886],
                [104.0669573, 10.4281425],
                [104.0665516, 10.4282901],
                [104.0661435, 10.4284313],
                [104.065937, 10.4284992],
                [104.0658755, 10.4286032],
                [104.0656532, 10.4289659],
                [104.0654253, 10.429325],
                [104.0651917, 10.4296806],
                [104.0649525, 10.4300325],
                [104.0647077, 10.4303807],
                [104.0644576, 10.4307251],
                [104.064202, 10.4310655],
                [104.063941, 10.431402],
                [104.0636748, 10.4317344],
                [104.0634034, 10.4320626],
                [104.0631268, 10.4323865],
                [104.0628451, 10.4327062],
                [104.0625584, 10.4330214],
                [104.0622668, 10.4333322],
                [104.0619702, 10.4336384],
                [104.0616689, 10.43394],
                [104.0613629, 10.4342369],
                [104.0610522, 10.434529],
                [104.0607369, 10.4348163],
                [104.0604172, 10.4350986],
                [104.060093, 10.435376],
                [104.0597645, 10.4356483],
                [104.0594317, 10.4359155],
                [104.0590947, 10.4361776],
                [104.0587536, 10.4364343],
                [104.0584086, 10.4366858],
                [104.0580595, 10.4369319],
                [104.0577067, 10.4371726],
                [104.0573501, 10.4374077],
                [104.0569898, 10.4376374],
                [104.0566259, 10.4378614],
                [104.0562585, 10.4380797],
                [104.0558878, 10.4382924],
                [104.0555137, 10.4384993],
                [104.0551364, 10.4387003],
                [104.0547559, 10.4388955],
                [104.0543725, 10.4390848],
                [104.0540922, 10.4392178],
                [104.0538772, 10.4393341],
                [104.0534969, 10.4395321],
                [104.0531135, 10.4397243],
                [104.0527272, 10.4399104],
                [104.0524095, 10.4400575],
                [104.0522169, 10.4401559],
                [104.051824, 10.4403491],
                [104.0514279, 10.440536],
                [104.0510289, 10.4407166],
                [104.0506271, 10.4408908],
                [104.0502224, 10.4410587],
                [104.0498151, 10.4412201],
                [104.0494053, 10.4413751],
                [104.0489929, 10.4415235],
                [104.0485783, 10.4416654],
                [104.0481614, 10.4418007],
                [104.0477423, 10.4419294],
                [104.0473213, 10.4420514],
                [104.0468983, 10.4421667],
                [104.0464735, 10.4422753],
                [104.0461645, 10.4423517],
                [104.0457424, 10.4424526],
                [104.0453188, 10.4425468],
                [104.0448937, 10.4426343],
                [104.0444673, 10.4427152],
                [104.0440397, 10.4427894],
                [104.0436109, 10.4428569],
                [104.0431811, 10.4429176],
                [104.0427503, 10.4429716],
                [104.0423188, 10.4430188],
                [104.0418865, 10.4430593],
                [104.0414537, 10.4430929],
                [104.0412245, 10.4431071],
                [104.0411998, 10.4431452],
                [104.0396543, 10.4457936],
                [104.0395833, 10.4459145],
                [104.0390717, 10.4467808],
                [104.0389476, 10.4470391],
                [104.0383375, 10.4482806],
                [104.0382905, 10.4483758],
                [104.0376643, 10.4496375],
                [104.0374709, 10.4500194],
                [104.0372716, 10.4503982],
                [104.0370662, 10.4507739],
                [104.0368548, 10.4511464],
                [104.0366376, 10.4515154],
                [104.0364145, 10.4518811],
                [104.0361857, 10.4522432],
                [104.0359511, 10.4526018],
                [104.0357108, 10.4529566],
                [104.0354649, 10.4533076],
                [104.0352135, 10.4536548],
                [104.0349566, 10.453998],
                [104.0346942, 10.4543371],
                [104.0346652, 10.4543734],
                [104.0345732, 10.4545022],
                [104.0343229, 10.4548405],
                [104.0341204, 10.455174],
                [104.0338916, 10.4555376],
                [104.033657, 10.4558977],
                [104.0334167, 10.456254],
                [104.0331707, 10.4566065],
                [104.0329749, 10.4568779],
                [104.0326718, 10.4574047],
                [104.032665, 10.4574199],
                [104.0324909, 10.4577909],
                [104.0323112, 10.4581593],
                [104.0321259, 10.4585249],
                [104.031935, 10.4588877],
                [104.0317387, 10.4592476],
                [104.0315369, 10.4596046],
                [104.0313296, 10.4599585],
                [104.0312718, 10.4600556],
                [104.0312306, 10.4601373],
                [104.0310318, 10.4605161],
                [104.0308269, 10.4608917],
                [104.0306162, 10.4612641],
                [104.0303995, 10.4616332],
                [104.030177, 10.4619989],
                [104.0299488, 10.462361],
                [104.0297148, 10.4627196],
                [104.0294751, 10.4630745],
                [104.0292298, 10.4634256],
                [104.0289789, 10.4637728],
                [104.0287226, 10.4641161],
                [104.0284609, 10.4644553],
                [104.0281937, 10.4647904],
                [104.0279213, 10.4651214],
                [104.0276437, 10.465448],
                [104.0273609, 10.4657703],
                [104.027073, 10.4660881],
                [104.0267801, 10.4664014],
                [104.0264822, 10.4667101],
                [104.0261795, 10.4670142],
                [104.025872, 10.4673134],
                [104.0255598, 10.4676079],
                [104.0252429, 10.4678974],
                [104.0249214, 10.468182],
                [104.0245955, 10.4684616],
                [104.0242652, 10.468736],
                [104.0239305, 10.4690052],
                [104.0235916, 10.4692693],
                [104.0232486, 10.4695279],
                [104.0229015, 10.4697813],
                [104.0225504, 10.4700292],
                [104.0221954, 10.4702715],
                [104.0218365, 10.4705084],
                [104.021474, 10.4707396],
                [104.0211078, 10.4709651],
                [104.0207381, 10.4711849],
                [104.020365, 10.4713989],
                [104.0199885, 10.4716071],
                [104.0196087, 10.4718094],
                [104.0192258, 10.4720058],
                [104.0188397, 10.4721961],
                [104.0184507, 10.4723804],
                [104.0180589, 10.4725587],
                [104.0176642, 10.4727308],
                [104.0172669, 10.4728968],
                [104.0168669, 10.4730565],
                [104.0164645, 10.47321],
                [104.0160597, 10.4733572],
                [104.0156525, 10.4734981],
                [104.0152432, 10.4736326],
                [104.0148319, 10.4737608],
                [104.0144185, 10.4738825],
                [104.0140153, 10.4739944],
                [104.0139196, 10.474052],
                [104.0135449, 10.4742697],
                [104.0131667, 10.4744815],
                [104.0127853, 10.4746873],
                [104.0124005, 10.4748871],
                [104.0120127, 10.4750809],
                [104.0116217, 10.4752685],
                [104.0112278, 10.47545],
                [104.0108311, 10.4756253],
                [104.0104316, 10.4757943],
                [104.0100294, 10.475957],
                [104.0096247, 10.4761135],
                [104.0092175, 10.4762635],
                [104.008808, 10.4764071],
                [104.0083962, 10.4765443],
                [104.0079823, 10.476675],
                [104.0075663, 10.4767993],
                [104.0071484, 10.4769169],
                [104.0068935, 10.4769844],
                [104.0066725, 10.4770907],
                [104.0062801, 10.4772719],
                [104.0058847, 10.4774469],
                [104.0054867, 10.4776158],
                [104.0050859, 10.4777784],
                [104.0046827, 10.4779347],
                [104.004277, 10.4780847],
                [104.0038689, 10.4782283],
                [104.0034586, 10.4783656],
                [104.0030462, 10.4784964],
                [104.0026317, 10.4786207],
                [104.0022153, 10.4787385],
                [104.0017971, 10.4788498],
                [104.0013771, 10.4789546],
                [104.0009556, 10.4790527],
                [104.0005325, 10.4791443],
                [104.000108, 10.4792292],
                [103.9996822, 10.4793075],
                [103.9992552, 10.4793791],
                [103.9988271, 10.4794441],
                [103.998398, 10.4795023],
                [103.997968, 10.4795538],
                [103.9975373, 10.4795986],
                [103.9971059, 10.4796366],
                [103.996674, 10.4796679],
                [103.9962416, 10.4796925],
                [103.9958089, 10.4797102],
                [103.995376, 10.4797212],
                [103.9949429, 10.4797254],
                [103.9945098, 10.4797229],
                [103.9940768, 10.4797136],
                [103.9936441, 10.4796975],
                [103.9932116, 10.4796746],
                [103.9927795, 10.479645],
                [103.992348, 10.4796086],
                [103.9919171, 10.4795655],
                [103.991487, 10.4795157],
                [103.9910577, 10.4794591],
                [103.9906293, 10.4793958],
                [103.990202, 10.4793258],
                [103.9897759, 10.4792492],
                [103.9893511, 10.4791659],
                [103.9889277, 10.479076],
                [103.9885057, 10.4789794],
                [103.9880853, 10.4788763],
                [103.9876667, 10.4787666],
                [103.9872498, 10.4786504],
                [103.9868348, 10.4785277],
                [103.9864219, 10.4783985],
                [103.9860111, 10.4782628],
                [103.9856025, 10.4781208],
                [103.9851962, 10.4779724],
                [103.9847923, 10.4778176],
                [103.9843909, 10.4776565],
                [103.9839922, 10.4774892],
                [103.9835962, 10.4773157],
                [103.983203, 10.477136],
                [103.9830977, 10.4770859],
                [103.9827557, 10.4770584],
                [103.9823255, 10.477017],
                [103.9818961, 10.4769689],
                [103.9814674, 10.4769141],
                [103.9810397, 10.4768527],
                [103.9806131, 10.4767845],
                [103.9801875, 10.4767097],
                [103.9797632, 10.4766283],
                [103.9793403, 10.4765403],
                [103.9789188, 10.4764457],
                [103.9784989, 10.4763445],
                [103.9780806, 10.4762368],
                [103.9776641, 10.4761225],
                [103.9772495, 10.4760018],
                [103.9768368, 10.4758746],
                [103.9764263, 10.475741],
                [103.9760179, 10.475601],
                [103.9756117, 10.4754547],
                [103.975208, 10.475302],
                [103.9748068, 10.4751431],
                [103.9740439, 10.4748339],
                [103.9736456, 10.4746689],
                [103.97325, 10.4744977],
                [103.9728572, 10.4743203],
                [103.9724672, 10.4741368],
                [103.9720802, 10.4739473],
                [103.9716963, 10.4737518],
                [103.9713155, 10.4735502],
                [103.970938, 10.4733428],
                [103.9707283, 10.4732233],
                [103.9705375, 10.4731405],
                [103.9701495, 10.4729651],
                [103.9697644, 10.4727837],
                [103.9693821, 10.4725964],
                [103.9690029, 10.4724032],
                [103.9686267, 10.4722042],
                [103.9682537, 10.4719994],
                [103.967884, 10.4717889],
                [103.9675176, 10.4715728],
                [103.9671547, 10.471351],
                [103.9667953, 10.4711237],
                [103.9664395, 10.4708908],
                [103.9660874, 10.4706525],
                [103.9657391, 10.4704088],
                [103.9653947, 10.4701598],
                [103.9650543, 10.4699055],
                [103.9647178, 10.469646],
                [103.9644652, 10.4694448],
                [103.9641619, 10.4692328],
                [103.9638135, 10.468981],
                [103.9634691, 10.4687239],
                [103.9631289, 10.4684615],
                [103.9627929, 10.4681937],
                [103.9624612, 10.4679208],
                [103.9621339, 10.4676427],
                [103.961811, 10.4673596],
                [103.9614927, 10.4670715],
                [103.961179, 10.4667784],
                [103.96087, 10.4664805],
                [103.9605658, 10.4661779],
                [103.9602664, 10.4658705],
                [103.9601479, 10.465745],
                [103.959885, 10.4655503],
                [103.9595485, 10.465293],
                [103.959216, 10.4650306],
                [103.9588877, 10.4647631],
                [103.9585636, 10.4644906],
                [103.9582439, 10.4642131],
                [103.9579285, 10.4639308],
                [103.9576176, 10.4636436],
                [103.9573112, 10.4633518],
                [103.9570094, 10.4630552],
                [103.9567124, 10.4627541],
                [103.9564201, 10.4624484],
                [103.9561326, 10.4621383],
                [103.95585, 10.4618238],
                [103.9555723, 10.461505],
                [103.9552997, 10.4611819],
                [103.9550322, 10.4608548],
                [103.9547699, 10.4605235],
                [103.9545128, 10.4601883],
                [103.9542609, 10.4598491],
                [103.9540145, 10.4595062],
                [103.9538233, 10.4592312],
                [103.9538094, 10.4592174],
                [103.9535127, 10.4589143],
                [103.9532208, 10.4586067],
                [103.9529338, 10.4582946],
                [103.9526517, 10.4579782],
                [103.9523746, 10.4576574],
                [103.9521026, 10.4573324],
                [103.9518358, 10.4570033],
                [103.9515741, 10.4566701],
                [103.9513178, 10.4563329],
                [103.9510667, 10.4559918],
                [103.9508211, 10.4556468],
                [103.9505809, 10.4552982],
                [103.9503462, 10.4549458],
                [103.9498473, 10.4541841],
                [103.9496143, 10.4538221],
                [103.9493871, 10.4534565],
                [103.9491658, 10.4530874],
                [103.9489505, 10.4527148],
                [103.9487411, 10.452339],
                [103.9485377, 10.4519599],
                [103.9483405, 10.4515776],
                [103.9481493, 10.4511923],
                [103.9479644, 10.4508041],
                [103.9477857, 10.4504131],
                [103.9476132, 10.4500192],
                [103.9474471, 10.4496228],
                [103.9472873, 10.4492237],
                [103.9471339, 10.4488223],
                [103.946987, 10.4484184],
                [103.9468465, 10.4480124],
                [103.9467126, 10.4476041],
                [103.9465851, 10.4471939],
                [103.9464643, 10.4467816],
                [103.9464189, 10.4466172],
                [103.946353, 10.4464499],
                [103.9462031, 10.446051],
                [103.9460595, 10.4456498],
                [103.9459223, 10.4452464],
                [103.9457915, 10.444841],
                [103.9456672, 10.4444336],
                [103.9455493, 10.4440243],
                [103.9454379, 10.4436132],
                [103.9453331, 10.4432004],
                [103.9452348, 10.4427861],
                [103.9451431, 10.4423703],
                [103.945058, 10.4419531],
                [103.9449795, 10.4415346],
                [103.9449077, 10.441115],
                [103.9448425, 10.4406943],
                [103.944784, 10.4402727],
                [103.9447322, 10.4398502],
                [103.9446871, 10.4394269],
                [103.9446487, 10.439003],
                [103.944617, 10.4385786],
                [103.9445921, 10.4381537],
                [103.9445739, 10.4377284],
                [103.9445624, 10.437303],
                [103.9445577, 10.4368774],
                [103.944544, 10.4325763],
                [103.9445145, 10.4311783],
                [103.9441981, 10.4310765],
                [103.9437929, 10.4309393],
                [103.94339, 10.4307957],
                [103.9429893, 10.430646],
                [103.942591, 10.4304901],
                [103.9421953, 10.4303281],
                [103.9418022, 10.4301599],
                [103.9414117, 10.4299857],
                [103.9410241, 10.4298055],
                [103.9406394, 10.4296193],
                [103.9402576, 10.4294271],
                [103.9399195, 10.4292503],
                [103.9395917, 10.4291207],
                [103.9391903, 10.4289547],
                [103.9387916, 10.4287823],
                [103.9383957, 10.4286037],
                [103.9380027, 10.4284189],
                [103.9376127, 10.428228],
                [103.9372258, 10.4280309],
                [103.9368422, 10.4278278],
                [103.9364618, 10.4276187],
                [103.9360849, 10.4274036],
                [103.9357114, 10.4271827],
                [103.9353416, 10.4269559],
                [103.9347227, 10.4265696],
                [103.9343662, 10.4263433],
                [103.9340132, 10.4261115],
                [103.9336639, 10.4258743],
                [103.9333184, 10.4256319],
                [103.9329767, 10.4253841],
                [103.9326389, 10.4251312],
                [103.932305, 10.4248731],
                [103.9320141, 10.424641],
                [103.9320107, 10.4246386],
                [103.9316601, 10.4243882],
                [103.9313136, 10.4241324],
                [103.9309712, 10.4238713],
                [103.930633, 10.4236048],
                [103.9302991, 10.423333],
                [103.9299696, 10.4230561],
                [103.9296445, 10.4227741],
                [103.929324, 10.422487],
                [103.9290081, 10.422195],
                [103.9286968, 10.4218981],
                [103.9283904, 10.4215963],
                [103.9280888, 10.4212898],
                [103.9277921, 10.4209786],
                [103.9275004, 10.4206629],
                [103.9264556, 10.4195138],
                [103.9261743, 10.4191995],
                [103.9258979, 10.418881],
                [103.9256265, 10.4185582],
                [103.9253602, 10.4182314],
                [103.9250991, 10.4179005],
                [103.9248432, 10.4175656],
                [103.9245925, 10.4172269],
                [103.9243471, 10.4168843],
                [103.9241072, 10.4165381],
                [103.9238727, 10.4161882],
                [103.9236437, 10.4158347],
                [103.9234202, 10.4154778],
                [103.9232023, 10.4151175],
                [103.9229902, 10.4147539],
                [103.9227837, 10.4143871],
                [103.922583, 10.4140172],
                [103.922388, 10.4136442],
                [103.922199, 10.4132683],
                [103.9220158, 10.4128896],
                [103.9218386, 10.4125081],
                [103.9216674, 10.4121239],
                [103.9215022, 10.4117372],
                [103.9214098, 10.4115113],
                [103.9212824, 10.4112749],
                [103.9210872, 10.4108991],
                [103.9208981, 10.4105203],
                [103.9207149, 10.4101387],
                [103.9205377, 10.4097542],
                [103.9203666, 10.4093671],
                [103.9202016, 10.4089774],
                [103.9200428, 10.4085853],
                [103.9198902, 10.4081907],
                [103.9197437, 10.4077938],
                [103.9196036, 10.4073947],
                [103.9194697, 10.4069934],
                [103.9193422, 10.4065902],
                [103.919221, 10.4061851],
                [103.9191062, 10.4057781],
                [103.9189979, 10.4053694],
                [103.918896, 10.4049591],
                [103.9188005, 10.4045473],
                [103.9187116, 10.404134],
                [103.9186291, 10.4037194],
                [103.9185532, 10.4033036],
                [103.9169745, 10.3942546],
                [103.9169007, 10.3938097],
                [103.9168344, 10.3933637],
                [103.9167756, 10.3929166],
                [103.9167243, 10.3924687],
                [103.9166806, 10.3920199],
                [103.9166755, 10.3919572],
                [103.9165279, 10.3913629],
                [103.9164664, 10.3911153],
                [103.9163237, 10.3908068],
                [103.9158112, 10.3899774],
                [103.9157737, 10.3899828],
                [103.9153461, 10.3900375],
                [103.9149494, 10.390082],
                [103.9147511, 10.3901178],
                [103.9143272, 10.3901875],
                [103.9141591, 10.3902124],
                [103.9123104, 10.3907478],
                [103.9118984, 10.3908637],
                [103.9114846, 10.3909732],
                [103.9110691, 10.3910762],
                [103.910652, 10.3911729],
                [103.9102335, 10.391263],
                [103.9098136, 10.3913467],
                [103.9093924, 10.3914238],
                [103.9089701, 10.3914945],
                [103.9085467, 10.3915585],
                [103.9081223, 10.3916161],
                [103.9076971, 10.391667],
                [103.907342, 10.391704],
                [103.9071425, 10.3918101],
                [103.9067629, 10.3920081],
                [103.9063802, 10.3922003],
                [103.9059945, 10.3923865],
                [103.905606, 10.3925668],
                [103.9052146, 10.3927409],
                [103.9048205, 10.392909],
                [103.9044238, 10.393071],
                [103.9040246, 10.3932267],
                [103.9036229, 10.3933763],
                [103.903219, 10.3935196],
                [103.9028128, 10.3936567],
                [103.9024045, 10.3937874],
                [103.9019942, 10.3939118],
                [103.901582, 10.3940298],
                [103.9012824, 10.3941105],
                [103.9010736, 10.3942123],
                [103.9006817, 10.3943958],
                [103.9002868, 10.394573],
                [103.8998891, 10.3947441],
                [103.8994888, 10.394909],
                [103.8990859, 10.3950675],
                [103.8986805, 10.3952197],
                [103.8982727, 10.3953655],
                [103.8978626, 10.395505],
                [103.8974503, 10.395638],
                [103.897036, 10.3957645],
                [103.8966197, 10.3958845],
                [103.8962016, 10.395998],
                [103.8957817, 10.3961049],
                [103.8953601, 10.3962052],
                [103.8949369, 10.3962989],
                [103.8945124, 10.3963859],
                [103.8940865, 10.3964663],
                [103.8936593, 10.3965401],
                [103.8932311, 10.3966071],
                [103.8928018, 10.3966674],
                [103.8923716, 10.3967209],
                [103.8919406, 10.3967678],
                [103.8915089, 10.3968078],
                [103.8913942, 10.3968166],
                [103.8912584, 10.3968648],
                [103.8908552, 10.3970009],
                [103.8895305, 10.3974366],
                [103.8891267, 10.397566],
                [103.888721, 10.3976892],
                [103.8883134, 10.3978061],
                [103.887904, 10.3979168],
                [103.8874929, 10.3980212],
                [103.8870802, 10.3981192],
                [103.886666, 10.398211],
                [103.8862505, 10.3982963],
                [103.8858337, 10.3983753],
                [103.8854157, 10.3984479],
                [103.8849966, 10.3985141],
                [103.8845765, 10.3985738],
                [103.8841555, 10.3986271],
                [103.8837338, 10.3986739],
                [103.8833113, 10.3987142],
                [103.8828883, 10.3987481],
                [103.8824648, 10.3987755],
                [103.882041, 10.3987964],
                [103.8816168, 10.3988107],
                [103.8811925, 10.3988186],
                [103.8807681, 10.39882],
                [103.8803438, 10.3988148],
                [103.8799195, 10.3988032],
                [103.8794955, 10.398785],
                [103.8790719, 10.3987604],
                [103.8786487, 10.3987292],
                [103.878226, 10.3986916],
                [103.8780928, 10.3986777],
                [103.8779316, 10.3987412],
                [103.8775253, 10.3988938],
                [103.8771167, 10.39904],
                [103.8767057, 10.3991798],
                [103.8762926, 10.3993132],
                [103.8758774, 10.39944],
                [103.8754602, 10.3995603],
                [103.8750411, 10.399674],
                [103.8746203, 10.3997812],
                [103.8741978, 10.3998817],
                [103.8737737, 10.3999756],
                [103.8733482, 10.4000628],
                [103.8729214, 10.4001433],
                [103.8724933, 10.4002171],
                [103.8720641, 10.4002842],
                [103.8716338, 10.4003445],
                [103.8712027, 10.4003981],
                [103.8707707, 10.4004448],
                [103.8703381, 10.4004849],
                [103.8699048, 10.4005181],
                [103.8694711, 10.4005445],
                [103.869037, 10.400564],
                [103.8688113, 10.4005707],
                [103.8687042, 10.4006113],
                [103.8682986, 10.4007581],
                [103.8678908, 10.4008984],
                [103.8674807, 10.4010324],
                [103.8670686, 10.40116],
                [103.8666545, 10.4012812],
                [103.8662385, 10.4013958],
                [103.8658207, 10.401504],
                [103.8654013, 10.4016057],
                [103.8649803, 10.4017007],
                [103.8645578, 10.4017892],
                [103.8641358, 10.4018708],
                [103.8637569, 10.4020512],
                [103.8633577, 10.4022335],
                [103.8629557, 10.4024094],
                [103.8625508, 10.402579],
                [103.8621433, 10.402742],
                [103.8619997, 10.4027981],
                [103.8615963, 10.4029522],
                [103.8611904, 10.4031],
                [103.8607822, 10.4032414],
                [103.8603718, 10.4033765],
                [103.8599593, 10.4035051],
                [103.8595448, 10.4036272],
                [103.8591284, 10.4037428],
                [103.8587102, 10.403852],
                [103.8582904, 10.4039545],
                [103.8578689, 10.4040505],
                [103.857446, 10.4041399],
                [103.8570217, 10.4042227],
                [103.8565961, 10.4042989],
                [103.8561694, 10.4043683],
                [103.8557416, 10.4044311],
                [103.8553129, 10.4044873],
                [103.8548833, 10.4045367],
                [103.854453, 10.4045794],
                [103.854022, 10.4046153],
                [103.8535906, 10.4046446],
                [103.8531587, 10.404667],
                [103.8527265, 10.4046828],
                [103.8522942, 10.4046918],
                [103.8518617, 10.404694],
                [103.8514293, 10.4046894],
                [103.850997, 10.4046781],
                [103.8505649, 10.4046601],
                [103.8501331, 10.4046353],
                [103.8497018, 10.4046038],
                [103.8492711, 10.4045655],
                [103.848841, 10.4045205],
                [103.8484117, 10.4044688],
                [103.8479833, 10.4044104],
                [103.8475559, 10.4043453],
                [103.8471295, 10.4042735],
                [103.847023, 10.4042538],
                [103.8467334, 10.4043049],
                [103.8463116, 10.4043725],
                [103.8458888, 10.4044336],
                [103.8454651, 10.4044882],
                [103.8450406, 10.4045363],
                [103.8446154, 10.4045778],
                [103.8442355, 10.4046089],
                [103.8440861, 10.4046403],
                [103.843664, 10.4047223],
                [103.8432407, 10.4047978],
                [103.8428163, 10.4048666],
                [103.8423908, 10.4049289],
                [103.8419643, 10.4049845],
                [103.8415371, 10.4050335],
                [103.8411091, 10.4050758],
                [103.8406805, 10.4051115],
                [103.8402513, 10.4051405],
                [103.8398218, 10.4051629],
                [103.839392, 10.4051786],
                [103.8390342, 10.405186],
                [103.838962, 10.4051876],
                [103.8385319, 10.4051899],
                [103.8381018, 10.4051855],
                [103.8376718, 10.4051744],
                [103.837242, 10.4051567],
                [103.8368126, 10.4051323],
                [103.8363837, 10.4051012],
                [103.8359552, 10.4050634],
                [103.8355275, 10.405019],
                [103.8351004, 10.404968],
                [103.8333611, 10.4047464],
                [103.8329384, 10.4046892],
                [103.8325166, 10.4046255],
                [103.8320959, 10.4045553],
                [103.8316764, 10.4044787],
                [103.8312581, 10.4043956],
                [103.8308412, 10.404306],
                [103.8304257, 10.40421],
                [103.8300118, 10.4041077],
                [103.8295995, 10.4039989],
                [103.829189, 10.4038839],
                [103.8287804, 10.4037625],
                [103.8283737, 10.4036348],
                [103.8279691, 10.4035009],
                [103.8275667, 10.4033608],
                [103.8271664, 10.4032144],
                [103.8267686, 10.403062],
                [103.8263732, 10.4029034],
                [103.8259803, 10.4027387],
                [103.8255901, 10.402568],
                [103.8252026, 10.4023913],
                [103.8248179, 10.4022087],
                [103.8244362, 10.4020201],
                [103.8240574, 10.4018257],
                [103.8236818, 10.4016255],
                [103.8233093, 10.4014195],
                [103.8229402, 10.4012078],
                [103.8225744, 10.4009904],
                [103.8222121, 10.4007674],
                [103.8218533, 10.4005389],
                [103.8214982, 10.4003048],
                [103.8211468, 10.4000653],
                [103.8207992, 10.3998204],
                [103.8204555, 10.3995702],
                [103.8199834, 10.3992209],
                [103.8196418, 10.398964],
                [103.8193044, 10.3987019],
                [103.8189711, 10.3984346],
                [103.8186421, 10.3981621],
                [103.8183174, 10.3978846],
                [103.8179972, 10.397602],
                [103.8176815, 10.3973146],
                [103.8173704, 10.3970223],
                [103.8170639, 10.3967252],
                [103.8167622, 10.3964233],
                [103.8164653, 10.3961169],
                [103.8161732, 10.3958059],
                [103.8158861, 10.3954904],
                [103.8156041, 10.3951705],
                [103.8153271, 10.3948462],
                [103.8150553, 10.3945177],
                [103.8147887, 10.394185],
                [103.8145274, 10.3938483],
                [103.8142715, 10.3935075],
                [103.814021, 10.3931628],
                [103.813776, 10.3928143],
                [103.8135365, 10.392462],
                [103.8133026, 10.392106],
                [103.8130744, 10.3917465],
                [103.8128519, 10.3913835],
                [103.8126351, 10.391017],
                [103.8124242, 10.3906473],
                [103.8122191, 10.3902743],
                [103.81202, 10.3898982],
                [103.8118268, 10.3895191],
                [103.8116397, 10.389137],
                [103.8114586, 10.388752],
                [103.8112836, 10.3883643],
                [103.8111148, 10.387974],
                [103.8109522, 10.387581],
                [103.8107958, 10.3871856],
                [103.8106457, 10.3867878],
                [103.8105019, 10.3863878],
                [103.8103644, 10.3859855],
                [103.8102333, 10.3855812],
                [103.8101086, 10.3851749],
                [103.8099904, 10.3847667],
                [103.8098786, 10.3843568],
                [103.8097733, 10.3839451],
                [103.8096746, 10.3835319],
                [103.8095824, 10.3831172],
                [103.8094967, 10.3827011],
                [103.8094177, 10.3822837],
                [103.8093452, 10.3818652],
                [103.8092794, 10.3814456],
                [103.8092202, 10.381025],
                [103.8091677, 10.3806035],
                [103.8091218, 10.3801813],
                [103.8090827, 10.3797585],
                [103.8090502, 10.379335],
                [103.8090321, 10.3790721],
                [103.8090288, 10.3790183],
                [103.8088379, 10.3787001],
                [103.8086233, 10.3783291],
                [103.8084145, 10.3779548],
                [103.8082118, 10.3775772],
                [103.808015, 10.3771966],
                [103.8078244, 10.3768129],
                [103.8076399, 10.3764263],
                [103.8074616, 10.3760369],
                [103.8072895, 10.3756447],
                [103.8071236, 10.3752499],
                [103.8069641, 10.3748526],
                [103.8068109, 10.3744528],
                [103.8066641, 10.3740507],
                [103.8065237, 10.3736463],
                [103.8063898, 10.3732398],
                [103.8062624, 10.3728313],
                [103.8061414, 10.3724208],
                [103.8060271, 10.3720085],
                [103.8059193, 10.3715945],
                [103.8058181, 10.3711788],
                [103.8057235, 10.3707616],
                [103.8056356, 10.3703429],
                [103.8055544, 10.369923],
                [103.8054799, 10.3695018],
                [103.805412, 10.3690796],
                [103.8053509, 10.3686563],
                [103.8052966, 10.3682321],
                [103.805249, 10.3678071],
                [103.8052082, 10.3673814],
                [103.8051742, 10.3669552],
                [103.8051469, 10.3665285],
                [103.8051265, 10.3661014],
                [103.8051129, 10.365674],
                [103.805106, 10.3652465],
                [103.805106, 10.3648189],
                [103.8051128, 10.3643914],
                [103.8051263, 10.363964],
                [103.8051467, 10.3635369],
                [103.8051739, 10.3631102],
                [103.8052079, 10.3626839],
                [103.8052486, 10.3622582],
                [103.8052961, 10.3618332],
                [103.8053504, 10.361409],
                [103.8053813, 10.3611952],
                [103.805386, 10.3610947],
                [103.805413, 10.3606676],
                [103.8054469, 10.3602409],
                [103.8054875, 10.3598148],
                [103.8055349, 10.3593893],
                [103.8055891, 10.3589647],
                [103.80565, 10.358541],
                [103.8057177, 10.3581182],
                [103.8057921, 10.3576966],
                [103.8058733, 10.3572762],
                [103.8059611, 10.3568571],
                [103.8060556, 10.3564395],
                [103.8061567, 10.3560233],
                [103.8062644, 10.3556088],
                [103.8063788, 10.355196],
                [103.8064997, 10.3547851],
                [103.8066271, 10.3543761],
                [103.806761, 10.3539692],
                [103.8069014, 10.3535644],
                [103.8070482, 10.3531618],
                [103.8072015, 10.3527616],
                [103.807361, 10.3523638],
                [103.8075269, 10.3519686],
                [103.8076991, 10.351576],
                [103.8078775, 10.3511861],
                [103.8080621, 10.3507991],
                [103.8082529, 10.350415],
                [103.8084497, 10.350034],
                [103.8086526, 10.349656],
                [103.8088615, 10.3492813],
                [103.8090763, 10.3489099],
                [103.809297, 10.3485419],
                [103.8095236, 10.3481774],
                [103.8097559, 10.3478164],
                [103.809994, 10.3474592],
                [103.8102377, 10.3471057],
                [103.810487, 10.346756],
                [103.8107419, 10.3464103],
                [103.8110022, 10.3460686],
                [103.811268, 10.3457311],
                [103.8115391, 10.3453977],
                [103.8118154, 10.3450686],
                [103.812097, 10.3447438],
                [103.8123837, 10.3444235],
                [103.8126755, 10.3441077],
                [103.8129723, 10.3437966],
                [103.813274, 10.34349],
                [103.8135252, 10.3432428],
                [103.8137828, 10.3429548],
                [103.8140719, 10.3426415],
                [103.814366, 10.3423328],
                [103.8146649, 10.3420287],
                [103.8149685, 10.3417292],
                [103.8152769, 10.3414344],
                [103.8155899, 10.3411445],
                [103.8159074, 10.3408595],
                [103.8162294, 10.3405794],
                [103.8165557, 10.3403043],
                [103.8168864, 10.3400343],
                [103.8172213, 10.3397695],
                [103.8175603, 10.3395099],
                [103.8179034, 10.3392556],
                [103.8182505, 10.3390066],
                [103.8186015, 10.338763],
                [103.8189563, 10.3385249],
                [103.8193147, 10.3382923],
                [103.8196769, 10.3380653],
                [103.8200425, 10.337844],
                [103.8201278, 10.3377941],
                [103.8220145, 10.3308794],
                [103.8226418, 10.3284858],
                [103.8227918, 10.3275392],
                [103.8227956, 10.3274782],
                [103.8228278, 10.3270567],
                [103.8228668, 10.3266357],
                [103.8229123, 10.3262154],
                [103.8229645, 10.3257958],
                [103.8230232, 10.3253771],
                [103.8230886, 10.3249593],
                [103.8231605, 10.3245426],
                [103.8232389, 10.324127],
                [103.8237128, 10.3217207],
                [103.8237963, 10.3213132],
                [103.8238861, 10.3209071],
                [103.8239822, 10.3205024],
                [103.8240845, 10.3200992],
                [103.8241931, 10.3196976],
                [103.8243079, 10.3192977],
                [103.8244288, 10.3188996],
                [103.8245559, 10.3185033],
                [103.8245982, 10.3183748],
                [103.8248754, 10.3174119],
                [103.8250002, 10.3169912],
                [103.8251319, 10.3165725],
                [103.8252704, 10.3161559],
                [103.8254157, 10.3157417],
                [103.8255678, 10.3153298],
                [103.8257265, 10.3149204],
                [103.8258919, 10.3145136],
                [103.8260639, 10.3141095],
                [103.8262425, 10.3137082],
                [103.8271385, 10.3117382],
                [103.8273198, 10.3113478],
                [103.8275074, 10.3109604],
                [103.8277011, 10.3105759],
                [103.8279009, 10.3101945],
                [103.8283373, 10.3093776],
                [103.8283425, 10.3089836],
                [103.828355, 10.3085586],
                [103.8283741, 10.3081338],
                [103.8284, 10.3077093],
                [103.8284326, 10.3072853],
                [103.8284719, 10.3068619],
                [103.8285179, 10.3064391],
                [103.8285705, 10.3060171],
                [103.8286299, 10.3055959],
                [103.8286959, 10.3051758],
                [103.8287686, 10.3047567],
                [103.8288478, 10.3043388],
                [103.8289337, 10.3039221],
                [103.8290262, 10.3035069],
                [103.8291252, 10.3030931],
                [103.8292308, 10.3026809],
                [103.8293428, 10.3022704],
                [103.8294614, 10.3018617],
                [103.8295864, 10.3014549],
                [103.8298287, 10.3006881],
                [103.8299184, 10.3004042],
                [103.8300488, 10.3000023],
                [103.8301855, 10.2996025],
                [103.8303285, 10.2992048],
                [103.8304777, 10.2988094],
                [103.8306331, 10.2984163],
                [103.8307947, 10.2980257],
                [103.8309624, 10.2976376],
                [103.8311361, 10.2972521],
                [103.8313159, 10.2968694],
                [103.8315017, 10.2964895],
                [103.8316934, 10.2961125],
                [103.831891, 10.2957385],
                [103.8320945, 10.2953675],
                [103.8323038, 10.2949998],
                [103.8325188, 10.2946353],
                [103.8327396, 10.2942742],
                [103.832966, 10.2939166],
                [103.8331979, 10.2935624],
                [103.8334354, 10.2932119],
                [103.8336784, 10.2928651],
                [103.8339268, 10.2925221],
                [103.8341806, 10.2921829],
                [103.8344397, 10.2918477],
                [103.834704, 10.2915165],
                [103.8349734, 10.2911894],
                [103.835248, 10.2908666],
                [103.8355276, 10.290548],
                [103.8358122, 10.2902337],
                [103.8362813, 10.2897237],
                [103.8365638, 10.2894215],
                [103.8367342, 10.2891269],
                [103.8369518, 10.2887637],
                [103.8371751, 10.288404],
                [103.8374041, 10.2880478],
                [103.8376386, 10.2876951],
                [103.8378787, 10.2873461],
                [103.8381242, 10.2870008],
                [103.8386287, 10.2863028],
                [103.838878, 10.2859635],
                [103.8391327, 10.2856279],
                [103.8393925, 10.2852964],
                [103.8396575, 10.2849688],
                [103.8399275, 10.2846454],
                [103.8402026, 10.2843261],
                [103.8404826, 10.284011],
                [103.8407675, 10.2837004],
                [103.8410573, 10.2833941],
                [103.8413518, 10.2830922],
                [103.8416509, 10.282795],
                [103.8419547, 10.2825023],
                [103.842263, 10.2822143],
                [103.8425758, 10.2819311],
                [103.842893, 10.2816528],
                [103.8432144, 10.2813793],
                [103.8435401, 10.2811107],
                [103.84387, 10.2808472],
                [103.8442039, 10.2805888],
                [103.8445419, 10.2803355],
                [103.8448786, 10.2800872],
                [103.8452253, 10.2798356],
                [103.845576, 10.2795895],
                [103.8459305, 10.2793489],
                [103.8462887, 10.2791138],
                [103.8466507, 10.2788843],
                [103.8470162, 10.2786604],
                [103.8473852, 10.2784423],
                [103.8477577, 10.2782299],
                [103.8481334, 10.2780233],
                [103.8485124, 10.2778226],
                [103.8488945, 10.2776278],
                [103.8492797, 10.277439],
                [103.8496678, 10.2772562],
                [103.8500588, 10.2770794],
                [103.8504525, 10.2769087],
                [103.8508488, 10.2767442],
                [103.8512477, 10.2765858],
                [103.8516491, 10.2764337],
                [103.8520528, 10.2762878],
                [103.8524588, 10.2761482],
                [103.852867, 10.2760149],
                [103.8532772, 10.275888],
                [103.8536894, 10.2757675],
                [103.8541034, 10.2756533],
                [103.8545192, 10.2755457],
                [103.8549367, 10.2754445],
                [103.8553556, 10.2753498],
                [103.8557761, 10.2752616],
                [103.8561979, 10.27518],
                [103.8565953, 10.2751063],
                [103.8570166, 10.2750315],
                [103.8574392, 10.2749633],
                [103.8578627, 10.2749016],
                [103.8582872, 10.2748464],
                [103.8587124, 10.2747979],
                [103.8591384, 10.2747559],
                [103.859565, 10.2747205],
                [103.8599921, 10.2746918],
                [103.8604197, 10.2746696],
                [103.8608475, 10.2746541],
                [103.8612755, 10.2746451],
                [103.8617036, 10.2746428],
                [103.8621316, 10.2746472],
                [103.8625596, 10.2746581],
                [103.8629873, 10.2746757],
                [103.8634147, 10.2746999],
                [103.8638417, 10.2747307],
                [103.8642681, 10.2747682],
                [103.8646939, 10.2748122],
                [103.8651189, 10.2748628],
                [103.8655431, 10.2749199],
                [103.8659664, 10.2749837],
                [103.8663885, 10.2750539],
                [103.8668096, 10.2751307],
                [103.8668119, 10.2751312],
                [103.8670046, 10.2748871],
                [103.8672763, 10.2745541],
                [103.8675533, 10.2742253],
                [103.8678355, 10.2739009],
                [103.8681228, 10.2735809],
                [103.8684152, 10.2732655],
                [103.8687125, 10.2729547],
                [103.8690148, 10.2726485],
                [103.8693219, 10.2723472],
                [103.8696338, 10.2720506],
                [103.8699504, 10.271759],
                [103.8702715, 10.2714724],
                [103.8705972, 10.2711908],
                [103.8709274, 10.2709143],
                [103.8712619, 10.2706431],
                [103.8716007, 10.2703771],
                [103.8719437, 10.2701164],
                [103.8722908, 10.2698611],
                [103.8726419, 10.2696113],
                [103.872997, 10.269367],
                [103.8733559, 10.2691283],
                [103.8737185, 10.2688952],
                [103.8740849, 10.2686678],
                [103.8744548, 10.2684462],
                [103.8748282, 10.2682304],
                [103.875205, 10.2680204],
                [103.8755851, 10.2678164],
                [103.8759684, 10.2676183],
                [103.8763548, 10.2674263],
                [103.8767442, 10.2672403],
                [103.8771365, 10.2670604],
                [103.8775317, 10.2668867],
                [103.8779295, 10.2667191],
                [103.87833, 10.2665578],
                [103.878733, 10.2664028],
                [103.879, 10.2663049],
                [103.8791899, 10.2661988],
                [103.8795656, 10.2659966],
                [103.8799443, 10.2658002],
                [103.8803261, 10.2656097],
                [103.8807109, 10.2654251],
                [103.8810985, 10.2652464],
                [103.8814889, 10.2650738],
                [103.8818819, 10.2649072],
                [103.8822776, 10.2647468],
                [103.8826757, 10.2645924],
                [103.8830762, 10.2644443],
                [103.883479, 10.2643023],
                [103.883884, 10.2641666],
                [103.884291, 10.2640372],
                [103.8847001, 10.2639141],
                [103.885111, 10.2637973],
                [103.8855237, 10.2636869],
                [103.8859382, 10.2635828],
                [103.8863542, 10.2634852],
                [103.8867717, 10.2633941],
                [103.8871906, 10.2633094],
                [103.8876107, 10.2632312],
                [103.8880321, 10.2631594],
                [103.8884545, 10.2630943],
                [103.8888779, 10.2630356],
                [103.8893022, 10.2629835],
                [103.8897272, 10.262938],
                [103.8901529, 10.262899],
                [103.8905792, 10.2628666],
                [103.8910059, 10.2628408],
                [103.891433, 10.2628217],
                [103.8918604, 10.2628091],
                [103.8922878, 10.2628031],
                [103.8927154, 10.2628037],
                [103.8931428, 10.262811],
                [103.8947182, 10.2628499],
                [103.8949306, 10.2628251],
                [103.895357, 10.262782],
                [103.895784, 10.2627456],
                [103.8962115, 10.2627158],
                [103.8966394, 10.2626926],
                [103.8970677, 10.262676],
                [103.8974961, 10.2626661],
                [103.8979247, 10.2626628],
                [103.8983533, 10.2626662],
                [103.8987817, 10.2626762],
                [103.89921, 10.2626928],
                [103.8996379, 10.2627161],
                [103.9000654, 10.262746],
                [103.9003552, 10.2627708],
                [103.9010273, 10.2611755],
                [103.9010584, 10.2610446],
                [103.9011631, 10.2606328],
                [103.9012743, 10.2602226],
                [103.9013919, 10.2598143],
                [103.901516, 10.2594078],
                [103.9016465, 10.2590033],
                [103.9017833, 10.2586008],
                [103.9019265, 10.2582006],
                [103.902076, 10.2578026],
                [103.9022318, 10.2574069],
                [103.9023938, 10.2570138],
                [103.902562, 10.2566231],
                [103.9027364, 10.2562352],
                [103.9029168, 10.25585],
                [103.9031034, 10.2554676],
                [103.9032959, 10.2550882],
                [103.9041025, 10.2535294],
                [103.9043067, 10.2531425],
                [103.9045171, 10.2527589],
                [103.9047338, 10.2523787],
                [103.9049565, 10.252002],
                [103.9053165, 10.2514045],
                [103.90549, 10.2510471],
                [103.905683, 10.2506649],
                [103.9058821, 10.2502858],
                [103.9060873, 10.2499099],
                [103.9062983, 10.2495372],
                [103.9065153, 10.2491679],
                [103.9067381, 10.248802],
                [103.9069667, 10.2484396],
                [103.9072011, 10.2480808],
                [103.9074411, 10.2477257],
                [103.9076867, 10.2473744],
                [103.9079379, 10.247027],
                [103.9081946, 10.2466835],
                [103.9084567, 10.2463441],
                [103.9087242, 10.2460088],
                [103.908997, 10.2456777],
                [103.909275, 10.245351],
                [103.9095581, 10.2450286],
                [103.9098464, 10.2447106],
                [103.9101397, 10.2443972],
                [103.9104379, 10.2440884],
                [103.910741, 10.2437843],
                [103.9110489, 10.243485],
                [103.9113615, 10.2431905],
                [103.9116788, 10.2429009],
                [103.9120006, 10.2426163],
                [103.9123269, 10.2423368],
                [103.9126576, 10.2420624],
                [103.9129927, 10.2417932],
                [103.9133319, 10.2415292],
                [103.9136754, 10.2412706],
                [103.9147427, 10.2404798],
                [103.9149989, 10.2402931],
                [103.9150966, 10.2401954],
                [103.9154073, 10.2398941],
                [103.9157229, 10.2395978],
                [103.916039, 10.2393102],
                [103.9160541, 10.2392934],
                [103.916341, 10.2389839],
                [103.9166326, 10.2386788],
                [103.9169291, 10.2383783],
                [103.9172302, 10.2380823],
                [103.9175358, 10.237791],
                [103.917846, 10.2375044],
                [103.9181607, 10.2372226],
                [103.9184797, 10.2369456],
                [103.918803, 10.2366736],
                [103.9191305, 10.2364066],
                [103.9194621, 10.2361446],
                [103.9197744, 10.2359057],
                [103.9199661, 10.2356294],
                [103.9202173, 10.2352737],
                [103.9203102, 10.2351464],
                [103.9203268, 10.2351073],
                [103.9204977, 10.2347215],
                [103.9206746, 10.2343383],
                [103.9208576, 10.233958],
                [103.9210464, 10.2335805],
                [103.9212412, 10.2332059],
                [103.9214418, 10.2328344],
                [103.9216482, 10.232466],
                [103.9218603, 10.2321008],
                [103.9220782, 10.2317389],
                [103.9223017, 10.2313804],
                [103.9225308, 10.2310254],
                [103.9227654, 10.230674],
                [103.9230055, 10.2303262],
                [103.9232511, 10.2299822],
                [103.923502, 10.229642],
                [103.9237582, 10.2293057],
                [103.9240196, 10.2289733],
                [103.9242863, 10.228645],
                [103.9244575, 10.2284408],
                [103.926522, 10.2221413],
                [103.9265138, 10.222054],
                [103.9264806, 10.2216282],
                [103.9264542, 10.221202],
                [103.9264346, 10.2207753],
                [103.9264217, 10.2203484],
                [103.9264156, 10.2199214],
                [103.9264163, 10.2194943],
                [103.9264238, 10.2190673],
                [103.9264381, 10.2186405],
                [103.9264432, 10.2185171],
                [103.9264641, 10.2180927],
                [103.9264918, 10.2176687],
                [103.9265261, 10.2172452],
                [103.9265672, 10.2168223],
                [103.9266149, 10.2164001],
                [103.9266692, 10.2159787],
                [103.9267303, 10.2155582],
                [103.926798, 10.2151386],
                [103.9268723, 10.2147202],
                [103.9269532, 10.214303],
                [103.9269548, 10.2142955],
                [103.9269623, 10.2142447],
                [103.9270316, 10.2138255],
                [103.9271075, 10.2134074],
                [103.92719, 10.2129905],
                [103.9272791, 10.212575],
                [103.9273747, 10.2121609],
                [103.9274769, 10.2117484],
                [103.9275856, 10.2113374],
                [103.9277008, 10.2109282],
                [103.9278224, 10.2105209],
                [103.9279504, 10.2101155],
                [103.9280849, 10.2097121],
                [103.9282256, 10.2093108],
                [103.9283728, 10.2089118],
                [103.9285262, 10.2085151],
                [103.9286858, 10.2081209],
                [103.9288517, 10.2077292],
                [103.9290237, 10.20734],
                [103.9292019, 10.2069536],
                [103.9293862, 10.2065701],
                [103.9295765, 10.2061894],
                [103.9297727, 10.2058117],
                [103.929975, 10.2054371],
                [103.9301831, 10.2050657],
                [103.9303971, 10.2046976],
                [103.9306168, 10.2043328],
                [103.9308424, 10.2039715],
                [103.9310735, 10.2036137],
                [103.9313104, 10.2032596],
                [103.9315527, 10.2029091],
                [103.9318006, 10.2025625],
                [103.932054, 10.2022198],
                [103.9323127, 10.201881],
                [103.9325767, 10.2015463],
                [103.932846, 10.2012157],
                [103.9331205, 10.2008893],
                [103.9334002, 10.2005673],
                [103.9336848, 10.2002496],
                [103.9339745, 10.1999364],
                [103.9341454, 10.1997573],
                [103.9344329, 10.1989719],
                [103.9360806, 10.1924409],
                [103.9367786, 10.1893377],
                [103.9367806, 10.1892567],
                [103.9367978, 10.1888328],
                [103.9368218, 10.1884093],
                [103.9368524, 10.1879861],
                [103.9368896, 10.1875635],
                [103.9369336, 10.1871415],
                [103.9369842, 10.1867202],
                [103.9370414, 10.1862998],
                [103.9371053, 10.1858803],
                [103.9371758, 10.1854618],
                [103.9372528, 10.1850445],
                [103.9373365, 10.1846284],
                [103.9374267, 10.1842137],
                [103.9375235, 10.1838004],
                [103.9376267, 10.1833887],
                [103.9377365, 10.1829786],
                [103.9378527, 10.1825703],
                [103.9379753, 10.1821638],
                [103.9381043, 10.1817593],
                [103.9382397, 10.1813568],
                [103.9383815, 10.1809565],
                [103.9385295, 10.1805584],
                [103.9386838, 10.1801627],
                [103.9388444, 10.1797694],
                [103.9390111, 10.1793786],
                [103.939184, 10.1789905],
                [103.9393629, 10.1786051],
                [103.9394083, 10.1785113],
                [103.939488, 10.1781747],
                [103.9395398, 10.1779463],
                [103.9398276, 10.1766764],
                [103.9398447, 10.1765019],
                [103.9398936, 10.1760721],
                [103.9399495, 10.1756432],
                [103.9400122, 10.1752153],
                [103.9400819, 10.1747884],
                [103.9401584, 10.1743626],
                [103.9402417, 10.1739381],
                [103.9403318, 10.173515],
                [103.9404288, 10.1730934],
                [103.9405325, 10.1726733],
                [103.940643, 10.1722549],
                [103.9407602, 10.1718384],
                [103.940884, 10.1714237],
                [103.9410146, 10.171011],
                [103.9411654, 10.1705472],
                [103.9415608, 10.1689826],
                [103.942332, 10.1654183],
                [103.9423661, 10.1652626],
                [103.9439233, 10.1582654],
                [103.9452066, 10.1519048],
                [103.9452982, 10.1514695],
                [103.9467726, 10.1447373],
                [103.9481656, 10.1371492],
                [103.9482617, 10.1366524],
                [103.950682, 10.1247342],
                [103.9507442, 10.1244362],
                [103.9535025, 10.1115986],
                [103.9535457, 10.1114012],
                [103.9554027, 10.103063],
                [103.9608992, 10.074506],
                [103.0522427, 9.4110961],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [107.3353814, 14.1149751],
                [107.3355827, 14.1139996],
                [107.3362424, 14.1132658],
                [107.3364549, 14.1126584],
                [107.3361428, 14.1072437],
                [107.33685, 14.1065219],
                [107.3374626, 14.1062234],
                [107.3386041, 14.1062699],
                [107.3397103, 14.1067189],
                [107.3408165, 14.1072818],
                [107.3422644, 14.1074778],
                [107.3432765, 14.1074894],
                [107.3445366, 14.1070322],
                [107.3456092, 14.1059432],
                [107.346694, 14.1043029],
                [107.350146, 14.1013802],
                [107.3507818, 14.1011515],
                [107.3513478, 14.1006005],
                [107.3516076, 14.0996944],
                [107.3517262, 14.0985131],
                [107.352033, 14.0980199],
                [107.3531637, 14.0975853],
                [107.3549648, 14.0975858],
                [107.3564369, 14.0969333],
                [107.3575207, 14.0961084],
                [107.3583691, 14.0951108],
                [107.3590295, 14.0938496],
                [107.359643, 14.0922895],
                [107.3606218, 14.0908219],
                [107.3615049, 14.0902835],
                [107.3626828, 14.0895503],
                [107.3632959, 14.0885753],
                [107.3636265, 14.0877612],
                [107.3642503, 14.0872683],
                [107.3651928, 14.0869936],
                [107.3656167, 14.0863051],
                [107.3660536, 14.0854108],
                [107.3667843, 14.0839546],
                [107.3671734, 14.0834844],
                [107.3677006, 14.0833162],
                [107.3682305, 14.0831559],
                [107.3687629, 14.0830037],
                [107.3692976, 14.0828595],
                [107.3698347, 14.0827234],
                [107.3703738, 14.0825955],
                [107.3709149, 14.0824757],
                [107.3714579, 14.0823641],
                [107.3720026, 14.0822607],
                [107.3725681, 14.0821623],
                [107.3731352, 14.0820729],
                [107.3737037, 14.0819922],
                [107.3742735, 14.0819205],
                [107.3748443, 14.0818577],
                [107.3754162, 14.0818038],
                [107.3759889, 14.0817589],
                [107.3765622, 14.081723],
                [107.3771361, 14.081696],
                [107.3777103, 14.0816779],
                [107.3779395, 14.0816441],
                [107.3781672, 14.0816026],
                [107.3783934, 14.0815535],
                [107.3786177, 14.0814968],
                [107.3788399, 14.0814327],
                [107.3790596, 14.0813613],
                [107.3792767, 14.0812825],
                [107.3794198, 14.081226],
                [107.3795616, 14.0811663],
                [107.3798429, 14.0810423],
                [107.3801206, 14.0809106],
                [107.3803943, 14.0807714],
                [107.3806638, 14.0806248],
                [107.380929, 14.0804708],
                [107.3811966, 14.0803051],
                [107.3814592, 14.0801319],
                [107.3817165, 14.0799515],
                [107.3819683, 14.0797638],
                [107.3822143, 14.0795692],
                [107.3824545, 14.0793677],
                [107.3825224, 14.0792866],
                [107.3825847, 14.0792015],
                [107.3826413, 14.0791125],
                [107.3826918, 14.0790203],
                [107.382736, 14.078925],
                [107.3827738, 14.0788272],
                [107.3828075, 14.0787176],
                [107.3828332, 14.078606],
                [107.3829193, 14.0781569],
                [107.3829796, 14.0778194],
                [107.3830314, 14.0774806],
                [107.3830747, 14.0771407],
                [107.3831099, 14.0767969],
                [107.3831364, 14.0764525],
                [107.3831543, 14.0761075],
                [107.3831635, 14.0757622],
                [107.383164, 14.0754167],
                [107.3831559, 14.0750714],
                [107.3831311, 14.0747784],
                [107.3830866, 14.0745102],
                [107.3830257, 14.0742961],
                [107.3829564, 14.0740845],
                [107.3828788, 14.0738756],
                [107.382793, 14.0736697],
                [107.3826992, 14.0734671],
                [107.3825928, 14.0732593],
                [107.3824779, 14.0730559],
                [107.3823547, 14.072857],
                [107.3822234, 14.072663],
                [107.3820843, 14.0724743],
                [107.3819589, 14.0723068],
                [107.3818445, 14.0721003],
                [107.3817585, 14.0719063],
                [107.3816797, 14.0717096],
                [107.3816081, 14.0715102],
                [107.3815438, 14.0713085],
                [107.3814869, 14.0711048],
                [107.3814321, 14.0708748],
                [107.3813867, 14.0706429],
                [107.3813508, 14.0704094],
                [107.3813245, 14.0701747],
                [107.3813077, 14.0699392],
                [107.3813006, 14.0697033],
                [107.3813031, 14.0694673],
                [107.3812938, 14.0691977],
                [107.3812774, 14.0689833],
                [107.3812303, 14.0687367],
                [107.381174, 14.0684918],
                [107.3811086, 14.0682491],
                [107.3810341, 14.0680089],
                [107.3809507, 14.0677715],
                [107.3808583, 14.0675371],
                [107.3807573, 14.0673062],
                [107.3806545, 14.0670924],
                [107.3805441, 14.0668822],
                [107.3804265, 14.0666758],
                [107.3803016, 14.0664733],
                [107.3801696, 14.0662752],
                [107.3800307, 14.0660815],
                [107.3798851, 14.0658926],
                [107.3797329, 14.0657086],
                [107.3795743, 14.0655297],
                [107.3793199, 14.0652326],
                [107.3792226, 14.0651145],
                [107.3791182, 14.0650023],
                [107.3790069, 14.0648964],
                [107.3788892, 14.0647973],
                [107.3787656, 14.0647053],
                [107.3786364, 14.0646207],
                [107.3785022, 14.0645439],
                [107.3783634, 14.0644751],
                [107.3782206, 14.0644146],
                [107.3780742, 14.0643626],
                [107.3779249, 14.0643193],
                [107.3777688, 14.0642841],
                [107.3776107, 14.0642583],
                [107.3774513, 14.0642422],
                [107.3772911, 14.0642357],
                [107.3771309, 14.064239],
                [107.3769712, 14.0642519],
                [107.3768126, 14.0642745],
                [107.3766557, 14.0643066],
                [107.3765013, 14.0643481],
                [107.3763498, 14.0643989],
                [107.3762019, 14.0644588],
                [107.3760581, 14.0645275],
                [107.3757369, 14.064677],
                [107.3754124, 14.0648195],
                [107.3750846, 14.064955],
                [107.3747539, 14.0650833],
                [107.3744203, 14.0652045],
                [107.374084, 14.0653184],
                [107.3737451, 14.0654251],
                [107.3734038, 14.0655245],
                [107.3730603, 14.0656164],
                [107.3727148, 14.0657009],
                [107.3723674, 14.0657779],
                [107.3715186, 14.0659616],
                [107.3706685, 14.0661394],
                [107.3704486, 14.0661818],
                [107.3702272, 14.0662161],
                [107.3700046, 14.0662421],
                [107.3697812, 14.0662599],
                [107.3695572, 14.0662694],
                [107.369333, 14.0662707],
                [107.3691089, 14.0662636],
                [107.3688853, 14.0662482],
                [107.3686624, 14.0662245],
                [107.3684406, 14.0661926],
                [107.3682156, 14.0661516],
                [107.3679925, 14.0661022],
                [107.3677715, 14.0660443],
                [107.367553, 14.0659781],
                [107.3673374, 14.0659037],
                [107.3671249, 14.0658213],
                [107.3669158, 14.0657308],
                [107.3667106, 14.0656325],
                [107.3665201, 14.0655214],
                [107.3663518, 14.0654009],
                [107.3661996, 14.065269],
                [107.3660531, 14.0651312],
                [107.3659126, 14.0649876],
                [107.3657784, 14.0648385],
                [107.3656506, 14.0646842],
                [107.3655294, 14.064525],
                [107.3654151, 14.064361],
                [107.3653068, 14.0641909],
                [107.3652059, 14.0640165],
                [107.3651126, 14.0638383],
                [107.3650269, 14.0636564],
                [107.3649492, 14.0634713],
                [107.3648794, 14.0632832],
                [107.3648178, 14.0630924],
                [107.3647644, 14.0628993],
                [107.3647194, 14.0627042],
                [107.3645579, 14.0610443],
                [107.3650073, 14.0592328],
                [107.3658802, 14.0572027],
                [107.3660104, 14.0559749],
                [107.3657181, 14.0544949],
                [107.3648955, 14.0530833],
                [107.364061, 14.0516379],
                [107.3637797, 14.050605],
                [107.3638637, 14.049206],
                [107.3643481, 14.0469233],
                [107.3648335, 14.0439403],
                [107.3653432, 14.0403507],
                [107.3654987, 14.0379187],
                [107.3656547, 14.0346837],
                [107.3652682, 14.0328715],
                [107.3642808, 14.0316207],
                [107.3630579, 14.0307585],
                [107.3609399, 14.0300812],
                [107.359458, 14.0292882],
                [107.3585167, 14.029207],
                [107.3574118, 14.0278873],
                [107.3573777, 14.0265335],
                [107.3574849, 14.0255352],
                [107.3577332, 14.0241933],
                [107.3578408, 14.0224959],
                [107.3579486, 14.020707],
                [107.3575514, 14.0177699],
                [107.3576581, 14.0170122],
                [107.3579293, 14.0164968],
                [107.358636, 14.0160958],
                [107.3601192, 14.0156149],
                [107.3620493, 14.015168],
                [107.3642627, 14.0141605],
                [107.3655936, 14.0131525],
                [107.366489, 14.0120968],
                [107.3674784, 14.0110086],
                [107.3686681, 14.0096552],
                [107.3695525, 14.0083139],
                [107.3710839, 14.0064569],
                [107.3720969, 14.005138],
                [107.373298, 14.0041177],
                [107.3742172, 14.0026733],
                [107.3745051, 14.001488],
                [107.3862993, 13.9896108],
                [107.3865814, 13.9901038],
                [107.3876733, 13.9903438],
                [107.3882718, 13.9907343],
                [107.3890348, 13.990641],
                [107.3899732, 13.9908],
                [107.390701, 13.9908798],
                [107.3915925, 13.9906948],
                [107.3925194, 13.990636],
                [107.3932709, 13.9908872],
                [107.3937993, 13.9910943],
                [107.394727, 13.9916668],
                [107.3949269, 13.9922069],
                [107.3955491, 13.9925972],
                [107.3957963, 13.9930679],
                [107.3970754, 13.9932834],
                [107.397849, 13.9926049],
                [107.3985405, 13.9916381],
                [107.3996196, 13.9912582],
                [107.4001937, 13.9907398],
                [107.4010611, 13.9901292],
                [107.402399, 13.9901623],
                [107.4032785, 13.9896555],
                [107.4040645, 13.9895506],
                [107.4047924, 13.9898709],
                [107.4059428, 13.9903406],
                [107.4071157, 13.9900749],
                [107.4075856, 13.9903842],
                [107.4085367, 13.9906469],
                [107.4089478, 13.9911409],
                [107.4094182, 13.9918296],
                [107.4100992, 13.9926439],
                [107.4107571, 13.9932404],
                [107.4115319, 13.993619],
                [107.4133985, 13.9939381],
                [107.4150178, 13.9943376],
                [107.417236, 13.9945526],
                [107.4186559, 13.9946311],
                [107.419829, 13.9945482],
                [107.4213545, 13.9945003],
                [107.4224695, 13.9944993],
                [107.42342, 13.9949223],
                [107.4243247, 13.9956101],
                [107.4247362, 13.9961955],
                [107.4250537, 13.996862],
                [107.4255585, 13.9972974],
                [107.4264513, 13.9976183],
                [107.4271674, 13.9980301],
                [107.4281883, 13.9983734],
                [107.428822, 13.9982575],
                [107.4286911, 13.9966958],
                [107.4298989, 13.9960151],
                [107.430356, 13.9954865],
                [107.4306601, 13.9947618],
                [107.4309173, 13.9940952],
                [107.4321839, 13.9937832],
                [107.4328884, 13.9941838],
                [107.433828, 13.9946645],
                [107.4346143, 13.9949277],
                [107.4354252, 13.9957654],
                [107.4367521, 13.9965099],
                [107.4381956, 13.9966343],
                [107.4391696, 13.9968165],
                [107.4402142, 13.9971594],
                [107.4414235, 13.9974454],
                [107.4424371, 13.9979305],
                [107.4424946, 13.9979435],
                [107.4425533, 13.9979492],
                [107.4426123, 13.9979475],
                [107.4426705, 13.9979384],
                [107.4427271, 13.997922],
                [107.4427782, 13.9979001],
                [107.4428262, 13.9978723],
                [107.4428703, 13.997839],
                [107.4429099, 13.9978008],
                [107.4429443, 13.9977581],
                [107.4429953, 13.9976815],
                [107.4430409, 13.9976019],
                [107.4430751, 13.9975325],
                [107.4431053, 13.9974614],
                [107.443139, 13.9973894],
                [107.4431802, 13.9973213],
                [107.4432284, 13.9972576],
                [107.443284, 13.9971984],
                [107.4433246, 13.9971574],
                [107.4433708, 13.9971225],
                [107.4434218, 13.9970944],
                [107.4434769, 13.9970735],
                [107.4435346, 13.9970607],
                [107.4435936, 13.997056],
                [107.4436526, 13.9970598],
                [107.4436755, 13.9968968],
                [107.4437376, 13.9966825],
                [107.4437833, 13.996573],
                [107.4438923, 13.9963774],
                [107.4439531, 13.9962913],
                [107.4440375, 13.9961978],
                [107.4441144, 13.9960983],
                [107.4441831, 13.9959939],
                [107.4442436, 13.9958847],
                [107.4442954, 13.9957714],
                [107.4443329, 13.9956417],
                [107.4443857, 13.9953318],
                [107.4444142, 13.9949655],
                [107.4444303, 13.9931814],
                [107.4444437, 13.9928797],
                [107.444467, 13.9925786],
                [107.4445309, 13.9920563],
                [107.444669, 13.991301],
                [107.444693, 13.9911361],
                [107.4447119, 13.9909705],
                [107.4447656, 13.9901741],
                [107.4447829, 13.9899932],
                [107.4448097, 13.9898135],
                [107.4448841, 13.9894858],
                [107.4449802, 13.9891922],
                [107.445038, 13.9890488],
                [107.4454737, 13.9881362],
                [107.4455759, 13.9879671],
                [107.4456995, 13.9878121],
                [107.4457687, 13.9877406],
                [107.4459447, 13.9875934],
                [107.4460406, 13.9875296],
                [107.4462455, 13.9874233],
                [107.4471071, 13.9870925],
                [107.4473545, 13.9869817],
                [107.4475847, 13.9868403],
                [107.4476921, 13.9867589],
                [107.4478892, 13.9865766],
                [107.44806, 13.9863708],
                [107.4481346, 13.9862603],
                [107.4482015, 13.9861451],
                [107.4485457, 13.9854554],
                [107.4486287, 13.9853052],
                [107.4487185, 13.9851587],
                [107.4488976, 13.9849041],
                [107.4490973, 13.9846642],
                [107.450287, 13.9833884],
                [107.4505347, 13.9831053],
                [107.4509733, 13.9825661],
                [107.4515328, 13.9818058],
                [107.4519448, 13.9812765],
                [107.452257, 13.9809065],
                [107.4524309, 13.9807422],
                [107.4525995, 13.9806193],
                [107.452782, 13.9805168],
                [107.4528989, 13.9804738],
                [107.4530282, 13.9804494],
                [107.4531666, 13.9804474],
                [107.4536973, 13.9805329],
                [107.4540956, 13.9805626],
                [107.454533, 13.9805599],
                [107.4547702, 13.9805465],
                [107.4552423, 13.9804946],
                [107.4554079, 13.9804399],
                [107.4554859, 13.9804012],
                [107.4556286, 13.9803029],
                [107.4556919, 13.9802442],
                [107.4557997, 13.9801104],
                [107.4558431, 13.9800365],
                [107.4558811, 13.9799536],
                [107.4559101, 13.9798674],
                [107.4559209, 13.9797426],
                [107.4559065, 13.9795983],
                [107.4558856, 13.9795184],
                [107.455796, 13.9792843],
                [107.4557558, 13.9791229],
                [107.4557351, 13.978959],
                [107.4557149, 13.9786572],
                [107.4556741, 13.9784176],
                [107.4556138, 13.9782367],
                [107.4554801, 13.9779953],
                [107.455428, 13.9778563],
                [107.4553459, 13.9774657],
                [107.4552879, 13.9771475],
                [107.4551684, 13.9763952],
                [107.45511, 13.9760892],
                [107.4548021, 13.9747179],
                [107.454738, 13.9743846],
                [107.4546494, 13.9738154],
                [107.4544742, 13.9723636],
                [107.4543869, 13.971493],
                [107.4543445, 13.9709758],
                [107.4542807, 13.9699402],
                [107.4542874, 13.9683889],
                [107.4543374, 13.9674981],
                [107.4543729, 13.9670534],
                [107.4544671, 13.9661487],
                [107.4545905, 13.9652472],
                [107.4546978, 13.9647656],
                [107.4548196, 13.9643454],
                [107.4548897, 13.964138],
                [107.4551104, 13.9635729],
                [107.4551719, 13.9633893],
                [107.4552727, 13.9630157],
                [107.4553473, 13.9626114],
                [107.4553729, 13.962395],
                [107.4553889, 13.9621778],
                [107.4553951, 13.9619601],
                [107.4553917, 13.9617424],
                [107.4553785, 13.961525],
                [107.4553556, 13.9613083],
                [107.4553231, 13.9610929],
                [107.455088, 13.9598031],
                [107.4550321, 13.9594068],
                [107.4549451, 13.958674],
                [107.4549043, 13.9584378],
                [107.4548262, 13.9580861],
                [107.4544645, 13.9567324],
                [107.4544162, 13.9565168],
                [107.454351, 13.9561376],
                [107.4542552, 13.9554339],
                [107.4540611, 13.9533508],
                [107.4539485, 13.95238],
                [107.4538597, 13.9518477],
                [107.4536946, 13.9510873],
                [107.4535682, 13.9504208],
                [107.4534608, 13.9496996],
                [107.4534184, 13.9493375],
                [107.4533977, 13.9490879],
                [107.4533701, 13.9485878],
                [107.4533617, 13.9482477],
                [107.4533701, 13.9475674],
                [107.4533619, 13.9470674],
                [107.4532723, 13.9456518],
                [107.4532616, 13.9454018],
                [107.4532595, 13.9449171],
                [107.453281, 13.9444484],
                [107.4533003, 13.9442146],
                [107.4534684, 13.9425115],
                [107.4534949, 13.9421616],
                [107.453514, 13.9418111],
                [107.4535298, 13.9411375],
                [107.453519, 13.9404918],
                [107.4535042, 13.9401692],
                [107.4533754, 13.9385604],
                [107.4533456, 13.9379428],
                [107.4533433, 13.9376337],
                [107.4533624, 13.9370414],
                [107.4533836, 13.9367458],
                [107.4534492, 13.9361566],
                [107.4535321, 13.9355835],
                [107.4535857, 13.9350209],
                [107.4536008, 13.9347388],
                [107.4536076, 13.9342035],
                [107.4535861, 13.9336685],
                [107.4535648, 13.9334015],
                [107.4535078, 13.9328898],
                [107.4534345, 13.9324011],
                [107.4533364, 13.9319001],
                [107.4532418, 13.9314914],
                [107.4531931, 13.9311671],
                [107.4531785, 13.930829],
                [107.453185, 13.9306598],
                [107.4532063, 13.930435],
                [107.4532588, 13.9301772],
                [107.4533484, 13.9298966],
                [107.4534624, 13.929631],
                [107.4535293, 13.9295026],
                [107.4536826, 13.9292563],
                [107.4537832, 13.92912],
                [107.4538917, 13.9289896],
                [107.4540078, 13.9288654],
                [107.4541312, 13.928748],
                [107.4542612, 13.9286377],
                [107.4543977, 13.9285349],
                [107.45454, 13.9284399],
                [107.4546878, 13.928353],
                [107.4548404, 13.9282746],
                [107.4564356, 13.9275804],
                [107.4565543, 13.927523],
                [107.4567797, 13.9273878],
                [107.4569867, 13.9272272],
                [107.4571721, 13.9270434],
                [107.4572636, 13.9269339],
                [107.4573473, 13.9268188],
                [107.457423, 13.9266985],
                [107.4574903, 13.9265736],
                [107.4576008, 13.926326],
                [107.4576848, 13.9260818],
                [107.4577421, 13.9258303],
                [107.4577613, 13.9256957],
                [107.4577765, 13.9254245],
                [107.4578054, 13.9227609],
                [107.457824, 13.9225749],
                [107.4578455, 13.9224838],
                [107.457912, 13.9223084],
                [107.4580082, 13.9221466],
                [107.4581366, 13.9219979],
                [107.4582133, 13.9219301],
                [107.4582961, 13.9218693],
                [107.4583843, 13.9218163],
                [107.4584771, 13.9217713],
                [107.4585738, 13.9217347],
                [107.4586736, 13.921707],
                [107.4587755, 13.9216882],
                [107.4588788, 13.9216786],
                [107.4588468, 13.923563],
                [107.4588531, 13.9237437],
                [107.4588909, 13.9239206],
                [107.4589591, 13.9240888],
                [107.4590041, 13.924168],
                [107.4591121, 13.9243117],
                [107.4592433, 13.9244359],
                [107.4593165, 13.9244895],
                [107.4603623, 13.9253821],
                [107.460548, 13.925566],
                [107.460707, 13.9257721],
                [107.4607755, 13.9258824],
                [107.4608893, 13.9261151],
                [107.4609339, 13.9262364],
                [107.4609955, 13.9264735],
                [107.4610146, 13.9265944],
                [107.461029, 13.9268385],
                [107.4610117, 13.9270825],
                [107.4609262, 13.9277636],
                [107.4609017, 13.9280535],
                [107.4609005, 13.9282155],
                [107.4609247, 13.9285383],
                [107.4609842, 13.9288569],
                [107.4610781, 13.9291675],
                [107.4612211, 13.9295102],
                [107.4614102, 13.929883],
                [107.4616284, 13.9302405],
                [107.4618742, 13.9305807],
                [107.4621463, 13.9309016],
                [107.4623448, 13.9311101],
                [107.4625647, 13.9312973],
                [107.4628136, 13.9314671],
                [107.46308, 13.9316096],
                [107.4633608, 13.931723],
                [107.4669163, 13.9328717],
                [107.4669323, 13.9328841],
                [107.4693746, 13.9343656],
                [107.470226, 13.9357427],
                [107.4702649, 13.9366894],
                [107.4704568, 13.9381432],
                [107.4706501, 13.9401144],
                [107.4701294, 13.9427749],
                [107.469203, 13.951859],
                [107.4698896, 13.9535249],
                [107.4731512, 13.9555241],
                [107.4757261, 13.9596889],
                [107.4825925, 13.9598555],
                [107.4873991, 13.9610216],
                [107.4951238, 13.9670188],
                [107.4952472, 13.9670849],
                [107.4958007, 13.966992],
                [107.4976612, 13.9666796],
                [107.4985147, 13.9673298],
                [107.4995095, 13.9684894],
                [107.5005992, 13.9696957],
                [107.5018033, 13.970726],
                [107.5021128, 13.9709229],
                [107.5037682, 13.9721034],
                [107.5042577, 13.9724372],
                [107.5047946, 13.9728835],
                [107.5051703, 13.9733993],
                [107.5056373, 13.9737786],
                [107.506314, 13.9743139],
                [107.507037, 13.9748487],
                [107.5073438, 13.9754104],
                [107.5076751, 13.9760848],
                [107.5077948, 13.9764676],
                [107.5085521, 13.9780241],
                [107.5088858, 13.9789251],
                [107.5093812, 13.9798017],
                [107.5101087, 13.9807439],
                [107.5103907, 13.9811481],
                [107.5106498, 13.981575],
                [107.5108408, 13.9821157],
                [107.5109614, 13.9825667],
                [107.5110593, 13.9830631],
                [107.5113878, 13.9834893],
                [107.5116683, 13.9837577],
                [107.5120407, 13.9839573],
                [107.5125273, 13.9840201],
                [107.5129902, 13.984038],
                [107.5137999, 13.9840295],
                [107.5142161, 13.9840026],
                [107.5147007, 13.9838845],
                [107.515255, 13.9837884],
                [107.5157167, 13.9836932],
                [107.5165008, 13.983459],
                [107.5169849, 13.9832957],
                [107.5173774, 13.9832238],
                [107.5178152, 13.9830609],
                [107.5182075, 13.9829664],
                [107.5185535, 13.9828722],
                [107.5188076, 13.9828357],
                [107.5190622, 13.9828331],
                [107.5194091, 13.9828069],
                [107.5197098, 13.9828038],
                [107.5204158, 13.982819],
                [107.5207397, 13.9828157],
                [107.5214088, 13.9826279],
                [107.5222283, 13.9824385],
                [107.522632, 13.9823234],
                [107.5229092, 13.9822753],
                [107.5241692, 13.9821604],
                [107.5244469, 13.9821575],
                [107.5247946, 13.9822104],
                [107.5251432, 13.9823425],
                [107.5253644, 13.9824759],
                [107.5255981, 13.982677],
                [107.5257506, 13.9828789],
                [107.525952, 13.9833066],
                [107.5260368, 13.9836449],
                [107.5260977, 13.983927],
                [107.5262856, 13.9841851],
                [107.5263807, 13.9844103],
                [107.5264423, 13.9847601],
                [107.5264444, 13.9849524],
                [107.5263559, 13.9853152],
                [107.5263025, 13.9857341],
                [107.5261679, 13.98612],
                [107.5260449, 13.9865171],
                [107.5259672, 13.9868119],
                [107.525925, 13.9871854],
                [107.5259051, 13.9874796],
                [107.5258386, 13.9877518],
                [107.5257492, 13.9880467],
                [107.5256015, 13.9882857],
                [107.5254782, 13.9886601],
                [107.5253779, 13.9890118],
                [107.5253241, 13.9893742],
                [107.5252934, 13.9897476],
                [107.525205, 13.990133],
                [107.5251288, 13.9905748],
                [107.5251318, 13.9908462],
                [107.5250673, 13.9912878],
                [107.5249664, 13.9915942],
                [107.5248315, 13.9919575],
                [107.5247087, 13.9923659],
                [107.5245623, 13.9927405],
                [107.5244614, 13.9930243],
                [107.5243049, 13.9935348],
                [107.5241254, 13.9940569],
                [107.5239338, 13.9945225],
                [107.5237527, 13.9948974],
                [107.5236767, 13.9953505],
                [107.5235648, 13.9957023],
                [107.5234991, 13.9960422],
                [107.5234682, 13.9963931],
                [107.5232911, 13.99713],
                [107.5231617, 13.9979907],
                [107.523062, 13.9984102],
                [107.5229274, 13.998796],
                [107.5225939, 13.9999212],
                [107.5225062, 14.0003081],
                [107.5224459, 14.0005274],
                [107.5223784, 14.0009042],
                [107.5222593, 14.0014826],
                [107.5222076, 14.0016683],
                [107.5221736, 14.0018351],
                [107.5221539, 14.0018253],
                [107.5221029, 14.0020798],
                [107.5219388, 14.0026422],
                [107.5218877, 14.002888],
                [107.5218189, 14.0031516],
                [107.5217597, 14.0034763],
                [107.5216573, 14.0039417],
                [107.5215538, 14.0043107],
                [107.521419, 14.0050918],
                [107.5213952, 14.0053724],
                [107.5213133, 14.0060829],
                [107.5212501, 14.0068632],
                [107.5212455, 14.0072574],
                [107.5212039, 14.007547],
                [107.5211811, 14.0079152],
                [107.5210798, 14.0084944],
                [107.5210385, 14.0088102],
                [107.5210259, 14.0093009],
                [107.5210284, 14.0095286],
                [107.5209954, 14.009783],
                [107.520936, 14.0100991],
                [107.5207738, 14.0108279],
                [107.5206722, 14.011372],
                [107.5206655, 14.0115824],
                [107.5206498, 14.0117841],
                [107.520652, 14.0119856],
                [107.5207359, 14.0122825],
                [107.5208364, 14.0124655],
                [107.5210634, 14.0127348],
                [107.5212894, 14.0129163],
                [107.5214977, 14.0131244],
                [107.5217147, 14.0132974],
                [107.5219052, 14.0135056],
                [107.5221129, 14.0136524],
                [107.5223294, 14.0137903],
                [107.5225999, 14.0139451],
                [107.522825, 14.014048],
                [107.5229964, 14.0141513],
                [107.5232034, 14.0142368],
                [107.5234815, 14.0142601],
                [107.5237859, 14.0142395],
                [107.524135, 14.0142007],
                [107.5258907, 14.0133326],
                [107.5260954, 14.0132079],
                [107.5264071, 14.0130382],
                [107.5266918, 14.0128512],
                [107.5268966, 14.0127352],
                [107.5270395, 14.01269],
                [107.5273258, 14.0126519],
                [107.5276572, 14.0126396],
                [107.5279978, 14.0126449],
                [107.5285888, 14.0126124],
                [107.5291175, 14.0126244],
                [107.5293057, 14.0126224],
                [107.5296732, 14.0126361],
                [107.5298889, 14.0126864],
                [107.5301771, 14.0128236],
                [107.5304199, 14.0129087],
                [107.530672, 14.0130112],
                [107.5309681, 14.0130519],
                [107.531201, 14.0130406],
                [107.5313977, 14.0130124],
                [107.5316742, 14.0128868],
                [107.5319681, 14.012726],
                [107.5323246, 14.0125558],
                [107.532645, 14.012351],
                [107.5329655, 14.0121724],
                [107.5335892, 14.0118418],
                [107.5338566, 14.0117163],
                [107.5340881, 14.0115738],
                [107.5343021, 14.0114839],
                [107.5346408, 14.0113226],
                [107.5349263, 14.0112145],
                [107.5352924, 14.0110969],
                [107.535783, 14.010899],
                [107.5361677, 14.0108336],
                [107.5364092, 14.0108048],
                [107.5366779, 14.0107933],
                [107.5372155, 14.0107876],
                [107.5377796, 14.010747],
                [107.5398767, 14.0107251],
                [107.5402535, 14.010756],
                [107.5405227, 14.0107883],
                [107.540829, 14.0109251],
                [107.5413701, 14.011221],
                [107.5420377, 14.0116171],
                [107.5423991, 14.0118762],
                [107.5427788, 14.0121702],
                [107.5431045, 14.0124472],
                [107.5435382, 14.0127582],
                [107.5438643, 14.0130701],
                [107.5441552, 14.0134351],
                [107.5444834, 14.01394],
                [107.5445949, 14.0143069],
                [107.5449639, 14.0152494],
                [107.5450952, 14.0157738],
                [107.5451744, 14.0164566],
                [107.5453055, 14.0169634],
                [107.5454527, 14.0173125],
                [107.5455827, 14.0177142],
                [107.545604, 14.0180294],
                [107.5457162, 14.018449],
                [107.5458117, 14.0189737],
                [107.5459232, 14.019323],
                [107.5460337, 14.0196023],
                [107.5461824, 14.020074],
                [107.5462932, 14.0203707],
                [107.5468615, 14.021504],
                [107.5471172, 14.0219396],
                [107.5473533, 14.0222174],
                [107.547661, 14.022477],
                [107.5479873, 14.0228067],
                [107.5483676, 14.0231532],
                [107.5487127, 14.0235527],
                [107.5490035, 14.0239176],
                [107.5494568, 14.0243861],
                [107.549838, 14.0248027],
                [107.5502001, 14.0251319],
                [107.550544, 14.0254263],
                [107.551012, 14.0255965],
                [107.5512636, 14.0257165],
                [107.5516594, 14.0258349],
                [107.5519296, 14.0259371],
                [107.5522524, 14.0259337],
                [107.552628, 14.0258422],
                [107.5529842, 14.0256283],
                [107.5533571, 14.0252917],
                [107.5536399, 14.024921],
                [107.5547545, 14.0235464],
                [107.5551623, 14.0231214],
                [107.55541, 14.0228207],
                [107.5557466, 14.0224489],
                [107.5560479, 14.0221303],
                [107.5565104, 14.0217922],
                [107.5569201, 14.0215425],
                [107.5572945, 14.0213456],
                [107.5577958, 14.0212702],
                [107.5583519, 14.0212818],
                [107.5589274, 14.0214335],
                [107.5591985, 14.0216235],
                [107.5595585, 14.0217424],
                [107.5600981, 14.0218945],
                [107.5607449, 14.0219929],
                [107.5611758, 14.0220409],
                [107.5615167, 14.0220548],
                [107.5621264, 14.0220484],
                [107.5625924, 14.0220259],
                [107.5630943, 14.0220206],
                [107.5637034, 14.0219616],
                [107.5648136, 14.0218095],
                [107.565386, 14.0216807],
                [107.5659218, 14.0214821],
                [107.5664221, 14.021319],
                [107.56703, 14.0211549],
                [107.5676009, 14.0208858],
                [107.5685088, 14.0202977],
                [107.5688817, 14.0199606],
                [107.5690563, 14.019538],
                [107.569248, 14.0190275],
                [107.5694052, 14.0186576],
                [107.5696344, 14.0183046],
                [107.5699357, 14.0179859],
                [107.570168, 14.0179132],
                [107.5704736, 14.0179801],
                [107.5708531, 14.018239],
                [107.5712322, 14.0184629],
                [107.5713949, 14.0185839],
                [107.5716641, 14.0185987],
                [107.5719141, 14.0185083],
                [107.5721979, 14.0182248],
                [107.5725007, 14.0180462],
                [107.5729661, 14.0179711],
                [107.5737202, 14.0180508],
                [107.5745661, 14.0183223],
                [107.5753928, 14.0184889],
                [107.575967, 14.0185178],
                [107.5765583, 14.0184764],
                [107.5770602, 14.0184535],
                [107.5775977, 14.0184128],
                [107.5783677, 14.0183169],
                [107.5796228, 14.0183035],
                [107.5804299, 14.0183125],
                [107.5810761, 14.0183758],
                [107.5816332, 14.0184749],
                [107.5822994, 14.0187133],
                [107.5826605, 14.0189374],
                [107.5829693, 14.0192847],
                [107.5831354, 14.0197037],
                [107.583249, 14.020246],
                [107.5833614, 14.0206831],
                [107.583565, 14.021242],
                [107.5838206, 14.0216424],
                [107.5841463, 14.021902],
                [107.5844351, 14.0220742],
                [107.5848483, 14.0221399],
                [107.5852609, 14.022153],
                [107.5855822, 14.0220268],
                [107.5860982, 14.0216531],
                [107.5865412, 14.0211927],
                [107.5868916, 14.0204525],
                [107.58721, 14.0200634],
                [107.5876913, 14.0197953],
                [107.5881551, 14.0195799],
                [107.5884386, 14.0192788],
                [107.5886886, 14.0191885],
                [107.5889937, 14.0192028],
                [107.5893898, 14.0193564],
                [107.5899159, 14.0198943],
                [107.5903154, 14.0203282],
                [107.5908946, 14.0208129],
                [107.5911303, 14.0210384],
                [107.5914562, 14.0213153],
                [107.5919637, 14.0218008],
                [107.5924337, 14.0221288],
                [107.5929387, 14.0223864],
                [107.5933901, 14.0226621],
                [107.5938419, 14.0229728],
                [107.5944378, 14.0233346],
                [107.5948723, 14.0236981],
                [107.5959388, 14.0244581],
                [107.596247, 14.0247528],
                [107.596663, 14.025064],
                [107.5970433, 14.0253929],
                [107.5975676, 14.0257731],
                [107.5980204, 14.0261714],
                [107.5985995, 14.0266385],
                [107.5990334, 14.0269495],
                [107.5994502, 14.0273307],
                [107.599849, 14.0277121],
                [107.6002302, 14.0281287],
                [107.6010329, 14.0293299],
                [107.6011801, 14.0296613],
                [107.6012743, 14.0300636],
                [107.6012966, 14.030449],
                [107.6013923, 14.0309914],
                [107.6014347, 14.0315695],
                [107.6014947, 14.0321123],
                [107.6015177, 14.0325679],
                [107.6014698, 14.0330945],
                [107.6014415, 14.0337609],
                [107.6013925, 14.0341823],
                [107.6013085, 14.0346915],
                [107.6011711, 14.035219],
                [107.6010698, 14.0357811],
                [107.6009144, 14.0363087],
                [107.6008322, 14.0369757],
                [107.6007305, 14.0375028],
                [107.6006472, 14.0380648],
                [107.6006017, 14.0388016],
                [107.6006447, 14.0394323],
                [107.6007214, 14.0398697],
                [107.6008033, 14.040763],
                [107.6008984, 14.0412529],
                [107.6010119, 14.0417776],
                [107.601037, 14.0424084],
                [107.6011342, 14.0430736],
                [107.6011425, 14.0438099],
                [107.601206, 14.0446682],
                [107.601282, 14.0450531],
                [107.6015383, 14.0455061],
                [107.6018662, 14.0459585],
                [107.6023017, 14.0464096],
                [107.6027904, 14.0468076],
                [107.6030989, 14.0471198],
                [107.6040592, 14.0479861],
                [107.6044399, 14.0483501],
                [107.6050534, 14.0486766],
                [107.6057027, 14.0490027],
                [107.6063164, 14.0493467],
                [107.6070374, 14.049672],
                [107.6075423, 14.049912],
                [107.608424, 14.0501654],
                [107.6089821, 14.0503523],
                [107.6095037, 14.0504869],
                [107.610098, 14.0507084],
                [107.61055, 14.0510191],
                [107.6109126, 14.0513658],
                [107.6113651, 14.051729],
                [107.6117295, 14.0522335],
                [107.6120402, 14.0527386],
                [107.6123522, 14.0533663],
                [107.6124808, 14.0536455],
                [107.612701, 14.0540813],
                [107.6129777, 14.0547621],
                [107.6133801, 14.0554414],
                [107.6135323, 14.0562113],
                [107.6135739, 14.0567192],
                [107.613599, 14.0573501],
                [107.6136102, 14.0583317],
                [107.6136509, 14.0587696],
                [107.6136034, 14.0593135],
                [107.613536, 14.0596999],
                [107.6136015, 14.0607336],
                [107.6136794, 14.0612762],
                [107.6137409, 14.0619417],
                [107.6139086, 14.062501],
                [107.6141647, 14.0629364],
                [107.6145632, 14.0632827],
                [107.6151763, 14.0635742],
                [107.6157502, 14.063568],
                [107.6161965, 14.0633878],
                [107.616821, 14.0631005],
                [107.61723, 14.0627981],
                [107.6171981, 14.0627684],
                [107.6178059, 14.0622877],
                [107.6180913, 14.06202],
                [107.6189309, 14.0613058],
                [107.6193241, 14.0610019],
                [107.6198611, 14.0606435],
                [107.6202917, 14.0604449],
                [107.6210456, 14.060137],
                [107.6217976, 14.0596529],
                [107.6221905, 14.0593136],
                [107.6225659, 14.0590276],
                [107.6230472, 14.0585287],
                [107.6234222, 14.0582073],
                [107.6238141, 14.05778],
                [107.6241705, 14.0574058],
                [107.6244547, 14.0570149],
                [107.6247046, 14.0568008],
                [107.6251519, 14.0564785],
                [107.6255809, 14.0561389],
                [107.626029, 14.0559049],
                [107.6264229, 14.0556538],
                [107.6269252, 14.0554192],
                [107.6273021, 14.0552564],
                [107.6277688, 14.055075],
                [107.6282718, 14.0548933],
                [107.6287029, 14.0547476],
                [107.6292416, 14.0545478],
                [107.6304267, 14.0540941],
                [107.6308755, 14.053913],
                [107.6313785, 14.0537488],
                [107.631864, 14.0536202],
                [107.6322599, 14.0535453],
                [107.632981, 14.0535198],
                [107.6335406, 14.0535666],
                [107.6343366, 14.0537872],
                [107.6347361, 14.0540295],
                [107.6351551, 14.0543952],
                [107.6355751, 14.0548489],
                [107.6359407, 14.0552856],
                [107.6363058, 14.0556695],
                [107.6366899, 14.0561412],
                [107.6373139, 14.0570864],
                [107.6376997, 14.0576992],
                [107.6379932, 14.0581367],
                [107.6382678, 14.0584862],
                [107.6385415, 14.0587654],
                [107.638834, 14.0591147],
                [107.6391635, 14.0595342],
                [107.6394561, 14.0598835],
                [107.6400953, 14.0605817],
                [107.6405517, 14.0610703],
                [107.6408984, 14.0614191],
                [107.6417382, 14.0623089],
                [107.6421027, 14.0626398],
                [107.6425024, 14.0629],
                [107.6427754, 14.0631085],
                [107.6431208, 14.0633515],
                [107.6436474, 14.063663],
                [107.6452579, 14.064139],
                [107.6458003, 14.0642564],
                [107.6467058, 14.0645815],
                [107.6470312, 14.0646483],
                [107.647448, 14.0648201],
                [107.6476834, 14.0649056],
                [107.6486444, 14.0653534],
                [107.6493718, 14.0658743],
                [107.6496822, 14.0662058],
                [107.6499566, 14.0665377],
                [107.6502312, 14.0668873],
                [107.6505987, 14.0674826],
                [107.6507298, 14.0679043],
                [107.6507525, 14.0683094],
                [107.65083, 14.0687669],
                [107.6509081, 14.0692949],
                [107.6509306, 14.0696825],
                [107.6509349, 14.0700526],
                [107.6509925, 14.0703517],
                [107.6509684, 14.071392],
                [107.6509194, 14.0718332],
                [107.6509066, 14.0722917],
                [107.6508205, 14.0726453],
                [107.6507389, 14.0733865],
                [107.6507622, 14.0738446],
                [107.6506955, 14.0743213],
                [107.6506836, 14.0748503],
                [107.6506889, 14.0753085],
                [107.6508389, 14.0758005],
                [107.650952, 14.0762223],
                [107.651247, 14.0767832],
                [107.6513947, 14.0770813],
                [107.6516158, 14.0774842],
                [107.6518538, 14.0777989],
                [107.6526212, 14.0786543],
                [107.6530401, 14.0790022],
                [107.6533876, 14.0794216],
                [107.6537528, 14.0798053],
                [107.653936, 14.0800501],
                [107.6543917, 14.0804682],
                [107.6547019, 14.0807821],
                [107.6550849, 14.081148],
                [107.6557589, 14.0817223],
                [107.6564527, 14.082455],
                [107.6568177, 14.0828212],
                [107.6572222, 14.0834866],
                [107.657407, 14.0838724],
                [107.6575377, 14.0842587],
                [107.6575789, 14.084699],
                [107.6575832, 14.0850692],
                [107.6575335, 14.0854575],
                [107.6572904, 14.0862711],
                [107.6570972, 14.0867139],
                [107.656815, 14.0872635],
                [107.656371, 14.0878678],
                [107.6561591, 14.0882579],
                [107.6556991, 14.089021],
                [107.6553266, 14.0895716],
                [107.6550958, 14.0898914],
                [107.6547581, 14.0903183],
                [107.6544741, 14.0907092],
                [107.6541711, 14.0910299],
                [107.653869, 14.091421],
                [107.653495, 14.0918306],
                [107.6531027, 14.0922227],
                [107.6528178, 14.0925431],
                [107.6525149, 14.0928638],
                [107.6522123, 14.0932197],
                [107.6517307, 14.0936832],
                [107.6513916, 14.0939867],
                [107.6510882, 14.0942721],
                [107.6507506, 14.0947165],
                [107.6503231, 14.0951795],
                [107.6499843, 14.0955183],
                [107.6496818, 14.0958742],
                [107.6493979, 14.0962827],
                [107.649222, 14.0966724],
                [107.6490662, 14.0972383],
                [107.6489643, 14.0977858],
                [107.6489686, 14.0981561],
                [107.6490288, 14.0986841],
                [107.6491786, 14.0991585],
                [107.6492736, 14.0995805],
                [107.649387, 14.10002],
                [107.6495718, 14.1004058],
                [107.6498292, 14.1008261],
                [107.6502122, 14.1011921],
                [107.650685, 14.1015217],
                [107.6511202, 14.1017285],
                [107.6521691, 14.1019637],
                [107.6526567, 14.1020112],
                [107.6533245, 14.1020392],
                [107.6538847, 14.1021387],
                [107.6541732, 14.1021356],
                [107.654642, 14.1021127],
                [107.6554537, 14.1021038],
                [107.6559239, 14.102222],
                [107.6566863, 14.1026367],
                [107.6571406, 14.1029313],
                [107.6574325, 14.1032101],
                [107.6576704, 14.1035072],
                [107.6577829, 14.1038762],
                [107.657792, 14.1046517],
                [107.6577786, 14.1050572],
                [107.6577835, 14.1054803],
                [107.6577363, 14.1060801],
                [107.6577115, 14.10705],
                [107.6576999, 14.1075967],
                [107.6577074, 14.1082488],
                [107.6577682, 14.1088298],
                [107.6577918, 14.1093055],
                [107.6578522, 14.1098513],
                [107.6579852, 14.1104316],
                [107.658009, 14.1109249],
                [107.6580869, 14.1114177],
                [107.6581115, 14.1119814],
                [107.6581165, 14.1124221],
                [107.6580489, 14.1128106],
                [107.6580546, 14.1133042],
                [107.6581139, 14.1137442],
                [107.6580835, 14.1142382],
                [107.6581071, 14.1147139],
                [107.6582032, 14.1152239],
                [107.6582815, 14.1157521],
                [107.6583952, 14.1162267],
                [107.6584893, 14.1165606],
                [107.6587127, 14.1171575],
                [107.6590258, 14.1177181],
                [107.6596876, 14.1187861],
                [107.6598719, 14.119119],
                [107.6600946, 14.119663],
                [107.6602441, 14.120102],
                [107.6603219, 14.1205948],
                [107.6604181, 14.1211049],
                [107.660458, 14.1214394],
                [107.6605365, 14.121985],
                [107.6605412, 14.1223905],
                [107.6605651, 14.1228837],
                [107.6605687, 14.123201],
                [107.6605551, 14.123589],
                [107.6605781, 14.1240118],
                [107.6605488, 14.1245939],
                [107.6605361, 14.1250524],
                [107.6605773, 14.1254926],
                [107.6605823, 14.1259331],
                [107.6605564, 14.1267973],
                [107.6605615, 14.1272379],
                [107.6605837, 14.1275902],
                [107.6605897, 14.128119],
                [107.6606131, 14.1285771],
                [107.6607462, 14.1291573],
                [107.6609333, 14.1297369],
                [107.6611584, 14.1304748],
                [107.6614945, 14.1314583],
                [107.661735, 14.1319668],
                [107.6623058, 14.1329654],
                [107.6625624, 14.1333151],
                [107.6641248, 14.1359232],
                [107.6646101, 14.1374588],
                [107.6645773, 14.1395764],
                [107.6619894, 14.1445328],
                [107.6617791, 14.145064],
                [107.6611449, 14.1463755],
                [107.6610061, 14.1468529],
                [107.6605777, 14.1487968],
                [107.66048, 14.149823],
                [107.660457, 14.1509503],
                [107.6605309, 14.1526455],
                [107.6607175, 14.1547241],
                [107.6607338, 14.1561171],
                [107.6606852, 14.1565937],
                [107.6606169, 14.1569295],
                [107.6603538, 14.1575849],
                [107.6598934, 14.1583483],
                [107.6591815, 14.1592026],
                [107.6588622, 14.1596823],
                [107.6582468, 14.1610821],
                [107.6579735, 14.1624077],
                [107.6579643, 14.1631661],
                [107.6580254, 14.1637649],
                [107.6580398, 14.1649992],
                [107.6583, 14.1656487],
                [107.6592397, 14.1667046],
                [107.6601654, 14.1678318],
                [107.6613688, 14.169651],
                [107.662338, 14.1708047],
                [107.6642172, 14.1723345],
                [107.6649018, 14.172805],
                [107.6659046, 14.1735906],
                [107.6665378, 14.1739696],
                [107.6673086, 14.1742797],
                [107.6680785, 14.1745164],
                [107.6688242, 14.1748268],
                [107.6694701, 14.1752179],
                [107.6701434, 14.1757866],
                [107.6706155, 14.1763696],
                [107.6709999, 14.1769783],
                [107.671694, 14.1782515],
                [107.6720295, 14.1789831],
                [107.6722265, 14.1796857],
                [107.6730019, 14.1814667],
                [107.673861, 14.1828607],
                [107.6742731, 14.1836897],
                [107.6760596, 14.1858822],
                [107.677333, 14.1872225],
                [107.6783769, 14.189362],
                [107.678884, 14.190766],
                [107.6790579, 14.1916528],
                [107.6791552, 14.1924361],
                [107.6791643, 14.1932084],
                [107.6790745, 14.1941347],
                [107.6789104, 14.195154],
                [107.6785005, 14.1966602],
                [107.6782975, 14.1975818],
                [107.6782552, 14.1982565],
                [107.6782658, 14.1991572],
                [107.6783476, 14.1996957],
                [107.6785832, 14.2004776],
                [107.6789183, 14.2011664],
                [107.6794441, 14.2020308],
                [107.6800946, 14.2027957],
                [107.6805016, 14.2031773],
                [107.6811912, 14.2040644],
                [107.6814739, 14.2045637],
                [107.6815678, 14.2050653],
                [107.6817475, 14.2053758],
                [107.6819631, 14.2055205],
                [107.6823289, 14.2056145],
                [107.6828577, 14.2056575],
                [107.6833873, 14.205762],
                [107.6836281, 14.2059186],
                [107.6837706, 14.2062724],
                [107.6842128, 14.2085658],
                [107.6845275, 14.2096531],
                [107.6848267, 14.2104895],
                [107.6855583, 14.2117315],
                [107.6870376, 14.2145098],
                [107.6872478, 14.2152673],
                [107.6874084, 14.2160868],
                [107.6880617, 14.2181448],
                [107.6881215, 14.218947],
                [107.6880607, 14.2201857],
                [107.6881038, 14.2206449],
                [107.6881843, 14.220881],
                [107.6884483, 14.2210455],
                [107.688978, 14.2211744],
                [107.6895569, 14.2211924],
                [107.6906858, 14.2209223],
                [107.6913364, 14.220633],
                [107.692685, 14.2197905],
                [107.693345, 14.2192313],
                [107.6942779, 14.2183384],
                [107.6948387, 14.2178968],
                [107.6962609, 14.2169125],
                [107.69726, 14.2163005],
                [107.6991257, 14.215587],
                [107.6997155, 14.21547],
                [107.7005076, 14.2154364],
                [107.7014014, 14.2155061],
                [107.7023599, 14.2157159],
                [107.7033065, 14.215987],
                [107.7041276, 14.2162842],
                [107.7062982, 14.2168787],
                [107.7072046, 14.2169419],
                [107.7077321, 14.2168686],
                [107.7084719, 14.2166763],
                [107.7091484, 14.216448],
                [107.7096113, 14.2162405],
                [107.7101472, 14.2158176],
                [107.7120196, 14.2146196],
                [107.7128445, 14.2141813],
                [107.7136836, 14.2138654],
                [107.7142477, 14.2137119],
                [107.7174386, 14.2135819],
                [107.7213652, 14.2150484],
                [107.7258472, 14.2149527],
                [107.7262355, 14.2148256],
                [107.7268237, 14.2145799],
                [107.7273487, 14.2143043],
                [107.7277994, 14.2141276],
                [107.7281752, 14.2140007],
                [107.7285892, 14.2139101],
                [107.7293424, 14.213779],
                [107.730058, 14.2136788],
                [107.7306863, 14.2136227],
                [107.731, 14.2135578],
                [107.7314883, 14.2133683],
                [107.7319633, 14.21313],
                [107.7324622, 14.2127811],
                [107.7329854, 14.2123522],
                [107.7334842, 14.2119912],
                [107.7339839, 14.2117035],
                [107.7345471, 14.2114765],
                [107.7349608, 14.2113552],
                [107.7352611, 14.2112292],
                [107.7356854, 14.2109547],
                [107.7362832, 14.2104576],
                [107.7367694, 14.2100966],
                [107.7374308, 14.2096599],
                [107.7382557, 14.2092338],
                [107.7388565, 14.2089879],
                [107.7392813, 14.2087501],
                [107.7398815, 14.2084613],
                [107.7404569, 14.2081973],
                [107.7407827, 14.2080894],
                [107.7413601, 14.207997],
                [107.7419888, 14.2079775],
                [107.7426177, 14.2079825],
                [107.7433723, 14.2079739],
                [107.7441015, 14.2079533],
                [107.7447048, 14.2079218],
                [107.7452699, 14.2078357],
                [107.7460854, 14.2076669],
                [107.7468879, 14.2074739],
                [107.7476276, 14.2072693],
                [107.7479781, 14.2071304],
                [107.748691, 14.2068035],
                [107.7493781, 14.2064157],
                [107.7498387, 14.2060181],
                [107.7503761, 14.2055708],
                [107.7506058, 14.205491],
                [107.7513059, 14.2051495],
                [107.7519359, 14.2048355],
                [107.7524955, 14.2045225],
                [107.7531392, 14.2041816],
                [107.7538257, 14.2038719],
                [107.754344, 14.2036394],
                [107.7550875, 14.203391],
                [107.7558037, 14.2032095],
                [107.7564076, 14.2030694],
                [107.7569975, 14.2029293],
                [107.7575586, 14.2027496],
                [107.7579786, 14.2025316],
                [107.7583705, 14.2023272],
                [107.7586502, 14.2022309],
                [107.7592828, 14.2021436],
                [107.7598179, 14.2021374],
                [107.7602263, 14.202146],
                [107.7607201, 14.2022203],
                [107.7611151, 14.2022824],
                [107.7615675, 14.202428],
                [107.762007, 14.2026628],
                [107.7624334, 14.2029909],
                [107.7627185, 14.2032807],
                [107.7628913, 14.2035984],
                [107.7630224, 14.2039565],
                [107.7631119, 14.2043684],
                [107.7631595, 14.2048076],
                [107.7631638, 14.2051672],
                [107.7631668, 14.2059972],
                [107.7631924, 14.2075291],
                [107.7631707, 14.208062],
                [107.7631327, 14.2084088],
                [107.7630951, 14.2087955],
                [107.7630303, 14.209249],
                [107.7628947, 14.2096768],
                [107.7628152, 14.2100906],
                [107.7626991, 14.210578],
                [107.7626648, 14.2109079],
                [107.7626506, 14.2109045],
                [107.7626288, 14.2113729],
                [107.7626486, 14.2118733],
                [107.762708, 14.2122367],
                [107.7628067, 14.2124436],
                [107.7630293, 14.2126881],
                [107.7631957, 14.2128163],
                [107.7633479, 14.2129055],
                [107.7636509, 14.2129671],
                [107.7639124, 14.213016],
                [107.7642838, 14.2130637],
                [107.7647798, 14.213188],
                [107.7654773, 14.2136151],
                [107.7660366, 14.213963],
                [107.7666529, 14.2142742],
                [107.7670805, 14.2144252],
                [107.7678889, 14.214506],
                [107.7684856, 14.2144992],
                [107.7689855, 14.2145328],
                [107.7694959, 14.2147232],
                [107.7698417, 14.2149141],
                [107.7701737, 14.2151051],
                [107.7705218, 14.2154908],
                [107.7708004, 14.2158124],
                [107.7711682, 14.2161354],
                [107.771529, 14.2164432],
                [107.7717922, 14.2166352],
                [107.7719597, 14.2168541],
                [107.7720864, 14.2171127],
                [107.7722143, 14.2174623],
                [107.7723704, 14.2178764],
                [107.7724704, 14.2181874],
                [107.7725697, 14.2184592],
                [107.7726957, 14.2186527],
                [107.7729168, 14.2187671],
                [107.7731647, 14.2188292],
                [107.7734668, 14.2188257],
                [107.7738231, 14.2187696],
                [107.7742062, 14.2186482],
                [107.7746156, 14.218429],
                [107.7751739, 14.2180454],
                [107.7756227, 14.2176892],
                [107.7760567, 14.2172421],
                [107.7764495, 14.2167956],
                [107.7768416, 14.216297],
                [107.7770853, 14.2160081],
                [107.777331, 14.2158882],
                [107.7775781, 14.2158854],
                [107.7777709, 14.2159222],
                [107.7779923, 14.2160626],
                [107.7782286, 14.2162939],
                [107.7783547, 14.2165004],
                [107.7784539, 14.2167593],
                [107.7785127, 14.2170706],
                [107.778517, 14.2174215],
                [107.7785225, 14.21787],
                [107.7785527, 14.2181036],
                [107.7786371, 14.2182587],
                [107.7788167, 14.2183476],
                [107.7790226, 14.2183451],
                [107.7792972, 14.2183419],
                [107.7795174, 14.2183784],
                [107.7797524, 14.2185057],
                [107.7799191, 14.2186598],
                [107.780102, 14.2190216],
                [107.7803278, 14.219513],
                [107.7806104, 14.2201663],
                [107.7807681, 14.2207104],
                [107.7808831, 14.2211251],
                [107.7810679, 14.221643],
                [107.7812525, 14.2221349],
                [107.781591, 14.2228654],
                [107.7818454, 14.2234604],
                [107.7820292, 14.2238873],
                [107.7822684, 14.2243525],
                [107.7826312, 14.2248293],
                [107.782952, 14.2252286],
                [107.7833399, 14.2255036],
                [107.783533, 14.2255793],
                [107.7839177, 14.2255878],
                [107.7842469, 14.225558],
                [107.7846992, 14.2254878],
                [107.7852053, 14.2253258],
                [107.78571, 14.2250469],
                [107.7862417, 14.2247287],
                [107.7866221, 14.2243863],
                [107.7870432, 14.2240109],
                [107.7873136, 14.2236697],
                [107.787515, 14.2232904],
                [107.7876868, 14.2227423],
                [107.7878314, 14.2222206],
                [107.7880319, 14.2217763],
                [107.7881806, 14.2215861],
                [107.7885348, 14.2213479],
                [107.788849, 14.2212141],
                [107.7892044, 14.22108],
                [107.7895741, 14.2209978],
                [107.789999, 14.2209278],
                [107.790424, 14.2208708],
                [107.7908909, 14.2208653],
                [107.7913587, 14.2209378],
                [107.7917716, 14.2210111],
                [107.7921028, 14.2211544],
                [107.7925595, 14.2214348],
                [107.7929338, 14.2217162],
                [107.7932795, 14.22192],
                [107.7935278, 14.222008],
                [107.7937475, 14.2220055],
                [107.7939943, 14.2219766],
                [107.7941449, 14.2219358],
                [107.7942815, 14.2218823],
                [107.7944723, 14.2217632],
                [107.7947034, 14.2215656],
                [107.7949778, 14.2213015],
                [107.7951817, 14.2211431],
                [107.7954953, 14.2209575],
                [107.7957681, 14.2208113],
                [107.7960817, 14.2206387],
                [107.7963671, 14.2204014],
                [107.7966927, 14.2200857],
                [107.7969508, 14.2198617],
                [107.7973192, 14.2196689],
                [107.7976748, 14.2195608],
                [107.7979353, 14.2195316],
                [107.798142, 14.2195942],
                [107.7983635, 14.2197346],
                [107.7985436, 14.2198755],
                [107.7986954, 14.2199388],
                [107.7988601, 14.2199368],
                [107.7990788, 14.2198562],
                [107.7994054, 14.2196184],
                [107.7996772, 14.2193943],
                [107.8000438, 14.2190651],
                [107.8002333, 14.218842],
                [107.8004902, 14.2185139],
                [107.8007744, 14.2181858],
                [107.8010591, 14.2178899],
                [107.8013309, 14.2176658],
                [107.8016166, 14.2174545],
                [107.8019293, 14.2172039],
                [107.8021875, 14.2169928],
                [107.8026759, 14.2165192],
                [107.8030293, 14.2162291],
                [107.8033008, 14.215979],
                [107.8035727, 14.2157548],
                [107.8038713, 14.2154826],
                [107.8042381, 14.2151666],
                [107.8047138, 14.2147844],
                [107.8050941, 14.2144422],
                [107.805364, 14.2140625],
                [107.805647, 14.2136306],
                [107.8059574, 14.2131983],
                [107.8062282, 14.2128965],
                [107.8064597, 14.2127379],
                [107.8067874, 14.2125912],
                [107.8069514, 14.2125373],
                [107.8071025, 14.2125355],
                [107.8075348, 14.2125304],
                [107.8080907, 14.2125239],
                [107.8085026, 14.2125191],
                [107.8089156, 14.2126116],
                [107.8093078, 14.2126849],
                [107.8096384, 14.2127785],
                [107.8099269, 14.2127945],
                [107.8103181, 14.2127899],
                [107.8106679, 14.2127663],
                [107.810977, 14.2127823],
                [107.8114106, 14.2128745],
                [107.8117616, 14.2129483],
                [107.8122366, 14.2130596],
                [107.8126903, 14.2131128],
                [107.8130205, 14.2131672],
                [107.8132065, 14.2132637],
                [107.8132487, 14.2133413],
                [107.8134574, 14.2135727],
                [107.8137071, 14.2137841],
                [107.8139565, 14.2139762],
                [107.8142479, 14.214226],
                [107.8145167, 14.2143204],
                [107.8147845, 14.2143367],
                [107.815011, 14.2143341],
                [107.8153395, 14.2142522],
                [107.8157896, 14.2140325],
                [107.8161781, 14.2138135],
                [107.8164854, 14.213693],
                [107.8167325, 14.21369],
                [107.8170003, 14.2137064],
                [107.8172694, 14.2138201],
                [107.8176021, 14.2140892],
                [107.8179149, 14.214407],
                [107.8183106, 14.2147726],
                [107.8186664, 14.2152363],
                [107.8189819, 14.2157784],
                [107.8192764, 14.2162816],
                [107.8195697, 14.2166875],
                [107.8199869, 14.2171308],
                [107.820425, 14.2175935],
                [107.8206987, 14.2180775],
                [107.8208202, 14.2184627],
                [107.8208237, 14.2187399],
                [107.820818, 14.2190172],
                [107.8207934, 14.2192513],
                [107.8207503, 14.2194684],
                [107.8206795, 14.2196598],
                [107.8205636, 14.2199124],
                [107.8204014, 14.2201093],
                [107.8201842, 14.2203024],
                [107.8199402, 14.2205478],
                [107.8197967, 14.220792],
                [107.8197082, 14.2210357],
                [107.819674, 14.2212267],
                [107.8196769, 14.2214605],
                [107.8197159, 14.221655],
                [107.8198005, 14.2218359],
                [107.81994, 14.2220161],
                [107.8201724, 14.222308],
                [107.8203315, 14.222592],
                [107.8205192, 14.2229709],
                [107.8206238, 14.2232858],
                [107.8207194, 14.223614],
                [107.8208149, 14.223942],
                [107.8209474, 14.2242956],
                [107.8211159, 14.2245967],
                [107.8212369, 14.2247599],
                [107.8213759, 14.2248969],
                [107.821505, 14.2249734],
                [107.8216333, 14.2249979],
                [107.821789, 14.2249961],
                [107.8219531, 14.2249594],
                [107.8221903, 14.2248916],
                [107.822372, 14.2247855],
                [107.8225258, 14.2246538],
                [107.8226522, 14.2245137],
                [107.822815, 14.2243559],
                [107.8229238, 14.2242766],
                [107.8230061, 14.2242669],
                [107.823171, 14.2242823],
                [107.8232902, 14.2243069],
                [107.8234468, 14.2243917],
                [107.8235764, 14.2245115],
                [107.8237066, 14.2246745],
                [107.8239017, 14.2249147],
                [107.824097, 14.2251637],
                [107.8243108, 14.225434],
                [107.8245617, 14.2257429],
                [107.824757, 14.2259831],
                [107.8249428, 14.2262062],
                [107.8251091, 14.2263428],
                [107.8252659, 14.2264449],
                [107.8254685, 14.2265465],
                [107.8257629, 14.2266643],
                [107.8260023, 14.2267827],
                [107.8261962, 14.2269277],
                [107.8263167, 14.2270519],
                [107.8263917, 14.2271983],
                [107.8265042, 14.2274135],
                [107.8265798, 14.2276032],
                [107.8266466, 14.227819],
                [107.8267505, 14.2280862],
                [107.8268636, 14.2283448],
                [107.8269769, 14.2286234],
                [107.8270712, 14.2288561],
                [107.8271658, 14.2290973],
                [107.8272888, 14.2294247],
                [107.8273838, 14.2297093],
                [107.8274331, 14.2299944],
                [107.8274458, 14.2302799],
                [107.8274663, 14.2306367],
                [107.8275542, 14.2310836],
                [107.8277052, 14.2316271],
                [107.8279372, 14.2320723],
                [107.8281069, 14.2324793],
                [107.8283283, 14.2328954],
                [107.828547, 14.2330973],
                [107.8288269, 14.2332401],
                [107.8291051, 14.233266],
                [107.8295059, 14.2332028],
                [107.8298138, 14.2330737],
                [107.8298198, 14.2330603],
                [107.8299232, 14.233017],
                [107.8304156, 14.2328944],
                [107.8313379, 14.2325913],
                [107.8321369, 14.2322899],
                [107.832967, 14.2320172],
                [107.8334892, 14.2318066],
                [107.8339831, 14.2318007],
                [107.8344774, 14.2318241],
                [107.8349727, 14.231935],
                [107.8356555, 14.2322189],
                [107.8362772, 14.2325619],
                [107.8369632, 14.2331086],
                [107.8374041, 14.2336172],
                [107.8378331, 14.2345982],
                [107.8382719, 14.236368],
                [107.838294, 14.2381428],
                [107.838217, 14.2403132],
                [107.837931, 14.2424368],
                [107.8377556, 14.2450685],
                [107.8377083, 14.2468441],
                [107.83747, 14.2500026],
                [107.8372227, 14.252438],
                [107.8368356, 14.2548092],
                [107.8365205, 14.257377],
                [107.8361733, 14.2592328],
                [107.8359155, 14.2600151],
                [107.8357396, 14.2607574],
                [107.8356254, 14.261499],
                [107.8353914, 14.2625217],
                [107.8353327, 14.2627783],
                [107.8352608, 14.2636168],
                [107.8352091, 14.2644161],
                [107.835276, 14.2648243],
                [107.8354275, 14.2654263],
                [107.8356839, 14.2661732],
                [107.835896, 14.2666772],
                [107.83617, 14.2671998],
                [107.8365272, 14.26778],
                [107.8369047, 14.2683403],
                [107.8373211, 14.2687249],
                [107.8377579, 14.2690899],
                [107.8384424, 14.2695165],
                [107.8392096, 14.2699744],
                [107.840163, 14.2705081],
                [107.8409501, 14.2709074],
                [107.8418602, 14.2712664],
                [107.8427484, 14.2715283],
                [107.8433469, 14.2716574],
                [107.844575, 14.2718161],
                [107.845564, 14.2719211],
                [107.8466466, 14.2721127],
                [107.8476367, 14.2723053],
                [107.8482561, 14.2724732],
                [107.8486903, 14.2726433],
                [107.8490026, 14.2729317],
                [107.8492855, 14.2733372],
                [107.849538, 14.2737818],
                [107.8497349, 14.2747135],
                [107.849996, 14.2758487],
                [107.8512573, 14.2755462],
                [107.8525919, 14.2753032],
                [107.8543707, 14.274925],
                [107.8558764, 14.2746475],
                [107.85697, 14.2743425],
                [107.8578576, 14.2740075],
                [107.8587778, 14.2735422],
                [107.8594943, 14.2732418],
                [107.8600764, 14.2731698],
                [107.8607296, 14.2732918],
                [107.8616262, 14.2736702],
                [107.8631238, 14.2744604],
                [107.863249, 14.2745265],
                [107.8644583, 14.2752256],
                [107.8660498, 14.2763092],
                [107.8666381, 14.2767238],
                [107.8671558, 14.2769771],
                [107.8674762, 14.2770611],
                [107.8677507, 14.2770577],
                [107.8681623, 14.2770528],
                [107.8688456, 14.2768283],
                [107.8697341, 14.276558],
                [107.8707136, 14.2762434],
                [107.8716013, 14.2759083],
                [107.8720795, 14.2757511],
                [107.8724672, 14.2756599],
                [107.8726961, 14.2756787],
                [107.8729725, 14.2758268],
                [107.87316, 14.2761923],
                [107.8733741, 14.2768386],
                [107.8735168, 14.2772694],
                [107.8736815, 14.2776351],
                [107.8739854, 14.2781505],
                [107.8744496, 14.2786856],
                [107.8749585, 14.2791336],
                [107.8753756, 14.2795613],
                [107.8757007, 14.2799467],
                [107.875912, 14.2803765],
                [107.8761704, 14.2809142],
                [107.8762899, 14.2813237],
                [107.8763239, 14.2819139],
                [107.8763824, 14.2825612],
                [107.8765982, 14.2827293],
                [107.8772197, 14.282495],
                [107.878544, 14.2823275],
                [107.8798893, 14.2820082],
                [107.8815755, 14.2815115],
                [107.8837179, 14.2809228],
                [107.8856097, 14.2804128],
                [107.8870007, 14.2800929],
                [107.8882565, 14.2799262],
                [107.8893536, 14.2798696],
                [107.8905422, 14.2798118],
                [107.891433, 14.2797252],
                [107.8922088, 14.279586],
                [107.8932119, 14.2793356],
                [107.894031, 14.2790011],
                [107.8949859, 14.2785566],
                [107.8957134, 14.278223],
                [107.8968292, 14.2778414],
                [107.8978556, 14.2776234],
                [107.8986303, 14.2773974],
                [107.8993562, 14.276934],
                [107.9002148, 14.2761226],
                [107.9010275, 14.2752902],
                [107.9020219, 14.2743689],
                [107.9031074, 14.2734033],
                [107.9044614, 14.2719907],
                [107.9052961, 14.271093],
                [107.9060856, 14.2702393],
                [107.906807, 14.2694296],
                [107.9080013, 14.2680405],
                [107.908564, 14.267341],
                [107.9090598, 14.2667722],
                [107.909511, 14.2662905],
                [107.9100531, 14.2657644],
                [107.910686, 14.265183],
                [107.9114564, 14.2646325],
                [107.9124762, 14.2639057],
                [107.9133166, 14.2634625],
                [107.9139982, 14.2631078],
                [107.9148173, 14.2627948],
                [107.9157046, 14.2624377],
                [107.9166148, 14.2620802],
                [107.9174097, 14.2616484],
                [107.917932, 14.2613606],
                [107.9184302, 14.2609866],
                [107.9189496, 14.2604823],
                [107.9193986, 14.2598275],
                [107.9200223, 14.2580469],
                [107.9202471, 14.2578558],
                [107.9206535, 14.2574204],
                [107.9210458, 14.2569854],
                [107.9213266, 14.2567398],
                [107.9216913, 14.2563722],
                [107.921971, 14.2560325],
                [107.9222007, 14.2556666],
                [107.922394, 14.2552338],
                [107.922517, 14.2548558],
                [107.9226667, 14.2543428],
                [107.9228525, 14.2538832],
                [107.9230181, 14.2535047],
                [107.9232826, 14.2530845],
                [107.9235334, 14.2527048],
                [107.9238135, 14.252392],
                [107.9240939, 14.2521061],
                [107.9244449, 14.2517791],
                [107.9248443, 14.2513572],
                [107.9252093, 14.2510166],
                [107.9256716, 14.2505133],
                [107.9261763, 14.249996],
                [107.9266812, 14.2494922],
                [107.926997, 14.2491924],
                [107.9272209, 14.2489341],
                [107.9274156, 14.2486089],
                [107.9275677, 14.2482843],
                [107.9276772, 14.2479601],
                [107.9277433, 14.2475828],
                [107.9277861, 14.2469032],
                [107.9278624, 14.2465601],
                [107.927999, 14.2461183],
                [107.9282014, 14.2457858],
                [107.9283412, 14.2456007],
                [107.9287624, 14.2451922],
                [107.9290808, 14.2448215],
                [107.9292842, 14.2445623],
                [107.9295134, 14.2443028],
                [107.9297936, 14.2439815],
                [107.9302023, 14.2435975],
                [107.9304699, 14.2433132],
                [107.9307116, 14.2430167],
                [107.9309786, 14.2426712],
                [107.931169, 14.2424],
                [107.9314547, 14.2420075],
                [107.9315896, 14.2418396],
                [107.9317726, 14.241612],
                [107.9319758, 14.2413281],
                [107.9321145, 14.2410694],
                [107.9321768, 14.2408851],
                [107.9322268, 14.2407499],
                [107.9324717, 14.2406984],
                [107.9329571, 14.2404858],
                [107.9337116, 14.2402092],
                [107.9343385, 14.240007],
                [107.9348504, 14.2398549],
                [107.9353628, 14.2397393],
                [107.9359133, 14.2395867],
                [107.9364515, 14.2394829],
                [107.9370797, 14.2393779],
                [107.937606, 14.2393471],
                [107.9384023, 14.2393251],
                [107.9395912, 14.2393616],
                [107.9402724, 14.2393897],
                [107.940928, 14.2394182],
                [107.9414805, 14.2394235],
                [107.9421491, 14.239464],
                [107.9429219, 14.2396168],
                [107.9436345, 14.2398497],
                [107.9443207, 14.2402438],
                [107.9449519, 14.2407729],
                [107.9456977, 14.2413811],
                [107.9463026, 14.2420715],
                [107.9466221, 14.2426313],
                [107.9471135, 14.2433231],
                [107.9475449, 14.2437563],
                [107.9479467, 14.2441005],
                [107.9481759, 14.2442723],
                [107.9483181, 14.2442974],
                [107.9484415, 14.2442959],
                [107.948531, 14.2442948],
                [107.948756, 14.2441442],
                [107.9490363, 14.2438722],
                [107.9493994, 14.2434244],
                [107.9496765, 14.2429106],
                [107.9499246, 14.2423434],
                [107.9502432, 14.2417484],
                [107.9505207, 14.2412615],
                [107.9511864, 14.2400779],
                [107.9515914, 14.2395759],
                [107.9520104, 14.2390603],
                [107.9527815, 14.2383422],
                [107.9537689, 14.2373427],
                [107.9545456, 14.2365171],
                [107.9548378, 14.2360702],
                [107.9550659, 14.235604],
                [107.9551042, 14.2352811],
                [107.9550995, 14.2349185],
                [107.9551792, 14.2344945],
                [107.9554053, 14.2338872],
                [107.9560512, 14.2328214],
                [107.9564052, 14.2322126],
                [107.9568224, 14.2315627],
                [107.9573248, 14.2309117],
                [107.957827, 14.2302608],
                [107.9583937, 14.2296393],
                [107.9587494, 14.2291715],
                [107.9592328, 14.228702],
                [107.9595712, 14.2285366],
                [107.9601219, 14.2283284],
                [107.96061, 14.2282215],
                [107.9610781, 14.2282157],
                [107.9616104, 14.2282293],
                [107.9622086, 14.2284032],
                [107.9626786, 14.2285384],
                [107.9632773, 14.2287526],
                [107.964134, 14.2291651],
                [107.9647344, 14.2295103],
                [107.9657426, 14.2301022],
                [107.9665388, 14.2307773],
                [107.9670146, 14.2313556],
                [107.9675127, 14.2320144],
                [107.9679886, 14.2325927],
                [107.968703, 14.2335207],
                [107.9692891, 14.2343999],
                [107.9696357, 14.234859],
                [107.9700019, 14.2351969],
                [107.970584, 14.2357539],
                [107.9712706, 14.2361885],
                [107.9720651, 14.2367126],
                [107.9729018, 14.237226],
                [107.9737164, 14.2376591],
                [107.9744016, 14.237973],
                [107.9754296, 14.2384638],
                [107.9759437, 14.2387193],
                [107.9765018, 14.2390751],
                [107.9769755, 14.2394922],
                [107.9776433, 14.2400984],
                [107.977946, 14.2404572],
                [107.9789392, 14.2415328],
                [107.9792204, 14.2418718],
                [107.9797388, 14.2424496],
                [107.9802588, 14.2431484],
                [107.9808212, 14.2438364],
                [107.981383, 14.244474],
                [107.9816412, 14.2446925],
                [107.9818774, 14.2448507],
                [107.9821126, 14.2449284],
                [107.9823257, 14.2449458],
                [107.98273, 14.2449408],
                [107.9831759, 14.2448546],
                [107.983907, 14.2449016],
                [107.9843762, 14.2449628],
                [107.9850445, 14.2450619],
                [107.9856849, 14.2451882],
                [107.9862823, 14.245288],
                [107.986908, 14.2453742],
                [107.9873917, 14.245462],
                [107.9878318, 14.24547],
                [107.9882433, 14.2454648],
                [107.9886969, 14.2454189],
                [107.98908, 14.2454141],
                [107.989521, 14.2454891],
                [107.9907321, 14.245582],
                [107.9916908, 14.2456304],
                [107.9919912, 14.245593],
                [107.9924118, 14.2453446],
                [107.9928206, 14.2451813],
                [107.993333, 14.2450898],
                [107.9941657, 14.2449335],
                [107.9946783, 14.2448419],
                [107.9951262, 14.2447268],
                [107.9955475, 14.2445392],
                [107.9959052, 14.2443888],
                [107.996327, 14.2442376],
                [107.9966734, 14.2442089],
                [107.9970969, 14.2441914],
                [107.9975593, 14.2441978],
                [107.9980613, 14.2442766],
                [107.9989766, 14.2445447],
                [107.9997382, 14.2448391],
                [108.0002535, 14.2449542],
                [108.0003804, 14.2449769],
                [108.0010641, 14.2452563],
                [108.0014768, 14.2452834],
                [108.0017969, 14.2451765],
                [108.0019066, 14.2450722],
                [108.0020239, 14.2449328],
                [108.0022076, 14.2446885],
                [108.0024056, 14.2444583],
                [108.0025485, 14.244291],
                [108.0027749, 14.2440966],
                [108.002979, 14.2439512],
                [108.0032342, 14.2437641],
                [108.0035036, 14.2435843],
                [108.0037731, 14.2434045],
                [108.0040388, 14.2432387],
                [108.0042428, 14.2430932],
                [108.0044543, 14.2429338],
                [108.0046734, 14.2427463],
                [108.0048637, 14.2425444],
                [108.0050469, 14.2423424],
                [108.0052123, 14.2421118],
                [108.0053884, 14.2418956],
                [108.0056079, 14.2416728],
                [108.0058276, 14.2414432],
                [108.0060327, 14.2412131],
                [108.006216, 14.240997],
                [108.0064036, 14.2407316],
                [108.0065507, 14.2405291],
                [108.006712, 14.2403338],
                [108.0069388, 14.2401111],
                [108.0071725, 14.2399097],
                [108.0073477, 14.2397639],
                [108.007392, 14.2397683],
                [108.007672, 14.2397961],
                [108.0079208, 14.2398204],
                [108.0082019, 14.2398521],
                [108.0086199, 14.2399067],
                [108.0089587, 14.2399462],
                [108.009276, 14.2399713],
                [108.0096258, 14.2399968],
                [108.0098638, 14.2400139],
                [108.0101239, 14.2399961],
                [108.0104633, 14.2399862],
                [108.0107665, 14.23999],
                [108.011077, 14.2399868],
                [108.0113978, 14.240019],
                [108.0116858, 14.240072],
                [108.0119237, 14.2401031],
                [108.0121542, 14.2401342],
                [108.0123995, 14.2401514],
                [108.0126664, 14.2401688],
                [108.0128829, 14.2401715],
                [108.0132476, 14.240162],
                [108.0134785, 14.2401648],
                [108.0137527, 14.2401753],
                [108.0140124, 14.2401928],
                [108.0142645, 14.240231],
                [108.0146534, 14.2403065],
                [108.0148688, 14.2403937],
                [108.0150012, 14.2404728],
                [108.0151367, 14.2406084],
                [108.0151703, 14.240799],
                [108.0151891, 14.2410107],
                [108.015178, 14.2413136],
                [108.0151636, 14.2415812],
                [108.0151746, 14.2418421],
                [108.0151935, 14.2420538],
                [108.015226, 14.2423289],
                [108.0152455, 14.242766],
                [108.0152637, 14.2430271],
                [108.0152759, 14.2431963],
                [108.0153163, 14.2434152],
                [108.0153865, 14.2435712],
                [108.0154927, 14.2437276],
                [108.0156137, 14.2438559],
                [108.0157531, 14.2439703],
                [108.0160033, 14.2441497],
                [108.0163036, 14.2443719],
                [108.0165611, 14.2445512],
                [108.0167899, 14.2447303],
                [108.0169107, 14.2448727],
                [108.0169553, 14.2450425],
                [108.0169663, 14.2453104],
                [108.0169844, 14.2455783],
                [108.0169956, 14.2458251],
                [108.017009, 14.2461775],
                [108.0170131, 14.2464101],
                [108.0170071, 14.2468752],
                [108.0169612, 14.2473467],
                [108.0169504, 14.2476215],
                [108.0169173, 14.2479381],
                [108.0168924, 14.2481844],
                [108.0168413, 14.248501],
                [108.0168096, 14.2487119],
                [108.0167486, 14.2489648],
                [108.0166735, 14.2491823],
                [108.01662, 14.2494071],
                [108.0165996, 14.2495831],
                [108.0166259, 14.2497807],
                [108.016674, 14.2499716],
                [108.0167576, 14.2501981],
                [108.0168134, 14.2503468],
                [108.0169898, 14.250659],
                [108.0170747, 14.2507869],
                [108.017255, 14.2508033],
                [108.0174355, 14.2508056],
                [108.0176847, 14.2507946],
                [108.0178656, 14.2507616],
                [108.0180973, 14.2507082],
                [108.018293, 14.2506541],
                [108.0185395, 14.2505727],
                [108.018873, 14.2504642],
                [108.0191121, 14.2503967],
                [108.0193189, 14.2503218],
                [108.0195729, 14.2502193],
                [108.0197977, 14.2501446],
                [108.0200436, 14.2501124],
                [108.0202384, 14.2501148],
                [108.0203973, 14.2501167],
                [108.0206133, 14.2501547],
                [108.020829, 14.2502209],
                [108.0210625, 14.2503083],
                [108.0212925, 14.2503887],
                [108.0215154, 14.250455],
                [108.0217745, 14.2505216],
                [108.0220047, 14.2505808],
                [108.0222563, 14.2506615],
                [108.0225652, 14.2507781],
                [108.0228415, 14.2509084],
                [108.0231071, 14.2510244],
                [108.0233511, 14.2511332],
                [108.0236311, 14.2512494],
                [108.0238607, 14.251358],
                [108.0240684, 14.2514874],
                [108.0242972, 14.2516524],
                [108.0245721, 14.2518883],
                [108.0247432, 14.2520595],
                [108.0248563, 14.2522371],
                [108.0249403, 14.2524426],
                [108.0249879, 14.2526616],
                [108.0250144, 14.2528451],
                [108.0250662, 14.2530219],
                [108.0251647, 14.2532205],
                [108.0252777, 14.2534121],
                [108.0253755, 14.25366],
                [108.0254163, 14.2538508],
                [108.0254448, 14.2541471],
                [108.0254191, 14.2544569],
                [108.0253878, 14.2546537],
                [108.0252901, 14.2549415],
                [108.025167, 14.255243],
                [108.0250559, 14.2554529],
                [108.0249239, 14.2556204],
                [108.0247264, 14.2558083],
                [108.0243906, 14.2560859],
                [108.0241235, 14.2563715],
                [108.0240498, 14.2564834],
                [108.024034, 14.2565818],
                [108.0240539, 14.2567229],
                [108.0241389, 14.2568437],
                [108.024239, 14.2569085],
                [108.0244614, 14.257024],
                [108.0246838, 14.2571324],
                [108.0248412, 14.2572402],
                [108.0250083, 14.2574324],
                [108.0251997, 14.2577026],
                [108.025334, 14.2579229],
                [108.0255252, 14.2582141],
                [108.02574, 14.2586184],
                [108.0258746, 14.2588174],
                [108.025966, 14.2590017],
                [108.0260632, 14.259299],
                [108.0261542, 14.2595186],
                [108.0262559, 14.2597453],
                [108.0263969, 14.2600007],
                [108.0265449, 14.2602775],
                [108.0266433, 14.260483],
                [108.0267703, 14.260703],
                [108.0268726, 14.2608946],
                [108.0270568, 14.2611576],
                [108.0272202, 14.2613569],
                [108.027384, 14.2615281],
                [108.0275762, 14.2617349],
                [108.0277861, 14.2619771],
                [108.0279496, 14.2621693],
                [108.0281061, 14.2623475],
                [108.0283059, 14.2625332],
                [108.0285193, 14.2627684],
                [108.0287217, 14.2630317],
                [108.028914, 14.2632314],
                [108.0291207, 14.2634313],
                [108.0293418, 14.2636384],
                [108.0296135, 14.2638391],
                [108.0297992, 14.2640035],
                [108.0299668, 14.2641536],
                [108.0301812, 14.2643254],
                [108.0304242, 14.2645116],
                [108.0306455, 14.2647046],
                [108.0308163, 14.2648899],
                [108.0309511, 14.2650819],
                [108.0310246, 14.2652449],
                [108.0310941, 14.2654571],
                [108.0310992, 14.2656193],
                [108.0310742, 14.2658726],
                [108.0310198, 14.2661679],
                [108.0309996, 14.2663298],
                [108.0312437, 14.2664385],
                [108.0315813, 14.2665695],
                [108.0318325, 14.2666854],
                [108.0320116, 14.2667863],
                [108.032176, 14.2669152],
                [108.0323972, 14.2671152],
                [108.0326283, 14.2673858],
                [108.0327845, 14.2675852],
                [108.0328909, 14.2677416],
                [108.0330324, 14.2679548],
                [108.0331597, 14.2681606],
                [108.0333312, 14.2685715],
                [108.0334437, 14.2688054],
                [108.033542, 14.269011],
                [108.0336692, 14.269224],
                [108.0337892, 14.2694298],
                [108.0339836, 14.2697494],
                [108.0341103, 14.2699976],
                [108.0342012, 14.2702242],
                [108.0342634, 14.2704434],
                [108.0343041, 14.2709232],
                [108.0343439, 14.2711843],
                [108.0343557, 14.2713889],
                [108.034395, 14.2716924],
                [108.034422, 14.2721084],
                [108.0344546, 14.2723838],
                [108.0344805, 14.2726095],
                [108.0344985, 14.2728846],
                [108.0344898, 14.273279],
                [108.0345006, 14.273554],
                [108.0345116, 14.2738149],
                [108.0345514, 14.2740902],
                [108.0345928, 14.2742316],
                [108.0347222, 14.2742756],
                [108.0348732, 14.2743197],
                [108.0350605, 14.2743502],
                [108.0353054, 14.2744026],
                [108.035572, 14.2744412],
                [108.0358424, 14.2744657],
                [108.0360518, 14.2744683],
                [108.036355, 14.2744721],
                [108.0366218, 14.2744965],
                [108.0367946, 14.2745339],
                [108.0369455, 14.2745992],
                [108.037103, 14.2746998],
                [108.037253, 14.2748215],
                [108.0373883, 14.2749641],
                [108.0375555, 14.2751494],
                [108.0378048, 14.2754132],
                [108.0380115, 14.2756202],
                [108.038211, 14.2758199],
                [108.0384177, 14.2760269],
                [108.0385988, 14.2762617],
                [108.038827, 14.2764759],
                [108.0390122, 14.2766685],
                [108.0392262, 14.2768684],
                [108.0394042, 14.277061],
                [108.0395964, 14.2772677],
                [108.0396965, 14.2773465],
                [108.039826, 14.2773832],
                [108.0400247, 14.2773717],
                [108.0402343, 14.2773602],
                [108.0405308, 14.2773216],
                [108.0407408, 14.277282],
                [108.041052, 14.2772153],
                [108.0414138, 14.2771634],
                [108.0417209, 14.2771449],
                [108.04201, 14.2771203],
                [108.0422631, 14.2770953],
                [108.0425307, 14.2770563],
                [108.0427768, 14.2770101],
                [108.0429797, 14.2769563],
                [108.0431685, 14.2768742],
                [108.0432779, 14.2767839],
                [108.0433955, 14.2766305],
                [108.0435213, 14.2763997],
                [108.0436031, 14.2762177],
                [108.0437064, 14.2760427],
                [108.0438605, 14.2758473],
                [108.0440878, 14.2755754],
                [108.0442897, 14.2753172],
                [108.044444, 14.2751149],
                [108.0445908, 14.2749265],
                [108.0447374, 14.2747592],
                [108.0448844, 14.2745567],
                [108.0450242, 14.274354],
                [108.0451235, 14.2742074],
                [108.0452706, 14.2739978],
                [108.0453961, 14.273795],
                [108.045507, 14.2735921],
                [108.0456544, 14.2733544],
                [108.0457788, 14.2732221],
                [108.0459537, 14.2731046],
                [108.0461024, 14.27305],
                [108.0463124, 14.2729962],
                [108.0465079, 14.2729493],
                [108.0467251, 14.2729027],
                [108.0469634, 14.2728986],
                [108.0472086, 14.2729157],
                [108.0474757, 14.2729191],
                [108.0476779, 14.2729146],
                [108.0478188, 14.2728951],
                [108.048036, 14.2728486],
                [108.0482751, 14.272781],
                [108.0485, 14.2726993],
                [108.0487106, 14.2725962],
                [108.0488633, 14.2725136],
                [108.0490673, 14.2723752],
                [108.0492567, 14.2722366],
                [108.0494533, 14.2721051],
                [108.0496318, 14.2719806],
                [108.0498429, 14.2718493],
                [108.0501412, 14.2716628],
                [108.0503815, 14.2715108],
                [108.0506149, 14.2713305],
                [108.0508846, 14.2711226],
                [108.0512015, 14.270908],
                [108.0514418, 14.2707419],
                [108.0516458, 14.2705965],
                [108.0518502, 14.2704229],
                [108.0520764, 14.2702425],
                [108.052303, 14.2700198],
                [108.0524822, 14.2698389],
                [108.0526434, 14.2696577],
                [108.0528049, 14.2694554],
                [108.0530103, 14.2691973],
                [108.0532153, 14.2689813],
                [108.0534023, 14.2687511],
                [108.053549, 14.2685768],
                [108.0537322, 14.2683678],
                [108.0539225, 14.2681587],
                [108.0541417, 14.2679642],
                [108.0543385, 14.2678116],
                [108.0544885, 14.2676586],
                [108.0546569, 14.2674774],
                [108.054847, 14.2672895],
                [108.0550224, 14.2671226],
                [108.0552555, 14.2669634],
                [108.0555754, 14.2667912],
                [108.055895, 14.2666332],
                [108.0561746, 14.2665027],
                [108.056393, 14.2663645],
                [108.0566542, 14.2662551],
                [108.0569736, 14.2661251],
                [108.0572274, 14.2660366],
                [108.0576331, 14.2659219],
                [108.0579551, 14.2658624],
                [108.0583169, 14.2657965],
                [108.0586425, 14.265737],
                [108.0590045, 14.2656571],
                [108.0593947, 14.2656266],
                [108.059669, 14.265623],
                [108.059979, 14.265655],
                [108.0601591, 14.2656853],
                [108.0600051, 14.2658737],
                [108.0599236, 14.2660276],
                [108.0598419, 14.2662098],
                [108.0597046, 14.2664971],
                [108.0596073, 14.2667636],
                [108.0595027, 14.2670371],
                [108.0593838, 14.2672963],
                [108.0592531, 14.2676328],
                [108.0592002, 14.2678153],
                [108.0591683, 14.2680475],
                [108.0591516, 14.2682234],
                [108.059171, 14.2683997],
                [108.0592132, 14.2687596],
                [108.059253, 14.2690278],
                [108.0592936, 14.2692397],
                [108.0593338, 14.2694797],
                [108.0593527, 14.2696984],
                [108.0594112, 14.2699175],
                [108.0595093, 14.2701442],
                [108.0595938, 14.2703073],
                [108.0597494, 14.2705557],
                [108.0598765, 14.2707687],
                [108.060004, 14.2709605],
                [108.0600999, 14.2710815],
                [108.0602352, 14.271224],
                [108.0604139, 14.2713601],
                [108.0605929, 14.2714751],
                [108.060779, 14.2715971],
                [108.0610154, 14.2717338],
                [108.0612736, 14.2718639],
                [108.0617722, 14.2721026],
                [108.0620595, 14.2722189],
                [108.0623177, 14.2723418],
                [108.0626049, 14.2724651],
                [108.0627985, 14.2725732],
                [108.0629054, 14.2726732],
                [108.0629402, 14.2727722],
                [108.0629388, 14.2728779],
                [108.0629008, 14.2730324],
                [108.0628438, 14.2732502],
                [108.0627977, 14.2734609],
                [108.0627445, 14.2736645],
                [108.0626396, 14.2739662],
                [108.0625787, 14.274212],
                [108.0625504, 14.2744442],
                [108.0625764, 14.2746559],
                [108.0626391, 14.2748398],
                [108.0627795, 14.2751375],
                [108.0628996, 14.2753504],
                [108.0629846, 14.2754712],
                [108.0630847, 14.2755428],
                [108.0632068, 14.2755936],
                [108.0634405, 14.27566],
                [108.0637429, 14.2757271],
                [108.0639805, 14.2757794],
                [108.0642105, 14.2758526],
                [108.0644184, 14.2759608],
                [108.0646115, 14.2761042],
                [108.0646227, 14.2763509],
                [108.0646204, 14.2765269],
                [108.0646142, 14.2767313],
                [108.0646181, 14.2769849],
                [108.0646374, 14.2771684],
                [108.0646634, 14.2773941],
                [108.0647039, 14.277606],
                [108.0647331, 14.277853],
                [108.0647881, 14.278072],
                [108.0648577, 14.2782772],
                [108.0649344, 14.2784754],
                [108.0651476, 14.2790136],
                [108.0652872, 14.2793886],
                [108.0653778, 14.2796364],
                [108.0654538, 14.2798979],
                [108.0655605, 14.280308],
                [108.0656567, 14.2806755],
                [108.0657321, 14.2809864],
                [108.0657945, 14.2811844],
                [108.0658437, 14.2812837],
                [108.0659324, 14.2814046],
                [108.0661176, 14.281597],
                [108.0663533, 14.2817902],
                [108.0665744, 14.2819973],
                [108.0667886, 14.282183],
                [108.0669601, 14.282319],
                [108.0672206, 14.2825547],
                [108.0675272, 14.2828404],
                [108.0677341, 14.2830331],
                [108.0679622, 14.2832613],
                [108.0681686, 14.2834893],
                [108.0683777, 14.2837879],
                [108.0684555, 14.2839156],
                [108.0684822, 14.284078],
                [108.0684655, 14.284254],
                [108.06842, 14.2844225],
                [108.0683603, 14.2845767],
                [108.0682502, 14.2847163],
                [108.0680418, 14.2849181],
                [108.0677936, 14.2851334],
                [108.0675891, 14.2853211],
                [108.067355, 14.2855507],
                [108.0671719, 14.2857528],
                [108.0669191, 14.2860386],
                [108.0667865, 14.2862483],
                [108.0667416, 14.2863674],
                [108.0667327, 14.2864942],
                [108.0667454, 14.2866283],
                [108.0667872, 14.2867485],
                [108.0668935, 14.2869048],
                [108.0670219, 14.2870262],
                [108.06719, 14.287141],
                [108.0674619, 14.2873274],
                [108.0676622, 14.2874709],
                [108.0678905, 14.287678],
                [108.068011, 14.2878485],
                [108.0680596, 14.2879971],
                [108.0680288, 14.2881518],
                [108.0679617, 14.288313],
                [108.0678796, 14.2885234],
                [108.0677567, 14.2888177],
                [108.0676884, 14.2890635],
                [108.0676003, 14.289457],
                [108.067546, 14.2897451],
                [108.0675217, 14.2899491],
                [108.0675273, 14.2900831],
                [108.067533, 14.290196],
                [108.0675817, 14.2903444],
                [108.0676623, 14.2905216],
                [108.0678251, 14.2907701],
                [108.0679743, 14.2909622],
                [108.0681162, 14.2911541],
                [108.0682724, 14.2913605],
                [108.0684145, 14.2915242],
                [108.0686247, 14.2917453],
                [108.0688317, 14.291931],
                [108.0690813, 14.2921666],
                [108.0692882, 14.2923593],
                [108.0694954, 14.2925239],
                [108.0698562, 14.2928173],
                [108.0700847, 14.2930104],
                [108.0702776, 14.2931677],
                [108.0704919, 14.2933394],
                [108.070728, 14.2935114],
                [108.0709786, 14.2936695],
                [108.0711791, 14.2937918],
                [108.071484, 14.2939434],
                [108.0717349, 14.2940804],
                [108.0718781, 14.2941738],
                [108.0720066, 14.2942951],
                [108.0720845, 14.2944018],
                [108.0721546, 14.2945717],
                [108.0722026, 14.2947625],
                [108.0722603, 14.2950451],
                [108.0722931, 14.2952991],
                [108.0722752, 14.2955666],
                [108.0722436, 14.2957776],
                [108.0721345, 14.2961145],
                [108.0720439, 14.2964162],
                [108.0719612, 14.296676],
                [108.0719015, 14.2968232],
                [108.0717767, 14.2969837],
                [108.07159, 14.2971857],
                [108.0713849, 14.2974156],
                [108.071202, 14.2975966],
                [108.0710628, 14.2977499],
                [108.0709665, 14.2979459],
                [108.0708653, 14.2982266],
                [108.070805, 14.2984301],
                [108.0707523, 14.2985985],
                [108.0707217, 14.2987321],
                [108.0707273, 14.2988519],
                [108.0707977, 14.2989936],
                [108.071028, 14.2993276],
                [108.0712272, 14.2995555],
                [108.0713698, 14.2996912],
                [108.07157, 14.2998487],
                [108.0717631, 14.2999919],
                [108.0720212, 14.3001289],
                [108.0722649, 14.3002658],
                [108.0726055, 14.3004391],
                [108.0730507, 14.3006349],
                [108.073359, 14.3008007],
                [108.0735018, 14.3009222],
                [108.0734623, 14.3011824],
                [108.0734094, 14.301365],
                [108.0732429, 14.3016799],
                [108.073132, 14.3018759],
                [108.0730358, 14.3020649],
                [108.0729464, 14.3022751],
                [108.0728598, 14.3025489],
                [108.072784, 14.3028298],
                [108.0727158, 14.3030826],
                [108.0726619, 14.3033355],
                [108.0726152, 14.3035956],
                [108.0725941, 14.3038349],
                [108.0726056, 14.3040605],
                [108.0726464, 14.3042583],
                [108.072707, 14.3045972],
                [108.0727929, 14.3049294],
                [108.0729045, 14.3052337],
                [108.0729882, 14.3054602],
                [108.0730433, 14.3056653],
                [108.0730989, 14.3058279],
                [108.073169, 14.3059839],
                [108.0732361, 14.3061044],
                [108.0734068, 14.3063038],
                [108.0736134, 14.3065107],
                [108.073799, 14.306682],
                [108.0739921, 14.3068253],
                [108.0741852, 14.3069616],
                [108.0744004, 14.307077],
                [108.0747915, 14.3072579],
                [108.0751146, 14.3073957],
                [108.0754307, 14.3075194],
                [108.0756965, 14.3076213],
                [108.0759336, 14.3077158],
                [108.0762353, 14.3078323],
                [108.0766559, 14.3079713],
                [108.0769652, 14.3080597],
                [108.0773106, 14.3081484],
                [108.0776917, 14.3082659],
                [108.0779362, 14.3083464],
                [108.0781227, 14.3084403],
                [108.07828, 14.3085549],
                [108.0784974, 14.3087761],
                [108.0786753, 14.3089755],
                [108.0788604, 14.309175],
                [108.0790739, 14.3094172],
                [108.0792092, 14.3095668],
                [108.0793449, 14.3096812],
                [108.0794883, 14.3097605],
                [108.0797359, 14.3098692],
                [108.0799373, 14.3099352],
                [108.0802686, 14.3099956],
                [108.0805856, 14.3100488],
                [108.0808811, 14.3100947],
                [108.0811332, 14.31014],
                [108.0814245, 14.3102282],
                [108.0816036, 14.310336],
                [108.0816312, 14.310428],
                [108.0816435, 14.3105973],
                [108.081634, 14.3107662],
                [108.0815947, 14.3110194],
                [108.0815299, 14.3112792],
                [108.0814543, 14.3115461],
                [108.0813787, 14.3118129],
                [108.0812885, 14.3120795],
                [108.0811645, 14.3124584],
                [108.0810966, 14.3126901],
                [108.0810431, 14.3129148],
                [108.0809746, 14.3131888],
                [108.0809208, 14.3134418],
                [108.0808528, 14.3136735],
                [108.0810333, 14.3136686],
                [108.0813008, 14.3136438],
                [108.0815826, 14.3136331],
                [108.0818067, 14.3136147],
                [108.0820813, 14.3135899],
                [108.082338, 14.3135649],
                [108.0826348, 14.3135052],
                [108.0829025, 14.3134591],
                [108.0831846, 14.3134202],
                [108.0835536, 14.3133544],
                [108.0838902, 14.313295],
                [108.0842008, 14.3132778],
                [108.0844751, 14.3132882],
                [108.0846843, 14.3133049],
                [108.084843, 14.3133209],
                [108.0850085, 14.3133582],
                [108.0851809, 14.3134237],
                [108.0853961, 14.313532],
                [108.0856504, 14.3136901],
                [108.0858654, 14.3138196],
                [108.0860228, 14.3139272],
                [108.0862374, 14.3140849],
                [108.0864448, 14.3142353],
                [108.0866597, 14.3143718],
                [108.0868747, 14.3145013],
                [108.0871111, 14.314638],
                [108.0874307, 14.3147758],
                [108.0877251, 14.3148992],
                [108.0879693, 14.3150008],
                [108.0880907, 14.315101],
                [108.088169, 14.3151935],
                [108.0881896, 14.3152783],
                [108.0881876, 14.3154262],
                [108.0881781, 14.3156093],
                [108.0881614, 14.3157782],
                [108.0881228, 14.3159751],
                [108.0880732, 14.3161858],
                [108.0880199, 14.3163965],
                [108.0879591, 14.3166353],
                [108.0878943, 14.3168952],
                [108.0878546, 14.3171765],
                [108.0877932, 14.3174577],
                [108.0877278, 14.3176921],
                [108.0877265, 14.3176968],
                [108.0876943, 14.3178509],
                [108.0876852, 14.3179988],
                [108.0876828, 14.3181819],
                [108.087692, 14.3183018],
                [108.0877256, 14.3184995],
                [108.0877734, 14.3187115],
                [108.0878213, 14.3189164],
                [108.0878873, 14.3191214],
                [108.0879779, 14.3193693],
                [108.0880691, 14.3195816],
                [108.0881604, 14.3197801],
                [108.0882372, 14.3199783],
                [108.0883242, 14.320233],
                [108.0883649, 14.3204308],
                [108.0883907, 14.3206707],
                [108.0884022, 14.3208963],
                [108.0884065, 14.3211288],
                [108.0883858, 14.3213329],
                [108.0883611, 14.3215721],
                [108.0883366, 14.3217902],
                [108.088305, 14.3220012],
                [108.0882806, 14.3222052],
                [108.0882808, 14.32248],
                [108.0882702, 14.3227405],
                [108.0882679, 14.3229166],
                [108.0882509, 14.3231137],
                [108.088212, 14.3233246],
                [108.0881335, 14.323542],
                [108.0880662, 14.3237174],
                [108.088007, 14.3238365],
                [108.0879113, 14.3239691],
                [108.0877792, 14.3241366],
                [108.0876692, 14.3242692],
                [108.087545, 14.3243803],
                [108.0873847, 14.3244911],
                [108.0871701, 14.3246153],
                [108.0869589, 14.3247536],
                [108.0867409, 14.3248636],
                [108.0864647, 14.3250083],
                [108.0862102, 14.325139],
                [108.085985, 14.3252489],
                [108.085767, 14.325352],
                [108.0854878, 14.3254459],
                [108.0852412, 14.3255274],
                [108.0849586, 14.3256013],
                [108.0846978, 14.3256755],
                [108.0843569, 14.325791],
                [108.0840525, 14.3258788],
                [108.0837552, 14.3259738],
                [108.0834507, 14.3260755],
                [108.0831243, 14.3261842],
                [108.0828634, 14.3262585],
                [108.0825994, 14.3263403],
                [108.0824184, 14.3263803],
                [108.0822159, 14.3264131],
                [108.0819915, 14.3264596],
                [108.0818245, 14.3265281],
                [108.081632, 14.3266243],
                [108.081457, 14.326756],
                [108.0812749, 14.3268805],
                [108.0810707, 14.32704],
                [108.080852, 14.3272063],
                [108.080582, 14.3274285],
                [108.0802939, 14.3276504],
                [108.0801041, 14.327817],
                [108.079892, 14.3280328],
                [108.0796945, 14.3282346],
                [108.0794752, 14.3284432],
                [108.0792854, 14.328603],
                [108.0790123, 14.3287828],
                [108.0788012, 14.328921],
                [108.0786555, 14.3290248],
                [108.0784874, 14.3291848],
                [108.0783842, 14.3293455],
                [108.0782439, 14.3295904],
                [108.0781138, 14.3298775],
                [108.0780459, 14.3301091],
                [108.0780003, 14.3302776],
                [108.0779755, 14.3305169],
                [108.0779738, 14.3306577],
                [108.077986, 14.3308269],
                [108.0780439, 14.3310941],
                [108.07812, 14.3313484],
                [108.0781891, 14.3315885],
                [108.0783081, 14.3318786],
                [108.0784485, 14.332183],
                [108.0785895, 14.3324452],
                [108.0787237, 14.3326721],
                [108.0789141, 14.3330193],
                [108.0790102, 14.3331738],
                [108.0792542, 14.3334106],
                [108.080152, 14.3338062],
                [108.0815372, 14.3341347],
                [108.0818956, 14.3341861],
                [108.0823017, 14.3341744],
                [108.0829415, 14.3341655],
                [108.0843394, 14.3342078],
                [108.0850188, 14.3342162],
                [108.0855486, 14.3341892],
                [108.0859898, 14.334178],
                [108.086475, 14.3341923],
                [108.0868141, 14.3342382],
                [108.0874124, 14.3343708],
                [108.0877206, 14.3344164],
                [108.0880114, 14.334445],
                [108.0882055, 14.3344473],
                [108.0884966, 14.3344509],
                [108.0888759, 14.3344639],
                [108.0893745, 14.3344533],
                [108.0901071, 14.3344457],
                [108.0907333, 14.3344617],
                [108.0918486, 14.3345254],
                [108.0929326, 14.3346306],
                [108.0934436, 14.3346869],
                [108.0939024, 14.3346926],
                [108.0942816, 14.3347055],
                [108.0948156, 14.3346954],
                [108.0953811, 14.3346355],
                [108.095849, 14.3346163],
                [108.0961311, 14.3346364],
                [108.0964567, 14.3347071],
                [108.0968612, 14.3348123],
                [108.0974203, 14.3349026],
                [108.0982214, 14.3350461],
                [108.0992157, 14.3352586],
                [108.0997421, 14.3354987],
                [108.1002854, 14.3357892],
                [108.1007573, 14.3361457],
                [108.1013869, 14.3365958],
                [108.102086, 14.337122],
                [108.1026018, 14.3374957],
                [108.102839, 14.3375738],
                [108.1030681, 14.3375932],
                [108.1032535, 14.3375955],
                [108.1035977, 14.3375913],
                [108.1038976, 14.3375951],
                [108.1041706, 14.3376401],
                [108.1042671, 14.3376747],
                [108.1043806, 14.3377763],
                [108.1045276, 14.3380035],
                [108.104618, 14.3381883],
                [108.1047017, 14.33854],
                [108.1047428, 14.3387742],
                [108.1047374, 14.3391915],
                [108.104663, 14.3398335],
                [108.1045418, 14.3403413],
                [108.1043512, 14.3407481],
                [108.1040207, 14.3414036],
                [108.1038473, 14.3418524],
                [108.103801, 14.3420272],
                [108.1037899, 14.3422023],
                [108.1038054, 14.3423612],
                [108.1038819, 14.3425875],
                [108.1040816, 14.3428405],
                [108.1045729, 14.3434058],
                [108.1050529, 14.3438208],
                [108.1056836, 14.3441874],
                [108.1061048, 14.3443679],
                [108.106575, 14.3445156],
                [108.1073488, 14.3447338],
                [108.1077532, 14.3448473],
                [108.1081588, 14.3448688],
                [108.1084238, 14.344847],
                [108.1086008, 14.3448159],
                [108.1088489, 14.3447354],
                [108.1091853, 14.3446393],
                [108.1097476, 14.3444959],
                [108.1101807, 14.3444344],
                [108.1106393, 14.3444483],
                [108.1109082, 14.3444766],
                [108.1112684, 14.344598],
                [108.1115311, 14.3447515],
                [108.111854, 14.3450392],
                [108.1120454, 14.3452503],
                [108.1122532, 14.3455617],
                [108.1123731, 14.3458387],
                [108.112439, 14.3462069],
                [108.1124572, 14.3465076],
                [108.1124359, 14.3467912],
                [108.1123804, 14.3469909],
                [108.1122892, 14.3472236],
                [108.1121265, 14.3475305],
                [108.1119632, 14.3478709],
                [108.1116153, 14.3485095],
                [108.1114126, 14.3488327],
                [108.1112149, 14.349114],
                [108.1110353, 14.3493539],
                [108.1107307, 14.3497093],
                [108.1103143, 14.3501801],
                [108.1098849, 14.3506424],
                [108.1093116, 14.3513033],
                [108.1089483, 14.3517581],
                [108.1086587, 14.3523224],
                [108.1084939, 14.3527878],
                [108.108415, 14.3530958],
                [108.1084295, 14.3533381],
                [108.1084892, 14.3535058],
                [108.1085839, 14.3536824],
                [108.1087918, 14.3539853],
                [108.1090738, 14.3543645],
                [108.1092996, 14.3546429],
                [108.1095952, 14.3549887],
                [108.1098824, 14.3553011],
                [108.1100648, 14.3555288],
                [108.1101728, 14.3557055],
                [108.1105424, 14.3564781],
                [108.1106891, 14.3567303],
                [108.1108185, 14.3569656],
                [108.1109401, 14.3571174],
                [108.1110878, 14.3572946],
                [108.1112358, 14.357455],
                [108.1113927, 14.3576072],
                [108.1116275, 14.3578772],
                [108.1118842, 14.3581559],
                [108.1120302, 14.3584749],
                [108.1121362, 14.3588102],
                [108.1123743, 14.3595143],
                [108.1125006, 14.3599835],
                [108.1125931, 14.3603352],
                [108.112629, 14.3606362],
                [108.1127136, 14.3609211],
                [108.1128683, 14.3612403],
                [108.1129719, 14.3614253],
                [108.1132249, 14.3616538],
                [108.1134292, 14.36189],
                [108.113986, 14.3625146],
                [108.1144023, 14.363079],
                [108.1146901, 14.3633498],
                [108.1149003, 14.3634776],
                [108.1150936, 14.3635383],
                [108.1154638, 14.3635679],
                [108.1158433, 14.3635726],
                [108.1162143, 14.3635437],
                [108.1168468, 14.3634262],
                [108.1171919, 14.3633552],
                [108.1175819, 14.363218],
                [108.1179814, 14.3630391],
                [108.1183144, 14.3628763],
                [108.1188039, 14.3625483],
                [108.1191951, 14.3623192],
                [108.1195513, 14.3620815],
                [108.1199618, 14.3617107],
                [108.1203235, 14.3613811],
                [108.1205922, 14.3610756],
                [108.1210671, 14.3605136],
                [108.121715, 14.3598786],
                [108.1220635, 14.3595405],
                [108.1225276, 14.359137],
                [108.1234673, 14.3584472],
                [108.1245079, 14.3578169],
                [108.1258722, 14.3570571],
                [108.1263161, 14.3568537],
                [108.1267498, 14.3567503],
                [108.1273426, 14.3566324],
                [108.1282929, 14.3565019],
                [108.1294862, 14.3563495],
                [108.1306308, 14.3562046],
                [108.1317264, 14.356101],
                [108.1324505, 14.3560596],
                [108.1328485, 14.3559977],
                [108.1331849, 14.3559099],
                [108.1343236, 14.3555397],
                [108.1348025, 14.3553534],
                [108.1350417, 14.3552813],
                [108.1353065, 14.355276],
                [108.1354344, 14.3552776],
                [108.1355574, 14.3553292],
                [108.1357847, 14.3554989],
                [108.1361862, 14.3558462],
                [108.1366209, 14.3563523],
                [108.1368044, 14.3565048],
                [108.1368831, 14.3565558],
                [108.1369976, 14.3565739],
                [108.1374263, 14.3565207],
                [108.138177, 14.3564712],
                [108.1387327, 14.3564947],
                [108.1390409, 14.3565485],
                [108.1392954, 14.3566602],
                [108.1394871, 14.3568462],
                [108.1395864, 14.3570227],
                [108.1397506, 14.3573002],
                [108.1398273, 14.3575099],
                [108.1398511, 14.3577189],
                [108.1398208, 14.3580191],
                [108.1397654, 14.3582188],
                [108.1397051, 14.3584435],
                [108.1395737, 14.3590597],
                [108.1395524, 14.3593517],
                [108.1395852, 14.3595442],
                [108.1396713, 14.3597121],
                [108.1398536, 14.3599565],
                [108.140024, 14.3600837],
                [108.1401558, 14.3601271],
                [108.1402618, 14.36012],
                [108.1404212, 14.3600802],
                [108.1406959, 14.3599917],
                [108.1411748, 14.3598055],
                [108.1416801, 14.3596279],
                [108.1421626, 14.3595001],
                [108.1429055, 14.3593838],
                [108.1435675, 14.3593667],
                [108.1442864, 14.3594005],
                [108.1451321, 14.3595109],
                [108.1456869, 14.359601],
                [108.1463989, 14.3598267],
                [108.1469434, 14.3600336],
                [108.1473035, 14.3601716],
                [108.1476729, 14.3602679],
                [108.1480915, 14.3603147],
                [108.1487792, 14.3603648],
                [108.1492561, 14.3603454],
                [108.1495217, 14.3602734],
                [108.1497216, 14.3601673],
                [108.1498288, 14.3600685],
                [108.1499016, 14.3598939],
                [108.1498868, 14.3596683],
                [108.1497756, 14.3593915],
                [108.1496031, 14.3590721],
                [108.149295, 14.3586663],
                [108.1490074, 14.3583793],
                [108.1485699, 14.358082],
                [108.1481932, 14.3578605],
                [108.1480097, 14.3577249],
                [108.1478875, 14.3576149],
                [108.1477927, 14.3574386],
                [108.1477677, 14.3573215],
                [108.1477875, 14.3571465],
                [108.1478592, 14.3569359],
                [108.1479555, 14.3567696],
                [108.1482128, 14.3562919],
                [108.148395, 14.3558836],
                [108.1484198, 14.3557326],
                [108.1483953, 14.3556297],
                [108.1483505, 14.3555643],
                [108.1482258, 14.3554926],
                [108.1480774, 14.3554853],
                [108.1479228, 14.3555213],
                [108.1476929, 14.3556428],
                [108.147503, 14.3557594],
                [108.1472496, 14.3559238],
                [108.1470458, 14.3560132],
                [108.146817, 14.3560428],
                [108.1466174, 14.3560297],
                [108.1464977, 14.3560174],
                [108.1463668, 14.3559726],
                [108.1462192, 14.3559114],
                [108.1460098, 14.355763],
                [108.1458123, 14.3555769],
                [108.145661, 14.3553482],
                [108.1454403, 14.3549674],
                [108.1453559, 14.3548691],
                [108.1452373, 14.3547651],
                [108.1451013, 14.3546932],
                [108.1449078, 14.3546368],
                [108.1447028, 14.3545912],
                [108.144469, 14.3545722],
                [108.1442151, 14.3545529],
                [108.1438498, 14.3545377],
                [108.1436446, 14.354519],
                [108.1434741, 14.3544467],
                [108.1432247, 14.3543032],
                [108.1429191, 14.3540943],
                [108.1426398, 14.3538316],
                [108.1424876, 14.3536784],
                [108.1422911, 14.3534168],
                [108.1422644, 14.3532652],
                [108.1422654, 14.3531896],
                [108.1423121, 14.3531037],
                [108.1424048, 14.3530022],
                [108.142483, 14.3529167],
                [108.1426849, 14.3527571],
                [108.1429439, 14.3525927],
                [108.1432313, 14.3524342],
                [108.1434387, 14.3522963],
                [108.1436171, 14.3521849],
                [108.1439204, 14.3519022],
                [108.1440363, 14.3517686],
                [108.1441471, 14.3515809],
                [108.1443654, 14.3512539],
                [108.1445405, 14.3509481],
                [108.144732, 14.3507128],
                [108.144987, 14.3504187],
                [108.1451585, 14.3501723],
                [108.1452669, 14.3500201],
                [108.1452806, 14.3500008],
                [108.1453686, 14.3498182],
                [108.1454041, 14.3497215],
                [108.1454405, 14.349549],
                [108.145457, 14.3493763],
                [108.1455197, 14.3489287],
                [108.1455786, 14.3485675],
                [108.1456405, 14.3481846],
                [108.1457582, 14.3479106],
                [108.1459086, 14.3477502],
                [108.146087, 14.3476444],
                [108.1463166, 14.3475446],
                [108.1464915, 14.3474926],
                [108.1468749, 14.3474162],
                [108.1473431, 14.3474219],
                [108.1475599, 14.3474352],
                [108.1477649, 14.3474809],
                [108.1479576, 14.3475967],
                [108.1481044, 14.3477173],
                [108.1481803, 14.3478155],
                [108.1482923, 14.3479897],
                [108.1484765, 14.3483215],
                [108.1485992, 14.3485553],
                [108.1486743, 14.348702],
                [108.1487813, 14.3488275],
                [108.1488889, 14.3488882],
                [108.1490028, 14.3489166],
                [108.1491056, 14.3489179],
                [108.1492826, 14.3489146],
                [108.1494827, 14.34889],
                [108.1497346, 14.3488443],
                [108.1500755, 14.3487459],
                [108.1506086, 14.3485794],
                [108.1510551, 14.3484929],
                [108.1513127, 14.348442],
                [108.1516301, 14.3484026],
                [108.1521328, 14.3483816],
                [108.1525495, 14.348392],
                [108.1529143, 14.3484396],
                [108.1532987, 14.3485253],
                [108.1538219, 14.3486828],
                [108.1541002, 14.348805],
                [108.1542812, 14.3489368],
                [108.1545228, 14.3492476],
                [108.1545724, 14.3493887],
                [108.1545149, 14.3500004],
                [108.153926, 14.350713],
                [108.1534322, 14.3506909],
                [108.1528552, 14.3504089],
                [108.1526731, 14.3503527],
                [108.1524963, 14.3503398],
                [108.152296, 14.3503751],
                [108.1521267, 14.3504678],
                [108.1519359, 14.3506161],
                [108.1517244, 14.35083],
                [108.1514904, 14.3512505],
                [108.1513364, 14.3516251],
                [108.1513287, 14.3518413],
                [108.1513648, 14.3521334],
                [108.1514696, 14.3524922],
                [108.1518098, 14.3531173],
                [108.151951, 14.3533542],
                [108.152089, 14.3534501],
                [108.1521882, 14.35347],
                [108.1524874, 14.3533984],
                [108.1528863, 14.3533184],
                [108.1534544, 14.3532218],
                [108.153773, 14.353188],
                [108.1540711, 14.353201],
                [108.1542944, 14.3532412],
                [108.1548395, 14.3533795],
                [108.1552056, 14.3535345],
                [108.1553542, 14.353574],
                [108.1554836, 14.3535661],
                [108.1556434, 14.3535115],
                [108.1558236, 14.3534196],
                [108.1559633, 14.3533836],
                [108.1560427, 14.353394],
                [108.156102, 14.3534229],
                [108.15622, 14.3535278],
                [108.1563077, 14.35367],
                [108.1564527, 14.354001],
                [108.1565784, 14.3542848],
                [108.1567082, 14.3546345],
                [108.1568445, 14.3548713],
                [108.157118, 14.3552604],
                [108.157333, 14.3555547],
                [108.1575491, 14.3557737],
                [108.1577657, 14.3559456],
                [108.157952, 14.3561548],
                [108.15801, 14.3562872],
                [108.1580083, 14.356419],
                [108.1579825, 14.356494],
                [108.1578989, 14.3568129],
                [108.1577939, 14.3572538],
                [108.1577547, 14.3576014],
                [108.1577899, 14.3579687],
                [108.1578569, 14.3581765],
                [108.1579542, 14.3583471],
                [108.1581797, 14.3585944],
                [108.1583758, 14.3588226],
                [108.158582, 14.359032],
                [108.1587139, 14.3592219],
                [108.1588788, 14.3595437],
                [108.1590513, 14.3600633],
                [108.1592469, 14.3607148],
                [108.1595368, 14.3613863],
                [108.1599654, 14.3620971],
                [108.1602593, 14.3624487],
                [108.1604959, 14.3626115],
                [108.1606442, 14.3626792],
                [108.1607634, 14.36269],
                [108.1608829, 14.3626821],
                [108.1610525, 14.362637],
                [108.161188, 14.3625445],
                [108.1614587, 14.3623691],
                [108.1617597, 14.3621563],
                [108.1621015, 14.3618688],
                [108.1623922, 14.361684],
                [108.1625715, 14.3616579],
                [108.1626611, 14.361659],
                [108.1628095, 14.3617173],
                [108.1629273, 14.3618409],
                [108.1631121, 14.3621725],
                [108.1632672, 14.3624848],
                [108.1634233, 14.3627219],
                [108.1635844, 14.3629591],
                [108.1637695, 14.3632718],
                [108.1638172, 14.3634324],
                [108.1638252, 14.3635829],
                [108.1637817, 14.363874],
                [108.1637788, 14.3641092],
                [108.1637968, 14.36426],
                [108.1638552, 14.3643642],
                [108.1639139, 14.3644402],
                [108.1640522, 14.3645172],
                [108.1641509, 14.3645748],
                [108.16426, 14.3645949],
                [108.1643495, 14.364596],
                [108.1645087, 14.3645884],
                [108.1647282, 14.3645347],
                [108.1649131, 14.3644522],
                [108.1652134, 14.3643147],
                [108.1654833, 14.364205],
                [108.1656924, 14.3641792],
                [108.1658613, 14.3641907],
                [108.1660994, 14.3642311],
                [108.1662875, 14.3643087],
                [108.1665047, 14.3644242],
                [108.166771, 14.3646062],
                [108.1669971, 14.3648159],
                [108.1672773, 14.3650715],
                [108.1675125, 14.3653469],
                [108.1680025, 14.3659356],
                [108.1683154, 14.366353],
                [108.1685311, 14.3665907],
                [108.1687573, 14.3667908],
                [108.1690485, 14.367049],
                [108.1697891, 14.3677912],
                [108.1701355, 14.3680031],
                [108.1703675, 14.3680548],
                [108.1705881, 14.3679963],
                [108.1709394, 14.3678172],
                [108.1714468, 14.3675667],
                [108.1718977, 14.3673274],
                [108.1722152, 14.3671043],
                [108.1725057, 14.3669518],
                [108.1728884, 14.3668533],
                [108.1731458, 14.36681],
                [108.1732876, 14.3668071],
                [108.1734393, 14.3668089],
                [108.173723, 14.3668076],
                [108.1739675, 14.3668106],
                [108.1744098, 14.366838],
                [108.1746202, 14.3668658],
                [108.1748837, 14.3669107],
                [108.1750738, 14.3669683],
                [108.1752008, 14.3669791],
                [108.1753232, 14.366976],
                [108.1754656, 14.3669315],
                [108.1755641, 14.3668726],
                [108.1757611, 14.3667731],
                [108.1760167, 14.3666744],
                [108.1762225, 14.3666399],
                [108.1763693, 14.3666324],
                [108.1765382, 14.3666476],
                [108.1766339, 14.3668047],
                [108.176786, 14.3669963],
                [108.1768809, 14.3672215],
                [108.176995, 14.3675637],
                [108.1770699, 14.3677496],
                [108.1771506, 14.3678725],
                [108.1772601, 14.3679661],
                [108.1774391, 14.3680511],
                [108.1776214, 14.3680825],
                [108.1777966, 14.3680748],
                [108.17807, 14.3680197],
                [108.1783382, 14.3679791],
                [108.1788458, 14.3679267],
                [108.1790207, 14.3679288],
                [108.1791131, 14.3679396],
                [108.1792776, 14.3679659],
                [108.1794572, 14.368007],
                [108.1795188, 14.3680176],
                [108.1797091, 14.3680246],
                [108.1798172, 14.3680259],
                [108.1799201, 14.3680223],
                [108.180119, 14.3679711],
                [108.1803977, 14.3679062],
                [108.180578, 14.3678889],
                [108.1807169, 14.3678954],
                [108.180799, 14.367911],
                [108.1810346, 14.3680063],
                [108.1811774, 14.3681054],
                [108.1813752, 14.3683415],
                [108.181506, 14.3685817],
                [108.1815633, 14.3687333],
                [108.1817701, 14.3690719],
                [108.1818561, 14.3691896],
                [108.1819506, 14.3692493],
                [108.1821144, 14.3693194],
                [108.1822734, 14.3693602],
                [108.1823815, 14.3693616],
                [108.1824741, 14.3693529],
                [108.1826081, 14.3693398],
                [108.182846, 14.3692551],
                [108.1830227, 14.3691799],
                [108.1832502, 14.3688265],
                [108.1833293, 14.3686716],
                [108.1834051, 14.3683754],
                [108.1834689, 14.3682154],
                [108.1835839, 14.3680707],
                [108.1836412, 14.3680131],
                [108.1837548, 14.3679852],
                [108.1838629, 14.3679864],
                [108.1839346, 14.3680068],
                [108.1840778, 14.3680816],
                [108.1842826, 14.3681716],
                [108.1844823, 14.368247],
                [108.184803, 14.3683239],
                [108.185085, 14.36841],
                [108.1854821, 14.3685511],
                [108.1858149, 14.3687259],
                [108.186004, 14.368791],
                [108.186143, 14.3687928],
                [108.1862716, 14.3687943],
                [108.1864106, 14.3687861],
                [108.1865758, 14.3687541],
                [108.1867593, 14.3686881],
                [108.1870851, 14.3685653],
                [108.1873696, 14.3684469],
                [108.1876126, 14.3683573],
                [108.1877671, 14.3683543],
                [108.187844, 14.3683796],
                [108.1879769, 14.3684493],
                [108.1880202, 14.3684839],
                [108.1881616, 14.3686999],
                [108.1883896, 14.3689849],
                [108.1885373, 14.3691036],
                [108.188686, 14.3691541],
                [108.1888349, 14.3691803],
                [108.1889147, 14.3691801],
                [108.1890027, 14.3691374],
                [108.1891275, 14.3690367],
                [108.1893515, 14.3688253],
                [108.1895071, 14.3687298],
                [108.1896204, 14.3687166],
                [108.1896924, 14.3687272],
                [108.1898457, 14.368802],
                [108.1899582, 14.3688714],
                [108.1901312, 14.3690292],
                [108.1902788, 14.3691922],
                [108.1905071, 14.3695062],
                [108.1907554, 14.3698351],
                [108.1911062, 14.3702984],
                [108.191418, 14.3707842],
                [108.1916559, 14.3711635],
                [108.1918741, 14.3715656],
                [108.1922137, 14.3723501],
                [108.1922752, 14.3725578],
                [108.1922709, 14.3728145],
                [108.1922994, 14.3730582],
                [108.1923115, 14.3732604],
                [108.192347, 14.3735225],
                [108.1924562, 14.3737212],
                [108.1925376, 14.3738049],
                [108.1926491, 14.3738625],
                [108.1928862, 14.3738641],
                [108.1930512, 14.3738615],
                [108.1932481, 14.3738362],
                [108.1935349, 14.3737983],
                [108.1938459, 14.3737606],
                [108.1940355, 14.3737353],
                [108.1941816, 14.3736912],
                [108.1944134, 14.3735828],
                [108.1945941, 14.3734978],
                [108.1948045, 14.3733581],
                [108.1950078, 14.3731985],
                [108.1951579, 14.3730319],
                [108.1952899, 14.3729602],
                [108.1954941, 14.3729213],
                [108.1958969, 14.3729169],
                [108.1961384, 14.3729555],
                [108.1966049, 14.3730897],
                [108.1969178, 14.3731627],
                [108.1972156, 14.373186],
                [108.1974016, 14.3731586],
                [108.1975275, 14.3731303],
                [108.1977963, 14.3729652],
                [108.1980852, 14.3728549],
                [108.1983158, 14.372818],
                [108.1984675, 14.3728148],
                [108.1985719, 14.3728358],
                [108.198681, 14.3728866],
                [108.1988205, 14.3730318],
                [108.1989207, 14.3731765],
                [108.1990109, 14.3733193],
                [108.1991371, 14.3736443],
                [108.1991545, 14.3739315],
                [108.1991574, 14.3741146],
                [108.1991559, 14.3744412],
                [108.199115, 14.374792],
                [108.1990767, 14.3749252],
                [108.1989175, 14.3753242],
                [108.198756, 14.3756884],
                [108.1985042, 14.3761654],
                [108.1984076, 14.3763672],
                [108.1983899, 14.3765254],
                [108.198394, 14.3766244],
                [108.1984451, 14.3767091],
                [108.1985279, 14.3767893],
                [108.1986371, 14.3768351],
                [108.198679, 14.3768356],
                [108.1988577, 14.3767684],
                [108.1990999, 14.3766328],
                [108.1993113, 14.376462],
                [108.199568, 14.3762127],
                [108.2002389, 14.3756763],
                [108.2006497, 14.3754486],
                [108.2009437, 14.3753581],
                [108.2015412, 14.3752611],
                [108.2018133, 14.3752397],
                [108.2022089, 14.3751849],
                [108.2024111, 14.375128],
                [108.2028061, 14.374905],
                [108.2029956, 14.3748033],
                [108.2031844, 14.3747611],
                [108.2032787, 14.3747523],
                [108.2034511, 14.3747641],
                [108.2035291, 14.3748047],
                [108.2036226, 14.3748553],
                [108.2037099, 14.374985],
                [108.2037217, 14.3750891],
                [108.203715, 14.3752077],
                [108.2037136, 14.3753166],
                [108.2035946, 14.3756319],
                [108.2035188, 14.3758388],
                [108.2033905, 14.3760599],
                [108.2031119, 14.3763865],
                [108.2030002, 14.3765385],
                [108.203004, 14.3766522],
                [108.2030214, 14.3767218],
                [108.2031086, 14.3768063],
                [108.2032313, 14.3768997],
                [108.2033635, 14.3769263],
                [108.2035315, 14.3769283],
                [108.2037259, 14.3769306],
                [108.2038764, 14.3769073],
                [108.2041862, 14.3768692],
                [108.2044072, 14.3768718],
                [108.2046275, 14.3769245],
                [108.2049037, 14.3770916],
                [108.2049227, 14.3771238],
                [108.2049256, 14.3772794],
                [108.2049187, 14.3774395],
                [108.2049193, 14.3777872],
                [108.2048914, 14.3780797],
                [108.2048557, 14.3784224],
                [108.2048562, 14.3787748],
                [108.2048421, 14.3791314],
                [108.2047121, 14.3798712],
                [108.2046555, 14.3803235],
                [108.2046635, 14.3806576],
                [108.204702, 14.3808142],
                [108.2047309, 14.3808825],
                [108.2048693, 14.3810215],
                [108.2049508, 14.3810865],
                [108.2052391, 14.3812318],
                [108.2056307, 14.3814331],
                [108.2059956, 14.381648],
                [108.2062737, 14.3818434],
                [108.2065176, 14.3820567],
                [108.2066697, 14.3822736],
                [108.2067941, 14.382577],
                [108.2069423, 14.3828991],
                [108.2071246, 14.3834182],
                [108.2072117, 14.3837278],
                [108.2075244, 14.3842752],
                [108.2079103, 14.3848157],
                [108.2084546, 14.3855773],
                [108.2089992, 14.3863024],
                [108.2093042, 14.3866227],
                [108.2096099, 14.3868942],
                [108.2100185, 14.387167],
                [108.21039, 14.3873176],
                [108.2105954, 14.3873688],
                [108.2108399, 14.3873838],
                [108.2111556, 14.3873631],
                [108.211697, 14.3873086],
                [108.212574, 14.3871972],
                [108.212832, 14.3871515],
                [108.2133351, 14.3870722],
                [108.2136442, 14.3870758],
                [108.21385, 14.3870904],
                [108.2141578, 14.3871915],
                [108.2145612, 14.3873668],
                [108.2156472, 14.3880495],
                [108.2162091, 14.3884215],
                [108.2165547, 14.3885839],
                [108.2169008, 14.3887098],
                [108.2174528, 14.3888503],
                [108.21792, 14.389075],
                [108.2184565, 14.3894224],
                [108.2189788, 14.389867],
                [108.2195128, 14.3904214],
                [108.2202083, 14.390929],
                [108.2208343, 14.3913262],
                [108.2212193, 14.3914281],
                [108.2213603, 14.3914785],
                [108.2215919, 14.3914934],
                [108.2218624, 14.3914844],
                [108.2221716, 14.3914758],
                [108.2227648, 14.3913975],
                [108.2232872, 14.3913184],
                [108.2240617, 14.3911691],
                [108.224577, 14.3911509],
                [108.2251434, 14.3911575],
                [108.2258125, 14.3912019],
                [108.2264036, 14.3912941],
                [108.2268976, 14.391434],
                [108.2274885, 14.3915504],
                [108.2279628, 14.3917265],
                [108.2285644, 14.3920138],
                [108.2293983, 14.3922672],
                [108.2297585, 14.3922835],
                [108.2300691, 14.3921654],
                [108.2302965, 14.3919952],
                [108.2306743, 14.3916345],
                [108.2311051, 14.3911649],
                [108.2320837, 14.3901175],
                [108.2325216, 14.3896577],
                [108.2328855, 14.3897841],
                [108.2333714, 14.3900337],
                [108.2346104, 14.3906684],
                [108.2355389, 14.3911978],
                [108.2363883, 14.3916043],
                [108.2370937, 14.3919277],
                [108.2375641, 14.3921365],
                [108.2382915, 14.3924094],
                [108.2394468, 14.39286],
                [108.2399166, 14.3931198],
                [108.2401242, 14.3932747],
                [108.2402087, 14.3933976],
                [108.2402822, 14.3935409],
                [108.2404302, 14.3937459],
                [108.2406207, 14.3939719],
                [108.2408759, 14.3942087],
                [108.241147, 14.3944456],
                [108.2420306, 14.3951372],
                [108.2430124, 14.3957078],
                [108.2437601, 14.3960928],
                [108.2443, 14.3963227],
                [108.2446958, 14.3964798],
                [108.2451141, 14.3965456],
                [108.2454366, 14.3965495],
                [108.2457271, 14.3965223],
                [108.2461038, 14.396476],
                [108.2467718, 14.3963515],
                [108.2470144, 14.3962832],
                [108.2473169, 14.3961545],
                [108.2475988, 14.3959646],
                [108.2477614, 14.3958445],
                [108.2479471, 14.3956026],
                [108.2481009, 14.39533],
                [108.2482117, 14.3950567],
                [108.2482483, 14.394708],
                [108.2482346, 14.3946642],
                [108.2483209, 14.3941715],
                [108.2484543, 14.3938071],
                [108.2487388, 14.3933937],
                [108.248891, 14.3932431],
                [108.2490317, 14.3931533],
                [108.2493124, 14.3930549],
                [108.2497539, 14.3929686],
                [108.250055, 14.3929416],
                [108.2503729, 14.3929256],
                [108.2507043, 14.392299],
                [108.250994, 14.3918469],
                [108.2513455, 14.3913954],
                [108.2517325, 14.3910728],
                [108.2521676, 14.3908676],
                [108.2526881, 14.3907452],
                [108.2534053, 14.3907068],
                [108.2543938, 14.3907183],
                [108.2552838, 14.3906936],
                [108.2561984, 14.3906925],
                [108.2572359, 14.3907397],
                [108.2580882, 14.3907729],
                [108.2586198, 14.390744],
                [108.2592013, 14.3906923],
                [108.259648, 14.3905456],
                [108.2602077, 14.3902484],
                [108.2609057, 14.3897543],
                [108.2617915, 14.3890637],
                [108.262652, 14.3884312],
                [108.2630638, 14.3880973],
                [108.2636118, 14.3877532],
                [108.2639971, 14.3875708],
                [108.2643813, 14.3874702],
                [108.2648513, 14.3874405],
                [108.2653697, 14.3874932],
                [108.2658871, 14.3876277],
                [108.2663545, 14.3878083],
                [108.2667245, 14.3878711],
                [108.2671322, 14.3878757],
                [108.2674656, 14.3878912],
                [108.267909, 14.3880132],
                [108.2685862, 14.3882196],
                [108.269215, 14.3883436],
                [108.2697341, 14.3883263],
                [108.2702427, 14.3881686],
                [108.2709634, 14.3878499],
                [108.271647, 14.3875191],
                [108.2722816, 14.3871643],
                [108.2729401, 14.3868681],
                [108.2734124, 14.3866517],
                [108.2738719, 14.3864585],
                [108.2742561, 14.3863694],
                [108.2750347, 14.3863667],
                [108.2756871, 14.3865728],
                [108.2765603, 14.3869215],
                [108.2772675, 14.3872004],
                [108.2779443, 14.3874421],
                [108.2784603, 14.3876818],
                [108.2791095, 14.3881569],
                [108.2796961, 14.3887013],
                [108.2801854, 14.389116],
                [108.2804862, 14.3892832],
                [108.2807941, 14.3893686],
                [108.2812265, 14.3893736],
                [108.2818084, 14.3892867],
                [108.2825788, 14.3889449],
                [108.2833982, 14.388627],
                [108.283801, 14.3885265],
                [108.2844932, 14.3885111],
                [108.2849126, 14.3885743],
                [108.2855033, 14.3887797],
                [108.2863146, 14.3891397],
                [108.2869849, 14.3894046],
                [108.2875516, 14.3895515],
                [108.2879588, 14.3896028],
                [108.2884777, 14.3896088],
                [108.2889973, 14.389568],
                [108.2897026, 14.3894943],
                [108.2902771, 14.3895008],
                [108.2907956, 14.3895535],
                [108.2912395, 14.3896287],
                [108.291757, 14.3897516],
                [108.2923721, 14.3899925],
                [108.2929368, 14.3903028],
                [108.293582, 14.3906025],
                [108.2941227, 14.3908541],
                [108.2946394, 14.3910471],
                [108.295231, 14.3911824],
                [108.2958219, 14.3913645],
                [108.2960991, 14.3914379],
                [108.2963569, 14.3915811],
                [108.2965099, 14.3917406],
                [108.2966588, 14.392169],
                [108.2965786, 14.3936877],
                [108.2966712, 14.3947292],
                [108.2965149, 14.395908],
                [108.2964653, 14.3969361],
                [108.2963204, 14.3976943],
                [108.2962557, 14.3984417],
                [108.29602, 14.3995378],
                [108.2958362, 14.3999214],
                [108.2955847, 14.4002809],
                [108.2952724, 14.4005578],
                [108.2946508, 14.4008664],
                [108.2944252, 14.4011209],
                [108.2942107, 14.4014926],
                [108.2940082, 14.402402],
                [108.2937815, 14.4027501],
                [108.2932183, 14.4033281],
                [108.2928125, 14.4036742],
                [108.2921351, 14.4045081],
                [108.2917694, 14.4051117],
                [108.291623, 14.4054724],
                [108.291542, 14.4060326],
                [108.2915094, 14.4066868],
                [108.2915636, 14.4073186],
                [108.2917632, 14.4076716],
                [108.292116, 14.4081432],
                [108.29302, 14.4090186],
                [108.2934583, 14.4095614],
                [108.2938522, 14.4102088],
                [108.2950018, 14.4127469],
                [108.2953975, 14.4137567],
                [108.2956816, 14.4142861],
                [108.2960336, 14.4148161],
                [108.2970075, 14.416043],
                [108.2973043, 14.4165374],
                [108.2974468, 14.4170299],
                [108.2976399, 14.4184349],
                [108.2978125, 14.4189862],
                [108.2981156, 14.4194806],
                [108.2985436, 14.4198597],
                [108.2991961, 14.4200659],
                [108.2996532, 14.4200828],
                [108.3001731, 14.4200186],
                [108.3012209, 14.4197383],
                [108.3014929, 14.4197297],
                [108.3018872, 14.4198277],
                [108.3029056, 14.4204473],
                [108.3052634, 14.4222276],
                [108.3062473, 14.4226364],
                [108.3068878, 14.4228308],
                [108.3072866, 14.4230574],
                [108.3075673, 14.4233644],
                [108.308351, 14.4249983],
                [108.3092299, 14.4264344],
                [108.3095927, 14.4271203],
                [108.3107104, 14.4292334],
                [108.3111647, 14.4299986],
                [108.3114178, 14.4305391],
                [108.3118166, 14.4318062],
                [108.3122576, 14.4326528],
                [108.3126859, 14.4330084],
                [108.3132626, 14.4333657],
                [108.3161198, 14.4347543],
                [108.316598, 14.4350752],
                [108.3170263, 14.4354425],
                [108.3173924, 14.4358324],
                [108.3175986, 14.4361621],
                [108.3179534, 14.4369844],
                [108.3183354, 14.438111],
                [108.3186882, 14.4385825],
                [108.3194298, 14.4391053],
                [108.3202298, 14.439395],
                [108.3205241, 14.4395971],
                [108.3211945, 14.4403879],
                [108.3218682, 14.4414126],
                [108.322672, 14.4424154],
                [108.3228108, 14.4426975],
                [108.3227942, 14.443048],
                [108.3226786, 14.4434208],
                [108.3226606, 14.4438881],
                [108.3227153, 14.4444732],
                [108.3233651, 14.4469938],
                [108.323456, 14.4476612],
                [108.3234834, 14.4489825],
                [108.3234345, 14.4499755],
                [108.3235485, 14.4512861],
                [108.3240297, 14.4539451],
                [108.3240697, 14.4547405],
                [108.3243308, 14.4551293],
                [108.3250521, 14.4558037],
                [108.3253706, 14.4560411],
                [108.3256388, 14.4563599],
                [108.3260172, 14.4572759],
                [108.3263151, 14.4582144],
                [108.3264246, 14.458356],
                [108.3266093, 14.4584165],
                [108.3276358, 14.4583814],
                [108.3281169, 14.4584687],
                [108.3288981, 14.4587816],
                [108.329743, 14.4594574],
                [108.3314456, 14.4612887],
                [108.3319329, 14.4619021],
                [108.3328795, 14.4628596],
                [108.3345149, 14.4646316],
                [108.3350343, 14.4651285],
                [108.3354606, 14.4656711],
                [108.3364609, 14.466793],
                [108.339222, 14.4695479],
                [108.3404055, 14.4708705],
                [108.3417553, 14.4722419],
                [108.342811, 14.4733877],
                [108.3448094, 14.4753157],
                [108.3457369, 14.4763315],
                [108.3470628, 14.4776441],
                [108.3476483, 14.4783053],
                [108.3498953, 14.4806453],
                [108.3516705, 14.4826408],
                [108.3533495, 14.484425],
                [108.3588756, 14.4902035],
                [108.3593634, 14.4907701],
                [108.3603725, 14.4916933],
                [108.3608122, 14.4921541],
                [108.362416, 14.4940192],
                [108.362893, 14.4944571],
                [108.3632221, 14.4948582],
                [108.3633043, 14.4952216],
                [108.3632928, 14.4956774],
                [108.3631878, 14.4961905],
                [108.3632084, 14.4965414],
                [108.3633528, 14.4968704],
                [108.3637116, 14.4973771],
                [108.3639321, 14.4975549],
                [108.3648934, 14.4978229],
                [108.3653599, 14.498097],
                [108.3654437, 14.4983317],
                [108.3654748, 14.4988464],
                [108.3654343, 14.4996857],
                [108.3659934, 14.5004652],
                [108.3663215, 14.5007572],
                [108.3666876, 14.5008421],
                [108.3672908, 14.5009065],
                [108.3674914, 14.5009664],
                [108.3676203, 14.5010831],
                [108.3677017, 14.5012109],
                [108.3678043, 14.5015523],
                [108.3678301, 14.5023714],
                [108.3679681, 14.5027189],
                [108.3680972, 14.5028126],
                [108.3683334, 14.5028731],
                [108.3691499, 14.5029282],
                [108.3693385, 14.5029996],
                [108.369526, 14.503169],
                [108.3696651, 14.5034242],
                [108.3699712, 14.5045809],
                [108.3700749, 14.5048185],
                [108.3702033, 14.5049814],
                [108.3709097, 14.5053353],
                [108.3711444, 14.5055109],
                [108.3714715, 14.5058952],
                [108.3719694, 14.5068522],
                [108.3722499, 14.5071667],
                [108.3727425, 14.5075759],
                [108.3739058, 14.5083559],
                [108.3742689, 14.5086944],
                [108.3745716, 14.509136],
                [108.3748945, 14.5098719],
                [108.3750456, 14.5101159],
                [108.3754921, 14.5104092],
                [108.3758456, 14.5105631],
                [108.376282, 14.5107179],
                [108.3771087, 14.5109147],
                [108.37802, 14.5109595],
                [108.3784589, 14.5109068],
                [108.379753, 14.5106332],
                [108.3801323, 14.5106029],
                [108.3805342, 14.510665],
                [108.3808521, 14.5108183],
                [108.3810988, 14.5109938],
                [108.3814258, 14.5113891],
                [108.3816675, 14.5119355],
                [108.3817237, 14.5121898],
                [108.3817046, 14.5128122],
                [108.3817645, 14.5137525],
                [108.3819013, 14.5142037],
                [108.3821893, 14.5148814],
                [108.3824503, 14.5158528],
                [108.3825732, 14.516471],
                [108.382651, 14.5168986],
                [108.3827053, 14.5173142],
                [108.3827478, 14.5177298],
                [108.382825, 14.5182207],
                [108.3829142, 14.5186828],
                [108.3830036, 14.5191336],
                [108.3830689, 14.5196243],
                [108.3831473, 14.5200057],
                [108.3833082, 14.5204226],
                [108.3834465, 14.520747],
                [108.3836892, 14.5212511],
                [108.3838845, 14.5217607],
                [108.3840203, 14.5222926],
                [108.3840632, 14.5226735],
                [108.3840928, 14.5231813],
                [108.3840903, 14.5233887],
                [108.3840481, 14.523959],
                [108.3839963, 14.524339],
                [108.3839198, 14.5247935],
                [108.3838438, 14.5252193],
                [108.3837439, 14.5256563],
                [108.3835726, 14.5261272],
                [108.3834141, 14.5265116],
                [108.3832092, 14.5268206],
                [108.3829456, 14.5270944],
                [108.3827069, 14.5272416],
                [108.3823974, 14.5273881],
                [108.3819932, 14.5275219],
                [108.3814228, 14.5277],
                [108.3808521, 14.5278954],
                [108.3801988, 14.5280726],
                [108.3795812, 14.5282271],
                [108.3789395, 14.5284159],
                [108.3784756, 14.5285951],
                [108.3781776, 14.5287648],
                [108.377986, 14.5289472],
                [108.3778545, 14.529082],
                [108.3777154, 14.5292508],
                [108.377265, 14.5296276],
                [108.3770397, 14.5297219],
                [108.3768465, 14.5298162],
                [108.3765888, 14.5298794],
                [108.3761379, 14.5299428],
                [108.3757512, 14.5299434],
                [108.3753324, 14.5298814],
                [108.3750421, 14.5297565],
                [108.3746876, 14.5296944],
                [108.3743333, 14.529695],
                [108.3736567, 14.5297273],
                [108.3727873, 14.5299794],
                [108.3721433, 14.5302311],
                [108.3710488, 14.5308283],
                [108.3701158, 14.5315817],
                [108.3697941, 14.5318957],
                [108.3696013, 14.5322406],
                [108.3694409, 14.5326482],
                [108.3693772, 14.533087],
                [108.3693457, 14.5334944],
                [108.3693787, 14.5339958],
                [108.3695091, 14.5349043],
                [108.3697357, 14.5355307],
                [108.3702003, 14.5362734],
                [108.3707331, 14.5367899],
                [108.3714284, 14.537711],
                [108.3723097, 14.5391491],
                [108.3729594, 14.5403853],
                [108.3733772, 14.5413292],
                [108.3738197, 14.5431279],
                [108.3738905, 14.544005],
                [108.3739372, 14.5442748],
                [108.3739846, 14.5449271],
                [108.3739873, 14.546569],
                [108.3739649, 14.5469965],
                [108.373874, 14.5479412],
                [108.3737363, 14.5486162],
                [108.3736671, 14.5489347],
                [108.3714369, 14.5520623],
                [108.3709935, 14.5525064],
                [108.3706216, 14.5529054],
                [108.370226, 14.5533276],
                [108.3699976, 14.5536248],
                [108.3698167, 14.5538994],
                [108.3696946, 14.5542208],
                [108.3695966, 14.5544905],
                [108.3694395, 14.5547539],
                [108.3692118, 14.5549934],
                [108.3690085, 14.555164],
                [108.3686749, 14.5553446],
                [108.3683291, 14.5555484],
                [108.3680774, 14.5558106],
                [108.3678493, 14.5560789],
                [108.3677038, 14.5563654],
                [108.367629, 14.5566873],
                [108.3676257, 14.5569639],
                [108.3676345, 14.5572177],
                [108.3676311, 14.5575],
                [108.367627, 14.5578458],
                [108.3676011, 14.5580299],
                [108.3675406, 14.5581445],
                [108.367362, 14.5582232],
                [108.3671014, 14.5582434],
                [108.366698, 14.5583079],
                [108.3663058, 14.5584418],
                [108.365877, 14.558656],
                [108.3654718, 14.558882],
                [108.365042, 14.5591826],
                [108.3644214, 14.5596021],
                [108.3637175, 14.5600552],
                [108.3629535, 14.5605711],
                [108.3624761, 14.5609001],
                [108.3620103, 14.5612405],
                [108.3616041, 14.5615472],
                [108.3609348, 14.5620757],
                [108.3603615, 14.5624957],
                [108.3599556, 14.5627793],
                [108.3594538, 14.563154],
                [108.359107, 14.5634498],
                [108.3588191, 14.5637636],
                [108.3586374, 14.5641074],
                [108.3584912, 14.5644515],
                [108.3584875, 14.5647626],
                [108.3585207, 14.5649589],
                [108.3585886, 14.5652249],
                [108.358716, 14.5654626],
                [108.3588556, 14.5656717],
                [108.3590421, 14.5659158],
                [108.3591009, 14.5660082],
                [108.3591341, 14.5661474],
                [108.3591209, 14.566251],
                [108.3588675, 14.5666516],
                [108.358662, 14.5670123],
                [108.3584072, 14.5675281],
                [108.3581649, 14.5679865],
                [108.35791, 14.5685197],
                [108.3574253, 14.5694478],
                [108.3571946, 14.5699351],
                [108.3570595, 14.570337],
                [108.3569604, 14.5707048],
                [108.3569212, 14.5710156],
                [108.356907, 14.5712114],
                [108.3569131, 14.5716898],
                [108.356932, 14.5720934],
                [108.3569731, 14.5726241],
                [108.3569895, 14.5732295],
                [108.3569726, 14.5736557],
                [108.3570148, 14.5740828],
                [108.357082, 14.5744063],
                [108.3571968, 14.5747073],
                [108.3573351, 14.5750259],
                [108.357402, 14.5753725],
                [108.3574339, 14.575684],
                [108.3574412, 14.576053],
                [108.3574137, 14.5763869],
                [108.3573745, 14.5766919],
                [108.3573113, 14.5770254],
                [108.3571498, 14.5776577],
                [108.3570614, 14.5781235],
                [108.3570326, 14.5785496],
                [108.3570632, 14.5789649],
                [108.3571064, 14.5793113],
                [108.3571856, 14.5796233],
                [108.3573005, 14.5799071],
                [108.3574512, 14.5801854],
                [108.3576493, 14.5804528],
                [108.3579182, 14.580744],
                [108.3582347, 14.5810012],
                [108.3585405, 14.5811775],
                [108.359023, 14.5814251],
                [108.3593767, 14.5815589],
                [108.3603681, 14.5818234],
                [108.3607219, 14.5819425],
                [108.3612876, 14.5821677],
                [108.3617935, 14.5824382],
                [108.3621934, 14.5826615],
                [108.3627351, 14.5829094],
                [108.3633954, 14.5831246],
                [108.364021, 14.5832698],
                [108.3647292, 14.5834507],
                [108.3653541, 14.5836537],
                [108.3660493, 14.5839266],
                [108.3665081, 14.5841622],
                [108.3669433, 14.5844034],
                [108.367213, 14.5846139],
                [108.3674927, 14.5849974],
                [108.3679338, 14.5857341],
                [108.3680478, 14.5860927],
                [108.3681032, 14.5864159],
                [108.3681119, 14.5866812],
                [108.3681065, 14.5871362],
                [108.3681515, 14.5883238],
                [108.3681819, 14.5887562],
                [108.3682252, 14.5891024],
                [108.3682929, 14.5893798],
                [108.3684068, 14.5897499],
                [108.3685866, 14.5905643],
                [108.3686533, 14.5909222],
                [108.3688103, 14.5916616],
                [108.3689022, 14.5918989],
                [108.3690873, 14.5922582],
                [108.3695035, 14.5931042],
                [108.3697012, 14.5934003],
                [108.3698754, 14.5936788],
                [108.3700853, 14.5939463],
                [108.3704947, 14.5943658],
                [108.3707051, 14.5945986],
                [108.3708685, 14.5947963],
                [108.3710771, 14.5951617],
                [108.3712156, 14.5954628],
                [108.3714337, 14.5960416],
                [108.3715251, 14.5963192],
                [108.3716646, 14.596534],
                [108.3718513, 14.5967665],
                [108.3720975, 14.5969652],
                [108.3723322, 14.5971407],
                [108.373473, 14.5977759],
                [108.3737072, 14.5979859],
                [108.3738238, 14.5981428],
                [108.3739392, 14.5983977],
                [108.3740763, 14.5988141],
                [108.3742374, 14.5992077],
                [108.3744104, 14.5995842],
                [108.3746532, 14.6000824],
                [108.3748274, 14.6003611],
                [108.3749424, 14.6004584],
                [108.3752913, 14.6007831],
                [108.3760369, 14.6010955],
                [108.3765438, 14.6012425],
                [108.3785578, 14.6016933],
                [108.3792801, 14.6017523],
                [108.3796723, 14.6018119],
                [108.3805596, 14.6018104],
                [108.381158, 14.6017894],
                [108.3826231, 14.601787],
                [108.383593, 14.6018055],
                [108.3862558, 14.6023228],
                [108.3866481, 14.6024224],
                [108.3869717, 14.6024321],
                [108.3872102, 14.6024188],
                [108.3874949, 14.6023759],
                [108.3877449, 14.602252],
                [108.3880917, 14.6019562],
                [108.3884251, 14.6017987],
                [108.3887104, 14.6016981],
                [108.3889955, 14.6016207],
                [108.3895761, 14.6015695],
                [108.3899324, 14.6014756],
                [108.3901831, 14.6013055],
                [108.3905293, 14.6010558],
                [108.3914493, 14.6003516],
                [108.3917358, 14.6001474],
                [108.3920807, 14.600013],
                [108.3923299, 14.5999581],
                [108.3925549, 14.5999548],
                [108.3926607, 14.6000252],
                [108.3927542, 14.60013],
                [108.3928113, 14.6003035],
                [108.3930271, 14.601078],
                [108.3931299, 14.6013933],
                [108.3932679, 14.6017402],
                [108.3933721, 14.601937],
                [108.3935122, 14.6021112],
                [108.3937698, 14.6023559],
                [108.39398, 14.6025999],
                [108.3943532, 14.603076],
                [108.3944943, 14.6031582],
                [108.3945532, 14.603162],
                [108.3946387, 14.6030221],
                [108.3948184, 14.6025079],
                [108.3951961, 14.6016455],
                [108.395311, 14.6014281],
                [108.395456, 14.6012508],
                [108.3959187, 14.6009876],
                [108.3977442, 14.6003072],
                [108.3983796, 14.6001056],
                [108.3993539, 14.5997488],
                [108.3998362, 14.5995454],
                [108.4006265, 14.5992014],
                [108.4011709, 14.5989291],
                [108.4016739, 14.5986962],
                [108.4021874, 14.5984434],
                [108.4028661, 14.5980386],
                [108.4031241, 14.5978027],
                [108.4034038, 14.5974681],
                [108.404111, 14.5963775],
                [108.4050671, 14.5949519],
                [108.4054722, 14.59436],
                [108.4058767, 14.5938278],
                [108.4065607, 14.5929557],
                [108.4073254, 14.5921839],
                [108.4076979, 14.5917705],
                [108.4081216, 14.591328],
                [108.40876, 14.5908828],
                [108.4092655, 14.5904312],
                [108.4095555, 14.5900866],
                [108.4097221, 14.5898052],
                [108.4098793, 14.5894589],
                [108.4102173, 14.5885085],
                [108.4106056, 14.5876034],
                [108.4107224, 14.587217],
                [108.4108612, 14.5866967],
                [108.4109795, 14.586191],
                [108.4111067, 14.5857949],
                [108.4112643, 14.585404],
                [108.4116835, 14.5844843],
                [108.4119448, 14.5839752],
                [108.4120607, 14.5836585],
                [108.412135, 14.5834106],
                [108.4122157, 14.5826171],
                [108.4123446, 14.5812051],
                [108.4124125, 14.5806392],
                [108.4126192, 14.5795528],
                [108.4126745, 14.5791955],
                [108.4126884, 14.5788775],
                [108.4126625, 14.5784696],
                [108.4126586, 14.5779376],
                [108.4126915, 14.5777391],
                [108.4129031, 14.5771052],
                [108.4132401, 14.5762389],
                [108.4135221, 14.5756952],
                [108.4141901, 14.5744648],
                [108.4147526, 14.5735217],
                [108.415033, 14.5731171],
                [108.4159271, 14.5717599],
                [108.4164768, 14.5710352],
                [108.4167667, 14.5707004],
                [108.4170763, 14.5704155],
                [108.4173749, 14.570205],
                [108.4180231, 14.5697946],
                [108.4184653, 14.569531],
                [108.4188559, 14.5693166],
                [108.4192669, 14.5691025],
                [108.4198416, 14.5688553],
                [108.4207763, 14.5683884],
                [108.4218647, 14.5678833],
                [108.4223878, 14.5676853],
                [108.4233415, 14.5673479],
                [108.42366, 14.5671824],
                [108.4261199, 14.5655341],
                [108.4264699, 14.5652894],
                [108.4268613, 14.5650004],
                [108.4282026, 14.5638221],
                [108.4288624, 14.5632874],
                [108.429337, 14.5628652],
                [108.4297913, 14.5624426],
                [108.4308731, 14.5616145],
                [108.431831, 14.5609042],
                [108.4322536, 14.5605707],
                [108.4327384, 14.5601485],
                [108.4332231, 14.5597462],
                [108.4337074, 14.5593588],
                [108.4342223, 14.5589965],
                [108.4345827, 14.5587321],
                [108.4349327, 14.5584974],
                [108.4354057, 14.5582191],
                [108.4364743, 14.5576442],
                [108.4370809, 14.557293],
                [108.4393641, 14.5558912],
                [108.439878, 14.5556035],
                [108.4407411, 14.5551457],
                [108.4411824, 14.5549518],
                [108.4422281, 14.5545902],
                [108.4428082, 14.5545977],
                [108.4435146, 14.5547528],
                [108.4440356, 14.5549184],
                [108.4446328, 14.5550499],
                [108.4453138, 14.5550899],
                [108.4461313, 14.5549144],
                [108.4462318, 14.554882],
                [108.4470941, 14.5546043],
                [108.4477209, 14.5543408],
                [108.4481947, 14.5541768],
                [108.4491338, 14.5539859],
                [108.4496598, 14.5539257],
                [108.4503136, 14.553898],
                [108.450733, 14.553843],
                [108.4517051, 14.5536647],
                [108.4522677, 14.5535814],
                [108.4528916, 14.5535036],
                [108.4535872, 14.5533918],
                [108.4543854, 14.5532414],
                [108.4551527, 14.5531205],
                [108.4557256, 14.5530323],
                [108.4563288, 14.5529693],
                [108.4570244, 14.5528675],
                [108.4582418, 14.552667],
                [108.4586407, 14.5526116],
                [108.4590091, 14.5525461],
                [108.4594087, 14.5524311],
                [108.4598192, 14.5522565],
                [108.4602295, 14.552092],
                [108.4605678, 14.5519814],
                [108.4620735, 14.5515801],
                [108.4625762, 14.5513767],
                [108.4635513, 14.5509449],
                [108.4648434, 14.5504717],
                [108.4654686, 14.5502597],
                [108.4665351, 14.5498786],
                [108.4671396, 14.5496961],
                [108.4677543, 14.5495238],
                [108.4682664, 14.5493902],
                [108.468645, 14.5493198],
                [108.469187, 14.549256],
                [108.4696168, 14.5491711],
                [108.4701598, 14.5490179],
                [108.4706619, 14.5488643],
                [108.4719744, 14.5483863],
                [108.4730607, 14.54805],
                [108.4745578, 14.5475045],
                [108.4750291, 14.5473703],
                [108.47548, 14.5472261],
                [108.4766283, 14.5468258],
                [108.4772641, 14.546594],
                [108.4783608, 14.546233],
                [108.4789755, 14.5460506],
                [108.479559, 14.5459277],
                [108.4801017, 14.5458043],
                [108.4811257, 14.5455519],
                [108.4816372, 14.5454579],
                [108.4823538, 14.5453066],
                [108.483766, 14.5450483],
                [108.4844412, 14.5449363],
                [108.4850548, 14.5448534],
                [108.4864156, 14.5446144],
                [108.4870906, 14.5445223],
                [108.4878063, 14.5444505],
                [108.4891253, 14.5443006],
                [108.4922953, 14.5439119],
                [108.4928987, 14.5438289],
                [108.4935122, 14.5437659],
                [108.4941354, 14.5437328],
                [108.494687, 14.5437139],
                [108.4952895, 14.5437103],
                [108.4964127, 14.5437223],
                [108.4971066, 14.5437646],
                [108.4980963, 14.5438448],
                [108.4985957, 14.5439296],
                [108.4989521, 14.544013],
                [108.4993894, 14.5441768],
                [108.4998366, 14.5443655],
                [108.5001716, 14.5445381],
                [108.5003865, 14.5446842],
                [108.5024177, 14.5457704],
                [108.502888, 14.5458409],
                [108.5034595, 14.5458797],
                [108.5037961, 14.5458669],
                [108.5041499, 14.5458855],
                [108.5048522, 14.546148],
                [108.5059416, 14.5467897],
                [108.5081206, 14.548165],
                [108.510727, 14.5501835],
                [108.5124327, 14.5513759],
                [108.5129063, 14.5516508],
                [108.5138526, 14.5517407],
                [108.5147995, 14.5521067],
                [108.517923, 14.5526978],
                [108.5192482, 14.553017],
                [108.5200234, 14.5532271],
                [108.5205486, 14.5533486],
                [108.5211065, 14.5537402],
                [108.521891, 14.5543575],
                [108.5226138, 14.5549133],
                [108.5234215, 14.5553076],
                [108.5242111, 14.5554782],
                [108.5248559, 14.5555459],
                [108.5254394, 14.5555116],
                [108.5261485, 14.5554176],
                [108.5266899, 14.5554233],
                [108.5272732, 14.5554092],
                [108.5277304, 14.5554952],
                [108.5281858, 14.5557436],
                [108.5286389, 14.556195],
                [108.5289091, 14.5565752],
                [108.5289493, 14.5575176],
                [108.5289809, 14.5576571],
                [108.5290227, 14.5579333],
                [108.5290406, 14.5582703],
                [108.5290366, 14.5583351],
                [108.5290082, 14.5584617],
                [108.528948, 14.5585899],
                [108.5289038, 14.5586529],
                [108.5288521, 14.5587103],
                [108.5287937, 14.5587613],
                [108.5287294, 14.5588052],
                [108.5295421, 14.5594153],
                [108.5298667, 14.5597344],
                [108.5298583, 14.5600292],
                [108.5298416, 14.5603236],
                [108.5297835, 14.5609106],
                [108.5297525, 14.5611346],
                [108.5296762, 14.5615804],
                [108.5295528, 14.561866],
                [108.5294934, 14.5619794],
                [108.5293597, 14.5621983],
                [108.5293152, 14.5623004],
                [108.5292793, 14.5624056],
                [108.5292344, 14.5626226],
                [108.5292256, 14.5627331],
                [108.5292261, 14.5628455],
                [108.5292361, 14.5629575],
                [108.5292556, 14.5630683],
                [108.5292843, 14.5631772],
                [108.5293221, 14.5632834],
                [108.5302931, 14.5653551],
                [108.5309046, 14.566326],
                [108.5303199, 14.5671151],
                [108.5302953, 14.5672015],
                [108.5302684, 14.5673786],
                [108.5302712, 14.5675528],
                [108.5303012, 14.5677195],
                [108.5303575, 14.5678797],
                [108.5304745, 14.5681358],
                [108.5305465, 14.5683183],
                [108.530611, 14.5685035],
                [108.5307169, 14.5688804],
                [108.5307616, 14.5690897],
                [108.5307969, 14.5693006],
                [108.5308227, 14.5695128],
                [108.530839, 14.569726],
                [108.5308488, 14.5700549],
                [108.530831, 14.570285],
                [108.5308101, 14.5703987],
                [108.5307515, 14.5706019],
                [108.5306762, 14.5707787],
                [108.5305823, 14.570966],
                [108.5305427, 14.5710719],
                [108.5305124, 14.5711807],
                [108.5304918, 14.5712915],
                [108.5304809, 14.5714037],
                [108.5304796, 14.5715101],
                [108.5305033, 14.5717215],
                [108.5305281, 14.5718252],
                [108.530603, 14.5720251],
                [108.5306525, 14.5721202],
                [108.5344505, 14.5809773],
                [108.5346268, 14.5812237],
                [108.5347242, 14.5813403],
                [108.5349361, 14.5815588],
                [108.5351694, 14.581756],
                [108.5352929, 14.5818458],
                [108.535553, 14.5820072],
                [108.5358283, 14.582143],
                [108.536116, 14.5822519],
                [108.5384095, 14.5829086],
                [108.538994, 14.583278],
                [108.5392785, 14.5834738],
                [108.539829, 14.5838857],
                [108.5403546, 14.5843237],
                [108.5408556, 14.5847879],
                [108.5416228, 14.5860598],
                [108.5419571, 14.5864719],
                [108.5423094, 14.5868697],
                [108.5427715, 14.587343],
                [108.5430121, 14.5875706],
                [108.5431036, 14.5876406],
                [108.5432007, 14.5877032],
                [108.5433027, 14.5877578],
                [108.5435068, 14.5878382],
                [108.5437085, 14.587885],
                [108.5440501, 14.5879121],
                [108.5442857, 14.5879517],
                [108.5445351, 14.5880225],
                [108.5447927, 14.5881303],
                [108.5449156, 14.5881959],
                [108.5451472, 14.5883493],
                [108.5457265, 14.5887023],
                [108.5464132, 14.5890813],
                [108.5471682, 14.5895109],
                [108.5463233, 14.5896734],
                [108.5451053, 14.5902031],
                [108.5444156, 14.590613],
                [108.5441217, 14.5909002],
                [108.5438096, 14.5917895],
                [108.5436539, 14.5923863],
                [108.5435001, 14.5928019],
                [108.5432902, 14.5932349],
                [108.5429874, 14.5936488],
                [108.542685, 14.5940265],
                [108.5424208, 14.5943139],
                [108.5420626, 14.5946729],
                [108.5412338, 14.595444],
                [108.5405375, 14.5960352],
                [108.5400094, 14.5965919],
                [108.5394624, 14.5971665],
                [108.5391978, 14.5974901],
                [108.5389322, 14.5979044],
                [108.5387602, 14.5982834],
                [108.5385494, 14.5988073],
                [108.5383559, 14.59944],
                [108.5380137, 14.6000348],
                [108.5376316, 14.6008651],
                [108.5373474, 14.6012792],
                [108.5371016, 14.6015849],
                [108.5366122, 14.602015],
                [108.5360185, 14.6025999],
                [108.535143, 14.6032015],
                [108.5343475, 14.604026],
                [108.5338957, 14.6046875],
                [108.533578, 14.6055836],
                [108.5331904, 14.6066123],
                [108.5328322, 14.6080744],
                [108.5325078, 14.6095701],
                [108.5323264, 14.610501],
                [108.5320095, 14.6113306],
                [108.5318326, 14.6118617],
                [108.531555, 14.6122253],
                [108.5311076, 14.612487],
                [108.5307275, 14.6128495],
                [108.530551, 14.6133474],
                [108.5303388, 14.6139781],
                [108.5300931, 14.6145418],
                [108.5296758, 14.6151704],
                [108.5288803, 14.6159948],
                [108.5283604, 14.6166223],
                [108.5280092, 14.6174514],
                [108.5277958, 14.6181822],
                [108.5276457, 14.6193799],
                [108.5274967, 14.6204777],
                [108.5272773, 14.6217414],
                [108.5269567, 14.622904],
                [108.526118, 14.6238031],
                [108.5257853, 14.623691],
                [108.5251751, 14.6232182],
                [108.5247321, 14.6230802],
                [108.5241859, 14.6230078],
                [108.5237756, 14.6230035],
                [108.5232279, 14.6230643],
                [108.5226456, 14.6231579],
                [108.5220287, 14.6232847],
                [108.5212752, 14.62341],
                [108.5199761, 14.6233961],
                [108.5189519, 14.6232519],
                [108.5177929, 14.6229397],
                [108.5163285, 14.6224243],
                [108.5147631, 14.6217693],
                [108.5139009, 14.6217181],
                [108.5130195, 14.6217507],
                [108.5124015, 14.6219772],
                [108.5118505, 14.6223374],
                [108.5113365, 14.6224318],
                [108.5109627, 14.6222281],
                [108.5105911, 14.6218246],
                [108.5102883, 14.6213886],
                [108.5099149, 14.6211515],
                [108.5091966, 14.6211772],
                [108.5082409, 14.6210338],
                [108.5075606, 14.6207268],
                [108.5070834, 14.6205886],
                [108.506707, 14.6206179],
                [108.5063275, 14.6209135],
                [108.5060161, 14.621243],
                [108.5057017, 14.621839],
                [108.5055939, 14.6223039],
                [108.5053147, 14.6228003],
                [108.5048669, 14.6230951],
                [108.5043142, 14.6235886],
                [108.5040032, 14.6238848],
                [108.5036223, 14.6243136],
                [108.5032736, 14.6249091],
                [108.5028946, 14.6251714],
                [108.5026556, 14.6251355],
                [108.5023514, 14.6248326],
                [108.5018568, 14.6236281],
                [108.5010601, 14.622899],
                [108.5000848, 14.6225924],
                [108.4995408, 14.622576],
                [108.4990621, 14.6225602],
                [108.4987365, 14.6224719],
                [108.4985203, 14.6223425],
                [108.4983046, 14.6221708],
                [108.4980464, 14.6219136],
                [108.4978417, 14.6217314],
                [108.4976359, 14.6216339],
                [108.4973865, 14.6215624],
                [108.4967999, 14.6214607],
                [108.496278, 14.6214233],
                [108.4957883, 14.6214181],
                [108.4953639, 14.6214029],
                [108.4950055, 14.6213461],
                [108.4946143, 14.6212942],
                [108.4941036, 14.6212251],
                [108.4936903, 14.6211995],
                [108.4930913, 14.6212354],
                [108.4925467, 14.621272],
                [108.4919362, 14.621366],
                [108.491228, 14.6214432],
                [108.4906721, 14.6215114],
                [108.4900621, 14.6215577],
                [108.4895175, 14.6216047],
                [108.4892233, 14.6216334],
                [108.4890265, 14.621716],
                [108.4889272, 14.6218315],
                [108.4888815, 14.6220217],
                [108.4888543, 14.6225034],
                [108.4888477, 14.6230753],
                [108.4888329, 14.6234247],
                [108.488797, 14.6237103],
                [108.4886972, 14.6238787],
                [108.488576, 14.6240045],
                [108.4884228, 14.624077],
                [108.4882705, 14.6240753],
                [108.48814, 14.6240634],
                [108.4876084, 14.6239146],
                [108.4868922, 14.6237269],
                [108.4860892, 14.6235275],
                [108.4854489, 14.6233617],
                [108.4850038, 14.6232721],
                [108.4848079, 14.62327],
                [108.4846661, 14.6233002],
                [108.4846003, 14.623342],
                [108.4845665, 14.6234369],
                [108.4845422, 14.6236644],
                [108.4845055, 14.6240135],
                [108.4844572, 14.6244367],
                [108.4843871, 14.6248597],
                [108.4843197, 14.625039],
                [108.4841874, 14.6251912],
                [108.4839356, 14.6253263],
                [108.4836736, 14.6253975],
                [108.4832487, 14.6254353],
                [108.4828895, 14.625442],
                [108.4824541, 14.6254373],
                [108.4821813, 14.6255085],
                [108.4820161, 14.6256762],
                [108.4819377, 14.6258661],
                [108.4818691, 14.6261618],
                [108.4818219, 14.6264845],
                [108.4817637, 14.6268121],
                [108.4817069, 14.6270235],
                [108.4815622, 14.6273078],
                [108.4813523, 14.627581],
                [108.4810656, 14.627901],
                [108.4808111, 14.628269],
                [108.4807435, 14.6284695],
                [108.4807298, 14.6287236],
                [108.4807257, 14.6290731],
                [108.4807327, 14.6294175],
                [108.4807054, 14.6299044],
                [108.4806997, 14.6304023],
                [108.4806727, 14.6308628],
                [108.4806688, 14.6312016],
                [108.4806856, 14.6316361],
                [108.4807138, 14.6320284],
                [108.4807541, 14.6323095],
                [108.4808469, 14.6327661],
                [108.4809514, 14.6331485],
                [108.4810869, 14.6336743],
                [108.4812125, 14.63411],
                [108.48135, 14.6344505],
                [108.481488, 14.6347591],
                [108.4816477, 14.6350786],
                [108.4818182, 14.635393],
                [108.4820966, 14.6357985],
                [108.4823211, 14.6361504],
                [108.4825553, 14.6366085],
                [108.4827467, 14.6370078],
                [108.4829061, 14.6373379],
                [108.4830222, 14.6376569],
                [108.4831156, 14.6380605],
                [108.483188, 14.6383897],
                [108.4833033, 14.6387775],
                [108.4834722, 14.6392348],
                [108.4835876, 14.6396175],
                [108.4836702, 14.640013],
                [108.4837306, 14.6404369],
                [108.4837693, 14.6408605],
                [108.4837973, 14.6412735],
                [108.4838049, 14.6415593],
                [108.483803, 14.6417285],
                [108.4837397, 14.6420352],
                [108.4836873, 14.6423365],
                [108.4836848, 14.6425588],
                [108.483747, 14.6428296],
                [108.483826, 14.6430528],
                [108.4839706, 14.6432529],
                [108.4840666, 14.6434287],
                [108.4841941, 14.6437001],
                [108.4843531, 14.6440671],
                [108.4844981, 14.6442276],
                [108.484741, 14.644405],
                [108.4849357, 14.6445024],
                [108.4851309, 14.6445681],
                [108.4854569, 14.6446034],
                [108.4870073, 14.6446201],
                [108.4872187, 14.6446859],
                [108.4873485, 14.6447509],
                [108.4875589, 14.644912],
                [108.4877534, 14.6450333],
                [108.4881118, 14.6451006],
                [108.4883241, 14.6450872],
                [108.4886356, 14.6449634],
                [108.4889963, 14.6448243],
                [108.4892586, 14.6447159],
                [108.4894545, 14.644718],
                [108.4900576, 14.644788],
                [108.4904319, 14.6448875],
                [108.4907409, 14.6449861],
                [108.4911472, 14.6451494],
                [108.4914552, 14.6453274],
                [108.491648, 14.6455995],
                [108.4916947, 14.6458066],
                [108.4916924, 14.6460051],
                [108.4916246, 14.6462267],
                [108.4914579, 14.6465267],
                [108.4911098, 14.6469996],
                [108.4907775, 14.6475043],
                [108.4905431, 14.6480339],
                [108.4905062, 14.6483989],
                [108.4905679, 14.6487173],
                [108.4906473, 14.6489087],
                [108.4908241, 14.6491489],
                [108.4910184, 14.6492939],
                [108.491407, 14.6495682],
                [108.4918765, 14.6499068],
                [108.4923457, 14.6502694],
                [108.4926848, 14.6505907],
                [108.4928941, 14.6508471],
                [108.493037, 14.6511982],
                [108.4930982, 14.6515641],
                [108.4930949, 14.6518501],
                [108.493058, 14.6522229],
                [108.4930535, 14.6526201],
                [108.4930673, 14.6528426],
                [108.4931458, 14.6531135],
                [108.4932416, 14.6533052],
                [108.493451, 14.6535458],
                [108.4938054, 14.6539625],
                [108.4941434, 14.6543871],
                [108.4944165, 14.6547714],
                [108.4946081, 14.6551388],
                [108.4947828, 14.6555695],
                [108.4949732, 14.6560481],
                [108.4950993, 14.6564546],
                [108.4952576, 14.6568853],
                [108.4953512, 14.6572675],
                [108.4954796, 14.6574594],
                [108.495609, 14.6575562],
                [108.4958689, 14.6576702],
                [108.4961129, 14.6577522],
                [108.4965191, 14.6579154],
                [108.4968758, 14.6581258],
                [108.4971841, 14.658288],
                [108.4976215, 14.6585866],
                [108.4979773, 14.6588764],
                [108.4984787, 14.6592788],
                [108.4987852, 14.6595999],
                [108.4990587, 14.6599523],
                [108.4992664, 14.6603516],
                [108.4993766, 14.6607065],
                [108.4994541, 14.6610723],
                [108.4995142, 14.661533],
                [108.4995909, 14.6619623],
                [108.4997008, 14.6623443],
                [108.499826, 14.6628216],
                [108.499888, 14.6631079],
                [108.4999668, 14.6633626],
                [108.5000778, 14.6636494],
                [108.5002218, 14.6639048],
                [108.5003901, 14.6641762],
                [108.5010109, 14.6650403],
                [108.502179, 14.6656993],
                [108.5030594, 14.6659722],
                [108.5035412, 14.6661216],
                [108.5042705, 14.6661672],
                [108.5060699, 14.6665414],
                [108.5065566, 14.6667765],
                [108.5065089, 14.6671546],
                [108.5061209, 14.6675807],
                [108.5060724, 14.6676281],
                [108.5061224, 14.668195],
                [108.5059339, 14.6707942],
                [108.5060358, 14.6728729],
                [108.5063775, 14.6734391],
                [108.5067192, 14.6740526],
                [108.507207, 14.6748077],
                [108.5074998, 14.6752322],
                [108.5077921, 14.6755623],
                [108.508328, 14.6760336],
                [108.508669, 14.6763164],
                [108.5090586, 14.6766464],
                [108.5093996, 14.6769764],
                [108.5098385, 14.6774952],
                [108.5100342, 14.6780617],
                [108.5104265, 14.6795255],
                [108.5107692, 14.680517],
                [108.5109648, 14.6810363],
                [108.511795, 14.6826411],
                [108.512234, 14.6832543],
                [108.5126249, 14.684104],
                [108.5126276, 14.685238],
                [108.5125058, 14.6866554],
                [108.5124855, 14.686892],
                [108.5122924, 14.6874595],
                [108.5119533, 14.6879799],
                [108.5113707, 14.6883592],
                [108.5107399, 14.6888804],
                [108.509623, 14.6894026],
                [108.5077284, 14.6900682],
                [108.5069508, 14.6902117],
                [108.5054923, 14.6902149],
                [108.5045203, 14.6903115],
                [108.50418, 14.6903594],
                [108.5035977, 14.6908332],
                [108.5027249, 14.6918274],
                [108.5022895, 14.6927733],
                [108.5022418, 14.6931987],
                [108.5022472, 14.6955611],
                [108.5021022, 14.6959395],
                [108.5018594, 14.6960345],
                [108.5012274, 14.6960831],
                [108.5006441, 14.6961316],
                [108.4997207, 14.6963226],
                [108.4987006, 14.6967028],
                [108.497923, 14.6968462],
                [108.4970975, 14.6973205],
                [108.496661, 14.6977467],
                [108.4963715, 14.6987395],
                [108.4962768, 14.6998738],
                [108.4962779, 14.7003462],
                [108.4965227, 14.7011018],
                [108.4967667, 14.7014792],
                [108.4972538, 14.7019034],
                [108.4975943, 14.70195],
                [108.497886, 14.7019966],
                [108.4981295, 14.7021378],
                [108.4983735, 14.7025153],
                [108.4988117, 14.7027979],
                [108.4992006, 14.702797],
                [108.4996868, 14.7027487],
                [108.5001727, 14.7026531],
                [108.5005131, 14.7026524],
                [108.5009509, 14.702746],
                [108.5013408, 14.7031703],
                [108.5015361, 14.7035479],
                [108.5015854, 14.7038313],
                [108.5018057, 14.7042822],
                [108.5023299, 14.7051623],
                [108.5029638, 14.7063898],
                [108.5033472, 14.7075942],
                [108.503488, 14.708084],
                [108.5037539, 14.7085955],
                [108.5040428, 14.7089241],
                [108.5043333, 14.7091104],
                [108.5057615, 14.7099089],
                [108.5094033, 14.7118607],
                [108.5115855, 14.7143986],
                [108.5133406, 14.71723],
                [108.5139283, 14.7177121],
                [108.5143775, 14.7180907],
                [108.5149949, 14.7190146],
                [108.5153554, 14.7195282],
                [108.515751, 14.7200251],
                [108.5160266, 14.7203338],
                [108.516373, 14.7205414],
                [108.5170511, 14.7207355],
                [108.5174857, 14.7208591],
                [108.5181807, 14.7211043],
                [108.5184743, 14.7213623],
                [108.5186031, 14.7216752],
                [108.5185991, 14.7220319],
                [108.5185615, 14.7222694],
                [108.5184539, 14.7225231],
                [108.5182414, 14.7228096],
                [108.5179944, 14.7230618],
                [108.5176955, 14.7232624],
                [108.5173791, 14.7234799],
                [108.5171834, 14.7238176],
                [108.5170573, 14.7241729],
                [108.517, 14.724614],
                [108.5170651, 14.7250224],
                [108.5171324, 14.725244],
                [108.517287, 14.7254496],
                [108.517597, 14.7258097],
                [108.5177679, 14.7261172],
                [108.5178687, 14.7264581],
                [108.5178999, 14.7267813],
                [108.5178928, 14.7274098],
                [108.5180075, 14.7280565],
                [108.518108, 14.7284313],
                [108.5182964, 14.7287392],
                [108.5186046, 14.7292521],
                [108.5189643, 14.7298335],
                [108.5192894, 14.7303976],
                [108.5194769, 14.7307734],
                [108.5195597, 14.731165],
                [108.5195204, 14.7315553],
                [108.5193587, 14.7319783],
                [108.5193199, 14.7323177],
                [108.5193326, 14.7327425],
                [108.5193979, 14.733134],
                [108.5196018, 14.7336119],
                [108.5199273, 14.7341419],
                [108.5205637, 14.7349303],
                [108.5212377, 14.7354981],
                [108.5217384, 14.7359451],
                [108.5221689, 14.7364424],
                [108.5223226, 14.7367328],
                [108.5223724, 14.7369712],
                [108.5223169, 14.7372424],
                [108.5221395, 14.7375124],
                [108.5219629, 14.7376974],
                [108.5216106, 14.7379993],
                [108.5213451, 14.7383363],
                [108.5211852, 14.7385894],
                [108.5211286, 14.7389625],
                [108.5211601, 14.7392686],
                [108.521314, 14.7395421],
                [108.5215712, 14.7399356],
                [108.5218988, 14.740279],
                [108.5221926, 14.7405199],
                [108.5225746, 14.7406768],
                [108.5228874, 14.7407822],
                [108.5233916, 14.7409404],
                [108.5237216, 14.7410629],
                [108.5241024, 14.7413217],
                [108.5244989, 14.7417508],
                [108.524702, 14.7422965],
                [108.5248877, 14.7428422],
                [108.5250227, 14.7432514],
                [108.5251585, 14.7435756],
                [108.525383, 14.7437818],
                [108.525625, 14.7439713],
                [108.5259898, 14.7441111],
                [108.5263375, 14.7442167],
                [108.5267376, 14.744323],
                [108.5273989, 14.744466],
                [108.5280595, 14.7446599],
                [108.5284747, 14.7449701],
                [108.5286466, 14.7451927],
                [108.5287486, 14.7454316],
                [108.5287454, 14.7457205],
                [108.5287245, 14.746026],
                [108.5286683, 14.7463651],
                [108.5285417, 14.7467715],
                [108.5283066, 14.7474995],
                [108.5281805, 14.7480668],
                [108.5281453, 14.7485548],
                [108.5275441, 14.7498764],
                [108.5272206, 14.7502609],
                [108.5270119, 14.7509988],
                [108.5270035, 14.7517389],
                [108.5274257, 14.7533796],
                [108.5274588, 14.7540033],
                [108.5274139, 14.7544314],
                [108.5269687, 14.7548162],
                [108.5259953, 14.7558661],
                [108.5255004, 14.7564751],
                [108.5252318, 14.7569107],
                [108.5249837, 14.7570406],
                [108.5247276, 14.7571349],
                [108.5241598, 14.7573235],
                [108.5237667, 14.7574304],
                [108.5233575, 14.7575054],
                [108.5230471, 14.757518],
                [108.5228649, 14.7574708],
                [108.5228504, 14.7574691],
                [108.522841, 14.7574682],
                [108.5228332, 14.7574706],
                [108.5228281, 14.7574767],
                [108.5224411, 14.7584261],
                [108.5215059, 14.7595409],
                [108.5212679, 14.7600794],
                [108.5210944, 14.7608938],
                [108.5210475, 14.7611141],
                [108.5209662, 14.761318],
                [108.5201527, 14.7622189],
                [108.5200382, 14.7631283],
                [108.5199868, 14.7637334],
                [108.5198617, 14.7645437],
                [108.5195658, 14.7660924],
                [108.5190847, 14.7676192],
                [108.5186268, 14.7691462],
                [108.5181225, 14.7706729],
                [108.5177442, 14.7713437],
                [108.5168233, 14.7728214],
                [108.5163488, 14.773763],
                [108.5161396, 14.7738059],
                [108.5155346, 14.7739985],
                [108.5131437, 14.7746966],
                [108.5095075, 14.7757595],
                [108.5065176, 14.7767863],
                [108.5047707, 14.7776361],
                [108.5029157, 14.7792566],
                [108.501749, 14.7808593],
                [108.5010502, 14.782661],
                [108.5003434, 14.7838571],
                [108.4999977, 14.7847103],
                [108.4998854, 14.7853415],
                [108.5001498, 14.7860993],
                [108.5006864, 14.7869211],
                [108.501039, 14.7873126],
                [108.5014753, 14.7877253],
                [108.5018063, 14.7886864],
                [108.5013164, 14.7887642],
                [108.5005963, 14.7892868],
                [108.4999588, 14.7899328],
                [108.4997019, 14.7903585],
                [108.4995703, 14.7908467],
                [108.4993108, 14.7914967],
                [108.4989074, 14.7918799],
                [108.497978, 14.7923391],
                [108.4967563, 14.7926523],
                [108.4959548, 14.7929496],
                [108.4957639, 14.7931108],
                [108.4955719, 14.7933739],
                [108.4953965, 14.7940045],
                [108.4954125, 14.7944535],
                [108.495597, 14.7948432],
                [108.4958443, 14.7952539],
                [108.4960289, 14.7956435],
                [108.4961931, 14.7959718],
                [108.4962327, 14.7961762],
                [108.4961872, 14.7964818],
                [108.4959747, 14.7967038],
                [108.4957208, 14.7968643],
                [108.4947268, 14.7974655],
                [108.4945354, 14.7976675],
                [108.4942584, 14.7980113],
                [108.4940001, 14.7985594],
                [108.4938714, 14.8006185],
                [108.4934124, 14.8003483],
                [108.4926195, 14.7998909],
                [108.4917405, 14.7996162],
                [108.4888349, 14.7984215],
                [108.4856591, 14.7969792],
                [108.4827094, 14.7959676],
                [108.4794255, 14.7947892],
                [108.4772503, 14.7940106],
                [108.4761833, 14.793652],
                [108.4753044, 14.7933773],
                [108.4736305, 14.7928284],
                [108.47275, 14.7926963],
                [108.4719944, 14.7926473],
                [108.4712382, 14.7926594],
                [108.469978, 14.7926659],
                [108.469139, 14.7925752],
                [108.4683016, 14.7923415],
                [108.4672974, 14.7920041],
                [108.4664825, 14.7916483],
                [108.4650421, 14.790898],
                [108.4640586, 14.7905811],
                [108.4632629, 14.7903888],
                [108.4626765, 14.7902395],
                [108.4614172, 14.7901644],
                [108.4595063, 14.7901434],
                [108.4585821, 14.7901537],
                [108.457721, 14.7901644],
                [108.4571948, 14.7902607],
                [108.4568566, 14.7904406],
                [108.4564541, 14.7907422],
                [108.4561129, 14.7911872],
                [108.4557029, 14.7921212],
                [108.4553331, 14.7932188],
                [108.4549666, 14.7940308],
                [108.4546392, 14.7950881],
                [108.4543767, 14.7959828],
                [108.4536853, 14.7979485],
                [108.4535758, 14.7985276],
                [108.4535481, 14.799298],
                [108.4534296, 14.7998498],
                [108.4531898, 14.8004002],
                [108.452913, 14.8009322],
                [108.4527134, 14.8012292],
                [108.4520046, 14.8019829],
                [108.4517312, 14.8022067],
                [108.4514969, 14.8022857],
                [108.4510762, 14.8023445],
                [108.4498515, 14.8025032],
                [108.4484309, 14.8026507],
                [108.4470667, 14.8027716],
                [108.4464785, 14.8027741],
                [108.4461137, 14.8028336],
                [108.4456272, 14.802937],
                [108.4451584, 14.8031131],
                [108.4445958, 14.8033336],
                [108.4441935, 14.8034016],
                [108.4439227, 14.8034078],
                [108.4436711, 14.8033777],
                [108.4428619, 14.8031238],
                [108.4419883, 14.8027969],
                [108.4415785, 14.8027197],
                [108.4412612, 14.8027162],
                [108.4410179, 14.8027679],
                [108.440933, 14.8028025],
                [108.4407633, 14.8028352],
                [108.4405616, 14.8029089],
                [108.4401629, 14.8032622],
                [108.439731, 14.8036798],
                [108.4389668, 14.804258],
                [108.438402, 14.804515],
                [108.4376378, 14.8048362],
                [108.4369069, 14.8052859],
                [108.4362276, 14.8057389],
                [108.43544, 14.8057339],
                [108.4353806, 14.8067932],
                [108.435465, 14.807545],
                [108.4354202, 14.8080553],
                [108.4351652, 14.8083155],
                [108.4349484, 14.8084943],
                [108.4344323, 14.8087244],
                [108.433982, 14.8089098],
                [108.4332421, 14.8091191],
                [108.4322117, 14.809425],
                [108.4317713, 14.8095652],
                [108.4314241, 14.8097154],
                [108.4312455, 14.8098222],
                [108.4310475, 14.8099923],
                [108.4308397, 14.8101985],
                [108.4306884, 14.8103601],
                [108.4304892, 14.8106298],
                [108.4303838, 14.8108644],
                [108.4302489, 14.8112165],
                [108.430209, 14.8114338],
                [108.4301865, 14.8118898],
                [108.4301515, 14.8130107],
                [108.4302291, 14.8138155],
                [108.4303729, 14.8145577],
                [108.4305421, 14.8149826],
                [108.4306488, 14.8151743],
                [108.429896, 14.8162026],
                [108.4292349, 14.8168511],
                [108.4286216, 14.8171404],
                [108.4277902, 14.8174485],
                [108.4271568, 14.8175895],
                [108.425937, 14.817597],
                [108.4239775, 14.8175327],
                [108.4217577, 14.8173598],
                [108.4190601, 14.8170756],
                [108.4168387, 14.8170508],
                [108.4159243, 14.8170193],
                [108.4133743, 14.8171388],
                [108.4127192, 14.8172795],
                [108.4119084, 14.8176935],
                [108.4110322, 14.8181068],
                [108.4102684, 14.8182251],
                [108.409745, 14.8182827],
                [108.4093514, 14.8184052],
                [108.4089347, 14.8186544],
                [108.4077075, 14.8192752],
                [108.4058254, 14.8200158],
                [108.4041849, 14.8205895],
                [108.4031109, 14.8211487],
                [108.4028513, 14.8230507],
                [108.4025932, 14.8232121],
                [108.4022035, 14.8231803],
                [108.4019508, 14.8230814],
                [108.4016415, 14.823563],
                [108.4008809, 14.8236955],
                [108.4004266, 14.8241495],
                [108.3998594, 14.8242447],
                [108.3989969, 14.8239783],
                [108.3983728, 14.8241657],
                [108.3974821, 14.8244328],
                [108.3973398, 14.8244441],
                [108.3972607, 14.8245769],
                [108.3971548, 14.8248055],
                [108.3970448, 14.8250049],
                [108.3967178, 14.8253689],
                [108.3963922, 14.8256034],
                [108.3960549, 14.8257459],
                [108.3957572, 14.8258177],
                [108.3954722, 14.8259022],
                [108.3952171, 14.826008],
                [108.3948796, 14.8261714],
                [108.3945197, 14.8264055],
                [108.3942549, 14.8265948],
                [108.3940765, 14.8267599],
                [108.3938885, 14.8270044],
                [108.3937263, 14.8272617],
                [108.3934811, 14.8276182],
                [108.3929348, 14.8283225],
                [108.3927285, 14.8286628],
                [108.3926095, 14.8288955],
                [108.3925255, 14.8290825],
                [108.3923914, 14.8294947],
                [108.3920723, 14.8302768],
                [108.3918515, 14.8307549],
                [108.3916488, 14.8311495],
                [108.3913607, 14.8321198],
                [108.3909346, 14.8325286],
                [108.3900299, 14.8330242],
                [108.3896714, 14.8335049],
                [108.3894217, 14.8338698],
                [108.3891556, 14.8341678],
                [108.3888298, 14.8344357],
                [108.3884528, 14.8346528],
                [108.3876614, 14.8349865],
                [108.3868708, 14.8352533],
                [108.3862617, 14.8354512],
                [108.3861018, 14.8355079],
                [108.3858426, 14.8355885],
                [108.3856228, 14.8356237],
                [108.3853385, 14.8356455],
                [108.3850241, 14.8356753],
                [108.3848385, 14.8357192],
                [108.3847348, 14.8357598],
                [108.3846045, 14.8358545],
                [108.3844995, 14.8360038],
                [108.3843682, 14.8361861],
                [108.3840697, 14.8366758],
                [108.3838281, 14.8370826],
                [108.3836038, 14.8374896],
                [108.3833845, 14.8378423],
                [108.3830742, 14.83824],
                [108.3828116, 14.8386047],
                [108.3826007, 14.8389659],
                [108.3824807, 14.8392821],
                [108.3824222, 14.8394946],
                [108.3824071, 14.8396741],
                [108.3823823, 14.8399497],
                [108.3823535, 14.8401917],
                [108.38228, 14.8405837],
                [108.3822252, 14.8408421],
                [108.3821527, 14.8411506],
                [108.3820585, 14.8414671],
                [108.3819652, 14.8417126],
                [108.3818599, 14.8418827],
                [108.3817244, 14.8420567],
                [108.3816234, 14.8422186],
                [108.3815655, 14.8423933],
                [108.3815503, 14.8425771],
                [108.3815565, 14.8427735],
                [108.3816003, 14.8430791],
                [108.3816275, 14.8433177],
                [108.3816212, 14.8434763],
                [108.3815941, 14.8435847],
                [108.3815757, 14.8436806],
                [108.3815098, 14.8437969],
                [108.3814005, 14.8439419],
                [108.3812439, 14.844078],
                [108.3808316, 14.84437],
                [108.3803421, 14.8446403],
                [108.3797229, 14.8449633],
                [108.3793946, 14.8450682],
                [108.3791013, 14.8451276],
                [108.378873, 14.8451459],
                [108.3786323, 14.8451264],
                [108.3784389, 14.8451033],
                [108.3782291, 14.8450132],
                [108.3779123, 14.8448842],
                [108.3776976, 14.8448441],
                [108.377521, 14.8448589],
                [108.3773047, 14.8449566],
                [108.3771136, 14.8450966],
                [108.3768878, 14.845282],
                [108.3767302, 14.8454934],
                [108.3766286, 14.8457179],
                [108.3765439, 14.8459635],
                [108.3764234, 14.8463172],
                [108.3763734, 14.8465298],
                [108.3763185, 14.8468092],
                [108.3762719, 14.8471012],
                [108.3762553, 14.8474102],
                [108.3762, 14.8477147],
                [108.3761641, 14.8478355],
                [108.376076, 14.8480016],
                [108.3758834, 14.8482752],
                [108.3755035, 14.8487306],
                [108.3751761, 14.8491154],
                [108.3751011, 14.8492692],
                [108.3750695, 14.8493983],
                [108.3750552, 14.8495111],
                [108.3750701, 14.8496951],
                [108.3751222, 14.8500175],
                [108.3751836, 14.8502815],
                [108.3752421, 14.8504325],
                [108.3752661, 14.850575],
                [108.3752695, 14.8506544],
                [108.3752545, 14.8508171],
                [108.3752097, 14.8509629],
                [108.375046, 14.8513413],
                [108.3748918, 14.8516362],
                [108.374703, 14.8519433],
                [108.374667, 14.8520766],
                [108.3746604, 14.8522646],
                [108.3746715, 14.8524235],
                [108.3747124, 14.8525996],
                [108.374771, 14.8527256],
                [108.3748551, 14.8528937],
                [108.3748969, 14.8529945],
                [108.3749172, 14.8530992],
                [108.3749067, 14.8532578],
                [108.3748598, 14.853575],
                [108.3748075, 14.8539755],
                [108.3747463, 14.8544178],
                [108.3746642, 14.8548054],
                [108.3745973, 14.8549927],
                [108.3744959, 14.8552005],
                [108.3743602, 14.8553828],
                [108.374186, 14.8555564],
                [108.3740078, 14.8557048],
                [108.3738341, 14.8558323],
                [108.3731156, 14.8561793],
                [108.3727088, 14.8563669],
                [108.3723922, 14.8565723],
                [108.3722747, 14.8566837],
                [108.3721651, 14.8568455],
                [108.3720769, 14.8570283],
                [108.372019, 14.8571906],
                [108.372004, 14.8573535],
                [108.3719671, 14.857562],
                [108.3719088, 14.8577619],
                [108.3718687, 14.8578743],
                [108.3717808, 14.8580237],
                [108.3716798, 14.8581896],
                [108.3715763, 14.8583493],
                [108.3714865, 14.8586652],
                [108.3714847, 14.8588154],
                [108.3715163, 14.8590493],
                [108.3715108, 14.8594997],
                [108.371386, 14.859882],
                [108.3712948, 14.8603147],
                [108.3709238, 14.8611613],
                [108.3705941, 14.8614244],
                [108.370161, 14.8617364],
                [108.3699354, 14.8619174],
                [108.3699002, 14.8619837],
                [108.3698294, 14.8621497],
                [108.3698105, 14.8622997],
                [108.3698426, 14.8624836],
                [108.3700123, 14.8626524],
                [108.3702517, 14.8627552],
                [108.3705085, 14.8628248],
                [108.3708151, 14.8630452],
                [108.3709337, 14.86318],
                [108.3710349, 14.8633314],
                [108.3710148, 14.8635813],
                [108.3709262, 14.8637972],
                [108.3706479, 14.8640776],
                [108.3705427, 14.86426],
                [108.3704717, 14.8644427],
                [108.3704356, 14.8645924],
                [108.3704328, 14.8648259],
                [108.3704479, 14.8649929],
                [108.3704796, 14.8652102],
                [108.3704776, 14.865377],
                [108.3704071, 14.8655263],
                [108.3702678, 14.8656749],
                [108.3700598, 14.8658227],
                [108.3694898, 14.8660831],
                [108.36873, 14.866408],
                [108.3684191, 14.8665379],
                [108.3679684, 14.8668663],
                [108.3677425, 14.8670807],
                [108.3675381, 14.8673211],
                [108.3674701, 14.8674909],
                [108.367437, 14.8678106],
                [108.367463, 14.8680812],
                [108.3675319, 14.8684447],
                [108.3676014, 14.8687513],
                [108.3676862, 14.8690011],
                [108.3677717, 14.8692084],
                [108.3678722, 14.8693659],
                [108.3681026, 14.8697028],
                [108.3684429, 14.8700198],
                [108.3687756, 14.8703649],
                [108.3688984, 14.8705085],
                [108.3689704, 14.8706089],
                [108.369006, 14.8706876],
                [108.3690117, 14.8708299],
                [108.3690034, 14.8709081],
                [108.3689726, 14.8710286],
                [108.3688828, 14.8711841],
                [108.3686962, 14.8714736],
                [108.3685404, 14.8716424],
                [108.3683843, 14.8718326],
                [108.3682941, 14.8720236],
                [108.368237, 14.8721766],
                [108.3680845, 14.8726383],
                [108.3680814, 14.8728911],
                [108.3681172, 14.8732415],
                [108.3681139, 14.8735137],
                [108.3680908, 14.8737663],
                [108.3680486, 14.8739407],
                [108.3679262, 14.8741338],
                [108.3677445, 14.8742679],
                [108.3675624, 14.8744407],
                [108.3674199, 14.8746336],
                [108.3673777, 14.874808],
                [108.3673554, 14.8750022],
                [108.3673328, 14.875216],
                [108.3672905, 14.8754099],
                [108.3671885, 14.8755642],
                [108.3670465, 14.8757181],
                [108.3666443, 14.8758886],
                [108.3663628, 14.876002],
                [108.3661202, 14.8761937],
                [108.3658372, 14.8764432],
                [108.3656347, 14.8766354],
                [108.3654916, 14.8768864],
                [108.3654875, 14.877217],
                [108.3656227, 14.8776269],
                [108.3657974, 14.878018],
                [108.365974, 14.8783115],
                [108.3660704, 14.8786041],
                [108.3661089, 14.8787407],
                [108.3661058, 14.8789933],
                [108.3660236, 14.8791673],
                [108.3658627, 14.8792236],
                [108.3655002, 14.8794139],
                [108.3650563, 14.8797003],
                [108.3648134, 14.8799308],
                [108.36459, 14.8802003],
                [108.3643661, 14.8805086],
                [108.3641802, 14.8809729],
                [108.3641333, 14.8814813],
                [108.3637649, 14.8817628],
                [108.3636814, 14.8818108],
                [108.3630653, 14.8821079],
                [108.3622743, 14.8822311],
                [108.3607258, 14.8819751],
                [108.3588574, 14.8811602],
                [108.3575846, 14.8806166],
                [108.3567236, 14.8797868],
                [108.3559437, 14.8790108],
                [108.3555114, 14.8787414],
                [108.3551867, 14.8785789],
                [108.3547525, 14.8784681],
                [108.3543446, 14.8784369],
                [108.3538931, 14.8786301],
                [108.3517819, 14.8798752],
                [108.3509576, 14.8805005],
                [108.3505422, 14.8810775],
                [108.3502639, 14.8815768],
                [108.3501194, 14.8822627],
                [108.3500034, 14.8828432],
                [108.3500199, 14.8837162],
                [108.3499398, 14.8847071],
                [108.3496265, 14.8858408],
                [108.3495114, 14.8863419],
                [108.349479, 14.8867647],
                [108.3496097, 14.8872159],
                [108.3499311, 14.8876427],
                [108.3501709, 14.8880687],
                [108.3503035, 14.8883611],
                [108.3503805, 14.8887323],
                [108.3504032, 14.8891028],
                [108.3502898, 14.8894717],
                [108.3501223, 14.8898136],
                [108.3500089, 14.8901826],
                [108.350088, 14.8903951],
                [108.3504229, 14.8908353],
                [108.3507142, 14.8914999],
                [108.3508179, 14.8919243],
                [108.3509423, 14.8928779],
                [108.3509602, 14.893645],
                [108.3508971, 14.8943584],
                [108.3508109, 14.8947277],
                [108.3501718, 14.8957782],
                [108.3496808, 14.8969719],
                [108.3492641, 14.8976544],
                [108.3490989, 14.897811],
                [108.3489073, 14.8978881],
                [108.3476284, 14.8978469],
                [108.3464317, 14.8977536],
                [108.3457508, 14.8977723],
                [108.345313, 14.8979522],
                [108.3448192, 14.8982636],
                [108.344513, 14.8988152],
                [108.3443633, 14.8999236],
                [108.3442463, 14.900583],
                [108.3440505, 14.9010037],
                [108.3437456, 14.9014494],
                [108.3426436, 14.9025205],
                [108.3413749, 14.9038538],
                [108.3401858, 14.9053465],
                [108.3396288, 14.9063709],
                [108.3392097, 14.9072383],
                [108.3389312, 14.9076036],
                [108.3388724, 14.9076644],
                [108.3387545, 14.9077934],
                [108.3386273, 14.9079484],
                [108.3384915, 14.9080773],
                [108.3383389, 14.908119],
                [108.33816, 14.9081082],
                [108.3379547, 14.908071],
                [108.3375259, 14.9080139],
                [108.3371287, 14.9079353],
                [108.3368071, 14.9078881],
                [108.3365389, 14.9078676],
                [108.336288, 14.9078994],
                [108.3361088, 14.9079148],
                [108.335947, 14.9079738],
                [108.3357123, 14.9081363],
                [108.3353873, 14.9083759],
                [108.335045, 14.9085544],
                [108.3346222, 14.9087408],
                [108.3343211, 14.908846],
                [108.3341328, 14.9088786],
                [108.333855, 14.9089101],
                [108.3334504, 14.9090706],
                [108.3330895, 14.9093098],
                [108.3326823, 14.9096788],
                [108.3323566, 14.909962],
                [108.3320235, 14.910132],
                [108.3315782, 14.9103224],
                [108.3311109, 14.9104822],
                [108.3308327, 14.9105398],
                [108.3306002, 14.9105371],
                [108.3303233, 14.9104904],
                [108.3300738, 14.9104092],
                [108.3296728, 14.9102741],
                [108.3292462, 14.9100343],
                [108.3287654, 14.9098375],
                [108.3285743, 14.909744],
                [108.3283419, 14.9097152],
                [108.3281451, 14.9097129],
                [108.3279211, 14.9097277],
                [108.3277688, 14.909752],
                [108.3276067, 14.9098284],
                [108.3273094, 14.90999],
                [108.326878, 14.9101502],
                [108.3265364, 14.9102766],
                [108.3258048, 14.9104535],
                [108.3254818, 14.9105279],
                [108.3251141, 14.9105931],
                [108.3247108, 14.9106492],
                [108.3244239, 14.9106892],
                [108.3242081, 14.9107736],
                [108.3239834, 14.9108579],
                [108.3237153, 14.9110378],
                [108.3225632, 14.9118076],
                [108.3225315, 14.911835],
                [108.3224712, 14.9121851],
                [108.3223956, 14.9124071],
                [108.3222899, 14.9125374],
                [108.3221843, 14.9126574],
                [108.3220369, 14.9127972],
                [108.3219003, 14.9128968],
                [108.3216389, 14.9130048],
                [108.3213671, 14.9130927],
                [108.3212518, 14.9131622],
                [108.3210821, 14.9134128],
                [108.3210181, 14.9135537],
                [108.321011, 14.9141197],
                [108.3210631, 14.9145399],
                [108.3211332, 14.9147632],
                [108.3213053, 14.9151493],
                [108.3214061, 14.9154133],
                [108.3214874, 14.9155761],
                [108.3215163, 14.9157584],
                [108.3215246, 14.9159303],
                [108.3215019, 14.9160717],
                [108.3213523, 14.9163934],
                [108.3212668, 14.9165744],
                [108.320977, 14.9168692],
                [108.3203057, 14.9172859],
                [108.319583, 14.9176516],
                [108.3192993, 14.9178706],
                [108.3192037, 14.9180313],
                [108.3191186, 14.9181819],
                [108.3190752, 14.918323],
                [108.3190408, 14.9185753],
                [108.3190287, 14.9187168],
                [108.3190363, 14.9189392],
                [108.3191519, 14.9192691],
                [108.319553, 14.919658],
                [108.319729, 14.9197309],
                [108.3198641, 14.9197527],
                [108.3200617, 14.9197551],
                [108.3202805, 14.9197373],
                [108.320499, 14.9197399],
                [108.3207177, 14.9197324],
                [108.3209154, 14.9197346],
                [108.3210192, 14.9197561],
                [108.3210912, 14.9198277],
                [108.3211412, 14.91999],
                [108.3212018, 14.9201424],
                [108.3212816, 14.9204164],
                [108.3214335, 14.9207619],
                [108.3216888, 14.9211592],
                [108.3218006, 14.9213727],
                [108.3218848, 14.9217225],
                [108.321922, 14.9220768],
                [108.3218848, 14.9225516],
                [108.3218484, 14.9229757],
                [108.3217822, 14.9232783],
                [108.3216864, 14.923449],
                [108.3214638, 14.9237699],
                [108.3212536, 14.9239393],
                [108.3210133, 14.9240174],
                [108.3204664, 14.9240565],
                [108.3197566, 14.92422],
                [108.3193586, 14.9244176],
                [108.3191387, 14.9245262],
                [108.3190437, 14.9246464],
                [108.3189375, 14.9248068],
                [108.3188939, 14.9249681],
                [108.3188712, 14.9251196],
                [108.3189003, 14.9252917],
                [108.3189079, 14.9255243],
                [108.3190193, 14.9257582],
                [108.3193294, 14.9263532],
                [108.3195039, 14.9265475],
                [108.3196172, 14.9266499],
                [108.3197823, 14.926763],
                [108.32, 14.9268362],
                [108.3201661, 14.9268686],
                [108.3203536, 14.9268606],
                [108.3205724, 14.926833],
                [108.3208024, 14.9267547],
                [108.3210943, 14.9267076],
                [108.3213129, 14.9267101],
                [108.3214681, 14.9267928],
                [108.3216431, 14.9269364],
                [108.321859, 14.9271613],
                [108.3220124, 14.9273755],
                [108.3220726, 14.9275582],
                [108.3220911, 14.9277403],
                [108.3221095, 14.9279428],
                [108.3220861, 14.9281447],
                [108.322019, 14.928518],
                [108.3219278, 14.9287444],
                [108.3217164, 14.9290047],
                [108.3215163, 14.9291945],
                [108.3213172, 14.9293034],
                [108.3210772, 14.9293511],
                [108.3209209, 14.9293695],
                [108.3207859, 14.9293477],
                [108.3205793, 14.9292139],
                [108.319767, 14.9284158],
                [108.319345, 14.9280367],
                [108.3191283, 14.9278825],
                [108.3189002, 14.9278091],
                [108.3187653, 14.9277772],
                [108.3185725, 14.9278002],
                [108.318404, 14.92796],
                [108.318137, 14.9285129],
                [108.3178048, 14.9292773],
                [108.3175915, 14.9301089],
                [108.3175117, 14.9306741],
                [108.3174496, 14.9314822],
                [108.3173392, 14.932406],
                [108.3173023, 14.9328605],
                [108.3170852, 14.9335859],
                [108.31686, 14.934119],
                [108.3167, 14.9344305],
                [108.3165622, 14.9346311],
                [108.3164359, 14.9347409],
                [108.316217, 14.9347685],
                [108.3160297, 14.9347664],
                [108.3157908, 14.9347333],
                [108.3155728, 14.9346801],
                [108.3152203, 14.934575],
                [108.3149509, 14.9344706],
                [108.3147534, 14.9344481],
                [108.3145975, 14.9344361],
                [108.3144929, 14.9344753],
                [108.3142563, 14.9346697],
                [108.3141388, 14.934911],
                [108.3140526, 14.9351526],
                [108.3140293, 14.9353444],
                [108.314027, 14.9355263],
                [108.3140874, 14.9356989],
                [108.3141685, 14.9358719],
                [108.314302, 14.9360149],
                [108.3145292, 14.9361592],
                [108.3148179, 14.9363748],
                [108.3155621, 14.936798],
                [108.3159803, 14.9370709],
                [108.3161753, 14.9372854],
                [108.3163077, 14.9375195],
                [108.3163885, 14.9377227],
                [108.316407, 14.9379049],
                [108.3164145, 14.9381375],
                [108.3163912, 14.9383395],
                [108.3163037, 14.9386821],
                [108.3161433, 14.939024],
                [108.315915, 14.9393903],
                [108.315461, 14.9399006],
                [108.315113, 14.9402706],
                [108.314709, 14.9409533],
                [108.3142467, 14.9417213],
                [108.3139676, 14.942232],
                [108.3137141, 14.9426957],
                [108.3133812, 14.9435106],
                [108.3130434, 14.9443189],
                [108.3127859, 14.9449322],
                [108.3125373, 14.9456769],
                [108.3123543, 14.9461497],
                [108.3123347, 14.9461868],
                [108.3115078, 14.9470934],
                [108.3105793, 14.9472194],
                [108.3103763, 14.9472342],
                [108.3102519, 14.9472328],
                [108.3101421, 14.9471643],
                [108.3100739, 14.9470963],
                [108.3099648, 14.9469742],
                [108.3098691, 14.9468925],
                [108.309745, 14.9468642],
                [108.3096342, 14.9468762],
                [108.3095365, 14.9469557],
                [108.309466, 14.9470624],
                [108.3093533, 14.9472356],
                [108.3093241, 14.9473561],
                [108.3091904, 14.9475492],
                [108.3090223, 14.9477218],
                [108.3089235, 14.9478819],
                [108.3088103, 14.9480954],
                [108.3087393, 14.9482423],
                [108.3086406, 14.9484023],
                [108.3085284, 14.9485352],
                [108.3083883, 14.9486813],
                [108.3082071, 14.9488],
                [108.3077616, 14.9490499],
                [108.3072468, 14.9493124],
                [108.3068358, 14.949567],
                [108.3064589, 14.9498578],
                [108.3062168, 14.950101],
                [108.3059561, 14.9502678],
                [108.3058254, 14.9503029],
                [108.3055273, 14.9502897],
                [108.3053339, 14.950274],
                [108.3052919, 14.9503137],
                [108.3051795, 14.95046],
                [108.3050809, 14.9506065],
                [108.3049271, 14.9507522],
                [108.3047879, 14.9508177],
                [108.3046076, 14.9508693],
                [108.3044277, 14.9508806],
                [108.304069, 14.9508226],
                [108.3039173, 14.950794],
                [108.3038616, 14.9508202],
                [108.3037632, 14.9509532],
                [108.3036774, 14.9511804],
                [108.3035772, 14.951461],
                [108.3035305, 14.9518765],
                [108.3034159, 14.9521971],
                [108.3032474, 14.9524099],
                [108.3030381, 14.9525551],
                [108.3026339, 14.9528186],
                [108.3023828, 14.9530035],
                [108.3020295, 14.953238],
                [108.3015202, 14.9538734],
                [108.301286, 14.9547828],
                [108.3009938, 14.955243],
                [108.3007979, 14.9556272],
                [108.300588, 14.955872],
                [108.3003306, 14.9560854],
                [108.2997375, 14.9564184],
                [108.2991095, 14.9569984],
                [108.2983782, 14.9575695],
                [108.2976559, 14.9580557],
                [108.2971441, 14.9582505],
                [108.2965535, 14.9583827],
                [108.2959484, 14.9584064],
                [108.2955816, 14.9584639],
                [108.294749, 14.9588559],
                [108.2935607, 14.9596921],
                [108.2928468, 14.9601396],
                [108.2924943, 14.9603209],
                [108.2913738, 14.9608333],
                [108.2909264, 14.9609671],
                [108.2904001, 14.9610535],
                [108.2899537, 14.96111],
                [108.2895232, 14.9611668],
                [108.2892034, 14.9612866],
                [108.2888354, 14.9614368],
                [108.2886107, 14.9615887],
                [108.288434, 14.9617103],
                [108.288303, 14.9620024],
                [108.2882207, 14.9622179],
                [108.2881066, 14.962433],
                [108.2879748, 14.962787],
                [108.2877387, 14.9632309],
                [108.287267, 14.9640074],
                [108.2871217, 14.9641758],
                [108.2868335, 14.9643116],
                [108.2863714, 14.9643523],
                [108.2860925, 14.9643221],
                [108.2854473, 14.9644187],
                [108.284937, 14.9645054],
                [108.2844731, 14.9646853],
                [108.284074, 14.9647733],
                [108.2838022, 14.9648783],
                [108.2836097, 14.9649996],
                [108.2834317, 14.9652294],
                [108.283365, 14.9654605],
                [108.2834101, 14.9656774],
                [108.2834399, 14.9658324],
                [108.283627, 14.9661438],
                [108.2838314, 14.9663472],
                [108.2840363, 14.9665042],
                [108.2842629, 14.9668393],
                [108.2843392, 14.9671029],
                [108.2843838, 14.9673508],
                [108.2844121, 14.9676294],
                [108.2844244, 14.9679232],
                [108.284355, 14.9683708],
                [108.2840927, 14.9689705],
                [108.2836514, 14.9698926],
                [108.2833804, 14.9705619],
                [108.2832126, 14.9712401],
                [108.2831123, 14.9716254],
                [108.2831394, 14.9719967],
                [108.2832304, 14.9723534],
                [108.2833709, 14.9725714],
                [108.2836525, 14.9729613],
                [108.2838068, 14.9733495],
                [108.2838526, 14.9735047],
                [108.2838658, 14.9737212],
                [108.2837998, 14.9739059],
                [108.2836612, 14.9741593],
                [108.2830143, 14.9749709],
                [108.28245, 14.9755362],
                [108.2821114, 14.9758877],
                [108.2818518, 14.976271],
                [108.2817513, 14.9766718],
                [108.2816494, 14.9771807],
                [108.2815341, 14.9774884],
                [108.2814508, 14.9777811],
                [108.2813052, 14.9779649],
                [108.2810973, 14.9780398],
                [108.2809221, 14.978053],
                [108.2806839, 14.9780039],
                [108.2804943, 14.9778934],
                [108.280202, 14.9777122],
                [108.2799504, 14.9774773],
                [108.2796226, 14.9769633],
                [108.2793402, 14.9766353],
                [108.2790557, 14.9764772],
                [108.2788333, 14.9764437],
                [108.2785142, 14.9765017],
                [108.2780341, 14.9766969],
                [108.2776811, 14.9769246],
                [108.2773593, 14.9771989],
                [108.2770371, 14.9775043],
                [108.2767475, 14.9777482],
                [108.2764113, 14.9778988],
                [108.2760918, 14.9779877],
                [108.2758523, 14.9780466],
                [108.2755971, 14.97809],
                [108.2750149, 14.9781834],
                [108.2746948, 14.9783188],
                [108.2744841, 14.97861],
                [108.2743207, 14.9789481],
                [108.2743025, 14.9791333],
                [108.2742982, 14.9794734],
                [108.2743599, 14.9796287],
                [108.2744538, 14.9797535],
                [108.2745633, 14.9799094],
                [108.274735, 14.9801743],
                [108.2748114, 14.9804225],
                [108.2748575, 14.9805622],
                [108.2748709, 14.9807479],
                [108.2748051, 14.9809326],
                [108.2747075, 14.981086],
                [108.2744657, 14.9813304],
                [108.2740079, 14.9816573],
                [108.2736825, 14.9822099],
                [108.2733106, 14.9826692],
                [108.273053, 14.982898],
                [108.2728602, 14.9830349],
                [108.2724774, 14.9830921],
                [108.2721433, 14.983088],
                [108.2718576, 14.9830227],
                [108.2716206, 14.9828808],
                [108.2712576, 14.9826291],
                [108.2708792, 14.9823463],
                [108.2705326, 14.982064],
                [108.270295, 14.9819684],
                [108.2700409, 14.981919],
                [108.269627, 14.9819295],
                [108.2693717, 14.9819728],
                [108.2690664, 14.982201],
                [108.2688493, 14.9823761],
                [108.2684421, 14.9830978],
                [108.2680347, 14.9838503],
                [108.2677213, 14.9847122],
                [108.2671881, 14.9859634],
                [108.266926, 14.9865473],
                [108.2666823, 14.9869306],
                [108.2664241, 14.9872056],
                [108.2661343, 14.9874647],
                [108.2657808, 14.9877231],
                [108.2652994, 14.9880264],
                [108.2648164, 14.9884531],
                [108.2645744, 14.9887129],
                [108.2644284, 14.9889274],
                [108.2643777, 14.9891585],
                [108.2643427, 14.9894053],
                [108.2643522, 14.9897657],
                [108.2643807, 14.9902765],
                [108.2643712, 14.991025],
                [108.2643036, 14.9913804],
                [108.2640591, 14.9921098],
                [108.2638119, 14.992441],
                [108.2636222, 14.9930282],
                [108.2635149, 14.9934985],
                [108.2634679, 14.9940088],
                [108.2634418, 14.9944802],
                [108.2634957, 14.9950114],
                [108.2635662, 14.9958376],
                [108.2636202, 14.9963688],
                [108.2636162, 14.9966832],
                [108.2634667, 14.9972905],
                [108.2634238, 14.9974865],
                [108.2631955, 14.9979161],
                [108.2629281, 14.9982469],
                [108.2625169, 14.9987333],
                [108.2622707, 14.9989738],
                [108.2619932, 14.9991899],
                [108.2617342, 14.999438],
                [108.2609711, 14.9999342],
                [108.2605655, 15.0002048],
                [108.2602288, 15.0006372],
                [108.260034, 15.0010712],
                [108.25991, 15.0015291],
                [108.2598797, 15.0020571],
                [108.2598747, 15.0024475],
                [108.2597987, 15.0028371],
                [108.2595321, 15.0033392],
                [108.2591488, 15.003725],
                [108.2584465, 15.0040803],
                [108.2575889, 15.004552],
                [108.2571349, 15.0049137],
                [108.2568733, 15.0050254],
                [108.2565778, 15.0050361],
                [108.2556433, 15.0050336],
                [108.2549554, 15.0051859],
                [108.2545498, 15.0054565],
                [108.2542377, 15.00582],
                [108.2541381, 15.006209],
                [108.2541115, 15.0064382],
                [108.2540575, 15.0069655],
                [108.2540539, 15.0072409],
                [108.2539783, 15.0076072],
                [108.253856, 15.007927],
                [108.2536606, 15.0084068],
                [108.2527186, 15.0099333],
                [108.2523317, 15.0105943],
                [108.2521912, 15.0109325],
                [108.252053, 15.0116737],
                [108.2520259, 15.0120341],
                [108.2520211, 15.0120939],
                [108.2518091, 15.0129984],
                [108.2513109, 15.0139483],
                [108.2511267, 15.0145163],
                [108.2510243, 15.0148162],
                [108.250898, 15.0150881],
                [108.2507872, 15.0160049],
                [108.2508671, 15.0164413],
                [108.2510129, 15.0167333],
                [108.2513312, 15.0170969],
                [108.2518092, 15.0173647],
                [108.2518519, 15.0173844],
                [108.2520442, 15.0175556],
                [108.2525898, 15.0181185],
                [108.2529741, 15.0184807],
                [108.2533597, 15.0187536],
                [108.2538457, 15.0191668],
                [108.2538746, 15.0193161],
                [108.2538734, 15.0194055],
                [108.2538303, 15.0195739],
                [108.2537468, 15.0197019],
                [108.2536219, 15.0198594],
                [108.2533328, 15.0200546],
                [108.2527652, 15.0204153],
                [108.2525168, 15.0206308],
                [108.2524018, 15.0208083],
                [108.252193, 15.0211335],
                [108.2520557, 15.0214596],
                [108.251931, 15.0216071],
                [108.2518092, 15.0217442],
                [108.2511206, 15.0222646],
                [108.2507702, 15.0224175],
                [108.2505815, 15.0224497],
                [108.2504271, 15.0224308],
                [108.2501834, 15.0222896],
                [108.2498553, 15.0219685],
                [108.2495864, 15.0218251],
                [108.2492446, 15.0217334],
                [108.248936, 15.0218447],
                [108.2487988, 15.0220175],
                [108.2487118, 15.02224],
                [108.2486485, 15.0228061],
                [108.2486831, 15.0238718],
                [108.2490108, 15.0244806],
                [108.2493486, 15.0248876],
                [108.2498657, 15.0253843],
                [108.2501317, 15.0257554],
                [108.250183, 15.0259837],
                [108.2501434, 15.026246],
                [108.2499702, 15.0264052],
                [108.2489663, 15.026547],
                [108.2486037, 15.0266652],
                [108.2479486, 15.0270778],
                [108.2474574, 15.0273695],
                [108.2463448, 15.0275789],
                [108.2459501, 15.0280506],
                [108.2459177, 15.028161],
                [108.2458369, 15.0282775],
                [108.2455898, 15.0287993],
                [108.2454001, 15.0293657],
                [108.2452804, 15.0294954],
                [108.2451536, 15.0295661],
                [108.2448555, 15.029627],
                [108.2438816, 15.029584],
                [108.243118, 15.029618],
                [108.2424218, 15.0297815],
                [108.2418494, 15.0298912],
                [108.2413969, 15.0300304],
                [108.2407022, 15.0302202],
                [108.24006, 15.030471],
                [108.2394505, 15.0308656],
                [108.2393216, 15.0309489],
                [108.2386311, 15.0315094],
                [108.2381504, 15.0320138],
                [108.2375936, 15.0319054],
                [108.2364351, 15.0318913],
                [108.2355066, 15.03203],
                [108.2346195, 15.0319442],
                [108.2336522, 15.0320824],
                [108.2328966, 15.0322795],
                [108.2321616, 15.032383],
                [108.2303091, 15.0322854],
                [108.2290389, 15.03197],
                [108.2275055, 15.0310887],
                [108.2259795, 15.029645],
                [108.2255571, 15.0294524],
                [108.2247067, 15.0295169],
                [108.2242801, 15.0296617],
                [108.2237356, 15.0299552],
                [108.2228769, 15.0306572],
                [108.2224527, 15.0306145],
                [108.2210514, 15.0299785],
                [108.2202827, 15.0297066],
                [108.2192304, 15.0304437],
                [108.2168136, 15.0321767],
                [108.2158024, 15.0327268],
                [108.2150306, 15.0326799],
                [108.2131088, 15.0319813],
                [108.2127227, 15.0319765],
                [108.2119466, 15.032267],
                [108.2101989, 15.0330331],
                [108.2096504, 15.0336264],
                [108.2092141, 15.034521],
                [108.2087307, 15.0360526],
                [108.2084043, 15.0373986],
                [108.2081701, 15.0375833],
                [108.2074531, 15.0377807],
                [108.2068676, 15.0382609],
                [108.2059287, 15.039187],
                [108.2054213, 15.0395933],
                [108.2042185, 15.0400284],
                [108.2032786, 15.0410293],
                [108.202804, 15.0418861],
                [108.2022585, 15.0422543],
                [108.2010739, 15.0427763],
                [108.1996681, 15.043958],
                [108.1993572, 15.0441041],
                [108.1979769, 15.0433376],
                [108.1968699, 15.0423496],
                [108.1964994, 15.041146],
                [108.1957326, 15.0407243],
                [108.194539, 15.0404474],
                [108.1943459, 15.040445],
                [108.1942287, 15.0405559],
                [108.1942228, 15.0410056],
                [108.1941758, 15.0416419],
                [108.1941319, 15.0420536],
                [108.19397, 15.0426136],
                [108.1929865, 15.0439879],
                [108.1920427, 15.0452877],
                [108.1918041, 15.0458093],
                [108.1919508, 15.0464106],
                [108.1921364, 15.046975],
                [108.1921652, 15.0477247],
                [108.1918431, 15.0487325],
                [108.1905503, 15.0501051],
                [108.189847, 15.0507337],
                [108.1884063, 15.0516532],
                [108.186733, 15.0526447],
                [108.1856787, 15.0535315],
                [108.1848547, 15.0545335],
                [108.1842604, 15.0556883],
                [108.1837134, 15.056169],
                [108.1828607, 15.0564209],
                [108.1821799, 15.0568061],
                [108.1807416, 15.057538],
                [108.1787598, 15.0584881],
                [108.1776655, 15.0594867],
                [108.1768439, 15.0603014],
                [108.175955, 15.0603653],
                [108.1739389, 15.0609775],
                [108.1708771, 15.0618392],
                [108.1700835, 15.061998],
                [108.1682442, 15.0609252],
                [108.1664431, 15.0598905],
                [108.1653636, 15.0597646],
                [108.1643588, 15.0598269],
                [108.1629561, 15.0607841],
                [108.1625361, 15.0610225],
                [108.1611466, 15.0603866],
                [108.1599577, 15.0597718],
                [108.1591936, 15.0591624],
                [108.1583968, 15.0581027],
                [108.157968, 15.0569538],
                [108.1574885, 15.0552607],
                [108.1569184, 15.0545787],
                [108.1556187, 15.0535876],
                [108.1531659, 15.0522072],
                [108.1516353, 15.0511757],
                [108.1504415, 15.0509358],
                [108.1497846, 15.050965],
                [108.1490983, 15.0503191],
                [108.1489113, 15.0498668],
                [108.148831, 15.0486473],
                [108.1487416, 15.0466591],
                [108.1483996, 15.0462425],
                [108.1477469, 15.0459719],
                [108.146284, 15.0456535],
                [108.1464885, 15.0447938],
                [108.1469661, 15.0437126],
                [108.1482956, 15.0424545],
                [108.1494922, 15.0410262],
                [108.1501659, 15.0397224],
                [108.1516993, 15.0376422],
                [108.1519023, 15.036895],
                [108.1516801, 15.0361799],
                [108.1505791, 15.0347788],
                [108.1498209, 15.0337196],
                [108.1498133, 15.0328384],
                [108.1502559, 15.0314943],
                [108.1507767, 15.0300762],
                [108.151481, 15.0293727],
                [108.1525752, 15.0283741],
                [108.1528147, 15.0277772],
                [108.1527841, 15.027177],
                [108.152369, 15.0264595],
                [108.1513281, 15.0248906],
                [108.150572, 15.0236814],
                [108.1491984, 15.0224644],
                [108.1491633, 15.0222016],
                [108.1492098, 15.0216022],
                [108.1493055, 15.0202163],
                [108.1494852, 15.0183065],
                [108.1498319, 15.016905],
                [108.1511881, 15.0136227],
                [108.1514761, 15.0122766],
                [108.1515232, 15.0116399],
                [108.1516524, 15.0106293],
                [108.1521716, 15.0093236],
                [108.1521601, 15.0087351],
                [108.1503077, 15.0028679],
                [108.1501575, 15.0022765],
                [108.1500413, 15.0018193],
                [108.1518863, 15.0002654],
                [108.1532557, 14.9989741],
                [108.1541846, 14.998544],
                [108.1545617, 14.9983516],
                [108.1552544, 14.9978368],
                [108.1554917, 14.9974946],
                [108.1556688, 14.9970686],
                [108.1557724, 14.9966416],
                [108.1557794, 14.9961181],
                [108.1558114, 14.9955475],
                [108.1558209, 14.9948337],
                [108.1559499, 14.9943356],
                [108.1562002, 14.9939342],
                [108.1565736, 14.9934868],
                [108.1567864, 14.9931444],
                [108.1570149, 14.9925285],
                [108.1572206, 14.9917935],
                [108.1573506, 14.991224],
                [108.1574072, 14.9906536],
                [108.157489, 14.9890961],
                [108.1575751, 14.9881452],
                [108.1576799, 14.9876231],
                [108.1577858, 14.9870296],
                [108.1579647, 14.9864606],
                [108.1580935, 14.9859864],
                [108.158252, 14.985108],
                [108.1583932, 14.9846219],
                [108.1584783, 14.9837425],
                [108.1586686, 14.9823171],
                [108.1590238, 14.9804536],
                [108.1593338, 14.9792439],
                [108.1599093, 14.978323],
                [108.1602362, 14.9776847],
                [108.1608315, 14.977121],
                [108.1613514, 14.9766991],
                [108.1624298, 14.975725],
                [108.1628292, 14.9751589],
                [108.1630553, 14.9747334],
                [108.1630584, 14.9744955],
                [108.1628546, 14.9742698],
                [108.1621893, 14.9735329],
                [108.1616573, 14.9730027],
                [108.1606115, 14.9724424],
                [108.1600507, 14.972245],
                [108.1586858, 14.9717283],
                [108.1579192, 14.9713499],
                [108.1570002, 14.970458],
                [108.156128, 14.9697333],
                [108.1556687, 14.9692754],
                [108.1555753, 14.9689174],
                [108.1556037, 14.9686321],
                [108.1554395, 14.9680828],
                [108.1551041, 14.9675075],
                [108.1546922, 14.9671692],
                [108.1536123, 14.9664062],
                [108.1524754, 14.9653212],
                [108.1514359, 14.964285],
                [108.150349, 14.9631293],
                [108.149597, 14.9625726],
                [108.1492316, 14.9624252],
                [108.1486445, 14.9623703],
                [108.147983, 14.9623858],
                [108.1460337, 14.9625399],
                [108.1446875, 14.9624755],
                [108.1439531, 14.9624424],
                [108.1427293, 14.9623796],
                [108.1414575, 14.9622445],
                [108.1386828, 14.9618409],
                [108.1379005, 14.9617361],
                [108.1369005, 14.9614141],
                [108.1364877, 14.9611471],
                [108.1352301, 14.9599416],
                [108.1346738, 14.9594111],
                [108.1342142, 14.9589771],
                [108.1337766, 14.9587336],
                [108.1332649, 14.9585369],
                [108.1328737, 14.9584843],
                [108.1323353, 14.9584537],
                [108.1314407, 14.9585021],
                [108.1305833, 14.958515],
                [108.1301424, 14.9585095],
                [108.1296521, 14.9585509],
                [108.1293575, 14.9585947],
                [108.1290872, 14.9586627],
                [108.1289142, 14.9587795],
                [108.1287883, 14.9590398],
                [108.1285455, 14.9593734],
                [108.1281924, 14.9596509],
                [108.1276254, 14.9599292],
                [108.1269864, 14.9600878],
                [108.1261783, 14.9600775],
                [108.125244, 14.9600811],
                [108.1252122, 14.9600806],
                [108.125107, 14.9600817],
                [108.1244927, 14.9602135],
                [108.1243141, 14.9602251],
                [108.1236308, 14.9602697],
                [108.1226513, 14.9602573],
                [108.1210867, 14.9600472],
                [108.1194506, 14.9596696],
                [108.1180851, 14.9592241],
                [108.1168076, 14.9586012],
                [108.1163467, 14.9582622],
                [108.1158659, 14.9575899],
                [108.1156966, 14.9574212],
                [108.1151385, 14.9570334],
                [108.1134861, 14.9560607],
                [108.1120544, 14.955067],
                [108.1116671, 14.954729],
                [108.1112047, 14.9545089],
                [108.110691, 14.9544549],
                [108.1099943, 14.9543627],
                [108.1095548, 14.954262],
                [108.1092636, 14.9540679],
                [108.1089029, 14.9535638],
                [108.1084469, 14.9528679],
                [108.1077514, 14.9517645],
                [108.1074614, 14.9514753],
                [108.1071689, 14.9513765],
                [108.105871, 14.95136],
                [108.1048165, 14.9514656],
                [108.1028152, 14.9518566],
                [108.1017591, 14.9520811],
                [108.1010455, 14.9523338],
                [108.100308, 14.9525386],
                [108.0996465, 14.9525539],
                [108.098766, 14.9524714],
                [108.0975689, 14.952242],
                [108.0970045, 14.95233],
                [108.0965615, 14.952491],
                [108.0957493, 14.95279],
                [108.0948795, 14.9528145],
                [108.0944652, 14.9526665],
                [108.0929318, 14.951957],
                [108.0923705, 14.951807],
                [108.0908066, 14.9515491],
                [108.089511, 14.9513661],
                [108.0885316, 14.9513535],
                [108.0881384, 14.9514437],
                [108.08758, 14.9519958],
                [108.0869791, 14.9529637],
                [108.086752, 14.9534605],
                [108.0868184, 14.9539848],
                [108.0869103, 14.954438],
                [108.0868313, 14.9548415],
                [108.0866058, 14.9552194],
                [108.0864773, 14.9556698],
                [108.0864581, 14.9561811],
                [108.086646, 14.9567784],
                [108.0869028, 14.9577097],
                [108.0868442, 14.9584227],
                [108.0867859, 14.959112],
                [108.0869482, 14.9606963],
                [108.0871432, 14.9625787],
                [108.0862278, 14.963257],
                [108.0838904, 14.9649522],
                [108.0834431, 14.9654224],
                [108.0832156, 14.965943],
                [108.0829907, 14.9662732],
                [108.0826943, 14.9664598],
                [108.082277, 14.9665258],
                [108.0813446, 14.9666566],
                [108.0804111, 14.9668588],
                [108.0793284, 14.9672256],
                [108.0778253, 14.9678964],
                [108.0766421, 14.9683103],
                [108.0763378, 14.9683175],
                [108.0755268, 14.9685213],
                [108.0747412, 14.9686539],
                [108.0739827, 14.9685966],
                [108.0731553, 14.9682053],
                [108.0722782, 14.967861],
                [108.0712256, 14.9678236],
                [108.0707117, 14.9677933],
                [108.0703222, 14.9676217],
                [108.0699352, 14.9672598],
                [108.0693752, 14.9670146],
                [108.0689958, 14.9669979],
                [108.0685205, 14.9672816],
                [108.0678625, 14.9674829],
                [108.0669056, 14.9676134],
                [108.0660714, 14.9677216],
                [108.0643749, 14.9681996],
                [108.0633412, 14.9685669],
                [108.0633214, 14.9685958],
                [108.0617794, 14.9708429],
                [108.0611058, 14.9717384],
                [108.0584199, 14.9756178],
                [108.0571205, 14.9775045],
                [108.0555084, 14.9798749],
                [108.0542575, 14.9817861],
                [108.0529175, 14.9839459],
                [108.0518168, 14.9856209],
                [108.0501039, 14.9881804],
                [108.04933, 14.9892411],
                [108.0482528, 14.9909878],
                [108.0476278, 14.9919077],
                [108.047429, 14.9921193],
                [108.0470462, 14.9923403],
                [108.0456193, 14.9927977],
                [108.0444634, 14.9931395],
                [108.0428901, 14.9935474],
                [108.0421525, 14.9937519],
                [108.0397543, 14.9944702],
                [108.0384273, 14.9947862],
                [108.0374411, 14.9952491],
                [108.0366048, 14.9955],
                [108.0361391, 14.9955177],
                [108.0349865, 14.9956216],
                [108.03403, 14.9957043],
                [108.0329243, 14.9959517],
                [108.0316084, 14.9963509],
                [108.030647, 14.9967905],
                [108.0298586, 14.9971133],
                [108.0290721, 14.9973171],
                [108.0283344, 14.9975216],
                [108.0275712, 14.9977972],
                [108.0270553, 14.9979094],
                [108.026541, 14.9979027],
                [108.0255378, 14.9978182],
                [108.0249007, 14.9978337],
                [108.0244596, 14.9978517],
                [108.0240913, 14.9979183],
                [108.0238886, 14.9980714],
                [108.0232099, 14.9989258],
                [108.0221759, 14.999584],
                [108.0205992, 15.000344],
                [108.0183388, 15.0013751],
                [108.0178849, 15.0016153],
                [108.0173424, 15.0019024],
                [108.0169532, 15.0017372],
                [108.0164249, 15.00119],
                [108.0155902, 15.0004584],
                [108.0143627, 14.9998219],
                [108.0127463, 14.9989757],
                [108.0114676, 14.9985618],
                [108.0104446, 14.9981439],
                [108.0093498, 14.9976061],
                [108.0086436, 14.9973112],
                [108.0084473, 14.9973324],
                [108.0083228, 14.9974736],
                [108.0082213, 14.9977339],
                [108.0079433, 14.9983488],
                [108.0076401, 14.9989757],
                [108.0070275, 14.9999044],
                [108.0060523, 15.0006407],
                [108.0043012, 15.0016665],
                [108.0033322, 15.0019534],
                [108.0003816, 15.0016686],
                [108.0002737, 15.0015415],
                [108.0000947, 15.001358],
                [107.999871, 15.0011391],
                [107.999648, 15.0009705],
                [107.9994256, 15.0008446],
                [107.9991366, 15.000691],
                [107.9987601, 15.0005887],
                [107.9985021, 15.000542],
                [107.9982515, 15.0005023],
                [107.99792, 15.0004638],
                [107.9976184, 15.0004606],
                [107.9973243, 15.0004717],
                [107.9970156, 15.0004829],
                [107.9967545, 15.0004792],
                [107.9966148, 15.0004799],
                [107.9962616, 15.0004702],
                [107.9958495, 15.0004542],
                [107.9955184, 15.0004442],
                [107.9952092, 15.0004198],
                [107.9949807, 15.000387],
                [107.9946855, 15.0003052],
                [107.9944787, 15.0002435],
                [107.9942935, 15.0001459],
                [107.9940561, 15.000006],
                [107.9938255, 14.999816],
                [107.993691, 14.99968],
                [107.9935183, 14.9993566],
                [107.9933001, 14.9990165],
                [107.9929527, 14.9987502],
                [107.9925407, 14.9985029],
                [107.9921761, 14.9983362],
                [107.9917006, 14.998207],
                [107.991264, 14.9981947],
                [107.9907728, 14.9982644],
                [107.9903287, 14.9983876],
                [107.9900613, 14.9985174],
                [107.9898322, 14.9987462],
                [107.9898112, 14.9987759],
                [107.9894865, 14.9996548],
                [107.988547, 15.0003679],
                [107.9882966, 15.0005398],
                [107.9880482, 15.0006717],
                [107.9877701, 15.0007755],
                [107.9875211, 15.0008647],
                [107.9871768, 15.0009836],
                [107.986965, 15.0011009],
                [107.9867758, 15.0012535],
                [107.9866538, 15.0014697],
                [107.9865622, 15.0017655],
                [107.986504, 15.0020741],
                [107.9864275, 15.0024043],
                [107.9863139, 15.0026993],
                [107.9861847, 15.0029299],
                [107.9860873, 15.0030744],
                [107.9858987, 15.0032701],
                [107.9857029, 15.0034802],
                [107.9854556, 15.003691],
                [107.9852087, 15.0039233],
                [107.9849991, 15.0042052],
                [107.9848224, 15.004458],
                [107.9845618, 15.0047692],
                [107.9843229, 15.0050515],
                [107.9840619, 15.0053411],
                [107.9838298, 15.0055804],
                [107.9836305, 15.0058049],
                [107.9834273, 15.0060079],
                [107.9832756, 15.0062104],
                [107.9831682, 15.0064265],
                [107.9830547, 15.0067357],
                [107.9829863, 15.0071088],
                [107.9829906, 15.0074165],
                [107.983047, 15.0077737],
                [107.9831036, 15.008138],
                [107.9831461, 15.0085525],
                [107.9831574, 15.0088315],
                [107.9831403, 15.0091895],
                [107.9830035, 15.0094061],
                [107.9827555, 15.0095668],
                [107.9824037, 15.0096716],
                [107.9820219, 15.0097339],
                [107.9815516, 15.009783],
                [107.9813024, 15.0098578],
                [107.9810385, 15.0099329],
                [107.9807004, 15.0099659],
                [107.9805682, 15.009982],
                [107.9803176, 15.0099567],
                [107.9800662, 15.009874],
                [107.9797558, 15.0097779],
                [107.9794758, 15.0097529],
                [107.9792129, 15.0097459],
                [107.9790471, 15.0097601],
                [107.9788551, 15.0098043],
                [107.978621, 15.0099011],
                [107.9783341, 15.010061],
                [107.9781335, 15.0102614],
                [107.977817, 15.0105988],
                [107.9775633, 15.0108311],
                [107.9772984, 15.0110219],
                [107.977043, 15.0111294],
                [107.9767866, 15.011164],
                [107.9764928, 15.0112095],
                [107.9761194, 15.0113081],
                [107.9757569, 15.0114066],
                [107.9753514, 15.0115055],
                [107.9750105, 15.0116245],
                [107.9747442, 15.0117113],
                [107.9744789, 15.0118813],
                [107.974256, 15.0120197],
                [107.9739896, 15.0121064],
                [107.9737971, 15.0121193],
                [107.9735935, 15.0121116],
                [107.9733473, 15.0121044],
                [107.9730367, 15.012098],
                [107.9727791, 15.0120494],
                [107.9725217, 15.012011],
                [107.9722323, 15.0119836],
                [107.9719439, 15.012029],
                [107.971753, 15.0121565],
                [107.9716904, 15.0122822],
                [107.9717681, 15.012479],
                [107.9720074, 15.012757],
                [107.9722144, 15.0130145],
                [107.9723356, 15.0132628],
                [107.9718844, 15.0139246],
                [107.971553, 15.0139602],
                [107.9712324, 15.0139955],
                [107.970891, 15.0140834],
                [107.9706361, 15.014222],
                [107.9703394, 15.0144445],
                [107.9701288, 15.0146971],
                [107.970047, 15.0149793],
                [107.9699986, 15.0153444],
                [107.9699289, 15.0157305],
                [107.9698361, 15.0159815],
                [107.9696277, 15.0164007],
                [107.9694485, 15.0166008],
                [107.9692054, 15.0168226],
                [107.9688873, 15.0170454],
                [107.9686109, 15.0171843],
                [107.9683141, 15.0173965],
                [107.9680712, 15.0176287],
                [107.967544, 15.0182186],
                [107.9673165, 15.0184089],
                [107.9670193, 15.0186003],
                [107.9668184, 15.0187798],
                [107.9666818, 15.0189691],
                [107.9657098, 15.0202831],
                [107.9654334, 15.0204221],
                [107.9651689, 15.0206545],
                [107.9650115, 15.0208857],
                [107.9649084, 15.0211682],
                [107.9648737, 15.0213767],
                [107.9648252, 15.0217314],
                [107.9647764, 15.0220756],
                [107.9647167, 15.022399],
                [107.9646144, 15.022744],
                [107.9645326, 15.0230262],
                [107.9643988, 15.0234131],
                [107.9642958, 15.023706],
                [107.9641879, 15.0240405],
                [107.9640644, 15.0243962],
                [107.9638887, 15.0248461],
                [107.9637547, 15.0252226],
                [107.9636203, 15.0255679],
                [107.9635074, 15.0259234],
                [107.9634048, 15.0262475],
                [107.9632396, 15.026687],
                [107.9631275, 15.0270945],
                [107.9630248, 15.0274081],
                [107.9628903, 15.0277533],
                [107.9626914, 15.0280787],
                [107.9625016, 15.0282894],
                [107.9622688, 15.0284799],
                [107.9620146, 15.028681],
                [107.9617927, 15.0288921],
                [107.961645, 15.0290606],
                [107.9614145, 15.0294176],
                [107.9612846, 15.0297004],
                [107.9611391, 15.0300146],
                [107.9610051, 15.0303912],
                [107.9608495, 15.0307577],
                [107.9606787, 15.0311763],
                [107.9604912, 15.0315534],
                [107.9602561, 15.031973],
                [107.9600577, 15.03234],
                [107.9598078, 15.0328534],
                [107.9588199, 15.0366181],
                [107.9586366, 15.0381952],
                [107.9587156, 15.0384766],
                [107.9587863, 15.0387601],
                [107.9589021, 15.0393324],
                [107.9589846, 15.0399184],
                [107.9590125, 15.040213],
                [107.9590416, 15.0408037],
                [107.9591489, 15.0424408],
                [107.9596746, 15.0431557],
                [107.9587412, 15.0442281],
                [107.9587012, 15.0444352],
                [107.9586699, 15.0445362],
                [107.9585856, 15.0447306],
                [107.958471, 15.0449152],
                [107.9584037, 15.0450011],
                [107.9582506, 15.0451576],
                [107.9580758, 15.0452911],
                [107.9578829, 15.0453989],
                [107.9562521, 15.0461811],
                [107.9561479, 15.0462452],
                [107.9559533, 15.0463919],
                [107.9557796, 15.0465615],
                [107.9556298, 15.046751],
                [107.9555613, 15.0468579],
                [107.9554474, 15.0470838],
                [107.9553661, 15.0473225],
                [107.9553187, 15.0475695],
                [107.9551144, 15.0477225],
                [107.9550211, 15.047809],
                [107.9548547, 15.0480001],
                [107.9547824, 15.0481037],
                [107.9546618, 15.0483233],
                [107.954614, 15.0484381],
                [107.9545439, 15.0486762],
                [107.9545089, 15.0489213],
                [107.9545097, 15.0491688],
                [107.954626, 15.0499489],
                [107.9546539, 15.0502179],
                [107.9546858, 15.0507577],
                [107.9546857, 15.0512984],
                [107.9546535, 15.0518382],
                [107.9540853, 15.052309],
                [107.9535056, 15.052758],
                [107.9529154, 15.0531851],
                [107.9515421, 15.0542004],
                [107.953248, 15.0552883],
                [107.9534569, 15.0555837],
                [107.9538576, 15.0561854],
                [107.9542351, 15.0568009],
                [107.9544279, 15.0571368],
                [107.9547926, 15.0578193],
                [107.9549644, 15.0581656],
                [107.9552865, 15.0588678],
                [107.9563594, 15.058987],
                [107.9566526, 15.0590478],
                [107.9569365, 15.0591415],
                [107.957207, 15.0592667],
                [107.9573375, 15.0593416],
                [107.9575837, 15.0595131],
                [107.9578069, 15.0597117],
                [107.957909, 15.0598203],
                [107.9580921, 15.0600541],
                [107.959857, 15.0626545],
                [107.9632473, 15.0644572],
                [107.964127, 15.0656175],
                [107.9683327, 15.0656383],
                [107.9700494, 15.0669333],
                [107.9716909, 15.0671508],
                [107.9719418, 15.0672174],
                [107.9721901, 15.0672927],
                [107.9724354, 15.0673766],
                [107.9726773, 15.0674691],
                [107.9729156, 15.06757],
                [107.9733545, 15.0677829],
                [107.9737526, 15.0680093],
                [107.9739458, 15.0681316],
                [107.9743194, 15.068394],
                [107.9749364, 15.0690415],
                [107.9751061, 15.0694241],
                [107.9751798, 15.0696197],
                [107.9753043, 15.0700182],
                [107.9753977, 15.0704245],
                [107.9754607, 15.0708472],
                [107.9754902, 15.0712733],
                [107.9754923, 15.0714868],
                [107.975471, 15.0719133],
                [107.9754162, 15.0723371],
                [107.975328, 15.0727555],
                [107.9753014, 15.0729204],
                [107.9752846, 15.0730865],
                [107.9752778, 15.0732532],
                [107.9752809, 15.0734201],
                [107.9752939, 15.0735865],
                [107.9753168, 15.0737519],
                [107.9753494, 15.0739158],
                [107.9754996, 15.0748482],
                [107.9756098, 15.0751558],
                [107.9756764, 15.0753054],
                [107.9758322, 15.075594],
                [107.9759264, 15.0757406],
                [107.9761378, 15.0760184],
                [107.9762545, 15.076149],
                [107.9765081, 15.0763917],
                [107.9766239, 15.0765986],
                [107.9767138, 15.0768171],
                [107.9767764, 15.0770444],
                [107.9768002, 15.0771816],
                [107.976814, 15.07732],
                [107.9768179, 15.0774591],
                [107.9768117, 15.0775981],
                [107.9767955, 15.0777363],
                [107.9767694, 15.0778731],
                [107.9766981, 15.0781396],
                [107.9766552, 15.0782692],
                [107.9765509, 15.0785123],
                [107.9764908, 15.0786255],
                [107.9763526, 15.0788417],
                [107.9763659, 15.0789643],
                [107.976374, 15.0792107],
                [107.9763534, 15.0794864],
                [107.9763286, 15.0796377],
                [107.9762943, 15.0797873],
                [107.9762506, 15.0799347],
                [107.9761658, 15.0801608],
                [107.9759779, 15.0806064],
                [107.9757439, 15.0810853],
                [107.9756044, 15.0813409],
                [107.9754567, 15.0815922],
                [107.9749203, 15.0820894],
                [107.9744267, 15.0831771],
                [107.9734397, 15.0848139],
                [107.9781818, 15.0867717],
                [107.9785805, 15.0869023],
                [107.9789865, 15.08701],
                [107.9793973, 15.0870942],
                [107.9799957, 15.0871822],
                [107.9801774, 15.0872166],
                [107.9805358, 15.0873065],
                [107.9808855, 15.0874244],
                [107.9810792, 15.0875034],
                [107.981269, 15.0875909],
                [107.9814543, 15.0876868],
                [107.9816349, 15.0877909],
                [107.9818103, 15.0879029],
                [107.9819801, 15.0880226],
                [107.982144, 15.0881498],
                [107.9824587, 15.0884174],
                [107.9826089, 15.0885576],
                [107.9827522, 15.0887045],
                [107.9828882, 15.0888578],
                [107.9830166, 15.089017],
                [107.983137, 15.0891819],
                [107.9832493, 15.0893521],
                [107.9833531, 15.0895272],
                [107.9834438, 15.0896979],
                [107.9836011, 15.0900501],
                [107.9837251, 15.0904145],
                [107.9837743, 15.0906004],
                [107.9838466, 15.0909775],
                [107.9840019, 15.0914127],
                [107.9841903, 15.0918355],
                [107.9842966, 15.0920416],
                [107.9845326, 15.0924416],
                [107.9847949, 15.0928183],
                [107.9850809, 15.0931707],
                [107.9853926, 15.0935022],
                [107.9857283, 15.093811],
                [107.9860862, 15.0940956],
                [107.9864645, 15.0943544],
                [107.9873764, 15.0943959],
                [107.9888409, 15.0957425],
                [107.9897925, 15.0988827],
                [107.989884, 15.0992021],
                [107.990045, 15.0998465],
                [107.9901767, 15.100497],
                [107.9902766, 15.1011361],
                [107.9903481, 15.1017787],
                [107.9903912, 15.1024237],
                [107.9910082, 15.1030037],
                [107.9910878, 15.1031748],
                [107.9911383, 15.1033558],
                [107.9911522, 15.1034487],
                [107.9911568, 15.1036352],
                [107.9911311, 15.1038191],
                [107.9910757, 15.1039968],
                [107.9909921, 15.1041639],
                [107.9905629, 15.1048268],
                [107.9920113, 15.1053292],
                [107.9926724, 15.1070077],
                [107.9927452, 15.1072236],
                [107.9928089, 15.1074422],
                [107.9928635, 15.1076631],
                [107.9929088, 15.1078859],
                [107.9929447, 15.1081103],
                [107.992971, 15.1083336],
                [107.9929881, 15.1085577],
                [107.9929944, 15.1090069],
                [107.9929635, 15.1094553],
                [107.9929342, 15.1096782],
                [107.9928481, 15.1101197],
                [107.9930717, 15.110356],
                [107.9935348, 15.1108137],
                [107.9937799, 15.1110402],
                [107.9942858, 15.1114766],
                [107.9952407, 15.113051],
                [107.99582, 15.1146047],
                [107.9966569, 15.1154333],
                [107.9967404, 15.1155649],
                [107.9969242, 15.1158176],
                [107.9971289, 15.1160548],
                [107.9972572, 15.1161849],
                [107.9975324, 15.1164266],
                [107.9978305, 15.1166415],
                [107.9983516, 15.1169642],
                [107.9990941, 15.1173942],
                [107.9998541, 15.1177948],
                [108.0005982, 15.1181507],
                [108.0013561, 15.1184784],
                [108.0018067, 15.1187373],
                [108.0019195, 15.1188343],
                [108.0019672, 15.1188908],
                [108.0020428, 15.119017],
                [108.0020687, 15.119082],
                [108.0020994, 15.119218],
                [108.0021011, 15.1193571],
                [108.0019998, 15.1199595],
                [108.0019923, 15.1200481],
                [108.0019942, 15.1201369],
                [108.0020058, 15.1202251],
                [108.0020267, 15.1203117],
                [108.0020832, 15.1204525],
                [108.0021645, 15.1205816],
                [108.0023576, 15.120794],
                [108.002442, 15.1208971],
                [108.0025953, 15.1211144],
                [108.0027743, 15.1214358],
                [108.0028421, 15.1216063],
                [108.0028868, 15.1217984],
                [108.0029011, 15.1219947],
                [108.0015475, 15.122082],
                [108.0014176, 15.1220963],
                [108.0011615, 15.1221464],
                [108.0010361, 15.1221822],
                [108.0007929, 15.1222744],
                [108.000558, 15.1223957],
                [108.0003396, 15.1225428],
                [108.0002375, 15.1226255],
                [108.0000494, 15.1228075],
                [107.9999641, 15.1229062],
                [107.9990923, 15.1242992],
                [107.9983199, 15.1256042],
                [107.9982585, 15.1257612],
                [107.99816, 15.1260827],
                [107.9980945, 15.1264121],
                [107.9980741, 15.1265825],
                [107.9980599, 15.1269252],
                [107.9980661, 15.1270966],
                [107.9981053, 15.1274375],
                [107.9981457, 15.1276643],
                [107.9981955, 15.1278895],
                [107.9982546, 15.1281125],
                [107.9983229, 15.1283331],
                [107.9984003, 15.1285508],
                [107.9985238, 15.1286635],
                [107.998787, 15.1288709],
                [107.998926, 15.1289651],
                [107.9992113, 15.1291304],
                [107.9995115, 15.1292688],
                [108.0004978, 15.129659],
                [108.0006259, 15.1299469],
                [108.0007207, 15.1302465],
                [108.0007805, 15.1305495],
                [108.000806, 15.1308521],
                [108.0007982, 15.1311556],
                [108.0004012, 15.1317667],
                [108.0004285, 15.1319257],
                [108.0004611, 15.1322465],
                [108.0004663, 15.1324077],
                [108.0004549, 15.1327298],
                [108.0004375, 15.1328956],
                [108.0003794, 15.1332239],
                [108.0003388, 15.1333858],
                [108.000235, 15.1337034],
                [108.0021071, 15.136334],
                [108.0022788, 15.1379082],
                [108.0023067, 15.1380606],
                [108.0023425, 15.1382114],
                [108.0024377, 15.1385069],
                [108.0025635, 15.1387915],
                [108.0027185, 15.1390624],
                [108.0029011, 15.1393167],
                [108.0030121, 15.139448],
                [108.0031305, 15.1395732],
                [108.003256, 15.1396918],
                [108.0033881, 15.1398035],
                [108.0035264, 15.1399079],
                [108.0036705, 15.1400048],
                [108.00382, 15.1400938],
                [108.0039743, 15.1401746],
                [108.0041331, 15.1402471],
                [108.0042958, 15.1403109],
                [108.0043225, 15.1406453],
                [108.0043495, 15.1413155],
                [108.004342, 15.1419685],
                [108.0043012, 15.1426204],
                [108.0048591, 15.1433039],
                [108.0048101, 15.1433762],
                [108.0046982, 15.143511],
                [108.0046279, 15.14358],
                [108.0044728, 15.1437026],
                [108.0014795, 15.1452923],
                [108.0011201, 15.1476949],
                [108.0010908, 15.1478575],
                [108.001013, 15.1481787],
                [108.0009011, 15.1485163],
                [108.00075, 15.1488661],
                [108.0006626, 15.1490359],
                [108.0000096, 15.1501907],
                [107.9997307, 15.1534217],
                [107.9995954, 15.1540536],
                [107.9995396, 15.1543717],
                [107.9994518, 15.1550113],
                [107.9994177, 15.1553574],
                [107.9993929, 15.1557041],
                [107.9993552, 15.1567304],
                [107.9992835, 15.1567882],
                [107.9992063, 15.156839],
                [107.9990384, 15.1569177],
                [107.998858, 15.1569637],
                [107.9986782, 15.1569752],
                [107.9985057, 15.1569555],
                [107.9984216, 15.1569345],
                [107.9982609, 15.1568708],
                [107.9981857, 15.1568287],
                [107.997816, 15.1567845],
                [107.9974435, 15.1567757],
                [107.9972573, 15.1567845],
                [107.9968876, 15.1568287],
                [107.9965607, 15.1568983],
                [107.9962415, 15.1569954],
                [107.9959324, 15.1571194],
                [107.9956552, 15.1572508],
                [107.9953914, 15.1573495],
                [107.9952557, 15.1573882],
                [107.9949788, 15.1574437],
                [107.9946834, 15.1574702],
                [107.9945281, 15.1574709],
                [107.994373, 15.1574626],
                [107.9942188, 15.1574453],
                [107.9940659, 15.157419],
                [107.9929072, 15.1570048],
                [107.9919094, 15.1569219],
                [107.9917391, 15.1569279],
                [107.9915695, 15.1569434],
                [107.9914011, 15.1569683],
                [107.9912344, 15.1570025],
                [107.9910701, 15.157046],
                [107.9909087, 15.1570986],
                [107.9907507, 15.1571601],
                [107.9904765, 15.1572927],
                [107.9903451, 15.1573691],
                [107.9900956, 15.1575413],
                [107.9899782, 15.1576365],
                [107.9893988, 15.1584442],
                [107.9883903, 15.1582578],
                [107.9870707, 15.1591277],
                [107.9863922, 15.1593076],
                [107.9862188, 15.1593802],
                [107.986062, 15.1594819],
                [107.985927, 15.1596095],
                [107.9858158, 15.1597627],
                [107.9857708, 15.1598483],
                [107.9857345, 15.1599377],
                [107.9857073, 15.16003],
                [107.9856893, 15.1601244],
                [107.9855955, 15.1609658],
                [107.9842167, 15.1623285],
                [107.9836552, 15.1629065],
                [107.9833837, 15.1632036],
                [107.9828596, 15.1638135],
                [107.9823446, 15.1644659],
                [107.982376, 15.1647727],
                [107.9823793, 15.1649269],
                [107.9823609, 15.1652347],
                [107.9823393, 15.1653875],
                [107.9822795, 15.1656599],
                [107.9822397, 15.1657938],
                [107.9821408, 15.1660554],
                [107.9810572, 15.1669666],
                [107.9808437, 15.1669965],
                [107.9807358, 15.1669988],
                [107.9805299, 15.1669794],
                [107.9803382, 15.1669322],
                [107.9802457, 15.1668983],
                [107.9801559, 15.1668579],
                [107.9800555, 15.1667991],
                [107.9799504, 15.1667482],
                [107.9798415, 15.1667056],
                [107.9797295, 15.1666715],
                [107.979614, 15.166646],
                [107.9794967, 15.1666295],
                [107.9793785, 15.1666223],
                [107.97926, 15.1666242],
                [107.9791421, 15.1666353],
                [107.9786169, 15.1667312],
                [107.978357, 15.1667914],
                [107.9778439, 15.1669356],
                [107.9776041, 15.167015],
                [107.9771328, 15.1671948],
                [107.9769016, 15.167295],
                [107.9764491, 15.1675155],
                [107.9760686, 15.1676577],
                [107.975678, 15.1677718],
                [107.9754797, 15.1678181],
                [107.9751247, 15.1678823],
                [107.9746571, 15.1679396],
                [107.9743442, 15.1679668],
                [107.9740305, 15.1679846],
                [107.9737164, 15.1679929],
                [107.9734021, 15.1679918],
                [107.9731022, 15.167982],
                [107.9725038, 15.1679365],
                [107.9722059, 15.1679009],
                [107.9716141, 15.1678043],
                [107.9711686, 15.1677406],
                [107.9709843, 15.1678164],
                [107.9706251, 15.1679878],
                [107.9704508, 15.1680831],
                [107.9701137, 15.1682921],
                [107.9697995, 15.1685204],
                [107.9695034, 15.1687704],
                [107.9692645, 15.1689924],
                [107.9691598, 15.1690747],
                [107.9690492, 15.1691493],
                [107.9689332, 15.1692159],
                [107.9688124, 15.1692742],
                [107.9686876, 15.1693238],
                [107.9685594, 15.1693645],
                [107.9684284, 15.1693961],
                [107.9682954, 15.1694183],
                [107.9680393, 15.1694346],
                [107.9677963, 15.1694182],
                [107.9675577, 15.1693711],
                [107.9673276, 15.1692941],
                [107.967217, 15.1692448],
                [107.9664338, 15.1687477],
                [107.9656184, 15.1687167],
                [107.9654173, 15.1690619],
                [107.9651881, 15.1693904],
                [107.9649324, 15.1697001],
                [107.9646517, 15.1699889],
                [107.964347, 15.1702558],
                [107.96402, 15.1704988],
                [107.9638491, 15.1706105],
                [107.9634941, 15.1708135],
                [107.9634404, 15.1713209],
                [107.9623139, 15.1723616],
                [107.9609406, 15.1725894],
                [107.9602003, 15.1731796],
                [107.9597592, 15.1730596],
                [107.9593098, 15.1729725],
                [107.9588927, 15.1729221],
                [107.958473, 15.1729001],
                [107.9582477, 15.1733401],
                [107.9569709, 15.1738838],
                [107.9562736, 15.1739148],
                [107.9554796, 15.1743394],
                [107.9539883, 15.1743704],
                [107.9537559, 15.174389],
                [107.9535244, 15.174417],
                [107.9532944, 15.1744543],
                [107.9530663, 15.174501],
                [107.9528403, 15.1745568],
                [107.9524222, 15.1746867],
                [107.9520161, 15.1748481],
                [107.9518183, 15.1749403],
                [107.9514348, 15.175147],
                [107.9502976, 15.1751781],
                [107.9504156, 15.1768763],
                [107.9497504, 15.1782845],
                [107.9495953, 15.1786825],
                [107.9495304, 15.1788857],
                [107.9494265, 15.1792989],
                [107.9493877, 15.1795082],
                [107.9493367, 15.1799305],
                [107.9493212, 15.1803554],
                [107.9493267, 15.1805623],
                [107.9493629, 15.1809745],
                [107.9493937, 15.1811793],
                [107.9494802, 15.1815845],
                [107.9495358, 15.1817843],
                [107.9495978, 15.1819232],
                [107.9496957, 15.1822103],
                [107.9497578, 15.1825065],
                [107.9497752, 15.1826567],
                [107.9497822, 15.1829588],
                [107.9497719, 15.1831097],
                [107.9497514, 15.1832649],
                [107.9497212, 15.1834187],
                [107.9496814, 15.1835703],
                [107.9496319, 15.1837194],
                [107.9495732, 15.1838652],
                [107.9495053, 15.1840073],
                [107.9494285, 15.1841451],
                [107.9487795, 15.1847974],
                [107.9485488, 15.1856982],
                [107.9483081, 15.1858731],
                [107.9481962, 15.1859706],
                [107.9479913, 15.1861837],
                [107.947899, 15.1862986],
                [107.9477363, 15.1865433],
                [107.9476046, 15.1868048],
                [107.9474762, 15.1871424],
                [107.947429, 15.1872393],
                [107.9473159, 15.1874236],
                [107.9471789, 15.1875935],
                [107.9470197, 15.1877459],
                [107.9469328, 15.1878145],
                [107.9467463, 15.1879347],
                [107.9455393, 15.1882557],
                [107.945441, 15.1881197],
                [107.9453178, 15.1880041],
                [107.9451743, 15.1879128],
                [107.9450965, 15.1878774],
                [107.9449323, 15.1878286],
                [107.9447681, 15.1878106],
                [107.9446098, 15.1878207],
                [107.9444557, 15.1878571],
                [107.9438388, 15.188323],
                [107.9428196, 15.1885974],
                [107.9427597, 15.1887434],
                [107.9426144, 15.1890243],
                [107.9425296, 15.1891583],
                [107.9423368, 15.1894112],
                [107.9422295, 15.1895293],
                [107.9419979, 15.1897441],
                [107.941873, 15.1898418],
                [107.9416072, 15.1900159],
                [107.9406975, 15.190193],
                [107.9402403, 15.1902696],
                [107.939322, 15.190399],
                [107.9383587, 15.1904999],
                [107.9378757, 15.1905372],
                [107.936908, 15.1905854],
                [107.9367041, 15.190866],
                [107.9362769, 15.1914135],
                [107.9360538, 15.1916801],
                [107.935259, 15.1925675],
                [107.9349864, 15.1928886],
                [107.9347206, 15.193215],
                [107.9342247, 15.1938632],
                [107.9337696, 15.1945095],
                [107.9333407, 15.1951721],
                [107.9333309, 15.1965367],
                [107.9333406, 15.1967685],
                [107.9333837, 15.1972305],
                [107.9334581, 15.1976887],
                [107.9335672, 15.1981548],
                [107.9337134, 15.1986258],
                [107.9338932, 15.1990858],
                [107.9339286, 15.1991855],
                [107.9339549, 15.1992877],
                [107.9339721, 15.1993918],
                [107.9339799, 15.1994969],
                [107.9339782, 15.1996022],
                [107.9339672, 15.199707],
                [107.9339468, 15.1998106],
                [107.933916, 15.1999158],
                [107.9338756, 15.200018],
                [107.9338258, 15.2001163],
                [107.9337671, 15.2002099],
                [107.9337, 15.200298],
                [107.933625, 15.20038],
                [107.9316964, 15.2020938],
                [107.9315285, 15.2022881],
                [107.9313901, 15.2025029],
                [107.9313327, 15.2026167],
                [107.9312432, 15.2028545],
                [107.9312139, 15.202966],
                [107.9311775, 15.2031932],
                [107.9311704, 15.203308],
                [107.9311788, 15.2035378],
                [107.9312154, 15.2036568],
                [107.9312425, 15.2037782],
                [107.9312599, 15.2039012],
                [107.9312677, 15.2040251],
                [107.9312657, 15.2041492],
                [107.9312539, 15.2042729],
                [107.9312334, 15.2043906],
                [107.9312042, 15.2045066],
                [107.9311197, 15.2047308],
                [107.931065, 15.2048379],
                [107.930932, 15.205039],
                [107.9309642, 15.2071511],
                [107.9296768, 15.208321],
                [107.9275946, 15.2096876],
                [107.9212227, 15.210117],
                [107.9209889, 15.2101465],
                [107.9207611, 15.2102052],
                [107.9205336, 15.2102968],
                [107.9203212, 15.2104175],
                [107.9202065, 15.2105015],
                [107.919962, 15.2106482],
                [107.9198332, 15.2107103],
                [107.9195648, 15.2108109],
                [107.9194324, 15.2108475],
                [107.9191619, 15.2108977],
                [107.9190249, 15.2109111],
                [107.9187494, 15.2109144],
                [107.9174647, 15.211357],
                [107.9169895, 15.2114532],
                [107.9165097, 15.2115745],
                [107.9160268, 15.2117219],
                [107.9155522, 15.2118928],
                [107.9143774, 15.2118617],
                [107.9141386, 15.2115364],
                [107.9140281, 15.211368],
                [107.9138255, 15.2110205],
                [107.9137337, 15.2108419],
                [107.9135641, 15.2104626],
                [107.91349, 15.2102687],
                [107.9133636, 15.2098739],
                [107.9133218, 15.2096878],
                [107.9132486, 15.2095109],
                [107.9132008, 15.2094274],
                [107.9130846, 15.2092735],
                [107.9129487, 15.2091443],
                [107.9127931, 15.2090378],
                [107.9124979, 15.2088965],
                [107.9123981, 15.2088373],
                [107.9122126, 15.2086996],
                [107.9120489, 15.2085382],
                [107.91191, 15.2083564],
                [107.9118508, 15.2082589],
                [107.9117585, 15.2080641],
                [107.9116938, 15.2078593],
                [107.9116721, 15.2077542],
                [107.9116443, 15.2075465],
                [107.9115945, 15.2073478],
                [107.9115149, 15.2071583],
                [107.9114646, 15.2070682],
                [107.9114016, 15.206974],
                [107.911331, 15.206885],
                [107.9112532, 15.2068018],
                [107.9111687, 15.2067249],
                [107.911078, 15.2066548],
                [107.9109818, 15.206592],
                [107.9107253, 15.2064558],
                [107.9104543, 15.2063491],
                [107.9101724, 15.2062732],
                [107.9098833, 15.2062291],
                [107.9097372, 15.2062193],
                [107.9094541, 15.2062233],
                [107.9091732, 15.2062578],
                [107.9088981, 15.2063223],
                [107.9087638, 15.2063656],
                [107.908402, 15.2065079],
                [107.9081619, 15.2065719],
                [107.9079155, 15.206607],
                [107.9077911, 15.2066135],
                [107.9075434, 15.2066046],
                [107.9072998, 15.2065668],
                [107.9071802, 15.2065373],
                [107.9069477, 15.2064574],
                [107.9067418, 15.2063085],
                [107.9066311, 15.2062449],
                [107.9063974, 15.206141],
                [107.9061508, 15.2060699],
                [107.9060242, 15.2060471],
                [107.9057793, 15.2060276],
                [107.9055454, 15.2060388],
                [107.9053149, 15.2060787],
                [107.9048618, 15.2062185],
                [107.9046294, 15.2062821],
                [107.9041582, 15.2063849],
                [107.9037094, 15.2064514],
                [107.903257, 15.2064885],
                [107.899797, 15.2034653],
                [107.8947866, 15.1984232],
                [107.892673, 15.1966941],
                [107.8918487, 15.1948557],
                [107.891742, 15.1946801],
                [107.8916055, 15.194525],
                [107.8914514, 15.1944006],
                [107.8912783, 15.1943024],
                [107.8909564, 15.1941995],
                [107.8907993, 15.1941378],
                [107.8904936, 15.1939941],
                [107.8902015, 15.193824],
                [107.8899264, 15.1936294],
                [107.8879094, 15.1948098],
                [107.8873787, 15.1950845],
                [107.8868441, 15.1953521],
                [107.8857636, 15.1958658],
                [107.884782, 15.1963021],
                [107.8837895, 15.1967148],
                [107.8811073, 15.1968184],
                [107.8801632, 15.199883],
                [107.8797354, 15.2009129],
                [107.8792834, 15.201933],
                [107.8789955, 15.2025513],
                [107.8786986, 15.2031656],
                [107.8783929, 15.2037759],
                [107.8782193, 15.2039701],
                [107.8778544, 15.204343],
                [107.8774948, 15.2046702],
                [107.877146, 15.204955],
                [107.876966, 15.2050908],
                [107.8761399, 15.2054946],
                [107.8740317, 15.2102415],
                [107.8737033, 15.2101966],
                [107.8735378, 15.2101857],
                [107.8733719, 15.2101826],
                [107.8730408, 15.2101996],
                [107.8727128, 15.2102475],
                [107.8725511, 15.2102829],
                [107.8720854, 15.2104058],
                [107.8718498, 15.2104568],
                [107.8713743, 15.2105378],
                [107.8708773, 15.2105916],
                [107.8706189, 15.2106074],
                [107.8701014, 15.2106144],
                [107.8698428, 15.2106054],
                [107.8695846, 15.2105883],
                [107.8682166, 15.2100085],
                [107.8681163, 15.2099722],
                [107.867909, 15.2099201],
                [107.867803, 15.2099045],
                [107.867589, 15.2098946],
                [107.8673913, 15.209911],
                [107.8671974, 15.2099516],
                [107.8664517, 15.2103916],
                [107.8627396, 15.2105779],
                [107.8623657, 15.2105798],
                [107.862179, 15.2105696],
                [107.8618077, 15.2105273],
                [107.8616238, 15.2104951],
                [107.8612699, 15.2104117],
                [107.860924, 15.2103014],
                [107.8607547, 15.2102363],
                [107.8604978, 15.2100847],
                [107.860236, 15.2099411],
                [107.8599696, 15.2098056],
                [107.8596989, 15.2096784],
                [107.859424, 15.2095597],
                [107.8591454, 15.2094495],
                [107.8588595, 15.2093466],
                [107.8585703, 15.2092527],
                [107.8582781, 15.209168],
                [107.8579832, 15.2090924],
                [107.8576859, 15.2090261],
                [107.8573866, 15.2089691],
                [107.8570855, 15.2089214],
                [107.8566626, 15.2092747],
                [107.8564431, 15.209442],
                [107.8559892, 15.2097573],
                [107.8554967, 15.2100571],
                [107.8552334, 15.2102011],
                [107.8549654, 15.2103368],
                [107.8546929, 15.210464],
                [107.8540752, 15.2103964],
                [107.8534564, 15.2103377],
                [107.8528368, 15.210288],
                [107.8516285, 15.2102173],
                [107.8510237, 15.2101949],
                [107.8509265, 15.2100971],
                [107.8508225, 15.2100061],
                [107.8507122, 15.2099224],
                [107.8505961, 15.2098462],
                [107.8504748, 15.209778],
                [107.8503489, 15.2097181],
                [107.8500942, 15.2096269],
                [107.8494573, 15.2094702],
                [107.8489252, 15.2093693],
                [107.8483882, 15.2092967],
                [107.8481184, 15.2092712],
                [107.8477083, 15.2092465],
                [107.8474317, 15.2092088],
                [107.8471621, 15.2091381],
                [107.8469038, 15.2090353],
                [107.8466566, 15.2088996],
                [107.8465403, 15.2088206],
                [107.8463244, 15.2086419],
                [107.8453763, 15.2074345],
                [107.8452126, 15.2074328],
                [107.8450491, 15.2074401],
                [107.8448863, 15.2074565],
                [107.8447247, 15.2074818],
                [107.8444337, 15.2075511],
                [107.8443044, 15.2075922],
                [107.8440526, 15.207692],
                [107.8436388, 15.2076983],
                [107.843227, 15.2077383],
                [107.8428201, 15.2078117],
                [107.8426111, 15.2078631],
                [107.8424045, 15.2079233],
                [107.8422009, 15.2079923],
                [107.8420007, 15.2080699],
                [107.8403699, 15.2089422],
                [107.8387016, 15.2080259],
                [107.8380766, 15.2072002],
                [107.8379673, 15.2072073],
                [107.837751, 15.2072404],
                [107.8376448, 15.2072662],
                [107.8374521, 15.2073305],
                [107.837268, 15.2074151],
                [107.8362501, 15.2080104],
                [107.8361059, 15.2080849],
                [107.8359573, 15.2081512],
                [107.835805, 15.2082088],
                [107.8356494, 15.2082577],
                [107.8354911, 15.2082976],
                [107.8353307, 15.2083284],
                [107.8351686, 15.20835],
                [107.8350055, 15.2083624],
                [107.8348412, 15.2083654],
                [107.834677, 15.208359],
                [107.8345135, 15.2083433],
                [107.8343513, 15.2083182],
                [107.8341908, 15.2082838],
                [107.8340328, 15.2082404],
                [107.8338777, 15.2081879],
                [107.8337261, 15.2081267],
                [107.8335786, 15.208057],
                [107.8328075, 15.2080982],
                [107.8319295, 15.2081713],
                [107.8314373, 15.2082246],
                [107.830102, 15.2084012],
                [107.8299267, 15.2084352],
                [107.8295822, 15.2085271],
                [107.8294137, 15.2085846],
                [107.829086, 15.2087224],
                [107.8287771, 15.2088872],
                [107.828489, 15.2090763],
                [107.8282201, 15.2092902],
                [107.8279728, 15.2095271],
                [107.8253281, 15.2125346],
                [107.8251633, 15.2126857],
                [107.8249788, 15.2128138],
                [107.8247733, 15.2129186],
                [107.8245498, 15.2129962],
                [107.8244343, 15.2130233],
                [107.8241989, 15.2130536],
                [107.8235679, 15.213059],
                [107.8233588, 15.2130804],
                [107.8232561, 15.2131026],
                [107.8230636, 15.2131672],
                [107.8229746, 15.2132083],
                [107.8228068, 15.2133085],
                [107.822711, 15.2133816],
                [107.8225349, 15.213545],
                [107.8224555, 15.2136346],
                [107.8223279, 15.2138079],
                [107.8222722, 15.2138997],
                [107.8218731, 15.2146696],
                [107.8217969, 15.2148034],
                [107.8216267, 15.2150607],
                [107.8214115, 15.2153282],
                [107.8212941, 15.2154541],
                [107.8209726, 15.2157167],
                [107.8208039, 15.2158385],
                [107.8204521, 15.2160619],
                [107.820069, 15.2162641],
                [107.8198643, 15.2163564],
                [107.8196556, 15.2164402],
                [107.8194433, 15.2165153],
                [107.8192854, 15.2165404],
                [107.8191253, 15.2165362],
                [107.8190464, 15.216523],
                [107.8188793, 15.2164689],
                [107.8188011, 15.2164295],
                [107.8186597, 15.216328],
                [107.8180217, 15.2157716],
                [107.8177106, 15.2154852],
                [107.8173711, 15.2151604],
                [107.8170385, 15.2148291],
                [107.8167128, 15.2144913],
                [107.8152323, 15.2131713],
                [107.8150448, 15.2130624],
                [107.8148415, 15.212984],
                [107.8146278, 15.2129383],
                [107.8144125, 15.2129263],
                [107.8143063, 15.2129326],
                [107.8140972, 15.2129691],
                [107.8138965, 15.2130367],
                [107.8127968, 15.2136061],
                [107.8116327, 15.2127158],
                [107.8108082, 15.212467],
                [107.8106243, 15.212403],
                [107.8102648, 15.2122551],
                [107.8099545, 15.2121013],
                [107.8098035, 15.212017],
                [107.8096101, 15.2119429],
                [107.809407, 15.2118992],
                [107.8093034, 15.2118891],
                [107.8090954, 15.2118928],
                [107.8088864, 15.211929],
                [107.8086863, 15.2119973],
                [107.8085911, 15.2120429],
                [107.807698, 15.2125411],
                [107.8075276, 15.2126248],
                [107.8071771, 15.2127721],
                [107.8068155, 15.2128918],
                [107.8066329, 15.2129406],
                [107.8062625, 15.213017],
                [107.8051767, 15.2131558],
                [107.802186, 15.213777],
                [107.8014323, 15.214723],
                [107.8011676, 15.2150045],
                [107.8010265, 15.2151373],
                [107.8007281, 15.2153856],
                [107.8004207, 15.2156025],
                [107.8001081, 15.2157892],
                [107.7999464, 15.2158737],
                [107.7996138, 15.2160248],
                [107.7964397, 15.2170205],
                [107.7962162, 15.2170702],
                [107.7959874, 15.2170873],
                [107.795865, 15.2170829],
                [107.7957433, 15.2170691],
                [107.795164, 15.2169604],
                [107.794727, 15.2169637],
                [107.7942915, 15.2169985],
                [107.7938418, 15.2170682],
                [107.7936105, 15.2171178],
                [107.7933814, 15.2171763],
                [107.793155, 15.2172438],
                [107.7923584, 15.2172451],
                [107.7913177, 15.2175971],
                [107.790336, 15.2177498],
                [107.7901563, 15.2178056],
                [107.7900705, 15.2178437],
                [107.7899095, 15.2179388],
                [107.7898363, 15.2179945],
                [107.7897045, 15.2181217],
                [107.7896467, 15.2181924],
                [107.7891129, 15.2190206],
                [107.7890391, 15.219099],
                [107.7889941, 15.2191296],
                [107.788893, 15.2191707],
                [107.7887813, 15.2191821],
                [107.788671, 15.2191615],
                [107.7862161, 15.2182079],
                [107.7858716, 15.2181458],
                [107.7855224, 15.2181167],
                [107.7851893, 15.2181201],
                [107.7848751, 15.2181512],
                [107.7847195, 15.2181769],
                [107.7842238, 15.2182806],
                [107.7839734, 15.2183197],
                [107.7835777, 15.2183639],
                [107.7832933, 15.2184143],
                [107.7831537, 15.2184511],
                [107.7828822, 15.218547],
                [107.7826244, 15.2186713],
                [107.7824172, 15.2187881],
                [107.7823293, 15.2188247],
                [107.7821445, 15.2188731],
                [107.7820484, 15.2188846],
                [107.7818549, 15.2188809],
                [107.781136, 15.2187877],
                [107.7808494, 15.2189961],
                [107.7805459, 15.2191811],
                [107.7801992, 15.2193543],
                [107.7798503, 15.2195092],
                [107.7795228, 15.2196906],
                [107.7792077, 15.2199045],
                [107.7790554, 15.2200242],
                [107.7789092, 15.2201508],
                [107.7787693, 15.220284],
                [107.7786362, 15.2204234],
                [107.7784463, 15.2205025],
                [107.7783562, 15.2205516],
                [107.7781879, 15.2206676],
                [107.7781105, 15.220734],
                [107.7779734, 15.220879],
                [107.777913, 15.2209581],
                [107.7778101, 15.2211274],
                [107.777649, 15.2213476],
                [107.7774668, 15.2215518],
                [107.7773611, 15.2216539],
                [107.7771342, 15.2218417],
                [107.7761686, 15.2225353],
                [107.7760675, 15.2225976],
                [107.7759615, 15.2226518],
                [107.7758513, 15.2226976],
                [107.7757377, 15.2227348],
                [107.7756214, 15.222763],
                [107.7754999, 15.2227826],
                [107.7753772, 15.2227924],
                [107.7752541, 15.2227924],
                [107.7751313, 15.2227826],
                [107.7750099, 15.222763],
                [107.7739584, 15.2241296],
                [107.7739155, 15.225113],
                [107.7738075, 15.2253664],
                [107.7736681, 15.225605],
                [107.7734994, 15.2258253],
                [107.7734049, 15.2259276],
                [107.7732071, 15.2261063],
                [107.7729999, 15.2262546],
                [107.7727769, 15.2263796],
                [107.7725711, 15.2264719],
                [107.7724858, 15.2265182],
                [107.7723296, 15.2266318],
                [107.7721965, 15.22677],
                [107.7721399, 15.226847],
                [107.7720539, 15.2270026],
                [107.7720211, 15.227085],
                [107.7719771, 15.2272561],
                [107.7718663, 15.2283843],
                [107.7718335, 15.2286095],
                [107.7717912, 15.2288331],
                [107.7717396, 15.229055],
                [107.7716252, 15.2294688],
                [107.7715981, 15.229668],
                [107.7715946, 15.2297879],
                [107.7716006, 15.2299077],
                [107.7716161, 15.2300267],
                [107.771641, 15.2301442],
                [107.771877, 15.230786],
                [107.7713966, 15.2312067],
                [107.7709947, 15.2315246],
                [107.7708363, 15.231635],
                [107.7705046, 15.231834],
                [107.7703319, 15.2319221],
                [107.7700016, 15.2320644],
                [107.7698456, 15.2321205],
                [107.769417, 15.2322504],
                [107.7693101, 15.2322951],
                [107.7692075, 15.2323483],
                [107.7691098, 15.2324095],
                [107.7690178, 15.2324785],
                [107.768932, 15.2325548],
                [107.7688531, 15.2326379],
                [107.7686895, 15.2328542],
                [107.7685757, 15.232964],
                [107.7684438, 15.2330531],
                [107.7683607, 15.2330941],
                [107.7682737, 15.2331268],
                [107.7681837, 15.2331508],
                [107.7680917, 15.233166],
                [107.7672211, 15.2332477],
                [107.7670756, 15.233284],
                [107.7670062, 15.2333119],
                [107.7668809, 15.2333825],
                [107.7668238, 15.2334257],
                [107.7665234, 15.2337052],
                [107.7663345, 15.2338331],
                [107.7661277, 15.2339316],
                [107.7659248, 15.2339947],
                [107.7658289, 15.2340143],
                [107.7656343, 15.2340352],
                [107.7647112, 15.2340728],
                [107.7645842, 15.2340866],
                [107.7643347, 15.23414],
                [107.7641024, 15.2342235],
                [107.7638841, 15.2343367],
                [107.7634549, 15.234492],
                [107.761446, 15.2353039],
                [107.7611635, 15.2353928],
                [107.7608722, 15.2354492],
                [107.7605812, 15.2354723],
                [107.7602947, 15.2354635],
                [107.7601522, 15.2354474],
                [107.7586377, 15.2351545],
                [107.7576184, 15.2351441],
                [107.7575255, 15.2351485],
                [107.7574333, 15.2351604],
                [107.7572536, 15.2352062],
                [107.7571709, 15.2352384],
                [107.7570148, 15.2353215],
                [107.7569425, 15.2353719],
                [107.7563202, 15.2359723],
                [107.7546948, 15.2352011],
                [107.7538472, 15.2336535],
                [107.7537804, 15.2334876],
                [107.7536808, 15.2333372],
                [107.7535522, 15.2332083],
                [107.7529894, 15.23285],
                [107.752856, 15.2327579],
                [107.7527287, 15.2326581],
                [107.7526081, 15.232551],
                [107.752424, 15.2323586],
                [107.7523398, 15.232256],
                [107.7521886, 15.2321816],
                [107.7520555, 15.23208],
                [107.7519487, 15.2319593],
                [107.7517691, 15.2316639],
                [107.7516627, 15.2315113],
                [107.7514274, 15.2312222],
                [107.7512991, 15.2310862],
                [107.7511623, 15.2309547],
                [107.7510189, 15.2308299],
                [107.7508694, 15.230712],
                [107.750714, 15.2306014],
                [107.7505531, 15.2304983],
                [107.7503872, 15.230403],
                [107.7489066, 15.2297508],
                [107.7469164, 15.2292073],
                [107.7467447, 15.2294143],
                [107.7465087, 15.2299319],
                [107.7464631, 15.2300122],
                [107.7463513, 15.2301597],
                [107.7462148, 15.2302865],
                [107.7460878, 15.2303719],
                [107.7459279, 15.2304522],
                [107.7457072, 15.2305306],
                [107.745482, 15.2305766],
                [107.7452523, 15.2305911],
                [107.7450228, 15.2305738],
                [107.7446681, 15.2305087],
                [107.7444891, 15.2304861],
                [107.7441095, 15.2304605],
                [107.7439094, 15.2304589],
                [107.74351, 15.2304806],
                [107.7424103, 15.2305841],
                [107.7406347, 15.2300717],
                [107.7395242, 15.2296731],
                [107.7380559, 15.2290861],
                [107.7379075, 15.2290592],
                [107.7378319, 15.2290579],
                [107.7376927, 15.2290776],
                [107.7375701, 15.2291205],
                [107.7375126, 15.2291504],
                [107.7370566, 15.2295127],
                [107.736968, 15.2296511],
                [107.7369064, 15.2298025],
                [107.736874, 15.2299603],
                [107.7368045, 15.2307083],
                [107.7367319, 15.2308312],
                [107.7366853, 15.230886],
                [107.7365746, 15.2309785],
                [107.7365118, 15.2310152],
                [107.7363758, 15.2310668],
                [107.7362319, 15.2310878],
                [107.7360867, 15.2310773],
                [107.7360159, 15.2310603],
                [107.7350932, 15.2307032],
                [107.7350032, 15.2306838],
                [107.7349114, 15.2306931],
                [107.7348306, 15.2307282],
                [107.7347967, 15.2307533],
                [107.7347423, 15.2308165],
                [107.7347231, 15.2308533],
                [107.734609, 15.2312063],
                [107.7345772, 15.2312766],
                [107.734537, 15.2313427],
                [107.7344888, 15.2314038],
                [107.7343778, 15.2315028],
                [107.7342536, 15.2315721],
                [107.7340337, 15.2316384],
                [107.7338772, 15.2317113],
                [107.7338058, 15.2317591],
                [107.7336967, 15.2318566],
                [107.7336073, 15.2319713],
                [107.7334356, 15.2324682],
                [107.7333685, 15.2327968],
                [107.7333473, 15.232963],
                [107.7333297, 15.2332975],
                [107.7333455, 15.233632],
                [107.7333928, 15.2339552],
                [107.7334297, 15.2341101],
                [107.7334763, 15.2342626],
                [107.7335326, 15.234412],
                [107.7335982, 15.2345578],
                [107.7336729, 15.2346994],
                [107.7337565, 15.2348364],
                [107.7339336, 15.2350759],
                [107.7340243, 15.2351792],
                [107.7342217, 15.2353714],
                [107.7345329, 15.2356198],
                [107.7346454, 15.235669],
                [107.7347058, 15.2356819],
                [107.7348293, 15.235683],
                [107.73489, 15.2356712],
                [107.7350028, 15.2356243],
                [107.7350976, 15.2355499],
                [107.7351679, 15.2354536],
                [107.7352085, 15.2353424],
                [107.7352166, 15.2352839],
                [107.7352788, 15.2350408],
                [107.7353496, 15.2347999],
                [107.7355171, 15.2343259],
                [107.7357, 15.2339028],
                [107.7358898, 15.2335292],
                [107.7359872, 15.2334594],
                [107.7360423, 15.2334347],
                [107.7361603, 15.233408],
                [107.7362211, 15.2334064],
                [107.7363404, 15.2334271],
                [107.7363968, 15.233449],
                [107.7364927, 15.2335095],
                [107.7365695, 15.2335915],
                [107.7366221, 15.2336897],
                [107.7366281, 15.2338003],
                [107.7366245, 15.233911],
                [107.7366114, 15.234021],
                [107.7365669, 15.2342055],
                [107.7364957, 15.234382],
                [107.7364203, 15.2345161],
                [107.7363854, 15.2346265],
                [107.7363868, 15.2347419],
                [107.7364244, 15.2348515],
                [107.7364942, 15.2349445],
                [107.736539, 15.234982],
                [107.7366435, 15.2350354],
                [107.7369922, 15.2350613],
                [107.7370818, 15.2350831],
                [107.7372547, 15.2351456],
                [107.737416, 15.2352321],
                [107.7374918, 15.2352842],
                [107.7375634, 15.2353417],
                [107.7376921, 15.2354713],
                [107.7377486, 15.2355427],
                [107.7394974, 15.2382186],
                [107.739534, 15.2383324],
                [107.7395347, 15.2384516],
                [107.7395215, 15.2385099],
                [107.7394693, 15.2386179],
                [107.7394315, 15.2386652],
                [107.7393365, 15.2387413],
                [107.7392844, 15.2387672],
                [107.7391716, 15.238797],
                [107.7390547, 15.2387956],
                [107.7389975, 15.2387832],
                [107.7388912, 15.2387362],
                [107.7376628, 15.2377062],
                [107.7375532, 15.2376644],
                [107.7374363, 15.2376503],
                [107.7373195, 15.2376648],
                [107.7372635, 15.2376825],
                [107.7371605, 15.2377374],
                [107.7371152, 15.2377738],
                [107.7370405, 15.2378614],
                [107.7367669, 15.2384101],
                [107.7366673, 15.2386746],
                [107.736494, 15.2392121],
                [107.7363559, 15.239759],
                [107.7363002, 15.2400353],
                [107.7362522, 15.2403221],
                [107.7362138, 15.2406103],
                [107.7361851, 15.2408995],
                [107.7361661, 15.2411895],
                [107.7361686, 15.241244],
                [107.7361615, 15.2412982],
                [107.7361449, 15.2413505],
                [107.7361194, 15.2413992],
                [107.7360856, 15.2414431],
                [107.7360503, 15.2414764],
                [107.7359662, 15.2415261],
                [107.7359194, 15.2415414],
                [107.7354688, 15.2416372],
                [107.7354072, 15.2416591],
                [107.7353495, 15.2416895],
                [107.7352556, 15.2417678],
                [107.73522, 15.2418127],
                [107.7351683, 15.2419141],
                [107.735104, 15.2423592],
                [107.7351161, 15.2427202],
                [107.735163, 15.2430786],
                [107.7352863, 15.2436711],
                [107.7353775, 15.244269],
                [107.7354353, 15.244855],
                [107.7354559, 15.245214],
                [107.7354534, 15.2452792],
                [107.7354312, 15.2454077],
                [107.7354068, 15.2454832],
                [107.7353346, 15.245625],
                [107.735002, 15.246008],
                [107.7347875, 15.2467533],
                [107.7347699, 15.2479937],
                [107.7347484, 15.2481855],
                [107.734696, 15.2483934],
                [107.7346548, 15.248504],
                [107.7346051, 15.2486114],
                [107.7345589, 15.2486676],
                [107.7344855, 15.2487923],
                [107.734459, 15.2488593],
                [107.734428, 15.2489995],
                [107.7344238, 15.2490782],
                [107.7344427, 15.2492344],
                [107.7344656, 15.2493101],
                [107.7347231, 15.249988],
                [107.73474, 15.250064],
                [107.7347474, 15.2501414],
                [107.7347452, 15.2502191],
                [107.7347333, 15.2502959],
                [107.7347121, 15.2503709],
                [107.7346817, 15.2504428],
                [107.7346426, 15.2505108],
                [107.7345481, 15.2506239],
                [107.7344299, 15.250714],
                [107.7343637, 15.2507488],
                [107.7338049, 15.2509125],
                [107.7337652, 15.2509342],
                [107.7336972, 15.2509927],
                [107.7336452, 15.2510758],
                [107.7336287, 15.2511267],
                [107.7336073, 15.2513389],
                [107.7334839, 15.2516183],
                [107.7334525, 15.2516589],
                [107.7334134, 15.2517525],
                [107.7334094, 15.2518534],
                [107.7334209, 15.2519028],
                [107.7334689, 15.2519921],
                [107.7335452, 15.2520604],
                [107.7335912, 15.2520841],
                [107.7336597, 15.2521351],
                [107.733782, 15.2522529],
                [107.7338817, 15.252389],
                [107.733956, 15.2525396],
                [107.7340031, 15.2527025],
                [107.7340155, 15.2527863],
                [107.7340219, 15.2529184],
                [107.734, 15.2530111],
                [107.7339474, 15.2530914],
                [107.7338701, 15.2531503],
                [107.733802, 15.253176],
                [107.7335536, 15.2532331],
                [107.7332301, 15.2532768],
                [107.7329036, 15.2532907],
                [107.7325773, 15.2532745],
                [107.7324469, 15.2532548],
                [107.7322946, 15.2531992],
                [107.7322334, 15.2531923],
                [107.7322031, 15.2531989],
                [107.7321509, 15.2532305],
                [107.7321179, 15.2532813],
                [107.7321111, 15.2533107],
                [107.7321187, 15.2533702],
                [107.7323359, 15.2539111],
                [107.732393, 15.2540247],
                [107.7324094, 15.2540859],
                [107.7324166, 15.2542119],
                [107.7323896, 15.2543354],
                [107.7323334, 15.2544431],
                [107.7322512, 15.2545338],
                [107.7322019, 15.254571],
                [107.7321024, 15.2546215],
                [107.7320615, 15.2546491],
                [107.7320269, 15.254684],
                [107.7319999, 15.2547247],
                [107.7319817, 15.2547696],
                [107.731973, 15.2548171],
                [107.7319739, 15.2548654],
                [107.7320033, 15.2549547],
                [107.7320632, 15.2550254],
                [107.7321456, 15.2550703],
                [107.7322394, 15.2550833],
                [107.7323318, 15.2551138],
                [107.7324083, 15.2551723],
                [107.7324598, 15.2552509],
                [107.7324815, 15.2553401],
                [107.732438, 15.2566578],
                [107.7324161, 15.2567772],
                [107.7323657, 15.2568882],
                [107.7322864, 15.2569878],
                [107.732236, 15.2570312],
                [107.7321204, 15.2570977],
                [107.732057, 15.2571198],
                [107.7319657, 15.2571379],
                [107.7319242, 15.2571587],
                [107.7318562, 15.2572201],
                [107.731832, 15.2572587],
                [107.7318079, 15.2573399],
                [107.7318068, 15.2573796],
                [107.7318243, 15.2574568],
                [107.7321101, 15.2579339],
                [107.7321442, 15.2580552],
                [107.7321482, 15.2581807],
                [107.7321231, 15.2582996],
                [107.7320709, 15.25841],
                [107.7319686, 15.2585351],
                [107.7319447, 15.258579],
                [107.731928, 15.2586258],
                [107.7319175, 15.2587242],
                [107.7319241, 15.258774],
                [107.7319605, 15.2588679],
                [107.7319895, 15.2589097],
                [107.7321327, 15.2590572],
                [107.7321772, 15.25912],
                [107.7322447, 15.2592572],
                [107.7322648, 15.2593215],
                [107.7322863, 15.2594542],
                [107.7322765, 15.2596592],
                [107.7322623, 15.2597289],
                [107.7322097, 15.2598613],
                [107.732172, 15.2599222],
                [107.7320762, 15.2600298],
                [107.7319595, 15.2601124],
                [107.7318286, 15.2601671],
                [107.7317593, 15.2601836],
                [107.7315868, 15.2601966],
                [107.731558, 15.2602062],
                [107.7315115, 15.2602435],
                [107.7314965, 15.2602691],
                [107.7314872, 15.2603247],
                [107.7314925, 15.2603516],
                [107.7315205, 15.2603986],
                [107.7323464, 15.2610355],
                [107.7324355, 15.2611324],
                [107.7324964, 15.2612477],
                [107.7325251, 15.2613742],
                [107.7325198, 15.2615036],
                [107.7324808, 15.2616275],
                [107.7324492, 15.261685],
                [107.7323648, 15.2617861],
                [107.7322565, 15.2618633],
                [107.7321315, 15.2619115],
                [107.7311128, 15.2620311],
                [107.7310576, 15.2620423],
                [107.7309533, 15.2620833],
                [107.7309058, 15.2621126],
                [107.7308232, 15.2621864],
                [107.730759, 15.2622811],
                [107.7307212, 15.2623882],
                [107.7307275, 15.26244],
                [107.7307146, 15.2625432],
                [107.7306691, 15.2626373],
                [107.7305982, 15.2627111],
                [107.7305563, 15.2627385],
                [107.7304624, 15.262774],
                [107.7302144, 15.2627911],
                [107.7300726, 15.2628314],
                [107.7299434, 15.2629006],
                [107.7299022, 15.2629311],
                [107.7298345, 15.2630068],
                [107.7297892, 15.2631013],
                [107.7297771, 15.2631542],
                [107.7298307, 15.2637752],
                [107.7298414, 15.2640114],
                [107.7298468, 15.2644842],
                [107.7298218, 15.2647338],
                [107.729746, 15.26523],
                [107.7296362, 15.2657203],
                [107.7295686, 15.2659624],
                [107.7294178, 15.2664153],
                [107.7293362, 15.2666259],
                [107.7291537, 15.2670397],
                [107.7290529, 15.2672425],
                [107.728962, 15.267376],
                [107.728906, 15.2674351],
                [107.7287764, 15.2675345],
                [107.7286311, 15.2676043],
                [107.728477, 15.2676433],
                [107.7273041, 15.2677031],
                [107.7272156, 15.2677116],
                [107.7271287, 15.2677293],
                [107.7270442, 15.2677561],
                [107.7269634, 15.2677917],
                [107.726887, 15.2678357],
                [107.7268161, 15.2678874],
                [107.726702, 15.2680018],
                [107.72662, 15.268125],
                [107.7265883, 15.2681916],
                [107.726545, 15.2683319],
                [107.7265444, 15.2683888],
                [107.7265534, 15.268445],
                [107.7265717, 15.268499],
                [107.7265988, 15.2685495],
                [107.726634, 15.2685951],
                [107.7266765, 15.2686346],
                [107.7267584, 15.2686834],
                [107.7268508, 15.2687097],
                [107.7271968, 15.2687485],
                [107.7274137, 15.2687908],
                [107.7276286, 15.2688418],
                [107.7280507, 15.2689698],
                [107.7282572, 15.2690464],
                [107.7285842, 15.2691883],
                [107.7287046, 15.2692523],
                [107.7289322, 15.2694005],
                [107.7290469, 15.2694912],
                [107.7291552, 15.2695888],
                [107.7294068, 15.2698433],
                [107.7295845, 15.2699881],
                [107.7296859, 15.2700578],
                [107.7297682, 15.2701314],
                [107.7298435, 15.2702117],
                [107.7299114, 15.2702979],
                [107.7299713, 15.2703894],
                [107.7300228, 15.2704857],
                [107.7300654, 15.2705858],
                [107.730099, 15.2706891],
                [107.7301226, 15.270792],
                [107.7301426, 15.2710018],
                [107.7301258, 15.2712118],
                [107.7300829, 15.2720398],
                [107.7300631, 15.2722438],
                [107.7300437, 15.2723445],
                [107.7299863, 15.2725418],
                [107.7299062, 15.2727281],
                [107.7298039, 15.272904],
                [107.7299514, 15.2735949],
                [107.7299609, 15.273674],
                [107.7299609, 15.2737537],
                [107.7299514, 15.2738329],
                [107.7299327, 15.2739105],
                [107.7298764, 15.274043],
                [107.7297932, 15.2741615],
                [107.7297168, 15.274233],
                [107.7296882, 15.2742765],
                [107.7296537, 15.2743737],
                [107.7296489, 15.2744168],
                [107.729657, 15.2745029],
                [107.7297197, 15.2746756],
                [107.7297328, 15.2747445],
                [107.7297379, 15.2748143],
                [107.7297234, 15.2749533],
                [107.7296793, 15.2750811],
                [107.7296468, 15.2751408],
                [107.7295625, 15.2752483],
                [107.7295073, 15.2753671],
                [107.7294737, 15.275489],
                [107.7294606, 15.2756105],
                [107.7292889, 15.2761383],
                [107.7292916, 15.2766162],
                [107.7292798, 15.2766993],
                [107.7292308, 15.2768598],
                [107.7291495, 15.2770077],
                [107.7290472, 15.2771292],
                [107.7289232, 15.27723],
                [107.7287375, 15.2773364],
                [107.7286415, 15.2774276],
                [107.7285727, 15.2775394],
                [107.72855, 15.2776007],
                [107.7285297, 15.2777292],
                [107.7285438, 15.2778578],
                [107.7285908, 15.2779782],
                [107.7286676, 15.2780833],
                [107.7287157, 15.2781279],
                [107.7288276, 15.2781979],
                [107.7289164, 15.2782925],
                [107.7289977, 15.2783931],
                [107.729071, 15.2784992],
                [107.7291361, 15.2786103],
                [107.7291924, 15.2787258],
                [107.7292723, 15.2789477],
                [107.7293003, 15.279062],
                [107.7293318, 15.279295],
                [107.7294069, 15.280599],
                [107.7295357, 15.2808785],
                [107.7297181, 15.2816857],
                [107.7297274, 15.2817791],
                [107.7297272, 15.2818729],
                [107.7297174, 15.2819661],
                [107.7296982, 15.2820581],
                [107.7296697, 15.2821477],
                [107.7296322, 15.2822343],
                [107.7295926, 15.2823065],
                [107.7294947, 15.2824398],
                [107.7293748, 15.2825551],
                [107.7286881, 15.2830829],
                [107.7285112, 15.2832396],
                [107.7283615, 15.2834207],
                [107.728298, 15.2835191],
                [107.7281954, 15.2837286],
                [107.728157, 15.2838384],
                [107.7281099, 15.2840459],
                [107.7280979, 15.2841516],
                [107.7280974, 15.284364],
                [107.7281592, 15.2847911],
                [107.728216, 15.2851114],
                [107.7283448, 15.2857323],
                [107.72838, 15.2858351],
                [107.7284062, 15.2859403],
                [107.7284232, 15.2860472],
                [107.728431, 15.2861552],
                [107.7284294, 15.2862634],
                [107.7284185, 15.2863711],
                [107.7283984, 15.2864775],
                [107.7283735, 15.2865685],
                [107.7283037, 15.286744],
                [107.7282591, 15.2868277],
                [107.7281517, 15.2869846],
                [107.7277064, 15.2874607],
                [107.7270091, 15.2882317],
                [107.7269726, 15.2882809],
                [107.7269417, 15.2883335],
                [107.726898, 15.2884466],
                [107.7268857, 15.2885059],
                [107.7268801, 15.2886164],
                [107.7268857, 15.2886715],
                [107.7270153, 15.2891189],
                [107.7271217, 15.2895719],
                [107.7272065, 15.290041],
                [107.7272665, 15.2905137],
                [107.7277118, 15.2910311],
                [107.7278195, 15.2911342],
                [107.7279027, 15.2912566],
                [107.7279577, 15.291393],
                [107.7279823, 15.2915374],
                [107.7279757, 15.2916803],
                [107.7279405, 15.2918157],
                [107.7279125, 15.2918803],
                [107.7278463, 15.2919965],
                [107.7278041, 15.2921148],
                [107.7277923, 15.2922394],
                [107.7278115, 15.2923635],
                [107.7279961, 15.2928085],
                [107.7280599, 15.2928672],
                [107.7282228, 15.2929392],
                [107.728332, 15.2930163],
                [107.7284194, 15.293116],
                [107.7284534, 15.2931725],
                [107.7284995, 15.2932952],
                [107.7285147, 15.2934288],
                [107.728495, 15.2935657],
                [107.7284408, 15.2936936],
                [107.7283366, 15.29383],
                [107.7283253, 15.2938593],
                [107.7283281, 15.2939213],
                [107.7283644, 15.2939741],
                [107.7283929, 15.2939918],
                [107.7284253, 15.2940013],
                [107.728672, 15.2940116],
                [107.7287238, 15.2940197],
                [107.7288213, 15.2940563],
                [107.7289019, 15.2941149],
                [107.7289342, 15.2941501],
                [107.7289833, 15.2942311],
                [107.7289993, 15.2942755],
                [107.7291026, 15.294718],
                [107.7291248, 15.2947707],
                [107.729189, 15.2948659],
                [107.7292747, 15.2949416],
                [107.729376, 15.2949939],
                [107.7294308, 15.2950106],
                [107.7296089, 15.2950424],
                [107.7297205, 15.2950935],
                [107.7297702, 15.2951291],
                [107.7298508, 15.2952142],
                [107.7298813, 15.2952617],
                [107.7299232, 15.2953655],
                [107.729938, 15.295476],
                [107.7299254, 15.2959115],
                [107.7299012, 15.2961189],
                [107.7298473, 15.2963305],
                [107.7298079, 15.2964376],
                [107.729761, 15.2965419],
                [107.7296688, 15.2967119],
                [107.7296054, 15.2968937],
                [107.7295831, 15.2969995],
                [107.7295705, 15.2971067],
                [107.7295679, 15.2972146],
                [107.7295757, 15.2973799],
                [107.7295522, 15.2975439],
                [107.7294981, 15.2977009],
                [107.7294572, 15.2977805],
                [107.7293521, 15.2979261],
                [107.7292506, 15.2980237],
                [107.7291895, 15.2981031],
                [107.7291526, 15.2981954],
                [107.7291424, 15.2982891],
                [107.7291463, 15.2983338],
                [107.7291709, 15.2984202],
                [107.729364, 15.2987306],
                [107.7294072, 15.2988444],
                [107.7294591, 15.2989546],
                [107.7295196, 15.2990608],
                [107.7295881, 15.2991622],
                [107.7296644, 15.2992584],
                [107.7298189, 15.2994156],
                [107.7299041, 15.2994863],
                [107.7301261, 15.299641],
                [107.7301841, 15.2997178],
                [107.7302028, 15.299762],
                [107.730217, 15.299856],
                [107.7302122, 15.2999035],
                [107.7301794, 15.2999932],
                [107.7301225, 15.3000648],
                [107.7300458, 15.3001165],
                [107.7299565, 15.3001435],
                [107.7299097, 15.3001468],
                [107.7297279, 15.3001369],
                [107.7295947, 15.3001587],
                [107.729476, 15.3002045],
                [107.7293694, 15.3002726],
                [107.7292198, 15.3003256],
                [107.7290629, 15.3003522],
                [107.7288998, 15.3003514],
                [107.728736, 15.3003211],
                [107.7285108, 15.3002355],
                [107.7284355, 15.3002377],
                [107.7283673, 15.3002685],
                [107.7283394, 15.3002933],
                [107.7283042, 15.3003501],
                [107.7282953, 15.3003823],
                [107.7282893, 15.3005289],
                [107.728275, 15.3006085],
                [107.7282249, 15.3007634],
                [107.7281471, 15.3009085],
                [107.7280444, 15.3010384],
                [107.7278942, 15.3013747],
                [107.7278164, 15.3016907],
                [107.7276979, 15.3022578],
                [107.7276014, 15.3028238],
                [107.7275958, 15.3029562],
                [107.7276195, 15.3030868],
                [107.7276729, 15.303212],
                [107.7277552, 15.3033253],
                [107.7278057, 15.3033745],
                [107.7279144, 15.3034341],
                [107.7280335, 15.3034711],
                [107.7281619, 15.3034839],
                [107.7282941, 15.3034696],
                [107.7284096, 15.3034402],
                [107.7284621, 15.3034344],
                [107.7285673, 15.3034418],
                [107.7286662, 15.3034731],
                [107.7287533, 15.3035258],
                [107.7288249, 15.303597],
                [107.7288457, 15.3036588],
                [107.7288573, 15.3037228],
                [107.7288595, 15.3037877],
                [107.7288522, 15.3038523],
                [107.7288153, 15.3039895],
                [107.7288276, 15.3040653],
                [107.7288637, 15.3041231],
                [107.7289186, 15.3041648],
                [107.7291092, 15.3042688],
                [107.7292622, 15.3043676],
                [107.7294096, 15.3044741],
                [107.7295146, 15.3045573],
                [107.7297544, 15.3047749],
                [107.7297998, 15.3048089],
                [107.7299028, 15.3048576],
                [107.7300033, 15.3048772],
                [107.7300546, 15.3048782],
                [107.7303835, 15.3048494],
                [107.7304553, 15.3048546],
                [107.7305945, 15.3048896],
                [107.7306599, 15.3049188],
                [107.7307722, 15.3049938],
                [107.7308597, 15.3050856],
                [107.7309251, 15.3051933],
                [107.7310297, 15.3055192],
                [107.7310385, 15.3055882],
                [107.7310323, 15.3057268],
                [107.7309948, 15.3058607],
                [107.730934, 15.3059746],
                [107.7308506, 15.3060743],
                [107.7307103, 15.3061875],
                [107.730677, 15.3062237],
                [107.7306486, 15.3062637],
                [107.7306086, 15.3063523],
                [107.7305973, 15.3064008],
                [107.7305946, 15.3064999],
                [107.730673, 15.30688],
                [107.7306938, 15.3070894],
                [107.7306783, 15.3072991],
                [107.7306563, 15.3074051],
                [107.7306249, 15.3075088],
                [107.7305844, 15.3076096],
                [107.7302787, 15.3082279],
                [107.7299407, 15.3081761],
                [107.7295598, 15.3081348],
                [107.7287954, 15.3080908],
                [107.7283434, 15.3081469],
                [107.7278888, 15.3081787],
                [107.7274024, 15.3081857],
                [107.7271593, 15.3081787],
                [107.725588, 15.3079282],
                [107.725144, 15.3078318],
                [107.7247023, 15.3077079],
                [107.721312, 15.30777],
                [107.7190483, 15.3072008],
                [107.7182624, 15.3064713],
                [107.7177608, 15.3063885],
                [107.7172351, 15.3062643],
                [107.7170391, 15.3062073],
                [107.7168521, 15.3061267],
                [107.716763, 15.306078],
                [107.7166588, 15.3060113],
                [107.7165602, 15.305937],
                [107.7163923, 15.3057904],
                [107.7163108, 15.3057321],
                [107.7161334, 15.3056375],
                [107.7160388, 15.305602],
                [107.7158668, 15.30556],
                [107.7156902, 15.3055444],
                [107.7143805, 15.305619],
                [107.7141751, 15.3056099],
                [107.7139735, 15.305571],
                [107.7137885, 15.3055068],
                [107.7136149, 15.3054175],
                [107.712993, 15.3049806],
                [107.7128237, 15.3048935],
                [107.7127346, 15.3048591],
                [107.7125561, 15.3048107],
                [107.7123786, 15.3047874],
                [107.7116668, 15.3047897],
                [107.7113756, 15.3047718],
                [107.7110875, 15.3047276],
                [107.7108045, 15.3046575],
                [107.7105296, 15.304562],
                [107.7077466, 15.3033004],
                [107.7075002, 15.3038697],
                [107.7074443, 15.3039669],
                [107.7073109, 15.3041481],
                [107.7071589, 15.304302],
                [107.7069861, 15.304434],
                [107.706893, 15.3044909],
                [107.7065692, 15.3046596],
                [107.7063603, 15.3048051],
                [107.7062636, 15.3048874],
                [107.7060878, 15.3050691],
                [107.7059374, 15.305272],
                [107.7058155, 15.3054919],
                [107.7049827, 15.3074699],
                [107.7048558, 15.3077248],
                [107.7047038, 15.3079666],
                [107.7046136, 15.3080886],
                [107.7044141, 15.3083184],
                [107.7037704, 15.3089497],
                [107.7027699, 15.3100517],
                [107.7027099, 15.3101648],
                [107.7026411, 15.310273],
                [107.7025639, 15.310376],
                [107.7024789, 15.3104729],
                [107.7023864, 15.3105634],
                [107.7021924, 15.3107154],
                [107.7019894, 15.310833],
                [107.701292, 15.3114643],
                [107.7010836, 15.3116389],
                [107.700869, 15.3118065],
                [107.7006486, 15.3119669],
                [107.7004226, 15.3121199],
                [107.7001913, 15.3122653],
                [107.6999549, 15.3124029],
                [107.6997138, 15.3125327],
                [107.6994681, 15.3126543],
                [107.6992206, 15.3127667],
                [107.6989693, 15.3128708],
                [107.6987145, 15.3129667],
                [107.6984564, 15.3130541],
                [107.6981953, 15.313133],
                [107.6979317, 15.3132033],
                [107.6976657, 15.3132648],
                [107.6975102, 15.3133544],
                [107.697376, 15.3134718],
                [107.6972768, 15.3135989],
                [107.6972365, 15.3136684],
                [107.6970863, 15.3140926],
                [107.6969942, 15.3142491],
                [107.696894, 15.314401],
                [107.6967861, 15.3145478],
                [107.6966707, 15.3146892],
                [107.6965481, 15.3148249],
                [107.6964185, 15.3149544],
                [107.6962825, 15.3150776],
                [107.6961402, 15.315194],
                [107.695992, 15.3153033],
                [107.6957069, 15.3154844],
                [107.6954058, 15.3156395],
                [107.6952501, 15.3157069],
                [107.6949298, 15.3158207],
                [107.693063, 15.3175592],
                [107.6928699, 15.3195459],
                [107.6928441, 15.3198674],
                [107.6927897, 15.3201856],
                [107.6927119, 15.3204824],
                [107.6926663, 15.3206205],
                [107.6925588, 15.3208911],
                [107.6921403, 15.3215844],
                [107.6920475, 15.3242595],
                [107.6920315, 15.3243466],
                [107.6919735, 15.3245143],
                [107.6919321, 15.3245932],
                [107.6918325, 15.3247306],
                [107.6917157, 15.3248419],
                [107.6915805, 15.3249319],
                [107.6915073, 15.324968],
                [107.6913122, 15.3250316],
                [107.690931, 15.3251817],
                [107.690585, 15.3253503],
                [107.6902736, 15.3255315],
                [107.6901233, 15.3256302],
                [107.6892435, 15.3263752],
                [107.6891693, 15.3264243],
                [107.6890826, 15.3264476],
                [107.6889997, 15.326444],
                [107.6889217, 15.3264166],
                [107.6887286, 15.3262976],
                [107.688614, 15.3262459],
                [107.6884902, 15.3262213],
                [107.6883638, 15.3262252],
                [107.6882944, 15.3262398],
                [107.6882276, 15.3262631],
                [107.6881645, 15.3262946],
                [107.6881063, 15.3263338],
                [107.6880555, 15.3263964],
                [107.687937, 15.3265067],
                [107.6878705, 15.3265536],
                [107.6877254, 15.3266287],
                [107.6876482, 15.3266563],
                [107.6874875, 15.3266906],
                [107.6873231, 15.326696],
                [107.6871813, 15.3266854],
                [107.6871104, 15.3266921],
                [107.6869735, 15.3267289],
                [107.686849, 15.3267952],
                [107.6867437, 15.3268874],
                [107.6867016, 15.3269397],
                [107.6866367, 15.3270562],
                [107.6866004, 15.3271837],
                [107.6865463, 15.3276538],
                [107.6865275, 15.3277253],
                [107.6864999, 15.327794],
                [107.6864637, 15.327859],
                [107.6864196, 15.3279192],
                [107.6863682, 15.3279739],
                [107.686265, 15.3280524],
                [107.686147, 15.3281084],
                [107.6859399, 15.3281633],
                [107.6858635, 15.3281957],
                [107.6857914, 15.3282362],
                [107.6857245, 15.3282843],
                [107.6856765, 15.3283266],
                [107.685593, 15.3284227],
                [107.6855582, 15.3284757],
                [107.6848125, 15.329976],
                [107.6847627, 15.3300541],
                [107.684705, 15.330127],
                [107.6846399, 15.3301939],
                [107.6845683, 15.3302541],
                [107.6844907, 15.3303071],
                [107.6843438, 15.3303805],
                [107.684186, 15.3304281],
                [107.6839956, 15.3304596],
                [107.6837828, 15.3305157],
                [107.6836797, 15.3305537],
                [107.6834822, 15.3306486],
                [107.6832754, 15.3307854],
                [107.6831805, 15.3308645],
                [107.6830101, 15.3310418],
                [107.6818185, 15.3328751],
                [107.6816925, 15.3330329],
                [107.6815456, 15.3331729],
                [107.6813798, 15.3332928],
                [107.6811978, 15.3333903],
                [107.6811021, 15.3334298],
                [107.6802421, 15.333732],
                [107.6800323, 15.333833],
                [107.6799333, 15.3338937],
                [107.6797495, 15.3340341],
                [107.6796656, 15.3341132],
                [107.679516, 15.3342867],
                [107.6793928, 15.3344781],
                [107.6792979, 15.3346839],
                [107.679137, 15.3355324],
                [107.6791113, 15.3359461],
                [107.6791199, 15.3363604],
                [107.6791648, 15.3367878],
                [107.6792024, 15.3370072],
                [107.6792497, 15.3372249],
                [107.6793066, 15.3374405],
                [107.6794225, 15.3378153],
                [107.6794642, 15.3379792],
                [107.6794981, 15.3381448],
                [107.679542, 15.3384796],
                [107.679552, 15.3386481],
                [107.679548, 15.3389855],
                [107.6795203, 15.3392644],
                [107.6794726, 15.3394823],
                [107.6793983, 15.3396933],
                [107.6792919, 15.3399057],
                [107.6791504, 15.3401139],
                [107.6789814, 15.3403022],
                [107.6787332, 15.3408427],
                [107.678664, 15.3409759],
                [107.6785865, 15.3411047],
                [107.6785008, 15.3412286],
                [107.6784074, 15.3413472],
                [107.6783068, 15.3414597],
                [107.6781993, 15.341566],
                [107.6780851, 15.3416657],
                [107.6779648, 15.3417584],
                [107.6778388, 15.3418438],
                [107.6774955, 15.3427957],
                [107.6771092, 15.3434889],
                [107.6770022, 15.3435903],
                [107.6768737, 15.3436648],
                [107.6767312, 15.3437083],
                [107.6766572, 15.3437177],
                [107.6765084, 15.3437113],
                [107.6763071, 15.3436622],
                [107.6761083, 15.3436043],
                [107.6759124, 15.3435379],
                [107.6757199, 15.343463],
                [107.6753744, 15.3433033],
                [107.6752072, 15.3432132],
                [107.6750439, 15.3431164],
                [107.6749726, 15.343066],
                [107.674896, 15.3430235],
                [107.674815, 15.3429893],
                [107.6747307, 15.3429639],
                [107.6746439, 15.3429476],
                [107.6745558, 15.3429405],
                [107.6743984, 15.3429513],
                [107.6743213, 15.3429678],
                [107.674174, 15.3430221],
                [107.6724476, 15.3438769],
                [107.6721064, 15.34403],
                [107.6719312, 15.3440963],
                [107.6715732, 15.344208],
                [107.6712404, 15.3442847],
                [107.6709026, 15.3443373],
                [107.6701677, 15.3449064],
                [107.6695454, 15.3450822],
                [107.6689982, 15.3455375],
                [107.6688884, 15.3456887],
                [107.668844, 15.3457706],
                [107.668778, 15.345944],
                [107.6687449, 15.3461155],
                [107.6687405, 15.3462798],
                [107.6687907, 15.3466603],
                [107.6688101, 15.3468788],
                [107.6688212, 15.347317],
                [107.6688127, 15.3475407],
                [107.6687947, 15.3477637],
                [107.6687671, 15.3479859],
                [107.66873, 15.3482068],
                [107.6681959, 15.3484466],
                [107.6676732, 15.3487086],
                [107.6673958, 15.3488593],
                [107.6668525, 15.3491794],
                [107.6667437, 15.3492511],
                [107.6666393, 15.3493287],
                [107.6664448, 15.3495001],
                [107.6662784, 15.3496828],
                [107.6661333, 15.3498816],
                [107.6660693, 15.3499864],
                [107.6655221, 15.3510003],
                [107.6648033, 15.3520245],
                [107.664616, 15.3522561],
                [107.664515, 15.3523659],
                [107.664299, 15.3525729],
                [107.6640302, 15.3527876],
                [107.6637411, 15.3529764],
                [107.6636304, 15.3531051],
                [107.6635269, 15.3532393],
                [107.6634312, 15.3533788],
                [107.6633433, 15.353523],
                [107.6632636, 15.3536715],
                [107.6631923, 15.353824],
                [107.6630768, 15.3541351],
                [107.6629969, 15.3544525],
                [107.6629701, 15.3546138],
                [107.6629428, 15.3549394],
                [107.6629425, 15.3551027],
                [107.6629686, 15.3554283],
                [107.6631725, 15.3569906],
                [107.6632674, 15.3573782],
                [107.6633282, 15.3575686],
                [107.6634753, 15.3579391],
                [107.6636553, 15.3582941],
                [107.6640147, 15.3589355],
                [107.6640744, 15.359087],
                [107.6641258, 15.3592413],
                [107.6642034, 15.3595565],
                [107.6642293, 15.3597166],
                [107.6642538, 15.3600033],
                [107.6642507, 15.3602908],
                [107.6640576, 15.3624117],
                [107.6637626, 15.3627582],
                [107.6636982, 15.3631772],
                [107.6636016, 15.3636117],
                [107.6635763, 15.3637934],
                [107.6635848, 15.3639766],
                [107.6636016, 15.3640669],
                [107.6636551, 15.3642301],
                [107.6636921, 15.3643079],
                [107.6639631, 15.3647786],
                [107.6640277, 15.3649602],
                [107.6640467, 15.3650546],
                [107.6640576, 15.3652463],
                [107.664049, 15.3653447],
                [107.6640308, 15.3654419],
                [107.6640032, 15.3655369],
                [107.6639664, 15.3656291],
                [107.6634192, 15.3667257],
                [107.6629579, 15.3677292],
                [107.6627556, 15.3681082],
                [107.6625287, 15.368474],
                [107.6623871, 15.3686785],
                [107.6620812, 15.3690719],
                [107.6602113, 15.3712982],
                [107.6601182, 15.371449],
                [107.6599557, 15.3717629],
                [107.6598262, 15.3720908],
                [107.6597308, 15.3724294],
                [107.6596963, 15.3726017],
                [107.6596567, 15.3729142],
                [107.6596459, 15.3732288],
                [107.6596641, 15.3735431],
                [107.6598036, 15.3747224],
                [107.6598763, 15.3751489],
                [107.6599766, 15.3755701],
                [107.660104, 15.3759845],
                [107.6601835, 15.3762035],
                [107.660365, 15.3766331],
                [107.6605761, 15.37705],
                [107.6613057, 15.3783328],
                [107.6613986, 15.3788888],
                [107.6614558, 15.3791649],
                [107.6615797, 15.3796685],
                [107.6617134, 15.3801224],
                [107.6622713, 15.3818706],
                [107.6623041, 15.3819927],
                [107.6623471, 15.3822414],
                [107.6623595, 15.3824776],
                [107.6623464, 15.3826982],
                [107.6618528, 15.3846843],
                [107.6617683, 15.3849431],
                [107.6616543, 15.3851912],
                [107.6615786, 15.3853237],
                [107.6614944, 15.3854515],
                [107.6614022, 15.3855739],
                [107.6612949, 15.3857032],
                [107.6607239, 15.3864169],
                [107.6606667, 15.3865096],
                [107.6605761, 15.3867066],
                [107.6605218, 15.3869016],
                [107.6604983, 15.3871023],
                [107.6604969, 15.3878967],
                [107.6604683, 15.3881102],
                [107.6604419, 15.3882148],
                [107.6603658, 15.3884171],
                [107.660312, 15.3885215],
                [107.6602498, 15.3886214],
                [107.6601797, 15.3887163],
                [107.6601019, 15.3888055],
                [107.6600171, 15.3888885],
                [107.6599258, 15.3889648],
                [107.6590258, 15.3895358],
                [107.6588274, 15.3896455],
                [107.6584446, 15.389887],
                [107.6580826, 15.3901565],
                [107.6577431, 15.3904526],
                [107.6575825, 15.3906102],
                [107.6572811, 15.3909429],
                [107.6565992, 15.3917931],
                [107.6563033, 15.3922039],
                [107.6561651, 15.3924156],
                [107.6559089, 15.3928506],
                [107.6557106, 15.3932358],
                [107.6555743, 15.3935707],
                [107.6554718, 15.3939166],
                [107.655404, 15.3942704],
                [107.6553714, 15.3946314],
                [107.6553752, 15.3949964],
                [107.6554155, 15.3953594],
                [107.6554711, 15.3956444],
                [107.6554855, 15.3957887],
                [107.6554872, 15.3960786],
                [107.6554745, 15.3962231],
                [107.6554229, 15.3965065],
                [107.6553366, 15.3967819],
                [107.655217, 15.3970455],
                [107.6541603, 15.3981678],
                [107.6540636, 15.3982535],
                [107.6539604, 15.398332],
                [107.6538515, 15.3984027],
                [107.6537372, 15.3984653],
                [107.6536184, 15.3985195],
                [107.6533916, 15.3985955],
                [107.6532746, 15.398622],
                [107.6528699, 15.3986745],
                [107.6527052, 15.3987073],
                [107.6525429, 15.3987497],
                [107.6523834, 15.3988014],
                [107.6522276, 15.3988623],
                [107.6520758, 15.3989322],
                [107.6519287, 15.3990108],
                [107.6517994, 15.3990895],
                [107.6515553, 15.3992669],
                [107.6513329, 15.3994691],
                [107.6511347, 15.3996935],
                [107.649622, 15.4012967],
                [107.6476908, 15.4025793],
                [107.6462621, 15.403926],
                [107.645893, 15.4042342],
                [107.6455042, 15.4045189],
                [107.6451035, 15.4047751],
                [107.644897, 15.4048939],
                [107.6444729, 15.4051124],
                [107.6442557, 15.4052118],
                [107.6428413, 15.4058065],
                [107.6426629, 15.4058714],
                [107.6425006, 15.4059679],
                [107.6424274, 15.4060269],
                [107.6423001, 15.4061637],
                [107.6422022, 15.4063215],
                [107.6421428, 15.4064744],
                [107.6420664, 15.4068234],
                [107.6420373, 15.4070793],
                [107.6420348, 15.4071941],
                [107.64204, 15.4073087],
                [107.6420732, 15.407536],
                [107.6421382, 15.4077625],
                [107.6422361, 15.4079839],
                [107.6423639, 15.4081906],
                [107.6408404, 15.4092973],
                [107.6407559, 15.4093846],
                [107.6406067, 15.409575],
                [107.6404864, 15.4097834],
                [107.640435, 15.4099],
                [107.6403927, 15.4100199],
                [107.6403597, 15.4101424],
                [107.6403154, 15.4103604],
                [107.6402879, 15.4104522],
                [107.6402128, 15.410629],
                [107.6401649, 15.4107143],
                [107.6401107, 15.410796],
                [107.6399848, 15.410947],
                [107.639386, 15.4115084],
                [107.6392352, 15.4116855],
                [107.6391081, 15.4118792],
                [107.6390042, 15.4120924],
                [107.638962, 15.4122063],
                [107.6389009, 15.4124409],
                [107.6386678, 15.4143395],
                [107.6385995, 15.414609],
                [107.6384986, 15.4148687],
                [107.6383666, 15.4151152],
                [107.6382052, 15.4153448],
                [107.6381142, 15.4154523],
                [107.6379133, 15.4156509],
                [107.6376863, 15.4158273],
                [107.6375633, 15.4159063],
                [107.6373036, 15.416042],
                [107.6370287, 15.4161463],
                [107.6367427, 15.4162176],
                [107.6365968, 15.4162406],
                [107.6363021, 15.4162607],
                [107.6351794, 15.4161764],
                [107.634854, 15.4161814],
                [107.6345308, 15.4162182],
                [107.6343711, 15.4162483],
                [107.6340574, 15.4163317],
                [107.6339042, 15.4163848],
                [107.6336188, 15.4165074],
                [107.633481, 15.4165786],
                [107.6332171, 15.4167399],
                [107.6330915, 15.4168296],
                [107.6325953, 15.4172717],
                [107.6325089, 15.4173388],
                [107.6323206, 15.4174517],
                [107.6322199, 15.4174967],
                [107.6320089, 15.4175623],
                [107.6317897, 15.4175941],
                [107.631568, 15.4175913],
                [107.6313464, 15.417553],
                [107.6312372, 15.4175202],
                [107.6311312, 15.4174787],
                [107.6310292, 15.4174288],
                [107.6309319, 15.4173708],
                [107.63084, 15.4173051],
                [107.6307541, 15.4172323],
                [107.6306748, 15.4171528],
                [107.6296502, 15.4154979],
                [107.627601, 15.4147377],
                [107.624318, 15.4147119],
                [107.6242413, 15.4135425],
                [107.6239815, 15.4131942],
                [107.6235511, 15.4128067],
                [107.6229506, 15.4124419],
                [107.6223288, 15.4120773],
                [107.6218765, 15.4116488],
                [107.6216154, 15.4111974],
                [107.6211832, 15.4106652],
                [107.620603, 15.4102175],
                [107.6198759, 15.4099368],
                [107.6192768, 15.4096752],
                [107.618997, 15.4094306],
                [107.61895, 15.40908],
                [107.6189879, 15.4087076],
                [107.6190041, 15.4082942],
                [107.6188284, 15.4078624],
                [107.6185866, 15.4072453],
                [107.6181569, 15.4069198],
                [107.6176002, 15.4066577],
                [107.6173612, 15.4062681],
                [107.6172266, 15.4057117],
                [107.6170302, 15.4053214],
                [107.6167895, 15.4047871],
                [107.6166356, 15.4043964],
                [107.6163345, 15.404152],
                [107.6159264, 15.4038469],
                [107.6155388, 15.4034794],
                [107.6152575, 15.4031109],
                [107.6150396, 15.4027002],
                [107.6150559, 15.4023074],
                [107.6152846, 15.4018708],
                [107.6154076, 15.4014974],
                [107.615425, 15.4011874],
                [107.6149496, 15.4006144],
                [107.614095, 15.3997679],
                [107.6140669, 15.3997151],
                [107.6139478, 15.3995034],
                [107.6139531, 15.3992005],
                [107.6140768, 15.3988891],
                [107.6140694, 15.3982899],
                [107.6137433, 15.3977359],
                [107.6135746, 15.3974967],
                [107.6132934, 15.3972803],
                [107.6130987, 15.3970291],
                [107.6129912, 15.3967768],
                [107.6130399, 15.396489],
                [107.6132088, 15.3960814],
                [107.6132891, 15.3955397],
                [107.6133526, 15.3950488],
                [107.6134106, 15.3945312],
                [107.6134427, 15.3943112],
                [107.6132482, 15.3938779],
                [107.6128421, 15.3937381],
                [107.6123115, 15.3938477],
                [107.6119523, 15.3940584],
                [107.6116122, 15.3940832],
                [107.6111657, 15.394109],
                [107.6110431, 15.3940956],
                [107.6109712, 15.3939105],
                [107.6108284, 15.3936249],
                [107.610667, 15.3932381],
                [107.6105756, 15.3929012],
                [107.6104331, 15.3926325],
                [107.6102062, 15.3925844],
                [107.6100161, 15.3926881],
                [107.6096188, 15.3929293],
                [107.6091851, 15.3930526],
                [107.6088731, 15.3931578],
                [107.6085944, 15.3931441],
                [107.6084175, 15.3929096],
                [107.60836, 15.3924878],
                [107.6083389, 15.3921838],
                [107.6082497, 15.3920159],
                [107.608074, 15.3918827],
                [107.6077249, 15.3918024],
                [107.6073934, 15.3917386],
                [107.6071313, 15.3916572],
                [107.6068323, 15.3914072],
                [107.6066214, 15.3912407],
                [107.6064794, 15.3910227],
                [107.6064746, 15.390634],
                [107.6064897, 15.3904478],
                [107.6063133, 15.390264],
                [107.6060152, 15.3900817],
                [107.6059772, 15.3898286],
                [107.6060778, 15.3895232],
                [107.6061959, 15.3892176],
                [107.6062441, 15.388896],
                [107.6061891, 15.3886769],
                [107.6060659, 15.3885601],
                [107.6058736, 15.3884948],
                [107.6055605, 15.3885153],
                [107.6052815, 15.3884679],
                [107.6049663, 15.3883195],
                [107.6046686, 15.3881709],
                [107.6042842, 15.3880571],
                [107.6039704, 15.3880101],
                [107.6035863, 15.3879131],
                [107.6031661, 15.3877152],
                [107.6027114, 15.3875347],
                [107.6023245, 15.3872181],
                [107.6019733, 15.3869687],
                [107.6017966, 15.3867511],
                [107.6015674, 15.3865171],
                [107.6013576, 15.3864351],
                [107.6010959, 15.3863875],
                [107.6007308, 15.3864256],
                [107.6005027, 15.3862761],
                [107.6002377, 15.3859582],
                [107.5998148, 15.3855405],
                [107.5995485, 15.3851212],
                [107.5994049, 15.384768],
                [107.599053, 15.3844678],
                [107.598511, 15.3842713],
                [107.5979871, 15.3841254],
                [107.597342, 15.3840316],
                [107.5970274, 15.3839338],
                [107.5968163, 15.3837503],
                [107.5965851, 15.3833474],
                [107.5963547, 15.3830121],
                [107.5963521, 15.3828094],
                [107.5964186, 15.3825551],
                [107.5965876, 15.3821475],
                [107.5968424, 15.3816376],
                [107.597186, 15.3812786],
                [107.5974084, 15.3809719],
                [107.5979213, 15.3802223],
                [107.5980045, 15.3799171],
                [107.5981565, 15.3795435],
                [107.598275, 15.3792717],
                [107.5983589, 15.3790172],
                [107.5983022, 15.3786629],
                [107.5980888, 15.3782937],
                [107.598016, 15.3780411],
                [107.5980647, 15.3777531],
                [107.5982892, 15.3776154],
                [107.5987402, 15.3774918],
                [107.5990864, 15.3773357],
                [107.5994489, 15.3770948],
                [107.5997946, 15.3769049],
                [107.6001399, 15.3766812],
                [107.6003116, 15.3764933],
                [107.6004472, 15.3762044],
                [107.6004083, 15.3758668],
                [107.6004753, 15.3756633],
                [107.6007696, 15.3755415],
                [107.6012751, 15.3756032],
                [107.6017307, 15.3758513],
                [107.6020974, 15.3759485],
                [107.6023407, 15.3759118],
                [107.6026511, 15.3756885],
                [107.6030811, 15.3752779],
                [107.6036657, 15.3746965],
                [107.6039051, 15.3743556],
                [107.604034, 15.3739795],
                [107.6038354, 15.3717019],
                [107.6046239, 15.3709369],
                [107.6053426, 15.3708151],
                [107.6063698, 15.3704629],
                [107.6078923, 15.3692358],
                [107.608906, 15.3678066],
                [107.6090751, 15.3673133],
                [107.608973, 15.3669367],
                [107.608501, 15.3665453],
                [107.6079869, 15.36589],
                [107.6077474, 15.3654203],
                [107.6078394, 15.3649847],
                [107.6076013, 15.3646285],
                [107.6070707, 15.3642189],
                [107.606538, 15.3636394],
                [107.6060267, 15.3632107],
                [107.6058432, 15.3625514],
                [107.6055916, 15.3614134],
                [107.6054301, 15.3606289],
                [107.6052678, 15.3601017],
                [107.6053982, 15.3596278],
                [107.6064195, 15.3587005],
                [107.6065992, 15.3582731],
                [107.6062249, 15.3579277],
                [107.6058323, 15.3576677],
                [107.6053597, 15.3572384],
                [107.6052081, 15.3567959],
                [107.6052906, 15.3559915],
                [107.6053018, 15.3555387],
                [107.6051536, 15.3550389],
                [107.6050157, 15.3543689],
                [107.6049923, 15.3533753],
                [107.6054593, 15.3527192],
                [107.6067644, 15.3528401],
                [107.6077869, 15.3529483],
                [107.6084773, 15.3526689],
                [107.6088426, 15.3523056],
                [107.6089173, 15.3517147],
                [107.6091229, 15.3508163],
                [107.6089043, 15.3488914],
                [107.608618, 15.3479941],
                [107.6086269, 15.3471434],
                [107.6088519, 15.3462466],
                [107.6089023, 15.3456034],
                [107.6083515, 15.3449622],
                [107.6080174, 15.3441758],
                [107.6078913, 15.3438283],
                [107.6078171, 15.3435091],
                [107.6080641, 15.3427834],
                [107.6087775, 15.3418257],
                [107.6098192, 15.3410765],
                [107.6103105, 15.3398992],
                [107.6104795, 15.3394059],
                [107.6104225, 15.3390698],
                [107.6099111, 15.3384172],
                [107.6096069, 15.3378786],
                [107.609439, 15.3371128],
                [107.6101283, 15.3361977],
                [107.6101979, 15.3355355],
                [107.6101526, 15.3350258],
                [107.6103029, 15.3345894],
                [107.6107084, 15.334339],
                [107.6111752, 15.3343147],
                [107.6119965, 15.3346262],
                [107.6127321, 15.3343153],
                [107.6134649, 15.3337776],
                [107.6145325, 15.3335573],
                [107.6152808, 15.3326982],
                [107.6157536, 15.3315966],
                [107.6159022, 15.331028],
                [107.6163841, 15.3306634],
                [107.6166537, 15.3304334],
                [107.6171583, 15.3303141],
                [107.6181189, 15.3308886],
                [107.6189917, 15.3306517],
                [107.6202334, 15.3303536],
                [107.6208155, 15.3302145],
                [107.62117, 15.3305505],
                [107.6216316, 15.3316788],
                [107.6223069, 15.3327669],
                [107.6233611, 15.333038],
                [107.6246451, 15.333004],
                [107.6258472, 15.3326496],
                [107.6263866, 15.3322086],
                [107.6263985, 15.3316039],
                [107.6263706, 15.3309239],
                [107.6262854, 15.3303392],
                [107.6262593, 15.3298104],
                [107.626814, 15.3290298],
                [107.6270508, 15.3287269],
                [107.6271597, 15.3282807],
                [107.6270038, 15.3275837],
                [107.6271157, 15.3273759],
                [107.6278164, 15.3271611],
                [107.6286133, 15.3267864],
                [107.6289679, 15.3263693],
                [107.629269, 15.3255874],
                [107.62941, 15.3250932],
                [107.6300614, 15.3248632],
                [107.6308142, 15.3248861],
                [107.6314342, 15.3247677],
                [107.6317363, 15.3240651],
                [107.6322336, 15.3232809],
                [107.6325509, 15.3224989],
                [107.6325272, 15.3219114],
                [107.6325504, 15.3211487],
                [107.6330686, 15.3207296],
                [107.6331803, 15.3205058],
                [107.6331416, 15.3200298],
                [107.6334641, 15.3196606],
                [107.6337719, 15.3194187],
                [107.6343462, 15.3195708],
                [107.6348042, 15.3195654],
                [107.6349989, 15.319436],
                [107.6349777, 15.319055],
                [107.6347538, 15.3181522],
                [107.6344, 15.3173304],
                [107.6340219, 15.3158735],
                [107.6338498, 15.3151924],
                [107.6340225, 15.3146186],
                [107.6337962, 15.3135253],
                [107.6335616, 15.3130832],
                [107.6329031, 15.3127415],
                [107.6320591, 15.3119573],
                [107.6307402, 15.311131],
                [107.6292477, 15.310799],
                [107.6281191, 15.3107964],
                [107.6268139, 15.3110968],
                [107.6259533, 15.3112211],
                [107.6256115, 15.3112306],
                [107.6249573, 15.3112507],
                [107.6244308, 15.3111625],
                [107.6241742, 15.3108632],
                [107.6242915, 15.30935],
                [107.6238491, 15.3082025],
                [107.6237956, 15.3070317],
                [107.6231988, 15.3059805],
                [107.6219747, 15.3045588],
                [107.6210548, 15.3025666],
                [107.6204565, 15.3014019],
                [107.6205803, 15.300399],
                [107.6215726, 15.2988378],
                [107.6219076, 15.2976057],
                [107.6220249, 15.2960926],
                [107.622034, 15.295261],
                [107.6214269, 15.2949469],
                [107.6201957, 15.2945079],
                [107.619163, 15.2943877],
                [107.6184383, 15.2939994],
                [107.6181919, 15.292963],
                [107.6177945, 15.2923063],
                [107.6166142, 15.2912618],
                [107.6163095, 15.2902261],
                [107.6155826, 15.2880993],
                [107.6153567, 15.2871383],
                [107.6155533, 15.2857377],
                [107.6159659, 15.2840222],
                [107.6159916, 15.2839708],
                [107.6160001, 15.2839101],
                [107.6159852, 15.2838389],
                [107.6159523, 15.2838059],
                [107.6157559, 15.2837242],
                [107.6157216, 15.2836641],
                [107.6157012, 15.2835912],
                [107.615741, 15.2835058],
                [107.616033, 15.2831853],
                [107.6162298, 15.2828515],
                [107.6163985, 15.2824233],
                [107.6164617, 15.2821185],
                [107.6165013, 15.2818771],
                [107.6165355, 15.2815156],
                [107.6165644, 15.2813204],
                [107.6166457, 15.2811643],
                [107.6167349, 15.2810688],
                [107.6167924, 15.2809738],
                [107.6168236, 15.280936],
                [107.6168364, 15.2808586],
                [107.6168147, 15.2806683],
                [107.6167713, 15.2805835],
                [107.6167633, 15.2805627],
                [107.6166441, 15.2804696],
                [107.6164348, 15.2803911],
                [107.6163163, 15.2803522],
                [107.6162243, 15.2803088],
                [107.6161381, 15.2802619],
                [107.6160657, 15.280196],
                [107.6160125, 15.2801144],
                [107.6159788, 15.2800155],
                [107.6159853, 15.2799494],
                [107.6160114, 15.2798431],
                [107.6160245, 15.2797756],
                [107.6160648, 15.2796989],
                [107.6162149, 15.2795669],
                [107.6162938, 15.2795179],
                [107.616393, 15.2794696],
                [107.6164952, 15.2794183],
                [107.6165515, 15.2793779],
                [107.6166916, 15.279262],
                [107.6167591, 15.2791617],
                [107.6168346, 15.2790378],
                [107.6168812, 15.2788754],
                [107.6169071, 15.2787199],
                [107.6168891, 15.2783964],
                [107.6168529, 15.2782755],
                [107.6168302, 15.2781206],
                [107.6168426, 15.2779991],
                [107.6169169, 15.277851],
                [107.616957, 15.2777874],
                [107.6169795, 15.2777157],
                [107.6169784, 15.2776281],
                [107.6169534, 15.2775679],
                [107.6169053, 15.277516],
                [107.6168342, 15.2774592],
                [107.616711, 15.2773628],
                [107.6166744, 15.27732],
                [107.6166385, 15.2772463],
                [107.6166099, 15.2771521],
                [107.6165883, 15.2770917],
                [107.6163322, 15.2761372],
                [107.6164441, 15.2751693],
                [107.6163037, 15.2747075],
                [107.6165593, 15.2740073],
                [107.6161684, 15.2734683],
                [107.6160235, 15.2724247],
                [107.6156437, 15.2714052],
                [107.61573, 15.270822],
                [107.6160823, 15.2707087],
                [107.6168201, 15.2711475],
                [107.6184599, 15.2709632],
                [107.6186615, 15.2683356],
                [107.6191765, 15.2672178],
                [107.6202678, 15.266474],
                [107.620529, 15.2638021],
                [107.620915, 15.2631192],
                [107.6198273, 15.2618123],
                [107.6203431, 15.2602596],
                [107.6198364, 15.2567785],
                [107.619197, 15.2558453],
                [107.6198439, 15.2526148],
                [107.6208392, 15.2518201],
                [107.6204031, 15.2499062],
                [107.6204256, 15.2497139],
                [107.6205164, 15.2495272],
                [107.6206121, 15.2494047],
                [107.620857, 15.2491914],
                [107.6210756, 15.2489792],
                [107.6212497, 15.2486487],
                [107.6212743, 15.2485216],
                [107.6212473, 15.2484458],
                [107.6211422, 15.2484047],
                [107.6209942, 15.2484149],
                [107.6207775, 15.2484936],
                [107.6206385, 15.2485206],
                [107.6205339, 15.2485133],
                [107.6204375, 15.2484637],
                [107.620288, 15.248347],
                [107.6201208, 15.2482051],
                [107.6199537, 15.2480718],
                [107.619811, 15.2478111],
                [107.6197628, 15.247431],
                [107.6196805, 15.247119],
                [107.6195368, 15.2467653],
                [107.6194118, 15.2465214],
                [107.6193578, 15.2463698],
                [107.6193379, 15.2461754],
                [107.6193699, 15.2459466],
                [107.6194453, 15.2457089],
                [107.6196425, 15.2454528],
                [107.6200385, 15.2450843],
                [107.6204954, 15.2447068],
                [107.620859, 15.2445333],
                [107.6210751, 15.2443954],
                [107.6211689, 15.2442336],
                [107.6211925, 15.2440388],
                [107.6211984, 15.2438102],
                [107.6211945, 15.2434973],
                [107.6213196, 15.2430615],
                [107.6214963, 15.2425687],
                [107.6217699, 15.2421594],
                [107.6221048, 15.2417748],
                [107.6222936, 15.2415442],
                [107.6225275, 15.2414484],
                [107.6228317, 15.241394],
                [107.6230484, 15.2413155],
                [107.6231341, 15.2412044],
                [107.623115, 15.2410693],
                [107.6229903, 15.2408423],
                [107.6229631, 15.240758],
                [107.6229708, 15.2406734],
                [107.623065, 15.2405539],
                [107.6233221, 15.2402124],
                [107.6237655, 15.2396612],
                [107.6241247, 15.2391324],
                [107.6241402, 15.2389715],
                [107.6240765, 15.2387608],
                [107.6242206, 15.2384154],
                [107.6243518, 15.2380932],
                [107.6244087, 15.2377626],
                [107.6244718, 15.2371499],
                [107.6244573, 15.2366031],
                [107.62443, 15.2362738],
                [107.6242939, 15.2358707],
                [107.6241163, 15.2352284],
                [107.623963, 15.2346908],
                [107.6239967, 15.2342932],
                [107.6242771, 15.2338928],
                [107.6245896, 15.2335821],
                [107.6246952, 15.2333934],
                [107.6247058, 15.2330111],
                [107.6246913, 15.2324569],
                [107.6247271, 15.2322318],
                [107.6250856, 15.2319203],
                [107.6255883, 15.2313974],
                [107.6258061, 15.2309227],
                [107.6260491, 15.2306203],
                [107.6264485, 15.2304808],
                [107.6268124, 15.2305451],
                [107.6270172, 15.2305996],
                [107.627681, 15.2307966],
                [107.6278126, 15.2308416],
                [107.6280582, 15.2309016],
                [107.6283824, 15.2308978],
                [107.6287856, 15.2308862],
                [107.6291023, 15.2308755],
                [107.6294271, 15.2309206],
                [107.6296374, 15.2310442],
                [107.6298269, 15.231224],
                [107.6300016, 15.231362],
                [107.6302471, 15.231415],
                [107.6307878, 15.2314508],
                [107.631379, 15.2314999],
                [107.6317823, 15.231495],
                [107.6321559, 15.2314208],
                [107.6324784, 15.2312909],
                [107.6327061, 15.2310643],
                [107.6328831, 15.2308172],
                [107.6329963, 15.2306548],
                [107.6330522, 15.2305142],
                [107.6330579, 15.230395],
                [107.6330422, 15.2303394],
                [107.6330127, 15.2302346],
                [107.6328596, 15.2300824],
                [107.6326561, 15.2299377],
                [107.6322854, 15.2296691],
                [107.6318642, 15.229394],
                [107.6315879, 15.2291873],
                [107.631347, 15.228931],
                [107.6311203, 15.2286467],
                [107.6310164, 15.2284098],
                [107.6308837, 15.2281593],
                [107.6307299, 15.2279582],
                [107.6305916, 15.2278338],
                [107.6303379, 15.2277107],
                [107.629788, 15.2275072],
                [107.6293622, 15.2274352],
                [107.6289805, 15.2274467],
                [107.6286855, 15.227464],
                [107.6283757, 15.2274607],
                [107.6281516, 15.2274004],
                [107.6279343, 15.227305],
                [107.6276731, 15.2271399],
                [107.6274842, 15.2270161],
                [107.6269137, 15.227017],
                [107.6217285, 15.2270152],
                [107.6204766, 15.2264551],
                [107.6201501, 15.2261232],
                [107.6195015, 15.2247251],
                [107.6196556, 15.2226415],
                [107.6201641, 15.2201819],
                [107.6203063, 15.2179521],
                [107.621388, 15.2172263],
                [107.6215018, 15.2170586],
                [107.6216032, 15.2167769],
                [107.6217295, 15.2163803],
                [107.6217665, 15.2161887],
                [107.6216959, 15.2158682],
                [107.6216163, 15.215706],
                [107.6215208, 15.215414],
                [107.6214919, 15.2151975],
                [107.6215298, 15.215057],
                [107.6216576, 15.2148132],
                [107.6217061, 15.2144939],
                [107.6216653, 15.214367],
                [107.6214799, 15.2142289],
                [107.6211747, 15.2140485],
                [107.6211237, 15.214],
                [107.6210844, 15.2139339],
                [107.6208146, 15.2134463],
                [107.6204552, 15.2130171],
                [107.6201826, 15.2125831],
                [107.6200305, 15.2121845],
                [107.6199878, 15.2121278],
                [107.6199286, 15.2120999],
                [107.6198138, 15.2120559],
                [107.6195704, 15.2120102],
                [107.6194823, 15.2119989],
                [107.6193773, 15.2120125],
                [107.6191927, 15.2120309],
                [107.6189622, 15.2121167],
                [107.618637, 15.2123372],
                [107.6184813, 15.2124666],
                [107.6182657, 15.2127215],
                [107.6180938, 15.21295],
                [107.6178785, 15.2132003],
                [107.6177267, 15.2132869],
                [107.6176034, 15.2133308],
                [107.6175016, 15.2133461],
                [107.6173633, 15.2133478],
                [107.6172394, 15.2133422],
                [107.6170936, 15.2133226],
                [107.616867, 15.2132615],
                [107.6165594, 15.2131236],
                [107.6161537, 15.2129939],
                [107.6159128, 15.2129401],
                [107.6157597, 15.2129277],
                [107.6156359, 15.2129292],
                [107.6154829, 15.2129238],
                [107.61533, 15.2129256],
                [107.6151769, 15.2129133],
                [107.6149945, 15.212887],
                [107.6147586, 15.2127677],
                [107.6146097, 15.2126799],
                [107.6143292, 15.2125259],
                [107.6142247, 15.2124643],
                [107.6140043, 15.2123112],
                [107.6136265, 15.212082],
                [107.6134944, 15.2119987],
                [107.6133769, 15.2119152],
                [107.6132155, 15.211818],
                [107.6130982, 15.2117557],
                [107.6130102, 15.2117072],
                [107.6128788, 15.2116804],
                [107.6127116, 15.2117035],
                [107.6125956, 15.2117545],
                [107.6123135, 15.2118921],
                [107.6120881, 15.2119498],
                [107.6119218, 15.2120241],
                [107.611711, 15.2120619],
                [107.6115072, 15.2120643],
                [107.6114415, 15.2120509],
                [107.6113826, 15.211995],
                [107.6113309, 15.211939],
                [107.6112937, 15.2118756],
                [107.6112563, 15.2117912],
                [107.6112114, 15.2116927],
                [107.6110383, 15.2113474],
                [107.6108801, 15.2111092],
                [107.6108127, 15.2109542],
                [107.6107156, 15.2107572],
                [107.6105675, 15.2105608],
                [107.6103687, 15.2103791],
                [107.6101851, 15.2102539],
                [107.6099725, 15.2101431],
                [107.609521, 15.2098441],
                [107.6093597, 15.2097539],
                [107.6090816, 15.209651],
                [107.6088259, 15.2095761],
                [107.6087456, 15.2095559],
                [107.6086943, 15.2095352],
                [107.6086432, 15.2095216],
                [107.6085773, 15.2094941],
                [107.6085041, 15.2094667],
                [107.6084309, 15.2094322],
                [107.6082841, 15.2093348],
                [107.608225, 15.2092718],
                [107.6081506, 15.2091452],
                [107.6079405, 15.2089206],
                [107.6077196, 15.2086675],
                [107.6075507, 15.2085422],
                [107.6072123, 15.2082767],
                [107.6068876, 15.2081305],
                [107.6065317, 15.2080952],
                [107.6064858, 15.208076],
                [107.6064034, 15.207988],
                [107.6063041, 15.2077523],
                [107.6062719, 15.2074694],
                [107.6061832, 15.2073022],
                [107.6060547, 15.207156],
                [107.6059776, 15.2070707],
                [107.6057609, 15.2069501],
                [107.6054042, 15.2067942],
                [107.6052378, 15.2066607],
                [107.6051477, 15.2065386],
                [107.605044, 15.2063428],
                [107.6049779, 15.206122],
                [107.6048486, 15.2059142],
                [107.6047458, 15.2057924],
                [107.6045534, 15.2055975],
                [107.604362, 15.2054889],
                [107.6040567, 15.2053818],
                [107.6038276, 15.2052982],
                [107.6036877, 15.2052506],
                [107.6035986, 15.2052147],
                [107.6034751, 15.2052058],
                [107.6030272, 15.2049825],
                [107.6027723, 15.2044299],
                [107.6026747, 15.2040203],
                [107.6025597, 15.2038242],
                [107.6024707, 15.2038823],
                [107.6023849, 15.2039696],
                [107.6022567, 15.2041303],
                [107.6021446, 15.2042336],
                [107.6018905, 15.204419],
                [107.6015763, 15.2045566],
                [107.6014687, 15.2046179],
                [107.601208, 15.2047301],
                [107.6010216, 15.2047929],
                [107.6007114, 15.2049419],
                [107.6004637, 15.2051025],
                [107.6002038, 15.2052754],
                [107.6000064, 15.2054594],
                [107.5999235, 15.2055814],
                [107.5998469, 15.2056797],
                [107.599735, 15.2057173],
                [107.5995494, 15.2058407],
                [107.599439, 15.2059997],
                [107.599268, 15.2063048],
                [107.5990701, 15.2064527],
                [107.5989592, 15.206563],
                [107.5989852, 15.2066477],
                [107.5992359, 15.2067417],
                [107.5995251, 15.2069203],
                [107.599564, 15.2070411],
                [107.5995407, 15.2071748],
                [107.5992925, 15.2072868],
                [107.5992198, 15.2074574],
                [107.5990121, 15.2078237],
                [107.5987807, 15.2082993],
                [107.5987699, 15.2084328],
                [107.5988214, 15.2085534],
                [107.59881, 15.2086505],
                [107.5984542, 15.209152],
                [107.598439, 15.2092027],
                [107.5982681, 15.2092389],
                [107.5980941, 15.2092895],
                [107.5978356, 15.2093076],
                [107.597708, 15.2093545],
                [107.5976091, 15.2094406],
                [107.5972751, 15.2094855],
                [107.5971108, 15.209507],
                [107.5970374, 15.2096291],
                [107.5969287, 15.2099215],
                [107.5968358, 15.2099639],
                [107.5968192, 15.2101531],
                [107.5971202, 15.2102709],
                [107.5971338, 15.2103678],
                [107.5970479, 15.2104901],
                [107.5969615, 15.2105638],
                [107.5968152, 15.2108444],
                [107.5967537, 15.2109178],
                [107.5966415, 15.2109313],
                [107.5964764, 15.2109639],
                [107.5961099, 15.2110254],
                [107.5959929, 15.2110757],
                [107.5956465, 15.211116],
                [107.59559, 15.2111203],
                [107.5954195, 15.2111052],
                [107.5949846, 15.2110646],
                [107.594873, 15.2110602],
                [107.5946792, 15.211051],
                [107.594579, 15.2110179],
                [107.5944367, 15.2109055],
                [107.5942408, 15.2107252],
                [107.5940765, 15.2104932],
                [107.5938969, 15.2103367],
                [107.5937848, 15.2101974],
                [107.5937038, 15.2101471],
                [107.5936523, 15.2101113],
                [107.5934903, 15.2100825],
                [107.5933577, 15.2100822],
                [107.5932089, 15.2101096],
                [107.5931153, 15.2101335],
                [107.5930506, 15.2101285],
                [107.5929974, 15.2100891],
                [107.5929023, 15.209999],
                [107.5928592, 15.2098341],
                [107.5928353, 15.2095662],
                [107.5928029, 15.2093099],
                [107.5927943, 15.2090876],
                [107.5928152, 15.2088763],
                [107.5928526, 15.2085791],
                [107.5929459, 15.2082928],
                [107.5929973, 15.2081668],
                [107.5931, 15.207926],
                [107.5932603, 15.2075932],
                [107.5933744, 15.2073238],
                [107.5934793, 15.2070202],
                [107.593525, 15.2069228],
                [107.5937391, 15.2066635],
                [107.5939479, 15.2064558],
                [107.5941385, 15.2062026],
                [107.5942534, 15.2059959],
                [107.5943029, 15.2057272],
                [107.5943065, 15.2055446],
                [107.5942812, 15.2051513],
                [107.5942741, 15.2050544],
                [107.5942378, 15.2049691],
                [107.5942019, 15.2049126],
                [107.5941068, 15.204811],
                [107.5940066, 15.2047779],
                [107.5935622, 15.2044465],
                [107.5935262, 15.2043785],
                [107.5934843, 15.2043162],
                [107.5934125, 15.2042029],
                [107.5933415, 15.2041524],
                [107.5932705, 15.2041132],
                [107.5932118, 15.2041025],
                [107.5930944, 15.2041095],
                [107.5930242, 15.2041218],
                [107.5929483, 15.2041626],
                [107.5928962, 15.204226],
                [107.5928505, 15.2043234],
                [107.5927962, 15.2044439],
                [107.5927284, 15.2046614],
                [107.5926188, 15.2048224],
                [107.5924794, 15.2049438],
                [107.5922987, 15.205043],
                [107.5921, 15.2051193],
                [107.5918952, 15.2051731],
                [107.591643, 15.2051874],
                [107.5914434, 15.2051898],
                [107.5913316, 15.2051682],
                [107.5912433, 15.2051407],
                [107.5911488, 15.2050961],
                [107.5910599, 15.2050286],
                [107.5908932, 15.2048367],
                [107.5907975, 15.2046951],
                [107.5907436, 15.2045988],
                [107.5907013, 15.2042627],
                [107.5906988, 15.2040516],
                [107.5906796, 15.2039321],
                [107.5906835, 15.2037723],
                [107.5906589, 15.2036813],
                [107.5905986, 15.2035451],
                [107.5904146, 15.203376],
                [107.5902163, 15.2032529],
                [107.5899918, 15.2031299],
                [107.5897389, 15.2030872],
                [107.5893094, 15.2030066],
                [107.5890207, 15.2029186],
                [107.5889025, 15.2028515],
                [107.5888018, 15.2027842],
                [107.5887129, 15.2027054],
                [107.5886529, 15.2026034],
                [107.5885808, 15.2024673],
                [107.5885323, 15.2023423],
                [107.5885087, 15.2020916],
                [107.5885121, 15.2018862],
                [107.5884514, 15.2012479],
                [107.588415, 15.2011515],
                [107.5883437, 15.2010838],
                [107.5882671, 15.2010505],
                [107.5881787, 15.2010286],
                [107.5880849, 15.2010297],
                [107.5879676, 15.2010425],
                [107.587833, 15.2010782],
                [107.587605, 15.201155],
                [107.5871233, 15.2013659],
                [107.5870359, 15.2014183],
                [107.586914, 15.2015396],
                [107.5868447, 15.2016202],
                [107.5867932, 15.2017292],
                [107.58676, 15.2019007],
                [107.5867203, 15.2020153],
                [107.5866748, 15.2021299],
                [107.5866287, 15.2021989],
                [107.5865361, 15.202314],
                [107.5863489, 15.2023618],
                [107.5862198, 15.2023632],
                [107.5861198, 15.202353],
                [107.5860138, 15.20232],
                [107.5859194, 15.2022869],
                [107.5858604, 15.202259],
                [107.5858073, 15.2022369],
                [107.5857834, 15.2022086],
                [107.5857713, 15.2021745],
                [107.5857707, 15.2021231],
                [107.5857819, 15.2020717],
                [107.5857986, 15.2020086],
                [107.5858039, 15.2019516],
                [107.5857971, 15.2018889],
                [107.5857671, 15.2018208],
                [107.5856686, 15.2016907],
                [107.5853491, 15.201489],
                [107.5851012, 15.2013778],
                [107.5849537, 15.201311],
                [107.5847888, 15.2012673],
                [107.5846121, 15.2012237],
                [107.5844887, 15.201208],
                [107.5843768, 15.2011808],
                [107.5842063, 15.2011599],
                [107.5838483, 15.201164],
                [107.5836452, 15.201115],
                [107.5833736, 15.200987],
                [107.583249, 15.20088],
                [107.583183, 15.2007609],
                [107.5831339, 15.2005846],
                [107.5831142, 15.2004138],
                [107.5831188, 15.2003167],
                [107.5831238, 15.2002368],
                [107.5831583, 15.2001793],
                [107.5831873, 15.2001505],
                [107.5832459, 15.2001384],
                [107.5833164, 15.2001433],
                [107.5833753, 15.2001654],
                [107.5834404, 15.2002046],
                [107.5835172, 15.2002551],
                [107.5836, 15.2002997],
                [107.5837002, 15.2003329],
                [107.5837824, 15.200332],
                [107.5838583, 15.2003026],
                [107.5839805, 15.2002042],
                [107.5843364, 15.1997892],
                [107.5844227, 15.1996457],
                [107.5844452, 15.1995655],
                [107.5844441, 15.1994856],
                [107.5844372, 15.1993945],
                [107.5843956, 15.1993494],
                [107.5843364, 15.1993101],
                [107.5842597, 15.1992768],
                [107.5841297, 15.1992097],
                [107.5840471, 15.1991765],
                [107.5837991, 15.1990595],
                [107.5835506, 15.198897],
                [107.5834436, 15.1987841],
                [107.5834077, 15.1987217],
                [107.5833774, 15.1986479],
                [107.5833706, 15.1985682],
                [107.583375, 15.1984541],
                [107.5834029, 15.1983396],
                [107.5834316, 15.1982822],
                [107.5835129, 15.1982014],
                [107.5836529, 15.198137],
                [107.5838279, 15.1980438],
                [107.583944, 15.1979341],
                [107.5839781, 15.1978481],
                [107.5839776, 15.1978053],
                [107.5839503, 15.1977287],
                [107.5838791, 15.1976696],
                [107.5837686, 15.1976366],
                [107.5835829, 15.1975702],
                [107.5834149, 15.1975123],
                [107.5832643, 15.1974371],
                [107.5830596, 15.1972597],
                [107.5828759, 15.1970417],
                [107.582872, 15.1970307],
                [107.5827817, 15.1968521],
                [107.5827631, 15.1967752],
                [107.5827535, 15.1967069],
                [107.5827522, 15.1965957],
                [107.5827743, 15.1962446],
                [107.582773, 15.1961334],
                [107.5827632, 15.1960479],
                [107.5826814, 15.1958435],
                [107.5827717, 15.1958142],
                [107.5828954, 15.1957522],
                [107.5829434, 15.1957462],
                [107.5829774, 15.1957485],
                [107.5830171, 15.1957591],
                [107.5834158, 15.1959909],
                [107.5834584, 15.1960069],
                [107.583528, 15.1960611],
                [107.5835704, 15.1961431],
                [107.5837143, 15.1964656],
                [107.5837941, 15.1965115],
                [107.5839022, 15.196568],
                [107.58405, 15.1966295],
                [107.5841124, 15.1966425],
                [107.5842284, 15.1966411],
                [107.5842988, 15.1966184],
                [107.5843884, 15.1965412],
                [107.5844357, 15.1964942],
                [107.584521, 15.1964468],
                [107.584645, 15.1964454],
                [107.5852733, 15.1963083],
                [107.5853492, 15.1962703],
                [107.5854632, 15.1962227],
                [107.5856057, 15.1961746],
                [107.5858808, 15.1960323],
                [107.5862426, 15.1959632],
                [107.5865572, 15.1959318],
                [107.5867865, 15.1959477],
                [107.5869827, 15.1959918],
                [107.5870979, 15.1960461],
                [107.5872136, 15.1961375],
                [107.5872621, 15.1962019],
                [107.5872916, 15.1962758],
                [107.5873022, 15.1963685],
                [107.5873224, 15.196461],
                [107.5873329, 15.196535],
                [107.5873338, 15.1966093],
                [107.5873439, 15.1966556],
                [107.5873545, 15.1967389],
                [107.5873646, 15.1967851],
                [107.5873939, 15.1968405],
                [107.587452, 15.1969048],
                [107.5875195, 15.1969689],
                [107.587577, 15.1969868],
                [107.5877107, 15.1969852],
                [107.5878629, 15.1969557],
                [107.5879868, 15.1969356],
                [107.5880527, 15.1968606],
                [107.5881363, 15.1966743],
                [107.5882289, 15.196432],
                [107.5883403, 15.1961803],
                [107.588453, 15.1960305],
                [107.5886039, 15.1958804],
                [107.5888692, 15.1957196],
                [107.5891153, 15.1955499],
                [107.5892755, 15.1953811],
                [107.589388, 15.1952128],
                [107.5894905, 15.1950168],
                [107.5895746, 15.1948674],
                [107.5896306, 15.1947648],
                [107.5896967, 15.1947083],
                [107.5899351, 15.194687],
                [107.5900882, 15.1947131],
                [107.5901741, 15.1947121],
                [107.590336, 15.1946917],
                [107.5905695, 15.1946612],
                [107.5907694, 15.1946218],
                [107.5910651, 15.1945998],
                [107.5912172, 15.1945517],
                [107.591359, 15.1944387],
                [107.5914718, 15.1942983],
                [107.5915466, 15.1941676],
                [107.5917811, 15.1938402],
                [107.5919222, 15.1936624],
                [107.5919488, 15.1935043],
                [107.5919763, 15.1934113],
                [107.5920609, 15.193299],
                [107.5922766, 15.1929904],
                [107.5925502, 15.1927275],
                [107.592906, 15.1925565],
                [107.5930006, 15.1924904],
                [107.5930848, 15.1923503],
                [107.5931124, 15.1922572],
                [107.5931303, 15.1921643],
                [107.5931482, 15.1920621],
                [107.5931657, 15.1919412],
                [107.593193, 15.1918297],
                [107.5932673, 15.1916617],
                [107.5933046, 15.1915964],
                [107.5934172, 15.1914282],
                [107.5935012, 15.1912788],
                [107.5935569, 15.1911483],
                [107.593575, 15.1910646],
                [107.593574, 15.1909904],
                [107.5935922, 15.190916],
                [107.5936199, 15.1908321],
                [107.593639, 15.190781],
                [107.5937154, 15.1906729],
                [107.5937848, 15.190654],
                [107.5938896, 15.1906435],
                [107.5940523, 15.1906788],
                [107.5941769, 15.1907237],
                [107.5943112, 15.1907778],
                [107.5944264, 15.1908415],
                [107.594532, 15.1908866],
                [107.5946184, 15.1909227],
                [107.5947427, 15.190949],
                [107.5950958, 15.190945],
                [107.5953053, 15.1909055],
                [107.5954767, 15.1908664],
                [107.595695, 15.1907712],
                [107.5959897, 15.190675],
                [107.5961891, 15.1905892],
                [107.5963118, 15.1904765],
                [107.5963771, 15.1903552],
                [107.5963857, 15.1902808],
                [107.5963749, 15.1901789],
                [107.5963253, 15.1900218],
                [107.5962573, 15.1899205],
                [107.5961407, 15.1897549],
                [107.5961009, 15.1896256],
                [107.5960997, 15.1895328],
                [107.5961083, 15.1894493],
                [107.5961456, 15.1893745],
                [107.5961924, 15.1892998],
                [107.5963999, 15.1890933],
                [107.5965974, 15.1888592],
                [107.5967296, 15.1887463],
                [107.596848, 15.1886707],
                [107.596943, 15.1886326],
                [107.5970671, 15.1886404],
                [107.5971531, 15.1886487],
                [107.5974018, 15.188683],
                [107.5976408, 15.1887266],
                [107.5977648, 15.1887159],
                [107.5979073, 15.1886679],
                [107.5979924, 15.1886019],
                [107.5980298, 15.1885272],
                [107.5980573, 15.1884436],
                [107.5980658, 15.1883599],
                [107.5980648, 15.1882764],
                [107.5980426, 15.1880169],
                [107.5980214, 15.1878503],
                [107.5979687, 15.1874427],
                [107.597887, 15.1869984],
                [107.5978368, 15.1867949],
                [107.5977629, 15.1866102],
                [107.5977331, 15.1865178],
                [107.5977034, 15.1864347],
                [107.5976931, 15.1863699],
                [107.5976918, 15.1862586],
                [107.5977001, 15.1861565],
                [107.5977367, 15.1860355],
                [107.5977927, 15.1859235],
                [107.597877, 15.1857926],
                [107.5979704, 15.185634],
                [107.5980644, 15.1855122],
                [107.5981677, 15.1853719],
                [107.5982906, 15.1852777],
                [107.5983951, 15.1852394],
                [107.5985095, 15.1852287],
                [107.5993078, 15.1849598],
                [107.5994217, 15.1849029],
                [107.5995169, 15.1848833],
                [107.599593, 15.1848638],
                [107.5996882, 15.1848442],
                [107.5997836, 15.1848431],
                [107.5998508, 15.1848702],
                [107.5999373, 15.1849248],
                [107.5999861, 15.1850077],
                [107.6000066, 15.185128],
                [107.6001266, 15.1855718],
                [107.6002342, 15.185784],
                [107.6003698, 15.1859494],
                [107.6005053, 15.1861055],
                [107.6006115, 15.1861971],
                [107.6007172, 15.1862607],
                [107.6007941, 15.186297],
                [107.6008707, 15.1863239],
                [107.6009854, 15.1863318],
                [107.6010661, 15.1863031],
                [107.6011417, 15.1862373],
                [107.6011694, 15.1861628],
                [107.6012808, 15.1859111],
                [107.6014184, 15.185455],
                [107.6014345, 15.1852043],
                [107.6014326, 15.1850559],
                [107.6014501, 15.1849258],
                [107.6014575, 15.1847495],
                [107.6014748, 15.1846102],
                [107.6015308, 15.1845075],
                [107.6018144, 15.1842724],
                [107.6020831, 15.1840095],
                [107.6022531, 15.1838591],
                [107.6024703, 15.1836711],
                [107.6025932, 15.1835769],
                [107.6027049, 15.183353],
                [107.6027785, 15.1831295],
                [107.6028149, 15.1829807],
                [107.6028416, 15.1828226],
                [107.6028494, 15.1826742],
                [107.6029809, 15.1821161],
                [107.6029892, 15.1820232],
                [107.6030074, 15.1819488],
                [107.603035, 15.1818557],
                [107.603081, 15.1817161],
                [107.6031367, 15.1815856],
                [107.6037233, 15.1807904],
                [107.6038086, 15.180743],
                [107.6038655, 15.1807053],
                [107.6041457, 15.1802104],
                [107.6041634, 15.1800989],
                [107.6042283, 15.1799405],
                [107.6042933, 15.1798005],
                [107.6044149, 15.179595],
                [107.6044898, 15.1794736],
                [107.6045647, 15.1793615],
                [107.6047984, 15.1789599],
                [107.6048538, 15.1788107],
                [107.6048716, 15.1786993],
                [107.6048783, 15.1784674],
                [107.6049138, 15.1782535],
                [107.6049647, 15.1781231],
                [107.6050114, 15.1780483],
                [107.6051725, 15.1779445],
                [107.6053339, 15.1778777],
                [107.6053816, 15.1778771],
                [107.6054113, 15.177776],
                [107.6054377, 15.1776424],
                [107.60551, 15.1774263],
                [107.6055642, 15.1772741],
                [107.6056467, 15.1771246],
                [107.605723, 15.1769934],
                [107.6058959, 15.1766844],
                [107.6059985, 15.1764717],
                [107.6061709, 15.176231],
                [107.6061894, 15.1762076],
                [107.6068472, 15.1749763],
                [107.6072658, 15.1744013],
                [107.607475, 15.1741149],
                [107.6074948, 15.1740192],
                [107.6075219, 15.173937],
                [107.6075421, 15.1738687],
                [107.6075582, 15.1737525],
                [107.6076964, 15.1735735],
                [107.6078976, 15.1733871],
                [107.608085, 15.1732143],
                [107.6082033, 15.1731379],
                [107.6084342, 15.1730739],
                [107.6087068, 15.1729888],
                [107.6090703, 15.1728618],
                [107.60937, 15.1727015],
                [107.6096208, 15.1725484],
                [107.6099339, 15.1723335],
                [107.6100833, 15.1722157],
                [107.6101941, 15.1720917],
                [107.6103048, 15.1719608],
                [107.6103946, 15.1718438],
                [107.6104565, 15.1717408],
                [107.6105253, 15.1716172],
                [107.6105729, 15.1715075],
                [107.6106347, 15.1713908],
                [107.6108208, 15.1711157],
                [107.6108261, 15.1709793],
                [107.6108405, 15.1707131],
                [107.6108811, 15.1705967],
                [107.6108935, 15.1704601],
                [107.6109066, 15.1703849],
                [107.6109196, 15.1703029],
                [107.6109466, 15.1702207],
                [107.6109949, 15.170152],
                [107.6110575, 15.1700966],
                [107.6110922, 15.1700758],
                [107.6112317, 15.1700059],
                [107.6113289, 15.1699162],
                [107.6113842, 15.1698473],
                [107.6114256, 15.1697924],
                [107.6115014, 15.1696755],
                [107.6115563, 15.1695793],
                [107.6116205, 15.1693739],
                [107.6116253, 15.1691965],
                [107.6116236, 15.1690533],
                [107.6116148, 15.1689102],
                [107.6115989, 15.1687603],
                [107.6115906, 15.168658],
                [107.6115876, 15.1684125],
                [107.6116007, 15.1683305],
                [107.6116475, 15.168139],
                [107.6116941, 15.1679406],
                [107.6117276, 15.1678107],
                [107.6118207, 15.1676799],
                [107.6118479, 15.1676046],
                [107.6118542, 15.1675432],
                [107.6118252, 15.1674752],
                [107.611768, 15.1673873],
                [107.6116899, 15.1673131],
                [107.6115832, 15.1671984],
                [107.6115471, 15.1671101],
                [107.6115251, 15.1670354],
                [107.6115102, 15.1669673],
                [107.6115025, 15.166906],
                [107.6115016, 15.1668446],
                [107.6114394, 15.1663474],
                [107.6114486, 15.1662382],
                [107.6114898, 15.1661626],
                [107.6117036, 15.1652871],
                [107.6117377, 15.1652048],
                [107.6117858, 15.1651223],
                [107.6118966, 15.1650051],
                [107.6120464, 15.1649148],
                [107.6121653, 15.1648861],
                [107.6129874, 15.1643922],
                [107.6130429, 15.1643438],
                [107.6130974, 15.1642212],
                [107.6135504, 15.1630897],
                [107.6136333, 15.1629865],
                [107.6136811, 15.1628836],
                [107.6137009, 15.162781],
                [107.6136985, 15.16259],
                [107.6137047, 15.1625149],
                [107.6137106, 15.1624262],
                [107.6137306, 15.1623441],
                [107.6137509, 15.1622756],
                [107.6138475, 15.1621449],
                [107.6139856, 15.161966],
                [107.6140999, 15.1618418],
                [107.6141758, 15.1617318],
                [107.614203, 15.1616702],
                [107.6142647, 15.1615535],
                [107.6143658, 15.1612043],
                [107.6144341, 15.1610603],
                [107.6145231, 15.1608751],
                [107.6146539, 15.160669],
                [107.6152196, 15.1595915],
                [107.6152608, 15.1595228],
                [107.6153233, 15.1594607],
                [107.6153997, 15.1593984],
                [107.6155041, 15.159329],
                [107.6155803, 15.1592462],
                [107.6156217, 15.1591913],
                [107.6156698, 15.159102],
                [107.615704, 15.1590334],
                [107.6157546, 15.158869],
                [107.6157815, 15.15878],
                [107.6157874, 15.1586913],
                [107.6157853, 15.158514],
                [107.6157704, 15.1584392],
                [107.6157556, 15.1583779],
                [107.6157336, 15.1583031],
                [107.6157184, 15.1582079],
                [107.6157174, 15.1581259],
                [107.6157303, 15.1580371],
                [107.6157715, 15.1579617],
                [107.6158336, 15.157879],
                [107.6161031, 15.1575349],
                [107.6161755, 15.1574317],
                [107.616196, 15.1573837],
                [107.6161952, 15.1573224],
                [107.6162746, 15.1569327],
                [107.6163228, 15.156857],
                [107.616334, 15.156625],
                [107.6163189, 15.1565364],
                [107.6163097, 15.1563593],
                [107.6163291, 15.1562295],
                [107.6163553, 15.1560722],
                [107.616378, 15.1559219],
                [107.6164297, 15.1558013],
                [107.6164169, 15.1557567],
                [107.6164922, 15.1555113],
                [107.6166775, 15.1551749],
                [107.6167632, 15.1547169],
                [107.6167763, 15.1546417],
                [107.61679, 15.1546143],
                [107.6168384, 15.1545591],
                [107.6168594, 15.154552],
                [107.6169224, 15.1545445],
                [107.6169998, 15.1545572],
                [107.6173017, 15.1545742],
                [107.6174136, 15.1545388],
                [107.6175247, 15.1544488],
                [107.6176381, 15.1542497],
                [107.6177896, 15.154016],
                [107.617907, 15.1538715],
                [107.617983, 15.1537682],
                [107.6185581, 15.1531749],
                [107.6187591, 15.1529679],
                [107.6190118, 15.1526922],
                [107.619234, 15.1524986],
                [107.6195122, 15.1522907],
                [107.6197555, 15.1521105],
                [107.6198702, 15.1520711],
                [107.6200662, 15.1520006],
                [107.6205406, 15.1520401],
                [107.6205966, 15.1520258],
                [107.6206947, 15.1520246],
                [107.620758, 15.1520375],
                [107.6212395, 15.1520933],
                [107.6213236, 15.1520924],
                [107.621415, 15.1521117],
                [107.6214996, 15.152138],
                [107.6216123, 15.1521708],
                [107.621697, 15.152204],
                [107.6218235, 15.1522298],
                [107.62195, 15.1522351],
                [107.622364, 15.1522303],
                [107.6225184, 15.1522354],
                [107.6226236, 15.1522341],
                [107.6227427, 15.1522191],
                [107.6228896, 15.1521764],
                [107.6230569, 15.1520859],
                [107.6232303, 15.1519201],
                [107.6237822, 15.1514431],
                [107.6239838, 15.1512906],
                [107.6242064, 15.1511312],
                [107.6244994, 15.1509913],
                [107.6249858, 15.1506036],
                [107.6250343, 15.1505485],
                [107.6250971, 15.1505273],
                [107.6256282, 15.1503438],
                [107.6262321, 15.1500912],
                [107.6263016, 15.1500359],
                [107.6263852, 15.149987],
                [107.6264759, 15.1499451],
                [107.6265873, 15.1498824],
                [107.6267056, 15.1497992],
                [107.6267538, 15.1497236],
                [107.6267554, 15.1496863],
                [107.6267582, 15.1496105],
                [107.6268115, 15.1494917],
                [107.6268272, 15.1490338],
                [107.6251903, 15.1483091],
                [107.6247458, 15.1470807],
                [107.6234064, 15.146573],
                [107.6233343, 15.1452009],
                [107.6231141, 15.1433953],
                [107.6250934, 15.1419064],
                [107.6253014, 15.1412783],
                [107.6247992, 15.1391272],
                [107.6236603, 15.1367526],
                [107.6222922, 15.1350738],
                [107.6204581, 15.1341708],
                [107.6187919, 15.134036],
                [107.6169635, 15.133595],
                [107.6152079, 15.1323338],
                [107.6144939, 15.1319955],
                [107.6133688, 15.131427],
                [107.6128312, 15.1311124],
                [107.6119513, 15.1310461],
                [107.6112057, 15.1312707],
                [107.6103617, 15.1317276],
                [107.609498, 15.1323719],
                [107.6080008, 15.1330825],
                [107.6076644, 15.132841],
                [107.6082105, 15.1317749],
                [107.6083802, 15.1311752],
                [107.6085163, 15.1296932],
                [107.609313, 15.1283684],
                [107.610686, 15.1260076],
                [107.6115994, 15.125291],
                [107.6123714, 15.1245445],
                [107.612722, 15.1242688],
                [107.6134962, 15.1239758],
                [107.614687, 15.1231754],
                [107.615747, 15.1228181],
                [107.6162774, 15.1221346],
                [107.6166842, 15.1210182],
                [107.6171407, 15.1207445],
                [107.6181312, 15.1200813],
                [107.6184379, 15.1196918],
                [107.6185419, 15.1193979],
                [107.6186203, 15.1187698],
                [107.618925, 15.1164041],
                [107.6191678, 15.1151471],
                [107.6200294, 15.114301],
                [107.6207087, 15.1135196],
                [107.6215555, 15.1123601],
                [107.6219793, 15.1118116],
                [107.6225112, 15.1107942],
                [107.6226975, 15.1098174],
                [107.6229154, 15.1082889],
                [107.6230792, 15.1075972],
                [107.6230976, 15.1073462],
                [107.623019, 15.1070753],
                [107.6227063, 15.1061591],
                [107.6219845, 15.1052267],
                [107.6214614, 15.1047103],
                [107.6199846, 15.1036403],
                [107.6189991, 15.1031501],
                [107.6180498, 15.1029729],
                [107.6175332, 15.102979],
                [107.6169086, 15.1029444],
                [107.6162834, 15.1028679],
                [107.6157012, 15.1027911],
                [107.6153127, 15.102712],
                [107.6148149, 15.1024878],
                [107.6139512, 15.1022678],
                [107.6134023, 15.1019232],
                [107.6124562, 15.1013199],
                [107.6116977, 15.1009141],
                [107.6111533, 15.1003979],
                [107.6103123, 15.0995505],
                [107.6093054, 15.0981824],
                [107.6088799, 15.0977065],
                [107.6085101, 15.0973972],
                [107.6082828, 15.0971473],
                [107.6072259, 15.0961828],
                [107.6060549, 15.0955245],
                [107.6044856, 15.0944443],
                [107.6033719, 15.0922679],
                [107.6020948, 15.0915001],
                [107.6014137, 15.0910271],
                [107.6008795, 15.0906125],
                [107.599459, 15.089474],
                [107.5986763, 15.088835],
                [107.5982636, 15.0885261],
                [107.5979797, 15.088195],
                [107.5978681, 15.0878618],
                [107.5979105, 15.0875725],
                [107.5980646, 15.0872446],
                [107.598366, 15.0868381],
                [107.5988045, 15.086095],
                [107.6004264, 15.0847625],
                [107.601665, 15.082852],
                [107.6027467, 15.0798244],
                [107.6028175, 15.0789206],
                [107.603054, 15.0780189],
                [107.6034563, 15.0775319],
                [107.6040191, 15.0770031],
                [107.6047629, 15.0762088],
                [107.6055345, 15.075266],
                [107.6062315, 15.073991],
                [107.606597, 15.0734872],
                [107.6069013, 15.0729566],
                [107.6071222, 15.0724943],
                [107.6075762, 15.071895],
                [107.6088305, 15.0705525],
                [107.6092518, 15.069745],
                [107.6095098, 15.0692417],
                [107.6097757, 15.0686584],
                [107.6100507, 15.068258],
                [107.6103793, 15.0677672],
                [107.6107435, 15.0672163],
                [107.6110652, 15.0669102],
                [107.6114936, 15.0667381],
                [107.6121814, 15.0666674],
                [107.613, 15.0667207],
                [107.6137109, 15.0667752],
                [107.6140339, 15.0667923],
                [107.6143772, 15.0667048],
                [107.6147835, 15.066491],
                [107.6149954, 15.0662169],
                [107.6152055, 15.0657963],
                [107.6152644, 15.0653357],
                [107.6154237, 15.064449],
                [107.6170054, 15.0584666],
                [107.6184036, 15.0564335],
                [107.6197271, 15.054055],
                [107.6207854, 15.0525498],
                [107.6215199, 15.0508864],
                [107.6209604, 15.0505395],
                [107.6203541, 15.0503514],
                [107.6199708, 15.0501342],
                [107.6196018, 15.0498667],
                [107.6191801, 15.0493824],
                [107.6184162, 15.0485133],
                [107.616598, 15.0467784],
                [107.6160771, 15.0464081],
                [107.6154699, 15.0460387],
                [107.6150799, 15.0458134],
                [107.6146042, 15.0456307],
                [107.6143388, 15.0456473],
                [107.6140801, 15.045822],
                [107.613871, 15.0460757],
                [107.613328, 15.0469122],
                [107.6125472, 15.0474471],
                [107.6118619, 15.047824],
                [107.6074854, 15.0469345],
                [107.6068773, 15.0464304],
                [107.6064427, 15.04608],
                [107.6061593, 15.0457905],
                [107.605858, 15.0456095],
                [107.6056503, 15.0455927],
                [107.605375, 15.0457014],
                [107.6053792, 15.0460468],
                [107.6045278, 15.0475665],
                [107.6031507, 15.0480437],
                [107.6018062, 15.0489446],
                [107.6010594, 15.0490899],
                [107.5991629, 15.0494266],
                [107.5966232, 15.0509169],
                [107.596046, 15.0512371],
                [107.5954246, 15.0514533],
                [107.5948438, 15.0514808],
                [107.594434, 15.0514018],
                [107.5940446, 15.0512391],
                [107.5935536, 15.0509317],
                [107.5932634, 15.0506281],
                [107.5931013, 15.0498944],
                [107.59304, 15.0494967],
                [107.5931777, 15.0488815],
                [107.5953051, 15.0458868],
                [107.5934657, 15.0452184],
                [107.5928885, 15.0449521],
                [107.5926482, 15.0446862],
                [107.5922166, 15.044125],
                [107.5918813, 15.0433613],
                [107.5912584, 15.0424074],
                [107.5899569, 15.0406662],
                [107.5895185, 15.0400023],
                [107.5893182, 15.0394611],
                [107.5892274, 15.0390649],
                [107.5890577, 15.0386056],
                [107.589264, 15.0376822],
                [107.589308, 15.0372402],
                [107.5891761, 15.0369539],
                [107.5887196, 15.0365486],
                [107.5883073, 15.0362606],
                [107.5878445, 15.0359836],
                [107.5876005, 15.0353802],
                [107.5877375, 15.0348533],
                [107.5876892, 15.033516],
                [107.5877314, 15.0327896],
                [107.5878331, 15.0323076],
                [107.5879334, 15.0317002],
                [107.5880767, 15.0310924],
                [107.5882457, 15.0302576],
                [107.5882263, 15.0299198],
                [107.5881348, 15.0294819],
                [107.587917, 15.029206],
                [107.5876324, 15.0290488],
                [107.587243, 15.0290217],
                [107.5867503, 15.0291233],
                [107.5862314, 15.0292498],
                [107.5856142, 15.0292257],
                [107.5849907, 15.0288805],
                [107.5846316, 15.0285551],
                [107.5839241, 15.0283612],
                [107.5828097, 15.0284181],
                [107.5809433, 15.028831],
                [107.5793373, 15.0287854],
                [107.5778265, 15.0291791],
                [107.577439, 15.0294786],
                [107.5770778, 15.0298376],
                [107.5767574, 15.0303019],
                [107.5766748, 15.0309529],
                [107.5764315, 15.0319202],
                [107.5758033, 15.0322893],
                [107.575019, 15.0326986],
                [107.57286, 15.0338066],
                [107.5710945, 15.035299],
                [107.5705383, 15.0355772],
                [107.5702175, 15.035748],
                [107.5698532, 15.0358775],
                [107.5695948, 15.0358595],
                [107.569313, 15.0356954],
                [107.5687243, 15.0350541],
                [107.5682479, 15.0348086],
                [107.5677198, 15.0347309],
                [107.567415, 15.0347074],
                [107.5668346, 15.0347138],
                [107.5654937, 15.0350927],
                [107.5637405, 15.0361954],
                [107.5617827, 15.0383331],
                [107.5605224, 15.0394791],
                [107.5599194, 15.039838],
                [107.5595654, 15.0399764],
                [107.5591634, 15.0402303],
                [107.5586931, 15.040562],
                [107.5582917, 15.0408639],
                [107.5575558, 15.0414606],
                [107.5568036, 15.0428812],
                [107.5549355, 15.0440444],
                [107.5540922, 15.0444191],
                [107.5533679, 15.0447372],
                [107.5521476, 15.0450877],
                [107.5496598, 15.0460657],
                [107.5480422, 15.0471953],
                [107.5475478, 15.0472427],
                [107.5468481, 15.047323],
                [107.5462869, 15.0473989],
                [107.5458028, 15.0475512],
                [107.5447453, 15.0482113],
                [107.5440425, 15.0488158],
                [107.5435073, 15.0490517],
                [107.5401664, 15.0494826],
                [107.5395462, 15.0491615],
                [107.5391429, 15.0489826],
                [107.5385305, 15.048395],
                [107.5378488, 15.04738],
                [107.5342226, 15.0446963],
                [107.531069, 15.0416983],
                [107.5306004, 15.0414027],
                [107.529887, 15.0410281],
                [107.5281298, 15.0407046],
                [107.5274708, 15.0405967],
                [107.5262912, 15.0400457],
                [107.5257488, 15.0396755],
                [107.5253568, 15.0392826],
                [107.5237891, 15.0386519],
                [107.5232914, 15.0385498],
                [107.5224709, 15.0385109],
                [107.5211582, 15.038612],
                [107.5203291, 15.0386787],
                [107.5199739, 15.0387209],
                [107.5195532, 15.0388576],
                [107.5191944, 15.0390152],
                [107.5186832, 15.0392271],
                [107.5185059, 15.0392818],
                [107.5183823, 15.0392736],
                [107.5183518, 15.0391923],
                [107.5178809, 15.038056],
                [107.5171555, 15.0336662],
                [107.5165573, 15.0330876],
                [107.5162299, 15.0326941],
                [107.5159894, 15.0323832],
                [107.5159429, 15.0319987],
                [107.515959, 15.0314931],
                [107.5158632, 15.0308178],
                [107.5154979, 15.030295],
                [107.5150116, 15.0300798],
                [107.5144515, 15.0298154],
                [107.5138472, 15.0295428],
                [107.511126, 15.0281222],
                [107.5102791, 15.0279885],
                [107.5085127, 15.0269209],
                [107.507991, 15.0265553],
                [107.5067732, 15.0261069],
                [107.5056711, 15.025722],
                [107.5048128, 15.0252941],
                [107.5035331, 15.0244302],
                [107.5018119, 15.0226355],
                [107.5015334, 15.0220664],
                [107.5009096, 15.021574],
                [107.5003692, 15.021503],
                [107.4990796, 15.0213758],
                [107.4985028, 15.0211693],
                [107.4978462, 15.020755],
                [107.4966818, 15.0201472],
                [107.496513, 15.0200227],
                [107.4962716, 15.0199502],
                [107.4960646, 15.0199355],
                [107.4957522, 15.0201573],
                [107.4955825, 15.0203492],
                [107.4934955, 15.0222568],
                [107.4929115, 15.0229183],
                [107.4897975, 15.0238714],
                [107.4894772, 15.0239089],
                [107.48902, 15.0236445],
                [107.4884958, 15.0231849],
                [107.4866868, 15.0231057],
                [107.4860929, 15.0231688],
                [107.4855699, 15.0233163],
                [107.4847901, 15.023444],
                [107.4810884, 15.0224861],
                [107.4806202, 15.0223381],
                [107.4804625, 15.0223171],
                [107.4797613, 15.0221773],
                [107.4758315, 15.02127],
                [107.4748226, 15.0210845],
                [107.4737779, 15.0204752],
                [107.4726786, 15.0195004],
                [107.4728247, 15.0185175],
                [107.4728711, 15.0169799],
                [107.4716585, 15.015037],
                [107.4711944, 15.0142195],
                [107.4707455, 15.0136039],
                [107.4696361, 15.012477],
                [107.4691169, 15.0113225],
                [107.4682963, 15.0095306],
                [107.4679663, 15.0079543],
                [107.4678683, 15.007547],
                [107.467766, 15.0070177],
                [107.4683483, 15.0056242],
                [107.4689244, 15.0043278],
                [107.4690401, 15.0039946],
                [107.4692087, 15.0026798],
                [107.4693287, 15.0019637],
                [107.4701876, 14.9989865],
                [107.4701329, 14.998881],
                [107.470058, 14.9983477],
                [107.4700966, 14.9978512],
                [107.470336, 14.9974604],
                [107.4707496, 14.9968089],
                [107.4724312, 14.9946447],
                [107.4743571, 14.9934808],
                [107.4779302, 14.9929459],
                [107.4780607, 14.9927288],
                [107.478067, 14.9923189],
                [107.4780613, 14.9918229],
                [107.4779129, 14.9914364],
                [107.4775975, 14.9909977],
                [107.4771242, 14.9903018],
                [107.4767531, 14.9898423],
                [107.4762818, 14.9893298],
                [107.4765771, 14.9889923],
                [107.4768616, 14.9886765],
                [107.4773281, 14.9878088],
                [107.4777311, 14.9872004],
                [107.4778169, 14.9869515],
                [107.4777828, 14.9868764],
                [107.4776935, 14.9868234],
                [107.4775605, 14.9868249],
                [107.4772293, 14.9869256],
                [107.4765896, 14.9871913],
                [107.4760273, 14.9874346],
                [107.4756507, 14.9874496],
                [107.475207, 14.9874005],
                [107.4746852, 14.9873198],
                [107.4740316, 14.9873377],
                [107.4734003, 14.9873661],
                [107.4730254, 14.9875212],
                [107.4725605, 14.9875693],
                [107.47194, 14.9875653],
                [107.4715619, 14.9874508],
                [107.4709051, 14.9871883],
                [107.4704258, 14.9869456],
                [107.4697501, 14.9869636],
                [107.4691753, 14.9870885],
                [107.4687794, 14.9873515],
                [107.4683483, 14.9874425],
                [107.4679601, 14.9874143],
                [107.4674484, 14.9872474],
                [107.4670458, 14.9869174],
                [107.4667954, 14.9863486],
                [107.4666675, 14.9858216],
                [107.4667899, 14.9849036],
                [107.4669826, 14.9843085],
                [107.4672636, 14.9836908],
                [107.4675265, 14.9834183],
                [107.4678251, 14.9833719],
                [107.468091, 14.9833691],
                [107.4685255, 14.9835693],
                [107.469337, 14.9837978],
                [107.4696027, 14.9837733],
                [107.4698222, 14.9835876],
                [107.4704592, 14.9830955],
                [107.4709427, 14.9827451],
                [107.4712387, 14.9824615],
                [107.4720005, 14.9812563],
                [107.4723733, 14.9799583],
                [107.4726312, 14.9792546],
                [107.4727795, 14.9786598],
                [107.4728723, 14.9780548],
                [107.4730316, 14.9774493],
                [107.4731038, 14.9769848],
                [107.4730761, 14.9764999],
                [107.4730954, 14.9762516],
                [107.4731828, 14.9761428],
                [107.4734698, 14.9760534],
                [107.4740436, 14.9758424],
                [107.4745815, 14.9754052],
                [107.4753511, 14.9748792],
                [107.4757804, 14.9746266],
                [107.4775158, 14.9733938],
                [107.4787972, 14.9720057],
                [107.4790256, 14.9716258],
                [107.4794858, 14.9711787],
                [107.4803211, 14.9711076],
                [107.4823296, 14.9714125],
                [107.4812414, 14.9687225],
                [107.4807607, 14.96699],
                [107.480614, 14.9667544],
                [107.4806175, 14.9661724],
                [107.480073, 14.9653923],
                [107.4797876, 14.9646621],
                [107.4793669, 14.9637285],
                [107.4790365, 14.962945],
                [107.4787165, 14.9620966],
                [107.4785992, 14.9615263],
                [107.4785944, 14.9611058],
                [107.4786222, 14.9606418],
                [107.4785599, 14.9600387],
                [107.478461, 14.9592148],
                [107.4785425, 14.9585291],
                [107.4785803, 14.9579572],
                [107.478632, 14.9576332],
                [107.4788616, 14.9573718],
                [107.4793543, 14.9568596],
                [107.4798346, 14.9562289],
                [107.4802606, 14.955696],
                [107.4804876, 14.9552082],
                [107.4808926, 14.9547725],
                [107.4818602, 14.9541365],
                [107.4831222, 14.9511574],
                [107.4832411, 14.9508973],
                [107.4834167, 14.9507551],
                [107.484088, 14.9503705],
                [107.48488, 14.9498657],
                [107.4854608, 14.9493095],
                [107.4869887, 14.9482684],
                [107.4877443, 14.9475054],
                [107.4883456, 14.9468086],
                [107.4887588, 14.9461248],
                [107.4891266, 14.9453552],
                [107.4895916, 14.9443688],
                [107.4899507, 14.9438042],
                [107.4901695, 14.9435645],
                [107.4906235, 14.9435488],
                [107.4913781, 14.9436593],
                [107.4919214, 14.9436964],
                [107.4926076, 14.943635],
                [107.4934582, 14.9434208],
                [107.4942768, 14.9433148],
                [107.4951404, 14.943273],
                [107.4956488, 14.9431704],
                [107.4963224, 14.9429798],
                [107.4973172, 14.9427855],
                [107.4979946, 14.9429183],
                [107.4987474, 14.9428777],
                [107.4992451, 14.9428076],
                [107.4994105, 14.9427303],
                [107.4996719, 14.94235],
                [107.4998325, 14.9418738],
                [107.4999394, 14.9415384],
                [107.5000343, 14.9411168],
                [107.5001199, 14.9398974],
                [107.4995893, 14.9361613],
                [107.499984, 14.9358119],
                [107.5005459, 14.9355469],
                [107.5011956, 14.9353819],
                [107.5016999, 14.93528],
                [107.5018118, 14.9352437],
                [107.5021195, 14.9350215],
                [107.5026571, 14.9345505],
                [107.5028439, 14.9341534],
                [107.5031419, 14.9334093],
                [107.503815, 14.9324836],
                [107.5044788, 14.9315948],
                [107.5050134, 14.9307876],
                [107.5052989, 14.9304025],
                [107.505467, 14.9299273],
                [107.5056325, 14.9292154],
                [107.5058019, 14.9288417],
                [107.5060091, 14.928738],
                [107.5062171, 14.928702],
                [107.5065298, 14.9286985],
                [107.506947, 14.9287278],
                [107.5076749, 14.9285845],
                [107.5084052, 14.9286441],
                [107.5089981, 14.9288405],
                [107.5101132, 14.9291326],
                [107.5112347, 14.9294591],
                [107.5119201, 14.929871],
                [107.5140194, 14.9311326],
                [107.5149305, 14.9311081],
                [107.5153465, 14.931036],
                [107.5158645, 14.9307598],
                [107.5171262, 14.930289],
                [107.5184952, 14.9297467],
                [107.5192348, 14.9293366],
                [107.5197204, 14.9292637],
                [107.5202906, 14.9291716],
                [107.5207789, 14.9293352],
                [107.5246187, 14.9294966],
                [107.5255413, 14.9293375],
                [107.5262696, 14.9292282],
                [107.5269982, 14.9287459],
                [107.5282212, 14.9283912],
                [107.529139, 14.9283486],
                [107.5299037, 14.9280639],
                [107.53021, 14.9275586],
                [107.5300628, 14.9273096],
                [107.5301938, 14.9266319],
                [107.5297833, 14.9237429],
                [107.5294983, 14.9231375],
                [107.5293869, 14.9225301],
                [107.5295204, 14.9220553],
                [107.5297251, 14.9217487],
                [107.5302596, 14.9208737],
                [107.5307479, 14.9200808],
                [107.5314626, 14.9183495],
                [107.5324766, 14.9164397],
                [107.533026, 14.9158633],
                [107.5336453, 14.9154433],
                [107.5346132, 14.9149329],
                [107.5353745, 14.9146877],
                [107.536306, 14.9141365],
                [107.5367867, 14.9136577],
                [107.5371608, 14.9129776],
                [107.5374316, 14.9123659],
                [107.5378119, 14.9111533],
                [107.5382683, 14.9097969],
                [107.5384792, 14.909159],
                [107.5387093, 14.9083328],
                [107.5387826, 14.9079714],
                [107.5390368, 14.9076704],
                [107.5395233, 14.9074648],
                [107.5403396, 14.9073438],
                [107.5412945, 14.907003],
                [107.5422383, 14.9066125],
                [107.5436078, 14.9058398],
                [107.5449212, 14.9052843],
                [107.5461656, 14.9047634],
                [107.546644, 14.9045881],
                [107.5484939, 14.9048608],
                [107.5501722, 14.9046627],
                [107.5516803, 14.904067],
                [107.5528921, 14.9028774],
                [107.5538144, 14.9019369],
                [107.5541386, 14.9017101],
                [107.5548318, 14.9014424],
                [107.5550971, 14.9014281],
                [107.555808, 14.9014294],
                [107.5563255, 14.9015366],
                [107.5580726, 14.9019728],
                [107.5604157, 14.9025083],
                [107.5609731, 14.9026372],
                [107.5615636, 14.9026307],
                [107.5653721, 14.9003589],
                [107.5669549, 14.8989644],
                [107.5682301, 14.8976271],
                [107.5699765, 14.8948632],
                [107.5722186, 14.8922763],
                [107.573028, 14.8916295],
                [107.5744055, 14.8916441],
                [107.5757208, 14.8925427],
                [107.5758744, 14.8926464],
                [107.5770302, 14.8931243],
                [107.5777594, 14.893319],
                [107.5789278, 14.8934061],
                [107.5796918, 14.8933976],
                [107.5813261, 14.8933185],
                [107.5830172, 14.8927881],
                [107.5837101, 14.8926451],
                [107.5843308, 14.8922662],
                [107.5849503, 14.891786],
                [107.5855852, 14.8896827],
                [107.5847379, 14.8885427],
                [107.5839526, 14.8867598],
                [107.5839824, 14.8863537],
                [107.5849924, 14.8836715],
                [107.5853684, 14.8831602],
                [107.5860574, 14.8826791],
                [107.5874339, 14.8816155],
                [107.5880502, 14.8808647],
                [107.5885235, 14.8797776],
                [107.5885173, 14.8792705],
                [107.5882323, 14.8786651],
                [107.5874498, 14.8771188],
                [107.5873043, 14.8765796],
                [107.5874054, 14.876308],
                [107.588645, 14.875415],
                [107.5898867, 14.874691],
                [107.5911476, 14.8739812],
                [107.5914121, 14.8736975],
                [107.5914633, 14.8735523],
                [107.5914722, 14.8733609],
                [107.5914141, 14.8731921],
                [107.5912307, 14.8728879],
                [107.5909317, 14.8725262],
                [107.5901393, 14.8717601],
                [107.5897812, 14.8713951],
                [107.5892535, 14.8705768],
                [107.5896851, 14.8655969],
                [107.5897282, 14.8650996],
                [107.5896902, 14.8647296],
                [107.5893453, 14.8639753],
                [107.5889393, 14.8638727],
                [107.5886045, 14.8638176],
                [107.5882271, 14.863951],
                [107.5878277, 14.8638263],
                [107.5867168, 14.8628239],
                [107.5842391, 14.8616154],
                [107.5837632, 14.8614177],
                [107.5832448, 14.8608515],
                [107.582593, 14.8602129],
                [107.5823843, 14.8601968],
                [107.5816757, 14.8595589],
                [107.5808538, 14.8589591],
                [107.5806016, 14.8584636],
                [107.5800104, 14.8565511],
                [107.579986, 14.85609],
                [107.579734, 14.855613],
                [107.5790019, 14.8545878],
                [107.5785646, 14.8544636],
                [107.5780912, 14.8544872],
                [107.5775995, 14.8545666],
                [107.576823, 14.8545938],
                [107.5762364, 14.8546558],
                [107.5755911, 14.8545706],
                [107.5751915, 14.8544276],
                [107.5746373, 14.8540277],
                [107.5738738, 14.8535564],
                [107.5728258, 14.8530516],
                [107.5706216, 14.8525225],
                [107.5696735, 14.8524594],
                [107.5692724, 14.852187],
                [107.5689074, 14.8517666],
                [107.5686372, 14.8513452],
                [107.5684042, 14.850868],
                [107.5662635, 14.8476993],
                [107.5661994, 14.8470726],
                [107.5660405, 14.8464469],
                [107.5659564, 14.8457466],
                [107.5657527, 14.8445493],
                [107.5645083, 14.8434559],
                [107.5638019, 14.8430025],
                [107.5632471, 14.8425473],
                [107.5625967, 14.8420193],
                [107.5623267, 14.8416163],
                [107.5615585, 14.8391335],
                [107.5609861, 14.8387893],
                [107.5604723, 14.838592],
                [107.5600167, 14.8385233],
                [107.5594639, 14.8382341],
                [107.5591562, 14.83785],
                [107.556425, 14.8359796],
                [107.5561195, 14.83578],
                [107.5528782, 14.8340443],
                [107.5518262, 14.8341417],
                [107.5512018, 14.8337994],
                [107.5506426, 14.8331338],
                [107.5502861, 14.8327259],
                [107.5499221, 14.8323793],
                [107.5489742, 14.831564],
                [107.5484305, 14.8315237],
                [107.5479264, 14.8318294],
                [107.5474528, 14.8318345],
                [107.5471676, 14.8317454],
                [107.5465881, 14.8316646],
                [107.5448085, 14.8299747],
                [107.5438264, 14.8290102],
                [107.5423107, 14.8279237],
                [107.5417198, 14.8276165],
                [107.5412219, 14.8271605],
                [107.5408195, 14.8267775],
                [107.5402825, 14.8262113],
                [107.5402103, 14.8255477],
                [107.5405788, 14.8248345],
                [107.5408253, 14.8235152],
                [107.5408264, 14.8224958],
                [107.540895, 14.8218862],
                [107.5406915, 14.8207297],
                [107.5405558, 14.8204135],
                [107.5402437, 14.8196418],
                [107.5396954, 14.8189281],
                [107.5389522, 14.8185488],
                [107.5382644, 14.8186544],
                [107.5376409, 14.8187997],
                [107.5372522, 14.8189827],
                [107.5365115, 14.8188249],
                [107.5356252, 14.818364],
                [107.5346546, 14.8179871],
                [107.5334558, 14.8175297],
                [107.5329421, 14.8173416],
                [107.5323955, 14.8167662],
                [107.5313914, 14.8159469],
                [107.5303033, 14.815239],
                [107.5273652, 14.8141424],
                [107.5252843, 14.8141803],
                [107.5241468, 14.8141096],
                [107.5231856, 14.8139096],
                [107.5225873, 14.8137778],
                [107.5221654, 14.8136319],
                [107.5220995, 14.8131237],
                [107.5221405, 14.8125149],
                [107.5222148, 14.8112308],
                [107.5227129, 14.8100626],
                [107.5230965, 14.8088405],
                [107.5232596, 14.8082018],
                [107.5235862, 14.807351],
                [107.524007, 14.8068757],
                [107.5241827, 14.8063629],
                [107.5241142, 14.8059226],
                [107.5236486, 14.8049864],
                [107.5223844, 14.8037823],
                [107.5206376, 14.8026111],
                [107.5194959, 14.8021806],
                [107.5183575, 14.802027],
                [107.5165427, 14.8023513],
                [107.5159712, 14.8020806],
                [107.5156543, 14.8016966],
                [107.5109662, 14.7967925],
                [107.5102785, 14.7963017],
                [107.5096471, 14.7957549],
                [107.5089522, 14.7946016],
                [107.5082809, 14.7937624],
                [107.5073389, 14.7927072],
                [107.5066226, 14.7902531],
                [107.5056233, 14.7868293],
                [107.5053536, 14.7858466],
                [107.5054474, 14.7857244],
                [107.5057176, 14.7853722],
                [107.506855, 14.7852103],
                [107.5080392, 14.7844223],
                [107.5087987, 14.7837497],
                [107.509448, 14.7833828],
                [107.5099887, 14.78346],
                [107.5135747, 14.7839746],
                [107.5143404, 14.7838554],
                [107.5163907, 14.7817845],
                [107.5166937, 14.7809508],
                [107.5168762, 14.7795369],
                [107.5169251, 14.7788443],
                [107.5173151, 14.7781757],
                [107.5181435, 14.7773787],
                [107.5186369, 14.7769985],
                [107.5191972, 14.7763004],
                [107.5194455, 14.7756609],
                [107.5199197, 14.7755793],
                [107.5209136, 14.7756248],
                [107.5236181, 14.7762084],
                [107.524512, 14.7760021],
                [107.5253904, 14.7757987],
                [107.5260624, 14.7753949],
                [107.5263691, 14.7748932],
                [107.5265654, 14.7746696],
                [107.526928, 14.7740843],
                [107.5303085, 14.7666836],
                [107.5305558, 14.7659612],
                [107.5313189, 14.7656206],
                [107.5320847, 14.7655014],
                [107.5340826, 14.7638463],
                [107.534277, 14.7634566],
                [107.5343292, 14.7630685],
                [107.5343525, 14.7626252],
                [107.5340278, 14.7615769],
                [107.5356838, 14.7573786],
                [107.5366451, 14.7569805],
                [107.5391075, 14.7539179],
                [107.5393528, 14.7530294],
                [107.5399945, 14.7520049],
                [107.5405304, 14.7515121],
                [107.541082, 14.7510415],
                [107.5419239, 14.7507022],
                [107.5420634, 14.7501288],
                [107.54212, 14.7495921],
                [107.5420013, 14.7492971],
                [107.541894, 14.7489791],
                [107.5415211, 14.7483219],
                [107.5414062, 14.7480598],
                [107.5411889, 14.7477352],
                [107.5408433, 14.7474103],
                [107.5404208, 14.7470853],
                [107.5398703, 14.7466727],
                [107.5383946, 14.744045],
                [107.5378568, 14.7436574],
                [107.5376013, 14.7432206],
                [107.5376083, 14.7429649],
                [107.5373001, 14.7401277],
                [107.5365701, 14.7396524],
                [107.5356737, 14.7390895],
                [107.5351744, 14.7387268],
                [107.534816, 14.7384144],
                [107.5344833, 14.7380895],
                [107.5335808, 14.7373957],
                [107.5329915, 14.7370703],
                [107.5324536, 14.73677],
                [107.5316339, 14.7363444],
                [107.5310447, 14.735994],
                [107.5268622, 14.7340466],
                [107.5267085, 14.7339466],
                [107.5265422, 14.7337592],
                [107.5262354, 14.7333595],
                [107.5259799, 14.7328976],
                [107.5256929, 14.7321426],
                [107.5256293, 14.731943],
                [107.5254759, 14.7317182],
                [107.5251944, 14.7314684],
                [107.5248234, 14.7310686],
                [107.5243753, 14.730706],
                [107.5239146, 14.7303061],
                [107.5235051, 14.7299187],
                [107.5233261, 14.7296814],
                [107.5231919, 14.7294754],
                [107.521862, 14.7277769],
                [107.5217086, 14.7275771],
                [107.5216706, 14.7273775],
                [107.5217093, 14.7272405],
                [107.5217994, 14.7271035],
                [107.5220114, 14.7268856],
                [107.5223328, 14.7265123],
                [107.5224347, 14.7257077],
                [107.5232863, 14.7243821],
                [107.5231857, 14.7237984],
                [107.5227995, 14.722906],
                [107.5233574, 14.7215986],
                [107.524795, 14.7212873],
                [107.526061, 14.7218269],
                [107.5267048, 14.7236531],
                [107.5271983, 14.7235286],
                [107.5323052, 14.7230306],
                [107.5351805, 14.7247738],
                [107.5373049, 14.7232381],
                [107.5383348, 14.7228645],
                [107.5421328, 14.7215778],
                [107.5450296, 14.7208307],
                [107.545609, 14.7209345],
                [107.5467891, 14.7209552],
                [107.547111, 14.7206854],
                [107.5487847, 14.7202704],
                [107.5502867, 14.7207062],
                [107.5509305, 14.7207684],
                [107.5519604, 14.7212043],
                [107.5537414, 14.7216608],
                [107.5557155, 14.7213495],
                [107.5556941, 14.7205817],
                [107.5557799, 14.7202911],
                [107.5561876, 14.7195648],
                [107.5561876, 14.7189006],
                [107.5558228, 14.7185271],
                [107.555458, 14.7182158],
                [107.5552006, 14.717946],
                [107.5550718, 14.7174479],
                [107.5550933, 14.7169706],
                [107.5552006, 14.7164102],
                [107.5552649, 14.715414],
                [107.5552649, 14.7145009],
                [107.5549431, 14.7131518],
                [107.5544924, 14.7122179],
                [107.5548358, 14.7106613],
                [107.5554795, 14.7093498],
                [107.5565348, 14.7091192],
                [107.5565934, 14.7088703],
                [107.556668, 14.7085801],
                [107.5567934, 14.7081965],
                [107.5581809, 14.7069401],
                [107.5591026, 14.7053374],
                [107.5593523, 14.7040425],
                [107.5589987, 14.7020071],
                [107.5586097, 14.699633],
                [107.5585213, 14.6979911],
                [107.5587335, 14.6962636],
                [107.5589103, 14.6953228],
                [107.5591037, 14.6947859],
                [107.5593448, 14.6938634],
                [107.5593329, 14.6934974],
                [107.559175, 14.6932384],
                [107.5585567, 14.6925862],
                [107.5582384, 14.6919875],
                [107.5583029, 14.6915126],
                [107.5587158, 14.6906192],
                [107.5586097, 14.6898494],
                [107.5573719, 14.6868903],
                [107.5565718, 14.6860512],
                [107.5562397, 14.6850276],
                [107.5566996, 14.6842567],
                [107.5555626, 14.6827233],
                [107.5550047, 14.682059],
                [107.5540606, 14.6814986],
                [107.5527517, 14.681291],
                [107.5520865, 14.6807721],
                [107.551593, 14.6799833],
                [107.5515071, 14.6793191],
                [107.551726, 14.6788252],
                [107.550255, 14.6778822],
                [107.549616, 14.6771959],
                [107.5497278, 14.6757832],
                [107.5505959, 14.6750205],
                [107.5508287, 14.6743878],
                [107.5506954, 14.6735503],
                [107.5504667, 14.6723795],
                [107.5512708, 14.6705041],
                [107.5521257, 14.6693983],
                [107.5533229, 14.6685665],
                [107.5545452, 14.6680438],
                [107.5548373, 14.6674229],
                [107.5547953, 14.6659531],
                [107.5548041, 14.6653094],
                [107.5548317, 14.6634945],
                [107.5548991, 14.6627021],
                [107.5549526, 14.6615193],
                [107.5544546, 14.6606824],
                [107.5546584, 14.6601047],
                [107.5545663, 14.6594074],
                [107.5539072, 14.6594327],
                [107.5529279, 14.6590584],
                [107.5520905, 14.6587051],
                [107.5515136, 14.6586731],
                [107.5509378, 14.6589753],
                [107.5501985, 14.6595401],
                [107.5494221, 14.6597044],
                [107.54855, 14.659513],
                [107.5486048, 14.658583],
                [107.548543, 14.6579514],
                [107.5486449, 14.6569859],
                [107.5490982, 14.6556169],
                [107.5494925, 14.6542609],
                [107.5494997, 14.6532265],
                [107.5497899, 14.6522951],
                [107.5504094, 14.6512584],
                [107.5511726, 14.6507047],
                [107.5517711, 14.6503108],
                [107.5519318, 14.6493119],
                [107.5516451, 14.6483361],
                [107.551652, 14.6472339],
                [107.5514603, 14.6464534],
                [107.551116, 14.6458113],
                [107.5512753, 14.6445934],
                [107.5515071, 14.6436277],
                [107.5518092, 14.6427763],
                [107.5518178, 14.6420185],
                [107.5513416, 14.640907],
                [107.5519878, 14.6405713],
                [107.5534237, 14.6403805],
                [107.5542225, 14.6399528],
                [107.5551694, 14.6383412],
                [107.5560939, 14.6371081],
                [107.5569491, 14.6360937],
                [107.5578359, 14.63431],
                [107.5581595, 14.6329074],
                [107.5580352, 14.6313459],
                [107.558029, 14.629956],
                [107.5587434, 14.6290572],
                [107.5588925, 14.628264],
                [107.557923, 14.6272109],
                [107.5575202, 14.626627],
                [107.5573279, 14.6257438],
                [107.5577946, 14.6247879],
                [107.5584743, 14.6240962],
                [107.5590723, 14.6235657],
                [107.5595296, 14.6230931],
                [107.5595627, 14.622553],
                [107.5587727, 14.6222805],
                [107.5578883, 14.6220655],
                [107.5570028, 14.6214034],
                [107.5565294, 14.6208315],
                [107.5551271, 14.6205612],
                [107.5543359, 14.6200369],
                [107.5535903, 14.6190864],
                [107.5531294, 14.61879],
                [107.5522678, 14.6181964],
                [107.5514635, 14.6173843],
                [107.5503816, 14.6175847],
                [107.5493942, 14.6180483],
                [107.5480272, 14.6177663],
                [107.5468938, 14.6170244],
                [107.5458454, 14.6168224],
                [107.5450204, 14.6166304],
                [107.5440996, 14.6160714],
                [107.5437088, 14.6155675],
                [107.5439259, 14.6139244],
                [107.544017, 14.6132233],
                [107.5443657, 14.6121885],
                [107.5447512, 14.6115779],
                [107.5451376, 14.610991],
                [107.5449574, 14.6102454],
                [107.5445075, 14.6096496],
                [107.5441979, 14.6087891],
                [107.5441837, 14.6082156],
                [107.5443221, 14.6075942],
                [107.5441305, 14.6068149],
                [107.5434689, 14.6062089],
                [107.542972, 14.6057051],
                [107.5423928, 14.6052021],
                [107.5414627, 14.6051377],
                [107.5408503, 14.6051286],
                [107.5398592, 14.6045578],
                [107.5391513, 14.6042053],
                [107.5388171, 14.6030696],
                [107.5385785, 14.6024387],
                [107.5379281, 14.6017411],
                [107.5371841, 14.6010773],
                [107.5365935, 14.6006434],
                [107.5358021, 14.5999812],
                [107.5351519, 14.5993514],
                [107.5345843, 14.5988494],
                [107.5339939, 14.5984267],
                [107.5332992, 14.5983146],
                [107.5326417, 14.5987192],
                [107.5321009, 14.5989396],
                [107.5313714, 14.5990232],
                [107.5305206, 14.5983492],
                [107.5299649, 14.5977658],
                [107.5294103, 14.5973777],
                [107.5282212, 14.5974392],
                [107.527445, 14.5975458],
                [107.526622, 14.5978246],
                [107.5257054, 14.5981394],
                [107.5250577, 14.5981306],
                [107.5248902, 14.5975791],
                [107.5245565, 14.5965359],
                [107.5239058, 14.5956995],
                [107.5233711, 14.5946223],
                [107.523521, 14.5937839],
                [107.5234219, 14.5927269],
                [107.522832, 14.5923392],
                [107.5221239, 14.5919742],
                [107.5223774, 14.5906062],
                [107.5226101, 14.5899623],
                [107.5225462, 14.5887344],
                [107.5223432, 14.5881144],
                [107.521091, 14.5870054],
                [107.5197247, 14.5867695],
                [107.5186051, 14.5865557],
                [107.5179101, 14.5864097],
                [107.5172239, 14.5856198],
                [107.5169625, 14.584909],
                [107.5176668, 14.5844463],
                [107.5185702, 14.5837884],
                [107.5192621, 14.5830966],
                [107.5199629, 14.581761],
                [107.5204429, 14.5812534],
                [107.5210421, 14.5809759],
                [107.522041, 14.580466],
                [107.522281, 14.5788339],
                [107.5225022, 14.5782477],
                [107.5229699, 14.5774759],
                [107.5231789, 14.5766832],
                [107.5228703, 14.5761095],
                [107.5232075, 14.5751211],
                [107.5235811, 14.5744305],
                [107.5239431, 14.5736938],
                [107.5240579, 14.5729811],
                [107.523584, 14.572294],
                [107.5230055, 14.5718937],
                [107.5228717, 14.5708845],
                [107.5233266, 14.5698023],
                [107.5234407, 14.5689756],
                [107.5235061, 14.5676887],
                [107.5235842, 14.5667122],
                [107.5232643, 14.5661047],
                [107.5226274, 14.5658203],
                [107.5231067, 14.5650947],
                [107.5240813, 14.5644935],
                [107.5246195, 14.5636984],
                [107.5252645, 14.5631212],
                [107.5259792, 14.5624066],
                [107.5253023, 14.5608928],
                [107.5250764, 14.5604006],
                [107.5264878, 14.5600839],
                [107.5274629, 14.5596781],
                [107.5288714, 14.5588002],
                [107.5299396, 14.5581291],
                [107.5306886, 14.5570121],
                [107.5318941, 14.5554679],
                [107.5322178, 14.554019],
                [107.5319767, 14.5525965],
                [107.5313004, 14.5513933],
                [107.5297397, 14.5497457],
                [107.5302653, 14.5487101],
                [107.5316486, 14.5473604],
                [107.53215, 14.5463477],
                [107.5307812, 14.5453541],
                [107.5299667, 14.5448401],
                [107.5289872, 14.5442353],
                [107.5278082, 14.5437805],
                [107.5268503, 14.5425792],
                [107.5272694, 14.5414635],
                [107.5277597, 14.5405887],
                [107.5276849, 14.5395664],
                [107.528044, 14.5381171],
                [107.528062, 14.5368431],
                [107.5277882, 14.5360624],
                [107.5269021, 14.5352047],
                [107.525958, 14.5346006],
                [107.5255434, 14.534028],
                [107.5258436, 14.5325444],
                [107.5258153, 14.5314301],
                [107.5250957, 14.5309975],
                [107.5238951, 14.5309788],
                [107.5229969, 14.5300749],
                [107.5223316, 14.5285509],
                [107.5225857, 14.5273432],
                [107.5231331, 14.5260432],
                [107.5233052, 14.5249167],
                [107.5230905, 14.5242742],
                [107.5225946, 14.5238402],
                [107.5224499, 14.5230367],
                [107.5226821, 14.522255],
                [107.5222054, 14.5207978],
                [107.5215886, 14.51956],
                [107.5217018, 14.5186068],
                [107.5214164, 14.5178149],
                [107.5204378, 14.5173828],
                [107.5195653, 14.5169495],
                [107.5191613, 14.5159748],
                [107.5188628, 14.5148962],
                [107.5181645, 14.5139811],
                [107.517054, 14.5129518],
                [107.5161793, 14.5120239],
                [107.5157529, 14.5112685],
                [107.5115507, 14.5110324],
                [107.5112193, 14.5105278],
                [107.510946, 14.5099322],
                [107.5104031, 14.5095891],
                [107.5091783, 14.5092611],
                [107.5084828, 14.5089535],
                [107.507528, 14.5085673],
                [107.5070213, 14.5083966],
                [107.5071593, 14.5075809],
                [107.508578, 14.5063925],
                [107.509513, 14.5048151],
                [107.509969, 14.5040785],
                [107.5101188, 14.5032853],
                [107.5105174, 14.5028587],
                [107.5113861, 14.5024417],
                [107.5122918, 14.5022773],
                [107.5123599, 14.501634],
                [107.5137525, 14.5019907],
                [107.5137332, 14.5010005],
                [107.5137586, 14.4999184],
                [107.5139075, 14.499193],
                [107.5141194, 14.4987074],
                [107.5140929, 14.497959],
                [107.5132389, 14.4972048],
                [107.5124881, 14.4969678],
                [107.5111873, 14.4966294],
                [107.5100814, 14.4961059],
                [107.5099388, 14.4947602],
                [107.5100754, 14.4934127],
                [107.5102169, 14.4917196],
                [107.5102464, 14.4902219],
                [107.5101827, 14.4893474],
                [107.5096434, 14.4886022],
                [107.5082686, 14.4874481],
                [107.5070093, 14.4862578],
                [107.5063338, 14.4850872],
                [107.5059781, 14.4836038],
                [107.5058155, 14.4818202],
                [107.5056151, 14.4799218],
                [107.5055226, 14.4781837],
                [107.5051578, 14.4761945],
                [107.504855, 14.4750334],
                [107.5045492, 14.4736996],
                [107.5043021, 14.472389],
                [107.504413, 14.4714787],
                [107.5044006, 14.4702694],
                [107.50414, 14.4694535],
                [107.5032968, 14.4680543],
                [107.5020847, 14.4668747],
                [107.501386, 14.465693],
                [107.5012277, 14.4635649],
                [107.5018164, 14.4620647],
                [107.502672, 14.4611149],
                [107.5036063, 14.4606149],
                [107.5048725, 14.4598019],
                [107.5056443, 14.4587152],
                [107.5057226, 14.4579431],
                [107.505295, 14.4569663],
                [107.5043893, 14.4559246],
                [107.5032062, 14.4544342],
                [107.5025676, 14.4527685],
                [107.5024914, 14.4512381],
                [107.5026148, 14.4504079],
                [107.5022944, 14.4489139],
                [107.501489, 14.4476633],
                [107.5011041, 14.4464569],
                [107.5006173, 14.4447896],
                [107.4997742, 14.4428144],
                [107.4992852, 14.4416204],
                [107.4988853, 14.4408285],
                [107.4986391, 14.4401614],
                [107.4980464, 14.4390712],
                [107.4978372, 14.4372993],
                [107.4978629, 14.4362285],
                [107.4973722, 14.4355641],
                [107.4962063, 14.4343501],
                [107.4951256, 14.4349544],
                [107.4944196, 14.4348274],
                [107.4938901, 14.4347246],
                [107.493149, 14.4344603],
                [107.4924313, 14.4337937],
                [107.4918085, 14.4325412],
                [107.4913152, 14.4315877],
                [107.4909624, 14.4310697],
                [107.4903274, 14.4301278],
                [107.4896344, 14.4288184],
                [107.4895293, 14.4275999],
                [107.4897535, 14.4264513],
                [107.4898247, 14.4255551],
                [107.4898253, 14.4241536],
                [107.4900029, 14.4231885],
                [107.49018, 14.4222346],
                [107.4907107, 14.4202596],
                [107.4904644, 14.4190528],
                [107.4898889, 14.4177319],
                [107.4891948, 14.4171453],
                [107.4893368, 14.4163759],
                [107.4896317, 14.4155371],
                [107.4904201, 14.4146988],
                [107.4904088, 14.4139976],
                [107.4898926, 14.4124808],
                [107.4896813, 14.4114475],
                [107.4894589, 14.4101489],
                [107.4892955, 14.4082648],
                [107.4889547, 14.407208],
                [107.4881202, 14.4059316],
                [107.4866032, 14.4050589],
                [107.4848506, 14.4043569],
                [107.4826742, 14.4035757],
                [107.4813685, 14.4034257],
                [107.4804507, 14.4038272],
                [107.4793676, 14.4042632],
                [107.4785553, 14.4047234],
                [107.4776727, 14.4051132],
                [107.4770133, 14.4054691],
                [107.4764487, 14.4060092],
                [107.476001, 14.4065943],
                [107.4759181, 14.4072727],
                [107.4754583, 14.4080771],
                [107.4745758, 14.4084782],
                [107.4731284, 14.4083511],
                [107.4718823, 14.4075126],
                [107.4708713, 14.4064436],
                [107.469248, 14.4052828],
                [107.4675778, 14.4047536],
                [107.4659896, 14.4045692],
                [107.4646952, 14.4049136],
                [107.4639768, 14.4054869],
                [107.4630117, 14.4058086],
                [107.4619412, 14.4058661],
                [107.460517, 14.4060029],
                [107.4591866, 14.4069789],
                [107.4592685, 14.407737],
                [107.4591265, 14.4083697],
                [107.4589143, 14.4090359],
                [107.4589724, 14.4101612],
                [107.4587243, 14.4115743],
                [107.4584289, 14.4126886],
                [107.4578401, 14.4133091],
                [107.4568749, 14.4137336],
                [107.4555567, 14.4142385],
                [107.4545446, 14.4142954],
                [107.4531443, 14.4144443],
                [107.4526259, 14.4149375],
                [107.4522606, 14.4159148],
                [107.4518713, 14.4170289],
                [107.4514115, 14.418154],
                [107.4506342, 14.4186477],
                [107.4497639, 14.4185325],
                [107.4489876, 14.4177399],
                [107.4486358, 14.4165103],
                [107.4485556, 14.4140978],
                [107.4483928, 14.4117077],
                [107.4478883, 14.4103408],
                [107.4476076, 14.4080309],
                [107.4471977, 14.4064564],
                [107.4467517, 14.4050889],
                [107.4463297, 14.4027805],
                [107.4455312, 14.4013206],
                [107.4441911, 14.4000787],
                [107.4428155, 14.3988259],
                [107.4416282, 14.3978952],
                [107.4405707, 14.3963781],
                [107.4407947, 14.3958259],
                [107.4414884, 14.3960107],
                [107.442571, 14.3962174],
                [107.4435003, 14.3963332],
                [107.4440068, 14.3958164],
                [107.4429724, 14.3946673],
                [107.4419255, 14.3939655],
                [107.4412677, 14.3929198],
                [107.4411397, 14.3918053],
                [107.4411407, 14.3905642],
                [107.4417186, 14.3891636],
                [107.4417074, 14.3885539],
                [107.4413548, 14.3880369],
                [107.4407439, 14.3873125],
                [107.4399217, 14.3854859],
                [107.4401707, 14.3835556],
                [107.4401484, 14.3821431],
                [107.4391851, 14.380913],
                [107.4382436, 14.381004],
                [107.4369843, 14.3813716],
                [107.4358431, 14.3814737],
                [107.4342906, 14.3810368],
                [107.4326796, 14.3798518],
                [107.4314454, 14.3786449],
                [107.4298228, 14.3777253],
                [107.4285531, 14.3767592],
                [107.4274246, 14.3758391],
                [107.4268263, 14.3746561],
                [107.4253798, 14.3735755],
                [107.4238632, 14.3725972],
                [107.4224649, 14.3711378],
                [107.4214076, 14.3697234],
                [107.4204428, 14.3685224],
                [107.4201541, 14.3676285],
                [107.4196494, 14.3668014],
                [107.4183694, 14.3653241],
                [107.4174366, 14.3643556],
                [107.416473, 14.3635625],
                [107.4152033, 14.3626426],
                [107.4134983, 14.3618029],
                [107.4122983, 14.3618716],
                [107.4115686, 14.3623646],
                [107.4104618, 14.3632951],
                [107.4092256, 14.3637651],
                [107.4086029, 14.3633852],
                [107.4088509, 14.3624781],
                [107.4101241, 14.3601003],
                [107.4105601, 14.3591122],
                [107.4107377, 14.3581708],
                [107.410775, 14.3565736],
                [107.4103405, 14.355539],
                [107.4099426, 14.3539654],
                [107.4092614, 14.3528735],
                [107.408945, 14.3515633],
                [107.4089943, 14.3493459],
                [107.4088193, 14.3479902],
                [107.4083624, 14.3465877],
                [107.4076103, 14.345737],
                [107.4065403, 14.3452307],
                [107.4052823, 14.3446324],
                [107.4039191, 14.3438851],
                [107.4024618, 14.342574],
                [107.4018397, 14.3411031],
                [107.4014415, 14.3397124],
                [107.4012785, 14.3384368],
                [107.4006091, 14.3371381],
                [107.4004341, 14.3358851],
                [107.4001417, 14.3345295],
                [107.3992848, 14.332921],
                [107.3981687, 14.3316561],
                [107.3967114, 14.3307131],
                [107.3955013, 14.3295971],
                [107.394302, 14.3290107],
                [107.3933381, 14.3286071],
                [107.3921387, 14.3279517],
                [107.390847, 14.3262854],
                [107.390825, 14.325032],
                [107.3913325, 14.3237925],
                [107.3915104, 14.322597],
                [107.3916412, 14.3210575],
                [107.3913718, 14.3200698],
                [107.3908445, 14.318564],
                [107.3908102, 14.3178405],
                [107.3908469, 14.3164386],
                [107.3911775, 14.3157723],
                [107.3920254, 14.3146356],
                [107.3928848, 14.3138657],
                [107.3946616, 14.313282],
                [107.3958739, 14.3127537],
                [107.3964154, 14.3123755],
                [107.3971218, 14.3117665],
                [107.3974872, 14.3113302],
                [107.3975354, 14.3103077],
                [107.3971479, 14.30932],
                [107.3970433, 14.3083544],
                [107.3973387, 14.3074355],
                [107.3981745, 14.306632],
                [107.3996686, 14.3062533],
                [107.4003638, 14.3053586],
                [107.4008241, 14.3041523],
                [107.4013191, 14.3031648],
                [107.4018719, 14.3028656],
                [107.4026958, 14.302637],
                [107.4036486, 14.3023383],
                [107.4042133, 14.3021327],
                [107.4051078, 14.3015127],
                [107.4056732, 14.3008813],
                [107.40618, 14.3000201],
                [107.4067228, 14.2984686],
                [107.4070897, 14.2963778],
                [107.406926, 14.29531],
                [107.4067159, 14.2940687],
                [107.4066698, 14.2934368],
                [107.4064123, 14.29231],
                [107.4066246, 14.2915749],
                [107.4066963, 14.2908627],
                [107.4062617, 14.2902302],
                [107.4058507, 14.2897024],
                [107.4057221, 14.28899],
                [107.4057704, 14.288243],
                [107.4059005, 14.287415],
                [107.4057132, 14.2865654],
                [107.4059607, 14.2858875],
                [107.406385, 14.2855433],
                [107.4070789, 14.2851297],
                [107.4075854, 14.2846819],
                [107.4077154, 14.2840041],
                [107.4077639, 14.2826834],
                [107.4077651, 14.2817065],
                [107.4078137, 14.2805575],
                [107.4081671, 14.2799836],
                [107.4087086, 14.27934],
                [107.4093802, 14.2784682],
                [107.4092399, 14.2778473],
                [107.40917, 14.277158],
                [107.4092295, 14.2764911],
                [107.4096415, 14.2758138],
                [107.4095839, 14.2750781],
                [107.4090324, 14.2741813],
                [107.4086337, 14.273274],
                [107.4087164, 14.272722],
                [107.4090109, 14.2721024],
                [107.4095056, 14.2713103],
                [107.4097656, 14.2702303],
                [107.4095902, 14.2696324],
                [107.4090976, 14.2686096],
                [107.4090626, 14.2680352],
                [107.4092637, 14.267185],
                [107.4092878, 14.2666223],
                [107.4094889, 14.2655994],
                [107.4097131, 14.2646925],
                [107.4095731, 14.2641518],
                [107.4092444, 14.2635193],
                [107.4088921, 14.2630023],
                [107.4087991, 14.2620376],
                [107.4087883, 14.2615092],
                [107.4083184, 14.2607155],
                [107.4075783, 14.2604282],
                [107.4067439, 14.2599793],
                [107.4063562, 14.2596116],
                [107.4059216, 14.2592546],
                [107.4054753, 14.2589328],
                [107.4050289, 14.2584381],
                [107.4043244, 14.2576219],
                [107.4034081, 14.2565526],
                [107.4030089, 14.2560812],
                [107.4026803, 14.2555639],
                [107.4023759, 14.2548058],
                [107.4021299, 14.2539895],
                [107.4017317, 14.2524734],
                [107.4015989, 14.2516333],
                [107.4010933, 14.2506243],
                [107.4006459, 14.2499241],
                [107.4002345, 14.2491783],
                [107.4003881, 14.2481005],
                [107.4007773, 14.2470226],
                [107.4012727, 14.2460238],
                [107.4020158, 14.2447977],
                [107.4031592, 14.2434321],
                [107.4039607, 14.2429734],
                [107.4048914, 14.2422402],
                [107.4055169, 14.2408978],
                [107.4060946, 14.2396938],
                [107.4070737, 14.2380656],
                [107.4073798, 14.237561],
                [107.4080174, 14.2355082],
                [107.4068876, 14.2335117],
                [107.4060398, 14.2335914],
                [107.4049448, 14.2333393],
                [107.4039087, 14.2319843],
                [107.40338, 14.2304356],
                [107.4017081, 14.2290588],
                [107.400637, 14.2281063],
                [107.3994246, 14.2276475],
                [107.3988714, 14.2267406],
                [107.3986016, 14.2255823],
                [107.3974006, 14.2247214],
                [107.396683, 14.2233101],
                [107.396696, 14.2218531],
                [107.396803, 14.2205804],
                [107.3965096, 14.218503],
                [107.3964995, 14.2167718],
                [107.3955701, 14.2148669],
                [107.3942877, 14.2135821],
                [107.3941348, 14.2129162],
                [107.3946426, 14.2109438],
                [107.3941371, 14.2098884],
                [107.3923818, 14.2100821],
                [107.391051, 14.2106782],
                [107.3902736, 14.2102421],
                [107.3899563, 14.2097371],
                [107.3888742, 14.2076721],
                [107.3880273, 14.2062836],
                [107.3872637, 14.203679],
                [107.3871315, 14.2020084],
                [107.3871003, 14.2016139],
                [107.3865247, 14.2001571],
                [107.385301, 14.1983781],
                [107.3838185, 14.1963702],
                [107.3830422, 14.1953028],
                [107.3826077, 14.1942128],
                [107.3818198, 14.1921019],
                [107.3820094, 14.1907482],
                [107.3817039, 14.1900374],
                [107.3816463, 14.1885687],
                [107.3820006, 14.1876289],
                [107.3813414, 14.1866417],
                [107.3801663, 14.184014],
                [107.3792486, 14.1827063],
                [107.3786606, 14.1817081],
                [107.3782724, 14.1810648],
                [107.3773792, 14.1793209],
                [107.3758254, 14.1785062],
                [107.3739644, 14.1790329],
                [107.3729046, 14.1791925],
                [107.3713021, 14.179651],
                [107.3699243, 14.1800622],
                [107.3684757, 14.1798439],
                [107.3668049, 14.178294],
                [107.3656409, 14.176608],
                [107.3646062, 14.1749433],
                [107.3633122, 14.1732225],
                [107.3615116, 14.1717065],
                [107.359911, 14.170777],
                [107.3586527, 14.1690321],
                [107.3587477, 14.1681954],
                [107.3598081, 14.1673695],
                [107.3604686, 14.1662687],
                [107.3614701, 14.1655123],
                [107.362602, 14.1641018],
                [107.3630153, 14.1629322],
                [107.3620274, 14.1615325],
                [107.3606041, 14.1597768],
                [107.3596397, 14.1583531],
                [107.3588991, 14.1573552],
                [107.3576516, 14.1563679],
                [107.353499, 14.1524073],
                [107.3534886, 14.1508353],
                [107.3538548, 14.1498039],
                [107.3531839, 14.1491724],
                [107.3523718, 14.1488156],
                [107.3516432, 14.147577],
                [107.3514557, 14.1468425],
                [107.3513273, 14.1458329],
                [107.3455309, 14.1369504],
                [107.3449428, 14.136204],
                [107.3435662, 14.135193],
                [107.3419425, 14.1342286],
                [107.3408227, 14.1323025],
                [107.3402269, 14.1309011],
                [107.3396521, 14.1293515],
                [107.3386524, 14.127849],
                [107.3381487, 14.1256109],
                [107.3386916, 14.1245451],
                [107.3387165, 14.1231342],
                [107.3383874, 14.1222384],
                [107.3379416, 14.1210795],
                [107.3374957, 14.11968],
                [107.336673, 14.1178786],
                [107.3357922, 14.116157],
                [107.3353814, 14.1149751],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [102.3205299, 22.5547249],
                [102.3205697, 22.5541372],
                [102.3211162, 22.5540318],
                [102.3216469, 22.5543933],
                [102.321669, 22.5544083],
                [102.3219696, 22.5541579],
                [102.3216983, 22.5533248],
                [102.3214238, 22.5529958],
                [102.3215403, 22.5525819],
                [102.3217157, 22.5526471],
                [102.3222014, 22.5528276],
                [102.322809, 22.5526309],
                [102.322628, 22.5523027],
                [102.3227986, 22.5518847],
                [102.322125, 22.5517492],
                [102.3219298, 22.5515297],
                [102.3220622, 22.5510226],
                [102.321767, 22.551017],
                [102.3210583, 22.550533],
                [102.3207797, 22.5500949],
                [102.3208179, 22.5494458],
                [102.3212285, 22.5492004],
                [102.322145, 22.5494403],
                [102.3232103, 22.5492309],
                [102.3235325, 22.5487268],
                [102.3234504, 22.5485154],
                [102.3231763, 22.5484004],
                [102.3225695, 22.5485111],
                [102.3220544, 22.5483992],
                [102.3216048, 22.5479246],
                [102.321583, 22.5474656],
                [102.3219861, 22.5470375],
                [102.322611, 22.5470095],
                [102.3234576, 22.5472702],
                [102.3237599, 22.5472702],
                [102.3236653, 22.546971],
                [102.3228338, 22.5459453],
                [102.3221577, 22.5446461],
                [102.3221017, 22.5444513],
                [102.3221135, 22.5438421],
                [102.3223553, 22.54368],
                [102.3226851, 22.5436024],
                [102.3230438, 22.5435806],
                [102.3236399, 22.5436183],
                [102.3239642, 22.5438175],
                [102.3242312, 22.5439051],
                [102.3246488, 22.543912],
                [102.3250089, 22.5438072],
                [102.3252215, 22.5436169],
                [102.3259317, 22.5423822],
                [102.3269577, 22.5402388],
                [102.3277635, 22.5394627],
                [102.3286372, 22.5390341],
                [102.3290624, 22.5386533],
                [102.3291652, 22.5379626],
                [102.3289084, 22.5373489],
                [102.3287645, 22.5370695],
                [102.3288306, 22.5367382],
                [102.3291976, 22.5362735],
                [102.3300415, 22.5358443],
                [102.3302598, 22.5353493],
                [102.330234, 22.5343656],
                [102.3306026, 22.5338177],
                [102.3308439, 22.5336832],
                [102.3312883, 22.5338568],
                [102.3321454, 22.5342866],
                [102.3328315, 22.5342979],
                [102.3337616, 22.5340364],
                [102.3339187, 22.5336235],
                [102.3339305, 22.5330143],
                [102.3340624, 22.5323517],
                [102.3349377, 22.5318399],
                [102.3359929, 22.5312757],
                [102.3365362, 22.5309523],
                [102.336572, 22.5306482],
                [102.3364265, 22.530452],
                [102.3361623, 22.5302259],
                [102.3361063, 22.5300311],
                [102.336319, 22.5298408],
                [102.3366789, 22.5297359],
                [102.337607, 22.5295851],
                [102.3379855, 22.5293005],
                [102.3381078, 22.5291363],
                [102.3386645, 22.5281207],
                [102.3389979, 22.5278491],
                [102.3392664, 22.5278536],
                [102.3395326, 22.5279689],
                [102.3399178, 22.5281137],
                [102.3409587, 22.5282972],
                [102.3418142, 22.5288099],
                [102.3423501, 22.5288741],
                [102.3425627, 22.5286837],
                [102.3425386, 22.5283786],
                [102.3419063, 22.5271217],
                [102.3413021, 22.5259483],
                [102.3409846, 22.5253891],
                [102.3406964, 22.524858],
                [102.3403421, 22.5246584],
                [102.3398941, 22.5246787],
                [102.3395655, 22.5247009],
                [102.3394492, 22.5245328],
                [102.3390255, 22.5240686],
                [102.3389696, 22.5238739],
                [102.3389414, 22.5237904],
                [102.3386495, 22.523453],
                [102.3379405, 22.5230813],
                [102.3377365, 22.5228286],
                [102.3377423, 22.522524],
                [102.3382834, 22.5223114],
                [102.3390052, 22.5220187],
                [102.3392209, 22.521662],
                [102.3391952, 22.5214401],
                [102.3389902, 22.5212428],
                [102.3386669, 22.5209882],
                [102.3384916, 22.5207914],
                [102.3384362, 22.5205689],
                [102.3387533, 22.5188152],
                [102.3390116, 22.5177946],
                [102.3397491, 22.5166711],
                [102.340294, 22.5162646],
                [102.3412218, 22.5161138],
                [102.341463, 22.5159792],
                [102.341527, 22.5157587],
                [102.3414454, 22.5153419],
                [102.3414607, 22.5145389],
                [102.342268, 22.5136797],
                [102.3431285, 22.5123643],
                [102.3435667, 22.5112913],
                [102.3435133, 22.510958],
                [102.3432524, 22.5105659],
                [102.3429924, 22.5101185],
                [102.3429999, 22.5097308],
                [102.3431543, 22.5094563],
                [102.3433114, 22.5090434],
                [102.3434581, 22.5083897],
                [102.3437968, 22.5078418],
                [102.3443342, 22.507823],
                [102.3450459, 22.508056],
                [102.3457605, 22.5081232],
                [102.3459411, 22.5080432],
                [102.3460028, 22.5079336],
                [102.3460092, 22.5076014],
                [102.345927, 22.5072126],
                [102.3448901, 22.5068357],
                [102.3445379, 22.5065255],
                [102.3444281, 22.5060255],
                [102.3445533, 22.5057231],
                [102.3448866, 22.5054519],
                [102.3451865, 22.5053737],
                [102.3458123, 22.5054117],
                [102.3463481, 22.5054759],
                [102.346821, 22.5057051],
                [102.3473244, 22.5059071],
                [102.3479203, 22.5059447],
                [102.3483108, 22.5058127],
                [102.348406, 22.5055098],
                [102.3484129, 22.5051502],
                [102.3483042, 22.5045948],
                [102.3480159, 22.5040642],
                [102.3474583, 22.5035845],
                [102.3469906, 22.5030785],
                [102.3469085, 22.5026897],
                [102.3469212, 22.5020257],
                [102.3470903, 22.5009766],
                [102.3471318, 22.5003685],
                [102.3470775, 22.5000909],
                [102.3469635, 22.4998122],
                [102.3470576, 22.4995646],
                [102.3472701, 22.4993743],
                [102.3480476, 22.4992765],
                [102.3482298, 22.499061],
                [102.3483282, 22.4987262],
                [102.3482876, 22.4985738],
                [102.3481426, 22.498418],
                [102.3480919, 22.4983992],
                [102.3478998, 22.4982753],
                [102.3477097, 22.4980457],
                [102.3476761, 22.4979296],
                [102.347815, 22.4976245],
                [102.3478347, 22.4974437],
                [102.3478544, 22.4972627],
                [102.3478095, 22.4970656],
                [102.347747, 22.4969438],
                [102.347653, 22.4967761],
                [102.3475582, 22.4966538],
                [102.3474471, 22.4965311],
                [102.3473363, 22.4963934],
                [102.3471951, 22.4961494],
                [102.3471519, 22.4958617],
                [102.3471088, 22.4955741],
                [102.3471305, 22.4952875],
                [102.3474085, 22.4947258],
                [102.3477096, 22.4942325],
                [102.3479097, 22.4939337],
                [102.348045, 22.4936339],
                [102.3481463, 22.4934241],
                [102.3481973, 22.4933041],
                [102.3482183, 22.4930477],
                [102.3482387, 22.4928216],
                [102.3481783, 22.492594],
                [102.3481017, 22.4923662],
                [102.3479929, 22.4921228],
                [102.347467, 22.4912307],
                [102.3473577, 22.4910174],
                [102.3473292, 22.4908207],
                [102.3473314, 22.4906999],
                [102.3473979, 22.4906104],
                [102.3474965, 22.4905365],
                [102.3476758, 22.490479],
                [102.3478057, 22.490466],
                [102.3481306, 22.4904261],
                [102.3484873, 22.4904169],
                [102.3489241, 22.4904392],
                [102.3494248, 22.4905229],
                [102.3497791, 22.4906345],
                [102.3499706, 22.4907886],
                [102.3503082, 22.4909302],
                [102.3505176, 22.4909939],
                [102.3509705, 22.4910316],
                [102.3513114, 22.491007],
                [102.3516039, 22.4909514],
                [102.3519455, 22.4908816],
                [102.3523358, 22.4908125],
                [102.3529044, 22.4907312],
                [102.354613, 22.4907668],
                [102.3550681, 22.4906836],
                [102.3554915, 22.4905848],
                [102.3557679, 22.490529],
                [102.3560117, 22.4904877],
                [102.3562062, 22.4904909],
                [102.3564976, 22.4904957],
                [102.3567082, 22.4904991],
                [102.3569671, 22.4905185],
                [102.3571455, 22.4905063],
                [102.3573481, 22.490502],
                [102.3575601, 22.4904301],
                [102.3578213, 22.4903287],
                [102.3579854, 22.4902105],
                [102.3581669, 22.4900474],
                [102.3583154, 22.4898988],
                [102.3586623, 22.4895572],
                [102.3588112, 22.4893935],
                [102.3589915, 22.4892756],
                [102.3593178, 22.4891601],
                [102.3594983, 22.4890423],
                [102.3595487, 22.4889525],
                [102.3595851, 22.4887416],
                [102.359586, 22.4886964],
                [102.3594933, 22.4884533],
                [102.3594158, 22.4882708],
                [102.3593854, 22.4881645],
                [102.3593393, 22.4880279],
                [102.3592461, 22.4878149],
                [102.3591524, 22.4876321],
                [102.3590587, 22.4874494],
                [102.3590258, 22.4870487],
                [102.3590026, 22.486565],
                [102.3590921, 22.4861134],
                [102.35913, 22.4858271],
                [102.359138, 22.4854043],
                [102.3591441, 22.4850873],
                [102.3591521, 22.4846646],
                [102.3592929, 22.4840779],
                [102.3594756, 22.4834089],
                [102.3599116, 22.4826307],
                [102.3602304, 22.4820469],
                [102.3609659, 22.480555],
                [102.3612709, 22.4798361],
                [102.3612872, 22.4789767],
                [102.3617108, 22.4771287],
                [102.3617313, 22.4760433],
                [102.3617448, 22.4757015],
                [102.3618185, 22.4753025],
                [102.3620214, 22.4750596],
                [102.3622224, 22.4749089],
                [102.3626589, 22.4748972],
                [102.3628808, 22.4749714],
                [102.363037, 22.4750235],
                [102.3639965, 22.4752299],
                [102.3648699, 22.475353],
                [102.365424, 22.4753892],
                [102.3659503, 22.4753434],
                [102.3666554, 22.47511],
                [102.3674226, 22.4746871],
                [102.3683669, 22.4741582],
                [102.3692849, 22.4734656],
                [102.3698533, 22.4727402],
                [102.3703654, 22.471905],
                [102.3704555, 22.4717703],
                [102.3708381, 22.4716132],
                [102.3711303, 22.4715908],
                [102.3716261, 22.4716261],
                [102.3727787, 22.4716586],
                [102.3757889, 22.4714898],
                [102.3767542, 22.4713967],
                [102.3773127, 22.4711881],
                [102.3779026, 22.4708711],
                [102.3793848, 22.4696705],
                [102.3800659, 22.4691646],
                [102.3803613, 22.4689789],
                [102.3808872, 22.4689602],
                [102.3812077, 22.4689926],
                [102.3814689, 22.4690785],
                [102.3818165, 22.4692202],
                [102.3825415, 22.4694769],
                [102.3830663, 22.4695127],
                [102.3836799, 22.4694954],
                [102.3843817, 22.4694251],
                [102.3847642, 22.469268],
                [102.3852352, 22.469058],
                [102.385651, 22.4686838],
                [102.3860401, 22.468173],
                [102.386507, 22.4674049],
                [102.3865372, 22.4657998],
                [102.3870256, 22.4646648],
                [102.3874515, 22.4637464],
                [102.3881068, 22.4630494],
                [102.3887878, 22.4625433],
                [102.3904978, 22.4616458],
                [102.3916456, 22.4611472],
                [102.3922657, 22.4607763],
                [102.3925026, 22.4605897],
                [102.3925958, 22.4602918],
                [102.3926014, 22.4599925],
                [102.392434, 22.4595817],
                [102.3924298, 22.4590236],
                [102.3924997, 22.4568476],
                [102.3923799, 22.4554577],
                [102.3921285, 22.454855],
                [102.3916199, 22.4539487],
                [102.3913347, 22.4535902],
                [102.3913115, 22.4532633],
                [102.391376, 22.4529377],
                [102.3915306, 22.4524776],
                [102.391805, 22.4518561],
                [102.3918489, 22.4510677],
                [102.3918192, 22.4503188],
                [102.3916045, 22.4493084],
                [102.3910545, 22.4475033],
                [102.3907205, 22.4450759],
                [102.3906798, 22.4417959],
                [102.390693, 22.4410885],
                [102.3906786, 22.440299],
                [102.3907139, 22.4399731],
                [102.3908397, 22.4394852],
                [102.3912605, 22.4388388],
                [102.3918281, 22.4381405],
                [102.3930464, 22.4369899],
                [102.3950716, 22.4348183],
                [102.3962007, 22.4337479],
                [102.3967075, 22.4331846],
                [102.3969212, 22.432671],
                [102.3969275, 22.4323444],
                [102.396849, 22.4310743],
                [102.3966932, 22.4300382],
                [102.3965917, 22.4292206],
                [102.396283, 22.4285627],
                [102.3962414, 22.4276644],
                [102.3963411, 22.4270133],
                [102.3964397, 22.4264164],
                [102.3968132, 22.4251712],
                [102.3971494, 22.4243606],
                [102.3973334, 22.423542],
                [102.397354, 22.4234381],
                [102.3974098, 22.4232144],
                [102.3974996, 22.4230074],
                [102.3975539, 22.4228639],
                [102.3976106, 22.422592],
                [102.3976021, 22.4221267],
                [102.3975623, 22.4210272],
                [102.3975438, 22.4201767],
                [102.3975338, 22.4197915],
                [102.3975549, 22.4195831],
                [102.3976961, 22.4193931],
                [102.3980682, 22.4188055],
                [102.398192, 22.418631],
                [102.3982291, 22.4184872],
                [102.3982311, 22.4183749],
                [102.3981988, 22.4182622],
                [102.3980642, 22.4180995],
                [102.3978768, 22.4180003],
                [102.3976886, 22.417949],
                [102.3974997, 22.41793],
                [102.3972071, 22.4179414],
                [102.3965436, 22.4180028],
                [102.3959207, 22.4182013],
                [102.3951388, 22.4186219],
                [102.3936914, 22.4196734],
                [102.3929044, 22.4203665],
                [102.3927095, 22.4206681],
                [102.3925837, 22.4209549],
                [102.3924243, 22.4211929],
                [102.3922667, 22.4213508],
                [102.3921448, 22.4214291],
                [102.3919894, 22.4214586],
                [102.3919035, 22.4214572],
                [102.3918009, 22.4214235],
                [102.3916144, 22.4212761],
                [102.3914455, 22.4211129],
                [102.3912947, 22.4208859],
                [102.3912429, 22.4204439],
                [102.3912166, 22.4200103],
                [102.3912234, 22.4196415],
                [102.3912384, 22.4188396],
                [102.3912556, 22.4179255],
                [102.3913816, 22.4176227],
                [102.3915584, 22.417369],
                [102.3918136, 22.4170602],
                [102.3919734, 22.4167901],
                [102.3924319, 22.4161878],
                [102.3925589, 22.415837],
                [102.3925625, 22.4156445],
                [102.3925148, 22.4154352],
                [102.3923645, 22.4151922],
                [102.3921793, 22.4149806],
                [102.3918743, 22.4147351],
                [102.3913982, 22.4144387],
                [102.3908538, 22.414109],
                [102.3905416, 22.4137911],
                [102.3904435, 22.4135169],
                [102.3903461, 22.4132105],
                [102.390162, 22.4129348],
                [102.3899435, 22.4126586],
                [102.3895873, 22.4123961],
                [102.3891437, 22.4121964],
                [102.3880841, 22.4118263],
                [102.3874515, 22.4116237],
                [102.3872425, 22.411536],
                [102.3870009, 22.4114348],
                [102.3868837, 22.4113466],
                [102.3868838, 22.4111189],
                [102.387171, 22.4106689],
                [102.3876813, 22.4091077],
                [102.3883301, 22.4078625],
                [102.3886278, 22.407374],
                [102.3889696, 22.4071105],
                [102.3895019, 22.4069398],
                [102.389988, 22.4066786],
                [102.3903801, 22.4062813],
                [102.390532, 22.4058801],
                [102.3907327, 22.4054349],
                [102.3909808, 22.4050353],
                [102.3910887, 22.4044092],
                [102.3912405, 22.4040081],
                [102.3913443, 22.4036062],
                [102.3914032, 22.4030243],
                [102.3920426, 22.4022722],
                [102.392877, 22.4013888],
                [102.3936574, 22.4008184],
                [102.3939543, 22.4006182],
                [102.3940472, 22.4005557],
                [102.3943382, 22.4004259],
                [102.3946757, 22.4003864],
                [102.395203, 22.4004846],
                [102.395537, 22.4006245],
                [102.3959657, 22.4008556],
                [102.396489, 22.4011779],
                [102.3969674, 22.4013203],
                [102.3975429, 22.4014192],
                [102.3979757, 22.4014261],
                [102.3984598, 22.4012546],
                [102.3988945, 22.4011719],
                [102.3993314, 22.4009547],
                [102.3998183, 22.4006485],
                [102.4001615, 22.4002954],
                [102.4004095, 22.3998958],
                [102.4005805, 22.399483],
                [102.400708, 22.3991752],
                [102.4011614, 22.3983965],
                [102.4013857, 22.398138],
                [102.4018009, 22.3977371],
                [102.4023403, 22.3973963],
                [102.4031003, 22.397001],
                [102.4036078, 22.3966889],
                [102.404024, 22.3962297],
                [102.4043186, 22.3955649],
                [102.4046846, 22.3944352],
                [102.4051483, 22.3931034],
                [102.4056099, 22.391888],
                [102.4062541, 22.3909667],
                [102.4065438, 22.3905637],
                [102.4077747, 22.3892921],
                [102.4083827, 22.3886317],
                [102.4087967, 22.3882888],
                [102.4090188, 22.3881466],
                [102.4094312, 22.3878911],
                [102.4100301, 22.3877258],
                [102.4126941, 22.3877976],
                [102.4147598, 22.3880055],
                [102.4165138, 22.3881209],
                [102.4169064, 22.3880835],
                [102.4173814, 22.3878289],
                [102.4182434, 22.3869976],
                [102.418913, 22.3863966],
                [102.4193881, 22.3861419],
                [102.419955, 22.3860052],
                [102.4202707, 22.3858937],
                [102.4208594, 22.3854224],
                [102.4216571, 22.3846775],
                [102.4224871, 22.383875],
                [102.4229373, 22.3832702],
                [102.4233561, 22.382665],
                [102.4236809, 22.3820584],
                [102.4240497, 22.3807532],
                [102.424127, 22.3799677],
                [102.4239283, 22.3771383],
                [102.4239076, 22.3765552],
                [102.4239327, 22.3760457],
                [102.4240347, 22.3756103],
                [102.4241656, 22.3753211],
                [102.424776, 22.3745149],
                [102.4252272, 22.3738521],
                [102.4254866, 22.37339],
                [102.4257145, 22.3729274],
                [102.4258183, 22.3724047],
                [102.4258603, 22.3718225],
                [102.4258466, 22.3708608],
                [102.4259641, 22.3704403],
                [102.4260296, 22.3702956],
                [102.4262218, 22.3700655],
                [102.4266331, 22.3698682],
                [102.4274853, 22.3695612],
                [102.4296264, 22.3690707],
                [102.4320936, 22.3678862],
                [102.4353686, 22.3654033],
                [102.4357636, 22.3652202],
                [102.4362663, 22.3651699],
                [102.4375449, 22.3655397],
                [102.4391371, 22.3659147],
                [102.4402661, 22.3659033],
                [102.4407075, 22.3657647],
                [102.4413429, 22.3653084],
                [102.4423326, 22.3643335],
                [102.4443152, 22.3622087],
                [102.4449209, 22.3616647],
                [102.4453793, 22.3614534],
                [102.4463516, 22.3614396],
                [102.4472647, 22.3612209],
                [102.4479935, 22.3607954],
                [102.4498418, 22.3588325],
                [102.4498852, 22.3584943],
                [102.4514437, 22.3564064],
                [102.4518003, 22.3559075],
                [102.4519584, 22.3556861],
                [102.4521565, 22.3551357],
                [102.4523361, 22.3538856],
                [102.4524157, 22.3529545],
                [102.4526425, 22.3525501],
                [102.4529957, 22.3520895],
                [102.4546478, 22.3508917],
                [102.4564569, 22.3496672],
                [102.4566651, 22.3494228],
                [102.4567342, 22.3490743],
                [102.4566137, 22.3453429],
                [102.4566728, 22.3446883],
                [102.4566555, 22.3439012],
                [102.4565451, 22.3430836],
                [102.4563655, 22.3426147],
                [102.456186, 22.3421457],
                [102.4561609, 22.3417956],
                [102.4562969, 22.3412151],
                [102.4566192, 22.3407248],
                [102.4570989, 22.3402078],
                [102.4573856, 22.3399501],
                [102.4581159, 22.3394371],
                [102.4615608, 22.3378886],
                [102.4628758, 22.3371079],
                [102.4635105, 22.3366808],
                [102.4641164, 22.3361075],
                [102.465298, 22.3349023],
                [102.4668662, 22.3331204],
                [102.4672672, 22.3326167],
                [102.467624, 22.3319521],
                [102.4679218, 22.3310827],
                [102.4684745, 22.3299841],
                [102.4689238, 22.3294084],
                [102.4693082, 22.3289482],
                [102.4696909, 22.3285753],
                [102.4702938, 22.3281769],
                [102.4708307, 22.3279522],
                [102.471555, 22.3277595],
                [102.4722487, 22.3275373],
                [102.472596, 22.3273969],
                [102.4731967, 22.327115],
                [102.4733873, 22.3269723],
                [102.473595, 22.326757],
                [102.4736659, 22.3262918],
                [102.4736833, 22.3253306],
                [102.4736459, 22.3239315],
                [102.4736574, 22.3232905],
                [102.4736982, 22.3227667],
                [102.4739872, 22.3215038],
                [102.4741963, 22.3203416],
                [102.4743661, 22.3196157],
                [102.4745933, 22.3191822],
                [102.47488, 22.3189245],
                [102.4754169, 22.3186997],
                [102.4761444, 22.3183323],
                [102.4767162, 22.3179041],
                [102.477579, 22.3169851],
                [102.4793364, 22.3151186],
                [102.4814611, 22.3128643],
                [102.4817813, 22.3124905],
                [102.4819144, 22.3120555],
                [102.4819514, 22.3117356],
                [102.4819906, 22.3112991],
                [102.482093, 22.3108346],
                [102.482352, 22.3103724],
                [102.4834684, 22.3092824],
                [102.484227, 22.3080559],
                [102.4844891, 22.3074189],
                [102.4847791, 22.3069863],
                [102.4849701, 22.3068146],
                [102.4860745, 22.3063946],
                [102.4871496, 22.3058576],
                [102.4880708, 22.3051727],
                [102.4889961, 22.3042546],
                [102.4897323, 22.3033918],
                [102.4902944, 22.3026283],
                [102.4908772, 22.3015885],
                [102.4910416, 22.3011539],
                [102.4910754, 22.3010088],
                [102.4910807, 22.3007174],
                [102.4910869, 22.3003679],
                [102.4909694, 22.299929],
                [102.4908478, 22.2997231],
                [102.4905407, 22.2993688],
                [102.4903882, 22.2991333],
                [102.490207, 22.2987517],
                [102.4901188, 22.2984298],
                [102.4900373, 22.2977293],
                [102.490063, 22.2971615],
                [102.4901707, 22.2964056],
                [102.4903335, 22.2960584],
                [102.4906249, 22.2955384],
                [102.4915797, 22.2947083],
                [102.4925687, 22.2937328],
                [102.4935745, 22.2926848],
                [102.4940204, 22.2922839],
                [102.4942105, 22.2921703],
                [102.4943044, 22.2921717],
                [102.4943979, 22.2922023],
                [102.4946141, 22.2923804],
                [102.4950413, 22.293028],
                [102.4951682, 22.2931501],
                [102.4952877, 22.293265],
                [102.4955994, 22.2934163],
                [102.496149, 22.2933259],
                [102.4962831, 22.2932625],
                [102.4965969, 22.2931144],
                [102.4967821, 22.2929364],
                [102.4969413, 22.2927684],
                [102.4971256, 22.2925515],
                [102.4973853, 22.2921683],
                [102.4976717, 22.2917215],
                [102.4978394, 22.2914891],
                [102.4980963, 22.2912579],
                [102.4982924, 22.2911088],
                [102.4984739, 22.2909318],
                [102.4987613, 22.2906735],
                [102.4990938, 22.2903743],
                [102.4997045, 22.2899066],
                [102.5002541, 22.2894211],
                [102.5013604, 22.2882625],
                [102.5019006, 22.2874043],
                [102.5033597, 22.2848834],
                [102.5048895, 22.2833197],
                [102.5059365, 22.2820343],
                [102.5064334, 22.2809627],
                [102.5066467, 22.2804898],
                [102.506857, 22.2801757],
                [102.5071356, 22.2798624],
                [102.5074136, 22.279581],
                [102.5077969, 22.2791425],
                [102.5080409, 22.2788606],
                [102.5082514, 22.2785464],
                [102.5084634, 22.278137],
                [102.5087835, 22.2774118],
                [102.5093823, 22.2763734],
                [102.5098404, 22.2755551],
                [102.509779, 22.2751732],
                [102.5096475, 22.2748855],
                [102.5094141, 22.2745645],
                [102.5092498, 22.2742129],
                [102.5091234, 22.2736395],
                [102.5090949, 22.2733217],
                [102.5091391, 22.2727509],
                [102.50925, 22.2722765],
                [102.5108607, 22.2699838],
                [102.5121984, 22.2676871],
                [102.513328, 22.2636413],
                [102.5133427, 22.2628161],
                [102.513538, 22.2614224],
                [102.5136174, 22.2607888],
                [102.5136302, 22.2607716],
                [102.5136874, 22.2606945],
                [102.5139649, 22.2604449],
                [102.5144449, 22.2603252],
                [102.5148907, 22.2602051],
                [102.5157163, 22.2598368],
                [102.5163076, 22.259211],
                [102.5167915, 22.2588692],
                [102.5191398, 22.2573179],
                [102.520597, 22.2559751],
                [102.5219926, 22.2542506],
                [102.5232114, 22.2528724],
                [102.5243178, 22.252064],
                [102.5267555, 22.251244],
                [102.5290012, 22.249691],
                [102.5324197, 22.2474256],
                [102.5329068, 22.2468933],
                [102.5330126, 22.2467045],
                [102.5331892, 22.2463579],
                [102.5332944, 22.2462008],
                [102.5336049, 22.2460151],
                [102.5338101, 22.2459864],
                [102.5348627, 22.2462882],
                [102.5353741, 22.2463276],
                [102.5364368, 22.2460581],
                [102.5371611, 22.2456246],
                [102.5376823, 22.245093],
                [102.5380779, 22.2439378],
                [102.5389773, 22.2435417],
                [102.5403966, 22.243125],
                [102.5416154, 22.2426428],
                [102.5422251, 22.2423703],
                [102.5428334, 22.2421917],
                [102.5435425, 22.2420146],
                [102.5442823, 22.2419945],
                [102.5453546, 22.2421984],
                [102.5475321, 22.2426383],
                [102.5494446, 22.2428548],
                [102.5508854, 22.243127],
                [102.5524288, 22.2433067],
                [102.5534658, 22.243604],
                [102.5541011, 22.2438014],
                [102.5544339, 22.2439942],
                [102.5545465, 22.2440617],
                [102.5547603, 22.2441898],
                [102.5552407, 22.2454142],
                [102.5562165, 22.2470082],
                [102.5573869, 22.2484974],
                [102.5587877, 22.250062],
                [102.5591126, 22.251359],
                [102.5591412, 22.2519338],
                [102.55913, 22.2525798],
                [102.5592295, 22.2535145],
                [102.5593016, 22.2538027],
                [102.5594884, 22.2541644],
                [102.559751, 22.2545992],
                [102.5599763, 22.2549615],
                [102.5608034, 22.2562302],
                [102.561735, 22.2581467],
                [102.5622622, 22.2589083],
                [102.5626025, 22.2593083],
                [102.5629063, 22.2596001],
                [102.5632866, 22.2599287],
                [102.5635541, 22.2600764],
                [102.5637833, 22.2602234],
                [102.5640134, 22.2602987],
                [102.5642835, 22.2603027],
                [102.565835, 22.2598594],
                [102.5670343, 22.2596978],
                [102.5679186, 22.2598906],
                [102.5690687, 22.2603385],
                [102.5696058, 22.260526],
                [102.5699899, 22.2606394],
                [102.5706452, 22.2606851],
                [102.5712246, 22.2606579],
                [102.5719596, 22.2605613],
                [102.5734314, 22.2602602],
                [102.5755536, 22.2602919],
                [102.578287, 22.2606916],
                [102.5785185, 22.2606951],
                [102.579139, 22.2605248],
                [102.5794134, 22.2602777],
                [102.5796511, 22.2599223],
                [102.5805228, 22.2586071],
                [102.5827977, 22.2564873],
                [102.5832651, 22.2562429],
                [102.5836944, 22.2559622],
                [102.5847423, 22.2556188],
                [102.5856795, 22.2549866],
                [102.5859545, 22.2547036],
                [102.5861535, 22.2543475],
                [102.5863526, 22.2539916],
                [102.5865548, 22.2534562],
                [102.5868354, 22.2528501],
                [102.587231, 22.2522816],
                [102.5888351, 22.2510132],
                [102.5922941, 22.2495928],
                [102.592606, 22.249418],
                [102.5929189, 22.2491714],
                [102.5932704, 22.2489253],
                [102.5934658, 22.2487846],
                [102.5938247, 22.248108],
                [102.5939086, 22.2477144],
                [102.5939949, 22.2471771],
                [102.594041, 22.2467471],
                [102.5941622, 22.2464258],
                [102.5943606, 22.2461057],
                [102.5956425, 22.2456222],
                [102.5975855, 22.2448287],
                [102.5985398, 22.2443216],
                [102.6005209, 22.2431547],
                [102.6025857, 22.2410446],
                [102.6033465, 22.2400485],
                [102.6043123, 22.2389296],
                [102.6049283, 22.238498],
                [102.605674, 22.238383],
                [102.606274, 22.2388955],
                [102.6068751, 22.239345],
                [102.6078183, 22.2395478],
                [102.6089023, 22.2394379],
                [102.6106719, 22.2387713],
                [102.6131314, 22.2372965],
                [102.6142967, 22.2363692],
                [102.6149835, 22.2357497],
                [102.6157314, 22.235509],
                [102.6167466, 22.2354608],
                [102.6174903, 22.2354717],
                [102.6185762, 22.2352358],
                [102.6193349, 22.2343655],
                [102.619691, 22.2333004],
                [102.6197872, 22.2316021],
                [102.6196111, 22.2300255],
                [102.6200013, 22.2289399],
                [102.6204207, 22.2281269],
                [102.6205676, 22.227436],
                [102.6202382, 22.2269272],
                [102.6196392, 22.2263515],
                [102.6186422, 22.2253287],
                [102.6179082, 22.224751],
                [102.6170433, 22.2239192],
                [102.6163124, 22.2231525],
                [102.6160526, 22.2225186],
                [102.6161277, 22.2220788],
                [102.6175234, 22.219516],
                [102.6176615, 22.2173444],
                [102.6173363, 22.2165836],
                [102.6166741, 22.2157548],
                [102.6162106, 22.215181],
                [102.6160849, 22.2146121],
                [102.6164334, 22.2139872],
                [102.6171202, 22.2133672],
                [102.6178722, 22.2128742],
                [102.6182165, 22.2125012],
                [102.6185094, 22.2111824],
                [102.6187461, 22.2091697],
                [102.6184775, 22.2070551],
                [102.6180204, 22.2061034],
                [102.6176318, 22.2050897],
                [102.6173172, 22.203699],
                [102.6174263, 22.2012434],
                [102.6180708, 22.1991108],
                [102.6183238, 22.1981378],
                [102.618534, 22.1976999],
                [102.619078, 22.1975189],
                [102.6200263, 22.1974067],
                [102.6209737, 22.1973575],
                [102.6220561, 22.1973104],
                [102.62301, 22.1968833],
                [102.6247399, 22.1945144],
                [102.6264698, 22.1921454],
                [102.6274626, 22.1893877],
                [102.6280557, 22.1882938],
                [102.6288772, 22.1876757],
                [102.6298278, 22.1874375],
                [102.6310442, 22.1874552],
                [102.6333379, 22.1877406],
                [102.6346897, 22.1877602],
                [102.6356432, 22.1873331],
                [102.6364648, 22.1867149],
                [102.6367509, 22.1857739],
                [102.6369104, 22.1843271],
                [102.6368766, 22.1823105],
                [102.6371014, 22.1809907],
                [102.637794, 22.1799926],
                [102.6390559, 22.1773018],
                [102.6395384, 22.1767417],
                [102.6405594, 22.1763155],
                [102.6416429, 22.1762051],
                [102.6426639, 22.1757789],
                [102.6434167, 22.1752228],
                [102.6442382, 22.1746046],
                [102.6448528, 22.1742355],
                [102.6453268, 22.1741793],
                [102.6461378, 22.174191],
                [102.6474187, 22.1743986],
                [102.6486299, 22.174731],
                [102.6507217, 22.1749502],
                [102.6537566, 22.175372],
                [102.65531, 22.1754574],
                [102.6576118, 22.1752385],
                [102.6591726, 22.1748829],
                [102.6606708, 22.1742114],
                [102.6626473, 22.1732316],
                [102.6639521, 22.1719902],
                [102.6650053, 22.1716588],
                [102.6661564, 22.1715492],
                [102.6668393, 22.1711179],
                [102.6677325, 22.1702487],
                [102.6684904, 22.1693775],
                [102.6689738, 22.1687544],
                [102.6695883, 22.1683851],
                [102.6719577, 22.168167],
                [102.6736523, 22.1678761],
                [102.6753532, 22.1672073],
                [102.6761683, 22.166967],
                [102.6767713, 22.1672906],
                [102.6774316, 22.1682451],
                [102.6780169, 22.1696396],
                [102.6784756, 22.1705281],
                [102.6788083, 22.1708479],
                [102.6795484, 22.1710475],
                [102.6801475, 22.171623],
                [102.6810074, 22.1727695],
                [102.6814567, 22.174225],
                [102.6817105, 22.1752367],
                [102.6819498, 22.1771303],
                [102.6826726, 22.1784007],
                [102.683338, 22.1790403],
                [102.6841402, 22.1794309],
                [102.6850046, 22.1803248],
                [102.6855995, 22.1811521],
                [102.6865265, 22.1823616],
                [102.686981, 22.1835017],
                [102.6872184, 22.1855202],
                [102.6876618, 22.1873528],
                [102.6880802, 22.1906963],
                [102.6881335, 22.1915787],
                [102.6883569, 22.1923808],
                [102.688821, 22.1929546],
                [102.6894877, 22.1935313],
                [102.690296, 22.1937317],
                [102.6912373, 22.1940602],
                [102.6920352, 22.1948909],
                [102.6925678, 22.1954025],
                [102.6932366, 22.1958531],
                [102.6941698, 22.1966856],
                [102.6949666, 22.1975792],
                [102.6956918, 22.1987238],
                [102.6961999, 22.2007477],
                [102.6973438, 22.2052382],
                [102.6976719, 22.2079213],
                [102.697719, 22.2091823],
                [102.697637, 22.2100635],
                [102.6963818, 22.2123775],
                [102.6958265, 22.2132519],
                [102.6949842, 22.2151305],
                [102.6947587, 22.2165138],
                [102.69424, 22.2192793],
                [102.6934674, 22.221033],
                [102.692497, 22.2224687],
                [102.6915328, 22.2235263],
                [102.6907796, 22.2240828],
                [102.6901629, 22.2245782],
                [102.6894577, 22.2263328],
                [102.6882137, 22.2279536],
                [102.6870976, 22.2300174],
                [102.686874, 22.2312745],
                [102.6873684, 22.2341174],
                [102.6873821, 22.2353466],
                [102.6871627, 22.2363517],
                [102.6857925, 22.2374036],
                [102.6839397, 22.2390157],
                [102.6824987, 22.2402555],
                [102.6818828, 22.2406879],
                [102.6812566, 22.2417503],
                [102.6806958, 22.2429396],
                [102.6800726, 22.243813],
                [102.6792163, 22.2444626],
                [102.6783309, 22.244828],
                [102.6773154, 22.2448766],
                [102.6766392, 22.2448668],
                [102.6761647, 22.244923],
                [102.675143, 22.2453496],
                [102.6745946, 22.2457829],
                [102.674216, 22.2459436],
                [102.6737897, 22.2463113],
                [102.6733659, 22.2465184],
                [102.6726014, 22.246543],
                [102.6717223, 22.2465658],
                [102.6707644, 22.2467299],
                [102.6701833, 22.247219],
                [102.6695204, 22.2480269],
                [102.6687699, 22.2495087],
                [102.6685285, 22.2502515],
                [102.6682175, 22.2505668],
                [102.6679084, 22.2507757],
                [102.666531, 22.250898],
                [102.6644954, 22.2515439],
                [102.6623392, 22.2525435],
                [102.6618761, 22.2528211],
                [102.6617962, 22.2530331],
                [102.6616763, 22.2533513],
                [102.6616716, 22.2536355],
                [102.6617443, 22.2538498],
                [102.6620816, 22.2542455],
                [102.6621945, 22.2543537],
                [102.662537, 22.2544297],
                [102.6629949, 22.2544719],
                [102.6632993, 22.2545474],
                [102.6635268, 22.2546573],
                [102.6637518, 22.2549093],
                [102.6639022, 22.2550535],
                [102.6646603, 22.2554197],
                [102.6664485, 22.2558719],
                [102.667896, 22.2561415],
                [102.6697878, 22.2572702],
                [102.6714991, 22.2585208],
                [102.6761348, 22.2616197],
                [102.6769141, 22.2622468],
                [102.6771434, 22.2622501],
                [102.6771441, 22.2622496],
                [102.6776673, 22.2618719],
                [102.677976, 22.2615652],
                [102.6783442, 22.2612628],
                [102.6787128, 22.2609298],
                [102.6789139, 22.2607482],
                [102.679116, 22.2605051],
                [102.6794232, 22.2598943],
                [102.6795949, 22.259497],
                [102.6797649, 22.2591918],
                [102.680067, 22.2588887],
                [102.6803352, 22.2586464],
                [102.6806017, 22.2584966],
                [102.6807683, 22.2584066],
                [102.6811354, 22.2581659],
                [102.681204, 22.258013],
                [102.6812724, 22.2578603],
                [102.6812771, 22.2575836],
                [102.6812211, 22.2569677],
                [102.6812593, 22.2566607],
                [102.6813953, 22.2564166],
                [102.6815624, 22.2562961],
                [102.681762, 22.2562066],
                [102.682026, 22.2562104],
                [102.6821909, 22.2562127],
                [102.6823891, 22.2562155],
                [102.6826207, 22.256188],
                [102.6827207, 22.256128],
                [102.6829207, 22.2560078],
                [102.6831126, 22.2557479],
                [102.6833187, 22.2553781],
                [102.6834437, 22.2550818],
                [102.6838103, 22.2546771],
                [102.6840947, 22.2544203],
                [102.6846602, 22.2540929],
                [102.6850632, 22.2539123],
                [102.6854669, 22.2536945],
                [102.6861911, 22.2534439],
                [102.686434, 22.2532611],
                [102.6868796, 22.252932],
                [102.6871656, 22.2525634],
                [102.6874523, 22.2521575],
                [102.6876984, 22.2517884],
                [102.6881057, 22.2513469],
                [102.6883906, 22.2510529],
                [102.6886743, 22.2508332],
                [102.6899244, 22.2502175],
                [102.6919808, 22.2492405],
                [102.6925443, 22.2490249],
                [102.6929067, 22.248881],
                [102.6932304, 22.2486619],
                [102.6933121, 22.2485513],
                [102.6933152, 22.2483651],
                [102.6933171, 22.2482532],
                [102.6932783, 22.2481782],
                [102.6930813, 22.247989],
                [102.6926851, 22.2477598],
                [102.6921688, 22.2475289],
                [102.6920082, 22.2472728],
                [102.6919522, 22.2471079],
                [102.6919543, 22.2469711],
                [102.6919869, 22.24678],
                [102.6923718, 22.2465939],
                [102.6929026, 22.2464646],
                [102.6936705, 22.2462019],
                [102.6940848, 22.2460163],
                [102.6948555, 22.2455894],
                [102.6955371, 22.245216],
                [102.6958302, 22.2452475],
                [102.6962093, 22.2454171],
                [102.6964985, 22.2456948],
                [102.6969037, 22.2460562],
                [102.6972484, 22.2465263],
                [102.6975435, 22.2464484],
                [102.6979875, 22.2462358],
                [102.6986113, 22.2458068],
                [102.699264, 22.2454057],
                [102.6996467, 22.2453563],
                [102.7003229, 22.2453113],
                [102.7010863, 22.2453221],
                [102.7016447, 22.2453025],
                [102.7019401, 22.2451972],
                [102.7021514, 22.2448445],
                [102.7023958, 22.2442735],
                [102.702463, 22.2437545],
                [102.7024981, 22.2433993],
                [102.7027063, 22.2432381],
                [102.7029995, 22.2432696],
                [102.7032914, 22.2433831],
                [102.7037007, 22.2434984],
                [102.7040531, 22.2435034],
                [102.7046727, 22.2433206],
                [102.7054842, 22.2430946],
                [102.7062561, 22.2425859],
                [102.7070321, 22.2418314],
                [102.707811, 22.2408854],
                [102.7084329, 22.2405661],
                [102.7089957, 22.2402732],
                [102.7093859, 22.2397593],
                [102.709695, 22.2388067],
                [102.7099958, 22.2383734],
                [102.7103526, 22.2381051],
                [102.7106756, 22.2381097],
                [102.7110568, 22.2381423],
                [102.7115227, 22.2383949],
                [102.7119595, 22.2386198],
                [102.712602, 22.2388476],
                [102.7129785, 22.2391215],
                [102.7133528, 22.2395643],
                [102.7136082, 22.2401145],
                [102.7142123, 22.2408885],
                [102.7146767, 22.2412231],
                [102.715029, 22.241228],
                [102.7153542, 22.2410959],
                [102.7155044, 22.2408793],
                [102.7156561, 22.2405807],
                [102.7157573, 22.2397054],
                [102.715828, 22.238968],
                [102.7160099, 22.238615],
                [102.7163328, 22.2386195],
                [102.7166822, 22.2387885],
                [102.7171176, 22.2390955],
                [102.7175216, 22.2395387],
                [102.7178398, 22.2398167],
                [102.718104, 22.2398205],
                [102.7184567, 22.239798],
                [102.7187533, 22.2396107],
                [102.7192419, 22.2393529],
                [102.7198377, 22.2387573],
                [102.7210244, 22.2379809],
                [102.7217917, 22.2377182],
                [102.7224373, 22.2377272],
                [102.7230219, 22.2378721],
                [102.7236631, 22.2381545],
                [102.7244361, 22.2384476],
                [102.7250553, 22.2382651],
                [102.7256456, 22.2380548],
                [102.7261759, 22.2379255],
                [102.7267929, 22.2378796],
                [102.7274089, 22.2378883],
                [102.7280529, 22.2379792],
                [102.7287559, 22.238071],
                [102.7291372, 22.2380764],
                [102.7294328, 22.2379438],
                [102.7295829, 22.2377274],
                [102.729615, 22.2375639],
                [102.7296181, 22.2373728],
                [102.7294535, 22.2366602],
                [102.7291413, 22.2360001],
                [102.7289518, 22.235014],
                [102.7290433, 22.2347967],
                [102.7295149, 22.2346667],
                [102.7301578, 22.2348397],
                [102.7306791, 22.2352567],
                [102.7310303, 22.2353163],
                [102.7312396, 22.2350733],
                [102.7311613, 22.2344712],
                [102.7311438, 22.2337333],
                [102.7310669, 22.2330494],
                [102.731073, 22.2326669],
                [102.7313681, 22.2325618],
                [102.7317789, 22.2325676],
                [102.7322477, 22.2326014],
                [102.7328293, 22.2329374],
                [102.7335556, 22.2333845],
                [102.7339357, 22.2334718],
                [102.7344642, 22.2334518],
                [102.7348781, 22.2332664],
                [102.7356177, 22.2328124],
                [102.7356523, 22.2324849],
                [102.7355158, 22.2318544],
                [102.7351726, 22.231303],
                [102.7351197, 22.2309468],
                [102.7351854, 22.2305105],
                [102.7353043, 22.2304029],
                [102.7355413, 22.2302695],
                [102.735952, 22.2302752],
                [102.7363629, 22.2302535],
                [102.7370266, 22.2300256],
                [102.7375591, 22.22965],
                [102.738034, 22.2293013],
                [102.7385356, 22.229117],
                [102.7393186, 22.2287541],
                [102.7395886, 22.2283755],
                [102.7396522, 22.2280761],
                [102.7396613, 22.2275028],
                [102.7396691, 22.2270114],
                [102.7396547, 22.2260827],
                [102.7396088, 22.2252902],
                [102.7398224, 22.2247743],
                [102.7400337, 22.224395],
                [102.7402167, 22.2239607],
                [102.7404574, 22.2235817],
                [102.7408447, 22.2232048],
                [102.7412579, 22.2230467],
                [102.7418426, 22.2230813],
                [102.7424865, 22.2231448],
                [102.7428681, 22.2231228],
                [102.7432226, 22.2229638],
                [102.743313, 22.2228012],
                [102.7433454, 22.2226104],
                [102.7432252, 22.2218798],
                [102.7431485, 22.2211689],
                [102.7432509, 22.2202694],
                [102.7436999, 22.2197023],
                [102.7442047, 22.2192998],
                [102.7447363, 22.2190615],
                [102.7452373, 22.2189047],
                [102.7457374, 22.2188024],
                [102.7461772, 22.2188085],
                [102.7466763, 22.2187608],
                [102.7473241, 22.2184972],
                [102.747741, 22.2180933],
                [102.7483967, 22.2174194],
                [102.7488481, 22.2166881],
                [102.7489936, 22.2158297],
                [102.7490602, 22.215339],
                [102.749299, 22.2150692],
                [102.7496537, 22.2148829],
                [102.7503033, 22.2145914],
                [102.7506606, 22.2142413],
                [102.750724, 22.2139417],
                [102.7507302, 22.2135593],
                [102.7507122, 22.2128489],
                [102.7505101, 22.2126548],
                [102.7503084, 22.2124336],
                [102.7501089, 22.2120757],
                [102.749985, 22.2115687],
                [102.7499917, 22.211159],
                [102.750184, 22.2101238],
                [102.7506372, 22.2092832],
                [102.7512324, 22.2087178],
                [102.7515527, 22.2084017],
                [102.7528683, 22.2080706],
                [102.7532207, 22.2072955],
                [102.7522227, 22.2071366],
                [102.751374, 22.2067475],
                [102.750978, 22.206197],
                [102.7509864, 22.2042477],
                [102.750656, 22.2023984],
                [102.7498383, 22.2000813],
                [102.7491473, 22.1968646],
                [102.7491707, 22.1953976],
                [102.7493665, 22.1943941],
                [102.7498887, 22.1926824],
                [102.7499758, 22.1914469],
                [102.7498603, 22.1902295],
                [102.7489379, 22.1888332],
                [102.747933, 22.1869746],
                [102.7475455, 22.1858793],
                [102.7476293, 22.1834489],
                [102.74765, 22.1821495],
                [102.7476694, 22.180934],
                [102.7476948, 22.1793412],
                [102.7474846, 22.178416],
                [102.7472268, 22.1776577],
                [102.7466147, 22.1765593],
                [102.7464388, 22.1763052],
                [102.7459085, 22.175711],
                [102.7436385, 22.1742123],
                [102.7424356, 22.1734829],
                [102.7413618, 22.1731326],
                [102.7405549, 22.1729537],
                [102.7397255, 22.1727955],
                [102.7391019, 22.1724096],
                [102.7383931, 22.1717289],
                [102.7376072, 22.1702507],
                [102.7372635, 22.1692397],
                [102.7372341, 22.168275],
                [102.7374817, 22.166853],
                [102.7371542, 22.1648362],
                [102.7369763, 22.1633035],
                [102.7371253, 22.1624251],
                [102.7374527, 22.1616332],
                [102.7380474, 22.1610125],
                [102.7383769, 22.1600948],
                [102.7387614, 22.1585489],
                [102.7402673, 22.1543773],
                [102.7405087, 22.1533326],
                [102.7410624, 22.1524599],
                [102.7411811, 22.1519995],
                [102.7407054, 22.1507579],
                [102.7404745, 22.1501373],
                [102.7403037, 22.1495175],
                [102.7402256, 22.1487588],
                [102.7399237, 22.1478706],
                [102.7395446, 22.1471077],
                [102.7392629, 22.1458971],
                [102.7392126, 22.145279],
                [102.739249, 22.1448867],
                [102.7396438, 22.1446677],
                [102.7400391, 22.1444205],
                [102.7402546, 22.1441148],
                [102.7403799, 22.1438078],
                [102.7404315, 22.1434017],
                [102.7406512, 22.1428436],
                [102.7411718, 22.1422894],
                [102.7413928, 22.141647],
                [102.7415858, 22.1408639],
                [102.741685, 22.1403042],
                [102.7419603, 22.1400273],
                [102.7422685, 22.1395826],
                [102.7423196, 22.1391995],
                [102.7424992, 22.1373792],
                [102.7425198, 22.1360896],
                [102.7425261, 22.1356971],
                [102.7428683, 22.1350008],
                [102.7433296, 22.134334],
                [102.7439462, 22.1334166],
                [102.7441091, 22.1326332],
                [102.7441176, 22.1321004],
                [102.7441228, 22.1317637],
                [102.7444588, 22.1314598],
                [102.745202, 22.1310863],
                [102.7464422, 22.1303605],
                [102.7472532, 22.1298296],
                [102.748019, 22.1292157],
                [102.7500613, 22.127944],
                [102.7521951, 22.1267152],
                [102.7539161, 22.1259839],
                [102.7546846, 22.1257428],
                [102.7551409, 22.1253297],
                [102.7552389, 22.1248277],
                [102.7554387, 22.1235722],
                [102.7555936, 22.122316],
                [102.755874, 22.1216488],
                [102.7570364, 22.1206791],
                [102.7578532, 22.1202289],
                [102.7587176, 22.1196117],
                [102.7604954, 22.1181262],
                [102.7612764, 22.1170884],
                [102.7617784, 22.1166338],
                [102.7619816, 22.1165785],
                [102.7623656, 22.1164741],
                [102.7632194, 22.1165278],
                [102.7646519, 22.1169668],
                [102.7659077, 22.1171938],
                [102.7665361, 22.1172862],
                [102.7670991, 22.1172309],
                [102.7675991, 22.1169022],
                [102.7683815, 22.1157805],
                [102.7706424, 22.1121624],
                [102.7723465, 22.1096271],
                [102.7741229, 22.1067782],
                [102.7751444, 22.104737],
                [102.7757587, 22.1028579],
                [102.7764955, 22.1016941],
                [102.777178, 22.1012004],
                [102.7780372, 22.1009185],
                [102.7792536, 22.1008094],
                [102.7801113, 22.1006115],
                [102.7811067, 22.1002477],
                [102.7815141, 22.1000855],
                [102.7810391, 22.0990577],
                [102.780694, 22.0984398],
                [102.7802855, 22.0981453],
                [102.7796692, 22.0979595],
                [102.7793328, 22.0972952],
                [102.7789125, 22.0964815],
                [102.7786043, 22.0960365],
                [102.777983, 22.0954771],
                [102.7773605, 22.0949913],
                [102.7769179, 22.094336],
                [102.7765761, 22.0935239],
                [102.7762859, 22.0919418],
                [102.7761063, 22.0908383],
                [102.7761908, 22.0904725],
                [102.7764322, 22.0901455],
                [102.7772317, 22.0893856],
                [102.7773944, 22.0890576],
                [102.777202, 22.0887613],
                [102.7770504, 22.0883923],
                [102.7770184, 22.0879147],
                [102.7771041, 22.0874755],
                [102.7775036, 22.0871139],
                [102.7781769, 22.0868662],
                [102.7789267, 22.0867663],
                [102.7797541, 22.0867408],
                [102.7806601, 22.0867164],
                [102.7813317, 22.0865787],
                [102.7820067, 22.0862208],
                [102.7827166, 22.0861572],
                [102.7837425, 22.0860243],
                [102.785242, 22.0858243],
                [102.7857174, 22.0856473],
                [102.7858795, 22.0853559],
                [102.7859683, 22.0846965],
                [102.7860923, 22.0843311],
                [102.786611, 22.0837853],
                [102.787562, 22.0833942],
                [102.7882705, 22.0834038],
                [102.7890531, 22.0837081],
                [102.7894074, 22.0837129],
                [102.7896054, 22.0836422],
                [102.7899247, 22.0833527],
                [102.7907014, 22.0827875],
                [102.7913034, 22.0823346],
                [102.7921532, 22.0816852],
                [102.7928285, 22.0812861],
                [102.7933601, 22.0808531],
                [102.7943152, 22.0801812],
                [102.7949487, 22.0799452],
                [102.7954613, 22.079891],
                [102.7959916, 22.0799531],
                [102.796877, 22.0799652],
                [102.7977095, 22.0795912],
                [102.7989601, 22.0788926],
                [102.8001483, 22.0784134],
                [102.8011594, 22.0779317],
                [102.8022937, 22.0771216],
                [102.8041398, 22.0761009],
                [102.8055665, 22.0754596],
                [102.8063648, 22.0749132],
                [102.8074391, 22.0741573],
                [102.8095008, 22.0735928],
                [102.8120666, 22.0720832],
                [102.8135287, 22.0705924],
                [102.8153401, 22.069332],
                [102.8158718, 22.0687699],
                [102.8166357, 22.0680728],
                [102.8166932, 22.0680789],
                [102.8168708, 22.0680537],
                [102.8174062, 22.067813],
                [102.8176762, 22.067541],
                [102.8180377, 22.0670775],
                [102.8185486, 22.0665056],
                [102.8191751, 22.0661008],
                [102.8200649, 22.0658646],
                [102.8209828, 22.0657116],
                [102.8218122, 22.0655573],
                [102.822377, 22.0653169],
                [102.8228663, 22.0651994],
                [102.8231611, 22.0652309],
                [102.8238083, 22.0654048],
                [102.8242512, 22.0654107],
                [102.8244592, 22.0653308],
                [102.8248799, 22.064868],
                [102.825092, 22.0645126],
                [102.8251224, 22.064458],
                [102.8255375, 22.0643533],
                [102.8261576, 22.0643615],
                [102.8269545, 22.0643996],
                [102.8281653, 22.0644158],
                [102.8292001, 22.0643468],
                [102.8302474, 22.0644296],
                [102.8312513, 22.0644429],
                [102.8320192, 22.0644531],
                [102.832787, 22.0644633],
                [102.8334333, 22.0646922],
                [102.8340788, 22.0649763],
                [102.8346082, 22.0651211],
                [102.8353174, 22.065103],
                [102.8358223, 22.0649167],
                [102.8364463, 22.064677],
                [102.8370693, 22.0644925],
                [102.8374978, 22.0644794],
                [102.8379096, 22.0645949],
                [102.8385846, 22.0648792],
                [102.8392862, 22.0653565],
                [102.8397533, 22.0657205],
                [102.840106, 22.0658353],
                [102.8405199, 22.0658134],
                [102.8406678, 22.0657878],
                [102.8414403, 22.0654951],
                [102.842063, 22.0653381],
                [102.8427123, 22.0653742],
                [102.8431826, 22.065518],
                [102.8432999, 22.0655747],
                [102.8436222, 22.0657441],
                [102.8438289, 22.0657468],
                [102.8441255, 22.0656681],
                [102.8444832, 22.0654526],
                [102.8448729, 22.0650723],
                [102.8452746, 22.0648703],
                [102.8456283, 22.0649163],
                [102.8460243, 22.0650867],
                [102.8463325, 22.0652147],
                [102.8465089, 22.0652584],
                [102.8471733, 22.0652671],
                [102.847749, 22.0652747],
                [102.8487695, 22.0651642],
                [102.849427, 22.0651383],
                [102.8496618, 22.0652241],
                [102.8498961, 22.0653649],
                [102.8503335, 22.0657288],
                [102.8507123, 22.0660643],
                [102.8510787, 22.0662433],
                [102.8515203, 22.0663316],
                [102.8519337, 22.0663371],
                [102.8524672, 22.0662064],
                [102.852943, 22.0659925],
                [102.8532407, 22.0658312],
                [102.8538939, 22.0655921],
                [102.8543663, 22.0655983],
                [102.8547206, 22.0656029],
                [102.8550758, 22.0655525],
                [102.8553428, 22.0654735],
                [102.8555819, 22.0652839],
                [102.8558522, 22.0649846],
                [102.8560929, 22.0646851],
                [102.8562734, 22.0644672],
                [102.8564565, 22.0640842],
                [102.8564594, 22.0638916],
                [102.8565832, 22.0635079],
                [102.8566772, 22.0631513],
                [102.856767, 22.0630698],
                [102.857004, 22.0630179],
                [102.8574776, 22.0629415],
                [102.8578356, 22.0626985],
                [102.8580756, 22.0624539],
                [102.8583172, 22.0620992],
                [102.8585076, 22.0612208],
                [102.8589608, 22.0605386],
                [102.8593815, 22.0600487],
                [102.8597691, 22.059806],
                [102.860244, 22.059647],
                [102.8608771, 22.0595201],
                [102.8610743, 22.0599115],
                [102.8614372, 22.0601603],
                [102.8617244, 22.0602129],
                [102.8621969, 22.0601459],
                [102.862855, 22.0599104],
                [102.8631684, 22.0599633],
                [102.8632451, 22.0600863],
                [102.863244, 22.0601596],
                [102.863189, 22.0603297],
                [102.8627355, 22.0608852],
                [102.8624355, 22.0616869],
                [102.8624565, 22.0620289],
                [102.8626343, 22.0623973],
                [102.8628563, 22.0626534],
                [102.8635998, 22.0637127],
                [102.8641134, 22.0644856],
                [102.8637135, 22.0649926],
                [102.8633915, 22.0655372],
                [102.8633059, 22.0660117],
                [102.8633391, 22.0664146],
                [102.8634861, 22.0670751],
                [102.8638451, 22.0679762],
                [102.8644854, 22.0697407],
                [102.8649721, 22.0712837],
                [102.8657245, 22.0734154],
                [102.8664472, 22.074925],
                [102.8675956, 22.0768059],
                [102.8681708, 22.0776915],
                [102.8685967, 22.078063],
                [102.8691043, 22.0782158],
                [102.8705112, 22.0785634],
                [102.8715264, 22.0788693],
                [102.8730952, 22.0788899],
                [102.8745866, 22.078836],
                [102.8757627, 22.0788879],
                [102.876879, 22.0789939],
                [102.8780474, 22.0795578],
                [102.8788627, 22.0801172],
                [102.8797384, 22.0800507],
                [102.8807601, 22.0798986],
                [102.8812962, 22.0796165],
                [102.8820125, 22.0791301],
                [102.8828673, 22.0782737],
                [102.8833615, 22.0778258],
                [102.8836748, 22.0776233],
                [102.8839862, 22.0775446],
                [102.8844736, 22.0775509],
                [102.8848268, 22.0776382],
                [102.8850484, 22.0776411],
                [102.88527, 22.0776439],
                [102.8856743, 22.0772774],
                [102.8862126, 22.0768299],
                [102.8868387, 22.0764662],
                [102.8873748, 22.0761841],
                [102.888088, 22.0759041],
                [102.8886215, 22.0757871],
                [102.8891543, 22.0757113],
                [102.8895976, 22.0757171],
                [102.8897822, 22.0757207],
                [102.889902, 22.075612],
                [102.8899348, 22.0753922],
                [102.8900283, 22.0750628],
                [102.8902085, 22.0748724],
                [102.8904461, 22.0747928],
                [102.8908015, 22.0747423],
                [102.891392, 22.0747774],
                [102.8918024, 22.075003],
                [102.8919768, 22.0751982],
                [102.8920605, 22.0755297],
                [102.8921439, 22.0758889],
                [102.892258, 22.0761659],
                [102.8924918, 22.0763341],
                [102.8927566, 22.0764201],
                [102.893318, 22.0764274],
                [102.8942049, 22.0764112],
                [102.8952968, 22.0765079],
                [102.8959764, 22.0765167],
                [102.8964803, 22.076413],
                [102.8969548, 22.0763089],
                [102.897176, 22.0763304],
                [102.8973217, 22.0764701],
                [102.897435, 22.0768024],
                [102.8975463, 22.0772724],
                [102.8978352, 22.0777172],
                [102.8981849, 22.0780524],
                [102.898858, 22.0785021],
                [102.8994757, 22.078703],
                [102.9002426, 22.0787956],
                [102.9007432, 22.0789123],
                [102.901065, 22.0791369],
                [102.9012961, 22.0794982],
                [102.9014082, 22.0799131],
                [102.9014633, 22.0801895],
                [102.9014583, 22.0805202],
                [102.9013187, 22.0809733],
                [102.9013433, 22.0813043],
                [102.9015756, 22.0815829],
                [102.9020428, 22.0819749],
                [102.9029173, 22.0827855],
                [102.9037892, 22.0837889],
                [102.9043671, 22.0846785],
                [102.905549, 22.0856998],
                [102.9066883, 22.0865964],
                [102.9075409, 22.0869105],
                [102.9083354, 22.0871413],
                [102.9088075, 22.0872024],
                [102.9094234, 22.0875134],
                [102.9096598, 22.0875165],
                [102.9101622, 22.0875229],
                [102.910725, 22.0874474],
                [102.9109901, 22.0875059],
                [102.9114142, 22.0878008],
                [102.911853, 22.0881097],
                [102.9122023, 22.0884725],
                [102.912493, 22.0888069],
                [102.9127841, 22.0891139],
                [102.9128981, 22.089391],
                [102.9130698, 22.0897791],
                [102.9133605, 22.0901135],
                [102.9136522, 22.0903654],
                [102.9141813, 22.0905651],
                [102.9145359, 22.0905696],
                [102.9151269, 22.0905771],
                [102.9158362, 22.0905862],
                [102.9164268, 22.0906212],
                [102.9169292, 22.0906277],
                [102.9174038, 22.0904959],
                [102.9179083, 22.0903645],
                [102.9183678, 22.0902739],
                [102.9188098, 22.0903623],
                [102.9190445, 22.0904756],
                [102.9193377, 22.0906446],
                [102.9196324, 22.0907035],
                [102.9200464, 22.0906812],
                [102.9204023, 22.090603],
                [102.9210832, 22.090529],
                [102.9217616, 22.0906203],
                [102.9222319, 22.0907917],
                [102.9226114, 22.0911273],
                [102.9230185, 22.0915735],
                [102.9232812, 22.0917973],
                [102.9235164, 22.0918831],
                [102.9238419, 22.0918596],
                [102.9242885, 22.0916448],
                [102.924824, 22.0914035],
                [102.9255477, 22.0914265],
                [102.9258995, 22.0916238],
                [102.9260744, 22.0917915],
                [102.9263323, 22.0923461],
                [102.9263838, 22.0928705],
                [102.9263478, 22.0933111],
                [102.9262972, 22.0942892],
                [102.9262867, 22.095003],
                [102.9260913, 22.0960505],
                [102.9256245, 22.0971787],
                [102.9252488, 22.0982239],
                [102.9249189, 22.0992277],
                [102.9249042, 22.1002355],
                [102.9250665, 22.1014557],
                [102.9252413, 22.1049018],
                [102.9251797, 22.1057439],
                [102.9270369, 22.1044605],
                [102.9291649, 22.104052],
                [102.9302288, 22.1041695],
                [102.9312302, 22.1041115],
                [102.9316682, 22.1037606],
                [102.9323574, 22.1023572],
                [102.9330463, 22.1010707],
                [102.9334225, 22.0993161],
                [102.934301, 22.0942864],
                [102.9358063, 22.0868003],
                [102.9358067, 22.0858645],
                [102.9351815, 22.0845189],
                [102.9298658, 22.0789017],
                [102.9286777, 22.0772634],
                [102.9281777, 22.076386],
                [102.9281781, 22.075567],
                [102.9288048, 22.0734032],
                [102.9290556, 22.072409],
                [102.9292445, 22.070011],
                [102.9296831, 22.0687245],
                [102.9303092, 22.0678473],
                [102.9311853, 22.0673213],
                [102.9321864, 22.0671462],
                [102.9332501, 22.0671466],
                [102.9346263, 22.0676151],
                [102.9357522, 22.067908],
                [102.9370035, 22.0679085],
                [102.938255, 22.067558],
                [102.9403195, 22.0675588],
                [102.9420088, 22.0674424],
                [102.9426971, 22.0671502],
                [102.9434482, 22.0663901],
                [102.9453265, 22.0628813],
                [102.9457649, 22.0614778],
                [102.9467668, 22.0591969],
                [102.9475178, 22.0582028],
                [102.9485191, 22.0574428],
                [102.9505836, 22.0568],
                [102.9525232, 22.0565082],
                [102.9531489, 22.0562159],
                [102.9538372, 22.0556313],
                [102.9549012, 22.0539353],
                [102.9561526, 22.0528244],
                [102.9589684, 22.0509534],
                [102.9604701, 22.0496084],
                [102.9609082, 22.0489067],
                [102.9612213, 22.047737],
                [102.961347, 22.0456314],
                [102.9615973, 22.044988],
                [102.9624733, 22.0442278],
                [102.9635995, 22.0431753],
                [102.9641003, 22.042298],
                [102.9641629, 22.0415376],
                [102.963913, 22.0404263],
                [102.9630376, 22.0390808],
                [102.9624123, 22.0383203],
                [102.9622248, 22.0377353],
                [102.9626003, 22.0372091],
                [102.9644775, 22.0347528],
                [102.9669174, 22.0321213],
                [102.968544, 22.0307764],
                [102.9691419, 22.0304969],
                [102.9700454, 22.0300747],
                [102.9707959, 22.0298995],
                [102.9720469, 22.030075],
                [102.9741733, 22.0306604],
                [102.9762374, 22.0310701],
                [102.9770505, 22.0310702],
                [102.9781138, 22.0307194],
                [102.9806159, 22.0290235],
                [102.9839937, 22.0273277],
                [102.985001, 22.0271217],
                [102.9859953, 22.0269184],
                [102.9870585, 22.0269185],
                [102.9883719, 22.0272111],
                [102.9894352, 22.027796],
                [102.9902482, 22.028732],
                [102.990811, 22.0308376],
                [102.990936, 22.0323584],
                [102.9913738, 22.0333527],
                [102.9917491, 22.0338791],
                [102.993, 22.0348735],
                [102.9939382, 22.0357509],
                [102.994376, 22.0368038],
                [102.9947513, 22.0382076],
                [102.9952517, 22.0390264],
                [102.9953767, 22.0399623],
                [102.9950639, 22.0436472],
                [102.9950638, 22.0444075],
                [102.9955017, 22.0454603],
                [102.9974408, 22.0480925],
                [102.9981914, 22.0493792],
                [102.9983164, 22.0501982],
                [102.9982539, 22.0516604],
                [102.9980663, 22.0525962],
                [102.9980663, 22.0530056],
                [102.9984416, 22.0532396],
                [102.9989421, 22.0531227],
                [103.0018971, 22.0502566],
                [103.0034692, 22.050975],
                [103.0044844, 22.0515988],
                [103.0052676, 22.0523311],
                [103.0060798, 22.052792],
                [103.0066019, 22.0529547],
                [103.0072401, 22.0529819],
                [103.0083712, 22.052792],
                [103.0094735, 22.0525206],
                [103.0104596, 22.052385],
                [103.011794, 22.0523579],
                [103.0128382, 22.052412],
                [103.0134474, 22.0526018],
                [103.0144046, 22.0532525],
                [103.0168414, 22.0547168],
                [103.0181757, 22.0552591],
                [103.0193359, 22.0555302],
                [103.0232229, 22.0556653],
                [103.023948, 22.0555024],
                [103.0246732, 22.0550955],
                [103.0275443, 22.0524644],
                [103.0287335, 22.0516777],
                [103.0302996, 22.0511079],
                [103.0327938, 22.0500226],
                [103.0343019, 22.0492629],
                [103.0361582, 22.048693],
                [103.0378983, 22.0482316],
                [103.0392036, 22.0479873],
                [103.0407988, 22.048041],
                [103.0423943, 22.0483389],
                [103.0438445, 22.0484742],
                [103.0447727, 22.0483655],
                [103.0455027, 22.0479556],
                [103.0486347, 22.0498844],
                [103.0514771, 22.0518136],
                [103.0536759, 22.0527905],
                [103.0557674, 22.053567],
                [103.0566524, 22.0541431],
                [103.0567866, 22.0545192],
                [103.0567871, 22.055597],
                [103.0569752, 22.0565997],
                [103.0577269, 22.0587301],
                [103.058681, 22.0609732],
                [103.0591168, 22.0613464],
                [103.0600609, 22.0618213],
                [103.0622758, 22.0623975],
                [103.0628205, 22.062533],
                [103.0635104, 22.06294],
                [103.0638735, 22.0631436],
                [103.064382, 22.0632113],
                [103.064999, 22.0630074],
                [103.0656886, 22.062464],
                [103.06714, 22.0610718],
                [103.0694266, 22.0597471],
                [103.0704429, 22.0591357],
                [103.0721853, 22.0588295],
                [103.0741094, 22.0586929],
                [103.0755252, 22.0584206],
                [103.0766141, 22.0581146],
                [103.0782478, 22.058012],
                [103.0807891, 22.0580786],
                [103.0830037, 22.0582473],
                [103.0848916, 22.0583142],
                [103.0862348, 22.0582117],
                [103.0879772, 22.0579052],
                [103.0899374, 22.0575987],
                [103.0918615, 22.0575637],
                [103.09335, 22.0575968],
                [103.0949608, 22.057695],
                [103.0951809, 22.0587741],
                [103.0952658, 22.0606927],
                [103.0952453, 22.0622183],
                [103.0952714, 22.0635795],
                [103.0953976, 22.0640758],
                [103.0956527, 22.0648624],
                [103.0957335, 22.0654407],
                [103.0956865, 22.0656463],
                [103.0955049, 22.0659741],
                [103.0951901, 22.0663416],
                [103.0946527, 22.066789],
                [103.0938957, 22.0671101],
                [103.0928308, 22.0672626],
                [103.0922547, 22.0672972],
                [103.0918098, 22.0674569],
                [103.0916534, 22.0675582],
                [103.0902146, 22.069191],
                [103.0900318, 22.0696012],
                [103.0901154, 22.0699734],
                [103.0903744, 22.0704713],
                [103.090546, 22.0708856],
                [103.0912849, 22.0719253],
                [103.0931817, 22.0741125],
                [103.094495, 22.0752413],
                [103.0946699, 22.0754082],
                [103.0948846, 22.0759056],
                [103.0951293, 22.0774755],
                [103.0956826, 22.0808017],
                [103.0957064, 22.0823279],
                [103.0958253, 22.0833602],
                [103.096039, 22.0839401],
                [103.0967808, 22.0847734],
                [103.0980515, 22.085778],
                [103.0993683, 22.0866594],
                [103.0996285, 22.0870748],
                [103.0998608, 22.087923],
                [103.1001967, 22.0892877],
                [103.1004365, 22.0915343],
                [103.1010398, 22.0941358],
                [103.1021704, 22.0990115],
                [103.1026126, 22.101379],
                [103.1038603, 22.100947],
                [103.1047367, 22.1008259],
                [103.1066031, 22.1005587],
                [103.1077075, 22.1002826],
                [103.109973, 22.0997049],
                [103.1115015, 22.0993812],
                [103.1126038, 22.0992626],
                [103.1133968, 22.0990617],
                [103.1138239, 22.0987777],
                [103.1148234, 22.0978965],
                [103.1160243, 22.0967286],
                [103.1168768, 22.096292],
                [103.1180946, 22.0959647],
                [103.1196781, 22.0957465],
                [103.1203857, 22.0955972],
                [103.1204892, 22.0955535],
                [103.1205421, 22.0955311],
                [103.1210987, 22.0952005],
                [103.1215873, 22.0946796],
                [103.1221025, 22.093689],
                [103.1225908, 22.0929014],
                [103.1232285, 22.0918598],
                [103.1234317, 22.0914027],
                [103.1234991, 22.0907805],
                [103.1235803, 22.0903107],
                [103.1238514, 22.0897012],
                [103.1242991, 22.0891929],
                [103.1247603, 22.0884815],
                [103.1251533, 22.0874783],
                [103.1259261, 22.0859414],
                [103.1263738, 22.0852682],
                [103.1266585, 22.0847474],
                [103.1269426, 22.0835665],
                [103.1271728, 22.0827284],
                [103.1273898, 22.0823474],
                [103.1277289, 22.0818773],
                [103.1278372, 22.0815852],
                [103.1279725, 22.0809376],
                [103.1281758, 22.0804169],
                [103.1285961, 22.0796929],
                [103.129668, 22.0784732],
                [103.1303871, 22.0778124],
                [103.1314047, 22.0767578],
                [103.1318254, 22.0761862],
                [103.1322454, 22.0752844],
                [103.132625, 22.0744462],
                [103.1330184, 22.0738744],
                [103.1339817, 22.0729342],
                [103.1344975, 22.0724767],
                [103.1349316, 22.0721843],
                [103.1357462, 22.0718916],
                [103.1386382, 22.0711528],
                [103.1399145, 22.0707582],
                [103.140851, 22.0702876],
                [103.1415295, 22.0696904],
                [103.1427911, 22.0682546],
                [103.143456, 22.0677461],
                [103.1442296, 22.0673518],
                [103.1458589, 22.0669696],
                [103.1470132, 22.0669177],
                [103.1476924, 22.0670187],
                [103.1482359, 22.0672594],
                [103.1490108, 22.0681348],
                [103.149868, 22.0696701],
                [103.1500864, 22.0706222],
                [103.1502044, 22.07252],
                [103.1501374, 22.0731198],
                [103.1502731, 22.0736248],
                [103.1505775, 22.0740664],
                [103.1513885, 22.0746024],
                [103.1537195, 22.0753264],
                [103.1562528, 22.0758922],
                [103.1579414, 22.0758906],
                [103.1595618, 22.0754155],
                [103.161148, 22.0744668],
                [103.1625997, 22.0740866],
                [103.1638828, 22.0740221],
                [103.1652338, 22.074147],
                [103.1661798, 22.0745881],
                [103.1670931, 22.0757867],
                [103.1675338, 22.0773333],
                [103.1679408, 22.078785],
                [103.1687528, 22.0799522],
                [103.1696654, 22.080709],
                [103.1701061, 22.08078],
                [103.170778, 22.0806826],
                [103.1717074, 22.0800052],
                [103.1733595, 22.0783124],
                [103.1749085, 22.0770062],
                [103.1764577, 22.0759415],
                [103.177439, 22.0754089],
                [103.1785239, 22.074973],
                [103.180125, 22.0741498],
                [103.1811973, 22.073784],
                [103.1879906, 22.0709712],
                [103.1911728, 22.0696911],
                [103.191598, 22.0695707],
                [103.1920162, 22.0694523],
                [103.1943807, 22.0691801],
                [103.1957814, 22.0689116],
                [103.1969485, 22.0685707],
                [103.1993606, 22.0678885],
                [103.2012284, 22.0676678],
                [103.202552, 22.0677876],
                [103.2049659, 22.0685123],
                [103.207458, 22.0693581],
                [103.2090154, 22.0697685],
                [103.2100275, 22.0698642],
                [103.2108576, 22.0697176],
                [103.2118433, 22.0695465],
                [103.2126473, 22.0692787],
                [103.214073, 22.0684278],
                [103.2151877, 22.0676987],
                [103.216173, 22.0673578],
                [103.2172107, 22.0672594],
                [103.2182222, 22.0669427],
                [103.2201923, 22.0658],
                [103.2231217, 22.0642436],
                [103.2251957, 22.0631734],
                [103.2278937, 22.0609773],
                [103.2278983, 22.0609784],
                [103.2293944, 22.0595109],
                [103.2302074, 22.0587485],
                [103.2309339, 22.0584757],
                [103.2313989, 22.0583391],
                [103.2318928, 22.0580121],
                [103.2327345, 22.057005],
                [103.2336053, 22.056161],
                [103.234702, 22.0550697],
                [103.2353501, 22.0545769],
                [103.2363662, 22.0538867],
                [103.2380478, 22.0526051],
                [103.2393792, 22.0517178],
                [103.2399397, 22.0513069],
                [103.2405875, 22.0506664],
                [103.2410428, 22.0502395],
                [103.2413231, 22.0500587],
                [103.2419365, 22.0497625],
                [103.2428302, 22.0493513],
                [103.244022, 22.048874],
                [103.2451264, 22.04861],
                [103.2463713, 22.0483949],
                [103.2478618, 22.0483106],
                [103.2490188, 22.0481285],
                [103.2506317, 22.0477325],
                [103.2529811, 22.047319],
                [103.254261, 22.0471202],
                [103.2555583, 22.0468887],
                [103.2568203, 22.0464274],
                [103.2581695, 22.045753],
                [103.2592736, 22.0453577],
                [103.2612687, 22.0449945],
                [103.2621698, 22.0448057],
                [103.2631208, 22.0444295],
                [103.2648223, 22.0436776],
                [103.2660836, 22.0432988],
                [103.2666438, 22.0432104],
                [103.2672039, 22.043035],
                [103.2679193, 22.0426844],
                [103.2688526, 22.0423336],
                [103.2693504, 22.0420998],
                [103.270376, 22.0411957],
                [103.2711221, 22.0406123],
                [103.271962, 22.0402324],
                [103.2731446, 22.0400267],
                [103.2739538, 22.0398215],
                [103.2747003, 22.0395292],
                [103.2756645, 22.0390326],
                [103.2763178, 22.0387695],
                [103.2773449, 22.0385931],
                [103.2786206, 22.038329],
                [103.2794917, 22.0382379],
                [103.2821918, 22.0382332],
                [103.2846532, 22.0387901],
                [103.2868149, 22.0395718],
                [103.2880507, 22.0405669],
                [103.2912819, 22.0446425],
                [103.2911085, 22.0488416],
                [103.2904978, 22.0501909],
                [103.2889933, 22.0525431],
                [103.2879855, 22.0545184],
                [103.2868417, 22.0558623],
                [103.2837322, 22.058694],
                [103.2812464, 22.0608066],
                [103.2784482, 22.0633168],
                [103.2769095, 22.0650955],
                [103.2748721, 22.0674037],
                [103.2743275, 22.0683527],
                [103.2740086, 22.0692851],
                [103.2732926, 22.0708433],
                [103.2726203, 22.0721729],
                [103.2725304, 22.0728211],
                [103.2727238, 22.073778],
                [103.2729967, 22.0747967],
                [103.2730573, 22.0750227],
                [103.2736182, 22.0760979],
                [103.2745713, 22.0786667],
                [103.275252, 22.0800105],
                [103.2762845, 22.0811098],
                [103.2788412, 22.0833519],
                [103.2809135, 22.0849395],
                [103.2829573, 22.0871951],
                [103.2833991, 22.0879635],
                [103.2835529, 22.0888054],
                [103.2835436, 22.089569],
                [103.2834806, 22.0914015],
                [103.2834588, 22.0931963],
                [103.2835343, 22.0954311],
                [103.2839964, 22.0979184],
                [103.2843489, 22.0992969],
                [103.2843554, 22.1004619],
                [103.2841729, 22.1019876],
                [103.284236, 22.1035541],
                [103.2845067, 22.1048936],
                [103.2852874, 22.1064866],
                [103.2872671, 22.1089897],
                [103.2903159, 22.111275],
                [103.2921072, 22.1124013],
                [103.2926387, 22.1125595],
                [103.293623, 22.1125698],
                [103.2957765, 22.1125733],
                [103.2977867, 22.112556],
                [103.302049, 22.1128677],
                [103.3037283, 22.1130761],
                [103.3067364, 22.1136612],
                [103.3077171, 22.1139768],
                [103.308491, 22.1144432],
                [103.3088948, 22.114982],
                [103.309092, 22.1156332],
                [103.3091245, 22.1163592],
                [103.3090329, 22.1171603],
                [103.3086928, 22.1181497],
                [103.3079183, 22.1194593],
                [103.3069553, 22.1210915],
                [103.3066554, 22.1221577],
                [103.3066034, 22.1230738],
                [103.3065952, 22.1237611],
                [103.3067019, 22.1251371],
                [103.3069808, 22.1258274],
                [103.307444, 22.1265388],
                [103.3092357, 22.1276648],
                [103.3116408, 22.12895],
                [103.3141637, 22.1306947],
                [103.3160108, 22.1323369],
                [103.3172621, 22.1340683],
                [103.3177817, 22.1352194],
                [103.3183409, 22.1382232],
                [103.3186333, 22.1397828],
                [103.3185594, 22.1405551],
                [103.3188749, 22.1416277],
                [103.3197651, 22.142668],
                [103.32078, 22.1435951],
                [103.3223497, 22.1444322],
                [103.324678, 22.1452964],
                [103.3260634, 22.1461126],
                [103.327566, 22.1474265],
                [103.3281733, 22.1481202],
                [103.3293947, 22.1506724],
                [103.3301602, 22.1518642],
                [103.3307283, 22.1524047],
                [103.3331373, 22.1534223],
                [103.3340385, 22.153546],
                [103.3392349, 22.1530452],
                [103.3441303, 22.1519875],
                [103.3469223, 22.1518248],
                [103.3496504, 22.1518716],
                [103.3526044, 22.1519015],
                [103.3563336, 22.152321],
                [103.3615926, 22.1535006],
                [103.362372, 22.1536903],
                [103.3635566, 22.1539786],
                [103.3658105, 22.1542304],
                [103.3676978, 22.1542493],
                [103.3715373, 22.1540012],
                [103.372535, 22.1537744],
                [103.3735076, 22.1539445],
                [103.3745324, 22.154031],
                [103.375882, 22.1544264],
                [103.3772699, 22.1550514],
                [103.3790695, 22.1555658],
                [103.3801743, 22.155844],
                [103.382613, 22.1560783],
                [103.3874091, 22.1565078],
                [103.3896667, 22.1564537],
                [103.3901202, 22.1562672],
                [103.3909054, 22.1557785],
                [103.3916095, 22.1552125],
                [103.3923187, 22.1541883],
                [103.3923662, 22.1536159],
                [103.3920674, 22.1528301],
                [103.3912629, 22.151409],
                [103.3901768, 22.1494887],
                [103.3898617, 22.1483398],
                [103.3898177, 22.1467927],
                [103.3901672, 22.1449247],
                [103.3907581, 22.1434792],
                [103.3913476, 22.1421483],
                [103.3922887, 22.1405727],
                [103.3938244, 22.1390219],
                [103.398445, 22.1349617],
                [103.4014255, 22.132623],
                [103.4036204, 22.1308113],
                [103.404161, 22.1301674],
                [103.4051077, 22.1280569],
                [103.4058728, 22.1256965],
                [103.406181, 22.123828],
                [103.4064279, 22.12194],
                [103.4065229, 22.1207952],
                [103.4065499, 22.1183894],
                [103.4065265, 22.1168232],
                [103.4066408, 22.115774],
                [103.4076156, 22.1129955],
                [103.408531, 22.1100064],
                [103.4089195, 22.1082915],
                [103.4101768, 22.1040835],
                [103.4113213, 22.1007719],
                [103.4123602, 22.0977457],
                [103.412828, 22.0962607],
                [103.4135153, 22.0934793],
                [103.4140257, 22.0918421],
                [103.4143431, 22.0909858],
                [103.4151884, 22.0899853],
                [103.4164619, 22.0885621],
                [103.4177934, 22.0868563],
                [103.4185731, 22.0849733],
                [103.4190019, 22.083297],
                [103.419145, 22.0815033],
                [103.4193966, 22.079157],
                [103.4200435, 22.0762988],
                [103.4207266, 22.0738993],
                [103.4214811, 22.072417],
                [103.4222096, 22.0714501],
                [103.4229561, 22.0706935],
                [103.4240316, 22.0698637],
                [103.427587, 22.067036],
                [103.4284521, 22.0664884],
                [103.4296889, 22.0654031],
                [103.4309244, 22.0639315],
                [103.4315552, 22.0628994],
                [103.4317458, 22.0621517],
                [103.4318819, 22.0615845],
                [103.4321282, 22.0610429],
                [103.4331691, 22.0590048],
                [103.4341269, 22.0568383],
                [103.4348643, 22.0553148],
                [103.4356779, 22.054404],
                [103.4363231, 22.0541789],
                [103.4369837, 22.0539452],
                [103.4377556, 22.0540462],
                [103.4380868, 22.0542256],
                [103.4384915, 22.0544758],
                [103.4402631, 22.0556832],
                [103.4427567, 22.0570977],
                [103.4439656, 22.0573786],
                [103.4449821, 22.057385],
                [103.4457013, 22.0572451],
                [103.4466962, 22.0569775],
                [103.44863, 22.0563849],
                [103.4504629, 22.0555317],
                [103.4526633, 22.0542633],
                [103.4540946, 22.0530091],
                [103.4555877, 22.0514574],
                [103.4564284, 22.0504494],
                [103.4574103, 22.048759],
                [103.4578499, 22.0476795],
                [103.4585326, 22.0457967],
                [103.4594453, 22.0432151],
                [103.4601626, 22.0413504],
                [103.4609914, 22.0393492],
                [103.4626969, 22.0365398],
                [103.4652903, 22.0335285],
                [103.4672205, 22.0316543],
                [103.4682906, 22.0305106],
                [103.4691162, 22.0300774],
                [103.4693352, 22.0296645],
                [103.4693609, 22.0293938],
                [103.469447, 22.0290126],
                [103.4694962, 22.0283759],
                [103.4694113, 22.0276548],
                [103.469244, 22.0270886],
                [103.4691325, 22.0266766],
                [103.4683304, 22.0256999],
                [103.4670874, 22.0246987],
                [103.4649061, 22.0231849],
                [103.4608154, 22.020847],
                [103.4605658, 22.0200078],
                [103.4574142, 22.0180622],
                [103.455543, 22.0156112],
                [103.4545366, 22.0139545],
                [103.4541417, 22.0125757],
                [103.4537909, 22.0103605],
                [103.4537884, 22.0095876],
                [103.453833, 22.0093905],
                [103.4539714, 22.0087801],
                [103.4544008, 22.0081598],
                [103.4551019, 22.0068788],
                [103.4561983, 22.0051755],
                [103.4569645, 22.0036275],
                [103.4582275, 22.0023616],
                [103.4595171, 22.0008123],
                [103.4604784, 21.9999337],
                [103.4616854, 21.9990449],
                [103.4623686, 21.9982946],
                [103.4631976, 21.9977274],
                [103.4643361, 21.9970671],
                [103.4650611, 21.9965947],
                [103.4656839, 21.9960734],
                [103.4663596, 21.9954089],
                [103.4667819, 21.9944065],
                [103.4669989, 21.9933543],
                [103.467113, 21.9923011],
                [103.4671768, 21.9911516],
                [103.4672381, 21.9902416],
                [103.4675053, 21.9892858],
                [103.4678778, 21.9881392],
                [103.4685091, 21.9868033],
                [103.4706445, 21.9842835],
                [103.472314, 21.9820468],
                [103.4737711, 21.9804311],
                [103.4755352, 21.978962],
                [103.4770914, 21.9776825],
                [103.4789486, 21.9771248],
                [103.482395, 21.9770128],
                [103.4851739, 21.9767989],
                [103.4869251, 21.9765276],
                [103.4890932, 21.9757329],
                [103.4912118, 21.9747461],
                [103.4929716, 21.9736601],
                [103.4941673, 21.9724251],
                [103.4951033, 21.9714275],
                [103.4967909, 21.9700109],
                [103.4970678, 21.9698699],
                [103.4973743, 21.9702292],
                [103.4991557, 21.9754617],
                [103.5009452, 21.9789799],
                [103.5042283, 21.9814755],
                [103.5071163, 21.9824418],
                [103.5083939, 21.9834076],
                [103.5097242, 21.9857792],
                [103.5103234, 21.986732],
                [103.510448, 21.9884952],
                [103.5115331, 21.9886732],
                [103.5119118, 21.9891783],
                [103.5127526, 21.9900747],
                [103.5134721, 21.9904362],
                [103.513719, 21.9909788],
                [103.5137864, 21.9917622],
                [103.5134559, 21.9929924],
                [103.5125585, 21.9953447],
                [103.5124515, 21.9978728],
                [103.5132401, 21.9991733],
                [103.5137816, 22.0008966],
                [103.5138993, 22.0056042],
                [103.5138123, 22.0067654],
                [103.5134996, 22.0075244],
                [103.512184, 22.0089924],
                [103.5109839, 22.0100993],
                [103.5113738, 22.0105905],
                [103.512319, 22.0112866],
                [103.5133417, 22.01178],
                [103.5142882, 22.0122044],
                [103.515714, 22.012634],
                [103.5163067, 22.0129629],
                [103.5168906, 22.0136828],
                [103.5174057, 22.0147125],
                [103.5182109, 22.0154912],
                [103.5186953, 22.0163399],
                [103.5202509, 22.0179773],
                [103.5209651, 22.0194371],
                [103.5210306, 22.020414],
                [103.5213815, 22.0211156],
                [103.522304, 22.0218114],
                [103.5234997, 22.0223212],
                [103.5241866, 22.0231055],
                [103.5245443, 22.0239907],
                [103.5249729, 22.0245139],
                [103.5256347, 22.0246909],
                [103.5268392, 22.0246035],
                [103.5287684, 22.0242411],
                [103.530567, 22.0248618],
                [103.5331328, 22.0264328],
                [103.5350108, 22.0273272],
                [103.5354686, 22.0275313],
                [103.5364421, 22.0279652],
                [103.5376069, 22.0282603],
                [103.5389671, 22.0284546],
                [103.539861, 22.0284656],
                [103.5415118, 22.0287758],
                [103.5428737, 22.0296756],
                [103.5441922, 22.0303321],
                [103.5453685, 22.0307043],
                [103.5488663, 22.0266292],
                [103.5527362, 22.0238195],
                [103.5544197, 22.0233495],
                [103.5564145, 22.0234372],
                [103.5587415, 22.0236156],
                [103.5613349, 22.0235153],
                [103.5623078, 22.0232671],
                [103.563877, 22.0217727],
                [103.5662954, 22.0215481],
                [103.5676826, 22.0211876],
                [103.5687762, 22.0198011],
                [103.5696315, 22.0192683],
                [103.5729342, 22.0182352],
                [103.5745661, 22.0184837],
                [103.5759466, 22.0187737],
                [103.5772896, 22.0186572],
                [103.580523, 22.0178672],
                [103.5857829, 22.0159428],
                [103.5894707, 22.0145761],
                [103.592919, 22.0131932],
                [103.5959912, 22.0124733],
                [103.5984567, 22.0122772],
                [103.6019672, 22.0123493],
                [103.6044054, 22.0123157],
                [103.606153, 22.0122099],
                [103.6079594, 22.0113913],
                [103.6089908, 22.0098331],
                [103.610954, 22.0056902],
                [103.6127387, 22.0029816],
                [103.6160523, 21.9996311],
                [103.6184265, 21.9985112],
                [103.6204651, 21.9965699],
                [103.6229509, 21.9962252],
                [103.624495, 21.9959388],
                [103.6264922, 21.9947824],
                [103.6285261, 21.9930559],
                [103.630243, 21.9917967],
                [103.6324281, 21.9904322],
                [103.6345479, 21.9892945],
                [103.6353219, 21.9893773],
                [103.6371925, 21.9907348],
                [103.6386696, 21.9914737],
                [103.640351, 21.9918304],
                [103.6422053, 21.9921906],
                [103.6429409, 21.992627],
                [103.6437883, 21.9937033],
                [103.6454149, 21.9938801],
                [103.6472866, 21.9937205],
                [103.6486999, 21.9937202],
                [103.6496833, 21.9933578],
                [103.6508185, 21.9931713],
                [103.6516502, 21.9930035],
                [103.6520698, 21.9924739],
                [103.6520202, 21.9900816],
                [103.6520485, 21.9857677],
                [103.6526214, 21.9845684],
                [103.654181, 21.9838381],
                [103.6564509, 21.9828119],
                [103.6584714, 21.9804611],
                [103.6598581, 21.9772418],
                [103.6612953, 21.9740308],
                [103.6618031, 21.9722023],
                [103.6616906, 21.9710901],
                [103.6601203, 21.9706178],
                [103.659929, 21.9698362],
                [103.6601707, 21.9686785],
                [103.6594942, 21.9660738],
                [103.6600149, 21.9647338],
                [103.6602972, 21.9631355],
                [103.6623975, 21.9600925],
                [103.664713, 21.9561618],
                [103.6651753, 21.9546378],
                [103.6651661, 21.9519384],
                [103.6660711, 21.9499418],
                [103.6681462, 21.9485892],
                [103.6698541, 21.9484742],
                [103.6705236, 21.9475248],
                [103.6713194, 21.946793],
                [103.6735489, 21.9462426],
                [103.6760083, 21.9456802],
                [103.6783969, 21.9449411],
                [103.6810083, 21.9444769],
                [103.6832582, 21.9437605],
                [103.6844733, 21.9435066],
                [103.6862208, 21.9428235],
                [103.6879597, 21.941593],
                [103.6889176, 21.9397156],
                [103.6894246, 21.9366101],
                [103.6898192, 21.9336802],
                [103.6896138, 21.9319327],
                [103.688773, 21.9298797],
                [103.6876972, 21.9277295],
                [103.6869064, 21.9258407],
                [103.6858451, 21.9244677],
                [103.6850614, 21.9236645],
                [103.6842267, 21.9230418],
                [103.6835373, 21.9222415],
                [103.6833055, 21.9213286],
                [103.6832788, 21.920129],
                [103.6843252, 21.9196809],
                [103.6854101, 21.9195637],
                [103.6867373, 21.9191861],
                [103.6879567, 21.9186056],
                [103.6888549, 21.917256],
                [103.6893719, 21.9159219],
                [103.6904915, 21.9138939],
                [103.6919233, 21.9136418],
                [103.6936836, 21.9135001],
                [103.6952177, 21.913299],
                [103.6963248, 21.9128099],
                [103.6974424, 21.9120593],
                [103.6990243, 21.9111369],
                [103.7012167, 21.9095407],
                [103.7027589, 21.9076693],
                [103.7032083, 21.9068223],
                [103.7032573, 21.9059845],
                [103.7033078, 21.9049477],
                [103.7042892, 21.9034178],
                [103.7053295, 21.9025118],
                [103.7060854, 21.9020587],
                [103.7064052, 21.9007879],
                [103.7061877, 21.8994316],
                [103.7058003, 21.898418],
                [103.7066008, 21.8964789],
                [103.7066963, 21.8953583],
                [103.7062773, 21.8942969],
                [103.7056066, 21.8932845],
                [103.7047232, 21.8927544],
                [103.7049418, 21.8912012],
                [103.7052982, 21.8897956],
                [103.7051188, 21.8882312],
                [103.7049996, 21.8873566],
                [103.7048715, 21.8856517],
                [103.7045593, 21.8846818],
                [103.7048853, 21.8839955],
                [103.7060553, 21.8838065],
                [103.7067702, 21.8838631],
                [103.7079095, 21.883754],
                [103.7093291, 21.8840469],
                [103.7104561, 21.8846304],
                [103.7117761, 21.8844452],
                [103.7125952, 21.8845211],
                [103.7137677, 21.8852016],
                [103.7146689, 21.885531],
                [103.7154082, 21.8856055],
                [103.7154201, 21.8850406],
                [103.714833, 21.8840581],
                [103.7146355, 21.8829818],
                [103.7149046, 21.8815453],
                [103.7155482, 21.8802627],
                [103.7164267, 21.8794682],
                [103.717488, 21.8792856],
                [103.7181902, 21.8793816],
                [103.7190653, 21.8793554],
                [103.7207511, 21.8795188],
                [103.7221632, 21.8794294],
                [103.7226588, 21.8789684],
                [103.7224216, 21.8779939],
                [103.721817, 21.8769395],
                [103.7218038, 21.8754993],
                [103.7219559, 21.8736906],
                [103.7216664, 21.8721522],
                [103.7209328, 21.8700906],
                [103.7193405, 21.8674058],
                [103.7174298, 21.8648879],
                [103.7168156, 21.8635377],
                [103.7164394, 21.8626918],
                [103.7158512, 21.8617773],
                [103.714681, 21.8609777],
                [103.7134259, 21.8604358],
                [103.7121425, 21.8594875],
                [103.7105657, 21.8569382],
                [103.7092136, 21.8551503],
                [103.7079599, 21.8527206],
                [103.7073511, 21.8507729],
                [103.7070502, 21.8488068],
                [103.7069535, 21.847533],
                [103.7060955, 21.8456164],
                [103.7060159, 21.8434486],
                [103.7056366, 21.8421354],
                [103.7043817, 21.8413793],
                [103.702591, 21.8410718],
                [103.7012129, 21.8404895],
                [103.6998004, 21.8396683],
                [103.6985942, 21.8386605],
                [103.6974491, 21.8381318],
                [103.6964497, 21.8376441],
                [103.6962804, 21.8369509],
                [103.6960248, 21.8356529],
                [103.6956059, 21.8335501],
                [103.6950981, 21.8326333],
                [103.6950069, 21.8317856],
                [103.6945603, 21.8306347],
                [103.6943267, 21.829525],
                [103.694774, 21.8291002],
                [103.6951387, 21.828067],
                [103.6957663, 21.8261362],
                [103.6965002, 21.8247476],
                [103.6972674, 21.8240399],
                [103.698378, 21.8236291],
                [103.7004038, 21.8236794],
                [103.7018319, 21.8233298],
                [103.7036805, 21.8229582],
                [103.7043983, 21.8229582],
                [103.7055547, 21.8233627],
                [103.706775, 21.8227109],
                [103.7079769, 21.8221489],
                [103.709466, 21.822055],
                [103.7106795, 21.8215785],
                [103.7119674, 21.8206261],
                [103.7130494, 21.8194068],
                [103.7137166, 21.8180384],
                [103.7139546, 21.816419],
                [103.7148452, 21.8146303],
                [103.7151047, 21.8130348],
                [103.7159222, 21.812166],
                [103.7166556, 21.8117913],
                [103.7177192, 21.8117331],
                [103.7183686, 21.8112873],
                [103.7185858, 21.8103743],
                [103.7193044, 21.8101282],
                [103.7206915, 21.8103243],
                [103.7217107, 21.8105907],
                [103.7227882, 21.8098465],
                [103.72364, 21.8083601],
                [103.7237435, 21.8069615],
                [103.7241068, 21.8060967],
                [103.7249044, 21.8061075],
                [103.7264691, 21.8071461],
                [103.7278427, 21.8073557],
                [103.7292641, 21.8085582],
                [103.7310433, 21.8087499],
                [103.7327841, 21.8085768],
                [103.7346496, 21.8086722],
                [103.7354542, 21.8085722],
                [103.7368536, 21.8089368],
                [103.7385466, 21.8097153],
                [103.740168, 21.8101243],
                [103.7416545, 21.8100199],
                [103.7426848, 21.8094638],
                [103.7434045, 21.8084695],
                [103.743727, 21.8079894],
                [103.7460185, 21.8084267],
                [103.7490587, 21.8090722],
                [103.7502942, 21.8087564],
                [103.7513588, 21.8094445],
                [103.7526708, 21.8100004],
                [103.7539137, 21.8101894],
                [103.7548145, 21.8099416],
                [103.7554754, 21.8089581],
                [103.7564689, 21.8082557],
                [103.7577727, 21.8079857],
                [103.7596415, 21.8079094],
                [103.7597304, 21.8075856],
                [103.7595474, 21.8066635],
                [103.7587952, 21.8051927],
                [103.7581055, 21.8038327],
                [103.7581465, 21.802299],
                [103.758509, 21.8005425],
                [103.7585034, 21.7998249],
                [103.7575979, 21.7984922],
                [103.7567561, 21.7975724],
                [103.7565284, 21.7972101],
                [103.7557244, 21.7961136],
                [103.7554054, 21.7947422],
                [103.7554943, 21.7927516],
                [103.7554379, 21.7918857],
                [103.7549663, 21.79089],
                [103.7538586, 21.7888486],
                [103.7534425, 21.7877749],
                [103.753332, 21.7868238],
                [103.7535977, 21.7858953],
                [103.7540758, 21.7845743],
                [103.754454, 21.7834034],
                [103.7543949, 21.7820528],
                [103.7541788, 21.7806822],
                [103.7544059, 21.7792872],
                [103.7551028, 21.7785607],
                [103.7559631, 21.7782176],
                [103.7566322, 21.7781231],
                [103.7573888, 21.777578],
                [103.7579248, 21.7773553],
                [103.7585431, 21.7773761],
                [103.7590397, 21.7778053],
                [103.7591838, 21.7788322],
                [103.759461, 21.7794282],
                [103.7599282, 21.7797518],
                [103.7606698, 21.7800547],
                [103.7631758, 21.7803427],
                [103.765197, 21.7798155],
                [103.7658604, 21.7796674],
                [103.7677684, 21.7786679],
                [103.769102, 21.7779066],
                [103.7701259, 21.7774491],
                [103.7707123, 21.777426],
                [103.7716987, 21.7778411],
                [103.7725109, 21.7785623],
                [103.7730942, 21.7788794],
                [103.7740219, 21.7789843],
                [103.7748698, 21.7788774],
                [103.7755224, 21.7784983],
                [103.7759587, 21.7781471],
                [103.7770563, 21.7783005],
                [103.7780949, 21.7785299],
                [103.7788026, 21.7784892],
                [103.7791282, 21.7780995],
                [103.7787955, 21.7773039],
                [103.7783983, 21.776442],
                [103.778112, 21.7751958],
                [103.7783008, 21.7745362],
                [103.7786347, 21.7741439],
                [103.778822, 21.7732488],
                [103.7790483, 21.7728267],
                [103.7800302, 21.7726261],
                [103.7807143, 21.7724046],
                [103.7813879, 21.7717477],
                [103.7824179, 21.7698468],
                [103.7825382, 21.7688557],
                [103.7823673, 21.7677354],
                [103.7824243, 21.7669917],
                [103.7828601, 21.7652627],
                [103.7827521, 21.7636501],
                [103.7828896, 21.7627766],
                [103.7834367, 21.7624333],
                [103.7843002, 21.7622888],
                [103.7851342, 21.7625258],
                [103.7861034, 21.7629945],
                [103.7866263, 21.7630047],
                [103.7884454, 21.7635784],
                [103.789257, 21.7633752],
                [103.7896275, 21.7630759],
                [103.7901063, 21.7624107],
                [103.7902409, 21.761172],
                [103.7901429, 21.7600661],
                [103.7904964, 21.758796],
                [103.7911315, 21.757815],
                [103.79212, 21.7568693],
                [103.7931711, 21.7559283],
                [103.794097, 21.7553669],
                [103.794878, 21.7549413],
                [103.7953468, 21.7546105],
                [103.7954917, 21.7541397],
                [103.7954917, 21.7536771],
                [103.7952761, 21.7525305],
                [103.7966953, 21.7518376],
                [103.7982371, 21.7504642],
                [103.7990334, 21.7499212],
                [103.799879, 21.7496215],
                [103.8010252, 21.7486482],
                [103.8015913, 21.7476969],
                [103.8018225, 21.7468873],
                [103.8022186, 21.7462499],
                [103.802616, 21.745342],
                [103.8030839, 21.7445876],
                [103.8036139, 21.7430484],
                [103.8038595, 21.7399073],
                [103.8060387, 21.7400962],
                [103.8066392, 21.7399184],
                [103.8071376, 21.7393608],
                [103.8070675, 21.7383302],
                [103.8072172, 21.7376951],
                [103.807508, 21.7373449],
                [103.8079396, 21.7376078],
                [103.8091041, 21.7384085],
                [103.8103406, 21.7394726],
                [103.8112162, 21.7399963],
                [103.8124117, 21.7405177],
                [103.8134838, 21.7407181],
                [103.8140066, 21.7406536],
                [103.815048, 21.7402525],
                [103.8157594, 21.7400509],
                [103.8168046, 21.7396285],
                [103.8184125, 21.7387789],
                [103.8195691, 21.7383978],
                [103.8206817, 21.7376457],
                [103.8214887, 21.7368211],
                [103.822443, 21.7361624],
                [103.8230468, 21.7359163],
                [103.8246433, 21.7355314],
                [103.8252966, 21.7354835],
                [103.8265424, 21.7356636],
                [103.8277264, 21.7362215],
                [103.8289915, 21.7372445],
                [103.8292996, 21.7373397],
                [103.8297152, 21.7377483],
                [103.8300633, 21.7385989],
                [103.8302844, 21.7395234],
                [103.8306435, 21.7404041],
                [103.8315037, 21.7419336],
                [103.8323703, 21.7434134],
                [103.8326211, 21.7437405],
                [103.832956, 21.744008],
                [103.8333081, 21.7437206],
                [103.8337492, 21.742752],
                [103.8340288, 21.7422185],
                [103.8355703, 21.7404183],
                [103.8363275, 21.7395925],
                [103.8367668, 21.7387069],
                [103.8369187, 21.7381048],
                [103.8368449, 21.7374265],
                [103.8361234, 21.7367176],
                [103.8354197, 21.7364076],
                [103.83421, 21.7359972],
                [103.833977, 21.735735],
                [103.8338475, 21.7352953],
                [103.8339743, 21.7340338],
                [103.8335521, 21.7328518],
                [103.8333603, 21.7321518],
                [103.8328324, 21.7313427],
                [103.8326106, 21.7304351],
                [103.8324838, 21.7297787],
                [103.832622, 21.7295656],
                [103.8329576, 21.7294635],
                [103.8334174, 21.7295313],
                [103.8337617, 21.729338],
                [103.834036, 21.7290317],
                [103.8342409, 21.7286426],
                [103.8344592, 21.7279235],
                [103.8351001, 21.7243472],
                [103.8350888, 21.7235159],
                [103.834939, 21.7227823],
                [103.8348425, 21.7213248],
                [103.835109, 21.7204808],
                [103.835981, 21.7193419],
                [103.8375874, 21.7176568],
                [103.8379265, 21.7169498],
                [103.8379348, 21.7162732],
                [103.8377218, 21.7152495],
                [103.8373731, 21.7136946],
                [103.8373731, 21.713257],
                [103.8373253, 21.7115743],
                [103.8373862, 21.7112607],
                [103.8375965, 21.7109222],
                [103.8386112, 21.7100289],
                [103.8388297, 21.7096632],
                [103.8387884, 21.7091186],
                [103.8385844, 21.7086815],
                [103.837846, 21.7074272],
                [103.8376338, 21.7066805],
                [103.8380616, 21.7059178],
                [103.8387733, 21.7053712],
                [103.8395077, 21.7051475],
                [103.8402113, 21.7037733],
                [103.8411984, 21.7020258],
                [103.8425746, 21.7007484],
                [103.8437623, 21.7008734],
                [103.8461066, 21.7016085],
                [103.8483201, 21.7016835],
                [103.8488947, 21.7012129],
                [103.8494493, 21.699236],
                [103.8499966, 21.6975228],
                [103.8512357, 21.6964204],
                [103.8516874, 21.6943735],
                [103.8521918, 21.6934388],
                [103.8534744, 21.6923491],
                [103.8550065, 21.6915696],
                [103.8561884, 21.6915931],
                [103.858791, 21.6922374],
                [103.8604515, 21.6930923],
                [103.8612698, 21.693102],
                [103.8618996, 21.692469],
                [103.8621903, 21.6914931],
                [103.8627565, 21.6907106],
                [103.8643826, 21.6891511],
                [103.8654234, 21.6887865],
                [103.8678268, 21.6892441],
                [103.8690048, 21.6890006],
                [103.8698053, 21.6885773],
                [103.8707723, 21.687398],
                [103.8717953, 21.6864581],
                [103.8728494, 21.6861521],
                [103.8751834, 21.6861621],
                [103.8764277, 21.6860789],
                [103.8780098, 21.686335],
                [103.8802544, 21.6872918],
                [103.8813659, 21.6881596],
                [103.8821207, 21.7085745],
                [103.8827622, 21.70855],
                [103.8838974, 21.7062601],
                [103.8852681, 21.7051343],
                [103.8867741, 21.7047584],
                [103.8876624, 21.70432],
                [103.8883509, 21.7034222],
                [103.8888862, 21.7021559],
                [103.8893866, 21.7016718],
                [103.8918337, 21.7005192],
                [103.8981009, 21.6992449],
                [103.9006911, 21.6984503],
                [103.9032444, 21.6972927],
                [103.9038842, 21.6965823],
                [103.9045964, 21.6952599],
                [103.9051712, 21.6946022],
                [103.9057981, 21.6943716],
                [103.9094721, 21.6945577],
                [103.9125367, 21.6952757],
                [103.9146692, 21.6957624],
                [103.9166351, 21.6959904],
                [103.9177207, 21.6966126],
                [103.9188236, 21.6981686],
                [103.9219414, 21.7006015],
                [103.9220008, 21.7037979],
                [103.9220015, 21.7053185],
                [103.9218505, 21.7070666],
                [103.9211238, 21.7086781],
                [103.9205448, 21.7093486],
                [103.9186694, 21.7100146],
                [103.917081, 21.7109506],
                [103.9157796, 21.7121565],
                [103.9108619, 21.7157109],
                [103.9166082, 21.7210368],
                [103.9180526, 21.7279491],
                [103.9167261, 21.7336234],
                [103.9158851, 21.7367554],
                [103.9152628, 21.7400229],
                [103.918762, 21.7408882],
                [103.9244499, 21.7416434],
                [103.9290575, 21.7424663],
                [103.9319383, 21.7427452],
                [103.9349693, 21.7414106],
                [103.9378601, 21.7391339],
                [103.942024, 21.742914],
                [103.9425988, 21.7433195],
                [103.9464887, 21.7434671],
                [103.9486485, 21.7438778],
                [103.9499468, 21.7434787],
                [103.953846, 21.7412049],
                [103.953401, 21.744566],
                [103.9519376, 21.7504795],
                [103.9512095, 21.7524946],
                [103.9516357, 21.7541101],
                [103.9526727, 21.7557964],
                [103.9527782, 21.7556742],
                [103.9528433, 21.7550871],
                [103.9529702, 21.7548233],
                [103.9530653, 21.7546474],
                [103.9531916, 21.7545009],
                [103.953349, 21.7545015],
                [103.9535689, 21.754561],
                [103.9537258, 21.7546789],
                [103.9540718, 21.7546801],
                [103.9542922, 21.7546221],
                [103.9544501, 21.7544758],
                [103.9545454, 21.7542412],
                [103.9547038, 21.7539187],
                [103.9547988, 21.7537721],
                [103.955083, 21.7534796],
                [103.9553355, 21.7532454],
                [103.9555245, 21.7531874],
                [103.9560908, 21.7531892],
                [103.9564367, 21.7532197],
                [103.9573159, 21.7536336],
                [103.9584769, 21.7544303],
                [103.9591353, 21.7550491],
                [103.9593382, 21.7554461],
                [103.9594939, 21.755887],
                [103.9596501, 21.7561518],
                [103.9601839, 21.7564473],
                [103.9606868, 21.7565369],
                [103.9616934, 21.7565695],
                [103.9621656, 21.7565124],
                [103.9626379, 21.7563672],
                [103.9627616, 21.7562613],
                [103.9634449, 21.7560318],
                [103.964874, 21.7592647],
                [103.966156, 21.7633039],
                [103.9665829, 21.7647848],
                [103.9675797, 21.7680161],
                [103.9681507, 21.7694976],
                [103.9675713, 21.7703028],
                [103.9656938, 21.7713727],
                [103.9619342, 21.7747231],
                [103.9604854, 21.776736],
                [103.9578714, 21.781973],
                [103.9569981, 21.7842568],
                [103.9546828, 21.7866703],
                [103.9532781, 21.7874917],
                [103.9516866, 21.7882679],
                [103.9516135, 21.7891807],
                [103.9516071, 21.7908693],
                [103.9516554, 21.7913854],
                [103.9517551, 21.7916203],
                [103.9521057, 21.7919497],
                [103.9524064, 21.7921853],
                [103.9526568, 21.7924207],
                [103.9528064, 21.7927495],
                [103.9528555, 21.793078],
                [103.9528039, 21.7934062],
                [103.9527023, 21.7936873],
                [103.9521957, 21.7947176],
                [103.9520941, 21.7949987],
                [103.9521433, 21.7952803],
                [103.9524428, 21.7958441],
                [103.9526911, 21.7966424],
                [103.9527399, 21.7970178],
                [103.9526378, 21.7974397],
                [103.9522829, 21.7982359],
                [103.9516773, 21.7988437],
                [103.9512236, 21.7991705],
                [103.9507705, 21.7993566],
                [103.9504179, 21.799543],
                [103.950166, 21.799683],
                [103.9500641, 21.8000579],
                [103.9501093, 21.800273],
                [103.9503596, 21.8005475],
                [103.950568, 21.8008217],
                [103.9506508, 21.8010957],
                [103.9506499, 21.8013302],
                [103.9506073, 21.8014865],
                [103.9503543, 21.8019547],
                [103.9495561, 21.8025383],
                [103.9483387, 21.8031988],
                [103.9460729, 21.8041293],
                [103.9455273, 21.8044011],
                [103.9451492, 21.8046344],
                [103.9449389, 21.8048682],
                [103.9447703, 21.8051412],
                [103.9446851, 21.8054927],
                [103.9446414, 21.8059617],
                [103.9449072, 21.8080492],
                [103.9449093, 21.8088147],
                [103.9447026, 21.8093937],
                [103.9444151, 21.8100257],
                [103.9441002, 21.810367],
                [103.9438188, 21.8105787],
                [103.9423543, 21.8110862],
                [103.9408907, 21.8113374],
                [103.9395181, 21.8117171],
                [103.9345732, 21.8144473],
                [103.9344123, 21.8150685],
                [103.934474, 21.8154289],
                [103.9347085, 21.8163453],
                [103.9349274, 21.8172167],
                [103.9353222, 21.8181192],
                [103.9352856, 21.8187193],
                [103.934944, 21.8192424],
                [103.934021, 21.8200618],
                [103.9331071, 21.8207538],
                [103.9321711, 21.8211679],
                [103.9315896, 21.8214792],
                [103.9307008, 21.8220438],
                [103.9291987, 21.8228594],
                [103.9278179, 21.8235933],
                [103.9273331, 21.8238903],
                [103.9252634, 21.8253176],
                [103.9234283, 21.8265586],
                [103.9225404, 21.8269881],
                [103.9215407, 21.8272968],
                [103.920397, 21.8275143],
                [103.9196711, 21.8277797],
                [103.9190099, 21.8280005],
                [103.9184128, 21.8282516],
                [103.9179838, 21.8286139],
                [103.9175115, 21.8293756],
                [103.9171988, 21.8303333],
                [103.9170969, 21.8310374],
                [103.9171588, 21.8313678],
                [103.9174304, 21.8316095],
                [103.9178476, 21.8317472],
                [103.9186835, 21.8318276],
                [103.9191982, 21.831846],
                [103.919422, 21.8320274],
                [103.9196771, 21.832344],
                [103.9197636, 21.8324786],
                [103.9202221, 21.8348371],
                [103.9201555, 21.8367289],
                [103.919411, 21.8380941],
                [103.9181976, 21.8390647],
                [103.9159775, 21.8413988],
                [103.9127546, 21.8445581],
                [103.9101649, 21.8471833],
                [103.9077029, 21.849087],
                [103.9056992, 21.8504533],
                [103.902746, 21.8525867],
                [103.8999987, 21.8552107],
                [103.8970441, 21.8574906],
                [103.8957704, 21.8594393],
                [103.8937126, 21.8610402],
                [103.8910289, 21.8631445],
                [103.8892818, 21.8652368],
                [103.889275, 21.8661174],
                [103.8897134, 21.8704752],
                [103.889545, 21.871893],
                [103.8891819, 21.8773381],
                [103.8890246, 21.8783639],
                [103.8885263, 21.8817368],
                [103.8886412, 21.8872177],
                [103.8878968, 21.8895948],
                [103.8874118, 21.8904497],
                [103.8869604, 21.8913673],
                [103.8869055, 21.891685],
                [103.8869883, 21.8928477],
                [103.8871116, 21.8938637],
                [103.8878953, 21.8959024],
                [103.888408, 21.897435],
                [103.8889179, 21.8991667],
                [103.8891563, 21.9005629],
                [103.8887497, 21.9022427],
                [103.888414, 21.9032436],
                [103.8877095, 21.904462],
                [103.8867447, 21.9056717],
                [103.8863196, 21.9063661],
                [103.8862366, 21.9069283],
                [103.8864421, 21.9074801],
                [103.8874119, 21.9092026],
                [103.8879918, 21.9105277],
                [103.8880368, 21.9114943],
                [103.887989, 21.9125124],
                [103.8882986, 21.9131872],
                [103.8889592, 21.9142804],
                [103.8897695, 21.9155846],
                [103.8910605, 21.9172742],
                [103.8922263, 21.9186383],
                [103.8938637, 21.9208022],
                [103.8953842, 21.9206895],
                [103.8963137, 21.9204437],
                [103.8992644, 21.9205613],
                [103.9018505, 21.9206759],
                [103.9040138, 21.9208249],
                [103.9055994, 21.9210434],
                [103.9065095, 21.9213745],
                [103.9076845, 21.9221163],
                [103.9088223, 21.9225764],
                [103.9096316, 21.9230955],
                [103.9113508, 21.9247217],
                [103.9122847, 21.9254032],
                [103.9130253, 21.92635],
                [103.913776, 21.9276761],
                [103.9147914, 21.9287103],
                [103.9159313, 21.9299114],
                [103.9176405, 21.931146],
                [103.9185908, 21.9317723],
                [103.9196504, 21.9321952],
                [103.9213036, 21.9321449],
                [103.9227642, 21.9315674],
                [103.9241912, 21.9307247],
                [103.9256432, 21.9295231],
                [103.9265594, 21.9280734],
                [103.9274658, 21.9257388],
                [103.9282374, 21.9243004],
                [103.9290518, 21.9233117],
                [103.9299723, 21.9230118],
                [103.9318345, 21.9230851],
                [103.9334589, 21.923377],
                [103.9342212, 21.9231984],
                [103.9351987, 21.9222873],
                [103.9354351, 21.9216431],
                [103.9357258, 21.9196021],
                [103.9360421, 21.9194142],
                [103.937435, 21.9190627],
                [103.9395808, 21.9190797],
                [103.941276, 21.9186869],
                [103.9422577, 21.9183769],
                [103.9430365, 21.9177092],
                [103.9437412, 21.9182152],
                [103.9455388, 21.9199271],
                [103.9472427, 21.9213499],
                [103.9490397, 21.9231596],
                [103.9512581, 21.9259053],
                [103.9520741, 21.9273295],
                [103.95361, 21.9287882],
                [103.9554757, 21.9301823],
                [103.9567351, 21.9311164],
                [103.9577249, 21.9321135],
                [103.9593613, 21.9340724],
                [103.9606235, 21.9354995],
                [103.96175, 21.9372839],
                [103.9626016, 21.9392465],
                [103.9631392, 21.9410426],
                [103.9630551, 21.9417881],
                [103.9625656, 21.9423477],
                [103.9615248, 21.9430589],
                [103.9614689, 21.9435233],
                [103.9614633, 21.944294],
                [103.9620154, 21.945719],
                [103.9630501, 21.9477683],
                [103.9639475, 21.9488627],
                [103.9654319, 21.9498298],
                [103.9663287, 21.9509976],
                [103.9670401, 21.9524211],
                [103.9678617, 21.95425],
                [103.9688826, 21.9564217],
                [103.9696096, 21.9575027],
                [103.9710252, 21.9591906],
                [103.9715723, 21.9597445],
                [103.9744039, 21.9594449],
                [103.975322, 21.9594873],
                [103.9769717, 21.9600114],
                [103.97812, 21.9608992],
                [103.9792848, 21.9624033],
                [103.9800919, 21.9633013],
                [103.9816332, 21.9643017],
                [103.9844881, 21.9661221],
                [103.98552, 21.9668014],
                [103.985835, 21.9668032],
                [103.9840161, 21.9678953],
                [103.9823289, 21.970034],
                [103.9811787, 21.9713171],
                [103.9803355, 21.9721723],
                [103.9778834, 21.9735965],
                [103.9765044, 21.9741657],
                [103.9757383, 21.9745215],
                [103.9746655, 21.9752338],
                [103.9742819, 21.9756614],
                [103.9736683, 21.976517],
                [103.9724412, 21.9779426],
                [103.9722874, 21.9784418],
                [103.9721332, 21.9792265],
                [103.9722079, 21.9805822],
                [103.9722069, 21.9812957],
                [103.9718997, 21.982009],
                [103.9695214, 21.9852881],
                [103.964843, 21.9904197],
                [103.9620056, 21.9932702],
                [103.9567921, 21.9975446],
                [103.9561024, 21.9979005],
                [103.9554131, 21.997971],
                [103.9543409, 21.9979695],
                [103.9532689, 21.9978969],
                [103.9528094, 21.9978963],
                [103.9525793, 21.9981813],
                [103.9525783, 21.9988234],
                [103.9536605, 21.9994014],
                [103.9553412, 22.0003632],
                [103.9574932, 22.0012545],
                [103.9600318, 22.0029529],
                [103.9615136, 22.0042788],
                [103.9619573, 22.0053238],
                [103.9621445, 22.0065684],
                [103.9637777, 22.0086634],
                [103.9649395, 22.0108284],
                [103.9652376, 22.0130245],
                [103.9652313, 22.0138656],
                [103.9649127, 22.014522],
                [103.9644179, 22.0150856],
                [103.9617316, 22.0174457],
                [103.9582356, 22.0204773],
                [103.9566548, 22.0219665],
                [103.9539623, 22.0251311],
                [103.9534827, 22.0262983],
                [103.9533993, 22.026956],
                [103.9535313, 22.0277066],
                [103.9551186, 22.0307156],
                [103.9559639, 22.0332077],
                [103.9561102, 22.0346714],
                [103.9557254, 22.0362894],
                [103.9555595, 22.0374943],
                [103.9557095, 22.0384453],
                [103.9561328, 22.0396172],
                [103.9569041, 22.0415224],
                [103.9570148, 22.0424732],
                [103.9568134, 22.0444745],
                [103.9569649, 22.0465888],
                [103.9571826, 22.0490034],
                [103.9570023, 22.0495256],
                [103.9559506, 22.0510796],
                [103.9552331, 22.0525741],
                [103.9549644, 22.0544188],
                [103.954199, 22.0570831],
                [103.9538596, 22.0605178],
                [103.9534735, 22.0623251],
                [103.9530699, 22.0638216],
                [103.9519583, 22.0654964],
                [103.9497829, 22.0677128],
                [103.9485573, 22.068875],
                [103.9480979, 22.0699506],
                [103.9476491, 22.0722511],
                [103.9474087, 22.0729077],
                [103.9447666, 22.0744994],
                [103.9437805, 22.0751147],
                [103.9432233, 22.0761348],
                [103.9433055, 22.0782743],
                [103.9429792, 22.0799175],
                [103.9425024, 22.0806822],
                [103.9414377, 22.0812968],
                [103.9405724, 22.0815106],
                [103.9397082, 22.0815781],
                [103.9389258, 22.0812074],
                [103.9385343, 22.0810587],
                [103.9385122, 22.0811481],
                [103.9384533, 22.0813872],
                [103.9385946, 22.0834722],
                [103.9387895, 22.086307],
                [103.9389306, 22.0884286],
                [103.9398164, 22.0907377],
                [103.9415398, 22.0939481],
                [103.9435157, 22.0975074],
                [103.9439026, 22.0982778],
                [103.9439365, 22.0990093],
                [103.9436927, 22.1001046],
                [103.9435831, 22.1016578],
                [103.9441885, 22.104733],
                [103.9440557, 22.1067431],
                [103.9436285, 22.1087329],
                [103.9423095, 22.1118322],
                [103.9411159, 22.1139085],
                [103.9389969, 22.1163262],
                [103.9365789, 22.119345],
                [103.9359347, 22.1214615],
                [103.9358442, 22.1230331],
                [103.9360763, 22.12351],
                [103.9367014, 22.1239528],
                [103.9381124, 22.1243276],
                [103.9388744, 22.1248262],
                [103.9394175, 22.1257071],
                [103.9396081, 22.1265127],
                [103.939163, 22.1282284],
                [103.9382124, 22.1293191],
                [103.9380657, 22.1300624],
                [103.9379674, 22.1305604],
                [103.9383255, 22.1325554],
                [103.938364, 22.1366329],
                [103.9383693, 22.1371992],
                [103.9383094, 22.1375619],
                [103.9382815, 22.1384198],
                [103.9382064, 22.13847],
                [103.9380736, 22.1385894],
                [103.9380173, 22.1386576],
                [103.9379275, 22.1388074],
                [103.9378974, 22.1388803],
                [103.9378588, 22.1390319],
                [103.9378497, 22.1391875],
                [103.9378647, 22.1394607],
                [103.9378443, 22.1395627],
                [103.937809, 22.1396343],
                [103.9376378, 22.1398708],
                [103.9378685, 22.1401192],
                [103.9378317, 22.1402296],
                [103.937787, 22.1403374],
                [103.9376781, 22.1405373],
                [103.9376153, 22.1406289],
                [103.9374715, 22.1407999],
                [103.9374273, 22.1408661],
                [103.937356, 22.1410069],
                [103.9373063, 22.1411685],
                [103.9372918, 22.1412579],
                [103.9372891, 22.1414384],
                [103.9372625, 22.1415308],
                [103.9372086, 22.1416123],
                [103.9371676, 22.1416513],
                [103.9371202, 22.1416836],
                [103.9370118, 22.1417248],
                [103.9368948, 22.1417316],
                [103.9355242, 22.1415701],
                [103.9346123, 22.1418086],
                [103.9339364, 22.1418881],
                [103.9333999, 22.1412571],
                [103.9323378, 22.1408397],
                [103.9321974, 22.1407953],
                [103.9320541, 22.1407595],
                [103.9319087, 22.1407326],
                [103.9317616, 22.1407146],
                [103.9314704, 22.1407054],
                [103.9311855, 22.1407303],
                [103.9310449, 22.1407552],
                [103.9303207, 22.1409341],
                [103.9289913, 22.1411793],
                [103.9286461, 22.1412219],
                [103.9284724, 22.141233],
                [103.9282099, 22.1412367],
                [103.9280349, 22.1412142],
                [103.9277729, 22.1411413],
                [103.9276811, 22.1411296],
                [103.9275884, 22.1411274],
                [103.927496, 22.1411348],
                [103.927405, 22.1411515],
                [103.9273167, 22.1411776],
                [103.9272337, 22.1412117],
                [103.9271552, 22.1412539],
                [103.9270821, 22.1413038],
                [103.9270153, 22.1413608],
                [103.9269556, 22.1414241],
                [103.9269036, 22.1414931],
                [103.9266407, 22.1418881],
                [103.926569, 22.1419771],
                [103.9264903, 22.1420609],
                [103.9263135, 22.1422107],
                [103.9261143, 22.1423341],
                [103.9260077, 22.142385],
                [103.9257906, 22.1424619],
                [103.9255637, 22.1425086],
                [103.9253318, 22.1425241],
                [103.9244628, 22.1424496],
                [103.9239994, 22.1423405],
                [103.9237708, 22.1422757],
                [103.9235116, 22.1421931],
                [103.9232558, 22.1421018],
                [103.9231297, 22.1420438],
                [103.9229994, 22.1419942],
                [103.9228657, 22.1419532],
                [103.9227292, 22.1419211],
                [103.9225906, 22.1418981],
                [103.9223503, 22.1418798],
                [103.9222297, 22.1418808],
                [103.9219898, 22.141903],
                [103.921756, 22.1419715],
                [103.9216439, 22.1420175],
                [103.9214319, 22.1421316],
                [103.9213414, 22.1421932],
                [103.9211747, 22.1423327],
                [103.9207292, 22.1428073],
                [103.9202195, 22.143026],
                [103.9194068, 22.1435676],
                [103.9188516, 22.1436272],
                [103.9181972, 22.144447],
                [103.9173323, 22.1444396],
                [103.9171709, 22.1444172],
                [103.9170172, 22.1443726],
                [103.9168747, 22.1443079],
                [103.9164242, 22.1440371],
                [103.916226, 22.1440154],
                [103.9160266, 22.1440226],
                [103.915928, 22.1440371],
                [103.9157448, 22.1440843],
                [103.9156566, 22.1441173],
                [103.9152092, 22.1443278],
                [103.9149552, 22.1444694],
                [103.9144626, 22.144775],
                [103.9142246, 22.1449386],
                [103.9138691, 22.1452151],
                [103.9136998, 22.1453623],
                [103.9133793, 22.1456733],
                [103.9132468, 22.1458271],
                [103.9131221, 22.1459864],
                [103.9130054, 22.1461509],
                [103.9128971, 22.1463202],
                [103.9127944, 22.1464993],
                [103.912701, 22.1466826],
                [103.9126172, 22.1468699],
                [103.912543, 22.1470606],
                [103.9121246, 22.1484269],
                [103.9118671, 22.1486455],
                [103.9114916, 22.1493014],
                [103.910923, 22.149997],
                [103.91066, 22.1503346],
                [103.9101505, 22.1510205],
                [103.9098608, 22.1517062],
                [103.9097611, 22.1517828],
                [103.9095786, 22.1519532],
                [103.9094215, 22.152144],
                [103.9092922, 22.1523521],
                [103.9092357, 22.1524682],
                [103.9091492, 22.15271],
                [103.9091196, 22.1528344],
                [103.9090884, 22.1530874],
                [103.9082086, 22.153773],
                [103.9078009, 22.1538426],
                [103.9076609, 22.1539215],
                [103.9075381, 22.1540219],
                [103.9074361, 22.1541407],
                [103.9073506, 22.154291],
                [103.9073203, 22.1543713],
                [103.9072859, 22.1545381],
                [103.9069962, 22.1548661],
                [103.9067817, 22.1554225],
                [103.9065937, 22.1554732],
                [103.9062238, 22.1555914],
                [103.9060203, 22.1556674],
                [103.9056229, 22.1558399],
                [103.9054823, 22.1558569],
                [103.9052045, 22.1559094],
                [103.9050813, 22.155941],
                [103.9048397, 22.1560187],
                [103.9032948, 22.1566845],
                [103.9030475, 22.1567286],
                [103.902558, 22.1568388],
                [103.9020771, 22.1569776],
                [103.9016375, 22.1571326],
                [103.9014217, 22.1572191],
                [103.9009988, 22.1574098],
                [103.9007199, 22.1577775],
                [103.8995075, 22.1579862],
                [103.8994182, 22.1580203],
                [103.89925, 22.1581082],
                [103.8991724, 22.1581614],
                [103.8990324, 22.1582846],
                [103.8989146, 22.1584295],
                [103.8988233, 22.1585928],
                [103.8987629, 22.1587679],
                [103.898735, 22.15895],
                [103.8981342, 22.1601622],
                [103.8969004, 22.161126],
                [103.8968404, 22.1612049],
                [103.8967391, 22.1613733],
                [103.8966983, 22.1614621],
                [103.8966376, 22.1616459],
                [103.8966179, 22.16174],
                [103.8966, 22.1619309],
                [103.896546, 22.1620216],
                [103.8964547, 22.1622107],
                [103.8963868, 22.1624065],
                [103.8963425, 22.1626065],
                [103.8962793, 22.162669],
                [103.8961728, 22.1628088],
                [103.8960957, 22.1629642],
                [103.8967609, 22.1633816],
                [103.8962513, 22.1639579],
                [103.8961575, 22.1641203],
                [103.8961218, 22.1642063],
                [103.8960743, 22.1643851],
                [103.8960595, 22.1645741],
                [103.8960796, 22.1647627],
                [103.8954466, 22.1652198],
                [103.8953638, 22.1652949],
                [103.8953072, 22.1653887],
                [103.895282, 22.1654871],
                [103.8952803, 22.1655377],
                [103.8953233, 22.165806],
                [103.8948619, 22.1659948],
                [103.8947332, 22.166263],
                [103.894263, 22.1665518],
                [103.8937837, 22.1668145],
                [103.8936577, 22.1669152],
                [103.8935504, 22.1670332],
                [103.8934592, 22.1671755],
                [103.8933921, 22.1673411],
                [103.8934028, 22.1677385],
                [103.8932419, 22.1678975],
                [103.8932757, 22.167956],
                [103.8933242, 22.1680808],
                [103.8933458, 22.1682137],
                [103.8933458, 22.1682818],
                [103.8933384, 22.1683496],
                [103.8932985, 22.1684386],
                [103.8931989, 22.1686079],
                [103.8930627, 22.1687758],
                [103.8929005, 22.1689227],
                [103.8927161, 22.1690451],
                [103.8919544, 22.1694922],
                [103.8918375, 22.1695928],
                [103.8916228, 22.1698115],
                [103.8914359, 22.170051],
                [103.891287, 22.1702937],
                [103.891173, 22.1705341],
                [103.8910854, 22.1707838],
                [103.8906669, 22.1709328],
                [103.8902592, 22.1714793],
                [103.8898605, 22.1718732],
                [103.8897802, 22.1719605],
                [103.8897078, 22.1720535],
                [103.8896439, 22.1721516],
                [103.8895449, 22.172355],
                [103.8895096, 22.1724585],
                [103.8894653, 22.1726715],
                [103.8888002, 22.1733919],
                [103.8882289, 22.1741395],
                [103.8872231, 22.1741345],
                [103.8868368, 22.1741519],
                [103.8860536, 22.1746089],
                [103.884395, 22.1749995],
                [103.8839589, 22.1751244],
                [103.8835323, 22.1752746],
                [103.8832899, 22.175373],
                [103.8828175, 22.1755942],
                [103.8825882, 22.1757167],
                [103.8824242, 22.175636],
                [103.8823379, 22.175604],
                [103.882159, 22.1755577],
                [103.8820704, 22.175544],
                [103.8818021, 22.1755228],
                [103.8816226, 22.175518],
                [103.8813239, 22.1755287],
                [103.8810271, 22.1755627],
                [103.8794125, 22.1758384],
                [103.8786561, 22.1757142],
                [103.8784576, 22.1757395],
                [103.8782645, 22.1757887],
                [103.8780499, 22.1758757],
                [103.8777388, 22.1759651],
                [103.8775196, 22.1760401],
                [103.8773042, 22.1761241],
                [103.877031, 22.1761046],
                [103.8767571, 22.1761092],
                [103.8764567, 22.1761419],
                [103.8761616, 22.1762035],
                [103.8759041, 22.176437],
                [103.8756605, 22.1764523],
                [103.8751746, 22.1764966],
                [103.8748964, 22.1765304],
                [103.8743431, 22.1766158],
                [103.8741097, 22.1766305],
                [103.8739928, 22.1766268],
                [103.8737722, 22.1765997],
                [103.873568, 22.1765499],
                [103.8734687, 22.1765165],
                [103.8731133, 22.1764746],
                [103.8729344, 22.1764645],
                [103.8725542, 22.1764676],
                [103.8723538, 22.1764825],
                [103.8721544, 22.1765066],
                [103.8720322, 22.1765306],
                [103.8717933, 22.176598],
                [103.8715643, 22.1766904],
                [103.8714355, 22.1767558],
                [103.8713117, 22.1768292],
                [103.8711935, 22.1769102],
                [103.8710815, 22.1769983],
                [103.870404, 22.1772791],
                [103.8700363, 22.177463],
                [103.8696967, 22.1776649],
                [103.8695389, 22.1777704],
                [103.8692362, 22.1779968],
                [103.8690235, 22.1780775],
                [103.8685924, 22.1782253],
                [103.8683317, 22.1783048],
                [103.8678252, 22.1784191],
                [103.8678834, 22.178506],
                [103.8679123, 22.1786043],
                [103.8679151, 22.178655],
                [103.8678843, 22.1787687],
                [103.8677636, 22.1789518],
                [103.8675989, 22.1791547],
                [103.8674122, 22.1793405],
                [103.8672385, 22.1795447],
                [103.8670551, 22.1797126],
                [103.8668704, 22.1798472],
                [103.8662267, 22.1802719],
                [103.866232, 22.1811412],
                [103.8654515, 22.181977],
                [103.8653892, 22.1820629],
                [103.8652828, 22.1822448],
                [103.8652021, 22.1824377],
                [103.8651688, 22.1825483],
                [103.8651279, 22.1827746],
                [103.8651216, 22.1830039],
                [103.865143, 22.1833566],
                [103.8651904, 22.1835241],
                [103.8652694, 22.1836811],
                [103.8653755, 22.1838202],
                [103.865504, 22.1839381],
                [103.8656527, 22.1840335],
                [103.8660051, 22.1842139],
                [103.866086, 22.184284],
                [103.8661462, 22.1843701],
                [103.86617, 22.1844248],
                [103.866192, 22.184541],
                [103.8661896, 22.1846],
                [103.866114, 22.1850555],
                [103.8661147, 22.1851932],
                [103.8661432, 22.1853282],
                [103.8661987, 22.1854563],
                [103.8662795, 22.1855728],
                [103.8663825, 22.1856732],
                [103.8666022, 22.1858404],
                [103.8666578, 22.1859815],
                [103.866698, 22.1860469],
                [103.8668006, 22.1861632],
                [103.8669305, 22.1862566],
                [103.8670031, 22.1862925],
                [103.8671591, 22.1863405],
                [103.8672405, 22.186352],
                [103.8680521, 22.1863617],
                [103.8682007, 22.1863967],
                [103.8683285, 22.1864591],
                [103.8684088, 22.1865172],
                [103.8684506, 22.1865688],
                [103.8684643, 22.1865994],
                [103.8685012, 22.1868487],
                [103.8685297, 22.1869844],
                [103.8686084, 22.187251],
                [103.8692844, 22.187261],
                [103.8694297, 22.1872509],
                [103.8697189, 22.1872163],
                [103.8700705, 22.1871483],
                [103.8701338, 22.1871431],
                [103.8702596, 22.1871574],
                [103.8703735, 22.1872017],
                [103.870468, 22.1872707],
                [103.870663, 22.1874994],
                [103.8706573, 22.1876692],
                [103.8706845, 22.1878372],
                [103.8707133, 22.1879271],
                [103.8707515, 22.1880139],
                [103.8707988, 22.1880968],
                [103.8708548, 22.1881749],
                [103.8711351, 22.188463],
                [103.8711758, 22.1885352],
                [103.8712782, 22.1886674],
                [103.8714087, 22.1887821],
                [103.8715642, 22.1888734],
                [103.8717359, 22.1889349],
                [103.8720578, 22.1890243],
                [103.8720095, 22.1894391],
                [103.8718892, 22.1900932],
                [103.8719828, 22.190671],
                [103.8721329, 22.191026],
                [103.8720945, 22.1911438],
                [103.8719934, 22.1913712],
                [103.87187, 22.1915724],
                [103.8717803, 22.1917507],
                [103.8717116, 22.1919435],
                [103.8716861, 22.1920455],
                [103.8716554, 22.1922529],
                [103.871647, 22.1925336],
                [103.8716578, 22.1930951],
                [103.8716997, 22.1936074],
                [103.8717641, 22.1940695],
                [103.8718056, 22.1942993],
                [103.8719612, 22.194935],
                [103.8720739, 22.1953026],
                [103.8728464, 22.1958639],
                [103.8723341, 22.196825],
                [103.8723042, 22.1969168],
                [103.8722626, 22.1971047],
                [103.8722452, 22.1973109],
                [103.8722477, 22.1974218],
                [103.8722777, 22.197642],
                [103.8724655, 22.1983324],
                [103.8725996, 22.1990377],
                [103.8728088, 22.1996436],
                [103.8712639, 22.200046],
                [103.871177, 22.2000843],
                [103.8710117, 22.2001751],
                [103.8709105, 22.2002442],
                [103.8708156, 22.2003205],
                [103.8693069, 22.201665],
                [103.8689724, 22.2019277],
                [103.8686196, 22.2021688],
                [103.8682403, 22.2023927],
                [103.8680396, 22.2024979],
                [103.8676268, 22.2026882],
                [103.8672459, 22.203463],
                [103.8663018, 22.2036319],
                [103.865937, 22.2036319],
                [103.8656374, 22.2036041],
                [103.8653362, 22.2036028],
                [103.8650394, 22.2036275],
                [103.8648938, 22.2036492],
                [103.8646066, 22.2037114],
                [103.864381, 22.2037047],
                [103.8641553, 22.2037074],
                [103.8639299, 22.2037196],
                [103.8637054, 22.2037412],
                [103.8633361, 22.2037976],
                [103.8631535, 22.2038354],
                [103.8627934, 22.2039299],
                [103.8625841, 22.2038584],
                [103.8624757, 22.2038338],
                [103.8622543, 22.203808],
                [103.8620313, 22.2038136],
                [103.8618118, 22.2038504],
                [103.8617088, 22.2038791],
                [103.861511, 22.2039569],
                [103.8614173, 22.2040057],
                [103.8612426, 22.2041216],
                [103.8611627, 22.2041882],
                [103.8608005, 22.2045578],
                [103.8604116, 22.2049034],
                [103.8600401, 22.2051925],
                [103.8596499, 22.2054596],
                [103.8584804, 22.2056583],
                [103.857252, 22.2059861],
                [103.8571502, 22.2060243],
                [103.8570525, 22.2060711],
                [103.8569599, 22.2061258],
                [103.8568731, 22.2061882],
                [103.8567928, 22.2062577],
                [103.8567197, 22.2063337],
                [103.8566545, 22.2064156],
                [103.8565975, 22.2065026],
                [103.8565502, 22.2065923],
                [103.8564826, 22.2067818],
                [103.8564527, 22.2069794],
                [103.8564085, 22.2070601],
                [103.8563347, 22.2072277],
                [103.8563038, 22.207319],
                [103.8562596, 22.2075058],
                [103.8562274, 22.2095023],
                [103.8557097, 22.2100387],
                [103.8546181, 22.2114839],
                [103.8545178, 22.2116663],
                [103.8544789, 22.2117622],
                [103.8544249, 22.2119607],
                [103.8544045, 22.2121358],
                [103.8544051, 22.2123482],
                [103.8543855, 22.2125771],
                [103.8543667, 22.2126811],
                [103.854311, 22.2128857],
                [103.8539153, 22.2136989],
                [103.8522577, 22.2158245],
                [103.8518822, 22.2168574],
                [103.8518772, 22.2169653],
                [103.8518792, 22.2170733],
                [103.8518884, 22.217181],
                [103.8519046, 22.2172879],
                [103.8519278, 22.2173938],
                [103.8519647, 22.2175185],
                [103.8520113, 22.2176403],
                [103.8520673, 22.2177588],
                [103.852087, 22.2178788],
                [103.8520995, 22.2179996],
                [103.8521047, 22.2181263],
                [103.8521021, 22.2182531],
                [103.8520914, 22.2183795],
                [103.8520874, 22.2184706],
                [103.8520739, 22.218561],
                [103.8520512, 22.2186497],
                [103.8520194, 22.218736],
                [103.8519788, 22.218819],
                [103.851927, 22.2189022],
                [103.8518663, 22.2189801],
                [103.8517975, 22.2190519],
                [103.8517213, 22.219117],
                [103.8516583, 22.2192066],
                [103.8516015, 22.2192996],
                [103.8515509, 22.2193957],
                [103.8515067, 22.2194944],
                [103.8514619, 22.2196176],
                [103.8514272, 22.2197436],
                [103.8514028, 22.2198716],
                [103.8513887, 22.220001],
                [103.8513637, 22.2200856],
                [103.8513305, 22.2201677],
                [103.8512894, 22.2202468],
                [103.8512467, 22.2203137],
                [103.8511982, 22.2203773],
                [103.8511444, 22.220437],
                [103.8510856, 22.2204926],
                [103.8509233, 22.2206465],
                [103.8507276, 22.2207215],
                [103.850534, 22.220801],
                [103.8503426, 22.2208849],
                [103.8500848, 22.2210068],
                [103.8498317, 22.2211368],
                [103.8495836, 22.2212747],
                [103.8491276, 22.2215528],
                [103.8484758, 22.2213939],
                [103.8484065, 22.2213797],
                [103.8483361, 22.2213712],
                [103.8482651, 22.2213685],
                [103.8481942, 22.2213716],
                [103.8481109, 22.2213828],
                [103.8480294, 22.221402],
                [103.8479504, 22.221429],
                [103.847875, 22.2214635],
                [103.8478129, 22.2215064],
                [103.8477571, 22.2215564],
                [103.8477087, 22.2216124],
                [103.8476687, 22.2216732],
                [103.8476372, 22.2217381],
                [103.8476148, 22.2218061],
                [103.8473493, 22.2225187],
                [103.8466787, 22.2225187],
                [103.8461852, 22.2225585],
                [103.8460616, 22.2225881],
                [103.845941, 22.222627],
                [103.8458244, 22.222675],
                [103.8457124, 22.2227317],
                [103.8456058, 22.2227968],
                [103.8454395, 22.222925],
                [103.8453641, 22.2229969],
                [103.8452303, 22.2231544],
                [103.8449921, 22.2233225],
                [103.8448801, 22.2234147],
                [103.8446717, 22.2236139],
                [103.8445021, 22.2238105],
                [103.844271, 22.2241427],
                [103.8441476, 22.2242676],
                [103.844077, 22.2243214],
                [103.8439259, 22.2244069],
                [103.8438446, 22.2244396],
                [103.8436199, 22.2245061],
                [103.8434825, 22.2245553],
                [103.8432199, 22.2246785],
                [103.8430958, 22.2247519],
                [103.8428778, 22.2249101],
                [103.8427833, 22.2249926],
                [103.8426109, 22.2251721],
                [103.8424623, 22.2253692],
                [103.8423524, 22.2255544],
                [103.8422512, 22.2257438],
                [103.8421591, 22.2259371],
                [103.842121, 22.2260781],
                [103.8420915, 22.2262208],
                [103.8420707, 22.2263648],
                [103.8420589, 22.2265044],
                [103.8420554, 22.2266444],
                [103.84206, 22.2267844],
                [103.8419902, 22.2279018],
                [103.8419465, 22.2283136],
                [103.8418713, 22.2287214],
                [103.8418219, 22.2289233],
                [103.84166, 22.2294675],
                [103.8415918, 22.2298196],
                [103.841561, 22.2301761],
                [103.8415596, 22.2303422],
                [103.841581, 22.2306737],
                [103.8416039, 22.2308384],
                [103.8416737, 22.2311642],
                [103.8417101, 22.231275],
                [103.8418053, 22.231489],
                [103.8418637, 22.2315914],
                [103.8420009, 22.2317849],
                [103.8432133, 22.2332448],
                [103.8432612, 22.2335065],
                [103.8432976, 22.233635],
                [103.8433895, 22.2338741],
                [103.8434434, 22.2339845],
                [103.8435701, 22.2341965],
                [103.8440501, 22.2350771],
                [103.8440674, 22.2351429],
                [103.8440809, 22.2352776],
                [103.8440636, 22.2354222],
                [103.8440411, 22.2354973],
                [103.8439697, 22.2356382],
                [103.843621, 22.2361049],
                [103.8435857, 22.2361754],
                [103.8435351, 22.2363234],
                [103.8435188, 22.2364104],
                [103.8435137, 22.2365866],
                [103.8434899, 22.2385596],
                [103.8433689, 22.2393027],
                [103.843284, 22.2397423],
                [103.8431895, 22.2401803],
                [103.8430855, 22.2406164],
                [103.84293, 22.2411918],
                [103.8428784, 22.2413303],
                [103.8428171, 22.2414655],
                [103.8426762, 22.2417097],
                [103.842515, 22.2419235],
                [103.8424247, 22.2420237],
                [103.8422736, 22.2421709],
                [103.8421517, 22.2423398],
                [103.8420622, 22.2425265],
                [103.8420306, 22.2426251],
                [103.8419956, 22.242828],
                [103.8418866, 22.2428528],
                [103.8416759, 22.2429239],
                [103.8415752, 22.2429697],
                [103.8413859, 22.243081],
                [103.8412101, 22.2432216],
                [103.8411285, 22.2433035],
                [103.8409871, 22.2434834],
                [103.8403433, 22.2447843],
                [103.8402722, 22.2449581],
                [103.8401548, 22.2453134],
                [103.840071, 22.2456768],
                [103.8400215, 22.2460455],
                [103.8400132, 22.2461595],
                [103.8400253, 22.2463877],
                [103.8400455, 22.2465005],
                [103.8401238, 22.2467776],
                [103.8401358, 22.246863],
                [103.8401344, 22.2470396],
                [103.84012, 22.2471293],
                [103.8400966, 22.2472172],
                [103.8400135, 22.2473711],
                [103.8399571, 22.247535],
                [103.8399372, 22.2476332],
                [103.839927, 22.2477327],
                [103.8399264, 22.2478326],
                [103.8399357, 22.2479322],
                [103.8399234, 22.2479839],
                [103.8399028, 22.2480333],
                [103.8398391, 22.2481209],
                [103.8397931, 22.2481601],
                [103.8397408, 22.2481919],
                [103.8396835, 22.2482152],
                [103.8392356, 22.2482326],
                [103.8389806, 22.2482672],
                [103.8387313, 22.2483282],
                [103.8386078, 22.2483691],
                [103.8383692, 22.2484703],
                [103.8376182, 22.2488905],
                [103.8365883, 22.2493075],
                [103.8362693, 22.2494804],
                [103.8361166, 22.249577],
                [103.8358265, 22.2497891],
                [103.8355839, 22.2500012],
                [103.8353612, 22.2502313],
                [103.8351568, 22.2504681],
                [103.8350639, 22.2505893],
                [103.8349795, 22.2507156],
                [103.8348498, 22.2509547],
                [103.8347598, 22.2511778],
                [103.8347241, 22.2512923],
                [103.8347349, 22.2520358],
                [103.834413, 22.2522443],
                [103.8341689, 22.2525447],
                [103.8341582, 22.253339],
                [103.834151, 22.2533959],
                [103.8341103, 22.2535038],
                [103.8340346, 22.2535994],
                [103.8339841, 22.2536394],
                [103.8339275, 22.2536717],
                [103.8329834, 22.2540292],
                [103.8327634, 22.2546051],
                [103.8326202, 22.2547139],
                [103.8324837, 22.2548298],
                [103.8323543, 22.2549526],
                [103.832176, 22.2551466],
                [103.8321258, 22.2552156],
                [103.8320451, 22.2553643],
                [103.8319927, 22.2555234],
                [103.8319775, 22.2556055],
                [103.8319704, 22.2557525],
                [103.831991, 22.2558984],
                [103.8320473, 22.2560573],
                [103.8324416, 22.2563179],
                [103.8322719, 22.2565827],
                [103.832128, 22.2568602],
                [103.8320132, 22.2571419],
                [103.8319266, 22.2574251],
                [103.8314116, 22.25743],
                [103.8312575, 22.2574492],
                [103.8311105, 22.257496],
                [103.8310414, 22.2575293],
                [103.8309223, 22.2576087],
                [103.8308693, 22.2576558],
                [103.8307786, 22.2577627],
                [103.8304326, 22.2584329],
                [103.8303763, 22.2587854],
                [103.8296306, 22.2592943],
                [103.8294868, 22.2594244],
                [103.8292108, 22.2596952],
                [103.8290319, 22.2598875],
                [103.8288608, 22.2600858],
                [103.8286977, 22.2602898],
                [103.8286543, 22.2609599],
                [103.8286167, 22.2613571],
                [103.8285907, 22.2614576],
                [103.8285548, 22.2615555],
                [103.8285094, 22.26165],
                [103.8283957, 22.2618206],
                [103.8283271, 22.2618982],
                [103.8280427, 22.2621613],
                [103.8279424, 22.2622869],
                [103.8279028, 22.2623559],
                [103.8278466, 22.2625032],
                [103.8278305, 22.2625799],
                [103.827823, 22.2627291],
                [103.8278443, 22.2628701],
                [103.8278925, 22.2630053],
                [103.8283867, 22.264034],
                [103.8284458, 22.2642106],
                [103.8285022, 22.2644772],
                [103.8285649, 22.2646468],
                [103.8286617, 22.2648189],
                [103.8287254, 22.2649051],
                [103.8287967, 22.2649861],
                [103.8289601, 22.2651301],
                [103.82815, 22.2657755],
                [103.8281167, 22.265812],
                [103.8280678, 22.2658959],
                [103.8280454, 22.265989],
                [103.8280448, 22.2660356],
                [103.8280637, 22.266127],
                [103.8282895, 22.26654],
                [103.8282223, 22.2666631],
                [103.8281071, 22.2669173],
                [103.8280097, 22.2672113],
                [103.8279462, 22.267513],
                [103.8276006, 22.2683305],
                [103.827573, 22.2684105],
                [103.8275385, 22.2685754],
                [103.8275321, 22.2686548],
                [103.8275385, 22.2688137],
                [103.8272935, 22.2690851],
                [103.8268214, 22.2696412],
                [103.8263962, 22.270184],
                [103.826015, 22.27071],
                [103.8249099, 22.2720504],
                [103.8248542, 22.2722532],
                [103.8247704, 22.2724475],
                [103.8247165, 22.2725439],
                [103.824588, 22.2727255],
                [103.8235581, 22.2731425],
                [103.8233373, 22.2731727],
                [103.8231182, 22.273212],
                [103.8228991, 22.2732609],
                [103.8226826, 22.2733188],
                [103.8224691, 22.2733858],
                [103.822368, 22.2735124],
                [103.8222926, 22.2736536],
                [103.8222462, 22.2738],
                [103.8222039, 22.2740937],
                [103.8221816, 22.2741652],
                [103.8221175, 22.2743007],
                [103.8220292, 22.2744233],
                [103.8217717, 22.2746913],
                [103.8217246, 22.2747513],
                [103.8216828, 22.2748146],
                [103.8216162, 22.2749495],
                [103.821587, 22.2750369],
                [103.8215673, 22.2751265],
                [103.8215345, 22.2755427],
                [103.8215089, 22.275704],
                [103.8214785, 22.2758446],
                [103.8213962, 22.276121],
                [103.8209724, 22.2764436],
                [103.8203824, 22.2764635],
                [103.8202931, 22.2764694],
                [103.8202052, 22.2764844],
                [103.8200642, 22.2765288],
                [103.8199061, 22.2766057],
                [103.8197114, 22.2767325],
                [103.8196226, 22.2768059],
                [103.8194666, 22.2769681],
                [103.8194, 22.2770555],
                [103.819289, 22.2772431],
                [103.8192451, 22.2773421],
                [103.8191968, 22.277774],
                [103.8185906, 22.2787916],
                [103.8179684, 22.2796355],
                [103.8175177, 22.2798738],
                [103.8170993, 22.2801319],
                [103.8164663, 22.2801815],
                [103.8152003, 22.2806779],
                [103.8150024, 22.2806893],
                [103.8148098, 22.2807334],
                [103.8146306, 22.2808078],
                [103.8144701, 22.2809093],
                [103.8143313, 22.2810353],
                [103.8139772, 22.2816608],
                [103.8135373, 22.2819487],
                [103.8130224, 22.2829712],
                [103.8129396, 22.2831596],
                [103.8128879, 22.2832494],
                [103.8127649, 22.283418],
                [103.8126247, 22.2835631],
                [103.8124649, 22.2836898],
                [103.8114238, 22.2843511],
                [103.811059, 22.2853637],
                [103.8108619, 22.2854285],
                [103.8104591, 22.2855329],
                [103.8102543, 22.2855722],
                [103.8098958, 22.2856198],
                [103.8095343, 22.285641],
                [103.8093531, 22.2856417],
                [103.8075044, 22.2857059],
                [103.8074021, 22.2857216],
                [103.8072044, 22.2857791],
                [103.8071108, 22.2858204],
                [103.8070213, 22.2858699],
                [103.8069372, 22.285927],
                [103.8068592, 22.2859911],
                [103.8067882, 22.2860616],
                [103.8064688, 22.2864552],
                [103.8063529, 22.286582],
                [103.8061023, 22.2868216],
                [103.8059681, 22.2869338],
                [103.8056838, 22.2871408],
                [103.8049543, 22.287925],
                [103.8047826, 22.2883817],
                [103.8045393, 22.2884462],
                [103.8044222, 22.2884906],
                [103.8041999, 22.2886026],
                [103.803994, 22.2887463],
                [103.8038985, 22.2888301],
                [103.8038103, 22.2889202],
                [103.8037296, 22.2890164],
                [103.8036571, 22.2891179],
                [103.8035931, 22.2892242],
                [103.8035381, 22.2893347],
                [103.8033664, 22.289831],
                [103.8033403, 22.2899524],
                [103.8032844, 22.2900647],
                [103.8032018, 22.290162],
                [103.8030968, 22.290239],
                [103.8029751, 22.2902914],
                [103.8029101, 22.2903073],
                [103.8026632, 22.2903327],
                [103.8025521, 22.2903574],
                [103.8024439, 22.2903914],
                [103.8023395, 22.2904345],
                [103.8021534, 22.2905409],
                [103.8019967, 22.2906695],
                [103.8019277, 22.2907425],
                [103.8018107, 22.2909032],
                [103.801403, 22.2917073],
                [103.8006177, 22.2921936],
                [103.7998474, 22.2927],
                [103.7989727, 22.2933124],
                [103.79812, 22.2939507],
                [103.7966126, 22.293782],
                [103.7970986, 22.2950087],
                [103.7971079, 22.2950663],
                [103.7971068, 22.2951244],
                [103.7970954, 22.2951817],
                [103.7970485, 22.2952798],
                [103.796972, 22.2953603],
                [103.7966502, 22.2956284],
                [103.79637, 22.2958927],
                [103.7962978, 22.2959524],
                [103.7961367, 22.2960512],
                [103.7959582, 22.2961197],
                [103.79578, 22.2961543],
                [103.7955981, 22.2961587],
                [103.7952939, 22.2961279],
                [103.7951864, 22.2961297],
                [103.7949738, 22.2961587],
                [103.7948702, 22.2961855],
                [103.7946724, 22.2962631],
                [103.7944896, 22.296372],
                [103.7944055, 22.2964377],
                [103.7942574, 22.2965881],
                [103.7941396, 22.29676],
                [103.7939894, 22.2971074],
                [103.7939156, 22.2972985],
                [103.7938655, 22.2973888],
                [103.7937463, 22.2975493],
                [103.7936063, 22.2976838],
                [103.7935281, 22.2977428],
                [103.7934306, 22.2978028],
                [103.7933891, 22.2978412],
                [103.7933242, 22.2979314],
                [103.7932609, 22.2980632],
                [103.7931702, 22.2981806],
                [103.7931158, 22.2982323],
                [103.7929162, 22.2983744],
                [103.7928537, 22.2984304],
                [103.7927969, 22.2984914],
                [103.7927019, 22.2986262],
                [103.7926323, 22.2987804],
                [103.7925947, 22.2989439],
                [103.7925786, 22.2993806],
                [103.791908, 22.299604],
                [103.7913072, 22.2998273],
                [103.7910663, 22.2999825],
                [103.790843, 22.3001587],
                [103.7906454, 22.3003478],
                [103.790557, 22.3004454],
                [103.7903952, 22.3006512],
                [103.7898733, 22.3006864],
                [103.789792, 22.3006982],
                [103.7897121, 22.3007162],
                [103.7895584, 22.3007703],
                [103.7893953, 22.3008616],
                [103.7893217, 22.3009177],
                [103.7891936, 22.3010483],
                [103.788684, 22.3020012],
                [103.7875131, 22.3023086],
                [103.7873375, 22.3023814],
                [103.7872561, 22.3024291],
                [103.7871096, 22.3025446],
                [103.787046, 22.3026115],
                [103.7869406, 22.3027605],
                [103.7868671, 22.3029265],
                [103.7868291, 22.3031023],
                [103.7868279, 22.3032817],
                [103.7864631, 22.3031229],
                [103.7859696, 22.3028697],
                [103.7855995, 22.3027903],
                [103.7854306, 22.302681],
                [103.7853405, 22.3026348],
                [103.7851509, 22.3025602],
                [103.7849361, 22.3025079],
                [103.7848182, 22.3024923],
                [103.7845802, 22.3024876],
                [103.7841028, 22.302299],
                [103.7839831, 22.302272],
                [103.7837686, 22.3022472],
                [103.7835824, 22.3022493],
                [103.7824103, 22.302366],
                [103.7822805, 22.3022368],
                [103.78213, 22.3021285],
                [103.7819563, 22.3020412],
                [103.7817624, 22.3019802],
                [103.781662, 22.3019615],
                [103.7807286, 22.301892],
                [103.7801385, 22.3018424],
                [103.7800015, 22.3018251],
                [103.7797254, 22.3018113],
                [103.7794494, 22.3018251],
                [103.7792261, 22.3018568],
                [103.7790591, 22.3019062],
                [103.7789798, 22.3019408],
                [103.7788322, 22.3020283],
                [103.7786983, 22.3021432],
                [103.778585, 22.3022845],
                [103.7785023, 22.3024429],
                [103.7784272, 22.3030732],
                [103.777644, 22.3032469],
                [103.777435, 22.3032671],
                [103.7772332, 22.3033215],
                [103.7770434, 22.3034095],
                [103.7769549, 22.3034657],
                [103.7768724, 22.3035291],
                [103.7767965, 22.3035993],
                [103.7762573, 22.3036241],
                [103.7753412, 22.3033314],
                [103.7754554, 22.303974],
                [103.7754567, 22.3041924],
                [103.7754434, 22.3043052],
                [103.7754205, 22.3044167],
                [103.7752596, 22.3049356],
                [103.7751764, 22.3052861],
                [103.7751172, 22.3054926],
                [103.7749709, 22.3058978],
                [103.7748838, 22.3060965],
                [103.7747877, 22.3062916],
                [103.7746829, 22.3064828],
                [103.774593, 22.3066157],
                [103.7744945, 22.3067432],
                [103.7743878, 22.306865],
                [103.7742061, 22.3070421],
                [103.774007, 22.3072025],
                [103.7735832, 22.3076739],
                [103.7734372, 22.3077824],
                [103.773158, 22.3080133],
                [103.7729002, 22.3082588],
                [103.7727196, 22.3084599],
                [103.7726405, 22.3085156],
                [103.7725484, 22.3085494],
                [103.7723766, 22.3085733],
                [103.7722555, 22.3085986],
                [103.7721371, 22.3086331],
                [103.7720221, 22.3086764],
                [103.7718383, 22.3087689],
                [103.7717521, 22.3088237],
                [103.771593, 22.3089494],
                [103.7711685, 22.3093394],
                [103.7710814, 22.309411],
                [103.7708911, 22.3095351],
                [103.770683, 22.3096317],
                [103.7704618, 22.3096987],
                [103.7702324, 22.3097345],
                [103.7699997, 22.3097385],
                [103.7694848, 22.3096938],
                [103.769301, 22.3097125],
                [103.7691253, 22.3097658],
                [103.7690538, 22.309799],
                [103.7689219, 22.3098825],
                [103.7686504, 22.3101155],
                [103.7684143, 22.3102718],
                [103.7682885, 22.310339],
                [103.7681533, 22.3104005],
                [103.7680139, 22.3104534],
                [103.767871, 22.3104975],
                [103.7677252, 22.3105326],
                [103.7673957, 22.3107923],
                [103.7672557, 22.3108745],
                [103.767108, 22.3109327],
                [103.7670335, 22.3109528],
                [103.7668803, 22.3109767],
                [103.7662576, 22.3110081],
                [103.766191, 22.3110189],
                [103.7660462, 22.3110685],
                [103.7655383, 22.3113034],
                [103.7654459, 22.3113386],
                [103.765351, 22.3113675],
                [103.7651557, 22.311406],
                [103.7650536, 22.3114156],
                [103.7648485, 22.3114136],
                [103.7647467, 22.3114021],
                [103.7644845, 22.3113494],
                [103.764402, 22.3113415],
                [103.7642998, 22.3113405],
                [103.7639785, 22.311369],
                [103.7638681, 22.3113668],
                [103.7636508, 22.3113381],
                [103.7634423, 22.3112784],
                [103.7633425, 22.3112373],
                [103.7627095, 22.3115351],
                [103.7624371, 22.3115515],
                [103.7623483, 22.3115664],
                [103.7621819, 22.3116192],
                [103.7620336, 22.3116988],
                [103.7616688, 22.3116418],
                [103.7615908, 22.3116418],
                [103.7614377, 22.3116638],
                [103.7612933, 22.3117137],
                [103.762055, 22.3121058],
                [103.762216, 22.3123142],
                [103.7630796, 22.3127261],
                [103.7630796, 22.3129594],
                [103.7631657, 22.3131276],
                [103.7632781, 22.3132819],
                [103.7633515, 22.3133609],
                [103.7635184, 22.3135004],
                [103.7636107, 22.3135599],
                [103.7640613, 22.3138179],
                [103.7641287, 22.3138697],
                [103.7641893, 22.3139281],
                [103.7642426, 22.3139925],
                [103.7642877, 22.3140619],
                [103.7643242, 22.3141355],
                [103.7643521, 22.3142145],
                [103.7643701, 22.3142959],
                [103.7643778, 22.3143787],
                [103.7643404, 22.3147027],
                [103.7643241, 22.3150135],
                [103.7643108, 22.3151206],
                [103.7642679, 22.3153241],
                [103.7642687, 22.3153826],
                [103.7642893, 22.3154978],
                [103.764309, 22.3155537],
                [103.7643661, 22.3156587],
                [103.7644449, 22.3157509],
                [103.7648794, 22.3160437],
                [103.764908, 22.3161898],
                [103.7649706, 22.3163266],
                [103.7650551, 22.3164378],
                [103.7651061, 22.316487],
                [103.7652227, 22.3165697],
                [103.765668, 22.3167682],
                [103.7658768, 22.3168348],
                [103.7660964, 22.3168735],
                [103.7663224, 22.3168824],
                [103.766469, 22.3168723],
                [103.7666168, 22.3168896],
                [103.7666895, 22.3169087],
                [103.7668267, 22.3169667],
                [103.7670413, 22.3171106],
                [103.7671477, 22.3172046],
                [103.767226, 22.3173151],
                [103.7672746, 22.3174349],
                [103.7673953, 22.3183166],
                [103.7677225, 22.318793],
                [103.7680766, 22.3193488],
                [103.7682752, 22.3194502],
                [103.7686832, 22.3196339],
                [103.7690807, 22.3197836],
                [103.7694645, 22.3199028],
                [103.7696591, 22.3199542],
                [103.7701741, 22.3199443],
                [103.7702243, 22.3199657],
                [103.7703136, 22.3200257],
                [103.7703831, 22.3201054],
                [103.7704277, 22.3201993],
                [103.7704691, 22.3204008],
                [103.770572, 22.3205427],
                [103.770694, 22.3206742],
                [103.7708339, 22.3207929],
                [103.7711012, 22.3208415],
                [103.7711102, 22.3232319],
                [103.7712206, 22.3235682],
                [103.7713584, 22.3238958],
                [103.7715286, 22.3242225],
                [103.7716271, 22.3243859],
                [103.7717326, 22.3245454],
                [103.7720041, 22.3249117],
                [103.772168, 22.3251193],
                [103.7725103, 22.3255245],
                [103.772559, 22.325696],
                [103.772572, 22.3257839],
                [103.7725747, 22.3259612],
                [103.7725624, 22.326061],
                [103.7725403, 22.3261593],
                [103.7725085, 22.3262553],
                [103.7724244, 22.3264246],
                [103.7723904, 22.3265047],
                [103.7723656, 22.3265877],
                [103.772345, 22.3267585],
                [103.7723606, 22.3269165],
                [103.7724036, 22.3270563],
                [103.7724727, 22.3271869],
                [103.772698, 22.3274796],
                [103.7729609, 22.3278617],
                [103.7721777, 22.3284076],
                [103.7721033, 22.3284451],
                [103.7719685, 22.3285399],
                [103.7718573, 22.328657],
                [103.7718119, 22.3287224],
                [103.7717432, 22.3288641],
                [103.7712872, 22.3290973],
                [103.7707239, 22.3295042],
                [103.770756, 22.3296334],
                [103.7707937, 22.3298962],
                [103.7707937, 22.330189],
                [103.7707773, 22.3302866],
                [103.7707195, 22.3304766],
                [103.7706785, 22.3305678],
                [103.7705737, 22.3307398],
                [103.7704343, 22.3309004],
                [103.770268, 22.3310375],
                [103.7695956, 22.3312997],
                [103.769411, 22.3313994],
                [103.769326, 22.3314596],
                [103.7691736, 22.3315982],
                [103.7691097, 22.3316727],
                [103.7690021, 22.3318344],
                [103.768959, 22.3319208],
                [103.769571, 22.3322075],
                [103.7705952, 22.3326874],
                [103.7706021, 22.3327427],
                [103.7706381, 22.3328487],
                [103.770667, 22.3328983],
                [103.7707438, 22.3329856],
                [103.770842, 22.3330521],
                [103.7715554, 22.333459],
                [103.7715809, 22.333573],
                [103.771652, 22.3337964],
                [103.7717625, 22.3340368],
                [103.7719041, 22.3342628],
                [103.7719115, 22.3343214],
                [103.7719286, 22.3343782],
                [103.7719549, 22.3344319],
                [103.7720311, 22.3345234],
                [103.7721315, 22.3345886],
                [103.7721884, 22.3346102],
                [103.7723589, 22.3346448],
                [103.772521, 22.3347045],
                [103.7733847, 22.3351709],
                [103.7735671, 22.3352453],
                [103.7736652, 22.3352717],
                [103.7737656, 22.33529],
                [103.7742563, 22.3353344],
                [103.7752354, 22.3354438],
                [103.775794, 22.3355187],
                [103.7763512, 22.3356026],
                [103.7769627, 22.335801],
                [103.7770555, 22.3358351],
                [103.777233, 22.3359196],
                [103.7774082, 22.3360332],
                [103.7774942, 22.3361028],
                [103.7775743, 22.3361782],
                [103.7780893, 22.3367438],
                [103.7804764, 22.337503],
                [103.7805964, 22.3375349],
                [103.7808408, 22.3375821],
                [103.7810985, 22.3376072],
                [103.7813669, 22.3376072],
                [103.7820911, 22.3375724],
                [103.7825525, 22.3383018],
                [103.7826008, 22.3384285],
                [103.7826135, 22.3385624],
                [103.7826061, 22.3386293],
                [103.7825691, 22.3387481],
                [103.7825397, 22.3388038],
                [103.7824611, 22.3389038],
                [103.782413, 22.3389469],
                [103.7818863, 22.3392051],
                [103.7816591, 22.339368],
                [103.7815543, 22.3394573],
                [103.7813616, 22.3396515],
                [103.7813092, 22.3397208],
                [103.7812236, 22.3398701],
                [103.7811654, 22.3400304],
                [103.7811363, 22.3401973],
                [103.7811014, 22.3402635],
                [103.7810129, 22.3403858],
                [103.7809111, 22.3404837],
                [103.7808539, 22.3405264],
                [103.780687, 22.3406281],
                [103.7805868, 22.3406994],
                [103.7804932, 22.3407778],
                [103.7804065, 22.3408628],
                [103.7803275, 22.340954],
                [103.7802565, 22.3410507],
                [103.7801542, 22.3412292],
                [103.7801132, 22.3413229],
                [103.7800163, 22.3416186],
                [103.7799195, 22.3418127],
                [103.7798595, 22.3419041],
                [103.7790763, 22.3429659],
                [103.7784862, 22.3445834],
                [103.7806857, 22.3471237],
                [103.7808412, 22.3476645],
                [103.780854, 22.3477483],
                [103.7808621, 22.3479174],
                [103.7808449, 22.3480971],
                [103.7807978, 22.3482836],
                [103.7805945, 22.3488206],
                [103.7782475, 22.3498922],
                [103.7781107, 22.3499344],
                [103.7780242, 22.3499497],
                [103.777875, 22.3499565],
                [103.777754, 22.3499741],
                [103.7776283, 22.3500207],
                [103.777518, 22.3500932],
                [103.7770057, 22.3505223],
                [103.7769842, 22.3509292],
                [103.7762439, 22.3514129],
                [103.7761327, 22.3515288],
                [103.7760859, 22.3515931],
                [103.776013, 22.3517287],
                [103.7759868, 22.3517988],
                [103.7759542, 22.3519438],
                [103.775965, 22.352807],
                [103.775796, 22.3528046],
                [103.7755054, 22.3527671],
                [103.7753588, 22.3527599],
                [103.775217, 22.3527603],
                [103.7749346, 22.3527827],
                [103.7747949, 22.3528046],
                [103.7742269, 22.3529981],
                [103.7734903, 22.3531299],
                [103.7722313, 22.3533553],
                [103.7713891, 22.3533354],
                [103.771342, 22.3533376],
                [103.7712496, 22.3533553],
                [103.7711942, 22.3533754],
                [103.7710941, 22.3534347],
                [103.7708902, 22.3536827],
                [103.7708246, 22.3537743],
                [103.7707845, 22.3538149],
                [103.7706909, 22.3538839],
                [103.770583, 22.3539338],
                [103.7694881, 22.3542247],
                [103.7688249, 22.3542682],
                [103.7685916, 22.3543076],
                [103.7684774, 22.3543375],
                [103.7682563, 22.354417],
                [103.7680233, 22.3545363],
                [103.767811, 22.3546849],
                [103.7670332, 22.3553646],
                [103.7664887, 22.3560096],
                [103.766824, 22.3564759],
                [103.7668807, 22.3567565],
                [103.7669189, 22.3568949],
                [103.7670115, 22.3571592],
                [103.7671244, 22.3574086],
                [103.7674677, 22.3580833],
                [103.767202, 22.3586809],
                [103.7671567, 22.3588566],
                [103.7671405, 22.3590545],
                [103.767516, 22.3594427],
                [103.7675716, 22.3595213],
                [103.7676186, 22.3596046],
                [103.7676565, 22.3596918],
                [103.767685, 22.359782],
                [103.7677123, 22.35996],
                [103.7677124, 22.3600461],
                [103.7676877, 22.3602166],
                [103.7671941, 22.3608938],
                [103.7671585, 22.3609665],
                [103.7671437, 22.3610545],
                [103.7671593, 22.3611518],
                [103.7674463, 22.3617347],
                [103.7674464, 22.3617905],
                [103.7674302, 22.3619009],
                [103.7673792, 22.3620332],
                [103.7670252, 22.362454],
                [103.7670947, 22.3626569],
                [103.7671546, 22.3628625],
                [103.7672046, 22.3630702],
                [103.7672446, 22.3632798],
                [103.7672939, 22.3636938],
                [103.7673046, 22.3641009],
                [103.7672531, 22.3654356],
                [103.7671347, 22.3655889],
                [103.7669199, 22.3659092],
                [103.7667542, 22.3662086],
                [103.7666295, 22.3664811],
                [103.7668455, 22.3673802],
                [103.7670278, 22.3682235],
                [103.7671079, 22.3686456],
                [103.7672424, 22.3694935],
                [103.7670439, 22.369806],
                [103.7669852, 22.3699332],
                [103.7669422, 22.370093],
                [103.7668991, 22.3705005],
                [103.7665236, 22.3714282],
                [103.7664971, 22.3716062],
                [103.7664807, 22.3717853],
                [103.7664749, 22.3720566],
                [103.7665665, 22.3734124],
                [103.7665722, 22.3737035],
                [103.7665527, 22.3740184],
                [103.7664808, 22.3745079],
                [103.7664669, 22.3746616],
                [103.7664604, 22.3748158],
                [103.76647, 22.3751241],
                [103.7665119, 22.3754437],
                [103.7665465, 22.3756085],
                [103.7666131, 22.3758527],
                [103.7666416, 22.3759324],
                [103.7667298, 22.3761155],
                [103.766845, 22.3762853],
                [103.7669849, 22.3764384],
                [103.7676608, 22.3770237],
                [103.7678259, 22.3772643],
                [103.7678969, 22.3773908],
                [103.7680024, 22.3776226],
                [103.7680455, 22.377742],
                [103.7681115, 22.377986],
                [103.7681523, 22.3781741],
                [103.7682113, 22.3785539],
                [103.7682407, 22.3789558],
                [103.7682426, 22.3791668],
                [103.7682188, 22.3795882],
                [103.7679988, 22.3798586],
                [103.7679832, 22.3799049],
                [103.7679734, 22.3800023],
                [103.7679797, 22.3800514],
                [103.7680365, 22.3802083],
                [103.7680452, 22.380266],
                [103.7680364, 22.3803819],
                [103.7679948, 22.3804882],
                [103.7679237, 22.3805803],
                [103.767634, 22.3808134],
                [103.7673336, 22.3816666],
                [103.7662822, 22.3830654],
                [103.7657297, 22.3831398],
                [103.7656453, 22.3831838],
                [103.7656109, 22.3832156],
                [103.7655634, 22.3832936],
                [103.7655488, 22.3833809],
                [103.7655544, 22.383425],
                [103.7655902, 22.3835069],
                [103.7663412, 22.384236],
                [103.7654936, 22.3853471],
                [103.765415, 22.3855517],
                [103.7653649, 22.3857638],
                [103.7653452, 22.3859486],
                [103.7653434, 22.3860415],
                [103.7653649, 22.3863987],
                [103.7657082, 22.3870931],
                [103.7655848, 22.3875445],
                [103.7655328, 22.3876175],
                [103.7654132, 22.3877528],
                [103.7653332, 22.3878253],
                [103.7651557, 22.3879512],
                [103.7646782, 22.3881744],
                [103.7646367, 22.3885742],
                [103.7645883, 22.3887754],
                [103.7645134, 22.3889745],
                [103.7644655, 22.3890729],
                [103.764351, 22.3892606],
                [103.7643071, 22.3894063],
                [103.7642959, 22.3895572],
                [103.764351, 22.3899302],
                [103.7643559, 22.3900617],
                [103.764351, 22.3901931],
                [103.7642062, 22.3907834],
                [103.7641874, 22.3908463],
                [103.7641246, 22.390963],
                [103.7640315, 22.391061],
                [103.7639755, 22.3911008],
                [103.76385, 22.3911587],
                [103.7637126, 22.3911851],
                [103.7631333, 22.3912496],
                [103.7630164, 22.3913077],
                [103.7627953, 22.391443],
                [103.7626668, 22.39154],
                [103.762428, 22.3917528],
                [103.762216, 22.3919886],
                [103.7620632, 22.3921984],
                [103.761685, 22.3928373],
                [103.7611699, 22.3936601],
                [103.760899, 22.3940695],
                [103.7603357, 22.3948755],
                [103.7600434, 22.3952721],
                [103.7598457, 22.3954669],
                [103.7594694, 22.3958722],
                [103.7592792, 22.3960967],
                [103.7589222, 22.3965616],
                [103.7581547, 22.3970913],
                [103.7579762, 22.3971796],
                [103.7578819, 22.3972138],
                [103.7576619, 22.3972759],
                [103.7575529, 22.3973391],
                [103.7574719, 22.3974178],
                [103.7574424, 22.3974589],
                [103.7573987, 22.3975486],
                [103.7573504, 22.3979702],
                [103.7567067, 22.3983273],
                [103.7563795, 22.3986645],
                [103.7549204, 22.3996962],
                [103.7551027, 22.4001227],
                [103.7550926, 22.4001795],
                [103.7550548, 22.4002891],
                [103.7549842, 22.4004046],
                [103.7549325, 22.4004628],
                [103.7548734, 22.4005146],
                [103.7548077, 22.4005591],
                [103.7536919, 22.4008766],
                [103.7536036, 22.4009746],
                [103.753413, 22.4011593],
                [103.7531769, 22.4013477],
                [103.7522381, 22.4018834],
                [103.7512189, 22.4019577],
                [103.7511287, 22.402004],
                [103.7509635, 22.4021179],
                [103.7508227, 22.402257],
                [103.7507109, 22.4024169],
                [103.7506315, 22.4025926],
                [103.7505504, 22.4026725],
                [103.7503713, 22.4028158],
                [103.75027, 22.402881],
                [103.7500536, 22.4029904],
                [103.7499395, 22.403034],
                [103.7494031, 22.4030662],
                [103.749104, 22.4031369],
                [103.749159, 22.4033663],
                [103.7491882, 22.4034473],
                [103.7492609, 22.4036043],
                [103.749572, 22.4040011],
                [103.7497383, 22.4041945],
                [103.7497696, 22.4042521],
                [103.7497786, 22.404316],
                [103.749761, 22.4043859],
                [103.7497169, 22.404445],
                [103.7494862, 22.4045466],
                [103.7494378, 22.4046396],
                [103.7493627, 22.4048337],
                [103.7493388, 22.4049229],
                [103.7492953, 22.4051581],
                [103.7492126, 22.4053451],
                [103.7492101, 22.4053841],
                [103.7492314, 22.4054592],
                [103.7493896, 22.4056526],
                [103.7494128, 22.4057101],
                [103.7494272, 22.40577],
                [103.7494303, 22.4058796],
                [103.7494223, 22.4059275],
                [103.7493896, 22.4060196],
                [103.7492316, 22.4062703],
                [103.7491643, 22.4064015],
                [103.7490624, 22.4066494],
                [103.7487674, 22.4069222],
                [103.7487298, 22.4073636],
                [103.7469381, 22.4074181],
                [103.7469047, 22.4074338],
                [103.7468469, 22.4074777],
                [103.7468216, 22.4075077],
                [103.7467879, 22.4075768],
                [103.7467208, 22.4079711],
                [103.7466646, 22.4082087],
                [103.7466374, 22.4083783],
                [103.7466377, 22.4085588],
                [103.7466646, 22.4086421],
                [103.7466817, 22.4087275],
                [103.7466888, 22.4088141],
                [103.7466738, 22.4089835],
                [103.7466226, 22.4091431],
                [103.7465841, 22.4092184],
                [103.7465358, 22.4092779],
                [103.7461871, 22.4092779],
                [103.7460744, 22.4093622],
                [103.7456667, 22.4093721],
                [103.7455543, 22.4095107],
                [103.7454683, 22.4096647],
                [103.7454161, 22.4098107],
                [103.7453878, 22.4099623],
                [103.7449157, 22.4097242],
                [103.7446555, 22.4097441],
                [103.7444996, 22.409741],
                [103.7441891, 22.4097133],
                [103.7438831, 22.4096573],
                [103.7435777, 22.4095711],
                [103.743429, 22.4095173],
                [103.7431414, 22.4093892],
                [103.7430033, 22.4093151],
                [103.7425801, 22.409727],
                [103.7422389, 22.410059],
                [103.7420136, 22.410054],
                [103.7419644, 22.4100981],
                [103.7418539, 22.4101728],
                [103.7417183, 22.4102317],
                [103.7415596, 22.4102658],
                [103.7413967, 22.4102673],
                [103.7413668, 22.4104193],
                [103.741284, 22.4107186],
                [103.7412314, 22.4108645],
                [103.7411043, 22.4111488],
                [103.7404954, 22.411879],
                [103.7402297, 22.412217],
                [103.7397176, 22.4129056],
                [103.7394843, 22.4129829],
                [103.7393644, 22.413012],
                [103.7391256, 22.4130499],
                [103.7388892, 22.4130623],
                [103.7381405, 22.413032],
                [103.738086, 22.4130331],
                [103.7379814, 22.4130594],
                [103.7378953, 22.4131124],
                [103.7378616, 22.4131456],
                [103.7378118, 22.4132239],
                [103.7377891, 22.4133122],
                [103.7377807, 22.4133872],
                [103.737781, 22.4135379],
                [103.7377595, 22.413679],
                [103.7377355, 22.4137469],
                [103.737663, 22.4138726],
                [103.7371568, 22.4137158],
                [103.7369066, 22.4136296],
                [103.7363384, 22.4134115],
                [103.7360591, 22.4132924],
                [103.7359831, 22.4133937],
                [103.7358552, 22.4136098],
                [103.7358031, 22.4137259],
                [103.7357601, 22.4138451],
                [103.7357265, 22.4139669],
                [103.7356646, 22.4141019],
                [103.7355751, 22.4142294],
                [103.7354582, 22.4143438],
                [103.7353524, 22.4144067],
                [103.7352413, 22.4144614],
                [103.7350341, 22.4145367],
                [103.734846, 22.414579],
                [103.7347501, 22.4145917],
                [103.734539, 22.4145222],
                [103.7344302, 22.4144973],
                [103.7342083, 22.4144677],
                [103.7340757, 22.4144631],
                [103.7339431, 22.4144681],
                [103.7338114, 22.4144826],
                [103.7334385, 22.4142619],
                [103.7328431, 22.4141751],
                [103.7328321, 22.4142389],
                [103.7328116, 22.4143006],
                [103.732782, 22.4143591],
                [103.7327439, 22.4144132],
                [103.7326497, 22.4145008],
                [103.7325338, 22.4145623],
                [103.7324703, 22.4145818],
                [103.7323509, 22.4146004],
                [103.7323377, 22.4146404],
                [103.7322959, 22.4147144],
                [103.7322633, 22.4147522],
                [103.7321826, 22.4148131],
                [103.7321361, 22.4148351],
                [103.731617, 22.4150177],
                [103.7315047, 22.4150827],
                [103.7314186, 22.4151671],
                [103.7313864, 22.4152132],
                [103.7313407, 22.4153143],
                [103.7313277, 22.4153678],
                [103.7313166, 22.4154837],
                [103.731298, 22.4155394],
                [103.7312366, 22.4156415],
                [103.7311465, 22.4157241],
                [103.731093, 22.4157559],
                [103.730873, 22.415917],
                [103.7310165, 22.4162344],
                [103.7310017, 22.4163047],
                [103.7309503, 22.4164397],
                [103.7308717, 22.4165629],
                [103.730771, 22.4166678],
                [103.7306506, 22.4167532],
                [103.7305149, 22.4168159],
                [103.7292767, 22.4163155],
                [103.7280251, 22.4159524],
                [103.7268906, 22.4152722],
                [103.7250453, 22.4132792],
                [103.7240651, 22.4123529],
                [103.7231908, 22.4123107],
                [103.7223894, 22.4126223],
                [103.7212761, 22.4137794],
                [103.7200924, 22.4142999],
                [103.7191599, 22.4143809],
                [103.7161238, 22.4137208],
                [103.7158508, 22.4135058],
                [103.7145382, 22.4124718],
                [103.7132587, 22.411013],
                [103.7124384, 22.4092401],
                [103.7113806, 22.4084897],
                [103.7102025, 22.4084097],
                [103.7084892, 22.4086432],
                [103.706836, 22.4085418],
                [103.7049803, 22.4077143],
                [103.7022589, 22.4059613],
                [103.7008181, 22.4055258],
                [103.700286, 22.4054862],
                [103.6996341, 22.4060815],
                [103.6977935, 22.4077272],
                [103.6965714, 22.4082826],
                [103.695773, 22.4082762],
                [103.6937445, 22.4076415],
                [103.6887352, 22.4065765],
                [103.684904, 22.4055913],
                [103.6812948, 22.405244],
                [103.6792989, 22.40521],
                [103.6766901, 22.4036343],
                [103.6759694, 22.4034517],
                [103.6755121, 22.4035539],
                [103.6740986, 22.404249],
                [103.6724216, 22.4046592],
                [103.6703304, 22.4046419],
                [103.6658696, 22.4039163],
                [103.6627579, 22.4032547],
                [103.6606679, 22.403096],
                [103.6588003, 22.4035751],
                [103.6568934, 22.4041599],
                [103.6549921, 22.4041794],
                [103.6523919, 22.4037162],
                [103.6501143, 22.4033085],
                [103.6475248, 22.4037108],
                [103.6460015, 22.4039453],
                [103.6447489, 22.4037229],
                [103.6431652, 22.4023671],
                [103.642132, 22.4016192],
                [103.6412395, 22.4009731],
                [103.6386419, 22.4002625],
                [103.6343173, 22.3992367],
                [103.6310918, 22.3985735],
                [103.6295434, 22.3975005],
                [103.628564, 22.3965737],
                [103.627692, 22.396319],
                [103.6261712, 22.3963061],
                [103.6248546, 22.3968071],
                [103.6226746, 22.3980957],
                [103.619572, 22.4004009],
                [103.6181204, 22.4010951],
                [103.6177401, 22.4010919],
                [103.6169413, 22.4011204],
                [103.6164454, 22.4012927],
                [103.6162528, 22.4015384],
                [103.6153654, 22.4028379],
                [103.614674, 22.4035385],
                [103.6133537, 22.4043929],
                [103.6098791, 22.4058467],
                [103.6061713, 22.4077932],
                [103.6043318, 22.4092258],
                [103.6031377, 22.41077],
                [103.6027622, 22.4121975],
                [103.6028755, 22.4160845],
                [103.6031791, 22.4199379],
                [103.6035897, 22.4226087],
                [103.6034259, 22.4237731],
                [103.6029977, 22.4247586],
                [103.6016935, 22.4258779],
                [103.5993007, 22.4274646],
                [103.5956037, 22.4282803],
                [103.5930533, 22.4285054],
                [103.5904653, 22.4286948],
                [103.588639, 22.4287848],
                [103.5879527, 22.4289555],
                [103.5877213, 22.4292714],
                [103.587602, 22.4298003],
                [103.5878873, 22.4316751],
                [103.5884113, 22.4343823],
                [103.5885129, 22.4356196],
                [103.5882811, 22.4359709],
                [103.5875938, 22.4362475],
                [103.5858004, 22.4368324],
                [103.5841917, 22.4379488],
                [103.5828204, 22.4381487],
                [103.5819821, 22.438318],
                [103.5816003, 22.438456],
                [103.5807557, 22.4392258],
                [103.5797508, 22.4408068],
                [103.5791095, 22.4421612],
                [103.5785569, 22.4441346],
                [103.5780482, 22.4455433],
                [103.577008, 22.4468412],
                [103.5758586, 22.4476436],
                [103.5745641, 22.4477736],
                [103.5727795, 22.4474752],
                [103.5722112, 22.447258],
                [103.5718682, 22.4473258],
                [103.5717124, 22.4476776],
                [103.5715915, 22.4483478],
                [103.5714285, 22.4494062],
                [103.570829, 22.4503724],
                [103.5690963, 22.4524414],
                [103.5687051, 22.4534978],
                [103.5688716, 22.4557955],
                [103.5688639, 22.4565373],
                [103.5685543, 22.4570645],
                [103.5679021, 22.4575886],
                [103.5664888, 22.4581767],
                [103.5650174, 22.4588525],
                [103.5619499, 22.4612274],
                [103.5596531, 22.4626199],
                [103.5580133, 22.4630292],
                [103.5569497, 22.4628785],
                [103.556381, 22.4626967],
                [103.5561901, 22.4627657],
                [103.5561495, 22.4630126],
                [103.5562193, 22.4636138],
                [103.5567114, 22.4656849],
                [103.5567352, 22.4670628],
                [103.5563453, 22.4679779],
                [103.5554163, 22.4695239],
                [103.5552187, 22.4702288],
                [103.5552137, 22.4707079],
                [103.5552103, 22.4710411],
                [103.5555438, 22.471892],
                [103.5560226, 22.4725416],
                [103.5565005, 22.4731901],
                [103.5586771, 22.476071],
                [103.5594299, 22.4768549],
                [103.5604467, 22.4778532],
                [103.561127, 22.4782832],
                [103.5627159, 22.4791452],
                [103.563468, 22.4799998],
                [103.5641938, 22.4815431],
                [103.5655016, 22.4838509],
                [103.5666302, 22.4850976],
                [103.5687067, 22.486635],
                [103.5710253, 22.4887154],
                [103.572733, 22.4891542],
                [103.5747089, 22.4893834],
                [103.5756571, 22.4896743],
                [103.5762995, 22.4901035],
                [103.5770141, 22.4909218],
                [103.5786295, 22.4929481],
                [103.5797512, 22.4948642],
                [103.5811043, 22.4964997],
                [103.5831035, 22.4990471],
                [103.5835648, 22.4998199],
                [103.5838825, 22.5001963],
                [103.5841045, 22.500759],
                [103.5841931, 22.501283],
                [103.5843218, 22.5018072],
                [103.5844934, 22.502058],
                [103.5849581, 22.5024856],
                [103.5852225, 22.5028492],
                [103.5853932, 22.5031746],
                [103.5854441, 22.503449],
                [103.5852384, 22.5039082],
                [103.5853163, 22.5041456],
                [103.5857279, 22.5045229],
                [103.5895571, 22.5093835],
                [103.5867386, 22.514477],
                [103.5939409, 22.521625],
                [103.5956315, 22.5269044],
                [103.5840024, 22.5324859],
                [103.5802081, 22.5468744],
                [103.5679267, 22.5686019],
                [103.5727152, 22.5780768],
                [103.5560695, 22.5860774],
                [103.5470308, 22.5885658],
                [103.5301475, 22.5846545],
                [103.5294336, 22.5952161],
                [103.5231771, 22.5972312],
                [103.5208395, 22.5992508],
                [103.5192475, 22.5982096],
                [103.5175179, 22.5983144],
                [103.5159055, 22.5998363],
                [103.5149206, 22.6000022],
                [103.5134508, 22.6000558],
                [103.5123989, 22.6011816],
                [103.511206, 22.602236],
                [103.510418, 22.602652],
                [103.509574, 22.603171],
                [103.509012, 22.603639],
                [103.508506, 22.604626],
                [103.508281, 22.605249],
                [103.508449, 22.606288],
                [103.508506, 22.607638],
                [103.508899, 22.610443],
                [103.509012, 22.61195],
                [103.508899, 22.612677],
                [103.508449, 22.613404],
                [103.507887, 22.613456],
                [103.50648, 22.613196],
                [103.505018, 22.613093],
                [103.503612, 22.613145],
                [103.50243, 22.6133],
                [103.501136, 22.613612],
                [103.499955, 22.613976],
                [103.49823, 22.614587],
                [103.496778, 22.6149],
                [103.495859, 22.61548],
                [103.494891, 22.616508],
                [103.493971, 22.617313],
                [103.493246, 22.61767],
                [103.492568, 22.617849],
                [103.491165, 22.617804],
                [103.489906, 22.618027],
                [103.488939, 22.618474],
                [103.488455, 22.619145],
                [103.488019, 22.620351],
                [103.487438, 22.621736],
                [103.487003, 22.623256],
                [103.486761, 22.623747],
                [103.485938, 22.624105],
                [103.485309, 22.625713],
                [103.484293, 22.627054],
                [103.483228, 22.62835],
                [103.482648, 22.62978],
                [103.482357, 22.630897],
                [103.482502, 22.632282],
                [103.482841, 22.633488],
                [103.482357, 22.635052],
                [103.480954, 22.635499],
                [103.47926, 22.635857],
                [103.478147, 22.636438],
                [103.477615, 22.637376],
                [103.477276, 22.638404],
                [103.476115, 22.640012],
                [103.47505, 22.640817],
                [103.474372, 22.641934],
                [103.473792, 22.642649],
                [103.472679, 22.643051],
                [103.470501, 22.643319],
                [103.469243, 22.643811],
                [103.467743, 22.644302],
                [103.466581, 22.644794],
                [103.465904, 22.645553],
                [103.465129, 22.646626],
                [103.464597, 22.647609],
                [103.464113, 22.648368],
                [103.464065, 22.649128],
                [103.464258, 22.650245],
                [103.464742, 22.650781],
                [103.465275, 22.65163],
                [103.46542, 22.652435],
                [103.464646, 22.653105],
                [103.46392, 22.653597],
                [103.463242, 22.653954],
                [103.461694, 22.654624],
                [103.460387, 22.655875],
                [103.459758, 22.656724],
                [103.459226, 22.657663],
                [103.458838, 22.658199],
                [103.458355, 22.658691],
                [103.457483, 22.659182],
                [103.456951, 22.659718],
                [103.456564, 22.660389],
                [103.456564, 22.660925],
                [103.456806, 22.661729],
                [103.457145, 22.662355],
                [103.457435, 22.663025],
                [103.457822, 22.663517],
                [103.458064, 22.66441],
                [103.458064, 22.665393],
                [103.457871, 22.6666],
                [103.457822, 22.667225],
                [103.457919, 22.667851],
                [103.458645, 22.668655],
                [103.459274, 22.669728],
                [103.459661, 22.670532],
                [103.46, 22.671917],
                [103.460387, 22.672811],
                [103.461016, 22.674062],
                [103.462081, 22.675939],
                [103.462032, 22.67652],
                [103.461936, 22.677011],
                [103.4615, 22.677771],
                [103.459903, 22.679558],
                [103.458645, 22.680854],
                [103.457, 22.682865],
                [103.456564, 22.683535],
                [103.455209, 22.684697],
                [103.454386, 22.68501],
                [103.45187, 22.68644],
                [103.450902, 22.68711],
                [103.450225, 22.687646],
                [103.449354, 22.687959],
                [103.448482, 22.688361],
                [103.447708, 22.688763],
                [103.446837, 22.689389],
                [103.446353, 22.690104],
                [103.446208, 22.690864],
                [103.446208, 22.692115],
                [103.445918, 22.693321],
                [103.445482, 22.694528],
                [103.445047, 22.695243],
                [103.444708, 22.69569],
                [103.444272, 22.696092],
                [103.443885, 22.696315],
                [103.442385, 22.696717],
                [103.442046, 22.696851],
                [103.441659, 22.696896],
                [103.441175, 22.697119],
                [103.440207, 22.698237],
                [103.439627, 22.698639],
                [103.438368, 22.699845],
                [103.437401, 22.70065],
                [103.436142, 22.702079],
                [103.434739, 22.703286],
                [103.433674, 22.703733],
                [103.432319, 22.703912],
                [103.431884, 22.704135],
                [103.431787, 22.704537],
                [103.431884, 22.70561],
                [103.432077, 22.706637],
                [103.432126, 22.707308],
                [103.431835, 22.708737],
                [103.431448, 22.709452],
                [103.431013, 22.710167],
                [103.430384, 22.710748],
                [103.429658, 22.711508],
                [103.427867, 22.712536],
                [103.426948, 22.712759],
                [103.426609, 22.713161],
                [103.426416, 22.713697],
                [103.426367, 22.714323],
                [103.426512, 22.715083],
                [103.426754, 22.715753],
                [103.427093, 22.716468],
                [103.427674, 22.717362],
                [103.428351, 22.718032],
                [103.429125, 22.719372],
                [103.429271, 22.719953],
                [103.429271, 22.720713],
                [103.42898, 22.721651],
                [103.428932, 22.730052],
                [103.429029, 22.731348],
                [103.42898, 22.732331],
                [103.429077, 22.732912],
                [103.429319, 22.733269],
                [103.429706, 22.733672],
                [103.430432, 22.734029],
                [103.4314, 22.734208],
                [103.432513, 22.734565],
                [103.434255, 22.735459],
                [103.43561, 22.73604],
                [103.436239, 22.736397],
                [103.43711, 22.736978],
                [103.437691, 22.737514],
                [103.439481, 22.738006],
                [103.440982, 22.738051],
                [103.443208, 22.738185],
                [103.444756, 22.738185],
                [103.445095, 22.738274],
                [103.445143, 22.738498],
                [103.444563, 22.739168],
                [103.44374, 22.739659],
                [103.443111, 22.740196],
                [103.442627, 22.741089],
                [103.442337, 22.74243],
                [103.442046, 22.743279],
                [103.441804, 22.744351],
                [103.441127, 22.748954],
                [103.440643, 22.750874],
                [103.440277, 22.752564],
                [103.440155, 22.753466],
                [103.437897, 22.753917],
                [103.436616, 22.754029],
                [103.43509, 22.754987],
                [103.433442, 22.757636],
                [103.431917, 22.758819],
                [103.430269, 22.759664],
                [103.428499, 22.760115],
                [103.42734, 22.760622],
                [103.425936, 22.761129],
                [103.425387, 22.761073],
                [103.42496, 22.76096],
                [103.424349, 22.760679],
                [103.422396, 22.760679],
                [103.4213577, 22.7608943],
                [103.419894, 22.761805],
                [103.418796, 22.763383],
                [103.418186, 22.763721],
                [103.416416, 22.764003],
                [103.416111, 22.76451],
                [103.415805, 22.765356],
                [103.415683, 22.766652],
                [103.415928, 22.76761],
                [103.416233, 22.768117],
                [103.417209, 22.768737],
                [103.416904, 22.769582],
                [103.41605, 22.770371],
                [103.415012, 22.771554],
                [103.414463, 22.77285],
                [103.414219, 22.773864],
                [103.413853, 22.77471],
                [103.412815, 22.775442],
                [103.412327, 22.776175],
                [103.411412, 22.776964],
                [103.410069, 22.777752],
                [103.409398, 22.778372],
                [103.408665, 22.77871],
                [103.408116, 22.778485],
                [103.407567, 22.778091],
                [103.407018, 22.778091],
                [103.40598, 22.778598],
                [103.404882, 22.778485],
                [103.404271, 22.77871],
                [103.403234, 22.779443],
                [103.402502, 22.779837],
                [103.401769, 22.779837],
                [103.399816, 22.780401],
                [103.398962, 22.780739],
                [103.398108, 22.781415],
                [103.395667, 22.781753],
                [103.394385, 22.781641],
                [103.393042, 22.781641],
                [103.39231, 22.781584],
                [103.39109, 22.781415],
                [103.390113, 22.781697],
                [103.389808, 22.782035],
                [103.389198, 22.783838],
                [103.387977, 22.78474],
                [103.386146, 22.785191],
                [103.384377, 22.785472],
                [103.383705, 22.785698],
                [103.383217, 22.78643],
                [103.383217, 22.78705],
                [103.383339, 22.787783],
                [103.383644, 22.788741],
                [103.383583, 22.789304],
                [103.382119, 22.790431],
                [103.381386, 22.790488],
                [103.380471, 22.790431],
                [103.380044, 22.790318],
                [103.379739, 22.790318],
                [103.379555, 22.790657],
                [103.379861, 22.791502],
                [103.379617, 22.792122],
                [103.379128, 22.792572],
                [103.377847, 22.792572],
                [103.376992, 22.793474],
                [103.37687, 22.794207],
                [103.377053, 22.795165],
                [103.376748, 22.795953],
                [103.37626, 22.796461],
                [103.375528, 22.796799],
                [103.374795, 22.796742],
                [103.374246, 22.79663],
                [103.373575, 22.79663],
                [103.373209, 22.796968],
                [103.37272, 22.797588],
                [103.372537, 22.798996],
                [103.372049, 22.799391],
                [103.371256, 22.799334],
                [103.370829, 22.799222],
                [103.369791, 22.799278],
                [103.368693, 22.800292],
                [103.367777, 22.800574],
                [103.365519, 22.801138],
                [103.363322, 22.801025],
                [103.360942, 22.801532],
                [103.359966, 22.801588],
                [103.359356, 22.802039],
                [103.358745, 22.803166],
                [103.356548, 22.80311],
                [103.355633, 22.803166],
                [103.35484, 22.802715],
                [103.354107, 22.802434],
                [103.353375, 22.802715],
                [103.352582, 22.802828],
                [103.351544, 22.803561],
                [103.3513, 22.803786],
                [103.350141, 22.80373],
                [103.348432, 22.80373],
                [103.347699, 22.803899],
                [103.347089, 22.804688],
                [103.34654, 22.804913],
                [103.344404, 22.805477],
                [103.343245, 22.805871],
                [103.341597, 22.805871],
                [103.340498, 22.806209],
                [103.339949, 22.806435],
                [103.339095, 22.806265],
                [103.338362, 22.805927],
                [103.337752, 22.805815],
                [103.33702, 22.806209],
                [103.336104, 22.806096],
                [103.334457, 22.80604],
                [103.333846, 22.806435],
                [103.332687, 22.807336],
                [103.33171, 22.807956],
                [103.330795, 22.808463],
                [103.330063, 22.809083],
                [103.329758, 22.80959],
                [103.330002, 22.810548],
                [103.330124, 22.811337],
                [103.329758, 22.812013],
                [103.328781, 22.81252],
                [103.328476, 22.812577],
                [103.327988, 22.81314],
                [103.327744, 22.813816],
                [103.327316, 22.813929],
                [103.326401, 22.813253],
                [103.325547, 22.812915],
                [103.32512, 22.812915],
                [103.32457, 22.81252],
                [103.324265, 22.812182],
                [103.323472, 22.812182],
                [103.322129, 22.811562],
                [103.322068, 22.811055],
                [103.322068, 22.810435],
                [103.322251, 22.809815],
                [103.322129, 22.809421],
                [103.321519, 22.809196],
                [103.320726, 22.808463],
                [103.32042, 22.807843],
                [103.319566, 22.807336],
                [103.319322, 22.807054],
                [103.319444, 22.806265],
                [103.3192, 22.80542],
                [103.318895, 22.804913],
                [103.318407, 22.804406],
                [103.318101, 22.803842],
                [103.31804, 22.802208],
                [103.318162, 22.80018],
                [103.317918, 22.79894],
                [103.31804, 22.798151],
                [103.318346, 22.797869],
                [103.318651, 22.797475],
                [103.318895, 22.797306],
                [103.3192, 22.796799],
                [103.319017, 22.796404],
                [103.318773, 22.795559],
                [103.318895, 22.795052],
                [103.319322, 22.793981],
                [103.319444, 22.793361],
                [103.319566, 22.792347],
                [103.319444, 22.789868],
                [103.319749, 22.789079],
                [103.319688, 22.788853],
                [103.319078, 22.788459],
                [103.31859, 22.788234],
                [103.318468, 22.787895],
                [103.318162, 22.787332],
                [103.317796, 22.786881],
                [103.317979, 22.786374],
                [103.318834, 22.785303],
                [103.319566, 22.784064],
                [103.320054, 22.783444],
                [103.320176, 22.783049],
                [103.320054, 22.782373],
                [103.320115, 22.781641],
                [103.320298, 22.780964],
                [103.320481, 22.78057],
                [103.320054, 22.780176],
                [103.319444, 22.779894],
                [103.318773, 22.779668],
                [103.317918, 22.779781],
                [103.316393, 22.779443],
                [103.316027, 22.779161],
                [103.315782, 22.778541],
                [103.315904, 22.778203],
                [103.316149, 22.777752],
                [103.315721, 22.777583],
                [103.31505, 22.777752],
                [103.314257, 22.777583],
                [103.313402, 22.777527],
                [103.313341, 22.776964],
                [103.313402, 22.776738],
                [103.313891, 22.776175],
                [103.314257, 22.775949],
                [103.314562, 22.775498],
                [103.314501, 22.775048],
                [103.314379, 22.774766],
                [103.313891, 22.774822],
                [103.313341, 22.775048],
                [103.312792, 22.774259],
                [103.312731, 22.773808],
                [103.312426, 22.77347],
                [103.311877, 22.773075],
                [103.311633, 22.772456],
                [103.310961, 22.771667],
                [103.311022, 22.771047],
                [103.311755, 22.770878],
                [103.31206, 22.770765],
                [103.312121, 22.770258],
                [103.311877, 22.769976],
                [103.310473, 22.768906],
                [103.309558, 22.767497],
                [103.309069, 22.767046],
                [103.308886, 22.766257],
                [103.308947, 22.765806],
                [103.309802, 22.765468],
                [103.310168, 22.765243],
                [103.310168, 22.764679],
                [103.310351, 22.764341],
                [103.310473, 22.763778],
                [103.310107, 22.763383],
                [103.309863, 22.762989],
                [103.309558, 22.76282],
                [103.30852, 22.762876],
                [103.308276, 22.762707],
                [103.308032, 22.76096],
                [103.307788, 22.760566],
                [103.307422, 22.760397],
                [103.307422, 22.760115],
                [103.307361, 22.759833],
                [103.307117, 22.759552],
                [103.306506, 22.759552],
                [103.305896, 22.759608],
                [103.305591, 22.759439],
                [103.304431, 22.759552],
                [103.304187, 22.759326],
                [103.303516, 22.758988],
                [103.302295, 22.757917],
                [103.302173, 22.757579],
                [103.302723, 22.757128],
                [103.302906, 22.756847],
                [103.303028, 22.756283],
                [103.302906, 22.756058],
                [103.302112, 22.755889],
                [103.301502, 22.755663],
                [103.30077, 22.755325],
                [103.300404, 22.754987],
                [103.300221, 22.754649],
                [103.300282, 22.753466],
                [103.300709, 22.752846],
                [103.30077, 22.752339],
                [103.300648, 22.751944],
                [103.299183, 22.751324],
                [103.298695, 22.75093],
                [103.298451, 22.750648],
                [103.297841, 22.750536],
                [103.297413, 22.749972],
                [103.297062, 22.749713],
                [103.296627, 22.748507],
                [103.296095, 22.747658],
                [103.295756, 22.746541],
                [103.295804, 22.745602],
                [103.295175, 22.7452],
                [103.294449, 22.744977],
                [103.293772, 22.744664],
                [103.293481, 22.744038],
                [103.293385, 22.743458],
                [103.292852, 22.742743],
                [103.291255, 22.741045],
                [103.29082, 22.740508],
                [103.290433, 22.739749],
                [103.2899, 22.739257],
                [103.288787, 22.738855],
                [103.28811, 22.738319],
                [103.287529, 22.737291],
                [103.287432, 22.736129],
                [103.287626, 22.734118],
                [103.287578, 22.733582],
                [103.287094, 22.733269],
                [103.287239, 22.732688],
                [103.287529, 22.732331],
                [103.287481, 22.731884],
                [103.287094, 22.731393],
                [103.287094, 22.731035],
                [103.286706, 22.730544],
                [103.286271, 22.729829],
                [103.286029, 22.729337],
                [103.286126, 22.728667],
                [103.286319, 22.728265],
                [103.286561, 22.728041],
                [103.28661, 22.727684],
                [103.286513, 22.727326],
                [103.286755, 22.726879],
                [103.287142, 22.726433],
                [103.287142, 22.725941],
                [103.287287, 22.725628],
                [103.287626, 22.725405],
                [103.288013, 22.72536],
                [103.288255, 22.725181],
                [103.288255, 22.724824],
                [103.288158, 22.724154],
                [103.288303, 22.723975],
                [103.288739, 22.72393],
                [103.288787, 22.723662],
                [103.288691, 22.723081],
                [103.289223, 22.722232],
                [103.289368, 22.721875],
                [103.289804, 22.72107],
                [103.290094, 22.720713],
                [103.290481, 22.72049],
                [103.290481, 22.720177],
                [103.290094, 22.720087],
                [103.289707, 22.719909],
                [103.289513, 22.71973],
                [103.289707, 22.719551],
                [103.290336, 22.719283],
                [103.290191, 22.719015],
                [103.289949, 22.718747],
                [103.289368, 22.718657],
                [103.289029, 22.718479],
                [103.288932, 22.7183],
                [103.288642, 22.718077],
                [103.288303, 22.717585],
                [103.288545, 22.716959],
                [103.288594, 22.716691],
                [103.288352, 22.716379],
                [103.288207, 22.716066],
                [103.288352, 22.715261],
                [103.288061, 22.714904],
                [103.287916, 22.714368],
                [103.287626, 22.713921],
                [103.287626, 22.713653],
                [103.287868, 22.713072],
                [103.287965, 22.712938],
                [103.288497, 22.712848],
                [103.288642, 22.712625],
                [103.288691, 22.712402],
                [103.288642, 22.712044],
                [103.2880325, 22.7083626],
                [103.288545, 22.706548],
                [103.288207, 22.705833],
                [103.287916, 22.70552],
                [103.287481, 22.705297],
                [103.286755, 22.705207],
                [103.285884, 22.705252],
                [103.285448, 22.705163],
                [103.285061, 22.704805],
                [103.284722, 22.704314],
                [103.284577, 22.703956],
                [103.28448, 22.702973],
                [103.284287, 22.70266],
                [103.283561, 22.701945],
                [103.283319, 22.701588],
                [103.283174, 22.700694],
                [103.283174, 22.699666],
                [103.283029, 22.698415],
                [103.283029, 22.697298],
                [103.282545, 22.69627],
                [103.282593, 22.694662],
                [103.282545, 22.694036],
                [103.282254, 22.693321],
                [103.282545, 22.692964],
                [103.282738, 22.692562],
                [103.282883, 22.691981],
                [103.283077, 22.691534],
                [103.283077, 22.691087],
                [103.282883, 22.690417],
                [103.282545, 22.689523],
                [103.282206, 22.688942],
                [103.282012, 22.688272],
                [103.282012, 22.688048],
                [103.282351, 22.687155],
                [103.282787, 22.686797],
                [103.283222, 22.686261],
                [103.283658, 22.685278],
                [103.283755, 22.684876],
                [103.283609, 22.684161],
                [103.283319, 22.683803],
                [103.28298, 22.683267],
                [103.282738, 22.682731],
                [103.282061, 22.681882],
                [103.28177, 22.681614],
                [103.281577, 22.681256],
                [103.281238, 22.680944],
                [103.280803, 22.68072],
                [103.279399, 22.679558],
                [103.278528, 22.679022],
                [103.277609, 22.678218],
                [103.276496, 22.677592],
                [103.274995, 22.676922],
                [103.274318, 22.676877],
                [103.273834, 22.676609],
                [103.27335, 22.67652],
                [103.272963, 22.676386],
                [103.272721, 22.675805],
                [103.272624, 22.675358],
                [103.272334, 22.675001],
                [103.27185, 22.674643],
                [103.271511, 22.674464],
                [103.271269, 22.674152],
                [103.270882, 22.673973],
                [103.270253, 22.673928],
                [103.269817, 22.67366],
                [103.269479, 22.673347],
                [103.269092, 22.673079],
                [103.268559, 22.67299],
                [103.268269, 22.673168],
                [103.268124, 22.673392],
                [103.267156, 22.674017],
                [103.266527, 22.674196],
                [103.265898, 22.674464],
                [103.265317, 22.674464],
                [103.264785, 22.674107],
                [103.264639, 22.673615],
                [103.264349, 22.673258],
                [103.263962, 22.67299],
                [103.263672, 22.672588],
                [103.26343, 22.672453],
                [103.262946, 22.671962],
                [103.261929, 22.671292],
                [103.261494, 22.671202],
                [103.260333, 22.671202],
                [103.2598, 22.67156],
                [103.258542, 22.672096],
                [103.257816, 22.672275],
                [103.256945, 22.672275],
                [103.256461, 22.672007],
                [103.255687, 22.672007],
                [103.254913, 22.672275],
                [103.253461, 22.6729],
                [103.252106, 22.673079],
                [103.25167, 22.6729],
                [103.25138, 22.672632],
                [103.25109, 22.672275],
                [103.251138, 22.671783],
                [103.251477, 22.671292],
                [103.251622, 22.670755],
                [103.25167, 22.670219],
                [103.251574, 22.669772],
                [103.25138, 22.669281],
                [103.250799, 22.668789],
                [103.250654, 22.668521],
                [103.250072, 22.668246],
                [103.248702, 22.668008],
                [103.246916, 22.667427],
                [103.246337, 22.667114],
                [103.245517, 22.666533],
                [103.244743, 22.665907],
                [103.243778, 22.665416],
                [103.241703, 22.664746],
                [103.241074, 22.664477],
                [103.240882, 22.663762],
                [103.239916, 22.663449],
                [103.23953, 22.663047],
                [103.239144, 22.66251],
                [103.239144, 22.661974],
                [103.239433, 22.661304],
                [103.23924, 22.661036],
                [103.237019, 22.660097],
                [103.236441, 22.65974],
                [103.236199, 22.659338],
                [103.235764, 22.658891],
                [103.23562, 22.658623],
                [103.235475, 22.658489],
                [103.23504, 22.658444],
                [103.234413, 22.658533],
                [103.232964, 22.65907],
                [103.232386, 22.65907],
                [103.232095, 22.658802],
                [103.231806, 22.658355],
                [103.231612, 22.657908],
                [103.231178, 22.656432],
                [103.231034, 22.655628],
                [103.230551, 22.654064],
                [103.230502, 22.653349],
                [103.230357, 22.652679],
                [103.230357, 22.652277],
                [103.230213, 22.65183],
                [103.230213, 22.651651],
                [103.230068, 22.651517],
                [103.229585, 22.651517],
                [103.229103, 22.651428],
                [103.228813, 22.651204],
                [103.228764, 22.650221],
                [103.228813, 22.650087],
                [103.228475, 22.649505],
                [103.228234, 22.649326],
                [103.228089, 22.64879],
                [103.227944, 22.648522],
                [103.22804, 22.648209],
                [103.227751, 22.647896],
                [103.227606, 22.647807],
                [103.226641, 22.647896],
                [103.226255, 22.648031],
                [103.225675, 22.64812],
                [103.225482, 22.648075],
                [103.225386, 22.647986],
                [103.225675, 22.647405],
                [103.225482, 22.647182],
                [103.225241, 22.647137],
                [103.224903, 22.647271],
                [103.224614, 22.647584],
                [103.224226, 22.647628],
                [103.223937, 22.647584],
                [103.22331, 22.647316],
                [103.223165, 22.646958],
                [103.222972, 22.646779],
                [103.222538, 22.646735],
                [103.222152, 22.646511],
                [103.221186, 22.646824],
                [103.220751, 22.646913],
                [103.220123, 22.646913],
                [103.219399, 22.646735],
                [103.21911, 22.646556],
                [103.218724, 22.646422],
                [103.218048, 22.646377],
                [103.217179, 22.646645],
                [103.216745, 22.646735],
                [103.216358, 22.646735],
                [103.216165, 22.646511],
                [103.215876, 22.646422],
                [103.215393, 22.646556],
                [103.21491, 22.646779],
                [103.214427, 22.647092],
                [103.214137, 22.647405],
                [103.213703, 22.647494],
                [103.213558, 22.64745],
                [103.21322, 22.647226],
                [103.213076, 22.646958],
                [103.212737, 22.646735],
                [103.212399, 22.646556],
                [103.211724, 22.646377],
                [103.209889, 22.646109],
                [103.209359, 22.646154],
                [103.208924, 22.646243],
                [103.208441, 22.646243],
                [103.207813, 22.646333],
                [103.207572, 22.646422],
                [103.206848, 22.646556],
                [103.206365, 22.646511],
                [103.205882, 22.646601],
                [103.205062, 22.64736],
                [103.204772, 22.647762],
                [103.204435, 22.647807],
                [103.203903, 22.647316],
                [103.203372, 22.647271],
                [103.202696, 22.647628],
                [103.202455, 22.648075],
                [103.201827, 22.648567],
                [103.201538, 22.648701],
                [103.201152, 22.64879],
                [103.1998, 22.649237],
                [103.199414, 22.649282],
                [103.198399, 22.649818],
                [103.197821, 22.650176],
                [103.197048, 22.650981],
                [103.196179, 22.651115],
                [103.195793, 22.650981],
                [103.195551, 22.650713],
                [103.195359, 22.6504],
                [103.195165, 22.6504],
                [103.194731, 22.650221],
                [103.194249, 22.65031],
                [103.193669, 22.650176],
                [103.193331, 22.649952],
                [103.193089, 22.649684],
                [103.192752, 22.64946],
                [103.192414, 22.649103],
                [103.191931, 22.648746],
                [103.191255, 22.648611],
                [103.190676, 22.648299],
                [103.190483, 22.64812],
                [103.19029, 22.647807],
                [103.189952, 22.646645],
                [103.189662, 22.646333],
                [103.188745, 22.645841],
                [103.188021, 22.645752],
                [103.187393, 22.645618],
                [103.18638, 22.645171],
                [103.186621, 22.645305],
                [103.186235, 22.645171],
                [103.185656, 22.644903],
                [103.185703, 22.644769],
                [103.185414, 22.64459],
                [103.185173, 22.644545],
                [103.184931, 22.644411],
                [103.184883, 22.644188],
                [103.185125, 22.643875],
                [103.185365, 22.643696],
                [103.185414, 22.643428],
                [103.185173, 22.643026],
                [103.185028, 22.642667],
                [103.185317, 22.641818],
                [103.184979, 22.641595],
                [103.184835, 22.641416],
                [103.183821, 22.641193],
                [103.183435, 22.641014],
                [103.183241, 22.640835],
                [103.183049, 22.640478],
                [103.182855, 22.640254],
                [103.182855, 22.639539],
                [103.182903, 22.639316],
                [103.18329, 22.639093],
                [103.183966, 22.638288],
                [103.183918, 22.637752],
                [103.183966, 22.637395],
                [103.184448, 22.63668],
                [103.184255, 22.636143],
                [103.184304, 22.635875],
                [103.184496, 22.635652],
                [103.184545, 22.635249],
                [103.184207, 22.634132],
                [103.183918, 22.633908],
                [103.183821, 22.633595],
                [103.183821, 22.633238],
                [103.183966, 22.63288],
                [103.184304, 22.632702],
                [103.184545, 22.632478],
                [103.184738, 22.63221],
                [103.184738, 22.631897],
                [103.184642, 22.631451],
                [103.184545, 22.631138],
                [103.184255, 22.630512],
                [103.184062, 22.630333],
                [103.183627, 22.630333],
                [103.183386, 22.630602],
                [103.182711, 22.63087],
                [103.182131, 22.630289],
                [103.181986, 22.629887],
                [103.181697, 22.629618],
                [103.181407, 22.629484],
                [103.180973, 22.629484],
                [103.180538, 22.629752],
                [103.180007, 22.629842],
                [103.17909, 22.629887],
                [103.178752, 22.629842],
                [103.178221, 22.630021],
                [103.177931, 22.630333],
                [103.176773, 22.630691],
                [103.176338, 22.63078],
                [103.176, 22.630646],
                [103.175904, 22.630378],
                [103.176386, 22.628591],
                [103.17629, 22.627919],
                [103.17629, 22.627294],
                [103.176194, 22.626624],
                [103.175373, 22.626266],
                [103.174842, 22.626221],
                [103.174648, 22.625864],
                [103.174311, 22.625417],
                [103.174166, 22.625015],
                [103.174214, 22.624747],
                [103.174166, 22.624479],
                [103.172815, 22.624037],
                [103.172912, 22.623545],
                [103.173079, 22.623227],
                [103.17267, 22.623099],
                [103.17238, 22.62283],
                [103.171992, 22.622562],
                [103.171897, 22.622088],
                [103.171246, 22.621753],
                [103.170686, 22.621624],
                [103.170444, 22.621266],
                [103.170105, 22.620909],
                [103.1697536, 22.6204446],
                [103.1696238, 22.6193176],
                [103.168971, 22.6188153],
                [103.1682759, 22.6187516],
                [103.1683055, 22.6181173],
                [103.1679438, 22.6167987],
                [103.1676675, 22.6162291],
                [103.1668847, 22.6149795],
                [103.1663328, 22.6142444],
                [103.1662604, 22.6135712],
                [103.1660324, 22.6129967],
                [103.165911, 22.612457],
                [103.1656626, 22.6118021],
                [103.1654778, 22.6113982],
                [103.1652806, 22.6108635],
                [103.1652402, 22.6104505],
                [103.165525, 22.6097434],
                [103.165669, 22.609373],
                [103.1657794, 22.609171],
                [103.1660136, 22.6088127],
                [103.1660793, 22.6086671],
                [103.1661465, 22.6083208],
                [103.166097, 22.6081476],
                [103.1659334, 22.6080106],
                [103.1655767, 22.6077621],
                [103.1645362, 22.6071119],
                [103.1641099, 22.60673],
                [103.163787, 22.606111],
                [103.1638763, 22.6057321],
                [103.164204, 22.6053389],
                [103.1647593, 22.6050065],
                [103.164752, 22.604189],
                [103.1640271, 22.6036679],
                [103.163798, 22.6035073],
                [103.1628002, 22.6027383],
                [103.1623575, 22.6021928],
                [103.1622028, 22.6011019],
                [103.1621318, 22.5996919],
                [103.1623268, 22.5980941],
                [103.162435, 22.597709],
                [103.160553, 22.597574],
                [103.159973, 22.59753],
                [103.159635, 22.597217],
                [103.159249, 22.596681],
                [103.158911, 22.596011],
                [103.158525, 22.595742],
                [103.158139, 22.595296],
                [103.157897, 22.594715],
                [103.158042, 22.593866],
                [103.158187, 22.593374],
                [103.159152, 22.592525],
                [103.159635, 22.591855],
                [103.159587, 22.590558],
                [103.160359, 22.588324],
                [103.160939, 22.587609],
                [103.161469, 22.586402],
                [103.162097, 22.583765],
                [103.163207, 22.582871],
                [103.165235, 22.580324],
                [103.166587, 22.579386],
                [103.16818, 22.578492],
                [103.168759, 22.57809],
                [103.169435, 22.575989],
                [103.170304, 22.575408],
                [103.171125, 22.574738],
                [103.171994, 22.573755],
                [103.173346, 22.570761],
                [103.173587, 22.569867],
                [103.174118, 22.568794],
                [103.174311, 22.568123],
                [103.175034, 22.56781],
                [103.175904, 22.567498],
                [103.176435, 22.56714],
                [103.176821, 22.56647],
                [103.176869, 22.565666],
                [103.177593, 22.564951],
                [103.178173, 22.564057],
                [103.178608, 22.563521],
                [103.180345, 22.562538],
                [103.181407, 22.561822],
                [103.183532, 22.560526],
                [103.18358, 22.560124],
                [103.183435, 22.559811],
                [103.183097, 22.55923],
                [103.182663, 22.558828],
                [103.182421, 22.558515],
                [103.182517, 22.557889],
                [103.182373, 22.557443],
                [103.181165, 22.556728],
                [103.180876, 22.55646],
                [103.180007, 22.556594],
                [103.179235, 22.556504],
                [103.178414, 22.556191],
                [103.177642, 22.555476],
                [103.177015, 22.555298],
                [103.176386, 22.554761],
                [103.175904, 22.554269],
                [103.174987, 22.553554],
                [103.174262, 22.55275],
                [103.173393, 22.552035],
                [103.173297, 22.551722],
                [103.173297, 22.551007],
                [103.172332, 22.550247],
                [103.171511, 22.550158],
                [103.170931, 22.550203],
                [103.170159, 22.550024],
                [103.170015, 22.549264],
                [103.170497, 22.548773],
                [103.170497, 22.548549],
                [103.170159, 22.548415],
                [103.169483, 22.548415],
                [103.169146, 22.548237],
                [103.169338, 22.54779],
                [103.16818, 22.547924],
                [103.167697, 22.54779],
                [103.167311, 22.547611],
                [103.16707, 22.547342],
                [103.164463, 22.54694],
                [103.164222, 22.546761],
                [103.164125, 22.546448],
                [103.163353, 22.545554],
                [103.161808, 22.544169],
                [103.15978, 22.542203],
                [103.158621, 22.540773],
                [103.158187, 22.540505],
                [103.157511, 22.540371],
                [103.15669, 22.54055],
                [103.155821, 22.540952],
                [103.153987, 22.542024],
                [103.152394, 22.542873],
                [103.150849, 22.543633],
                [103.149884, 22.543991],
                [103.147422, 22.543901],
                [103.146311, 22.543454],
                [103.145298, 22.542605],
                [103.143656, 22.541533],
                [103.142546, 22.541175],
                [103.141243, 22.540997],
                [103.14047, 22.540773],
                [103.139891, 22.54055],
                [103.139456, 22.540102],
                [103.138973, 22.5397],
                [103.138684, 22.538672],
                [103.138298, 22.537734],
                [103.137912, 22.537287],
                [103.136802, 22.536661],
                [103.135787, 22.535455],
                [103.134774, 22.533623],
                [103.134291, 22.532594],
                [103.132312, 22.530315],
                [103.131153, 22.529108],
                [103.129319, 22.527723],
                [103.127823, 22.527232],
                [103.125456, 22.526115],
                [103.124492, 22.525712],
                [103.124105, 22.525086],
                [103.12396, 22.522762],
                [103.123671, 22.521779],
                [103.12285, 22.520305],
                [103.120678, 22.518338],
                [103.119616, 22.517757],
                [103.118361, 22.51731],
                [103.116671, 22.517131],
                [103.115657, 22.516908],
                [103.114692, 22.516639],
                [103.113774, 22.516148],
                [103.112857, 22.515835],
                [103.111843, 22.51588],
                [103.110589, 22.516014],
                [103.109043, 22.516416],
                [103.107547, 22.516461],
                [103.106195, 22.516416],
                [103.105519, 22.516282],
                [103.104361, 22.516505],
                [103.103443, 22.51655],
                [103.102188, 22.516193],
                [103.10074, 22.515701],
                [103.09934, 22.515388],
                [103.097602, 22.514763],
                [103.095912, 22.513914],
                [103.094175, 22.513109],
                [103.092148, 22.51226],
                [103.090699, 22.51159],
                [103.089395, 22.511099],
                [103.088478, 22.510517],
                [103.087706, 22.509846],
                [103.087319, 22.50931],
                [103.087127, 22.508059],
                [103.086547, 22.506808],
                [103.085437, 22.505154],
                [103.08452, 22.504574],
                [103.083072, 22.503098],
                [103.08283, 22.502204],
                [103.0823, 22.500953],
                [103.081285, 22.500015],
                [103.080434, 22.499679],
                [103.079514, 22.499456],
                [103.079611, 22.498115],
                [103.078837, 22.497623],
                [103.077917, 22.497221],
                [103.077433, 22.496864],
                [103.077288, 22.496104],
                [103.076949, 22.4953],
                [103.076078, 22.493915],
                [103.075643, 22.493334],
                [103.074965, 22.492663],
                [103.074578, 22.492172],
                [103.073949, 22.491636],
                [103.07332, 22.491457],
                [103.072304, 22.491412],
                [103.071771, 22.491144],
                [103.071723, 22.490429],
                [103.072207, 22.489893],
                [103.0724, 22.489133],
                [103.072013, 22.48824],
                [103.072062, 22.487659],
                [103.072304, 22.487123],
                [103.072981, 22.486408],
                [103.07361, 22.485648],
                [103.074578, 22.484844],
                [103.074772, 22.484397],
                [103.074868, 22.483905],
                [103.074868, 22.483503],
                [103.074627, 22.483056],
                [103.073659, 22.482207],
                [103.073078, 22.481626],
                [103.072933, 22.480911],
                [103.072691, 22.480375],
                [103.072739, 22.479884],
                [103.072352, 22.479303],
                [103.071094, 22.478052],
                [103.070949, 22.477471],
                [103.071336, 22.476845],
                [103.071384, 22.476398],
                [103.070997, 22.475773],
                [103.070997, 22.475505],
                [103.071191, 22.475281],
                [103.072691, 22.474164],
                [103.074385, 22.473181],
                [103.075062, 22.472511],
                [103.075933, 22.471796],
                [103.076998, 22.471081],
                [103.078208, 22.470366],
                [103.0797287, 22.4692491],
                [103.07995, 22.467104],
                [103.079756, 22.464021],
                [103.079805, 22.463752],
                [103.080434, 22.463037],
                [103.081208, 22.461786],
                [103.081401, 22.46125],
                [103.081401, 22.460714],
                [103.081111, 22.459641],
                [103.081208, 22.459418],
                [103.080772, 22.458077],
                [103.080627, 22.457273],
                [103.080482, 22.455799],
                [103.080434, 22.45495],
                [103.080579, 22.454011],
                [103.080676, 22.452045],
                [103.080676, 22.451553],
                [103.080579, 22.451017],
                [103.080143, 22.450704],
                [103.0799903, 22.4506235],
                [103.079466, 22.450347],
                [103.078595, 22.449543],
                [103.077046, 22.448872],
                [103.075594, 22.448426],
                [103.075304, 22.447979],
                [103.074191, 22.447487],
                [103.073223, 22.447442],
                [103.07182, 22.44713],
                [103.071433, 22.446906],
                [103.070707, 22.446862],
                [103.069739, 22.446638],
                [103.068336, 22.446728],
                [103.0651087, 22.4473403],
                [103.064174, 22.447711],
                [103.062093, 22.448783],
                [103.061706, 22.449185],
                [103.059141, 22.450615],
                [103.057931, 22.451107],
                [103.056866, 22.451062],
                [103.056383, 22.45057],
                [103.055511, 22.448828],
                [103.055221, 22.448113],
                [103.055076, 22.447442],
                [103.05435, 22.446549],
                [103.054205, 22.446147],
                [103.053769, 22.445834],
                [103.051447, 22.446013],
                [103.050866, 22.446013],
                [103.04985, 22.445298],
                [103.048979, 22.444851],
                [103.04714, 22.44427],
                [103.046607, 22.444359],
                [103.045252, 22.444806],
                [103.044333, 22.445342],
                [103.044042, 22.4457],
                [103.043655, 22.445968],
                [103.043075, 22.445879],
                [103.042058, 22.445342],
                [103.041574, 22.445164],
                [103.040945, 22.445074],
                [103.039639, 22.445342],
                [103.038187, 22.445834],
                [103.034945, 22.445253],
                [103.034364, 22.444717],
                [103.033541, 22.444136],
                [103.032332, 22.44284],
                [103.031944, 22.442617],
                [103.031412, 22.442706],
                [103.030783, 22.442929],
                [103.028025, 22.444359],
                [103.027154, 22.444717],
                [103.025992, 22.44494],
                [103.024153, 22.445834],
                [103.023572, 22.446057],
                [103.023331, 22.44637],
                [103.023282, 22.446772],
                [103.023089, 22.447577],
                [103.022798, 22.447979],
                [103.021395, 22.448694],
                [103.020621, 22.449409],
                [103.019604, 22.450213],
                [103.01883, 22.450526],
                [103.016314, 22.451017],
                [103.013652, 22.451956],
                [103.012926, 22.452447],
                [103.012345, 22.453028],
                [103.011281, 22.454771],
                [103.010506, 22.455218],
                [103.008861, 22.455843],
                [103.008522, 22.456245],
                [103.008377, 22.456782],
                [103.008087, 22.457363],
                [103.0077, 22.457809],
                [103.007313, 22.457943],
                [103.006732, 22.457943],
                [103.004651, 22.457497],
                [103.003586, 22.457631],
                [103.00228, 22.458212],
                [103.000586, 22.459731],
                [102.999957, 22.460178],
                [102.998409, 22.460624],
                [102.99628, 22.461607],
                [102.994393, 22.46192],
                [102.992796, 22.463037],
                [102.991586, 22.46402],
                [102.991489, 22.465539],
                [102.992263, 22.467237],
                [102.992747, 22.469203],
                [102.992651, 22.470365],
                [102.991731, 22.472376],
                [102.99086, 22.473091],
                [102.989166, 22.473046],
                [102.988779, 22.47318],
                [102.988828, 22.474118],
                [102.989021, 22.475593],
                [102.988779, 22.477738],
                [102.98844, 22.478319],
                [102.987715, 22.478587],
                [102.986747, 22.478319],
                [102.986069, 22.478542],
                [102.984859, 22.479123],
                [102.983892, 22.479257],
                [102.983117, 22.478944],
                [102.982101, 22.478274],
                [102.981375, 22.478006],
                [102.980407, 22.478006],
                [102.979149, 22.478319],
                [102.978326, 22.478587],
                [102.977117, 22.478676],
                [102.976197, 22.478006],
                [102.975616, 22.477783],
                [102.975326, 22.477604],
                [102.974939, 22.477827],
                [102.974552, 22.478408],
                [102.974116, 22.478676],
                [102.971551, 22.478364],
                [102.968067, 22.477827],
                [102.967148, 22.477783],
                [102.966422, 22.47738],
                [102.965502, 22.476978],
                [102.965115, 22.476755],
                [102.964728, 22.476844],
                [102.963228, 22.478319],
                [102.962938, 22.479168],
                [102.96255, 22.479972],
                [102.962163, 22.480285],
                [102.961196, 22.480732],
                [102.959647, 22.482564],
                [102.958873, 22.483279],
                [102.958389, 22.48386],
                [102.957276, 22.484217],
                [102.955437, 22.483904],
                [102.953985, 22.483368],
                [102.953356, 22.483324],
                [102.952388, 22.482877],
                [102.952098, 22.482609],
                [102.952049, 22.481536],
                [102.951953, 22.481179],
                [102.951565, 22.481045],
                [102.950985, 22.481179],
                [102.950404, 22.481402],
                [102.949243, 22.48167],
                [102.948371, 22.481938],
                [102.946823, 22.482162],
                [102.946242, 22.482653],
                [102.945903, 22.482832],
                [102.944548, 22.483189],
                [102.943532, 22.483815],
                [102.942468, 22.484083],
                [102.941113, 22.48386],
                [102.940387, 22.483815],
                [102.939516, 22.484128],
                [102.938645, 22.484173],
                [102.937386, 22.484575],
                [102.936709, 22.484351],
                [102.936225, 22.484083],
                [102.935596, 22.483592],
                [102.93516, 22.483458],
                [102.93458, 22.483413],
                [102.933563, 22.48243],
                [102.933079, 22.48234],
                [102.931531, 22.482564],
                [102.930902, 22.482743],
                [102.9301794, 22.4831075],
                [102.928773, 22.484396],
                [102.928337, 22.484887],
                [102.927998, 22.485334],
                [102.927611, 22.486317],
                [102.926788, 22.487166],
                [102.926159, 22.487479],
                [102.92524, 22.488954],
                [102.925046, 22.490652],
                [102.925095, 22.491814],
                [102.924901, 22.492886],
                [102.925046, 22.493914],
                [102.924998, 22.494539],
                [102.924708, 22.494852],
                [102.923885, 22.49512],
                [102.923691, 22.496014],
                [102.923546, 22.497086],
                [102.923546, 22.498606],
                [102.923691, 22.499455],
                [102.923643, 22.500036],
                [102.923673, 22.507244],
                [102.922617, 22.508413],
                [102.91607, 22.509583],
                [102.913536, 22.510362],
                [102.912058, 22.511727],
                [102.91248, 22.517574],
                [102.909735, 22.524592],
                [102.907623, 22.526346],
                [102.904456, 22.5281],
                [102.901077, 22.530049],
                [102.899388, 22.530634],
                [102.896853, 22.531024],
                [102.893897, 22.534922],
                [102.893263, 22.536092],
                [102.893052, 22.538821],
                [102.893263, 22.54155],
                [102.894319, 22.542719],
                [102.897909, 22.542914],
                [102.897698, 22.545838],
                [102.895164, 22.549541],
                [102.892208, 22.550126],
                [102.891996, 22.552465],
                [102.888406, 22.55266],
                [102.886928, 22.554414],
                [102.884394, 22.556169],
                [102.882916, 22.558508],
                [102.880171, 22.560652],
                [102.881015, 22.561821],
                [102.883338, 22.563381],
                [102.884605, 22.566305],
                [102.884605, 22.568254],
                [102.883972, 22.569423],
                [102.882071, 22.570593],
                [102.880382, 22.571762],
                [102.877636, 22.573322],
                [102.875947, 22.574881],
                [102.874891, 22.576246],
                [102.878481, 22.578],
                [102.882282, 22.579949],
                [102.883972, 22.584822],
                [102.883338, 22.588915],
                [102.881015, 22.592229],
                [102.878059, 22.594568],
                [102.876792, 22.596907],
                [102.874891, 22.598661],
                [102.870457, 22.598661],
                [102.869612, 22.599831],
                [102.868345, 22.601195],
                [102.866444, 22.602755],
                [102.866233, 22.604704],
                [102.866233, 22.606848],
                [102.865388, 22.609187],
                [102.863277, 22.611136],
                [102.859053, 22.611916],
                [102.850395, 22.611526],
                [102.847861, 22.612696],
                [102.846594, 22.614645],
                [102.845749, 22.619518],
                [102.84237, 22.620688],
                [102.837302, 22.621857],
                [102.83329, 22.622637],
                [102.830544, 22.623416],
                [102.829066, 22.625171],
                [102.827799, 22.62595],
                [102.825265, 22.62673],
                [102.823153, 22.626925],
                [102.821253, 22.62595],
                [102.819141, 22.622637],
                [102.817874, 22.622247],
                [102.81534, 22.623416],
                [102.813439, 22.624196],
                [102.809849, 22.624976],
                [102.807104, 22.624586],
                [102.803514, 22.623027],
                [102.801613, 22.622637],
                [102.79929, 22.623222],
                [102.796334, 22.624781],
                [102.794433, 22.625366],
                [102.79211, 22.625561],
                [102.7876761, 22.6263556],
                [102.788098, 22.628289],
                [102.785775, 22.631993],
                [102.78303, 22.634917],
                [102.783452, 22.636866],
                [102.785986, 22.637646],
                [102.787253, 22.639985],
                [102.788521, 22.641934],
                [102.787465, 22.643883],
                [102.784297, 22.644663],
                [102.780918, 22.644663],
                [102.777751, 22.645053],
                [102.775639, 22.647002],
                [102.773949, 22.647587],
                [102.770993, 22.647002],
                [102.768459, 22.646807],
                [102.765714, 22.647197],
                [102.763602, 22.649536],
                [102.761068, 22.652655],
                [102.759801, 22.654799],
                [102.757478, 22.658112],
                [102.756844, 22.660646],
                [102.754521, 22.665519],
                [102.752198, 22.668248],
                [102.750087, 22.670392],
                [102.749031, 22.670587],
                [102.747552, 22.669223],
                [102.746082, 22.667559],
                [102.744967, 22.666803],
                [102.743628, 22.666941],
                [102.742959, 22.667147],
                [102.740802, 22.666872],
                [102.738645, 22.666391],
                [102.736488, 22.665567],
                [102.735372, 22.666323],
                [102.734182, 22.666735],
                [102.733513, 22.667147],
                [102.732621, 22.668246],
                [102.732026, 22.668383],
                [102.730836, 22.669619],
                [102.730241, 22.669894],
                [102.729497, 22.670169],
                [102.728753, 22.670581],
                [102.728456, 22.670993],
                [102.728232, 22.671748],
                [102.727266, 22.672298],
                [102.726671, 22.672984],
                [102.726373, 22.67319],
                [102.726299, 22.674633],
                [102.726447, 22.676143],
                [102.726076, 22.676693],
                [102.725852, 22.678341],
                [102.725183, 22.678684],
                [102.724514, 22.678684],
                [102.72377, 22.678066],
                [102.723472, 22.67683],
                [102.723324, 22.676624],
                [102.722729, 22.676762],
                [102.721985, 22.677174],
                [102.718787, 22.677242],
                [102.717894, 22.677448],
                [102.716704, 22.678547],
                [102.715812, 22.678684],
                [102.714845, 22.678684],
                [102.713432, 22.679028],
                [102.712242, 22.679646],
                [102.710754, 22.680195],
                [102.710382, 22.680539],
                [102.710382, 22.681363],
                [102.711052, 22.683767],
                [102.710754, 22.685415],
                [102.709564, 22.6872],
                [102.709639, 22.689192],
                [102.70949, 22.690359],
                [102.708151, 22.692763],
                [102.707333, 22.693519],
                [102.706961, 22.693931],
                [102.705548, 22.694205],
                [102.704507, 22.694205],
                [102.703986, 22.694686],
                [102.703614, 22.695991],
                [102.703094, 22.696884],
                [102.701978, 22.696952],
                [102.701011, 22.696609],
                [102.700267, 22.696128],
                [102.699895, 22.69606],
                [102.698705, 22.697433],
                [102.69811, 22.697776],
                [102.69692, 22.697776],
                [102.69573, 22.698326],
                [102.694392, 22.699837],
                [102.693945, 22.700043],
                [102.693053, 22.700043],
                [102.691565, 22.701485],
                [102.690599, 22.702172],
                [102.690227, 22.70224],
                [102.689483, 22.701828],
                [102.689037, 22.702927],
                [102.688516, 22.703133],
                [102.68807, 22.702927],
                [102.687475, 22.70224],
                [102.6874, 22.701348],
                [102.686062, 22.70018],
                [102.682715, 22.698326],
                [102.680037, 22.697845],
                [102.676393, 22.697364],
                [102.672823, 22.696952],
                [102.67141, 22.696884],
                [102.670592, 22.69654],
                [102.668063, 22.694823],
                [102.665832, 22.694274],
                [102.664567, 22.693862],
                [102.66241, 22.693038],
                [102.661815, 22.69242],
                [102.660254, 22.69187],
                [102.658022, 22.689879],
                [102.657353, 22.689467],
                [102.656758, 22.689192],
                [102.656163, 22.689398],
                [102.654601, 22.692969],
                [102.653039, 22.695098],
                [102.651552, 22.696609],
                [102.650064, 22.69757],
                [102.648577, 22.698944],
                [102.647312, 22.699768],
                [102.64642, 22.699974],
                [102.64575, 22.70018],
                [102.64523, 22.700043],
                [102.644189, 22.699905],
                [102.643891, 22.700043],
                [102.642924, 22.701004],
                [102.641585, 22.70224],
                [102.640395, 22.702859],
                [102.639875, 22.704095],
                [102.639131, 22.70485],
                [102.638015, 22.705812],
                [102.63623, 22.706704],
                [102.63504, 22.70746],
                [102.633627, 22.708559],
                [102.631694, 22.709451],
                [102.62857, 22.712198],
                [102.627975, 22.712679],
                [102.625892, 22.714121],
                [102.625149, 22.714808],
                [102.624405, 22.715701],
                [102.623512, 22.717624],
                [102.62262, 22.71941],
                [102.621058, 22.720783],
                [102.620686, 22.721538],
                [102.619422, 22.721607],
                [102.618009, 22.722019],
                [102.617488, 22.722569],
                [102.616744, 22.723187],
                [102.616298, 22.723805],
                [102.615852, 22.724698],
                [102.614439, 22.726002],
                [102.613844, 22.726621],
                [102.613323, 22.726964],
                [102.613249, 22.727239],
                [102.613249, 22.727994],
                [102.6131, 22.728406],
                [102.611761, 22.729093],
                [102.611092, 22.728749],
                [102.610199, 22.728543],
                [102.609679, 22.728818],
                [102.609381, 22.729162],
                [102.608786, 22.730947],
                [102.60767, 22.731909],
                [102.607075, 22.732046],
                [102.60596, 22.732115],
                [102.604844, 22.731703],
                [102.604398, 22.731497],
                [102.603134, 22.731634],
                [102.602464, 22.731977],
                [102.600828, 22.731359],
                [102.600084, 22.731153],
                [102.599787, 22.731016],
                [102.599415, 22.729574],
                [102.599415, 22.729299],
                [102.59882, 22.728681],
                [102.59644, 22.727994],
                [102.595845, 22.727307],
                [102.595473, 22.726483],
                [102.595175, 22.725522],
                [102.594878, 22.724904],
                [102.594357, 22.72456],
                [102.592349, 22.724423],
                [102.591457, 22.724286],
                [102.590639, 22.723667],
                [102.589969, 22.723393],
                [102.58982, 22.722775],
                [102.589449, 22.722294],
                [102.588779, 22.721882],
                [102.587961, 22.721538],
                [102.588035, 22.721058],
                [102.588259, 22.720508],
                [102.587589, 22.719272],
                [102.58744, 22.718517],
                [102.587366, 22.717555],
                [102.587143, 22.716937],
                [102.586994, 22.716319],
                [102.587069, 22.715495],
                [102.58692, 22.71158],
                [102.586548, 22.709314],
                [102.586771, 22.708902],
                [102.58692, 22.708353],
                [102.586548, 22.707735],
                [102.585879, 22.706773],
                [102.585135, 22.705468],
                [102.58454, 22.705468],
                [102.583052, 22.705262],
                [102.581639, 22.704987],
                [102.580821, 22.704987],
                [102.579854, 22.704919],
                [102.57911, 22.704987],
                [102.578367, 22.705262],
                [102.577623, 22.705468],
                [102.576433, 22.705674],
                [102.575317, 22.705262],
                [102.574722, 22.705125],
                [102.573904, 22.705125],
                [102.572268, 22.70485],
                [102.571673, 22.704575],
                [102.570037, 22.703408],
                [102.569665, 22.703614],
                [102.569293, 22.704507],
                [102.568995, 22.70588],
                [102.569144, 22.70691],
                [102.569516, 22.708284],
                [102.569962, 22.709383],
                [102.570037, 22.709932],
                [102.569962, 22.710962],
                [102.569367, 22.711992],
                [102.567805, 22.713229],
                [102.567285, 22.713572],
                [102.566913, 22.713984],
                [102.566839, 22.714808],
                [102.567136, 22.7168],
                [102.566987, 22.718036],
                [102.566764, 22.718585],
                [102.566095, 22.719547],
                [102.566095, 22.720028],
                [102.566467, 22.721813],
                [102.566244, 22.722569],
                [102.5655, 22.723118],
                [102.564979, 22.723667],
                [102.564682, 22.724629],
                [102.564384, 22.725041],
                [102.563566, 22.725316],
                [102.562897, 22.725796],
                [102.56245, 22.726277],
                [102.562153, 22.726827],
                [102.561409, 22.727582],
                [102.561037, 22.728063],
                [102.56074, 22.728818],
                [102.560368, 22.729093],
                [102.559847, 22.729162],
                [102.558955, 22.729436],
                [102.558211, 22.729848],
                [102.557839, 22.73026],
                [102.556947, 22.729986],
                [102.556352, 22.729986],
                [102.555757, 22.730192],
                [102.555162, 22.730741],
                [102.554715, 22.731703],
                [102.55412, 22.732389],
                [102.553302, 22.732733],
                [102.552707, 22.733076],
                [102.552335, 22.73445],
                [102.552187, 22.736029],
                [102.552038, 22.73651],
                [102.551294, 22.737059],
                [102.550625, 22.737746],
                [102.550179, 22.738502],
                [102.55003, 22.738982],
                [102.549435, 22.74015],
                [102.549286, 22.740768],
                [102.549732, 22.741798],
                [102.549658, 22.742416],
                [102.549658, 22.743034],
                [102.549881, 22.743652],
                [102.55003, 22.744614],
                [102.550253, 22.745163],
                [102.550774, 22.745575],
                [102.550402, 22.74585],
                [102.549286, 22.746125],
                [102.547799, 22.746605],
                [102.547055, 22.746399],
                [102.546385, 22.746468],
                [102.54579, 22.746811],
                [102.545493, 22.747498],
                [102.545047, 22.748254],
                [102.544377, 22.748528],
                [102.543559, 22.748597],
                [102.542964, 22.749009],
                [102.542369, 22.749215],
                [102.541923, 22.749558],
                [102.5415, 22.751846],
                [102.540656, 22.754185],
                [102.537911, 22.756914],
                [102.536221, 22.759058],
                [102.532842, 22.762176],
                [102.529464, 22.763151],
                [102.526311, 22.7631577],
                [102.5240321, 22.7641618],
                [102.5220194, 22.7659804],
                [102.5207858, 22.7666431],
                [102.5190992, 22.7672275],
                [102.5171152, 22.7686014],
                [102.5162875, 22.7694485],
                [102.514224, 22.7716526],
                [102.513429, 22.773137],
                [102.5119632, 22.7755146],
                [102.5103253, 22.7762557],
                [102.5088643, 22.7775455],
                [102.507924, 22.778355],
                [102.505601, 22.77816],
                [102.5036618, 22.7777076],
                [102.5027763, 22.7787358],
                [102.501095, 22.7795042],
                [102.4985135, 22.779918],
                [102.4981318, 22.779645],
                [102.4977466, 22.7795122],
                [102.4974858, 22.7792532],
                [102.4973255, 22.77924],
                [102.4971982, 22.7792295],
                [102.4968714, 22.7790858],
                [102.496265, 22.7796222],
                [102.4955643, 22.7798899],
                [102.4924114, 22.7797949],
                [102.4903437, 22.7794436],
                [102.488492, 22.7784437],
                [102.4861986, 22.77806],
                [102.482372, 22.77816],
                [102.4812956, 22.7781239],
                [102.4806889, 22.7771549],
                [102.4803059, 22.7758659],
                [102.4801451, 22.7741121],
                [102.4801954, 22.7720669],
                [102.4799232, 22.771334],
                [102.4791717, 22.7705278],
                [102.4780016, 22.7698379],
                [102.47118, 22.770753],
                [102.4699287, 22.770753],
                [102.4674912, 22.7698538],
                [102.465372, 22.7688572],
                [102.4600158, 22.7662585],
                [102.4593032, 22.7654597],
                [102.4574644, 22.7623668],
                [102.4567047, 22.7603897],
                [102.456867, 22.7587878],
                [102.4557709, 22.7579658],
                [102.4548699, 22.7573946],
                [102.4541072, 22.7559008],
                [102.4530512, 22.7540328],
                [102.4517842, 22.752748],
                [102.4512267, 22.7504952],
                [102.4519205, 22.7492214],
                [102.4526311, 22.7483255],
                [102.4529993, 22.7472325],
                [102.452815, 22.7463792],
                [102.4521949, 22.7452003],
                [102.4513987, 22.7446876],
                [102.4505468, 22.7440499],
                [102.4502472, 22.7426078],
                [102.4513115, 22.7413703],
                [102.4522619, 22.7402353],
                [102.4527813, 22.739463],
                [102.45356, 22.7386907],
                [102.4549155, 22.7373863],
                [102.4551553, 22.7365964],
                [102.4558098, 22.7346561],
                [102.4563223, 22.7331665],
                [102.4564672, 22.7322516],
                [102.4541628, 22.7321645],
                [102.4528216, 22.7308347],
                [102.4519911, 22.7296839],
                [102.4500905, 22.7291089],
                [102.449206, 22.7286031],
                [102.4482113, 22.7277257],
                [102.4469279, 22.7272785],
                [102.445947, 22.7267185],
                [102.445019, 22.7265011],
                [102.4435645, 22.72549],
                [102.4432185, 22.7235634],
                [102.441723, 22.7204121],
                [102.4416183, 22.7202069],
                [102.4413239, 22.7201432],
                [102.4407462, 22.719861],
                [102.4404107, 22.719077],
                [102.4420715, 22.716893],
                [102.4421144, 22.7157888],
                [102.4413347, 22.7151921],
                [102.4405225, 22.7143482],
                [102.4400588, 22.7139311],
                [102.4392946, 22.7135932],
                [102.4386794, 22.7124749],
                [102.4380415, 22.7107837],
                [102.4376216, 22.7097336],
                [102.4367704, 22.7084326],
                [102.4367047, 22.7075549],
                [102.4369005, 22.7062895],
                [102.4367128, 22.7049],
                [102.43604, 22.7033621],
                [102.435965, 22.702479],
                [102.4353987, 22.7010238],
                [102.4338561, 22.7001109],
                [102.4322198, 22.699571],
                [102.4315225, 22.6986754],
                [102.4296308, 22.6975852],
                [102.4288514, 22.6967778],
                [102.4277377, 22.6959928],
                [102.4259328, 22.6953504],
                [102.4250562, 22.6944722],
                [102.4251418, 22.6940742],
                [102.4247661, 22.6938625],
                [102.4242328, 22.6937474],
                [102.4239619, 22.6933393],
                [102.4236256, 22.6924402],
                [102.4232757, 22.6919439],
                [102.422255, 22.6917328],
                [102.421436, 22.691663],
                [102.4208343, 22.6917755],
                [102.4196694, 22.6923284],
                [102.4184688, 22.6918477],
                [102.4171587, 22.6908761],
                [102.4168137, 22.6908866],
                [102.4163682, 22.6906103],
                [102.4158797, 22.6907125],
                [102.4146186, 22.6898837],
                [102.4137966, 22.6886202],
                [102.4137211, 22.6870762],
                [102.4128421, 22.6866683],
                [102.412578, 22.6860799],
                [102.4108974, 22.6854085],
                [102.4101937, 22.6851896],
                [102.4094732, 22.6849476],
                [102.4085073, 22.6844954],
                [102.407773, 22.684366],
                [102.407008, 22.684589],
                [102.406041, 22.6853964],
                [102.405297, 22.685757],
                [102.4046206, 22.6859948],
                [102.4039847, 22.686068],
                [102.4033168, 22.6860733],
                [102.4027056, 22.6862583],
                [102.40143, 22.686924],
                [102.401058, 22.686993],
                [102.4003439, 22.6871811],
                [102.3992372, 22.6877351],
                [102.398603, 22.688298],
                [102.398083, 22.688778],
                [102.396372, 22.689191],
                [102.3952556, 22.6897769],
                [102.3938285, 22.6897268],
                [102.3907749, 22.6889913],
                [102.3890452, 22.6880295],
                [102.3874629, 22.687016],
                [102.3859736, 22.6861747],
                [102.3846533, 22.6851954],
                [102.3841895, 22.683838],
                [102.3834077, 22.6822556],
                [102.3829049, 22.6803679],
                [102.383201, 22.6787042],
                [102.3834052, 22.6773977],
                [102.3837952, 22.6762806],
                [102.3844284, 22.6755091],
                [102.3843913, 22.6743413],
                [102.3851564, 22.6731263],
                [102.385796, 22.6725104],
                [102.3860699, 22.6713404],
                [102.3865725, 22.6689652],
                [102.3871077, 22.6671745],
                [102.3880596, 22.6663131],
                [102.3888661, 22.6650681],
                [102.3895457, 22.6632821],
                [102.3909181, 22.6625325],
                [102.392058, 22.661582],
                [102.3928425, 22.6608142],
                [102.3939681, 22.659576],
                [102.3956304, 22.6580827],
                [102.3969923, 22.6570251],
                [102.3976396, 22.655697],
                [102.3982623, 22.654187],
                [102.3989001, 22.6520091],
                [102.3991582, 22.65044],
                [102.4000315, 22.6478245],
                [102.4008829, 22.6457329],
                [102.4014791, 22.6452519],
                [102.4035746, 22.643459],
                [102.4051874, 22.6429777],
                [102.4065567, 22.642832],
                [102.4073314, 22.6426327],
                [102.4078299, 22.6425876],
                [102.4091964, 22.6423534],
                [102.4100278, 22.6426694],
                [102.4119746, 22.6434783],
                [102.4133128, 22.6433047],
                [102.4146766, 22.6423256],
                [102.4157085, 22.6421031],
                [102.4162932, 22.6423318],
                [102.4168961, 22.6423318],
                [102.4171786, 22.6418729],
                [102.4175779, 22.640531],
                [102.4175485, 22.6391977],
                [102.4181114, 22.6388719],
                [102.4185782, 22.6383932],
                [102.4190384, 22.6377174],
                [102.4190597, 22.6369202],
                [102.4184498, 22.6358817],
                [102.4171504, 22.6353528],
                [102.4162612, 22.6347774],
                [102.4156369, 22.6347294],
                [102.4149475, 22.6346171],
                [102.4140328, 22.6346266],
                [102.4111481, 22.6352482],
                [102.4102508, 22.6351101],
                [102.4090191, 22.6351193],
                [102.407848, 22.6344415],
                [102.4066088, 22.6338649],
                [102.4056044, 22.6338081],
                [102.4042732, 22.6336981],
                [102.4035795, 22.6333513],
                [102.4029842, 22.6326462],
                [102.4023669, 22.6320109],
                [102.4002355, 22.6299045],
                [102.3991571, 22.6283101],
                [102.3985581, 22.6266327],
                [102.3977067, 22.6259604],
                [102.396481, 22.6247549],
                [102.3954341, 22.6236873],
                [102.395217, 22.623172],
                [102.3946443, 22.622874],
                [102.393191, 22.621635],
                [102.3919241, 22.6210689],
                [102.3910652, 22.6202213],
                [102.3897444, 22.6181272],
                [102.3884848, 22.6169461],
                [102.3882669, 22.6159862],
                [102.3876248, 22.6144395],
                [102.387544, 22.6114921],
                [102.3869709, 22.6106135],
                [102.386319, 22.6091938],
                [102.3848787, 22.6082012],
                [102.3840703, 22.6072274],
                [102.3837992, 22.6062821],
                [102.3828921, 22.6049554],
                [102.382252, 22.6042406],
                [102.3813328, 22.6038116],
                [102.3802981, 22.6027729],
                [102.3785301, 22.6017064],
                [102.3779926, 22.6009667],
                [102.3777919, 22.6003828],
                [102.3764877, 22.5983387],
                [102.3761728, 22.5977949],
                [102.3756912, 22.5972083],
                [102.375027, 22.5966044],
                [102.3745102, 22.5959308],
                [102.3729003, 22.5947603],
                [102.3720805, 22.5934988],
                [102.3707224, 22.592781],
                [102.3697589, 22.5927336],
                [102.3684278, 22.5925351],
                [102.3677422, 22.5928144],
                [102.3670561, 22.5927162],
                [102.3651676, 22.5922734],
                [102.363683, 22.5914584],
                [102.3628622, 22.5911086],
                [102.3621781, 22.591306],
                [102.3609733, 22.5918668],
                [102.3605094, 22.5918411],
                [102.3596, 22.5914543],
                [102.3584837, 22.5916974],
                [102.3567026, 22.5929795],
                [102.3560614, 22.5930739],
                [102.3556301, 22.5928301],
                [102.3555726, 22.5924675],
                [102.3551223, 22.5918628],
                [102.3548349, 22.5912563],
                [102.3541946, 22.5907777],
                [102.3537418, 22.5901181],
                [102.3531436, 22.5896433],
                [102.3516659, 22.5892235],
                [102.3504745, 22.589389],
                [102.3499983, 22.5899146],
                [102.3495072, 22.5902024],
                [102.3479186, 22.5902024],
                [102.347402, 22.5898532],
                [102.3472758, 22.5894228],
                [102.3470543, 22.5890882],
                [102.3468952, 22.5885189],
                [102.3462819, 22.5869964],
                [102.3460632, 22.5857438],
                [102.3457597, 22.5831433],
                [102.3460512, 22.58258],
                [102.3476945, 22.5806572],
                [102.3491277, 22.5794081],
                [102.3487631, 22.5784325],
                [102.3487127, 22.5780472],
                [102.3485378, 22.5776868],
                [102.3491573, 22.5763605],
                [102.3494813, 22.5762306],
                [102.3500071, 22.5762905],
                [102.3508348, 22.5758458],
                [102.3513807, 22.5750586],
                [102.3513666, 22.5743514],
                [102.3527096, 22.5731176],
                [102.353493, 22.5729903],
                [102.3549567, 22.572357],
                [102.354931, 22.5691651],
                [102.3549567, 22.5676526],
                [102.3551633, 22.5666553],
                [102.3536598, 22.5658825],
                [102.3507752, 22.5653203],
                [102.3483283, 22.5635797],
                [102.3435713, 22.562407],
                [102.3431279, 22.56183],
                [102.3409107, 22.5615322],
                [102.3380082, 22.5602851],
                [102.3367585, 22.5592054],
                [102.3321426, 22.5582003],
                [102.3290385, 22.5583492],
                [102.3249266, 22.5579955],
                [102.3235157, 22.5582933],
                [102.3209214, 22.5574576],
                [102.3208588, 22.557031],
                [102.320933, 22.5567873],
                [102.3215076, 22.5565684],
                [102.3219789, 22.5555763],
                [102.322028, 22.5550711],
                [102.3214101, 22.555184],
                [102.3208425, 22.5551001],
                [102.3205299, 22.5547249],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [106.0953797, 21.8198489],
                [106.0954651, 21.81932],
                [106.0955733, 21.8190171],
                [106.0959868, 21.8182029],
                [106.0979948, 21.8147556],
                [106.0982239, 21.8142226],
                [106.0984931, 21.8131718],
                [106.0987017, 21.8127136],
                [106.0991046, 21.8123092],
                [106.1006012, 21.8112938],
                [106.1009941, 21.8109579],
                [106.1017717, 21.8101752],
                [106.1030298, 21.8087403],
                [106.1048378, 21.8065672],
                [106.1054583, 21.8057061],
                [106.106412, 21.8040822],
                [106.1067311, 21.803779],
                [106.1072673, 21.8033866],
                [106.1074733, 21.8031224],
                [106.1074993, 21.8029018],
                [106.1074284, 21.8026251],
                [106.1069346, 21.801715],
                [106.1066882, 21.8011],
                [106.1065231, 21.800352],
                [106.1064555, 21.7997225],
                [106.1064133, 21.7985474],
                [106.1064426, 21.797617],
                [106.106869, 21.7944505],
                [106.1068177, 21.7938328],
                [106.1064214, 21.7923447],
                [106.1063316, 21.7915936],
                [106.1063611, 21.7907185],
                [106.1064611, 21.7902855],
                [106.1066365, 21.7898493],
                [106.1068895, 21.7894354],
                [106.1072054, 21.7890733],
                [106.1080413, 21.7884415],
                [106.1086313, 21.7881094],
                [106.1089908, 21.7879661],
                [106.1101259, 21.7876518],
                [106.1105933, 21.7874675],
                [106.1108809, 21.7872361],
                [106.1111463, 21.7869213],
                [106.1113493, 21.7865478],
                [106.1114167, 21.7863322],
                [106.1116619, 21.7848411],
                [106.1117222, 21.7838614],
                [106.1116416, 21.7824392],
                [106.1116834, 21.7819728],
                [106.1117399, 21.7817633],
                [106.1118668, 21.7815939],
                [106.1122821, 21.7814211],
                [106.1127814, 21.7814132],
                [106.1131482, 21.7814817],
                [106.1151963, 21.7821701],
                [106.1159453, 21.7823638],
                [106.1171511, 21.7824694],
                [106.1176281, 21.7824261],
                [106.1179543, 21.7822971],
                [106.1187057, 21.7815375],
                [106.1189597, 21.7813997],
                [106.1193378, 21.7813242],
                [106.1195491, 21.7813857],
                [106.1197759, 21.7815531],
                [106.1211496, 21.782979],
                [106.1213294, 21.7830978],
                [106.1218203, 21.7832565],
                [106.1229949, 21.7834149],
                [106.1242528, 21.7834753],
                [106.1253167, 21.7833878],
                [106.1267953, 21.7831365],
                [106.1275725, 21.7831378],
                [106.1280379, 21.7832897],
                [106.1284686, 21.783563],
                [106.1291121, 21.784082],
                [106.1293558, 21.7842286],
                [106.1299517, 21.7844679],
                [106.1318532, 21.784977],
                [106.1336592, 21.7852312],
                [106.1350398, 21.7852953],
                [106.1359336, 21.7852298],
                [106.1366608, 21.7850625],
                [106.1372358, 21.7847957],
                [106.1374653, 21.7845637],
                [106.1375339, 21.784405],
                [106.1375956, 21.7839619],
                [106.1376437, 21.7828595],
                [106.1378293, 21.7821419],
                [106.138396, 21.7806292],
                [106.1391399, 21.778359],
                [106.1395596, 21.7775112],
                [106.1401119, 21.7767921],
                [106.1427084, 21.7742254],
                [106.1433891, 21.7734825],
                [106.1439077, 21.7728225],
                [106.1444014, 21.772071],
                [106.1446101, 21.7716622],
                [106.1447704, 21.7712397],
                [106.1452013, 21.7696944],
                [106.1453669, 21.7693923],
                [106.1455993, 21.7691082],
                [106.1463115, 21.7685522],
                [106.1477089, 21.7676677],
                [106.1479193, 21.767483],
                [106.1482396, 21.7670421],
                [106.1483546, 21.7667932],
                [106.1484803, 21.7663112],
                [106.1484917, 21.7646145],
                [106.1486676, 21.7622592],
                [106.1488647, 21.7616177],
                [106.1492012, 21.7611416],
                [106.1495316, 21.7608984],
                [106.1500024, 21.7607129],
                [106.1505301, 21.760624],
                [106.1511586, 21.7606],
                [106.1519355, 21.7606393],
                [106.1527334, 21.7607518],
                [106.1543525, 21.7612025],
                [106.1547452, 21.7612148],
                [106.1550964, 21.7611449],
                [106.1553618, 21.7610073],
                [106.1556014, 21.7608137],
                [106.1566052, 21.7597641],
                [106.1578554, 21.7582352],
                [106.1594069, 21.7560927],
                [106.1596919, 21.75579],
                [106.1599534, 21.7556116],
                [106.1604163, 21.7554414],
                [106.1609916, 21.7553414],
                [106.1635003, 21.755248],
                [106.1661275, 21.7549632],
                [106.1671631, 21.7547688],
                [106.1678186, 21.754502],
                [106.16811, 21.7542908],
                [106.1683207, 21.7540723],
                [106.1684573, 21.7538333],
                [106.1685383, 21.7535242],
                [106.1685494, 21.7525427],
                [106.1683205, 21.7504439],
                [106.168376, 21.7500606],
                [106.1685369, 21.7498078],
                [106.1688602, 21.7496592],
                [106.1693686, 21.7495981],
                [106.1721329, 21.7496559],
                [106.1727491, 21.7495367],
                [106.1732139, 21.7493456],
                [106.1736825, 21.7490126],
                [106.1765546, 21.7461432],
                [106.1769877, 21.7455652],
                [106.1775435, 21.7444482],
                [106.1779054, 21.7440245],
                [106.1782316, 21.743825],
                [106.1788687, 21.7436286],
                [106.1797944, 21.74351],
                [106.1814965, 21.7434061],
                [106.1817019, 21.7433519],
                [106.1818731, 21.7430945],
                [106.1821139, 21.7422893],
                [106.1822808, 21.7419675],
                [106.1825358, 21.7416989],
                [106.1828918, 21.7414819],
                [106.1836782, 21.7411981],
                [106.1850706, 21.7408773],
                [106.1866622, 21.7406218],
                [106.1880663, 21.7405073],
                [106.1891585, 21.7405635],
                [106.1899078, 21.7406805],
                [106.1938488, 21.7415409],
                [106.1941986, 21.7415842],
                [106.1943739, 21.7415547],
                [106.1944893, 21.741475],
                [106.1945681, 21.7413284],
                [106.1946222, 21.7409225],
                [106.1946549, 21.7394402],
                [106.1945519, 21.7383014],
                [106.1945771, 21.7380605],
                [106.1948191, 21.7376756],
                [106.1953853, 21.7370076],
                [106.1963373, 21.7359811],
                [106.1967688, 21.735613],
                [106.1973497, 21.735295],
                [106.198049, 21.7350096],
                [106.2010169, 21.7339452],
                [106.2026552, 21.7334541],
                [106.2035346, 21.7332707],
                [106.2043352, 21.7331591],
                [106.2071014, 21.7330811],
                [106.2075273, 21.732975],
                [106.2079524, 21.732795],
                [106.2085533, 21.7323874],
                [106.2089181, 21.7319928],
                [106.2105767, 21.7294021],
                [106.2111507, 21.7287447],
                [106.2115703, 21.7284424],
                [106.2123996, 21.7279978],
                [106.2130829, 21.7277161],
                [106.2138575, 21.7274765],
                [106.2151775, 21.7272083],
                [106.2168326, 21.7269827],
                [106.217738, 21.7269434],
                [106.2181836, 21.727047],
                [106.2185989, 21.7273067],
                [106.2191648, 21.7277542],
                [106.220482, 21.7289192],
                [106.2243774, 21.7328283],
                [106.2247185, 21.7330572],
                [106.2251321, 21.7331511],
                [106.2254532, 21.7330821],
                [106.2259276, 21.7328277],
                [106.2276245, 21.7316607],
                [106.2293126, 21.7306544],
                [106.2296846, 21.7302896],
                [106.2303747, 21.7290367],
                [106.2307065, 21.7285662],
                [106.2310974, 21.7281757],
                [106.2315679, 21.7278617],
                [106.2321355, 21.7276201],
                [106.2324649, 21.7275356],
                [106.2332374, 21.7274743],
                [106.234616, 21.7275254],
                [106.2347907, 21.7274902],
                [106.2350462, 21.7273401],
                [106.2352697, 21.7270882],
                [106.2354593, 21.7267453],
                [106.2359355, 21.7254755],
                [106.236863, 21.7226865],
                [106.2377255, 21.7197627],
                [106.2378894, 21.7190484],
                [106.2379808, 21.7183807],
                [106.2379808, 21.7177014],
                [106.2378366, 21.7164276],
                [106.2375828, 21.715341],
                [106.2373813, 21.7147327],
                [106.2365957, 21.7127716],
                [106.2358194, 21.7110303],
                [106.2352761, 21.7100988],
                [106.234963, 21.7097231],
                [106.2339037, 21.708695],
                [106.2336545, 21.7083237],
                [106.2335766, 21.7081177],
                [106.2335155, 21.7076264],
                [106.2335006, 21.7046536],
                [106.2334049, 21.7039273],
                [106.233169, 21.703373],
                [106.2322637, 21.7022711],
                [106.2320533, 21.7018502],
                [106.2320024, 21.701618],
                [106.2320225, 21.7010954],
                [106.2321861, 21.7004741],
                [106.232564, 21.6993868],
                [106.2326082, 21.6990578],
                [106.2325805, 21.6989194],
                [106.2324155, 21.6986527],
                [106.2320982, 21.6983546],
                [106.2316485, 21.6980343],
                [106.2306606, 21.6974437],
                [106.230344, 21.6971841],
                [106.2300962, 21.6968985],
                [106.2298963, 21.696538],
                [106.2298327, 21.6963155],
                [106.2297598, 21.6952577],
                [106.2297413, 21.6950035],
                [106.2295773, 21.69433],
                [106.2293621, 21.6939745],
                [106.2290759, 21.6937059],
                [106.2283915, 21.6934104],
                [106.2257119, 21.6927777],
                [106.225202, 21.6926196],
                [106.2248096, 21.692422],
                [106.2246491, 21.692295],
                [106.2243826, 21.6919578],
                [106.2242747, 21.691739],
                [106.224111, 21.6911802],
                [106.2240643, 21.6904427],
                [106.2241087, 21.6900033],
                [106.2243282, 21.6890282],
                [106.2248879, 21.6875301],
                [106.2261354, 21.685252],
                [106.226355, 21.6846167],
                [106.2265067, 21.6836687],
                [106.2265482, 21.6829055],
                [106.2265172, 21.6824945],
                [106.2263504, 21.6816392],
                [106.2260783, 21.6807822],
                [106.2257499, 21.6800191],
                [106.2254098, 21.6794435],
                [106.2248654, 21.6787786],
                [106.2234409, 21.6772913],
                [106.2224753, 21.6763617],
                [106.2211756, 21.6752752],
                [106.2195669, 21.6740304],
                [106.2191825, 21.6736638],
                [106.2190548, 21.6734717],
                [106.2188992, 21.6730591],
                [106.2187649, 21.6719494],
                [106.2187268, 21.6718387],
                [106.2185896, 21.6716655],
                [106.2184371, 21.6715771],
                [106.2160466, 21.6705562],
                [106.214541, 21.6697817],
                [106.2132759, 21.669046],
                [106.2114377, 21.6677767],
                [106.2106505, 21.6671479],
                [106.2100939, 21.6665712],
                [106.2092656, 21.665566],
                [106.2088591, 21.6651942],
                [106.2082142, 21.6648068],
                [106.2066517, 21.6640252],
                [106.2059234, 21.6635294],
                [106.2057778, 21.6633271],
                [106.2057366, 21.6630531],
                [106.2058869, 21.6626416],
                [106.2063934, 21.6618152],
                [106.2066233, 21.6613126],
                [106.2067293, 21.6606571],
                [106.2066293, 21.6599921],
                [106.2064018, 21.6594102],
                [106.2061894, 21.6590162],
                [106.2052146, 21.6574288],
                [106.2040247, 21.6553415],
                [106.2035712, 21.6547183],
                [106.2027898, 21.6538984],
                [106.202128, 21.6534144],
                [106.2010517, 21.6528083],
                [106.1994094, 21.6520549],
                [106.1985208, 21.6515984],
                [106.1942326, 21.6489146],
                [106.1935109, 21.6483511],
                [106.1926329, 21.6475133],
                [106.191954, 21.6467096],
                [106.1914803, 21.6459226],
                [106.1911718, 21.6449608],
                [106.1911551, 21.6443084],
                [106.1912018, 21.6437981],
                [106.1920448, 21.638116],
                [106.1921721, 21.6375726],
                [106.192381, 21.6370974],
                [106.1929024, 21.6364042],
                [106.1936088, 21.6358028],
                [106.1945975, 21.6352088],
                [106.1969246, 21.6340521],
                [106.1975106, 21.6337155],
                [106.1979033, 21.6333276],
                [106.1980613, 21.6329562],
                [106.1980889, 21.6327215],
                [106.1980555, 21.6324523],
                [106.1978937, 21.6320616],
                [106.1975023, 21.631451],
                [106.1960331, 21.6294465],
                [106.1955681, 21.6286785],
                [106.1951611, 21.6276276],
                [106.1950492, 21.6270201],
                [106.1950761, 21.6259139],
                [106.1952447, 21.6249907],
                [106.1955762, 21.6242426],
                [106.1960035, 21.6234714],
                [106.1973202, 21.6214444],
                [106.1974329, 21.6211908],
                [106.1975312, 21.6207914],
                [106.1975122, 21.6200933],
                [106.1973105, 21.6194628],
                [106.1967698, 21.6186719],
                [106.1959779, 21.6177811],
                [106.1948326, 21.6167486],
                [106.1944237, 21.6163239],
                [106.1940624, 21.6158157],
                [106.1932079, 21.6144156],
                [106.1926464, 21.6137132],
                [106.1921235, 21.6131589],
                [106.1914806, 21.6126186],
                [106.1904485, 21.6120001],
                [106.1898645, 21.6117908],
                [106.1886246, 21.611548],
                [106.1883257, 21.6113919],
                [106.188081, 21.6111472],
                [106.1878446, 21.6107366],
                [106.1870579, 21.6086659],
                [106.1867143, 21.6079373],
                [106.1855635, 21.6058528],
                [106.1853585, 21.6053638],
                [106.1851661, 21.6044912],
                [106.1850403, 21.6042639],
                [106.184857, 21.6040447],
                [106.1841267, 21.6034752],
                [106.1820327, 21.6022878],
                [106.1815516, 21.6021262],
                [106.1812859, 21.6020826],
                [106.1797085, 21.6019997],
                [106.17912, 21.6017994],
                [106.1782128, 21.6013222],
                [106.1775355, 21.6008375],
                [106.1772597, 21.6005552],
                [106.1770528, 21.6002668],
                [106.1768891, 21.5999311],
                [106.176687, 21.5990346],
                [106.1765187, 21.5985298],
                [106.1761843, 21.5980581],
                [106.1759284, 21.5978543],
                [106.1756067, 21.5976837],
                [106.1752158, 21.5975551],
                [106.174755, 21.5974744],
                [106.1726497, 21.5972708],
                [106.1717265, 21.5970677],
                [106.1710057, 21.5968232],
                [106.1702479, 21.5963903],
                [106.1697198, 21.5958978],
                [106.1693256, 21.5953333],
                [106.1691746, 21.5948388],
                [106.1691907, 21.5942757],
                [106.1692773, 21.5939795],
                [106.1702881, 21.5920151],
                [106.1704959, 21.5912683],
                [106.1705554, 21.590505],
                [106.1705683, 21.5892353],
                [106.1704216, 21.5874712],
                [106.1700958, 21.5855418],
                [106.1698901, 21.58494],
                [106.1692223, 21.5835819],
                [106.1689989, 21.5830149],
                [106.1685032, 21.5807886],
                [106.1682365, 21.5802221],
                [106.1674045, 21.578955],
                [106.1671457, 21.5784425],
                [106.166945, 21.5777754],
                [106.1668866, 21.5770192],
                [106.1670013, 21.576115],
                [106.1671338, 21.5757388],
                [106.1673315, 21.5753787],
                [106.1677625, 21.5748604],
                [106.1690501, 21.5737226],
                [106.1693083, 21.5733528],
                [106.1694058, 21.5729542],
                [106.1693225, 21.5720125],
                [106.1688349, 21.5692092],
                [106.1687125, 21.5680398],
                [106.1687855, 21.567254],
                [106.1691892, 21.5657313],
                [106.1692213, 21.5652373],
                [106.1690727, 21.5646814],
                [106.168742, 21.5638087],
                [106.1677495, 21.561707],
                [106.1675902, 21.5611186],
                [106.1675746, 21.5607921],
                [106.1676532, 21.5599369],
                [106.1677478, 21.5594298],
                [106.168046, 21.558356],
                [106.1685025, 21.5570352],
                [106.1686313, 21.5564943],
                [106.1686197, 21.555571],
                [106.1682604, 21.553739],
                [106.1682571, 21.5534276],
                [106.1683149, 21.5531315],
                [106.1684419, 21.5528313],
                [106.1686459, 21.5525082],
                [106.1694377, 21.5514528],
                [106.1696169, 21.5511268],
                [106.1697343, 21.5508263],
                [106.1699899, 21.5498243],
                [106.1700633, 21.5490982],
                [106.1699596, 21.5483558],
                [106.1694682, 21.5471507],
                [106.1689908, 21.5463212],
                [106.1677317, 21.5445651],
                [106.1673406, 21.5438897],
                [106.1671257, 21.5433111],
                [106.1670042, 21.5423671],
                [106.1670255, 21.5405269],
                [106.1671485, 21.539702],
                [106.1673109, 21.5391876],
                [106.1675947, 21.5386468],
                [106.16782, 21.5383229],
                [106.1695662, 21.5362159],
                [106.1698058, 21.535787],
                [106.1698459, 21.5354336],
                [106.1698055, 21.5348356],
                [106.1696865, 21.5341923],
                [106.1693915, 21.533233],
                [106.1687158, 21.5314317],
                [106.1683822, 21.5301456],
                [106.1681316, 21.5289036],
                [106.1679857, 21.5279658],
                [106.1677334, 21.5251314],
                [106.1674164, 21.5224417],
                [106.1673778, 21.5217266],
                [106.1674388, 21.5210183],
                [106.1676278, 21.5202787],
                [106.168153, 21.5188443],
                [106.1682541, 21.5182147],
                [106.1681788, 21.5171183],
                [106.1675866, 21.5139906],
                [106.1676094, 21.5135073],
                [106.1678814, 21.5130104],
                [106.1682895, 21.51259],
                [106.1689038, 21.5121726],
                [106.17364, 21.5096847],
                [106.1744494, 21.5093341],
                [106.1748186, 21.5092378],
                [106.1755495, 21.5091682],
                [106.1787748, 21.5094354],
                [106.1795665, 21.5093557],
                [106.1801135, 21.5092527],
                [106.1817112, 21.5088703],
                [106.1845659, 21.5080536],
                [106.1876335, 21.5070867],
                [106.1905051, 21.5060847],
                [106.1920769, 21.5056413],
                [106.1925218, 21.5054179],
                [106.1928396, 21.505134],
                [106.1935894, 21.503799],
                [106.1942899, 21.502884],
                [106.1947994, 21.5024314],
                [106.1954666, 21.5020512],
                [106.1962031, 21.5017627],
                [106.1972872, 21.5014301],
                [106.1985882, 21.5010917],
                [106.1998406, 21.5008225],
                [106.2017394, 21.5005476],
                [106.2047128, 21.5003042],
                [106.2053701, 21.5001714],
                [106.2058019, 21.4999888],
                [106.2063501, 21.4996777],
                [106.2081002, 21.4983975],
                [106.2102531, 21.4970908],
                [106.2131322, 21.4963747],
                [106.215579, 21.4959894],
                [106.2187186, 21.4960063],
                [106.2202272, 21.496313],
                [106.2214986, 21.4961448],
                [106.2223072, 21.4951756],
                [106.22378, 21.4937347],
                [106.2243734, 21.4936707],
                [106.2249708, 21.4941033],
                [106.2259124, 21.4950697],
                [106.2263813, 21.4953443],
                [106.2337075, 21.4950222],
                [106.237228, 21.4949367],
                [106.2377234, 21.4945655],
                [106.2383513, 21.4935677],
                [106.2402298, 21.4887172],
                [106.2414635, 21.4866224],
                [106.2419258, 21.4861025],
                [106.2434839, 21.4860016],
                [106.2450711, 21.4855725],
                [106.2455756, 21.4850326],
                [106.2456548, 21.4843764],
                [106.2453101, 21.4837633],
                [106.2446716, 21.48349],
                [106.2440533, 21.4831172],
                [106.2438596, 21.482781],
                [106.2444895, 21.4820413],
                [106.2450722, 21.4819675],
                [106.2455845, 21.4823411],
                [106.2459955, 21.4832914],
                [106.2468557, 21.4846754],
                [106.2477145, 21.4858807],
                [106.2488482, 21.4870044],
                [106.2494885, 21.4874762],
                [106.2499552, 21.4874727],
                [106.2506953, 21.487209],
                [106.2517378, 21.4870209],
                [106.2534814, 21.4872421],
                [106.2543949, 21.4873153],
                [106.2547623, 21.487242],
                [106.2554705, 21.486926],
                [106.2557793, 21.4868499],
                [106.2561376, 21.4868631],
                [106.2566303, 21.4869748],
                [106.2572825, 21.4872068],
                [106.2580289, 21.4875463],
                [106.258741, 21.4879478],
                [106.259534, 21.4884834],
                [106.2598639, 21.4888242],
                [106.2604512, 21.4899501],
                [106.2606119, 21.4902015],
                [106.2609665, 21.4906015],
                [106.2616085, 21.4910938],
                [106.2621164, 21.4913832],
                [106.2627112, 21.491616],
                [106.2630573, 21.4916936],
                [106.2634346, 21.4917387],
                [106.2641953, 21.4917384],
                [106.2648573, 21.4916236],
                [106.2655946, 21.4913471],
                [106.2678488, 21.4902876],
                [106.2682238, 21.4901558],
                [106.2686371, 21.4901154],
                [106.2691135, 21.4902622],
                [106.2700184, 21.4908883],
                [106.2707531, 21.4915473],
                [106.2718831, 21.4927257],
                [106.2725276, 21.4933225],
                [106.2730787, 21.493616],
                [106.2733635, 21.4936843],
                [106.2740064, 21.4937173],
                [106.2747294, 21.4936158],
                [106.2754758, 21.493377],
                [106.2761492, 21.4929976],
                [106.2771156, 21.4923197],
                [106.277854, 21.4919228],
                [106.2792384, 21.4912472],
                [106.279781, 21.4910426],
                [106.2802414, 21.4909367],
                [106.2826284, 21.490662],
                [106.2835082, 21.4906311],
                [106.2843548, 21.4906608],
                [106.2856475, 21.4908003],
                [106.2875712, 21.4913202],
                [106.2879617, 21.4913259],
                [106.2880083, 21.4912609],
                [106.2879608, 21.4911439],
                [106.2875361, 21.4905304],
                [106.2870284, 21.4899698],
                [106.2856108, 21.4885823],
                [106.2853332, 21.4882469],
                [106.2851551, 21.487912],
                [106.2850849, 21.4875192],
                [106.285113, 21.4872965],
                [106.285691, 21.4859521],
                [106.2857364, 21.4855185],
                [106.2857141, 21.4852676],
                [106.2856575, 21.4849968],
                [106.2854332, 21.4844087],
                [106.2842981, 21.4823713],
                [106.2841378, 21.4819083],
                [106.2841122, 21.4816832],
                [106.284125, 21.4814566],
                [106.284257, 21.4809903],
                [106.2846276, 21.4802325],
                [106.2848367, 21.479673],
                [106.2848953, 21.4790302],
                [106.2848424, 21.4786654],
                [106.2846111, 21.4779189],
                [106.2833149, 21.4752321],
                [106.2826165, 21.4735855],
                [106.2823924, 21.4728934],
                [106.282416, 21.4725596],
                [106.282509, 21.4724311],
                [106.2826687, 21.4723252],
                [106.2841519, 21.4718699],
                [106.2847778, 21.4716354],
                [106.2852663, 21.4713766],
                [106.2857929, 21.4709537],
                [106.2860534, 21.470519],
                [106.2861652, 21.4702042],
                [106.2863401, 21.4693422],
                [106.2863968, 21.4687891],
                [106.2864494, 21.4675986],
                [106.2864352, 21.4666312],
                [106.2862655, 21.4653855],
                [106.2859525, 21.4645566],
                [106.2850244, 21.462968],
                [106.2845835, 21.4620173],
                [106.2844517, 21.461517],
                [106.2845623, 21.4600567],
                [106.2844695, 21.4594801],
                [106.2842202, 21.4588995],
                [106.2834193, 21.4575821],
                [106.2831813, 21.4567801],
                [106.2830897, 21.4558432],
                [106.2831396, 21.4545807],
                [106.2832497, 21.4539315],
                [106.2835044, 21.4529045],
                [106.2836165, 21.4525575],
                [106.2837033, 21.4521868],
                [106.2841661, 21.4522416],
                [106.2849448, 21.4524585],
                [106.287505, 21.455068],
                [106.2881056, 21.4554969],
                [106.2887046, 21.4557957],
                [106.289242, 21.4560059],
                [106.2895929, 21.4560527],
                [106.2898374, 21.4559588],
                [106.2902228, 21.4554986],
                [106.2904018, 21.4550697],
                [106.2905219, 21.4543187],
                [106.2904701, 21.4538122],
                [106.2902949, 21.4532436],
                [106.290013, 21.4527072],
                [106.2888593, 21.4512635],
                [106.2885574, 21.4507927],
                [106.2884455, 21.4504714],
                [106.2883929, 21.4500008],
                [106.2884348, 21.4496696],
                [106.2885443, 21.4493144],
                [106.28873, 21.448979],
                [106.2893387, 21.4483045],
                [106.2934175, 21.4442739],
                [106.2943563, 21.4435228],
                [106.2947782, 21.4432983],
                [106.2951704, 21.4431927],
                [106.2954062, 21.4431824],
                [106.2956503, 21.4432268],
                [106.2959878, 21.4434317],
                [106.2963238, 21.4439225],
                [106.2970045, 21.4452754],
                [106.2975564, 21.4461043],
                [106.2979238, 21.4464782],
                [106.2981291, 21.4465597],
                [106.2984699, 21.4465723],
                [106.2988868, 21.4464931],
                [106.2992522, 21.446177],
                [106.2994946, 21.4458246],
                [106.2999878, 21.4447764],
                [106.3003203, 21.4442165],
                [106.300866, 21.4435562],
                [106.3013795, 21.4431602],
                [106.3020383, 21.442919],
                [106.3044109, 21.4424737],
                [106.3051879, 21.4423908],
                [106.3055401, 21.4424332],
                [106.3056814, 21.4424998],
                [106.3059117, 21.4427138],
                [106.3060636, 21.4429633],
                [106.3061627, 21.4433098],
                [106.3061596, 21.4435339],
                [106.3060529, 21.4438088],
                [106.3058184, 21.4441666],
                [106.3044401, 21.4458764],
                [106.304024, 21.446523],
                [106.3039057, 21.4469168],
                [106.3038802, 21.4473319],
                [106.3039295, 21.4478174],
                [106.3040771, 21.4482236],
                [106.30445, 21.4486776],
                [106.3049316, 21.4490412],
                [106.3073135, 21.4502464],
                [106.3090966, 21.4512342],
                [106.3100895, 21.4518346],
                [106.3104902, 21.4519528],
                [106.3109998, 21.4518857],
                [106.3113892, 21.4516851],
                [106.3120953, 21.451012],
                [106.3129295, 21.450064],
                [106.3133488, 21.4494571],
                [106.3138419, 21.4484128],
                [106.3140774, 21.4476571],
                [106.314392, 21.4463453],
                [106.3146057, 21.4457322],
                [106.3148957, 21.4453126],
                [106.3154345, 21.4447503],
                [106.3157029, 21.4445874],
                [106.3160351, 21.4444725],
                [106.3163672, 21.444418],
                [106.3166875, 21.4444271],
                [106.3168265, 21.4444582],
                [106.3169879, 21.4445624],
                [106.3173571, 21.4451555],
                [106.3179677, 21.4464096],
                [106.3181799, 21.4466347],
                [106.3184693, 21.4468018],
                [106.3189955, 21.4469087],
                [106.3216062, 21.4469786],
                [106.322471, 21.4470901],
                [106.3233032, 21.4473655],
                [106.3249997, 21.4481139],
                [106.3254866, 21.4482853],
                [106.3258014, 21.4482975],
                [106.3262547, 21.44816],
                [106.3276706, 21.4474436],
                [106.3284707, 21.4469512],
                [106.3287167, 21.4467165],
                [106.3290081, 21.4463156],
                [106.3291245, 21.4460533],
                [106.3291756, 21.4457997],
                [106.3291159, 21.4451683],
                [106.3287284, 21.4440557],
                [106.3286419, 21.4435255],
                [106.3286926, 21.4422345],
                [106.3287911, 21.4415859],
                [106.328926, 21.4411307],
                [106.3291941, 21.4406491],
                [106.3295167, 21.4403703],
                [106.3299742, 21.4401061],
                [106.3304681, 21.4399012],
                [106.3314602, 21.4396719],
                [106.3316769, 21.4395425],
                [106.3318481, 21.4392937],
                [106.331966, 21.4389039],
                [106.3321806, 21.4374025],
                [106.3324956, 21.4356585],
                [106.3324277, 21.4354773],
                [106.3322035, 21.4352664],
                [106.3298195, 21.4337259],
                [106.3293835, 21.4333566],
                [106.3291694, 21.4330683],
                [106.3289657, 21.4325715],
                [106.3288988, 21.4318571],
                [106.3289831, 21.4315094],
                [106.3292072, 21.431195],
                [106.3292003, 21.4310281],
                [106.3283248, 21.4298371],
                [106.3281622, 21.4295132],
                [106.3280557, 21.4291194],
                [106.3280146, 21.4286554],
                [106.3280787, 21.4270075],
                [106.3279822, 21.4263876],
                [106.3278418, 21.4261643],
                [106.3276185, 21.4259786],
                [106.3263846, 21.4254447],
                [106.3259991, 21.4252232],
                [106.3256257, 21.424804],
                [106.3254572, 21.4244402],
                [106.3253957, 21.4240292],
                [106.3255282, 21.4233719],
                [106.3258742, 21.4227213],
                [106.3265182, 21.42181],
                [106.326699, 21.4212132],
                [106.3267731, 21.4205635],
                [106.326808, 21.4196348],
                [106.3267643, 21.4181882],
                [106.3265974, 21.4172538],
                [106.3263337, 21.4166468],
                [106.3259519, 21.4161836],
                [106.3249458, 21.4152842],
                [106.3246358, 21.4149425],
                [106.3242097, 21.4142777],
                [106.3240257, 21.413844],
                [106.3239265, 21.4130832],
                [106.3239626, 21.4120641],
                [106.3240863, 21.4110774],
                [106.3242616, 21.410481],
                [106.324491, 21.4099415],
                [106.3247633, 21.409463],
                [106.3251823, 21.4090456],
                [106.3257713, 21.4086998],
                [106.3263848, 21.4084546],
                [106.3272477, 21.4082425],
                [106.3280576, 21.4081415],
                [106.3288129, 21.4081653],
                [106.3290843, 21.4080961],
                [106.3292194, 21.4080086],
                [106.3296801, 21.4074791],
                [106.3299932, 21.4069422],
                [106.3310153, 21.4045632],
                [106.3311597, 21.4043601],
                [106.3314934, 21.4040751],
                [106.33189, 21.4039157],
                [106.332089, 21.4038924],
                [106.3324664, 21.4039624],
                [106.3334064, 21.4042902],
                [106.3341182, 21.4044761],
                [106.3345281, 21.4044959],
                [106.3347278, 21.4044533],
                [106.3349203, 21.4043506],
                [106.3350996, 21.4041653],
                [106.3352614, 21.4038798],
                [106.3358555, 21.4024174],
                [106.3362354, 21.4018261],
                [106.3367488, 21.4012158],
                [106.3395831, 21.3981214],
                [106.3400893, 21.3976349],
                [106.3404915, 21.3973626],
                [106.3406582, 21.3973092],
                [106.3409394, 21.3973385],
                [106.3411842, 21.3974581],
                [106.3413927, 21.39763],
                [106.3415185, 21.3979061],
                [106.3416762, 21.3990138],
                [106.3418155, 21.3992397],
                [106.3419956, 21.3993515],
                [106.3422071, 21.3993544],
                [106.342496, 21.399235],
                [106.3436175, 21.3985029],
                [106.3438697, 21.3984582],
                [106.3441003, 21.3985421],
                [106.344446, 21.3989416],
                [106.3453025, 21.400386],
                [106.34565, 21.4008832],
                [106.3459488, 21.4011285],
                [106.3461801, 21.4011897],
                [106.346455, 21.4011767],
                [106.3466025, 21.4011412],
                [106.3467538, 21.4010843],
                [106.3472591, 21.4007149],
                [106.3484384, 21.3994113],
                [106.3489117, 21.398826],
                [106.3492286, 21.3982791],
                [106.3497397, 21.3970588],
                [106.3498552, 21.3968843],
                [106.3500196, 21.3966534],
                [106.3505508, 21.3961649],
                [106.3511101, 21.3958461],
                [106.3514247, 21.3958214],
                [106.3517199, 21.3959457],
                [106.3526576, 21.3967188],
                [106.3530138, 21.3969578],
                [106.3534148, 21.397153],
                [106.3542955, 21.3973694],
                [106.355129, 21.3974152],
                [106.3558292, 21.3973545],
                [106.3567313, 21.397134],
                [106.3582078, 21.3963905],
                [106.3591212, 21.3961057],
                [106.3595556, 21.3960782],
                [106.3601936, 21.3961106],
                [106.3611154, 21.3959356],
                [106.3618623, 21.3959448],
                [106.3621177, 21.3960185],
                [106.3623594, 21.3961708],
                [106.3631625, 21.3972638],
                [106.36377, 21.397759],
                [106.3648534, 21.3983989],
                [106.36585, 21.3989173],
                [106.3663599, 21.3991343],
                [106.3667513, 21.3991832],
                [106.3671479, 21.399087],
                [106.3677215, 21.3987405],
                [106.3694102, 21.3974377],
                [106.3702638, 21.3966757],
                [106.371583, 21.3951136],
                [106.3721058, 21.3946804],
                [106.3727391, 21.3944718],
                [106.3735283, 21.3944123],
                [106.3744454, 21.3945584],
                [106.3755594, 21.3948931],
                [106.3758415, 21.3948822],
                [106.3761204, 21.3947636],
                [106.3763804, 21.3945485],
                [106.377064, 21.3937465],
                [106.3772848, 21.3936905],
                [106.377697, 21.3937847],
                [106.3785159, 21.3941765],
                [106.3816278, 21.3960387],
                [106.3824643, 21.3964455],
                [106.3833568, 21.3966199],
                [106.3852336, 21.3967667],
                [106.3861525, 21.3969092],
                [106.3867861, 21.3971351],
                [106.3872515, 21.3974071],
                [106.3876929, 21.3977671],
                [106.3902815, 21.4001943],
                [106.3906815, 21.4006311],
                [106.3917413, 21.4019919],
                [106.392525, 21.4028261],
                [106.394089, 21.4042396],
                [106.3950081, 21.4051973],
                [106.3953911, 21.4054343],
                [106.3957277, 21.4054863],
                [106.396194, 21.4054841],
                [106.3989985, 21.4052523],
                [106.4000833, 21.4052486],
                [106.4004392, 21.4053734],
                [106.4006274, 21.4055424],
                [106.4010804, 21.4060934],
                [106.4011989, 21.4061344],
                [106.4016283, 21.4060008],
                [106.4022265, 21.4056766],
                [106.4041931, 21.4044247],
                [106.4044337, 21.4043293],
                [106.4048912, 21.4042716],
                [106.4053496, 21.4043696],
                [106.408034, 21.405533],
                [106.4087587, 21.4057749],
                [106.4094204, 21.4058558],
                [106.4113713, 21.4058445],
                [106.4121529, 21.4059983],
                [106.4126921, 21.4062169],
                [106.4134414, 21.4066873],
                [106.4141398, 21.4072708],
                [106.4146228, 21.4077676],
                [106.4150844, 21.408372],
                [106.4152615, 21.4086997],
                [106.4154626, 21.4093782],
                [106.415523, 21.410062],
                [106.4154869, 21.4107195],
                [106.4153371, 21.4113152],
                [106.4150621, 21.4120653],
                [106.4150152, 21.412496],
                [106.4151189, 21.4129374],
                [106.4155525, 21.4139222],
                [106.4156355, 21.4143456],
                [106.415434, 21.4150828],
                [106.4147577, 21.4165218],
                [106.4141318, 21.4173227],
                [106.4138082, 21.4175804],
                [106.4134099, 21.4177389],
                [106.41196, 21.4180486],
                [106.4114065, 21.4182286],
                [106.4099909, 21.4189132],
                [106.4087387, 21.4193472],
                [106.4084216, 21.4195208],
                [106.4081169, 21.4197757],
                [106.4078238, 21.4201376],
                [106.4072554, 21.4210485],
                [106.4056986, 21.4239788],
                [106.4049882, 21.4257956],
                [106.4048878, 21.4262977],
                [106.4051491, 21.4273828],
                [106.4054218, 21.427989],
                [106.405813, 21.4285619],
                [106.4062933, 21.4290234],
                [106.407381, 21.4297014],
                [106.4094988, 21.4308541],
                [106.4105474, 21.4313782],
                [106.4110192, 21.4315745],
                [106.4118869, 21.4318291],
                [106.4131556, 21.4320125],
                [106.413543, 21.4321235],
                [106.4138876, 21.4323103],
                [106.4141834, 21.4325539],
                [106.4147652, 21.4332381],
                [106.4157311, 21.4349914],
                [106.4164109, 21.4359986],
                [106.4172133, 21.4367792],
                [106.4173537, 21.4369518],
                [106.4180712, 21.4373331],
                [106.4187936, 21.4375899],
                [106.4201245, 21.437878],
                [106.420847, 21.4380871],
                [106.4237866, 21.4392852],
                [106.4252237, 21.4397937],
                [106.4259493, 21.439959],
                [106.4266981, 21.4400105],
                [106.4274745, 21.4399464],
                [106.4278463, 21.4398678],
                [106.4287697, 21.4395236],
                [106.4292, 21.4392534],
                [106.4305626, 21.4382084],
                [106.431207, 21.4378714],
                [106.4318663, 21.4377259],
                [106.4322722, 21.4377364],
                [106.4324703, 21.4377908],
                [106.4328695, 21.4380165],
                [106.4334283, 21.4384422],
                [106.433599, 21.4386341],
                [106.4337699, 21.4389417],
                [106.4339466, 21.4394233],
                [106.4345856, 21.4416393],
                [106.4348407, 21.4420667],
                [106.4350028, 21.4421981],
                [106.4354497, 21.4424343],
                [106.4376575, 21.4434219],
                [106.4380766, 21.443571],
                [106.4384257, 21.4436132],
                [106.4387769, 21.4435487],
                [106.4391403, 21.443361],
                [106.4395126, 21.4430494],
                [106.4398773, 21.44265],
                [106.4415002, 21.4406041],
                [106.4419397, 21.4399397],
                [106.4426902, 21.4384267],
                [106.4431483, 21.4373033],
                [106.4433925, 21.4368541],
                [106.4435734, 21.4366828],
                [106.4438088, 21.4365431],
                [106.4444727, 21.4363159],
                [106.4459191, 21.4360061],
                [106.4472538, 21.4358456],
                [106.4492169, 21.4358424],
                [106.4499896, 21.4360011],
                [106.45056, 21.4363368],
                [106.4509591, 21.4368053],
                [106.4512193, 21.4373565],
                [106.4514146, 21.4380359],
                [106.4516588, 21.4385086],
                [106.4518599, 21.4387259],
                [106.4523527, 21.4390841],
                [106.4528055, 21.4392931],
                [106.4529971, 21.4393285],
                [106.4534128, 21.4392504],
                [106.4549045, 21.4385347],
                [106.4554292, 21.4383809],
                [106.4562884, 21.4383496],
                [106.4580276, 21.4385946],
                [106.4583528, 21.4385745],
                [106.4590702, 21.4383468],
                [106.459478, 21.438124],
                [106.4607409, 21.4372044],
                [106.461626, 21.4374804],
                [106.4617605, 21.4375082],
                [106.4620337, 21.4375403],
                [106.4623126, 21.4375406],
                [106.4625895, 21.4375085],
                [106.4627257, 21.4374804],
                [106.4635089, 21.43728],
                [106.4642717, 21.4370598],
                [106.4650136, 21.4368213],
                [106.4652218, 21.4367884],
                [106.4653273, 21.4367838],
                [106.4655378, 21.4367981],
                [106.4657432, 21.4368438],
                [106.4658506, 21.4368812],
                [106.4659541, 21.4369273],
                [106.4660529, 21.4369816],
                [106.4661462, 21.4370436],
                [106.4662333, 21.4371131],
                [106.4663136, 21.4371893],
                [106.4663865, 21.4372718],
                [106.4664513, 21.4373599],
                [106.4665076, 21.437453],
                [106.4666229, 21.437825],
                [106.4667334, 21.4380299],
                [106.4668744, 21.4382179],
                [106.4669555, 21.4383043],
                [106.4671326, 21.4384565],
                [106.467331, 21.4385839],
                [106.4686775, 21.4390233],
                [106.4691925, 21.4395102],
                [106.4694338, 21.4396312],
                [106.4695479, 21.4397019],
                [106.4697611, 21.4398622],
                [106.4699526, 21.4400468],
                [106.4701181, 21.4402521],
                [106.4701903, 21.4403615],
                [106.4705336, 21.4409557],
                [106.4708728, 21.4413979],
                [106.4712985, 21.4419102],
                [106.4715561, 21.4421998],
                [106.4718211, 21.4424836],
                [106.4718421, 21.442552],
                [106.4718586, 21.4426933],
                [106.4718425, 21.4428259],
                [106.4717964, 21.4429523],
                [106.4717628, 21.4430113],
                [106.4716762, 21.4431177],
                [106.4711881, 21.4436021],
                [106.4712384, 21.4440774],
                [106.4712766, 21.4443136],
                [106.471371, 21.4447512],
                [106.4714295, 21.4449677],
                [106.4715261, 21.4450769],
                [106.4717379, 21.4452797],
                [106.4718542, 21.4453742],
                [106.4721027, 21.4455444],
                [106.4723191, 21.4458332],
                [106.4725077, 21.4461385],
                [106.4726531, 21.446427],
                [106.4727736, 21.4467253],
                [106.4728242, 21.4468775],
                [106.4731273, 21.4479435],
                [106.4732911, 21.4479489],
                [106.4736168, 21.4479822],
                [106.4737984, 21.4480141],
                [106.4739779, 21.4480553],
                [106.4741546, 21.4481058],
                [106.4747152, 21.4481482],
                [106.4748176, 21.448165],
                [106.4750183, 21.4482156],
                [106.4751407, 21.4482588],
                [106.4752593, 21.4483104],
                [106.4753734, 21.4483702],
                [106.4754823, 21.4484378],
                [106.4756796, 21.4484704],
                [106.4758782, 21.4484954],
                [106.4762506, 21.4485214],
                [106.4765968, 21.4485214],
                [106.4767697, 21.4485127],
                [106.4769473, 21.4484476],
                [106.4770326, 21.4484078],
                [106.4772383, 21.4482851],
                [106.477333, 21.4482131],
                [106.4778694, 21.4477438],
                [106.4781124, 21.4476263],
                [106.4783688, 21.4475399],
                [106.4786341, 21.4474857],
                [106.4789048, 21.4474642],
                [106.4790972, 21.4474625],
                [106.4792895, 21.4474698],
                [106.4794812, 21.4474862],
                [106.4796717, 21.4475116],
                [106.4798606, 21.4475459],
                [106.4800474, 21.447589],
                [106.4807622, 21.4475915],
                [106.4809359, 21.4476743],
                [106.4810932, 21.4477817],
                [106.4811645, 21.4478438],
                [106.4812835, 21.4479744],
                [106.4813318, 21.4480411],
                [106.4814126, 21.4481831],
                [106.4814627, 21.4482245],
                [106.4815725, 21.4482895],
                [106.4816307, 21.448313],
                [106.4817533, 21.4483429],
                [106.481982, 21.4483442],
                [106.4822093, 21.4483679],
                [106.482438, 21.4484152],
                [106.4826599, 21.4484852],
                [106.4830434, 21.4504424],
                [106.483232, 21.4507462],
                [106.4833975, 21.4510615],
                [106.4834849, 21.4512548],
                [106.4836335, 21.4516507],
                [106.4835944, 21.4519711],
                [106.4835423, 21.4526143],
                [106.4835287, 21.4529603],
                [106.4835251, 21.4533066],
                [106.4835316, 21.4536528],
                [106.4839446, 21.4543917],
                [106.4840536, 21.4546512],
                [106.4842558, 21.4551756],
                [106.4843651, 21.455488],
                [106.4845616, 21.4561192],
                [106.4848137, 21.4563588],
                [106.4847921, 21.4565859],
                [106.4847279, 21.4570378],
                [106.4846835, 21.4572706],
                [106.4845723, 21.4577318],
                [106.4845506, 21.4578493],
                [106.484524, 21.4580863],
                [106.484757, 21.4582943],
                [106.4848646, 21.4584065],
                [106.4850604, 21.4586454],
                [106.4851426, 21.4587632],
                [106.4852882, 21.459009],
                [106.4853513, 21.4591365],
                [106.4854574, 21.4593993],
                [106.4858323, 21.4596243],
                [106.4861883, 21.4598744],
                [106.4865177, 21.4601431],
                [106.4872242, 21.4607894],
                [106.4874152, 21.4609805],
                [106.4875986, 21.4611779],
                [106.487774, 21.4613815],
                [106.4879411, 21.461591],
                [106.4880961, 21.4618007],
                [106.4882427, 21.4620155],
                [106.488381, 21.4622351],
                [106.4884454, 21.4623698],
                [106.4883327, 21.4628292],
                [106.4882692, 21.4630313],
                [106.4881182, 21.4634282],
                [106.4881134, 21.4638508],
                [106.4880753, 21.464272],
                [106.4880047, 21.464686],
                [106.4879148, 21.4650473],
                [106.4878101, 21.4653546],
                [106.4876641, 21.4656846],
                [106.4875709, 21.4658601],
                [106.4874687, 21.4660312],
                [106.4871526, 21.4664686],
                [106.4873614, 21.4666211],
                [106.487764, 21.4669432],
                [106.4881416, 21.467284],
                [106.4884937, 21.4676418],
                [106.4887476, 21.4681848],
                [106.4888638, 21.4684605],
                [106.4890194, 21.4688649],
                [106.4892393, 21.4692642],
                [106.4892071, 21.4699781],
                [106.4891447, 21.4702294],
                [106.4891019, 21.4703521],
                [106.4889982, 21.4705814],
                [106.488873, 21.4707908],
                [106.4887243, 21.4709865],
                [106.4896899, 21.4716804],
                [106.4899796, 21.4720279],
                [106.4902961, 21.4723544],
                [106.490467, 21.472512],
                [106.4908272, 21.4728087],
                [106.4912403, 21.4729335],
                [106.4915621, 21.4731831],
                [106.4916942, 21.4732999],
                [106.491943, 21.4735475],
                [106.4928925, 21.4741216],
                [106.4930047, 21.4743651],
                [106.493082, 21.4746201],
                [106.4931213, 21.4748636],
                [106.4931294, 21.4750911],
                [106.4931232, 21.4752048],
                [106.4928818, 21.4757939],
                [106.4928823, 21.4758726],
                [106.4929032, 21.4760285],
                [106.4929271, 21.476116],
                [106.4930362, 21.4763792],
                [106.4930899, 21.4765768],
                [106.4931187, 21.4767956],
                [106.4931214, 21.4769141],
                [106.4930998, 21.4771503],
                [106.4929201, 21.4778777],
                [106.4928281, 21.4783797],
                [106.4935094, 21.4801369],
                [106.4935394, 21.4803143],
                [106.4935952, 21.4804863],
                [106.4936405, 21.4805849],
                [106.4937562, 21.4807703],
                [106.4940539, 21.4811277],
                [106.4941818, 21.4814601],
                [106.4943194, 21.4817891],
                [106.4945501, 21.4822883],
                [106.4945508, 21.4823648],
                [106.4945419, 21.4824409],
                [106.4945, 21.4825772],
                [106.4944339, 21.4826926],
                [106.494247, 21.4829347],
                [106.494711, 21.4829248],
                [106.4953601, 21.4832293],
                [106.4963445, 21.4832542],
                [106.496712, 21.4835213],
                [106.4969859, 21.4834644],
                [106.4972634, 21.4834355],
                [106.4975409, 21.4834346],
                [106.497817, 21.4834614],
                [106.4979525, 21.4835058],
                [106.498214, 21.4836161],
                [106.4984715, 21.4837609],
                [106.49859, 21.4837451],
                [106.4987022, 21.483706],
                [106.4988187, 21.4836326],
                [106.4989264, 21.483535],
                [106.4989899, 21.48349],
                [106.4991308, 21.4834203],
                [106.4992064, 21.4833965],
                [106.4993575, 21.4833727],
                [106.4995105, 21.4833784],
                [106.499659, 21.4834135],
                [106.5007514, 21.4838957],
                [106.5011351, 21.4840788],
                [106.5015346, 21.4842301],
                [106.5017008, 21.4842822],
                [106.5020388, 21.4843699],
                [106.5024841, 21.4843848],
                [106.5025874, 21.4844098],
                [106.5026826, 21.4844547],
                [106.5027303, 21.4844876],
                [106.5028113, 21.4845681],
                [106.5028825, 21.4846586],
                [106.5029267, 21.4846982],
                [106.5030286, 21.4847617],
                [106.5032385, 21.4848645],
                [106.5036696, 21.4850487],
                [106.5038885, 21.4851293],
                [106.5043348, 21.4852684],
                [106.5045382, 21.4853683],
                [106.5047256, 21.4854924],
                [106.5049021, 21.4856469],
                [106.5050608, 21.485832],
                [106.5052086, 21.486064],
                [106.5053924, 21.4863134],
                [106.5056062, 21.4865412],
                [106.5058276, 21.4867297],
                [106.5059461, 21.4868154],
                [106.5061972, 21.4869683],
                [106.5075669, 21.4876294],
                [106.5077658, 21.4876762],
                [106.5079692, 21.4877018],
                [106.5081982, 21.4877049],
                [106.5083123, 21.4876964],
                [106.5084821, 21.4876717],
                [106.5086222, 21.4876345],
                [106.5087823, 21.4875645],
                [106.5088567, 21.4875201],
                [106.5089267, 21.4874697],
                [106.5089868, 21.4872116],
                [106.5090287, 21.4870853],
                [106.5091239, 21.486863],
                [106.5091806, 21.4867556],
                [106.5092432, 21.486651],
                [106.5095812, 21.4862268],
                [106.5103322, 21.4865063],
                [106.5105243, 21.4866566],
                [106.5106919, 21.4868314],
                [106.5108315, 21.4870274],
                [106.5109394, 21.4872399],
                [106.5110434, 21.4875403],
                [106.5111208, 21.4876843],
                [106.5124887, 21.4896114],
                [106.5137893, 21.4909118],
                [106.5176627, 21.4945577],
                [106.5182963, 21.4956659],
                [106.5190904, 21.4972569],
                [106.5196146, 21.4978334],
                [106.5203453, 21.4983111],
                [106.5215239, 21.4998796],
                [106.5202141, 21.5008123],
                [106.5191125, 21.5017794],
                [106.5178861, 21.50358],
                [106.5166843, 21.5056092],
                [106.5150498, 21.5088075],
                [106.5144051, 21.5109565],
                [106.5139072, 21.512194],
                [106.5131843, 21.5132415],
                [106.5119735, 21.5144182],
                [106.5101506, 21.5166208],
                [106.5089468, 21.5184637],
                [106.508073, 21.5199914],
                [106.5074963, 21.5216125],
                [106.5073731, 21.5226129],
                [106.5075173, 21.5236526],
                [106.5078828, 21.5246069],
                [106.5086728, 21.5257239],
                [106.5105545, 21.5273384],
                [106.5118314, 21.5282428],
                [106.5125058, 21.5289445],
                [106.5128451, 21.5295035],
                [106.5146618, 21.5316425],
                [106.5152198, 21.5318457],
                [106.517422, 21.5319089],
                [106.5181798, 21.5320685],
                [106.5188955, 21.5324576],
                [106.5200177, 21.5334336],
                [106.5221121, 21.5354904],
                [106.5234005, 21.5379543],
                [106.5240625, 21.5395928],
                [106.52507, 21.5424875],
                [106.5252984, 21.5430684],
                [106.5260454, 21.5443106],
                [106.5263381, 21.5446619],
                [106.5265028, 21.5455139],
                [106.5260392, 21.5479862],
                [106.5261565, 21.5485681],
                [106.5264958, 21.5491062],
                [106.5269897, 21.5495598],
                [106.527351, 21.5500769],
                [106.5276255, 21.5508031],
                [106.5276541, 21.5514065],
                [106.5275028, 21.5518243],
                [106.5270862, 21.5523902],
                [106.526787, 21.5535294],
                [106.527472, 21.5573538],
                [106.5275439, 21.5578528],
                [106.5278157, 21.5586052],
                [106.5283557, 21.5592041],
                [106.5284683, 21.5593279],
                [106.528986, 21.5599269],
                [106.5295056, 21.5606925],
                [106.5298685, 21.5613553],
                [106.5301197, 21.5619775],
                [106.5307961, 21.5628457],
                [106.531403, 21.5634439],
                [106.5320966, 21.563833],
                [106.532587, 21.5639326],
                [106.5331651, 21.5639273],
                [106.534385, 21.5635829],
                [106.5349245, 21.5633846],
                [106.5353471, 21.5633807],
                [106.5358159, 21.5635429],
                [106.5362865, 21.5638716],
                [106.53667, 21.5643885],
                [106.5370752, 21.5648428],
                [106.5376611, 21.565566],
                [106.5380223, 21.5660624],
                [106.5384931, 21.5664119],
                [106.5391647, 21.5668221],
                [106.539657, 21.567109],
                [106.5401962, 21.5676036],
                [106.5405349, 21.5680794],
                [106.5410755, 21.5687197],
                [106.5416395, 21.569464],
                [106.5423377, 21.5702903],
                [106.5424751, 21.5706637],
                [106.5424783, 21.5709551],
                [106.5421051, 21.5714166],
                [106.5417554, 21.5720343],
                [106.5417605, 21.5725132],
                [106.5418997, 21.5730531],
                [106.5422829, 21.5735283],
                [106.5425713, 21.5737113],
                [106.5428652, 21.5738976],
                [106.5433137, 21.5742475],
                [106.5435628, 21.5746615],
                [106.5437257, 21.575347],
                [106.5439096, 21.5759073],
                [106.5441414, 21.5767797],
                [106.5444116, 21.5770894],
                [106.5449707, 21.5773757],
                [106.5478887, 21.5784833],
                [106.5488057, 21.5789327],
                [106.5493682, 21.5795313],
                [106.5500012, 21.580483],
                [106.5509714, 21.5817648],
                [106.55187, 21.5825891],
                [106.5524557, 21.5832706],
                [106.5527251, 21.5840287],
                [106.5528026, 21.5850271],
                [106.552528, 21.5864037],
                [106.5522101, 21.5878849],
                [106.5521293, 21.5886558],
                [106.5520687, 21.5892308],
                [106.5520697, 21.5893349],
                [106.5533675, 21.5900306],
                [106.5547542, 21.5907254],
                [106.5554274, 21.5912604],
                [106.5560591, 21.5920872],
                [106.556468, 21.5928744],
                [106.5567616, 21.5932881],
                [106.5570975, 21.5934932],
                [106.5583246, 21.5938148],
                [106.5595482, 21.5938242],
                [106.560708, 21.5935786],
                [106.5618222, 21.5937554],
                [106.5637658, 21.5945076],
                [106.5638419, 21.5945533],
                [106.564684, 21.5950609],
                [106.567152, 21.5969947],
                [106.5693247, 21.5986122],
                [106.5701134, 21.5995416],
                [106.5707076, 21.6010139],
                [106.5711405, 21.602499],
                [106.5711463, 21.6025183],
                [106.5730758, 21.6019589],
                [106.5769574, 21.6008604],
                [106.5802895, 21.6003916],
                [106.5824258, 21.6004326],
                [106.5840204, 21.5997306],
                [106.5851957, 21.5993657],
                [106.586683, 21.5990393],
                [106.588571, 21.59873],
                [106.5900817, 21.5985075],
                [106.5906826, 21.5985225],
                [106.5905553, 21.5990855],
                [106.5907396, 21.5996664],
                [106.5910566, 21.6001629],
                [106.5922525, 21.5996521],
                [106.5934055, 21.5992664],
                [106.5944292, 21.5992774],
                [106.5981302, 21.5999494],
                [106.5984237, 21.6003212],
                [106.5988333, 21.6011496],
                [106.5991969, 21.6018328],
                [106.5997792, 21.602181],
                [106.6006912, 21.6021515],
                [106.6019844, 21.6023886],
                [106.6025233, 21.6028205],
                [106.6029948, 21.6032114],
                [106.6036638, 21.6033298],
                [106.6054212, 21.603292],
                [106.6074689, 21.6033347],
                [106.6090727, 21.6034856],
                [106.6096111, 21.6038759],
                [106.6111804, 21.604922],
                [106.6116282, 21.6051881],
                [106.612453, 21.605305],
                [106.613235, 21.6055887],
                [106.6137752, 21.6061454],
                [106.6143625, 21.6069304],
                [106.6150835, 21.607735],
                [106.6154406, 21.6078355],
                [106.6161744, 21.6077869],
                [106.6181303, 21.6075597],
                [106.6204831, 21.6070373],
                [106.6222351, 21.6065001],
                [106.6242087, 21.6058773],
                [106.6246961, 21.6056853],
                [106.6250459, 21.6051408],
                [106.6250835, 21.6045162],
                [106.6251178, 21.6036001],
                [106.6252684, 21.6031409],
                [106.6255083, 21.6027016],
                [106.6259508, 21.6024684],
                [106.6273288, 21.60233],
                [106.62955, 21.6019753],
                [106.6325503, 21.6016546],
                [106.63337, 21.6013344],
                [106.6341477, 21.601114],
                [106.6349671, 21.6008817],
                [106.6357665, 21.6007282],
                [106.6366805, 21.6008856],
                [106.6380237, 21.6016007],
                [106.6395716, 21.6027092],
                [106.6404868, 21.6029708],
                [106.6417789, 21.6031036],
                [106.6426744, 21.6035734],
                [106.6436158, 21.6041883],
                [106.6442507, 21.6052434],
                [106.6446609, 21.6060926],
                [106.6449978, 21.6063597],
                [106.6455775, 21.6064581],
                [106.6465779, 21.6063857],
                [106.6477539, 21.6060827],
                [106.6497445, 21.6050225],
                [106.6511345, 21.6039682],
                [106.6522401, 21.6033538],
                [106.6531724, 21.6031573],
                [106.654732, 21.6033291],
                [106.6559113, 21.6033173],
                [106.6567146, 21.6035174],
                [106.6574796, 21.6042589],
                [106.6585367, 21.6052472],
                [106.6596372, 21.6061311],
                [106.6601731, 21.6062922],
                [106.6607725, 21.6061822],
                [106.6614786, 21.6056549],
                [106.6620057, 21.6050462],
                [106.6624692, 21.6047086],
                [106.6630005, 21.6044744],
                [106.6636676, 21.604426],
                [106.6645831, 21.6047082],
                [106.6658812, 21.6053611],
                [106.6682348, 21.6068567],
                [106.6693609, 21.6080314],
                [106.6702393, 21.608959],
                [106.670792, 21.6086413],
                [106.6724196, 21.6069811],
                [106.6750175, 21.6045619],
                [106.6775159, 21.6031633],
                [106.6783358, 21.6028845],
                [106.6801145, 21.6027417],
                [106.6810455, 21.602441],
                [106.6816446, 21.6022892],
                [106.6822244, 21.6024083],
                [106.6828491, 21.6025475],
                [106.6832932, 21.6024598],
                [106.6840431, 21.6018904],
                [106.6851047, 21.6013386],
                [106.6859666, 21.6008304],
                [106.6865877, 21.6006577],
                [106.6876546, 21.6005636],
                [106.68843, 21.6002645],
                [106.6893381, 21.5999014],
                [106.6898884, 21.5993965],
                [106.6903494, 21.5988508],
                [106.6912892, 21.5974054],
                [106.6930617, 21.5967423],
                [106.6950344, 21.596098],
                [106.6966544, 21.5957277],
                [106.6982761, 21.595503],
                [106.6993543, 21.5944724],
                [106.6998411, 21.5942386],
                [106.7008613, 21.5939576],
                [106.7020592, 21.593654],
                [106.7025935, 21.5931433],
                [106.7026973, 21.593044],
                [106.7028866, 21.5921057],
                [106.702811, 21.5913365],
                [106.7025141, 21.5906945],
                [106.702104, 21.5898663],
                [106.701937, 21.5889108],
                [106.7017445, 21.5876643],
                [106.7017734, 21.5863322],
                [106.7024747, 21.5835158],
                [106.702665, 21.5826608],
                [106.7025946, 21.5823493],
                [106.7020959, 21.5815636],
                [106.7005835, 21.5797064],
                [106.700175, 21.579003],
                [106.70008, 21.5784838],
                [106.7000061, 21.577881],
                [106.6999079, 21.5770912],
                [106.6996318, 21.5763034],
                [106.699242, 21.5753086],
                [106.6989225, 21.5746251],
                [106.698848, 21.57396],
                [106.698928, 21.5731892],
                [106.699319, 21.5723737],
                [106.6999306, 21.5714103],
                [106.7024476, 21.5697822],
                [106.7042606, 21.5688273],
                [106.7051877, 21.5681936],
                [106.7056713, 21.56771],
                [106.7060642, 21.5670609],
                [106.7063851, 21.5659755],
                [106.7072315, 21.5641564],
                [106.7077364, 21.5635894],
                [106.7085042, 21.562666],
                [106.7097784, 21.5613004],
                [106.710239, 21.5607338],
                [106.7106618, 21.5607502],
                [106.7113304, 21.5608683],
                [106.7119535, 21.5608826],
                [106.7125705, 21.5603769],
                [106.7130553, 21.5599974],
                [106.7134091, 21.5598065],
                [106.7138979, 21.5597598],
                [106.7144765, 21.5597955],
                [106.7150796, 21.5599974],
                [106.7156821, 21.5601576],
                [106.7163051, 21.5601721],
                [106.7166593, 21.5600227],
                [106.7170347, 21.5597899],
                [106.7174302, 21.5593697],
                [106.7179309, 21.5584489],
                [106.7185913, 21.5578594],
                [106.7192102, 21.5575202],
                [106.7200537, 21.5573866],
                [106.7210288, 21.5570643],
                [106.7216456, 21.5565586],
                [106.7222386, 21.5559074],
                [106.7227474, 21.5556733],
                [106.7237703, 21.5556418],
                [106.7247053, 21.5557154],
                [106.7254607, 21.5556244],
                [106.7263907, 21.5552817],
                [106.7268753, 21.5548814],
                [106.7272888, 21.5541073],
                [106.7278356, 21.5533107],
                [106.7283846, 21.5527225],
                [106.7293963, 21.5517547],
                [106.7301678, 21.5511641],
                [106.7326188, 21.5496404],
                [106.7333923, 21.5492162],
                [106.7335669, 21.5489439],
                [106.7335617, 21.548507],
                [106.7334293, 21.5467187],
                [106.7335281, 21.5456773],
                [106.7336107, 21.5451354],
                [106.7340718, 21.5446312],
                [106.7346019, 21.5443344],
                [106.7358005, 21.5441139],
                [106.7375539, 21.5438042],
                [106.739439, 21.5433476],
                [106.7406777, 21.5427729],
                [106.7417175, 21.5423042],
                [106.7428473, 21.5419178],
                [106.7436907, 21.5417841],
                [106.7444904, 21.5416926],
                [106.7461823, 21.5418205],
                [106.7481641, 21.5420078],
                [106.7488969, 21.5419168],
                [106.7496358, 21.5416457],
                [106.7518818, 21.5403401],
                [106.7524799, 21.5404136],
                [106.7534514, 21.5406848],
                [106.7545875, 21.5407666],
                [106.7550554, 21.5408241],
                [106.7555569, 21.5408813],
                [106.7559275, 21.5411588],
                [106.7561999, 21.5415936],
                [106.7564777, 21.5424971],
                [106.7570535, 21.5431787],
                [106.7573249, 21.5435352],
                [106.7575619, 21.5438141],
                [106.757899, 21.5440918],
                [106.7583016, 21.5442438],
                [106.7585708, 21.5444286],
                [106.7587086, 21.5447709],
                [106.7586804, 21.5452088],
                [106.7585845, 21.5455536],
                [106.7581577, 21.546152],
                [106.7574638, 21.5467532],
                [106.7571318, 21.5469131],
                [106.7570677, 21.5471325],
                [106.7572698, 21.5472867],
                [106.7576705, 21.5472825],
                [106.7583699, 21.54715],
                [106.7590372, 21.5471117],
                [106.7595375, 21.5470752],
                [106.7598395, 21.547197],
                [106.759943, 21.5474772],
                [106.7599471, 21.547821],
                [106.759784, 21.5481354],
                [106.759026, 21.548956],
                [106.7584282, 21.5492124],
                [106.7583304, 21.5494166],
                [106.7584675, 21.5496964],
                [106.7587042, 21.5499441],
                [106.759107, 21.5501274],
                [106.7597089, 21.5502147],
                [106.7600105, 21.5503053],
                [106.760147, 21.550554],
                [106.7601827, 21.5507411],
                [106.7600525, 21.5510237],
                [106.7597567, 21.551402],
                [106.7591607, 21.5518146],
                [106.7587294, 21.552038],
                [106.7584316, 21.5522599],
                [106.7583694, 21.5526356],
                [106.7584414, 21.5530725],
                [106.7587141, 21.5535385],
                [106.7589493, 21.553661],
                [106.7591488, 21.5535964],
                [106.7593126, 21.5533446],
                [106.7594747, 21.5529365],
                [106.7596394, 21.5527473],
                [106.7600065, 21.5527434],
                [106.7604105, 21.5530204],
                [106.7610155, 21.5533579],
                [106.7614203, 21.5536975],
                [106.7615902, 21.5539456],
                [106.7616263, 21.5541641],
                [106.7614961, 21.5544468],
                [106.760998, 21.5546709],
                [106.7605637, 21.5546442],
                [106.7600296, 21.5546498],
                [106.7596965, 21.5547159],
                [106.7595319, 21.5549051],
                [106.7595011, 21.5551244],
                [106.7597419, 21.5557156],
                [106.7599473, 21.5561354],
                [106.7597512, 21.5564814],
                [106.7593198, 21.5567046],
                [106.7586867, 21.5568052],
                [106.7582534, 21.5568722],
                [106.7580216, 21.557031],
                [106.7579927, 21.5574064],
                [106.7583983, 21.5578085],
                [106.7590413, 21.5585205],
                [106.7590781, 21.5588015],
                [106.758982, 21.5591463],
                [106.7587521, 21.5594613],
                [106.7583556, 21.5598093],
                [106.757993, 21.5601882],
                [106.7579326, 21.5607202],
                [106.7579086, 21.5615019],
                [106.7578478, 21.5620026],
                [106.7575838, 21.5622555],
                [106.7572177, 21.5623531],
                [106.7569158, 21.5622313],
                [106.7562428, 21.5618008],
                [106.7558065, 21.5615762],
                [106.755679, 21.5621193],
                [106.755689, 21.5629475],
                [106.7558748, 21.5645083],
                [106.756092, 21.5659127],
                [106.7566079, 21.5671576],
                [106.7579941, 21.5685807],
                [106.7598853, 21.570358],
                [106.7621703, 21.5715528],
                [106.7640551, 21.5727832],
                [106.7650264, 21.573023],
                [106.7659616, 21.5730442],
                [106.766693, 21.5727864],
                [106.7686883, 21.572109],
                [106.7703074, 21.5719045],
                [106.7710907, 21.5718055],
                [106.7719564, 21.5717616],
                [106.7734274, 21.5719179],
                [106.7744343, 21.5723447],
                [106.7765473, 21.5731036],
                [106.7798678, 21.5743183],
                [106.7842303, 21.5760846],
                [106.7866787, 21.5769648],
                [106.7867414, 21.5766359],
                [106.7868604, 21.5754469],
                [106.7871733, 21.5737244],
                [106.7872947, 21.5727542],
                [106.7876191, 21.5719693],
                [106.7882765, 21.5711183],
                [106.7889023, 21.570424],
                [106.7904957, 21.5696566],
                [106.7916221, 21.5689412],
                [106.792215, 21.5682785],
                [106.792674, 21.5675859],
                [106.7928339, 21.5670215],
                [106.7929284, 21.5665517],
                [106.7930922, 21.5662999],
                [106.7935906, 21.566107],
                [106.7942905, 21.5660057],
                [106.7953567, 21.5658066],
                [106.7973508, 21.5650663],
                [106.7982441, 21.5644001],
                [106.7988046, 21.5638315],
                [106.7990985, 21.5632969],
                [106.7991891, 21.5625146],
                [106.7992805, 21.5618103],
                [106.7998779, 21.5615226],
                [106.8009423, 21.5611985],
                [106.8015067, 21.5609424],
                [106.8021383, 21.5607167],
                [106.802839, 21.5606779],
                [106.8035727, 21.5606075],
                [106.8043355, 21.5601928],
                [106.8052974, 21.5596823],
                [106.8061277, 21.5593294],
                [106.8066933, 21.5591671],
                [106.8074945, 21.5591584],
                [106.80823, 21.5592442],
                [106.8094357, 21.5595437],
                [106.8113731, 21.5596163],
                [106.8121771, 21.5598353],
                [106.8133525, 21.5603847],
                [106.8159366, 21.5614497],
                [106.8174776, 21.5618701],
                [106.8194213, 21.5624422],
                [106.8205246, 21.5625552],
                [106.8218269, 21.5625722],
                [106.8232274, 21.562432],
                [106.8242588, 21.5621396],
                [106.8255859, 21.5614691],
                [106.8275081, 21.5603236],
                [106.8284062, 21.560064],
                [106.8289733, 21.5600264],
                [106.8294433, 21.56024],
                [106.8296799, 21.5604559],
                [106.8295515, 21.5608634],
                [106.8293205, 21.5610845],
                [106.8292898, 21.5613036],
                [106.8293585, 21.561459],
                [106.8297278, 21.5616111],
                [106.830265, 21.561855],
                [106.8311643, 21.5619393],
                [106.8320688, 21.5620242],
                [106.8337311, 21.5614903],
                [106.8368209, 21.5603312],
                [106.8382514, 21.5599404],
                [106.8397837, 21.5596736],
                [106.8410515, 21.5596282],
                [106.8427545, 21.5596719],
                [106.8434558, 21.5596954],
                [106.8438873, 21.5595031],
                [106.8443796, 21.5588415],
                [106.8451636, 21.557489],
                [106.8458813, 21.5561686],
                [106.8467998, 21.5548928],
                [106.8479174, 21.5535365],
                [106.8482775, 21.5529701],
                [106.8483025, 21.5523135],
                [106.8481253, 21.551503],
                [106.8480506, 21.5508788],
                [106.8480773, 21.5503473],
                [106.8485009, 21.5495301],
                [106.8493226, 21.548521],
                [106.8500429, 21.5474193],
                [106.8500693, 21.5468721],
                [106.8498307, 21.5464685],
                [106.8496594, 21.5461266],
                [106.8496844, 21.54547],
                [106.849905, 21.5444364],
                [106.8502852, 21.5428384],
                [106.8503428, 21.542119],
                [106.8506352, 21.5414908],
                [106.8518236, 21.5404463],
                [106.8524487, 21.5397362],
                [106.8529148, 21.5396373],
                [106.8535487, 21.5396303],
                [106.8546182, 21.5397433],
                [106.8551841, 21.5396121],
                [106.8555149, 21.5393896],
                [106.8556805, 21.539294],
                [106.8561438, 21.5389763],
                [106.8562416, 21.5387878],
                [106.8564051, 21.5385359],
                [106.8566714, 21.5384706],
                [106.8568722, 21.5385307],
                [106.8571438, 21.5388715],
                [106.8575461, 21.5390233],
                [106.8578793, 21.5389883],
                [106.8582096, 21.5387034],
                [106.8585377, 21.5382622],
                [106.8590642, 21.5376626],
                [106.8595571, 21.5370633],
                [106.8602942, 21.5368411],
                [106.8613202, 21.5366061],
                [106.8624174, 21.5362814],
                [106.8634793, 21.5358008],
                [106.8645999, 21.5346945],
                [106.8650218, 21.5337612],
                [106.8651403, 21.5330487],
                [106.8650992, 21.5324399],
                [106.8651567, 21.5317206],
                [106.865064, 21.5296908],
                [106.8650557, 21.5290347],
                [106.8650119, 21.5282229],
                [106.8644661, 21.5272916],
                [106.8635202, 21.5263805],
                [106.8622342, 21.5249576],
                [106.8603417, 21.5230729],
                [106.8594298, 21.5222083],
                [106.8585595, 21.5219992],
                [106.8579929, 21.5220368],
                [106.85733, 21.5223878],
                [106.8561747, 21.5233693],
                [106.8548585, 21.5248212],
                [106.8542646, 21.5253589],
                [106.8537666, 21.5255519],
                [106.8533335, 21.525588],
                [106.8525957, 21.525315],
                [106.8519905, 21.5249468],
                [106.8512821, 21.5243298],
                [106.8508411, 21.5237723],
                [106.8504314, 21.5230269],
                [106.8503858, 21.5220744],
                [106.8505004, 21.5205735],
                [106.8507062, 21.5183841],
                [106.8507316, 21.5177589],
                [106.8506578, 21.5171973],
                [106.8502503, 21.5166395],
                [106.8498453, 21.516269],
                [106.8491062, 21.515871],
                [106.8481309, 21.5152569],
                [106.8474199, 21.5144369],
                [106.8468468, 21.5139745],
                [106.8462425, 21.5136688],
                [106.8454052, 21.513428],
                [106.8433024, 21.5133576],
                [106.841835, 21.513405],
                [106.8409336, 21.5133524],
                [106.8403963, 21.5130772],
                [106.840125, 21.5127365],
                [106.8397844, 21.5121779],
                [106.8395445, 21.5116806],
                [106.839472, 21.5112128],
                [106.8392648, 21.5106526],
                [106.8391219, 21.5099042],
                [106.8389743, 21.5087811],
                [106.8389905, 21.5074217],
                [106.8390787, 21.5064629],
                [106.8393747, 21.5061365],
                [106.8403715, 21.5058131],
                [106.841464, 21.5051448],
                [106.8421225, 21.5044502],
                [106.8427457, 21.5035999],
                [106.8433892, 21.501718],
                [106.8440635, 21.4998791],
                [106.8448093, 21.4970274],
                [106.8451513, 21.4956781],
                [106.8454509, 21.4947331],
                [106.8460444, 21.4943228],
                [106.8467673, 21.4946736],
                [106.8473475, 21.4953143],
                [106.8488835, 21.4972375],
                [106.8519352, 21.4998516],
                [106.8519378, 21.4976111],
                [106.8519871, 21.4965921],
                [106.8522299, 21.4960949],
                [106.8526611, 21.4957409],
                [106.8532483, 21.4954144],
                [106.8538379, 21.4952914],
                [106.8543356, 21.4952859],
                [106.8553002, 21.4952752],
                [106.8565482, 21.4955232],
                [106.8579523, 21.4958276],
                [106.8596827, 21.4960847],
                [106.8618607, 21.4960605],
                [106.8628586, 21.496224],
                [106.864325, 21.4965276],
                [106.8656386, 21.4970366],
                [106.8670105, 21.4972541],
                [106.8683324, 21.4971956],
                [106.8689213, 21.4970144],
                [106.8704092, 21.4965614],
                [106.8726473, 21.4963617],
                [106.8739503, 21.4960561],
                [106.8762119, 21.4952743],
                [106.8800694, 21.4939652],
                [106.8828501, 21.492421],
                [106.884384, 21.4918873],
                [106.884397, 21.491872],
                [106.8846896, 21.4917467],
                [106.8852058, 21.4915648],
                [106.885711, 21.4913528],
                [106.886256, 21.4910104],
                [106.886869, 21.4905776],
                [106.8874284, 21.4902905],
                [106.8878243, 21.4901625],
                [106.88805, 21.4901401],
                [106.8882255, 21.4901951],
                [106.8883983, 21.490288],
                [106.888569, 21.4903723],
                [106.888721, 21.4904037],
                [106.8888842, 21.4903739],
                [106.8890705, 21.4902811],
                [106.889223, 21.4901106],
                [106.8893379, 21.4898884],
                [106.8894527, 21.4896537],
                [106.8896165, 21.489428],
                [106.8898101, 21.4892436],
                [106.8899862, 21.489133],
                [106.8901367, 21.4891107],
                [106.8902136, 21.4891506],
                [106.8899045, 21.486878],
                [106.8897161, 21.48659],
                [106.8890075, 21.4858388],
                [106.8884411, 21.4849584],
                [106.8884109, 21.4846758],
                [106.8886544, 21.4838212],
                [106.8887461, 21.482642],
                [106.888803, 21.4823321],
                [106.8889217, 21.482079],
                [106.8890841, 21.4817927],
                [106.8892968, 21.4816143],
                [106.8903045, 21.4813473],
                [106.8916062, 21.4806446],
                [106.8916737, 21.4802355],
                [106.8915557, 21.478626],
                [106.8915449, 21.4777865],
                [106.8917049, 21.4770133],
                [106.8921591, 21.4764636],
                [106.8927119, 21.4760262],
                [106.8956623, 21.4751305],
                [106.8987954, 21.4733479],
                [106.900601, 21.4721701],
                [106.9011302, 21.4717785],
                [106.9014636, 21.4712755],
                [106.9016522, 21.4708423],
                [106.9017677, 21.4703872],
                [106.9017388, 21.4700471],
                [106.9015092, 21.4691876],
                [106.8989645, 21.4648496],
                [106.897529, 21.4617897],
                [106.8969653, 21.4613874],
                [106.8955016, 21.4608364],
                [106.8935665, 21.4604382],
                [106.8928354, 21.4602196],
                [106.8923946, 21.4599294],
                [106.8920989, 21.4595922],
                [106.8916982, 21.4567588],
                [106.8909188, 21.4537367],
                [106.8909051, 21.4526698],
                [106.8910838, 21.451726],
                [106.8932176, 21.4496336],
                [106.8944085, 21.4476764],
                [106.8944675, 21.4473194],
                [106.8954277, 21.4474617],
                [106.8963128, 21.4475116],
                [106.8962431, 21.4466478],
                [106.8961787, 21.4441313],
                [106.8965005, 21.4440049],
                [106.8968058, 21.443847],
                [106.8969512, 21.4437568],
                [106.897098, 21.4436544],
                [106.8972384, 21.4435444],
                [106.8973719, 21.4434272],
                [106.897498, 21.4433032],
                [106.8981314, 21.4426034],
                [106.8982107, 21.4424693],
                [106.8982602, 21.442408],
                [106.8983766, 21.4422999],
                [106.8985145, 21.4422131],
                [106.8986694, 21.4421517],
                [106.8987509, 21.4421317],
                [106.8988341, 21.4421191],
                [106.8989419, 21.4421181],
                [106.899054, 21.4420921],
                [106.8991625, 21.4420377],
                [106.8992525, 21.4419593],
                [106.8993822, 21.4419058],
                [106.899451, 21.4418894],
                [106.899594, 21.4418786],
                [106.8999767, 21.4419044],
                [106.9001466, 21.4418823],
                [106.9002289, 21.4418595],
                [106.9003684, 21.4417999],
                [106.9004333, 21.4417617],
                [106.9004942, 21.4417182],
                [106.9008887, 21.4413876],
                [106.9010888, 21.4412639],
                [106.9011938, 21.4412095],
                [106.90141, 21.4411175],
                [106.9016705, 21.4410475],
                [106.9018922, 21.4409968],
                [106.9020988, 21.4409236],
                [106.9022888, 21.4408184],
                [106.902436, 21.4407034],
                [106.902563, 21.4405693],
                [106.9027219, 21.4403446],
                [106.9027821, 21.4402739],
                [106.9028473, 21.4402072],
                [106.903086, 21.4400058],
                [106.903175, 21.4399195],
                [106.903258, 21.4398283],
                [106.9033996, 21.4396407],
                [106.903488, 21.439498],
                [106.903533, 21.4393848],
                [106.9035492, 21.4392539],
                [106.9035388, 21.4391281],
                [106.9035434, 21.4390681],
                [106.9035578, 21.4390094],
                [106.9035816, 21.4389534],
                [106.9036147, 21.4389009],
                [106.903656, 21.4388538],
                [106.9037046, 21.4388131],
                [106.9037594, 21.4387799],
                [106.903819, 21.4387549],
                [106.903882, 21.4387387],
                [106.904015, 21.4387237],
                [106.9040792, 21.4387055],
                [106.9041406, 21.4386804],
                [106.9042511, 21.438612],
                [106.9043409, 21.4385227],
                [106.904377, 21.4384715],
                [106.9044066, 21.4384168],
                [106.9044691, 21.4382598],
                [106.9045347, 21.4381732],
                [106.9046216, 21.438105],
                [106.9046713, 21.4380793],
                [106.9047794, 21.4380395],
                [106.9048819, 21.4379843],
                [106.9049797, 21.4379013],
                [106.9050243, 21.4378473],
                [106.9050902, 21.4377257],
                [106.9052339, 21.4371378],
                [106.9053057, 21.4368789],
                [106.9054873, 21.436314],
                [106.9055999, 21.4360093],
                [106.905722, 21.4357078],
                [106.9057575, 21.435639],
                [106.9058503, 21.4355134],
                [106.9059688, 21.4354082],
                [106.9061098, 21.4353269],
                [106.9062658, 21.4352745],
                [106.9063474, 21.43526],
                [106.9068325, 21.4352284],
                [106.9072884, 21.4350736],
                [106.9074104, 21.4351205],
                [106.9075358, 21.4351588],
                [106.9076639, 21.4351885],
                [106.9078901, 21.4352188],
                [106.9081186, 21.435222],
                [106.9082326, 21.4352134],
                [106.9085437, 21.4351685],
                [106.9087085, 21.4349844],
                [106.9087821, 21.4348861],
                [106.9089204, 21.4346598],
                [106.9089822, 21.4345315],
                [106.9090345, 21.4343995],
                [106.9090634, 21.4342268],
                [106.9090882, 21.4341424],
                [106.9091632, 21.4339694],
                [106.910947, 21.4309341],
                [106.9109657, 21.430607],
                [106.9114566, 21.4303224],
                [106.9115812, 21.430282],
                [106.9117114, 21.4302624],
                [106.9117958, 21.4302611],
                [106.9119623, 21.4302848],
                [106.9122941, 21.4304066],
                [106.9124784, 21.4304413],
                [106.9126637, 21.4304455],
                [106.9128443, 21.4304205],
                [106.9129322, 21.4303974],
                [106.9131814, 21.4303036],
                [106.9133417, 21.4302327],
                [106.9136506, 21.4300702],
                [106.9139361, 21.4298851],
                [106.9140715, 21.4297835],
                [106.9143265, 21.4295633],
                [106.9144146, 21.4295215],
                [106.9144633, 21.4295128],
                [106.9145096, 21.4295124],
                [106.9145988, 21.429534],
                [106.915614, 21.4300502],
                [106.9159455, 21.4302452],
                [106.9162899, 21.4304197],
                [106.9165112, 21.4305181],
                [106.9167367, 21.4306081],
                [106.9169658, 21.4306894],
                [106.9177195, 21.4312961],
                [106.917801, 21.4314185],
                [106.9178694, 21.4315646],
                [106.9178966, 21.4316481],
                [106.9179448, 21.4318878],
                [106.9189694, 21.4320951],
                [106.9198814, 21.4329539],
                [106.9202998, 21.433643],
                [106.9204084, 21.433933],
                [106.9204822, 21.4342322],
                [106.9205182, 21.4345059],
                [106.9205253, 21.4346436],
                [106.9205238, 21.4349699],
                [106.9205327, 21.4351582],
                [106.920552, 21.4353458],
                [106.9205765, 21.4355051],
                [106.9206476, 21.4358199],
                [106.9206941, 21.4359749],
                [106.9207438, 21.4360459],
                [106.9208096, 21.4360951],
                [106.9208819, 21.4361222],
                [106.921539, 21.4362446],
                [106.9217686, 21.4363228],
                [106.9219872, 21.4364248],
                [106.9222022, 21.4365562],
                [106.922408, 21.4367186],
                [106.9225904, 21.4369037],
                [106.9226395, 21.4369714],
                [106.9227533, 21.4370959],
                [106.9228855, 21.4372033],
                [106.9230333, 21.4372914],
                [106.9231934, 21.4373581],
                [106.9233456, 21.4374082],
                [106.9234699, 21.4374812],
                [106.9235699, 21.4375789],
                [106.9236406, 21.4376945],
                [106.9236641, 21.4377574],
                [106.9237162, 21.4379589],
                [106.9238082, 21.4382269],
                [106.9238392, 21.4383384],
                [106.9238618, 21.4384516],
                [106.9238697, 21.4385491],
                [106.9238833, 21.4385964],
                [106.9239038, 21.4386434],
                [106.9239634, 21.4387282],
                [106.9240442, 21.4387961],
                [106.9248365, 21.4392716],
                [106.9250956, 21.4394353],
                [106.9251811, 21.4395053],
                [106.9253387, 21.4396602],
                [106.92541, 21.4397446],
                [106.9256453, 21.4400819],
                [106.9257619, 21.4402349],
                [106.9260143, 21.4405272],
                [106.9261497, 21.4406661],
                [106.926477, 21.4409183],
                [106.9265522, 21.4409448],
                [106.9266304, 21.4409624],
                [106.9267103, 21.4409707],
                [106.9268482, 21.4409631],
                [106.9269812, 21.4409282],
                [106.9280675, 21.4401643],
                [106.9291833, 21.4393154],
                [106.9293174, 21.4387212],
                [106.9293818, 21.4385665],
                [106.9295434, 21.438292],
                [106.9295671, 21.4382239],
                [106.9295813, 21.4381536],
                [106.929598, 21.4379827],
                [106.9296446, 21.4377875],
                [106.9298807, 21.4372283],
                [106.9299007, 21.4371444],
                [106.9298968, 21.4370585],
                [106.929883, 21.4370085],
                [106.9298314, 21.4369175],
                [106.9293898, 21.4364468],
                [106.9293559, 21.4363776],
                [106.9293481, 21.4363401],
                [106.9293469, 21.436302],
                [106.9293626, 21.4362307],
                [106.9294006, 21.4361672],
                [106.9295285, 21.4360115],
                [106.9295812, 21.4359265],
                [106.9296637, 21.4357407],
                [106.9296919, 21.4356409],
                [106.9297103, 21.4355392],
                [106.9297187, 21.4354364],
                [106.9297278, 21.4345343],
                [106.9297457, 21.4343843],
                [106.9297922, 21.4342397],
                [106.9298275, 21.4341676],
                [106.9299194, 21.4340343],
                [106.9300365, 21.4339194],
                [106.9302302, 21.4337767],
                [106.9303353, 21.4336621],
                [106.9304144, 21.4335307],
                [106.9304437, 21.4334591],
                [106.9304788, 21.4333097],
                [106.9304816, 21.4331568],
                [106.9304, 21.4326637],
                [106.9303125, 21.4323273],
                [106.9302519, 21.4321523],
                [106.9301817, 21.4319805],
                [106.9301021, 21.4318122],
                [106.9298601, 21.4313861],
                [106.929768, 21.4311738],
                [106.9297052, 21.4309665],
                [106.92964, 21.4306241],
                [106.9295615, 21.4303698],
                [106.9295056, 21.4302386],
                [106.9294403, 21.4301113],
                [106.9293658, 21.4299884],
                [106.9290831, 21.4295779],
                [106.9288444, 21.429207],
                [106.9288046, 21.4291295],
                [106.9287461, 21.4289666],
                [106.9287275, 21.4288803],
                [106.9287141, 21.4287049],
                [106.9287582, 21.4281376],
                [106.9287461, 21.4279479],
                [106.9287041, 21.427774],
                [106.92858, 21.4274875],
                [106.928494, 21.4272388],
                [106.9284359, 21.4269788],
                [106.9284081, 21.4267145],
                [106.9283786, 21.4258556],
                [106.928382, 21.4257333],
                [106.9284081, 21.4256134],
                [106.928431, 21.4255521],
                [106.9284943, 21.4254368],
                [106.9287026, 21.4254592],
                [106.9288668, 21.425506],
                [106.9289398, 21.4255377],
                [106.9290753, 21.4256184],
                [106.9291368, 21.4256668],
                [106.9292924, 21.4258685],
                [106.9293523, 21.425933],
                [106.929497, 21.4260566],
                [106.9296607, 21.4261577],
                [106.9306397, 21.4265247],
                [106.9309154, 21.4265763],
                [106.9310509, 21.4266113],
                [106.9313157, 21.4266995],
                [106.9316034, 21.426828],
                [106.9317412, 21.4269032],
                [106.9320023, 21.427074],
                [106.9320601, 21.4271878],
                [106.9320774, 21.4272488],
                [106.9320881, 21.4273637],
                [106.9321525, 21.4274835],
                [106.9322271, 21.4275734],
                [106.9322705, 21.4276133],
                [106.9331449, 21.4282575],
                [106.9332019, 21.4283396],
                [106.9333005, 21.4285122],
                [106.933437, 21.4288382],
                [106.93359, 21.4290924],
                [106.9336384, 21.4291857],
                [106.9337481, 21.4294412],
                [106.9338959, 21.4297556],
                [106.9340866, 21.4301024],
                [106.9341908, 21.4302713],
                [106.9344163, 21.4305995],
                [106.9348213, 21.4311213],
                [106.9348589, 21.4314559],
                [106.934887, 21.4315282],
                [106.9349447, 21.4315832],
                [106.9350164, 21.4316103],
                [106.9350937, 21.4316094],
                [106.93609, 21.4312387],
                [106.9362268, 21.4312087],
                [106.9363625, 21.4311989],
                [106.9364306, 21.4312012],
                [106.9368222, 21.4316431],
                [106.9369075, 21.4317769],
                [106.9370009, 21.4319059],
                [106.9371022, 21.4320295],
                [106.9372112, 21.4321475],
                [106.9373297, 21.4322616],
                [106.9374554, 21.432369],
                [106.9375877, 21.4324692],
                [106.9377261, 21.4325619],
                [106.9379654, 21.4326865],
                [106.938221, 21.4327786],
                [106.9384879, 21.4328366],
                [106.9389734, 21.4329939],
                [106.9390546, 21.4330372],
                [106.9392015, 21.4331451],
                [106.9393218, 21.4332761],
                [106.9394118, 21.4334246],
                [106.9394696, 21.4335865],
                [106.9394991, 21.4337541],
                [106.9395221, 21.4338358],
                [106.9395544, 21.4339148],
                [106.9395956, 21.4339901],
                [106.9396826, 21.4341045],
                [106.939734, 21.4341559],
                [106.9399013, 21.4342901],
                [106.9399461, 21.4343407],
                [106.940015, 21.4344549],
                [106.9400956, 21.4346498],
                [106.9401621, 21.4347789],
                [106.9402376, 21.4349038],
                [106.9403215, 21.4350238],
                [106.9404137, 21.4351385],
                [106.9404914, 21.4352026],
                [106.9405854, 21.4352434],
                [106.9406365, 21.435254],
                [106.940741, 21.4352543],
                [106.9408412, 21.4352267],
                [106.940887, 21.435203],
                [106.9410132, 21.4351071],
                [106.9410926, 21.4350355],
                [106.9412347, 21.4348781],
                [106.9414059, 21.4346284],
                [106.9415353, 21.4344935],
                [106.94161, 21.4344355],
                [106.9417647, 21.4343463],
                [106.9419225, 21.4342881],
                [106.9420048, 21.4342687],
                [106.9421732, 21.4342497],
                [106.9422492, 21.4342539],
                [106.9423239, 21.4342672],
                [106.9423961, 21.4342893],
                [106.9424647, 21.43432],
                [106.9425284, 21.4343586],
                [106.9425863, 21.4344045],
                [106.9426915, 21.4344606],
                [106.9429189, 21.4345523],
                [106.94304, 21.4345867],
                [106.9431637, 21.4346124],
                [106.943289, 21.4346292],
                [106.943468, 21.4346661],
                [106.9438301, 21.4347191],
                [106.9441956, 21.4347441],
                [106.9445727, 21.4347404],
                [106.9447608, 21.4347274],
                [106.9451344, 21.4346792],
                [106.9453946, 21.4346065],
                [106.9456325, 21.4345103],
                [106.9458468, 21.4343956],
                [106.9459486, 21.4343302],
                [106.9462758, 21.4340863],
                [106.946499, 21.433906],
                [106.9469261, 21.4335257],
                [106.9470167, 21.4333941],
                [106.9470757, 21.4332478],
                [106.9470924, 21.4331711],
                [106.9470994, 21.4330135],
                [106.9470893, 21.432935],
                [106.9470426, 21.4327833],
                [106.9470066, 21.4327117],
                [106.9468754, 21.4325159],
                [106.9466299, 21.4321199],
                [106.9463398, 21.4316008],
                [106.946265, 21.4314883],
                [106.9461832, 21.4313831],
                [106.9460936, 21.4312835],
                [106.9459967, 21.43119],
                [106.9463843, 21.4312861],
                [106.9465064, 21.4312815],
                [106.9465663, 21.4312694],
                [106.9466794, 21.4312262],
                [106.9467862, 21.4311534],
                [106.9468315, 21.4311083],
                [106.9468703, 21.4310582],
                [106.9469803, 21.4308767],
                [106.9470817, 21.4307643],
                [106.9471429, 21.4307126],
                [106.9472796, 21.4306262],
                [106.9473538, 21.4305923],
                [106.9474311, 21.430565],
                [106.9478917, 21.4304397],
                [106.9479926, 21.4303887],
                [106.9480884, 21.4303298],
                [106.9481784, 21.4302634],
                [106.9482619, 21.43019],
                [106.9483953, 21.4300399],
                [106.9484521, 21.4299579],
                [106.9485439, 21.4297828],
                [106.9485784, 21.4296907],
                [106.9486684, 21.4293395],
                [106.9487232, 21.4291663],
                [106.9488428, 21.4288482],
                [106.9489109, 21.428692],
                [106.9490236, 21.4278855],
                [106.9491856, 21.4273632],
                [106.9492543, 21.427099],
                [106.9493493, 21.4266605],
                [106.9493884, 21.4264398],
                [106.9495243, 21.4263641],
                [106.9495851, 21.4263166],
                [106.9496888, 21.4262051],
                [106.949761, 21.4260845],
                [106.9498063, 21.4259529],
                [106.9498183, 21.4258846],
                [106.9498534, 21.4254064],
                [106.9498926, 21.4252813],
                [106.9499585, 21.4251664],
                [106.9500482, 21.4250666],
                [106.9504313, 21.4247652],
                [106.9507885, 21.4244374],
                [106.9511005, 21.4241047],
                [106.9512466, 21.4239307],
                [106.951518, 21.4235684],
                [106.9521725, 21.4233337],
                [106.9523352, 21.4232963],
                [106.9526649, 21.4232415],
                [106.9529986, 21.4232139],
                [106.9533315, 21.4232136],
                [106.9534977, 21.4232235],
                [106.9538277, 21.4232637],
                [106.9539911, 21.4232938],
                [106.9544068, 21.4234686],
                [106.9546161, 21.4233967],
                [106.9548333, 21.4233487],
                [106.9550471, 21.4233258],
                [106.9552624, 21.4233262],
                [106.9554341, 21.4235168],
                [106.9555302, 21.4236033],
                [106.9557358, 21.4237534],
                [106.9559563, 21.4238735],
                [106.9562433, 21.4240037],
                [106.9564097, 21.424092],
                [106.9566565, 21.4242382],
                [106.9568396, 21.4243175],
                [106.9569363, 21.4243458],
                [106.9571359, 21.4243781],
                [106.9573384, 21.4243774],
                [106.9575205, 21.4243399],
                [106.9577066, 21.4243284],
                [106.9579056, 21.4243453],
                [106.9580097, 21.4243665],
                [106.9582102, 21.4244338],
                [106.9584968, 21.4245849],
                [106.9586025, 21.4246299],
                [106.9588156, 21.4246942],
                [106.9590282, 21.424727],
                [106.9591273, 21.4247516],
                [106.9593179, 21.4248219],
                [106.9594188, 21.4248729],
                [106.9595146, 21.4249318],
                [106.9596046, 21.4249982],
                [106.9596881, 21.4250716],
                [106.9610828, 21.4264498],
                [106.9616139, 21.4265497],
                [106.9616546, 21.4265652],
                [106.9617277, 21.4266105],
                [106.9617923, 21.4266826],
                [106.9618173, 21.4267304],
                [106.9618331, 21.4267815],
                [106.961866, 21.4276283],
                [106.9619994, 21.4276958],
                [106.962255, 21.4278483],
                [106.9623767, 21.4279328],
                [106.9626063, 21.4281177],
                [106.9627201, 21.4282237],
                [106.9629295, 21.4284512],
                [106.9630247, 21.4285721],
                [106.9630866, 21.4286854],
                [106.9632324, 21.4289002],
                [106.9634056, 21.4290964],
                [106.9636061, 21.4292731],
                [106.9637149, 21.4293523],
                [106.9640374, 21.4295521],
                [106.9641998, 21.4296939],
                [106.964271, 21.4297736],
                [106.9643912, 21.4299481],
                [106.964479, 21.4301386],
                [106.9645321, 21.4303398],
                [106.9645635, 21.4304243],
                [106.9646508, 21.4305832],
                [106.9647682, 21.4307243],
                [106.964906, 21.430839],
                [106.9650635, 21.4309289],
                [106.9652358, 21.4309912],
                [106.9654173, 21.4310239],
                [106.9655836, 21.4313935],
                [106.9661039, 21.431778],
                [106.9662809, 21.4322523],
                [106.9664472, 21.4327767],
                [106.9665545, 21.4330563],
                [106.9666805, 21.4332948],
                [106.9667537, 21.433409],
                [106.9669193, 21.4336255],
                [106.967048, 21.4344545],
                [106.9677079, 21.434789],
                [106.9677201, 21.4348505],
                [106.9677653, 21.4349679],
                [106.9678366, 21.4350736],
                [106.9679237, 21.4351573],
                [106.9679735, 21.4351926],
                [106.9682412, 21.4353348],
                [106.9683945, 21.4354282],
                [106.968543, 21.4355283],
                [106.9688237, 21.4357477],
                [106.9689012, 21.4357943],
                [106.9690682, 21.435867],
                [106.9692447, 21.4359103],
                [106.9694248, 21.4359235],
                [106.9696047, 21.4359069],
                [106.9699087, 21.4358257],
                [106.97002, 21.4358068],
                [106.9702455, 21.4357933],
                [106.9704705, 21.4358126],
                [106.9706932, 21.4358654],
                [106.9709041, 21.4359503],
                [106.9710979, 21.4360652],
                [106.9712698, 21.4362071],
                [106.9713797, 21.4360763],
                [106.9715095, 21.4359623],
                [106.971664, 21.4358637],
                [106.9717511, 21.4358214],
                [106.9718416, 21.435786],
                [106.9720641, 21.4357264],
                [106.9723275, 21.4356889],
                [106.9724607, 21.4356828],
                [106.9725618, 21.4357593],
                [106.9727498, 21.4359268],
                [106.9729278, 21.4361253],
                [106.9730889, 21.4363558],
                [106.9731577, 21.4364774],
                [106.9733287, 21.4368529],
                [106.9733666, 21.4369093],
                [106.9734585, 21.437011],
                [106.9735251, 21.4370654],
                [106.9735981, 21.4371122],
                [106.9736764, 21.4371509],
                [106.9745368, 21.4374405],
                [106.9746295, 21.4374608],
                [106.9747245, 21.4374554],
                [106.9748144, 21.4374245],
                [106.9748548, 21.4374001],
                [106.9749844, 21.4372923],
                [106.9750839, 21.4372208],
                [106.9752977, 21.4371],
                [106.9754982, 21.437006],
                [106.9756622, 21.4369009],
                [106.9757384, 21.4368413],
                [106.9759823, 21.4368475],
                [106.9763231, 21.4368563],
                [106.9764961, 21.4368325],
                [106.9765806, 21.4368113],
                [106.976737, 21.4367534],
                [106.9768113, 21.4367164],
                [106.976906, 21.4367084],
                [106.9769989, 21.4367273],
                [106.977083, 21.4367726],
                [106.9771986, 21.4368915],
                [106.9772535, 21.4369366],
                [106.9773132, 21.436976],
                [106.9774443, 21.437036],
                [106.9776884, 21.437217],
                [106.9778025, 21.437316],
                [106.9780118, 21.4375281],
                [106.978195, 21.4377569],
                [106.9783522, 21.4380018],
                [106.9784206, 21.4381295],
                [106.978544, 21.4384891],
                [106.9794935, 21.4388685],
                [106.9796064, 21.4389053],
                [106.9798373, 21.4389628],
                [106.9800828, 21.4389994],
                [106.9802116, 21.4390089],
                [106.9804698, 21.4390084],
                [106.9805932, 21.4391282],
                [106.9811243, 21.4388636],
                [106.9812751, 21.4388208],
                [106.9813529, 21.4388087],
                [106.9815105, 21.4388036],
                [106.9816898, 21.4388293],
                [106.9817765, 21.4388545],
                [106.9820318, 21.4389689],
                [106.9821274, 21.4390017],
                [106.9823259, 21.4390433],
                [106.982522, 21.4390523],
                [106.982717, 21.4390305],
                [106.9828123, 21.4390081],
                [106.9836831, 21.4387038],
                [106.984756, 21.4391332],
                [106.9868025, 21.4395926],
                [106.9869248, 21.439589],
                [106.9869849, 21.4395776],
                [106.9870893, 21.4395409],
                [106.9871834, 21.4394852],
                [106.987328, 21.4393702],
                [106.9873926, 21.4393051],
                [106.9875097, 21.4391541],
                [106.98756, 21.4390682],
                [106.9876358, 21.4388861],
                [106.987713, 21.4385709],
                [106.9877574, 21.4383492],
                [106.9877788, 21.4382829],
                [106.9878405, 21.438157],
                [106.9878832, 21.4380947],
                [106.987987, 21.4379831],
                [106.9880471, 21.4379348],
                [106.9885273, 21.4377549],
                [106.9886699, 21.4376798],
                [106.9888006, 21.4375852],
                [106.9888607, 21.4375308],
                [106.9890242, 21.4373518],
                [106.9891534, 21.4372491],
                [106.9892245, 21.4372058],
                [106.9907695, 21.4364118],
                [106.9908804, 21.4363331],
                [106.9909275, 21.4362851],
                [106.9909987, 21.4361803],
                [106.9910411, 21.4360689],
                [106.9910565, 21.4359517],
                [106.9910109, 21.4348689],
                [106.9910132, 21.4346454],
                [106.991027, 21.4345343],
                [106.9910784, 21.4343203],
                [106.9911159, 21.4342163],
                [106.991187, 21.4340633],
                [106.9912148, 21.4339526],
                [106.9912088, 21.4338379],
                [106.9911931, 21.4337822],
                [106.9911374, 21.4336552],
                [106.9910914, 21.433502],
                [106.9910747, 21.433402],
                [106.991068, 21.433301],
                [106.9910713, 21.4331999],
                [106.9911298, 21.4327485],
                [106.9911504, 21.432522],
                [106.9911758, 21.4320029],
                [106.9911772, 21.431743],
                [106.9911973, 21.4316282],
                [106.9912436, 21.4315162],
                [106.9913138, 21.4314156],
                [106.9914408, 21.4312687],
                [106.9915058, 21.431155],
                [106.9915534, 21.43102],
                [106.9915864, 21.4307909],
                [106.9916237, 21.4306946],
                [106.9916552, 21.4306446],
                [106.9917378, 21.4305583],
                [106.9920355, 21.4303199],
                [106.9921911, 21.4300777],
                [106.9922745, 21.4300374],
                [106.9923198, 21.4300252],
                [106.9924293, 21.4300191],
                [106.9925516, 21.4300452],
                [106.9926174, 21.4300695],
                [106.9927382, 21.4301376],
                [106.9928561, 21.4301932],
                [106.9929778, 21.430241],
                [106.9932304, 21.4303124],
                [106.9934604, 21.4303477],
                [106.9935767, 21.4303555],
                [106.9947137, 21.4303286],
                [106.9948971, 21.430165],
                [106.9950557, 21.4299803],
                [106.9951893, 21.429773],
                [106.9952917, 21.4295509],
                [106.9953386, 21.4294694],
                [106.995394, 21.4293926],
                [106.9954572, 21.4293213],
                [106.9955277, 21.4292562],
                [106.995657, 21.4291646],
                [106.9958005, 21.4290936],
                [106.9959474, 21.4290357],
                [106.9960139, 21.4289972],
                [106.9960749, 21.4289516],
                [106.9961675, 21.4288548],
                [106.9962358, 21.4287419],
                [106.9967025, 21.4281976],
                [106.997019, 21.4275984],
                [106.9970786, 21.4275168],
                [106.9972122, 21.4273637],
                [106.997298, 21.4272815],
                [106.9974862, 21.4271339],
                [106.9975877, 21.427069],
                [106.9977915, 21.4266745],
                [106.9978709, 21.4265676],
                [106.9979197, 21.426521],
                [106.9980379, 21.4264418],
                [106.9981785, 21.426389],
                [106.9982529, 21.4263749],
                [106.9994813, 21.4262251],
                [107.0008922, 21.4251215],
                [107.0009904, 21.4249379],
                [107.0010484, 21.4248507],
                [107.0011803, 21.424688],
                [107.0013314, 21.4245422],
                [107.0014139, 21.4244761],
                [107.0027768, 21.4235431],
                [107.0028691, 21.4234707],
                [107.0029548, 21.4233915],
                [107.0030332, 21.4233061],
                [107.003104, 21.4232151],
                [107.0031667, 21.423119],
                [107.0032577, 21.4229349],
                [107.0032922, 21.4228387],
                [107.003338, 21.4226406],
                [107.0033893, 21.4221652],
                [107.0034325, 21.4220411],
                [107.0035032, 21.4219278],
                [107.003548, 21.4218768],
                [107.0037973, 21.4216561],
                [107.0038175, 21.4214871],
                [107.0037926, 21.4213081],
                [107.0034307, 21.4203842],
                [107.0030401, 21.4198192],
                [107.0026971, 21.4194542],
                [107.0019244, 21.4189986],
                [107.0003647, 21.4183546],
                [106.9978867, 21.4181558],
                [106.9968824, 21.4179597],
                [106.9945226, 21.4162815],
                [106.9934044, 21.4158786],
                [106.9929547, 21.415551],
                [106.9915346, 21.4142362],
                [106.9907946, 21.4138288],
                [106.9903874, 21.4133342],
                [106.990224, 21.4127743],
                [106.9901405, 21.4115476],
                [106.9902865, 21.4108175],
                [106.9905853, 21.4098777],
                [106.9909769, 21.409207],
                [106.9913278, 21.4088283],
                [106.9914339, 21.4084525],
                [106.9913354, 21.4077462],
                [106.9904705, 21.4063415],
                [106.9890258, 21.4048397],
                [106.9887769, 21.4045305],
                [106.9887047, 21.4041359],
                [106.9885223, 21.4038052],
                [106.988229, 21.4035174],
                [106.9858039, 21.401923],
                [106.9855549, 21.4016138],
                [106.9853921, 21.4010956],
                [106.985869, 21.3968657],
                [106.9863566, 21.3950704],
                [106.9872555, 21.392417],
                [106.9880582, 21.3908884],
                [106.9881591, 21.3901173],
                [106.9880385, 21.389432],
                [106.9878284, 21.3887062],
                [106.9877536, 21.3881037],
                [106.987878, 21.3874363],
                [106.990753, 21.3844682],
                [106.9915044, 21.3824408],
                [106.9921228, 21.3804566],
                [106.9928305, 21.3784922],
                [106.9930809, 21.3772615],
                [106.992975, 21.3760143],
                [106.9925196, 21.3752497],
                [106.9910665, 21.3731028],
                [106.9910147, 21.3725624],
                [106.9911186, 21.3720201],
                [106.991191, 21.3675037],
                [106.9908556, 21.365739],
                [106.9901891, 21.3641654],
                [106.9897562, 21.3634214],
                [106.9893058, 21.3630106],
                [106.9886336, 21.3626648],
                [106.9876914, 21.3620933],
                [106.9873263, 21.361411],
                [106.9873331, 21.3602664],
                [106.9875009, 21.3595153],
                [106.9884997, 21.3576932],
                [106.9890711, 21.3571661],
                [106.9894682, 21.3569117],
                [106.9899345, 21.3568438],
                [106.990446, 21.3568377],
                [106.9909596, 21.3569772],
                [106.9915166, 21.357033],
                [106.9923396, 21.3570234],
                [106.99467, 21.3566002],
                [106.995604, 21.3565683],
                [106.9959802, 21.3564182],
                [106.9963758, 21.3560598],
                [106.9969301, 21.3559075],
                [106.9978604, 21.3558258],
                [106.9979577, 21.355655],
                [106.9981725, 21.3553242],
                [106.9982896, 21.3551646],
                [106.9985425, 21.354858],
                [106.9986931, 21.3546958],
                [106.9990152, 21.3543894],
                [106.9991862, 21.3542457],
                [106.9993633, 21.3541086],
                [106.9994438, 21.3540605],
                [106.9995185, 21.3540049],
                [106.9995867, 21.3539424],
                [106.9996981, 21.3538037],
                [106.9997759, 21.3536515],
                [106.9998026, 21.3535708],
                [106.9998207, 21.3534881],
                [106.9998944, 21.3529295],
                [106.9999359, 21.352731],
                [107.000007, 21.3525398],
                [107.0000581, 21.3524396],
                [107.0001846, 21.3522517],
                [107.0002592, 21.3521651],
                [107.0004667, 21.351957],
                [107.0005618, 21.3518459],
                [107.0007243, 21.3516247],
                [107.0008559, 21.3514047],
                [107.0011349, 21.3508266],
                [107.0012774, 21.3506088],
                [107.0014545, 21.3504007],
                [107.0015573, 21.3503011],
                [107.0016668, 21.3502079],
                [107.0017827, 21.3501216],
                [107.0029253, 21.3501167],
                [107.003848, 21.3497969],
                [107.0040398, 21.3496582],
                [107.0041434, 21.3495989],
                [107.0043629, 21.3495021],
                [107.0045917, 21.3494367],
                [107.0047092, 21.3494156],
                [107.0049477, 21.3493972],
                [107.0054868, 21.3494322],
                [107.0061439, 21.3495596],
                [107.0076466, 21.348995],
                [107.0087242, 21.3488201],
                [107.008802, 21.348288],
                [107.0088651, 21.3480936],
                [107.0089602, 21.3479108],
                [107.0090219, 21.3478215],
                [107.0091677, 21.3476585],
                [107.0092509, 21.3475859],
                [107.009435, 21.3474611],
                [107.0098652, 21.3472465],
                [107.0102906, 21.3470239],
                [107.0110062, 21.3466255],
                [107.0113585, 21.3464178],
                [107.0117068, 21.3462045],
                [107.0131016, 21.3452852],
                [107.0136353, 21.3447006],
                [107.0138039, 21.3445766],
                [107.0138947, 21.3445231],
                [107.0140928, 21.3444326],
                [107.0141994, 21.3443967],
                [107.0159662, 21.3438862],
                [107.0172751, 21.3438662],
                [107.0174434, 21.3438494],
                [107.0176059, 21.3438057],
                [107.0177579, 21.3437363],
                [107.0178949, 21.343643],
                [107.0179563, 21.3435884],
                [107.0180626, 21.3434652],
                [107.0181066, 21.3433975],
                [107.0181441, 21.3433266],
                [107.0181435, 21.3432687],
                [107.0181208, 21.3431725],
                [107.0180824, 21.3430992],
                [107.0180452, 21.3429984],
                [107.0179886, 21.3427919],
                [107.0179587, 21.3426059],
                [107.017942, 21.3423713],
                [107.0179241, 21.3422313],
                [107.0178974, 21.3420924],
                [107.0178639, 21.3419621],
                [107.017774, 21.3417077],
                [107.0177203, 21.3415178],
                [107.0176965, 21.3413417],
                [107.0176935, 21.341253],
                [107.0177096, 21.3406734],
                [107.0175594, 21.339844],
                [107.0183694, 21.3394942],
                [107.0186147, 21.3393501],
                [107.0187304, 21.3392687],
                [107.0189461, 21.3390881],
                [107.0191443, 21.338879],
                [107.0192359, 21.3387632],
                [107.0193958, 21.3385172],
                [107.0194635, 21.3383879],
                [107.0196944, 21.3378503],
                [107.0198222, 21.337674],
                [107.0198929, 21.3375905],
                [107.0200395, 21.3374406],
                [107.0201182, 21.3373706],
                [107.0211106, 21.3368559],
                [107.0216042, 21.3368609],
                [107.0216767, 21.3368533],
                [107.0218163, 21.3368144],
                [107.0218814, 21.3367837],
                [107.0219924, 21.3367065],
                [107.0220781, 21.3366138],
                [107.0221406, 21.3365062],
                [107.0221728, 21.3361264],
                [107.0223378, 21.3359507],
                [107.0224797, 21.3357583],
                [107.0225966, 21.3355518],
                [107.0226856, 21.3353376],
                [107.0227476, 21.3351154],
                [107.0227682, 21.3350021],
                [107.0228282, 21.3345538],
                [107.0228454, 21.3343285],
                [107.0228581, 21.3340078],
                [107.0228439, 21.3338185],
                [107.0228258, 21.333725],
                [107.0227642, 21.3335339],
                [107.0227196, 21.3334378],
                [107.0226063, 21.3332571],
                [107.0224634, 21.3330956],
                [107.021918, 21.3325762],
                [107.0218465, 21.3324587],
                [107.0217966, 21.3323319],
                [107.0217802, 21.3322659],
                [107.0217651, 21.3321315],
                [107.0217758, 21.3319066],
                [107.0217953, 21.3317896],
                [107.0218241, 21.3316742],
                [107.0218481, 21.3316429],
                [107.0219073, 21.3315893],
                [107.0219801, 21.3315499],
                [107.022503, 21.3314029],
                [107.0227414, 21.3313267],
                [107.0232305, 21.3311486],
                [107.0234804, 21.3310458],
                [107.0238102, 21.3309015],
                [107.0239699, 21.3308198],
                [107.0241725, 21.3306819],
                [107.0242649, 21.3306028],
                [107.0243508, 21.3305175],
                [107.0244568, 21.3303389],
                [107.0245361, 21.3301486],
                [107.0245653, 21.3300502],
                [107.0246037, 21.3298343],
                [107.0246102, 21.329725],
                [107.0246058, 21.3291318],
                [107.0246121, 21.3290781],
                [107.0246482, 21.3289799],
                [107.0247102, 21.3288984],
                [107.0250365, 21.3287766],
                [107.0252037, 21.328726],
                [107.0255063, 21.3286537],
                [107.025851, 21.3285956],
                [107.0260352, 21.3285386],
                [107.0261395, 21.3284929],
                [107.026239, 21.3284386],
                [107.0263329, 21.3283762],
                [107.0272449, 21.3277416],
                [107.0275264, 21.3275644],
                [107.0278709, 21.3273767],
                [107.0282829, 21.3271895],
                [107.0297822, 21.3264099],
                [107.0300665, 21.3261426],
                [107.0318234, 21.325403],
                [107.0319591, 21.3253093],
                [107.0324104, 21.3252506],
                [107.0337474, 21.3251504],
                [107.0362933, 21.3257858],
                [107.0392163, 21.3263541],
                [107.0409449, 21.3274365],
                [107.0413483, 21.3276398],
                [107.0421955, 21.3277544],
                [107.042883, 21.3275795],
                [107.0434355, 21.3273022],
                [107.0437689, 21.3272773],
                [107.0441926, 21.3273346],
                [107.0447972, 21.3275979],
                [107.0459891, 21.3284993],
                [107.0465046, 21.3287638],
                [107.0475735, 21.3288131],
                [107.0504256, 21.3290699],
                [107.0514312, 21.3293698],
                [107.0518584, 21.3296769],
                [107.0520442, 21.3302367],
                [107.0519638, 21.3308622],
                [107.0517547, 21.3318224],
                [107.051635, 21.3336049],
                [107.051686, 21.3349987],
                [107.0518826, 21.3354711],
                [107.0522991, 21.335822],
                [107.0544082, 21.3371907],
                [107.0554618, 21.3378008],
                [107.0559124, 21.3383293],
                [107.0565241, 21.3391618],
                [107.0566981, 21.3396303],
                [107.0568568, 21.3398651],
                [107.0576374, 21.3403961],
                [107.0577068, 21.3405064],
                [107.0577944, 21.3407119],
                [107.0578297, 21.3420246],
                [107.0579583, 21.342738],
                [107.0581336, 21.3431491],
                [107.0582893, 21.3433697],
                [107.0590287, 21.3441234],
                [107.0590815, 21.3442658],
                [107.0591105, 21.3451236],
                [107.0592695, 21.3455825],
                [107.0596484, 21.3460069],
                [107.0610656, 21.3467285],
                [107.0620921, 21.3473992],
                [107.0624346, 21.3476492],
                [107.0630199, 21.3482777],
                [107.0632756, 21.3483858],
                [107.0638668, 21.3482355],
                [107.0649335, 21.3481429],
                [107.0684291, 21.3471704],
                [107.0692587, 21.3470966],
                [107.0698331, 21.3469465],
                [107.0702228, 21.3469418],
                [107.0705807, 21.3470803],
                [107.0711244, 21.3471689],
                [107.0717384, 21.3474315],
                [107.0720957, 21.3475225],
                [107.0736396, 21.3476068],
                [107.0741126, 21.3474896],
                [107.0748534, 21.3471309],
                [107.0753392, 21.3467118],
                [107.0758224, 21.3461178],
                [107.0761603, 21.3460342],
                [107.0769406, 21.3460722],
                [107.0774676, 21.3461769],
                [107.078316, 21.34623],
                [107.0786556, 21.3462735],
                [107.0788095, 21.3463669],
                [107.0789302, 21.3465084],
                [107.0792102, 21.3471247],
                [107.0795751, 21.3477559],
                [107.0800485, 21.3488703],
                [107.0803747, 21.3491682],
                [107.0807513, 21.3494178],
                [107.0812846, 21.3499674],
                [107.0815926, 21.3501702],
                [107.0821555, 21.3504175],
                [107.0828872, 21.3506149],
                [107.0830921, 21.3507078],
                [107.0832646, 21.3509281],
                [107.0832084, 21.3517473],
                [107.0831123, 21.3521456],
                [107.0828513, 21.352864],
                [107.0828237, 21.3533093],
                [107.0829448, 21.3534826],
                [107.0831506, 21.3536548],
                [107.0836458, 21.353903],
                [107.0839717, 21.354169],
                [107.0846952, 21.3549864],
                [107.084901, 21.3551428],
                [107.085345, 21.3553756],
                [107.0857865, 21.3554257],
                [107.0865834, 21.3554317],
                [107.0870595, 21.3555371],
                [107.0884889, 21.3559007],
                [107.0898694, 21.3564239],
                [107.0916566, 21.356926],
                [107.0929025, 21.3575064],
                [107.0943268, 21.3587122],
                [107.094672, 21.3591529],
                [107.0950539, 21.3597679],
                [107.0951787, 21.3601954],
                [107.0951667, 21.3605451],
                [107.0945731, 21.3617205],
                [107.0945427, 21.3619752],
                [107.0945839, 21.3624831],
                [107.0949298, 21.3641633],
                [107.0949759, 21.3650208],
                [107.0952426, 21.3670754],
                [107.0952678, 21.3676471],
                [107.0953593, 21.3681227],
                [107.0956033, 21.3685963],
                [107.0959218, 21.3695379],
                [107.0962486, 21.3698516],
                [107.0964703, 21.3699443],
                [107.0965908, 21.3700698],
                [107.0966437, 21.3702122],
                [107.0965691, 21.37212],
                [107.0965884, 21.3722787],
                [107.0968314, 21.3726888],
                [107.0969464, 21.3736011],
                [107.097086, 21.3738855],
                [107.0972754, 21.3740896],
                [107.0992213, 21.3750029],
                [107.0997697, 21.3754092],
                [107.10008, 21.3757709],
                [107.1020119, 21.3768829],
                [107.1029396, 21.3765375],
                [107.1036127, 21.3761795],
                [107.1046069, 21.3757538],
                [107.1055828, 21.3752331],
                [107.1060067, 21.3752278],
                [107.1064149, 21.375318],
                [107.107863, 21.3757925],
                [107.1093582, 21.37602],
                [107.1105121, 21.376085],
                [107.1108341, 21.376065],
                [107.1118633, 21.3757184],
                [107.1127007, 21.3749927],
                [107.1131553, 21.3747645],
                [107.1143536, 21.374384],
                [107.1159081, 21.3740069],
                [107.1167043, 21.373965],
                [107.1178688, 21.3735849],
                [107.1204885, 21.3718356],
                [107.1210791, 21.3716375],
                [107.1215865, 21.3715516],
                [107.1223433, 21.3711209],
                [107.1230644, 21.3705715],
                [107.123351, 21.3704567],
                [107.1241135, 21.3704311],
                [107.1244689, 21.370379],
                [107.1251602, 21.370116],
                [107.1259484, 21.369518],
                [107.1268771, 21.3692521],
                [107.1280325, 21.369428],
                [107.1290724, 21.3698201],
                [107.1306017, 21.370055],
                [107.1323571, 21.3706842],
                [107.1334601, 21.3707497],
                [107.1338342, 21.3708243],
                [107.1343127, 21.3710726],
                [107.1344825, 21.3711022],
                [107.135971, 21.3708528],
                [107.1375625, 21.3707054],
                [107.1381031, 21.3705714],
                [107.1390844, 21.3704318],
                [107.1393885, 21.3703485],
                [107.1400782, 21.3699742],
                [107.1407038, 21.3698551],
                [107.1410741, 21.3696755],
                [107.1417323, 21.3694685],
                [107.142325, 21.3694133],
                [107.1432575, 21.3694172],
                [107.1433553, 21.3691459],
                [107.142796, 21.367993],
                [107.1418934, 21.3665585],
                [107.1418721, 21.3662568],
                [107.1423096, 21.3660287],
                [107.1426302, 21.3659293],
                [107.1431367, 21.3657799],
                [107.1443032, 21.3655504],
                [107.1453325, 21.3652194],
                [107.1460069, 21.3649566],
                [107.1466121, 21.3646152],
                [107.1468469, 21.3644374],
                [107.1469821, 21.3644039],
                [107.147169, 21.3644333],
                [107.1479252, 21.3651388],
                [107.1491212, 21.365759],
                [107.149479, 21.3658815],
                [107.1501578, 21.3659285],
                [107.1507031, 21.3661122],
                [107.1521025, 21.3667458],
                [107.1525476, 21.3670419],
                [107.153946, 21.3675961],
                [107.1546784, 21.367825],
                [107.1554969, 21.3681482],
                [107.1560925, 21.3682995],
                [107.1569083, 21.36844],
                [107.157298, 21.3684191],
                [107.1577373, 21.3683181],
                [107.158106, 21.3680274],
                [107.1584219, 21.3675942],
                [107.15896, 21.3673013],
                [107.1597646, 21.3666712],
                [107.1620944, 21.3660056],
                [107.1635331, 21.3658362],
                [107.1644244, 21.365348],
                [107.1655985, 21.3644747],
                [107.1662877, 21.3640846],
                [107.1669613, 21.3637739],
                [107.1673297, 21.3634673],
                [107.1676661, 21.3632881],
                [107.1679025, 21.3632215],
                [107.168157, 21.3632341],
                [107.1688035, 21.3633847],
                [107.169398, 21.3634644],
                [107.1698891, 21.3634262],
                [107.1702797, 21.363469],
                [107.1710617, 21.3636176],
                [107.1713348, 21.3637413],
                [107.1722588, 21.3643172],
                [107.1745235, 21.3661312],
                [107.1755215, 21.3671273],
                [107.1763668, 21.3681174],
                [107.1765929, 21.368496],
                [107.1767825, 21.368716],
                [107.1773144, 21.3691381],
                [107.1792951, 21.3700817],
                [107.1799282, 21.3704787],
                [107.1815133, 21.371046],
                [107.1821929, 21.3711326],
                [107.1826845, 21.3711261],
                [107.183077, 21.3712958],
                [107.1833371, 21.3716897],
                [107.1833913, 21.3719114],
                [107.1834352, 21.3725942],
                [107.1835245, 21.3728949],
                [107.1844053, 21.3739799],
                [107.1847834, 21.3743245],
                [107.1850786, 21.3747895],
                [107.185819, 21.3755585],
                [107.186059, 21.3757301],
                [107.1862465, 21.3758071],
                [107.1870935, 21.3757484],
                [107.1898398, 21.3757286],
                [107.1908392, 21.3756678],
                [107.191853, 21.3754242],
                [107.191989, 21.3754542],
                [107.1923058, 21.3762446],
                [107.1930859, 21.3773785],
                [107.1931591, 21.377743],
                [107.1931712, 21.3785532],
                [107.1932425, 21.3787906],
                [107.1934481, 21.378931],
                [107.1941284, 21.379081],
                [107.1950243, 21.3788945],
                [107.1966907, 21.3780781],
                [107.1975376, 21.3780195],
                [107.1993119, 21.3776228],
                [107.1995161, 21.3776678],
                [107.1998746, 21.3778379],
                [107.2005377, 21.3779563],
                [107.2010665, 21.3781719],
                [107.2020067, 21.3786839],
                [107.2029412, 21.3788146],
                [107.2032625, 21.3787627],
                [107.2037402, 21.378963],
                [107.2042097, 21.3797355],
                [107.2045189, 21.3800016],
                [107.2049673, 21.380512],
                [107.2063783, 21.3818918],
                [107.2068267, 21.3823944],
                [107.207075, 21.3831221],
                [107.2073532, 21.3835792],
                [107.2075988, 21.3841322],
                [107.209159, 21.3864077],
                [107.2093658, 21.3866275],
                [107.2095876, 21.38672],
                [107.2100809, 21.3868246],
                [107.2104879, 21.3868352],
                [107.2115978, 21.3873449],
                [107.2135153, 21.3874468],
                [107.2142629, 21.3875481],
                [107.2146376, 21.3876624],
                [107.2154233, 21.3880334],
                [107.2161441, 21.3886118],
                [107.216557, 21.3890035],
                [107.2168261, 21.388857],
                [107.2172274, 21.3884862],
                [107.2186264, 21.3868151],
                [107.2191784, 21.3863153],
                [107.2201533, 21.3857463],
                [107.2205752, 21.3856136],
                [107.2211677, 21.3855501],
                [107.2219475, 21.3855398],
                [107.2224538, 21.3853742],
                [107.2227544, 21.3850841],
                [107.2231207, 21.3846345],
                [107.2244005, 21.3829331],
                [107.2254338, 21.3817436],
                [107.2260856, 21.3811232],
                [107.2273852, 21.3807245],
                [107.2279221, 21.3803519],
                [107.2281213, 21.3800792],
                [107.2281696, 21.3799037],
                [107.2281808, 21.3795222],
                [107.228089, 21.3790627],
                [107.2279658, 21.3787625],
                [107.2275664, 21.3781481],
                [107.227374, 21.3777533],
                [107.2275224, 21.3774733],
                [107.2290524, 21.3766267],
                [107.230032, 21.3763753],
                [107.2306543, 21.3760493],
                [107.2312727, 21.3754531],
                [107.2315084, 21.3753388],
                [107.2326256, 21.3752286],
                [107.233468, 21.3748836],
                [107.2345761, 21.3741619],
                [107.2349292, 21.3739665],
                [107.2352495, 21.373851],
                [107.2355178, 21.3736567],
                [107.2355692, 21.3725755],
                [107.2354722, 21.3717664],
                [107.235465, 21.3712898],
                [107.2354953, 21.3710511],
                [107.2356896, 21.3704526],
                [107.2360596, 21.3702571],
                [107.2368897, 21.3702143],
                [107.2372616, 21.3701457],
                [107.2375471, 21.369967],
                [107.2383661, 21.3691935],
                [107.2385648, 21.3688889],
                [107.2391896, 21.3676094],
                [107.239737, 21.3668076],
                [107.2406909, 21.3648801],
                [107.2407273, 21.3643184],
                [107.2412306, 21.3643227],
                [107.2422861, 21.3652521],
                [107.2434059, 21.3658552],
                [107.2437568, 21.3661108],
                [107.2447168, 21.366472],
                [107.2459235, 21.367074],
                [107.2466199, 21.3672273],
                [107.2486451, 21.367443],
                [107.2493959, 21.3673702],
                [107.2499517, 21.3672721],
                [107.2504719, 21.3671057],
                [107.2510825, 21.3670093],
                [107.2513165, 21.3669179],
                [107.2521862, 21.3663327],
                [107.2525157, 21.3663283],
                [107.25269, 21.366429],
                [107.252864, 21.3665296],
                [107.2537869, 21.36737],
                [107.2545561, 21.3684184],
                [107.2552262, 21.3691447],
                [107.2557305, 21.3703143],
                [107.2558423, 21.3711525],
                [107.2570189, 21.3729718],
                [107.257786, 21.3738791],
                [107.2582686, 21.374649],
                [107.2587544, 21.3762395],
                [107.2588898, 21.3777023],
                [107.2591499, 21.3787045],
                [107.2598812, 21.3797181],
                [107.2590585, 21.3819438],
                [107.2588203, 21.3823706],
                [107.2588873, 21.3826668],
                [107.2594015, 21.3843623],
                [107.2599562, 21.3851679],
                [107.2610834, 21.3861692],
                [107.2613883, 21.386597],
                [107.2614538, 21.3873331],
                [107.2613157, 21.3889358],
                [107.2609658, 21.3917484],
                [107.2610322, 21.3925352],
                [107.2618246, 21.3946843],
                [107.2627889, 21.3956622],
                [107.2636203, 21.3968199],
                [107.2649565, 21.3999401],
                [107.2649624, 21.4003211],
                [107.2646936, 21.4004772],
                [107.2635563, 21.4005942],
                [107.2619342, 21.400921],
                [107.2613996, 21.4014364],
                [107.2607296, 21.4019537],
                [107.2602709, 21.4021123],
                [107.2592878, 21.4025448],
                [107.2586181, 21.4030875],
                [107.2580305, 21.4036799],
                [107.257797, 21.404369],
                [107.257698, 21.4049802],
                [107.2582919, 21.4065731],
                [107.2582995, 21.4070558],
                [107.258036, 21.4075675],
                [107.2573792, 21.4080719],
                [107.2568479, 21.4087905],
                [107.2566172, 21.4096576],
                [107.256343, 21.4112367],
                [107.2561538, 21.4130434],
                [107.2561646, 21.4146314],
                [107.2565269, 21.4152618],
                [107.2571085, 21.4160416],
                [107.2571431, 21.416524],
                [107.2567728, 21.4171388],
                [107.2560626, 21.4185714],
                [107.2539692, 21.4218012],
                [107.2530879, 21.4227025],
                [107.2523856, 21.4228279],
                [107.2517144, 21.4228879],
                [107.2502817, 21.4228982],
                [107.2491194, 21.4230582],
                [107.2485802, 21.4233625],
                [107.2482825, 21.4236495],
                [107.2474921, 21.4253152],
                [107.2475422, 21.4256259],
                [107.2476684, 21.4259778],
                [107.2478987, 21.4272015],
                [107.2477191, 21.4282938],
                [107.2478596, 21.42958],
                [107.2477098, 21.4296669],
                [107.2474682, 21.4296702],
                [107.2464976, 21.4294425],
                [107.2463315, 21.4294448],
                [107.2461065, 21.4295469],
                [107.2459735, 21.4297468],
                [107.2458184, 21.4304638],
                [107.2456593, 21.4309329],
                [107.2446401, 21.4324612],
                [107.2442528, 21.4328202],
                [107.2436812, 21.4329836],
                [107.2427216, 21.4334707],
                [107.2422732, 21.4337881],
                [107.2418871, 21.4342321],
                [107.241814, 21.4343888],
                [107.2418065, 21.4348843],
                [107.241993, 21.4352215],
                [107.2425922, 21.4358787],
                [107.2430908, 21.4368629],
                [107.243754, 21.4377387],
                [107.2443062, 21.4382833],
                [107.2448867, 21.4387002],
                [107.2451946, 21.4390642],
                [107.2452884, 21.4392752],
                [107.2453119, 21.4398269],
                [107.2451966, 21.4401823],
                [107.2452375, 21.4408824],
                [107.2454843, 21.4412188],
                [107.2455785, 21.4414441],
                [107.2456927, 21.4419946],
                [107.2455827, 21.4427038],
                [107.245549, 21.4434686],
                [107.245401, 21.4436688],
                [107.245042, 21.4439071],
                [107.2445828, 21.4445078],
                [107.2443731, 21.4446239],
                [107.244012, 21.4447278],
                [107.2416987, 21.4456222],
                [107.241474, 21.4457526],
                [107.2410426, 21.4461972],
                [107.2405841, 21.4468544],
                [107.2405121, 21.4470819],
                [107.2405484, 21.4474848],
                [107.2410476, 21.4485114],
                [107.2416225, 21.4495371],
                [107.2417557, 21.4503422],
                [107.2417007, 21.4506967],
                [107.2414818, 21.4512022],
                [107.2412295, 21.4515028],
                [107.2407072, 21.4519344],
                [107.2405589, 21.4521204],
                [107.2404278, 21.4524478],
                [107.2404169, 21.4527169],
                [107.2404804, 21.4529142],
                [107.2409153, 21.4536869],
                [107.2410344, 21.4545558],
                [107.241028, 21.4551221],
                [107.2408552, 21.4556906],
                [107.2405061, 21.4565729],
                [107.2403295, 21.4568866],
                [107.2398107, 21.4575447],
                [107.2393786, 21.4579398],
                [107.2386959, 21.4587557],
                [107.2384739, 21.4590701],
                [107.2383719, 21.459312],
                [107.2385041, 21.4600605],
                [107.2385604, 21.4607675],
                [107.2386572, 21.4611767],
                [107.2389816, 21.4616324],
                [107.2393177, 21.4618686],
                [107.2395941, 21.4621621],
                [107.2397798, 21.4624428],
                [107.240044, 21.4629204],
                [107.2401231, 21.4631459],
                [107.2401746, 21.4635557],
                [107.2405684, 21.4645978],
                [107.2411805, 21.4650921],
                [107.2419251, 21.4653653],
                [107.2420325, 21.4654629],
                [107.242127, 21.4657165],
                [107.242131, 21.4659712],
                [107.24198, 21.4669782],
                [107.2419886, 21.468528],
                [107.2418026, 21.46921],
                [107.2414948, 21.4698369],
                [107.2411642, 21.4709454],
                [107.2409295, 21.4714016],
                [107.2404131, 21.4722224],
                [107.2399964, 21.4726527],
                [107.2397423, 21.47284],
                [107.2397132, 21.4729111],
                [107.2397448, 21.4729957],
                [107.2399902, 21.473233],
                [107.2407237, 21.4737611],
                [107.2409394, 21.4740271],
                [107.2412989, 21.4748008],
                [107.2415764, 21.4751581],
                [107.2418059, 21.475339],
                [107.2424592, 21.4755709],
                [107.2434808, 21.4761517],
                [107.2439559, 21.4765841],
                [107.2440643, 21.4767525],
                [107.244617, 21.4773113],
                [107.2448948, 21.4776898],
                [107.2451904, 21.4782378],
                [107.2458051, 21.4808695],
                [107.2463381, 21.482115],
                [107.2463874, 21.4823691],
                [107.2461975, 21.4827963],
                [107.2459381, 21.4836209],
                [107.2457448, 21.4838357],
                [107.2451465, 21.4842401],
                [107.2445073, 21.4849422],
                [107.2439167, 21.485849],
                [107.2430418, 21.4869506],
                [107.2424715, 21.4882039],
                [107.2418788, 21.4889763],
                [107.24148, 21.4895832],
                [107.2402982, 21.4903917],
                [107.2395818, 21.4909817],
                [107.2384534, 21.4923131],
                [107.2382315, 21.4926276],
                [107.2376879, 21.493661],
                [107.2374255, 21.4942874],
                [107.2372044, 21.4946583],
                [107.2367669, 21.4956975],
                [107.2365462, 21.4960968],
                [107.2364797, 21.4966851],
                [107.2364865, 21.497138],
                [107.236731, 21.4982954],
                [107.2367135, 21.4991308],
                [107.2365674, 21.4998066],
                [107.2366264, 21.5008133],
                [107.236446, 21.5015903],
                [107.2360536, 21.5023519],
                [107.2360572, 21.502586],
                [107.2361957, 21.5028363],
                [107.2367027, 21.5032978],
                [107.2375667, 21.5044568],
                [107.2382559, 21.5054921],
                [107.2383359, 21.5056892],
                [107.2384422, 21.5064021],
                [107.2382772, 21.5069267],
                [107.2377908, 21.5078157],
                [107.237303, 21.5086327],
                [107.2369281, 21.509268],
                [107.2367078, 21.5099554],
                [107.236611, 21.5109905],
                [107.2364262, 21.5114792],
                [107.2360707, 21.5121324],
                [107.2356951, 21.5127316],
                [107.2352018, 21.5131706],
                [107.2345748, 21.5136832],
                [107.2341205, 21.5141576],
                [107.2337426, 21.5145948],
                [107.2335007, 21.5151384],
                [107.2334356, 21.5159137],
                [107.2333705, 21.5166078],
                [107.2334958, 21.5172545],
                [107.2338749, 21.5181499],
                [107.2340573, 21.5187599],
                [107.2338162, 21.5193395],
                [107.2333602, 21.5197057],
                [107.2328277, 21.5201091],
                [107.2324683, 21.5205102],
                [107.2319756, 21.521003],
                [107.2315237, 21.5216394],
                [107.2311067, 21.5220412],
                [107.2295727, 21.5208301],
                [107.2283938, 21.5204678],
                [107.2273108, 21.520086],
                [107.2263848, 21.5199182],
                [107.2250958, 21.5198994],
                [107.223409, 21.5197776],
                [107.2229663, 21.5197654],
                [107.2227781, 21.5200382],
                [107.2226739, 21.520778],
                [107.222672, 21.5219127],
                [107.2225155, 21.5230133],
                [107.2224537, 21.5240047],
                [107.2222413, 21.5252143],
                [107.2220036, 21.5260278],
                [107.2215926, 21.526833],
                [107.2210445, 21.5274707],
                [107.2203424, 21.5281103],
                [107.2196406, 21.5287681],
                [107.2186107, 21.5293581],
                [107.2181762, 21.529594],
                [107.2179138, 21.5300477],
                [107.2175756, 21.5305745],
                [107.2173161, 21.5312263],
                [107.217016, 21.5317346],
                [107.216675, 21.5320813],
                [107.2161372, 21.5321425],
                [107.215405, 21.5320622],
                [107.214271, 21.5321313],
                [107.2138552, 21.5321537],
                [107.2133176, 21.532215],
                [107.2129496, 21.5320577],
                [107.2125962, 21.5315941],
                [107.2122839, 21.5312921],
                [107.212033, 21.5312414],
                [107.2118215, 21.5312442],
                [107.2116297, 21.5312828],
                [107.2114203, 21.5314297],
                [107.2112125, 21.5316846],
                [107.2109688, 21.5318569],
                [107.2104953, 21.5325946],
                [107.2100451, 21.533357],
                [107.2096345, 21.5341909],
                [107.2090134, 21.5350996],
                [107.208695, 21.5355511],
                [107.2082016, 21.5360079],
                [107.2074274, 21.5366192],
                [107.2070484, 21.5370025],
                [107.2068256, 21.5375276],
                [107.2065652, 21.5381254],
                [107.2063808, 21.5386502],
                [107.2060593, 21.5390147],
                [107.2054528, 21.5396171],
                [107.2049207, 21.5400563],
                [107.2045996, 21.5404568],
                [107.2044719, 21.5409087],
                [107.2043825, 21.5413601],
                [107.2043315, 21.5418112],
                [107.2043358, 21.5420993],
                [107.2043582, 21.5422971],
                [107.2044567, 21.5424579],
                [107.2046128, 21.5425999],
                [107.2048461, 21.5427588],
                [107.205214, 21.5429161],
                [107.2055827, 21.5431274],
                [107.2058756, 21.5434116],
                [107.2061512, 21.5438223],
                [107.2063679, 21.5441615],
                [107.2066443, 21.5446262],
                [107.2071327, 21.5451526],
                [107.207636, 21.5453621],
                [107.2085246, 21.5456024],
                [107.2090638, 21.5456312],
                [107.2096794, 21.5456232],
                [107.209988, 21.5456731],
                [107.2103176, 21.5458487],
                [107.2104982, 21.5463327],
                [107.2107532, 21.5466535],
                [107.2110439, 21.5467937],
                [107.2115635, 21.5468048],
                [107.2120444, 21.5467984],
                [107.2125453, 21.5468459],
                [107.2129713, 21.5470202],
                [107.2132827, 21.5472503],
                [107.2135184, 21.5475713],
                [107.2136978, 21.5479832],
                [107.2139165, 21.5484486],
                [107.2140585, 21.5489329],
                [107.2143396, 21.5497492],
                [107.2147122, 21.5502124],
                [107.2151058, 21.5507836],
                [107.2152063, 21.5510703],
                [107.2153652, 21.5513925],
                [107.2155831, 21.5519236],
                [107.2158949, 21.5521896],
                [107.2165704, 21.5523246],
                [107.2176896, 21.5525439],
                [107.2185388, 21.5527128],
                [107.219484, 21.5530947],
                [107.2203919, 21.5533347],
                [107.2210134, 21.5537047],
                [107.2213621, 21.5538621],
                [107.221785, 21.5538385],
                [107.2223958, 21.5535241],
                [107.2231529, 21.5531179],
                [107.2240487, 21.5525657],
                [107.2245994, 21.5520901],
                [107.2251911, 21.5517759],
                [107.2259212, 21.5517122],
                [107.2269059, 21.5519331],
                [107.228256, 21.5521492],
                [107.2290627, 21.5524773],
                [107.2298624, 21.5531871],
                [107.2306033, 21.5538255],
                [107.2314395, 21.5544087],
                [107.2320021, 21.5547073],
                [107.2324281, 21.5548817],
                [107.2328518, 21.5549121],
                [107.2331406, 21.5549263],
                [107.2334806, 21.5545074],
                [107.2339529, 21.5539428],
                [107.2344838, 21.5534313],
                [107.2350465, 21.5529389],
                [107.2357525, 21.5525511],
                [107.2367914, 21.5525552],
                [107.2376379, 21.5525438],
                [107.2382133, 21.5524281],
                [107.2388826, 21.5521668],
                [107.2395317, 21.551834],
                [107.242026, 21.5507595],
                [107.2433252, 21.5501657],
                [107.2443968, 21.549791],
                [107.2450664, 21.5495479],
                [107.2456781, 21.5492875],
                [107.246059, 21.5490302],
                [107.2463241, 21.5489133],
                [107.2471005, 21.5492671],
                [107.2476729, 21.5506592],
                [107.2482145, 21.5526043],
                [107.2482754, 21.5540031],
                [107.2486882, 21.55525],
                [107.2494944, 21.5564916],
                [107.250371, 21.5573154],
                [107.2511941, 21.5575605],
                [107.252142, 21.558138],
                [107.2531824, 21.5591772],
                [107.2547057, 21.5605288],
                [107.2561918, 21.5616895],
                [107.2582594, 21.5629061],
                [107.2597184, 21.5634129],
                [107.2605054, 21.5635299],
                [107.2619388, 21.5634786],
                [107.2629278, 21.5634012],
                [107.2637165, 21.5636139],
                [107.2656016, 21.5640671],
                [107.2666252, 21.5640212],
                [107.2686873, 21.5637856],
                [107.2694339, 21.5634882],
                [107.271169, 21.5630817],
                [107.2722866, 21.5624919],
                [107.2731038, 21.5623532],
                [107.27413, 21.5624668],
                [107.2751937, 21.5628033],
                [107.2759825, 21.5630159],
                [107.2770105, 21.5632571],
                [107.2778205, 21.5637407],
                [107.27872, 21.5644943],
                [107.2793049, 21.5647735],
                [107.2799274, 21.5652756],
                [107.2805172, 21.5658739],
                [107.2809754, 21.5667931],
                [107.2814969, 21.5673923],
                [107.2822576, 21.5679882],
                [107.2830508, 21.5684879],
                [107.2834883, 21.5691682],
                [107.2835971, 21.5695815],
                [107.2835364, 21.5700611],
                [107.2833043, 21.570511],
                [107.2830779, 21.5713119],
                [107.2830506, 21.5726454],
                [107.283232, 21.5744149],
                [107.2832094, 21.5751494],
                [107.2832918, 21.5760422],
                [107.2833716, 21.5767755],
                [107.2833183, 21.577734],
                [107.2837025, 21.5782715],
                [107.2841851, 21.5785521],
                [107.2848411, 21.5789901],
                [107.2852569, 21.5793675],
                [107.2856387, 21.5797454],
                [107.2860892, 21.5801543],
                [107.2865872, 21.580323],
                [107.2874466, 21.5806624],
                [107.2881654, 21.5807483],
                [107.2888515, 21.5809303],
                [107.2892664, 21.581244],
                [107.2895818, 21.5817504],
                [107.2897275, 21.5823231],
                [107.2897726, 21.5830248],
                [107.2897842, 21.583759],
                [107.2898003, 21.5847804],
                [107.2903802, 21.58581],
                [107.2907373, 21.5867948],
                [107.2911272, 21.5876833],
                [107.2916856, 21.5884419],
                [107.2923492, 21.5893586],
                [107.2924642, 21.5901551],
                [107.2925753, 21.5906963],
                [107.2926073, 21.5916378],
                [107.2936742, 21.5921338],
                [107.2948772, 21.5925961],
                [107.295566, 21.5929378],
                [107.2964931, 21.5932442],
                [107.2973527, 21.5935835],
                [107.2981745, 21.5936999],
                [107.2993028, 21.5937482],
                [107.3015791, 21.59402],
                [107.3027801, 21.5943546],
                [107.3039807, 21.5946573],
                [107.304907, 21.5948999],
                [107.3057987, 21.5951109],
                [107.3069988, 21.5953816],
                [107.3086154, 21.5960616],
                [107.3097803, 21.5962689],
                [107.3103626, 21.595287],
                [107.3105196, 21.5944229],
                [107.3105596, 21.5926345],
                [107.3107218, 21.5920895],
                [107.3119408, 21.5914021],
                [107.3126511, 21.5909454],
                [107.3137421, 21.5897329],
                [107.3150914, 21.5907997],
                [107.3172378, 21.5925576],
                [107.3198876, 21.5937657],
                [107.3209128, 21.5937834],
                [107.3222445, 21.5937328],
                [107.323232, 21.5935275],
                [107.3237546, 21.5930891],
                [107.324663, 21.5922146],
                [107.3251723, 21.5920159],
                [107.3261964, 21.5919696],
                [107.3271187, 21.5919567],
                [107.3274252, 21.5918886],
                [107.3276617, 21.5917257],
                [107.3279609, 21.5912107],
                [107.3284585, 21.5902778],
                [107.3286466, 21.5892218],
                [107.3286338, 21.5884238],
                [107.3308142, 21.5869667],
                [107.3334111, 21.587849],
                [107.3361209, 21.5870474],
                [107.337372, 21.5874178],
                [107.3405814, 21.5875406],
                [107.3400983, 21.5892962],
                [107.3433795, 21.5899266],
                [107.3438422, 21.5920557],
                [107.348039, 21.5945514],
                [107.3541483, 21.5941305],
                [107.3596553, 21.5950915],
                [107.3614883, 21.5987542],
                [107.3620891, 21.5996819],
                [107.3617727, 21.6001657],
                [107.3615956, 21.6007593],
                [107.3610592, 21.6016371],
                [107.3605388, 21.6025548],
                [107.3587954, 21.6045997],
                [107.357685, 21.6054975],
                [107.3570788, 21.6063952],
                [107.3570409, 21.606598],
                [107.3570621, 21.6067958],
                [107.3572612, 21.6069937],
                [107.3586237, 21.6074676],
                [107.359237, 21.607887],
                [107.3595786, 21.6087593],
                [107.3606837, 21.6104849],
                [107.3611128, 21.612031],
                [107.3603511, 21.6131107],
                [107.3590314, 21.6144748],
                [107.3592567, 21.6150632],
                [107.3599219, 21.6157914],
                [107.360225, 21.616592],
                [107.3601365, 21.617497],
                [107.3598254, 21.6186241],
                [107.3595571, 21.6195018],
                [107.359484, 21.620421],
                [107.3594069, 21.6212373],
                [107.3594713, 21.6215664],
                [107.3583877, 21.6220252],
                [107.3569929, 21.622474],
                [107.3562419, 21.622883],
                [107.3555553, 21.6238604],
                [107.3548365, 21.6259948],
                [107.354815, 21.6277701],
                [107.354845, 21.628625],
                [107.3546541, 21.6296451],
                [107.3543, 21.6309815],
                [107.3553139, 21.6316996],
                [107.3567676, 21.6324276],
                [107.3580229, 21.6331058],
                [107.3592245, 21.6341928],
                [107.3604262, 21.6344821],
                [107.3610377, 21.6346815],
                [107.3618692, 21.6353298],
                [107.3622715, 21.6359182],
                [107.3623252, 21.6364318],
                [107.362544, 21.636919],
                [107.3627758, 21.6380075],
                [107.3630279, 21.6388651],
                [107.3639667, 21.6396829],
                [107.3640954, 21.6401167],
                [107.362002, 21.642616],
                [107.360373, 21.646217],
                [107.360323, 21.646764],
                [107.360373, 21.64722],
                [107.3605764, 21.6481495],
                [107.360916, 21.648906],
                [107.3609626, 21.6502785],
                [107.360718, 21.651276],
                [107.360422, 21.651732],
                [107.3596698, 21.6526717],
                [107.358991, 21.653555],
                [107.358793, 21.655834],
                [107.358941, 21.656927],
                [107.359287, 21.658249],
                [107.3591387, 21.6591981],
                [107.3590207, 21.6598014],
                [107.3588651, 21.6603797],
                [107.3588169, 21.6611475],
                [107.3597342, 21.6618654],
                [107.3606622, 21.6628725],
                [107.3604691, 21.6635605],
                [107.3601633, 21.6640939],
                [107.3599112, 21.6646573],
                [107.3586345, 21.6654251],
                [107.3569661, 21.6659037],
                [107.3563009, 21.6662876],
                [107.3560703, 21.6666465],
                [107.356891, 21.6677533],
                [107.3570305, 21.6683914],
                [107.357164, 21.669051],
                [107.356918, 21.669598],
                [107.3565584, 21.6700615],
                [107.354697, 21.6709838],
                [107.3538333, 21.6712031],
                [107.3532432, 21.671931],
                [107.3525566, 21.6726638],
                [107.3518753, 21.6734514],
                [107.3505878, 21.6738702],
                [107.3496652, 21.6741394],
                [107.3489463, 21.6745581],
                [107.3468918, 21.6747077],
                [107.345674, 21.6758543],
                [107.3450947, 21.6769709],
                [107.3447513, 21.6780677],
                [107.3468542, 21.6790846],
                [107.3480558, 21.6795432],
                [107.3486245, 21.6804305],
                [107.348576, 21.68122],
                [107.348428, 21.681721],
                [107.3482704, 21.6826538],
                [107.3475837, 21.6839698],
                [107.3462319, 21.6850266],
                [107.3449659, 21.6858441],
                [107.3443222, 21.6874791],
                [107.34429, 21.6883862],
                [107.3420477, 21.6882218],
                [107.3406744, 21.6878379],
                [107.3397839, 21.6878379],
                [107.3390543, 21.6878579],
                [107.3385823, 21.6881869],
                [107.3381638, 21.6886455],
                [107.3377347, 21.6893234],
                [107.3373699, 21.6900511],
                [107.3370159, 21.6906393],
                [107.336576, 21.691397],
                [107.3362005, 21.6919054],
                [107.3356426, 21.6932213],
                [107.3353636, 21.6949659],
                [107.3350525, 21.6969596],
                [107.3350203, 21.6979565],
                [107.3350418, 21.6988636],
                [107.3346233, 21.6993521],
                [107.3333251, 21.7001695],
                [107.331351, 21.701286],
                [107.3302245, 21.7021034],
                [107.3290229, 21.7032298],
                [107.3276818, 21.7043761],
                [107.3272419, 21.7050739],
                [107.3265982, 21.705373],
                [107.325654, 21.7051836],
                [107.3248708, 21.7056421],
                [107.3243451, 21.706295],
                [107.3240018, 21.7072769],
                [107.3241091, 21.7093502],
                [107.3241413, 21.7101177],
                [107.3225641, 21.7110049],
                [107.3215449, 21.7124302],
                [107.3208475, 21.7135665],
                [107.3199355, 21.7149221],
                [107.3198, 21.716997],
                [107.320442, 21.718182],
                [107.3210031, 21.7191283],
                [107.3209548, 21.7199506],
                [107.321105, 21.721665],
                [107.3220223, 21.7231451],
                [107.322169, 21.724517],
                [107.3217648, 21.7250437],
                [107.3206973, 21.725253],
                [107.3195279, 21.7258162],
                [107.3180258, 21.7259059],
                [107.3168456, 21.7255271],
                [107.3154724, 21.7255172],
                [107.3144424, 21.7256766],
                [107.3139918, 21.7258062],
                [107.313581, 21.726249],
                [107.3134392, 21.7275404],
                [107.3133159, 21.7283228],
                [107.313285, 21.72912],
                [107.3133695, 21.7296134],
                [107.3134124, 21.7303659],
                [107.3122859, 21.7305303],
                [107.3102581, 21.7302912],
                [107.3090029, 21.7303211],
                [107.3084021, 21.7304008],
                [107.307855, 21.730579],
                [107.307609, 21.730989],
                [107.306671, 21.731445],
                [107.306128, 21.731992],
                [107.3060202, 21.7339687],
                [107.305634, 21.735866],
                [107.305486, 21.73664],
                [107.304943, 21.73778],
                [107.3045933, 21.7390314],
                [107.3036063, 21.7404864],
                [107.3025978, 21.7413734],
                [107.3018467, 21.742071],
                [107.3010528, 21.742649],
                [107.29954, 21.7428383],
                [107.2979736, 21.7427187],
                [107.2961604, 21.7426789],
                [107.2957635, 21.7422105],
                [107.29522, 21.741153],
                [107.2944331, 21.7404466],
                [107.2938752, 21.7400778],
                [107.293245, 21.739466],
                [107.292949, 21.739102],
                [107.292209, 21.738509],
                [107.291666, 21.738008],
                [107.29058, 21.73778],
                [107.289494, 21.737187],
                [107.287964, 21.736595],
                [107.2878992, 21.7359221],
                [107.288264, 21.7348657],
                [107.2884142, 21.7340086],
                [107.288146, 21.7329322],
                [107.2875452, 21.7323143],
                [107.287116, 21.7321349],
                [107.2852385, 21.7326731],
                [107.2831893, 21.7326033],
                [107.2818804, 21.7322645],
                [107.2808611, 21.734178],
                [107.2801316, 21.7346564],
                [107.279284, 21.7345966],
                [107.2779536, 21.7335003],
                [107.2766232, 21.732703],
                [107.2751212, 21.7326731],
                [107.274853, 21.7320552],
                [107.2746599, 21.7314074],
                [107.2740376, 21.731228],
                [107.2733402, 21.731218],
                [107.272557, 21.7309589],
                [107.2712481, 21.7312978],
                [107.2699928, 21.731228],
                [107.2683191, 21.7305303],
                [107.266624, 21.7295237],
                [107.265555, 21.729029],
                [107.264716, 21.729075],
                [107.264025, 21.728847],
                [107.263729, 21.728391],
                [107.263532, 21.727844],
                [107.26363, 21.726705],
                [107.263236, 21.726249],
                [107.2628045, 21.7256966],
                [107.2624504, 21.7253677],
                [107.2619462, 21.7250388],
                [107.2612274, 21.7245006],
                [107.259978, 21.724153],
                [107.258596, 21.724153],
                [107.257757, 21.723742],
                [107.257164, 21.723241],
                [107.256424, 21.722147],
                [107.255536, 21.721509],
                [107.254499, 21.72037],
                [107.254252, 21.719549],
                [107.254302, 21.71882],
                [107.25445, 21.718455],
                [107.254302, 21.717909],
                [107.253808, 21.71759],
                [107.253265, 21.717681],
                [107.252475, 21.717954],
                [107.251636, 21.717635],
                [107.250896, 21.717134],
                [107.250007, 21.716405],
                [107.249721, 21.715959],
                [107.249769, 21.715426],
                [107.249865, 21.715027],
                [107.2499835, 21.712809],
                [107.2495329, 21.7113238],
                [107.2491145, 21.7099383],
                [107.24852, 21.70855],
                [107.2485673, 21.7078849],
                [107.249034, 21.7056571],
                [107.2486961, 21.7049742],
                [107.2480068, 21.7043911],
                [107.2466791, 21.7048746],
                [107.2453594, 21.7057717],
                [107.2430205, 21.7054427],
                [107.2419584, 21.7052533],
                [107.2410035, 21.7048945],
                [107.2398448, 21.705363],
                [107.2385573, 21.7062203],
                [107.2379458, 21.7071872],
                [107.2371304, 21.7076158],
                [107.235682, 21.7071872],
                [107.2351348, 21.7071373],
                [107.2342229, 21.7071872],
                [107.2335362, 21.7066888],
                [107.2329032, 21.7067286],
                [107.2320986, 21.7074563],
                [107.2305536, 21.7081341],
                [107.2298026, 21.7081341],
                [107.2277856, 21.7072868],
                [107.2268307, 21.7074962],
                [107.2258222, 21.7078251],
                [107.2244275, 21.7080544],
                [107.2234297, 21.7081341],
                [107.2224426, 21.7082338],
                [107.2221208, 21.7077753],
                [107.2208011, 21.7082936],
                [107.2192347, 21.7091907],
                [107.2170782, 21.7101476],
                [107.2155011, 21.7120614],
                [107.21475, 21.7129785],
                [107.2138917, 21.7134968],
                [107.212615, 21.7142144],
                [107.2108877, 21.7140649],
                [107.2093642, 21.7144636],
                [107.2080016, 21.7150517],
                [107.2066712, 21.7153707],
                [107.20552, 21.71587],
                [107.203576, 21.7180319],
                [107.2022617, 21.7187097],
                [107.2000837, 21.7200453],
                [107.1981954, 21.7212912],
                [107.1970796, 21.7212713],
                [107.1964091, 21.721361],
                [107.1961731, 21.7214905],
                [107.196334, 21.7219739],
                [107.1964842, 21.7225122],
                [107.1971333, 21.7232447],
                [107.1982384, 21.7240321],
                [107.1985602, 21.7247796],
                [107.1990162, 21.7251035],
                [107.1990108, 21.7266235],
                [107.1987963, 21.7277098],
                [107.1987963, 21.7284872],
                [107.1983778, 21.7291151],
                [107.1978307, 21.7299324],
                [107.1979916, 21.7307297],
                [107.1980345, 21.7317761],
                [107.1980667, 21.7328326],
                [107.1970796, 21.7335402],
                [107.1961141, 21.7337594],
                [107.1948159, 21.7342976],
                [107.1937966, 21.7345866],
                [107.1929705, 21.7350251],
                [107.1929812, 21.7357128],
                [107.1922517, 21.7361264],
                [107.1922517, 21.7364621],
                [107.1922517, 21.7370731],
                [107.1916938, 21.7379551],
                [107.1914953, 21.7386378],
                [107.1906424, 21.7401077],
                [107.19046, 21.7416873],
                [107.189194, 21.7427686],
                [107.1875524, 21.743805],
                [107.1869194, 21.7449111],
                [107.1857715, 21.746675],
                [107.1846235, 21.7474324],
                [107.183454, 21.7472829],
                [107.1819305, 21.7474722],
                [107.1811366, 21.7474025],
                [107.1803105, 21.748389],
                [107.1794307, 21.7488375],
                [107.1778214, 21.7485285],
                [107.1766198, 21.7483691],
                [107.1757185, 21.7485684],
                [107.1750533, 21.7489371],
                [107.1743131, 21.7497144],
                [107.1736693, 21.7502625],
                [107.1733045, 21.7510597],
                [107.172811, 21.7521857],
                [107.1718347, 21.7523352],
                [107.170783, 21.752293],
                [107.1702146, 21.7523651],
                [107.1692383, 21.7528384],
                [107.1687984, 21.7522704],
                [107.1677577, 21.7514383],
                [107.1672642, 21.7507906],
                [107.1671194, 21.7500781],
                [107.1659338, 21.7504717],
                [107.1652526, 21.7504717],
                [107.1644854, 21.7504368],
                [107.1638739, 21.7508803],
                [107.162807, 21.751495],
                [107.1619159, 21.7525893],
                [107.1617389, 21.7530576],
                [107.1619534, 21.7544228],
                [107.1616745, 21.7550556],
                [107.1612775, 21.7555339],
                [107.1606794, 21.7570261],
                [107.1605211, 21.7574819],
                [107.1585417, 21.7576613],
                [107.1573722, 21.7580599],
                [107.157041, 21.758459],
                [107.1564496, 21.7582741],
                [107.1558863, 21.7582641],
                [107.1554679, 21.7581844],
                [107.1545184, 21.7593154],
                [107.1537942, 21.7593303],
                [107.152909, 21.7598784],
                [107.1521848, 21.7600926],
                [107.1511495, 21.7604165],
                [107.149402, 21.760278],
                [107.148441, 21.760411],
                [107.147491, 21.7612335],
                [107.146343, 21.7618712],
                [107.1453559, 21.7629374],
                [107.1443474, 21.7629474],
                [107.1434998, 21.7626285],
                [107.1430197, 21.7623869],
                [107.1422982, 21.7625239],
                [107.1414936, 21.7624193],
                [107.1407989, 21.7625687],
                [107.1401793, 21.7627581],
                [107.138983, 21.7636473],
                [107.1389213, 21.7639961],
                [107.1388113, 21.7656725],
                [107.138291, 21.7670625],
                [107.1377224, 21.7677799],
                [107.1366227, 21.7677699],
                [107.135421, 21.7679891],
                [107.1348149, 21.7693442],
                [107.1354854, 21.7700616],
                [107.1360326, 21.7705797],
                [107.1361613, 21.7711576],
                [107.1359199, 21.7719796],
                [107.1354157, 21.7725525],
                [107.13489, 21.7730556],
                [107.1347, 21.773543],
                [107.1344662, 21.7741915],
                [107.1344018, 21.7750832],
                [107.1339083, 21.7757906],
                [107.1324599, 21.7759101],
                [107.1294236, 21.77594],
                [107.1273154, 21.7756959],
                [107.1261835, 21.7760048],
                [107.1249819, 21.7772153],
                [107.1239626, 21.7778829],
                [107.1233511, 21.7784009],
                [107.1226752, 21.7793474],
                [107.1207762, 21.7810013],
                [107.1196014, 21.7824708],
                [107.1186089, 21.7844683],
                [107.1182334, 21.785096],
                [107.117899, 21.7859547],
                [107.1169943, 21.787213],
                [107.1164632, 21.787487],
                [107.1163237, 21.7877759],
                [107.1153688, 21.7882142],
                [107.1137971, 21.7887323],
                [107.1135986, 21.7892204],
                [107.113384, 21.7893449],
                [107.1132714, 21.7902067],
                [107.1133518, 21.791437],
                [107.1135771, 21.7927321],
                [107.113148, 21.7929463],
                [107.1126115, 21.7932153],
                [107.1121073, 21.7934543],
                [107.1116888, 21.793818],
                [107.111426, 21.7939475],
                [107.1110022, 21.793803],
                [107.1102083, 21.7939176],
                [107.1098542, 21.7938678],
                [107.1092159, 21.7939475],
                [107.1082932, 21.7939425],
                [107.1076119, 21.793798],
                [107.106775, 21.7938429],
                [107.1059221, 21.7942463],
                [107.1052086, 21.7948839],
                [107.1044469, 21.795108],
                [107.1039694, 21.7949885],
                [107.1036369, 21.7949636],
                [107.1033525, 21.7947569],
                [107.1028107, 21.7942837],
                [107.1026444, 21.7941965],
                [107.1021911, 21.7940795],
                [107.1013677, 21.7942339],
                [107.1010217, 21.794189],
                [107.1005228, 21.7943584],
                [107.1002305, 21.7945825],
                [107.1000668, 21.794996],
                [107.099643, 21.795626],
                [107.0994794, 21.7958328],
                [107.099061, 21.7959025],
                [107.0986989, 21.7962262],
                [107.0983663, 21.7966471],
                [107.0981491, 21.7970556],
                [107.0979667, 21.7976109],
                [107.0975777, 21.7977927],
                [107.097162, 21.7979297],
                [107.0969903, 21.7982834],
                [107.0969582, 21.7986171],
                [107.0964968, 21.7984054],
                [107.096071, 21.798518],
                [107.0952603, 21.7987466],
                [107.0944583, 21.799287],
                [107.0936, 21.7993717],
                [107.0929885, 21.7999096],
                [107.0926183, 21.7999494],
                [107.0912397, 21.7996456],
                [107.0905799, 21.7998996],
                [107.0901293, 21.7999943],
                [107.0898181, 21.8003778],
                [107.089684, 21.8010054],
                [107.0896357, 21.8015184],
                [107.0893621, 21.8020264],
                [107.0889652, 21.8022555],
                [107.0884502, 21.8027934],
                [107.0880049, 21.8033065],
                [107.087667, 21.8034858],
                [107.0873398, 21.8039241],
                [107.0867872, 21.8042378],
                [107.0862991, 21.8045317],
                [107.0858002, 21.8045466],
                [107.085591, 21.8047857],
                [107.0857143, 21.8052489],
                [107.0858377, 21.8061853],
                [107.0856714, 21.8074204],
                [107.085363, 21.8080928],
                [107.0852584, 21.8082148],
                [107.0847729, 21.8082298],
                [107.0841801, 21.8088947],
                [107.0832735, 21.8094126],
                [107.0826566, 21.8093778],
                [107.081802, 21.809343],
                [107.081455, 21.8094375],
                [107.0809722, 21.809328],
                [107.0798671, 21.8103041],
                [107.0791805, 21.8104386],
                [107.077834, 21.8103041],
                [107.0769328, 21.8096915],
                [107.0756561, 21.8086407],
                [107.0752966, 21.8082173],
                [107.0740896, 21.8085211],
                [107.0723677, 21.8088349],
                [107.070694, 21.8089992],
                [107.0675182, 21.8090341],
                [107.0648726, 21.809135],
                [107.064357, 21.8096253],
                [107.0638477, 21.8103784],
                [107.0636214, 21.8105068],
                [107.0622067, 21.8097479],
                [107.0615618, 21.8096175],
                [107.0611503, 21.8094027],
                [107.0605091, 21.8098647],
                [107.0592075, 21.8104309],
                [107.0585159, 21.810822],
                [107.0570635, 21.8108862],
                [107.055824, 21.8115059],
                [107.0532444, 21.8117298],
                [107.0517002, 21.8118202],
                [107.0510337, 21.8114875],
                [107.0507961, 21.8115623],
                [107.0506125, 21.8118727],
                [107.0505559, 21.8125499],
                [107.0503107, 21.8131628],
                [107.049531, 21.8131803],
                [107.0487451, 21.8133321],
                [107.0483763, 21.8129828],
                [107.0476888, 21.8128534],
                [107.0468211, 21.8132679],
                [107.0460792, 21.8137174],
                [107.045639, 21.8132504],
                [107.0451297, 21.8130402],
                [107.0445648, 21.8129806],
                [107.0435201, 21.8127075],
                [107.0433944, 21.8126316],
                [107.0432246, 21.8123456],
                [107.0406403, 21.8134419],
                [107.0396671, 21.8147229],
                [107.0394955, 21.8151204],
                [107.0389931, 21.8154569],
                [107.038729, 21.8157312],
                [107.0377796, 21.8159239],
                [107.0375344, 21.8160464],
                [107.0369119, 21.8160289],
                [107.0366541, 21.816385],
                [107.033364, 21.8164493],
                [107.0320709, 21.8164709],
                [107.0317121, 21.816887],
                [107.0310959, 21.8171964],
                [107.0307237, 21.8172566],
                [107.0297811, 21.8172691],
                [107.0292851, 21.8177042],
                [107.0287221, 21.818897],
                [107.0281031, 21.8191518],
                [107.027556, 21.8194815],
                [107.0271355, 21.8194177],
                [107.0266119, 21.819351],
                [107.0261191, 21.819284],
                [107.0261136, 21.8192833],
                [107.0260101, 21.8192658],
                [107.0255605, 21.8191765],
                [107.0247853, 21.8207427],
                [107.0244419, 21.8210465],
                [107.0241308, 21.8212457],
                [107.0237768, 21.8212855],
                [107.023809, 21.8221571],
                [107.0236051, 21.8224708],
                [107.0222103, 21.8235465],
                [107.0214379, 21.8240146],
                [107.019614, 21.8247217],
                [107.0193136, 21.8247715],
                [107.0191848, 21.8253094],
                [107.018981, 21.8257775],
                [107.0190453, 21.8265543],
                [107.0183265, 21.8267535],
                [107.0180368, 21.8270025],
                [107.0172912, 21.8271918],
                [107.016272, 21.827067],
                [107.0158267, 21.8270125],
                [107.0156658, 21.8267436],
                [107.0149684, 21.8267934],
                [107.0146304, 21.8266888],
                [107.0141047, 21.8267087],
                [107.0135897, 21.8266938],
                [107.0132679, 21.8266241],
                [107.0126134, 21.8266938],
                [107.0123237, 21.8269876],
                [107.0120448, 21.8268979],
                [107.0116746, 21.8266041],
                [107.0113796, 21.8267237],
                [107.0111597, 21.8269926],
                [107.010591, 21.8269378],
                [107.0104086, 21.8269577],
                [107.010119, 21.8272714],
                [107.0092714, 21.8274955],
                [107.0087618, 21.827864],
                [107.0086867, 21.8280632],
                [107.00822, 21.8286807],
                [107.0069003, 21.8301996],
                [107.0054948, 21.8319076],
                [107.0042932, 21.8336953],
                [107.0040572, 21.8348207],
                [107.0041323, 21.8360855],
                [107.0046687, 21.8370515],
                [107.0045614, 21.8402383],
                [107.0044649, 21.8411048],
                [107.0051193, 21.8420907],
                [107.0048189, 21.8428276],
                [107.0053607, 21.8439579],
                [107.004265, 21.8462508],
                [107.0051193, 21.8468807],
                [107.0054412, 21.8479562],
                [107.0064711, 21.8495097],
                [107.0073938, 21.8498483],
                [107.0077371, 21.8507644],
                [107.0091319, 21.8517801],
                [107.0093679, 21.8523776],
                [107.0119643, 21.8546878],
                [107.0130157, 21.8551459],
                [107.0133805, 21.8555043],
                [107.0135736, 21.8567391],
                [107.0142174, 21.8585315],
                [107.015114, 21.859871],
                [107.016123, 21.861557],
                [107.016988, 21.862622],
                [107.018189, 21.863731],
                [107.019438, 21.864352],
                [107.021264, 21.86515],
                [107.022369, 21.865505],
                [107.024964, 21.865949],
                [107.026261, 21.866259],
                [107.02751, 21.866836],
                [107.029, 21.867368],
                [107.030201, 21.868122],
                [107.03121, 21.869054],
                [107.032219, 21.870163],
                [107.032939, 21.870784],
                [107.034909, 21.873091],
                [107.035966, 21.8742],
                [107.036783, 21.875354],
                [107.037552, 21.87664],
                [107.038513, 21.877705],
                [107.039185, 21.878326],
                [107.03981, 21.878814],
                [107.040963, 21.87908],
                [107.042452, 21.879257],
                [107.043846, 21.879302],
                [107.045191, 21.879612],
                [107.046584, 21.87979],
                [107.0477342, 21.8802073],
                [107.049365, 21.8806752],
                [107.051307, 21.8819097],
                [107.0524228, 21.8827659],
                [107.053791, 21.882984],
                [107.054656, 21.883738],
                [107.055377, 21.884581],
                [107.056049, 21.885512],
                [107.0566392, 21.8859617],
                [107.0572185, 21.8878831],
                [107.057873, 21.8898045],
                [107.0588279, 21.8904217],
                [107.0589781, 21.8915566],
                [107.0600724, 21.8925024],
                [107.0609629, 21.8929703],
                [107.0615315, 21.8928707],
                [107.0628405, 21.8936223],
                [107.0633179, 21.894792],
                [107.063737, 21.89576],
                [107.063592, 21.896736],
                [107.063304, 21.897934],
                [107.06292, 21.899087],
                [107.061815, 21.900995],
                [107.060902, 21.902281],
                [107.059989, 21.903967],
                [107.059316, 21.905342],
                [107.058307, 21.908181],
                [107.057779, 21.911109],
                [107.0570683, 21.9121724],
                [107.0569181, 21.9140238],
                [107.0547294, 21.9202347],
                [107.052878, 21.923353],
                [107.051869, 21.924063],
                [107.051004, 21.924817],
                [107.049947, 21.926991],
                [107.049755, 21.928144],
                [107.049179, 21.929032],
                [107.048554, 21.929653],
                [107.04668, 21.930274],
                [107.040819, 21.931161],
                [107.0401451, 21.9313685],
                [107.039666, 21.931516],
                [107.038993, 21.932004],
                [107.038368, 21.932802],
                [107.037936, 21.933734],
                [107.037263, 21.934799],
                [107.036639, 21.935908],
                [107.035774, 21.936839],
                [107.034957, 21.937372],
                [107.033756, 21.937682],
                [107.032939, 21.937549],
                [107.032074, 21.937194],
                [107.031162, 21.936928],
                [107.030537, 21.936795],
                [107.029624, 21.936839],
                [107.028759, 21.937017],
                [107.027702, 21.93746],
                [107.026645, 21.93817],
                [107.026165, 21.939102],
                [107.025877, 21.94203],
                [107.025204, 21.943405],
                [107.024772, 21.944026],
                [107.024291, 21.94447],
                [107.023811, 21.94478],
                [107.023186, 21.945002],
                [107.021937, 21.944913],
                [107.020832, 21.945046],
                [107.019871, 21.945312],
                [107.01891, 21.945756],
                [107.016796, 21.946599],
                [107.016171, 21.946643],
                [107.015451, 21.946555],
                [107.014201, 21.946067],
                [107.012808, 21.9458],
                [107.011847, 21.945845],
                [107.0086706, 21.9458817],
                [107.0077908, 21.9460957],
                [107.0073616, 21.9463594],
                [107.00638, 21.9464887],
                [107.004953, 21.9458568],
                [107.0046043, 21.9454638],
                [107.0033437, 21.9448219],
                [107.0024478, 21.9447622],
                [107.0013428, 21.9445582],
                [107.0001841, 21.9440457],
                [106.9994867, 21.9455732],
                [106.9987357, 21.9460111],
                [106.9980597, 21.9459315],
                [106.9969332, 21.9464738],
                [106.9958872, 21.9477078],
                [106.9951147, 21.9483098],
                [106.9939721, 21.9489567],
                [106.9932425, 21.9494443],
                [106.9921911, 21.9519221],
                [106.9915688, 21.9517231],
                [106.9895732, 21.9503996],
                [106.9886398, 21.9494542],
                [106.9879639, 21.9483795],
                [106.9878566, 21.9475834],
                [106.9874275, 21.9464191],
                [106.9874811, 21.9451354],
                [106.9880927, 21.9444686],
                [106.9889091, 21.9440198],
                [106.9891333, 21.9426972],
                [106.9888807, 21.9422008],
                [106.9876528, 21.9417419],
                [106.9863331, 21.941513],
                [106.9848955, 21.9401396],
                [106.9836402, 21.9386966],
                [106.9848579, 21.9366614],
                [106.9848204, 21.9358603],
                [106.9844502, 21.9353179],
                [106.9837689, 21.9346113],
                [106.9817036, 21.9322676],
                [106.9813657, 21.9316057],
                [106.9810867, 21.930929],
                [106.9806093, 21.929874],
                [106.9796866, 21.9275799],
                [106.9795847, 21.9268086],
                [106.9793755, 21.9265299],
                [106.9789249, 21.9263557],
                [106.9781631, 21.9261069],
                [106.9774765, 21.9260621],
                [106.9765967, 21.9258581],
                [106.9747889, 21.9246787],
                [106.9732332, 21.9236336],
                [106.9722301, 21.9231559],
                [106.9716346, 21.9228772],
                [106.9711626, 21.9229767],
                [106.9707763, 21.9230464],
                [106.9703793, 21.9232355],
                [106.9698107, 21.9236187],
                [106.9690436, 21.9240666],
                [106.9686627, 21.9240915],
                [106.9670159, 21.9235689],
                [106.9655353, 21.9232952],
                [106.9649881, 21.9230912],
                [106.9647896, 21.9227926],
                [106.9646448, 21.9218769],
                [106.9643068, 21.9213096],
                [106.963935, 21.920634],
                [106.963598, 21.919836],
                [106.96307, 21.919614],
                [106.9619411, 21.9194732],
                [106.961571, 21.9194036],
                [106.9611901, 21.9194434],
                [106.9608521, 21.9195628],
                [106.9596451, 21.9210956],
                [106.958921, 21.9217674],
                [106.9582289, 21.922917],
                [106.9570863, 21.9230613],
                [106.9555575, 21.9233898],
                [106.9540608, 21.9240069],
                [106.9538677, 21.9243254],
                [106.9536263, 21.9246986],
                [106.9540071, 21.9257287],
                [106.9544256, 21.9272764],
                [106.9541413, 21.9278188],
                [106.9539535, 21.9288091],
                [106.9537282, 21.9291972],
                [106.9538838, 21.9296202],
                [106.9538677, 21.9302273],
                [106.9537389, 21.9306652],
                [106.9532025, 21.9310932],
                [106.9524729, 21.9313619],
                [106.9517434, 21.931541],
                [106.9513893, 21.9316605],
                [106.9510674, 21.9316704],
                [106.9502413, 21.93175],
                [106.9494688, 21.9315311],
                [106.9485247, 21.9311131],
                [106.9479668, 21.9311131],
                [106.9473767, 21.931342],
                [106.9468725, 21.9320685],
                [106.9466847, 21.9324666],
                [106.945703, 21.9329642],
                [106.9452953, 21.9324567],
                [106.943419, 21.931415],
                [106.942795, 21.931459],
                [106.941882, 21.931592],
                [106.941257, 21.931415],
                [106.940296, 21.930749],
                [106.93972, 21.930439],
                [106.939287, 21.930261],
                [106.938374, 21.930261],
                [106.936837, 21.930439],
                [106.936068, 21.930395],
                [106.9344056, 21.9299486],
                [106.93357, 21.929596],
                [106.9328821, 21.9302024],
                [106.9313049, 21.9308195],
                [106.929822, 21.930794],
                [106.929678, 21.931104],
                [106.92963, 21.931548],
                [106.929822, 21.932968],
                [106.930014, 21.933766],
                [106.9302159, 21.9346312],
                [106.930334, 21.9353975],
                [106.9298458, 21.9368157],
                [106.9293308, 21.9377263],
                [106.92939, 21.938336],
                [106.9292289, 21.9394132],
                [106.9293576, 21.9404432],
                [106.9295829, 21.9410652],
                [106.9294757, 21.9416523],
                [106.9289875, 21.9422444],
                [106.9284564, 21.9426574],
                [106.9277859, 21.9445283],
                [106.9265681, 21.9459912],
                [106.925306, 21.94641],
                [106.924537, 21.946588],
                [106.924345, 21.946943],
                [106.9242883, 21.9486681],
                [106.9239128, 21.9497727],
                [106.923384, 21.950447],
                [106.923192, 21.951201],
                [106.922904, 21.951823],
                [106.9228238, 21.9525689],
                [106.9222122, 21.9534495],
                [106.9214129, 21.9543849],
                [106.9213325, 21.9547332],
                [106.9217777, 21.9556089],
                [106.9224536, 21.9561761],
                [106.9233227, 21.9572806],
                [106.923624, 21.958034],
                [106.9237035, 21.9585393],
                [106.9238806, 21.9593901],
                [106.92372, 21.960385],
                [106.923725, 21.9608578],
                [106.924063, 21.9621563],
                [106.9240093, 21.9632508],
                [106.9235319, 21.9647732],
                [106.9231778, 21.9660766],
                [106.9221693, 21.9668627],
                [106.9217187, 21.9671811],
                [106.9211394, 21.9680567],
                [106.9206566, 21.9691163],
                [106.919809, 21.9698427],
                [106.9198787, 21.9705093],
                [106.919986, 21.9712207],
                [106.9197875, 21.9721311],
                [106.9192672, 21.9732056],
                [106.9187951, 21.9743249],
                [106.9172502, 21.9749915],
                [106.9162255, 21.975693],
                [106.9152117, 21.9756084],
                [106.9143265, 21.9761059],
                [106.9133341, 21.9759318],
                [106.9125295, 21.9756233],
                [106.910845, 21.9747378],
                [106.9096005, 21.9740066],
                [106.9084954, 21.9734643],
                [106.9072187, 21.972733],
                [106.905427, 21.9722306],
                [106.904676, 21.9721559],
                [106.903587, 21.9721311],
                [106.9027179, 21.9721709],
                [106.9020259, 21.9724345],
                [106.9005614, 21.9733598],
                [106.8992901, 21.974499],
                [106.8980294, 21.9755189],
                [106.896565, 21.9762402],
                [106.8957013, 21.9761755],
                [106.8948537, 21.9758124],
                [106.8940276, 21.9763546],
                [106.8930352, 21.9766183],
                [106.8921983, 21.9771008],
                [106.8915224, 21.9768272],
                [106.8908948, 21.9765237],
                [106.8905139, 21.9764242],
                [106.8899292, 21.9766183],
                [106.8896073, 21.9769864],
                [106.8888509, 21.978046],
                [106.8882769, 21.9787424],
                [106.8869787, 21.9792747],
                [106.8856537, 21.9802149],
                [106.8845648, 21.980195],
                [106.8837708, 21.9800656],
                [106.8833524, 21.9802149],
                [106.8825692, 21.9805133],
                [106.8817699, 21.9810456],
                [106.8808526, 21.981548],
                [106.8801713, 21.9820405],
                [106.8785298, 21.9817619],
                [106.8773067, 21.981558],
                [106.876663, 21.9814038],
                [106.876155, 21.980748],
                [106.8754935, 21.9802696],
                [106.8747961, 21.9803392],
                [106.8736696, 21.9804984],
                [106.8726289, 21.9805581],
                [106.8715346, 21.9810406],
                [106.8698877, 21.9809013],
                [106.8695337, 21.9811451],
                [106.8692923, 21.9821698],
                [106.8680906, 21.9832493],
                [106.8668729, 21.98311],
                [106.8659288, 21.982528],
                [106.8654406, 21.9820753],
                [106.8649149, 21.9817619],
                [106.864121, 21.9816326],
                [106.8633485, 21.9815082],
                [106.8628818, 21.9814634],
                [106.8625009, 21.9816376],
                [106.8622166, 21.982125],
                [106.8619806, 21.9828463],
                [106.861707, 21.9838412],
                [106.8614066, 21.9839357],
                [106.8604463, 21.9833289],
                [106.859724, 21.982567],
                [106.8572706, 21.9808615],
                [106.8567824, 21.9807869],
                [106.8561172, 21.9811749],
                [106.8554896, 21.9814585],
                [106.8547869, 21.9817619],
                [106.8540198, 21.9821201],
                [106.8530542, 21.9826026],
                [106.8527698, 21.9826324],
                [106.8517506, 21.9820206],
                [106.8503666, 21.9816127],
                [106.849153, 21.981148],
                [106.848625, 21.980615],
                [106.8481511, 21.9800109],
                [106.84752, 21.979462],
                [106.846991, 21.979196],
                [106.8462843, 21.9790458],
                [106.8455923, 21.9793692],
                [106.8449056, 21.9796179],
                [106.8439937, 21.9803193],
                [106.8426311, 21.9814983],
                [106.8417942, 21.9821897],
                [106.840598, 21.983299],
                [106.8397987, 21.9840949],
                [106.8390477, 21.9838661],
                [106.8380713, 21.9835925],
                [106.8361992, 21.9835776],
                [106.8349063, 21.9836373],
                [106.8342894, 21.9826573],
                [106.8330771, 21.9816823],
                [106.8332863, 21.9805382],
                [106.8332755, 21.9798119],
                [106.8329108, 21.9793493],
                [106.8327257, 21.9782637],
                [106.8327123, 21.9781852],
                [106.8329805, 21.9771854],
                [106.8338013, 21.9762004],
                [106.8333051, 21.9753597],
                [106.8328813, 21.9748597],
                [106.8322027, 21.9749368],
                [106.8304324, 21.9751358],
                [106.8287694, 21.9757825],
                [106.8276536, 21.9757228],
                [106.8261355, 21.9748224],
                [106.825583, 21.9741956],
                [106.8252718, 21.9735837],
                [106.8250143, 21.9736235],
                [106.8246764, 21.9744941],
                [106.8242526, 21.9749617],
                [106.8231207, 21.9750761],
                [106.8217152, 21.9746433],
                [106.8207926, 21.9742503],
                [106.8200308, 21.9735936],
                [106.8198377, 21.9736732],
                [106.8191189, 21.9740414],
                [106.8183517, 21.9742603],
                [106.8170965, 21.9736732],
                [106.8160558, 21.9730763],
                [106.8156588, 21.9732255],
                [106.8152082, 21.9734643],
                [106.8148005, 21.9737329],
                [106.8140817, 21.9739717],
                [106.813851, 21.973912],
                [106.8123919, 21.9731907],
                [106.8119949, 21.9732205],
                [106.8113726, 21.9737081],
                [106.8093771, 21.9748324],
                [106.8077248, 21.9753049],
                [106.8059653, 21.9756482],
                [106.8050373, 21.9760561],
                [106.8047959, 21.9764839],
                [106.8043452, 21.9771406],
                [106.804231, 21.977953],
                [106.8042755, 21.9784589],
                [106.8042648, 21.9790956],
                [106.8043613, 21.9798517],
                [106.8045115, 21.980777],
                [106.8045545, 21.981752],
                [106.804533, 21.9825081],
                [106.8040824, 21.9828264],
                [106.802634, 21.9822992],
                [106.800971, 21.9815729],
                [106.8001556, 21.9809561],
                [106.799705, 21.9806775],
                [106.7994851, 21.9806277],
                [106.7991954, 21.9810953],
                [106.7986321, 21.98213],
                [106.7982137, 21.9829856],
                [106.7975807, 21.9839009],
                [106.7968297, 21.9849853],
                [106.796784, 21.985673],
                [106.796543, 21.986427],
                [106.796543, 21.987359],
                [106.796639, 21.988113],
                [106.7966473, 21.9887409],
                [106.7967439, 21.989497],
                [106.7971784, 21.9902083],
                [106.7970872, 21.9905863],
                [106.7965615, 21.9917602],
                [106.796186, 21.9923471],
                [106.7958641, 21.9930634],
                [106.7947215, 21.9929042],
                [106.7933857, 21.9927451],
                [106.7924631, 21.9926953],
                [106.7914224, 21.9912727],
                [106.7909718, 21.9906112],
                [106.790961, 21.9898253],
                [106.7907733, 21.9896014],
                [106.790387, 21.9893975],
                [106.7899954, 21.9892433],
                [106.7893946, 21.9891836],
                [106.7889601, 21.9897059],
                [106.788606, 21.9899397],
                [106.7883432, 21.9900043],
                [106.7881018, 21.990253],
                [106.7879301, 21.9906858],
                [106.7882681, 21.9911136],
                [106.7883539, 21.9913623],
                [106.7885148, 21.9918],
                [106.7884076, 21.9922974],
                [106.7880535, 21.9927749],
                [106.7878604, 21.9934116],
                [106.787367, 21.995699],
                [106.787415, 21.996143],
                [106.787163, 21.9968635],
                [106.7868304, 21.9988332],
                [106.7860794, 22.0009819],
                [106.7855215, 22.0021955],
                [106.7843735, 22.0040158],
                [106.7822599, 22.0036179],
                [106.7785209, 22.0044585],
                [106.7778128, 22.0060003],
                [106.7770135, 22.0065126],
                [106.7761445, 22.0078057],
                [106.7751145, 22.0091585],
                [106.7721963, 22.0104715],
                [106.7710912, 22.0113369],
                [106.7699432, 22.0115656],
                [106.7691493, 22.0122619],
                [106.7676794, 22.0125404],
                [106.7652333, 22.012799],
                [106.7638171, 22.0128786],
                [106.7631733, 22.0121823],
                [106.7610919, 22.0127493],
                [106.7598903, 22.0128189],
                [106.7586028, 22.0131372],
                [106.7566287, 22.0125902],
                [106.7552233, 22.0111678],
                [106.7527932, 22.0106505],
                [106.7500359, 22.0089794],
                [106.7491883, 22.0086114],
                [106.7471337, 22.0083428],
                [106.7450523, 22.0080345],
                [106.7414045, 22.0086114],
                [106.7393017, 22.0082931],
                [106.7377996, 22.0075968],
                [106.736598, 22.0074576],
                [106.736598, 22.0072586],
                [106.737113, 22.006443],
                [106.7374885, 22.0038965],
                [106.736716, 22.0004347],
                [106.7343879, 21.999251],
                [106.7327732, 21.9977141],
                [106.731593, 21.9960378],
                [106.7305469, 21.9949635],
                [106.729576, 21.9940284],
                [106.729002, 21.9926953],
                [106.7286587, 21.9916209],
                [106.7285514, 21.9907853],
                [106.728561, 21.989576],
                [106.728705, 21.987757],
                [106.728705, 21.986559],
                [106.728369, 21.985805],
                [106.7277848, 21.9846168],
                [106.7275114, 21.9826386],
                [106.7268445, 21.9814726],
                [106.7264583, 21.9809816],
                [106.7254186, 21.9810158],
                [106.7243779, 21.9808367],
                [106.7236137, 21.98019],
                [106.7232084, 21.9796826],
                [106.7226269, 21.9791554],
                [106.722027, 21.97813],
                [106.721018, 21.976888],
                [106.720249, 21.976089],
                [106.7199147, 21.9751557],
                [106.719433, 21.9744331],
                [106.718528, 21.974059],
                [106.7169946, 21.973837],
                [106.7164814, 21.9732852],
                [106.7157572, 21.9723599],
                [106.7156741, 21.9722156],
                [106.7155754, 21.972213],
                [106.7150035, 21.9721982],
                [106.7143115, 21.9722728],
                [106.7138046, 21.9721137],
                [106.7137331, 21.9721102],
                [106.7136316, 21.9721051],
                [106.7132521, 21.9720863],
                [106.7128336, 21.9723599],
                [106.7125869, 21.973121],
                [106.7121175, 21.9736509],
                [106.7115994, 21.9737037],
                [106.7112927, 21.9737255],
                [106.7089181, 21.9741725],
                [106.7075166, 21.9741105],
                [106.7072422, 21.9736152],
                [106.7073619, 21.9725589],
                [106.7076516, 21.9711162],
                [106.7059171, 21.9691244],
                [106.7053683, 21.9680594],
                [106.7046763, 21.9676973],
                [106.7033046, 21.9675159],
                [106.7028523, 21.9673963],
                [106.7027334, 21.9667782],
                [106.7024956, 21.9662711],
                [106.7022657, 21.9658528],
                [106.7022228, 21.9650667],
                [106.7015282, 21.9645861],
                [106.7015791, 21.9640021],
                [106.7019546, 21.9631065],
                [106.6992617, 21.9604001],
                [106.6979742, 21.9603105],
                [106.6968477, 21.9609573],
                [106.6961825, 21.9621513],
                [106.6957426, 21.9625294],
                [106.6950345, 21.9624001],
                [106.6940045, 21.9624598],
                [106.6938007, 21.9637036],
                [106.69379, 21.9644399],
                [106.693283, 21.9644772],
                [106.6916335, 21.9641911],
                [106.6908824, 21.9641911],
                [106.6904962, 21.9643304],
                [106.6904104, 21.9658229],
                [106.6908181, 21.9665294],
                [106.6918588, 21.9671164],
                [106.6921162, 21.9682009],
                [106.6932668, 21.9688707],
                [106.6938221, 21.9693153],
                [106.6933232, 21.9708625],
                [106.6938436, 21.971554],
                [106.6940906, 21.9721984],
                [106.6938147, 21.9725456],
                [106.6928853, 21.9726319],
                [106.6917837, 21.972937],
                [106.691097, 21.9757129],
                [106.6900349, 21.9768172],
                [106.6888976, 21.9778818],
                [106.6876316, 21.9786379],
                [106.6860652, 21.9797522],
                [106.6852544, 21.9806569],
                [106.6850624, 21.9815889],
                [106.6848704, 21.9822989],
                [106.6845846, 21.9829856],
                [106.6838614, 21.9840729],
                [106.6831684, 21.9854529],
                [106.6828465, 21.9863682],
                [106.682868, 21.9872636],
                [106.6830611, 21.9879202],
                [106.6832113, 21.9889747],
                [106.6834366, 21.9899098],
                [106.6835868, 21.9909444],
                [106.6837048, 21.9918199],
                [106.683104, 21.992765],
                [106.6830289, 21.9934414],
                [106.683501, 21.994675],
                [106.683542, 21.995344],
                [106.6835868, 21.9959583],
                [106.683222, 21.9967044],
                [106.6834688, 21.9979876],
                [106.6837692, 21.9989525],
                [106.6840052, 21.9999772],
                [106.6838014, 22.0004546],
                [106.6828787, 22.0013002],
                [106.6826898, 22.0020242],
                [106.6829989, 22.0027167],
                [106.6836641, 22.0042067],
                [106.6843271, 22.0053587],
                [106.6848206, 22.0058461],
                [106.6862583, 22.0056372],
                [106.6869664, 22.007189],
                [106.6874599, 22.0074377],
                [106.6888761, 22.007746],
                [106.689713, 22.0087805],
                [106.6916871, 22.0111777],
                [106.6958392, 22.0145795],
                [106.6979313, 22.014664],
                [106.6990685, 22.0147784],
                [106.6999912, 22.0156637],
                [106.7005598, 22.017837],
                [106.7011607, 22.0190454],
                [106.7018741, 22.0196024],
                [106.7023998, 22.0195676],
                [106.7031133, 22.0204081],
                [106.7031133, 22.0209352],
                [106.7022121, 22.0219994],
                [106.7021799, 22.0227951],
                [106.7043525, 22.024297],
                [106.7056775, 22.0242671],
                [106.7067504, 22.0243666],
                [106.708011, 22.0253811],
                [106.708287, 22.026221],
                [106.7083704, 22.0276387],
                [106.7088103, 22.0287029],
                [106.7080593, 22.0297671],
                [106.7076409, 22.0304832],
                [106.7076516, 22.0309506],
                [106.7074156, 22.0315026],
                [106.7066699, 22.0316219],
                [106.7063159, 22.03198],
                [106.7057955, 22.0335463],
                [106.7060423, 22.0350481],
                [106.7057311, 22.036321],
                [106.7053771, 22.0373951],
                [106.7055702, 22.0389167],
                [106.7043364, 22.0396625],
                [106.7031133, 22.0404482],
                [106.7007047, 22.0414725],
                [106.6988969, 22.04192],
                [106.6979206, 22.0428747],
                [106.6971373, 22.04371],
                [106.6966653, 22.0451619],
                [106.6968048, 22.0462359],
                [106.6979098, 22.0478966],
                [106.6987279, 22.0488811],
                [106.6991329, 22.0495275],
                [106.6993689, 22.0502136],
                [106.699149, 22.0507357],
                [106.69828, 22.0511036],
                [106.6974968, 22.0516306],
                [106.6971052, 22.0523963],
                [106.6970408, 22.0532018],
                [106.6971213, 22.0543055],
                [106.6977972, 22.0553198],
                [106.69887, 22.055901],
                [106.7010212, 22.0585167],
                [106.7020941, 22.0608832],
                [106.7032099, 22.064602],
                [106.7035961, 22.0649003],
                [106.7057633, 22.0646218],
                [106.7065573, 22.0652582],
                [106.7061496, 22.0686786],
                [106.7058491, 22.0725761],
                [106.7057419, 22.0749821],
                [106.7055702, 22.0771098],
                [106.7055058, 22.078959],
                [106.7054415, 22.0825779],
                [106.7052698, 22.0837411],
                [106.7051518, 22.0848645],
                [106.7059189, 22.0849987],
                [106.7076141, 22.0854212],
                [106.7086011, 22.0862364],
                [106.7096686, 22.0878817],
                [106.7097759, 22.0887914],
                [106.7103338, 22.0890449],
                [106.711455, 22.0896712],
                [106.711554, 22.090106],
                [106.7114711, 22.0906802],
                [106.7122811, 22.0919328],
                [106.7120933, 22.0928126],
                [106.7119211, 22.0929254],
                [106.7112887, 22.0933395],
                [106.711278, 22.0939608],
                [106.7126942, 22.0954022],
                [106.7130804, 22.0960682],
                [106.7119646, 22.0978576],
                [106.7109132, 22.0987026],
                [106.7101836, 22.0993785],
                [106.7098027, 22.0998855],
                [106.710071, 22.1010286],
                [106.7097813, 22.1016698],
                [106.7082417, 22.1025396],
                [106.7076181, 22.1028167],
                [106.7066485, 22.1031857],
                [106.7058545, 22.1035336],
                [106.7055756, 22.1034491],
                [106.7048407, 22.1038418],
                [106.7035586, 22.1042692],
                [106.7030007, 22.1043388],
                [106.7024696, 22.1047165],
                [106.7019975, 22.1053825],
                [106.7014128, 22.1055714],
                [106.7007047, 22.1059491],
                [106.6998517, 22.1062125],
                [106.6990632, 22.1065008],
                [106.6983497, 22.1068338],
                [106.6982478, 22.10746],
                [106.6980547, 22.1083596],
                [106.6968423, 22.1090156],
                [106.6963488, 22.1100494],
                [106.695056, 22.1115354],
                [106.6953564, 22.1125393],
                [106.695233, 22.1133344],
                [106.6952974, 22.1147508],
                [106.6955334, 22.1158889],
                [106.6953939, 22.1168232],
                [106.694836, 22.1175041],
                [106.6944176, 22.1181402],
                [106.6932481, 22.1182694],
                [106.6927868, 22.1187167],
                [106.6923845, 22.1190745],
                [106.6923094, 22.1193677],
                [106.6927278, 22.1208188],
                [106.6920197, 22.1217531],
                [106.6920143, 22.1221307],
                [106.6925347, 22.1224488],
                [106.6927063, 22.1228215],
                [106.6927975, 22.1234626],
                [106.6932374, 22.1243024],
                [106.6934091, 22.1247795],
                [106.6931033, 22.1254006],
                [106.6932374, 22.1265237],
                [106.6935271, 22.1269113],
                [106.6936129, 22.1273387],
                [106.6941011, 22.1281139],
                [106.6941226, 22.1284319],
                [106.6940582, 22.1289835],
                [106.6941977, 22.1294929],
                [106.6934466, 22.1302631],
                [106.6932401, 22.1305339],
                [106.6940957, 22.1319104],
                [106.6943371, 22.1327204],
                [106.6942862, 22.133344],
                [106.6940233, 22.1338807],
                [106.6935593, 22.1343776],
                [106.693055, 22.1347428],
                [106.6923469, 22.1346881],
                [106.6914886, 22.1350012],
                [106.6911882, 22.1352944],
                [106.6903192, 22.1359602],
                [106.6895145, 22.1365565],
                [106.6892007, 22.1368124],
                [106.6883934, 22.1374434],
                [106.6881385, 22.1382757],
                [106.6885141, 22.1388323],
                [106.6892624, 22.1390559],
                [106.6906303, 22.1397664],
                [106.6911453, 22.1407254],
                [106.692009, 22.1415999],
                [106.6924059, 22.1418434],
                [106.693275, 22.1420322],
                [106.6938811, 22.1419875],
                [106.6945785, 22.1424098],
                [106.6950023, 22.142698],
                [106.6954261, 22.1431204],
                [106.6952437, 22.1434881],
                [106.694718, 22.1446557],
                [106.6950291, 22.1452569],
                [106.6953832, 22.1457538],
                [106.6956782, 22.1460867],
                [106.6957855, 22.1464395],
                [106.6957641, 22.1472494],
                [106.6961932, 22.1484766],
                [106.6962254, 22.1496889],
                [106.6963595, 22.1504094],
                [106.696676, 22.1510404],
                [106.6968799, 22.1516465],
                [106.697014, 22.1521881],
                [106.697132, 22.1531917],
                [106.696912, 22.1539171],
                [106.6976362, 22.1544239],
                [106.6979903, 22.1549207],
                [106.6995728, 22.1554672],
                [106.7002112, 22.1555219],
                [106.7008227, 22.1559392],
                [106.7012948, 22.1562969],
                [106.7032045, 22.1557107],
                [106.7040843, 22.1552486],
                [106.704551, 22.1550747],
                [106.7054307, 22.1551691],
                [106.7056775, 22.1555219],
                [106.7059511, 22.1558846],
                [106.7059779, 22.1561529],
                [106.7059082, 22.1568584],
                [106.7060637, 22.1576433],
                [106.705935, 22.1594369],
                [106.705066, 22.1612155],
                [106.7038858, 22.162696],
                [106.7037441, 22.1629815],
                [106.7034642, 22.1631767],
                [106.7031679, 22.1635303],
                [106.7029384, 22.163712],
                [106.7026412, 22.1638486],
                [106.701924, 22.1639773],
                [106.7014689, 22.163926],
                [106.7008656, 22.1638883],
                [106.6998035, 22.1641367],
                [106.6993582, 22.1632424],
                [106.698795, 22.1628053],
                [106.6983658, 22.1625419],
                [106.6975826, 22.1626662],
                [106.6967189, 22.1627009],
                [106.6957587, 22.162537],
                [106.6948521, 22.1619557],
                [106.6934949, 22.1620253],
                [106.6928673, 22.1625419],
                [106.6922075, 22.1626264],
                [106.6918534, 22.1629642],
                [106.6914618, 22.1638535],
                [106.6912311, 22.1648223],
                [106.6903031, 22.1647478],
                [106.6878676, 22.1643255],
                [106.686784, 22.1645341],
                [106.6860384, 22.1649316],
                [106.685518, 22.1654234],
                [106.6851801, 22.1661885],
                [106.6850567, 22.1669337],
                [106.6848743, 22.1672914],
                [106.6836351, 22.1676639],
                [106.683303, 22.168232],
                [106.6831469, 22.1685532],
                [106.6829538, 22.1690003],
                [106.6823691, 22.1692189],
                [106.6807437, 22.169358],
                [106.6793865, 22.1702125],
                [106.6786998, 22.1702373],
                [106.6779327, 22.1704459],
                [106.6779381, 22.1714594],
                [106.677981, 22.172438],
                [106.6780668, 22.1729447],
                [106.6780776, 22.1739532],
                [106.6781902, 22.1747778],
                [106.6784638, 22.175672],
                [106.677686, 22.176129],
                [106.6772944, 22.1763674],
                [106.6761196, 22.1766804],
                [106.6760552, 22.1775448],
                [106.675905, 22.1783098],
                [106.6759157, 22.1788512],
                [106.6761142, 22.1796758],
                [106.675905, 22.1799292],
                [106.6750386, 22.180329],
                [106.6743976, 22.1810518],
                [106.6729975, 22.1811511],
                [106.6722679, 22.1821595],
                [106.6713452, 22.1830189],
                [106.6703689, 22.1851449],
                [106.6695857, 22.1859546],
                [106.669575, 22.1863519],
                [106.6699076, 22.1865407],
                [106.6709697, 22.1865059],
                [106.6721713, 22.1866003],
                [106.6733622, 22.186491],
                [106.6741937, 22.1872013],
                [106.6747141, 22.1880805],
                [106.6757172, 22.189069],
                [106.6758674, 22.1899184],
                [106.6768277, 22.1912148],
                [106.6780454, 22.1928142],
                [106.6788554, 22.1941304],
                [106.679703, 22.1958391],
                [106.6798317, 22.197498],
                [106.6801536, 22.2001602],
                [106.6806847, 22.2018191],
                [106.6803575, 22.2030756],
                [106.6799337, 22.2033985],
                [106.6794509, 22.2035375],
                [106.6782385, 22.2042378],
                [106.6780347, 22.2045358],
                [106.6781956, 22.2052312],
                [106.6784209, 22.2053702],
                [106.682107, 22.2043512],
                [106.6839696, 22.2038287],
                [106.685183, 22.2035129],
                [106.6860166, 22.2033311],
                [106.6873041, 22.2030977],
                [106.6903801, 22.2036589],
                [106.69163, 22.2040612],
                [106.692956, 22.2048032],
                [106.694589, 22.2055591],
                [106.6963421, 22.2068752],
                [106.697548, 22.2083354],
                [106.6983044, 22.2094528],
                [106.6985168, 22.2106011],
                [106.698697, 22.2119122],
                [106.6984674, 22.2136325],
                [106.6982615, 22.2146933],
                [106.6981295, 22.2164176],
                [106.6982904, 22.2178945],
                [106.6988912, 22.2190208],
                [106.7003989, 22.2195689],
                [106.7020082, 22.2196484],
                [106.7020512, 22.2201053],
                [106.7023355, 22.2212921],
                [106.7023462, 22.2226478],
                [106.7009515, 22.2225783],
                [106.6998571, 22.2228812],
                [106.6987145, 22.2234623],
                [106.6982531, 22.2240284],
                [106.6985536, 22.2251805],
                [106.6976952, 22.2266404],
                [106.6976094, 22.2280308],
                [106.6966116, 22.2290836],
                [106.6960216, 22.2305385],
                [106.6954315, 22.2312685],
                [106.6953778, 22.2316856],
                [106.6954744, 22.2322517],
                [106.6951525, 22.2332647],
                [106.6946375, 22.2336619],
                [106.6932481, 22.2343074],
                [106.6936934, 22.235231],
                [106.6941279, 22.2360354],
                [106.6949379, 22.2373761],
                [106.6949326, 22.2383791],
                [106.6950238, 22.2397347],
                [106.6944766, 22.240544],
                [106.6940474, 22.2411796],
                [106.6923737, 22.2419343],
                [106.6920948, 22.2420137],
                [106.691671, 22.2424507],
                [106.6913652, 22.2426294],
                [106.6925991, 22.2460355],
                [106.6924381, 22.2470087],
                [106.6899276, 22.2495409],
                [106.6893267, 22.2511893],
                [106.6895842, 22.2531156],
                [106.690172, 22.25461],
                [106.6903353, 22.2558959],
                [106.6902816, 22.2569485],
                [106.6900027, 22.257723],
                [106.6894126, 22.2585272],
                [106.6890693, 22.2591925],
                [106.6882753, 22.259967],
                [106.6867626, 22.2611287],
                [106.6864729, 22.261933],
                [106.6859043, 22.263055],
                [106.6842413, 22.265011],
                [106.6843271, 22.2656762],
                [106.6847348, 22.2667783],
                [106.6852283, 22.2678705],
                [106.685512, 22.268984],
                [106.68628, 22.270004],
                [106.687001, 22.270625],
                [106.6876852, 22.2713157],
                [106.6894448, 22.2724575],
                [106.6900456, 22.2742346],
                [106.69022, 22.275239],
                [106.690652, 22.276481],
                [106.6906142, 22.2777144],
                [106.6902494, 22.2788115],
                [106.6891873, 22.2794866],
                [106.6866177, 22.2807325],
                [106.685003, 22.2799482],
                [106.6836512, 22.2801617],
                [106.6822886, 22.2816707],
                [106.6807866, 22.2836562],
                [106.6794777, 22.2842916],
                [106.6766238, 22.2849071],
                [106.6756367, 22.2848872],
                [106.6735339, 22.2850461],
                [106.672225, 22.2838349],
                [106.67171, 22.2835172],
                [106.6701221, 22.2838548],
                [106.6691887, 22.2842816],
                [106.6684484, 22.2844604],
                [106.6669196, 22.2859445],
                [106.6664743, 22.2869224],
                [106.666131, 22.2881831],
                [106.6661525, 22.2898608],
                [106.6661739, 22.2907046],
                [106.6672039, 22.2918859],
                [106.6679549, 22.2933849],
                [106.6681373, 22.2954993],
                [106.6680836, 22.2967203],
                [106.667633, 22.2986858],
                [106.666131, 22.3001549],
                [106.6661525, 22.3012667],
                [106.6664314, 22.3037879],
                [106.6667318, 22.3053364],
                [106.6671824, 22.307262],
                [106.6670751, 22.3083936],
                [106.6661954, 22.3096045],
                [106.6661954, 22.3111926],
                [106.665813, 22.313481],
                [106.6652298, 22.3139916],
                [106.6629982, 22.3136939],
                [106.6619468, 22.3144482],
                [106.6617322, 22.3158775],
                [106.6597795, 22.3175846],
                [106.6593075, 22.3202048],
                [106.6581917, 22.322428],
                [106.657505, 22.325068],
                [106.6573119, 22.3271323],
                [106.6572904, 22.3284225],
                [106.6576928, 22.3298218],
                [106.6587818, 22.3315982],
                [106.6593075, 22.3327792],
                [106.6596991, 22.3337815],
                [106.6589642, 22.3339502],
                [106.656754, 22.3350964],
                [106.6547906, 22.3359747],
                [106.6529345, 22.3368083],
                [106.6522157, 22.3375476],
                [106.6511965, 22.3381331],
                [106.649767, 22.338991],
                [106.6485357, 22.3396862],
                [106.647124, 22.339435],
                [106.6455638, 22.339587],
                [106.6442442, 22.3399442],
                [106.6432625, 22.3398648],
                [106.6422486, 22.3396415],
                [106.6413635, 22.3392644],
                [106.6405696, 22.3393537],
                [106.6393304, 22.33984],
                [106.6380805, 22.339969],
                [106.6370881, 22.3402022],
                [106.6361332, 22.3406091],
                [106.6353768, 22.3402518],
                [106.6349423, 22.3396614],
                [106.6342932, 22.3389568],
                [106.633864, 22.338459],
                [106.6331774, 22.3375377],
                [106.632024, 22.3376618],
                [106.6310853, 22.3376816],
                [106.630212, 22.337305],
                [106.6288161, 22.336595],
                [106.6276199, 22.3364808],
                [106.6261715, 22.3358655],
                [106.6251093, 22.3350121],
                [106.6238272, 22.3347838],
                [106.6227919, 22.3345258],
                [106.622116, 22.3340296],
                [106.6215473, 22.3336376],
                [106.6212308, 22.3332804],
                [106.620796, 22.33309],
                [106.6199756, 22.3334193],
                [106.6187525, 22.3337269],
                [106.6181999, 22.3340892],
                [106.6174811, 22.3344018],
                [106.6172182, 22.334635],
                [106.6175401, 22.3362427],
                [106.61724, 22.337083],
                [106.6166067, 22.3375724],
                [106.6156948, 22.3384557],
                [106.6149652, 22.3392198],
                [106.6143536, 22.3400732],
                [106.613349, 22.340322],
                [106.6125512, 22.3405495],
                [106.6116285, 22.3394579],
                [106.6112584, 22.3385995],
                [106.6107273, 22.3383663],
                [106.6099602, 22.3381927],
                [106.6090375, 22.3382423],
                [106.607824, 22.338414],
                [106.6065967, 22.3386343],
                [106.6056418, 22.3386839],
                [106.6052341, 22.3384755],
                [106.6050732, 22.3378801],
                [106.6041881, 22.3376419],
                [106.6032493, 22.3376221],
                [106.602443, 22.337926],
                [106.6017204, 22.3383812],
                [106.6005188, 22.3388923],
                [106.5998483, 22.3385053],
                [106.5992582, 22.3382919],
                [106.5984321, 22.3384954],
                [106.5973753, 22.3388278],
                [106.5967423, 22.3395224],
                [106.5954494, 22.3398549],
                [106.5945375, 22.3405992],
                [106.5941995, 22.3410308],
                [106.5933305, 22.3408373],
                [106.5921289, 22.3415469],
                [106.591453, 22.3419736],
                [106.5908575, 22.3424003],
                [106.5903962, 22.3428915],
                [106.5901333, 22.343467],
                [106.5891194, 22.3432338],
                [106.58827, 22.342939],
                [106.587693, 22.342629],
                [106.586732, 22.341742],
                [106.586156, 22.341387],
                [106.584618, 22.340056],
                [106.583994, 22.339568],
                [106.582985, 22.339301],
                [106.582264, 22.339435],
                [106.581447, 22.339745],
                [106.580438, 22.340411],
                [106.579766, 22.34112],
                [106.579141, 22.341653],
                [106.5782618, 22.3424449],
                [106.5780902, 22.3431296],
                [106.5777147, 22.3447868],
                [106.5751076, 22.3451044],
                [106.574337, 22.344891],
                [106.5733909, 22.3435663],
                [106.5712666, 22.3414526],
                [106.5699792, 22.3418396],
                [106.5686059, 22.3427526],
                [106.5676832, 22.3432884],
                [106.5665674, 22.3441815],
                [106.5632415, 22.3460669],
                [106.56223, 22.347198],
                [106.561028, 22.347686],
                [106.56026, 22.348086],
                [106.559923, 22.348441],
                [106.559875, 22.348884],
                [106.5601301, 22.3496392],
                [106.561203, 22.3525565],
                [106.56219, 22.3576368],
                [106.5615892, 22.3625185],
                [106.5615034, 22.3643243],
                [106.5623188, 22.3644235],
                [106.5637135, 22.3660309],
                [106.5647435, 22.3663682],
                [106.5692925, 22.365257],
                [106.5702125, 22.3658299],
                [106.5704405, 22.3672115],
                [106.5713739, 22.368164],
                [106.571598, 22.369292],
                [106.5722537, 22.3696225],
                [106.575826, 22.37049],
                [106.577267, 22.370578],
                [106.578324, 22.3708],
                [106.579237, 22.3712],
                [106.580006, 22.371688],
                [106.580727, 22.372397],
                [106.581063, 22.372841],
                [106.582216, 22.373773],
                [106.583802, 22.375858],
                [106.5854287, 22.3777777],
                [106.5854502, 22.3805158],
                [106.5849352, 22.3814484],
                [106.582725, 22.3812103],
                [106.5814161, 22.3830952],
                [106.5838408, 22.3841468],
                [106.5853429, 22.3857142],
                [106.5860295, 22.3877776],
                [106.5861153, 22.3894243],
                [106.5855789, 22.3909123],
                [106.5865445, 22.393551],
                [106.5853429, 22.3951183],
                [106.58324, 22.3955548],
                [106.5818882, 22.3962888],
                [106.5775216, 22.3972114],
                [106.5761483, 22.3994531],
                [106.5739274, 22.4016255],
                [106.5727043, 22.4029546],
                [106.5721035, 22.4037085],
                [106.5711593, 22.4045417],
                [106.570816, 22.4062279],
                [106.569314, 22.4082712],
                [106.5686703, 22.4094217],
                [106.5676188, 22.4118419],
                [106.5648508, 22.4159282],
                [106.5648293, 22.4173564],
                [106.5646362, 22.4190821],
                [106.5631986, 22.4205004],
                [106.5624797, 22.421145],
                [106.5615463, 22.4217004],
                [106.5604949, 22.4221368],
                [106.5588599, 22.4216347],
                [106.5574043, 22.4217316],
                [106.5569875, 22.4216804],
                [106.5567183, 22.4215449],
                [106.5564477, 22.4212713],
                [106.5557542, 22.4203941],
                [106.5554538, 22.4201208],
                [106.5548876, 22.4199882],
                [106.5539657, 22.4199694],
                [106.5528545, 22.4202977],
                [106.5517608, 22.4208606],
                [106.5509303, 22.4210343],
                [106.550098, 22.4210423],
                [106.5485481, 22.4206981],
                [106.5475626, 22.4202933],
                [106.5465498, 22.4201096],
                [106.5449136, 22.4200148],
                [106.5437077, 22.4198469],
                [106.5396033, 22.4196926],
                [106.5385909, 22.4195366],
                [106.5379643, 22.4193216],
                [106.5374236, 22.4188296],
                [106.537126, 22.4188048],
                [106.5358224, 22.4192039],
                [106.5354657, 22.4192072],
                [106.535256, 22.4190711],
                [106.5350749, 22.4188243],
                [106.5348434, 22.4180393],
                [106.5346234, 22.4169642],
                [106.5342559, 22.4159735],
                [106.5339221, 22.415369],
                [106.5332615, 22.4147677],
                [106.5328125, 22.4144957],
                [106.5312942, 22.4142891],
                [106.5306079, 22.414047],
                [106.528547, 22.4131828],
                [106.5283225, 22.4130329],
                [106.5284692, 22.4128658],
                [106.5293286, 22.4126092],
                [106.5296538, 22.4124404],
                [106.5306514, 22.4112432],
                [106.5316217, 22.4102674],
                [106.5320882, 22.4094343],
                [106.5323109, 22.4080512],
                [106.5320282, 22.4080401],
                [106.5315242, 22.4081554],
                [106.530782, 22.4082453],
                [106.5300972, 22.4081413],
                [106.5297975, 22.4079232],
                [106.5293156, 22.4073478],
                [106.5283787, 22.405948],
                [106.5276272, 22.4051817],
                [106.5270573, 22.4047176],
                [106.5264319, 22.404613],
                [106.5251541, 22.4046526],
                [106.5243804, 22.4045772],
                [106.5238723, 22.4043334],
                [106.5222315, 22.4024293],
                [106.5212297, 22.4005053],
                [106.5207421, 22.399405],
                [106.5202899, 22.3988293],
                [106.5196898, 22.3983102],
                [106.5190948, 22.3982606],
                [106.5188285, 22.3983736],
                [106.5182382, 22.3987658],
                [106.5178525, 22.3988247],
                [106.5159345, 22.3987682],
                [106.5156695, 22.3989915],
                [106.5150264, 22.399991],
                [106.5145242, 22.4002993],
                [106.5125725, 22.4012283],
                [106.5123388, 22.4016169],
                [106.5121668, 22.402198],
                [106.5120204, 22.4023926],
                [106.5118429, 22.402477],
                [106.5110674, 22.4022359],
                [106.510264, 22.4021606],
                [106.5086004, 22.4022589],
                [106.5080383, 22.4025125],
                [106.507333, 22.4032642],
                [106.5068325, 22.4037105],
                [106.505858, 22.4043266],
                [106.504798, 22.4052473],
                [106.5046202, 22.4053042],
                [106.5043526, 22.4053066],
                [106.5040241, 22.4051717],
                [106.5025585, 22.4043298],
                [106.5019332, 22.4042253],
                [106.5010712, 22.4042333],
                [106.5003489, 22.4042966],
                [106.498721, 22.404706],
                [106.4977743, 22.4048681],
                [106.4973206, 22.4048723],
                [106.4966589, 22.4046869],
                [106.494951, 22.4030935],
                [106.4946992, 22.4026744],
                [106.4938312, 22.4024908],
                [106.4928408, 22.4024233],
                [106.491683, 22.4021275],
                [106.4908131, 22.4017524],
                [106.4900835, 22.401012],
                [106.4900369, 22.4005144],
                [106.4901485, 22.399364],
                [106.4903485, 22.3987874],
                [106.4905926, 22.3984787],
                [106.4931076, 22.3984555],
                [106.4934779, 22.3983754],
                [106.493763, 22.3980279],
                [106.4939225, 22.3975284],
                [106.4939168, 22.3969921],
                [106.4933807, 22.3950623],
                [106.4922585, 22.3942297],
                [106.4909742, 22.3936669],
                [106.4893602, 22.3931071],
                [106.4858094, 22.39268],
                [106.4851894, 22.3925324],
                [106.4846501, 22.3922309],
                [106.4840659, 22.3915849],
                [106.4837708, 22.3909746],
                [106.4835999, 22.3904015],
                [106.483595, 22.3899418],
                [106.4837158, 22.3896724],
                [106.4844559, 22.3894741],
                [106.4861643, 22.3892094],
                [106.4890017, 22.3884936],
                [106.4898627, 22.388026],
                [106.4902271, 22.3874097],
                [106.4903439, 22.3867572],
                [106.4903603, 22.3844199],
                [106.4896683, 22.3833344],
                [106.4890851, 22.382765],
                [106.4860605, 22.3813753],
                [106.482126, 22.3797256],
                [106.4810109, 22.3795442],
                [106.4797328, 22.3795559],
                [106.4794851, 22.3795198],
                [106.4791953, 22.3794076],
                [106.4791936, 22.3792544],
                [106.4793141, 22.3789467],
                [106.4804322, 22.3774615],
                [106.4810878, 22.3770724],
                [106.4825258, 22.3765994],
                [106.4842152, 22.3765073],
                [106.4855757, 22.3764948],
                [106.4872259, 22.3765947],
                [106.4884177, 22.3762389],
                [106.4888684, 22.3759665],
                [106.4890304, 22.3756967],
                [106.4893305, 22.374832],
                [106.4889955, 22.374337],
                [106.4888668, 22.3738785],
                [106.4877697, 22.3715131],
                [106.4877592, 22.3705171],
                [106.4874747, 22.368968],
                [106.4869734, 22.3683596],
                [106.4866012, 22.368248],
                [106.4852397, 22.3681456],
                [106.4831756, 22.3678964],
                [106.4819341, 22.367448],
                [106.47801, 22.3647827],
                [106.4768881, 22.3639501],
                [106.476225, 22.3636114],
                [106.4757716, 22.3636155],
                [106.4749084, 22.3638533],
                [106.4738766, 22.3637476],
                [106.4732954, 22.3633699],
                [106.4717618, 22.3625792],
                [106.4711835, 22.3624695],
                [106.4687926, 22.3624912],
                [106.4685444, 22.3624169],
                [106.468378, 22.3622651],
                [106.4680187, 22.3614063],
                [106.4673873, 22.360186],
                [106.4669275, 22.3595771],
                [106.466637, 22.3593882],
                [106.4663892, 22.3593522],
                [106.4657297, 22.3593581],
                [106.4649073, 22.3595572],
                [106.4641269, 22.3598325],
                [106.4627734, 22.360496],
                [106.4624857, 22.3605752],
                [106.4620734, 22.3605789],
                [106.4616184, 22.3604298],
                [106.4609076, 22.3594783],
                [106.4602825, 22.3588327],
                [106.4600344, 22.3587582],
                [106.4572958, 22.3590321],
                [106.4567171, 22.3588839],
                [106.4555994, 22.3584343],
                [106.4531927, 22.3569233],
                [106.4513382, 22.3569783],
                [106.4505166, 22.3572538],
                [106.4486923, 22.3582472],
                [106.4473376, 22.3587956],
                [106.4459778, 22.3588461],
                [106.4446602, 22.3590111],
                [106.4433454, 22.3594443],
                [106.4427326, 22.3599862],
                [106.4414278, 22.3613771],
                [106.4403737, 22.3631106],
                [106.4401293, 22.363381],
                [106.4398818, 22.3633832],
                [106.4392392, 22.3630249],
                [106.4385776, 22.3628393],
                [106.4382482, 22.3628805],
                [106.4377979, 22.363191],
                [106.4366083, 22.3637762],
                [106.4361138, 22.3637806],
                [106.4350399, 22.3635985],
                [106.4331787, 22.363002],
                [106.4313091, 22.361601],
                [106.4297452, 22.3598332],
                [106.4288305, 22.359075],
                [106.4266877, 22.3591706],
                [106.4252054, 22.3593368],
                [106.4236837, 22.3596951],
                [106.4223303, 22.3603965],
                [106.4213099, 22.3614017],
                [106.4188888, 22.3645455],
                [106.4181164, 22.3656252],
                [106.416933, 22.3668232],
                [106.4163196, 22.3673266],
                [106.4156669, 22.3680221],
                [106.4153018, 22.3685999],
                [106.4151031, 22.3693296],
                [106.414306, 22.3700071],
                [106.4134033, 22.3704364],
                [106.4127849, 22.3704418],
                [106.4114229, 22.3703005],
                [106.4089452, 22.3699006],
                [106.404758, 22.3696497],
                [106.4024928, 22.3698991],
                [106.4006821, 22.3702214],
                [106.3997377, 22.3706127],
                [106.3989617, 22.3713474],
                [106.397862, 22.3726978],
                [106.39762, 22.3732364],
                [106.3971287, 22.3735854],
                [106.3967596, 22.3737802],
                [106.3961004, 22.3738241],
                [106.3953564, 22.373639],
                [106.3949819, 22.3732974],
                [106.3932915, 22.3712238],
                [106.392001, 22.3699707],
                [106.3902996, 22.3688741],
                [106.3891775, 22.3679642],
                [106.3882792, 22.366765],
                [106.3878571, 22.3657725],
                [106.3873551, 22.3650488],
                [106.3868566, 22.3646699],
                [106.3864836, 22.3645497],
                [106.3853196, 22.3645123],
                [106.3851262, 22.364491],
                [106.3849589, 22.364399],
                [106.3832049, 22.363247],
                [106.382718, 22.363005],
                [106.3822106, 22.3628834],
                [106.3794585, 22.3625033],
                [106.3791388, 22.362165],
                [106.3784697, 22.3596198],
                [106.3780756, 22.3593076],
                [106.3677689, 22.358358],
                [106.3674178, 22.3583218],
                [106.3659296, 22.3579129],
                [106.364521, 22.3571969],
                [106.3634474, 22.3570143],
                [106.3620049, 22.3570647],
                [106.3608533, 22.3573427],
                [106.3604018, 22.357538],
                [106.359951, 22.35781],
                [106.3597073, 22.3581951],
                [106.3592851, 22.3592907],
                [106.3589, 22.3599644],
                [106.3571752, 22.3606495],
                [106.3556983, 22.361409],
                [106.3544339, 22.3617548],
                [106.3531811, 22.362225],
                [106.352282, 22.3630371],
                [106.3521197, 22.3633259],
                [106.3518999, 22.3640366],
                [106.351692, 22.3649291],
                [106.351306, 22.365507],
                [106.3511645, 22.3657955],
                [106.3502681, 22.366895],
                [106.3497171, 22.3685375],
                [106.349534, 22.3687882],
                [106.3491447, 22.3690405],
                [106.3484672, 22.3693335],
                [106.3470488, 22.3697475],
                [106.3464341, 22.3701359],
                [106.3462513, 22.3704247],
                [106.3459807, 22.3712221],
                [106.3456983, 22.3718757],
                [106.3453513, 22.3722234],
                [106.3449414, 22.3724758],
                [106.3443653, 22.3725956],
                [106.3438516, 22.3727723],
                [106.3435447, 22.3730047],
                [106.3420839, 22.3743866],
                [106.3411456, 22.3754288],
                [106.3410865, 22.3757168],
                [106.3416934, 22.3766504],
                [106.3433245, 22.3779875],
                [106.3437014, 22.3785974],
                [106.3439356, 22.3793617],
                [106.3440805, 22.3805004],
                [106.3445528, 22.3824504],
                [106.3445944, 22.3835709],
                [106.3443748, 22.3843007],
                [106.3437434, 22.3851105],
                [106.3435024, 22.3857831],
                [106.3433151, 22.3866754],
                [106.343302, 22.3874611],
                [106.3433452, 22.3876522],
                [106.3435977, 22.3881865],
                [106.3439713, 22.3895148],
                [106.3444852, 22.391503],
                [106.3444885, 22.3918477],
                [106.3443886, 22.3921742],
                [106.3439388, 22.3925803],
                [106.3437131, 22.3926971],
                [106.3426508, 22.3926389],
                [106.3422402, 22.3928147],
                [106.3416461, 22.393222],
                [106.3411147, 22.3937053],
                [106.34085, 22.3940523],
                [106.3400284, 22.3954672],
                [106.3396242, 22.3963135],
                [106.3391781, 22.3970834],
                [106.3383822, 22.397952],
                [106.3374732, 22.3988504],
                [106.3365719, 22.399471],
                [106.3359994, 22.3999738],
                [106.3354466, 22.4003806],
                [106.3340931, 22.4011581],
                [106.3335327, 22.4018428],
                [106.3328018, 22.4030558],
                [106.3326821, 22.4034782],
                [106.332793, 22.4043011],
                [106.3330175, 22.4051325],
                [106.3333849, 22.4068921],
                [106.3335764, 22.4075035],
                [106.3340281, 22.4083906],
                [106.3341356, 22.4088303],
                [106.334138, 22.4090985],
                [106.3340168, 22.4093677],
                [106.3337733, 22.4097721],
                [106.3334261, 22.4101197],
                [106.3319017, 22.4113679],
                [106.3306307, 22.4121447],
                [106.3295632, 22.4126516],
                [106.3289666, 22.4128097],
                [106.3285337, 22.4128134],
                [106.3281, 22.4127594],
                [106.3276238, 22.4125526],
                [106.3267392, 22.4116882],
                [106.3263834, 22.4111355],
                [106.3259451, 22.4105836],
                [106.324531, 22.4093308],
                [106.322885, 22.4086258],
                [106.3216231, 22.4081956],
                [106.3204863, 22.4079175],
                [106.3197225, 22.407828],
                [106.3187339, 22.4079509],
                [106.3140374, 22.4095697],
                [106.3129691, 22.4099999],
                [106.3121187, 22.4105719],
                [106.3116893, 22.4109585],
                [106.3110579, 22.4117875],
                [106.3100309, 22.4133188],
                [106.3092737, 22.4139379],
                [106.3080641, 22.4146948],
                [106.3071644, 22.4155067],
                [106.3058843, 22.4164463],
                [106.3051288, 22.4172378],
                [106.3040086, 22.4187411],
                [106.303552, 22.4195399],
                [106.3029794, 22.4200617],
                [106.3024042, 22.4202964],
                [106.3015817, 22.420552],
                [106.3010876, 22.4206518],
                [106.3005948, 22.4208664],
                [106.300349, 22.42106],
                [106.3001655, 22.4212722],
                [106.2995683, 22.4224743],
                [106.2991617, 22.4231099],
                [106.2985696, 22.4237468],
                [106.2979764, 22.4242688],
                [106.2971357, 22.4247929],
                [106.2966647, 22.4251414],
                [106.296379, 22.4254885],
                [106.2960106, 22.4257789],
                [106.2957429, 22.4258194],
                [106.2947212, 22.4257222],
                [106.2938945, 22.4255373],
                [106.2931086, 22.4252945],
                [106.291912, 22.4252466],
                [106.2912732, 22.4253092],
                [106.2895764, 22.4258112],
                [106.2884671, 22.4262798],
                [106.2878123, 22.4268407],
                [106.2873224, 22.427381],
                [106.2870793, 22.4278619],
                [106.2868504, 22.4287545],
                [106.2868179, 22.4297126],
                [106.2866389, 22.4315436],
                [106.2864621, 22.4325029],
                [106.2863905, 22.4336721],
                [106.2861452, 22.4350438],
                [106.2854204, 22.4369462],
                [106.2845973, 22.4382841],
                [106.2841877, 22.438594],
                [106.2827904, 22.4391607],
                [106.2821955, 22.4395294],
                [106.281909, 22.4397616],
                [106.2815838, 22.4402814],
                [106.2813542, 22.4411165],
                [106.281145, 22.443034],
                [106.2807297, 22.4438515],
                [106.2801989, 22.4444496],
                [106.2797918, 22.4450467],
                [106.2790356, 22.4457998],
                [106.2777163, 22.4470076],
                [106.2768581, 22.4478957],
                [106.2764061, 22.4480908],
                [106.2756641, 22.4481542],
                [106.2738288, 22.4482069],
                [106.2734999, 22.4483245],
                [106.2733358, 22.4484216],
                [106.2731929, 22.448576],
                [106.2727999, 22.449604],
                [106.2724554, 22.4502772],
                [106.2720878, 22.4506824],
                [106.2715136, 22.451051],
                [106.2708966, 22.4512474],
                [106.2702156, 22.4512145],
                [106.2694719, 22.4510862],
                [106.2692232, 22.4509732],
                [106.268934, 22.4509181],
                [106.2685009, 22.4509406],
                [106.2679582, 22.4513376],
                [106.2672803, 22.4516686],
                [106.2657788, 22.4521401],
                [106.2652861, 22.4523931],
                [106.2645306, 22.453242],
                [106.2644497, 22.453415],
                [106.2643056, 22.4534544],
                [106.2640992, 22.4534369],
                [106.2636202, 22.4529425],
                [106.2631896, 22.4520933],
                [106.2626253, 22.4512549],
                [106.2622078, 22.4507217],
                [106.2615857, 22.4503626],
                [106.2609047, 22.4503296],
                [106.2598944, 22.4503566],
                [106.2589266, 22.450575],
                [106.2583434, 22.451068],
                [106.2576522, 22.4522038],
                [106.2573093, 22.4530494],
                [106.2571499, 22.4536827],
                [106.2566492, 22.4541944],
                [106.2562593, 22.4544272],
                [106.2558685, 22.4545452],
                [106.2551051, 22.454532],
                [106.2544647, 22.4544221],
                [106.2541352, 22.4544821],
                [106.2537047, 22.4547728],
                [106.2526228, 22.4560456],
                [106.2518968, 22.456654],
                [106.2496044, 22.4580193],
                [106.2488336, 22.4583893],
                [106.2482664, 22.4585457],
                [106.2473139, 22.458648],
                [106.2469653, 22.4587473],
                [106.2460283, 22.4591602],
                [106.2440786, 22.4595393],
                [106.2435563, 22.4597476],
                [106.2419188, 22.4605483],
                [106.2402341, 22.4613061],
                [106.239836, 22.4614339],
                [106.2394158, 22.4614571],
                [106.2390821, 22.4613534],
                [106.2387138, 22.4611225],
                [106.23781, 22.4602412],
                [106.2374899, 22.4600194],
                [106.2371505, 22.4599005],
                [106.236209, 22.4597791],
                [106.235632, 22.4596291],
                [106.2343546, 22.4591603],
                [106.2337687, 22.4588454],
                [106.2334615, 22.4585672],
                [106.2332317, 22.4582344],
                [106.2331072, 22.4578717],
                [106.2329137, 22.4568429],
                [106.2327353, 22.4564194],
                [106.2324987, 22.4560409],
                [106.2321879, 22.4557196],
                [106.2319607, 22.4555619],
                [106.231313, 22.4552343],
                [106.2305675, 22.454975],
                [106.2302108, 22.4549014],
                [106.2294347, 22.4548518],
                [106.2272457, 22.4549297],
                [106.2238914, 22.4548943],
                [106.2223092, 22.4551086],
                [106.2216344, 22.4550906],
                [106.2213525, 22.4549873],
                [106.2209749, 22.4547029],
                [106.2196895, 22.4532556],
                [106.2192866, 22.4529152],
                [106.2189307, 22.4527526],
                [106.217445, 22.4523729],
                [106.2166019, 22.4519314],
                [106.2161729, 22.4516028],
                [106.2155103, 22.4508902],
                [106.2150476, 22.4502159],
                [106.2143334, 22.4488163],
                [106.214048, 22.4484918],
                [106.2132676, 22.4477753],
                [106.2130528, 22.4474734],
                [106.2125595, 22.4453556],
                [106.2124042, 22.4449507],
                [106.2115603, 22.4432396],
                [106.2110814, 22.4416033],
                [106.2109027, 22.4412391],
                [106.2106497, 22.4408903],
                [106.2103265, 22.440549],
                [106.2097769, 22.4400734],
                [106.2091899, 22.4396514],
                [106.2085885, 22.4393079],
                [106.2079567, 22.4390861],
                [106.206482, 22.4388805],
                [106.2059516, 22.4386434],
                [106.2055877, 22.4383007],
                [106.2054676, 22.4380215],
                [106.2054799, 22.4375524],
                [106.2057251, 22.4370968],
                [106.2061077, 22.4367445],
                [106.2062459, 22.4365601],
                [106.2063226, 22.4362193],
                [106.2062075, 22.4359776],
                [106.2059101, 22.4356931],
                [106.2041278, 22.4342671],
                [106.2016362, 22.4326466],
                [106.2012833, 22.4322591],
                [106.2010734, 22.4318075],
                [106.2008669, 22.4311019],
                [106.2006065, 22.4293149],
                [106.2004929, 22.428812],
                [106.2001608, 22.428183],
                [106.1992939, 22.4268568],
                [106.1992043, 22.4265749],
                [106.1993012, 22.426041],
                [106.2, 22.4245308],
                [106.2001066, 22.4240877],
                [106.2000827, 22.4233584],
                [106.1999264, 22.4227832],
                [106.1997835, 22.4224852],
                [106.19914, 22.4215353],
                [106.1982945, 22.4205893],
                [106.197597, 22.4199965],
                [106.1958411, 22.4188297],
                [106.1950957, 22.418189],
                [106.1932596, 22.4161139],
                [106.1930093, 22.4157045],
                [106.1929425, 22.4154247],
                [106.1929432, 22.415151],
                [106.1931065, 22.4143251],
                [106.1931304, 22.413948],
                [106.1930843, 22.4137544],
                [106.1928169, 22.4132592],
                [106.1927017, 22.4124871],
                [106.192463, 22.4119456],
                [106.1924419, 22.4116986],
                [106.1925778, 22.4111105],
                [106.1928885, 22.4102264],
                [106.1936264, 22.4085166],
                [106.1936541, 22.4082634],
                [106.1935862, 22.4079021],
                [106.1934176, 22.4075011],
                [106.1931372, 22.4070284],
                [106.1927698, 22.4065328],
                [106.1925098, 22.406267],
                [106.1919972, 22.4058507],
                [106.1908047, 22.4052369],
                [106.1905042, 22.4050071],
                [106.1897428, 22.4035184],
                [106.1886228, 22.4015824],
                [106.1883183, 22.4012899],
                [106.1873665, 22.4006813],
                [106.187203, 22.4005291],
                [106.1870888, 22.4003326],
                [106.1869315, 22.3994272],
                [106.1869495, 22.3988824],
                [106.1871601, 22.3980589],
                [106.1876054, 22.3967305],
                [106.1876529, 22.3964316],
                [106.1875552, 22.3960524],
                [106.1869675, 22.3952528],
                [106.1868608, 22.3949501],
                [106.1868882, 22.3943341],
                [106.1871194, 22.3935147],
                [106.1871054, 22.3930266],
                [106.1869668, 22.3926532],
                [106.1865764, 22.3921782],
                [106.1864582, 22.3919468],
                [106.1864422, 22.391675],
                [106.1865854, 22.3904636],
                [106.1864666, 22.3899665],
                [106.186131, 22.3893074],
                [106.1860173, 22.3886457],
                [106.1858963, 22.3884053],
                [106.1852027, 22.3876347],
                [106.1847986, 22.3870627],
                [106.1847959, 22.3868191],
                [106.1849784, 22.38639],
                [106.185522, 22.3855674],
                [106.1858855, 22.3851865],
                [106.1862292, 22.3849166],
                [106.186581, 22.3847133],
                [106.1875523, 22.384434],
                [106.1877954, 22.3842962],
                [106.1879455, 22.3841267],
                [106.1879962, 22.3838731],
                [106.1879718, 22.3836815],
                [106.187177, 22.38083],
                [106.1871214, 22.3802948],
                [106.1871744, 22.3799901],
                [106.1873226, 22.3797239],
                [106.1875623, 22.3794738],
                [106.1884311, 22.3788375],
                [106.1887902, 22.3785132],
                [106.1896534, 22.3772915],
                [106.1899891, 22.3769406],
                [106.1907639, 22.3763425],
                [106.1912741, 22.3760261],
                [106.1914487, 22.3760388],
                [106.1933715, 22.3757245],
                [106.1943739, 22.3754906],
                [106.1955929, 22.3751179],
                [106.1972756, 22.374838],
                [106.1992468, 22.374102],
                [106.201174, 22.3734911],
                [106.2014782, 22.3733597],
                [106.2018754, 22.373058],
                [106.2024692, 22.3723581],
                [106.2030166, 22.3718221],
                [106.204038, 22.3710574],
                [106.2052909, 22.3702149],
                [106.2075673, 22.3689387],
                [106.2084454, 22.3683829],
                [106.2088507, 22.3680088],
                [106.2090786, 22.3676272],
                [106.2091807, 22.3671817],
                [106.2091821, 22.3660333],
                [106.2092358, 22.3657466],
                [106.209492, 22.365126],
                [106.2103723, 22.3635687],
                [106.2104994, 22.3630988],
                [106.2105546, 22.3623183],
                [106.2106803, 22.362016],
                [106.2108663, 22.3618158],
                [106.2112458, 22.3615855],
                [106.2127318, 22.3609723],
                [106.2134366, 22.3606123],
                [106.2154887, 22.3592507],
                [106.2160793, 22.3588109],
                [106.216532, 22.3583406],
                [106.2173045, 22.357327],
                [106.2179431, 22.3564115],
                [106.2182093, 22.3558889],
                [106.2184283, 22.3547559],
                [106.2186106, 22.3540963],
                [106.2186802, 22.3530532],
                [106.2187675, 22.3526511],
                [106.2189474, 22.3522492],
                [106.2197015, 22.3509695],
                [106.2199404, 22.3502863],
                [106.2200044, 22.3497106],
                [106.2199994, 22.3492015],
                [106.219896, 22.3487237],
                [106.2196328, 22.3479562],
                [106.2193682, 22.3474871],
                [106.2189196, 22.3470575],
                [106.2184374, 22.3468299],
                [106.2174593, 22.3465489],
                [106.2168685, 22.3463157],
                [106.2163818, 22.3459978],
                [106.215826, 22.3454809],
                [106.2155637, 22.3451315],
                [106.215416, 22.3448002],
                [106.214895, 22.3428624],
                [106.2146587, 22.3424304],
                [106.2142455, 22.3419503],
                [106.2136714, 22.3416335],
                [106.2120808, 22.3411328],
                [106.2117685, 22.3409538],
                [106.211538, 22.3407288],
                [106.2108925, 22.3396267],
                [106.2098503, 22.3383276],
                [106.2090148, 22.3374623],
                [106.2088806, 22.3372524],
                [106.2088528, 22.3369543],
                [106.2091735, 22.3360973],
                [106.2092012, 22.3358997],
                [106.2091568, 22.3357255],
                [106.2089267, 22.3353113],
                [106.2085892, 22.334929],
                [106.2075009, 22.3341281],
                [106.2071701, 22.333825],
                [106.2057552, 22.3319978],
                [106.2054591, 22.3311092],
                [106.2050441, 22.330175],
                [106.2049145, 22.329781],
                [106.2049355, 22.3294545],
                [106.2050884, 22.3288161],
                [106.2049443, 22.3283502],
                [106.2044651, 22.3276018],
                [106.2034888, 22.326389],
                [106.2017176, 22.3238084],
                [106.2014791, 22.3233668],
                [106.2013076, 22.32287],
                [106.2011506, 22.3217459],
                [106.2011415, 22.3201533],
                [106.2013118, 22.319219],
                [106.2019035, 22.317702],
                [106.2019369, 22.3172039],
                [106.201732, 22.3166451],
                [106.2015347, 22.3163036],
                [106.2002111, 22.3145966],
                [106.1997455, 22.313887],
                [106.1996049, 22.3133995],
                [106.1996092, 22.3129813],
                [106.1997652, 22.3124222],
                [106.2006391, 22.3101948],
                [106.2010404, 22.3093428],
                [106.2017142, 22.3084969],
                [106.2031391, 22.3070327],
                [106.2033727, 22.3066807],
                [106.2036878, 22.3051465],
                [106.2037957, 22.3048768],
                [106.2043699, 22.3041875],
                [106.2045883, 22.3037188],
                [106.2046509, 22.303413],
                [106.20475, 22.302143],
                [106.2048851, 22.3017236],
                [106.2051687, 22.3013281],
                [106.2053828, 22.3011173],
                [106.2067438, 22.3000823],
                [106.2071356, 22.299699],
                [106.207854, 22.2987554],
                [106.2086434, 22.2973635],
                [106.2089989, 22.2964518],
                [106.2095522, 22.2947108],
                [106.2098332, 22.2940059],
                [106.2105181, 22.2925676],
                [106.2111406, 22.291481],
                [106.2114098, 22.2911039],
                [106.2120067, 22.2904492],
                [106.2127652, 22.289767],
                [106.2135773, 22.2891718],
                [106.2143868, 22.2887462],
                [106.2159223, 22.2882661],
                [106.2161731, 22.2881209],
                [106.2166008, 22.2877501],
                [106.216715, 22.2877485],
                [106.2168702, 22.287818],
                [106.2183386, 22.2885882],
                [106.2190929, 22.2890413],
                [106.2195813, 22.2894661],
                [106.2201192, 22.2901265],
                [106.220373, 22.2903661],
                [106.2206057, 22.2904142],
                [106.2208713, 22.2902929],
                [106.2224361, 22.2888784],
                [106.2233985, 22.2881336],
                [106.2236598, 22.2878375],
                [106.2238855, 22.28746],
                [106.2241183, 22.2868475],
                [106.2242569, 22.2861637],
                [106.224316, 22.2854549],
                [106.2243693, 22.2833165],
                [106.2244482, 22.2828659],
                [106.2246039, 22.2825332],
                [106.2247691, 22.2824358],
                [106.2250529, 22.2824167],
                [106.2269869, 22.2827427],
                [106.227325, 22.2827513],
                [106.2276165, 22.2826953],
                [106.2281188, 22.2824333],
                [106.2302069, 22.2808507],
                [106.2314375, 22.2800691],
                [106.2324602, 22.2796281],
                [106.2342819, 22.2791402],
                [106.2348157, 22.2788916],
                [106.2351305, 22.278616],
                [106.2387544, 22.2748503],
                [106.2392586, 22.2742361],
                [106.2397552, 22.273371],
                [106.2400922, 22.2728949],
                [106.2414645, 22.2715293],
                [106.2429259, 22.269939],
                [106.244062, 22.268855],
                [106.2443765, 22.2684976],
                [106.2447173, 22.2680098],
                [106.244955, 22.2675397],
                [106.2450944, 22.2670744],
                [106.2452065, 22.2658297],
                [106.2451533, 22.2646532],
                [106.2448048, 22.2617465],
                [106.2448215, 22.2608684],
                [106.2449621, 22.2603869],
                [106.2452183, 22.2598934],
                [106.2463379, 22.2583383],
                [106.246724, 22.2577164],
                [106.2469677, 22.2571143],
                [106.2469976, 22.2566667],
                [106.2468925, 22.2561356],
                [106.2466363, 22.2553844],
                [106.2454176, 22.252516],
                [106.2448771, 22.251495],
                [106.2433752, 22.2488851],
                [106.2430546, 22.2484115],
                [106.242629, 22.2477828],
                [106.2422169, 22.246842],
                [106.2419892, 22.2457882],
                [106.2418915, 22.2445911],
                [106.241893, 22.2435253],
                [106.2418141, 22.2420034],
                [106.2418518, 22.2413332],
                [106.2422238, 22.2402036],
                [106.2427845, 22.2394279],
                [106.2429752, 22.2387972],
                [106.2429277, 22.2383509],
                [106.2427051, 22.2378654],
                [106.2419005, 22.2369479],
                [106.2412599, 22.2360798],
                [106.2408809, 22.2351691],
                [106.2406172, 22.2337196],
                [106.2400748, 22.2315923],
                [106.2400743, 22.2303234],
                [106.2397986, 22.228742],
                [106.2394214, 22.2280344],
                [106.2385309, 22.2272596],
                [106.2380241, 22.2267154],
                [106.2378023, 22.226311],
                [106.2376206, 22.2254801],
                [106.2376271, 22.222506],
                [106.2375339, 22.2218367],
                [106.2371584, 22.2213118],
                [106.236184, 22.2209234],
                [106.2350038, 22.220709],
                [106.2341745, 22.2207152],
                [106.2332156, 22.2208443],
                [106.2326262, 22.2208285],
                [106.2323417, 22.2207291],
                [106.231727, 22.2203075],
                [106.2311748, 22.2195403],
                [106.230588, 22.2185601],
                [106.2292357, 22.2161342],
                [106.2271973, 22.215094],
                [106.2262538, 22.214492],
                [106.2255086, 22.2141119],
                [106.2251352, 22.2138305],
                [106.2249347, 22.2133651],
                [106.2250326, 22.2120651],
                [106.2249574, 22.2109289],
                [106.224737, 22.2106869],
                [106.224342, 22.210426],
                [106.2232238, 22.209805],
                [106.2227247, 22.2088648],
                [106.2222412, 22.2084624],
                [106.2219323, 22.208079],
                [106.2214924, 22.2076763],
                [106.2213147, 22.2073122],
                [106.2213531, 22.2067029],
                [106.221573, 22.2056354],
                [106.2217163, 22.2045382],
                [106.2222512, 22.2033364],
                [106.223209, 22.2018167],
                [106.2232932, 22.2014506],
                [106.2233103, 22.2009227],
                [106.2228458, 22.2001751],
                [106.2219751, 22.1991564],
                [106.2216874, 22.1986917],
                [106.2211799, 22.1980256],
                [106.2212173, 22.1973147],
                [106.223838, 22.192565],
                [106.224136, 22.1917101],
                [106.2245422, 22.1907529],
                [106.225736, 22.1887849],
                [106.2266421, 22.1876006],
                [106.2280134, 22.1859966],
                [106.230461, 22.1839684],
                [106.2313725, 22.1834133],
                [106.2325025, 22.1829175],
                [106.2333201, 22.1828607],
                [106.2337721, 22.1821466],
                [106.2340259, 22.1812515],
                [106.2340419, 22.1805815],
                [106.2339133, 22.1795978],
                [106.2334787, 22.1772869],
                [106.2331556, 22.1765078],
                [106.2325151, 22.1756193],
                [106.2314788, 22.1743482],
                [106.2305656, 22.1734313],
                [106.2297535, 22.1728691],
                [106.2290066, 22.1722656],
                [106.2280828, 22.1713794],
                [106.2266652, 22.1701618],
                [106.225809, 22.1695187],
                [106.2248226, 22.1689779],
                [106.2227155, 22.1674915],
                [106.2196011, 22.1667739],
                [106.2179003, 22.1668475],
                [106.2174198, 22.1667901],
                [106.2170042, 22.1666512],
                [106.2166086, 22.166309],
                [106.2157995, 22.1648026],
                [106.2147613, 22.1632878],
                [106.214344, 22.161677],
                [106.2137636, 22.1601384],
                [106.2129555, 22.1587538],
                [106.2126032, 22.1583504],
                [106.2122746, 22.1581904],
                [106.2118371, 22.1580516],
                [106.2109638, 22.1579565],
                [106.2105265, 22.1578379],
                [106.2101321, 22.1576175],
                [106.2094916, 22.1566886],
                [106.2088829, 22.1556475],
                [106.2082866, 22.154779],
                [106.2074065, 22.1538922],
                [106.2028945, 22.150383],
                [106.2017341, 22.1498637],
                [106.2011294, 22.1492896],
                [106.2006968, 22.1471409],
                [106.2002414, 22.1461596],
                [106.1998672, 22.1457564],
                [106.1992086, 22.1452537],
                [106.1982231, 22.1447737],
                [106.1975641, 22.1442101],
                [106.1971436, 22.1435027],
                [106.196539, 22.1429285],
                [106.1950548, 22.1427973],
                [106.194554, 22.1428821],
                [106.1941193, 22.143068],
                [106.1929911, 22.1437665],
                [106.1924692, 22.1439531],
                [106.1921642, 22.1439756],
                [106.1917713, 22.1439378],
                [106.1914866, 22.1437978],
                [106.1913536, 22.1435552],
                [106.1912509, 22.1430179],
                [106.1908712, 22.1419448],
                [106.1903427, 22.1413396],
                [106.1895745, 22.1407565],
                [106.1889668, 22.1398169],
                [106.1887434, 22.1391892],
                [106.1887124, 22.1380932],
                [106.188776, 22.1365803],
                [106.1885937, 22.1356477],
                [106.1882591, 22.1347568],
                [106.1877069, 22.1339082],
                [106.1865787, 22.1333176],
                [106.1854169, 22.1325952],
                [106.1837076, 22.1316128],
                [106.1825231, 22.130789],
                [106.1813231, 22.1294071],
                [106.1810797, 22.1289825],
                [106.1810328, 22.1285971],
                [106.1810737, 22.128272],
                [106.1819175, 22.1274741],
                [106.1837272, 22.1261313],
                [106.1839432, 22.1258861],
                [106.1843521, 22.1252335],
                [106.184608, 22.1245414],
                [106.1848283, 22.1234943],
                [106.1855582, 22.1221288],
                [106.1859647, 22.1211717],
                [106.1859247, 22.1203092],
                [106.1857208, 22.1193971],
                [106.1852949, 22.1180197],
                [106.185225, 22.1174721],
                [106.1845137, 22.1171625],
                [106.1825017, 22.1164464],
                [106.181275, 22.1158055],
                [106.1799169, 22.1150439],
                [106.1789618, 22.1142691],
                [106.1771849, 22.1129827],
                [106.1769844, 22.1124563],
                [106.1767722, 22.1105496],
                [106.1766397, 22.1103475],
                [106.176421, 22.1102679],
                [106.1762034, 22.1103099],
                [106.1758991, 22.1104544],
                [106.1753378, 22.111108],
                [106.175036, 22.1115366],
                [106.1737466, 22.1124898],
                [106.17318, 22.1125344],
                [106.1723068, 22.1124393],
                [106.1702957, 22.111804],
                [106.169333, 22.1114252],
                [106.1685723, 22.1103851],
                [106.1684812, 22.1099189],
                [106.1685134, 22.1085381],
                [106.1684653, 22.1066606],
                [106.1683972, 22.1063362],
                [106.1682191, 22.1058909],
                [106.1674936, 22.1051652],
                [106.166594, 22.1044814],
                [106.1653371, 22.1040945],
                [106.1641357, 22.1038188],
                [106.1634126, 22.1033977],
                [106.1631044, 22.1030345],
                [106.1627068, 22.1024078],
                [106.162358, 22.1010806],
                [106.1622618, 22.099985],
                [106.1620135, 22.0989514],
                [106.1616595, 22.0983246],
                [106.1609419, 22.0972232],
                [106.1608088, 22.0969399],
                [106.1606505, 22.0962508],
                [106.1611881, 22.0940037],
                [106.1613333, 22.0931094],
                [106.1613274, 22.0923786],
                [106.1607411, 22.0913372],
                [106.1602532, 22.0903256],
                [106.1594784, 22.0888897],
                [106.1592204, 22.0880084],
                [106.1590611, 22.0871772],
                [106.1586144, 22.0858811],
                [106.1576996, 22.084639],
                [106.1574508, 22.0835242],
                [106.1572654, 22.0821855],
                [106.1570965, 22.081527],
                [106.1565075, 22.0814702],
                [106.1520933, 22.0815116],
                [106.1498909, 22.0814459],
                [106.1477327, 22.081461],
                [106.146306, 22.0816232],
                [106.1450422, 22.081693],
                [106.1422539, 22.0819561],
                [106.1412969, 22.0822267],
                [106.1409278, 22.0824323],
                [106.1407331, 22.0826165],
                [106.1403355, 22.0833196],
                [106.1398054, 22.0851911],
                [106.1393514, 22.0856611],
                [106.1391992, 22.0857028],
                [106.1362091, 22.0852973],
                [106.1354148, 22.0854753],
                [106.1345692, 22.0860496],
                [106.1341772, 22.0861132],
                [106.1329995, 22.0860606],
                [106.1318208, 22.0858658],
                [106.1303458, 22.0854394],
                [106.1283594, 22.0851283],
                [106.1243599, 22.0852675],
                [106.1233129, 22.0851936],
                [106.1226359, 22.0850561],
                [106.1211917, 22.0843758],
                [106.1200511, 22.0834903],
                [106.1189658, 22.0827161],
                [106.1185669, 22.0818864],
                [106.1182963, 22.0807514],
                [106.1177543, 22.0769689],
                [106.1173233, 22.0762308],
                [106.1164442, 22.0753232],
                [106.1155228, 22.0745783],
                [106.1148852, 22.0738924],
                [106.114463, 22.0728599],
                [106.1142655, 22.0726988],
                [106.1132325, 22.0730204],
                [106.1122532, 22.0732302],
                [106.1112555, 22.0738663],
                [106.1093475, 22.0751989],
                [106.10833, 22.0760991],
                [106.1081123, 22.0761412],
                [106.1079595, 22.0761219],
                [106.1077949, 22.0759707],
                [106.1076084, 22.0744291],
                [106.1072526, 22.0735383],
                [106.106639, 22.073116],
                [106.105514, 22.0728292],
                [106.1024164, 22.0725456],
                [106.1010186, 22.0722099],
                [106.1005585, 22.0719085],
                [106.1002508, 22.0715857],
                [106.0999672, 22.0701564],
                [106.0989953, 22.0684981],
                [106.0988608, 22.0680321],
                [106.0988368, 22.067748],
                [106.0989496, 22.0668235],
                [106.100483, 22.0635751],
                [106.1010566, 22.0616425],
                [106.1012029, 22.0608294],
                [106.1011224, 22.060292],
                [106.1002206, 22.0592424],
                [106.0996011, 22.058069],
                [106.099344, 22.0572282],
                [106.0992719, 22.0563557],
                [106.0992619, 22.0550564],
                [106.0994389, 22.0539894],
                [106.0996509, 22.0532166],
                [106.1001412, 22.0517921],
                [106.1006238, 22.050784],
                [106.1008573, 22.0499702],
                [106.1016073, 22.0483207],
                [106.1020908, 22.047414],
                [106.1024979, 22.0465181],
                [106.1027086, 22.0455828],
                [106.1027263, 22.0450548],
                [106.1024468, 22.0441532],
                [106.1015367, 22.0420073],
                [106.1012556, 22.0409028],
                [106.1009462, 22.0403364],
                [106.1004186, 22.0397512],
                [106.0994721, 22.0385394],
                [106.098727, 22.0380166],
                [106.0973627, 22.0377313],
                [106.0964443, 22.0373315],
                [106.095962, 22.0369693],
                [106.0956543, 22.0366262],
                [106.0955641, 22.036241],
                [106.0957115, 22.0355701],
                [106.0960419, 22.0346036],
                [106.0963072, 22.032267],
                [106.096507, 22.0313217],
                [106.0966988, 22.0307519],
                [106.0970636, 22.0300389],
                [106.0974318, 22.0297319],
                [106.0986266, 22.0292367],
                [106.100765, 22.0281768],
                [106.1017207, 22.0277644],
                [106.1023487, 22.0272526],
                [106.1031685, 22.0261913],
                [106.1042377, 22.0249559],
                [106.1047114, 22.0242014],
                [106.1054677, 22.0233843],
                [106.1064423, 22.0226062],
                [106.1081016, 22.0216103],
                [106.1085551, 22.0210795],
                [106.1093248, 22.0191861],
                [106.1107313, 22.0151162],
                [106.1110962, 22.0144033],
                [106.1112012, 22.0138949],
                [106.1111752, 22.013347],
                [106.1110635, 22.0130026],
                [106.1105353, 22.0123363],
                [106.1103241, 22.0117997],
                [106.1103201, 22.0112921],
                [106.1104052, 22.0110073],
                [106.1107492, 22.0104366],
                [106.110899, 22.0100498],
                [106.1108712, 22.0079081],
                [106.1108239, 22.0074212],
                [106.1107118, 22.0070159],
                [106.1103157, 22.0065314],
                [106.1097674, 22.0060682],
                [106.1095758, 22.0052471],
                [106.1101174, 22.0048375],
                [106.1103326, 22.0044909],
                [106.1103962, 22.0042671],
                [106.1103703, 22.0037394],
                [106.1101265, 22.0032133],
                [106.1101293, 22.0021676],
                [106.109921, 22.0006058],
                [106.1095662, 21.9998164],
                [106.108916, 21.9990143],
                [106.1076657, 21.9978828],
                [106.1075502, 21.9977063],
                [106.1075211, 21.9975072],
                [106.1075707, 21.9973967],
                [106.1076944, 21.9973113],
                [106.1090672, 21.9969949],
                [106.1099283, 21.9967359],
                [106.1112094, 21.9961461],
                [106.1124482, 21.9953503],
                [106.1134816, 21.9945623],
                [106.1141513, 21.9939279],
                [106.1144118, 21.9934786],
                [106.1145003, 21.9931321],
                [106.1144951, 21.9926869],
                [106.1144043, 21.992407],
                [106.1139277, 21.9914336],
                [106.1137576, 21.9906856],
                [106.1137313, 21.989509],
                [106.1138171, 21.9871809],
                [106.1137882, 21.9866573],
                [106.1136038, 21.9857821],
                [106.1131042, 21.9840876],
                [106.1130462, 21.9834487],
                [106.1131512, 21.9827981],
                [106.1135182, 21.9817901],
                [106.1139546, 21.9809664],
                [106.1149944, 21.9792824],
                [106.1152939, 21.9785595],
                [106.1153992, 21.9779866],
                [106.1153396, 21.9777324],
                [106.1151911, 21.9774946],
                [106.1142769, 21.9765181],
                [106.1139062, 21.9759867],
                [106.1135933, 21.9752985],
                [106.1133553, 21.9745163],
                [106.1132759, 21.9740096],
                [106.1132859, 21.9735256],
                [106.1133989, 21.9730246],
                [106.1139042, 21.9713813],
                [106.114009, 21.9707261],
                [106.1140079, 21.970189],
                [106.1139043, 21.9690536],
                [106.1139761, 21.9676685],
                [106.1138721, 21.9672177],
                [106.1137013, 21.9669332],
                [106.1126305, 21.9659801],
                [106.1124585, 21.9657798],
                [106.1123661, 21.9655751],
                [106.1123734, 21.9651791],
                [106.1127103, 21.9640319],
                [106.1127101, 21.9636314],
                [106.1125195, 21.9630353],
                [106.1121407, 21.9624716],
                [106.1116598, 21.9620772],
                [106.1114998, 21.9620122],
                [106.1111848, 21.9619696],
                [106.1106577, 21.9620774],
                [106.1094998, 21.9625423],
                [106.1086927, 21.9627834],
                [106.1076852, 21.9628688],
                [106.1071335, 21.9628387],
                [106.1065812, 21.9627454],
                [106.1060794, 21.9625726],
                [106.1054625, 21.9621981],
                [106.1051378, 21.9618599],
                [106.1045912, 21.9610603],
                [106.104279, 21.9608513],
                [106.103807, 21.96076],
                [106.1024213, 21.9606712],
                [106.1017027, 21.9605832],
                [106.100329, 21.9602419],
                [106.0998306, 21.9600406],
                [106.0993433, 21.9597388],
                [106.0989793, 21.9593969],
                [106.0988223, 21.9591513],
                [106.0987062, 21.9587412],
                [106.0983832, 21.9566053],
                [106.0979071, 21.9544986],
                [106.0978241, 21.9538854],
                [106.0978749, 21.9529791],
                [106.0980271, 21.9522563],
                [106.0983791, 21.9515371],
                [106.0986885, 21.9512098],
                [106.0988691, 21.951091],
                [106.099278, 21.9509313],
                [106.0996822, 21.9508877],
                [106.0998703, 21.9509234],
                [106.1003402, 21.9512086],
                [106.1017844, 21.9525145],
                [106.1022463, 21.9528664],
                [106.1027615, 21.9530678],
                [106.1033071, 21.9530882],
                [106.103723, 21.95299],
                [106.1050741, 21.9524832],
                [106.1056524, 21.9523487],
                [106.1086907, 21.9522892],
                [106.1093509, 21.9522261],
                [106.10981, 21.9521078],
                [106.1105278, 21.9517048],
                [106.1113204, 21.9509802],
                [106.1115378, 21.950724],
                [106.1117931, 21.9503102],
                [106.1118753, 21.9498862],
                [106.1118335, 21.9496451],
                [106.1116984, 21.9493579],
                [106.1106124, 21.9477006],
                [106.109963, 21.9468829],
                [106.1092721, 21.9461948],
                [106.1075594, 21.9448883],
                [106.1056974, 21.9429463],
                [106.105211, 21.9426238],
                [106.1039544, 21.9419498],
                [106.1036076, 21.941648],
                [106.1028999, 21.9408601],
                [106.1024773, 21.9405147],
                [106.1019606, 21.9402421],
                [106.1002172, 21.9396261],
                [106.099702, 21.9393243],
                [106.0993238, 21.9389962],
                [106.0989252, 21.938512],
                [106.0986511, 21.9380107],
                [106.0985894, 21.9376758],
                [106.0986133, 21.9375758],
                [106.0988878, 21.9372964],
                [106.0995411, 21.937027],
                [106.1005989, 21.9368479],
                [106.1025516, 21.9367242],
                [106.103395, 21.9366102],
                [106.1040703, 21.9364355],
                [106.1049151, 21.936081],
                [106.105546, 21.9355956],
                [106.1059877, 21.9350006],
                [106.1061741, 21.9345803],
                [106.1063689, 21.9337581],
                [106.106547, 21.9333736],
                [106.1067082, 21.9332076],
                [106.1072012, 21.9329595],
                [106.1078353, 21.932855],
                [106.1085124, 21.9329279],
                [106.109156, 21.9332005],
                [106.1099388, 21.93372],
                [106.1103204, 21.933897],
                [106.1106662, 21.9339081],
                [106.1110107, 21.9337832],
                [106.1113401, 21.9335714],
                [106.1116688, 21.9332722],
                [106.1120119, 21.932867],
                [106.1122986, 21.9323987],
                [106.1124695, 21.9319138],
                [106.1126626, 21.9308864],
                [106.1128338, 21.9302688],
                [106.113094, 21.9296587],
                [106.1134135, 21.9292442],
                [106.1136137, 21.9291201],
                [106.1141578, 21.9289994],
                [106.1154905, 21.9289791],
                [106.1161588, 21.9289011],
                [106.1169286, 21.9286877],
                [106.1176382, 21.9283961],
                [106.1181793, 21.9280553],
                [106.1187352, 21.9275526],
                [106.1203602, 21.925759],
                [106.1209737, 21.9251528],
                [106.1215687, 21.9247437],
                [106.1221264, 21.9245237],
                [106.1228233, 21.9244168],
                [106.1235578, 21.9244146],
                [106.1265567, 21.9248768],
                [106.1272855, 21.9249414],
                [106.127668, 21.9248964],
                [106.1282039, 21.9247119],
                [106.1288274, 21.9243323],
                [106.1294046, 21.923827],
                [106.1303651, 21.9228473],
                [106.1310527, 21.9220411],
                [106.1313127, 21.9216401],
                [106.1314645, 21.9212508],
                [106.1315795, 21.9204167],
                [106.1316328, 21.9185849],
                [106.1317467, 21.9168438],
                [106.1317139, 21.9165127],
                [106.1315791, 21.9161046],
                [106.1313171, 21.9156857],
                [106.1309075, 21.9152732],
                [106.1295392, 21.9144184],
                [106.1292459, 21.9141736],
                [106.1288275, 21.9137241],
                [106.1284824, 21.9132057],
                [106.1279742, 21.9120438],
                [106.1273348, 21.9110835],
                [106.1271292, 21.9105269],
                [106.1271671, 21.9101034],
                [106.1278192, 21.908844],
                [106.1278587, 21.9085011],
                [106.1277579, 21.9075063],
                [106.1278399, 21.9071175],
                [106.1279943, 21.9068922],
                [106.1286577, 21.9062773],
                [106.1294274, 21.9054588],
                [106.1297314, 21.9050541],
                [106.1298221, 21.904711],
                [106.1296984, 21.9035996],
                [106.1295631, 21.9032157],
                [106.1290618, 21.9025904],
                [106.128162, 21.9017829],
                [106.1280062, 21.9013677],
                [106.1277425, 21.9002121],
                [106.1273662, 21.8993517],
                [106.1271292, 21.8991315],
                [106.1266786, 21.898954],
                [106.1261899, 21.8988754],
                [106.1248844, 21.8988084],
                [106.1243741, 21.8987206],
                [106.1238569, 21.8984241],
                [106.1230343, 21.897576],
                [106.1225563, 21.8972242],
                [106.1222421, 21.8970764],
                [106.1215598, 21.8968613],
                [106.1209232, 21.8967654],
                [106.119822, 21.8967145],
                [106.1193567, 21.8966122],
                [106.1153964, 21.8944676],
                [106.1149885, 21.8941633],
                [106.1148171, 21.8938627],
                [106.1147246, 21.8934915],
                [106.1146527, 21.8925932],
                [106.1145421, 21.8922577],
                [106.1143211, 21.8919185],
                [106.1134066, 21.8908817],
                [106.1132607, 21.8906],
                [106.113233, 21.8904433],
                [106.113293, 21.8897022],
                [106.1138839, 21.8869877],
                [106.1138955, 21.8849678],
                [106.1139841, 21.8842303],
                [106.11406, 21.8839882],
                [106.1143216, 21.8834932],
                [106.1156786, 21.8816925],
                [106.1158632, 21.8812844],
                [106.1159972, 21.8805672],
                [106.1160321, 21.8800314],
                [106.1159901, 21.8792703],
                [106.1158018, 21.8781677],
                [106.115639, 21.8775132],
                [106.1153901, 21.8770182],
                [106.1142169, 21.8759484],
                [106.1140788, 21.8755613],
                [106.1141267, 21.8752878],
                [106.1143085, 21.8750336],
                [106.1152438, 21.8744216],
                [106.1153799, 21.8742438],
                [106.1154746, 21.8739829],
                [106.1155352, 21.8730692],
                [106.1154142, 21.8709129],
                [106.1152871, 21.8700446],
                [106.1151154, 21.8696209],
                [106.1146514, 21.8690588],
                [106.113897, 21.868456],
                [106.1137649, 21.8682506],
                [106.1136769, 21.8672249],
                [106.1135318, 21.8668477],
                [106.1132144, 21.8664642],
                [106.1120894, 21.8654956],
                [106.1118081, 21.865133],
                [106.1115088, 21.8645812],
                [106.1113603, 21.8641217],
                [106.1108424, 21.8617985],
                [106.1107051, 21.8608308],
                [106.1106982, 21.8602891],
                [106.110871, 21.8597997],
                [106.1113395, 21.8590474],
                [106.1115168, 21.8586738],
                [106.1116085, 21.8582056],
                [106.1116664, 21.8570165],
                [106.1118053, 21.8564859],
                [106.1120019, 21.8560775],
                [106.1126337, 21.8551137],
                [106.1127319, 21.8548357],
                [106.112739, 21.854366],
                [106.11267, 21.8539362],
                [106.1120878, 21.8522436],
                [106.1118981, 21.8514828],
                [106.1117908, 21.850191],
                [106.1118806, 21.849496],
                [106.112099, 21.8491211],
                [106.1125619, 21.848756],
                [106.1143698, 21.8478454],
                [106.1149838, 21.8474782],
                [106.1154671, 21.8470846],
                [106.1173693, 21.8453146],
                [106.1177619, 21.8448447],
                [106.1180801, 21.8442702],
                [106.1181179, 21.8438282],
                [106.1179542, 21.8432498],
                [106.1175304, 21.8422427],
                [106.1172841, 21.8418339],
                [106.1170026, 21.8414927],
                [106.1166623, 21.841212],
                [106.1162126, 21.8409766],
                [106.115118, 21.8406354],
                [106.1142345, 21.8404433],
                [106.1134515, 21.8403322],
                [106.1124522, 21.8402666],
                [106.1116104, 21.8402892],
                [106.1110451, 21.840408],
                [106.109517, 21.8409859],
                [106.1088229, 21.8410968],
                [106.1082611, 21.8410607],
                [106.1078124, 21.8409554],
                [106.1074569, 21.84078],
                [106.1073056, 21.8406556],
                [106.1070296, 21.8402886],
                [106.1067768, 21.8397578],
                [106.1065445, 21.8389702],
                [106.1065248, 21.83856],
                [106.1066243, 21.8382165],
                [106.1067353, 21.8380405],
                [106.1080178, 21.8365861],
                [106.1083331, 21.8360918],
                [106.1084258, 21.8358639],
                [106.1084913, 21.8352773],
                [106.1083733, 21.833834],
                [106.1081909, 21.8326254],
                [106.1079973, 21.8320932],
                [106.107724, 21.8316886],
                [106.1072922, 21.831239],
                [106.1068127, 21.8309876],
                [106.106459, 21.8308745],
                [106.105565, 21.8307484],
                [106.1050532, 21.830761],
                [106.1033392, 21.8309527],
                [106.1025766, 21.8309518],
                [106.1020921, 21.8308935],
                [106.1016822, 21.8307909],
                [106.1011614, 21.8305189],
                [106.1008752, 21.8301829],
                [106.1005887, 21.8296211],
                [106.1003726, 21.8289496],
                [106.1003245, 21.8285456],
                [106.1004493, 21.8279987],
                [106.1010082, 21.8269321],
                [106.101164, 21.8264977],
                [106.1011593, 21.8259344],
                [106.1010835, 21.8256195],
                [106.1009571, 21.8253456],
                [106.1006296, 21.8249704],
                [106.1000542, 21.8245253],
                [106.0972537, 21.8228656],
                [106.0967655, 21.8225225],
                [106.096278, 21.8220871],
                [106.0957376, 21.8214587],
                [106.095577, 21.8211581],
                [106.0954521, 21.8207273],
                [106.0953797, 21.8198489],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [103.5294336, 22.5952161],
                [103.5301475, 22.5846545],
                [103.5470308, 22.5885658],
                [103.5560695, 22.5860774],
                [103.5727152, 22.5780768],
                [103.5679267, 22.5686019],
                [103.5802081, 22.5468744],
                [103.5840024, 22.5324859],
                [103.5956315, 22.5269044],
                [103.5939409, 22.521625],
                [103.5867386, 22.514477],
                [103.5895571, 22.5093835],
                [103.5857279, 22.5045229],
                [103.5853163, 22.5041456],
                [103.5852384, 22.5039082],
                [103.5854441, 22.503449],
                [103.5853932, 22.5031746],
                [103.5852225, 22.5028492],
                [103.5849581, 22.5024856],
                [103.5844934, 22.502058],
                [103.5843218, 22.5018072],
                [103.5841931, 22.501283],
                [103.5841045, 22.500759],
                [103.5838825, 22.5001963],
                [103.5835648, 22.4998199],
                [103.5831035, 22.4990471],
                [103.5811043, 22.4964997],
                [103.5797512, 22.4948642],
                [103.5786295, 22.4929481],
                [103.5770141, 22.4909218],
                [103.5762995, 22.4901035],
                [103.5756571, 22.4896743],
                [103.5747089, 22.4893834],
                [103.572733, 22.4891542],
                [103.5710253, 22.4887154],
                [103.5687067, 22.486635],
                [103.5666302, 22.4850976],
                [103.5655016, 22.4838509],
                [103.5641938, 22.4815431],
                [103.563468, 22.4799998],
                [103.5627159, 22.4791452],
                [103.561127, 22.4782832],
                [103.5604467, 22.4778532],
                [103.5594299, 22.4768549],
                [103.5586771, 22.476071],
                [103.5565005, 22.4731901],
                [103.5560226, 22.4725416],
                [103.5555438, 22.471892],
                [103.5552103, 22.4710411],
                [103.5552137, 22.4707079],
                [103.5552187, 22.4702288],
                [103.5554163, 22.4695239],
                [103.5563453, 22.4679779],
                [103.5567352, 22.4670628],
                [103.5567114, 22.4656849],
                [103.5562193, 22.4636138],
                [103.5561495, 22.4630126],
                [103.5561901, 22.4627657],
                [103.556381, 22.4626967],
                [103.5569497, 22.4628785],
                [103.5580133, 22.4630292],
                [103.5596531, 22.4626199],
                [103.5619499, 22.4612274],
                [103.5650174, 22.4588525],
                [103.5664888, 22.4581767],
                [103.5679021, 22.4575886],
                [103.5685543, 22.4570645],
                [103.5688639, 22.4565373],
                [103.5688716, 22.4557955],
                [103.5687051, 22.4534978],
                [103.5690963, 22.4524414],
                [103.570829, 22.4503724],
                [103.5714285, 22.4494062],
                [103.5715915, 22.4483478],
                [103.5717124, 22.4476776],
                [103.5718682, 22.4473258],
                [103.5722112, 22.447258],
                [103.5727795, 22.4474752],
                [103.5745641, 22.4477736],
                [103.5758586, 22.4476436],
                [103.577008, 22.4468412],
                [103.5780482, 22.4455433],
                [103.5785569, 22.4441346],
                [103.5791095, 22.4421612],
                [103.5797508, 22.4408068],
                [103.5807557, 22.4392258],
                [103.5816003, 22.438456],
                [103.5819821, 22.438318],
                [103.5828204, 22.4381487],
                [103.5841917, 22.4379488],
                [103.5858004, 22.4368324],
                [103.5875938, 22.4362475],
                [103.5882811, 22.4359709],
                [103.5885129, 22.4356196],
                [103.5884113, 22.4343823],
                [103.5878873, 22.4316751],
                [103.587602, 22.4298003],
                [103.5877213, 22.4292714],
                [103.5879527, 22.4289555],
                [103.588639, 22.4287848],
                [103.5904653, 22.4286948],
                [103.5930533, 22.4285054],
                [103.5956037, 22.4282803],
                [103.5993007, 22.4274646],
                [103.6016935, 22.4258779],
                [103.6029977, 22.4247586],
                [103.6034259, 22.4237731],
                [103.6035897, 22.4226087],
                [103.6031791, 22.4199379],
                [103.6028755, 22.4160845],
                [103.6027622, 22.4121975],
                [103.6031377, 22.41077],
                [103.6043318, 22.4092258],
                [103.6061713, 22.4077932],
                [103.6098791, 22.4058467],
                [103.6133537, 22.4043929],
                [103.614674, 22.4035385],
                [103.6153654, 22.4028379],
                [103.6162528, 22.4015384],
                [103.6164454, 22.4012927],
                [103.6169413, 22.4011204],
                [103.6177401, 22.4010919],
                [103.6181204, 22.4010951],
                [103.619572, 22.4004009],
                [103.6226746, 22.3980957],
                [103.6248546, 22.3968071],
                [103.6261712, 22.3963061],
                [103.627692, 22.396319],
                [103.628564, 22.3965737],
                [103.6295434, 22.3975005],
                [103.6310918, 22.3985735],
                [103.6343173, 22.3992367],
                [103.6386419, 22.4002625],
                [103.6412395, 22.4009731],
                [103.642132, 22.4016192],
                [103.6431652, 22.4023671],
                [103.6447489, 22.4037229],
                [103.6460015, 22.4039453],
                [103.6475248, 22.4037108],
                [103.6501143, 22.4033085],
                [103.6523919, 22.4037162],
                [103.6549921, 22.4041794],
                [103.6568934, 22.4041599],
                [103.6588003, 22.4035751],
                [103.6606679, 22.403096],
                [103.6627579, 22.4032547],
                [103.6658696, 22.4039163],
                [103.6703304, 22.4046419],
                [103.6724216, 22.4046592],
                [103.6740986, 22.404249],
                [103.6755121, 22.4035539],
                [103.6759694, 22.4034517],
                [103.6766901, 22.4036343],
                [103.6792989, 22.40521],
                [103.6812948, 22.405244],
                [103.684904, 22.4055913],
                [103.6887352, 22.4065765],
                [103.6937445, 22.4076415],
                [103.695773, 22.4082762],
                [103.6965714, 22.4082826],
                [103.6977935, 22.4077272],
                [103.6996341, 22.4060815],
                [103.700286, 22.4054862],
                [103.7008181, 22.4055258],
                [103.7022589, 22.4059613],
                [103.7049803, 22.4077143],
                [103.706836, 22.4085418],
                [103.7084892, 22.4086432],
                [103.7102025, 22.4084097],
                [103.7113806, 22.4084897],
                [103.7124384, 22.4092401],
                [103.7132587, 22.411013],
                [103.7145382, 22.4124718],
                [103.7158508, 22.4135058],
                [103.7161238, 22.4137208],
                [103.7191599, 22.4143809],
                [103.7200924, 22.4142999],
                [103.7212761, 22.4137794],
                [103.7223894, 22.4126223],
                [103.7231908, 22.4123107],
                [103.7240651, 22.4123529],
                [103.7250453, 22.4132792],
                [103.7268906, 22.4152722],
                [103.7280251, 22.4159524],
                [103.7292767, 22.4163155],
                [103.7305149, 22.4168159],
                [103.7306506, 22.4167532],
                [103.730771, 22.4166678],
                [103.7308717, 22.4165629],
                [103.7309503, 22.4164397],
                [103.7310017, 22.4163047],
                [103.7310165, 22.4162344],
                [103.730873, 22.415917],
                [103.731093, 22.4157559],
                [103.7311465, 22.4157241],
                [103.7312366, 22.4156415],
                [103.731298, 22.4155394],
                [103.7313166, 22.4154837],
                [103.7313277, 22.4153678],
                [103.7313407, 22.4153143],
                [103.7313864, 22.4152132],
                [103.7314186, 22.4151671],
                [103.7315047, 22.4150827],
                [103.731617, 22.4150177],
                [103.7321361, 22.4148351],
                [103.7321826, 22.4148131],
                [103.7322633, 22.4147522],
                [103.7322959, 22.4147144],
                [103.7323377, 22.4146404],
                [103.7323509, 22.4146004],
                [103.7324703, 22.4145818],
                [103.7325338, 22.4145623],
                [103.7326497, 22.4145008],
                [103.7327439, 22.4144132],
                [103.732782, 22.4143591],
                [103.7328116, 22.4143006],
                [103.7328321, 22.4142389],
                [103.7328431, 22.4141751],
                [103.7334385, 22.4142619],
                [103.7338114, 22.4144826],
                [103.7339431, 22.4144681],
                [103.7340757, 22.4144631],
                [103.7342083, 22.4144677],
                [103.7344302, 22.4144973],
                [103.734539, 22.4145222],
                [103.7347501, 22.4145917],
                [103.734846, 22.414579],
                [103.7350341, 22.4145367],
                [103.7352413, 22.4144614],
                [103.7353524, 22.4144067],
                [103.7354582, 22.4143438],
                [103.7355751, 22.4142294],
                [103.7356646, 22.4141019],
                [103.7357265, 22.4139669],
                [103.7357601, 22.4138451],
                [103.7358031, 22.4137259],
                [103.7358552, 22.4136098],
                [103.7359831, 22.4133937],
                [103.7360591, 22.4132924],
                [103.7363384, 22.4134115],
                [103.7369066, 22.4136296],
                [103.7371568, 22.4137158],
                [103.737663, 22.4138726],
                [103.7377355, 22.4137469],
                [103.7377595, 22.413679],
                [103.737781, 22.4135379],
                [103.7377807, 22.4133872],
                [103.7377891, 22.4133122],
                [103.7378118, 22.4132239],
                [103.7378616, 22.4131456],
                [103.7378953, 22.4131124],
                [103.7379814, 22.4130594],
                [103.738086, 22.4130331],
                [103.7381405, 22.413032],
                [103.7388892, 22.4130623],
                [103.7391256, 22.4130499],
                [103.7393644, 22.413012],
                [103.7394843, 22.4129829],
                [103.7397176, 22.4129056],
                [103.7402297, 22.412217],
                [103.7404954, 22.411879],
                [103.7411043, 22.4111488],
                [103.7412314, 22.4108645],
                [103.741284, 22.4107186],
                [103.7413668, 22.4104193],
                [103.7413967, 22.4102673],
                [103.7415596, 22.4102658],
                [103.7417183, 22.4102317],
                [103.7418539, 22.4101728],
                [103.7419644, 22.4100981],
                [103.7420136, 22.410054],
                [103.7422389, 22.410059],
                [103.7425801, 22.409727],
                [103.7430033, 22.4093151],
                [103.7431414, 22.4093892],
                [103.743429, 22.4095173],
                [103.7435777, 22.4095711],
                [103.7438831, 22.4096573],
                [103.7441891, 22.4097133],
                [103.7444996, 22.409741],
                [103.7446555, 22.4097441],
                [103.7449157, 22.4097242],
                [103.7453878, 22.4099623],
                [103.7454161, 22.4098107],
                [103.7454683, 22.4096647],
                [103.7455543, 22.4095107],
                [103.7456667, 22.4093721],
                [103.7460744, 22.4093622],
                [103.7461871, 22.4092779],
                [103.7465358, 22.4092779],
                [103.7465841, 22.4092184],
                [103.7466226, 22.4091431],
                [103.7466738, 22.4089835],
                [103.7466888, 22.4088141],
                [103.7466817, 22.4087275],
                [103.7466646, 22.4086421],
                [103.7466377, 22.4085588],
                [103.7466374, 22.4083783],
                [103.7466646, 22.4082087],
                [103.7467208, 22.4079711],
                [103.7467879, 22.4075768],
                [103.7468216, 22.4075077],
                [103.7468469, 22.4074777],
                [103.7469047, 22.4074338],
                [103.7469381, 22.4074181],
                [103.7487298, 22.4073636],
                [103.7487674, 22.4069222],
                [103.7490624, 22.4066494],
                [103.7491643, 22.4064015],
                [103.7492316, 22.4062703],
                [103.7493896, 22.4060196],
                [103.7494223, 22.4059275],
                [103.7494303, 22.4058796],
                [103.7494272, 22.40577],
                [103.7494128, 22.4057101],
                [103.7493896, 22.4056526],
                [103.7492314, 22.4054592],
                [103.7492101, 22.4053841],
                [103.7492126, 22.4053451],
                [103.7492953, 22.4051581],
                [103.7493388, 22.4049229],
                [103.7493627, 22.4048337],
                [103.7494378, 22.4046396],
                [103.7494862, 22.4045466],
                [103.7497169, 22.404445],
                [103.749761, 22.4043859],
                [103.7497786, 22.404316],
                [103.7497696, 22.4042521],
                [103.7497383, 22.4041945],
                [103.749572, 22.4040011],
                [103.7492609, 22.4036043],
                [103.7491882, 22.4034473],
                [103.749159, 22.4033663],
                [103.749104, 22.4031369],
                [103.7494031, 22.4030662],
                [103.7499395, 22.403034],
                [103.7500536, 22.4029904],
                [103.75027, 22.402881],
                [103.7503713, 22.4028158],
                [103.7505504, 22.4026725],
                [103.7506315, 22.4025926],
                [103.7507109, 22.4024169],
                [103.7508227, 22.402257],
                [103.7509635, 22.4021179],
                [103.7511287, 22.402004],
                [103.7512189, 22.4019577],
                [103.7522381, 22.4018834],
                [103.7531769, 22.4013477],
                [103.753413, 22.4011593],
                [103.7536036, 22.4009746],
                [103.7536919, 22.4008766],
                [103.7548077, 22.4005591],
                [103.7548734, 22.4005146],
                [103.7549325, 22.4004628],
                [103.7549842, 22.4004046],
                [103.7550548, 22.4002891],
                [103.7550926, 22.4001795],
                [103.7551027, 22.4001227],
                [103.7549204, 22.3996962],
                [103.7563795, 22.3986645],
                [103.7567067, 22.3983273],
                [103.7573504, 22.3979702],
                [103.7573987, 22.3975486],
                [103.7574424, 22.3974589],
                [103.7574719, 22.3974178],
                [103.7575529, 22.3973391],
                [103.7576619, 22.3972759],
                [103.7578819, 22.3972138],
                [103.7579762, 22.3971796],
                [103.7581547, 22.3970913],
                [103.7589222, 22.3965616],
                [103.7592792, 22.3960967],
                [103.7594694, 22.3958722],
                [103.7598457, 22.3954669],
                [103.7600434, 22.3952721],
                [103.7603357, 22.3948755],
                [103.760899, 22.3940695],
                [103.7611699, 22.3936601],
                [103.761685, 22.3928373],
                [103.7620632, 22.3921984],
                [103.762216, 22.3919886],
                [103.762428, 22.3917528],
                [103.7626668, 22.39154],
                [103.7627953, 22.391443],
                [103.7630164, 22.3913077],
                [103.7631333, 22.3912496],
                [103.7637126, 22.3911851],
                [103.76385, 22.3911587],
                [103.7639755, 22.3911008],
                [103.7640315, 22.391061],
                [103.7641246, 22.390963],
                [103.7641874, 22.3908463],
                [103.7642062, 22.3907834],
                [103.764351, 22.3901931],
                [103.7643559, 22.3900617],
                [103.764351, 22.3899302],
                [103.7642959, 22.3895572],
                [103.7643071, 22.3894063],
                [103.764351, 22.3892606],
                [103.7644655, 22.3890729],
                [103.7645134, 22.3889745],
                [103.7645883, 22.3887754],
                [103.7646367, 22.3885742],
                [103.7646782, 22.3881744],
                [103.7651557, 22.3879512],
                [103.7653332, 22.3878253],
                [103.7654132, 22.3877528],
                [103.7655328, 22.3876175],
                [103.7655848, 22.3875445],
                [103.7657082, 22.3870931],
                [103.7653649, 22.3863987],
                [103.7653434, 22.3860415],
                [103.7653452, 22.3859486],
                [103.7653649, 22.3857638],
                [103.765415, 22.3855517],
                [103.7654936, 22.3853471],
                [103.7663412, 22.384236],
                [103.7655902, 22.3835069],
                [103.7655544, 22.383425],
                [103.7655488, 22.3833809],
                [103.7655634, 22.3832936],
                [103.7656109, 22.3832156],
                [103.7656453, 22.3831838],
                [103.7657297, 22.3831398],
                [103.7662822, 22.3830654],
                [103.7673336, 22.3816666],
                [103.767634, 22.3808134],
                [103.7679237, 22.3805803],
                [103.7679948, 22.3804882],
                [103.7680364, 22.3803819],
                [103.7680452, 22.380266],
                [103.7680365, 22.3802083],
                [103.7679797, 22.3800514],
                [103.7679734, 22.3800023],
                [103.7679832, 22.3799049],
                [103.7679988, 22.3798586],
                [103.7682188, 22.3795882],
                [103.7682426, 22.3791668],
                [103.7682407, 22.3789558],
                [103.7682113, 22.3785539],
                [103.7681523, 22.3781741],
                [103.7681115, 22.377986],
                [103.7680455, 22.377742],
                [103.7680024, 22.3776226],
                [103.7678969, 22.3773908],
                [103.7678259, 22.3772643],
                [103.7676608, 22.3770237],
                [103.7669849, 22.3764384],
                [103.766845, 22.3762853],
                [103.7667298, 22.3761155],
                [103.7666416, 22.3759324],
                [103.7666131, 22.3758527],
                [103.7665465, 22.3756085],
                [103.7665119, 22.3754437],
                [103.76647, 22.3751241],
                [103.7664604, 22.3748158],
                [103.7664669, 22.3746616],
                [103.7664808, 22.3745079],
                [103.7665527, 22.3740184],
                [103.7665722, 22.3737035],
                [103.7665665, 22.3734124],
                [103.7664749, 22.3720566],
                [103.7664807, 22.3717853],
                [103.7664971, 22.3716062],
                [103.7665236, 22.3714282],
                [103.7668991, 22.3705005],
                [103.7669422, 22.370093],
                [103.7669852, 22.3699332],
                [103.7670439, 22.369806],
                [103.7672424, 22.3694935],
                [103.7671079, 22.3686456],
                [103.7670278, 22.3682235],
                [103.7668455, 22.3673802],
                [103.7666295, 22.3664811],
                [103.7667542, 22.3662086],
                [103.7669199, 22.3659092],
                [103.7671347, 22.3655889],
                [103.7672531, 22.3654356],
                [103.7673046, 22.3641009],
                [103.7672939, 22.3636938],
                [103.7672446, 22.3632798],
                [103.7672046, 22.3630702],
                [103.7671546, 22.3628625],
                [103.7670947, 22.3626569],
                [103.7670252, 22.362454],
                [103.7673792, 22.3620332],
                [103.7674302, 22.3619009],
                [103.7674464, 22.3617905],
                [103.7674463, 22.3617347],
                [103.7671593, 22.3611518],
                [103.7671437, 22.3610545],
                [103.7671585, 22.3609665],
                [103.7671941, 22.3608938],
                [103.7676877, 22.3602166],
                [103.7677124, 22.3600461],
                [103.7677123, 22.35996],
                [103.767685, 22.359782],
                [103.7676565, 22.3596918],
                [103.7676186, 22.3596046],
                [103.7675716, 22.3595213],
                [103.767516, 22.3594427],
                [103.7671405, 22.3590545],
                [103.7671567, 22.3588566],
                [103.767202, 22.3586809],
                [103.7674677, 22.3580833],
                [103.7671244, 22.3574086],
                [103.7670115, 22.3571592],
                [103.7669189, 22.3568949],
                [103.7668807, 22.3567565],
                [103.766824, 22.3564759],
                [103.7664887, 22.3560096],
                [103.7670332, 22.3553646],
                [103.767811, 22.3546849],
                [103.7680233, 22.3545363],
                [103.7682563, 22.354417],
                [103.7684774, 22.3543375],
                [103.7685916, 22.3543076],
                [103.7688249, 22.3542682],
                [103.7694881, 22.3542247],
                [103.770583, 22.3539338],
                [103.7706909, 22.3538839],
                [103.7707845, 22.3538149],
                [103.7708246, 22.3537743],
                [103.7708902, 22.3536827],
                [103.7710941, 22.3534347],
                [103.7711942, 22.3533754],
                [103.7712496, 22.3533553],
                [103.771342, 22.3533376],
                [103.7713891, 22.3533354],
                [103.7722313, 22.3533553],
                [103.7734903, 22.3531299],
                [103.7742269, 22.3529981],
                [103.7747949, 22.3528046],
                [103.7749346, 22.3527827],
                [103.775217, 22.3527603],
                [103.7753588, 22.3527599],
                [103.7755054, 22.3527671],
                [103.775796, 22.3528046],
                [103.775965, 22.352807],
                [103.7759542, 22.3519438],
                [103.7759868, 22.3517988],
                [103.776013, 22.3517287],
                [103.7760859, 22.3515931],
                [103.7761327, 22.3515288],
                [103.7762439, 22.3514129],
                [103.7769842, 22.3509292],
                [103.7770057, 22.3505223],
                [103.777518, 22.3500932],
                [103.7776283, 22.3500207],
                [103.777754, 22.3499741],
                [103.777875, 22.3499565],
                [103.7780242, 22.3499497],
                [103.7781107, 22.3499344],
                [103.7782475, 22.3498922],
                [103.7805945, 22.3488206],
                [103.7807978, 22.3482836],
                [103.7808449, 22.3480971],
                [103.7808621, 22.3479174],
                [103.780854, 22.3477483],
                [103.7808412, 22.3476645],
                [103.7806857, 22.3471237],
                [103.7784862, 22.3445834],
                [103.7790763, 22.3429659],
                [103.7798595, 22.3419041],
                [103.7799195, 22.3418127],
                [103.7800163, 22.3416186],
                [103.7801132, 22.3413229],
                [103.7801542, 22.3412292],
                [103.7802565, 22.3410507],
                [103.7803275, 22.340954],
                [103.7804065, 22.3408628],
                [103.7804932, 22.3407778],
                [103.7805868, 22.3406994],
                [103.780687, 22.3406281],
                [103.7808539, 22.3405264],
                [103.7809111, 22.3404837],
                [103.7810129, 22.3403858],
                [103.7811014, 22.3402635],
                [103.7811363, 22.3401973],
                [103.7811654, 22.3400304],
                [103.7812236, 22.3398701],
                [103.7813092, 22.3397208],
                [103.7813616, 22.3396515],
                [103.7815543, 22.3394573],
                [103.7816591, 22.339368],
                [103.7818863, 22.3392051],
                [103.782413, 22.3389469],
                [103.7824611, 22.3389038],
                [103.7825397, 22.3388038],
                [103.7825691, 22.3387481],
                [103.7826061, 22.3386293],
                [103.7826135, 22.3385624],
                [103.7826008, 22.3384285],
                [103.7825525, 22.3383018],
                [103.7820911, 22.3375724],
                [103.7813669, 22.3376072],
                [103.7810985, 22.3376072],
                [103.7808408, 22.3375821],
                [103.7805964, 22.3375349],
                [103.7804764, 22.337503],
                [103.7780893, 22.3367438],
                [103.7775743, 22.3361782],
                [103.7774942, 22.3361028],
                [103.7774082, 22.3360332],
                [103.777233, 22.3359196],
                [103.7770555, 22.3358351],
                [103.7769627, 22.335801],
                [103.7763512, 22.3356026],
                [103.775794, 22.3355187],
                [103.7752354, 22.3354438],
                [103.7742563, 22.3353344],
                [103.7737656, 22.33529],
                [103.7736652, 22.3352717],
                [103.7735671, 22.3352453],
                [103.7733847, 22.3351709],
                [103.772521, 22.3347045],
                [103.7723589, 22.3346448],
                [103.7721884, 22.3346102],
                [103.7721315, 22.3345886],
                [103.7720311, 22.3345234],
                [103.7719549, 22.3344319],
                [103.7719286, 22.3343782],
                [103.7719115, 22.3343214],
                [103.7719041, 22.3342628],
                [103.7717625, 22.3340368],
                [103.771652, 22.3337964],
                [103.7715809, 22.333573],
                [103.7715554, 22.333459],
                [103.770842, 22.3330521],
                [103.7707438, 22.3329856],
                [103.770667, 22.3328983],
                [103.7706381, 22.3328487],
                [103.7706021, 22.3327427],
                [103.7705952, 22.3326874],
                [103.769571, 22.3322075],
                [103.768959, 22.3319208],
                [103.7690021, 22.3318344],
                [103.7691097, 22.3316727],
                [103.7691736, 22.3315982],
                [103.769326, 22.3314596],
                [103.769411, 22.3313994],
                [103.7695956, 22.3312997],
                [103.770268, 22.3310375],
                [103.7704343, 22.3309004],
                [103.7705737, 22.3307398],
                [103.7706785, 22.3305678],
                [103.7707195, 22.3304766],
                [103.7707773, 22.3302866],
                [103.7707937, 22.330189],
                [103.7707937, 22.3298962],
                [103.770756, 22.3296334],
                [103.7707239, 22.3295042],
                [103.7712872, 22.3290973],
                [103.7717432, 22.3288641],
                [103.7718119, 22.3287224],
                [103.7718573, 22.328657],
                [103.7719685, 22.3285399],
                [103.7721033, 22.3284451],
                [103.7721777, 22.3284076],
                [103.7729609, 22.3278617],
                [103.772698, 22.3274796],
                [103.7724727, 22.3271869],
                [103.7724036, 22.3270563],
                [103.7723606, 22.3269165],
                [103.772345, 22.3267585],
                [103.7723656, 22.3265877],
                [103.7723904, 22.3265047],
                [103.7724244, 22.3264246],
                [103.7725085, 22.3262553],
                [103.7725403, 22.3261593],
                [103.7725624, 22.326061],
                [103.7725747, 22.3259612],
                [103.772572, 22.3257839],
                [103.772559, 22.325696],
                [103.7725103, 22.3255245],
                [103.772168, 22.3251193],
                [103.7720041, 22.3249117],
                [103.7717326, 22.3245454],
                [103.7716271, 22.3243859],
                [103.7715286, 22.3242225],
                [103.7713584, 22.3238958],
                [103.7712206, 22.3235682],
                [103.7711102, 22.3232319],
                [103.7711012, 22.3208415],
                [103.7708339, 22.3207929],
                [103.770694, 22.3206742],
                [103.770572, 22.3205427],
                [103.7704691, 22.3204008],
                [103.7704277, 22.3201993],
                [103.7703831, 22.3201054],
                [103.7703136, 22.3200257],
                [103.7702243, 22.3199657],
                [103.7701741, 22.3199443],
                [103.7696591, 22.3199542],
                [103.7694645, 22.3199028],
                [103.7690807, 22.3197836],
                [103.7686832, 22.3196339],
                [103.7682752, 22.3194502],
                [103.7680766, 22.3193488],
                [103.7677225, 22.318793],
                [103.7673953, 22.3183166],
                [103.7672746, 22.3174349],
                [103.767226, 22.3173151],
                [103.7671477, 22.3172046],
                [103.7670413, 22.3171106],
                [103.7668267, 22.3169667],
                [103.7666895, 22.3169087],
                [103.7666168, 22.3168896],
                [103.766469, 22.3168723],
                [103.7663224, 22.3168824],
                [103.7660964, 22.3168735],
                [103.7658768, 22.3168348],
                [103.765668, 22.3167682],
                [103.7652227, 22.3165697],
                [103.7651061, 22.316487],
                [103.7650551, 22.3164378],
                [103.7649706, 22.3163266],
                [103.764908, 22.3161898],
                [103.7648794, 22.3160437],
                [103.7644449, 22.3157509],
                [103.7643661, 22.3156587],
                [103.764309, 22.3155537],
                [103.7642893, 22.3154978],
                [103.7642687, 22.3153826],
                [103.7642679, 22.3153241],
                [103.7643108, 22.3151206],
                [103.7643241, 22.3150135],
                [103.7643404, 22.3147027],
                [103.7643778, 22.3143787],
                [103.7643701, 22.3142959],
                [103.7643521, 22.3142145],
                [103.7643242, 22.3141355],
                [103.7642877, 22.3140619],
                [103.7642426, 22.3139925],
                [103.7641893, 22.3139281],
                [103.7641287, 22.3138697],
                [103.7640613, 22.3138179],
                [103.7636107, 22.3135599],
                [103.7635184, 22.3135004],
                [103.7633515, 22.3133609],
                [103.7632781, 22.3132819],
                [103.7631657, 22.3131276],
                [103.7630796, 22.3129594],
                [103.7630796, 22.3127261],
                [103.762216, 22.3123142],
                [103.762055, 22.3121058],
                [103.7612933, 22.3117137],
                [103.7614377, 22.3116638],
                [103.7615908, 22.3116418],
                [103.7616688, 22.3116418],
                [103.7620336, 22.3116988],
                [103.7621819, 22.3116192],
                [103.7623483, 22.3115664],
                [103.7624371, 22.3115515],
                [103.7627095, 22.3115351],
                [103.7633425, 22.3112373],
                [103.7634423, 22.3112784],
                [103.7636508, 22.3113381],
                [103.7638681, 22.3113668],
                [103.7639785, 22.311369],
                [103.7642998, 22.3113405],
                [103.764402, 22.3113415],
                [103.7644845, 22.3113494],
                [103.7647467, 22.3114021],
                [103.7648485, 22.3114136],
                [103.7650536, 22.3114156],
                [103.7651557, 22.311406],
                [103.765351, 22.3113675],
                [103.7654459, 22.3113386],
                [103.7655383, 22.3113034],
                [103.7660462, 22.3110685],
                [103.766191, 22.3110189],
                [103.7662576, 22.3110081],
                [103.7668803, 22.3109767],
                [103.7670335, 22.3109528],
                [103.767108, 22.3109327],
                [103.7672557, 22.3108745],
                [103.7673957, 22.3107923],
                [103.7677252, 22.3105326],
                [103.767871, 22.3104975],
                [103.7680139, 22.3104534],
                [103.7681533, 22.3104005],
                [103.7682885, 22.310339],
                [103.7684143, 22.3102718],
                [103.7686504, 22.3101155],
                [103.7689219, 22.3098825],
                [103.7690538, 22.309799],
                [103.7691253, 22.3097658],
                [103.769301, 22.3097125],
                [103.7694848, 22.3096938],
                [103.7699997, 22.3097385],
                [103.7702324, 22.3097345],
                [103.7704618, 22.3096987],
                [103.770683, 22.3096317],
                [103.7708911, 22.3095351],
                [103.7710814, 22.309411],
                [103.7711685, 22.3093394],
                [103.771593, 22.3089494],
                [103.7717521, 22.3088237],
                [103.7718383, 22.3087689],
                [103.7720221, 22.3086764],
                [103.7721371, 22.3086331],
                [103.7722555, 22.3085986],
                [103.7723766, 22.3085733],
                [103.7725484, 22.3085494],
                [103.7726405, 22.3085156],
                [103.7727196, 22.3084599],
                [103.7729002, 22.3082588],
                [103.773158, 22.3080133],
                [103.7734372, 22.3077824],
                [103.7735832, 22.3076739],
                [103.774007, 22.3072025],
                [103.7742061, 22.3070421],
                [103.7743878, 22.306865],
                [103.7744945, 22.3067432],
                [103.774593, 22.3066157],
                [103.7746829, 22.3064828],
                [103.7747877, 22.3062916],
                [103.7748838, 22.3060965],
                [103.7749709, 22.3058978],
                [103.7751172, 22.3054926],
                [103.7751764, 22.3052861],
                [103.7752596, 22.3049356],
                [103.7754205, 22.3044167],
                [103.7754434, 22.3043052],
                [103.7754567, 22.3041924],
                [103.7754554, 22.303974],
                [103.7753412, 22.3033314],
                [103.7762573, 22.3036241],
                [103.7767965, 22.3035993],
                [103.7768724, 22.3035291],
                [103.7769549, 22.3034657],
                [103.7770434, 22.3034095],
                [103.7772332, 22.3033215],
                [103.777435, 22.3032671],
                [103.777644, 22.3032469],
                [103.7784272, 22.3030732],
                [103.7785023, 22.3024429],
                [103.778585, 22.3022845],
                [103.7786983, 22.3021432],
                [103.7788322, 22.3020283],
                [103.7789798, 22.3019408],
                [103.7790591, 22.3019062],
                [103.7792261, 22.3018568],
                [103.7794494, 22.3018251],
                [103.7797254, 22.3018113],
                [103.7800015, 22.3018251],
                [103.7801385, 22.3018424],
                [103.7807286, 22.301892],
                [103.781662, 22.3019615],
                [103.7817624, 22.3019802],
                [103.7819563, 22.3020412],
                [103.78213, 22.3021285],
                [103.7822805, 22.3022368],
                [103.7824103, 22.302366],
                [103.7835824, 22.3022493],
                [103.7837686, 22.3022472],
                [103.7839831, 22.302272],
                [103.7841028, 22.302299],
                [103.7845802, 22.3024876],
                [103.7848182, 22.3024923],
                [103.7849361, 22.3025079],
                [103.7851509, 22.3025602],
                [103.7853405, 22.3026348],
                [103.7854306, 22.302681],
                [103.7855995, 22.3027903],
                [103.7859696, 22.3028697],
                [103.7864631, 22.3031229],
                [103.7868279, 22.3032817],
                [103.7868291, 22.3031023],
                [103.7868671, 22.3029265],
                [103.7869406, 22.3027605],
                [103.787046, 22.3026115],
                [103.7871096, 22.3025446],
                [103.7872561, 22.3024291],
                [103.7873375, 22.3023814],
                [103.7875131, 22.3023086],
                [103.788684, 22.3020012],
                [103.7891936, 22.3010483],
                [103.7893217, 22.3009177],
                [103.7893953, 22.3008616],
                [103.7895584, 22.3007703],
                [103.7897121, 22.3007162],
                [103.789792, 22.3006982],
                [103.7898733, 22.3006864],
                [103.7903952, 22.3006512],
                [103.790557, 22.3004454],
                [103.7906454, 22.3003478],
                [103.790843, 22.3001587],
                [103.7910663, 22.2999825],
                [103.7913072, 22.2998273],
                [103.791908, 22.299604],
                [103.7925786, 22.2993806],
                [103.7925947, 22.2989439],
                [103.7926323, 22.2987804],
                [103.7927019, 22.2986262],
                [103.7927969, 22.2984914],
                [103.7928537, 22.2984304],
                [103.7929162, 22.2983744],
                [103.7931158, 22.2982323],
                [103.7931702, 22.2981806],
                [103.7932609, 22.2980632],
                [103.7933242, 22.2979314],
                [103.7933891, 22.2978412],
                [103.7934306, 22.2978028],
                [103.7935281, 22.2977428],
                [103.7936063, 22.2976838],
                [103.7937463, 22.2975493],
                [103.7938655, 22.2973888],
                [103.7939156, 22.2972985],
                [103.7939894, 22.2971074],
                [103.7941396, 22.29676],
                [103.7942574, 22.2965881],
                [103.7944055, 22.2964377],
                [103.7944896, 22.296372],
                [103.7946724, 22.2962631],
                [103.7948702, 22.2961855],
                [103.7949738, 22.2961587],
                [103.7951864, 22.2961297],
                [103.7952939, 22.2961279],
                [103.7955981, 22.2961587],
                [103.79578, 22.2961543],
                [103.7959582, 22.2961197],
                [103.7961367, 22.2960512],
                [103.7962978, 22.2959524],
                [103.79637, 22.2958927],
                [103.7966502, 22.2956284],
                [103.796972, 22.2953603],
                [103.7970485, 22.2952798],
                [103.7970954, 22.2951817],
                [103.7971068, 22.2951244],
                [103.7971079, 22.2950663],
                [103.7970986, 22.2950087],
                [103.7966126, 22.293782],
                [103.79812, 22.2939507],
                [103.7989727, 22.2933124],
                [103.7998474, 22.2927],
                [103.8006177, 22.2921936],
                [103.801403, 22.2917073],
                [103.8018107, 22.2909032],
                [103.8019277, 22.2907425],
                [103.8019967, 22.2906695],
                [103.8021534, 22.2905409],
                [103.8023395, 22.2904345],
                [103.8024439, 22.2903914],
                [103.8025521, 22.2903574],
                [103.8026632, 22.2903327],
                [103.8029101, 22.2903073],
                [103.8029751, 22.2902914],
                [103.8030968, 22.290239],
                [103.8032018, 22.290162],
                [103.8032844, 22.2900647],
                [103.8033403, 22.2899524],
                [103.8033664, 22.289831],
                [103.8035381, 22.2893347],
                [103.8035931, 22.2892242],
                [103.8036571, 22.2891179],
                [103.8037296, 22.2890164],
                [103.8038103, 22.2889202],
                [103.8038985, 22.2888301],
                [103.803994, 22.2887463],
                [103.8041999, 22.2886026],
                [103.8044222, 22.2884906],
                [103.8045393, 22.2884462],
                [103.8047826, 22.2883817],
                [103.8049543, 22.287925],
                [103.8056838, 22.2871408],
                [103.8059681, 22.2869338],
                [103.8061023, 22.2868216],
                [103.8063529, 22.286582],
                [103.8064688, 22.2864552],
                [103.8067882, 22.2860616],
                [103.8068592, 22.2859911],
                [103.8069372, 22.285927],
                [103.8070213, 22.2858699],
                [103.8071108, 22.2858204],
                [103.8072044, 22.2857791],
                [103.8074021, 22.2857216],
                [103.8075044, 22.2857059],
                [103.8093531, 22.2856417],
                [103.8095343, 22.285641],
                [103.8098958, 22.2856198],
                [103.8102543, 22.2855722],
                [103.8104591, 22.2855329],
                [103.8108619, 22.2854285],
                [103.811059, 22.2853637],
                [103.8114238, 22.2843511],
                [103.8124649, 22.2836898],
                [103.8126247, 22.2835631],
                [103.8127649, 22.283418],
                [103.8128879, 22.2832494],
                [103.8129396, 22.2831596],
                [103.8130224, 22.2829712],
                [103.8135373, 22.2819487],
                [103.8139772, 22.2816608],
                [103.8143313, 22.2810353],
                [103.8144701, 22.2809093],
                [103.8146306, 22.2808078],
                [103.8148098, 22.2807334],
                [103.8150024, 22.2806893],
                [103.8152003, 22.2806779],
                [103.8164663, 22.2801815],
                [103.8170993, 22.2801319],
                [103.8175177, 22.2798738],
                [103.8179684, 22.2796355],
                [103.8185906, 22.2787916],
                [103.8191968, 22.277774],
                [103.8192451, 22.2773421],
                [103.819289, 22.2772431],
                [103.8194, 22.2770555],
                [103.8194666, 22.2769681],
                [103.8196226, 22.2768059],
                [103.8197114, 22.2767325],
                [103.8199061, 22.2766057],
                [103.8200642, 22.2765288],
                [103.8202052, 22.2764844],
                [103.8202931, 22.2764694],
                [103.8203824, 22.2764635],
                [103.8209724, 22.2764436],
                [103.8213962, 22.276121],
                [103.8214785, 22.2758446],
                [103.8215089, 22.275704],
                [103.8215345, 22.2755427],
                [103.8215673, 22.2751265],
                [103.821587, 22.2750369],
                [103.8216162, 22.2749495],
                [103.8216828, 22.2748146],
                [103.8217246, 22.2747513],
                [103.8217717, 22.2746913],
                [103.8220292, 22.2744233],
                [103.8221175, 22.2743007],
                [103.8221816, 22.2741652],
                [103.8222039, 22.2740937],
                [103.8222462, 22.2738],
                [103.8222926, 22.2736536],
                [103.822368, 22.2735124],
                [103.8224691, 22.2733858],
                [103.8226826, 22.2733188],
                [103.8228991, 22.2732609],
                [103.8231182, 22.273212],
                [103.8233373, 22.2731727],
                [103.8235581, 22.2731425],
                [103.824588, 22.2727255],
                [103.8247165, 22.2725439],
                [103.8247704, 22.2724475],
                [103.8248542, 22.2722532],
                [103.8249099, 22.2720504],
                [103.826015, 22.27071],
                [103.8263962, 22.270184],
                [103.8268214, 22.2696412],
                [103.8272935, 22.2690851],
                [103.8275385, 22.2688137],
                [103.8275321, 22.2686548],
                [103.8275385, 22.2685754],
                [103.827573, 22.2684105],
                [103.8276006, 22.2683305],
                [103.8279462, 22.267513],
                [103.8280097, 22.2672113],
                [103.8281071, 22.2669173],
                [103.8282223, 22.2666631],
                [103.8282895, 22.26654],
                [103.8280637, 22.266127],
                [103.8280448, 22.2660356],
                [103.8280454, 22.265989],
                [103.8280678, 22.2658959],
                [103.8281167, 22.265812],
                [103.82815, 22.2657755],
                [103.8289601, 22.2651301],
                [103.8287967, 22.2649861],
                [103.8287254, 22.2649051],
                [103.8286617, 22.2648189],
                [103.8285649, 22.2646468],
                [103.8285022, 22.2644772],
                [103.8284458, 22.2642106],
                [103.8283867, 22.264034],
                [103.8278925, 22.2630053],
                [103.8278443, 22.2628701],
                [103.827823, 22.2627291],
                [103.8278305, 22.2625799],
                [103.8278466, 22.2625032],
                [103.8279028, 22.2623559],
                [103.8279424, 22.2622869],
                [103.8280427, 22.2621613],
                [103.8283271, 22.2618982],
                [103.8283957, 22.2618206],
                [103.8285094, 22.26165],
                [103.8285548, 22.2615555],
                [103.8285907, 22.2614576],
                [103.8286167, 22.2613571],
                [103.8286543, 22.2609599],
                [103.8286977, 22.2602898],
                [103.8288608, 22.2600858],
                [103.8290319, 22.2598875],
                [103.8292108, 22.2596952],
                [103.8294868, 22.2594244],
                [103.8296306, 22.2592943],
                [103.8303763, 22.2587854],
                [103.8304326, 22.2584329],
                [103.8307786, 22.2577627],
                [103.8308693, 22.2576558],
                [103.8309223, 22.2576087],
                [103.8310414, 22.2575293],
                [103.8311105, 22.257496],
                [103.8312575, 22.2574492],
                [103.8314116, 22.25743],
                [103.8319266, 22.2574251],
                [103.8320132, 22.2571419],
                [103.832128, 22.2568602],
                [103.8322719, 22.2565827],
                [103.8324416, 22.2563179],
                [103.8320473, 22.2560573],
                [103.831991, 22.2558984],
                [103.8319704, 22.2557525],
                [103.8319775, 22.2556055],
                [103.8319927, 22.2555234],
                [103.8320451, 22.2553643],
                [103.8321258, 22.2552156],
                [103.832176, 22.2551466],
                [103.8323543, 22.2549526],
                [103.8324837, 22.2548298],
                [103.8326202, 22.2547139],
                [103.8327634, 22.2546051],
                [103.8329834, 22.2540292],
                [103.8339275, 22.2536717],
                [103.8339841, 22.2536394],
                [103.8340346, 22.2535994],
                [103.8341103, 22.2535038],
                [103.834151, 22.2533959],
                [103.8341582, 22.253339],
                [103.8341689, 22.2525447],
                [103.834413, 22.2522443],
                [103.8347349, 22.2520358],
                [103.8347241, 22.2512923],
                [103.8347598, 22.2511778],
                [103.8348498, 22.2509547],
                [103.8349795, 22.2507156],
                [103.8350639, 22.2505893],
                [103.8351568, 22.2504681],
                [103.8353612, 22.2502313],
                [103.8355839, 22.2500012],
                [103.8358265, 22.2497891],
                [103.8361166, 22.249577],
                [103.8362693, 22.2494804],
                [103.8365883, 22.2493075],
                [103.8376182, 22.2488905],
                [103.8383692, 22.2484703],
                [103.8386078, 22.2483691],
                [103.8387313, 22.2483282],
                [103.8389806, 22.2482672],
                [103.8392356, 22.2482326],
                [103.8396835, 22.2482152],
                [103.8397408, 22.2481919],
                [103.8397931, 22.2481601],
                [103.8398391, 22.2481209],
                [103.8399028, 22.2480333],
                [103.8399234, 22.2479839],
                [103.8399357, 22.2479322],
                [103.8399264, 22.2478326],
                [103.839927, 22.2477327],
                [103.8399372, 22.2476332],
                [103.8399571, 22.247535],
                [103.8400135, 22.2473711],
                [103.8400966, 22.2472172],
                [103.84012, 22.2471293],
                [103.8401344, 22.2470396],
                [103.8401358, 22.246863],
                [103.8401238, 22.2467776],
                [103.8400455, 22.2465005],
                [103.8400253, 22.2463877],
                [103.8400132, 22.2461595],
                [103.8400215, 22.2460455],
                [103.840071, 22.2456768],
                [103.8401548, 22.2453134],
                [103.8402722, 22.2449581],
                [103.8403433, 22.2447843],
                [103.8409871, 22.2434834],
                [103.8411285, 22.2433035],
                [103.8412101, 22.2432216],
                [103.8413859, 22.243081],
                [103.8415752, 22.2429697],
                [103.8416759, 22.2429239],
                [103.8418866, 22.2428528],
                [103.8419956, 22.242828],
                [103.8420306, 22.2426251],
                [103.8420622, 22.2425265],
                [103.8421517, 22.2423398],
                [103.8422736, 22.2421709],
                [103.8424247, 22.2420237],
                [103.842515, 22.2419235],
                [103.8426762, 22.2417097],
                [103.8428171, 22.2414655],
                [103.8428784, 22.2413303],
                [103.84293, 22.2411918],
                [103.8430855, 22.2406164],
                [103.8431895, 22.2401803],
                [103.843284, 22.2397423],
                [103.8433689, 22.2393027],
                [103.8434899, 22.2385596],
                [103.8435137, 22.2365866],
                [103.8435188, 22.2364104],
                [103.8435351, 22.2363234],
                [103.8435857, 22.2361754],
                [103.843621, 22.2361049],
                [103.8439697, 22.2356382],
                [103.8440411, 22.2354973],
                [103.8440636, 22.2354222],
                [103.8440809, 22.2352776],
                [103.8440674, 22.2351429],
                [103.8440501, 22.2350771],
                [103.8435701, 22.2341965],
                [103.8434434, 22.2339845],
                [103.8433895, 22.2338741],
                [103.8432976, 22.233635],
                [103.8432612, 22.2335065],
                [103.8432133, 22.2332448],
                [103.8420009, 22.2317849],
                [103.8418637, 22.2315914],
                [103.8418053, 22.231489],
                [103.8417101, 22.231275],
                [103.8416737, 22.2311642],
                [103.8416039, 22.2308384],
                [103.841581, 22.2306737],
                [103.8415596, 22.2303422],
                [103.841561, 22.2301761],
                [103.8415918, 22.2298196],
                [103.84166, 22.2294675],
                [103.8418219, 22.2289233],
                [103.8418713, 22.2287214],
                [103.8419465, 22.2283136],
                [103.8419902, 22.2279018],
                [103.84206, 22.2267844],
                [103.8420554, 22.2266444],
                [103.8420589, 22.2265044],
                [103.8420707, 22.2263648],
                [103.8420915, 22.2262208],
                [103.842121, 22.2260781],
                [103.8421591, 22.2259371],
                [103.8422512, 22.2257438],
                [103.8423524, 22.2255544],
                [103.8424623, 22.2253692],
                [103.8426109, 22.2251721],
                [103.8427833, 22.2249926],
                [103.8428778, 22.2249101],
                [103.8430958, 22.2247519],
                [103.8432199, 22.2246785],
                [103.8434825, 22.2245553],
                [103.8436199, 22.2245061],
                [103.8438446, 22.2244396],
                [103.8439259, 22.2244069],
                [103.844077, 22.2243214],
                [103.8441476, 22.2242676],
                [103.844271, 22.2241427],
                [103.8445021, 22.2238105],
                [103.8446717, 22.2236139],
                [103.8448801, 22.2234147],
                [103.8449921, 22.2233225],
                [103.8452303, 22.2231544],
                [103.8453641, 22.2229969],
                [103.8454395, 22.222925],
                [103.8456058, 22.2227968],
                [103.8457124, 22.2227317],
                [103.8458244, 22.222675],
                [103.845941, 22.222627],
                [103.8460616, 22.2225881],
                [103.8461852, 22.2225585],
                [103.8466787, 22.2225187],
                [103.8473493, 22.2225187],
                [103.8476148, 22.2218061],
                [103.8476372, 22.2217381],
                [103.8476687, 22.2216732],
                [103.8477087, 22.2216124],
                [103.8477571, 22.2215564],
                [103.8478129, 22.2215064],
                [103.847875, 22.2214635],
                [103.8479504, 22.221429],
                [103.8480294, 22.221402],
                [103.8481109, 22.2213828],
                [103.8481942, 22.2213716],
                [103.8482651, 22.2213685],
                [103.8483361, 22.2213712],
                [103.8484065, 22.2213797],
                [103.8484758, 22.2213939],
                [103.8491276, 22.2215528],
                [103.8495836, 22.2212747],
                [103.8498317, 22.2211368],
                [103.8500848, 22.2210068],
                [103.8503426, 22.2208849],
                [103.850534, 22.220801],
                [103.8507276, 22.2207215],
                [103.8509233, 22.2206465],
                [103.8510856, 22.2204926],
                [103.8511444, 22.220437],
                [103.8511982, 22.2203773],
                [103.8512467, 22.2203137],
                [103.8512894, 22.2202468],
                [103.8513305, 22.2201677],
                [103.8513637, 22.2200856],
                [103.8513887, 22.220001],
                [103.8514028, 22.2198716],
                [103.8514272, 22.2197436],
                [103.8514619, 22.2196176],
                [103.8515067, 22.2194944],
                [103.8515509, 22.2193957],
                [103.8516015, 22.2192996],
                [103.8516583, 22.2192066],
                [103.8517213, 22.219117],
                [103.8517975, 22.2190519],
                [103.8518663, 22.2189801],
                [103.851927, 22.2189022],
                [103.8519788, 22.218819],
                [103.8520194, 22.218736],
                [103.8520512, 22.2186497],
                [103.8520739, 22.218561],
                [103.8520874, 22.2184706],
                [103.8520914, 22.2183795],
                [103.8521021, 22.2182531],
                [103.8521047, 22.2181263],
                [103.8520995, 22.2179996],
                [103.852087, 22.2178788],
                [103.8520673, 22.2177588],
                [103.8520113, 22.2176403],
                [103.8519647, 22.2175185],
                [103.8519278, 22.2173938],
                [103.8519046, 22.2172879],
                [103.8518884, 22.217181],
                [103.8518792, 22.2170733],
                [103.8518772, 22.2169653],
                [103.8518822, 22.2168574],
                [103.8522577, 22.2158245],
                [103.8539153, 22.2136989],
                [103.854311, 22.2128857],
                [103.8543667, 22.2126811],
                [103.8543855, 22.2125771],
                [103.8544051, 22.2123482],
                [103.8544045, 22.2121358],
                [103.8544249, 22.2119607],
                [103.8544789, 22.2117622],
                [103.8545178, 22.2116663],
                [103.8546181, 22.2114839],
                [103.8557097, 22.2100387],
                [103.8562274, 22.2095023],
                [103.8562596, 22.2075058],
                [103.8563038, 22.207319],
                [103.8563347, 22.2072277],
                [103.8564085, 22.2070601],
                [103.8564527, 22.2069794],
                [103.8564826, 22.2067818],
                [103.8565502, 22.2065923],
                [103.8565975, 22.2065026],
                [103.8566545, 22.2064156],
                [103.8567197, 22.2063337],
                [103.8567928, 22.2062577],
                [103.8568731, 22.2061882],
                [103.8569599, 22.2061258],
                [103.8570525, 22.2060711],
                [103.8571502, 22.2060243],
                [103.857252, 22.2059861],
                [103.8584804, 22.2056583],
                [103.8596499, 22.2054596],
                [103.8600401, 22.2051925],
                [103.8604116, 22.2049034],
                [103.8608005, 22.2045578],
                [103.8611627, 22.2041882],
                [103.8612426, 22.2041216],
                [103.8614173, 22.2040057],
                [103.861511, 22.2039569],
                [103.8617088, 22.2038791],
                [103.8618118, 22.2038504],
                [103.8620313, 22.2038136],
                [103.8622543, 22.203808],
                [103.8624757, 22.2038338],
                [103.8625841, 22.2038584],
                [103.8627934, 22.2039299],
                [103.8631535, 22.2038354],
                [103.8633361, 22.2037976],
                [103.8637054, 22.2037412],
                [103.8639299, 22.2037196],
                [103.8641553, 22.2037074],
                [103.864381, 22.2037047],
                [103.8646066, 22.2037114],
                [103.8648938, 22.2036492],
                [103.8650394, 22.2036275],
                [103.8653362, 22.2036028],
                [103.8656374, 22.2036041],
                [103.865937, 22.2036319],
                [103.8663018, 22.2036319],
                [103.8672459, 22.203463],
                [103.8676268, 22.2026882],
                [103.8680396, 22.2024979],
                [103.8682403, 22.2023927],
                [103.8686196, 22.2021688],
                [103.8689724, 22.2019277],
                [103.8693069, 22.201665],
                [103.8708156, 22.2003205],
                [103.8709105, 22.2002442],
                [103.8710117, 22.2001751],
                [103.871177, 22.2000843],
                [103.8712639, 22.200046],
                [103.8728088, 22.1996436],
                [103.8725996, 22.1990377],
                [103.8724655, 22.1983324],
                [103.8722777, 22.197642],
                [103.8722477, 22.1974218],
                [103.8722452, 22.1973109],
                [103.8722626, 22.1971047],
                [103.8723042, 22.1969168],
                [103.8723341, 22.196825],
                [103.8728464, 22.1958639],
                [103.8720739, 22.1953026],
                [103.8719612, 22.194935],
                [103.8718056, 22.1942993],
                [103.8717641, 22.1940695],
                [103.8716997, 22.1936074],
                [103.8716578, 22.1930951],
                [103.871647, 22.1925336],
                [103.8716554, 22.1922529],
                [103.8716861, 22.1920455],
                [103.8717116, 22.1919435],
                [103.8717803, 22.1917507],
                [103.87187, 22.1915724],
                [103.8719934, 22.1913712],
                [103.8720945, 22.1911438],
                [103.8721329, 22.191026],
                [103.8719828, 22.190671],
                [103.8718892, 22.1900932],
                [103.8720095, 22.1894391],
                [103.8720578, 22.1890243],
                [103.8717359, 22.1889349],
                [103.8715642, 22.1888734],
                [103.8714087, 22.1887821],
                [103.8712782, 22.1886674],
                [103.8711758, 22.1885352],
                [103.8711351, 22.188463],
                [103.8708548, 22.1881749],
                [103.8707988, 22.1880968],
                [103.8707515, 22.1880139],
                [103.8707133, 22.1879271],
                [103.8706845, 22.1878372],
                [103.8706573, 22.1876692],
                [103.870663, 22.1874994],
                [103.870468, 22.1872707],
                [103.8703735, 22.1872017],
                [103.8702596, 22.1871574],
                [103.8701338, 22.1871431],
                [103.8700705, 22.1871483],
                [103.8697189, 22.1872163],
                [103.8694297, 22.1872509],
                [103.8692844, 22.187261],
                [103.8686084, 22.187251],
                [103.8685297, 22.1869844],
                [103.8685012, 22.1868487],
                [103.8684643, 22.1865994],
                [103.8684506, 22.1865688],
                [103.8684088, 22.1865172],
                [103.8683285, 22.1864591],
                [103.8682007, 22.1863967],
                [103.8680521, 22.1863617],
                [103.8672405, 22.186352],
                [103.8671591, 22.1863405],
                [103.8670031, 22.1862925],
                [103.8669305, 22.1862566],
                [103.8668006, 22.1861632],
                [103.866698, 22.1860469],
                [103.8666578, 22.1859815],
                [103.8666022, 22.1858404],
                [103.8663825, 22.1856732],
                [103.8662795, 22.1855728],
                [103.8661987, 22.1854563],
                [103.8661432, 22.1853282],
                [103.8661147, 22.1851932],
                [103.866114, 22.1850555],
                [103.8661896, 22.1846],
                [103.866192, 22.184541],
                [103.86617, 22.1844248],
                [103.8661462, 22.1843701],
                [103.866086, 22.184284],
                [103.8660051, 22.1842139],
                [103.8656527, 22.1840335],
                [103.865504, 22.1839381],
                [103.8653755, 22.1838202],
                [103.8652694, 22.1836811],
                [103.8651904, 22.1835241],
                [103.865143, 22.1833566],
                [103.8651216, 22.1830039],
                [103.8651279, 22.1827746],
                [103.8651688, 22.1825483],
                [103.8652021, 22.1824377],
                [103.8652828, 22.1822448],
                [103.8653892, 22.1820629],
                [103.8654515, 22.181977],
                [103.866232, 22.1811412],
                [103.8662267, 22.1802719],
                [103.8668704, 22.1798472],
                [103.8670551, 22.1797126],
                [103.8672385, 22.1795447],
                [103.8674122, 22.1793405],
                [103.8675989, 22.1791547],
                [103.8677636, 22.1789518],
                [103.8678843, 22.1787687],
                [103.8679151, 22.178655],
                [103.8679123, 22.1786043],
                [103.8678834, 22.178506],
                [103.8678252, 22.1784191],
                [103.8683317, 22.1783048],
                [103.8685924, 22.1782253],
                [103.8690235, 22.1780775],
                [103.8692362, 22.1779968],
                [103.8695389, 22.1777704],
                [103.8696967, 22.1776649],
                [103.8700363, 22.177463],
                [103.870404, 22.1772791],
                [103.8710815, 22.1769983],
                [103.8711935, 22.1769102],
                [103.8713117, 22.1768292],
                [103.8714355, 22.1767558],
                [103.8715643, 22.1766904],
                [103.8717933, 22.176598],
                [103.8720322, 22.1765306],
                [103.8721544, 22.1765066],
                [103.8723538, 22.1764825],
                [103.8725542, 22.1764676],
                [103.8729344, 22.1764645],
                [103.8731133, 22.1764746],
                [103.8734687, 22.1765165],
                [103.873568, 22.1765499],
                [103.8737722, 22.1765997],
                [103.8739928, 22.1766268],
                [103.8741097, 22.1766305],
                [103.8743431, 22.1766158],
                [103.8748964, 22.1765304],
                [103.8751746, 22.1764966],
                [103.8756605, 22.1764523],
                [103.8759041, 22.176437],
                [103.8761616, 22.1762035],
                [103.8764567, 22.1761419],
                [103.8767571, 22.1761092],
                [103.877031, 22.1761046],
                [103.8773042, 22.1761241],
                [103.8775196, 22.1760401],
                [103.8777388, 22.1759651],
                [103.8780499, 22.1758757],
                [103.8782645, 22.1757887],
                [103.8784576, 22.1757395],
                [103.8786561, 22.1757142],
                [103.8794125, 22.1758384],
                [103.8810271, 22.1755627],
                [103.8813239, 22.1755287],
                [103.8816226, 22.175518],
                [103.8818021, 22.1755228],
                [103.8820704, 22.175544],
                [103.882159, 22.1755577],
                [103.8823379, 22.175604],
                [103.8824242, 22.175636],
                [103.8825882, 22.1757167],
                [103.8828175, 22.1755942],
                [103.8832899, 22.175373],
                [103.8835323, 22.1752746],
                [103.8839589, 22.1751244],
                [103.884395, 22.1749995],
                [103.8860536, 22.1746089],
                [103.8868368, 22.1741519],
                [103.8872231, 22.1741345],
                [103.8882289, 22.1741395],
                [103.8888002, 22.1733919],
                [103.8894653, 22.1726715],
                [103.8895096, 22.1724585],
                [103.8895449, 22.172355],
                [103.8896439, 22.1721516],
                [103.8897078, 22.1720535],
                [103.8897802, 22.1719605],
                [103.8898605, 22.1718732],
                [103.8902592, 22.1714793],
                [103.8906669, 22.1709328],
                [103.8910854, 22.1707838],
                [103.891173, 22.1705341],
                [103.891287, 22.1702937],
                [103.8914359, 22.170051],
                [103.8916228, 22.1698115],
                [103.8918375, 22.1695928],
                [103.8919544, 22.1694922],
                [103.8927161, 22.1690451],
                [103.8929005, 22.1689227],
                [103.8930627, 22.1687758],
                [103.8931989, 22.1686079],
                [103.8932985, 22.1684386],
                [103.8933384, 22.1683496],
                [103.8933458, 22.1682818],
                [103.8933458, 22.1682137],
                [103.8933242, 22.1680808],
                [103.8932757, 22.167956],
                [103.8932419, 22.1678975],
                [103.8934028, 22.1677385],
                [103.8933921, 22.1673411],
                [103.8934592, 22.1671755],
                [103.8935504, 22.1670332],
                [103.8936577, 22.1669152],
                [103.8937837, 22.1668145],
                [103.894263, 22.1665518],
                [103.8947332, 22.166263],
                [103.8948619, 22.1659948],
                [103.8953233, 22.165806],
                [103.8952803, 22.1655377],
                [103.895282, 22.1654871],
                [103.8953072, 22.1653887],
                [103.8953638, 22.1652949],
                [103.8954466, 22.1652198],
                [103.8960796, 22.1647627],
                [103.8960595, 22.1645741],
                [103.8960743, 22.1643851],
                [103.8961218, 22.1642063],
                [103.8961575, 22.1641203],
                [103.8962513, 22.1639579],
                [103.8967609, 22.1633816],
                [103.8960957, 22.1629642],
                [103.8961728, 22.1628088],
                [103.8962793, 22.162669],
                [103.8963425, 22.1626065],
                [103.8963868, 22.1624065],
                [103.8964547, 22.1622107],
                [103.896546, 22.1620216],
                [103.8966, 22.1619309],
                [103.8966179, 22.16174],
                [103.8966376, 22.1616459],
                [103.8966983, 22.1614621],
                [103.8967391, 22.1613733],
                [103.8968404, 22.1612049],
                [103.8969004, 22.161126],
                [103.8981342, 22.1601622],
                [103.898735, 22.15895],
                [103.8987629, 22.1587679],
                [103.8988233, 22.1585928],
                [103.8989146, 22.1584295],
                [103.8990324, 22.1582846],
                [103.8991724, 22.1581614],
                [103.89925, 22.1581082],
                [103.8994182, 22.1580203],
                [103.8995075, 22.1579862],
                [103.9007199, 22.1577775],
                [103.9009988, 22.1574098],
                [103.9014217, 22.1572191],
                [103.9016375, 22.1571326],
                [103.9020771, 22.1569776],
                [103.902558, 22.1568388],
                [103.9030475, 22.1567286],
                [103.9032948, 22.1566845],
                [103.9048397, 22.1560187],
                [103.9050813, 22.155941],
                [103.9052045, 22.1559094],
                [103.9054823, 22.1558569],
                [103.9056229, 22.1558399],
                [103.9060203, 22.1556674],
                [103.9062238, 22.1555914],
                [103.9065937, 22.1554732],
                [103.9067817, 22.1554225],
                [103.9069962, 22.1548661],
                [103.9072859, 22.1545381],
                [103.9073203, 22.1543713],
                [103.9073506, 22.154291],
                [103.9074361, 22.1541407],
                [103.9075381, 22.1540219],
                [103.9076609, 22.1539215],
                [103.9078009, 22.1538426],
                [103.9082086, 22.153773],
                [103.9090884, 22.1530874],
                [103.9091196, 22.1528344],
                [103.9091492, 22.15271],
                [103.9092357, 22.1524682],
                [103.9092922, 22.1523521],
                [103.9094215, 22.152144],
                [103.9095786, 22.1519532],
                [103.9097611, 22.1517828],
                [103.9098608, 22.1517062],
                [103.9101505, 22.1510205],
                [103.91066, 22.1503346],
                [103.910923, 22.149997],
                [103.9114916, 22.1493014],
                [103.9118671, 22.1486455],
                [103.9121246, 22.1484269],
                [103.912543, 22.1470606],
                [103.9126172, 22.1468699],
                [103.912701, 22.1466826],
                [103.9127944, 22.1464993],
                [103.9128971, 22.1463202],
                [103.9130054, 22.1461509],
                [103.9131221, 22.1459864],
                [103.9132468, 22.1458271],
                [103.9133793, 22.1456733],
                [103.9136998, 22.1453623],
                [103.9138691, 22.1452151],
                [103.9142246, 22.1449386],
                [103.9144626, 22.144775],
                [103.9149552, 22.1444694],
                [103.9152092, 22.1443278],
                [103.9156566, 22.1441173],
                [103.9157448, 22.1440843],
                [103.915928, 22.1440371],
                [103.9160266, 22.1440226],
                [103.916226, 22.1440154],
                [103.9164242, 22.1440371],
                [103.9168747, 22.1443079],
                [103.9170172, 22.1443726],
                [103.9171709, 22.1444172],
                [103.9173323, 22.1444396],
                [103.9181972, 22.144447],
                [103.9188516, 22.1436272],
                [103.9194068, 22.1435676],
                [103.9202195, 22.143026],
                [103.9207292, 22.1428073],
                [103.9211747, 22.1423327],
                [103.9213414, 22.1421932],
                [103.9214319, 22.1421316],
                [103.9216439, 22.1420175],
                [103.921756, 22.1419715],
                [103.9219898, 22.141903],
                [103.9222297, 22.1418808],
                [103.9223503, 22.1418798],
                [103.9225906, 22.1418981],
                [103.9227292, 22.1419211],
                [103.9228657, 22.1419532],
                [103.9229994, 22.1419942],
                [103.9231297, 22.1420438],
                [103.9232558, 22.1421018],
                [103.9235116, 22.1421931],
                [103.9237708, 22.1422757],
                [103.9239994, 22.1423405],
                [103.9244628, 22.1424496],
                [103.9253318, 22.1425241],
                [103.9255637, 22.1425086],
                [103.9257906, 22.1424619],
                [103.9260077, 22.142385],
                [103.9261143, 22.1423341],
                [103.9263135, 22.1422107],
                [103.9264903, 22.1420609],
                [103.926569, 22.1419771],
                [103.9266407, 22.1418881],
                [103.9269036, 22.1414931],
                [103.9269556, 22.1414241],
                [103.9270153, 22.1413608],
                [103.9270821, 22.1413038],
                [103.9271552, 22.1412539],
                [103.9272337, 22.1412117],
                [103.9273167, 22.1411776],
                [103.927405, 22.1411515],
                [103.927496, 22.1411348],
                [103.9275884, 22.1411274],
                [103.9276811, 22.1411296],
                [103.9277729, 22.1411413],
                [103.9280349, 22.1412142],
                [103.9282099, 22.1412367],
                [103.9284724, 22.141233],
                [103.9286461, 22.1412219],
                [103.9289913, 22.1411793],
                [103.9303207, 22.1409341],
                [103.9310449, 22.1407552],
                [103.9311855, 22.1407303],
                [103.9314704, 22.1407054],
                [103.9317616, 22.1407146],
                [103.9319087, 22.1407326],
                [103.9320541, 22.1407595],
                [103.9321974, 22.1407953],
                [103.9323378, 22.1408397],
                [103.9333999, 22.1412571],
                [103.9339364, 22.1418881],
                [103.9346123, 22.1418086],
                [103.9355242, 22.1415701],
                [103.9368948, 22.1417316],
                [103.9370118, 22.1417248],
                [103.9371202, 22.1416836],
                [103.9371676, 22.1416513],
                [103.9372086, 22.1416123],
                [103.9372625, 22.1415308],
                [103.9372891, 22.1414384],
                [103.9372918, 22.1412579],
                [103.9373063, 22.1411685],
                [103.937356, 22.1410069],
                [103.9374273, 22.1408661],
                [103.9374715, 22.1407999],
                [103.9376153, 22.1406289],
                [103.9376781, 22.1405373],
                [103.937787, 22.1403374],
                [103.9378317, 22.1402296],
                [103.9378685, 22.1401192],
                [103.9376378, 22.1398708],
                [103.937809, 22.1396343],
                [103.9378443, 22.1395627],
                [103.9378647, 22.1394607],
                [103.9378497, 22.1391875],
                [103.9378588, 22.1390319],
                [103.9378974, 22.1388803],
                [103.9379275, 22.1388074],
                [103.9380173, 22.1386576],
                [103.9380736, 22.1385894],
                [103.9382064, 22.13847],
                [103.9382815, 22.1384198],
                [103.9383094, 22.1375619],
                [103.9383693, 22.1371992],
                [103.938364, 22.1366329],
                [103.9383255, 22.1325554],
                [103.9379674, 22.1305604],
                [103.9380657, 22.1300624],
                [103.9382124, 22.1293191],
                [103.939163, 22.1282284],
                [103.9396081, 22.1265127],
                [103.9394175, 22.1257071],
                [103.9388744, 22.1248262],
                [103.9381124, 22.1243276],
                [103.9367014, 22.1239528],
                [103.9360763, 22.12351],
                [103.9358442, 22.1230331],
                [103.9359347, 22.1214615],
                [103.9365789, 22.119345],
                [103.9389969, 22.1163262],
                [103.9411159, 22.1139085],
                [103.9423095, 22.1118322],
                [103.9436285, 22.1087329],
                [103.9440557, 22.1067431],
                [103.9441885, 22.104733],
                [103.9435831, 22.1016578],
                [103.9436927, 22.1001046],
                [103.9439365, 22.0990093],
                [103.9439026, 22.0982778],
                [103.9435157, 22.0975074],
                [103.9415398, 22.0939481],
                [103.9398164, 22.0907377],
                [103.9389306, 22.0884286],
                [103.9387895, 22.086307],
                [103.9385946, 22.0834722],
                [103.9384533, 22.0813872],
                [103.9385122, 22.0811481],
                [103.9385343, 22.0810587],
                [103.9389258, 22.0812074],
                [103.9397082, 22.0815781],
                [103.9405724, 22.0815106],
                [103.9414377, 22.0812968],
                [103.9425024, 22.0806822],
                [103.9429792, 22.0799175],
                [103.9433055, 22.0782743],
                [103.9432233, 22.0761348],
                [103.9437805, 22.0751147],
                [103.9447666, 22.0744994],
                [103.9474087, 22.0729077],
                [103.9476491, 22.0722511],
                [103.9480979, 22.0699506],
                [103.9485573, 22.068875],
                [103.9497829, 22.0677128],
                [103.9519583, 22.0654964],
                [103.9530699, 22.0638216],
                [103.9534735, 22.0623251],
                [103.9538596, 22.0605178],
                [103.954199, 22.0570831],
                [103.9549644, 22.0544188],
                [103.9552331, 22.0525741],
                [103.9559506, 22.0510796],
                [103.9570023, 22.0495256],
                [103.9571826, 22.0490034],
                [103.9569649, 22.0465888],
                [103.9568134, 22.0444745],
                [103.9570148, 22.0424732],
                [103.9569041, 22.0415224],
                [103.9561328, 22.0396172],
                [103.9557095, 22.0384453],
                [103.9555595, 22.0374943],
                [103.9557254, 22.0362894],
                [103.9561102, 22.0346714],
                [103.9559639, 22.0332077],
                [103.9551186, 22.0307156],
                [103.9535313, 22.0277066],
                [103.9533993, 22.026956],
                [103.9534827, 22.0262983],
                [103.9539623, 22.0251311],
                [103.9566548, 22.0219665],
                [103.9582356, 22.0204773],
                [103.9617316, 22.0174457],
                [103.9644179, 22.0150856],
                [103.9649127, 22.014522],
                [103.9652313, 22.0138656],
                [103.9652376, 22.0130245],
                [103.9649395, 22.0108284],
                [103.9637777, 22.0086634],
                [103.9621445, 22.0065684],
                [103.9619573, 22.0053238],
                [103.9615136, 22.0042788],
                [103.9600318, 22.0029529],
                [103.9574932, 22.0012545],
                [103.9553412, 22.0003632],
                [103.9536605, 21.9994014],
                [103.9525783, 21.9988234],
                [103.9525793, 21.9981813],
                [103.9528094, 21.9978963],
                [103.9532689, 21.9978969],
                [103.9543409, 21.9979695],
                [103.9554131, 21.997971],
                [103.9561024, 21.9979005],
                [103.9567921, 21.9975446],
                [103.9620056, 21.9932702],
                [103.964843, 21.9904197],
                [103.9695214, 21.9852881],
                [103.9718997, 21.982009],
                [103.9722069, 21.9812957],
                [103.9722079, 21.9805822],
                [103.9721332, 21.9792265],
                [103.9722874, 21.9784418],
                [103.9724412, 21.9779426],
                [103.9736683, 21.976517],
                [103.9742819, 21.9756614],
                [103.9746655, 21.9752338],
                [103.9757383, 21.9745215],
                [103.9765044, 21.9741657],
                [103.9778834, 21.9735965],
                [103.9803355, 21.9721723],
                [103.9811787, 21.9713171],
                [103.9823289, 21.970034],
                [103.9840161, 21.9678953],
                [103.985835, 21.9668032],
                [103.9867038, 21.9664173],
                [103.9882106, 21.9656485],
                [103.9904404, 21.9639251],
                [103.9914713, 21.9628794],
                [103.9932034, 21.9604636],
                [103.9941064, 21.9589278],
                [103.9944125, 21.9583058],
                [103.9951556, 21.9566322],
                [103.9958396, 21.9545201],
                [103.9959635, 21.9537014],
                [103.9963576, 21.9513902],
                [103.996689, 21.9490436],
                [103.9968221, 21.9470016],
                [103.996493, 21.9452748],
                [103.9961045, 21.9445506],
                [103.9939663, 21.9431865],
                [103.992151, 21.9419888],
                [103.9907412, 21.9409893],
                [103.9902646, 21.9404321],
                [103.9901677, 21.9392939],
                [103.9904545, 21.9377054],
                [103.9910357, 21.9367255],
                [103.9915475, 21.9361369],
                [103.9930024, 21.9350371],
                [103.9943678, 21.9343446],
                [103.9952311, 21.9328331],
                [103.9957544, 21.9311726],
                [103.9961996, 21.9294261],
                [103.9968555, 21.9275796],
                [103.9972613, 21.927027],
                [103.9989089, 21.9258995],
                [103.9997878, 21.9250905],
                [104.0032351, 21.9232264],
                [104.0051537, 21.9222351],
                [104.0067446, 21.9211303],
                [104.0080357, 21.9201909],
                [104.0088677, 21.9194526],
                [104.0095888, 21.9188441],
                [104.0103096, 21.9182912],
                [104.0108181, 21.9181829],
                [104.012312, 21.9181361],
                [104.0132088, 21.9180579],
                [104.0140753, 21.9180074],
                [104.0146132, 21.9179828],
                [104.0155646, 21.9186292],
                [104.0164565, 21.9192474],
                [104.0168739, 21.9193614],
                [104.0181026, 21.9187836],
                [104.0190613, 21.9183715],
                [104.020498, 21.9179622],
                [104.0216502, 21.9176377],
                [104.0223682, 21.9174749],
                [104.0229957, 21.9174507],
                [104.0244584, 21.9175987],
                [104.0258019, 21.9176902],
                [104.0267881, 21.9176403],
                [104.0283129, 21.9174265],
                [104.029569, 21.917211],
                [104.0304064, 21.9170488],
                [104.0322626, 21.9164468],
                [104.0338939, 21.9160731],
                [104.0346751, 21.9154091],
                [104.0356678, 21.9143562],
                [104.0362382, 21.9139416],
                [104.0372482, 21.9147833],
                [104.0381394, 21.915513],
                [104.0389132, 21.9159353],
                [104.0406423, 21.9164469],
                [104.0416182, 21.9167016],
                [104.0420961, 21.9167044],
                [104.0423961, 21.9165111],
                [104.0426975, 21.9161229],
                [104.0430603, 21.9154563],
                [104.0433321, 21.9150121],
                [104.0437529, 21.9146246],
                [104.0445004, 21.9145175],
                [104.0454863, 21.9144953],
                [104.0461901, 21.9145578],
                [104.0469654, 21.9147573],
                [104.0478314, 21.9147902],
                [104.0490281, 21.9145185],
                [104.0508852, 21.9137491],
                [104.0527714, 21.9130913],
                [104.0549553, 21.9125746],
                [104.0566826, 21.9117822],
                [104.0588407, 21.9106524],
                [104.0609991, 21.9094667],
                [104.062168, 21.9088605],
                [104.0626769, 21.9086963],
                [104.0629453, 21.9087536],
                [104.0637793, 21.9091204],
                [104.0643154, 21.9093464],
                [104.0645847, 21.9092922],
                [104.065783, 21.9087418],
                [104.0672169, 21.9076444],
                [104.0684792, 21.9064536],
                [104.0691898, 21.9059594],
                [104.0698903, 21.9057699],
                [104.0718597, 21.9056368],
                [104.0741493, 21.9052911],
                [104.0762434, 21.9048013],
                [104.0770949, 21.9043928],
                [104.0783542, 21.9046412],
                [104.0797076, 21.9048765],
                [104.0813482, 21.90522],
                [104.0822726, 21.9054759],
                [104.0832837, 21.90615],
                [104.0844116, 21.9072708],
                [104.0847961, 21.907858],
                [104.0860009, 21.910985],
                [104.0865791, 21.9121145],
                [104.0874975, 21.9133176],
                [104.0881258, 21.9139812],
                [104.0882661, 21.9145756],
                [104.0883829, 21.9149941],
                [104.0885316, 21.9151064],
                [104.0893072, 21.9152499],
                [104.0901125, 21.9154495],
                [104.0912746, 21.9159016],
                [104.0921397, 21.9161014],
                [104.0928258, 21.9162445],
                [104.0936927, 21.9162266],
                [104.0953127, 21.9156492],
                [104.0965713, 21.915405],
                [104.0981736, 21.9152875],
                [104.0991066, 21.9151959],
                [104.0998601, 21.914844],
                [104.1004747, 21.9146798],
                [104.10079, 21.914514],
                [104.1013012, 21.9141538],
                [104.1020683, 21.9135439],
                [104.1026696, 21.9131006],
                [104.1036602, 21.9126593],
                [104.1051729, 21.9122726],
                [104.1062251, 21.9117584],
                [104.1073776, 21.9117024],
                [104.1079468, 21.9116217],
                [104.1085166, 21.9114574],
                [104.1094463, 21.9111552],
                [104.1099442, 21.9109382],
                [104.1107549, 21.9105237],
                [104.1116443, 21.9100836],
                [104.1128136, 21.9096194],
                [104.1132618, 21.909373],
                [104.1142361, 21.9090351],
                [104.1147454, 21.9089541],
                [104.1150146, 21.9089834],
                [104.1153136, 21.9090409],
                [104.1158802, 21.9093789],
                [104.1165957, 21.9098294],
                [104.1173424, 21.9100847],
                [104.1183519, 21.9100649],
                [104.119115, 21.9099589],
                [104.1200644, 21.9096821],
                [104.1212021, 21.9089635],
                [104.1222226, 21.9084944],
                [104.1236622, 21.9080274],
                [104.1254018, 21.9074225],
                [104.127142, 21.9067338],
                [104.1283597, 21.906082],
                [104.1290511, 21.9055832],
                [104.1297131, 21.9050005],
                [104.1304387, 21.9038038],
                [104.1307108, 21.9026199],
                [104.1308827, 21.9020525],
                [104.1315324, 21.9016811],
                [104.1325192, 21.9012743],
                [104.1350409, 21.9000642],
                [104.1365022, 21.8993565],
                [104.1371934, 21.8988854],
                [104.1378551, 21.8983305],
                [104.1382477, 21.8977534],
                [104.1385351, 21.8972524],
                [104.1389414, 21.8968636],
                [104.139617, 21.8964903],
                [104.1402619, 21.8962563],
                [104.1410711, 21.8960651],
                [104.1417297, 21.8960406],
                [104.142612, 21.8961431],
                [104.1433665, 21.8961226],
                [104.1438463, 21.8959715],
                [104.1444312, 21.8957513],
                [104.145121, 21.8955036],
                [104.1456309, 21.8953248],
                [104.1461403, 21.8952157],
                [104.1468744, 21.8950661],
                [104.1478321, 21.8950709],
                [104.1490587, 21.8951471],
                [104.1496228, 21.8952058],
                [104.1502352, 21.8953905],
                [104.1512046, 21.8959399],
                [104.1516523, 21.8961516],
                [104.1519514, 21.896195],
                [104.1524013, 21.8960158],
                [104.1531603, 21.8956157],
                [104.154024, 21.895274],
                [104.154766, 21.8948562],
                [104.1552766, 21.8945519],
                [104.1556669, 21.8943444],
                [104.1560118, 21.8942206],
                [104.1566857, 21.8941263],
                [104.1577285, 21.8938548],
                [104.1584327, 21.8937048],
                [104.1592269, 21.8935134],
                [104.1602166, 21.8931556],
                [104.1612963, 21.8927702],
                [104.1618316, 21.8924983],
                [104.1621775, 21.8922069],
                [104.1626578, 21.891958],
                [104.1635263, 21.8918647],
                [104.1644241, 21.8918554],
                [104.1661441, 21.8920036],
                [104.1671833, 21.8921204],
                [104.1677519, 21.8921232],
                [104.1681711, 21.8920835],
                [104.1689055, 21.8918779],
                [104.1696551, 21.8916444],
                [104.1703597, 21.8914106],
                [104.1713202, 21.8909268],
                [104.1715829, 21.8908481],
                [104.1720622, 21.8907806],
                [104.1730799, 21.8907439],
                [104.1739778, 21.8907205],
                [104.1749512, 21.8905998],
                [104.1760739, 21.8905216],
                [104.1767175, 21.890511],
                [104.1778537, 21.8906841],
                [104.1786536, 21.8908007],
                [104.1793119, 21.8908039],
                [104.1799108, 21.890751],
                [104.1802404, 21.8906829],
                [104.1811264, 21.890129],
                [104.1815311, 21.8900192],
                [104.182235, 21.8899112],
                [104.1837323, 21.8897371],
                [104.1861521, 21.8892992],
                [104.1902395, 21.8887388],
                [104.1919056, 21.8885552],
                [104.192774, 21.8884478],
                [104.1931487, 21.888352],
                [104.1937331, 21.8881873],
                [104.1941829, 21.8880359],
                [104.1946331, 21.8878008],
                [104.1950381, 21.8876353],
                [104.195233, 21.8875664],
                [104.1955774, 21.8875402],
                [104.1963092, 21.8877811],
                [104.1979506, 21.8885847],
                [104.2002993, 21.8898487],
                [104.2021349, 21.890737],
                [104.2030012, 21.8910204],
                [104.2041655, 21.8915424],
                [104.2050915, 21.891868],
                [104.2055552, 21.8918982],
                [104.2071622, 21.8918779],
                [104.2088991, 21.8916908],
                [104.2096177, 21.8916245],
                [104.2103512, 21.8915861],
                [104.2108892, 21.8916864],
                [104.2112924, 21.8918419],
                [104.2115764, 21.8918991],
                [104.2119205, 21.8919007],
                [104.2123288, 21.8917233],
                [104.2126882, 21.8916691],
                [104.2130491, 21.8913872],
                [104.2134891, 21.8914256],
                [104.2142081, 21.8922606],
                [104.2160034, 21.8934346],
                [104.2175388, 21.8945307],
                [104.2185381, 21.8951216],
                [104.2203743, 21.89594],
                [104.2214554, 21.8964256],
                [104.2219927, 21.8966794],
                [104.2225892, 21.897073],
                [104.2231255, 21.8975223],
                [104.2237816, 21.8979441],
                [104.2245128, 21.8983246],
                [104.2254693, 21.8985663],
                [104.226081, 21.8989181],
                [104.227059, 21.8995013],
                [104.2282084, 21.9000231],
                [104.2290585, 21.9005576],
                [104.2305181, 21.9015094],
                [104.2309563, 21.9024171],
                [104.2310477, 21.9029754],
                [104.2307139, 21.9038393],
                [104.2299289, 21.9051059],
                [104.2287514, 21.9073529],
                [104.2279952, 21.908843],
                [104.2277852, 21.909351],
                [104.2276621, 21.9099785],
                [104.2277017, 21.9109698],
                [104.2277435, 21.9115282],
                [104.2279035, 21.9123314],
                [104.2285238, 21.9138697],
                [104.2290393, 21.9154075],
                [104.2297455, 21.9173554],
                [104.2300558, 21.9181106],
                [104.2302166, 21.9188093],
                [104.2306431, 21.9200321],
                [104.2309376, 21.9209268],
                [104.2310411, 21.9215007],
                [104.2311405, 21.9223589],
                [104.2311654, 21.9229862],
                [104.2310741, 21.923251],
                [104.2309686, 21.923404],
                [104.230563, 21.9236534],
                [104.2298877, 21.9239991],
                [104.2288826, 21.9244132],
                [104.2286123, 21.9245795],
                [104.2279665, 21.9250092],
                [104.2269509, 21.9254663],
                [104.2265655, 21.9257349],
                [104.2263945, 21.9260678],
                [104.2259756, 21.9273295],
                [104.2254809, 21.9277866],
                [104.2250354, 21.9282808],
                [104.2243692, 21.9296875],
                [104.2239775, 21.9308041],
                [104.2234769, 21.9320301],
                [104.2233103, 21.9323782],
                [104.2231594, 21.9326009],
                [104.222649, 21.9328776],
                [104.2221834, 21.9331686],
                [104.2220478, 21.9333214],
                [104.2216858, 21.9339305],
                [104.2214746, 21.9342365],
                [104.2209787, 21.9345831],
                [104.2204235, 21.9348318],
                [104.2199133, 21.9350806],
                [104.2188624, 21.9356339],
                [104.2166625, 21.936538],
                [104.2152672, 21.9371176],
                [104.2132168, 21.9381765],
                [104.2115056, 21.9390477],
                [104.2107696, 21.9395187],
                [104.2102127, 21.9400604],
                [104.2096114, 21.9405321],
                [104.2090378, 21.9413949],
                [104.2088309, 21.9419838],
                [104.2086181, 21.9425691],
                [104.2084647, 21.9432523],
                [104.2083715, 21.943866],
                [104.2083945, 21.9451224],
                [104.2084812, 21.94559],
                [104.2086142, 21.9458977],
                [104.2089114, 21.94629],
                [104.2098938, 21.9472858],
                [104.2114139, 21.9485493],
                [104.2120278, 21.9491153],
                [104.2142324, 21.949533],
                [104.2151619, 21.9492861],
                [104.2160012, 21.9490947],
                [104.2166605, 21.9490002],
                [104.2179332, 21.9489503],
                [104.2197941, 21.9488493],
                [104.2207527, 21.9487422],
                [104.221667, 21.9485371],
                [104.2223864, 21.948401],
                [104.2229564, 21.9481803],
                [104.2242623, 21.9475165],
                [104.2255086, 21.9468244],
                [104.2265064, 21.9462377],
                [104.2276938, 21.94535],
                [104.2284154, 21.944795],
                [104.2294985, 21.943809],
                [104.2302201, 21.9432401],
                [104.2309502, 21.9427481],
                [104.232059, 21.9422248],
                [104.232824, 21.9419353],
                [104.2336335, 21.9417017],
                [104.2342778, 21.9416071],
                [104.235356, 21.9415144],
                [104.2356258, 21.9414598],
                [104.2364057, 21.9411842],
                [104.2368257, 21.9410047],
                [104.2371352, 21.940979],
                [104.2379878, 21.9410946],
                [104.2386464, 21.9411116],
                [104.239537, 21.9412027],
                [104.2403392, 21.9411901],
                [104.2410787, 21.9409638],
                [104.2419879, 21.940471],
                [104.2430081, 21.9400431],
                [104.2438408, 21.9396458],
                [104.2446818, 21.9391193],
                [104.2455083, 21.9385228],
                [104.2465453, 21.937732],
                [104.247472, 21.9370587],
                [104.248599, 21.9362264],
                [104.2518941, 21.9337192],
                [104.2524298, 21.9333657],
                [104.2533346, 21.9326767],
                [104.2537601, 21.9324313],
                [104.2543446, 21.9321864],
                [104.2548227, 21.9319906],
                [104.2553004, 21.9318938],
                [104.2559902, 21.9317978],
                [104.2567854, 21.9318014],
                [104.2578982, 21.9319054],
                [104.2592764, 21.9319613],
                [104.260549, 21.9319175],
                [104.2619807, 21.9318744],
                [104.2625634, 21.9319759],
                [104.2639938, 21.9321804],
                [104.2651594, 21.9323341],
                [104.2657948, 21.9324855],
                [104.2668002, 21.9328859],
                [104.2674345, 21.9332352],
                [104.2682813, 21.9335359],
                [104.2686524, 21.9335376],
                [104.2696077, 21.9333439],
                [104.2703505, 21.9332482],
                [104.2706688, 21.9332001],
                [104.2713576, 21.9333022],
                [104.2721523, 21.9334047],
                [104.2741114, 21.9339082],
                [104.2752225, 21.9343587],
                [104.2761738, 21.9349568],
                [104.2776016, 21.9357056],
                [104.2790824, 21.9364545],
                [104.2799279, 21.9370027],
                [104.280931, 21.9378486],
                [104.2822513, 21.9388938],
                [104.2831498, 21.9394422],
                [104.2839869, 21.9401541],
                [104.2846381, 21.9387063],
                [104.2853861, 21.937571],
                [104.2860277, 21.9364848],
                [104.2866151, 21.935646],
                [104.2889614, 21.9329338],
                [104.2908301, 21.9303184],
                [104.2914692, 21.9297272],
                [104.2920548, 21.9292347],
                [104.2924286, 21.928692],
                [104.2927489, 21.9282478],
                [104.293069, 21.9278532],
                [104.2932825, 21.9275571],
                [104.2936013, 21.92741],
                [104.2942914, 21.927215],
                [104.2951406, 21.9270206],
                [104.2959897, 21.9268758],
                [104.2974747, 21.9267336],
                [104.2989072, 21.9265417],
                [104.2997023, 21.926545],
                [104.3002328, 21.9264979],
                [104.3011355, 21.9262048],
                [104.3020907, 21.9260108],
                [104.3029959, 21.9259421],
                [104.3032606, 21.9252733],
                [104.3035283, 21.92473],
                [104.3039552, 21.9241872],
                [104.3044874, 21.9237441],
                [104.3048062, 21.9235969],
                [104.3052303, 21.9235987],
                [104.306768, 21.9235556],
                [104.3081468, 21.9234625],
                [104.3096317, 21.9233697],
                [104.3101093, 21.9232727],
                [104.3108531, 21.9229293],
                [104.3118637, 21.9222406],
                [104.3128759, 21.9212054],
                [104.3136208, 21.920664],
                [104.3139923, 21.9205666],
                [104.3151598, 21.920324],
                [104.3164861, 21.9201315],
                [104.3180256, 21.9196924],
                [104.3194594, 21.9191538],
                [104.321105, 21.9187152],
                [104.3223781, 21.9185225],
                [104.3234919, 21.918428],
                [104.3255594, 21.9184365],
                [104.3252516, 21.9162573],
                [104.3251017, 21.9143263],
                [104.3252182, 21.9120994],
                [104.3253821, 21.9110606],
                [104.3257048, 21.9100719],
                [104.3259712, 21.909776],
                [104.3265562, 21.9093824],
                [104.3271935, 21.9091376],
                [104.3279367, 21.9088931],
                [104.3291045, 21.9085513],
                [104.3292642, 21.9084036],
                [104.3294288, 21.9072163],
                [104.3297087, 21.9040495],
                [104.3298224, 21.9024165],
                [104.3301024, 21.8992003],
                [104.3301573, 21.8988046],
                [104.3309542, 21.8984118],
                [104.3327621, 21.8971816],
                [104.334411, 21.8959509],
                [104.3362197, 21.8945227],
                [104.3371775, 21.8937346],
                [104.3378179, 21.8927967],
                [104.337926, 21.8923516],
                [104.3379897, 21.8900255],
                [104.3381581, 21.8879967],
                [104.3386963, 21.886217],
                [104.3392297, 21.8854766],
                [104.3400811, 21.8847376],
                [104.3410916, 21.8839992],
                [104.3425252, 21.8834108],
                [104.3437988, 21.8830694],
                [104.3466114, 21.8822886],
                [104.3477803, 21.8816499],
                [104.3485249, 21.8810588],
                [104.3506521, 21.8794832],
                [104.3522487, 21.8780046],
                [104.3529945, 21.877166],
                [104.3536344, 21.8762776],
                [104.3540725, 21.8758057],
                [104.3551042, 21.8764814],
                [104.3558428, 21.8772268],
                [104.3563713, 21.8775754],
                [104.3567945, 21.8777256],
                [104.3575893, 21.8777781],
                [104.3583834, 21.8779792],
                [104.3592305, 21.8781805],
                [104.3597061, 21.8784793],
                [104.3610256, 21.8797219],
                [104.3617638, 21.8805663],
                [104.3620257, 21.8812603],
                [104.362393, 21.8821033],
                [104.3626543, 21.8829457],
                [104.3627567, 21.883738],
                [104.3628083, 21.8840353],
                [104.3629668, 21.8841843],
                [104.3637074, 21.8844843],
                [104.3654542, 21.8849859],
                [104.3691038, 21.8866829],
                [104.3700027, 21.8871814],
                [104.3707966, 21.8874319],
                [104.3714849, 21.887583],
                [104.3724388, 21.8876361],
                [104.3728621, 21.8877862],
                [104.3732848, 21.8880848],
                [104.3740764, 21.8888798],
                [104.3752349, 21.8906168],
                [104.375973, 21.8915105],
                [104.3764485, 21.8918588],
                [104.3771893, 21.8921586],
                [104.378194, 21.8927068],
                [104.3787223, 21.8931049],
                [104.3790384, 21.8935515],
                [104.3802517, 21.8948926],
                [104.3826264, 21.8973269],
                [104.3834717, 21.8979735],
                [104.3842644, 21.8985211],
                [104.3850577, 21.8989694],
                [104.3854802, 21.8993175],
                [104.3857435, 21.8997145],
                [104.3858472, 21.9002594],
                [104.3858974, 21.9009031],
                [104.3858379, 21.9024374],
                [104.3858873, 21.9032789],
                [104.3861464, 21.9046659],
                [104.3861967, 21.9053096],
                [104.3860813, 21.9074871],
                [104.3861264, 21.9093187],
                [104.3863887, 21.9099632],
                [104.3865978, 21.910657],
                [104.3868606, 21.9112024],
                [104.3869646, 21.9116483],
                [104.3871752, 21.9119956],
                [104.3887634, 21.9124965],
                [104.3892921, 21.9128449],
                [104.389767, 21.9133417],
                [104.3908766, 21.9141873],
                [104.3914583, 21.9145359],
                [104.3924638, 21.9149356],
                [104.3929923, 21.9153336],
                [104.3933081, 21.9158792],
                [104.3935706, 21.9164742],
                [104.3942032, 21.917318],
                [104.394944, 21.9176672],
                [104.3954193, 21.9180649],
                [104.3958936, 21.9187597],
                [104.3968442, 21.9196046],
                [104.3972142, 21.9198535],
                [104.3987501, 21.9202056],
                [104.3999695, 21.92021],
                [104.4008186, 21.9200151],
                [104.4015611, 21.9199188],
                [104.4028862, 21.9199732],
                [104.4040515, 21.9202249],
                [104.4048455, 21.9205247],
                [104.4055326, 21.9210222],
                [104.4067491, 21.9217197],
                [104.4087043, 21.9232611],
                [104.4095487, 21.9242046],
                [104.4107635, 21.925298],
                [104.4124016, 21.9265908],
                [104.4143562, 21.9283302],
                [104.415095, 21.9291744],
                [104.4156762, 21.9296713],
                [104.4162584, 21.929921],
                [104.418272, 21.9301755],
                [104.419596, 21.9305268],
                [104.4201248, 21.9308751],
                [104.4207576, 21.9317188],
                [104.4212326, 21.932265],
                [104.4218136, 21.9328115],
                [104.422447, 21.9335067],
                [104.4231346, 21.9339051],
                [104.4239819, 21.9341557],
                [104.4248288, 21.934505],
                [104.4260999, 21.9348559],
                [104.4269996, 21.9352551],
                [104.4273168, 21.9355038],
                [104.4276336, 21.9358513],
                [104.4279498, 21.9362979],
                [104.4285791, 21.9380326],
                [104.4289994, 21.9390241],
                [104.4296279, 21.9409566],
                [104.4299955, 21.9418489],
                [104.4303097, 21.94284],
                [104.4309403, 21.9442776],
                [104.4312564, 21.9447737],
                [104.4321019, 21.9455192],
                [104.4322071, 21.9457174],
                [104.4319883, 21.9473996],
                [104.4317713, 21.9486364],
                [104.4313929, 21.9504665],
                [104.4312324, 21.9508125],
                [104.4300622, 21.9517489],
                [104.4290517, 21.9524878],
                [104.4288386, 21.9527346],
                [104.4286781, 21.9530805],
                [104.4285685, 21.9539712],
                [104.4284073, 21.9545151],
                [104.428194, 21.9548114],
                [104.4279279, 21.9550579],
                [104.4275556, 21.9553041],
                [104.4268651, 21.9555987],
                [104.4286071, 21.9575847],
                [104.4293454, 21.9586268],
                [104.4295026, 21.9590727],
                [104.4296562, 21.9604593],
                [104.4296004, 21.9611521],
                [104.4295951, 21.9624885],
                [104.4293798, 21.9632797],
                [104.4286322, 21.9645641],
                [104.4281526, 21.9651564],
                [104.4281515, 21.9654039],
                [104.428203, 21.9658496],
                [104.4290454, 21.9673375],
                [104.4298326, 21.9694192],
                [104.4300404, 21.9705088],
                [104.4302495, 21.971252],
                [104.4302466, 21.9719945],
                [104.4300257, 21.9741717],
                [104.429757, 21.9750618],
                [104.4294902, 21.9754568],
                [104.428371, 21.976839],
                [104.4278373, 21.9776785],
                [104.4270868, 21.9796558],
                [104.426979, 21.980101],
                [104.4269776, 21.9804474],
                [104.4274508, 21.9814886],
                [104.4275012, 21.9821322],
                [104.4276573, 21.9828752],
                [104.4275999, 21.983964],
                [104.427439, 21.9844089],
                [104.4267441, 21.9857429],
                [104.4257837, 21.9871751],
                [104.4257294, 21.9875214],
                [104.4257811, 21.9878184],
                [104.4259912, 21.9883637],
                [104.4260425, 21.9887599],
                [104.4259354, 21.989007],
                [104.4257221, 21.9893033],
                [104.4254028, 21.9895496],
                [104.4250308, 21.9897463],
                [104.4247124, 21.9897947],
                [104.4242351, 21.989793],
                [104.4235981, 21.9898898],
                [104.4230135, 21.9901847],
                [104.4225345, 21.990579],
                [104.4221596, 21.9914687],
                [104.4221048, 21.991914],
                [104.422312, 21.9931522],
                [104.4222529, 21.9946369],
                [104.4220906, 21.9954283],
                [104.4215533, 21.9971094],
                [104.4212327, 21.9977023],
                [104.4211238, 21.9983949],
                [104.4211227, 21.9989496],
                [104.4212732, 21.9998356],
                [104.421773, 22.0004759],
                [104.4217721, 22.0006895],
                [104.4212664, 22.0015697],
                [104.421191, 22.0019554],
                [104.4213058, 22.0027828],
                [104.4214224, 22.0031691],
                [104.4214653, 22.0035276],
                [104.4215821, 22.003845],
                [104.4215518, 22.0040517],
                [104.4213294, 22.0042438],
                [104.4205744, 22.0046615],
                [104.4203222, 22.0049362],
                [104.4201438, 22.0052526],
                [104.4199641, 22.0058446],
                [104.4196175, 22.0075386],
                [104.4190092, 22.0082049],
                [104.4177048, 22.0093305],
                [104.417274, 22.0099354],
                [104.4171696, 22.0101969],
                [104.417123, 22.0107481],
                [104.4171942, 22.0113961],
                [104.4173406, 22.0117274],
                [104.4175901, 22.0120935],
                [104.4189286, 22.0134902],
                [104.4191343, 22.0137391],
                [104.4192073, 22.0139599],
                [104.4191174, 22.0142766],
                [104.4188645, 22.0147029],
                [104.4187003, 22.0151434],
                [104.4179424, 22.0162639],
                [104.4171832, 22.0177084],
                [104.4169118, 22.0190443],
                [104.4169085, 22.0198506],
                [104.4167098, 22.0214762],
                [104.416302, 22.0236731],
                [104.4159558, 22.025243],
                [104.4155821, 22.0263029],
                [104.4150752, 22.0274519],
                [104.4148949, 22.0281956],
                [104.4144781, 22.0289522],
                [104.414226, 22.0291855],
                [104.4140032, 22.029488],
                [104.4132444, 22.0308222],
                [104.4129472, 22.0312414],
                [104.4127245, 22.0314887],
                [104.4125023, 22.0316534],
                [104.4113324, 22.0323382],
                [104.4105484, 22.0325835],
                [104.4096767, 22.0325941],
                [104.4089537, 22.0324123],
                [104.408792, 22.0322325],
                [104.4086444, 22.0321769],
                [104.4085409, 22.0322317],
                [104.4085109, 22.0323143],
                [104.4085675, 22.0329347],
                [104.408373, 22.0335129],
                [104.4080756, 22.0339803],
                [104.4077047, 22.0343512],
                [104.4071402, 22.0350865],
                [104.4064578, 22.0357869],
                [104.4060569, 22.0362816],
                [104.4058781, 22.0366394],
                [104.4051204, 22.0376841],
                [104.4048228, 22.0381929],
                [104.4046735, 22.0385645],
                [104.4045376, 22.03926],
                [104.4043591, 22.0395488],
                [104.4035423, 22.0405519],
                [104.4027275, 22.0411003],
                [104.4023277, 22.0413193],
                [104.401852, 22.0420067],
                [104.4018061, 22.0424063],
                [104.4018338, 22.0428266],
                [104.4017417, 22.043667],
                [104.4010719, 22.0448361],
                [104.4008493, 22.0450696],
                [104.3997379, 22.0458787],
                [104.3993979, 22.0459326],
                [104.3990131, 22.0460691],
                [104.3985675, 22.0466393],
                [104.397942, 22.0478224],
                [104.3977636, 22.0480697],
                [104.3973067, 22.0481851],
                [104.3956943, 22.0482964],
                [104.3947931, 22.0482931],
                [104.3940836, 22.0483594],
                [104.393448, 22.0484398],
                [104.3927971, 22.0486165],
                [104.3925297, 22.04896],
                [104.3923952, 22.0493179],
                [104.3922895, 22.049855],
                [104.3922572, 22.0504958],
                [104.3923539, 22.0520672],
                [104.392292, 22.0527149],
                [104.3919604, 22.0542642],
                [104.3914097, 22.055213],
                [104.3907707, 22.0560789],
                [104.3905619, 22.0565192],
                [104.389017, 22.0584361],
                [104.388853, 22.0587801],
                [104.3887329, 22.0592482],
                [104.3885776, 22.060998],
                [104.3881015, 22.0617543],
                [104.3870195, 22.062515],
                [104.3867384, 22.0626243],
                [104.3864122, 22.0628711],
                [104.3862041, 22.063146],
                [104.3852753, 22.064864],
                [104.3835776, 22.0657617],
                [104.3827642, 22.065924],
                [104.3819647, 22.0662793],
                [104.3811669, 22.0662625],
                [104.3803546, 22.0661493],
                [104.3799997, 22.0662168],
                [104.3784893, 22.0669621],
                [104.3777795, 22.0670835],
                [104.3775277, 22.0672342],
                [104.3772455, 22.06755],
                [104.3771561, 22.0677427],
                [104.3767404, 22.0681684],
                [104.3765921, 22.0682918],
                [104.3763408, 22.0683046],
                [104.3760892, 22.068414],
                [104.3758517, 22.0686473],
                [104.3757326, 22.0688674],
                [104.3759524, 22.0692679],
                [104.3761143, 22.0694202],
                [104.3770582, 22.0698372],
                [104.3775885, 22.0702045],
                [104.3786056, 22.0707872],
                [104.3795043, 22.0714108],
                [104.3796365, 22.0715629],
                [104.3796505, 22.0717559],
                [104.3792615, 22.072857],
                [104.3792594, 22.0733394],
                [104.3794001, 22.0749456],
                [104.3794084, 22.0764065],
                [104.3795518, 22.0774408],
                [104.3795648, 22.0778474],
                [104.3794264, 22.0790597],
                [104.3792768, 22.0794725],
                [104.3793032, 22.080217],
                [104.3792419, 22.0807266],
                [104.3789713, 22.0817799],
                [104.3788072, 22.0821101],
                [104.3787471, 22.0823442],
                [104.3786861, 22.0827712],
                [104.3786831, 22.0834603],
                [104.3788688, 22.0849219],
                [104.3788808, 22.0855628],
                [104.3787281, 22.0867062],
                [104.3788258, 22.0880158],
                [104.379274, 22.0902847],
                [104.379522, 22.0910024],
                [104.3797275, 22.0913339],
                [104.3802125, 22.0919422],
                [104.3805661, 22.0921916],
                [104.3810676, 22.092414],
                [104.3815837, 22.0927054],
                [104.3823065, 22.0930114],
                [104.3828235, 22.0930685],
                [104.3832515, 22.0932148],
                [104.3842692, 22.0936871],
                [104.3851404, 22.0938421],
                [104.3862933, 22.0938188],
                [104.3876809, 22.0941962],
                [104.3883154, 22.0944191],
                [104.3890969, 22.0948492],
                [104.389525, 22.0949749],
                [104.389805, 22.0951414],
                [104.3899816, 22.095335],
                [104.3899949, 22.0956657],
                [104.3899042, 22.0961409],
                [104.389962, 22.0964444],
                [104.3899007, 22.096954],
                [104.38991, 22.098222],
                [104.3901274, 22.099229],
                [104.3900184, 22.1005034],
                [104.3901564, 22.1007849],
                [104.3903951, 22.1023808],
                [104.3910675, 22.1039884],
                [104.3913127, 22.1044198],
                [104.3917981, 22.1049592],
                [104.3917721, 22.1051614],
                [104.3923456, 22.1058251],
                [104.3925218, 22.1061152],
                [104.3927572, 22.1063642],
                [104.3932288, 22.106683],
                [104.3946862, 22.1080597],
                [104.3952598, 22.1087096],
                [104.3956125, 22.1091932],
                [104.3961551, 22.1102013],
                [104.3963309, 22.1105741],
                [104.396418, 22.1109466],
                [104.396417, 22.1111809],
                [104.3963266, 22.1115871],
                [104.3962645, 22.1123035],
                [104.3960994, 22.1128818],
                [104.3959779, 22.1136394],
                [104.3962285, 22.1137919],
                [104.3972766, 22.1141266],
                [104.3978672, 22.1142666],
                [104.3992547, 22.1147403],
                [104.3996969, 22.1150106],
                [104.4006246, 22.115841],
                [104.400845, 22.1161588],
                [104.4007807, 22.1166527],
                [104.401022, 22.117055],
                [104.4008799, 22.1176274],
                [104.4013986, 22.1189489],
                [104.4022498, 22.1176723],
                [104.4022029, 22.117364],
                [104.4024822, 22.1169918],
                [104.4032683, 22.1163744],
                [104.4036245, 22.1160173],
                [104.4040531, 22.1160327],
                [104.4044075, 22.1161029],
                [104.4060905, 22.116619],
                [104.4064347, 22.1167995],
                [104.4048206, 22.1198463],
                [104.4039545, 22.1219243],
                [104.4036841, 22.1229362],
                [104.4036385, 22.1232393],
                [104.4038673, 22.1250594],
                [104.4038155, 22.1268441],
                [104.4036791, 22.127629],
                [104.4034096, 22.128455],
                [104.4022191, 22.1303155],
                [104.4004201, 22.132733],
                [104.3993195, 22.1343266],
                [104.3982433, 22.1357159],
                [104.3987176, 22.1366757],
                [104.3988339, 22.1371583],
                [104.3992598, 22.1378349],
                [104.3994498, 22.1383729],
                [104.4000959, 22.1394221],
                [104.4004617, 22.1403051],
                [104.4008579, 22.1410642],
                [104.4015723, 22.1434472],
                [104.4017466, 22.1441922],
                [104.4018179, 22.1448265],
                [104.4019346, 22.1451854],
                [104.4021512, 22.1464199],
                [104.4022504, 22.1474679],
                [104.4022313, 22.1484879],
                [104.4021405, 22.1489563],
                [104.4023014, 22.149391],
                [104.4023004, 22.1496117],
                [104.4009505, 22.1506267],
                [104.4006681, 22.1509566],
                [104.4004446, 22.1513279],
                [104.399982, 22.1523325],
                [104.3996824, 22.1532481],
                [104.3996222, 22.153496],
                [104.3996197, 22.1540749],
                [104.3997065, 22.1545302],
                [104.3998198, 22.1557161],
                [104.3997255, 22.1570322],
                [104.399621, 22.1572523],
                [104.3992046, 22.1578021],
                [104.3989967, 22.1579944],
                [104.3971592, 22.1588974],
                [104.3956932, 22.1593331],
                [104.3953524, 22.1594972],
                [104.3947591, 22.1599086],
                [104.3939724, 22.1605743],
                [104.3938391, 22.1606289],
                [104.3936175, 22.1605867],
                [104.3933665, 22.1604756],
                [104.393131, 22.1602265],
                [104.3924379, 22.159769],
                [104.3922605, 22.1597546],
                [104.3921417, 22.1598782],
                [104.3919778, 22.1601395],
                [104.3915096, 22.1624122],
                [104.3910438, 22.1641198],
                [104.3900895, 22.1659289],
                [104.3897922, 22.1662862],
                [104.3895757, 22.1667704],
                [104.3892147, 22.1688997],
                [104.3887274, 22.1707837],
                [104.387839, 22.1724348],
                [104.3873221, 22.1730217],
                [104.3868432, 22.1734082],
                [104.3866063, 22.1735473],
                [104.3859489, 22.1738603],
                [104.38512, 22.1740789],
                [104.3848239, 22.1743336],
                [104.3845135, 22.1747679],
                [104.3843932, 22.1750831],
                [104.3843791, 22.1754096],
                [104.3844895, 22.1757443],
                [104.3846223, 22.175905],
                [104.38479, 22.176017],
                [104.3851318, 22.1761492],
                [104.3854931, 22.1762271],
                [104.3872684, 22.1760933],
                [104.3878609, 22.1761261],
                [104.3881727, 22.1762481],
                [104.3887713, 22.1766941],
                [104.3893064, 22.1769048],
                [104.3913465, 22.1764569],
                [104.3918417, 22.1764115],
                [104.3923981, 22.1764224],
                [104.3940831, 22.176746],
                [104.394533, 22.1767434],
                [104.3949148, 22.1766232],
                [104.3958727, 22.1767219],
                [104.3962688, 22.1768332],
                [104.3967014, 22.1770857],
                [104.3970328, 22.1773877],
                [104.39726, 22.1777143],
                [104.3974407, 22.1781161],
                [104.3977408, 22.1790379],
                [104.3987548, 22.1807801],
                [104.3991393, 22.1817289],
                [104.3998508, 22.1837261],
                [104.4001006, 22.1842841],
                [104.4002924, 22.1844182],
                [104.4020202, 22.1849037],
                [104.4032417, 22.1853645],
                [104.4053271, 22.186318],
                [104.4063706, 22.1868704],
                [104.4065249, 22.1869913],
                [104.4064843, 22.1871632],
                [104.4057546, 22.1880697],
                [104.4053784, 22.1886792],
                [104.4052509, 22.1889912],
                [104.404997, 22.1899172],
                [104.404758, 22.1909075],
                [104.4047559, 22.1911482],
                [104.4048234, 22.1912153],
                [104.4051268, 22.1913039],
                [104.4062651, 22.1915241],
                [104.4073957, 22.191675],
                [104.4078987, 22.1917987],
                [104.4093322, 22.1922882],
                [104.4100404, 22.1926303],
                [104.4105656, 22.1929531],
                [104.4110581, 22.1933976],
                [104.4114861, 22.1940069],
                [104.4122337, 22.1953768],
                [104.4130681, 22.1972791],
                [104.4136209, 22.1981503],
                [104.4143748, 22.1989475],
                [104.4154267, 22.1998611],
                [104.4159242, 22.2001339],
                [104.416494, 22.2002833],
                [104.4172842, 22.2002835],
                [104.4180609, 22.2000916],
                [104.4187735, 22.2000144],
                [104.4205286, 22.2000985],
                [104.4212775, 22.2000523],
                [104.4218225, 22.1999694],
                [104.4228885, 22.1996809],
                [104.4241948, 22.1994442],
                [104.4248933, 22.1992411],
                [104.425607, 22.1989544],
                [104.4259876, 22.1987478],
                [104.4263602, 22.1984563],
                [104.4266878, 22.1980958],
                [104.4269222, 22.1977228],
                [104.4271747, 22.1970899],
                [104.427417, 22.196681],
                [104.4277707, 22.1963851],
                [104.4280304, 22.1962408],
                [104.4283346, 22.1961193],
                [104.4293924, 22.1958747],
                [104.42979, 22.1957297],
                [104.4310591, 22.1950287],
                [104.4321351, 22.194545],
                [104.4329117, 22.1942976],
                [104.4345158, 22.1938912],
                [104.4351189, 22.1936092],
                [104.4353521, 22.1933986],
                [104.4355899, 22.1930933],
                [104.4364913, 22.1918256],
                [104.4368262, 22.1912854],
                [104.438159, 22.1888576],
                [104.4394088, 22.1863846],
                [104.4398286, 22.1858508],
                [104.4402133, 22.185609],
                [104.4416936, 22.1850887],
                [104.4420908, 22.1849927],
                [104.4444035, 22.1845958],
                [104.4451378, 22.1845098],
                [104.445402, 22.1845312],
                [104.4456267, 22.1846089],
                [104.4476717, 22.1858856],
                [104.4481158, 22.1861181],
                [104.4482469, 22.186136],
                [104.4483657, 22.1860896],
                [104.449208, 22.1854395],
                [104.4497959, 22.1850741],
                [104.4504095, 22.1848656],
                [104.4509798, 22.1847933],
                [104.4515432, 22.1848122],
                [104.4521165, 22.1849679],
                [104.4528252, 22.1853165],
                [104.4535112, 22.1857467],
                [104.4542176, 22.1846723],
                [104.4554161, 22.1831463],
                [104.4558766, 22.1824229],
                [104.456218, 22.1816966],
                [104.4563755, 22.1810619],
                [104.456428, 22.180494],
                [104.4564412, 22.1777188],
                [104.4565786, 22.177251],
                [104.4568373, 22.1767603],
                [104.4569353, 22.176379],
                [104.457135, 22.1753281],
                [104.4572638, 22.1739685],
                [104.4574332, 22.1727662],
                [104.4574428, 22.1719744],
                [104.457184, 22.1708487],
                [104.4571741, 22.1704681],
                [104.4572586, 22.1701971],
                [104.4574462, 22.1698963],
                [104.4581954, 22.1690505],
                [104.4588035, 22.1680636],
                [104.4590647, 22.1677593],
                [104.4593683, 22.1676012],
                [104.4597769, 22.1674834],
                [104.4611538, 22.1671739],
                [104.4620667, 22.166921],
                [104.4642114, 22.1665443],
                [104.4650184, 22.1660363],
                [104.465487, 22.1655877],
                [104.4663425, 22.1650202],
                [104.468152, 22.1633454],
                [104.4688944, 22.1628974],
                [104.469233, 22.1627336],
                [104.4696521, 22.1625997],
                [104.4705704, 22.1624527],
                [104.4710053, 22.1624391],
                [104.4720848, 22.1621875],
                [104.4723752, 22.1620459],
                [104.4726827, 22.1616117],
                [104.4729252, 22.1613574],
                [104.4732653, 22.1608032],
                [104.4734115, 22.1604735],
                [104.4734608, 22.1601885],
                [104.4738997, 22.1590794],
                [104.4741136, 22.157827],
                [104.4741474, 22.1573769],
                [104.4743109, 22.1567171],
                [104.4746517, 22.1559979],
                [104.475008, 22.1553987],
                [104.4756697, 22.1549957],
                [104.4772335, 22.1543854],
                [104.4779594, 22.1540125],
                [104.4788148, 22.1534225],
                [104.4792995, 22.1529739],
                [104.4804614, 22.1522122],
                [104.4810108, 22.1516587],
                [104.4812534, 22.1513594],
                [104.4820801, 22.1498162],
                [104.4830996, 22.1483863],
                [104.4837626, 22.1475629],
                [104.4841192, 22.1468888],
                [104.4842509, 22.1460638],
                [104.4844456, 22.1456743],
                [104.4849147, 22.1450529],
                [104.4851895, 22.1447687],
                [104.4865434, 22.143742],
                [104.4865916, 22.1436429],
                [104.4867078, 22.1436235],
                [104.4880457, 22.1428718],
                [104.4901896, 22.1420832],
                [104.4910274, 22.1418906],
                [104.4923811, 22.1414643],
                [104.492865, 22.1411959],
                [104.493688, 22.1405985],
                [104.4943178, 22.1400606],
                [104.4952881, 22.1388189],
                [104.4961134, 22.1376066],
                [104.4967916, 22.1370238],
                [104.4979359, 22.1366224],
                [104.499144, 22.1364162],
                [104.4994502, 22.1363122],
                [104.5003044, 22.1358198],
                [104.5006857, 22.1354235],
                [104.5013829, 22.1344382],
                [104.5015402, 22.1341656],
                [104.5017972, 22.1333651],
                [104.5018963, 22.1331827],
                [104.5023461, 22.1328933],
                [104.5025809, 22.1328029],
                [104.5034215, 22.1327691],
                [104.5037347, 22.1326608],
                [104.5049879, 22.1319908],
                [104.5061047, 22.1312293],
                [104.5070252, 22.1306858],
                [104.5072801, 22.1304681],
                [104.5085927, 22.1295615],
                [104.509847, 22.128603],
                [104.5105526, 22.1280221],
                [104.5108076, 22.1277312],
                [104.511082, 22.1275316],
                [104.5112975, 22.1274047],
                [104.5117671, 22.1272603],
                [104.5143876, 22.1268126],
                [104.5160905, 22.1260705],
                [104.516541, 22.1257803],
                [104.5169913, 22.1255813],
                [104.5183439, 22.1243643],
                [104.5187945, 22.1240742],
                [104.5192449, 22.1238204],
                [104.5201058, 22.1235495],
                [104.5204777, 22.1233867],
                [104.5207323, 22.1232052],
                [104.5211439, 22.1228784],
                [104.5216692, 22.1222792],
                [104.5220456, 22.1215419],
                [104.522408, 22.1204703],
                [104.5227915, 22.1189136],
                [104.5233273, 22.1183138],
                [104.5236894, 22.1173535],
                [104.5239072, 22.1170337],
                [104.5245523, 22.11636],
                [104.5254998, 22.1147049],
                [104.5259053, 22.1141349],
                [104.5263706, 22.113586],
                [104.5266345, 22.1129598],
                [104.526907, 22.1119158],
                [104.5276462, 22.1107278],
                [104.52796, 22.1103825],
                [104.5284081, 22.1100813],
                [104.5287975, 22.1099013],
                [104.5298381, 22.1097684],
                [104.5309335, 22.1094799],
                [104.5337644, 22.1088429],
                [104.5345579, 22.1084968],
                [104.5351423, 22.1080946],
                [104.5354722, 22.1078028],
                [104.5360576, 22.1070801],
                [104.5364928, 22.1066714],
                [104.536957, 22.1063165],
                [104.5379158, 22.1057341],
                [104.5383659, 22.1052339],
                [104.5385703, 22.1048004],
                [104.5399261, 22.1035387],
                [104.5405262, 22.1028996],
                [104.5407222, 22.1024125],
                [104.5410675, 22.1019398],
                [104.5413669, 22.1018153],
                [104.5421149, 22.1016503],
                [104.5429587, 22.101387],
                [104.5445402, 22.1005843],
                [104.5451239, 22.100377],
                [104.5455733, 22.1000857],
                [104.5458134, 22.099752],
                [104.5458144, 22.0994873],
                [104.5456825, 22.0990257],
                [104.545606, 22.0985153],
                [104.5457239, 22.0983151],
                [104.5469872, 22.0973591],
                [104.5473323, 22.0969839],
                [104.5476928, 22.0963859],
                [104.5483546, 22.0951061],
                [104.5486682, 22.0941505],
                [104.5491996, 22.0929492],
                [104.549279, 22.0925851],
                [104.5494168, 22.0922574],
                [104.549545, 22.092142],
                [104.5497247, 22.0920589],
                [104.5503827, 22.0919771],
                [104.5518037, 22.0917024],
                [104.5548143, 22.0908692],
                [104.5561726, 22.0904049],
                [104.5570117, 22.0897802],
                [104.5575963, 22.0892663],
                [104.5588247, 22.0884059],
                [104.5601121, 22.0877965],
                [104.5612504, 22.087075],
                [104.5625192, 22.0863541],
                [104.5630144, 22.0866819],
                [104.5654492, 22.089203],
                [104.5657022, 22.0895135],
                [104.5659545, 22.0900791],
                [104.5661079, 22.0910087],
                [104.5668371, 22.0932336],
                [104.5672086, 22.0939589],
                [104.5674765, 22.0943498],
                [104.567849, 22.0947408],
                [104.5691593, 22.096447],
                [104.5697054, 22.0967948],
                [104.5700952, 22.0971238],
                [104.5706362, 22.0974646],
                [104.571009, 22.0977722],
                [104.5714716, 22.098038],
                [104.5720538, 22.0982765],
                [104.5721579, 22.0984996],
                [104.5720824, 22.0987362],
                [104.5718678, 22.0990053],
                [104.5717301, 22.0992965],
                [104.5713768, 22.0998058],
                [104.5709435, 22.1009162],
                [104.5706261, 22.1024824],
                [104.5707033, 22.1028288],
                [104.5708972, 22.1033032],
                [104.5715789, 22.1040703],
                [104.5719681, 22.104618],
                [104.572942, 22.1056774],
                [104.5737013, 22.1066452],
                [104.5741665, 22.1079037],
                [104.5743388, 22.1090887],
                [104.5746689, 22.1098002],
                [104.5749801, 22.1102747],
                [104.5758963, 22.1110424],
                [104.5775166, 22.1116297],
                [104.5790208, 22.1118704],
                [104.5807206, 22.1120207],
                [104.5818145, 22.1121875],
                [104.5825566, 22.1123533],
                [104.5828492, 22.1125545],
                [104.5830047, 22.1128283],
                [104.5830432, 22.1130288],
                [104.5829254, 22.1132107],
                [104.5796334, 22.1161908],
                [104.5792208, 22.1169185],
                [104.5789645, 22.1176468],
                [104.5789243, 22.1180293],
                [104.5788627, 22.1190313],
                [104.5788354, 22.1216735],
                [104.5789125, 22.1219835],
                [104.5794558, 22.1233333],
                [104.5796285, 22.1244272],
                [104.579822, 22.1250654],
                [104.5803456, 22.1264334],
                [104.5805596, 22.1267984],
                [104.5809881, 22.1272915],
                [104.5813767, 22.1280578],
                [104.5814513, 22.1292789],
                [104.5812833, 22.1332508],
                [104.5813342, 22.1358203],
                [104.5810773, 22.1368037],
                [104.5807824, 22.1373496],
                [104.5805636, 22.1386246],
                [104.5806793, 22.1391532],
                [104.5807958, 22.1394269],
                [104.5811081, 22.1396099],
                [104.5818113, 22.1397758],
                [104.5821429, 22.1399953],
                [104.5824544, 22.1404334],
                [104.5827442, 22.1415638],
                [104.5829774, 22.1420382],
                [104.5833478, 22.142349],
                [104.5838161, 22.1426417],
                [104.5850653, 22.1432826],
                [104.5852989, 22.143593],
                [104.5853175, 22.1439575],
                [104.5852571, 22.1445223],
                [104.5849209, 22.1458334],
                [104.5846065, 22.1464339],
                [104.5836845, 22.1475431],
                [104.5834682, 22.1479799],
                [104.5828545, 22.1505841],
                [104.582853, 22.1510761],
                [104.5825765, 22.1520411],
                [104.582321, 22.1525143],
                [104.5813985, 22.1537511],
                [104.5811038, 22.1542605],
                [104.5809461, 22.1546973],
                [104.5809258, 22.1549707],
                [104.5809643, 22.1551712],
                [104.581276, 22.1555],
                [104.5818221, 22.1559387],
                [104.5828367, 22.1566155],
                [104.5834614, 22.1569087],
                [104.5842233, 22.1570928],
                [104.5856891, 22.1572059],
                [104.5863338, 22.1573716],
                [104.5868805, 22.157628],
                [104.5873488, 22.1579389],
                [104.5878166, 22.1583776],
                [104.5884594, 22.1591809],
                [104.5889273, 22.1596376],
                [104.5891413, 22.1599843],
                [104.5891211, 22.1602395],
                [104.5888462, 22.1606396],
                [104.5855693, 22.1647677],
                [104.5851177, 22.1654226],
                [104.5846471, 22.1658952],
                [104.5841564, 22.1665442],
                [104.5836351, 22.1671313],
                [104.5835241, 22.1679898],
                [104.5835495, 22.1685962],
                [104.5839533, 22.1695064],
                [104.5841402, 22.1704921],
                [104.5840793, 22.1729553],
                [104.5836358, 22.1762882],
                [104.5836338, 22.1769702],
                [104.583944, 22.1772321],
                [104.5853988, 22.1777503],
                [104.5860643, 22.1780928],
                [104.5877472, 22.1788167],
                [104.5887108, 22.1794945],
                [104.5891036, 22.1801421],
                [104.5892648, 22.1808522],
                [104.5896767, 22.1813283],
                [104.5900541, 22.1820618],
                [104.5904593, 22.1825175],
                [104.590978, 22.1816402],
                [104.591522, 22.18101],
                [104.5921738, 22.1805571],
                [104.5929877, 22.1803318],
                [104.5939084, 22.1806119],
                [104.5971829, 22.1824386],
                [104.598456, 22.1827449],
                [104.5993774, 22.1828482],
                [104.6002725, 22.1826231],
                [104.6010329, 22.1820945],
                [104.6016855, 22.1813636],
                [104.6020955, 22.1801775],
                [104.601855, 22.1789392],
                [104.6018117, 22.1773812],
                [104.6026713, 22.1758964],
                [104.6034543, 22.1751883],
                [104.6044062, 22.1750671],
                [104.6052236, 22.1751787],
                [104.6076088, 22.1762253],
                [104.6087782, 22.1765054],
                [104.6105938, 22.1766613],
                [104.6123021, 22.1764381],
                [104.614283, 22.1755839],
                [104.6148798, 22.1754338],
                [104.6156112, 22.175587],
                [104.6165038, 22.1762459],
                [104.6176926, 22.1776632],
                [104.6183144, 22.1782204],
                [104.6187749, 22.1783224],
                [104.6193446, 22.1781722],
                [104.6197248, 22.1778701],
                [104.6203215, 22.1768521],
                [104.6220984, 22.1746282],
                [104.6230112, 22.1737293],
                [104.6243565, 22.1728303],
                [104.625106, 22.1724771],
                [104.626384, 22.1721024],
                [104.6262882, 22.1724093],
                [104.6253836, 22.1734627],
                [104.6246952, 22.1741347],
                [104.6244041, 22.1748748],
                [104.6244388, 22.1754475],
                [104.6248704, 22.1762231],
                [104.6251936, 22.1769985],
                [104.6250828, 22.1779075],
                [104.6247193, 22.1786813],
                [104.6239572, 22.1798583],
                [104.6226158, 22.181438],
                [104.6214182, 22.1833212],
                [104.6206555, 22.1846665],
                [104.6205079, 22.185656],
                [104.6205407, 22.1869022],
                [104.6203584, 22.1874744],
                [104.6195246, 22.1884154],
                [104.619016, 22.1893909],
                [104.6190132, 22.1904349],
                [104.6193699, 22.192187],
                [104.619368, 22.1928605],
                [104.6191501, 22.1932642],
                [104.6177381, 22.1941702],
                [104.617158, 22.1948424],
                [104.6167211, 22.1959864],
                [104.616498, 22.197651],
                [104.6160609, 22.198896],
                [104.6153658, 22.201959],
                [104.6151435, 22.2039456],
                [104.6152463, 22.2060001],
                [104.6157086, 22.2076337],
                [104.6161054, 22.2079378],
                [104.6172606, 22.208513],
                [104.6177296, 22.2088172],
                [104.6177291, 22.2090193],
                [104.6175472, 22.2094567],
                [104.6164215, 22.21134],
                [104.6162757, 22.2117774],
                [104.6163835, 22.2119798],
                [104.6186971, 22.2119852],
                [104.619781, 22.2122235],
                [104.6212974, 22.2128669],
                [104.6226692, 22.2135774],
                [104.6237513, 22.2144555],
                [104.6247966, 22.2156029],
                [104.6268163, 22.2173588],
                [104.6286551, 22.2191817],
                [104.6264479, 22.2199849],
                [104.6252186, 22.2200158],
                [104.6241336, 22.2202154],
                [104.6233735, 22.220584],
                [104.6229383, 22.2210545],
                [104.621324, 22.2232708],
                [104.6208163, 22.2238421],
                [104.6140152, 22.2255437],
                [104.6127498, 22.2255407],
                [104.6112328, 22.2250319],
                [104.6085609, 22.2238804],
                [104.6077315, 22.2231375],
                [104.6067582, 22.2221248],
                [104.6057833, 22.2216846],
                [104.6048268, 22.221481],
                [104.6038143, 22.2215459],
                [104.6017891, 22.221844],
                [104.6010666, 22.2216403],
                [104.6000933, 22.2206611],
                [104.5990846, 22.2194126],
                [104.5979308, 22.2183657],
                [104.5975345, 22.2178932],
                [104.5969561, 22.2178918],
                [104.5965211, 22.2183286],
                [104.595978, 22.2185966],
                [104.5952908, 22.2186959],
                [104.594495, 22.2188961],
                [104.5934454, 22.2193648],
                [104.5927584, 22.2193632],
                [104.5919272, 22.2192937],
                [104.5916015, 22.2194276],
                [104.5909482, 22.2203353],
                [104.5896429, 22.2216454],
                [104.5866763, 22.2238028],
                [104.5860597, 22.2244748],
                [104.5858776, 22.2249458],
                [104.5858768, 22.2252152],
                [104.5860923, 22.2256873],
                [104.587137, 22.2270034],
                [104.5872798, 22.2276099],
                [104.5872781, 22.2281825],
                [104.5870232, 22.228788],
                [104.5856454, 22.2301653],
                [104.5843775, 22.231004],
                [104.5839788, 22.2313734],
                [104.5839775, 22.2318112],
                [104.5842293, 22.2322498],
                [104.5854892, 22.2341052],
                [104.5864954, 22.2361621],
                [104.5870338, 22.2375106],
                [104.5869874, 22.2391191],
                [104.5863459, 22.2406112],
                [104.5856227, 22.2430548],
                [104.5857376, 22.2445744],
                [104.5869214, 22.2478009],
                [104.5872347, 22.2494691],
                [104.5871132, 22.2501729],
                [104.586832, 22.2508599],
                [104.5860421, 22.2513836],
                [104.5840689, 22.2517321],
                [104.5793382, 22.2526212],
                [104.5782584, 22.251619],
                [104.5771338, 22.2508941],
                [104.5763599, 22.2500441],
                [104.5758256, 22.2481675],
                [104.5752841, 22.2469473],
                [104.5751738, 22.2458649],
                [104.5745871, 22.2454425],
                [104.5733385, 22.2453237],
                [104.5720145, 22.2453294],
                [104.5676972, 22.2448587],
                [104.5633262, 22.243519],
                [104.5604652, 22.2425361],
                [104.5579327, 22.2426186],
                [104.5556099, 22.2419993],
                [104.55389, 22.2417479],
                [104.5521136, 22.2417103],
                [104.5512494, 22.2424905],
                [104.5508067, 22.2435364],
                [104.5497251, 22.2477511],
                [104.5495169, 22.2494071],
                [104.5495252, 22.2509969],
                [104.5498303, 22.2518868],
                [104.5499095, 22.2529961],
                [104.5497328, 22.2538422],
                [104.5492682, 22.2546887],
                [104.5489467, 22.2560561],
                [104.5484256, 22.2573331],
                [104.5480211, 22.258291],
                [104.5476511, 22.2603571],
                [104.5478159, 22.2631007],
                [104.5477214, 22.2656108],
                [104.5467564, 22.2687],
                [104.5454191, 22.270886],
                [104.5438656, 22.2725355],
                [104.5428844, 22.2737888],
                [104.5422083, 22.2749122],
                [104.5423774, 22.2763724],
                [104.5429556, 22.2776666],
                [104.543696, 22.2783447],
                [104.5457166, 22.2793528],
                [104.5492297, 22.2821751],
                [104.5508406, 22.2835843],
                [104.5516281, 22.2838949],
                [104.5530598, 22.2839575],
                [104.5556249, 22.283624],
                [104.5574568, 22.2834927],
                [104.5587009, 22.2839048],
                [104.5625012, 22.2868444],
                [104.5648768, 22.288588],
                [104.5653099, 22.290701],
                [104.565963, 22.2927465],
                [104.5691749, 22.2965701],
                [104.5715154, 22.2978706],
                [104.5765307, 22.3005246],
                [104.5786485, 22.3028463],
                [104.5791553, 22.3048914],
                [104.5794346, 22.3095247],
                [104.5793172, 22.312011],
                [104.5783533, 22.3158235],
                [104.5764437, 22.3176738],
                [104.5740205, 22.3193706],
                [104.5721074, 22.3219543],
                [104.5721372, 22.3241685],
                [104.571841, 22.3251895],
                [104.5694909, 22.3268865],
                [104.5659654, 22.3294659],
                [104.564716, 22.3306207],
                [104.5647041, 22.3313113],
                [104.5663825, 22.3336321],
                [104.5708763, 22.3379699],
                [104.5731395, 22.3408371],
                [104.5742365, 22.3415893],
                [104.5779706, 22.3428253],
                [104.5798731, 22.3438521],
                [104.5810743, 22.3465461],
                [104.5806293, 22.3483163],
                [104.5792315, 22.3499727],
                [104.5744584, 22.3529577],
                [104.5693223, 22.3547154],
                [104.5677983, 22.3549846],
                [104.5672323, 22.3550845],
                [104.5614451, 22.3540471],
                [104.5593948, 22.3534285],
                [104.5560232, 22.3534193],
                [104.553897, 22.3536179],
                [104.551913, 22.3551792],
                [104.5513329, 22.3561005],
                [104.5507116, 22.3566679],
                [104.5494028, 22.3572898],
                [104.5484385, 22.3577382],
                [104.5477551, 22.3582866],
                [104.5463828, 22.3598757],
                [104.5459353, 22.3608013],
                [104.5458928, 22.3613552],
                [104.5463041, 22.3619409],
                [104.5470732, 22.3628643],
                [104.5481132, 22.3639605],
                [104.5483542, 22.364751],
                [104.5485479, 22.365724],
                [104.5492041, 22.3665936],
                [104.5505031, 22.3681577],
                [104.5509655, 22.368967],
                [104.5516642, 22.3695522],
                [104.5523471, 22.3704591],
                [104.5536943, 22.3718783],
                [104.5536549, 22.3725105],
                [104.5533217, 22.373096],
                [104.5531216, 22.3738737],
                [104.5524881, 22.3746496],
                [104.5516104, 22.3753881],
                [104.5504661, 22.3766521],
                [104.5496734, 22.3778015],
                [104.5497741, 22.3783823],
                [104.550032, 22.3787966],
                [104.5504936, 22.3790938],
                [104.5507711, 22.3795401],
                [104.5507509, 22.3799473],
                [104.5503936, 22.3802227],
                [104.5488505, 22.3803581],
                [104.5473833, 22.3809109],
                [104.5463127, 22.3809798],
                [104.5445709, 22.3818742],
                [104.5415335, 22.3832133],
                [104.5405426, 22.3836615],
                [104.539511, 22.3844709],
                [104.5374521, 22.3855559],
                [104.53669, 22.3857271],
                [104.5355456, 22.3863059],
                [104.5338686, 22.3869109],
                [104.5328986, 22.3872254],
                [104.5315911, 22.3878939],
                [104.5309635, 22.388304],
                [104.5304712, 22.3888486],
                [104.5286841, 22.3901737],
                [104.5277552, 22.3908625],
                [104.5270832, 22.3909084],
                [104.5268281, 22.3907758],
                [104.5261053, 22.3908275],
                [104.5255306, 22.3909883],
                [104.5249183, 22.3913235],
                [104.5238727, 22.3923628],
                [104.5224212, 22.3935859],
                [104.5211815, 22.3951189],
                [104.5206445, 22.3955259],
                [104.518863, 22.3957494],
                [104.5177995, 22.3958181],
                [104.5165294, 22.3964411],
                [104.514586, 22.3974803],
                [104.5130743, 22.3986294],
                [104.5127313, 22.3996978],
                [104.5121849, 22.4003039],
                [104.5114859, 22.4008745],
                [104.5109764, 22.4010178],
                [104.5104667, 22.4013318],
                [104.509767, 22.4017605],
                [104.5091939, 22.4023783],
                [104.5084454, 22.4036228],
                [104.507962, 22.4039478],
                [104.5064577, 22.4042047],
                [104.5056366, 22.4044962],
                [104.5048628, 22.4045781],
                [104.504219, 22.4044613],
                [104.5039772, 22.4043204],
                [104.5032074, 22.404158],
                [104.5012289, 22.4045261],
                [104.5002038, 22.4047791],
                [104.4991762, 22.4050799],
                [104.4984542, 22.4050371],
                [104.4979868, 22.4048673],
                [104.4975018, 22.4047811],
                [104.4969044, 22.4050965],
                [104.4963541, 22.4061467],
                [104.4951502, 22.4073911],
                [104.493603, 22.4089503],
                [104.4934065, 22.4095751],
                [104.4931177, 22.4101062],
                [104.4920865, 22.4105241],
                [104.491278, 22.4114857],
                [104.4909001, 22.4122724],
                [104.4904906, 22.4127422],
                [104.4901935, 22.4136638],
                [104.4904032, 22.4147742],
                [104.491133, 22.4161764],
                [104.4906509, 22.4176499],
                [104.4906683, 22.4183491],
                [104.4905545, 22.4194889],
                [104.4899505, 22.4197346],
                [104.4888679, 22.41994],
                [104.487043, 22.4205897],
                [104.4855844, 22.4207792],
                [104.4842182, 22.4209533],
                [104.48285, 22.4209648],
                [104.4820807, 22.4208898],
                [104.4811373, 22.4205749],
                [104.4799348, 22.4204205],
                [104.4786222, 22.4204896],
                [104.4777615, 22.4207903],
                [104.4762521, 22.4214773],
                [104.4738587, 22.4226243],
                [104.4727723, 22.4229144],
                [104.4717361, 22.4229556],
                [104.4704073, 22.4233959],
                [104.4691674, 22.423531],
                [104.468273, 22.4237569],
                [104.4669092, 22.4242679],
                [104.4662602, 22.4248636],
                [104.4657163, 22.4253687],
                [104.46524, 22.4272638],
                [104.4648023, 22.4293133],
                [104.4645261, 22.4302157],
                [104.4641818, 22.4308989],
                [104.4633496, 22.4314543],
                [104.4623682, 22.4332386],
                [104.4619994, 22.434312],
                [104.4616527, 22.4346115],
                [104.461485, 22.4353501],
                [104.460837, 22.4357391],
                [104.4601039, 22.4356731],
                [104.4589506, 22.4358915],
                [104.4579862, 22.4364192],
                [104.4568348, 22.437709],
                [104.4556885, 22.4396775],
                [104.4549275, 22.4412108],
                [104.4538493, 22.4427624],
                [104.4529667, 22.4436093],
                [104.4522337, 22.4445781],
                [104.4519224, 22.4453017],
                [104.4514995, 22.4459572],
                [104.4502169, 22.4473006],
                [104.4493959, 22.4483421],
                [104.44892, 22.4491842],
                [104.4487903, 22.4499935],
                [104.4491416, 22.450675],
                [104.4494818, 22.4512108],
                [104.4495593, 22.4519463],
                [104.4490222, 22.4536264],
                [104.4486392, 22.4542245],
                [104.4481913, 22.455244],
                [104.4481857, 22.456149],
                [104.448134, 22.457106],
                [104.4482165, 22.4577866],
                [104.4482097, 22.4593126],
                [104.4487659, 22.4602728],
                [104.449276, 22.4609064],
                [104.4498282, 22.4615774],
                [104.4502591, 22.4623334],
                [104.4510268, 22.4629401],
                [104.4517352, 22.4631977],
                [104.452888, 22.4637013],
                [104.4538751, 22.4639662],
                [104.4550488, 22.4644036],
                [104.4560446, 22.4648604],
                [104.4565324, 22.4653141],
                [104.45698, 22.4658195],
                [104.4573506, 22.4665826],
                [104.4578797, 22.4672451],
                [104.4587141, 22.4685163],
                [104.4593083, 22.4692651],
                [104.46067, 22.4702486],
                [104.461501, 22.4707699],
                [104.4624848, 22.4711679],
                [104.4630752, 22.4715782],
                [104.4635254, 22.4720853],
                [104.4640795, 22.4735279],
                [104.4642505, 22.4743986],
                [104.4642115, 22.4751822],
                [104.464077, 22.4759287],
                [104.4642298, 22.4765228],
                [104.4645546, 22.4769737],
                [104.4650341, 22.4771968],
                [104.4657831, 22.4776791],
                [104.4669144, 22.4787296],
                [104.4683627, 22.4801675],
                [104.4693725, 22.4805937],
                [104.4717188, 22.4819866],
                [104.4724966, 22.4822048],
                [104.4734554, 22.4827053],
                [104.4738941, 22.4830034],
                [104.4740626, 22.4835136],
                [104.4736114, 22.4840055],
                [104.4727598, 22.4843316],
                [104.472021, 22.4848276],
                [104.4716363, 22.4855874],
                [104.47161, 22.4859602],
                [104.4714004, 22.4870632],
                [104.4712871, 22.4881071],
                [104.4707941, 22.4888871],
                [104.4700661, 22.4894229],
                [104.4691787, 22.4899101],
                [104.4673633, 22.4903584],
                [104.4662629, 22.4905342],
                [104.4651833, 22.4906174],
                [104.464148, 22.4907797],
                [104.4632215, 22.4910727],
                [104.4622746, 22.4911841],
                [104.4613525, 22.4919235],
                [104.4606349, 22.4926653],
                [104.4602261, 22.493394],
                [104.4593913, 22.4942902],
                [104.4587337, 22.4948907],
                [104.4582947, 22.4948491],
                [104.4572188, 22.4949117],
                [104.4560307, 22.4949228],
                [104.4551554, 22.4947099],
                [104.45416, 22.4947658],
                [104.4533177, 22.4953823],
                [104.4527936, 22.4961684],
                [104.4522235, 22.4970521],
                [104.4505682, 22.4987302],
                [104.4498001, 22.4997448],
                [104.4492316, 22.5002933],
                [104.4486496, 22.5009291],
                [104.4480237, 22.5011546],
                [104.4476411, 22.5015647],
                [104.4460175, 22.5030703],
                [104.445168, 22.5039699],
                [104.4449586, 22.5052655],
                [104.445044, 22.506731],
                [104.4451127, 22.5084595],
                [104.4449743, 22.5095392],
                [104.4445684, 22.5105045],
                [104.4439492, 22.5122983],
                [104.4431238, 22.5136542],
                [104.4420723, 22.5150027],
                [104.4411484, 22.5160006],
                [104.4402074, 22.5169457],
                [104.4393769, 22.5179608],
                [104.438651, 22.5187632],
                [104.4378888, 22.5193151],
                [104.4370985, 22.519711],
                [104.4359758, 22.5202728],
                [104.435278, 22.5206329],
                [104.4346831, 22.5213995],
                [104.4341308, 22.5223271],
                [104.4335339, 22.5225421],
                [104.4317576, 22.5223596],
                [104.4304367, 22.5225861],
                [104.4296601, 22.5227308],
                [104.4288742, 22.5229534],
                [104.4279479, 22.5229667],
                [104.4265104, 22.5232949],
                [104.425686, 22.5238069],
                [104.4248548, 22.5241512],
                [104.4237428, 22.5244492],
                [104.4222206, 22.5246621],
                [104.4210105, 22.524744],
                [104.4199397, 22.5249416],
                [104.4185439, 22.5250196],
                [104.4175536, 22.5252664],
                [104.4164833, 22.5255284],
                [104.4155923, 22.5262407],
                [104.4149184, 22.5272149],
                [104.4146444, 22.5280793],
                [104.4144748, 22.529223],
                [104.4145979, 22.5303366],
                [104.4147523, 22.5314859],
                [104.4147819, 22.5328516],
                [104.4150316, 22.5344694],
                [104.4153598, 22.5362681],
                [104.4158546, 22.5375619],
                [104.416438, 22.5384779],
                [104.4177877, 22.5401556],
                [104.419142, 22.5412096],
                [104.4197796, 22.5418292],
                [104.4209295, 22.5442],
                [104.4215493, 22.5449712],
                [104.4220211, 22.5459047],
                [104.422159, 22.5476752],
                [104.4223461, 22.5487342],
                [104.4227722, 22.5494103],
                [104.4234116, 22.5499457],
                [104.4236194, 22.550605],
                [104.4236219, 22.5513801],
                [104.4231902, 22.5525043],
                [104.4227082, 22.5533506],
                [104.4226496, 22.553942],
                [104.4225459, 22.5551154],
                [104.4220504, 22.5558772],
                [104.4214485, 22.5570634],
                [104.4213258, 22.5579441],
                [104.4216599, 22.5589336],
                [104.4217713, 22.5597802],
                [104.4224013, 22.5606382],
                [104.4229112, 22.5616574],
                [104.4239356, 22.5630741],
                [104.4246417, 22.563741],
                [104.4247411, 22.5647159],
                [104.4247491, 22.5658439],
                [104.4246834, 22.5667434],
                [104.4248573, 22.5675812],
                [104.4249097, 22.5679113],
                [104.4251775, 22.5687057],
                [104.425103, 22.5701345],
                [104.4250948, 22.5719314],
                [104.4248571, 22.5727159],
                [104.4253386, 22.5731374],
                [104.4261185, 22.5733804],
                [104.4269231, 22.5735358],
                [104.4280776, 22.5739733],
                [104.4286449, 22.5742232],
                [104.4300154, 22.5745531],
                [104.431329, 22.5749122],
                [104.4326862, 22.5751049],
                [104.4341587, 22.5750943],
                [104.4373204, 22.5751729],
                [104.4397865, 22.5754729],
                [104.441288, 22.5756657],
                [104.4424471, 22.5757216],
                [104.4435065, 22.5756466],
                [104.4440873, 22.5755063],
                [104.445822, 22.575296],
                [104.4478836, 22.5748731],
                [104.4499359, 22.5748157],
                [104.4505241, 22.5745972],
                [104.4518631, 22.5734274],
                [104.4524421, 22.573001],
                [104.4532639, 22.5725703],
                [104.454137, 22.5725703],
                [104.4553278, 22.5727619],
                [104.4567093, 22.5728944],
                [104.4583993, 22.5726051],
                [104.459677, 22.5722691],
                [104.4617719, 22.573228],
                [104.4626946, 22.5735837],
                [104.4640983, 22.573819],
                [104.4652252, 22.5738385],
                [104.4658647, 22.5739621],
                [104.4665588, 22.5743026],
                [104.4666856, 22.5750164],
                [104.4664152, 22.5758107],
                [104.465773, 22.5765512],
                [104.4652163, 22.577811],
                [104.4652619, 22.5791476],
                [104.4643206, 22.5837495],
                [104.463888, 22.585237],
                [104.4634925, 22.5869563],
                [104.4632941, 22.5887026],
                [104.4629719, 22.5898644],
                [104.4630026, 22.5915369],
                [104.4627724, 22.5929546],
                [104.4627201, 22.5945309],
                [104.46294, 22.5955764],
                [104.4634822, 22.5966466],
                [104.4639883, 22.5970917],
                [104.4642064, 22.5978315],
                [104.4638787, 22.5987519],
                [104.4632913, 22.5994548],
                [104.4622917, 22.6000458],
                [104.4605625, 22.6018213],
                [104.4594313, 22.6031779],
                [104.4592839, 22.6039826],
                [104.4595223, 22.6053426],
                [104.46001, 22.6062913],
                [104.4606976, 22.6070393],
                [104.4608007, 22.6076041],
                [104.4605182, 22.6084329],
                [104.460328, 22.6098759],
                [104.4604803, 22.6107473],
                [104.4612624, 22.6116643],
                [104.4619147, 22.6123448],
                [104.462749, 22.6132898],
                [104.4614196, 22.6134282],
                [104.4600772, 22.6137399],
                [104.4584739, 22.6140904],
                [104.456164, 22.6146837],
                [104.4515173, 22.6155796],
                [104.4494185, 22.6158251],
                [104.4482992, 22.6159676],
                [104.4460951, 22.6167453],
                [104.444682, 22.6172328],
                [104.4432308, 22.6179346],
                [104.4416472, 22.6186807],
                [104.4399841, 22.619345],
                [104.4390193, 22.6194744],
                [104.437832, 22.6196293],
                [104.4365351, 22.620406],
                [104.4353814, 22.6211324],
                [104.4329311, 22.6228205],
                [104.4316927, 22.6240223],
                [104.4297519, 22.6249012],
                [104.4276222, 22.6254511],
                [104.4265484, 22.6259944],
                [104.425309, 22.6271363],
                [104.4238214, 22.6285128],
                [104.4230685, 22.6290555],
                [104.4206166, 22.6307183],
                [104.4185577, 22.6322349],
                [104.4167733, 22.633663],
                [104.4155458, 22.6352372],
                [104.4143689, 22.6365549],
                [104.4138362, 22.6373307],
                [104.4133971, 22.6391857],
                [104.4132812, 22.6410469],
                [104.4123289, 22.6431641],
                [104.4110112, 22.6464714],
                [104.4107219, 22.6478218],
                [104.4104356, 22.6493381],
                [104.408952, 22.6515548],
                [104.4070337, 22.6540174],
                [104.4072494, 22.6549247],
                [104.4075266, 22.6556489],
                [104.4084097, 22.6563439],
                [104.4096041, 22.6570894],
                [104.4105299, 22.6579153],
                [104.4109475, 22.658566],
                [104.4103467, 22.6602665],
                [104.4094686, 22.6623161],
                [104.4083889, 22.6644002],
                [104.4074325, 22.6662616],
                [104.4070148, 22.6669373],
                [104.4062292, 22.6691994],
                [104.4056057, 22.6708655],
                [104.4054599, 22.6718814],
                [104.4050062, 22.6730088],
                [104.4035841, 22.6754442],
                [104.4021391, 22.6770012],
                [104.4007832, 22.6785076],
                [104.3997217, 22.6793206],
                [104.3983766, 22.6805435],
                [104.3974494, 22.6806024],
                [104.3962522, 22.680565],
                [104.3941093, 22.6804162],
                [104.3936362, 22.6802725],
                [104.3931707, 22.6801589],
                [104.3926509, 22.6798914],
                [104.3922654, 22.6795473],
                [104.3919222, 22.6792758],
                [104.391542, 22.6790971],
                [104.39101, 22.6790094],
                [104.3904336, 22.6789968],
                [104.3897614, 22.6790951],
                [104.3884192, 22.6794008],
                [104.3873504, 22.6797169],
                [104.3869739, 22.6798204],
                [104.3866063, 22.6798707],
                [104.3860311, 22.6798286],
                [104.3855481, 22.6797557],
                [104.3851355, 22.6795995],
                [104.3847558, 22.6793187],
                [104.3841559, 22.6787407],
                [104.3831569, 22.6777615],
                [104.382634, 22.6773809],
                [104.3820393, 22.6771231],
                [104.3810677, 22.6767965],
                [104.3799768, 22.6765293],
                [104.3788031, 22.6763199],
                [104.3771462, 22.6759607],
                [104.3761857, 22.6757916],
                [104.3750606, 22.6757283],
                [104.3741081, 22.6757159],
                [104.3736049, 22.6756457],
                [104.3729898, 22.675446],
                [104.3719942, 22.6749044],
                [104.3712473, 22.6745417],
                [104.370644, 22.6743494],
                [104.3698205, 22.6742537],
                [104.3689378, 22.6740803],
                [104.3683066, 22.673882],
                [104.3673901, 22.6734366],
                [104.36635, 22.6730538],
                [104.3656702, 22.6728827],
                [104.364795, 22.6728332],
                [104.3640509, 22.6729103],
                [104.3634573, 22.6730002],
                [104.3624611, 22.6731481],
                [104.3615929, 22.6732053],
                [104.3600314, 22.6730515],
                [104.3590069, 22.6736186],
                [104.3577858, 22.6742236],
                [104.3565482, 22.6747454],
                [104.3550484, 22.6752672],
                [104.3544583, 22.6755772],
                [104.3537453, 22.6760461],
                [104.3523357, 22.676583],
                [104.3514014, 22.6775887],
                [104.3504507, 22.6786701],
                [104.3494754, 22.6795019],
                [104.3476478, 22.6801447],
                [104.3459897, 22.6814556],
                [104.34508, 22.6818261],
                [104.3444899, 22.6824915],
                [104.3431868, 22.6838299],
                [104.3425721, 22.6846617],
                [104.3421459, 22.6850398],
                [104.3420722, 22.6854633],
                [104.3413915, 22.6862425],
                [104.3400928, 22.6870951],
                [104.338593, 22.6877076],
                [104.3376259, 22.6878286],
                [104.3366916, 22.688373],
                [104.3354602, 22.6894647],
                [104.334475, 22.6902453],
                [104.3343417, 22.690919],
                [104.3347648, 22.6914323],
                [104.3355819, 22.6921327],
                [104.3360803, 22.6928919],
                [104.3362642, 22.6937545],
                [104.3365381, 22.6944264],
                [104.3365555, 22.6948594],
                [104.3360919, 22.6955064],
                [104.3356514, 22.696442],
                [104.3357326, 22.6968483],
                [104.3361614, 22.6971851],
                [104.3364454, 22.6976075],
                [104.3366192, 22.6981582],
                [104.3370975, 22.6987545],
                [104.3373552, 22.6991366],
                [104.3382245, 22.6997567],
                [104.3388967, 22.7002112],
                [104.3392097, 22.7007672],
                [104.3396154, 22.7015851],
                [104.3398414, 22.7023015],
                [104.3401601, 22.7029752],
                [104.3405542, 22.7032692],
                [104.3412322, 22.703467],
                [104.3413708, 22.7040806],
                [104.3407728, 22.7049193],
                [104.3406175, 22.7057644],
                [104.3410027, 22.7067472],
                [104.3411502, 22.7077225],
                [104.340765, 22.7085844],
                [104.3411193, 22.7094064],
                [104.3414855, 22.7103607],
                [104.3419135, 22.7117775],
                [104.3420439, 22.7138639],
                [104.3418645, 22.7144522],
                [104.3415459, 22.7169326],
                [104.340888, 22.7174475],
                [104.340378, 22.7190659],
                [104.339658, 22.7211152],
                [104.3406196, 22.72204],
                [104.3406488, 22.7222684],
                [104.3392529, 22.7228151],
                [104.3373375, 22.7229808],
                [104.3359915, 22.7230184],
                [104.3342086, 22.7239068],
                [104.3331389, 22.7250424],
                [104.3319235, 22.7258199],
                [104.3306284, 22.7258015],
                [104.3292486, 22.7262384],
                [104.3278879, 22.7273811],
                [104.3259245, 22.7289493],
                [104.3254267, 22.7298126],
                [104.3248872, 22.73007],
                [104.3229058, 22.7300327],
                [104.3217557, 22.7308813],
                [104.3214929, 22.7315152],
                [104.3209864, 22.7319478],
                [104.3197632, 22.7320552],
                [104.3184837, 22.7317602],
                [104.31721, 22.7316465],
                [104.3159828, 22.7320547],
                [104.3126233, 22.731743],
                [104.3109429, 22.7307282],
                [104.3092215, 22.7301305],
                [104.3080745, 22.7297905],
                [104.3058262, 22.7300333],
                [104.3022909, 22.7301079],
                [104.3007868, 22.7307453],
                [104.2994318, 22.7312675],
                [104.2968363, 22.7328998],
                [104.2956351, 22.7332099],
                [104.2940901, 22.7335877],
                [104.2923579, 22.7355945],
                [104.2906011, 22.7363544],
                [104.2893027, 22.7376994],
                [104.2877196, 22.738066],
                [104.2842151, 22.7394175],
                [104.2807362, 22.7411536],
                [104.279514, 22.74122],
                [104.278864, 22.741487],
                [104.278286, 22.74162],
                [104.277709, 22.741553],
                [104.276914, 22.74122],
                [104.275831, 22.740953],
                [104.2748207, 22.7408211],
                [104.2736436, 22.7410881],
                [104.2716783, 22.7413414],
                [104.2705675, 22.7416151],
                [104.269407, 22.7414535],
                [104.2695506, 22.7422963],
                [104.269187, 22.74282],
                [104.2695435, 22.7448954],
                [104.2698172, 22.7461493],
                [104.2709938, 22.7499696],
                [104.27027, 22.752556],
                [104.270053, 22.753757],
                [104.269259, 22.754957],
                [104.268104, 22.75589],
                [104.267237, 22.756824],
                [104.266731, 22.757824],
                [104.266082, 22.758291],
                [104.265648, 22.758824],
                [104.265071, 22.759691],
                [104.264782, 22.760225],
                [104.264276, 22.760958],
                [104.263048, 22.761625],
                [104.261821, 22.762092],
                [104.261171, 22.762425],
                [104.260521, 22.763759],
                [104.259654, 22.765359],
                [104.25886, 22.76616],
                [104.258354, 22.766893],
                [104.2583245, 22.7682782],
                [104.2592034, 22.7701124],
                [104.2594204, 22.771357],
                [104.2589592, 22.7726416],
                [104.2575447, 22.7737694],
                [104.2574171, 22.7752298],
                [104.256693, 22.775495],
                [104.256549, 22.775962],
                [104.255971, 22.776496],
                [104.255321, 22.776962],
                [104.255105, 22.777496],
                [104.255105, 22.777896],
                [104.255321, 22.778429],
                [104.25626, 22.779096],
                [104.256766, 22.780163],
                [104.25691, 22.780963],
                [104.256838, 22.78163],
                [104.256838, 22.782364],
                [104.257271, 22.783164],
                [104.257777, 22.783831],
                [104.258138, 22.785164],
                [104.258354, 22.785831],
                [104.258354, 22.787165],
                [104.258427, 22.788299],
                [104.258788, 22.789165],
                [104.259365, 22.789966],
                [104.259799, 22.790766],
                [104.260015, 22.791833],
                [104.260521, 22.792766],
                [104.260954, 22.793433],
                [104.261315, 22.794233],
                [104.261387, 22.795034],
                [104.261387, 22.795767],
                [104.261676, 22.796367],
                [104.261749, 22.797501],
                [104.26211, 22.798168],
                [104.262471, 22.799035],
                [104.262832, 22.799835],
                [104.263265, 22.800835],
                [104.26341, 22.802035],
                [104.264059, 22.802969],
                [104.264276, 22.803969],
                [104.264421, 22.804703],
                [104.264854, 22.805303],
                [104.264926, 22.80577],
                [104.264709, 22.80657],
                [104.265071, 22.80717],
                [104.265648, 22.80757],
                [104.266082, 22.807837],
                [104.266298, 22.80817],
                [104.266226, 22.80877],
                [104.26637, 22.809104],
                [104.266804, 22.809437],
                [104.266804, 22.809771],
                [104.266731, 22.810571],
                [104.266659, 22.811304],
                [104.266731, 22.811971],
                [104.267237, 22.812505],
                [104.267815, 22.813505],
                [104.268104, 22.814038],
                [104.26832, 22.814638],
                [104.268176, 22.815439],
                [104.267598, 22.816439],
                [104.267237, 22.817039],
                [104.266731, 22.817973],
                [104.266876, 22.818506],
                [104.266804, 22.819173],
                [104.266731, 22.819773],
                [104.266515, 22.820507],
                [104.266659, 22.820973],
                [104.267309, 22.82164],
                [104.267743, 22.822307],
                [104.268104, 22.822907],
                [104.26897, 22.823841],
                [104.269259, 22.824508],
                [104.269692, 22.825041],
                [104.269837, 22.825308],
                [104.270415, 22.825641],
                [104.27092, 22.825908],
                [104.270992, 22.826508],
                [104.27092, 22.827042],
                [104.270776, 22.827708],
                [104.270703, 22.828575],
                [104.271065, 22.829242],
                [104.2711685, 22.8291819],
                [104.2701673, 22.8315866],
                [104.2679572, 22.8334654],
                [104.2682361, 22.837025],
                [104.2679572, 22.8384291],
                [104.2672276, 22.8392794],
                [104.2648244, 22.8397936],
                [104.2629361, 22.8404857],
                [104.2599213, 22.8429675],
                [104.2588377, 22.8449153],
                [104.2571425, 22.8445396],
                [104.2558121, 22.8447769],
                [104.253602, 22.8429576],
                [104.2525506, 22.8412965],
                [104.2516268, 22.8395507],
                [104.2474111, 22.8422069],
                [104.2458689, 22.8433519],
                [104.2436456, 22.8440057],
                [104.2418861, 22.844243],
                [104.2404699, 22.8430169],
                [104.2405772, 22.8409208],
                [104.2381739, 22.8392992],
                [104.2353415, 22.8381523],
                [104.2325576, 22.8375615],
                [104.2298269, 22.8347706],
                [104.2248872, 22.8320981],
                [104.2216002, 22.8297021],
                [104.2208147, 22.829075],
                [104.2198705, 22.8285213],
                [104.2188332, 22.8301821],
                [104.2173912, 22.8307671],
                [104.2163532, 22.8309801],
                [104.2150272, 22.8309271],
                [104.2134132, 22.8304481],
                [104.2116252, 22.8300221],
                [104.2068402, 22.8284252],
                [104.2043612, 22.8274662],
                [104.1985952, 22.8267212],
                [104.1974422, 22.8264552],
                [104.1953916, 22.8264663],
                [104.1940656, 22.8257743],
                [104.1922206, 22.8249223],
                [104.1908366, 22.8246034],
                [104.1902026, 22.8245504],
                [104.1895106, 22.8245504],
                [104.1874346, 22.8260403],
                [104.1862816, 22.8267863],
                [104.1853016, 22.8268393],
                [104.1841476, 22.8265733],
                [104.1805736, 22.8254553],
                [104.1784396, 22.8244434],
                [104.1743466, 22.8226864],
                [104.1730776, 22.8222604],
                [104.1723286, 22.8221015],
                [104.1714636, 22.8220475],
                [104.1696186, 22.8223674],
                [104.1685226, 22.8223674],
                [104.1671966, 22.8222604],
                [104.1654096, 22.8217815],
                [104.1630456, 22.8209295],
                [104.1605086, 22.8195456],
                [104.1590666, 22.8184276],
                [104.1580296, 22.8174696],
                [104.1554346, 22.8152337],
                [104.1540506, 22.8139558],
                [104.1528396, 22.8131038],
                [104.1516296, 22.8123588],
                [104.1485156, 22.8107619],
                [104.1447106, 22.809005],
                [104.1425766, 22.807834],
                [104.1392906, 22.806769],
                [104.1376766, 22.806716],
                [104.1356681, 22.806124],
                [104.1333996, 22.8059444],
                [104.1329661, 22.8076484],
                [104.1321851, 22.8084349],
                [104.1313131, 22.8094567],
                [104.1309485, 22.8108563],
                [104.1311671, 22.8114712],
                [104.1305037, 22.8120366],
                [104.1300159, 22.8123074],
                [104.1289126, 22.8130508],
                [104.1279689, 22.813343],
                [104.127385, 22.813822],
                [104.1263716, 22.8138441],
                [104.1255424, 22.8137654],
                [104.1234073, 22.8132296],
                [104.1223104, 22.8131808],
                [104.1213621, 22.8131488],
                [104.1193319, 22.8119153],
                [104.117644, 22.8116218],
                [104.1150731, 22.8113861],
                [104.1141516, 22.8101759],
                [104.1126526, 22.808685],
                [104.1117306, 22.807621],
                [104.1110944, 22.8059143],
                [104.1100804, 22.8041616],
                [104.1083797, 22.8026118],
                [104.1064789, 22.8027755],
                [104.1052034, 22.8020609],
                [104.1040829, 22.8010156],
                [104.1026307, 22.7993794],
                [104.1016252, 22.7977402],
                [104.1009486, 22.7964535],
                [104.1006611, 22.7954149],
                [104.1000548, 22.7940526],
                [104.0984733, 22.7926176],
                [104.0971133, 22.7915478],
                [104.0950235, 22.7898685],
                [104.0945411, 22.7885042],
                [104.0940162, 22.787184],
                [104.093345, 22.7850081],
                [104.0921898, 22.7820406],
                [104.0916649, 22.7796535],
                [104.0910086, 22.7773774],
                [104.0904353, 22.7766578],
                [104.0904241, 22.7747125],
                [104.0896826, 22.7733363],
                [104.0892542, 22.7715883],
                [104.0885137, 22.7701228],
                [104.0868791, 22.7702131],
                [104.0848671, 22.7700001],
                [104.0836216, 22.769633],
                [104.0832488, 22.7673509],
                [104.0820713, 22.7664282],
                [104.0803172, 22.7665627],
                [104.0788475, 22.7664312],
                [104.0782194, 22.7660102],
                [104.0780112, 22.76538],
                [104.0780313, 22.7647941],
                [104.0786673, 22.763945],
                [104.0784997, 22.7632697],
                [104.0782522, 22.7626974],
                [104.0770206, 22.7624227],
                [104.0759246, 22.7619968],
                [104.0749446, 22.7617298],
                [104.0740796, 22.7613048],
                [104.0730996, 22.7609318],
                [104.0707356, 22.7602398],
                [104.0698706, 22.7598668],
                [104.0690636, 22.7592279],
                [104.0679106, 22.7586959],
                [104.0668156, 22.7580039],
                [104.0658926, 22.7573119],
                [104.0649126, 22.756726],
                [104.0631652, 22.7534571],
                [104.0625107, 22.7530515],
                [104.060305, 22.748582],
                [104.058979, 22.74773],
                [104.057307, 22.745494],
                [104.05598, 22.74427],
                [104.055231, 22.742353],
                [104.053386, 22.741129],
                [104.051771, 22.739904],
                [104.051599, 22.739372],
                [104.051714, 22.738201],
                [104.05108, 22.737668],
                [104.050042, 22.737349],
                [104.049811, 22.73655],
                [104.047562, 22.734793],
                [104.046698, 22.734527],
                [104.046006, 22.733941],
                [104.045429, 22.73293],
                [104.045256, 22.732025],
                [104.044737, 22.731599],
                [104.044622, 22.729523],
                [104.044103, 22.729097],
                [104.043123, 22.728777],
                [104.042719, 22.727872],
                [104.0422, 22.727127],
                [104.041508, 22.726541],
                [104.041105, 22.726275],
                [104.040586, 22.725423],
                [104.040413, 22.724625],
                [104.040067, 22.724252],
                [104.039375, 22.723134],
                [104.038856, 22.722602],
                [104.038568, 22.722176],
                [104.038972, 22.719833],
                [104.03926, 22.71765],
                [104.039029, 22.716639],
                [104.038164, 22.71387],
                [104.037299, 22.712486],
                [104.037126, 22.711581],
                [104.037184, 22.710995],
                [104.036608, 22.709558],
                [104.036204, 22.708706],
                [104.035281, 22.707056],
                [104.034936, 22.706151],
                [104.034647, 22.705565],
                [104.03459, 22.704979],
                [104.034878, 22.702637],
                [104.034359, 22.701732],
                [104.034359, 22.700401],
                [104.034071, 22.698804],
                [104.033782, 22.697845],
                [104.0300774, 22.6959714],
                [104.0282964, 22.6937147],
                [104.0288758, 22.6924279],
                [104.0281677, 22.6914777],
                [104.0284896, 22.6890228],
                [104.0291977, 22.6873005],
                [104.0302062, 22.6865482],
                [104.0311718, 22.6863502],
                [104.0322447, 22.6851029],
                [104.0340686, 22.6837765],
                [104.035356, 22.6825688],
                [104.0372872, 22.6834399],
                [104.038918, 22.6828856],
                [104.0397763, 22.6819749],
                [104.039905, 22.6800149],
                [104.0393257, 22.677837],
                [104.0379953, 22.6754611],
                [104.0376735, 22.6740554],
                [104.0361285, 22.6725507],
                [104.0358495, 22.6716201],
                [104.0347552, 22.6699767],
                [104.0344763, 22.6686303],
                [104.0345192, 22.6666305],
                [104.033339, 22.6650068],
                [104.0328455, 22.6626902],
                [104.033575, 22.6608685],
                [104.0325665, 22.659324],
                [104.0315151, 22.6566903],
                [104.0325665, 22.6551656],
                [104.0326738, 22.6540765],
                [104.031361, 22.652645],
                [104.030727, 22.651847],
                [104.030265, 22.651048],
                [104.030323, 22.650196],
                [104.031072, 22.644446],
                [104.031476, 22.642104],
                [104.0298629, 22.6405906],
                [104.0286612, 22.6390854],
                [104.0276313, 22.6376001],
                [104.02776, 22.6340154],
                [104.0297985, 22.6321934],
                [104.0296912, 22.6292622],
                [104.0291762, 22.6278163],
                [104.029541, 22.6259347],
                [104.0294123, 22.6255386],
                [104.025228, 22.6229143],
                [104.0246701, 22.6206167],
                [104.0248096, 22.6195273],
                [104.0248901, 22.618423],
                [104.0252709, 22.6179179],
                [104.0251998, 22.6172109],
                [104.0250456, 22.6166453],
                [104.0260112, 22.6164225],
                [104.0268138, 22.6157739],
                [104.0267558, 22.6153479],
                [104.0262473, 22.6153231],
                [104.0254801, 22.6152192],
                [104.0252173, 22.6148923],
                [104.0252656, 22.6141991],
                [104.0252066, 22.6128224],
                [104.0247452, 22.6117676],
                [104.0243, 22.6110545],
                [104.0239513, 22.6105742],
                [104.0241122, 22.6092618],
                [104.0236455, 22.6088558],
                [104.0230608, 22.6084447],
                [104.0230501, 22.6076029],
                [104.023034, 22.6073156],
                [104.0227389, 22.6069838],
                [104.0218538, 22.6070235],
                [104.0211296, 22.6067857],
                [104.0204644, 22.6044037],
                [104.0208292, 22.6031161],
                [104.0206361, 22.6018681],
                [104.0207326, 22.6008083],
                [104.0205717, 22.5995107],
                [104.0199923, 22.5983716],
                [104.0199172, 22.5965491],
                [104.019826, 22.5960043],
                [104.019708, 22.5955041],
                [104.019531, 22.5952862],
                [104.0193379, 22.5951871],
                [104.0174389, 22.595093],
                [104.016881, 22.5949642],
                [104.0157008, 22.5944789],
                [104.0155935, 22.593835],
                [104.0154701, 22.5932902],
                [104.0151161, 22.5930277],
                [104.0149605, 22.5929683],
                [104.0145957, 22.5930079],
                [104.0144294, 22.5928742],
                [104.0142846, 22.5916459],
                [104.0143168, 22.5897936],
                [104.0140808, 22.5886841],
                [104.0133887, 22.5883572],
                [104.0130508, 22.5879759],
                [104.0135497, 22.5869655],
                [104.014179, 22.586735],
                [104.0150785, 22.5864157],
                [104.0154648, 22.5861234],
                [104.015395, 22.585955],
                [104.0150785, 22.5858708],
                [104.0143865, 22.5857866],
                [104.0144348, 22.5851626],
                [104.0142256, 22.5846871],
                [104.0138608, 22.5841224],
                [104.0138984, 22.583533],
                [104.0132546, 22.5829238],
                [104.0136838, 22.5825969],
                [104.0136462, 22.5823294],
                [104.0136409, 22.581948],
                [104.013775, 22.5812843],
                [104.0138179, 22.5810911],
                [104.01366, 22.580826],
                [104.01366, 22.580666],
                [104.013775, 22.580453],
                [104.013602, 22.579814],
                [104.013775, 22.579548],
                [104.0140647, 22.5792188],
                [104.0140808, 22.5785699],
                [104.0129864, 22.5782183],
                [104.0121335, 22.5784659],
                [104.0113074, 22.5790702],
                [104.0106851, 22.5793724],
                [104.0099126, 22.5792931],
                [104.0096122, 22.5790455],
                [104.009281, 22.5786886],
                [104.0089635, 22.5782363],
                [104.008645, 22.5776812],
                [104.008067, 22.577259],
                [104.006914, 22.577312],
                [104.0060019, 22.5770097],
                [104.005419, 22.5765365],
                [104.0052134, 22.5756574],
                [104.0051973, 22.5752562],
                [104.0060931, 22.5753057],
                [104.0073055, 22.5748699],
                [104.0078419, 22.5742457],
                [104.0078419, 22.5730222],
                [104.0079868, 22.5727448],
                [104.008403, 22.5724926],
                [104.0087185, 22.5719361],
                [104.00899, 22.571935],
                [104.009336, 22.571988],
                [104.0100038, 22.572081],
                [104.0105125, 22.5719704],
                [104.0120005, 22.5710991],
                [104.012777, 22.5709391],
                [104.013191, 22.5698576],
                [104.013429, 22.569113],
                [104.013664, 22.5689416],
                [104.014024, 22.5685212],
                [104.015173, 22.5676402],
                [104.0149715, 22.5672406],
                [104.0139097, 22.5666965],
                [104.0140542, 22.5662085],
                [104.0148817, 22.5657364],
                [104.016372, 22.5654174],
                [104.0164588, 22.5645101],
                [104.0163663, 22.5637672],
                [104.01543, 22.5635967],
                [104.013486, 22.5636345],
                [104.012795, 22.563257],
                [104.0121772, 22.5627687],
                [104.0116175, 22.5625242],
                [104.0104892, 22.5633145],
                [104.0098051, 22.5622486],
                [104.0102372, 22.5612496],
                [104.0112765, 22.5605476],
                [104.0121948, 22.5606664],
                [104.0126498, 22.5599609],
                [104.011634, 22.5592215],
                [104.011795, 22.558443],
                [104.0115223, 22.5580373],
                [104.0103525, 22.5579967],
                [104.010385, 22.5571319],
                [104.011141, 22.5563034],
                [104.0136623, 22.5542969],
                [104.0141773, 22.5531673],
                [104.0140808, 22.5524836],
                [104.0146494, 22.5510172],
                [104.014703, 22.5500362],
                [104.0144884, 22.5495903],
                [104.0131152, 22.5491841],
                [104.0120101, 22.5484806],
                [104.0116453, 22.5477771],
                [104.0127826, 22.5472519],
                [104.0129757, 22.5458746],
                [104.013083, 22.544745],
                [104.0140271, 22.5449233],
                [104.0147889, 22.5449679],
                [104.0151751, 22.5448787],
                [104.015159, 22.5443635],
                [104.0150142, 22.5440166],
                [104.014467, 22.5436847],
                [104.0139574, 22.5434172],
                [104.013657, 22.5433329],
                [104.0122569, 22.5434122],
                [104.0117526, 22.5441455],
                [104.0113825, 22.5441851],
                [104.010604, 22.54425],
                [104.0097141, 22.5445914],
                [104.0089578, 22.544418],
                [104.0082979, 22.5438333],
                [104.0079224, 22.5431496],
                [104.007563, 22.542238],
                [104.007225, 22.5417227],
                [104.0058517, 22.5409399],
                [104.0055567, 22.5401075],
                [104.0050793, 22.5381999],
                [104.0056694, 22.5378333],
                [104.006337, 22.537223],
                [104.007894, 22.536264],
                [104.008586, 22.535679],
                [104.009739, 22.533762],
                [104.0102, 22.532804],
                [104.010489, 22.531846],
                [104.010258, 22.530089],
                [104.0102, 22.528917],
                [104.010373, 22.528225],
                [104.01095, 22.527746],
                [104.011123, 22.527001],
                [104.01095, 22.526362],
                [104.0119458, 22.5249632],
                [104.0122752, 22.5238465],
                [104.0119034, 22.5228508],
                [104.0096224, 22.5209573],
                [104.0087003, 22.520531],
                [104.0065923, 22.5205197],
                [104.0060685, 22.5200086],
                [104.0061034, 22.5180453],
                [104.0057188, 22.5171311],
                [104.0052798, 22.5168651],
                [104.0045362, 22.5170088],
                [104.0017079, 22.5183291],
                [104.000773, 22.5188636],
                [104.0000804, 22.519275],
                [103.9994171, 22.519591],
                [103.9987471, 22.519647],
                [103.9980281, 22.5193977],
                [103.9972463, 22.5190327],
                [103.996306, 22.518694],
                [103.995325, 22.518641],
                [103.99325, 22.519173],
                [103.991751, 22.519705],
                [103.990828, 22.519759],
                [103.990079, 22.519599],
                [103.989733, 22.519066],
                [103.989329, 22.516671],
                [103.98881, 22.515712],
                [103.988234, 22.515074],
                [103.987945, 22.514541],
                [103.986504, 22.513955],
                [103.985293, 22.513849],
                [103.984486, 22.513583],
                [103.982929, 22.512465],
                [103.98218, 22.511613],
                [103.981084, 22.509164],
                [103.9798734, 22.5077501],
                [103.9773427, 22.5068178],
                [103.9751067, 22.5064738],
                [103.9735256, 22.5065659],
                [103.9724303, 22.5068439],
                [103.9719085, 22.5070965],
                [103.9716747, 22.5074833],
                [103.971571, 22.508791],
                [103.9714905, 22.5103007],
                [103.9712077, 22.5111656],
                [103.9706144, 22.5115399],
                [103.9696136, 22.5116227],
                [103.968668, 22.5115539],
                [103.9674424, 22.5110436],
                [103.9666045, 22.5106505],
                [103.9660312, 22.5101536],
                [103.9653729, 22.5094931],
                [103.9645518, 22.5081003],
                [103.9642474, 22.5064916],
                [103.9640634, 22.5057527],
                [103.9632611, 22.5058973],
                [103.9619882, 22.5064152],
                [103.9604843, 22.5073368],
                [103.9587518, 22.5084418],
                [103.9566629, 22.5099323],
                [103.9551096, 22.5108494],
                [103.9535616, 22.5117355],
                [103.9525521, 22.5125647],
                [103.951863, 22.5132552],
                [103.9513375, 22.5138162],
                [103.9505303, 22.5159091],
                [103.9496325, 22.5184551],
                [103.9487568, 22.5204745],
                [103.9474355, 22.5229689],
                [103.9460532, 22.524574],
                [103.9456969, 22.5250464],
                [103.9449144, 22.5255426],
                [103.9440672, 22.5259054],
                [103.9428486, 22.5261555],
                [103.9377761, 22.526033],
                [103.9355823, 22.5263546],
                [103.9343716, 22.5266226],
                [103.9334715, 22.526943],
                [103.9327242, 22.5274526],
                [103.9322964, 22.5277443],
                [103.9313823, 22.5289872],
                [103.9310024, 22.5296737],
                [103.9307464, 22.5303831],
                [103.9303087, 22.5316569],
                [103.9299734, 22.5330628],
                [103.9299124, 22.5341739],
                [103.9296062, 22.5353086],
                [103.928609, 22.5374645],
                [103.9279246, 22.538295],
                [103.927231, 22.5389357],
                [103.9264217, 22.5394696],
                [103.9252804, 22.5396583],
                [103.9243407, 22.5394391],
                [103.9235163, 22.5386186],
                [103.921115, 22.5350909],
                [103.9196777, 22.5327744],
                [103.918519, 22.5323582],
                [103.9170277, 22.5331708],
                [103.9139936, 22.5351171],
                [103.9135179, 22.536443],
                [103.913588, 22.5372412],
                [103.914312, 22.5390533],
                [103.9152948, 22.5418315],
                [103.9152229, 22.5434325],
                [103.9146857, 22.5443169],
                [103.9137324, 22.5456913],
                [103.9085155, 22.5502274],
                [103.8982462, 22.5611957],
                [103.8919743, 22.5684121],
                [103.8890147, 22.5698528],
                [103.8865244, 22.5703729],
                [103.8844929, 22.5677453],
                [103.8846345, 22.565353],
                [103.8840823, 22.5642483],
                [103.8811944, 22.5630094],
                [103.8786924, 22.5644947],
                [103.8773107, 22.5652101],
                [103.8749017, 22.5674316],
                [103.8728914, 22.5710919],
                [103.8716891, 22.572868],
                [103.8710994, 22.5738998],
                [103.8701167, 22.5753338],
                [103.8683541, 22.57819],
                [103.8673353, 22.5799701],
                [103.8657102, 22.5821175],
                [103.8641822, 22.5834908],
                [103.8616332, 22.5865205],
                [103.858097, 22.5886068],
                [103.8554455, 22.5899995],
                [103.8534654, 22.5909135],
                [103.8523664, 22.5913973],
                [103.8516007, 22.5918756],
                [103.8501002, 22.5927108],
                [103.8458658, 22.5957552],
                [103.8444793, 22.5978759],
                [103.8433035, 22.5999333],
                [103.8419866, 22.6031495],
                [103.8406648, 22.6051009],
                [103.839381, 22.606804],
                [103.8373333, 22.6082264],
                [103.8361689, 22.6095118],
                [103.8346827, 22.6107717],
                [103.834169, 22.6113752],
                [103.8332257, 22.6124444],
                [103.8315165, 22.6130134],
                [103.8302983, 22.6128771],
                [103.8261285, 22.6154213],
                [103.8218742, 22.620695],
                [103.820072, 22.6243757],
                [103.8194747, 22.6262181],
                [103.8193661, 22.6269717],
                [103.8187314, 22.6287187],
                [103.817989, 22.6303143],
                [103.8171269, 22.6324706],
                [103.8160412, 22.6343867],
                [103.8146695, 22.6370977],
                [103.8126046, 22.6398135],
                [103.8119948, 22.6409269],
                [103.8089175, 22.6436109],
                [103.8077486, 22.6441924],
                [103.8067518, 22.6450865],
                [103.8041826, 22.6467914],
                [103.8032521, 22.6491253],
                [103.8031587, 22.6502459],
                [103.8032531, 22.6506104],
                [103.8021474, 22.6516684],
                [103.8011584, 22.6527315],
                [103.7995534, 22.6543574],
                [103.7974426, 22.6570552],
                [103.7944401, 22.6614071],
                [103.791949, 22.6628794],
                [103.7887003, 22.6644908],
                [103.787162, 22.6651375],
                [103.7861673, 22.6661805],
                [103.7814058, 22.670759],
                [103.7800422, 22.6749647],
                [103.7793626, 22.6767831],
                [103.7780901, 22.6784981],
                [103.776882, 22.6796603],
                [103.7742701, 22.6809197],
                [103.7704018, 22.6860386],
                [103.7696096, 22.6873891],
                [103.7686833, 22.689801],
                [103.7681015, 22.6903266],
                [103.7674997, 22.6904537],
                [103.766788, 22.6906183],
                [103.7614187, 22.6888912],
                [103.7592696, 22.6884418],
                [103.7579558, 22.6878192],
                [103.7553385, 22.6872419],
                [103.7527738, 22.6872945],
                [103.7502193, 22.6889004],
                [103.7495536, 22.6928623],
                [103.7478278, 22.6953534],
                [103.7453298, 22.6978515],
                [103.7448962, 22.6984111],
                [103.7437422, 22.6990712],
                [103.7404421, 22.699434],
                [103.7376026, 22.7024575],
                [103.7376333, 22.7076664],
                [103.7399058, 22.7087338],
                [103.7400487, 22.7105681],
                [103.739155, 22.7117528],
                [103.7320616, 22.7176955],
                [103.7272427, 22.723386],
                [103.7203395, 22.728675],
                [103.716201, 22.7328135],
                [103.7153677, 22.7328135],
                [103.7103417, 22.7378396],
                [103.7095532, 22.7383767],
                [103.7086039, 22.7396583],
                [103.707839, 22.7403425],
                [103.7037106, 22.7439908],
                [103.7028379, 22.7445074],
                [103.7021573, 22.7453977],
                [103.700712, 22.7461246],
                [103.6961061, 22.7459962],
                [103.6942448, 22.7463945],
                [103.6920212, 22.7478309],
                [103.690017, 22.7490075],
                [103.6851962, 22.752412],
                [103.6836382, 22.7545387],
                [103.6836382, 22.7562475],
                [103.6832002, 22.7584606],
                [103.682815, 22.7597557],
                [103.6823358, 22.7607903],
                [103.6819715, 22.762043],
                [103.678701, 22.7653136],
                [103.6703677, 22.7678136],
                [103.668701, 22.7686469],
                [103.6661382, 22.7712097],
                [103.6656499, 22.773372],
                [103.6653659, 22.7749088],
                [103.6656077, 22.7776863],
                [103.6648005, 22.7810719],
                [103.6644715, 22.782043],
                [103.6644715, 22.7828764],
                [103.6636382, 22.784543],
                [103.6628057, 22.786203],
                [103.661404, 22.788197],
                [103.660336, 22.78882],
                [103.659323, 22.789495],
                [103.658254, 22.789755],
                [103.656792, 22.790222],
                [103.654767, 22.791105],
                [103.65381, 22.791365],
                [103.65156, 22.791105],
                [103.65021, 22.791573],
                [103.64931, 22.792508],
                [103.6472629, 22.7939948],
                [103.6458625, 22.7948823],
                [103.645653, 22.79469],
                [103.645147, 22.794482],
                [103.644866, 22.794326],
                [103.644122, 22.7940674],
                [103.643539, 22.7936504],
                [103.643122, 22.793079],
                [103.641603, 22.793027],
                [103.640928, 22.793183],
                [103.640534, 22.793599],
                [103.640534, 22.793807],
                [103.640647, 22.794014],
                [103.640872, 22.794274],
                [103.641153, 22.79443],
                [103.641547, 22.794482],
                [103.642166, 22.794638],
                [103.642616, 22.794949],
                [103.642841, 22.795365],
                [103.642841, 22.795832],
                [103.642559, 22.796767],
                [103.642391, 22.797079],
                [103.642166, 22.797443],
                [103.641828, 22.797754],
                [103.641322, 22.797962],
                [103.640197, 22.797806],
                [103.639691, 22.797598],
                [103.638622, 22.796404],
                [103.638453, 22.79604],
                [103.638397, 22.795573],
                [103.638453, 22.795053],
                [103.638565, 22.794638],
                [103.638734, 22.794326],
                [103.639409, 22.793443],
                [103.639466, 22.792404],
                [103.639241, 22.792196],
                [103.638734, 22.791885],
                [103.638228, 22.791833],
                [103.637665, 22.792092],
                [103.637272, 22.792352],
                [103.636765, 22.792352],
                [103.636034, 22.792196],
                [103.635359, 22.791677],
                [103.635134, 22.791002],
                [103.635134, 22.790378],
                [103.635359, 22.789911],
                [103.635753, 22.789599],
                [103.636315, 22.789391],
                [103.637215, 22.7883],
                [103.638003, 22.78721],
                [103.638003, 22.787054],
                [103.637497, 22.786638],
                [103.635978, 22.78695],
                [103.634909, 22.787054],
                [103.634009, 22.787314],
                [103.633221, 22.787262],
                [103.632321, 22.787365],
                [103.631703, 22.787054],
                [103.631253, 22.787365],
                [103.630971, 22.787781],
                [103.630803, 22.788145],
                [103.630803, 22.788664],
                [103.631028, 22.789235],
                [103.631253, 22.789547],
                [103.631309, 22.790067],
                [103.631478, 22.79043],
                [103.631759, 22.790846],
                [103.631815, 22.791157],
                [103.631646, 22.791521],
                [103.631309, 22.791521],
                [103.630915, 22.791417],
                [103.629677, 22.790794],
                [103.629509, 22.790274],
                [103.629452, 22.789547],
                [103.629227, 22.789028],
                [103.628834, 22.788924],
                [103.628271, 22.789028],
                [103.627484, 22.788716],
                [103.62709, 22.788404],
                [103.626921, 22.788197],
                [103.625852, 22.787469],
                [103.625458, 22.787002],
                [103.625346, 22.786586],
                [103.625233, 22.78643],
                [103.624896, 22.786327],
                [103.624108, 22.785807],
                [103.623602, 22.785599],
                [103.622871, 22.785599],
                [103.622589, 22.785651],
                [103.622364, 22.785755],
                [103.622083, 22.785963],
                [103.621746, 22.786067],
                [103.621464, 22.786223],
                [103.620452, 22.786534],
                [103.620171, 22.786482],
                [103.619946, 22.786275],
                [103.619439, 22.786067],
                [103.619214, 22.785755],
                [103.618764, 22.785392],
                [103.617808, 22.783522],
                [103.617695, 22.783054],
                [103.617752, 22.782639],
                [103.61792, 22.782171],
                [103.618202, 22.781807],
                [103.618652, 22.780976],
                [103.619271, 22.779626],
                [103.619664, 22.778327],
                [103.620227, 22.777184],
                [103.620452, 22.776353],
                [103.620846, 22.775522],
                [103.621014, 22.774535],
                [103.620846, 22.774172],
                [103.620396, 22.773964],
                [103.619946, 22.77386],
                [103.619608, 22.77386],
                [103.619439, 22.773808],
                [103.619271, 22.773185],
                [103.618933, 22.772717],
                [103.618652, 22.772561],
                [103.617639, 22.772146],
                [103.617189, 22.772042],
                [103.61522, 22.771886],
                [103.615052, 22.771782],
                [103.614939, 22.771626],
                [103.614827, 22.770639],
                [103.614601, 22.77038],
                [103.614601, 22.769912],
                [103.61432, 22.769341],
                [103.613983, 22.768094],
                [103.613476, 22.767315],
                [103.61342, 22.7669],
                [103.613139, 22.766692],
                [103.611733, 22.766536],
                [103.611283, 22.765965],
                [103.610101, 22.764978],
                [103.609932, 22.764614],
                [103.609707, 22.764354],
                [103.608976, 22.763887],
                [103.608807, 22.763627],
                [103.608864, 22.763056],
                [103.608976, 22.762848],
                [103.609539, 22.761238],
                [103.609539, 22.760666],
                [103.609707, 22.760043],
                [103.609707, 22.759316],
                [103.609539, 22.758588],
                [103.60982, 22.758173],
                [103.61027, 22.757082],
                [103.610157, 22.756563],
                [103.609932, 22.756147],
                [103.609482, 22.755939],
                [103.608807, 22.756095],
                [103.607907, 22.756615],
                [103.607289, 22.75703],
                [103.606613, 22.757238],
                [103.606388, 22.757134],
                [103.606276, 22.756822],
                [103.606388, 22.756355],
                [103.606332, 22.754329],
                [103.605601, 22.752771],
                [103.604982, 22.751057],
                [103.604645, 22.750537],
                [103.6047006, 22.7499052],
                [103.6044626, 22.7496047],
                [103.6040783, 22.7493412],
                [103.6037135, 22.7491928],
                [103.60322, 22.7492621],
                [103.6015034, 22.7498953],
                [103.6006451, 22.750014],
                [103.5994112, 22.7499645],
                [103.5990357, 22.7498854],
                [103.59851, 22.7496479],
                [103.5975659, 22.7488663],
                [103.5972655, 22.7484013],
                [103.5971153, 22.7479659],
                [103.5971475, 22.7473228],
                [103.5972655, 22.746927],
                [103.5974479, 22.7463927],
                [103.597641, 22.7454792],
                [103.5974264, 22.743929],
                [103.5972226, 22.7430583],
                [103.5970294, 22.7427218],
                [103.5966647, 22.742425],
                [103.5963857, 22.7422073],
                [103.5959995, 22.7419896],
                [103.5956454, 22.7418709],
                [103.5953021, 22.7417621],
                [103.5950553, 22.7415839],
                [103.5948408, 22.7413267],
                [103.5943687, 22.7402284],
                [103.5940361, 22.7400206],
                [103.5938322, 22.7399612],
                [103.5932529, 22.7400008],
                [103.5923088, 22.7401838],
                [103.591842, 22.7402333],
                [103.5915041, 22.7402333],
                [103.5913163, 22.740169],
                [103.5911661, 22.7400453],
                [103.5911623, 22.7394907],
                [103.5920137, 22.7364979],
                [103.5914826, 22.7349642],
                [103.5904688, 22.7345239],
                [103.5890526, 22.7352363],
                [103.5872424, 22.7348876],
                [103.5864866, 22.733018],
                [103.5860258, 22.7328173],
                [103.584333, 22.7329572],
                [103.5835913, 22.7329052],
                [103.5832531, 22.7323432],
                [103.5827708, 22.7304816],
                [103.5806451, 22.7285987],
                [103.5801544, 22.7280945],
                [103.5803102, 22.7270466],
                [103.5820136, 22.725008],
                [103.5819196, 22.7244095],
                [103.5815743, 22.7238926],
                [103.5813734, 22.7236826],
                [103.5811078, 22.7234638],
                [103.580765, 22.7232168],
                [103.5804781, 22.723237],
                [103.58024, 22.723496],
                [103.5798411, 22.7245054],
                [103.5791337, 22.7249301],
                [103.5783398, 22.7248509],
                [103.5771806, 22.7244633],
                [103.5764388, 22.7231855],
                [103.5756889, 22.7227898],
                [103.5748902, 22.7226437],
                [103.5749471, 22.721744],
                [103.5754471, 22.7211282],
                [103.5756696, 22.7206539],
                [103.5753697, 22.7201563],
                [103.5749149, 22.7197512],
                [103.5745507, 22.7192441],
                [103.574521, 22.7183663],
                [103.5745186, 22.717968],
                [103.5745342, 22.7175499],
                [103.5744329, 22.7172826],
                [103.574247, 22.7170658],
                [103.5739446, 22.7168604],
                [103.5737648, 22.7168419],
                [103.5735564, 22.716831],
                [103.5733173, 22.7168337],
                [103.5730422, 22.7167335],
                [103.5725329, 22.7163742],
                [103.5722115, 22.7158943],
                [103.5718924, 22.7152292],
                [103.5715618, 22.7144114],
                [103.5712869, 22.713575],
                [103.5711446, 22.7129597],
                [103.5704311, 22.712277],
                [103.5699025, 22.7117206],
                [103.569617, 22.7112859],
                [103.5695436, 22.7110077],
                [103.5695297, 22.7106561],
                [103.5696572, 22.7103567],
                [103.5699038, 22.7099238],
                [103.5700782, 22.7096478],
                [103.5699769, 22.7093686],
                [103.5698345, 22.7090682],
                [103.5695225, 22.7088754],
                [103.5687665, 22.7087062],
                [103.5675292, 22.7087717],
                [103.5670586, 22.7087212],
                [103.5668106, 22.7083676],
                [103.5666984, 22.7079377],
                [103.5665098, 22.7073637],
                [103.5658526, 22.7069613],
                [103.5646902, 22.7065019],
                [103.5641867, 22.7060526],
                [103.5640111, 22.7058959],
                [103.5640355, 22.7054199],
                [103.5643678, 22.7048004],
                [103.5645145, 22.7045623],
                [103.5644226, 22.7041219],
                [103.5643199, 22.7032171],
                [103.5642624, 22.7026496],
                [103.5639728, 22.7024357],
                [103.5635302, 22.7022459],
                [103.5632261, 22.7021111],
                [103.5628709, 22.7018889],
                [103.5626117, 22.70155],
                [103.5624729, 22.7011674],
                [103.5625453, 22.7003796],
                [103.5627052, 22.70015],
                [103.5627947, 22.6998925],
                [103.5628044, 22.699681],
                [103.5627925, 22.6995007],
                [103.562636, 22.6992281],
                [103.5628507, 22.6982239],
                [103.5633444, 22.6974176],
                [103.564227, 22.6962928],
                [103.5644278, 22.695571],
                [103.5644398, 22.6947071],
                [103.5647271, 22.6944315],
                [103.5651228, 22.694165],
                [103.5655186, 22.6939975],
                [103.5656, 22.6936623],
                [103.5655098, 22.6933667],
                [103.5650229, 22.6918135],
                [103.5648802, 22.6914968],
                [103.5649971, 22.6909143],
                [103.5651497, 22.6902559],
                [103.5647822, 22.6890206],
                [103.5647569, 22.6880364],
                [103.5652226, 22.6867908],
                [103.5655801, 22.686178],
                [103.5659588, 22.6856134],
                [103.5668233, 22.6847817],
                [103.5673045, 22.6837133],
                [103.5678616, 22.6831335],
                [103.5683016, 22.6823863],
                [103.5684184, 22.681505],
                [103.5678295, 22.6807655],
                [103.5672622, 22.6801523],
                [103.5676252, 22.6794427],
                [103.5683071, 22.6787151],
                [103.5691027, 22.6777807],
                [103.571063, 22.6777722],
                [103.5722293, 22.6767759],
                [103.5732513, 22.676478],
                [103.5737166, 22.6757849],
                [103.5737928, 22.674788],
                [103.573816, 22.674283],
                [103.574097, 22.6737839],
                [103.574322, 22.673555],
                [103.5746872, 22.6729053],
                [103.5752849, 22.6722386],
                [103.5762749, 22.6715805],
                [103.5778577, 22.6712937],
                [103.5784086, 22.6708473],
                [103.5786633, 22.6686471],
                [103.5781224, 22.6679976],
                [103.578862, 22.6658947],
                [103.579104, 22.665556],
                [103.5796186, 22.6649074],
                [103.579526, 22.6641787],
                [103.578364, 22.6628668],
                [103.5775943, 22.662279],
                [103.5769312, 22.6608588],
                [103.5765941, 22.6594529],
                [103.577022, 22.658076],
                [103.5771544, 22.6573504],
                [103.5766883, 22.6569053],
                [103.5763105, 22.6569042],
                [103.5756337, 22.6571445],
                [103.5751052, 22.6569778],
                [103.5748008, 22.656656],
                [103.5750318, 22.6559237],
                [103.5743931, 22.6548163],
                [103.5749033, 22.6536227],
                [103.575027, 22.6529076],
                [103.5739482, 22.6516154],
                [103.573042, 22.6512549],
                [103.5732334, 22.6498248],
                [103.572248, 22.6489636],
                [103.57144, 22.6473007],
                [103.5714166, 22.646362],
                [103.5716976, 22.645739],
                [103.5716976, 22.6453922],
                [103.5716976, 22.645219],
                [103.5715296, 22.644752],
                [103.5713606, 22.643713],
                [103.5713606, 22.642882],
                [103.5712476, 22.642674],
                [103.5709666, 22.642466],
                [103.5706856, 22.64231],
                [103.5704606, 22.642258],
                [103.5703476, 22.642258],
                [103.5692786, 22.642726],
                [103.5687726, 22.642414],
                [103.5684356, 22.642414],
                [103.5680416, 22.64231],
                [103.5670856, 22.642518],
                [103.5666916, 22.642362],
                [103.5664096, 22.642103],
                [103.5659596, 22.641791],
                [103.5655096, 22.641739],
                [103.5650036, 22.641583],
                [103.5644976, 22.6410641],
                [103.5640476, 22.6404401],
                [103.5639346, 22.6403881],
                [103.5636536, 22.6400771],
                [103.5629786, 22.6397131],
                [103.5625846, 22.6396611],
                [103.5620226, 22.6395051],
                [103.5617406, 22.6395051],
                [103.5612906, 22.6395571],
                [103.5607286, 22.6398171],
                [103.5601656, 22.6399731],
                [103.5593226, 22.6398691],
                [103.5587596, 22.6395571],
                [103.5584786, 22.6393501],
                [103.5583656, 22.6390381],
                [103.5581406, 22.6387781],
                [103.5578036, 22.6387261],
                [103.5569036, 22.6387261],
                [103.5565656, 22.6386741],
                [103.5556656, 22.6381031],
                [103.5544846, 22.6377911],
                [103.5534716, 22.6377911],
                [103.5530216, 22.6376871],
                [103.5526846, 22.6374801],
                [103.5521776, 22.6372721],
                [103.5520096, 22.6370641],
                [103.5529424, 22.6345381],
                [103.5528166, 22.6341311],
                [103.5527194, 22.633431],
                [103.551828, 22.6323991],
                [103.5518062, 22.6305655],
                [103.5513391, 22.6293741],
                [103.5511599, 22.6275527],
                [103.5504095, 22.6260019],
                [103.5499102, 22.6255001],
                [103.5490646, 22.6251427],
                [103.5490621, 22.623987],
                [103.5479972, 22.6230763],
                [103.5474912, 22.6229203],
                [103.5470689, 22.6225168],
                [103.5472662, 22.6219333],
                [103.5470972, 22.6215183],
                [103.5463662, 22.6209983],
                [103.5462542, 22.6206873],
                [103.5457467, 22.6197027],
                [103.5450613, 22.6183257],
                [103.5453058, 22.6173883],
                [103.545745, 22.617092],
                [103.5464456, 22.6164946],
                [103.5465959, 22.6154068],
                [103.5463361, 22.6148863],
                [103.545723, 22.61453],
                [103.5454784, 22.6127012],
                [103.5444026, 22.6122889],
                [103.5431086, 22.6113388],
                [103.541965, 22.6100773],
                [103.5412247, 22.609024],
                [103.5403161, 22.6085175],
                [103.539968, 22.6070317],
                [103.5394712, 22.6065647],
                [103.5391673, 22.6054608],
                [103.5364111, 22.6030966],
                [103.535579, 22.6026811],
                [103.5348149, 22.601428],
                [103.5348634, 22.600563],
                [103.5341146, 22.5992187],
                [103.5331755, 22.5987485],
                [103.5322772, 22.5984368],
                [103.5315742, 22.5973498],
                [103.5319656, 22.5966241],
                [103.5317219, 22.595743],
                [103.5312073, 22.5952227],
                [103.5301655, 22.5950713],
                [103.5294336, 22.5952161],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.5003679, 10.951757],
                [105.50046, 10.9516231],
                [105.5005441, 10.9514843],
                [105.50062, 10.951341],
                [105.5006854, 10.9511984],
                [105.5007917, 10.9509038],
                [105.5009389, 10.9505488],
                [105.501017, 10.9504403],
                [105.5010664, 10.950392],
                [105.501255, 10.9502645],
                [105.5013871, 10.9501454],
                [105.5014822, 10.9500208],
                [105.5015539, 10.9498818],
                [105.5015802, 10.9498083],
                [105.5016119, 10.9496494],
                [105.5016607, 10.9493291],
                [105.5016913, 10.9489853],
                [105.5016983, 10.9488129],
                [105.5017519, 10.9483073],
                [105.5025995, 10.9482704],
                [105.5026156, 10.9475068],
                [105.5029911, 10.947433],
                [105.5029321, 10.9471091],
                [105.5033559, 10.9470538],
                [105.5029052, 10.9467957],
                [105.5030742, 10.9465245],
                [105.5038816, 10.9464034],
                [105.5035838, 10.9454606],
                [105.5045468, 10.9444125],
                [105.5048123, 10.9440517],
                [105.5050564, 10.9432011],
                [105.5053554, 10.942735],
                [105.5090183, 10.9365038],
                [105.5091091, 10.9363197],
                [105.5091521, 10.9362145],
                [105.5092809, 10.9357879],
                [105.5097261, 10.9359143],
                [105.5089322, 10.9343316],
                [105.5102357, 10.93292],
                [105.5107802, 10.9325316],
                [105.5104865, 10.9320365],
                [105.5110216, 10.9316506],
                [105.5108432, 10.9315137],
                [105.5110672, 10.9311713],
                [105.5107373, 10.930987],
                [105.5121803, 10.9297097],
                [105.5114883, 10.9291461],
                [105.5117404, 10.9289644],
                [105.5125666, 10.9285431],
                [105.5122688, 10.9279821],
                [105.5129045, 10.9276503],
                [105.5132103, 10.9281006],
                [105.5133417, 10.92799],
                [105.5134866, 10.9281454],
                [105.5137575, 10.9278478],
                [105.5137548, 10.9277161],
                [105.5140042, 10.9274475],
                [105.5143502, 10.9270024],
                [105.5146587, 10.9264283],
                [105.51488, 10.9265139],
                [105.5149256, 10.9263901],
                [105.5145474, 10.9262466],
                [105.5148062, 10.9254196],
                [105.5148518, 10.9251418],
                [105.5149873, 10.9251628],
                [105.5150181, 10.9249798],
                [105.5150825, 10.9249851],
                [105.51512, 10.9248824],
                [105.5148572, 10.9247981],
                [105.5145675, 10.924777],
                [105.5145648, 10.9246111],
                [105.5148277, 10.9246085],
                [105.515407, 10.9247375],
                [105.5155089, 10.9243161],
                [105.5156189, 10.9240212],
                [105.5153561, 10.9239395],
                [105.5153534, 10.9237236],
                [105.515281, 10.9235603],
                [105.5151656, 10.923555],
                [105.5151468, 10.92321],
                [105.5155706, 10.9231495],
                [105.5154365, 10.922707],
                [105.5175501, 10.9218801],
                [105.5178915, 10.9217569],
                [105.5180651, 10.9217036],
                [105.5184285, 10.9216112],
                [105.5187973, 10.921543],
                [105.5192184, 10.9216141],
                [105.5194598, 10.9210465],
                [105.5195618, 10.92109],
                [105.5201183, 10.9200181],
                [105.5208975, 10.9203947],
                [105.5210343, 10.9201129],
                [105.5211456, 10.9199364],
                [105.5216673, 10.9192122],
                [105.5221206, 10.919515],
                [105.5230004, 10.9184695],
                [105.5235931, 10.9188435],
                [105.5237862, 10.9185696],
                [105.5243683, 10.9189409],
                [105.5246365, 10.9185617],
                [105.5249047, 10.9187302],
                [105.5254251, 10.9179322],
                [105.5260393, 10.9181482],
                [105.5266052, 10.9170684],
                [105.52697, 10.9173528],
                [105.527375, 10.916298],
                [105.528515, 10.9168551],
                [105.5287805, 10.9162756],
                [105.5291131, 10.9164521],
                [105.5292754, 10.9160864],
                [105.5296603, 10.9163152],
                [105.5300063, 10.9156199],
                [105.5306688, 10.9160096],
                [105.5310309, 10.9153038],
                [105.5314869, 10.9155198],
                [105.5317551, 10.9149483],
                [105.5320421, 10.9151116],
                [105.5322728, 10.9153223],
                [105.5323827, 10.9152037],
                [105.5329594, 10.9142477],
                [105.5338472, 10.9147349],
                [105.5343005, 10.9139448],
                [105.5345097, 10.9138922],
                [105.5346216, 10.913873],
                [105.5348477, 10.9138527],
                [105.5349965, 10.913854],
                [105.5350645, 10.9138623],
                [105.5351977, 10.9138935],
                [105.5352218, 10.913825],
                [105.5355316, 10.9138882],
                [105.5356899, 10.9134076],
                [105.5360761, 10.913534],
                [105.5363283, 10.9130362],
                [105.536854, 10.9134194],
                [105.5371356, 10.9130086],
                [105.5374829, 10.913264],
                [105.5379322, 10.9123752],
                [105.5382729, 10.9125832],
                [105.5385706, 10.9119854],
                [105.5390641, 10.9123146],
                [105.5399061, 10.9116914],
                [105.5400499, 10.9115641],
                [105.5401155, 10.9114942],
                [105.5402409, 10.911332],
                [105.5402952, 10.9112453],
                [105.540306, 10.9109371],
                [105.5410328, 10.9111399],
                [105.5410972, 10.9104552],
                [105.5419381, 10.9106843],
                [105.5420454, 10.9098376],
                [105.543011, 10.910359],
                [105.5434938, 10.9095452],
                [105.543652, 10.9096501],
                [105.5442113, 10.9101181],
                [105.5463409, 10.908788],
                [105.5494174, 10.9072855],
                [105.5493678, 10.9072157],
                [105.5509181, 10.906631],
                [105.5509718, 10.9067139],
                [105.5530733, 10.9058224],
                [105.5530196, 10.9056749],
                [105.5529928, 10.9051995],
                [105.555156, 10.9051442],
                [105.5551869, 10.9042843],
                [105.5558574, 10.9041052],
                [105.5562034, 10.9040314],
                [105.5563027, 10.9038919],
                [105.5563666, 10.9038462],
                [105.5564421, 10.9038234],
                [105.556484, 10.9038215],
                [105.5565655, 10.9038392],
                [105.5566715, 10.9039775],
                [105.5618843, 10.9015004],
                [105.5668397, 10.8993591],
                [105.5670556, 10.8991826],
                [105.5670932, 10.8992406],
                [105.5681366, 10.8982818],
                [105.5686757, 10.8987454],
                [105.5688272, 10.8985465],
                [105.5691263, 10.8987691],
                [105.5694415, 10.8983174],
                [105.5695595, 10.8984214],
                [105.5696534, 10.8985242],
                [105.5698854, 10.8981765],
                [105.5699337, 10.8980685],
                [105.5699808, 10.8979159],
                [105.5700101, 10.8978499],
                [105.5706713, 10.8982081],
                [105.5711621, 10.8976366],
                [105.5713579, 10.897365],
                [105.5714464, 10.897223],
                [105.5718058, 10.8976655],
                [105.5782485, 10.8944733],
                [105.5790693, 10.8938543],
                [105.5794002, 10.8940703],
                [105.5794373, 10.8940004],
                [105.5794946, 10.8938531],
                [105.5795145, 10.8937766],
                [105.5795372, 10.8935936],
                [105.579536, 10.8935014],
                [105.5799463, 10.893533],
                [105.580004, 10.8935307],
                [105.5801153, 10.8935014],
                [105.5801678, 10.8934742],
                [105.5802573, 10.8933983],
                [105.5802923, 10.8933513],
                [105.5805364, 10.8933276],
                [105.5822115, 10.8923807],
                [105.5827439, 10.8920462],
                [105.5831194, 10.8912679],
                [105.5833957, 10.8907516],
                [105.583452, 10.8906726],
                [105.5836746, 10.8901801],
                [105.58414, 10.8893188],
                [105.5847314, 10.8881506],
                [105.5847542, 10.8880782],
                [105.5853362, 10.8869509],
                [105.5858526, 10.8859829],
                [105.5864319, 10.8847805],
                [105.5877382, 10.8821044],
                [105.5881311, 10.8812391],
                [105.5885911, 10.8803107],
                [105.5887169, 10.8800831],
                [105.5892456, 10.8791886],
                [105.5897793, 10.878185],
                [105.590996, 10.8756303],
                [105.5921075, 10.8734174],
                [105.5939153, 10.8697508],
                [105.595277, 10.8669082],
                [105.5956315, 10.8662129],
                [105.5958331, 10.8658391],
                [105.598231, 10.8610819],
                [105.6008863, 10.8556186],
                [105.6048748, 10.8475947],
                [105.6050196, 10.8472522],
                [105.6051993, 10.8467517],
                [105.6055105, 10.8460088],
                [105.605595, 10.8460115],
                [105.6065994, 10.8440568],
                [105.608308, 10.8404926],
                [105.6097189, 10.8375921],
                [105.6101319, 10.8367755],
                [105.6104873, 10.836136],
                [105.6105208, 10.8361327],
                [105.6105423, 10.8361142],
                [105.611009, 10.8352357],
                [105.6111764, 10.8349498],
                [105.6112756, 10.834757],
                [105.6113255, 10.8346482],
                [105.6123972, 10.8319758],
                [105.6024098, 10.8312083],
                [105.5959153, 10.8306572],
                [105.5992948, 10.8278879],
                [105.6042191, 10.8233175],
                [105.6062727, 10.8214354],
                [105.606749, 10.8209989],
                [105.6094483, 10.818514],
                [105.6140512, 10.8142109],
                [105.6191953, 10.809639],
                [105.6237582, 10.8051016],
                [105.6286893, 10.800514],
                [105.6325113, 10.7968697],
                [105.6335893, 10.7960382],
                [105.6359448, 10.7935524],
                [105.638774, 10.7911572],
                [105.6398382, 10.7901082],
                [105.6434329, 10.7867181],
                [105.6452858, 10.7851547],
                [105.6479622, 10.7825999],
                [105.6488474, 10.7817274],
                [105.6510811, 10.7796919],
                [105.6534201, 10.7775733],
                [105.6544316, 10.7766178],
                [105.6586279, 10.7726482],
                [105.6601739, 10.77129],
                [105.6634125, 10.7683336],
                [105.6683142, 10.7637179],
                [105.6695905, 10.7625306],
                [105.6740387, 10.7583905],
                [105.6781684, 10.7545475],
                [105.6801953, 10.7526404],
                [105.6814374, 10.7514717],
                [105.6823191, 10.750642],
                [105.6855381, 10.7477287],
                [105.6882035, 10.7453208],
                [105.690564, 10.7430572],
                [105.6930324, 10.7406395],
                [105.6968324, 10.7369652],
                [105.7003849, 10.7337839],
                [105.7028709, 10.7314779],
                [105.7053145, 10.7292343],
                [105.7078782, 10.7266563],
                [105.7106792, 10.7240485],
                [105.7128771, 10.7221877],
                [105.7169847, 10.7183262],
                [105.720381, 10.7150487],
                [105.728479, 10.7074913],
                [105.7327031, 10.7034073],
                [105.7377366, 10.6987467],
                [105.7453657, 10.6917129],
                [105.746641, 10.6907605],
                [105.747412, 10.6900077],
                [105.7476088, 10.6898155],
                [105.7480679, 10.6893672],
                [105.7529321, 10.6846172],
                [105.7681262, 10.670487],
                [105.770023, 10.6687377],
                [105.7757539, 10.6634523],
                [105.7806022, 10.658802],
                [105.7847215, 10.6580397],
                [105.7858202, 10.6578364],
                [105.7956688, 10.6559383],
                [105.8079689, 10.6536377],
                [105.8094252, 10.6517538],
                [105.814519, 10.6495338],
                [105.818856, 10.6473916],
                [105.8249775, 10.6441282],
                [105.8281838, 10.6420915],
                [105.8344746, 10.6384391],
                [105.8346407, 10.6382902],
                [105.8361195, 10.6364025],
                [105.836593, 10.6358409],
                [105.8371074, 10.6351668],
                [105.8388301, 10.63288],
                [105.8421203, 10.6284747],
                [105.8432715, 10.6267816],
                [105.8469372, 10.621967],
                [105.8519256, 10.6153467],
                [105.8537799, 10.6127291],
                [105.855126, 10.6108289],
                [105.8578121, 10.6070733],
                [105.8617146, 10.6015762],
                [105.8687033, 10.5922029],
                [105.8716669, 10.5882946],
                [105.8722482, 10.5875107],
                [105.8729406, 10.5865772],
                [105.8764921, 10.5818101],
                [105.8811457, 10.5759434],
                [105.8844604, 10.5716818],
                [105.8868852, 10.5686802],
                [105.8917104, 10.56242],
                [105.8946413, 10.5585996],
                [105.8970417, 10.5554935],
                [105.8990582, 10.5528368],
                [105.9017606, 10.5493293],
                [105.9027485, 10.5479167],
                [105.9037364, 10.546504],
                [105.9068062, 10.5422741],
                [105.9097943, 10.5386621],
                [105.9100537, 10.5386091],
                [105.9103903, 10.5385899],
                [105.9123253, 10.5384535],
                [105.9157379, 10.5382367],
                [105.9174455, 10.5381003],
                [105.9183505, 10.538028],
                [105.9237308, 10.537715],
                [105.9266863, 10.5375224],
                [105.9362467, 10.5368802],
                [105.9417001, 10.5364846],
                [105.9510581, 10.5358303],
                [105.9514458, 10.5358032],
                [105.9567135, 10.5354762],
                [105.9579894, 10.5353892],
                [105.9921365, 10.5330617],
                [105.9925407, 10.5330342],
                [105.9970553, 10.5327263],
                [106.0013622, 10.5323654],
                [106.0388535, 10.5286625],
                [106.0415679, 10.5283461],
                [106.0469315, 10.5277741],
                [106.0682881, 10.5256299],
                [106.0789472, 10.5245223],
                [106.0896895, 10.5233895],
                [106.0928571, 10.5230666],
                [106.0933082, 10.5230371],
                [106.0937597, 10.5230169],
                [106.0942116, 10.523006],
                [106.0946646, 10.5230044],
                [106.0951174, 10.5230123],
                [106.09557, 10.5230295],
                [106.0959095, 10.5230455],
                [106.0963815, 10.523035],
                [106.0974867, 10.5230246],
                [106.09864, 10.5229981],
                [106.0999494, 10.5229612],
                [106.1012586, 10.5229171],
                [106.1025676, 10.5228658],
                [106.1024645, 10.5243899],
                [106.1022449, 10.5324793],
                [106.1020734, 10.5422325],
                [106.1013853, 10.5757262],
                [106.1017689, 10.5757666],
                [106.1021145, 10.5758029],
                [106.1062914, 10.5760944],
                [106.1116534, 10.5763268],
                [106.1191334, 10.5766608],
                [106.1223329, 10.5768065],
                [106.1246445, 10.5768935],
                [106.1329525, 10.5772728],
                [106.1343417, 10.5773262],
                [106.1365149, 10.5774842],
                [106.1386422, 10.5776388],
                [106.1450654, 10.5778982],
                [106.1516538, 10.5781806],
                [106.1575332, 10.5784548],
                [106.1595824, 10.5785814],
                [106.1643352, 10.5788556],
                [106.1724302, 10.5792221],
                [106.1728754, 10.5792432],
                [106.1748187, 10.5793197],
                [106.1768706, 10.5794462],
                [106.1777919, 10.5794976],
                [106.1786314, 10.5795161],
                [106.1790472, 10.5795359],
                [106.1796619, 10.5795636],
                [106.1829945, 10.5797192],
                [106.1845207, 10.579793],
                [106.1857132, 10.5798447],
                [106.1896424, 10.580054],
                [106.194229, 10.5802874],
                [106.197406, 10.5804021],
                [106.1979416, 10.5803729],
                [106.1984239, 10.5803467],
                [106.2017794, 10.5797627],
                [106.2043427, 10.5793086],
                [106.204842, 10.5792825],
                [106.2083514, 10.5795939],
                [106.2153328, 10.5802085],
                [106.2186548, 10.5805792],
                [106.2234558, 10.5810401],
                [106.2286245, 10.5814654],
                [106.229967, 10.5815939],
                [106.2317364, 10.5817564],
                [106.2340635, 10.5820338],
                [106.2372719, 10.5822978],
                [106.238277, 10.5824128],
                [106.2421739, 10.5827918],
                [106.2482325, 10.5833942],
                [106.2520588, 10.5835861],
                [106.2568368, 10.5841595],
                [106.2588298, 10.5844443],
                [106.2618395, 10.5847452],
                [106.2659549, 10.5850754],
                [106.268934, 10.5853458],
                [106.2703559, 10.5855024],
                [106.2725887, 10.5857371],
                [106.2746661, 10.5859281],
                [106.2797292, 10.5863586],
                [106.2834761, 10.5867196],
                [106.2860755, 10.5870076],
                [106.2906579, 10.5829479],
                [106.2921215, 10.5816926],
                [106.294365, 10.5792791],
                [106.2956817, 10.5777345],
                [106.2962425, 10.5769946],
                [106.2979987, 10.575545],
                [106.3001937, 10.5736606],
                [106.3009416, 10.5730819],
                [106.3033159, 10.5712444],
                [106.3062917, 10.5689731],
                [106.3097697, 10.5663814],
                [106.3103558, 10.5655012],
                [106.3118386, 10.5662874],
                [106.3161755, 10.5692147],
                [106.317729, 10.570213],
                [106.3184933, 10.570792],
                [106.320972, 10.5694107],
                [106.3212487, 10.5691811],
                [106.3221557, 10.5680159],
                [106.3223112, 10.5675033],
                [106.3225872, 10.567124],
                [106.3229385, 10.5663499],
                [106.3230265, 10.5661856],
                [106.3231336, 10.5660313],
                [106.3232579, 10.5658899],
                [106.3238175, 10.5653582],
                [106.3241311, 10.5651454],
                [106.3243745, 10.5649263],
                [106.3247414, 10.5644896],
                [106.3253043, 10.5637071],
                [106.3257973, 10.5631004],
                [106.3259118, 10.5628393],
                [106.3262462, 10.5623599],
                [106.3266458, 10.562],
                [106.3269482, 10.5616432],
                [106.3271772, 10.5612847],
                [106.3279908, 10.5604585],
                [106.328785, 10.5598898],
                [106.3293859, 10.5595557],
                [106.3297925, 10.5591381],
                [106.3301567, 10.5587562],
                [106.3304116, 10.5582133],
                [106.3312188, 10.5572766],
                [106.3319713, 10.5567217],
                [106.3335808, 10.5547287],
                [106.3337631, 10.5545103],
                [106.3345427, 10.5533342],
                [106.3350658, 10.5525715],
                [106.3357212, 10.5516743],
                [106.3354936, 10.5513268],
                [106.3354526, 10.5510091],
                [106.3363888, 10.5501379],
                [106.336668, 10.550047],
                [106.337721, 10.5490431],
                [106.3381373, 10.5484348],
                [106.3382081, 10.5484727],
                [106.3384334, 10.5484506],
                [106.3385102, 10.5484311],
                [106.338581, 10.5484764],
                [106.3387607, 10.5482012],
                [106.338897, 10.548219],
                [106.3390246, 10.5483088],
                [106.3391491, 10.5483744],
                [106.3392953, 10.5484028],
                [106.3395966, 10.5484099],
                [106.3397446, 10.5484383],
                [106.3398709, 10.5483921],
                [106.3399647, 10.5482857],
                [106.3400838, 10.5480427],
                [106.3402638, 10.5478126],
                [106.3403136, 10.5477147],
                [106.3403187, 10.547632],
                [106.3402906, 10.5475379],
                [106.3402102, 10.5474187],
                [106.3401554, 10.5473171],
                [106.3401554, 10.5472243],
                [106.340186, 10.5471616],
                [106.3402493, 10.5471105],
                [106.3402919, 10.547043],
                [106.3402862, 10.546971],
                [106.3402297, 10.5468937],
                [106.3400601, 10.5467568],
                [106.3400094, 10.5466498],
                [106.34002, 10.5465335],
                [106.3400807, 10.5464142],
                [106.3404165, 10.5460439],
                [106.3407105, 10.5465059],
                [106.34085, 10.5466747],
                [106.340998, 10.5467633],
                [106.3411482, 10.5467696],
                [106.3413135, 10.5467063],
                [106.3414894, 10.5465186],
                [106.3418027, 10.5460334],
                [106.3419379, 10.5458878],
                [106.342116, 10.5457718],
                [106.3424228, 10.5456579],
                [106.3426696, 10.5455883],
                [106.3428606, 10.5454659],
                [106.3430108, 10.545314],
                [106.3434742, 10.5447023],
                [106.3439849, 10.5442487],
                [106.344575, 10.5438796],
                [106.3452215, 10.5435888],
                [106.3461273, 10.5433529],
                [106.3465914, 10.5435893],
                [106.3471987, 10.5439806],
                [106.3481407, 10.5447554],
                [106.3484563, 10.54429],
                [106.3485747, 10.5439917],
                [106.3485518, 10.543637],
                [106.3483436, 10.5432573],
                [106.3480956, 10.5429229],
                [106.3478315, 10.5427081],
                [106.3473348, 10.5424703],
                [106.3471472, 10.5422663],
                [106.347095, 10.5420835],
                [106.3470976, 10.5418477],
                [106.3472226, 10.5416194],
                [106.3474114, 10.5414488],
                [106.3476615, 10.5413284],
                [106.3480825, 10.5412732],
                [106.3483734, 10.5412004],
                [106.348723, 10.5410022],
                [106.348945, 10.5407739],
                [106.3490241, 10.5405431],
                [106.3490216, 10.5402797],
                [106.3489042, 10.5399134],
                [106.3485163, 10.5392863],
                [106.3482765, 10.5386265],
                [106.34815, 10.538129],
                [106.3479819, 10.5365399],
                [106.3479455, 10.5347558],
                [106.3478241, 10.5329837],
                [106.3478266, 10.5299928],
                [106.3477689, 10.5296309],
                [106.3475808, 10.52896],
                [106.3478886, 10.5286352],
                [106.3481169, 10.5285411],
                [106.3484146, 10.5284701],
                [106.3487761, 10.5284282],
                [106.3491377, 10.5284074],
                [106.3496055, 10.5285127],
                [106.3503495, 10.5287654],
                [106.3507323, 10.5288287],
                [106.3509236, 10.5287866],
                [106.3511576, 10.5286606],
                [106.351264, 10.5284714],
                [106.3517918, 10.5277484],
                [106.3523367, 10.5265385],
                [106.352464, 10.5262219],
                [106.3528161, 10.5259258],
                [106.3533941, 10.52563],
                [106.3535832, 10.5253381],
                [106.3546234, 10.5238757],
                [106.3549653, 10.5234593],
                [106.3553294, 10.5230702],
                [106.3557718, 10.5227129],
                [106.3558791, 10.5226486],
                [106.3559969, 10.522622],
                [106.3561984, 10.5226249],
                [106.3570559, 10.5226803],
                [106.3571618, 10.5226753],
                [106.3572588, 10.522652],
                [106.3573448, 10.5226138],
                [106.357479, 10.5223954],
                [106.3575461, 10.5222228],
                [106.3579033, 10.5220418],
                [106.3587189, 10.5216799],
                [106.358719, 10.5215053],
                [106.3587942, 10.5214373],
                [106.3591631, 10.521403],
                [106.3592883, 10.5213955],
                [106.3594015, 10.5214686],
                [106.3594421, 10.5216145],
                [106.359528, 10.5219563],
                [106.3594979, 10.5222179],
                [106.3595323, 10.5224753],
                [106.3596653, 10.5227158],
                [106.3599915, 10.5229731],
                [106.360628, 10.5233527],
                [106.3611423, 10.5230902],
                [106.362169, 10.5225562],
                [106.3631008, 10.5222194],
                [106.3637869, 10.5219056],
                [106.3639757, 10.5216883],
                [106.3645154, 10.521355],
                [106.3651836, 10.5208407],
                [106.367653, 10.5189571],
                [106.3689364, 10.5182843],
                [106.3694639, 10.5180099],
                [106.3692184, 10.517386],
                [106.3668877, 10.5119594],
                [106.3665464, 10.511259],
                [106.3667194, 10.5110658],
                [106.3668032, 10.5109297],
                [106.3668441, 10.5108421],
                [106.3673683, 10.5103198],
                [106.3675352, 10.510099],
                [106.3677568, 10.5100184],
                [106.3685397, 10.5093948],
                [106.3693963, 10.5085859],
                [106.37023, 10.5078549],
                [106.371833, 10.5063668],
                [106.3716678, 10.5059414],
                [106.3715465, 10.505693],
                [106.3714751, 10.5054709],
                [106.3714572, 10.5053304],
                [106.371447, 10.5048487],
                [106.3713845, 10.50417],
                [106.3712773, 10.5035101],
                [106.3712955, 10.5034251],
                [106.3712166, 10.5030223],
                [106.3712186, 10.5027658],
                [106.3711076, 10.5019931],
                [106.3709621, 10.501391],
                [106.3701379, 10.5002017],
                [106.3693436, 10.4990148],
                [106.3686125, 10.49805],
                [106.3691375, 10.4976347],
                [106.3693899, 10.4974877],
                [106.3695593, 10.4973603],
                [106.369924, 10.4970497],
                [106.3703081, 10.4967539],
                [106.3707015, 10.4965354],
                [106.3726507, 10.4953162],
                [106.3740411, 10.4944456],
                [106.3732951, 10.4933036],
                [106.3728173, 10.4922296],
                [106.3719567, 10.4898158],
                [106.3706071, 10.4878099],
                [106.3710051, 10.487569],
                [106.3714007, 10.487436],
                [106.3718396, 10.4870948],
                [106.3723193, 10.4867761],
                [106.3728807, 10.4865151],
                [106.373529, 10.4863579],
                [106.374142, 10.4863161],
                [106.3744758, 10.4861878],
                [106.3747641, 10.4860088],
                [106.3751647, 10.485609],
                [106.3755076, 10.485424],
                [106.3758748, 10.4853255],
                [106.3761327, 10.485224],
                [106.3764301, 10.4849704],
                [106.3767244, 10.4844691],
                [106.3770279, 10.4841349],
                [106.3777835, 10.4836724],
                [106.3785968, 10.4832905],
                [106.378882, 10.4831323],
                [106.3790793, 10.4829473],
                [106.3793706, 10.4825863],
                [106.3796771, 10.4823386],
                [106.380017, 10.4821088],
                [106.3801657, 10.4819238],
                [106.3801869, 10.4817776],
                [106.3801323, 10.4816195],
                [106.3800321, 10.4815001],
                [106.3798713, 10.4813897],
                [106.3793585, 10.4811271],
                [106.3792583, 10.4810137],
                [106.3792189, 10.4808735],
                [106.3792856, 10.4806497],
                [106.3794404, 10.4803931],
                [106.379671, 10.4802051],
                [106.3799107, 10.4801185],
                [106.3801869, 10.4800887],
                [106.3805419, 10.4801275],
                [106.3812186, 10.4803125],
                [106.3814675, 10.4803035],
                [106.3817072, 10.4801902],
                [106.3820956, 10.4798709],
                [106.3823779, 10.4797784],
                [106.3830086, 10.479691],
                [106.3837716, 10.4795254],
                [106.3842666, 10.4793297],
                [106.3845728, 10.4791214],
                [106.3847971, 10.4788277],
                [106.3849791, 10.4782727],
                [106.3853772, 10.4775075],
                [106.3855468, 10.4773158],
                [106.3857363, 10.4771757],
                [106.3859582, 10.4771011],
                [106.3870643, 10.4768847],
                [106.3877643, 10.4766634],
                [106.388045, 10.4765231],
                [106.388247, 10.4763478],
                [106.3884208, 10.4760693],
                [106.3886292, 10.4758199],
                [106.3887815, 10.4757165],
                [106.3889103, 10.475668],
                [106.3890218, 10.4756617],
                [106.3891184, 10.4757018],
                [106.3891742, 10.4758136],
                [106.3891849, 10.475995],
                [106.3891935, 10.4761765],
                [106.3892967, 10.4765234],
                [106.3893887, 10.4766831],
                [106.3895242, 10.4767781],
                [106.3896204, 10.4765986],
                [106.3898614, 10.4767225],
                [106.389975, 10.4768237],
                [106.3900691, 10.4769762],
                [106.3901176, 10.4770415],
                [106.3902044, 10.4770804],
                [106.3903128, 10.4770741],
                [106.3905221, 10.4770064],
                [106.3906292, 10.4770101],
                [106.3907264, 10.4770768],
                [106.3907757, 10.4772013],
                [106.3907757, 10.4773469],
                [106.3907435, 10.4776043],
                [106.3907693, 10.4777267],
                [106.3908616, 10.4778343],
                [106.3909989, 10.4778891],
                [106.3911405, 10.4778828],
                [106.3912692, 10.4778469],
                [106.392862, 10.4772278],
                [106.393255, 10.4775841],
                [106.3936837, 10.4782014],
                [106.3939337, 10.4787684],
                [106.3941124, 10.4796868],
                [106.3942859, 10.48057],
                [106.3948327, 10.4814243],
                [106.3955603, 10.4830965],
                [106.3954124, 10.4831994],
                [106.3955675, 10.4837317],
                [106.3958112, 10.4837254],
                [106.396, 10.4841115],
                [106.3961252, 10.4845731],
                [106.3960584, 10.4846835],
                [106.3960796, 10.484961],
                [106.3963171, 10.4855707],
                [106.3964658, 10.4858959],
                [106.3968657, 10.4862018],
                [106.3972085, 10.486326],
                [106.3975622, 10.4863526],
                [106.3979501, 10.4862674],
                [106.3983056, 10.4860811],
                [106.3985816, 10.4858629],
                [106.3992907, 10.4851656],
                [106.3996949, 10.4848835],
                [106.4000017, 10.4847398],
                [106.4003693, 10.4846645],
                [106.4005302, 10.4855633],
                [106.4008435, 10.4854557],
                [106.4009916, 10.4859051],
                [106.4007706, 10.485979],
                [106.4008818, 10.4862844],
                [106.4017819, 10.4884756],
                [106.4027002, 10.4907993],
                [106.403266, 10.4921736],
                [106.4037061, 10.4932139],
                [106.4039473, 10.4927154],
                [106.4040162, 10.4925574],
                [106.4040468, 10.4923592],
                [106.4040289, 10.492048],
                [106.4039983, 10.4918799],
                [106.404057, 10.4917469],
                [106.404182, 10.4916566],
                [106.4043224, 10.4916139],
                [106.4047358, 10.4915964],
                [106.404945, 10.4916215],
                [106.4051236, 10.4916917],
                [106.4053073, 10.4918348],
                [106.4056243, 10.4921557],
                [106.4057213, 10.4922034],
                [106.4058514, 10.4922034],
                [106.4059739, 10.4921357],
                [106.4060556, 10.4920002],
                [106.4060632, 10.4918571],
                [106.4060122, 10.4916188],
                [106.4060071, 10.4914482],
                [106.406053, 10.4912926],
                [106.4061704, 10.4911947],
                [106.4063516, 10.4911446],
                [106.4066603, 10.4911747],
                [106.4069742, 10.4912349],
                [106.4072855, 10.4915034],
                [106.4076096, 10.4917217],
                [106.4077627, 10.4919374],
                [106.4078801, 10.4920353],
                [106.4079796, 10.4920278],
                [106.4081378, 10.4919274],
                [106.4082935, 10.4917141],
                [106.4084593, 10.4913804],
                [106.4089595, 10.4911947],
                [106.4093512, 10.490971],
                [106.4096931, 10.4909108],
                [106.4097646, 10.4908631],
                [106.409785, 10.4907953],
                [106.4097569, 10.4904641],
                [106.409836, 10.4898569],
                [106.4098463, 10.489729],
                [106.4099228, 10.489611],
                [106.4100734, 10.4895408],
                [106.4103158, 10.4895232],
                [106.4105863, 10.4895985],
                [106.4109052, 10.4896963],
                [106.4110685, 10.4897942],
                [106.4111196, 10.4899397],
                [106.4111196, 10.4900627],
                [106.4109869, 10.4902509],
                [106.4110073, 10.4904014],
                [106.4111137, 10.4906159],
                [106.4123068, 10.4901496],
                [106.4123859, 10.4900831],
                [106.4124255, 10.4900003],
                [106.4124558, 10.4897597],
                [106.4125272, 10.4894912],
                [106.4127319, 10.489135],
                [106.412958, 10.4888814],
                [106.4131037, 10.4887635],
                [106.4133753, 10.4886218],
                [106.4135092, 10.4885324],
                [106.4135993, 10.4884301],
                [106.4136776, 10.48839],
                [106.4137688, 10.4883963],
                [106.4138581, 10.4884654],
                [106.4139882, 10.488626],
                [106.4140367, 10.4886617],
                [106.4141228, 10.4886699],
                [106.4142472, 10.4886655],
                [106.4143602, 10.4886911],
                [106.4148501, 10.4889433],
                [106.4151053, 10.4889834],
                [106.4151985, 10.4889772],
                [106.4153541, 10.4888505],
                [106.4155799, 10.4887652],
                [106.4159397, 10.4886422],
                [106.4162323, 10.4885647],
                [106.4170199, 10.4882477],
                [106.4168313, 10.4872909],
                [106.4166834, 10.4862761],
                [106.4166942, 10.4855522],
                [106.4167844, 10.4852719],
                [106.417266, 10.4844338],
                [106.4173093, 10.4840488],
                [106.4174915, 10.4840275],
                [106.4175547, 10.4836833],
                [106.4177586, 10.4834952],
                [106.4177839, 10.4834012],
                [106.4177514, 10.4832309],
                [106.4177737, 10.4830376],
                [106.4176474, 10.4827289],
                [106.4175788, 10.4826704],
                [106.4174868, 10.4826349],
                [106.4174471, 10.4825941],
                [106.4174399, 10.482532],
                [106.4174563, 10.4824122],
                [106.4173949, 10.4820698],
                [106.4177316, 10.4819567],
                [106.4181204, 10.4817641],
                [106.4184803, 10.4814893],
                [106.4189527, 10.4809994],
                [106.4194901, 10.4804838],
                [106.4200888, 10.4800872],
                [106.4206424, 10.479815],
                [106.421166, 10.4796293],
                [106.4217282, 10.4794922],
                [106.4226939, 10.4793623],
                [106.4236805, 10.4788383],
                [106.4239961, 10.4786384],
                [106.4241842, 10.4783758],
                [106.4244149, 10.477785],
                [106.4249884, 10.4770002],
                [106.4250976, 10.4767555],
                [106.4251644, 10.4764959],
                [106.4252615, 10.475556],
                [106.4253738, 10.474622],
                [106.4254654, 10.4742903],
                [106.4255544, 10.4741268],
                [106.425681, 10.4740181],
                [106.4258072, 10.4739491],
                [106.4262647, 10.4738282],
                [106.4268848, 10.4735961],
                [106.4270578, 10.4734814],
                [106.4272488, 10.4734087],
                [106.4274054, 10.4733981],
                [106.4275746, 10.4734182],
                [106.4280509, 10.4735637],
                [106.4292075, 10.474002],
                [106.4296658, 10.4740853],
                [106.4299491, 10.4740765],
                [106.4301584, 10.4739913],
                [106.43031, 10.4738476],
                [106.430557, 10.4734049],
                [106.4310291, 10.4727475],
                [106.4320115, 10.4714853],
                [106.4324325, 10.4707802],
                [106.4334405, 10.469927],
                [106.4338207, 10.4694879],
                [106.434393, 10.4687239],
                [106.4345022, 10.4686344],
                [106.4346525, 10.4686418],
                [106.4347723, 10.4687164],
                [106.4349453, 10.4689552],
                [106.4351855, 10.4693232],
                [106.4352964, 10.4694656],
                [106.4354227, 10.4695521],
                [106.4355522, 10.4696023],
                [106.4357123, 10.4696158],
                [106.4358598, 10.4695929],
                [106.4359934, 10.4695185],
                [106.4361114, 10.4693951],
                [106.4364955, 10.468856],
                [106.4367699, 10.4683807],
                [106.4368873, 10.4682377],
                [106.4373483, 10.4679593],
                [106.437842, 10.4677781],
                [106.4380307, 10.4676945],
                [106.4381942, 10.4675657],
                [106.4386955, 10.4669977],
                [106.438961, 10.4668843],
                [106.4391795, 10.4667053],
                [106.4393716, 10.4664123],
                [106.4394979, 10.4663076],
                [106.4396621, 10.4662615],
                [106.43983, 10.4663111],
                [106.4399581, 10.4664016],
                [106.4401908, 10.4666571],
                [106.4404723, 10.4668555],
                [106.4407186, 10.4669471],
                [106.4409861, 10.46698],
                [106.4412934, 10.4669398],
                [106.4415659, 10.4668166],
                [106.4418238, 10.4665749],
                [106.4420336, 10.4662693],
                [106.4421455, 10.4659535],
                [106.442178, 10.4656554],
                [106.4419759, 10.4644027],
                [106.4421671, 10.4633736],
                [106.4422862, 10.4615424],
                [106.4422403, 10.4605418],
                [106.4422339, 10.4603646],
                [106.4421845, 10.4601873],
                [106.4419786, 10.4598771],
                [106.4419271, 10.4597547],
                [106.4419142, 10.4596007],
                [106.4419528, 10.4593369],
                [106.4420459, 10.4585946],
                [106.442093, 10.4584364],
                [106.4421491, 10.458326],
                [106.4422562, 10.4582139],
                [106.4424076, 10.458156],
                [106.4425699, 10.4581321],
                [106.4429143, 10.458141],
                [106.4434212, 10.4580357],
                [106.4435424, 10.4580336],
                [106.4436669, 10.4580642],
                [106.4438589, 10.4581275],
                [106.4440429, 10.4581332],
                [106.4442857, 10.4580646],
                [106.4445527, 10.45789],
                [106.4447621, 10.4576781],
                [106.4449814, 10.4573684],
                [106.4450669, 10.4572743],
                [106.4451472, 10.4572416],
                [106.4452432, 10.4572381],
                [106.445703, 10.4572829],
                [106.4461293, 10.4571232],
                [106.4462386, 10.4570441],
                [106.4462893, 10.456954],
                [106.4463086, 10.4568274],
                [106.4462936, 10.4567008],
                [106.4461627, 10.4563378],
                [106.4460652, 10.4559215],
                [106.446076, 10.4558168],
                [106.4461175, 10.4557405],
                [106.4462131, 10.4556943],
                [106.446307, 10.4556908],
                [106.4464008, 10.455705],
                [106.4466083, 10.4557955],
                [106.4467436, 10.4558398],
                [106.4468356, 10.4558345],
                [106.4469587, 10.4557651],
                [106.4471578, 10.4555794],
                [106.4475346, 10.455078],
                [106.4478563, 10.4548422],
                [106.4485715, 10.4545301],
                [106.4495334, 10.4542511],
                [106.4497549, 10.4541347],
                [106.4503649, 10.4537095],
                [106.4506168, 10.453599],
                [106.4508474, 10.4535438],
                [106.4510977, 10.4535289],
                [106.4516151, 10.4535916],
                [106.4517851, 10.4535752],
                [106.4519307, 10.4535364],
                [106.4521128, 10.4534319],
                [106.4522129, 10.4533901],
                [106.4523207, 10.4533797],
                [106.4526399, 10.4534379],
                [106.4527686, 10.4534358],
                [106.4528716, 10.4533789],
                [106.4529875, 10.4532206],
                [106.4530862, 10.4529948],
                [106.4531441, 10.452691],
                [106.4531935, 10.4525622],
                [106.4534703, 10.4522014],
                [106.4536591, 10.4518659],
                [106.453863, 10.4514396],
                [106.4540652, 10.451007],
                [106.4534453, 10.4505276],
                [106.4529888, 10.4505436],
                [106.4525125, 10.4506057],
                [106.4521082, 10.4505678],
                [106.4516379, 10.4504529],
                [106.4511766, 10.4503096],
                [106.4507776, 10.4502544],
                [106.4504771, 10.4503067],
                [106.4500538, 10.4504618],
                [106.4495587, 10.4506956],
                [106.4493785, 10.4507167],
                [106.4489193, 10.4508328],
                [106.4484966, 10.4509003],
                [106.4483142, 10.4509636],
                [106.4475439, 10.4511451],
                [106.4472992, 10.4504825],
                [106.447252, 10.4503833],
                [106.4471834, 10.4503095],
                [106.4469821, 10.4501597],
                [106.4464462, 10.4499184],
                [106.446226, 10.4498066],
                [106.4459355, 10.4495777],
                [106.4456883, 10.4493754],
                [106.4454393, 10.4490329],
                [106.4446617, 10.4480747],
                [106.4441907, 10.4475353],
                [106.4439199, 10.4471966],
                [106.4436498, 10.4467847],
                [106.4430338, 10.4457253],
                [106.4424057, 10.4445465],
                [106.4422721, 10.4445674],
                [106.4418739, 10.4447324],
                [106.4413267, 10.4448632],
                [106.4405753, 10.4450722],
                [106.4400868, 10.4451647],
                [106.4395154, 10.4453335],
                [106.4393728, 10.4449999],
                [106.4391184, 10.4446007],
                [106.4390318, 10.4444179],
                [106.4389192, 10.4440578],
                [106.4387748, 10.443678],
                [106.4385556, 10.4433377],
                [106.438237, 10.4433676],
                [106.4380185, 10.4425171],
                [106.4379099, 10.4422264],
                [106.437795, 10.4420031],
                [106.4376215, 10.4412829],
                [106.4374633, 10.4410821],
                [106.437175, 10.4414636],
                [106.4368815, 10.4413632],
                [106.436759, 10.4413833],
                [106.4363456, 10.4415915],
                [106.4360981, 10.4416718],
                [106.4360376, 10.4416355],
                [106.4358386, 10.4411317],
                [106.4357762, 10.4406439],
                [106.4357886, 10.4402623],
                [106.4357052, 10.4399103],
                [106.4356819, 10.4397832],
                [106.4356712, 10.4396059],
                [106.4355918, 10.4394076],
                [106.4353551, 10.4389753],
                [106.4352432, 10.4389912],
                [106.4346712, 10.4389402],
                [106.4341889, 10.4387595],
                [106.4334106, 10.4385337],
                [106.4331733, 10.4383981],
                [106.432092, 10.4375256],
                [106.4318775, 10.4374264],
                [106.4317079, 10.4372998],
                [106.4314655, 10.4372323],
                [106.4313496, 10.4371457],
                [106.4311779, 10.4368165],
                [106.4308546, 10.4365171],
                [106.4300176, 10.4367329],
                [106.4296552, 10.436738],
                [106.4288606, 10.4366556],
                [106.4275133, 10.4364882],
                [106.4274466, 10.4349393],
                [106.4273828, 10.4337724],
                [106.4261953, 10.4338563],
                [106.4252849, 10.4339309],
                [106.4237009, 10.4341487],
                [106.4226223, 10.4344258],
                [106.421906, 10.4345802],
                [106.4213378, 10.4346761],
                [106.4206437, 10.4347413],
                [106.4204575, 10.4347062],
                [106.4199732, 10.4348442],
                [106.4195772, 10.4348651],
                [106.4191994, 10.4348248],
                [106.4189247, 10.4348308],
                [106.417899, 10.4347084],
                [106.4176739, 10.4347121],
                [106.4175055, 10.4346714],
                [106.4173574, 10.4345913],
                [106.4169551, 10.434311],
                [106.4166806, 10.4341601],
                [106.4162436, 10.4340154],
                [106.4156944, 10.4337065],
                [106.4153452, 10.4334619],
                [106.4148425, 10.4320791],
                [106.4148272, 10.4318532],
                [106.4147276, 10.4314843],
                [106.4146548, 10.430956],
                [106.4146608, 10.4305338],
                [106.4146801, 10.4302109],
                [106.4148052, 10.429718],
                [106.4164111, 10.4297286],
                [106.4173674, 10.4297269],
                [106.4177499, 10.429702],
                [106.4181902, 10.4296985],
                [106.4188065, 10.4291864],
                [106.4192001, 10.4288495],
                [106.4193455, 10.4286778],
                [106.419516, 10.4285003],
                [106.4197163, 10.4283096],
                [106.4199491, 10.4280809],
                [106.4211638, 10.4277245],
                [106.4208053, 10.4264629],
                [106.4203772, 10.4256945],
                [106.419995, 10.4250142],
                [106.4203311, 10.424665],
                [106.4205502, 10.4243664],
                [106.4204883, 10.4240465],
                [106.4204519, 10.4235421],
                [106.4203994, 10.4231772],
                [106.420259, 10.4224996],
                [106.4202922, 10.4223465],
                [106.4203713, 10.4222863],
                [106.4203736, 10.4221707],
                [106.4205557, 10.422108],
                [106.4207043, 10.4220051],
                [106.4207732, 10.4216261],
                [106.4212225, 10.4214611],
                [106.421329, 10.4213954],
                [106.421412, 10.4212996],
                [106.4215798, 10.4209766],
                [106.4220814, 10.4197024],
                [106.4225567, 10.4185816],
                [106.4227207, 10.4182728],
                [106.4230776, 10.4177245],
                [106.4238286, 10.4167779],
                [106.4242797, 10.4161816],
                [106.4248535, 10.4156563],
                [106.4257671, 10.4148468],
                [106.4262972, 10.4144341],
                [106.4261126, 10.4139951],
                [106.4258734, 10.4135108],
                [106.4254936, 10.4127183],
                [106.4251784, 10.4121845],
                [106.4251017, 10.4121024],
                [106.4249457, 10.411826],
                [106.4247855, 10.4115663],
                [106.4247179, 10.4114342],
                [106.4246811, 10.4113369],
                [106.4251452, 10.4110856],
                [106.4258705, 10.4106976],
                [106.42662, 10.4104021],
                [106.4278188, 10.4097867],
                [106.428013, 10.4100613],
                [106.428362, 10.4104702],
                [106.4286473, 10.4108846],
                [106.4288855, 10.4113552],
                [106.4290958, 10.4116718],
                [106.4291923, 10.4119145],
                [106.4293618, 10.4125391],
                [106.4294906, 10.4131448],
                [106.4295807, 10.4137379],
                [106.4297052, 10.4142169],
                [106.4298102, 10.4144757],
                [106.4301318, 10.414551],
                [106.4302032, 10.4147217],
                [106.4304125, 10.4149927],
                [106.4305222, 10.4150856],
                [106.4306702, 10.4151584],
                [106.4316985, 10.415447],
                [106.4319023, 10.4146385],
                [106.4320178, 10.4144717],
                [106.432139, 10.4143588],
                [106.4322997, 10.4142509],
                [106.4327718, 10.4140075],
                [106.4331367, 10.4138268],
                [106.433619, 10.4135582],
                [106.434285, 10.4134051],
                [106.4366428, 10.41274],
                [106.4387353, 10.4119093],
                [106.4392466, 10.4117332],
                [106.4392937, 10.4117391],
                [106.439322, 10.41177],
                [106.4394122, 10.4121499],
                [106.4394873, 10.41242],
                [106.4396439, 10.412802],
                [106.4398563, 10.4132663],
                [106.4400742, 10.4137215],
                [106.4401201, 10.4140151],
                [106.4401379, 10.4141343],
                [106.4402369, 10.4144522],
                [106.440401, 10.4149274],
                [106.4410005, 10.4147358],
                [106.4415346, 10.4146009],
                [106.4423862, 10.4145619],
                [106.4433209, 10.4142921],
                [106.4446671, 10.4139185],
                [106.4451328, 10.4137665],
                [106.4461217, 10.4136563],
                [106.446186, 10.4130021],
                [106.4462697, 10.4126116],
                [106.4462998, 10.4122824],
                [106.4463448, 10.4121621],
                [106.4467997, 10.4119701],
                [106.4470165, 10.4119215],
                [106.447201, 10.4119089],
                [106.4473405, 10.4118878],
                [106.4474842, 10.4118434],
                [106.4479069, 10.4116577],
                [106.4484278, 10.4114794],
                [106.448783, 10.4113865],
                [106.449166, 10.4112789],
                [106.4512388, 10.4129208],
                [106.4519089, 10.4133313],
                [106.4520123, 10.4131395],
                [106.452115, 10.4130487],
                [106.4523406, 10.4129132],
                [106.4530075, 10.4124188],
                [106.4537003, 10.4117338],
                [106.4545159, 10.4106299],
                [106.4549526, 10.4101969],
                [106.4551114, 10.4099236],
                [106.4553676, 10.4097],
                [106.4560929, 10.4087559],
                [106.4567173, 10.4077834],
                [106.4571374, 10.4072223],
                [106.4576758, 10.406635],
                [106.458204, 10.4061456],
                [106.4586506, 10.4057867],
                [106.4589747, 10.4055131],
                [106.4591916, 10.4052747],
                [106.4596933, 10.4047891],
                [106.4593806, 10.4044756],
                [106.4583898, 10.4037548],
                [106.4576069, 10.4031429],
                [106.4566289, 10.4025732],
                [106.4561415, 10.4022721],
                [106.4559246, 10.4021767],
                [106.4556467, 10.4019975],
                [106.4555618, 10.4019034],
                [106.4553886, 10.4017703],
                [106.4553183, 10.4016904],
                [106.4551198, 10.4013568],
                [106.4550061, 10.4012201],
                [106.4548058, 10.4010746],
                [106.4545339, 10.4008919],
                [106.4543686, 10.4008872],
                [106.4542025, 10.4009825],
                [106.454027, 10.4012996],
                [106.4536923, 10.4014363],
                [106.4535335, 10.4015224],
                [106.4534559, 10.4016209],
                [106.4534171, 10.4017282],
                [106.453354, 10.4018445],
                [106.4531546, 10.4020104],
                [106.4526431, 10.4023174],
                [106.4524058, 10.4024408],
                [106.452228, 10.4025473],
                [106.4520611, 10.4026803],
                [106.4517506, 10.4029613],
                [106.4515416, 10.4031178],
                [106.4511228, 10.4033745],
                [106.4506699, 10.4037779],
                [106.4502535, 10.4041938],
                [106.4489549, 10.4045036],
                [106.4486678, 10.4035959],
                [106.4484272, 10.4029142],
                [106.4480926, 10.402157],
                [106.4489555, 10.4019034],
                [106.4486459, 10.4009525],
                [106.4484841, 10.4005077],
                [106.4483857, 10.4002571],
                [106.448188, 10.3993799],
                [106.4481222, 10.3989921],
                [106.4480601, 10.3986772],
                [106.4483723, 10.3985976],
                [106.4485389, 10.3985446],
                [106.4487316, 10.3984611],
                [106.4491746, 10.3983539],
                [106.449592, 10.3982726],
                [106.4498552, 10.3982006],
                [106.4500633, 10.3981816],
                [106.4503434, 10.3983062],
                [106.4505236, 10.3984233],
                [106.4507049, 10.3985383],
                [106.4511856, 10.398761],
                [106.4513476, 10.3988116],
                [106.4519987, 10.3990988],
                [106.4528855, 10.3995312],
                [106.4535754, 10.3998963],
                [106.4541068, 10.4002151],
                [106.4541859, 10.3998825],
                [106.4542276, 10.3998477],
                [106.4544983, 10.3990331],
                [106.4545181, 10.3985876],
                [106.4547674, 10.3981128],
                [106.4548976, 10.3975707],
                [106.455066, 10.3972092],
                [106.4551068, 10.3970461],
                [106.4551221, 10.3966922],
                [106.4552559, 10.3961319],
                [106.4554941, 10.3957841],
                [106.4558207, 10.3953528],
                [106.4558747, 10.395199],
                [106.4559092, 10.3950949],
                [106.4559768, 10.3950108],
                [106.4561375, 10.3948326],
                [106.4564826, 10.3948946],
                [106.4571177, 10.3951099],
                [106.4574825, 10.3952069],
                [106.4576863, 10.3952302],
                [106.4581709, 10.3953586],
                [106.4584865, 10.3953929],
                [106.4587845, 10.3954647],
                [106.4590149, 10.3954925],
                [106.459484, 10.3960406],
                [106.459783, 10.396289],
                [106.4601631, 10.3968733],
                [106.4602817, 10.3971016],
                [106.4604144, 10.3974166],
                [106.4605247, 10.397615],
                [106.4607998, 10.3978504],
                [106.4610215, 10.3981386],
                [106.4611034, 10.3982908],
                [106.4612551, 10.398652],
                [106.4613128, 10.3987833],
                [106.4613917, 10.3989146],
                [106.4616648, 10.3992758],
                [106.4617437, 10.3993952],
                [106.4617923, 10.3995176],
                [106.4618256, 10.3997026],
                [106.4618742, 10.3997892],
                [106.4625479, 10.400222],
                [106.4629636, 10.4004607],
                [106.4631791, 10.4005324],
                [106.4634531, 10.4005437],
                [106.4641268, 10.4004541],
                [106.4643104, 10.4004019],
                [106.4644712, 10.4003094],
                [106.4646048, 10.4001915],
                [106.464717, 10.4000482],
                [106.4649598, 10.3996438],
                [106.4650493, 10.3995468],
                [106.4651571, 10.3995169],
                [106.4652551, 10.3995241],
                [106.4653327, 10.39932],
                [106.4653634, 10.3992597],
                [106.4654157, 10.3992242],
                [106.4655754, 10.3991694],
                [106.4657336, 10.3989987],
                [106.4659122, 10.3988958],
                [106.4660015, 10.398813],
                [106.466318, 10.3983487],
                [106.4664098, 10.3981805],
                [106.466443, 10.3980199],
                [106.4664685, 10.397681],
                [106.4665476, 10.397425],
                [106.4666562, 10.3972167],
                [106.4667176, 10.3971457],
                [106.4667897, 10.3970819],
                [106.4670009, 10.3969825],
                [106.4670839, 10.3969346],
                [106.4671632, 10.3968281],
                [106.4672697, 10.3967482],
                [106.4673996, 10.3966861],
                [106.4675476, 10.3966364],
                [106.4677316, 10.396624],
                [106.4680384, 10.3965636],
                [106.4681972, 10.3965654],
                [106.4683505, 10.3965938],
                [106.4689821, 10.3968298],
                [106.4690885, 10.3968458],
                [106.4692635, 10.3968192],
                [106.4693862, 10.396821],
                [106.4695053, 10.3968831],
                [106.4696244, 10.3970144],
                [106.4697345, 10.3972043],
                [106.4698391, 10.3975025],
                [106.4699203, 10.3976178],
                [106.4700528, 10.3977146],
                [106.4713159, 10.3982275],
                [106.47186, 10.3985124],
                [106.4719174, 10.398604],
                [106.4719952, 10.3986391],
                [106.4720986, 10.398663],
                [106.4721777, 10.398737],
                [106.472281, 10.3987985],
                [106.4724851, 10.3988223],
                [106.4726995, 10.3988198],
                [106.4728998, 10.3987897],
                [106.473081, 10.3987232],
                [106.4732137, 10.3986005],
                [106.4735511, 10.398565],
                [106.4739535, 10.3984443],
                [106.4745904, 10.3984319],
                [106.474769, 10.3984088],
                [106.4749405, 10.3983609],
                [106.4754421, 10.3981745],
                [106.4757133, 10.398041],
                [106.4758957, 10.3979524],
                [106.4760674, 10.3979017],
                [106.4763828, 10.3978278],
                [106.4772614, 10.3976884],
                [106.4784108, 10.3974417],
                [106.4788745, 10.3972252],
                [106.4792859, 10.3970939],
                [106.4798933, 10.3968206],
                [106.480072, 10.3967653],
                [106.4801766, 10.3967578],
                [106.4802531, 10.3967955],
                [106.4803169, 10.3969084],
                [106.4803654, 10.397069],
                [106.4803986, 10.3972472],
                [106.4803986, 10.3974179],
                [106.4803501, 10.3976137],
                [106.4802761, 10.3978722],
                [106.4802608, 10.3980454],
                [106.4803195, 10.3982437],
                [106.4804318, 10.3984419],
                [106.4805595, 10.398657],
                [106.480649, 10.3988092],
                [106.4806721, 10.398944],
                [106.4806764, 10.3990558],
                [106.4806633, 10.3991745],
                [106.480545, 10.3995326],
                [106.4805268, 10.3996699],
                [106.480545, 10.3998161],
                [106.4806148, 10.3999743],
                [106.4806148, 10.4000728],
                [106.4806299, 10.4001683],
                [106.4806633, 10.4002635],
                [106.4807407, 10.4003948],
                [106.4807927, 10.4006206],
                [106.4808565, 10.4008264],
                [106.4808897, 10.4009444],
                [106.4808846, 10.4011502],
                [106.4809061, 10.4012449],
                [106.4809911, 10.4014091],
                [106.4810002, 10.4014986],
                [106.4809638, 10.4016777],
                [106.4809668, 10.4017642],
                [106.4810548, 10.4020478],
                [106.4810912, 10.4023492],
                [106.4811642, 10.4026457],
                [106.4811728, 10.4029749],
                [106.4812221, 10.4034265],
                [106.4813487, 10.4038866],
                [106.4814453, 10.4043214],
                [106.4814982, 10.4047434],
                [106.4817383, 10.4047695],
                [106.4818737, 10.404773],
                [106.4819595, 10.404735],
                [106.4820153, 10.4046822],
                [106.4821205, 10.4044669],
                [106.4821977, 10.4044057],
                [106.4823136, 10.4043846],
                [106.4825324, 10.4043783],
                [106.482732, 10.4043382],
                [106.4830045, 10.4042095],
                [106.483262, 10.4040807],
                [106.4834637, 10.4038971],
                [106.4836118, 10.4037916],
                [106.4838521, 10.4037156],
                [106.4842061, 10.4035003],
                [106.4843714, 10.4033315],
                [106.4846975, 10.4031162],
                [106.4848606, 10.4030635],
                [106.4850124, 10.4030589],
                [106.4851247, 10.4031127],
                [106.4852097, 10.4031858],
                [106.4852643, 10.4033186],
                [106.4852871, 10.4035544],
                [106.485322, 10.4036738],
                [106.4853872, 10.4037618],
                [106.4854752, 10.4038096],
                [106.485589, 10.4038081],
                [106.4858151, 10.4036409],
                [106.4859532, 10.403538],
                [106.4860822, 10.4035152],
                [106.4861808, 10.403557],
                [106.4862628, 10.4036137],
                [106.4863235, 10.4036928],
                [106.4863629, 10.4038227],
                [106.486372, 10.4039212],
                [106.4863493, 10.4040092],
                [106.4862855, 10.4041062],
                [106.4861611, 10.404206],
                [106.4859698, 10.4043693],
                [106.4857515, 10.4046036],
                [106.4856721, 10.4047313],
                [106.4856432, 10.4048556],
                [106.4856613, 10.4049567],
                [106.4857334, 10.4050508],
                [106.4858525, 10.4051182],
                [106.4863505, 10.405285],
                [106.4865238, 10.4053631],
                [106.4866735, 10.4054856],
                [106.4867728, 10.4056134],
                [106.4868287, 10.4057376],
                [106.486854, 10.4058583],
                [106.4868449, 10.4059647],
                [106.48678, 10.4060783],
                [106.4866302, 10.4062114],
                [106.4861056, 10.4065839],
                [106.4859903, 10.4067316],
                [106.485969, 10.4068779],
                [106.485963, 10.4070211],
                [106.4859887, 10.4071748],
                [106.4860297, 10.4073077],
                [106.4860934, 10.4074599],
                [106.4863013, 10.4079225],
                [106.4863468, 10.4080747],
                [106.4863696, 10.408212],
                [106.4864121, 10.4085717],
                [106.4864412, 10.4086724],
                [106.4864918, 10.4087638],
                [106.4865669, 10.4088477],
                [106.4866549, 10.408891],
                [106.4867579, 10.4089079],
                [106.4868727, 10.4088973],
                [106.4869875, 10.4088615],
                [106.4871784, 10.4087802],
                [106.4872696, 10.4087612],
                [106.487349, 10.4087707],
                [106.4874252, 10.4088309],
                [106.4875114, 10.4089382],
                [106.4875767, 10.4090427],
                [106.4876816, 10.4093034],
                [106.487761, 10.4094427],
                [106.4878608, 10.4095419],
                [106.4880206, 10.4096601],
                [106.4881738, 10.4097306],
                [106.4885364, 10.4098365],
                [106.4888226, 10.4099569],
                [106.4890536, 10.4101059],
                [106.4892088, 10.4102603],
                [106.4895065, 10.4106064],
                [106.489705, 10.4107626],
                [106.4899049, 10.4108927],
                [106.4911902, 10.4116524],
                [106.491407, 10.4118023],
                [106.4915443, 10.4119985],
                [106.4916323, 10.4122201],
                [106.4917224, 10.4125177],
                [106.4918465, 10.4129228],
                [106.4919633, 10.4131705],
                [106.492106, 10.4133839],
                [106.4922834, 10.4136052],
                [106.4925203, 10.4138109],
                [106.4928163, 10.4140042],
                [106.4930396, 10.4141121],
                [106.4932731, 10.4141723],
                [106.4934976, 10.4141774],
                [106.4939868, 10.4141295],
                [106.4941645, 10.414126],
                [106.4943016, 10.4141565],
                [106.4944381, 10.4142236],
                [106.4945413, 10.4143102],
                [106.494646, 10.4144326],
                [106.4948918, 10.4147803],
                [106.4949798, 10.4148743],
                [106.4950875, 10.4149429],
                [106.4952074, 10.4149758],
                [106.4953561, 10.4149638],
                [106.4954972, 10.4148892],
                [106.4958831, 10.4145472],
                [106.4960827, 10.4144501],
                [106.4962468, 10.4144237],
                [106.4964524, 10.4144424],
                [106.4966463, 10.4145378],
                [106.4968581, 10.414667],
                [106.4970273, 10.414847],
                [106.4971428, 10.4150084],
                [106.4972078, 10.4151646],
                [106.497224, 10.415335],
                [106.4972114, 10.4154699],
                [106.497067, 10.4159259],
                [106.4970183, 10.4160981],
                [106.4970039, 10.4162649],
                [106.4970382, 10.4164406],
                [106.4970941, 10.4165985],
                [106.4971861, 10.416721],
                [106.4973016, 10.4167937],
                [106.4974333, 10.4168257],
                [106.4975795, 10.4168168],
                [106.4977689, 10.4167511],
                [106.4979331, 10.4166198],
                [106.4980612, 10.4164548],
                [106.498152, 10.4162369],
                [106.4983344, 10.4156565],
                [106.4984409, 10.4154173],
                [106.4985668, 10.4152636],
                [106.4987261, 10.4151591],
                [106.4989006, 10.4151203],
                [106.4990872, 10.4151561],
                [106.4992366, 10.415256],
                [106.4993629, 10.4154174],
                [106.4994459, 10.4156499],
                [106.4995307, 10.4159836],
                [106.4996353, 10.4165301],
                [106.4997294, 10.4171694],
                [106.4997719, 10.4173574],
                [106.4998902, 10.4175305],
                [106.5000693, 10.4176708],
                [106.5002847, 10.4177753],
                [106.5005396, 10.4178678],
                [106.5010039, 10.4179902],
                [106.5012376, 10.4181006],
                [106.5014075, 10.4182468],
                [106.501535, 10.4184259],
                [106.501626, 10.4186318],
                [106.5016837, 10.4188676],
                [106.5017684, 10.4195208],
                [106.5018108, 10.4198089],
                [106.5018746, 10.4200163],
                [106.5019747, 10.4202028],
                [106.5021462, 10.4203834],
                [106.5023161, 10.4205042],
                [106.5024951, 10.4205729],
                [106.5026893, 10.4206251],
                [106.5029048, 10.420655],
                [106.5033577, 10.4206711],
                [106.5036217, 10.4206775],
                [106.5038212, 10.4207302],
                [106.5039864, 10.4208442],
                [106.5041045, 10.4209982],
                [106.5041655, 10.4211993],
                [106.5041867, 10.4214291],
                [106.5041655, 10.422035],
                [106.5041814, 10.4223226],
                [106.5042464, 10.4225711],
                [106.504345, 10.4227923],
                [106.5044995, 10.4229949],
                [106.5046905, 10.4231658],
                [106.5048965, 10.4232777],
                [106.5051239, 10.4233178],
                [106.5053879, 10.4232819],
                [106.5056539, 10.4231553],
                [106.5064274, 10.4226531],
                [106.5067103, 10.4225296],
                [106.5070229, 10.422449],
                [106.5073225, 10.4224265],
                [106.5076287, 10.4224742],
                [106.5079069, 10.4225394],
                [106.5081442, 10.4226448],
                [106.5083279, 10.4227678],
                [106.5084428, 10.4229139],
                [106.5085201, 10.4230876],
                [106.5087225, 10.4236791],
                [106.5087783, 10.4239788],
                [106.5088727, 10.42492],
                [106.5090015, 10.4256038],
                [106.5091946, 10.4263213],
                [106.5093655, 10.4270871],
                [106.5094919, 10.4275166],
                [106.509638, 10.4277916],
                [106.5098275, 10.4280773],
                [106.5101144, 10.428308],
                [106.5104229, 10.4285192],
                [106.510796, 10.4287129],
                [106.511738, 10.4289682],
                [106.5122594, 10.4290547],
                [106.5127122, 10.4290526],
                [106.5130941, 10.4290252],
                [106.5134418, 10.4289197],
                [106.5137357, 10.4287551],
                [106.5139632, 10.4285736],
                [106.5141048, 10.4283879],
                [106.5141863, 10.428179],
                [106.5142185, 10.4279721],
                [106.5142035, 10.42774],
                [106.514049, 10.4271386],
                [106.5140089, 10.4267928],
                [106.5140833, 10.4264824],
                [106.5142062, 10.4262466],
                [106.5144368, 10.4260153],
                [106.5147615, 10.4258347],
                [106.5151712, 10.4256646],
                [106.5157003, 10.4254953],
                [106.5161402, 10.4253665],
                [106.5164685, 10.4252441],
                [106.5168311, 10.425088],
                [106.5176627, 10.4246612],
                [106.517995, 10.4245209],
                [106.51829, 10.4244513],
                [106.5185604, 10.4244355],
                [106.5188683, 10.4244629],
                [106.5191739, 10.4245535],
                [106.5194763, 10.4247317],
                [106.5197353, 10.4250065],
                [106.5199029, 10.425331],
                [106.520088, 10.4258073],
                [106.5201546, 10.4260247],
                [106.5202863, 10.4265207],
                [106.5204144, 10.4268632],
                [106.5206291, 10.4272607],
                [106.5209142, 10.4276901],
                [106.5212733, 10.4281231],
                [106.5216612, 10.4284461],
                [106.5222366, 10.4288856],
                [106.5225992, 10.4292422],
                [106.5229425, 10.42962],
                [106.5234296, 10.4302636],
                [106.5238423, 10.430742],
                [106.524271, 10.4311059],
                [106.5247762, 10.4314798],
                [106.5253657, 10.4317935],
                [106.5259577, 10.4320545],
                [106.5265982, 10.4323005],
                [106.5276699, 10.4326844],
                [106.5284408, 10.4332082],
                [106.5287651, 10.4334104],
                [106.529029, 10.4335391],
                [106.529249, 10.4335749],
                [106.5294386, 10.4335361],
                [106.5296222, 10.4334287],
                [106.5298102, 10.4332407],
                [106.5304478, 10.4322972],
                [106.5311873, 10.4313631],
                [106.5323638, 10.4302522],
                [106.5327788, 10.4297908],
                [106.5331541, 10.4292798],
                [106.5338047, 10.4282691],
                [106.5343691, 10.4272663],
                [106.5348971, 10.4259233],
                [106.5354494, 10.4244311],
                [106.5364326, 10.4219957],
                [106.5367125, 10.4213151],
                [106.5370372, 10.4208227],
                [106.5376259, 10.4202288],
                [106.5383411, 10.4209125],
                [106.5388003, 10.4214654],
                [106.5391736, 10.4220859],
                [106.5400534, 10.4238712],
                [106.5405108, 10.424671],
                [106.5412426, 10.4258494],
                [106.5419773, 10.4268916],
                [106.5427439, 10.4277002],
                [106.5435423, 10.4284139],
                [106.5442194, 10.4288687],
                [106.5450372, 10.4293108],
                [106.5453693, 10.4294624],
                [106.5457142, 10.4295381],
                [106.5461421, 10.4295444],
                [106.5465381, 10.4294305],
                [106.5468702, 10.4291906],
                [106.5471128, 10.4289377],
                [106.5472278, 10.4287229],
                [106.5473683, 10.4283312],
                [106.5475049, 10.4278932],
                [106.5477705, 10.4266406],
                [106.547912, 10.4261295],
                [106.5481399, 10.4257001],
                [106.5484557, 10.4253791],
                [106.5488227, 10.4251259],
                [106.5492854, 10.4249249],
                [106.5498203, 10.4248174],
                [106.5503821, 10.4248057],
                [106.5510999, 10.4249],
                [106.5534038, 10.4253547],
                [106.5542209, 10.4254501],
                [106.55502, 10.4255087],
                [106.5557704, 10.4255102],
                [106.5564145, 10.4254631],
                [106.5569688, 10.425359],
                [106.5574847, 10.4251945],
                [106.5580185, 10.4248447],
                [106.5584308, 10.4244403],
                [106.5587223, 10.4240446],
                [106.5589241, 10.4236169],
                [106.5590768, 10.423001],
                [106.5590799, 10.4221843],
                [106.5590161, 10.4214709],
                [106.5580203, 10.4175015],
                [106.5578925, 10.4168824],
                [106.5578093, 10.416358],
                [106.5577581, 10.4154357],
                [106.5577451, 10.4150945],
                [106.5577451, 10.4147787],
                [106.5578153, 10.4141659],
                [106.5578536, 10.4140269],
                [106.5579175, 10.4138248],
                [106.5580196, 10.4136226],
                [106.558224, 10.4133319],
                [106.5583517, 10.4132308],
                [106.5584922, 10.4131297],
                [106.5587092, 10.4130538],
                [106.5591181, 10.4130032],
                [106.559335, 10.412978],
                [106.5599611, 10.4129778],
                [106.5605232, 10.4130914],
                [106.5609959, 10.4131924],
                [106.561922, 10.4135144],
                [106.5628673, 10.4139186],
                [106.5638382, 10.4143606],
                [106.5643493, 10.4145753],
                [106.5649816, 10.4147331],
                [106.5656971, 10.4148213],
                [106.5661952, 10.4148339],
                [106.5666678, 10.4148339],
                [106.5672555, 10.4148084],
                [106.5676686, 10.4147279],
                [106.5680452, 10.4145912],
                [106.5684404, 10.4143901],
                [106.5689082, 10.4139949],
                [106.5701944, 10.4127231],
                [106.5705237, 10.4124168],
                [106.5709164, 10.4122529],
                [106.5712931, 10.4121961],
                [106.5716787, 10.4121929],
                [106.5720932, 10.4122796],
                [106.5725148, 10.4124752],
                [106.572965, 10.4127856],
                [106.5731379, 10.4129747],
                [106.5734442, 10.4133099],
                [106.5737508, 10.4136636],
                [106.5739552, 10.4138657],
                [106.5741469, 10.4140172],
                [106.574364, 10.4141435],
                [106.5748367, 10.4143455],
                [106.5751817, 10.4144466],
                [106.575973, 10.4145449],
                [106.5768614, 10.4145661],
                [106.5775896, 10.4146165],
                [106.57819, 10.4146543],
                [106.5786756, 10.4147552],
                [106.5790459, 10.4148689],
                [106.57987, 10.4152161],
                [106.5806492, 10.4155698],
                [106.581352, 10.4158854],
                [106.5820928, 10.416239],
                [106.5835813, 10.4169398],
                [106.5843989, 10.4172429],
                [106.584961, 10.417407],
                [106.5853571, 10.4174826],
                [106.5865897, 10.4177414],
                [106.5872924, 10.4178675],
                [106.5879439, 10.4180442],
                [106.588391, 10.4181831],
                [106.5888318, 10.4183788],
                [106.589049, 10.418543],
                [106.5891768, 10.4186946],
                [106.5892407, 10.4188841],
                [106.5892664, 10.4191115],
                [106.5892664, 10.4193516],
                [106.5892027, 10.4195411],
                [106.589075, 10.4198444],
                [106.5886281, 10.4205268],
                [106.588264, 10.4211397],
                [106.5879704, 10.421582],
                [106.5877917, 10.4218347],
                [106.587669, 10.4221901],
                [106.5875924, 10.4225571],
                [106.5876275, 10.4235522],
                [106.5876326, 10.4243314],
                [106.5876023, 10.4250263],
                [106.5873828, 10.4259006],
                [106.5859662, 10.427729],
                [106.5847843, 10.4296894],
                [106.5843139, 10.431166],
                [106.5840615, 10.4323904],
                [106.5841117, 10.4333495],
                [106.5843683, 10.434196],
                [106.5850081, 10.4352054],
                [106.5863386, 10.4364964],
                [106.5882965, 10.4381083],
                [106.5899473, 10.4369955],
                [106.5914852, 10.4364224],
                [106.5932207, 10.4361071],
                [106.5951831, 10.4362035],
                [106.5972776, 10.436514],
                [106.5994376, 10.4372133],
                [106.6017049, 10.4384217],
                [106.6038249, 10.4398161],
                [106.6063521, 10.4416714],
                [106.608612, 10.4434308],
                [106.6102885, 10.4446514],
                [106.6129299, 10.4460681],
                [106.6162814, 10.4477174],
                [106.6190134, 10.4488999],
                [106.6216719, 10.4497586],
                [106.6233471, 10.4502233],
                [106.6253686, 10.4510652],
                [106.6274817, 10.4521769],
                [106.6278075, 10.4524309],
                [106.6292315, 10.4535413],
                [106.6308363, 10.4552297],
                [106.6317852, 10.4565236],
                [106.6327708, 10.4579614],
                [106.633063, 10.4585008],
                [106.6333006, 10.4590222],
                [106.6337072, 10.4607482],
                [106.6337412, 10.460893],
                [106.634257, 10.4639516],
                [106.6348818, 10.4669378],
                [106.6350847, 10.4682872],
                [106.6353076, 10.4704464],
                [106.6357108, 10.4718853],
                [106.6360778, 10.4733963],
                [106.6369923, 10.4756261],
                [106.6381439, 10.4780895],
                [106.6392033, 10.4799231],
                [106.640662, 10.4812879],
                [106.6430675, 10.4828308],
                [106.6454715, 10.4836538],
                [106.6474741, 10.4839377],
                [106.6496395, 10.4838073],
                [106.651568, 10.4835154],
                [106.6536407, 10.4825393],
                [106.6557479, 10.4806632],
                [106.6576348, 10.4779238],
                [106.6587578, 10.4753659],
                [106.6594257, 10.472791],
                [106.6602553, 10.4692618],
                [106.6605413, 10.4668137],
                [106.6601874, 10.462981],
                [106.6598223, 10.4538213],
                [106.6598164, 10.4510138],
                [106.6598864, 10.4497178],
                [106.660482, 10.447359],
                [106.6620034, 10.4439725],
                [106.6633096, 10.4420621],
                [106.6644357, 10.4410878],
                [106.6659806, 10.4402388],
                [106.6675077, 10.4395517],
                [106.6685989, 10.4392614],
                [106.6695811, 10.4390794],
                [106.6706992, 10.4387968],
                [106.671687, 10.4387475],
                [106.6729554, 10.4386841],
                [106.6747385, 10.4386443],
                [106.6771136, 10.4386676],
                [106.6776963, 10.4388103],
                [106.6797535, 10.4392558],
                [106.6823392, 10.4401142],
                [106.6856551, 10.4419788],
                [106.6877706, 10.4440619],
                [106.6887069, 10.4452138],
                [106.6894857, 10.4461819],
                [106.6900703, 10.4471885],
                [106.6919511, 10.4501539],
                [106.6938885, 10.454001],
                [106.6947656, 10.4555827],
                [106.6949297, 10.4558678],
                [106.6962453, 10.4581532],
                [106.6978531, 10.4609931],
                [106.6995884, 10.4639229],
                [106.7023634, 10.4678761],
                [106.7051741, 10.4714692],
                [106.705977, 10.4724393],
                [106.7090237, 10.4757258],
                [106.7141469, 10.4798717],
                [106.716152, 10.481235],
                [106.7180848, 10.4828503],
                [106.7201642, 10.4848612],
                [106.7225361, 10.4874834],
                [106.7250525, 10.4896013],
                [106.7298844, 10.4934595],
                [106.7452324, 10.4978311],
                [106.7455304, 10.5033889],
                [106.7468118, 10.5153662],
                [106.747241, 10.5280244],
                [106.7434274, 10.553281],
                [106.7433503, 10.5536451],
                [106.7430803, 10.5549196],
                [106.7430324, 10.5551096],
                [106.7423493, 10.558386],
                [106.7420092, 10.561775],
                [106.741955, 10.5628074],
                [106.7418966, 10.5639214],
                [106.7421276, 10.5669712],
                [106.7425864, 10.569343],
                [106.7431745, 10.5709969],
                [106.7438371, 10.5723589],
                [106.7445393, 10.5742958],
                [106.745422, 10.5759802],
                [106.7457147, 10.5762661],
                [106.7457018, 10.5762762],
                [106.7447004, 10.5770623],
                [106.7431094, 10.5782964],
                [106.7425599, 10.5786011],
                [106.7424982, 10.5786407],
                [106.742372, 10.578711],
                [106.7422456, 10.5787753],
                [106.7421101, 10.5788364],
                [106.7419636, 10.5789013],
                [106.7417552, 10.5789864],
                [106.7412316, 10.5790717],
                [106.7406599, 10.5790984],
                [106.7404136, 10.5790776],
                [106.7401355, 10.5790542],
                [106.7394196, 10.578858],
                [106.7386442, 10.5786624],
                [106.7380474, 10.5784538],
                [106.7378203, 10.5783021],
                [106.7376167, 10.578115],
                [106.7374243, 10.5777982],
                [106.737196, 10.5774465],
                [106.7369921, 10.5772005],
                [106.7367652, 10.5770841],
                [106.7363123, 10.577063],
                [106.7360268, 10.5771353],
                [106.7355638, 10.5774085],
                [106.7352444, 10.5778103],
                [106.7350676, 10.5781525],
                [106.7350098, 10.5784705],
                [106.7350589, 10.5787291],
                [106.7352749, 10.5789986],
                [106.7355738, 10.579197],
                [106.736135, 10.5794292],
                [106.7366368, 10.5796971],
                [106.7370201, 10.5800598],
                [106.7373797, 10.5804697],
                [106.7375832, 10.5806333],
                [106.7378819, 10.5807846],
                [106.7382878, 10.5809589],
                [106.7386703, 10.5811803],
                [106.7389339, 10.5814495],
                [106.7391621, 10.5817778],
                [106.7393666, 10.5821414],
                [106.7394519, 10.5824704],
                [106.7395014, 10.5827996],
                [106.7395159, 10.5832702],
                [106.7394004, 10.5839063],
                [106.7392715, 10.5842953],
                [106.7390244, 10.5848145],
                [106.7388237, 10.5851568],
                [106.7387389, 10.5852702],
                [106.7387142, 10.5852969],
                [106.7384979, 10.585511],
                [106.738221, 10.5857566],
                [106.737921, 10.5859565],
                [106.7375229, 10.5861508],
                [106.7371421, 10.586305],
                [106.736842, 10.5863966],
                [106.7365419, 10.5864482],
                [106.7362014, 10.5864827],
                [106.7359705, 10.5864771],
                [106.7357858, 10.5864431],
                [106.7355549, 10.586369],
                [106.7354335, 10.5862778],
                [106.7352949, 10.586141],
                [106.7350753, 10.5858103],
                [106.7348498, 10.5854283],
                [106.7347516, 10.5852228],
                [106.7348055, 10.5849142],
                [106.7348533, 10.5846796],
                [106.7348608, 10.5845582],
                [106.7348683, 10.5844208],
                [106.734851, 10.5842674],
                [106.7347929, 10.5841141],
                [106.7346682, 10.5837753],
                [106.7346509, 10.5836218],
                [106.7346503, 10.5835005],
                [106.7346902, 10.5833225],
                [106.734771, 10.5831281],
                [106.7348761, 10.5829173],
                [106.7349079, 10.5827555],
                [106.7349072, 10.58261],
                [106.7348405, 10.5824084],
                [106.734799, 10.5823035],
                [106.7347, 10.5821665],
                [106.7345765, 10.5820218],
                [106.7343052, 10.581797],
                [106.7340342, 10.5816288],
                [106.733788, 10.5815089],
                [106.7335992, 10.5813887],
                [106.7334431, 10.5812682],
                [106.7333031, 10.5811074],
                [106.7331959, 10.5809706],
                [106.7331131, 10.5808012],
                [106.7330303, 10.58064],
                [106.7329231, 10.580479],
                [106.7328242, 10.5803583],
                [106.7327542, 10.5802693],
                [106.7327506, 10.5802724],
                [106.7326968, 10.5802726],
                [106.7325891, 10.5802643],
                [106.7324901, 10.5802206],
                [106.7322921, 10.5801153],
                [106.7320312, 10.5799839],
                [106.7317615, 10.5798967],
                [106.7312581, 10.5797488],
                [106.7309708, 10.5797061],
                [106.7307286, 10.5797162],
                [106.7305314, 10.5797616],
                [106.7302067, 10.5797163],
                [106.7301901, 10.5797103],
                [106.7300196, 10.5797113],
                [106.7297511, 10.5798102],
                [106.7295002, 10.5798914],
                [106.7292671, 10.5799281],
                [106.729016, 10.579965],
                [106.7288636, 10.5799835],
                [106.7287025, 10.5800465],
                [106.7286221, 10.5801267],
                [106.7285244, 10.5802957],
                [106.7284535, 10.5804378],
                [106.7283828, 10.580651],
                [106.7283211, 10.5808463],
                [106.7282329, 10.5811127],
                [106.7280732, 10.5814327],
                [106.7279667, 10.5816371],
                [106.7277083, 10.5819665],
                [106.7275125, 10.5822512],
                [106.7274594, 10.5823845],
                [106.7274335, 10.5825797],
                [106.7274255, 10.5827392],
                [106.727408, 10.582828],
                [106.7273638, 10.5829435],
                [106.727257, 10.5831036],
                [106.7271508, 10.5833524],
                [106.7270172, 10.5835392],
                [106.7268302, 10.5837974],
                [106.7267792, 10.5839197],
                [106.7267166, 10.5840995],
                [106.7266632, 10.5842973],
                [106.726646, 10.584468],
                [106.726656, 10.5846473],
                [106.7266931, 10.5847639],
                [106.7267846, 10.58488],
                [106.7269308, 10.5850319],
                [106.7270041, 10.5851391],
                [106.7270593, 10.5852644],
                [106.7270782, 10.58539],
                [106.7270696, 10.5854799],
                [106.7270246, 10.5855519],
                [106.7269521, 10.5855882],
                [106.7268522, 10.5855887],
                [106.7267156, 10.5855536],
                [106.7265699, 10.5854826],
                [106.7263877, 10.5854118],
                [106.7261873, 10.5853141],
                [106.7259689, 10.5852436],
                [106.7258143, 10.5852264],
                [106.7256144, 10.5852275],
                [106.7254419, 10.5852554],
                [106.7253151, 10.5853189],
                [106.725134, 10.5854276],
                [106.7249168, 10.5855904],
                [106.7247541, 10.5857438],
                [106.7246368, 10.5858881],
                [106.724538, 10.586086],
                [106.72443, 10.5862842],
                [106.7243495, 10.586509],
                [106.7242604, 10.5868236],
                [106.724207, 10.5870034],
                [106.7241711, 10.5871023],
                [106.7241574, 10.5871275],
                [106.7241082, 10.5872194],
                [106.7240362, 10.5873275],
                [106.7239278, 10.5874627],
                [106.7238196, 10.587589],
                [106.7236477, 10.5877335],
                [106.7235303, 10.5878598],
                [106.723413, 10.588004],
                [106.723314, 10.5881751],
                [106.7232422, 10.5883371],
                [106.7232157, 10.5884629],
                [106.7232074, 10.5886065],
                [106.7231995, 10.58884],
                [106.7232096, 10.5890104],
                [106.7232472, 10.5892256],
                [106.7233027, 10.5894048],
                [106.7233817, 10.5896318],
                [106.7235193, 10.5899626],
                [106.7236066, 10.5902513],
                [106.7236507, 10.5904909],
                [106.7236587, 10.5906319],
                [106.7236594, 10.5907518],
                [106.7236404, 10.5908712],
                [106.7235966, 10.5912893],
                [106.7235735, 10.5915103],
                [106.723575, 10.5917802],
                [106.7235787, 10.5924518],
                [106.7235586, 10.5931111],
                [106.72337, 10.5934888],
                [106.7228727, 10.5940564],
                [106.7220182, 10.5946732],
                [106.7211868, 10.5951721],
                [106.7206412, 10.5956223],
                [106.7203811, 10.5960004],
                [106.7202879, 10.5963776],
                [106.7202664, 10.5967779],
                [106.7203649, 10.5973659],
                [106.7208222, 10.5981637],
                [106.7214215, 10.5987961],
                [106.7217826, 10.5994298],
                [106.7218323, 10.5998061],
                [106.7217392, 10.6002068],
                [106.7210282, 10.6009169],
                [106.7203887, 10.6016266],
                [106.7195144, 10.6029732],
                [106.7191153, 10.6040818],
                [106.718954, 10.6050714],
                [106.7189372, 10.6059224],
                [106.7189999, 10.6061174],
                [106.7190132, 10.606159],
                [106.7191143, 10.6062586],
                [106.7192828, 10.6063805],
                [106.7195972, 10.6065134],
                [106.7199565, 10.6066129],
                [106.7205852, 10.6066457],
                [106.7211577, 10.6066343],
                [106.7214946, 10.6066895],
                [106.7217753, 10.6067669],
                [106.722, 10.606911],
                [106.7222246, 10.607188],
                [106.7223305, 10.607567],
                [106.7223464, 10.6077045],
                [106.7223489, 10.6081749],
                [106.7223657, 10.6084176],
                [106.7225217, 10.6088417],
                [106.7226624, 10.609281],
                [106.7228198, 10.6099631],
                [106.7229147, 10.610433],
                [106.7231015, 10.610857],
                [106.7232717, 10.6110684],
                [106.7234887, 10.6114163],
                [106.7236747, 10.6117188],
                [106.7238446, 10.6118696],
                [106.7239525, 10.6119298],
                [106.7240897, 10.6119105],
                [106.724293, 10.6117878],
                [106.7244481, 10.6116992],
                [106.7246584, 10.6115773],
                [106.7249408, 10.6114332],
                [106.7251402, 10.6113499],
                [106.7252843, 10.6112996],
                [106.7254342, 10.6112879],
                [106.7256121, 10.6113033],
                [106.7258677, 10.6113404],
                [106.7260956, 10.6113774],
                [106.72629, 10.6113874],
                [106.7264066, 10.6113703],
                [106.7264952, 10.6113314],
                [106.7265892, 10.6112651],
                [106.7266884, 10.6111384],
                [106.7267594, 10.610999],
                [106.7269009, 10.6107512],
                [106.7269709, 10.6104919],
                [106.7271596, 10.6101497],
                [106.7272896, 10.6099844],
                [106.7278345, 10.6094401],
                [106.7280715, 10.6092154],
                [106.7282019, 10.6091087],
                [106.7282967, 10.6090376],
                [106.7283681, 10.6090137],
                [106.7285229, 10.6090246],
                [106.728702, 10.6090824],
                [106.7288575, 10.6091992],
                [106.7290111, 10.6093886],
                [106.7292341, 10.6092769],
                [106.7295953, 10.6091472],
                [106.730079, 10.6089657],
                [106.7303178, 10.6089134],
                [106.7306212, 10.6088543],
                [106.730886, 10.6088273],
                [106.7310865, 10.6088389],
                [106.7312547, 10.6088699],
                [106.7314036, 10.6089201],
                [106.7316046, 10.6090276],
                [106.7317408, 10.6091035],
                [106.7320783, 10.6093569],
                [106.7327945, 10.6099603],
                [106.7325973, 10.6103289],
                [106.7323958, 10.6107527],
                [106.7321739, 10.6111566],
                [106.7319716, 10.6114295],
                [106.7318297, 10.6115813],
                [106.7317495, 10.6118133],
                [106.7317399, 10.611924],
                [106.7316897, 10.6120552],
                [106.7316087, 10.6121361],
                [106.731446, 10.6122075],
                [106.7312832, 10.6122586],
                [106.7310392, 10.6123506],
                [106.7307645, 10.6124428],
                [106.7305913, 10.6124538],
                [106.7303975, 10.6124347],
                [106.7301932, 10.6123453],
                [106.7297738, 10.6120657],
                [106.7295284, 10.6119161],
                [106.7294059, 10.6118764],
                [106.7292736, 10.6119074],
                [106.7291316, 10.6120491],
                [106.7288489, 10.6124936],
                [106.7285665, 10.6130286],
                [106.7284463, 10.6134218],
                [106.7283167, 10.6139157],
                [106.7281761, 10.614289],
                [106.7280254, 10.6146723],
                [106.7278542, 10.6150456],
                [106.7276931, 10.6153988],
                [106.7276029, 10.6156711],
                [106.7275737, 10.6159128],
                [106.7275447, 10.6161848],
                [106.727556, 10.6163861],
                [106.7275976, 10.6165368],
                [106.72768, 10.6166874],
                [106.7277826, 10.6168076],
                [106.7278847, 10.6168472],
                [106.7279559, 10.6168268],
                [106.7281386, 10.6166848],
                [106.7283008, 10.616543],
                [106.728463, 10.6163811],
                [106.7286354, 10.6162391],
                [106.7287575, 10.6162083],
                [106.7289107, 10.6162477],
                [106.7290643, 10.6163878],
                [106.7292082, 10.6165984],
                [106.7293116, 10.6168797],
                [106.7294045, 10.6170906],
                [106.7295175, 10.6172409],
                [106.729692, 10.6174613],
                [106.729887, 10.6177019],
                [106.7299495, 10.617933],
                [106.7299609, 10.6181645],
                [106.7299435, 10.618688],
                [106.7299152, 10.619111],
                [106.7298458, 10.6194335],
                [106.7297966, 10.6197559],
                [106.7297979, 10.6199873],
                [106.7298501, 10.6202085],
                [106.729943, 10.6204193],
                [106.7300869, 10.6206198],
                [106.7302816, 10.62082],
                [106.730466, 10.6209902],
                [106.7307735, 10.6213105],
                [106.7311839, 10.6217915],
                [106.731533, 10.6222426],
                [106.7317184, 10.6225837],
                [106.7317806, 10.6227848],
                [106.7317917, 10.6229457],
                [106.7317523, 10.6231975],
                [106.7316617, 10.6233822],
                [106.7315316, 10.6235697],
                [106.731399, 10.6237009],
                [106.7312096, 10.6237759],
                [106.7310012, 10.6237948],
                [106.730508, 10.6237802],
                [106.7299621, 10.6237919],
                [106.7294523, 10.6238567],
                [106.7289965, 10.6239653],
                [106.7287376, 10.6240728],
                [106.7283726, 10.6244284],
                [106.7283024, 10.6246674],
                [106.7283315, 10.6250651],
                [106.7286526, 10.62646],
                [106.7288088, 10.627184],
                [106.7288736, 10.6275549],
                [106.7289655, 10.6279787],
                [106.7290657, 10.6282855],
                [106.7292776, 10.6285698],
                [106.729767, 10.6287315],
                [106.730075, 10.6286636],
                [106.7303291, 10.628543],
                [106.7307431, 10.6282494],
                [106.7311969, 10.6278893],
                [106.7314375, 10.6277556],
                [106.7319335, 10.627753],
                [106.7322158, 10.6278837],
                [106.7324987, 10.6281073],
                [106.7329163, 10.6284625],
                [106.7331747, 10.6287402],
                [106.7331861, 10.6287524],
                [106.7332404, 10.6288844],
                [106.7331876, 10.6290304],
                [106.7330147, 10.6292697],
                [106.7328161, 10.6297077],
                [106.7326848, 10.6301984],
                [106.7325265, 10.6306628],
                [106.7323673, 10.6309683],
                [106.7313272, 10.6327156],
                [106.7312607, 10.6328424],
                [106.7312185, 10.6329492],
                [106.7311764, 10.6330838],
                [106.7311483, 10.6332186],
                [106.7311404, 10.6332916],
                [106.7311543, 10.6332859],
                [106.7311871, 10.6332812],
                [106.7312317, 10.6332859],
                [106.7312551, 10.6332929],
                [106.7312834, 10.6333113],
                [106.7313075, 10.6333284],
                [106.7313606, 10.6333339],
                [106.7311956, 10.633955],
                [106.731205, 10.6340997],
                [106.7311004, 10.6345769],
                [106.7307295, 10.6353735],
                [106.7301703, 10.6360651],
                [106.7297702, 10.6364382],
                [106.7288073, 10.6368937],
                [106.7278439, 10.6372432],
                [106.7268535, 10.6375664],
                [106.7261317, 10.6379676],
                [106.725705, 10.6383672],
                [106.7255195, 10.6387655],
                [106.7253875, 10.6391371],
                [106.7251494, 10.639721],
                [106.7246701, 10.6403063],
                [106.7240827, 10.6407597],
                [106.7237035, 10.6412777],
                [106.7235546, 10.6417238],
                [106.7234404, 10.6423387],
                [106.7233263, 10.6432096],
                [106.7232934, 10.6435205],
                [106.7231644, 10.644393],
                [106.722928, 10.6452683],
                [106.7224484, 10.6458269],
                [106.7220206, 10.6460412],
                [106.721565, 10.6460967],
                [106.720894, 10.6459945],
                [106.7203293, 10.6457327],
                [106.719953, 10.6455759],
                [106.719363, 10.6455527],
                [106.7186937, 10.6457417],
                [106.7179719, 10.6461694],
                [106.7174114, 10.6466227],
                [106.7167182, 10.6473415],
                [106.7157034, 10.6481152],
                [106.7148484, 10.6486759],
                [106.7134586, 10.6495046],
                [106.7123094, 10.6502259],
                [106.7117476, 10.6504673],
                [106.7111849, 10.6505498],
                [106.7106482, 10.6504733],
                [106.7103793, 10.6503424],
                [106.7093853, 10.65003],
                [106.7087409, 10.6499011],
                [106.7080169, 10.6499051],
                [106.7073205, 10.6500677],
                [106.7068657, 10.6502555],
                [106.7062241, 10.6506034],
                [106.7055277, 10.6507661],
                [106.7051531, 10.650927],
                [106.7047792, 10.6511939],
                [106.7045395, 10.6514865],
                [106.704249, 10.6523091],
                [106.7040112, 10.652946],
                [106.7037444, 10.6531858],
                [106.7035568, 10.6532133],
                [106.703208, 10.6531888],
                [106.702456, 10.6529809],
                [106.7020533, 10.6529036],
                [106.7017587, 10.6529847],
                [106.7014385, 10.6532777],
                [106.7012628, 10.6534337],
                [106.7009582, 10.6537041],
                [106.7006379, 10.6539706],
                [106.7003966, 10.653972],
                [106.700074, 10.6538414],
                [106.6995354, 10.653447],
                [106.6987812, 10.652842],
                [106.6976775, 10.6520799],
                [106.6971114, 10.6515533],
                [106.6969216, 10.6511836],
                [106.6968121, 10.6507869],
                [106.6967465, 10.6504042],
                [106.6965405, 10.6501528],
                [106.6962707, 10.6498628],
                [106.6957868, 10.6496537],
                [106.6953039, 10.6496033],
                [106.6948478, 10.6495793],
                [106.6944991, 10.6495547],
                [106.6938008, 10.649373],
                [106.6928873, 10.6490602],
                [106.692162, 10.6488257],
                [106.691437, 10.6486444],
                [106.6907933, 10.6486478],
                [106.6904456, 10.6488086],
                [106.6903122, 10.6489417],
                [106.6902333, 10.6492069],
                [106.6902898, 10.6497364],
                [106.6903999, 10.6502654],
                [106.6903753, 10.6506627],
                [106.6903773, 10.6510071],
                [106.6901917, 10.6514053],
                [106.6899253, 10.6517245],
                [106.6893389, 10.6523898],
                [106.6889388, 10.6527628],
                [106.6884597, 10.6534274],
                [106.6881147, 10.6540914],
                [106.6877701, 10.6548348],
                [106.6875585, 10.6553656],
                [106.6872126, 10.6558707],
                [106.6869725, 10.6561024],
                [106.6865713, 10.6563164],
                [106.6859018, 10.6564604],
                [106.6853929, 10.6565691],
                [106.6856284, 10.6555084],
                [106.6858336, 10.6538123],
                [106.68583, 10.6531504],
                [106.6857185, 10.6523829],
                [106.6855553, 10.65196],
                [106.6853915, 10.6514312],
                [106.6850937, 10.6509296],
                [106.6841775, 10.6501136],
                [106.6826975, 10.6491945],
                [106.6817279, 10.6484318],
                [106.6812152, 10.6478519],
                [106.680703, 10.6473514],
                [106.6803778, 10.6467441],
                [106.6799714, 10.6460046],
                [106.6792711, 10.6454257],
                [106.6785185, 10.645112],
                [106.6774446, 10.6449059],
                [106.6763717, 10.6448586],
                [106.6750842, 10.6448125],
                [106.674601, 10.6447092],
                [106.6740096, 10.6444475],
                [106.6737402, 10.6442371],
                [106.6735509, 10.6439203],
                [106.6733874, 10.6434445],
                [106.6733823, 10.6425176],
                [106.6732467, 10.6422534],
                [106.6730046, 10.6421223],
                [106.6721987, 10.6418617],
                [106.6715263, 10.6414946],
                [106.6710948, 10.6410466],
                [106.670689, 10.6403867],
                [106.6701758, 10.6397009],
                [106.6696371, 10.6392799],
                [106.6691805, 10.63915],
                [106.6685368, 10.6391269],
                [106.6679206, 10.6392361],
                [106.667314, 10.6396524],
                [106.6665953, 10.6404273],
                [106.666324, 10.6401448],
                [106.6662831, 10.6399912],
                [106.6663047, 10.6398374],
                [106.6664227, 10.6395906],
                [106.6665171, 10.6393755],
                [106.666545, 10.6391372],
                [106.6664596, 10.6387884],
                [106.6661916, 10.6385226],
                [106.6660405, 10.6384323],
                [106.6659295, 10.6384574],
                [106.6658399, 10.638537],
                [106.6657917, 10.6387082],
                [106.6658093, 10.6390045],
                [106.6658255, 10.6392475],
                [106.665802, 10.6394142],
                [106.6657263, 10.6395578],
                [106.6656054, 10.6396021],
                [106.6654863, 10.6395631],
                [106.6653997, 10.6394425],
                [106.6652878, 10.639173],
                [106.6651909, 10.6390232],
                [106.6651029, 10.6389154],
                [106.6649995, 10.6388865],
                [106.6648975, 10.6389066],
                [106.6648171, 10.6389618],
                [106.6646986, 10.6390956],
                [106.6646697, 10.6392534],
                [106.664639, 10.6394361],
                [106.6645687, 10.6395301],
                [106.6644279, 10.6395567],
                [106.6641789, 10.6395283],
                [106.6640346, 10.6395496],
                [106.6638794, 10.6396471],
                [106.6636069, 10.6399078],
                [106.6634354, 10.640051],
                [106.6633378, 10.6400752],
                [106.663238, 10.6400763],
                [106.6631479, 10.6400394],
                [106.6630835, 10.6399635],
                [106.6630267, 10.6398139],
                [106.662966, 10.6395082],
                [106.6629068, 10.6393695],
                [106.6627991, 10.639289],
                [106.6626216, 10.6392726],
                [106.6624213, 10.6393546],
                [106.6621466, 10.6395798],
                [106.6619236, 10.6398467],
                [106.6618417, 10.6399034],
                [106.6617097, 10.6398915],
                [106.6614972, 10.6398721],
                [106.6612924, 10.6399094],
                [106.661044, 10.640013],
                [106.6608582, 10.6400786],
                [106.660712, 10.6400626],
                [106.6605713, 10.6400023],
                [106.6603349, 10.639848],
                [106.6601906, 10.6398143],
                [106.6600715, 10.6398587],
                [106.6599668, 10.6400325],
                [106.6598983, 10.6402222],
                [106.6599001, 10.6403547],
                [106.6600083, 10.6406105],
                [106.6600236, 10.6409817],
                [106.6600925, 10.6411397],
                [106.6601793, 10.6412425],
                [106.6604625, 10.6413805],
                [106.6607203, 10.6419874],
                [106.6605245, 10.6421095],
                [106.660317, 10.6421697],
                [106.6601131, 10.642301],
                [106.6599201, 10.642363],
                [106.6597739, 10.6424357],
                [106.6595121, 10.6424907],
                [106.6592146, 10.6426198],
                [106.6585198, 10.6430336],
                [106.6581125, 10.6431966],
                [106.6577946, 10.6432833],
                [106.6575534, 10.6433266],
                [106.6572823, 10.6433466],
                [106.6569372, 10.6433228],
                [106.6567458, 10.6432106],
                [106.6565951, 10.6428855],
                [106.656419, 10.6424441],
                [106.6562901, 10.6420446],
                [106.6563055, 10.6416734],
                [106.6563791, 10.6414268],
                [106.6565491, 10.6411837],
                [106.6567357, 10.6408512],
                [106.6568107, 10.6405952],
                [106.6568471, 10.640246],
                [106.6568453, 10.6399056],
                [106.6567668, 10.6396396],
                [106.6567172, 10.6392813],
                [106.6565977, 10.6389681],
                [106.6565033, 10.638811],
                [106.6562758, 10.6385611],
                [106.6562233, 10.6384862],
                [106.6561804, 10.6384725],
                [106.6561417, 10.6384915],
                [106.6561171, 10.6385568],
                [106.6560787, 10.6387553],
                [106.6560551, 10.6388292],
                [106.6559778, 10.6388903],
                [106.6557246, 10.6389156],
                [106.6554242, 10.6389768],
                [106.6550208, 10.6391054],
                [106.6545618, 10.6392434],
                [106.6544827, 10.639286],
                [106.6544062, 10.6393838],
                [106.6542735, 10.639454],
                [106.6541339, 10.6394695],
                [106.6539451, 10.6394395],
                [106.6538048, 10.6393642],
                [106.6535726, 10.6391109],
                [106.6534501, 10.6388877],
                [106.6534092, 10.6386294],
                [106.6533633, 10.6382858],
                [106.6533046, 10.6381504],
                [106.6531821, 10.6380451],
                [106.6530376, 10.6379781],
                [106.6529438, 10.6378948],
                [106.6528951, 10.6377192],
                [106.652888, 10.6374244],
                [106.6529674, 10.6370875],
                [106.6531658, 10.6367967],
                [106.6532849, 10.6365519],
                [106.6532713, 10.6362563],
                [106.6531631, 10.6356162],
                [106.6531138, 10.634353],
                [106.653176, 10.6341062],
                [106.653191, 10.6338911],
                [106.653455, 10.6338531],
                [106.6534867, 10.6333058],
                [106.6533429, 10.6328524],
                [106.6531433, 10.6326457],
                [106.6529545, 10.6324475],
                [106.6528386, 10.6322999],
                [106.6527271, 10.6322535],
                [106.6525897, 10.6322872],
                [106.652476, 10.6323463],
                [106.6523258, 10.6323484],
                [106.6521368, 10.632321],
                [106.6520015, 10.6323547],
                [106.6518012, 10.6324788],
                [106.6515576, 10.6326349],
                [106.6513717, 10.6326934],
                [106.6511101, 10.6327147],
                [106.6509712, 10.6326863],
                [106.6508593, 10.6326278],
                [106.6507709, 10.632626],
                [106.6505489, 10.6327165],
                [106.6503956, 10.6327236],
                [106.6499715, 10.632665],
                [106.6497189, 10.6326597],
                [106.6494772, 10.6327094],
                [106.6492642, 10.63283],
                [106.6491307, 10.6328708],
                [106.6489936, 10.6328317],
                [106.6488186, 10.6327218],
                [106.6486255, 10.6326757],
                [106.6484757, 10.6327058],
                [106.6484198, 10.632775],
                [106.6483819, 10.6329541],
                [106.6483169, 10.6330445],
                [106.6481044, 10.6331658],
                [106.6477242, 10.6335195],
                [106.6474792, 10.6337],
                [106.6470955, 10.6338759],
                [106.6468358, 10.6341508],
                [106.6467861, 10.6341632],
                [106.6465976, 10.6341428],
                [106.6461988, 10.6342395],
                [106.6461068, 10.6341056],
                [106.645722, 10.633482],
                [106.6453087, 10.6326507],
                [106.6451931, 10.6323573],
                [106.6438377, 10.6297093],
                [106.6437827, 10.6296189],
                [106.6437069, 10.6295284],
                [106.642746, 10.6285757],
                [106.6420202, 10.6278423],
                [106.6415701, 10.6273885],
                [106.6412375, 10.6269646],
                [106.6410251, 10.6266209],
                [106.6392895, 10.6271444],
                [106.6391347, 10.6269983],
                [106.6388936, 10.6274694],
                [106.6387147, 10.6272347],
                [106.6382368, 10.6268663],
                [106.6378878, 10.6265681],
                [106.6376162, 10.626416],
                [106.6373021, 10.6262847],
                [106.6368773, 10.6261848],
                [106.6366163, 10.6261818],
                [106.6362778, 10.6262452],
                [106.6358976, 10.6262954],
                [106.6355863, 10.6262527],
                [106.6353235, 10.6261223],
                [106.635178, 10.6259518],
                [106.6350606, 10.625716],
                [106.6349407, 10.6253147],
                [106.6346271, 10.6256414],
                [106.6341996, 10.6263073],
                [106.6335571, 10.6271058],
                [106.6328824, 10.6279942],
                [106.6321728, 10.6282499],
                [106.6316328, 10.628275],
                [106.6312727, 10.6280616],
                [106.6307996, 10.6275256],
                [106.6303256, 10.6267627],
                [106.6301368, 10.6264266],
                [106.6298361, 10.6269214],
                [106.6296632, 10.6272859],
                [106.6295621, 10.6275909],
                [106.6295871, 10.628189],
                [106.629663, 10.6285976],
                [106.6296624, 10.6288789],
                [106.6296789, 10.6289406],
                [106.6297287, 10.6289796],
                [106.6298286, 10.6290346],
                [106.629955, 10.6291446],
                [106.6306027, 10.629835],
                [106.6307456, 10.6300306],
                [106.630766, 10.6302011],
                [106.6307099, 10.6303391],
                [106.6305848, 10.6304745],
                [106.6302506, 10.6307078],
                [106.6301434, 10.6308131],
                [106.6300413, 10.6311542],
                [106.6300311, 10.6313347],
                [106.6299903, 10.63142],
                [106.6299035, 10.6315178],
                [106.6297555, 10.6315881],
                [106.6296305, 10.6316508],
                [106.6296785, 10.632041],
                [106.629832, 10.6329679],
                [106.6298491, 10.6330965],
                [106.6298566, 10.633242],
                [106.6297903, 10.6342603],
                [106.6293897, 10.6349538],
                [106.629356, 10.6350553],
                [106.6293401, 10.6351394],
                [106.6293385, 10.6352493],
                [106.6294331, 10.635773],
                [106.6295121, 10.6363135],
                [106.6296942, 10.6364164],
                [106.6300963, 10.6365059],
                [106.6304832, 10.6365715],
                [106.6310082, 10.6366848],
                [106.6311629, 10.636743],
                [106.6311333, 10.636913],
                [106.6310408, 10.6370919],
                [106.6310074, 10.637159],
                [106.6309771, 10.6373171],
                [106.6307798, 10.6375348],
                [106.6305628, 10.6379166],
                [106.6305079, 10.6380794],
                [106.6304754, 10.6382621],
                [106.6303581, 10.6384944],
                [106.6303311, 10.6386044],
                [106.6303581, 10.6387037],
                [106.6304285, 10.6387888],
                [106.630544, 10.6389413],
                [106.6305584, 10.6390016],
                [106.6305332, 10.6390601],
                [106.6304826, 10.6391027],
                [106.6303744, 10.6391541],
                [106.6302254, 10.6392973],
                [106.6301572, 10.6393405],
                [106.630101, 10.6393435],
                [106.6300039, 10.6392854],
                [106.6299326, 10.6392764],
                [106.6296754, 10.6393398],
                [106.629897, 10.6403018],
                [106.6295293, 10.6403921],
                [106.6297181, 10.6409577],
                [106.6297295, 10.6411321],
                [106.6296574, 10.6414698],
                [106.6295545, 10.6418067],
                [106.629483, 10.64203],
                [106.6291929, 10.6419954],
                [106.6285812, 10.6419913],
                [106.6284295, 10.6419749],
                [106.6283344, 10.6419452],
                [106.6281483, 10.6418075],
                [106.6275792, 10.6421382],
                [106.6271793, 10.6423959],
                [106.6263003, 10.640937],
                [106.6261724, 10.6405754],
                [106.6261098, 10.640349],
                [106.625805, 10.6397168],
                [106.625249, 10.6396544],
                [106.6251991, 10.6397129],
                [106.6251307, 10.6397548],
                [106.6250197, 10.6397738],
                [106.6247857, 10.6398015],
                [106.6244881, 10.6398568],
                [106.6243896, 10.639648],
                [106.624335, 10.6395725],
                [106.6241558, 10.6394428],
                [106.6241101, 10.6394356],
                [106.6240628, 10.639447],
                [106.6237668, 10.6396223],
                [106.6237183, 10.6396887],
                [106.6233975, 10.6399703],
                [106.6230637, 10.6401522],
                [106.6229023, 10.6403277],
                [106.6227943, 10.6404098],
                [106.6224388, 10.6405853],
                [106.6222466, 10.6406435],
                [106.6220611, 10.6406686],
                [106.6218814, 10.6407452],
                [106.6215431, 10.6408111],
                [106.6212058, 10.6409296],
                [106.6213834, 10.6415169],
                [106.6216929, 10.6414104],
                [106.6217452, 10.6415248],
                [106.621942, 10.6414814],
                [106.6221889, 10.641505],
                [106.6225062, 10.6425918],
                [106.6221253, 10.6426575],
                [106.6220632, 10.6431575],
                [106.6215383, 10.6432687],
                [106.6217719, 10.6441002],
                [106.6218987, 10.6440571],
                [106.6220105, 10.6440344],
                [106.62213, 10.6440259],
                [106.6222484, 10.6440295],
                [106.6223508, 10.6440497],
                [106.6224293, 10.6440883],
                [106.6224976, 10.6441336],
                [106.6233554, 10.6440829],
                [106.6234376, 10.6444323],
                [106.6243293, 10.6444613],
                [106.6244225, 10.6450358],
                [106.6244177, 10.6452112],
                [106.6243823, 10.6453693],
                [106.6243244, 10.6455306],
                [106.6242504, 10.6456302],
                [106.624134, 10.6457306],
                [106.6240284, 10.6458373],
                [106.6239309, 10.6459765],
                [106.6238926, 10.6460911],
                [106.6239118, 10.6463356],
                [106.6239614, 10.6464561],
                [106.6240839, 10.6465574],
                [106.624712, 10.6470826],
                [106.6245614, 10.6474547],
                [106.6240334, 10.647406],
                [106.6240585, 10.6476632],
                [106.6236104, 10.6477282],
                [106.6230706, 10.647921],
                [106.6226675, 10.6480087],
                [106.6223239, 10.6481443],
                [106.6223469, 10.6483823],
                [106.6220935, 10.6484548],
                [106.6215741, 10.6485667],
                [106.6211348, 10.6487236],
                [106.6207792, 10.6487789],
                [106.6198603, 10.648899],
                [106.6189639, 10.6490133],
                [106.618984, 10.6496845],
                [106.6184884, 10.6497278],
                [106.6180516, 10.6498385],
                [106.6180154, 10.6501043],
                [106.6173422, 10.6501711],
                [106.6173612, 10.6503572],
                [106.6173612, 10.6505141],
                [106.6172854, 10.650792],
                [106.6172732, 10.6509276],
                [106.617395, 10.6510153],
                [106.6176128, 10.6511576],
                [106.6177305, 10.6512254],
                [106.6177575, 10.6512745],
                [106.6177562, 10.6516494],
                [106.6177159, 10.6519567],
                [106.6175877, 10.6519007],
                [106.6174662, 10.65188],
                [106.6173467, 10.6518969],
                [106.6172338, 10.6519251],
                [106.6171822, 10.6519702],
                [106.617141, 10.6520482],
                [106.6170033, 10.6520332],
                [106.6169172, 10.6520699],
                [106.6167909, 10.6521968],
                [106.6167431, 10.6523608],
                [106.6167226, 10.6525509],
                [106.6167453, 10.6529354],
                [106.6167353, 10.6533377],
                [106.6163857, 10.6531765],
                [106.6160647, 10.6532955],
                [106.6158426, 10.6537853],
                [106.6155775, 10.6536691],
                [106.6154639, 10.6538729],
                [106.615655, 10.6540319],
                [106.6156273, 10.6541118],
                [106.6149266, 10.653872],
                [106.6145946, 10.6537296],
                [106.6144817, 10.6537645],
                [106.6143992, 10.6538355],
                [106.6143015, 10.6538219],
                [106.6141904, 10.6538525],
                [106.6140141, 10.654122],
                [106.6137798, 10.6545106],
                [106.613445, 10.6543595],
                [106.6133426, 10.6546613],
                [106.6133039, 10.6547373],
                [106.6132164, 10.6548245],
                [106.6128194, 10.6550257],
                [106.6127072, 10.6551163],
                [106.6126003, 10.6552527],
                [106.6123175, 10.6558074],
                [106.6117696, 10.6560561],
                [106.6115279, 10.6561105],
                [106.6114274, 10.6565987],
                [106.6108306, 10.6568979],
                [106.6105133, 10.6566013],
                [106.6101374, 10.6568697],
                [106.6097224, 10.6572048],
                [106.6095519, 10.6573345],
                [106.6092494, 10.6574768],
                [106.608902, 10.6575716],
                [106.607428, 10.6579333],
                [106.6069861, 10.6580456],
                [106.6059856, 10.6580963],
                [106.6057924, 10.6581396],
                [106.6056183, 10.658226],
                [106.6055241, 10.6582743],
                [106.6054934, 10.6583146],
                [106.6054968, 10.6583537],
                [106.6055583, 10.6584756],
                [106.605573, 10.6585426],
                [106.6055605, 10.6585985],
                [106.6054877, 10.6586611],
                [106.6053836, 10.6586959],
                [106.6049824, 10.658224],
                [106.6047259, 10.6579783],
                [106.6046237, 10.6578162],
                [106.6045537, 10.6576523],
                [106.6041939, 10.6570103],
                [106.604014, 10.6566229],
                [106.6039706, 10.6564749],
                [106.6039285, 10.6559997],
                [106.6039106, 10.6558818],
                [106.6038354, 10.6557013],
                [106.6034921, 10.655137],
                [106.6032408, 10.6547621],
                [106.603182, 10.6546263],
                [106.6029423, 10.6538852],
                [106.6029119, 10.6537331],
                [106.6028907, 10.6535705],
                [106.6028497, 10.6534363],
                [106.6027481, 10.6532842],
                [106.6025872, 10.6531277],
                [106.6024401, 10.6530248],
                [106.602302, 10.6528787],
                [106.602173, 10.6527012],
                [106.6020896, 10.6525342],
                [106.6019211, 10.6523284],
                [106.6018589, 10.6521719],
                [106.6018347, 10.6520198],
                [106.6018316, 10.6516857],
                [106.6018028, 10.651465],
                [106.6017542, 10.6512846],
                [106.601648, 10.65114],
                [106.6015009, 10.6510311],
                [106.6013218, 10.6509745],
                [106.6010851, 10.6509327],
                [106.6009531, 10.650882],
                [106.6006523, 10.6506916],
                [106.6004849, 10.6508482],
                [106.6004243, 10.6509376],
                [106.6004015, 10.6510405],
                [106.6003742, 10.6511807],
                [106.600318, 10.651288],
                [106.6002953, 10.6513686],
                [106.6003029, 10.6514476],
                [106.6003575, 10.6515386],
                [106.6003712, 10.6515922],
                [106.6003332, 10.6517399],
                [106.6003454, 10.6518935],
                [106.6003105, 10.6520023],
                [106.6002194, 10.6521052],
                [106.600007, 10.6522379],
                [106.5997662, 10.652228],
                [106.599659, 10.6522581],
                [106.5996118, 10.6523346],
                [106.5996118, 10.6525942],
                [106.5993451, 10.6525879],
                [106.5990925, 10.6525164],
                [106.5988773, 10.6526866],
                [106.5984494, 10.6528953],
                [106.5980397, 10.6530355],
                [106.5974935, 10.6531399],
                [106.5975102, 10.6533844],
                [106.5952297, 10.6534997],
                [106.5952794, 10.6531131],
                [106.5955505, 10.6529725],
                [106.5955006, 10.6526161],
                [106.5954791, 10.652325],
                [106.595565, 10.6518527],
                [106.5956508, 10.6510809],
                [106.595771, 10.6509585],
                [106.5958353, 10.6506886],
                [106.5959254, 10.6503934],
                [106.5950103, 10.6503684],
                [106.5949769, 10.6506025],
                [106.5933278, 10.6504127],
                [106.5927656, 10.6504001],
                [106.5923216, 10.6502391],
                [106.591663, 10.6501114],
                [106.5914942, 10.6511666],
                [106.5909105, 10.6510949],
                [106.5907354, 10.6518203],
                [106.5905312, 10.6527818],
                [106.5887249, 10.6526091],
                [106.5869821, 10.6524906],
                [106.586855, 10.6532116],
                [106.5865062, 10.6531845],
                [106.5861658, 10.6532287],
                [106.5856912, 10.6533281],
                [106.5855467, 10.653364],
                [106.5849847, 10.6534705],
                [106.5845432, 10.6536181],
                [106.5843474, 10.6536585],
                [106.5841286, 10.6536567],
                [106.5831997, 10.6553672],
                [106.5814854, 10.6553786],
                [106.5811314, 10.6554418],
                [106.58065, 10.6554804],
                [106.5806525, 10.6559387],
                [106.5806321, 10.6560101],
                [106.5805785, 10.6560603],
                [106.5805042, 10.6560978],
                [106.5798284, 10.6560951],
                [106.5797761, 10.6560809],
                [106.5797445, 10.6560322],
                [106.579651, 10.6556802],
                [106.5794043, 10.655075],
                [106.5793893, 10.6549274],
                [106.5791339, 10.6547819],
                [106.5788075, 10.6544953],
                [106.5788246, 10.6544057],
                [106.5784551, 10.6539649],
                [106.5784046, 10.6538408],
                [106.5782548, 10.653644],
                [106.5779319, 10.6533159],
                [106.5779066, 10.6532379],
                [106.5780347, 10.6528442],
                [106.5780654, 10.6526492],
                [106.5780618, 10.6523796],
                [106.5780058, 10.651892],
                [106.5795486, 10.6519005],
                [106.5793937, 10.6512942],
                [106.5794027, 10.6511967],
                [106.5794587, 10.6510335],
                [106.5794731, 10.6508243],
                [106.5793738, 10.6503774],
                [106.5791718, 10.6500139],
                [106.5789715, 10.6496043],
                [106.5786036, 10.649432],
                [106.5786197, 10.6491884],
                [106.577813, 10.6491039],
                [106.5769639, 10.648885],
                [106.5769205, 10.6489742],
                [106.5768957, 10.6490283],
                [106.5768805, 10.6490848],
                [106.5768593, 10.6491724],
                [106.576841, 10.6492088],
                [106.576807, 10.6492418],
                [106.5767139, 10.6492861],
                [106.5766914, 10.6493114],
                [106.5766742, 10.6493515],
                [106.5764945, 10.6493214],
                [106.5761823, 10.6492355],
                [106.576141, 10.6492329],
                [106.5760959, 10.6492402],
                [106.5760391, 10.6492703],
                [106.5754631, 10.6491116],
                [106.5751555, 10.6497213],
                [106.5751194, 10.6498703],
                [106.5747072, 10.6498913],
                [106.5741794, 10.6498591],
                [106.5733555, 10.6498562],
                [106.5733184, 10.6505689],
                [106.5733154, 10.6509065],
                [106.5733002, 10.6510407],
                [106.5732699, 10.6511935],
                [106.5731486, 10.6516676],
                [106.5730876, 10.6519271],
                [106.5730741, 10.6519945],
                [106.5730677, 10.6520628],
                [106.5730781, 10.65231],
                [106.573152, 10.6529495],
                [106.5731681, 10.6530326],
                [106.5731893, 10.6531124],
                [106.5732356, 10.6532257],
                [106.5732919, 10.6533366],
                [106.5734032, 10.6535642],
                [106.5734943, 10.6537938],
                [106.5735057, 10.65389],
                [106.5734877, 10.6541309],
                [106.5732597, 10.6541615],
                [106.5728599, 10.6543657],
                [106.5718896, 10.6549938],
                [106.5706201, 10.655792],
                [106.5698411, 10.6562645],
                [106.5697294, 10.656319],
                [106.5695974, 10.6563635],
                [106.5690213, 10.656514],
                [106.5686163, 10.656583],
                [106.5685221, 10.6560476],
                [106.5672389, 10.6562395],
                [106.5662728, 10.6564275],
                [106.5660311, 10.6552974],
                [106.5660053, 10.6551498],
                [106.565981, 10.6542313],
                [106.5658803, 10.6537659],
                [106.5655954, 10.6534463],
                [106.5650451, 10.6530313],
                [106.5651651, 10.6525984],
                [106.5652959, 10.6523024],
                [106.5648159, 10.6521803],
                [106.5644638, 10.6521552],
                [106.563711, 10.6521552],
                [106.5631335, 10.6522051],
                [106.5630729, 10.6514923],
                [106.5616829, 10.651567],
                [106.5608331, 10.6516303],
                [106.559774, 10.6516331],
                [106.5590288, 10.6518814],
                [106.5588863, 10.6511526],
                [106.5587753, 10.6507101],
                [106.558777, 10.6506093],
                [106.5590065, 10.6502718],
                [106.55918, 10.6499646],
                [106.5588441, 10.6495406],
                [106.5586492, 10.6491948],
                [106.5585265, 10.648638],
                [106.5579277, 10.648603],
                [106.5577401, 10.6492237],
                [106.5575127, 10.6497486],
                [106.5573397, 10.6503921],
                [106.5572657, 10.6505125],
                [106.5569927, 10.6507232],
                [106.5565107, 10.6507604],
                [106.5563712, 10.6508857],
                [106.5557916, 10.6508827],
                [106.5556683, 10.6509266],
                [106.5554127, 10.6519353],
                [106.5552789, 10.6523528],
                [106.5552058, 10.652581],
                [106.5551694, 10.6535175],
                [106.5553029, 10.6546835],
                [106.5553727, 10.6548833],
                [106.5555305, 10.6550981],
                [106.555749, 10.6552591],
                [106.5560088, 10.655402],
                [106.5559506, 10.6562617],
                [106.5559, 10.6565235],
                [106.5559217, 10.6569738],
                [106.5540801, 10.6571716],
                [106.5532101, 10.6574053],
                [106.55272, 10.6574903],
                [106.5522728, 10.6573953],
                [106.551732, 10.6573352],
                [106.5512227, 10.6573656],
                [106.5505936, 10.6574704],
                [106.5499123, 10.6574725],
                [106.5489702, 10.6574327],
                [106.548974, 10.658528],
                [106.5490004, 10.6588219],
                [106.5490948, 10.6592142],
                [106.5492686, 10.659657],
                [106.5497364, 10.6593175],
                [106.5501219, 10.6590867],
                [106.5502685, 10.6590117],
                [106.550393, 10.6589991],
                [106.5504895, 10.6590349],
                [106.5505539, 10.6592268],
                [106.5508728, 10.6601818],
                [106.5509638, 10.6606142],
                [106.5512824, 10.6610616],
                [106.5513735, 10.6612822],
                [106.5513947, 10.6614582],
                [106.5513644, 10.6617862],
                [106.5514158, 10.6622258],
                [106.5514745, 10.6632539],
                [106.5515465, 10.6634152],
                [106.5515681, 10.6635943],
                [106.5515064, 10.6638263],
                [106.5514922, 10.6639835],
                [106.551519, 10.6641393],
                [106.5516212, 10.6642593],
                [106.5517137, 10.6643905],
                [106.5518169, 10.6646336],
                [106.5518927, 10.6647467],
                [106.5519948, 10.664847],
                [106.5525217, 10.665104],
                [106.5529249, 10.6653134],
                [106.5530525, 10.6654188],
                [106.5531701, 10.6655602],
                [106.553235, 10.6656959],
                [106.5532732, 10.6658413],
                [106.5532825, 10.666045],
                [106.5532468, 10.6662732],
                [106.5532411, 10.6665442],
                [106.5533004, 10.6675446],
                [106.5533266, 10.6677106],
                [106.553469, 10.6681984],
                [106.5535565, 10.6684693],
                [106.5535808, 10.6685847],
                [106.5535705, 10.6686812],
                [106.553531, 10.6687502],
                [106.5534468, 10.6688154],
                [106.5533001, 10.6688605],
                [106.5530717, 10.6689282],
                [106.55292, 10.6690084],
                [106.5528314, 10.6691163],
                [106.5527798, 10.6692344],
                [106.5526582, 10.6696203],
                [106.5525869, 10.6697649],
                [106.5521135, 10.6703584],
                [106.5520673, 10.6704687],
                [106.5520597, 10.6705916],
                [106.5520992, 10.6707584],
                [106.5521477, 10.6710254],
                [106.5521898, 10.6711458],
                [106.5522727, 10.6712662],
                [106.5523927, 10.6713364],
                [106.5525841, 10.6713903],
                [106.5527984, 10.6714981],
                [106.5529592, 10.671626],
                [106.5530676, 10.6717364],
                [106.5531659, 10.6718818],
                [106.5532335, 10.6720611],
                [106.5532947, 10.6723181],
                [106.5533751, 10.6730566],
                [106.5533738, 10.6734027],
                [106.5533049, 10.6743208],
                [106.5533279, 10.6745565],
                [106.5534096, 10.6747847],
                [106.5536367, 10.6751759],
                [106.5536761, 10.6753994],
                [106.553392, 10.6756097],
                [106.5530928, 10.6756942],
                [106.5529879, 10.676013],
                [106.5529219, 10.6768055],
                [106.5528827, 10.6770924],
                [106.5528083, 10.6773436],
                [106.552554, 10.6775654],
                [106.5524514, 10.6777946],
                [106.5523145, 10.6780699],
                [106.5520287, 10.6785632],
                [106.5516632, 10.6792513],
                [106.5515095, 10.6796467],
                [106.5516196, 10.6801282],
                [106.5515205, 10.6804859],
                [106.5514902, 10.6812666],
                [106.5513475, 10.681798],
                [106.5513036, 10.6822328],
                [106.5512363, 10.6825878],
                [106.5511285, 10.6831032],
                [106.5511049, 10.683346],
                [106.5510109, 10.6836184],
                [106.5508522, 10.6840235],
                [106.5508026, 10.6844881],
                [106.5507772, 10.6847446],
                [106.5507228, 10.6850455],
                [106.5506169, 10.6854183],
                [106.5503892, 10.6857036],
                [106.5501589, 10.6858959],
                [106.5499239, 10.6860534],
                [106.5496751, 10.6862272],
                [106.5494624, 10.6863542],
                [106.5490012, 10.686345],
                [106.5484588, 10.6862447],
                [106.5482288, 10.6862695],
                [106.5481245, 10.6862807],
                [106.5479091, 10.6864791],
                [106.5477009, 10.6866887],
                [106.5474885, 10.6869002],
                [106.5471528, 10.6871444],
                [106.5470613, 10.6871315],
                [106.5468897, 10.6871215],
                [106.5462635, 10.6870019],
                [106.5461397, 10.6869831],
                [106.5407431, 10.6861777],
                [106.5402099, 10.6860981],
                [106.5398751, 10.6860736],
                [106.5386506, 10.6861182],
                [106.538285, 10.6861554],
                [106.5378709, 10.6861646],
                [106.5375781, 10.6861055],
                [106.5372773, 10.6859921],
                [106.5365597, 10.6857233],
                [106.5362099, 10.6856203],
                [106.5357105, 10.6855624],
                [106.5356728, 10.6860015],
                [106.5356108, 10.6862032],
                [106.5354947, 10.686407],
                [106.5352991, 10.6865864],
                [106.5351185, 10.6870425],
                [106.5345856, 10.6873334],
                [106.5343165, 10.6875621],
                [106.5339848, 10.6879482],
                [106.5338665, 10.6881621],
                [106.5337771, 10.6883974],
                [106.5322544, 10.6930899],
                [106.5321707, 10.693611],
                [106.5319006, 10.6941008],
                [106.5303326, 10.6982404],
                [106.528797, 10.7027991],
                [106.5275976, 10.7074711],
                [106.527255, 10.7090329],
                [106.5272428, 10.7092927],
                [106.5272973, 10.7096247],
                [106.52843, 10.714401],
                [106.5284255, 10.7149252],
                [106.5298694, 10.7168426],
                [106.5301779, 10.7171431],
                [106.5302547, 10.7172794],
                [106.5291828, 10.7180075],
                [106.5278175, 10.718777],
                [106.5263635, 10.7195943],
                [106.5258247, 10.7198259],
                [106.5248644, 10.7204004],
                [106.5238332, 10.7209711],
                [106.5202276, 10.7236952],
                [106.5186719, 10.7227409],
                [106.5109689, 10.7179871],
                [106.5093718, 10.7170518],
                [106.5087283, 10.7179442],
                [106.5071619, 10.7198101],
                [106.5057832, 10.7215178],
                [106.5043261, 10.7233277],
                [106.5034367, 10.7244005],
                [106.5016856, 10.7265365],
                [106.4996255, 10.7249923],
                [106.4976268, 10.7262419],
                [106.4957672, 10.7276392],
                [106.4953548, 10.7273631],
                [106.4933096, 10.7285484],
                [106.4911731, 10.7297864],
                [106.4889865, 10.7312615],
                [106.4871569, 10.7298149],
                [106.4858499, 10.7314319],
                [106.4846448, 10.7329689],
                [106.4843356, 10.7333672],
                [106.4824246, 10.7356846],
                [106.482108, 10.7354428],
                [106.4800788, 10.7381167],
                [106.4791087, 10.7393705],
                [106.4776517, 10.74125],
                [106.476624, 10.7426171],
                [106.4735079, 10.7464922],
                [106.4722331, 10.7455255],
                [106.4698171, 10.7484476],
                [106.4670105, 10.7521769],
                [106.4659004, 10.753584],
                [106.4637111, 10.7563181],
                [106.4635618, 10.7565046],
                [106.4719495, 10.7628495],
                [106.4721531, 10.7630037],
                [106.4746657, 10.7649376],
                [106.4765931, 10.7663864],
                [106.4770895, 10.7667069],
                [106.4771195, 10.7667263],
                [106.4794953, 10.7685706],
                [106.4828578, 10.7712496],
                [106.4869667, 10.774357],
                [106.4885044, 10.7754906],
                [106.4894363, 10.7762833],
                [106.4900987, 10.7768468],
                [106.490708, 10.7773264],
                [106.4928029, 10.779107],
                [106.4949844, 10.780898],
                [106.4965049, 10.7821591],
                [106.4972207, 10.7827529],
                [106.4994896, 10.7846399],
                [106.5004348, 10.7853752],
                [106.5014309, 10.7862305],
                [106.5028119, 10.78754],
                [106.5038684, 10.7883308],
                [106.5044774, 10.7889841],
                [106.5046416, 10.7896632],
                [106.5049526, 10.7909377],
                [106.5054201, 10.7928872],
                [106.5055375, 10.7933273],
                [106.505652, 10.7937568],
                [106.5062217, 10.7949845],
                [106.5071802, 10.7993437],
                [106.5078134, 10.8022988],
                [106.5087259, 10.8060045],
                [106.5093506, 10.8084099],
                [106.509515, 10.8092876],
                [106.5100956, 10.8111705],
                [106.511257, 10.8164818],
                [106.511447, 10.8171478],
                [106.5130631, 10.8236797],
                [106.513592, 10.8261456],
                [106.5137691, 10.8268056],
                [106.5152215, 10.8329608],
                [106.5157244, 10.8348271],
                [106.516243, 10.8367517],
                [106.5180024, 10.8437424],
                [106.5188492, 10.8471074],
                [106.5201298, 10.8521182],
                [106.5201795, 10.8523127],
                [106.5214826, 10.8575156],
                [106.5223911, 10.8610169],
                [106.5227851, 10.8625355],
                [106.5242041, 10.8674542],
                [106.5247046, 10.8694461],
                [106.5253413, 10.8718268],
                [106.5257732, 10.8732353],
                [106.5258774, 10.8737182],
                [106.526006, 10.8743612],
                [106.5265907, 10.877284],
                [106.527992, 10.8817066],
                [106.5292296, 10.8863862],
                [106.5306742, 10.8922333],
                [106.5320003, 10.896933],
                [106.5320612, 10.8971212],
                [106.5321231, 10.8973344],
                [106.5321781, 10.8975358],
                [106.5322349, 10.897744],
                [106.5332267, 10.9012996],
                [106.5307472, 10.9030537],
                [106.5281797, 10.9040208],
                [106.5250273, 10.9057091],
                [106.5195891, 10.9086013],
                [106.5190829, 10.9088703],
                [106.5159775, 10.9104121],
                [106.5110411, 10.9129849],
                [106.5053085, 10.9159587],
                [106.5039168, 10.9166866],
                [106.5036729, 10.9168143],
                [106.500437, 10.9185069],
                [106.4962415, 10.9207229],
                [106.4921272, 10.9227751],
                [106.4896076, 10.9240457],
                [106.4890819, 10.9243108],
                [106.4883389, 10.9246855],
                [106.4873707, 10.9251532],
                [106.4871887, 10.9252411],
                [106.486191, 10.9257231],
                [106.4831155, 10.9272983],
                [106.4793619, 10.9291689],
                [106.4743177, 10.9316629],
                [106.4713285, 10.9332035],
                [106.4673236, 10.9350956],
                [106.4642779, 10.9366321],
                [106.4592582, 10.9390665],
                [106.456995, 10.9401582],
                [106.453609, 10.9419049],
                [106.4535763, 10.9419218],
                [106.4533261, 10.9420509],
                [106.4507237, 10.9440068],
                [106.4505243, 10.944158],
                [106.4471167, 10.9467409],
                [106.443695, 10.9493397],
                [106.4400903, 10.9521032],
                [106.4366692, 10.9547162],
                [106.4222714, 10.9658091],
                [106.4154834, 10.9708971],
                [106.4118982, 10.973542],
                [106.4115791, 10.9736578],
                [106.411248, 10.9736951],
                [106.4109339, 10.9736832],
                [106.4025006, 10.9726611],
                [106.3976003, 10.9720919],
                [106.3924154, 10.9716018],
                [106.3914766, 10.9715032],
                [106.3868159, 10.9710137],
                [106.3776218, 10.9700091],
                [106.373408, 10.9696228],
                [106.3732176, 10.9696228],
                [106.3731933, 10.9696228],
                [106.3730725, 10.9696889],
                [106.3726911, 10.9701444],
                [106.3712059, 10.9722566],
                [106.3679502, 10.9765155],
                [106.3636619, 10.9822497],
                [106.3618592, 10.9846905],
                [106.3567007, 10.9915971],
                [106.3543671, 10.994546],
                [106.3511251, 10.9988937],
                [106.3495541, 10.9977487],
                [106.3455329, 10.9947858],
                [106.344651, 10.9970055],
                [106.3439438, 10.9987855],
                [106.3430053, 11.0012811],
                [106.3395801, 11.0038745],
                [106.3378339, 11.0052375],
                [106.3376063, 11.0053922],
                [106.3373673, 11.005528],
                [106.3361965, 11.0061138],
                [106.327824, 11.0098801],
                [106.3233125, 11.0125234],
                [106.3123031, 11.0198451],
                [106.3121572, 11.0199321],
                [106.3120618, 11.0199771],
                [106.3118622, 11.0200453],
                [106.3078281, 11.0211168],
                [106.3074986, 11.0211992],
                [106.3068357, 11.0213485],
                [106.3064328, 11.0214287],
                [106.3045116, 11.0217671],
                [106.3041819, 11.0218091],
                [106.3037829, 11.0218849],
                [106.300806, 11.0226745],
                [106.2976075, 11.0240541],
                [106.2974831, 11.0241721],
                [106.2974101, 11.0243279],
                [106.2971774, 11.0253364],
                [106.2970763, 11.0256694],
                [106.2970114, 11.0257438],
                [106.296914, 11.0257756],
                [106.2961886, 11.0257367],
                [106.294792, 11.0253577],
                [106.2946008, 11.0252443],
                [106.2943806, 11.0250353],
                [106.2939404, 11.0253258],
                [106.2934207, 11.0255348],
                [106.2929624, 11.0256092],
                [106.2924536, 11.0255702],
                [106.2919303, 11.0254746],
                [106.2914359, 11.0253081],
                [106.2913998, 11.0241073],
                [106.2913385, 11.023785],
                [106.2912013, 11.0235193],
                [106.2910353, 11.0234166],
                [106.2903388, 11.0234414],
                [106.2898444, 11.0235405],
                [106.2887907, 11.0240364],
                [106.2882494, 11.0242029],
                [106.2877442, 11.0245111],
                [106.287556, 11.0245556],
                [106.2873844, 11.0245535],
                [106.2873329, 11.0244145],
                [106.2873543, 11.0242565],
                [106.2877551, 11.0234293],
                [106.2879392, 11.0230021],
                [106.2880583, 11.0226692],
                [106.2881088, 11.0223539],
                [106.2880872, 11.0220918],
                [106.2880078, 11.0219182],
                [106.2878959, 11.0218297],
                [106.2877191, 11.0217907],
                [106.2875351, 11.0218226],
                [106.2872644, 11.0219253],
                [106.2870659, 11.0219678],
                [106.2868711, 11.021943],
                [106.2866509, 11.0218687],
                [106.2861818, 11.0216313],
                [106.2859436, 11.0215747],
                [106.2857206, 11.0215679],
                [106.285487, 11.0216185],
                [106.2852715, 11.0217228],
                [106.2848497, 11.0220862],
                [106.2847131, 11.0221607],
                [106.2845614, 11.0221934],
                [106.2843884, 11.0221845],
                [106.2842458, 11.0221517],
                [106.2841263, 11.0220866],
                [106.2840535, 11.0219987],
                [106.284011, 11.02189],
                [106.2839913, 11.0215739],
                [106.2839516, 11.0213932],
                [106.2838253, 11.0212019],
                [106.2833923, 11.0207273],
                [106.2832768, 11.020582],
                [106.283183, 11.0204226],
                [106.2830711, 11.0201534],
                [106.282934, 11.0200082],
                [106.2821545, 11.0196682],
                [106.2820354, 11.0195619],
                [106.2819993, 11.0194273],
                [106.2820174, 11.0192785],
                [106.2820607, 11.0191262],
                [106.2820462, 11.0190341],
                [106.2819668, 11.0189456],
                [106.2814364, 11.0187472],
                [106.2809889, 11.018609],
                [106.2808734, 11.0185453],
                [106.2807074, 11.0183044],
                [106.2802924, 11.0180246],
                [106.2796825, 11.0173161],
                [106.2793974, 11.0167281],
                [106.2790618, 11.0158957],
                [106.2785097, 11.0166325],
                [106.278138, 11.0172347],
                [106.2777266, 11.0180848],
                [106.2772286, 11.0188499],
                [106.2764274, 11.0198205],
                [106.2753917, 11.0205077],
                [106.2749984, 11.0210815],
                [106.2748252, 11.0212444],
                [106.2744679, 11.0214038],
                [106.2738652, 11.0216199],
                [106.2728727, 11.0197064],
                [106.271689, 11.0174182],
                [106.2711694, 11.01615],
                [106.2708807, 11.0151157],
                [106.2708229, 11.0143718],
                [106.2708951, 11.0136209],
                [106.2711694, 11.0129124],
                [106.2716241, 11.0123102],
                [106.2724758, 11.0116584],
                [106.277076, 11.0085395],
                [106.2812592, 11.0061488],
                [106.2821344, 11.0055307],
                [106.2828627, 11.00478],
                [106.2833239, 11.0040949],
                [106.2836334, 11.0033681],
                [106.2846711, 10.9996173],
                [106.2861617, 10.9955665],
                [106.2865223, 10.9947783],
                [106.2867316, 10.9943205],
                [106.2868812, 10.9935494],
                [106.2868805, 10.9927783],
                [106.2866992, 10.9919185],
                [106.2863675, 10.9908216],
                [106.2851329, 10.9886284],
                [106.2831164, 10.9860504],
                [106.281883, 10.9850137],
                [106.2805298, 10.9845108],
                [106.2779742, 10.9840684],
                [106.274877, 10.9828257],
                [106.2737989, 10.9818653],
                [106.2731311, 10.9809543],
                [106.2717417, 10.9812992],
                [106.2714775, 10.9813848],
                [106.2713286, 10.9814941],
                [106.2712428, 10.9816178],
                [106.2711476, 10.9818864],
                [106.2710778, 10.9820213],
                [106.2710094, 10.9820983],
                [106.2709229, 10.9821569],
                [106.2708116, 10.9821859],
                [106.2706279, 10.9821879],
                [106.2705032, 10.9821826],
                [106.2703852, 10.9821925],
                [106.2700272, 10.9823087],
                [106.2693934, 10.9825025],
                [106.2692325, 10.982521],
                [106.2691413, 10.9825736],
                [106.2690836, 10.9826671],
                [106.268975, 10.9829646],
                [106.2689026, 10.983091],
                [106.2687846, 10.9831937],
                [106.2686545, 10.9832372],
                [106.2683541, 10.983253],
                [106.2679973, 10.9832556],
                [106.2676755, 10.9832793],
                [106.2672503, 10.9833662],
                [106.2669553, 10.9834741],
                [106.266795, 10.9834959],
                [106.2666549, 10.9834827],
                [106.2661573, 10.983382],
                [106.2657952, 10.9833609],
                [106.2655377, 10.9833899],
                [106.2653097, 10.9834847],
                [106.2651461, 10.9836084],
                [106.2648377, 10.9839139],
                [106.2646767, 10.9840073],
                [106.2645198, 10.9840481],
                [106.2642382, 10.9840811],
                [106.26408, 10.984139],
                [106.2635844, 10.9844253],
                [106.2633812, 10.9844826],
                [106.2628931, 10.9845142],
                [106.2626195, 10.9846432],
                [106.2622762, 10.9847933],
                [106.2620509, 10.9848354],
                [106.261839, 10.9848354],
                [106.2616137, 10.9847828],
                [106.2612328, 10.9846301],
                [106.2608895, 10.9845642],
                [106.2604576, 10.9845432],
                [106.2599909, 10.9845511],
                [106.2591293, 10.9845859],
                [106.258328, 10.9845247],
                [106.2577888, 10.9845563],
                [106.2575421, 10.9845721],
                [106.2573114, 10.984559],
                [106.2568152, 10.9844431],
                [106.2565792, 10.9844247],
                [106.2561983, 10.9844826],
                [106.255855, 10.9845879],
                [106.2555304, 10.9847617],
                [106.2549323, 10.9852278],
                [106.2541276, 10.9858176],
                [106.2534785, 10.9862257],
                [106.2529662, 10.9864758],
                [106.2525076, 10.9866759],
                [106.2520167, 10.9868155],
                [106.2516144, 10.9868997],
                [106.2511799, 10.9869103],
                [106.249246, 10.9867628],
                [106.247642, 10.9868023],
                [106.2464411, 10.9869221],
                [106.2447774, 10.9868892],
                [106.2436858, 10.9869432],
                [106.2427658, 10.987036],
                [106.2420899, 10.9870051],
                [106.2414984, 10.9870064],
                [106.2408976, 10.9870558],
                [106.2403192, 10.9871788],
                [106.2399307, 10.9873526],
                [106.2397322, 10.9874106],
                [106.2395297, 10.9873921],
                [106.2393567, 10.9872868],
                [106.2392226, 10.987142],
                [106.2388903, 10.9867204],
                [106.2385896, 10.9864785],
                [106.2381041, 10.9862283],
                [106.2379217, 10.9860967],
                [106.2377849, 10.9859518],
                [106.2376884, 10.9858123],
                [106.2376401, 10.9856806],
                [106.2375703, 10.9854108],
                [106.2375234, 10.9852778],
                [106.2374523, 10.9851909],
                [106.2373182, 10.9850987],
                [106.2368247, 10.984925],
                [106.2366262, 10.984896],
                [106.2362105, 10.984896],
                [106.2359074, 10.9848012],
                [106.2356928, 10.9847327],
                [106.2355667, 10.9846643],
                [106.2354809, 10.9845879],
                [106.2353414, 10.9844036],
                [106.2352234, 10.9843009],
                [106.2345019, 10.9838981],
                [106.2338582, 10.9836427],
                [106.2333955, 10.9835196],
                [106.2329469, 10.9833445],
                [106.2321228, 10.9830186],
                [106.231868, 10.9828659],
                [106.2313476, 10.9824525],
                [106.2309962, 10.9823156],
                [106.2299113, 10.9818785],
                [106.2295707, 10.9817653],
                [106.2293963, 10.9817534],
                [106.229222, 10.9817811],
                [106.2290838, 10.9818258],
                [106.2287767, 10.9820167],
                [106.228652, 10.9820944],
                [106.2285447, 10.9821286],
                [106.2284401, 10.9821286],
                [106.2280941, 10.9820286],
                [106.2277119, 10.9819371],
                [106.227551, 10.9818693],
                [106.2274396, 10.9817903],
                [106.2272197, 10.9815941],
                [106.2270829, 10.981502],
                [106.2269005, 10.9814203],
                [106.2267315, 10.9813545],
                [106.2262397, 10.9812156],
                [106.2260932, 10.9811596],
                [106.2259859, 10.9810886],
                [106.2258142, 10.9809385],
                [106.2254991, 10.9806699],
                [106.2254602, 10.9806133],
                [106.2254427, 10.9805422],
                [106.2254199, 10.98035],
                [106.2253904, 10.9802802],
                [106.2251745, 10.9800695],
                [106.2247169, 10.979772],
                [106.2243645, 10.9794718],
                [106.2242626, 10.9793968],
                [106.2241399, 10.979327],
                [106.223765, 10.9791954],
                [106.2232876, 10.9790769],
                [106.2230435, 10.9790005],
                [106.2219465, 10.9785213],
                [106.2214234, 10.9783712],
                [106.2211928, 10.9782817],
                [106.220718, 10.9780183],
                [106.220545, 10.9779459],
                [106.2203801, 10.977913],
                [106.2201776, 10.9778985],
                [106.2196327, 10.9778729],
                [106.2191489, 10.977755],
                [106.2188633, 10.9777142],
                [106.2185749, 10.9777103],
                [106.2181739, 10.9777208],
                [106.2180667, 10.9776971],
                [106.2179849, 10.9776471],
                [106.2176858, 10.9773811],
                [106.2174565, 10.9772416],
                [106.2172352, 10.9771823],
                [106.2170206, 10.9771573],
                [106.2166491, 10.9771468],
                [106.2157787, 10.9771534],
                [106.2154408, 10.9772021],
                [106.2152584, 10.9772087],
                [106.2147608, 10.9771804],
                [106.2143102, 10.9773206],
                [106.2140715, 10.9773429],
                [106.2138194, 10.97731],
                [106.2135364, 10.9772337],
                [106.2129557, 10.9770586],
                [106.2120625, 10.9767992],
                [106.2107067, 10.9764727],
                [106.2102547, 10.9763239],
                [106.209949, 10.976262],
                [106.2083048, 10.9760224],
                [106.2076476, 10.9759461],
                [106.2066954, 10.9757433],
                [106.2053785, 10.9754721],
                [106.2050351, 10.975351],
                [106.2047321, 10.9751667],
                [106.2039274, 10.9745663],
                [106.2036753, 10.9743083],
                [106.2031227, 10.9736131],
                [106.202935, 10.9732761],
                [106.2025648, 10.9729759],
                [106.2018943, 10.9724229],
                [106.2011754, 10.9721543],
                [106.2008053, 10.9720437],
                [106.2004566, 10.9718015],
                [106.199888, 10.9712959],
                [106.1989385, 10.9711142],
                [106.1985522, 10.9710984],
                [106.1973251, 10.9711774],
                [106.1963998, 10.9713183],
                [106.1950225, 10.9717383],
                [106.1946791, 10.9715013],
                [106.1943358, 10.9714065],
                [106.1929947, 10.9708061],
                [106.1926085, 10.9705744],
                [106.1921203, 10.9703111],
                [106.1919594, 10.9702532],
                [106.1915463, 10.9701847],
                [106.1914337, 10.970132],
                [106.1913478, 10.9700478],
                [106.1911923, 10.9696423],
                [106.1910099, 10.969063],
                [106.1908489, 10.9687733],
                [106.1904466, 10.9683099],
                [106.1899316, 10.9678306],
                [106.1892128, 10.9673882],
                [106.1885691, 10.9669722],
                [106.1887836, 10.9665825],
                [106.188671, 10.9662138],
                [106.188612, 10.9660032],
                [106.1885315, 10.965861],
                [106.1881292, 10.9653922],
                [106.1880165, 10.9653185],
                [106.1861819, 10.9645865],
                [106.1850312, 10.9642889],
                [106.1845779, 10.9641257],
                [106.1842507, 10.9640388],
                [106.1839905, 10.9640177],
                [106.1832637, 10.9640203],
                [106.1827782, 10.9638939],
                [106.1821479, 10.963886],
                [106.1820245, 10.9638176],
                [106.1812627, 10.9637649],
                [106.1802328, 10.9637438],
                [106.1799216, 10.9638281],
                [106.1796588, 10.9638281],
                [106.1794281, 10.9637386],
                [106.1790043, 10.9633857],
                [106.1783579, 10.9629855],
                [106.1780816, 10.9628485],
                [106.1773735, 10.9628643],
                [106.1758876, 10.9629539],
                [106.1739671, 10.9632066],
                [106.1738491, 10.9631856],
                [106.1735594, 10.9629855],
                [106.1733663, 10.962917],
                [106.1730927, 10.9629065],
                [106.1722505, 10.9631435],
                [106.1716068, 10.9632593],
                [106.1705339, 10.9633673],
                [106.1696085, 10.9635147],
                [106.1693242, 10.9634673],
                [106.1683586, 10.9633936],
                [106.1679, 10.9634015],
                [106.167444, 10.9642389],
                [106.167106, 10.9647813],
                [106.1668056, 10.965329],
                [106.1667412, 10.9654765],
                [106.1666018, 10.9656556],
                [106.1661512, 10.9661769],
                [106.1658293, 10.9667773],
                [106.1655503, 10.967225],
                [106.1652929, 10.9676463],
                [106.1650729, 10.9679623],
                [106.1647081, 10.968589],
                [106.164456, 10.9691314],
                [106.1641556, 10.9698371],
                [106.1637694, 10.9708746],
                [106.1642092, 10.9716224],
                [106.1642682, 10.9726546],
                [106.164279, 10.9733182],
                [106.1640751, 10.9739238],
                [106.1639786, 10.9747612],
                [106.1637747, 10.9754563],
                [106.163764, 10.9761831],
                [106.1639678, 10.9775681],
                [106.163985, 10.9784637],
                [106.1639437, 10.979119],
                [106.163928, 10.9792095],
                [106.1639048, 10.9792984],
                [106.1638364, 10.9794692],
                [106.163731, 10.979613],
                [106.1636696, 10.9796898],
                [106.1622233, 10.9795497],
                [106.161087, 10.979613],
                [106.1599828, 10.979885],
                [106.1579339, 10.9808789],
                [106.1559294, 10.9825261],
                [106.1546567, 10.9837561],
                [106.15273, 10.9816692],
                [106.1504667, 10.9801931],
                [106.1492078, 10.9797981],
                [106.1464961, 10.9795183],
                [106.1451525, 10.9774547],
                [106.1443797, 10.9767294],
                [106.1448961, 10.9738353],
                [106.1452314, 10.9726647],
                [106.14602, 10.970054],
                [106.1461803, 10.9689289],
                [106.1462002, 10.9653547],
                [106.1479195, 10.9580295],
                [106.1485505, 10.9548038],
                [106.1493257, 10.9512251],
                [106.149433, 10.9508143],
                [106.1495322, 10.9505088],
                [106.1507461, 10.9473486],
                [106.1513193, 10.9459334],
                [106.1518439, 10.944701],
                [106.1524075, 10.9434258],
                [106.1527315, 10.9426603],
                [106.1528112, 10.9424364],
                [106.1529551, 10.9418887],
                [106.1529729, 10.941794],
                [106.1529816, 10.9416018],
                [106.152961, 10.9414407],
                [106.1529412, 10.9413617],
                [106.1517332, 10.9382593],
                [106.151355, 10.9373112],
                [106.1511754, 10.9368335],
                [106.1508035, 10.9357773],
                [106.1500793, 10.9334437],
                [106.150035, 10.9333291],
                [106.1499307, 10.9331127],
                [106.1498074, 10.9329128],
                [106.1480366, 10.9304232],
                [106.1436869, 10.9245343],
                [106.1435008, 10.9242577],
                [106.1434191, 10.9241126],
                [106.1432898, 10.9238372],
                [106.1400372, 10.9148193],
                [106.1400314, 10.914733],
                [106.1400372, 10.91469],
                [106.1400706, 10.9145999],
                [106.1401, 10.9145556],
                [106.1410948, 10.9136201],
                [106.1619213, 10.8879939],
                [106.1632919, 10.885507],
                [106.1642656, 10.8837401],
                [106.1661555, 10.88007],
                [106.1689165, 10.8748787],
                [106.1705379, 10.8718014],
                [106.1741395, 10.8650443],
                [106.1774915, 10.8587612],
                [106.1808839, 10.852393],
                [106.1819433, 10.8504041],
                [106.185392, 10.8436228],
                [106.1882566, 10.837982],
                [106.189015, 10.818118],
                [106.1900872, 10.7900301],
                [106.1858889, 10.793587],
                [106.1821455, 10.7967534],
                [106.1816744, 10.7971518],
                [106.1735816, 10.8040113],
                [106.1690446, 10.8078506],
                [106.1645371, 10.8116642],
                [106.1575942, 10.8112717],
                [106.1495617, 10.8108007],
                [106.1407037, 10.8102916],
                [106.1331399, 10.8098483],
                [106.1259717, 10.8094334],
                [106.1181048, 10.8089802],
                [106.1169448, 10.8089145],
                [106.1089705, 10.8084632],
                [106.1023924, 10.8080765],
                [106.0960101, 10.807709],
                [106.0853013, 10.8094426],
                [106.0791839, 10.8104312],
                [106.070922, 10.8117531],
                [106.0658137, 10.8148896],
                [106.0603514, 10.8188296],
                [106.0556629, 10.8223229],
                [106.0496333, 10.8265737],
                [106.0435849, 10.8308902],
                [106.0366201, 10.8358083],
                [106.0365091, 10.8358866],
                [106.0298599, 10.8405817],
                [106.0253635, 10.8437566],
                [106.0180058, 10.8489518],
                [106.0109813, 10.8539116],
                [106.0076863, 10.8566064],
                [106.0050738, 10.859238],
                [106.0036611, 10.861024],
                [106.0020096, 10.8631074],
                [106.0018659, 10.8632998],
                [106.0017333, 10.8634924],
                [105.9985962, 10.8682968],
                [105.9982073, 10.8689052],
                [105.9981387, 10.8689991],
                [105.9980211, 10.869199],
                [105.9979726, 10.8693043],
                [105.9979051, 10.8694955],
                [105.9978799, 10.8695937],
                [105.996421, 10.8710652],
                [105.9954701, 10.8720531],
                [105.9938082, 10.8738058],
                [105.993095, 10.8745685],
                [105.9923628, 10.8753824],
                [105.9922475, 10.8755431],
                [105.9921831, 10.8756695],
                [105.991931, 10.875846],
                [105.9917459, 10.8759876],
                [105.9915662, 10.8761357],
                [105.9892719, 10.8779],
                [105.9886845, 10.8783322],
                [105.9883797, 10.8785459],
                [105.9855071, 10.8806794],
                [105.9837234, 10.882028],
                [105.9832191, 10.8824231],
                [105.9810573, 10.8842801],
                [105.980887, 10.8844474],
                [105.9805588, 10.8847417],
                [105.9753013, 10.8889869],
                [105.9735686, 10.8904777],
                [105.9735466, 10.890844],
                [105.9734666, 10.8916208],
                [105.9734124, 10.8920038],
                [105.9700361, 10.8952819],
                [105.9652483, 10.8995355],
                [105.9441671, 10.9167399],
                [105.9440995, 10.9166014],
                [105.9440401, 10.9164594],
                [105.9439889, 10.9163143],
                [105.9439463, 10.9161666],
                [105.9439151, 10.915996],
                [105.9439108, 10.9159094],
                [105.9439249, 10.915737],
                [105.9439687, 10.9155698],
                [105.9440013, 10.9154896],
                [105.944087, 10.915339],
                [105.9443352, 10.9150247],
                [105.9443706, 10.9149673],
                [105.944421, 10.9148429],
                [105.9444416, 10.9147226],
                [105.9444371, 10.9146006],
                [105.9443793, 10.9140583],
                [105.9443781, 10.9139422],
                [105.9444059, 10.9135924],
                [105.9444093, 10.9134318],
                [105.9444045, 10.9132713],
                [105.9443604, 10.9128455],
                [105.9443593, 10.9127386],
                [105.9443727, 10.9125836],
                [105.9445041, 10.9118036],
                [105.944524, 10.9115299],
                [105.944521, 10.9113926],
                [105.9444886, 10.9111157],
                [105.9444587, 10.9109771],
                [105.94442, 10.9108406],
                [105.9442839, 10.9104806],
                [105.9442382, 10.910284],
                [105.94422, 10.9101163],
                [105.9442345, 10.9099818],
                [105.9442541, 10.9099169],
                [105.9443144, 10.9097992],
                [105.9443983, 10.9097011],
                [105.9445027, 10.9096243],
                [105.9446811, 10.9095484],
                [105.9447366, 10.9095171],
                [105.9448341, 10.9094359],
                [105.9449108, 10.9093306],
                [105.9449386, 10.9092718],
                [105.9450105, 10.909],
                [105.9450805, 10.9088579],
                [105.9451277, 10.9087899],
                [105.9452417, 10.9086695],
                [105.9454239, 10.9085351],
                [105.9455904, 10.9083824],
                [105.945765, 10.9081804],
                [105.9458422, 10.9080717],
                [105.9465081, 10.9069645],
                [105.9466753, 10.9066353],
                [105.9468367, 10.9062746],
                [105.946927, 10.9060946],
                [105.9473027, 10.9054185],
                [105.9473902, 10.9052193],
                [105.9474301, 10.9051011],
                [105.9474623, 10.9049806],
                [105.9475967, 10.9043449],
                [105.9476546, 10.9041658],
                [105.9477389, 10.9039972],
                [105.9477992, 10.9039055],
                [105.9478673, 10.9038193],
                [105.9479427, 10.9037391],
                [105.9480183, 10.9036317],
                [105.9480721, 10.9035123],
                [105.9480903, 10.9034494],
                [105.9481087, 10.9032961],
                [105.9481049, 10.9032189],
                [105.9480713, 10.9030681],
                [105.9480218, 10.9029275],
                [105.9480017, 10.9027857],
                [105.9480085, 10.9026476],
                [105.9480408, 10.9025131],
                [105.9480661, 10.9024485],
                [105.948447, 10.9016689],
                [105.9485547, 10.901501],
                [105.9486914, 10.9013499],
                [105.9487703, 10.9012822],
                [105.9490907, 10.9010605],
                [105.9491924, 10.9009515],
                [105.9492677, 10.9008235],
                [105.9493134, 10.900682],
                [105.9493243, 10.9006084],
                [105.949327, 10.9005341],
                [105.9493122, 10.9001774],
                [105.9493209, 10.89997],
                [105.9493362, 10.8999055],
                [105.949389, 10.8997913],
                [105.9494649, 10.8996988],
                [105.949707, 10.8995279],
                [105.9501743, 10.8992277],
                [105.9506249, 10.8989719],
                [105.9507043, 10.8989175],
                [105.9508508, 10.8987934],
                [105.9509173, 10.8987243],
                [105.9510346, 10.8985745],
                [105.9511303, 10.8984104],
                [105.951385, 10.8977796],
                [105.9514966, 10.8975645],
                [105.951612, 10.8973916],
                [105.9516646, 10.8972987],
                [105.9517094, 10.8972019],
                [105.9517723, 10.8970083],
                [105.9518033, 10.8968166],
                [105.9518062, 10.8964529],
                [105.951831, 10.8961146],
                [105.9519762, 10.8951517],
                [105.9520477, 10.8945211],
                [105.9521001, 10.8938886],
                [105.952129, 10.8936596],
                [105.9522021, 10.8932038],
                [105.952262, 10.8929026],
                [105.9523308, 10.8926032],
                [105.9523576, 10.8921818],
                [105.9523499, 10.8918409],
                [105.9523362, 10.8916708],
                [105.9522665, 10.8912468],
                [105.9522513, 10.8911874],
                [105.9522028, 10.8910749],
                [105.9521285, 10.890971],
                [105.9520292, 10.8908823],
                [105.9519727, 10.8908467],
                [105.9519124, 10.8908175],
                [105.9517002, 10.8907506],
                [105.9515979, 10.890707],
                [105.9514032, 10.8906007],
                [105.9513116, 10.8905383],
                [105.9512104, 10.8904583],
                [105.9511157, 10.890371],
                [105.9510281, 10.8902768],
                [105.9509481, 10.8901763],
                [105.9508761, 10.8900701],
                [105.9508127, 10.8899588],
                [105.9507167, 10.8897476],
                [105.9506274, 10.8894579],
                [105.9506097, 10.8893163],
                [105.9506112, 10.8892449],
                [105.9506362, 10.8890987],
                [105.9506605, 10.8890256],
                [105.9507312, 10.8888885],
                [105.9507769, 10.8888259],
                [105.9510834, 10.8884974],
                [105.9513593, 10.8882475],
                [105.9515047, 10.8881309],
                [105.9518187, 10.8879093],
                [105.9521502, 10.8877139],
                [105.9523219, 10.8876265],
                [105.9524971, 10.8875461],
                [105.9532265, 10.8872408],
                [105.9533162, 10.8871933],
                [105.953401, 10.8871379],
                [105.9535295, 10.8870296],
                [105.953715, 10.8868191],
                [105.9537958, 10.8867399],
                [105.9539723, 10.8865979],
                [105.9540783, 10.8865292],
                [105.9541892, 10.8864684],
                [105.9543042, 10.8864157],
                [105.9546378, 10.8862888],
                [105.9548489, 10.8861975],
                [105.9550559, 10.8860975],
                [105.9554433, 10.8858803],
                [105.9556299, 10.8857603],
                [105.9557905, 10.8856318],
                [105.955932, 10.8854831],
                [105.9559947, 10.8854021],
                [105.9560593, 10.8853047],
                [105.9561161, 10.8852027],
                [105.9561647, 10.8850968],
                [105.9562048, 10.8849874],
                [105.9562361, 10.8848753],
                [105.9564722, 10.8840088],
                [105.9564812, 10.8839509],
                [105.9564772, 10.8838341],
                [105.9564436, 10.8837213],
                [105.9563822, 10.8836196],
                [105.9563422, 10.8835752],
                [105.9562469, 10.883503],
                [105.9557657, 10.8832425],
                [105.9556326, 10.8831554],
                [105.9554871, 10.8830242],
                [105.9551954, 10.8826733],
                [105.9550933, 10.8825767],
                [105.9549849, 10.8824869],
                [105.9548707, 10.8824043],
                [105.9547511, 10.8823294],
                [105.9546268, 10.8822624],
                [105.9543929, 10.8821629],
                [105.9541485, 10.8820915],
                [105.9540235, 10.8820666],
                [105.9537646, 10.8820292],
                [105.9535042, 10.8819656],
                [105.9532538, 10.8818712],
                [105.953017, 10.8817474],
                [105.952793, 10.8815926],
                [105.9526868, 10.8815034],
                [105.9524929, 10.8813058],
                [105.9523195, 10.8810904],
                [105.952226, 10.8809883],
                [105.9521259, 10.8808924],
                [105.9520197, 10.8808032],
                [105.9518005, 10.8806522],
                [105.9516841, 10.8805871],
                [105.9514445, 10.8804749],
                [105.9513295, 10.8804121],
                [105.9512195, 10.8803414],
                [105.951115, 10.8802631],
                [105.9510164, 10.8801775],
                [105.9509245, 10.8800852],
                [105.9508395, 10.8799867],
                [105.9507025, 10.8797897],
                [105.9506443, 10.8796849],
                [105.9505498, 10.8794651],
                [105.950204, 10.8784275],
                [105.9501314, 10.8782377],
                [105.9499799, 10.8778795],
                [105.9490478, 10.8759619],
                [105.9489168, 10.8757406],
                [105.9487568, 10.8755384],
                [105.9486669, 10.8754456],
                [105.948567, 10.8753557],
                [105.9484609, 10.8752727],
                [105.9483492, 10.8751972],
                [105.9480607, 10.875035],
                [105.9478857, 10.8749464],
                [105.9477078, 10.8748637],
                [105.9472821, 10.8746833],
                [105.9470513, 10.874552],
                [105.9469422, 10.8744767],
                [105.9467384, 10.8743077],
                [105.9466437, 10.8742139],
                [105.9464719, 10.8740107],
                [105.9463955, 10.8739019],
                [105.9463258, 10.8737889],
                [105.9461486, 10.8734425],
                [105.9460822, 10.8733375],
                [105.9459279, 10.8731425],
                [105.9458407, 10.8730534],
                [105.9456483, 10.8728943],
                [105.9454352, 10.8727631],
                [105.9452149, 10.8726658],
                [105.944993, 10.8726006],
                [105.9447643, 10.872564],
                [105.9445468, 10.8725533],
                [105.9443297, 10.8725709],
                [105.9441076, 10.8726193],
                [105.9438858, 10.8727011],
                [105.9435941, 10.8728566],
                [105.9432345, 10.8730819],
                [105.942999, 10.8732501],
                [105.9429319, 10.8732888],
                [105.9427862, 10.873342],
                [105.9426319, 10.8733608],
                [105.9424773, 10.873344],
                [105.9423307, 10.8732926],
                [105.9422629, 10.8732547],
                [105.9418896, 10.8729909],
                [105.9417763, 10.8729234],
                [105.9415394, 10.872807],
                [105.9414164, 10.8727585],
                [105.9411071, 10.8726577],
                [105.9410191, 10.8726184],
                [105.940853, 10.8725212],
                [105.9407759, 10.8724639],
                [105.9406232, 10.8723199],
                [105.9405563, 10.8722393],
                [105.9404433, 10.8720635],
                [105.9403193, 10.8718193],
                [105.9402476, 10.8717024],
                [105.9400827, 10.8714768],
                [105.9398906, 10.8712666],
                [105.9396214, 10.8710324],
                [105.9395727, 10.8709803],
                [105.939496, 10.8708608],
                [105.9394504, 10.8707269],
                [105.9394401, 10.8706569],
                [105.9394434, 10.8705299],
                [105.9394742, 10.8704066],
                [105.9394996, 10.870348],
                [105.9400731, 10.8694321],
                [105.9400932, 10.8693899],
                [105.9401128, 10.8692992],
                [105.9401041, 10.8692068],
                [105.9400678, 10.8691212],
                [105.9400401, 10.8690834],
                [105.9399691, 10.869022],
                [105.9399274, 10.8689999],
                [105.9398827, 10.8689843],
                [105.9397797, 10.8689671],
                [105.9395715, 10.86895],
                [105.939467, 10.86895],
                [105.9392154, 10.8689738],
                [105.9389695, 10.8690306],
                [105.9385422, 10.8691748],
                [105.9381641, 10.8693138],
                [105.9380107, 10.8693451],
                [105.9378541, 10.8693427],
                [105.937777, 10.8693289],
                [105.9376296, 10.8692767],
                [105.9375073, 10.8692016],
                [105.9374029, 10.8691039],
                [105.9373207, 10.8689874],
                [105.9370907, 10.8684528],
                [105.9368652, 10.867994],
                [105.9367424, 10.8677696],
                [105.936434, 10.8672485],
                [105.936352, 10.8670943],
                [105.936183, 10.8667303],
                [105.9360998, 10.8665195],
                [105.9359996, 10.8662297],
                [105.9359662, 10.8661568],
                [105.9358783, 10.8660225],
                [105.9358248, 10.8659624],
                [105.9357063, 10.8658624],
                [105.9355766, 10.8657887],
                [105.9354356, 10.865739],
                [105.9352008, 10.8657244],
                [105.9350832, 10.865729],
                [105.9348588, 10.8657605],
                [105.934753, 10.8657869],
                [105.9346494, 10.8658208],
                [105.9344512, 10.8659103],
                [105.9342797, 10.8660185],
                [105.9341999, 10.866081],
                [105.9340543, 10.8662211],
                [105.9337243, 10.8667005],
                [105.9336704, 10.8667619],
                [105.9335447, 10.8668668],
                [105.9334742, 10.8669091],
                [105.9333218, 10.8669712],
                [105.9332415, 10.8669903],
                [105.9331039, 10.8670037],
                [105.932966, 10.8669935],
                [105.9328982, 10.8669797],
                [105.9327318, 10.8669226],
                [105.9326526, 10.8668845],
                [105.9325046, 10.8667902],
                [105.9323676, 10.8666676],
                [105.9323046, 10.8665947],
                [105.9321994, 10.866434],
                [105.9321579, 10.8663475],
                [105.9317932, 10.8653676],
                [105.9317541, 10.8652783],
                [105.9317064, 10.865193],
                [105.9316503, 10.8651128],
                [105.9315302, 10.8649832],
                [105.9314051, 10.8648868],
                [105.9309674, 10.8646493],
                [105.9308786, 10.8645923],
                [105.9307952, 10.8645279],
                [105.9307179, 10.8644566],
                [105.9306471, 10.8643789],
                [105.9305835, 10.8642955],
                [105.9304853, 10.8641264],
                [105.9304164, 10.8639438],
                [105.9303785, 10.8637527],
                [105.9303716, 10.8636555],
                [105.9303743, 10.8632498],
                [105.9303684, 10.8630933],
                [105.9303357, 10.8627817],
                [105.9302752, 10.8624741],
                [105.9301843, 10.8621633],
                [105.9300609, 10.8618526],
                [105.9299084, 10.8615546],
                [105.9291592, 10.8603179],
                [105.9290781, 10.8601537],
                [105.9290485, 10.8600671],
                [105.9290159, 10.8599058],
                [105.9290123, 10.859888],
                [105.9290075, 10.8597055],
                [105.9290359, 10.8595189],
                [105.9290996, 10.8593344],
                [105.9291963, 10.8591642],
                [105.9292561, 10.8590863],
                [105.929988, 10.8582475],
                [105.9300396, 10.8581777],
                [105.9301255, 10.8580272],
                [105.9301592, 10.8579476],
                [105.9302095, 10.8577703],
                [105.9302294, 10.8575758],
                [105.930208, 10.856991],
                [105.9301986, 10.8568826],
                [105.9301543, 10.8566696],
                [105.9300857, 10.8564843],
                [105.9300013, 10.8563099],
                [105.9299694, 10.8562207],
                [105.9299464, 10.8561289],
                [105.9299323, 10.8560354],
                [105.9299274, 10.855941],
                [105.9299317, 10.8558465],
                [105.9299451, 10.8557529],
                [105.9299906, 10.8555925],
                [105.9300628, 10.8554418],
                [105.9301083, 10.8553715],
                [105.9301597, 10.8553051],
                [105.9302493, 10.855209],
                [105.9304443, 10.8550322],
                [105.930549, 10.8549522],
                [105.9306581, 10.854878],
                [105.9309372, 10.8547126],
                [105.9310142, 10.8546551],
                [105.9311542, 10.8545243],
                [105.9312165, 10.8544516],
                [105.9313231, 10.8542949],
                [105.9314051, 10.8541246],
                [105.9318656, 10.852742],
                [105.9319183, 10.852606],
                [105.9319792, 10.8524732],
                [105.9321093, 10.8522428],
                [105.9323471, 10.8518919],
                [105.9324315, 10.8517186],
                [105.9324816, 10.8515437],
                [105.9324953, 10.8514537],
                [105.9325038, 10.8512879],
                [105.932489, 10.8511385],
                [105.9324717, 10.8510653],
                [105.9324283, 10.850955],
                [105.9323962, 10.8509048],
                [105.932314, 10.8508183],
                [105.9322652, 10.8507834],
                [105.9322071, 10.8507526],
                [105.9321451, 10.8507299],
                [105.9320806, 10.850716],
                [105.9320147, 10.8507109],
                [105.9316721, 10.8507474],
                [105.9313131, 10.8507947],
                [105.9311727, 10.8507892],
                [105.9310378, 10.8507503],
                [105.9309751, 10.8507189],
                [105.930915, 10.8506789],
                [105.9308605, 10.8506318],
                [105.9308125, 10.8505782],
                [105.9307718, 10.8505191],
                [105.930739, 10.8504555],
                [105.9307009, 10.8503501],
                [105.9306425, 10.8501341],
                [105.9306241, 10.8500347],
                [105.9306154, 10.8499341],
                [105.9306165, 10.849833],
                [105.9306273, 10.8497326],
                [105.9306478, 10.8496336],
                [105.9307089, 10.8494611],
                [105.9307505, 10.8493794],
                [105.9308544, 10.8492279],
                [105.9309397, 10.8491339],
                [105.931032, 10.8490465],
                [105.9311307, 10.8489662],
                [105.9312353, 10.8488934],
                [105.9314262, 10.8487873],
                [105.9315266, 10.8487436],
                [105.9316295, 10.8487063],
                [105.931741, 10.8486773],
                [105.9319675, 10.8486352],
                [105.9327139, 10.8485846],
                [105.9328849, 10.8485462],
                [105.9330463, 10.8484789],
                [105.9331933, 10.848385],
                [105.9333164, 10.8482724],
                [105.9334186, 10.8481411],
                [105.9335795, 10.8478331],
                [105.9336841, 10.8476842],
                [105.9338343, 10.8475275],
                [105.9340087, 10.8473971],
                [105.9342501, 10.8472778],
                [105.9343755, 10.8472281],
                [105.9346336, 10.8471495],
                [105.9348971, 10.8470995],
                [105.935483, 10.8470769],
                [105.9355752, 10.8470641],
                [105.9357538, 10.8470132],
                [105.9358386, 10.8469754],
                [105.9359953, 10.8468772],
                [105.9361287, 10.8467535],
                [105.9362349, 10.8466098],
                [105.9362774, 10.8465313],
                [105.9363395, 10.8463645],
                [105.9364975, 10.8455335],
                [105.9365152, 10.8453779],
                [105.9365427, 10.8445048],
                [105.9365841, 10.8428082],
                [105.936597, 10.8413856],
                [105.9365971, 10.8411743],
                [105.9365881, 10.8409631],
                [105.9365699, 10.8407525],
                [105.9365426, 10.8405429],
                [105.9365062, 10.8403346],
                [105.9364608, 10.840128],
                [105.9364066, 10.8399235],
                [105.9363067, 10.8396232],
                [105.9361786, 10.8393334],
                [105.9360379, 10.8390803],
                [105.9358758, 10.8388398],
                [105.9356085, 10.8384686],
                [105.9350082, 10.8387343],
                [105.9305326, 10.8394546],
                [105.9212093, 10.840955],
                [105.9170511, 10.8416242],
                [105.8988985, 10.8445738],
                [105.8961088, 10.8455773],
                [105.8835767, 10.8500855],
                [105.8635806, 10.8575639],
                [105.8498248, 10.8627124],
                [105.8496474, 10.8629386],
                [105.8491006, 10.8636357],
                [105.84876, 10.864333],
                [105.848498, 10.865395],
                [105.848524, 10.8663712],
                [105.848792, 10.867478],
                [105.8492763, 10.8685141],
                [105.8511579, 10.8724573],
                [105.8534666, 10.8771367],
                [105.8571821, 10.884903],
                [105.860433, 10.8918947],
                [105.862656, 10.896393],
                [105.863137, 10.897817],
                [105.8633539, 10.8991899],
                [105.8538254, 10.9143965],
                [105.8506338, 10.9189276],
                [105.8471322, 10.9238988],
                [105.843725, 10.9287359],
                [105.8400857, 10.9339023],
                [105.835593, 10.9394045],
                [105.8307495, 10.9453362],
                [105.8305581, 10.9455706],
                [105.8260009, 10.9511514],
                [105.8215498, 10.9565625],
                [105.8173291, 10.9617439],
                [105.813672, 10.9662332],
                [105.808382, 10.972727],
                [105.7975929, 10.9938773],
                [105.7929931, 11.002894],
                [105.7918013, 11.0049402],
                [105.7916149, 11.0052502],
                [105.7869698, 11.0138969],
                [105.7868587, 11.0141269],
                [105.7866561, 11.0145955],
                [105.7864696, 11.0151063],
                [105.7863879, 11.0153656],
                [105.7855486, 11.018309],
                [105.7853365, 11.0189448],
                [105.7852338, 11.0192218],
                [105.7850118, 11.0197695],
                [105.7848926, 11.02004],
                [105.7833691, 11.0231193],
                [105.779491, 11.0311834],
                [105.7794391, 11.0312636],
                [105.7793136, 11.031408],
                [105.7791736, 11.0315196],
                [105.7790159, 11.0316055],
                [105.7779415, 11.0320754],
                [105.7778156, 11.0321162],
                [105.7776723, 11.0321489],
                [105.7773818, 11.0321931],
                [105.7770882, 11.0322087],
                [105.7767898, 11.0321951],
                [105.7764897, 11.0321512],
                [105.7761958, 11.0320772],
                [105.7751588, 11.0317013],
                [105.7744269, 11.0314616],
                [105.7737172, 11.0312527],
                [105.7725102, 11.0309157],
                [105.7720718, 11.030785],
                [105.7715129, 11.0306069],
                [105.7713875, 11.030575],
                [105.7711315, 11.0305366],
                [105.7708894, 11.030532],
                [105.7706487, 11.0305577],
                [105.769927, 11.0306851],
                [105.7697917, 11.0307004],
                [105.7695195, 11.0307104],
                [105.769292, 11.0306976],
                [105.7689158, 11.0306439],
                [105.768393, 11.0305445],
                [105.7680956, 11.0304762],
                [105.7678002, 11.0303997],
                [105.7653474, 11.0296266],
                [105.7637788, 11.029154],
                [105.7600003, 11.028083],
                [105.7594961, 11.0279145],
                [105.7582784, 11.0274406],
                [105.7572645, 11.0270931],
                [105.7561638, 11.0267358],
                [105.7556772, 11.0265542],
                [105.755439, 11.0264512],
                [105.7550702, 11.026272],
                [105.7548111, 11.0261192],
                [105.7546868, 11.0260348],
                [105.7544375, 11.0258404],
                [105.75396, 11.0253976],
                [105.7536328, 11.0251396],
                [105.7535056, 11.0250679],
                [105.7534373, 11.0250416],
                [105.7532944, 11.0250092],
                [105.7532212, 11.0250035],
                [105.7530749, 11.0250133],
                [105.7529312, 11.0250517],
                [105.752798, 11.0251173],
                [105.7526808, 11.0252075],
                [105.7526296, 11.0252607],
                [105.7519215, 11.0262901],
                [105.7518885, 11.0263485],
                [105.7518061, 11.0264545],
                [105.7517043, 11.0265429],
                [105.7516426, 11.026582],
                [105.7515085, 11.0266403],
                [105.7514123, 11.0266613],
                [105.7512161, 11.0266771],
                [105.7511147, 11.0266715],
                [105.7510143, 11.0266565],
                [105.7509157, 11.0266324],
                [105.7508254, 11.0266005],
                [105.7506521, 11.02652],
                [105.7504737, 11.026406],
                [105.7503832, 11.026333],
                [105.7500038, 11.0259505],
                [105.7499127, 11.0258767],
                [105.7497161, 11.0257483],
                [105.7495034, 11.0256476],
                [105.7493922, 11.0256083],
                [105.749178, 11.0255552],
                [105.7489586, 11.0255296],
                [105.7487377, 11.0255319],
                [105.7485766, 11.0255548],
                [105.7484171, 11.0255866],
                [105.7482596, 11.0256273],
                [105.7481047, 11.0256767],
                [105.7478156, 11.0257958],
                [105.7475415, 11.0259452],
                [105.7464977, 11.0266315],
                [105.7462868, 11.0267357],
                [105.7460636, 11.0268114],
                [105.7459514, 11.0268375],
                [105.7457229, 11.0268678],
                [105.7456076, 11.0268719],
                [105.7448475, 11.0268521],
                [105.7446131, 11.0268573],
                [105.7441864, 11.0268911],
                [105.7439943, 11.0269166],
                [105.7436134, 11.0269865],
                [105.7429334, 11.0271563],
                [105.742564, 11.0272248],
                [105.7421904, 11.0272666],
                [105.7418188, 11.0272814],
                [105.7414512, 11.0272699],
                [105.741268, 11.0272545],
                [105.7397228, 11.0270826],
                [105.7390121, 11.0269773],
                [105.7381618, 11.0268219],
                [105.7378675, 11.0267727],
                [105.7375717, 11.0267324],
                [105.7373413, 11.0267073],
                [105.7368863, 11.0266732],
                [105.7366617, 11.0266679],
                [105.7363504, 11.0266759],
                [105.736177, 11.0266666],
                [105.7360094, 11.0266352],
                [105.7357932, 11.0265694],
                [105.7355338, 11.0265108],
                [105.734122, 11.026346],
                [105.7334729, 11.0262529],
                [105.7331783, 11.0262032],
                [105.7322153, 11.0260083],
                [105.7317916, 11.0258926],
                [105.7314465, 11.0257761],
                [105.7311901, 11.0256791],
                [105.731011, 11.0256346],
                [105.730854, 11.0256174],
                [105.7305658, 11.0256109],
                [105.7303394, 11.0255884],
                [105.730114, 11.0255577],
                [105.7296717, 11.0254726],
                [105.7294553, 11.0254187],
                [105.7278755, 11.024958],
                [105.7277132, 11.0248935],
                [105.7275622, 11.0248066],
                [105.727492, 11.0247553],
                [105.7273704, 11.0246451],
                [105.7272047, 11.024453],
                [105.7271378, 11.0243925],
                [105.7269877, 11.0242919],
                [105.7269035, 11.0242517],
                [105.7268156, 11.0242201],
                [105.7267249, 11.0241971],
                [105.723905, 11.0235298],
                [105.7236143, 11.0234482],
                [105.7233265, 11.0233573],
                [105.7228067, 11.0231665],
                [105.7225513, 11.0230598],
                [105.7203449, 11.0220307],
                [105.7199764, 11.0218698],
                [105.7195017, 11.0216908],
                [105.7188633, 11.0214722],
                [105.7184342, 11.0212932],
                [105.7180077, 11.02108],
                [105.7174624, 11.0207412],
                [105.7167541, 11.020271],
                [105.7165262, 11.0201359],
                [105.7159837, 11.0198442],
                [105.7156839, 11.0196632],
                [105.7153952, 11.0194661],
                [105.7142381, 11.0186363],
                [105.7140896, 11.018538],
                [105.7139361, 11.0184472],
                [105.713639, 11.0182997],
                [105.712595, 11.017868],
                [105.7124035, 11.017776],
                [105.7120306, 11.0175734],
                [105.7116723, 11.0173467],
                [105.7114701, 11.0172033],
                [105.7112737, 11.0170522],
                [105.7105782, 11.0164511],
                [105.710251, 11.0161805],
                [105.7099182, 11.0159165],
                [105.7094126, 11.0155354],
                [105.7092399, 11.0154186],
                [105.7089073, 11.015221],
                [105.708749, 11.0151384],
                [105.708419, 11.0149833],
                [105.7080921, 11.0148066],
                [105.7077815, 11.0146036],
                [105.7074898, 11.0143764],
                [105.707219, 11.0141267],
                [105.7070918, 11.0139937],
                [105.7061383, 11.0129142],
                [105.7059783, 11.0127472],
                [105.70564, 11.0124313],
                [105.7053122, 11.012166],
                [105.7049668, 11.0119232],
                [105.7022309, 11.0102276],
                [105.7019265, 11.0100118],
                [105.7016442, 11.0097689],
                [105.7013993, 11.0095155],
                [105.7011899, 11.0092572],
                [105.6990098, 11.0061301],
                [105.698846, 11.0058676],
                [105.6987646, 11.0057211],
                [105.6985637, 11.0053049],
                [105.6984383, 11.005083],
                [105.6983518, 11.0049548],
                [105.6982579, 11.0048318],
                [105.6980758, 11.0046252],
                [105.6977248, 11.0042378],
                [105.6974285, 11.0039501],
                [105.6971257, 11.0036688],
                [105.6968165, 11.0033944],
                [105.6965012, 11.0031267],
                [105.6959005, 11.002649],
                [105.6953293, 11.0022306],
                [105.6950377, 11.0020295],
                [105.6947422, 11.001834],
                [105.6914377, 10.9998541],
                [105.6889594, 10.9982954],
                [105.688479, 10.9980282],
                [105.687994, 10.9977695],
                [105.6875042, 10.9975194],
                [105.6870101, 10.9972778],
                [105.6865116, 10.997045],
                [105.6860089, 10.9968209],
                [105.6851029, 10.9964433],
                [105.6846454, 10.9962652],
                [105.6814653, 10.9950568],
                [105.6810469, 10.9948911],
                [105.6802207, 10.9945355],
                [105.6794879, 10.9941873],
                [105.6757624, 10.9922789],
                [105.6748885, 10.9918182],
                [105.6742829, 10.9914859],
                [105.673682, 10.9911454],
                [105.673086, 10.9907966],
                [105.6723833, 10.9904069],
                [105.6686792, 10.9881926],
                [105.6682211, 10.987943],
                [105.6679865, 10.9878286],
                [105.6674839, 10.9876119],
                [105.6672159, 10.9875113],
                [105.6669445, 10.9874196],
                [105.6664326, 10.987266],
                [105.6659648, 10.9871025],
                [105.6655418, 10.9869259],
                [105.6652119, 10.9867716],
                [105.6649595, 10.9866741],
                [105.6648302, 10.9866338],
                [105.6645864, 10.9865744],
                [105.6637949, 10.9864232],
                [105.6633459, 10.9863144],
                [105.6629511, 10.9861931],
                [105.6626093, 10.9860677],
                [105.6624112, 10.985987],
                [105.6620058, 10.9858492],
                [105.6594551, 10.9851883],
                [105.6591614, 10.9851069],
                [105.6585845, 10.9849198],
                [105.6580013, 10.984709],
                [105.657663, 10.9846132],
                [105.6574917, 10.9845734],
                [105.6558663, 10.9842509],
                [105.6534201, 10.9837875],
                [105.6517249, 10.9834557],
                [105.6494987, 10.9829975],
                [105.6458962, 10.9821891],
                [105.6454988, 10.9820681],
                [105.6449828, 10.9818768],
                [105.6446765, 10.9817936],
                [105.6443891, 10.9817416],
                [105.6429703, 10.9815793],
                [105.642297, 10.9814845],
                [105.6415796, 10.9813567],
                [105.6408204, 10.9811908],
                [105.6404436, 10.9810965],
                [105.6374557, 10.9801757],
                [105.6359053, 10.979722],
                [105.6344625, 10.9793241],
                [105.6329029, 10.9789179],
                [105.6284751, 10.9777205],
                [105.6266458, 10.9772413],
                [105.6245683, 10.9767252],
                [105.6234062, 10.9764464],
                [105.6212872, 10.975954],
                [105.6203066, 10.9757074],
                [105.6194906, 10.9755162],
                [105.6188464, 10.975351],
                [105.6182161, 10.9751667],
                [105.6162836, 10.9745702],
                [105.6155937, 10.9743317],
                [105.6150446, 10.974121],
                [105.6148334, 10.9740481],
                [105.614455, 10.9739422],
                [105.6139567, 10.9738422],
                [105.61361, 10.9737553],
                [105.6130011, 10.9735949],
                [105.6125003, 10.973463],
                [105.6120064, 10.9732947],
                [105.6117868, 10.9732287],
                [105.6115269, 10.9731737],
                [105.6113952, 10.9731549],
                [105.6112102, 10.9731105],
                [105.6110276, 10.9730569],
                [105.6105196, 10.9728683],
                [105.6103522, 10.9728138],
                [105.6095982, 10.9726072],
                [105.6093197, 10.9725175],
                [105.6090442, 10.9724192],
                [105.608772, 10.9723123],
                [105.6072009, 10.9717648],
                [105.6056599, 10.9712574],
                [105.6041501, 10.9707882],
                [105.6025131, 10.9703084],
                [105.6023918, 10.9702798],
                [105.6021484, 10.9702445],
                [105.6017738, 10.9702322],
                [105.6016453, 10.9702028],
                [105.601521, 10.9701484],
                [105.6014618, 10.9701114],
                [105.6010448, 10.9697846],
                [105.6009078, 10.9697107],
                [105.6008235, 10.9696782],
                [105.6006476, 10.969637],
                [105.6002748, 10.9695975],
                [105.6000852, 10.9695644],
                [105.5997115, 10.9694737],
                [105.599391, 10.9693675],
                [105.5988549, 10.9691517],
                [105.598467, 10.9690208],
                [105.5981213, 10.9689251],
                [105.5964553, 10.9684968],
                [105.594816, 10.968006],
                [105.5930995, 10.9674655],
                [105.5920078, 10.9671495],
                [105.5910871, 10.9669023],
                [105.5903039, 10.9667186],
                [105.5898946, 10.9666351],
                [105.5890958, 10.966487],
                [105.5883611, 10.9663272],
                [105.5847629, 10.9654317],
                [105.5843747, 10.965329],
                [105.5836022, 10.9651079],
                [105.5792034, 10.9637702],
                [105.5783215, 10.963495],
                [105.5773255, 10.9632095],
                [105.5767706, 10.9630618],
                [105.5740106, 10.9623587],
                [105.5736441, 10.9622778],
                [105.5732757, 10.9622058],
                [105.5725299, 10.9620857],
                [105.5717754, 10.9619947],
                [105.5713969, 10.9619608],
                [105.5706381, 10.9619164],
                [105.5702582, 10.9619058],
                [105.5695753, 10.9619064],
                [105.5686231, 10.9619433],
                [105.568389, 10.9619464],
                [105.5680829, 10.9619427],
                [105.5677971, 10.9619299],
                [105.5675118, 10.9619086],
                [105.5662874, 10.9618437],
                [105.5656367, 10.9617794],
                [105.5649596, 10.9616818],
                [105.5646229, 10.9616214],
                [105.564179, 10.961527],
                [105.5637373, 10.9614234],
                [105.5627324, 10.9611622],
                [105.5624472, 10.9610976],
                [105.5613715, 10.9608852],
                [105.5599622, 10.9605727],
                [105.5590903, 10.9604041],
                [105.5581992, 10.9602637],
                [105.557745, 10.9602044],
                [105.5572898, 10.9601531],
                [105.5563407, 10.9600664],
                [105.5558486, 10.9600141],
                [105.5548677, 10.9598835],
                [105.5539705, 10.9597328],
                [105.5532961, 10.9596023],
                [105.5528451, 10.9594942],
                [105.5526222, 10.9594303],
                [105.5521828, 10.9592831],
                [105.5519536, 10.9591947],
                [105.5515044, 10.9589967],
                [105.5512848, 10.9588874],
                [105.5508567, 10.9586486],
                [105.5502434, 10.9582752],
                [105.5498339, 10.9580382],
                [105.5490363, 10.9576038],
                [105.5482587, 10.9572133],
                [105.5463785, 10.9563074],
                [105.5459658, 10.9561268],
                [105.5457562, 10.9560441],
                [105.5453041, 10.9558855],
                [105.5450749, 10.955815],
                [105.5449068, 10.9557511],
                [105.5445814, 10.9555991],
                [105.5443095, 10.9554398],
                [105.5432424, 10.9547007],
                [105.5431491, 10.9546432],
                [105.5429658, 10.9545531],
                [105.5425295, 10.954393],
                [105.5421924, 10.9542441],
                [105.5397283, 10.9529605],
                [105.5395013, 10.9528486],
                [105.5390376, 10.9526448],
                [105.5385625, 10.952468],
                [105.5382912, 10.9523809],
                [105.5380171, 10.9523026],
                [105.5377405, 10.9522332],
                [105.5374617, 10.9521729],
                [105.537181, 10.9521217],
                [105.5368988, 10.9520796],
                [105.5274689, 10.9509617],
                [105.5261385, 10.9507932],
                [105.5243897, 10.9505878],
                [105.5220721, 10.9504001],
                [105.5210005, 10.9502921],
                [105.5204657, 10.9502297],
                [105.5191782, 10.9500559],
                [105.5174804, 10.949782],
                [105.5161608, 10.9496392],
                [105.5154999, 10.9495797],
                [105.5141759, 10.9494844],
                [105.5134584, 10.9494461],
                [105.5108795, 10.9493554],
                [105.5051503, 10.9490499],
                [105.5049956, 10.9490609],
                [105.5049196, 10.9490763],
                [105.5047759, 10.9491248],
                [105.5047077, 10.9491579],
                [105.5044448, 10.9493291],
                [105.5043459, 10.9493965],
                [105.5009995, 10.951962],
                [105.5007045, 10.9521766],
                [105.5003679, 10.951757],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.9239975, 20.3947816],
                [105.9241532, 20.3935991],
                [105.9243592, 20.3926928],
                [105.9244378, 20.3922166],
                [105.9246855, 20.3900976],
                [105.9249366, 20.3885618],
                [105.9250769, 20.3875329],
                [105.925329, 20.3861351],
                [105.9254079, 20.3857203],
                [105.9254541, 20.3852905],
                [105.92556, 20.3839702],
                [105.9256373, 20.3832792],
                [105.9258602, 20.3824648],
                [105.9260816, 20.3813895],
                [105.9264337, 20.3804055],
                [105.9268844, 20.3795898],
                [105.9272871, 20.378897],
                [105.9276584, 20.3784041],
                [105.9279658, 20.3780955],
                [105.9285649, 20.3776013],
                [105.9291966, 20.3771068],
                [105.9297791, 20.3765667],
                [105.9303139, 20.3761802],
                [105.9311091, 20.3758076],
                [105.9319041, 20.3754352],
                [105.9325051, 20.3752477],
                [105.9333665, 20.375059],
                [105.9336592, 20.3750421],
                [105.9341804, 20.3751007],
                [105.9347185, 20.375282],
                [105.9352736, 20.3755705],
                [105.9358289, 20.3759052],
                [105.9361714, 20.3760108],
                [105.9364154, 20.3760094],
                [105.9366591, 20.3759621],
                [105.9367884, 20.375808],
                [105.9368847, 20.3755773],
                [105.936931, 20.3751627],
                [105.9368633, 20.3747333],
                [105.9365654, 20.3739063],
                [105.9359895, 20.3728814],
                [105.9355131, 20.3721473],
                [105.9352657, 20.3715961],
                [105.9349501, 20.3705544],
                [105.934747, 20.3692512],
                [105.934678, 20.368607],
                [105.9346393, 20.3679253],
                [105.9355716, 20.3674818],
                [105.9360968, 20.3670652],
                [105.9366038, 20.3662903],
                [105.9369589, 20.3657576],
                [105.9371594, 20.365081],
                [105.9372322, 20.3644533],
                [105.9372018, 20.3636332],
                [105.9367728, 20.360354],
                [105.9367656, 20.3591718],
                [105.9368374, 20.3583752],
                [105.9370624, 20.3574812],
                [105.9374155, 20.3566591],
                [105.93795, 20.3561977],
                [105.9386386, 20.3558321],
                [105.9396855, 20.3555128],
                [105.9408357, 20.3553136],
                [105.9412387, 20.3552998],
                [105.9424982, 20.3552563],
                [105.9452871, 20.3553136],
                [105.9494332, 20.3555807],
                [105.9509682, 20.3555965],
                [105.9519657, 20.3555428],
                [105.9525278, 20.3554191],
                [105.9528279, 20.3553503],
                [105.9529277, 20.3552925],
                [105.9533842, 20.355028],
                [105.9537374, 20.3546907],
                [105.9541064, 20.3541937],
                [105.9542732, 20.3537937],
                [105.9543046, 20.3534104],
                [105.9542159, 20.3527562],
                [105.9540414, 20.3518789],
                [105.9538457, 20.3502993],
                [105.9536666, 20.34864],
                [105.9531307, 20.3467918],
                [105.9530251, 20.3465307],
                [105.9524607, 20.3451359],
                [105.9519278, 20.3437665],
                [105.9512262, 20.3424459],
                [105.9500126, 20.340426],
                [105.9489369, 20.3388043],
                [105.9477605, 20.3373265],
                [105.9465509, 20.3359289],
                [105.9453769, 20.3348502],
                [105.944204, 20.3339151],
                [105.9434901, 20.3333604],
                [105.9432486, 20.3331687],
                [105.9405388, 20.3321991],
                [105.9393785, 20.3317091],
                [105.9381471, 20.3310541],
                [105.9371962, 20.3303975],
                [105.9365595, 20.3295077],
                [105.9361821, 20.3283466],
                [105.9361832, 20.3278346],
                [105.9363715, 20.3273915],
                [105.9369984, 20.3266935],
                [105.9376977, 20.3263258],
                [105.939272, 20.3256888],
                [105.9416154, 20.3246507],
                [105.9430841, 20.323948],
                [105.9458843, 20.3230397],
                [105.9485089, 20.3221323],
                [105.9514811, 20.3206936],
                [105.9537827, 20.3198292],
                [105.9560975, 20.3186174],
                [105.9583702, 20.3175132],
                [105.9616216, 20.315896],
                [105.962876, 20.3152656],
                [105.9632994, 20.3150261],
                [105.9654309, 20.3137896],
                [105.9668619, 20.3126893],
                [105.9684646, 20.3110253],
                [105.9711503, 20.3086933],
                [105.9724712, 20.3073842],
                [105.9733103, 20.3064468],
                [105.974918, 20.3045816],
                [105.9752598, 20.304185],
                [105.9768621, 20.3024548],
                [105.9794393, 20.2996598],
                [105.9806228, 20.2982795],
                [105.9817014, 20.2969493],
                [105.9830561, 20.2949225],
                [105.9841277, 20.2925],
                [105.9849572, 20.2906085],
                [105.9864002, 20.2859164],
                [105.9864284, 20.2848239],
                [105.9863829, 20.283169],
                [105.986056, 20.2813833],
                [105.9841886, 20.2743596],
                [105.9835111, 20.2730732],
                [105.9833369, 20.2727424],
                [105.9827888, 20.2716514],
                [105.9822009, 20.2704812],
                [105.9817361, 20.2690604],
                [105.9816262, 20.2682997],
                [105.9813733, 20.2671095],
                [105.9812644, 20.2665141],
                [105.981186, 20.2651906],
                [105.9811768, 20.2637341],
                [105.9815243, 20.2632522],
                [105.9818025, 20.2628865],
                [105.982923, 20.2623427],
                [105.9841923, 20.261382],
                [105.9854695, 20.2606812],
                [105.9883776, 20.2583438],
                [105.9911265, 20.2566082],
                [105.9955863, 20.2544862],
                [106.0030518, 20.251802],
                [106.0042735, 20.2514237],
                [106.0059763, 20.2510838],
                [106.0078543, 20.2507842],
                [106.0096888, 20.2505262],
                [106.010235, 20.2504623],
                [106.011174, 20.2503526],
                [106.0134464, 20.2502156],
                [106.0157186, 20.2500375],
                [106.0176852, 20.2499435],
                [106.019721, 20.249896],
                [106.019862, 20.2498927],
                [106.0217945, 20.2499196],
                [106.0239534, 20.2499885],
                [106.0240844, 20.2499932],
                [106.0259486, 20.2500604],
                [106.0277426, 20.2502975],
                [106.0296688, 20.2506988],
                [106.0312898, 20.2512256],
                [106.0331303, 20.2518749],
                [106.0347533, 20.2526906],
                [106.0360699, 20.2534666],
                [106.0374304, 20.2542425],
                [106.03879, 20.2548946],
                [106.0401917, 20.2552989],
                [106.0415473, 20.2553733],
                [106.0428133, 20.255077],
                [106.0440769, 20.2544507],
                [106.0454253, 20.2534111],
                [106.0468154, 20.2520826],
                [106.0472061, 20.2516676],
                [106.0474406, 20.2513435],
                [106.0479857, 20.2505903],
                [106.0485045, 20.2497208],
                [106.0489357, 20.2490077],
                [106.0492685, 20.2483606],
                [106.0493761, 20.2481647],
                [106.0494721, 20.247897],
                [106.0496259, 20.2472899],
                [106.0497292, 20.2464774],
                [106.0497694, 20.2459942],
                [106.049798, 20.2453981],
                [106.0497788, 20.2449769],
                [106.049656, 20.2444948],
                [106.0494177, 20.243847],
                [106.049186, 20.2433659],
                [106.0489991, 20.2430692],
                [106.0488014, 20.2427829],
                [106.0485269, 20.2424252],
                [106.0480887, 20.241976],
                [106.0475886, 20.2415751],
                [106.0463501, 20.2411159],
                [106.0452079, 20.2408355],
                [106.0436003, 20.2405757],
                [106.0424977, 20.2405106],
                [106.041415, 20.2406066],
                [106.0402569, 20.2407931],
                [106.0388428, 20.2410528],
                [106.0364313, 20.2413542],
                [106.0353481, 20.2413786],
                [106.0342073, 20.2412956],
                [106.0329223, 20.2409799],
                [106.0315883, 20.2404313],
                [106.0310735, 20.2401652],
                [106.0306537, 20.2398983],
                [106.0302711, 20.2395237],
                [106.0298302, 20.2389518],
                [106.0295798, 20.2384508],
                [106.0294336, 20.2378772],
                [106.0293725, 20.2372672],
                [106.029368, 20.2365852],
                [106.0294396, 20.2359026],
                [106.0295196, 20.2350764],
                [106.0297412, 20.2340879],
                [106.0300391, 20.2331707],
                [106.0306672, 20.2318566],
                [106.0316062, 20.2301817],
                [106.0320581, 20.2295508],
                [106.0327465, 20.228739],
                [106.0337466, 20.2276741],
                [106.0357393, 20.2258494],
                [106.0377025, 20.2238812],
                [106.0386005, 20.2231759],
                [106.0403398, 20.2218192],
                [106.0423437, 20.2202637],
                [106.0439999, 20.2193025],
                [106.0458752, 20.2184118],
                [106.0477511, 20.2176288],
                [106.0489082, 20.2173166],
                [106.0500943, 20.2170942],
                [106.0514616, 20.2169783],
                [106.0534, 20.2170026],
                [106.0556722, 20.2172403],
                [106.0565095, 20.2174148],
                [106.0585478, 20.2181563],
                [106.0603305, 20.219061],
                [106.0621613, 20.2200193],
                [106.065776, 20.2220437],
                [106.0684288, 20.2237327],
                [106.0706723, 20.2252987],
                [106.0736767, 20.227896],
                [106.0751317, 20.2293293],
                [106.0771499, 20.231393],
                [106.07874, 20.233268],
                [106.0799201, 20.2349121],
                [106.0809583, 20.2366468],
                [106.0817589, 20.2383651],
                [106.0825851, 20.2410524],
                [106.0829175, 20.2423966],
                [106.0834762, 20.2448523],
                [106.0838305, 20.2466092],
                [106.0841435, 20.2478997],
                [106.0844648, 20.2490073],
                [106.0849482, 20.2500556],
                [106.0855274, 20.2512067],
                [106.0865554, 20.252432],
                [106.0877034, 20.2533101],
                [106.0888908, 20.2539571],
                [106.0903632, 20.2545637],
                [106.0917112, 20.2548634],
                [106.0927721, 20.2549337],
                [106.0938322, 20.2548886],
                [106.0946722, 20.2545394],
                [106.0955282, 20.2540084],
                [106.0960845, 20.2534459],
                [106.0965383, 20.2527243],
                [106.0969852, 20.251714],
                [106.0972492, 20.2508415],
                [106.0973167, 20.2498903],
                [106.0973269, 20.2490072],
                [106.0972433, 20.2479458],
                [106.097254, 20.2472952],
                [106.0973176, 20.2465576],
                [106.097468, 20.2461809],
                [106.0977702, 20.2456008],
                [106.0983203, 20.2443975],
                [106.0993639, 20.2426128],
                [106.1000206, 20.2413367],
                [106.1002309, 20.2407716],
                [106.1007139, 20.2387158],
                [106.1011175, 20.2362704],
                [106.1014293, 20.2348951],
                [106.1017021, 20.2334333],
                [106.101938, 20.2310612],
                [106.102203, 20.2285008],
                [106.1024645, 20.2265477],
                [106.1025681, 20.2260411],
                [106.1028076, 20.2252589],
                [106.1030763, 20.2243032],
                [106.1043894, 20.2206376],
                [106.1049142, 20.2191021],
                [106.1050947, 20.2186671],
                [106.105306, 20.2182611],
                [106.1056546, 20.2177674],
                [106.1060031, 20.2172883],
                [106.1064211, 20.2168808],
                [106.1068477, 20.2166179],
                [106.1079148, 20.2162263],
                [106.1085501, 20.2160619],
                [106.1095086, 20.2160127],
                [106.1110409, 20.2161549],
                [106.1117804, 20.2162832],
                [106.1128687, 20.216522],
                [106.1139496, 20.2168188],
                [106.1166188, 20.2177414],
                [106.1176229, 20.2179953],
                [106.1228577, 20.2192195],
                [106.1248817, 20.2197992],
                [106.1281717, 20.2208334],
                [106.1303188, 20.2214845],
                [106.133441, 20.2225919],
                [106.1346379, 20.2230612],
                [106.1379773, 20.2245574],
                [106.1426059, 20.2265656],
                [106.1443234, 20.2270458],
                [106.1452126, 20.2272425],
                [106.1461163, 20.2273811],
                [106.1473792, 20.2274163],
                [106.1491542, 20.2273902],
                [106.1496435, 20.2273436],
                [106.1501326, 20.2272537],
                [106.1505294, 20.227121],
                [106.1508346, 20.2269889],
                [106.1513523, 20.2266385],
                [106.1515044, 20.2265075],
                [106.1517552, 20.2262746],
                [106.1519512, 20.2258974],
                [106.1521174, 20.2256072],
                [106.1522835, 20.2252736],
                [106.1524038, 20.2249982],
                [106.1526129, 20.2240819],
                [106.1527375, 20.223536],
                [106.1528109, 20.2231163],
                [106.1528853, 20.2218004],
                [106.1529216, 20.2204992],
                [106.1529935, 20.2188362],
                [106.153063, 20.2178818],
                [106.1533044, 20.2163769],
                [106.1535564, 20.2152766],
                [106.1540997, 20.2132058],
                [106.1549315, 20.2108441],
                [106.1567176, 20.2061199],
                [106.1574831, 20.2041345],
                [106.1577923, 20.2035108],
                [106.1583345, 20.2023363],
                [106.1589867, 20.2005396],
                [106.1596212, 20.198396],
                [106.160253, 20.1959054],
                [106.1609304, 20.1933712],
                [106.1612747, 20.1923426],
                [106.1618037, 20.1904454],
                [106.1620255, 20.1894032],
                [106.1622069, 20.1880721],
                [106.1624256, 20.1855988],
                [106.1625477, 20.1824612],
                [106.1625859, 20.18142],
                [106.1626248, 20.1794538],
                [106.1627998, 20.1772555],
                [106.1628827, 20.1749998],
                [106.1629495, 20.1726865],
                [106.1627491, 20.1683512],
                [106.162663, 20.1670652],
                [106.1626273, 20.1653452],
                [106.1628479, 20.1631032],
                [106.1632055, 20.1607735],
                [106.1636541, 20.1583564],
                [106.1641486, 20.155939],
                [106.1646276, 20.1535074],
                [106.1650914, 20.1510613],
                [106.1657826, 20.148368],
                [106.1661383, 20.1468334],
                [106.1666842, 20.1451529],
                [106.1669951, 20.1437776],
                [106.1672796, 20.1419254],
                [106.1675771, 20.139755],
                [106.1678136, 20.1376286],
                [106.1679401, 20.1361243],
                [106.1680296, 20.1353802],
                [106.1681489, 20.1343883],
                [106.1681968, 20.1326098],
                [106.1681642, 20.1313092],
                [106.1679883, 20.1302695],
                [106.167894, 20.1299233],
                [106.1674956, 20.1287839],
                [106.1670143, 20.1278185],
                [106.1666895, 20.1273436],
                [106.1663199, 20.1269991],
                [106.1654187, 20.1261089],
                [106.1633264, 20.1244458],
                [106.1625976, 20.1238755],
                [106.162274, 20.1236222],
                [106.1619343, 20.1233564],
                [106.1606274, 20.1224109],
                [106.1597802, 20.1215926],
                [106.1590486, 20.1208746],
                [106.158447, 20.1201846],
                [106.157814, 20.1193648],
                [106.1575453, 20.1189935],
                [106.157335, 20.118703],
                [106.1570644, 20.1182856],
                [106.1556915, 20.1156347],
                [106.1543026, 20.1128828],
                [106.1541613, 20.1126054],
                [106.1538664, 20.1120266],
                [106.1529703, 20.1105353],
                [106.1523281, 20.1095131],
                [106.1516158, 20.1082891],
                [106.1505843, 20.1062864],
                [106.1494893, 20.1039806],
                [106.1485933, 20.101688],
                [106.1478197, 20.0993946],
                [106.1470775, 20.0972166],
                [106.1465408, 20.0959799],
                [106.1461302, 20.0949747],
                [106.1458202, 20.0935522],
                [106.1456509, 20.0924381],
                [106.1454303, 20.0910941],
                [106.145249, 20.0893234],
                [106.1450382, 20.0873945],
                [106.1450455, 20.0862516],
                [106.1448749, 20.084733],
                [106.1447338, 20.0829872],
                [106.1446058, 20.0815042],
                [106.1442495, 20.0793824],
                [106.1441657, 20.0772314],
                [106.1440207, 20.0759883],
                [106.1439694, 20.0750052],
                [106.1438494, 20.0738542],
                [106.1436256, 20.0724594],
                [106.1433639, 20.0711509],
                [106.1430116, 20.0695919],
                [106.1426034, 20.0685136],
                [106.1418273, 20.0664894],
                [106.1415248, 20.0659093],
                [106.1408222, 20.0646044],
                [106.1404576, 20.0640568],
                [106.1398611, 20.0632381],
                [106.1394087, 20.0626525],
                [106.1386866, 20.0618594],
                [106.1377365, 20.0609749],
                [106.1369665, 20.0603544],
                [106.1357359, 20.0593497],
                [106.134546, 20.0585951],
                [106.1334657, 20.0581168],
                [106.132383, 20.0576914],
                [106.130326, 20.0570245],
                [106.1281268, 20.0565288],
                [106.1270237, 20.0563316],
                [106.1263993, 20.0561523],
                [106.1258159, 20.0559477],
                [106.1244329, 20.0552961],
                [106.1239006, 20.0551013],
                [106.1232906, 20.0549105],
                [106.122579, 20.0546009],
                [106.1215613, 20.0540524],
                [106.1205667, 20.0535186],
                [106.1197142, 20.0528953],
                [106.118719, 20.0519313],
                [106.117678, 20.0508002],
                [106.1168013, 20.0500134],
                [106.1162864, 20.0494009],
                [106.1157819, 20.0487417],
                [106.1152667, 20.0478216],
                [106.114866, 20.0466475],
                [106.1146176, 20.0454905],
                [106.1142987, 20.0439245],
                [106.1138942, 20.0415622],
                [106.113696, 20.0390902],
                [106.1135326, 20.0363029],
                [106.11341, 20.0351298],
                [106.1133722, 20.0347104],
                [106.1132896, 20.0340244],
                [106.1130535, 20.0323231],
                [106.1128035, 20.0297784],
                [106.1126805, 20.0279172],
                [106.112599, 20.0248918],
                [106.1124624, 20.023078],
                [106.111804, 20.0196577],
                [106.1113591, 20.0179922],
                [106.1103441, 20.0140958],
                [106.109573, 20.0118492],
                [106.1086008, 20.0087755],
                [106.1079258, 20.0067787],
                [106.1070228, 20.0044507],
                [106.1060472, 20.0022969],
                [106.1050684, 19.9999365],
                [106.10503, 19.9997852],
                [106.1043832, 19.9982553],
                [106.1021498, 19.9922694],
                [106.1007479, 19.9886734],
                [106.0992435, 19.9847544],
                [106.098309, 19.9823955],
                [106.0976155, 19.980248],
                [106.0971878, 19.9788169],
                [106.0968223, 19.9775944],
                [106.0965263, 19.9762671],
                [106.095935, 19.973236],
                [106.0956673, 19.9718804],
                [106.0954881, 19.97085],
                [106.0954256, 19.9701174],
                [106.0951893, 19.9690315],
                [106.0951438, 19.9680251],
                [106.0952295, 19.9666995],
                [106.0952411, 19.9653615],
                [106.0953484, 19.9640451],
                [106.0953998, 19.9632677],
                [106.0955799, 19.9594679],
                [106.0959552, 19.9580131],
                [106.0961297, 19.9567534],
                [106.0962779, 19.9553706],
                [106.0966555, 19.9542119],
                [106.0975021, 19.9517424],
                [106.097831, 19.9506612],
                [106.0983912, 19.9494465],
                [106.0990052, 19.948263],
                [106.0996235, 19.9470529],
                [106.1004593, 19.9447804],
                [106.1019224, 19.941723],
                [106.1026873, 19.9401752],
                [106.1035516, 19.9385794],
                [106.1066753, 19.9349688],
                [106.1073102, 19.9324086],
                [106.1074706, 19.9311014],
                [106.1073854, 19.9297741],
                [106.1070692, 19.9283343],
                [106.106297, 19.9265096],
                [106.1003258, 19.9188151],
                [106.0949781, 19.9116702],
                [106.0928323, 19.9081194],
                [106.0904805, 19.9037614],
                [106.0887811, 19.8999683],
                [106.0878026, 19.8973373],
                [106.0872361, 19.894908],
                [106.0869443, 19.8922366],
                [106.2035325, 19.7138161],
                [106.2221629, 19.7236123],
                [106.24412, 19.7377483],
                [106.2597297, 19.749402],
                [106.2737333, 19.7617863],
                [106.285703, 19.7739619],
                [106.2970594, 19.7872912],
                [106.3071671, 19.8014734],
                [106.3170983, 19.8188024],
                [106.4512653, 19.9715238],
                [106.4703299, 19.9904513],
                [106.4807084, 19.9893013],
                [106.4984171, 19.9886318],
                [106.5149924, 19.9891016],
                [106.5442611, 19.9920617],
                [106.5692869, 19.9965811],
                [106.5868148, 20.0011671],
                [106.6118992, 20.0049572],
                [106.627902, 20.0090397],
                [106.6747277, 20.026151],
                [106.6981849, 20.0373258],
                [106.7130191, 20.0460697],
                [106.728234, 20.0568],
                [106.7423624, 20.068757],
                [106.7552952, 20.0818415],
                [106.7669335, 20.0959466],
                [106.7771893, 20.1109583],
                [106.785986, 20.1267566],
                [106.7957738, 20.148953],
                [106.6028738, 20.2373147],
                [106.596576, 20.2404856],
                [106.5918231, 20.2433142],
                [106.5880895, 20.246787],
                [106.5879331, 20.2474297],
                [106.5877674, 20.2480703],
                [106.5875922, 20.2487087],
                [106.5874078, 20.2493448],
                [106.587214, 20.2499784],
                [106.5870109, 20.2506095],
                [106.5867986, 20.2512379],
                [106.5865771, 20.2518635],
                [106.5863465, 20.2524861],
                [106.5861067, 20.2531058],
                [106.5858579, 20.2537222],
                [106.5855966, 20.2543434],
                [106.5853261, 20.2549612],
                [106.5850464, 20.2555753],
                [106.5847577, 20.2561857],
                [106.5844599, 20.2567922],
                [106.5841532, 20.2573949],
                [106.5838375, 20.2579934],
                [106.5835129, 20.2585877],
                [106.5831795, 20.2591778],
                [106.5828375, 20.2597634],
                [106.5824867, 20.2603445],
                [106.5821273, 20.2609209],
                [106.5817594, 20.2614926],
                [106.574496, 20.2697356],
                [106.5701938, 20.2734292],
                [106.5664555, 20.2766522],
                [106.5653136, 20.2778231],
                [106.5641782, 20.2789996],
                [106.5630494, 20.2801817],
                [106.5619271, 20.2813692],
                [106.5608114, 20.2825621],
                [106.5598006, 20.2836538],
                [106.5587954, 20.28475],
                [106.5577957, 20.2858506],
                [106.5568015, 20.2869556],
                [106.555813, 20.288065],
                [106.5548301, 20.2891788],
                [106.5543356, 20.2897744],
                [106.5538339, 20.2903647],
                [106.5533251, 20.2909496],
                [106.5528092, 20.291529],
                [106.5522863, 20.2921029],
                [106.5517565, 20.2926711],
                [106.5512199, 20.2932337],
                [106.550698, 20.2937686],
                [106.55017, 20.2942981],
                [106.5496358, 20.2948222],
                [106.5490955, 20.2953407],
                [106.5485492, 20.2958537],
                [106.546603, 20.2975025],
                [106.5424414, 20.3006681],
                [106.5416823, 20.3012223],
                [106.5414182, 20.3014152],
                [106.5401562, 20.3023366],
                [106.5373153, 20.305039],
                [106.5364971, 20.3058173],
                [106.535543, 20.3067676],
                [106.5334323, 20.3098644],
                [106.5320465, 20.3122821],
                [106.5310953, 20.3135351],
                [106.5285469, 20.3156938],
                [106.5257176, 20.3164922],
                [106.5249852, 20.3164146],
                [106.5226537, 20.3152237],
                [106.5207329, 20.3140461],
                [106.5181862, 20.3125739],
                [106.5152024, 20.3104943],
                [106.512746, 20.3092876],
                [106.5105084, 20.30855],
                [106.5085618, 20.3083816],
                [106.5073664, 20.308392],
                [106.5053347, 20.308645],
                [106.5018687, 20.3091137],
                [106.5008625, 20.3091064],
                [106.5001456, 20.3089818],
                [106.4990023, 20.3087832],
                [106.4979393, 20.3083214],
                [106.496735, 20.3076723],
                [106.4957907, 20.3070775],
                [106.4949657, 20.3064252],
                [106.4937373, 20.305343],
                [106.4920607, 20.3031519],
                [106.4900187, 20.3007213],
                [106.4883625, 20.2987574],
                [106.4871524, 20.2975054],
                [106.4864862, 20.2967387],
                [106.4858606, 20.2960469],
                [106.4844819, 20.294912],
                [106.4835777, 20.2943164],
                [106.4825341, 20.2937595],
                [106.4806501, 20.2930214],
                [106.4765926, 20.2915285],
                [106.4742497, 20.2908319],
                [106.4710772, 20.2900102],
                [106.4692364, 20.2896109],
                [106.4658871, 20.2890923],
                [106.4615838, 20.2890528],
                [106.4589901, 20.2892818],
                [106.4545881, 20.2898831],
                [106.454214, 20.2899248],
                [106.452011, 20.2902825],
                [106.4513735, 20.2904386],
                [106.4506971, 20.2907082],
                [106.4497816, 20.2910363],
                [106.4486877, 20.2914978],
                [106.4466874, 20.2921931],
                [106.4443379, 20.2929101],
                [106.4425672, 20.2936034],
                [106.4399532, 20.2948881],
                [106.4385031, 20.2957107],
                [106.4380864, 20.2959969],
                [106.4362706, 20.2972184],
                [106.4333535, 20.2992031],
                [106.4331735, 20.2993436],
                [106.4319791, 20.3003399],
                [106.4295575, 20.3022507],
                [106.4269199, 20.3053453],
                [106.4247992, 20.3081906],
                [106.4227934, 20.310507],
                [106.4215474, 20.3118369],
                [106.4190682, 20.3147982],
                [106.4165091, 20.3177789],
                [106.414071, 20.3208907],
                [106.4121387, 20.3247335],
                [106.4101627, 20.3281808],
                [106.408497, 20.3306075],
                [106.407587, 20.3315764],
                [106.4062481, 20.3326052],
                [106.4052545, 20.3331599],
                [106.402624, 20.3350693],
                [106.4015643, 20.3357295],
                [106.4009453, 20.3362283],
                [106.4000866, 20.336938],
                [106.3993374, 20.3374948],
                [106.3984374, 20.3380717],
                [106.3972174, 20.3388602],
                [106.3960177, 20.3396485],
                [106.3952502, 20.3402367],
                [106.3942895, 20.3409728],
                [106.3927693, 20.3419346],
                [106.3921894, 20.3423189],
                [106.3912693, 20.342896],
                [106.3902985, 20.3434356],
                [106.3889048, 20.3439215],
                [106.388263, 20.3441356],
                [106.3874603, 20.3443509],
                [106.3868173, 20.3444319],
                [106.3859128, 20.3445151],
                [106.3850383, 20.344579],
                [106.3830882, 20.3447084],
                [106.3811859, 20.3445526],
                [106.3798779, 20.3445059],
                [106.3784492, 20.3444602],
                [106.3772517, 20.3443937],
                [106.3764278, 20.3444761],
                [106.375624, 20.3445774],
                [106.3746392, 20.3446802],
                [106.3738758, 20.3448002],
                [106.3731327, 20.3449199],
                [106.3722294, 20.3451549],
                [106.3712961, 20.3453902],
                [106.3704136, 20.345682],
                [106.3693806, 20.346013],
                [106.3678971, 20.3466134],
                [106.3664935, 20.3471181],
                [106.3649811, 20.3478518],
                [106.3641604, 20.3483139],
                [106.3634599, 20.3487183],
                [106.3616075, 20.3496824],
                [106.3606671, 20.3502595],
                [106.3596062, 20.3508755],
                [106.3586455, 20.3514338],
                [106.3578757, 20.3519716],
                [106.3568559, 20.3526823],
                [106.3561967, 20.3532192],
                [106.355698, 20.3536979],
                [106.3549501, 20.3544444],
                [106.3546216, 20.3548408],
                [106.3541738, 20.3553811],
                [106.3537559, 20.3559162],
                [106.3533579, 20.3564131],
                [106.3530207, 20.3569665],
                [106.352566, 20.3578817],
                [106.3517726, 20.3591794],
                [106.3511948, 20.3598296],
                [106.3503373, 20.36071],
                [106.3497798, 20.361379],
                [106.3493216, 20.3618954],
                [106.3489829, 20.3622778],
                [106.3486967, 20.3629441],
                [106.347881, 20.3649025],
                [106.3458347, 20.3700784],
                [106.3437588, 20.3741534],
                [106.3430242, 20.3752481],
                [106.3421651, 20.3761531],
                [106.3410244, 20.3769527],
                [106.3393487, 20.3774209],
                [106.3380795, 20.3773441],
                [106.3371506, 20.3770371],
                [106.3357005, 20.3761521],
                [106.330652, 20.3712281],
                [106.326359, 20.3669336],
                [106.3255756, 20.3658949],
                [106.3249252, 20.3651211],
                [106.3245387, 20.3646302],
                [106.3243483, 20.3644093],
                [106.324051, 20.3640642],
                [106.3237154, 20.3636298],
                [106.3226, 20.3625937],
                [106.3219515, 20.3620478],
                [106.321152, 20.361484],
                [106.3210278, 20.3613867],
                [106.320504, 20.3609761],
                [106.3196536, 20.3603558],
                [106.3189248, 20.3598105],
                [106.317811, 20.3589642],
                [106.3167993, 20.3582691],
                [106.315414, 20.3574248],
                [106.3137278, 20.3566778],
                [106.3124134, 20.3558899],
                [106.3117253, 20.3554014],
                [106.3104392, 20.3543853],
                [106.3094464, 20.353538],
                [106.3087172, 20.3529547],
                [106.3074671, 20.3518153],
                [106.3071258, 20.3515042],
                [106.3057668, 20.3501848],
                [106.3037685, 20.3481864],
                [106.3025118, 20.3470562],
                [106.3011626, 20.3456987],
                [106.3000677, 20.3446622],
                [106.2987797, 20.3433993],
                [106.2974619, 20.3421745],
                [106.2960332, 20.3409125],
                [106.2953437, 20.3403631],
                [106.2944426, 20.3397251],
                [106.2935659, 20.3391758],
                [106.2927131, 20.3386707],
                [106.2913159, 20.3379028],
                [106.2899645, 20.3369567],
                [106.2885295, 20.335881],
                [106.2862293, 20.3342287],
                [106.2847674, 20.3333393],
                [106.2755605, 20.3283322],
                [106.2716433, 20.3272398],
                [106.2703514, 20.3270142],
                [106.2681653, 20.3267524],
                [106.266897, 20.3266547],
                [106.2657206, 20.3267059],
                [106.2648521, 20.3268629],
                [106.2623458, 20.3277772],
                [106.2604749, 20.3287308],
                [106.259282, 20.3295512],
                [106.2581251, 20.3306063],
                [106.2573629, 20.3315305],
                [106.2563121, 20.3331191],
                [106.255031, 20.3354922],
                [106.2546762, 20.3362319],
                [106.2540478, 20.337542],
                [106.253278, 20.3396523],
                [106.2529603, 20.3408926],
                [106.2524351, 20.3428464],
                [106.252182, 20.3439934],
                [106.2520649, 20.3457276],
                [106.2520943, 20.3473369],
                [106.2523352, 20.3487591],
                [106.2525759, 20.3501502],
                [106.2529662, 20.3517879],
                [106.2532724, 20.3531785],
                [106.2536408, 20.3541354],
                [106.253943, 20.3550308],
                [106.2541675, 20.3558664],
                [106.2543966, 20.3563894],
                [106.2551934, 20.3576218],
                [106.255955, 20.3585448],
                [106.2564975, 20.3591502],
                [106.2566166, 20.359283],
                [106.2573775, 20.3601133],
                [106.2584176, 20.3610343],
                [106.2598697, 20.3622001],
                [106.2608931, 20.3630593],
                [106.2645698, 20.3656327],
                [106.2657576, 20.3665526],
                [106.270095, 20.3697091],
                [106.271644, 20.3706884],
                [106.2727643, 20.3713612],
                [106.2737872, 20.3721584],
                [106.2740899, 20.3723699],
                [106.2757164, 20.3735062],
                [106.2769365, 20.374333],
                [106.2779259, 20.3750376],
                [106.2787179, 20.3756509],
                [106.279444, 20.3762337],
                [106.2810122, 20.3775221],
                [106.2823349, 20.3788743],
                [106.2834607, 20.380197],
                [106.2845732, 20.3818911],
                [106.285408, 20.3837111],
                [106.28588, 20.3852552],
                [106.286268, 20.3865833],
                [106.2864746, 20.3877579],
                [106.2865178, 20.3890267],
                [106.2864818, 20.3893175],
                [106.2863488, 20.3903897],
                [106.2861603, 20.3913816],
                [106.285744, 20.3925917],
                [106.2852166, 20.3942362],
                [106.284506, 20.3955414],
                [106.2837272, 20.3965376],
                [106.2816644, 20.3989051],
                [106.2805244, 20.399842],
                [106.2793181, 20.4007176],
                [106.278307, 20.401375],
                [106.277427, 20.4020313],
                [106.2764311, 20.4025649],
                [106.275221, 20.4029761],
                [106.2741414, 20.4032935],
                [106.272832, 20.4035816],
                [106.2712928, 20.4038406],
                [106.2697371, 20.4040994],
                [106.2678736, 20.4046351],
                [106.2666293, 20.4050507],
                [106.2655177, 20.4054611],
                [106.2643391, 20.4057172],
                [106.2629474, 20.4059688],
                [106.2611452, 20.4061673],
                [106.2591127, 20.4062749],
                [106.258937, 20.4062762],
                [106.2556695, 20.4062997],
                [106.2546026, 20.4062269],
                [106.2540288, 20.4061878],
                [106.2518705, 20.4059254],
                [106.2460137, 20.4057713],
                [106.2441124, 20.4056972],
                [106.2417674, 20.405714],
                [106.2379704, 20.4059413],
                [106.2363151, 20.4061818],
                [106.2343164, 20.406482],
                [106.2318177, 20.4068144],
                [106.2290226, 20.4072346],
                [106.2277733, 20.4074151],
                [106.2266815, 20.4077374],
                [106.225403, 20.4081754],
                [106.2244986, 20.4084678],
                [106.2233766, 20.4089334],
                [106.2222187, 20.4093593],
                [106.2210873, 20.4100651],
                [106.2201855, 20.4107007],
                [106.2195944, 20.4110767],
                [106.2188497, 20.4117971],
                [106.2182288, 20.4123736],
                [106.2176703, 20.412921],
                [106.2169894, 20.4138124],
                [106.2162397, 20.4146861],
                [106.2156118, 20.415567],
                [106.2149314, 20.4165443],
                [106.2144379, 20.417406],
                [106.213696, 20.4184981],
                [106.2131546, 20.4192456],
                [106.2121353, 20.4208546],
                [106.2113351, 20.4224907],
                [106.2107178, 20.4238828],
                [106.2101415, 20.4252526],
                [106.2097592, 20.4261874],
                [106.2096701, 20.4267888],
                [106.209637, 20.4272796],
                [106.2096448, 20.4282807],
                [106.209701, 20.4291404],
                [106.20978, 20.429567],
                [106.2100829, 20.4303087],
                [106.2112209, 20.4318741],
                [106.2126304, 20.4341527],
                [106.2147501, 20.4372559],
                [106.2167266, 20.4400453],
                [106.2179632, 20.4421821],
                [106.2204177, 20.4460838],
                [106.222087, 20.4495333],
                [106.223048, 20.4524157],
                [106.223557, 20.453499],
                [106.2239573, 20.4546691],
                [106.2250749, 20.4575503],
                [106.2254279, 20.4586921],
                [106.2256727, 20.4599775],
                [106.2259062, 20.4618066],
                [106.2259475, 20.463065],
                [106.2259242, 20.4640663],
                [106.225759, 20.4649257],
                [106.2253332, 20.4664448],
                [106.2247806, 20.4677644],
                [106.2238254, 20.4696018],
                [106.2234385, 20.4701195],
                [106.2225088, 20.4712131],
                [106.2211132, 20.4727389],
                [106.2203671, 20.4733163],
                [106.2191526, 20.4740113],
                [106.2168318, 20.475229],
                [106.2142781, 20.4766771],
                [106.2079326, 20.4792672],
                [106.2034313, 20.479699],
                [106.1974609, 20.4795769],
                [106.1929656, 20.479485],
                [106.19195, 20.4796064],
                [106.1897947, 20.4800217],
                [106.1887338, 20.4803722],
                [106.1877057, 20.4809228],
                [106.1853415, 20.4826553],
                [106.1842389, 20.4836925],
                [106.1832245, 20.4847086],
                [106.1816619, 20.4828143],
                [106.1810436, 20.4818452],
                [106.1802778, 20.4801229],
                [106.1801569, 20.4799571],
                [106.1800418, 20.4797991],
                [106.1789608, 20.478463],
                [106.1782669, 20.4781821],
                [106.1774234, 20.4779612],
                [106.1766954, 20.4773889],
                [106.1763189, 20.4770855],
                [106.1757708, 20.4764745],
                [106.1732854, 20.4740588],
                [106.1726095, 20.4735794],
                [106.1721214, 20.4733342],
                [106.1717343, 20.473206],
                [106.171283, 20.4731022],
                [106.1702093, 20.4729525],
                [106.1685498, 20.4728852],
                [106.165775, 20.4728385],
                [106.1635959, 20.472849],
                [106.1620204, 20.4729299],
                [106.1597717, 20.4729274],
                [106.1582221, 20.4726038],
                [106.1556028, 20.4718831],
                [106.154793, 20.4715368],
                [106.1540211, 20.4711202],
                [106.1509995, 20.4692771],
                [106.1502086, 20.4688956],
                [106.1495094, 20.4686612],
                [106.1486781, 20.4685542],
                [106.1479671, 20.4685589],
                [106.1473333, 20.4686158],
                [106.1466811, 20.4687783],
                [106.1459909, 20.4690114],
                [106.145321, 20.4693849],
                [106.1437636, 20.4705903],
                [106.1433832, 20.4704431],
                [106.1430011, 20.4714214],
                [106.1423147, 20.4721817],
                [106.1414456, 20.4729076],
                [106.1416292, 20.4730475],
                [106.1412588, 20.47363],
                [106.1408608, 20.4743884],
                [106.1404641, 20.4753225],
                [106.1400023, 20.4765559],
                [106.1394206, 20.4784413],
                [106.1391764, 20.4792328],
                [106.1390191, 20.4800425],
                [106.1389157, 20.4816602],
                [106.1389182, 20.4832771],
                [106.1389427, 20.4839976],
                [106.1392245, 20.4870189],
                [106.1395753, 20.4889852],
                [106.1398736, 20.490319],
                [106.1401676, 20.4923911],
                [106.1405461, 20.4946674],
                [106.1352276, 20.4951737],
                [106.1346208, 20.4952755],
                [106.1332528, 20.4965916],
                [106.1307531, 20.4990077],
                [106.1300829, 20.4991935],
                [106.1291225, 20.4991654],
                [106.1283755, 20.4989028],
                [106.127934, 20.4980051],
                [106.1277403, 20.4977491],
                [106.1274377, 20.4974879],
                [106.1271421, 20.4972909],
                [106.1266158, 20.4970371],
                [106.1260896, 20.496795],
                [106.1255768, 20.4966345],
                [106.1249615, 20.4964513],
                [106.1243403, 20.4963326],
                [106.1237, 20.4962432],
                [106.1228936, 20.4961549],
                [106.122254, 20.496159],
                [106.1213005, 20.4960774],
                [106.1209424, 20.4960914],
                [106.1205716, 20.4961172],
                [106.1202271, 20.4962247],
                [106.1198822, 20.4963087],
                [106.1193452, 20.496324],
                [106.1188719, 20.496327],
                [106.1181745, 20.4962906],
                [106.1175605, 20.4962945],
                [106.1169468, 20.4963219],
                [106.1163462, 20.4963842],
                [106.1157836, 20.4964346],
                [106.1153104, 20.4964376],
                [106.1150096, 20.496422],
                [106.1145609, 20.4962729],
                [106.1142801, 20.4961259],
                [106.1139055, 20.495856],
                [106.1137506, 20.4956582],
                [106.1135816, 20.4952967],
                [106.113412, 20.4948417],
                [106.1133394, 20.4945323],
                [106.1132855, 20.494135],
                [106.1132572, 20.4937726],
                [106.1132167, 20.4934688],
                [106.1131378, 20.4931885],
                [106.1130335, 20.4929086],
                [106.1127814, 20.4925301],
                [106.1124581, 20.4920527],
                [106.1121486, 20.4917038],
                [106.1118522, 20.4913899],
                [106.1114662, 20.4910649],
                [106.1111957, 20.4908211],
                [106.1109, 20.4906007],
                [106.1105784, 20.4903454],
                [106.110206, 20.4901491],
                [106.1098466, 20.4899759],
                [106.1093465, 20.4898037],
                [106.1084875, 20.4895384],
                [106.1067593, 20.4893273],
                [106.1062591, 20.4892429],
                [106.1060335, 20.4892048],
                [106.1056132, 20.4892624],
                [106.1049447, 20.4892874],
                [106.1042384, 20.4893056],
                [106.103457, 20.4893105],
                [106.1028141, 20.4893145],
                [106.1024016, 20.4892712],
                [106.1022297, 20.4892531],
                [106.1017088, 20.4891257],
                [106.1012496, 20.4888967],
                [106.1008512, 20.4885343],
                [106.1005652, 20.488216],
                [106.1004328, 20.4879671],
                [106.1002961, 20.4877619],
                [106.1001436, 20.4874605],
                [106.0999911, 20.4871318],
                [106.099822, 20.4866107],
                [106.0997418, 20.4863686],
                [106.1004205, 20.4860789],
                [106.1007886, 20.4859021],
                [106.1010672, 20.4856909],
                [106.1012057, 20.4854806],
                [106.1013185, 20.4852238],
                [106.1015429, 20.4845476],
                [106.1021082, 20.4825782],
                [106.102251, 20.4815665],
                [106.1017031, 20.4813798],
                [106.0994631, 20.4806854],
                [106.0994837, 20.4801126],
                [106.09958, 20.4797503],
                [106.0997741, 20.4792369],
                [106.0988429, 20.478549],
                [106.0984105, 20.4783475],
                [106.0986338, 20.4780575],
                [106.0989472, 20.477767],
                [106.0992008, 20.4774906],
                [106.0996029, 20.4769933],
                [106.0998628, 20.4765588],
                [106.1001751, 20.4760897],
                [106.1004879, 20.4757029],
                [106.1008005, 20.4753024],
                [106.1011285, 20.4749293],
                [106.1015317, 20.4745833],
                [106.1018225, 20.4742723],
                [106.102031, 20.4740099],
                [106.1022839, 20.4736509],
                [106.1025066, 20.4732786],
                [106.1029687, 20.4727397],
                [106.1034972, 20.4720562],
                [106.1039442, 20.4715313],
                [106.1042416, 20.4711034],
                [106.1044643, 20.4707171],
                [106.1046712, 20.4702349],
                [106.1048411, 20.4698285],
                [106.1050616, 20.46914],
                [106.1051931, 20.4686308],
                [106.1052654, 20.4682318],
                [106.1052834, 20.4676065],
                [106.1052795, 20.4670432],
                [106.1052603, 20.4664799],
                [106.1052123, 20.4660679],
                [106.105194, 20.4656146],
                [106.1051395, 20.4653333],
                [106.1050163, 20.4649356],
                [106.1048777, 20.4644693],
                [106.1046483, 20.4639073],
                [106.1044798, 20.4634549],
                [106.1042284, 20.4629687],
                [106.1038631, 20.4623115],
                [106.1035743, 20.4618598],
                [106.1033152, 20.4613394],
                [106.1029948, 20.4606681],
                [106.1027435, 20.4602093],
                [106.1025147, 20.4597298],
                [106.1022708, 20.4592229],
                [106.1019658, 20.4586065],
                [106.1018447, 20.4582115],
                [106.1017675, 20.457878],
                [106.1015767, 20.4572345],
                [106.1014644, 20.4567753],
                [106.1014322, 20.4564731],
                [106.1013555, 20.4562401],
                [106.1012485, 20.4560072],
                [106.1009756, 20.4556654],
                [106.1006763, 20.4553913],
                [106.1004772, 20.4552838],
                [106.1001388, 20.4552515],
                [106.0995681, 20.4552826],
                [106.0990724, 20.4552995],
                [106.098367, 20.455414],
                [106.0976466, 20.4555146],
                [106.0968511, 20.4556297],
                [106.0965136, 20.4557073],
                [106.0959888, 20.4558617],
                [106.0954191, 20.4560302],
                [106.0949093, 20.4561709],
                [106.0945641, 20.4562417],
                [106.0942339, 20.4562851],
                [106.0936329, 20.4562888],
                [106.0932274, 20.4563189],
                [106.0928072, 20.4563764],
                [106.0924996, 20.4564379],
                [106.092125, 20.4565913],
                [106.0917808, 20.456772],
                [106.0916164, 20.4568877],
                [106.0914068, 20.4570352],
                [106.0908688, 20.4574231],
                [106.0904206, 20.4577966],
                [106.0899132, 20.4582668],
                [106.0895097, 20.4585851],
                [106.0892115, 20.4589028],
                [106.0889586, 20.4592615],
                [106.088551, 20.4598594],
                [106.0883132, 20.460199],
                [106.088139, 20.4604817],
                [106.0880197, 20.460724],
                [106.0878464, 20.4611175],
                [106.0875646, 20.461753],
                [106.0873132, 20.4623784],
                [106.0871096, 20.4629305],
                [106.0869737, 20.4632926],
                [106.0868279, 20.4636367],
                [106.0866821, 20.4639627],
                [106.0865162, 20.4642619],
                [106.0863305, 20.4645429],
                [106.0860759, 20.4648787],
                [106.0859239, 20.4650619],
                [106.085708, 20.4652622],
                [106.0855407, 20.4653807],
                [106.0853536, 20.4654541],
                [106.0851957, 20.4654732],
                [106.0849881, 20.4654745],
                [106.0847503, 20.4653946],
                [106.084532, 20.4652605],
                [106.0843629, 20.4650898],
                [106.0842031, 20.4648829],
                [106.0840532, 20.4646308],
                [106.0839326, 20.4643243],
                [106.083821, 20.4639274],
                [106.0837101, 20.4636298],
                [106.0835544, 20.4632512],
                [106.0834043, 20.4629901],
                [106.083195, 20.4627473],
                [106.0829068, 20.4624961],
                [106.0826284, 20.4622628],
                [106.0823753, 20.4620927],
                [106.082127, 20.4619226],
                [106.0817998, 20.4617529],
                [106.0813835, 20.4616108],
                [106.0808888, 20.4615054],
                [106.0802757, 20.461437],
                [106.0797765, 20.461431],
                [106.0794607, 20.4614782],
                [106.0790957, 20.4615799],
                [106.0788001, 20.4617083],
                [106.0783769, 20.4619459],
                [106.0777177, 20.4623566],
                [106.077487, 20.4625659],
                [106.0772416, 20.4628114],
                [106.0769769, 20.4631113],
                [106.0766831, 20.4634836],
                [106.0764779, 20.4638284],
                [106.076307, 20.4641186],
                [106.0762004, 20.4644085],
                [106.0761243, 20.4648337],
                [106.0760202, 20.4655031],
                [106.0759194, 20.4659745],
                [106.0757889, 20.4664491],
                [106.0756834, 20.4669082],
                [106.0756023, 20.4672525],
                [106.0755121, 20.4675205],
                [106.0753884, 20.4677581],
                [106.0752226, 20.4679654],
                [106.0749567, 20.4681962],
                [106.074711, 20.4683291],
                [106.0743688, 20.4684153],
                [106.0739342, 20.468441],
                [106.0732153, 20.4684531],
                [106.0726799, 20.4684106],
                [106.072048, 20.468315],
                [106.0715789, 20.4681802],
                [106.0711514, 20.4680224],
                [106.070388, 20.4676754],
                [106.0693855, 20.4671616],
                [106.0684037, 20.46664],
                [106.0672743, 20.4659435],
                [106.0668125, 20.4656559],
                [106.0664851, 20.4654667],
                [106.0661035, 20.4652931],
                [106.0658436, 20.4651954],
                [106.0657013, 20.4651581],
                [106.0655758, 20.4651435],
                [106.0655005, 20.4651364],
                [106.0653333, 20.465145],
                [106.0651582, 20.4652072],
                [106.0649917, 20.4653154],
                [106.0645595, 20.4656697],
                [106.0639781, 20.466239],
                [106.063318, 20.4669082],
                [106.0626549, 20.4677304],
                [106.0621725, 20.4682905],
                [106.061707, 20.4689204],
                [106.0605083, 20.4706938],
                [106.0596786, 20.4716059],
                [106.0595318, 20.4718056],
                [106.0589891, 20.4722654],
                [106.0584252, 20.4725924],
                [106.0577897, 20.4728226],
                [106.0571186, 20.4730206],
                [106.0566946, 20.4731202],
                [106.0559498, 20.4731998],
                [106.0552176, 20.4732539],
                [106.0536219, 20.4731375],
                [106.0526005, 20.4730899],
                [106.0515571, 20.4730094],
                [106.0507022, 20.4729072],
                [106.0497113, 20.4727977],
                [106.0489639, 20.4726882],
                [106.0481225, 20.4725636],
                [106.0472903, 20.4723974],
                [106.0463157, 20.471975],
                [106.0454707, 20.4715476],
                [106.0444013, 20.4708537],
                [106.0437704, 20.4701528],
                [106.0434637, 20.4695621],
                [106.0433381, 20.4686884],
                [106.0434333, 20.4672372],
                [106.0436336, 20.4658157],
                [106.0436211, 20.4653712],
                [106.0435789, 20.4647785],
                [106.0435575, 20.4644474],
                [106.0434699, 20.4641775],
                [106.0432373, 20.4636208],
                [106.0430171, 20.4631596],
                [106.042774, 20.4626334],
                [106.0426576, 20.4625083],
                [106.0425215, 20.462108],
                [106.0422576, 20.4611592],
                [106.0420601, 20.4601663],
                [106.0418257, 20.4593306],
                [106.0416688, 20.4586863],
                [106.0416187, 20.4583292],
                [106.0415959, 20.4577712],
                [106.0416926, 20.4566814],
                [106.0416982, 20.4559919],
                [106.0417594, 20.4551632],
                [106.0418695, 20.4545347],
                [106.042091, 20.4534523],
                [106.0423519, 20.4525613],
                [106.0424715, 20.4519211],
                [106.042524, 20.4512237],
                [106.0425023, 20.4508403],
                [106.0424629, 20.450649],
                [106.0423855, 20.4504926],
                [106.0421935, 20.4502846],
                [106.0417907, 20.4499385],
                [106.0411384, 20.4493325],
                [106.0406587, 20.4488821],
                [106.0402172, 20.4484491],
                [106.0397374, 20.4480163],
                [106.038976, 20.447433],
                [106.038285, 20.4466993],
                [106.037884, 20.4465435],
                [106.0371762, 20.4464379],
                [106.0364685, 20.4463446],
                [106.0355349, 20.446399],
                [106.0341976, 20.4465258],
                [106.033435, 20.4465726],
                [106.0326955, 20.4466193],
                [106.0320137, 20.446655],
                [106.0315169, 20.4466896],
                [106.0310891, 20.4466711],
                [106.0308573, 20.4465985],
                [106.0306718, 20.4464939],
                [106.030394, 20.4464427],
                [106.0300935, 20.4464551],
                [106.029781, 20.4463935],
                [106.0295199, 20.4462383],
                [106.029194, 20.4459019],
                [106.0288794, 20.4455443],
                [106.0287622, 20.4453019],
                [106.0287378, 20.4451117],
                [106.0287599, 20.4449425],
                [106.0288623, 20.4447092],
                [106.0290739, 20.4443802],
                [106.029533, 20.4438806],
                [106.0296703, 20.4436788],
                [106.0297271, 20.4434988],
                [106.0297598, 20.4432343],
                [106.0297679, 20.4427162],
                [106.0298871, 20.4424089],
                [106.0301391, 20.4420268],
                [106.0302644, 20.4417617],
                [106.0303549, 20.4414652],
                [106.0303634, 20.4410105],
                [106.0303083, 20.4405668],
                [106.0300726, 20.4398916],
                [106.0296496, 20.4388579],
                [106.0292914, 20.4380036],
                [106.0289528, 20.4375087],
                [106.0284864, 20.4368877],
                [106.0277825, 20.4362152],
                [106.0267585, 20.4351851],
                [106.0261938, 20.4345647],
                [106.0258682, 20.43426],
                [106.025391, 20.4337659],
                [106.0252277, 20.4335554],
                [106.0248943, 20.4329442],
                [106.0245663, 20.4322905],
                [106.024245, 20.4313763],
                [106.02403, 20.4307501],
                [106.0238056, 20.4300114],
                [106.0237098, 20.4295149],
                [106.023535, 20.4284164],
                [106.0233326, 20.42754],
                [106.0232661, 20.4271071],
                [106.023265, 20.4269378],
                [106.0232637, 20.4267264],
                [106.0233315, 20.4264829],
                [106.0236493, 20.4256246],
                [106.0237335, 20.425254],
                [106.0237311, 20.4248946],
                [106.0237054, 20.4245247],
                [106.0235747, 20.4239651],
                [106.0234152, 20.4234374],
                [106.0230754, 20.4227522],
                [106.0225258, 20.4218145],
                [106.0217468, 20.4202331],
                [106.0212153, 20.4193869],
                [106.020608, 20.4184213],
                [106.0202102, 20.4176941],
                [106.0199567, 20.4169344],
                [106.0198617, 20.4165543],
                [106.0198228, 20.4159202],
                [106.0198104, 20.4149264],
                [106.0198279, 20.4140594],
                [106.0198994, 20.4135303],
                [106.0199661, 20.4131175],
                [106.0201243, 20.4125775],
                [106.0203058, 20.4120688],
                [106.0205745, 20.4116233],
                [106.0209186, 20.4112194],
                [106.021183, 20.4110171],
                [106.021494, 20.4108672],
                [106.0218981, 20.410812],
                [106.0223485, 20.4107564],
                [106.0229029, 20.4107215],
                [106.023226, 20.4106456],
                [106.0234969, 20.4105488],
                [106.0237271, 20.4103889],
                [106.0238419, 20.4102719],
                [106.0239564, 20.4101127],
                [106.0240248, 20.4099854],
                [106.0241046, 20.4098157],
                [106.0241721, 20.4095299],
                [106.024273, 20.4090746],
                [106.0243277, 20.408588],
                [106.0243875, 20.4080378],
                [106.0243943, 20.4073084],
                [106.0244358, 20.4066209],
                [106.0244841, 20.4060497],
                [106.0245163, 20.4056688],
                [106.0245725, 20.405436],
                [106.0246978, 20.4051814],
                [106.024893, 20.4049793],
                [106.0252546, 20.4046284],
                [106.0257721, 20.4042341],
                [106.0261401, 20.4039782],
                [106.0267618, 20.403615],
                [106.0271186, 20.4034016],
                [106.0273833, 20.4032308],
                [106.0275961, 20.4030815],
                [106.0277338, 20.4029433],
                [106.0279058, 20.4027414],
                [106.0280086, 20.4025611],
                [106.0280648, 20.4023281],
                [106.0281203, 20.4019683],
                [106.0280974, 20.4017925],
                [106.0280441, 20.4017322],
                [106.0278847, 20.4016847],
                [106.0274609, 20.4017356],
                [106.026256, 20.4018881],
                [106.0253688, 20.4019903],
                [106.0245876, 20.4020675],
                [106.0239521, 20.4021804],
                [106.0234229, 20.4022924],
                [106.0228404, 20.4023685],
                [106.0218735, 20.4024469],
                [106.0211117, 20.4024778],
                [106.0206742, 20.4024561],
                [106.0203426, 20.4023854],
                [106.0192396, 20.4019431],
                [106.0180704, 20.4014893],
                [106.0177046, 20.4012732],
                [106.0173455, 20.4010813],
                [106.0170263, 20.4009134],
                [106.0164943, 20.4006013],
                [106.0159623, 20.4003135],
                [106.01547, 20.4000132],
                [106.015084, 20.3997487],
                [106.0148045, 20.3995685],
                [106.0145477, 20.3994374],
                [106.0143848, 20.3993771],
                [106.014203, 20.3993432],
                [106.0139405, 20.3993448],
                [106.0139067, 20.399345],
                [106.0136106, 20.3993729],
                [106.0131909, 20.3994714],
                [106.0126757, 20.3996142],
                [106.0119889, 20.3998192],
                [106.0112546, 20.4000244],
                [106.0105202, 20.4002733],
                [106.0097719, 20.4005589],
                [106.0089047, 20.4009312],
                [106.0079804, 20.4013387],
                [106.0071563, 20.4017282],
                [106.0068038, 20.4018876],
                [106.0064796, 20.4020206],
                [106.0060326, 20.4022209],
                [106.0055335, 20.4023948],
                [106.0050162, 20.4024923],
                [106.0045506, 20.4024399],
                [106.0041626, 20.4022567],
                [106.0037401, 20.4019342],
                [106.0033729, 20.4015594],
                [106.0030595, 20.4010809],
                [106.0028387, 20.4005917],
                [106.0025228, 20.4007252],
                [106.0022997, 20.4007694],
                [106.002061, 20.4007333],
                [106.0018223, 20.4006661],
                [106.0014019, 20.400504],
                [106.0003212, 20.400192],
                [106.0004299, 20.3998297],
                [105.9989047, 20.3993469],
                [106.0003489, 20.396221],
                [105.9997266, 20.3960206],
                [105.9989789, 20.3958951],
                [105.9975038, 20.3957366],
                [105.9955617, 20.3953691],
                [105.995404, 20.3967217],
                [105.9969822, 20.3979135],
                [105.9968401, 20.3983206],
                [105.996132, 20.3984579],
                [105.996339, 20.399472],
                [105.995451, 20.3996107],
                [105.9954954, 20.4006471],
                [105.9935151, 20.400868],
                [105.993457, 20.4003067],
                [105.9927817, 20.400434],
                [105.9926469, 20.4008472],
                [105.9925632, 20.4011262],
                [105.9928129, 20.4012071],
                [105.9926083, 20.4015457],
                [105.9922291, 20.4016505],
                [105.9921266, 20.4019468],
                [105.9918983, 20.4019995],
                [105.9917352, 20.4025094],
                [105.9915115, 20.4030181],
                [105.9914664, 20.403708],
                [105.9892991, 20.4039309],
                [105.9894419, 20.4042269],
                [105.9895107, 20.4047076],
                [105.9897391, 20.4064908],
                [105.9897365, 20.4078006],
                [105.9897655, 20.4093453],
                [105.9896716, 20.4104209],
                [105.9909083, 20.4104719],
                [105.9912696, 20.4105057],
                [105.9914605, 20.4106301],
                [105.9915377, 20.410809],
                [105.9914872, 20.4118313],
                [105.991398, 20.4128371],
                [105.9913614, 20.4130524],
                [105.9910977, 20.4134301],
                [105.9909484, 20.413861],
                [105.9908965, 20.4146677],
                [105.9907917, 20.4160841],
                [105.9908591, 20.4177143],
                [105.9909228, 20.4182082],
                [105.9910883, 20.418747],
                [105.9913555, 20.4196021],
                [105.991686, 20.4206328],
                [105.9919921, 20.4217458],
                [105.9924877, 20.4232448],
                [105.9927421, 20.424053],
                [105.9929576, 20.4246502],
                [105.993092, 20.4253458],
                [105.9932262, 20.4255423],
                [105.9938576, 20.4257791],
                [105.9940668, 20.4257959],
                [105.9942387, 20.4259204],
                [105.9943538, 20.426081],
                [105.9944511, 20.4264209],
                [105.9946451, 20.4270291],
                [105.9946516, 20.4283125],
                [105.993975, 20.4281344],
                [105.9937255, 20.4294858],
                [105.9884348, 20.4292297],
                [105.9883506, 20.4296809],
                [105.9880246, 20.4296827],
                [105.987742, 20.4296639],
                [105.9869145, 20.4294227],
                [105.9863056, 20.4294057],
                [105.9861971, 20.4294064],
                [105.9860348, 20.4312308],
                [105.9859716, 20.4315385],
                [105.9858639, 20.431703],
                [105.9856472, 20.4318068],
                [105.9853863, 20.4318082],
                [105.9844721, 20.4316085],
                [105.9836017, 20.4314699],
                [105.9831671, 20.4314724],
                [105.9830155, 20.4315552],
                [105.9828221, 20.4319047],
                [105.9824797, 20.4327672],
                [105.9821838, 20.4340802],
                [105.9819128, 20.4358849],
                [105.9817744, 20.4380576],
                [105.9816952, 20.4392465],
                [105.9815406, 20.4403431],
                [105.9814617, 20.4404938],
                [105.9814073, 20.4405197],
                [105.9811069, 20.44047],
                [105.9803136, 20.4401916],
                [105.9798301, 20.4413774],
                [105.9797218, 20.4415066],
                [105.9794216, 20.4415082],
                [105.9787936, 20.4414346],
                [105.9778933, 20.4414398],
                [105.9780436, 20.43933],
                [105.9768038, 20.4390134],
                [105.976938, 20.4375532],
                [105.9770445, 20.4362283],
                [105.9771506, 20.4348764],
                [105.9774236, 20.4326857],
                [105.9762751, 20.432584],
                [105.9753646, 20.4299318],
                [105.9747276, 20.4284437],
                [105.9743666, 20.4274425],
                [105.9740322, 20.4263385],
                [105.9738068, 20.4252082],
                [105.9738527, 20.4238448],
                [105.9738483, 20.4231504],
                [105.9731939, 20.4232313],
                [105.971969, 20.4236495],
                [105.9718324, 20.4236504],
                [105.9716956, 20.423574],
                [105.9715845, 20.4232659],
                [105.9715023, 20.4231892],
                [105.9702474, 20.4232219],
                [105.9698381, 20.4231984],
                [105.9698155, 20.4239445],
                [105.9697617, 20.4240734],
                [105.9694621, 20.4241523],
                [105.9689708, 20.4241292],
                [105.9689203, 20.4247725],
                [105.9689798, 20.4255438],
                [105.9688712, 20.4256215],
                [105.9676712, 20.4257311],
                [105.9657891, 20.4257929],
                [105.9647522, 20.4257729],
                [105.9646151, 20.4256708],
                [105.9645047, 20.4254657],
                [105.9643391, 20.425158],
                [105.9642565, 20.4250556],
                [105.9637935, 20.4251611],
                [105.9637373, 20.4249042],
                [105.9634839, 20.423671],
                [105.9628574, 20.423803],
                [105.9627471, 20.4236237],
                [105.9625496, 20.422596],
                [105.9623839, 20.4222625],
                [105.9622195, 20.4221606],
                [105.9617829, 20.4221373],
                [105.960828, 20.4221425],
                [105.960771, 20.4217571],
                [105.960713, 20.4212173],
                [105.9606301, 20.4210378],
                [105.960438, 20.4208587],
                [105.9602196, 20.4208342],
                [105.9596205, 20.4210175],
                [105.9595379, 20.4208894],
                [105.9593428, 20.4202219],
                [105.9585494, 20.4198661],
                [105.9583819, 20.4192498],
                [105.9580761, 20.4183512],
                [105.9579391, 20.4181529],
                [105.9575806, 20.4176339],
                [105.9573999, 20.4173868],
                [105.9571569, 20.4171073],
                [105.9567715, 20.4167215],
                [105.956389, 20.4165],
                [105.9560898, 20.4163611],
                [105.9557268, 20.4162426],
                [105.9551281, 20.4159248],
                [105.955194, 20.4151564],
                [105.9539403, 20.4146214],
                [105.9529104, 20.4143362],
                [105.9525603, 20.4142413],
                [105.9505224, 20.4138647],
                [105.948546, 20.4134491],
                [105.9486625, 20.4123242],
                [105.9487379, 20.4115434],
                [105.9488365, 20.4105206],
                [105.9490573, 20.4096665],
                [105.949363, 20.4092191],
                [105.9495654, 20.4087139],
                [105.9497037, 20.4078022],
                [105.9500414, 20.4058814],
                [105.9505818, 20.4035136],
                [105.9488698, 20.4025925],
                [105.9474675, 20.4019024],
                [105.9471372, 20.401691],
                [105.9452788, 20.4003636],
                [105.9441002, 20.3995841],
                [105.9426569, 20.3986139],
                [105.9421966, 20.3983244],
                [105.9419859, 20.3998006],
                [105.9414226, 20.4005171],
                [105.9405441, 20.3998857],
                [105.9392065, 20.3990459],
                [105.9383956, 20.3987844],
                [105.9364942, 20.3984973],
                [105.9336105, 20.3980234],
                [105.9322833, 20.3977297],
                [105.9318272, 20.3976554],
                [105.9315178, 20.3976417],
                [105.9311764, 20.3977049],
                [105.9303977, 20.3981081],
                [105.9280767, 20.3991639],
                [105.9274922, 20.3994126],
                [105.9274378, 20.3994128],
                [105.9273131, 20.3994134],
                [105.927052, 20.3992921],
                [105.9262174, 20.3985446],
                [105.9248752, 20.3972781],
                [105.9243667, 20.3966364],
                [105.9241366, 20.3962693],
                [105.9240702, 20.3960549],
                [105.9240193, 20.3957021],
                [105.9239975, 20.3947816],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [103.8745552, 19.3116455],
                [103.8748167, 19.3095708],
                [103.875191, 19.3079464],
                [103.8753323, 19.3072808],
                [103.8754454, 19.3064847],
                [103.8758373, 19.3058836],
                [103.8769459, 19.3052927],
                [103.8783643, 19.3050741],
                [103.8812926, 19.3048792],
                [103.882377, 19.3044947],
                [103.8835513, 19.3038895],
                [103.8841113, 19.3028193],
                [103.8843535, 19.3016673],
                [103.8888167, 19.3031266],
                [103.8910395, 19.3073353],
                [103.8916818, 19.3079347],
                [103.8921914, 19.3083204],
                [103.8931146, 19.3090802],
                [103.8938642, 19.3093361],
                [103.895034, 19.3095593],
                [103.8968462, 19.3095703],
                [103.8991454, 19.3094584],
                [103.9012256, 19.3090517],
                [103.9027314, 19.3085996],
                [103.904197, 19.3075603],
                [103.9052259, 19.3066569],
                [103.9060879, 19.3068964],
                [103.9072042, 19.306738],
                [103.9082836, 19.3064467],
                [103.909472, 19.3059575],
                [103.9111885, 19.3055446],
                [103.9134869, 19.3044762],
                [103.9152968, 19.3033923],
                [103.9163181, 19.3026856],
                [103.9168532, 19.3019762],
                [103.9169921, 19.3010127],
                [103.9170003, 19.299755],
                [103.9165225, 19.2984945],
                [103.9164398, 19.2976136],
                [103.9165561, 19.2967339],
                [103.9171368, 19.2958149],
                [103.9189626, 19.2937296],
                [103.9199862, 19.2926456],
                [103.920658, 19.291308],
                [103.9219801, 19.2885069],
                [103.9225631, 19.2872106],
                [103.9239007, 19.2854158],
                [103.9266563, 19.2830423],
                [103.929588, 19.2807537],
                [103.9314746, 19.2794651],
                [103.932854, 19.2780057],
                [103.9351661, 19.2758392],
                [103.9386774, 19.2727152],
                [103.9403882, 19.2712579],
                [103.9432553, 19.2684015],
                [103.9483183, 19.2645752],
                [103.9521394, 19.2614123],
                [103.9555216, 19.2577437],
                [103.9598812, 19.2532426],
                [103.9641002, 19.2499684],
                [103.9674735, 19.247639],
                [103.9727879, 19.2458236],
                [103.9766794, 19.245426],
                [103.9800415, 19.2448576],
                [103.9850897, 19.2432082],
                [103.9888533, 19.2420128],
                [103.9911599, 19.2405997],
                [103.9941847, 19.2373451],
                [103.997211, 19.2338388],
                [103.9995569, 19.2325253],
                [104.0010646, 19.2325335],
                [104.0032145, 19.2329189],
                [104.0053462, 19.2338667],
                [104.0055062, 19.2339379],
                [104.0079869, 19.2349041],
                [104.0100171, 19.2353345],
                [104.0134144, 19.2362335],
                [104.0168541, 19.2374263],
                [104.0208272, 19.2381186],
                [104.0243604, 19.238473],
                [104.0270977, 19.238907],
                [104.0309785, 19.2402696],
                [104.0319262, 19.2405613],
                [104.0348605, 19.2414644],
                [104.0379963, 19.2417744],
                [104.0419696, 19.2424663],
                [104.0442632, 19.2432332],
                [104.0451405, 19.2433703],
                [104.0462737, 19.2435473],
                [104.0470642, 19.2435099],
                [104.0488581, 19.2434249],
                [104.049212, 19.2435277],
                [104.0506229, 19.2439373],
                [104.0523838, 19.2451208],
                [104.0542355, 19.2458852],
                [104.0552078, 19.2458902],
                [104.0568016, 19.2453952],
                [104.0587513, 19.2444826],
                [104.0607392, 19.2446186],
                [104.0642694, 19.2455594],
                [104.0662062, 19.2469113],
                [104.0674393, 19.2476726],
                [104.068233, 19.2480121],
                [104.0692052, 19.248017],
                [104.0692905, 19.247947],
                [104.0699155, 19.2474334],
                [104.0710802, 19.2446714],
                [104.0734792, 19.2424188],
                [104.07641, 19.2399173],
                [104.0807584, 19.2367518],
                [104.0849285, 19.233837],
                [104.089137, 19.2319707],
                [104.0919807, 19.229133],
                [104.0947457, 19.2245333],
                [104.0968854, 19.2211048],
                [104.0992032, 19.2174256],
                [104.1009385, 19.2152113],
                [104.1018236, 19.214964],
                [104.1030571, 19.2156411],
                [104.1055161, 19.218505],
                [104.1079728, 19.2217882],
                [104.1092898, 19.2233884],
                [104.1109675, 19.2236481],
                [104.1135362, 19.2225702],
                [104.1170003, 19.2193156],
                [104.1194042, 19.215972],
                [104.1223512, 19.2101985],
                [104.1240417, 19.2080257],
                [104.125598, 19.2061458],
                [104.1269293, 19.2050617],
                [104.1279013, 19.2050664],
                [104.1289599, 19.2054069],
                [104.1313808, 19.2060993],
                [104.1331299, 19.2061076],
                [104.1344736, 19.2061139],
                [104.1380671, 19.2043595],
                [104.1436501, 19.2012822],
                [104.1475442, 19.200126],
                [104.1513485, 19.199221],
                [104.153955, 19.1992749],
                [104.1572231, 19.1995416],
                [104.1614632, 19.1998127],
                [104.1647348, 19.1994082],
                [104.16792, 19.1985839],
                [104.1722514, 19.198268],
                [104.176582, 19.1981197],
                [104.178804, 19.1977368],
                [104.1791909, 19.1976701],
                [104.1814075, 19.1961701],
                [104.1832742, 19.1939137],
                [104.184702, 19.1910681],
                [104.1865715, 19.1882244],
                [104.1886879, 19.1848216],
                [104.1904867, 19.1826218],
                [104.1915156, 19.179984],
                [104.1917035, 19.17772],
                [104.1917995, 19.1761268],
                [104.1923386, 19.1742838],
                [104.1935882, 19.1716889],
                [104.1949374, 19.1667459],
                [104.1964063, 19.1644456],
                [104.1980934, 19.1626914],
                [104.2001325, 19.1611904],
                [104.2029591, 19.1612867],
                [104.2071992, 19.1613889],
                [104.2130326, 19.1608268],
                [104.2166571, 19.160339],
                [104.2207279, 19.1588465],
                [104.222814, 19.1567163],
                [104.2235286, 19.1550416],
                [104.2238933, 19.1526107],
                [104.2239011, 19.1509331],
                [104.2232894, 19.1495045],
                [104.2210029, 19.1473139],
                [104.2185405, 19.1449547],
                [104.2176631, 19.1436927],
                [104.2170967, 19.1420547],
                [104.216842, 19.1398726],
                [104.216497, 19.1381097],
                [104.2156192, 19.1369316],
                [104.2151815, 19.1360909],
                [104.2153637, 19.1349174],
                [104.2149808, 19.1346086],
                [104.2147684, 19.1340121],
                [104.2136977, 19.1328163],
                [104.2132737, 19.1314247],
                [104.2122858, 19.1295828],
                [104.212542, 19.1268554],
                [104.2127638, 19.1254666],
                [104.2129871, 19.1237801],
                [104.2130806, 19.1234004],
                [104.2145913, 19.1214775],
                [104.2180446, 19.1189492],
                [104.2227374, 19.1164527],
                [104.2261873, 19.1152929],
                [104.2306955, 19.114473],
                [104.2347596, 19.1141544],
                [104.2394382, 19.1146771],
                [104.2412505, 19.1143071],
                [104.2446127, 19.1129788],
                [104.2469128, 19.1121494],
                [104.2481492, 19.1121545],
                [104.2511445, 19.1138443],
                [104.25599, 19.1165482],
                [104.2619853, 19.1189211],
                [104.2656013, 19.1201099],
                [104.2719979, 19.1216872],
                [104.2763264, 19.1215365],
                [104.2776196, 19.12116],
                [104.2809255, 19.1201285],
                [104.2832262, 19.1191308],
                [104.284994, 19.1188022],
                [104.2866729, 19.118641],
                [104.2882641, 19.1183116],
                [104.2896823, 19.1171427],
                [104.2911508, 19.114506],
                [104.2924846, 19.1124141],
                [104.2935462, 19.1119988],
                [104.2946914, 19.1126742],
                [104.2959258, 19.1131823],
                [104.2984853, 19.1136115],
                [104.2991039, 19.1135299],
                [104.2993257, 19.1130437],
                [104.2996387, 19.1123576],
                [104.300985, 19.1072458],
                [104.3021563, 19.101714],
                [104.3030165, 19.0966423],
                [104.3034644, 19.095134],
                [104.3042659, 19.0935433],
                [104.305949, 19.0922914],
                [104.3086048, 19.0907076],
                [104.3123225, 19.0886244],
                [104.3174511, 19.0869658],
                [104.3247532, 19.0828405],
                [104.3299742, 19.0800914],
                [104.3349291, 19.0775928],
                [104.3373153, 19.0770981],
                [104.3395242, 19.0767705],
                [104.3409386, 19.0763561],
                [104.3426203, 19.0753555],
                [104.344967, 19.0736442],
                [104.3464717, 19.0727269],
                [104.3485915, 19.0725666],
                [104.3510639, 19.0725753],
                [104.3546809, 19.0734268],
                [104.3587394, 19.0742798],
                [104.3617413, 19.0743741],
                [104.364302, 19.074383],
                [104.365848, 19.0741786],
                [104.3676178, 19.0731781],
                [104.3715173, 19.0694166],
                [104.3762165, 19.0642317],
                [104.3779016, 19.0622242],
                [104.3798481, 19.0611402],
                [104.3815263, 19.0609781],
                [104.3863928, 19.0601709],
                [104.3868056, 19.0600322],
                [104.3870163, 19.0599753],
                [104.3874442, 19.059887],
                [104.387813, 19.0598389],
                [104.3879986, 19.0598243],
                [104.3881845, 19.059816],
                [104.3884451, 19.0598332],
                [104.3885738, 19.0598545],
                [104.388825, 19.0599221],
                [104.3890692, 19.0600245],
                [104.3891875, 19.0600892],
                [104.3893005, 19.0601618],
                [104.3894076, 19.0602419],
                [104.3894721, 19.0603078],
                [104.3896175, 19.0604229],
                [104.3897813, 19.0605133],
                [104.3898687, 19.0605483],
                [104.3900514, 19.0605968],
                [104.3901442, 19.0606098],
                [104.3903318, 19.0606137],
                [104.3905175, 19.0605879],
                [104.3906079, 19.060564],
                [104.3907809, 19.0604954],
                [104.3918967, 19.0597754],
                [104.3920454, 19.0596595],
                [104.3921704, 19.0595217],
                [104.3922683, 19.0593663],
                [104.3923366, 19.0591974],
                [104.3942141, 19.0596334],
                [104.3944533, 19.0593572],
                [104.3945814, 19.059226],
                [104.3948537, 19.0589786],
                [104.3949973, 19.0588628],
                [104.3952861, 19.058656],
                [104.3954365, 19.0585605],
                [104.3956675, 19.0584249],
                [104.3958328, 19.0583544],
                [104.3960092, 19.0583136],
                [104.3961905, 19.0583037],
                [104.3963706, 19.0583253],
                [104.3972719, 19.0583861],
                [104.3974449, 19.0583826],
                [104.3976174, 19.0583695],
                [104.3977888, 19.058347],
                [104.3979585, 19.0583152],
                [104.3981245, 19.0582744],
                [104.3982878, 19.0582247],
                [104.3984478, 19.0581662],
                [104.3986039, 19.0580991],
                [104.3987557, 19.0580236],
                [104.3989026, 19.0579399],
                [104.4032049, 19.0552222],
                [104.4036414, 19.0541283],
                [104.4038195, 19.0537937],
                [104.4039451, 19.053509],
                [104.4040417, 19.0532143],
                [104.4040823, 19.0530491],
                [104.404136, 19.052714],
                [104.4041567, 19.0523275],
                [104.4041541, 19.0521098],
                [104.4041413, 19.0518926],
                [104.4041183, 19.051676],
                [104.4040851, 19.0514607],
                [104.4040417, 19.0512469],
                [104.4024432, 19.0500705],
                [104.4023037, 19.0499523],
                [104.4022393, 19.049888],
                [104.4020972, 19.0497164],
                [104.4012308, 19.048458],
                [104.4012403, 19.0482553],
                [104.4012403, 19.0480523],
                [104.4012308, 19.0478496],
                [104.4011915, 19.0474977],
                [104.4011235, 19.0471498],
                [104.4013019, 19.0469208],
                [104.4013822, 19.0468005],
                [104.4015238, 19.0465496],
                [104.4016469, 19.0462665],
                [104.4016999, 19.0461103],
                [104.4017436, 19.0459516],
                [104.401778, 19.0457909],
                [104.4015527, 19.044655],
                [104.4015197, 19.0445307],
                [104.4014795, 19.0442773],
                [104.4014741, 19.0440211],
                [104.4014845, 19.0438933],
                [104.4015312, 19.0436408],
                [104.4016048, 19.043414],
                [104.4017064, 19.043197],
                [104.4017672, 19.0430932],
                [104.4017993, 19.0429938],
                [104.4018423, 19.042434],
                [104.4023359, 19.0419066],
                [104.4026792, 19.0410547],
                [104.402543, 19.0409556],
                [104.4024843, 19.0408961],
                [104.402433, 19.0408308],
                [104.4023575, 19.0406935],
                [104.4023154, 19.0405521],
                [104.4023057, 19.0404791],
                [104.402293, 19.0400608],
                [104.4022624, 19.0399499],
                [104.4022377, 19.0398974],
                [104.402169, 19.0397994],
                [104.4020765, 19.0397166],
                [104.4019665, 19.0396559],
                [104.4019067, 19.0396348],
                [104.4016496, 19.0395737],
                [104.4015245, 19.0395318],
                [104.4012818, 19.0394254],
                [104.4010532, 19.0392897],
                [104.4009461, 19.0392119],
                [104.4008104, 19.0390916],
                [104.4007587, 19.0390081],
                [104.400742, 19.0389623],
                [104.4007285, 19.0388619],
                [104.4007332, 19.0388092],
                [104.4007663, 19.0387084],
                [104.4008285, 19.0386206],
                [104.4018423, 19.0374949],
                [104.4019792, 19.0373743],
                [104.4021387, 19.0372818],
                [104.4023144, 19.037221],
                [104.4024822, 19.0371954],
                [104.4026522, 19.0371988],
                [104.4028187, 19.0372312],
                [104.4030132, 19.0372455],
                [104.4032071, 19.0372244],
                [104.4033015, 19.0372008],
                [104.4033944, 19.0371681],
                [104.4034836, 19.0371269],
                [104.4035681, 19.0370778],
                [104.4036471, 19.0370211],
                [104.4042885, 19.0364198],
                [104.4044348, 19.0363587],
                [104.4045889, 19.0363184],
                [104.4047603, 19.0362992],
                [104.4048467, 19.0362992],
                [104.4050181, 19.0363184],
                [104.4058442, 19.0366227],
                [104.406445, 19.0352129],
                [104.4066376, 19.034887],
                [104.4067424, 19.0347288],
                [104.4069942, 19.0343904],
                [104.4071435, 19.0342123],
                [104.4073002, 19.0340402],
                [104.4085801, 19.0327686],
                [104.4123888, 19.0303649],
                [104.4133544, 19.0303953],
                [104.4133115, 19.0298375],
                [104.4133432, 19.0296856],
                [104.4133716, 19.0296128],
                [104.413408, 19.0295433],
                [104.413498, 19.0294227],
                [104.4136118, 19.0293215],
                [104.413769, 19.0292116],
                [104.4139384, 19.0290581],
                [104.4149208, 19.0278698],
                [104.4163263, 19.026886],
                [104.4169413, 19.0254959],
                [104.4171239, 19.0251406],
                [104.4173322, 19.0248118],
                [104.417562, 19.0245106],
                [104.4176867, 19.0243671],
                [104.419384, 19.0225043],
                [104.4194673, 19.0224003],
                [104.4195235, 19.0222811],
                [104.4195493, 19.0221555],
                [104.4195449, 19.0220276],
                [104.4195139, 19.0218258],
                [104.4195127, 19.0216219],
                [104.4195412, 19.0214213],
                [104.4195986, 19.0212263],
                [104.4201887, 19.0203895],
                [104.4202589, 19.0203137],
                [104.420336, 19.0202442],
                [104.4204193, 19.0201815],
                [104.4205915, 19.0200834],
                [104.4206836, 19.0200451],
                [104.4207787, 19.0200142],
                [104.4221786, 19.0196569],
                [104.4223643, 19.0195907],
                [104.4224524, 19.0195476],
                [104.4225994, 19.0194553],
                [104.4227314, 19.0193447],
                [104.423359, 19.0187108],
                [104.4233537, 19.0181428],
                [104.4233663, 19.0180564],
                [104.423418, 19.0178892],
                [104.4234589, 19.0178057],
                [104.4235087, 19.0177266],
                [104.4235668, 19.0176527],
                [104.4236326, 19.0175849],
                [104.4246733, 19.0166517],
                [104.42494, 19.0165817],
                [104.425199, 19.0164894],
                [104.425486, 19.016356],
                [104.4256237, 19.0162789],
                [104.4257569, 19.0161953],
                [104.4264758, 19.0155664],
                [104.4258213, 19.0141463],
                [104.4254852, 19.0136454],
                [104.4253263, 19.0133897],
                [104.4250273, 19.0128682],
                [104.4247452, 19.0123163],
                [104.4242974, 19.0113284],
                [104.4240951, 19.010908],
                [104.4238842, 19.0104914],
                [104.4236648, 19.0100787],
                [104.4236326, 19.0094498],
                [104.4230747, 19.0085369],
                [104.4230153, 19.0084083],
                [104.4229651, 19.0082762],
                [104.4229245, 19.0081413],
                [104.4228737, 19.0078744],
                [104.4228623, 19.0077393],
                [104.4228279, 19.0065893],
                [104.4249737, 19.0056154],
                [104.4260573, 19.0048749],
                [104.4268834, 19.0048039],
                [104.4277418, 19.0060922],
                [104.4284606, 19.0055343],
                [104.4297373, 19.0050879],
                [104.4305098, 19.0054328],
                [104.4312394, 19.005159],
                [104.4331705, 19.0033837],
                [104.4334489, 19.0030766],
                [104.4337032, 19.0027515],
                [104.4339323, 19.0024099],
                [104.4340519, 19.0022071],
                [104.4341626, 19.0019998],
                [104.4342641, 19.0017884],
                [104.4343564, 19.0015732],
                [104.4344392, 19.0013545],
                [104.4345125, 19.0011329],
                [104.434576, 19.0009086],
                [104.4346644, 19.0005511],
                [104.4347839, 19.0002019],
                [104.4349406, 18.999849],
                [104.4351374, 18.9994967],
                [104.435367, 18.9991625],
                [104.4356275, 18.9988493],
                [104.4360459, 18.9985246],
                [104.4369471, 18.9975001],
                [104.4371283, 18.9973322],
                [104.4373296, 18.9971861],
                [104.4374368, 18.9971218],
                [104.4376787, 18.9970056],
                [104.4378134, 18.996956],
                [104.4379514, 18.9969153],
                [104.4384105, 18.9968257],
                [104.4387561, 18.9967315],
                [104.4390906, 18.9966065],
                [104.4392528, 18.9965328],
                [104.4395649, 18.9963639],
                [104.4397366, 18.9957045],
                [104.4403857, 18.9956943],
                [104.441008, 18.995811],
                [104.4415015, 18.9954204],
                [104.4417057, 18.9950781],
                [104.441936, 18.9947509],
                [104.4420759, 18.9945748],
                [104.4422234, 18.9944043],
                [104.4423782, 18.9942397],
                [104.4425399, 18.9940812],
                [104.4427085, 18.9939292],
                [104.4428061, 18.9938684],
                [104.4429089, 18.9938158],
                [104.4430161, 18.9937717],
                [104.4431269, 18.9937364],
                [104.4432387, 18.9937106],
                [104.4433524, 18.9936938],
                [104.4434671, 18.9936861],
                [104.4435822, 18.9936875],
                [104.4436967, 18.9936982],
                [104.4438098, 18.9937179],
                [104.4439208, 18.9937466],
                [104.4460666, 18.9929147],
                [104.4460773, 18.9924176],
                [104.4459164, 18.991393],
                [104.4458948, 18.9911787],
                [104.4459092, 18.9909638],
                [104.4459299, 18.9908579],
                [104.4459963, 18.9906549],
                [104.4460939, 18.9904666],
                [104.4462209, 18.9902948],
                [104.4463743, 18.9901434],
                [104.4465506, 18.9900162],
                [104.446646, 18.9899626],
                [104.4467807, 18.9898544],
                [104.4469086, 18.9897391],
                [104.4470291, 18.989617],
                [104.447142, 18.9894884],
                [104.4472468, 18.9893539],
                [104.4474212, 18.9890855],
                [104.4475635, 18.9888007],
                [104.4476221, 18.9886533],
                [104.4477133, 18.9883503],
                [104.447769, 18.9880398],
                [104.4477832, 18.9878828],
                [104.4477882, 18.987573],
                [104.4478209, 18.9872647],
                [104.4478838, 18.9869494],
                [104.4479277, 18.9867888],
                [104.4480385, 18.986474],
                [104.4481537, 18.9862255],
                [104.4482755, 18.986049],
                [104.4483477, 18.9859687],
                [104.4485073, 18.9858305],
                [104.4486827, 18.985722],
                [104.4487767, 18.9856779],
                [104.4489743, 18.9856117],
                [104.4491805, 18.9855759],
                [104.4512916, 18.9854379],
                [104.4523752, 18.9851436],
                [104.4525203, 18.9851258],
                [104.4528124, 18.9851108],
                [104.4531047, 18.9851234],
                [104.4533836, 18.9851614],
                [104.4535212, 18.9851899],
                [104.4537914, 18.9852654],
                [104.4554543, 18.9869393],
                [104.4556429, 18.9871028],
                [104.4560045, 18.9874451],
                [104.4563444, 18.9878067],
                [104.4566835, 18.9882149],
                [104.4568533, 18.9884408],
                [104.457015, 18.988672],
                [104.4571685, 18.9889082],
                [104.4573135, 18.9891491],
                [104.4574499, 18.9893944],
                [104.4575466, 18.9894361],
                [104.4577498, 18.9894943],
                [104.4578546, 18.9895104],
                [104.4580668, 18.9895162],
                [104.4582711, 18.9894877],
                [104.4583705, 18.9894612],
                [104.4586898, 18.9893377],
                [104.4589572, 18.9892695],
                [104.4592319, 18.9892358],
                [104.4593704, 18.9892321],
                [104.4595082, 18.9892372],
                [104.4597812, 18.9892732],
                [104.4600467, 18.9893434],
                [104.4603, 18.9894464],
                [104.4605365, 18.9895803],
                [104.4606471, 18.9896582],
                [104.4607819, 18.9897753],
                [104.4609097, 18.9898992],
                [104.4610302, 18.9900294],
                [104.4611431, 18.9901656],
                [104.4612479, 18.9903075],
                [104.4614218, 18.9905869],
                [104.4615648, 18.9908816],
                [104.4616753, 18.9911886],
                [104.4617629, 18.9915857],
                [104.4620273, 18.9914555],
                [104.4623047, 18.9913524],
                [104.4626138, 18.991273],
                [104.4627714, 18.9912459],
                [104.4638228, 18.990551],
                [104.464257, 18.990361],
                [104.4646951, 18.9901792],
                [104.4651369, 18.9900058],
                [104.4660073, 18.9896924],
                [104.4668658, 18.9894184],
                [104.467299, 18.989293],
                [104.4707751, 18.987964],
                [104.4709756, 18.9879094],
                [104.4711787, 18.9878641],
                [104.4713838, 18.9878282],
                [104.4717817, 18.9877857],
                [104.4721654, 18.987778],
                [104.4723571, 18.9877863],
                [104.4726438, 18.9878119],
                [104.4727385, 18.987827],
                [104.4729565, 18.9878851],
                [104.4731628, 18.9879735],
                [104.4733526, 18.9880903],
                [104.4735217, 18.9882328],
                [104.4751739, 18.991464],
                [104.4751954, 18.993635],
                [104.4754958, 18.9940408],
                [104.475644, 18.9942651],
                [104.4757835, 18.9944944],
                [104.475914, 18.9947284],
                [104.4760354, 18.9949667],
                [104.4761475, 18.995209],
                [104.4762502, 18.9954551],
                [104.4763434, 18.9957045],
                [104.4765365, 18.9982102],
                [104.476551, 18.998316],
                [104.476572, 18.9984208],
                [104.4766331, 18.9986261],
                [104.4766819, 18.9987461],
                [104.4767395, 18.9988625],
                [104.4768056, 18.9989749],
                [104.4768798, 18.9990826],
                [104.4776952, 18.9998231],
                [104.4779162, 18.9998984],
                [104.4780308, 18.9999232],
                [104.4782643, 18.9999465],
                [104.4783819, 18.9999449],
                [104.4785017, 18.9999339],
                [104.4786202, 18.9999138],
                [104.4787365, 18.9998845],
                [104.4788499, 18.9998463],
                [104.4789596, 18.9997995],
                [104.4790649, 18.9997443],
                [104.4791651, 18.9996811],
                [104.4799161, 18.999042],
                [104.4811606, 19.0013143],
                [104.4812826, 19.0015572],
                [104.4813752, 19.0018114],
                [104.4814349, 19.0020599],
                [104.4814666, 19.002313],
                [104.4814863, 19.0026559],
                [104.4815081, 19.0028709],
                [104.4815371, 19.0030852],
                [104.4816166, 19.0035105],
                [104.4816526, 19.0036317],
                [104.4817474, 19.0038669],
                [104.4818714, 19.0040896],
                [104.4820208, 19.0042942],
                [104.4821038, 19.0043887],
                [104.4822867, 19.004563],
                [104.4823859, 19.0046423],
                [104.4825983, 19.0047836],
                [104.482961, 19.0049492],
                [104.4833375, 19.0050848],
                [104.4837248, 19.0051894],
                [104.483924, 19.0052302],
                [104.484327, 19.0052871],
                [104.4847337, 19.0053108],
                [104.485141, 19.005301],
                [104.4851732, 19.0050981],
                [104.4853561, 19.0048733],
                [104.4857408, 19.004438],
                [104.4859422, 19.0042278],
                [104.4861495, 19.0040228],
                [104.4865692, 19.0036395],
                [104.4870095, 19.0032773],
                [104.4874692, 19.0029374],
                [104.4880271, 19.0026229],
                [104.4881834, 19.0025596],
                [104.4883498, 19.0025264],
                [104.4884348, 19.0025215],
                [104.4885937, 19.0025336],
                [104.4886716, 19.00255],
                [104.488821, 19.0026026],
                [104.489065, 19.0026741],
                [104.489559, 19.0027964],
                [104.4898087, 19.0028471],
                [104.4903123, 19.0029273],
                [104.4905952, 19.0029597],
                [104.4911637, 19.0029978],
                [104.4917335, 19.0030003],
                [104.4920182, 19.0029881],
                [104.4941854, 19.0037997],
                [104.4962239, 19.0037388],
                [104.4965493, 19.0037387],
                [104.496873, 19.0037692],
                [104.4970453, 19.0037982],
                [104.4972157, 19.0038358],
                [104.4975489, 19.0039366],
                [104.4975757, 19.0031809],
                [104.497609, 19.003043],
                [104.4976519, 19.0029075],
                [104.4977592, 19.0026601],
                [104.49789, 19.0024406],
                [104.4979657, 19.0023368],
                [104.4980478, 19.0022375],
                [104.4991958, 19.001152],
                [104.4999576, 19.0007361],
                [104.5006174, 18.9999347],
                [104.5017385, 18.9987529],
                [104.5019864, 18.9985771],
                [104.502252, 18.9984261],
                [104.5025325, 18.9983015],
                [104.5026942, 18.998244],
                [104.5028591, 18.9981953],
                [104.5030267, 18.9981556],
                [104.5031964, 18.998125],
                [104.5033676, 18.9981035],
                [104.5035399, 18.9980913],
                [104.5039924, 18.9980872],
                [104.5041319, 18.9980761],
                [104.5044159, 18.9980313],
                [104.5047006, 18.9979551],
                [104.5048392, 18.9979058],
                [104.5058799, 18.9973276],
                [104.5057511, 18.9964349],
                [104.5050001, 18.9948726],
                [104.5051718, 18.9934321],
                [104.50524, 18.9931189],
                [104.5052862, 18.9929651],
                [104.5054025, 18.9926648],
                [104.5055532, 18.9923701],
                [104.5056423, 18.9922252],
                [104.5058436, 18.9919495],
                [104.5060738, 18.9916949],
                [104.5063305, 18.991464],
                [104.5065443, 18.9912346],
                [104.5069515, 18.9907596],
                [104.5073304, 18.9902641],
                [104.5076799, 18.9897496],
                [104.5078432, 18.9894857],
                [104.5080046, 18.9892075],
                [104.5081575, 18.9889249],
                [104.5083017, 18.9886384],
                [104.5084373, 18.9883481],
                [104.508564, 18.9880542],
                [104.5086818, 18.987757],
                [104.5087905, 18.9874567],
                [104.5088901, 18.9871537],
                [104.5089805, 18.986848],
                [104.5089844, 18.9860084],
                [104.508992, 18.9859299],
                [104.5090342, 18.9857777],
                [104.5091061, 18.985644],
                [104.5092059, 18.9855273],
                [104.5092649, 18.985477],
                [104.5098431, 18.9850461],
                [104.5099831, 18.9849035],
                [104.5100427, 18.9848241],
                [104.510128, 18.9846753],
                [104.5101885, 18.9845159],
                [104.510209, 18.9844335],
                [104.5103328, 18.9841725],
                [104.5104074, 18.984048],
                [104.5105664, 18.9838306],
                [104.5107508, 18.983632],
                [104.5118773, 18.9825464],
                [104.5122306, 18.9821511],
                [104.5129609, 18.9814406],
                [104.5131812, 18.9814414],
                [104.5136213, 18.9814617],
                [104.5140943, 18.9815116],
                [104.5143466, 18.9815503],
                [104.5145974, 18.9815973],
                [104.515093, 18.9817159],
                [104.5157609, 18.9819183],
                [104.5159453, 18.9819626],
                [104.516319, 18.982029],
                [104.516522, 18.9820525],
                [104.5169302, 18.9820733],
                [104.5173388, 18.9820592],
                [104.5175421, 18.9820392],
                [104.518792, 18.9808623],
                [104.5200151, 18.9802485],
                [104.5201031, 18.980213],
                [104.5202867, 18.980163],
                [104.5204765, 18.980142],
                [104.5205735, 18.9801426],
                [104.5207658, 18.9801668],
                [104.5209512, 18.9802209],
                [104.5216437, 18.9805149],
                [104.5222436, 18.9807793],
                [104.5228396, 18.9810518],
                [104.5240187, 18.9816209],
                [104.5246017, 18.9819174],
                [104.5257358, 18.982524],
                [104.526296, 18.9828386],
                [104.5274019, 18.98349],
                [104.5278705, 18.9838523],
                [104.528096, 18.9840432],
                [104.5285284, 18.9844436],
                [104.5289235, 18.9848552],
                [104.5291114, 18.9850689],
                [104.5294672, 18.9855114],
                [104.5309793, 18.9839394],
                [104.5323616, 18.983239],
                [104.5341911, 18.9817411],
                [104.5350018, 18.9812707],
                [104.5359185, 18.9784671],
                [104.5397197, 18.9761277],
                [104.5426836, 18.9734087],
                [104.5450752, 18.970143],
                [104.5457882, 18.9675443],
                [104.5463293, 18.9632673],
                [104.5463377, 18.9601635],
                [104.546655, 18.9570185],
                [104.5471017, 18.9550063],
                [104.5486103, 18.9518223],
                [104.5502058, 18.9491417],
                [104.5534801, 18.9455425],
                [104.5586536, 18.9410668],
                [104.5621908, 18.9380553],
                [104.56414, 18.934956],
                [104.5664475, 18.9296765],
                [104.5692422, 18.9236011],
                [104.5709253, 18.9209206],
                [104.5724309, 18.9186591],
                [104.5738497, 18.9158102],
                [104.5746482, 18.9139665],
                [104.5761958, 18.912502],
                [104.5783183, 18.9104096],
                [104.5796473, 18.9080638],
                [104.5799183, 18.9055477],
                [104.5802775, 18.9030319],
                [104.580545, 18.901858],
                [104.581165, 18.9008528],
                [104.5819593, 18.9006868],
                [104.5837233, 18.9007747],
                [104.5867215, 18.9012008],
                [104.5892786, 18.9016259],
                [104.5921008, 18.9018838],
                [104.5933366, 18.901551],
                [104.595015, 18.900548],
                [104.5973144, 18.8981202],
                [104.599303, 18.8964886],
                [104.6044219, 18.8953252],
                [104.6079527, 18.8942421],
                [104.6125436, 18.8924901],
                [104.6154552, 18.8921605],
                [104.617838, 18.8916621],
                [104.6214581, 18.8900757],
                [104.6268, 18.8876118],
                [104.6291841, 18.8864421],
                [104.6300675, 18.8858566],
                [104.6300706, 18.8844306],
                [104.6289313, 18.8810727],
                [104.6283177, 18.8793937],
                [104.6282307, 18.8788063],
                [104.6282326, 18.8779674],
                [104.6293819, 18.8767114],
                [104.6330923, 18.8739504],
                [104.6364956, 18.8703918],
                [104.6394113, 18.8679647],
                [104.6417944, 18.8671304],
                [104.6441758, 18.8670511],
                [104.648764, 18.8660531],
                [104.6522943, 18.8648014],
                [104.6547662, 18.8636316],
                [104.6568864, 18.8618738],
                [104.6594504, 18.8586908],
                [104.6619691, 18.8560528],
                [104.6643525, 18.8548826],
                [104.6672652, 18.8536295],
                [104.6694715, 18.8527946],
                [104.6695856, 18.8526865],
                [104.6703551, 18.8519572],
                [104.6711527, 18.8499454],
                [104.6711585, 18.8469253],
                [104.670634, 18.8445755],
                [104.670548, 18.8434848],
                [104.6707706, 18.8423527],
                [104.6720975, 18.8402578],
                [104.6758057, 18.8378315],
                [104.6790719, 18.8359915],
                [104.6832176, 18.8352437],
                [104.6863044, 18.8349972],
                [104.6886864, 18.8343301],
                [104.6906277, 18.8335783],
                [104.6917746, 18.8332446],
                [104.6926566, 18.8331622],
                [104.6938895, 18.8340032],
                [104.6955611, 18.8361031],
                [104.6973661, 18.8375742],
                [104.6989526, 18.8379962],
                [104.7011577, 18.8376641],
                [104.7038922, 18.8371652],
                [104.7046866, 18.8366631],
                [104.7051288, 18.8359088],
                [104.7054833, 18.8349027],
                [104.7057496, 18.8338964],
                [104.7061038, 18.8330581],
                [104.7068102, 18.8324719],
                [104.7073393, 18.8324728],
                [104.7078676, 18.832893],
                [104.7087472, 18.8341528],
                [104.7098033, 18.8353289],
                [104.7108609, 18.835666],
                [104.7124483, 18.8355846],
                [104.7136835, 18.8351671],
                [104.713949, 18.8345803],
                [104.7141276, 18.8332383],
                [104.7145726, 18.8308062],
                [104.7153705, 18.8282907],
                [104.7168731, 18.8261119],
                [104.7188158, 18.8243531],
                [104.7199649, 18.822677],
                [104.720718, 18.8204551],
                [104.7212501, 18.8186103],
                [104.7215171, 18.8171007],
                [104.7218705, 18.8165978],
                [104.7226646, 18.8162635],
                [104.7235462, 18.8163487],
                [104.7265431, 18.8168565],
                [104.7297893, 18.8162259],
                [104.731509, 18.815732],
                [104.7345186, 18.8148429],
                [104.7361577, 18.8141858],
                [104.736952, 18.8136836],
                [104.7370417, 18.8126771],
                [104.7369581, 18.8097408],
                [104.7364967, 18.8075985],
                [104.7370747, 18.8044535],
                [104.73816, 18.8022343],
                [104.7389554, 18.8009771],
                [104.739926, 18.8003912],
                [104.7404551, 18.8003081],
                [104.7419541, 18.8001423],
                [104.7445109, 18.8000619],
                [104.7469795, 18.7999814],
                [104.7484787, 18.7996479],
                [104.7498902, 18.7990626],
                [104.7522726, 18.7975557],
                [104.755715, 18.794708],
                [104.7568953, 18.7938265],
                [104.7587685, 18.7924635],
                [104.760268, 18.7918782],
                [104.7615022, 18.7918798],
                [104.7630889, 18.7919657],
                [104.7653792, 18.793227],
                [104.7688128, 18.7965869],
                [104.7726008, 18.7986049],
                [104.7767424, 18.8001199],
                [104.7795633, 18.800291],
                [104.7819432, 18.8006294],
                [104.7834421, 18.8005473],
                [104.7845007, 18.7999613],
                [104.7852964, 18.7982004],
                [104.786889, 18.7936722],
                [104.7877735, 18.7913243],
                [104.7900261, 18.7875937],
                [104.79188, 18.7854146],
                [104.7945276, 18.7829847],
                [104.7969978, 18.7813096],
                [104.8004375, 18.7797194],
                [104.8037882, 18.778884],
                [104.8053753, 18.7785502],
                [104.8100242, 18.781061],
                [104.8148674, 18.7810659],
                [104.8198791, 18.7814689],
                [104.8253108, 18.7826496],
                [104.8301279, 18.7835357],
                [104.8337152, 18.7839307],
                [104.8421209, 18.783938],
                [104.845505, 18.7825533],
                [104.8469802, 18.7812896],
                [104.8489831, 18.7786638],
                [104.85246, 18.7758445],
                [104.8548827, 18.7745814],
                [104.8562169, 18.7739486],
                [104.8590102, 18.7732558],
                [104.8601455, 18.776337],
                [104.8621445, 18.7780901],
                [104.863934, 18.7784806],
                [104.865702, 18.7787647],
                [104.8674441, 18.7793536],
                [104.8701088, 18.7799433],
                [104.8716178, 18.7814054],
                [104.8724592, 18.7825738],
                [104.8730901, 18.7837419],
                [104.8740371, 18.784521],
                [104.8776174, 18.7839395],
                [104.880562, 18.7834767],
                [104.8846719, 18.7838467],
                [104.8913052, 18.7838507],
                [104.8940421, 18.7849227],
                [104.8974106, 18.7862869],
                [104.9025703, 18.7857058],
                [104.9067644, 18.7856003],
                [104.910078, 18.7864126],
                [104.9110651, 18.7897848],
                [104.9111389, 18.7899772],
                [104.9111877, 18.7900686],
                [104.9113076, 18.7902389],
                [104.9114546, 18.7903889],
                [104.9116242, 18.7905144],
                [104.9118117, 18.7906125],
                [104.9119111, 18.7906507],
                [104.9121178, 18.7907042],
                [104.9123725, 18.7907333],
                [104.9125219, 18.7907392],
                [104.9128205, 18.7907269],
                [104.9131156, 18.7906824],
                [104.9132607, 18.7906482],
                [104.9135435, 18.7905567],
                [104.9137301, 18.7904918],
                [104.9138186, 18.7904489],
                [104.9139833, 18.7903434],
                [104.9140653, 18.7902753],
                [104.9141406, 18.7902007],
                [104.914788, 18.7894598],
                [104.9154639, 18.7894394],
                [104.9155429, 18.7894252],
                [104.9156942, 18.7893743],
                [104.915765, 18.7893381],
                [104.9158931, 18.7892465],
                [104.9159913, 18.7891421],
                [104.9160673, 18.7890222],
                [104.9163544, 18.7882612],
                [104.9164694, 18.7881374],
                [104.9166033, 18.788032],
                [104.9167586, 18.7879448],
                [104.9169329, 18.7878806],
                [104.9173026, 18.7878099],
                [104.9174871, 18.7877672],
                [104.9178493, 18.7876585],
                [104.9180262, 18.7875929],
                [104.9185236, 18.7873755],
                [104.9188432, 18.787223],
                [104.9191582, 18.7870621],
                [104.9194684, 18.7868931],
                [104.9197736, 18.7867161],
                [104.9200735, 18.7865312],
                [104.9203679, 18.7863386],
                [104.9206567, 18.7861384],
                [104.9211033, 18.7860411],
                [104.9213289, 18.7860025],
                [104.9217832, 18.7859454],
                [104.9222815, 18.785914],
                [104.922781, 18.7859149],
                [104.9227742, 18.7859894],
                [104.9227872, 18.7861382],
                [104.9228346, 18.7862806],
                [104.9228736, 18.786352],
                [104.9229214, 18.7864184],
                [104.9229773, 18.786479],
                [104.9230404, 18.7865327],
                [104.9231099, 18.786579],
                [104.9231847, 18.786617],
                [104.9232638, 18.7866462],
                [104.9241543, 18.7863009],
                [104.9245834, 18.7858032],
                [104.9248478, 18.7855936],
                [104.9249724, 18.7854808],
                [104.9252052, 18.7852399],
                [104.925313, 18.7851125],
                [104.9254299, 18.7849598],
                [104.9255384, 18.7848017],
                [104.9256382, 18.7846386],
                [104.9257291, 18.7844708],
                [104.9258108, 18.7842989],
                [104.9258832, 18.7841233],
                [104.925946, 18.7839444],
                [104.9258923, 18.7815574],
                [104.9259841, 18.7813238],
                [104.9260425, 18.781212],
                [104.9261124, 18.7810992],
                [104.9261906, 18.7809915],
                [104.9262769, 18.7808894],
                [104.9263708, 18.7807934],
                [104.9264717, 18.7807041],
                [104.9266425, 18.7805194],
                [104.9267864, 18.780315],
                [104.9269008, 18.7800947],
                [104.9269536, 18.7800147],
                [104.9270786, 18.7798681],
                [104.9271501, 18.7798024],
                [104.9273085, 18.7796884],
                [104.9274967, 18.7795949],
                [104.9276989, 18.7795334],
                [104.9280268, 18.7794877],
                [104.9282565, 18.7794301],
                [104.9284762, 18.7793441],
                [104.9286818, 18.7792313],
                [104.9300122, 18.7784187],
                [104.9303021, 18.7782064],
                [104.9304414, 18.7780936],
                [104.9307699, 18.7777951],
                [104.9309242, 18.7776365],
                [104.9312046, 18.7774039],
                [104.9315046, 18.7771941],
                [104.9316612, 18.7770983],
                [104.9319863, 18.7769255],
                [104.9323402, 18.7767732],
                [104.9325221, 18.776708],
                [104.9328939, 18.7766004],
                [104.9332742, 18.7765238],
                [104.9334666, 18.7764972],
                [104.93366, 18.7764785],
                [104.9338691, 18.7764972],
                [104.9340786, 18.7764827],
                [104.9342826, 18.7764354],
                [104.9343807, 18.7763998],
                [104.9345653, 18.7763065],
                [104.9347305, 18.776186],
                [104.9348724, 18.7760413],
                [104.9349871, 18.7758764],
                [104.9350997, 18.7756695],
                [104.9351728, 18.7755547],
                [104.9353379, 18.7753372],
                [104.9355268, 18.7751377],
                [104.9357592, 18.774942],
                [104.9360143, 18.7747738],
                [104.9362886, 18.7746352],
                [104.9365783, 18.7745282],
                [104.9378228, 18.7739797],
                [104.937923, 18.7739258],
                [104.9381107, 18.7737995],
                [104.9381975, 18.7737277],
                [104.9383547, 18.7735683],
                [104.9384245, 18.7734815],
                [104.9385453, 18.7732949],
                [104.9386387, 18.7730938],
                [104.9390244, 18.7718465],
                [104.9391746, 18.7709322],
                [104.9391843, 18.7708029],
                [104.9391843, 18.7705435],
                [104.9391746, 18.7704142],
                [104.9391309, 18.7701319],
                [104.9390566, 18.7698555],
                [104.9385953, 18.7685349],
                [104.9385659, 18.7681697],
                [104.9385652, 18.7679865],
                [104.9385895, 18.7676395],
                [104.9386432, 18.7673141],
                [104.9386811, 18.7671533],
                [104.9387554, 18.7669224],
                [104.9387812, 18.7668041],
                [104.9388099, 18.7665642],
                [104.9388123, 18.7664297],
                [104.9388051, 18.7662953],
                [104.9387883, 18.7661617],
                [104.9387619, 18.7660296],
                [104.9387262, 18.7658994],
                [104.9386811, 18.7657718],
                [104.9385416, 18.765081],
                [104.9385268, 18.7649165],
                [104.9385216, 18.7647514],
                [104.9385259, 18.7645864],
                [104.9385397, 18.7644218],
                [104.9385631, 18.7642582],
                [104.9385968, 18.764092],
                [104.9386403, 18.7639279],
                [104.9386935, 18.7637664],
                [104.9387562, 18.763608],
                [104.9388515, 18.7634505],
                [104.9389173, 18.7632801],
                [104.9389525, 18.7630954],
                [104.9389525, 18.7629016],
                [104.9389386, 18.7628055],
                [104.9387884, 18.7621553],
                [104.9383271, 18.7615966],
                [104.938209, 18.7610683],
                [104.9381503, 18.7609104],
                [104.9381125, 18.7608347],
                [104.9380242, 18.7606962],
                [104.937973, 18.7606315],
                [104.9377584, 18.760408],
                [104.9372971, 18.7597578],
                [104.9361988, 18.7583238],
                [104.9346951, 18.7576943],
                [104.9342744, 18.756721],
                [104.9372229, 18.7543865],
                [104.9385921, 18.7526353],
                [104.9400668, 18.7501057],
                [104.9409366, 18.7463838],
                [104.9418253, 18.7445132],
                [104.9409381, 18.7419075],
                [104.9408338, 18.73928],
                [104.9464387, 18.738931],
                [104.9499915, 18.7397693],
                [104.9567285, 18.7392844],
                [104.9636756, 18.7409402],
                [104.9685743, 18.7408951],
                [104.9723663, 18.7406998],
                [104.9753384, 18.7402105],
                [104.978208, 18.7385455],
                [104.981676, 18.7382181],
                [104.9848337, 18.7406512],
                [104.9883072, 18.7425976],
                [104.9918862, 18.7423059],
                [104.9955706, 18.7382189],
                [104.9974654, 18.7376351],
                [105.0020969, 18.7392894],
                [105.0043604, 18.7403403],
                [105.0087584, 18.7423877],
                [105.011929, 18.7445325],
                [105.0132587, 18.7455074],
                [105.0154065, 18.7463847],
                [105.016327, 18.7462871],
                [105.0177588, 18.7456045],
                [105.0185771, 18.745897],
                [105.0198045, 18.7467742],
                [105.0202334, 18.7481246],
                [105.0212931, 18.748998],
                [105.0225066, 18.7492457],
                [105.0226567, 18.7491973],
                [105.0229916, 18.7490895],
                [105.0228914, 18.7469401],
                [105.0209364, 18.7453154],
                [105.0195042, 18.7434631],
                [105.0185836, 18.7418057],
                [105.0182765, 18.7397582],
                [105.0183787, 18.7380032],
                [105.0189921, 18.7357607],
                [105.020219, 18.7340056],
                [105.0216507, 18.7328353],
                [105.0232868, 18.7317627],
                [105.0248209, 18.731275],
                [105.0277865, 18.7306896],
                [105.0309654, 18.7301454],
                [105.0330933, 18.7312691],
                [105.0349453, 18.733357],
                [105.0366401, 18.7333293],
                [105.0367412, 18.7323925],
                [105.036437, 18.7313622],
                [105.0360702, 18.7306882],
                [105.0366833, 18.728543],
                [105.037399, 18.727178],
                [105.0387282, 18.7262027],
                [105.040262, 18.7259099],
                [105.0417962, 18.7264945],
                [105.0443393, 18.7274261],
                [105.045994, 18.7287659],
                [105.0483465, 18.7296915],
                [105.0493649, 18.7298565],
                [105.0518101, 18.7304326],
                [105.0536507, 18.7297495],
                [105.0565102, 18.7287153],
                [105.0583336, 18.7274032],
                [105.0596383, 18.7262042],
                [105.0607523, 18.724705],
                [105.0606506, 18.7234872],
                [105.0591908, 18.7229909],
                [105.0578446, 18.7222523],
                [105.0576289, 18.7212177],
                [105.0564955, 18.7205846],
                [105.0555998, 18.7196172],
                [105.0548836, 18.7185449],
                [105.0545763, 18.7168876],
                [105.0540642, 18.7140603],
                [105.0536546, 18.7125004],
                [105.052734, 18.7115744],
                [105.0515067, 18.7106973],
                [105.0514265, 18.7102843],
                [105.0514262, 18.7092118],
                [105.0518354, 18.7087397],
                [105.0532669, 18.7084469],
                [105.056414, 18.7078684],
                [105.0602998, 18.7081597],
                [105.0623451, 18.7086465],
                [105.0640836, 18.7092309],
                [105.0655153, 18.7096203],
                [105.0671513, 18.7094248],
                [105.0698094, 18.7080588],
                [105.0710361, 18.7071809],
                [105.0723649, 18.7059129],
                [105.0739162, 18.705924],
                [105.0753478, 18.7060209],
                [105.07655, 18.70662],
                [105.079012, 18.7074702],
                [105.0809556, 18.7088831],
                [105.0828987, 18.7094673],
                [105.0847393, 18.7095639],
                [105.087091, 18.7092703],
                [105.0899537, 18.7084891],
                [105.0926118, 18.7073177],
                [105.0950653, 18.7063414],
                [105.0977236, 18.7058526],
                [105.1005864, 18.7054611],
                [105.1027337, 18.7053624],
                [105.1046765, 18.7054588],
                [105.1065176, 18.7062865],
                [105.1079504, 18.7081382],
                [105.1095883, 18.7110622],
                [105.1107138, 18.7122316],
                [105.1130668, 18.7138877],
                [105.1133324, 18.7140512],
                [105.1157022, 18.7150588],
                [105.117206, 18.7150578],
                [105.1191053, 18.7146796],
                [105.1214006, 18.7145273],
                [105.1226669, 18.714451],
                [105.1241708, 18.7146763],
                [105.1270999, 18.7153531],
                [105.1294748, 18.7159561],
                [105.1310579, 18.7159537],
                [105.1311857, 18.7159932],
                [105.1328854, 18.716518],
                [105.1338986, 18.7167046],
                [105.1341612, 18.7164007],
                [105.1343036, 18.716236],
                [105.1354876, 18.7126321],
                [105.1361195, 18.711048],
                [105.1369099, 18.7096145],
                [105.138017, 18.7084823],
                [105.1389659, 18.7074258],
                [105.1431691, 18.7051032],
                [105.1450681, 18.7045738],
                [105.1452753, 18.704119],
                [105.1456331, 18.7033337],
                [105.1450095, 18.7021826],
                [105.1431405, 18.7012244],
                [105.1408511, 18.6991246],
                [105.139466, 18.6948122],
                [105.1400194, 18.6920138],
                [105.1386697, 18.691439],
                [105.1366978, 18.6912486],
                [105.1357628, 18.6900976],
                [105.1370054, 18.6866419],
                [105.1389763, 18.6855847],
                [105.1411537, 18.68328],
                [105.1435127, 18.6806802],
                [105.1450794, 18.6801284],
                [105.149375, 18.680125],
                [105.1508067, 18.6798312],
                [105.1521355, 18.6789523],
                [105.1528507, 18.6781714],
                [105.1550983, 18.675341],
                [105.158268, 18.6730461],
                [105.1590806, 18.6720668],
                [105.1623865, 18.668083],
                [105.163833, 18.6645532],
                [105.1653075, 18.661757],
                [105.1666531, 18.6590979],
                [105.1674119, 18.6580802],
                [105.1682371, 18.6571936],
                [105.1691661, 18.6569468],
                [105.1706265, 18.6577218],
                [105.172366, 18.6586956],
                [105.1747187, 18.6593761],
                [105.1767594, 18.660056],
                [105.1792888, 18.6603808],
                [105.1815006, 18.6604169],
                [105.18476, 18.6582098],
                [105.1850696, 18.6559717],
                [105.1858312, 18.653028],
                [105.1869155, 18.6511808],
                [105.1888376, 18.6492117],
                [105.194248, 18.6495479],
                [105.1946246, 18.6489542],
                [105.1952837, 18.6479153],
                [105.1959046, 18.6465712],
                [105.1964217, 18.6451311],
                [105.1971468, 18.6440747],
                [105.1974563, 18.6426348],
                [105.19818, 18.6403308],
                [105.1953088, 18.6374098],
                [105.1937559, 18.6349177],
                [105.1922348, 18.6307616],
                [105.1897495, 18.6281688],
                [105.1893056, 18.6259063],
                [105.1904825, 18.6229448],
                [105.1918932, 18.6209447],
                [105.191916, 18.6190851],
                [105.1912845, 18.6169423],
                [105.1912635, 18.6145597],
                [105.191526, 18.6124157],
                [105.1924456, 18.6106821],
                [105.1923067, 18.6078076],
                [105.1919603, 18.6063788],
                [105.1907466, 18.6051846],
                [105.1896703, 18.6045645],
                [105.1873856, 18.6043571],
                [105.1893188, 18.6004682],
                [105.1897605, 18.6002035],
                [105.1902316, 18.6000898],
                [105.1905427, 18.6000092],
                [105.1910051, 18.5999628],
                [105.1922337, 18.6004657],
                [105.1935401, 18.6010131],
                [105.1941933, 18.6012201],
                [105.1947171, 18.6013285],
                [105.1955823, 18.6014636],
                [105.1966609, 18.601668],
                [105.1969192, 18.6016474],
                [105.1969772, 18.6016335],
                [105.1972234, 18.6016057],
                [105.1977954, 18.6013849],
                [105.1992217, 18.6006223],
                [105.2005187, 18.5998491],
                [105.2018649, 18.5989482],
                [105.2026097, 18.5988084],
                [105.2029863, 18.5986974],
                [105.2032471, 18.5986971],
                [105.2038847, 18.5987794],
                [105.204334, 18.598917],
                [105.2067388, 18.5987348],
                [105.2079411, 18.5985123],
                [105.208564, 18.5984702],
                [105.2088393, 18.5984699],
                [105.2094334, 18.5985383],
                [105.2100567, 18.5988278],
                [105.2108251, 18.5992554],
                [105.211231, 18.5993793],
                [105.2114484, 18.599393],
                [105.2117091, 18.5993926],
                [105.2126506, 18.5991566],
                [105.2132444, 18.5989625],
                [105.213867, 18.5987268],
                [105.2155468, 18.5980754],
                [105.2167055, 18.5977562],
                [105.2173261, 18.597724],
                [105.217705, 18.5976307],
                [105.2178933, 18.5975614],
                [105.2183855, 18.5973259],
                [105.2193118, 18.5965923],
                [105.2195436, 18.5965506],
                [105.2209676, 18.5954991],
                [105.2215895, 18.5947039],
                [105.2220773, 18.5940451],
                [105.2235982, 18.5920416],
                [105.2243441, 18.5908891],
                [105.2253202, 18.5899556],
                [105.2265265, 18.5891315],
                [105.2270504, 18.5885893],
                [105.2274522, 18.5881158],
                [105.2276387, 18.5879579],
                [105.2277752, 18.5879029],
                [105.2280842, 18.5878546],
                [105.2284717, 18.5878447],
                [105.2288405, 18.5878671],
                [105.22943, 18.5880377],
                [105.230013, 18.5882559],
                [105.2303555, 18.5884044],
                [105.2308594, 18.5887112],
                [105.2312424, 18.5889796],
                [105.2316256, 18.589325],
                [105.2321503, 18.5901504],
                [105.2321514, 18.5909766],
                [105.2324745, 18.5916102],
                [105.232657, 18.5924361],
                [105.2326984, 18.5934159],
                [105.2326186, 18.5939346],
                [105.2330635, 18.5952213],
                [105.2334275, 18.596316],
                [105.2337708, 18.5969496],
                [105.2341339, 18.5973719],
                [105.234799, 18.5977457],
                [105.2356051, 18.5980136],
                [105.236391, 18.5983969],
                [105.2368549, 18.5987229],
                [105.2372786, 18.5993947],
                [105.2375408, 18.5996826],
                [105.2382466, 18.6003158],
                [105.2386906, 18.6008147],
                [105.2388733, 18.6019096],
                [105.239338, 18.602908],
                [105.2399026, 18.603426],
                [105.2408909, 18.6044623],
                [105.2421809, 18.6050561],
                [105.2427653, 18.6053628],
                [105.24337, 18.6055733],
                [105.2455061, 18.6064351],
                [105.2461318, 18.6072797],
                [105.2466361, 18.6077977],
                [105.2469387, 18.6081624],
                [105.2472613, 18.6083924],
                [105.2477248, 18.6085071],
                [105.2490344, 18.6087936],
                [105.2502438, 18.6094068],
                [105.2507479, 18.6097711],
                [105.2511509, 18.6099243],
                [105.2518523, 18.61025],
                [105.2535032, 18.6112031],
                [105.2550251, 18.6131118],
                [105.258241, 18.6156183],
                [105.262811, 18.6178274],
                [105.265868, 18.6176016],
                [105.2694295, 18.6181783],
                [105.2701213, 18.6183119],
                [105.2715604, 18.6196611],
                [105.2734218, 18.6220216],
                [105.275937, 18.6217594],
                [105.2786466, 18.6220878],
                [105.2815519, 18.6237081],
                [105.2833318, 18.6234101],
                [105.2864235, 18.6207838],
                [105.2882076, 18.6196822],
                [105.2892682, 18.6196611],
                [105.2907089, 18.6209046],
                [105.2910251, 18.6211921],
                [105.2916513, 18.6215516],
                [105.2934956, 18.6222575],
                [105.2939755, 18.6223573],
                [105.2944124, 18.6223441],
                [105.295186, 18.6221272],
                [105.2956349, 18.6218996],
                [105.2972987, 18.6189181],
                [105.2975516, 18.6185266],
                [105.2983946, 18.6171498],
                [105.2988869, 18.6164611],
                [105.2994931, 18.6161224],
                [105.3002747, 18.6154126],
                [105.3009641, 18.6145483],
                [105.3017671, 18.6139127],
                [105.3020768, 18.6135749],
                [105.3021886, 18.6132243],
                [105.3024456, 18.6118693],
                [105.302879, 18.610507],
                [105.3035246, 18.6092115],
                [105.3041146, 18.6082395],
                [105.3046347, 18.6074565],
                [105.3048742, 18.6072671],
                [105.3059318, 18.6067519],
                [105.30644, 18.6066831],
                [105.3069202, 18.6066817],
                [105.3079226, 18.6065709],
                [105.308459, 18.6065018],
                [105.3093066, 18.6065398],
                [105.3099135, 18.6064437],
                [105.3106404, 18.6062325],
                [105.3118954, 18.6056629],
                [105.3126574, 18.605472],
                [105.3138436, 18.605401],
                [105.3145498, 18.6054124],
                [105.3153113, 18.6050327],
                [105.3161152, 18.6046801],
                [105.3163542, 18.6043289],
                [105.3168047, 18.6039235],
                [105.3173972, 18.6037195],
                [105.3183288, 18.603555],
                [105.3183995, 18.6035548],
                [105.3186267, 18.6039583],
                [105.3191359, 18.6041723],
                [105.3197563, 18.603847],
                [105.3202374, 18.6035509],
                [105.3204024, 18.6035173],
                [105.3205525, 18.6035484],
                [105.3208572, 18.6035959],
                [105.3212809, 18.6037399],
                [105.3218223, 18.6036737],
                [105.3223453, 18.6031877],
                [105.3229534, 18.602863],
                [105.3235948, 18.6022959],
                [105.3241182, 18.6019391],
                [105.3248372, 18.6018319],
                [105.3256492, 18.6017327],
                [105.3260555, 18.6017475],
                [105.3266988, 18.6018102],
                [105.327427, 18.6018886],
                [105.3281379, 18.6019188],
                [105.3288658, 18.6019325],
                [105.3293565, 18.601915],
                [105.3299825, 18.6018647],
                [105.3302446, 18.6017912],
                [105.3304977, 18.6015966],
                [105.3307665, 18.6009662],
                [105.3310354, 18.6004487],
                [105.3312367, 18.5998991],
                [105.3314888, 18.5993977],
                [105.3320118, 18.5988794],
                [105.3324078, 18.5983696],
                [105.3330488, 18.5977701],
                [105.3334184, 18.5969455],
                [105.3335528, 18.596606],
                [105.3336023, 18.5962507],
                [105.3342595, 18.5954413],
                [105.3351209, 18.5948897],
                [105.3358975, 18.5943706],
                [105.3362863, 18.5942564],
                [105.3365909, 18.5942231],
                [105.3369288, 18.5940284],
                [105.3376451, 18.5931784],
                [105.3377446, 18.5925969],
                [105.3382657, 18.5915458],
                [105.3383661, 18.5912064],
                [105.3384661, 18.5907701],
                [105.3387358, 18.5904463],
                [105.3390322, 18.590502],
                [105.3402687, 18.5908209],
                [105.3409452, 18.5907059],
                [105.341639, 18.5907037],
                [105.3423329, 18.5906854],
                [105.3427389, 18.5906356],
                [105.343094, 18.5905699],
                [105.3436348, 18.590326],
                [105.3442258, 18.589969],
                [105.3447411, 18.5897009],
                [105.3452988, 18.5895054],
                [105.3463806, 18.5890983],
                [105.3469549, 18.5887737],
                [105.3472933, 18.5887242],
                [105.3476321, 18.5888199],
                [105.3479872, 18.5887219],
                [105.3482405, 18.5885596],
                [105.348408, 18.588107],
                [105.3491487, 18.5874838],
                [105.3525261, 18.5863424],
                [105.3529725, 18.5862078],
                [105.3534882, 18.5862253],
                [105.3543652, 18.5861885],
                [105.354923, 18.5860401],
                [105.3559675, 18.5853041],
                [105.3565246, 18.5849694],
                [105.3570119, 18.5845948],
                [105.3574573, 18.5842337],
                [105.3578607, 18.5838462],
                [105.3584014, 18.5832145],
                [105.3589721, 18.5823108],
                [105.3591442, 18.581954],
                [105.3596451, 18.5815396],
                [105.3605035, 18.5811736],
                [105.3611171, 18.5810118],
                [105.3615359, 18.5809838],
                [105.3618992, 18.5811024],
                [105.3625286, 18.5814201],
                [105.3630287, 18.58147],
                [105.3637846, 18.5809432],
                [105.3648362, 18.5808004],
                [105.3657008, 18.5804141],
                [105.3662117, 18.5802443],
                [105.3669076, 18.580247],
                [105.3671307, 18.5801531],
                [105.36734, 18.580139],
                [105.3679122, 18.5801237],
                [105.368345, 18.580149],
                [105.369407, 18.5804385],
                [105.3702452, 18.580609],
                [105.3715723, 18.5808443],
                [105.3722705, 18.5809619],
                [105.3728004, 18.580827],
                [105.3740833, 18.5804564],
                [105.3745012, 18.5802019],
                [105.3748423, 18.5799811],
                [105.3753639, 18.5794265],
                [105.3759482, 18.5788918],
                [105.3764498, 18.578637],
                [105.3771785, 18.5784495],
                [105.3773094, 18.5784666],
                [105.3774526, 18.5785172],
                [105.3783601, 18.578893],
                [105.3787949, 18.5790297],
                [105.3792933, 18.5791413],
                [105.3801107, 18.5793789],
                [105.3803686, 18.5794665],
                [105.3805656, 18.5795035],
                [105.3809899, 18.5795021],
                [105.3810946, 18.5795118],
                [105.3812728, 18.5795512],
                [105.3818919, 18.5798141],
                [105.3824012, 18.5801423],
                [105.3828163, 18.5804658],
                [105.3829317, 18.5805305],
                [105.3830525, 18.580575],
                [105.3832622, 18.5806392],
                [105.3833931, 18.5806589],
                [105.3845606, 18.580445],
                [105.3846129, 18.5804448],
                [105.3847702, 18.5804693],
                [105.3849066, 18.5805239],
                [105.385169, 18.5806679],
                [105.3853579, 18.5807872],
                [105.385615, 18.5808813],
                [105.3858196, 18.5809506],
                [105.3860029, 18.58097],
                [105.3861865, 18.5809844],
                [105.3863853, 18.5809787],
                [105.3876007, 18.5809796],
                [105.388177, 18.5809875],
                [105.388685, 18.5810258],
                [105.3890099, 18.5810248],
                [105.3891985, 18.5810141],
                [105.3893083, 18.5809937],
                [105.3896015, 18.5809627],
                [105.3899367, 18.5809066],
                [105.3903135, 18.5808254],
                [105.3905855, 18.5807394],
                [105.3907789, 18.5806388],
                [105.3909881, 18.5805231],
                [105.3911659, 18.5804376],
                [105.391307, 18.5803921],
                [105.3915009, 18.5803714],
                [105.3916684, 18.5803659],
                [105.3922184, 18.580334],
                [105.3925691, 18.5802728],
                [105.3927627, 18.5802271],
                [105.3930297, 18.5801862],
                [105.3935818, 18.5800669],
                [105.3945766, 18.5799436],
                [105.3958753, 18.5798292],
                [105.3969961, 18.5797753],
                [105.3972054, 18.579786],
                [105.3974151, 18.5798038],
                [105.39752, 18.5798334],
                [105.3976146, 18.5799231],
                [105.3976886, 18.5801028],
                [105.3977313, 18.5803226],
                [105.3977433, 18.5807025],
                [105.3977759, 18.5810123],
                [105.3978938, 18.5817317],
                [105.3979789, 18.5820914],
                [105.3984324, 18.5828897],
                [105.3986012, 18.583169],
                [105.3986748, 18.5832486],
                [105.3988219, 18.5833682],
                [105.4005836, 18.5838219],
                [105.4018003, 18.5841776],
                [105.402671, 18.5844845],
                [105.4038156, 18.5851604],
                [105.4046659, 18.5856373],
                [105.4054216, 18.5859546],
                [105.4061146, 18.5863921],
                [105.4063141, 18.5864715],
                [105.4065445, 18.5864906],
                [105.4068483, 18.5864795],
                [105.4070567, 18.5863528],
                [105.4072555, 18.5861182],
                [105.4074326, 18.5858477],
                [105.4076516, 18.585567],
                [105.4079332, 18.585256],
                [105.4084711, 18.5848502],
                [105.4087286, 18.5846352],
                [105.4091139, 18.5844322],
                [105.4093966, 18.5843711],
                [105.4095538, 18.5843707],
                [105.4097007, 18.5844301],
                [105.4098373, 18.5845496],
                [105.4101629, 18.5847784],
                [105.4118534, 18.5857722],
                [105.4137449, 18.5871953],
                [105.4168884, 18.5900335],
                [105.4172565, 18.590382],
                [105.4175191, 18.5905611],
                [105.4176663, 18.5906805],
                [105.417876, 18.5907597],
                [105.4181067, 18.5907989],
                [105.418243, 18.5908184],
                [105.4184002, 18.5908279],
                [105.4186201, 18.5908271],
                [105.4187877, 18.5907965],
                [105.4189865, 18.5907258],
                [105.4192376, 18.590605],
                [105.4194775, 18.5903842],
                [105.4195293, 18.5902341],
                [105.41956, 18.5900441],
                [105.4195908, 18.5898839],
                [105.4196105, 18.5895639],
                [105.4196306, 18.5893338],
                [105.4196511, 18.5892138],
                [105.4198176, 18.5889033],
                [105.4201914, 18.5880422],
                [105.4203985, 18.5874618],
                [105.4204295, 18.5872916],
                [105.4204706, 18.5870916],
                [105.4205012, 18.5869015],
                [105.4205944, 18.5866212],
                [105.420698, 18.5863609],
                [105.4209378, 18.5859282],
                [105.4212019, 18.5856601],
                [105.42129, 18.5855812],
                [105.421337, 18.5855473],
                [105.421431, 18.585502],
                [105.4214958, 18.5854989],
                [105.4215842, 18.5855014],
                [105.421767, 18.5855627],
                [105.4220855, 18.5856796],
                [105.4228143, 18.5859862],
                [105.4235034, 18.5863786],
                [105.4237756, 18.586653],
                [105.4245885, 18.5881622],
                [105.4247343, 18.5884997],
                [105.4259276, 18.5879073],
                [105.4263724, 18.5878139],
                [105.4278606, 18.5872199],
                [105.4293905, 18.5878439],
                [105.4314611, 18.5879685],
                [105.4333935, 18.5871149],
                [105.4348069, 18.5875362],
                [105.4362631, 18.5895452],
                [105.4372159, 18.5914445],
                [105.438398, 18.5921618],
                [105.439254, 18.5940613],
                [105.4395462, 18.5948176],
                [105.4397218, 18.5953895],
                [105.4397624, 18.5961279],
                [105.4397951, 18.5971518],
                [105.4398239, 18.5974754],
                [105.4400936, 18.5978431],
                [105.4405534, 18.5981827],
                [105.4408477, 18.5984619],
                [105.4414817, 18.5985847],
                [105.4416151, 18.598538],
                [105.4417851, 18.5985492],
                [105.4421502, 18.5988496],
                [105.4427468, 18.5995086],
                [105.4429658, 18.5996125],
                [105.4432606, 18.5996505],
                [105.4436877, 18.5998434],
                [105.4444301, 18.600084],
                [105.445172, 18.6002131],
                [105.4456803, 18.6003089],
                [105.4461375, 18.6002981],
                [105.4463712, 18.6002686],
                [105.4466351, 18.6001903],
                [105.4468178, 18.6001414],
                [105.4470414, 18.6001118],
                [105.4472447, 18.6001307],
                [105.4474379, 18.6001884],
                [105.4476513, 18.6002752],
                [105.4478245, 18.6004007],
                [105.4480077, 18.6005846],
                [105.4482321, 18.6008748],
                [105.4483139, 18.6010201],
                [105.448365, 18.6011848],
                [105.4484061, 18.6013397],
                [105.4485185, 18.6015818],
                [105.448651, 18.6017463],
                [105.4489567, 18.6020897],
                [105.4493073, 18.6019878],
                [105.4497812, 18.6018676],
                [105.4508535, 18.6015078],
                [105.4535229, 18.6014491],
                [105.4560177, 18.6010859],
                [105.4593875, 18.6010823],
                [105.4592412, 18.6022972],
                [105.4595177, 18.6030348],
                [105.4632874, 18.6034304],
                [105.4649956, 18.6052674],
                [105.4674279, 18.605364],
                [105.4684979, 18.6055633],
                [105.4718787, 18.6070674],
                [105.4729585, 18.6074179],
                [105.4741674, 18.607502],
                [105.4758733, 18.6074609],
                [105.476562, 18.6076358],
                [105.4767537, 18.609478],
                [105.4764469, 18.6104941],
                [105.4763743, 18.6122481],
                [105.4764955, 18.6140754],
                [105.4782551, 18.6143468],
                [105.4799723, 18.6138041],
                [105.4803623, 18.6137317],
                [105.481996, 18.6131044],
                [105.4822965, 18.6131794],
                [105.4828087, 18.6133638],
                [105.4832085, 18.6133799],
                [105.4837938, 18.6132711],
                [105.4846402, 18.6130485],
                [105.486015, 18.6127771],
                [105.4863406, 18.6126842],
                [105.4868816, 18.6124244],
                [105.4888778, 18.6114186],
                [105.492062, 18.6106017],
                [105.4939004, 18.6107076],
                [105.4958304, 18.6106499],
                [105.4981383, 18.6108072],
                [105.5000731, 18.6107836],
                [105.501852, 18.6109009],
                [105.5022857, 18.6105585],
                [105.5027292, 18.6105116],
                [105.5040118, 18.6103937],
                [105.5043115, 18.6103472],
                [105.5049931, 18.6096705],
                [105.5054706, 18.6090172],
                [105.5059737, 18.6087984],
                [105.5062731, 18.6086947],
                [105.5063207, 18.6085573],
                [105.5063079, 18.6083056],
                [105.5061987, 18.6078712],
                [105.5059819, 18.6075286],
                [105.5058855, 18.6073344],
                [105.5058608, 18.6071286],
                [105.5058485, 18.6070028],
                [105.5058482, 18.6069112],
                [105.50592, 18.606831],
                [105.5060636, 18.6067849],
                [105.5067225, 18.6065656],
                [105.5073215, 18.6064153],
                [105.5074653, 18.6063349],
                [105.5075727, 18.606163],
                [105.5078231, 18.6057161],
                [105.5081563, 18.6049145],
                [105.508424, 18.6041702],
                [105.5085897, 18.6034148],
                [105.5086248, 18.6031629],
                [105.5087205, 18.6030598],
                [105.5088044, 18.6030481],
                [105.5089363, 18.6030477],
                [105.5091526, 18.6032187],
                [105.5096323, 18.6032403],
                [105.5099441, 18.6032509],
                [105.5105425, 18.6028832],
                [105.5110087, 18.6024244],
                [105.5113788, 18.6018743],
                [105.5115337, 18.6015421],
                [105.5124003, 18.6006703],
                [105.5130578, 18.5999822],
                [105.5138361, 18.5996597],
                [105.5151299, 18.5992101],
                [105.5155588, 18.5983623],
                [105.5160747, 18.5964619],
                [105.5156031, 18.5951591],
                [105.5154634, 18.5945417],
                [105.5155447, 18.5936607],
                [105.5158185, 18.5930193],
                [105.5158646, 18.5923901],
                [105.515803, 18.5918411],
                [105.5156376, 18.5906862],
                [105.5161861, 18.5896551],
                [105.51677, 18.5884752],
                [105.5174679, 18.5873293],
                [105.5176571, 18.5864823],
                [105.5179313, 18.5859438],
                [105.5180005, 18.5850857],
                [105.5179813, 18.5846511],
                [105.5181836, 18.5841471],
                [105.5187804, 18.5832761],
                [105.5196293, 18.5824844],
                [105.5202916, 18.5814873],
                [105.5205424, 18.5811664],
                [105.5209852, 18.5809135],
                [105.5212363, 18.5807069],
                [105.5213556, 18.5805235],
                [105.5215813, 18.5798251],
                [105.5217722, 18.5795271],
                [105.5225018, 18.5789531],
                [105.5231241, 18.5785967],
                [105.523333, 18.5782644],
                [105.5234639, 18.5779781],
                [105.5242684, 18.5783762],
                [105.5245925, 18.5785126],
                [105.524796, 18.5784319],
                [105.5251309, 18.578145],
                [105.5253095, 18.577767],
                [105.52526, 18.5772523],
                [105.5253783, 18.5767144],
                [105.5255249, 18.5760702],
                [105.525532, 18.576039],
                [105.5257356, 18.5759927],
                [105.5265389, 18.5760019],
                [105.5266329, 18.5753953],
                [105.5267679, 18.5744569],
                [105.5271029, 18.5742043],
                [105.5271865, 18.5741011],
                [105.5271604, 18.5734605],
                [105.527255, 18.5730141],
                [105.5272162, 18.5720876],
                [105.5274369, 18.5717323],
                [105.5276521, 18.5715144],
                [105.5279985, 18.5711474],
                [105.5281656, 18.5708724],
                [105.5282487, 18.5706091],
                [105.528223, 18.5700714],
                [105.5283416, 18.5696593],
                [105.5287355, 18.5690747],
                [105.5289143, 18.5687425],
                [105.5290867, 18.5682959],
                [105.5293619, 18.5681121],
                [105.529709, 18.5679738],
                [105.5299123, 18.5678131],
                [105.5302228, 18.5674232],
                [105.5304378, 18.567171],
                [105.5304961, 18.5666446],
                [105.5305357, 18.5658781],
                [105.5305342, 18.5653518],
                [105.5304374, 18.5651004],
                [105.5304845, 18.5648142],
                [105.5304356, 18.5645056],
                [105.5304229, 18.5642769],
                [105.53053, 18.5640706],
                [105.5309247, 18.5637149],
                [105.5310196, 18.5634286],
                [105.5309584, 18.5630055],
                [105.5304765, 18.5621946],
                [105.5304817, 18.561943],
                [105.53091, 18.5609236],
                [105.5313411, 18.5607623],
                [105.5322275, 18.5605653],
                [105.5327785, 18.5604494],
                [105.5329875, 18.5602956],
                [105.5331374, 18.5601852],
                [105.5333886, 18.5600472],
                [105.5356697, 18.5591004],
                [105.5358309, 18.5590335],
                [105.538395, 18.5586718],
                [105.539041, 18.5582466],
                [105.5391604, 18.558109],
                [105.5391595, 18.5578345],
                [105.5390868, 18.5575601],
                [105.5391461, 18.5573426],
                [105.5396482, 18.5569064],
                [105.5413565, 18.5550483],
                [105.5416909, 18.5546699],
                [105.5423975, 18.5544962],
                [105.5427264, 18.554255],
                [105.5433475, 18.5535212],
                [105.5436253, 18.5532463],
                [105.5437417, 18.5531311],
                [105.5440053, 18.5530731],
                [105.5445329, 18.5531402],
                [105.5447965, 18.5531166],
                [105.5453227, 18.5527146],
                [105.5461958, 18.5521172],
                [105.5466896, 18.5528709],
                [105.5471345, 18.5532586],
                [105.5477111, 18.5536343],
                [105.5480169, 18.553702],
                [105.5485561, 18.5536205],
                [105.5490352, 18.5535161],
                [105.5493703, 18.553355],
                [105.5496821, 18.5533884],
                [105.5501629, 18.5538216],
                [105.5505349, 18.5539579],
                [105.5510523, 18.5545398],
                [105.5515235, 18.5557167],
                [105.551684, 18.5561447],
                [105.5520652, 18.5570665],
                [105.5538889, 18.5577969],
                [105.5541554, 18.5578631],
                [105.5548978, 18.5578474],
                [105.5552905, 18.557972],
                [105.5555916, 18.5580221],
                [105.5560928, 18.5580587],
                [105.5572591, 18.5581831],
                [105.5575093, 18.5582619],
                [105.5581885, 18.5584587],
                [105.5585764, 18.5587415],
                [105.5599828, 18.5592381],
                [105.5607866, 18.5594187],
                [105.56323, 18.5596859],
                [105.5666222, 18.5601433],
                [105.5725472, 18.5605082],
                [105.5727575, 18.5580871],
                [105.5737859, 18.5583466],
                [105.5743098, 18.5583677],
                [105.57488, 18.5580365],
                [105.5757636, 18.5587949],
                [105.5763723, 18.5590324],
                [105.5768405, 18.5592596],
                [105.5775495, 18.5597837],
                [105.5777537, 18.559886],
                [105.5782394, 18.5599531],
                [105.578563, 18.5599521],
                [105.5788027, 18.55994],
                [105.5791022, 18.5598819],
                [105.579665, 18.55972],
                [105.581113, 18.5590292],
                [105.5823945, 18.5586592],
                [105.5828139, 18.558635],
                [105.5830537, 18.5586342],
                [105.5836486, 18.5587356],
                [105.5839843, 18.5591118],
                [105.5840928, 18.559283],
                [105.5842251, 18.5593856],
                [105.5844051, 18.5594651],
                [105.5845613, 18.5595562],
                [105.5846936, 18.559693],
                [105.5847901, 18.5598873],
                [105.5850934, 18.560893],
                [105.5851177, 18.5609295],
                [105.5854909, 18.5614866],
                [105.5855419, 18.5615929],
                [105.5855513, 18.5616123],
                [105.5855044, 18.5619442],
                [105.585657, 18.5627444],
                [105.5856821, 18.5630761],
                [105.5852137, 18.5633704],
                [105.5859513, 18.5641244],
                [105.5859751, 18.5646539],
                [105.5858214, 18.5652606],
                [105.5858889, 18.5657294],
                [105.5864906, 18.5663682],
                [105.5867433, 18.5666762],
                [105.5870204, 18.5670872],
                [105.587153, 18.5673041],
                [105.5872494, 18.5674182],
                [105.5873214, 18.5674523],
                [105.5874773, 18.5674633],
                [105.5876331, 18.5674628],
                [105.587825, 18.567485],
                [105.588017, 18.5675417],
                [105.588233, 18.5676668],
                [105.5886913, 18.5683746],
                [105.5887876, 18.568523],
                [105.5893776, 18.5710607],
                [105.5897649, 18.5727076],
                [105.5903249, 18.5727079],
                [105.5907654, 18.572629],
                [105.5913027, 18.5724722],
                [105.5917789, 18.5724852],
                [105.5921529, 18.5724695],
                [105.5929086, 18.5722763],
                [105.5931866, 18.5721919],
                [105.5933305, 18.5721974],
                [105.5935307, 18.5722504],
                [105.5940196, 18.5725651],
                [105.5946585, 18.5729329],
                [105.5950968, 18.5731343],
                [105.5953438, 18.5731694],
                [105.5955566, 18.5732104],
                [105.5958197, 18.5733083],
                [105.5958575, 18.5734302],
                [105.5958651, 18.5736142],
                [105.5958223, 18.5738749],
                [105.5958009, 18.5742373],
                [105.5958518, 18.574538],
                [105.5959686, 18.5747329],
                [105.5964729, 18.5750057],
                [105.5967613, 18.5752371],
                [105.5972874, 18.5753886],
                [105.5975256, 18.5754933],
                [105.5980508, 18.576228],
                [105.5986554, 18.5767012],
                [105.5994091, 18.5771052],
                [105.5996136, 18.5770465],
                [105.5997169, 18.5768951],
                [105.5997064, 18.576682],
                [105.5993945, 18.5761024],
                [105.5993819, 18.5758023],
                [105.6011968, 18.575992],
                [105.6038991, 18.5765554],
                [105.6062414, 18.576994],
                [105.6066539, 18.5766952],
                [105.6069923, 18.5766198],
                [105.6085853, 18.5767325],
                [105.6091567, 18.5767729],
                [105.61187, 18.5769306],
                [105.6122872, 18.5769548],
                [105.616378, 18.5771926],
                [105.6165786, 18.573553],
                [105.6186329, 18.5699895],
                [105.6260817, 18.5676356],
                [105.6318185, 18.5711123],
                [105.6390271, 18.5746476],
                [105.644238, 18.5769017],
                [105.6498827, 18.5790727],
                [105.6524228, 18.5800398],
                [105.6532112, 18.5803967],
                [105.6550069, 18.5812096],
                [105.657582, 18.582478],
                [105.6624782, 18.5842591],
                [105.6656727, 18.5839158],
                [105.6687096, 18.5827669],
                [105.6725724, 18.5802425],
                [105.6761777, 18.5795918],
                [105.677629, 18.5793298],
                [105.681805, 18.5798843],
                [105.6877973, 18.5911666],
                [105.6887889, 18.5937944],
                [105.6891551, 18.5947215],
                [105.6895141, 18.5959538],
                [105.689625, 18.5977673],
                [105.6893376, 18.6037517],
                [105.6893454, 18.6056843],
                [105.6893248, 18.6093263],
                [105.689356, 18.6106128],
                [105.6893923, 18.6121084],
                [105.6892283, 18.6138402],
                [105.6890379, 18.6152632],
                [105.6888724, 18.6166289],
                [105.6886447, 18.6180208],
                [105.688646, 18.6183515],
                [105.6885672, 18.6196897],
                [105.6887726, 18.6211071],
                [105.6895578, 18.6240088],
                [105.6903867, 18.6277739],
                [105.6908578, 18.6288698],
                [105.6919358, 18.6301877],
                [105.6938635, 18.6315452],
                [105.6956094, 18.6321784],
                [105.6976667, 18.6324266],
                [105.699108, 18.6324031],
                [105.7009008, 18.632105],
                [105.7015991, 18.6321561],
                [105.7022721, 18.6325596],
                [105.702315, 18.6325853],
                [105.7047735, 18.6364118],
                [105.7059652, 18.6384467],
                [105.7063251, 18.6393193],
                [105.7064802, 18.6399014],
                [105.7066225, 18.641007],
                [105.7068712, 18.6421577],
                [105.7077402, 18.6440484],
                [105.710533, 18.6501243],
                [105.711805, 18.6550724],
                [105.713347, 18.6633756],
                [105.7138014, 18.6643459],
                [105.7144241, 18.6652894],
                [105.71514, 18.6660878],
                [105.7168524, 18.667805],
                [105.7187683, 18.6694728],
                [105.7201967, 18.6701956],
                [105.7216918, 18.6707558],
                [105.7239949, 18.6715891],
                [105.7261867, 18.6723089],
                [105.7275872, 18.6724735],
                [105.7291903, 18.6723701],
                [105.7311493, 18.6721683],
                [105.7331073, 18.6717722],
                [105.740748, 18.6687779],
                [105.7443182, 18.6679636],
                [105.7471372, 18.6676729],
                [105.7490244, 18.6676163],
                [105.7511106, 18.6676409],
                [105.7518514, 18.6677896],
                [105.7614531, 18.6698776],
                [105.7637259, 18.6706752],
                [105.7653775, 18.671608],
                [105.7670954, 18.6732512],
                [105.7680928, 18.6744796],
                [105.7687073, 18.6758531],
                [105.7687567, 18.6761889],
                [105.7689641, 18.6775986],
                [105.7687532, 18.6798624],
                [105.7682005, 18.6826014],
                [105.7667385, 18.6876907],
                [105.7666881, 18.687783],
                [105.7647953, 18.6912718],
                [105.7645615, 18.6916724],
                [105.763499, 18.6936996],
                [105.7608952, 18.6973061],
                [105.7576963, 18.701977],
                [105.756287, 18.7052643],
                [105.7549692, 18.7114182],
                [105.7546627, 18.7124997],
                [105.754071, 18.7144444],
                [105.7538971, 18.7150042],
                [105.7535147, 18.7162342],
                [105.7526589, 18.7177627],
                [105.7518521, 18.7195069],
                [105.7489309, 18.7271151],
                [105.7468125, 18.7308814],
                [105.7458939, 18.7324961],
                [105.744237, 18.7350235],
                [105.7440327, 18.735411],
                [105.7438361, 18.735802],
                [105.7434955, 18.7365291],
                [105.7432926, 18.7369947],
                [105.7431271, 18.7373984],
                [105.7429696, 18.737805],
                [105.7428201, 18.7382143],
                [105.7426786, 18.7386261],
                [105.7425352, 18.7390728],
                [105.7424012, 18.7395222],
                [105.7422767, 18.739974],
                [105.7421618, 18.740428],
                [105.7420565, 18.7408842],
                [105.7419609, 18.7413423],
                [105.741875, 18.741802],
                [105.7418344, 18.7420323],
                [105.7417989, 18.7422634],
                [105.7417594, 18.7425739],
                [105.7417293, 18.7428853],
                [105.7417085, 18.7431974],
                [105.741697, 18.7435099],
                [105.741695, 18.7438226],
                [105.7417023, 18.7441353],
                [105.7417189, 18.7444476],
                [105.7417449, 18.7447594],
                [105.7417803, 18.7450703],
                [105.7418249, 18.7453802],
                [105.7418788, 18.7456887],
                [105.7419419, 18.7459956],
                [105.7420142, 18.7463008],
                [105.7420955, 18.7466039],
                [105.7424094, 18.7472844],
                [105.7426026, 18.7476714],
                [105.7428045, 18.7480544],
                [105.7430148, 18.7484333],
                [105.7432336, 18.748808],
                [105.7434606, 18.7491781],
                [105.7436958, 18.7495437],
                [105.7439391, 18.7499045],
                [105.7441904, 18.7502603],
                [105.7444496, 18.750611],
                [105.7447165, 18.7509565],
                [105.7449911, 18.7512966],
                [105.7452654, 18.7516221],
                [105.7455467, 18.7519422],
                [105.7458349, 18.7522567],
                [105.7461298, 18.7525656],
                [105.7464314, 18.7528687],
                [105.7467394, 18.7531658],
                [105.7470539, 18.7534569],
                [105.7473746, 18.7537419],
                [105.7477014, 18.7540205],
                [105.7480341, 18.7542928],
                [105.7483727, 18.7545585],
                [105.748717, 18.7548176],
                [105.7490668, 18.7550699],
                [105.749422, 18.7553154],
                [105.7497825, 18.7555539],
                [105.7505862, 18.7560732],
                [105.7513947, 18.7565858],
                [105.752208, 18.7570916],
                [105.753026, 18.7575905],
                [105.753944, 18.7580976],
                [105.7547683, 18.7585118],
                [105.7618516, 18.7621681],
                [105.7621122, 18.7623398],
                [105.7623086, 18.7624736],
                [105.7625282, 18.7626359],
                [105.7627067, 18.7628252],
                [105.7628367, 18.7629949],
                [105.763015, 18.7632148],
                [105.7631441, 18.763421],
                [105.7633551, 18.7637432],
                [105.7655655, 18.7669016],
                [105.7656712, 18.767025],
                [105.7657803, 18.7671314],
                [105.7659162, 18.7672379],
                [105.7660578, 18.7673377],
                [105.7662046, 18.7674304],
                [105.7663562, 18.7675158],
                [105.7665123, 18.7675937],
                [105.7666724, 18.7676639],
                [105.7668362, 18.7677261],
                [105.7670031, 18.7677803],
                [105.7671727, 18.7678262],
                [105.7673256, 18.76786],
                [105.76748, 18.7678872],
                [105.7676355, 18.7679076],
                [105.9660296, 18.7895502],
                [106.1664675, 18.857548],
                [106.1620806, 18.8693638],
                [106.1575202, 18.8796895],
                [106.1496931, 18.8945149],
                [106.140597, 18.9086858],
                [106.1302899, 18.9221042],
                [106.1118839, 18.9414488],
                [106.0985193, 18.9530905],
                [106.085183, 18.9633369],
                [106.0702237, 18.9731188],
                [106.0426471, 18.9874862],
                [106.0255823, 18.9942594],
                [106.0079466, 18.9995872],
                [105.9898775, 19.0034252],
                [105.9715172, 19.0057411],
                [105.9530105, 19.0065152],
                [105.9336828, 19.0057411],
                [105.9309925, 19.0230414],
                [105.9375097, 19.0386677],
                [105.9426278, 19.0547474],
                [105.9463136, 19.0711696],
                [105.9485434, 19.0878216],
                [105.9493025, 19.1045896],
                [105.9486279, 19.1207679],
                [105.9634119, 19.1238041],
                [105.9806005, 19.1288196],
                [105.9977192, 19.1353366],
                [106.0146137, 19.1432711],
                [106.0306847, 19.1525946],
                [106.0458059, 19.1632286],
                [106.0605507, 19.1757142],
                [106.0723033, 19.1874637],
                [106.0830119, 19.2000626],
                [106.0893261, 19.2088546],
                [105.8050977, 19.2872227],
                [105.8049412, 19.2886676],
                [105.8042669, 19.2907108],
                [105.8033481, 19.2934165],
                [105.8028108, 19.2945174],
                [105.8021965, 19.2967734],
                [105.8018517, 19.2986583],
                [105.8013987, 19.3001288],
                [105.8009648, 19.3008709],
                [105.8007107, 19.3009412],
                [105.8002381, 19.3009432],
                [105.7996554, 19.3007385],
                [105.7965224, 19.299405],
                [105.790364, 19.2963919],
                [105.7884701, 19.2956747],
                [105.7876695, 19.2955055],
                [105.7860688, 19.2952705],
                [105.7823379, 19.2943021],
                [105.7785513, 19.2930747],
                [105.776475, 19.2921854],
                [105.7724514, 19.2908035],
                [105.7718691, 19.2906678],
                [105.7710326, 19.2905677],
                [105.7701964, 19.2905712],
                [105.7695786, 19.2906084],
                [105.7680167, 19.2908913],
                [105.7673275, 19.2912051],
                [105.7665849, 19.2917781],
                [105.7657893, 19.2926796],
                [105.7643838, 19.2953108],
                [105.7640226, 19.2958305],
                [105.7636967, 19.2961082],
                [105.7623571, 19.2972883],
                [105.7617608, 19.298068],
                [105.7601023, 19.3010457],
                [105.7584052, 19.3035745],
                [105.7562564, 19.3067096],
                [105.7557488, 19.3070226],
                [105.7554224, 19.3071966],
                [105.7548056, 19.3074755],
                [105.7542974, 19.3076504],
                [105.7529017, 19.3045472],
                [105.7514742, 19.3024458],
                [105.7511081, 19.3018947],
                [105.7490746, 19.3024212],
                [105.747096, 19.3030338],
                [105.7467335, 19.3032771],
                [105.7465891, 19.3034849],
                [105.7465183, 19.3039344],
                [105.7465346, 19.3075268],
                [105.7464659, 19.3083907],
                [105.746124, 19.3091693],
                [105.7438149, 19.3130822],
                [105.7430935, 19.3143632],
                [105.7427876, 19.3150726],
                [105.7427537, 19.3156254],
                [105.7437209, 19.3204577],
                [105.743708, 19.3216149],
                [105.7434569, 19.3223759],
                [105.7400636, 19.3197644],
                [105.7380214, 19.3183909],
                [105.7361991, 19.317431],
                [105.7356887, 19.3171222],
                [105.7350324, 19.3167103],
                [105.7340472, 19.3159543],
                [105.7304514, 19.3147476],
                [105.7297198, 19.3142495],
                [105.7290395, 19.3140573],
                [105.7285296, 19.313852],
                [105.7279829, 19.3135778],
                [105.7276911, 19.3133372],
                [105.7275446, 19.3130959],
                [105.7274698, 19.3126472],
                [105.7274306, 19.3119911],
                [105.7273201, 19.3116806],
                [105.7270646, 19.3114398],
                [105.7268084, 19.3110609],
                [105.7263161, 19.3107346],
                [105.72545, 19.3105326],
                [105.7245691, 19.3103271],
                [105.7221317, 19.3099912],
                [105.7214035, 19.3097523],
                [105.7208934, 19.3095126],
                [105.7202373, 19.3091352],
                [105.7188882, 19.3082423],
                [105.717429, 19.3071081],
                [105.7159136, 19.3056114],
                [105.7146696, 19.3037855],
                [105.7143767, 19.3033376],
                [105.7139758, 19.3030974],
                [105.7135026, 19.302961],
                [105.7082308, 19.302947],
                [105.7059235, 19.3032496],
                [105.7040357, 19.3038786],
                [105.7020729, 19.3039898],
                [105.7017818, 19.3039218],
                [105.6983361, 19.3036445],
                [105.6953285, 19.3034211],
                [105.6939288, 19.303583],
                [105.6936817, 19.303584],
                [105.6929333, 19.3026591],
                [105.6917352, 19.300565],
                [105.6914368, 19.300324],
                [105.6908845, 19.3002858],
                [105.6896959, 19.3004516],
                [105.6888056, 19.3008586],
                [105.6872815, 19.3020347],
                [105.6855903, 19.3038975],
                [105.6840702, 19.3060826],
                [105.6827157, 19.3072177],
                [105.6819963, 19.3078661],
                [105.6815735, 19.308352],
                [105.680815, 19.3098076],
                [105.6794414, 19.3114876],
                [105.6774094, 19.3131498],
                [105.676944, 19.3135955],
                [105.6765218, 19.3142428],
                [105.6764386, 19.3146467],
                [105.6764409, 19.3152117],
                [105.6769567, 19.316703],
                [105.6776674, 19.3190613],
                [105.6775414, 19.319425],
                [105.676104, 19.321085],
                [105.6747908, 19.3219777],
                [105.6745358, 19.3219786],
                [105.6741102, 19.3217784],
                [105.6725709, 19.3193223],
                [105.670481, 19.3172718],
                [105.6682217, 19.3153025],
                [105.6659636, 19.3136561],
                [105.6653465, 19.3133557],
                [105.6646315, 19.315134],
                [105.6642168, 19.3176377],
                [105.6639732, 19.3204233],
                [105.6642529, 19.3212899],
                [105.6660102, 19.3251579],
                [105.6665674, 19.3263666],
                [105.666911, 19.3272935],
                [105.6673651, 19.3292896],
                [105.6673715, 19.3308635],
                [105.6670022, 19.3340532],
                [105.6665639, 19.3359717],
                [105.66585, 19.3380326],
                [105.6658268, 19.3380982],
                [105.6652199, 19.3398107],
                [105.6650506, 19.3399728],
                [105.6647961, 19.3400544],
                [105.664414, 19.3401364],
                [105.659061, 19.3401559],
                [105.6570646, 19.3402439],
                [105.6566624, 19.3405883],
                [105.6560713, 19.3415187],
                [105.6552288, 19.3433379],
                [105.6541782, 19.346207],
                [105.6538468, 19.3483472],
                [105.6534994, 19.351799],
                [105.6533305, 19.3520821],
                [105.6529491, 19.3523256],
                [105.6521855, 19.3526108],
                [105.6501477, 19.3530217],
                [105.6490432, 19.353066],
                [105.6456376, 19.3529824],
                [105.6439863, 19.3528821],
                [105.6441243, 19.3555857],
                [105.6441533, 19.3575429],
                [105.6440277, 19.3580276],
                [105.643732, 19.3584726],
                [105.6432664, 19.3589182],
                [105.6408483, 19.3598953],
                [105.6402545, 19.3601799],
                [105.6399585, 19.3605442],
                [105.6395845, 19.3626844],
                [105.6392802, 19.3663782],
                [105.6367259, 19.3651361],
                [105.6357478, 19.3649378],
                [105.6347702, 19.3648605],
                [105.633963, 19.3649036],
                [105.6335812, 19.3650664],
                [105.6329451, 19.3653914],
                [105.6323943, 19.3657969],
                [105.631971, 19.3662423],
                [105.631168, 19.3673348],
                [105.6301553, 19.3691948],
                [105.629841, 19.3703461],
                [105.6295516, 19.3724457],
                [105.629151, 19.3741683],
                [105.6287155, 19.3760404],
                [105.6284918, 19.3786443],
                [105.6283665, 19.3792097],
                [105.6281556, 19.379614],
                [105.6278168, 19.3799381],
                [105.6274781, 19.3802621],
                [105.6251033, 19.3815617],
                [105.6222609, 19.3828225],
                [105.6212215, 19.3832902],
                [105.6203241, 19.3823984],
                [105.6180649, 19.3801531],
                [105.6169569, 19.3793093],
                [105.6144851, 19.3774209],
                [105.6135477, 19.3767783],
                [105.6132486, 19.3763353],
                [105.6131398, 19.3756294],
                [105.6133433, 19.3732476],
                [105.6132983, 19.3725617],
                [105.6129548, 19.3716346],
                [105.6126981, 19.3711512],
                [105.6123136, 19.3706278],
                [105.6118453, 19.3703873],
                [105.611505, 19.3702673],
                [105.6108668, 19.3700677],
                [105.6098039, 19.3699098],
                [105.6051109, 19.3705914],
                [105.6009916, 19.3712508],
                [105.5988686, 19.371742],
                [105.5963005, 19.3724971],
                [105.5955785, 19.3725802],
                [105.5952808, 19.3725408],
                [105.5946849, 19.3722603],
                [105.5930243, 19.3713374],
                [105.5923863, 19.3711781],
                [105.5917486, 19.3710994],
                [105.5906287, 19.3700913],
                [105.5893729, 19.3721565],
                [105.5882288, 19.3730884],
                [105.5868101, 19.3744046],
                [105.5857527, 19.3758206],
                [105.5844441, 19.3782463],
                [105.5833036, 19.3801871],
                [105.5826102, 19.3823888],
                [105.5825726, 19.3837611],
                [105.5833074, 19.3872296],
                [105.5833767, 19.3887831],
                [105.5832111, 19.3900348],
                [105.5827889, 19.3908029],
                [105.5819426, 19.391855],
                [105.5813497, 19.3924218],
                [105.5797827, 19.3939605],
                [105.5788065, 19.3948235],
                [105.5781517, 19.3954387],
                [105.5756929, 19.3972223],
                [105.5710722, 19.4005866],
                [105.5704163, 19.401416],
                [105.5677061, 19.4042495],
                [105.5653363, 19.4072434],
                [105.5633266, 19.4099335],
                [105.5613822, 19.413047],
                [105.5602822, 19.4145437],
                [105.5585274, 19.4172329],
                [105.5564117, 19.4200241],
                [105.5550145, 19.4216831],
                [105.5522448, 19.4259492],
                [105.5500864, 19.4287809],
                [105.5466382, 19.4336545],
                [105.5458769, 19.4347868],
                [105.5439079, 19.4371133],
                [105.5417926, 19.4401867],
                [105.5412863, 19.4413587],
                [105.5410351, 19.4425298],
                [105.5409347, 19.444326],
                [105.5407266, 19.4456585],
                [105.5404745, 19.4465471],
                [105.5399256, 19.4476787],
                [105.5386976, 19.4491757],
                [105.5374908, 19.4506523],
                [105.5364326, 19.452068],
                [105.5348683, 19.4547362],
                [105.5336412, 19.4565156],
                [105.5327795, 19.4575815],
                [105.5312812, 19.4585197],
                [105.528748, 19.4596081],
                [105.5251288, 19.4610929],
                [105.5222838, 19.4618383],
                [105.5200586, 19.4621395],
                [105.5192301, 19.4620927],
                [105.5183497, 19.461997],
                [105.516952, 19.4620501],
                [105.5162282, 19.4623471],
                [105.513643, 19.4634356],
                [105.5130737, 19.4634862],
                [105.5122966, 19.4633411],
                [105.5108456, 19.4629029],
                [105.5080724, 19.461805],
                [105.5075546, 19.4617573],
                [105.5062607, 19.4619083],
                [105.50476, 19.462109],
                [105.5029487, 19.4623598],
                [105.5021202, 19.462313],
                [105.5014468, 19.4621674],
                [105.5003072, 19.461974],
                [105.4995813, 19.461632],
                [105.4978707, 19.4608996],
                [105.4952112, 19.4592269],
                [105.4937294, 19.4582459],
                [105.4931052, 19.4577057],
                [105.4927929, 19.4573865],
                [105.4925575, 19.4567714],
                [105.4920585, 19.4547533],
                [105.4912892, 19.4534379],
                [105.4909507, 19.4530447],
                [105.4902999, 19.4523076],
                [105.4899875, 19.4519637],
                [105.4895194, 19.4515709],
                [105.4891034, 19.4512519],
                [105.4883241, 19.4509092],
                [105.4857768, 19.4496705],
                [105.4850505, 19.4493173],
                [105.483375, 19.4485952],
                [105.4827519, 19.4484245],
                [105.4821809, 19.4483275],
                [105.4814285, 19.4483295],
                [105.4795613, 19.4485561],
                [105.4783945, 19.4487808],
                [105.4776043, 19.44914],
                [105.4758696, 19.4503513],
                [105.4749896, 19.4510678],
                [105.4744985, 19.4516847],
                [105.473698, 19.4529675],
                [105.4724196, 19.4549779],
                [105.4710757, 19.4567792],
                [105.4708424, 19.4568537],
                [105.4704534, 19.456904],
                [105.4694411, 19.4567095],
                [105.4683372, 19.4563061],
                [105.4671944, 19.4558658],
                [105.4650383, 19.4548862],
                [105.4626486, 19.4538825],
                [105.4603702, 19.4528575],
                [105.459336, 19.452204],
                [105.4583478, 19.4513692],
                [105.4567341, 19.449477],
                [105.4552879, 19.4472272],
                [105.4536189, 19.4441037],
                [105.452825, 19.4431328],
                [105.452305, 19.4427155],
                [105.4517332, 19.4423475],
                [105.4504087, 19.441809],
                [105.4494593, 19.4412019],
                [105.4469285, 19.4403399],
                [105.4451507, 19.4400857],
                [105.4449488, 19.4421549],
                [105.4447431, 19.4428204],
                [105.4444592, 19.4433136],
                [105.4438888, 19.4439169],
                [105.4432692, 19.4445725],
                [105.4411344, 19.4466095],
                [105.4400997, 19.4477203],
                [105.4393245, 19.4488304],
                [105.4366476, 19.4519276],
                [105.43582, 19.4528654],
                [105.4339945, 19.4542366],
                [105.4333725, 19.4544844],
                [105.4328538, 19.4545596],
                [105.4321276, 19.4546105],
                [105.430182, 19.454689],
                [105.4289108, 19.4547412],
                [105.425708, 19.4552291],
                [105.4241263, 19.4555529],
                [105.4229598, 19.4559251],
                [105.4223639, 19.4562713],
                [105.4202274, 19.4577661],
                [105.4195539, 19.4581617],
                [105.4189059, 19.4584342],
                [105.4181801, 19.4586575],
                [105.4176353, 19.4586833],
                [105.4169606, 19.4586356],
                [105.4156108, 19.4583679],
                [105.412352, 19.457304],
                [105.4113647, 19.4567398],
                [105.4105589, 19.4561013],
                [105.4087902, 19.4543075],
                [105.4070852, 19.4520333],
                [105.4058113, 19.4509772],
                [105.4054733, 19.4506824],
                [105.4051613, 19.4504122],
                [105.4047712, 19.4500436],
                [105.4035871, 19.4485564],
                [105.4030938, 19.448385],
                [105.4027565, 19.4483857],
                [105.4023675, 19.4484359],
                [105.4000087, 19.4492784],
                [105.3980394, 19.4502432],
                [105.3948135, 19.451888],
                [105.3911861, 19.4538908],
                [105.3901636, 19.4548165],
                [105.3897502, 19.4555562],
                [105.3895708, 19.4564678],
                [105.3895488, 19.4581179],
                [105.3898249, 19.4596811],
                [105.3908957, 19.4626342],
                [105.3917437, 19.4646149],
                [105.3918745, 19.4650578],
                [105.3919275, 19.4655257],
                [105.3909584, 19.4671409],
                [105.3892773, 19.4693856],
                [105.388446, 19.4705725],
                [105.3880099, 19.471063],
                [105.3877389, 19.471448],
                [105.3861092, 19.4737638],
                [105.385257, 19.4754649],
                [105.3842879, 19.4771047],
                [105.3835389, 19.4786332],
                [105.3829713, 19.4800135],
                [105.3826493, 19.480987],
                [105.3826759, 19.4812825],
                [105.3828587, 19.4817746],
                [105.3831195, 19.4823405],
                [105.3832245, 19.4828575],
                [105.3832521, 19.483547],
                [105.3830976, 19.4840645],
                [105.3824376, 19.4847924],
                [105.3809606, 19.4856575],
                [105.3784718, 19.486697],
                [105.3777595, 19.4872034],
                [105.3761801, 19.4887828],
                [105.3761801, 19.4887829],
                [105.373825, 19.4914967],
                [105.3730738, 19.4920894],
                [105.3708083, 19.4943228],
                [105.3679467, 19.4969761],
                [105.3677404, 19.4975184],
                [105.3670755, 19.4985822],
                [105.3640664, 19.5009237],
                [105.3638385, 19.5022403],
                [105.3606182, 19.5042009],
                [105.3594793, 19.5052632],
                [105.3584318, 19.5070831],
                [105.3606315, 19.5088886],
                [105.3627681, 19.5113396],
                [105.3627699, 19.5142672],
                [105.3635421, 19.5172198],
                [105.3629946, 19.5196842],
                [105.3621027, 19.5215418],
                [105.3626857, 19.5233903],
                [105.3627714, 19.5246306],
                [105.3632616, 19.5256274],
                [105.3627185, 19.5264904],
                [105.3614903, 19.5285493],
                [105.3611021, 19.5290919],
                [105.3607655, 19.5294619],
                [105.3604029, 19.5298567],
                [105.3600663, 19.5302021],
                [105.3589777, 19.5308939],
                [105.3571507, 19.5322151],
                [105.3568661, 19.5326343],
                [105.3564781, 19.5332754],
                [105.3553921, 19.5351739],
                [105.3543439, 19.5365674],
                [105.3540074, 19.5370113],
                [105.3533073, 19.5373821],
                [105.3527627, 19.5375802],
                [105.3521919, 19.5377291],
                [105.3515691, 19.5377795],
                [105.3492588, 19.5377348],
                [105.3450802, 19.5380137],
                [105.344483, 19.5379409],
                [105.3439896, 19.5378434],
                [105.3436778, 19.5376716],
                [105.3432615, 19.5372537],
                [105.3412836, 19.5348193],
                [105.3411533, 19.534524],
                [105.3410487, 19.5341795],
                [105.3409655, 19.531643],
                [105.3407953, 19.5309168],
                [105.3405349, 19.5305479],
                [105.3401971, 19.5303515],
                [105.3395999, 19.5302787],
                [105.3389514, 19.5304523],
                [105.3376544, 19.5308242],
                [105.3368862, 19.5315865],
                [105.3361337, 19.5317357],
                [105.3360977, 19.5320152],
                [105.3342015, 19.5313475],
                [105.3337898, 19.5301074],
                [105.3336872, 19.5293739],
                [105.3336666, 19.5282558],
                [105.3333426, 19.527051],
                [105.3331669, 19.5266176],
                [105.3329628, 19.5262092],
                [105.3326723, 19.5254791],
                [105.332495, 19.52403],
                [105.3321997, 19.5232448],
                [105.331556, 19.5227046],
                [105.330224, 19.5221482],
                [105.3287355, 19.5213477],
                [105.3274856, 19.5205641],
                [105.3264649, 19.5196578],
                [105.3260045, 19.5189253],
                [105.325773, 19.5179303],
                [105.3254944, 19.5163068],
                [105.3253364, 19.5153292],
                [105.3252122, 19.5129021],
                [105.3252292, 19.5121686],
                [105.3251, 19.511837],
                [105.3247693, 19.5116805],
                [105.3241086, 19.5117166],
                [105.3235206, 19.5114557],
                [105.3233637, 19.5110369],
                [105.3233999, 19.5107399],
                [105.323711, 19.5103028],
                [105.3238018, 19.5097438],
                [105.3235899, 19.5093076],
                [105.3230935, 19.5089767],
                [105.3223406, 19.5087511],
                [105.321551, 19.5086477],
                [105.3207245, 19.5083871],
                [105.3201815, 19.5076547],
                [105.3199602, 19.5070963],
                [105.3197395, 19.5068522],
                [105.3192988, 19.5067832],
                [105.318436, 19.5067672],
                [105.3168841, 19.5063508],
                [105.3159837, 19.5058285],
                [105.3150357, 19.504468],
                [105.3144648, 19.5034562],
                [105.3139022, 19.5020601],
                [105.3136989, 19.5013794],
                [105.3134588, 19.5006289],
                [105.3131094, 19.5002803],
                [105.3124665, 19.4999845],
                [105.3113188, 19.4997071],
                [105.3103269, 19.4993071],
                [105.3096103, 19.4989066],
                [105.3092607, 19.4984707],
                [105.3091218, 19.4978248],
                [105.3092489, 19.4971086],
                [105.30967, 19.4964967],
                [105.3102655, 19.4959893],
                [105.310301, 19.495343],
                [105.3101612, 19.4942605],
                [105.309593, 19.4924533],
                [105.308883, 19.4906733],
                [105.3087529, 19.4898353],
                [105.3083294, 19.4891025],
                [105.3075758, 19.4884927],
                [105.3070614, 19.4882141],
                [105.3059324, 19.4881461],
                [105.3045377, 19.4882009],
                [105.3040421, 19.4881842],
                [105.3033446, 19.488133],
                [105.3028854, 19.4879592],
                [105.3025178, 19.4877327],
                [105.3021037, 19.4871047],
                [105.3017535, 19.4862671],
                [105.301357, 19.4852375],
                [105.3008787, 19.4847145],
                [105.3005847, 19.4845054],
                [105.3000523, 19.4844014],
                [105.2995936, 19.4844197],
                [105.2991532, 19.4845601],
                [105.2989514, 19.4845954],
                [105.2986577, 19.4845261],
                [105.2986022, 19.484299],
                [105.2985649, 19.4839324],
                [105.2987376, 19.4830939],
                [105.2988461, 19.4821159],
                [105.2990185, 19.4811027],
                [105.298999, 19.4804742],
                [105.2986863, 19.4800555],
                [105.2983736, 19.4796893],
                [105.297786, 19.4795157],
                [105.2970702, 19.4795343],
                [105.2965835, 19.479343],
                [105.2963262, 19.479099],
                [105.2963623, 19.4787671],
                [105.2966, 19.4782953],
                [105.2962917, 19.4778779],
                [105.2962534, 19.4775428],
                [105.2953968, 19.4775401],
                [105.2953971, 19.4772833],
                [105.2959989, 19.4765136],
                [105.2956854, 19.4759781],
                [105.2952953, 19.4757998],
                [105.2943825, 19.4760686],
                [105.2934993, 19.4758945],
                [105.2934126, 19.4768573],
                [105.2936186, 19.4781366],
                [105.2938522, 19.4794045],
                [105.293418, 19.4805363],
                [105.2928268, 19.4810174],
                [105.2921662, 19.4808419],
                [105.2913857, 19.4812208],
                [105.2908435, 19.4823842],
                [105.2904716, 19.4836097],
                [105.2911035, 19.4851343],
                [105.2914578, 19.4865807],
                [105.2911389, 19.4878561],
                [105.2904627, 19.4895336],
                [105.2896109, 19.490443],
                [105.2883461, 19.4913182],
                [105.2870436, 19.4917045],
                [105.285934, 19.4921777],
                [105.2844112, 19.4925643],
                [105.2827596, 19.4926891],
                [105.2813003, 19.4926389],
                [105.2795739, 19.4919956],
                [105.278656, 19.4918572],
                [105.2781977, 19.4922072],
                [105.2778778, 19.4929761],
                [105.2778608, 19.4937619],
                [105.2779269, 19.4948621],
                [105.278186, 19.4960315],
                [105.2785461, 19.4973582],
                [105.2790806, 19.4987021],
                [105.2790735, 19.4998371],
                [105.2795711, 19.5010413],
                [105.2799305, 19.5018615],
                [105.2800791, 19.502909],
                [105.2799615, 19.5039045],
                [105.2794122, 19.5046563],
                [105.2784956, 19.5053737],
                [105.2775053, 19.5058816],
                [105.2763215, 19.5059882],
                [105.2748349, 19.5061652],
                [105.2735326, 19.5067259],
                [105.2728815, 19.5070587],
                [105.2724608, 19.5078976],
                [105.2722601, 19.5086661],
                [105.2722521, 19.5093124],
                [105.2726396, 19.5105166],
                [105.2731102, 19.5120178],
                [105.2734427, 19.5132745],
                [105.2738945, 19.5144439],
                [105.2743367, 19.5154735],
                [105.2744854, 19.5165037],
                [105.2743949, 19.5172721],
                [105.2743018, 19.5175065],
                [105.2741938, 19.5177789],
                [105.2739005, 19.518076],
                [105.2739286, 19.5216641],
                [105.2721284, 19.5239212],
                [105.2705172, 19.5264999],
                [105.2697124, 19.5283343],
                [105.2696066, 19.529474],
                [105.2698782, 19.5308608],
                [105.2711503, 19.5347977],
                [105.2727723, 19.5385606],
                [105.2738323, 19.5443805],
                [105.2741061, 19.5470555],
                [105.2739482, 19.5491862],
                [105.2738421, 19.5502268],
                [105.273359, 19.5511689],
                [105.2719611, 19.5526573],
                [105.2712626, 19.5536492],
                [105.2711024, 19.5544917],
                [105.27097, 19.5558049],
                [105.2713491, 19.5570924],
                [105.2720518, 19.5585282],
                [105.2732391, 19.5599137],
                [105.2743184, 19.5611506],
                [105.2756124, 19.5618422],
                [105.2768521, 19.5623358],
                [105.278522, 19.5624323],
                [105.2797613, 19.5626781],
                [105.280947, 19.5630726],
                [105.2818635, 19.5635666],
                [105.2824034, 19.5642594],
                [105.2827284, 19.5652993],
                [105.2830547, 19.567132],
                [105.2831642, 19.5680732],
                [105.2831386, 19.5688907],
                [105.2829282, 19.571715],
                [105.2827688, 19.573053],
                [105.2812123, 19.5762758],
                [105.2805683, 19.5776641],
                [105.2802473, 19.5789527],
                [105.2802531, 19.5822722],
                [105.2802017, 19.5837586],
                [105.2799345, 19.5849976],
                [105.2791291, 19.5865347],
                [105.2780538, 19.5878245],
                [105.2774089, 19.5887173],
                [105.2773555, 19.5889651],
                [105.2776796, 19.5895096],
                [105.2783808, 19.590004],
                [105.2793516, 19.590597],
                [105.2804307, 19.5915862],
                [105.2813487, 19.5928234],
                [105.2819975, 19.5941105],
                [105.2827842, 19.5972554],
                [105.2840316, 19.6019601],
                [105.2840341, 19.6033969],
                [105.2836051, 19.6045866],
                [105.2827999, 19.6062229],
                [105.2813478, 19.6077609],
                [105.2797867, 19.6086056],
                [105.2782786, 19.6089052],
                [105.2769316, 19.6088578],
                [105.2754225, 19.6085629],
                [105.2734817, 19.6079217],
                [105.2711631, 19.6068353],
                [105.270732, 19.6067863],
                [105.2705706, 19.6069848],
                [105.2705711, 19.6072821],
                [105.2708143, 19.6077028],
                [105.272056, 19.6091872],
                [105.2727058, 19.6110689],
                [105.2727078, 19.612258],
                [105.2721718, 19.6139433],
                [105.2709887, 19.6153323],
                [105.2695633, 19.616796],
                [105.2689186, 19.617887],
                [105.2685976, 19.6192747],
                [105.2684926, 19.6209098],
                [105.2684952, 19.6224952],
                [105.268605, 19.6237337],
                [105.2685383, 19.6242513],
                [105.2684454, 19.6249725],
                [105.2679349, 19.6258403],
                [105.2655148, 19.6287175],
                [105.2642246, 19.630602],
                [105.2638498, 19.6320393],
                [105.2631282, 19.6356323],
                [105.2630236, 19.6376143],
                [105.2626485, 19.638903],
                [105.2618957, 19.6399941],
                [105.259906, 19.6425732],
                [105.2549852, 19.6489962],
                [105.2508453, 19.6553932],
                [105.2491775, 19.6572782],
                [105.2477775, 19.6580728],
                [105.2470231, 19.6582225],
                [105.2459451, 19.6581745],
                [105.244327, 19.6573839],
                [105.242331, 19.656148],
                [105.241063, 19.6551837],
                [105.2398757, 19.6540953],
                [105.239443, 19.6530058],
                [105.2393317, 19.6506279],
                [105.2393832, 19.6490424],
                [105.2390043, 19.6479034],
                [105.2374385, 19.6459732],
                [105.2367372, 19.6454788],
                [105.2363598, 19.6453801],
                [105.2356593, 19.6454306],
                [105.2343398, 19.6460517],
                [105.2284146, 19.6480411],
                [105.2250741, 19.6487388],
                [105.2247511, 19.6489374],
                [105.2244303, 19.6493153],
                [105.2228141, 19.6512189],
                [105.218348, 19.6564265],
                [105.212078, 19.6631967],
                [105.2083361, 19.6662481],
                [105.2052665, 19.6683325],
                [105.2004188, 19.6709142],
                [105.1926895, 19.6755055],
                [105.1885425, 19.678433],
                [105.1841263, 19.6817571],
                [105.1832107, 19.6824516],
                [105.1818632, 19.6826016],
                [105.181378, 19.682503],
                [105.1813241, 19.6825031],
                [105.1808386, 19.6822063],
                [105.1784652, 19.6806728],
                [105.1770623, 19.6794356],
                [105.1761455, 19.6790401],
                [105.175337, 19.6790905],
                [105.1739362, 19.6797359],
                [105.1722542, 19.6805907],
                [105.1712698, 19.6814973],
                [105.1681462, 19.684126],
                [105.1666114, 19.6856386],
                [105.1656965, 19.6870266],
                [105.1653206, 19.6884142],
                [105.1650523, 19.6896531],
                [105.1648921, 19.6911891],
                [105.1651646, 19.6940128],
                [105.1657342, 19.6975547],
                [105.1656814, 19.6985952],
                [105.164928, 19.6998345],
                [105.1643356, 19.7004296],
                [105.1625574, 19.7013229],
                [105.1602404, 19.7025141],
                [105.1574937, 19.7052415],
                [105.1556359, 19.7075469],
                [105.1548833, 19.7097275],
                [105.1547766, 19.7110157],
                [105.1547774, 19.7118083],
                [105.1551019, 19.7128486],
                [105.1559117, 19.7139378],
                [105.1579351, 19.7156453],
                [105.1601469, 19.7168324],
                [105.1617649, 19.7173759],
                [105.1627899, 19.7179696],
                [105.1632759, 19.7187618],
                [105.1638708, 19.7205448],
                [105.1639259, 19.7216347],
                [105.1638851, 19.722593],
                [105.1638732, 19.7228734],
                [105.1634969, 19.7239636],
                [105.1630665, 19.7249054],
                [105.1621505, 19.7255007],
                [105.1586734, 19.7261727],
                [105.1560319, 19.72672],
                [105.1546845, 19.7272662],
                [105.1531765, 19.7291006],
                [105.1498639, 19.732943],
                [105.1485667, 19.7340574],
                [105.145008, 19.7344539],
                [105.141673, 19.7377096],
                [105.1389245, 19.737634],
                [105.1368631, 19.7393444],
                [105.1344987, 19.7416343],
                [105.1337573, 19.7447867],
                [105.1352079, 19.7473366],
                [105.1363098, 19.7492027],
                [105.1375043, 19.750764],
                [105.1375323, 19.7519777],
                [105.1367814, 19.756685],
                [105.1361372, 19.7603021],
                [105.1361109, 19.760971],
                [105.1372995, 19.7635464],
                [105.1383794, 19.7649823],
                [105.1387039, 19.766072],
                [105.1388127, 19.7671123],
                [105.1387065, 19.7690942],
                [105.1383044, 19.771745],
                [105.1364736, 19.7753136],
                [105.1351271, 19.7775441],
                [105.1325945, 19.7804195],
                [105.1322176, 19.7813116],
                [105.1321105, 19.7821539],
                [105.1326254, 19.7852004],
                [105.1326825, 19.7890153],
                [105.1320656, 19.7932021],
                [105.13072, 19.7967703],
                [105.1296424, 19.7983069],
                [105.1290502, 19.7997936],
                [105.1289432, 19.8008837],
                [105.1291333, 19.8024937],
                [105.1304843, 19.8053662],
                [105.132051, 19.8081395],
                [105.1326999, 19.8100217],
                [105.132701, 19.8114089],
                [105.132406, 19.8133661],
                [105.1316527, 19.8157943],
                [105.1314921, 19.8172806],
                [105.13171, 19.8199063],
                [105.1324418, 19.8241417],
                [105.1330917, 19.8269652],
                [105.1338482, 19.8284014],
                [105.1343884, 19.8292432],
                [105.1354142, 19.8300351],
                [105.1398395, 19.8312703],
                [105.1417281, 19.8315661],
                [105.1426456, 19.8318626],
                [105.1439414, 19.8327038],
                [105.1451569, 19.8345112],
                [105.1463469, 19.837681],
                [105.1473745, 19.8403554],
                [105.1482688, 19.8446401],
                [105.1491361, 19.8488506],
                [105.1512454, 19.8539517],
                [105.1519478, 19.854942],
                [105.1527039, 19.855635],
                [105.1537294, 19.8558818],
                [105.1550783, 19.8556329],
                [105.1576139, 19.8549867],
                [105.1589088, 19.8547378],
                [105.1597722, 19.854737],
                [105.1613372, 19.8548843],
                [105.1646835, 19.8555253],
                [105.1676247, 19.855795],
                [105.1712945, 19.8559897],
                [105.1730481, 19.8559137],
                [105.177957, 19.8544226],
                [105.1810852, 19.8528836],
                [105.1817866, 19.8528333],
                [105.1823802, 19.8528822],
                [105.1827581, 19.8530305],
                [105.1828126, 19.8534763],
                [105.1823286, 19.8549631],
                [105.1820602, 19.8562019],
                [105.182385, 19.8570438],
                [105.1836272, 19.8580334],
                [105.1861659, 19.8600125],
                [105.1879123, 19.8623936],
                [105.1877283, 19.864329],
                [105.1875474, 19.8647542],
                [105.1875519, 19.8650151],
                [105.1875845, 19.865536],
                [105.1876125, 19.8657956],
                [105.187692, 19.8663121],
                [105.1878004, 19.8668156],
                [105.1878647, 19.867061],
                [105.1880146, 19.8675465],
                [105.1870651, 19.8688222],
                [105.1868412, 19.8690341],
                [105.1866101, 19.869239],
                [105.186372, 19.8694367],
                [105.1861272, 19.8696269],
                [105.1858758, 19.8698096],
                [105.1853768, 19.8701378],
                [105.1847881, 19.8704771],
                [105.1847017, 19.8705379],
                [105.1846215, 19.8706057],
                [105.1845481, 19.8706799],
                [105.1844821, 19.8707601],
                [105.1843762, 19.8709314],
                [105.1843049, 19.8711132],
                [105.1842677, 19.8713038],
                [105.1842623, 19.8714006],
                [105.1842668, 19.8717807],
                [105.1842403, 19.8720628],
                [105.1841822, 19.8723582],
                [105.1840879, 19.8726634],
                [105.1838376, 19.8733258],
                [105.1836148, 19.8738279],
                [105.1834916, 19.874074],
                [105.183222, 19.8745555],
                [105.183112, 19.8747338],
                [105.1828791, 19.8750834],
                [105.1829601, 19.8758377],
                [105.1830713, 19.876604],
                [105.1831391, 19.8769933],
                [105.1831677, 19.8772827],
                [105.1831594, 19.8775732],
                [105.1831145, 19.8778608],
                [105.1830336, 19.8781413],
                [105.1829787, 19.8782806],
                [105.1829149, 19.8784166],
                [105.1828424, 19.8785486],
                [105.1827615, 19.8786762],
                [105.1826725, 19.878799],
                [105.1825757, 19.8789164],
                [105.1824715, 19.8790281],
                [105.1823603, 19.8791337],
                [105.1821203, 19.8793269],
                [105.1819928, 19.8794146],
                [105.1818601, 19.8794953],
                [105.1815813, 19.8796348],
                [105.1812836, 19.879745],
                [105.1809704, 19.8798231],
                [105.1790775, 19.8801397],
                [105.1778912, 19.8823088],
                [105.1777507, 19.8826748],
                [105.1776443, 19.883051],
                [105.177573, 19.8834344],
                [105.1775372, 19.8838222],
                [105.1775327, 19.8840085],
                [105.1775485, 19.8843808],
                [105.1775686, 19.8845662],
                [105.1776334, 19.8849338],
                [105.1776779, 19.8851155],
                [105.1779878, 19.8862234],
                [105.1776343, 19.8873176],
                [105.1774465, 19.8878615],
                [105.177049, 19.8889423],
                [105.1766159, 19.8900298],
                [105.1761532, 19.8911064],
                [105.1761828, 19.8919746],
                [105.176239, 19.8928417],
                [105.1762837, 19.8933436],
                [105.1763999, 19.8943449],
                [105.1772153, 19.8953436],
                [105.1724839, 19.8977749],
                [105.1722897, 19.8979159],
                [105.1719169, 19.898216],
                [105.1715665, 19.898539],
                [105.17124, 19.8988835],
                [105.170939, 19.8992478],
                [105.1706558, 19.8996437],
                [105.1704027, 19.9000572],
                [105.1701809, 19.9004863],
                [105.1699913, 19.9009288],
                [105.169909, 19.9011545],
                [105.1698023, 19.9015364],
                [105.1697218, 19.9019249],
                [105.1696681, 19.9023185],
                [105.1696353, 19.9027926],
                [105.1696272, 19.9030691],
                [105.1696272, 19.9033457],
                [105.1696515, 19.9038984],
                [105.169158, 19.9090836],
                [105.1693404, 19.9109094],
                [105.1693142, 19.9114244],
                [105.1692784, 19.911939],
                [105.1691837, 19.9129176],
                [105.1690614, 19.9138448],
                [105.1690093, 19.9140355],
                [105.1689299, 19.914422],
                [105.168866, 19.9149811],
                [105.1688173, 19.9151436],
                [105.1687406, 19.9152945],
                [105.1686376, 19.9154311],
                [105.167757, 19.9162245],
                [105.1676491, 19.9163298],
                [105.1674507, 19.916555],
                [105.1671463, 19.916982],
                [105.1670245, 19.9171339],
                [105.1667598, 19.9174217],
                [105.1664688, 19.9176861],
                [105.1661539, 19.9179251],
                [105.1641476, 19.9189994],
                [105.1629299, 19.9191204],
                [105.1622072, 19.9197162],
                [105.1618391, 19.9200068],
                [105.1610899, 19.9205729],
                [105.1606357, 19.9209004],
                [105.1601757, 19.9212206],
                [105.1597099, 19.9215334],
                [105.1592385, 19.9218387],
                [105.1587617, 19.9221364],
                [105.1545024, 19.9233821],
                [105.1542721, 19.9232105],
                [105.1541655, 19.9231154],
                [105.1539713, 19.922908],
                [105.1538827, 19.9227943],
                [105.1537285, 19.9225526],
                [105.1536633, 19.9224256],
                [105.1535582, 19.9221616],
                [105.1534472, 19.9219327],
                [105.1533035, 19.9217205],
                [105.1532203, 19.921622],
                [105.1530584, 19.9214638],
                [105.1528773, 19.9213252],
                [105.1527804, 19.9212639],
                [105.1497553, 19.9221176],
                [105.1494006, 19.921673],
                [105.1492155, 19.9214564],
                [105.14893, 19.9211406],
                [105.1487195, 19.9209525],
                [105.1486052, 19.9208683],
                [105.1483606, 19.9207211],
                [105.1482314, 19.9206587],
                [105.1480921, 19.9206019],
                [105.1479492, 19.9205539],
                [105.1478032, 19.9205149],
                [105.1476547, 19.9204851],
                [105.1475045, 19.9204646],
                [105.1473532, 19.9204535],
                [105.1472014, 19.9204519],
                [105.1460159, 19.9204519],
                [105.1455678, 19.920507],
                [105.1451254, 19.9205931],
                [105.1449043, 19.9206484],
                [105.1444695, 19.9207821],
                [105.1442563, 19.9208604],
                [105.1427355, 19.9215328],
                [105.1425632, 19.9215977],
                [105.1422101, 19.9217055],
                [105.1418601, 19.9217809],
                [105.1415165, 19.9218264],
                [105.1413435, 19.9218388],
                [105.1400131, 19.9218867],
                [105.1398896, 19.9219088],
                [105.1396484, 19.9219747],
                [105.1395317, 19.9220184],
                [105.1393082, 19.9221263],
                [105.1392024, 19.92219],
                [105.1390088, 19.9223325],
                [105.1389213, 19.9224103],
                [105.1387622, 19.9225801],
                [105.1385646, 19.9228554],
                [105.1384953, 19.9229391],
                [105.1383367, 19.9230895],
                [105.1381642, 19.9232083],
                [105.1379851, 19.9232969],
                [105.1378909, 19.9233318],
                [105.1376957, 19.9233821],
                [105.1370573, 19.9233972],
                [105.1369457, 19.9233732],
                [105.1368368, 19.9233398],
                [105.1367316, 19.9232975],
                [105.1366308, 19.9232464],
                [105.1365352, 19.923187],
                [105.1364458, 19.9231199],
                [105.1363066, 19.922986],
                [105.1361912, 19.9228335],
                [105.1361433, 19.9227514],
                [105.1359375, 19.9223362],
                [105.1357458, 19.9220195],
                [105.1356402, 19.9218667],
                [105.1354038, 19.9215652],
                [105.1352726, 19.9214175],
                [105.1349913, 19.921138],
                [105.1348418, 19.9210067],
                [105.1343429, 19.9212841],
                [105.1344609, 19.9217001],
                [105.1332968, 19.9221276],
                [105.1321435, 19.9225802],
                [105.131483, 19.9228528],
                [105.1308265, 19.9231337],
                [105.1293958, 19.9237774],
                [105.1291495, 19.9239156],
                [105.1289248, 19.9240831],
                [105.1287411, 19.92426],
                [105.1285058, 19.9245456],
                [105.1284233, 19.9246293],
                [105.1282395, 19.9247784],
                [105.1280343, 19.9249002],
                [105.1278355, 19.9249841],
                [105.127732, 19.9250165],
                [105.1275194, 19.9250615],
                [105.1261997, 19.9251826],
                [105.1259678, 19.9252471],
                [105.125738, 19.9253178],
                [105.1252851, 19.9254772],
                [105.1248118, 19.9256741],
                [105.1243223, 19.9259131],
                [105.1238501, 19.9261812],
                [105.1236925, 19.926431],
                [105.1235101, 19.9266654],
                [105.1233052, 19.9268821],
                [105.1230796, 19.927079],
                [105.1229596, 19.9271696],
                [105.1219028, 19.9277143],
                [105.1217818, 19.9277955],
                [105.1215547, 19.9279755],
                [105.1214491, 19.9280739],
                [105.1212556, 19.9282859],
                [105.1210874, 19.9285162],
                [105.1210084, 19.9286462],
                [105.1208748, 19.928918],
                [105.1207753, 19.9292025],
                [105.1207387, 19.9293483],
                [105.1206933, 19.9296039],
                [105.1206586, 19.9297293],
                [105.1205661, 19.9299734],
                [105.1204437, 19.9302056],
                [105.120288, 19.9304293],
                [105.1201045, 19.9306335],
                [105.1198961, 19.9308154],
                [105.1196659, 19.9309722],
                [105.1196621, 19.9310574],
                [105.119631, 19.9312254],
                [105.1195693, 19.9313857],
                [105.1195267, 19.9314628],
                [105.1194769, 19.931536],
                [105.1193576, 19.9316685],
                [105.1191378, 19.9318387],
                [105.1189994, 19.9319744],
                [105.1189394, 19.9320498],
                [105.1188397, 19.9322128],
                [105.1188016, 19.9322973],
                [105.118747, 19.9324735],
                [105.1187309, 19.932564],
                [105.1187217, 19.9327473],
                [105.1184291, 19.932873],
                [105.1178351, 19.9331054],
                [105.1172304, 19.9333122],
                [105.1168172, 19.9334308],
                [105.1166068, 19.9334771],
                [105.1161806, 19.9335435],
                [105.1157498, 19.9335744],
                [105.115319, 19.9335696],
                [105.1148904, 19.9335293],
                [105.1146778, 19.9334959],
                [105.1142585, 19.9334029],
                [105.1140301, 19.933231],
                [105.1137958, 19.9330662],
                [105.1135558, 19.9329087],
                [105.1131494, 19.9326662],
                [105.1127297, 19.9324448],
                [105.1126009, 19.9323988],
                [105.1124644, 19.9323817],
                [105.1123273, 19.9323943],
                [105.112256, 19.9324132],
                [105.1121235, 19.932475],
                [105.1117651, 19.9328708],
                [105.1113832, 19.9332466],
                [105.1109729, 19.9336061],
                [105.110541, 19.9339425],
                [105.1102971, 19.9342088],
                [105.1100319, 19.9344565],
                [105.1097605, 19.9346742],
                [105.1094867, 19.9348635],
                [105.108304, 19.9355815],
                [105.1080263, 19.9360309],
                [105.1078854, 19.9361942],
                [105.1077147, 19.9363373],
                [105.1076183, 19.9363999],
                [105.1075168, 19.9364547],
                [105.1074107, 19.9365014],
                [105.1073009, 19.9365397],
                [105.1062387, 19.9370339],
                [105.1060994, 19.9370847],
                [105.1058149, 19.9371705],
                [105.105491, 19.9372405],
                [105.1051285, 19.9372859],
                [105.1049459, 19.9372961],
                [105.1047978, 19.9372843],
                [105.1045051, 19.9372361],
                [105.1043615, 19.9371998],
                [105.1040825, 19.9371036],
                [105.1039481, 19.937044],
                [105.1036956, 19.9369052],
                [105.1035763, 19.9368257],
                [105.1033535, 19.9366477],
                [105.1031634, 19.936471],
                [105.1029705, 19.936334],
                [105.1027572, 19.936227],
                [105.1025564, 19.9361597],
                [105.1024528, 19.936136],
                [105.1022415, 19.9361091],
                [105.1021349, 19.936106],
                [105.1017031, 19.9360702],
                [105.1012696, 19.9360634],
                [105.101053, 19.936071],
                [105.1006218, 19.9361078],
                [105.1004079, 19.936137],
                [105.099984, 19.9362168],
                [105.0994052, 19.9363496],
                [105.0992245, 19.9364042],
                [105.099047, 19.9364676],
                [105.0988734, 19.9365397],
                [105.0985541, 19.9367004],
                [105.0984013, 19.9367918],
                [105.0981805, 19.936938],
                [105.0980643, 19.9370027],
                [105.0978219, 19.9371146],
                [105.0976953, 19.9371618],
                [105.0974349, 19.937237],
                [105.096949, 19.9373284],
                [105.0967323, 19.9373783],
                [105.0963057, 19.9375009],
                [105.0960963, 19.9375733],
                [105.0956869, 19.9377399],
                [105.0952666, 19.9379484],
                [105.0950634, 19.9380642],
                [105.0946724, 19.9383182],
                [105.0944853, 19.938456],
                [105.0942734, 19.9385968],
                [105.0940674, 19.9387451],
                [105.0938676, 19.9389006],
                [105.0936742, 19.9390632],
                [105.0933403, 19.9393757],
                [105.0930604, 19.9396745],
                [105.0928008, 19.939989],
                [105.092398, 19.9398081],
                [105.0920069, 19.9396058],
                [105.0915792, 19.9393513],
                [105.0913722, 19.9392144],
                [105.0911701, 19.9390712],
                [105.0910416, 19.9388571],
                [105.0909659, 19.9387567],
                [105.0907936, 19.9385717],
                [105.0905961, 19.9384106],
                [105.0902535, 19.9382024],
                [105.0898953, 19.9380188],
                [105.0897109, 19.9379366],
                [105.0892068, 19.9377303],
                [105.0887132, 19.9375029],
                [105.0884005, 19.9373449],
                [105.0880928, 19.9371785],
                [105.0876958, 19.9369451],
                [105.0875963, 19.9368942],
                [105.0873855, 19.9368164],
                [105.0872757, 19.93679],
                [105.0870511, 19.9367633],
                [105.0868249, 19.9367717],
                [105.086611, 19.9368128],
                [105.0865073, 19.9368453],
                [105.0863099, 19.936933],
                [105.085979, 19.9369193],
                [105.0856482, 19.9369346],
                [105.0853176, 19.9369795],
                [105.0849903, 19.9370541],
                [105.0845182, 19.9375915],
                [105.084459, 19.9376502],
                [105.0843253, 19.9377521],
                [105.0842519, 19.9377945],
                [105.0840948, 19.9378604],
                [105.083928, 19.9378998],
                [105.0837564, 19.9379113],
                [105.0818145, 19.9364287],
                [105.0815718, 19.936435],
                [105.0813317, 19.9364691],
                [105.0811186, 19.9365237],
                [105.0809133, 19.9366002],
                [105.0808547, 19.9366099],
                [105.0807359, 19.9366099],
                [105.0806773, 19.9366002],
                [105.0805506, 19.9365545],
                [105.0804412, 19.9364792],
                [105.0799576, 19.9358409],
                [105.0798067, 19.93569],
                [105.0796318, 19.9355641],
                [105.0795367, 19.9355116],
                [105.0793346, 19.9354293],
                [105.0792289, 19.9354],
                [105.0790072, 19.9353657],
                [105.0788948, 19.9353616],
                [105.0783869, 19.9353952],
                [105.0781025, 19.9354021],
                [105.077818, 19.9354003],
                [105.0772647, 19.9353721],
                [105.076729, 19.9353134],
                [105.0764628, 19.9352725],
                [105.0759351, 19.935168],
                [105.0751242, 19.9350434],
                [105.0743083, 19.9349526],
                [105.073899, 19.9349199],
                [105.0731592, 19.9348824],
                [105.0724993, 19.9348723],
                [105.0721693, 19.9348755],
                [105.071955, 19.9348684],
                [105.0715285, 19.9348285],
                [105.0711071, 19.9347545],
                [105.0708964, 19.9347041],
                [105.070483, 19.9345779],
                [105.070281, 19.9345023],
                [105.0682211, 19.9336652],
                [105.0675159, 19.9335971],
                [105.067165, 19.9335498],
                [105.0665498, 19.933445],
                [105.0660217, 19.9333323],
                [105.0646269, 19.9328583],
                [105.0642877, 19.9327589],
                [105.0641145, 19.9327211],
                [105.0637634, 19.9326699],
                [105.0635862, 19.9326566],
                [105.0632661, 19.9326534],
                [105.0631063, 19.9326619],
                [105.0627886, 19.9326988],
                [105.062281, 19.9327997],
                [105.0621033, 19.9328229],
                [105.0617452, 19.9328431],
                [105.0613868, 19.932828],
                [105.0610431, 19.93278],
                [105.0607063, 19.9326995],
                [105.0603798, 19.9325873],
                [105.0600672, 19.9324448],
                [105.0598935, 19.9324938],
                [105.0597173, 19.9325338],
                [105.0595389, 19.9325646],
                [105.0593591, 19.9325861],
                [105.0591783, 19.9325983],
                [105.058997, 19.9326011],
                [105.0586693, 19.9325823],
                [105.0583452, 19.932533],
                [105.0575566, 19.9322733],
                [105.0573056, 19.9322056],
                [105.0570483, 19.9321635],
                [105.0569183, 19.9321523],
                [105.0566249, 19.9321508],
                [105.0563335, 19.9321825],
                [105.0554634, 19.9324211],
                [105.0539109, 19.9328657],
                [105.0525463, 19.9332769],
                [105.0522753, 19.9333252],
                [105.0520027, 19.9333646],
                [105.0517287, 19.9333949],
                [105.0514538, 19.9334161],
                [105.0509833, 19.9334312],
                [105.0505937, 19.9334164],
                [105.0502064, 19.9333739],
                [105.0500143, 19.9333424],
                [105.0496319, 19.9332584],
                [105.0492575, 19.9331472],
                [105.0488931, 19.9330096],
                [105.0484756, 19.9326585],
                [105.0482596, 19.9324908],
                [105.0478137, 19.9321721],
                [105.0475842, 19.9320211],
                [105.0470881, 19.9317228],
                [105.0468337, 19.9315835],
                [105.0463128, 19.9313252],
                [105.0458568, 19.9323086],
                [105.0458259, 19.932388],
                [105.0458028, 19.9324698],
                [105.0457806, 19.9326375],
                [105.0457921, 19.9328129],
                [105.0458118, 19.9329023],
                [105.0458407, 19.9329894],
                [105.0463422, 19.9337281],
                [105.0468171, 19.9344821],
                [105.0471907, 19.9351005],
                [105.0476164, 19.9358942],
                [105.0466669, 19.9367263],
                [105.0465096, 19.9368861],
                [105.0462158, 19.9372224],
                [105.045951, 19.9375795],
                [105.045714, 19.93796],
                [105.0456065, 19.9381591],
                [105.0455077, 19.9383622],
                [105.0454176, 19.9385688],
                [105.0453365, 19.9387787],
                [105.0452688, 19.9390163],
                [105.045106, 19.9394836],
                [105.0450111, 19.9397128],
                [105.0447985, 19.9401537],
                [105.0446817, 19.9403652],
                [105.0444245, 19.9407757],
                [105.0443101, 19.9409822],
                [105.0442416, 19.9410794],
                [105.0440839, 19.9412589],
                [105.0439057, 19.9414125],
                [105.0438112, 19.9414787],
                [105.0436091, 19.9415926],
                [105.0432775, 19.9415581],
                [105.0431108, 19.9415516],
                [105.0429439, 19.9415523],
                [105.0426204, 19.9415741],
                [105.0423002, 19.9416229],
                [105.0397288, 19.9420827],
                [105.0396452, 19.9420911],
                [105.0394774, 19.9420843],
                [105.0393142, 19.9420466],
                [105.0391622, 19.9419794],
                [105.039026, 19.9418842],
                [105.0389115, 19.9417639],
                [105.0388251, 19.9416245],
                [105.0387704, 19.9414716],
                [105.0387865, 19.9410177],
                [105.038801, 19.9408798],
                [105.0387865, 19.9407419],
                [105.0387436, 19.9406093],
                [105.0387118, 19.9405463],
                [105.0386294, 19.9404308],
                [105.0385244, 19.9403328],
                [105.038179, 19.9400955],
                [105.0376171, 19.9396915],
                [105.0370846, 19.9392849],
                [105.0368231, 19.9390762],
                [105.0367306, 19.9390339],
                [105.0366799, 19.9390245],
                [105.0365771, 19.9390306],
                [105.0364833, 19.9390686],
                [105.0364433, 19.939098],
                [105.0363816, 19.9391735],
                [105.0363618, 19.9392174],
                [105.0363099, 19.9395962],
                [105.0362599, 19.9397469],
                [105.0362214, 19.9398229],
                [105.0361217, 19.9399622],
                [105.0360614, 19.9400243],
                [105.0359719, 19.9400956],
                [105.0358891, 19.9401737],
                [105.0358134, 19.940258],
                [105.0357456, 19.9403479],
                [105.0356406, 19.9405298],
                [105.0356022, 19.9406197],
                [105.0355469, 19.9408062],
                [105.0355303, 19.9409017],
                [105.0354928, 19.9415271],
                [105.0354967, 19.9417447],
                [105.0355078, 19.9418531],
                [105.0355478, 19.9420663],
                [105.0356108, 19.9422734],
                [105.0356682, 19.9424177],
                [105.0356941, 19.9425699],
                [105.0356948, 19.942647],
                [105.0356718, 19.9427996],
                [105.0356483, 19.9428735],
                [105.0355817, 19.9430079],
                [105.0355383, 19.94307],
                [105.0354338, 19.9431811],
                [105.0352544, 19.9431113],
                [105.0350885, 19.9430164],
                [105.035012, 19.9429603],
                [105.0348658, 19.9428233],
                [105.0347391, 19.9426557],
                [105.0346452, 19.94247],
                [105.0345861, 19.9424029],
                [105.0345071, 19.9423572],
                [105.0344122, 19.9423375],
                [105.0343611, 19.9423396],
                [105.0342643, 19.9423692],
                [105.0339693, 19.9426768],
                [105.0339056, 19.9428029],
                [105.0338042, 19.9430652],
                [105.0337732, 19.9432299],
                [105.0337388, 19.9435627],
                [105.0337413, 19.9438971],
                [105.0337708, 19.9441694],
                [105.0336674, 19.944251],
                [105.0334471, 19.9443974],
                [105.0332161, 19.9445181],
                [105.0329777, 19.944613],
                [105.0327301, 19.9446838],
                [105.0321454, 19.9445678],
                [105.0319432, 19.9446776],
                [105.031725, 19.9447556],
                [105.0314963, 19.9447998],
                [105.0312867, 19.9448096],
                [105.0310779, 19.944791],
                [105.030874, 19.9447443],
                [105.0302334, 19.9445214],
                [105.0301538, 19.9445021],
                [105.0299906, 19.9444849],
                [105.0299084, 19.9444871],
                [105.0298266, 19.9444967],
                [105.0296676, 19.9445372],
                [105.0294067, 19.9446412],
                [105.0290286, 19.9447695],
                [105.0288092, 19.9448315],
                [105.0283632, 19.9449308],
                [105.0281375, 19.9449678],
                [105.0276822, 19.9450166],
                [105.0272369, 19.9449511],
                [105.0257993, 19.946363],
                [105.0251233, 19.9468774],
                [105.0245869, 19.9477043],
                [105.0240777, 19.9478944],
                [105.0235833, 19.9481163],
                [105.0231162, 19.9483632],
                [105.0226772, 19.9486322],
                [105.0208479, 19.9501752],
                [105.0206493, 19.9503294],
                [105.0204574, 19.9504909],
                [105.0202725, 19.9506596],
                [105.0200951, 19.9508352],
                [105.0199252, 19.9510173],
                [105.0197703, 19.9511972],
                [105.0196229, 19.9513826],
                [105.0193512, 19.9517686],
                [105.0192357, 19.9519156],
                [105.0191124, 19.9520569],
                [105.0189816, 19.9521922],
                [105.0188438, 19.9523211],
                [105.0186991, 19.9524433],
                [105.0185481, 19.9525584],
                [105.018391, 19.9526661],
                [105.018085, 19.9528458],
                [105.0177624, 19.9529976],
                [105.0175958, 19.9530627],
                [105.0172538, 19.9531704],
                [105.0155071, 19.9535239],
                [105.0152824, 19.9535623],
                [105.0148292, 19.9536161],
                [105.0143731, 19.9536393],
                [105.0139057, 19.9536312],
                [105.0136726, 19.953615],
                [105.0134402, 19.9535908],
                [105.0126378, 19.9534853],
                [105.0114924, 19.953362],
                [105.0108566, 19.9533071],
                [105.01022, 19.9532617],
                [105.0095826, 19.9532259],
                [105.0089746, 19.9532173],
                [105.0083672, 19.9532444],
                [105.0078032, 19.9532928],
                [105.007541, 19.9532861],
                [105.0072813, 19.9532511],
                [105.0069996, 19.9531795],
                [105.0067299, 19.9530747],
                [105.0064766, 19.9529384],
                [105.0048888, 19.9517283],
                [105.003891, 19.952172],
                [105.0037412, 19.9522489],
                [105.0035961, 19.9523335],
                [105.0034564, 19.9524256],
                [105.0033224, 19.952525],
                [105.0031206, 19.9526983],
                [105.0027863, 19.9530382],
                [105.0026308, 19.953183],
                [105.0024696, 19.9533224],
                [105.0021315, 19.9535839],
                [105.0019708, 19.9536951],
                [105.001638, 19.9539019],
                [105.0014663, 19.9539973],
                [105.0012914, 19.9540672],
                [105.0011207, 19.9541457],
                [105.0009548, 19.9542326],
                [105.0007939, 19.9543276],
                [105.0006387, 19.9544306],
                [105.0004895, 19.9545412],
                [105.0003468, 19.9546591],
                [105.000211, 19.954784],
                [105.0000822, 19.9549156],
                [104.9999611, 19.9550535],
                [104.999848, 19.9551973],
                [104.9997432, 19.9553465],
                [104.999647, 19.9555008],
                [104.9995596, 19.9556597],
                [104.9994814, 19.9558227],
                [104.9994486, 19.9559588],
                [104.9994256, 19.9560967],
                [104.9994125, 19.9562358],
                [104.9994094, 19.9563754],
                [104.9994163, 19.9565148],
                [104.9994332, 19.9566535],
                [104.99946, 19.9567908],
                [104.9995396, 19.9570508],
                [104.9996545, 19.9572988],
                [104.9998028, 19.9575307],
                [104.999982, 19.9577424],
                [105.0002001, 19.9579609],
                [105.0003114, 19.9580874],
                [105.000416, 19.958219],
                [105.0006041, 19.9584957],
                [105.000687, 19.9586403],
                [105.0008297, 19.95894],
                [105.0008892, 19.9590944],
                [105.0009836, 19.9594105],
                [105.0010446, 19.9597336],
                [105.0010715, 19.9600608],
                [105.0010643, 19.9603889],
                [105.0010384, 19.9606487],
                [105.0009962, 19.9608381],
                [105.0009237, 19.9610191],
                [105.0008225, 19.9611876],
                [105.0007045, 19.96133],
                [105.0006376, 19.9613949],
                [105.0004899, 19.9615103],
                [104.9999711, 19.961807],
                [104.999464, 19.9621295],
                [104.9992149, 19.9623007],
                [104.9987304, 19.9626599],
                [104.997282, 19.9633759],
                [104.9951363, 19.9646465],
                [104.9941727, 19.9650342],
                [104.9921536, 19.9658465],
                [104.9908876, 19.9670061],
                [104.9885273, 19.9685389],
                [104.9881521, 19.968808],
                [104.9877971, 19.9691003],
                [104.9874639, 19.9694144],
                [104.987306, 19.9695792],
                [104.9870184, 19.9699108],
                [104.9868882, 19.9700767],
                [104.9866448, 19.9704194],
                [104.9865317, 19.970596],
                [104.9864909, 19.9706888],
                [104.9863892, 19.9708654],
                [104.9862625, 19.971027],
                [104.9861133, 19.9711707],
                [104.9859397, 19.9712967],
                [104.9857489, 19.9713982],
                [104.9855447, 19.9714733],
                [104.9818218, 19.9723001],
                [104.9812102, 19.9734295],
                [104.9802703, 19.9743416],
                [104.9793541, 19.9752747],
                [104.9785942, 19.9760842],
                [104.9778521, 19.9769082],
                [104.9770671, 19.9766852],
                [104.976671, 19.9765853],
                [104.9758726, 19.9764091],
                [104.9754371, 19.9763269],
                [104.9749997, 19.9762539],
                [104.9745607, 19.9761904],
                [104.9741202, 19.9761362],
                [104.973258, 19.9760482],
                [104.9726572, 19.9759385],
                [104.9723594, 19.9758722],
                [104.9717702, 19.9757167],
                [104.9712094, 19.9755377],
                [104.9706777, 19.9753384],
                [104.970416, 19.9752293],
                [104.969902, 19.9749924],
                [104.9697435, 19.9749436],
                [104.9695823, 19.9749037],
                [104.9694188, 19.9748729],
                [104.9692536, 19.9748511],
                [104.9690874, 19.9748385],
                [104.9689207, 19.9748352],
                [104.9686034, 19.9748545],
                [104.9684537, 19.9748755],
                [104.968159, 19.9749397],
                [104.9680148, 19.9749829],
                [104.9677348, 19.9750907],
                [104.9668175, 19.9751159],
                [104.9667319, 19.975124],
                [104.966566, 19.9751658],
                [104.9664876, 19.9751991],
                [104.9663492, 19.9752849],
                [104.9662328, 19.9753957],
                [104.9656963, 19.9763864],
                [104.9656565, 19.9765207],
                [104.9656493, 19.97666],
                [104.965658, 19.9767293],
                [104.965698, 19.9768592],
                [104.9657278, 19.9769185],
                [104.9658063, 19.9770267],
                [104.9662789, 19.9773618],
                [104.966362, 19.9774294],
                [104.9664383, 19.9775035],
                [104.9665074, 19.9775837],
                [104.9665688, 19.9776693],
                [104.9666218, 19.9777596],
                [104.9666661, 19.9778541],
                [104.9667014, 19.9779518],
                [104.9667274, 19.978052],
                [104.9667438, 19.9781541],
                [104.9667506, 19.9782571],
                [104.9667478, 19.9783602],
                [104.9667155, 19.9785542],
                [104.9666492, 19.9787404],
                [104.9665506, 19.9789135],
                [104.9664226, 19.9790686],
                [104.9662687, 19.9792014],
                [104.9660933, 19.979308],
                [104.9653029, 19.9796978],
                [104.9648057, 19.9799262],
                [104.9646243, 19.9800333],
                [104.9645407, 19.9800963],
                [104.9643921, 19.9802372],
                [104.9642693, 19.9803951],
                [104.9641728, 19.9805684],
                [104.9635613, 19.98319],
                [104.9635848, 19.9840337],
                [104.9636471, 19.9862753],
                [104.9629015, 19.9898698],
                [104.9622008, 19.9904634],
                [104.9615174, 19.9910746],
                [104.9611512, 19.9914162],
                [104.9604349, 19.9921145],
                [104.9600852, 19.992471],
                [104.9599804, 19.9927887],
                [104.9599059, 19.9929139],
                [104.9598568, 19.992969],
                [104.9597455, 19.993056],
                [104.9596859, 19.9930881],
                [104.9595573, 19.9931325],
                [104.9594215, 19.993149],
                [104.957411, 19.9930558],
                [104.9546751, 19.9908478],
                [104.95298, 19.991856],
                [104.9503729, 19.9918258],
                [104.9498664, 19.991374],
                [104.9493377, 19.9909454],
                [104.9487879, 19.9905407],
                [104.9482185, 19.9901609],
                [104.947598, 19.9897885],
                [104.947264, 19.9896044],
                [104.946925, 19.9894287],
                [104.9465812, 19.9892614],
                [104.9462328, 19.9891026],
                [104.9458802, 19.9889525],
                [104.9455234, 19.9888111],
                [104.9453419, 19.9888565],
                [104.944987, 19.9889724],
                [104.9448224, 19.9890392],
                [104.9445042, 19.9891942],
                [104.943839, 19.9892043],
                [104.943647, 19.9892331],
                [104.9434568, 19.9892712],
                [104.943269, 19.9893186],
                [104.9430841, 19.9893751],
                [104.9429026, 19.9894405],
                [104.9427249, 19.9895147],
                [104.9424041, 19.9896767],
                [104.9421213, 19.9898536],
                [104.9418569, 19.9900538],
                [104.9416924, 19.9900634],
                [104.9415294, 19.9900482],
                [104.941372, 19.990009],
                [104.9412963, 19.9899807],
                [104.9403736, 19.9894665],
                [104.9383727, 19.9887657],
                [104.9380061, 19.9886839],
                [104.9376335, 19.9886316],
                [104.9372575, 19.9886093],
                [104.9370691, 19.9886095],
                [104.9368608, 19.9886184],
                [104.9366533, 19.9886365],
                [104.9364468, 19.9886638],
                [104.936242, 19.9887002],
                [104.9360391, 19.9887456],
                [104.9351594, 19.9890228],
                [104.9343654, 19.9888414],
                [104.9318334, 19.9891035],
                [104.9293443, 19.9890329],
                [104.9271235, 19.9899403],
                [104.9270098, 19.9899933],
                [104.9269001, 19.9900531],
                [104.9266943, 19.9901924],
                [104.9265981, 19.9902723],
                [104.9264238, 19.9904491],
                [104.9262759, 19.9906461],
                [104.9254712, 19.9921081],
                [104.9252962, 19.9926664],
                [104.9250957, 19.9932171],
                [104.9248255, 19.993859],
                [104.9246001, 19.994325],
                [104.9245141, 19.9944707],
                [104.9244194, 19.9946117],
                [104.9243165, 19.9947475],
                [104.9242057, 19.9948776],
                [104.9239704, 19.9951114],
                [104.9237192, 19.9953129],
                [104.9235856, 19.9954041],
                [104.923304, 19.9955663],
                [104.9194309, 19.9975726],
                [104.9163946, 19.9963325],
                [104.915472, 19.9961813],
                [104.9150993, 19.9965178],
                [104.9147517, 19.9968773],
                [104.9144306, 19.997258],
                [104.9142805, 19.9974557],
                [104.9140021, 19.997865],
                [104.9137409, 19.9983117],
                [104.9135086, 19.9987723],
                [104.9134088, 19.998995],
                [104.9132296, 19.9994478],
                [104.9132084, 19.9995568],
                [104.9131417, 19.9997694],
                [104.9130439, 19.999971],
                [104.912917, 20.0001577],
                [104.9127603, 20.0003288],
                [104.9125755, 20.0004797],
                [104.9123693, 20.0006036],
                [104.912146, 20.000698],
                [104.912028, 20.0011078],
                [104.9119422, 20.0015247],
                [104.9118925, 20.0019083],
                [104.9118895, 20.0023505],
                [104.9117977, 20.0021984],
                [104.9070212, 19.9984008],
                [104.9075925, 19.9973246],
                [104.9073047, 19.9942215],
                [104.908579, 19.9884627],
                [104.9084116, 19.9851117],
                [104.9083209, 19.9827525],
                [104.9080163, 19.980927],
                [104.9071187, 19.9789682],
                [104.9070074, 19.9771129],
                [104.9065021, 19.9765974],
                [104.905604, 19.9755662],
                [104.9048184, 19.9741744],
                [104.9042014, 19.9725765],
                [104.9036118, 19.9708441],
                [104.9034711, 19.9701481],
                [104.9034824, 19.9689394],
                [104.9029085, 19.9677998],
                [104.9005589, 19.9657674],
                [104.8991493, 19.9643053],
                [104.8965392, 19.9613812],
                [104.8948686, 19.9596465],
                [104.8926761, 19.9575151],
                [104.8903265, 19.9557551],
                [104.8883424, 19.9545155],
                [104.8868282, 19.9534743],
                [104.8845305, 19.9524325],
                [104.8809794, 19.9510927],
                [104.8786292, 19.9504719],
                [104.8770104, 19.9498267],
                [104.8752873, 19.9489834],
                [104.8727285, 19.9479413],
                [104.8711097, 19.9473952],
                [104.8695953, 19.9468988],
                [104.8677963, 19.9462463],
                [104.866364, 19.9455454],
                [104.8626103, 19.9432598],
                [104.8591129, 19.9406565],
                [104.8576472, 19.9393413],
                [104.8575989, 19.939191],
                [104.8575899, 19.9390043],
                [104.8576167, 19.9388191],
                [104.8576674, 19.9386666],
                [104.8577423, 19.9385232],
                [104.8577884, 19.938456],
                [104.8580548, 19.9381929],
                [104.8581178, 19.938097],
                [104.8581384, 19.9380439],
                [104.8581563, 19.9379325],
                [104.8581408, 19.9378161],
                [104.8580892, 19.9377045],
                [104.858051, 19.9376551],
                [104.8579541, 19.9375743],
                [104.8578367, 19.937523],
                [104.8570513, 19.9372758],
                [104.8566715, 19.9371693],
                [104.8561936, 19.9370489],
                [104.8560096, 19.9369834],
                [104.8558381, 19.9368928],
                [104.8556828, 19.9367792],
                [104.8555411, 19.9366378],
                [104.8554248, 19.9364772],
                [104.8552862, 19.9362136],
                [104.8551732, 19.936048],
                [104.8551071, 19.9359682],
                [104.8548925, 19.9357418],
                [104.8547432, 19.9355963],
                [104.8544276, 19.9353218],
                [104.8542495, 19.935184],
                [104.8538764, 19.9349291],
                [104.8535538, 19.9347398],
                [104.8534298, 19.9346612],
                [104.8532689, 19.9345452],
                [104.8530006, 19.9342577],
                [104.8528808, 19.9341663],
                [104.8527525, 19.9341087],
                [104.8526257, 19.9340808],
                [104.8519814, 19.9340711],
                [104.8518391, 19.9340575],
                [104.8517025, 19.9340174],
                [104.8515678, 19.9339463],
                [104.8515031, 19.9338974],
                [104.851445, 19.9338417],
                [104.8510922, 19.9333222],
                [104.8509831, 19.9331788],
                [104.8508658, 19.9330412],
                [104.8507407, 19.9329099],
                [104.8506081, 19.9327852],
                [104.8503402, 19.93257],
                [104.8500502, 19.9323817],
                [104.8499439, 19.9323429],
                [104.8498417, 19.9322953],
                [104.8497443, 19.9322394],
                [104.8496527, 19.9321756],
                [104.8495674, 19.9321043],
                [104.8495005, 19.9320384],
                [104.8493911, 19.9319064],
                [104.8493475, 19.9318421],
                [104.8491919, 19.9315093],
                [104.8490737, 19.9313549],
                [104.8490044, 19.9312854],
                [104.848848, 19.9311645],
                [104.8487619, 19.9311139],
                [104.8485655, 19.9310258],
                [104.8483658, 19.9309142],
                [104.8482701, 19.9308453],
                [104.8481805, 19.9307695],
                [104.8480976, 19.9306872],
                [104.8475826, 19.929956],
                [104.8475143, 19.9298221],
                [104.8474795, 19.9296772],
                [104.8474753, 19.929603],
                [104.847489, 19.9294744],
                [104.8475289, 19.9293508],
                [104.8475407, 19.9292756],
                [104.847537, 19.9291237],
                [104.8474973, 19.9289765],
                [104.8474646, 19.928907],
                [104.8473782, 19.928784],
                [104.8472656, 19.9286815],
                [104.847201, 19.9286395],
                [104.8468999, 19.9284866],
                [104.8466722, 19.9283614],
                [104.846449, 19.9282292],
                [104.8460466, 19.9279645],
                [104.8456903, 19.9276988],
                [104.845351, 19.9274142],
                [104.8452605, 19.9273055],
                [104.8451943, 19.9271823],
                [104.8451713, 19.9271167],
                [104.8451151, 19.9268761],
                [104.8450742, 19.9267591],
                [104.8449674, 19.9265342],
                [104.844827, 19.926322],
                [104.8447455, 19.9262233],
                [104.8445618, 19.9260427],
                [104.8443837, 19.9258931],
                [104.844251, 19.9257378],
                [104.8441498, 19.9255629],
                [104.8440832, 19.9253738],
                [104.8440635, 19.9252759],
                [104.8440531, 19.9251752],
                [104.8440525, 19.9250741],
                [104.8440615, 19.9249733],
                [104.8440802, 19.9248737],
                [104.8441084, 19.9247761],
                [104.8442369, 19.9244855],
                [104.8442734, 19.9243782],
                [104.8443209, 19.9241575],
                [104.8443317, 19.9240453],
                [104.8443274, 19.9238219],
                [104.844247, 19.9233724],
                [104.8441866, 19.922911],
                [104.8441681, 19.9226792],
                [104.8441575, 19.922447],
                [104.8441555, 19.9221397],
                [104.8441471, 19.9220652],
                [104.8441034, 19.9219215],
                [104.844026, 19.9217909],
                [104.8439379, 19.9216966],
                [104.8438321, 19.92162],
                [104.8437738, 19.9215892],
                [104.8437157, 19.9215357],
                [104.8436189, 19.9214131],
                [104.8435515, 19.9212743],
                [104.8435163, 19.9211252],
                [104.8435144, 19.9209795],
                [104.8435434, 19.9208364],
                [104.8436022, 19.9207015],
                [104.8436435, 19.9205034],
                [104.8436507, 19.9203017],
                [104.8436236, 19.9201014],
                [104.8435952, 19.9199964],
                [104.8435573, 19.9198942],
                [104.8435103, 19.9197953],
                [104.8434544, 19.9197006],
                [104.8433901, 19.9196108],
                [104.8432248, 19.9194226],
                [104.8431393, 19.9193132],
                [104.8430617, 19.9191987],
                [104.8429924, 19.9190796],
                [104.8429316, 19.9189565],
                [104.8428386, 19.9187113],
                [104.842774, 19.9184582],
                [104.8427526, 19.9183295],
                [104.8427316, 19.9180581],
                [104.8427443, 19.9177743],
                [104.8427921, 19.9174939],
                [104.8444658, 19.9158926],
                [104.844591, 19.9157811],
                [104.8448521, 19.9155698],
                [104.8449985, 19.9154624],
                [104.8454644, 19.9151599],
                [104.8456207, 19.9150506],
                [104.8459157, 19.9148114],
                [104.8460537, 19.9146821],
                [104.8462969, 19.9144202],
                [104.8465137, 19.9141386],
                [104.8467047, 19.9138502],
                [104.8468051, 19.9137138],
                [104.8470271, 19.9134561],
                [104.8472748, 19.913221],
                [104.8475457, 19.9130107],
                [104.8476891, 19.9129153],
                [104.8479903, 19.9127453],
                [104.8482437, 19.9126495],
                [104.8485005, 19.9125622],
                [104.8487605, 19.9124834],
                [104.8490232, 19.9124133],
                [104.8492885, 19.9123519],
                [104.8497724, 19.9122635],
                [104.8502613, 19.9122042],
                [104.8507532, 19.9121743],
                [104.8509997, 19.9121703],
                [104.8509511, 19.9120604],
                [104.8508806, 19.9119577],
                [104.850789, 19.9118666],
                [104.8507369, 19.9118274],
                [104.8490202, 19.9109901],
                [104.848763, 19.910642],
                [104.8486233, 19.9104756],
                [104.8483636, 19.9101997],
                [104.8482261, 19.9100684],
                [104.8480837, 19.9099418],
                [104.8468208, 19.9088919],
                [104.8460913, 19.9078831],
                [104.8460499, 19.9077703],
                [104.8460376, 19.9077116],
                [104.8460313, 19.9075722],
                [104.84604, 19.9075029],
                [104.8466492, 19.9055428],
                [104.846713, 19.9050727],
                [104.8467505, 19.9045973],
                [104.846762, 19.9039981],
                [104.8467408, 19.9037306],
                [104.8467155, 19.903584],
                [104.8466806, 19.9034393],
                [104.8466364, 19.9032968],
                [104.8464842, 19.9029185],
                [104.8464355, 19.9027079],
                [104.8464234, 19.9026007],
                [104.8464239, 19.9023852],
                [104.8463773, 19.9011131],
                [104.8463461, 19.9009728],
                [104.8463189, 19.9009057],
                [104.8462454, 19.900785],
                [104.8461506, 19.9006836],
                [104.8460956, 19.90064],
                [104.8459732, 19.9005694],
                [104.8458507, 19.9004854],
                [104.8457324, 19.9003963],
                [104.8455091, 19.9002036],
                [104.8454046, 19.9001003],
                [104.845191, 19.8998558],
                [104.8450942, 19.8997264],
                [104.8449218, 19.8994546],
                [104.8448842, 19.8993651],
                [104.8448561, 19.8992725],
                [104.8448377, 19.8991778],
                [104.8448292, 19.8990818],
                [104.8448401, 19.8989015],
                [104.8448818, 19.8987373],
                [104.8449137, 19.8986584],
                [104.8449984, 19.8985095],
                [104.8450506, 19.8984408],
                [104.8451824, 19.898318],
                [104.8452934, 19.8981782],
                [104.8453889, 19.8980072],
                [104.8454619, 19.8978069],
                [104.8454858, 19.8977034],
                [104.8455077, 19.8974999],
                [104.845498, 19.8973028],
                [104.8454583, 19.8971091],
                [104.8450559, 19.8965391],
                [104.8448639, 19.8963331],
                [104.8446647, 19.8961331],
                [104.8444586, 19.8959394],
                [104.8442459, 19.8957522],
                [104.8438118, 19.8954059],
                [104.8435861, 19.8952427],
                [104.8435271, 19.8949754],
                [104.8432481, 19.8945971],
                [104.8431251, 19.8944544],
                [104.8429796, 19.8943319],
                [104.8428994, 19.8942793],
                [104.8428186, 19.8942346],
                [104.8425842, 19.8941364],
                [104.8425249, 19.8941026],
                [104.8424703, 19.8940624],
                [104.8423834, 19.893972],
                [104.8423201, 19.8938657],
                [104.8419553, 19.893109],
                [104.8415071, 19.8922681],
                [104.8414173, 19.8920398],
                [104.8413845, 19.891922],
                [104.8413427, 19.8916732],
                [104.8413352, 19.8915431],
                [104.8413373, 19.8914128],
                [104.8413623, 19.891199],
                [104.8413847, 19.8911168],
                [104.8414563, 19.8909612],
                [104.8415047, 19.8908895],
                [104.8416237, 19.8907625],
                [104.8417682, 19.8906615],
                [104.841848, 19.8906221],
                [104.8427041, 19.8906134],
                [104.842849, 19.8905851],
                [104.8429182, 19.8905601],
                [104.8430461, 19.8904899],
                [104.8431033, 19.8904456],
                [104.8431938, 19.8903497],
                [104.8432614, 19.8902383],
                [104.8432857, 19.8901782],
                [104.843314, 19.8900504],
                [104.8433131, 19.8899199],
                [104.8433018, 19.8898554],
                [104.8431016, 19.8895226],
                [104.8430121, 19.889351],
                [104.8428585, 19.8890079],
                [104.8427331, 19.8886548],
                [104.8424145, 19.8882472],
                [104.8420795, 19.8877825],
                [104.8417348, 19.8872578],
                [104.8415717, 19.8869901],
                [104.841789, 19.8858803],
                [104.8419756, 19.8857053],
                [104.842155, 19.8855236],
                [104.8423269, 19.8853357],
                [104.8424911, 19.8851418],
                [104.8426473, 19.8849421],
                [104.8427963, 19.8847355],
                [104.8429367, 19.8845236],
                [104.8430684, 19.8843069],
                [104.8431911, 19.8840856],
                [104.8433047, 19.88386],
                [104.8434091, 19.8836305],
                [104.8437685, 19.8825862],
                [104.8438991, 19.8823922],
                [104.8440582, 19.882218],
                [104.8442254, 19.8820793],
                [104.844316, 19.882018],
                [104.844509, 19.8819131],
                [104.8449309, 19.8817346],
                [104.8452358, 19.881571],
                [104.8455226, 19.8813805],
                [104.8457991, 19.8811557],
                [104.8460503, 19.880906],
                [104.8462736, 19.8806339],
                [104.8471856, 19.8793425],
                [104.8472779, 19.8791492],
                [104.8473138, 19.8790487],
                [104.8473643, 19.8788422],
                [104.8473859, 19.8786275],
                [104.8473852, 19.8785178],
                [104.8473765, 19.8784083],
                [104.8473274, 19.8781137],
                [104.8473338, 19.877956],
                [104.8473485, 19.8778782],
                [104.8474002, 19.8777281],
                [104.8474453, 19.8775656],
                [104.8474818, 19.8774013],
                [104.8475291, 19.8770684],
                [104.8475414, 19.8767261],
                [104.8475336, 19.8765515],
                [104.8474898, 19.8762045],
                [104.8474408, 19.8759526],
                [104.8474374, 19.8758714],
                [104.8474435, 19.8757902],
                [104.8474797, 19.8756443],
                [104.8475399, 19.8755193],
                [104.8475791, 19.8754614],
                [104.8476738, 19.8753571],
                [104.8477977, 19.8752427],
                [104.8479284, 19.8751353],
                [104.8480655, 19.8750352],
                [104.8482086, 19.8749427],
                [104.8483571, 19.8748582],
                [104.8485106, 19.8747819],
                [104.8486755, 19.8745882],
                [104.8488174, 19.8743788],
                [104.8488791, 19.8742691],
                [104.8489845, 19.8740378],
                [104.8490275, 19.8739168],
                [104.8490918, 19.873669],
                [104.849113, 19.873543],
                [104.8491329, 19.8732886],
                [104.8499858, 19.8724915],
                [104.8500639, 19.8723984],
                [104.8501985, 19.872198],
                [104.8502544, 19.8720917],
                [104.8503376, 19.8718835],
                [104.8503886, 19.8716813],
                [104.8504043, 19.8715784],
                [104.8504045, 19.8714684],
                [104.8503774, 19.8713615],
                [104.8503225, 19.8712604],
                [104.8502433, 19.8711748],
                [104.849503, 19.8706552],
                [104.8484516, 19.8691719],
                [104.8482731, 19.8688452],
                [104.8481727, 19.8686876],
                [104.8480812, 19.8685564],
                [104.847777, 19.868163],
                [104.8476774, 19.8680177],
                [104.8474981, 19.8677159],
                [104.8473465, 19.8674011],
                [104.8467135, 19.8666594],
                [104.8466566, 19.8665664],
                [104.8465674, 19.8663689],
                [104.8465356, 19.8662658],
                [104.846499, 19.866054],
                [104.8464979, 19.8658483],
                [104.84651, 19.8657461],
                [104.8465589, 19.8655456],
                [104.8466344, 19.8653402],
                [104.8466492, 19.8652266],
                [104.8466368, 19.8651004],
                [104.8466194, 19.8650391],
                [104.8465633, 19.8649239],
                [104.8458094, 19.8626808],
                [104.8457557, 19.8625596],
                [104.8456326, 19.8623386],
                [104.845492, 19.8621427],
                [104.8453295, 19.8619623],
                [104.845207, 19.861945],
                [104.8450834, 19.8619366],
                [104.8449595, 19.8619374],
                [104.844836, 19.8619472],
                [104.8446331, 19.8619835],
                [104.844534, 19.8620109],
                [104.8443425, 19.8620834],
                [104.8437256, 19.8615537],
                [104.8434725, 19.8615005],
                [104.8432273, 19.8614212],
                [104.8429946, 19.8613175],
                [104.8427771, 19.8611912],
                [104.8426741, 19.8611198],
                [104.8418641, 19.8602974],
                [104.8407054, 19.8603731],
                [104.8396003, 19.8598282],
                [104.838506, 19.8602015],
                [104.8385221, 19.858809],
                [104.8380983, 19.8577305],
                [104.8365909, 19.8571238],
                [104.8362261, 19.8562055],
                [104.8361999, 19.8560705],
                [104.8361838, 19.855934],
                [104.8361803, 19.8556876],
                [104.8362045, 19.8554703],
                [104.8362515, 19.8552369],
                [104.836268, 19.8551096],
                [104.8362757, 19.8549816],
                [104.8362655, 19.8547376],
                [104.836226, 19.8545085],
                [104.8361955, 19.8543961],
                [104.8361579, 19.8542855],
                [104.83602, 19.853949],
                [104.8359537, 19.8537124],
                [104.8359138, 19.853453],
                [104.8359074, 19.8531735],
                [104.8359383, 19.8528954],
                [104.8360062, 19.8526232],
                [104.8363633, 19.8516787],
                [104.8365533, 19.8512104],
                [104.8369567, 19.850281],
                [104.8373902, 19.8493637],
                [104.837543, 19.8491803],
                [104.8376289, 19.8490963],
                [104.8378163, 19.8489464],
                [104.8380232, 19.8488213],
                [104.8391122, 19.848405],
                [104.8395294, 19.8489068],
                [104.8399168, 19.8494293],
                [104.840244, 19.8499239],
                [104.8405445, 19.8504334],
                [104.8406517, 19.8502366],
                [104.8406603, 19.8501616],
                [104.8406782, 19.8500881],
                [104.8407405, 19.8499512],
                [104.8408339, 19.849833],
                [104.8409539, 19.8497385],
                [104.8410219, 19.8497017],
                [104.8424113, 19.8491417],
                [104.8425911, 19.8490539],
                [104.8427661, 19.8489579],
                [104.8429358, 19.8488538],
                [104.8430998, 19.8487419],
                [104.8432577, 19.8486225],
                [104.8434091, 19.8484958],
                [104.8436816, 19.8482321],
                [104.8438074, 19.8480913],
                [104.844037, 19.8477936],
                [104.8442352, 19.8474766],
                [104.844485, 19.8471693],
                [104.8447625, 19.8468838],
                [104.8449111, 19.84675],
                [104.8452256, 19.8465019],
                [104.845562, 19.8462808],
                [104.8459177, 19.8460883],
                [104.8484194, 19.8449032],
                [104.8491537, 19.8440645],
                [104.849621, 19.8435308],
                [104.8479903, 19.8415931],
                [104.8479151, 19.8377884],
                [104.8479221, 19.8375481],
                [104.8479137, 19.837428],
                [104.8478733, 19.8371906],
                [104.8478019, 19.8369597],
                [104.8477006, 19.8367389],
                [104.8476334, 19.8366242],
                [104.8474752, 19.8364088],
                [104.8472876, 19.8362156],
                [104.847074, 19.8360478],
                [104.8469586, 19.8359745],
                [104.8467135, 19.8358507],
                [104.8431086, 19.8343571],
                [104.8429088, 19.834259],
                [104.8427138, 19.8341529],
                [104.8425237, 19.8340389],
                [104.8423391, 19.8339174],
                [104.8421603, 19.8337884],
                [104.8419875, 19.8336523],
                [104.8418212, 19.8335093],
                [104.8415219, 19.8332175],
                [104.8412493, 19.8329035],
                [104.8411235, 19.8327388],
                [104.8408943, 19.8323955],
                [104.8405436, 19.8318014],
                [104.8403293, 19.8313692],
                [104.840235, 19.8311478],
                [104.8400728, 19.830696],
                [104.8399465, 19.8302342],
                [104.8398568, 19.8297649],
                [104.8398258, 19.8295282],
                [104.8397972, 19.8291794],
                [104.8397808, 19.8290691],
                [104.8397549, 19.8289604],
                [104.8397198, 19.828854],
                [104.8396757, 19.8287506],
                [104.8396229, 19.8286509],
                [104.8395617, 19.8285555],
                [104.8394925, 19.8284651],
                [104.8394158, 19.8283803],
                [104.8392429, 19.8282301],
                [104.8390479, 19.8281086],
                [104.8388347, 19.8280177],
                [104.8387231, 19.8279846],
                [104.8384931, 19.8279439],
                [104.8382592, 19.8279382],
                [104.8381401, 19.8279068],
                [104.8380241, 19.8278666],
                [104.8379118, 19.8278178],
                [104.837804, 19.8277608],
                [104.8377013, 19.8276959],
                [104.837605, 19.8276241],
                [104.8375151, 19.8275452],
                [104.837432, 19.82746],
                [104.8373564, 19.8273688],
                [104.8372887, 19.8272723],
                [104.8372292, 19.8271711],
                [104.8364996, 19.8252333],
                [104.8363863, 19.8247565],
                [104.8363435, 19.8245154],
                [104.8362862, 19.8240298],
                [104.8362667, 19.8235415],
                [104.8362711, 19.8232972],
                [104.8362851, 19.8230532],
                [104.8363088, 19.8228065],
                [104.8363423, 19.8225609],
                [104.8363854, 19.8223165],
                [104.8364381, 19.8220739],
                [104.8365002, 19.8218332],
                [104.8365718, 19.8215948],
                [104.8366528, 19.8213591],
                [104.8367429, 19.8211264],
                [104.8369757, 19.8206042],
                [104.8370005, 19.820464],
                [104.8369994, 19.8203928],
                [104.8369706, 19.8202533],
                [104.8369077, 19.820124],
                [104.8368189, 19.8200169],
                [104.8367126, 19.8199348],
                [104.8365899, 19.8198761],
                [104.8364568, 19.8198436],
                [104.8358238, 19.8197628],
                [104.8357379, 19.8197474],
                [104.8356545, 19.819723],
                [104.8355745, 19.8196899],
                [104.835499, 19.8196484],
                [104.8354289, 19.8195992],
                [104.8353653, 19.8195429],
                [104.8353088, 19.8194802],
                [104.8352616, 19.8194142],
                [104.8351916, 19.8192696],
                [104.8351567, 19.8191142],
                [104.8351586, 19.8189553],
                [104.8352498, 19.8185386],
                [104.8353205, 19.8183007],
                [104.8353813, 19.8180604],
                [104.8354321, 19.8178181],
                [104.8354728, 19.8175741],
                [104.8355034, 19.8173288],
                [104.8355239, 19.8170825],
                [104.8355341, 19.8168357],
                [104.8355343, 19.8166],
                [104.8355068, 19.8161295],
                [104.8354422, 19.8156621],
                [104.8352275, 19.8146688],
                [104.8351937, 19.8144717],
                [104.8351884, 19.8143719],
                [104.8352018, 19.8141662],
                [104.8352497, 19.8139585],
                [104.8352864, 19.813858],
                [104.8353839, 19.8136663],
                [104.8365426, 19.8119504],
                [104.837594, 19.8104565],
                [104.8378291, 19.810262],
                [104.8379398, 19.8101578],
                [104.8381466, 19.8099367],
                [104.8383633, 19.809656],
                [104.8384601, 19.8095083],
                [104.8386377, 19.8092003],
                [104.83879, 19.8088769],
                [104.8389074, 19.8085408],
                [104.8389888, 19.8081955],
                [104.8390334, 19.8078434],
                [104.8390406, 19.807489],
                [104.8390102, 19.8071356],
                [104.8385489, 19.8056013],
                [104.8382206, 19.8032835],
                [104.8382128, 19.8030671],
                [104.8382402, 19.8028522],
                [104.8382669, 19.8027468],
                [104.8383742, 19.8024549],
                [104.8384136, 19.8022577],
                [104.8384193, 19.8020572],
                [104.8384094, 19.8019572],
                [104.838361, 19.8017503],
                [104.8382765, 19.8015539],
                [104.8381583, 19.8013735],
                [104.8380875, 19.801291],
                [104.836516, 19.7999966],
                [104.8363751, 19.7998519],
                [104.8363154, 19.7997713],
                [104.8362232, 19.7996036],
                [104.8361646, 19.799431],
                [104.8361468, 19.7993419],
                [104.8361349, 19.799161],
                [104.8361993, 19.7983232],
                [104.8362938, 19.7976982],
                [104.8363521, 19.7970692],
                [104.8363738, 19.7964382],
                [104.8363709, 19.7961225],
                [104.8363567, 19.7958966],
                [104.836338, 19.7957847],
                [104.836278, 19.7955655],
                [104.8361885, 19.7953553],
                [104.8361241, 19.7952395],
                [104.8360506, 19.7951285],
                [104.8359686, 19.7950229],
                [104.8358785, 19.7949234],
                [104.8357808, 19.7948304],
                [104.8349976, 19.7943862],
                [104.8343646, 19.7934474],
                [104.8325086, 19.7914889],
                [104.832294, 19.7907217],
                [104.8322594, 19.7906356],
                [104.8321661, 19.7904738],
                [104.8321082, 19.7903996],
                [104.8319721, 19.7902674],
                [104.8318285, 19.7901693],
                [104.8317507, 19.7901288],
                [104.8315859, 19.7900667],
                [104.8315, 19.7900453],
                [104.8305988, 19.7899444],
                [104.8293972, 19.7895002],
                [104.8291568, 19.7893957],
                [104.8289328, 19.7892629],
                [104.8287288, 19.789104],
                [104.8286354, 19.7890156],
                [104.8284608, 19.7888135],
                [104.8283847, 19.7887045],
                [104.8282569, 19.7884734],
                [104.8281634, 19.7882282],
                [104.8276657, 19.7866758],
                [104.8275553, 19.7864364],
                [104.8274155, 19.786211],
                [104.8272481, 19.7860029],
                [104.8271549, 19.7859063],
                [104.8269287, 19.7857117],
                [104.8268066, 19.7856245],
                [104.8265467, 19.7854716],
                [104.8264099, 19.7854066],
                [104.8262691, 19.7853495],
                [104.825986, 19.7852619],
                [104.8257022, 19.7852073],
                [104.8254137, 19.7851837],
                [104.8251241, 19.7851912],
                [104.8248374, 19.7852299],
                [104.8236787, 19.7856337],
                [104.8231991, 19.7857481],
                [104.8229563, 19.7857927],
                [104.8224662, 19.7858567],
                [104.8222196, 19.785876],
                [104.8217574, 19.785889],
                [104.8212953, 19.7858722],
                [104.8208356, 19.7858255],
                [104.8196983, 19.7849169],
                [104.8195758, 19.7846317],
                [104.8195017, 19.7844946],
                [104.8193333, 19.7842391],
                [104.8191404, 19.7840083],
                [104.8162222, 19.7842001],
                [104.8160692, 19.7842852],
                [104.8157782, 19.7844773],
                [104.81551, 19.7846967],
                [104.815282, 19.7849249],
                [104.8150915, 19.7851556],
                [104.814924, 19.7854015],
                [104.8148692, 19.7855915],
                [104.8148047, 19.7857788],
                [104.8147305, 19.7859629],
                [104.8146468, 19.7861434],
                [104.8145539, 19.7863198],
                [104.8144519, 19.7864918],
                [104.8143828, 19.7865981],
                [104.8143054, 19.7866993],
                [104.8142202, 19.7867948],
                [104.8141276, 19.7868839],
                [104.813934, 19.7870337],
                [104.8137324, 19.7871501],
                [104.8136258, 19.7871985],
                [104.8135403, 19.7872131],
                [104.8134538, 19.7872206],
                [104.8132803, 19.7872138],
                [104.8131108, 19.7871783],
                [104.8130251, 19.7871484],
                [104.8128643, 19.7870668],
                [104.8127907, 19.7870158],
                [104.8127225, 19.7869586],
                [104.8125715, 19.7868091],
                [104.8124761, 19.7867291],
                [104.8122678, 19.7865903],
                [104.812156, 19.7865322],
                [104.8119261, 19.7864422],
                [104.8118065, 19.7864093],
                [104.8115614, 19.7863683],
                [104.8114371, 19.7863606],
                [104.8107183, 19.7860173],
                [104.808315, 19.7859668],
                [104.8080567, 19.7859707],
                [104.8077986, 19.7859838],
                [104.8075413, 19.7860061],
                [104.8072851, 19.7860375],
                [104.8065097, 19.7861599],
                [104.8057294, 19.7862495],
                [104.8049364, 19.7863068],
                [104.8045391, 19.7863227],
                [104.8038419, 19.7863339],
                [104.8035447, 19.7863692],
                [104.8032535, 19.7864355],
                [104.8029721, 19.7865322],
                [104.8027154, 19.7866518],
                [104.8025926, 19.7867211],
                [104.8023597, 19.7868778],
                [104.8021459, 19.7870571],
                [104.8014915, 19.7878648],
                [104.8013334, 19.7879436],
                [104.8011803, 19.7880307],
                [104.8010325, 19.7881256],
                [104.8008907, 19.7882282],
                [104.8007539, 19.7883391],
                [104.800624, 19.7884571],
                [104.8005014, 19.7885818],
                [104.8003864, 19.7887128],
                [104.7995718, 19.7901244],
                [104.7975755, 19.7893387],
                [104.797404, 19.7892649],
                [104.7970711, 19.7890982],
                [104.7964951, 19.7887499],
                [104.7960627, 19.788521],
                [104.7956215, 19.7883224],
                [104.795396, 19.7882334],
                [104.7949362, 19.7880768],
                [104.7925115, 19.7857548],
                [104.7916746, 19.7857346],
                [104.7914339, 19.7857169],
                [104.7911942, 19.7856899],
                [104.7909558, 19.7856539],
                [104.7905555, 19.7855721],
                [104.7901622, 19.7854647],
                [104.7899687, 19.7854015],
                [104.7878659, 19.7854217],
                [104.786396, 19.7846645],
                [104.7845721, 19.7846342],
                [104.7841898, 19.7841185],
                [104.783988, 19.783868],
                [104.7835637, 19.7833822],
                [104.7833416, 19.7831473],
                [104.7829124, 19.7827264],
                [104.782296, 19.7821655],
                [104.7815428, 19.7815077],
                [104.7808599, 19.7809392],
                [104.7800231, 19.7797176],
                [104.7788751, 19.7785768],
                [104.7785326, 19.7783152],
                [104.778369, 19.778176],
                [104.778058, 19.7778816],
                [104.7779111, 19.7777268],
                [104.7776184, 19.7773818],
                [104.7774748, 19.7771909],
                [104.7773394, 19.7769947],
                [104.7772124, 19.7767936],
                [104.7769761, 19.7763864],
                [104.7768323, 19.7762003],
                [104.7767514, 19.776114],
                [104.7765635, 19.7759489],
                [104.7764561, 19.775872],
                [104.7762258, 19.7757399],
                [104.7761039, 19.7756853],
                [104.7758022, 19.7755777],
                [104.7756229, 19.7755253],
                [104.7754409, 19.7754819],
                [104.7752568, 19.7754476],
                [104.7750709, 19.7754225],
                [104.7747194, 19.7754004],
                [104.7743902, 19.7754094],
                [104.7740632, 19.775447],
                [104.7737517, 19.7755439],
                [104.7735926, 19.7755813],
                [104.7732693, 19.7756338],
                [104.7729505, 19.7756558],
                [104.7726309, 19.775649],
                [104.7716331, 19.7750634],
                [104.7695893, 19.7750634],
                [104.7693869, 19.7750192],
                [104.7692836, 19.7750079],
                [104.7690758, 19.7750073],
                [104.7688592, 19.7750382],
                [104.7687483, 19.7750673],
                [104.7686402, 19.7751049],
                [104.7685359, 19.7751508],
                [104.7684359, 19.7752047],
                [104.7681985, 19.7752159],
                [104.7677258, 19.7752635],
                [104.7672583, 19.7753445],
                [104.7667985, 19.7754583],
                [104.7663492, 19.7756044],
                [104.7659069, 19.7757847],
                [104.7654749, 19.7759992],
                [104.7650617, 19.7762446],
                [104.7648842, 19.7762863],
                [104.7647015, 19.7762965],
                [104.7646102, 19.7762897],
                [104.7644344, 19.7762533],
                [104.7642713, 19.7761889],
                [104.764123, 19.7760982],
                [104.7640747, 19.7755985],
                [104.76408, 19.7750331],
                [104.7644395, 19.7741598],
                [104.7644631, 19.7737832],
                [104.7644869, 19.7735958],
                [104.7645583, 19.7732246],
                [104.7646057, 19.7730412],
                [104.7647238, 19.7726807],
                [104.7649764, 19.7720908],
                [104.7652555, 19.7715116],
                [104.7655606, 19.7709441],
                [104.7658765, 19.7704128],
                [104.7662955, 19.769769],
                [104.7663671, 19.7696393],
                [104.7664296, 19.7695054],
                [104.7664827, 19.7693681],
                [104.7665262, 19.7692277],
                [104.7665674, 19.7690453],
                [104.7666182, 19.768865],
                [104.7666785, 19.7686872],
                [104.7667479, 19.7685125],
                [104.7668265, 19.7683413],
                [104.766914, 19.7681739],
                [104.7670102, 19.7680108],
                [104.7671149, 19.7678524],
                [104.7672278, 19.767699],
                [104.7673486, 19.7675511],
                [104.767477, 19.7674091],
                [104.7676127, 19.7672731],
                [104.7677555, 19.7671437],
                [104.7679048, 19.767021],
                [104.7682982, 19.7667356],
                [104.7685169, 19.7665442],
                [104.7685823, 19.7662478],
                [104.7686021, 19.7660975],
                [104.7686159, 19.765795],
                [104.768595, 19.7654929],
                [104.7685397, 19.7651947],
                [104.7684505, 19.7649038],
                [104.7683936, 19.7647622],
                [104.7682534, 19.7644844],
                [104.7681701, 19.7643494],
                [104.7679802, 19.7640934],
                [104.7678742, 19.7639732],
                [104.7676418, 19.7637505],
                [104.767516, 19.7636486],
                [104.7673845, 19.7635534],
                [104.7671833, 19.763548],
                [104.7669826, 19.7635331],
                [104.7667829, 19.7635088],
                [104.7665848, 19.7634752],
                [104.7663887, 19.7634323],
                [104.7661951, 19.7633802],
                [104.7660045, 19.7633192],
                [104.7658175, 19.7632492],
                [104.7656343, 19.7631706],
                [104.7654555, 19.7630834],
                [104.7651243, 19.7628918],
                [104.7648246, 19.7626792],
                [104.764683, 19.7625633],
                [104.7644177, 19.7623135],
                [104.7642946, 19.7621802],
                [104.7628355, 19.7602416],
                [104.762315, 19.760115],
                [104.7620582, 19.7600403],
                [104.7615527, 19.7598682],
                [104.7610597, 19.7596666],
                [104.7605576, 19.7594241],
                [104.7603018, 19.7592848],
                [104.7600512, 19.7591373],
                [104.7598061, 19.7589817],
                [104.7595669, 19.7588183],
                [104.7593339, 19.7586472],
                [104.7591072, 19.7584686],
                [104.7588873, 19.7582827],
                [104.7586792, 19.7580622],
                [104.758585, 19.7579444],
                [104.7584175, 19.7576952],
                [104.7583447, 19.7575646],
                [104.7582221, 19.7572932],
                [104.7581293, 19.7570035],
                [104.7580959, 19.7568554],
                [104.7580553, 19.7565553],
                [104.7580503, 19.7562527],
                [104.7580612, 19.7561017],
                [104.7581907, 19.7555207],
                [104.7582841, 19.7549336],
                [104.7583412, 19.7543424],
                [104.7583559, 19.7540458],
                [104.7583587, 19.7534763],
                [104.7583296, 19.7529317],
                [104.7582697, 19.7523893],
                [104.7581792, 19.7518507],
                [104.757988, 19.7512774],
                [104.7578807, 19.7509945],
                [104.7576427, 19.750437],
                [104.7573994, 19.7499396],
                [104.7572684, 19.7496951],
                [104.7569883, 19.7492152],
                [104.7565722, 19.7494194],
                [104.7561711, 19.7496486],
                [104.7557866, 19.7499018],
                [104.7555825, 19.7500513],
                [104.7553842, 19.7502075],
                [104.755006, 19.7505396],
                [104.7548266, 19.7507149],
                [104.7544885, 19.7510833],
                [104.7541551, 19.751076],
                [104.7534897, 19.7510356],
                [104.7531581, 19.7510025],
                [104.7519465, 19.7509565],
                [104.7517333, 19.7509416],
                [104.751521, 19.7509175],
                [104.7513101, 19.7508842],
                [104.751101, 19.7508418],
                [104.7508943, 19.7507904],
                [104.7505013, 19.7506653],
                [104.7503097, 19.7505905],
                [104.7498952, 19.7504004],
                [104.7497767, 19.7503578],
                [104.7495317, 19.7502956],
                [104.7492892, 19.7502657],
                [104.7490447, 19.7502657],
                [104.7488022, 19.7502956],
                [104.7458357, 19.7507955],
                [104.7416997, 19.7491546],
                [104.7413712, 19.7490352],
                [104.7410316, 19.7489473],
                [104.7408588, 19.7489155],
                [104.7405091, 19.7488763],
                [104.740157, 19.7488701],
                [104.7399812, 19.7488795],
                [104.7396338, 19.7489226],
                [104.7392943, 19.7489975],
                [104.7389644, 19.7491037],
                [104.7386472, 19.7492399],
                [104.7384944, 19.749319],
                [104.7382021, 19.7494979],
                [104.7364425, 19.751053],
                [104.7360267, 19.7514583],
                [104.735633, 19.7518828],
                [104.7352624, 19.7523253],
                [104.7349759, 19.7527014],
                [104.7343816, 19.7534389],
                [104.734074, 19.7538],
                [104.7334385, 19.7545063],
                [104.7330942, 19.7548683],
                [104.7327428, 19.7552241],
                [104.7323844, 19.7555736],
                [104.732019, 19.7559166],
                [104.7316468, 19.7562532],
                [104.7294259, 19.7549708],
                [104.7241044, 19.7550314],
                [104.7239475, 19.7547713],
                [104.7237823, 19.7545159],
                [104.7236091, 19.7542652],
                [104.7234279, 19.7540195],
                [104.7232389, 19.7537791],
                [104.7230424, 19.7535441],
                [104.7228384, 19.7533148],
                [104.7224492, 19.7529133],
                [104.7220372, 19.7525324],
                [104.7216037, 19.7521732],
                [104.7209401, 19.7516641],
                [104.7200795, 19.7509684],
                [104.7192429, 19.750247],
                [104.7184314, 19.7495008],
                [104.7176456, 19.7487305],
                [104.716933, 19.747987],
                [104.7162444, 19.7472238],
                [104.7155806, 19.7464414],
                [104.714942, 19.7456405],
                [104.7129249, 19.7452164],
                [104.7087836, 19.7427525],
                [104.7080111, 19.7399376],
                [104.7076822, 19.739559],
                [104.7073284, 19.7392009],
                [104.7069511, 19.7388647],
                [104.7067542, 19.7387051],
                [104.706552, 19.7385516],
                [104.7061048, 19.7382436],
                [104.7056393, 19.7379606],
                [104.7051573, 19.7377033],
                [104.7049013, 19.7375804],
                [104.7043783, 19.7373566],
                [104.703842, 19.7371627],
                [104.7035694, 19.7370772],
                [104.7019815, 19.7369964],
                [104.7016993, 19.7367318],
                [104.7015679, 19.7365909],
                [104.7013257, 19.7362932],
                [104.7012153, 19.7361371],
                [104.7010174, 19.7358118],
                [104.7008507, 19.7354707],
                [104.7007168, 19.7351167],
                [104.700617, 19.7347528],
                [104.7005801, 19.7345681],
                [104.7005328, 19.7341949],
                [104.7005013, 19.7337593],
                [104.7004717, 19.7335122],
                [104.7003869, 19.7330214],
                [104.7002685, 19.7325367],
                [104.7001968, 19.7322974],
                [104.7000289, 19.7318259],
                [104.6999248, 19.731576],
                [104.6998115, 19.7313296],
                [104.6996891, 19.7310872],
                [104.6995577, 19.7308489],
                [104.6994175, 19.7306151],
                [104.6992688, 19.7303861],
                [104.6991115, 19.7301622],
                [104.6989461, 19.7299435],
                [104.6987725, 19.7297305],
                [104.6985912, 19.7295233],
                [104.6983395, 19.729263],
                [104.698113, 19.7289829],
                [104.6979136, 19.7286851],
                [104.6977428, 19.728372],
                [104.6975985, 19.7280369],
                [104.6975371, 19.7278605],
                [104.6974406, 19.7275007],
                [104.6974058, 19.727318],
                [104.6973633, 19.726949],
                [104.6973558, 19.7267635],
                [104.6973681, 19.7263926],
                [104.6973409, 19.7260987],
                [104.6972773, 19.7258098],
                [104.697178, 19.7255299],
                [104.6971154, 19.7253946],
                [104.6969654, 19.7251355],
                [104.6967842, 19.7248947],
                [104.6965742, 19.7246757],
                [104.6964548, 19.7245716],
                [104.6963289, 19.7244746],
                [104.696197, 19.724385],
                [104.6960595, 19.7243031],
                [104.6959169, 19.7242293],
                [104.6957699, 19.7241638],
                [104.6956188, 19.7241069],
                [104.6954643, 19.7240588],
                [104.695307, 19.7240196],
                [104.6951475, 19.7239896],
                [104.6947964, 19.72395],
                [104.6946131, 19.7238991],
                [104.6944427, 19.723818],
                [104.6941804, 19.7236315],
                [104.6940802, 19.7235753],
                [104.6939753, 19.7235271],
                [104.6938665, 19.7234874],
                [104.6937545, 19.7234564],
                [104.6936402, 19.7234344],
                [104.6935243, 19.7234215],
                [104.6934077, 19.7234179],
                [104.6932912, 19.7234234],
                [104.6930658, 19.7234609],
                [104.6929563, 19.7234926],
                [104.6927476, 19.7235809],
                [104.692557, 19.7237003],
                [104.6923899, 19.7238476],
                [104.692262, 19.723925],
                [104.692129, 19.7239946],
                [104.6919916, 19.724056],
                [104.6918503, 19.724109],
                [104.6917056, 19.7241534],
                [104.6915583, 19.724189],
                [104.6914088, 19.7242157],
                [104.6912578, 19.7242333],
                [104.691106, 19.7242418],
                [104.6909538, 19.7242412],
                [104.6908021, 19.7242313],
                [104.6905112, 19.7241864],
                [104.6903686, 19.7241513],
                [104.6900916, 19.7240565],
                [104.6898292, 19.7239302],
                [104.6897048, 19.7238558],
                [104.6894717, 19.723686],
                [104.6893674, 19.7236244],
                [104.6891454, 19.7235242],
                [104.68891, 19.7234564],
                [104.6886664, 19.7234225],
                [104.6885433, 19.7234186],
                [104.6884203, 19.7234234],
                [104.688082, 19.7234567],
                [104.687742, 19.7234562],
                [104.6874038, 19.7234218],
                [104.6872367, 19.723392],
                [104.6869086, 19.7233077],
                [104.6865919, 19.7231911],
                [104.6864389, 19.7231211],
                [104.6861458, 19.7229588],
                [104.6858751, 19.7227699],
                [104.6856276, 19.7225547],
                [104.6855135, 19.722438],
                [104.6853062, 19.722188],
                [104.6852135, 19.7220555],
                [104.6850517, 19.7217773],
                [104.684983, 19.7216324],
                [104.6848697, 19.7213294],
                [104.6848259, 19.7211719],
                [104.6847915, 19.7210124],
                [104.6847664, 19.7208514],
                [104.6847509, 19.7206893],
                [104.684745, 19.7205266],
                [104.6847486, 19.7203639],
                [104.6847618, 19.7202016],
                [104.6848187, 19.7199389],
                [104.6848353, 19.7198057],
                [104.6848444, 19.7195377],
                [104.6848369, 19.7194038],
                [104.6847971, 19.7191344],
                [104.6847248, 19.718871],
                [104.6846211, 19.7186171],
                [104.6845579, 19.7184948],
                [104.6840613, 19.7178062],
                [104.683534, 19.7171382],
                [104.6829767, 19.716492],
                [104.6823907, 19.7158689],
                [104.6820768, 19.7155561],
                [104.6817556, 19.71525],
                [104.6814273, 19.7149506],
                [104.6810921, 19.7146582],
                [104.6807501, 19.7143728],
                [104.6804015, 19.7140945],
                [104.6800464, 19.7138236],
                [104.6797601, 19.713535],
                [104.6796261, 19.7133831],
                [104.6793775, 19.7130651],
                [104.6792632, 19.7128995],
                [104.6790625, 19.7125687],
                [104.6788887, 19.7122246],
                [104.6786715, 19.7117012],
                [104.6785927, 19.7115361],
                [104.6784137, 19.7112158],
                [104.6781965, 19.7108966],
                [104.6780714, 19.7107371],
                [104.6779387, 19.7105831],
                [104.6777987, 19.710435],
                [104.6759319, 19.7092432],
                [104.6726918, 19.7061121],
                [104.6701598, 19.7041324],
                [104.6630923, 19.7025373],
                [104.6631581, 19.7023914],
                [104.6632145, 19.7022421],
                [104.6632614, 19.7020899],
                [104.6632984, 19.7019353],
                [104.6633255, 19.7017789],
                [104.6633489, 19.701504],
                [104.6633331, 19.700821],
                [104.6633423, 19.7004818],
                [104.6633777, 19.7002126],
                [104.663407, 19.7000798],
                [104.6634883, 19.6998196],
                [104.6635401, 19.6996931],
                [104.6653747, 19.6961123],
                [104.666158, 19.69489],
                [104.6662059, 19.6946834],
                [104.6662423, 19.6945832],
                [104.666335, 19.6943985],
                [104.6664507, 19.6942345],
                [104.66659, 19.6940875],
                [104.6666676, 19.6940213],
                [104.6671021, 19.6937082],
                [104.667145, 19.692789],
                [104.6671919, 19.6923889],
                [104.6672287, 19.6921907],
                [104.6673288, 19.6917994],
                [104.6673918, 19.6916071],
                [104.6675401, 19.6912378],
                [104.6676262, 19.6910578],
                [104.6678218, 19.6907086],
                [104.6679308, 19.69054],
                [104.6681707, 19.6902162],
                [104.668301, 19.6900616],
                [104.6699023, 19.6882635],
                [104.6707713, 19.6873745],
                [104.6713185, 19.6858391],
                [104.6709859, 19.6849703],
                [104.6709729, 19.6848527],
                [104.6709848, 19.6847282],
                [104.6710254, 19.6846022],
                [104.671056, 19.6845429],
                [104.6710931, 19.6844869],
                [104.6714687, 19.6840208],
                [104.6715345, 19.6839148],
                [104.6715585, 19.6838577],
                [104.671576, 19.6837985],
                [104.6715913, 19.6836801],
                [104.6715546, 19.6832025],
                [104.6715543, 19.6830633],
                [104.6715445, 19.6829245],
                [104.6715251, 19.6827865],
                [104.6714962, 19.68265],
                [104.6714155, 19.6823963],
                [104.6713086, 19.6821657],
                [104.6712446, 19.6820553],
                [104.6710964, 19.681846],
                [104.6710128, 19.6817478],
                [104.6705366, 19.6817291],
                [104.6700632, 19.6816771],
                [104.669669, 19.6816078],
                [104.66928, 19.6815155],
                [104.6687436, 19.6812326],
                [104.6681857, 19.6809599],
                [104.6676353, 19.680667],
                [104.6671004, 19.6803497],
                [104.6665777, 19.6800052],
                [104.6660688, 19.6796339],
                [104.6658218, 19.6794396],
                [104.6653432, 19.6790341],
                [104.6650465, 19.6787634],
                [104.6649856, 19.6786995],
                [104.6648785, 19.6785606],
                [104.6648326, 19.6784858],
                [104.6647578, 19.6783282],
                [104.6649423, 19.6780344],
                [104.6650956, 19.677725],
                [104.6652145, 19.6774093],
                [104.6652996, 19.6770907],
                [104.6654574, 19.6768708],
                [104.6656261, 19.6765938],
                [104.6657925, 19.676255],
                [104.6658902, 19.676015],
                [104.6659084, 19.6759468],
                [104.6659171, 19.6758769],
                [104.6659163, 19.6758066],
                [104.6659058, 19.675737],
                [104.6658601, 19.6756103],
                [104.6658253, 19.6755518],
                [104.6657342, 19.6754491],
                [104.6653211, 19.6751965],
                [104.6647218, 19.6751867],
                [104.6644262, 19.6751554],
                [104.66428, 19.675129],
                [104.6640043, 19.6750585],
                [104.6637483, 19.674967],
                [104.663624, 19.6749128],
                [104.6635026, 19.674853],
                [104.663465, 19.6744893],
                [104.6632834, 19.6744217],
                [104.6631057, 19.6743452],
                [104.6629325, 19.6742601],
                [104.6627642, 19.6741666],
                [104.6624684, 19.6739732],
                [104.662216, 19.6737735],
                [104.6620971, 19.673666],
                [104.6615338, 19.6731002],
                [104.6608365, 19.6730901],
                [104.6602356, 19.672878],
                [104.6602678, 19.6716354],
                [104.6601927, 19.6701604],
                [104.6609759, 19.6693117],
                [104.6616787, 19.6685136],
                [104.6617172, 19.6684641],
                [104.6617474, 19.6684097],
                [104.6617687, 19.6683517],
                [104.6617806, 19.6682913],
                [104.6617764, 19.6681748],
                [104.6617377, 19.668064],
                [104.661425, 19.667812],
                [104.6611191, 19.6675527],
                [104.6608204, 19.6672861],
                [104.6603798, 19.6668671],
                [104.6599567, 19.6664324],
                [104.6600362, 19.666141],
                [104.6600663, 19.6659933],
                [104.6601069, 19.6656949],
                [104.6601212, 19.6653817],
                [104.6601176, 19.6652249],
                [104.6600913, 19.6648791],
                [104.6601137, 19.6646903],
                [104.660139, 19.6645983],
                [104.6602163, 19.6644229],
                [104.6603193, 19.6642736],
                [104.660439, 19.6641518],
                [104.6605064, 19.6640983],
                [104.6606541, 19.6640077],
                [104.6606752, 19.6636492],
                [104.6606862, 19.6632903],
                [104.6606804, 19.6626301],
                [104.6606648, 19.6623002],
                [104.6606862, 19.6599411],
                [104.6606564, 19.6598349],
                [104.660629, 19.6597863],
                [104.6605521, 19.6597037],
                [104.6604631, 19.6596511],
                [104.6603622, 19.6596235],
                [104.659855, 19.6596278],
                [104.6594537, 19.6596046],
                [104.6590518, 19.6595527],
                [104.658851, 19.6595158],
                [104.6584547, 19.6594208],
                [104.6568346, 19.6588146],
                [104.6564553, 19.6586973],
                [104.6562704, 19.6586264],
                [104.6560891, 19.6585476],
                [104.6557673, 19.6583827],
                [104.6554881, 19.6582108],
                [104.655354, 19.6581174],
                [104.6548819, 19.6580871],
                [104.6544673, 19.6583265],
                [104.6540388, 19.6585432],
                [104.6535503, 19.6587557],
                [104.6532768, 19.6588597],
                [104.6529997, 19.6589548],
                [104.6523366, 19.6591501],
                [104.6522345, 19.6591735],
                [104.6521306, 19.6591878],
                [104.6520257, 19.6591928],
                [104.6519208, 19.6591884],
                [104.6517398, 19.6591583],
                [104.6516519, 19.6591328],
                [104.6514843, 19.6590617],
                [104.6514058, 19.6590166],
                [104.650923, 19.6583801],
                [104.650689, 19.6581715],
                [104.6504799, 19.6579407],
                [104.650298, 19.6576902],
                [104.650218, 19.6575584],
                [104.6501455, 19.6574229],
                [104.6499719, 19.65704],
                [104.6499041, 19.6569241],
                [104.6498279, 19.6568129],
                [104.6497436, 19.656707],
                [104.6496516, 19.6566069],
                [104.6494596, 19.6564361],
                [104.6492455, 19.6562906],
                [104.6491313, 19.656228],
                [104.6461916, 19.6550964],
                [104.6462294, 19.6546793],
                [104.6462345, 19.65447],
                [104.6462188, 19.6540748],
                [104.6461701, 19.6536819],
                [104.6461611, 19.6535533],
                [104.6461658, 19.6532957],
                [104.6462006, 19.6530401],
                [104.6462291, 19.652914],
                [104.6463121, 19.6527057],
                [104.6463686, 19.6524896],
                [104.6463968, 19.6522833],
                [104.6464008, 19.6520754],
                [104.6467763, 19.6518278],
                [104.646871, 19.6517217],
                [104.6469099, 19.6516627],
                [104.6469684, 19.6515372],
                [104.6470007, 19.6514053],
                [104.647007, 19.6513378],
                [104.647007, 19.6510448],
                [104.6476216, 19.6505801],
                [104.6477321, 19.6504662],
                [104.6477799, 19.6504035],
                [104.6478655, 19.6502551],
                [104.6479265, 19.6500808],
                [104.647952, 19.6498989],
                [104.6479404, 19.6491907],
                [104.6485466, 19.6485541],
                [104.648509, 19.6479681],
                [104.6486853, 19.6479063],
                [104.6488653, 19.6478767],
                [104.6490426, 19.6478775],
                [104.6493051, 19.6479213],
                [104.6493941, 19.647926],
                [104.6494832, 19.6479213],
                [104.6495712, 19.6479075],
                [104.6497214, 19.6478608],
                [104.6497921, 19.6478273],
                [104.6499215, 19.6477416],
                [104.6499789, 19.6476902],
                [104.6499719, 19.6474856],
                [104.6500003, 19.6472826],
                [104.6500635, 19.6470867],
                [104.650162, 19.6468994],
                [104.6502247, 19.6468105],
                [104.6502952, 19.646727],
                [104.6503729, 19.6466493],
                [104.6504574, 19.6465782],
                [104.650548, 19.6465141],
                [104.6507661, 19.6463832],
                [104.6508823, 19.6463008],
                [104.650992, 19.646211],
                [104.6510947, 19.646114],
                [104.6511884, 19.6460119],
                [104.6512744, 19.645904],
                [104.6513522, 19.6457906],
                [104.6516526, 19.6451035],
                [104.6517508, 19.6448972],
                [104.6518581, 19.644695],
                [104.6519744, 19.6444973],
                [104.6521812, 19.6441875],
                [104.65241, 19.6438917],
                [104.6525323, 19.6437496],
                [104.6523176, 19.6435507],
                [104.6522202, 19.6434425],
                [104.6520469, 19.6432106],
                [104.6519046, 19.6429606],
                [104.6518457, 19.6428301],
                [104.6517189, 19.6425068],
                [104.6515667, 19.6421935],
                [104.6513819, 19.6418792],
                [104.6512798, 19.6417273],
                [104.6510571, 19.6414356],
                [104.651202, 19.6414237],
                [104.6513468, 19.6414356],
                [104.6515086, 19.6414788],
                [104.6516579, 19.6415518],
                [104.6517242, 19.6415708],
                [104.6517922, 19.6415827],
                [104.6519305, 19.6415844],
                [104.6520656, 19.6415569],
                [104.6521752, 19.6415075],
                [104.6522658, 19.6414314],
                [104.6523017, 19.6413851],
                [104.6523484, 19.6412893],
                [104.6523674, 19.6411853],
                [104.652366, 19.6411325],
                [104.6522534, 19.6405414],
                [104.6521917, 19.6402619],
                [104.6521594, 19.639978],
                [104.6521544, 19.6398353],
                [104.6521668, 19.6395492],
                [104.6522093, 19.6392647],
                [104.6522815, 19.6389855],
                [104.6523665, 19.6387414],
                [104.6523966, 19.6386319],
                [104.6524325, 19.6384082],
                [104.6524358, 19.6381819],
                [104.6524236, 19.6380582],
                [104.6524015, 19.6379356],
                [104.6523698, 19.637815],
                [104.6523285, 19.6376969],
                [104.6523178, 19.6369289],
                [104.6529937, 19.6363631],
                [104.652921, 19.6362148],
                [104.6528394, 19.6360707],
                [104.6527491, 19.6359314],
                [104.6526503, 19.6357972],
                [104.652472, 19.6355904],
                [104.6523752, 19.6354931],
                [104.6522736, 19.6354003],
                [104.652059, 19.6352236],
                [104.651957, 19.6351284],
                [104.6518622, 19.6350269],
                [104.6517749, 19.6349196],
                [104.6516324, 19.6347037],
                [104.6515265, 19.6344876],
                [104.6514487, 19.6342612],
                [104.6512771, 19.6330991],
                [104.6512651, 19.6329803],
                [104.6512151, 19.6327464],
                [104.6511772, 19.6326325],
                [104.6510765, 19.6324135],
                [104.6510143, 19.6323095],
                [104.6508752, 19.632124],
                [104.6507201, 19.6319655],
                [104.6506349, 19.6318935],
                [104.6504509, 19.6317653],
                [104.6505316, 19.6313264],
                [104.6505797, 19.6311084],
                [104.6506892, 19.6306828],
                [104.6507513, 19.6304718],
                [104.6492064, 19.6296937],
                [104.6484768, 19.6296027],
                [104.6485978, 19.6289949],
                [104.6486699, 19.6286933],
                [104.6488342, 19.6281052],
                [104.6489274, 19.6278141],
                [104.648952, 19.6271614],
                [104.6489762, 19.6269691],
                [104.6490312, 19.6267586],
                [104.649074, 19.6266456],
                [104.6491253, 19.6265357],
                [104.6492064, 19.6263947],
                [104.6492197, 19.6263563],
                [104.6492197, 19.626276],
                [104.6492064, 19.6262376],
                [104.6491609, 19.6261768],
                [104.6491297, 19.6261537],
                [104.6490562, 19.6261265],
                [104.648522, 19.6261122],
                [104.6484714, 19.6261029],
                [104.6483816, 19.6260641],
                [104.6483111, 19.6260041],
                [104.6482837, 19.6259673],
                [104.6480369, 19.625409],
                [104.6476079, 19.6250342],
                [104.6473846, 19.6248561],
                [104.6469211, 19.6245197],
                [104.6466648, 19.6243509],
                [104.6464028, 19.62419],
                [104.6461354, 19.6240373],
                [104.6458628, 19.6238928],
                [104.6455854, 19.6237567],
                [104.6449363, 19.6229179],
                [104.643445, 19.622256],
                [104.6433993, 19.6223554],
                [104.6433216, 19.6225592],
                [104.6432476, 19.6228145],
                [104.6432143, 19.6228522],
                [104.6431931, 19.622865],
                [104.6431446, 19.6228775],
                [104.6427507, 19.6228371],
                [104.6426918, 19.6228182],
                [104.6426367, 19.6227911],
                [104.6425457, 19.6227175],
                [104.6424833, 19.6226256],
                [104.6424005, 19.6223873],
                [104.6423094, 19.6221928],
                [104.6421858, 19.6219953],
                [104.6421146, 19.6219023],
                [104.6418997, 19.621895],
                [104.6414698, 19.6219035],
                [104.6410417, 19.6219427],
                [104.640828, 19.621974],
                [104.6406157, 19.6220129],
                [104.6401965, 19.6221136],
                [104.6399903, 19.6221752],
                [104.639913, 19.6222184],
                [104.6397464, 19.6222814],
                [104.6395696, 19.6223113],
                [104.6393901, 19.6223066],
                [104.6393016, 19.6222914],
                [104.639132, 19.6222358],
                [104.638967, 19.6221621],
                [104.6387905, 19.6221182],
                [104.6386083, 19.6221056],
                [104.6385171, 19.6221113],
                [104.6383383, 19.6221462],
                [104.6382522, 19.6221752],
                [104.6379877, 19.6222912],
                [104.6378507, 19.6223387],
                [104.6375695, 19.6224122],
                [104.6372955, 19.6224547],
                [104.6371641, 19.6224654],
                [104.6369004, 19.6224682],
                [104.6347718, 19.6222749],
                [104.631939, 19.6229269],
                [104.6315354, 19.6230768],
                [104.6299414, 19.6236686],
                [104.6284676, 19.625058],
                [104.626888, 19.6267454],
                [104.6246262, 19.6281827],
                [104.6223662, 19.6287746],
                [104.6191351, 19.6291905],
                [104.6171375, 19.6298824],
                [104.614875, 19.6315682],
                [104.6124002, 19.634099],
                [104.6098758, 19.6354361],
                [104.6076169, 19.6355803],
                [104.604703, 19.6350021],
                [104.60271, 19.6338541],
                [104.6000876, 19.6322571],
                [104.5973581, 19.6315051],
                [104.5941013, 19.6316469],
                [104.5916847, 19.6318404],
                [104.5889263, 19.6321573],
                [104.5868788, 19.6318045],
                [104.5825221, 19.6307006],
                [104.5795831, 19.6297986],
                [104.5769616, 19.6280024],
                [104.5740557, 19.6245396],
                [104.5725895, 19.6228455],
                [104.5714388, 19.6210526],
                [104.5703946, 19.6187131],
                [104.569874, 19.6169714],
                [104.5687743, 19.6157755],
                [104.5672257, 19.6154982],
                [104.5658076, 19.6155445],
                [104.5633388, 19.6156877],
                [104.5619071, 19.6162447],
                [104.5606932, 19.6160003],
                [104.5589159, 19.6123509],
                [104.5577669, 19.6102015],
                [104.5571168, 19.6110343],
                [104.5569446, 19.6108985],
                [104.557159, 19.6119102],
                [104.5568365, 19.6119921],
                [104.5559559, 19.6130759],
                [104.5552681, 19.6149572],
                [104.5543557, 19.6157919],
                [104.552596, 19.616631],
                [104.5493453, 19.6173486],
                [104.5464428, 19.6196489],
                [104.5446545, 19.6205891],
                [104.5438137, 19.6207362],
                [104.5427115, 19.6204848],
                [104.5421353, 19.6199363],
                [104.5407226, 19.6180431],
                [104.5389967, 19.6155028],
                [104.537581, 19.6147035],
                [104.5365567, 19.6147755],
                [104.5359786, 19.6148734],
                [104.5349272, 19.6152685],
                [104.53356, 19.6158119],
                [104.5326664, 19.616108],
                [104.5319312, 19.616106],
                [104.5312486, 19.6160546],
                [104.5297263, 19.6157523],
                [104.5278894, 19.6153497],
                [104.5267863, 19.6153965],
                [104.5257873, 19.6157916],
                [104.5246814, 19.616833],
                [104.5222863, 19.6186913],
                [104.5196021, 19.6205737],
                [104.5169717, 19.6220584],
                [104.5138692, 19.6232932],
                [104.510979, 19.6237826],
                [104.5083512, 19.6243721],
                [104.5068264, 19.624915],
                [104.5059308, 19.6258076],
                [104.5057183, 19.6266025],
                [104.5055861, 19.6275974],
                [104.5055301, 19.6280192],
                [104.5056819, 19.6299092],
                [104.505679, 19.6308539],
                [104.5055193, 19.6315497],
                [104.5046234, 19.6325417],
                [104.5031475, 19.6342282],
                [104.5008527, 19.6374788],
                [104.499692, 19.6391165],
                [104.4991623, 19.6405571],
                [104.4991579, 19.6419494],
                [104.4994155, 19.643591],
                [104.4995685, 19.6450832],
                [104.4992211, 19.6469966],
                [104.4985334, 19.6485361],
                [104.4971636, 19.6498251],
                [104.4947438, 19.6509123],
                [104.4932186, 19.6515047],
                [104.4924288, 19.6520992],
                [104.491795, 19.6531914],
                [104.4911079, 19.6545319],
                [104.4897911, 19.6556222],
                [104.4876356, 19.656163],
                [104.4855597, 19.6564306],
                [104.482408, 19.6563717],
                [104.4793608, 19.6564624],
                [104.4764178, 19.6568517],
                [104.474369, 19.6568457],
                [104.4731615, 19.6566433],
                [104.4695157, 19.6550662],
                [104.4658413, 19.65421],
                [104.4620071, 19.6540494],
                [104.4587485, 19.6545369],
                [104.4536748, 19.6558392],
                [104.4503078, 19.6573207],
                [104.4491485, 19.6583613],
                [104.4485136, 19.659702],
                [104.4483112, 19.6607981],
                [104.4481924, 19.6614414],
                [104.4478403, 19.664548],
                [104.4471487, 19.6670818],
                [104.4466689, 19.6691191],
                [104.445925, 19.6715532],
                [104.4453946, 19.6729936],
                [104.4449732, 19.6733404],
                [104.4437905, 19.6735107],
                [104.4409534, 19.6735517],
                [104.4394294, 19.6736463],
                [104.4366968, 19.6738367],
                [104.4343328, 19.6737795],
                [104.4324424, 19.673525],
                [104.430868, 19.6730227],
                [104.4293984, 19.6726203],
                [104.4276904, 19.6727889],
                [104.4264796, 19.6734811],
                [104.4260574, 19.6740267],
                [104.4252543, 19.6745964],
                [104.4249873, 19.6757888],
                [104.4242396, 19.6766664],
                [104.4237648, 19.6772119],
                [104.4229344, 19.6784017],
                [104.4214075, 19.6793415],
                [104.4207812, 19.680275],
                [104.4205682, 19.68107],
                [104.4205902, 19.6822385],
                [104.4205859, 19.6834319],
                [104.4208981, 19.6842782],
                [104.4212209, 19.6857115],
                [104.421671, 19.6864953],
                [104.4219282, 19.6880376],
                [104.4224569, 19.6891562],
                [104.4223496, 19.6897525],
                [104.4227593, 19.6908065],
                [104.4220852, 19.6923079],
                [104.4206214, 19.6921456],
                [104.4200962, 19.692834],
                [104.4196888, 19.6935027],
                [104.4198921, 19.6938113],
                [104.4206231, 19.6951065],
                [104.4209894, 19.6954415],
                [104.4215643, 19.6962887],
                [104.4218754, 19.6974333],
                [104.4229025, 19.6979802],
                [104.4228715, 19.6992979],
                [104.4231995, 19.7008967],
                [104.4209256, 19.7024775],
                [104.4191744, 19.7028653],
                [104.4154921, 19.7039969],
                [104.4127077, 19.7049324],
                [104.4093684, 19.7049755],
                [104.4081072, 19.7049713],
                [104.4076611, 19.7048367],
                [104.4068741, 19.7044862],
                [104.4059818, 19.7042349],
                [104.4044582, 19.7041802],
                [104.4034077, 19.7040276],
                [104.4025165, 19.703478],
                [104.4022572, 19.7025331],
                [104.4022607, 19.7015891],
                [104.4024227, 19.7004468],
                [104.4029018, 19.6987591],
                [104.4031171, 19.6973685],
                [104.4026986, 19.6968703],
                [104.4021745, 19.6965207],
                [104.401072, 19.6962686],
                [104.3992335, 19.6961134],
                [104.3971838, 19.6962556],
                [104.3948702, 19.696695],
                [104.3923961, 19.6978792],
                [104.3912164, 19.6983354],
                [104.3888833, 19.6978015],
                [104.3881962, 19.6979079],
                [104.3873367, 19.698231],
                [104.3865922, 19.6983916],
                [104.3859063, 19.6981718],
                [104.3853364, 19.697572],
                [104.3847661, 19.6970808],
                [104.3839661, 19.6968064],
                [104.3833368, 19.6967498],
                [104.3827092, 19.6962585],
                [104.3816832, 19.6952223],
                [104.3809413, 19.6947306],
                [104.3800259, 19.6947003],
                [104.3785376, 19.6948582],
                [104.3767644, 19.694689],
                [104.3746478, 19.6945185],
                [104.3730977, 19.6947093],
                [104.3729304, 19.69473],
                [104.3706398, 19.6952111],
                [104.3688076, 19.6955036],
                [104.3664052, 19.6952776],
                [104.3651478, 19.6948928],
                [104.3641207, 19.6941827],
                [104.3630945, 19.693255],
                [104.3621253, 19.6923276],
                [104.3610414, 19.6915085],
                [104.3597272, 19.6910689],
                [104.3585262, 19.6909016],
                [104.3573809, 19.6911421],
                [104.3561775, 19.6915726],
                [104.3544584, 19.6922186],
                [104.3529698, 19.6924306],
                [104.3513111, 19.6922616],
                [104.3494254, 19.691657],
                [104.3477693, 19.6908356],
                [104.3465145, 19.6898528],
                [104.3455461, 19.6887894],
                [104.3446351, 19.6876992],
                [104.3434946, 19.686771],
                [104.3421242, 19.6860594],
                [104.3411549, 19.6852406],
                [104.3401876, 19.6839327],
                [104.3397339, 19.6829527],
                [104.3395093, 19.681892],
                [104.3390586, 19.6802056],
                [104.3385486, 19.6790079],
                [104.337638, 19.6778089],
                [104.3362696, 19.6766624],
                [104.3348429, 19.6757875],
                [104.3329577, 19.6751011],
                [104.3312445, 19.674334],
                [104.3299887, 19.6736227],
                [104.3283325, 19.67291],
                [104.3267904, 19.6723063],
                [104.3256496, 19.6714868],
                [104.324395, 19.6705037],
                [104.3235398, 19.6697939],
                [104.3224558, 19.6690833],
                [104.3214278, 19.6686447],
                [104.3204596, 19.6667518],
                [104.3186967, 19.6708283],
                [104.318767, 19.6728507],
                [104.3207494, 19.6755462],
                [104.3226648, 19.680735],
                [104.3226617, 19.6820833],
                [104.3226477, 19.6837759],
                [104.3218893, 19.6856084],
                [104.3207839, 19.6865573],
                [104.3165783, 19.6903111],
                [104.3146155, 19.6918299],
                [104.3081482, 19.6950022],
                [104.3041756, 19.697095],
                [104.3018569, 19.6972557],
                [104.300757, 19.697074],
                [104.2993339, 19.6967979],
                [104.2975787, 19.6964527],
                [104.2952544, 19.6959927],
                [104.2934989, 19.6957153],
                [104.2919326, 19.6956189],
                [104.2913761, 19.6961184],
                [104.2901932, 19.6948774],
                [104.2895144, 19.6945294],
                [104.2881742, 19.6941411],
                [104.2868689, 19.6940446],
                [104.2858219, 19.6942687],
                [104.2840398, 19.695078],
                [104.2834268, 19.695217],
                [104.2827484, 19.6951314],
                [104.2814871, 19.695412],
                [104.279832, 19.6955098],
                [104.2789668, 19.6959565],
                [104.2777132, 19.6960699],
                [104.2766698, 19.6962925],
                [104.2754839, 19.6965077],
                [104.2739526, 19.6961837],
                [104.2716042, 19.6963267],
                [104.2699805, 19.6967626],
                [104.2686604, 19.6973063],
                [104.2674915, 19.6982321],
                [104.2667077, 19.6990855],
                [104.2664429, 19.699795],
                [104.2660952, 19.7008504],
                [104.265244, 19.701937],
                [104.2643106, 19.7024252],
                [104.2638317, 19.7033793],
                [104.2630287, 19.7039393],
                [104.2621025, 19.7040361],
                [104.2611692, 19.7039067],
                [104.2596442, 19.7032319],
                [104.2581338, 19.7027963],
                [104.2571949, 19.7030728],
                [104.2555517, 19.7032195],
                [104.254649, 19.7031602],
                [104.253891, 19.703217],
                [104.2529364, 19.7036887],
                [104.252531, 19.7040019],
                [104.2519162, 19.7044769],
                [104.249691, 19.7052198],
                [104.2484615, 19.7052264],
                [104.2467684, 19.7056134],
                [104.2451546, 19.7057748],
                [104.2442566, 19.705468],
                [104.243536, 19.7051288],
                [104.2427193, 19.7049537],
                [104.2412245, 19.7053837],
                [104.2404393, 19.7054164],
                [104.2394614, 19.7057821],
                [104.2388175, 19.7058982],
                [104.2383901, 19.7061786],
                [104.2376102, 19.7071113],
                [104.2366992, 19.7075816],
                [104.235914, 19.7076095],
                [104.2338698, 19.7070986],
                [104.232279, 19.7066943],
                [104.2309525, 19.7060831],
                [104.2292967, 19.7057193],
                [104.2284065, 19.705667],
                [104.2274673, 19.705536],
                [104.2258878, 19.7055621],
                [104.2247936, 19.7056691],
                [104.2241437, 19.7054895],
                [104.223755, 19.7054835],
                [104.223116, 19.7057149],
                [104.2226584, 19.7056861],
                [104.2203847, 19.7050433],
                [104.2189625, 19.7045528],
                [104.2174441, 19.7038118],
                [104.2157511, 19.7034183],
                [104.2144298, 19.7029298],
                [104.2134813, 19.7029428],
                [104.2120129, 19.7028117],
                [104.2101451, 19.7030431],
                [104.209689, 19.7029395],
                [104.2090548, 19.7023705],
                [104.2084933, 19.7010753],
                [104.2080585, 19.7005977],
                [104.2066133, 19.6998946],
                [104.2056477, 19.6993331],
                [104.2042421, 19.6980229],
                [104.2032712, 19.6974442],
                [104.2027847, 19.697007],
                [104.2023843, 19.6961274],
                [104.2018034, 19.6955121],
                [104.2018851, 19.6948402],
                [104.2018688, 19.6943654],
                [104.2014528, 19.6936008],
                [104.199948, 19.6925861],
                [104.198879, 19.6924743],
                [104.1985386, 19.6926345],
                [104.1976867, 19.6935736],
                [104.197105, 19.6940049],
                [104.1955839, 19.6945527],
                [104.1949587, 19.6949429],
                [104.1941328, 19.6951837],
                [104.1933371, 19.6951328],
                [104.1923971, 19.6938723],
                [104.1921629, 19.69374],
                [104.1913738, 19.6937025],
                [104.1877961, 19.694367],
                [104.1858242, 19.6943004],
                [104.1842602, 19.6940231],
                [104.1834467, 19.6939232],
                [104.1822171, 19.6934915],
                [104.1813222, 19.693498],
                [104.1806667, 19.6934226],
                [104.1800274, 19.6931288],
                [104.179432, 19.6920007],
                [104.1791593, 19.6910596],
                [104.1786169, 19.6900252],
                [104.1779777, 19.6892313],
                [104.1766274, 19.6883621],
                [104.1760235, 19.6880442],
                [104.1753318, 19.6882312],
                [104.1741973, 19.6889475],
                [104.1738494, 19.6894172],
                [104.1736374, 19.6900658],
                [104.1737243, 19.6910969],
                [104.1742511, 19.691851],
                [104.1742511, 19.6922338],
                [104.1739595, 19.6925397],
                [104.1718625, 19.6933389],
                [104.1706756, 19.6934002],
                [104.1695987, 19.6930666],
                [104.1690776, 19.6931493],
                [104.1676935, 19.694258],
                [104.1662447, 19.6952548],
                [104.1650238, 19.696464],
                [104.163903, 19.6968414],
                [104.1631061, 19.6965556],
                [104.1622081, 19.6956902],
                [104.1618517, 19.695453],
                [104.1613259, 19.6955545],
                [104.1606761, 19.6953605],
                [104.1601823, 19.6943474],
                [104.1595045, 19.6918209],
                [104.1587182, 19.6902422],
                [104.1578598, 19.6882971],
                [104.1574099, 19.6875142],
                [104.1567016, 19.6868803],
                [104.1563558, 19.6861876],
                [104.1562319, 19.6851236],
                [104.1565084, 19.6844494],
                [104.1565359, 19.6840934],
                [104.1567254, 19.6834258],
                [104.1568712, 19.6823534],
                [104.1572121, 19.6799564],
                [104.1571376, 19.6791851],
                [104.1567708, 19.6781822],
                [104.1568584, 19.677213],
                [104.1573505, 19.6761935],
                [104.1575927, 19.6750922],
                [104.157979, 19.6734685],
                [104.1577364, 19.6723392],
                [104.1577773, 19.6715439],
                [104.1572026, 19.6706945],
                [104.1559592, 19.6695362],
                [104.1545597, 19.6681944],
                [104.1539871, 19.6674645],
                [104.1530689, 19.6666129],
                [104.1523722, 19.6661082],
                [104.1492681, 19.665738],
                [104.1477905, 19.6658167],
                [104.147252, 19.6657893],
                [104.1458479, 19.6653598],
                [104.1447504, 19.6647238],
                [104.1437926, 19.6642761],
                [104.1416913, 19.6630443],
                [104.1404388, 19.6627222],
                [104.1369748, 19.6616914],
                [104.1345828, 19.6627314],
                [104.133634, 19.6626366],
                [104.1329233, 19.6624077],
                [104.1323555, 19.6620668],
                [104.1316952, 19.661297],
                [104.1309376, 19.6609325],
                [104.1300357, 19.6609732],
                [104.1294639, 19.6613764],
                [104.1289369, 19.6622757],
                [104.1283162, 19.6629491],
                [104.1275069, 19.6633961],
                [104.1262721, 19.6635254],
                [104.125323, 19.6634756],
                [104.1241383, 19.6631092],
                [104.1230012, 19.6627203],
                [104.1217209, 19.6624886],
                [104.1205346, 19.6624376],
                [104.1195388, 19.6622524],
                [104.1187342, 19.6618426],
                [104.1172648, 19.6614746],
                [104.1164109, 19.6613802],
                [104.1155086, 19.661466],
                [104.1147012, 19.6615523],
                [104.1131807, 19.6618378],
                [104.1115189, 19.6619199],
                [104.1099052, 19.6619119],
                [104.1070579, 19.6618075],
                [104.1054431, 19.6619798],
                [104.104872, 19.6622476],
                [104.1041569, 19.6628076],
                [104.1031078, 19.6636593],
                [104.1024409, 19.6641069],
                [104.1017281, 19.6642386],
                [104.1006839, 19.6642334],
                [104.0995438, 19.6644081],
                [104.0986397, 19.6648094],
                [104.0976386, 19.6655709],
                [104.0968289, 19.6660628],
                [104.0957832, 19.6663282],
                [104.0942623, 19.6666587],
                [104.0928854, 19.6667419],
                [104.0917455, 19.6668715],
                [104.0910796, 19.6670936],
                [104.0903651, 19.6675409],
                [104.0892207, 19.6684821],
                [104.0887049, 19.6688902],
                [104.0883147, 19.669199],
                [104.0876002, 19.6696463],
                [104.0866008, 19.6700922],
                [104.0844606, 19.6708253],
                [104.0836029, 19.6714071],
                [104.0831731, 19.6718559],
                [104.0825496, 19.6729801],
                [104.0815441, 19.674508],
                [104.07992, 19.676281],
                [104.0791569, 19.6769083],
                [104.0783953, 19.6772652],
                [104.0771588, 19.6776646],
                [104.0755878, 19.6784683],
                [104.0746329, 19.6794104],
                [104.0739629, 19.6803539],
                [104.0733414, 19.6811172],
                [104.0727209, 19.6817003],
                [104.0719109, 19.6822371],
                [104.0704837, 19.6827709],
                [104.069262, 19.6830749],
                [104.0684453, 19.6826827],
                [104.0682111, 19.6821404],
                [104.0682134, 19.6817346],
                [104.0687393, 19.6811061],
                [104.0690267, 19.6806566],
                [104.0690765, 19.680251],
                [104.0689367, 19.6797994],
                [104.0687016, 19.6793923],
                [104.0686568, 19.678941],
                [104.0688487, 19.6785813],
                [104.0690883, 19.6782218],
                [104.0690906, 19.677816],
                [104.0690924, 19.6775004],
                [104.0692841, 19.6771857],
                [104.0697139, 19.6767369],
                [104.0699536, 19.6763323],
                [104.0700034, 19.6759268],
                [104.0698164, 19.6754298],
                [104.0693918, 19.6749766],
                [104.0690147, 19.6745238],
                [104.0689217, 19.6741625],
                [104.0687828, 19.6735756],
                [104.0688346, 19.6728318],
                [104.068743, 19.6722452],
                [104.0685083, 19.671793],
                [104.0679408, 19.6714292],
                [104.067564, 19.6709313],
                [104.0675193, 19.670435],
                [104.0675723, 19.6694883],
                [104.0676269, 19.6682711],
                [104.0673929, 19.6676837],
                [104.0668746, 19.6670271],
                [104.0665939, 19.6663041],
                [104.0663626, 19.6652659],
                [104.0657548, 19.6636844],
                [104.0651442, 19.6625538],
                [104.0645784, 19.6619196],
                [104.0637275, 19.6613063],
                [104.0634458, 19.6608089],
                [104.0631649, 19.660131],
                [104.0628358, 19.6595881],
                [104.0622688, 19.6591343],
                [104.0615124, 19.6586343],
                [104.0610411, 19.6580456],
                [104.0605225, 19.6574566],
                [104.0599078, 19.6570476],
                [104.0593889, 19.6565037],
                [104.059108, 19.6558259],
                [104.0584018, 19.6548527],
                [104.0575996, 19.6540368],
                [104.0566062, 19.6534904],
                [104.0561339, 19.6530821],
                [104.055852, 19.6525846],
                [104.0557617, 19.6518176],
                [104.0559122, 19.6504204],
                [104.0561079, 19.6494294],
                [104.0564924, 19.6485972],
                [104.0573991, 19.6477452],
                [104.0583043, 19.6471637],
                [104.0594944, 19.6465386],
                [104.0599711, 19.6461803],
                [104.0607826, 19.6453729],
                [104.0615464, 19.6446103],
                [104.0620225, 19.6443422],
                [104.0627349, 19.6442558],
                [104.0633533, 19.6440335],
                [104.0638777, 19.6436304],
                [104.0644515, 19.6428894],
                [104.0647892, 19.6419442],
                [104.0650815, 19.6406379],
                [104.0654727, 19.6386558],
                [104.0656706, 19.6373044],
                [104.0657701, 19.6364932],
                [104.0656781, 19.6359967],
                [104.0652064, 19.6354982],
                [104.0642631, 19.6345013],
                [104.0640752, 19.6341395],
                [104.0640776, 19.6337336],
                [104.0638897, 19.633417],
                [104.0634174, 19.6330087],
                [104.0626619, 19.6323735],
                [104.0622382, 19.631785],
                [104.0616747, 19.630745],
                [104.0608288, 19.6293201],
                [104.0599849, 19.627557],
                [104.0592831, 19.6258398],
                [104.0586277, 19.6243031],
                [104.0582567, 19.6228356],
                [104.0580723, 19.6218877],
                [104.0581246, 19.6210763],
                [104.0583198, 19.6201304],
                [104.0587523, 19.6192307],
                [104.0593265, 19.618377],
                [104.0595692, 19.6174539],
                [104.0598146, 19.6160572],
                [104.0601095, 19.6143001],
                [104.0603531, 19.6132192],
                [104.0606407, 19.6127246],
                [104.0612607, 19.6121643],
                [104.0618322, 19.6118064],
                [104.0625928, 19.6115849],
                [104.0634012, 19.6112735],
                [104.0642592, 19.6106015],
                [104.0654056, 19.6092997],
                [104.0663132, 19.6082222],
                [104.0667903, 19.6077737],
                [104.0672661, 19.6075507],
                [104.0685498, 19.6071065],
                [104.0692642, 19.6066367],
                [104.0699803, 19.6058737],
                [104.0703649, 19.605019],
                [104.0706084, 19.603938],
                [104.0707151, 19.6018642],
                [104.0707242, 19.6002859],
                [104.0708713, 19.5994524],
                [104.0712553, 19.5986878],
                [104.0716369, 19.5983291],
                [104.0723978, 19.5980173],
                [104.0730655, 19.5974346],
                [104.0739725, 19.5964471],
                [104.0744545, 19.595142],
                [104.0748357, 19.5944914],
                [104.0749815, 19.5942428],
                [104.0755072, 19.5935915],
                [104.0760794, 19.5930984],
                [104.0767477, 19.5923804],
                [104.0775134, 19.5912119],
                [104.078087, 19.5904482],
                [104.0787074, 19.58982],
                [104.0791851, 19.5892363],
                [104.0796642, 19.588427],
                [104.0800946, 19.5878204],
                [104.0804281, 19.5875966],
                [104.0809042, 19.5872834],
                [104.0814287, 19.5868352],
                [104.0822401, 19.5859826],
                [104.0827657, 19.5853088],
                [104.0830057, 19.584814],
                [104.0834381, 19.5838692],
                [104.083988, 19.582792],
                [104.0868164, 19.5811944],
                [104.0908227, 19.5790469],
                [104.0948933, 19.5783476],
                [104.0972731, 19.5773669],
                [104.0998709, 19.5759903],
                [104.1020735, 19.5732668],
                [104.1039641, 19.5712752],
                [104.1048537, 19.5697232],
                [104.1052982, 19.568318],
                [104.1054216, 19.5679282],
                [104.1056091, 19.565871],
                [104.1053868, 19.5635892],
                [104.1046396, 19.5606373],
                [104.1043596, 19.5581659],
                [104.1043156, 19.5554957],
                [104.1043294, 19.5529926],
                [104.1045203, 19.5503235],
                [104.1047095, 19.5469115],
                [104.1053578, 19.546027],
                [104.106942, 19.5438657],
                [104.106452, 19.5413701],
                [104.1060638, 19.5391943],
                [104.1060173, 19.5372647],
                [104.1060846, 19.5354171],
                [104.1062103, 19.5333524],
                [104.105876, 19.5317747],
                [104.1055394, 19.5306317],
                [104.1049156, 19.5297319],
                [104.1042893, 19.529294],
                [104.1039509, 19.5284771],
                [104.1037851, 19.5274436],
                [104.1035597, 19.5268447],
                [104.1029918, 19.5261896],
                [104.1019681, 19.5252607],
                [104.101629, 19.5245524],
                [104.101632, 19.5240089],
                [104.1016365, 19.5231938],
                [104.1015267, 19.5223779],
                [104.1013125, 19.5221949],
                [104.1025143, 19.521045],
                [104.105082, 19.5187629],
                [104.1077483, 19.5166384],
                [104.1090174, 19.5151356],
                [104.1100243, 19.5130658],
                [104.1120312, 19.5101206],
                [104.1131002, 19.5088055],
                [104.1152299, 19.5076842],
                [104.1164611, 19.50703],
                [104.1173952, 19.5060915],
                [104.1182644, 19.5049012],
                [104.1193978, 19.5046513],
                [104.1200474, 19.5040842],
                [104.1201514, 19.5035579],
                [104.1201052, 19.5027436],
                [104.1196568, 19.5014964],
                [104.1187621, 19.5014341],
                [104.1178551, 19.5008073],
                [104.1172248, 19.5005397],
                [104.1157719, 19.4997537],
                [104.1152669, 19.4996076],
                [104.1151167, 19.4993195],
                [104.1149684, 19.4986962],
                [104.1147734, 19.4973546],
                [104.11358, 19.496353],
                [104.1121918, 19.4937655],
                [104.1113648, 19.4925933],
                [104.1100565, 19.4913889],
                [104.1090213, 19.4902696],
                [104.1077081, 19.4899756],
                [104.1064443, 19.4898735],
                [104.1047249, 19.489865],
                [104.103308, 19.4900018],
                [104.1023959, 19.4903921],
                [104.1016866, 19.4905688],
                [104.1003188, 19.4909933],
                [104.0988993, 19.4916092],
                [104.0949536, 19.4945018],
                [104.0913818, 19.4984744],
                [104.0897489, 19.4949443],
                [104.0895791, 19.4888552],
                [104.0881216, 19.4866091],
                [104.0876546, 19.4856608],
                [104.0854909, 19.4836383],
                [104.0840322, 19.4821941],
                [104.0836316, 19.4814737],
                [104.0834836, 19.4808024],
                [104.0836664, 19.4797813],
                [104.0839766, 19.4785846],
                [104.0843855, 19.4778199],
                [104.0844918, 19.4769097],
                [104.0844437, 19.4764781],
                [104.0838403, 19.4758519],
                [104.0832379, 19.4750341],
                [104.0828887, 19.4741696],
                [104.0828898, 19.4736684],
                [104.083175, 19.4731076],
                [104.0831988, 19.4730644],
                [104.0831252, 19.4728893],
                [104.0830461, 19.4727068],
                [104.0830132, 19.4726309],
                [104.0829295, 19.4721945],
                [104.0830331, 19.4714305],
                [104.0824336, 19.4702183],
                [104.0820689, 19.4691623],
                [104.0810295, 19.4682888],
                [104.0795572, 19.466514],
                [104.0754721, 19.4621214],
                [104.0740446, 19.4604964],
                [104.0701682, 19.4579485],
                [104.0672862, 19.4555772],
                [104.0659791, 19.4547135],
                [104.0654377, 19.4544536],
                [104.0640594, 19.4541755],
                [104.063201, 19.4539567],
                [104.0629765, 19.4536554],
                [104.0629805, 19.4529693],
                [104.0632094, 19.4524988],
                [104.063845, 19.4521162],
                [104.0649772, 19.4519077],
                [104.0665616, 19.4517443],
                [104.0695039, 19.4514593],
                [104.0710431, 19.4512957],
                [104.0719943, 19.4510862],
                [104.0731297, 19.4503202],
                [104.0734053, 19.4495926],
                [104.0727578, 19.4479087],
                [104.0727668, 19.4463205],
                [104.0721249, 19.4454239],
                [104.071059, 19.4432347],
                [104.0704216, 19.4415441],
                [104.0701671, 19.4401751],
                [104.0699456, 19.4393593],
                [104.0696872, 19.437967],
                [104.0693246, 19.4352932],
                [104.0694721, 19.4332358],
                [104.0698904, 19.4312655],
                [104.0700586, 19.4295297],
                [104.0699343, 19.4275139],
                [104.0697217, 19.4251544],
                [104.0697097, 19.4232892],
                [104.0698165, 19.4204168],
                [104.0698755, 19.417973],
                [104.0697014, 19.4172318],
                [104.0692318, 19.4166091],
                [104.0685626, 19.4169364],
                [104.0682591, 19.4169949],
                [104.0675919, 19.4169642],
                [104.0671478, 19.4171076],
                [104.0666603, 19.4175158],
                [104.0663875, 19.4175845],
                [104.0659827, 19.4174244],
                [104.0653375, 19.4169666],
                [104.064475, 19.4159998],
                [104.0642794, 19.4159312],
                [104.0639541, 19.4159393],
                [104.0630134, 19.4162952],
                [104.061377, 19.4171078],
                [104.0608397, 19.417268],
                [104.05969, 19.417213],
                [104.0589888, 19.4172583],
                [104.0580076, 19.4174995],
                [104.0572087, 19.4176354],
                [104.0557213, 19.4177501],
                [104.054006, 19.4182078],
                [104.0536405, 19.4185093],
                [104.053341, 19.4189732],
                [104.0530811, 19.4199998],
                [104.0528724, 19.4205551],
                [104.0526362, 19.4220447],
                [104.0524129, 19.4225863],
                [104.051849, 19.4237882],
                [104.0517803, 19.4244776],
                [104.0518881, 19.4248506],
                [104.0517881, 19.4252818],
                [104.0515713, 19.4256727],
                [104.0512326, 19.4258699],
                [104.0507857, 19.4259462],
                [104.0498233, 19.425646],
                [104.0495724, 19.4255285],
                [104.0491904, 19.4251101],
                [104.0486931, 19.424851],
                [104.0481894, 19.4241884],
                [104.0471209, 19.4232768],
                [104.0469318, 19.4231773],
                [104.0465457, 19.4231891],
                [104.0458932, 19.4230976],
                [104.0452129, 19.4227999],
                [104.0434267, 19.4215698],
                [104.0425911, 19.4212449],
                [104.0412919, 19.4203772],
                [104.0410007, 19.4202755],
                [104.0399389, 19.4201735],
                [104.0382284, 19.4198973],
                [104.0367682, 19.4195546],
                [104.0365038, 19.4193989],
                [104.0359728, 19.4187059],
                [104.0353022, 19.4182312],
                [104.0348639, 19.4180168],
                [104.0345838, 19.4179881],
                [104.0342402, 19.418101],
                [104.0339586, 19.4180551],
                [104.0337336, 19.4178089],
                [104.0336953, 19.4174768],
                [104.0337549, 19.4167642],
                [104.0336103, 19.4161645],
                [104.0333763, 19.4158698],
                [104.033009, 19.4155913],
                [104.0316217, 19.4150953],
                [104.0313322, 19.4146015],
                [104.0309541, 19.4144163],
                [104.0303389, 19.4143692],
                [104.0292647, 19.4146704],
                [104.0289864, 19.4149404],
                [104.0289176, 19.4151882],
                [104.0288542, 19.4155017],
                [104.0286009, 19.4156859],
                [104.0278325, 19.4157884],
                [104.0262049, 19.4156509],
                [104.0255306, 19.415702],
                [104.0240516, 19.416086],
                [104.0232083, 19.4160105],
                [104.0228446, 19.4158138],
                [104.0226404, 19.4153424],
                [104.0222483, 19.4137619],
                [104.0219629, 19.4129228],
                [104.0213728, 19.4098023],
                [104.0207699, 19.4086073],
                [104.0196631, 19.4072978],
                [104.0193537, 19.4069639],
                [104.0190253, 19.4068091],
                [104.0186306, 19.4067943],
                [104.0182186, 19.406888],
                [104.0172221, 19.4072806],
                [104.0169536, 19.4073505],
                [104.0167176, 19.4073909],
                [104.0163206, 19.4073758],
                [104.0159014, 19.4072095],
                [104.0153757, 19.4067079],
                [104.014894, 19.405681],
                [104.0151058, 19.4045048],
                [104.0151201, 19.4035425],
                [104.0147604, 19.4027369],
                [104.0145294, 19.4024197],
                [104.0143154, 19.4022209],
                [104.0139226, 19.4021052],
                [104.0126599, 19.4018465],
                [104.010348, 19.4017758],
                [104.0066486, 19.4021558],
                [104.0043348, 19.403449],
                [103.9977981, 19.4051143],
                [103.9927572, 19.4051143],
                [103.9897355, 19.3997957],
                [103.9873047, 19.398926],
                [103.9856074, 19.3994489],
                [103.9764295, 19.3984249],
                [103.9749222, 19.3969948],
                [103.9747208, 19.393801],
                [103.9708795, 19.3907424],
                [103.9674735, 19.3905411],
                [103.9662072, 19.3901895],
                [103.9654217, 19.3890495],
                [103.9642247, 19.388491],
                [103.9630076, 19.3873699],
                [103.9627045, 19.3853926],
                [103.9592496, 19.3843994],
                [103.9579568, 19.3838762],
                [103.9569923, 19.3828651],
                [103.9567842, 19.3819948],
                [103.9573163, 19.3813804],
                [103.9584943, 19.3811446],
                [103.9592325, 19.3811193],
                [103.9597657, 19.3807155],
                [103.9598269, 19.3785041],
                [103.9611208, 19.3755082],
                [103.95927, 19.3717026],
                [103.9596308, 19.3700214],
                [103.9596949, 19.3697228],
                [103.9596495, 19.3692321],
                [103.959175, 19.3684176],
                [103.9580328, 19.3675172],
                [103.9561179, 19.3645],
                [103.9555852, 19.3639806],
                [103.9552135, 19.3638613],
                [103.9547707, 19.363902],
                [103.9544421, 19.3640719],
                [103.9535666, 19.3647004],
                [103.9531337, 19.3646963],
                [103.9526514, 19.3644899],
                [103.9523593, 19.364184],
                [103.9521858, 19.3636387],
                [103.9521502, 19.3628431],
                [103.9526342, 19.3615323],
                [103.9526827, 19.3610122],
                [103.9524492, 19.3600563],
                [103.9525035, 19.3596865],
                [103.9527508, 19.3593269],
                [103.9529981, 19.3587571],
                [103.95304, 19.3583488],
                [103.9528303, 19.3578726],
                [103.9524068, 19.3574253],
                [103.9518286, 19.3571022],
                [103.9511569, 19.3569379],
                [103.9493346, 19.3566183],
                [103.948221, 19.3562216],
                [103.946453, 19.3549247],
                [103.9443475, 19.3529924],
                [103.93726, 19.3481979],
                [103.9363674, 19.3472489],
                [103.9356956, 19.3469353],
                [103.9347153, 19.3464778],
                [103.9330709, 19.3453275],
                [103.9305512, 19.343982],
                [103.9288417, 19.3435556],
                [103.9272928, 19.3430403],
                [103.9248404, 19.3425333],
                [103.9223939, 19.342321],
                [103.9203541, 19.3423052],
                [103.91967, 19.3421295],
                [103.9191731, 19.3417476],
                [103.9186212, 19.3409958],
                [103.9166338, 19.3353974],
                [103.9154647, 19.3335874],
                [103.9131576, 19.3322388],
                [103.9095604, 19.3303801],
                [103.9093137, 19.330046],
                [103.9090776, 19.3298435],
                [103.908745, 19.3296107],
                [103.9082117, 19.3294647],
                [103.9077365, 19.3294689],
                [103.9074147, 19.3294487],
                [103.9070713, 19.329226],
                [103.9066851, 19.3292158],
                [103.9065134, 19.3292968],
                [103.9059877, 19.3295601],
                [103.9054767, 19.3296231],
                [103.9044227, 19.3297996],
                [103.9011413, 19.3305784],
                [103.8991204, 19.330808],
                [103.8965217, 19.3304728],
                [103.8950023, 19.3307672],
                [103.893588, 19.3301319],
                [103.8922364, 19.330052],
                [103.8916084, 19.3291261],
                [103.8890571, 19.3281765],
                [103.8875157, 19.3261035],
                [103.8845316, 19.3259107],
                [103.8831658, 19.325067],
                [103.8824395, 19.3236793],
                [103.8827228, 19.3221383],
                [103.8822722, 19.3215876],
                [103.8810999, 19.3208388],
                [103.8770054, 19.3197119],
                [103.8757546, 19.3185418],
                [103.8751346, 19.316951],
                [103.875544, 19.314749],
                [103.8745552, 19.3116455],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.5416957, 20.3637357],
                [105.5427586, 20.36216],
                [105.5434043, 20.3600889],
                [105.5438605, 20.3566099],
                [105.5445668, 20.3532481],
                [105.5453419, 20.3516838],
                [105.5468353, 20.3502055],
                [105.5472385, 20.3494675],
                [105.5484524, 20.3483731],
                [105.550102, 20.3469534],
                [105.5497811, 20.3445022],
                [105.5491036, 20.3383202],
                [105.5487483, 20.3349347],
                [105.5488715, 20.3344336],
                [105.5494341, 20.3338276],
                [105.5510859, 20.3323749],
                [105.5516907, 20.3314175],
                [105.5524006, 20.3299354],
                [105.5524737, 20.3298126],
                [105.5532851, 20.3285823],
                [105.5540034, 20.3279871],
                [105.5545826, 20.3278846],
                [105.5551359, 20.328036],
                [105.5553556, 20.3281102],
                [105.5560934, 20.3285184],
                [105.5569322, 20.3291473],
                [105.5572509, 20.3293673],
                [105.5574183, 20.3293826],
                [105.5577194, 20.3293659],
                [105.5582376, 20.3292221],
                [105.5585716, 20.3290473],
                [105.5592055, 20.3285242],
                [105.5600391, 20.3276848],
                [105.5606902, 20.3272721],
                [105.5609911, 20.327208],
                [105.5614887, 20.3272909],
                [105.5618905, 20.327337],
                [105.5622751, 20.3272252],
                [105.5627423, 20.3269079],
                [105.5643931, 20.3253552],
                [105.5648595, 20.3247695],
                [105.5650089, 20.3244375],
                [105.5650075, 20.3240586],
                [105.5649049, 20.3234588],
                [105.564854, 20.3232221],
                [105.5636886, 20.3213367],
                [105.5633551, 20.3203441],
                [105.5628983, 20.3189561],
                [105.5627786, 20.3182617],
                [105.5627195, 20.3177959],
                [105.5630019, 20.3172425],
                [105.5638023, 20.3165135],
                [105.5644033, 20.3161326],
                [105.5651552, 20.3158618],
                [105.5658118, 20.3156806],
                [105.5661422, 20.315827],
                [105.5670747, 20.3162323],
                [105.568366, 20.3170491],
                [105.5693881, 20.3174879],
                [105.5699573, 20.3175809],
                [105.5706097, 20.3175629],
                [105.5712952, 20.3174343],
                [105.5719137, 20.3172744],
                [105.5724647, 20.3169726],
                [105.5729489, 20.3167026],
                [105.5746677, 20.315497],
                [105.5773615, 20.3134428],
                [105.5791962, 20.3119367],
                [105.5797143, 20.3118086],
                [105.5798816, 20.3118081],
                [105.5803168, 20.3118382],
                [105.5818014, 20.3119282],
                [105.5840436, 20.3120312],
                [105.5854817, 20.311837],
                [105.5864686, 20.311802],
                [105.5874633, 20.311924],
                [105.5887197, 20.3123619],
                [105.5920529, 20.3133769],
                [105.5924212, 20.3134705],
                [105.5927902, 20.3136745],
                [105.593293, 20.3139413],
                [105.5935946, 20.3140349],
                [105.5939795, 20.3140651],
                [105.594565, 20.3140473],
                [105.595267, 20.3138714],
                [105.5958019, 20.3137589],
                [105.596253, 20.3136154],
                [105.5982694, 20.3126961],
                [105.5991875, 20.312172],
                [105.5997377, 20.3116806],
                [105.6001041, 20.311253],
                [105.6006863, 20.3104142],
                [105.6011361, 20.3098916],
                [105.6016864, 20.3094002],
                [105.6023204, 20.3089875],
                [105.6027379, 20.3087965],
                [105.6029383, 20.3086696],
                [105.6036176, 20.3079786],
                [105.6046973, 20.3059697],
                [105.6060916, 20.3040289],
                [105.6071063, 20.3025096],
                [105.6078551, 20.3015123],
                [105.6081837, 20.3006797],
                [105.6083819, 20.3000317],
                [105.6086459, 20.2991149],
                [105.609095, 20.2984502],
                [105.6097122, 20.298006],
                [105.6105966, 20.2974344],
                [105.6108801, 20.2971967],
                [105.6111467, 20.2969115],
                [105.6112791, 20.2965637],
                [105.6113063, 20.2954989],
                [105.6109312, 20.2937319],
                [105.6107445, 20.293022],
                [105.6107088, 20.2924379],
                [105.6107234, 20.2919167],
                [105.6107889, 20.2915533],
                [105.6110054, 20.2912842],
                [105.6119812, 20.2902],
                [105.6133965, 20.2885214],
                [105.614847, 20.2873322],
                [105.616798, 20.2857621],
                [105.6184564, 20.2842785],
                [105.6194384, 20.2830435],
                [105.6203218, 20.2822507],
                [105.620789, 20.2819491],
                [105.6212401, 20.281837],
                [105.6221099, 20.2818339],
                [105.6232143, 20.2819247],
                [105.6240504, 20.2818902],
                [105.6245687, 20.2818093],
                [105.6250027, 20.2816183],
                [105.6252198, 20.2814913],
                [105.6255471, 20.2812106],
                [105.6267803, 20.2800851],
                [105.6279984, 20.2793228],
                [105.6290339, 20.2789718],
                [105.6302546, 20.2788884],
                [105.6308038, 20.2788985],
                [105.6338541, 20.2783557],
                [105.6354061, 20.2774975],
                [105.6357583, 20.2776855],
                [105.6359424, 20.2777323],
                [105.6369644, 20.2781707],
                [105.6373993, 20.2781533],
                [105.6377832, 20.2779466],
                [105.6383163, 20.2774394],
                [105.6389014, 20.2773268],
                [105.6392195, 20.2773888],
                [105.6400741, 20.2777962],
                [105.64071, 20.2778727],
                [105.6411114, 20.2778555],
                [105.6412784, 20.2778075],
                [105.6413618, 20.2777599],
                [105.6417212, 20.2774628],
                [105.6422045, 20.2770032],
                [105.6433058, 20.2763833],
                [105.644041, 20.2761911],
                [105.6446758, 20.2759993],
                [105.6453942, 20.2757913],
                [105.6467245, 20.2751435],
                [105.6481608, 20.2746171],
                [105.6488792, 20.274425],
                [105.6500472, 20.2737416],
                [105.6507405, 20.2730857],
                [105.6507829, 20.2728545],
                [105.6508564, 20.2722536],
                [105.6510014, 20.2719592],
                [105.6512307, 20.2716761],
                [105.6517129, 20.271303],
                [105.6522311, 20.2709865],
                [105.6524099, 20.2707645],
                [105.6526595, 20.2704793],
                [105.6532525, 20.2697914],
                [105.6542029, 20.2690932],
                [105.655821, 20.2680924],
                [105.6566709, 20.267347],
                [105.6579522, 20.2657949],
                [105.6598304, 20.2640739],
                [105.6622136, 20.2620912],
                [105.6658241, 20.2587044],
                [105.6676564, 20.2569764],
                [105.668106, 20.2565326],
                [105.6686545, 20.255741],
                [105.6706308, 20.2534328],
                [105.6696579, 20.2524939],
                [105.6692364, 20.2516745],
                [105.6690484, 20.2507278],
                [105.6690471, 20.2504437],
                [105.6692292, 20.2500008],
                [105.669561, 20.2493838],
                [105.6697725, 20.2490118],
                [105.6700433, 20.2485486],
                [105.6704168, 20.2482092],
                [105.6708414, 20.2478375],
                [105.6710796, 20.247724],
                [105.6714206, 20.2477548],
                [105.6720521, 20.2478972],
                [105.6733488, 20.2481336],
                [105.6742697, 20.2482426],
                [105.6749858, 20.2482399],
                [105.675224, 20.2481263],
                [105.6772322, 20.2473464],
                [105.6785772, 20.2469068],
                [105.6789342, 20.2466963],
                [105.6790338, 20.2461005],
                [105.6789302, 20.2418695],
                [105.6792003, 20.2412347],
                [105.6800982, 20.2399593],
                [105.6814188, 20.2388339],
                [105.6817393, 20.2380507],
                [105.6823522, 20.2367062],
                [105.6826996, 20.2358522],
                [105.6829486, 20.2354407],
                [105.6833312, 20.2349813],
                [105.6837481, 20.2347113],
                [105.6846729, 20.2342391],
                [105.6857104, 20.2337619],
                [105.6860163, 20.2337791],
                [105.6865132, 20.2339362],
                [105.6869551, 20.2339891],
                [105.6872591, 20.2339506],
                [105.6877569, 20.2336911],
                [105.688393, 20.2333537],
                [105.6888082, 20.232964],
                [105.6889687, 20.2324619],
                [105.689029, 20.2323746],
                [105.6895451, 20.2318673],
                [105.6897897, 20.2317926],
                [105.6916945, 20.2314535],
                [105.6936154, 20.2310038],
                [105.6936809, 20.2309835],
                [105.6941832, 20.2308278],
                [105.694233, 20.2307329],
                [105.6943106, 20.2300295],
                [105.6945681, 20.227739],
                [105.6947975, 20.2267118],
                [105.6951794, 20.2261103],
                [105.696146, 20.2253643],
                [105.6961617, 20.225159],
                [105.695173, 20.2240466],
                [105.6933084, 20.2220961],
                [105.6931902, 20.2218281],
                [105.6931897, 20.2217334],
                [105.6932896, 20.2216067],
                [105.6935397, 20.2214478],
                [105.6946085, 20.2211593],
                [105.695919, 20.2204976],
                [105.6972043, 20.220003],
                [105.6984894, 20.2194927],
                [105.6994577, 20.2191888],
                [105.7003589, 20.2187905],
                [105.7008919, 20.2183462],
                [105.7013077, 20.2178551],
                [105.701689, 20.2171273],
                [105.7020217, 20.2167469],
                [105.7024828, 20.2164027],
                [105.7039827, 20.2153073],
                [105.7053002, 20.2145598],
                [105.7072858, 20.2136677],
                [105.7094873, 20.2124588],
                [105.7101541, 20.2120298],
                [105.7108818, 20.2114338],
                [105.7116973, 20.2106253],
                [105.7127626, 20.2095788],
                [105.7134286, 20.2089762],
                [105.7137786, 20.2087379],
                [105.7142415, 20.2085987],
                [105.7149265, 20.2084855],
                [105.7155951, 20.2084354],
                [105.7157741, 20.2084347],
                [105.716431, 20.208432],
                [105.7169474, 20.2080351],
                [105.7172131, 20.2076393],
                [105.7178103, 20.2066263],
                [105.7183267, 20.2062137],
                [105.7195863, 20.205284],
                [105.7208861, 20.2043629],
                [105.7219525, 20.203569],
                [105.7234196, 20.2027103],
                [105.7241866, 20.2022493],
                [105.7251486, 20.201649],
                [105.7265496, 20.2009488],
                [105.7286022, 20.2001354],
                [105.7297376, 20.199815],
                [105.730305, 20.1996233],
                [105.7305384, 20.1994802],
                [105.7307375, 20.199148],
                [105.7313352, 20.1982459],
                [105.7320253, 20.1965149],
                [105.7327471, 20.1952464],
                [105.7333566, 20.1946814],
                [105.7340338, 20.1945067],
                [105.735257, 20.1942829],
                [105.7373708, 20.1944327],
                [105.7376354, 20.1943848],
                [105.7378828, 20.1942588],
                [105.7383413, 20.1936498],
                [105.7387361, 20.1931639],
                [105.7393109, 20.192271],
                [105.7402315, 20.1910797],
                [105.7408903, 20.1904832],
                [105.7413511, 20.189997],
                [105.742053, 20.1884385],
                [105.7420495, 20.1877045],
                [105.7418325, 20.1872681],
                [105.741483, 20.1868323],
                [105.7404529, 20.1858685],
                [105.7404524, 20.1857434],
                [105.7406003, 20.1855399],
                [105.7409632, 20.1853509],
                [105.743902, 20.1841829],
                [105.746048, 20.1833148],
                [105.7483096, 20.1823838],
                [105.7503298, 20.1815006],
                [105.7518691, 20.1810786],
                [105.752589, 20.1808486],
                [105.7535651, 20.1805148],
                [105.7546453, 20.1801146],
                [105.7562136, 20.1795145],
                [105.7572933, 20.1790154],
                [105.7579553, 20.1787159],
                [105.7588618, 20.1784812],
                [105.7595947, 20.1784121],
                [105.7606766, 20.1783415],
                [105.7639917, 20.1780305],
                [105.765457, 20.1778264],
                [105.766329, 20.1776907],
                [105.7670959, 20.1774237],
                [105.7679719, 20.1770928],
                [105.7684201, 20.1769234],
                [105.7696401, 20.1765225],
                [105.7706512, 20.1762545],
                [105.7712442, 20.1761529],
                [105.771873, 20.1762491],
                [105.7722935, 20.1765439],
                [105.7726789, 20.1768388],
                [105.7729583, 20.1768706],
                [105.7734815, 20.1767694],
                [105.7744216, 20.1762708],
                [105.7751183, 20.1759382],
                [105.7752556, 20.175476],
                [105.7753213, 20.1746187],
                [105.775459, 20.1742555],
                [105.7757366, 20.1739246],
                [105.7760154, 20.1738245],
                [105.7764335, 20.1736249],
                [105.7766421, 20.1734592],
                [105.7767101, 20.1730962],
                [105.7767427, 20.1726346],
                [105.7769496, 20.1721062],
                [105.7770889, 20.1720398],
                [105.7777173, 20.1720369],
                [105.7790453, 20.1723277],
                [105.7807227, 20.1726829],
                [105.7813172, 20.172878],
                [105.7820502, 20.1728418],
                [105.7824684, 20.1727081],
                [105.7829372, 20.172484],
                [105.7833666, 20.1719465],
                [105.7840441, 20.1714085],
                [105.7852626, 20.1712256],
                [105.7869927, 20.1708166],
                [105.7877, 20.1705622],
                [105.7880886, 20.1705108],
                [105.7884969, 20.1705385],
                [105.7904516, 20.1704638],
                [105.7920218, 20.1703249],
                [105.7928592, 20.1702223],
                [105.7933473, 20.1700882],
                [105.7939026, 20.1694595],
                [105.7946995, 20.1682691],
                [105.7952895, 20.1675742],
                [105.7959152, 20.167044],
                [105.7964728, 20.1668766],
                [105.7977645, 20.1668708],
                [105.7980451, 20.1664327],
                [105.798754, 20.1655148],
                [105.8004473, 20.1635975],
                [105.8015618, 20.1629893],
                [105.8048029, 20.1619694],
                [105.805865, 20.1615123],
                [105.8074002, 20.1599474],
                [105.8089377, 20.1587845],
                [105.8097361, 20.158831],
                [105.8108532, 20.1587254],
                [105.811863, 20.1584695],
                [105.8150647, 20.1562858],
                [105.8156471, 20.1558886],
                [105.8172722, 20.1548261],
                [105.8189179, 20.1540647],
                [105.8207778, 20.1535031],
                [105.8224775, 20.1529424],
                [105.8247647, 20.1527307],
                [105.8261478, 20.1526238],
                [105.8265709, 20.1521192],
                [105.8271541, 20.1517144],
                [105.828697, 20.1516569],
                [105.8306646, 20.1513964],
                [105.8319907, 20.150586],
                [105.8328341, 20.1490744],
                [105.8330395, 20.1476663],
                [105.8333513, 20.1462577],
                [105.8343533, 20.1445443],
                [105.8355159, 20.1430311],
                [105.8364183, 20.1426249],
                [105.8376419, 20.1425688],
                [105.8391832, 20.1422096],
                [105.8405681, 20.1425045],
                [105.8419511, 20.1423974],
                [105.8433849, 20.1418378],
                [105.8445502, 20.1408271],
                [105.8451321, 20.1402213],
                [105.8467277, 20.1400628],
                [105.8480034, 20.1398055],
                [105.8490121, 20.1393985],
                [105.85236, 20.1385783],
                [105.8530911, 20.1381218],
                [105.8537387, 20.1377174],
                [105.8552229, 20.1366547],
                [105.8565997, 20.1354419],
                [105.8581882, 20.1340272],
                [105.8595671, 20.1331661],
                [105.8605646, 20.1322755],
                [105.8610217, 20.1318674],
                [105.8612084, 20.1317006],
                [105.8615776, 20.1310958],
                [105.862532, 20.1304881],
                [105.8633822, 20.1302829],
                [105.8641778, 20.1298267],
                [105.8643854, 20.1288709],
                [105.8643818, 20.1282176],
                [105.8649119, 20.1278632],
                [105.8657616, 20.1275575],
                [105.8666078, 20.1266488],
                [105.8676661, 20.1255882],
                [105.8684074, 20.1249313],
                [105.8691497, 20.1244753],
                [105.8702116, 20.1240681],
                [105.8712742, 20.1238115],
                [105.872335, 20.1232032],
                [105.8738718, 20.12209],
                [105.8748272, 20.1216832],
                [105.8759443, 20.1216274],
                [105.8778618, 20.12202],
                [105.8789798, 20.1221651],
                [105.8796709, 20.1220612],
                [105.8808902, 20.121251],
                [105.8821629, 20.1205412],
                [105.883118, 20.120084],
                [105.883915, 20.119879],
                [105.8858288, 20.1196182],
                [105.8883294, 20.1196056],
                [105.8899252, 20.1195473],
                [105.891309, 20.1196408],
                [105.8920529, 20.1194863],
                [105.8923161, 20.1189824],
                [105.8932725, 20.1187262],
                [105.8943344, 20.1183691],
                [105.8958182, 20.1173565],
                [105.8969261, 20.1156924],
                [105.8981954, 20.1143795],
                [105.8995724, 20.1132668],
                [105.9008994, 20.1127576],
                [105.9019082, 20.1124005],
                [105.9025966, 20.1118442],
                [105.903934, 20.1105322],
                [105.9056142, 20.1092658],
                [105.9066178, 20.1080043],
                [105.9071437, 20.1069463],
                [105.9076195, 20.1064413],
                [105.9082585, 20.1065385],
                [105.9088585, 20.1066394],
                [105.9096396, 20.1061796],
                [105.9099031, 20.1057259],
                [105.9101657, 20.1051717],
                [105.9106443, 20.105119],
                [105.9114434, 20.105316],
                [105.9121901, 20.1056639],
                [105.9130428, 20.1059108],
                [105.9137886, 20.106108],
                [105.9152776, 20.1059997],
                [105.9160218, 20.1058953],
                [105.9170311, 20.1056388],
                [105.9184639, 20.1050283],
                [105.9195254, 20.1046207],
                [105.9205327, 20.1040125],
                [105.9209, 20.1031562],
                [105.921375, 20.1025004],
                [105.9218541, 20.1025482],
                [105.9227585, 20.1025435],
                [105.9242109, 20.1021784],
                [105.9259371, 20.0979946],
                [105.9280096, 20.0939348],
                [105.9289752, 20.0917731],
                [105.9297856, 20.0904705],
                [105.9316162, 20.0893387],
                [105.934584, 20.0870343],
                [105.9366718, 20.0856149],
                [105.9409477, 20.0839638],
                [105.9426012, 20.080038],
                [105.9461129, 20.075266],
                [105.9468996, 20.0744256],
                [105.9482974, 20.0744621],
                [105.950301, 20.0745833],
                [105.951511, 20.0744008],
                [105.9522051, 20.0736488],
                [105.9535017, 20.0723655],
                [105.9545682, 20.0715676],
                [105.956282, 20.0699738],
                [105.9576686, 20.0682059],
                [105.9588721, 20.0669671],
                [105.9610543, 20.0658109],
                [105.9623115, 20.0657601],
                [105.964036, 20.0659267],
                [105.9666456, 20.0661325],
                [105.9679955, 20.0659931],
                [105.9700395, 20.0658762],
                [105.9709758, 20.0658887],
                [105.9714416, 20.0658862],
                [105.9719551, 20.0661035],
                [105.9727038, 20.0666274],
                [105.9739656, 20.0673246],
                [105.9748057, 20.0675841],
                [105.9756901, 20.0674912],
                [105.9765734, 20.0672223],
                [105.9776891, 20.066864],
                [105.9780582, 20.0662898],
                [105.9784759, 20.0660674],
                [105.979083, 20.0663281],
                [105.9827259, 20.0680542],
                [105.9853358, 20.0681419],
                [105.9892014, 20.0680762],
                [105.991627, 20.0686348],
                [105.9926053, 20.0686733],
                [105.9929308, 20.0685835],
                [105.993208, 20.0682299],
                [105.9932508, 20.0676134],
                [105.9932929, 20.066909],
                [105.9937573, 20.0666863],
                [105.9943167, 20.0667712],
                [105.9949247, 20.0671639],
                [105.9959076, 20.0679065],
                [105.9968413, 20.0682533],
                [105.9974204, 20.0679905],
                [105.998513, 20.0674518],
                [106.0002665, 20.0666987],
                [106.0018671, 20.0666768],
                [106.0029335, 20.0665461],
                [106.0033789, 20.066515],
                [106.0036624, 20.0665133],
                [106.0041487, 20.0665392],
                [106.004666, 20.0666799],
                [106.0048284, 20.0667366],
                [106.0050766, 20.066735],
                [106.0053295, 20.0666953],
                [106.0056022, 20.0665884],
                [106.0058549, 20.0665008],
                [106.0060976, 20.066442],
                [106.006391, 20.066402],
                [106.0074797, 20.0664149],
                [106.0081382, 20.0664399],
                [106.0084518, 20.0663998],
                [106.0087045, 20.0663313],
                [106.0089875, 20.066234],
                [106.0094621, 20.066011],
                [106.0101544, 20.0657964],
                [106.0107107, 20.0656879],
                [106.010747, 20.0656855],
                [106.0119253, 20.065032],
                [106.0140641, 20.0638081],
                [106.0144505, 20.0635737],
                [106.0146908, 20.0633425],
                [106.0149455, 20.0628004],
                [106.0151308, 20.0624833],
                [106.0153219, 20.0622715],
                [106.015533, 20.0620214],
                [106.0157742, 20.0617518],
                [106.0165948, 20.0610194],
                [106.0172686, 20.0602781],
                [106.0178026, 20.0598632],
                [106.0179237, 20.0597956],
                [106.0180452, 20.0597757],
                [106.0181872, 20.0598323],
                [106.0183603, 20.0599941],
                [106.0185027, 20.0600699],
                [106.0186141, 20.0600788],
                [106.0187355, 20.0600495],
                [106.0194169, 20.0597295],
                [106.0203459, 20.0593124],
                [106.0204673, 20.0592829],
                [106.0209837, 20.05928],
                [106.0224464, 20.0591949],
                [106.0231647, 20.0590854],
                [106.0234181, 20.0591223],
                [106.0236212, 20.0591977],
                [106.0237938, 20.0592733],
                [106.0244439, 20.0595856],
                [106.0250379, 20.0598214],
                [106.0259512, 20.0601035],
                [106.0261843, 20.06015],
                [106.0263465, 20.0601682],
                [106.0265896, 20.0601764],
                [106.0267921, 20.0601656],
                [106.026883, 20.0601459],
                [106.0272765, 20.0599139],
                [106.0281096, 20.0595642],
                [106.0283727, 20.059534],
                [106.0286259, 20.0595326],
                [106.0290212, 20.0595877],
                [106.0292952, 20.0596723],
                [106.0298129, 20.0598703],
                [106.030559, 20.0601437],
                [106.0321534, 20.0608239],
                [106.0324066, 20.060832],
                [106.0327304, 20.0608013],
                [106.0330439, 20.0607325],
                [106.0334131, 20.0606537],
                [106.0339083, 20.0605072],
                [106.0342214, 20.0603904],
                [106.0352106, 20.0598676],
                [106.0353722, 20.0598283],
                [106.0354937, 20.0598275],
                [106.0357425, 20.0599315],
                [106.0361487, 20.0601206],
                [106.0368293, 20.060423],
                [106.0381451, 20.0610952],
                [106.0392832, 20.0616822],
                [106.0394051, 20.0617389],
                [106.0396282, 20.0618047],
                [106.0399097, 20.061825],
                [106.0406452, 20.0618467],
                [106.041242, 20.0619447],
                [106.0422406, 20.0621021],
                [106.0426336, 20.0621508],
                [106.0426387, 20.0622002],
                [106.0436554, 20.0622533],
                [106.0446605, 20.0624142],
                [106.0468324, 20.0627789],
                [106.051872, 20.0634919],
                [106.0544378, 20.0638794],
                [106.0590781, 20.0645919],
                [106.0604244, 20.0647824],
                [106.0617292, 20.0649968],
                [106.064411, 20.0654111],
                [106.0677786, 20.0659781],
                [106.0679726, 20.0660073],
                [106.0720678, 20.0666239],
                [106.0750546, 20.0633691],
                [106.0754391, 20.0630355],
                [106.0756058, 20.0629153],
                [106.0758904, 20.0628553],
                [106.0760695, 20.0628331],
                [106.0760657, 20.062671],
                [106.0760065, 20.0623987],
                [106.0759318, 20.0619958],
                [106.0758256, 20.0615504],
                [106.0757392, 20.0611258],
                [106.0755417, 20.0599818],
                [106.0754941, 20.0595731],
                [106.0754385, 20.059149],
                [106.0753992, 20.0588449],
                [106.0753813, 20.0585683],
                [106.0752223, 20.0580568],
                [106.0750833, 20.0575968],
                [106.0749696, 20.0570105],
                [106.0749094, 20.0565458],
                [106.0747256, 20.0558289],
                [106.074602, 20.0553716],
                [106.0745055, 20.0550079],
                [106.0743404, 20.054494],
                [106.0742803, 20.0539729],
                [106.0742187, 20.0534729],
                [106.0740833, 20.0528003],
                [106.0739257, 20.0521852],
                [106.0737816, 20.0517376],
                [106.0736829, 20.0514792],
                [106.0735914, 20.0512856],
                [106.0734833, 20.0511283],
                [106.0733455, 20.0508881],
                [106.0731799, 20.0506229],
                [106.0729198, 20.0501711],
                [106.0724641, 20.0495406],
                [106.0720208, 20.0489029],
                [106.0715789, 20.0484277],
                [106.0714094, 20.0481591],
                [106.0712286, 20.0477857],
                [106.0710011, 20.0474697],
                [106.0707067, 20.0468801],
                [106.0704456, 20.046391],
                [106.070203, 20.0457949],
                [106.0699105, 20.0452118],
                [106.0695824, 20.0444955],
                [106.0694065, 20.0441999],
                [106.0692649, 20.0438046],
                [106.0690516, 20.0432395],
                [106.068785, 20.0423974],
                [106.0684618, 20.0415739],
                [106.0680494, 20.0405623],
                [106.0674051, 20.0393461],
                [106.0669026, 20.0382037],
                [106.0664461, 20.0374046],
                [106.0662701, 20.0371206],
                [106.0663404, 20.0363536],
                [106.0663701, 20.0356408],
                [106.0663897, 20.0349513],
                [106.0663663, 20.0341733],
                [106.0663967, 20.0334944],
                [106.066372, 20.033057],
                [106.0663235, 20.0327397],
                [106.0660259, 20.0317683],
                [106.0656254, 20.0308065],
                [106.065425, 20.0302466],
                [106.0649848, 20.0291299],
                [106.0646597, 20.028106],
                [106.0643968, 20.0272522],
                [106.064311, 20.0268984],
                [106.0642513, 20.0266205],
                [106.0641748, 20.0262458],
                [106.0640316, 20.0258927],
                [106.063763, 20.0251387],
                [106.0634785, 20.0243994],
                [106.0632368, 20.0237038],
                [106.0630406, 20.0230467],
                [106.0627169, 20.0221597],
                [106.0625068, 20.021516],
                [106.06236, 20.0210123],
                [106.0622376, 20.0203855],
                [106.0620053, 20.0196367],
                [106.0618629, 20.0190873],
                [106.0616749, 20.0185287],
                [106.0612235, 20.0176882],
                [106.0611076, 20.0174971],
                [106.0608091, 20.0171374],
                [106.0606682, 20.0166987],
                [106.0604477, 20.0162662],
                [106.0602284, 20.0158767],
                [106.059668, 20.0150052],
                [106.0591276, 20.0142466],
                [106.0588167, 20.0138085],
                [106.0585886, 20.0135215],
                [106.0579618, 20.0126824],
                [106.0576589, 20.0123219],
                [106.0573266, 20.0116087],
                [106.0570285, 20.011137],
                [106.0566611, 20.0104965],
                [106.0562532, 20.0097457],
                [106.0555667, 20.0085633],
                [106.0550157, 20.0076422],
                [106.0544602, 20.0066216],
                [106.0540575, 20.0058715],
                [106.0533165, 20.0049516],
                [106.0527048, 20.0043928],
                [106.0522571, 20.003979],
                [106.0518934, 20.0037025],
                [106.0515928, 20.0034849],
                [106.051335, 20.0033069],
                [106.0508763, 20.0030611],
                [106.0501585, 20.0026917],
                [106.0491352, 20.0021844],
                [106.048363, 20.0018118],
                [106.0482109, 20.0017385],
                [106.0475541, 20.0014189],
                [106.0467557, 20.0011039],
                [106.0463143, 20.0009669],
                [106.0460217, 20.0009055],
                [106.0456259, 20.0007906],
                [106.044992, 20.0007709],
                [106.0440554, 20.0008692],
                [106.0429823, 20.0009786],
                [106.0415059, 20.0011083],
                [106.0399406, 20.001155],
                [106.0390417, 20.0011472],
                [106.0387242, 20.0011337],
                [106.038436, 20.0011432],
                [106.0379424, 20.001108],
                [106.0375435, 20.0010445],
                [106.0366486, 20.0008285],
                [106.0356924, 20.0005713],
                [106.0345848, 20.0001835],
                [106.0339837, 19.9999189],
                [106.0329924, 19.9993636],
                [106.0324646, 19.9990069],
                [106.0314431, 19.9981749],
                [106.0297893, 19.9964486],
                [106.0286329, 19.9950715],
                [106.0277637, 19.9935969],
                [106.0269143, 19.9920736],
                [106.0261218, 19.990528],
                [106.025464, 19.9889769],
                [106.0246275, 19.9852419],
                [106.0242459, 19.9836077],
                [106.024142, 19.9819736],
                [106.0242127, 19.9815858],
                [106.0242228, 19.9810593],
                [106.0242209, 19.9806594],
                [106.024294, 19.9801075],
                [106.0244295, 19.9778883],
                [106.0244422, 19.9750891],
                [106.0245034, 19.9727279],
                [106.0248022, 19.9701482],
                [106.025136, 19.9671633],
                [106.0275226, 19.8845403],
                [106.1253083, 19.6931063],
                [106.140463, 19.694798],
                [106.1596928, 19.6986123],
                [106.1746941, 19.7026604],
                [106.1893339, 19.7077381],
                [106.2035325, 19.7138161],
                [106.0869443, 19.8922366],
                [106.0872361, 19.894908],
                [106.0878026, 19.8973373],
                [106.0887811, 19.8999683],
                [106.0904805, 19.9037614],
                [106.0928323, 19.9081194],
                [106.0949781, 19.9116702],
                [106.1003258, 19.9188151],
                [106.106297, 19.9265096],
                [106.1070692, 19.9283343],
                [106.1073854, 19.9297741],
                [106.1074706, 19.9311014],
                [106.1073102, 19.9324086],
                [106.1066753, 19.9349688],
                [106.1035516, 19.9385794],
                [106.1026873, 19.9401752],
                [106.1019224, 19.941723],
                [106.1004593, 19.9447804],
                [106.0996235, 19.9470529],
                [106.0990052, 19.948263],
                [106.0983912, 19.9494465],
                [106.097831, 19.9506612],
                [106.0975021, 19.9517424],
                [106.0966555, 19.9542119],
                [106.0962779, 19.9553706],
                [106.0961297, 19.9567534],
                [106.0959552, 19.9580131],
                [106.0955799, 19.9594679],
                [106.0953998, 19.9632677],
                [106.0953484, 19.9640451],
                [106.0952411, 19.9653615],
                [106.0952295, 19.9666995],
                [106.0951438, 19.9680251],
                [106.0951893, 19.9690315],
                [106.0954256, 19.9701174],
                [106.0954881, 19.97085],
                [106.0956673, 19.9718804],
                [106.095935, 19.973236],
                [106.0965263, 19.9762671],
                [106.0968223, 19.9775944],
                [106.0971878, 19.9788169],
                [106.0976155, 19.980248],
                [106.098309, 19.9823955],
                [106.0992435, 19.9847544],
                [106.1007479, 19.9886734],
                [106.1021498, 19.9922694],
                [106.1043832, 19.9982553],
                [106.10503, 19.9997852],
                [106.1050684, 19.9999365],
                [106.1060472, 20.0022969],
                [106.1070228, 20.0044507],
                [106.1079258, 20.0067787],
                [106.1086008, 20.0087755],
                [106.109573, 20.0118492],
                [106.1103441, 20.0140958],
                [106.1113591, 20.0179922],
                [106.111804, 20.0196577],
                [106.1124624, 20.023078],
                [106.112599, 20.0248918],
                [106.1126805, 20.0279172],
                [106.1128035, 20.0297784],
                [106.1130535, 20.0323231],
                [106.1132896, 20.0340244],
                [106.1133722, 20.0347104],
                [106.11341, 20.0351298],
                [106.1135326, 20.0363029],
                [106.113696, 20.0390902],
                [106.1138942, 20.0415622],
                [106.1142987, 20.0439245],
                [106.1146176, 20.0454905],
                [106.114866, 20.0466475],
                [106.1152667, 20.0478216],
                [106.1157819, 20.0487417],
                [106.1162864, 20.0494009],
                [106.1168013, 20.0500134],
                [106.117678, 20.0508002],
                [106.118719, 20.0519313],
                [106.1197142, 20.0528953],
                [106.1205667, 20.0535186],
                [106.1215613, 20.0540524],
                [106.122579, 20.0546009],
                [106.1232906, 20.0549105],
                [106.1239006, 20.0551013],
                [106.1244329, 20.0552961],
                [106.1258159, 20.0559477],
                [106.1263993, 20.0561523],
                [106.1270237, 20.0563316],
                [106.1281268, 20.0565288],
                [106.130326, 20.0570245],
                [106.132383, 20.0576914],
                [106.1334657, 20.0581168],
                [106.134546, 20.0585951],
                [106.1357359, 20.0593497],
                [106.1369665, 20.0603544],
                [106.1377365, 20.0609749],
                [106.1386866, 20.0618594],
                [106.1394087, 20.0626525],
                [106.1398611, 20.0632381],
                [106.1404576, 20.0640568],
                [106.1408222, 20.0646044],
                [106.1415248, 20.0659093],
                [106.1418273, 20.0664894],
                [106.1426034, 20.0685136],
                [106.1430116, 20.0695919],
                [106.1433639, 20.0711509],
                [106.1436256, 20.0724594],
                [106.1438494, 20.0738542],
                [106.1439694, 20.0750052],
                [106.1440207, 20.0759883],
                [106.1441657, 20.0772314],
                [106.1442495, 20.0793824],
                [106.1446058, 20.0815042],
                [106.1447338, 20.0829872],
                [106.1448749, 20.084733],
                [106.1450455, 20.0862516],
                [106.1450382, 20.0873945],
                [106.145249, 20.0893234],
                [106.1454303, 20.0910941],
                [106.1456509, 20.0924381],
                [106.1458202, 20.0935522],
                [106.1461302, 20.0949747],
                [106.1465408, 20.0959799],
                [106.1470775, 20.0972166],
                [106.1478197, 20.0993946],
                [106.1485933, 20.101688],
                [106.1494893, 20.1039806],
                [106.1505843, 20.1062864],
                [106.1516158, 20.1082891],
                [106.1523281, 20.1095131],
                [106.1529703, 20.1105353],
                [106.1538664, 20.1120266],
                [106.1541613, 20.1126054],
                [106.1543026, 20.1128828],
                [106.1556915, 20.1156347],
                [106.1570644, 20.1182856],
                [106.157335, 20.118703],
                [106.1575453, 20.1189935],
                [106.157814, 20.1193648],
                [106.158447, 20.1201846],
                [106.1590486, 20.1208746],
                [106.1597802, 20.1215926],
                [106.1606274, 20.1224109],
                [106.1619343, 20.1233564],
                [106.162274, 20.1236222],
                [106.1625976, 20.1238755],
                [106.1633264, 20.1244458],
                [106.1654187, 20.1261089],
                [106.1663199, 20.1269991],
                [106.1666895, 20.1273436],
                [106.1670143, 20.1278185],
                [106.1674956, 20.1287839],
                [106.167894, 20.1299233],
                [106.1679883, 20.1302695],
                [106.1681642, 20.1313092],
                [106.1681968, 20.1326098],
                [106.1681489, 20.1343883],
                [106.1680296, 20.1353802],
                [106.1679401, 20.1361243],
                [106.1678136, 20.1376286],
                [106.1675771, 20.139755],
                [106.1672796, 20.1419254],
                [106.1669951, 20.1437776],
                [106.1666842, 20.1451529],
                [106.1661383, 20.1468334],
                [106.1657826, 20.148368],
                [106.1650914, 20.1510613],
                [106.1646276, 20.1535074],
                [106.1641486, 20.155939],
                [106.1636541, 20.1583564],
                [106.1632055, 20.1607735],
                [106.1628479, 20.1631032],
                [106.1626273, 20.1653452],
                [106.162663, 20.1670652],
                [106.1627491, 20.1683512],
                [106.1629495, 20.1726865],
                [106.1628827, 20.1749998],
                [106.1627998, 20.1772555],
                [106.1626248, 20.1794538],
                [106.1625859, 20.18142],
                [106.1625477, 20.1824612],
                [106.1624256, 20.1855988],
                [106.1622069, 20.1880721],
                [106.1620255, 20.1894032],
                [106.1618037, 20.1904454],
                [106.1612747, 20.1923426],
                [106.1609304, 20.1933712],
                [106.160253, 20.1959054],
                [106.1596212, 20.198396],
                [106.1589867, 20.2005396],
                [106.1583345, 20.2023363],
                [106.1577923, 20.2035108],
                [106.1574831, 20.2041345],
                [106.1567176, 20.2061199],
                [106.1549315, 20.2108441],
                [106.1540997, 20.2132058],
                [106.1535564, 20.2152766],
                [106.1533044, 20.2163769],
                [106.153063, 20.2178818],
                [106.1529935, 20.2188362],
                [106.1529216, 20.2204992],
                [106.1528853, 20.2218004],
                [106.1528109, 20.2231163],
                [106.1527375, 20.223536],
                [106.1526129, 20.2240819],
                [106.1524038, 20.2249982],
                [106.1522835, 20.2252736],
                [106.1521174, 20.2256072],
                [106.1519512, 20.2258974],
                [106.1517552, 20.2262746],
                [106.1515044, 20.2265075],
                [106.1513523, 20.2266385],
                [106.1508346, 20.2269889],
                [106.1505294, 20.227121],
                [106.1501326, 20.2272537],
                [106.1496435, 20.2273436],
                [106.1491542, 20.2273902],
                [106.1473792, 20.2274163],
                [106.1461163, 20.2273811],
                [106.1452126, 20.2272425],
                [106.1443234, 20.2270458],
                [106.1426059, 20.2265656],
                [106.1379773, 20.2245574],
                [106.1346379, 20.2230612],
                [106.133441, 20.2225919],
                [106.1303188, 20.2214845],
                [106.1281717, 20.2208334],
                [106.1248817, 20.2197992],
                [106.1228577, 20.2192195],
                [106.1176229, 20.2179953],
                [106.1166188, 20.2177414],
                [106.1139496, 20.2168188],
                [106.1128687, 20.216522],
                [106.1117804, 20.2162832],
                [106.1110409, 20.2161549],
                [106.1095086, 20.2160127],
                [106.1085501, 20.2160619],
                [106.1079148, 20.2162263],
                [106.1068477, 20.2166179],
                [106.1064211, 20.2168808],
                [106.1060031, 20.2172883],
                [106.1056546, 20.2177674],
                [106.105306, 20.2182611],
                [106.1050947, 20.2186671],
                [106.1049142, 20.2191021],
                [106.1043894, 20.2206376],
                [106.1030763, 20.2243032],
                [106.1028076, 20.2252589],
                [106.1025681, 20.2260411],
                [106.1024645, 20.2265477],
                [106.102203, 20.2285008],
                [106.101938, 20.2310612],
                [106.1017021, 20.2334333],
                [106.1014293, 20.2348951],
                [106.1011175, 20.2362704],
                [106.1007139, 20.2387158],
                [106.1002309, 20.2407716],
                [106.1000206, 20.2413367],
                [106.0993639, 20.2426128],
                [106.0983203, 20.2443975],
                [106.0977702, 20.2456008],
                [106.097468, 20.2461809],
                [106.0973176, 20.2465576],
                [106.097254, 20.2472952],
                [106.0972433, 20.2479458],
                [106.0973269, 20.2490072],
                [106.0973167, 20.2498903],
                [106.0972492, 20.2508415],
                [106.0969852, 20.251714],
                [106.0965383, 20.2527243],
                [106.0960845, 20.2534459],
                [106.0955282, 20.2540084],
                [106.0946722, 20.2545394],
                [106.0938322, 20.2548886],
                [106.0927721, 20.2549337],
                [106.0917112, 20.2548634],
                [106.0903632, 20.2545637],
                [106.0888908, 20.2539571],
                [106.0877034, 20.2533101],
                [106.0865554, 20.252432],
                [106.0855274, 20.2512067],
                [106.0849482, 20.2500556],
                [106.0844648, 20.2490073],
                [106.0841435, 20.2478997],
                [106.0838305, 20.2466092],
                [106.0834762, 20.2448523],
                [106.0829175, 20.2423966],
                [106.0825851, 20.2410524],
                [106.0817589, 20.2383651],
                [106.0809583, 20.2366468],
                [106.0799201, 20.2349121],
                [106.07874, 20.233268],
                [106.0771499, 20.231393],
                [106.0751317, 20.2293293],
                [106.0736767, 20.227896],
                [106.0706723, 20.2252987],
                [106.0684288, 20.2237327],
                [106.065776, 20.2220437],
                [106.0621613, 20.2200193],
                [106.0603305, 20.219061],
                [106.0585478, 20.2181563],
                [106.0565095, 20.2174148],
                [106.0556722, 20.2172403],
                [106.0534, 20.2170026],
                [106.0514616, 20.2169783],
                [106.0500943, 20.2170942],
                [106.0489082, 20.2173166],
                [106.0477511, 20.2176288],
                [106.0458752, 20.2184118],
                [106.0439999, 20.2193025],
                [106.0423437, 20.2202637],
                [106.0403398, 20.2218192],
                [106.0386005, 20.2231759],
                [106.0377025, 20.2238812],
                [106.0357393, 20.2258494],
                [106.0337466, 20.2276741],
                [106.0327465, 20.228739],
                [106.0320581, 20.2295508],
                [106.0316062, 20.2301817],
                [106.0306672, 20.2318566],
                [106.0300391, 20.2331707],
                [106.0297412, 20.2340879],
                [106.0295196, 20.2350764],
                [106.0294396, 20.2359026],
                [106.029368, 20.2365852],
                [106.0293725, 20.2372672],
                [106.0294336, 20.2378772],
                [106.0295798, 20.2384508],
                [106.0298302, 20.2389518],
                [106.0302711, 20.2395237],
                [106.0306537, 20.2398983],
                [106.0310735, 20.2401652],
                [106.0315883, 20.2404313],
                [106.0329223, 20.2409799],
                [106.0342073, 20.2412956],
                [106.0353481, 20.2413786],
                [106.0364313, 20.2413542],
                [106.0388428, 20.2410528],
                [106.0402569, 20.2407931],
                [106.041415, 20.2406066],
                [106.0424977, 20.2405106],
                [106.0436003, 20.2405757],
                [106.0452079, 20.2408355],
                [106.0463501, 20.2411159],
                [106.0475886, 20.2415751],
                [106.0480887, 20.241976],
                [106.0485269, 20.2424252],
                [106.0488014, 20.2427829],
                [106.0489991, 20.2430692],
                [106.049186, 20.2433659],
                [106.0494177, 20.243847],
                [106.049656, 20.2444948],
                [106.0497788, 20.2449769],
                [106.049798, 20.2453981],
                [106.0497694, 20.2459942],
                [106.0497292, 20.2464774],
                [106.0496259, 20.2472899],
                [106.0494721, 20.247897],
                [106.0493761, 20.2481647],
                [106.0492685, 20.2483606],
                [106.0489357, 20.2490077],
                [106.0485045, 20.2497208],
                [106.0479857, 20.2505903],
                [106.0474406, 20.2513435],
                [106.0472061, 20.2516676],
                [106.0468154, 20.2520826],
                [106.0454253, 20.2534111],
                [106.0440769, 20.2544507],
                [106.0428133, 20.255077],
                [106.0415473, 20.2553733],
                [106.0401917, 20.2552989],
                [106.03879, 20.2548946],
                [106.0374304, 20.2542425],
                [106.0360699, 20.2534666],
                [106.0347533, 20.2526906],
                [106.0331303, 20.2518749],
                [106.0312898, 20.2512256],
                [106.0296688, 20.2506988],
                [106.0277426, 20.2502975],
                [106.0259486, 20.2500604],
                [106.0240844, 20.2499932],
                [106.0239534, 20.2499885],
                [106.0217945, 20.2499196],
                [106.019862, 20.2498927],
                [106.019721, 20.249896],
                [106.0176852, 20.2499435],
                [106.0157186, 20.2500375],
                [106.0134464, 20.2502156],
                [106.011174, 20.2503526],
                [106.010235, 20.2504623],
                [106.0096888, 20.2505262],
                [106.0078543, 20.2507842],
                [106.0059763, 20.2510838],
                [106.0042735, 20.2514237],
                [106.0030518, 20.251802],
                [105.9955863, 20.2544862],
                [105.9911265, 20.2566082],
                [105.9883776, 20.2583438],
                [105.9854695, 20.2606812],
                [105.9841923, 20.261382],
                [105.982923, 20.2623427],
                [105.9818025, 20.2628865],
                [105.9815243, 20.2632522],
                [105.9811768, 20.2637341],
                [105.981186, 20.2651906],
                [105.9812644, 20.2665141],
                [105.9813733, 20.2671095],
                [105.9816262, 20.2682997],
                [105.9817361, 20.2690604],
                [105.9822009, 20.2704812],
                [105.9827888, 20.2716514],
                [105.9833369, 20.2727424],
                [105.9835111, 20.2730732],
                [105.9841886, 20.2743596],
                [105.986056, 20.2813833],
                [105.9863829, 20.283169],
                [105.9864284, 20.2848239],
                [105.9864002, 20.2859164],
                [105.9849572, 20.2906085],
                [105.9841277, 20.2925],
                [105.9830561, 20.2949225],
                [105.9817014, 20.2969493],
                [105.9806228, 20.2982795],
                [105.9794393, 20.2996598],
                [105.9768621, 20.3024548],
                [105.9752598, 20.304185],
                [105.974918, 20.3045816],
                [105.9733103, 20.3064468],
                [105.9724712, 20.3073842],
                [105.9711503, 20.3086933],
                [105.9684646, 20.3110253],
                [105.9668619, 20.3126893],
                [105.9654309, 20.3137896],
                [105.9632994, 20.3150261],
                [105.962876, 20.3152656],
                [105.9616216, 20.315896],
                [105.9583702, 20.3175132],
                [105.9560975, 20.3186174],
                [105.9537827, 20.3198292],
                [105.9514811, 20.3206936],
                [105.9485089, 20.3221323],
                [105.9458843, 20.3230397],
                [105.9430841, 20.323948],
                [105.9416154, 20.3246507],
                [105.939272, 20.3256888],
                [105.9376977, 20.3263258],
                [105.9369984, 20.3266935],
                [105.9363715, 20.3273915],
                [105.9361832, 20.3278346],
                [105.9361821, 20.3283466],
                [105.9365595, 20.3295077],
                [105.9371962, 20.3303975],
                [105.9381471, 20.3310541],
                [105.9393785, 20.3317091],
                [105.9405388, 20.3321991],
                [105.9432486, 20.3331687],
                [105.9434901, 20.3333604],
                [105.944204, 20.3339151],
                [105.9453769, 20.3348502],
                [105.9465509, 20.3359289],
                [105.9477605, 20.3373265],
                [105.9489369, 20.3388043],
                [105.9500126, 20.340426],
                [105.9512262, 20.3424459],
                [105.9519278, 20.3437665],
                [105.9524607, 20.3451359],
                [105.9530251, 20.3465307],
                [105.9531307, 20.3467918],
                [105.9536666, 20.34864],
                [105.9538457, 20.3502993],
                [105.9540414, 20.3518789],
                [105.9542159, 20.3527562],
                [105.9543046, 20.3534104],
                [105.9542732, 20.3537937],
                [105.9541064, 20.3541937],
                [105.9537374, 20.3546907],
                [105.9533842, 20.355028],
                [105.9529277, 20.3552925],
                [105.9528279, 20.3553503],
                [105.9525278, 20.3554191],
                [105.9519657, 20.3555428],
                [105.9509682, 20.3555965],
                [105.9494332, 20.3555807],
                [105.9452871, 20.3553136],
                [105.9424982, 20.3552563],
                [105.9412387, 20.3552998],
                [105.9408357, 20.3553136],
                [105.9396855, 20.3555128],
                [105.9386386, 20.3558321],
                [105.93795, 20.3561977],
                [105.9374155, 20.3566591],
                [105.9370624, 20.3574812],
                [105.9368374, 20.3583752],
                [105.9367656, 20.3591718],
                [105.9367728, 20.360354],
                [105.9372018, 20.3636332],
                [105.9372322, 20.3644533],
                [105.9371594, 20.365081],
                [105.9369589, 20.3657576],
                [105.9366038, 20.3662903],
                [105.9360968, 20.3670652],
                [105.9355716, 20.3674818],
                [105.9346393, 20.3679253],
                [105.9323372, 20.3674634],
                [105.9307581, 20.3667721],
                [105.9257329, 20.3632581],
                [105.9241194, 20.36286],
                [105.9222675, 20.3631768],
                [105.920604, 20.3640011],
                [105.9196887, 20.365011],
                [105.9187074, 20.3674182],
                [105.9183672, 20.368554],
                [105.9177305, 20.3702568],
                [105.9170495, 20.3722639],
                [105.9167082, 20.3729826],
                [105.9165213, 20.3730894],
                [105.9160125, 20.3730485],
                [105.9153182, 20.3728995],
                [105.9151335, 20.3729224],
                [105.9148804, 20.3731198],
                [105.9147297, 20.3737569],
                [105.9128383, 20.3756589],
                [105.9124947, 20.3761839],
                [105.9114975, 20.3767888],
                [105.9107979, 20.3768867],
                [105.9102462, 20.3765833],
                [105.9098411, 20.3757611],
                [105.9098533, 20.3750738],
                [105.9105311, 20.374038],
                [105.9103059, 20.3735143],
                [105.9099098, 20.3729496],
                [105.9093553, 20.3730061],
                [105.909049, 20.3728916],
                [105.9090708, 20.372326],
                [105.9082877, 20.3722824],
                [105.9074099, 20.3723741],
                [105.9066258, 20.3726834],
                [105.9059581, 20.373101],
                [105.9054966, 20.373256],
                [105.9046876, 20.3732819],
                [105.9041326, 20.3732412],
                [105.9028592, 20.3728773],
                [105.9026052, 20.3729222],
                [105.9021674, 20.3731424],
                [105.9016158, 20.3736902],
                [105.9012015, 20.3739975],
                [105.9006255, 20.3743274],
                [105.9001423, 20.3747005],
                [105.8995215, 20.3752704],
                [105.8990392, 20.3757961],
                [105.8980988, 20.3770432],
                [105.8979257, 20.377613],
                [105.8979298, 20.3782961],
                [105.8977331, 20.3788623],
                [105.8964035, 20.3796675],
                [105.8961981, 20.3801264],
                [105.8960854, 20.3806283],
                [105.8959019, 20.3808691],
                [105.8953961, 20.3813293],
                [105.8953049, 20.3815478],
                [105.8950995, 20.381726],
                [105.8941778, 20.3823432],
                [105.8937808, 20.3828634],
                [105.8935599, 20.3835711],
                [105.8936729, 20.3842567],
                [105.8940812, 20.3857826],
                [105.8928407, 20.3871186],
                [105.8923126, 20.3877534],
                [105.8918061, 20.3881048],
                [105.8912528, 20.3883691],
                [105.8904911, 20.3885692],
                [105.8894051, 20.3886619],
                [105.8881801, 20.38869],
                [105.8877185, 20.3888449],
                [105.8875119, 20.3890857],
                [105.8873801, 20.3902852],
                [105.8871982, 20.3908092],
                [105.8869237, 20.391312],
                [105.8863494, 20.3919471],
                [105.884716, 20.3933285],
                [105.8840263, 20.3940077],
                [105.8824877, 20.395781],
                [105.8817742, 20.3963513],
                [105.8810599, 20.3967691],
                [105.8803002, 20.3973397],
                [105.8797947, 20.3978653],
                [105.8791524, 20.3987623],
                [105.8785623, 20.4006835],
                [105.8776697, 20.4022572],
                [105.8762254, 20.4044224],
                [105.8757447, 20.4052531],
                [105.8752672, 20.4066723],
                [105.8750837, 20.4069348],
                [105.8746005, 20.4073513],
                [105.8741395, 20.4075934],
                [105.872431, 20.4080161],
                [105.8709543, 20.4085466],
                [105.8695937, 20.4091637],
                [105.8690868, 20.4094714],
                [105.8687188, 20.409822],
                [105.8685589, 20.4101715],
                [105.8684026, 20.4111314],
                [105.8682669, 20.411677],
                [105.8680151, 20.4121361],
                [105.8674873, 20.4128361],
                [105.866821, 20.4135805],
                [105.866177, 20.4141941],
                [105.8658327, 20.4146317],
                [105.8657184, 20.4148721],
                [105.8656821, 20.4166378],
                [105.8655003, 20.4172055],
                [105.8652941, 20.4175552],
                [105.8649256, 20.4177968],
                [105.8631019, 20.4183071],
                [105.8627791, 20.4184831],
                [105.8624343, 20.4188553],
                [105.8617841, 20.4201664],
                [105.8615356, 20.4212138],
                [105.860874, 20.4228083],
                [105.8598929, 20.4242907],
                [105.8593366, 20.4249302],
                [105.8588072, 20.4253688],
                [105.857863, 20.4260273],
                [105.8571482, 20.4265979],
                [105.8566326, 20.4266516],
                [105.85611, 20.426658],
                [105.8552712, 20.4264494],
                [105.8542558, 20.4261435],
                [105.8522761, 20.4260933],
                [105.8513928, 20.4261481],
                [105.8501712, 20.4261053],
                [105.8491113, 20.4261877],
                [105.8486698, 20.4261595],
                [105.8482805, 20.4253204],
                [105.8477072, 20.4245157],
                [105.8469132, 20.4236138],
                [105.8458469, 20.4227603],
                [105.8451704, 20.4222466],
                [105.84445, 20.4214973],
                [105.8438467, 20.4211639],
                [105.8427535, 20.420708],
                [105.8417877, 20.4205611],
                [105.8408225, 20.4204899],
                [105.8399226, 20.4207514],
                [105.8388386, 20.4207566],
                [105.8378183, 20.4207615],
                [105.8376271, 20.4207624],
                [105.8364081, 20.421533],
                [105.8356624, 20.4216833],
                [105.8351914, 20.4216967],
                [105.8350003, 20.421447],
                [105.8346774, 20.4206842],
                [105.834361, 20.4205522],
                [105.8337281, 20.4204821],
                [105.8324624, 20.4203978],
                [105.8315497, 20.420494],
                [105.8309315, 20.4204934],
                [105.8308064, 20.4204309],
                [105.8306154, 20.4202088],
                [105.8304541, 20.4195848],
                [105.8303084, 20.418309],
                [105.8301175, 20.4179068],
                [105.829897, 20.4176848],
                [105.8294407, 20.4176704],
                [105.8287338, 20.4179886],
                [105.8281079, 20.4183416],
                [105.8271065, 20.4187843],
                [105.8265472, 20.4187421],
                [105.8255176, 20.4181449],
                [105.8244317, 20.4175983],
                [105.8235609, 20.4178041],
                [105.8227844, 20.41816],
                [105.8225052, 20.418352],
                [105.8222575, 20.4186174],
                [105.8217628, 20.4192655],
                [105.8211767, 20.420325],
                [105.8208562, 20.4210407],
                [105.8207584, 20.4212592],
                [105.820723, 20.4213614],
                [105.8205569, 20.4218155],
                [105.8201295, 20.4228038],
                [105.8197119, 20.4234178],
                [105.8194728, 20.4237136],
                [105.8191373, 20.4239419],
                [105.8186214, 20.424137],
                [105.8179608, 20.4242761],
                [105.8175163, 20.4243462],
                [105.8169511, 20.4243488],
                [105.816326, 20.4243745],
                [105.8158936, 20.4244559],
                [105.8153652, 20.4245944],
                [105.8146098, 20.4249719],
                [105.8134714, 20.4257253],
                [105.8118175, 20.4267532],
                [105.8098155, 20.4279188],
                [105.8082227, 20.4291617],
                [105.8077195, 20.4295268],
                [105.8073486, 20.4298572],
                [105.8068698, 20.4302901],
                [105.8058297, 20.4314624],
                [105.8049205, 20.4323735],
                [105.8044782, 20.4328629],
                [105.8032107, 20.4342177],
                [105.8020738, 20.4352996],
                [105.8017271, 20.4356753],
                [105.8010454, 20.436404],
                [105.8002447, 20.437371],
                [105.7997667, 20.43794],
                [105.7993974, 20.4385991],
                [105.7986699, 20.4397699],
                [105.7981336, 20.4407472],
                [105.7978004, 20.4414062],
                [105.7972047, 20.4424858],
                [105.7965258, 20.4437584],
                [105.7958564, 20.4445663],
                [105.7955461, 20.4450323],
                [105.7948419, 20.4460557],
                [105.7943399, 20.4466362],
                [105.7939578, 20.4471706],
                [105.7937552, 20.4475343],
                [105.7935418, 20.448136],
                [105.7932363, 20.4495542],
                [105.7928377, 20.4515511],
                [105.7924627, 20.453457],
                [105.7923713, 20.4543869],
                [105.7923136, 20.4547086],
                [105.7916983, 20.454492],
                [105.7905656, 20.4540551],
                [105.789783, 20.4538546],
                [105.7892051, 20.4537438],
                [105.7883151, 20.4537479],
                [105.7881105, 20.4537375],
                [105.7880381, 20.4536698],
                [105.7879654, 20.4535681],
                [105.7878198, 20.4533195],
                [105.787722, 20.4529911],
                [105.7876724, 20.452708],
                [105.787682, 20.4522319],
                [105.7878467, 20.4515397],
                [105.7879876, 20.4508476],
                [105.7880452, 20.4503486],
                [105.7881274, 20.4499742],
                [105.7882571, 20.4494408],
                [105.7884473, 20.4490206],
                [105.7886855, 20.448566],
                [105.7887922, 20.448271],
                [105.7888159, 20.4481688],
                [105.7888027, 20.4479421],
                [105.7887056, 20.4477839],
                [105.7881123, 20.4470045],
                [105.7877248, 20.4464735],
                [105.7875308, 20.446157],
                [105.787119, 20.4456034],
                [105.7865117, 20.4444274],
                [105.7858716, 20.4430692],
                [105.7849614, 20.4414993],
                [105.7843993, 20.4404344],
                [105.7839161, 20.4397672],
                [105.7835675, 20.4391716],
                [105.7832571, 20.4385036],
                [105.7830606, 20.437618],
                [105.7829629, 20.4372927],
                [105.782866, 20.4370941],
                [105.7826347, 20.4369142],
                [105.7819805, 20.4365915],
                [105.7806928, 20.4362716],
                [105.7795018, 20.4360598],
                [105.7787902, 20.4359625],
                [105.7786337, 20.4359382],
                [105.7759897, 20.4356807],
                [105.7748124, 20.4354853],
                [105.774282, 20.4357339],
                [105.7739281, 20.4362045],
                [105.7738982, 20.4365923],
                [105.7739268, 20.4370911],
                [105.7738082, 20.4376174],
                [105.7736604, 20.4381159],
                [105.7735706, 20.4390578],
                [105.7738046, 20.4400556],
                [105.7738626, 20.4407483],
                [105.7738178, 20.4413973],
                [105.773466, 20.4420032],
                [105.7732182, 20.4423661],
                [105.7728545, 20.4425486],
                [105.7725667, 20.4425681],
                [105.771317, 20.4422117],
                [105.7691449, 20.4415699],
                [105.7674152, 20.4411253],
                [105.7663388, 20.4408405],
                [105.7647439, 20.4404857],
                [105.7636873, 20.4403093],
                [105.76265, 20.4401692],
                [105.7623811, 20.4401523],
                [105.7620171, 20.4402805],
                [105.7614049, 20.4406994],
                [105.7612334, 20.4409354],
                [105.7610436, 20.4413885],
                [105.7607953, 20.4416428],
                [105.7602591, 20.4418985],
                [105.7601336, 20.4419016],
                [105.7589114, 20.4420929],
                [105.7574834, 20.4421325],
                [105.7562766, 20.4419784],
                [105.7554376, 20.4418803],
                [105.7552023, 20.4417553],
                [105.7548937, 20.4414638],
                [105.7544236, 20.4407983],
                [105.7538209, 20.4402017],
                [105.7531446, 20.4396881],
                [105.7527523, 20.4394808],
                [105.7518769, 20.4391862],
                [105.7513679, 20.4389154],
                [105.7518721, 20.4376804],
                [105.7520781, 20.4371756],
                [105.7523993, 20.4361249],
                [105.7525686, 20.4354004],
                [105.7526985, 20.4344952],
                [105.7529202, 20.4327211],
                [105.7530676, 20.4314539],
                [105.7533124, 20.4304759],
                [105.7535776, 20.4297692],
                [105.7544518, 20.4279742],
                [105.7547335, 20.4267064],
                [105.7550344, 20.4254205],
                [105.7552229, 20.4247141],
                [105.7555989, 20.4231203],
                [105.7561451, 20.421001],
                [105.7563138, 20.4201499],
                [105.7566144, 20.4188278],
                [105.7571434, 20.4171067],
                [105.7589905, 20.4165867],
                [105.7591642, 20.4161495],
                [105.759129, 20.4134719],
                [105.7569981, 20.4134559],
                [105.7561403, 20.4134181],
                [105.7547755, 20.4134528],
                [105.7532794, 20.4135516],
                [105.7529566, 20.4134467],
                [105.7527139, 20.4132502],
                [105.7526322, 20.4130076],
                [105.752615, 20.4127799],
                [105.752725, 20.4122174],
                [105.7531526, 20.4106815],
                [105.7532785, 20.4100734],
                [105.7533253, 20.4097542],
                [105.7532919, 20.4095264],
                [105.7529993, 20.4089811],
                [105.7523326, 20.4077232],
                [105.752169, 20.4072227],
                [105.7521348, 20.4067975],
                [105.7521656, 20.4064936],
                [105.7525645, 20.4057173],
                [105.7533162, 20.4046052],
                [105.7538614, 20.4040712],
                [105.7544067, 20.4035524],
                [105.7547113, 20.4032473],
                [105.7549666, 20.4027298],
                [105.7554582, 20.4011177],
                [105.7558082, 20.4002351],
                [105.755903, 20.399938],
                [105.7553415, 20.3997459],
                [105.7541213, 20.3996649],
                [105.7531957, 20.3994257],
                [105.7530493, 20.3989696],
                [105.7532187, 20.3981769],
                [105.753389, 20.3969879],
                [105.7535167, 20.3960762],
                [105.7539804, 20.3955615],
                [105.7546548, 20.3948885],
                [105.7549501, 20.3943735],
                [105.7550777, 20.3935809],
                [105.7549732, 20.3930852],
                [105.7546372, 20.3926881],
                [105.7543857, 20.3921328],
                [105.7544707, 20.3915779],
                [105.7545138, 20.3908644],
                [105.7543887, 20.3901903],
                [105.7543897, 20.3895559],
                [105.7546855, 20.3887634],
                [105.7551491, 20.3882884],
                [105.7559915, 20.3878534],
                [105.7570913, 20.3871116],
                [105.7571656, 20.386189],
                [105.7571756, 20.3851943],
                [105.7571716, 20.3843817],
                [105.7572291, 20.3839892],
                [105.7573766, 20.3837504],
                [105.7577606, 20.3832864],
                [105.7581737, 20.3826821],
                [105.7586074, 20.38184],
                [105.7589979, 20.3810814],
                [105.7593798, 20.3801972],
                [105.7595859, 20.379804],
                [105.7598199, 20.3790324],
                [105.7598632, 20.3787661],
                [105.7598315, 20.3783739],
                [105.759695, 20.3778141],
                [105.7585397, 20.3755074],
                [105.7578646, 20.3741375],
                [105.7569488, 20.3721799],
                [105.7568125, 20.3716622],
                [105.7566893, 20.3707662],
                [105.7566557, 20.3699957],
                [105.7567113, 20.3692109],
                [105.7567961, 20.3682999],
                [105.7568353, 20.367207],
                [105.7518712, 20.3668114],
                [105.7465638, 20.3664987],
                [105.7461992, 20.3665004],
                [105.7453089, 20.3692245],
                [105.7450459, 20.3705409],
                [105.744876, 20.3714722],
                [105.7448779, 20.371861],
                [105.7451979, 20.3725029],
                [105.7453466, 20.3737586],
                [105.7454772, 20.3745508],
                [105.745606, 20.3749691],
                [105.7458773, 20.3753568],
                [105.7458787, 20.3756559],
                [105.7456618, 20.3766889],
                [105.7456984, 20.3776909],
                [105.7458153, 20.3789618],
                [105.7459158, 20.3800832],
                [105.7457507, 20.3819834],
                [105.7455627, 20.382448],
                [105.7434602, 20.3835938],
                [105.7419103, 20.3843035],
                [105.7394711, 20.3843058],
                [105.7376393, 20.3840991],
                [105.7360537, 20.3842787],
                [105.7356859, 20.3845102],
                [105.7352559, 20.3847807],
                [105.7325304, 20.3850166],
                [105.731249, 20.3855901],
                [105.7305219, 20.3860268],
                [105.7304273, 20.3861467],
                [105.7303595, 20.3885689],
                [105.7303035, 20.390158],
                [105.7304241, 20.3921921],
                [105.7308929, 20.394105],
                [105.7316956, 20.3962408],
                [105.7320993, 20.3978099],
                [105.7322448, 20.3983928],
                [105.7322293, 20.3984975],
                [105.7321038, 20.3987524],
                [105.7318037, 20.3990079],
                [105.7302829, 20.399224],
                [105.7290631, 20.3994536],
                [105.7284462, 20.3997554],
                [105.7282416, 20.4000853],
                [105.7281504, 20.4009085],
                [105.7281217, 20.4015519],
                [105.727901, 20.4018222],
                [105.7259351, 20.4018305],
                [105.7256345, 20.4019514],
                [105.7234713, 20.4038605],
                [105.72164, 20.4055438],
                [105.7195881, 20.4075421],
                [105.7190512, 20.4080082],
                [105.7138343, 20.4078803],
                [105.7122181, 20.4080816],
                [105.7110951, 20.4086996],
                [105.7101633, 20.4094814],
                [105.7092637, 20.4104126],
                [105.7073029, 20.4115428],
                [105.7062112, 20.412041],
                [105.7059098, 20.4120123],
                [105.7039059, 20.4106741],
                [105.702762, 20.4101552],
                [105.7013968, 20.4098018],
                [105.7010005, 20.4098333],
                [105.6992608, 20.4107978],
                [105.6979959, 20.411566],
                [105.6975004, 20.41183],
                [105.6953176, 20.4118611],
                [105.6933358, 20.4118841],
                [105.6921464, 20.411844],
                [105.6906851, 20.4112815],
                [105.6895417, 20.4108373],
                [105.6887, 20.4105564],
                [105.6882877, 20.4105282],
                [105.6869402, 20.4105785],
                [105.6862901, 20.4105811],
                [105.6853706, 20.4103911],
                [105.6853976, 20.4093145],
                [105.6851061, 20.4079101],
                [105.6848484, 20.4070589],
                [105.684831, 20.4066702],
                [105.6849717, 20.4062211],
                [105.6859019, 20.4050212],
                [105.6875908, 20.4032351],
                [105.6879699, 20.4029045],
                [105.6884438, 20.402484],
                [105.6888237, 20.4023329],
                [105.6892679, 20.4023611],
                [105.6900304, 20.402687],
                [105.6911285, 20.4035647],
                [105.6914784, 20.4037876],
                [105.691669, 20.4038466],
                [105.6918321, 20.4038486],
                [105.6920375, 20.4036761],
                [105.692629, 20.4026748],
                [105.6928103, 20.4023464],
                [105.6930258, 20.402316],
                [105.6932478, 20.4023001],
                [105.6934737, 20.4023508],
                [105.6939053, 20.4027134],
                [105.6941451, 20.4028426],
                [105.6942955, 20.4028541],
                [105.6944033, 20.4027928],
                [105.6945448, 20.4026215],
                [105.6950697, 20.4014611],
                [105.6952313, 20.4009155],
                [105.6958304, 20.3996157],
                [105.6962857, 20.3987008],
                [105.696685, 20.3979201],
                [105.6968897, 20.3976053],
                [105.6970162, 20.3975301],
                [105.6971983, 20.3975022],
                [105.6979052, 20.3977507],
                [105.6981588, 20.3977197],
                [105.698317, 20.3976293],
                [105.6984432, 20.3974793],
                [105.6985052, 20.3971651],
                [105.6985032, 20.3967315],
                [105.6983748, 20.3964059],
                [105.6981679, 20.3962122],
                [105.6979611, 20.3960934],
                [105.6972947, 20.3959914],
                [105.6969298, 20.395918],
                [105.6966943, 20.3957947],
                [105.6964551, 20.3954665],
                [105.6962076, 20.3940057],
                [105.6960784, 20.3934975],
                [105.6958553, 20.3932291],
                [105.6956009, 20.3930587],
                [105.6951411, 20.3930973],
                [105.6942196, 20.3935397],
                [105.6936223, 20.3938516],
                [105.6930202, 20.3939588],
                [105.692576, 20.3939008],
                [105.692242, 20.3936927],
                [105.6920185, 20.3933494],
                [105.6919849, 20.3929306],
                [105.6920778, 20.3924364],
                [105.6924561, 20.3918961],
                [105.6928507, 20.3915205],
                [105.6934837, 20.3912487],
                [105.6948295, 20.3907943],
                [105.6951932, 20.3905833],
                [105.6956347, 20.3900129],
                [105.6958399, 20.3898027],
                [105.6963615, 20.3894564],
                [105.6966777, 20.3892306],
                [105.6967087, 20.3890809],
                [105.6966761, 20.3888866],
                [105.6964689, 20.388618],
                [105.69577, 20.3883665],
                [105.6951196, 20.3883093],
                [105.6942639, 20.3884175],
                [105.6941687, 20.3884029],
                [105.6940733, 20.3883285],
                [105.6940251, 20.3882239],
                [105.693977, 20.3880895],
                [105.6939763, 20.3879249],
                [105.6940709, 20.3877898],
                [105.6944982, 20.3876385],
                [105.6947197, 20.3875029],
                [105.6948139, 20.3872931],
                [105.69486, 20.3869937],
                [105.6949196, 20.3861255],
                [105.6949661, 20.3859009],
                [105.6950762, 20.3857059],
                [105.6953139, 20.3856452],
                [105.6960271, 20.3855973],
                [105.6962485, 20.3854618],
                [105.6964375, 20.3851917],
                [105.6966733, 20.3847269],
                [105.6968623, 20.3844417],
                [105.6971313, 20.3843209],
                [105.6979708, 20.3841381],
                [105.6986367, 20.3841353],
                [105.6990327, 20.3840738],
                [105.6992543, 20.3839533],
                [105.6994754, 20.3837728],
                [105.6996645, 20.3835176],
                [105.6998848, 20.3831127],
                [105.700024, 20.3823491],
                [105.7000735, 20.3818914],
                [105.7000716, 20.3814731],
                [105.7002218, 20.380976],
                [105.7007051, 20.3805819],
                [105.7018935, 20.3798321],
                [105.7034837, 20.3790546],
                [105.7038569, 20.37887],
                [105.7041055, 20.378686],
                [105.704188, 20.3785288],
                [105.7042013, 20.3783981],
                [105.7042008, 20.3782805],
                [105.7040187, 20.37785],
                [105.7036979, 20.37742],
                [105.7036412, 20.3771197],
                [105.7036954, 20.376858],
                [105.7039289, 20.376389],
                [105.7039825, 20.3759833],
                [105.7039901, 20.3746101],
                [105.7040991, 20.3742304],
                [105.7050664, 20.3735986],
                [105.7053327, 20.3732243],
                [105.7053803, 20.3731571],
                [105.7056447, 20.3727855],
                [105.7064699, 20.3714088],
                [105.7068125, 20.3705703],
                [105.7071153, 20.3700982],
                [105.7081769, 20.3689169],
                [105.7091284, 20.367932],
                [105.7095825, 20.367237],
                [105.7099552, 20.3668823],
                [105.7102593, 20.3667373],
                [105.7105226, 20.3667362],
                [105.710814, 20.3668265],
                [105.7112861, 20.3670469],
                [105.7114386, 20.3670593],
                [105.7117016, 20.3669929],
                [105.7119498, 20.3667302],
                [105.7121697, 20.366337],
                [105.7122647, 20.3658919],
                [105.7122627, 20.3654472],
                [105.7122051, 20.3649897],
                [105.7119532, 20.3644415],
                [105.71152, 20.3636586],
                [105.7109071, 20.3629418],
                [105.7105586, 20.3624987],
                [105.7095822, 20.3610771],
                [105.7095261, 20.3609465],
                [105.7095657, 20.3605279],
                [105.7096618, 20.3603314],
                [105.7099107, 20.3601864],
                [105.7103675, 20.3601061],
                [105.7107138, 20.3600785],
                [105.7109907, 20.360025],
                [105.7112119, 20.3599195],
                [105.7114047, 20.3596571],
                [105.7117204, 20.359015],
                [105.7119273, 20.3587919],
                [105.7123423, 20.3586593],
                [105.7128961, 20.3585524],
                [105.713173, 20.3584858],
                [105.7134351, 20.3582493],
                [105.7137376, 20.3577511],
                [105.7139572, 20.3572662],
                [105.7140928, 20.3566249],
                [105.7142683, 20.3556302],
                [105.71443, 20.3546225],
                [105.7146905, 20.3540198],
                [105.7149384, 20.3536918],
                [105.7150449, 20.3535828],
                [105.71549, 20.3531271],
                [105.7159319, 20.3528245],
                [105.7162777, 20.3526792],
                [105.7166101, 20.3526517],
                [105.7171232, 20.3527411],
                [105.7174554, 20.3527005],
                [105.7176352, 20.352595],
                [105.7178144, 20.3523981],
                [105.7180062, 20.3519396],
                [105.7183062, 20.350892],
                [105.7184962, 20.350015],
                [105.7187127, 20.3489155],
                [105.7189461, 20.3484438],
                [105.7194047, 20.3478803],
                [105.7194696, 20.3478007],
                [105.720338, 20.3468805],
                [105.7206555, 20.3466177],
                [105.7214571, 20.3461438],
                [105.7218436, 20.3458284],
                [105.7225608, 20.3451065],
                [105.7230156, 20.3445556],
                [105.7233993, 20.3436259],
                [105.7236731, 20.3429189],
                [105.7238789, 20.3424866],
                [105.7240713, 20.3421223],
                [105.72443, 20.3417938],
                [105.7249136, 20.341504],
                [105.7256749, 20.3412915],
                [105.726062, 20.3411198],
                [105.726338, 20.3408701],
                [105.7271652, 20.3399772],
                [105.727455, 20.3397274],
                [105.7277451, 20.3395299],
                [105.7283676, 20.3393179],
                [105.729157, 20.3391838],
                [105.7295724, 20.3390898],
                [105.7291518, 20.3380717],
                [105.7290998, 20.3376993],
                [105.7290514, 20.3373532],
                [105.7289084, 20.3364124],
                [105.7287677, 20.3359423],
                [105.7285867, 20.3357862],
                [105.7281706, 20.3357096],
                [105.7277825, 20.3356981],
                [105.7275046, 20.3355555],
                [105.7273653, 20.3353861],
                [105.7272951, 20.3351902],
                [105.7273319, 20.3341704],
                [105.727193, 20.3331783],
                [105.7265894, 20.3323611],
                [105.7256941, 20.3316331],
                [105.725477, 20.3314154],
                [105.7253058, 20.3311394],
                [105.7251806, 20.3307757],
                [105.7250241, 20.3303392],
                [105.7248215, 20.3298884],
                [105.7245574, 20.3295399],
                [105.7228578, 20.3286277],
                [105.7198173, 20.3275341],
                [105.7185717, 20.3270265],
                [105.7181984, 20.3269373],
                [105.7173757, 20.3266954],
                [105.7158143, 20.3261919],
                [105.7135417, 20.3254728],
                [105.7122138, 20.3249405],
                [105.7117786, 20.3247662],
                [105.7095994, 20.3241777],
                [105.7056017, 20.322653],
                [105.7032202, 20.3217302],
                [105.7018975, 20.3212737],
                [105.7015419, 20.3211295],
                [105.7010002, 20.3208402],
                [105.7008452, 20.3206952],
                [105.7004415, 20.3201724],
                [105.6996495, 20.3191267],
                [105.6980054, 20.3164731],
                [105.6969482, 20.3147728],
                [105.6965747, 20.3141042],
                [105.6964493, 20.3136677],
                [105.696061, 20.3126546],
                [105.695905, 20.3122764],
                [105.6956875, 20.3119714],
                [105.6952848, 20.3116525],
                [105.6946658, 20.3112908],
                [105.6931402, 20.3107037],
                [105.6927426, 20.3105001],
                [105.6926606, 20.3104579],
                [105.6919181, 20.3100967],
                [105.6913607, 20.309691],
                [105.6906784, 20.3090236],
                [105.6895293, 20.3074548],
                [105.6883402, 20.306201],
                [105.6878448, 20.3058679],
                [105.6873653, 20.3056366],
                [105.6868242, 20.305464],
                [105.686191, 20.3053645],
                [105.6837676, 20.3053594],
                [105.6774234, 20.3060273],
                [105.6762999, 20.3062438],
                [105.6756229, 20.3067126],
                [105.6749786, 20.307633],
                [105.6742568, 20.3084952],
                [105.6735797, 20.308964],
                [105.6730557, 20.3091408],
                [105.6724075, 20.309158],
                [105.671666, 20.3090734],
                [105.6711724, 20.3091189],
                [105.6704638, 20.3094276],
                [105.6690182, 20.3106714],
                [105.6675374, 20.3121697],
                [105.666569, 20.3131204],
                [105.6659394, 20.3138803],
                [105.6656953, 20.3145513],
                [105.6651793, 20.3162639],
                [105.6648727, 20.3167459],
                [105.6632263, 20.3179903],
                [105.6628623, 20.3183482],
                [105.6613816, 20.3198038],
                [105.6603092, 20.32078],
                [105.6579843, 20.3222019],
                [105.6566605, 20.3230955],
                [105.6555902, 20.3241854],
                [105.6549895, 20.3245082],
                [105.6534021, 20.3251405],
                [105.6525706, 20.3256244],
                [105.6509858, 20.3269123],
                [105.6493568, 20.328652],
                [105.6485804, 20.3302377],
                [105.647568, 20.3318149],
                [105.6470504, 20.3323892],
                [105.6449365, 20.3335013],
                [105.6442069, 20.3340919],
                [105.6440042, 20.3342848],
                [105.644005, 20.3345044],
                [105.6443861, 20.3352166],
                [105.6444161, 20.3354635],
                [105.6443881, 20.3357108],
                [105.6442437, 20.3359309],
                [105.6434613, 20.3365926],
                [105.6430862, 20.3373077],
                [105.6425964, 20.3384075],
                [105.642364, 20.3384358],
                [105.6403871, 20.338553],
                [105.6398065, 20.3387748],
                [105.6390816, 20.3392716],
                [105.6381843, 20.3402905],
                [105.6369696, 20.3419145],
                [105.6361301, 20.3428234],
                [105.6354637, 20.343375],
                [105.6342739, 20.3440107],
                [105.6340998, 20.3441212],
                [105.6284973, 20.3465298],
                [105.6265839, 20.3479642],
                [105.6258599, 20.3487354],
                [105.6255697, 20.3488738],
                [105.6253083, 20.3489571],
                [105.62493, 20.3489036],
                [105.6244923, 20.3485209],
                [105.6236165, 20.3476731],
                [105.6232084, 20.3474275],
                [105.6226558, 20.3474296],
                [105.6219302, 20.3477341],
                [105.6182134, 20.34912],
                [105.617024, 20.3498655],
                [105.6164753, 20.3508007],
                [105.6158954, 20.351242],
                [105.6132275, 20.3531456],
                [105.6122714, 20.3540548],
                [105.6115468, 20.3546613],
                [105.6102724, 20.3559835],
                [105.6093733, 20.3565905],
                [105.6078358, 20.3575842],
                [105.6072277, 20.3582451],
                [105.6068517, 20.358768],
                [105.6063625, 20.3601147],
                [105.6060733, 20.3605275],
                [105.6054641, 20.360914],
                [105.6043026, 20.3613573],
                [105.6014567, 20.3624652],
                [105.6001225, 20.3634031],
                [105.5991074, 20.3641204],
                [105.5986423, 20.3641496],
                [105.5982637, 20.3640686],
                [105.5973034, 20.3639072],
                [105.5967513, 20.3640464],
                [105.5928292, 20.3651305],
                [105.591348, 20.3656846],
                [105.5862078, 20.3676512],
                [105.5843599, 20.3686254],
                [105.5817366, 20.3696978],
                [105.5811552, 20.3697821],
                [105.5807476, 20.3696737],
                [105.5803102, 20.3693732],
                [105.579726, 20.3686889],
                [105.5771827, 20.3652114],
                [105.5762773, 20.3641715],
                [105.5744977, 20.3626677],
                [105.5720751, 20.3603152],
                [105.5710257, 20.3596599],
                [105.5702974, 20.3593056],
                [105.5697445, 20.3592251],
                [105.5691049, 20.359282],
                [105.5673025, 20.3594802],
                [105.5663127, 20.3591815],
                [105.563808, 20.3582016],
                [105.5628476, 20.3580126],
                [105.562179, 20.3580697],
                [105.561104, 20.3583752],
                [105.5598545, 20.3586538],
                [105.5585453, 20.3585208],
                [105.5567132, 20.3585267],
                [105.5561324, 20.3587758],
                [105.5548865, 20.3600974],
                [105.5541905, 20.3606212],
                [105.553464, 20.3607882],
                [105.551719, 20.3607938],
                [105.5510794, 20.3608509],
                [105.5496552, 20.361075],
                [105.5479121, 20.3616022],
                [105.5467795, 20.3620724],
                [105.5457336, 20.3624052],
                [105.5416957, 20.3637357],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [104.814289, 21.3333137],
                [104.8154007, 21.3303896],
                [104.8164609, 21.3287399],
                [104.8168161, 21.3266769],
                [104.8176127, 21.3242017],
                [104.8192908, 21.3218926],
                [104.8228654, 21.3187187],
                [104.8259539, 21.3164934],
                [104.8284241, 21.3150929],
                [104.8306295, 21.3139396],
                [104.8332748, 21.3136121],
                [104.8376839, 21.312791],
                [104.8405059, 21.3120509],
                [104.8427562, 21.3101134],
                [104.8432863, 21.3090409],
                [104.8437277, 21.3083811],
                [104.8438173, 21.3070606],
                [104.8437306, 21.3056574],
                [104.843557, 21.3031811],
                [104.8435188, 21.2976099],
                [104.8431694, 21.2945557],
                [104.8422052, 21.289355],
                [104.8419893, 21.2851867],
                [104.841818, 21.2804819],
                [104.8427393, 21.2761301],
                [104.8430584, 21.274623],
                [104.8439864, 21.2721065],
                [104.8439004, 21.2701256],
                [104.8424939, 21.2666577],
                [104.8419674, 21.2645112],
                [104.8418824, 21.2615398],
                [104.8424127, 21.2601372],
                [104.8436043, 21.2584463],
                [104.8467785, 21.2569635],
                [104.8496879, 21.2558932],
                [104.8507465, 21.2548212],
                [104.8511, 21.253831],
                [104.8511888, 21.2531709],
                [104.8507498, 21.2515197],
                [104.8496941, 21.2497855],
                [104.8493433, 21.2480519],
                [104.8494708, 21.2474418],
                [104.8500075, 21.2448748],
                [104.8516859, 21.2407494],
                [104.8537173, 21.2361292],
                [104.8558353, 21.2327469],
                [104.856277, 21.2315918],
                [104.8561893, 21.2312616],
                [104.8560135, 21.2307662],
                [104.8555729, 21.2307658],
                [104.8552206, 21.2306829],
                [104.8541629, 21.2310122],
                [104.8530167, 21.231754],
                [104.8522231, 21.2322486],
                [104.8506377, 21.2315869],
                [104.8499778, 21.2306372],
                [104.849802, 21.2301418],
                [104.8500678, 21.2288214],
                [104.8502454, 21.2274184],
                [104.8498935, 21.2268404],
                [104.84822, 21.2262611],
                [104.8463707, 21.225269],
                [104.8457545, 21.2246906],
                [104.8454907, 21.2241127],
                [104.8454911, 21.2237825],
                [104.8467257, 21.2227106],
                [104.8492816, 21.2220527],
                [104.8509565, 21.2213113],
                [104.8545703, 21.2199113],
                [104.8570834, 21.2178088],
                [104.8585831, 21.2158291],
                [104.8602588, 21.2140147],
                [104.8616719, 21.2103017],
                [104.8642292, 21.2077451],
                [104.8645613, 21.2070209],
                [104.8639655, 21.2048081],
                [104.8634827, 21.204218],
                [104.8632681, 21.2033378],
                [104.862911, 21.2021713],
                [104.8627746, 21.2017874],
                [104.862163, 21.200397],
                [104.8613262, 21.200317],
                [104.8613047, 21.1996768],
                [104.8610902, 21.1989566],
                [104.8610258, 21.1977563],
                [104.8603606, 21.1954756],
                [104.8599783, 21.194459],
                [104.8596732, 21.1934368],
                [104.8595881, 21.1930948],
                [104.8592196, 21.1907745],
                [104.8588478, 21.190484],
                [104.8589435, 21.1892945],
                [104.858898, 21.1891359],
                [104.8588264, 21.1890485],
                [104.8587345, 21.1889881],
                [104.8586277, 21.1889554],
                [104.8585153, 21.1889534],
                [104.8583566, 21.1888793],
                [104.8582347, 21.1887551],
                [104.8581686, 21.1885967],
                [104.8581612, 21.1886433],
                [104.8581935, 21.187624],
                [104.8582378, 21.1874295],
                [104.8583405, 21.1871862],
                [104.8583702, 21.1870641],
                [104.8583602, 21.1869393],
                [104.8583405, 21.1868793],
                [104.8582748, 21.186772],
                [104.858124, 21.1866566],
                [104.8580003, 21.1866176],
                [104.8575496, 21.1866126],
                [104.8570201, 21.1862573],
                [104.8566967, 21.1859859],
                [104.8562622, 21.1855322],
                [104.8559061, 21.1850472],
                [104.8556185, 21.1845242],
                [104.8554039, 21.1839716],
                [104.8529577, 21.1814506],
                [104.8510534, 21.17989],
                [104.8515539, 21.1797164],
                [104.8520425, 21.1795154],
                [104.8524785, 21.1793076],
                [104.8528638, 21.1790996],
                [104.8532779, 21.1790473],
                [104.8536873, 21.1789696],
                [104.853924, 21.178907],
                [104.8541499, 21.1788159],
                [104.8543609, 21.1786979],
                [104.8545527, 21.1785557],
                [104.8547222, 21.1783921],
                [104.8548674, 21.1782093],
                [104.8551293, 21.1780315],
                [104.8553236, 21.1779434],
                [104.8557848, 21.1777892],
                [104.8560047, 21.1776691],
                [104.8564682, 21.1773622],
                [104.8569811, 21.1771071],
                [104.857357, 21.1769669],
                [104.8580068, 21.1767802],
                [104.8582966, 21.176657],
                [104.8585704, 21.1765053],
                [104.8588252, 21.176327],
                [104.8590606, 21.1761212],
                [104.8592732, 21.1758897],
                [104.8594569, 21.1756376],
                [104.8596096, 21.1753681],
                [104.8597759, 21.1751161],
                [104.8599059, 21.1748462],
                [104.8599973, 21.1745629],
                [104.8600483, 21.1742735],
                [104.8600563, 21.1738378],
                [104.8600175, 21.1735494],
                [104.8598941, 21.1731277],
                [104.8597696, 21.1728597],
                [104.8595238, 21.1724868],
                [104.8585474, 21.1713362],
                [104.8583832, 21.1710428],
                [104.8582558, 21.1707339],
                [104.8581672, 21.1704134],
                [104.8581377, 21.1702502],
                [104.8581091, 21.1697783],
                [104.8581609, 21.1693199],
                [104.8582899, 21.168875],
                [104.8564768, 21.1662437],
                [104.855994, 21.1660036],
                [104.8548353, 21.164232],
                [104.8546785, 21.1639284],
                [104.8545425, 21.1635984],
                [104.8544324, 21.1632429],
                [104.85439, 21.1630621],
                [104.854461, 21.1628689],
                [104.8545033, 21.1626686],
                [104.8545161, 21.1624589],
                [104.8544965, 21.1622438],
                [104.8542307, 21.1613136],
                [104.8539904, 21.1606957],
                [104.8537946, 21.1602906],
                [104.8528504, 21.1596203],
                [104.8525071, 21.1568387],
                [104.8525607, 21.1547875],
                [104.8524518, 21.1545262],
                [104.852379, 21.1542542],
                [104.8523401, 21.1538355],
                [104.8523616, 21.1535558],
                [104.8524208, 21.1532809],
                [104.8525185, 21.1530108],
                [104.8526551, 21.1527505],
                [104.8528269, 21.152509],
                [104.8529251, 21.1523966],
                [104.853144, 21.152191],
                [104.8532638, 21.1520986],
                [104.8535214, 21.1519367],
                [104.8536582, 21.1518678],
                [104.8539448, 21.1517558],
                [104.8544026, 21.1504216],
                [104.8546071, 21.1500655],
                [104.8547709, 21.1498591],
                [104.8549586, 21.1496713],
                [104.8575067, 21.1476832],
                [104.8577811, 21.1475194],
                [104.8586534, 21.14706],
                [104.8592866, 21.1467797],
                [104.8599037, 21.146529],
                [104.8604824, 21.1462629],
                [104.8617768, 21.1455819],
                [104.8618999, 21.1455324],
                [104.8620316, 21.1455086],
                [104.862167, 21.145512],
                [104.8622999, 21.1455432],
                [104.8624221, 21.1456008],
                [104.8625278, 21.145682],
                [104.8631877, 21.1464375],
                [104.8632477, 21.1464846],
                [104.8633802, 21.1465628],
                [104.8635256, 21.1466176],
                [104.8637932, 21.1466521],
                [104.8640593, 21.1466084],
                [104.864223, 21.1465375],
                [104.8657036, 21.145692],
                [104.8658876, 21.1456052],
                [104.8664597, 21.1453863],
                [104.8668547, 21.1452759],
                [104.8674623, 21.1451654],
                [104.8678736, 21.1451291],
                [104.8707407, 21.1451266],
                [104.8709645, 21.1450952],
                [104.8710734, 21.1450662],
                [104.8712811, 21.1449823],
                [104.8713784, 21.1449282],
                [104.8716138, 21.1447438],
                [104.8717363, 21.1446027],
                [104.8718351, 21.1444462],
                [104.8720926, 21.1403435],
                [104.876191, 21.1385222],
                [104.8769367, 21.1375565],
                [104.8776877, 21.1369261],
                [104.8786318, 21.1362656],
                [104.8796832, 21.1350348],
                [104.8797987, 21.1349169],
                [104.8801831, 21.1346],
                [104.8804673, 21.1344223],
                [104.8807726, 21.1342728],
                [104.8810958, 21.1341538],
                [104.8816015, 21.1340381],
                [104.8851339, 21.1336353],
                [104.8856419, 21.1335359],
                [104.8859703, 21.1334336],
                [104.886435, 21.1332312],
                [104.8867264, 21.1330643],
                [104.8870003, 21.1328732],
                [104.8878372, 21.1323528],
                [104.8882145, 21.1321814],
                [104.888604, 21.1320356],
                [104.8892177, 21.1318641],
                [104.8896401, 21.1317843],
                [104.8900676, 21.1317336],
                [104.8904979, 21.1317124],
                [104.8916724, 21.1314505],
                [104.8928368, 21.1311519],
                [104.8939055, 21.1308428],
                [104.8949634, 21.1305027],
                [104.8960094, 21.1301319],
                [104.8970425, 21.1297309],
                [104.8970361, 21.1295035],
                [104.8969979, 21.1292776],
                [104.896928, 21.1290569],
                [104.8966241, 21.1283699],
                [104.8965865, 21.1281309],
                [104.8965812, 21.1278895],
                [104.8965519, 21.1276791],
                [104.8964832, 21.1274768],
                [104.8963773, 21.127289],
                [104.8963335, 21.1271791],
                [104.8962727, 21.1269518],
                [104.8962486, 21.1267186],
                [104.8962608, 21.1264913],
                [104.896308, 21.1262681],
                [104.8963891, 21.1260535],
                [104.8965024, 21.125852],
                [104.8966455, 21.1256678],
                [104.8991454, 21.1236762],
                [104.8994388, 21.123467],
                [104.8997462, 21.1232759],
                [104.9000729, 21.1231005],
                [104.9010221, 21.1226525],
                [104.9015057, 21.1224052],
                [104.9019175, 21.1221589],
                [104.9023115, 21.1218883],
                [104.9029021, 21.1214107],
                [104.9031694, 21.1211067],
                [104.9033043, 21.1209013],
                [104.9034154, 21.1206838],
                [104.9035176, 21.1205648],
                [104.9037433, 21.1203442],
                [104.9041212, 21.1200675],
                [104.9045395, 21.1198562],
                [104.9052882, 21.1195804],
                [104.9057221, 21.1193728],
                [104.9062776, 21.1190377],
                [104.9068865, 21.1186021],
                [104.9071714, 21.1184536],
                [104.9073207, 21.1183919],
                [104.9077681, 21.1182641],
                [104.9082327, 21.1182118],
                [104.9107003, 21.1182618],
                [104.9118268, 21.1186121],
                [104.9123096, 21.11604],
                [104.9123574, 21.1154653],
                [104.9124406, 21.1149076],
                [104.913007, 21.1124269],
                [104.9119381, 21.1113489],
                [104.9116975, 21.1111463],
                [104.9114858, 21.1109172],
                [104.9113065, 21.1106654],
                [104.9111767, 21.110426],
                [104.9110762, 21.1101748],
                [104.9110061, 21.1099147],
                [104.9109849, 21.1096622],
                [104.9110061, 21.1094943],
                [104.9111025, 21.1092281],
                [104.9112691, 21.1089941],
                [104.9114138, 21.1088638],
                [104.9119127, 21.1085135],
                [104.9120958, 21.1083323],
                [104.912256, 21.1081331],
                [104.9124596, 21.1077827],
                [104.9125564, 21.1075326],
                [104.9126881, 21.1071077],
                [104.9128997, 21.1067118],
                [104.9130855, 21.1064664],
                [104.9133021, 21.1062441],
                [104.9135463, 21.1060482],
                [104.9136776, 21.1059612],
                [104.9136407, 21.1054661],
                [104.9136814, 21.1049628],
                [104.9140102, 21.103559],
                [104.913699, 21.1022277],
                [104.9136768, 21.1020806],
                [104.9136527, 21.1017842],
                [104.9136561, 21.101487],
                [104.9136962, 21.1011331],
                [104.9137308, 21.100958],
                [104.913829, 21.100614],
                [104.9138921, 21.1004461],
                [104.9149865, 21.0981839],
                [104.9153943, 21.0972425],
                [104.9157313, 21.0962975],
                [104.9159139, 21.0956823],
                [104.9160701, 21.0950608],
                [104.9180335, 21.0936795],
                [104.9180335, 21.0928987],
                [104.9180742, 21.0926883],
                [104.9181467, 21.0924855],
                [104.9182555, 21.092285],
                [104.9184029, 21.0920938],
                [104.9185807, 21.0919266],
                [104.9192379, 21.0915007],
                [104.9199603, 21.0911077],
                [104.920742, 21.0907604],
                [104.9215525, 21.0904763],
                [104.9218253, 21.0903639],
                [104.9223577, 21.0901134],
                [104.9228706, 21.0898295],
                [104.9231189, 21.0896755],
                [104.9235182, 21.0894026],
                [104.9237115, 21.0892583],
                [104.923816, 21.0891575],
                [104.9240887, 21.0888232],
                [104.9242334, 21.0885776],
                [104.9243436, 21.0883241],
                [104.9244202, 21.088067],
                [104.92468, 21.0867224],
                [104.9249697, 21.0862519],
                [104.925195, 21.0856113],
                [104.9263859, 21.0842999],
                [104.9251628, 21.0827382],
                [104.9230866, 21.0814951],
                [104.9228794, 21.0813018],
                [104.9227425, 21.0810788],
                [104.9226884, 21.0809206],
                [104.9222467, 21.0788551],
                [104.9222338, 21.078674],
                [104.9222553, 21.0784936],
                [104.9223102, 21.0783201],
                [104.9223969, 21.0781582],
                [104.9225743, 21.0779216],
                [104.9227164, 21.0776263],
                [104.922791, 21.0773037],
                [104.922791, 21.0769706],
                [104.9227488, 21.0767517],
                [104.9220085, 21.0748997],
                [104.9217843, 21.0746241],
                [104.9215023, 21.0744029],
                [104.9211797, 21.0742464],
                [104.9209476, 21.07418],
                [104.9178135, 21.0738685],
                [104.9159145, 21.074349],
                [104.9126959, 21.0721265],
                [104.9112582, 21.0708851],
                [104.9110436, 21.0686826],
                [104.910357, 21.067281],
                [104.9133825, 21.0656991],
                [104.9136613, 21.0655808],
                [104.9139511, 21.0654888],
                [104.9143839, 21.0654041],
                [104.9145305, 21.0653887],
                [104.9153459, 21.0653787],
                [104.9158303, 21.0654286],
                [104.9163332, 21.0655483],
                [104.9166655, 21.0656691],
                [104.9182212, 21.0663398],
                [104.919852, 21.0663499],
                [104.9227703, 21.0675312],
                [104.9229526, 21.0667003],
                [104.9229919, 21.0665876],
                [104.9230921, 21.0663699],
                [104.9232919, 21.0660709],
                [104.9233711, 21.0659794],
                [104.9247444, 21.0651685],
                [104.927448, 21.0654288],
                [104.9322116, 21.063907],
                [104.9320731, 21.063612],
                [104.9319736, 21.0633036],
                [104.9319145, 21.0629863],
                [104.9319005, 21.0628257],
                [104.9319031, 21.0625103],
                [104.9319455, 21.0621974],
                [104.9319815, 21.0620432],
                [104.9320823, 21.0617422],
                [104.9322205, 21.0614543],
                [104.932394, 21.0611837],
                [104.9341643, 21.0593715],
                [104.934454, 21.0584504],
                [104.936353, 21.0570286],
                [104.9379408, 21.0558071],
                [104.9381405, 21.0557347],
                [104.9383495, 21.055691],
                [104.9386725, 21.0556813],
                [104.9388886, 21.0557133],
                [104.939097, 21.0557758],
                [104.9392927, 21.0558672],
                [104.9412453, 21.0571888],
                [104.941932, 21.0569385],
                [104.9432945, 21.0553866],
                [104.9451506, 21.0551463],
                [104.9465239, 21.0560374],
                [104.9504721, 21.0573991],
                [104.9515772, 21.0573991],
                [104.9518776, 21.0571187],
                [104.9552143, 21.0566982],
                [104.9568987, 21.0567483],
                [104.9575866, 21.0570894],
                [104.9582275, 21.0574811],
                [104.9588084, 21.0579097],
                [104.9592682, 21.0583134],
                [104.9597514, 21.0586924],
                [104.9605172, 21.0592119],
                [104.9610366, 21.0595153],
                [104.9615566, 21.059784],
                [104.9620911, 21.0600266],
                [104.9626386, 21.0602426],
                [104.9640012, 21.0596919],
                [104.965868, 21.0591713],
                [104.9659884, 21.0591271],
                [104.9662171, 21.0590142],
                [104.966324, 21.0589461],
                [104.9665201, 21.0587886],
                [104.9666081, 21.0587],
                [104.9668157, 21.0584195],
                [104.9669524, 21.0581146],
                [104.9670052, 21.0578997],
                [104.9670696, 21.0571788],
                [104.9676061, 21.0568083],
                [104.9687111, 21.0531437],
                [104.9693877, 21.0527459],
                [104.969858, 21.0525115],
                [104.9706079, 21.0522009],
                [104.9714245, 21.0519424],
                [104.9722631, 21.0517557],
                [104.972831, 21.0516719],
                [104.9743625, 21.0521149],
                [104.9785132, 21.0517193],
                [104.9797775, 21.0516551],
                [104.9807827, 21.0516393],
                [104.9812667, 21.0515849],
                [104.9817359, 21.0514616],
                [104.98203, 21.0513448],
                [104.9823099, 21.0512008],
                [104.9825728, 21.0510311],
                [104.983753, 21.0501099],
                [104.9869501, 21.0496993],
                [104.9885809, 21.0488933],
                [104.9923339, 21.0457477],
                [104.9926996, 21.0454774],
                [104.9930874, 21.0452354],
                [104.9937014, 21.0449304],
                [104.9943426, 21.0446983],
                [104.9947845, 21.0445837],
                [104.9952347, 21.0445021],
                [104.9959195, 21.0444425],
                [104.9976093, 21.0434812],
                [104.9976887, 21.0429084],
                [104.997797, 21.0423397],
                [104.9978662, 21.0420709],
                [104.9979701, 21.0418119],
                [104.9981875, 21.0414506],
                [104.9983703, 21.041233],
                [104.998695, 21.0409493],
                [104.998941, 21.0407927],
                [104.9992058, 21.0406654],
                [104.9994853, 21.0405696],
                [104.999923, 21.0404876],
                [105.0046849, 21.0401017],
                [105.0071043, 21.03883],
                [105.0100279, 21.0369824],
                [105.0101917, 21.0368859],
                [105.01035, 21.0367815],
                [105.0105022, 21.0366696],
                [105.0106479, 21.0365504],
                [105.0107868, 21.0364242],
                [105.0109184, 21.0362915],
                [105.0110323, 21.0361643],
                [105.0111397, 21.0360322],
                [105.0112401, 21.0358954],
                [105.0113334, 21.0357543],
                [105.0114193, 21.0356091],
                [105.0114977, 21.0354603],
                [105.0121415, 21.0340984],
                [105.0142926, 21.0315198],
                [105.016175, 21.0314523],
                [105.0165673, 21.0314399],
                [105.0168158, 21.031292],
                [105.0172735, 21.0304536],
                [105.0181954, 21.0289431],
                [105.0193395, 21.0274081],
                [105.0203574, 21.0253741],
                [105.0204462, 21.0240134],
                [105.0205501, 21.0232994],
                [105.0207416, 21.0226464],
                [105.0212756, 21.0219214],
                [105.0213736, 21.0212865],
                [105.0211719, 21.0204496],
                [105.0205399, 21.0193342],
                [105.0198574, 21.0186935],
                [105.0191749, 21.018219],
                [105.0188968, 21.0175545],
                [105.0185976, 21.0150383],
                [105.0201941, 21.0156227],
                [105.0212007, 21.0161574],
                [105.0232525, 21.0178154],
                [105.0247664, 21.0194795],
                [105.0263171, 21.0209987],
                [105.0271518, 21.0214572],
                [105.0281393, 21.0217591],
                [105.0287395, 21.0216455],
                [105.0293323, 21.0217415],
                [105.029618, 21.0220314],
                [105.0299057, 21.0221177],
                [105.0300758, 21.0221424],
                [105.0304172, 21.0221049],
                [105.0310972, 21.0218335],
                [105.0319718, 21.0212297],
                [105.0327433, 21.0205135],
                [105.0341285, 21.0199393],
                [105.0346851, 21.019497],
                [105.0351753, 21.0188497],
                [105.0354564, 21.018264],
                [105.0358615, 21.0170557],
                [105.0362275, 21.0163282],
                [105.0366915, 21.015422],
                [105.0370217, 21.0145535],
                [105.0371934, 21.0143693],
                [105.0374681, 21.0140087],
                [105.0375785, 21.013144],
                [105.0383515, 21.0122779],
                [105.0386915, 21.0120312],
                [105.0395152, 21.0115872],
                [105.0399663, 21.0114329],
                [105.0410908, 21.0108903],
                [105.0416661, 21.0105079],
                [105.0421629, 21.0100763],
                [105.0428231, 21.0097124],
                [105.0440672, 21.0094163],
                [105.0447191, 21.0094162],
                [105.0456608, 21.0095391],
                [105.0465696, 21.0095327],
                [105.047107, 21.0091132],
                [105.0482574, 21.0084472],
                [105.0489346, 21.0082379],
                [105.0500786, 21.0079602],
                [105.0509171, 21.0079965],
                [105.0513225, 21.0081198],
                [105.052134, 21.0089167],
                [105.0526569, 21.009685],
                [105.0535399, 21.0103814],
                [105.0539976, 21.0105661],
                [105.0543768, 21.0105536],
                [105.0546906, 21.0104796],
                [105.0552137, 21.0102945],
                [105.0557366, 21.0099492],
                [105.0561549, 21.0094311],
                [105.0571546, 21.0079329],
                [105.058259, 21.0064101],
                [105.0589772, 21.00528],
                [105.0598437, 21.0046493],
                [105.0612749, 21.0030892],
                [105.0627751, 21.001927],
                [105.0631019, 21.001705],
                [105.0640236, 21.0013656],
                [105.0645336, 21.0013654],
                [105.0656965, 21.0020212],
                [105.0665857, 21.0021811],
                [105.0671947, 21.001765],
                [105.0678745, 21.001247],
                [105.0684756, 21.0005439],
                [105.0687239, 21.0002726],
                [105.0691657, 20.9998575],
                [105.0701503, 20.9990424],
                [105.0705428, 20.9985809],
                [105.0706015, 20.998212],
                [105.070621, 20.9979167],
                [105.070562, 20.9976953],
                [105.0700906, 20.9971421],
                [105.069953, 20.9968838],
                [105.0699725, 20.9967178],
                [105.0701687, 20.9961826],
                [105.070973, 20.9949092],
                [105.071012, 20.9945125],
                [105.0710118, 20.9940513],
                [105.0710509, 20.9937007],
                [105.0711686, 20.9933869],
                [105.07162, 20.992907],
                [105.0723067, 20.9920581],
                [105.0731504, 20.9910614],
                [105.0738372, 20.9902125],
                [105.0748771, 20.9891327],
                [105.0755836, 20.9884312],
                [105.0762899, 20.9876006],
                [105.076898, 20.9865671],
                [105.0772117, 20.9858844],
                [105.0775254, 20.985137],
                [105.0780158, 20.9842512],
                [105.0789573, 20.9828485],
                [105.0798598, 20.981575],
                [105.0805659, 20.9803662],
                [105.0811345, 20.9791666],
                [105.0817033, 20.9782807],
                [105.0825469, 20.9773026],
                [105.0840184, 20.9757797],
                [105.0846854, 20.9748938],
                [105.0861764, 20.9732878],
                [105.0869807, 20.9722819],
                [105.0878243, 20.9716356],
                [105.0887074, 20.9711002],
                [105.0890998, 20.9707125],
                [105.0894135, 20.9701589],
                [105.0897271, 20.9693285],
                [105.0898051, 20.968535],
                [105.089559, 20.9681436],
                [105.0890386, 20.9674661],
                [105.0887834, 20.966755],
                [105.0887244, 20.9666261],
                [105.0887437, 20.9661463],
                [105.0889788, 20.9653897],
                [105.0890206, 20.9651521],
                [105.0895081, 20.9640796],
                [105.0903904, 20.9625937],
                [105.0910572, 20.9615232],
                [105.0914495, 20.9610803],
                [105.0921557, 20.9603972],
                [105.0931565, 20.9598985],
                [105.09453, 20.959206],
                [105.0962178, 20.9587436],
                [105.098239, 20.9578569],
                [105.0990435, 20.9574874],
                [105.0997108, 20.9573395],
                [105.1007116, 20.957136],
                [105.1024777, 20.956443],
                [105.1035963, 20.9560548],
                [105.1042634, 20.9558699],
                [105.1045775, 20.9558697],
                [105.1054609, 20.955943],
                [105.1066387, 20.9561267],
                [105.1074631, 20.9561448],
                [105.1083659, 20.955978],
                [105.1095431, 20.9553409],
                [105.1105436, 20.9546575],
                [105.1111713, 20.9540852],
                [105.1113279, 20.9535316],
                [105.1112486, 20.9524061],
                [105.1112091, 20.9521478],
                [105.1117189, 20.9514741],
                [105.1128371, 20.9506246],
                [105.1139747, 20.9496644],
                [105.1145238, 20.9490368],
                [105.1152686, 20.9476526],
                [105.1159157, 20.9469787],
                [105.1173085, 20.9460367],
                [105.1185442, 20.9449842],
                [105.1194855, 20.9440057],
                [105.120623, 20.9429532],
                [105.1220353, 20.9418913],
                [105.1229575, 20.9416693],
                [105.1239388, 20.9416686],
                [105.125352, 20.9417044],
                [105.125764, 20.9415749],
                [105.1263524, 20.941187],
                [105.1276469, 20.9402636],
                [105.1298046, 20.9388321],
                [105.1306677, 20.9383149],
                [105.1311385, 20.9380377],
                [105.1316094, 20.9378712],
                [105.1320607, 20.9378156],
                [105.1323551, 20.9378154],
                [105.132885, 20.9379257],
                [105.1338274, 20.9383308],
                [105.1350447, 20.9387728],
                [105.1360263, 20.9390763],
                [105.1370863, 20.9392415],
                [105.1381264, 20.9392038],
                [105.1392254, 20.9392029],
                [105.1404227, 20.9393864],
                [105.1413257, 20.9394779],
                [105.1420322, 20.9394773],
                [105.1426013, 20.9394492],
                [105.1435431, 20.9391901],
                [105.1448771, 20.9386539],
                [105.1452892, 20.9385614],
                [105.1453677, 20.9385796],
                [105.1456624, 20.9389115],
                [105.1462522, 20.9399074],
                [105.1467829, 20.9407003],
                [105.1471365, 20.9411244],
                [105.1473722, 20.9413086],
                [105.1475293, 20.9413638],
                [105.1479217, 20.9413081],
                [105.1487457, 20.9409569],
                [105.1499034, 20.9406606],
                [105.1513946, 20.9402812],
                [105.1528855, 20.9396524],
                [105.153788, 20.9393933],
                [105.1546711, 20.9392634],
                [105.1555934, 20.9391888],
                [105.1560056, 20.9392621],
                [105.1565161, 20.9394462],
                [105.1571443, 20.9396301],
                [105.1576546, 20.9395836],
                [105.1586551, 20.9392874],
                [105.1592633, 20.9390286],
                [105.1600287, 20.9390279],
                [105.1606961, 20.939101],
                [105.1611083, 20.9392851],
                [105.1613049, 20.9394878],
                [105.1614228, 20.9396907],
                [105.161404, 20.9403733],
                [105.1613667, 20.9422923],
                [105.1614265, 20.9431041],
                [105.1615838, 20.943436],
                [105.1617019, 20.9436112],
                [105.161859, 20.9437586],
                [105.1622713, 20.9438873],
                [105.1634097, 20.9439785],
                [105.1648032, 20.9440325],
                [105.1658828, 20.9440869],
                [105.1667658, 20.9439752],
                [105.1674133, 20.9436979],
                [105.1680606, 20.9433652],
                [105.1685315, 20.9433093],
                [105.1687474, 20.9433275],
                [105.1694935, 20.9436035],
                [105.1708875, 20.9441465],
                [105.1721245, 20.9445143],
                [105.1728901, 20.9446796],
                [105.1736164, 20.9447525],
                [105.1751671, 20.9448248],
                [105.1758736, 20.944824],
                [105.1769334, 20.9448968],
                [105.1775421, 20.9450714],
                [105.1780333, 20.9454768],
                [105.1793885, 20.9462687],
                [105.1805277, 20.9469871],
                [105.1822756, 20.9479262],
                [105.1828846, 20.9484237],
                [105.1837883, 20.9490778],
                [105.1853987, 20.9498878],
                [105.1869503, 20.9507717],
                [105.1878929, 20.9511766],
                [105.1893659, 20.9517837],
                [105.189798, 20.9520785],
                [105.1902306, 20.9527053],
                [105.190742, 20.9536089],
                [105.1911556, 20.9547061],
                [105.1915109, 20.9562741],
                [105.1917872, 20.9574546],
                [105.1921076, 20.95853],
                [105.192518, 20.9584681],
                [105.1930899, 20.9582393],
                [105.1940431, 20.9578796],
                [105.1954036, 20.9574665],
                [105.1964436, 20.9571964],
                [105.1975359, 20.9570973],
                [105.1981341, 20.9571211],
                [105.1986803, 20.9571205],
                [105.1988883, 20.9570469],
                [105.1993042, 20.9568264],
                [105.2000572, 20.9559454],
                [105.2012519, 20.9546971],
                [105.2024727, 20.9534978],
                [105.2043428, 20.9516741],
                [105.2050444, 20.9512821],
                [105.2055903, 20.9510126],
                [105.2063324, 20.9507824],
                [105.2059064, 20.9504221],
                [105.2057452, 20.950279],
                [105.2049896, 20.9491553],
                [105.2049371, 20.9488375],
                [105.2048846, 20.9484954],
                [105.2049093, 20.9475174],
                [105.204516, 20.9451342],
                [105.2044363, 20.9439119],
                [105.2043052, 20.9431298],
                [105.203758, 20.9423481],
                [105.2026646, 20.9415427],
                [105.2014152, 20.9406518],
                [105.2003738, 20.939724],
                [105.1993843, 20.9387227],
                [105.1983943, 20.9374771],
                [105.197327, 20.9366227],
                [105.1964676, 20.9356702],
                [105.1956337, 20.9342899],
                [105.1951905, 20.9334592],
                [105.1950856, 20.9327259],
                [105.1949798, 20.9314302],
                [105.1945367, 20.930624],
                [105.194068, 20.9300623],
                [105.1938855, 20.9297569],
                [105.1938332, 20.9294635],
                [105.1940409, 20.9292432],
                [105.1952356, 20.9281418],
                [105.1964047, 20.9272848],
                [105.1973913, 20.9261346],
                [105.1979882, 20.9252781],
                [105.1982735, 20.9246911],
                [105.1981685, 20.9239456],
                [105.1979072, 20.9229925],
                [105.1974674, 20.9222054],
                [105.1968597, 20.9214584],
                [105.1962221, 20.9209277],
                [105.1961881, 20.9207452],
                [105.1961881, 20.9207207],
                [105.1962141, 20.9207208],
                [105.1968824, 20.9207423],
                [105.1977351, 20.9205783],
                [105.1982939, 20.920376],
                [105.1996446, 20.9193721],
                [105.2014374, 20.9183554],
                [105.2023464, 20.9175721],
                [105.202788, 20.9172781],
                [105.202996, 20.9172045],
                [105.2034639, 20.9170818],
                [105.2038877, 20.9170274],
                [105.2043892, 20.9171862],
                [105.2057233, 20.917474],
                [105.2060105, 20.9175963],
                [105.2062324, 20.9177065],
                [105.2064413, 20.9177431],
                [105.2067675, 20.9177672],
                [105.2070153, 20.9177423],
                [105.2073415, 20.9176806],
                [105.2077457, 20.9175697],
                [105.2079676, 20.9175571],
                [105.2080981, 20.9175692],
                [105.2083461, 20.9176057],
                [105.2085419, 20.9176914],
                [105.2087638, 20.9178015],
                [105.2089988, 20.9178993],
                [105.2091294, 20.917936],
                [105.209245, 20.9179698],
                [105.2092731, 20.9180694],
                [105.2094564, 20.9185721],
                [105.2095351, 20.9188173],
                [105.2095485, 20.9190381],
                [105.2095489, 20.9194183],
                [105.2095363, 20.919676],
                [105.2095367, 20.9199949],
                [105.209681, 20.9205098],
                [105.2097857, 20.9207673],
                [105.2098121, 20.9209759],
                [105.2098906, 20.9211842],
                [105.2099952, 20.9213681],
                [105.210335, 20.9217725],
                [105.2107533, 20.9222749],
                [105.2111192, 20.9226302],
                [105.2114065, 20.9228506],
                [105.2116156, 20.9229975],
                [105.2120332, 20.9230706],
                [105.2124378, 20.9231438],
                [105.2126075, 20.9232171],
                [105.2128818, 20.9234253],
                [105.2130908, 20.9235722],
                [105.2134565, 20.9237927],
                [105.2136785, 20.9239763],
                [105.2139006, 20.9241478],
                [105.2141095, 20.9242334],
                [105.2142922, 20.9242823],
                [105.2144879, 20.924282],
                [105.2149185, 20.9242201],
                [105.2152446, 20.9242073],
                [105.2154794, 20.924158],
                [105.2157012, 20.9240719],
                [105.215975, 20.9239979],
                [105.2161185, 20.9239732],
                [105.2162881, 20.9239607],
                [105.2166275, 20.9240094],
                [105.2170974, 20.924156],
                [105.2172542, 20.924303],
                [105.2174241, 20.924499],
                [105.2175548, 20.9246215],
                [105.2176592, 20.9246582],
                [105.2178028, 20.9246702],
                [105.2179854, 20.9246577],
                [105.218155, 20.9246208],
                [105.2183377, 20.9246206],
                [105.2185464, 20.924608],
                [105.2187291, 20.9245832],
                [105.2189248, 20.9245707],
                [105.2191988, 20.9245704],
                [105.2194336, 20.9245578],
                [105.2196424, 20.924533],
                [105.2197989, 20.9244959],
                [105.2201638, 20.9242502],
                [105.2202813, 20.9241887],
                [105.2204509, 20.9241885],
                [105.2206467, 20.9242374],
                [105.220725, 20.924274],
                [105.2208948, 20.9244579],
                [105.2211042, 20.9248132],
                [105.2211175, 20.9249973],
                [105.2210527, 20.9252795],
                [105.2209357, 20.9256108],
                [105.2208187, 20.9258317],
                [105.2208059, 20.9260525],
                [105.2208453, 20.9262365],
                [105.2209237, 20.9263713],
                [105.2211198, 20.9265551],
                [105.2212896, 20.9266529],
                [105.2214593, 20.9266773],
                [105.2217854, 20.9266523],
                [105.2224247, 20.9265778],
                [105.2231029, 20.9264175],
                [105.2237683, 20.9263308],
                [105.2240683, 20.9262936],
                [105.2243162, 20.9262932],
                [105.2244599, 20.926379],
                [105.2245514, 20.9264769],
                [105.2245777, 20.9266364],
                [105.224578, 20.9267959],
                [105.2245, 20.9269922],
                [105.2243177, 20.9272868],
                [105.2239399, 20.9276676],
                [105.2237599, 20.9278071],
                [105.223486, 20.9279056],
                [105.2233033, 20.9279427],
                [105.2230425, 20.927992],
                [105.2228337, 20.9280045],
                [105.2226641, 20.9280661],
                [105.2224295, 20.9282012],
                [105.2219081, 20.9286064],
                [105.2214519, 20.9289504],
                [105.2210217, 20.9291839],
                [105.2206957, 20.9293436],
                [105.2202264, 20.9296752],
                [105.2201222, 20.9298346],
                [105.2200051, 20.9300188],
                [105.2198492, 20.9304848],
                [105.2197452, 20.9307792],
                [105.219498, 20.9312331],
                [105.2192637, 20.9316379],
                [105.2189905, 20.9322268],
                [105.2189126, 20.9324598],
                [105.2188997, 20.9326192],
                [105.218887, 20.9327909],
                [105.2189971, 20.933406],
                [105.2198181, 20.9338932],
                [105.2202562, 20.9342912],
                [105.2204888, 20.9345092],
                [105.2208446, 20.9346887],
                [105.2211729, 20.9348553],
                [105.221474, 20.9350605],
                [105.2217068, 20.9352788],
                [105.2219944, 20.9356639],
                [105.2221314, 20.9358693],
                [105.222252, 20.9360196],
                [105.2223752, 20.9361351],
                [105.2224983, 20.9362378],
                [105.2226898, 20.9362889],
                [105.2229906, 20.9362757],
                [105.2233323, 20.9362238],
                [105.2237149, 20.9361463],
                [105.2240977, 20.93612],
                [105.2244668, 20.9361195],
                [105.2247677, 20.9361577],
                [105.2250686, 20.9363116],
                [105.2253422, 20.936414],
                [105.2256157, 20.9364522],
                [105.2259301, 20.9364518],
                [105.2263401, 20.9363356],
                [105.2269413, 20.936142],
                [105.2275017, 20.9360255],
                [105.2280211, 20.9359735],
                [105.2283492, 20.9359602],
                [105.2288002, 20.9358954],
                [105.2291145, 20.9357921],
                [105.2295927, 20.9355859],
                [105.2296473, 20.9355472],
                [105.2298587, 20.935528],
                [105.2299681, 20.935562],
                [105.2300776, 20.9356419],
                [105.2302357, 20.9357787],
                [105.2303331, 20.9358699],
                [105.2305279, 20.936121],
                [105.2306496, 20.9362464],
                [105.2307712, 20.936349],
                [105.2309901, 20.9364402],
                [105.2311481, 20.9365199],
                [105.2312576, 20.936611],
                [105.2313793, 20.9367137],
                [105.2315739, 20.9368162],
                [105.2316713, 20.9368961],
                [105.2317079, 20.9370103],
                [105.2317079, 20.9370674],
                [105.2316596, 20.9371816],
                [105.2314653, 20.9373304],
                [105.2305548, 20.9378569],
                [105.2303243, 20.9380972],
                [105.230276, 20.9382457],
                [105.230264, 20.9383714],
                [105.2303614, 20.9384969],
                [105.2305195, 20.9385995],
                [105.2307505, 20.9386334],
                [105.230957, 20.9386217],
                [105.2312243, 20.9385871],
                [105.2316615, 20.9384608],
                [105.2322194, 20.9382859],
                [105.2323652, 20.9382628],
                [105.2325596, 20.9382626],
                [105.2327297, 20.938308],
                [105.2328757, 20.9383993],
                [105.2330096, 20.9385361],
                [105.2330584, 20.938696],
                [105.2330954, 20.9390499],
                [105.2331323, 20.9393012],
                [105.2332176, 20.9394725],
                [105.2333394, 20.9396207],
                [105.2334489, 20.9397234],
                [105.2336433, 20.9397574],
                [105.2339471, 20.9397341],
                [105.2344329, 20.9396192],
                [105.2347611, 20.9396302],
                [105.2350649, 20.9396869],
                [105.2353567, 20.9397664],
                [105.2357213, 20.9398687],
                [105.236001, 20.9399711],
                [105.2362562, 20.9400392],
                [105.2366087, 20.9400845],
                [105.236791, 20.94013],
                [105.2370099, 20.9402096],
                [105.2371316, 20.9403008],
                [105.237241, 20.9404148],
                [105.2373507, 20.9405746],
                [105.2374969, 20.9408028],
                [105.2375699, 20.9408941],
                [105.2376794, 20.940951],
                [105.2377765, 20.9409624],
                [105.2378616, 20.9409508],
                [105.2379952, 20.9408821],
                [105.2382257, 20.9406419],
                [105.2383956, 20.9405275],
                [105.2385291, 20.9404816],
                [105.2386872, 20.9404699],
                [105.2387965, 20.9404926],
                [105.2389547, 20.9406067],
                [105.23904, 20.9407778],
                [105.2391378, 20.9411661],
                [105.2391989, 20.9413487],
                [105.2392476, 20.9414514],
                [105.2393206, 20.9415085],
                [105.23943, 20.9415291],
                [105.2395394, 20.941529],
                [105.2396973, 20.9414946],
                [105.2400495, 20.9413799],
                [105.2402439, 20.941334],
                [105.2404504, 20.9412994],
                [105.2406205, 20.9413106],
                [105.2408149, 20.9413331],
                [105.2410702, 20.9413785],
                [105.2412769, 20.941458],
                [105.241362, 20.9415265],
                [105.2414959, 20.9416518],
                [105.2415812, 20.9418229],
                [105.2417031, 20.9420396],
                [105.2417762, 20.9421194],
                [105.241837, 20.9421878],
                [105.2419586, 20.9422333],
                [105.2421652, 20.9422673],
                [105.2424811, 20.9422668],
                [105.2426998, 20.9422094],
                [105.2430277, 20.942072],
                [105.2433676, 20.9419346],
                [105.2435741, 20.9418658],
                [105.2437563, 20.9418427],
                [105.2439265, 20.9418424],
                [105.2440845, 20.9418764],
                [105.2442181, 20.9419104],
                [105.2443885, 20.9420358],
                [105.2444981, 20.9421612],
                [105.2445347, 20.9422754],
                [105.2445106, 20.942458],
                [105.2444503, 20.9426407],
                [105.2442684, 20.9429149],
                [105.2440258, 20.9431436],
                [105.2436131, 20.9434067],
                [105.2432972, 20.9435328],
                [105.2430789, 20.9437043],
                [105.2428848, 20.9439328],
                [105.2427152, 20.9442185],
                [105.242509, 20.94447],
                [105.2424342, 20.9445276],
                [105.2424945, 20.9446088],
                [105.2428042, 20.9446603],
                [105.243358, 20.9447179],
                [105.2439874, 20.9447833],
                [105.2446731, 20.9449279],
                [105.2456132, 20.9450721],
                [105.2460335, 20.9451961],
                [105.2464541, 20.9454554],
                [105.2466533, 20.945611],
                [105.2468524, 20.9456939],
                [105.2470957, 20.9456831],
                [105.2473167, 20.9455788],
                [105.2474379, 20.9454227],
                [105.2475482, 20.9452147],
                [105.247658, 20.9446949],
                [105.2477681, 20.9444453],
                [105.2479447, 20.9443099],
                [105.2481437, 20.9442577],
                [105.2483538, 20.9442678],
                [105.248575, 20.944309],
                [105.24873, 20.9444335],
                [105.2489625, 20.9446929],
                [105.2491844, 20.94515],
                [105.2493286, 20.94542],
                [105.2495834, 20.9456691],
                [105.2498489, 20.9457726],
                [105.25007, 20.9458035],
                [105.2502964, 20.9458031],
                [105.2506789, 20.9456999],
                [105.2510333, 20.9456044],
                [105.251359, 20.9453483],
                [105.2513586, 20.9450929],
                [105.2511946, 20.9445311],
                [105.2513565, 20.9437644],
                [105.2516266, 20.9427931],
                [105.2520064, 20.9422816],
                [105.252724, 20.9417801],
                [105.2536023, 20.9412855],
                [105.2543772, 20.9407767],
                [105.25456, 20.9407335],
                [105.2550312, 20.9405191],
                [105.2557457, 20.9400905],
                [105.2562168, 20.9399067],
                [105.256883, 20.9396461],
                [105.2575676, 20.9390254],
                [105.2580081, 20.9387428],
                [105.2580352, 20.9382615],
                [105.2579357, 20.9377063],
                [105.2580821, 20.9369656],
                [105.2585733, 20.9362477],
                [105.2589666, 20.9359],
                [105.2598769, 20.9356209],
                [105.2603692, 20.9355508],
                [105.2609109, 20.9356888],
                [105.2615513, 20.9359192],
                [105.261798, 20.9361734],
                [105.2619709, 20.9365664],
                [105.2620942, 20.9366588],
                [105.2624634, 20.9367045],
                [105.2626605, 20.9367737],
                [105.2627838, 20.936866],
                [105.2630553, 20.9373051],
                [105.2633759, 20.9375592],
                [105.2635483, 20.9376515],
                [105.2637206, 20.9376512],
                [105.2640651, 20.9375118],
                [105.2646305, 20.9371177],
                [105.2648024, 20.9369091],
                [105.2649748, 20.9369321],
                [105.2651227, 20.9370475],
                [105.2652099, 20.9372742],
                [105.2663705, 20.9393547],
                [105.2666431, 20.940303],
                [105.2669894, 20.9412973],
                [105.2671372, 20.9413665],
                [105.2679005, 20.9413885],
                [105.2695004, 20.9413166],
                [105.2711243, 20.9408511],
                [105.2713949, 20.9407119],
                [105.2714429, 20.9400068],
                [105.2712931, 20.9387807],
                [105.2710435, 20.9369532],
                [105.2707947, 20.9354033],
                [105.2707943, 20.9352182],
                [105.2714568, 20.934014],
                [105.2722859, 20.9320097],
                [105.273657, 20.9286674],
                [105.2747864, 20.9261315],
                [105.2753826, 20.9247467],
                [105.2757265, 20.9247452],
                [105.2761864, 20.924715],
                [105.2780815, 20.9246193],
                [105.2786054, 20.9247704],
                [105.2894012, 20.931427],
                [105.2924617, 20.9276215],
                [105.2934307, 20.9262609],
                [105.2946405, 20.9255815],
                [105.2965783, 20.9241856],
                [105.2997148, 20.9239576],
                [105.301192, 20.9240244],
                [105.3032018, 20.9240669],
                [105.3038418, 20.9240658],
                [105.3056632, 20.9240393],
                [105.307263, 20.9240133],
                [105.3091584, 20.92401],
                [105.3119548, 20.9241062],
                [105.3126561, 20.9241303],
                [105.3137355, 20.9252436],
                [105.3157576, 20.9270678],
                [105.3164647, 20.9284651],
                [105.3166883, 20.9289571],
                [105.316763, 20.9293041],
                [105.3168611, 20.930017],
                [105.3168226, 20.9304237],
                [105.316341, 20.9324134],
                [105.3162035, 20.9337094],
                [105.3163859, 20.9344871],
                [105.3172485, 20.9349573],
                [105.3181826, 20.9352252],
                [105.3192601, 20.9353579],
                [105.32084, 20.9354224],
                [105.3227066, 20.9352167],
                [105.3247171, 20.9352129],
                [105.325579, 20.9352786],
                [105.3260114, 20.9360192],
                [105.3263727, 20.9370295],
                [105.327595, 20.9377012],
                [105.3295351, 20.938304],
                [105.3316182, 20.9385695],
                [105.3329107, 20.938567],
                [105.3337723, 20.9384979],
                [105.3346347, 20.9388332],
                [105.3352817, 20.9391689],
                [105.3361443, 20.9395717],
                [105.3364323, 20.9399081],
                [105.3365049, 20.940245],
                [105.3366496, 20.9407165],
                [105.3372965, 20.9409848],
                [105.3384462, 20.9413195],
                [105.3407437, 20.9411127],
                [105.3423236, 20.9411096],
                [105.3447756, 20.9409031],
                [105.3443626, 20.9436077],
                [105.3437065, 20.9471315],
                [105.3427661, 20.9514261],
                [105.3422951, 20.9529877],
                [105.3418238, 20.9543709],
                [105.340422, 20.9586339],
                [105.3397152, 20.9608206],
                [105.338456, 20.9652605],
                [105.3380329, 20.9670006],
                [105.3370073, 20.9699237],
                [105.3364652, 20.9716978],
                [105.3361945, 20.9721918],
                [105.33142, 20.9857616],
                [105.3302942, 20.9889609],
                [105.3259154, 20.9990245],
                [105.3254921, 20.9998447],
                [105.3246975, 21.0019521],
                [105.3231541, 21.0042297],
                [105.3208385, 21.0074234],
                [105.3183238, 21.0109621],
                [105.3176881, 21.0118405],
                [105.3175069, 21.0121743],
                [105.3164205, 21.0138547],
                [105.3154112, 21.0151478],
                [105.3142517, 21.0161265],
                [105.3129117, 21.0170108],
                [105.3102579, 21.0184015],
                [105.3073444, 21.0200199],
                [105.3062455, 21.0206774],
                [105.3054953, 21.021309],
                [105.3048259, 21.0220667],
                [105.3045328, 21.0230756],
                [105.3042408, 21.0246647],
                [105.3034669, 21.0262901],
                [105.3026298, 21.0281606],
                [105.3026286, 21.0281632],
                [105.3023715, 21.0287377],
                [105.3013812, 21.0300505],
                [105.2995075, 21.0325751],
                [105.2984942, 21.0339529],
                [105.2974779, 21.0356943],
                [105.296837, 21.0373596],
                [105.2959834, 21.039958],
                [105.2955922, 21.0433206],
                [105.2951083, 21.0451533],
                [105.2949262, 21.045843],
                [105.2944772, 21.0495752],
                [105.2941628, 21.0534178],
                [105.2943003, 21.0550313],
                [105.29449, 21.055989],
                [105.2953886, 21.0583584],
                [105.2954074, 21.0584079],
                [105.2961867, 21.0598081],
                [105.2971838, 21.0617982],
                [105.2986123, 21.0646952],
                [105.2991206, 21.0657597],
                [105.2995788, 21.0667194],
                [105.3005771, 21.0691627],
                [105.300955, 21.0701707],
                [105.3014133, 21.0711532],
                [105.3031367, 21.0737721],
                [105.3040517, 21.0748547],
                [105.3049932, 21.0761526],
                [105.3060428, 21.0774618],
                [105.3071735, 21.0789725],
                [105.3090044, 21.0815661],
                [105.310559, 21.0836678],
                [105.3123622, 21.0857824],
                [105.3137066, 21.0878432],
                [105.3146786, 21.0893331],
                [105.3150558, 21.0899375],
                [105.3168032, 21.0935251],
                [105.3177477, 21.0958177],
                [105.3182346, 21.0974808],
                [105.3183438, 21.0983379],
                [105.3190758, 21.101702],
                [105.3196208, 21.1054324],
                [105.3202733, 21.1091748],
                [105.3202249, 21.1116205],
                [105.3199579, 21.113163],
                [105.31988, 21.1136129],
                [105.3189443, 21.1153428],
                [105.3180347, 21.1169328],
                [105.3165089, 21.1192302],
                [105.315438, 21.12077],
                [105.3137003, 21.1236069],
                [105.3128982, 21.1253229],
                [105.3123641, 21.126761],
                [105.3121093, 21.1272697],
                [105.3118169, 21.1278537],
                [105.3112141, 21.1290575],
                [105.3105985, 21.130042],
                [105.3095861, 21.131665],
                [105.308515, 21.1331797],
                [105.3069874, 21.1347456],
                [105.3040393, 21.137726],
                [105.3024852, 21.1395441],
                [105.2978297, 21.1448353],
                [105.2960882, 21.1470318],
                [105.2960807, 21.1470416],
                [105.2927702, 21.151339],
                [105.2913673, 21.1533533],
                [105.2902315, 21.1551395],
                [105.2889615, 21.1570007],
                [105.2905331, 21.1645247],
                [105.2908165, 21.1658811],
                [105.2922364, 21.1698565],
                [105.2932485, 21.1719729],
                [105.295622, 21.1748674],
                [105.2959469, 21.1752606],
                [105.2980039, 21.1792104],
                [105.3032963, 21.1897521],
                [105.3036905, 21.1915845],
                [105.3072426, 21.1935045],
                [105.3122152, 21.1960522],
                [105.3160147, 21.1982667],
                [105.3167577, 21.1986474],
                [105.3227973, 21.2017416],
                [105.3234496, 21.2019017],
                [105.323487, 21.2018856],
                [105.3268408, 21.2025857],
                [105.3312299, 21.204758],
                [105.3325197, 21.2056093],
                [105.3337215, 21.2068697],
                [105.3359021, 21.2083383],
                [105.3374958, 21.2100362],
                [105.3379652, 21.211197],
                [105.3384782, 21.2124656],
                [105.3414228, 21.216372],
                [105.3437539, 21.2191433],
                [105.344467, 21.220578],
                [105.3449818, 21.2215635],
                [105.3451799, 21.2219425],
                [105.3464557, 21.2244267],
                [105.3481789, 21.2253751],
                [105.3486331, 21.2263937],
                [105.3498371, 21.230034],
                [105.3514944, 21.2374964],
                [105.3521591, 21.2404528],
                [105.3527842, 21.2432614],
                [105.3531527, 21.2451188],
                [105.3539287, 21.2481823],
                [105.354261, 21.2521624],
                [105.3542497, 21.2547787],
                [105.3543555, 21.2555082],
                [105.3562619, 21.2607843],
                [105.3563452, 21.2682402],
                [105.356392, 21.2724229],
                [105.3561646, 21.2753153],
                [105.3562197, 21.2774656],
                [105.3562961, 21.2785341],
                [105.3576025, 21.279577],
                [105.3585631, 21.280465],
                [105.3605148, 21.2823372],
                [105.3620018, 21.2847086],
                [105.3633675, 21.285815],
                [105.3648481, 21.2868911],
                [105.3667557, 21.2880849],
                [105.3686708, 21.2891617],
                [105.3709845, 21.2902599],
                [105.3738131, 21.2917579],
                [105.3744664, 21.2922259],
                [105.3763641, 21.293018],
                [105.3771467, 21.2938334],
                [105.3771714, 21.2951936],
                [105.3775291, 21.2956744],
                [105.3781271, 21.2959312],
                [105.3795292, 21.2959651],
                [105.3821849, 21.2960385],
                [105.3828133, 21.2961757],
                [105.3831326, 21.2965047],
                [105.383325, 21.296907],
                [105.3870634, 21.3005795],
                [105.3881739, 21.3016703],
                [105.3888121, 21.3020469],
                [105.3895351, 21.3021833],
                [105.3904432, 21.3023104],
                [105.3914981, 21.302438],
                [105.3933598, 21.3024856],
                [105.3941897, 21.3029653],
                [105.3946561, 21.3034131],
                [105.3950882, 21.3043087],
                [105.395805, 21.3045067],
                [105.3967678, 21.3043122],
                [105.4011006, 21.3020395],
                [105.4060857, 21.2996708],
                [105.4066001, 21.2988004],
                [105.408549, 21.297329],
                [105.4116546, 21.295162],
                [105.4140927, 21.2930874],
                [105.4202505, 21.285276],
                [105.4211444, 21.2847771],
                [105.4252599, 21.2801997],
                [105.4261718, 21.2791347],
                [105.4270895, 21.2782561],
                [105.4326187, 21.2741139],
                [105.440403, 21.2714997],
                [105.4439745, 21.2700524],
                [105.4431678, 21.2663221],
                [105.4433957, 21.2644046],
                [105.4445895, 21.2646891],
                [105.4485083, 21.2647123],
                [105.4485905, 21.2661714],
                [105.4485349, 21.267133],
                [105.4483645, 21.2689192],
                [105.4482522, 21.2704854],
                [105.448372, 21.271378],
                [105.4485492, 21.2718995],
                [105.4489907, 21.2726126],
                [105.4491384, 21.2730243],
                [105.4491714, 21.2742056],
                [105.4491202, 21.2766507],
                [105.4492996, 21.2778945],
                [105.449481, 21.2797332],
                [105.4495711, 21.2805017],
                [105.4497484, 21.2809954],
                [105.4503674, 21.2822839],
                [105.4508397, 21.2834631],
                [105.4515749, 21.2844493],
                [105.4522374, 21.284842],
                [105.4522607, 21.285075],
                [105.452437, 21.2859431],
                [105.4529459, 21.2882994],
                [105.4530441, 21.2888959],
                [105.4532324, 21.290162],
                [105.4533434, 21.2906316],
                [105.4534278, 21.2914348],
                [105.4535357, 21.2920409],
                [105.4535527, 21.2924814],
                [105.4536632, 21.2930633],
                [105.4525832, 21.2933697],
                [105.452149, 21.2935535],
                [105.4511894, 21.2941379],
                [105.4503533, 21.2947429],
                [105.4497127, 21.2952454],
                [105.4493542, 21.2956677],
                [105.4492447, 21.2959759],
                [105.4492242, 21.2962735],
                [105.4492829, 21.2967558],
                [105.4495266, 21.2975016],
                [105.449657, 21.2977967],
                [105.4497176, 21.2986731],
                [105.4500011, 21.2991431],
                [105.4503022, 21.2996422],
                [105.450482, 21.2996523],
                [105.4506964, 21.2996643],
                [105.4518462, 21.3000729],
                [105.4533358, 21.301146],
                [105.4547087, 21.3026942],
                [105.4556923, 21.3039903],
                [105.4564077, 21.3059838],
                [105.456717, 21.3076616],
                [105.4568215, 21.3087066],
                [105.4566217, 21.3096257],
                [105.4555315, 21.3120993],
                [105.454893, 21.3132096],
                [105.4532443, 21.3153995],
                [105.4505493, 21.3178774],
                [105.4497246, 21.3193807],
                [105.4489382, 21.3206582],
                [105.4485937, 21.3213902],
                [105.4484147, 21.3217703],
                [105.4471914, 21.3237894],
                [105.4450912, 21.3261811],
                [105.440668, 21.3300189],
                [105.4378619, 21.3313838],
                [105.4356425, 21.3321896],
                [105.4346655, 21.333187],
                [105.4342761, 21.3336404],
                [105.4332925, 21.3343874],
                [105.4319533, 21.3352123],
                [105.4299024, 21.3361673],
                [105.4264837, 21.3375622],
                [105.424815, 21.3381312],
                [105.4227772, 21.3389194],
                [105.4220168, 21.3391215],
                [105.4212177, 21.3393339],
                [105.4202321, 21.3394134],
                [105.4184543, 21.3401366],
                [105.4153916, 21.3416074],
                [105.4131767, 21.3427424],
                [105.4110589, 21.3443263],
                [105.4084615, 21.34577],
                [105.4074225, 21.3463373],
                [105.4061652, 21.3471875],
                [105.4050722, 21.3480629],
                [105.4035147, 21.3493246],
                [105.4021754, 21.3502262],
                [105.4012607, 21.3512167],
                [105.4005515, 21.3522452],
                [105.3996781, 21.3533254],
                [105.3988316, 21.3542259],
                [105.3977669, 21.355435],
                [105.3967296, 21.3566952],
                [105.3960077, 21.3581216],
                [105.3955707, 21.3585846],
                [105.3944793, 21.3600761],
                [105.3939344, 21.3611298],
                [105.3919637, 21.366692],
                [105.3921251, 21.3707067],
                [105.3921819, 21.3715047],
                [105.3915381, 21.3868826],
                [105.3916494, 21.3875497],
                [105.391816, 21.3883964],
                [105.3920376, 21.38932],
                [105.392369, 21.3903461],
                [105.3927551, 21.3913463],
                [105.3927922, 21.3914196],
                [105.3930719, 21.3919744],
                [105.3931822, 21.3922565],
                [105.3934585, 21.3931543],
                [105.3938996, 21.3942315],
                [105.3946978, 21.3956158],
                [105.3952488, 21.3967697],
                [105.3958427, 21.3986293],
                [105.3961465, 21.399527],
                [105.3968363, 21.4013479],
                [105.3973612, 21.4030153],
                [105.3976376, 21.403913],
                [105.3980942, 21.4056191],
                [105.3985377, 21.4074919],
                [105.3986508, 21.4088008],
                [105.3985732, 21.4104951],
                [105.3985698, 21.4105448],
                [105.3984546, 21.4122538],
                [105.3982644, 21.4127934],
                [105.3981291, 21.4134354],
                [105.3978302, 21.4143347],
                [105.3973398, 21.4153626],
                [105.3968548, 21.4160023],
                [105.3962479, 21.4168027],
                [105.394761, 21.419155],
                [105.3945429, 21.4195406],
                [105.393697, 21.420826],
                [105.3927968, 21.4223171],
                [105.3917328, 21.4239881],
                [105.391406, 21.4247332],
                [105.390601, 21.4259801],
                [105.389317, 21.4273436],
                [105.388661, 21.4279612],
                [105.3872113, 21.4288886],
                [105.3849681, 21.4302543],
                [105.3828069, 21.4315171],
                [105.3803855, 21.432896],
                [105.3788801, 21.4335155],
                [105.3774567, 21.4340321],
                [105.3758128, 21.4341128],
                [105.3738394, 21.4338861],
                [105.3720113, 21.4330877],
                [105.3716176, 21.4329158],
                [105.3703283, 21.4323281],
                [105.3688602, 21.4314714],
                [105.3688521, 21.4314674],
                [105.367461, 21.4307814],
                [105.365239, 21.4296824],
                [105.3631809, 21.4284291],
                [105.3613417, 21.4270469],
                [105.357952, 21.424577],
                [105.3567165, 21.4235528],
                [105.3558647, 21.4225278],
                [105.3543787, 21.4198613],
                [105.3531149, 21.4183751],
                [105.3519631, 21.4179669],
                [105.3508945, 21.4178664],
                [105.3497441, 21.4179972],
                [105.347553, 21.4183097],
                [105.3469538, 21.4184576],
                [105.3454035, 21.4188404],
                [105.344392, 21.4197409],
                [105.3437362, 21.4204612],
                [105.3430808, 21.4214122],
                [105.342426, 21.42262],
                [105.3418805, 21.4236737],
                [105.3417457, 21.4246237],
                [105.3413055, 21.4252028],
                [105.341169, 21.4261123],
                [105.3411724, 21.4275844],
                [105.3413633, 21.4301818],
                [105.3418308, 21.4323024],
                [105.3421127, 21.4342502],
                [105.3425332, 21.4360679],
                [105.3427669, 21.4371497],
                [105.3430944, 21.4387943],
                [105.3431992, 21.4427822],
                [105.3430646, 21.4438093],
                [105.3425086, 21.446262],
                [105.3424001, 21.4467498],
                [105.3422011, 21.4476932],
                [105.3420749, 21.4482907],
                [105.3417479, 21.4490615],
                [105.341258, 21.4505513],
                [105.3404555, 21.4530302],
                [105.3401298, 21.4544426],
                [105.3398321, 21.4560347],
                [105.3393983, 21.4580636],
                [105.3391691, 21.4596684],
                [105.3391971, 21.4599507],
                [105.3389803, 21.460978],
                [105.3389811, 21.4613117],
                [105.3388188, 21.4622619],
                [105.3386014, 21.4630579],
                [105.3383844, 21.4640339],
                [105.3379761, 21.4652412],
                [105.3375817, 21.4665127],
                [105.337255, 21.4674631],
                [105.3367096, 21.4686963],
                [105.3358656, 21.4705178],
                [105.3358643, 21.4705207],
                [105.3353185, 21.4715486],
                [105.3347179, 21.4726279],
                [105.3344458, 21.4734755],
                [105.3343378, 21.4741945],
                [105.3343664, 21.4747078],
                [105.33445, 21.475298],
                [105.3342759, 21.4771082],
                [105.3339363, 21.4791634],
                [105.3338028, 21.4799712],
                [105.3334772, 21.4814095],
                [105.3332605, 21.4825393],
                [105.3328788, 21.4834386],
                [105.331868, 21.4849294],
                [105.3304602, 21.486609],
                [105.329147, 21.4877147],
                [105.3283271, 21.4887681],
                [105.3271514, 21.4901045],
                [105.3253462, 21.491878],
                [105.3239796, 21.4936765],
                [105.3233789, 21.4947039],
                [105.3228878, 21.4957823],
                [105.3225611, 21.4967579],
                [105.3224806, 21.4976045],
                [105.3225643, 21.4990211],
                [105.3227753, 21.4998584],
                [105.3230702, 21.5008706],
                [105.3238649, 21.5028687],
                [105.3245424, 21.5047303],
                [105.3248391, 21.5065652],
                [105.3248528, 21.5070172],
                [105.3248708, 21.5076061],
                [105.3249026, 21.5087566],
                [105.3244943, 21.5094423],
                [105.323969, 21.5101829],
                [105.3228588, 21.5112261],
                [105.3215437, 21.5122148],
                [105.3202235, 21.5128595],
                [105.3190375, 21.5128945],
                [105.3177436, 21.5124061],
                [105.3154968, 21.511616],
                [105.3130779, 21.5103781],
                [105.3100538, 21.5085766],
                [105.3084814, 21.5077041],
                [105.3060041, 21.5073698],
                [105.3043736, 21.5075987],
                [105.3014172, 21.5092419],
                [105.2989464, 21.5121266],
                [105.2981043, 21.5138788],
                [105.2974451, 21.5164778],
                [105.2972072, 21.5182854],
                [105.296428, 21.5213082],
                [105.2966135, 21.5233975],
                [105.2972813, 21.5250341],
                [105.2983745, 21.5278558],
                [105.2988623, 21.5300292],
                [105.2987637, 21.5314038],
                [105.2987447, 21.5316673],
                [105.2983845, 21.532741],
                [105.2973004, 21.5343806],
                [105.2956721, 21.5358519],
                [105.2925336, 21.5374387],
                [105.2903006, 21.5386569],
                [105.2859543, 21.5405845],
                [105.2827539, 21.5414935],
                [105.2816666, 21.5416084],
                [105.276954, 21.5416727],
                [105.2708202, 21.5434499],
                [105.268517, 21.544378],
                [105.2673885, 21.5461728],
                [105.2671413, 21.5489697],
                [105.2682378, 21.5513474],
                [105.2720361, 21.5545515],
                [105.2740628, 21.5564626],
                [105.2742982, 21.5580657],
                [105.2753591, 21.5609333],
                [105.2731874, 21.5629418],
                [105.267332, 21.5662268],
                [105.2622981, 21.570242],
                [105.2620822, 21.5704143],
                [105.2581008, 21.574402],
                [105.2549044, 21.5781342],
                [105.2537593, 21.5799432],
                [105.2516487, 21.582629],
                [105.2504431, 21.584438],
                [105.2503172, 21.5849265],
                [105.2499628, 21.5863024],
                [105.2495343, 21.5890466],
                [105.2494724, 21.5916913],
                [105.2495143, 21.5928506],
                [105.2492769, 21.5937898],
                [105.2489187, 21.594688],
                [105.2480017, 21.5960357],
                [105.246864, 21.5969163],
                [105.2460258, 21.597572],
                [105.2457867, 21.5980025],
                [105.2459591, 21.5984161],
                [105.2460478, 21.5985367],
                [105.2466811, 21.5991862],
                [105.2465303, 21.5994277],
                [105.2463972, 21.5996525],
                [105.2463885, 21.5997607],
                [105.2464243, 21.5998854],
                [105.2464421, 21.599927],
                [105.2465401, 21.5999518],
                [105.2466824, 21.5999599],
                [105.2468337, 21.5999846],
                [105.2469939, 21.6000426],
                [105.2472523, 21.6002752],
                [105.247662, 21.6006073],
                [105.2478401, 21.6007152],
                [105.2479113, 21.6007234],
                [105.2480002, 21.6006983],
                [105.2480356, 21.6006234],
                [105.2480619, 21.6003987],
                [105.2480974, 21.6002989],
                [105.2481684, 21.6002156],
                [105.2482395, 21.6001738],
                [105.2483107, 21.6001737],
                [105.2484174, 21.6001819],
                [105.2485154, 21.60024],
                [105.2486312, 21.6003646],
                [105.2487115, 21.6004643],
                [105.2487738, 21.6005141],
                [105.2488806, 21.6005306],
                [105.2489339, 21.6004972],
                [105.2490049, 21.6003973],
                [105.2490402, 21.6002641],
                [105.2490578, 21.6001227],
                [105.249111, 21.5999978],
                [105.2491642, 21.5998979],
                [105.2492352, 21.599823],
                [105.249333, 21.5997895],
                [105.2494397, 21.5997811],
                [105.2495644, 21.5998558],
                [105.2497426, 21.6000136],
                [105.2499832, 21.6002627],
                [105.2500457, 21.6003958],
                [105.2500547, 21.6004872],
                [105.2499482, 21.6006289],
                [105.2497172, 21.6007789],
                [105.2494151, 21.6010123],
                [105.2493264, 21.6011289],
                [105.2493177, 21.6012121],
                [105.2493267, 21.601312],
                [105.2494099, 21.601455],
                [105.2494576, 21.6014918],
                [105.2495732, 21.6014501],
                [105.2496799, 21.6013834],
                [105.2498221, 21.6013498],
                [105.2498845, 21.6014246],
                [105.2499025, 21.6015328],
                [105.2499026, 21.6016161],
                [105.2499383, 21.6016909],
                [105.2500985, 21.601724],
                [105.2502853, 21.6017153],
                [105.2505344, 21.6017317],
                [105.2507214, 21.6018396],
                [105.2507838, 21.6019227],
                [105.2507662, 21.6019893],
                [105.2507217, 21.602031],
                [105.2505617, 21.6020729],
                [105.2504283, 21.6021313],
                [105.2502684, 21.6022315],
                [105.250153, 21.6023897],
                [105.2501355, 21.6025396],
                [105.2501802, 21.6026728],
                [105.2502515, 21.6027725],
                [105.2503761, 21.6028223],
                [105.2505096, 21.6028138],
                [105.2506429, 21.602747],
                [105.2508296, 21.6026634],
                [105.250954, 21.6025884],
                [105.2510607, 21.6025466],
                [105.2511763, 21.6025547],
                [105.2511942, 21.6026379],
                [105.2510522, 21.602813],
                [105.2509102, 21.603013],
                [105.2509103, 21.6030796],
                [105.250946, 21.6031295],
                [105.251035, 21.6031376],
                [105.2511596, 21.6031374],
                [105.2513108, 21.6031621],
                [105.2514177, 21.6032453],
                [105.2515246, 21.6033616],
                [105.2515665, 21.6034435],
                [105.2516413, 21.6035033],
                [105.2517054, 21.603558],
                [105.2517748, 21.6035728],
                [105.2519027, 21.6035627],
                [105.2520411, 21.6034627],
                [105.2521157, 21.6033878],
                [105.2522701, 21.6032978],
                [105.2524034, 21.6032228],
                [105.2525046, 21.6031579],
                [105.2526217, 21.6030828],
                [105.2526323, 21.6030181],
                [105.2526322, 21.6029781],
                [105.2525947, 21.6028984],
                [105.2524451, 21.6027441],
                [105.2523596, 21.6026145],
                [105.2523168, 21.6025348],
                [105.2523167, 21.60246],
                [105.2523379, 21.6023702],
                [105.2523697, 21.6022755],
                [105.2524335, 21.6021706],
                [105.252524, 21.6021007],
                [105.2525667, 21.6020906],
                [105.2526468, 21.6021453],
                [105.2526948, 21.6022101],
                [105.252727, 21.6022599],
                [105.2527964, 21.6023147],
                [105.2528657, 21.6023246],
                [105.2528924, 21.6023245],
                [105.2529456, 21.6022895],
                [105.2529615, 21.6022446],
                [105.2529773, 21.6021549],
                [105.2529718, 21.6020252],
                [105.2529504, 21.6019555],
                [105.2529556, 21.6018956],
                [105.2529928, 21.6018656],
                [105.2530621, 21.6018406],
                [105.2531155, 21.6018504],
                [105.2531795, 21.6018952],
                [105.2532383, 21.6019749],
                [105.2532651, 21.6020247],
                [105.2533024, 21.6020695],
                [105.25332, 21.6020859],
                [105.2533112, 21.6022252],
                [105.2535338, 21.602527],
                [105.2535125, 21.6027648],
                [105.2535354, 21.6034663],
                [105.2532936, 21.6043775],
                [105.252597, 21.6063996],
                [105.2520955, 21.6076333],
                [105.2515596, 21.6085312],
                [105.2513417, 21.6095971],
                [105.2513433, 21.6105505],
                [105.2511845, 21.6111676],
                [105.2507061, 21.6118226],
                [105.2496889, 21.6128897],
                [105.2489081, 21.6138162],
                [105.2484396, 21.6145138],
                [105.2470507, 21.615363],
                [105.2443918, 21.6158752],
                [105.2402825, 21.6166718],
                [105.2378041, 21.6167319],
                [105.2356887, 21.6169043],
                [105.2342991, 21.6173863],
                [105.2331523, 21.6185174],
                [105.2302576, 21.6229264],
                [105.2289319, 21.6256391],
                [105.2284497, 21.6265433],
                [105.2265482, 21.629168],
                [105.2262177, 21.6296242],
                [105.2290019, 21.6316535],
                [105.2314231, 21.6335138],
                [105.2335421, 21.6353745],
                [105.2343903, 21.6364464],
                [105.2375413, 21.6408186],
                [105.2393609, 21.6443739],
                [105.2396005, 21.6461426],
                [105.2396669, 21.6466324],
                [105.2394889, 21.6486376],
                [105.2388211, 21.6509178],
                [105.2374411, 21.6535538],
                [105.2364192, 21.6562169],
                [105.2354591, 21.6587346],
                [105.2345202, 21.6586008],
                [105.2323199, 21.6591283],
                [105.2310616, 21.6597284],
                [105.2299428, 21.660029],
                [105.2285838, 21.6601057],
                [105.2275843, 21.6600322],
                [105.2266249, 21.6599962],
                [105.2254655, 21.6599416],
                [105.224446, 21.6598495],
                [105.2228747, 21.6599611],
                [105.2219953, 21.660037],
                [105.2212451, 21.6601721],
                [105.2211871, 21.6607223],
                [105.2208142, 21.6610926],
                [105.2195593, 21.6615919],
                [105.2187607, 21.6622098],
                [105.2175693, 21.6623048],
                [105.2163302, 21.6623439],
                [105.2149581, 21.6621775],
                [105.2140584, 21.6620291],
                [105.2135385, 21.6618803],
                [105.2129184, 21.6615633],
                [105.2120584, 21.6612092],
                [105.2108593, 21.6613228],
                [105.2084406, 21.661139],
                [105.2075415, 21.6613831],
                [105.2051446, 21.6625077],
                [105.2018692, 21.6643438],
                [105.2013704, 21.6649425],
                [105.2003121, 21.6657103],
                [105.2000109, 21.6661368],
                [105.1997335, 21.6665297],
                [105.1994781, 21.6673109],
                [105.1994784, 21.6675697],
                [105.1993997, 21.6678656],
                [105.1993011, 21.6680505],
                [105.1990838, 21.6681987],
                [105.1988664, 21.6682729],
                [105.1986686, 21.6682731],
                [105.1980752, 21.6681814],
                [105.1974621, 21.6681636],
                [105.1970865, 21.6682381],
                [105.1961179, 21.668535],
                [105.1953869, 21.6689794],
                [105.1947351, 21.6696087],
                [105.1938861, 21.6706079],
                [105.1934321, 21.6711999],
                [105.1930769, 21.6717919],
                [105.1930179, 21.6720137],
                [105.1931172, 21.6723093],
                [105.1934739, 21.6728635],
                [105.1935729, 21.6730297],
                [105.1936127, 21.6731961],
                [105.193613, 21.6733624],
                [105.193534, 21.6734919],
                [105.1932573, 21.6735847],
                [105.1926841, 21.6738256],
                [105.1924273, 21.6740108],
                [105.1923286, 21.6741957],
                [105.1923288, 21.6743436],
                [105.1925564, 21.6745224],
                [105.1929326, 21.6747624],
                [105.1933481, 21.6749468],
                [105.1934866, 21.6750392],
                [105.1935659, 21.6751685],
                [105.193566, 21.675261],
                [105.193507, 21.6755015],
                [105.1932703, 21.6758902],
                [105.1927765, 21.6763902],
                [105.1922233, 21.6768347],
                [105.191828, 21.6770202],
                [105.1915314, 21.6770575],
                [105.190424, 21.6770957],
                [105.1890397, 21.6770973],
                [105.1879125, 21.6771541],
                [105.1866272, 21.6772665],
                [105.1858165, 21.6773969],
                [105.1854606, 21.6774713],
                [105.1851247, 21.6776381],
                [105.1846308, 21.6780456],
                [105.1839494, 21.6787954],
                [105.183495, 21.6791843],
                [105.1830011, 21.6794993],
                [105.1824477, 21.6798144],
                [105.1819734, 21.6800923],
                [105.1817363, 21.6802776],
                [105.1814934, 21.6804399],
                [105.1813025, 21.6812768],
                [105.1811058, 21.6821463],
                [105.1809878, 21.6826458],
                [105.1807803, 21.6828218],
                [105.1805825, 21.6828405],
                [105.1800482, 21.6825452],
                [105.1796919, 21.682342],
                [105.1794545, 21.6822314],
                [105.179316, 21.682213],
                [105.1792768, 21.6824166],
                [105.1792772, 21.6827679],
                [105.1792201, 21.6845991],
                [105.1791426, 21.6858754],
                [105.1790578, 21.6861923],
                [105.1789591, 21.6863773],
                [105.1785638, 21.6867244],
                [105.1780943, 21.6870485],
                [105.1777482, 21.6872107],
                [105.1774021, 21.6873036],
                [105.1770064, 21.6873502],
                [105.1765118, 21.6873508],
                [105.1757941, 21.6869354],
                [105.1748783, 21.6863816],
                [105.174161, 21.6862668],
                [105.1733695, 21.6862214],
                [105.1725927, 21.6863666],
                [105.1719353, 21.6864309],
                [105.1713089, 21.6863522],
                [105.1704759, 21.6862244],
                [105.1698327, 21.6860863],
                [105.1694864, 21.6860867],
                [105.1691651, 21.6861564],
                [105.1684732, 21.6867812],
                [105.1673861, 21.6876838],
                [105.1668424, 21.6881004],
                [105.1663976, 21.688332],
                [105.1659278, 21.6884711],
                [105.1654702, 21.6884179],
                [105.164802, 21.6881179],
                [105.1628716, 21.6868709],
                [105.1611882, 21.6854848],
                [105.1602228, 21.684653],
                [105.1598759, 21.6840983],
                [105.1595787, 21.6836822],
                [105.1588607, 21.682966],
                [105.1579943, 21.6822266],
                [105.1572889, 21.6817532],
                [105.1568471, 21.6815363],
                [105.1567883, 21.6817295],
                [105.1565973, 21.6818685],
                [105.1563426, 21.6818688],
                [105.1561514, 21.6817896],
                [105.1558935, 21.6815322],
                [105.1558114, 21.6814328],
                [105.1557042, 21.6813118],
                [105.1556348, 21.6812855],
                [105.1555388, 21.6813119],
                [105.1554445, 21.6815105],
                [105.1553975, 21.6817313],
                [105.1553979, 21.6821505],
                [105.1554692, 21.6825035],
                [105.1555644, 21.6831212],
                [105.1557303, 21.6836286],
                [105.1557541, 21.6838272],
                [105.1558489, 21.6840477],
                [105.155991, 21.6843125],
                [105.1563221, 21.6845989],
                [105.1565349, 21.684687],
                [105.1567476, 21.684753],
                [105.1568894, 21.6848191],
                [105.1569133, 21.6850397],
                [105.1567482, 21.6853488],
                [105.1567248, 21.6855916],
                [105.1568614, 21.6862258],
                [105.1568061, 21.6863618],
                [105.1566139, 21.6864904],
                [105.1563393, 21.6866448],
                [105.156202, 21.6867478],
                [105.1562022, 21.686902],
                [105.1562298, 21.6870047],
                [105.1564772, 21.6872101],
                [105.1565324, 21.6873128],
                [105.1565325, 21.6874156],
                [105.1564502, 21.6875956],
                [105.1562854, 21.6876471],
                [105.1561205, 21.6876216],
                [105.1559005, 21.687519],
                [105.1556533, 21.6875707],
                [105.1551866, 21.6879565],
                [105.1551044, 21.6882393],
                [105.1551045, 21.6883934],
                [105.1551324, 21.6887276],
                [105.1550227, 21.6889332],
                [105.1548304, 21.6890105],
                [105.1544732, 21.689088],
                [105.1538414, 21.6892427],
                [105.1535117, 21.6892944],
                [105.1533743, 21.689346],
                [105.1532097, 21.689526],
                [105.1530725, 21.6897831],
                [105.1528529, 21.6899889],
                [105.1527157, 21.6901946],
                [105.1526061, 21.6905545],
                [105.1525239, 21.6907088],
                [105.1523317, 21.6908631],
                [105.1520569, 21.6909404],
                [105.1518646, 21.690992],
                [105.1518098, 21.6910949],
                [105.1517002, 21.6914033],
                [105.1512884, 21.6917635],
                [105.1511513, 21.692072],
                [105.1511518, 21.692586],
                [105.1511246, 21.6927916],
                [105.1509323, 21.6929717],
                [105.1506028, 21.6932032],
                [105.1504107, 21.6934347],
                [105.1503835, 21.6936403],
                [105.150466, 21.6937687],
                [105.1505487, 21.694],
                [105.1505214, 21.6941798],
                [105.1502196, 21.694617],
                [105.1501924, 21.6948226],
                [105.1501653, 21.6952081],
                [105.1501931, 21.6955679],
                [105.1503032, 21.6958247],
                [105.1505234, 21.6961072],
                [105.1505237, 21.6963899],
                [105.1505789, 21.6965954],
                [105.1507714, 21.696698],
                [105.1509639, 21.6968264],
                [105.1509916, 21.697032],
                [105.1510194, 21.697366],
                [105.1512395, 21.6976228],
                [105.1512396, 21.697777],
                [105.1511025, 21.6979827],
                [105.1505531, 21.6982612],
                [105.1503609, 21.6984155],
                [105.1502652, 21.6985275],
                [105.1496695, 21.6988329],
                [105.1494428, 21.6989285],
                [105.1481242, 21.6997034],
                [105.1478401, 21.6998393],
                [105.1469089, 21.7002847],
                [105.1462881, 21.7004545],
                [105.1455877, 21.7005938],
                [105.1450985, 21.7006552],
                [105.1445883, 21.7005545],
                [105.144384, 21.7004041],
                [105.1441923, 21.7000936],
                [105.1438808, 21.6991752],
                [105.143699, 21.6984017],
                [105.1435695, 21.6982326],
                [105.1431814, 21.6981846],
                [105.1416553, 21.6984277],
                [105.1403876, 21.6983803],
                [105.1393009, 21.6982362],
                [105.1379294, 21.6978505],
                [105.1364802, 21.6973199],
                [105.1353718, 21.6969959],
                [105.1351605, 21.6969341],
                [105.1342161, 21.6967577],
                [105.1337182, 21.6966795],
                [105.133026, 21.6967829],
                [105.1324571, 21.6970252],
                [105.1319918, 21.6973401],
                [105.1314232, 21.6980421],
                [105.130648, 21.6990346],
                [105.1297949, 21.699761],
                [105.1288127, 21.7007536],
                [105.128451, 21.7012861],
                [105.1282184, 21.7016008],
                [105.1277073, 21.7022428],
                [105.1276445, 21.7023428],
                [105.127449, 21.7026542],
                [105.1271647, 21.7030415],
                [105.1265699, 21.7032839],
                [105.125949, 21.7033812],
                [105.1251215, 21.7037689],
                [105.1243199, 21.7042775],
                [105.1236475, 21.7045682],
                [105.1224059, 21.7048353],
                [105.1212936, 21.7050297],
                [105.1202072, 21.7053691],
                [105.1195088, 21.7054906],
                [105.1185904, 21.7055033],
                [105.1179436, 21.7054071],
                [105.1171414, 21.705214],
                [105.1162875, 21.7050696],
                [105.1158995, 21.7051182],
                [105.1150719, 21.7054816],
                [105.1142703, 21.7060386],
                [105.1134428, 21.706523],
                [105.113003, 21.7066684],
                [105.1125115, 21.7066688],
                [105.111761, 21.7064032],
                [105.1108294, 21.7061136],
                [105.110349, 21.7059316],
                [105.1096422, 21.705877],
                [105.1094554, 21.7059295],
                [105.1091939, 21.7060869],
                [105.1090258, 21.7062443],
                [105.1087084, 21.7066114],
                [105.1084659, 21.7071009],
                [105.1082608, 21.7078698],
                [105.1080372, 21.7086738],
                [105.1079066, 21.7088835],
                [105.1078459, 21.7089176],
                [105.1073992, 21.7097691],
                [105.10665, 21.7113655],
                [105.10634, 21.7121395],
                [105.106159, 21.7123572],
                [105.1059003, 21.7124299],
                [105.1053827, 21.7124302],
                [105.1049687, 21.7124789],
                [105.1046842, 21.7126242],
                [105.104348, 21.7129872],
                [105.1041422, 21.7145348],
                [105.1039614, 21.7149944],
                [105.1035736, 21.7155509],
                [105.1034081, 21.7156581],
                [105.1032116, 21.7157853],
                [105.1031624, 21.7158007],
                [105.1027565, 21.7159044],
                [105.1025535, 21.7160253],
                [105.1024059, 21.7161978],
                [105.1022401, 21.7166464],
                [105.1020742, 21.7170432],
                [105.1017594, 21.7176177],
                [105.1010437, 21.717591],
                [105.1007533, 21.7175172],
                [105.1004892, 21.7173447],
                [105.100093, 21.7169997],
                [105.0997761, 21.7168271],
                [105.0996178, 21.7167779],
                [105.0994066, 21.7168274],
                [105.099222, 21.7169509],
                [105.0988791, 21.717321],
                [105.0985624, 21.7175185],
                [105.098193, 21.7176174],
                [105.0975068, 21.7178151],
                [105.0971901, 21.717914],
                [105.0966887, 21.7180376],
                [105.0964248, 21.718161],
                [105.0962138, 21.7183585],
                [105.0957916, 21.7186547],
                [105.0952902, 21.7188524],
                [105.0949735, 21.7188772],
                [105.0944983, 21.7188775],
                [105.0939439, 21.7186804],
                [105.0936271, 21.7185573],
                [105.0934184, 21.7186114],
                [105.0931504, 21.7189664],
                [105.0928919, 21.7193779],
                [105.0926591, 21.7195474],
                [105.0921933, 21.7196203],
                [105.0916499, 21.7196447],
                [105.0912358, 21.7196208],
                [105.0906405, 21.7194759],
                [105.0897087, 21.7192587],
                [105.0880263, 21.7187515],
                [105.0862792, 21.7182763],
                [105.0867959, 21.7167955],
                [105.087394, 21.7161488],
                [105.0887759, 21.715426],
                [105.0903935, 21.7143938],
                [105.0915028, 21.7135853],
                [105.0917415, 21.7131665],
                [105.0919431, 21.7123044],
                [105.0917846, 21.7111565],
                [105.09137, 21.7101052],
                [105.0907518, 21.7094376],
                [105.0891075, 21.7086778],
                [105.0883574, 21.7082294],
                [105.0878456, 21.7075924],
                [105.0872512, 21.706244],
                [105.0866284, 21.7044278],
                [105.0864449, 21.7032275],
                [105.086521, 21.7025586],
                [105.086683, 21.7022078],
                [105.0869776, 21.701986],
                [105.0884117, 21.7015503],
                [105.0900065, 21.7011523],
                [105.091191, 21.7008394],
                [105.0947677, 21.7007142],
                [105.0958955, 21.7006144],
                [105.0964154, 21.7002119],
                [105.0966608, 21.6996546],
                [105.0957383, 21.6968527],
                [105.0962793, 21.6946052],
                [105.0977059, 21.6921955],
                [105.099086, 21.6908412],
                [105.0997853, 21.6901384],
                [105.1003814, 21.6893708],
                [105.1001496, 21.6876313],
                [105.0997802, 21.6850964],
                [105.099477, 21.6831973],
                [105.0985807, 21.6815129],
                [105.0982308, 21.680471],
                [105.0974394, 21.6807109],
                [105.0965775, 21.681116],
                [105.0963006, 21.6811104],
                [105.0960378, 21.6810924],
                [105.0954747, 21.6808759],
                [105.0951451, 21.6804835],
                [105.0950958, 21.6798716],
                [105.0949518, 21.6790414],
                [105.0948559, 21.678517],
                [105.0946669, 21.6781242],
                [105.0942917, 21.6780381],
                [105.0940122, 21.6784325],
                [105.0934046, 21.6787846],
                [105.0931703, 21.6787854],
                [105.0926546, 21.6786998],
                [105.0922325, 21.6786138],
                [105.0917179, 21.6787906],
                [105.091626, 21.6792281],
                [105.091721, 21.6795338],
                [105.0920495, 21.6796639],
                [105.0926588, 21.6797491],
                [105.0930393, 21.6798797],
                [105.0930074, 21.6802206],
                [105.0929098, 21.6806855],
                [105.0925141, 21.6813373],
                [105.0921835, 21.6816483],
                [105.0918523, 21.6818044],
                [105.0915867, 21.6817743],
                [105.0912204, 21.6814969],
                [105.0909537, 21.6812191],
                [105.0906873, 21.6810031],
                [105.0905546, 21.6810037],
                [105.0903227, 21.6811283],
                [105.090225, 21.6815932],
                [105.0901272, 21.6820273],
                [105.0900289, 21.6823374],
                [105.089897, 21.6825237],
                [105.0896319, 21.6826485],
                [105.0893666, 21.6827114],
                [105.0890345, 21.6826507],
                [105.0888014, 21.6824656],
                [105.0886018, 21.6823734],
                [105.08837, 21.6824671],
                [105.0879734, 21.6829022],
                [105.087675, 21.6829962],
                [105.0874431, 21.6830899],
                [105.0871776, 21.6830908],
                [105.0869447, 21.6829678],
                [105.0867118, 21.6828137],
                [105.0863458, 21.6825982],
                [105.0861461, 21.682475],
                [105.0858803, 21.6823831],
                [105.0855813, 21.6823221],
                [105.0851829, 21.6822925],
                [105.0847185, 21.6823562],
                [105.0845864, 21.6825114],
                [105.0844876, 21.6827286],
                [105.084754, 21.6829135],
                [105.0851527, 21.683036],
                [105.0854851, 21.6831588],
                [105.0855521, 21.6833134],
                [105.0854198, 21.6834377],
                [105.0851212, 21.6834388],
                [105.0846892, 21.6833163],
                [105.0841906, 21.6831323],
                [105.0839246, 21.6830403],
                [105.0837919, 21.6830408],
                [105.0835598, 21.6831035],
                [105.0832294, 21.6834764],
                [105.0829648, 21.6836942],
                [105.0826997, 21.683819],
                [105.0824672, 21.6837578],
                [105.0823666, 21.6835105],
                [105.0825643, 21.683169],
                [105.0827294, 21.6829517],
                [105.0827284, 21.6827039],
                [105.0826616, 21.6825802],
                [105.0822958, 21.6824266],
                [105.0814652, 21.6822127],
                [105.0806353, 21.6821846],
                [105.0801879, 21.6823622],
                [105.0799133, 21.6826781],
                [105.0786922, 21.6820131],
                [105.0776137, 21.681634],
                [105.0756657, 21.6817844],
                [105.0741799, 21.6820767],
                [105.0725392, 21.6821302],
                [105.0712092, 21.6829482],
                [105.0698279, 21.6837665],
                [105.067779, 21.6843477],
                [105.065684, 21.6862689],
                [105.0644576, 21.6873738],
                [105.0624108, 21.6892786],
                [105.0614524, 21.6896952],
                [105.0608606, 21.6894215],
                [105.0598947, 21.6879089],
                [105.0591491, 21.686051],
                [105.0581132, 21.6855031],
                [105.0554569, 21.6860634],
                [105.0534693, 21.6876548],
                [105.0522163, 21.6882792],
                [105.0497048, 21.6880808],
                [105.0485274, 21.6891183],
                [105.0469805, 21.690157],
                [105.0454303, 21.6903],
                [105.043807, 21.6906498],
                [105.0432219, 21.6921677],
                [105.0421928, 21.6934114],
                [105.0404981, 21.6944505],
                [105.0398354, 21.694935],
                [105.040356, 21.6959668],
                [105.039253, 21.6972109],
                [105.0374822, 21.6976301],
                [105.0355665, 21.6988076],
                [105.0340205, 21.7001218],
                [105.0320306, 21.7012307],
                [105.0306383, 21.701304],
                [105.0306384, 21.7012013],
                [105.0305026, 21.7011799],
                [105.0298866, 21.7006174],
                [105.029587, 21.6994469],
                [105.028917, 21.698002],
                [105.0276568, 21.6966969],
                [105.0267677, 21.6958728],
                [105.0257317, 21.6953249],
                [105.0248477, 21.6958789],
                [105.0244756, 21.6951221],
                [105.0238824, 21.6944349],
                [105.0232929, 21.6947813],
                [105.0228537, 21.6958852],
                [105.0219684, 21.6960947],
                [105.020713, 21.6960986],
                [105.0204904, 21.6958237],
                [105.0204871, 21.694928],
                [105.0209263, 21.693824],
                [105.021587, 21.6927194],
                [105.0212898, 21.6922381],
                [105.0204743, 21.6913447],
                [105.0198078, 21.6907957],
                [105.0195106, 21.6903143],
                [105.0195049, 21.6887294],
                [105.0192802, 21.6878342],
                [105.0179517, 21.6880452],
                [105.0161822, 21.6888086],
                [105.0147797, 21.6889508],
                [105.0121941, 21.6886832],
                [105.0097558, 21.6882772],
                [105.0068747, 21.6879415],
                [105.0058404, 21.6878069],
                [105.0046616, 21.6885685],
                [105.0028156, 21.688643],
                [105.0014853, 21.6883025],
                [105.0006719, 21.6879604],
                [104.9995627, 21.6875503],
                [104.9988958, 21.6868632],
                [104.9988193, 21.6861055],
                [104.9990051, 21.6859165],
                [105.0016944, 21.6847187],
                [105.0021351, 21.6840282],
                [105.0019833, 21.6828573],
                [105.0013872, 21.6812741],
                [105.0007169, 21.6796224],
                [105.0000509, 21.6792111],
                [104.9982049, 21.6792166],
                [104.9957353, 21.6783975],
                [104.9954443, 21.678438],
                [104.9950363, 21.6784436],
                [104.9941838, 21.6783825],
                [104.9936386, 21.6784477],
                [104.99306, 21.6787041],
                [104.9924813, 21.6789286],
                [104.9914581, 21.6788044],
                [104.9907751, 21.6784562],
                [104.9901252, 21.6777895],
                [104.989662, 21.6769098],
                [104.9893867, 21.6761471],
                [104.989113, 21.6758298],
                [104.9887035, 21.6757355],
                [104.9880899, 21.6757373],
                [104.9877828, 21.6756428],
                [104.9873382, 21.6752305],
                [104.9870984, 21.6748813],
                [104.98662, 21.6745009],
                [104.9860739, 21.6743117],
                [104.9848126, 21.6743153],
                [104.9841303, 21.6741583],
                [104.9834815, 21.6738421],
                [104.9829689, 21.67343],
                [104.9824228, 21.6732406],
                [104.9816726, 21.6731474],
                [104.9801023, 21.6724839],
                [104.9810652, 21.671237],
                [104.9824864, 21.6706352],
                [104.983695, 21.6702996],
                [104.9844756, 21.6696332],
                [104.9849711, 21.6688348],
                [104.9857534, 21.6686997],
                [104.9863919, 21.6681001],
                [104.9863872, 21.6667054],
                [104.9859546, 21.6650464],
                [104.985735, 21.6631874],
                [104.9856596, 21.6619258],
                [104.9851595, 21.6613295],
                [104.9834486, 21.6604047],
                [104.9818104, 21.6599446],
                [104.9801024, 21.6598831],
                [104.9785365, 21.6597549],
                [104.9774661, 21.6588281],
                [104.9767504, 21.6575683],
                [104.975752, 21.656907],
                [104.9745419, 21.6567777],
                [104.9737598, 21.6569792],
                [104.9730477, 21.656782],
                [104.9729032, 21.6561182],
                [104.9731841, 21.6549884],
                [104.9729668, 21.6537935],
                [104.9724665, 21.6531309],
                [104.971611, 21.652602],
                [104.9707547, 21.6518738],
                [104.9705389, 21.651144],
                [104.9707498, 21.6503464],
                [104.9714591, 21.6496801],
                [104.9721683, 21.6489476],
                [104.9722358, 21.6478184],
                [104.972161, 21.6466895],
                [104.9718035, 21.6461593],
                [104.9713057, 21.6462272],
                [104.9698861, 21.6472938],
                [104.9676135, 21.6486284],
                [104.9659675, 21.6504198],
                [104.9649122, 21.6518044],
                [104.9643078, 21.652372],
                [104.963936, 21.6527647],
                [104.9631442, 21.6530717],
                [104.9626315, 21.6531602],
                [104.9620713, 21.6530311],
                [104.9616048, 21.6529889],
                [104.961232, 21.6530769],
                [104.961233, 21.6533816],
                [104.9612808, 21.6537298],
                [104.9613291, 21.654252],
                [104.9613768, 21.6546001],
                [104.9613775, 21.6548178],
                [104.9611444, 21.6548184],
                [104.9607703, 21.6545582],
                [104.9604442, 21.6546462],
                [104.9603986, 21.6549946],
                [104.9603996, 21.6553065],
                [104.9604033, 21.6564745],
                [104.9603107, 21.6566489],
                [104.960264, 21.6566491],
                [104.9598898, 21.6563018],
                [104.9594684, 21.6557806],
                [104.9591511, 21.6556207],
                [104.9590156, 21.6559396],
                [104.9593602, 21.6570216],
                [104.9593622, 21.6576586],
                [104.9591589, 21.6581051],
                [104.9588178, 21.6581698],
                [104.9586138, 21.6583614],
                [104.9584786, 21.6588077],
                [104.9584146, 21.6589868],
                [104.9582363, 21.6590985],
                [104.9580187, 21.6593771],
                [104.9576427, 21.6598229],
                [104.9575839, 21.6600826],
                [104.95769, 21.6606025],
                [104.9572304, 21.6606574],
                [104.956494, 21.6608314],
                [104.9561618, 21.6606603],
                [104.9556081, 21.6603867],
                [104.9551659, 21.6603879],
                [104.9549767, 21.6604642],
                [104.9543087, 21.659932],
                [104.9532786, 21.6597061],
                [104.9524683, 21.6591822],
                [104.9525068, 21.6580097],
                [104.9523821, 21.6571563],
                [104.9513438, 21.6566468],
                [104.9504258, 21.6555678],
                [104.9497534, 21.6550574],
                [104.9485345, 21.6552884],
                [104.9472539, 21.6553488],
                [104.9463389, 21.6552373],
                [104.9451153, 21.6539315],
                [104.9432222, 21.6530829],
                [104.9413295, 21.6523479],
                [104.9402918, 21.6520092],
                [104.9381458, 21.6518966],
                [104.9381533, 21.6506488],
                [104.9384527, 21.6488266],
                [104.9383869, 21.647233],
                [104.9380156, 21.6454126],
                [104.9366693, 21.6438224],
                [104.9345912, 21.6421772],
                [104.9332473, 21.6413839],
                [104.9310499, 21.6406496],
                [104.9282448, 21.6405999],
                [104.9254401, 21.6406641],
                [104.9224522, 21.6406717],
                [104.9198298, 21.6405077],
                [104.9179983, 21.6397723],
                [104.9162883, 21.6388089],
                [104.9131987, 21.6363654],
                [104.9129882, 21.6361989],
                [104.9118268, 21.6352341],
                [104.9102389, 21.6343273],
                [104.9087093, 21.6324528],
                [104.9068131, 21.6302946],
                [104.9050994, 21.6279651],
                [104.9044224, 21.62569],
                [104.9042956, 21.6239827],
                [104.9044125, 21.622161],
                [104.9044546, 21.6207911],
                [104.9044685, 21.6203394],
                [104.9045821, 21.6173794],
                [104.9050615, 21.6143615],
                [104.9058466, 21.6116844],
                [104.9065124, 21.6099752],
                [104.9064487, 21.6090078],
                [104.9062037, 21.6086099],
                [104.905941, 21.6082433],
                [104.9051689, 21.6091247],
                [104.9031601, 21.610268],
                [104.9018191, 21.6103281],
                [104.8996219, 21.6094228],
                [104.896694, 21.6088036],
                [104.892581, 21.6091512],
                [104.8918707, 21.6088818],
                [104.890235, 21.6099573],
                [104.8891419, 21.6115536],
                [104.8880474, 21.6126376],
                [104.8878476, 21.6125664],
                [104.8874975, 21.6121836],
                [104.8879196, 21.6104181],
                [104.8904729, 21.6077368],
                [104.8922363, 21.6060821],
                [104.8933918, 21.6050549],
                [104.8936339, 21.6043712],
                [104.8932646, 21.6031198],
                [104.8924482, 21.6017351],
                [104.8921082, 21.5997667],
                [104.8919891, 21.5984753],
                [104.89235, 21.597465],
                [104.8933123, 21.5950515],
                [104.8933731, 21.5940972],
                [104.8929904, 21.5918504],
                [104.8928352, 21.5908968],
                [104.8931066, 21.5897442],
                [104.8946261, 21.5881468],
                [104.8951108, 21.5870642],
                [104.895045, 21.5852429],
                [104.8952249, 21.584161],
                [104.8954817, 21.5836],
                [104.8962625, 21.5828706],
                [104.8968034, 21.5819165],
                [104.8970442, 21.5807939],
                [104.8972686, 21.5757974],
                [104.8974097, 21.5743424],
                [104.8977699, 21.5732152],
                [104.8981307, 21.5721487],
                [104.8981685, 21.5716129],
                [104.8978105, 21.5713198],
                [104.8965567, 21.571319],
                [104.8962882, 21.5711096],
                [104.8963332, 21.5708167],
                [104.8966917, 21.5703984],
                [104.8974981, 21.5698549],
                [104.8977671, 21.5693529],
                [104.8977674, 21.5688508],
                [104.8974991, 21.5684322],
                [104.8971412, 21.5680135],
                [104.8970966, 21.5676787],
                [104.8971864, 21.5673439],
                [104.8977243, 21.5665911],
                [104.8977711, 21.5664427],
                [104.8979486, 21.5658798],
                [104.8987066, 21.5645725],
                [104.8959582, 21.5624161],
                [104.8907082, 21.5591272],
                [104.8884498, 21.5578232],
                [104.8868029, 21.5572579],
                [104.8855234, 21.5573747],
                [104.8835147, 21.5582901],
                [104.8824196, 21.5589756],
                [104.8813821, 21.5584089],
                [104.8805877, 21.5576137],
                [104.8789399, 21.5567068],
                [104.8770483, 21.5558004],
                [104.8754038, 21.5561457],
                [104.8741245, 21.5563192],
                [104.8729051, 21.5560944],
                [104.8722331, 21.5554128],
                [104.8710107, 21.5540494],
                [104.8696039, 21.5520035],
                [104.8684412, 21.5500708],
                [104.8678878, 21.5481367],
                [104.8679434, 21.5460305],
                [104.8681814, 21.5437532],
                [104.8684738, 21.5420972],
                [104.8707317, 21.5401616],
                [104.8727392, 21.538848],
                [104.8783359, 21.5353063],
                [104.8790453, 21.5344244],
                [104.8805832, 21.5325121],
                [104.8819168, 21.5298908],
                [104.8822169, 21.5281256],
                [104.8828209, 21.5260751],
                [104.8842785, 21.5243073],
                [104.8853099, 21.5226542],
                [104.8853069, 21.5215158],
                [104.884451, 21.5203794],
                [104.8831073, 21.5191303],
                [104.8829815, 21.5176506],
                [104.8835242, 21.5154865],
                [104.8831513, 21.5126982],
                [104.8824129, 21.5099108],
                [104.8824701, 21.5084308],
                [104.8834406, 21.5068348],
                [104.8845937, 21.5051815],
                [104.8849561, 21.5039853],
                [104.8848272, 21.5013103],
                [104.8849461, 21.5001717],
                [104.8860375, 21.4982339],
                [104.8868834, 21.4956135],
                [104.887487, 21.4935062],
                [104.8890644, 21.4911119],
                [104.8916719, 21.4867229],
                [104.8936743, 21.4838153],
                [104.8943406, 21.4824477],
                [104.8942751, 21.4807401],
                [104.8934149, 21.4788126],
                [104.8935353, 21.4774405],
                [104.8944441, 21.4756169],
                [104.8955381, 21.4747606],
                [104.8966933, 21.4740179],
                [104.8972397, 21.4733335],
                [104.8971144, 21.4720817],
                [104.8966226, 21.4703751],
                [104.897227, 21.4686661],
                [104.9015353, 21.46285],
                [104.9033573, 21.4610242],
                [104.9041448, 21.4594854],
                [104.9055994, 21.4569205],
                [104.9064474, 21.4552678],
                [104.9092994, 21.4516179],
                [104.9110572, 21.4486538],
                [104.9119023, 21.4459764],
                [104.9122573, 21.4422757],
                [104.9124316, 21.4393155],
                [104.9124233, 21.4363556],
                [104.9120505, 21.4337382],
                [104.9113759, 21.4319754],
                [104.9099717, 21.4306696],
                [104.9074731, 21.4298788],
                [104.9063141, 21.4291417],
                [104.9057621, 21.4276631],
                [104.9060001, 21.4256702],
                [104.9081171, 21.4205991],
                [104.9081692, 21.4174685],
                [104.9079779, 21.4143383],
                [104.907488, 21.413315],
                [104.9055944, 21.4110428],
                [104.9035213, 21.4099093],
                [104.9020541, 21.4076929],
                [104.9007079, 21.4051916],
                [104.8996694, 21.403885],
                [104.8997273, 21.4027464],
                [104.8995402, 21.4010962],
                [104.8989883, 21.3996175],
                [104.8961772, 21.3956397],
                [104.8952597, 21.3940481],
                [104.8950741, 21.3929101],
                [104.8937358, 21.3932548],
                [104.8904491, 21.3934333],
                [104.8879524, 21.3930975],
                [104.8855767, 21.3924769],
                [104.8839297, 21.3911715],
                [104.8833018, 21.3908221],
                [104.8830116, 21.3892383],
                [104.8836786, 21.3882691],
                [104.8845281, 21.3872426],
                [104.8846477, 21.3863886],
                [104.8840976, 21.385536],
                [104.882635, 21.3849132],
                [104.8820849, 21.3840037],
                [104.8818986, 21.3825812],
                [104.8830521, 21.38144],
                [104.8847538, 21.3804116],
                [104.8849337, 21.3793866],
                [104.8845647, 21.3779076],
                [104.8840743, 21.3765995],
                [104.8841302, 21.374721],
                [104.8838215, 21.373014],
                [104.8844269, 21.3717604],
                [104.8866138, 21.3701615],
                [104.8889211, 21.3680502],
                [104.8924453, 21.365879],
                [104.8936032, 21.3649167],
                [104.8937485, 21.364202],
                [104.8936307, 21.3633859],
                [104.8935995, 21.3618135],
                [104.8945038, 21.3612916],
                [104.896299, 21.3604841],
                [104.8963861, 21.3601777],
                [104.8973535, 21.3578416],
                [104.8997792, 21.3546483],
                [104.9006855, 21.3521986],
                [104.9006204, 21.3506618],
                [104.8998861, 21.3491836],
                [104.8989688, 21.3475351],
                [104.8981121, 21.3457726],
                [104.8973791, 21.3447497],
                [104.8969424, 21.344506],
                [104.8961602, 21.3440695],
                [104.8945162, 21.3437319],
                [104.8928718, 21.3432234],
                [104.8908622, 21.3426589],
                [104.8884879, 21.3422659],
                [104.8863582, 21.3423278],
                [104.8845926, 21.3419904],
                [104.8830103, 21.3419371],
                [104.8814914, 21.3428512],
                [104.8805207, 21.3439918],
                [104.8796096, 21.3446769],
                [104.8772994, 21.3455928],
                [104.8758407, 21.3462792],
                [104.8742002, 21.3472505],
                [104.873229, 21.3482773],
                [104.8723799, 21.3494176],
                [104.8715926, 21.3508993],
                [104.8701359, 21.3524393],
                [104.8687385, 21.3533532],
                [104.8686201, 21.3546626],
                [104.86771, 21.355803],
                [104.8674097, 21.3573975],
                [104.8673532, 21.3591621],
                [104.8665647, 21.3602454],
                [104.8655336, 21.3616707],
                [104.8646855, 21.3632662],
                [104.863776, 21.3646343],
                [104.8630465, 21.3649775],
                [104.8625812, 21.3654553],
                [104.86043, 21.3652676],
                [104.8587253, 21.3649868],
                [104.8567155, 21.3644219],
                [104.855189, 21.3623759],
                [104.8544548, 21.3607837],
                [104.8535994, 21.3593624],
                [104.8534748, 21.3581674],
                [104.8542619, 21.3564581],
                [104.8548053, 21.3546924],
                [104.8548629, 21.3533261],
                [104.8541287, 21.351677],
                [104.8524855, 21.3516804],
                [104.8510866, 21.3520249],
                [104.848775, 21.3524281],
                [104.8469496, 21.3525457],
                [104.8454275, 21.3522643],
                [104.844756, 21.3514119],
                [104.8446922, 21.3501597],
                [104.8455397, 21.3482227],
                [104.8461454, 21.3469692],
                [104.8468713, 21.3451462],
                [104.8476585, 21.3434939],
                [104.8488112, 21.3420115],
                [104.849903, 21.3405293],
                [104.8500225, 21.3395614],
                [104.8495339, 21.3388225],
                [104.8475844, 21.3378588],
                [104.8445397, 21.3369544],
                [104.8418012, 21.3368461],
                [104.8399763, 21.3371344],
                [104.8386982, 21.3370231],
                [104.8363849, 21.3366293],
                [104.8330391, 21.3370344],
                [104.8282951, 21.3381252],
                [104.8254362, 21.3386431],
                [104.8218455, 21.3384224],
                [104.8197516, 21.3381466],
                [104.8174559, 21.3363894],
                [104.816361, 21.3347329],
                [104.814289, 21.3333137],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [107.2109165, 15.825709],
                [107.2111141, 15.8254298],
                [107.2117407, 15.8251186],
                [107.2123971, 15.8248902],
                [107.2130824, 15.8246893],
                [107.2137964, 15.8244881],
                [107.2145385, 15.8242588],
                [107.2150804, 15.8240317],
                [107.2160155, 15.8231076],
                [107.2172275, 15.8212945],
                [107.2189946, 15.8178687],
                [107.2208477, 15.814442],
                [107.2212605, 15.8128861],
                [107.2214218, 15.8119148],
                [107.2217867, 15.8112462],
                [107.2225202, 15.8102135],
                [107.2248432, 15.8078625],
                [107.2275373, 15.8043487],
                [107.2283161, 15.8032831],
                [107.2282543, 15.8028681],
                [107.2270401, 15.8018555],
                [107.2262865, 15.8010322],
                [107.2259673, 15.8006475],
                [107.2259634, 15.8002873],
                [107.2261026, 15.7999258],
                [107.2262692, 15.799453],
                [107.2262646, 15.7990374],
                [107.2260499, 15.7977375],
                [107.2260665, 15.7966292],
                [107.2263085, 15.7951859],
                [107.2264711, 15.7943253],
                [107.2271449, 15.7930995],
                [107.2277638, 15.792068],
                [107.2280731, 15.7915384],
                [107.228182, 15.7910386],
                [107.2281202, 15.7906236],
                [107.2276846, 15.790074],
                [107.2272783, 15.7895795],
                [107.2272159, 15.7891091],
                [107.2273548, 15.7887199],
                [107.2277483, 15.7880508],
                [107.2288105, 15.7856574],
                [107.229451, 15.7839883],
                [107.2294453, 15.7834622],
                [107.2292116, 15.7830489],
                [107.2288661, 15.7828863],
                [107.2282934, 15.782892],
                [107.2274928, 15.7830112],
                [107.2267507, 15.7832403],
                [107.2262356, 15.7832734],
                [107.2256026, 15.7830027],
                [107.2239866, 15.7818833],
                [107.2219878, 15.7797983],
                [107.2207134, 15.7784814],
                [107.2197591, 15.7776322],
                [107.2192373, 15.7770557],
                [107.2190311, 15.7765314],
                [107.2190736, 15.7751734],
                [107.2192087, 15.774424],
                [107.2196309, 15.7737546],
                [107.2197689, 15.7732824],
                [107.2197056, 15.7727288],
                [107.2193525, 15.7718458],
                [107.2185871, 15.769942],
                [107.2182372, 15.7667317],
                [107.2180163, 15.76485],
                [107.2177222, 15.7641327],
                [107.2173436, 15.7635547],
                [107.216992, 15.7628103],
                [107.2169274, 15.7621459],
                [107.2168911, 15.7614537],
                [107.2169112, 15.76065],
                [107.2167032, 15.7599594],
                [107.215922, 15.7592193],
                [107.2152568, 15.7586166],
                [107.2144226, 15.7582649],
                [107.2137619, 15.7580778],
                [107.2127875, 15.7580046],
                [107.2120977, 15.7577622],
                [107.2117514, 15.7575164],
                [107.2114617, 15.7572146],
                [107.2113111, 15.7565233],
                [107.21135, 15.754833],
                [107.2114791, 15.7535295],
                [107.2116313, 15.7517271],
                [107.2116679, 15.749815],
                [107.211966, 15.7482603],
                [107.2122376, 15.7468724],
                [107.2125901, 15.745068],
                [107.2129447, 15.7434296],
                [107.213124, 15.7414883],
                [107.2131341, 15.7397705],
                [107.213098, 15.7390781],
                [107.2131238, 15.7388287],
                [107.2132948, 15.7387437],
                [107.2136111, 15.7388791],
                [107.2139867, 15.7391799],
                [107.2144197, 15.7395081],
                [107.2148523, 15.7398085],
                [107.2153413, 15.7399975],
                [107.2157422, 15.7400211],
                [107.2163689, 15.7397099],
                [107.217024, 15.7393984],
                [107.2181102, 15.7392211],
                [107.218655, 15.7392988],
                [107.2192876, 15.7395417],
                [107.2200088, 15.740033],
                [107.2208494, 15.7409664],
                [107.222181, 15.7423104],
                [107.2226141, 15.7426384],
                [107.2233322, 15.7428528],
                [107.2237623, 15.7429038],
                [107.2243919, 15.7428697],
                [107.2249928, 15.7428358],
                [107.2256214, 15.7427185],
                [107.2262218, 15.7426293],
                [107.2268519, 15.7426506],
                [107.2277403, 15.7427245],
                [107.2289452, 15.742934],
                [107.2308093, 15.7431919],
                [107.2317547, 15.7432375],
                [107.232357, 15.7433423],
                [107.2330758, 15.7436119],
                [107.2336238, 15.7439664],
                [107.2339137, 15.744296],
                [107.2342292, 15.7443482],
                [107.2345712, 15.7442062],
                [107.2349111, 15.7438702],
                [107.235424, 15.7436432],
                [107.2358533, 15.743611],
                [107.2363113, 15.7436064],
                [107.2366838, 15.7436303],
                [107.2372292, 15.7437633],
                [107.238725, 15.744385],
                [107.2399031, 15.7447608],
                [107.2423689, 15.7450679],
                [107.2448056, 15.7453198],
                [107.2467234, 15.7452723],
                [107.247925, 15.7451769],
                [107.2487531, 15.7449742],
                [107.2494652, 15.7446346],
                [107.2503165, 15.7439331],
                [107.2511563, 15.7427174],
                [107.2517836, 15.7418045],
                [107.2520769, 15.7412033],
                [107.2524096, 15.7407829],
                [107.2525937, 15.7404728],
                [107.2528157, 15.7401985],
                [107.253112, 15.7398691],
                [107.2535098, 15.7395442],
                [107.2540126, 15.739134],
                [107.2542953, 15.7387899],
                [107.2543794, 15.7384052],
                [107.2543733, 15.7378509],
                [107.2543219, 15.7371905],
                [107.2542301, 15.7368717],
                [107.2542214, 15.7360827],
                [107.2541907, 15.7352967],
                [107.2541218, 15.7350415],
                [107.2539429, 15.7348301],
                [107.2538091, 15.734682],
                [107.2535653, 15.734578],
                [107.2532108, 15.7344108],
                [107.2528789, 15.7343076],
                [107.2526564, 15.734118],
                [107.2525002, 15.7339489],
                [107.2523632, 15.7335235],
                [107.252225, 15.7329917],
                [107.2520668, 15.7326306],
                [107.2517289, 15.7319942],
                [107.2514589, 15.7314851],
                [107.2510819, 15.7312969],
                [107.2507491, 15.7311084],
                [107.2503704, 15.7310481],
                [107.2497635, 15.7309323],
                [107.2489942, 15.7306228],
                [107.2485889, 15.7302742],
                [107.2483282, 15.7297828],
                [107.2482468, 15.7290077],
                [107.2483465, 15.7281248],
                [107.2489898, 15.7269187],
                [107.2503704, 15.7251408],
                [107.2508313, 15.7245817],
                [107.2513307, 15.7238942],
                [107.2517669, 15.7234417],
                [107.2522237, 15.7228613],
                [107.2528128, 15.7223008],
                [107.253559, 15.721973],
                [107.2541299, 15.7217539],
                [107.254568, 15.7214935],
                [107.2548974, 15.7213621],
                [107.2551613, 15.7212955],
                [107.2553815, 15.7212717],
                [107.2560883, 15.7213711],
                [107.2574814, 15.7217192],
                [107.258343, 15.7218596],
                [107.2589381, 15.7218322],
                [107.2595322, 15.7217195],
                [107.2604967, 15.7211975],
                [107.2616146, 15.7205914],
                [107.2624701, 15.7201771],
                [107.2631955, 15.7199776],
                [107.2636794, 15.7198659],
                [107.264186, 15.7198179],
                [107.2647594, 15.7198119],
                [107.2653135, 15.7200622],
                [107.2660243, 15.7205456],
                [107.266378, 15.720606],
                [107.2665541, 15.7205828],
                [107.2667075, 15.7204958],
                [107.2668605, 15.7203663],
                [107.2669692, 15.7202371],
                [107.2670776, 15.7200652],
                [107.2671644, 15.7199363],
                [107.2672286, 15.719765],
                [107.2672716, 15.7196578],
                [107.267314, 15.7195081],
                [107.2673344, 15.7193584],
                [107.2673328, 15.7192091],
                [107.2673302, 15.7189744],
                [107.2673281, 15.7187824],
                [107.2671678, 15.7182506],
                [107.2668307, 15.7176782],
                [107.2667621, 15.7174655],
                [107.2667814, 15.7172094],
                [107.2668869, 15.7167815],
                [107.2670354, 15.7162463],
                [107.2669609, 15.7155006],
                [107.2667776, 15.7148835],
                [107.2665489, 15.7141393],
                [107.2664761, 15.7135425],
                [107.2664702, 15.7130092],
                [107.2667039, 15.7122173],
                [107.2670473, 15.7113601],
                [107.2671932, 15.7105906],
                [107.2672796, 15.7104189],
                [107.2674103, 15.7102895],
                [107.2677615, 15.7101366],
                [107.2681133, 15.7100475],
                [107.268884, 15.7099542],
                [107.2695655, 15.7097765],
                [107.2698948, 15.7096236],
                [107.2700033, 15.7094731],
                [107.2701332, 15.7092586],
                [107.2703049, 15.7088514],
                [107.2706918, 15.7079299],
                [107.2710177, 15.7074996],
                [107.2712361, 15.7073052],
                [107.2714772, 15.7071748],
                [107.2717188, 15.7070869],
                [107.2719828, 15.7070415],
                [107.2723132, 15.7070166],
                [107.2727983, 15.7070117],
                [107.2732632, 15.7071777],
                [107.2736626, 15.7074081],
                [107.2743298, 15.7079133],
                [107.2746631, 15.7081444],
                [107.274951, 15.7082481],
                [107.2756116, 15.7081772],
                [107.2761839, 15.7080859],
                [107.2765553, 15.7077832],
                [107.2766846, 15.7075046],
                [107.276703, 15.7071844],
                [107.2766081, 15.7065665],
                [107.2764023, 15.7059073],
                [107.2762201, 15.705397],
                [107.2759729, 15.704973],
                [107.2757705, 15.7046123],
                [107.2753896, 15.7040615],
                [107.2752783, 15.7039774],
                [107.275057, 15.7038942],
                [107.2748362, 15.7038753],
                [107.2745274, 15.7038572],
                [107.2742408, 15.7038601],
                [107.2740199, 15.7038196],
                [107.2736222, 15.7037599],
                [107.2733783, 15.7036558],
                [107.2731342, 15.7034876],
                [107.2730663, 15.703339],
                [107.2730649, 15.7032109],
                [107.2730848, 15.7030188],
                [107.2731699, 15.7027405],
                [107.2733399, 15.7021627],
                [107.2735997, 15.7017332],
                [107.2740123, 15.7011528],
                [107.2746908, 15.7006975],
                [107.2755655, 15.7000484],
                [107.2759156, 15.69981],
                [107.2761097, 15.6994239],
                [107.2763687, 15.6989092],
                [107.276603, 15.6981813],
                [107.2770111, 15.6971954],
                [107.2771125, 15.696405],
                [107.2770863, 15.6960426],
                [107.2770177, 15.6958085],
                [107.2768158, 15.6954907],
                [107.2764101, 15.6947054],
                [107.2761193, 15.6943244],
                [107.2755831, 15.6936899],
                [107.2743061, 15.6918682],
                [107.2740576, 15.6913373],
                [107.2737848, 15.6905936],
                [107.2735997, 15.689806],
                [107.2733888, 15.6886773],
                [107.2732921, 15.6879103],
                [107.2733304, 15.6873977],
                [107.2734137, 15.6869488],
                [107.2735851, 15.6864989],
                [107.2738882, 15.6859837],
                [107.2745867, 15.6853577],
                [107.2753982, 15.6849864],
                [107.2760111, 15.6845745],
                [107.2761399, 15.6842746],
                [107.2762247, 15.6839537],
                [107.2761525, 15.6833997],
                [107.2760573, 15.682782],
                [107.2758939, 15.6819728],
                [107.2755074, 15.68091],
                [107.2751484, 15.6803376],
                [107.2748333, 15.6797648],
                [107.2747194, 15.6794246],
                [107.2746279, 15.6791268],
                [107.2744483, 15.6788514],
                [107.273935, 15.6782806],
                [107.2725529, 15.6769081],
                [107.2719487, 15.6761036],
                [107.2716569, 15.6756372],
                [107.2715428, 15.6752756],
                [107.2714928, 15.6747429],
                [107.2712825, 15.6736781],
                [107.2711236, 15.6732532],
                [107.2709439, 15.6729563],
                [107.2704093, 15.6724711],
                [107.2696334, 15.6720737],
                [107.2689027, 15.6717825],
                [107.2685244, 15.6714664],
                [107.2680107, 15.6708743],
                [107.2674894, 15.6695782],
                [107.267437, 15.668832],
                [107.2673874, 15.6683417],
                [107.2673608, 15.6679153],
                [107.2672011, 15.6674261],
                [107.2669299, 15.6668315],
                [107.2667477, 15.6663642],
                [107.266514, 15.665777],
                [107.2662521, 15.6652027],
                [107.2652408, 15.6636199],
                [107.2649813, 15.6632654],
                [107.264922, 15.6630464],
                [107.2649472, 15.6627439],
                [107.2650547, 15.6621933],
                [107.2653054, 15.6617512],
                [107.2653296, 15.6613662],
                [107.265267, 15.660845],
                [107.2650048, 15.6602434],
                [107.264715, 15.6597244],
                [107.2643385, 15.6590417],
                [107.2640506, 15.6586914],
                [107.2640486, 15.6585264],
                [107.264132, 15.6583607],
                [107.2644421, 15.6581376],
                [107.264835, 15.6577211],
                [107.2651431, 15.6573331],
                [107.2653086, 15.6568916],
                [107.2654469, 15.6565602],
                [107.2654723, 15.656285],
                [107.265471, 15.6561751],
                [107.2654695, 15.6560378],
                [107.2654683, 15.6559278],
                [107.2652678, 15.6557651],
                [107.2649534, 15.6555759],
                [107.2647809, 15.6553851],
                [107.2647219, 15.6551933],
                [107.2647207, 15.6550835],
                [107.2647744, 15.6548081],
                [107.2650542, 15.6544202],
                [107.265504, 15.6540033],
                [107.2659564, 15.6538337],
                [107.2664667, 15.6537459],
                [107.2669769, 15.6536582],
                [107.2673454, 15.6535994],
                [107.2680278, 15.6536747],
                [107.268882, 15.6538859],
                [107.269821, 15.6540685],
                [107.2709596, 15.6543041],
                [107.2718147, 15.6545975],
                [107.2727274, 15.6549454],
                [107.2731261, 15.6550513],
                [107.2733538, 15.6551039],
                [107.2735526, 15.6551018],
                [107.2738617, 15.6547962],
                [107.274196, 15.6542155],
                [107.2744192, 15.6538558],
                [107.2747269, 15.6534404],
                [107.2750932, 15.6531617],
                [107.2755175, 15.6530198],
                [107.2764254, 15.6529554],
                [107.276965, 15.6529497],
                [107.2772193, 15.6528372],
                [107.2774425, 15.6524774],
                [107.2776366, 15.6520632],
                [107.2778, 15.6514293],
                [107.2780766, 15.6507667],
                [107.2780996, 15.6502717],
                [107.2778388, 15.649807],
                [107.2776315, 15.6490396],
                [107.2773122, 15.6484107],
                [107.2769923, 15.6477267],
                [107.2765875, 15.6470713],
                [107.2760111, 15.6463078],
                [107.2754353, 15.645599],
                [107.2748895, 15.6450273],
                [107.2745427, 15.6444811],
                [107.2745408, 15.6443162],
                [107.2745109, 15.6441791],
                [107.2745099, 15.6440967],
                [107.2745378, 15.6440414],
                [107.2745653, 15.6439587],
                [107.2746212, 15.6438756],
                [107.2746492, 15.6438479],
                [107.2747048, 15.6437374],
                [107.2748175, 15.6436537],
                [107.2749011, 15.6435154],
                [107.2749854, 15.643432],
                [107.2750978, 15.6433209],
                [107.2752379, 15.6431545],
                [107.2753496, 15.6429884],
                [107.2757156, 15.6427098],
                [107.2760238, 15.6423217],
                [107.2762142, 15.6415776],
                [107.2763208, 15.6409443],
                [107.2765394, 15.6401724],
                [107.2768394, 15.6390422],
                [107.2768324, 15.63841],
                [107.276828, 15.6380253],
                [107.2765386, 15.6375335],
                [107.2763359, 15.6371783],
                [107.2760753, 15.6367137],
                [107.2759566, 15.6362476],
                [107.2758641, 15.6355888],
                [107.2760271, 15.6349275],
                [107.2763061, 15.6344848],
                [107.2766717, 15.6341511],
                [107.2770642, 15.6337071],
                [107.2773997, 15.6332365],
                [107.2776519, 15.6329314],
                [107.2779618, 15.6327084],
                [107.2786112, 15.6323717],
                [107.2791487, 15.6322011],
                [107.2798849, 15.6320012],
                [107.2806776, 15.6318004],
                [107.2818389, 15.631541],
                [107.2826015, 15.6311758],
                [107.283192, 15.6306474],
                [107.2834972, 15.6300118],
                [107.2838282, 15.6291287],
                [107.2841619, 15.6284932],
                [107.2844406, 15.6280229],
                [107.2848042, 15.6275244],
                [107.2853387, 15.6270789],
                [107.2863534, 15.6264087],
                [107.2877614, 15.6253495],
                [107.2884094, 15.624903],
                [107.2892839, 15.624399],
                [107.2898207, 15.6241459],
                [107.2903869, 15.6240028],
                [107.2909532, 15.6238593],
                [107.2916624, 15.6237968],
                [107.2923718, 15.6237619],
                [107.2937354, 15.6238302],
                [107.2961806, 15.6241344],
                [107.2974023, 15.6242041],
                [107.29902, 15.6241321],
                [107.30027, 15.6242015],
                [107.3015187, 15.624161],
                [107.3018878, 15.6241571],
                [107.3021445, 15.6242642],
                [107.3025155, 15.6244254],
                [107.3029163, 15.6247235],
                [107.3034596, 15.6250751],
                [107.3041169, 15.6254531],
                [107.3048581, 15.6257202],
                [107.3055671, 15.6256302],
                [107.3061044, 15.6254322],
                [107.30701, 15.6251753],
                [107.3078885, 15.6250286],
                [107.3087113, 15.624965],
                [107.3097903, 15.6249811],
                [107.3104434, 15.6250016],
                [107.3112401, 15.6251307],
                [107.3117569, 15.6252333],
                [107.3129466, 15.6253874],
                [107.3139407, 15.6254321],
                [107.3154499, 15.6258283],
                [107.3161927, 15.6262329],
                [107.3165341, 15.6262842],
                [107.3169883, 15.6262793],
                [107.3175542, 15.6261086],
                [107.3182901, 15.6259083],
                [107.3193621, 15.6252923],
                [107.3200373, 15.6247353],
                [107.3207977, 15.6241775],
                [107.3217049, 15.624058],
                [107.3221609, 15.6242182],
                [107.3227596, 15.6244317],
                [107.3230727, 15.6245109],
                [107.3232999, 15.6245084],
                [107.3234978, 15.6244238],
                [107.3237224, 15.6242016],
                [107.3238337, 15.624008],
                [107.3239171, 15.6238422],
                [107.3239717, 15.6236492],
                [107.3240271, 15.6235387],
                [107.3241108, 15.6234003],
                [107.3242792, 15.6232336],
                [107.3244775, 15.6231766],
                [107.3248178, 15.6231454],
                [107.3254158, 15.6233041],
                [107.326355, 15.623514],
                [107.3268386, 15.6235912],
                [107.3274638, 15.6236396],
                [107.3282868, 15.6236034],
                [107.328454, 15.6233268],
                [107.3286503, 15.6231048],
                [107.3287623, 15.6229662],
                [107.3289313, 15.6228544],
                [107.3290724, 15.6227705],
                [107.3293276, 15.6227403],
                [107.3298101, 15.6227352],
                [107.330547, 15.6226172],
                [107.3311703, 15.6225009],
                [107.3318776, 15.6222733],
                [107.3325848, 15.622046],
                [107.3333509, 15.6220104],
                [107.3343445, 15.6219998],
                [107.3355073, 15.6218774],
                [107.3359599, 15.6217351],
                [107.3361855, 15.6215952],
                [107.3362981, 15.6215118],
                [107.3363818, 15.6213732],
                [107.3364091, 15.6212906],
                [107.3364357, 15.6211254],
                [107.3364905, 15.6209598],
                [107.3365175, 15.6208222],
                [107.3365434, 15.620602],
                [107.3365977, 15.6203816],
                [107.3366237, 15.6201888],
                [107.3366787, 15.6200232],
                [107.3368743, 15.6197464],
                [107.3370431, 15.619607],
                [107.3373529, 15.619384],
                [107.3377191, 15.6191326],
                [107.3380857, 15.6189088],
                [107.338563, 15.6184365],
                [107.3387857, 15.6180494],
                [107.3389772, 15.6174149],
                [107.3391395, 15.6166986],
                [107.3391656, 15.6165059],
                [107.3392773, 15.6163398],
                [107.3394461, 15.6162006],
                [107.3397281, 15.6160327],
                [107.3401795, 15.6157805],
                [107.3413387, 15.6153557],
                [107.3420144, 15.6148539],
                [107.3424352, 15.6144095],
                [107.3426891, 15.6142694],
                [107.3429433, 15.6141567],
                [107.3431986, 15.6141265],
                [107.343652, 15.6140668],
                [107.3436517, 15.6140393],
                [107.3437925, 15.6139276],
                [107.3439034, 15.6137066],
                [107.3439301, 15.6135415],
                [107.3439275, 15.6133215],
                [107.3438049, 15.6125258],
                [107.3437634, 15.6113719],
                [107.3437839, 15.6106844],
                [107.3438056, 15.6100795],
                [107.3437128, 15.6094207],
                [107.3435051, 15.6086259],
                [107.3434985, 15.6080487],
                [107.343374, 15.6070881],
                [107.3432543, 15.6065396],
                [107.3430475, 15.6058272],
                [107.3429257, 15.6051136],
                [107.3428615, 15.6044547],
                [107.3428559, 15.6039599],
                [107.3428515, 15.6035752],
                [107.3430758, 15.6033255],
                [107.3431881, 15.6032143],
                [107.3432448, 15.6032137],
                [107.3434151, 15.6032118],
                [107.3436707, 15.6032092],
                [107.3440998, 15.603507],
                [107.3443843, 15.6035589],
                [107.3445547, 15.603557],
                [107.344753, 15.6035275],
                [107.3452039, 15.6032203],
                [107.3456835, 15.6029679],
                [107.3465049, 15.6027939],
                [107.3472416, 15.6026763],
                [107.3482043, 15.6024462],
                [107.3488269, 15.6022744],
                [107.3492498, 15.6020228],
                [107.3498937, 15.6012187],
                [107.3501996, 15.6006656],
                [107.3507292, 15.5998078],
                [107.3511188, 15.5991165],
                [107.3513383, 15.5984545],
                [107.3514736, 15.5978759],
                [107.3515225, 15.5971881],
                [107.3517655, 15.5960859],
                [107.351906, 15.5959471],
                [107.3522434, 15.5956685],
                [107.3526096, 15.5954173],
                [107.3526921, 15.5951964],
                [107.3527712, 15.5946459],
                [107.3528238, 15.5942879],
                [107.3529908, 15.5939838],
                [107.353194, 15.5936676],
                [107.3535755, 15.5929881],
                [107.3537685, 15.5924912],
                [107.3538723, 15.5916381],
                [107.3538648, 15.5909784],
                [107.3538554, 15.5901539],
                [107.353904, 15.5894388],
                [107.3539009, 15.589164],
                [107.353899, 15.5889991],
                [107.3538977, 15.5888891],
                [107.3538959, 15.5887243],
                [107.3538372, 15.5885599],
                [107.3537793, 15.5884506],
                [107.353636, 15.5883422],
                [107.3534658, 15.588344],
                [107.3532671, 15.5883461],
                [107.3529832, 15.5883492],
                [107.3525303, 15.5884639],
                [107.3514222, 15.588366],
                [107.3506545, 15.5882642],
                [107.3499144, 15.5880798],
                [107.3488289, 15.5874866],
                [107.3484535, 15.5869409],
                [107.3478481, 15.5861227],
                [107.3473264, 15.5851664],
                [107.347007, 15.5845374],
                [107.3467695, 15.5836329],
                [107.3464783, 15.5829765],
                [107.3463045, 15.5826759],
                [107.3461327, 15.5825403],
                [107.3459327, 15.5824325],
                [107.3457612, 15.5823244],
                [107.3455332, 15.5822444],
                [107.3453056, 15.5821918],
                [107.3451068, 15.5821939],
                [107.3449077, 15.5821686],
                [107.3446218, 15.5819792],
                [107.344621, 15.5818968],
                [107.3445908, 15.5817321],
                [107.3445894, 15.5816222],
                [107.3446161, 15.5814569],
                [107.3447547, 15.5811807],
                [107.3449499, 15.5808762],
                [107.3451452, 15.5805717],
                [107.3453416, 15.5803772],
                [107.345454, 15.5802662],
                [107.3457362, 15.5801256],
                [107.3460478, 15.5800674],
                [107.3464168, 15.5800634],
                [107.3467007, 15.5800603],
                [107.346927, 15.5800031],
                [107.347152, 15.5798083],
                [107.3471791, 15.5796979],
                [107.3471496, 15.5795883],
                [107.346949, 15.5794255],
                [107.3464052, 15.5790465],
                [107.3457726, 15.5783111],
                [107.3452304, 15.5780696],
                [107.3443437, 15.5774743],
                [107.3436868, 15.5771241],
                [107.3433999, 15.5768522],
                [107.3432545, 15.5765513],
                [107.3432514, 15.5762766],
                [107.3432485, 15.5760292],
                [107.3432455, 15.5757543],
                [107.3434413, 15.5755049],
                [107.3440041, 15.5750591],
                [107.3445648, 15.5744483],
                [107.344925, 15.573675],
                [107.3450344, 15.5733163],
                [107.3452299, 15.5730394],
                [107.3453707, 15.5729281],
                [107.3454549, 15.5728446],
                [107.3458788, 15.5726752],
                [107.3462187, 15.5726165],
                [107.3466718, 15.5725293],
                [107.3474965, 15.5726578],
                [107.348293, 15.5728143],
                [107.3488333, 15.572891],
                [107.349739, 15.5726614],
                [107.350189, 15.5722993],
                [107.3506949, 15.5718541],
                [107.3518722, 15.5705496],
                [107.3522883, 15.5696932],
                [107.3525963, 15.5693325],
                [107.3527652, 15.5691934],
                [107.3529628, 15.5691086],
                [107.353246, 15.5690507],
                [107.353444, 15.5689936],
                [107.3536994, 15.5689908],
                [107.3544085, 15.5689284],
                [107.3554641, 15.5694118],
                [107.3568307, 15.5697819],
                [107.3579436, 15.5702922],
                [107.3586874, 15.5708065],
                [107.3591716, 15.5712798],
                [107.3597494, 15.5718397],
                [107.3600915, 15.5719733],
                [107.3602054, 15.5719996],
                [107.3604617, 15.5720519],
                [107.3607737, 15.5720485],
                [107.3608588, 15.5720476],
                [107.3610859, 15.5720452],
                [107.3612543, 15.5718784],
                [107.3615075, 15.5716832],
                [107.3616476, 15.5715168],
                [107.3618721, 15.5712946],
                [107.3619, 15.5712392],
                [107.3620402, 15.5711004],
                [107.3621808, 15.570989],
                [107.3624918, 15.5708756],
                [107.3627741, 15.5707351],
                [107.3629442, 15.5707332],
                [107.3632562, 15.5707024],
                [107.3635397, 15.5706719],
                [107.3637664, 15.570642],
                [107.3639644, 15.5705849],
                [107.3642189, 15.5704998],
                [107.3643879, 15.570388],
                [107.3645013, 15.5703593],
                [107.3646411, 15.5701929],
                [107.365148, 15.5698301],
                [107.3652246, 15.5690872],
                [107.3652098, 15.5677954],
                [107.3651447, 15.567054],
                [107.3651081, 15.5663397],
                [107.3651004, 15.56568],
                [107.3652056, 15.5649369],
                [107.3655044, 15.5637791],
                [107.3658092, 15.5631162],
                [107.36628, 15.5620941],
                [107.3664984, 15.5613497],
                [107.3667199, 15.5608526],
                [107.3667411, 15.5602201],
                [107.3665357, 15.5596451],
                [107.3658761, 15.5590475],
                [107.3650755, 15.558534],
                [107.3644455, 15.5580458],
                [107.3642141, 15.5576636],
                [107.3641791, 15.5570867],
                [107.3643122, 15.5563156],
                [107.3646163, 15.5555978],
                [107.3650659, 15.5552081],
                [107.3653995, 15.5545997],
                [107.3654764, 15.5538842],
                [107.3655242, 15.5530866],
                [107.3654586, 15.5523179],
                [107.3652231, 15.5515782],
                [107.365188, 15.5510013],
                [107.3654085, 15.5504218],
                [107.3658585, 15.5500596],
                [107.3663923, 15.5495865],
                [107.3665315, 15.5493377],
                [107.3666438, 15.5492266],
                [107.3666415, 15.5490341],
                [107.3666403, 15.5489242],
                [107.3664683, 15.5487887],
                [107.3662682, 15.5486532],
                [107.3661244, 15.54849],
                [107.3658957, 15.5483551],
                [107.3656949, 15.5481647],
                [107.3654944, 15.548002],
                [107.3654074, 15.5478379],
                [107.3653204, 15.5476741],
                [107.3652902, 15.5475095],
                [107.3653156, 15.5472617],
                [107.3655956, 15.5469289],
                [107.3661825, 15.5461255],
                [107.367133, 15.5448509],
                [107.3677487, 15.5441021],
                [107.3681958, 15.5434927],
                [107.3684449, 15.5429402],
                [107.3685561, 15.5427192],
                [107.368695, 15.5424703],
                [107.3689475, 15.5422202],
                [107.3691444, 15.5420531],
                [107.3693398, 15.5417762],
                [107.3692506, 15.5414198],
                [107.3692434, 15.5407878],
                [107.3692637, 15.5400729],
                [107.3693718, 15.5396044],
                [107.3695933, 15.5391348],
                [107.3699843, 15.5385808],
                [107.3704325, 15.5380536],
                [107.370713, 15.5377758],
                [107.3709387, 15.5376635],
                [107.3711368, 15.5376064],
                [107.3714489, 15.5376031],
                [107.3718192, 15.537709],
                [107.3720472, 15.537789],
                [107.3723031, 15.5378413],
                [107.3725304, 15.5378663],
                [107.3727, 15.5378095],
                [107.3727543, 15.537589],
                [107.3727524, 15.5374241],
                [107.3724577, 15.5364652],
                [107.3724225, 15.5358884],
                [107.3724184, 15.5355311],
                [107.3724451, 15.535366],
                [107.3725558, 15.5351174],
                [107.3728034, 15.5344549],
                [107.3731933, 15.5337911],
                [107.3736965, 15.533126],
                [107.374956, 15.5316008],
                [107.3753445, 15.5308268],
                [107.375592, 15.530137],
                [107.3758107, 15.52942],
                [107.3758017, 15.528623],
                [107.3757355, 15.5277992],
                [107.3756443, 15.5272779],
                [107.3753808, 15.5265661],
                [107.3751752, 15.5259636],
                [107.3750583, 15.5256625],
                [107.3750277, 15.5254704],
                [107.3750256, 15.5252781],
                [107.3754742, 15.5248061],
                [107.3757812, 15.524363],
                [107.375915, 15.5236468],
                [107.3758505, 15.5229877],
                [107.375815, 15.522356],
                [107.3757807, 15.5218341],
                [107.3757788, 15.5216693],
                [107.3759442, 15.5212552],
                [107.3766149, 15.5203409],
                [107.3772024, 15.5196199],
                [107.3777911, 15.5189813],
                [107.3784657, 15.5184244],
                [107.3788566, 15.5178703],
                [107.3789642, 15.517347],
                [107.3788696, 15.5165234],
                [107.3786596, 15.5155361],
                [107.3784774, 15.5144936],
                [107.3780695, 15.5135634],
                [107.3778337, 15.5127964],
                [107.3778307, 15.5125217],
                [107.3780236, 15.5120248],
                [107.3784172, 15.5117182],
                [107.3789788, 15.51119],
                [107.379568, 15.5106063],
                [107.3799301, 15.5100252],
                [107.3802624, 15.5093071],
                [107.3803984, 15.5087832],
                [107.3804796, 15.5084527],
                [107.3804778, 15.5082876],
                [107.3804756, 15.5080952],
                [107.380473, 15.5078754],
                [107.3802963, 15.5073],
                [107.3800071, 15.5068359],
                [107.3798622, 15.5065626],
                [107.3798306, 15.5062882],
                [107.3798271, 15.5059857],
                [107.3800212, 15.5055988],
                [107.3802426, 15.5051017],
                [107.3804665, 15.5048245],
                [107.3805484, 15.5045487],
                [107.3805456, 15.5043013],
                [107.3805433, 15.5041091],
                [107.3803407, 15.5037538],
                [107.3797045, 15.5027162],
                [107.3793014, 15.5021984],
                [107.3790423, 15.5018712],
                [107.3789256, 15.5015977],
                [107.3789221, 15.5012955],
                [107.3793714, 15.5008782],
                [107.3798489, 15.5004609],
                [107.3804107, 15.49996],
                [107.3806906, 15.4996927],
                [107.3808374, 15.4992297],
                [107.3809684, 15.4988074],
                [107.381237, 15.4985417],
                [107.3817486, 15.4981415],
                [107.3819085, 15.4978768],
                [107.381931, 15.4974559],
                [107.3819228, 15.4967458],
                [107.3819094, 15.4955889],
                [107.3818992, 15.4946947],
                [107.3819466, 15.4940893],
                [107.3822391, 15.493534],
                [107.3829001, 15.4931369],
                [107.3841174, 15.4927026],
                [107.3850666, 15.4925608],
                [107.3857727, 15.4925532],
                [107.3861833, 15.4928119],
                [107.3865986, 15.4934917],
                [107.386962, 15.4943827],
                [107.3877559, 15.4949267],
                [107.3888188, 15.4952311],
                [107.390367, 15.4952145],
                [107.3908831, 15.4952088],
                [107.3921757, 15.4954053],
                [107.3925267, 15.4952173],
                [107.392602, 15.4946901],
                [107.3927047, 15.4941626],
                [107.3930279, 15.4939222],
                [107.3933813, 15.4939449],
                [107.3937383, 15.4942831],
                [107.394176, 15.4945415],
                [107.3946114, 15.4946157],
                [107.394936, 15.4944805],
                [107.3953112, 15.4940554],
                [107.3955503, 15.4935791],
                [107.3958159, 15.4930498],
                [107.3963008, 15.4927025],
                [107.3971412, 15.4925617],
                [107.3979561, 15.4925528],
                [107.3987149, 15.492413],
                [107.3993385, 15.4923009],
                [107.3998688, 15.4923478],
                [107.4003055, 15.4925273],
                [107.4006597, 15.4926287],
                [107.4011192, 15.4924396],
                [107.4014975, 15.4922512],
                [107.4019318, 15.4922202],
                [107.4028315, 15.4924999],
                [107.4037591, 15.4928582],
                [107.4047674, 15.4931368],
                [107.406208, 15.4932001],
                [107.407077, 15.4931906],
                [107.407866, 15.4932871],
                [107.4082326, 15.4932831],
                [107.4086915, 15.4930415],
                [107.4089858, 15.4926434],
                [107.4091701, 15.4921412],
                [107.4093825, 15.4917179],
                [107.4097061, 15.4915038],
                [107.4100861, 15.4914996],
                [107.4105776, 15.4917048],
                [107.4109347, 15.4920432],
                [107.4114839, 15.4925635],
                [107.4122248, 15.4932135],
                [107.4129915, 15.4937314],
                [107.4136758, 15.4941713],
                [107.4140328, 15.4945096],
                [107.4143871, 15.494611],
                [107.4148476, 15.4945007],
                [107.4152788, 15.4942063],
                [107.4157088, 15.493807],
                [107.4159203, 15.4933046],
                [107.4159678, 15.492725],
                [107.4157441, 15.4921748],
                [107.4156306, 15.491755],
                [107.4156246, 15.4912287],
                [107.4157557, 15.4908324],
                [107.4161588, 15.4904595],
                [107.4170107, 15.4901344],
                [107.4176091, 15.4902067],
                [107.4189702, 15.4904551],
                [107.4195708, 15.4907118],
                [107.4200648, 15.4911538],
                [107.4204225, 15.4915445],
                [107.4208089, 15.4920668],
                [107.4212217, 15.4925097],
                [107.4216599, 15.4928207],
                [107.4222323, 15.4929986],
                [107.4228298, 15.4929921],
                [107.4235608, 15.4927735],
                [107.4242345, 15.4923188],
                [107.4249049, 15.4915744],
                [107.4257786, 15.4907752],
                [107.4267216, 15.4901332],
                [107.427751, 15.4898851],
                [107.4285648, 15.4897972],
                [107.4297052, 15.4897583],
                [107.430304, 15.489857],
                [107.430742, 15.4901416],
                [107.4311811, 15.4905316],
                [107.4314849, 15.4909493],
                [107.4316817, 15.4915263],
                [107.4316891, 15.4921578],
                [107.431535, 15.4929228],
                [107.4311195, 15.4945591],
                [107.4311282, 15.495296],
                [107.4311628, 15.4959271],
                [107.4312239, 15.4965056],
                [107.4311198, 15.4969014],
                [107.430489, 15.49754],
                [107.4300309, 15.4978969],
                [107.4298644, 15.4981657],
                [107.4297489, 15.4989072],
                [107.4297589, 15.4991496],
                [107.4298893, 15.499629],
                [107.4311956, 15.5012307],
                [107.4318393, 15.5022248],
                [107.432709, 15.5028828],
                [107.4339226, 15.5034813],
                [107.4349587, 15.5036368],
                [107.4363918, 15.5033428],
                [107.4367926, 15.5032272],
                [107.4371617, 15.5032117],
                [107.4377118, 15.5032171],
                [107.4386312, 15.503207],
                [107.4396099, 15.503363],
                [107.4404717, 15.5033535],
                [107.4411597, 15.5032347],
                [107.4422493, 15.5030556],
                [107.4433932, 15.502598],
                [107.4442532, 15.50243],
                [107.4453113, 15.502591],
                [107.4473067, 15.5031198],
                [107.4488044, 15.5034371],
                [107.4503006, 15.5036431],
                [107.4513923, 15.5036309],
                [107.4522515, 15.5033989],
                [107.4531119, 15.503278],
                [107.454203, 15.5032102],
                [107.4550052, 15.5030344],
                [107.4555177, 15.5026389],
                [107.4559688, 15.5019105],
                [107.456768, 15.5014562],
                [107.4583699, 15.5008818],
                [107.4587668, 15.5004321],
                [107.4588223, 15.5002645],
                [107.4592263, 15.499788],
                [107.4594399, 15.4993251],
                [107.459763, 15.4990845],
                [107.4602759, 15.4988158],
                [107.4608728, 15.4987564],
                [107.461717, 15.4989313],
                [107.4623442, 15.4991349],
                [107.4631318, 15.4991259],
                [107.464027, 15.4990107],
                [107.4647569, 15.4987131],
                [107.4651879, 15.4984188],
                [107.4656721, 15.4980186],
                [107.4662122, 15.4977493],
                [107.466756, 15.4977959],
                [107.4673577, 15.4981315],
                [107.4678774, 15.4984413],
                [107.4683678, 15.4985675],
                [107.4689653, 15.4985608],
                [107.4693966, 15.4982664],
                [107.4699335, 15.497734],
                [107.4702805, 15.4972302],
                [107.471032, 15.4964586],
                [107.4718138, 15.4959498],
                [107.4726515, 15.4955983],
                [107.4733562, 15.4954588],
                [107.4740304, 15.4950564],
                [107.4749603, 15.4944407],
                [107.4755527, 15.4940129],
                [107.476226, 15.4935317],
                [107.4765755, 15.4932119],
                [107.4768157, 15.4928671],
                [107.4769457, 15.4923656],
                [107.4769683, 15.491997],
                [107.477208, 15.4915995],
                [107.477612, 15.4913056],
                [107.47829, 15.4912189],
                [107.4794875, 15.4914159],
                [107.4801649, 15.4912768],
                [107.4805687, 15.4909565],
                [107.4809161, 15.490479],
                [107.4811781, 15.4896864],
                [107.4814281, 15.4889992],
                [107.4819099, 15.4884149],
                [107.4823663, 15.4879623],
                [107.482578, 15.4874863],
                [107.482654, 15.487038],
                [107.4826474, 15.4864855],
                [107.4823408, 15.485831],
                [107.4820072, 15.4851767],
                [107.4817255, 15.4843378],
                [107.4815122, 15.4835243],
                [107.4813422, 15.4829471],
                [107.4809573, 15.4825566],
                [107.4801946, 15.4823809],
                [107.4792969, 15.4822595],
                [107.478561, 15.4820572],
                [107.4780962, 15.4817991],
                [107.4776836, 15.4813564],
                [107.4774038, 15.4806755],
                [107.47726, 15.4799926],
                [107.4772759, 15.479045],
                [107.4775799, 15.4783572],
                [107.4780925, 15.4780622],
                [107.4792047, 15.4779444],
                [107.4799643, 15.4778569],
                [107.4805586, 15.477587],
                [107.4809615, 15.4772141],
                [107.4814201, 15.4769458],
                [107.4819354, 15.4768873],
                [107.4825356, 15.4771175],
                [107.482947, 15.4774549],
                [107.4835469, 15.4776589],
                [107.4843075, 15.4776504],
                [107.4849605, 15.4777482],
                [107.4857247, 15.4780554],
                [107.4868541, 15.4782532],
                [107.4878619, 15.4784789],
                [107.4885439, 15.4787342],
                [107.48925, 15.4787263],
                [107.489929, 15.4787187],
                [107.490636, 15.4787897],
                [107.4912893, 15.478914],
                [107.4918609, 15.4790128],
                [107.492729, 15.4789241],
                [107.4935125, 15.4785731],
                [107.494272, 15.4784856],
                [107.4952333, 15.4782641],
                [107.4961757, 15.4775693],
                [107.496819, 15.4768515],
                [107.4973253, 15.4760298],
                [107.4979166, 15.4755231],
                [107.4986188, 15.4751994],
                [107.4994042, 15.4750063],
                [107.5003742, 15.4747636],
                [107.5017801, 15.4748514],
                [107.5031108, 15.4748573],
                [107.504242, 15.4748653],
                [107.5056154, 15.4747038],
                [107.5056726, 15.4747006],
                [107.5074326, 15.4745998],
                [107.5085396, 15.4744412],
                [107.5096013, 15.4741997],
                [107.5101753, 15.4739847],
                [107.5105496, 15.4737511],
                [107.5108562, 15.4734347],
                [107.5110307, 15.4731825],
                [107.5112939, 15.4729501],
                [107.5116041, 15.4729257],
                [107.5120925, 15.4729618],
                [107.5125146, 15.4730195],
                [107.5128472, 15.4730158],
                [107.5132453, 15.4729279],
                [107.513608, 15.4726463],
                [107.5137829, 15.4724355],
                [107.5138231, 15.4721012],
                [107.5137518, 15.4717052],
                [107.513659, 15.4713723],
                [107.5136101, 15.4709972],
                [107.5136493, 15.4705584],
                [107.5137535, 15.4700142],
                [107.5139437, 15.4692399],
                [107.5142013, 15.4685271],
                [107.5144677, 15.4676369],
                [107.5146172, 15.4671551],
                [107.5146555, 15.4666536],
                [107.5146494, 15.4661526],
                [107.5147336, 15.4657759],
                [107.5149062, 15.4653773],
                [107.5151461, 15.4650406],
                [107.515638, 15.4644609],
                [107.5159921, 15.4643943],
                [107.5165689, 15.4644086],
                [107.5168813, 15.464572],
                [107.5171256, 15.4645902],
                [107.5173683, 15.4644829],
                [107.5174755, 15.4641895],
                [107.5174921, 15.46373],
                [107.517377, 15.4633764],
                [107.5171064, 15.463004],
                [107.5165676, 15.462467],
                [107.5158946, 15.4618277],
                [107.5153546, 15.4611867],
                [107.5151497, 15.4607506],
                [107.515123, 15.4603752],
                [107.5153073, 15.4600287],
                [107.5155711, 15.4598377],
                [107.5159471, 15.4597499],
                [107.5163683, 15.4597243],
                [107.5172119, 15.4597981],
                [107.5179647, 15.4597061],
                [107.5190471, 15.4593597],
                [107.5200162, 15.4588061],
                [107.5208505, 15.4581077],
                [107.5215295, 15.457411],
                [107.5220731, 15.4565072],
                [107.5224425, 15.4558977],
                [107.5227107, 15.4551326],
                [107.5227471, 15.4544851],
                [107.5227625, 15.4539213],
                [107.5226673, 15.4533796],
                [107.5224844, 15.4529432],
                [107.5222141, 15.4525915],
                [107.5220105, 15.4522597],
                [107.5219511, 15.4519369],
                [107.5220144, 15.4516648],
                [107.5221658, 15.4513498],
                [107.5224282, 15.4510337],
                [107.522802, 15.4507791],
                [107.5231104, 15.4506085],
                [107.5234183, 15.4503963],
                [107.5234817, 15.450145],
                [107.5233888, 15.4497912],
                [107.523072, 15.449273],
                [107.5227792, 15.4489005],
                [107.522419, 15.4484454],
                [107.5221119, 15.4478122],
                [107.5219682, 15.446937],
                [107.5219614, 15.4463733],
                [107.5220448, 15.445934],
                [107.5222408, 15.4456398],
                [107.522614, 15.4453223],
                [107.52336, 15.4446665],
                [107.5239536, 15.4442423],
                [107.5246132, 15.4437755],
                [107.5255047, 15.4432331],
                [107.5261004, 15.4429757],
                [107.5263863, 15.4427847],
                [107.5265608, 15.4425321],
                [107.526645, 15.4421764],
                [107.5266392, 15.4416963],
                [107.5265432, 15.441092],
                [107.5262692, 15.4404271],
                [107.5260859, 15.4399491],
                [107.5260365, 15.4395321],
                [107.5263701, 15.4387037],
                [107.52652, 15.4382636],
                [107.5265581, 15.4377414],
                [107.5264854, 15.4372412],
                [107.526214, 15.4368059],
                [107.5258556, 15.4364968],
                [107.5252068, 15.436024],
                [107.524377, 15.4352403],
                [107.5235281, 15.43382],
                [107.522982, 15.4326571],
                [107.5228639, 15.432074],
                [107.5228158, 15.4315606],
                [107.5227878, 15.4312607],
                [107.5226063, 15.4300205],
                [107.5223715, 15.4289377],
                [107.5222458, 15.4277075],
                [107.5219932, 15.4269796],
                [107.5213959, 15.426183],
                [107.5204806, 15.4256713],
                [107.5194757, 15.4250774],
                [107.5189602, 15.4246239],
                [107.5187126, 15.4243137],
                [107.5186408, 15.4238761],
                [107.5186364, 15.4235213],
                [107.5188488, 15.4227465],
                [107.5190495, 15.4219197],
                [107.5190878, 15.4214181],
                [107.5189711, 15.4209393],
                [107.5187162, 15.4200237],
                [107.5180411, 15.4191963],
                [107.5176571, 15.4186162],
                [107.517507, 15.4181066],
                [107.5175029, 15.4177725],
                [107.5175643, 15.4173544],
                [107.5179105, 15.4166405],
                [107.5184764, 15.4157574],
                [107.5190011, 15.4151461],
                [107.519482, 15.414577],
                [107.5203228, 15.4135131],
                [107.5210653, 15.4125653],
                [107.5218322, 15.411826],
                [107.522448, 15.4114015],
                [107.5230435, 15.4111442],
                [107.5238743, 15.4110826],
                [107.5245395, 15.4110959],
                [107.5249378, 15.4110289],
                [107.5252462, 15.4108582],
                [107.5254656, 15.4106679],
                [107.5258809, 15.4101831],
                [107.5262976, 15.4098025],
                [107.5270013, 15.4093353],
                [107.5278366, 15.4087203],
                [107.5286937, 15.4081052],
                [107.5291537, 15.4076407],
                [107.5295034, 15.4072192],
                [107.5297643, 15.4067988],
                [107.5299578, 15.4062955],
                [107.5301401, 15.4058028],
                [107.5302926, 15.4055714],
                [107.5307125, 15.4054623],
                [107.5311783, 15.4054781],
                [107.5317127, 15.4056597],
                [107.5321594, 15.4059261],
                [107.5325615, 15.4061928],
                [107.5330961, 15.4063955],
                [107.5333841, 15.4063713],
                [107.5337364, 15.4061794],
                [107.5341092, 15.4058411],
                [107.5343045, 15.4054841],
                [107.5345428, 15.4050221],
                [107.5348484, 15.4046219],
                [107.5351987, 15.404263],
                [107.5356615, 15.4040282],
                [107.5361042, 15.4039813],
                [107.5364167, 15.4041446],
                [107.5367316, 15.4045169],
                [107.536891, 15.40487],
                [107.5372286, 15.4052836],
                [107.5375765, 15.4056241],
                [107.5377769, 15.4057053],
                [107.5383327, 15.4058242],
                [107.5387103, 15.4058825],
                [107.539156, 15.4060863],
                [107.5396472, 15.406352],
                [107.5400497, 15.4066395],
                [107.5404062, 15.4067817],
                [107.5407829, 15.4067773],
                [107.5411578, 15.4066061],
                [107.5415751, 15.4062882],
                [107.5419482, 15.4059707],
                [107.5424306, 15.4055268],
                [107.5428706, 15.4052504],
                [107.5434016, 15.4051608],
                [107.5437568, 15.4051984],
                [107.5441124, 15.405278],
                [107.5445349, 15.4053775],
                [107.5449886, 15.4052993],
                [107.5453396, 15.4050029],
                [107.5459089, 15.404412],
                [107.5463007, 15.4038229],
                [107.5466722, 15.4033802],
                [107.5471775, 15.4029987],
                [107.5477287, 15.4027419],
                [107.5483895, 15.4024003],
                [107.5488505, 15.4020192],
                [107.5493094, 15.4014713],
                [107.5497904, 15.400923],
                [107.5507181, 15.3997329],
                [107.5513548, 15.3992038],
                [107.551838, 15.3988433],
                [107.5523214, 15.3984829],
                [107.553025, 15.3979946],
                [107.5534194, 15.3976352],
                [107.5537912, 15.3972135],
                [107.5540101, 15.3969812],
                [107.5544389, 15.3966947],
                [107.5547488, 15.3966494],
                [107.5552591, 15.3966852],
                [107.5557693, 15.396721],
                [107.5563229, 15.3966522],
                [107.5570712, 15.3962051],
                [107.5574879, 15.3958454],
                [107.5580137, 15.3953382],
                [107.5584752, 15.394999],
                [107.5589369, 15.3946805],
                [107.559488, 15.3944237],
                [107.5600399, 15.3942504],
                [107.560505, 15.3942033],
                [107.5613347, 15.3940581],
                [107.562044, 15.3940498],
                [107.562732, 15.3941255],
                [107.5634434, 15.3942842],
                [107.5646004, 15.3946257],
                [107.5655135, 15.39497],
                [107.5669816, 15.3953706],
                [107.5678718, 15.3956527],
                [107.5686968, 15.3960188],
                [107.5692011, 15.3964826],
                [107.5698277, 15.3969555],
                [107.5704749, 15.3973028],
                [107.5710097, 15.3975263],
                [107.5716538, 15.3976233],
                [107.5722969, 15.3976368],
                [107.5728288, 15.3976306],
                [107.5734724, 15.3976858],
                [107.5740066, 15.3978466],
                [107.5744304, 15.3980713],
                [107.5747234, 15.3984645],
                [107.5750178, 15.398962],
                [107.57516, 15.399712],
                [107.5755596, 15.4006361],
                [107.5763021, 15.4015252],
                [107.5773128, 15.402599],
                [107.5780737, 15.4031747],
                [107.5784998, 15.4035662],
                [107.5791725, 15.4041848],
                [107.5808223, 15.4058251],
                [107.5820553, 15.406938],
                [107.5830411, 15.4077616],
                [107.5836458, 15.4082556],
                [107.5874645, 15.4113944],
                [107.5883846, 15.4122813],
                [107.5898666, 15.4129006],
                [107.5911358, 15.413345],
                [107.591758, 15.4134631],
                [107.592534, 15.413454],
                [107.5931539, 15.4133843],
                [107.5937282, 15.4132105],
                [107.5943267, 15.4128902],
                [107.5944386, 15.4128548],
                [107.5945532, 15.4128283],
                [107.5946696, 15.4128109],
                [107.5947871, 15.4128026],
                [107.5949049, 15.4128036],
                [107.5951143, 15.4128284],
                [107.5952171, 15.4128517],
                [107.595416, 15.4129197],
                [107.5955111, 15.4129639],
                [107.5974469, 15.4140983],
                [107.5977347, 15.4142387],
                [107.5980344, 15.4143538],
                [107.5983437, 15.4144428],
                [107.5985013, 15.4144773],
                [107.5988204, 15.4145256],
                [107.5991428, 15.4145464],
                [107.5992744, 15.414562],
                [107.5994047, 15.4145864],
                [107.5996582, 15.4146614],
                [107.5997802, 15.4147116],
                [107.6000118, 15.414836],
                [107.6001413, 15.4149285],
                [107.6003854, 15.4151314],
                [107.6004994, 15.4152412],
                [107.600801, 15.4155729],
                [107.6009652, 15.4157802],
                [107.6010376, 15.4158904],
                [107.601162, 15.416122],
                [107.6012623, 15.4163807],
                [107.6013016, 15.4165215],
                [107.6013315, 15.4166644],
                [107.6013515, 15.416809],
                [107.6013618, 15.4169545],
                [107.6013623, 15.4171003],
                [107.6013248, 15.4175389],
                [107.6013205, 15.4176861],
                [107.6013315, 15.4179802],
                [107.601349, 15.4181442],
                [107.6014083, 15.418469],
                [107.6014499, 15.4186289],
                [107.6015568, 15.4189421],
                [107.6025116, 15.4206176],
                [107.6026518, 15.4207911],
                [107.6028169, 15.4209429],
                [107.6029077, 15.4210097],
                [107.6031033, 15.4211229],
                [107.603207, 15.4211689],
                [107.6034236, 15.4212381],
                [107.6035446, 15.4212626],
                [107.6036673, 15.4212778],
                [107.6037909, 15.4212834],
                [107.6039145, 15.4212795],
                [107.6040375, 15.4212662],
                [107.6041589, 15.4212435],
                [107.6042781, 15.4212115],
                [107.6043942, 15.4211704],
                [107.6045066, 15.4211206],
                [107.6046145, 15.4210623],
                [107.6047897, 15.4209875],
                [107.6051482, 15.4208569],
                [107.6055157, 15.420752],
                [107.6057076, 15.4207084],
                [107.6060958, 15.4206423],
                [107.6064882, 15.4206048],
                [107.6066852, 15.4205969],
                [107.6089167, 15.4205659],
                [107.6093459, 15.4200488],
                [107.6097107, 15.4189318],
                [107.6108689, 15.4174014],
                [107.6114699, 15.4173807],
                [107.6121147, 15.417388],
                [107.6128015, 15.4174294],
                [107.6131439, 15.4174631],
                [107.6136312, 15.4175243],
                [107.6138761, 15.417545],
                [107.614367, 15.4175665],
                [107.6148572, 15.4175615],
                [107.615102, 15.4175491],
                [107.6155901, 15.4175044],
                [107.619313, 15.4174527],
                [107.619407, 15.4175227],
                [107.6195066, 15.4175852],
                [107.619611, 15.4176398],
                [107.6197197, 15.4176861],
                [107.6198318, 15.4177239],
                [107.6199468, 15.4177529],
                [107.6200638, 15.4177728],
                [107.6202919, 15.4177856],
                [107.6205107, 15.4177657],
                [107.6207239, 15.4177148],
                [107.620927, 15.417634],
                [107.6210233, 15.417583],
                [107.6211154, 15.4175251],
                [107.6212273, 15.4174313],
                [107.6213455, 15.4173449],
                [107.6214695, 15.4172666],
                [107.6216812, 15.4171574],
                [107.6219155, 15.4170517],
                [107.6221519, 15.4169118],
                [107.6222634, 15.4168322],
                [107.6239049, 15.4154049],
                [107.624318, 15.4147119],
                [107.627601, 15.4147377],
                [107.6296502, 15.4154979],
                [107.6306748, 15.4171528],
                [107.6307541, 15.4172323],
                [107.63084, 15.4173051],
                [107.6309319, 15.4173708],
                [107.6310292, 15.4174288],
                [107.6311312, 15.4174787],
                [107.6312372, 15.4175202],
                [107.6313464, 15.417553],
                [107.631568, 15.4175913],
                [107.6317897, 15.4175941],
                [107.6320089, 15.4175623],
                [107.6322199, 15.4174967],
                [107.6323206, 15.4174517],
                [107.6325089, 15.4173388],
                [107.6325953, 15.4172717],
                [107.6330915, 15.4168296],
                [107.6332171, 15.4167399],
                [107.633481, 15.4165786],
                [107.6336188, 15.4165074],
                [107.6339042, 15.4163848],
                [107.6340574, 15.4163317],
                [107.6343711, 15.4162483],
                [107.6345308, 15.4162182],
                [107.634854, 15.4161814],
                [107.6351794, 15.4161764],
                [107.6363021, 15.4162607],
                [107.6365968, 15.4162406],
                [107.6367427, 15.4162176],
                [107.6370287, 15.4161463],
                [107.6373036, 15.416042],
                [107.6375633, 15.4159063],
                [107.6376863, 15.4158273],
                [107.6379133, 15.4156509],
                [107.6381142, 15.4154523],
                [107.6382052, 15.4153448],
                [107.6383666, 15.4151152],
                [107.6384986, 15.4148687],
                [107.6385995, 15.414609],
                [107.6386678, 15.4143395],
                [107.6389009, 15.4124409],
                [107.638962, 15.4122063],
                [107.6390042, 15.4120924],
                [107.6391081, 15.4118792],
                [107.6392352, 15.4116855],
                [107.639386, 15.4115084],
                [107.6399848, 15.410947],
                [107.6401107, 15.410796],
                [107.6401649, 15.4107143],
                [107.6402128, 15.410629],
                [107.6402879, 15.4104522],
                [107.6403154, 15.4103604],
                [107.6403597, 15.4101424],
                [107.6403927, 15.4100199],
                [107.640435, 15.4099],
                [107.6404864, 15.4097834],
                [107.6406067, 15.409575],
                [107.6407559, 15.4093846],
                [107.6408404, 15.4092973],
                [107.6423639, 15.4081906],
                [107.6422361, 15.4079839],
                [107.6421382, 15.4077625],
                [107.6420732, 15.407536],
                [107.64204, 15.4073087],
                [107.6420348, 15.4071941],
                [107.6420373, 15.4070793],
                [107.6420664, 15.4068234],
                [107.6421428, 15.4064744],
                [107.6422022, 15.4063215],
                [107.6423001, 15.4061637],
                [107.6424274, 15.4060269],
                [107.6425006, 15.4059679],
                [107.6426629, 15.4058714],
                [107.6428413, 15.4058065],
                [107.6442557, 15.4052118],
                [107.6444729, 15.4051124],
                [107.644897, 15.4048939],
                [107.6451035, 15.4047751],
                [107.6455042, 15.4045189],
                [107.645893, 15.4042342],
                [107.6462621, 15.403926],
                [107.6476908, 15.4025793],
                [107.649622, 15.4012967],
                [107.6511347, 15.3996935],
                [107.6513329, 15.3994691],
                [107.6515553, 15.3992669],
                [107.6517994, 15.3990895],
                [107.6519287, 15.3990108],
                [107.6520758, 15.3989322],
                [107.6522276, 15.3988623],
                [107.6523834, 15.3988014],
                [107.6525429, 15.3987497],
                [107.6527052, 15.3987073],
                [107.6528699, 15.3986745],
                [107.6532746, 15.398622],
                [107.6533916, 15.3985955],
                [107.6536184, 15.3985195],
                [107.6537372, 15.3984653],
                [107.6538515, 15.3984027],
                [107.6539604, 15.398332],
                [107.6540636, 15.3982535],
                [107.6541603, 15.3981678],
                [107.655217, 15.3970455],
                [107.6553366, 15.3967819],
                [107.6554229, 15.3965065],
                [107.6554745, 15.3962231],
                [107.6554872, 15.3960786],
                [107.6554855, 15.3957887],
                [107.6554711, 15.3956444],
                [107.6554155, 15.3953594],
                [107.6553752, 15.3949964],
                [107.6553714, 15.3946314],
                [107.655404, 15.3942704],
                [107.6554718, 15.3939166],
                [107.6555743, 15.3935707],
                [107.6557106, 15.3932358],
                [107.6559089, 15.3928506],
                [107.6561651, 15.3924156],
                [107.6563033, 15.3922039],
                [107.6565992, 15.3917931],
                [107.6572811, 15.3909429],
                [107.6575825, 15.3906102],
                [107.6577431, 15.3904526],
                [107.6580826, 15.3901565],
                [107.6584446, 15.389887],
                [107.6588274, 15.3896455],
                [107.6590258, 15.3895358],
                [107.6599258, 15.3889648],
                [107.6600171, 15.3888885],
                [107.6601019, 15.3888055],
                [107.6601797, 15.3887163],
                [107.6602498, 15.3886214],
                [107.660312, 15.3885215],
                [107.6603658, 15.3884171],
                [107.6604419, 15.3882148],
                [107.6604683, 15.3881102],
                [107.6604969, 15.3878967],
                [107.6604983, 15.3871023],
                [107.6605218, 15.3869016],
                [107.6605761, 15.3867066],
                [107.6606667, 15.3865096],
                [107.6607239, 15.3864169],
                [107.6612949, 15.3857032],
                [107.6614022, 15.3855739],
                [107.6614944, 15.3854515],
                [107.6615786, 15.3853237],
                [107.6616543, 15.3851912],
                [107.6617683, 15.3849431],
                [107.6618528, 15.3846843],
                [107.6623464, 15.3826982],
                [107.6623595, 15.3824776],
                [107.6623471, 15.3822414],
                [107.6623041, 15.3819927],
                [107.6622713, 15.3818706],
                [107.6617134, 15.3801224],
                [107.6615797, 15.3796685],
                [107.6614558, 15.3791649],
                [107.6613986, 15.3788888],
                [107.6613057, 15.3783328],
                [107.6605761, 15.37705],
                [107.660365, 15.3766331],
                [107.6601835, 15.3762035],
                [107.660104, 15.3759845],
                [107.6599766, 15.3755701],
                [107.6598763, 15.3751489],
                [107.6598036, 15.3747224],
                [107.6596641, 15.3735431],
                [107.6596459, 15.3732288],
                [107.6596567, 15.3729142],
                [107.6596963, 15.3726017],
                [107.6597308, 15.3724294],
                [107.6598262, 15.3720908],
                [107.6599557, 15.3717629],
                [107.6601182, 15.371449],
                [107.6602113, 15.3712982],
                [107.6620812, 15.3690719],
                [107.6623871, 15.3686785],
                [107.6625287, 15.368474],
                [107.6627556, 15.3681082],
                [107.6629579, 15.3677292],
                [107.6634192, 15.3667257],
                [107.6639664, 15.3656291],
                [107.6640032, 15.3655369],
                [107.6640308, 15.3654419],
                [107.664049, 15.3653447],
                [107.6640576, 15.3652463],
                [107.6640467, 15.3650546],
                [107.6640277, 15.3649602],
                [107.6639631, 15.3647786],
                [107.6636921, 15.3643079],
                [107.6636551, 15.3642301],
                [107.6636016, 15.3640669],
                [107.6635848, 15.3639766],
                [107.6635763, 15.3637934],
                [107.6636016, 15.3636117],
                [107.6636982, 15.3631772],
                [107.6637626, 15.3627582],
                [107.6640576, 15.3624117],
                [107.6642507, 15.3602908],
                [107.6642538, 15.3600033],
                [107.6642293, 15.3597166],
                [107.6642034, 15.3595565],
                [107.6641258, 15.3592413],
                [107.6640744, 15.359087],
                [107.6640147, 15.3589355],
                [107.6636553, 15.3582941],
                [107.6634753, 15.3579391],
                [107.6633282, 15.3575686],
                [107.6632674, 15.3573782],
                [107.6631725, 15.3569906],
                [107.6629686, 15.3554283],
                [107.6629425, 15.3551027],
                [107.6629428, 15.3549394],
                [107.6629701, 15.3546138],
                [107.6629969, 15.3544525],
                [107.6630768, 15.3541351],
                [107.6631923, 15.353824],
                [107.6632636, 15.3536715],
                [107.6633433, 15.353523],
                [107.6634312, 15.3533788],
                [107.6635269, 15.3532393],
                [107.6636304, 15.3531051],
                [107.6637411, 15.3529764],
                [107.6640302, 15.3527876],
                [107.664299, 15.3525729],
                [107.664515, 15.3523659],
                [107.664616, 15.3522561],
                [107.6648033, 15.3520245],
                [107.6655221, 15.3510003],
                [107.6660693, 15.3499864],
                [107.6661333, 15.3498816],
                [107.6662784, 15.3496828],
                [107.6664448, 15.3495001],
                [107.6666393, 15.3493287],
                [107.6667437, 15.3492511],
                [107.6668525, 15.3491794],
                [107.6673958, 15.3488593],
                [107.6676732, 15.3487086],
                [107.6681959, 15.3484466],
                [107.66873, 15.3482068],
                [107.6687671, 15.3479859],
                [107.6687947, 15.3477637],
                [107.6688127, 15.3475407],
                [107.6688212, 15.347317],
                [107.6688101, 15.3468788],
                [107.6687907, 15.3466603],
                [107.6687405, 15.3462798],
                [107.6687449, 15.3461155],
                [107.668778, 15.345944],
                [107.668844, 15.3457706],
                [107.6688884, 15.3456887],
                [107.6689982, 15.3455375],
                [107.6695454, 15.3450822],
                [107.6701677, 15.3449064],
                [107.6709026, 15.3443373],
                [107.6712404, 15.3442847],
                [107.6715732, 15.344208],
                [107.6719312, 15.3440963],
                [107.6721064, 15.34403],
                [107.6724476, 15.3438769],
                [107.674174, 15.3430221],
                [107.6743213, 15.3429678],
                [107.6743984, 15.3429513],
                [107.6745558, 15.3429405],
                [107.6746439, 15.3429476],
                [107.6747307, 15.3429639],
                [107.674815, 15.3429893],
                [107.674896, 15.3430235],
                [107.6749726, 15.343066],
                [107.6750439, 15.3431164],
                [107.6752072, 15.3432132],
                [107.6753744, 15.3433033],
                [107.6757199, 15.343463],
                [107.6759124, 15.3435379],
                [107.6761083, 15.3436043],
                [107.6763071, 15.3436622],
                [107.6765084, 15.3437113],
                [107.6766572, 15.3437177],
                [107.6767312, 15.3437083],
                [107.6768737, 15.3436648],
                [107.6770022, 15.3435903],
                [107.6771092, 15.3434889],
                [107.6774955, 15.3427957],
                [107.6778388, 15.3418438],
                [107.6779648, 15.3417584],
                [107.6780851, 15.3416657],
                [107.6781993, 15.341566],
                [107.6783068, 15.3414597],
                [107.6784074, 15.3413472],
                [107.6785008, 15.3412286],
                [107.6785865, 15.3411047],
                [107.678664, 15.3409759],
                [107.6787332, 15.3408427],
                [107.6789814, 15.3403022],
                [107.6791504, 15.3401139],
                [107.6792919, 15.3399057],
                [107.6793983, 15.3396933],
                [107.6794726, 15.3394823],
                [107.6795203, 15.3392644],
                [107.679548, 15.3389855],
                [107.679552, 15.3386481],
                [107.679542, 15.3384796],
                [107.6794981, 15.3381448],
                [107.6794642, 15.3379792],
                [107.6794225, 15.3378153],
                [107.6793066, 15.3374405],
                [107.6792497, 15.3372249],
                [107.6792024, 15.3370072],
                [107.6791648, 15.3367878],
                [107.6791199, 15.3363604],
                [107.6791113, 15.3359461],
                [107.679137, 15.3355324],
                [107.6792979, 15.3346839],
                [107.6793928, 15.3344781],
                [107.679516, 15.3342867],
                [107.6796656, 15.3341132],
                [107.6797495, 15.3340341],
                [107.6799333, 15.3338937],
                [107.6800323, 15.333833],
                [107.6802421, 15.333732],
                [107.6811021, 15.3334298],
                [107.6811978, 15.3333903],
                [107.6813798, 15.3332928],
                [107.6815456, 15.3331729],
                [107.6816925, 15.3330329],
                [107.6818185, 15.3328751],
                [107.6830101, 15.3310418],
                [107.6831805, 15.3308645],
                [107.6832754, 15.3307854],
                [107.6834822, 15.3306486],
                [107.6836797, 15.3305537],
                [107.6837828, 15.3305157],
                [107.6839956, 15.3304596],
                [107.684186, 15.3304281],
                [107.6843438, 15.3303805],
                [107.6844907, 15.3303071],
                [107.6845683, 15.3302541],
                [107.6846399, 15.3301939],
                [107.684705, 15.330127],
                [107.6847627, 15.3300541],
                [107.6848125, 15.329976],
                [107.6855582, 15.3284757],
                [107.685593, 15.3284227],
                [107.6856765, 15.3283266],
                [107.6857245, 15.3282843],
                [107.6857914, 15.3282362],
                [107.6858635, 15.3281957],
                [107.6859399, 15.3281633],
                [107.686147, 15.3281084],
                [107.686265, 15.3280524],
                [107.6863682, 15.3279739],
                [107.6864196, 15.3279192],
                [107.6864637, 15.327859],
                [107.6864999, 15.327794],
                [107.6865275, 15.3277253],
                [107.6865463, 15.3276538],
                [107.6866004, 15.3271837],
                [107.6866367, 15.3270562],
                [107.6867016, 15.3269397],
                [107.6867437, 15.3268874],
                [107.686849, 15.3267952],
                [107.6869735, 15.3267289],
                [107.6871104, 15.3266921],
                [107.6871813, 15.3266854],
                [107.6873231, 15.326696],
                [107.6874875, 15.3266906],
                [107.6876482, 15.3266563],
                [107.6877254, 15.3266287],
                [107.6878705, 15.3265536],
                [107.687937, 15.3265067],
                [107.6880555, 15.3263964],
                [107.6881063, 15.3263338],
                [107.6881645, 15.3262946],
                [107.6882276, 15.3262631],
                [107.6882944, 15.3262398],
                [107.6883638, 15.3262252],
                [107.6884902, 15.3262213],
                [107.688614, 15.3262459],
                [107.6887286, 15.3262976],
                [107.6889217, 15.3264166],
                [107.6889997, 15.326444],
                [107.6890826, 15.3264476],
                [107.6891693, 15.3264243],
                [107.6892435, 15.3263752],
                [107.6901233, 15.3256302],
                [107.6902736, 15.3255315],
                [107.690585, 15.3253503],
                [107.690931, 15.3251817],
                [107.6913122, 15.3250316],
                [107.6915073, 15.324968],
                [107.6915805, 15.3249319],
                [107.6917157, 15.3248419],
                [107.6918325, 15.3247306],
                [107.6919321, 15.3245932],
                [107.6919735, 15.3245143],
                [107.6920315, 15.3243466],
                [107.6920475, 15.3242595],
                [107.6921403, 15.3215844],
                [107.6925588, 15.3208911],
                [107.6926663, 15.3206205],
                [107.6927119, 15.3204824],
                [107.6927897, 15.3201856],
                [107.6928441, 15.3198674],
                [107.6928699, 15.3195459],
                [107.693063, 15.3175592],
                [107.6949298, 15.3158207],
                [107.6952501, 15.3157069],
                [107.6954058, 15.3156395],
                [107.6957069, 15.3154844],
                [107.695992, 15.3153033],
                [107.6961402, 15.315194],
                [107.6962825, 15.3150776],
                [107.6964185, 15.3149544],
                [107.6965481, 15.3148249],
                [107.6966707, 15.3146892],
                [107.6967861, 15.3145478],
                [107.696894, 15.314401],
                [107.6969942, 15.3142491],
                [107.6970863, 15.3140926],
                [107.6972365, 15.3136684],
                [107.6972768, 15.3135989],
                [107.697376, 15.3134718],
                [107.6975102, 15.3133544],
                [107.6976657, 15.3132648],
                [107.6979317, 15.3132033],
                [107.6981953, 15.313133],
                [107.6984564, 15.3130541],
                [107.6987145, 15.3129667],
                [107.6989693, 15.3128708],
                [107.6992206, 15.3127667],
                [107.6994681, 15.3126543],
                [107.6997138, 15.3125327],
                [107.6999549, 15.3124029],
                [107.7001913, 15.3122653],
                [107.7004226, 15.3121199],
                [107.7006486, 15.3119669],
                [107.700869, 15.3118065],
                [107.7010836, 15.3116389],
                [107.701292, 15.3114643],
                [107.7019894, 15.310833],
                [107.7021924, 15.3107154],
                [107.7023864, 15.3105634],
                [107.7024789, 15.3104729],
                [107.7025639, 15.310376],
                [107.7026411, 15.310273],
                [107.7027099, 15.3101648],
                [107.7027699, 15.3100517],
                [107.7037704, 15.3089497],
                [107.7044141, 15.3083184],
                [107.7046136, 15.3080886],
                [107.7047038, 15.3079666],
                [107.7048558, 15.3077248],
                [107.7049827, 15.3074699],
                [107.7058155, 15.3054919],
                [107.7059374, 15.305272],
                [107.7060878, 15.3050691],
                [107.7062636, 15.3048874],
                [107.7063603, 15.3048051],
                [107.7065692, 15.3046596],
                [107.706893, 15.3044909],
                [107.7069861, 15.304434],
                [107.7071589, 15.304302],
                [107.7073109, 15.3041481],
                [107.7074443, 15.3039669],
                [107.7075002, 15.3038697],
                [107.7077466, 15.3033004],
                [107.7105296, 15.304562],
                [107.7108045, 15.3046575],
                [107.7110875, 15.3047276],
                [107.7113756, 15.3047718],
                [107.7116668, 15.3047897],
                [107.7123786, 15.3047874],
                [107.7125561, 15.3048107],
                [107.7127346, 15.3048591],
                [107.7128237, 15.3048935],
                [107.712993, 15.3049806],
                [107.7136149, 15.3054175],
                [107.7137885, 15.3055068],
                [107.7139735, 15.305571],
                [107.7141751, 15.3056099],
                [107.7143805, 15.305619],
                [107.7156902, 15.3055444],
                [107.7158668, 15.30556],
                [107.7160388, 15.305602],
                [107.7161334, 15.3056375],
                [107.7163108, 15.3057321],
                [107.7163923, 15.3057904],
                [107.7165602, 15.305937],
                [107.7166588, 15.3060113],
                [107.716763, 15.306078],
                [107.7168521, 15.3061267],
                [107.7170391, 15.3062073],
                [107.7172351, 15.3062643],
                [107.7177608, 15.3063885],
                [107.7182624, 15.3064713],
                [107.7190483, 15.3072008],
                [107.721312, 15.30777],
                [107.7247023, 15.3077079],
                [107.725144, 15.3078318],
                [107.725588, 15.3079282],
                [107.7271593, 15.3081787],
                [107.7274024, 15.3081857],
                [107.7278888, 15.3081787],
                [107.7283434, 15.3081469],
                [107.7287954, 15.3080908],
                [107.7295598, 15.3081348],
                [107.7299407, 15.3081761],
                [107.7302787, 15.3082279],
                [107.7305844, 15.3076096],
                [107.7306249, 15.3075088],
                [107.7306563, 15.3074051],
                [107.7306783, 15.3072991],
                [107.7306938, 15.3070894],
                [107.730673, 15.30688],
                [107.7305946, 15.3064999],
                [107.7305973, 15.3064008],
                [107.7306086, 15.3063523],
                [107.7306486, 15.3062637],
                [107.730677, 15.3062237],
                [107.7307103, 15.3061875],
                [107.7308506, 15.3060743],
                [107.730934, 15.3059746],
                [107.7309948, 15.3058607],
                [107.7310323, 15.3057268],
                [107.7310385, 15.3055882],
                [107.7310297, 15.3055192],
                [107.7309251, 15.3051933],
                [107.7308597, 15.3050856],
                [107.7307722, 15.3049938],
                [107.7306599, 15.3049188],
                [107.7305945, 15.3048896],
                [107.7304553, 15.3048546],
                [107.7303835, 15.3048494],
                [107.7300546, 15.3048782],
                [107.7300033, 15.3048772],
                [107.7299028, 15.3048576],
                [107.7297998, 15.3048089],
                [107.7297544, 15.3047749],
                [107.7295146, 15.3045573],
                [107.7294096, 15.3044741],
                [107.7292622, 15.3043676],
                [107.7291092, 15.3042688],
                [107.7289186, 15.3041648],
                [107.7288637, 15.3041231],
                [107.7288276, 15.3040653],
                [107.7288153, 15.3039895],
                [107.7288522, 15.3038523],
                [107.7288595, 15.3037877],
                [107.7288573, 15.3037228],
                [107.7288457, 15.3036588],
                [107.7288249, 15.303597],
                [107.7287533, 15.3035258],
                [107.7286662, 15.3034731],
                [107.7285673, 15.3034418],
                [107.7284621, 15.3034344],
                [107.7284096, 15.3034402],
                [107.7282941, 15.3034696],
                [107.7281619, 15.3034839],
                [107.7280335, 15.3034711],
                [107.7279144, 15.3034341],
                [107.7278057, 15.3033745],
                [107.7277552, 15.3033253],
                [107.7276729, 15.303212],
                [107.7276195, 15.3030868],
                [107.7275958, 15.3029562],
                [107.7276014, 15.3028238],
                [107.7276979, 15.3022578],
                [107.7278164, 15.3016907],
                [107.7278942, 15.3013747],
                [107.7280444, 15.3010384],
                [107.7281471, 15.3009085],
                [107.7282249, 15.3007634],
                [107.728275, 15.3006085],
                [107.7282893, 15.3005289],
                [107.7282953, 15.3003823],
                [107.7283042, 15.3003501],
                [107.7283394, 15.3002933],
                [107.7283673, 15.3002685],
                [107.7284355, 15.3002377],
                [107.7285108, 15.3002355],
                [107.728736, 15.3003211],
                [107.7288998, 15.3003514],
                [107.7290629, 15.3003522],
                [107.7292198, 15.3003256],
                [107.7293694, 15.3002726],
                [107.729476, 15.3002045],
                [107.7295947, 15.3001587],
                [107.7297279, 15.3001369],
                [107.7299097, 15.3001468],
                [107.7299565, 15.3001435],
                [107.7300458, 15.3001165],
                [107.7301225, 15.3000648],
                [107.7301794, 15.2999932],
                [107.7302122, 15.2999035],
                [107.730217, 15.299856],
                [107.7302028, 15.299762],
                [107.7301841, 15.2997178],
                [107.7301261, 15.299641],
                [107.7299041, 15.2994863],
                [107.7298189, 15.2994156],
                [107.7296644, 15.2992584],
                [107.7295881, 15.2991622],
                [107.7295196, 15.2990608],
                [107.7294591, 15.2989546],
                [107.7294072, 15.2988444],
                [107.729364, 15.2987306],
                [107.7291709, 15.2984202],
                [107.7291463, 15.2983338],
                [107.7291424, 15.2982891],
                [107.7291526, 15.2981954],
                [107.7291895, 15.2981031],
                [107.7292506, 15.2980237],
                [107.7293521, 15.2979261],
                [107.7294572, 15.2977805],
                [107.7294981, 15.2977009],
                [107.7295522, 15.2975439],
                [107.7295757, 15.2973799],
                [107.7295679, 15.2972146],
                [107.7295705, 15.2971067],
                [107.7295831, 15.2969995],
                [107.7296054, 15.2968937],
                [107.7296688, 15.2967119],
                [107.729761, 15.2965419],
                [107.7298079, 15.2964376],
                [107.7298473, 15.2963305],
                [107.7299012, 15.2961189],
                [107.7299254, 15.2959115],
                [107.729938, 15.295476],
                [107.7299232, 15.2953655],
                [107.7298813, 15.2952617],
                [107.7298508, 15.2952142],
                [107.7297702, 15.2951291],
                [107.7297205, 15.2950935],
                [107.7296089, 15.2950424],
                [107.7294308, 15.2950106],
                [107.729376, 15.2949939],
                [107.7292747, 15.2949416],
                [107.729189, 15.2948659],
                [107.7291248, 15.2947707],
                [107.7291026, 15.294718],
                [107.7289993, 15.2942755],
                [107.7289833, 15.2942311],
                [107.7289342, 15.2941501],
                [107.7289019, 15.2941149],
                [107.7288213, 15.2940563],
                [107.7287238, 15.2940197],
                [107.728672, 15.2940116],
                [107.7284253, 15.2940013],
                [107.7283929, 15.2939918],
                [107.7283644, 15.2939741],
                [107.7283281, 15.2939213],
                [107.7283253, 15.2938593],
                [107.7283366, 15.29383],
                [107.7284408, 15.2936936],
                [107.728495, 15.2935657],
                [107.7285147, 15.2934288],
                [107.7284995, 15.2932952],
                [107.7284534, 15.2931725],
                [107.7284194, 15.293116],
                [107.728332, 15.2930163],
                [107.7282228, 15.2929392],
                [107.7280599, 15.2928672],
                [107.7279961, 15.2928085],
                [107.7278115, 15.2923635],
                [107.7277923, 15.2922394],
                [107.7278041, 15.2921148],
                [107.7278463, 15.2919965],
                [107.7279125, 15.2918803],
                [107.7279405, 15.2918157],
                [107.7279757, 15.2916803],
                [107.7279823, 15.2915374],
                [107.7279577, 15.291393],
                [107.7279027, 15.2912566],
                [107.7278195, 15.2911342],
                [107.7277118, 15.2910311],
                [107.7272665, 15.2905137],
                [107.7272065, 15.290041],
                [107.7271217, 15.2895719],
                [107.7270153, 15.2891189],
                [107.7268857, 15.2886715],
                [107.7268801, 15.2886164],
                [107.7268857, 15.2885059],
                [107.726898, 15.2884466],
                [107.7269417, 15.2883335],
                [107.7269726, 15.2882809],
                [107.7270091, 15.2882317],
                [107.7277064, 15.2874607],
                [107.7281517, 15.2869846],
                [107.7282591, 15.2868277],
                [107.7283037, 15.286744],
                [107.7283735, 15.2865685],
                [107.7283984, 15.2864775],
                [107.7284185, 15.2863711],
                [107.7284294, 15.2862634],
                [107.728431, 15.2861552],
                [107.7284232, 15.2860472],
                [107.7284062, 15.2859403],
                [107.72838, 15.2858351],
                [107.7283448, 15.2857323],
                [107.728216, 15.2851114],
                [107.7281592, 15.2847911],
                [107.7280974, 15.284364],
                [107.7280979, 15.2841516],
                [107.7281099, 15.2840459],
                [107.728157, 15.2838384],
                [107.7281954, 15.2837286],
                [107.728298, 15.2835191],
                [107.7283615, 15.2834207],
                [107.7285112, 15.2832396],
                [107.7286881, 15.2830829],
                [107.7293748, 15.2825551],
                [107.7294947, 15.2824398],
                [107.7295926, 15.2823065],
                [107.7296322, 15.2822343],
                [107.7296697, 15.2821477],
                [107.7296982, 15.2820581],
                [107.7297174, 15.2819661],
                [107.7297272, 15.2818729],
                [107.7297274, 15.2817791],
                [107.7297181, 15.2816857],
                [107.7295357, 15.2808785],
                [107.7294069, 15.280599],
                [107.7293318, 15.279295],
                [107.7293003, 15.279062],
                [107.7292723, 15.2789477],
                [107.7291924, 15.2787258],
                [107.7291361, 15.2786103],
                [107.729071, 15.2784992],
                [107.7289977, 15.2783931],
                [107.7289164, 15.2782925],
                [107.7288276, 15.2781979],
                [107.7287157, 15.2781279],
                [107.7286676, 15.2780833],
                [107.7285908, 15.2779782],
                [107.7285438, 15.2778578],
                [107.7285297, 15.2777292],
                [107.72855, 15.2776007],
                [107.7285727, 15.2775394],
                [107.7286415, 15.2774276],
                [107.7287375, 15.2773364],
                [107.7289232, 15.27723],
                [107.7290472, 15.2771292],
                [107.7291495, 15.2770077],
                [107.7292308, 15.2768598],
                [107.7292798, 15.2766993],
                [107.7292916, 15.2766162],
                [107.7292889, 15.2761383],
                [107.7294606, 15.2756105],
                [107.7294737, 15.275489],
                [107.7295073, 15.2753671],
                [107.7295625, 15.2752483],
                [107.7296468, 15.2751408],
                [107.7296793, 15.2750811],
                [107.7297234, 15.2749533],
                [107.7297379, 15.2748143],
                [107.7297328, 15.2747445],
                [107.7297197, 15.2746756],
                [107.729657, 15.2745029],
                [107.7296489, 15.2744168],
                [107.7296537, 15.2743737],
                [107.7296882, 15.2742765],
                [107.7297168, 15.274233],
                [107.7297932, 15.2741615],
                [107.7298764, 15.274043],
                [107.7299327, 15.2739105],
                [107.7299514, 15.2738329],
                [107.7299609, 15.2737537],
                [107.7299609, 15.273674],
                [107.7299514, 15.2735949],
                [107.7298039, 15.272904],
                [107.7299062, 15.2727281],
                [107.7299863, 15.2725418],
                [107.7300437, 15.2723445],
                [107.7300631, 15.2722438],
                [107.7300829, 15.2720398],
                [107.7301258, 15.2712118],
                [107.7301426, 15.2710018],
                [107.7301226, 15.270792],
                [107.730099, 15.2706891],
                [107.7300654, 15.2705858],
                [107.7300228, 15.2704857],
                [107.7299713, 15.2703894],
                [107.7299114, 15.2702979],
                [107.7298435, 15.2702117],
                [107.7297682, 15.2701314],
                [107.7296859, 15.2700578],
                [107.7295845, 15.2699881],
                [107.7294068, 15.2698433],
                [107.7291552, 15.2695888],
                [107.7290469, 15.2694912],
                [107.7289322, 15.2694005],
                [107.7287046, 15.2692523],
                [107.7285842, 15.2691883],
                [107.7282572, 15.2690464],
                [107.7280507, 15.2689698],
                [107.7276286, 15.2688418],
                [107.7274137, 15.2687908],
                [107.7271968, 15.2687485],
                [107.7268508, 15.2687097],
                [107.7267584, 15.2686834],
                [107.7266765, 15.2686346],
                [107.726634, 15.2685951],
                [107.7265988, 15.2685495],
                [107.7265717, 15.268499],
                [107.7265534, 15.268445],
                [107.7265444, 15.2683888],
                [107.726545, 15.2683319],
                [107.7265883, 15.2681916],
                [107.72662, 15.268125],
                [107.726702, 15.2680018],
                [107.7268161, 15.2678874],
                [107.726887, 15.2678357],
                [107.7269634, 15.2677917],
                [107.7270442, 15.2677561],
                [107.7271287, 15.2677293],
                [107.7272156, 15.2677116],
                [107.7273041, 15.2677031],
                [107.728477, 15.2676433],
                [107.7286311, 15.2676043],
                [107.7287764, 15.2675345],
                [107.728906, 15.2674351],
                [107.728962, 15.267376],
                [107.7290529, 15.2672425],
                [107.7291537, 15.2670397],
                [107.7293362, 15.2666259],
                [107.7294178, 15.2664153],
                [107.7295686, 15.2659624],
                [107.7296362, 15.2657203],
                [107.729746, 15.26523],
                [107.7298218, 15.2647338],
                [107.7298468, 15.2644842],
                [107.7298414, 15.2640114],
                [107.7298307, 15.2637752],
                [107.7297771, 15.2631542],
                [107.7297892, 15.2631013],
                [107.7298345, 15.2630068],
                [107.7299022, 15.2629311],
                [107.7299434, 15.2629006],
                [107.7300726, 15.2628314],
                [107.7302144, 15.2627911],
                [107.7304624, 15.262774],
                [107.7305563, 15.2627385],
                [107.7305982, 15.2627111],
                [107.7306691, 15.2626373],
                [107.7307146, 15.2625432],
                [107.7307275, 15.26244],
                [107.7307212, 15.2623882],
                [107.730759, 15.2622811],
                [107.7308232, 15.2621864],
                [107.7309058, 15.2621126],
                [107.7309533, 15.2620833],
                [107.7310576, 15.2620423],
                [107.7311128, 15.2620311],
                [107.7321315, 15.2619115],
                [107.7322565, 15.2618633],
                [107.7323648, 15.2617861],
                [107.7324492, 15.261685],
                [107.7324808, 15.2616275],
                [107.7325198, 15.2615036],
                [107.7325251, 15.2613742],
                [107.7324964, 15.2612477],
                [107.7324355, 15.2611324],
                [107.7323464, 15.2610355],
                [107.7315205, 15.2603986],
                [107.7314925, 15.2603516],
                [107.7314872, 15.2603247],
                [107.7314965, 15.2602691],
                [107.7315115, 15.2602435],
                [107.731558, 15.2602062],
                [107.7315868, 15.2601966],
                [107.7317593, 15.2601836],
                [107.7318286, 15.2601671],
                [107.7319595, 15.2601124],
                [107.7320762, 15.2600298],
                [107.732172, 15.2599222],
                [107.7322097, 15.2598613],
                [107.7322623, 15.2597289],
                [107.7322765, 15.2596592],
                [107.7322863, 15.2594542],
                [107.7322648, 15.2593215],
                [107.7322447, 15.2592572],
                [107.7321772, 15.25912],
                [107.7321327, 15.2590572],
                [107.7319895, 15.2589097],
                [107.7319605, 15.2588679],
                [107.7319241, 15.258774],
                [107.7319175, 15.2587242],
                [107.731928, 15.2586258],
                [107.7319447, 15.258579],
                [107.7319686, 15.2585351],
                [107.7320709, 15.25841],
                [107.7321231, 15.2582996],
                [107.7321482, 15.2581807],
                [107.7321442, 15.2580552],
                [107.7321101, 15.2579339],
                [107.7318243, 15.2574568],
                [107.7318068, 15.2573796],
                [107.7318079, 15.2573399],
                [107.731832, 15.2572587],
                [107.7318562, 15.2572201],
                [107.7319242, 15.2571587],
                [107.7319657, 15.2571379],
                [107.732057, 15.2571198],
                [107.7321204, 15.2570977],
                [107.732236, 15.2570312],
                [107.7322864, 15.2569878],
                [107.7323657, 15.2568882],
                [107.7324161, 15.2567772],
                [107.732438, 15.2566578],
                [107.7324815, 15.2553401],
                [107.7324598, 15.2552509],
                [107.7324083, 15.2551723],
                [107.7323318, 15.2551138],
                [107.7322394, 15.2550833],
                [107.7321456, 15.2550703],
                [107.7320632, 15.2550254],
                [107.7320033, 15.2549547],
                [107.7319739, 15.2548654],
                [107.731973, 15.2548171],
                [107.7319817, 15.2547696],
                [107.7319999, 15.2547247],
                [107.7320269, 15.254684],
                [107.7320615, 15.2546491],
                [107.7321024, 15.2546215],
                [107.7322019, 15.254571],
                [107.7322512, 15.2545338],
                [107.7323334, 15.2544431],
                [107.7323896, 15.2543354],
                [107.7324166, 15.2542119],
                [107.7324094, 15.2540859],
                [107.732393, 15.2540247],
                [107.7323359, 15.2539111],
                [107.7321187, 15.2533702],
                [107.7321111, 15.2533107],
                [107.7321179, 15.2532813],
                [107.7321509, 15.2532305],
                [107.7322031, 15.2531989],
                [107.7322334, 15.2531923],
                [107.7322946, 15.2531992],
                [107.7324469, 15.2532548],
                [107.7325773, 15.2532745],
                [107.7329036, 15.2532907],
                [107.7332301, 15.2532768],
                [107.7335536, 15.2532331],
                [107.733802, 15.253176],
                [107.7338701, 15.2531503],
                [107.7339474, 15.2530914],
                [107.734, 15.2530111],
                [107.7340219, 15.2529184],
                [107.7340155, 15.2527863],
                [107.7340031, 15.2527025],
                [107.733956, 15.2525396],
                [107.7338817, 15.252389],
                [107.733782, 15.2522529],
                [107.7336597, 15.2521351],
                [107.7335912, 15.2520841],
                [107.7335452, 15.2520604],
                [107.7334689, 15.2519921],
                [107.7334209, 15.2519028],
                [107.7334094, 15.2518534],
                [107.7334134, 15.2517525],
                [107.7334525, 15.2516589],
                [107.7334839, 15.2516183],
                [107.7336073, 15.2513389],
                [107.7336287, 15.2511267],
                [107.7336452, 15.2510758],
                [107.7336972, 15.2509927],
                [107.7337652, 15.2509342],
                [107.7338049, 15.2509125],
                [107.7343637, 15.2507488],
                [107.7344299, 15.250714],
                [107.7345481, 15.2506239],
                [107.7346426, 15.2505108],
                [107.7346817, 15.2504428],
                [107.7347121, 15.2503709],
                [107.7347333, 15.2502959],
                [107.7347452, 15.2502191],
                [107.7347474, 15.2501414],
                [107.73474, 15.250064],
                [107.7347231, 15.249988],
                [107.7344656, 15.2493101],
                [107.7344427, 15.2492344],
                [107.7344238, 15.2490782],
                [107.734428, 15.2489995],
                [107.734459, 15.2488593],
                [107.7344855, 15.2487923],
                [107.7345589, 15.2486676],
                [107.7346051, 15.2486114],
                [107.7346548, 15.248504],
                [107.734696, 15.2483934],
                [107.7347484, 15.2481855],
                [107.7347699, 15.2479937],
                [107.7347875, 15.2467533],
                [107.735002, 15.246008],
                [107.7353346, 15.245625],
                [107.7354068, 15.2454832],
                [107.7354312, 15.2454077],
                [107.7354534, 15.2452792],
                [107.7354559, 15.245214],
                [107.7354353, 15.244855],
                [107.7353775, 15.244269],
                [107.7352863, 15.2436711],
                [107.735163, 15.2430786],
                [107.7351161, 15.2427202],
                [107.735104, 15.2423592],
                [107.7351683, 15.2419141],
                [107.73522, 15.2418127],
                [107.7352556, 15.2417678],
                [107.7353495, 15.2416895],
                [107.7354072, 15.2416591],
                [107.7354688, 15.2416372],
                [107.7359194, 15.2415414],
                [107.7359662, 15.2415261],
                [107.7360503, 15.2414764],
                [107.7360856, 15.2414431],
                [107.7361194, 15.2413992],
                [107.7361449, 15.2413505],
                [107.7361615, 15.2412982],
                [107.7361686, 15.241244],
                [107.7361661, 15.2411895],
                [107.7361851, 15.2408995],
                [107.7362138, 15.2406103],
                [107.7362522, 15.2403221],
                [107.7363002, 15.2400353],
                [107.7363559, 15.239759],
                [107.736494, 15.2392121],
                [107.7366673, 15.2386746],
                [107.7367669, 15.2384101],
                [107.7370405, 15.2378614],
                [107.7371152, 15.2377738],
                [107.7371605, 15.2377374],
                [107.7372635, 15.2376825],
                [107.7373195, 15.2376648],
                [107.7374363, 15.2376503],
                [107.7375532, 15.2376644],
                [107.7376628, 15.2377062],
                [107.7388912, 15.2387362],
                [107.7389975, 15.2387832],
                [107.7390547, 15.2387956],
                [107.7391716, 15.238797],
                [107.7392844, 15.2387672],
                [107.7393365, 15.2387413],
                [107.7394315, 15.2386652],
                [107.7394693, 15.2386179],
                [107.7395215, 15.2385099],
                [107.7395347, 15.2384516],
                [107.739534, 15.2383324],
                [107.7394974, 15.2382186],
                [107.7377486, 15.2355427],
                [107.7376921, 15.2354713],
                [107.7375634, 15.2353417],
                [107.7374918, 15.2352842],
                [107.737416, 15.2352321],
                [107.7372547, 15.2351456],
                [107.7370818, 15.2350831],
                [107.7369922, 15.2350613],
                [107.7366435, 15.2350354],
                [107.736539, 15.234982],
                [107.7364942, 15.2349445],
                [107.7364244, 15.2348515],
                [107.7363868, 15.2347419],
                [107.7363854, 15.2346265],
                [107.7364203, 15.2345161],
                [107.7364957, 15.234382],
                [107.7365669, 15.2342055],
                [107.7366114, 15.234021],
                [107.7366245, 15.233911],
                [107.7366281, 15.2338003],
                [107.7366221, 15.2336897],
                [107.7365695, 15.2335915],
                [107.7364927, 15.2335095],
                [107.7363968, 15.233449],
                [107.7363404, 15.2334271],
                [107.7362211, 15.2334064],
                [107.7361603, 15.233408],
                [107.7360423, 15.2334347],
                [107.7359872, 15.2334594],
                [107.7358898, 15.2335292],
                [107.7357, 15.2339028],
                [107.7355171, 15.2343259],
                [107.7353496, 15.2347999],
                [107.7352788, 15.2350408],
                [107.7352166, 15.2352839],
                [107.7352085, 15.2353424],
                [107.7351679, 15.2354536],
                [107.7350976, 15.2355499],
                [107.7350028, 15.2356243],
                [107.73489, 15.2356712],
                [107.7348293, 15.235683],
                [107.7347058, 15.2356819],
                [107.7346454, 15.235669],
                [107.7345329, 15.2356198],
                [107.7342217, 15.2353714],
                [107.7340243, 15.2351792],
                [107.7339336, 15.2350759],
                [107.7337565, 15.2348364],
                [107.7336729, 15.2346994],
                [107.7335982, 15.2345578],
                [107.7335326, 15.234412],
                [107.7334763, 15.2342626],
                [107.7334297, 15.2341101],
                [107.7333928, 15.2339552],
                [107.7333455, 15.233632],
                [107.7333297, 15.2332975],
                [107.7333473, 15.232963],
                [107.7333685, 15.2327968],
                [107.7334356, 15.2324682],
                [107.7336073, 15.2319713],
                [107.7336967, 15.2318566],
                [107.7338058, 15.2317591],
                [107.7338772, 15.2317113],
                [107.7340337, 15.2316384],
                [107.7342536, 15.2315721],
                [107.7343778, 15.2315028],
                [107.7344888, 15.2314038],
                [107.734537, 15.2313427],
                [107.7345772, 15.2312766],
                [107.734609, 15.2312063],
                [107.7347231, 15.2308533],
                [107.7347423, 15.2308165],
                [107.7347967, 15.2307533],
                [107.7348306, 15.2307282],
                [107.7349114, 15.2306931],
                [107.7350032, 15.2306838],
                [107.7350932, 15.2307032],
                [107.7360159, 15.2310603],
                [107.7360867, 15.2310773],
                [107.7362319, 15.2310878],
                [107.7363758, 15.2310668],
                [107.7365118, 15.2310152],
                [107.7365746, 15.2309785],
                [107.7366853, 15.230886],
                [107.7367319, 15.2308312],
                [107.7368045, 15.2307083],
                [107.736874, 15.2299603],
                [107.7369064, 15.2298025],
                [107.736968, 15.2296511],
                [107.7370566, 15.2295127],
                [107.7375126, 15.2291504],
                [107.7375701, 15.2291205],
                [107.7376927, 15.2290776],
                [107.7378319, 15.2290579],
                [107.7379075, 15.2290592],
                [107.7380559, 15.2290861],
                [107.7395242, 15.2296731],
                [107.7406347, 15.2300717],
                [107.7424103, 15.2305841],
                [107.74351, 15.2304806],
                [107.7439094, 15.2304589],
                [107.7441095, 15.2304605],
                [107.7444891, 15.2304861],
                [107.7446681, 15.2305087],
                [107.7450228, 15.2305738],
                [107.7452523, 15.2305911],
                [107.745482, 15.2305766],
                [107.7457072, 15.2305306],
                [107.7459279, 15.2304522],
                [107.7460878, 15.2303719],
                [107.7462148, 15.2302865],
                [107.7463513, 15.2301597],
                [107.7464631, 15.2300122],
                [107.7465087, 15.2299319],
                [107.7467447, 15.2294143],
                [107.7469164, 15.2292073],
                [107.7489066, 15.2297508],
                [107.7503872, 15.230403],
                [107.7505531, 15.2304983],
                [107.750714, 15.2306014],
                [107.7508694, 15.230712],
                [107.7510189, 15.2308299],
                [107.7511623, 15.2309547],
                [107.7512991, 15.2310862],
                [107.7514274, 15.2312222],
                [107.7516627, 15.2315113],
                [107.7517691, 15.2316639],
                [107.7519487, 15.2319593],
                [107.7520555, 15.23208],
                [107.7521886, 15.2321816],
                [107.7523398, 15.232256],
                [107.752424, 15.2323586],
                [107.7526081, 15.232551],
                [107.7527287, 15.2326581],
                [107.752856, 15.2327579],
                [107.7529894, 15.23285],
                [107.7535522, 15.2332083],
                [107.7536808, 15.2333372],
                [107.7537804, 15.2334876],
                [107.7538472, 15.2336535],
                [107.7546948, 15.2352011],
                [107.7563202, 15.2359723],
                [107.7569425, 15.2353719],
                [107.7570148, 15.2353215],
                [107.7571709, 15.2352384],
                [107.7572536, 15.2352062],
                [107.7574333, 15.2351604],
                [107.7575255, 15.2351485],
                [107.7576184, 15.2351441],
                [107.7586377, 15.2351545],
                [107.7601522, 15.2354474],
                [107.7602947, 15.2354635],
                [107.7605812, 15.2354723],
                [107.7608722, 15.2354492],
                [107.7611635, 15.2353928],
                [107.761446, 15.2353039],
                [107.7634549, 15.234492],
                [107.7638841, 15.2343367],
                [107.7641024, 15.2342235],
                [107.7643347, 15.23414],
                [107.7645842, 15.2340866],
                [107.7647112, 15.2340728],
                [107.7656343, 15.2340352],
                [107.7658289, 15.2340143],
                [107.7659248, 15.2339947],
                [107.7661277, 15.2339316],
                [107.7663345, 15.2338331],
                [107.7665234, 15.2337052],
                [107.7668238, 15.2334257],
                [107.7668809, 15.2333825],
                [107.7670062, 15.2333119],
                [107.7670756, 15.233284],
                [107.7672211, 15.2332477],
                [107.7680917, 15.233166],
                [107.7681837, 15.2331508],
                [107.7682737, 15.2331268],
                [107.7683607, 15.2330941],
                [107.7684438, 15.2330531],
                [107.7685757, 15.232964],
                [107.7686895, 15.2328542],
                [107.7688531, 15.2326379],
                [107.768932, 15.2325548],
                [107.7690178, 15.2324785],
                [107.7691098, 15.2324095],
                [107.7692075, 15.2323483],
                [107.7693101, 15.2322951],
                [107.769417, 15.2322504],
                [107.7698456, 15.2321205],
                [107.7700016, 15.2320644],
                [107.7703319, 15.2319221],
                [107.7705046, 15.231834],
                [107.7708363, 15.231635],
                [107.7709947, 15.2315246],
                [107.7713966, 15.2312067],
                [107.771877, 15.230786],
                [107.771641, 15.2301442],
                [107.7716161, 15.2300267],
                [107.7716006, 15.2299077],
                [107.7715946, 15.2297879],
                [107.7715981, 15.229668],
                [107.7716252, 15.2294688],
                [107.7717396, 15.229055],
                [107.7717912, 15.2288331],
                [107.7718335, 15.2286095],
                [107.7718663, 15.2283843],
                [107.7719771, 15.2272561],
                [107.7720211, 15.227085],
                [107.7720539, 15.2270026],
                [107.7721399, 15.226847],
                [107.7721965, 15.22677],
                [107.7723296, 15.2266318],
                [107.7724858, 15.2265182],
                [107.7725711, 15.2264719],
                [107.7727769, 15.2263796],
                [107.7729999, 15.2262546],
                [107.7732071, 15.2261063],
                [107.7734049, 15.2259276],
                [107.7734994, 15.2258253],
                [107.7736681, 15.225605],
                [107.7738075, 15.2253664],
                [107.7739155, 15.225113],
                [107.7739584, 15.2241296],
                [107.7750099, 15.222763],
                [107.7751313, 15.2227826],
                [107.7752541, 15.2227924],
                [107.7753772, 15.2227924],
                [107.7754999, 15.2227826],
                [107.7756214, 15.222763],
                [107.7757377, 15.2227348],
                [107.7758513, 15.2226976],
                [107.7759615, 15.2226518],
                [107.7760675, 15.2225976],
                [107.7761686, 15.2225353],
                [107.7771342, 15.2218417],
                [107.7773611, 15.2216539],
                [107.7774668, 15.2215518],
                [107.777649, 15.2213476],
                [107.7778101, 15.2211274],
                [107.777913, 15.2209581],
                [107.7779734, 15.220879],
                [107.7781105, 15.220734],
                [107.7781879, 15.2206676],
                [107.7783562, 15.2205516],
                [107.7784463, 15.2205025],
                [107.7786362, 15.2204234],
                [107.7787693, 15.220284],
                [107.7789092, 15.2201508],
                [107.7790554, 15.2200242],
                [107.7792077, 15.2199045],
                [107.7795228, 15.2196906],
                [107.7798503, 15.2195092],
                [107.7801992, 15.2193543],
                [107.7805459, 15.2191811],
                [107.7808494, 15.2189961],
                [107.781136, 15.2187877],
                [107.7818549, 15.2188809],
                [107.7820484, 15.2188846],
                [107.7821445, 15.2188731],
                [107.7823293, 15.2188247],
                [107.7824172, 15.2187881],
                [107.7826244, 15.2186713],
                [107.7828822, 15.218547],
                [107.7831537, 15.2184511],
                [107.7832933, 15.2184143],
                [107.7835777, 15.2183639],
                [107.7839734, 15.2183197],
                [107.7842238, 15.2182806],
                [107.7847195, 15.2181769],
                [107.7848751, 15.2181512],
                [107.7851893, 15.2181201],
                [107.7855224, 15.2181167],
                [107.7858716, 15.2181458],
                [107.7862161, 15.2182079],
                [107.788671, 15.2191615],
                [107.7887813, 15.2191821],
                [107.788893, 15.2191707],
                [107.7889941, 15.2191296],
                [107.7890391, 15.219099],
                [107.7891129, 15.2190206],
                [107.7896467, 15.2181924],
                [107.7897045, 15.2181217],
                [107.7898363, 15.2179945],
                [107.7899095, 15.2179388],
                [107.7900705, 15.2178437],
                [107.7901563, 15.2178056],
                [107.790336, 15.2177498],
                [107.7913177, 15.2175971],
                [107.7923584, 15.2172451],
                [107.793155, 15.2172438],
                [107.7933814, 15.2171763],
                [107.7936105, 15.2171178],
                [107.7938418, 15.2170682],
                [107.7942915, 15.2169985],
                [107.794727, 15.2169637],
                [107.795164, 15.2169604],
                [107.7957433, 15.2170691],
                [107.795865, 15.2170829],
                [107.7959874, 15.2170873],
                [107.7962162, 15.2170702],
                [107.7964397, 15.2170205],
                [107.7996138, 15.2160248],
                [107.7999464, 15.2158737],
                [107.8001081, 15.2157892],
                [107.8004207, 15.2156025],
                [107.8007281, 15.2153856],
                [107.8010265, 15.2151373],
                [107.8011676, 15.2150045],
                [107.8014323, 15.214723],
                [107.802186, 15.213777],
                [107.8051767, 15.2131558],
                [107.8062625, 15.213017],
                [107.8066329, 15.2129406],
                [107.8068155, 15.2128918],
                [107.8071771, 15.2127721],
                [107.8075276, 15.2126248],
                [107.807698, 15.2125411],
                [107.8085911, 15.2120429],
                [107.8086863, 15.2119973],
                [107.8088864, 15.211929],
                [107.8090954, 15.2118928],
                [107.8093034, 15.2118891],
                [107.809407, 15.2118992],
                [107.8096101, 15.2119429],
                [107.8098035, 15.212017],
                [107.8099545, 15.2121013],
                [107.8102648, 15.2122551],
                [107.8106243, 15.212403],
                [107.8108082, 15.212467],
                [107.8116327, 15.2127158],
                [107.8127968, 15.2136061],
                [107.8138965, 15.2130367],
                [107.8140972, 15.2129691],
                [107.8143063, 15.2129326],
                [107.8144125, 15.2129263],
                [107.8146278, 15.2129383],
                [107.8148415, 15.212984],
                [107.8150448, 15.2130624],
                [107.8152323, 15.2131713],
                [107.8167128, 15.2144913],
                [107.8170385, 15.2148291],
                [107.8173711, 15.2151604],
                [107.8177106, 15.2154852],
                [107.8180217, 15.2157716],
                [107.8186597, 15.216328],
                [107.8188011, 15.2164295],
                [107.8188793, 15.2164689],
                [107.8190464, 15.216523],
                [107.8191253, 15.2165362],
                [107.8192854, 15.2165404],
                [107.8194433, 15.2165153],
                [107.8196556, 15.2164402],
                [107.8198643, 15.2163564],
                [107.820069, 15.2162641],
                [107.8204521, 15.2160619],
                [107.8208039, 15.2158385],
                [107.8209726, 15.2157167],
                [107.8212941, 15.2154541],
                [107.8214115, 15.2153282],
                [107.8216267, 15.2150607],
                [107.8217969, 15.2148034],
                [107.8218731, 15.2146696],
                [107.8222722, 15.2138997],
                [107.8223279, 15.2138079],
                [107.8224555, 15.2136346],
                [107.8225349, 15.213545],
                [107.822711, 15.2133816],
                [107.8228068, 15.2133085],
                [107.8229746, 15.2132083],
                [107.8230636, 15.2131672],
                [107.8232561, 15.2131026],
                [107.8233588, 15.2130804],
                [107.8235679, 15.213059],
                [107.8241989, 15.2130536],
                [107.8244343, 15.2130233],
                [107.8245498, 15.2129962],
                [107.8247733, 15.2129186],
                [107.8249788, 15.2128138],
                [107.8251633, 15.2126857],
                [107.8253281, 15.2125346],
                [107.8279728, 15.2095271],
                [107.8282201, 15.2092902],
                [107.828489, 15.2090763],
                [107.8287771, 15.2088872],
                [107.829086, 15.2087224],
                [107.8294137, 15.2085846],
                [107.8295822, 15.2085271],
                [107.8299267, 15.2084352],
                [107.830102, 15.2084012],
                [107.8314373, 15.2082246],
                [107.8319295, 15.2081713],
                [107.8328075, 15.2080982],
                [107.8335786, 15.208057],
                [107.8337261, 15.2081267],
                [107.8338777, 15.2081879],
                [107.8340328, 15.2082404],
                [107.8341908, 15.2082838],
                [107.8343513, 15.2083182],
                [107.8345135, 15.2083433],
                [107.834677, 15.208359],
                [107.8348412, 15.2083654],
                [107.8350055, 15.2083624],
                [107.8351686, 15.20835],
                [107.8353307, 15.2083284],
                [107.8354911, 15.2082976],
                [107.8356494, 15.2082577],
                [107.835805, 15.2082088],
                [107.8359573, 15.2081512],
                [107.8361059, 15.2080849],
                [107.8362501, 15.2080104],
                [107.837268, 15.2074151],
                [107.8374521, 15.2073305],
                [107.8376448, 15.2072662],
                [107.837751, 15.2072404],
                [107.8379673, 15.2072073],
                [107.8380766, 15.2072002],
                [107.8387016, 15.2080259],
                [107.8403699, 15.2089422],
                [107.8420007, 15.2080699],
                [107.8422009, 15.2079923],
                [107.8424045, 15.2079233],
                [107.8426111, 15.2078631],
                [107.8428201, 15.2078117],
                [107.843227, 15.2077383],
                [107.8436388, 15.2076983],
                [107.8440526, 15.207692],
                [107.8443044, 15.2075922],
                [107.8444337, 15.2075511],
                [107.8447247, 15.2074818],
                [107.8448863, 15.2074565],
                [107.8450491, 15.2074401],
                [107.8452126, 15.2074328],
                [107.8453763, 15.2074345],
                [107.8463244, 15.2086419],
                [107.8465403, 15.2088206],
                [107.8466566, 15.2088996],
                [107.8469038, 15.2090353],
                [107.8471621, 15.2091381],
                [107.8474317, 15.2092088],
                [107.8477083, 15.2092465],
                [107.8481184, 15.2092712],
                [107.8483882, 15.2092967],
                [107.8489252, 15.2093693],
                [107.8494573, 15.2094702],
                [107.8500942, 15.2096269],
                [107.8503489, 15.2097181],
                [107.8504748, 15.209778],
                [107.8505961, 15.2098462],
                [107.8507122, 15.2099224],
                [107.8508225, 15.2100061],
                [107.8509265, 15.2100971],
                [107.8510237, 15.2101949],
                [107.8516285, 15.2102173],
                [107.8528368, 15.210288],
                [107.8534564, 15.2103377],
                [107.8540752, 15.2103964],
                [107.8546929, 15.210464],
                [107.8549654, 15.2103368],
                [107.8552334, 15.2102011],
                [107.8554967, 15.2100571],
                [107.8559892, 15.2097573],
                [107.8564431, 15.209442],
                [107.8566626, 15.2092747],
                [107.8570855, 15.2089214],
                [107.8573866, 15.2089691],
                [107.8576859, 15.2090261],
                [107.8579832, 15.2090924],
                [107.8582781, 15.209168],
                [107.8585703, 15.2092527],
                [107.8588595, 15.2093466],
                [107.8591454, 15.2094495],
                [107.859424, 15.2095597],
                [107.8596989, 15.2096784],
                [107.8599696, 15.2098056],
                [107.860236, 15.2099411],
                [107.8604978, 15.2100847],
                [107.8607547, 15.2102363],
                [107.860924, 15.2103014],
                [107.8612699, 15.2104117],
                [107.8616238, 15.2104951],
                [107.8618077, 15.2105273],
                [107.862179, 15.2105696],
                [107.8623657, 15.2105798],
                [107.8627396, 15.2105779],
                [107.8664517, 15.2103916],
                [107.8671974, 15.2099516],
                [107.8673913, 15.209911],
                [107.867589, 15.2098946],
                [107.867803, 15.2099045],
                [107.867909, 15.2099201],
                [107.8681163, 15.2099722],
                [107.8682166, 15.2100085],
                [107.8695846, 15.2105883],
                [107.8698428, 15.2106054],
                [107.8701014, 15.2106144],
                [107.8706189, 15.2106074],
                [107.8708773, 15.2105916],
                [107.8713743, 15.2105378],
                [107.8718498, 15.2104568],
                [107.8720854, 15.2104058],
                [107.8725511, 15.2102829],
                [107.8727128, 15.2102475],
                [107.8730408, 15.2101996],
                [107.8733719, 15.2101826],
                [107.8735378, 15.2101857],
                [107.8737033, 15.2101966],
                [107.8740317, 15.2102415],
                [107.8761399, 15.2054946],
                [107.876966, 15.2050908],
                [107.877146, 15.204955],
                [107.8774948, 15.2046702],
                [107.8778544, 15.204343],
                [107.8782193, 15.2039701],
                [107.8783929, 15.2037759],
                [107.8786986, 15.2031656],
                [107.8789955, 15.2025513],
                [107.8792834, 15.201933],
                [107.8797354, 15.2009129],
                [107.8801632, 15.199883],
                [107.8811073, 15.1968184],
                [107.8837895, 15.1967148],
                [107.884782, 15.1963021],
                [107.8857636, 15.1958658],
                [107.8868441, 15.1953521],
                [107.8873787, 15.1950845],
                [107.8879094, 15.1948098],
                [107.8899264, 15.1936294],
                [107.8902015, 15.193824],
                [107.8904936, 15.1939941],
                [107.8907993, 15.1941378],
                [107.8909564, 15.1941995],
                [107.8912783, 15.1943024],
                [107.8914514, 15.1944006],
                [107.8916055, 15.194525],
                [107.891742, 15.1946801],
                [107.8918487, 15.1948557],
                [107.892673, 15.1966941],
                [107.8947866, 15.1984232],
                [107.899797, 15.2034653],
                [107.903257, 15.2064885],
                [107.9037094, 15.2064514],
                [107.9041582, 15.2063849],
                [107.9046294, 15.2062821],
                [107.9048618, 15.2062185],
                [107.9053149, 15.2060787],
                [107.9055454, 15.2060388],
                [107.9057793, 15.2060276],
                [107.9060242, 15.2060471],
                [107.9061508, 15.2060699],
                [107.9063974, 15.206141],
                [107.9066311, 15.2062449],
                [107.9067418, 15.2063085],
                [107.9069477, 15.2064574],
                [107.9071802, 15.2065373],
                [107.9072998, 15.2065668],
                [107.9075434, 15.2066046],
                [107.9077911, 15.2066135],
                [107.9079155, 15.206607],
                [107.9081619, 15.2065719],
                [107.908402, 15.2065079],
                [107.9087638, 15.2063656],
                [107.9088981, 15.2063223],
                [107.9091732, 15.2062578],
                [107.9094541, 15.2062233],
                [107.9097372, 15.2062193],
                [107.9098833, 15.2062291],
                [107.9101724, 15.2062732],
                [107.9104543, 15.2063491],
                [107.9107253, 15.2064558],
                [107.9109818, 15.206592],
                [107.911078, 15.2066548],
                [107.9111687, 15.2067249],
                [107.9112532, 15.2068018],
                [107.911331, 15.206885],
                [107.9114016, 15.206974],
                [107.9114646, 15.2070682],
                [107.9115149, 15.2071583],
                [107.9115945, 15.2073478],
                [107.9116443, 15.2075465],
                [107.9116721, 15.2077542],
                [107.9116938, 15.2078593],
                [107.9117585, 15.2080641],
                [107.9118508, 15.2082589],
                [107.91191, 15.2083564],
                [107.9120489, 15.2085382],
                [107.9122126, 15.2086996],
                [107.9123981, 15.2088373],
                [107.9124979, 15.2088965],
                [107.9127931, 15.2090378],
                [107.9129487, 15.2091443],
                [107.9130846, 15.2092735],
                [107.9132008, 15.2094274],
                [107.9132486, 15.2095109],
                [107.9133218, 15.2096878],
                [107.9133636, 15.2098739],
                [107.91349, 15.2102687],
                [107.9135641, 15.2104626],
                [107.9137337, 15.2108419],
                [107.9138255, 15.2110205],
                [107.9140281, 15.211368],
                [107.9141386, 15.2115364],
                [107.9143774, 15.2118617],
                [107.9155522, 15.2118928],
                [107.9160268, 15.2117219],
                [107.9165097, 15.2115745],
                [107.9169895, 15.2114532],
                [107.9174647, 15.211357],
                [107.9187494, 15.2109144],
                [107.9190249, 15.2109111],
                [107.9191619, 15.2108977],
                [107.9194324, 15.2108475],
                [107.9195648, 15.2108109],
                [107.9198332, 15.2107103],
                [107.919962, 15.2106482],
                [107.9202065, 15.2105015],
                [107.9203212, 15.2104175],
                [107.9205336, 15.2102968],
                [107.9207611, 15.2102052],
                [107.9209889, 15.2101465],
                [107.9212227, 15.210117],
                [107.9275946, 15.2096876],
                [107.9296768, 15.208321],
                [107.9309642, 15.2071511],
                [107.930932, 15.205039],
                [107.931065, 15.2048379],
                [107.9311197, 15.2047308],
                [107.9312042, 15.2045066],
                [107.9312334, 15.2043906],
                [107.9312539, 15.2042729],
                [107.9312657, 15.2041492],
                [107.9312677, 15.2040251],
                [107.9312599, 15.2039012],
                [107.9312425, 15.2037782],
                [107.9312154, 15.2036568],
                [107.9311788, 15.2035378],
                [107.9311704, 15.203308],
                [107.9311775, 15.2031932],
                [107.9312139, 15.202966],
                [107.9312432, 15.2028545],
                [107.9313327, 15.2026167],
                [107.9313901, 15.2025029],
                [107.9315285, 15.2022881],
                [107.9316964, 15.2020938],
                [107.933625, 15.20038],
                [107.9337, 15.200298],
                [107.9337671, 15.2002099],
                [107.9338258, 15.2001163],
                [107.9338756, 15.200018],
                [107.933916, 15.1999158],
                [107.9339468, 15.1998106],
                [107.9339672, 15.199707],
                [107.9339782, 15.1996022],
                [107.9339799, 15.1994969],
                [107.9339721, 15.1993918],
                [107.9339549, 15.1992877],
                [107.9339286, 15.1991855],
                [107.9338932, 15.1990858],
                [107.9337134, 15.1986258],
                [107.9335672, 15.1981548],
                [107.9334581, 15.1976887],
                [107.9333837, 15.1972305],
                [107.9333406, 15.1967685],
                [107.9333309, 15.1965367],
                [107.9333407, 15.1951721],
                [107.9337696, 15.1945095],
                [107.9342247, 15.1938632],
                [107.9347206, 15.193215],
                [107.9349864, 15.1928886],
                [107.935259, 15.1925675],
                [107.9360538, 15.1916801],
                [107.9362769, 15.1914135],
                [107.9367041, 15.190866],
                [107.936908, 15.1905854],
                [107.9378757, 15.1905372],
                [107.9383587, 15.1904999],
                [107.939322, 15.190399],
                [107.9402403, 15.1902696],
                [107.9406975, 15.190193],
                [107.9416072, 15.1900159],
                [107.941873, 15.1898418],
                [107.9419979, 15.1897441],
                [107.9422295, 15.1895293],
                [107.9423368, 15.1894112],
                [107.9425296, 15.1891583],
                [107.9426144, 15.1890243],
                [107.9427597, 15.1887434],
                [107.9428196, 15.1885974],
                [107.9438388, 15.188323],
                [107.9444557, 15.1878571],
                [107.9446098, 15.1878207],
                [107.9447681, 15.1878106],
                [107.9449323, 15.1878286],
                [107.9450965, 15.1878774],
                [107.9451743, 15.1879128],
                [107.9453178, 15.1880041],
                [107.945441, 15.1881197],
                [107.9455393, 15.1882557],
                [107.9467463, 15.1879347],
                [107.9469328, 15.1878145],
                [107.9470197, 15.1877459],
                [107.9471789, 15.1875935],
                [107.9473159, 15.1874236],
                [107.947429, 15.1872393],
                [107.9474762, 15.1871424],
                [107.9476046, 15.1868048],
                [107.9477363, 15.1865433],
                [107.947899, 15.1862986],
                [107.9479913, 15.1861837],
                [107.9481962, 15.1859706],
                [107.9483081, 15.1858731],
                [107.9485488, 15.1856982],
                [107.9487795, 15.1847974],
                [107.9494285, 15.1841451],
                [107.9495053, 15.1840073],
                [107.9495732, 15.1838652],
                [107.9496319, 15.1837194],
                [107.9496814, 15.1835703],
                [107.9497212, 15.1834187],
                [107.9497514, 15.1832649],
                [107.9497719, 15.1831097],
                [107.9497822, 15.1829588],
                [107.9497752, 15.1826567],
                [107.9497578, 15.1825065],
                [107.9496957, 15.1822103],
                [107.9495978, 15.1819232],
                [107.9495358, 15.1817843],
                [107.9494802, 15.1815845],
                [107.9493937, 15.1811793],
                [107.9493629, 15.1809745],
                [107.9493267, 15.1805623],
                [107.9493212, 15.1803554],
                [107.9493367, 15.1799305],
                [107.9493877, 15.1795082],
                [107.9494265, 15.1792989],
                [107.9495304, 15.1788857],
                [107.9495953, 15.1786825],
                [107.9497504, 15.1782845],
                [107.9504156, 15.1768763],
                [107.9502976, 15.1751781],
                [107.9514348, 15.175147],
                [107.9518183, 15.1749403],
                [107.9520161, 15.1748481],
                [107.9524222, 15.1746867],
                [107.9528403, 15.1745568],
                [107.9530663, 15.174501],
                [107.9532944, 15.1744543],
                [107.9535244, 15.174417],
                [107.9537559, 15.174389],
                [107.9539883, 15.1743704],
                [107.9554796, 15.1743394],
                [107.9562736, 15.1739148],
                [107.9569709, 15.1738838],
                [107.9582477, 15.1733401],
                [107.958473, 15.1729001],
                [107.9588927, 15.1729221],
                [107.9593098, 15.1729725],
                [107.9597592, 15.1730596],
                [107.9602003, 15.1731796],
                [107.9609406, 15.1725894],
                [107.9623139, 15.1723616],
                [107.9634404, 15.1713209],
                [107.9634941, 15.1708135],
                [107.9638491, 15.1706105],
                [107.96402, 15.1704988],
                [107.964347, 15.1702558],
                [107.9646517, 15.1699889],
                [107.9649324, 15.1697001],
                [107.9651881, 15.1693904],
                [107.9654173, 15.1690619],
                [107.9656184, 15.1687167],
                [107.9664338, 15.1687477],
                [107.967217, 15.1692448],
                [107.9673276, 15.1692941],
                [107.9675577, 15.1693711],
                [107.9677963, 15.1694182],
                [107.9680393, 15.1694346],
                [107.9682954, 15.1694183],
                [107.9684284, 15.1693961],
                [107.9685594, 15.1693645],
                [107.9686876, 15.1693238],
                [107.9688124, 15.1692742],
                [107.9689332, 15.1692159],
                [107.9690492, 15.1691493],
                [107.9691598, 15.1690747],
                [107.9692645, 15.1689924],
                [107.9695034, 15.1687704],
                [107.9697995, 15.1685204],
                [107.9701137, 15.1682921],
                [107.9704508, 15.1680831],
                [107.9706251, 15.1679878],
                [107.9709843, 15.1678164],
                [107.9711686, 15.1677406],
                [107.9716141, 15.1678043],
                [107.9722059, 15.1679009],
                [107.9725038, 15.1679365],
                [107.9731022, 15.167982],
                [107.9734021, 15.1679918],
                [107.9737164, 15.1679929],
                [107.9740305, 15.1679846],
                [107.9743442, 15.1679668],
                [107.9746571, 15.1679396],
                [107.9751247, 15.1678823],
                [107.9754797, 15.1678181],
                [107.975678, 15.1677718],
                [107.9760686, 15.1676577],
                [107.9764491, 15.1675155],
                [107.9769016, 15.167295],
                [107.9771328, 15.1671948],
                [107.9776041, 15.167015],
                [107.9778439, 15.1669356],
                [107.978357, 15.1667914],
                [107.9786169, 15.1667312],
                [107.9791421, 15.1666353],
                [107.97926, 15.1666242],
                [107.9793785, 15.1666223],
                [107.9794967, 15.1666295],
                [107.979614, 15.166646],
                [107.9797295, 15.1666715],
                [107.9798415, 15.1667056],
                [107.9799504, 15.1667482],
                [107.9800555, 15.1667991],
                [107.9801559, 15.1668579],
                [107.9802457, 15.1668983],
                [107.9803382, 15.1669322],
                [107.9805299, 15.1669794],
                [107.9807358, 15.1669988],
                [107.9808437, 15.1669965],
                [107.9810572, 15.1669666],
                [107.9821408, 15.1660554],
                [107.9822397, 15.1657938],
                [107.9822795, 15.1656599],
                [107.9823393, 15.1653875],
                [107.9823609, 15.1652347],
                [107.9823793, 15.1649269],
                [107.982376, 15.1647727],
                [107.9823446, 15.1644659],
                [107.9828596, 15.1638135],
                [107.9833837, 15.1632036],
                [107.9836552, 15.1629065],
                [107.9842167, 15.1623285],
                [107.9855955, 15.1609658],
                [107.9856893, 15.1601244],
                [107.9857073, 15.16003],
                [107.9857345, 15.1599377],
                [107.9857708, 15.1598483],
                [107.9858158, 15.1597627],
                [107.985927, 15.1596095],
                [107.986062, 15.1594819],
                [107.9862188, 15.1593802],
                [107.9863922, 15.1593076],
                [107.9870707, 15.1591277],
                [107.9883903, 15.1582578],
                [107.9893988, 15.1584442],
                [107.9899782, 15.1576365],
                [107.9900956, 15.1575413],
                [107.9903451, 15.1573691],
                [107.9904765, 15.1572927],
                [107.9907507, 15.1571601],
                [107.9909087, 15.1570986],
                [107.9910701, 15.157046],
                [107.9912344, 15.1570025],
                [107.9914011, 15.1569683],
                [107.9915695, 15.1569434],
                [107.9917391, 15.1569279],
                [107.9919094, 15.1569219],
                [107.9929072, 15.1570048],
                [107.9940659, 15.157419],
                [107.9942188, 15.1574453],
                [107.994373, 15.1574626],
                [107.9945281, 15.1574709],
                [107.9946834, 15.1574702],
                [107.9949788, 15.1574437],
                [107.9952557, 15.1573882],
                [107.9953914, 15.1573495],
                [107.9956552, 15.1572508],
                [107.9959324, 15.1571194],
                [107.9962415, 15.1569954],
                [107.9965607, 15.1568983],
                [107.9968876, 15.1568287],
                [107.9972573, 15.1567845],
                [107.9974435, 15.1567757],
                [107.997816, 15.1567845],
                [107.9981857, 15.1568287],
                [107.9982609, 15.1568708],
                [107.9984216, 15.1569345],
                [107.9985057, 15.1569555],
                [107.9986782, 15.1569752],
                [107.998858, 15.1569637],
                [107.9990384, 15.1569177],
                [107.9992063, 15.156839],
                [107.9992835, 15.1567882],
                [107.9993552, 15.1567304],
                [107.9993929, 15.1557041],
                [107.9994177, 15.1553574],
                [107.9994518, 15.1550113],
                [107.9995396, 15.1543717],
                [107.9995954, 15.1540536],
                [107.9997307, 15.1534217],
                [108.0000096, 15.1501907],
                [108.0006626, 15.1490359],
                [108.00075, 15.1488661],
                [108.0009011, 15.1485163],
                [108.001013, 15.1481787],
                [108.0010908, 15.1478575],
                [108.0011201, 15.1476949],
                [108.0014795, 15.1452923],
                [108.0044728, 15.1437026],
                [108.0046279, 15.14358],
                [108.0046982, 15.143511],
                [108.0048101, 15.1433762],
                [108.0048591, 15.1433039],
                [108.0043012, 15.1426204],
                [108.004342, 15.1419685],
                [108.0043495, 15.1413155],
                [108.0043225, 15.1406453],
                [108.0042958, 15.1403109],
                [108.0041331, 15.1402471],
                [108.0039743, 15.1401746],
                [108.00382, 15.1400938],
                [108.0036705, 15.1400048],
                [108.0035264, 15.1399079],
                [108.0033881, 15.1398035],
                [108.003256, 15.1396918],
                [108.0031305, 15.1395732],
                [108.0030121, 15.139448],
                [108.0029011, 15.1393167],
                [108.0027185, 15.1390624],
                [108.0025635, 15.1387915],
                [108.0024377, 15.1385069],
                [108.0023425, 15.1382114],
                [108.0023067, 15.1380606],
                [108.0022788, 15.1379082],
                [108.0021071, 15.136334],
                [108.000235, 15.1337034],
                [108.0003388, 15.1333858],
                [108.0003794, 15.1332239],
                [108.0004375, 15.1328956],
                [108.0004549, 15.1327298],
                [108.0004663, 15.1324077],
                [108.0004611, 15.1322465],
                [108.0004285, 15.1319257],
                [108.0004012, 15.1317667],
                [108.0007982, 15.1311556],
                [108.000806, 15.1308521],
                [108.0007805, 15.1305495],
                [108.0007207, 15.1302465],
                [108.0006259, 15.1299469],
                [108.0004978, 15.129659],
                [107.9995115, 15.1292688],
                [107.9992113, 15.1291304],
                [107.998926, 15.1289651],
                [107.998787, 15.1288709],
                [107.9985238, 15.1286635],
                [107.9984003, 15.1285508],
                [107.9983229, 15.1283331],
                [107.9982546, 15.1281125],
                [107.9981955, 15.1278895],
                [107.9981457, 15.1276643],
                [107.9981053, 15.1274375],
                [107.9980661, 15.1270966],
                [107.9980599, 15.1269252],
                [107.9980741, 15.1265825],
                [107.9980945, 15.1264121],
                [107.99816, 15.1260827],
                [107.9982585, 15.1257612],
                [107.9983199, 15.1256042],
                [107.9990923, 15.1242992],
                [107.9999641, 15.1229062],
                [108.0000494, 15.1228075],
                [108.0002375, 15.1226255],
                [108.0003396, 15.1225428],
                [108.000558, 15.1223957],
                [108.0007929, 15.1222744],
                [108.0010361, 15.1221822],
                [108.0011615, 15.1221464],
                [108.0014176, 15.1220963],
                [108.0015475, 15.122082],
                [108.0029011, 15.1219947],
                [108.0028868, 15.1217984],
                [108.0028421, 15.1216063],
                [108.0027743, 15.1214358],
                [108.0025953, 15.1211144],
                [108.002442, 15.1208971],
                [108.0023576, 15.120794],
                [108.0021645, 15.1205816],
                [108.0020832, 15.1204525],
                [108.0020267, 15.1203117],
                [108.0020058, 15.1202251],
                [108.0019942, 15.1201369],
                [108.0019923, 15.1200481],
                [108.0019998, 15.1199595],
                [108.0021011, 15.1193571],
                [108.0020994, 15.119218],
                [108.0020687, 15.119082],
                [108.0020428, 15.119017],
                [108.0019672, 15.1188908],
                [108.0019195, 15.1188343],
                [108.0018067, 15.1187373],
                [108.0013561, 15.1184784],
                [108.0005982, 15.1181507],
                [107.9998541, 15.1177948],
                [107.9990941, 15.1173942],
                [107.9983516, 15.1169642],
                [107.9978305, 15.1166415],
                [107.9975324, 15.1164266],
                [107.9972572, 15.1161849],
                [107.9971289, 15.1160548],
                [107.9969242, 15.1158176],
                [107.9967404, 15.1155649],
                [107.9966569, 15.1154333],
                [107.99582, 15.1146047],
                [107.9952407, 15.113051],
                [107.9942858, 15.1114766],
                [107.9937799, 15.1110402],
                [107.9935348, 15.1108137],
                [107.9930717, 15.110356],
                [107.9928481, 15.1101197],
                [107.9929342, 15.1096782],
                [107.9929635, 15.1094553],
                [107.9929944, 15.1090069],
                [107.9929881, 15.1085577],
                [107.992971, 15.1083336],
                [107.9929447, 15.1081103],
                [107.9929088, 15.1078859],
                [107.9928635, 15.1076631],
                [107.9928089, 15.1074422],
                [107.9927452, 15.1072236],
                [107.9926724, 15.1070077],
                [107.9920113, 15.1053292],
                [107.9905629, 15.1048268],
                [107.9909921, 15.1041639],
                [107.9910757, 15.1039968],
                [107.9911311, 15.1038191],
                [107.9911568, 15.1036352],
                [107.9911522, 15.1034487],
                [107.9911383, 15.1033558],
                [107.9910878, 15.1031748],
                [107.9910082, 15.1030037],
                [107.9903912, 15.1024237],
                [107.9903481, 15.1017787],
                [107.9902766, 15.1011361],
                [107.9901767, 15.100497],
                [107.990045, 15.0998465],
                [107.989884, 15.0992021],
                [107.9897925, 15.0988827],
                [107.9888409, 15.0957425],
                [107.9873764, 15.0943959],
                [107.9864645, 15.0943544],
                [107.9860862, 15.0940956],
                [107.9857283, 15.093811],
                [107.9853926, 15.0935022],
                [107.9850809, 15.0931707],
                [107.9847949, 15.0928183],
                [107.9845326, 15.0924416],
                [107.9842966, 15.0920416],
                [107.9841903, 15.0918355],
                [107.9840019, 15.0914127],
                [107.9838466, 15.0909775],
                [107.9837743, 15.0906004],
                [107.9837251, 15.0904145],
                [107.9836011, 15.0900501],
                [107.9834438, 15.0896979],
                [107.9833531, 15.0895272],
                [107.9832493, 15.0893521],
                [107.983137, 15.0891819],
                [107.9830166, 15.089017],
                [107.9828882, 15.0888578],
                [107.9827522, 15.0887045],
                [107.9826089, 15.0885576],
                [107.9824587, 15.0884174],
                [107.982144, 15.0881498],
                [107.9819801, 15.0880226],
                [107.9818103, 15.0879029],
                [107.9816349, 15.0877909],
                [107.9814543, 15.0876868],
                [107.981269, 15.0875909],
                [107.9810792, 15.0875034],
                [107.9808855, 15.0874244],
                [107.9805358, 15.0873065],
                [107.9801774, 15.0872166],
                [107.9799957, 15.0871822],
                [107.9793973, 15.0870942],
                [107.9789865, 15.08701],
                [107.9785805, 15.0869023],
                [107.9781818, 15.0867717],
                [107.9734397, 15.0848139],
                [107.9744267, 15.0831771],
                [107.9749203, 15.0820894],
                [107.9754567, 15.0815922],
                [107.9756044, 15.0813409],
                [107.9757439, 15.0810853],
                [107.9759779, 15.0806064],
                [107.9761658, 15.0801608],
                [107.9762506, 15.0799347],
                [107.9762943, 15.0797873],
                [107.9763286, 15.0796377],
                [107.9763534, 15.0794864],
                [107.976374, 15.0792107],
                [107.9763659, 15.0789643],
                [107.9763526, 15.0788417],
                [107.9764908, 15.0786255],
                [107.9765509, 15.0785123],
                [107.9766552, 15.0782692],
                [107.9766981, 15.0781396],
                [107.9767694, 15.0778731],
                [107.9767955, 15.0777363],
                [107.9768117, 15.0775981],
                [107.9768179, 15.0774591],
                [107.976814, 15.07732],
                [107.9768002, 15.0771816],
                [107.9767764, 15.0770444],
                [107.9767138, 15.0768171],
                [107.9766239, 15.0765986],
                [107.9765081, 15.0763917],
                [107.9762545, 15.076149],
                [107.9761378, 15.0760184],
                [107.9759264, 15.0757406],
                [107.9758322, 15.075594],
                [107.9756764, 15.0753054],
                [107.9756098, 15.0751558],
                [107.9754996, 15.0748482],
                [107.9753494, 15.0739158],
                [107.9753168, 15.0737519],
                [107.9752939, 15.0735865],
                [107.9752809, 15.0734201],
                [107.9752778, 15.0732532],
                [107.9752846, 15.0730865],
                [107.9753014, 15.0729204],
                [107.975328, 15.0727555],
                [107.9754162, 15.0723371],
                [107.975471, 15.0719133],
                [107.9754923, 15.0714868],
                [107.9754902, 15.0712733],
                [107.9754607, 15.0708472],
                [107.9753977, 15.0704245],
                [107.9753043, 15.0700182],
                [107.9751798, 15.0696197],
                [107.9751061, 15.0694241],
                [107.9749364, 15.0690415],
                [107.9743194, 15.068394],
                [107.9739458, 15.0681316],
                [107.9737526, 15.0680093],
                [107.9733545, 15.0677829],
                [107.9729156, 15.06757],
                [107.9726773, 15.0674691],
                [107.9724354, 15.0673766],
                [107.9721901, 15.0672927],
                [107.9719418, 15.0672174],
                [107.9716909, 15.0671508],
                [107.9700494, 15.0669333],
                [107.9683327, 15.0656383],
                [107.964127, 15.0656175],
                [107.9632473, 15.0644572],
                [107.959857, 15.0626545],
                [107.9580921, 15.0600541],
                [107.957909, 15.0598203],
                [107.9578069, 15.0597117],
                [107.9575837, 15.0595131],
                [107.9573375, 15.0593416],
                [107.957207, 15.0592667],
                [107.9569365, 15.0591415],
                [107.9566526, 15.0590478],
                [107.9563594, 15.058987],
                [107.9552865, 15.0588678],
                [107.9549644, 15.0581656],
                [107.9547926, 15.0578193],
                [107.9544279, 15.0571368],
                [107.9542351, 15.0568009],
                [107.9538576, 15.0561854],
                [107.9534569, 15.0555837],
                [107.953248, 15.0552883],
                [107.9515421, 15.0542004],
                [107.9529154, 15.0531851],
                [107.9535056, 15.052758],
                [107.9540853, 15.052309],
                [107.9546535, 15.0518382],
                [107.9546857, 15.0512984],
                [107.9546858, 15.0507577],
                [107.9546539, 15.0502179],
                [107.954626, 15.0499489],
                [107.9545097, 15.0491688],
                [107.9545089, 15.0489213],
                [107.9545439, 15.0486762],
                [107.954614, 15.0484381],
                [107.9546618, 15.0483233],
                [107.9547824, 15.0481037],
                [107.9548547, 15.0480001],
                [107.9550211, 15.047809],
                [107.9551144, 15.0477225],
                [107.9553187, 15.0475695],
                [107.9553661, 15.0473225],
                [107.9554474, 15.0470838],
                [107.9555613, 15.0468579],
                [107.9556298, 15.046751],
                [107.9557796, 15.0465615],
                [107.9559533, 15.0463919],
                [107.9561479, 15.0462452],
                [107.9562521, 15.0461811],
                [107.9578829, 15.0453989],
                [107.9580758, 15.0452911],
                [107.9582506, 15.0451576],
                [107.9584037, 15.0450011],
                [107.958471, 15.0449152],
                [107.9585856, 15.0447306],
                [107.9586699, 15.0445362],
                [107.9587012, 15.0444352],
                [107.9587412, 15.0442281],
                [107.9596746, 15.0431557],
                [107.9591489, 15.0424408],
                [107.9590416, 15.0408037],
                [107.9590125, 15.040213],
                [107.9589846, 15.0399184],
                [107.9589021, 15.0393324],
                [107.9587863, 15.0387601],
                [107.9587156, 15.0384766],
                [107.9586366, 15.0381952],
                [107.9588199, 15.0366181],
                [107.9598078, 15.0328534],
                [107.9600577, 15.03234],
                [107.9602561, 15.031973],
                [107.9604912, 15.0315534],
                [107.9606787, 15.0311763],
                [107.9608495, 15.0307577],
                [107.9610051, 15.0303912],
                [107.9611391, 15.0300146],
                [107.9612846, 15.0297004],
                [107.9614145, 15.0294176],
                [107.961645, 15.0290606],
                [107.9617927, 15.0288921],
                [107.9620146, 15.028681],
                [107.9622688, 15.0284799],
                [107.9625016, 15.0282894],
                [107.9626914, 15.0280787],
                [107.9628903, 15.0277533],
                [107.9630248, 15.0274081],
                [107.9631275, 15.0270945],
                [107.9632396, 15.026687],
                [107.9634048, 15.0262475],
                [107.9635074, 15.0259234],
                [107.9636203, 15.0255679],
                [107.9637547, 15.0252226],
                [107.9638887, 15.0248461],
                [107.9640644, 15.0243962],
                [107.9641879, 15.0240405],
                [107.9642958, 15.023706],
                [107.9643988, 15.0234131],
                [107.9645326, 15.0230262],
                [107.9646144, 15.022744],
                [107.9647167, 15.022399],
                [107.9647764, 15.0220756],
                [107.9648252, 15.0217314],
                [107.9648737, 15.0213767],
                [107.9649084, 15.0211682],
                [107.9650115, 15.0208857],
                [107.9651689, 15.0206545],
                [107.9654334, 15.0204221],
                [107.9657098, 15.0202831],
                [107.9666818, 15.0189691],
                [107.9668184, 15.0187798],
                [107.9670193, 15.0186003],
                [107.9673165, 15.0184089],
                [107.967544, 15.0182186],
                [107.9680712, 15.0176287],
                [107.9683141, 15.0173965],
                [107.9686109, 15.0171843],
                [107.9688873, 15.0170454],
                [107.9692054, 15.0168226],
                [107.9694485, 15.0166008],
                [107.9696277, 15.0164007],
                [107.9698361, 15.0159815],
                [107.9699289, 15.0157305],
                [107.9699986, 15.0153444],
                [107.970047, 15.0149793],
                [107.9701288, 15.0146971],
                [107.9703394, 15.0144445],
                [107.9706361, 15.014222],
                [107.970891, 15.0140834],
                [107.9712324, 15.0139955],
                [107.971553, 15.0139602],
                [107.9718844, 15.0139246],
                [107.9723356, 15.0132628],
                [107.9722144, 15.0130145],
                [107.9720074, 15.012757],
                [107.9717681, 15.012479],
                [107.9716904, 15.0122822],
                [107.971753, 15.0121565],
                [107.9719439, 15.012029],
                [107.9722323, 15.0119836],
                [107.9725217, 15.012011],
                [107.9727791, 15.0120494],
                [107.9730367, 15.012098],
                [107.9733473, 15.0121044],
                [107.9735935, 15.0121116],
                [107.9737971, 15.0121193],
                [107.9739896, 15.0121064],
                [107.974256, 15.0120197],
                [107.9744789, 15.0118813],
                [107.9747442, 15.0117113],
                [107.9750105, 15.0116245],
                [107.9753514, 15.0115055],
                [107.9757569, 15.0114066],
                [107.9761194, 15.0113081],
                [107.9764928, 15.0112095],
                [107.9767866, 15.011164],
                [107.977043, 15.0111294],
                [107.9772984, 15.0110219],
                [107.9775633, 15.0108311],
                [107.977817, 15.0105988],
                [107.9781335, 15.0102614],
                [107.9783341, 15.010061],
                [107.978621, 15.0099011],
                [107.9788551, 15.0098043],
                [107.9790471, 15.0097601],
                [107.9792129, 15.0097459],
                [107.9794758, 15.0097529],
                [107.9797558, 15.0097779],
                [107.9800662, 15.009874],
                [107.9803176, 15.0099567],
                [107.9805682, 15.009982],
                [107.9807004, 15.0099659],
                [107.9810385, 15.0099329],
                [107.9813024, 15.0098578],
                [107.9815516, 15.009783],
                [107.9820219, 15.0097339],
                [107.9824037, 15.0096716],
                [107.9827555, 15.0095668],
                [107.9830035, 15.0094061],
                [107.9831403, 15.0091895],
                [107.9831574, 15.0088315],
                [107.9831461, 15.0085525],
                [107.9831036, 15.008138],
                [107.983047, 15.0077737],
                [107.9829906, 15.0074165],
                [107.9829863, 15.0071088],
                [107.9830547, 15.0067357],
                [107.9831682, 15.0064265],
                [107.9832756, 15.0062104],
                [107.9834273, 15.0060079],
                [107.9836305, 15.0058049],
                [107.9838298, 15.0055804],
                [107.9840619, 15.0053411],
                [107.9843229, 15.0050515],
                [107.9845618, 15.0047692],
                [107.9848224, 15.004458],
                [107.9849991, 15.0042052],
                [107.9852087, 15.0039233],
                [107.9854556, 15.003691],
                [107.9857029, 15.0034802],
                [107.9858987, 15.0032701],
                [107.9860873, 15.0030744],
                [107.9861847, 15.0029299],
                [107.9863139, 15.0026993],
                [107.9864275, 15.0024043],
                [107.986504, 15.0020741],
                [107.9865622, 15.0017655],
                [107.9866538, 15.0014697],
                [107.9867758, 15.0012535],
                [107.986965, 15.0011009],
                [107.9871768, 15.0009836],
                [107.9875211, 15.0008647],
                [107.9877701, 15.0007755],
                [107.9880482, 15.0006717],
                [107.9882966, 15.0005398],
                [107.988547, 15.0003679],
                [107.9894865, 14.9996548],
                [107.9898112, 14.9987759],
                [107.9898322, 14.9987462],
                [107.9900613, 14.9985174],
                [107.9903287, 14.9983876],
                [107.9907728, 14.9982644],
                [107.991264, 14.9981947],
                [107.9917006, 14.998207],
                [107.9921761, 14.9983362],
                [107.9925407, 14.9985029],
                [107.9929527, 14.9987502],
                [107.9933001, 14.9990165],
                [107.9935183, 14.9993566],
                [107.993691, 14.99968],
                [107.9938255, 14.999816],
                [107.9940561, 15.000006],
                [107.9942935, 15.0001459],
                [107.9944787, 15.0002435],
                [107.9946855, 15.0003052],
                [107.9949807, 15.000387],
                [107.9952092, 15.0004198],
                [107.9955184, 15.0004442],
                [107.9958495, 15.0004542],
                [107.9962616, 15.0004702],
                [107.9966148, 15.0004799],
                [107.9967545, 15.0004792],
                [107.9970156, 15.0004829],
                [107.9973243, 15.0004717],
                [107.9976184, 15.0004606],
                [107.99792, 15.0004638],
                [107.9982515, 15.0005023],
                [107.9985021, 15.000542],
                [107.9987601, 15.0005887],
                [107.9991366, 15.000691],
                [107.9994256, 15.0008446],
                [107.999648, 15.0009705],
                [107.999871, 15.0011391],
                [108.0000947, 15.001358],
                [108.0002737, 15.0015415],
                [108.0003816, 15.0016686],
                [108.0033322, 15.0019534],
                [108.0043012, 15.0016665],
                [108.0060523, 15.0006407],
                [108.0070275, 14.9999044],
                [108.0076401, 14.9989757],
                [108.0079433, 14.9983488],
                [108.0082213, 14.9977339],
                [108.0083228, 14.9974736],
                [108.0084473, 14.9973324],
                [108.0086436, 14.9973112],
                [108.0093498, 14.9976061],
                [108.0104446, 14.9981439],
                [108.0114676, 14.9985618],
                [108.0127463, 14.9989757],
                [108.0143627, 14.9998219],
                [108.0155902, 15.0004584],
                [108.0164249, 15.00119],
                [108.0169532, 15.0017372],
                [108.0173424, 15.0019024],
                [108.0178849, 15.0016153],
                [108.0183388, 15.0013751],
                [108.0205992, 15.000344],
                [108.0221759, 14.999584],
                [108.0232099, 14.9989258],
                [108.0238886, 14.9980714],
                [108.0240913, 14.9979183],
                [108.0244596, 14.9978517],
                [108.0249007, 14.9978337],
                [108.0255378, 14.9978182],
                [108.026541, 14.9979027],
                [108.0270553, 14.9979094],
                [108.0275712, 14.9977972],
                [108.0283344, 14.9975216],
                [108.0290721, 14.9973171],
                [108.0298586, 14.9971133],
                [108.030647, 14.9967905],
                [108.0316084, 14.9963509],
                [108.0329243, 14.9959517],
                [108.03403, 14.9957043],
                [108.0349865, 14.9956216],
                [108.0361391, 14.9955177],
                [108.0366048, 14.9955],
                [108.0374411, 14.9952491],
                [108.0384273, 14.9947862],
                [108.0397543, 14.9944702],
                [108.0421525, 14.9937519],
                [108.0428901, 14.9935474],
                [108.0444634, 14.9931395],
                [108.0456193, 14.9927977],
                [108.0470462, 14.9923403],
                [108.047429, 14.9921193],
                [108.0476278, 14.9919077],
                [108.0482528, 14.9909878],
                [108.04933, 14.9892411],
                [108.0501039, 14.9881804],
                [108.0518168, 14.9856209],
                [108.0529175, 14.9839459],
                [108.0542575, 14.9817861],
                [108.0555084, 14.9798749],
                [108.0571205, 14.9775045],
                [108.0584199, 14.9756178],
                [108.0611058, 14.9717384],
                [108.0617794, 14.9708429],
                [108.0633214, 14.9685958],
                [108.0633412, 14.9685669],
                [108.0643749, 14.9681996],
                [108.0660714, 14.9677216],
                [108.0669056, 14.9676134],
                [108.0678625, 14.9674829],
                [108.0685205, 14.9672816],
                [108.0689958, 14.9669979],
                [108.0693752, 14.9670146],
                [108.0699352, 14.9672598],
                [108.0703222, 14.9676217],
                [108.0707117, 14.9677933],
                [108.0712256, 14.9678236],
                [108.0722782, 14.967861],
                [108.0731553, 14.9682053],
                [108.0739827, 14.9685966],
                [108.0747412, 14.9686539],
                [108.0755268, 14.9685213],
                [108.0763378, 14.9683175],
                [108.0766421, 14.9683103],
                [108.0778253, 14.9678964],
                [108.0793284, 14.9672256],
                [108.0804111, 14.9668588],
                [108.0813446, 14.9666566],
                [108.082277, 14.9665258],
                [108.0826943, 14.9664598],
                [108.0829907, 14.9662732],
                [108.0832156, 14.965943],
                [108.0834431, 14.9654224],
                [108.0838904, 14.9649522],
                [108.0862278, 14.963257],
                [108.0871432, 14.9625787],
                [108.0869482, 14.9606963],
                [108.0867859, 14.959112],
                [108.0868442, 14.9584227],
                [108.0869028, 14.9577097],
                [108.086646, 14.9567784],
                [108.0864581, 14.9561811],
                [108.0864773, 14.9556698],
                [108.0866058, 14.9552194],
                [108.0868313, 14.9548415],
                [108.0869103, 14.954438],
                [108.0868184, 14.9539848],
                [108.086752, 14.9534605],
                [108.0869791, 14.9529637],
                [108.08758, 14.9519958],
                [108.0881384, 14.9514437],
                [108.0885316, 14.9513535],
                [108.089511, 14.9513661],
                [108.0908066, 14.9515491],
                [108.0923705, 14.951807],
                [108.0929318, 14.951957],
                [108.0944652, 14.9526665],
                [108.0948795, 14.9528145],
                [108.0957493, 14.95279],
                [108.0965615, 14.952491],
                [108.0970045, 14.95233],
                [108.0975689, 14.952242],
                [108.098766, 14.9524714],
                [108.0996465, 14.9525539],
                [108.100308, 14.9525386],
                [108.1010455, 14.9523338],
                [108.1017591, 14.9520811],
                [108.1028152, 14.9518566],
                [108.1048165, 14.9514656],
                [108.105871, 14.95136],
                [108.1071689, 14.9513765],
                [108.1074614, 14.9514753],
                [108.1077514, 14.9517645],
                [108.1084469, 14.9528679],
                [108.1089029, 14.9535638],
                [108.1092636, 14.9540679],
                [108.1095548, 14.954262],
                [108.1099943, 14.9543627],
                [108.110691, 14.9544549],
                [108.1112047, 14.9545089],
                [108.1116671, 14.954729],
                [108.1120544, 14.955067],
                [108.1134861, 14.9560607],
                [108.1151385, 14.9570334],
                [108.1156966, 14.9574212],
                [108.1158659, 14.9575899],
                [108.1163467, 14.9582622],
                [108.1168076, 14.9586012],
                [108.1180851, 14.9592241],
                [108.1194506, 14.9596696],
                [108.1210867, 14.9600472],
                [108.1226513, 14.9602573],
                [108.1236308, 14.9602697],
                [108.1243141, 14.9602251],
                [108.1244927, 14.9602135],
                [108.125107, 14.9600817],
                [108.1252122, 14.9600806],
                [108.125244, 14.9600811],
                [108.1261783, 14.9600775],
                [108.1269864, 14.9600878],
                [108.1276254, 14.9599292],
                [108.1281924, 14.9596509],
                [108.1285455, 14.9593734],
                [108.1287883, 14.9590398],
                [108.1289142, 14.9587795],
                [108.1290872, 14.9586627],
                [108.1293575, 14.9585947],
                [108.1296521, 14.9585509],
                [108.1301424, 14.9585095],
                [108.1305833, 14.958515],
                [108.1314407, 14.9585021],
                [108.1323353, 14.9584537],
                [108.1328737, 14.9584843],
                [108.1332649, 14.9585369],
                [108.1337766, 14.9587336],
                [108.1342142, 14.9589771],
                [108.1346738, 14.9594111],
                [108.1352301, 14.9599416],
                [108.1364877, 14.9611471],
                [108.1369005, 14.9614141],
                [108.1379005, 14.9617361],
                [108.1386828, 14.9618409],
                [108.1414575, 14.9622445],
                [108.1427293, 14.9623796],
                [108.1439531, 14.9624424],
                [108.1446875, 14.9624755],
                [108.1460337, 14.9625399],
                [108.147983, 14.9623858],
                [108.1486445, 14.9623703],
                [108.1492316, 14.9624252],
                [108.149597, 14.9625726],
                [108.150349, 14.9631293],
                [108.1514359, 14.964285],
                [108.1524754, 14.9653212],
                [108.1536123, 14.9664062],
                [108.1546922, 14.9671692],
                [108.1551041, 14.9675075],
                [108.1554395, 14.9680828],
                [108.1556037, 14.9686321],
                [108.1555753, 14.9689174],
                [108.1556687, 14.9692754],
                [108.156128, 14.9697333],
                [108.1570002, 14.970458],
                [108.1579192, 14.9713499],
                [108.1586858, 14.9717283],
                [108.1600507, 14.972245],
                [108.1606115, 14.9724424],
                [108.1616573, 14.9730027],
                [108.1621893, 14.9735329],
                [108.1628546, 14.9742698],
                [108.1630584, 14.9744955],
                [108.1630553, 14.9747334],
                [108.1628292, 14.9751589],
                [108.1624298, 14.975725],
                [108.1613514, 14.9766991],
                [108.1608315, 14.977121],
                [108.1602362, 14.9776847],
                [108.1599093, 14.978323],
                [108.1593338, 14.9792439],
                [108.1590238, 14.9804536],
                [108.1586686, 14.9823171],
                [108.1584783, 14.9837425],
                [108.1583932, 14.9846219],
                [108.158252, 14.985108],
                [108.1580935, 14.9859864],
                [108.1579647, 14.9864606],
                [108.1577858, 14.9870296],
                [108.1576799, 14.9876231],
                [108.1575751, 14.9881452],
                [108.157489, 14.9890961],
                [108.1574072, 14.9906536],
                [108.1573506, 14.991224],
                [108.1572206, 14.9917935],
                [108.1570149, 14.9925285],
                [108.1567864, 14.9931444],
                [108.1565736, 14.9934868],
                [108.1562002, 14.9939342],
                [108.1559499, 14.9943356],
                [108.1558209, 14.9948337],
                [108.1558114, 14.9955475],
                [108.1557794, 14.9961181],
                [108.1557724, 14.9966416],
                [108.1556688, 14.9970686],
                [108.1554917, 14.9974946],
                [108.1552544, 14.9978368],
                [108.1545617, 14.9983516],
                [108.1541846, 14.998544],
                [108.1532557, 14.9989741],
                [108.1518863, 15.0002654],
                [108.1500413, 15.0018193],
                [108.1501575, 15.0022765],
                [108.1503077, 15.0028679],
                [108.1521601, 15.0087351],
                [108.1521716, 15.0093236],
                [108.1516524, 15.0106293],
                [108.1515232, 15.0116399],
                [108.1514761, 15.0122766],
                [108.1511881, 15.0136227],
                [108.1498319, 15.016905],
                [108.1494852, 15.0183065],
                [108.1493055, 15.0202163],
                [108.1492098, 15.0216022],
                [108.1491633, 15.0222016],
                [108.1491984, 15.0224644],
                [108.150572, 15.0236814],
                [108.1513281, 15.0248906],
                [108.152369, 15.0264595],
                [108.1527841, 15.027177],
                [108.1528147, 15.0277772],
                [108.1525752, 15.0283741],
                [108.151481, 15.0293727],
                [108.1507767, 15.0300762],
                [108.1502559, 15.0314943],
                [108.1498133, 15.0328384],
                [108.1498209, 15.0337196],
                [108.1505791, 15.0347788],
                [108.1516801, 15.0361799],
                [108.1519023, 15.036895],
                [108.1516993, 15.0376422],
                [108.1501659, 15.0397224],
                [108.1494922, 15.0410262],
                [108.1482956, 15.0424545],
                [108.1469661, 15.0437126],
                [108.1464885, 15.0447938],
                [108.146284, 15.0456535],
                [108.1477469, 15.0459719],
                [108.1483996, 15.0462425],
                [108.1487416, 15.0466591],
                [108.148831, 15.0486473],
                [108.1489113, 15.0498668],
                [108.1490983, 15.0503191],
                [108.1497846, 15.050965],
                [108.1504415, 15.0509358],
                [108.1516353, 15.0511757],
                [108.1531659, 15.0522072],
                [108.1556187, 15.0535876],
                [108.1569184, 15.0545787],
                [108.1574885, 15.0552607],
                [108.157968, 15.0569538],
                [108.1583968, 15.0581027],
                [108.1591936, 15.0591624],
                [108.1599577, 15.0597718],
                [108.1611466, 15.0603866],
                [108.1625361, 15.0610225],
                [108.1629561, 15.0607841],
                [108.1643588, 15.0598269],
                [108.1653636, 15.0597646],
                [108.1664431, 15.0598905],
                [108.1682442, 15.0609252],
                [108.1700835, 15.061998],
                [108.1708771, 15.0618392],
                [108.1739389, 15.0609775],
                [108.175955, 15.0603653],
                [108.1768439, 15.0603014],
                [108.1776655, 15.0594867],
                [108.1787598, 15.0584881],
                [108.1807416, 15.057538],
                [108.1821799, 15.0568061],
                [108.1828607, 15.0564209],
                [108.1837134, 15.056169],
                [108.1842604, 15.0556883],
                [108.1848547, 15.0545335],
                [108.1856787, 15.0535315],
                [108.186733, 15.0526447],
                [108.1884063, 15.0516532],
                [108.189847, 15.0507337],
                [108.1905503, 15.0501051],
                [108.1918431, 15.0487325],
                [108.1921652, 15.0477247],
                [108.1921364, 15.046975],
                [108.1919508, 15.0464106],
                [108.1918041, 15.0458093],
                [108.1920427, 15.0452877],
                [108.1929865, 15.0439879],
                [108.19397, 15.0426136],
                [108.1941319, 15.0420536],
                [108.1941758, 15.0416419],
                [108.1942228, 15.0410056],
                [108.1942287, 15.0405559],
                [108.1943459, 15.040445],
                [108.194539, 15.0404474],
                [108.1957326, 15.0407243],
                [108.1964994, 15.041146],
                [108.1968699, 15.0423496],
                [108.1979769, 15.0433376],
                [108.1993572, 15.0441041],
                [108.1996681, 15.043958],
                [108.2010739, 15.0427763],
                [108.2022585, 15.0422543],
                [108.202804, 15.0418861],
                [108.2032786, 15.0410293],
                [108.2042185, 15.0400284],
                [108.2054213, 15.0395933],
                [108.2059287, 15.039187],
                [108.2068676, 15.0382609],
                [108.2074531, 15.0377807],
                [108.2081701, 15.0375833],
                [108.2084043, 15.0373986],
                [108.2087307, 15.0360526],
                [108.2092141, 15.034521],
                [108.2096504, 15.0336264],
                [108.2101989, 15.0330331],
                [108.2119466, 15.032267],
                [108.2127227, 15.0319765],
                [108.2131088, 15.0319813],
                [108.2150306, 15.0326799],
                [108.2158024, 15.0327268],
                [108.2168136, 15.0321767],
                [108.2192304, 15.0304437],
                [108.2202827, 15.0297066],
                [108.2210514, 15.0299785],
                [108.2224527, 15.0306145],
                [108.2228769, 15.0306572],
                [108.2237356, 15.0299552],
                [108.2242801, 15.0296617],
                [108.2247067, 15.0295169],
                [108.2255571, 15.0294524],
                [108.2259795, 15.029645],
                [108.2275055, 15.0310887],
                [108.2290389, 15.03197],
                [108.2303091, 15.0322854],
                [108.2321616, 15.032383],
                [108.2328966, 15.0322795],
                [108.2336522, 15.0320824],
                [108.2346195, 15.0319442],
                [108.2355066, 15.03203],
                [108.2364351, 15.0318913],
                [108.2375936, 15.0319054],
                [108.2381504, 15.0320138],
                [108.2386311, 15.0315094],
                [108.2393216, 15.0309489],
                [108.239832, 15.0319163],
                [108.2403468, 15.0326985],
                [108.2410133, 15.0341949],
                [108.2413172, 15.0346734],
                [108.2417967, 15.0353974],
                [108.2424117, 15.0362793],
                [108.2427012, 15.0369429],
                [108.2427924, 15.0372682],
                [108.2428585, 15.0381666],
                [108.2430905, 15.0396058],
                [108.2432895, 15.0408125],
                [108.2433167, 15.0414845],
                [108.2432904, 15.0416812],
                [108.2432532, 15.0417849],
                [108.243144, 15.0419225],
                [108.2428783, 15.0421857],
                [108.2416888, 15.0433237],
                [108.2406631, 15.0442493],
                [108.2390785, 15.0455098],
                [108.2380289, 15.0464351],
                [108.23775, 15.0467792],
                [108.2376147, 15.0471017],
                [108.2376074, 15.0472233],
                [108.2376285, 15.0474321],
                [108.2376939, 15.0479077],
                [108.2379708, 15.0490865],
                [108.2386032, 15.05137],
                [108.2392359, 15.0536419],
                [108.2398251, 15.0556008],
                [108.2403455, 15.0573268],
                [108.2408424, 15.0590297],
                [108.2412533, 15.0609167],
                [108.2416528, 15.0627918],
                [108.2420781, 15.0640363],
                [108.2421801, 15.0644313],
                [108.2421789, 15.0645241],
                [108.242116, 15.0647896],
                [108.2420242, 15.0649681],
                [108.2417928, 15.0653356],
                [108.2413672, 15.0659676],
                [108.2408573, 15.0666679],
                [108.240789, 15.0668697],
                [108.2407122, 15.0672742],
                [108.2406098, 15.0678172],
                [108.2405545, 15.0683956],
                [108.2404721, 15.0687826],
                [108.2403857, 15.0690016],
                [108.2401662, 15.0693696],
                [108.2394428, 15.0704321],
                [108.2379052, 15.0726543],
                [108.2373215, 15.0735159],
                [108.2363762, 15.0751198],
                [108.2360443, 15.0758801],
                [108.2355804, 15.0771659],
                [108.2355279, 15.0775359],
                [108.2354886, 15.0778018],
                [108.2355337, 15.0780107],
                [108.2356033, 15.0781621],
                [108.2357315, 15.0783953],
                [108.2358844, 15.0785594],
                [108.2360966, 15.0787473],
                [108.2363342, 15.0788313],
                [108.2367736, 15.0789758],
                [108.2374825, 15.0790479],
                [108.2381254, 15.0791485],
                [108.238779, 15.0793418],
                [108.240457, 15.0801092],
                [108.2413336, 15.0805832],
                [108.243136, 15.0818848],
                [108.2440573, 15.0826025],
                [108.2450238, 15.083506],
                [108.2452844, 15.0836484],
                [108.2456055, 15.0837216],
                [108.2461416, 15.0837745],
                [108.2467798, 15.0837859],
                [108.2476032, 15.0837612],
                [108.2483782, 15.0837936],
                [108.2494028, 15.0838987],
                [108.250395, 15.0840725],
                [108.2511428, 15.0842929],
                [108.2517585, 15.0845648],
                [108.2527609, 15.0855861],
                [108.2541309, 15.0869],
                [108.2549376, 15.0877268],
                [108.2554222, 15.0885735],
                [108.2558357, 15.0891554],
                [108.2567014, 15.0899837],
                [108.2570711, 15.0903582],
                [108.2577214, 15.0912931],
                [108.2578156, 15.0915096],
                [108.2578181, 15.091594],
                [108.2578166, 15.0917151],
                [108.2577167, 15.0919292],
                [108.2569929, 15.0926678],
                [108.2566508, 15.0931113],
                [108.2565167, 15.0932915],
                [108.2564795, 15.0934865],
                [108.2565244, 15.0937697],
                [108.2568196, 15.094275],
                [108.256977, 15.0944451],
                [108.2574109, 15.0946725],
                [108.2579364, 15.0948001],
                [108.2582098, 15.0948473],
                [108.2583828, 15.0948695],
                [108.2584447, 15.0949107],
                [108.2584989, 15.0950123],
                [108.2585787, 15.0952759],
                [108.2586608, 15.0959097],
                [108.2587652, 15.09615],
                [108.2590739, 15.0965049],
                [108.2595169, 15.0970142],
                [108.2599508, 15.0975279],
                [108.2600386, 15.0977038],
                [108.2600338, 15.0980877],
                [108.2598363, 15.098607],
                [108.2595975, 15.0991125],
                [108.2595167, 15.0994616],
                [108.2594779, 15.0999598],
                [108.2594502, 15.1003159],
                [108.2594459, 15.1009253],
                [108.2593638, 15.1013822],
                [108.2592257, 15.1018719],
                [108.2592159, 15.1020939],
                [108.2592733, 15.1024918],
                [108.2596193, 15.1036272],
                [108.2598102, 15.10469],
                [108.2599386, 15.1057653],
                [108.2598906, 15.1059905],
                [108.2597603, 15.1064265],
                [108.2594002, 15.1069137],
                [108.2587454, 15.1079458],
                [108.2580698, 15.1089779],
                [108.2578795, 15.1094772],
                [108.2576492, 15.1101274],
                [108.2575693, 15.1106754],
                [108.2575698, 15.1111803],
                [108.2577049, 15.1122625],
                [108.2578739, 15.1134192],
                [108.2578442, 15.1143851],
                [108.2579159, 15.1155474],
                [108.2579713, 15.1158207],
                [108.2580794, 15.116051],
                [108.2584897, 15.1165071],
                [108.2587854, 15.1167059],
                [108.2597532, 15.117226],
                [108.2607666, 15.1177614],
                [108.2613629, 15.1182064],
                [108.2614753, 15.1182963],
                [108.2615662, 15.1183689],
                [108.2621552, 15.1189417],
                [108.2627328, 15.1198711],
                [108.2631744, 15.1211287],
                [108.2637638, 15.1233107],
                [108.2639325, 15.1236696],
                [108.2641665, 15.1240164],
                [108.2645942, 15.1243971],
                [108.2651954, 15.1248302],
                [108.2662023, 15.1256051],
                [108.2666183, 15.1258985],
                [108.2669103, 15.1260304],
                [108.2671225, 15.1260956],
                [108.267438, 15.1261559],
                [108.2680336, 15.126279],
                [108.2687854, 15.1265574],
                [108.2690892, 15.1267701],
                [108.269411, 15.1269907],
                [108.2700369, 15.1276435],
                [108.2702258, 15.127951],
                [108.2703547, 15.128189],
                [108.2704425, 15.1284659],
                [108.2705587, 15.1288908],
                [108.2707178, 15.1291489],
                [108.270828, 15.1292586],
                [108.2709694, 15.1293194],
                [108.2712028, 15.1293124],
                [108.2713812, 15.1292505],
                [108.2715349, 15.129144],
                [108.2716579, 15.129047],
                [108.2718315, 15.1289702],
                [108.2720147, 15.1289134],
                [108.272208, 15.1288862],
                [108.27236, 15.1288979],
                [108.2726642, 15.1289212],
                [108.2728976, 15.1289241],
                [108.2733542, 15.1289197],
                [108.273618, 15.1289328],
                [108.2737292, 15.1289637],
                [108.2738441, 15.1291079],
                [108.2738619, 15.1292953],
                [108.2738591, 15.1295119],
                [108.2738246, 15.1298365],
                [108.2738433, 15.1299648],
                [108.2739022, 15.1301035],
                [108.2739922, 15.130213],
                [108.274123, 15.1303031],
                [108.2744558, 15.1304648],
                [108.2748536, 15.1307109],
                [108.275216, 15.1309418],
                [108.2754375, 15.1310726],
                [108.2755576, 15.131202],
                [108.2756268, 15.1313506],
                [108.2756658, 15.131479],
                [108.2756643, 15.1315973],
                [108.275663, 15.1317057],
                [108.2756407, 15.1318532],
                [108.2755657, 15.1321576],
                [108.27545, 15.1324813],
                [108.2752422, 15.1332519],
                [108.2751882, 15.1335074],
                [108.2751752, 15.1337238],
                [108.275198, 15.133936],
                [108.2754354, 15.1344117],
                [108.2755814, 15.1348961],
                [108.2756503, 15.1350742],
                [108.275648, 15.1352516],
                [108.2756356, 15.1354187],
                [108.275505, 15.1357177],
                [108.2753091, 15.1359517],
                [108.2749085, 15.1363309],
                [108.2748248, 15.136527],
                [108.2748225, 15.1367042],
                [108.2748821, 15.1368133],
                [108.2751334, 15.1369936],
                [108.2754765, 15.1371355],
                [108.2760169, 15.137354],
                [108.2766444, 15.1374798],
                [108.2772814, 15.1376647],
                [108.2779379, 15.137909],
                [108.27823, 15.1380801],
                [108.2785049, 15.1384035],
                [108.2787445, 15.1387217],
                [108.2788823, 15.1390582],
                [108.2789694, 15.1393843],
                [108.2790176, 15.1395917],
                [108.2790294, 15.1398529],
                [108.2788729, 15.1401958],
                [108.2787697, 15.1403226],
                [108.2786157, 15.1404587],
                [108.2783705, 15.1405936],
                [108.2782273, 15.1406706],
                [108.2779523, 15.1407461],
                [108.2777686, 15.1408226],
                [108.2776863, 15.1409202],
                [108.2776749, 15.1410185],
                [108.2776928, 15.141196],
                [108.2777557, 15.1414383],
                [108.2778038, 15.1416455],
                [108.2777808, 15.1418522],
                [108.277687, 15.142048],
                [108.2774921, 15.142223],
                [108.2773177, 15.1423686],
                [108.2771335, 15.1424846],
                [108.2768178, 15.1425596],
                [108.2764312, 15.1426436],
                [108.2763189, 15.1426914],
                [108.2763076, 15.1427801],
                [108.276347, 15.142879],
                [108.2765025, 15.1430136],
                [108.2768039, 15.1432538],
                [108.2771968, 15.1434753],
                [108.277539, 15.1437158],
                [108.2778706, 15.1439661],
                [108.278022, 15.1440369],
                [108.2781335, 15.1440383],
                [108.2782764, 15.143971],
                [108.2785205, 15.1439344],
                [108.2789724, 15.1439152],
                [108.2793681, 15.14392],
                [108.2798851, 15.143966],
                [108.2802603, 15.1439901],
                [108.2806456, 15.1440145],
                [108.2809496, 15.1440673],
                [108.2812635, 15.1441105],
                [108.2814346, 15.1442307],
                [108.2814636, 15.1443294],
                [108.2814506, 15.144556],
                [108.2814475, 15.1448021],
                [108.2813768, 15.1451805],
                [108.2813706, 15.1456533],
                [108.2814246, 15.1461958],
                [108.2815611, 15.1466307],
                [108.281704, 15.1469625],
                [108.2819047, 15.1471521],
                [108.2821168, 15.1472236],
                [108.2822993, 15.1472453],
                [108.2825934, 15.1472586],
                [108.2828257, 15.1473503],
                [108.2831267, 15.1476199],
                [108.2834157, 15.148037],
                [108.2837295, 15.1485087],
                [108.2839751, 15.1491323],
                [108.2841208, 15.1496462],
                [108.2841958, 15.1501297],
                [108.2842484, 15.1503718],
                [108.2842455, 15.1506082],
                [108.2842439, 15.1507361],
                [108.28421, 15.1510016],
                [108.2841358, 15.1512569],
                [108.2840002, 15.151541],
                [108.2837975, 15.1519176],
                [108.283478, 15.1522881],
                [108.2832816, 15.1525714],
                [108.2832373, 15.1528566],
                [108.2832358, 15.1529846],
                [108.2833245, 15.1531826],
                [108.2835458, 15.1533528],
                [108.2837671, 15.1535031],
                [108.28408, 15.1536448],
                [108.2843783, 15.1537224],
                [108.2846221, 15.1537055],
                [108.2852426, 15.1536047],
                [108.2855784, 15.15352],
                [108.2858939, 15.1534549],
                [108.2862496, 15.1534199],
                [108.2864219, 15.1534416],
                [108.2865429, 15.1535021],
                [108.2866633, 15.1536217],
                [108.2867427, 15.153741],
                [108.2868014, 15.1539289],
                [108.2868347, 15.1541015],
                [108.2868311, 15.1543773],
                [108.286818, 15.1546137],
                [108.2867933, 15.1549582],
                [108.2867692, 15.1552533],
                [108.2866884, 15.1556118],
                [108.2867053, 15.1558878],
                [108.2867629, 15.1561447],
                [108.286902, 15.1563827],
                [108.2871225, 15.1566021],
                [108.2872729, 15.1567417],
                [108.2875149, 15.1568729],
                [108.2878476, 15.1570541],
                [108.2880997, 15.1571752],
                [108.288661, 15.1573249],
                [108.2890448, 15.1574772],
                [108.289389, 15.1575503],
                [108.2897837, 15.1576241],
                [108.2900469, 15.1576862],
                [108.2901679, 15.1577567],
                [108.2902474, 15.1578759],
                [108.2902564, 15.1579745],
                [108.290245, 15.158063],
                [108.2902033, 15.1581709],
                [108.2900788, 15.1583663],
                [108.2899236, 15.1586009],
                [108.289764, 15.1587813],
                [108.2895875, 15.1590942],
                [108.289461, 15.159467],
                [108.2893656, 15.1597811],
                [108.2893529, 15.1599779],
                [108.2893505, 15.160175],
                [108.2894182, 15.1604319],
                [108.2895217, 15.1606745],
                [108.2896925, 15.1608046],
                [108.2899138, 15.1609648],
                [108.2901459, 15.1610661],
                [108.2904994, 15.1612083],
                [108.2907623, 15.1612903],
                [108.2910853, 15.161432],
                [108.2913268, 15.1615925],
                [108.2914668, 15.1617618],
                [108.2916007, 15.1620045],
                [108.2917389, 15.1623116],
                [108.2918773, 15.162599],
                [108.2920561, 15.1628966],
                [108.2925687, 15.1636956],
                [108.2929582, 15.164183],
                [108.293349, 15.1645915],
                [108.2935584, 15.1648798],
                [108.2936919, 15.1651504],
                [108.2937999, 15.165447],
                [108.2938668, 15.1657725],
                [108.2939446, 15.1660392],
                [108.2939928, 15.166332],
                [108.2940332, 15.1664545],
                [108.294363, 15.1669266],
                [108.2948563, 15.1677261],
                [108.2953944, 15.1683226],
                [108.2959757, 15.1688182],
                [108.2969334, 15.1694197],
                [108.297475, 15.1697314],
                [108.2977255, 15.1698363],
                [108.2978507, 15.1698989],
                [108.2980815, 15.1699016],
                [108.2983984, 15.1697426],
                [108.2986099, 15.1696027],
                [108.298926, 15.1695046],
                [108.2991784, 15.169467],
                [108.2993884, 15.1694493],
                [108.2995972, 15.1695331],
                [108.2998242, 15.1698411],
                [108.3000091, 15.1701485],
                [108.3002787, 15.1703958],
                [108.3007163, 15.1706454],
                [108.3014688, 15.1708783],
                [108.3021503, 15.1709209],
                [108.3027581, 15.1709892],
                [108.3032199, 15.1709948],
                [108.303808, 15.1709407],
                [108.3041658, 15.1708636],
                [108.3044791, 15.1709894],
                [108.3048113, 15.1712583],
                [108.3052205, 15.1720984],
                [108.3055013, 15.17312],
                [108.3056648, 15.1734682],
                [108.3059129, 15.1737385],
                [108.3059861, 15.1738183],
                [108.3053058, 15.1751518],
                [108.3048949, 15.1761036],
                [108.3046365, 15.1766094],
                [108.3041443, 15.1773566],
                [108.3035885, 15.1781437],
                [108.3033875, 15.1783669],
                [108.3026394, 15.1792176],
                [108.3019268, 15.1799579],
                [108.3004989, 15.1812723],
                [108.3002313, 15.1815722],
                [108.3001562, 15.181709],
                [108.3001156, 15.1818411],
                [108.3001229, 15.1820307],
                [108.3002382, 15.1821742],
                [108.3004498, 15.1824327],
                [108.3006416, 15.1827098],
                [108.300773, 15.1831284],
                [108.3007824, 15.1835503],
                [108.3007475, 15.1839859],
                [108.300727, 15.1840613],
                [108.300735, 15.1842038],
                [108.3007736, 15.1843111],
                [108.3008142, 15.1844035],
                [108.3010589, 15.1848893],
                [108.30143, 15.1854639],
                [108.3021773, 15.1861757],
                [108.3031028, 15.1868273],
                [108.3038532, 15.1873206],
                [108.3040561, 15.1878073],
                [108.3040542, 15.1879636],
                [108.3040205, 15.188088],
                [108.3039215, 15.1882743],
                [108.3034312, 15.1888621],
                [108.3029888, 15.1894659],
                [108.3028559, 15.1897924],
                [108.3028276, 15.1901124],
                [108.3028547, 15.1905187],
                [108.3029881, 15.1914108],
                [108.3029685, 15.1916916],
                [108.3027857, 15.1921424],
                [108.3025157, 15.1924877],
                [108.301216, 15.1934243],
                [108.2994043, 15.1940891],
                [108.2984036, 15.1943113],
                [108.2979532, 15.1942748],
                [108.2972945, 15.1941731],
                [108.2963316, 15.193943],
                [108.2958487, 15.1939372],
                [108.2952033, 15.1940542],
                [108.2944094, 15.1944505],
                [108.2937878, 15.1950232],
                [108.2919742, 15.1967817],
                [108.2909954, 15.198169],
                [108.2896131, 15.2003566],
                [108.2891218, 15.2011984],
                [108.2891174, 15.2015374],
                [108.2892027, 15.2017081],
                [108.2895493, 15.2019241],
                [108.2899829, 15.2021837],
                [108.2906796, 15.2023617],
                [108.2912033, 15.2024104],
                [108.2917694, 15.2025443],
                [108.2930799, 15.2025602],
                [108.2940823, 15.2027419],
                [108.2949908, 15.2030124],
                [108.2961014, 15.2032907],
                [108.2972925, 15.2036707],
                [108.2977379, 15.2038562],
                [108.2980831, 15.2041888],
                [108.2989257, 15.2053488],
                [108.2993357, 15.2057459],
                [108.2998862, 15.2062507],
                [108.3012347, 15.2071411],
                [108.302581, 15.2077613],
                [108.3035456, 15.2083665],
                [108.3043406, 15.2089853],
                [108.3046851, 15.2093604],
                [108.3048238, 15.2096164],
                [108.3049714, 15.210042],
                [108.3050908, 15.210515],
                [108.3052498, 15.2117675],
                [108.3053932, 15.2125111],
                [108.3054837, 15.2126869],
                [108.3060636, 15.213478],
                [108.3064825, 15.2140236],
                [108.3067633, 15.2147106],
                [108.3070459, 15.2156782],
                [108.3071289, 15.2164477],
                [108.3071276, 15.217412],
                [108.3071856, 15.2179956],
                [108.3072696, 15.2182615],
                [108.3074043, 15.2184148],
                [108.3077353, 15.2190117],
                [108.3082367, 15.2199497],
                [108.3083931, 15.220476],
                [108.3085202, 15.2211808],
                [108.3085148, 15.2215983],
                [108.3084641, 15.2220152],
                [108.3081646, 15.2224072],
                [108.3076041, 15.2228511],
                [108.3068692, 15.2236334],
                [108.3066857, 15.2238289],
                [108.3066392, 15.2239165],
                [108.3066349, 15.2242459],
                [108.3066768, 15.2245102],
                [108.3068276, 15.2251273],
                [108.3069147, 15.2254141],
                [108.3069123, 15.22559],
                [108.3068885, 15.2256776],
                [108.3067512, 15.2257858],
                [108.3065022, 15.2257828],
                [108.3062536, 15.2257359],
                [108.3054164, 15.2256599],
                [108.3040788, 15.2257537],
                [108.3021074, 15.2257959],
                [108.3010082, 15.2258375],
                [108.3004609, 15.2259434],
                [108.300259, 15.2259824],
                [108.299782, 15.2260866],
                [108.2994164, 15.2263239],
                [108.2986838, 15.2269304],
                [108.297725, 15.2275122],
                [108.2958913, 15.2283144],
                [108.2943922, 15.2286259],
                [108.2932137, 15.2286776],
                [108.2921277, 15.2285766],
                [108.2907724, 15.2282746],
                [108.2895342, 15.2276661],
                [108.2877103, 15.226831],
                [108.2869886, 15.2265807],
                [108.2867159, 15.2266432],
                [108.2866234, 15.2267849],
                [108.2865907, 15.2271416],
                [108.28661, 15.2273946],
                [108.2866747, 15.2276591],
                [108.2871321, 15.2286372],
                [108.2884497, 15.2305375],
                [108.2898154, 15.2317957],
                [108.2900618, 15.2320186],
                [108.2901389, 15.2321841],
                [108.2901367, 15.23236],
                [108.2900716, 15.2325735],
                [108.2897489, 15.2330202],
                [108.2892631, 15.2337945],
                [108.2890685, 15.2339678],
                [108.2887044, 15.2340954],
                [108.2883579, 15.2341736],
                [108.2876529, 15.2343737],
                [108.2869815, 15.2346404],
                [108.2853444, 15.2355491],
                [108.2845759, 15.236287],
                [108.283738, 15.2376009],
                [108.2832007, 15.238869],
                [108.2827592, 15.2401658],
                [108.2822265, 15.2410713],
                [108.2816726, 15.2423009],
                [108.2815305, 15.2427936],
                [108.2815038, 15.243101],
                [108.2816125, 15.243454],
                [108.2818362, 15.2436764],
                [108.2820049, 15.2437774],
                [108.2822415, 15.2438792],
                [108.2828669, 15.244123],
                [108.283804, 15.2443541],
                [108.2847528, 15.2445633],
                [108.2853416, 15.2445814],
                [108.2861299, 15.2444977],
                [108.2865717, 15.2444811],
                [108.2874775, 15.2445249],
                [108.2878725, 15.2446396],
                [108.2891145, 15.2453852],
                [108.290306, 15.246092],
                [108.290968, 15.2465835],
                [108.2913965, 15.2471766],
                [108.2921469, 15.248724],
                [108.2928341, 15.2503422],
                [108.2930758, 15.2513725],
                [108.2933736, 15.2520024],
                [108.2937626, 15.2525674],
                [108.2942223, 15.2529466],
                [108.2946158, 15.2531822],
                [108.2951291, 15.2533477],
                [108.2957734, 15.2534434],
                [108.2966334, 15.2535196],
                [108.297107, 15.2536903],
                [108.2980912, 15.2542241],
                [108.299239, 15.2548204],
                [108.3000951, 15.2552042],
                [108.3009535, 15.2558353],
                [108.3021402, 15.2569376],
                [108.3027336, 15.2574722],
                [108.3029838, 15.2578324],
                [108.3036147, 15.2594389],
                [108.304319, 15.2614966],
                [108.304729, 15.2635508],
                [108.3051774, 15.265243],
                [108.3053959, 15.2658829],
                [108.3060547, 15.2670611],
                [108.306195, 15.2676232],
                [108.3061797, 15.2679198],
                [108.3060276, 15.2683025],
                [108.305703, 15.268881],
                [108.3055927, 15.2690939],
                [108.3055433, 15.2694121],
                [108.3054966, 15.2708456],
                [108.3054912, 15.272164],
                [108.3054971, 15.2730266],
                [108.3053524, 15.2737063],
                [108.3052923, 15.2739802],
                [108.3051527, 15.2742643],
                [108.3047425, 15.2748911],
                [108.3037958, 15.2762863],
                [108.3032783, 15.2768844],
                [108.3025757, 15.2773429],
                [108.301948, 15.277698],
                [108.3013679, 15.2778886],
                [108.3007433, 15.2780131],
                [108.3004361, 15.2781083],
                [108.3002991, 15.2781946],
                [108.3002414, 15.2782706],
                [108.3002049, 15.2784681],
                [108.3002144, 15.2786111],
                [108.3002715, 15.2790129],
                [108.3004607, 15.2801689],
                [108.3004573, 15.2808776],
                [108.300388, 15.2818658],
                [108.3004014, 15.2830361],
                [108.3004862, 15.2834877],
                [108.3005597, 15.2836359],
                [108.3019356, 15.2848711],
                [108.3032469, 15.2859739],
                [108.3038764, 15.2863547],
                [108.304856, 15.2868276],
                [108.3059589, 15.2873641],
                [108.3079111, 15.2888532],
                [108.3095906, 15.2900885],
                [108.310804, 15.2910235],
                [108.311875, 15.2922876],
                [108.3126539, 15.2933333],
                [108.3134574, 15.2946208],
                [108.3139231, 15.2956629],
                [108.3144386, 15.2971525],
                [108.3146549, 15.2975303],
                [108.3152737, 15.2981276],
                [108.3155932, 15.2983548],
                [108.3158686, 15.2984474],
                [108.3162725, 15.2985596],
                [108.3169513, 15.2988],
                [108.317189, 15.2989458],
                [108.318958, 15.3003789],
                [108.3193871, 15.3006968],
                [108.3207459, 15.3010704],
                [108.3214829, 15.3010793],
                [108.3222769, 15.3009459],
                [108.3233876, 15.3005482],
                [108.325053, 15.2999964],
                [108.3256821, 15.2997894],
                [108.3263552, 15.2997527],
                [108.3274463, 15.2998061],
                [108.3278426, 15.2998019],
                [108.3285339, 15.2997654],
                [108.3292902, 15.2997119],
                [108.3298677, 15.2995803],
                [108.3309422, 15.2991195],
                [108.3316846, 15.2986996],
                [108.33233, 15.2983096],
                [108.3334511, 15.2978135],
                [108.3345893, 15.2974161],
                [108.3357786, 15.296988],
                [108.3374203, 15.2965027],
                [108.3378186, 15.2963287],
                [108.3381227, 15.2963234],
                [108.3383343, 15.2963528],
                [108.3385913, 15.2964273],
                [108.3390674, 15.2966653],
                [108.3398856, 15.2971603],
                [108.3407747, 15.2975279],
                [108.3414822, 15.2976794],
                [108.3421458, 15.2976694],
                [108.342782, 15.2976233],
                [108.3433103, 15.2975319],
                [108.3451052, 15.2966084],
                [108.3459277, 15.2964413],
                [108.3466579, 15.2963616],
                [108.3474898, 15.2963493],
                [108.3480313, 15.2963281],
                [108.3486704, 15.2962473],
                [108.3492188, 15.2961434],
                [108.350523, 15.2957168],
                [108.3511809, 15.2954981],
                [108.3517747, 15.2954056],
                [108.3520705, 15.2954312],
                [108.3530014, 15.2957076],
                [108.3540666, 15.2961732],
                [108.3543048, 15.2962535],
                [108.3547551, 15.2962422],
                [108.3555769, 15.2961302],
                [108.3572754, 15.2956144],
                [108.3590132, 15.2951486],
                [108.3600415, 15.2949288],
                [108.3614392, 15.294772],
                [108.3634161, 15.2943207],
                [108.3643528, 15.294133],
                [108.3646488, 15.2941474],
                [108.3647274, 15.2942367],
                [108.3647724, 15.2943746],
                [108.3649043, 15.2952179],
                [108.3650176, 15.2956911],
                [108.365112, 15.2958571],
                [108.3653574, 15.2960648],
                [108.3656486, 15.2962641],
                [108.3674687, 15.2971984],
                [108.3702664, 15.2985361],
                [108.3730643, 15.2998647],
                [108.3742438, 15.3004676],
                [108.3758991, 15.3011671],
                [108.3770678, 15.3016973],
                [108.3787129, 15.3026828],
                [108.3791706, 15.302795],
                [108.3802145, 15.3030211],
                [108.3809661, 15.3031368],
                [108.3818804, 15.3030895],
                [108.3826444, 15.3029382],
                [108.3830593, 15.3027917],
                [108.383516, 15.3026234],
                [108.3848282, 15.3019619],
                [108.3855107, 15.3017118],
                [108.3862606, 15.3015913],
                [108.3869696, 15.3014304],
                [108.3888842, 15.3006915],
                [108.3902698, 15.3000219],
                [108.3909544, 15.2996202],
                [108.3914146, 15.2991581],
                [108.391751, 15.298594],
                [108.3919211, 15.2983089],
                [108.3923214, 15.2978594],
                [108.3924784, 15.2977901],
                [108.3926899, 15.2977659],
                [108.3930388, 15.29777],
                [108.3938941, 15.2980248],
                [108.3963778, 15.2987706],
                [108.398826, 15.2994359],
                [108.399723, 15.2996691],
                [108.4011948, 15.2998241],
                [108.4021029, 15.299906],
                [108.4032652, 15.3002223],
                [108.4036484, 15.3003959],
                [108.4037348, 15.3004904],
                [108.4037325, 15.3006596],
                [108.4037026, 15.3008553],
                [108.4034934, 15.3018191],
                [108.4033251, 15.3024361],
                [108.4032572, 15.3030986],
                [108.4030387, 15.3040625],
                [108.4028082, 15.3048791],
                [108.4028011, 15.3054489],
                [108.4029405, 15.3060562],
                [108.4030831, 15.3067837],
                [108.4030137, 15.3075709],
                [108.4030009, 15.3078647],
                [108.4029964, 15.3082209],
                [108.4030297, 15.3085061],
                [108.4031014, 15.3086495],
                [108.4032287, 15.3087491],
                [108.4033929, 15.30884],
                [108.4037536, 15.309],
                [108.4049078, 15.3092362],
                [108.4058416, 15.3094518],
                [108.4066101, 15.3096922],
                [108.4071243, 15.3096982],
                [108.4073777, 15.3096255],
                [108.4077094, 15.3095314],
                [108.408944, 15.3088287],
                [108.4103139, 15.307954],
                [108.4113534, 15.3074364],
                [108.4117958, 15.3072989],
                [108.4120347, 15.307284],
                [108.4124478, 15.3072976],
                [108.4131822, 15.3073062],
                [108.4138404, 15.3071845],
                [108.4146828, 15.3066333],
                [108.41556, 15.3062338],
                [108.4160395, 15.3060701],
                [108.4163333, 15.3060735],
                [108.4166678, 15.3061441],
                [108.4170783, 15.3063626],
                [108.4184641, 15.3075409],
                [108.4204821, 15.3095992],
                [108.4213633, 15.3103754],
                [108.4225919, 15.3116586],
                [108.4234512, 15.3127193],
                [108.4243541, 15.3136071],
                [108.4249344, 15.3142192],
                [108.4250967, 15.3144527],
                [108.4254686, 15.3152007],
                [108.4257914, 15.3158366],
                [108.4262711, 15.3167996],
                [108.4268862, 15.3183028],
                [108.427193, 15.3191302],
                [108.4273825, 15.3197781],
                [108.4276072, 15.32129],
                [108.4278321, 15.3228023],
                [108.4279248, 15.3234802],
                [108.4281347, 15.3243465],
                [108.4283687, 15.3247411],
                [108.4285605, 15.3249599],
                [108.4298685, 15.3253147],
                [108.4309591, 15.3254786],
                [108.4320084, 15.3255181],
                [108.4328668, 15.3255212],
                [108.4333792, 15.3253758],
                [108.4340201, 15.3251768],
                [108.434437, 15.3247413],
                [108.4348867, 15.323921],
                [108.4351337, 15.3234561],
                [108.4354462, 15.3216779],
                [108.4356403, 15.320882],
                [108.4358405, 15.3201759],
                [108.4358625, 15.3195431],
                [108.4358279, 15.3188824],
                [108.4358127, 15.3172243],
                [108.4359039, 15.3167163],
                [108.4364562, 15.3150647],
                [108.4368513, 15.3140785],
                [108.4372221, 15.3133332],
                [108.4374249, 15.3130052],
                [108.4379979, 15.312544],
                [108.4388685, 15.3121274],
                [108.4397147, 15.3119513],
                [108.4404826, 15.3118088],
                [108.4411488, 15.311844],
                [108.4417722, 15.3119199],
                [108.4426215, 15.3120811],
                [108.4432658, 15.3121779],
                [108.4443885, 15.3120256],
                [108.4460076, 15.311879],
                [108.4471771, 15.3119543],
                [108.4477732, 15.3119335],
                [108.4481283, 15.3118963],
                [108.4485139, 15.3116807],
                [108.4497019, 15.3108275],
                [108.4503393, 15.3103326],
                [108.451011, 15.3099275],
                [108.4513103, 15.3098071],
                [108.4514521, 15.3098087],
                [108.4517359, 15.309812],
                [108.4521454, 15.3099679],
                [108.4524972, 15.3102058],
                [108.4529362, 15.310266],
                [108.4533334, 15.3102705],
                [108.4545366, 15.3099058],
                [108.4557744, 15.3096174],
                [108.4564427, 15.3095011],
                [108.4569817, 15.3095072],
                [108.4577958, 15.3096472],
                [108.4583343, 15.3096945],
                [108.4589025, 15.3096322],
                [108.4595586, 15.309337],
                [108.4602721, 15.3090012],
                [108.4608124, 15.3088972],
                [108.4619121, 15.3088892],
                [108.4625222, 15.3088824],
                [108.4632187, 15.3087664],
                [108.4637453, 15.3086348],
                [108.4640149, 15.3086241],
                [108.4642417, 15.3086405],
                [108.4648132, 15.3088877],
                [108.4654449, 15.309459],
                [108.4659252, 15.3096295],
                [108.4666482, 15.309679],
                [108.4675849, 15.3096484],
                [108.4685269, 15.3097623],
                [108.4693224, 15.3096749],
                [108.4702756, 15.3094519],
                [108.4708583, 15.309376],
                [108.4714401, 15.3093687],
                [108.472057, 15.3093827],
                [108.4728913, 15.3096123],
                [108.4735991, 15.3097441],
                [108.4741234, 15.3097912],
                [108.4747131, 15.3097223],
                [108.4750544, 15.3096573],
                [108.4753523, 15.3096469],
                [108.4755362, 15.3096902],
                [108.4759872, 15.3099431],
                [108.4765622, 15.3105],
                [108.4771121, 15.3107814],
                [108.4773809, 15.3108532],
                [108.4774944, 15.3108545],
                [108.4776084, 15.3108008],
                [108.4777116, 15.3104854],
                [108.4777873, 15.3100872],
                [108.4779758, 15.3097454],
                [108.47858, 15.3090505],
                [108.479633, 15.3082024],
                [108.4804038, 15.3078121],
                [108.4812724, 15.3075468],
                [108.4817978, 15.3074978],
                [108.4828625, 15.3074408],
                [108.4838769, 15.3074454],
                [108.484713, 15.3075374],
                [108.4856743, 15.3078098],
                [108.4868891, 15.3082636],
                [108.4877102, 15.3089953],
                [108.4886475, 15.3101065],
                [108.4888839, 15.3105082],
                [108.4897457, 15.3114053],
                [108.4912502, 15.3125505],
                [108.4927088, 15.3133719],
                [108.4934862, 15.3136145],
                [108.4939523, 15.3137849],
                [108.4943887, 15.314065],
                [108.4951574, 15.3150504],
                [108.4958328, 15.3161105],
                [108.4961542, 15.316527],
                [108.4969823, 15.3172794],
                [108.4974108, 15.3176349],
                [108.4976626, 15.3179268],
                [108.4978563, 15.3183554],
                [108.498238, 15.3196532],
                [108.4983908, 15.3205286],
                [108.4984961, 15.3212178],
                [108.4986326, 15.3216596],
                [108.4991395, 15.322594],
                [108.499919, 15.3238547],
                [108.5002154, 15.3241263],
                [108.501354, 15.3247283],
                [108.5032495, 15.325719],
                [108.5045653, 15.3263005],
                [108.5056456, 15.3266482],
                [108.5059988, 15.326697],
                [108.5064454, 15.3266571],
                [108.5069091, 15.3264684],
                [108.5085528, 15.3253382],
                [108.5105583, 15.32419],
                [108.5114113, 15.3235878],
                [108.5124802, 15.3229584],
                [108.5133984, 15.3226928],
                [108.5146766, 15.3225578],
                [108.5160151, 15.3225281],
                [108.5175371, 15.3225897],
                [108.5196546, 15.322367],
                [108.5207638, 15.3222154],
                [108.522187, 15.3215151],
                [108.5242218, 15.3204863],
                [108.5251173, 15.320198],
                [108.5256722, 15.3200998],
                [108.5258254, 15.3201461],
                [108.5265354, 15.320594],
                [108.5277677, 15.3217412],
                [108.5288632, 15.3227527],
                [108.5311346, 15.325142],
                [108.5324747, 15.3269393],
                [108.532996, 15.3277282],
                [108.533356, 15.3285078],
                [108.5335637, 15.3291664],
                [108.533728, 15.3295709],
                [108.5339699, 15.3299316],
                [108.5341694, 15.3299785],
                [108.5343396, 15.3298909],
                [108.5354415, 15.3290528],
                [108.5361089, 15.3285531],
                [108.536465, 15.3283482],
                [108.5369118, 15.3282786],
                [108.5377571, 15.3283477],
                [108.5382022, 15.3284272],
                [108.5387239, 15.3285374],
                [108.5391677, 15.3287362],
                [108.5398553, 15.3291166],
                [108.5414586, 15.3301188],
                [108.5428638, 15.3317227],
                [108.543101, 15.3330294],
                [108.5443721, 15.3350672],
                [108.5454956, 15.3354438],
                [108.5468744, 15.3354185],
                [108.5482594, 15.3348671],
                [108.5497678, 15.334479],
                [108.5508889, 15.335058],
                [108.5520072, 15.3358799],
                [108.553217, 15.3360143],
                [108.5554463, 15.3347034],
                [108.5573766, 15.3339553],
                [108.5595918, 15.3338582],
                [108.5616272, 15.3348519],
                [108.5652818, 15.3367133],
                [108.5685199, 15.3384487],
                [108.5705527, 15.339685],
                [108.5723333, 15.3410402],
                [108.5734525, 15.3417808],
                [108.5745731, 15.341991],
                [108.5748282, 15.3420388],
                [108.5764474, 15.3429063],
                [108.577528, 15.3434038],
                [108.5810654, 15.3445756],
                [108.5820252, 15.3446669],
                [108.584117, 15.3444063],
                [108.5856291, 15.3436942],
                [108.5863494, 15.3428115],
                [108.5869046, 15.3417652],
                [108.5883081, 15.3432376],
                [108.5911423, 15.3437944],
                [108.5951445, 15.3445256],
                [108.5984811, 15.345007],
                [108.6011509, 15.3453596],
                [108.6021869, 15.3460992],
                [108.6022643, 15.3466262],
                [108.6019966, 15.3481211],
                [108.6014801, 15.3494512],
                [108.6015069, 15.3507467],
                [108.6017475, 15.3516397],
                [108.6023249, 15.3522935],
                [108.6034922, 15.3525084],
                [108.604873, 15.352321],
                [108.6063806, 15.3520133],
                [108.6091743, 15.3524887],
                [108.6105886, 15.3530299],
                [108.6110454, 15.3532778],
                [108.6129288, 15.3529742],
                [108.6147688, 15.352832],
                [108.615764, 15.3534904],
                [108.6169231, 15.3544336],
                [108.6177984, 15.3546049],
                [108.6190082, 15.3547798],
                [108.6215132, 15.3549281],
                [108.6229689, 15.3555102],
                [108.6248785, 15.356583],
                [108.6259173, 15.3570799],
                [108.6266693, 15.3570878],
                [108.6282986, 15.3571053],
                [108.6295477, 15.3574829],
                [108.6316651, 15.3586792],
                [108.6326446, 15.3607136],
                [108.6337549, 15.3623039],
                [108.6349603, 15.362843],
                [108.6385055, 15.3634067],
                [108.6397961, 15.3638251],
                [108.6404094, 15.3650056],
                [108.6411803, 15.3670376],
                [108.6416306, 15.367852],
                [108.6425916, 15.3678622],
                [108.6436371, 15.3677922],
                [108.6448917, 15.367684],
                [108.6481676, 15.3661807],
                [108.649549, 15.3659524],
                [108.6509196, 15.3666955],
                [108.6520039, 15.3668688],
                [108.6539271, 15.3667676],
                [108.655135, 15.3671043],
                [108.6557752, 15.3658967],
                [108.6574935, 15.365429],
                [108.6587877, 15.3655236],
                [108.6601546, 15.3665906],
                [108.6613899, 15.3682225],
                [108.6618763, 15.3695634],
                [108.6624724, 15.3685578],
                [108.6638729, 15.3666295],
                [108.6655135, 15.3656349],
                [108.6685291, 15.3649783],
                [108.6715889, 15.3641199],
                [108.6738027, 15.3660306],
                [108.6752866, 15.3676999],
                [108.6767672, 15.3682933],
                [108.6783674, 15.3678102],
                [108.6788613, 15.3661006],
                [108.6835869, 15.3673215],
                [108.6857544, 15.3677894],
                [108.6861674, 15.3682389],
                [108.6869984, 15.3686524],
                [108.6893862, 15.3681106],
                [108.6915172, 15.3680922],
                [108.6933498, 15.3686375],
                [108.6944298, 15.3692154],
                [108.6950476, 15.3700313],
                [108.6963849, 15.3700048],
                [108.6980562, 15.370022],
                [108.6995126, 15.3705634],
                [108.7004247, 15.3712203],
                [108.7012029, 15.3726451],
                [108.7032559, 15.3721402],
                [108.7053516, 15.3715548],
                [108.7071539, 15.3710471],
                [108.7090776, 15.370905],
                [108.7102741, 15.3706678],
                [108.7112133, 15.3704816],
                [108.7126733, 15.3706992],
                [108.7149211, 15.3714913],
                [108.7177938, 15.3724518],
                [108.7204033, 15.3734874],
                [108.7219916, 15.374519],
                [108.7231558, 15.375057],
                [108.7240564, 15.3755581],
                [108.7269354, 15.3771602],
                [108.7266184, 15.3812709],
                [108.7287006, 15.3839929],
                [108.7306009, 15.3865645],
                [108.7325602, 15.388849],
                [108.7341329, 15.390878],
                [108.7355228, 15.3926535],
                [108.7361411, 15.3937741],
                [108.736576, 15.3947131],
                [108.736827, 15.3955065],
                [108.7373704, 15.3966983],
                [108.738064, 15.3977118],
                [108.7391188, 15.3992599],
                [109.0235567, 15.7972884],
                [108.6563747, 16.1663171],
                [108.2895709, 15.9674041],
                [108.285911, 15.9655679],
                [108.2800323, 15.9614787],
                [108.279174, 15.9616514],
                [108.2788789, 15.96185],
                [108.2785276, 15.9615741],
                [108.2783022, 15.9618448],
                [108.2780313, 15.9617004],
                [108.2777658, 15.9617262],
                [108.2778114, 15.9621234],
                [108.2753438, 15.9625102],
                [108.274751, 15.9624999],
                [108.2747188, 15.9623297],
                [108.274515, 15.9624586],
                [108.2743098, 15.9625734],
                [108.2742843, 15.9624148],
                [108.2739598, 15.9624328],
                [108.2738176, 15.9622575],
                [108.2736865, 15.9622932],
                [108.2734206, 15.9623503],
                [108.2732748, 15.9623732],
                [108.2729807, 15.9624019],
                [108.2729378, 15.9618087],
                [108.2724617, 15.9611021],
                [108.2722445, 15.961155],
                [108.272042, 15.9607708],
                [108.2719119, 15.9607979],
                [108.2717711, 15.9600861],
                [108.2715672, 15.9600861],
                [108.2712561, 15.960148],
                [108.2706016, 15.9601686],
                [108.2702073, 15.9598566],
                [108.2697621, 15.959787],
                [108.2697464, 15.9598702],
                [108.269695, 15.9600319],
                [108.2696648, 15.9600991],
                [108.2695904, 15.9602266],
                [108.2694375, 15.960215],
                [108.2690205, 15.9614993],
                [108.2693088, 15.9615973],
                [108.2692445, 15.9618213],
                [108.269223, 15.9619893],
                [108.2692227, 15.9620644],
                [108.2692391, 15.9622136],
                [108.2692981, 15.9624509],
                [108.2690609, 15.9624766],
                [108.2688263, 15.9625307],
                [108.2682359, 15.9627371],
                [108.2680535, 15.9629898],
                [108.2679248, 15.9632142],
                [108.2681809, 15.963463],
                [108.2683472, 15.963387],
                [108.2684155, 15.9633834],
                [108.26848, 15.963405],
                [108.2685094, 15.9634258],
                [108.2685337, 15.963452],
                [108.2685632, 15.9635159],
                [108.2685495, 15.963653],
                [108.2684602, 15.9639408],
                [108.2683942, 15.9641967],
                [108.2683482, 15.9644778],
                [108.2682118, 15.966484],
                [108.2672998, 15.96635],
                [108.2675573, 15.965811],
                [108.2671872, 15.9656833],
                [108.2671643, 15.9656885],
                [108.2671293, 15.9657164],
                [108.2671201, 15.9657362],
                [108.2671175, 15.9658506],
                [108.2670919, 15.9659],
                [108.2670705, 15.9659206],
                [108.2668881, 15.96586],
                [108.2667607, 15.9661462],
                [108.2662886, 15.965949],
                [108.2660101, 15.965847],
                [108.2657281, 15.9657543],
                [108.2658568, 15.9653855],
                [108.2657146, 15.965321],
                [108.2655457, 15.9652849],
                [108.2654276, 15.965548],
                [108.2649153, 15.9653597],
                [108.2649288, 15.9651173],
                [108.2646579, 15.9649832],
                [108.2644969, 15.9649703],
                [108.2643333, 15.9653056],
                [108.2642118, 15.9652668],
                [108.2640823, 15.9652025],
                [108.2639524, 15.965107],
                [108.2641026, 15.9647821],
                [108.2636225, 15.9645938],
                [108.263581, 15.9646892],
                [108.2635125, 15.9648852],
                [108.2634562, 15.9651302],
                [108.2631719, 15.9651302],
                [108.2631397, 15.9650528],
                [108.2623968, 15.9647743],
                [108.2618724, 15.9651792],
                [108.261002, 15.9655583],
                [108.2607311, 15.9654719],
                [108.2604521, 15.9656627],
                [108.2599211, 15.9657233],
                [108.2594179, 15.9665956],
                [108.2593314, 15.9668138],
                [108.2593243, 15.9670275],
                [108.2593759, 15.9679816],
                [108.2593469, 15.9681644],
                [108.2592881, 15.9691492],
                [108.2594054, 15.9694747],
                [108.259111, 15.9695991],
                [108.2587664, 15.9693335],
                [108.2586128, 15.9696378],
                [108.2587409, 15.969924],
                [108.258584, 15.9699653],
                [108.2584578, 15.9703036],
                [108.2581709, 15.9701909],
                [108.2581227, 15.9703412],
                [108.2578759, 15.9703992],
                [108.2578021, 15.9706719],
                [108.258061, 15.9708201],
                [108.2580355, 15.9711573],
                [108.2581763, 15.9712682],
                [108.2581455, 15.971695],
                [108.2575367, 15.9719371],
                [108.2568312, 15.9721353],
                [108.2557704, 15.9723493],
                [108.2556322, 15.9723957],
                [108.2555008, 15.9724512],
                [108.2547283, 15.9725878],
                [108.2547425, 15.973324],
                [108.2540256, 15.9733318],
                [108.2540122, 15.9729437],
                [108.253229, 15.9729475],
                [108.2532236, 15.9727935],
                [108.2528756, 15.9726993],
                [108.2528541, 15.9727819],
                [108.2520233, 15.972519],
                [108.2518369, 15.972486],
                [108.2517833, 15.9726858],
                [108.2501773, 15.9724131],
                [108.2501766, 15.9720038],
                [108.2498306, 15.9719509],
                [108.2484849, 15.9716058],
                [108.2480577, 15.9714764],
                [108.247835, 15.971479],
                [108.2476875, 15.9717988],
                [108.2474139, 15.971644],
                [108.2472421, 15.9715123],
                [108.2471506, 15.9716614],
                [108.2469901, 15.971675],
                [108.2468923, 15.9719872],
                [108.2465213, 15.9719763],
                [108.246489, 15.9718595],
                [108.2462427, 15.9719639],
                [108.2462249, 15.9716431],
                [108.2462633, 15.9713269],
                [108.2463625, 15.9710355],
                [108.2463598, 15.9707467],
                [108.2462579, 15.9707183],
                [108.245936, 15.9707183],
                [108.2457992, 15.9710123],
                [108.2454023, 15.9708949],
                [108.2444823, 15.9705997],
                [108.2440883, 15.9704156],
                [108.2441001, 15.9703773],
                [108.2437956, 15.9700917],
                [108.2436708, 15.9700327],
                [108.2436133, 15.969995],
                [108.2435085, 15.9699033],
                [108.2434625, 15.9698496],
                [108.2434222, 15.9697918],
                [108.2432002, 15.9693903],
                [108.24283, 15.9689055],
                [108.2424492, 15.9685419],
                [108.2421555, 15.968128],
                [108.2421193, 15.9680029],
                [108.2415372, 15.9677657],
                [108.2415292, 15.9674588],
                [108.241328, 15.9674588],
                [108.2412073, 15.9677347],
                [108.2408452, 15.9675491],
                [108.2394907, 15.9665176],
                [108.2394907, 15.9657078],
                [108.2393096, 15.9656975],
                [108.2393069, 15.9656107],
                [108.2393142, 15.9655678],
                [108.2393646, 15.9654293],
                [108.2393727, 15.9653326],
                [108.2388228, 15.9651212],
                [108.2388765, 15.9649407],
                [108.2386082, 15.9648427],
                [108.2385626, 15.9649471],
                [108.2383078, 15.9648723],
                [108.2380048, 15.9648251],
                [108.2378552, 15.9647924],
                [108.2375417, 15.9647029],
                [108.2372195, 15.9645789],
                [108.2362962, 15.9641296],
                [108.2347244, 15.9634978],
                [108.2336113, 15.9631832],
                [108.2326081, 15.9628867],
                [108.2321528, 15.9626346],
                [108.2312898, 15.962398],
                [108.2313331, 15.961986],
                [108.2309823, 15.9616457],
                [108.2306126, 15.9613317],
                [108.2302173, 15.96104],
                [108.230012, 15.9609043],
                [108.2302053, 15.9603358],
                [108.2305788, 15.959686],
                [108.2308962, 15.9588522],
                [108.2309677, 15.9587192],
                [108.2309934, 15.9586484],
                [108.2310169, 15.958551],
                [108.2310498, 15.9582783],
                [108.2309157, 15.9581442],
                [108.230972, 15.9580127],
                [108.2306327, 15.9578141],
                [108.2305455, 15.9579611],
                [108.2303819, 15.957876],
                [108.230369, 15.9578163],
                [108.23038, 15.9577537],
                [108.2303953, 15.9577239],
                [108.2305495, 15.9575421],
                [108.2305925, 15.9574621],
                [108.2306045, 15.9573912],
                [108.2305898, 15.957279],
                [108.2303953, 15.9571565],
                [108.2293975, 15.9567491],
                [108.2289783, 15.9566169],
                [108.2286776, 15.9565365],
                [108.2285248, 15.9565057],
                [108.2278046, 15.9564245],
                [108.2264219, 15.9561339],
                [108.2262158, 15.9560986],
                [108.2260081, 15.9560729],
                [108.2257995, 15.9560569],
                [108.2255964, 15.9560506],
                [108.2253932, 15.9560535],
                [108.2251904, 15.9560655],
                [108.2244456, 15.9561285],
                [108.223561, 15.9542115],
                [108.2233339, 15.953668],
                [108.2230979, 15.9531281],
                [108.2228529, 15.9525919],
                [108.2224158, 15.9516881],
                [108.2221877, 15.9512406],
                [108.2221089, 15.951067],
                [108.2219756, 15.9507107],
                [108.2218754, 15.9503445],
                [108.2218092, 15.9499712],
                [108.2217775, 15.9495937],
                [108.2217785, 15.9492561],
                [108.2218133, 15.9489614],
                [108.221844, 15.948816],
                [108.2219319, 15.9485317],
                [108.2219886, 15.9483938],
                [108.2221235, 15.9481341],
                [108.2222846, 15.947896],
                [108.2223753, 15.9477839],
                [108.2225757, 15.9475751],
                [108.2226846, 15.9474791],
                [108.222574, 15.9471613],
                [108.2220724, 15.947024],
                [108.2207001, 15.9466091],
                [108.2203974, 15.9464256],
                [108.2202818, 15.9463361],
                [108.2200743, 15.9461433],
                [108.2198971, 15.9459343],
                [108.2197215, 15.9459214],
                [108.2197094, 15.9460955],
                [108.2194879, 15.9462234],
                [108.2192615, 15.9463431],
                [108.2188082, 15.946552],
                [108.2185453, 15.9465623],
                [108.2184284, 15.9466373],
                [108.218317, 15.9467195],
                [108.2181121, 15.9469042],
                [108.2179529, 15.9470885],
                [108.2178386, 15.9472664],
                [108.2177917, 15.9473607],
                [108.2177192, 15.9475578],
                [108.2176778, 15.9477536],
                [108.2176643, 15.9479531],
                [108.2176682, 15.948053],
                [108.2178694, 15.9483109],
                [108.2178614, 15.9487003],
                [108.2178783, 15.9487984],
                [108.2178882, 15.9489969],
                [108.2178667, 15.9491903],
                [108.217793, 15.9494475],
                [108.2164773, 15.9493786],
                [108.2163164, 15.9493631],
                [108.2161193, 15.9493218],
                [108.2162386, 15.9490098],
                [108.2163164, 15.9487158],
                [108.2163191, 15.9477254],
                [108.2164036, 15.9471993],
                [108.2164282, 15.9471027],
                [108.2165196, 15.9468592],
                [108.2165502, 15.9467149],
                [108.2165614, 15.946507],
                [108.2165444, 15.9463612],
                [108.2165049, 15.946242],
                [108.2164438, 15.9461316],
                [108.2161354, 15.9458995],
                [108.2159302, 15.9457938],
                [108.215724, 15.9457158],
                [108.215493, 15.9459575],
                [108.2151785, 15.9458096],
                [108.214581, 15.945649],
                [108.2146776, 15.9448976],
                [108.2146306, 15.9448744],
                [108.2144174, 15.9448589],
                [108.2143155, 15.9448808],
                [108.2140171, 15.9448847],
                [108.2140338, 15.9446538],
                [108.2140201, 15.9446041],
                [108.2139819, 15.9445539],
                [108.2138933, 15.9444931],
                [108.2137898, 15.9444011],
                [108.2136963, 15.9442828],
                [108.2136583, 15.944218],
                [108.2126498, 15.9434778],
                [108.2119524, 15.943648],
                [108.2111236, 15.9435732],
                [108.2109815, 15.9434185],
                [108.2109842, 15.9431503],
                [108.2109311, 15.943071],
                [108.210806, 15.9429262],
                [108.2107347, 15.9428614],
                [108.2105883, 15.9427562],
                [108.2105094, 15.9427118],
                [108.2103183, 15.9431361],
                [108.2102805, 15.9432047],
                [108.2101902, 15.9433334],
                [108.2102197, 15.9435088],
                [108.21019, 15.9435476],
                [108.2101178, 15.9436145],
                [108.2100687, 15.9436461],
                [108.2099596, 15.9436893],
                [108.209407, 15.9446745],
                [108.2091629, 15.9448872],
                [108.2095358, 15.9451774],
                [108.2092246, 15.9453038],
                [108.2083234, 15.9457654],
                [108.2075027, 15.9458608],
                [108.2068858, 15.9463096],
                [108.2064459, 15.946601],
                [108.2059979, 15.9468357],
                [108.2059926, 15.94714],
                [108.2057619, 15.9472225],
                [108.2058236, 15.9477332],
                [108.20575, 15.94777],
                [108.2055938, 15.9478231],
                [108.2053681, 15.9478606],
                [108.2052979, 15.9478802],
                [108.2051465, 15.9479455],
                [108.2050109, 15.9480375],
                [108.2051651, 15.9482154],
                [108.2047948, 15.948502],
                [108.2047069, 15.9485984],
                [108.2046702, 15.9486539],
                [108.2046944, 15.9489066],
                [108.2051396, 15.9491671],
                [108.2051294, 15.9494146],
                [108.2051289, 15.9496622],
                [108.2051346, 15.9498413],
                [108.2051611, 15.9501987],
                [108.2056023, 15.9511064],
                [108.2045663, 15.9514307],
                [108.204563, 15.9517615],
                [108.2046595, 15.9521329],
                [108.20467, 15.9522941],
                [108.2046568, 15.9524552],
                [108.2046418, 15.952533],
                [108.2045951, 15.9526847],
                [108.2045898, 15.9529891],
                [108.2050095, 15.9529942],
                [108.2050109, 15.9540052],
                [108.2054803, 15.9547143],
                [108.2052255, 15.9550444],
                [108.2050055, 15.9553668],
                [108.2053515, 15.9556814],
                [108.2049143, 15.9561663],
                [108.2044704, 15.9558826],
                [108.2037288, 15.9564035],
                [108.2034981, 15.9570431],
                [108.2034672, 15.9571468],
                [108.2034259, 15.9573589],
                [108.2034123, 15.9575743],
                [108.2034161, 15.9576835],
                [108.2034451, 15.9579],
                [108.2034702, 15.9580065],
                [108.203541, 15.9582139],
                [108.2036751, 15.9584472],
                [108.2039595, 15.958905],
                [108.2041415, 15.9591755],
                [108.2045281, 15.9597019],
                [108.204627, 15.9598839],
                [108.2047001, 15.9600768],
                [108.2047445, 15.9602673],
                [108.2047567, 15.960359],
                [108.2047641, 15.9605438],
                [108.2046749, 15.9613382],
                [108.204657, 15.9614416],
                [108.20463, 15.9615432],
                [108.2045499, 15.961738],
                [108.2044369, 15.9619171],
                [108.204375, 15.9619923],
                [108.2042341, 15.962128],
                [108.2040731, 15.9622411],
                [108.2037666, 15.9624234],
                [108.2035518, 15.9625656],
                [108.2032146, 15.9628109],
                [108.2028919, 15.9630736],
                [108.2028785, 15.9638666],
                [108.2018968, 15.9637402],
                [108.2004592, 15.9635288],
                [108.2002199, 15.9635432],
                [108.1986058, 15.9631239],
                [108.1980425, 15.9630053],
                [108.1979547, 15.963006],
                [108.1978708, 15.9630311],
                [108.1978283, 15.9630551],
                [108.1977582, 15.9631213],
                [108.1977576, 15.963256],
                [108.1977455, 15.9633224],
                [108.1976986, 15.9634493],
                [108.1976643, 15.9635082],
                [108.1975855, 15.9636041],
                [108.1975389, 15.9636458],
                [108.1974336, 15.9637145],
                [108.1973639, 15.9644829],
                [108.1966478, 15.9643411],
                [108.1960577, 15.9642586],
                [108.1959128, 15.9642147],
                [108.1952074, 15.9641844],
                [108.1950731, 15.9637785],
                [108.1950418, 15.9635907],
                [108.1950438, 15.9633966],
                [108.1951327, 15.9629289],
                [108.1948185, 15.9628454],
                [108.1944644, 15.9628351],
                [108.1944671, 15.9631446],
                [108.1940138, 15.9633457],
                [108.1932152, 15.9633908],
                [108.1931622, 15.9629328],
                [108.1922731, 15.9630214],
                [108.1921685, 15.9627236],
                [108.1921417, 15.9624779],
                [108.1920145, 15.9625394],
                [108.1917541, 15.9626494],
                [108.1914168, 15.9627629],
                [108.1910295, 15.96095],
                [108.1900822, 15.9609287],
                [108.1901836, 15.9613342],
                [108.1905363, 15.9620486],
                [108.1897116, 15.962144],
                [108.1891966, 15.9624483],
                [108.1888291, 15.9622446],
                [108.188636, 15.9620331],
                [108.1884925, 15.9618423],
                [108.1882211, 15.9619598],
                [108.1879775, 15.9620357],
                [108.1872213, 15.9621598],
                [108.1868225, 15.962196],
                [108.1864467, 15.9622033],
                [108.1863776, 15.9626365],
                [108.1862153, 15.9631175],
                [108.1864001, 15.9632348],
                [108.1865653, 15.9633766],
                [108.1867028, 15.9635336],
                [108.1867626, 15.9636186],
                [108.1868631, 15.9637996],
                [108.1871662, 15.9636371],
                [108.1872068, 15.9637095],
                [108.1872678, 15.963863],
                [108.1872878, 15.963943],
                [108.1873056, 15.9641064],
                [108.1873036, 15.9641861],
                [108.1872788, 15.9643437],
                [108.1871608, 15.9644778],
                [108.1867665, 15.9643746],
                [108.1862274, 15.9644391],
                [108.1862136, 15.9645926],
                [108.1862271, 15.9647063],
                [108.1862596, 15.964804],
                [108.1864004, 15.9650864],
                [108.186447, 15.9655415],
                [108.1860665, 15.9656131],
                [108.1857028, 15.9656576],
                [108.1856252, 15.9656598],
                [108.185234, 15.9655999],
                [108.1848447, 15.9655176],
                [108.1847103, 15.9655109],
                [108.1846217, 15.9655183],
                [108.1846234, 15.9655454],
                [108.1843016, 15.9655273],
                [108.1841621, 15.9655028],
                [108.1840441, 15.9654706],
                [108.1832139, 15.9653391],
                [108.1833333, 15.9647589],
                [108.1835505, 15.9628544],
                [108.1834882, 15.9616057],
                [108.1837785, 15.9610196],
                [108.1841272, 15.9602615],
                [108.1838858, 15.9593795],
                [108.1835183, 15.9590907],
                [108.18312, 15.9588979],
                [108.1831254, 15.9586619],
                [108.183105, 15.9586015],
                [108.1830584, 15.9585423],
                [108.1830228, 15.9585169],
                [108.1829202, 15.9584846],
                [108.1827754, 15.9581726],
                [108.1829899, 15.957925],
                [108.1829041, 15.9574041],
                [108.1826466, 15.9569812],
                [108.1812251, 15.9566047],
                [108.1811821, 15.9562668],
                [108.1807879, 15.9561637],
                [108.180415, 15.9558104],
                [108.1804204, 15.9515036],
                [108.1811634, 15.9495023],
                [108.1802143, 15.9491134],
                [108.1817299, 15.9461362],
                [108.1814686, 15.9460159],
                [108.1821971, 15.9445236],
                [108.1822256, 15.9444466],
                [108.182245, 15.9443669],
                [108.1822565, 15.9442424],
                [108.1822539, 15.9441799],
                [108.1822347, 15.9441102],
                [108.1821772, 15.9439694],
                [108.1820934, 15.9438331],
                [108.1817917, 15.9434862],
                [108.1816267, 15.9432812],
                [108.1814463, 15.94304],
                [108.1800623, 15.9409768],
                [108.1800028, 15.9408763],
                [108.1799014, 15.9406668],
                [108.1798598, 15.9405583],
                [108.1797976, 15.9403442],
                [108.1796888, 15.9396711],
                [108.1779259, 15.9394551],
                [108.176569, 15.9392716],
                [108.1764975, 15.9392691],
                [108.1763558, 15.939287],
                [108.1762962, 15.9393041],
                [108.1761836, 15.9393546],
                [108.1757265, 15.9396789],
                [108.1756498, 15.9397263],
                [108.1754889, 15.939807],
                [108.1753202, 15.9398691],
                [108.1746246, 15.9400635],
                [108.1746188, 15.9408143],
                [108.1745517, 15.9408214],
                [108.174427, 15.940802],
                [108.1743747, 15.9409129],
                [108.1740582, 15.9408227],
                [108.1742178, 15.9403449],
                [108.1743036, 15.940254],
                [108.1743157, 15.9401819],
                [108.1743036, 15.9401518],
                [108.1742909, 15.9401408],
                [108.1742566, 15.9401327],
                [108.1741574, 15.9401599],
                [108.1740582, 15.9401653],
                [108.1734849, 15.9401051],
                [108.1733578, 15.9412911],
                [108.173271, 15.9412711],
                [108.173211, 15.9412427],
                [108.1731334, 15.9411898],
                [108.1729907, 15.941069],
                [108.172874, 15.9409391],
                [108.1726775, 15.9406866],
                [108.1726722, 15.9405768],
                [108.1726431, 15.9404959],
                [108.1726158, 15.9404539],
                [108.1724238, 15.9402621],
                [108.1722705, 15.940308],
                [108.1721123, 15.940336],
                [108.1719312, 15.9403339],
                [108.1715476, 15.9401998],
                [108.1714645, 15.9404397],
                [108.1712244, 15.9404178],
                [108.1711323, 15.940505],
                [108.1709938, 15.9405854],
                [108.1709074, 15.9406254],
                [108.1707269, 15.9406873],
                [108.1704158, 15.9412547],
                [108.1699061, 15.9417679],
                [108.1690586, 15.9417473],
                [108.1688775, 15.942138],
                [108.1686133, 15.9421419],
                [108.1684403, 15.9424823],
                [108.1682646, 15.9425545],
                [108.1679749, 15.9431374],
                [108.1678945, 15.9432199],
                [108.167586, 15.9437228],
                [108.167346, 15.9447441],
                [108.1672325, 15.9448025],
                [108.1671395, 15.9448879],
                [108.1670737, 15.9449943],
                [108.1670397, 15.9451159],
                [108.1670415, 15.9452419],
                [108.1670898, 15.9455307],
                [108.1671364, 15.9457164],
                [108.1671481, 15.9458113],
                [108.167148, 15.9460023],
                [108.1671362, 15.9460972],
                [108.1670908, 15.9462789],
                [108.1670581, 15.9463647],
                [108.1669729, 15.946528],
                [108.1668632, 15.9466771],
                [108.1664059, 15.9467686],
                [108.1657621, 15.9466526],
                [108.1654349, 15.9477216],
                [108.1654322, 15.9486822],
                [108.1649816, 15.9493425],
                [108.1650405, 15.9495146],
                [108.1651425, 15.9498634],
                [108.1652176, 15.9501883],
                [108.1652439, 15.9504078],
                [108.1652337, 15.9506286],
                [108.165215, 15.9507377],
                [108.1651608, 15.950922],
                [108.165123, 15.9510107],
                [108.1650272, 15.9511787],
                [108.1649711, 15.9512552],
                [108.1648421, 15.9513953],
                [108.1649762, 15.9519343],
                [108.1646785, 15.9520052],
                [108.1644237, 15.9517667],
                [108.1637612, 15.9517022],
                [108.1633082, 15.951259],
                [108.163088, 15.9510317],
                [108.1625034, 15.9503966],
                [108.1624244, 15.9503305],
                [108.1623395, 15.9502718],
                [108.1622492, 15.9502208],
                [108.1621543, 15.9501782],
                [108.1620558, 15.9501442],
                [108.1618847, 15.9501073],
                [108.1617975, 15.9500985],
                [108.1617098, 15.9500963],
                [108.1614817, 15.9501068],
                [108.161203, 15.9501417],
                [108.1610656, 15.9501703],
                [108.1608276, 15.9502386],
                [108.1607115, 15.9502812],
                [108.1593704, 15.950962],
                [108.1567553, 15.9521741],
                [108.1546122, 15.9527312],
                [108.1540006, 15.9528601],
                [108.153888, 15.9526796],
                [108.1518066, 15.9510446],
                [108.1515465, 15.9507896],
                [108.151424, 15.9506553],
                [108.151195, 15.950374],
                [108.1510832, 15.9502193],
                [108.1508789, 15.9498977],
                [108.1507868, 15.9497313],
                [108.1504997, 15.9491594],
                [108.1503265, 15.9487449],
                [108.1502509, 15.9485337],
                [108.1501222, 15.9481046],
                [108.1500678, 15.9478809],
                [108.1499833, 15.9474288],
                [108.1499317, 15.9469722],
                [108.1498911, 15.9463917],
                [108.1498379, 15.9460051],
                [108.1497985, 15.9457946],
                [108.1496982, 15.9453779],
                [108.1495696, 15.9449685],
                [108.1495229, 15.9447854],
                [108.1494859, 15.9446002],
                [108.1494585, 15.9444135],
                [108.1494342, 15.9440859],
                [108.1494424, 15.9433865],
                [108.1494752, 15.9426332],
                [108.1495294, 15.9419665],
                [108.149537, 15.9417647],
                [108.149535, 15.9415627],
                [108.1495234, 15.9413611],
                [108.1494831, 15.94103],
                [108.1494167, 15.9407028],
                [108.1490976, 15.9396144],
                [108.1490285, 15.9393252],
                [108.1490035, 15.9391788],
                [108.148973, 15.9388884],
                [108.1489674, 15.9386014],
                [108.1489014, 15.9375634],
                [108.1488499, 15.937209],
                [108.1487667, 15.9368601],
                [108.1487133, 15.9366886],
                [108.1486523, 15.9365195],
                [108.1485108, 15.9361959],
                [108.1483412, 15.935885],
                [108.1480433, 15.9354629],
                [108.1477747, 15.9350229],
                [108.1476517, 15.9347969],
                [108.1474292, 15.9343339],
                [108.1472387, 15.9338581],
                [108.1470809, 15.9333714],
                [108.1469565, 15.9328758],
                [108.146907, 15.9326254],
                [108.146866, 15.9323735],
                [108.1453291, 15.9333523],
                [108.1449884, 15.9330466],
                [108.1446424, 15.9329499],
                [108.1444412, 15.9331227],
                [108.1437948, 15.9326198],
                [108.1434881, 15.9327978],
                [108.1433723, 15.9327189],
                [108.1432624, 15.9326327],
                [108.1430651, 15.9324431],
                [108.1429768, 15.9323393],
                [108.1426549, 15.9325102],
                [108.1423733, 15.9320111],
                [108.1419857, 15.9320343],
                [108.1418872, 15.9318729],
                [108.1417483, 15.9316913],
                [108.1416108, 15.9317422],
                [108.1413849, 15.9313231],
                [108.1401883, 15.9310816],
                [108.1399287, 15.9310676],
                [108.1396688, 15.9310628],
                [108.1394089, 15.9310671],
                [108.1391493, 15.9310806],
                [108.1388904, 15.9311032],
                [108.1384624, 15.931161],
                [108.1380388, 15.9312438],
                [108.1376304, 15.9305539],
                [108.1360459, 15.9312718],
                [108.1355279, 15.9315662],
                [108.1352172, 15.9317561],
                [108.1350552, 15.9318402],
                [108.1347643, 15.9319684],
                [108.1344634, 15.932073],
                [108.1323954, 15.9327333],
                [108.132166, 15.9327863],
                [108.1317144, 15.932918],
                [108.1314929, 15.9329963],
                [108.1312671, 15.9330862],
                [108.1310452, 15.9331847],
                [108.1308277, 15.9332917],
                [108.1280261, 15.9348043],
                [108.1268916, 15.9354272],
                [108.1267973, 15.9355261],
                [108.1267306, 15.9356438],
                [108.1266954, 15.935774],
                [108.1266872, 15.936],
                [108.126673, 15.9360784],
                [108.1266508, 15.9361543],
                [108.1265831, 15.9362977],
                [108.1264607, 15.9363523],
                [108.1262076, 15.9364421],
                [108.126069, 15.9364791],
                [108.1257865, 15.9365298],
                [108.12565, 15.9365708],
                [108.1255264, 15.9366399],
                [108.1254713, 15.9366839],
                [108.1253783, 15.9367878],
                [108.1253116, 15.9369086],
                [108.1252742, 15.9370404],
                [108.1252547, 15.937462],
                [108.1252194, 15.9376524],
                [108.1251904, 15.937745],
                [108.1251106, 15.9379225],
                [108.1249998, 15.9380906],
                [108.1249343, 15.9381678],
                [108.1247854, 15.938306],
                [108.1233618, 15.9393565],
                [108.1216934, 15.9401276],
                [108.1213608, 15.9402983],
                [108.121045, 15.9404961],
                [108.1208941, 15.9406048],
                [108.1204352, 15.9409647],
                [108.1201978, 15.941135],
                [108.1197082, 15.9414555],
                [108.1191964, 15.9417504],
                [108.118932, 15.941888],
                [108.1186636, 15.9420181],
                [108.1181154, 15.9422554],
                [108.1177198, 15.9425836],
                [108.1173178, 15.9429045],
                [108.1169096, 15.9432181],
                [108.1161453, 15.9437737],
                [108.1154331, 15.9442567],
                [108.1146178, 15.945247],
                [108.1127402, 15.9457938],
                [108.1111309, 15.94665],
                [108.1094357, 15.9479395],
                [108.1093463, 15.9479404],
                [108.1091685, 15.9479222],
                [108.1089958, 15.9478776],
                [108.1088158, 15.947799],
                [108.1086525, 15.9476919],
                [108.1078035, 15.9469576],
                [108.1075697, 15.9467459],
                [108.1073292, 15.9465415],
                [108.107082, 15.9463444],
                [108.1068286, 15.9461548],
                [108.1065815, 15.9459814],
                [108.1060717, 15.9456563],
                [108.1058094, 15.9455049],
                [108.1045541, 15.9444733],
                [108.104317, 15.9438932],
                [108.1041893, 15.9436068],
                [108.1038684, 15.9429496],
                [108.1036958, 15.9426267],
                [108.1019792, 15.941884],
                [108.0977735, 15.9411412],
                [108.0950376, 15.93947],
                [108.0945123, 15.9391776],
                [108.0939719, 15.9389118],
                [108.0936965, 15.9387891],
                [108.093181, 15.9385811],
                [108.0922303, 15.9382439],
                [108.0918083, 15.9380823],
                [108.0913898, 15.9379122],
                [108.0907302, 15.9376235],
                [108.0900809, 15.9373138],
                [108.0882355, 15.9367774],
                [108.0881792, 15.9340925],
                [108.0878788, 15.9335354],
                [108.087852, 15.9329835],
                [108.0881202, 15.9325295],
                [108.088134, 15.9321152],
                [108.0881191, 15.9319267],
                [108.0880765, 15.9317422],
                [108.0879774, 15.9314785],
                [108.0879541, 15.9313883],
                [108.0879291, 15.9312078],
                [108.0879318, 15.9310294],
                [108.0879432, 15.9309407],
                [108.0879911, 15.9307443],
                [108.0880129, 15.9305435],
                [108.0880068, 15.9303255],
                [108.08797, 15.9301102],
                [108.087324, 15.929446],
                [108.0871628, 15.92926],
                [108.0870902, 15.9291611],
                [108.0869529, 15.9289358],
                [108.0868946, 15.9288178],
                [108.0867451, 15.9284595],
                [108.0866848, 15.9283456],
                [108.0865431, 15.9281294],
                [108.0864524, 15.9280165],
                [108.0863539, 15.9279097],
                [108.086248, 15.9278096],
                [108.0861354, 15.9277167],
                [108.0852771, 15.9270977],
                [108.0842471, 15.927108],
                [108.0834854, 15.9272628],
                [108.0833758, 15.9272649],
                [108.0832664, 15.9272581],
                [108.0831581, 15.9272422],
                [108.0830515, 15.9272176],
                [108.0829476, 15.9271842],
                [108.082847, 15.9271424],
                [108.0826708, 15.9270431],
                [108.082524, 15.926928],
                [108.0824576, 15.926863],
                [108.0823229, 15.9267063],
                [108.0822422, 15.9266253],
                [108.082155, 15.9265509],
                [108.0820056, 15.9264484],
                [108.0818438, 15.9263652],
                [108.0809265, 15.9259989],
                [108.0801111, 15.925968],
                [108.0793387, 15.925576],
                [108.0786788, 15.9254367],
                [108.0773377, 15.9254676],
                [108.077134, 15.925485],
                [108.0767291, 15.9255402],
                [108.0763292, 15.9256224],
                [108.0761147, 15.9256782],
                [108.075693, 15.9258131],
                [108.0751874, 15.9260138],
                [108.0750888, 15.9260414],
                [108.074904, 15.9260707],
                [108.0747346, 15.9260733],
                [108.0746502, 15.926066],
                [108.0740279, 15.9259267],
                [108.0738517, 15.9258772],
                [108.073669, 15.9258606],
                [108.0735773, 15.9258648],
                [108.0733981, 15.925898],
                [108.0731179, 15.9260058],
                [108.0730048, 15.9260424],
                [108.0728896, 15.9260724],
                [108.0726546, 15.9261124],
                [108.0725395, 15.9261221],
                [108.0723084, 15.9261221],
                [108.0715442, 15.926066],
                [108.0712541, 15.9260738],
                [108.0706751, 15.9261112],
                [108.070099, 15.9261775],
                [108.0695271, 15.9262724],
                [108.0692401, 15.9263312],
                [108.0686712, 15.9264705],
                [108.0683899, 15.9265509],
                [108.0682442, 15.9266156],
                [108.0681027, 15.9266884],
                [108.067966, 15.9267692],
                [108.0678345, 15.9268576],
                [108.0675997, 15.9270461],
                [108.0673983, 15.9272478],
                [108.0672204, 15.9274691],
                [108.0671422, 15.9275977],
                [108.066963, 15.9278405],
                [108.0668881, 15.9279264],
                [108.0667269, 15.9280881],
                [108.0634546, 15.9288722],
                [108.0632488, 15.9289289],
                [108.0630458, 15.9289943],
                [108.0628461, 15.9290683],
                [108.06265, 15.9291508],
                [108.0623268, 15.9293095],
                [108.062017, 15.9294912],
                [108.0618758, 15.9296454],
                [108.0617527, 15.9298207],
                [108.0616515, 15.9300155],
                [108.0615783, 15.9302216],
                [108.0615342, 15.9304352],
                [108.0615234, 15.9309562],
                [108.0615263, 15.9311416],
                [108.0615195, 15.9313269],
                [108.0615029, 15.9315116],
                [108.0614768, 15.9316953],
                [108.061441, 15.9318775],
                [108.0613957, 15.9320577],
                [108.061341, 15.9322355],
                [108.0611695, 15.9326781],
                [108.0609763, 15.9331124],
                [108.0608417, 15.9333848],
                [108.0605525, 15.9339352],
                [108.0597907, 15.933832],
                [108.0591011, 15.9337641],
                [108.0587554, 15.9337392],
                [108.0583836, 15.9336937],
                [108.0580177, 15.9336152],
                [108.0578381, 15.9335638],
                [108.0575262, 15.933453],
                [108.0573741, 15.9333884],
                [108.0573261, 15.9333374],
                [108.0572963, 15.9332749],
                [108.0572878, 15.9331992],
                [108.0572936, 15.9331614],
                [108.0573928, 15.9329293],
                [108.0574033, 15.9328851],
                [108.0574006, 15.9327948],
                [108.0573697, 15.9327152],
                [108.0573186, 15.9326525],
                [108.0572507, 15.9326069],
                [108.0570495, 15.9325605],
                [108.0569791, 15.9325587],
                [108.0568391, 15.9325729],
                [108.0566999, 15.9326119],
                [108.0566317, 15.9326413],
                [108.0565667, 15.9326765],
                [108.0561493, 15.9329713],
                [108.0559624, 15.9330685],
                [108.0557589, 15.9331402],
                [108.0556516, 15.9331656],
                [108.0554326, 15.933193],
                [108.0546301, 15.9332156],
                [108.0542748, 15.9330995],
                [108.0540939, 15.9330511],
                [108.0537371, 15.9329758],
                [108.0533856, 15.9329267],
                [108.052023, 15.9329267],
                [108.0517441, 15.932545],
                [108.0516899, 15.9324967],
                [108.0515683, 15.932416],
                [108.051433, 15.9323593],
                [108.051288, 15.9323284],
                [108.0512138, 15.9323232],
                [108.0510657, 15.9323335],
                [108.0476993, 15.9328442],
                [108.0468517, 15.9327307],
                [108.0443197, 15.9325966],
                [108.0436599, 15.9334864],
                [108.0420828, 15.9347553],
                [108.0419961, 15.9348121],
                [108.0419044, 15.934861],
                [108.0418085, 15.9349018],
                [108.0417092, 15.934934],
                [108.0416072, 15.9349575],
                [108.0414083, 15.9349773],
                [108.041218, 15.9349675],
                [108.041124, 15.9349524],
                [108.0409396, 15.9349021],
                [108.0408502, 15.9348669],
                [108.0406811, 15.9347778],
                [108.0404745, 15.9346414],
                [108.0403421, 15.9345656],
                [108.040093, 15.9344485],
                [108.0398621, 15.9343658],
                [108.0371475, 15.9336411],
                [108.0360182, 15.933615],
                [108.0357798, 15.9336004],
                [108.0355422, 15.9335765],
                [108.0353057, 15.9335435],
                [108.0350709, 15.9335014],
                [108.034838, 15.9334503],
                [108.0346074, 15.9333902],
                [108.0341665, 15.9332481],
                [108.0337501, 15.9330785],
                [108.0335472, 15.9329824],
                [108.0331535, 15.9327684],
                [108.0329633, 15.9326507],
                [108.0319762, 15.9319905],
                [108.0309724, 15.9315415],
                [108.0293048, 15.9307624],
                [108.0284247, 15.9303191],
                [108.0281094, 15.9301716],
                [108.0274684, 15.9298986],
                [108.0268144, 15.9296555],
                [108.0261697, 15.9294491],
                [108.0255365, 15.929277],
                [108.0252171, 15.9292013],
                [108.0245733, 15.9290708],
                [108.0241495, 15.9290316],
                [108.0237237, 15.9290282],
                [108.0235112, 15.9290399],
                [108.0231413, 15.9290817],
                [108.0227758, 15.9291507],
                [108.0224168, 15.9292462],
                [108.0203174, 15.9300225],
                [108.0202405, 15.9300388],
                [108.0200837, 15.9300494],
                [108.0199277, 15.9300303],
                [108.0198475, 15.9300083],
                [108.0196963, 15.9299408],
                [108.0195629, 15.9298446],
                [108.0189085, 15.9289264],
                [108.0186246, 15.9287558],
                [108.0184761, 15.9286814],
                [108.0183238, 15.9286143],
                [108.0180135, 15.9285046],
                [108.0176972, 15.9284266],
                [108.0175363, 15.9283992],
                [108.0173743, 15.9283796],
                [108.0164355, 15.9284209],
                [108.0160922, 15.9280546],
                [108.0160868, 15.9277915],
                [108.0160698, 15.927667],
                [108.0160292, 15.9275301],
                [108.0159581, 15.9273866],
                [108.0158912, 15.9273163],
                [108.0158176, 15.9272525],
                [108.0156649, 15.9271526],
                [108.0155088, 15.9270844],
                [108.0154268, 15.9270599],
                [108.015258, 15.9270306],
                [108.014161, 15.9270049],
                [108.0128146, 15.9266181],
                [108.0127984, 15.9262182],
                [108.0125641, 15.9260529],
                [108.0124551, 15.9259603],
                [108.0122938, 15.9257997],
                [108.0121493, 15.925625],
                [108.011997, 15.9254689],
                [108.0116773, 15.925171],
                [108.0113733, 15.92492],
                [108.011055, 15.9246861],
                [108.0084586, 15.9229116],
                [108.0056477, 15.9215084],
                [108.0055215, 15.921462],
                [108.0053881, 15.9214415],
                [108.0053204, 15.9214413],
                [108.0052582, 15.9214471],
                [108.005137, 15.9214756],
                [108.005079, 15.9214981],
                [108.0049603, 15.9215715],
                [108.0047142, 15.9217044],
                [108.0045464, 15.9217818],
                [108.0043746, 15.9218506],
                [108.0041993, 15.9219108],
                [108.0032551, 15.9219108],
                [108.0013883, 15.9211164],
                [108.0010352, 15.9209527],
                [108.0008538, 15.9208814],
                [108.0004795, 15.9207593],
                [108.0002872, 15.920709],
                [107.999897, 15.9206314],
                [107.9983309, 15.9206338],
                [107.9981599, 15.9206454],
                [107.9979898, 15.9206661],
                [107.9978213, 15.9206959],
                [107.9976547, 15.9207346],
                [107.9973721, 15.9208227],
                [107.9970996, 15.9209366],
                [107.996761, 15.9211161],
                [107.9966508, 15.9211598],
                [107.9965371, 15.9211943],
                [107.9964208, 15.9212195],
                [107.9961975, 15.9212411],
                [107.9959735, 15.9212284],
                [107.995863, 15.9212092],
                [107.9945755, 15.9194862],
                [107.9929554, 15.9179902],
                [107.9912494, 15.9194399],
                [107.9911578, 15.919511],
                [107.9910609, 15.9195755],
                [107.9908539, 15.9196832],
                [107.9906326, 15.9197606],
                [107.990402, 15.9198061],
                [107.9902986, 15.9198157],
                [107.9899873, 15.9198257],
                [107.9895578, 15.9198213],
                [107.989115, 15.9197903],
                [107.9888946, 15.9197648],
                [107.9884442, 15.9195822],
                [107.9880094, 15.9193676],
                [107.9876252, 15.9191427],
                [107.9874393, 15.9190208],
                [107.9870814, 15.9187588],
                [107.9863358, 15.9187692],
                [107.9861674, 15.918789],
                [107.9860005, 15.9188182],
                [107.9858673, 15.9188484],
                [107.9856062, 15.9189265],
                [107.9849073, 15.9191906],
                [107.983439, 15.9194656],
                [107.9797161, 15.9224886],
                [107.9781818, 15.9236544],
                [107.977066, 15.9246242],
                [107.9695237, 15.9304326],
                [107.9634565, 15.9342756],
                [107.9625606, 15.935039],
                [107.9623139, 15.9355497],
                [107.960329, 15.938036],
                [107.9595995, 15.939047],
                [107.9575396, 15.9415126],
                [107.9573736, 15.941687],
                [107.957201, 15.9418553],
                [107.957022, 15.9420173],
                [107.9568368, 15.9421728],
                [107.9566601, 15.9423108],
                [107.9564786, 15.9424429],
                [107.9562924, 15.9425689],
                [107.9561019, 15.9426886],
                [107.9549163, 15.9430445],
                [107.9527384, 15.9458866],
                [107.9518533, 15.94714],
                [107.9517619, 15.9473078],
                [107.9516431, 15.9474589],
                [107.9514916, 15.9475954],
                [107.9514028, 15.9476565],
                [107.9513085, 15.9477094],
                [107.9497826, 15.9484192],
                [107.9496274, 15.9485104],
                [107.9494916, 15.9486267],
                [107.9493796, 15.9487645],
                [107.9492949, 15.9489192],
                [107.9492636, 15.9490014],
                [107.9492247, 15.9491722],
                [107.9492172, 15.9492613],
                [107.9492278, 15.9494395],
                [107.9492719, 15.9496129],
                [107.9493481, 15.9497757],
                [107.9507051, 15.9514253],
                [107.9508104, 15.9515933],
                [107.9508823, 15.951777],
                [107.9509036, 15.9518658],
                [107.9509224, 15.9520469],
                [107.9508984, 15.953247],
                [107.9507965, 15.9540103],
                [107.9507998, 15.9541554],
                [107.9508342, 15.9542967],
                [107.9508628, 15.954364],
                [107.9509396, 15.9544867],
                [107.9509867, 15.954541],
                [107.9510969, 15.9546344],
                [107.9529101, 15.955733],
                [107.9538381, 15.9568574],
                [107.9551678, 15.957747],
                [107.9552746, 15.9578284],
                [107.9553759, 15.957916],
                [107.9555602, 15.9581088],
                [107.9557062, 15.9583044],
                [107.955818, 15.9584958],
                [107.9558659, 15.9585955],
                [107.9561448, 15.9615045],
                [107.9558498, 15.9621001],
                [107.955905, 15.9622475],
                [107.9560322, 15.962536],
                [107.9561162, 15.9626995],
                [107.9563057, 15.9630156],
                [107.9562306, 15.9640626],
                [107.9562017, 15.9642206],
                [107.9561746, 15.9642966],
                [107.9560991, 15.9644349],
                [107.9559994, 15.9645528],
                [107.9557795, 15.9647498],
                [107.9556239, 15.9649011],
                [107.9553535, 15.9651937],
                [107.9551256, 15.9654758],
                [107.9550801, 15.9655543],
                [107.9550432, 15.965637],
                [107.9550153, 15.9657228],
                [107.9549968, 15.965811],
                [107.954988, 15.9659831],
                [107.9549968, 15.9660689],
                [107.9551202, 15.9666104],
                [107.9559195, 15.9672809],
                [107.9563379, 15.9683794],
                [107.9566491, 15.9688126],
                [107.9571225, 15.9688217],
                [107.9573816, 15.9688425],
                [107.9576378, 15.9688866],
                [107.9577641, 15.9689173],
                [107.9578888, 15.9689537],
                [107.9580794, 15.9690214],
                [107.9581436, 15.9690543],
                [107.9582584, 15.9691401],
                [107.9583115, 15.9691967],
                [107.9583569, 15.9692592],
                [107.958394, 15.9693267],
                [107.9586875, 15.9701897],
                [107.9604072, 15.9706176],
                [107.9604612, 15.9706401],
                [107.9605571, 15.9707053],
                [107.9605973, 15.9707467],
                [107.9606559, 15.970838],
                [107.9606883, 15.9709407],
                [107.9606957, 15.9710518],
                [107.9607207, 15.9711644],
                [107.960742, 15.9712152],
                [107.9608018, 15.9713083],
                [107.9608816, 15.9713862],
                [107.961306, 15.9716854],
                [107.9613664, 15.9717373],
                [107.9614717, 15.9718555],
                [107.961517, 15.971923],
                [107.9615556, 15.9719943],
                [107.9616117, 15.9721456],
                [107.9616286, 15.9722243],
                [107.9616648, 15.9725672],
                [107.9616849, 15.9726402],
                [107.9617143, 15.9727102],
                [107.9617524, 15.9727762],
                [107.9617987, 15.9728371],
                [107.9618525, 15.9728921],
                [107.9619118, 15.9729394],
                [107.9619766, 15.9729795],
                [107.9620459, 15.9730117],
                [107.9621188, 15.9730356],
                [107.9621942, 15.9730508],
                [107.9633653, 15.9731706],
                [107.9636979, 15.9737998],
                [107.963799, 15.9739146],
                [107.9638586, 15.9739635],
                [107.9639839, 15.974038],
                [107.9641132, 15.9740846],
                [107.9643351, 15.9741393],
                [107.9644811, 15.9742021],
                [107.9645647, 15.9742508],
                [107.9647155, 15.97437],
                [107.9649639, 15.9746353],
                [107.9650068, 15.9746677],
                [107.9651043, 15.9747145],
                [107.965157, 15.9747281],
                [107.9652569, 15.9747348],
                [107.9660439, 15.9746186],
                [107.9662537, 15.9746095],
                [107.9663585, 15.9746173],
                [107.9665644, 15.974657],
                [107.966761, 15.9747281],
                [107.9669334, 15.9748224],
                [107.9670889, 15.9749409],
                [107.9672238, 15.9750807],
                [107.967335, 15.9752387],
                [107.9680109, 15.976626],
                [107.9691589, 15.9776162],
                [107.9695133, 15.9778761],
                [107.9696981, 15.9779959],
                [107.9700815, 15.9782144],
                [107.970281, 15.9783134],
                [107.9706911, 15.9784884],
                [107.9711142, 15.9786321],
                [107.9714817, 15.9787714],
                [107.971612, 15.978842],
                [107.9717266, 15.9789345],
                [107.9718299, 15.9790578],
                [107.9718747, 15.9791328],
                [107.9719105, 15.9792122],
                [107.971937, 15.9792949],
                [107.9720024, 15.9796015],
                [107.97212, 15.98004],
                [107.9742443, 15.9833921],
                [107.9757249, 15.984898],
                [107.9758579, 15.9850154],
                [107.9761372, 15.9852353],
                [107.976433, 15.9854343],
                [107.9765933, 15.9855294],
                [107.9767574, 15.9856183],
                [107.977096, 15.9857772],
                [107.9777741, 15.9860531],
                [107.9778466, 15.9860921],
                [107.9779802, 15.9861865],
                [107.978096, 15.9863007],
                [107.9781477, 15.9863664],
                [107.9782329, 15.9865092],
                [107.9782916, 15.9866638],
                [107.9783383, 15.9868486],
                [107.9783739, 15.9869509],
                [107.978417, 15.9870504],
                [107.9785252, 15.9872392],
                [107.9786474, 15.9873966],
                [107.9787164, 15.9874691],
                [107.9788685, 15.9876002],
                [107.9792306, 15.9875486],
                [107.9796977, 15.9875234],
                [107.9798311, 15.9875429],
                [107.9799721, 15.9875922],
                [107.9800442, 15.9876312],
                [107.9801111, 15.9876779],
                [107.980172, 15.9877317],
                [107.9808801, 15.9885388],
                [107.9809298, 15.9886149],
                [107.9810079, 15.9887779],
                [107.9810544, 15.9889438],
                [107.9810719, 15.9891071],
                [107.9810625, 15.989271],
                [107.9809499, 15.9897867],
                [107.9808694, 15.9903798],
                [107.9805368, 15.9912255],
                [107.9806185, 15.991654],
                [107.9806709, 15.9920867],
                [107.9806874, 15.9923305],
                [107.9806974, 15.993012],
                [107.9807353, 15.9933965],
                [107.9807688, 15.9935909],
                [107.9808117, 15.9937836],
                [107.980864, 15.9939741],
                [107.9814697, 15.9956056],
                [107.981552, 15.995812],
                [107.9816872, 15.9962204],
                [107.9817867, 15.9966246],
                [107.9812932, 15.9975013],
                [107.9812597, 15.9979511],
                [107.9812407, 15.9980804],
                [107.9811823, 15.9983354],
                [107.9810905, 15.9985996],
                [107.9810297, 15.9987357],
                [107.9809608, 15.9988682],
                [107.9807997, 15.9991205],
                [107.980746, 15.9992473],
                [107.9807013, 15.9993773],
                [107.9806658, 15.9995099],
                [107.9806395, 15.9996445],
                [107.9806168, 15.9998683],
                [107.9806193, 15.9999562],
                [107.9806492, 16.0001296],
                [107.9806763, 16.0002137],
                [107.9807407, 16.0003508],
                [107.9808265, 16.0004766],
                [107.981084, 16.0007139],
                [107.9811726, 16.0008096],
                [107.9812084, 16.0008638],
                [107.981261, 16.0009816],
                [107.981286, 16.0011049],
                [107.9812839, 16.001228],
                [107.9812556, 16.0013481],
                [107.9812036, 16.0014944],
                [107.9811836, 16.0016478],
                [107.9811966, 16.0018019],
                [107.9812127, 16.0018681],
                [107.981263, 16.0019951],
                [107.9813361, 16.0021113],
                [107.982173, 16.002957],
                [107.9821837, 16.0047514],
                [107.9813522, 16.0056951],
                [107.9812838, 16.0057537],
                [107.9812395, 16.0058308],
                [107.981228, 16.0058736],
                [107.981228, 16.0059617],
                [107.9812396, 16.0060044],
                [107.9812834, 16.0060808],
                [107.9813999, 16.0061861],
                [107.9814587, 16.0062832],
                [107.9814757, 16.0063384],
                [107.9814831, 16.0063955],
                [107.9814812, 16.0065037],
                [107.9814884, 16.0065538],
                [107.9815207, 16.0066447],
                [107.9817273, 16.0069291],
                [107.9817947, 16.0070395],
                [107.9819077, 16.0072712],
                [107.9820077, 16.0075342],
                [107.9820469, 16.0076004],
                [107.9820844, 16.0076512],
                [107.982173, 16.0077422],
                [107.982376, 16.0078454],
                [107.9824709, 16.0079076],
                [107.982645, 16.0080516],
                [107.982727, 16.0081367],
                [107.9828018, 16.0082277],
                [107.9828689, 16.0083241],
                [107.982928, 16.0084253],
                [107.9829786, 16.0085306],
                [107.9830205, 16.0086394],
                [107.982747, 16.0092994],
                [107.9830285, 16.0097467],
                [107.9830956, 16.0099078],
                [107.983133, 16.0100684],
                [107.9831439, 16.0102327],
                [107.9831921, 16.0104235],
                [107.9832619, 16.0108102],
                [107.9832841, 16.0110124],
                [107.9832966, 16.0112154],
                [107.9832995, 16.0114186],
                [107.9835355, 16.0119394],
                [107.9817438, 16.0116816],
                [107.9817009, 16.012156],
                [107.9806495, 16.0126974],
                [107.9797804, 16.0128573],
                [107.9792088, 16.0133303],
                [107.9786217, 16.0137854],
                [107.9782969, 16.0140246],
                [107.9776347, 16.0144866],
                [107.9756927, 16.0164151],
                [107.9756171, 16.0164825],
                [107.9755352, 16.016543],
                [107.975448, 16.0165959],
                [107.975356, 16.0166408],
                [107.97526, 16.0166775],
                [107.975161, 16.0167054],
                [107.9749816, 16.0167329],
                [107.9748288, 16.0167683],
                [107.9746842, 16.0168276],
                [107.9745405, 16.0169176],
                [107.9744755, 16.0169717],
                [107.9743624, 16.0170957],
                [107.9743516, 16.0172813],
                [107.9736316, 16.0180455],
                [107.973538, 16.0181332],
                [107.9733328, 16.0182889],
                [107.9731071, 16.0184156],
                [107.9728698, 16.0185094],
                [107.9726213, 16.0185712],
                [107.9724944, 16.0185896],
                [107.9722381, 16.0186013],
                [107.9717714, 16.0190859],
                [107.9714495, 16.0191169],
                [107.9713229, 16.0191451],
                [107.9711988, 16.0191823],
                [107.971078, 16.0192283],
                [107.9709611, 16.0192827],
                [107.9708486, 16.0193454],
                [107.9707414, 16.0194159],
                [107.970555, 16.0195691],
                [107.9704706, 16.0196548],
                [107.9702417, 16.0199223],
                [107.9701586, 16.0200041],
                [107.9700297, 16.0201143],
                [107.9699648, 16.0201962],
                [107.9699277, 16.0202928],
                [107.9699212, 16.0203921],
                [107.9699429, 16.0204858],
                [107.9699879, 16.0205946],
                [107.9700068, 16.0206613],
                [107.9700202, 16.0207291],
                [107.97003, 16.0208667],
                [107.9700172, 16.021004],
                [107.9700172, 16.0221487],
                [107.9700277, 16.0222516],
                [107.9700284, 16.022355],
                [107.9700194, 16.022458],
                [107.9700007, 16.0225599],
                [107.9699724, 16.0226597],
                [107.9699349, 16.0227566],
                [107.9698405, 16.0229282],
                [107.9697258, 16.0230726],
                [107.9696599, 16.0231378],
                [107.9695129, 16.023252],
                [107.9694936, 16.023408],
                [107.9694447, 16.0235578],
                [107.969368, 16.0236965],
                [107.9693201, 16.0237601],
                [107.9692263, 16.0238566],
                [107.969163, 16.0239444],
                [107.9691406, 16.0239934],
                [107.969116, 16.0240976],
                [107.9691213, 16.0242146],
                [107.96916, 16.0243255],
                [107.9694486, 16.0247576],
                [107.9694702, 16.0248994],
                [107.9695012, 16.0250395],
                [107.9695416, 16.0251774],
                [107.9695911, 16.0253125],
                [107.9696496, 16.0254443],
                [107.9697758, 16.0256882],
                [107.969805, 16.0258143],
                [107.9698026, 16.0259435],
                [107.9697759, 16.0260502],
                [107.9697275, 16.0261497],
                [107.9688156, 16.0274284],
                [107.9685906, 16.0278959],
                [107.9684814, 16.0280548],
                [107.9683435, 16.0281914],
                [107.9682648, 16.0282503],
                [107.9681806, 16.0283017],
                [107.9679993, 16.0283809],
                [107.9679036, 16.028408],
                [107.9665732, 16.0286348],
                [107.9664659, 16.0286882],
                [107.9662617, 16.0288121],
                [107.9660589, 16.0289707],
                [107.9659587, 16.0290658],
                [107.9658654, 16.0291671],
                [107.9657793, 16.0292742],
                [107.9653072, 16.0300269],
                [107.9652883, 16.0301161],
                [107.9652274, 16.0302882],
                [107.9651372, 16.030448],
                [107.9650265, 16.0305847],
                [107.9649014, 16.0306971],
                [107.9647601, 16.03079],
                [107.9639983, 16.0311612],
                [107.9639246, 16.0312513],
                [107.9638436, 16.0313352],
                [107.9637556, 16.0314125],
                [107.9636613, 16.0314826],
                [107.9635614, 16.0315451],
                [107.9633633, 16.0316393],
                [107.9632674, 16.0316728],
                [107.9630694, 16.03172],
                [107.9629683, 16.0317335],
                [107.9623304, 16.0317367],
                [107.9622372, 16.0317438],
                [107.9620537, 16.0317773],
                [107.9619512, 16.031808],
                [107.961756, 16.0318933],
                [107.9612946, 16.0322439],
                [107.9611818, 16.0323857],
                [107.9611344, 16.0324625],
                [107.9610586, 16.0326254],
                [107.9610058, 16.0328198],
                [107.9609929, 16.0329195],
                [107.9609942, 16.0331204],
                [107.9604578, 16.0341],
                [107.9604578, 16.0347393],
                [107.9598677, 16.0359354],
                [107.958515, 16.0363691],
                [107.9584424, 16.036399],
                [107.958374, 16.0364372],
                [107.958311, 16.0364832],
                [107.9582543, 16.0365361],
                [107.9581667, 16.0366535],
                [107.9581116, 16.03678],
                [107.9580867, 16.036915],
                [107.9580956, 16.0369879],
                [107.958095, 16.0370613],
                [107.9580851, 16.0371341],
                [107.9580658, 16.0372052],
                [107.9580376, 16.0372734],
                [107.9579632, 16.0373889],
                [107.9579089, 16.0375025],
                [107.9578931, 16.0375633],
                [107.9578854, 16.0376881],
                [107.9579095, 16.0378101],
                [107.9579633, 16.0379221],
                [107.9581472, 16.0381394],
                [107.9582276, 16.0382757],
                [107.9582815, 16.0384193],
                [107.9583093, 16.0385656],
                [107.9583228, 16.0395031],
                [107.9584398, 16.0408192],
                [107.9584507, 16.041043],
                [107.9584519, 16.041267],
                [107.9584435, 16.0414908],
                [107.9584255, 16.0417141],
                [107.9583979, 16.0419365],
                [107.9583645, 16.0421373],
                [107.9582745, 16.0425346],
                [107.9583074, 16.042727],
                [107.9583108, 16.042922],
                [107.9582855, 16.0431105],
                [107.9582632, 16.0432005],
                [107.9581994, 16.0433749],
                [107.9581878, 16.0440146],
                [107.9582117, 16.0442721],
                [107.9582691, 16.0445246],
                [107.9583454, 16.0447365],
                [107.9584508, 16.0449494],
                [107.9584746, 16.045071],
                [107.9584651, 16.0451817],
                [107.9584328, 16.0452751],
                [107.9582822, 16.0454964],
                [107.9582292, 16.0455914],
                [107.9581491, 16.0457785],
                [107.9581067, 16.0459632],
                [107.9581002, 16.0461523],
                [107.9581296, 16.0463393],
                [107.9581895, 16.046508],
                [107.9582787, 16.0466643],
                [107.9583884, 16.0467957],
                [107.9584787, 16.0469288],
                [107.9585131, 16.0470012],
                [107.9585588, 16.0471539],
                [107.9585696, 16.0472323],
                [107.9585669, 16.0473903],
                [107.9585534, 16.0474683],
                [107.9585165, 16.0475668],
                [107.958463, 16.0477695],
                [107.9584375, 16.0479772],
                [107.9584398, 16.0481782],
                [107.9584502, 16.0482741],
                [107.9584891, 16.0484633],
                [107.9588056, 16.0492057],
                [107.9589844, 16.0493384],
                [107.9590657, 16.0494139],
                [107.9592098, 16.0495811],
                [107.9593267, 16.049767],
                [107.9594144, 16.0499688],
                [107.9594702, 16.0501825],
                [107.9594922, 16.0504017],
                [107.9595625, 16.0505124],
                [107.9596818, 16.0507449],
                [107.9597711, 16.0509894],
                [107.9598292, 16.0512424],
                [107.9598462, 16.0513709],
                [107.9598516, 16.0522989],
                [107.9602164, 16.0527165],
                [107.9602783, 16.0527993],
                [107.9603321, 16.0528872],
                [107.9603773, 16.0529794],
                [107.9604399, 16.0531697],
                [107.9605651, 16.0539279],
                [107.9608768, 16.0547556],
                [107.9609228, 16.054912],
                [107.9609356, 16.0549924],
                [107.9609406, 16.0551549],
                [107.9609133, 16.0553353],
                [107.9608869, 16.0554229],
                [107.960726, 16.0556575],
                [107.960702, 16.0557121],
                [107.9606761, 16.0558278],
                [107.9606806, 16.0559461],
                [107.9607153, 16.0560596],
                [107.960782, 16.0561663],
                [107.9608753, 16.0562527],
                [107.9609667, 16.0563142],
                [107.9609974, 16.0563481],
                [107.9610364, 16.0564284],
                [107.9610425, 16.0565158],
                [107.9609766, 16.0570861],
                [107.9609675, 16.0572549],
                [107.9609669, 16.0574239],
                [107.960991, 16.057761],
                [107.9610157, 16.0579283],
                [107.9610872, 16.058248],
                [107.9611345, 16.058405],
                [107.9612517, 16.0587119],
                [107.9613912, 16.0592893],
                [107.9614434, 16.0594052],
                [107.9615238, 16.0596455],
                [107.9615709, 16.0598939],
                [107.961584, 16.060146],
                [107.9615629, 16.0603976],
                [107.961539, 16.0605243],
                [107.9614654, 16.0607717],
                [107.9613582, 16.0610075],
                [107.9612927, 16.0611198],
                [107.9611441, 16.0613453],
                [107.9610772, 16.0614675],
                [107.961019, 16.0615939],
                [107.9609698, 16.0617238],
                [107.9609299, 16.0618565],
                [107.9608994, 16.0619912],
                [107.9608785, 16.0621276],
                [107.9608671, 16.0622651],
                [107.9608655, 16.0624029],
                [107.9609084, 16.0643309],
                [107.9605355, 16.0643599],
                [107.9597926, 16.0644443],
                [107.9590758, 16.0645598],
                [107.9585251, 16.0646734],
                [107.9583262, 16.064692],
                [107.9581283, 16.0646853],
                [107.9579347, 16.0646543],
                [107.95784, 16.0646299],
                [107.9571211, 16.0643412],
                [107.9559946, 16.0639082],
                [107.9553938, 16.0639082],
                [107.9551394, 16.0637817],
                [107.9550075, 16.0637278],
                [107.954879, 16.0636822],
                [107.9546159, 16.0636092],
                [107.9544731, 16.0635605],
                [107.9541939, 16.063447],
                [107.954058, 16.0633824],
                [107.9538899, 16.0632932],
                [107.9537266, 16.063196],
                [107.9535686, 16.0630912],
                [107.9534162, 16.0629788],
                [107.9532699, 16.0628593],
                [107.95313, 16.0627329],
                [107.9529114, 16.0627013],
                [107.9526946, 16.0626602],
                [107.9524799, 16.0626097],
                [107.9522679, 16.0625499],
                [107.9520588, 16.0624809],
                [107.9518533, 16.0624029],
                [107.9514895, 16.0622384],
                [107.9511412, 16.0620456],
                [107.9509735, 16.061939],
                [107.9496807, 16.0618926],
                [107.948699, 16.0613822],
                [107.9481089, 16.0613513],
                [107.9477978, 16.060877],
                [107.9477191, 16.0607386],
                [107.947617, 16.0606229],
                [107.9474985, 16.0605323],
                [107.947433, 16.0604956],
                [107.9468054, 16.0602687],
                [107.9461128, 16.0602054],
                [107.9459526, 16.0601793],
                [107.9457944, 16.0601442],
                [107.9456385, 16.0601],
                [107.9454857, 16.0600471],
                [107.9452143, 16.0599274],
                [107.9449581, 16.0597801],
                [107.9448366, 16.0596965],
                [107.9442197, 16.0597017],
                [107.9435492, 16.0594852],
                [107.9433955, 16.0593878],
                [107.9433251, 16.0593308],
                [107.9432595, 16.0592687],
                [107.9430706, 16.0590636],
                [107.9428571, 16.058882],
                [107.942736, 16.0587968],
                [107.9426094, 16.0587193],
                [107.942478, 16.0586496],
                [107.9423422, 16.0585882],
                [107.9414302, 16.0582119],
                [107.9406309, 16.0582067],
                [107.9382384, 16.0569489],
                [107.9379272, 16.0565365],
                [107.9377605, 16.0563535],
                [107.9376678, 16.0562707],
                [107.9375694, 16.056194],
                [107.9373663, 16.0560657],
                [107.9371567, 16.0559676],
                [107.9370475, 16.0559282],
                [107.9368114, 16.0556807],
                [107.935245, 16.055258],
                [107.9348373, 16.0549693],
                [107.9341936, 16.0547322],
                [107.9339974, 16.0546695],
                [107.9338158, 16.0545744],
                [107.9337323, 16.0545156],
                [107.9336528, 16.0544484],
                [107.93358, 16.0543744],
                [107.9335145, 16.0542945],
                [107.9334569, 16.0542091],
                [107.9334075, 16.0541191],
                [107.9333669, 16.0540251],
                [107.9333353, 16.0539279],
                [107.9331397, 16.0538535],
                [107.9330469, 16.0538058],
                [107.932874, 16.0536908],
                [107.9327165, 16.0535471],
                [107.9325843, 16.0533815],
                [107.9319835, 16.0532268],
                [107.9296231, 16.0516906],
                [107.9276275, 16.0509482],
                [107.9239797, 16.0490304],
                [107.9225314, 16.0480921],
                [107.9212224, 16.0475354],
                [107.9203373, 16.0465868],
                [107.920195, 16.0465026],
                [107.920048, 16.0464263],
                [107.9198968, 16.0463581],
                [107.9197419, 16.0462981],
                [107.9195819, 16.0462461],
                [107.9194192, 16.0462028],
                [107.9192542, 16.0461686],
                [107.9190874, 16.0461434],
                [107.917639, 16.0461331],
                [107.9171562, 16.0458135],
                [107.9161048, 16.0452773],
                [107.9157642, 16.0450423],
                [107.9154578, 16.0447989],
                [107.915184, 16.0445513],
                [107.9150534, 16.0444215],
                [107.9148872, 16.0443254],
                [107.9147361, 16.0442086],
                [107.9145919, 16.0440602],
                [107.9145238, 16.0439718],
                [107.9144109, 16.0437806],
                [107.9143667, 16.0436791],
                [107.9141978, 16.0435668],
                [107.9140234, 16.0434626],
                [107.9137102, 16.0433022],
                [107.9133839, 16.0431678],
                [107.9130471, 16.0430604],
                [107.912951, 16.0429243],
                [107.9128472, 16.0427936],
                [107.9127359, 16.0426686],
                [107.9125208, 16.0424619],
                [107.9124054, 16.0423666],
                [107.9108155, 16.0412457],
                [107.9105462, 16.0410312],
                [107.9104203, 16.0409145],
                [107.9101982, 16.040677],
                [107.9100103, 16.0404345],
                [107.909925, 16.0403074],
                [107.9090238, 16.0395753],
                [107.9079821, 16.0386658],
                [107.9069445, 16.0377242],
                [107.9059124, 16.03675],
                [107.9048073, 16.0360488],
                [107.9045386, 16.035904],
                [107.9042872, 16.0357326],
                [107.9040561, 16.0355367],
                [107.903949, 16.0354302],
                [107.903641, 16.0350909],
                [107.9034789, 16.0349284],
                [107.9031392, 16.0346184],
                [107.902962, 16.0344712],
                [107.902754, 16.0343098],
                [107.9025401, 16.0341557],
                [107.9023206, 16.034009],
                [107.9020959, 16.0338699],
                [107.9018661, 16.0337387],
                [107.9016316, 16.0336154],
                [107.9010442, 16.034515],
                [107.9009209, 16.034688],
                [107.9006543, 16.03502],
                [107.9003623, 16.0353316],
                [107.9000519, 16.0356164],
                [107.899726, 16.0358741],
                [107.8993812, 16.0361081],
                [107.8983995, 16.0367371],
                [107.8981785, 16.0369529],
                [107.8980776, 16.0370693],
                [107.8978965, 16.0373171],
                [107.897817, 16.0374479],
                [107.897691, 16.0376981],
                [107.8976425, 16.0378158],
                [107.8975627, 16.038057],
                [107.8975123, 16.0381753],
                [107.8974547, 16.0382905],
                [107.8973187, 16.0385102],
                [107.897155, 16.0387146],
                [107.8969651, 16.0389004],
                [107.8967532, 16.0390628],
                [107.89664, 16.0391345],
                [107.896387, 16.0392583],
                [107.8958729, 16.0394902],
                [107.895576, 16.0396126],
                [107.8949734, 16.0398363],
                [107.8943601, 16.0400316],
                [107.8936145, 16.0400367],
                [107.8934804, 16.0400522],
                [107.893423, 16.0400677],
                [107.8933141, 16.0401141],
                [107.893269, 16.0401601],
                [107.8931987, 16.0402667],
                [107.8931746, 16.0403254],
                [107.8931507, 16.0404424],
                [107.8931497, 16.0405021],
                [107.8931699, 16.0406198],
                [107.8934857, 16.0414184],
                [107.8934804, 16.0421814],
                [107.8927347, 16.0425114],
                [107.8925597, 16.0426014],
                [107.8924777, 16.0426553],
                [107.892327, 16.0427795],
                [107.8922495, 16.0428596],
                [107.8921792, 16.0429457],
                [107.8921165, 16.0430371],
                [107.892062, 16.0431331],
                [107.8920159, 16.0432332],
                [107.8917316, 16.0441663],
                [107.8916827, 16.0443885],
                [107.8916687, 16.0445013],
                [107.8916618, 16.0447282],
                [107.8916844, 16.0449625],
                [107.8917369, 16.0451922],
                [107.8922433, 16.0469824],
                [107.8922787, 16.0471564],
                [107.8923203, 16.0474954],
                [107.8923098, 16.0475649],
                [107.8922711, 16.0476167],
                [107.8922468, 16.0476335],
                [107.8921902, 16.0476514],
                [107.8908679, 16.0476488],
                [107.89009, 16.0475122],
                [107.8899606, 16.0475165],
                [107.8897036, 16.047546],
                [107.8895767, 16.047571],
                [107.8893283, 16.047641],
                [107.8890983, 16.0477332],
                [107.8888799, 16.0478484],
                [107.8887758, 16.0479143],
                [107.8882393, 16.048404],
                [107.8880355, 16.0482958],
                [107.887617, 16.0474451],
                [107.8864691, 16.0471513],
                [107.8861829, 16.0473689],
                [107.8858773, 16.0475606],
                [107.885569, 16.0477183],
                [107.8852622, 16.0478442],
                [107.8849456, 16.0479452],
                [107.8836152, 16.0482442],
                [107.8835615, 16.0485226],
                [107.8831222, 16.0492127],
                [107.8829737, 16.049404],
                [107.8827998, 16.0495743],
                [107.8825993, 16.0497234],
                [107.8824915, 16.0497876],
                [107.8822634, 16.0498939],
                [107.8818557, 16.0504816],
                [107.8806487, 16.0516674],
                [107.8790769, 16.0512292],
                [107.8779986, 16.0520076],
                [107.8765503, 16.0519921],
                [107.8763332, 16.0519077],
                [107.8761075, 16.0518477],
                [107.8758517, 16.0518109],
                [107.8757102, 16.051804],
                [107.8755686, 16.0518066],
                [107.8751716, 16.0521829],
                [107.8747819, 16.0522062],
                [107.8746177, 16.0522425],
                [107.8744506, 16.0523109],
                [107.8743672, 16.0523595],
                [107.8742892, 16.0524157],
                [107.8742174, 16.0524791],
                [107.8735167, 16.0532449],
                [107.8731358, 16.0532474],
                [107.8730046, 16.0532877],
                [107.8728864, 16.0533557],
                [107.8727847, 16.0534512],
                [107.8727434, 16.053507],
                [107.8725323, 16.0538738],
                [107.8718671, 16.0539151],
                [107.8716903, 16.0540752],
                [107.871522, 16.0542706],
                [107.8714425, 16.0543844],
                [107.8713715, 16.0545032],
                [107.8713092, 16.0546265],
                [107.8709801, 16.0548833],
                [107.8708562, 16.0550233],
                [107.870762, 16.0551832],
                [107.870704, 16.0553446],
                [107.8706762, 16.0555132],
                [107.8706548, 16.0561009],
                [107.8698716, 16.0568999],
                [107.8697763, 16.0569921],
                [107.8696745, 16.0570776],
                [107.8695666, 16.0571558],
                [107.8694531, 16.0572264],
                [107.8693346, 16.057289],
                [107.8690958, 16.0573854],
                [107.8688566, 16.0574474],
                [107.8686672, 16.0574806],
                [107.8685379, 16.0575246],
                [107.8684208, 16.0575882],
                [107.8683185, 16.0576684],
                [107.868273, 16.0577144],
                [107.8679994, 16.058065],
                [107.8679477, 16.0581501],
                [107.8678681, 16.0583317],
                [107.8678223, 16.0585238],
                [107.8677957, 16.0587132],
                [107.8677713, 16.058806],
                [107.8677013, 16.058985],
                [107.8676026, 16.0591543],
                [107.8675425, 16.0592342],
                [107.8674039, 16.0593795],
                [107.8665027, 16.059395],
                [107.8661755, 16.0591527],
                [107.8656122, 16.059096],
                [107.8655431, 16.0591029],
                [107.8654106, 16.0591419],
                [107.8653015, 16.0592053],
                [107.8652179, 16.059283],
                [107.8651831, 16.0593279],
                [107.8651642, 16.0593929],
                [107.8651049, 16.0595148],
                [107.8650212, 16.059619],
                [107.8649722, 16.0596631],
                [107.8648612, 16.0597352],
                [107.8629997, 16.0614828],
                [107.8612885, 16.0614931],
                [107.8612132, 16.0613713],
                [107.861167, 16.061316],
                [107.8610593, 16.0612193],
                [107.8609988, 16.0611786],
                [107.8607623, 16.0610673],
                [107.8606402, 16.0610197],
                [107.8603731, 16.0609367],
                [107.8602282, 16.0609032],
                [107.8599339, 16.0608583],
                [107.8597853, 16.0608472],
                [107.8578767, 16.0608229],
                [107.8569862, 16.0606683],
                [107.8554252, 16.0606631],
                [107.8549336, 16.0606867],
                [107.8544447, 16.0607418],
                [107.8542019, 16.0607811],
                [107.8537338, 16.0608797],
                [107.8535087, 16.0609382],
                [107.8530648, 16.0610755],
                [107.8526518, 16.0614261],
                [107.8524586, 16.0614138],
                [107.8523637, 16.061395],
                [107.8521812, 16.0613331],
                [107.8520951, 16.0612905],
                [107.8519487, 16.0611931],
                [107.8514841, 16.0607571],
                [107.8511599, 16.060408],
                [107.85086, 16.0600394],
                [107.8505905, 16.0596595],
                [107.850347, 16.0592637],
                [107.8501305, 16.0588537],
                [107.8498916, 16.0589528],
                [107.8496416, 16.0590217],
                [107.8495136, 16.0590444],
                [107.8492565, 16.059066],
                [107.8491273, 16.059065],
                [107.8489736, 16.0590529],
                [107.8488966, 16.0590592],
                [107.8487472, 16.0590965],
                [107.8486124, 16.0591633],
                [107.8484979, 16.0592549],
                [107.8484066, 16.059368],
                [107.8481242, 16.0599259],
                [107.8480749, 16.0600088],
                [107.8479561, 16.0601616],
                [107.847813, 16.0602938],
                [107.8476494, 16.0604017],
                [107.8474697, 16.0604827],
                [107.8473668, 16.0605146],
                [107.8472613, 16.0605376],
                [107.847154, 16.0605514],
                [107.8470459, 16.0605561],
                [107.8469379, 16.0605514],
                [107.8468306, 16.0605376],
                [107.8464897, 16.0604499],
                [107.8463553, 16.0604252],
                [107.846083, 16.0604003],
                [107.8459462, 16.0604002],
                [107.8456981, 16.0604212],
                [107.8455754, 16.0604419],
                [107.8453347, 16.0605033],
                [107.8450249, 16.0604531],
                [107.8448728, 16.0604154],
                [107.844584, 16.060318],
                [107.8443154, 16.0601941],
                [107.8441867, 16.0601218],
                [107.8423521, 16.0595238],
                [107.8422098, 16.059641],
                [107.841939, 16.0598899],
                [107.8416386, 16.0602146],
                [107.8396699, 16.0604105],
                [107.839348, 16.0597507],
                [107.8390425, 16.0594853],
                [107.8388814, 16.0593621],
                [107.838715, 16.0592455],
                [107.8384076, 16.0590553],
                [107.8380863, 16.0588876],
                [107.8379211, 16.0588124],
                [107.8364512, 16.0584103],
                [107.8352281, 16.0579567],
                [107.8347561, 16.0575752],
                [107.8336081, 16.0568535],
                [107.8335437, 16.0558328],
                [107.833489, 16.0556448],
                [107.8334524, 16.0555537],
                [107.8333613, 16.0553791],
                [107.8332947, 16.0552787],
                [107.8332202, 16.0551837],
                [107.8331381, 16.0550945],
                [107.8330491, 16.0550117],
                [107.8329536, 16.0549358],
                [107.8317788, 16.0544254],
                [107.8316729, 16.0544735],
                [107.8314527, 16.0545506],
                [107.8313394, 16.0545792],
                [107.8311083, 16.0546162],
                [107.8308597, 16.0546253],
                [107.8307355, 16.0546182],
                [107.8304788, 16.0545848],
                [107.8303445, 16.0545756],
                [107.8302099, 16.0545758],
                [107.8300756, 16.0545852],
                [107.8298581, 16.0546207],
                [107.8296465, 16.0546806],
                [107.8281257, 16.0552245],
                [107.8272727, 16.0553276],
                [107.826809, 16.0553489],
                [107.8263447, 16.0553482],
                [107.8260729, 16.0553376],
                [107.8255309, 16.0552937],
                [107.8252611, 16.0552606],
                [107.8243437, 16.0558792],
                [107.8235659, 16.0559307],
                [107.8228363, 16.0562091],
                [107.8217474, 16.056271],
                [107.8200415, 16.0566576],
                [107.8192368, 16.0572917],
                [107.8187648, 16.0573896],
                [107.8173459, 16.0558508],
                [107.8169796, 16.055425],
                [107.8167187, 16.0551045],
                [107.8163266, 16.0545878],
                [107.8159734, 16.054051],
                [107.8154039, 16.0531856],
                [107.8151037, 16.0530522],
                [107.8149489, 16.0529964],
                [107.8146315, 16.0529072],
                [107.8144552, 16.0528714],
                [107.8142772, 16.0528449],
                [107.8140979, 16.0528276],
                [107.813918, 16.0528196],
                [107.8071856, 16.0531804],
                [107.806854, 16.0534195],
                [107.806545, 16.0536852],
                [107.8062609, 16.0539754],
                [107.8061289, 16.054129],
                [107.805909, 16.0544187],
                [107.805808, 16.0545694],
                [107.8056246, 16.0548816],
                [107.804836, 16.0566241],
                [107.8033347, 16.0576771],
                [107.8031802, 16.0577989],
                [107.8028897, 16.0580626],
                [107.8026259, 16.0583511],
                [107.8025109, 16.0584957],
                [107.8023014, 16.058799],
                [107.8021204, 16.0591188],
                [107.8016525, 16.0601261],
                [107.8016142, 16.0601945],
                [107.8015198, 16.0603204],
                [107.8013986, 16.0604332],
                [107.8012518, 16.0605264],
                [107.8011721, 16.0605626],
                [107.8006223, 16.0607688],
                [107.7983826, 16.0616709],
                [107.796607, 16.0617998],
                [107.793678, 16.0630215],
                [107.7935756, 16.063199],
                [107.7933884, 16.0635628],
                [107.793316, 16.0637208],
                [107.7931845, 16.0640422],
                [107.7927315, 16.064244],
                [107.7925107, 16.064356],
                [107.7921038, 16.064588],
                [107.7917348, 16.0648302],
                [107.7913821, 16.0650938],
                [107.7871013, 16.0677228],
                [107.7858024, 16.0668557],
                [107.7851593, 16.0664135],
                [107.7839281, 16.0655425],
                [107.7827132, 16.0646505],
                [107.7813882, 16.0624699],
                [107.7809536, 16.0624854],
                [107.7808359, 16.0625067],
                [107.7806059, 16.0625709],
                [107.7804946, 16.0626136],
                [107.7802818, 16.0627193],
                [107.7801812, 16.0627818],
                [107.7799962, 16.0629227],
                [107.7799111, 16.0630014],
                [107.7797574, 16.0631736],
                [107.7793872, 16.0637948],
                [107.7791341, 16.0640427],
                [107.7789987, 16.0641578],
                [107.7787119, 16.0643691],
                [107.7784172, 16.0645476],
                [107.7781188, 16.0646946],
                [107.7779649, 16.0647586],
                [107.7776492, 16.064867],
                [107.7770242, 16.0651048],
                [107.7764197, 16.0653631],
                [107.775836, 16.0656402],
                [107.7739531, 16.0667434],
                [107.7740269, 16.0662883],
                [107.7740765, 16.0660629],
                [107.7741845, 16.0656694],
                [107.7742481, 16.0654753],
                [107.7747976, 16.0644909],
                [107.7749542, 16.0641624],
                [107.775084, 16.0638233],
                [107.7751386, 16.0636504],
                [107.7752346, 16.0632603],
                [107.7752955, 16.0628637],
                [107.7753126, 16.0626638],
                [107.7753208, 16.0624635],
                [107.7753103, 16.0620627],
                [107.7753151, 16.0616264],
                [107.775284, 16.061191],
                [107.7752549, 16.0609746],
                [107.7751702, 16.0605458],
                [107.7751147, 16.0603342],
                [107.7749777, 16.0599182],
                [107.7748132, 16.0595276],
                [107.7747194, 16.0593371],
                [107.7745094, 16.0589671],
                [107.7743935, 16.0587882],
                [107.7741408, 16.0584439],
                [107.7738564, 16.0582762],
                [107.7735774, 16.0581005],
                [107.7733038, 16.0579169],
                [107.773036, 16.0577256],
                [107.7727742, 16.0575268],
                [107.7725187, 16.0573207],
                [107.7722696, 16.0571073],
                [107.7718202, 16.056688],
                [107.7714225, 16.0562749],
                [107.7710478, 16.0558425],
                [107.7706969, 16.055392],
                [107.7706057, 16.0548835],
                [107.7705713, 16.0546276],
                [107.7705445, 16.0543709],
                [107.7704963, 16.0536866],
                [107.7704693, 16.0534743],
                [107.7703903, 16.0530532],
                [107.7703384, 16.0528452],
                [107.7702119, 16.0524398],
                [107.7700562, 16.0520488],
                [107.7699671, 16.0518577],
                [107.7698707, 16.0516699],
                [107.769697, 16.0515045],
                [107.7695009, 16.051364],
                [107.7692863, 16.0512512],
                [107.7690567, 16.0511682],
                [107.7688166, 16.0511168],
                [107.7685713, 16.0510983],
                [107.7683258, 16.0511132],
                [107.7677103, 16.0511129],
                [107.7670958, 16.0510798],
                [107.7667895, 16.051051],
                [107.76618, 16.0509688],
                [107.7658539, 16.0509111],
                [107.7655298, 16.0508441],
                [107.7652079, 16.0507677],
                [107.7648885, 16.0506821],
                [107.764572, 16.0505874],
                [107.7642585, 16.0504836],
                [107.7639484, 16.0503708],
                [107.7611804, 16.0492366],
                [107.760601, 16.0484737],
                [107.7603416, 16.0482803],
                [107.7601033, 16.0480632],
                [107.7598887, 16.0478244],
                [107.7596998, 16.0475663],
                [107.7595282, 16.0472716],
                [107.7593901, 16.0469611],
                [107.7592871, 16.0466383],
                [107.7592492, 16.0464734],
                [107.7573824, 16.0437461],
                [107.7572974, 16.043665],
                [107.7572061, 16.0435907],
                [107.7571089, 16.0435234],
                [107.7570065, 16.0434638],
                [107.7568996, 16.043412],
                [107.7567887, 16.0433686],
                [107.7566746, 16.0433337],
                [107.756558, 16.0433076],
                [107.7564397, 16.0432903],
                [107.7563202, 16.0432821],
                [107.7561015, 16.0432906],
                [107.7559932, 16.0433062],
                [107.7557814, 16.0433597],
                [107.7555799, 16.0434419],
                [107.7549362, 16.0439111],
                [107.754871, 16.0439677],
                [107.7547251, 16.0440613],
                [107.7546457, 16.0440975],
                [107.7544778, 16.0441467],
                [107.7543032, 16.0441637],
                [107.7541304, 16.0441483],
                [107.7539638, 16.0441013],
                [107.7538097, 16.0440245],
                [107.7528012, 16.0427975],
                [107.7521574, 16.0429676],
                [107.7518087, 16.0429956],
                [107.7514592, 16.0430142],
                [107.7511093, 16.0430236],
                [107.7507594, 16.0430236],
                [107.7504095, 16.0430142],
                [107.75006, 16.0429956],
                [107.7497113, 16.0429676],
                [107.7490875, 16.0428942],
                [107.7484683, 16.042791],
                [107.7478552, 16.0426583],
                [107.7437836, 16.0414468],
                [107.7430379, 16.0403383],
                [107.741632, 16.0393948],
                [107.7413814, 16.039262],
                [107.7412501, 16.0392071],
                [107.7409781, 16.0391213],
                [107.7408385, 16.0390907],
                [107.7405642, 16.0390554],
                [107.7402875, 16.0390519],
                [107.7400124, 16.0390804],
                [107.7388966, 16.039266],
                [107.7387679, 16.0388123],
                [107.7386815, 16.0386039],
                [107.7386265, 16.0385049],
                [107.738564, 16.0384101],
                [107.7384192, 16.0382363],
                [107.7382494, 16.0380847],
                [107.7381563, 16.0380183],
                [107.7360856, 16.0367294],
                [107.7357617, 16.0365681],
                [107.7354247, 16.0364337],
                [107.7352521, 16.0363769],
                [107.7349085, 16.0362866],
                [107.7345667, 16.036225],
                [107.7343942, 16.0362043],
                [107.7340472, 16.0361829],
                [107.7328026, 16.0348218],
                [107.7324052, 16.0345937],
                [107.7322133, 16.0344692],
                [107.7318446, 16.0342002],
                [107.7314975, 16.0339057],
                [107.7313328, 16.0337494],
                [107.731011, 16.0334073],
                [107.7307174, 16.0330425],
                [107.7305817, 16.0328523],
                [107.7300453, 16.0316819],
                [107.7299179, 16.0315857],
                [107.7296494, 16.0314112],
                [107.7295089, 16.0313334],
                [107.7292172, 16.0311975],
                [107.7290667, 16.0311398],
                [107.7287417, 16.0310412],
                [107.7285676, 16.0310022],
                [107.7282233, 16.0309097],
                [107.7275401, 16.0307075],
                [107.7268837, 16.0304894],
                [107.7265584, 16.0303724],
                [107.7265155, 16.0298929],
                [107.7263266, 16.0292916],
                [107.7261635, 16.0286834],
                [107.7260189, 16.0280311],
                [107.7259543, 16.0276837],
                [107.7258981, 16.0273349],
                [107.7258359, 16.0268541],
                [107.7258309, 16.0267226],
                [107.7258354, 16.0265912],
                [107.7258493, 16.0264603],
                [107.7258727, 16.0263306],
                [107.7259052, 16.0262028],
                [107.7259942, 16.0259625],
                [107.7261115, 16.0257426],
                [107.7262575, 16.0255392],
                [107.7264297, 16.0253557],
                [107.7271539, 16.0247885],
                [107.7273157, 16.0246699],
                [107.7274533, 16.0245377],
                [107.7275669, 16.0243967],
                [107.7277386, 16.0236336],
                [107.728047, 16.0224513],
                [107.7280799, 16.0222697],
                [107.7281155, 16.0219375],
                [107.7281209, 16.0216382],
                [107.7281141, 16.0214887],
                [107.7280427, 16.0210586],
                [107.728019, 16.020842],
                [107.7279957, 16.0204071],
                [107.7279961, 16.0201894],
                [107.7280184, 16.0197843],
                [107.72804, 16.0195825],
                [107.7280685, 16.0193815],
                [107.7281655, 16.0188737],
                [107.7281763, 16.0187635],
                [107.7281715, 16.0185347],
                [107.7281547, 16.0184175],
                [107.7281284, 16.018302],
                [107.7280927, 16.0181888],
                [107.7270788, 16.0166471],
                [107.727021, 16.0165395],
                [107.7269258, 16.0163154],
                [107.7268888, 16.0161997],
                [107.7268364, 16.0159629],
                [107.7268213, 16.0158427],
                [107.7268137, 16.0155894],
                [107.7268222, 16.0154629],
                [107.7268637, 16.0152127],
                [107.7268964, 16.0150899],
                [107.7274032, 16.0134536],
                [107.7266872, 16.0119291],
                [107.726529, 16.0117709],
                [107.7263769, 16.0116074],
                [107.726231, 16.0114387],
                [107.7260915, 16.011265],
                [107.7259586, 16.0110866],
                [107.7258325, 16.0109038],
                [107.7257133, 16.0107167],
                [107.7256012, 16.0105255],
                [107.7254963, 16.0103307],
                [107.7254035, 16.0101424],
                [107.7253175, 16.0099512],
                [107.7252384, 16.0097573],
                [107.7251662, 16.0095609],
                [107.725101, 16.0093622],
                [107.7250429, 16.0091616],
                [107.724992, 16.0089591],
                [107.7249439, 16.0088214],
                [107.7248875, 16.0086867],
                [107.7248232, 16.0085553],
                [107.724751, 16.0084277],
                [107.7246713, 16.0083043],
                [107.7245843, 16.0081856],
                [107.7244916, 16.0080733],
                [107.7243923, 16.0079663],
                [107.7242868, 16.0078649],
                [107.7241754, 16.0077695],
                [107.7240586, 16.0076803],
                [107.7203464, 16.0055352],
                [107.7191663, 16.0040708],
                [107.7190155, 16.003891],
                [107.7188584, 16.0037164],
                [107.7186951, 16.0035471],
                [107.7185259, 16.0033832],
                [107.7183509, 16.0032251],
                [107.718161, 16.0030653],
                [107.7179652, 16.0029122],
                [107.7177637, 16.002766],
                [107.7175569, 16.002627],
                [107.7148103, 16.00108],
                [107.7112269, 15.9996671],
                [107.7085984, 15.9965937],
                [107.7084929, 15.9964627],
                [107.7083806, 15.996337],
                [107.7082617, 15.9962171],
                [107.7081366, 15.9961032],
                [107.7080055, 15.9959957],
                [107.7078688, 15.9958948],
                [107.7077269, 15.9958008],
                [107.7075801, 15.995714],
                [107.7074289, 15.9956345],
                [107.70727, 15.9955611],
                [107.7071073, 15.9954958],
                [107.7069413, 15.9954389],
                [107.7067723, 15.9953905],
                [107.7066009, 15.9953506],
                [107.7064276, 15.9953195],
                [107.7062528, 15.9952973],
                [107.7060771, 15.9952839],
                [107.7052082, 15.9953022],
                [107.7043391, 15.9953125],
                [107.70347, 15.9953148],
                [107.7025404, 15.9953084],
                [107.701611, 15.9952928],
                [107.7006818, 15.9952681],
                [107.6997529, 15.9952342],
                [107.6988244, 15.9951911],
                [107.6985133, 15.9951681],
                [107.6982029, 15.9951373],
                [107.6978935, 15.9950985],
                [107.6975852, 15.9950518],
                [107.6973472, 15.9950102],
                [107.6971078, 15.9949773],
                [107.6968672, 15.9949532],
                [107.6966259, 15.9949377],
                [107.6963841, 15.9949311],
                [107.6961422, 15.9949332],
                [107.6959213, 15.9949429],
                [107.6957008, 15.9949599],
                [107.6954811, 15.9949842],
                [107.6952624, 15.9950158],
                [107.6854992, 15.9971712],
                [107.6850102, 15.9972799],
                [107.6845199, 15.9973832],
                [107.6840284, 15.997481],
                [107.6835358, 15.9975735],
                [107.6829957, 15.9976683],
                [107.6824544, 15.9977567],
                [107.681912, 15.9978385],
                [107.6813686, 15.9979138],
                [107.681111, 15.9979384],
                [107.6808545, 15.9979719],
                [107.6805994, 15.9980143],
                [107.6803461, 15.9980656],
                [107.6800948, 15.9981256],
                [107.679846, 15.9981942],
                [107.6795999, 15.9982715],
                [107.6793569, 15.9983573],
                [107.6791218, 15.9984496],
                [107.6788902, 15.9985498],
                [107.6786625, 15.9986579],
                [107.6784388, 15.9987736],
                [107.6782196, 15.9988969],
                [107.6780051, 15.9990276],
                [107.6778564, 15.9990554],
                [107.6777097, 15.9990917],
                [107.6775654, 15.9991362],
                [107.6774242, 15.9991889],
                [107.6772865, 15.9992495],
                [107.6771527, 15.9993179],
                [107.6770234, 15.9993938],
                [107.6769086, 15.9994701],
                [107.6767983, 15.9995523],
                [107.6766928, 15.9996403],
                [107.6765925, 15.9997336],
                [107.6764977, 15.9998321],
                [107.6764564, 15.9998924],
                [107.6764227, 15.9999569],
                [107.676397, 16.0000248],
                [107.6763797, 16.0000951],
                [107.6763709, 16.0001671],
                [107.6763709, 16.0002396],
                [107.6763797, 16.0003116],
                [107.676487, 16.0008789],
                [107.6764655, 16.0042873],
                [107.6764107, 16.0044059],
                [107.6763483, 16.0045211],
                [107.6762787, 16.0046323],
                [107.6762021, 16.0047391],
                [107.6761188, 16.0048413],
                [107.6760291, 16.0049383],
                [107.6759334, 16.0050298],
                [107.6758319, 16.0051154],
                [107.6757252, 16.0051949],
                [107.67562, 16.005264],
                [107.6755107, 16.005327],
                [107.6752916, 16.005452],
                [107.6750682, 16.0055697],
                [107.6748407, 16.00568],
                [107.6746094, 16.0057827],
                [107.674377, 16.0058768],
                [107.6741413, 16.0059633],
                [107.6739028, 16.006042],
                [107.6736616, 16.0061129],
                [107.6734181, 16.0061758],
                [107.6731724, 16.0062308],
                [107.672925, 16.0062777],
                [107.6720452, 16.0087941],
                [107.6720071, 16.0089176],
                [107.6719782, 16.0090434],
                [107.6719587, 16.0091709],
                [107.6719487, 16.0092994],
                [107.6719481, 16.0094268],
                [107.6719569, 16.0095539],
                [107.671975, 16.0096801],
                [107.6720023, 16.0098047],
                [107.6722813, 16.0108257],
                [107.6723084, 16.0109164],
                [107.6723271, 16.011009],
                [107.6723372, 16.0111029],
                [107.6723386, 16.0111973],
                [107.6723312, 16.0112914],
                [107.6723152, 16.0113846],
                [107.6722907, 16.011476],
                [107.6722578, 16.0115649],
                [107.6722169, 16.0116507],
                [107.6721692, 16.0117311],
                [107.6721144, 16.0118072],
                [107.672053, 16.0118785],
                [107.6719853, 16.0119443],
                [107.671912, 16.0120043],
                [107.6718334, 16.0120578],
                [107.6717504, 16.0121047],
                [107.6716634, 16.0121444],
                [107.6715732, 16.0121766],
                [107.6693094, 16.0128366],
                [107.6670778, 16.0150126],
                [107.6668453, 16.0152531],
                [107.6666057, 16.0154869],
                [107.6663589, 16.0157138],
                [107.6661113, 16.0159288],
                [107.6658573, 16.0161368],
                [107.6655972, 16.0163377],
                [107.6654921, 16.0164063],
                [107.6653916, 16.016481],
                [107.665296, 16.0165613],
                [107.6652056, 16.0166471],
                [107.6651188, 16.0167402],
                [107.6650383, 16.0168383],
                [107.6649642, 16.016941],
                [107.6646209, 16.0176216],
                [107.6645707, 16.0177172],
                [107.6645141, 16.0178094],
                [107.6644514, 16.0178979],
                [107.6643828, 16.0179822],
                [107.6643087, 16.0180621],
                [107.6642293, 16.0181372],
                [107.6641461, 16.0182062],
                [107.6640586, 16.01827],
                [107.6639669, 16.0183282],
                [107.6638716, 16.0183806],
                [107.6637729, 16.018427],
                [107.6636714, 16.0184672],
                [107.6630384, 16.0186451],
                [107.662657, 16.0187461],
                [107.6622732, 16.0188378],
                [107.661887, 16.0189202],
                [107.6614988, 16.0189931],
                [107.6612082, 16.0190414],
                [107.6609167, 16.0190843],
                [107.6606244, 16.019122],
                [107.6592833, 16.01922],
                [107.6561934, 16.0194314],
                [107.6554263, 16.0194005],
                [107.6548201, 16.0193592],
                [107.6510704, 16.0192226],
                [107.650926, 16.0192311],
                [107.6508551, 16.0192452],
                [107.650719, 16.0192922],
                [107.6505964, 16.0193617],
                [107.6505406, 16.0194043],
                [107.6504427, 16.0195036],
                [107.6498473, 16.0204987],
                [107.6485916, 16.0220313],
                [107.6485327, 16.0221189],
                [107.6484334, 16.0223043],
                [107.6483116, 16.0226302],
                [107.6482058, 16.0228499],
                [107.6480747, 16.0230613],
                [107.6479214, 16.0232585],
                [107.6461378, 16.0236645],
                [107.6459482, 16.0236946],
                [107.6455729, 16.0237739],
                [107.6452044, 16.0238785],
                [107.6450186, 16.0239418],
                [107.6448354, 16.0240115],
                [107.6444775, 16.0241698],
                [107.6441676, 16.0243328],
                [107.6435617, 16.0246823],
                [107.6432662, 16.0248684],
                [107.6426911, 16.0252629],
                [107.6424373, 16.0254515],
                [107.6419441, 16.0258462],
                [107.6414712, 16.0262631],
                [107.641086, 16.0266237],
                [107.6407562, 16.0268941],
                [107.6404059, 16.0271396],
                [107.6402067, 16.0272627],
                [107.6400024, 16.0273778],
                [107.6397933, 16.0274846],
                [107.6395798, 16.027583],
                [107.6334643, 16.0303053],
                [107.6323003, 16.0309807],
                [107.6321048, 16.0309913],
                [107.6317134, 16.0309872],
                [107.6315182, 16.0309727],
                [107.6311322, 16.0309189],
                [107.630754, 16.0308329],
                [107.6305682, 16.030778],
                [107.6303852, 16.0307152],
                [107.6301774, 16.0306199],
                [107.629957, 16.0305562],
                [107.6297291, 16.0305254],
                [107.6294989, 16.0305284],
                [107.6292715, 16.0305651],
                [107.6291604, 16.0305959],
                [107.6289473, 16.0306817],
                [107.6288465, 16.0307361],
                [107.6285681, 16.0309102],
                [107.6281925, 16.0311174],
                [107.6280046, 16.0312094],
                [107.6276202, 16.0313758],
                [107.6272103, 16.0315228],
                [107.6269938, 16.0315875],
                [107.6265535, 16.0316916],
                [107.626106, 16.0317617],
                [107.625648, 16.0317974],
                [107.6254152, 16.0318019],
                [107.6251824, 16.0317972],
                [107.6249499, 16.0317834],
                [107.6247183, 16.0317604],
                [107.6244879, 16.0317283],
                [107.624259, 16.0316871],
                [107.6234436, 16.0314551],
                [107.6233055, 16.0314071],
                [107.6231645, 16.0313678],
                [107.6230211, 16.0313374],
                [107.622876, 16.0313159],
                [107.6227297, 16.0313035],
                [107.622583, 16.0313002],
                [107.6224362, 16.0313061],
                [107.6222902, 16.031321],
                [107.6220264, 16.0313716],
                [107.6217705, 16.0314512],
                [107.6215283, 16.0315573],
                [107.6213028, 16.0316877],
                [107.621094, 16.0318418],
                [107.6208491, 16.0321119],
                [107.6206277, 16.0324003],
                [107.6204314, 16.0327048],
                [107.6202592, 16.0330281],
                [107.6201134, 16.0333685],
                [107.6200517, 16.0335427],
                [107.6199511, 16.0338979],
                [107.6199125, 16.0340781],
                [107.6197767, 16.0348474],
                [107.6196997, 16.035439],
                [107.619651, 16.0360334],
                [107.6196298, 16.0367139],
                [107.6196331, 16.0370543],
                [107.6196517, 16.0375775],
                [107.6196677, 16.03776],
                [107.6196934, 16.0379414],
                [107.6197289, 16.0381214],
                [107.6197739, 16.0382993],
                [107.6198285, 16.0384748],
                [107.6198923, 16.0386473],
                [107.619965, 16.0388155],
                [107.6200465, 16.0389799],
                [107.6201367, 16.03914],
                [107.6202353, 16.0392955],
                [107.6203421, 16.0394459],
                [107.6204567, 16.0395909],
                [107.620579, 16.03973],
                [107.6214051, 16.0403177],
                [107.6216344, 16.0404952],
                [107.6218427, 16.0406953],
                [107.6220274, 16.0409157],
                [107.6221902, 16.0411603],
                [107.6223241, 16.0414205],
                [107.6224275, 16.0416933],
                [107.6224673, 16.0418334],
                [107.6229191, 16.0431819],
                [107.6230736, 16.0435399],
                [107.623258, 16.0438846],
                [107.6233611, 16.0440511],
                [107.6235882, 16.0443714],
                [107.6238543, 16.0446863],
                [107.6240041, 16.0448417],
                [107.6241611, 16.0449905],
                [107.6243248, 16.0451323],
                [107.624495, 16.045267],
                [107.6249195, 16.0456852],
                [107.6249793, 16.0457524],
                [107.6250812, 16.0458995],
                [107.6251617, 16.0460714],
                [107.6251918, 16.0461673],
                [107.6252128, 16.0462655],
                [107.6252246, 16.0463651],
                [107.6252139, 16.0473034],
                [107.6257254, 16.0485008],
                [107.6257793, 16.0486871],
                [107.6257982, 16.0488684],
                [107.6257963, 16.0489539],
                [107.6257718, 16.0491232],
                [107.6252916, 16.0501852],
                [107.6253238, 16.051407],
                [107.6250985, 16.0529381],
                [107.6249564, 16.0543197],
                [107.6249265, 16.0545353],
                [107.6248406, 16.0549624],
                [107.6247847, 16.0551732],
                [107.6246501, 16.0555809],
                [107.624572, 16.0557775],
                [107.6243931, 16.0561614],
                [107.6234812, 16.0578304],
                [107.6231369, 16.0582986],
                [107.622974, 16.0585387],
                [107.6226673, 16.0590304],
                [107.6225236, 16.0592815],
                [107.6222731, 16.0597593],
                [107.6221566, 16.0600022],
                [107.6218414, 16.060714],
                [107.6216213, 16.0611421],
                [107.6213778, 16.0615539],
                [107.6212479, 16.061753],
                [107.6209706, 16.06214],
                [107.6195061, 16.0639752],
                [107.6105269, 16.0609909],
                [107.6073731, 16.0602081],
                [107.6057945, 16.0595728],
                [107.605009, 16.0592467],
                [107.6033731, 16.0585458],
                [107.6016768, 16.0577877],
                [107.5999936, 16.057003],
                [107.5972227, 16.0570447],
                [107.5968501, 16.0568685],
                [107.5964884, 16.0566727],
                [107.5961382, 16.0564577],
                [107.5958011, 16.0562243],
                [107.5923392, 16.0570586],
                [107.5898287, 16.0566136],
                [107.5864284, 16.0567666],
                [107.5847712, 16.0562557],
                [107.5831048, 16.0557733],
                [107.5814752, 16.0553316],
                [107.5798835, 16.0549283],
                [107.579085, 16.0547364],
                [107.5739483, 16.0541315],
                [107.5729361, 16.0550486],
                [107.5728512, 16.0551104],
                [107.5727609, 16.0551649],
                [107.5726661, 16.0552115],
                [107.5725673, 16.05525],
                [107.5724654, 16.0552799],
                [107.5723611, 16.0553011],
                [107.5722554, 16.0553135],
                [107.5721474, 16.0553168],
                [107.5720396, 16.0553109],
                [107.5719327, 16.0552957],
                [107.5718277, 16.0552715],
                [107.5717254, 16.0552384],
                [107.5716265, 16.0551967],
                [107.5715319, 16.0551466],
                [107.5703444, 16.0542446],
                [107.5687093, 16.0536605],
                [107.5669657, 16.0522213],
                [107.566016, 16.0519873],
                [107.5655441, 16.0518593],
                [107.5646072, 16.0515817],
                [107.5641281, 16.0514274],
                [107.5631783, 16.0510961],
                [107.5627078, 16.0509192],
                [107.5617762, 16.0505432],
                [107.5613153, 16.0503441],
                [107.5610946, 16.0500676],
                [107.5608664, 16.0497967],
                [107.560631, 16.0495316],
                [107.5603885, 16.0492724],
                [107.560139, 16.0490194],
                [107.5598828, 16.0487728],
                [107.5594171, 16.0483553],
                [107.5591769, 16.0481544],
                [107.558932, 16.0479587],
                [107.5582546, 16.0474491],
                [107.5579219, 16.0471623],
                [107.5577637, 16.0470107],
                [107.557457, 16.0466833],
                [107.5573095, 16.0465074],
                [107.5570355, 16.0461404],
                [107.5569093, 16.0459499],
                [107.5531607, 16.0446062],
                [107.5530328, 16.0445355],
                [107.5529241, 16.0444396],
                [107.5528437, 16.0443302],
                [107.5527911, 16.0442147],
                [107.5527738, 16.0441538],
                [107.5527628, 16.0440916],
                [107.552778, 16.0436676],
                [107.5527717, 16.0434555],
                [107.5527315, 16.043033],
                [107.5526976, 16.0428233],
                [107.5526115, 16.0424413],
                [107.5525571, 16.0422529],
                [107.5524257, 16.041883],
                [107.5523221, 16.0416453],
                [107.5538242, 16.040029],
                [107.5548488, 16.0380286],
                [107.5561094, 16.0368325],
                [107.5562114, 16.0366673],
                [107.5563978, 16.0363276],
                [107.556482, 16.0361535],
                [107.5566425, 16.0357693],
                [107.5567166, 16.0355591],
                [107.556839, 16.0351311],
                [107.5568827, 16.0348373],
                [107.5569364, 16.0345451],
                [107.5569999, 16.0342547],
                [107.5571419, 16.0337279],
                [107.5573164, 16.0332102],
                [107.5574157, 16.0329554],
                [107.5574834, 16.0328222],
                [107.5575596, 16.0326933],
                [107.557644, 16.0325693],
                [107.5577363, 16.0324505],
                [107.5578361, 16.0323375],
                [107.557943, 16.0322307],
                [107.5581637, 16.0320468],
                [107.5583915, 16.0318971],
                [107.5586352, 16.0317728],
                [107.5587621, 16.0317206],
                [107.5590182, 16.0316459],
                [107.5592713, 16.0315625],
                [107.5597504, 16.0313775],
                [107.5601986, 16.0311699],
                [107.5606316, 16.0309343],
                [107.5605941, 16.0296041],
                [107.5599021, 16.0286039],
                [107.5592744, 16.0267478],
                [107.5583142, 16.0257991],
                [107.5603473, 16.0232933],
                [107.5604046, 16.0232082],
                [107.5604981, 16.0230266],
                [107.5605617, 16.0228335],
                [107.5605941, 16.0226333],
                [107.5605982, 16.0225224],
                [107.5605927, 16.0224115],
                [107.5605776, 16.0223014],
                [107.560553, 16.0221929],
                [107.560519, 16.0220868],
                [107.5587165, 16.0197769],
                [107.5577509, 16.0178072],
                [107.5556052, 16.0162397],
                [107.5541997, 16.0140741],
                [107.5527406, 16.0149713],
                [107.5506806, 16.014961],
                [107.5505887, 16.0142241],
                [107.5505329, 16.0138568],
                [107.5504017, 16.0131254],
                [107.5502207, 16.0122988],
                [107.5501177, 16.0118882],
                [107.5498867, 16.0110732],
                [107.5448441, 16.0105988],
                [107.540499, 16.0118466],
                [107.5389969, 16.0120013],
                [107.5364796, 16.012821],
                [107.5361589, 16.0129155],
                [107.5358357, 16.0130015],
                [107.5351853, 16.0131472],
                [107.5348587, 16.013207],
                [107.5342011, 16.0133007],
                [107.5333911, 16.0139143],
                [107.5310898, 16.0151776],
                [107.5306814, 16.0152844],
                [107.5298585, 16.0154752],
                [107.5294444, 16.015559],
                [107.5286114, 16.0157035],
                [107.5281481, 16.0157701],
                [107.5276834, 16.0158272],
                [107.5272176, 16.0158746],
                [107.5267508, 16.0159125],
                [107.5262833, 16.0159407],
                [107.5252426, 16.0164151],
                [107.5250457, 16.0164638],
                [107.5246469, 16.0165393],
                [107.5242432, 16.016585],
                [107.5240403, 16.0165966],
                [107.5236371, 16.0165974],
                [107.5232383, 16.0165689],
                [107.52304, 16.0165438],
                [107.5226471, 16.016472],
                [107.5224531, 16.0164254],
                [107.5218428, 16.0159993],
                [107.5215304, 16.0157963],
                [107.5211887, 16.0155854],
                [107.5208417, 16.0153826],
                [107.5204897, 16.0151879],
                [107.5202281, 16.0150741],
                [107.5199551, 16.0149895],
                [107.5196743, 16.0149352],
                [107.5193783, 16.014916],
                [107.5181166, 16.0149006],
                [107.5170028, 16.0149765],
                [107.5153238, 16.0156004],
                [107.513237, 16.0160232],
                [107.5120139, 16.0166729],
                [107.511102, 16.01724],
                [107.510794, 16.0175669],
                [107.5102866, 16.0180135],
                [107.5098873, 16.0183018],
                [107.5092459, 16.0186735],
                [107.5076187, 16.0208607],
                [107.5074817, 16.0210698],
                [107.5073039, 16.0214526],
                [107.5072203, 16.0215823],
                [107.5071137, 16.0216952],
                [107.5069874, 16.0217877],
                [107.506556, 16.0219586],
                [107.5064415, 16.0220425],
                [107.5063491, 16.0221487],
                [107.5062807, 16.022279],
                [107.5062418, 16.0224941],
                [107.5063048, 16.0230929],
                [107.5062954, 16.0236239],
                [107.506375, 16.0240317],
                [107.5063759, 16.024147],
                [107.5063437, 16.0242781],
                [107.5060251, 16.0247332],
                [107.5058806, 16.0250749],
                [107.505818, 16.0253351],
                [107.5055912, 16.0267616],
                [107.5054479, 16.0270881],
                [107.5051292, 16.0274566],
                [107.50489, 16.0276398],
                [107.5047302, 16.0277134],
                [107.5045601, 16.0277609],
                [107.5044978, 16.0278523],
                [107.5044637, 16.0279565],
                [107.5044599, 16.028061],
                [107.5045037, 16.0282043],
                [107.5048305, 16.0286013],
                [107.5051733, 16.0289362],
                [107.5052395, 16.0290521],
                [107.5052743, 16.0292468],
                [107.5052521, 16.0293773],
                [107.5052016, 16.0294871],
                [107.5051253, 16.0295822],
                [107.5049724, 16.0296854],
                [107.5045252, 16.0297485],
                [107.5038275, 16.030278],
                [107.5029342, 16.0308346],
                [107.5025511, 16.0310993],
                [107.5022073, 16.0313695],
                [107.5017249, 16.0318108],
                [107.5011027, 16.0318727],
                [107.5005126, 16.032213],
                [107.5002338, 16.0324172],
                [107.4998474, 16.0327595],
                [107.4987398, 16.0341169],
                [107.4985904, 16.0342401],
                [107.4984201, 16.0343424],
                [107.4981335, 16.034448],
                [107.4979928, 16.0353389],
                [107.4978954, 16.0357304],
                [107.4977177, 16.0362499],
                [107.4970632, 16.0375438],
                [107.497015, 16.0377347],
                [107.4970039, 16.0379378],
                [107.4969578, 16.0380568],
                [107.4967671, 16.0383135],
                [107.4965323, 16.0387148],
                [107.4964103, 16.0388547],
                [107.4962639, 16.0389678],
                [107.4960118, 16.0390804],
                [107.4953949, 16.0391835],
                [107.4949887, 16.0391985],
                [107.4943026, 16.0391156],
                [107.4941128, 16.0391268],
                [107.4939088, 16.039172],
                [107.4924244, 16.039772],
                [107.4920574, 16.039971],
                [107.4916382, 16.0402586],
                [107.4912912, 16.0404208],
                [107.4910538, 16.0404905],
                [107.4908094, 16.040532],
                [107.4898392, 16.0405377],
                [107.4896663, 16.0405655],
                [107.4894269, 16.040664],
                [107.4891131, 16.0409026],
                [107.4889023, 16.0409759],
                [107.4885917, 16.0409717],
                [107.4882469, 16.0409041],
                [107.4878875, 16.040764],
                [107.4876665, 16.0406347],
                [107.4874753, 16.0404896],
                [107.4872398, 16.0402509],
                [107.487046, 16.0399685],
                [107.486946, 16.0397609],
                [107.4867026, 16.0396151],
                [107.4862942, 16.0392892],
                [107.4856613, 16.0388871],
                [107.4855137, 16.0387607],
                [107.4854249, 16.0386392],
                [107.4853635, 16.0385029],
                [107.4853042, 16.03828],
                [107.4852809, 16.0380509],
                [107.4853092, 16.0377334],
                [107.4854482, 16.0372985],
                [107.4854323, 16.0371539],
                [107.4853635, 16.0370336],
                [107.4850041, 16.0367655],
                [107.4847694, 16.0366213],
                [107.4845806, 16.0364246],
                [107.4844864, 16.0362705],
                [107.4844155, 16.0360844],
                [107.4843825, 16.0358887],
                [107.4843969, 16.0356107],
                [107.4843792, 16.0355222],
                [107.4843281, 16.0354353],
                [107.4841052, 16.0353084],
                [107.483867, 16.0352102],
                [107.4836207, 16.035143],
                [107.4833708, 16.0351068],
                [107.4830721, 16.0350948],
                [107.4828231, 16.0350287],
                [107.4825995, 16.0348949],
                [107.4823316, 16.0346114],
                [107.4822156, 16.0345494],
                [107.4820415, 16.0345222],
                [107.4818225, 16.0345517],
                [107.4815874, 16.0346315],
                [107.4814475, 16.0347084],
                [107.4811701, 16.0349177],
                [107.4808672, 16.0350697],
                [107.4802099, 16.035252],
                [107.4799677, 16.0353923],
                [107.4796854, 16.0356263],
                [107.4795226, 16.0357035],
                [107.4793536, 16.0357449],
                [107.4791865, 16.0357526],
                [107.4787957, 16.0357154],
                [107.4784917, 16.0356622],
                [107.4781351, 16.0355672],
                [107.4777899, 16.0354392],
                [107.4773476, 16.0352277],
                [107.4769418, 16.0349674],
                [107.4765444, 16.0346207],
                [107.4757316, 16.0336885],
                [107.4754672, 16.0334932],
                [107.4752462, 16.033404],
                [107.4732321, 16.0328591],
                [107.4730956, 16.0327841],
                [107.4729944, 16.0326649],
                [107.4729549, 16.0325683],
                [107.4728061, 16.0313872],
                [107.4727191, 16.031084],
                [107.4726283, 16.0308931],
                [107.4724175, 16.0305982],
                [107.4720362, 16.0302464],
                [107.47177, 16.0299341],
                [107.471558, 16.0295759],
                [107.4713797, 16.0291316],
                [107.4711979, 16.0288381],
                [107.4709591, 16.0285776],
                [107.4705356, 16.0282218],
                [107.4703411, 16.0280089],
                [107.4701713, 16.0277773],
                [107.4700206, 16.0275142],
                [107.469896, 16.0272204],
                [107.4697798, 16.0267581],
                [107.4694739, 16.0264542],
                [107.4692459, 16.0262767],
                [107.4688833, 16.0260576],
                [107.4684126, 16.025846],
                [107.4682548, 16.0257357],
                [107.4680563, 16.0255293],
                [107.467954, 16.025369],
                [107.467913, 16.0252835],
                [107.4678647, 16.0250515],
                [107.4677628, 16.0248659],
                [107.4672612, 16.0246607],
                [107.4669122, 16.0242496],
                [107.4667478, 16.0239921],
                [107.4665506, 16.023683],
                [107.4663225, 16.0234988],
                [107.4660958, 16.023408],
                [107.4656876, 16.0234231],
                [107.4656226, 16.0232766],
                [107.4654639, 16.0229056],
                [107.4653797, 16.0223103],
                [107.4653732, 16.0219808],
                [107.4652676, 16.0204367],
                [107.4650064, 16.0185186],
                [107.4644165, 16.0162283],
                [107.4642047, 16.015877],
                [107.4640415, 16.0156063],
                [107.4636018, 16.0149849],
                [107.4630323, 16.014365],
                [107.4617834, 16.0129803],
                [107.4605704, 16.0109687],
                [107.4600235, 16.0104319],
                [107.4594131, 16.0100005],
                [107.458568, 16.0098013],
                [107.4582199, 16.0096175],
                [107.4579992, 16.0092441],
                [107.4580142, 16.008701],
                [107.4580954, 16.0082615],
                [107.4580912, 16.0079274],
                [107.4579987, 16.0074273],
                [107.4576229, 16.0067427],
                [107.4572711, 16.0062455],
                [107.4566386, 16.0057724],
                [107.4560509, 16.0054241],
                [107.4556579, 16.0050944],
                [107.4555028, 16.004783],
                [107.4555104, 16.0036345],
                [107.4552813, 16.0025721],
                [107.4550563, 16.0018437],
                [107.4550487, 16.0012175],
                [107.4552144, 16.0006308],
                [107.4555124, 16.0002518],
                [107.4556605, 15.9999994],
                [107.455658, 15.9997904],
                [107.454936, 15.9990679],
                [107.4544105, 15.9984892],
                [107.4540792, 15.9979083],
                [107.4538966, 15.9971168],
                [107.4537312, 15.9959494],
                [107.4534782, 15.9946995],
                [107.4534043, 15.9939485],
                [107.4533336, 15.9934691],
                [107.4528688, 15.9925556],
                [107.4527032, 15.9913882],
                [107.4525628, 15.9905127],
                [107.4522594, 15.9899514],
                [107.4519741, 15.9895997],
                [107.4516047, 15.9894159],
                [107.4511505, 15.9893794],
                [107.4504789, 15.989241],
                [107.4501741, 15.9890563],
                [107.44935, 15.9887943],
                [107.4487887, 15.9888425],
                [107.4477987, 15.9891669],
                [107.4461864, 15.989766],
                [107.4446358, 15.9901805],
                [107.4438166, 15.9903358],
                [107.4432744, 15.990175],
                [107.4430331, 15.9898854],
                [107.4429846, 15.9894475],
                [107.4429775, 15.9888627],
                [107.4431653, 15.9883177],
                [107.4436531, 15.9875605],
                [107.4445285, 15.9866943],
                [107.4449523, 15.9860004],
                [107.446502, 15.9837274],
                [107.4472251, 15.9827795],
                [107.4473446, 15.9819429],
                [107.4472306, 15.981464],
                [107.4469642, 15.9808614],
                [107.446439, 15.9803245],
                [107.4459152, 15.9798919],
                [107.4454352, 15.9795214],
                [107.4450638, 15.9791707],
                [107.4444431, 15.9778832],
                [107.4432912, 15.9755366],
                [107.4425386, 15.9740624],
                [107.4413216, 15.9716958],
                [107.4405026, 15.9700762],
                [107.4396762, 15.9678511],
                [107.4393418, 15.966999],
                [107.4391819, 15.9662907],
                [107.438829, 15.9656892],
                [107.4387158, 15.9652519],
                [107.4386238, 15.9647936],
                [107.4384028, 15.9643785],
                [107.4379225, 15.9639662],
                [107.4370269, 15.963162],
                [107.4352135, 15.9614909],
                [107.4346013, 15.9608924],
                [107.434273, 15.9605411],
                [107.4341114, 15.9596868],
                [107.4340916, 15.9580372],
                [107.4340784, 15.9569515],
                [107.4339003, 15.9565151],
                [107.4335941, 15.9562053],
                [107.4332019, 15.9559383],
                [107.4328099, 15.9556712],
                [107.4325459, 15.9552775],
                [107.4323881, 15.9547363],
                [107.4321788, 15.9535067],
                [107.4317269, 15.9518621],
                [107.4312242, 15.9495915],
                [107.4310821, 15.948549],
                [107.4306825, 15.9458598],
                [107.4304986, 15.9449431],
                [107.4295767, 15.9419465],
                [107.4290812, 15.9402605],
                [107.4281949, 15.9384119],
                [107.4275351, 15.9374381],
                [107.4271158, 15.9366908],
                [107.4270232, 15.9361908],
                [107.4271482, 15.9357926],
                [107.4273379, 15.9354146],
                [107.4279975, 15.9345719],
                [107.4284663, 15.9340445],
                [107.428656, 15.9336457],
                [107.4286291, 15.9332075],
                [107.4285776, 15.9325189],
                [107.4284114, 15.9318624],
                [107.4283509, 15.9316235],
                [107.4281492, 15.9310203],
                [107.4277742, 15.9303771],
                [107.4266203, 15.9293757],
                [107.4249869, 15.9282666],
                [107.4237662, 15.9273406],
                [107.4231316, 15.9266586],
                [107.4227825, 15.9263701],
                [107.4222626, 15.9262507],
                [107.4216363, 15.9262578],
                [107.4210753, 15.9263057],
                [107.4204932, 15.9264168],
                [107.4198465, 15.9265285],
                [107.4194566, 15.9264284],
                [107.4191288, 15.9261188],
                [107.4188423, 15.9256417],
                [107.418554, 15.9250185],
                [107.4184373, 15.9242889],
                [107.4184486, 15.9234325],
                [107.4185432, 15.9223039],
                [107.4185104, 15.9213646],
                [107.4184393, 15.9208434],
                [107.4181448, 15.919698],
                [107.4178746, 15.9187823],
                [107.4167359, 15.9156836],
                [107.416513, 15.9151014],
                [107.4157072, 15.9127507],
                [107.4153964, 15.9120443],
                [107.415026, 15.911777],
                [107.414419, 15.9115958],
                [107.4136613, 15.911458],
                [107.4128403, 15.9114254],
                [107.4121471, 15.911266],
                [107.4116698, 15.9110836],
                [107.4107319, 15.9103212],
                [107.409512, 15.9094578],
                [107.4090742, 15.9089617],
                [107.4087389, 15.9080257],
                [107.4078484, 15.9057802],
                [107.4076665, 15.9050306],
                [107.4073143, 15.9044705],
                [107.4068988, 15.9040367],
                [107.4064627, 15.9036867],
                [107.4061129, 15.9033355],
                [107.4060222, 15.9029815],
                [107.405881, 15.9020017],
                [107.4058532, 15.9014799],
                [107.4057713, 15.90077],
                [107.4057328, 15.900437],
                [107.4053101, 15.8993976],
                [107.4049348, 15.8987128],
                [107.4042789, 15.8980519],
                [107.4035408, 15.8977258],
                [107.4027613, 15.8975676],
                [107.4021555, 15.8974699],
                [107.4016133, 15.8972878],
                [107.401329, 15.8969987],
                [107.4011084, 15.8966044],
                [107.4002399, 15.8944005],
                [107.3988537, 15.8922443],
                [107.3978847, 15.8906678],
                [107.3972719, 15.8899855],
                [107.3963804, 15.8894733],
                [107.3955999, 15.8892313],
                [107.3951427, 15.8889232],
                [107.394247, 15.888056],
                [107.3931351, 15.8871703],
                [107.3921165, 15.8868894],
                [107.3910369, 15.8869014],
                [107.3895708, 15.8871053],
                [107.3875433, 15.8873368],
                [107.3867234, 15.8874084],
                [107.3860312, 15.8873116],
                [107.3857271, 15.8871689],
                [107.3854375, 15.8864202],
                [107.3849541, 15.8857155],
                [107.383779, 15.8849767],
                [107.3831071, 15.8847544],
                [107.3825451, 15.884719],
                [107.3820496, 15.8848287],
                [107.3805903, 15.8856175],
                [107.3779873, 15.8865023],
                [107.3752606, 15.8878689],
                [107.3740354, 15.8883835],
                [107.3726416, 15.8892343],
                [107.3714225, 15.8902499],
                [107.3706101, 15.8909689],
                [107.3701873, 15.8917463],
                [107.3697065, 15.8931089],
                [107.3694294, 15.8934253],
                [107.3691918, 15.8934279],
                [107.3689099, 15.8933264],
                [107.3680416, 15.8929602],
                [107.3647482, 15.8920565],
                [107.3628227, 15.8917852],
                [107.3600558, 15.8915857],
                [107.3590118, 15.8916328],
                [107.3582209, 15.8916684],
                [107.3570128, 15.8917861],
                [107.3557, 15.8921972],
                [107.3544953, 15.8926071],
                [107.3520486, 15.8939494],
                [107.3514452, 15.8940604],
                [107.3508614, 15.894004],
                [107.3504054, 15.8938003],
                [107.3499051, 15.8934925],
                [107.3491909, 15.8933749],
                [107.3487387, 15.8934843],
                [107.3482449, 15.8937401],
                [107.347814, 15.8938284],
                [107.347382, 15.8938331],
                [107.3469266, 15.8936708],
                [107.3462297, 15.8931774],
                [107.34462, 15.8921925],
                [107.3436426, 15.8917228],
                [107.3428187, 15.8914601],
                [107.3419319, 15.8913446],
                [107.3411966, 15.8912689],
                [107.339573, 15.8909525],
                [107.3378364, 15.8901776],
                [107.3370728, 15.8895177],
                [107.3364618, 15.8889813],
                [107.3360052, 15.8887148],
                [107.3355279, 15.888532],
                [107.3349014, 15.8885179],
                [107.3334952, 15.8883242],
                [107.3328225, 15.8880391],
                [107.3321926, 15.8877325],
                [107.3316939, 15.887571],
                [107.331089, 15.8875566],
                [107.3306138, 15.8875618],
                [107.3296834, 15.8874047],
                [107.3292275, 15.8872008],
                [107.3287913, 15.8868295],
                [107.3277564, 15.8851074],
                [107.3263923, 15.8829294],
                [107.3256634, 15.8815172],
                [107.3253994, 15.8811022],
                [107.3249637, 15.8807728],
                [107.3244436, 15.8806323],
                [107.3239022, 15.8804919],
                [107.3227799, 15.8805666],
                [107.3219183, 15.8807637],
                [107.3207542, 15.8809643],
                [107.3197192, 15.8811008],
                [107.3189632, 15.881088],
                [107.3181827, 15.8808457],
                [107.3177912, 15.8805993],
                [107.3175486, 15.8801634],
                [107.3172521, 15.8787882],
                [107.3171933, 15.8774314],
                [107.317112, 15.8759703],
                [107.3169092, 15.8752417],
                [107.3165582, 15.8747651],
                [107.3156855, 15.8739808],
                [107.3145304, 15.8730743],
                [107.3142021, 15.872702],
                [107.3141125, 15.8724105],
                [107.3141868, 15.8713656],
                [107.3144758, 15.870193],
                [107.3146203, 15.8696067],
                [107.3145932, 15.8691265],
                [107.3142636, 15.8686289],
                [107.3137843, 15.8682581],
                [107.3133706, 15.8679703],
                [107.312785, 15.8677468],
                [107.3117466, 15.8675908],
                [107.30961, 15.867718],
                [107.3087885, 15.8676433],
                [107.3082687, 15.8675236],
                [107.3077471, 15.8672367],
                [107.306897, 15.8665357],
                [107.3060242, 15.8657515],
                [107.3051563, 15.8653849],
                [107.3040729, 15.8650831],
                [107.3030117, 15.864823],
                [107.302536, 15.8647653],
                [107.3021911, 15.8648108],
                [107.3018273, 15.8651069],
                [107.3015725, 15.8655066],
                [107.3012313, 15.865886],
                [107.3000749, 15.8667546],
                [107.2996917, 15.8672389],
                [107.2995456, 15.8676791],
                [107.2994633, 15.8680351],
                [107.2992946, 15.8683918],
                [107.2989937, 15.8685204],
                [107.2987778, 15.8685227],
                [107.2984314, 15.8684636],
                [107.2978867, 15.868031],
                [107.2964278, 15.8669815],
                [107.2956205, 15.866259],
                [107.2951413, 15.8658882],
                [107.2944706, 15.8657908],
                [107.2939955, 15.8657959],
                [107.2933043, 15.8657822],
                [107.2926752, 15.8655383],
                [107.2920033, 15.8653158],
                [107.2913977, 15.8652387],
                [107.2908375, 15.8653491],
                [107.2902772, 15.8654594],
                [107.2897376, 15.865486],
                [107.2894561, 15.8654263],
                [107.2889118, 15.8650353],
                [107.2885841, 15.8647045],
                [107.2884925, 15.864246],
                [107.288485, 15.8635779],
                [107.2884796, 15.8630976],
                [107.2883455, 15.8627022],
                [107.2881906, 15.8623697],
                [107.2872933, 15.8613141],
                [107.2863977, 15.8604047],
                [107.2859835, 15.860075],
                [107.2853773, 15.8599351],
                [107.2849451, 15.8599189],
                [107.2844459, 15.8596944],
                [107.2838351, 15.8591369],
                [107.2828316, 15.8582494],
                [107.2822671, 15.857984],
                [107.2813147, 15.8577852],
                [107.2796532, 15.8579072],
                [107.2786601, 15.8579384],
                [107.2778842, 15.858072],
                [107.2774125, 15.8583901],
                [107.2770727, 15.8588949],
                [107.2767989, 15.8595035],
                [107.2763137, 15.860532],
                [107.2760604, 15.8610568],
                [107.2757189, 15.8614155],
                [107.2753751, 15.8615652],
                [107.2750729, 15.8615893],
                [107.2743979, 15.8610951],
                [107.2737454, 15.8606843],
                [107.2731373, 15.8603775],
                [107.2725303, 15.8601749],
                [107.2719449, 15.8599722],
                [107.271554, 15.8597676],
                [107.2711608, 15.8593749],
                [107.2704591, 15.8584215],
                [107.2702791, 15.8577761],
                [107.2702737, 15.8572958],
                [107.2704409, 15.8567928],
                [107.2708588, 15.8555353],
                [107.2708506, 15.8548045],
                [107.2706286, 15.8542638],
                [107.2696428, 15.8530211],
                [107.2684214, 15.8519478],
                [107.267681, 15.8513918],
                [107.2663583, 15.8509044],
                [107.2644753, 15.8505273],
                [107.2634582, 15.850329],
                [107.2627667, 15.8502944],
                [107.2619688, 15.8504074],
                [107.2612781, 15.8504354],
                [107.2606939, 15.8503162],
                [107.2595871, 15.8498474],
                [107.2587158, 15.8491673],
                [107.2578245, 15.8486337],
                [107.2571311, 15.848432],
                [107.2567412, 15.8483109],
                [107.2562172, 15.8477942],
                [107.2556448, 15.8468185],
                [107.2541804, 15.8452464],
                [107.253635, 15.8447509],
                [107.2532181, 15.8441496],
                [107.2527554, 15.8433191],
                [107.2520955, 15.8427112],
                [107.2515452, 15.8421629],
                [107.2508415, 15.840702],
                [107.2500251, 15.8394082],
                [107.2488658, 15.8382013],
                [107.2480861, 15.8376275],
                [107.2472244, 15.8374147],
                [107.2466234, 15.8374764],
                [107.2459972, 15.8378431],
                [107.2454018, 15.8384034],
                [107.2443793, 15.8391896],
                [107.2431564, 15.8400057],
                [107.2417248, 15.8408273],
                [107.240993, 15.8412473],
                [107.2394267, 15.8420947],
                [107.2376851, 15.8426112],
                [107.2357741, 15.8433792],
                [107.234462, 15.8438913],
                [107.2338605, 15.8438975],
                [107.2333717, 15.8437365],
                [107.2329936, 15.8432139],
                [107.2324073, 15.842001],
                [107.2319432, 15.8388751],
                [107.2318525, 15.8384326],
                [107.2315912, 15.8381306],
                [107.2311891, 15.8380239],
                [107.2306183, 15.8382237],
                [107.2299903, 15.8384243],
                [107.2294757, 15.8385126],
                [107.2288163, 15.8384639],
                [107.2272549, 15.8371501],
                [107.2256128, 15.8363081],
                [107.2242619, 15.8358787],
                [107.2231721, 15.8357791],
                [107.2222564, 15.8358716],
                [107.2217129, 15.8359325],
                [107.2213092, 15.8356875],
                [107.2214731, 15.8349653],
                [107.221493, 15.8341618],
                [107.2212828, 15.8332772],
                [107.2209531, 15.8319507],
                [107.2207684, 15.8307612],
                [107.2204182, 15.830183],
                [107.219435, 15.8293342],
                [107.2190587, 15.828978],
                [107.2186285, 15.828927],
                [107.2183435, 15.8290684],
                [107.217689, 15.829463],
                [107.2170061, 15.8298856],
                [107.2165765, 15.82989],
                [107.216117, 15.8297838],
                [107.2136348, 15.8280638],
                [107.2114112, 15.8264241],
                [107.2109491, 15.8260686],
                [107.2109165, 15.825709],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [106.439154, 21.1070264],
                [106.4391952, 21.1067186],
                [106.4393682, 21.1063964],
                [106.439679, 21.1058994],
                [106.4405341, 21.1047828],
                [106.4420052, 21.1028593],
                [106.4433825, 21.1010968],
                [106.443926, 21.1003838],
                [106.444522, 21.0997907],
                [106.4452652, 21.0992364],
                [106.4467754, 21.0983281],
                [106.4489805, 21.0972],
                [106.4500017, 21.0965631],
                [106.4503292, 21.0962798],
                [106.4505752, 21.0959168],
                [106.4507046, 21.0956485],
                [106.4507888, 21.0952467],
                [106.4508119, 21.0946051],
                [106.4507099, 21.0939244],
                [106.4504986, 21.0933248],
                [106.4501721, 21.0929534],
                [106.4496984, 21.0925298],
                [106.4484805, 21.0917917],
                [106.4472699, 21.0910403],
                [106.4466046, 21.0905515],
                [106.4460561, 21.0899682],
                [106.4455438, 21.0893578],
                [106.4452597, 21.0888123],
                [106.4449591, 21.0880797],
                [106.4447072, 21.0870796],
                [106.4444419, 21.085438],
                [106.4441715, 21.0840371],
                [106.4439607, 21.0834776],
                [106.4436772, 21.0829855],
                [106.4428682, 21.0820435],
                [106.4419061, 21.0811829],
                [106.4407228, 21.0802174],
                [106.4405141, 21.0798851],
                [106.440468, 21.0796717],
                [106.4405018, 21.0793773],
                [106.4407298, 21.0786672],
                [106.4410176, 21.0780766],
                [106.4413072, 21.0776733],
                [106.4416123, 21.0773499],
                [106.4419622, 21.0771063],
                [106.4423779, 21.0768489],
                [106.4428458, 21.0766712],
                [106.4434458, 21.0765057],
                [106.4444847, 21.0762028],
                [106.4451429, 21.0759835],
                [106.4455952, 21.0757123],
                [106.4459227, 21.0754289],
                [106.4463291, 21.0749709],
                [106.446588, 21.0744342],
                [106.446701, 21.0739789],
                [106.4467763, 21.0734148],
                [106.446853, 21.0722387],
                [106.4468009, 21.0714112],
                [106.4468969, 21.0707158],
                [106.4470982, 21.0702867],
                [106.4473152, 21.0699643],
                [106.4476159, 21.0696906],
                [106.4478902, 21.0695838],
                [106.4482799, 21.0695388],
                [106.4487616, 21.0695137],
                [106.4494729, 21.0695076],
                [106.4505518, 21.0695402],
                [106.4517801, 21.0696337],
                [106.4534108, 21.0697869],
                [106.4541681, 21.0697804],
                [106.4546474, 21.0695256],
                [106.4548979, 21.0693144],
                [106.4550993, 21.0687901],
                [106.4552532, 21.0680991],
                [106.4552219, 21.0672424],
                [106.4550559, 21.0666246],
                [106.4550314, 21.0665335],
                [106.4547703, 21.065637],
                [106.4560514, 21.065229],
                [106.4568533, 21.0650966],
                [106.4585271, 21.0649569],
                [106.4599056, 21.0651331],
                [106.4623901, 21.0657595],
                [106.4649487, 21.066259],
                [106.4655931, 21.0664415],
                [106.4670637, 21.0666378],
                [106.4685376, 21.0671685],
                [106.4707946, 21.0680059],
                [106.4730521, 21.0688849],
                [106.4747317, 21.0693094],
                [106.4756341, 21.0693826],
                [106.4774229, 21.0692625],
                [106.4812064, 21.0689789],
                [106.4838655, 21.0686842],
                [106.4853088, 21.0684418],
                [106.4870719, 21.0680505],
                [106.4873215, 21.0679342],
                [106.487757, 21.0677312],
                [106.4885554, 21.0672438],
                [106.4934795, 21.063984],
                [106.496261, 21.0621842],
                [106.4978313, 21.0608754],
                [106.4987849, 21.0598643],
                [106.4992829, 21.0591916],
                [106.5001732, 21.0573271],
                [106.5017771, 21.0569986],
                [106.502914, 21.0568419],
                [106.5035387, 21.0568154],
                [106.5039778, 21.0570656],
                [106.5058124, 21.0581184],
                [106.5083218, 21.0591295],
                [106.5089727, 21.0592624],
                [106.5096708, 21.0592644],
                [106.511068, 21.0588755],
                [106.5117204, 21.058572],
                [106.5120472, 21.0582675],
                [106.5123267, 21.0581809],
                [106.5126057, 21.0582253],
                [106.5129778, 21.0583137],
                [106.5133028, 21.0585328],
                [106.514695, 21.0596716],
                [106.5152985, 21.0601097],
                [106.5158095, 21.0603731],
                [106.5161816, 21.0604178],
                [106.5166474, 21.0602882],
                [106.5169741, 21.0599836],
                [106.5172081, 21.0595478],
                [106.5171636, 21.0589804],
                [106.5165883, 21.0569711],
                [106.516126, 21.0560097],
                [106.5161272, 21.0556605],
                [106.5162683, 21.0552244],
                [106.5165021, 21.0548323],
                [106.5169686, 21.0544844],
                [106.5174343, 21.0543549],
                [106.5182256, 21.0542699],
                [106.5187376, 21.0542275],
                [106.519157, 21.0540543],
                [106.5192972, 21.0538364],
                [106.5192514, 21.0536181],
                [106.518973, 21.0533991],
                [106.5185086, 21.0531359],
                [106.5179976, 21.0528289],
                [106.5176726, 21.0526098],
                [106.5169306, 21.0518221],
                [106.5156345, 21.0497671],
                [106.5151258, 21.0487618],
                [106.5148959, 21.047932],
                [106.5148979, 21.047321],
                [106.5150391, 21.0468413],
                [106.5152726, 21.0465365],
                [106.5157387, 21.0463196],
                [106.5160178, 21.0463203],
                [106.5163898, 21.0463651],
                [106.5167615, 21.0465408],
                [106.517133, 21.0468036],
                [106.5175808, 21.0472111],
                [106.518432, 21.0479857],
                [106.5187571, 21.0481612],
                [106.519129, 21.0482496],
                [106.5195013, 21.0482506],
                [106.5199202, 21.0482082],
                [106.5206653, 21.047992],
                [106.5218309, 21.0472533],
                [106.5225319, 21.0462951],
                [106.5229052, 21.0459907],
                [106.5231841, 21.0460352],
                [106.5234165, 21.046123],
                [106.5236484, 21.0463856],
                [106.52374, 21.0468222],
                [106.5237387, 21.0472151],
                [106.5235507, 21.0478255],
                [106.5235497, 21.048131],
                [106.5235941, 21.0487858],
                [106.5236853, 21.0493971],
                [106.5238236, 21.0497903],
                [106.5243339, 21.0502719],
                [106.5249145, 21.050579],
                [106.5254264, 21.0505804],
                [106.5257058, 21.0505374],
                [106.5259855, 21.0503201],
                [106.5264991, 21.0497978],
                [106.526733, 21.0494056],
                [106.5271535, 21.048883],
                [106.5275728, 21.048666],
                [106.5278989, 21.0485797],
                [106.5286438, 21.0484507],
                [106.5293421, 21.0483218],
                [106.531065, 21.0478902],
                [106.5320429, 21.0476309],
                [106.5326943, 21.0476763],
                [106.5331126, 21.0478084],
                [106.5335307, 21.0479842],
                [106.5343197, 21.048641],
                [106.5368032, 21.050677],
                [106.5373367, 21.0514437],
                [106.5374871, 21.0519039],
                [106.5375386, 21.0520463],
                [106.537585, 21.0521315],
                [106.5377509, 21.0523182],
                [106.537971, 21.0524702],
                [106.5381723, 21.0525711],
                [106.5385283, 21.0526363],
                [106.5389935, 21.0527091],
                [106.539692, 21.0528824],
                [106.5406423, 21.0531819],
                [106.5416109, 21.0534982],
                [106.542791, 21.054001],
                [106.5436684, 21.0542839],
                [106.5447273, 21.054531],
                [106.5456025, 21.0546257],
                [106.5460763, 21.0546385],
                [106.5464587, 21.054635],
                [106.5468409, 21.0545974],
                [106.5473134, 21.0544903],
                [106.5478858, 21.0543654],
                [106.5486489, 21.0541701],
                [106.5496835, 21.0538185],
                [106.5508627, 21.0533627],
                [106.5519435, 21.0530619],
                [106.5527431, 21.0528835],
                [106.5534529, 21.0528427],
                [106.5539451, 21.0528725],
                [106.5545472, 21.0529696],
                [106.5554145, 21.0531671],
                [106.5559814, 21.0533843],
                [106.5564582, 21.053688],
                [106.5571192, 21.0541954],
                [106.5579273, 21.0548383],
                [106.5587079, 21.0554643],
                [106.559387, 21.0559545],
                [106.5598085, 21.0561901],
                [106.5601369, 21.0562384],
                [106.560647, 21.0562509],
                [106.5611023, 21.0562467],
                [106.5616657, 21.0561216],
                [106.5622564, 21.0559965],
                [106.5628918, 21.0557852],
                [106.5633093, 21.0556445],
                [106.5637077, 21.0554354],
                [106.5641956, 21.0550545],
                [106.5646285, 21.0546569],
                [106.5651162, 21.0542588],
                [106.5657806, 21.053782],
                [106.5662417, 21.0534527],
                [106.5665408, 21.053313],
                [106.5671452, 21.0531962],
                [106.5676001, 21.0531577],
                [106.5680369, 21.0531195],
                [106.5685476, 21.0531832],
                [106.5690229, 21.05335],
                [106.5694813, 21.0536366],
                [106.5701248, 21.0542126],
                [106.5706221, 21.0547214],
                [106.5711201, 21.0553157],
                [106.5717, 21.0559093],
                [106.5724531, 21.0565014],
                [106.5728747, 21.0567541],
                [106.5733139, 21.0569383],
                [106.5742185, 21.0572209],
                [106.5749855, 21.057402],
                [106.5754593, 21.0574318],
                [106.575787, 21.0574116],
                [106.5760773, 21.0573064],
                [106.5763672, 21.0571496],
                [106.5769828, 21.0568016],
                [106.5777066, 21.0563499],
                [106.5783113, 21.0558309],
                [106.5789959, 21.0551058],
                [106.5796814, 21.0544662],
                [106.5802407, 21.0539648],
                [106.5811332, 21.0531008],
                [106.582314, 21.052067],
                [106.5832901, 21.0513389],
                [106.5844124, 21.0506438],
                [106.5855344, 21.0499143],
                [106.5864739, 21.0491866],
                [106.5874865, 21.0484582],
                [106.5886075, 21.0476603],
                [106.5896379, 21.0468976],
                [106.5907579, 21.0459969],
                [106.5920947, 21.0449231],
                [106.5926999, 21.0444382],
                [106.5931866, 21.0439543],
                [106.5937084, 21.0433331],
                [106.5941934, 21.0426952],
                [106.594797, 21.0420562],
                [106.59532, 21.0415548],
                [106.595873, 21.0408706],
                [106.596409, 21.0404922],
                [106.5973101, 21.0402524],
                [106.5982703, 21.039764],
                [106.5994302, 21.0391882],
                [106.6000192, 21.0389088],
                [106.6006897, 21.0385773],
                [106.6013051, 21.0381949],
                [106.6017753, 21.0378651],
                [106.6024985, 21.037527],
                [106.6032078, 21.0370012],
                [106.6038756, 21.036456],
                [106.604502, 21.0357933],
                [106.6052957, 21.0348187],
                [106.6059851, 21.0339414],
                [106.6064809, 21.0332846],
                [106.6069828, 21.0325267],
                [106.6071805, 21.0322681],
                [106.6074937, 21.0317516],
                [106.607851, 21.0310976],
                [106.608135, 21.0303931],
                [106.6082826, 21.0297241],
                [106.6083311, 21.0291589],
                [106.6083229, 21.0283887],
                [106.6082589, 21.0275162],
                [106.6082346, 21.0269516],
                [106.6082737, 21.0263521],
                [106.608359, 21.0258206],
                [106.6085893, 21.0252022],
                [106.6088628, 21.024378],
                [106.6091654, 21.0237075],
                [106.6094164, 21.0233457],
                [106.6096499, 21.0230354],
                [106.6099201, 21.0227589],
                [106.6101714, 21.0224141],
                [106.6103325, 21.0221473],
                [106.6105654, 21.0217856],
                [106.6115432, 21.0202876],
                [106.6128805, 21.0200877],
                [106.6144806, 21.0197261],
                [106.6161607, 21.0190796],
                [106.6171278, 21.0184256],
                [106.6192545, 21.0165495],
                [106.6200846, 21.015695],
                [106.6208655, 21.0148916],
                [106.6215621, 21.0141151],
                [106.6224716, 21.013165],
                [106.6229061, 21.0129041],
                [106.6232862, 21.012695],
                [106.6238657, 21.0123814],
                [106.6239488, 21.012356],
                [106.6249364, 21.0120117],
                [106.6261809, 21.0117259],
                [106.6270547, 21.0116833],
                [106.6278377, 21.0116587],
                [106.6284753, 21.0116697],
                [106.6304512, 21.0116337],
                [106.6318171, 21.0116206],
                [106.6337941, 21.0116872],
                [106.6348344, 21.0118827],
                [106.6361844, 21.0120751],
                [106.6373533, 21.0123719],
                [106.638633, 21.0128047],
                [106.6396586, 21.0133083],
                [106.6403554, 21.0137467],
                [106.6412004, 21.014389],
                [106.6421948, 21.0153722],
                [106.6428966, 21.0162555],
                [106.6434509, 21.0169691],
                [106.6441243, 21.01775],
                [106.6446397, 21.0182585],
                [106.6450619, 21.0185455],
                [106.6455379, 21.0187634],
                [106.6463795, 21.0190976],
                [106.6469281, 21.0192976],
                [106.6476403, 21.019462],
                [106.6487436, 21.019571],
                [106.6497462, 21.0196469],
                [106.6504383, 21.0196402],
                [106.6512203, 21.0195299],
                [106.6522115, 21.0194005],
                [106.6531923, 21.0191342],
                [106.6540816, 21.0188346],
                [106.6550062, 21.018449],
                [106.6552489, 21.0183636],
                [106.6563579, 21.0179737],
                [106.6579742, 21.0175301],
                [106.6585288, 21.0173002],
                [106.6587356, 21.0172145],
                [106.6601227, 21.016636],
                [106.6616188, 21.0160394],
                [106.6628524, 21.0155823],
                [106.6639223, 21.015161],
                [106.66501, 21.0146882],
                [106.6659344, 21.0142855],
                [106.6679379, 21.0134613],
                [106.6689713, 21.0130232],
                [106.6706037, 21.0124089],
                [106.6718195, 21.0119693],
                [106.6729447, 21.0115819],
                [106.674351, 21.0110744],
                [106.6752859, 21.0107741],
                [106.67661, 21.0102474],
                [106.6781325, 21.0095475],
                [106.679909, 21.0087424],
                [106.6814311, 21.0080083],
                [106.6830985, 21.0072384],
                [106.6842221, 21.0067137],
                [106.6860074, 21.0058912],
                [106.6865516, 21.0056287],
                [106.6892172, 21.0045752],
                [106.6982826, 21.0027127],
                [106.6992571, 21.0023351],
                [106.700346, 21.0019817],
                [106.7020424, 21.0013655],
                [106.7030764, 21.0009785],
                [106.7041833, 21.0005908],
                [106.7060423, 20.9998702],
                [106.706756, 20.9996075],
                [106.7076313, 20.9994206],
                [106.7086959, 20.9991872],
                [106.7103158, 20.9991278],
                [106.7112626, 20.9990361],
                [106.71151, 20.9990034],
                [106.7140296, 20.9987624],
                [106.7169881, 20.9980843],
                [106.7197642, 20.9976621],
                [106.7229951, 20.9971978],
                [106.7237535, 20.997199],
                [106.7244054, 20.9972001],
                [106.727044, 20.9973751],
                [106.7292067, 20.9979199],
                [106.7297724, 20.9980624],
                [106.7322213, 20.998696],
                [106.7333754, 20.9989367],
                [106.7334328, 20.9989487],
                [106.7362417, 20.9995346],
                [106.738548, 20.9997047],
                [106.7403226, 20.999652],
                [106.7417426, 20.9993211],
                [106.7427552, 20.9990031],
                [106.7473395, 20.9970038],
                [106.7491724, 20.9961202],
                [106.750164, 20.9955335],
                [106.7526443, 20.9941747],
                [106.7553844, 20.9920566],
                [106.7575029, 20.9898551],
                [106.758116, 20.989218],
                [106.7604374, 20.9862055],
                [106.7609149, 20.9855859],
                [106.7621866, 20.9840528],
                [106.7627308, 20.9832611],
                [106.7647398, 20.9810424],
                [106.7661759, 20.9790817],
                [106.7671554, 20.9774862],
                [106.7679789, 20.975676],
                [106.7680918, 20.9742078],
                [106.7681484, 20.9732053],
                [106.7682359, 20.9714216],
                [106.768467, 20.9682124],
                [106.7685276, 20.9668426],
                [106.7686277, 20.966101],
                [106.7688102, 20.9647498],
                [106.7689501, 20.9635953],
                [106.769091, 20.9625128],
                [106.7690766, 20.9612879],
                [106.7691381, 20.9599901],
                [106.7694685, 20.9554468],
                [106.7696398, 20.9506305],
                [106.7696993, 20.9489585],
                [106.7696833, 20.9475896],
                [106.7698097, 20.9452823],
                [106.7698421, 20.9432163],
                [106.7698739, 20.941892],
                [106.7701224, 20.9392256],
                [106.7702638, 20.9354758],
                [106.7702712, 20.9353333],
                [106.770459, 20.9317059],
                [106.7705353, 20.9279936],
                [106.7706246, 20.922847],
                [106.7707033, 20.9192459],
                [106.7706708, 20.9176133],
                [106.7706205, 20.9150848],
                [106.7705659, 20.9139866],
                [106.7704285, 20.9129676],
                [106.7700761, 20.9114409],
                [106.7699419, 20.9106966],
                [106.7693582, 20.9090348],
                [106.7689236, 20.9076266],
                [106.7685902, 20.9069253],
                [106.7683666, 20.9064551],
                [106.7674561, 20.9049257],
                [106.7671288, 20.9042703],
                [106.7657666, 20.9021652],
                [106.7647613, 20.9008805],
                [106.7610189, 20.896447],
                [106.756984, 20.8914869],
                [106.7558251, 20.8899509],
                [106.7547914, 20.8881464],
                [106.7541836, 20.8866687],
                [106.7536746, 20.8851378],
                [106.7533543, 20.8837733],
                [106.7532287, 20.8824226],
                [106.7532279, 20.8810715],
                [106.7534226, 20.8789968],
                [106.7538127, 20.8765201],
                [106.7542089, 20.8743943],
                [106.7546287, 20.8726389],
                [106.7551214, 20.870845],
                [106.7560595, 20.8675393],
                [106.7567651, 20.8651467],
                [106.7578168, 20.8619208],
                [106.759127, 20.8585486],
                [106.7608515, 20.8548551],
                [106.7625429, 20.8521075],
                [106.7639879, 20.8504777],
                [106.765699, 20.848691],
                [106.767743, 20.8472372],
                [106.7711938, 20.8446586],
                [106.776653, 20.8410339],
                [106.7819745, 20.8377771],
                [106.7889097, 20.8342155],
                [106.792411, 20.8328418],
                [106.7968408, 20.831697],
                [106.8008891, 20.8311829],
                [106.8160966, 20.8311226],
                [106.8192961, 20.8303287],
                [106.8223433, 20.8287879],
                [106.8242889, 20.8270515],
                [106.8257064, 20.8255708],
                [106.8271477, 20.8232031],
                [106.8287366, 20.8201637],
                [106.8303428, 20.8169799],
                [106.8325938, 20.8120886],
                [106.8357165, 20.8138179],
                [106.8432659, 20.8179987],
                [106.8495313, 20.8215246],
                [106.849791, 20.823715],
                [106.8495693, 20.8254614],
                [106.8492336, 20.8270775],
                [106.8492653, 20.8275274],
                [106.8494616, 20.827924],
                [106.8498733, 20.8281904],
                [106.850399, 20.8283384],
                [106.8526055, 20.8286037],
                [106.8533878, 20.8289325],
                [106.8540266, 20.8293857],
                [106.8544164, 20.8301305],
                [106.854689, 20.8312213],
                [106.8548586, 20.8322158],
                [106.8548553, 20.8329125],
                [106.8549942, 20.8335086],
                [106.8554078, 20.8340726],
                [106.856704, 20.8351446],
                [106.8572135, 20.8353938],
                [106.8578199, 20.835581],
                [106.8585044, 20.8357434],
                [106.8602966, 20.8365498],
                [106.8608323, 20.8366963],
                [106.861361, 20.8366928],
                [106.8618554, 20.8365289],
                [106.8622606, 20.8362332],
                [106.8630922, 20.8358983],
                [106.8640806, 20.8356954],
                [106.865035, 20.8355544],
                [106.8653755, 20.8354442],
                [106.8657034, 20.8352143],
                [106.8659418, 20.834937],
                [106.8666444, 20.8338769],
                [106.8672429, 20.8332683],
                [106.8679444, 20.832756],
                [106.8698645, 20.8317809],
                [106.8770954, 20.8356356],
                [106.8798591, 20.8324268],
                [106.885833, 20.8280468],
                [106.8952572, 20.8197195],
                [106.8986732, 20.8224953],
                [106.899892, 20.8242602],
                [106.9001667, 20.8267151],
                [106.9006817, 20.8283035],
                [106.9016258, 20.8304053],
                [106.9033424, 20.8320578],
                [106.9059688, 20.8340152],
                [106.9090415, 20.8368352],
                [106.9105078, 20.8384754],
                [106.9117085, 20.8396841],
                [106.9137475, 20.8420375],
                [106.9153922, 20.8440559],
                [106.9163043, 20.8452949],
                [106.9191719, 20.8491326],
                [106.920582, 20.850882],
                [106.9225075, 20.8533723],
                [106.9232716, 20.8543413],
                [106.9246264, 20.8562813],
                [106.9267628, 20.8594211],
                [106.9277059, 20.8608226],
                [106.9282976, 20.8618751],
                [106.9298737, 20.864152],
                [106.9303147, 20.8647172],
                [106.9316341, 20.8661417],
                [106.9328362, 20.8674316],
                [106.9341989, 20.8688418],
                [106.9356334, 20.8702107],
                [106.9383568, 20.8728683],
                [106.9391633, 20.8737418],
                [106.9402794, 20.8750868],
                [106.9418674, 20.8771327],
                [106.9428379, 20.8783981],
                [106.9436476, 20.8795158],
                [106.9442329, 20.8800524],
                [106.9451067, 20.8805312],
                [106.9461825, 20.8807781],
                [106.947139, 20.8810076],
                [106.9481838, 20.8809568],
                [106.9495375, 20.8809016],
                [106.9510337, 20.8808281],
                [106.9526085, 20.8807013],
                [106.9538795, 20.8805934],
                [106.9548783, 20.8804117],
                [106.956215, 20.8803335],
                [106.9573758, 20.8801676],
                [106.9592685, 20.8794567],
                [106.959948, 20.8790877],
                [106.9611733, 20.8785953],
                [106.9622696, 20.8782334],
                [106.9634372, 20.8775332],
                [106.9644334, 20.8766404],
                [106.9652928, 20.8760111],
                [106.9667912, 20.8753677],
                [106.9690353, 20.874265],
                [106.9706828, 20.8738668],
                [106.9730244, 20.8734031],
                [106.9750693, 20.8726153],
                [106.9766056, 20.872019],
                [106.9784663, 20.8715103],
                [106.9798647, 20.8706274],
                [106.9811599, 20.86982],
                [106.9828138, 20.8692806],
                [106.9840325, 20.8687988],
                [106.9856576, 20.8681522],
                [106.9869679, 20.867685],
                [106.9886353, 20.8668929],
                [106.9903943, 20.8660605],
                [106.9920801, 20.8652449],
                [106.9935242, 20.8644025],
                [106.9952625, 20.8635408],
                [106.9966272, 20.8630553],
                [106.9981654, 20.8627719],
                [106.9995078, 20.8628489],
                [107.0005186, 20.8630989],
                [107.001771, 20.863138],
                [107.003824, 20.8628994],
                [107.0054265, 20.8626148],
                [107.0070977, 20.8622661],
                [107.0080369, 20.8622103],
                [107.0094274, 20.8624113],
                [107.0117633, 20.8626913],
                [107.0144425, 20.862843],
                [107.0169491, 20.8630993],
                [107.0188902, 20.8632843],
                [107.0212658, 20.863563],
                [107.0239761, 20.8636839],
                [107.0248162, 20.8640491],
                [107.0258624, 20.8639651],
                [107.0269056, 20.8638379],
                [107.0277354, 20.8638436],
                [107.0284794, 20.8642825],
                [107.0296179, 20.8644134],
                [107.0310998, 20.8641915],
                [107.032715, 20.86389],
                [107.0339525, 20.8635244],
                [107.0351988, 20.8630343],
                [107.0361705, 20.8627046],
                [107.0372092, 20.8625517],
                [107.0382274, 20.8623435],
                [107.0389029, 20.8618623],
                [107.0395313, 20.8612092],
                [107.0401219, 20.8601949],
                [107.0406043, 20.8589381],
                [107.040692, 20.8571681],
                [107.040784, 20.8555659],
                [107.0421119, 20.8538353],
                [107.0432484, 20.8525626],
                [107.0443335, 20.851691],
                [107.0450519, 20.8506773],
                [107.046338, 20.8494807],
                [107.0476585, 20.8485728],
                [107.0486147, 20.8475081],
                [107.0502261, 20.8459627],
                [107.0513475, 20.845344],
                [107.0527459, 20.8444496],
                [107.0541023, 20.8438048],
                [107.0554337, 20.8429854],
                [107.0568024, 20.8419667],
                [107.0582674, 20.8408681],
                [107.0595673, 20.8399992],
                [107.0604552, 20.839002],
                [107.0611497, 20.8380842],
                [107.0615352, 20.837006],
                [107.0623935, 20.8363106],
                [107.0634181, 20.8358034],
                [107.0643842, 20.8355408],
                [107.065142, 20.8349443],
                [107.0658299, 20.8344535],
                [107.0669951, 20.8340199],
                [107.0683014, 20.8335581],
                [107.0696763, 20.8331926],
                [107.0710554, 20.8326587],
                [107.0721942, 20.8319324],
                [107.0736134, 20.8309931],
                [107.0754379, 20.8299696],
                [107.0774855, 20.8287856],
                [107.0792904, 20.8273508],
                [107.0810094, 20.8259801],
                [107.0831002, 20.8241073],
                [107.085609, 20.8223041],
                [107.0869504, 20.8213031],
                [107.0892901, 20.8198146],
                [107.0903974, 20.8194673],
                [107.0912426, 20.8192883],
                [107.0918787, 20.8190911],
                [107.0923947, 20.8183965],
                [107.0930309, 20.8180549],
                [107.0943658, 20.8175925],
                [107.0955291, 20.8169053],
                [107.0966923, 20.8162664],
                [107.0976496, 20.8155632],
                [107.0989291, 20.8154202],
                [107.1002727, 20.8155894],
                [107.1016506, 20.8157907],
                [107.1027554, 20.8159107],
                [107.1039601, 20.81573],
                [107.1046397, 20.8153748],
                [107.1053193, 20.81441],
                [107.1057241, 20.8134857],
                [107.1058508, 20.81269],
                [107.1061045, 20.8119458],
                [107.1064826, 20.8111013],
                [107.1070998, 20.8088239],
                [107.1073364, 20.8067773],
                [107.1079149, 20.8047428],
                [107.1087058, 20.8032258],
                [107.1096849, 20.8017934],
                [107.1107327, 20.8004572],
                [107.1114388, 20.7990269],
                [107.111766, 20.7975695],
                [107.1120099, 20.7954314],
                [107.1121508, 20.7926193],
                [107.1126826, 20.7898561],
                [107.1128882, 20.7884088],
                [107.1128021, 20.786785],
                [107.1125442, 20.7853217],
                [107.1121995, 20.7834164],
                [107.1119241, 20.7815936],
                [107.1117173, 20.7801719],
                [107.1117959, 20.7781614],
                [107.1118724, 20.7768384],
                [107.1124293, 20.7734911],
                [107.1129123, 20.7716088],
                [107.1128801, 20.7664245],
                [107.1134981, 20.7616573],
                [107.1150259, 20.7487033],
                [107.1150149, 20.7358871],
                [107.1149996, 20.7180273],
                [107.3759991, 20.4646312],
                [107.5737884, 20.5494684],
                [107.5885162, 20.5592203],
                [107.6023069, 20.570096],
                [107.6175666, 20.584528],
                [107.6343026, 20.6025162],
                [107.644634, 20.6152326],
                [107.6538502, 20.628671],
                [107.6618961, 20.6427454],
                [107.6690945, 20.6583704],
                [107.6767702, 20.6706504],
                [107.6906778, 20.6994424],
                [107.6967294, 20.7152278],
                [107.7204713, 20.7435141],
                [107.7391998, 20.7427949],
                [107.7579233, 20.7436225],
                [107.7764944, 20.7459899],
                [107.7986552, 20.750789],
                [107.8164059, 20.7560479],
                [107.8335925, 20.7627349],
                [107.850084, 20.7707956],
                [107.9040041, 20.7990045],
                [107.9121155, 20.8021777],
                [107.9286748, 20.8103185],
                [107.944402, 20.8197792],
                [107.9591783, 20.8304836],
                [107.9728933, 20.842346],
                [107.9854458, 20.8552729],
                [108.0030332, 20.8760316],
                [108.0102063, 20.8819693],
                [108.0228601, 20.8944065],
                [108.0343252, 20.9078019],
                [108.0477577, 20.9271408],
                [108.1548974, 21.0332297],
                [108.1687924, 21.0448941],
                [108.1866334, 21.0630941],
                [108.1978812, 21.0770479],
                [108.2077848, 21.0918529],
                [108.2162734, 21.1073962],
                [108.2232868, 21.12356],
                [108.208611, 21.209722],
                [108.1597629, 21.2532481],
                [108.1347222, 21.2755556],
                [108.115661, 21.3614049],
                [108.0954722, 21.4522778],
                [108.0941111, 21.4564167],
                [108.0944167, 21.4578333],
                [108.0976389, 21.4609722],
                [108.0993611, 21.4639167],
                [108.1004444, 21.4671389],
                [108.1011944, 21.4701389],
                [108.1003184, 21.4733651],
                [108.0959683, 21.4811238],
                [108.0931306, 21.484899],
                [108.0893755, 21.4885129],
                [108.0852985, 21.4920469],
                [108.0805349, 21.4957006],
                [108.0760932, 21.4990348],
                [108.0734217, 21.5008615],
                [108.0711793, 21.5025086],
                [108.0691217, 21.5042433],
                [108.0669847, 21.506443],
                [108.0611372, 21.5156644],
                [108.0592703, 21.5190081],
                [108.0573499, 21.5230004],
                [108.0549466, 21.5277112],
                [108.0496788, 21.5344878],
                [108.0493402, 21.5350075],
                [108.0476291, 21.5376486],
                [108.0475887, 21.537711],
                [108.0427272, 21.5412466],
                [108.0426666, 21.5412907],
                [108.0418171, 21.5419085],
                [108.0375741, 21.5440356],
                [108.0373781, 21.5441338],
                [108.0365467, 21.5459392],
                [108.0363874, 21.5461616],
                [108.0358976, 21.5464881],
                [108.0349493, 21.5470814],
                [108.0336868, 21.5473979],
                [108.0264219, 21.5483595],
                [108.0229677, 21.5484054],
                [108.0222022, 21.5482893],
                [108.0212581, 21.5481895],
                [108.0202759, 21.5480321],
                [108.0182712, 21.5475412],
                [108.0163173, 21.54714],
                [108.0153937, 21.5470503],
                [108.0138153, 21.5469984],
                [108.013137, 21.5469168],
                [108.0127952, 21.5468756],
                [108.0115925, 21.5467199],
                [108.0106902, 21.5464881],
                [108.0101752, 21.5464582],
                [108.0092706, 21.5465866],
                [108.0086026, 21.5472474],
                [108.0077934, 21.5481048],
                [108.0069244, 21.548449],
                [108.0061708, 21.5486691],
                [108.0054391, 21.5487123],
                [108.0052507, 21.5487234],
                [108.0044514, 21.5485288],
                [108.0034477, 21.548122],
                [108.0025451, 21.5477233],
                [108.001914, 21.547466],
                [107.9999667, 21.5474062],
                [107.9988475, 21.547082],
                [107.9984156, 21.5469125],
                [107.9970217, 21.5464931],
                [107.993862, 21.5463534],
                [107.9928642, 21.5464282],
                [107.9920381, 21.5462985],
                [107.9915071, 21.5461888],
                [107.990284, 21.5454553],
                [107.9886103, 21.5445771],
                [107.9867542, 21.5438386],
                [107.9858315, 21.543669],
                [107.9843659, 21.5439149],
                [107.9816112, 21.5444199],
                [107.9809289, 21.5445944],
                [107.9794122, 21.5447337],
                [107.9791807, 21.5447374],
                [107.9785542, 21.5443027],
                [107.978042, 21.54338],
                [107.9777248, 21.5427111],
                [107.9776033, 21.5421103],
                [107.9775007, 21.5416034],
                [107.9769335, 21.5409124],
                [107.9762199, 21.5403265],
                [107.9754188, 21.539869],
                [107.9744491, 21.539631],
                [107.9742843, 21.5396023],
                [107.9740755, 21.5395659],
                [107.9736178, 21.5396223],
                [107.9730201, 21.5399438],
                [107.9713888, 21.5407304],
                [107.9706663, 21.5406302],
                [107.9703283, 21.5404456],
                [107.9698938, 21.5398918],
                [107.9691781, 21.5380787],
                [107.9685246, 21.5373273],
                [107.9677029, 21.5365017],
                [107.9671117, 21.5364183],
                [107.9667797, 21.536278],
                [107.9664483, 21.5360968],
                [107.9656219, 21.5361388],
                [107.9636424, 21.5371265],
                [107.9629753, 21.5373176],
                [107.9610157, 21.5373869],
                [107.9592079, 21.5370027],
                [107.9573464, 21.5365785],
                [107.9561502, 21.5365486],
                [107.9548198, 21.536853],
                [107.9538274, 21.5375915],
                [107.9527491, 21.5387241],
                [107.9514027, 21.5409446],
                [107.95003, 21.544401],
                [107.948781, 21.548438],
                [107.9464238, 21.5541545],
                [107.944457, 21.559884],
                [107.943064, 21.562724],
                [107.942439, 21.564986],
                [107.942343, 21.566184],
                [107.942391, 21.566672],
                [107.94316, 21.568092],
                [107.943158, 21.5691226],
                [107.942679, 21.569866],
                [107.9398642, 21.5722586],
                [107.9359942, 21.5747623],
                [107.932446, 21.57621],
                [107.9297466, 21.577396],
                [107.9293365, 21.577838],
                [107.9292375, 21.5781293],
                [107.9295612, 21.5786708],
                [107.929515, 21.579316],
                [107.928506, 21.580647],
                [107.926969, 21.5822],
                [107.9257339, 21.583436],
                [107.9249507, 21.5842142],
                [107.9241353, 21.5849524],
                [107.9237327, 21.5854681],
                [107.9236243, 21.5857883],
                [107.9237115, 21.5861147],
                [107.9239797, 21.5869028],
                [107.9248702, 21.5879204],
                [107.9250951, 21.5883911],
                [107.9250419, 21.5888682],
                [107.9239531, 21.5893731],
                [107.9231661, 21.5894482],
                [107.9222014, 21.5893111],
                [107.921011, 21.588854],
                [107.9190074, 21.5879935],
                [107.9172306, 21.5868977],
                [107.9160242, 21.5866326],
                [107.9154714, 21.5865198],
                [107.914953, 21.5865401],
                [107.9139759, 21.5871725],
                [107.9137733, 21.5875718],
                [107.9130821, 21.5879666],
                [107.9120995, 21.5884641],
                [107.9114299, 21.5886479],
                [107.9111974, 21.5886028],
                [107.9108369, 21.5884317],
                [107.9104076, 21.5883244],
                [107.909865, 21.588366],
                [107.909, 21.588721],
                [107.908087, 21.589209],
                [107.905445, 21.591161],
                [107.90458, 21.591028],
                [107.903811, 21.59054],
                [107.903523, 21.590052],
                [107.903619, 21.58952],
                [107.903571, 21.588056],
                [107.903475, 21.587612],
                [107.903187, 21.587035],
                [107.90261, 21.586769],
                [107.902034, 21.586725],
                [107.901265, 21.586902],
                [107.900304, 21.587301],
                [107.899487, 21.587878],
                [107.898959, 21.588677],
                [107.898671, 21.589298],
                [107.898767, 21.590008],
                [107.899295, 21.590584],
                [107.899584, 21.591516],
                [107.899728, 21.592448],
                [107.899535, 21.593202],
                [107.898863, 21.594134],
                [107.898046, 21.595065],
                [107.896268, 21.596219],
                [107.895211, 21.596485],
                [107.89401, 21.596574],
                [107.892857, 21.596929],
                [107.891848, 21.597461],
                [107.890599, 21.59857],
                [107.890311, 21.599058],
                [107.890551, 21.599901],
                [107.891176, 21.600566],
                [107.8927009, 21.6013997],
                [107.8930191, 21.6016427],
                [107.893338, 21.602341],
                [107.892905, 21.603184],
                [107.892569, 21.604071],
                [107.892809, 21.604959],
                [107.892809, 21.605535],
                [107.892774, 21.6055817],
                [107.892377, 21.606112],
                [107.891896, 21.606556],
                [107.891405, 21.60667],
                [107.8908747, 21.6066477],
                [107.8900071, 21.6065433],
                [107.8889673, 21.6065173],
                [107.886948, 21.606334],
                [107.886035, 21.606511],
                [107.884594, 21.606955],
                [107.884257, 21.607177],
                [107.884017, 21.607709],
                [107.883969, 21.607975],
                [107.883152, 21.609306],
                [107.88296, 21.610194],
                [107.883056, 21.610903],
                [107.883344, 21.611746],
                [107.884113, 21.612234],
                [107.885074, 21.612367],
                [107.886419, 21.612456],
                [107.888725, 21.612989],
                [107.889254, 21.613255],
                [107.890215, 21.613831],
                [107.890647, 21.614586],
                [107.890839, 21.615295],
                [107.890936, 21.616316],
                [107.890743, 21.616937],
                [107.890359, 21.617602],
                [107.889782, 21.618224],
                [107.888918, 21.6188],
                [107.888245, 21.618712],
                [107.886756, 21.6192],
                [107.885939, 21.619732],
                [107.885603, 21.620442],
                [107.885603, 21.621152],
                [107.8865674, 21.6221297],
                [107.8871644, 21.6227098],
                [107.8874419, 21.623039],
                [107.888581, 21.624745],
                [107.888581, 21.625278],
                [107.888245, 21.625899],
                [107.887717, 21.626342],
                [107.886852, 21.62683],
                [107.885939, 21.627451],
                [107.88517, 21.628206],
                [107.88445, 21.628782],
                [107.8832, 21.629403],
                [107.882384, 21.629625],
                [107.880942, 21.629448],
                [107.879933, 21.629536],
                [107.879309, 21.629891],
                [107.87878, 21.630513],
                [107.878444, 21.631089],
                [107.878156, 21.632243],
                [107.8783, 21.632997],
                [107.878492, 21.633618],
                [107.8786645, 21.634328],
                [107.8789199, 21.6352968],
                [107.8789073, 21.635792],
                [107.878732, 21.636812],
                [107.878732, 21.6377],
                [107.878588, 21.639075],
                [107.878396, 21.639829],
                [107.878108, 21.64014],
                [107.877531, 21.640406],
                [107.876714, 21.640628],
                [107.876042, 21.640938],
                [107.875369, 21.641116],
                [107.8745797, 21.6414001],
                [107.8731991, 21.6413464],
                [107.871526, 21.642269],
                [107.871141, 21.64289],
                [107.871189, 21.643778],
                [107.871478, 21.644665],
                [107.871526, 21.645419],
                [107.871766, 21.646173],
                [107.87191, 21.646883],
                [107.871333, 21.647637],
                [107.86946, 21.648746],
                [107.868018, 21.649234],
                [107.867442, 21.649501],
                [107.867057, 21.649501],
                [107.866241, 21.649101],
                [107.86552, 21.648835],
                [107.864799, 21.648702],
                [107.864079, 21.648791],
                [107.862109, 21.64919],
                [107.860908, 21.649589],
                [107.860331, 21.649944],
                [107.860091, 21.650388],
                [107.860139, 21.651408],
                [107.860283, 21.653005],
                [107.859082, 21.654381],
                [107.857497, 21.654913],
                [107.85668, 21.654824],
                [107.854326, 21.652296],
                [107.8534342, 21.6510666],
                [107.851203, 21.650521],
                [107.849809, 21.65021],
                [107.848128, 21.649944],
                [107.846494, 21.649456],
                [107.84563, 21.648924],
                [107.845197, 21.648525],
                [107.845101, 21.647593],
                [107.845485, 21.646395],
                [107.845582, 21.64573],
                [107.845582, 21.644887],
                [107.845197, 21.642801],
                [107.844428, 21.642003],
                [107.844044, 21.641781],
                [107.843612, 21.641426],
                [107.843035, 21.641249],
                [107.842266, 21.641116],
                [107.841594, 21.64116],
                [107.840873, 21.641382],
                [107.840537, 21.641648],
                [107.839864, 21.642624],
                [107.838615, 21.643334],
                [107.837991, 21.643556],
                [107.837222, 21.643689],
                [107.835973, 21.643156],
                [107.834964, 21.643112],
                [107.834339, 21.643245],
                [107.833859, 21.643556],
                [107.832994, 21.644576],
                [107.832561, 21.645375],
                [107.832273, 21.646395],
                [107.831408, 21.647992],
                [107.831216, 21.648569],
                [107.830928, 21.649146],
                [107.83064, 21.649944],
                [107.830159, 21.650654],
                [107.829391, 21.651231],
                [107.828622, 21.652118],
                [107.827901, 21.652784],
                [107.826988, 21.653405],
                [107.825835, 21.653804],
                [107.825211, 21.653804],
                [107.824394, 21.653671],
                [107.823673, 21.653449],
                [107.823049, 21.653316],
                [107.822232, 21.653449],
                [107.821607, 21.653671],
                [107.820406, 21.654514],
                [107.819926, 21.65509],
                [107.8189391, 21.6556632],
                [107.8182739, 21.6562167],
                [107.8173137, 21.6569296],
                [107.8161657, 21.6579268],
                [107.815361, 21.6587045],
                [107.8139126, 21.6584951],
                [107.811842, 21.6579667],
                [107.8100347, 21.657559],
                [107.8098286, 21.6575125],
                [107.8085428, 21.6579766],
                [107.8069389, 21.6577024],
                [107.8056675, 21.6578994],
                [107.80541, 21.6581287],
                [107.8051123, 21.6587419],
                [107.8047609, 21.6588342],
                [107.8041065, 21.6583655],
                [107.8038597, 21.6576675],
                [107.8039026, 21.6563812],
                [107.8036559, 21.6555635],
                [107.802774, 21.655135],
                [107.800516, 21.654514],
                [107.799267, 21.654425],
                [107.797921, 21.65407],
                [107.797057, 21.653937],
                [107.795856, 21.653981],
                [107.793982, 21.654736],
                [107.7934366, 21.6551048],
                [107.7931416, 21.6553691],
                [107.7927929, 21.6556383],
                [107.7923906, 21.6556533],
                [107.791779, 21.6554239],
                [107.7912694, 21.6551347],
                [107.7905989, 21.6546362],
                [107.7902716, 21.6545614],
                [107.7899015, 21.654681],
                [107.7888823, 21.6558427],
                [107.7882707, 21.656441],
                [107.7873534, 21.6564061],
                [107.784517, 21.655623],
                [107.7828634, 21.6554588],
                [107.782225, 21.6550949],
                [107.781651, 21.6544916],
                [107.7810985, 21.6540628],
                [107.7806961, 21.653649],
                [107.7804387, 21.6530208],
                [107.7802831, 21.6524673],
                [107.7799934, 21.651171],
                [107.7801543, 21.6507721],
                [107.780818, 21.650077],
                [107.7808034, 21.6496203],
                [107.7807015, 21.6493312],
                [107.7802134, 21.6489273],
                [107.7790546, 21.6480797],
                [107.7777296, 21.6479301],
                [107.7762759, 21.6483888],
                [107.7750045, 21.6484022],
                [107.7745914, 21.6484038],
                [107.7735481, 21.6482392],
                [107.7728856, 21.6479799],
                [107.7721292, 21.6470326],
                [107.7720648, 21.6460753],
                [107.7718234, 21.645437],
                [107.7712762, 21.6450481],
                [107.7706164, 21.6447689],
                [107.7701282, 21.6446343],
                [107.7696186, 21.6445495],
                [107.7690661, 21.6444548],
                [107.767725, 21.6442703],
                [107.7669901, 21.6442354],
                [107.7659815, 21.6438614],
                [107.7650213, 21.6431733],
                [107.7644634, 21.6429389],
                [107.7632886, 21.6427794],
                [107.7618463, 21.6426818],
                [107.7602363, 21.6425401],
                [107.7588201, 21.6423905],
                [107.7574897, 21.6422509],
                [107.755979, 21.642003],
                [107.755114, 21.642225],
                [107.754297, 21.642491],
                [107.75324, 21.642935],
                [107.7527529, 21.6430536],
                [107.7524364, 21.642909],
                [107.7515459, 21.6424304],
                [107.7507681, 21.6418968],
                [107.7503014, 21.6414979],
                [107.7495557, 21.6409644],
                [107.7485809, 21.6409837],
                [107.7482951, 21.6409893],
                [107.7470505, 21.640755],
                [107.7457577, 21.6404009],
                [107.7445453, 21.6399671],
                [107.7430272, 21.6396679],
                [107.7417934, 21.6391494],
                [107.7409726, 21.6388402],
                [107.7402055, 21.6375537],
                [107.7395028, 21.6368855],
                [107.738618, 21.636191],
                [107.736985, 21.635038],
                [107.736264, 21.634284],
                [107.735495, 21.633662],
                [107.735111, 21.632997],
                [107.734486, 21.632509],
                [107.734006, 21.632287],
                [107.732901, 21.63211],
                [107.732132, 21.631222],
                [107.731459, 21.630912],
                [107.730643, 21.630468],
                [107.729874, 21.630291],
                [107.729009, 21.62998],
                [107.727808, 21.629891],
                [107.726078, 21.629625],
                [107.724877, 21.629537],
                [107.724349, 21.629581],
                [107.723244, 21.629537],
                [107.722523, 21.62927],
                [107.721706, 21.629093],
                [107.721274, 21.628694],
                [107.720457, 21.627363],
                [107.720265, 21.626786],
                [107.7200494, 21.626197],
                [107.7199037, 21.6259818],
                [107.7196356, 21.6259311],
                [107.718535, 21.625899],
                [107.718199, 21.625766],
                [107.717622, 21.624878],
                [107.71743, 21.624523],
                [107.7172351, 21.6235313],
                [107.7170312, 21.6222845],
                [107.7164894, 21.6215863],
                [107.7154648, 21.621337],
                [107.7138448, 21.6212472],
                [107.71301, 21.621019],
                [107.7111196, 21.6202199],
                [107.710154, 21.619811],
                [107.7088559, 21.6191327],
                [107.7081585, 21.6189133],
                [107.7067057, 21.6188476],
                [107.7058947, 21.618659],
                [107.7056909, 21.6184894],
                [107.7051598, 21.6178311],
                [107.7046394, 21.6174022],
                [107.7041042, 21.6170881],
                [107.703545, 21.61676],
                [107.703016, 21.616538],
                [107.702584, 21.616493],
                [107.702104, 21.616538],
                [107.701671, 21.616715],
                [107.701335, 21.616715],
                [107.700854, 21.616493],
                [107.699509, 21.61565],
                [107.6987761, 21.6153126],
                [107.6981217, 21.6154024],
                [107.6976013, 21.6156817],
                [107.6970005, 21.6162801],
                [107.6967269, 21.6162701],
                [107.696051, 21.615946],
                [107.6956326, 21.616315],
                [107.6949298, 21.6161804],
                [107.69427, 21.6156817],
                [107.6937711, 21.6154123],
                [107.6934385, 21.6150732],
                [107.6928967, 21.6146892],
                [107.6924354, 21.6142653],
                [107.6920813, 21.6142653],
                [107.691738, 21.6143999],
                [107.691245, 21.614719],
                [107.6908368, 21.6147491],
                [107.690524, 21.614719],
                [107.6899, 21.614053],
                [107.689467, 21.613787],
                [107.689131, 21.613432],
                [107.688891, 21.613033],
                [107.6889646, 21.6121108],
                [107.6888198, 21.6117467],
                [107.6885354, 21.6113228],
                [107.6882297, 21.6111433],
                [107.6877683, 21.6111582],
                [107.6869476, 21.6113577],
                [107.6861268, 21.6114824],
                [107.684279, 21.611347],
                [107.6834285, 21.6109488],
                [107.6828384, 21.6107493],
                [107.6819748, 21.61047],
                [107.6815939, 21.610864],
                [107.681154, 21.6109986],
                [107.6807517, 21.6109488],
                [107.68058, 21.6110335],
                [107.6803708, 21.6110435],
                [107.6798987, 21.6107991],
                [107.6791906, 21.6109637],
                [107.6786059, 21.6111133],
                [107.6781124, 21.6104001],
                [107.6773667, 21.6098366],
                [107.6765996, 21.6091284],
                [107.6761866, 21.6088391],
                [107.6759076, 21.6088391],
                [107.6754248, 21.6091483],
                [107.674942, 21.6093279],
                [107.6738691, 21.6099862],
                [107.6728499, 21.610834],
                [107.6707256, 21.6123701],
                [107.6691055, 21.6132479],
                [107.6671958, 21.6125796],
                [107.6658976, 21.6134075],
                [107.6651573, 21.6133876],
                [107.6641381, 21.6128788],
                [107.6620245, 21.6111732],
                [107.660557, 21.6100632],
                [107.657969, 21.6091782],
                [107.6549864, 21.608081],
                [107.6519823, 21.6070386],
                [107.6512098, 21.6071384],
                [107.6499623, 21.6070587],
                [107.6464535, 21.6070907],
                [107.6432171, 21.6071191],
                [107.6366076, 21.6071321],
                [107.6310664, 21.6071484],
                [107.6288222, 21.607443],
                [107.6282501, 21.6075623],
                [107.6231003, 21.6087194],
                [107.6218215, 21.6087575],
                [107.6185215, 21.6084847],
                [107.613848, 21.6082567],
                [107.6120595, 21.6081191],
                [107.605766, 21.6048563],
                [107.6040741, 21.6041363],
                [107.6031768, 21.6034875],
                [107.6010579, 21.6038765],
                [107.5938213, 21.6047893],
                [107.5932232, 21.6045014],
                [107.5928461, 21.6043117],
                [107.5925978, 21.6049719],
                [107.5926687, 21.6054094],
                [107.5921583, 21.6063254],
                [107.5899696, 21.6066147],
                [107.5896478, 21.6077917],
                [107.5881886, 21.6086496],
                [107.5876428, 21.6091121],
                [107.5862682, 21.6107293],
                [107.5851577, 21.6107493],
                [107.584488, 21.611835],
                [107.5835323, 21.6133377],
                [107.5830603, 21.6147241],
                [107.5828242, 21.6156916],
                [107.5825775, 21.616325],
                [107.5822234, 21.6168337],
                [107.5818962, 21.6170531],
                [107.580421, 21.6169484],
                [107.5795895, 21.6171928],
                [107.5787902, 21.6177912],
                [107.5777602, 21.618265],
                [107.5769609, 21.6181104],
                [107.5760865, 21.6179009],
                [107.5744343, 21.6175069],
                [107.5735545, 21.6173224],
                [107.572723, 21.6168935],
                [107.57214, 21.616538],
                [107.5716716, 21.6160258],
                [107.5710547, 21.6150333],
                [107.5707918, 21.6142553],
                [107.5705558, 21.6136718],
                [107.5701535, 21.6131182],
                [107.5696224, 21.612415],
                [107.568873, 21.612013],
                [107.568152, 21.611924],
                [107.567143, 21.612101],
                [107.5656152, 21.6124998],
                [107.5639093, 21.6126744],
                [107.5630081, 21.6126993],
                [107.5614631, 21.6127242],
                [107.5606209, 21.6129935],
                [107.5601327, 21.6132529],
                [107.5596768, 21.6131781],
                [107.5591296, 21.6126345],
                [107.5586629, 21.6118614],
                [107.5584269, 21.6115123],
                [107.5579655, 21.6112181],
                [107.5576544, 21.6108291],
                [107.5572735, 21.61044],
                [107.5571126, 21.6099712],
                [107.5569624, 21.6095024],
                [107.5566298, 21.6089738],
                [107.555276, 21.607931],
                [107.554891, 21.607576],
                [107.554555, 21.606822],
                [107.554555, 21.606201],
                [107.553546, 21.605136],
                [107.552009, 21.604515],
                [107.551624, 21.604249],
                [107.551192, 21.603849],
                [107.551096, 21.603051],
                [107.550904, 21.602607],
                [107.550663, 21.601676],
                [107.550375, 21.600833],
                [107.550135, 21.600433],
                [107.549702, 21.600034],
                [107.548934, 21.599635],
                [107.548501, 21.599236],
                [107.548261, 21.598748],
                [107.548213, 21.598393],
                [107.548261, 21.597772],
                [107.548213, 21.596662],
                [107.547685, 21.595731],
                [107.547252, 21.59511],
                [107.547012, 21.594577],
                [107.5465661, 21.5936716],
                [107.5463086, 21.5933125],
                [107.5458258, 21.5928885],
                [107.545402, 21.5924146],
                [107.5449943, 21.5919109],
                [107.544356, 21.5912624],
                [107.5439751, 21.5907387],
                [107.5437766, 21.5904194],
                [107.543553, 21.590052],
                [107.543312, 21.589653],
                [107.54312, 21.589475],
                [107.542544, 21.589431],
                [107.541631, 21.589653],
                [107.540862, 21.590008],
                [107.540382, 21.59054],
                [107.539661, 21.590806],
                [107.539084, 21.591206],
                [107.538892, 21.591827],
                [107.538604, 21.592448],
                [107.538316, 21.592891],
                [107.537931, 21.593646],
                [107.537643, 21.594001],
                [107.537355, 21.594134],
                [107.536586, 21.594134],
                [107.536202, 21.593823],
                [107.534472, 21.593513],
                [107.533367, 21.593246],
                [107.532983, 21.593069],
                [107.532983, 21.592536],
                [107.53279, 21.591649],
                [107.532214, 21.591117],
                [107.531829, 21.590851],
                [107.531349, 21.590673],
                [107.530724, 21.590584],
                [107.528706, 21.590451],
                [107.527794, 21.590274],
                [107.526544, 21.590318],
                [107.525535, 21.590185],
                [107.524574, 21.590274],
                [107.523662, 21.590451],
                [107.522797, 21.590806],
                [107.52174, 21.591383],
                [107.520923, 21.592137],
                [107.52025, 21.592936],
                [107.519674, 21.593468],
                [107.519097, 21.593646],
                [107.517464, 21.593601],
                [107.516359, 21.593734],
                [107.5144716, 21.5946823],
                [107.513188, 21.595243],
                [107.5119757, 21.5953715],
                [107.5097728, 21.596413],
                [107.50723, 21.5971268],
                [107.505452, 21.597417],
                [107.504732, 21.597417],
                [107.503963, 21.597106],
                [107.503242, 21.596751],
                [107.5022697, 21.5963122],
                [107.500119, 21.595198],
                [107.498872, 21.595126],
                [107.4977984, 21.5956745],
                [107.4957011, 21.5966307],
                [107.4938316, 21.5963347],
                [107.4925919, 21.596646],
                [107.4924642, 21.596756],
                [107.4922551, 21.5972393],
                [107.4905806, 21.5985293],
                [107.4893386, 21.5987353],
                [107.4880391, 21.598789],
                [107.4869241, 21.5988943],
                [107.4860701, 21.5990371],
                [107.4847144, 21.5988989],
                [107.4833275, 21.5986359],
                [107.4826571, 21.5987606],
                [107.4841082, 21.5994176],
                [107.4848968, 21.5995273],
                [107.4883139, 21.6013728],
                [107.4894458, 21.6026496],
                [107.4908835, 21.6039015],
                [107.4921207, 21.6039537],
                [107.4927654, 21.6043186],
                [107.4937141, 21.6048952],
                [107.4946654, 21.6075274],
                [107.4940163, 21.6084251],
                [107.4939036, 21.6090685],
                [107.4942148, 21.6096919],
                [107.4950693, 21.6107286],
                [107.4952984, 21.6111034],
                [107.4956846, 21.6122255],
                [107.4967521, 21.6126444],
                [107.4971437, 21.6129836],
                [107.4976265, 21.6141107],
                [107.4977821, 21.6145047],
                [107.497095, 21.61641],
                [107.4969774, 21.6171279],
                [107.4961138, 21.6184695],
                [107.4954325, 21.6197412],
                [107.4947727, 21.6198858],
                [107.4942577, 21.6201252],
                [107.4940592, 21.6204443],
                [107.4940485, 21.621342],
                [107.4928254, 21.6224142],
                [107.4927449, 21.6228231],
                [107.4923801, 21.6238255],
                [107.4925679, 21.6241596],
                [107.4929702, 21.6246333],
                [107.4929917, 21.6248877],
                [107.4928147, 21.625137],
                [107.4922782, 21.6249375],
                [107.4913824, 21.6249375],
                [107.4904221, 21.6245735],
                [107.4894458, 21.6238604],
                [107.488566, 21.624],
                [107.4879974, 21.624399],
                [107.4878955, 21.6246732],
                [107.488153, 21.6256756],
                [107.4878043, 21.6262989],
                [107.4873912, 21.6265583],
                [107.486726, 21.625885],
                [107.4862218, 21.6262142],
                [107.4861896, 21.6269921],
                [107.4852669, 21.6273013],
                [107.4853527, 21.6283884],
                [107.4847948, 21.6289369],
                [107.4845052, 21.6303033],
                [107.4840546, 21.630782],
                [107.4841833, 21.6310413],
                [107.4854064, 21.6312208],
                [107.4852884, 21.6316297],
                [107.484988, 21.6318492],
                [107.4843228, 21.6320785],
                [107.484355, 21.6329562],
                [107.4842584, 21.6337141],
                [107.4845803, 21.6339236],
                [107.4850523, 21.6340831],
                [107.4849558, 21.6347613],
                [107.4848807, 21.6354395],
                [107.4843442, 21.6355492],
                [107.4842262, 21.635968],
                [107.4847948, 21.6364168],
                [107.4853742, 21.6368058],
                [107.484945, 21.6379726],
                [107.4850738, 21.6385809],
                [107.484945, 21.6389699],
                [107.4843979, 21.6392092],
                [107.4847305, 21.6399372],
                [107.4846446, 21.6406054],
                [107.4850738, 21.6417123],
                [107.4851918, 21.642201],
                [107.4854708, 21.6425002],
                [107.4859536, 21.6432182],
                [107.4859857, 21.6434775],
                [107.4858248, 21.6437766],
                [107.4852884, 21.643647],
                [107.4846125, 21.6433678],
                [107.4842799, 21.6435872],
                [107.4843442, 21.6443451],
                [107.4836469, 21.6449733],
                [107.4835288, 21.6456714],
                [107.4841511, 21.6461401],
                [107.4839687, 21.6465091],
                [107.4840009, 21.646883],
                [107.4837917, 21.6474016],
                [107.4842584, 21.6476558],
                [107.4842852, 21.6478802],
                [107.4840975, 21.648663],
                [107.4833143, 21.6493511],
                [107.4824881, 21.6501588],
                [107.4816084, 21.6509466],
                [107.4814421, 21.6514402],
                [107.4810827, 21.6519438],
                [107.4811149, 21.6526219],
                [107.4808627, 21.6537836],
                [107.4808144, 21.6542174],
                [107.482413, 21.6571739],
                [107.4821816, 21.6574942],
                [107.481941, 21.6578271],
                [107.4818874, 21.6578717],
                [107.4805033, 21.6590236],
                [107.4798113, 21.6593427],
                [107.4791927, 21.659133],
                [107.4776669, 21.6595797],
                [107.4776065, 21.659602],
                [107.4768132, 21.6597609],
                [107.475965, 21.659941],
                [107.474817, 21.6617059],
                [107.4740231, 21.662374],
                [107.4728215, 21.6628725],
                [107.471416, 21.6633212],
                [107.470386, 21.6630021],
                [107.4701393, 21.6624338],
                [107.4694419, 21.6621546],
                [107.4682832, 21.6621446],
                [107.4662876, 21.6632414],
                [107.4652254, 21.6635306],
                [107.463482, 21.66349],
                [107.461459, 21.662807],
                [107.459632, 21.661941],
                [107.456029, 21.659297],
                [107.454005, 21.658477],
                [107.452327, 21.657885],
                [107.450057, 21.657657],
                [107.44907, 21.657246],
                [107.448033, 21.656426],
                [107.445072, 21.65474],
                [107.44443, 21.654102],
                [107.441913, 21.654284],
                [107.440679, 21.654056],
                [107.439889, 21.653372],
                [107.438852, 21.651185],
                [107.437865, 21.650683],
                [107.436236, 21.650137],
                [107.434163, 21.650729],
                [107.431202, 21.64959],
                [107.42982, 21.648724],
                [107.428734, 21.647311],
                [107.42671, 21.645579],
                [107.423601, 21.643163],
                [107.420392, 21.641112],
                [107.418961, 21.640474],
                [107.417431, 21.640019],
                [107.416, 21.638378],
                [107.415901, 21.637649],
                [107.416, 21.636053],
                [107.41674, 21.634276],
                [107.417431, 21.633],
                [107.417184, 21.632362],
                [107.414568, 21.630356],
                [107.412989, 21.629673],
                [107.410323, 21.627667],
                [107.4085665, 21.6259748],
                [107.4084377, 21.6235113],
                [107.408309, 21.6231423],
                [107.4067747, 21.6223843],
                [107.4051547, 21.622095],
                [107.4040496, 21.6214966],
                [107.4033415, 21.6212173],
                [107.4023116, 21.6207186],
                [107.4011421, 21.6199007],
                [107.399465, 21.619692],
                [107.397737, 21.618552],
                [107.395615, 21.617367],
                [107.394677, 21.616045],
                [107.394726, 21.615225],
                [107.396207, 21.612445],
                [107.396898, 21.610986],
                [107.397984, 21.609756],
                [107.397638, 21.609528],
                [107.394183, 21.60848],
                [107.3926663, 21.6066396],
                [107.392146, 21.6053728],
                [107.3917436, 21.6038516],
                [107.3907566, 21.6027244],
                [107.389543, 21.602373],
                [107.388507, 21.601689],
                [107.384311, 21.598407],
                [107.384015, 21.597633],
                [107.384065, 21.597086],
                [107.383867, 21.596539],
                [107.382633, 21.595217],
                [107.380215, 21.596311],
                [107.378685, 21.596858],
                [107.376101, 21.5986545],
                [107.3738372, 21.5988639],
                [107.371434, 21.6003802],
                [107.3700821, 21.6015374],
                [107.3697281, 21.602081],
                [107.368623, 21.6018715],
                [107.3680812, 21.6017069],
                [107.3674214, 21.6014376],
                [107.3661017, 21.6005997],
                [107.3646212, 21.5993976],
                [107.3639613, 21.599243],
                [107.3628429, 21.59946],
                [107.3620891, 21.5996819],
                [107.3614883, 21.5987542],
                [107.3596553, 21.5950915],
                [107.3541483, 21.5941305],
                [107.348039, 21.5945514],
                [107.3438422, 21.5920557],
                [107.3433795, 21.5899266],
                [107.3400983, 21.5892962],
                [107.3405814, 21.5875406],
                [107.337372, 21.5874178],
                [107.3361209, 21.5870474],
                [107.3334111, 21.587849],
                [107.3308142, 21.5869667],
                [107.3286338, 21.5884238],
                [107.3286466, 21.5892218],
                [107.3284585, 21.5902778],
                [107.3279609, 21.5912107],
                [107.3276617, 21.5917257],
                [107.3274252, 21.5918886],
                [107.3271187, 21.5919567],
                [107.3261964, 21.5919696],
                [107.3251723, 21.5920159],
                [107.324663, 21.5922146],
                [107.3237546, 21.5930891],
                [107.323232, 21.5935275],
                [107.3222445, 21.5937328],
                [107.3209128, 21.5937834],
                [107.3198876, 21.5937657],
                [107.3172378, 21.5925576],
                [107.3150914, 21.5907997],
                [107.3137421, 21.5897329],
                [107.3126511, 21.5909454],
                [107.3119408, 21.5914021],
                [107.3107218, 21.5920895],
                [107.3105596, 21.5926345],
                [107.3105196, 21.5944229],
                [107.3103626, 21.595287],
                [107.3097803, 21.5962689],
                [107.3086154, 21.5960616],
                [107.3069988, 21.5953816],
                [107.3057987, 21.5951109],
                [107.304907, 21.5948999],
                [107.3039807, 21.5946573],
                [107.3027801, 21.5943546],
                [107.3015791, 21.59402],
                [107.2993028, 21.5937482],
                [107.2981745, 21.5936999],
                [107.2973527, 21.5935835],
                [107.2964931, 21.5932442],
                [107.295566, 21.5929378],
                [107.2948772, 21.5925961],
                [107.2936742, 21.5921338],
                [107.2926073, 21.5916378],
                [107.2925753, 21.5906963],
                [107.2924642, 21.5901551],
                [107.2923492, 21.5893586],
                [107.2916856, 21.5884419],
                [107.2911272, 21.5876833],
                [107.2907373, 21.5867948],
                [107.2903802, 21.58581],
                [107.2898003, 21.5847804],
                [107.2897842, 21.583759],
                [107.2897726, 21.5830248],
                [107.2897275, 21.5823231],
                [107.2895818, 21.5817504],
                [107.2892664, 21.581244],
                [107.2888515, 21.5809303],
                [107.2881654, 21.5807483],
                [107.2874466, 21.5806624],
                [107.2865872, 21.580323],
                [107.2860892, 21.5801543],
                [107.2856387, 21.5797454],
                [107.2852569, 21.5793675],
                [107.2848411, 21.5789901],
                [107.2841851, 21.5785521],
                [107.2837025, 21.5782715],
                [107.2833183, 21.577734],
                [107.2833716, 21.5767755],
                [107.2832918, 21.5760422],
                [107.2832094, 21.5751494],
                [107.283232, 21.5744149],
                [107.2830506, 21.5726454],
                [107.2830779, 21.5713119],
                [107.2833043, 21.570511],
                [107.2835364, 21.5700611],
                [107.2835971, 21.5695815],
                [107.2834883, 21.5691682],
                [107.2830508, 21.5684879],
                [107.2822576, 21.5679882],
                [107.2814969, 21.5673923],
                [107.2809754, 21.5667931],
                [107.2805172, 21.5658739],
                [107.2799274, 21.5652756],
                [107.2793049, 21.5647735],
                [107.27872, 21.5644943],
                [107.2778205, 21.5637407],
                [107.2770105, 21.5632571],
                [107.2759825, 21.5630159],
                [107.2751937, 21.5628033],
                [107.27413, 21.5624668],
                [107.2731038, 21.5623532],
                [107.2722866, 21.5624919],
                [107.271169, 21.5630817],
                [107.2694339, 21.5634882],
                [107.2686873, 21.5637856],
                [107.2666252, 21.5640212],
                [107.2656016, 21.5640671],
                [107.2637165, 21.5636139],
                [107.2629278, 21.5634012],
                [107.2619388, 21.5634786],
                [107.2605054, 21.5635299],
                [107.2597184, 21.5634129],
                [107.2582594, 21.5629061],
                [107.2561918, 21.5616895],
                [107.2547057, 21.5605288],
                [107.2531824, 21.5591772],
                [107.252142, 21.558138],
                [107.2511941, 21.5575605],
                [107.250371, 21.5573154],
                [107.2494944, 21.5564916],
                [107.2486882, 21.55525],
                [107.2482754, 21.5540031],
                [107.2482145, 21.5526043],
                [107.2476729, 21.5506592],
                [107.2471005, 21.5492671],
                [107.2463241, 21.5489133],
                [107.246059, 21.5490302],
                [107.2456781, 21.5492875],
                [107.2450664, 21.5495479],
                [107.2443968, 21.549791],
                [107.2433252, 21.5501657],
                [107.242026, 21.5507595],
                [107.2395317, 21.551834],
                [107.2388826, 21.5521668],
                [107.2382133, 21.5524281],
                [107.2376379, 21.5525438],
                [107.2367914, 21.5525552],
                [107.2357525, 21.5525511],
                [107.2350465, 21.5529389],
                [107.2344838, 21.5534313],
                [107.2339529, 21.5539428],
                [107.2334806, 21.5545074],
                [107.2331406, 21.5549263],
                [107.2328518, 21.5549121],
                [107.2324281, 21.5548817],
                [107.2320021, 21.5547073],
                [107.2314395, 21.5544087],
                [107.2306033, 21.5538255],
                [107.2298624, 21.5531871],
                [107.2290627, 21.5524773],
                [107.228256, 21.5521492],
                [107.2269059, 21.5519331],
                [107.2259212, 21.5517122],
                [107.2251911, 21.5517759],
                [107.2245994, 21.5520901],
                [107.2240487, 21.5525657],
                [107.2231529, 21.5531179],
                [107.2223958, 21.5535241],
                [107.221785, 21.5538385],
                [107.2213621, 21.5538621],
                [107.2210134, 21.5537047],
                [107.2203919, 21.5533347],
                [107.219484, 21.5530947],
                [107.2185388, 21.5527128],
                [107.2176896, 21.5525439],
                [107.2165704, 21.5523246],
                [107.2158949, 21.5521896],
                [107.2155831, 21.5519236],
                [107.2153652, 21.5513925],
                [107.2152063, 21.5510703],
                [107.2151058, 21.5507836],
                [107.2147122, 21.5502124],
                [107.2143396, 21.5497492],
                [107.2140585, 21.5489329],
                [107.2139165, 21.5484486],
                [107.2136978, 21.5479832],
                [107.2135184, 21.5475713],
                [107.2132827, 21.5472503],
                [107.2129713, 21.5470202],
                [107.2125453, 21.5468459],
                [107.2120444, 21.5467984],
                [107.2115635, 21.5468048],
                [107.2110439, 21.5467937],
                [107.2107532, 21.5466535],
                [107.2104982, 21.5463327],
                [107.2103176, 21.5458487],
                [107.209988, 21.5456731],
                [107.2096794, 21.5456232],
                [107.2090638, 21.5456312],
                [107.2085246, 21.5456024],
                [107.207636, 21.5453621],
                [107.2071327, 21.5451526],
                [107.2066443, 21.5446262],
                [107.2063679, 21.5441615],
                [107.2061512, 21.5438223],
                [107.2058756, 21.5434116],
                [107.2055827, 21.5431274],
                [107.205214, 21.5429161],
                [107.2048461, 21.5427588],
                [107.2046128, 21.5425999],
                [107.2044567, 21.5424579],
                [107.2043582, 21.5422971],
                [107.2043358, 21.5420993],
                [107.2043315, 21.5418112],
                [107.2043825, 21.5413601],
                [107.2044719, 21.5409087],
                [107.2045996, 21.5404568],
                [107.2049207, 21.5400563],
                [107.2054528, 21.5396171],
                [107.2060593, 21.5390147],
                [107.2063808, 21.5386502],
                [107.2065652, 21.5381254],
                [107.2068256, 21.5375276],
                [107.2070484, 21.5370025],
                [107.2074274, 21.5366192],
                [107.2082016, 21.5360079],
                [107.208695, 21.5355511],
                [107.2090134, 21.5350996],
                [107.2096345, 21.5341909],
                [107.2100451, 21.533357],
                [107.2104953, 21.5325946],
                [107.2109688, 21.5318569],
                [107.2112125, 21.5316846],
                [107.2114203, 21.5314297],
                [107.2116297, 21.5312828],
                [107.2118215, 21.5312442],
                [107.212033, 21.5312414],
                [107.2122839, 21.5312921],
                [107.2125962, 21.5315941],
                [107.2129496, 21.5320577],
                [107.2133176, 21.532215],
                [107.2138552, 21.5321537],
                [107.214271, 21.5321313],
                [107.215405, 21.5320622],
                [107.2161372, 21.5321425],
                [107.216675, 21.5320813],
                [107.217016, 21.5317346],
                [107.2173161, 21.5312263],
                [107.2175756, 21.5305745],
                [107.2179138, 21.5300477],
                [107.2181762, 21.529594],
                [107.2186107, 21.5293581],
                [107.2196406, 21.5287681],
                [107.2203424, 21.5281103],
                [107.2210445, 21.5274707],
                [107.2215926, 21.526833],
                [107.2220036, 21.5260278],
                [107.2222413, 21.5252143],
                [107.2224537, 21.5240047],
                [107.2225155, 21.5230133],
                [107.222672, 21.5219127],
                [107.2226739, 21.520778],
                [107.2227781, 21.5200382],
                [107.2229663, 21.5197654],
                [107.223409, 21.5197776],
                [107.2250958, 21.5198994],
                [107.2263848, 21.5199182],
                [107.2273108, 21.520086],
                [107.2283938, 21.5204678],
                [107.2295727, 21.5208301],
                [107.2311067, 21.5220412],
                [107.2315237, 21.5216394],
                [107.2319756, 21.521003],
                [107.2324683, 21.5205102],
                [107.2328277, 21.5201091],
                [107.2333602, 21.5197057],
                [107.2338162, 21.5193395],
                [107.2340573, 21.5187599],
                [107.2338749, 21.5181499],
                [107.2334958, 21.5172545],
                [107.2333705, 21.5166078],
                [107.2334356, 21.5159137],
                [107.2335007, 21.5151384],
                [107.2337426, 21.5145948],
                [107.2341205, 21.5141576],
                [107.2345748, 21.5136832],
                [107.2352018, 21.5131706],
                [107.2356951, 21.5127316],
                [107.2360707, 21.5121324],
                [107.2364262, 21.5114792],
                [107.236611, 21.5109905],
                [107.2367078, 21.5099554],
                [107.2369281, 21.509268],
                [107.237303, 21.5086327],
                [107.2377908, 21.5078157],
                [107.2382772, 21.5069267],
                [107.2384422, 21.5064021],
                [107.2383359, 21.5056892],
                [107.2382559, 21.5054921],
                [107.2375667, 21.5044568],
                [107.2367027, 21.5032978],
                [107.2361957, 21.5028363],
                [107.2360572, 21.502586],
                [107.2360536, 21.5023519],
                [107.236446, 21.5015903],
                [107.2366264, 21.5008133],
                [107.2365674, 21.4998066],
                [107.2367135, 21.4991308],
                [107.236731, 21.4982954],
                [107.2364865, 21.497138],
                [107.2364797, 21.4966851],
                [107.2365462, 21.4960968],
                [107.2367669, 21.4956975],
                [107.2372044, 21.4946583],
                [107.2374255, 21.4942874],
                [107.2376879, 21.493661],
                [107.2382315, 21.4926276],
                [107.2384534, 21.4923131],
                [107.2395818, 21.4909817],
                [107.2402982, 21.4903917],
                [107.24148, 21.4895832],
                [107.2418788, 21.4889763],
                [107.2424715, 21.4882039],
                [107.2430418, 21.4869506],
                [107.2439167, 21.485849],
                [107.2445073, 21.4849422],
                [107.2451465, 21.4842401],
                [107.2457448, 21.4838357],
                [107.2459381, 21.4836209],
                [107.2461975, 21.4827963],
                [107.2463874, 21.4823691],
                [107.2463381, 21.482115],
                [107.2458051, 21.4808695],
                [107.2451904, 21.4782378],
                [107.2448948, 21.4776898],
                [107.244617, 21.4773113],
                [107.2440643, 21.4767525],
                [107.2439559, 21.4765841],
                [107.2434808, 21.4761517],
                [107.2424592, 21.4755709],
                [107.2418059, 21.475339],
                [107.2415764, 21.4751581],
                [107.2412989, 21.4748008],
                [107.2409394, 21.4740271],
                [107.2407237, 21.4737611],
                [107.2399902, 21.473233],
                [107.2397448, 21.4729957],
                [107.2397132, 21.4729111],
                [107.2397423, 21.47284],
                [107.2399964, 21.4726527],
                [107.2404131, 21.4722224],
                [107.2409295, 21.4714016],
                [107.2411642, 21.4709454],
                [107.2414948, 21.4698369],
                [107.2418026, 21.46921],
                [107.2419886, 21.468528],
                [107.24198, 21.4669782],
                [107.242131, 21.4659712],
                [107.242127, 21.4657165],
                [107.2420325, 21.4654629],
                [107.2419251, 21.4653653],
                [107.2411805, 21.4650921],
                [107.2405684, 21.4645978],
                [107.2401746, 21.4635557],
                [107.2401231, 21.4631459],
                [107.240044, 21.4629204],
                [107.2397798, 21.4624428],
                [107.2395941, 21.4621621],
                [107.2393177, 21.4618686],
                [107.2389816, 21.4616324],
                [107.2386572, 21.4611767],
                [107.2385604, 21.4607675],
                [107.2385041, 21.4600605],
                [107.2383719, 21.459312],
                [107.2384739, 21.4590701],
                [107.2386959, 21.4587557],
                [107.2393786, 21.4579398],
                [107.2398107, 21.4575447],
                [107.2403295, 21.4568866],
                [107.2405061, 21.4565729],
                [107.2408552, 21.4556906],
                [107.241028, 21.4551221],
                [107.2410344, 21.4545558],
                [107.2409153, 21.4536869],
                [107.2404804, 21.4529142],
                [107.2404169, 21.4527169],
                [107.2404278, 21.4524478],
                [107.2405589, 21.4521204],
                [107.2407072, 21.4519344],
                [107.2412295, 21.4515028],
                [107.2414818, 21.4512022],
                [107.2417007, 21.4506967],
                [107.2417557, 21.4503422],
                [107.2416225, 21.4495371],
                [107.2410476, 21.4485114],
                [107.2405484, 21.4474848],
                [107.2405121, 21.4470819],
                [107.2405841, 21.4468544],
                [107.2410426, 21.4461972],
                [107.241474, 21.4457526],
                [107.2416987, 21.4456222],
                [107.244012, 21.4447278],
                [107.2443731, 21.4446239],
                [107.2445828, 21.4445078],
                [107.245042, 21.4439071],
                [107.245401, 21.4436688],
                [107.245549, 21.4434686],
                [107.2455827, 21.4427038],
                [107.2456927, 21.4419946],
                [107.2455785, 21.4414441],
                [107.2454843, 21.4412188],
                [107.2452375, 21.4408824],
                [107.2451966, 21.4401823],
                [107.2453119, 21.4398269],
                [107.2452884, 21.4392752],
                [107.2451946, 21.4390642],
                [107.2448867, 21.4387002],
                [107.2443062, 21.4382833],
                [107.243754, 21.4377387],
                [107.2430908, 21.4368629],
                [107.2425922, 21.4358787],
                [107.241993, 21.4352215],
                [107.2418065, 21.4348843],
                [107.241814, 21.4343888],
                [107.2418871, 21.4342321],
                [107.2422732, 21.4337881],
                [107.2427216, 21.4334707],
                [107.2436812, 21.4329836],
                [107.2442528, 21.4328202],
                [107.2446401, 21.4324612],
                [107.2456593, 21.4309329],
                [107.2458184, 21.4304638],
                [107.2459735, 21.4297468],
                [107.2461065, 21.4295469],
                [107.2463315, 21.4294448],
                [107.2464976, 21.4294425],
                [107.2474682, 21.4296702],
                [107.2477098, 21.4296669],
                [107.2478596, 21.42958],
                [107.2477191, 21.4282938],
                [107.2478987, 21.4272015],
                [107.2476684, 21.4259778],
                [107.2475422, 21.4256259],
                [107.2474921, 21.4253152],
                [107.2482825, 21.4236495],
                [107.2485802, 21.4233625],
                [107.2491194, 21.4230582],
                [107.2502817, 21.4228982],
                [107.2517144, 21.4228879],
                [107.2523856, 21.4228279],
                [107.2530879, 21.4227025],
                [107.2539692, 21.4218012],
                [107.2560626, 21.4185714],
                [107.2567728, 21.4171388],
                [107.2571431, 21.416524],
                [107.2571085, 21.4160416],
                [107.2565269, 21.4152618],
                [107.2561646, 21.4146314],
                [107.2561538, 21.4130434],
                [107.256343, 21.4112367],
                [107.2566172, 21.4096576],
                [107.2568479, 21.4087905],
                [107.2573792, 21.4080719],
                [107.258036, 21.4075675],
                [107.2582995, 21.4070558],
                [107.2582919, 21.4065731],
                [107.257698, 21.4049802],
                [107.257797, 21.404369],
                [107.2580305, 21.4036799],
                [107.2586181, 21.4030875],
                [107.2592878, 21.4025448],
                [107.2602709, 21.4021123],
                [107.2607296, 21.4019537],
                [107.2613996, 21.4014364],
                [107.2619342, 21.400921],
                [107.2635563, 21.4005942],
                [107.2646936, 21.4004772],
                [107.2649624, 21.4003211],
                [107.2649565, 21.3999401],
                [107.2636203, 21.3968199],
                [107.2627889, 21.3956622],
                [107.2618246, 21.3946843],
                [107.2610322, 21.3925352],
                [107.2609658, 21.3917484],
                [107.2613157, 21.3889358],
                [107.2614538, 21.3873331],
                [107.2613883, 21.386597],
                [107.2610834, 21.3861692],
                [107.2599562, 21.3851679],
                [107.2594015, 21.3843623],
                [107.2588873, 21.3826668],
                [107.2588203, 21.3823706],
                [107.2590585, 21.3819438],
                [107.2598812, 21.3797181],
                [107.2591499, 21.3787045],
                [107.2588898, 21.3777023],
                [107.2587544, 21.3762395],
                [107.2582686, 21.374649],
                [107.257786, 21.3738791],
                [107.2570189, 21.3729718],
                [107.2558423, 21.3711525],
                [107.2557305, 21.3703143],
                [107.2552262, 21.3691447],
                [107.2545561, 21.3684184],
                [107.2537869, 21.36737],
                [107.252864, 21.3665296],
                [107.25269, 21.366429],
                [107.2525157, 21.3663283],
                [107.2521862, 21.3663327],
                [107.2513165, 21.3669179],
                [107.2510825, 21.3670093],
                [107.2504719, 21.3671057],
                [107.2499517, 21.3672721],
                [107.2493959, 21.3673702],
                [107.2486451, 21.367443],
                [107.2466199, 21.3672273],
                [107.2459235, 21.367074],
                [107.2447168, 21.366472],
                [107.2437568, 21.3661108],
                [107.2434059, 21.3658552],
                [107.2422861, 21.3652521],
                [107.2412306, 21.3643227],
                [107.2407273, 21.3643184],
                [107.2406909, 21.3648801],
                [107.239737, 21.3668076],
                [107.2391896, 21.3676094],
                [107.2385648, 21.3688889],
                [107.2383661, 21.3691935],
                [107.2375471, 21.369967],
                [107.2372616, 21.3701457],
                [107.2368897, 21.3702143],
                [107.2360596, 21.3702571],
                [107.2356896, 21.3704526],
                [107.2354953, 21.3710511],
                [107.235465, 21.3712898],
                [107.2354722, 21.3717664],
                [107.2355692, 21.3725755],
                [107.2355178, 21.3736567],
                [107.2352495, 21.373851],
                [107.2349292, 21.3739665],
                [107.2345761, 21.3741619],
                [107.233468, 21.3748836],
                [107.2326256, 21.3752286],
                [107.2315084, 21.3753388],
                [107.2312727, 21.3754531],
                [107.2306543, 21.3760493],
                [107.230032, 21.3763753],
                [107.2290524, 21.3766267],
                [107.2275224, 21.3774733],
                [107.227374, 21.3777533],
                [107.2275664, 21.3781481],
                [107.2279658, 21.3787625],
                [107.228089, 21.3790627],
                [107.2281808, 21.3795222],
                [107.2281696, 21.3799037],
                [107.2281213, 21.3800792],
                [107.2279221, 21.3803519],
                [107.2273852, 21.3807245],
                [107.2260856, 21.3811232],
                [107.2254338, 21.3817436],
                [107.2244005, 21.3829331],
                [107.2231207, 21.3846345],
                [107.2227544, 21.3850841],
                [107.2224538, 21.3853742],
                [107.2219475, 21.3855398],
                [107.2211677, 21.3855501],
                [107.2205752, 21.3856136],
                [107.2201533, 21.3857463],
                [107.2191784, 21.3863153],
                [107.2186264, 21.3868151],
                [107.2172274, 21.3884862],
                [107.2168261, 21.388857],
                [107.216557, 21.3890035],
                [107.2161441, 21.3886118],
                [107.2154233, 21.3880334],
                [107.2146376, 21.3876624],
                [107.2142629, 21.3875481],
                [107.2135153, 21.3874468],
                [107.2115978, 21.3873449],
                [107.2104879, 21.3868352],
                [107.2100809, 21.3868246],
                [107.2095876, 21.38672],
                [107.2093658, 21.3866275],
                [107.209159, 21.3864077],
                [107.2075988, 21.3841322],
                [107.2073532, 21.3835792],
                [107.207075, 21.3831221],
                [107.2068267, 21.3823944],
                [107.2063783, 21.3818918],
                [107.2049673, 21.380512],
                [107.2045189, 21.3800016],
                [107.2042097, 21.3797355],
                [107.2037402, 21.378963],
                [107.2032625, 21.3787627],
                [107.2029412, 21.3788146],
                [107.2020067, 21.3786839],
                [107.2010665, 21.3781719],
                [107.2005377, 21.3779563],
                [107.1998746, 21.3778379],
                [107.1995161, 21.3776678],
                [107.1993119, 21.3776228],
                [107.1975376, 21.3780195],
                [107.1966907, 21.3780781],
                [107.1950243, 21.3788945],
                [107.1941284, 21.379081],
                [107.1934481, 21.378931],
                [107.1932425, 21.3787906],
                [107.1931712, 21.3785532],
                [107.1931591, 21.377743],
                [107.1930859, 21.3773785],
                [107.1923058, 21.3762446],
                [107.191989, 21.3754542],
                [107.191853, 21.3754242],
                [107.1908392, 21.3756678],
                [107.1898398, 21.3757286],
                [107.1870935, 21.3757484],
                [107.1862465, 21.3758071],
                [107.186059, 21.3757301],
                [107.185819, 21.3755585],
                [107.1850786, 21.3747895],
                [107.1847834, 21.3743245],
                [107.1844053, 21.3739799],
                [107.1835245, 21.3728949],
                [107.1834352, 21.3725942],
                [107.1833913, 21.3719114],
                [107.1833371, 21.3716897],
                [107.183077, 21.3712958],
                [107.1826845, 21.3711261],
                [107.1821929, 21.3711326],
                [107.1815133, 21.371046],
                [107.1799282, 21.3704787],
                [107.1792951, 21.3700817],
                [107.1773144, 21.3691381],
                [107.1767825, 21.368716],
                [107.1765929, 21.368496],
                [107.1763668, 21.3681174],
                [107.1755215, 21.3671273],
                [107.1745235, 21.3661312],
                [107.1722588, 21.3643172],
                [107.1713348, 21.3637413],
                [107.1710617, 21.3636176],
                [107.1702797, 21.363469],
                [107.1698891, 21.3634262],
                [107.169398, 21.3634644],
                [107.1688035, 21.3633847],
                [107.168157, 21.3632341],
                [107.1679025, 21.3632215],
                [107.1676661, 21.3632881],
                [107.1673297, 21.3634673],
                [107.1669613, 21.3637739],
                [107.1662877, 21.3640846],
                [107.1655985, 21.3644747],
                [107.1644244, 21.365348],
                [107.1635331, 21.3658362],
                [107.1620944, 21.3660056],
                [107.1597646, 21.3666712],
                [107.15896, 21.3673013],
                [107.1584219, 21.3675942],
                [107.158106, 21.3680274],
                [107.1577373, 21.3683181],
                [107.157298, 21.3684191],
                [107.1569083, 21.36844],
                [107.1560925, 21.3682995],
                [107.1554969, 21.3681482],
                [107.1546784, 21.367825],
                [107.153946, 21.3675961],
                [107.1525476, 21.3670419],
                [107.1521025, 21.3667458],
                [107.1507031, 21.3661122],
                [107.1501578, 21.3659285],
                [107.149479, 21.3658815],
                [107.1491212, 21.365759],
                [107.1479252, 21.3651388],
                [107.147169, 21.3644333],
                [107.1469821, 21.3644039],
                [107.1468469, 21.3644374],
                [107.1466121, 21.3646152],
                [107.1460069, 21.3649566],
                [107.1453325, 21.3652194],
                [107.1443032, 21.3655504],
                [107.1431367, 21.3657799],
                [107.1426302, 21.3659293],
                [107.1423096, 21.3660287],
                [107.1418721, 21.3662568],
                [107.1418934, 21.3665585],
                [107.142796, 21.367993],
                [107.1433553, 21.3691459],
                [107.1432575, 21.3694172],
                [107.142325, 21.3694133],
                [107.1417323, 21.3694685],
                [107.1410741, 21.3696755],
                [107.1407038, 21.3698551],
                [107.1400782, 21.3699742],
                [107.1393885, 21.3703485],
                [107.1390844, 21.3704318],
                [107.1381031, 21.3705714],
                [107.1375625, 21.3707054],
                [107.135971, 21.3708528],
                [107.1344825, 21.3711022],
                [107.1343127, 21.3710726],
                [107.1338342, 21.3708243],
                [107.1334601, 21.3707497],
                [107.1323571, 21.3706842],
                [107.1306017, 21.370055],
                [107.1290724, 21.3698201],
                [107.1280325, 21.369428],
                [107.1268771, 21.3692521],
                [107.1259484, 21.369518],
                [107.1251602, 21.370116],
                [107.1244689, 21.370379],
                [107.1241135, 21.3704311],
                [107.123351, 21.3704567],
                [107.1230644, 21.3705715],
                [107.1223433, 21.3711209],
                [107.1215865, 21.3715516],
                [107.1210791, 21.3716375],
                [107.1204885, 21.3718356],
                [107.1178688, 21.3735849],
                [107.1167043, 21.373965],
                [107.1159081, 21.3740069],
                [107.1143536, 21.374384],
                [107.1131553, 21.3747645],
                [107.1127007, 21.3749927],
                [107.1118633, 21.3757184],
                [107.1108341, 21.376065],
                [107.1105121, 21.376085],
                [107.1093582, 21.37602],
                [107.107863, 21.3757925],
                [107.1064149, 21.375318],
                [107.1060067, 21.3752278],
                [107.1055828, 21.3752331],
                [107.1046069, 21.3757538],
                [107.1036127, 21.3761795],
                [107.1029396, 21.3765375],
                [107.1020119, 21.3768829],
                [107.10008, 21.3757709],
                [107.0997697, 21.3754092],
                [107.0992213, 21.3750029],
                [107.0972754, 21.3740896],
                [107.097086, 21.3738855],
                [107.0969464, 21.3736011],
                [107.0968314, 21.3726888],
                [107.0965884, 21.3722787],
                [107.0965691, 21.37212],
                [107.0966437, 21.3702122],
                [107.0965908, 21.3700698],
                [107.0964703, 21.3699443],
                [107.0962486, 21.3698516],
                [107.0959218, 21.3695379],
                [107.0956033, 21.3685963],
                [107.0953593, 21.3681227],
                [107.0952678, 21.3676471],
                [107.0952426, 21.3670754],
                [107.0949759, 21.3650208],
                [107.0949298, 21.3641633],
                [107.0945839, 21.3624831],
                [107.0945427, 21.3619752],
                [107.0945731, 21.3617205],
                [107.0951667, 21.3605451],
                [107.0951787, 21.3601954],
                [107.0950539, 21.3597679],
                [107.094672, 21.3591529],
                [107.0943268, 21.3587122],
                [107.0929025, 21.3575064],
                [107.0916566, 21.356926],
                [107.0898694, 21.3564239],
                [107.0884889, 21.3559007],
                [107.0870595, 21.3555371],
                [107.0865834, 21.3554317],
                [107.0857865, 21.3554257],
                [107.085345, 21.3553756],
                [107.084901, 21.3551428],
                [107.0846952, 21.3549864],
                [107.0839717, 21.354169],
                [107.0836458, 21.353903],
                [107.0831506, 21.3536548],
                [107.0829448, 21.3534826],
                [107.0828237, 21.3533093],
                [107.0828513, 21.352864],
                [107.0831123, 21.3521456],
                [107.0832084, 21.3517473],
                [107.0832646, 21.3509281],
                [107.0830921, 21.3507078],
                [107.0828872, 21.3506149],
                [107.0821555, 21.3504175],
                [107.0815926, 21.3501702],
                [107.0812846, 21.3499674],
                [107.0807513, 21.3494178],
                [107.0803747, 21.3491682],
                [107.0800485, 21.3488703],
                [107.0795751, 21.3477559],
                [107.0792102, 21.3471247],
                [107.0789302, 21.3465084],
                [107.0788095, 21.3463669],
                [107.0786556, 21.3462735],
                [107.078316, 21.34623],
                [107.0774676, 21.3461769],
                [107.0769406, 21.3460722],
                [107.0761603, 21.3460342],
                [107.0758224, 21.3461178],
                [107.0753392, 21.3467118],
                [107.0748534, 21.3471309],
                [107.0741126, 21.3474896],
                [107.0736396, 21.3476068],
                [107.0720957, 21.3475225],
                [107.0717384, 21.3474315],
                [107.0711244, 21.3471689],
                [107.0705807, 21.3470803],
                [107.0702228, 21.3469418],
                [107.0698331, 21.3469465],
                [107.0692587, 21.3470966],
                [107.0684291, 21.3471704],
                [107.0649335, 21.3481429],
                [107.0638668, 21.3482355],
                [107.0632756, 21.3483858],
                [107.0630199, 21.3482777],
                [107.0624346, 21.3476492],
                [107.0620921, 21.3473992],
                [107.0610656, 21.3467285],
                [107.0596484, 21.3460069],
                [107.0592695, 21.3455825],
                [107.0591105, 21.3451236],
                [107.0590815, 21.3442658],
                [107.0590287, 21.3441234],
                [107.0582893, 21.3433697],
                [107.0581336, 21.3431491],
                [107.0579583, 21.342738],
                [107.0578297, 21.3420246],
                [107.0577944, 21.3407119],
                [107.0577068, 21.3405064],
                [107.0576374, 21.3403961],
                [107.0568568, 21.3398651],
                [107.0566981, 21.3396303],
                [107.0565241, 21.3391618],
                [107.0559124, 21.3383293],
                [107.0554618, 21.3378008],
                [107.0544082, 21.3371907],
                [107.0522991, 21.335822],
                [107.0518826, 21.3354711],
                [107.051686, 21.3349987],
                [107.051635, 21.3336049],
                [107.0517547, 21.3318224],
                [107.0519638, 21.3308622],
                [107.0520442, 21.3302367],
                [107.0518584, 21.3296769],
                [107.0514312, 21.3293698],
                [107.0504256, 21.3290699],
                [107.0475735, 21.3288131],
                [107.0465046, 21.3287638],
                [107.0459891, 21.3284993],
                [107.0447972, 21.3275979],
                [107.0441926, 21.3273346],
                [107.0437689, 21.3272773],
                [107.0434355, 21.3273022],
                [107.042883, 21.3275795],
                [107.0421955, 21.3277544],
                [107.0413483, 21.3276398],
                [107.0409449, 21.3274365],
                [107.0392163, 21.3263541],
                [107.0362933, 21.3257858],
                [107.0337474, 21.3251504],
                [107.0324104, 21.3252506],
                [107.0319591, 21.3253093],
                [107.0320181, 21.3252527],
                [107.0321124, 21.3251276],
                [107.0321751, 21.3249912],
                [107.0322069, 21.3248458],
                [107.0322341, 21.3245101],
                [107.0322615, 21.3243436],
                [107.0323385, 21.3240331],
                [107.0324405, 21.323748],
                [107.032502, 21.323609],
                [107.0328855, 21.3228644],
                [107.0330453, 21.3226986],
                [107.0331154, 21.3226082],
                [107.0332342, 21.3224147],
                [107.0333228, 21.3222071],
                [107.0333791, 21.3219899],
                [107.0333835, 21.3218337],
                [107.0333723, 21.3217561],
                [107.0333238, 21.321607],
                [107.0332429, 21.3214713],
                [107.0330036, 21.3211704],
                [107.0329412, 21.3210759],
                [107.0329044, 21.3209705],
                [107.0328957, 21.3208511],
                [107.0329227, 21.3207249],
                [107.0329499, 21.3206657],
                [107.0334113, 21.3202609],
                [107.0334942, 21.3201572],
                [107.03355, 21.3200389],
                [107.0335668, 21.319976],
                [107.0335769, 21.3198422],
                [107.0335692, 21.3197753],
                [107.0335291, 21.3196465],
                [107.0334971, 21.3195862],
                [107.0332289, 21.3191765],
                [107.0331312, 21.3189869],
                [107.0330932, 21.3188879],
                [107.0330394, 21.3186832],
                [107.0330165, 21.318473],
                [107.0330168, 21.3183674],
                [107.0330448, 21.3179923],
                [107.0330411, 21.3178572],
                [107.0330123, 21.3176176],
                [107.0329553, 21.3173824],
                [107.0328561, 21.31712],
                [107.0327229, 21.316871],
                [107.0326441, 21.3167527],
                [107.0328433, 21.3162148],
                [107.0329499, 21.3159481],
                [107.0332396, 21.3152835],
                [107.0326924, 21.3141491],
                [107.0322418, 21.3137243],
                [107.0320138, 21.3128497],
                [107.0313191, 21.3117652],
                [107.0312365, 21.3116196],
                [107.0310885, 21.3113204],
                [107.0310122, 21.3111399],
                [107.0308846, 21.3107707],
                [107.0307915, 21.3105671],
                [107.0306889, 21.3103675],
                [107.0305771, 21.3101722],
                [107.0304563, 21.3099817],
                [107.0303267, 21.3097962],
                [107.0300885, 21.3094953],
                [107.0298272, 21.3092115],
                [107.0296884, 21.3090765],
                [107.0277625, 21.3082569],
                [107.0260835, 21.3078071],
                [107.0256704, 21.3063296],
                [107.0256118, 21.3060083],
                [107.0255899, 21.305683],
                [107.025608, 21.3052869],
                [107.0256597, 21.3048934],
                [107.0257258, 21.3045791],
                [107.025767, 21.3044236],
                [107.0256329, 21.3040538],
                [107.0253685, 21.3040683],
                [107.0248389, 21.3040738],
                [107.0243807, 21.304053],
                [107.0241523, 21.3040338],
                [107.0238599, 21.3036079],
                [107.0237036, 21.3034013],
                [107.0234791, 21.3031258],
                [107.0234118, 21.303057],
                [107.0233393, 21.302993],
                [107.0231836, 21.3028832],
                [107.0230165, 21.3027973],
                [107.022838, 21.3027343],
                [107.0224518, 21.3023645],
                [107.0217973, 21.3018997],
                [107.021495, 21.3016097],
                [107.021215, 21.301301],
                [107.0209625, 21.3009801],
                [107.0207374, 21.3006494],
                [107.0205367, 21.3003053],
                [107.0204025, 21.2999855],
                [107.0202148, 21.2994957],
                [107.0201744, 21.2994698],
                [107.020086, 21.2994307],
                [107.0200324, 21.2994164],
                [107.0199217, 21.2994064],
                [107.0198661, 21.2994107],
                [107.0187288, 21.2996056],
                [107.0186619, 21.2996246],
                [107.0185352, 21.2996798],
                [107.01823, 21.2998905],
                [107.0181345, 21.2999453],
                [107.0179349, 21.3000404],
                [107.0168889, 21.2992607],
                [107.0161915, 21.2990408],
                [107.0152849, 21.2983561],
                [107.0150634, 21.2982209],
                [107.0149454, 21.2981646],
                [107.0147072, 21.2980785],
                [107.0144677, 21.298025],
                [107.0142227, 21.2980012],
                [107.0130721, 21.2976564],
                [107.0126027, 21.2970916],
                [107.0119938, 21.296152],
                [107.0116854, 21.2958671],
                [107.0116735, 21.2957104],
                [107.0116301, 21.2955586],
                [107.0115566, 21.2954172],
                [107.0115045, 21.2953459],
                [107.0114446, 21.29528],
                [107.0113777, 21.2952202],
                [107.0113045, 21.2951673],
                [107.0111978, 21.2951063],
                [107.0109726, 21.2950048],
                [107.0107348, 21.2949324],
                [107.0106125, 21.2949074],
                [107.0103695, 21.2948809],
                [107.0102472, 21.2948789],
                [107.0100033, 21.2948972],
                [107.0098829, 21.2949174],
                [107.009148, 21.2950874],
                [107.0082307, 21.2952673],
                [107.0065972, 21.2958496],
                [107.0060715, 21.2951148],
                [107.005928, 21.2949411],
                [107.0058489, 21.2948599],
                [107.0056607, 21.2946979],
                [107.0045266, 21.2938353],
                [107.0045061, 21.2936825],
                [107.004485, 21.2936079],
                [107.0044568, 21.2935354],
                [107.0043903, 21.2934136],
                [107.0043039, 21.293303],
                [107.0042011, 21.2932477],
                [107.0040867, 21.293218],
                [107.0039656, 21.2932161],
                [107.003848, 21.293243],
                [107.0020026, 21.2942777],
                [107.0014501, 21.2940377],
                [107.0011746, 21.2941521],
                [107.001099, 21.2941779],
                [107.0010205, 21.2941949],
                [107.0009404, 21.2942027],
                [107.0008077, 21.2941953],
                [107.0006794, 21.2941629],
                [107.0006186, 21.2941377],
                [107.0000392, 21.2946925],
                [106.9999037, 21.2947955],
                [106.9998286, 21.2948374],
                [106.9996744, 21.2948982],
                [106.999597, 21.2949177],
                [106.9994384, 21.2949374],
                [106.9991434, 21.2946625],
                [106.9989886, 21.2945456],
                [106.9988161, 21.2944526],
                [106.9986115, 21.2943809],
                [106.998505, 21.2943576],
                [106.9972873, 21.2943026],
                [106.9967633, 21.294134],
                [106.9965041, 21.2940427],
                [106.9958244, 21.2937775],
                [106.9954902, 21.2936329],
                [106.9949028, 21.2936579],
                [106.9946025, 21.2936502],
                [106.9943044, 21.2936154],
                [106.9939918, 21.2935486],
                [106.9936695, 21.2934447],
                [106.9935134, 21.2933805],
                [106.9926417, 21.2929531],
                [106.9924973, 21.2929122],
                [106.9923466, 21.2929044],
                [106.9921981, 21.2929302],
                [106.9921273, 21.2929553],
                [106.991998, 21.2930281],
                [106.9917673, 21.293283],
                [106.9917055, 21.2933711],
                [106.9915658, 21.2935363],
                [106.9913857, 21.2937019],
                [106.9912765, 21.2937837],
                [106.9911611, 21.2938578],
                [106.9900292, 21.2936229],
                [106.9879049, 21.293278],
                [106.9866013, 21.2927032],
                [106.9856518, 21.2926882],
                [106.9847439, 21.2926458],
                [106.9846578, 21.2926308],
                [106.984496, 21.29258],
                [106.9843506, 21.2925048],
                [106.9842839, 21.2924583],
                [106.9835329, 21.2924308],
                [106.983439, 21.2919635],
                [106.9818753, 21.2910938],
                [106.9817358, 21.2905989],
                [106.981658, 21.2903965],
                [106.9815802, 21.2902341],
                [106.9811404, 21.2896643],
                [106.9809852, 21.2895056],
                [106.980899, 21.2894343],
                [106.9807344, 21.2893228],
                [106.9806468, 21.2892744],
                [106.9802338, 21.2890895],
                [106.9797724, 21.2886146],
                [106.9768006, 21.2880648],
                [106.9762965, 21.2872562],
                [106.976155, 21.2870837],
                [106.9760734, 21.2870062],
                [106.9758912, 21.2868712],
                [106.975688, 21.2867659],
                [106.9755802, 21.2867252],
                [106.9754999, 21.2866765],
                [106.9754246, 21.2866214],
                [106.9752931, 21.2864957],
                [106.9751886, 21.2863528],
                [106.9750625, 21.2859005],
                [106.974838, 21.2857376],
                [106.9746198, 21.2855676],
                [106.974408, 21.2853906],
                [106.9740423, 21.2850536],
                [106.9736999, 21.2846958],
                [106.9723561, 21.2839885],
                [106.9725949, 21.2836686],
                [106.9728503, 21.2834444],
                [106.9730989, 21.2832136],
                [106.9733405, 21.2829763],
                [106.9737652, 21.2825248],
                [106.9740679, 21.2821669],
                [106.9742504, 21.2819068],
                [106.9743329, 21.2817717],
                [106.9744902, 21.2814703],
                [106.9746182, 21.2811571],
                [106.9746709, 21.2809969],
                [106.974805, 21.2803421],
                [106.9748373, 21.2802537],
                [106.9749238, 21.2800706],
                [106.975041, 21.2798872],
                [106.975023, 21.2797493],
                [106.9750035, 21.2796823],
                [106.9749711, 21.2796046],
                [106.9749294, 21.2795309],
                [106.9748789, 21.2794621],
                [106.9749767, 21.2788996],
                [106.9749769, 21.2775217],
                [106.9749244, 21.2770296],
                [106.9747669, 21.2765866],
                [106.9744518, 21.2758976],
                [106.9743992, 21.2755532],
                [106.9743993, 21.275061],
                [106.9754243, 21.2733142],
                [106.9756347, 21.2722806],
                [106.976318, 21.2704599],
                [106.9774217, 21.2693282],
                [106.9795237, 21.2679014],
                [106.9800493, 21.2677046],
                [106.9805222, 21.2676061],
                [106.981468, 21.2677047],
                [106.9817832, 21.2677048],
                [106.9819934, 21.2676556],
                [106.9822037, 21.2674588],
                [106.9837276, 21.2658348],
                [106.9837802, 21.2651951],
                [106.9837803, 21.2648014],
                [106.9837278, 21.2642601],
                [106.9837279, 21.2638664],
                [106.983833, 21.2634726],
                [106.9845424, 21.2624147],
                [106.9848578, 21.2616765],
                [106.984963, 21.2609384],
                [106.9852783, 21.2599049],
                [106.9854098, 21.2586501],
                [106.9853049, 21.2574689],
                [106.9851473, 21.2570753],
                [106.9848846, 21.2567306],
                [106.9845168, 21.2561401],
                [106.9844643, 21.2557464],
                [106.9844119, 21.2552051],
                [106.9841493, 21.254713],
                [106.9833086, 21.2538762],
                [106.9823106, 21.2522521],
                [106.9818642, 21.2512432],
                [106.9815491, 21.2496684],
                [106.9814967, 21.2482905],
                [106.9814968, 21.2471094],
                [106.9814446, 21.2453377],
                [106.9812607, 21.2440827],
                [106.9808405, 21.2434922],
                [106.9794223, 21.2417203],
                [106.9782666, 21.2404407],
                [106.9777414, 21.2398009],
                [106.9773213, 21.2394071],
                [106.9772162, 21.2388657],
                [106.9772163, 21.2382259],
                [106.9777156, 21.2366266],
                [106.9782938, 21.2346582],
                [106.9782414, 21.2337232],
                [106.9780838, 21.2333294],
                [106.9777688, 21.2326896],
                [106.9766658, 21.2310655],
                [106.9764558, 21.2306717],
                [106.9764558, 21.2302781],
                [106.9765085, 21.2298844],
                [106.9766661, 21.2295399],
                [106.9777695, 21.2282113],
                [106.9785575, 21.2271779],
                [106.9785576, 21.2269811],
                [106.978295, 21.2267842],
                [106.9768504, 21.2264149],
                [106.9762201, 21.2262672],
                [106.9756423, 21.2260211],
                [106.9752745, 21.2258242],
                [106.974014, 21.224889],
                [106.9731736, 21.2245444],
                [106.972123, 21.2245933],
                [106.9703894, 21.2249376],
                [106.9697589, 21.2251835],
                [106.9693912, 21.2251342],
                [106.9691286, 21.2249374],
                [106.9690761, 21.2246912],
                [106.9689712, 21.2242484],
                [106.9688137, 21.2237561],
                [106.9687613, 21.2231656],
                [106.9677376, 21.2204833],
                [106.967475, 21.2199912],
                [106.9671074, 21.2195481],
                [106.9666873, 21.2191544],
                [106.9663722, 21.2187606],
                [106.9662147, 21.2183177],
                [106.9662148, 21.2178256],
                [106.9662149, 21.2173827],
                [106.966215, 21.2168904],
                [106.9661626, 21.216546],
                [106.9659525, 21.2163491],
                [106.9656374, 21.2160046],
                [106.9652172, 21.2156107],
                [106.9650074, 21.215217],
                [106.9640885, 21.2133221],
                [106.9627759, 21.2110581],
                [106.9623558, 21.2104183],
                [106.9619882, 21.2101721],
                [106.9614631, 21.2099259],
                [106.9608853, 21.2097289],
                [106.9600975, 21.2094335],
                [106.9589945, 21.2089903],
                [106.9582069, 21.2083996],
                [106.9568943, 21.2067752],
                [106.956448, 21.2060123],
                [106.9563431, 21.2056186],
                [106.9563432, 21.2052248],
                [106.9563959, 21.2046343],
                [106.9563961, 21.204093],
                [106.9563437, 21.2035024],
                [106.9561865, 21.2027641],
                [106.9559765, 21.2020259],
                [106.9557666, 21.2012877],
                [106.9557668, 21.2007956],
                [106.9557669, 21.2002541],
                [106.9562138, 21.1988517],
                [106.9566867, 21.1978677],
                [106.9566344, 21.1974247],
                [106.9564769, 21.1969818],
                [106.9560044, 21.1963911],
                [106.955742, 21.1959481],
                [106.9555845, 21.1956036],
                [106.9553748, 21.1943732],
                [106.955375, 21.1937826],
                [106.9554802, 21.1932414],
                [106.955822, 21.1915928],
                [106.9557696, 21.1911499],
                [106.9556122, 21.1909037],
                [106.9552447, 21.19051],
                [106.9547196, 21.1901161],
                [106.9518839, 21.1891311],
                [106.9512012, 21.1889833],
                [106.9503609, 21.1889338],
                [106.9494681, 21.1889336],
                [106.9485754, 21.188884],
                [106.9479452, 21.1886377],
                [106.9477353, 21.1883916],
                [106.9477354, 21.1880964],
                [106.9477354, 21.1877519],
                [106.9476833, 21.1869645],
                [106.9474735, 21.185931],
                [106.9465025, 21.1843804],
                [106.9458204, 21.1829038],
                [106.9457156, 21.1823132],
                [106.9457682, 21.1818211],
                [106.9457685, 21.1810829],
                [106.9457687, 21.1804925],
                [106.9457689, 21.179951],
                [106.9456116, 21.1791637],
                [106.9446409, 21.1770718],
                [106.9431191, 21.1742169],
                [106.9422794, 21.1730846],
                [106.9416494, 21.1725431],
                [106.9411242, 21.1723953],
                [106.9408093, 21.1723951],
                [106.9402316, 21.1724934],
                [106.9392338, 21.1727392],
                [106.9387612, 21.172739],
                [106.9383412, 21.1726403],
                [106.9379738, 21.172099],
                [106.9376063, 21.1716066],
                [106.9373965, 21.1711637],
                [106.9372393, 21.1705238],
                [106.936977, 21.1697856],
                [106.9365046, 21.1692441],
                [106.9360353, 21.1689627],
                [106.9358836, 21.1689497],
                [106.9354577, 21.1689133],
                [106.9343779, 21.1694646],
                [106.9323296, 21.1702513],
                [106.9303862, 21.1712348],
                [106.928285, 21.1728579],
                [106.9267616, 21.1737431],
                [106.926499, 21.1739398],
                [106.9253957, 21.1749728],
                [106.9244499, 21.1761536],
                [106.9240296, 21.1764486],
                [106.9235567, 21.1770389],
                [106.9232672, 21.1782937],
                [106.9231095, 21.1787365],
                [106.9225314, 21.179376],
                [106.9188537, 21.182524],
                [106.9175402, 21.183852],
                [106.91733, 21.1840488],
                [106.9163845, 21.1843436],
                [106.9162496, 21.184332],
                [106.9160956, 21.1843188],
                [106.9158856, 21.1842203],
                [106.9155181, 21.1840726],
                [106.9151507, 21.1838262],
                [106.914573, 21.1836292],
                [106.9138903, 21.1836288],
                [106.9132602, 21.1836285],
                [106.9126299, 21.1836773],
                [106.9116846, 21.1838245],
                [106.9111594, 21.1838242],
                [106.9085863, 21.1837244],
                [106.9066958, 21.1834281],
                [106.9049104, 21.183181],
                [106.9041228, 21.1828853],
                [106.9025216, 21.1821215],
                [106.9013141, 21.1816288],
                [106.8978488, 21.1805932],
                [106.8973237, 21.1804945],
                [106.8962734, 21.1804446],
                [106.8956432, 21.1804442],
                [106.8949082, 21.1802469],
                [106.8941733, 21.179902],
                [106.8934382, 21.1795078],
                [106.8927558, 21.1792613],
                [106.8926508, 21.1792121],
                [106.8904179, 21.1806132],
                [106.8876332, 21.1824323],
                [106.8858471, 21.1833168],
                [106.8840088, 21.1836109],
                [106.8834836, 21.1836106],
                [106.882906, 21.1835609],
                [106.8821184, 21.1833635],
                [106.8810684, 21.1829691],
                [106.8803861, 21.1824765],
                [106.8795728, 21.1815162],
                [106.878576, 21.180236],
                [106.8773694, 21.1788081],
                [106.8750614, 21.1757059],
                [106.8744846, 21.1747212],
                [106.8740124, 21.1742779],
                [106.8735925, 21.1739824],
                [106.8732251, 21.1738836],
                [106.8708355, 21.1741033],
                [106.8699951, 21.1743488],
                [106.8695223, 21.1745945],
                [106.8681037, 21.1754792],
                [106.8676309, 21.1756265],
                [106.8673158, 21.1756262],
                [106.8669484, 21.1755767],
                [106.8658457, 21.175379],
                [106.8651105, 21.1753783],
                [106.8634824, 21.1755739],
                [106.8626945, 21.1757208],
                [106.8622218, 21.175819],
                [106.8615388, 21.1762121],
                [106.8612761, 21.1764088],
                [106.8593832, 21.178917],
                [106.8590678, 21.1792612],
                [106.8588572, 21.1799009],
                [106.8587246, 21.1812048],
                [106.8584062, 21.1846494],
                [106.858353, 21.1853384],
                [106.8583, 21.1858797],
                [106.8582472, 21.1862241],
                [106.8580892, 21.1867161],
                [106.8577211, 21.1871096],
                [106.8574582, 21.187503],
                [106.8570903, 21.187798],
                [106.8566699, 21.1880437],
                [106.8557767, 21.1884859],
                [106.8554089, 21.1886824],
                [106.8540432, 21.188878],
                [106.8517587, 21.188753],
                [106.8506561, 21.1886045],
                [106.8497635, 21.1884067],
                [106.8492385, 21.1882095],
                [106.8488186, 21.1879138],
                [106.848399, 21.1874705],
                [106.8478217, 21.1870763],
                [106.8472139, 21.1868427],
                [106.8466668, 21.1866324],
                [106.8451442, 21.1862373],
                [106.8437789, 21.1860883],
                [106.8426762, 21.1860381],
                [106.8399186, 21.1863554],
                [106.8383428, 21.1867476],
                [106.8378176, 21.1867471],
                [106.8372924, 21.1867467],
                [106.8366622, 21.186746],
                [106.8358745, 21.1867453],
                [106.8344565, 21.1867439],
                [106.8319879, 21.1869383],
                [106.8304646, 21.1872812],
                [106.8280481, 21.1877709],
                [106.8271024, 21.1882129],
                [106.8266295, 21.1884093],
                [106.8264719, 21.1883599],
                [106.8265249, 21.1881138],
                [106.826683, 21.1876711],
                [106.8268937, 21.1870808],
                [106.8269995, 21.1863919],
                [106.8266076, 21.1847429],
                [106.8263981, 21.1842013],
                [106.8261884, 21.1838074],
                [106.8257694, 21.1829211],
                [106.8256122, 21.1825764],
                [106.8255601, 21.1821827],
                [106.8255606, 21.181789],
                [106.8256136, 21.1814446],
                [106.8258243, 21.1809034],
                [106.8264031, 21.1799199],
                [106.8268242, 21.1790345],
                [106.8270622, 21.1775829],
                [106.8270632, 21.1767463],
                [106.8270115, 21.1761065],
                [106.8262257, 21.1744816],
                [106.8260686, 21.1740878],
                [106.8260691, 21.1736941],
                [106.8260701, 21.172759],
                [106.8261765, 21.1715781],
                [106.8262834, 21.1700033],
                [106.8261297, 21.1665095],
                [106.8262071, 21.1655641],
                [106.826199, 21.164905],
                [106.8262388, 21.1642131],
                [106.8263429, 21.1637079],
                [106.8265032, 21.1632795],
                [106.826732, 21.1628246],
                [106.827374, 21.1617643],
                [106.828103, 21.1604769],
                [106.8289068, 21.1591174],
                [106.8295549, 21.157986],
                [106.8300012, 21.1572702],
                [106.8308636, 21.1561945],
                [106.8325396, 21.1545477],
                [106.8334912, 21.1534387],
                [106.8336662, 21.1530879],
                [106.8336775, 21.1528809],
                [106.833694, 21.1526938],
                [106.8329805, 21.1525712],
                [106.8326297, 21.1525359],
                [106.8324535, 21.1525289],
                [106.8321007, 21.1525362],
                [106.8317449, 21.1525726],
                [106.8315686, 21.1526017],
                [106.831221, 21.1526813],
                [106.8307596, 21.1531966],
                [106.8305933, 21.1533367],
                [106.830408, 21.1534542],
                [106.8302071, 21.1535468],
                [106.8300122, 21.1536085],
                [106.8298106, 21.1536467],
                [106.8295985, 21.1536644],
                [106.8294898, 21.1536803],
                [106.8293828, 21.1537044],
                [106.8291771, 21.1537769],
                [106.8289823, 21.1538829],
                [106.8284208, 21.1543173],
                [106.8282137, 21.1544239],
                [106.8279947, 21.1545073],
                [106.8277516, 21.1545692],
                [106.8276197, 21.1545905],
                [106.8273529, 21.1546084],
                [106.8261275, 21.1545524],
                [106.8258358, 21.1546379],
                [106.8255535, 21.1547475],
                [106.8252652, 21.15489],
                [106.8249147, 21.1551037],
                [106.8248326, 21.1551435],
                [106.8246592, 21.1552032],
                [106.8244731, 21.1552357],
                [106.8242794, 21.1552378],
                [106.8241922, 21.1551408],
                [106.82414, 21.1551002],
                [106.8240219, 21.1550377],
                [106.8239359, 21.1550112],
                [106.8237576, 21.1549828],
                [106.823667, 21.154981],
                [106.8234944, 21.1550012],
                [106.8234138, 21.1550216],
                [106.8232602, 21.1550827],
                [106.8230382, 21.1552578],
                [106.8227977, 21.1554101],
                [106.8225414, 21.155538],
                [106.8223868, 21.1556003],
                [106.8222286, 21.1556537],
                [106.8220671, 21.155698],
                [106.821903, 21.1557331],
                [106.8210125, 21.1558682],
                [106.8206329, 21.1560956],
                [106.8202347, 21.1562934],
                [106.8198986, 21.1564316],
                [106.8195534, 21.1565486],
                [106.8193167, 21.1565129],
                [106.8190819, 21.1564681],
                [106.8188493, 21.156414],
                [106.8186194, 21.1563509],
                [106.8183925, 21.1562788],
                [106.818169, 21.1561979],
                [106.8179494, 21.1561083],
                [106.8175405, 21.1559128],
                [106.8173428, 21.1558036],
                [106.8169622, 21.1555635],
                [106.81678, 21.1554329],
                [106.8148863, 21.1546274],
                [106.8130839, 21.1540771],
                [106.8127372, 21.1540127],
                [106.8125619, 21.1539909],
                [106.8122092, 21.153968],
                [106.8118462, 21.1539736],
                [106.8116604, 21.153988],
                [106.8112918, 21.1540398],
                [106.8111098, 21.1540771],
                [106.8097258, 21.1544874],
                [106.8079555, 21.1548876],
                [106.8072983, 21.1549473],
                [106.8066402, 21.1549974],
                [106.8059814, 21.1550377],
                [106.8053952, 21.1550654],
                [106.8042219, 21.1550977],
                [106.8039042, 21.1548813],
                [106.8037541, 21.1547627],
                [106.8034818, 21.1545146],
                [106.803243, 21.1542535],
                [106.8030304, 21.1539734],
                [106.8029344, 21.153827],
                [106.8026213, 21.1534484],
                [106.8024558, 21.1532658],
                [106.802108, 21.1529146],
                [106.8019259, 21.1527463],
                [106.8015447, 21.1524242],
                [106.8013466, 21.1522711],
                [106.8009362, 21.1519818],
                [106.8007243, 21.1518458],
                [106.7985785, 21.1508752],
                [106.7982973, 21.1507661],
                [106.7977264, 21.1505686],
                [106.7974369, 21.1504803],
                [106.7968511, 21.1503249],
                [106.7965223, 21.1502509],
                [106.7961913, 21.1501859],
                [106.7958584, 21.1501298],
                [106.795524, 21.1500827],
                [106.7951882, 21.1500447],
                [106.7917389, 21.1499747],
                [106.7914131, 21.1499737],
                [106.790762, 21.1499973],
                [106.7901134, 21.1500547],
                [106.7897743, 21.1500984],
                [106.7894366, 21.1501514],
                [106.7891007, 21.1502135],
                [106.788767, 21.1502849],
                [106.7865139, 21.1509453],
                [106.7857677, 21.1510022],
                [106.7850387, 21.1510803],
                [106.78463, 21.1511327],
                [106.7838156, 21.1512554],
                [106.7818844, 21.1513605],
                [106.7808711, 21.1515464],
                [106.7798526, 21.1517059],
                [106.7787931, 21.1518432],
                [106.7782435, 21.1519031],
                [106.7771416, 21.1519998],
                [106.7760372, 21.1520659],
                [106.7751013, 21.152187],
                [106.7741579, 21.1522777],
                [106.7732083, 21.1523378],
                [106.7724738, 21.1523637],
                [106.7722154, 21.1523798],
                [106.7717011, 21.1524373],
                [106.7714459, 21.1524787],
                [106.770941, 21.1525862],
                [106.7706909, 21.1526526],
                [106.7701989, 21.1528099],
                [106.76972, 21.152999],
                [106.7692566, 21.1532191],
                [106.7683232, 21.1551678],
                [106.76818, 21.1553672],
                [106.7680129, 21.1555498],
                [106.7678243, 21.1557131],
                [106.7677079, 21.1557968],
                [106.7675859, 21.1558731],
                [106.7674587, 21.1559416],
                [106.7673269, 21.1560022],
                [106.7671911, 21.1560545],
                [106.7670518, 21.1560983],
                [106.7667782, 21.1559082],
                [106.7665691, 21.15588],
                [106.7664636, 21.155875],
                [106.7662525, 21.1558832],
                [106.7660528, 21.1559136],
                [106.7658582, 21.1559657],
                [106.7654157, 21.1562184],
                [106.7646003, 21.1564885],
                [106.7642513, 21.15641],
                [106.7640749, 21.1563789],
                [106.7636865, 21.1563304],
                [106.7632624, 21.1563079],
                [106.76305, 21.1563084],
                [106.7625753, 21.1562926],
                [106.7621008, 21.1563112],
                [106.7618644, 21.1563334],
                [106.7613216, 21.1563996],
                [106.761049, 21.1564235],
                [106.7607347, 21.1564433],
                [106.7601049, 21.1564585],
                [106.7572644, 21.1552528],
                [106.756848, 21.1554054],
                [106.7563757, 21.155612],
                [106.7560886, 21.1557105],
                [106.7559378, 21.1557514],
                [106.755631, 21.1558131],
                [106.7544776, 21.1559782],
                [106.7525625, 21.1565435],
                [106.7524008, 21.1565719],
                [106.7522364, 21.1565668],
                [106.752077, 21.1565285],
                [106.751377, 21.1559907],
                [106.7512211, 21.1559666],
                [106.7511419, 21.155966],
                [106.750992, 21.1559865],
                [106.7509225, 21.1560062],
                [106.7507912, 21.1560635],
                [106.7507306, 21.1561008],
                [106.7501968, 21.1565035],
                [106.7499895, 21.1565572],
                [106.7497797, 21.1566018],
                [106.7495679, 21.1566373],
                [106.7493546, 21.1566636],
                [106.7489889, 21.1566871],
                [106.7486224, 21.1566836],
                [106.7482093, 21.1563534],
                [106.7480405, 21.1562552],
                [106.7478606, 21.1561758],
                [106.7476593, 21.1561131],
                [106.7474512, 21.1560738],
                [106.7473456, 21.1560633],
                [106.7472169, 21.1562709],
                [106.7470955, 21.1564344],
                [106.7469487, 21.1565786],
                [106.7468753, 21.1566361],
                [106.7467167, 21.1567364],
                [106.7465147, 21.156833],
                [106.7462686, 21.156917],
                [106.7460471, 21.1569621],
                [106.7459519, 21.156972],
                [106.7457604, 21.1569738],
                [106.7455634, 21.1569497],
                [106.7453724, 21.1568985],
                [106.7451918, 21.1568212],
                [106.7450307, 21.1567233],
                [106.7448865, 21.1566048],
                [106.7448217, 21.1565385],
                [106.7442549, 21.1558264],
                [106.7441564, 21.1557544],
                [106.7440415, 21.1557081],
                [106.7439178, 21.1556906],
                [106.7438497, 21.1556936],
                [106.7437182, 21.1557258],
                [106.7436013, 21.1557906],
                [106.742869, 21.1572339],
                [106.7428048, 21.1573266],
                [106.7427645, 21.1573671],
                [106.7426705, 21.157434],
                [106.7425697, 21.1574771],
                [106.7424613, 21.1574991],
                [106.7420697, 21.1575191],
                [106.7419778, 21.1575357],
                [106.7418015, 21.1575941],
                [106.7417216, 21.1576339],
                [106.7415762, 21.1577342],
                [106.7411363, 21.1577492],
                [106.7401952, 21.1582133],
                [106.7400552, 21.1582759],
                [106.7399122, 21.1583321],
                [106.7396182, 21.1584246],
                [106.7394701, 21.1584602],
                [106.7391692, 21.1585114],
                [106.7390172, 21.1585268],
                [106.7387116, 21.1585372],
                [106.7385979, 21.1587089],
                [106.738481, 21.158937],
                [106.7383846, 21.1590567],
                [106.7382663, 21.159155],
                [106.7380199, 21.1592467],
                [106.7377621, 21.1593051],
                [106.7375493, 21.1593272],
                [106.7371419, 21.1593513],
                [106.7369402, 21.1593869],
                [106.7368364, 21.1594153],
                [106.7367348, 21.1594502],
                [106.7346454, 21.1603857],
                [106.7344439, 21.1605013],
                [106.7343511, 21.1605698],
                [106.734184, 21.1607259],
                [106.7340542, 21.1608906],
                [106.7339992, 21.1609791],
                [106.7339104, 21.1611661],
                [106.7336636, 21.1613264],
                [106.7333991, 21.16146],
                [106.7331747, 21.1615474],
                [106.7329952, 21.1615917],
                [106.7328107, 21.1616113],
                [106.732619, 21.1616052],
                [106.7324304, 21.1615725],
                [106.7323387, 21.1615463],
                [106.7313892, 21.1611311],
                [106.730563, 21.1611611],
                [106.7300235, 21.1609505],
                [106.729758, 21.1608362],
                [106.7292429, 21.160593],
                [106.7287472, 21.1603306],
                [106.7260838, 21.1597703],
                [106.7254034, 21.1594003],
                [106.7251573, 21.1592915],
                [106.7248986, 21.1592119],
                [106.7247658, 21.1591835],
                [106.7244959, 21.15915],
                [106.7242151, 21.1591484],
                [106.7239364, 21.1591803],
                [106.7237993, 21.1592086],
                [106.723481, 21.1592977],
                [106.7232949, 21.1593411],
                [106.7231065, 21.1593751],
                [106.7229197, 21.1593994],
                [106.7227318, 21.1594144],
                [106.7225432, 21.1594202],
                [106.7216098, 21.1594051],
                [106.7215301, 21.159515],
                [106.7214425, 21.1596195],
                [106.7213473, 21.1597181],
                [106.721245, 21.1598104],
                [106.7210699, 21.1599423],
                [106.7208803, 21.1600555],
                [106.7205074, 21.1605157],
                [106.7204789, 21.1605672],
                [106.7204571, 21.1606215],
                [106.720435, 21.1607354],
                [106.720435, 21.1607934],
                [106.7204548, 21.1609001],
                [106.720474, 21.1609513],
                [106.7205302, 21.161046],
                [106.7209701, 21.1616401],
                [106.721348, 21.1621053],
                [106.7208883, 21.1621041],
                [106.7203998, 21.1620922],
                [106.717785, 21.1619065],
                [106.7168248, 21.1613712],
                [106.7154904, 21.1610827],
                [106.7153109, 21.1610168],
                [106.7152168, 21.1609697],
                [106.7149285, 21.1607827],
                [106.7143089, 21.1613312],
                [106.7142496, 21.1614041],
                [106.7141986, 21.1614824],
                [106.7141565, 21.1615651],
                [106.7141238, 21.1616514],
                [106.7140936, 21.1617806],
                [106.7140858, 21.1618986],
                [106.7140642, 21.1620007],
                [106.7140108, 21.1621024],
                [106.7139226, 21.1621929],
                [106.7137499, 21.1622996],
                [106.7135621, 21.1623811],
                [106.7133634, 21.1624355],
                [106.7132486, 21.1624538],
                [106.7131325, 21.162463],
                [106.713016, 21.162463],
                [106.7126191, 21.1623717],
                [106.7125507, 21.1624964],
                [106.7124732, 21.1626163],
                [106.7123869, 21.1627309],
                [106.7122923, 21.1628397],
                [106.7120907, 21.1630289],
                [106.7119858, 21.1631097],
                [106.7117607, 21.1632522],
                [106.7110693, 21.1637507],
                [106.7103982, 21.1642727],
                [106.7100807, 21.1645418],
                [106.7099306, 21.1646847],
                [106.7096572, 21.1649768],
                [106.7095335, 21.165125],
                [106.7093038, 21.1654333],
                [106.708585, 21.1660586],
                [106.7083409, 21.166764],
                [106.7077202, 21.1674081],
                [106.7070723, 21.1681097],
                [106.7070049, 21.1681707],
                [106.706933, 21.1682271],
                [106.706857, 21.1682786],
                [106.7067772, 21.1683248],
                [106.7066941, 21.1683655],
                [106.7066082, 21.1684006],
                [106.7065197, 21.1684298],
                [106.7063073, 21.1683281],
                [106.7060906, 21.1682347],
                [106.7059094, 21.1681643],
                [106.7057258, 21.1680997],
                [106.7055989, 21.1680643],
                [106.7054753, 21.1680201],
                [106.7053556, 21.1679671],
                [106.7052598, 21.1679167],
                [106.7051676, 21.1678608],
                [106.7050794, 21.1677995],
                [106.7049717, 21.167741],
                [106.7048599, 21.1676897],
                [106.7047445, 21.1676458],
                [106.7046261, 21.1676094],
                [106.7045146, 21.1675828],
                [106.7044016, 21.1675629],
                [106.7042874, 21.1675498],
                [106.7041725, 21.1675437],
                [106.7040574, 21.1675444],
                [106.7038695, 21.1674329],
                [106.7036853, 21.1673161],
                [106.7035049, 21.1671942],
                [106.7034294, 21.1671408],
                [106.7033504, 21.167092],
                [106.7032681, 21.1670481],
                [106.703183, 21.1670091],
                [106.7030922, 21.1669742],
                [106.702999, 21.1669449],
                [106.702904, 21.1669215],
                [106.7028075, 21.1669041],
                [106.7021906, 21.1668991],
                [106.7021194, 21.1669099],
                [106.7020495, 21.1669266],
                [106.7019814, 21.1669491],
                [106.7019157, 21.1669772],
                [106.701853, 21.1670106],
                [106.7017937, 21.1670491],
                [106.7017161, 21.1670866],
                [106.7016352, 21.1671176],
                [106.7015518, 21.1671418],
                [106.7014664, 21.1671592],
                [106.7013904, 21.1671687],
                [106.7013138, 21.1671727],
                [106.701237, 21.1671712],
                [106.7011607, 21.1671642],
                [106.7007369, 21.1670692],
                [106.7003292, 21.1670441],
                [106.7002648, 21.1671406],
                [106.7002092, 21.1672417],
                [106.7001629, 21.1673468],
                [106.7001293, 21.1674443],
                [106.7001038, 21.1675439],
                [106.7000863, 21.167645],
                [106.7000771, 21.167747],
                [106.6999906, 21.1678506],
                [106.6998965, 21.1679483],
                [106.6997954, 21.1680396],
                [106.6997117, 21.1681064],
                [106.6996241, 21.1681688],
                [106.699533, 21.1682266],
                [106.6994387, 21.1682798],
                [106.6993639, 21.1683318],
                [106.6992939, 21.1683895],
                [106.6992293, 21.1684523],
                [106.6991705, 21.1685199],
                [106.6991244, 21.1685821],
                [106.6990832, 21.1686472],
                [106.6990471, 21.168715],
                [106.6990357, 21.168766],
                [106.6990174, 21.1688152],
                [106.6989924, 21.1688618],
                [106.6989613, 21.1689051],
                [106.6989271, 21.1689416],
                [106.6988885, 21.1689741],
                [106.6988461, 21.1690021],
                [106.6988003, 21.1690251],
                [106.6984436, 21.1691452],
                [106.6983748, 21.1691678],
                [106.6983092, 21.1691977],
                [106.6982478, 21.1692345],
                [106.6981915, 21.1692777],
                [106.698146, 21.1693213],
                [106.6981056, 21.169369],
                [106.6980708, 21.1694203],
                [106.6980064, 21.1695954],
                [106.697968, 21.1696648],
                [106.6979231, 21.1697308],
                [106.697872, 21.1697927],
                [106.6978151, 21.1698501],
                [106.6977529, 21.1699025],
                [106.697686, 21.1699494],
                [106.6976148, 21.1699906],
                [106.6975353, 21.1700275],
                [106.6974524, 21.1700572],
                [106.6973669, 21.1700794],
                [106.6972795, 21.1700939],
                [106.697191, 21.1701006],
                [106.6970127, 21.1700826],
                [106.6968351, 21.170059],
                [106.6966584, 21.17003],
                [106.6964829, 21.1699956],
                [106.6962621, 21.1699441],
                [106.6960438, 21.1698841],
                [106.6958284, 21.1698155],
                [106.6956633, 21.1697671],
                [106.6954953, 21.1697278],
                [106.6953252, 21.1696976],
                [106.6951536, 21.1696768],
                [106.6949809, 21.1696654],
                [106.6948343, 21.1696631],
                [106.6946881, 21.1696529],
                [106.6945427, 21.1696349],
                [106.6943988, 21.169609],
                [106.6942567, 21.1695754],
                [106.6941295, 21.1695383],
                [106.6940047, 21.1694949],
                [106.6938824, 21.1694456],
                [106.6937631, 21.1693903],
                [106.6931838, 21.1690701],
                [106.6926473, 21.1689901],
                [106.6925321, 21.1688594],
                [106.692422, 21.168725],
                [106.6923117, 21.1685793],
                [106.6922075, 21.1684298],
                [106.691848, 21.1682247],
                [106.6915918, 21.1681592],
                [106.6913384, 21.1680847],
                [106.6910898, 21.1680016],
                [106.6908447, 21.1679099],
                [106.6906035, 21.1678095],
                [106.6897505, 21.1678545],
                [106.6894261, 21.167835],
                [106.6891023, 21.1678067],
                [106.6887796, 21.1677695],
                [106.6884967, 21.1677296],
                [106.688215, 21.1676829],
                [106.6879347, 21.1676294],
                [106.6878395, 21.1676636],
                [106.6877471, 21.1677038],
                [106.687658, 21.1677501],
                [106.6875726, 21.167802],
                [106.6875315, 21.16783],
                [106.6874869, 21.1678529],
                [106.6874395, 21.1678704],
                [106.6873902, 21.1678821],
                [106.6873412, 21.1678877],
                [106.6872917, 21.1678877],
                [106.6872427, 21.1678821],
                [106.6870933, 21.1678449],
                [106.6869423, 21.1678145],
                [106.6867779, 21.1677893],
                [106.6866124, 21.167772],
                [106.6865694, 21.1681472],
                [106.6865973, 21.1682425],
                [106.6866179, 21.1683393],
                [106.6866311, 21.1684373],
                [106.6866352, 21.1684901],
                [106.6866316, 21.168543],
                [106.6866204, 21.1685949],
                [106.6866032, 21.1686417],
                [106.6865799, 21.1686861],
                [106.6865507, 21.1687275],
                [106.6865066, 21.1687522],
                [106.6864589, 21.1687703],
                [106.6864089, 21.1687813],
                [106.6863576, 21.168785],
                [106.6863021, 21.1687806],
                [106.6862481, 21.1687676],
                [106.6861973, 21.1687463],
                [106.686151, 21.1687175],
                [106.6858801, 21.1684548],
                [106.6857453, 21.168387],
                [106.6856077, 21.1683243],
                [106.6854675, 21.1682669],
                [106.6853249, 21.1682147],
                [106.685167, 21.168164],
                [106.6850069, 21.1681198],
                [106.6848448, 21.1680822],
                [106.6840321, 21.1680496],
                [106.6838687, 21.1679539],
                [106.6837111, 21.1678501],
                [106.6835598, 21.1677385],
                [106.6834152, 21.1676194],
                [106.683285, 21.1675003],
                [106.6831615, 21.1673751],
                [106.683045, 21.1672442],
                [106.682632, 21.1665539],
                [106.682552, 21.1665289],
                [106.6824698, 21.1665111],
                [106.6823862, 21.1665007],
                [106.6823019, 21.1664978],
                [106.6822177, 21.1665024],
                [106.6821343, 21.1665145],
                [106.6820526, 21.1665339],
                [106.6819787, 21.1665584],
                [106.6819074, 21.1665889],
                [106.6818392, 21.1666252],
                [106.6817748, 21.166667],
                [106.6817146, 21.166714],
                [106.6816717, 21.1670592],
                [106.6815386, 21.1671827],
                [106.6814109, 21.1673112],
                [106.6812888, 21.1674443],
                [106.6811726, 21.1675819],
                [106.6810623, 21.1677237],
                [106.6809583, 21.1678696],
                [106.6808492, 21.1680375],
                [106.6807483, 21.1682099],
                [106.6806559, 21.1683864],
                [106.680572, 21.1685665],
                [106.6804969, 21.16875],
                [106.6804057, 21.1692852],
                [106.680389, 21.1693739],
                [106.6803635, 21.1694607],
                [106.6803296, 21.169545],
                [106.6802875, 21.169626],
                [106.6802377, 21.169703],
                [106.6801804, 21.1697755],
                [106.6801183, 21.1698407],
                [106.6800502, 21.1699006],
                [106.6799767, 21.1699546],
                [106.6798983, 21.1700022],
                [106.6798156, 21.1700431],
                [106.6797163, 21.1700844],
                [106.6796213, 21.1701337],
                [106.6795313, 21.1701907],
                [106.6794512, 21.1702515],
                [106.6793768, 21.1703183],
                [106.6793087, 21.1703908],
                [106.6792469, 21.1704429],
                [106.6791804, 21.1704896],
                [106.6791095, 21.1705305],
                [106.679035, 21.1705651],
                [106.6789573, 21.1705934],
                [106.6788683, 21.1706169],
                [106.6787773, 21.1706319],
                [106.678685, 21.1706382],
                [106.6785926, 21.1706359],
                [106.6783803, 21.1706076],
                [106.6781692, 21.1705726],
                [106.6779596, 21.1705308],
                [106.6777407, 21.1704797],
                [106.677524, 21.1704214],
                [106.6773097, 21.1703557],
                [106.6770981, 21.170283],
                [106.6768894, 21.1702032],
                [106.6765434, 21.1700806],
                [106.6763614, 21.1700623],
                [106.6761645, 21.1700724],
                [106.6758144, 21.1701225],
                [106.6755526, 21.1701337],
                [106.6742825, 21.1700431],
                [106.6740552, 21.1700828],
                [106.6736407, 21.1702521],
                [106.6734422, 21.1702894],
                [106.6732513, 21.1702955],
                [106.67257, 21.1702124],
                [106.6717932, 21.1701832],
                [106.6713023, 21.1701081],
                [106.6711521, 21.1701065],
                [106.671004, 21.1701298],
                [106.6708648, 21.1701765],
                [106.6706804, 21.1702851],
                [106.6698968, 21.1710661],
                [106.6691887, 21.1711086],
                [106.6685986, 21.1713162],
                [106.6679201, 21.1713412],
                [106.6676062, 21.1715188],
                [106.6668203, 21.1715388],
                [106.6663241, 21.1718139],
                [106.6661728, 21.1718707],
                [106.666013, 21.1719015],
                [106.6657501, 21.171894],
                [106.6652378, 21.1718089],
                [106.6646907, 21.171849],
                [106.6639638, 21.1717164],
                [106.6637599, 21.1721166],
                [106.6625027, 21.1721058],
                [106.6621955, 21.172151],
                [106.6620068, 21.1722173],
                [106.661758, 21.172362],
                [106.6609936, 21.173083],
                [106.6608086, 21.1732054],
                [106.6606053, 21.1732992],
                [106.6603893, 21.1733619],
                [106.6601661, 21.1733923],
                [106.6599405, 21.1733897],
                [106.6589856, 21.1733271],
                [106.6587089, 21.1733491],
                [106.6583021, 21.1734317],
                [106.6580425, 21.1735183],
                [106.6577954, 21.1736285],
                [106.6574152, 21.1738577],
                [106.6571356, 21.173995],
                [106.6566875, 21.1741449],
                [106.6563758, 21.174205],
                [106.6560701, 21.1745152],
                [106.6559481, 21.1745492],
                [106.6558084, 21.1745602],
                [106.655657, 21.1745402],
                [106.6552444, 21.1743609],
                [106.6546203, 21.1741642],
                [106.6541866, 21.1739564],
                [106.6538143, 21.1737123],
                [106.6528957, 21.1737572],
                [106.6525161, 21.1738174],
                [106.6520521, 21.1739399],
                [106.6512168, 21.1742366],
                [106.6510516, 21.1743176],
                [106.6508746, 21.1744426],
                [106.6507602, 21.1745713],
                [106.6506198, 21.1746752],
                [106.6504905, 21.1747384],
                [106.6501921, 21.1748246],
                [106.6500485, 21.1749028],
                [106.6499379, 21.1750004],
                [106.6497171, 21.1752711],
                [106.6494675, 21.1754698],
                [106.649268, 21.1755781],
                [106.6486173, 21.175785],
                [106.6483137, 21.175909],
                [106.6479421, 21.1761211],
                [106.6477311, 21.1762785],
                [106.6475395, 21.1764486],
                [106.6471754, 21.1768064],
                [106.6468379, 21.1771863],
                [106.6465493, 21.1775577],
                [106.6461915, 21.1780992],
                [106.6460818, 21.1783404],
                [106.6459715, 21.1787195],
                [106.6459342, 21.178996],
                [106.6459265, 21.1793684],
                [106.6458897, 21.1794557],
                [106.6457838, 21.1795548],
                [106.6456955, 21.1795884],
                [106.6455531, 21.1795884],
                [106.6450837, 21.1794023],
                [106.6433859, 21.1793748],
                [106.6430336, 21.1797402],
                [106.6427606, 21.1799575],
                [106.642612, 21.1800576],
                [106.6424578, 21.1801501],
                [106.6414761, 21.1805352],
                [106.6407251, 21.1812655],
                [106.6405455, 21.1811024],
                [106.6404382, 21.1809595],
                [106.6403024, 21.1806788],
                [106.6401814, 21.1804781],
                [106.6400317, 21.1803024],
                [106.6398596, 21.1801545],
                [106.6397649, 21.1800901],
                [106.6381213, 21.179313],
                [106.6376721, 21.1790623],
                [106.6372436, 21.1787895],
                [106.6380295, 21.1782743],
                [106.6381556, 21.1777041],
                [106.6395249, 21.1764632],
                [106.6396995, 21.176237],
                [106.639808, 21.1759771],
                [106.63984, 21.1757932],
                [106.6398718, 21.1746691],
                [106.6399205, 21.1742225],
                [106.6400482, 21.1736378],
                [106.6401726, 21.1732571],
                [106.6400643, 21.1716925],
                [106.6400007, 21.1712271],
                [106.6398963, 21.1707434],
                [106.6387698, 21.1689576],
                [106.6385846, 21.168686],
                [106.638438, 21.168426],
                [106.6382963, 21.1681277],
                [106.6381677, 21.1679927],
                [106.6380537, 21.1679346],
                [106.6375387, 21.1678045],
                [106.636941, 21.168555],
                [106.6366298, 21.1689213],
                [106.635983, 21.1696354],
                [106.6351217, 21.1705001],
                [106.6342127, 21.1713212],
                [106.6329253, 21.1714763],
                [106.6320932, 21.171916],
                [106.6314589, 21.1722096],
                [106.6309973, 21.172392],
                [106.6306668, 21.1724517],
                [106.6303933, 21.1724567],
                [106.6302184, 21.1723406],
                [106.6299389, 21.1720949],
                [106.6295326, 21.1716088],
                [106.6293499, 21.1714413],
                [106.6291649, 21.1713084],
                [106.6289581, 21.1712074],
                [106.6288484, 21.1711697],
                [106.6286202, 21.1711217],
                [106.6285034, 21.1711117],
                [106.6282692, 21.1711202],
                [106.6277084, 21.1712362],
                [106.6272238, 21.1712614],
                [106.6268802, 21.1712422],
                [106.6265202, 21.1711886],
                [106.6257746, 21.1711288],
                [106.6249342, 21.1710335],
                [106.6247381, 21.1710248],
                [106.6245443, 21.1710376],
                [106.6237816, 21.1712062],
                [106.6233977, 21.1712673],
                [106.6230105, 21.1713062],
                [106.6225129, 21.1714913],
                [106.620571, 21.1717864],
                [106.6205871, 21.1721666],
                [106.6196209, 21.1722086],
                [106.6192877, 21.1721896],
                [106.6191226, 21.1721666],
                [106.6187845, 21.1720904],
                [106.6186198, 21.1720382],
                [106.6183019, 21.1719065],
                [106.6174403, 21.1714387],
                [106.6172232, 21.1713644],
                [106.6171103, 21.1713407],
                [106.6167879, 21.1713237],
                [106.6166845, 21.1713337],
                [106.6162768, 21.1714188],
                [106.6157362, 21.1713982],
                [106.6155717, 21.1714297],
                [106.6153356, 21.1715318],
                [106.6150923, 21.1715914],
                [106.614183, 21.171628],
                [106.6138702, 21.1716574],
                [106.6135209, 21.1717142],
                [106.6133236, 21.1717174],
                [106.6131282, 21.1716921],
                [106.6129368, 21.1716379],
                [106.6127546, 21.1715551],
                [106.6123896, 21.1713089],
                [106.6121736, 21.1711956],
                [106.6119564, 21.1711122],
                [106.6116339, 21.1710361],
                [106.6114876, 21.1713075],
                [106.6113584, 21.1714757],
                [106.6111976, 21.1716222],
                [106.6110091, 21.1717415],
                [106.609928, 21.1722742],
                [106.6086996, 21.1727069],
                [106.6082221, 21.1744927],
                [106.6083884, 21.1748428],
                [106.6085291, 21.175051],
                [106.6086942, 21.175243],
                [106.6079807, 21.1762284],
                [106.607909, 21.177124],
                [106.6078724, 21.177398],
                [106.6078349, 21.1775223],
                [106.6077281, 21.1776972],
                [106.6076195, 21.1778009],
                [106.6068769, 21.1782736],
                [106.6067378, 21.1783789],
                [106.6066046, 21.1785146],
                [106.6064868, 21.1786828],
                [106.6061795, 21.1793208],
                [106.6060624, 21.1794898],
                [106.6059181, 21.1796399],
                [106.6057403, 21.1797812],
                [106.6051537, 21.1799373],
                [106.6046373, 21.1799925],
                [106.6038885, 21.1799996],
                [106.6030768, 21.179957],
                [106.6025587, 21.1798448],
                [106.6018427, 21.1795839],
                [106.600895, 21.1793251],
                [106.5995121, 21.1792043],
                [106.5985848, 21.1791796],
                [106.5973545, 21.1791744],
                [106.5956869, 21.1791065],
                [106.594421, 21.1791017],
                [106.593493, 21.1790101],
                [106.5923942, 21.1788029],
                [106.5915547, 21.1786434],
                [106.5902861, 21.1783876],
                [106.589216, 21.1783474],
                [106.588896, 21.1784341],
                [106.5887019, 21.17862],
                [106.5886158, 21.1789053],
                [106.5885507, 21.1794917],
                [106.5885206, 21.1800108],
                [106.5886251, 21.1806123],
                [106.5888986, 21.1811788],
                [106.589486, 21.1819264],
                [106.5904226, 21.1828213],
                [106.5907659, 21.1832364],
                [106.5908409, 21.1835872],
                [106.5908257, 21.1838217],
                [106.5906433, 21.1842752],
                [106.5903281, 21.1848138],
                [106.5897835, 21.185572],
                [106.5890714, 21.1864992],
                [106.5885973, 21.1871898],
                [106.5881068, 21.1879977],
                [106.5877892, 21.1883186],
                [106.5871084, 21.1888439],
                [106.5863291, 21.1893198],
                [106.5858844, 21.1894244],
                [106.5854391, 21.1894621],
                [106.5846192, 21.1894865],
                [106.5842987, 21.1895229],
                [106.5840508, 21.1896926],
                [106.5838144, 21.1900966],
                [106.583537, 21.1908355],
                [106.5833452, 21.1912389],
                [106.5831341, 21.1915087],
                [106.582798, 21.1917628],
                [106.582337, 21.1920015],
                [106.5813681, 21.1922616],
                [106.5804252, 21.1924545],
                [106.5799265, 21.1925261],
                [106.5794094, 21.1925141],
                [106.5788382, 21.1924526],
                [106.577757, 21.1922117],
                [106.5769705, 21.1920182],
                [106.5764513, 21.191839],
                [106.5756442, 21.1913779],
                [106.5743984, 21.1907534],
                [106.5735699, 21.1905197],
                [106.5726216, 21.1902755],
                [106.5721475, 21.1901661],
                [106.5710297, 21.190015],
                [106.570513, 21.1900533],
                [106.5702988, 21.1900218],
                [106.5700481, 21.1899237],
                [106.5693849, 21.1895951],
                [106.5676744, 21.1888579],
                [106.5673696, 21.1886933],
                [106.5670839, 21.1886625],
                [106.5663534, 21.1887028],
                [106.5658546, 21.1887409],
                [106.56541, 21.1888454],
                [106.5651261, 21.188982],
                [106.5648435, 21.1892356],
                [106.5646321, 21.1894886],
                [106.5643804, 21.1901269],
                [106.5642251, 21.1906305],
                [106.5640861, 21.1909664],
                [106.5639107, 21.1912358],
                [106.5635927, 21.1915233],
                [106.5631682, 21.1918453],
                [106.56238, 21.1923379],
                [106.5617794, 21.192879],
                [106.5614959, 21.193049],
                [106.5611577, 21.1931023],
                [106.5608544, 21.1930883],
                [106.5604792, 21.1930081],
                [106.5599434, 21.1929294],
                [106.5596409, 21.1929824],
                [106.5593573, 21.1931356],
                [106.558772, 21.1936585],
                [106.5586948, 21.1937275],
                [106.5583943, 21.1939812],
                [106.5580407, 21.1942691],
                [106.5577924, 21.1943885],
                [106.5574721, 21.1944585],
                [106.5570983, 21.1945121],
                [106.5566702, 21.1944993],
                [106.5556614, 21.1943579],
                [106.5546235, 21.1940161],
                [106.5524684, 21.1933832],
                [106.5515742, 21.1931236],
                [106.5503049, 21.1928005],
                [106.549076, 21.1924403],
                [106.5488652, 21.1923786],
                [106.548399, 21.1921318],
                [106.5480391, 21.1918171],
                [106.5476061, 21.1913357],
                [106.5473361, 21.1910872],
                [106.5470498, 21.1909894],
                [106.5466757, 21.1910096],
                [106.5452964, 21.1912565],
                [106.5440702, 21.1916526],
                [106.5433066, 21.1919608],
                [106.5426159, 21.1924024],
                [106.5420241, 21.1929266],
                [106.5417972, 21.1933972],
                [106.5416422, 21.1939342],
                [106.5415559, 21.1950563],
                [106.5414394, 21.1958607],
                [106.5412858, 21.1965316],
                [106.5410154, 21.1971031],
                [106.5405939, 21.1977261],
                [106.5401716, 21.1982655],
                [106.5394069, 21.1993269],
                [106.5391272, 21.199865],
                [106.5389379, 21.2005362],
                [106.5387084, 21.2016093],
                [106.5385705, 21.2020624],
                [106.538414, 21.2024488],
                [106.5380801, 21.2029204],
                [106.5375686, 21.2034774],
                [106.5370571, 21.2040008],
                [106.5365384, 21.2047252],
                [106.5358853, 21.2062208],
                [106.5355724, 21.2070101],
                [106.5350449, 21.2085882],
                [106.5348396, 21.2094268],
                [106.5343674, 21.2111884],
                [106.5342311, 21.2118089],
                [106.5339894, 21.2125809],
                [106.5336529, 21.2136552],
                [106.5334461, 21.2143598],
                [106.5332936, 21.215131],
                [106.5330058, 21.2157529],
                [106.5326528, 21.2161076],
                [106.5321743, 21.2163965],
                [106.5314828, 21.2167876],
                [106.5310759, 21.2171094],
                [106.5307593, 21.2175306],
                [106.5305947, 21.2180007],
                [106.5305817, 21.2184695],
                [106.5305869, 21.2189716],
                [106.5305376, 21.2193736],
                [106.5303992, 21.2197933],
                [106.5301727, 21.2203142],
                [106.5297345, 21.2210545],
                [106.5293668, 21.2217106],
                [106.5290503, 21.2221485],
                [106.5287151, 21.222503],
                [106.5283436, 21.2227909],
                [106.5274482, 21.2233011],
                [106.5266146, 21.2237438],
                [106.5255145, 21.2242893],
                [106.5241728, 21.2247533],
                [106.5231057, 21.2250475],
                [106.5221623, 21.2252233],
                [106.5208888, 21.2253855],
                [106.5201584, 21.225459],
                [106.5196589, 21.2254467],
                [106.5185692, 21.2252724],
                [106.5175061, 21.2250811],
                [106.5167009, 21.2248207],
                [106.5158415, 21.2244936],
                [106.5152139, 21.2241478],
                [106.514801, 21.2238838],
                [106.5137126, 21.222973],
                [106.5130654, 21.22246],
                [106.5124727, 21.2220469],
                [106.5119363, 21.2219178],
                [106.5115618, 21.2219211],
                [106.5112053, 21.221941],
                [106.5107074, 21.2220794],
                [106.5103068, 21.2221332],
                [106.5099499, 21.2221197],
                [106.5096457, 21.222022],
                [106.5088575, 21.2216609],
                [106.508285, 21.2214986],
                [106.5078747, 21.2214855],
                [106.5076614, 21.2215543],
                [106.5074133, 21.2217072],
                [106.5069798, 21.2220458],
                [106.5063085, 21.2226711],
                [106.5056524, 21.2230452],
                [106.5050131, 21.2233186],
                [106.5039287, 21.223663],
                [106.5028978, 21.2240069],
                [106.5018799, 21.2244007],
                [106.5017393, 21.2239587],
                [106.5013912, 21.223177],
                [106.5013087, 21.2227733],
                [106.5008253, 21.2215407],
                [106.5007985, 21.2214697],
                [106.5004137, 21.2202745],
                [106.5001012, 21.2190615],
                [106.4998996, 21.2180211],
                [106.4998636, 21.2171528],
                [106.4999337, 21.215986],
                [106.5000361, 21.2153152],
                [106.500051, 21.2140991],
                [106.4999639, 21.2135788],
                [106.4997692, 21.2132084],
                [106.4992732, 21.2127413],
                [106.4987232, 21.2123244],
                [106.4985015, 21.2119789],
                [106.4983056, 21.2115092],
                [106.4982466, 21.211063],
                [106.4985334, 21.2097454],
                [106.4992244, 21.207903],
                [106.4996482, 21.2066337],
                [106.5000947, 21.2049176],
                [106.500491, 21.2036238],
                [106.5006357, 21.2031274],
                [106.5007299, 21.2025827],
                [106.5007437, 21.2023104],
                [106.5007401, 21.2019479],
                [106.5006363, 21.2015408],
                [106.5004153, 21.2010441],
                [106.5001355, 21.2008477],
                [106.4980581, 21.200494],
                [106.4969096, 21.200256],
                [106.4953227, 21.1998234],
                [106.4949934, 21.1996526],
                [106.4947182, 21.199407],
                [106.4943867, 21.1990128],
                [106.4943861, 21.1990037],
                [106.4943544, 21.1985168],
                [106.4952885, 21.1937195],
                [106.4955739, 21.1922529],
                [106.495674, 21.1913587],
                [106.4957716, 21.1902164],
                [106.4959203, 21.1887263],
                [106.4961107, 21.1859455],
                [106.4961022, 21.1851018],
                [106.4960711, 21.1847299],
                [106.4958509, 21.1845333],
                [106.4936055, 21.1837342],
                [106.4927834, 21.1833694],
                [106.4923703, 21.1830008],
                [106.4921485, 21.1826306],
                [106.491954, 21.1822848],
                [106.491541, 21.1819164],
                [106.490446, 21.1815538],
                [106.4897612, 21.1812869],
                [106.4896503, 21.1811142],
                [106.4894557, 21.1807436],
                [106.4894821, 21.1804792],
                [106.4895053, 21.1802469],
                [106.4896327, 21.1793525],
                [106.4897044, 21.1783345],
                [106.4895077, 21.1777654],
                [106.4885665, 21.176409],
                [106.4869344, 21.1741652],
                [106.4865711, 21.1733],
                [106.4873262, 21.1724249],
                [106.4883001, 21.1715973],
                [106.4888114, 21.1708981],
                [106.4892635, 21.1697278],
                [106.4897118, 21.1681855],
                [106.490003, 21.1672896],
                [106.4901071, 21.1667924],
                [106.4900149, 21.1664771],
                [106.489662, 21.1661436],
                [106.4891685, 21.1657389],
                [106.4885045, 21.1652538],
                [106.4881309, 21.1648479],
                [106.4876762, 21.1643247],
                [106.4872031, 21.1636703],
                [106.4865152, 21.1627945],
                [106.4860302, 21.1622442],
                [106.4855085, 21.1617691],
                [106.4848757, 21.161411],
                [106.4842313, 21.1608984],
                [106.4838012, 21.160497],
                [106.4834372, 21.1600457],
                [106.4828668, 21.1589416],
                [106.4826841, 21.1586341],
                [106.4826016, 21.1583712],
                [106.4825988, 21.1580985],
                [106.4827274, 21.1569701],
                [106.4828206, 21.1562953],
                [106.4827474, 21.1559689],
                [106.4825952, 21.1557341],
                [106.4822822, 21.1553916],
                [106.4818077, 21.1548689],
                [106.4816314, 21.1546165],
                [106.4814829, 21.1535971],
                [106.4811706, 21.152297],
                [106.4810153, 21.1513679],
                [106.4808021, 21.1497613],
                [106.4806014, 21.1486597],
                [106.480304, 21.1473861],
                [106.4798266, 21.1463667],
                [106.4795392, 21.1455388],
                [106.4805024, 21.1454449],
                [106.4811691, 21.1453884],
                [106.4820211, 21.1453134],
                [106.4826873, 21.1452064],
                [106.4832413, 21.1449991],
                [106.4835532, 21.1446757],
                [106.4837237, 21.1441173],
                [106.4838451, 21.1432727],
                [106.4842972, 21.1412102],
                [106.4842022, 21.1391187],
                [106.4841389, 21.1383431],
                [106.4838907, 21.1376197],
                [106.4833104, 21.136598],
                [106.4820871, 21.1347358],
                [106.4827467, 21.1339707],
                [106.483751, 21.1324602],
                [106.4842309, 21.1314913],
                [106.4842786, 21.1312068],
                [106.4843343, 21.1308687],
                [106.4843001, 21.1307702],
                [106.4842488, 21.1306878],
                [106.484216, 21.1305731],
                [106.4842039, 21.1304104],
                [106.4841989, 21.1298933],
                [106.4841662, 21.1297786],
                [106.4840701, 21.1296262],
                [106.483974, 21.129493],
                [106.4836983, 21.1292655],
                [106.4836554, 21.1291797],
                [106.4836325, 21.1289885],
                [106.4836277, 21.1285],
                [106.4836156, 21.1283469],
                [106.4835621, 21.1282516],
                [106.4834983, 21.128185],
                [106.4834139, 21.1281476],
                [106.4833192, 21.1281484],
                [106.4832569, 21.1282255],
                [106.4830724, 21.1287252],
                [106.4829381, 21.1289658],
                [106.4827817, 21.1291012],
                [106.4826036, 21.1291794],
                [106.4825093, 21.1292185],
                [106.4822885, 21.1292204],
                [106.4821091, 21.1291646],
                [106.4818977, 21.1290611],
                [106.4816543, 21.1289003],
                [106.4813998, 21.1286919],
                [106.4812297, 21.1285114],
                [106.4811547, 21.1283685],
                [106.4811429, 21.1282344],
                [106.4811312, 21.12811],
                [106.4811719, 21.1279756],
                [106.481255, 21.1278695],
                [106.481391, 21.1277917],
                [106.481548, 21.1277138],
                [106.4817575, 21.1276257],
                [106.4819355, 21.1275476],
                [106.4820822, 21.1274983],
                [106.4821867, 21.1274209],
                [106.4823432, 21.127295],
                [106.4824157, 21.127189],
                [106.4824359, 21.1271026],
                [106.4824347, 21.1269782],
                [106.4824012, 21.1267869],
                [106.4822626, 21.126587],
                [106.4821139, 21.1264447],
                [106.4818813, 21.1263222],
                [106.4817439, 21.1262564],
                [106.481586, 21.1262385],
                [106.481502, 21.1262488],
                [106.481376, 21.1262787],
                [106.4812219, 21.1263711],
                [106.4810014, 21.1263467],
                [106.4804934, 21.1264129],
                [106.4798152, 21.1263879],
                [106.4795889, 21.1263693],
                [106.4795498, 21.1263475],
                [106.4794964, 21.1263177],
                [106.4794072, 21.126268],
                [106.479293, 21.1261559],
                [106.4791783, 21.125982],
                [106.4790863, 21.1258182],
                [106.4790167, 21.1256337],
                [106.4789594, 21.1255518],
                [106.4788562, 21.1254086],
                [106.4786746, 21.1253176],
                [106.4783686, 21.125238],
                [106.4782043, 21.125188],
                [106.4781019, 21.1251169],
                [106.4780561, 21.1250555],
                [106.4779078, 21.1249231],
                [106.4777606, 21.1248833],
                [106.4775681, 21.1248438],
                [106.4774093, 21.1247938],
                [106.4773287, 21.1246401],
                [106.4772807, 21.1243524],
                [106.4772303, 21.1238282],
                [106.47716, 21.1235818],
                [106.4770216, 21.123295],
                [106.4768616, 21.1231113],
                [106.4767364, 21.12303],
                [106.4766235, 21.123031],
                [106.4764997, 21.1230835],
                [106.4764107, 21.1232284],
                [106.4762552, 21.1234971],
                [106.4760771, 21.1237764],
                [106.4758755, 21.1239531],
                [106.475667, 21.1240167],
                [106.4754181, 21.1239777],
                [106.4752366, 21.1239175],
                [106.4750775, 21.1238264],
                [106.4749398, 21.1236012],
                [106.4748581, 21.1233448],
                [106.4748872, 21.1228609],
                [106.474895, 21.1225007],
                [106.4748595, 21.1223467],
                [106.4747684, 21.1222755],
                [106.4746551, 21.1222354],
                [106.4744972, 21.1222676],
                [106.4743177, 21.1224029],
                [106.4740262, 21.122642],
                [106.4738012, 21.1227367],
                [106.4735866, 21.1227487],
                [106.4734057, 21.1227401],
                [106.4731282, 21.122679],
                [106.4728781, 21.1225269],
                [106.4727175, 21.1222817],
                [106.4726937, 21.1221584],
                [106.4726929, 21.1220762],
                [106.4727261, 21.122004],
                [106.472816, 21.1219518],
                [106.4732901, 21.1219065],
                [106.4736402, 21.1218829],
                [106.4737641, 21.1218407],
                [106.4738764, 21.121778],
                [106.4739323, 21.1217159],
                [106.4739656, 21.121654],
                [106.4739648, 21.1215716],
                [106.4739408, 21.1214279],
                [106.4738836, 21.1213565],
                [106.4736906, 21.1212657],
                [106.4733731, 21.1211553],
                [106.4732369, 21.1210948],
                [106.4731344, 21.1210134],
                [106.4730544, 21.1209215],
                [106.4730082, 21.1208192],
                [106.472927, 21.120604],
                [106.4728342, 21.1203682],
                [106.4727183, 21.120105],
                [106.4726732, 21.1199392],
                [106.4726878, 21.1196543],
                [106.4726754, 21.1192746],
                [106.4726653, 21.1191402],
                [106.4725768, 21.1189748],
                [106.4724494, 21.1188323],
                [106.4722045, 21.1186762],
                [106.4717854, 21.1184822],
                [106.4714801, 21.1183584],
                [106.4710165, 21.1180619],
                [106.4706849, 21.1179145],
                [106.4705019, 21.1178687],
                [106.4703628, 21.117862],
                [106.4701813, 21.1179664],
                [106.469983, 21.1181263],
                [106.4698622, 21.1182222],
                [106.4697514, 21.1182579],
                [106.4696722, 21.1182381],
                [106.4695471, 21.1181672],
                [106.4692851, 21.1179433],
                [106.4690802, 21.1177908],
                [106.4689104, 21.1177513],
                [106.4688088, 21.1177522],
                [106.4685157, 21.1178163],
                [106.4681774, 21.1178912],
                [106.4677707, 21.1178947],
                [106.4674768, 21.1178767],
                [106.4672275, 21.1177967],
                [106.4669329, 21.1177067],
                [106.4666951, 21.1176573],
                [106.4664805, 21.1176592],
                [106.4661379, 21.1177066],
                [106.4659325, 21.1177218],
                [106.4657269, 21.1177235],
                [106.4656239, 21.1176977],
                [106.4653718, 21.1174459],
                [106.4651847, 21.1170865],
                [106.4649004, 21.1165541],
                [106.4647964, 21.1164213],
                [106.4646637, 21.116369],
                [106.4643994, 21.1163713],
                [106.4639301, 21.1164288],
                [106.4635784, 21.1164988],
                [106.4633434, 21.1165008],
                [106.4631228, 21.1164625],
                [106.4628721, 21.1163444],
                [106.4625023, 21.1160802],
                [106.4622446, 21.1160021],
                [106.4619362, 21.1159915],
                [106.4617016, 21.1160336],
                [106.461452, 21.1160358],
                [106.4613492, 21.1160366],
                [106.4612609, 21.1160107],
                [106.4612307, 21.1159307],
                [106.4612445, 21.115837],
                [106.4612728, 21.1157298],
                [106.4613743, 21.1155952],
                [106.4614756, 21.1154473],
                [106.4615628, 21.1153529],
                [106.4615769, 21.115286],
                [106.4615764, 21.1152459],
                [106.4615317, 21.1151794],
                [106.461458, 21.11514],
                [106.4613111, 21.1151412],
                [106.4609445, 21.1151845],
                [106.4600513, 21.1154462],
                [106.4592464, 21.1157205],
                [106.4590278, 21.1158962],
                [106.4589634, 21.1160684],
                [106.4590277, 21.1166423],
                [106.4589301, 21.1171643],
                [106.4588732, 21.1173518],
                [106.4587716, 21.1174729],
                [106.4586551, 21.1175808],
                [106.4585097, 21.1177291],
                [106.4584081, 21.11785],
                [106.4583214, 21.1179845],
                [106.4582496, 21.1181588],
                [106.4582075, 21.1183595],
                [106.4581809, 21.1186404],
                [106.4581397, 21.1189347],
                [106.458025, 21.1192162],
                [106.4579526, 21.1193238],
                [106.4578221, 21.1194852],
                [106.4577204, 21.119593],
                [106.4575442, 21.1195944],
                [106.457396, 21.1194621],
                [106.4572001, 21.1189428],
                [106.4570923, 21.118436],
                [106.4568686, 21.1180905],
                [106.4565866, 21.1177723],
                [106.4562905, 21.117521],
                [106.4559606, 21.1174316],
                [106.4553118, 21.1174545],
                [106.4549689, 21.1175269],
                [106.4545908, 21.117895],
                [106.4542118, 21.1181935],
                [106.4539568, 21.118332],
                [106.4537196, 21.1183341],
                [106.4533924, 21.1183678],
                [106.4529185, 21.1184335],
                [106.4525233, 21.1184471],
                [106.4521732, 21.1184502],
                [106.4520036, 21.118431],
                [106.4519014, 21.1183702],
                [106.4518776, 21.1182574],
                [106.4518989, 21.1181236],
                [106.4520109, 21.1180199],
                [106.4524602, 21.1177591],
                [106.452561, 21.117676],
                [106.4526502, 21.1175622],
                [106.4526722, 21.1175003],
                [106.4526713, 21.1174078],
                [106.452659, 21.1173052],
                [106.4526015, 21.1171926],
                [106.4525443, 21.1171212],
                [106.4524307, 21.1170605],
                [106.4522613, 21.1170619],
                [106.4520699, 21.1171252],
                [106.4517219, 21.1173338],
                [106.4510019, 21.1177922],
                [106.4505921, 21.117916],
                [106.4501666, 21.1179464],
                [106.4498287, 21.1179226],
                [106.4491669, 21.1178079],
                [106.4485051, 21.1176799],
                [106.4479912, 21.1176842],
                [106.4476178, 21.1177788],
                [106.4469763, 21.1182385],
                [106.4465086, 21.1184563],
                [106.4461857, 21.1184723],
                [106.4459946, 21.1184472],
                [106.4458916, 21.1184214],
                [106.4457734, 21.1183423],
                [106.4456837, 21.1181693],
                [106.4456533, 21.1180761],
                [106.4456519, 21.1179292],
                [106.4457224, 21.1176213],
                [106.4459232, 21.1171388],
                [106.4460368, 21.1167371],
                [106.446036, 21.1166569],
                [106.4459907, 21.1165371],
                [106.4458723, 21.1164312],
                [106.4457106, 21.1164059],
                [106.4455641, 21.1164339],
                [106.4452143, 21.116704],
                [106.444648, 21.11735],
                [106.4442696, 21.1176872],
                [106.4439912, 21.1177429],
                [106.4438442, 21.1177175],
                [106.4437111, 21.1176251],
                [106.4435752, 21.1172389],
                [106.4433373, 21.1169204],
                [106.4430425, 21.1168027],
                [106.4427782, 21.116805],
                [106.4425443, 21.1169137],
                [106.4422091, 21.1171571],
                [106.4417851, 21.1173477],
                [106.4413819, 21.1173381],
                [106.4411842, 21.1166315],
                [106.4407897, 21.1152984],
                [106.4407253, 21.1147109],
                [106.4407456, 21.1145236],
                [106.440976, 21.1140673],
                [106.4415844, 21.1133972],
                [106.4416732, 21.1132995],
                [106.4417671, 21.1131518],
                [106.4422532, 21.1118112],
                [106.4423078, 21.1113964],
                [106.442304, 21.1109955],
                [106.4421984, 21.1106891],
                [106.4418638, 21.1102509],
                [106.440902, 21.1094439],
                [106.4398364, 21.1085308],
                [106.4394134, 21.1080399],
                [106.4391896, 21.1076676],
                [106.4391577, 21.1074005],
                [106.439154, 21.1070264],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [106.5142953, 16.8958852],
                [106.5144848, 16.895559],
                [106.514788, 16.8950878],
                [106.5192163, 16.8897623],
                [106.5204617, 16.8893999],
                [106.5309293, 16.8863539],
                [106.5309158, 16.8860697],
                [106.5314772, 16.8849662],
                [106.5340448, 16.8799186],
                [106.5381623, 16.8718241],
                [106.5386739, 16.8711978],
                [106.5421464, 16.8694289],
                [106.5502506, 16.8655088],
                [106.5513254, 16.8651569],
                [106.5513125, 16.7981644],
                [106.5512967, 16.6943437],
                [106.5543256, 16.6907627],
                [106.5544447, 16.6888698],
                [106.5552814, 16.6855564],
                [106.5558005, 16.6840564],
                [106.5560135, 16.6832693],
                [106.5561649, 16.6826337],
                [106.5567203, 16.6816022],
                [106.5576942, 16.6775796],
                [106.5584577, 16.673097],
                [106.5592855, 16.6703952],
                [106.5601109, 16.6687482],
                [106.5608655, 16.6680904],
                [106.5616195, 16.6676964],
                [106.5627154, 16.6675668],
                [106.5656602, 16.6672432],
                [106.5660031, 16.6670461],
                [106.5660724, 16.6666505],
                [106.566074, 16.6659252],
                [106.5656648, 16.665199],
                [106.564778, 16.6637463],
                [106.5645067, 16.6626247],
                [106.5645082, 16.6619652],
                [106.5649889, 16.6613068],
                [106.566019, 16.6599901],
                [106.5667077, 16.6581452],
                [106.5667802, 16.6562987],
                [106.5667172, 16.6539247],
                [106.5666543, 16.6514186],
                [106.5662469, 16.649901],
                [106.565702, 16.648713],
                [106.5649518, 16.6473924],
                [106.5645471, 16.6447538],
                [106.5647566, 16.6429078],
                [106.5655833, 16.6406675],
                [106.5660476, 16.6396298],
                [106.5666311, 16.6389386],
                [106.5678986, 16.6384137],
                [106.5695774, 16.6376917],
                [106.5705708, 16.6373971],
                [106.5715639, 16.6371353],
                [106.5730372, 16.6364789],
                [106.5744829, 16.6355646],
                [106.5763287, 16.6339432],
                [106.5775624, 16.6320391],
                [106.5781801, 16.6312019],
                [106.5801628, 16.6299525],
                [106.5831994, 16.6284654],
                [106.5848573, 16.6275054],
                [106.5882123, 16.6253676],
                [106.5891772, 16.6244918],
                [106.5903025, 16.6234434],
                [106.5907225, 16.6210358],
                [106.5905511, 16.618939],
                [106.590529, 16.6186481],
                [106.5904938, 16.6184395],
                [106.5907333, 16.6180784],
                [106.5908022, 16.6177827],
                [106.5908033, 16.6172569],
                [106.5905658, 16.6166319],
                [106.5902596, 16.6162698],
                [106.5896463, 16.6158741],
                [106.5887261, 16.615478],
                [106.5878738, 16.6151805],
                [106.5863737, 16.6147502],
                [106.5854705, 16.6144197],
                [106.5849593, 16.6141558],
                [106.5843122, 16.6136286],
                [106.5838697, 16.6131676],
                [106.5831213, 16.6122129],
                [106.5826796, 16.6113904],
                [106.5823739, 16.6107652],
                [106.5821705, 16.6101405],
                [106.5820354, 16.6095156],
                [106.5820539, 16.6088584],
                [106.5820896, 16.6081353],
                [106.5823308, 16.6070512],
                [106.5826743, 16.6059344],
                [106.5830519, 16.6048834],
                [106.5836, 16.6037999],
                [106.5843357, 16.6027168],
                [106.5850197, 16.6018965],
                [106.5857376, 16.6012077],
                [106.5864547, 16.6008147],
                [106.5867962, 16.6006511],
                [106.5877854, 16.6006859],
                [106.5891146, 16.6012143],
                [106.5909547, 16.6021383],
                [106.5939869, 16.603919],
                [106.5956389, 16.6051384],
                [106.5968996, 16.6057982],
                [106.5977176, 16.6061285],
                [106.5985361, 16.6062615],
                [106.5997302, 16.606198],
                [106.6014353, 16.6064314],
                [106.6046409, 16.6069305],
                [106.6061586, 16.6070977],
                [106.6076936, 16.6071335],
                [106.6088533, 16.6072014],
                [106.6100475, 16.6071051],
                [106.6112079, 16.6068443],
                [106.6120936, 16.6066666],
                [106.6139381, 16.6062906],
                [106.6156956, 16.6058995],
                [106.6169926, 16.6055732],
                [106.6189724, 16.6048867],
                [106.6218745, 16.6036101],
                [106.6243668, 16.6025629],
                [106.6255447, 16.6020391],
                [106.6261593, 16.6016787],
                [106.6264331, 16.6012848],
                [106.6265703, 16.6008578],
                [106.6265373, 16.600299],
                [106.6260952, 16.5995751],
                [106.6253465, 16.5987521],
                [106.6247678, 16.5980936],
                [106.6245297, 16.5976988],
                [106.6243261, 16.5971397],
                [106.6241573, 16.5962849],
                [106.624091, 16.5952987],
                [106.6240936, 16.5939512],
                [106.6243364, 16.5918152],
                [106.624716, 16.5895809],
                [106.6249923, 16.5879052],
                [106.6253873, 16.5864926],
                [106.6259355, 16.5851789],
                [106.6265859, 16.5839968],
                [106.6271675, 16.5830447],
                [106.6279022, 16.5823558],
                [106.6283119, 16.5821265],
                [106.6291651, 16.5818979],
                [106.6298472, 16.5819321],
                [106.6304607, 16.5821632],
                [106.6310397, 16.5826244],
                [106.6313457, 16.5831508],
                [106.6315151, 16.5837098],
                [106.6316162, 16.5844002],
                [106.6315807, 16.5850904],
                [106.6313406, 16.585813],
                [106.63069, 16.5871594],
                [106.630655, 16.5876195],
                [106.6306881, 16.5881783],
                [106.6308392, 16.5893946],
                [106.6313151, 16.5902501],
                [106.6316892, 16.5907766],
                [106.6322344, 16.5911391],
                [106.6331892, 16.5913052],
                [106.6342466, 16.5913399],
                [106.6355086, 16.5913421],
                [106.6361227, 16.5913431],
                [106.6374527, 16.591444],
                [106.6387143, 16.5916763],
                [106.6395328, 16.5917764],
                [106.6403169, 16.5920078],
                [106.6414759, 16.5924371],
                [106.6426006, 16.5929978],
                [106.6435207, 16.5934266],
                [106.643619, 16.5934597],
                [106.6443048, 16.5936908],
                [106.6453619, 16.5938571],
                [106.6464531, 16.5940232],
                [106.6472717, 16.5940575],
                [106.6478178, 16.5939269],
                [106.6484322, 16.5936979],
                [106.6488422, 16.5933042],
                [106.6492183, 16.5928118],
                [106.6500054, 16.5913999],
                [106.6506227, 16.5895603],
                [106.6513931, 16.5879839],
                [106.6523856, 16.5861122],
                [106.6534119, 16.5843719],
                [106.6545914, 16.5828948],
                [106.655582, 16.5820419],
                [106.6560603, 16.5816483],
                [106.6565382, 16.5814519],
                [106.6572887, 16.5814204],
                [106.657425, 16.5814205],
                [106.6579024, 16.5815198],
                [106.6590611, 16.5821134],
                [106.660492, 16.5830031],
                [106.6610717, 16.5831684],
                [106.66206, 16.5836302],
                [106.662469, 16.5838281],
                [106.6631168, 16.5839606],
                [106.6638501, 16.5840275],
                [106.6647369, 16.584029],
                [106.6658622, 16.584228],
                [106.6664757, 16.5844262],
                [106.666885, 16.5844926],
                [106.667158, 16.5843943],
                [106.667329, 16.5841318],
                [106.6673636, 16.5838688],
                [106.6672963, 16.5832771],
                [106.6669222, 16.5826849],
                [106.6660028, 16.581796],
                [106.6641302, 16.5798538],
                [106.6634838, 16.5788667],
                [106.6627186, 16.5775509],
                [106.6622767, 16.5766954],
                [106.6620741, 16.5756106],
                [106.66211, 16.5744931],
                [106.6622142, 16.5735073],
                [106.6625399, 16.572456],
                [106.6630192, 16.571405],
                [106.6638055, 16.5704202],
                [106.6655136, 16.5687468],
                [106.6679223, 16.5663513],
                [106.6699035, 16.5645796],
                [106.6712356, 16.5633985],
                [106.6721348, 16.5624747],
                [106.6724829, 16.5619543],
                [106.6726201, 16.5614615],
                [106.6725873, 16.5607384],
                [106.6724516, 16.5602451],
                [106.6720773, 16.5597844],
                [106.6714982, 16.5593562],
                [106.6707484, 16.5590921],
                [106.668532, 16.5588257],
                [106.6667255, 16.5582312],
                [106.6644421, 16.5573072],
                [106.661853, 16.5556596],
                [106.6599798, 16.5542104],
                [106.6592648, 16.553519],
                [106.6581762, 16.5520382],
                [106.6562371, 16.5492413],
                [106.6550466, 16.5475302],
                [106.6544171, 16.5467075],
                [106.6538043, 16.5461476],
                [106.6512841, 16.544303],
                [106.6494798, 16.542558],
                [106.6483225, 16.5414057],
                [106.64771, 16.5405829],
                [106.6475067, 16.5398923],
                [106.6474405, 16.5388405],
                [106.6477165, 16.5370661],
                [106.6478912, 16.5347],
                [106.6477246, 16.5325961],
                [106.6476421, 16.5310512],
                [106.6476128, 16.5284218],
                [106.6475133, 16.5268768],
                [106.6473284, 16.5254632],
                [106.6472473, 16.5241154],
                [106.6471622, 16.5230307],
                [106.6471475, 16.522335],
                [106.6474374, 16.5216837],
                [106.6480354, 16.5210273],
                [106.6492984, 16.5202734],
                [106.6508335, 16.5198816],
                [106.6517546, 16.5196202],
                [106.6525054, 16.519227],
                [106.6529157, 16.5185704],
                [106.653088, 16.5176504],
                [106.6532264, 16.5164674],
                [106.6531085, 16.5157112],
                [106.6529736, 16.5148235],
                [106.6526001, 16.5139355],
                [106.6523963, 16.5135407],
                [106.6523288, 16.513212],
                [106.6523295, 16.5127847],
                [106.6523985, 16.5122918],
                [106.6526384, 16.511602],
                [106.6542099, 16.5098626],
                [106.6561214, 16.5086826],
                [106.6581011, 16.5074696],
                [106.6604901, 16.5062245],
                [106.6628782, 16.505341],
                [106.6635603, 16.5052435],
                [106.6639357, 16.5050141],
                [106.6644136, 16.5046861],
                [106.6647552, 16.5043579],
                [106.6650628, 16.5039641],
                [106.6651999, 16.5034713],
                [106.6652349, 16.5029784],
                [106.6652013, 16.5026496],
                [106.6647828, 16.5022466],
                [106.6643843, 16.5019581],
                [106.6639761, 16.5013986],
                [106.6636019, 16.5009379],
                [106.6634663, 16.5004447],
                [106.6633992, 16.49982],
                [106.6634691, 16.4988342],
                [106.6636082, 16.4972896],
                [106.6634395, 16.4963362],
                [106.6631674, 16.4958756],
                [106.662759, 16.4954148],
                [106.6621461, 16.4949536],
                [106.6605795, 16.493998],
                [106.6600689, 16.4935369],
                [106.6597626, 16.4931421],
                [106.6596273, 16.4926159],
                [106.6595939, 16.4921886],
                [106.6596632, 16.4915643],
                [106.659766, 16.4912357],
                [106.6603292, 16.490908],
                [106.6615921, 16.4900226],
                [106.6629243, 16.4886114],
                [106.6634709, 16.4878892],
                [106.6637788, 16.487298],
                [106.6638827, 16.486378],
                [106.6638156, 16.4857204],
                [106.6635779, 16.4851613],
                [106.6633742, 16.4847009],
                [106.6625916, 16.4837793],
                [106.6618431, 16.4829235],
                [106.6615712, 16.4824959],
                [106.6614357, 16.4819698],
                [106.6613007, 16.4812135],
                [106.6610993, 16.4793397],
                [106.6609666, 16.4772689],
                [106.6607989, 16.4757239],
                [106.6606812, 16.4747376],
                [106.6606819, 16.4743432],
                [106.6607514, 16.4736203],
                [106.660786, 16.4732916],
                [106.6608549, 16.4729302],
                [106.6611282, 16.4726348],
                [106.6616056, 16.4724712],
                [106.6623213, 16.4725053],
                [106.6631735, 16.4726052],
                [106.6647401, 16.4734623],
                [106.6668856, 16.4747476],
                [106.6685199, 16.4759334],
                [106.6688264, 16.4761311],
                [106.6693374, 16.4763291],
                [106.6704621, 16.4764953],
                [106.6714506, 16.4765625],
                [106.6722689, 16.476531],
                [106.6729169, 16.4763348],
                [106.6733606, 16.4760067],
                [106.6739752, 16.4754161],
                [106.67442, 16.4744965],
                [106.6745238, 16.4736092],
                [106.6745262, 16.4721302],
                [106.6742557, 16.4707822],
                [106.6741057, 16.4688099],
                [106.6737348, 16.4663442],
                [106.6736003, 16.4651936],
                [106.6735184, 16.4631886],
                [106.6734873, 16.4614136],
                [106.6736603, 16.4598691],
                [106.6737813, 16.4588504],
                [106.6739886, 16.4572402],
                [106.6742636, 16.4558603],
                [106.6747429, 16.4546447],
                [106.6756837, 16.4525756],
                [106.6759913, 16.4520831],
                [106.6763669, 16.4516893],
                [106.6767761, 16.4514926],
                [106.6773218, 16.4513948],
                [106.6776284, 16.4514611],
                [106.6783776, 16.4518567],
                [106.6793646, 16.4528113],
                [106.6810654, 16.4549504],
                [106.6819836, 16.4562993],
                [106.6824594, 16.4570889],
                [106.6827994, 16.4576153],
                [106.6830548, 16.45778],
                [106.6834979, 16.4578464],
                [106.6840093, 16.4577485],
                [106.6849303, 16.4573555],
                [106.6864665, 16.4559774],
                [106.689129, 16.453582],
                [106.6906655, 16.4519737],
                [106.6921164, 16.4505297],
                [106.6926287, 16.449906],
                [106.6928679, 16.449479],
                [106.6929367, 16.4491504],
                [106.6928351, 16.448723],
                [106.6927334, 16.4483285],
                [106.6925634, 16.4480324],
                [106.6922573, 16.4476046],
                [106.6915425, 16.447012],
                [106.6907425, 16.4463863],
                [106.6897888, 16.4459905],
                [106.6891072, 16.4458251],
                [106.6882898, 16.4454625],
                [106.6878132, 16.445133],
                [106.6874048, 16.4446723],
                [106.6869969, 16.4440472],
                [106.6866912, 16.4434222],
                [106.6864537, 16.4426987],
                [106.6864205, 16.442107],
                [106.6864725, 16.4415155],
                [106.6868497, 16.4401685],
                [106.6873621, 16.4394462],
                [106.6876694, 16.4391508],
                [106.6881129, 16.4388557],
                [106.6887949, 16.4386923],
                [106.6893063, 16.4386273],
                [106.6900219, 16.4386942],
                [106.6921684, 16.4392561],
                [106.6939061, 16.4395872],
                [106.6947921, 16.4396871],
                [106.6951839, 16.4397535],
                [106.6958998, 16.4396887],
                [106.6967183, 16.4393284],
                [106.6989019, 16.4379183],
                [106.6998232, 16.4372294],
                [106.7007105, 16.4364418],
                [106.701359, 16.4359496],
                [106.7017683, 16.4357202],
                [106.7028935, 16.435426],
                [106.7051265, 16.4351334],
                [106.7061716, 16.4349892],
                [106.7074108, 16.4345778],
                [106.7088772, 16.4340539],
                [106.7104797, 16.4336288],
                [106.7121841, 16.433434],
                [106.7138033, 16.4332719],
                [106.7143487, 16.4332398],
                [106.7147239, 16.4331416],
                [106.7154397, 16.4329454],
                [106.7173496, 16.4320606],
                [106.7188505, 16.431208],
                [106.7201812, 16.4302237],
                [106.7210002, 16.4294359],
                [106.72199, 16.4285169],
                [106.7230137, 16.427598],
                [106.7235597, 16.4271714],
                [106.7241055, 16.4267778],
                [106.7244808, 16.4264824],
                [106.7247198, 16.4262198],
                [106.7252659, 16.4256289],
                [106.7257097, 16.4251036],
                [106.7259144, 16.4249067],
                [106.7261191, 16.4248413],
                [106.7264258, 16.4248416],
                [106.7269708, 16.4250723],
                [106.728333, 16.425863],
                [106.7290818, 16.4265213],
                [106.7297624, 16.4272781],
                [106.7300003, 16.4277387],
                [106.7301021, 16.4281332],
                [106.7300673, 16.4285933],
                [106.7299986, 16.4289876],
                [106.7296225, 16.4298417],
                [106.7289058, 16.430531],
                [106.72778, 16.4313184],
                [106.7250856, 16.4326953],
                [106.724318, 16.4331216],
                [106.724011, 16.433417],
                [106.7238742, 16.4337126],
                [106.7238055, 16.4340084],
                [106.7238393, 16.4343699],
                [106.7240092, 16.434666],
                [106.7242472, 16.4350935],
                [106.7251322, 16.4359493],
                [106.725745, 16.4363774],
                [106.7265964, 16.4369372],
                [106.7285034, 16.4380902],
                [106.7295422, 16.4386174],
                [106.7306324, 16.4390131],
                [106.7312458, 16.4391126],
                [106.7318931, 16.4392449],
                [106.7322336, 16.4395412],
                [106.7324721, 16.4396401],
                [106.7327786, 16.4397062],
                [106.7333581, 16.4397069],
                [106.7342448, 16.4394122],
                [106.7347223, 16.4391499],
                [106.7353022, 16.4387233],
                [106.7357119, 16.4382637],
                [106.735917, 16.4377709],
                [106.7360881, 16.4373767],
                [106.7361574, 16.4364237],
                [106.7359881, 16.4356675],
                [106.7354797, 16.4334646],
                [106.7352766, 16.4325111],
                [106.7350233, 16.4308018],
                [106.7347192, 16.4288292],
                [106.7342786, 16.4270867],
                [106.7341608, 16.4259033],
                [106.7340259, 16.4248842],
                [106.734095, 16.4242269],
                [106.7343688, 16.4234057],
                [106.7350183, 16.4219274],
                [106.7354455, 16.4210733],
                [106.7358554, 16.4203179],
                [106.7363674, 16.4197597],
                [106.736811, 16.4193658],
                [106.7372889, 16.4188406],
                [106.7377664, 16.4184468],
                [106.7378694, 16.417921],
                [106.7379898, 16.4170995],
                [106.7380589, 16.4163765],
                [106.7380599, 16.4155877],
                [106.7380613, 16.4146016],
                [106.7379952, 16.4130896],
                [106.7380303, 16.4122679],
                [106.7382698, 16.4116766],
                [106.7386452, 16.4112169],
                [106.7389864, 16.4108558],
                [106.7392593, 16.4107246],
                [106.7394978, 16.4106592],
                [106.7398727, 16.4106597],
                [106.7402815, 16.4108245],
                [106.7407239, 16.4112194],
                [106.740996, 16.4116799],
                [106.7419487, 16.4128315],
                [106.7441784, 16.4147077],
                [106.7454378, 16.4158925],
                [106.74571, 16.4163202],
                [106.7459478, 16.4168135],
                [106.7460156, 16.4171093],
                [106.745981, 16.4176024],
                [106.7458606, 16.418391],
                [106.7456896, 16.4188837],
                [106.7454156, 16.4199353],
                [106.745244, 16.4207567],
                [106.7452426, 16.4218414],
                [106.7452414, 16.4227288],
                [106.7454624, 16.4231893],
                [106.7458367, 16.4236827],
                [106.7463477, 16.4239134],
                [106.7470973, 16.4240458],
                [106.748222, 16.4240471],
                [106.7495853, 16.4239173],
                [106.7510509, 16.4238204],
                [106.7518009, 16.4236899],
                [106.7536755, 16.4235934],
                [106.7554818, 16.4236284],
                [106.7570154, 16.4236631],
                [106.7579751, 16.4237299],
                [106.7595085, 16.4243044],
                [106.7603754, 16.424588],
                [106.7613973, 16.4246922],
                [106.7623317, 16.4247688],
                [106.7629458, 16.4249328],
                [106.7636202, 16.4252936],
                [106.7652081, 16.4266892],
                [106.766797, 16.4281696],
                [106.7679139, 16.4290902],
                [106.7687356, 16.4296187],
                [106.7698785, 16.4301727],
                [106.7709056, 16.4308403],
                [106.7716703, 16.4315103],
                [106.7720244, 16.4319582],
                [106.7721749, 16.4324642],
                [106.7720973, 16.433564],
                [106.7720378, 16.4340745],
                [106.7719627, 16.4348052],
                [106.7720289, 16.4356501],
                [106.7722459, 16.4370855],
                [106.7728134, 16.438518],
                [106.7737927, 16.4403416],
                [106.7747104, 16.4417993],
                [106.7751867, 16.442838],
                [106.7752499, 16.443373],
                [106.7752834, 16.4438518],
                [106.7751419, 16.4443321],
                [106.774537, 16.4451826],
                [106.7737013, 16.4463451],
                [106.7732133, 16.4472229],
                [106.7728754, 16.4485785],
                [106.772695, 16.451201],
                [106.7726996, 16.4512528],
                [106.7728903, 16.4534257],
                [106.7737877, 16.4558417],
                [106.7750669, 16.4585364],
                [106.7754239, 16.4590549],
                [106.7751789, 16.4596716],
                [106.7742112, 16.46132],
                [106.7740068, 16.4632628],
                [106.7734757, 16.4637233],
                [106.7728668, 16.464158],
                [106.7720863, 16.4648036],
                [106.7715148, 16.4651615],
                [106.7708962, 16.4659122],
                [106.7705843, 16.4664128],
                [106.7705839, 16.4668144],
                [106.7706591, 16.4671796],
                [106.7708102, 16.4674719],
                [106.7710868, 16.4678164],
                [106.7722574, 16.4683144],
                [106.7731444, 16.4688786],
                [106.7733119, 16.4692429],
                [106.7737486, 16.4694541],
                [106.7746764, 16.4693448],
                [106.7763753, 16.4692686],
                [106.7779122, 16.4694262],
                [106.7791658, 16.4696616],
                [106.7800957, 16.4699355],
                [106.7806618, 16.4701701],
                [106.7811468, 16.4704436],
                [106.781551, 16.470717],
                [106.7825212, 16.4711859],
                [106.7835019, 16.4717364],
                [106.7839492, 16.4720087],
                [106.7861845, 16.4728992],
                [106.7893436, 16.4737177],
                [106.7918605, 16.4745135],
                [106.7931469, 16.4747843],
                [106.7939059, 16.4748061],
                [106.7966754, 16.475333],
                [106.799176, 16.4701898],
                [106.8010519, 16.4657142],
                [106.8017992, 16.4637192],
                [106.801944, 16.4636052],
                [106.802177, 16.4635469],
                [106.8027415, 16.4646411],
                [106.8034017, 16.466608],
                [106.8043306, 16.4692492],
                [106.804954, 16.4703992],
                [106.8053162, 16.4716925],
                [106.8054776, 16.4733538],
                [106.8054325, 16.4747914],
                [106.8054377, 16.4753551],
                [106.8061, 16.4772799],
                [106.8067226, 16.4784834],
                [106.8086602, 16.4817596],
                [106.8152518, 16.4927709],
                [106.8155343, 16.4933321],
                [106.8182738, 16.4960418],
                [106.8202105, 16.4989912],
                [106.819444, 16.5000548],
                [106.8189672, 16.5010354],
                [106.8189047, 16.5013783],
                [106.8189105, 16.5019997],
                [106.818998, 16.5029468],
                [106.8190951, 16.5034913],
                [106.8194711, 16.5044115],
                [106.8200549, 16.5050406],
                [106.8205093, 16.505528],
                [106.8211104, 16.5065785],
                [106.8223056, 16.5079948],
                [106.8228147, 16.5087037],
                [106.8229719, 16.5097011],
                [106.8230703, 16.5107274],
                [106.8234151, 16.5128877],
                [106.8236556, 16.5133136],
                [106.8244857, 16.5138723],
                [106.8250794, 16.5141525],
                [106.8255842, 16.5144049],
                [106.8259133, 16.5148299],
                [106.8262139, 16.5153695],
                [106.8266654, 16.5163571],
                [106.8269694, 16.5172676],
                [106.8270082, 16.5182373],
                [106.8272523, 16.5190627],
                [106.8277663, 16.5203895],
                [106.8283069, 16.5212979],
                [106.8285493, 16.5219235],
                [106.8284375, 16.5226093],
                [106.8279735, 16.523555],
                [106.8270936, 16.524276],
                [106.8264773, 16.5247379],
                [106.8258012, 16.5251433],
                [106.8254498, 16.5254888],
                [106.8251694, 16.5260298],
                [106.8247528, 16.5266643],
                [106.8242666, 16.5276996],
                [106.8241273, 16.528468],
                [106.8240922, 16.5289358],
                [106.8241265, 16.5294035],
                [106.8243682, 16.5304062],
                [106.8245062, 16.5311748],
                [106.8252331, 16.5322446],
                [106.8265492, 16.5337493],
                [106.8276356, 16.5350422],
                [106.8278804, 16.5359246],
                [106.8281214, 16.5364076],
                [106.8283003, 16.5365773],
                [106.8288052, 16.5369406],
                [106.8291933, 16.5373366],
                [106.8291951, 16.5375363],
                [106.8291414, 16.5381075],
                [106.8292367, 16.53882],
                [106.8294776, 16.5392744],
                [106.8304672, 16.540778],
                [106.8306184, 16.5411476],
                [106.8306811, 16.5415181],
                [106.8306559, 16.5424463],
                [106.8303719, 16.5436758],
                [106.8299696, 16.5449063],
                [106.8298303, 16.545792],
                [106.8298059, 16.5463343],
                [106.829931, 16.5470751],
                [106.8305349, 16.548411],
                [106.8307745, 16.5487512],
                [106.8311004, 16.548973],
                [106.8320796, 16.5493639],
                [106.8333524, 16.5495524],
                [106.8346828, 16.5495692],
                [106.8368236, 16.5494626],
                [106.8384312, 16.5491631],
                [106.8409418, 16.548715],
                [106.8418472, 16.5483844],
                [106.8431404, 16.5479123],
                [106.8447547, 16.5470555],
                [106.8455844, 16.5465037],
                [106.848751, 16.5448579],
                [106.8508321, 16.5435908],
                [106.8517166, 16.5433547],
                [106.8541956, 16.5429332],
                [106.8552489, 16.5426167],
                [106.8562726, 16.5423433],
                [106.8575238, 16.541988],
                [106.8591162, 16.5415507],
                [106.8599409, 16.541332],
                [106.8614193, 16.5411137],
                [106.8626016, 16.5410322],
                [106.8627787, 16.541002],
                [106.8628953, 16.5408299],
                [106.8627947, 16.5395753],
                [106.8630217, 16.5386031],
                [106.8633396, 16.5378299],
                [106.863913, 16.5369234],
                [106.8648497, 16.5359735],
                [106.866993, 16.5344706],
                [106.8679283, 16.5340926],
                [106.8690194, 16.533826],
                [106.87058, 16.5332129],
                [106.8719662, 16.5328865],
                [106.8725265, 16.5327674],
                [106.8740053, 16.5328682],
                [106.8742412, 16.5328091],
                [106.8752955, 16.5321171],
                [106.8759687, 16.5314262],
                [106.8762336, 16.5313098],
                [106.8765575, 16.5311927],
                [106.8767034, 16.5309917],
                [106.8768453, 16.5303912],
                [106.8771003, 16.5292476],
                [106.8774983, 16.5276176],
                [106.8777853, 16.5267305],
                [106.878139, 16.5260988],
                [106.8787791, 16.5250658],
                [106.8795705, 16.5243738],
                [106.8803299, 16.5238847],
                [106.8813594, 16.5233903],
                [106.8830968, 16.5227184],
                [106.8852133, 16.5220465],
                [106.8860402, 16.5219819],
                [106.8865705, 16.521806],
                [106.8868044, 16.521547],
                [106.8869776, 16.5211175],
                [106.8869712, 16.5204614],
                [106.8870526, 16.5197187],
                [106.8876055, 16.5188292],
                [106.8881283, 16.5178829],
                [106.8889923, 16.5157191],
                [106.8892783, 16.5147464],
                [106.8896484, 16.5132878],
                [106.8904286, 16.5114867],
                [106.8904268, 16.511287],
                [106.8899523, 16.51112],
                [106.8892421, 16.5110409],
                [106.8883501, 16.5104782],
                [106.8873672, 16.5096882],
                [106.8871566, 16.5092699],
                [106.8870931, 16.508814],
                [106.8871954, 16.5072193],
                [106.8871891, 16.5065631],
                [106.8871555, 16.5061639],
                [106.8871227, 16.5058218],
                [106.8873853, 16.505477],
                [106.8875866, 16.5049045],
                [106.8876104, 16.5043052],
                [106.8877266, 16.5041044],
                [106.8881586, 16.502846],
                [106.8882066, 16.5021568],
                [106.8882548, 16.5012837],
                [106.8880649, 16.4998132],
                [106.8876366, 16.4986642],
                [106.8873329, 16.4978111],
                [106.8870327, 16.4969403],
                [106.8867873, 16.4957166],
                [106.8866893, 16.4946325],
                [106.8866688, 16.4925192],
                [106.886659, 16.4915048],
                [106.8866242, 16.4909415],
                [106.8864157, 16.4904924],
                [106.8860027, 16.4900453],
                [106.8843553, 16.4886794],
                [106.8832391, 16.4879285],
                [106.8816827, 16.486928],
                [106.8802418, 16.4857856],
                [106.8790651, 16.4848098],
                [106.878592, 16.4841659],
                [106.8781761, 16.4834087],
                [106.8778498, 16.4828481],
                [106.8778449, 16.4823409],
                [106.8778962, 16.4816078],
                [106.8783492, 16.4801664],
                [106.8787762, 16.4790635],
                [106.879062, 16.478441],
                [106.8790964, 16.4783147],
                [106.8791466, 16.4781302],
                [106.8790565, 16.4778775],
                [106.8785864, 16.4775435],
                [106.8775881, 16.4769043],
                [106.8770294, 16.4764584],
                [106.8767627, 16.4760381],
                [106.8766109, 16.4754195],
                [106.8766292, 16.4742921],
                [106.8766728, 16.4727417],
                [106.8766608, 16.4715018],
                [106.8767961, 16.4704016],
                [106.8770251, 16.4699487],
                [106.8773709, 16.4693197],
                [106.8778722, 16.4687564],
                [106.8784986, 16.4684349],
                [106.8801267, 16.468315],
                [106.8817549, 16.4682354],
                [106.88259, 16.4679542],
                [106.8833107, 16.4675733],
                [106.8835663, 16.4674383],
                [106.8840282, 16.4669269],
                [106.8845741, 16.4660483],
                [106.8852038, 16.4647745],
                [106.8859798, 16.4635558],
                [106.8864662, 16.462565],
                [106.8868387, 16.4618572],
                [106.887151, 16.4609527],
                [106.8872594, 16.4601063],
                [106.8872534, 16.4594863],
                [106.8870685, 16.4584735],
                [106.8865863, 16.4568715],
                [106.8864325, 16.4560556],
                [106.8864256, 16.4553512],
                [106.8865652, 16.4547018],
                [106.8868507, 16.4540511],
                [106.8879198, 16.4529707],
                [106.8881867, 16.4517411],
                [106.888187, 16.4511632],
                [106.887908, 16.4502],
                [106.887509, 16.4493137],
                [106.8865364, 16.4486996],
                [106.8846652, 16.4482937],
                [106.8834063, 16.4478542],
                [106.8823204, 16.4471875],
                [106.8816157, 16.4467148],
                [106.8803497, 16.4455427],
                [106.8795555, 16.4448733],
                [106.8790246, 16.4442582],
                [106.877895, 16.4420984],
                [106.8765288, 16.4395743],
                [106.8760261, 16.4388743],
                [106.8751403, 16.4377832],
                [106.8741098, 16.4368061],
                [106.8735517, 16.4364165],
                [106.8724402, 16.4361165],
                [106.8717674, 16.4359252],
                [106.8715618, 16.4357861],
                [106.8712917, 16.4349995],
                [106.8713123, 16.4340976],
                [106.8714368, 16.4318702],
                [106.8716448, 16.4292192],
                [106.8719283, 16.4283713],
                [106.87256, 16.4272948],
                [106.8733975, 16.4264137],
                [106.8740643, 16.4259851],
                [106.8766254, 16.4253422],
                [106.878399, 16.4247345],
                [106.8819478, 16.4236601],
                [106.8843595, 16.4226521],
                [106.8854047, 16.4221355],
                [106.8861876, 16.4216493],
                [106.88674, 16.4214471],
                [106.8872359, 16.4214426],
                [106.8877921, 16.4216349],
                [106.8884415, 16.4224181],
                [106.8898366, 16.4249136],
                [106.8904879, 16.4258942],
                [106.8910173, 16.4263402],
                [106.8915449, 16.4265891],
                [106.8919827, 16.4266134],
                [106.892565, 16.4264954],
                [106.894193, 16.425917],
                [106.8955616, 16.4256511],
                [106.8966118, 16.4256415],
                [106.8971665, 16.4256929],
                [106.8981897, 16.4259091],
                [106.898656, 16.4256887],
                [106.8988718, 16.4250655],
                [106.8988185, 16.4239225],
                [106.8979037, 16.4221039],
                [106.8965578, 16.4208045],
                [106.8951039, 16.4197128],
                [106.8944044, 16.418206],
                [106.8942437, 16.4166474],
                [106.8943522, 16.4155047],
                [106.8945681, 16.4144137],
                [106.8946762, 16.4136346],
                [106.8947843, 16.4130112],
                [106.8947307, 16.4124917],
                [106.8944616, 16.412076],
                [106.8934923, 16.4116598],
                [106.8925768, 16.4113477],
                [106.892146, 16.4109838],
                [106.8918231, 16.4105162],
                [106.891716, 16.4095291],
                [106.8918779, 16.4088019],
                [106.8922341, 16.4073637],
                [106.8937042, 16.405547],
                [106.8947399, 16.4040722],
                [106.8957527, 16.4021655],
                [106.8958359, 16.4017139],
                [106.895842, 16.4003988],
                [106.8958352, 16.3996943],
                [106.8959731, 16.3988758],
                [106.8963988, 16.3976602],
                [106.8964231, 16.3971528],
                [106.8963863, 16.396364],
                [106.8964415, 16.3960253],
                [106.89673, 16.3957128],
                [106.8970176, 16.3952875],
                [106.8972994, 16.3942704],
                [106.8973166, 16.3930303],
                [106.8977399, 16.3910028],
                [106.8981546, 16.3902609],
                [106.899339, 16.3888914],
                [106.9007007, 16.3878645],
                [106.9020624, 16.3872372],
                [106.9029504, 16.3864955],
                [106.9041345, 16.3854684],
                [106.9063252, 16.3834715],
                [106.9084564, 16.3821023],
                [106.9110016, 16.3813612],
                [106.9124812, 16.3811335],
                [106.9140522, 16.3803947],
                [106.9146601, 16.3799383],
                [106.9149199, 16.379654],
                [106.9150025, 16.379146],
                [106.915024, 16.3783568],
                [106.915338, 16.3776777],
                [106.9162925, 16.3768518],
                [106.9170675, 16.3755766],
                [106.9177855, 16.3744429],
                [106.9189429, 16.3735023],
                [106.9201878, 16.372561],
                [106.9219526, 16.3711076],
                [106.922586, 16.3703492],
                [106.9232357, 16.3693375],
                [106.9235856, 16.368952],
                [106.9245351, 16.368374],
                [106.9258842, 16.3676516],
                [106.9288789, 16.3666482],
                [106.9323989, 16.3657424],
                [106.9391185, 16.3640179],
                [106.9417371, 16.363402],
                [106.9428697, 16.3629126],
                [106.9445225, 16.3619673],
                [106.9462298, 16.3612207],
                [106.9519935, 16.3586491],
                [106.9586538, 16.3565308],
                [106.9640352, 16.3547637],
                [106.9641632, 16.3546556],
                [106.9645387, 16.3542857],
                [106.9648848, 16.3539162],
                [106.9651669, 16.3529554],
                [106.9654525, 16.352361],
                [106.965741, 16.3520484],
                [106.9661467, 16.3517909],
                [106.9663197, 16.3515921],
                [106.9664918, 16.3513086],
                [106.9663982, 16.3506895],
                [106.9663953, 16.3504078],
                [106.9665657, 16.3499554],
                [106.9669383, 16.3493036],
                [106.9671371, 16.3487665],
                [106.9673596, 16.3476936],
                [106.9674408, 16.3470729],
                [106.9674348, 16.3464811],
                [106.9672826, 16.3458343],
                [106.9670431, 16.3452167],
                [106.9668634, 16.3447393],
                [106.9667734, 16.3444865],
                [106.9668558, 16.3439785],
                [106.9669651, 16.3432449],
                [106.9669614, 16.3428785],
                [106.96678, 16.3422321],
                [106.966747, 16.3418378],
                [106.9668571, 16.3411887],
                [106.9667939, 16.3407102],
                [106.9665559, 16.3402334],
                [106.9660829, 16.3395897],
                [106.9659608, 16.3390553],
                [106.9660147, 16.338604],
                [106.9661, 16.3383778],
                [106.9660688, 16.3381809],
                [106.9660088, 16.3380123],
                [106.9657434, 16.3377048],
                [106.9656244, 16.3374805],
                [106.9656229, 16.3373114],
                [106.965738, 16.3371694],
                [106.9663188, 16.3369385],
                [106.9671893, 16.3365077],
                [106.9676237, 16.3362219],
                [106.9677959, 16.3359385],
                [106.9678222, 16.3356564],
                [106.9676913, 16.3342205],
                [106.967325, 16.3325895],
                [106.9672568, 16.3316038],
                [106.9671634, 16.331013],
                [106.9666291, 16.3300598],
                [106.9664199, 16.3295545],
                [106.9661128, 16.3280075],
                [106.967337, 16.3261774],
                [106.968245, 16.3244107],
                [106.9688556, 16.3234676],
                [106.9681158, 16.3221007],
                [106.9671173, 16.3202683],
                [106.9683455, 16.3189309],
                [106.9666457, 16.3167416],
                [106.9660074, 16.3153139],
                [106.9659081, 16.3137014],
                [106.9660369, 16.3130991],
                [106.9661549, 16.3128442],
                [106.9663637, 16.3126149],
                [106.9664927, 16.3125249],
                [106.968403, 16.3119792],
                [106.969459, 16.3112021],
                [106.969401, 16.3101154],
                [106.9687681, 16.3081035],
                [106.9684078, 16.3068131],
                [106.968909, 16.3057378],
                [106.9700562, 16.3057828],
                [106.9711154, 16.3051908],
                [106.9711449, 16.3035757],
                [106.9719556, 16.3028284],
                [106.9741699, 16.302796],
                [106.9763915, 16.3033952],
                [106.9772691, 16.3036969],
                [106.978029, 16.303887],
                [106.9785845, 16.3040228],
                [106.9795463, 16.3040137],
                [106.980685, 16.3041721],
                [106.9815909, 16.3043891],
                [106.9824079, 16.304466],
                [106.9830521, 16.3047417],
                [106.9845173, 16.3054887],
                [106.9848419, 16.3058802],
                [106.9852231, 16.3061021],
                [106.9857192, 16.3061538],
                [106.9863317, 16.3061762],
                [106.9870037, 16.3063107],
                [106.9875609, 16.3066437],
                [106.9887398, 16.3079007],
                [106.9895331, 16.3085132],
                [106.9903821, 16.3088715],
                [106.9910249, 16.3090063],
                [106.9917826, 16.308971],
                [106.9927709, 16.3086799],
                [106.9937849, 16.3080503],
                [106.9943654, 16.3077913],
                [106.994977, 16.3077291],
                [106.9953568, 16.3078101],
                [106.99571, 16.3081449],
                [106.996153, 16.3087043],
                [106.9969161, 16.3092043],
                [106.998121, 16.3101511],
                [106.9994114, 16.3108997],
                [106.9996769, 16.3112072],
                [106.9999721, 16.3115707],
                [107.000207, 16.3117375],
                [107.0007038, 16.3118455],
                [107.0018275, 16.3119115],
                [107.0028409, 16.3123],
                [107.004443, 16.3132565],
                [107.0060344, 16.3131605],
                [107.0077908, 16.3128198],
                [107.0102079, 16.311501],
                [107.0131244, 16.3098536],
                [107.0154559, 16.3083736],
                [107.015781, 16.3073987],
                [107.0151779, 16.3057848],
                [107.0151655, 16.3045701],
                [107.0152451, 16.3041645],
                [107.0157412, 16.3035119],
                [107.0178296, 16.3028441],
                [107.0200921, 16.3028225],
                [107.0218268, 16.3040613],
                [107.0239812, 16.3077519],
                [107.0252453, 16.3098117],
                [107.0270098, 16.3105877],
                [107.0277119, 16.3123378],
                [107.0287206, 16.3119635],
                [107.0303341, 16.312661],
                [107.0319931, 16.3112025],
                [107.0347443, 16.3097087],
                [107.0365287, 16.3082009],
                [107.0390053, 16.3075856],
                [107.0406891, 16.3063785],
                [107.0438746, 16.3041469],
                [107.0462688, 16.3023827],
                [107.0475357, 16.3024968],
                [107.048994, 16.3021941],
                [107.0502534, 16.3032495],
                [107.0511387, 16.3043898],
                [107.051999, 16.3046971],
                [107.0544192, 16.3089019],
                [107.0573089, 16.30823],
                [107.0616376, 16.3080396],
                [107.0631507, 16.3070458],
                [107.06917, 16.3069217],
                [107.0722307, 16.3062614],
                [107.0756834, 16.306831],
                [107.0772228, 16.3095079],
                [107.0786799, 16.3106055],
                [107.0813316, 16.3124426],
                [107.0821738, 16.3136319],
                [107.0821792, 16.3140696],
                [107.0821892, 16.3141755],
                [107.0822295, 16.3143844],
                [107.0822596, 16.3144866],
                [107.0825762, 16.3150169],
                [107.0825869, 16.3165305],
                [107.0826183, 16.3166626],
                [107.0826747, 16.3168469],
                [107.0826887, 16.3169591],
                [107.0826727, 16.3170711],
                [107.0826556, 16.3171201],
                [107.0826043, 16.3172108],
                [107.0825708, 16.3172513],
                [107.0823616, 16.317416],
                [107.082317, 16.3174597],
                [107.082279, 16.3175089],
                [107.0822258, 16.3176198],
                [107.082206, 16.3177404],
                [107.0822159, 16.3178405],
                [107.0822489, 16.317936],
                [107.0823237, 16.3180832],
                [107.0823689, 16.3182412],
                [107.082383, 16.3184045],
                [107.0823688, 16.3185448],
                [107.0823276, 16.31868],
                [107.082256, 16.3188125],
                [107.0822075, 16.3188763],
                [107.0820002, 16.3190774],
                [107.0819324, 16.3191561],
                [107.0818219, 16.3193146],
                [107.0817339, 16.3194856],
                [107.0816685, 16.319694],
                [107.0816266, 16.3199078],
                [107.0816137, 16.3200292],
                [107.0816103, 16.3203172],
                [107.0816038, 16.3203607],
                [107.081573, 16.3204432],
                [107.081511, 16.3205239],
                [107.0811814, 16.3207521],
                [107.0810605, 16.3208232],
                [107.0809451, 16.3209022],
                [107.0808357, 16.3209887],
                [107.0807328, 16.3210822],
                [107.0806369, 16.3211824],
                [107.0805484, 16.3212888],
                [107.0803541, 16.321565],
                [107.0802866, 16.3216395],
                [107.080133, 16.3217705],
                [107.0799583, 16.3218744],
                [107.0797716, 16.321947],
                [107.0796741, 16.3219718],
                [107.0794744, 16.3219973],
                [107.0782812, 16.321993],
                [107.0781976, 16.3220003],
                [107.078115, 16.3220146],
                [107.0779554, 16.3220638],
                [107.0778796, 16.3220984],
                [107.0777516, 16.3221766],
                [107.0776382, 16.3222734],
                [107.0775174, 16.3224287],
                [107.07742, 16.3225985],
                [107.0773136, 16.3228484],
                [107.0772683, 16.3229233],
                [107.0771564, 16.323059],
                [107.0770197, 16.3231717],
                [107.0769435, 16.3232181],
                [107.076737, 16.3233236],
                [107.0765439, 16.3234575],
                [107.075474, 16.3243686],
                [107.0753165, 16.3245213],
                [107.075245, 16.3246039],
                [107.0751271, 16.3247658],
                [107.0750677, 16.3248357],
                [107.0750011, 16.3248992],
                [107.074928, 16.3249558],
                [107.0748492, 16.3250048],
                [107.0747656, 16.3250457],
                [107.0746805, 16.3250773],
                [107.0745025, 16.325115],
                [107.0743203, 16.3251178],
                [107.0742024, 16.3251312],
                [107.0740859, 16.3251536],
                [107.0738716, 16.3252199],
                [107.0736807, 16.3253102],
                [107.0728826, 16.325769],
                [107.0728013, 16.3258078],
                [107.0726305, 16.3258668],
                [107.0724696, 16.3258977],
                [107.0722496, 16.3259003],
                [107.0721533, 16.3259222],
                [107.0719358, 16.3259926],
                [107.0718715, 16.3260032],
                [107.0718004, 16.3260072],
                [107.0713554, 16.3259941],
                [107.0712794, 16.3259994],
                [107.0711304, 16.3260298],
                [107.0710587, 16.3260547],
                [107.0709283, 16.3261201],
                [107.0708682, 16.3261609],
                [107.0707544, 16.3262563],
                [107.0706264, 16.3263398],
                [107.070557, 16.3263728],
                [107.0703295, 16.3264508],
                [107.0702572, 16.3264884],
                [107.070129, 16.3265844],
                [107.0700264, 16.3267035],
                [107.0699859, 16.3267703],
                [107.0699616, 16.326838],
                [107.0698942, 16.3269656],
                [107.0698035, 16.3270792],
                [107.06968, 16.3271837],
                [107.0695371, 16.327262],
                [107.0694158, 16.3273037],
                [107.0693748, 16.3273245],
                [107.0693075, 16.3273855],
                [107.069267, 16.3274653],
                [107.0692581, 16.3275147],
                [107.0692241, 16.3276095],
                [107.0691519, 16.327716],
                [107.0689215, 16.3279205],
                [107.0687967, 16.328047],
                [107.0685669, 16.3283165],
                [107.0684243, 16.3285147],
                [107.0683786, 16.328565],
                [107.0683263, 16.3286089],
                [107.0682682, 16.3286455],
                [107.0682055, 16.3286742],
                [107.0681392, 16.3286945],
                [107.0680708, 16.328706],
                [107.0679423, 16.3287033],
                [107.0678794, 16.3286904],
                [107.0676977, 16.3286229],
                [107.067568, 16.3286097],
                [107.0674394, 16.3286311],
                [107.0673286, 16.3286816],
                [107.0672823, 16.3287144],
                [107.0672038, 16.3287949],
                [107.067077, 16.329001],
                [107.0669872, 16.3290972],
                [107.0668834, 16.3291758],
                [107.0667672, 16.3292362],
                [107.0666541, 16.3293077],
                [107.0665466, 16.3293868],
                [107.0663701, 16.3295459],
                [107.0662331, 16.3297031],
                [107.0660902, 16.3299097],
                [107.065984, 16.3300136],
                [107.0659173, 16.3300608],
                [107.0658455, 16.3301004],
                [107.0656884, 16.3301615],
                [107.0656114, 16.3301995],
                [107.0655392, 16.3302455],
                [107.0654729, 16.330299],
                [107.0654132, 16.3303593],
                [107.0653609, 16.3304255],
                [107.0653167, 16.330497],
                [107.065281, 16.3305728],
                [107.0652544, 16.3306519],
                [107.0652303, 16.3308019],
                [107.0652303, 16.3308778],
                [107.0652544, 16.3310277],
                [107.0652521, 16.3311344],
                [107.0652151, 16.3312351],
                [107.0651847, 16.33128],
                [107.0651057, 16.331352],
                [107.0650074, 16.3313975],
                [107.064954, 16.3314087],
                [107.0647227, 16.3314867],
                [107.0642694, 16.3316515],
                [107.0640475, 16.3317382],
                [107.0638585, 16.3318299],
                [107.0637587, 16.3318642],
                [107.0635521, 16.3319086],
                [107.0633053, 16.3319299],
                [107.0631705, 16.3319723],
                [107.0630494, 16.3320432],
                [107.062996, 16.3320882],
                [107.0629111, 16.3321887],
                [107.062851, 16.3323046],
                [107.0628188, 16.3324303],
                [107.0628136, 16.3324949],
                [107.0628339, 16.3331798],
                [107.0628294, 16.3332984],
                [107.0627989, 16.3335137],
                [107.0627756, 16.3336098],
                [107.0627117, 16.3337973],
                [107.0626956, 16.3339218],
                [107.0627037, 16.334047],
                [107.0627185, 16.3341154],
                [107.0627406, 16.334182],
                [107.0628056, 16.334307],
                [107.0628361, 16.3343882],
                [107.0628593, 16.3344717],
                [107.062883, 16.3346427],
                [107.062876, 16.3348156],
                [107.0628609, 16.3349012],
                [107.0628382, 16.3349852],
                [107.0627707, 16.3351461],
                [107.0627555, 16.3352123],
                [107.0627484, 16.3352798],
                [107.0627588, 16.3354149],
                [107.0627986, 16.3355384],
                [107.0628619, 16.3356454],
                [107.0631945, 16.3359543],
                [107.0636451, 16.3363558],
                [107.063683, 16.3364211],
                [107.0637332, 16.3365624],
                [107.0637448, 16.3366363],
                [107.0637406, 16.3367819],
                [107.0637258, 16.3368516],
                [107.0636719, 16.3369839],
                [107.0634745, 16.337269],
                [107.0632688, 16.3375488],
                [107.0629838, 16.3379109],
                [107.0628624, 16.3381004],
                [107.062771, 16.338305],
                [107.0627371, 16.3384115],
                [107.0626942, 16.3386302],
                [107.0626853, 16.3387412],
                [107.0626913, 16.338948],
                [107.062729, 16.3392325],
                [107.0627975, 16.3396092],
                [107.0628943, 16.3399954],
                [107.0629522, 16.3401861],
                [107.0630872, 16.3405616],
                [107.063104, 16.3406537],
                [107.0631131, 16.3408404],
                [107.0631053, 16.3409337],
                [107.0630654, 16.3411166],
                [107.0630336, 16.341205],
                [107.062949, 16.3413697],
                [107.0628964, 16.3414465],
                [107.0627723, 16.3415862],
                [107.0626259, 16.3417044],
                [107.0620139, 16.3419673],
                [107.0619584, 16.3420004],
                [107.0619077, 16.34204],
                [107.0618238, 16.342136],
                [107.0617921, 16.3421909],
                [107.0617514, 16.3423099],
                [107.0617432, 16.3423721],
                [107.0617515, 16.3424971],
                [107.0617694, 16.3425622],
                [107.0617964, 16.3426244],
                [107.0618756, 16.342764],
                [107.0619428, 16.3429355],
                [107.0619821, 16.3431148],
                [107.062009, 16.3433671],
                [107.0620387, 16.343527],
                [107.0620781, 16.3436851],
                [107.062127, 16.3438406],
                [107.062133, 16.3440651],
                [107.0620941, 16.3441863],
                [107.0620275, 16.3442892],
                [107.0619868, 16.3443319],
                [107.0618909, 16.3444017],
                [107.0611319, 16.3445453],
                [107.0608688, 16.3446254],
                [107.0606169, 16.3447338],
                [107.0604531, 16.3448232],
                [107.0603824, 16.3448918],
                [107.0603385, 16.3449785],
                [107.0603259, 16.3450742],
                [107.060346, 16.3451687],
                [107.0603994, 16.3452551],
                [107.0604798, 16.3453194],
                [107.060578, 16.3453542],
                [107.0610648, 16.3454004],
                [107.0611998, 16.3454319],
                [107.0612638, 16.3454579],
                [107.0613813, 16.3455291],
                [107.0614302, 16.3455704],
                [107.0615138, 16.3456661],
                [107.0615476, 16.3457195],
                [107.0619875, 16.3469446],
                [107.0620069, 16.3470265],
                [107.0620168, 16.3471099],
                [107.0620171, 16.3471939],
                [107.0620078, 16.3472774],
                [107.0619889, 16.3473593],
                [107.061928, 16.3475068],
                [107.0618885, 16.3475714],
                [107.0617907, 16.3476878],
                [107.061671, 16.3477837],
                [107.0608878, 16.3480076],
                [107.0607671, 16.3477631],
                [107.0606558, 16.3474787],
                [107.0604944, 16.3474114],
                [107.0604097, 16.3473881],
                [107.0602356, 16.3473631],
                [107.0601475, 16.3473616],
                [107.0598983, 16.3473895],
                [107.059654, 16.347444],
                [107.0595241, 16.3474845],
                [107.0591868, 16.3476189],
                [107.0590975, 16.3476406],
                [107.0590064, 16.3476531],
                [107.0589144, 16.3476562],
                [107.0588225, 16.3476499],
                [107.0585269, 16.3475971],
                [107.0583773, 16.3475829],
                [107.0582186, 16.3475769],
                [107.0580598, 16.3475801],
                [107.0579015, 16.3475924],
                [107.0577443, 16.3476138],
                [107.0573471, 16.3476988],
                [107.056945, 16.347758],
                [107.0567603, 16.3477763],
                [107.0563897, 16.3477966],
                [107.0559462, 16.3475654],
                [107.0557192, 16.3474594],
                [107.0552685, 16.3472718],
                [107.0548072, 16.3471094],
                [107.0533025, 16.3461674],
                [107.0527234, 16.3471673],
                [107.0525515, 16.3474251],
                [107.0523469, 16.3476688],
                [107.0522319, 16.3477846],
                [107.0521102, 16.3478941],
                [107.0519824, 16.3479969],
                [107.0518488, 16.3480926],
                [107.0518357, 16.3483861],
                [107.0518179, 16.3485321],
                [107.05176, 16.3488206],
                [107.0516908, 16.3490519],
                [107.051654, 16.3491387],
                [107.0515591, 16.3493022],
                [107.0514344, 16.3494522],
                [107.0512855, 16.3495802],
                [107.0511819, 16.3496271],
                [107.0509652, 16.3496981],
                [107.0508534, 16.3497218],
                [107.0506257, 16.3497449],
                [107.0503864, 16.3497341],
                [107.0502682, 16.3497154],
                [107.050038, 16.3496517],
                [107.0499276, 16.3496071],
                [107.0496618, 16.3494771],
                [107.0494986, 16.3494079],
                [107.049162, 16.3492946],
                [107.0489895, 16.3492507],
                [107.0488093, 16.3492147],
                [107.0486272, 16.349188],
                [107.0484439, 16.3491709],
                [107.04826, 16.3491632],
                [107.047906, 16.3489737],
                [107.0478214, 16.3489361],
                [107.0476427, 16.3488857],
                [107.0474572, 16.3488698],
                [107.0473641, 16.348875],
                [107.0471959, 16.3489073],
                [107.0471148, 16.3489343],
                [107.0469618, 16.3490088],
                [107.046769, 16.3490873],
                [107.0465726, 16.3491572],
                [107.0463732, 16.3492184],
                [107.046171, 16.3492708],
                [107.0459665, 16.3493142],
                [107.0455729, 16.3493717],
                [107.0451962, 16.3493957],
                [107.0448187, 16.3493897],
                [107.0446816, 16.3492551],
                [107.0445326, 16.3491406],
                [107.0443761, 16.3490448],
                [107.0442942, 16.3490029],
                [107.044124, 16.3489316],
                [107.0438719, 16.3491426],
                [107.04341, 16.3491756],
                [107.0429519, 16.3492404],
                [107.0425531, 16.3493236],
                [107.0420599, 16.3494566],
                [107.0419572, 16.3494731],
                [107.0418533, 16.3494804],
                [107.0417491, 16.3494783],
                [107.0416456, 16.3494669],
                [107.0415427, 16.349446],
                [107.0414423, 16.3494159],
                [107.0413452, 16.3493769],
                [107.0411548, 16.3493987],
                [107.0408946, 16.3502725],
                [107.0408578, 16.3504559],
                [107.0408507, 16.3506426],
                [107.0408736, 16.3508285],
                [107.0408962, 16.3509198],
                [107.0411086, 16.351474],
                [107.0412312, 16.3518592],
                [107.0412946, 16.3520971],
                [107.0414054, 16.3526256],
                [107.0414018, 16.3527215],
                [107.0413879, 16.3527677],
                [107.0413374, 16.3528505],
                [107.0413023, 16.3528848],
                [107.0412228, 16.3529325],
                [107.0411321, 16.3529548],
                [107.040838, 16.3529397],
                [107.0407747, 16.3529438],
                [107.0406512, 16.3529711],
                [107.0405313, 16.3530258],
                [107.040427, 16.3531046],
                [107.0403174, 16.3532235],
                [107.0402588, 16.3533],
                [107.0401617, 16.3534652],
                [107.0401271, 16.3535446],
                [107.0400766, 16.3537097],
                [107.0400638, 16.3538215],
                [107.0400175, 16.3540419],
                [107.0399438, 16.3542553],
                [107.0398304, 16.3545082],
                [107.0397824, 16.354695],
                [107.0397708, 16.3547903],
                [107.0397627, 16.3555565],
                [107.0397569, 16.355666],
                [107.03972, 16.3558823],
                [107.0396892, 16.3559878],
                [107.0396035, 16.3561909],
                [107.0395492, 16.3562874],
                [107.039419, 16.3564674],
                [107.0392625, 16.356627],
                [107.0391803, 16.3566943],
                [107.0390023, 16.3568116],
                [107.0389073, 16.3568609],
                [107.0387077, 16.35694],
                [107.038604, 16.3569693],
                [107.038518, 16.3569825],
                [107.0383439, 16.3569859],
                [107.0381722, 16.3569583],
                [107.038089, 16.3569332],
                [107.0379576, 16.3568757],
                [107.0377542, 16.3567485],
                [107.0375763, 16.3566691],
                [107.0373863, 16.3566218],
                [107.0371998, 16.356608],
                [107.0371063, 16.3566126],
                [107.0369223, 16.356645],
                [107.0355889, 16.3570581],
                [107.0353472, 16.3571193],
                [107.0350751, 16.3571613],
                [107.0349249, 16.3571728],
                [107.0346236, 16.3571713],
                [107.0346451, 16.3579292],
                [107.0345313, 16.3580111],
                [107.0344826, 16.358061],
                [107.0344048, 16.3581752],
                [107.0343557, 16.3583076],
                [107.0343436, 16.3583792],
                [107.0343474, 16.3585237],
                [107.0343854, 16.358639],
                [107.0344546, 16.3587399],
                [107.0346853, 16.3589201],
                [107.0347329, 16.3589694],
                [107.0348087, 16.3590822],
                [107.034855, 16.3592075],
                [107.0348704, 16.3593396],
                [107.0348983, 16.359456],
                [107.0349222, 16.3595112],
                [107.0349884, 16.3596124],
                [107.0350254, 16.3596917],
                [107.0350819, 16.3598568],
                [107.0351149, 16.3600352],
                [107.0351206, 16.3602238],
                [107.035097, 16.360411],
                [107.0347041, 16.361836],
                [107.0349723, 16.362729],
                [107.0351735, 16.3636195],
                [107.0362759, 16.364147],
                [107.0363122, 16.3641806],
                [107.0363411, 16.3642202],
                [107.0363614, 16.3642645],
                [107.0363724, 16.3643117],
                [107.0363688, 16.3643931],
                [107.0363567, 16.3644322],
                [107.0363134, 16.3645022],
                [107.0358628, 16.36495],
                [107.0358228, 16.3650019],
                [107.035788, 16.3650572],
                [107.0357353, 16.3651758],
                [107.0357053, 16.3653146],
                [107.0357017, 16.3653918],
                [107.0357215, 16.3655451],
                [107.0360532, 16.3667515],
                [107.0360894, 16.3668541],
                [107.0361849, 16.3670502],
                [107.0363104, 16.3672319],
                [107.0364637, 16.3673947],
                [107.0367049, 16.3675917],
                [107.0367633, 16.3676549],
                [107.036861, 16.3677955],
                [107.0369303, 16.3679507],
                [107.0371234, 16.3685169],
                [107.037743, 16.3694511],
                [107.0377962, 16.3695563],
                [107.0378423, 16.3696647],
                [107.0379077, 16.3698706],
                [107.0379952, 16.3702926],
                [107.0383676, 16.3699376],
                [107.0385624, 16.3697689],
                [107.038946, 16.3694665],
                [107.0389898, 16.3694387],
                [107.0390381, 16.369419],
                [107.0390893, 16.369408],
                [107.0391918, 16.3694128],
                [107.0392845, 16.3694507],
                [107.0393864, 16.3695363],
                [107.0394422, 16.369598],
                [107.039532, 16.3697365],
                [107.0396823, 16.3701073],
                [107.0398124, 16.3704823],
                [107.0399038, 16.3708455],
                [107.0399612, 16.3711933],
                [107.039978, 16.3713796],
                [107.0400047, 16.3715649],
                [107.0400413, 16.3717486],
                [107.0400876, 16.3719303],
                [107.0402011, 16.3722656],
                [107.040266, 16.3724191],
                [107.0404172, 16.3727168],
                [107.0405352, 16.3729211],
                [107.0406614, 16.3731209],
                [107.0407957, 16.3733157],
                [107.0409379, 16.3735054],
                [107.0412307, 16.3738523],
                [107.0415343, 16.3741626],
                [107.0418602, 16.3744513],
                [107.0422441, 16.3747631],
                [107.0424271, 16.3749287],
                [107.042774, 16.3752781],
                [107.043094, 16.3756505],
                [107.0432432, 16.3758443],
                [107.0435194, 16.3762466],
                [107.043765, 16.3766668],
                [107.0438759, 16.376883],
                [107.0440733, 16.3773259],
                [107.0442372, 16.3777813],
                [107.0443064, 16.3780129],
                [107.0449126, 16.3798091],
                [107.0449656, 16.3799175],
                [107.0450942, 16.3801227],
                [107.045251, 16.3803091],
                [107.0454329, 16.380473],
                [107.0456343, 16.38061],
                [107.0458531, 16.3807196],
                [107.0460855, 16.3807996],
                [107.0464069, 16.3808809],
                [107.0468042, 16.3810047],
                [107.0471924, 16.3811524],
                [107.0474157, 16.38125],
                [107.0476351, 16.3813556],
                [107.0480608, 16.3815898],
                [107.0484671, 16.3818537],
                [107.0486623, 16.3819964],
                [107.0487466, 16.3820664],
                [107.0489317, 16.3821855],
                [107.0491344, 16.3822743],
                [107.0492407, 16.3823066],
                [107.0494599, 16.3823459],
                [107.0496828, 16.3823506],
                [107.0497938, 16.3823399],
                [107.0500113, 16.3822928],
                [107.0501163, 16.3822567],
                [107.0517951, 16.3815538],
                [107.0521512, 16.3814744],
                [107.0525132, 16.3814255],
                [107.0526956, 16.3814125],
                [107.0530611, 16.3814097],
                [107.0532492, 16.3814203],
                [107.0536229, 16.381466],
                [107.0538078, 16.381501],
                [107.0540819, 16.381567],
                [107.0541716, 16.381595],
                [107.054292, 16.3816413],
                [107.0544083, 16.3816965],
                [107.0545197, 16.3817603],
                [107.0546256, 16.3818322],
                [107.0547253, 16.3819119],
                [107.0548183, 16.3819987],
                [107.0549039, 16.3820923],
                [107.0552087, 16.3824903],
                [107.0556468, 16.3830978],
                [107.055726, 16.3831941],
                [107.0559014, 16.3833726],
                [107.0560974, 16.3835301],
                [107.0562073, 16.3836035],
                [107.0564402, 16.3837302],
                [107.0566875, 16.3838286],
                [107.0582519, 16.3845677],
                [107.0584259, 16.3846891],
                [107.0585042, 16.3847601],
                [107.0586368, 16.3849154],
                [107.058738, 16.3850846],
                [107.0588094, 16.3852674],
                [107.0588332, 16.3853623],
                [107.0593965, 16.3871893],
                [107.0590817, 16.38733],
                [107.0587822, 16.3874987],
                [107.0585006, 16.3876936],
                [107.0583682, 16.3877998],
                [107.0581196, 16.3880294],
                [107.0579463, 16.388214],
                [107.0578945, 16.3882803],
                [107.0578073, 16.3884309],
                [107.0577503, 16.3885941],
                [107.0577338, 16.3886788],
                [107.0577253, 16.3888508],
                [107.0577496, 16.3890214],
                [107.0581573, 16.3904985],
                [107.0586079, 16.3909513],
                [107.0585972, 16.3917748],
                [107.058168, 16.3922688],
                [107.0581079, 16.3924346],
                [107.0580907, 16.392521],
                [107.0580821, 16.3926841],
                [107.0580885, 16.3927595],
                [107.0581209, 16.3929075],
                [107.0584577, 16.3937304],
                [107.0584439, 16.3938476],
                [107.0584425, 16.3940833],
                [107.0584759, 16.3943169],
                [107.0585436, 16.3945435],
                [107.0585851, 16.3946426],
                [107.0586876, 16.3948322],
                [107.0587483, 16.3949219],
                [107.0588869, 16.395089],
                [107.0589341, 16.395158],
                [107.0589733, 16.3952314],
                [107.059026, 16.3953882],
                [107.0590426, 16.3955522],
                [107.0590532, 16.3967667],
                [107.0575941, 16.3984083],
                [107.057254, 16.3985532],
                [107.0570791, 16.3986142],
                [107.0567894, 16.3986965],
                [107.0567126, 16.3987261],
                [107.0566387, 16.3987621],
                [107.0565023, 16.3988518],
                [107.0563817, 16.3989658],
                [107.0563287, 16.3990316],
                [107.0562427, 16.3991758],
                [107.0562104, 16.3992529],
                [107.0561684, 16.3994141],
                [107.0561591, 16.3994967],
                [107.056151, 16.400086],
                [107.0561841, 16.4002125],
                [107.0562476, 16.4003279],
                [107.056291, 16.4003806],
                [107.0563964, 16.4004688],
                [107.0564568, 16.4005028],
                [107.0579374, 16.4011255],
                [107.0582558, 16.401427],
                [107.0585972, 16.4017044],
                [107.0589259, 16.4019343],
                [107.0592704, 16.4021419],
                [107.0591237, 16.4023409],
                [107.0589993, 16.4025535],
                [107.0588969, 16.4027816],
                [107.0588546, 16.4029014],
                [107.0587905, 16.4031467],
                [107.0587689, 16.4032714],
                [107.0584524, 16.4047175],
                [107.0578194, 16.4054482],
                [107.0580232, 16.4067244],
                [107.0592838, 16.4080521],
                [107.060228, 16.4084483],
                [107.0603416, 16.4085198],
                [107.0605554, 16.4086805],
                [107.0607491, 16.4088633],
                [107.06092, 16.4090658],
                [107.0610677, 16.4092883],
                [107.0611881, 16.4095254],
                [107.0612795, 16.4097742],
                [107.0613408, 16.4100313],
                [107.0613599, 16.4101619],
                [107.0614135, 16.411469],
                [107.0621592, 16.4108412],
                [107.0622452, 16.4107876],
                [107.0623361, 16.410742],
                [107.0624311, 16.4107048],
                [107.0626183, 16.4106589],
                [107.0627992, 16.4106454],
                [107.0628899, 16.4106496],
                [107.0634896, 16.4107331],
                [107.0636333, 16.4107315],
                [107.0637044, 16.4107203],
                [107.063839, 16.4106784],
                [107.0639603, 16.410613],
                [107.0640154, 16.4105721],
                [107.0640661, 16.4105262],
                [107.0648414, 16.4096833],
                [107.0649703, 16.4096445],
                [107.0650302, 16.4096142],
                [107.0651364, 16.4095341],
                [107.0652153, 16.4094387],
                [107.0652699, 16.4093288],
                [107.0652974, 16.4092099],
                [107.0652974, 16.408577],
                [107.0655043, 16.4082892],
                [107.0656156, 16.4081507],
                [107.0658776, 16.4078601],
                [107.066189, 16.4075665],
                [107.0664839, 16.4073283],
                [107.0667309, 16.407112],
                [107.0669628, 16.4068747],
                [107.0671776, 16.4066172],
                [107.0672768, 16.4064826],
                [107.0673564, 16.4063105],
                [107.0674045, 16.4061217],
                [107.0674153, 16.4060219],
                [107.0674163, 16.4059216],
                [107.0674726, 16.4058171],
                [107.0675504, 16.4057261],
                [107.0676065, 16.4056793],
                [107.0676684, 16.4056397],
                [107.067735, 16.405608],
                [107.0678052, 16.4055846],
                [107.068116, 16.4055348],
                [107.0682693, 16.4054997],
                [107.0685442, 16.405418],
                [107.0688111, 16.4053144],
                [107.0689251, 16.4050976],
                [107.0690681, 16.4048972],
                [107.0692376, 16.4047168],
                [107.0694249, 16.4045637],
                [107.0696251, 16.4044384],
                [107.06984, 16.4043382],
                [107.069952, 16.404298],
                [107.0703096, 16.4041981],
                [107.0705416, 16.4041015],
                [107.0707583, 16.4039764],
                [107.0708645, 16.4039004],
                [107.071059, 16.4037281],
                [107.0711466, 16.4036328],
                [107.0714771, 16.4031901],
                [107.0715524, 16.4031091],
                [107.0716349, 16.4030348],
                [107.07181, 16.4029137],
                [107.0719945, 16.4028264],
                [107.0720916, 16.4027938],
                [107.0722926, 16.4027517],
                [107.0731294, 16.4027208],
                [107.0751679, 16.4023914],
                [107.0757879, 16.402048],
                [107.0765312, 16.4016623],
                [107.076965, 16.4014497],
                [107.0784398, 16.4007567],
                [107.0790048, 16.4004912],
                [107.079117, 16.400532],
                [107.0793184, 16.4005832],
                [107.0794936, 16.4006064],
                [107.0796111, 16.4006113],
                [107.079868, 16.4006091],
                [107.0806804, 16.4005465],
                [107.0809513, 16.4004847],
                [107.0812061, 16.4003612],
                [107.0819545, 16.3999135],
                [107.0820886, 16.3998582],
                [107.0821838, 16.3998389],
                [107.0822763, 16.399853],
                [107.0823957, 16.3998955],
                [107.0825003, 16.399983],
                [107.0825566, 16.4000769],
                [107.0825714, 16.4001991],
                [107.0825419, 16.400477],
                [107.0824614, 16.4007549],
                [107.0824145, 16.4009865],
                [107.0823796, 16.401101],
                [107.0823179, 16.4012399],
                [107.0822348, 16.401366],
                [107.082051, 16.4015551],
                [107.0817667, 16.4017777],
                [107.081701, 16.4019154],
                [107.0816822, 16.4020453],
                [107.0816863, 16.4023824],
                [107.0817211, 16.4024801],
                [107.0817788, 16.402556],
                [107.0818485, 16.4026268],
                [107.0819679, 16.402677],
                [107.082165, 16.4026744],
                [107.0823997, 16.4026525],
                [107.0829442, 16.4025123],
                [107.0831588, 16.4024891],
                [107.0833761, 16.4025252],
                [107.0835048, 16.4026075],
                [107.0836443, 16.4027619],
                [107.0837891, 16.4028417],
                [107.083879, 16.4028571],
                [107.0839822, 16.4028442],
                [107.0842263, 16.4027311],
                [107.0843175, 16.402713],
                [107.0844141, 16.4027285],
                [107.0846394, 16.4028686],
                [107.0848446, 16.4030198],
                [107.0850026, 16.4030991],
                [107.0851815, 16.4031123],
                [107.0853367, 16.4030938],
                [107.0854655, 16.4030269],
                [107.0858785, 16.40252],
                [107.085971, 16.402439],
                [107.0860542, 16.402412],
                [107.0861548, 16.4024055],
                [107.0862514, 16.4024647],
                [107.0865518, 16.4027104],
                [107.0867181, 16.402767],
                [107.0868415, 16.4027773],
                [107.0869702, 16.4027336],
                [107.0870721, 16.402659],
                [107.0873055, 16.4023399],
                [107.0874074, 16.4022704],
                [107.0875093, 16.4022576],
                [107.0875797, 16.4022769],
                [107.0876944, 16.4023592],
                [107.0878842, 16.4025393],
                [107.0879982, 16.4026094],
                [107.0881289, 16.4026583],
                [107.0882463, 16.4026725],
                [107.0883743, 16.4026699],
                [107.0885366, 16.4026422],
                [107.0888639, 16.4024415],
                [107.0889671, 16.4024017],
                [107.0890543, 16.4024017],
                [107.0892018, 16.4024248],
                [107.0893171, 16.402466],
                [107.0894083, 16.4025149],
                [107.0895331, 16.4026525],
                [107.0896068, 16.4027542],
                [107.0898174, 16.4031401],
                [107.0899072, 16.4032186],
                [107.0899837, 16.4032521],
                [107.0903324, 16.4032855],
                [107.0904477, 16.4033151],
                [107.0904973, 16.4033434],
                [107.0905697, 16.4034257],
                [107.0905858, 16.4034939],
                [107.0906207, 16.4039584],
                [107.0906743, 16.4040304],
                [107.0907696, 16.4040857],
                [107.0908527, 16.4040896],
                [107.0909385, 16.404078],
                [107.0910619, 16.4040381],
                [107.0915568, 16.403786],
                [107.0921952, 16.403436],
                [107.0923011, 16.4033614],
                [107.0923695, 16.4032829],
                [107.0924084, 16.4031633],
                [107.0924097, 16.4030475],
                [107.0923762, 16.4028841],
                [107.0923628, 16.4027683],
                [107.0923547, 16.4026487],
                [107.0923601, 16.4025612],
                [107.0924097, 16.4023811],
                [107.0926659, 16.4016503],
                [107.0927356, 16.401523],
                [107.0927946, 16.4014342],
                [107.0928697, 16.4013493],
                [107.0931688, 16.401065],
                [107.0932359, 16.4009762],
                [107.0932761, 16.4009067],
                [107.0933083, 16.4008218],
                [107.0933485, 16.4006558],
                [107.0934021, 16.4004834],
                [107.093441, 16.4003805],
                [107.0934665, 16.4003355],
                [107.0935349, 16.400266],
                [107.0937508, 16.4001296],
                [107.0938233, 16.4000666],
                [107.0938742, 16.4000036],
                [107.0939252, 16.3999174],
                [107.0939641, 16.3998106],
                [107.094011, 16.3995404],
                [107.0941438, 16.3986051],
                [107.0941827, 16.3984211],
                [107.094231, 16.3983259],
                [107.0942752, 16.3982577],
                [107.0943235, 16.3982191],
                [107.094412, 16.3981741],
                [107.0944897, 16.3981638],
                [107.0946723, 16.3981596],
                [107.0947687, 16.3981844],
                [107.09488, 16.3982281],
                [107.0950279, 16.3983277],
                [107.095127, 16.3984221],
                [107.0952281, 16.398595],
                [107.0952701, 16.398734],
                [107.0952785, 16.39886],
                [107.0952398, 16.3989937],
                [107.0950115, 16.3993371],
                [107.094952, 16.3995101],
                [107.0949632, 16.3997025],
                [107.0950222, 16.3998518],
                [107.0950276, 16.4000319],
                [107.0949981, 16.4001817],
                [107.0949216, 16.4002812],
                [107.0944914, 16.4007674],
                [107.0943025, 16.4010807],
                [107.0942218, 16.401227],
                [107.0941835, 16.4014381],
                [107.0941686, 16.4015992],
                [107.0941101, 16.4017093],
                [107.0940303, 16.401777],
                [107.0937723, 16.4019359],
                [107.0936452, 16.4020651],
                [107.0935609, 16.4022126],
                [107.0935437, 16.4023783],
                [107.0936543, 16.4025689],
                [107.0938123, 16.4027571],
                [107.0938647, 16.402887],
                [107.0938725, 16.4030059],
                [107.0938038, 16.4031072],
                [107.0936811, 16.4032656],
                [107.0936173, 16.4034402],
                [107.0935763, 16.4036543],
                [107.0935733, 16.40387],
                [107.0936241, 16.4040232],
                [107.0937642, 16.4041388],
                [107.0938935, 16.4041979],
                [107.0940691, 16.4042452],
                [107.0942446, 16.4042274],
                [107.0944713, 16.4040245],
                [107.0945915, 16.4039359],
                [107.0947817, 16.4038686],
                [107.0950579, 16.4038524],
                [107.0952225, 16.4038244],
                [107.0953526, 16.4037612],
                [107.0954314, 16.4036282],
                [107.095555, 16.40348],
                [107.0957548, 16.4030771],
                [107.0957871, 16.4028626],
                [107.095797, 16.4025333],
                [107.0960146, 16.4016632],
                [107.0962216, 16.4010385],
                [107.0963049, 16.4009098],
                [107.0964465, 16.4007709],
                [107.0966375, 16.4006971],
                [107.0969262, 16.4005466],
                [107.0970433, 16.4004218],
                [107.0971351, 16.4002378],
                [107.0972237, 16.3999349],
                [107.0973337, 16.3993493],
                [107.0974017, 16.3991216],
                [107.0975073, 16.3990078],
                [107.0977379, 16.3989156],
                [107.0982159, 16.3987953],
                [107.0983914, 16.3987074],
                [107.0985127, 16.3985453],
                [107.0987773, 16.3980609],
                [107.0991201, 16.3975139],
                [107.0993002, 16.3973198],
                [107.0995228, 16.3972029],
                [107.0996867, 16.3971031],
                [107.0997628, 16.3969656],
                [107.0997865, 16.3967718],
                [107.0997835, 16.3965414],
                [107.0997965, 16.3963575],
                [107.0998919, 16.3961507],
                [107.1000288, 16.3960041],
                [107.1001641, 16.3959612],
                [107.1006147, 16.3959924],
                [107.1008501, 16.3959946],
                [107.1010772, 16.3959208],
                [107.1012939, 16.3958242],
                [107.1020886, 16.3953757],
                [107.1021757, 16.3953025],
                [107.1022543, 16.3951991],
                [107.1023978, 16.3947491],
                [107.1024523, 16.3946563],
                [107.1026236, 16.3944225],
                [107.1028584, 16.394153],
                [107.1029361, 16.3940841],
                [107.1030138, 16.3940468],
                [107.1034828, 16.393968],
                [107.1038413, 16.3939452],
                [107.1039151, 16.3939246],
                [107.1039935, 16.3938837],
                [107.1041861, 16.3936466],
                [107.104312, 16.3935039],
                [107.1045226, 16.3933212],
                [107.1050076, 16.3929853],
                [107.1050664, 16.39293],
                [107.1051261, 16.3928475],
                [107.1051469, 16.3927821],
                [107.1051361, 16.392694],
                [107.1051001, 16.3926211],
                [107.1050403, 16.3925628],
                [107.1046661, 16.3923894],
                [107.1044837, 16.3923243],
                [107.1043722, 16.392236],
                [107.1042986, 16.3921414],
                [107.1042879, 16.392024],
                [107.1043188, 16.3919329],
                [107.1047781, 16.3913422],
                [107.104946, 16.3910475],
                [107.105053, 16.3909175],
                [107.1051955, 16.3908304],
                [107.1053648, 16.3908149],
                [107.1056152, 16.3908059],
                [107.1058415, 16.3907694],
                [107.10604, 16.3907273],
                [107.1062793, 16.3906108],
                [107.1066943, 16.3903413],
                [107.106981, 16.3900234],
                [107.1071786, 16.3897014],
                [107.1073235, 16.3892242],
                [107.1074659, 16.388815],
                [107.1075513, 16.3886369],
                [107.1076366, 16.3884835],
                [107.1077493, 16.3883421],
                [107.1083523, 16.3875244],
                [107.1084332, 16.3874208],
                [107.1084964, 16.3873618],
                [107.1086881, 16.3872484],
                [107.1089491, 16.387156],
                [107.1093191, 16.3869899],
                [107.1094256, 16.3869279],
                [107.1095166, 16.3868468],
                [107.1095552, 16.3868001],
                [107.1096004, 16.3866989],
                [107.1096391, 16.3865238],
                [107.1096759, 16.386243],
                [107.1100346, 16.3862464],
                [107.1106803, 16.3862526],
                [107.1108784, 16.3862262],
                [107.1110719, 16.386178],
                [107.1113044, 16.3860879],
                [107.111521, 16.3859665],
                [107.1116218, 16.3858949],
                [107.1117021, 16.3858523],
                [107.1118712, 16.3857843],
                [107.11198, 16.3857542],
                [107.1120912, 16.3857336],
                [107.1122038, 16.3857225],
                [107.1127993, 16.3857071],
                [107.1133013, 16.3856078],
                [107.1135541, 16.385567],
                [107.1140804, 16.3855013],
                [107.1146278, 16.3854601],
                [107.1149021, 16.3854498],
                [107.118153, 16.3854601],
                [107.11936, 16.3853108],
                [107.1194777, 16.3853046],
                [107.1195956, 16.3853061],
                [107.1198296, 16.3853324],
                [107.1200644, 16.3853915],
                [107.1202945, 16.3854849],
                [107.1204036, 16.3855433],
                [107.1207161, 16.3857465],
                [107.120939, 16.3858602],
                [107.1211895, 16.3859539],
                [107.1214661, 16.386021],
                [107.1216071, 16.3860417],
                [107.121892, 16.386057],
                [107.1231687, 16.3859953],
                [107.1232995, 16.3859722],
                [107.1235557, 16.3859039],
                [107.1238017, 16.385807],
                [107.1240434, 16.3856771],
                [107.1242751, 16.3855127],
                [107.124483, 16.3853211],
                [107.1248317, 16.3848219],
                [107.1249724, 16.3846807],
                [107.1251321, 16.3845594],
                [107.1253057, 16.3844613],
                [107.1253973, 16.3844209],
                [107.1257408, 16.3843087],
                [107.1258911, 16.3842526],
                [107.1261835, 16.3841219],
                [107.1268916, 16.3836485],
                [107.128222, 16.3821868],
                [107.1287691, 16.3829794],
                [107.129091, 16.3837823],
                [107.12915, 16.383873],
                [107.1292895, 16.3840396],
                [107.1293582, 16.3841048],
                [107.1295087, 16.3842209],
                [107.1295899, 16.3842712],
                [107.1298879, 16.3844025],
                [107.130475, 16.3846829],
                [107.1308197, 16.3848617],
                [107.1313869, 16.3851723],
                [107.1315906, 16.3853167],
                [107.1317694, 16.3854777],
                [107.1318513, 16.3855653],
                [107.132085, 16.3858541],
                [107.1321786, 16.3859489],
                [107.1323848, 16.3861188],
                [107.1325945, 16.38625],
                [107.1328199, 16.3863548],
                [107.1342355, 16.3868805],
                [107.135362, 16.3878017],
                [107.1360165, 16.3879149],
                [107.1361881, 16.3879961],
                [107.1365399, 16.3881403],
                [107.1369016, 16.3882598],
                [107.1371079, 16.3883156],
                [107.1375267, 16.3884032],
                [107.1377385, 16.3884347],
                [107.1384251, 16.3885119],
                [107.1390367, 16.3893611],
                [107.13909, 16.3894212],
                [107.1391489, 16.3894761],
                [107.1392817, 16.389569],
                [107.1393543, 16.389606],
                [107.1395087, 16.3896596],
                [107.1395973, 16.3896769],
                [107.1396873, 16.3896854],
                [107.1397777, 16.3896848],
                [107.1398676, 16.3896753],
                [107.139956, 16.3896569],
                [107.1400419, 16.3896299],
                [107.1401244, 16.3895944],
                [107.1402027, 16.389551],
                [107.1404184, 16.3893942],
                [107.1406889, 16.3891655],
                [107.1409491, 16.3889033],
                [107.1411317, 16.3886896],
                [107.141273, 16.3885558],
                [107.1414345, 16.388445],
                [107.1416271, 16.3883545],
                [107.141833, 16.3882968],
                [107.1420461, 16.3882736],
                [107.1430868, 16.38827],
                [107.1442777, 16.3881105],
                [107.1444541, 16.3879501],
                [107.1445498, 16.3878781],
                [107.1447543, 16.3877522],
                [107.1448624, 16.3876988],
                [107.1450875, 16.387612],
                [107.1452038, 16.387579],
                [107.1455105, 16.3875178],
                [107.1456401, 16.3874634],
                [107.1457534, 16.3873827],
                [107.1458449, 16.38728],
                [107.1458811, 16.387222],
                [107.1459319, 16.387096],
                [107.1460587, 16.3865666],
                [107.1461418, 16.3864099],
                [107.146256, 16.3862725],
                [107.1463233, 16.3862128],
                [107.1463966, 16.38616],
                [107.146552, 16.3860792],
                [107.1467208, 16.386029],
                [107.1468082, 16.3860159],
                [107.1469849, 16.3860145],
                [107.1481737, 16.3861755],
                [107.1482034, 16.3863689],
                [107.148268, 16.3865544],
                [107.1483654, 16.3867261],
                [107.1484273, 16.3868073],
                [107.1484966, 16.3868828],
                [107.1485727, 16.386952],
                [107.1486551, 16.3870143],
                [107.1488804, 16.3870555],
                [107.1494329, 16.3875341],
                [107.1495361, 16.3875718],
                [107.1496459, 16.3875841],
                [107.1498656, 16.3875527],
                [107.1500324, 16.3875579],
                [107.1501946, 16.3875959],
                [107.1503464, 16.3876655],
                [107.1504158, 16.3877113],
                [107.150538, 16.3878223],
                [107.1507525, 16.3881877],
                [107.1508851, 16.3883595],
                [107.1509606, 16.3884382],
                [107.1511281, 16.3885791],
                [107.1513232, 16.3887002],
                [107.1514316, 16.3887519],
                [107.1515438, 16.3887953],
                [107.1520679, 16.3889359],
                [107.1523511, 16.3890369],
                [107.1526276, 16.3891603],
                [107.1528929, 16.3893045],
                [107.1528709, 16.3896378],
                [107.1528191, 16.3899869],
                [107.1527801, 16.3901687],
                [107.1526784, 16.3905268],
                [107.1526558, 16.3907685],
                [107.1526074, 16.3910067],
                [107.1525314, 16.3912455],
                [107.1524262, 16.3914814],
                [107.15226, 16.3915675],
                [107.1520988, 16.3916622],
                [107.1519432, 16.391765],
                [107.1517937, 16.3918757],
                [107.1516505, 16.391994],
                [107.1515143, 16.3921196],
                [107.1513876, 16.3922495],
                [107.1512682, 16.3923857],
                [107.1511566, 16.3925278],
                [107.1510529, 16.3926754],
                [107.1502375, 16.3941369],
                [107.150126, 16.3943792],
                [107.1498791, 16.3948529],
                [107.1495931, 16.3953231],
                [107.1494339, 16.3955573],
                [107.1492666, 16.3957863],
                [107.1491844, 16.3958828],
                [107.1490948, 16.395973],
                [107.1489984, 16.3960565],
                [107.1488062, 16.39619],
                [107.1487033, 16.3962469],
                [107.1485279, 16.3963172],
                [107.148183, 16.3964708],
                [107.1478343, 16.3966483],
                [107.1475261, 16.3968837],
                [107.1472242, 16.3971266],
                [107.146929, 16.3973769],
                [107.1466405, 16.3976344],
                [107.146131, 16.3981235],
                [107.1456901, 16.3985865],
                [107.1454774, 16.3988246],
                [107.1450456, 16.3993316],
                [107.1445788, 16.3998464],
                [107.1440883, 16.4003417],
                [107.1435749, 16.4008167],
                [107.1435245, 16.4009643],
                [107.1434014, 16.4012514],
                [107.143329, 16.4013903],
                [107.1431635, 16.401657],
                [107.1430707, 16.4017842],
                [107.1428782, 16.402012],
                [107.1427739, 16.4021193],
                [107.1425503, 16.4023194],
                [107.1425341, 16.4024295],
                [107.1424743, 16.4026441],
                [107.1424311, 16.4027473],
                [107.1423197, 16.4029421],
                [107.1422579, 16.4030252],
                [107.1421161, 16.4031774],
                [107.1420368, 16.4032455],
                [107.1418637, 16.4033641],
                [107.1418629, 16.4038391],
                [107.1418927, 16.4039416],
                [107.1419511, 16.4040375],
                [107.1419945, 16.4041454],
                [107.1420033, 16.4042551],
                [107.1419817, 16.4043572],
                [107.1414131, 16.4052115],
                [107.141171, 16.4055957],
                [107.140705, 16.4063745],
                [107.140764, 16.4069714],
                [107.1408123, 16.4077279],
                [107.1408255, 16.4078486],
                [107.1408472, 16.4079682],
                [107.1409159, 16.4082017],
                [107.141015, 16.4084204],
                [107.1410744, 16.4085229],
                [107.1412128, 16.408716],
                [107.1413755, 16.4088909],
                [107.1416867, 16.4091636],
                [107.1419656, 16.4094415],
                [107.142148, 16.4107382],
                [107.1422821, 16.4110316],
                [107.1427622, 16.4110187],
                [107.1428084, 16.4110255],
                [107.1428532, 16.4110382],
                [107.1429355, 16.4110802],
                [107.1430054, 16.4111443],
                [107.1430336, 16.4111841],
                [107.1430707, 16.4112734],
                [107.1431274, 16.4115309],
                [107.1432155, 16.4120504],
                [107.143248, 16.4123224],
                [107.1432853, 16.4128686],
                [107.1430249, 16.4130706],
                [107.1429008, 16.4131784],
                [107.1426674, 16.4134051],
                [107.1424538, 16.4136456],
                [107.1417108, 16.4145281],
                [107.1415068, 16.4147271],
                [107.1413967, 16.4148183],
                [107.1411666, 16.4149802],
                [107.1409249, 16.4151155],
                [107.1407296, 16.4152082],
                [107.1406057, 16.4152983],
                [107.1405065, 16.4154132],
                [107.1404467, 16.4155233],
                [107.1404099, 16.4156422],
                [107.1404475, 16.4166971],
                [107.1405873, 16.4170939],
                [107.1407586, 16.4174792],
                [107.1408616, 16.4176775],
                [107.140973, 16.4178716],
                [107.1410925, 16.4180612],
                [107.14122, 16.4182459],
                [107.1424806, 16.4187759],
                [107.1428186, 16.4188017],
                [107.1429566, 16.4188241],
                [107.1430868, 16.4188737],
                [107.1431942, 16.418941],
                [107.1432424, 16.4189818],
                [107.1433068, 16.4193323],
                [107.1433554, 16.4194927],
                [107.1434384, 16.4196407],
                [107.143492, 16.419708],
                [107.1435528, 16.4197694],
                [107.14362, 16.4198243],
                [107.143693, 16.419872],
                [107.144083, 16.4199485],
                [107.1442084, 16.4199811],
                [107.1444491, 16.4200676],
                [107.1446747, 16.4201807],
                [107.1451789, 16.4212921],
                [107.1452094, 16.421389],
                [107.1452497, 16.4215876],
                [107.1452729, 16.421964],
                [107.1452945, 16.4221005],
                [107.1453258, 16.4222352],
                [107.1453667, 16.4223676],
                [107.1454167, 16.4224963],
                [107.1454758, 16.4226215],
                [107.1455437, 16.4227424],
                [107.1456201, 16.4228587],
                [107.1457046, 16.4229696],
                [107.1457535, 16.423065],
                [107.1458255, 16.423266],
                [107.1458481, 16.4233701],
                [107.1458656, 16.4235819],
                [107.1458619, 16.4236693],
                [107.145835, 16.4238421],
                [107.1457746, 16.4240287],
                [107.1457279, 16.4241271],
                [107.1456723, 16.4242211],
                [107.1456081, 16.42431],
                [107.1443367, 16.4254343],
                [107.1442309, 16.4259878],
                [107.1441543, 16.4265457],
                [107.1441081, 16.4270917],
                [107.1440899, 16.4276391],
                [107.1439582, 16.4278643],
                [107.1438539, 16.4281022],
                [107.1437851, 16.4283226],
                [107.1437399, 16.4285486],
                [107.1439594, 16.4286906],
                [107.144162, 16.4288542],
                [107.1443443, 16.4290363],
                [107.1444272, 16.4291334],
                [107.1445765, 16.4293394],
                [107.1446425, 16.4294478],
                [107.1451682, 16.4305746],
                [107.1453133, 16.4309093],
                [107.1453982, 16.4310712],
                [107.1454912, 16.431229],
                [107.1456869, 16.4315134],
                [107.1457875, 16.4316403],
                [107.146005, 16.4318815],
                [107.1461175, 16.432018],
                [107.1462228, 16.4321597],
                [107.1464108, 16.4324571],
                [107.1464932, 16.4326121],
                [107.146616, 16.4328876],
                [107.1466681, 16.4330288],
                [107.1467534, 16.433317],
                [107.1470023, 16.4332663],
                [107.1472549, 16.4332373],
                [107.1475339, 16.4332307],
                [107.1476734, 16.4332373],
                [107.1477275, 16.4332453],
                [107.1478298, 16.4332825],
                [107.1478759, 16.433311],
                [107.1479528, 16.4333838],
                [107.1480056, 16.4334728],
                [107.148108, 16.4338153],
                [107.1481615, 16.4339589],
                [107.1482226, 16.4341013],
                [107.1483654, 16.434377],
                [107.1484136, 16.4345019],
                [107.1484297, 16.4346342],
                [107.1484266, 16.434691],
                [107.1484023, 16.4348022],
                [107.148006, 16.4358845],
                [107.1478665, 16.4364762],
                [107.1477607, 16.4367015],
                [107.1476593, 16.4368785],
                [107.1475929, 16.4370216],
                [107.1475632, 16.4371123],
                [107.1475285, 16.4372994],
                [107.1469706, 16.4377754],
                [107.1459623, 16.4377231],
                [107.1455466, 16.4376729],
                [107.1453405, 16.4376366],
                [107.1449144, 16.4375363],
                [107.1446955, 16.4374711],
                [107.1444795, 16.4373972],
                [107.1441442, 16.4372639],
                [107.1438904, 16.4371825],
                [107.1436286, 16.4371297],
                [107.1434884, 16.4371135],
                [107.1432063, 16.4371058],
                [107.1430653, 16.4371142],
                [107.1421641, 16.4372789],
                [107.1420848, 16.437303],
                [107.1419345, 16.4373713],
                [107.1417993, 16.4374641],
                [107.1417304, 16.4375272],
                [107.1416685, 16.4375968],
                [107.1416144, 16.437672],
                [107.1415687, 16.4377522],
                [107.1415158, 16.4379098],
                [107.1414345, 16.4380558],
                [107.1413438, 16.4381686],
                [107.1412361, 16.4382667],
                [107.1407372, 16.438277],
                [107.1398789, 16.4389819],
                [107.1389428, 16.4397048],
                [107.1388, 16.4398001],
                [107.1386423, 16.4398702],
                [107.1384734, 16.4399133],
                [107.1382983, 16.4399275],
                [107.1381388, 16.4399195],
                [107.138067, 16.4399225],
                [107.1379959, 16.4399324],
                [107.1378629, 16.4399709],
                [107.1378021, 16.4399982],
                [107.1376897, 16.4400687],
                [107.1374717, 16.4402669],
                [107.137379, 16.4403351],
                [107.1372865, 16.4403932],
                [107.1370894, 16.4404894],
                [107.1374917, 16.441112],
                [107.137599, 16.4413142],
                [107.1376807, 16.4415272],
                [107.1377792, 16.441925],
                [107.1378241, 16.4420664],
                [107.1378761, 16.4422055],
                [107.1380013, 16.4424754],
                [107.1380848, 16.4426233],
                [107.1381767, 16.4427665],
                [107.1382769, 16.4429045],
                [107.138385, 16.4430369],
                [107.1385007, 16.4431632],
                [107.1386236, 16.4432832],
                [107.1384798, 16.4434988],
                [107.1383086, 16.4436952],
                [107.1381128, 16.4438692],
                [107.1379011, 16.4440147],
                [107.137678, 16.4441312],
                [107.1374419, 16.4442211],
                [107.136172, 16.4445746],
                [107.1358817, 16.4446674],
                [107.1355947, 16.4447693],
                [107.1353113, 16.4448802],
                [107.1350319, 16.445],
                [107.1347567, 16.4451285],
                [107.1344861, 16.4452656],
                [107.1342203, 16.4454112],
                [107.1339597, 16.4455651],
                [107.1337044, 16.4457271],
                [107.1334195, 16.4459221],
                [107.1331395, 16.4461236],
                [107.1325952, 16.4465459],
                [107.1320526, 16.4470109],
                [107.1317809, 16.4472623],
                [107.131516, 16.4475204],
                [107.1312582, 16.447785],
                [107.1311469, 16.4478754],
                [107.1302314, 16.4486192],
                [107.1315251, 16.4499124],
                [107.1327495, 16.4519524],
                [107.1332169, 16.4524149],
                [107.1337044, 16.4528578],
                [107.1339261, 16.4530471],
                [107.1343803, 16.4534135],
                [107.1351206, 16.453362],
                [107.1351716, 16.4533719],
                [107.1352665, 16.4534122],
                [107.1353486, 16.4534803],
                [107.1353819, 16.4535244],
                [107.1354076, 16.4535729],
                [107.135422, 16.453663],
                [107.1354286, 16.4537539],
                [107.1354184, 16.4539357],
                [107.1353993, 16.4540348],
                [107.1353708, 16.4541318],
                [107.1353333, 16.4542259],
                [107.1352869, 16.4543164],
                [107.135244, 16.4544325],
                [107.1351827, 16.4546716],
                [107.1351548, 16.4549165],
                [107.1351609, 16.4551627],
                [107.1352025, 16.455413],
                [107.1352365, 16.4555356],
                [107.1353303, 16.4557727],
                [107.1354568, 16.4559953],
                [107.1355316, 16.4560999],
                [107.1357027, 16.4562932],
                [107.135539, 16.4573337],
                [107.1353298, 16.4581157],
                [107.135404, 16.4581913],
                [107.1354709, 16.4582729],
                [107.1355302, 16.4583598],
                [107.1355812, 16.4584514],
                [107.1356236, 16.4585469],
                [107.1356571, 16.4586456],
                [107.1356785, 16.4587325],
                [107.1357006, 16.4589097],
                [107.1357012, 16.458999],
                [107.1356796, 16.4592911],
                [107.1356893, 16.4594945],
                [107.1357082, 16.4596224],
                [107.135737, 16.4597487],
                [107.1357755, 16.4598726],
                [107.1358234, 16.4599935],
                [107.1351314, 16.4607292],
                [107.1350898, 16.4608879],
                [107.1350596, 16.4609645],
                [107.1349812, 16.4611099],
                [107.134868, 16.4612555],
                [107.134802, 16.4613208],
                [107.1346539, 16.461434],
                [107.1345091, 16.4617118],
                [107.1341175, 16.4617375],
                [107.1340493, 16.4617461],
                [107.133983, 16.4617636],
                [107.1339199, 16.4617897],
                [107.133861, 16.4618239],
                [107.1338077, 16.4618655],
                [107.1337607, 16.4619137],
                [107.1336903, 16.4620249],
                [107.1336675, 16.4620863],
                [107.1336489, 16.4622152],
                [107.1336535, 16.4622802],
                [107.1336339, 16.4629989],
                [107.1336231, 16.4630609],
                [107.1335808, 16.4631784],
                [107.1335501, 16.4632326],
                [107.1334711, 16.4633297],
                [107.1334711, 16.4636178],
                [107.1334474, 16.4637502],
                [107.1333935, 16.4638674],
                [107.1333167, 16.4639644],
                [107.1332701, 16.464006],
                [107.1330097, 16.4641914],
                [107.132994, 16.4645746],
                [107.133007, 16.4649579],
                [107.133049, 16.4653406],
                [107.1331197, 16.4657193],
                [107.1329833, 16.46595],
                [107.1329262, 16.4660708],
                [107.1328354, 16.4663212],
                [107.1327798, 16.4665611],
                [107.1327626, 16.4666829],
                [107.1327496, 16.4669282],
                [107.1325769, 16.4670635],
                [107.1324273, 16.4672221],
                [107.1323042, 16.4674004],
                [107.1322104, 16.4675944],
                [107.1321744, 16.4676985],
                [107.1321451, 16.4678341],
                [107.132128, 16.4679131],
                [107.1321179, 16.4680224],
                [107.1321192, 16.4682765],
                [107.1321085, 16.4683481],
                [107.1320713, 16.4684684],
                [107.1320441, 16.4685256],
                [107.1317732, 16.468667],
                [107.1317732, 16.4688265],
                [107.1317947, 16.4690246],
                [107.1317959, 16.4691104],
                [107.131784, 16.4692818],
                [107.1315693, 16.4693735],
                [107.1313682, 16.4694901],
                [107.1311641, 16.4696471],
                [107.1309847, 16.4698297],
                [107.1309659, 16.4702515],
                [107.1306038, 16.470699],
                [107.1303793, 16.470703],
                [107.1301559, 16.470681],
                [107.1299459, 16.4706359],
                [107.1297428, 16.4705679],
                [107.1296597, 16.4706939],
                [107.129636, 16.4707512],
                [107.1296087, 16.4708714],
                [107.1295069, 16.4710706],
                [107.1294317, 16.4712803],
                [107.1293808, 16.4715199],
                [107.1293646, 16.4717639],
                [107.1290857, 16.4720108],
                [107.1290642, 16.4727567],
                [107.1291867, 16.4728474],
                [107.1294175, 16.4730451],
                [107.1295252, 16.4731517],
                [107.129724, 16.4733792],
                [107.1297761, 16.4734468],
                [107.1298624, 16.4735928],
                [107.1298962, 16.4736703],
                [107.129944, 16.4738319],
                [107.129959, 16.4739268],
                [107.1299616, 16.4741186],
                [107.1298678, 16.4746673],
                [107.1298152, 16.4750279],
                [107.1297562, 16.475514],
                [107.1295689, 16.4757519],
                [107.1294656, 16.4758634],
                [107.1292412, 16.4760696],
                [107.1289977, 16.4762501],
                [107.1288688, 16.4763309],
                [107.1285986, 16.4764723],
                [107.128458, 16.4765326],
                [107.1270418, 16.4768206],
                [107.1269276, 16.4768306],
                [107.1268146, 16.4768498],
                [107.1267037, 16.476878],
                [107.1265957, 16.476915],
                [107.1264913, 16.4769607],
                [107.1263913, 16.4770146],
                [107.1262964, 16.4770764],
                [107.1262072, 16.4771457],
                [107.1261245, 16.4772219],
                [107.126051, 16.4773019],
                [107.1259255, 16.4774778],
                [107.1258744, 16.4775725],
                [107.1257973, 16.4777723],
                [107.1257238, 16.4784482],
                [107.1257063, 16.4785522],
                [107.1256471, 16.4787548],
                [107.1255663, 16.4789291],
                [107.1255167, 16.4790118],
                [107.1254003, 16.4791664],
                [107.1255407, 16.4800551],
                [107.1255615, 16.4802144],
                [107.1255827, 16.4805347],
                [107.1255742, 16.4808864],
                [107.1255575, 16.4810617],
                [107.1254995, 16.4814092],
                [107.1250892, 16.48169],
                [107.1247405, 16.4819287],
                [107.1213716, 16.4848968],
                [107.1213609, 16.4855449],
                [107.121368, 16.4856126],
                [107.1213655, 16.4856806],
                [107.1213535, 16.4857477],
                [107.1213322, 16.4858126],
                [107.1212709, 16.4859211],
                [107.1212323, 16.4859627],
                [107.121187, 16.4859975],
                [107.1211365, 16.4860249],
                [107.1210262, 16.4860539],
                [107.1209134, 16.4860478],
                [107.1208592, 16.4860319],
                [107.120737, 16.4859695],
                [107.1206968, 16.485961],
                [107.1206152, 16.4859667],
                [107.1205778, 16.4859803],
                [107.1205141, 16.4860264],
                [107.1204902, 16.4860573],
                [107.1204625, 16.486129],
                [107.1204275, 16.4866251],
                [107.1197945, 16.4871241],
                [107.119714, 16.4874481],
                [107.1196799, 16.4874997],
                [107.119596, 16.4875913],
                [107.1194941, 16.4876642],
                [107.1193776, 16.4877156],
                [107.1192524, 16.4877426],
                [107.1191883, 16.4877465],
                [107.1187001, 16.4877002],
                [107.1185427, 16.4877],
                [107.1182289, 16.4877225],
                [107.1181291, 16.4877396],
                [107.1179191, 16.4877756],
                [107.1176005, 16.4878638],
                [107.1174375, 16.4879232],
                [107.1172783, 16.4879912],
                [107.1171232, 16.4880678],
                [107.116973, 16.4881526],
                [107.116828, 16.4882454],
                [107.1153758, 16.4882657],
                [107.115112, 16.4882877],
                [107.1149816, 16.4883099],
                [107.114726, 16.4883763],
                [107.1146017, 16.4884203],
                [107.1143683, 16.4885261],
                [107.1141492, 16.4886569],
                [107.1139473, 16.4888112],
                [107.1130246, 16.4897371],
                [107.1128836, 16.4899205],
                [107.1128212, 16.4900175],
                [107.1127135, 16.4902206],
                [107.112628, 16.4904386],
                [107.1125686, 16.4906643],
                [107.1121418, 16.4909808],
                [107.1116942, 16.49127],
                [107.1112304, 16.491529],
                [107.1107501, 16.4917586],
                [107.1106378, 16.49196],
                [107.1105033, 16.4921484],
                [107.1103359, 16.4923337],
                [107.1101332, 16.4925096],
                [107.1091354, 16.4926691],
                [107.1080571, 16.4937698],
                [107.1078899, 16.4939526],
                [107.1076993, 16.4941129],
                [107.1074885, 16.4942482],
                [107.1072574, 16.4943574],
                [107.1071367, 16.4944008],
                [107.1068877, 16.4944642],
                [107.1059972, 16.495102],
                [107.1059201, 16.4951475],
                [107.1058384, 16.495185],
                [107.1057531, 16.4952141],
                [107.1056651, 16.4952346],
                [107.1055754, 16.4952461],
                [107.1054849, 16.4952486],
                [107.1053178, 16.4952292],
                [107.1051577, 16.4951792],
                [107.1046212, 16.494886],
                [107.104336, 16.4949658],
                [107.1040633, 16.4950789],
                [107.1038543, 16.4951938],
                [107.1036583, 16.4953283],
                [107.1033255, 16.4952972],
                [107.1029985, 16.4952306],
                [107.1026472, 16.4951188],
                [107.1023088, 16.4949748],
                [107.1020045, 16.4948107],
                [107.101734, 16.4946329],
                [107.1014804, 16.4944333],
                [107.1012604, 16.4940064],
                [107.100986, 16.4936374],
                [107.1008594, 16.4934459],
                [107.100625, 16.4930447],
                [107.1004191, 16.4926219],
                [107.1002465, 16.4921855],
                [107.10022, 16.491223],
                [107.1002057, 16.4910634],
                [107.1001586, 16.4907871],
                [107.1001163, 16.4906296],
                [107.1000963, 16.490555],
                [107.0985246, 16.4912957],
                [107.0977628, 16.4935898],
                [107.0976984, 16.495781],
                [107.0976611, 16.4959864],
                [107.0975666, 16.4963935],
                [107.0974463, 16.4967943],
                [107.0973629, 16.4970284],
                [107.0972706, 16.4972594],
                [107.0971696, 16.497487],
                [107.0970601, 16.497711],
                [107.0969421, 16.497931],
                [107.0960194, 16.4991191],
                [107.0955975, 16.4994775],
                [107.0954881, 16.4995805],
                [107.0953857, 16.49969],
                [107.0952909, 16.4998056],
                [107.0951351, 16.5000362],
                [107.0950727, 16.5001493],
                [107.094968, 16.5003845],
                [107.0945442, 16.5016446],
                [107.0945216, 16.5017567],
                [107.0945013, 16.5019841],
                [107.0945027, 16.5020787],
                [107.0945227, 16.502267],
                [107.0951223, 16.5036103],
                [107.0952147, 16.5038974],
                [107.0952502, 16.5040494],
                [107.0952952, 16.5043578],
                [107.0943457, 16.5044041],
                [107.0934686, 16.5039437],
                [107.0931119, 16.5040389],
                [107.0927855, 16.5038218],
                [107.0927262, 16.5037904],
                [107.0925992, 16.503746],
                [107.0925328, 16.5037335],
                [107.0923982, 16.5037286],
                [107.0922659, 16.5037501],
                [107.0921409, 16.5037971],
                [107.0915133, 16.5041135],
                [107.0911324, 16.504098],
                [107.0907864, 16.5044494],
                [107.0907479, 16.5044758],
                [107.0906603, 16.5045095],
                [107.090556, 16.5045141],
                [107.0904565, 16.5044838],
                [107.0902573, 16.504322],
                [107.0901668, 16.5042318],
                [107.0900043, 16.5040319],
                [107.0899342, 16.5039245],
                [107.0898181, 16.5036968],
                [107.0896148, 16.5034779],
                [107.089389, 16.5032802],
                [107.0891431, 16.5031059],
                [107.0888756, 16.5029549],
                [107.0885885, 16.5028299],
                [107.0882893, 16.502735],
                [107.0862508, 16.5024779],
                [107.0858801, 16.5024452],
                [107.085694, 16.5024404],
                [107.085322, 16.502454],
                [107.0849526, 16.5024984],
                [107.084601, 16.5025702],
                [107.0844278, 16.5026166],
                [107.0840884, 16.50273],
                [107.0839226, 16.5027967],
                [107.0821202, 16.5036711],
                [107.0821094, 16.504278],
                [107.082069, 16.5045218],
                [107.0819956, 16.5047583],
                [107.0819469, 16.5048725],
                [107.0818265, 16.5050907],
                [107.0817554, 16.5051936],
                [107.0815434, 16.5054655],
                [107.0814466, 16.5056077],
                [107.0812726, 16.5059034],
                [107.0811339, 16.5061926],
                [107.0810737, 16.5063409],
                [107.0809722, 16.506644],
                [107.0805159, 16.5073193],
                [107.0804787, 16.5073743],
                [107.0804639, 16.5075431],
                [107.0804102, 16.5078778],
                [107.0803714, 16.508043],
                [107.0802819, 16.508335],
                [107.0802277, 16.5084782],
                [107.0801333, 16.5086856],
                [107.0800908, 16.5087476],
                [107.0800407, 16.5088041],
                [107.0799838, 16.5088544],
                [107.079921, 16.5088976],
                [107.079853, 16.5089331],
                [107.0797811, 16.5089604],
                [107.0796307, 16.5089887],
                [107.0794787, 16.5089816],
                [107.079333, 16.5089398],
                [107.0792019, 16.5088659],
                [107.0786548, 16.5082796],
                [107.0785291, 16.5083243],
                [107.0782706, 16.5083913],
                [107.0781385, 16.5084133],
                [107.0778716, 16.5084339],
                [107.0776059, 16.5084233],
                [107.0774741, 16.5084064],
                [107.0772148, 16.5083499],
                [107.0770883, 16.5083104],
                [107.0769735, 16.508301],
                [107.0767434, 16.5083083],
                [107.0766295, 16.5083249],
                [107.0764075, 16.5083836],
                [107.0761979, 16.508475],
                [107.0760093, 16.5085941],
                [107.0758421, 16.5087396],
                [107.0757003, 16.5089081],
                [107.07564, 16.5089996],
                [107.0749054, 16.5103529],
                [107.0748558, 16.5104211],
                [107.074737, 16.5105423],
                [107.0746039, 16.5106348],
                [107.0745354, 16.51067],
                [107.07439, 16.5107226],
                [107.0742049, 16.510754],
                [107.0738375, 16.5108306],
                [107.0735918, 16.5108927],
                [107.073108, 16.5110415],
                [107.0721745, 16.5108769],
                [107.071854, 16.5107894],
                [107.0715444, 16.5106713],
                [107.0712487, 16.5105239],
                [107.0709747, 16.5103521],
                [107.0707243, 16.5101589],
                [107.0706067, 16.5100538],
                [107.0703882, 16.5098277],
                [107.0698839, 16.5101209],
                [107.0689666, 16.5101157],
                [107.0686769, 16.5102906],
                [107.0683821, 16.5103042],
                [107.0680907, 16.5103491],
                [107.0678033, 16.5104257],
                [107.0675239, 16.5105339],
                [107.0673895, 16.5105992],
                [107.0661986, 16.5116844],
                [107.0661521, 16.5118985],
                [107.0660806, 16.5121061],
                [107.0660277, 16.5122231],
                [107.0658982, 16.5124456],
                [107.0645678, 16.5140194],
                [107.0642409, 16.5141964],
                [107.0639189, 16.5143816],
                [107.0636022, 16.5145749],
                [107.0633148, 16.5147602],
                [107.0627546, 16.5151509],
                [107.0624113, 16.5158401],
                [107.0622969, 16.5159836],
                [107.0620499, 16.5162564],
                [107.0617798, 16.5165089],
                [107.0616365, 16.5166269],
                [107.0613352, 16.5168453],
                [107.0611777, 16.5169453],
                [107.0608503, 16.5171258],
                [107.060704, 16.5171189],
                [107.0604115, 16.517131],
                [107.0602664, 16.51715],
                [107.0599812, 16.5172133],
                [107.0598358, 16.5172596],
                [107.0596937, 16.5173149],
                [107.0595557, 16.5173788],
                [107.0594222, 16.5174511],
                [107.0592939, 16.5175315],
                [107.0591712, 16.5176196],
                [107.0591242, 16.5177881],
                [107.0590046, 16.5181174],
                [107.0589323, 16.5182775],
                [107.0587635, 16.5185864],
                [107.0586737, 16.5187254],
                [107.0584746, 16.5189906],
                [107.0583658, 16.5191163],
                [107.0581305, 16.5193528],
                [107.0579917, 16.5195197],
                [107.0579311, 16.5196093],
                [107.0578286, 16.5197988],
                [107.0577539, 16.5199949],
                [107.0577066, 16.5201938],
                [107.0576503, 16.5207435],
                [107.0575829, 16.5210857],
                [107.0575372, 16.5212543],
                [107.0574224, 16.5215848],
                [107.0572871, 16.5218841],
                [107.0572097, 16.5220294],
                [107.0570362, 16.5223099],
                [107.056843, 16.5227574],
                [107.0563173, 16.5232871],
                [107.0562629, 16.5233772],
                [107.0562321, 16.523477],
                [107.0562275, 16.5235916],
                [107.0562578, 16.5237133],
                [107.0563227, 16.523822],
                [107.0564027, 16.5239041],
                [107.0565459, 16.5240819],
                [107.0566647, 16.5242756],
                [107.0567612, 16.524493],
                [107.0568293, 16.5247322],
                [107.0568621, 16.5249781],
                [107.0568591, 16.525226],
                [107.0568699, 16.52591],
                [107.0569024, 16.5261784],
                [107.0569602, 16.5264358],
                [107.0570408, 16.5266803],
                [107.0579481, 16.5289159],
                [107.058005, 16.5289945],
                [107.0581326, 16.5291413],
                [107.0582885, 16.5292817],
                [107.0583789, 16.5293486],
                [107.0588118, 16.529623],
                [107.0593321, 16.5299727],
                [107.0594555, 16.5301121],
                [107.0595578, 16.5302663],
                [107.0596393, 16.5304388],
                [107.0596958, 16.530627],
                [107.059713, 16.5307236],
                [107.0597371, 16.5309655],
                [107.0597706, 16.5312065],
                [107.0598134, 16.531446],
                [107.0598247, 16.5314974],
                [107.0598655, 16.5316839],
                [107.0599269, 16.5319198],
                [107.0600724, 16.5323721],
                [107.0602466, 16.5328016],
                [107.0603455, 16.5330117],
                [107.0605661, 16.533421],
                [107.0606876, 16.5336199],
                [107.0609522, 16.5340046],
                [107.0621324, 16.5359331],
                [107.0621377, 16.5370027],
                [107.0618678, 16.5372639],
                [107.0615798, 16.5375067],
                [107.0614181, 16.5376291],
                [107.0610809, 16.5378564],
                [107.0610294, 16.5379026],
                [107.0609429, 16.5380094],
                [107.0609089, 16.5380686],
                [107.060861, 16.5381958],
                [107.0608418, 16.5383269],
                [107.0608428, 16.5383932],
                [107.0608659, 16.5385237],
                [107.0608878, 16.5385866],
                [107.0613062, 16.5395122],
                [107.0613313, 16.5396293],
                [107.0613331, 16.5397488],
                [107.0613247, 16.5398101],
                [107.0612897, 16.5399289],
                [107.0612633, 16.5399853],
                [107.0611439, 16.5401816],
                [107.061036, 16.5404083],
                [107.0609879, 16.5405359],
                [107.0609146, 16.5407978],
                [107.0599383, 16.5419549],
                [107.0600456, 16.5427931],
                [107.0600826, 16.5431458],
                [107.0601422, 16.5438524],
                [107.0610809, 16.5445878],
                [107.0610434, 16.5457499],
                [107.0612593, 16.5457603],
                [107.0614756, 16.5457612],
                [107.0616916, 16.5457526],
                [107.0620544, 16.5457166],
                [107.0625281, 16.5456413],
                [107.0628932, 16.5456104],
                [107.0630765, 16.545606],
                [107.0632456, 16.5454308],
                [107.0633383, 16.545351],
                [107.0634359, 16.5452768],
                [107.0636278, 16.5451555],
                [107.0638329, 16.5450557],
                [107.064232, 16.5448959],
                [107.0644283, 16.5448089],
                [107.064954, 16.5445466],
                [107.0651407, 16.5444741],
                [107.0653349, 16.5444232],
                [107.0659028, 16.5443653],
                [107.0664668, 16.5442792],
                [107.0670017, 16.5441707],
                [107.0672669, 16.5441069],
                [107.0679079, 16.5439289],
                [107.0681442, 16.5438825],
                [107.0682639, 16.5438679],
                [107.0685441, 16.5438558],
                [107.0686843, 16.5438613],
                [107.0689626, 16.5438956],
                [107.0692348, 16.5439604],
                [107.0699537, 16.543549],
                [107.0713074, 16.5435258],
                [107.071567, 16.5434967],
                [107.0716948, 16.5434704],
                [107.0719439, 16.5433948],
                [107.0720697, 16.5433433],
                [107.0723092, 16.5432168],
                [107.0724219, 16.5431425],
                [107.0727496, 16.5428776],
                [107.0728742, 16.5427887],
                [107.0731382, 16.542632],
                [107.0732768, 16.5425647],
                [107.0735513, 16.5424578],
                [107.0738225, 16.542383],
                [107.0741004, 16.5423354],
                [107.0741775, 16.5423296],
                [107.0743317, 16.5423391],
                [107.0744812, 16.5423766],
                [107.0745593, 16.5424085],
                [107.0746335, 16.5424481],
                [107.0747668, 16.5425484],
                [107.0749688, 16.5427664],
                [107.0751056, 16.5429309],
                [107.0752346, 16.543101],
                [107.0753556, 16.5432765],
                [107.075758, 16.5435233],
                [107.075833, 16.543583],
                [107.0759648, 16.5437204],
                [107.0760691, 16.5438781],
                [107.0761444, 16.5440569],
                [107.0761842, 16.5442459],
                [107.0762032, 16.5451072],
                [107.0761925, 16.5457088],
                [107.0765895, 16.5461562],
                [107.0767298, 16.5461683],
                [107.0770116, 16.5461712],
                [107.0772922, 16.5461459],
                [107.0775765, 16.5460906],
                [107.0777159, 16.546052],
                [107.077853, 16.5460063],
                [107.0782444, 16.5458461],
                [107.0785049, 16.5457741],
                [107.0787728, 16.5457345],
                [107.0792019, 16.5457345],
                [107.0794594, 16.5461562],
                [107.0795555, 16.5462493],
                [107.0796703, 16.5463203],
                [107.0796818, 16.5463244],
                [107.0797991, 16.5463661],
                [107.079867, 16.5463787],
                [107.0800051, 16.5463822],
                [107.0801407, 16.5463567],
                [107.0806235, 16.5463233],
                [107.0808209, 16.5461562],
                [107.0809132, 16.5460662],
                [107.0810919, 16.5458645],
                [107.0811734, 16.5457576],
                [107.082324, 16.5458374],
                [107.0830589, 16.5463156],
                [107.0835233, 16.5463592],
                [107.0837564, 16.5463695],
                [107.084223, 16.546367],
                [107.0846385, 16.5463388],
                [107.0848453, 16.5463156],
                [107.0861661, 16.5457271],
                [107.0863706, 16.545663],
                [107.0865831, 16.5456306],
                [107.0866907, 16.5456265],
                [107.0868041, 16.5456311],
                [107.0869168, 16.5456446],
                [107.087028, 16.5456671],
                [107.0871368, 16.5456984],
                [107.0872425, 16.5457383],
                [107.0875992, 16.5459247],
                [107.0877611, 16.545999],
                [107.0879268, 16.546065],
                [107.0880958, 16.5461226],
                [107.0882678, 16.5461716],
                [107.0883715, 16.5463696],
                [107.0884487, 16.5465784],
                [107.0885003, 16.5468082],
                [107.0885199, 16.5470561],
                [107.0886488, 16.5472284],
                [107.0887017, 16.5473216],
                [107.0887828, 16.5475189],
                [107.0888291, 16.547726],
                [107.0888388, 16.5478317],
                [107.0888311, 16.5480434],
                [107.0888459, 16.548106],
                [107.0888675, 16.5481668],
                [107.0889302, 16.5482801],
                [107.0890195, 16.5483812],
                [107.0891334, 16.5484634],
                [107.0892635, 16.5485192],
                [107.0894034, 16.5485456],
                [107.0895568, 16.5485542],
                [107.0897182, 16.5485851],
                [107.0898732, 16.5486385],
                [107.0899473, 16.5486733],
                [107.0900863, 16.5487582],
                [107.0908266, 16.5493547],
                [107.0909423, 16.549503],
                [107.0910658, 16.5496453],
                [107.0911968, 16.5497815],
                [107.0914248, 16.5499884],
                [107.0917405, 16.5502488],
                [107.0921141, 16.5505939],
                [107.0925188, 16.5505738],
                [107.0927203, 16.5505528],
                [107.0930957, 16.5504936],
                [107.0932816, 16.5504545],
                [107.0936483, 16.5503574],
                [107.0939089, 16.5502764],
                [107.0944214, 16.5500911],
                [107.0946729, 16.5499871],
                [107.0951414, 16.5500362],
                [107.0956063, 16.5501106],
                [107.096112, 16.5502215],
                [107.0966095, 16.5503625],
                [107.0972747, 16.5503522],
                [107.0973879, 16.5503933],
                [107.0974972, 16.5504431],
                [107.0976019, 16.5505014],
                [107.0977011, 16.5505676],
                [107.0977943, 16.5506415],
                [107.0979684, 16.5507948],
                [107.0981565, 16.5509236],
                [107.099635, 16.5517715],
                [107.100445, 16.5519154],
                [107.1007735, 16.5520598],
                [107.1010888, 16.5522291],
                [107.1012432, 16.5523243],
                [107.1015394, 16.5525325],
                [107.1016366, 16.5525582],
                [107.1018356, 16.5525892],
                [107.1020385, 16.5525922],
                [107.1021402, 16.552583],
                [107.1023401, 16.5525432],
                [107.1025318, 16.5524759],
                [107.1030253, 16.55226],
                [107.1031021, 16.5522453],
                [107.1032583, 16.552235],
                [107.1033364, 16.5522394],
                [107.1034092, 16.5522493],
                [107.103551, 16.5522857],
                [107.1041572, 16.5518126],
                [107.1047543, 16.5517593],
                [107.1053481, 16.5516789],
                [107.1059684, 16.5515651],
                [107.1062761, 16.5514971],
                [107.1065819, 16.5514218],
                [107.1070433, 16.5511338],
                [107.1074992, 16.5513344],
                [107.1076384, 16.5513492],
                [107.1077787, 16.5513318],
                [107.1079118, 16.5512822],
                [107.1079727, 16.5512462],
                [107.1080786, 16.5511544],
                [107.1082824, 16.5511549],
                [107.1084841, 16.5511272],
                [107.1086803, 16.5510717],
                [107.1087751, 16.5510338],
                [107.1089548, 16.5509386],
                [107.1095806, 16.5505528],
                [107.1098737, 16.5506609],
                [107.1100161, 16.5507243],
                [107.1102914, 16.550869],
                [107.1105424, 16.55103],
                [107.1106623, 16.5511181],
                [107.1108895, 16.5513087],
                [107.1127939, 16.5509616],
                [107.1134751, 16.5506027],
                [107.1135521, 16.5505929],
                [107.1136252, 16.5506115],
                [107.1136568, 16.55063],
                [107.1137065, 16.5506822],
                [107.1137675, 16.5508461],
                [107.113811, 16.5509408],
                [107.113863, 16.5510314],
                [107.113923, 16.5511174],
                [107.1139906, 16.551198],
                [107.1142556, 16.5514625],
                [107.1144193, 16.5516738],
                [107.1144916, 16.5517883],
                [107.1146123, 16.5520296],
                [107.1146602, 16.5521553],
                [107.1147305, 16.5524142],
                [107.1172571, 16.5524502],
                [107.1178726, 16.5523673],
                [107.1184909, 16.5523062],
                [107.1191287, 16.5522663],
                [107.1197677, 16.5522497],
                [107.1200267, 16.5521787],
                [107.1201522, 16.5521315],
                [107.1203925, 16.5520145],
                [107.1205063, 16.5519452],
                [107.1207208, 16.551785],
                [107.1208205, 16.5516944],
                [107.1210004, 16.5514955],
                [107.1211518, 16.5512759],
                [107.1213079, 16.5509817],
                [107.121473, 16.5506178],
                [107.1216781, 16.5500738],
                [107.1217496, 16.5499057],
                [107.1219189, 16.5495649],
                [107.1220173, 16.5493932],
                [107.1222353, 16.5490616],
                [107.1222906, 16.5490617],
                [107.1223988, 16.5490825],
                [107.1224946, 16.549127],
                [107.1225733, 16.5491901],
                [107.1225807, 16.5495917],
                [107.1225742, 16.5497924],
                [107.1225411, 16.5501928],
                [107.1224722, 16.5506366],
                [107.1223701, 16.5510744],
                [107.1222353, 16.5515041],
                [107.1221563, 16.551666],
                [107.1220228, 16.5519996],
                [107.1219687, 16.5521706],
                [107.121886, 16.5525191],
                [107.1218382, 16.5528734],
                [107.1218257, 16.5532212],
                [107.1218457, 16.5535593],
                [107.1218973, 16.5538942],
                [107.1219349, 16.5540597],
                [107.1220072, 16.5541332],
                [107.1220529, 16.5542242],
                [107.1220676, 16.5543177],
                [107.1220643, 16.5543649],
                [107.1220368, 16.5544556],
                [107.1221763, 16.555083],
                [107.1222011, 16.5551566],
                [107.1222704, 16.5552963],
                [107.122364, 16.5554223],
                [107.1224834, 16.5555341],
                [107.1225504, 16.5555818],
                [107.1226966, 16.5556589],
                [107.1227035, 16.5557688],
                [107.1227342, 16.5558748],
                [107.1227644, 16.5559362],
                [107.1228482, 16.5560451],
                [107.1229005, 16.5560908],
                [107.1229773, 16.5561308],
                [107.123119, 16.556229],
                [107.1231828, 16.5562864],
                [107.1232939, 16.5564157],
                [107.1233404, 16.5564867],
                [107.1234121, 16.5566348],
                [107.1234572, 16.5567923],
                [107.1234745, 16.5569547],
                [107.1235396, 16.557136],
                [107.1235838, 16.5572221],
                [107.1236869, 16.5573734],
                [107.1237436, 16.5574387],
                [107.1238714, 16.5575563],
                [107.1243864, 16.5579162],
                [107.1243937, 16.5579639],
                [107.1243918, 16.5580602],
                [107.1243815, 16.558112],
                [107.1243648, 16.5581623],
                [107.1243132, 16.5582553],
                [107.123571, 16.5591503],
                [107.1234789, 16.5592409],
                [107.123394, 16.5593378],
                [107.123317, 16.5594405],
                [107.1232481, 16.5595485],
                [107.1231879, 16.559661],
                [107.1231365, 16.5597776],
                [107.1230664, 16.5599999],
                [107.1230286, 16.5602293],
                [107.1230239, 16.5604615],
                [107.1240056, 16.5614076],
                [107.1241373, 16.5614981],
                [107.1243844, 16.5616989],
                [107.1244991, 16.5618086],
                [107.124615, 16.5619328],
                [107.1247232, 16.5620632],
                [107.1248233, 16.5621994],
                [107.124915, 16.562341],
                [107.124998, 16.5624874],
                [107.125147, 16.5627943],
                [107.1252689, 16.5631121],
                [107.1257095, 16.5635589],
                [107.1265805, 16.5644618],
                [107.1275085, 16.5654542],
                [107.1276358, 16.5655031],
                [107.1277662, 16.5655435],
                [107.1278992, 16.5655753],
                [107.1281522, 16.5656108],
                [107.1283895, 16.5656157],
                [107.1286257, 16.5655936],
                [107.1287423, 16.5655725],
                [107.1294719, 16.5654028],
                [107.1295991, 16.5654614],
                [107.1298624, 16.5655593],
                [107.130111, 16.5656252],
                [107.1303409, 16.565665],
                [107.1305523, 16.5659679],
                [107.1307915, 16.5662512],
                [107.1309249, 16.5663885],
                [107.1312111, 16.5666443],
                [107.1315211, 16.5668733],
                [107.1316964, 16.5669747],
                [107.1318758, 16.5670694],
                [107.1322453, 16.5672384],
                [107.1324584, 16.567321],
                [107.1326751, 16.5673945],
                [107.1328949, 16.5674589],
                [107.1331175, 16.567514],
                [107.1333423, 16.5675597],
                [107.1335367, 16.5675914],
                [107.1339286, 16.5676338],
                [107.1341255, 16.5676445],
                [107.1345198, 16.5676446],
                [107.1350276, 16.5677608],
                [107.135528, 16.5679031],
                [107.1360435, 16.56808],
                [107.1363089, 16.5681837],
                [107.1368293, 16.5684134],
                [107.137084, 16.5685392],
                [107.137835, 16.5698658],
                [107.1380839, 16.5699679],
                [107.1385712, 16.5701938],
                [107.1388092, 16.5703175],
                [107.1392727, 16.5705856],
                [107.1394951, 16.5707281],
                [107.1399259, 16.5710321],
                [107.1403366, 16.5713607],
                [107.1407256, 16.5717126],
                [107.1437662, 16.5713421],
                [107.1438889, 16.5713367],
                [107.1441344, 16.5713428],
                [107.1442812, 16.5713572],
                [107.1445711, 16.5714099],
                [107.1447132, 16.5714481],
                [107.1459004, 16.5719875],
                [107.1461391, 16.5720165],
                [107.1462565, 16.5720423],
                [107.1464846, 16.5721159],
                [107.1467164, 16.5722266],
                [107.1469294, 16.5723676],
                [107.147076, 16.5724348],
                [107.1473783, 16.5725488],
                [107.1476905, 16.5726347],
                [107.1478557, 16.5726681],
                [107.1481902, 16.5727111],
                [107.1485275, 16.5727223],
                [107.1488643, 16.5727015],
                [107.1489336, 16.5728191],
                [107.1490935, 16.5730412],
                [107.1491837, 16.573145],
                [107.1493826, 16.573336],
                [107.1496039, 16.5735029],
                [107.1497277, 16.5735798],
                [107.1498562, 16.573649],
                [107.1501257, 16.5737636],
                [107.150408, 16.573845],
                [107.1508066, 16.5739281],
                [107.1513083, 16.5740628],
                [107.1521819, 16.5740988],
                [107.152358, 16.5741791],
                [107.1527018, 16.5743556],
                [107.1528901, 16.5744641],
                [107.1532539, 16.5746998],
                [107.1537336, 16.5747229],
                [107.1541463, 16.5748899],
                [107.1543984, 16.574822],
                [107.1545206, 16.5747766],
                [107.1546395, 16.5747238],
                [107.1548628, 16.5745986],
                [107.1550656, 16.5744494],
                [107.1557408, 16.5738651],
                [107.1557651, 16.5735294],
                [107.155797, 16.5734512],
                [107.1558213, 16.5734161],
                [107.1558856, 16.5733568],
                [107.1559664, 16.5733172],
                [107.1560105, 16.5733063],
                [107.1561012, 16.5733038],
                [107.1561932, 16.5732902],
                [107.1563737, 16.5732471],
                [107.1564616, 16.5732178],
                [107.1566725, 16.5731224],
                [107.1567717, 16.5730635],
                [107.1569547, 16.572925],
                [107.1573123, 16.5731913],
                [107.1576863, 16.5734361],
                [107.1579175, 16.573572],
                [107.1581538, 16.5736996],
                [107.1583949, 16.5738187],
                [107.1586403, 16.5739293],
                [107.1594282, 16.5739498],
                [107.16102, 16.5748128],
                [107.1614649, 16.5752906],
                [107.1616015, 16.5753946],
                [107.1617549, 16.5754742],
                [107.1619205, 16.5755269],
                [107.1620122, 16.5755434],
                [107.1621984, 16.5755515],
                [107.1623829, 16.5755261],
                [107.1624725, 16.5755012],
                [107.1628481, 16.5753324],
                [107.162905, 16.5753145],
                [107.1630238, 16.5753012],
                [107.1630835, 16.575306],
                [107.1631471, 16.5753197],
                [107.1632079, 16.5753421],
                [107.1632647, 16.5753727],
                [107.1633163, 16.5754108],
                [107.1633616, 16.5754557],
                [107.1634867, 16.5756309],
                [107.1636409, 16.5758916],
                [107.164938, 16.5764053],
                [107.1651738, 16.5771399],
                [107.1653094, 16.5771197],
                [107.1654462, 16.5771086],
                [107.1655834, 16.5771068],
                [107.1657205, 16.5771142],
                [107.1659603, 16.5771497],
                [107.1661938, 16.5772134],
                [107.166307, 16.5772555],
                [107.1672373, 16.5776638],
                [107.1672987, 16.5776973],
                [107.1673561, 16.5777369],
                [107.1674526, 16.577828],
                [107.1674916, 16.5778779],
                [107.1675535, 16.5779875],
                [107.1677411, 16.5779541],
                [107.1678972, 16.5779015],
                [107.1680439, 16.5778282],
                [107.168208, 16.5777942],
                [107.1682919, 16.577789],
                [107.1684593, 16.5778025],
                [107.1686253, 16.5778492],
                [107.168778, 16.5779271],
                [107.1688473, 16.5779769],
                [107.1689684, 16.5780953],
                [107.1690309, 16.5781729],
                [107.1691374, 16.5783401],
                [107.1691809, 16.5784288],
                [107.1692471, 16.5786142],
                [107.1693279, 16.5786334],
                [107.1694105, 16.5786438],
                [107.1694937, 16.578645],
                [107.1696319, 16.5786265],
                [107.1697639, 16.5785831],
                [107.169826, 16.5785525],
                [107.17011, 16.5782186],
                [107.1702345, 16.5781157],
                [107.170378, 16.5780388],
                [107.1705303, 16.577992],
                [107.1706094, 16.5779796],
                [107.1710013, 16.5779701],
                [107.1714653, 16.577982],
                [107.1716966, 16.578001],
                [107.1721376, 16.5780615],
                [107.1723469, 16.5781017],
                [107.1727603, 16.5782034],
                [107.1729638, 16.5782649],
                [107.1734682, 16.5784429],
                [107.1735757, 16.5784686],
                [107.1737747, 16.5784932],
                [107.1739546, 16.5784906],
                [107.1740441, 16.5784806],
                [107.1740414, 16.5780722],
                [107.174084, 16.578011],
                [107.1741137, 16.5779874],
                [107.1741857, 16.5779586],
                [107.1742638, 16.5779585],
                [107.174565, 16.5780634],
                [107.1747003, 16.5781007],
                [107.1749932, 16.5781577],
                [107.1753086, 16.5781849],
                [107.1756252, 16.5781775],
                [107.1756305, 16.5777666],
                [107.175672, 16.5776347],
                [107.1757345, 16.5775079],
                [107.1758178, 16.5773894],
                [107.1758663, 16.5773351],
                [107.1762241, 16.5770843],
                [107.1764077, 16.5769652],
                [107.1768553, 16.5766997],
                [107.1770848, 16.5765763],
                [107.1775546, 16.5763488],
                [107.178514, 16.5763436],
                [107.1795163, 16.577371],
                [107.1796775, 16.5775164],
                [107.1798557, 16.5776422],
                [107.1800545, 16.5777495],
                [107.1801618, 16.5777953],
                [107.1804806, 16.5779034],
                [107.1806606, 16.5780003],
                [107.1807397, 16.5780571],
                [107.1808816, 16.578189],
                [107.1809982, 16.5783419],
                [107.1811149, 16.5784626],
                [107.1811822, 16.5785138],
                [107.181317, 16.5785893],
                [107.1814638, 16.5786398],
                [107.1816167, 16.5783017],
                [107.1825045, 16.5783236],
                [107.1825584, 16.5783673],
                [107.1826467, 16.5784727],
                [107.1826754, 16.5785239],
                [107.1827146, 16.5786338],
                [107.1827245, 16.5786912],
                [107.182754, 16.5796501],
                [107.1828419, 16.5796576],
                [107.1830141, 16.5796938],
                [107.1831861, 16.579761],
                [107.1832707, 16.5798077],
                [107.1834899, 16.5799704],
                [107.1835592, 16.580013],
                [107.1836318, 16.58005],
                [107.1837853, 16.5801064],
                [107.1839526, 16.5801388],
                [107.1841232, 16.5801437],
                [107.1842249, 16.5801165],
                [107.1842719, 16.5800932],
                [107.1843559, 16.5800269],
                [107.1843906, 16.5799846],
                [107.1844391, 16.5798877],
                [107.18447, 16.5797844],
                [107.1845296, 16.5796938],
                [107.1846106, 16.5796277],
                [107.1846579, 16.579604],
                [107.1847606, 16.5795776],
                [107.1849454, 16.5795761],
                [107.1850766, 16.5795847],
                [107.1852069, 16.5796012],
                [107.185463, 16.5796578],
                [107.1869813, 16.5789928],
                [107.1871788, 16.5789231],
                [107.1873851, 16.5788831],
                [107.1875954, 16.5788737],
                [107.1877096, 16.5788816],
                [107.1878227, 16.5788985],
                [107.187934, 16.5789244],
                [107.1880426, 16.5789591],
                [107.1881479, 16.5790023],
                [107.1881935, 16.5790437],
                [107.1883005, 16.579106],
                [107.1884215, 16.5791372],
                [107.188539, 16.579136],
                [107.1885966, 16.5791242],
                [107.18877, 16.5790553],
                [107.1889067, 16.5790256],
                [107.1890576, 16.579022],
                [107.1891377, 16.5790322],
                [107.1892163, 16.5790508],
                [107.1893646, 16.579112],
                [107.1895817, 16.5792572],
                [107.1896633, 16.5792979],
                [107.1898225, 16.579352],
                [107.1899892, 16.579378],
                [107.1900737, 16.5793802],
                [107.1906128, 16.5793545],
                [107.190806, 16.5792388],
                [107.1909453, 16.5792943],
                [107.1912137, 16.5794264],
                [107.1914427, 16.5795691],
                [107.1916562, 16.5797323],
                [107.1921444, 16.5795627],
                [107.1925134, 16.5796639],
                [107.1928742, 16.5797893],
                [107.1932458, 16.579948],
                [107.1936243, 16.5801435],
                [107.1938074, 16.5802516],
                [107.1939737, 16.5806012],
                [107.1947461, 16.5809611],
                [107.1949038, 16.5811438],
                [107.1950435, 16.581351],
                [107.1951057, 16.5814645],
                [107.1952075, 16.5817015],
                [107.1965593, 16.5817015],
                [107.1966728, 16.5816423],
                [107.1967906, 16.5815915],
                [107.1969122, 16.5815494],
                [107.1970367, 16.5815164],
                [107.1972754, 16.5814794],
                [107.1973962, 16.5814732],
                [107.1976376, 16.5814856],
                [107.1977931, 16.581285],
                [107.1980309, 16.5812921],
                [107.1982652, 16.5813313],
                [107.1983793, 16.5813627],
                [107.1985992, 16.5814483],
                [107.1988043, 16.5815629],
                [107.1989, 16.5816303],
                [107.1990752, 16.5817837],
                [107.199307, 16.5817459],
                [107.1995419, 16.5817375],
                [107.1997764, 16.5817588],
                [107.2000059, 16.5818095],
                [107.200419, 16.5818429],
                [107.2004845, 16.5819111],
                [107.2006307, 16.5820323],
                [107.2007945, 16.5821308],
                [107.2009706, 16.5822037],
                [107.2011562, 16.5822504],
                [107.2014423, 16.5822818],
                [107.2016291, 16.5823244],
                [107.2025031, 16.5826115],
                [107.2029054, 16.5822156],
                [107.2030573, 16.5819317],
                [107.2031414, 16.581794],
                [107.2033564, 16.5814878],
                [107.203474, 16.5813416],
                [107.2033963, 16.5809971],
                [107.203409, 16.5809098],
                [107.2034258, 16.5808686],
                [107.2034836, 16.5807904],
                [107.2035652, 16.5807349],
                [107.2045013, 16.580267],
                [107.2045599, 16.5802251],
                [107.2046596, 16.5801231],
                [107.2047015, 16.5800607],
                [107.2047351, 16.5799938],
                [107.2047597, 16.5799234],
                [107.2047749, 16.5798506],
                [107.2047695, 16.5796706],
                [107.2047917, 16.5796069],
                [107.2048354, 16.5795489],
                [107.2049, 16.5795046],
                [107.2049371, 16.5794907],
                [107.2052298, 16.5794445],
                [107.2054893, 16.5793705],
                [107.2056207, 16.5793184],
                [107.2057479, 16.5792574],
                [107.2058703, 16.5791879],
                [107.2059873, 16.5791102],
                [107.2061455, 16.5787246],
                [107.207095, 16.5787195],
                [107.2073598, 16.5788492],
                [107.2074975, 16.5789029],
                [107.2077817, 16.5789868],
                [107.2080592, 16.579037],
                [107.2081997, 16.5790511],
                [107.208482, 16.5790569],
                [107.2087634, 16.5790331],
                [107.2090681, 16.5788901],
                [107.2093981, 16.5787662],
                [107.2097525, 16.5786659],
                [107.2099328, 16.5786269],
                [107.210952, 16.578411],
                [107.211467, 16.5784161],
                [107.2116392, 16.578644],
                [107.2118368, 16.5788505],
                [107.2120571, 16.5790334],
                [107.2122664, 16.5791756],
                [107.2124383, 16.5793094],
                [107.2125976, 16.5794633],
                [107.2126719, 16.579548],
                [107.2128054, 16.5797285],
                [107.2129099, 16.5797543],
                [107.2130164, 16.5797713],
                [107.2131239, 16.5797793],
                [107.2132318, 16.5797783],
                [107.2134218, 16.5797544],
                [107.2135831, 16.5797106],
                [107.2136611, 16.5796809],
                [107.2141814, 16.5796912],
                [107.2142966, 16.5796786],
                [107.2146332, 16.5796147],
                [107.2148466, 16.5795524],
                [107.2150137, 16.5794893],
                [107.2151006, 16.5794674],
                [107.2152784, 16.5794431],
                [107.2153788, 16.5794413],
                [107.215578, 16.5794625],
                [107.2156754, 16.5794856],
                [107.2158731, 16.5795584],
                [107.2159758, 16.5795832],
                [107.2161448, 16.5796064],
                [107.2161448, 16.5788223],
                [107.216297, 16.5786133],
                [107.2163647, 16.5785036],
                [107.216498, 16.5782396],
                [107.2165525, 16.5781025],
                [107.2167742, 16.5780263],
                [107.2170044, 16.577978],
                [107.2172391, 16.5779586],
                [107.2174916, 16.5779701],
                [107.2177399, 16.5780149],
                [107.2179794, 16.5780922],
                [107.2187948, 16.5786012],
                [107.2190205, 16.5786027],
                [107.2191328, 16.578591],
                [107.2192421, 16.5785714],
                [107.2194546, 16.5785087],
                [107.2195338, 16.5784927],
                [107.2196951, 16.5784857],
                [107.2198542, 16.578512],
                [107.2199306, 16.5785373],
                [107.2200726, 16.5786109],
                [107.2201949, 16.578712],
                [107.2202468, 16.5787714],
                [107.220329, 16.5789046],
                [107.2216004, 16.5788737],
                [107.2216659, 16.578878],
                [107.221793, 16.5789083],
                [107.2219092, 16.5789662],
                [107.2220081, 16.5790485],
                [107.2220515, 16.5791005],
                [107.2221166, 16.5792179],
                [107.2221494, 16.5793471],
                [107.2221744, 16.5800048],
                [107.2223858, 16.579996],
                [107.2224909, 16.5799843],
                [107.2227538, 16.5799329],
                [107.2228815, 16.5799368],
                [107.223004, 16.5799719],
                [107.2232069, 16.5800914],
                [107.2233056, 16.5801386],
                [107.2234084, 16.580177],
                [107.2235144, 16.5802062],
                [107.2236228, 16.5802259],
                [107.2237327, 16.5802361],
                [107.2238432, 16.5802365],
                [107.2239532, 16.5802273],
                [107.2240618, 16.5802084],
                [107.2241682, 16.58018],
                [107.2242715, 16.5801424],
                [107.2243707, 16.5800959],
                [107.2254735, 16.5794804],
                [107.2260493, 16.5794356],
                [107.2266269, 16.5794239],
                [107.2272101, 16.5794456],
                [107.2277909, 16.579501],
                [107.2283971, 16.5791359],
                [107.2284186, 16.5794393],
                [107.229411, 16.5807658],
                [107.2294728, 16.5809549],
                [107.2295151, 16.5810454],
                [107.2296215, 16.5812154],
                [107.2297543, 16.5813673],
                [107.2299211, 16.5815048],
                [107.2301096, 16.5816137],
                [107.2303144, 16.5816908],
                [107.2304211, 16.5817168],
                [107.2306395, 16.5817426],
                [107.2317687, 16.5817632],
                [107.232407, 16.5814213],
                [107.2325867, 16.5816192],
                [107.2326503, 16.5816637],
                [107.2327192, 16.5817004],
                [107.2327922, 16.5817285],
                [107.2328684, 16.5817478],
                [107.2329457, 16.5817578],
                [107.2330237, 16.5817586],
                [107.2331012, 16.5817502],
                [107.233177, 16.5817328],
                [107.2332501, 16.5817066],
                [107.2333192, 16.581672],
                [107.2333834, 16.5816295],
                [107.2334394, 16.5815806],
                [107.2335013, 16.5815386],
                [107.2335681, 16.5815043],
                [107.2336388, 16.5814782],
                [107.2337124, 16.5814606],
                [107.2337877, 16.5814519],
                [107.2339335, 16.5814604],
                [107.2340683, 16.5814998],
                [107.2341902, 16.5815676],
                [107.2342926, 16.5816604],
                [107.2344117, 16.5818268],
                [107.2345579, 16.5819721],
                [107.2347271, 16.5820922],
                [107.2349126, 16.582183],
                [107.2350106, 16.5822172],
                [107.2352135, 16.5822619],
                [107.2356543, 16.5822964],
                [107.2358217, 16.5823193],
                [107.2361479, 16.5823853],
                [107.2364625, 16.5824771],
                [107.2366163, 16.5825327],
                [107.2369158, 16.5826629],
                [107.2370576, 16.5825952],
                [107.2371231, 16.5825521],
                [107.2372435, 16.5824461],
                [107.2373434, 16.5823158],
                [107.2374432, 16.5821236],
                [107.2375161, 16.5820433],
                [107.2375596, 16.58201],
                [107.237682, 16.5820616],
                [107.2379361, 16.5821414],
                [107.2380666, 16.5821692],
                [107.238332, 16.5822002],
                [107.2386051, 16.5821974],
                [107.2387409, 16.5821829],
                [107.2390079, 16.5821282],
                [107.2392225, 16.5826835],
                [107.2403115, 16.583686],
                [107.2407031, 16.5842464],
                [107.2414702, 16.5842516],
                [107.2416032, 16.5843072],
                [107.2417319, 16.5843714],
                [107.2418559, 16.5844437],
                [107.2419745, 16.5845238],
                [107.2420871, 16.5846115],
                [107.2421781, 16.5846917],
                [107.2423448, 16.5848667],
                [107.2424895, 16.5850587],
                [107.2428851, 16.5856731],
                [107.2429352, 16.585728],
                [107.2429923, 16.5857763],
                [107.2430554, 16.5858171],
                [107.2431143, 16.585846],
                [107.2432976, 16.5859055],
                [107.2433516, 16.5859335],
                [107.243447, 16.5860073],
                [107.2440076, 16.5863389],
                [107.2444661, 16.5870167],
                [107.244527, 16.5871196],
                [107.244628, 16.5873307],
                [107.2447023, 16.5875471],
                [107.2448713, 16.58729],
                [107.2449546, 16.5871427],
                [107.2450992, 16.5868376],
                [107.2457778, 16.5865806],
                [107.2463572, 16.5860459],
                [107.247237, 16.5857425],
                [107.2473412, 16.5856807],
                [107.24744, 16.5856111],
                [107.2475326, 16.5855342],
                [107.2476185, 16.5854504],
                [107.2476972, 16.5853603],
                [107.2478278, 16.5851684],
                [107.2479238, 16.5849648],
                [107.2479594, 16.5848584],
                [107.2480047, 16.5846393],
                [107.2480142, 16.5845279],
                [107.2479933, 16.5831565],
                [107.2470224, 16.5818712],
                [107.2481811, 16.5804881],
                [107.2483695, 16.5803992],
                [107.248562, 16.5803185],
                [107.2489321, 16.5801899],
                [107.2489863, 16.5801651],
                [107.249084, 16.5800986],
                [107.2491634, 16.5800127],
                [107.2492225, 16.5799068],
                [107.2492543, 16.5797855],
                [107.2492582, 16.5797229],
                [107.2492325, 16.5791102],
                [107.2489268, 16.5789149],
                [107.2493331, 16.5785778],
                [107.2497153, 16.5782156],
                [107.2500801, 16.5778204],
                [107.2502518, 16.5776141],
                [107.2509263, 16.5768814],
                [107.2510893, 16.5767827],
                [107.2511758, 16.5767418],
                [107.2513568, 16.5766783],
                [107.2515529, 16.5766391],
                [107.2516526, 16.5766299],
                [107.2518529, 16.5766329],
                [107.2521082, 16.5766629],
                [107.2522629, 16.5766891],
                [107.2525665, 16.5767657],
                [107.2528434, 16.576867],
                [107.2529773, 16.5769278],
                [107.2532344, 16.5770691],
                [107.2531968, 16.5762053],
                [107.253296, 16.5761728],
                [107.2533837, 16.5761176],
                [107.2534543, 16.5760434],
                [107.2534843, 16.5759959],
                [107.2535224, 16.5758916],
                [107.253535, 16.5757012],
                [107.2535481, 16.5756221],
                [107.253596, 16.5754688],
                [107.2536304, 16.5753958],
                [107.2539693, 16.5748223],
                [107.2545379, 16.574668],
                [107.2545379, 16.5742207],
                [107.2546334, 16.5741118],
                [107.2547445, 16.5740176],
                [107.2548806, 16.573934],
                [107.2549535, 16.5739004],
                [107.2551066, 16.5738505],
                [107.2564634, 16.5735407],
                [107.259069, 16.572923],
                [107.2615653, 16.5723029],
                [107.2646175, 16.5714172],
                [107.264843, 16.5713517],
                [107.2656423, 16.5706833],
                [107.2663182, 16.5704314],
                [107.2665435, 16.5702565],
                [107.2667592, 16.5700508],
                [107.2669512, 16.5698246],
                [107.2670281, 16.5696858],
                [107.2670572, 16.5696123],
                [107.267098, 16.5694468],
                [107.267107, 16.5693557],
                [107.2671014, 16.5689403],
                [107.2672609, 16.5688026],
                [107.2674135, 16.568658],
                [107.267559, 16.5685066],
                [107.2676969, 16.568349],
                [107.267931, 16.5680419],
                [107.2681858, 16.5676493],
                [107.2683014, 16.5675284],
                [107.268367, 16.5674757],
                [107.2685159, 16.5673853],
                [107.2685985, 16.5673492],
                [107.2687723, 16.5672997],
                [107.268862, 16.5672866],
                [107.2689526, 16.5672816],
                [107.269907, 16.5673001],
                [107.2701312, 16.5669238],
                [107.2702503, 16.5667396],
                [107.2705068, 16.5663732],
                [107.2706419, 16.5661946],
                [107.2711247, 16.5656907],
                [107.2711184, 16.5659025],
                [107.2711459, 16.5661377],
                [107.2712004, 16.5663635],
                [107.2712369, 16.5664714],
                [107.2713286, 16.5666805],
                [107.2715619, 16.5669196],
                [107.2718576, 16.5671285],
                [107.2719087, 16.5672198],
                [107.2720259, 16.5673939],
                [107.2721023, 16.5674884],
                [107.2722727, 16.5676626],
                [107.2725315, 16.5680276],
                [107.2726403, 16.568149],
                [107.2727772, 16.5682588],
                [107.2728574, 16.5683078],
                [107.2734026, 16.568696],
                [107.2738069, 16.5691819],
                [107.2739166, 16.5692862],
                [107.274042, 16.5693804],
                [107.2741109, 16.5694233],
                [107.2744879, 16.5696241],
                [107.2747249, 16.5698381],
                [107.2748322, 16.5699172],
                [107.2749335, 16.5699602],
                [107.2750231, 16.569979],
                [107.2750917, 16.570002],
                [107.2751286, 16.570023],
                [107.2751909, 16.5700792],
                [107.275553, 16.5705059],
                [107.2755477, 16.5709429],
                [107.2756396, 16.5710934],
                [107.2757596, 16.5712245],
                [107.2758861, 16.5713208],
                [107.2763792, 16.5715934],
                [107.2764778, 16.5716775],
                [107.2765577, 16.5717783],
                [107.2766196, 16.5719015],
                [107.2766548, 16.5720447],
                [107.2766558, 16.5721917],
                [107.2766378, 16.5723089],
                [107.276638, 16.5723537],
                [107.2766554, 16.5724417],
                [107.2766977, 16.5725274],
                [107.2767272, 16.5725656],
                [107.2768003, 16.5726294],
                [107.2769612, 16.5727091],
                [107.277019, 16.5727174],
                [107.2771355, 16.5727117],
                [107.2772393, 16.5726796],
                [107.2772873, 16.5726546],
                [107.2773716, 16.5725883],
                [107.2774184, 16.5725371],
                [107.2775692, 16.572302],
                [107.2776532, 16.5721872],
                [107.2778216, 16.5719926],
                [107.2779134, 16.5719019],
                [107.2780311, 16.5718176],
                [107.2781628, 16.5717553],
                [107.2782459, 16.5717297],
                [107.2783313, 16.5717129],
                [107.2784182, 16.5717051],
                [107.2785054, 16.5717064],
                [107.278592, 16.5717168],
                [107.2791579, 16.5718582],
                [107.279402, 16.5718659],
                [107.2794635, 16.5718806],
                [107.279522, 16.5719039],
                [107.2795764, 16.5719353],
                [107.2796588, 16.5720085],
                [107.2797185, 16.5720998],
                [107.2797508, 16.5721776],
                [107.2798312, 16.572326],
                [107.2798829, 16.5724015],
                [107.2800025, 16.5725406],
                [107.2800699, 16.5726037],
                [107.2801312, 16.5726402],
                [107.2802648, 16.572692],
                [107.2804045, 16.572713],
                [107.2805427, 16.5727041],
                [107.2806101, 16.5726887],
                [107.2813788, 16.5723697],
                [107.2815639, 16.5736603],
                [107.2819177, 16.5739528],
                [107.2822264, 16.574249],
                [107.2825187, 16.5745729],
                [107.2829325, 16.5750671],
                [107.2829932, 16.5751286],
                [107.2831308, 16.5752349],
                [107.2832064, 16.5752787],
                [107.2833661, 16.5753453],
                [107.2834487, 16.5753679],
                [107.2836185, 16.5753922],
                [107.2837044, 16.5753936],
                [107.2839229, 16.5753729],
                [107.2841849, 16.5753214],
                [107.2843131, 16.575285],
                [107.2846779, 16.5752927],
                [107.2849756, 16.5753184],
                [107.2850541, 16.5753139],
                [107.2851314, 16.5753002],
                [107.2852609, 16.5752541],
                [107.2853129, 16.575226],
                [107.2854632, 16.5751195],
                [107.2855237, 16.5750901],
                [107.2856542, 16.5750562],
                [107.2857233, 16.5750523],
                [107.2857923, 16.5750576],
                [107.2858599, 16.575072],
                [107.2859247, 16.5750953],
                [107.2861166, 16.5752083],
                [107.286188, 16.5752387],
                [107.2863258, 16.5752754],
                [107.2863968, 16.5752841],
                [107.2870101, 16.575312],
                [107.2881782, 16.5765781],
                [107.2883308, 16.5766618],
                [107.2883996, 16.5767144],
                [107.2885179, 16.5768371],
                [107.2886074, 16.5769786],
                [107.2886657, 16.5771343],
                [107.2886825, 16.5772156],
                [107.288712, 16.5775575],
                [107.2887878, 16.5777682],
                [107.2888917, 16.5779676],
                [107.2889508, 16.5780579],
                [107.2890861, 16.5782272],
                [107.2892187, 16.578337],
                [107.2892931, 16.5783816],
                [107.2894509, 16.5784469],
                [107.2896159, 16.5784804],
                [107.2904983, 16.5785087],
                [107.2905939, 16.578614],
                [107.2906674, 16.5787344],
                [107.2907175, 16.5788701],
                [107.2907325, 16.5789429],
                [107.2907397, 16.579091],
                [107.2905546, 16.5793056],
                [107.2903367, 16.5796128],
                [107.2903213, 16.5796496],
                [107.2903052, 16.5797272],
                [107.2903103, 16.5798103],
                [107.2903213, 16.5798506],
                [107.290384, 16.579946],
                [107.2905236, 16.5801275],
                [107.2906949, 16.5803081],
                [107.2909004, 16.5804819],
                [107.2911269, 16.5806346],
                [107.2913699, 16.5807672],
                [107.2914961, 16.5808249],
                [107.2915614, 16.5808435],
                [107.2916961, 16.5808629],
                [107.2918473, 16.5808559],
                [107.2919289, 16.5808392],
                [107.292008, 16.5808138],
                [107.2922749, 16.5807008],
                [107.2924751, 16.5806449],
                [107.2926871, 16.5806127],
                [107.2927943, 16.5806064],
                [107.2936016, 16.5808763],
                [107.2946334, 16.5812466],
                [107.2952344, 16.5814433],
                [107.2958324, 16.5816108],
                [107.2970563, 16.5819046],
                [107.2971318, 16.581926],
                [107.2972758, 16.5819866],
                [107.2974037, 16.5820669],
                [107.2974606, 16.5821128],
                [107.2975619, 16.5822169],
                [107.2973138, 16.5826244],
                [107.2974104, 16.5827979],
                [107.2975407, 16.5828407],
                [107.2976598, 16.5829071],
                [107.2977179, 16.5829519],
                [107.2978177, 16.5830579],
                [107.2978583, 16.5831179],
                [107.2980916, 16.5835446],
                [107.298303, 16.5837162],
                [107.2985204, 16.5838808],
                [107.2989636, 16.5841831],
                [107.2991173, 16.5842698],
                [107.2991887, 16.584321],
                [107.2993126, 16.5844315],
                [107.2994193, 16.5845575],
                [107.2995121, 16.5847121],
                [107.2996259, 16.5848531],
                [107.2996975, 16.5849246],
                [107.2998565, 16.585051],
                [107.2999611, 16.5851567],
                [107.3000433, 16.5852791],
                [107.3000751, 16.5853454],
                [107.3002616, 16.5856551],
                [107.3003144, 16.5857061],
                [107.3003738, 16.58575],
                [107.3004386, 16.5857862],
                [107.3005074, 16.5858139],
                [107.3005793, 16.5858329],
                [107.3006532, 16.5858428],
                [107.300908, 16.5858454],
                [107.301486, 16.5865073],
                [107.3020264, 16.5865111],
                [107.3022222, 16.5865883],
                [107.3023254, 16.5866433],
                [107.3024234, 16.5867065],
                [107.3025033, 16.5867454],
                [107.302587, 16.5867759],
                [107.3027131, 16.5868093],
                [107.302784, 16.586409],
                [107.3028123, 16.5862078],
                [107.3028571, 16.5857764],
                [107.3028713, 16.58556],
                [107.3029675, 16.5854805],
                [107.3030055, 16.5854315],
                [107.3030357, 16.5853773],
                [107.3030573, 16.5853195],
                [107.3030698, 16.5852593],
                [107.3030859, 16.584758],
                [107.3032303, 16.5841928],
                [107.3032308, 16.5840948],
                [107.3032223, 16.5840403],
                [107.3031872, 16.5839355],
                [107.303161, 16.5838865],
                [107.3063314, 16.5830768],
                [107.3067552, 16.5830202],
                [107.3069011, 16.5829302],
                [107.3069787, 16.582893],
                [107.3071414, 16.5828351],
                [107.3073333, 16.5827975],
                [107.307529, 16.5827902],
                [107.3084021, 16.5821796],
                [107.3090243, 16.5821565],
                [107.3092626, 16.5821374],
                [107.3094964, 16.5820897],
                [107.3096238, 16.5820508],
                [107.3097481, 16.5820034],
                [107.3098685, 16.5819477],
                [107.3099846, 16.581884],
                [107.3108643, 16.5813544],
                [107.3110628, 16.5810974],
                [107.3114598, 16.5808969],
                [107.3116046, 16.5805832],
                [107.3118728, 16.5804856],
                [107.3119385, 16.5803802],
                [107.3120123, 16.5802799],
                [107.3122509, 16.5800176],
                [107.3124146, 16.579812],
                [107.3125213, 16.5794014],
                [107.3127631, 16.5793599],
                [107.3129527, 16.5793474],
                [107.3131536, 16.5793647],
                [107.3132475, 16.5794097],
                [107.3135291, 16.5794161],
                [107.3136498, 16.5793943],
                [107.3136954, 16.5794688],
                [107.3137251, 16.5795686],
                [107.3137423, 16.5798635],
                [107.3137532, 16.5799227],
                [107.3137937, 16.5800364],
                [107.3138635, 16.5801474],
                [107.313911, 16.5802002],
                [107.314024, 16.5802876],
                [107.3145819, 16.580573],
                [107.3147106, 16.5807529],
                [107.3147759, 16.5807859],
                [107.3149146, 16.5808336],
                [107.315071, 16.5808565],
                [107.3151556, 16.5808563],
                [107.3153224, 16.5808301],
                [107.3154026, 16.5808043],
                [107.3157942, 16.5806321],
                [107.316041, 16.5804753],
                [107.3162556, 16.580466],
                [107.3164545, 16.5804844],
                [107.3165452, 16.5805017],
                [107.3167223, 16.5805524],
                [107.3168752, 16.5806321],
                [107.3169117, 16.58074],
                [107.3169637, 16.5809611],
                [107.3169806, 16.5810918],
                [107.3169878, 16.5812233],
                [107.3180231, 16.5824367],
                [107.3181193, 16.5824385],
                [107.3183092, 16.5824669],
                [107.3184909, 16.5825276],
                [107.3185769, 16.5825696],
                [107.3186586, 16.5826188],
                [107.3188063, 16.5827375],
                [107.3190263, 16.5829688],
                [107.3190867, 16.583019],
                [107.3192221, 16.5830999],
                [107.3192989, 16.583131],
                [107.3194608, 16.5831685],
                [107.319544, 16.5831745],
                [107.3196924, 16.5831682],
                [107.3198581, 16.5831829],
                [107.320037, 16.5832257],
                [107.3202065, 16.5832953],
                [107.3205364, 16.5833159],
                [107.3212264, 16.5835858],
                [107.3219043, 16.5835472],
                [107.3230147, 16.5837606],
                [107.3230295, 16.5849932],
                [107.3235512, 16.5851179],
                [107.3236502, 16.5851596],
                [107.3237438, 16.5852234],
                [107.3237872, 16.5852644],
                [107.3240769, 16.5856423],
                [107.3241386, 16.5858094],
                [107.3241761, 16.5859739],
                [107.3243629, 16.5859344],
                [107.3244524, 16.5859019],
                [107.3246027, 16.5858238],
                [107.3247367, 16.585722],
                [107.3247678, 16.5857063],
                [107.3248012, 16.5856959],
                [107.3248707, 16.585692],
                [107.3249049, 16.5856984],
                [107.3249674, 16.5857271],
                [107.3252197, 16.5859015],
                [107.3255089, 16.5860692],
                [107.3258364, 16.5862232],
                [107.3266625, 16.5862567],
                [107.3264828, 16.5866448],
                [107.3264626, 16.5867252],
                [107.3264397, 16.5868793],
                [107.3264366, 16.5870253],
                [107.3265646, 16.5870259],
                [107.3265204, 16.5875754],
                [107.326228, 16.5875831],
                [107.3262387, 16.5877579],
                [107.3263594, 16.5878196],
                [107.3263407, 16.5879121],
                [107.3262978, 16.5879991],
                [107.3260161, 16.5883414],
                [107.3260188, 16.5888632],
                [107.3260648, 16.5889619],
                [107.3260805, 16.5890689],
                [107.3260661, 16.5891715],
                [107.326033, 16.5892563],
                [107.3260211, 16.5893324],
                [107.3260349, 16.5894082],
                [107.3260527, 16.5894463],
                [107.3261073, 16.589511],
                [107.3268262, 16.5894622],
                [107.3268959, 16.5895856],
                [107.326515, 16.5899429],
                [107.3262338, 16.5899882],
                [107.3259544, 16.5900431],
                [107.3255199, 16.5901485],
                [107.3254864, 16.5904047],
                [107.3254211, 16.5906882],
                [107.3253724, 16.5908426],
                [107.3253419, 16.5909105],
                [107.3252597, 16.5910353],
                [107.3252082, 16.5910916],
                [107.3250881, 16.591187],
                [107.3243746, 16.5915289],
                [107.3243101, 16.5915684],
                [107.3242509, 16.591615],
                [107.324152, 16.5917268],
                [107.3240908, 16.5918395],
                [107.3240551, 16.5919618],
                [107.3240474, 16.592025],
                [107.3240447, 16.5923746],
                [107.3240279, 16.5924604],
                [107.3240185, 16.5926346],
                [107.3240413, 16.592807],
                [107.3240644, 16.5928907],
                [107.3241332, 16.5930506],
                [107.3241305, 16.5940917],
                [107.3240876, 16.5945505],
                [107.3241949, 16.5945698],
                [107.3242415, 16.5946121],
                [107.3243223, 16.5947076],
                [107.3243887, 16.5948246],
                [107.3244145, 16.594892],
                [107.3244329, 16.5949616],
                [107.324447, 16.5951045],
                [107.3242566, 16.5951919],
                [107.3241681, 16.5953692],
                [107.3241328, 16.5954657],
                [107.3240849, 16.5956648],
                [107.3243397, 16.596472],
                [107.3243505, 16.5971609],
                [107.3245731, 16.5971737],
                [107.3245651, 16.5974976],
                [107.3244727, 16.5975951],
                [107.3243935, 16.5977181],
                [107.3242003, 16.5982122],
                [107.3244363, 16.5985078],
                [107.3245104, 16.5985274],
                [107.3245799, 16.5985187],
                [107.3246357, 16.59849],
                [107.3246589, 16.5984692],
                [107.3248225, 16.5982353],
                [107.3249506, 16.5982673],
                [107.3252115, 16.5983098],
                [107.3254957, 16.5983234],
                [107.3257282, 16.5983134],
                [107.3258184, 16.5983186],
                [107.3259703, 16.598349],
                [107.3260912, 16.5983947],
                [107.326123, 16.5984234],
                [107.3261817, 16.5984967],
                [107.3262253, 16.5985887],
                [107.3266277, 16.5985232],
                [107.3266478, 16.598716],
                [107.3272178, 16.5986864],
                [107.3272151, 16.599081],
                [107.3283683, 16.5990629],
                [107.3286011, 16.599027],
                [107.3287685, 16.5989788],
                [107.3288499, 16.598948],
                [107.3289584, 16.598939],
                [107.3290674, 16.5989396],
                [107.3292579, 16.5989642],
                [107.3293387, 16.598984],
                [107.329495, 16.5990398],
                [107.3295469, 16.5991046],
                [107.3296667, 16.5992207],
                [107.3298117, 16.5993202],
                [107.3299801, 16.599397],
                [107.3301601, 16.5994434],
                [107.3303488, 16.5994439],
                [107.3305355, 16.5994176],
                [107.3307246, 16.599362],
                [107.3308193, 16.5993218],
                [107.330997, 16.5992202],
                [107.3310788, 16.5991594],
                [107.3314396, 16.5988394],
                [107.3315516, 16.5987671],
                [107.3316824, 16.5987062],
                [107.3318312, 16.598662],
                [107.3319999, 16.5985849],
                [107.3321739, 16.5985365],
                [107.3323479, 16.5985156],
                [107.3325232, 16.5985206],
                [107.3327463, 16.598458],
                [107.3330053, 16.5983612],
                [107.333293, 16.5982199],
                [107.333485, 16.5983481],
                [107.3336899, 16.5984564],
                [107.3339199, 16.5985483],
                [107.3341955, 16.5986421],
                [107.3343486, 16.5987081],
                [107.3344973, 16.5987828],
                [107.3340869, 16.5996182],
                [107.3333278, 16.6001477],
                [107.3333734, 16.6002582],
                [107.3328128, 16.6006232],
                [107.3324092, 16.6009683],
                [107.3320961, 16.6011299],
                [107.331799, 16.6013172],
                [107.331538, 16.6015142],
                [107.3312947, 16.6017311],
                [107.33126, 16.6018169],
                [107.3312102, 16.6019945],
                [107.3311944, 16.6020941],
                [107.3311888, 16.6023158],
                [107.3296854, 16.6023917],
                [107.3294979, 16.6024045],
                [107.3293115, 16.6024267],
                [107.3291264, 16.6024582],
                [107.3289433, 16.6024988],
                [107.3287627, 16.6025485],
                [107.3284244, 16.6026688],
                [107.3282604, 16.6027409],
                [107.3279446, 16.6029083],
                [107.3264949, 16.6037617],
                [107.326353, 16.6038526],
                [107.3259813, 16.6041138],
                [107.3249218, 16.6049466],
                [107.3267859, 16.6065993],
                [107.3285776, 16.6079565],
                [107.3275426, 16.6090637],
                [107.3265796, 16.6102888],
                [107.324666, 16.6126899],
                [107.3233687, 16.6141256],
                [107.3231487, 16.6145132],
                [107.3229321, 16.6162163],
                [107.3227553, 16.6168772],
                [107.3224269, 16.6179299],
                [107.3218187, 16.6187384],
                [107.3206653, 16.620086],
                [107.3202598, 16.6206495],
                [107.3197017, 16.621042],
                [107.3192448, 16.6212139],
                [107.3182546, 16.6214846],
                [107.3159697, 16.6221488],
                [107.3145227, 16.6226404],
                [107.311388, 16.6240155],
                [107.3105763, 16.6246773],
                [107.3103231, 16.625216],
                [107.3103236, 16.6255097],
                [107.3104005, 16.6259011],
                [107.3105537, 16.6264148],
                [107.3116755, 16.6290559],
                [107.3124663, 16.6310612],
                [107.3131543, 16.6324794],
                [107.3149636, 16.6360739],
                [107.3155505, 16.6376146],
                [107.3160225, 16.6388863],
                [107.3159469, 16.6393025],
                [107.3153219, 16.6406771],
                [107.3142665, 16.6418032],
                [107.3131702, 16.6418445],
                [107.3125658, 16.640489],
                [107.3135269, 16.6376693],
                [107.3134025, 16.636688],
                [107.3128664, 16.6359327],
                [107.311978, 16.6354667],
                [107.310281, 16.6354677],
                [107.3087959, 16.6361366],
                [107.3082583, 16.6365],
                [107.3078779, 16.6369165],
                [107.3074725, 16.6375779],
                [107.3070166, 16.638386],
                [107.3066507, 16.6399037],
                [107.3068538, 16.6409497],
                [107.3074915, 16.6415176],
                [107.3090436, 16.6430813],
                [107.312211, 16.6459885],
                [107.3137628, 16.647381],
                [107.315175, 16.6487247],
                [107.3155826, 16.6494093],
                [107.3159646, 16.6500694],
                [107.3164483, 16.6507538],
                [107.3169065, 16.6512914],
                [107.3174538, 16.6519513],
                [107.3178862, 16.6522688],
                [107.31842, 16.6524393],
                [107.3187757, 16.6524632],
                [107.3197411, 16.6524617],
                [107.3209766, 16.6526514],
                [107.3213498, 16.6529153],
                [107.3215531, 16.6531676],
                [107.321955, 16.6532958],
                [107.3224856, 16.6530692],
                [107.3226885, 16.6527997],
                [107.3232199, 16.6515509],
                [107.3243221, 16.6498363],
                [107.3250056, 16.6484649],
                [107.3251063, 16.6478775],
                [107.3249133, 16.6463851],
                [107.3248097, 16.6452595],
                [107.3248593, 16.6445498],
                [107.3250109, 16.6440846],
                [107.3252894, 16.6435703],
                [107.3254416, 16.64335],
                [107.3255172, 16.6430561],
                [107.3256438, 16.6427623],
                [107.3257959, 16.6426152],
                [107.3262529, 16.6424432],
                [107.3266084, 16.6424182],
                [107.3270658, 16.6424419],
                [107.3271164, 16.642344],
                [107.3271923, 16.6421482],
                [107.327395, 16.6418541],
                [107.3275218, 16.6417316],
                [107.327598, 16.641756],
                [107.3277507, 16.641927],
                [107.3278275, 16.6422205],
                [107.3280819, 16.6425137],
                [107.3282599, 16.6425869],
                [107.328743, 16.6428308],
                [107.3288196, 16.6430753],
                [107.3287696, 16.6435159],
                [107.3288461, 16.6437361],
                [107.3291516, 16.6440781],
                [107.3299146, 16.6446398],
                [107.3301943, 16.6448106],
                [107.3302964, 16.6450306],
                [107.330246, 16.6452999],
                [107.3300938, 16.6454469],
                [107.3298401, 16.6456187],
                [107.3296117, 16.6458149],
                [107.3294854, 16.6461821],
                [107.3295112, 16.6465002],
                [107.3295116, 16.646696],
                [107.329385, 16.6469163],
                [107.3291063, 16.6473328],
                [107.3290054, 16.6476999],
                [107.3291076, 16.6480913],
                [107.3292098, 16.6484337],
                [107.3287043, 16.6499273],
                [107.3277164, 16.6516173],
                [107.3262082, 16.6536017],
                [107.325422, 16.6543616],
                [107.3249146, 16.6548028],
                [107.3249369, 16.6550964],
                [107.3250044, 16.6554186],
                [107.3249384, 16.6559989],
                [107.3244037, 16.6565156],
                [107.3240025, 16.6567741],
                [107.3234674, 16.6569683],
                [107.322061, 16.6566481],
                [107.3216594, 16.6566487],
                [107.3212579, 16.6567783],
                [107.3205896, 16.6574885],
                [107.3191191, 16.6587802],
                [107.318302, 16.6598673],
                [107.3180509, 16.6605225],
                [107.318254, 16.6619405],
                [107.3186563, 16.6623267],
                [107.3190581, 16.6623261],
                [107.3199952, 16.6623891],
                [107.3210009, 16.6631613],
                [107.322074, 16.6644489],
                [107.3226773, 16.6648993],
                [107.3232803, 16.6651562],
                [107.3249876, 16.6652825],
                [107.3272231, 16.6650785],
                [107.3276619, 16.665025],
                [107.3287807, 16.6652984],
                [107.3302364, 16.6663407],
                [107.33017, 16.6666355],
                [107.3300096, 16.6667982],
                [107.3297881, 16.6671313],
                [107.3296596, 16.6673068],
                [107.3293232, 16.667641],
                [107.3288678, 16.6680159],
                [107.3285905, 16.668193],
                [107.3282206, 16.6683712],
                [107.3279574, 16.6686046],
                [107.3276961, 16.6688691],
                [107.3272387, 16.6686255],
                [107.3268933, 16.6685196],
                [107.3266235, 16.6684528],
                [107.3264281, 16.6684175],
                [107.3258339, 16.6683723],
                [107.3255279, 16.6683921],
                [107.3252041, 16.6684797],
                [107.3249381, 16.66852],
                [107.3247449, 16.6685594],
                [107.3245122, 16.6686243],
                [107.3243337, 16.6687123],
                [107.3241794, 16.6688643],
                [107.3240533, 16.6690423],
                [107.3239713, 16.6692871],
                [107.3238945, 16.6695475],
                [107.3238576, 16.6697323],
                [107.323869, 16.6698467],
                [107.3239133, 16.6699505],
                [107.3242658, 16.6702528],
                [107.3244853, 16.6703613],
                [107.3248084, 16.6705015],
                [107.3251639, 16.6706844],
                [107.325323, 16.6708392],
                [107.3254793, 16.6710323],
                [107.3258788, 16.6714387],
                [107.3261471, 16.6716051],
                [107.3264727, 16.6717893],
                [107.3270046, 16.672063],
                [107.3278254, 16.6724309],
                [107.3285947, 16.6726568],
                [107.3289967, 16.6727088],
                [107.3294972, 16.6727076],
                [107.3301333, 16.6727439],
                [107.3308068, 16.6729478],
                [107.3310241, 16.6734527],
                [107.3313374, 16.6739799],
                [107.3324695, 16.6749421],
                [107.333395, 16.6754526],
                [107.3335302, 16.6761615],
                [107.3338787, 16.6773887],
                [107.3339314, 16.6783866],
                [107.3338991, 16.678568],
                [107.3339394, 16.6791153],
                [107.3339635, 16.6792643],
                [107.3340413, 16.679439],
                [107.3340869, 16.6795187],
                [107.3344463, 16.6799658],
                [107.3346114, 16.6800997],
                [107.3351526, 16.6806056],
                [107.3355986, 16.6811467],
                [107.3360057, 16.6822113],
                [107.3363749, 16.6833619],
                [107.3366532, 16.6838486],
                [107.3371414, 16.6842349],
                [107.3380123, 16.6846254],
                [107.3399521, 16.68562],
                [107.3412935, 16.6869065],
                [107.3420697, 16.6882373],
                [107.342973, 16.6900605],
                [107.3434427, 16.6906397],
                [107.3440205, 16.6908881],
                [107.3452516, 16.6912809],
                [107.3469264, 16.6918579],
                [107.3473958, 16.6922437],
                [107.3477988, 16.6930162],
                [107.3482694, 16.6939817],
                [107.3484181, 16.6948328],
                [107.3485966, 16.6954899],
                [107.3494333, 16.6967276],
                [107.3513319, 16.698035],
                [107.3520377, 16.6984365],
                [107.3532104, 16.6986149],
                [107.3537552, 16.698385],
                [107.3543003, 16.6981841],
                [107.3548325, 16.6981087],
                [107.3556615, 16.6981409],
                [107.3565577, 16.6983669],
                [107.3573241, 16.6985944],
                [107.3577149, 16.6987428],
                [107.3578629, 16.6987693],
                [107.3580899, 16.6987664],
                [107.3581785, 16.698788],
                [107.3582736, 16.6988778],
                [107.3584559, 16.6993411],
                [107.358494, 16.6995677],
                [107.358342, 16.7006025],
                [107.3583734, 16.7012492],
                [107.3583616, 16.7017148],
                [107.3582477, 16.7020339],
                [107.3581085, 16.7022057],
                [107.3579213, 16.7022987],
                [107.3576042, 16.7023477],
                [107.3573468, 16.7024755],
                [107.3572186, 16.7025678],
                [107.3571841, 16.7026477],
                [107.3572111, 16.7029312],
                [107.3573099, 16.7032933],
                [107.3573915, 16.7034853],
                [107.3574101, 16.7035533],
                [107.3574107, 16.7036043],
                [107.357376, 16.7036558],
                [107.3572417, 16.7037367],
                [107.3570439, 16.7039206],
                [107.3568351, 16.7041785],
                [107.356714, 16.7043786],
                [107.3565514, 16.7045563],
                [107.3564112, 16.7046373],
                [107.3561651, 16.7047252],
                [107.3558771, 16.7047626],
                [107.3556954, 16.7048215],
                [107.3554444, 16.7049832],
                [107.3550196, 16.7053855],
                [107.3547272, 16.7057746],
                [107.354581, 16.7058445],
                [107.3544575, 16.7058516],
                [107.3540197, 16.7056636],
                [107.3535171, 16.7054592],
                [107.3534081, 16.7054549],
                [107.3532384, 16.7055363],
                [107.352953, 16.7057779],
                [107.3526229, 16.7062187],
                [107.3517996, 16.7074711],
                [107.351165, 16.7082786],
                [107.3511713, 16.7083069],
                [107.3515778, 16.7088244],
                [107.3521126, 16.7094824],
                [107.352154, 16.7094989],
                [107.3521894, 16.7094986],
                [107.3528592, 16.7089347],
                [107.3531529, 16.7086476],
                [107.3531765, 16.7086473],
                [107.3532481, 16.7087374],
                [107.3537342, 16.7094923],
                [107.3544444, 16.71054],
                [107.3544945, 16.7105451],
                [107.3552883, 16.7100139],
                [107.3557318, 16.7097023],
                [107.3557962, 16.7096732],
                [107.3558551, 16.7096838],
                [107.3560451, 16.709818],
                [107.3564038, 16.710268],
                [107.3568692, 16.7110175],
                [107.3571701, 16.7115647],
                [107.3577683, 16.7125909],
                [107.3579085, 16.7127481],
                [107.3579913, 16.7127812],
                [107.3581098, 16.712848],
                [107.3582606, 16.7131471],
                [107.3585334, 16.713791],
                [107.3599851, 16.7167372],
                [107.3611727, 16.7185627],
                [107.3620576, 16.7201531],
                [107.3626769, 16.7212244],
                [107.3641569, 16.7238298],
                [107.3650664, 16.7255108],
                [107.3659432, 16.7269197],
                [107.3674027, 16.7292982],
                [107.3682994, 16.7308884],
                [107.3695378, 16.7329856],
                [107.3697914, 16.7335049],
                [107.370177, 16.734227],
                [107.3721842, 16.7347033],
                [107.3746209, 16.7356516],
                [107.3768277, 16.7368981],
                [107.382854, 16.7394729],
                [107.3830837, 16.7396584],
                [107.3833834, 16.7399549],
                [107.3835314, 16.7400697],
                [107.3836147, 16.7400998],
                [107.3836966, 16.7400995],
                [107.3840063, 16.7399722],
                [107.3841107, 16.7399464],
                [107.3849218, 16.7401439],
                [107.3850891, 16.7401948],
                [107.3853885, 16.7403152],
                [107.3860258, 16.7406104],
                [107.3863368, 16.740719],
                [107.3865736, 16.7408788],
                [107.3867627, 16.7410258],
                [107.3870014, 16.7412644],
                [107.3871636, 16.741486],
                [107.387273, 16.7417035],
                [107.3873321, 16.7417997],
                [107.387392, 16.7418801],
                [107.3875303, 16.7420226],
                [107.3875768, 16.7420946],
                [107.3875878, 16.7421412],
                [107.3875796, 16.7422442],
                [107.3875964, 16.7423155],
                [107.3876235, 16.7423801],
                [107.3876598, 16.7424214],
                [107.3877324, 16.742462],
                [107.3878029, 16.7424655],
                [107.38791, 16.7424362],
                [107.38824, 16.7422732],
                [107.3883325, 16.7422207],
                [107.3884012, 16.7421695],
                [107.3884425, 16.7421199],
                [107.3885364, 16.7419571],
                [107.3886732, 16.7418071],
                [107.3887477, 16.7417359],
                [107.3888516, 16.7416624],
                [107.3889858, 16.7416091],
                [107.3889166, 16.7428454],
                [107.5168222, 16.9018096],
                [107.6883175, 17.1145026],
                [107.4916985, 17.3077003],
                [107.4639405, 17.3303136],
                [107.4325205, 17.3479565],
                [107.3983839, 17.3600848],
                [107.3625712, 17.3663294],
                [107.326174, 17.3665004],
                [107.2903018, 17.3605935],
                [107.2560478, 17.3487892],
                [107.2244551, 17.3314479],
                [107.1964847, 17.3090983],
                [107.1729866, 17.2824212],
                [107.1356105, 17.311118],
                [107.1233733, 17.3213211],
                [106.9921599, 17.165011],
                [106.9911104, 17.164172],
                [106.9910067, 17.1641061],
                [106.9909497, 17.164082],
                [106.9908264, 17.1640526],
                [106.9906968, 17.1640514],
                [106.9900131, 17.1641616],
                [106.9899353, 17.164159],
                [106.9897835, 17.1641274],
                [106.9897117, 17.1640988],
                [106.9895813, 17.1640181],
                [106.989487, 17.1639254],
                [106.989448, 17.1638726],
                [106.9893882, 17.1637567],
                [106.9893667, 17.1630263],
                [106.9876635, 17.1618167],
                [106.9861776, 17.1605507],
                [106.9843697, 17.1554762],
                [106.9712108, 17.1434714],
                [106.968888, 17.1378121],
                [106.9679224, 17.1368022],
                [106.9674075, 17.1371969],
                [106.9670159, 17.1367663],
                [106.9668904, 17.1364966],
                [106.966815, 17.1363677],
                [106.9666504, 17.1361366],
                [106.9664704, 17.1359364],
                [106.9663721, 17.1358436],
                [106.9662414, 17.1358503],
                [106.9661763, 17.1358433],
                [106.9660504, 17.135809],
                [106.9659913, 17.1357821],
                [106.9658889, 17.1357146],
                [106.9658035, 17.1356283],
                [106.9657928, 17.1353361],
                [106.9656338, 17.1351921],
                [106.9655003, 17.1350262],
                [106.9653992, 17.1348504],
                [106.9653293, 17.1346705],
                [106.9653046, 17.1345774],
                [106.9642746, 17.1337059],
                [106.9638079, 17.1329113],
                [106.9632578, 17.1323393],
                [106.9629925, 17.132045],
                [106.9624887, 17.1314485],
                [106.9622469, 17.1311427],
                [106.9574819, 17.1279489],
                [106.9567591, 17.1261495],
                [106.9559973, 17.1254677],
                [106.9544685, 17.1245295],
                [106.9535994, 17.1236888],
                [106.9536, 17.1233161],
                [106.9536102, 17.12313],
                [106.9536281, 17.1229327],
                [106.9537035, 17.1223431],
                [106.9538279, 17.1215369],
                [106.9539857, 17.1207127],
                [106.9536799, 17.1202539],
                [106.9530094, 17.1196848],
                [106.9509065, 17.119085],
                [106.9488573, 17.1197618],
                [106.9270617, 17.1083442],
                [106.9238162, 17.1083596],
                [106.922695, 17.1066163],
                [106.9206083, 17.105837],
                [106.9135433, 17.1017917],
                [106.9113976, 17.1003048],
                [106.9104427, 17.0991922],
                [106.9081521, 17.0979514],
                [106.9063872, 17.0967977],
                [106.9062565, 17.096704],
                [106.906132, 17.096603],
                [106.9060141, 17.0964949],
                [106.9059032, 17.0963802],
                [106.9057171, 17.0961508],
                [106.90557, 17.095922],
                [106.905382, 17.0955571],
                [106.9053071, 17.0954387],
                [106.9052241, 17.0953254],
                [106.9051334, 17.0952177],
                [106.9050354, 17.0951159],
                [106.9049298, 17.0950202],
                [106.9048178, 17.0949314],
                [106.9046998, 17.09485],
                [106.9045763, 17.0947764],
                [106.904448, 17.0947109],
                [106.9038847, 17.0947032],
                [106.9030023, 17.0953826],
                [106.9028221, 17.0954203],
                [106.90264, 17.095449],
                [106.9024567, 17.0954686],
                [106.9022725, 17.0954789],
                [106.902088, 17.0954799],
                [106.9019037, 17.0954718],
                [106.9017202, 17.0954543],
                [106.9013883, 17.0953989],
                [106.9012249, 17.0953598],
                [106.9009048, 17.0952595],
                [106.9003254, 17.0939417],
                [106.89973, 17.093229],
                [106.8975788, 17.0916241],
                [106.896919, 17.091096],
                [106.8962806, 17.090573],
                [106.8950415, 17.0901218],
                [106.8940088, 17.0893975],
                [106.8938231, 17.0893963],
                [106.8936387, 17.0894167],
                [106.8934513, 17.0894607],
                [106.8932712, 17.089527],
                [106.892547, 17.0899833],
                [106.8914285, 17.0911011],
                [106.891375, 17.0911296],
                [106.8912587, 17.0911655],
                [106.8911426, 17.0911718],
                [106.8910342, 17.0911524],
                [106.8879604, 17.0895065],
                [106.8876322, 17.0891886],
                [106.8874768, 17.0890218],
                [106.8872599, 17.0887685],
                [106.8871417, 17.0885938],
                [106.8870914, 17.0885015],
                [106.8870095, 17.0883083],
                [106.8869782, 17.0882085],
                [106.8869358, 17.0880041],
                [106.8829822, 17.0824764],
                [106.8699306, 17.0813022],
                [106.8716633, 17.0796408],
                [106.8701827, 17.0795741],
                [106.8683481, 17.0792767],
                [106.8673738, 17.0791069],
                [106.8654299, 17.0787434],
                [106.864659, 17.0783706],
                [106.8638742, 17.0780255],
                [106.8631654, 17.0777424],
                [106.8624473, 17.0774819],
                [106.8607092, 17.0762307],
                [106.8592071, 17.0749077],
                [106.8582952, 17.0735539],
                [106.8578553, 17.072313],
                [106.8578714, 17.0711027],
                [106.8578845, 17.0709241],
                [106.8579358, 17.0705694],
                [106.8579772, 17.0703799],
                [106.8580282, 17.0701926],
                [106.8580887, 17.0700079],
                [106.8593466, 17.0686925],
                [106.8611276, 17.0673387],
                [106.8688846, 17.0613181],
                [106.8701881, 17.0601745],
                [106.8708426, 17.0592719],
                [106.8714085, 17.0587437],
                [106.872704, 17.0570103],
                [106.8734144, 17.0561998],
                [106.8740988, 17.0553692],
                [106.8747505, 17.054527],
                [106.8753755, 17.0536665],
                [106.8773389, 17.0507535],
                [106.877709, 17.0496354],
                [106.8768561, 17.0487379],
                [106.8657517, 17.0425577],
                [106.8648232, 17.041622],
                [106.8647057, 17.041496],
                [106.8644911, 17.0412293],
                [106.8637937, 17.0400702],
                [106.8635134, 17.0393316],
                [106.8634898, 17.0394191],
                [106.8634178, 17.0395859],
                [106.8633699, 17.0396637],
                [106.8632752, 17.039782],
                [106.8632211, 17.0398356],
                [106.8627884, 17.0401644],
                [106.862635, 17.040292],
                [106.8625097, 17.0404052],
                [106.8622729, 17.0406446],
                [106.8622868, 17.0389872],
                [106.8623029, 17.0388127],
                [106.8623877, 17.0383281],
                [106.8624073, 17.0381711],
                [106.8624276, 17.0378289],
                [106.8624258, 17.0376442],
                [106.8623373, 17.0370133],
                [106.8622085, 17.0368646],
                [106.8620684, 17.036751],
                [106.8619913, 17.036703],
                [106.8612617, 17.0363466],
                [106.8601632, 17.0362995],
                [106.8595994, 17.0362405],
                [106.8593193, 17.0361979],
                [106.8590409, 17.0361465],
                [106.8585434, 17.0360322],
                [106.8579003, 17.0358494],
                [106.8575889, 17.0357853],
                [106.8574315, 17.0357619],
                [106.8570708, 17.0357303],
                [106.8568897, 17.035726],
                [106.8557954, 17.0359773],
                [106.8550068, 17.0357157],
                [106.8541324, 17.0358439],
                [106.8536818, 17.0355003],
                [106.8535696, 17.0354688],
                [106.8534605, 17.0354287],
                [106.8533552, 17.0353801],
                [106.8532544, 17.0353235],
                [106.8530705, 17.035189],
                [106.8529131, 17.0350295],
                [106.8528449, 17.0349412],
                [106.8519491, 17.0333307],
                [106.8518003, 17.0331474],
                [106.8517175, 17.0330625],
                [106.8515368, 17.0329076],
                [106.8513266, 17.032768],
                [106.851209, 17.0327051],
                [106.850962, 17.0326024],
                [106.8498355, 17.0325254],
                [106.8484193, 17.0335102],
                [106.8441975, 17.0346796],
                [106.8435162, 17.0352182],
                [106.843071, 17.0348745],
                [106.841113, 17.035013],
                [106.8408604, 17.0349592],
                [106.8406033, 17.034931],
                [106.8404608, 17.0349265],
                [106.8401762, 17.0349413],
                [106.8398952, 17.0349874],
                [106.839458, 17.0352746],
                [106.8378407, 17.0346925],
                [106.8376559, 17.034714],
                [106.8374773, 17.034764],
                [106.8373918, 17.0347993],
                [106.8372327, 17.0348884],
                [106.837092, 17.0349997],
                [106.8369716, 17.035131],
                [106.8367678, 17.0361055],
                [106.8366885, 17.0363438],
                [106.8365743, 17.0365688],
                [106.8364309, 17.0367721],
                [106.8362627, 17.0369508],
                [106.8360704, 17.0371057],
                [106.835078, 17.0372595],
                [106.8329966, 17.0383469],
                [106.8319893, 17.0384744],
                [106.831904, 17.0384787],
                [106.8317338, 17.038464],
                [106.8316506, 17.0384451],
                [106.8314978, 17.038388],
                [106.8313463, 17.0383481],
                [106.8311894, 17.038338],
                [106.831111, 17.0383443],
                [106.8309737, 17.0383744],
                [106.8309077, 17.0383982],
                [106.8307838, 17.0384623],
                [106.8306899, 17.0391419],
                [106.829805, 17.0415607],
                [106.82972, 17.0417437],
                [106.8296674, 17.0418304],
                [106.8295431, 17.0419916],
                [106.8293876, 17.0421409],
                [106.8292009, 17.0422714],
                [106.8289949, 17.0423719],
                [106.8287748, 17.0424398],
                [106.8278682, 17.0426141],
                [106.8276949, 17.0426789],
                [106.8273405, 17.0427868],
                [106.82716, 17.0428299],
                [106.8268032, 17.0428923],
                [106.8266278, 17.0429124],
                [106.826275, 17.0429321],
                [106.8240541, 17.0429526],
                [106.8237978, 17.0429693],
                [106.8235423, 17.0429945],
                [106.8230349, 17.0430706],
                [106.8227696, 17.0431245],
                [106.8225066, 17.0431878],
                [106.8221685, 17.0432792],
                [106.8220893, 17.0432912],
                [106.8219291, 17.0432947],
                [106.8217692, 17.0432702],
                [106.821691, 17.0432474],
                [106.8215427, 17.0431817],
                [106.8214739, 17.0431393],
                [106.8214095, 17.0430911],
                [106.8208623, 17.0425064],
                [106.8204868, 17.0417679],
                [106.8199948, 17.0411476],
                [106.8198053, 17.0408569],
                [106.8197222, 17.0407053],
                [106.8195799, 17.040391],
                [106.8195212, 17.0402292],
                [106.8194337, 17.0399178],
                [106.8194014, 17.0397594],
                [106.8193602, 17.0394394],
                [106.8192798, 17.0383623],
                [106.8191249, 17.0381785],
                [106.8190375, 17.038095],
                [106.8188453, 17.0379468],
                [106.8186454, 17.0378324],
                [106.8185399, 17.0377851],
                [106.8183202, 17.0377114],
                [106.8182069, 17.0376852],
                [106.818053, 17.0375385],
                [106.8179226, 17.0373724],
                [106.8178246, 17.0372034],
                [106.8177509, 17.0370236],
                [106.8177049, 17.0368453],
                [106.8176284, 17.0366769],
                [106.8175266, 17.0365271],
                [106.8174046, 17.0363986],
                [106.8173359, 17.0363417],
                [106.8172628, 17.0362902],
                [106.8171508, 17.0362453],
                [106.8169182, 17.0361795],
                [106.8167987, 17.0361589],
                [106.816678, 17.0361465],
                [106.8164551, 17.0361459],
                [106.8162341, 17.0361735],
                [106.8160674, 17.0362163],
                [106.815946, 17.0362304],
                [106.8158251, 17.0362132],
                [106.815715, 17.0361671],
                [106.8156213, 17.0360953],
                [106.8151438, 17.0354695],
                [106.8149753, 17.035384],
                [106.8148115, 17.0352903],
                [106.814653, 17.0351888],
                [106.8145001, 17.0350797],
                [106.8142915, 17.0349077],
                [106.8141946, 17.034814],
                [106.8140213, 17.0346178],
                [106.8138736, 17.0344111],
                [106.8138081, 17.0343026],
                [106.8136618, 17.0343188],
                [106.8135169, 17.0343442],
                [106.8133741, 17.0343787],
                [106.813234, 17.0344222],
                [106.8129729, 17.0345311],
                [106.8128525, 17.0345951],
                [106.8126252, 17.0347437],
                [106.8114102, 17.034695],
                [106.8112081, 17.0346546],
                [106.8110169, 17.0345802],
                [106.81087, 17.0344936],
                [106.8107082, 17.0343686],
                [106.8105118, 17.0341815],
                [106.8104231, 17.0340795],
                [106.8102864, 17.033892],
                [106.8101715, 17.0336915],
                [106.8101227, 17.0335871],
                [106.8099564, 17.0329614],
                [106.8094468, 17.0317304],
                [106.8095705, 17.0315353],
                [106.8097026, 17.0313453],
                [106.8098429, 17.0311608],
                [106.8099912, 17.030982],
                [106.8101471, 17.0308093],
                [106.8103105, 17.030643],
                [106.8108852, 17.0301086],
                [106.8111634, 17.0298326],
                [106.8117106, 17.029253],
                [106.8112171, 17.0287811],
                [106.8107396, 17.0276886],
                [106.8101335, 17.027268],
                [106.8099354, 17.0271055],
                [106.8097429, 17.0269063],
                [106.8096498, 17.0267898],
                [106.8095648, 17.0266679],
                [106.8095621, 17.0254548],
                [106.8092269, 17.0244571],
                [106.8090616, 17.0243264],
                [106.8089708, 17.0242716],
                [106.8088755, 17.0242245],
                [106.8086822, 17.0241565],
                [106.8084885, 17.0241205],
                [106.80839, 17.0241135],
                [106.8081057, 17.0242007],
                [106.8079384, 17.0239471],
                [106.8078448, 17.0238268],
                [106.807639, 17.0236005],
                [106.8074081, 17.0233937],
                [106.8071562, 17.0232107],
                [106.8066806, 17.0224732],
                [106.8065339, 17.0222051],
                [106.8063764, 17.0218652],
                [106.8062697, 17.0216965],
                [106.8061584, 17.021559],
                [106.8060576, 17.0214599],
                [106.8058371, 17.0212811],
                [106.8055947, 17.0211305],
                [106.8054664, 17.0210666],
                [106.8052419, 17.0209771],
                [106.8050088, 17.020911],
                [106.8044731, 17.0208214],
                [106.8040636, 17.0207229],
                [106.803852, 17.0206581],
                [106.8036435, 17.0205846],
                [106.8034387, 17.0205024],
                [106.803385, 17.0194559],
                [106.8030256, 17.0178196],
                [106.8031663, 17.0176102],
                [106.8033335, 17.0174194],
                [106.8035245, 17.0172503],
                [106.8037458, 17.0170998],
                [106.8039858, 17.0169784],
                [106.80471, 17.0169938],
                [106.804829, 17.0168827],
                [106.8049246, 17.0167527],
                [106.8049978, 17.0165965],
                [106.8050373, 17.0164295],
                [106.8050104, 17.0158191],
                [106.8047529, 17.0152241],
                [106.8045385, 17.0144131],
                [106.8045171, 17.0142443],
                [106.804517, 17.0141592],
                [106.8045384, 17.0139904],
                [106.8045621, 17.0138997],
                [106.8046341, 17.013726],
                [106.8046818, 17.0136445],
                [106.8047368, 17.0135672],
                [106.8050096, 17.0132315],
                [106.8051363, 17.0130569],
                [106.8053698, 17.0126952],
                [106.8055865, 17.0122968],
                [106.8056828, 17.012092],
                [106.8058509, 17.0116728],
                [106.8059224, 17.0114589],
                [106.8061745, 17.0106125],
                [106.8065391, 17.009645],
                [106.8065624, 17.0095473],
                [106.8065817, 17.0093746],
                [106.8065562, 17.0089545],
                [106.8065711, 17.0087718],
                [106.8065872, 17.008681],
                [106.806609, 17.0085914],
                [106.8069631, 17.0076783],
                [106.8070101, 17.0073697],
                [106.8070467, 17.0072177],
                [106.8070918, 17.0070678],
                [106.8071862, 17.0068228],
                [106.807242, 17.0067036],
                [106.8078643, 17.0059341],
                [106.8083095, 17.0051749],
                [106.8083439, 17.0051028],
                [106.808388, 17.0049502],
                [106.808398, 17.0047921],
                [106.80839, 17.0047132],
                [106.8083511, 17.004567],
                [106.8083205, 17.0044974],
                [106.8082517, 17.0043804],
                [106.8082137, 17.0042689],
                [106.808209, 17.0041644],
                [106.8082279, 17.004074],
                [106.8082452, 17.0040309],
                [106.8084973, 17.0036693],
                [106.8090445, 17.0036436],
                [106.8091238, 17.0036042],
                [106.8091983, 17.0035569],
                [106.8092671, 17.0035024],
                [106.8093297, 17.0034414],
                [106.8093851, 17.0033743],
                [106.809466, 17.0032394],
                [106.8095185, 17.0030923],
                [106.8095407, 17.0029383],
                [106.8093127, 17.0020611],
                [106.8087468, 17.0014378],
                [106.808678, 17.0013019],
                [106.8086548, 17.0012297],
                [106.8086322, 17.0010829],
                [106.8086403, 17.0009373],
                [106.8086777, 17.00075],
                [106.808677, 17.0006323],
                [106.8086475, 17.0004982],
                [106.8085885, 17.0003733],
                [106.8079019, 16.9995833],
                [106.8081254, 16.9991352],
                [106.8081352, 16.9990523],
                [106.8081297, 16.9990095],
                [106.8080977, 16.9989292],
                [106.806624, 16.9981869],
                [106.8064297, 16.9980723],
                [106.8062542, 16.9979327],
                [106.8061013, 16.9977716],
                [106.805974, 16.9975923],
                [106.8058741, 16.9973979],
                [106.8056676, 16.9967361],
                [106.8058687, 16.9963872],
                [106.8058987, 16.996312],
                [106.8059191, 16.9962339],
                [106.8059296, 16.996154],
                [106.80593, 16.9960735],
                [106.8059204, 16.9959936],
                [106.8059009, 16.9959153],
                [106.8058464, 16.99579],
                [106.8058096, 16.995732],
                [106.8057185, 16.995628],
                [106.805405, 16.9954392],
                [106.8053232, 16.9953629],
                [106.8052652, 16.9952689],
                [106.8052363, 16.9951714],
                [106.805233, 16.9950702],
                [106.8052411, 16.99502],
                [106.8053752, 16.9944942],
                [106.8051445, 16.9940428],
                [106.8052089, 16.9930372],
                [106.8048334, 16.9925345],
                [106.8046286, 16.9923234],
                [106.8044047, 16.9921308],
                [106.8042862, 16.992042],
                [106.8041378, 16.9919417],
                [106.8039839, 16.9918492],
                [106.8038249, 16.9917649],
                [106.803031, 16.9917239],
                [106.8018401, 16.9915392],
                [106.8013665, 16.9914853],
                [106.8008974, 16.9914031],
                [106.8006651, 16.9913515],
                [106.8002027, 16.9912265],
                [106.7997461, 16.9910725],
                [106.7995219, 16.9909851],
                [106.7990827, 16.9907902],
                [106.7986107, 16.9902669],
                [106.7983801, 16.9900629],
                [106.7981274, 16.9898842],
                [106.7979938, 16.9898052],
                [106.797755, 16.9896864],
                [106.7975056, 16.9895897],
                [106.7966801, 16.9895838],
                [106.7965898, 16.9895775],
                [106.7964113, 16.9895487],
                [106.7963124, 16.9895228],
                [106.7961216, 16.9894512],
                [106.7958999, 16.9892822],
                [106.7956978, 16.9890921],
                [106.7955042, 16.9888657],
                [106.7954177, 16.9887451],
                [106.7953384, 16.9886201],
                [106.7946705, 16.9872887],
                [106.7941362, 16.9870647],
                [106.7938739, 16.9869424],
                [106.7936036, 16.9868075],
                [106.7924201, 16.9861831],
                [106.7920796, 16.9861219],
                [106.791735, 16.9860875],
                [106.791387, 16.9860804],
                [106.7910381, 16.9861009],
                [106.7908647, 16.9861215],
                [106.7905211, 16.9861832],
                [106.7889547, 16.9861524],
                [106.7888187, 16.9861591],
                [106.7886836, 16.9861749],
                [106.7885499, 16.9861996],
                [106.7884183, 16.9862332],
                [106.7882895, 16.9862755],
                [106.788162, 16.9863272],
                [106.7880386, 16.9863874],
                [106.78792, 16.9864558],
                [106.7875291, 16.9867212],
                [106.7872381, 16.9868911],
                [106.7869374, 16.98704],
                [106.7866266, 16.9871682],
                [106.7845344, 16.9868501],
                [106.7836761, 16.986019],
                [106.7834512, 16.985829],
                [106.7833464, 16.9857263],
                [106.7831552, 16.9855093],
                [106.7829887, 16.9852781],
                [106.7829144, 16.9851571],
                [106.7818844, 16.9829407],
                [106.7816368, 16.9827008],
                [106.7815229, 16.9825721],
                [106.781316, 16.9822992],
                [106.7812236, 16.9821556],
                [106.7810622, 16.9818559],
                [106.7809332, 16.9815422],
                [106.7808486, 16.9812612],
                [106.7808252, 16.9811395],
                [106.7808111, 16.9810164],
                [106.7808065, 16.9808927],
                [106.7808113, 16.980769],
                [106.7808255, 16.980646],
                [106.7808491, 16.9805242],
                [106.7809154, 16.9803088],
                [106.7810108, 16.9801036],
                [106.781069, 16.980006],
                [106.7812136, 16.9798689],
                [106.7812783, 16.9797937],
                [106.7813909, 16.9796315],
                [106.7814783, 16.9794593],
                [106.7815411, 16.9792775],
                [106.7814767, 16.9788568],
                [106.781329, 16.9784514],
                [106.7811441, 16.9781554],
                [106.780926, 16.9779353],
                [106.7806581, 16.9777562],
                [106.7804575, 16.9776665],
                [106.7794651, 16.977628],
                [106.7793196, 16.9771058],
                [106.7792694, 16.9767485],
                [106.7792611, 16.9762806],
                [106.7792982, 16.975841],
                [106.7792804, 16.9756887],
                [106.7792305, 16.9755302],
                [106.7790507, 16.9751975],
                [106.778993, 16.9750088],
                [106.7789723, 16.9748324],
                [106.7790263, 16.9744234],
                [106.779111, 16.9741417],
                [106.7792934, 16.9737672],
                [106.7795089, 16.9734422],
                [106.7796582, 16.9730848],
                [106.7797328, 16.9727356],
                [106.7797531, 16.9722323],
                [106.7798245, 16.9720227],
                [106.7799147, 16.9718906],
                [106.7800329, 16.9717805],
                [106.7806023, 16.9714532],
                [106.7809173, 16.9711635],
                [106.7810957, 16.970943],
                [106.7812461, 16.9707041],
                [106.7813756, 16.9704263],
                [106.7814672, 16.970135],
                [106.7814982, 16.9699858],
                [106.7815143, 16.9696061],
                [106.7815733, 16.9692316],
                [106.7815203, 16.9689253],
                [106.7813883, 16.9685048],
                [106.7812192, 16.9681541],
                [106.7807579, 16.967564],
                [106.7806613, 16.9667174],
                [106.7800498, 16.9659529],
                [106.7796183, 16.9657373],
                [106.7792269, 16.9654603],
                [106.7788896, 16.9651337],
                [106.7787006, 16.9648975],
                [106.778537, 16.9646445],
                [106.7776144, 16.9640006],
                [106.7763162, 16.9639262],
                [106.7758104, 16.9635996],
                [106.7752863, 16.9633006],
                [106.7742991, 16.9628333],
                [106.7735696, 16.9629307],
                [106.7731941, 16.9626075],
                [106.7727971, 16.9618584],
                [106.7726504, 16.9614934],
                [106.7725418, 16.9611164],
                [106.7724557, 16.9605772],
                [106.7724414, 16.9602274],
                [106.7724591, 16.9598778],
                [106.7725342, 16.959416],
                [106.7725719, 16.9593114],
                [106.7726742, 16.9591131],
                [106.7728052, 16.9589393],
                [106.7730439, 16.9587284],
                [106.7736915, 16.9583504],
                [106.7744997, 16.9578071],
                [106.7748551, 16.9576172],
                [106.7752375, 16.9574473],
                [106.7758334, 16.9572455],
                [106.7775714, 16.9572455],
                [106.7758977, 16.9554906],
                [106.7742938, 16.9543412],
                [106.774443, 16.9539193],
                [106.7746264, 16.9535099],
                [106.77482, 16.953154],
                [106.7750394, 16.9528121],
                [106.7748607, 16.9523751],
                [106.7747807, 16.9521533],
                [106.7746572, 16.9517658],
                [106.7746173, 16.9515998],
                [106.7745873, 16.9514318],
                [106.7745674, 16.9512624],
                [106.7745576, 16.9510949],
                [106.7745576, 16.9509271],
                [106.7745674, 16.9507596],
                [106.7755759, 16.9504414],
                [106.7754689, 16.9501517],
                [106.7753935, 16.949853],
                [106.7753506, 16.9495485],
                [106.7753407, 16.9492273],
                [106.7753669, 16.9489068],
                [106.7755866, 16.9475473],
                [106.7757153, 16.9466852],
                [106.7764664, 16.9458642],
                [106.774739, 16.9449815],
                [106.7733711, 16.94414],
                [106.77303, 16.943967],
                [106.772673, 16.9438263],
                [106.7724897, 16.9437685],
                [106.7723036, 16.9437192],
                [106.7721087, 16.9436773],
                [106.7719118, 16.9436448],
                [106.7716083, 16.943608],
                [106.7715096, 16.9435704],
                [106.77143, 16.9435166],
                [106.7713648, 16.9434472],
                [106.7712253, 16.9431855],
                [106.7711338, 16.9430304],
                [106.7709357, 16.9427288],
                [106.7713568, 16.9421412],
                [106.7713783, 16.9416694],
                [106.771354, 16.941544],
                [106.7713001, 16.9414307],
                [106.7712218, 16.9413351],
                [106.7711744, 16.9412945],
                [106.7707694, 16.9410071],
                [106.7702866, 16.9402656],
                [106.7701578, 16.9391726],
                [106.7705601, 16.9382437],
                [106.7707236, 16.9379868],
                [106.7707956, 16.9378531],
                [106.7709196, 16.9375766],
                [106.7709777, 16.9374148],
                [106.7710267, 16.9372502],
                [106.7710663, 16.9370833],
                [106.7710966, 16.9369146],
                [106.7712253, 16.9359755],
                [106.7713326, 16.9356933],
                [106.7713418, 16.9354587],
                [106.7713556, 16.9353421],
                [106.7714083, 16.9350853],
                [106.77145, 16.9349466],
                [106.7715599, 16.9346779],
                [106.7716277, 16.9345489],
                [106.771971, 16.933815],
                [106.7722928, 16.9333737],
                [106.7721705, 16.9324001],
                [106.7721534, 16.9319881],
                [106.7721657, 16.9315124],
                [106.7722297, 16.9308221],
                [106.7721695, 16.9306127],
                [106.7720604, 16.9304568],
                [106.771553, 16.9301011],
                [106.7712505, 16.9298526],
                [106.7709542, 16.9295665],
                [106.7706835, 16.9292579],
                [106.7690313, 16.9287755],
                [106.7687201, 16.9284984],
                [106.7673013, 16.9281725],
                [106.7671271, 16.9281139],
                [106.7669668, 16.9280262],
                [106.7668306, 16.927917],
                [106.7666736, 16.9277234],
                [106.7662472, 16.9269203],
                [106.7657009, 16.926985],
                [106.7650241, 16.9271435],
                [106.7642301, 16.9271512],
                [106.763247, 16.9273036],
                [106.7631103, 16.9273466],
                [106.7629312, 16.9274573],
                [106.7627978, 16.9276105],
                [106.7624654, 16.9283384],
                [106.7623718, 16.9284296],
                [106.762198, 16.9285117],
                [106.7620769, 16.9285273],
                [106.761682, 16.9284959],
                [106.7614084, 16.9285138],
                [106.7611753, 16.928558],
                [106.7607191, 16.9287027],
                [106.7603474, 16.9287368],
                [106.7599962, 16.928691],
                [106.7595335, 16.9285329],
                [106.7592612, 16.9285406],
                [106.7591211, 16.9285214],
                [106.7588879, 16.9284096],
                [106.7584473, 16.9279544],
                [106.7577616, 16.9274406],
                [106.7574627, 16.9271403],
                [106.7572855, 16.9269117],
                [106.7571291, 16.9266603],
                [106.75685, 16.9260216],
                [106.7565218, 16.925453],
                [106.7563102, 16.9252181],
                [106.7561409, 16.9250892],
                [106.7559692, 16.9249933],
                [106.7557854, 16.9249208],
                [106.7551442, 16.9250034],
                [106.7547675, 16.9250132],
                [106.7543912, 16.9249947],
                [106.7539882, 16.9249293],
                [106.75364, 16.924793],
                [106.7534895, 16.9247555],
                [106.7531938, 16.9247523],
                [106.7528782, 16.9250765],
                [106.7526565, 16.9252381],
                [106.752408, 16.9253262],
                [106.7520423, 16.9253554],
                [106.7518293, 16.9253984],
                [106.7516302, 16.9254638],
                [106.7513598, 16.9255978],
                [106.750981, 16.9254103],
                [106.7506026, 16.9252815],
                [106.7504562, 16.9252514],
                [106.7502282, 16.9252698],
                [106.7500806, 16.9253276],
                [106.7499303, 16.9254188],
                [106.7496933, 16.9254649],
                [106.7488214, 16.9252609],
                [106.7485943, 16.9251437],
                [106.7484654, 16.9250344],
                [106.7483154, 16.9248304],
                [106.74823, 16.9245934],
                [106.7471634, 16.9234967],
                [106.7467378, 16.9231182],
                [106.7466016, 16.9229037],
                [106.7464491, 16.9225304],
                [106.7462829, 16.9222888],
                [106.7458339, 16.9225814],
                [106.7456083, 16.922666],
                [106.7454473, 16.9226829],
                [106.7452862, 16.9226671],
                [106.7450535, 16.9225805],
                [106.7448622, 16.9224269],
                [106.7444688, 16.9218915],
                [106.7437445, 16.9210249],
                [106.7435186, 16.9213095],
                [106.7433258, 16.9214445],
                [106.7428673, 16.9215748],
                [106.7427358, 16.9216504],
                [106.7425782, 16.92181],
                [106.7422094, 16.9218545],
                [106.7407542, 16.9230402],
                [106.7405951, 16.9232261],
                [106.7404153, 16.9235424],
                [106.7403264, 16.9238239],
                [106.7402935, 16.9241505],
                [106.7402092, 16.924471],
                [106.7396412, 16.925518],
                [106.7394811, 16.9257018],
                [106.7393419, 16.9258095],
                [106.7391751, 16.9258988],
                [106.7388756, 16.9259938],
                [106.7386694, 16.9260846],
                [106.7383819, 16.926276],
                [106.7382154, 16.926175],
                [106.7379765, 16.9259454],
                [106.7377881, 16.9258522],
                [106.7376509, 16.9258183],
                [106.7370728, 16.9263841],
                [106.7356175, 16.9264095],
                [106.7354022, 16.9266663],
                [106.735252, 16.9269626],
                [106.7351918, 16.927176],
                [106.7351657, 16.9275061],
                [106.7341822, 16.9275475],
                [106.7338699, 16.927592],
                [106.7335988, 16.927664],
                [106.7334114, 16.9276746],
                [106.7331192, 16.9276297],
                [106.7325778, 16.9273638],
                [106.7323349, 16.9272868],
                [106.7320217, 16.9272282],
                [106.7317035, 16.9272048],
                [106.7313846, 16.9272169],
                [106.7310614, 16.927266],
                [106.7307467, 16.9273515],
                [106.7305938, 16.9274076],
                [106.7302542, 16.9285973],
                [106.7301551, 16.9287961],
                [106.7300216, 16.9289755],
                [106.7298848, 16.9291082],
                [106.7297292, 16.9292203],
                [106.7291644, 16.9295222],
                [106.7288359, 16.9296434],
                [106.7284667, 16.929698],
                [106.7275928, 16.9296748],
                [106.7274422, 16.9297005],
                [106.7270227, 16.9298257],
                [106.726782, 16.9299378],
                [106.7265562, 16.9300753],
                [106.7252237, 16.9300706],
                [106.7245996, 16.9301107],
                [106.7240244, 16.930177],
                [106.723423, 16.9300174],
                [106.7231601, 16.9306743],
                [106.7224333, 16.9314467],
                [106.722247, 16.9317647],
                [106.7220309, 16.9320651],
                [106.7216206, 16.932509],
                [106.7215106, 16.9327758],
                [106.7209634, 16.9333352],
                [106.7211565, 16.9335328],
                [106.7215079, 16.934418],
                [106.7219627, 16.9350227],
                [106.7219989, 16.9351316],
                [106.7220055, 16.9352517],
                [106.7219505, 16.9354328],
                [106.7214864, 16.9360166],
                [106.7212826, 16.9363245],
                [106.7210926, 16.9365357],
                [106.7202097, 16.9373636],
                [106.7198423, 16.9374817],
                [106.7190993, 16.9384285],
                [106.7190792, 16.9386632],
                [106.7186051, 16.9392271],
                [106.7182355, 16.9397368],
                [106.7180249, 16.9398893],
                [106.7177259, 16.9400335],
                [106.7174926, 16.9401091],
                [106.7174263, 16.9401677],
                [106.7172137, 16.9404452],
                [106.7170345, 16.940867],
                [106.7168728, 16.9410338],
                [106.716806, 16.9410764],
                [106.7164573, 16.9411816],
                [106.7159334, 16.9414861],
                [106.715797, 16.9416649],
                [106.7156647, 16.9421233],
                [106.7155883, 16.9421431],
                [106.7151162, 16.9421438],
                [106.7148212, 16.9422618],
                [106.7144548, 16.9423291],
                [106.714246, 16.9424208],
                [106.7138433, 16.9427401],
                [106.7135975, 16.9428705],
                [106.7134157, 16.9429148],
                [106.7129489, 16.9429627],
                [106.712729, 16.9430303],
                [106.7124271, 16.9430847],
                [106.711779, 16.9432951],
                [106.7116242, 16.9433766],
                [106.711451, 16.9435047],
                [106.7112731, 16.943541],
                [106.7110419, 16.9434806],
                [106.7109303, 16.9434181],
                [106.7108327, 16.9433369],
                [106.7105699, 16.9430187],
                [106.7102958, 16.9427762],
                [106.7101554, 16.9426948],
                [106.7100129, 16.9426482],
                [106.7098081, 16.9426313],
                [106.7092824, 16.9424982],
                [106.7090504, 16.9423619],
                [106.7086346, 16.9420129],
                [106.7083769, 16.9420388],
                [106.7081215, 16.9419967],
                [106.7079621, 16.9419322],
                [106.7077243, 16.941786],
                [106.7074693, 16.9416963],
                [106.7065895, 16.9416742],
                [106.7059189, 16.9418359],
                [106.7057354, 16.941723],
                [106.7055334, 16.9416441],
                [106.7053198, 16.9416017],
                [106.7049697, 16.9415928],
                [106.7047316, 16.9415564],
                [106.7045137, 16.9414907],
                [106.7042264, 16.9413484],
                [106.702652, 16.9412611],
                [106.7024267, 16.9415229],
                [106.702078, 16.9419385],
                [106.7019638, 16.9420933],
                [106.7017561, 16.9424158],
                [106.7015775, 16.9427531],
                [106.7013391, 16.9433134],
                [106.7012402, 16.9435196],
                [106.7011323, 16.9437217],
                [106.7010158, 16.9439193],
                [106.7009068, 16.9440884],
                [106.7006698, 16.9444145],
                [106.70057, 16.9445404],
                [106.7003552, 16.9447804],
                [106.7000975, 16.9450249],
                [106.6997927, 16.9452663],
                [106.6991973, 16.9455563],
                [106.6978669, 16.9463157],
                [106.6974634, 16.9465957],
                [106.6972691, 16.9467451],
                [106.6968967, 16.947062],
                [106.6965362, 16.9474131],
                [106.6961918, 16.9477988],
                [106.6960305, 16.9480002],
                [106.6957306, 16.9484186],
                [106.6955924, 16.9486352],
                [106.6953027, 16.949528],
                [106.6947663, 16.9506877],
                [106.6942463, 16.9517607],
                [106.6941304, 16.952068],
                [106.6940841, 16.9522253],
                [106.6940153, 16.9525453],
                [106.6939778, 16.952885],
                [106.693976, 16.9532266],
                [106.6940017, 16.9535069],
                [106.6940098, 16.9537279],
                [106.6939901, 16.9539698],
                [106.693967, 16.9541],
                [106.6932723, 16.9554906],
                [106.6930585, 16.9556065],
                [106.6928324, 16.9556984],
                [106.6925999, 16.9557646],
                [106.6924259, 16.9557981],
                [106.692314, 16.9557951],
                [106.6922595, 16.9557825],
                [106.6922075, 16.9557626],
                [106.6920191, 16.9556519],
                [106.691815, 16.9555708],
                [106.6916079, 16.9555223],
                [106.6914035, 16.9555043],
                [106.6912015, 16.9555058],
                [106.6910042, 16.9555274],
                [106.6908127, 16.9555778],
                [106.6907199, 16.9556139],
                [106.6905445, 16.9557061],
                [106.6904656, 16.9557363],
                [106.6903015, 16.9557775],
                [106.6901185, 16.9557922],
                [106.6900199, 16.955787],
                [106.6899222, 16.9557728],
                [106.689586, 16.95565],
                [106.6892409, 16.9555522],
                [106.6888953, 16.9554809],
                [106.6887206, 16.9554547],
                [106.6881741, 16.9557708],
                [106.6879083, 16.9559401],
                [106.6875429, 16.9561907],
                [106.6874332, 16.9562582],
                [106.6872012, 16.9563717],
                [106.6869557, 16.9564553],
                [106.6867226, 16.9565043],
                [106.6866041, 16.9565187],
                [106.6863656, 16.9565271],
                [106.6860959, 16.9565031],
                [106.6859628, 16.9564783],
                [106.6858318, 16.956445],
                [106.6856015, 16.9563637],
                [106.6853831, 16.9562564],
                [106.685156, 16.9560972],
                [106.6850469, 16.9560039],
                [106.6849445, 16.9559039],
                [106.6848493, 16.9557976],
                [106.6847616, 16.9556856],
                [106.6845095, 16.9550596],
                [106.6844216, 16.9548029],
                [106.6843654, 16.9546789],
                [106.6842296, 16.9544421],
                [106.6841505, 16.9543302],
                [106.6839657, 16.9541157],
                [106.6838601, 16.9540147],
                [106.6837478, 16.9539204],
                [106.6834335, 16.9538278],
                [106.6831318, 16.9537024],
                [106.6828465, 16.9535459],
                [106.682583, 16.9533616],
                [106.6823423, 16.9531507],
                [106.681366, 16.9520732],
                [106.6783351, 16.9517343],
                [106.6780934, 16.9516798],
                [106.6778547, 16.9515952],
                [106.6776236, 16.9514795],
                [106.6775143, 16.9514112],
                [106.6766667, 16.9508365],
                [106.6755295, 16.9503593],
                [106.6747892, 16.9497743],
                [106.6745173, 16.9496269],
                [106.6742328, 16.9495035],
                [106.6739324, 16.9494034],
                [106.6736183, 16.9493289],
                [106.6731819, 16.949268],
                [106.6729108, 16.9492045],
                [106.6726488, 16.9491124],
                [106.6723894, 16.9489874],
                [106.6721469, 16.9488345],
                [106.671484, 16.9483006],
                [106.6710287, 16.9479629],
                [106.6705344, 16.9476268],
                [106.6700256, 16.9473112],
                [106.6692692, 16.9473523],
                [106.669163, 16.9473731],
                [106.6689562, 16.9474346],
                [106.6688564, 16.947475],
                [106.6686764, 16.9475681],
                [106.6684978, 16.9476369],
                [106.6683087, 16.9476716],
                [106.6681277, 16.9476717],
                [106.6679608, 16.9476434],
                [106.6678798, 16.9476191],
                [106.6669142, 16.9471573],
                [106.6666555, 16.9469927],
                [106.6665333, 16.9469007],
                [106.6663209, 16.946714],
                [106.6662222, 16.9466134],
                [106.6657823, 16.9459052],
                [106.6649723, 16.944961],
                [106.6648283, 16.9431946],
                [106.6647987, 16.9430096],
                [106.6647738, 16.9429191],
                [106.6647041, 16.9427442],
                [106.6645953, 16.9425603],
                [106.6644573, 16.9423952],
                [106.6638008, 16.9419101],
                [106.6636318, 16.9417509],
                [106.6634864, 16.9415716],
                [106.6634177, 16.941466],
                [106.6633574, 16.9413559],
                [106.6633056, 16.9412419],
                [106.6632627, 16.9411246],
                [106.6632289, 16.9410046],
                [106.6629606, 16.9395318],
                [106.6623223, 16.938798],
                [106.6622887, 16.9384112],
                [106.6622734, 16.9383127],
                [106.6622175, 16.9381211],
                [106.6621292, 16.937941],
                [106.6620178, 16.9377854],
                [106.6618824, 16.9376485],
                [106.6613191, 16.9372687],
                [106.6606861, 16.9366016],
                [106.660564, 16.9366562],
                [106.6603116, 16.9367461],
                [106.6600773, 16.9368043],
                [106.6598654, 16.9368376],
                [106.6595004, 16.9368438],
                [106.6591358, 16.9368274],
                [106.6586983, 16.9367774],
                [106.6584814, 16.9367401],
                [106.6581807, 16.9368299],
                [106.6580268, 16.9368618],
                [106.6577142, 16.9368992],
                [106.6574387, 16.9369026],
                [106.6573012, 16.9368941],
                [106.6566253, 16.9368222],
                [106.6564725, 16.936864],
                [106.6561609, 16.9369231],
                [106.6558536, 16.9369489],
                [106.6555544, 16.9369439],
                [106.6552573, 16.9369095],
                [106.6543347, 16.9367401],
                [106.6537023, 16.93665],
                [106.6530638, 16.936578],
                [106.6524196, 16.9365246],
                [106.6508049, 16.9360781],
                [106.6505485, 16.9362757],
                [106.6502741, 16.9364489],
                [106.6488147, 16.9371917],
                [106.6473019, 16.9381052],
                [106.6450468, 16.9393769],
                [106.6449015, 16.9394335],
                [106.6448247, 16.9394493],
                [106.644668, 16.9394548],
                [106.6445326, 16.9394309],
                [106.6444052, 16.939381],
                [106.6443461, 16.939347],
                [106.6435683, 16.9387723],
                [106.6434031, 16.9386769],
                [106.6433142, 16.9386408],
                [106.6431277, 16.9385933],
                [106.6430318, 16.9385824],
                [106.6429491, 16.93858],
                [106.6427843, 16.9385943],
                [106.6426241, 16.9386337],
                [106.641503, 16.9390597],
                [106.6413518, 16.9391462],
                [106.6410363, 16.9392957],
                [106.640891, 16.9393519],
                [106.6405929, 16.9394444],
                [106.6404408, 16.9394805],
                [106.6397271, 16.9394946],
                [106.6394591, 16.9395164],
                [106.6392732, 16.9395427],
                [106.639089, 16.939578],
                [106.6377237, 16.9399115],
                [106.6361744, 16.9400932],
                [106.6355305, 16.9401907],
                [106.6349121, 16.9403124],
                [106.63432, 16.9404555],
                [106.634104, 16.9404865],
                [106.6336767, 16.940573],
                [106.6332579, 16.9406915],
                [106.6328821, 16.9408283],
                [106.6325178, 16.9409909],
                [106.6323405, 16.9410815],
                [106.6321023, 16.9412202],
                [106.6319774, 16.9412795],
                [106.6317179, 16.9413771],
                [106.6314449, 16.9414464],
                [106.6311619, 16.9414852],
                [106.6308761, 16.9414921],
                [106.6292345, 16.9412355],
                [106.6288967, 16.9410238],
                [106.6287356, 16.9409072],
                [106.6285801, 16.9407839],
                [106.6284228, 16.9406471],
                [106.6282725, 16.9405033],
                [106.6281295, 16.9403529],
                [106.627904, 16.9400505],
                [106.627652, 16.9397678],
                [106.6274063, 16.9395339],
                [106.6271424, 16.9393188],
                [106.6269745, 16.9392027],
                [106.6268011, 16.9390941],
                [106.6266226, 16.9389933],
                [106.6264394, 16.9389005],
                [106.6260749, 16.9387451],
                [106.6257121, 16.9386256],
                [106.6255271, 16.9385771],
                [106.62534, 16.9385362],
                [106.624015, 16.9383669],
                [106.623382, 16.9383823],
                [106.6232786, 16.9383937],
                [106.6230773, 16.938443],
                [106.6228884, 16.938526],
                [106.6227989, 16.9385805],
                [106.6227149, 16.9386426],
                [106.6226372, 16.9387119],
                [106.6225666, 16.9387877],
                [106.6225137, 16.938865],
                [106.6224527, 16.9389366],
                [106.6223843, 16.9390017],
                [106.6223091, 16.9390597],
                [106.622154, 16.9391463],
                [106.6220706, 16.9391784],
                [106.621896, 16.9392188],
                [106.6209841, 16.9392239],
                [106.6205853, 16.9394273],
                [106.6201794, 16.9396344],
                [106.6190958, 16.9393778],
                [106.618377, 16.9393624],
                [106.6181531, 16.9393217],
                [106.6179313, 16.9392721],
                [106.6177118, 16.9392136],
                [106.6173189, 16.9390848],
                [106.6171266, 16.9390098],
                [106.6166481, 16.9387969],
                [106.6165413, 16.9387634],
                [106.6164319, 16.9387387],
                [106.6163207, 16.9387231],
                [106.6162085, 16.9387166],
                [106.6160961, 16.9387193],
                [106.6159844, 16.9387312],
                [106.615873, 16.9387525],
                [106.6157639, 16.9387829],
                [106.615658, 16.9388222],
                [106.615556, 16.9388701],
                [106.6150349, 16.9391623],
                [106.6149212, 16.939199],
                [106.6146873, 16.9392504],
                [106.6144514, 16.9392719],
                [106.6142176, 16.9392642],
                [106.6141015, 16.9392495],
                [106.6136134, 16.9390905],
                [106.6132137, 16.939052],
                [106.6130674, 16.939061],
                [106.6127765, 16.9390956],
                [106.6124466, 16.9391623],
                [106.6112423, 16.9395677],
                [106.6107327, 16.9395985],
                [106.6104463, 16.9395878],
                [106.6103035, 16.9395754],
                [106.6099896, 16.9395315],
                [106.6098341, 16.939501],
                [106.6092521, 16.9394368],
                [106.609185, 16.9393782],
                [106.6091115, 16.939327],
                [106.6090325, 16.9392839],
                [106.608949, 16.9392495],
                [106.6088179, 16.9392152],
                [106.6084946, 16.9391777],
                [106.608238, 16.9391572],
                [106.6079807, 16.9391443],
                [106.6074711, 16.9391418],
                [106.6068381, 16.9399064],
                [106.606754, 16.9399996],
                [106.6066626, 16.9400862],
                [106.6065644, 16.9401657],
                [106.6064599, 16.9402377],
                [106.6063499, 16.9403015],
                [106.6062385, 16.9403555],
                [106.6061231, 16.9404012],
                [106.6060044, 16.9404383],
                [106.6058831, 16.9404667],
                [106.6052288, 16.9405632],
                [106.6050655, 16.9406848],
                [106.6049084, 16.9408136],
                [106.6047579, 16.9409494],
                [106.6046142, 16.9410918],
                [106.6044778, 16.9412406],
                [106.6043523, 16.941391],
                [106.6041237, 16.9417076],
                [106.6040151, 16.9417745],
                [106.6038134, 16.9419288],
                [106.6037213, 16.9420154],
                [106.603559, 16.9422026],
                [106.6034263, 16.9424055],
                [106.6032654, 16.9428519],
                [106.6031537, 16.9429913],
                [106.6029093, 16.9432533],
                [106.6026491, 16.943483],
                [106.6025158, 16.9435849],
                [106.6022354, 16.9437705],
                [106.6017231, 16.9439655],
                [106.6017124, 16.9447378],
                [106.6012368, 16.9450222],
                [106.6010715, 16.9451455],
                [106.6009855, 16.9452227],
                [106.5996766, 16.9467827],
                [106.599658, 16.9469012],
                [106.5996392, 16.9471401],
                [106.599646, 16.9473906],
                [106.599682, 16.9476499],
                [106.5998484, 16.9483608],
                [106.5998726, 16.9485843],
                [106.5998751, 16.9486967],
                [106.5998627, 16.9489059],
                [106.5998483, 16.9490097],
                [106.5997463, 16.9493228],
                [106.5997308, 16.9494643],
                [106.5997195, 16.9497487],
                [106.5997393, 16.9500778],
                [106.5998778, 16.9511572],
                [106.5998805, 16.9518423],
                [106.5999045, 16.9523102],
                [106.5999556, 16.9527762],
                [106.5999957, 16.9530347],
                [106.6001889, 16.9539897],
                [106.6002774, 16.9546286],
                [106.600279, 16.9549727],
                [106.600256, 16.9553161],
                [106.6001976, 16.9557167],
                [106.6001058, 16.9561115],
                [106.6000724, 16.9561824],
                [106.5999824, 16.9563116],
                [106.5998905, 16.9563995],
                [106.5997839, 16.9564707],
                [106.5983731, 16.9569325],
                [106.5974075, 16.9570505],
                [106.5973893, 16.9571559],
                [106.5973699, 16.9573686],
                [106.5973692, 16.9574938],
                [106.5973914, 16.9577432],
                [106.5967843, 16.9581926],
                [106.5966934, 16.9582459],
                [106.5965009, 16.9583333],
                [106.5962932, 16.9583945],
                [106.5960784, 16.9584262],
                [106.5946011, 16.9584398],
                [106.5943056, 16.9584642],
                [106.5939958, 16.9585219],
                [106.5938347, 16.9585655],
                [106.5936765, 16.9586181],
                [106.5935867, 16.9586617],
                [106.5935015, 16.9587132],
                [106.5933629, 16.9588234],
                [106.5933083, 16.9588788],
                [106.5932125, 16.9590003],
                [106.5931547, 16.9591267],
                [106.5931058, 16.9592564],
                [106.593066, 16.9593889],
                [106.5930355, 16.9595237],
                [106.5930047, 16.959764],
                [106.5930033, 16.960006],
                [106.5930676, 16.9616582],
                [106.5930929, 16.9618508],
                [106.5931159, 16.9622381],
                [106.5931077, 16.962553],
                [106.5930247, 16.9634169],
                [106.5928074, 16.9637152],
                [106.592707, 16.9638696],
                [106.5925232, 16.9641878],
                [106.5924752, 16.9642803],
                [106.5923971, 16.9644726],
                [106.5923434, 16.9646727],
                [106.5922863, 16.9647588],
                [106.5921936, 16.9649422],
                [106.5921316, 16.9651371],
                [106.5921028, 16.9653233],
                [106.592102, 16.9655116],
                [106.5921194, 16.9655659],
                [106.5921302, 16.9656785],
                [106.5921235, 16.9657348],
                [106.5920919, 16.9658312],
                [106.5920672, 16.9658759],
                [106.5916504, 16.9662616],
                [106.5915641, 16.9663738],
                [106.5915254, 16.9664455],
                [106.5914395, 16.9670176],
                [106.5914257, 16.9670718],
                [106.5913732, 16.9671712],
                [106.591292, 16.967251],
                [106.5911933, 16.9673027],
                [106.5911391, 16.9673177],
                [106.5908534, 16.9673409],
                [106.590807, 16.9673567],
                [106.5907261, 16.9674101],
                [106.5906945, 16.9674454],
                [106.5906523, 16.9675287],
                [106.5906188, 16.9677846],
                [106.5903237, 16.9679745],
                [106.5901264, 16.9681232],
                [106.5899482, 16.9682926],
                [106.5898685, 16.9683826],
                [106.5897256, 16.9685748],
                [106.5897068, 16.9695804],
                [106.5896291, 16.9697296],
                [106.5895257, 16.9698639],
                [106.5894088, 16.9699724],
                [106.5892851, 16.970056],
                [106.5878105, 16.9708991],
                [106.5867698, 16.9718944],
                [106.5867943, 16.9728781],
                [106.5868181, 16.9732233],
                [106.586839, 16.9733969],
                [106.5868986, 16.9737415],
                [106.5873157, 16.975696],
                [106.5873357, 16.9758363],
                [106.5873525, 16.9761191],
                [106.5873365, 16.9764166],
                [106.5873143, 16.9765715],
                [106.5872827, 16.976725],
                [106.5872419, 16.9768764],
                [106.5869173, 16.9774767],
                [106.5869837, 16.977541],
                [106.5871289, 16.9776567],
                [106.5872916, 16.9777551],
                [106.5873794, 16.977797],
                [106.5902138, 16.9789697],
                [106.5916407, 16.98016],
                [106.5935129, 16.9810527],
                [106.594044, 16.9815247],
                [106.5941636, 16.9816963],
                [106.5942521, 16.9818844],
                [106.5943068, 16.9820839],
                [106.5943215, 16.9821912],
                [106.5943265, 16.9822994],
                [106.5943215, 16.9824075],
                [106.5943068, 16.9825149],
                [106.5940922, 16.983405],
                [106.5941003, 16.9839078],
                [106.5940942, 16.9840197],
                [106.5940646, 16.9842418],
                [106.5940062, 16.9844805],
                [106.5939125, 16.9847312],
                [106.5933922, 16.9858086],
                [106.5929925, 16.9864294],
                [106.5928106, 16.9866853],
                [106.5926524, 16.9869637],
                [106.5925217, 16.987263],
                [106.5924239, 16.9875735],
                [106.5912626, 16.98773],
                [106.5911096, 16.9877633],
                [106.5910145, 16.9877933],
                [106.5909219, 16.98783],
                [106.5904015, 16.988184],
                [106.5897015, 16.9885867],
                [106.5891838, 16.9886868],
                [106.5890085, 16.9887102],
                [106.5886612, 16.9887759],
                [106.5883201, 16.9888663],
                [106.5881147, 16.9889338],
                [106.5879127, 16.9890103],
                [106.5877146, 16.9890956],
                [106.5875208, 16.9891895],
                [106.5862896, 16.9898606],
                [106.5861636, 16.9899052],
                [106.5860052, 16.9899289],
                [106.5859249, 16.9899283],
                [106.585198, 16.9898526],
                [106.5845409, 16.9898475],
                [106.583981, 16.9897893],
                [106.5837024, 16.9897495],
                [106.5831054, 16.9896393],
                [106.5827879, 16.9895666],
                [106.5818346, 16.9893255],
                [106.581381, 16.9892958],
                [106.5812044, 16.9892778],
                [106.5808555, 16.9892152],
                [106.5805794, 16.9891391],
                [106.5803111, 16.9890408],
                [106.5798417, 16.9890228],
                [106.5797477, 16.9890862],
                [106.5795459, 16.9891917],
                [106.5793471, 16.989262],
                [106.5791577, 16.9893024],
                [106.5773901, 16.9894281],
                [106.5766096, 16.9893357],
                [106.5761688, 16.9892664],
                [106.5757352, 16.9891639],
                [106.5755084, 16.989096],
                [106.5752847, 16.989019],
                [106.5750647, 16.988933],
                [106.5744317, 16.9892101],
                [106.5739059, 16.9892998],
                [106.5733641, 16.9893075],
                [106.572691, 16.9892923],
                [106.5720191, 16.9892495],
                [106.5716841, 16.9892178],
                [106.5713498, 16.9891793],
                [106.5710286, 16.9891092],
                [106.5708713, 16.9890622],
                [106.5705589, 16.988942],
                [106.5704049, 16.9888684],
                [106.5702555, 16.9887868],
                [106.5701737, 16.98879],
                [106.5700114, 16.9888099],
                [106.5699015, 16.9888341],
                [106.5696895, 16.9889074],
                [106.5692228, 16.9889279],
                [106.5689346, 16.9889175],
                [106.568791, 16.9889048],
                [106.5685166, 16.9888666],
                [106.5683806, 16.9888407],
                [106.5681449, 16.9887055],
                [106.568022, 16.9886467],
                [106.5677677, 16.988547],
                [106.567631, 16.9885047],
                [106.5673517, 16.9884412],
                [106.5672098, 16.98842],
                [106.5667713, 16.9883335],
                [106.5660694, 16.9882317],
                [106.5658164, 16.988166],
                [106.56568, 16.9881176],
                [106.5655471, 16.9880608],
                [106.5654183, 16.9879959],
                [106.5652943, 16.9879231],
                [106.5651754, 16.9878428],
                [106.565045, 16.9877812],
                [106.5647757, 16.9876761],
                [106.5644994, 16.9875966],
                [106.563786, 16.9875812],
                [106.5636051, 16.9875521],
                [106.5634229, 16.9875325],
                [106.5632398, 16.9875225],
                [106.5630564, 16.9875222],
                [106.5627104, 16.9875478],
                [106.5626916, 16.9877864],
                [106.5626404, 16.9880654],
                [106.5626246, 16.9882712],
                [106.5626316, 16.9884825],
                [106.5626621, 16.9886919],
                [106.5622316, 16.9893596],
                [106.5621257, 16.9895538],
                [106.5620707, 16.989678],
                [106.5620238, 16.9898052],
                [106.5619433, 16.990508],
                [106.5617447, 16.9906435],
                [106.5613586, 16.9909287],
                [106.5609187, 16.991293],
                [106.5603789, 16.9917008],
                [106.5602854, 16.9917824],
                [106.5601987, 16.9918706],
                [106.5600636, 16.9920415],
                [106.5599669, 16.9922034],
                [106.5599002, 16.9923615],
                [106.5598246, 16.9924982],
                [106.5597761, 16.9925601],
                [106.5596633, 16.9926652],
                [106.559599, 16.9927089],
                [106.5589017, 16.9928218],
                [106.558274, 16.9930167],
                [106.5573379, 16.9930118],
                [106.5566523, 16.9929973],
                [106.5563598, 16.9930138],
                [106.5560713, 16.9930625],
                [106.5557971, 16.9931406],
                [106.5555402, 16.993245],
                [106.5552968, 16.9933758],
                [106.5551994, 16.9934539],
                [106.5549904, 16.9935919],
                [106.5547653, 16.9937043],
                [106.5545217, 16.9937909],
                [106.554263, 16.9938484],
                [106.5539986, 16.9938735],
                [106.55218, 16.9939017],
                [106.5520746, 16.9940218],
                [106.5518828, 16.9942762],
                [106.5517969, 16.9944099],
                [106.5516463, 16.9946885],
                [106.5515199, 16.9949942],
                [106.5514671, 16.9951585],
                [106.5513768, 16.9954903],
                [106.5512617, 16.9958139],
                [106.5511937, 16.9959721],
                [106.5510374, 16.9962795],
                [106.5508417, 16.9965928],
                [106.5507331, 16.9967429],
                [106.5504956, 16.9970285],
                [106.5492243, 16.9982392],
                [106.5491878, 16.9970618],
                [106.5482034, 16.9436985],
                [106.5481171, 16.9390134],
                [106.5479248, 16.9285785],
                [106.5477889, 16.927629],
                [106.5476919, 16.9270538],
                [106.5476784, 16.926857],
                [106.5475226, 16.9257689],
                [106.5451969, 16.9261548],
                [106.5439718, 16.9264865],
                [106.5431515, 16.9265645],
                [106.5416027, 16.9262248],
                [106.540813, 16.925999],
                [106.5404492, 16.9258321],
                [106.5401719, 16.9257338],
                [106.5401251, 16.9256751],
                [106.5400649, 16.9256106],
                [106.5399722, 16.9255463],
                [106.5397957, 16.9254537],
                [106.5397486, 16.9254359],
                [106.5397261, 16.9254301],
                [106.5396877, 16.9254218],
                [106.5395786, 16.9254051],
                [106.5394668, 16.9254019],
                [106.5392852, 16.9253873],
                [106.5390932, 16.9253788],
                [106.5390346, 16.9253704],
                [106.5389876, 16.9253617],
                [106.5388357, 16.9253158],
                [106.5387446, 16.925284],
                [106.538625, 16.9252398],
                [106.5385734, 16.9252189],
                [106.5382688, 16.9250715],
                [106.5380495, 16.9249919],
                [106.5379089, 16.9249356],
                [106.537862, 16.924916],
                [106.5376847, 16.9248149],
                [106.5375563, 16.924661],
                [106.5374355, 16.9244217],
                [106.5373695, 16.9243377],
                [106.5372809, 16.9242719],
                [106.5371948, 16.9242533],
                [106.5370834, 16.9242524],
                [106.536837, 16.9242792],
                [106.5366892, 16.9242903],
                [106.5365277, 16.9242631],
                [106.5363212, 16.9241875],
                [106.5362369, 16.9241838],
                [106.5361998, 16.9241853],
                [106.5361053, 16.9241891],
                [106.535905, 16.9242213],
                [106.5357057, 16.9242702],
                [106.5354388, 16.9243222],
                [106.5352429, 16.9243493],
                [106.5351173, 16.924352],
                [106.534993, 16.9243705],
                [106.5347974, 16.9244269],
                [106.5347445, 16.9244523],
                [106.5345039, 16.9245527],
                [106.5342554, 16.9246245],
                [106.5340819, 16.9246733],
                [106.5339706, 16.9247042],
                [106.5338204, 16.9247503],
                [106.5336974, 16.9247874],
                [106.5336349, 16.9248063],
                [106.5335306, 16.9248349],
                [106.5333338, 16.9248837],
                [106.5331445, 16.9249253],
                [106.5330387, 16.9249585],
                [106.5329661, 16.924994],
                [106.5328916, 16.9250794],
                [106.5328556, 16.9251196],
                [106.5328326, 16.9251647],
                [106.5327912, 16.9253346],
                [106.5327851, 16.9253949],
                [106.532764, 16.9254705],
                [106.5327266, 16.9255453],
                [106.5326527, 16.9256249],
                [106.5325102, 16.9257318],
                [106.532443, 16.9257623],
                [106.5323756, 16.9257676],
                [106.5322534, 16.9257461],
                [106.5320533, 16.9256952],
                [106.5319311, 16.9256761],
                [106.5317988, 16.9256721],
                [106.5317029, 16.9256927],
                [106.5316695, 16.9257255],
                [106.5316131, 16.9258183],
                [106.53158, 16.9258787],
                [106.5315286, 16.9259464],
                [106.5313868, 16.9260773],
                [106.5312549, 16.9261282],
                [106.5310892, 16.926167],
                [106.5309776, 16.9261703],
                [106.5308503, 16.9261463],
                [106.5305452, 16.9259488],
                [106.5304826, 16.9259293],
                [106.5303736, 16.9259277],
                [106.5301666, 16.9260027],
                [106.5301213, 16.9260131],
                [106.5300785, 16.9260146],
                [106.5300396, 16.9260162],
                [106.5299929, 16.9260081],
                [106.5298419, 16.9259528],
                [106.5296997, 16.9258651],
                [106.5294802, 16.9257119],
                [106.5294316, 16.9256703],
                [106.5291872, 16.925461],
                [106.5289776, 16.9252898],
                [106.5285724, 16.9249053],
                [106.5284497, 16.9248166],
                [106.5283456, 16.9247775],
                [106.5282522, 16.9247831],
                [106.5280812, 16.9248195],
                [106.52773, 16.9250468],
                [106.5275441, 16.9251581],
                [106.52728, 16.9253126],
                [106.5271568, 16.9253758],
                [106.5268037, 16.9256053],
                [106.5266836, 16.9256608],
                [106.5265167, 16.9257018],
                [106.5264442, 16.9256473],
                [106.5262685, 16.9255154],
                [106.5261353, 16.9254657],
                [106.5260261, 16.9254352],
                [106.5258437, 16.9252002],
                [106.525667, 16.9247555],
                [106.5257034, 16.9244139],
                [106.5257036, 16.9243455],
                [106.5255616, 16.9242426],
                [106.5254549, 16.9242423],
                [106.5253482, 16.9242421],
                [106.5253116, 16.9246863],
                [106.5252395, 16.9250963],
                [106.5249899, 16.9253691],
                [106.5247198, 16.9254628],
                [106.5245983, 16.9255049],
                [106.5242068, 16.9255724],
                [106.5237449, 16.9254346],
                [106.5235679, 16.9250924],
                [106.5235332, 16.9248189],
                [106.523534, 16.9244771],
                [106.5239266, 16.9238629],
                [106.5247825, 16.922942],
                [106.5249855, 16.9225757],
                [106.525104, 16.9223618],
                [106.5252117, 16.9219176],
                [106.5252126, 16.9215759],
                [106.5250357, 16.9211995],
                [106.5247162, 16.9209937],
                [106.524673, 16.920986],
                [106.5243253, 16.9209244],
                [106.5236493, 16.9210254],
                [106.5222255, 16.9215006],
                [106.5204461, 16.9220091],
                [106.5197884, 16.9220133],
                [106.5195396, 16.9219443],
                [106.5192912, 16.9217044],
                [106.5192562, 16.9214991],
                [106.5192933, 16.9208835],
                [106.5195278, 16.9203237],
                [106.5195795, 16.9202002],
                [106.5195808, 16.9196872],
                [106.519405, 16.9189344],
                [106.5185142, 16.9169127],
                [106.5182735, 16.9163667],
                [106.5177742, 16.9156978],
                [106.5174758, 16.9154072],
                [106.5167662, 16.9147899],
                [106.5165535, 16.9145158],
                [106.5163779, 16.9136604],
                [106.5162402, 16.9118817],
                [106.5161735, 16.9101715],
                [106.516336, 16.90918],
                [106.516634, 16.9087523],
                [106.516907, 16.9083606],
                [106.5170493, 16.9079534],
                [106.5170503, 16.9079505],
                [106.5171222, 16.907677],
                [106.5170878, 16.9071981],
                [106.5168399, 16.9067872],
                [106.5168404, 16.9066161],
                [106.5169121, 16.906377],
                [106.5175883, 16.906105],
                [106.5182393, 16.9056638],
                [106.5184433, 16.9055256],
                [106.518947, 16.904846],
                [106.5198349, 16.9036478],
                [106.5199496, 16.9033831],
                [106.5203535, 16.9024519],
                [106.5204251, 16.9022812],
                [106.520426, 16.9019392],
                [106.5203197, 16.901768],
                [106.5200355, 16.9016646],
                [106.5194817, 16.9017792],
                [106.5192172, 16.9018338],
                [106.5189681, 16.9019015],
                [106.5186128, 16.9017981],
                [106.5184713, 16.9015242],
                [106.5183665, 16.9007715],
                [106.5181984, 16.9003206],
                [106.5179073, 16.8995392],
                [106.5171261, 16.8989413],
                [106.5170556, 16.8988874],
                [106.515956, 16.8978587],
                [106.515478, 16.897071],
                [106.515172, 16.8967754],
                [106.5142953, 16.8958852],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.5440061, 9.4710571],
                [105.5452359, 9.470483],
                [105.5458394, 9.4702118],
                [105.5460379, 9.4701431],
                [105.5462994, 9.4700855],
                [105.5460834, 9.4695134],
                [105.5458099, 9.4690583],
                [105.5443373, 9.4691721],
                [105.5445009, 9.4662407],
                [105.5450642, 9.4577929],
                [105.545267, 9.4505738],
                [105.5469758, 9.4484797],
                [105.5497256, 9.4453594],
                [105.5525355, 9.4424779],
                [105.5527376, 9.4422639],
                [105.5544064, 9.4404015],
                [105.5550891, 9.4396822],
                [105.5557282, 9.4388843],
                [105.5564354, 9.4381531],
                [105.5571693, 9.437387],
                [105.5579212, 9.4365649],
                [105.5585331, 9.4358514],
                [105.5592534, 9.4349978],
                [105.5600156, 9.4341604],
                [105.5607617, 9.4334088],
                [105.5614099, 9.4327058],
                [105.5620936, 9.4318723],
                [105.5628344, 9.4311135],
                [105.5634685, 9.4303921],
                [105.5641411, 9.4296364],
                [105.5647421, 9.4289349],
                [105.565306, 9.4283268],
                [105.5660495, 9.427592],
                [105.5666313, 9.426856],
                [105.5672472, 9.4261482],
                [105.5680705, 9.4252786],
                [105.569372, 9.4237593],
                [105.5715372, 9.4213555],
                [105.5721798, 9.4205886],
                [105.5729661, 9.4197908],
                [105.5736877, 9.4189243],
                [105.5744246, 9.418149],
                [105.5749398, 9.4174641],
                [105.5757447, 9.4165383],
                [105.5764563, 9.4157445],
                [105.5778724, 9.4142729],
                [105.5786576, 9.4133749],
                [105.5805287, 9.4113924],
                [105.5808878, 9.4117247],
                [105.5810165, 9.4118543],
                [105.5811051, 9.411984],
                [105.5814075, 9.4125295],
                [105.582014, 9.4136702],
                [105.5831636, 9.4157305],
                [105.5866019, 9.4217292],
                [105.5876181, 9.423583],
                [105.5884087, 9.423161],
                [105.5902621, 9.422092],
                [105.5914021, 9.4214913],
                [105.5925634, 9.4208483],
                [105.5938308, 9.4202027],
                [105.5941392, 9.4201035],
                [105.5955943, 9.4193718],
                [105.5969837, 9.4201524],
                [105.596855, 9.4203244],
                [105.5991214, 9.4240844],
                [105.5997008, 9.4242696],
                [105.6003553, 9.423587],
                [105.6028873, 9.4211447],
                [105.6027102, 9.420634],
                [105.6023857, 9.4198402],
                [105.6019378, 9.418668],
                [105.6018788, 9.4184881],
                [105.6018466, 9.4182975],
                [105.6018305, 9.4180462],
                [105.6017849, 9.4174826],
                [105.6017661, 9.417292],
                [105.6020169, 9.4170076],
                [105.602147, 9.4168885],
                [105.6022918, 9.4167761],
                [105.6025037, 9.4166504],
                [105.6037483, 9.4159822],
                [105.6039092, 9.4158817],
                [105.6040487, 9.4157414],
                [105.604156, 9.4156039],
                [105.6042713, 9.4154319],
                [105.6043732, 9.415236],
                [105.6046039, 9.4153445],
                [105.6052744, 9.4147412],
                [105.6057197, 9.414339],
                [105.6060791, 9.4140294],
                [105.6063929, 9.4137119],
                [105.6065029, 9.413577],
                [105.6066102, 9.4134923],
                [105.6067148, 9.4134341],
                [105.6068382, 9.4134076],
                [105.6075302, 9.4129631],
                [105.6076884, 9.4128704],
                [105.6079808, 9.4127276],
                [105.6083429, 9.4126323],
                [105.609794, 9.412365],
                [105.6104484, 9.4122592],
                [105.6110653, 9.4122433],
                [105.6118619, 9.4122354],
                [105.6121033, 9.4115844],
                [105.6130944, 9.4089965],
                [105.6135812, 9.4077357],
                [105.6137221, 9.4073454],
                [105.6149411, 9.4077635],
                [105.6154561, 9.4064033],
                [105.6166175, 9.4068373],
                [105.616529, 9.4071495],
                [105.6169642, 9.4072561],
                [105.6170654, 9.4075524],
                [105.6171861, 9.4078137],
                [105.617225, 9.4079579],
                [105.617225, 9.408081],
                [105.6171727, 9.4082596],
                [105.6170285, 9.4086883],
                [105.6166778, 9.4094226],
                [105.6170399, 9.4096038],
                [105.6169018, 9.40992],
                [105.6167972, 9.4101661],
                [105.6170332, 9.4105525],
                [105.6181839, 9.4126879],
                [105.6195518, 9.4152625],
                [105.6197235, 9.4150257],
                [105.6198066, 9.4148868],
                [105.6199843, 9.4145818],
                [105.620042, 9.4146566],
                [105.6200943, 9.4146949],
                [105.6201573, 9.4147201],
                [105.6202405, 9.4147306],
                [105.6203458, 9.4147234],
                [105.6205121, 9.4146949],
                [105.6206596, 9.4146632],
                [105.6208232, 9.4146103],
                [105.6214039, 9.41438],
                [105.6215045, 9.4143536],
                [105.6216051, 9.4143489],
                [105.6217345, 9.4143886],
                [105.6218357, 9.4144568],
                [105.6220007, 9.4146314],
                [105.6223172, 9.4148352],
                [105.6224741, 9.4149489],
                [105.6226062, 9.4150918],
                [105.6227001, 9.4152142],
                [105.6227678, 9.4153697],
                [105.6232412, 9.4172715],
                [105.6233552, 9.4175871],
                [105.6234357, 9.4176995],
                [105.6235483, 9.4178093],
                [105.6237334, 9.4179059],
                [105.6239279, 9.4179747],
                [105.6241223, 9.418025],
                [105.6243389, 9.4180534],
                [105.6252495, 9.4180336],
                [105.625513, 9.4180369],
                [105.6257437, 9.4180607],
                [105.6259704, 9.4181031],
                [105.6262238, 9.4181652],
                [105.6267308, 9.4183306],
                [105.6274241, 9.4185807],
                [105.6277151, 9.4186508],
                [105.6279998, 9.4186961],
                [105.6283367, 9.4186984],
                [105.6286559, 9.4186733],
                [105.6289148, 9.4186217],
                [105.6290951, 9.418545],
                [105.6292292, 9.4184391],
                [105.6293915, 9.4182777],
                [105.6297737, 9.4179198],
                [105.629873, 9.4178471],
                [105.6300098, 9.4177862],
                [105.6301452, 9.4177723],
                [105.6302927, 9.4177948],
                [105.6307527, 9.4179033],
                [105.6307849, 9.4178054],
                [105.6306884, 9.4176731],
                [105.6306052, 9.4175355],
                [105.6305864, 9.417468],
                [105.6305848, 9.4173761],
                [105.6306146, 9.417086],
                [105.6306418, 9.4168495],
                [105.6306709, 9.4166719],
                [105.6306981, 9.4165574],
                [105.6307997, 9.416317],
                [105.6308332, 9.416227],
                [105.6308533, 9.4161238],
                [105.6308533, 9.4159855],
                [105.6308409, 9.415796],
                [105.6308097, 9.4155734],
                [105.6307809, 9.4153816],
                [105.6313415, 9.4152109],
                [105.6315064, 9.4156197],
                [105.6323245, 9.4154028],
                [105.6317371, 9.4129551],
                [105.6313536, 9.411251],
                [105.6344944, 9.4103223],
                [105.6351569, 9.4101714],
                [105.6380215, 9.4092665],
                [105.6387216, 9.409023],
                [105.6383689, 9.4075438],
                [105.6407332, 9.4067447],
                [105.6402799, 9.4050697],
                [105.6406957, 9.4049241],
                [105.6405978, 9.404555],
                [105.6410417, 9.403564],
                [105.6410497, 9.4034687],
                [105.6410336, 9.4033814],
                [105.640811, 9.4025479],
                [105.6410095, 9.4024976],
                [105.6408298, 9.4022515],
                [105.6407869, 9.4021377],
                [105.6407198, 9.401844],
                [105.6406246, 9.4018387],
                [105.6406809, 9.4015079],
                [105.6403336, 9.4014471],
                [105.6401197, 9.4013908],
                [105.6394528, 9.4003549],
                [105.6375493, 9.3974801],
                [105.6366808, 9.3961805],
                [105.6369151, 9.3961679],
                [105.6382804, 9.3962036],
                [105.6385231, 9.3952946],
                [105.6386465, 9.3950433],
                [105.6387967, 9.3948607],
                [105.6389952, 9.3947072],
                [105.6392151, 9.3946066],
                [105.6394297, 9.3945643],
                [105.6399178, 9.3945008],
                [105.6406528, 9.3944849],
                [105.640972, 9.3943605],
                [105.6415057, 9.3940615],
                [105.6420609, 9.3937678],
                [105.6426617, 9.3936249],
                [105.6429595, 9.3935931],
                [105.6433913, 9.3935878],
                [105.6436864, 9.3936355],
                [105.6437963, 9.393691],
                [105.643917, 9.3938022],
                [105.6438741, 9.3939557],
                [105.6445152, 9.3942758],
                [105.644668, 9.3943618],
                [105.6449114, 9.3945264],
                [105.6449121, 9.3946093],
                [105.6453305, 9.3948421],
                [105.6456766, 9.3949969],
                [105.6460427, 9.3950988],
                [105.6467266, 9.395202],
                [105.6467977, 9.3950618],
                [105.6473368, 9.3948871],
                [105.6475112, 9.3946119],
                [105.6477888, 9.394776],
                [105.6483494, 9.3939332],
                [105.6489328, 9.3930004],
                [105.6491446, 9.3926147],
                [105.6494538, 9.3925846],
                [105.6496254, 9.3925444],
                [105.6497583, 9.3924889],
                [105.6498997, 9.3924063],
                [105.650019, 9.3923176],
                [105.6501558, 9.39218],
                [105.6502444, 9.3920398],
                [105.650129, 9.3918995],
                [105.6500781, 9.3917884],
                [105.6500566, 9.3916666],
                [105.6499976, 9.3911586],
                [105.6499976, 9.3905923],
                [105.6499976, 9.3899651],
                [105.6499493, 9.387381],
                [105.6525993, 9.3880016],
                [105.6538948, 9.3858792],
                [105.6549189, 9.3864616],
                [105.6552628, 9.3857602],
                [105.655421, 9.3858316],
                [105.6555444, 9.3859348],
                [105.6556463, 9.3859666],
                [105.6557858, 9.385993],
                [105.6560513, 9.3860116],
                [105.6561023, 9.3859533],
                [105.6563866, 9.3859798],
                [105.6570518, 9.3860856],
                [105.6576633, 9.3862233],
                [105.657666, 9.3863767],
                [105.6578243, 9.3864747],
                [105.6579182, 9.3865911],
                [105.6580657, 9.3867049],
                [105.658114, 9.3867287],
                [105.6581676, 9.3867287],
                [105.6582212, 9.3867155],
                [105.6582347, 9.3870039],
                [105.6591372, 9.3871375],
                [105.6599727, 9.3872871],
                [105.6609169, 9.3874247],
                [105.6608632, 9.3875252],
                [105.6614292, 9.3878613],
                [105.6623492, 9.3882424],
                [105.6624055, 9.3881577],
                [105.6631941, 9.38847],
                [105.6639799, 9.3887663],
                [105.6644654, 9.3888537],
                [105.6650099, 9.3889357],
                [105.6656939, 9.3890257],
                [105.6663403, 9.3890495],
                [105.666512, 9.3887769],
                [105.6669706, 9.3890389],
                [105.6677243, 9.3876919],
                [105.6680113, 9.3879142],
                [105.6680569, 9.3878401],
                [105.668655, 9.3883853],
                [105.6683734, 9.3888563],
                [105.6699988, 9.3894411],
                [105.6702644, 9.3895973],
                [105.6704414, 9.3893174],
                [105.6710476, 9.3896237],
                [105.6725737, 9.3905102],
                [105.6731125, 9.3908265],
                [105.6732688, 9.3906196],
                [105.6745116, 9.3886473],
                [105.6765112, 9.3856609],
                [105.6769752, 9.3850205],
                [105.677218, 9.38474],
                [105.6772757, 9.384658],
                [105.6773132, 9.384564],
                [105.677336, 9.3844661],
                [105.6773494, 9.3843655],
                [105.6773588, 9.384216],
                [105.6776485, 9.3840427],
                [105.6782842, 9.3837278],
                [105.6788099, 9.3836206],
                [105.6791505, 9.3834658],
                [105.6794643, 9.3834023],
                [105.679695, 9.3833838],
                [105.6798626, 9.3833851],
                [105.6800169, 9.3834248],
                [105.6804849, 9.3836285],
                [105.6806847, 9.3837146],
                [105.6810576, 9.3839448],
                [105.6816396, 9.3841882],
                [105.6820795, 9.3843761],
                [105.6824657, 9.3844714],
                [105.6832261, 9.3827354],
                [105.6844908, 9.3799052],
                [105.6851761, 9.378455],
                [105.6862128, 9.3786601],
                [105.6879115, 9.379009],
                [105.6880997, 9.3790477],
                [105.6903407, 9.3795492],
                [105.6913841, 9.3797742],
                [105.691824, 9.3797636],
                [105.6924784, 9.3797662],
                [105.6930953, 9.3797636],
                [105.6940874, 9.3797814],
                [105.6941695, 9.3797556],
                [105.6942272, 9.3797054],
                [105.6942996, 9.3796035],
                [105.6943841, 9.3795267],
                [105.6947241, 9.3793708],
                [105.6949877, 9.3792283],
                [105.6951338, 9.3791629],
                [105.6953075, 9.3791311],
                [105.696196, 9.3791814],
                [105.6963649, 9.379225],
                [105.696487, 9.3792396],
                [105.6966104, 9.379229],
                [105.6967337, 9.3791973],
                [105.6968652, 9.3791271],
                [105.6970838, 9.3789644],
                [105.697234, 9.3788903],
                [105.697411, 9.3788374],
                [105.6976095, 9.3788241],
                [105.6977221, 9.3788056],
                [105.6978522, 9.3787368],
                [105.6982291, 9.3785449],
                [105.6986368, 9.3783901],
                [105.6987977, 9.3783187],
                [105.6989801, 9.378226],
                [105.6996238, 9.3778635],
                [105.6997767, 9.3778079],
                [105.6999698, 9.3777841],
                [105.7001871, 9.3777762],
                [105.7003453, 9.377755],
                [105.7006618, 9.3776809],
                [105.7009757, 9.37762],
                [105.7012573, 9.3775221],
                [105.7013753, 9.3774798],
                [105.7014933, 9.3774613],
                [105.7016033, 9.3774639],
                [105.7018152, 9.3774692],
                [105.7020324, 9.3774586],
                [105.7024911, 9.3774586],
                [105.7028049, 9.3774665],
                [105.7033601, 9.3775089],
                [105.7035935, 9.377538],
                [105.7037464, 9.3775724],
                [105.703918, 9.3776227],
                [105.7043901, 9.3777868],
                [105.7045108, 9.3778212],
                [105.704661, 9.3778291],
                [105.7048112, 9.3778185],
                [105.7052055, 9.3777828],
                [105.7052793, 9.3777868],
                [105.7053436, 9.3778053],
                [105.7054067, 9.3778436],
                [105.7054415, 9.3779005],
                [105.705467, 9.3779773],
                [105.7058157, 9.3796114],
                [105.7062422, 9.381526],
                [105.706668, 9.3836405],
                [105.7068604, 9.3835981],
                [105.7069784, 9.3835915],
                [105.707134, 9.3835981],
                [105.7071018, 9.3842862],
                [105.7070133, 9.3849768],
                [105.7070589, 9.3866176],
                [105.7070696, 9.3885361],
                [105.7071072, 9.3909574],
                [105.7071742, 9.3914338],
                [105.7073379, 9.3921959],
                [105.707413, 9.3924102],
                [105.7078797, 9.3945511],
                [105.7070911, 9.3951094],
                [105.7057768, 9.3960091],
                [105.7050955, 9.3964537],
                [105.7059833, 9.3966442],
                [105.7066432, 9.3968003],
                [105.7087111, 9.3972581],
                [105.7091269, 9.3973534],
                [105.7094005, 9.3973984],
                [105.7097471, 9.397405],
                [105.7097197, 9.3971179],
                [105.709725, 9.396885],
                [105.7097492, 9.3966363],
                [105.709835, 9.3963293],
                [105.7099637, 9.3959985],
                [105.7100952, 9.3957816],
                [105.7103151, 9.3954878],
                [105.7107443, 9.3949718],
                [105.7111278, 9.3944955],
                [105.7114685, 9.3941541],
                [105.711675, 9.3939292],
                [105.711785, 9.3938498],
                [105.7118949, 9.3938048],
                [105.7119915, 9.3937889],
                [105.7120854, 9.3937995],
                [105.7121927, 9.3938392],
                [105.7124287, 9.3939768],
                [105.712948, 9.3943212],
                [105.7130201, 9.3943552],
                [105.7130922, 9.394379],
                [105.7131931, 9.3943811],
                [105.7133514, 9.394342],
                [105.7135123, 9.3942732],
                [105.7143304, 9.3938419],
                [105.7144698, 9.3937559],
                [105.7145785, 9.3936778],
                [105.7146616, 9.3935918],
                [105.714722, 9.3934899],
                [105.7147689, 9.3933629],
                [105.7148695, 9.3929236],
                [105.7149875, 9.392524],
                [105.7151189, 9.3921562],
                [105.7152396, 9.3919075],
                [105.7153469, 9.3917288],
                [105.7154676, 9.3915502],
                [105.7155602, 9.3914285],
                [105.7156527, 9.3913173],
                [105.7157546, 9.3912009],
                [105.7158767, 9.3910924],
                [105.7160188, 9.390978],
                [105.7161784, 9.3908781],
                [105.7170917, 9.3911242],
                [105.7181981, 9.3911347],
                [105.7202768, 9.3911533],
                [105.7202889, 9.3914563],
                [105.7203104, 9.3916005],
                [105.7203707, 9.3918783],
                [105.7204834, 9.3922382],
                [105.7220652, 9.3912631],
                [105.7236108, 9.3902965],
                [105.7240534, 9.3919194],
                [105.7243726, 9.3931194],
                [105.7254615, 9.392958],
                [105.7263467, 9.3927992],
                [105.7269166, 9.392747],
                [105.726859, 9.3930797],
                [105.7268462, 9.3932445],
                [105.7268442, 9.393394],
                [105.7268496, 9.3939199],
                [105.7268603, 9.3941091],
                [105.726867, 9.3941687],
                [105.7268871, 9.3942176],
                [105.7269354, 9.3942666],
                [105.7272633, 9.3945888],
                [105.7275188, 9.3948183],
                [105.7276167, 9.3949334],
                [105.7277977, 9.395159],
                [105.7278447, 9.3952225],
                [105.727903, 9.3953158],
                [105.728015, 9.3955302],
                [105.728078, 9.3956267],
                [105.7281518, 9.3957154],
                [105.7282349, 9.395771],
                [105.7287204, 9.3954825],
                [105.7291684, 9.3952285],
                [105.7294781, 9.3951213],
                [105.730142, 9.3947826],
                [105.7302949, 9.3946781],
                [105.7305403, 9.3945735],
                [105.7305658, 9.3945484],
                [105.7305792, 9.3945114],
                [105.7305752, 9.394465],
                [105.7305323, 9.3943248],
                [105.7305363, 9.3942772],
                [105.7305591, 9.3942481],
                [105.730598, 9.3942322],
                [105.7306503, 9.3942295],
                [105.7308514, 9.3942335],
                [105.7309091, 9.3942309],
                [105.7309668, 9.3942216],
                [105.7310285, 9.3942017],
                [105.7310942, 9.3941726],
                [105.7312484, 9.3940853],
                [105.7313007, 9.3940536],
                [105.7313409, 9.3940443],
                [105.7313865, 9.3940403],
                [105.7315153, 9.3940417],
                [105.7316387, 9.3940245],
                [105.7318144, 9.3939781],
                [105.7319123, 9.3939583],
                [105.7319967, 9.3939543],
                [105.7320799, 9.3939636],
                [105.7321885, 9.3939861],
                [105.7322703, 9.3940112],
                [105.7323924, 9.3940562],
                [105.7325184, 9.3941568],
                [105.7326941, 9.3943407],
                [105.7330281, 9.3947231],
                [105.7330857, 9.3948223],
                [105.733134, 9.3949215],
                [105.7332011, 9.3950552],
                [105.7332641, 9.3951861],
                [105.7333124, 9.3952523],
                [105.7333821, 9.3953238],
                [105.7334733, 9.3953846],
                [105.7335967, 9.3954137],
                [105.733712, 9.3953978],
                [105.7338904, 9.3953396],
                [105.7340057, 9.3953118],
                [105.7340996, 9.3953145],
                [105.7341814, 9.3953396],
                [105.7343504, 9.3954389],
                [105.7344523, 9.3954891],
                [105.7345623, 9.3955288],
                [105.7346696, 9.3955368],
                [105.7347809, 9.3955288],
                [105.7348922, 9.3954997],
                [105.7350312, 9.3954428],
                [105.7352181, 9.3953066],
                [105.7354005, 9.3951756],
                [105.7356432, 9.3950565],
                [105.7358739, 9.3949612],
                [105.7360697, 9.3949136],
                [105.7362199, 9.3949083],
                [105.7363969, 9.3949295],
                [105.7367429, 9.3950221],
                [105.7371721, 9.3951173],
                [105.7373652, 9.3951782],
                [105.7375811, 9.3953046],
                [105.7374376, 9.3957802],
                [105.7373035, 9.3961203],
                [105.7372713, 9.3962261],
                [105.7372579, 9.3963452],
                [105.7372686, 9.396504],
                [105.7374644, 9.3972449],
                [105.737569, 9.3977239],
                [105.7377246, 9.398224],
                [105.7377514, 9.3984066],
                [105.7377675, 9.3985812],
                [105.7377541, 9.3988326],
                [105.737671, 9.3995074],
                [105.7375784, 9.4000247],
                [105.7375529, 9.4001359],
                [105.7375154, 9.4002351],
                [105.7374631, 9.400333],
                [105.737388, 9.4004217],
                [105.7370822, 9.4007445],
                [105.7370138, 9.4008517],
                [105.7369736, 9.4009761],
                [105.7369561, 9.4011428],
                [105.7369106, 9.401623],
                [105.7371383, 9.4016851],
                [105.7374032, 9.4017072],
                [105.7380121, 9.401623],
                [105.738186, 9.4016139],
                [105.7383361, 9.4016323],
                [105.7384771, 9.4016882],
                [105.738777, 9.4018449],
                [105.7390355, 9.4019523],
                [105.7392243, 9.4019809],
                [105.7393989, 9.4019677],
                [105.7395679, 9.4018781],
                [105.7396836, 9.4017547],
                [105.7397443, 9.4016169],
                [105.7397833, 9.4014213],
                [105.7399236, 9.4007922],
                [105.7399898, 9.4005165],
                [105.74012, 9.4003346],
                [105.7403013, 9.4001951],
                [105.7405535, 9.4000771],
                [105.7407401, 9.4000486],
                [105.7409915, 9.4000525],
                [105.7412438, 9.4001011],
                [105.7415259, 9.4001705],
                [105.7416617, 9.4002489],
                [105.7418316, 9.4003946],
                [105.7419785, 9.4006004],
                [105.7420697, 9.4007424],
                [105.7421867, 9.4009137],
                [105.7422893, 9.4011393],
                [105.7424229, 9.4015751],
                [105.7425762, 9.4021518],
                [105.7426411, 9.4023335],
                [105.7427293, 9.4024794],
                [105.7428476, 9.4026087],
                [105.743437, 9.4030316],
                [105.743771, 9.4032669],
                [105.7439554, 9.4033894],
                [105.7440866, 9.4034624],
                [105.7442237, 9.4035066],
                [105.7443389, 9.4035137],
                [105.7444596, 9.4034873],
                [105.7446714, 9.4034003],
                [105.744866, 9.4033087],
                [105.7450048, 9.4032424],
                [105.7451641, 9.4032101],
                [105.7454201, 9.4032147],
                [105.7457226, 9.4032447],
                [105.7459858, 9.40331],
                [105.7462276, 9.4034317],
                [105.7464269, 9.4035546],
                [105.7465204, 9.403652],
                [105.7465666, 9.4037718],
                [105.7465598, 9.4038842],
                [105.7464984, 9.4040085],
                [105.7463394, 9.4042187],
                [105.746232, 9.4043243],
                [105.7460971, 9.4044028],
                [105.7458256, 9.4044857],
                [105.7456787, 9.4045431],
                [105.7455691, 9.404615],
                [105.7454696, 9.4047118],
                [105.7454128, 9.4048763],
                [105.745388, 9.405095],
                [105.7453923, 9.4055861],
                [105.7454011, 9.4057392],
                [105.7454217, 9.405867],
                [105.7454596, 9.4059649],
                [105.7455271, 9.4060315],
                [105.7456158, 9.4060767],
                [105.7457264, 9.4061088],
                [105.7458611, 9.4061242],
                [105.7461199, 9.4061162],
                [105.7464337, 9.4061175],
                [105.7465544, 9.4061334],
                [105.746659, 9.4061824],
                [105.7467502, 9.4062684],
                [105.7470024, 9.40657],
                [105.7471418, 9.4067129],
                [105.7473698, 9.4068929],
                [105.7475549, 9.4069855],
                [105.7477319, 9.4070516],
                [105.7479626, 9.4071522],
                [105.7481638, 9.4071522],
                [105.7483032, 9.4071204],
                [105.7484132, 9.4070569],
                [105.7486036, 9.4069458],
                [105.7487565, 9.4068532],
                [105.7489067, 9.4067817],
                [105.7490543, 9.4067288],
                [105.7493386, 9.4066521],
                [105.749537, 9.4066044],
                [105.7498817, 9.4065978],
                [105.7500681, 9.4065912],
                [105.7501566, 9.4065687],
                [105.7502452, 9.4065317],
                [105.7503819, 9.4064682],
                [105.7504731, 9.4064338],
                [105.7505737, 9.4064232],
                [105.7508594, 9.4064086],
                [105.7511799, 9.4064152],
                [105.7512979, 9.4064258],
                [105.7513958, 9.4064523],
                [105.7514776, 9.4065012],
                [105.7515668, 9.4065813],
                [105.7516305, 9.4066871],
                [105.7516707, 9.4068049],
                [105.7518961, 9.4074737],
                [105.752061, 9.4079196],
                [105.7520771, 9.4080148],
                [105.7520717, 9.4081048],
                [105.7520556, 9.4081762],
                [105.7520114, 9.4082675],
                [105.7517284, 9.4086684],
                [105.7513261, 9.4093088],
                [105.7512456, 9.409412],
                [105.7511571, 9.4094914],
                [105.7510552, 9.4095708],
                [105.7507333, 9.4098407],
                [105.7506435, 9.4099465],
                [105.750567, 9.4100742],
                [105.7505321, 9.4101648],
                [105.7505147, 9.4102468],
                [105.750512, 9.4103249],
                [105.7505268, 9.4104109],
                [105.7505643, 9.4104903],
                [105.750626, 9.4105644],
                [105.7507119, 9.4106332],
                [105.7509774, 9.4108038],
                [105.7511705, 9.4109361],
                [105.7512724, 9.4109891],
                [105.7513797, 9.4110341],
                [105.7515138, 9.4110658],
                [105.7518196, 9.4110976],
                [105.7518518, 9.4106954],
                [105.7518665, 9.4102482],
                [105.7518759, 9.4101026],
                [105.7518974, 9.4099809],
                [105.7519457, 9.409883],
                [105.7520154, 9.4098116],
                [105.7521147, 9.4097454],
                [105.7523507, 9.4096184],
                [105.752466, 9.4095311],
                [105.7525894, 9.4094332],
                [105.7527155, 9.4093088],
                [105.7529756, 9.4089913],
                [105.75315, 9.4087901],
                [105.7532439, 9.4087055],
                [105.7533458, 9.4086446],
                [105.7534584, 9.4086155],
                [105.753555, 9.4086155],
                [105.7536569, 9.4086287],
                [105.7537642, 9.4086605],
                [105.754247, 9.4088537],
                [105.7544535, 9.4089727],
                [105.7547647, 9.4091659],
                [105.7548639, 9.40924],
                [105.7549611, 9.4093313],
                [105.7550074, 9.4094279],
                [105.7550396, 9.4095261],
                [105.7550624, 9.4096581],
                [105.7550745, 9.4098314],
                [105.7550745, 9.4101145],
                [105.7550772, 9.4101992],
                [105.7550932, 9.4102733],
                [105.7551268, 9.4103315],
                [105.7551844, 9.410395],
                [105.7554299, 9.4105988],
                [105.7556659, 9.4108078],
                [105.7557946, 9.4108885],
                [105.7559153, 9.4109309],
                [105.7560334, 9.4109388],
                [105.7561406, 9.4109203],
                [105.7562265, 9.4108779],
                [105.7563016, 9.4108091],
                [105.7563633, 9.4107059],
                [105.7564169, 9.4105789],
                [105.7564867, 9.4103884],
                [105.7566369, 9.4099756],
                [105.756712, 9.4098248],
                [105.7568085, 9.4096978],
                [105.7569373, 9.409621],
                [105.7570928, 9.409576],
                [105.7572618, 9.4095708],
                [105.7574281, 9.4095946],
                [105.7576078, 9.409629],
                [105.7577312, 9.4096819],
                [105.7578385, 9.4097428],
                [105.7579431, 9.4098301],
                [105.7580638, 9.4099677],
                [105.7582328, 9.4101794],
                [105.758324, 9.4102693],
                [105.7584339, 9.4103487],
                [105.7585627, 9.4104016],
                [105.758737, 9.4104625],
                [105.7589355, 9.4105181],
                [105.7592681, 9.4106186],
                [105.7597482, 9.4110208],
                [105.7598622, 9.4110976],
                [105.7599869, 9.4111571],
                [105.7601532, 9.411218],
                [105.7607782, 9.4114177],
                [105.7608962, 9.4114733],
                [105.7610008, 9.4115448],
                [105.761084, 9.4116374],
                [105.7611269, 9.4117472],
                [105.7611396, 9.4118656],
                [105.7611322, 9.4120263],
                [105.7610974, 9.4121639],
                [105.761041, 9.412283],
                [105.760805, 9.4127778],
                [105.7607594, 9.4129313],
                [105.7607406, 9.4130663],
                [105.7607326, 9.4132237],
                [105.7607339, 9.413397],
                [105.7607621, 9.4135187],
                [105.7608077, 9.4136087],
                [105.7608774, 9.4136934],
                [105.7609713, 9.4137595],
                [105.7610866, 9.4138019],
                [105.7612154, 9.4138098],
                [105.7614407, 9.4138045],
                [105.7616445, 9.4138151],
                [105.7617384, 9.4138098],
                [105.7618142, 9.413786],
                [105.761892, 9.413741],
                [105.7619624, 9.4136696],
                [105.7620227, 9.4135796],
                [105.7621059, 9.4133864],
                [105.7621381, 9.4132806],
                [105.7621542, 9.4131615],
                [105.7621837, 9.4126693],
                [105.7622105, 9.4125106],
                [105.7622641, 9.4123809],
                [105.7623365, 9.4122671],
                [105.7624251, 9.4121586],
                [105.7625565, 9.4120078],
                [105.7625887, 9.4119073],
                [105.7626075, 9.4118067],
                [105.7626423, 9.4115474],
                [105.7629427, 9.4112457],
                [105.76327, 9.4110367],
                [105.7638037, 9.4107139],
                [105.7646352, 9.4101873],
                [105.765, 9.4100074],
                [105.7652736, 9.4099015],
                [105.7654935, 9.4098645],
                [105.7657081, 9.4098645],
                [105.7658958, 9.4099174],
                [105.7660782, 9.4100074],
                [105.7662606, 9.4101608],
                [105.7664323, 9.410399],
                [105.7666039, 9.4106901],
                [105.7667429, 9.4110378],
                [105.7669473, 9.4108912],
                [105.7670867, 9.4106848],
                [105.7672021, 9.4104043],
                [105.7673013, 9.4102587],
                [105.7674301, 9.410137],
                [105.7677171, 9.4099174],
                [105.7683071, 9.4095125],
                [105.7689562, 9.4090237],
                [105.7692406, 9.4093511],
                [105.7694256, 9.4094437],
                [105.7702102, 9.4100616],
                [105.7717819, 9.411472],
                [105.7720287, 9.4111849],
                [105.7723117, 9.4108012],
                [105.7727301, 9.4100232],
                [105.7731941, 9.4092373],
                [105.7735562, 9.4086552],
                [105.7735991, 9.4086287],
                [105.7736582, 9.4086155],
                [105.7737601, 9.4086129],
                [105.7738164, 9.4086049],
                [105.7738647, 9.4085758],
                [105.7739317, 9.4085017],
                [105.7750207, 9.4068532],
                [105.7753547, 9.4063504],
                [105.7757395, 9.4057603],
                [105.7763672, 9.4048897],
                [105.7775608, 9.405501],
                [105.7789314, 9.4061572],
                [105.7800981, 9.406742],
                [105.781517, 9.4074512],
                [105.7817262, 9.4072752],
                [105.7818604, 9.4071707],
                [105.7819368, 9.4070635],
                [105.7819851, 9.4069008],
                [105.7820696, 9.4066097],
                [105.78215, 9.4065039],
                [105.7825363, 9.4061228],
                [105.7826275, 9.4059905],
                [105.7827347, 9.405763],
                [105.7833356, 9.4050908],
                [105.7834992, 9.4048421],
                [105.7839015, 9.4043552],
                [105.7841, 9.4041224],
                [105.7843548, 9.4040456],
                [105.784497, 9.4039874],
                [105.7846177, 9.4038921],
                [105.7851326, 9.4033894],
                [105.7852721, 9.4032782],
                [105.7854411, 9.4031883],
                [105.785771, 9.4030612],
                [105.7858568, 9.4030004],
                [105.7859105, 9.4029131],
                [105.7859454, 9.4027887],
                [105.7861036, 9.4020345],
                [105.786117, 9.4017884],
                [105.7861868, 9.4014391],
                [105.7862833, 9.4012486],
                [105.7863638, 9.4007723],
                [105.7863665, 9.4006056],
                [105.7855082, 9.4004997],
                [105.7853419, 9.4005302],
                [105.7852091, 9.4003939],
                [105.7850508, 9.4003079],
                [105.7848349, 9.4002536],
                [105.784159, 9.4001174],
                [105.7839847, 9.4000684],
                [105.7838291, 9.4000419],
                [105.7837754, 9.4000181],
                [105.7835367, 9.3997853],
                [105.7831934, 9.3994386],
                [105.7827294, 9.3989544],
                [105.7810771, 9.397401],
                [105.7798782, 9.3962473],
                [105.7794973, 9.3959006],
                [105.7778826, 9.3942666],
                [105.7772067, 9.3936169],
                [105.7770874, 9.3932068],
                [105.777542, 9.393138],
                [105.7778719, 9.3931168],
                [105.7782795, 9.3931084],
                [105.7783702, 9.3931133],
                [105.7784558, 9.393098],
                [105.7785111, 9.3930629],
                [105.7785614, 9.3930077],
                [105.7785915, 9.3929475],
                [105.7786041, 9.3928072],
                [105.7786041, 9.392655],
                [105.7785827, 9.3923705],
                [105.7785505, 9.392143],
                [105.7784942, 9.3918519],
                [105.7784137, 9.3915132],
                [105.7783225, 9.3912512],
                [105.778065, 9.3908397],
                [105.777998, 9.3907087],
                [105.7779711, 9.3906214],
                [105.7779926, 9.3905605],
                [105.7780704, 9.3905155],
                [105.7782481, 9.3904494],
                [105.7785183, 9.3903911],
                [105.7787731, 9.3903488],
                [105.7790467, 9.3903118],
                [105.7794088, 9.3902774],
                [105.7795483, 9.3902562],
                [105.7796556, 9.3902138],
                [105.7797414, 9.390145],
                [105.7797843, 9.3900524],
                [105.779795, 9.389936],
                [105.7797977, 9.3898196],
                [105.7797669, 9.3896026],
                [105.7797655, 9.3895113],
                [105.779795, 9.3894067],
                [105.7798655, 9.3893055],
                [105.7799882, 9.3891739],
                [105.7801491, 9.3890627],
                [105.780412, 9.3888907],
                [105.7806909, 9.3887108],
                [105.7808089, 9.3886473],
                [105.780935, 9.3886049],
                [105.7810181, 9.3885705],
                [105.7810852, 9.3885096],
                [105.7811442, 9.388425],
                [105.7812743, 9.3882093],
                [105.781395, 9.3880571],
                [105.7815734, 9.3878931],
                [105.7819247, 9.3876284],
                [105.7820374, 9.387516],
                [105.7822278, 9.3872619],
                [105.7824558, 9.3869936],
                [105.7825059, 9.3869033],
                [105.782546, 9.3867982],
                [105.7825497, 9.3867141],
                [105.7825685, 9.3865382],
                [105.7825926, 9.3864482],
                [105.7826315, 9.3863516],
                [105.7827212, 9.3862522],
                [105.7828313, 9.3861769],
                [105.7829574, 9.3861161],
                [105.7831384, 9.3860446],
                [105.783298, 9.3860089],
                [105.7834724, 9.3859838],
                [105.7836789, 9.3859666],
                [105.7841174, 9.3859599],
                [105.7842341, 9.3859348],
                [105.7843253, 9.3858872],
                [105.7844004, 9.3858144],
                [105.7845063, 9.3856569],
                [105.7845452, 9.3855656],
                [105.7845573, 9.385481],
                [105.7845493, 9.3853844],
                [105.7844768, 9.3849451],
                [105.7844708, 9.3848611],
                [105.7844748, 9.3847903],
                [105.7844869, 9.3847168],
                [105.7845077, 9.3846441],
                [105.7845456, 9.384564],
                [105.7846847, 9.3843166],
                [105.7848389, 9.3839871],
                [105.7849503, 9.3836987],
                [105.7851139, 9.3834658],
                [105.7852077, 9.3833203],
                [105.7852775, 9.3831747],
                [105.7854646, 9.3827903],
                [105.7859927, 9.3828815],
                [105.7864054, 9.3829723],
                [105.7867326, 9.3830516],
                [105.7869901, 9.3831218],
                [105.7873186, 9.3832276],
                [105.7876164, 9.3833547],
                [105.7879282, 9.3835247],
                [105.7889327, 9.3840533],
                [105.7891949, 9.3841624],
                [105.7894316, 9.3842266],
                [105.7896776, 9.3842597],
                [105.7899271, 9.3842504],
                [105.7901765, 9.3841975],
                [105.7903844, 9.3840956],
                [105.7905494, 9.3839832],
                [105.7906647, 9.3838561],
                [105.7907492, 9.3837093],
                [105.7907908, 9.3835664],
                [105.7908082, 9.3834327],
                [105.7908149, 9.3832448],
                [105.7908095, 9.383094],
                [105.7907921, 9.3829789],
                [105.7907599, 9.3828532],
                [105.7906969, 9.3827023],
                [105.7902704, 9.3818992],
                [105.7901467, 9.381616],
                [105.7900498, 9.3813686],
                [105.7899955, 9.38118],
                [105.7899633, 9.381008],
                [105.7899526, 9.3808142],
                [105.7899633, 9.3806607],
                [105.7899968, 9.3805019],
                [105.7900625, 9.3803213],
                [105.7901604, 9.3800983],
                [105.7903013, 9.3798522],
                [105.7904474, 9.3796326],
                [105.7907854, 9.3792211],
                [105.7913809, 9.3786032],
                [105.7922841, 9.3777226],
                [105.7926683, 9.377452],
                [105.7929915, 9.3772787],
                [105.7933107, 9.3771569],
                [105.793646, 9.3770749],
                [105.7939397, 9.3770431],
                [105.7943286, 9.3770511],
                [105.7946384, 9.3771013],
                [105.7949576, 9.377186],
                [105.7953331, 9.3773051],
                [105.7955235, 9.3773527],
                [105.795722, 9.3773792],
                [105.7959339, 9.3773792],
                [105.7961243, 9.3773554],
                [105.7962745, 9.3773051],
                [105.796414, 9.3772204],
                [105.7965267, 9.3771199],
                [105.7966474, 9.3769889],
                [105.7969166, 9.3766263],
                [105.7971409, 9.3763074],
                [105.7974869, 9.3756994],
                [105.7977431, 9.3752595],
                [105.7978638, 9.3750795],
                [105.7980099, 9.3749088],
                [105.7981548, 9.3747858],
                [105.7983211, 9.3746865],
                [105.7985182, 9.3745899],
                [105.7987046, 9.3745423],
                [105.7988977, 9.3745185],
                [105.7990972, 9.3745099],
                [105.7993422, 9.374517],
                [105.7996032, 9.3745516],
                [105.7999733, 9.3746257],
                [105.8003367, 9.3746984],
                [105.8013443, 9.3748999],
                [105.8020138, 9.3750193],
                [105.8031336, 9.3752839],
                [105.8034254, 9.375361],
                [105.8036533, 9.3754394],
                [105.8038706, 9.3755215],
                [105.804069, 9.3755982],
                [105.8043091, 9.3756961],
                [105.8049139, 9.3759574],
                [105.8050628, 9.376015],
                [105.8051902, 9.376056],
                [105.8053096, 9.3760772],
                [105.8054169, 9.3760812],
                [105.8055255, 9.3760746],
                [105.8056408, 9.376056],
                [105.8057602, 9.3760203],
                [105.8058742, 9.3759581],
                [105.8059734, 9.3758602],
                [105.8060525, 9.375757],
                [105.8061612, 9.3755598],
                [105.8063006, 9.3752833],
                [105.806424, 9.3749445],
                [105.8068076, 9.3740607],
                [105.8069739, 9.3737219],
                [105.8071938, 9.3733011],
                [105.807462, 9.372883],
                [105.8077088, 9.3725734],
                [105.8080146, 9.3722585],
                [105.8084893, 9.3718536],
                [105.8089292, 9.3715386],
                [105.8094006, 9.3712707],
                [105.8099136, 9.3710477],
                [105.8102489, 9.3709247],
                [105.8105788, 9.3708175],
                [105.8109368, 9.3707282],
                [105.8114988, 9.3705251],
                [105.8118126, 9.3704615],
                [105.8121371, 9.3704351],
                [105.8129901, 9.3703822],
                [105.8138303, 9.3702816],
                [105.8146669, 9.3701905],
                [105.8152471, 9.3701248],
                [105.8157903, 9.3700897],
                [105.8160277, 9.3700897],
                [105.8162583, 9.370095],
                [105.8163951, 9.3701082],
                [105.8165333, 9.3701387],
                [105.8166654, 9.370187],
                [105.8168189, 9.3702419],
                [105.8170375, 9.3703411],
                [105.8172529, 9.3704829],
                [105.8174915, 9.3706971],
                [105.817646, 9.3708979],
                [105.8177657, 9.3711059],
                [105.8178717, 9.3713481],
                [105.8179495, 9.3716107],
                [105.817985, 9.3718985],
                [105.8179937, 9.372103],
                [105.817991, 9.3723577],
                [105.8179575, 9.3726078],
                [105.817869, 9.3728724],
                [105.8177617, 9.3731106],
                [105.8176437, 9.373317],
                [105.8174854, 9.3734837],
                [105.8173218, 9.3735856],
                [105.8171502, 9.3736425],
                [105.8169731, 9.3736716],
                [105.8167921, 9.3736809],
                [105.8163013, 9.3737034],
                [105.8160049, 9.3737404],
                [105.8157742, 9.373796],
                [105.8154979, 9.3738741],
                [105.8151224, 9.3740315],
                [105.8148086, 9.374185],
                [105.8145511, 9.374365],
                [105.8143124, 9.3745688],
                [105.8141112, 9.3748043],
                [105.8138725, 9.3751271],
                [105.8136445, 9.3755506],
                [105.8134943, 9.3759819],
                [105.8134353, 9.3763471],
                [105.8134514, 9.3766488],
                [105.8135185, 9.3769108],
                [105.813607, 9.3771331],
                [105.8137411, 9.3773422],
                [105.8138993, 9.3774983],
                [105.8141112, 9.3776491],
                [105.8143365, 9.3777656],
                [105.8145779, 9.3778476],
                [105.8148944, 9.3779244],
                [105.8160987, 9.3781281],
                [105.8174613, 9.3782737],
                [105.8177751, 9.3783107],
                [105.8180889, 9.3783637],
                [105.8183169, 9.3784272],
                [105.8185637, 9.378533],
                [105.8187501, 9.378672],
                [105.8189553, 9.378885],
                [105.8191484, 9.3791232],
                [105.8192825, 9.3793243],
                [105.8194367, 9.3796006],
                [105.8195615, 9.3798747],
                [105.8196285, 9.3801023],
                [105.8196674, 9.380404],
                [105.819717, 9.3815657],
                [105.8197761, 9.3819389],
                [105.819996, 9.3828042],
                [105.8201033, 9.3830953],
                [105.820232, 9.3833441],
                [105.8203769, 9.383569],
                [105.820578, 9.3838098],
                [105.8211306, 9.3843947],
                [105.8219406, 9.3850946],
                [105.8220801, 9.3852335],
                [105.82219, 9.385395],
                [105.8222786, 9.3855776],
                [105.8223429, 9.3857681],
                [105.8223671, 9.3859666],
                [105.8223483, 9.3861571],
                [105.8223134, 9.3864138],
                [105.8222222, 9.3866916],
                [105.8219486, 9.3873665],
                [105.8215731, 9.3882662],
                [105.8212566, 9.3891183],
                [105.8211172, 9.3895682],
                [105.820924, 9.3902985],
                [105.8208382, 9.3907696],
                [105.8207738, 9.3912274],
                [105.8207175, 9.3916508],
                [105.8206397, 9.3925558],
                [105.8206183, 9.393093],
                [105.8206344, 9.3934793],
                [105.8206719, 9.3937069],
                [105.8207604, 9.393916],
                [105.8208945, 9.3940933],
                [105.8210769, 9.3942203],
                [105.8212674, 9.3942891],
                [105.8214793, 9.3942944],
                [105.8217099, 9.3942335],
                [105.822233, 9.3940086],
                [105.8232187, 9.3934992],
                [105.8237538, 9.3932518],
                [105.8244753, 9.3929395],
                [105.8248401, 9.3928151],
                [105.8251405, 9.392741],
                [105.8253899, 9.3927066],
                [105.8256528, 9.3926881],
                [105.8262911, 9.3926299],
                [105.8264816, 9.3925981],
                [105.8266693, 9.3925399],
                [105.8268329, 9.3924711],
                [105.8269644, 9.3923891],
                [105.8270985, 9.3922912],
                [105.827195, 9.3921853],
                [105.8272594, 9.3920689],
                [105.8273238, 9.3919286],
                [105.8274472, 9.3916137],
                [105.8276061, 9.3912221],
                [105.8277261, 9.3908662],
                [105.827812, 9.3907034],
                [105.8279072, 9.3905764],
                [105.8280171, 9.3904745],
                [105.8281271, 9.3903978],
                [105.8282357, 9.3903515],
                [105.8284081, 9.3903118],
                [105.8285925, 9.3902932],
                [105.8287668, 9.3902932],
                [105.8289385, 9.3903065],
                [105.8291222, 9.3903462],
                [105.8293435, 9.3904269],
                [105.8315483, 9.3913134],
                [105.8323154, 9.3916375],
                [105.8327177, 9.3918201],
                [105.8332126, 9.3920649],
                [105.8333494, 9.3921377],
                [105.8334728, 9.3922171],
                [105.8335693, 9.3923097],
                [105.8336645, 9.3924341],
                [105.8337906, 9.3926167],
                [105.833922, 9.3928257],
                [105.8340186, 9.3930321],
                [105.8340964, 9.3932518],
                [105.8341795, 9.3935534],
                [105.8342332, 9.3938154],
                [105.834268, 9.3941435],
                [105.8344665, 9.3959125],
                [105.834614, 9.39676],
                [105.8347059, 9.3971424],
                [105.8351827, 9.3984172],
                [105.8352524, 9.39865],
                [105.8352846, 9.3988988],
                [105.8352739, 9.3991634],
                [105.8351934, 9.3994227],
                [105.8350378, 9.3996503],
                [105.8348179, 9.3998197],
                [105.8345658, 9.3998938],
                [105.8343083, 9.3998779],
                [105.8340561, 9.3998303],
                [105.833804, 9.3997509],
                [105.8334821, 9.3995868],
                [105.8329672, 9.3992163],
                [105.8326668, 9.3990099],
                [105.8324656, 9.3989226],
                [105.8322381, 9.3988372],
                [105.8320257, 9.3988181],
                [105.8317542, 9.3988138],
                [105.8315075, 9.3988782],
                [105.8313053, 9.3989817],
                [105.8311325, 9.3991131],
                [105.8310118, 9.3992851],
                [105.8308831, 9.3995498],
                [105.8307624, 9.3998991],
                [105.8307034, 9.4001637],
                [105.8306926, 9.4003489],
                [105.8307356, 9.4005024],
                [105.8309179, 9.4007405],
                [105.8313417, 9.4012116],
                [105.8315724, 9.4014074],
                [105.8318567, 9.4015238],
                [105.8321625, 9.4015662],
                [105.8324897, 9.4015556],
                [105.8329135, 9.4015238],
                [105.8339207, 9.4013597],
                [105.8349413, 9.4011481],
                [105.8355394, 9.4010131],
                [105.8358479, 9.4009522],
                [105.8361791, 9.4009006],
                [105.8364446, 9.4008768],
                [105.8367424, 9.4008676],
                [105.836969, 9.4009046],
                [105.8371621, 9.4009655],
                [105.8373392, 9.4010475],
                [105.8375081, 9.4011772],
                [105.8376637, 9.4013201],
                [105.8378863, 9.4015609],
                [105.8384523, 9.40236],
                [105.8386561, 9.4026114],
                [105.8389015, 9.4028734],
                [105.8392222, 9.4031912],
                [105.8394579, 9.4034467],
                [105.8397204, 9.4037012],
                [105.8401152, 9.4040165],
                [105.8404371, 9.4041964],
                [105.8407885, 9.404379],
                [105.8411533, 9.4045431],
                [105.8415046, 9.4046463],
                [105.8418077, 9.4046992],
                [105.8420947, 9.4047204],
                [105.8423361, 9.4047072],
                [105.8425963, 9.4046304],
                [105.8428538, 9.4044716],
                [105.8430576, 9.4042705],
                [105.8432132, 9.4040377],
                [105.8433044, 9.4037889],
                [105.8433258, 9.4035614],
                [105.8433044, 9.4033179],
                [105.8432347, 9.4030639],
                [105.8430013, 9.4025955],
                [105.8425869, 9.401803],
                [105.8423368, 9.4012559],
                [105.8422798, 9.4011249],
                [105.8422235, 9.4009496],
                [105.842194, 9.4007935],
                [105.8421779, 9.4006241],
                [105.8421779, 9.4004256],
                [105.8421993, 9.4002007],
                [105.8422476, 9.4000221],
                [105.8423388, 9.3997879],
                [105.8424783, 9.3995709],
                [105.842607, 9.3994399],
                [105.8427586, 9.3993341],
                [105.8429275, 9.399256],
                [105.8431461, 9.3992071],
                [105.8433969, 9.3991912],
                [105.8436745, 9.3992137],
                [105.8444879, 9.3994208],
                [105.8452624, 9.3995789],
                [105.8455467, 9.3996212],
                [105.8457747, 9.3996424],
                [105.8459464, 9.3996477],
                [105.8460885, 9.3996318],
                [105.84622, 9.3995894],
                [105.8463702, 9.3994915],
                [105.8465874, 9.3993063],
                [105.8467591, 9.3991158],
                [105.8469093, 9.3988935],
                [105.8471024, 9.3985336],
                [105.8476174, 9.3975439],
                [105.8481538, 9.396496],
                [105.8484596, 9.3955169],
                [105.8485454, 9.3952206],
                [105.8485883, 9.3949877],
                [105.8486098, 9.3947442],
                [105.8486111, 9.3944809],
                [105.8485937, 9.3941726],
                [105.8485293, 9.393818],
                [105.8482826, 9.392749],
                [105.8481418, 9.3922197],
                [105.8479848, 9.3915529],
                [105.84795, 9.3913385],
                [105.8479419, 9.391103],
                [105.8479607, 9.3907405],
                [105.8479875, 9.390415],
                [105.8480385, 9.3901927],
                [105.848119, 9.3899572],
                [105.8482343, 9.3897163],
                [105.8485991, 9.3891527],
                [105.8487895, 9.388896],
                [105.849039, 9.3886234],
                [105.849224, 9.3884488],
                [105.8494171, 9.3882979],
                [105.8496049, 9.3881921],
                [105.8498007, 9.3881233],
                [105.8500019, 9.3880836],
                [105.8502057, 9.3880677],
                [105.8504176, 9.3880836],
                [105.8506805, 9.3881418],
                [105.8514905, 9.3884567],
                [105.8517265, 9.388552],
                [105.8519948, 9.3886234],
                [105.8522361, 9.3886499],
                [105.8524507, 9.3886367],
                [105.8526385, 9.3885943],
                [105.852786, 9.3885308],
                [105.8529147, 9.3884276],
                [105.8530086, 9.38829],
                [105.853081, 9.3881392],
                [105.8531401, 9.3879645],
                [105.8531642, 9.3877899],
                [105.8531588, 9.3875623],
                [105.8531266, 9.38734],
                [105.8530623, 9.3871362],
                [105.8529758, 9.386902],
                [105.8528021, 9.386562],
                [105.8523381, 9.3857258],
                [105.8522576, 9.3855193],
                [105.852204, 9.3852971],
                [105.8522201, 9.3850853],
                [105.8522469, 9.3849054],
                [105.8523273, 9.3847413],
                [105.8524346, 9.3846143],
                [105.8525687, 9.3845137],
                [105.8527189, 9.3844344],
                [105.8528799, 9.384388],
                [105.8530636, 9.3843708],
                [105.8532621, 9.3843841],
                [105.8534485, 9.3844317],
                [105.854044, 9.3846249],
                [105.8544517, 9.3847413],
                [105.8547816, 9.3848181],
                [105.8550391, 9.3848657],
                [105.8552912, 9.3848922],
                [105.85556, 9.3849013],
                [105.8558223, 9.3848683],
                [105.856061, 9.3847863],
                [105.8563051, 9.3846566],
                [105.8565733, 9.3844264],
                [105.8568549, 9.3841274],
                [105.8570051, 9.3839263],
                [105.8571392, 9.3836775],
                [105.8572143, 9.3834446],
                [105.8572572, 9.3831906],
                [105.8572572, 9.3829207],
                [105.857209, 9.3826719],
                [105.8571231, 9.3824337],
                [105.8569944, 9.3822379],
                [105.8568415, 9.3821056],
                [105.856584, 9.3819706],
                [105.8561119, 9.3818198],
                [105.8555648, 9.3817404],
                [105.8545482, 9.3816557],
                [105.8542532, 9.3816293],
                [105.8539474, 9.381571],
                [105.8536524, 9.3814652],
                [105.8534163, 9.3813382],
                [105.8532071, 9.3811953],
                [105.8530569, 9.3810312],
                [105.8529389, 9.3808512],
                [105.8528638, 9.380666],
                [105.8528316, 9.3804649],
                [105.8528195, 9.3802743],
                [105.8528289, 9.3800203],
                [105.8528665, 9.3797609],
                [105.8529496, 9.3793719],
                [105.8530757, 9.37893],
                [105.8533788, 9.3778079],
                [105.8537087, 9.3764278],
                [105.8537704, 9.3762161],
                [105.8538482, 9.3760005],
                [105.8539789, 9.3756842],
                [105.8541124, 9.3754275],
                [105.8542545, 9.3752237],
                [105.8544704, 9.3749604],
                [105.8547118, 9.3747275],
                [105.8550444, 9.3744722],
                [105.8552469, 9.3743372],
                [105.8555299, 9.3741811],
                [105.8558035, 9.3740421],
                [105.8562648, 9.3738331],
                [105.8565304, 9.3736928],
                [105.8567449, 9.3735314],
                [105.8569595, 9.3733091],
                [105.8571285, 9.3730603],
                [105.8572197, 9.372801],
                [105.8572733, 9.3725284],
                [105.8573216, 9.3721341],
                [105.857327, 9.3716842],
                [105.8572894, 9.3712237],
                [105.8571982, 9.3706944],
                [105.8568502, 9.3693282],
                [105.8567046, 9.3687221],
                [105.8566524, 9.368359],
                [105.8566457, 9.3680599],
                [105.8566571, 9.3678052],
                [105.8566843, 9.3675581],
                [105.8567342, 9.3673004],
                [105.8568089, 9.3670292],
                [105.8568965, 9.3667797],
                [105.8569998, 9.3665104],
                [105.8571044, 9.3663093],
                [105.857221, 9.3661611],
                [105.8573914, 9.3659977],
                [105.8576924, 9.3657959],
                [105.8580485, 9.3656662],
                [105.8584186, 9.3655815],
                [105.8593145, 9.3654042],
                [105.8595988, 9.3653248],
                [105.8598214, 9.3652163],
                [105.8600306, 9.3650787],
                [105.8602077, 9.364904],
                [105.8603364, 9.3647294],
                [105.8604377, 9.3645335],
                [105.8604732, 9.3643827],
                [105.8604705, 9.3642345],
                [105.8604518, 9.3640995],
                [105.8603927, 9.363819],
                [105.8603284, 9.3635649],
                [105.860256, 9.3633241],
                [105.8601621, 9.3630092],
                [105.8598697, 9.3621887],
                [105.8597678, 9.3619559],
                [105.8596337, 9.3616965],
                [105.8592743, 9.3610031],
                [105.8589524, 9.3601827],
                [105.8587944, 9.3597579],
                [105.8584091, 9.3600931],
                [105.8579435, 9.3603284],
                [105.8573922, 9.3604955],
                [105.8568089, 9.3605802],
                [105.8562744, 9.3606135],
                [105.8556251, 9.3605237],
                [105.855046, 9.3603054],
                [105.8545268, 9.3599763],
                [105.8540702, 9.3595545],
                [105.8534948, 9.3587859],
                [105.8528311, 9.3578483],
                [105.8523273, 9.3571657],
                [105.8517951, 9.3565981],
                [105.8510895, 9.3560212],
                [105.8504165, 9.3555912],
                [105.8496257, 9.3551706],
                [105.8489058, 9.3548437],
                [105.848101, 9.3545626],
                [105.8473473, 9.3543471],
                [105.8465817, 9.3540933],
                [105.8454527, 9.353812],
                [105.8447526, 9.3536386],
                [105.8439033, 9.35341],
                [105.842718, 9.3530425],
                [105.8409029, 9.3524187],
                [105.839884, 9.3520324],
                [105.8394291, 9.3518435],
                [105.8390772, 9.3516991],
                [105.8387924, 9.3515678],
                [105.8384609, 9.3514165],
                [105.8378267, 9.3511195],
                [105.8366579, 9.3505875],
                [105.8355337, 9.3501485],
                [105.8340689, 9.3496761],
                [105.8322781, 9.349175],
                [105.8309456, 9.3487972],
                [105.8302564, 9.3485885],
                [105.8296105, 9.3483462],
                [105.8282755, 9.3477512],
                [105.827272, 9.347254],
                [105.8265033, 9.3468741],
                [105.8255349, 9.3464346],
                [105.8243524, 9.3458918],
                [105.8239672, 9.3457183],
                [105.8234996, 9.3455033],
                [105.8226425, 9.3451325],
                [105.8219272, 9.3448115],
                [105.8211734, 9.3444527],
                [105.8193842, 9.3434645],
                [105.8179194, 9.3426503],
                [105.8163254, 9.3417498],
                [105.815388, 9.3412386],
                [105.8144557, 9.3406929],
                [105.8128211, 9.3397138],
                [105.8120928, 9.3393061],
                [105.8124402, 9.3383669],
                [105.8125688, 9.3379693],
                [105.8135319, 9.3350242],
                [105.8139645, 9.3336185],
                [105.8144541, 9.3323258],
                [105.8147147, 9.3313321],
                [105.8148783, 9.3304348],
                [105.8153973, 9.3277934],
                [105.8156361, 9.3266672],
                [105.8165815, 9.3221346],
                [105.8173057, 9.3186196],
                [105.817836, 9.3163038],
                [105.8185047, 9.313018],
                [105.818848, 9.311272],
                [105.8193691, 9.308785],
                [105.8197921, 9.3066744],
                [105.8200128, 9.3056521],
                [105.8203876, 9.3037654],
                [105.8204935, 9.3031368],
                [105.8207592, 9.3012436],
                [105.8209428, 9.3004812],
                [105.8210903, 9.2998056],
                [105.8216643, 9.2971136],
                [105.82205, 9.2952043],
                [105.8222306, 9.2942434],
                [105.8227375, 9.2919108],
                [105.8234534, 9.2886564],
                [105.8241215, 9.2854804],
                [105.824773, 9.2824094],
                [105.8251475, 9.2808233],
                [105.8252105, 9.2804761],
                [105.8257091, 9.2775758],
                [105.8258812, 9.2765179],
                [105.8256528, 9.2764388],
                [105.8245275, 9.275884],
                [105.823481, 9.2754088],
                [105.823684, 9.2746904],
                [105.8228971, 9.2743377],
                [105.8212271, 9.2734436],
                [105.8208878, 9.2733033],
                [105.820519, 9.2730822],
                [105.8202615, 9.2729605],
                [105.8199579, 9.2728468],
                [105.8192408, 9.2726246],
                [105.8191554, 9.2725795],
                [105.8195308, 9.2712708],
                [105.8198562, 9.2699854],
                [105.8204161, 9.268146],
                [105.8213718, 9.2648263],
                [105.8216937, 9.263728],
                [105.8219929, 9.2625888],
                [105.8221274, 9.2621673],
                [105.8221954, 9.2619812],
                [105.822473, 9.2611328],
                [105.8232238, 9.2584304],
                [105.8237913, 9.256467],
                [105.8242004, 9.2551116],
                [105.8246296, 9.2536248],
                [105.8249339, 9.2525662],
                [105.8256521, 9.2500227],
                [105.8259874, 9.2488212],
                [105.826524, 9.2468737],
                [105.8269188, 9.2454568],
                [105.8270534, 9.2450076],
                [105.8997374, 9.0536918],
                [105.9769387, 8.702505],
                [106.4538443, 9.0800586],
                [106.3472667, 9.5148061],
                [106.3469648, 9.5159565],
                [106.346611, 9.5170724],
                [106.3462481, 9.5181855],
                [106.3458761, 9.5192957],
                [106.3454952, 9.5204029],
                [106.3451053, 9.521507],
                [106.3447064, 9.5226081],
                [106.3442985, 9.5237059],
                [106.3438818, 9.5248005],
                [106.3434562, 9.5258917],
                [106.3430217, 9.5269796],
                [106.3425784, 9.5280639],
                [106.3421263, 9.5291447],
                [106.3416654, 9.530222],
                [106.3411958, 9.5312955],
                [106.3407175, 9.5323653],
                [106.3402305, 9.5334312],
                [106.3397349, 9.5344933],
                [106.3392306, 9.5355514],
                [106.3387178, 9.5366055],
                [106.3381964, 9.5376556],
                [106.3376665, 9.5387014],
                [106.3371281, 9.539743],
                [106.3365813, 9.5407804],
                [106.336026, 9.5418133],
                [106.3354624, 9.5428419],
                [106.3348904, 9.543866],
                [106.3343101, 9.5448855],
                [106.3337216, 9.5459003],
                [106.3331248, 9.5469105],
                [106.3325199, 9.547916],
                [106.3319068, 9.5489166],
                [106.3312855, 9.5499124],
                [106.3306562, 9.5509032],
                [106.3300189, 9.5518889],
                [106.3293736, 9.5528697],
                [106.3287203, 9.5538453],
                [106.3280591, 9.5548156],
                [106.3273901, 9.5557808],
                [106.3267132, 9.5567406],
                [106.3260312, 9.5576913],
                [106.3253415, 9.5586367],
                [106.3246442, 9.5595766],
                [106.3239393, 9.560511],
                [106.3232269, 9.5614398],
                [106.322507, 9.562363],
                [106.3217796, 9.5632804],
                [106.3210448, 9.5641921],
                [106.3203026, 9.565098],
                [106.3195532, 9.565998],
                [106.3187964, 9.5668921],
                [106.3180325, 9.5677802],
                [106.3172614, 9.5686622],
                [106.3164832, 9.5695382],
                [106.3156979, 9.5704079],
                [106.3149056, 9.5712715],
                [106.3141063, 9.5721288],
                [106.3133001, 9.5729798],
                [106.3124871, 9.5738244],
                [106.3116672, 9.5746626],
                [106.3108406, 9.5754943],
                [106.3100073, 9.5763194],
                [106.3091673, 9.577138],
                [106.3083207, 9.5779499],
                [106.3074675, 9.5787552],
                [106.3066079, 9.5795537],
                [106.3057419, 9.5803454],
                [106.3048694, 9.5811302],
                [106.3039906, 9.5819082],
                [106.3031056, 9.5826792],
                [106.3022143, 9.5834432],
                [106.3010747, 9.5842997],
                [106.2999295, 9.5851489],
                [106.2987787, 9.5859907],
                [106.2976223, 9.586825],
                [106.2964605, 9.5876519],
                [106.2952932, 9.5884713],
                [106.2941205, 9.5892832],
                [106.2929425, 9.5900875],
                [106.2917591, 9.5908842],
                [106.2905705, 9.5916733],
                [106.2893768, 9.5924547],
                [106.2881778, 9.5932284],
                [106.2869738, 9.5939944],
                [106.2857727, 9.5947477],
                [106.2845666, 9.5954933],
                [106.2833556, 9.5962312],
                [106.2821399, 9.5969614],
                [106.2809193, 9.5976837],
                [106.279694, 9.5983982],
                [106.2784641, 9.5991049],
                [106.2772295, 9.5998037],
                [106.2759904, 9.6004946],
                [106.2747467, 9.6011775],
                [106.2734986, 9.6018525],
                [106.2722461, 9.6025195],
                [106.2693064, 9.6041046],
                [106.2683008, 9.6046307],
                [106.2672988, 9.6051633],
                [106.2663004, 9.6057026],
                [106.2653056, 9.6062485],
                [106.2643146, 9.6068008],
                [106.2633623, 9.6073399],
                [106.2624134, 9.6078849],
                [106.2614682, 9.608436],
                [106.2605266, 9.6089931],
                [106.2595886, 9.6095562],
                [106.2586558, 9.6101072],
                [106.257728, 9.6106664],
                [106.2568054, 9.6112339],
                [106.2558879, 9.6118094],
                [106.2549758, 9.6123931],
                [106.254069, 9.6129848],
                [106.2531676, 9.6135845],
                [106.2523457, 9.6141416],
                [106.2515284, 9.6147054],
                [106.2507159, 9.6152758],
                [106.2499082, 9.6158527],
                [106.2491053, 9.6164362],
                [106.2483073, 9.6170262],
                [106.2477415, 9.6174764],
                [106.2471811, 9.617933],
                [106.2467011, 9.6183431],
                [106.246262, 9.6186932],
                [106.2458054, 9.6190795],
                [106.2453312, 9.6194595],
                [106.2446571, 9.6200131],
                [106.2421907, 9.6220804],
                [106.2397291, 9.6241532],
                [106.2372723, 9.6262316],
                [106.2348203, 9.6283154],
                [106.2323731, 9.6304047],
                [106.2299307, 9.6324995],
                [106.2274932, 9.6345997],
                [106.2246719, 9.6370427],
                [106.2218572, 9.6394929],
                [106.219049, 9.6419505],
                [106.2162474, 9.6444153],
                [106.2134525, 9.6468873],
                [106.2106641, 9.6493667],
                [106.207936, 9.6518744],
                [106.2068495, 9.6529645],
                [106.20581, 9.6540486],
                [106.204764, 9.6551373],
                [106.2037115, 9.6562198],
                [106.2026525, 9.6572962],
                [106.2015871, 9.6583663],
                [106.2005153, 9.6594303],
                [106.1994372, 9.660488],
                [106.1983527, 9.6615394],
                [106.197262, 9.6625845],
                [106.196165, 9.6636232],
                [106.1950028, 9.6647104],
                [106.1938337, 9.6657904],
                [106.1926579, 9.6668633],
                [106.1914753, 9.6679289],
                [106.190286, 9.6689873],
                [106.18909, 9.6700383],
                [106.1878875, 9.671082],
                [106.1866783, 9.6721183],
                [106.1854627, 9.6731577],
                [106.1842538, 9.6742039],
                [106.1830511, 9.6752675],
                [106.1818548, 9.6763381],
                [106.1806649, 9.6774156],
                [106.1794814, 9.6784999],
                [106.1783043, 9.679591],
                [106.1771337, 9.6806889],
                [106.1759696, 9.6817934],
                [106.1748121, 9.6829047],
                [106.1736612, 9.6840226],
                [106.1725169, 9.6851471],
                [106.1713794, 9.6862782],
                [106.1702485, 9.6874158],
                [106.1691245, 9.6885599],
                [106.1680072, 9.6897104],
                [106.1668967, 9.6908674],
                [106.1658112, 9.6920116],
                [106.1647324, 9.6931619],
                [106.1636603, 9.6943183],
                [106.1625949, 9.6954808],
                [106.1615364, 9.6966493],
                [106.1604846, 9.6978237],
                [106.1594398, 9.6990041],
                [106.1584018, 9.7001904],
                [106.1573708, 9.7013826],
                [106.1563468, 9.7025805],
                [106.1553297, 9.7037843],
                [106.1543197, 9.7049938],
                [106.1533167, 9.7062089],
                [106.1523209, 9.7074298],
                [106.1513322, 9.7086562],
                [106.1503506, 9.7098882],
                [106.1493763, 9.7111258],
                [106.1488247, 9.7117667],
                [106.1482668, 9.7124022],
                [106.1477026, 9.7130322],
                [106.147132, 9.7136568],
                [106.1465553, 9.7142758],
                [106.1459724, 9.7148891],
                [106.1453835, 9.7154968],
                [106.1447885, 9.7160987],
                [106.1441875, 9.7166948],
                [106.1435805, 9.7172851],
                [106.1429677, 9.7178694],
                [106.1423492, 9.7184478],
                [106.1417081, 9.7190353],
                [106.141061, 9.7196164],
                [106.140408, 9.7201911],
                [106.1397491, 9.7207592],
                [106.1390844, 9.7213207],
                [106.138414, 9.7218756],
                [106.137738, 9.7224237],
                [106.1370564, 9.7229651],
                [106.1363692, 9.7234997],
                [106.1356766, 9.7240274],
                [106.1349787, 9.7245482],
                [106.1342754, 9.725062],
                [106.1275072, 9.7320032],
                [106.1235669, 9.736431],
                [106.1205583, 9.740145],
                [106.1172968, 9.7444147],
                [106.1170075, 9.7448371],
                [106.1150484, 9.7473945],
                [106.1135783, 9.7491075],
                [106.1111659, 9.7514323],
                [106.1069473, 9.7551002],
                [106.1050514, 9.7564381],
                [106.0991632, 9.7606741],
                [106.0934917, 9.7645922],
                [106.0928216, 9.764994],
                [106.0911237, 9.7660513],
                [106.0871205, 9.7681375],
                [106.0843802, 9.7694884],
                [106.0818676, 9.7702733],
                [106.0803713, 9.7708107],
                [106.0785627, 9.7715489],
                [106.0765204, 9.772639],
                [106.073573, 9.7744104],
                [106.0702641, 9.777331],
                [106.0689268, 9.7785769],
                [106.0655882, 9.7830665],
                [106.0633633, 9.7863552],
                [106.0603153, 9.7905807],
                [106.0589788, 9.7920048],
                [106.0567639, 9.794807],
                [106.0539276, 9.7990087],
                [106.0511335, 9.8024117],
                [106.0499528, 9.8036578],
                [106.0487718, 9.8047706],
                [106.0460849, 9.8067979],
                [106.0409477, 9.8114947],
                [106.0358323, 9.8160363],
                [106.0317981, 9.8196869],
                [106.0311681, 9.8202245],
                [106.03065, 9.8206666],
                [106.0283537, 9.8225815],
                [106.0250218, 9.825738],
                [106.0215465, 9.8295173],
                [106.0182488, 9.8329517],
                [106.0172195, 9.8339048],
                [106.0160211, 9.8350084],
                [106.0146909, 9.8362866],
                [106.0124824, 9.8387921],
                [106.0090472, 9.8426087],
                [106.0067218, 9.8452521],
                [106.004986, 9.8473272],
                [106.0040945, 9.8484946],
                [106.0031607, 9.8497891],
                [106.0013983, 9.8518695],
                [106.0004052, 9.8531608],
                [105.999561, 9.8546399],
                [105.9988822, 9.8558544],
                [105.9988768, 9.8558641],
                [105.9986142, 9.8565269],
                [105.9985223, 9.8567709],
                [105.9978267, 9.8586173],
                [105.9972804, 9.8607215],
                [105.9967261, 9.8626568],
                [105.9961499, 9.8642571],
                [105.9954408, 9.865805],
                [105.9943875, 9.8674886],
                [105.9929371, 9.8690717],
                [105.9900358, 9.8721867],
                [105.9869245, 9.8758117],
                [105.9850001, 9.8782109],
                [105.9833061, 9.8804788],
                [105.9823223, 9.8829837],
                [105.9789246, 9.8838302],
                [105.9766981, 9.885051],
                [105.9743185, 9.8870228],
                [105.973022, 9.8884061],
                [105.9715868, 9.8900196],
                [105.9700593, 9.8918175],
                [105.9686249, 9.893707],
                [105.9670521, 9.8959189],
                [105.9658023, 9.897394],
                [105.9651074, 9.8980397],
                [105.9634633, 9.8997332],
                [105.9620269, 9.9009338],
                [105.9607292, 9.90195],
                [105.9590141, 9.9031975],
                [105.9568812, 9.9045382],
                [105.9551653, 9.9054635],
                [105.9542845, 9.9061103],
                [105.949855, 9.908401],
                [105.9457286, 9.911128],
                [105.9426694, 9.9134377],
                [105.9415563, 9.9140851],
                [105.9378715, 9.9169257],
                [105.9357851, 9.9183582],
                [105.9331429, 9.9203445],
                [105.9317063, 9.9215911],
                [105.931336, 9.9220524],
                [105.9260157, 9.9265864],
                [105.9241795, 9.928173],
                [105.9230956, 9.9289234],
                [105.9217866, 9.9297837],
                [105.9201046, 9.9305149],
                [105.9187158, 9.9309657],
                [105.9170052, 9.9314047],
                [105.9150999, 9.931704],
                [105.9133559, 9.9319376],
                [105.9112764, 9.9321439],
                [105.9092036, 9.932235],
                [105.9072269, 9.9322709],
                [105.9056519, 9.9323167],
                [105.9032867, 9.9337958],
                [105.9028082, 9.9341355],
                [105.9017925, 9.9348566],
                [105.8995774, 9.9364291],
                [105.8968064, 9.9380667],
                [105.8939043, 9.9333229],
                [105.8929038, 9.9323084],
                [105.8923888, 9.9317113],
                [105.8919221, 9.930998],
                [105.8915627, 9.9303797],
                [105.8911402, 9.9295713],
                [105.8904, 9.929743],
                [105.8900714, 9.9298104],
                [105.8899668, 9.9298236],
                [105.8898608, 9.9298183],
                [105.8897582, 9.9297991],
                [105.8896601, 9.9297676],
                [105.8895705, 9.9297034],
                [105.8894907, 9.9296267],
                [105.8891916, 9.9292489],
                [105.8885747, 9.9285845],
                [105.888106, 9.9281062],
                [105.8879253, 9.9279959],
                [105.8877607, 9.9279424],
                [105.8875756, 9.9279107],
                [105.887074, 9.9278804],
                [105.8864035, 9.9278685],
                [105.8860333, 9.9278665],
                [105.8858134, 9.9278685],
                [105.8856639, 9.9278863],
                [105.8852676, 9.9279808],
                [105.8850557, 9.9280098],
                [105.884833, 9.9280045],
                [105.8846024, 9.9279675],
                [105.883968, 9.9278262],
                [105.8837058, 9.9278031],
                [105.8834705, 9.9278196],
                [105.8832023, 9.9278671],
                [105.8832639, 9.9270217],
                [105.8833806, 9.9259305],
                [105.8834524, 9.9259173],
                [105.8834785, 9.9258539],
                [105.8835134, 9.9257429],
                [105.8836368, 9.9252066],
                [105.8837038, 9.9248975],
                [105.8837374, 9.9245738],
                [105.8834222, 9.9245844],
                [105.8831392, 9.9245751],
                [105.8829166, 9.9245236],
                [105.882706, 9.9244331],
                [105.8818477, 9.9237838],
                [105.8809968, 9.9231821],
                [105.8808634, 9.9230493],
                [105.8807775, 9.9229053],
                [105.8807239, 9.9227521],
                [105.8805918, 9.9223029],
                [105.880514, 9.9220638],
                [105.8804161, 9.921898],
                [105.8801955, 9.9216269],
                [105.8806461, 9.9188946],
                [105.8807762, 9.9184574],
                [105.8802988, 9.9182777],
                [105.8802116, 9.9182447],
                [105.8801284, 9.9182262],
                [105.880056, 9.9182288],
                [105.8799729, 9.9182539],
                [105.8798173, 9.9183556],
                [105.8797355, 9.9183741],
                [105.8796497, 9.9183689],
                [105.879541, 9.9183424],
                [105.8790448, 9.9182103],
                [105.8787766, 9.918176],
                [105.8783823, 9.9180941],
                [105.8780256, 9.9179937],
                [105.8771995, 9.9174626],
                [105.875676, 9.9164903],
                [105.875904, 9.9156977],
                [105.8757699, 9.9156422],
                [105.8756733, 9.9155735],
                [105.8756089, 9.9154942],
                [105.8755714, 9.9153885],
                [105.8755445, 9.9151692],
                [105.8753782, 9.9146117],
                [105.8753353, 9.9144876],
                [105.8752844, 9.9143977],
                [105.8752066, 9.9143396],
                [105.8750993, 9.9143079],
                [105.8749679, 9.9143343],
                [105.8746809, 9.9144374],
                [105.874587, 9.9144664],
                [105.8744931, 9.9144506],
                [105.874418, 9.9143845],
                [105.8743201, 9.9142498],
                [105.8741122, 9.9140199],
                [105.8738226, 9.9137874],
                [105.8734471, 9.91351],
                [105.8731279, 9.9132959],
                [105.8733881, 9.9129128],
                [105.8739594, 9.9129604],
                [105.8740103, 9.9123553],
                [105.8748311, 9.9124425],
                [105.8748901, 9.9117767],
                [105.8744717, 9.9117318],
                [105.8744824, 9.9116525],
                [105.8754989, 9.9117661],
                [105.8755311, 9.9115178],
                [105.8746648, 9.91142],
                [105.8746836, 9.9111822],
                [105.8744596, 9.9111631],
                [105.8744697, 9.910768],
                [105.8745025, 9.9106115],
                [105.8745468, 9.9104325],
                [105.8745293, 9.9103856],
                [105.8745213, 9.9103446],
                [105.8745454, 9.9103037],
                [105.874595, 9.9102561],
                [105.8746098, 9.9101002],
                [105.8745266, 9.9100527],
                [105.8744435, 9.9100355],
                [105.874359, 9.9100434],
                [105.8742718, 9.9100659],
                [105.8741766, 9.9100791],
                [105.8740894, 9.9100738],
                [105.8739983, 9.9100395],
                [105.8739232, 9.9099827],
                [105.8738762, 9.909914],
                [105.8738172, 9.9098321],
                [105.8737117, 9.9097341],
                [105.8735758, 9.9096788],
                [105.873439, 9.9096061],
                [105.8733264, 9.909482],
                [105.8732204, 9.9093353],
                [105.8731111, 9.9091907],
                [105.8730045, 9.9091213],
                [105.8727926, 9.9090579],
                [105.8727846, 9.9082282],
                [105.872924, 9.9076932],
                [105.8730032, 9.9073035],
                [105.8731601, 9.9064474],
                [105.8732298, 9.9062519],
                [105.8733505, 9.9060986],
                [105.8734739, 9.9059824],
                [105.8736724, 9.9058661],
                [105.8744609, 9.9055081],
                [105.8748659, 9.9053059],
                [105.8739835, 9.9044287],
                [105.8736992, 9.9041196],
                [105.8735704, 9.9039584],
                [105.8733103, 9.90357],
                [105.8731815, 9.9033718],
                [105.8730474, 9.9032133],
                [105.8729026, 9.90306],
                [105.8727457, 9.9029279],
                [105.8718579, 9.9023731],
                [105.8715333, 9.9021234],
                [105.8714072, 9.9019648],
                [105.8731869, 9.9011973],
                [105.8734927, 9.9010546],
                [105.8737689, 9.9008934],
                [105.8740734, 9.9006728],
                [105.8738239, 9.9003914],
                [105.8737569, 9.9002949],
                [105.8737257, 9.9002034],
                [105.8737166, 9.9001166],
                [105.8737421, 9.8997731],
                [105.8737314, 9.8996727],
                [105.8737045, 9.8995855],
                [105.8736455, 9.8995036],
                [105.8733371, 9.8991812],
                [105.8725727, 9.8982908],
                [105.8721328, 9.8961347],
                [105.8694318, 9.8928318],
                [105.8688819, 9.8921078],
                [105.8687277, 9.8910957],
                [105.8701345, 9.8906135],
                [105.8718538, 9.8900197],
                [105.8685802, 9.884799],
                [105.8695176, 9.884252],
                [105.8696142, 9.8842177],
                [105.8697081, 9.8842018],
                [105.8701318, 9.8842943],
                [105.870215, 9.8842916],
                [105.8703008, 9.8842679],
                [105.8710445, 9.8838623],
                [105.8709285, 9.8834434],
                [105.8711296, 9.8832849],
                [105.8712074, 9.8832083],
                [105.871245, 9.8830841],
                [105.8712396, 9.882952],
                [105.8711725, 9.8828833],
                [105.871017, 9.8824248],
                [105.8712812, 9.8823204],
                [105.8711779, 9.8818752],
                [105.8710948, 9.8814537],
                [105.8714582, 9.8811736],
                [105.8714569, 9.8808169],
                [105.8711484, 9.8808909],
                [105.8709982, 9.8804337],
                [105.8700621, 9.880727],
                [105.8699146, 9.8802646],
                [105.8691421, 9.8805236],
                [105.8685025, 9.8772097],
                [105.8685225, 9.8771228],
                [105.8686352, 9.8770091],
                [105.8687746, 9.8769272],
                [105.8691072, 9.8768162],
                [105.8691502, 9.8767422],
                [105.8691019, 9.8765467],
                [105.8691126, 9.8764423],
                [105.8692494, 9.8761609],
                [105.8692923, 9.8759099],
                [105.8693969, 9.8757328],
                [105.8695713, 9.8755426],
                [105.869633, 9.8754237],
                [105.8697027, 9.8753602],
                [105.869869, 9.8753127],
                [105.8697832, 9.8750088],
                [105.8699736, 9.8749586],
                [105.870325, 9.8749137],
                [105.8703786, 9.8748344],
                [105.8703786, 9.8747287],
                [105.8703142, 9.8746124],
                [105.8701909, 9.874512],
                [105.8699977, 9.874401],
                [105.8699025, 9.8744261],
                [105.8698046, 9.8744327],
                [105.8697215, 9.8744063],
                [105.8696625, 9.8743719],
                [105.8695646, 9.8743019],
                [105.8695035, 9.8742867],
                [105.8694351, 9.8742973],
                [105.8692118, 9.874364],
                [105.8690938, 9.8743257],
                [105.8686486, 9.8739808],
                [105.8677393, 9.8733388],
                [105.8676615, 9.8732066],
                [105.8673304, 9.8716217],
                [105.8671439, 9.8706249],
                [105.8662775, 9.8664682],
                [105.8655775, 9.8632284],
                [105.8654433, 9.862441],
                [105.8653039, 9.8615874],
                [105.8635537, 9.8524836],
                [105.8631621, 9.8525966],
                [105.8612403, 9.8533517],
                [105.8611666, 9.8533676],
                [105.8611035, 9.8533583],
                [105.8610539, 9.8533279],
                [105.8610177, 9.8532738],
                [105.8606127, 9.8522907],
                [105.859977, 9.8506945],
                [105.8578017, 9.8450735],
                [105.8569173, 9.8426938],
                [105.8563976, 9.8413711],
                [105.8563352, 9.8411511],
                [105.8561606, 9.8409916],
                [105.8561475, 9.8407368],
                [105.8561173, 9.8405756],
                [105.8557056, 9.8387633],
                [105.8548406, 9.8346743],
                [105.8547749, 9.8342475],
                [105.8547353, 9.8335967],
                [105.8546394, 9.8328772],
                [105.8538978, 9.8290583],
                [105.8529912, 9.8244518],
                [105.8529174, 9.8240891],
                [105.8523998, 9.8226553],
                [105.851455, 9.8196926],
                [105.850136, 9.8140202],
                [105.849224, 9.8101826],
                [105.8485729, 9.8071974],
                [105.8503992, 9.8067431],
                [105.855318, 9.8055395],
                [105.8538924, 9.8026831],
                [105.853704, 9.8022443],
                [105.8534105, 9.8013305],
                [105.8521691, 9.7951899],
                [105.851634, 9.7922954],
                [105.8509983, 9.7891064],
                [105.8504706, 9.7864735],
                [105.8498362, 9.7831794],
                [105.8485655, 9.7769863],
                [105.848422, 9.7762542],
                [105.8483604, 9.7756449],
                [105.8482933, 9.7751057],
                [105.8482665, 9.7750026],
                [105.8482289, 9.7749233],
                [105.8481646, 9.7748493],
                [105.8475128, 9.7743761],
                [105.8479835, 9.7737705],
                [105.8476764, 9.7732937],
                [105.8474479, 9.7727822],
                [105.8468218, 9.7706993],
                [105.845475, 9.7663616],
                [105.8445116, 9.7632666],
                [105.8444245, 9.7623582],
                [105.8439106, 9.7586707],
                [105.8436659, 9.7543674],
                [105.843589, 9.7531226],
                [105.843497, 9.7522237],
                [105.8434609, 9.7511456],
                [105.8433525, 9.7490181],
                [105.8428862, 9.7486941],
                [105.8424647, 9.7484004],
                [105.8412752, 9.74765],
                [105.839102, 9.7460833],
                [105.8380281, 9.7453983],
                [105.8359792, 9.7440277],
                [105.835161, 9.7433747],
                [105.8334754, 9.7421343],
                [105.8311875, 9.7408434],
                [105.8292878, 9.7397353],
                [105.8255126, 9.7372879],
                [105.8241567, 9.7364544],
                [105.8219788, 9.7351181],
                [105.8210697, 9.7345692],
                [105.818948, 9.733288],
                [105.8159173, 9.7314577],
                [105.8130346, 9.7297849],
                [105.8104141, 9.728169],
                [105.8097822, 9.7278423],
                [105.8094877, 9.7276334],
                [105.806369, 9.7257245],
                [105.8059453, 9.7254069],
                [105.8036581, 9.7240506],
                [105.8000654, 9.721967],
                [105.7988204, 9.7211515],
                [105.7977771, 9.7204926],
                [105.7954777, 9.7190404],
                [105.7920274, 9.7170085],
                [105.7894981, 9.7156168],
                [105.7882592, 9.7149884],
                [105.7864232, 9.7138993],
                [105.7848317, 9.7129789],
                [105.7817452, 9.7111601],
                [105.7796705, 9.7097736],
                [105.777904, 9.7104215],
                [105.7776533, 9.7104657],
                [105.7766545, 9.7098786],
                [105.7718419, 9.7070414],
                [105.7673923, 9.7043015],
                [105.7657576, 9.7032084],
                [105.7625671, 9.701284],
                [105.7601691, 9.6998325],
                [105.7577545, 9.6983156],
                [105.757039, 9.6978873],
                [105.7553566, 9.6968805],
                [105.7527357, 9.6952657],
                [105.7504078, 9.6938057],
                [105.7495532, 9.693213],
                [105.7486589, 9.6926269],
                [105.7471903, 9.6917135],
                [105.7460366, 9.6910415],
                [105.74497, 9.690352],
                [105.7439383, 9.6896452],
                [105.7427493, 9.688783],
                [105.7415952, 9.6880246],
                [105.7405505, 9.6873274],
                [105.7396152, 9.6867477],
                [105.7381979, 9.6858922],
                [105.7367324, 9.6850178],
                [105.735604, 9.6843321],
                [105.734612, 9.6836132],
                [105.7336082, 9.6829651],
                [105.7319951, 9.682011],
                [105.7302194, 9.6809455],
                [105.7286086, 9.6799794],
                [105.7272258, 9.6791324],
                [105.7260177, 9.6783646],
                [105.7236171, 9.6768372],
                [105.7226543, 9.6762657],
                [105.7216708, 9.6756429],
                [105.7206562, 9.6750101],
                [105.7196354, 9.6743601],
                [105.7188746, 9.6738754],
                [105.7176342, 9.6731023],
                [105.7163936, 9.6722829],
                [105.7152233, 9.6715675],
                [105.7141184, 9.670886],
                [105.7134086, 9.6704487],
                [105.7116673, 9.6693441],
                [105.710758, 9.6688412],
                [105.710159, 9.6684803],
                [105.7095046, 9.6680318],
                [105.709072, 9.6677145],
                [105.7086615, 9.6673752],
                [105.7081066, 9.6669265],
                [105.7077849, 9.6666967],
                [105.707294, 9.6662881],
                [105.7058147, 9.6653676],
                [105.7009417, 9.6621748],
                [105.6971996, 9.659645],
                [105.6964161, 9.6589691],
                [105.6945088, 9.6573984],
                [105.6908681, 9.6539502],
                [105.6845418, 9.6479132],
                [105.6730983, 9.6371095],
                [105.6690007, 9.6332115],
                [105.668483, 9.6344298],
                [105.6669386, 9.6386851],
                [105.6665665, 9.6398594],
                [105.6665728, 9.6399448],
                [105.6666303, 9.6400107],
                [105.6674467, 9.640359],
                [105.6674982, 9.6404146],
                [105.6674981, 9.6404792],
                [105.66736, 9.6408483],
                [105.6673582, 9.640955],
                [105.66739, 9.6410543],
                [105.6673785, 9.6411428],
                [105.6671624, 9.6417916],
                [105.6662204, 9.6414111],
                [105.6651947, 9.6410473],
                [105.6638171, 9.6406275],
                [105.6624068, 9.6399718],
                [105.6620882, 9.6397802],
                [105.6618275, 9.6396075],
                [105.6616032, 9.6393855],
                [105.6614476, 9.6391534],
                [105.6613685, 9.6388991],
                [105.6613082, 9.6381798],
                [105.661256, 9.6379518],
                [105.6611415, 9.6376446],
                [105.6609872, 9.6373578],
                [105.659903, 9.6357716],
                [105.6597492, 9.6355762],
                [105.6595498, 9.6354347],
                [105.6593456, 9.6353486],
                [105.6591263, 9.6352864],
                [105.6589091, 9.6352458],
                [105.6586659, 9.6352364],
                [105.6570105, 9.6353376],
                [105.6562428, 9.6353255],
                [105.6554861, 9.6353191],
                [105.6547724, 9.6352704],
                [105.653825, 9.6350826],
                [105.6518064, 9.6346782],
                [105.6499418, 9.6345385],
                [105.6496793, 9.6344998],
                [105.649438, 9.6344086],
                [105.6492572, 9.6342805],
                [105.6491177, 9.6340891],
                [105.6490293, 9.6338415],
                [105.6489827, 9.6334871],
                [105.6489597, 9.6330185],
                [105.6489823, 9.6312373],
                [105.6489468, 9.6308205],
                [105.6488666, 9.6302339],
                [105.6488107, 9.6299735],
                [105.6487063, 9.6297205],
                [105.6485738, 9.6294929],
                [105.6479604, 9.6285807],
                [105.6478115, 9.6282981],
                [105.6476604, 9.6277304],
                [105.6475254, 9.6273451],
                [105.6473549, 9.6265945],
                [105.6472325, 9.6262234],
                [105.6470628, 9.6259102],
                [105.6467502, 9.6255718],
                [105.6465958, 9.6253366],
                [105.6463432, 9.6253806],
                [105.6460395, 9.6253995],
                [105.6457435, 9.6253831],
                [105.6453582, 9.6252963],
                [105.6451477, 9.6252108],
                [105.6446756, 9.6249038],
                [105.6444753, 9.6248108],
                [105.6439075, 9.6246724],
                [105.6436, 9.6246107],
                [105.6433779, 9.6246012],
                [105.6431803, 9.6246288],
                [105.6428708, 9.6247026],
                [105.6426453, 9.6247453],
                [105.6424107, 9.6247409],
                [105.6421626, 9.6246653],
                [105.6413143, 9.6242449],
                [105.6410107, 9.6241481],
                [105.6400755, 9.6239229],
                [105.6396684, 9.6237795],
                [105.6394031, 9.6236172],
                [105.6391874, 9.6234411],
                [105.63882, 9.6230109],
                [105.6384016, 9.6224317],
                [105.6378952, 9.6215199],
                [105.6375076, 9.6209614],
                [105.6370372, 9.6204244],
                [105.6365623, 9.6199382],
                [105.636048, 9.6192859],
                [105.6358598, 9.619081],
                [105.6355124, 9.6187788],
                [105.6352984, 9.6186756],
                [105.635083, 9.6186442],
                [105.6348721, 9.6186531],
                [105.6346566, 9.618701],
                [105.6344199, 9.6187788],
                [105.6342318, 9.618879],
                [105.6337842, 9.619247],
                [105.6332531, 9.6197302],
                [105.6329876, 9.6199262],
                [105.6328162, 9.6200234],
                [105.632675, 9.6200653],
                [105.6325355, 9.6200653],
                [105.6323898, 9.6200249],
                [105.6322441, 9.6199442],
                [105.6311911, 9.6190436],
                [105.6309484, 9.6188686],
                [105.6306783, 9.6187205],
                [105.6304158, 9.6186068],
                [105.6298923, 9.6184018],
                [105.6285862, 9.617721],
                [105.6268663, 9.6166769],
                [105.6260294, 9.616297],
                [105.6256721, 9.6161788],
                [105.6254425, 9.6161486],
                [105.6252026, 9.6161687],
                [105.6249347, 9.6162492],
                [105.6246999, 9.61637],
                [105.6239749, 9.6168874],
                [105.6237503, 9.6169861],
                [105.6235136, 9.6170355],
                [105.6232602, 9.617037],
                [105.6204487, 9.6167094],
                [105.62008, 9.6166211],
                [105.6197359, 9.6164946],
                [105.6186641, 9.6159093],
                [105.618019, 9.6154684],
                [105.6178693, 9.6153963],
                [105.6177105, 9.6153545],
                [105.6175734, 9.6153376],
                [105.6174453, 9.6153483],
                [105.6173162, 9.6153767],
                [105.6172188, 9.615431],
                [105.6171475, 9.6155093],
                [105.6170952, 9.6156009],
                [105.6170583, 9.6157173],
                [105.6170393, 9.6158629],
                [105.6169668, 9.6171044],
                [105.6170438, 9.6176795],
                [105.6170782, 9.6178297],
                [105.6171303, 9.6179591],
                [105.6178119, 9.6190631],
                [105.6179031, 9.6192757],
                [105.6179373, 9.6194203],
                [105.6179427, 9.6195726],
                [105.6179202, 9.619726],
                [105.6178762, 9.6198783],
                [105.6178204, 9.6200137],
                [105.6177292, 9.6201449],
                [105.6175769, 9.6203004],
                [105.6165372, 9.6210852],
                [105.6157776, 9.6217184],
                [105.6155734, 9.6218662],
                [105.6154069, 9.6219461],
                [105.6152278, 9.6220035],
                [105.6150403, 9.6220363],
                [105.6148278, 9.6220281],
                [105.6145741, 9.6219409],
                [105.6143129, 9.6217811],
                [105.6138556, 9.6213518],
                [105.6131535, 9.6206228],
                [105.6123342, 9.6197342],
                [105.6120635, 9.6194873],
                [105.6118732, 9.619346],
                [105.6116919, 9.6192519],
                [105.6112026, 9.6190456],
                [105.6110374, 9.6190213],
                [105.6108572, 9.6190361],
                [105.6105836, 9.619107],
                [105.6102692, 9.619217],
                [105.6099077, 9.6193862],
                [105.6091234, 9.6198263],
                [105.6087758, 9.619986],
                [105.6084807, 9.6200675],
                [105.6081074, 9.620133],
                [105.6077909, 9.6201658],
                [105.607484, 9.6201817],
                [105.607189, 9.6201542],
                [105.6069776, 9.6201055],
                [105.6067974, 9.6200336],
                [105.6066515, 9.6199194],
                [105.6065324, 9.6197491],
                [105.6064305, 9.6195396],
                [105.60635, 9.6193323],
                [105.6062974, 9.6191059],
                [105.6062169, 9.6185612],
                [105.6061542, 9.617966],
                [105.6061172, 9.6178041],
                [105.6060595, 9.61766],
                [105.6059747, 9.6175275],
                [105.6058547, 9.6174145],
                [105.6047313, 9.6166364],
                [105.6042669, 9.6162715],
                [105.60388, 9.6159364],
                [105.6033915, 9.6154164],
                [105.6018249, 9.6136813],
                [105.6006444, 9.6123548],
                [105.6000468, 9.6118358],
                [105.5985808, 9.610951],
                [105.5964189, 9.6094883],
                [105.5964025, 9.6094775],
                [105.596092, 9.6092752],
                [105.5959095, 9.6091563],
                [105.5955613, 9.608738],
                [105.5952499, 9.6080729],
                [105.5951997, 9.6078142],
                [105.5952608, 9.6072595],
                [105.5955079, 9.6066429],
                [105.5955571, 9.6063716],
                [105.5955319, 9.6061745],
                [105.5953438, 9.6051025],
                [105.5951853, 9.6034542],
                [105.5950944, 9.6032267],
                [105.5950051, 9.6029956],
                [105.5931692, 9.6033932],
                [105.5926856, 9.6036776],
                [105.5925495, 9.6038873],
                [105.592488, 9.6041709],
                [105.5924512, 9.6044544],
                [105.5924767, 9.6047871],
                [105.5925391, 9.6050088],
                [105.5928008, 9.6057232],
                [105.5928638, 9.6061298],
                [105.5928023, 9.6064996],
                [105.5925919, 9.606845],
                [105.5914519, 9.6077714],
                [105.5908445, 9.6081299],
                [105.5902368, 9.6083158],
                [105.5882143, 9.6084918],
                [105.5878174, 9.6086897],
                [105.5874457, 9.6090354],
                [105.5871488, 9.6095165],
                [105.5870501, 9.6098988],
                [105.5870379, 9.6100221],
                [105.587113, 9.6103423],
                [105.5875367, 9.6114386],
                [105.5875874, 9.6120546],
                [105.5875881, 9.6123504],
                [105.5875015, 9.6125232],
                [105.5873156, 9.6126837],
                [105.5868317, 9.6127215],
                [105.5846711, 9.612035],
                [105.5844103, 9.6119369],
                [105.5840256, 9.6119129],
                [105.5835295, 9.6120616],
                [105.5825252, 9.6126179],
                [105.5791649, 9.6142382],
                [105.5769199, 9.6150061],
                [105.5764484, 9.6150439],
                [105.5761131, 9.6149582],
                [105.5756289, 9.6146755],
                [105.575231, 9.6143064],
                [105.5749691, 9.6136044],
                [105.574856, 9.6127788],
                [105.5749049, 9.6123227],
                [105.575077, 9.611398],
                [105.5753978, 9.6102883],
                [105.5754098, 9.6100418],
                [105.5753224, 9.6097462],
                [105.5750113, 9.6092784],
                [105.5746385, 9.6089339],
                [105.5735579, 9.6084059],
                [105.5720056, 9.6078148],
                [105.5716096, 9.6076032],
                [105.5708144, 9.6068899],
                [105.5705904, 9.6065576],
                [105.5702413, 9.6054862],
                [105.5697173, 9.6037866],
                [105.5694439, 9.6035159],
                [105.5691582, 9.6032947],
                [105.5688477, 9.6031597],
                [105.5684259, 9.6032096],
                [105.567955, 9.6034814],
                [105.5672863, 9.6042219],
                [105.5658504, 9.6062327],
                [105.5656398, 9.6064056],
                [105.5648584, 9.6066164],
                [105.5638163, 9.6066674],
                [105.5624757, 9.6062999],
                [105.5603649, 9.6053423],
                [105.559123, 9.6046296],
                [105.5584888, 9.6038154],
                [105.5582275, 9.6033233],
                [105.558177, 9.6028924],
                [105.5582262, 9.6025969],
                [105.5586449, 9.6006875],
                [105.558657, 9.6005027],
                [105.5585942, 9.5999112],
                [105.5579449, 9.5972266],
                [105.5576711, 9.596759],
                [105.5574722, 9.5964883],
                [105.5571245, 9.5962671],
                [105.556777, 9.5961691],
                [105.5560576, 9.5962155],
                [105.5533354, 9.5971473],
                [105.5522302, 9.597413],
                [105.5512868, 9.5974726],
                [105.5496463, 9.5973594],
                [105.5484921, 9.5971558],
                [105.5478054, 9.5970633],
                [105.5474071, 9.5969631],
                [105.5471522, 9.5967223],
                [105.5470525, 9.5964497],
                [105.5470524, 9.5964024],
                [105.5470672, 9.5962088],
                [105.5470768, 9.5960822],
                [105.5473485, 9.5952442],
                [105.5488334, 9.5933205],
                [105.5490803, 9.592581],
                [105.5492282, 9.5920882],
                [105.5492029, 9.5917433],
                [105.5489793, 9.5914234],
                [105.5478106, 9.5896763],
                [105.5460689, 9.5862797],
                [105.5460081, 9.5861594],
                [105.5463061, 9.5859634],
                [105.5473012, 9.5846622],
                [105.54791, 9.5837127],
                [105.5480227, 9.5834853],
                [105.5480924, 9.5832552],
                [105.5482963, 9.5824353],
                [105.5483526, 9.5821893],
                [105.5483687, 9.5819698],
                [105.5483419, 9.5815678],
                [105.5483419, 9.5814012],
                [105.548358, 9.5812531],
                [105.5484089, 9.5811129],
                [105.5485752, 9.5808378],
                [105.5487442, 9.5806448],
                [105.5490097, 9.5803115],
                [105.5491519, 9.5800576],
                [105.5493236, 9.5796503],
                [105.5494416, 9.5794123],
                [105.5495596, 9.5792377],
                [105.5501577, 9.5784284],
                [105.5503187, 9.5781957],
                [105.5504152, 9.5780079],
                [105.5505306, 9.5776826],
                [105.5506164, 9.5773626],
                [105.550847, 9.5763919],
                [105.5508846, 9.5758629],
                [105.5508792, 9.5755561],
                [105.5507961, 9.5748235],
                [105.5506271, 9.5738978],
                [105.5506057, 9.5737524],
                [105.5506164, 9.5736228],
                [105.550662, 9.5734773],
                [105.5507854, 9.5733027],
                [105.551075, 9.5729457],
                [105.5513218, 9.5726812],
                [105.5514827, 9.5725437],
                [105.5517027, 9.5723744],
                [105.5518073, 9.5723215],
                [105.5519092, 9.572303],
                [105.5520111, 9.5723083],
                [105.5522418, 9.5723374],
                [105.5523545, 9.57234],
                [105.5524725, 9.5723136],
                [105.5526093, 9.572258],
                [105.552738, 9.5721681],
                [105.5528587, 9.5720464],
                [105.5530143, 9.5718428],
                [105.5532449, 9.5714857],
                [105.5534971, 9.5710916],
                [105.5536848, 9.5707531],
                [105.5538297, 9.5704992],
                [105.554055, 9.570232],
                [105.5541757, 9.5700866],
                [105.5543393, 9.5697163],
                [105.5544519, 9.5693857],
                [105.5545217, 9.5691],
                [105.5545914, 9.5685975],
                [105.5547523, 9.5682166],
                [105.5549133, 9.5678278],
                [105.5549803, 9.5676268],
                [105.5549964, 9.5675025],
                [105.5549884, 9.5673941],
                [105.554857, 9.567119],
                [105.5548114, 9.567],
                [105.5547872, 9.5668915],
                [105.5547765, 9.5667355],
                [105.5548114, 9.5657304],
                [105.5548382, 9.565421],
                [105.5548891, 9.5651644],
                [105.5550286, 9.5647743],
                [105.5550407, 9.564691],
                [105.555034, 9.5646209],
                [105.5549924, 9.5645561],
                [105.5548596, 9.5644662],
                [105.5547765, 9.5643908],
                [105.5547161, 9.5642903],
                [105.5545592, 9.5639028],
                [105.55446, 9.5636092],
                [105.554409, 9.56335],
                [105.5543983, 9.5630855],
                [105.5544358, 9.5628104],
                [105.5545351, 9.5625697],
                [105.5547497, 9.562255],
                [105.554873, 9.5620302],
                [105.5550098, 9.5616334],
                [105.5552781, 9.5607394],
                [105.5554336, 9.5603374],
                [105.5554363, 9.5598296],
                [105.5552606, 9.5581103],
                [105.5544439, 9.5519184],
                [105.5540228, 9.5488607],
                [105.5537546, 9.5488555],
                [105.5535561, 9.5488316],
                [105.553304, 9.5487602],
                [105.5529419, 9.5460742],
                [105.5516785, 9.5341275],
                [105.5511368, 9.5289752],
                [105.5508417, 9.5261681],
                [105.5504662, 9.5228722],
                [105.5503384, 9.5212223],
                [105.5502677, 9.5203089],
                [105.5500907, 9.5189307],
                [105.5499579, 9.5176868],
                [105.5493101, 9.5115027],
                [105.548889, 9.5071194],
                [105.5483097, 9.5016143],
                [105.5481783, 9.500117],
                [105.5481005, 9.4993181],
                [105.5477853, 9.4970734],
                [105.5475318, 9.4946095],
                [105.5472636, 9.4920596],
                [105.5472368, 9.4915404],
                [105.5472422, 9.4908632],
                [105.5472743, 9.4895986],
                [105.5472495, 9.4892997],
                [105.547155, 9.4887263],
                [105.5473736, 9.4885881],
                [105.5466655, 9.4818075],
                [105.5466387, 9.4815721],
                [105.5464134, 9.4810086],
                [105.5459225, 9.4796514],
                [105.5456007, 9.4781775],
                [105.5448094, 9.4746684],
                [105.5441093, 9.4714447],
                [105.5440061, 9.4710571],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.4775053, 21.6964874],
                [105.4776494, 21.6960237],
                [105.47791, 21.6955599],
                [105.4783605, 21.6952316],
                [105.4789716, 21.695012],
                [105.4792683, 21.6949318],
                [105.4793769, 21.6946967],
                [105.4794005, 21.6946609],
                [105.4794637, 21.6946013],
                [105.4795432, 21.6945621],
                [105.4796483, 21.6945469],
                [105.4797013, 21.6945526],
                [105.4800528, 21.6946468],
                [105.4803892, 21.6946228],
                [105.4807211, 21.6945669],
                [105.4808843, 21.6945272],
                [105.4812202, 21.6944184],
                [105.4813829, 21.6943516],
                [105.4816955, 21.6941939],
                [105.4818445, 21.6941035],
                [105.483073, 21.6932661],
                [105.4839474, 21.6932462],
                [105.4843765, 21.6928225],
                [105.4848057, 21.6919602],
                [105.4862863, 21.6912125],
                [105.4873967, 21.6907141],
                [105.4875619, 21.6905955],
                [105.4876374, 21.6905285],
                [105.4877724, 21.6903805],
                [105.4878907, 21.6902047],
                [105.4879417, 21.6901049],
                [105.4880174, 21.6898957],
                [105.4880416, 21.6897875],
                [105.4880619, 21.6895676],
                [105.4880726, 21.6889645],
                [105.4879777, 21.6888076],
                [105.4878918, 21.6886462],
                [105.4878151, 21.688481],
                [105.4877149, 21.6882176],
                [105.4876736, 21.6880833],
                [105.487612, 21.6878332],
                [105.4875416, 21.6876086],
                [105.4874394, 21.6873761],
                [105.4873109, 21.687155],
                [105.4870802, 21.6866665],
                [105.487056, 21.6865765],
                [105.4870319, 21.6863924],
                [105.4870323, 21.6862971],
                [105.4870588, 21.6861082],
                [105.4871859, 21.6859637],
                [105.4872385, 21.6858839],
                [105.4873142, 21.6857263],
                [105.4873412, 21.6856436],
                [105.487673, 21.6842539],
                [105.4875791, 21.6840271],
                [105.4875818, 21.6839274],
                [105.4876167, 21.6837504],
                [105.487614, 21.6836133],
                [105.4875904, 21.6833226],
                [105.4875389, 21.6830177],
                [105.487394, 21.6828482],
                [105.4872398, 21.6826176],
                [105.4874074, 21.6824245],
                [105.4875523, 21.6821802],
                [105.4872092, 21.6810461],
                [105.4871003, 21.6808243],
                [105.4870307, 21.6807241],
                [105.4868683, 21.6805938],
                [105.486355, 21.6804127],
                [105.4861721, 21.6803123],
                [105.4859126, 21.6801294],
                [105.485664, 21.6800143],
                [105.4852947, 21.6796047],
                [105.4851598, 21.6795307],
                [105.4850364, 21.6795133],
                [105.4848164, 21.6796242],
                [105.484759, 21.679538],
                [105.4846837, 21.6794647],
                [105.4845643, 21.6793936],
                [105.4844889, 21.6793768],
                [105.484335, 21.67936],
                [105.4841813, 21.6793657],
                [105.4838529, 21.6794249],
                [105.4836124, 21.6794061],
                [105.4835397, 21.6793862],
                [105.48309, 21.6791536],
                [105.4829189, 21.6790886],
                [105.4819947, 21.6788578],
                [105.4819411, 21.6787556],
                [105.4814617, 21.6782259],
                [105.4811847, 21.6777586],
                [105.4808434, 21.6773973],
                [105.4807958, 21.6773274],
                [105.4808173, 21.6770208],
                [105.4808038, 21.6768638],
                [105.4807529, 21.6766195],
                [105.4805319, 21.6762973],
                [105.4804513, 21.6761349],
                [105.4804042, 21.6759615],
                [105.4803916, 21.6758063],
                [105.4803954, 21.6757286],
                [105.4804308, 21.6755296],
                [105.4804308, 21.6754835],
                [105.4804071, 21.6753931],
                [105.4803836, 21.6753512],
                [105.4803532, 21.6753134],
                [105.479621, 21.6747227],
                [105.4791677, 21.6744584],
                [105.4799616, 21.6737007],
                [105.4806483, 21.673247],
                [105.4809111, 21.6729729],
                [105.4821181, 21.6724145],
                [105.4821342, 21.6716767],
                [105.4820806, 21.6714823],
                [105.4820591, 21.6707395],
                [105.4822576, 21.670565],
                [105.4823863, 21.6702709],
                [105.4824132, 21.6700116],
                [105.4824041, 21.6697986],
                [105.4823917, 21.6696926],
                [105.4823398, 21.6694373],
                [105.4823024, 21.6693121],
                [105.4822117, 21.6690799],
                [105.4821969, 21.6690228],
                [105.4821846, 21.6689098],
                [105.4821932, 21.6688002],
                [105.4822339, 21.6686227],
                [105.4823377, 21.668241],
                [105.4824024, 21.6680374],
                [105.4823401, 21.6679196],
                [105.4823013, 21.6678648],
                [105.482216, 21.6677711],
                [105.4821066, 21.6676796],
                [105.4820967, 21.6676361],
                [105.4821108, 21.6676072],
                [105.4822737, 21.6674641],
                [105.4823273, 21.6673569],
                [105.4823455, 21.6672813],
                [105.4823962, 21.6671466],
                [105.4824266, 21.6670877],
                [105.4825821, 21.6668659],
                [105.4825813, 21.6666352],
                [105.4825893, 21.6665443],
                [105.4826271, 21.6663812],
                [105.4826862, 21.66624],
                [105.4827243, 21.6661729],
                [105.4830676, 21.6657142],
                [105.4830462, 21.6653403],
                [105.4830537, 21.665235],
                [105.4830931, 21.6650272],
                [105.4831642, 21.6648268],
                [105.4832536, 21.6646569],
                [105.4833069, 21.6645763],
                [105.4835112, 21.664327],
                [105.4836342, 21.6641459],
                [105.4837194, 21.6639843],
                [105.4837414, 21.6638119],
                [105.4837891, 21.6636602],
                [105.4839876, 21.6633661],
                [105.4839903, 21.6630096],
                [105.4839755, 21.6628998],
                [105.483959, 21.6628466],
                [105.4839088, 21.6627464],
                [105.4838103, 21.6626223],
                [105.4837889, 21.6625832],
                [105.4837737, 21.6625417],
                [105.4837629, 21.6624558],
                [105.4837778, 21.6623715],
                [105.4838429, 21.6621981],
                [105.483883, 21.6620624],
                [105.4839177, 21.6619099],
                [105.483942, 21.6617557],
                [105.4837635, 21.6615409],
                [105.4836845, 21.6614267],
                [105.4836055, 21.6612955],
                [105.4835357, 21.66116],
                [105.4834913, 21.6610927],
                [105.4833841, 21.6609705],
                [105.4832732, 21.6608799],
                [105.4832125, 21.6608409],
                [105.4832393, 21.6606976],
                [105.4832655, 21.6606235],
                [105.4833615, 21.6604497],
                [105.4833841, 21.6603947],
                [105.4834011, 21.6603368],
                [105.4834321, 21.6601423],
                [105.4834852, 21.659987],
                [105.4835666, 21.6598248],
                [105.4836701, 21.6596662],
                [105.4838116, 21.6595056],
                [105.4839003, 21.6594258],
                [105.4839959, 21.6593531],
                [105.4842975, 21.6591629],
                [105.4844704, 21.6590192],
                [105.4846156, 21.6588621],
                [105.4847377, 21.6586889],
                [105.4849827, 21.6583655],
                [105.4854548, 21.6579791],
                [105.485561, 21.6578342],
                [105.4856399, 21.657675],
                [105.485692, 21.657491],
                [105.4857044, 21.6573965],
                [105.4857016, 21.6565158],
                [105.4857264, 21.6562358],
                [105.4857816, 21.6559556],
                [105.4858209, 21.6558159],
                [105.4858679, 21.6556782],
                [105.4860341, 21.6553093],
                [105.4861502, 21.655133],
                [105.4863027, 21.6549558],
                [105.4864955, 21.6547857],
                [105.486989, 21.6544018],
                [105.487249, 21.6536059],
                [105.4873889, 21.6532902],
                [105.4874665, 21.6531354],
                [105.4876326, 21.6528389],
                [105.4877226, 21.6526942],
                [105.4882206, 21.652053],
                [105.4883441, 21.65188],
                [105.4884589, 21.651702],
                [105.4886173, 21.6514201],
                [105.4887539, 21.6511286],
                [105.4887271, 21.6507796],
                [105.4886972, 21.650557],
                [105.4886574, 21.6503358],
                [105.4885554, 21.6499818],
                [105.4883033, 21.6495805],
                [105.4881531, 21.6492333],
                [105.4881405, 21.649173],
                [105.4881419, 21.6490556],
                [105.4881746, 21.6489472],
                [105.4882617, 21.6488248],
                [105.4883113, 21.6487677],
                [105.4883831, 21.6486968],
                [105.4886569, 21.6484794],
                [105.4888664, 21.6482772],
                [105.4889631, 21.6481694],
                [105.4891375, 21.6479445],
                [105.489216, 21.6478265],
                [105.4893547, 21.6475811],
                [105.4894835, 21.6473068],
                [105.4900896, 21.6462248],
                [105.4901934, 21.646035],
                [105.4902354, 21.6459358],
                [105.4902989, 21.6457312],
                [105.4903231, 21.6456077],
                [105.4903375, 21.6454828],
                [105.4903418, 21.6453573],
                [105.4902184, 21.6447539],
                [105.490202, 21.6446186],
                [105.4901886, 21.6443466],
                [105.490202, 21.6440646],
                [105.4902449, 21.6437751],
                [105.4902774, 21.643632],
                [105.4904169, 21.643288],
                [105.490496, 21.6431484],
                [105.4906328, 21.6429639],
                [105.4910251, 21.6426312],
                [105.4911771, 21.6425352],
                [105.4919028, 21.6421761],
                [105.4921871, 21.6420165],
                [105.4925305, 21.6417921],
                [105.493024, 21.6414431],
                [105.4936838, 21.6410841],
                [105.4940969, 21.6407101],
                [105.4944563, 21.6404508],
                [105.4945561, 21.6404654],
                [105.4947576, 21.6404778],
                [105.4949816, 21.6404656],
                [105.4951035, 21.6404472],
                [105.4953414, 21.640386],
                [105.4954478, 21.6403446],
                [105.4955499, 21.6402948],
                [105.495647, 21.640237],
                [105.4965109, 21.639653],
                [105.496704, 21.639481],
                [105.4969346, 21.6393064],
                [105.497365, 21.6390444],
                [105.4974964, 21.6389319],
                [105.4975566, 21.6388668],
                [105.4976596, 21.6387246],
                [105.4977018, 21.6386482],
                [105.49773, 21.6384887],
                [105.4977515, 21.6383282],
                [105.4977735, 21.6380209],
                [105.4977578, 21.6372446],
                [105.4977715, 21.6367609],
                [105.49782, 21.6362149],
                [105.4978818, 21.6358115],
                [105.4979485, 21.6355517],
                [105.4980585, 21.63525],
                [105.4983777, 21.6349209],
                [105.4991394, 21.6338238],
                [105.499633, 21.6333152],
                [105.4998206, 21.6330706],
                [105.4999055, 21.6329427],
                [105.5000441, 21.6327021],
                [105.5001506, 21.6324775],
                [105.5002445, 21.6317644],
                [105.5003893, 21.6315101],
                [105.500494, 21.6313505],
                [105.5008557, 21.6308707],
                [105.5011027, 21.6305042],
                [105.5012208, 21.6303133],
                [105.5011135, 21.62968],
                [105.5010814, 21.6295884],
                [105.5010401, 21.6295],
                [105.5009901, 21.6294157],
                [105.5008849, 21.6292818],
                [105.5002314, 21.6286557],
                [105.5001186, 21.6285325],
                [105.5000138, 21.6284034],
                [105.4998463, 21.6281566],
                [105.4997734, 21.6280274],
                [105.4992628, 21.6270046],
                [105.4989603, 21.626712],
                [105.4989035, 21.6266434],
                [105.4988129, 21.6264922],
                [105.49878, 21.6264111],
                [105.4987546, 21.6263222],
                [105.4987394, 21.6262313],
                [105.4987371, 21.6256706],
                [105.4985976, 21.6254213],
                [105.498485, 21.6250921],
                [105.4983401, 21.624753],
                [105.4978359, 21.6238005],
                [105.4978037, 21.6236036],
                [105.4977393, 21.6234116],
                [105.4977231, 21.6233415],
                [105.4977096, 21.6231989],
                [105.4977125, 21.6231273],
                [105.4977382, 21.622983],
                [105.4979646, 21.6223942],
                [105.498029, 21.6220202],
                [105.4978734, 21.6214966],
                [105.497911, 21.6211176],
                [105.4978274, 21.6207001],
                [105.4977246, 21.6203448],
                [105.4976159, 21.6200503],
                [105.4969293, 21.6189432],
                [105.4965584, 21.6185755],
                [105.4965327, 21.6185369],
                [105.4965064, 21.6184498],
                [105.4965162, 21.6183597],
                [105.4965605, 21.6182795],
                [105.4965938, 21.6182464],
                [105.4966772, 21.6182002],
                [105.4971149, 21.6180231],
                [105.49716, 21.6179957],
                [105.4972306, 21.6179327],
                [105.4972833, 21.617856],
                [105.4974411, 21.6175734],
                [105.4976213, 21.6173025],
                [105.4978402, 21.6170245],
                [105.4980826, 21.6167639],
                [105.4984099, 21.616335],
                [105.4994184, 21.6153774],
                [105.4995488, 21.6152349],
                [105.4996587, 21.6150762],
                [105.4997053, 21.6149913],
                [105.4997456, 21.6149037],
                [105.4998261, 21.6146892],
                [105.4998787, 21.6146033],
                [105.4999582, 21.6145216],
                [105.50001, 21.6144845],
                [105.5001265, 21.6144299],
                [105.5002123, 21.6141705],
                [105.5003949, 21.6140398],
                [105.5005956, 21.6139244],
                [105.5008131, 21.613827],
                [105.5013657, 21.6136319],
                [105.5019289, 21.6133476],
                [105.5024975, 21.6133427],
                [105.5028516, 21.6132778],
                [105.5034095, 21.613223],
                [105.5037206, 21.6131631],
                [105.5041391, 21.6130584],
                [105.5043272, 21.61297],
                [105.5044153, 21.6129162],
                [105.5045653, 21.6128019],
                [105.504697, 21.6126694],
                [105.5048659, 21.6124749],
                [105.5051315, 21.6123253],
                [105.505271, 21.6120659],
                [105.5052656, 21.611637],
                [105.505284, 21.6115631],
                [105.5053246, 21.6114974],
                [105.5053597, 21.611463],
                [105.505447, 21.6114148],
                [105.5054963, 21.6114026],
                [105.5060273, 21.6111782],
                [105.5061561, 21.6110959],
                [105.5062768, 21.6109936],
                [105.5066764, 21.6103852],
                [105.5071056, 21.6100111],
                [105.5071056, 21.6096072],
                [105.5075723, 21.6091982],
                [105.5078942, 21.6090635],
                [105.5079911, 21.6089886],
                [105.5080681, 21.6088958],
                [105.5081195, 21.6087951],
                [105.5081465, 21.6086923],
                [105.5081516, 21.6086396],
                [105.5081221, 21.6085448],
                [105.508047, 21.6084177],
                [105.5083153, 21.6081234],
                [105.5083582, 21.6080461],
                [105.5084114, 21.6078796],
                [105.5084842, 21.6077568],
                [105.5089885, 21.607288],
                [105.5092138, 21.6069688],
                [105.5096912, 21.6066496],
                [105.5097884, 21.6065463],
                [105.5098697, 21.6064292],
                [105.5099326, 21.6063005],
                [105.5099165, 21.6060461],
                [105.5099487, 21.605889],
                [105.5100238, 21.6057095],
                [105.510174, 21.6054576],
                [105.5102038, 21.6053776],
                [105.5102085, 21.6053354],
                [105.5101971, 21.6052518],
                [105.5101594, 21.6051757],
                [105.5101317, 21.6051424],
                [105.5100989, 21.6051135],
                [105.509761, 21.6049189],
                [105.5098551, 21.6048138],
                [105.5099273, 21.6046945],
                [105.5099751, 21.6045653],
                [105.509997, 21.6044302],
                [105.5104959, 21.6043454],
                [105.5105779, 21.6042955],
                [105.5106541, 21.6042381],
                [105.5107802, 21.604111],
                [105.5108252, 21.6040818],
                [105.5109189, 21.6040436],
                [105.5109653, 21.6040336],
                [105.5115259, 21.6039738],
                [105.5116408, 21.6039411],
                [105.5116948, 21.6039164],
                [105.5117977, 21.6038483],
                [105.5120569, 21.6036122],
                [105.5121054, 21.6035841],
                [105.512226, 21.6035338],
                [105.5123788, 21.603495],
                [105.5125344, 21.6034401],
                [105.5127163, 21.6033441],
                [105.5128599, 21.6032372],
                [105.5129904, 21.6031533],
                [105.5130834, 21.6031079],
                [105.5133046, 21.6030312],
                [105.5134197, 21.602971],
                [105.513522, 21.6028922],
                [105.5138111, 21.6026197],
                [105.5141088, 21.6024177],
                [105.5144092, 21.6021034],
                [105.5144714, 21.6020252],
                [105.5145803, 21.6018591],
                [105.5146623, 21.6016935],
                [105.5147088, 21.6015735],
                [105.5147312, 21.6014657],
                [105.5147243, 21.6013313],
                [105.5147067, 21.6012659],
                [105.514666, 21.6011646],
                [105.5146606, 21.6011241],
                [105.5146641, 21.6010835],
                [105.514693, 21.6010136],
                [105.5147475, 21.6009584],
                [105.5151066, 21.6006994],
                [105.5151821, 21.6006172],
                [105.5152108, 21.60057],
                [105.5152483, 21.6004636],
                [105.5152555, 21.6004059],
                [105.5152451, 21.6002756],
                [105.5152032, 21.6001358],
                [105.5156028, 21.600071],
                [105.5160212, 21.5998316],
                [105.5160729, 21.599792],
                [105.5161619, 21.5996993],
                [105.5162319, 21.5995848],
                [105.5162751, 21.5994518],
                [105.5163027, 21.5992811],
                [105.516327, 21.5991807],
                [105.5163841, 21.5989994],
                [105.5164455, 21.5988813],
                [105.5165362, 21.5987742],
                [105.5171156, 21.5987043],
                [105.5173021, 21.5985934],
                [105.5174721, 21.5984607],
                [105.51755, 21.5983867],
                [105.5180812, 21.5977816],
                [105.5185872, 21.5970187],
                [105.5186713, 21.5969087],
                [105.5192077, 21.5963252],
                [105.5192989, 21.595971],
                [105.5195081, 21.5955071],
                [105.5195752, 21.5949834],
                [105.5194498, 21.5946311],
                [105.5194437, 21.594547],
                [105.5194524, 21.5944976],
                [105.519496, 21.5944065],
                [105.5195296, 21.5943674],
                [105.5198353, 21.5940706],
                [105.5199146, 21.5939547],
                [105.5199426, 21.5938911],
                [105.5199711, 21.5937779],
                [105.5199778, 21.5934915],
                [105.5200124, 21.5931529],
                [105.520045, 21.5929798],
                [105.5200875, 21.5928087],
                [105.5201422, 21.5927027],
                [105.5202189, 21.5926092],
                [105.5203191, 21.5925288],
                [105.5204354, 21.59247],
                [105.5206128, 21.5924068],
                [105.5208344, 21.5923044],
                [105.5210624, 21.5921673],
                [105.5211788, 21.5920824],
                [105.5212891, 21.5919907],
                [105.5217719, 21.5915018],
                [105.5223674, 21.5909182],
                [105.5225146, 21.5907697],
                [105.5226541, 21.590615],
                [105.5228901, 21.590315],
                [105.5230807, 21.5900255],
                [105.5231667, 21.5898757],
                [105.5235636, 21.5889081],
                [105.5237092, 21.588763],
                [105.523847, 21.5886116],
                [105.5239767, 21.5884541],
                [105.5241897, 21.5881555],
                [105.5243736, 21.5878406],
                [105.5245775, 21.5874216],
                [105.5246633, 21.5870425],
                [105.5248564, 21.5866434],
                [105.5248915, 21.5865453],
                [105.5249201, 21.5864455],
                [105.5249567, 21.5862515],
                [105.525004, 21.5854488],
                [105.5251569, 21.585142],
                [105.5252467, 21.5850411],
                [105.5252829, 21.5849849],
                [105.5253311, 21.5848785],
                [105.5256128, 21.5838051],
                [105.5266374, 21.5821291],
                [105.5268313, 21.5822062],
                [105.5270344, 21.5822588],
                [105.5272393, 21.5822855],
                [105.5274463, 21.5822873],
                [105.5280992, 21.5822139],
                [105.5284023, 21.5822014],
                [105.5285487, 21.5821401],
                [105.5286249, 21.5821166],
                [105.5287991, 21.5820821],
                [105.5291721, 21.5820543],
                [105.5295235, 21.5819944],
                [105.5297893, 21.5819605],
                [105.5300819, 21.5819455],
                [105.5302408, 21.5819471],
                [105.5305575, 21.5819707],
                [105.5308163, 21.5821341],
                [105.5308787, 21.5821485],
                [105.5310068, 21.582154],
                [105.5311093, 21.5821353],
                [105.5311584, 21.5821184],
                [105.5314252, 21.5819744],
                [105.5321757, 21.5818718],
                [105.5323371, 21.5818398],
                [105.5325973, 21.5817674],
                [105.5329299, 21.5817599],
                [105.5330056, 21.5817769],
                [105.5331525, 21.5818248],
                [105.5332488, 21.5818681],
                [105.5333872, 21.5819447],
                [105.5334878, 21.5819819],
                [105.5335547, 21.5819961],
                [105.5336915, 21.5820017],
                [105.5337594, 21.5819931],
                [105.5339465, 21.5819507],
                [105.5340645, 21.5818784],
                [105.534139, 21.5818506],
                [105.5341785, 21.5818435],
                [105.5342732, 21.5818453],
                [105.5344072, 21.5818691],
                [105.5344958, 21.5818762],
                [105.5346801, 21.5818715],
                [105.5348677, 21.5818392],
                [105.5349589, 21.5818128],
                [105.5350475, 21.5817799],
                [105.535199, 21.5816901],
                [105.5355874, 21.5817015],
                [105.5356738, 21.5816808],
                [105.5357978, 21.581618],
                [105.535891, 21.5815904],
                [105.5359957, 21.5815829],
                [105.5363062, 21.581591],
                [105.5364114, 21.5815779],
                [105.5365032, 21.5815429],
                [105.5365831, 21.5814881],
                [105.5366667, 21.5813817],
                [105.5368118, 21.5811556],
                [105.5369179, 21.5809334],
                [105.5369905, 21.5807211],
                [105.5370176, 21.5806126],
                [105.5370927, 21.5798644],
                [105.5373555, 21.5794703],
                [105.537556, 21.5793048],
                [105.5376469, 21.5792132],
                [105.5377982, 21.579028],
                [105.5380422, 21.5786322],
                [105.5382514, 21.5783728],
                [105.5386269, 21.5777742],
                [105.5389756, 21.5775946],
                [105.5398124, 21.577001],
                [105.5403328, 21.5765171],
                [105.5405205, 21.5762078],
                [105.5408692, 21.5760631],
                [105.5411589, 21.5757389],
                [105.5413497, 21.5754858],
                [105.5414379, 21.5753548],
                [105.5418241, 21.5745416],
                [105.5418321, 21.5743446],
                [105.5418962, 21.5742761],
                [105.5419341, 21.5742473],
                [105.5419882, 21.574216],
                [105.542113, 21.5741696],
                [105.5421754, 21.5741282],
                [105.5422264, 21.5740677],
                [105.5422879, 21.5739463],
                [105.5423284, 21.5738906],
                [105.5424196, 21.5737983],
                [105.5424811, 21.5737776],
                [105.542634, 21.5737495],
                [105.5428044, 21.5737459],
                [105.5435541, 21.5735289],
                [105.5438109, 21.5734442],
                [105.543935, 21.5733918],
                [105.5441424, 21.5732851],
                [105.5443373, 21.5731598],
                [105.5444131, 21.5731357],
                [105.5445062, 21.5731287],
                [105.5445592, 21.573135],
                [105.5446592, 21.5731698],
                [105.5466521, 21.5729453],
                [105.5474567, 21.5729627],
                [105.5475476, 21.5729842],
                [105.547733, 21.5730101],
                [105.5478497, 21.5730146],
                [105.5479664, 21.5730101],
                [105.5487388, 21.5728081],
                [105.5491412, 21.5727407],
                [105.5494577, 21.5727008],
                [105.5498385, 21.5727208],
                [105.5499145, 21.5727352],
                [105.5500537, 21.5727793],
                [105.5501765, 21.5728405],
                [105.550364, 21.5729727],
                [105.5505572, 21.5730975],
                [105.550926, 21.5733067],
                [105.5512762, 21.5734741],
                [105.551641, 21.5735938],
                [105.5520755, 21.5735539],
                [105.5521835, 21.5735629],
                [105.552307, 21.5735956],
                [105.5523744, 21.5736248],
                [105.5524818, 21.5736883],
                [105.5525791, 21.5737273],
                [105.5526947, 21.5737443],
                [105.5527589, 21.5737417],
                [105.5528828, 21.573711],
                [105.5531269, 21.5735788],
                [105.5532168, 21.5735097],
                [105.5532557, 21.5734691],
                [105.5533319, 21.5733532],
                [105.5533576, 21.5732895],
                [105.5534472, 21.5729927],
                [105.5534971, 21.5729228],
                [105.5535684, 21.5728735],
                [105.5536754, 21.5728345],
                [105.5537965, 21.5727727],
                [105.5540872, 21.5725661],
                [105.5542991, 21.5724389],
                [105.5549562, 21.5719775],
                [105.5558247, 21.5715665],
                [105.5560356, 21.5714594],
                [105.5564475, 21.5712292],
                [105.5568874, 21.5709498],
                [105.5571073, 21.5708301],
                [105.5573273, 21.5707802],
                [105.5575848, 21.5706206],
                [105.5579764, 21.5702813],
                [105.558266, 21.5701566],
                [105.5583277, 21.5700967],
                [105.5583601, 21.570032],
                [105.5583929, 21.5696985],
                [105.5584297, 21.5695904],
                [105.558498, 21.5694939],
                [105.5586979, 21.5693135],
                [105.5590975, 21.5685951],
                [105.5592692, 21.568226],
                [105.5592957, 21.5680182],
                [105.5593228, 21.5679166],
                [105.5593901, 21.5677486],
                [105.5594824, 21.5675912],
                [105.5595374, 21.5675175],
                [105.5596762, 21.5673965],
                [105.5597949, 21.5672581],
                [105.5598926, 21.5671022],
                [105.5599319, 21.5670197],
                [105.5599905, 21.5668478],
                [105.5600376, 21.5666545],
                [105.5601114, 21.5664499],
                [105.5609858, 21.5644743],
                [105.5609053, 21.5641251],
                [105.5612594, 21.5635863],
                [105.5614793, 21.5633668],
                [105.5614727, 21.5632396],
                [105.5614823, 21.5629926],
                [105.5615199, 21.5627553],
                [105.5615491, 21.5626384],
                [105.5620453, 21.5614186],
                [105.5622438, 21.5612988],
                [105.562807, 21.560523],
                [105.5628778, 21.5603832],
                [105.5629197, 21.5602337],
                [105.56293, 21.5601459],
                [105.5629302, 21.5600577],
                [105.5629205, 21.5599699],
                [105.5629008, 21.5598835],
                [105.5628714, 21.5597996],
                [105.5628159, 21.5596902],
                [105.5627963, 21.5596325],
                [105.5627722, 21.5594878],
                [105.5628321, 21.5594698],
                [105.562858, 21.5594529],
                [105.562896, 21.5594068],
                [105.5629068, 21.5593794],
                [105.5630172, 21.5587446],
                [105.5630397, 21.5586819],
                [105.5631016, 21.5585739],
                [105.5631574, 21.5585066],
                [105.5632102, 21.5584693],
                [105.5632791, 21.5584501],
                [105.5635822, 21.5584351],
                [105.5637458, 21.5582829],
                [105.5639496, 21.558243],
                [105.5641063, 21.5581838],
                [105.5641803, 21.5581457],
                [105.5646309, 21.5578089],
                [105.5648443, 21.5575641],
                [105.5650623, 21.5573591],
                [105.5652773, 21.5571903],
                [105.5657333, 21.556871],
                [105.5663615, 21.5563231],
                [105.5664694, 21.5561876],
                [105.5665111, 21.5561126],
                [105.5665628, 21.5559732],
                [105.5665779, 21.5559007],
                [105.566605, 21.555],
                [105.5666143, 21.5549298],
                [105.5666332, 21.5548612],
                [105.5666614, 21.5547955],
                [105.5667282, 21.5546944],
                [105.5668167, 21.554609],
                [105.5672139, 21.5543315],
                [105.5673373, 21.5540097],
                [105.5674826, 21.5539079],
                [105.5675465, 21.5538475],
                [105.5676544, 21.553709],
                [105.5677315, 21.553578],
                [105.5678164, 21.5534778],
                [105.5678657, 21.5534334],
                [105.5679834, 21.5533536],
                [105.5680478, 21.5533219],
                [105.5681848, 21.5532762],
                [105.568673, 21.5532812],
                [105.5689359, 21.553139],
                [105.5692416, 21.5530043],
                [105.5693216, 21.5529625],
                [105.5693986, 21.552916],
                [105.5696338, 21.5527505],
                [105.5698264, 21.5526451],
                [105.5700572, 21.5525478],
                [105.5702984, 21.5524754],
                [105.5704322, 21.5524062],
                [105.5704936, 21.5523634],
                [105.5706032, 21.552263],
                [105.5706921, 21.5521453],
                [105.5707276, 21.5520813],
                [105.5708724, 21.5516771],
                [105.570992, 21.5515729],
                [105.5712479, 21.5513828],
                [105.5715212, 21.5512203],
                [105.5718111, 21.5510853],
                [105.5719614, 21.5510285],
                [105.572353, 21.5508439],
                [105.5727017, 21.5502901],
                [105.5730396, 21.5497113],
                [105.573045, 21.5495317],
                [105.5732167, 21.5493571],
                [105.5733429, 21.5492442],
                [105.5736136, 21.5490377],
                [105.5738002, 21.5489199],
                [105.5738872, 21.5488531],
                [105.5740231, 21.5487299],
                [105.5740857, 21.5486635],
                [105.5740857, 21.5483966],
                [105.5740958, 21.5483411],
                [105.574134, 21.5482344],
                [105.5741929, 21.5481395],
                [105.5742708, 21.5480573],
                [105.5747402, 21.5477006],
                [105.5748949, 21.5475615],
                [105.5750446, 21.5473893],
                [105.5751163, 21.5472877],
                [105.5753303, 21.5469222],
                [105.5755904, 21.5467451],
                [105.5757125, 21.5466407],
                [105.5757675, 21.5465829],
                [105.5758819, 21.546429],
                [105.5759284, 21.5463459],
                [105.5760464, 21.5460964],
                [105.576246, 21.5459251],
                [105.5763381, 21.5458321],
                [105.5765048, 21.5456337],
                [105.5765791, 21.5455288],
                [105.5767411, 21.5452582],
                [105.5768047, 21.545165],
                [105.5769476, 21.5449887],
                [105.5770712, 21.544793],
                [105.5772185, 21.544612],
                [105.5773714, 21.5444623],
                [105.5775726, 21.5443875],
                [105.5781466, 21.5437887],
                [105.5784094, 21.5435268],
                [105.5786776, 21.5432149],
                [105.5800402, 21.5420972],
                [105.5800977, 21.5420587],
                [105.580195, 21.5419631],
                [105.5802333, 21.5419076],
                [105.5802806, 21.5418038],
                [105.5803271, 21.5415791],
                [105.5803612, 21.5414675],
                [105.580405, 21.5413587],
                [105.5804934, 21.5412128],
                [105.5806088, 21.5410843],
                [105.5808859, 21.5409862],
                [105.5812139, 21.5408979],
                [105.5824166, 21.5406502],
                [105.5827527, 21.5404492],
                [105.5831033, 21.540271],
                [105.583314, 21.5401779],
                [105.5835287, 21.540093],
                [105.583747, 21.5400165],
                [105.5836253, 21.5398159],
                [105.5835324, 21.5396024],
                [105.5834678, 21.5393688],
                [105.5834378, 21.5391292],
                [105.5834305, 21.5372472],
                [105.583503, 21.5370525],
                [105.5835289, 21.5369523],
                [105.5835593, 21.5367482],
                [105.5835637, 21.5366353],
                [105.5835465, 21.53641],
                [105.5835249, 21.5362988],
                [105.583451, 21.536038],
                [105.5833876, 21.5357302],
                [105.583361, 21.5354844],
                [105.5833554, 21.535361],
                [105.5836702, 21.5353628],
                [105.5839819, 21.5353443],
                [105.5842888, 21.5353061],
                [105.5844233, 21.535269],
                [105.5845463, 21.5352063],
                [105.5846543, 21.5351188],
                [105.5848559, 21.5348598],
                [105.5849969, 21.5347272],
                [105.5851509, 21.534622],
                [105.5852339, 21.5345779],
                [105.5854096, 21.5345081],
                [105.5857166, 21.5344176],
                [105.5861505, 21.5342712],
                [105.5865848, 21.5341035],
                [105.5867112, 21.5340671],
                [105.5868402, 21.5340396],
                [105.586971, 21.5340212],
                [105.5872983, 21.533879],
                [105.5877006, 21.5338839],
                [105.5882397, 21.5337268],
                [105.5884967, 21.5338326],
                [105.5887654, 21.5339089],
                [105.5890358, 21.5339538],
                [105.5893099, 21.5339688],
                [105.5895217, 21.5339507],
                [105.5897284, 21.5339039],
                [105.5903614, 21.5339039],
                [105.5905628, 21.5338289],
                [105.5906677, 21.5338026],
                [105.5908734, 21.5337741],
                [105.5909729, 21.5337708],
                [105.5912935, 21.533792],
                [105.5915362, 21.5338216],
                [105.5916608, 21.5338441],
                [105.5921574, 21.5339585],
                [105.5926127, 21.5340329],
                [105.5930194, 21.5340736],
                [105.593128, 21.5340731],
                [105.593344, 21.5340536],
                [105.5935499, 21.5340112],
                [105.593749, 21.5339463],
                [105.5939233, 21.5338515],
                [105.5947763, 21.5337916],
                [105.5949533, 21.5334323],
                [105.5950237, 21.5334206],
                [105.5950951, 21.5334155],
                [105.5952376, 21.5334249],
                [105.5953887, 21.5334651],
                [105.5955268, 21.5335347],
                [105.5956701, 21.5336299],
                [105.5958438, 21.5337118],
                [105.596061, 21.5337068],
                [105.596458, 21.5335072],
                [105.5965362, 21.533493],
                [105.5966319, 21.5334983],
                [105.596686, 21.5335125],
                [105.5969596, 21.533612],
                [105.5972975, 21.533612],
                [105.5974665, 21.5335596],
                [105.5976328, 21.5334723],
                [105.5977136, 21.5334141],
                [105.597788, 21.533349],
                [105.5978554, 21.5332777],
                [105.5979657, 21.5331207],
                [105.5980083, 21.5330356],
                [105.5981343, 21.5327232],
                [105.5981957, 21.5326508],
                [105.5982363, 21.532624],
                [105.5983307, 21.5325938],
                [105.5983808, 21.5325917],
                [105.5985844, 21.5326364],
                [105.5986927, 21.53265],
                [105.5988019, 21.5326545],
                [105.5989112, 21.53265],
                [105.5991231, 21.5326148],
                [105.5993222, 21.5325471],
                [105.5995059, 21.5324485],
                [105.5995902, 21.5323886],
                [105.6005752, 21.5315636],
                [105.6010419, 21.5312941],
                [105.6012404, 21.5309847],
                [105.6012458, 21.5304059],
                [105.6014382, 21.5302563],
                [105.6016493, 21.5301301],
                [105.601888, 21.5300249],
                [105.6020187, 21.5299816],
                [105.6024413, 21.5298651],
                [105.6030106, 21.5296773],
                [105.6032145, 21.529358],
                [105.6033648, 21.5292721],
                [105.6034452, 21.5292382],
                [105.6036084, 21.5291907],
                [105.6037778, 21.5291683],
                [105.6042874, 21.5283998],
                [105.6046146, 21.5280455],
                [105.6046465, 21.5279946],
                [105.6046951, 21.5278859],
                [105.6047218, 21.5277735],
                [105.6047273, 21.5277162],
                [105.6047134, 21.5274243],
                [105.604727, 21.5273607],
                [105.6047434, 21.5273319],
                [105.6047906, 21.5272865],
                [105.6048202, 21.5272707],
                [105.6048525, 21.5272604],
                [105.6049731, 21.527253],
                [105.6050246, 21.5272414],
                [105.6050736, 21.5272227],
                [105.6051669, 21.5271624],
                [105.605253, 21.5270824],
                [105.6052946, 21.5270321],
                [105.605362, 21.5269219],
                [105.6053871, 21.5268629],
                [105.605489, 21.5264237],
                [105.6056339, 21.5259072],
                [105.6056958, 21.5257681],
                [105.6057389, 21.5257042],
                [105.6057894, 21.5256452],
                [105.6059008, 21.5255511],
                [105.606145, 21.5254122],
                [105.6062542, 21.5253373],
                [105.6064511, 21.5251735],
                [105.6066204, 21.5249935],
                [105.606696, 21.5248967],
                [105.6071493, 21.5244326],
                [105.6071831, 21.5243244],
                [105.60723, 21.5241033],
                [105.6072485, 21.5238787],
                [105.6072461, 21.5237449],
                [105.6072335, 21.5236116],
                [105.6072029, 21.5233993],
                [105.6072137, 21.5232387],
                [105.6072324, 21.5231601],
                [105.6072853, 21.5230296],
                [105.6073625, 21.5229101],
                [105.6074095, 21.5228557],
                [105.6075597, 21.5223666],
                [105.6074148, 21.5220372],
                [105.6074062, 21.5217406],
                [105.6073738, 21.5216098],
                [105.6073064, 21.5214871],
                [105.6072597, 21.521431],
                [105.6072056, 21.521381],
                [105.6071918, 21.5211435],
                [105.6071797, 21.5210563],
                [105.6071287, 21.5208874],
                [105.6070903, 21.5208071],
                [105.6070058, 21.5206787],
                [105.6068408, 21.5204959],
                [105.6067895, 21.5204216],
                [105.6067121, 21.5202606],
                [105.6066754, 21.5201203],
                [105.6066638, 21.5199762],
                [105.606696, 21.5193624],
                [105.6067711, 21.5188184],
                [105.6067979, 21.5182395],
                [105.6068408, 21.5177355],
                [105.6067952, 21.5173662],
                [105.6067765, 21.5170617],
                [105.607001, 21.5169562],
                [105.607108, 21.5168945],
                [105.6073248, 21.5167424],
                [105.6074322, 21.5166508],
                [105.6075329, 21.5165527],
                [105.6077099, 21.5162832],
                [105.6077891, 21.5162373],
                [105.6078627, 21.5161839],
                [105.6079842, 21.5160635],
                [105.6080734, 21.5159305],
                [105.6081337, 21.5157841],
                [105.6083912, 21.5155845],
                [105.6085433, 21.5154391],
                [105.6087023, 21.5153],
                [105.6090134, 21.5150605],
                [105.609118, 21.514948],
                [105.609301, 21.5146568],
                [105.6094309, 21.5145106],
                [105.6095872, 21.5143875],
                [105.609979, 21.5141647],
                [105.6101231, 21.5140698],
                [105.6102618, 21.5139682],
                [105.6105116, 21.5137557],
                [105.6107291, 21.5135322],
                [105.6108293, 21.5134136],
                [105.6112155, 21.5128945],
                [105.6114284, 21.5126912],
                [105.6115267, 21.5125826],
                [105.6116715, 21.5124005],
                [105.6116576, 21.5122102],
                [105.611642, 21.512116],
                [105.6115939, 21.5119315],
                [105.6115615, 21.5118415],
                [105.6114972, 21.511577],
                [105.6115133, 21.5114373],
                [105.6115317, 21.511367],
                [105.6115884, 21.5112326],
                [105.6115776, 21.5108983],
                [105.6116258, 21.5107134],
                [105.6116389, 21.5105421],
                [105.6116337, 21.510427],
                [105.6116114, 21.5103514],
                [105.6115906, 21.5103162],
                [105.6113443, 21.5100149],
                [105.6111981, 21.5098177],
                [105.61106, 21.5096156],
                [105.6110546, 21.5095358],
                [105.6110743, 21.509469],
                [105.6112155, 21.5092588],
                [105.6113175, 21.5088395],
                [105.6112005, 21.5087595],
                [105.6111107, 21.5086571],
                [105.6110519, 21.5085401],
                [105.6110436, 21.5084917],
                [105.611051, 21.5084013],
                [105.6110835, 21.5083232],
                [105.6111082, 21.5082881],
                [105.6112611, 21.5081258],
                [105.6113684, 21.5079686],
                [105.611466, 21.5077729],
                [105.6115347, 21.5075669],
                [105.6114636, 21.5073947],
                [105.6114006, 21.5072774],
                [105.6113768, 21.5071863],
                [105.6113757, 21.5071394],
                [105.6114504, 21.5067747],
                [105.6114703, 21.5065612],
                [105.6115937, 21.5058176],
                [105.6117209, 21.5057209],
                [105.6118714, 21.5056508],
                [105.611954, 21.5056274],
                [105.6121692, 21.505591],
                [105.6122911, 21.5055431],
                [105.6123564, 21.5055037],
                [105.6124158, 21.5054569],
                [105.6124681, 21.5054033],
                [105.6126291, 21.5051937],
                [105.6127793, 21.5051038],
                [105.6129616, 21.505034],
                [105.6132976, 21.5049458],
                [105.6134613, 21.5048891],
                [105.613767, 21.5047569],
                [105.6140463, 21.504602],
                [105.6141794, 21.5045149],
                [105.6143595, 21.5043774],
                [105.6144565, 21.5043173],
                [105.6146622, 21.5042155],
                [105.6148674, 21.5041434],
                [105.6149732, 21.5041165],
                [105.6155795, 21.5040058],
                [105.6158788, 21.503816],
                [105.6160355, 21.5037313],
                [105.6163504, 21.503588],
                [105.6165129, 21.5035267],
                [105.6167382, 21.5033171],
                [105.6168935, 21.5032027],
                [105.6170178, 21.5030789],
                [105.6171137, 21.5029527],
                [105.617162, 21.5027631],
                [105.6176716, 21.5024037],
                [105.6178253, 21.5022798],
                [105.6179613, 21.5021392],
                [105.6180283, 21.5020547],
                [105.6181428, 21.5018738],
                [105.619238, 21.499439],
                [105.6192717, 21.4993303],
                [105.6192809, 21.4992743],
                [105.6192815, 21.4991374],
                [105.6192702, 21.4990696],
                [105.6196249, 21.49905],
                [105.6198013, 21.4990297],
                [105.6201173, 21.4989754],
                [105.6202733, 21.4989399],
                [105.6206489, 21.4991745],
                [105.6218398, 21.499454],
                [105.621916, 21.4994663],
                [105.6220704, 21.4994739],
                [105.6222567, 21.4994528],
                [105.6224352, 21.4993991],
                [105.6225532, 21.4991994],
                [105.6228858, 21.49887],
                [105.6230321, 21.4986914],
                [105.6231435, 21.4985097],
                [105.6232238, 21.4983309],
                [105.6232792, 21.4981463],
                [105.6233096, 21.4979566],
                [105.6239641, 21.4974425],
                [105.6241398, 21.497341],
                [105.6242323, 21.4972978],
                [105.6244554, 21.4972181],
                [105.6245709, 21.4971893],
                [105.6251925, 21.4970781],
                [105.6259382, 21.4969334],
                [105.6263028, 21.4969175],
                [105.6269681, 21.4969061],
                [105.628438, 21.4969284],
                [105.6286805, 21.4968341],
                [105.628919, 21.4967314],
                [105.6291532, 21.4966204],
                [105.6293828, 21.4965013],
                [105.6296074, 21.4963744],
                [105.6299639, 21.4961497],
                [105.6301363, 21.4960299],
                [105.6309378, 21.495436],
                [105.6315172, 21.4950667],
                [105.6320437, 21.4947563],
                [105.6322259, 21.494663],
                [105.6323111, 21.4946075],
                [105.6324694, 21.4944783],
                [105.6325406, 21.4944061],
                [105.6327443, 21.4941528],
                [105.6329226, 21.4939785],
                [105.6334215, 21.493694],
                [105.6348538, 21.4932398],
                [105.6348439, 21.4931595],
                [105.6348091, 21.4930189],
                [105.634787, 21.4929589],
                [105.6347404, 21.4928756],
                [105.6346189, 21.4927079],
                [105.6345427, 21.4926259],
                [105.6344608, 21.4925186],
                [105.6343871, 21.4924063],
                [105.6341733, 21.4920065],
                [105.6340599, 21.4918148],
                [105.6338453, 21.4914878],
                [105.6336098, 21.4910558],
                [105.6338802, 21.490794],
                [105.63384, 21.4895961],
                [105.6337698, 21.48947],
                [105.633691, 21.4893483],
                [105.6336039, 21.4892317],
                [105.6334333, 21.4890414],
                [105.6333397, 21.4889533],
                [105.6332409, 21.4888702],
                [105.632981, 21.4886757],
                [105.6327762, 21.4885538],
                [105.632524, 21.4884379],
                [105.6324339, 21.4883647],
                [105.6323755, 21.4882883],
                [105.6322682, 21.4880287],
                [105.632531, 21.487844],
                [105.6326153, 21.4877444],
                [105.6326491, 21.4876893],
                [105.6327039, 21.4875506],
                [105.632718, 21.4874777],
                [105.6327188, 21.4873299],
                [105.6328368, 21.4871352],
                [105.6329495, 21.4869306],
                [105.6331191, 21.4866567],
                [105.6331657, 21.4864918],
                [105.6331779, 21.4864072],
                [105.6331801, 21.4862367],
                [105.633156, 21.4860952],
                [105.6331319, 21.4860271],
                [105.6330621, 21.4859016],
                [105.6329656, 21.4857925],
                [105.6323495, 21.485367],
                [105.6321904, 21.4852571],
                [105.6321476, 21.4851618],
                [105.6321142, 21.4850632],
                [105.6320773, 21.4848678],
                [105.6320569, 21.4846292],
                [105.6320563, 21.4843412],
                [105.6317934, 21.4840928],
                [105.6317505, 21.4840342],
                [105.6317353, 21.4840006],
                [105.6316929, 21.4837771],
                [105.631666, 21.4835013],
                [105.6313455, 21.4820612],
                [105.6311798, 21.4819395],
                [105.6311217, 21.4818657],
                [105.6310853, 21.4817754],
                [105.6310265, 21.4814594],
                [105.6310075, 21.4812999],
                [105.6309763, 21.4806303],
                [105.6309834, 21.4805137],
                [105.631012, 21.4803414],
                [105.6310344, 21.4802567],
                [105.63108, 21.4798049],
                [105.6310944, 21.4795585],
                [105.6311417, 21.4793157],
                [105.6312176, 21.4790887],
                [105.6312664, 21.4789787],
                [105.6315762, 21.4786742],
                [105.6319785, 21.4781102],
                [105.6321233, 21.4777782],
                [105.6322031, 21.4776535],
                [105.6323017, 21.4775411],
                [105.6324373, 21.4774289],
                [105.6325123, 21.4773813],
                [105.6332619, 21.4769995],
                [105.6333786, 21.4769009],
                [105.6334335, 21.476845],
                [105.6337059, 21.4765252],
                [105.6340545, 21.4761783],
                [105.6345347, 21.4758338],
                [105.6350751, 21.4753933],
                [105.6359254, 21.4749253],
                [105.6361396, 21.4747684],
                [105.6363317, 21.4745883],
                [105.6364276, 21.4744795],
                [105.6365154, 21.474365],
                [105.6366178, 21.4742108],
                [105.6366784, 21.4741528],
                [105.6367609, 21.4741103],
                [105.6368608, 21.4740926],
                [105.6374127, 21.4741241],
                [105.6376385, 21.4741169],
                [105.637861, 21.4740854],
                [105.6380772, 21.4740304],
                [105.6381825, 21.4739943],
                [105.6384884, 21.473864],
                [105.6387766, 21.4737022],
                [105.6389378, 21.4735756],
                [105.6390113, 21.4735051],
                [105.6391401, 21.473354],
                [105.6391963, 21.4732729],
                [105.6393492, 21.4731531],
                [105.639493, 21.4730717],
                [105.640229, 21.4727687],
                [105.6405704, 21.4726474],
                [105.6409512, 21.4725372],
                [105.6413716, 21.4724442],
                [105.6416211, 21.4723744],
                [105.6417557, 21.4723127],
                [105.6418669, 21.4722308],
                [105.6419531, 21.472136],
                [105.6421763, 21.4717903],
                [105.643115, 21.4709816],
                [105.6435643, 21.4707444],
                [105.6436761, 21.4706686],
                [105.64378, 21.470569],
                [105.6439322, 21.470361],
                [105.6440983, 21.4701767],
                [105.6442038, 21.4700798],
                [105.6443161, 21.4699897],
                [105.6444347, 21.469907],
                [105.6446211, 21.4695719],
                [105.6448048, 21.4691694],
                [105.645057, 21.468785],
                [105.6451642, 21.4685903],
                [105.6452419, 21.4683958],
                [105.6454424, 21.467816],
                [105.6455625, 21.4673734],
                [105.6456417, 21.4669728],
                [105.6455666, 21.4659943],
                [105.645597, 21.4657952],
                [105.6456054, 21.4655696],
                [105.645574, 21.4651806],
                [105.645588, 21.4650274],
                [105.645606, 21.4649452],
                [105.6456631, 21.4647862],
                [105.6462524, 21.4638669],
                [105.6463981, 21.4636878],
                [105.6464858, 21.4635991],
                [105.6466793, 21.463439],
                [105.6470986, 21.4631661],
                [105.647251, 21.4630588],
                [105.6476193, 21.4627712],
                [105.6479645, 21.4624597],
                [105.6486654, 21.4619075],
                [105.648914, 21.4616759],
                [105.6491457, 21.4614177],
                [105.649901, 21.4604677],
                [105.6499714, 21.4603622],
                [105.6500351, 21.460253],
                [105.650134, 21.4600456],
                [105.6502905, 21.4595927],
                [105.6503624, 21.4594243],
                [105.6504937, 21.4591669],
                [105.6506467, 21.45892],
                [105.6509739, 21.4584957],
                [105.6512636, 21.4582161],
                [105.6518215, 21.4573623],
                [105.653286, 21.4576993],
                [105.6541899, 21.4577992],
                [105.6543294, 21.4578179],
                [105.6546046, 21.4578741],
                [105.6548941, 21.4579627],
                [105.6550451, 21.4580219],
                [105.6551923, 21.4580889],
                [105.6556195, 21.4583209],
                [105.6574219, 21.4587852],
                [105.6575745, 21.4588154],
                [105.6578833, 21.4588554],
                [105.6582198, 21.4588677],
                [105.6587809, 21.4588365],
                [105.6590042, 21.458852],
                [105.6592461, 21.4588995],
                [105.6593639, 21.458935],
                [105.6603563, 21.4594443],
                [105.6604843, 21.4594633],
                [105.6607425, 21.4594842],
                [105.6609859, 21.4594827],
                [105.6612655, 21.4594669],
                [105.6615425, 21.4594772],
                [105.6617778, 21.4595092],
                [105.6621059, 21.4595679],
                [105.662489, 21.4596085],
                [105.6627073, 21.4596185],
                [105.6632826, 21.4596115],
                [105.6635878, 21.4596326],
                [105.6637394, 21.4596526],
                [105.664039, 21.4597113],
                [105.6644287, 21.4598224],
                [105.6648236, 21.4599081],
                [105.6652218, 21.4599685],
                [105.6657904, 21.4600284],
                [105.6664288, 21.4601232],
                [105.6665325, 21.4600494],
                [105.66663, 21.4599685],
                [105.6667209, 21.4598805],
                [105.6668043, 21.4597862],
                [105.6669524, 21.459574],
                [105.6670362, 21.4594743],
                [105.6671262, 21.4593793],
                [105.667311, 21.4592147],
                [105.6674105, 21.4591397],
                [105.6679094, 21.45891],
                [105.6683707, 21.4584158],
                [105.6684663, 21.4583464],
                [105.6686711, 21.4582261],
                [105.6687843, 21.4581736],
                [105.6690209, 21.458091],
                [105.6691432, 21.4580613],
                [105.669668, 21.4579823],
                [105.6701763, 21.4579282],
                [105.6706667, 21.4578966],
                [105.6717475, 21.4577694],
                [105.6721205, 21.4577532],
                [105.6724423, 21.4577618],
                [105.6726266, 21.4577945],
                [105.6728033, 21.457853],
                [105.673033, 21.4579532],
                [105.6731816, 21.4580081],
                [105.6734602, 21.4580911],
                [105.673719, 21.4581462],
                [105.6739059, 21.458168],
                [105.6740937, 21.4581802],
                [105.6742819, 21.458183],
                [105.6744701, 21.4581761],
                [105.6747999, 21.4581409],
                [105.6751245, 21.4580763],
                [105.6752778, 21.4579882],
                [105.6755698, 21.4577917],
                [105.675777, 21.4576254],
                [105.6759622, 21.4574571],
                [105.6761545, 21.4573174],
                [105.6763234, 21.4572273],
                [105.6765845, 21.4571208],
                [105.6767353, 21.4570281],
                [105.6771496, 21.4566684],
                [105.6772271, 21.4566112],
                [105.6773096, 21.4565603],
                [105.6774686, 21.4564856],
                [105.677619, 21.4564387],
                [105.6777987, 21.4563988],
                [105.678252, 21.4559894],
                [105.6785577, 21.4554202],
                [105.6789708, 21.4546064],
                [105.6790172, 21.4543983],
                [105.6790513, 21.4542969],
                [105.6791464, 21.4540901],
                [105.6792712, 21.4538974],
                [105.6798881, 21.4531635],
                [105.6802851, 21.4525144],
                [105.6807786, 21.4519952],
                [105.680864, 21.4518852],
                [105.680941, 21.4517699],
                [105.6810092, 21.4516499],
                [105.6810683, 21.4515259],
                [105.6811168, 21.451402],
                [105.6811562, 21.4512753],
                [105.6812088, 21.4510406],
                [105.6812826, 21.4508283],
                [105.6813346, 21.4507234],
                [105.6813956, 21.4506229],
                [105.6814653, 21.4505273],
                [105.681873, 21.4500979],
                [105.6821519, 21.4496585],
                [105.6822941, 21.4494488],
                [105.6823863, 21.4492744],
                [105.6824416, 21.4491295],
                [105.6825006, 21.4488672],
                [105.6825795, 21.4487074],
                [105.6826672, 21.4485517],
                [105.6828639, 21.4482592],
                [105.6830868, 21.4479918],
                [105.6832087, 21.4478661],
                [105.6833295, 21.4477596],
                [105.6835896, 21.4475665],
                [105.6837008, 21.4474967],
                [105.6840368, 21.4473102],
                [105.6842311, 21.4471695],
                [105.684405, 21.4470073],
                [105.6852633, 21.445794],
                [105.6860143, 21.4447555],
                [105.6861773, 21.4447101],
                [105.6862551, 21.4446779],
                [105.6864005, 21.4445957],
                [105.6864736, 21.4445409],
                [105.6866017, 21.4444134],
                [105.6867197, 21.4442636],
                [105.6868922, 21.4440962],
                [105.6869692, 21.443994],
                [105.6870232, 21.4438764],
                [105.6870497, 21.4437509],
                [105.6870496, 21.4433125],
                [105.6871086, 21.4430628],
                [105.6871409, 21.4428791],
                [105.6871784, 21.4425086],
                [105.6873017, 21.442234],
                [105.68816, 21.442224],
                [105.6883056, 21.4422455],
                [105.6885674, 21.4423026],
                [105.6887984, 21.4423738],
                [105.6888685, 21.4424052],
                [105.6889423, 21.4424281],
                [105.6890186, 21.4424423],
                [105.6890962, 21.4424475],
                [105.6892478, 21.4424314],
                [105.6893882, 21.4423826],
                [105.6894526, 21.4423467],
                [105.6895119, 21.4423039],
                [105.6900054, 21.4416048],
                [105.6901824, 21.4409358],
                [105.690261, 21.4407866],
                [105.6903657, 21.4406522],
                [105.6904936, 21.4405363],
                [105.6905749, 21.4404802],
                [105.6906618, 21.4404316],
                [105.6907533, 21.4403912],
                [105.6908486, 21.4403592],
                [105.6909467, 21.440336],
                [105.6910469, 21.4403217],
                [105.691148, 21.4403166],
                [105.691287, 21.4403315],
                [105.6915665, 21.4403365],
                [105.6918384, 21.4403094],
                [105.6920987, 21.4402526],
                [105.6923496, 21.4401668],
                [105.6925538, 21.4401075],
                [105.692967, 21.4400051],
                [105.693396, 21.4399229],
                [105.6938396, 21.4398628],
                [105.6942862, 21.4398273],
                [105.6954194, 21.439826],
                [105.6975945, 21.4389657],
                [105.6979229, 21.4388652],
                [105.6982612, 21.4387987],
                [105.6984396, 21.4387777],
                [105.6986189, 21.4387662],
                [105.6987987, 21.4387642],
                [105.6989783, 21.4387717],
                [105.6991571, 21.4387887],
                [105.7004607, 21.4389534],
                [105.7007106, 21.4389989],
                [105.7009542, 21.4390683],
                [105.7010983, 21.439122],
                [105.7012386, 21.439184],
                [105.7013743, 21.4392542],
                [105.701505, 21.4393322],
                [105.703218, 21.4404564],
                [105.703386, 21.4405274],
                [105.703565, 21.4405694],
                [105.7037491, 21.4405812],
                [105.703844, 21.4405753],
                [105.7040302, 21.4405394],
                [105.7042061, 21.4404724],
                [105.704366, 21.4403765],
                [105.7044863, 21.4403173],
                [105.7047361, 21.4402167],
                [105.7049812, 21.4401445],
                [105.7051063, 21.4401168],
                [105.7052267, 21.4401053],
                [105.7053457, 21.4400844],
                [105.7054624, 21.4400542],
                [105.705576, 21.440015],
                [105.7056856, 21.4399671],
                [105.7058753, 21.4398566],
                [105.7060442, 21.4397201],
                [105.7061884, 21.4395609],
                [105.7062501, 21.4394739],
                [105.7063508, 21.439288],
                [105.7066673, 21.4385789],
                [105.7068819, 21.4381295],
                [105.7068977, 21.4380468],
                [105.7068919, 21.437956],
                [105.7068594, 21.4378628],
                [105.7068059, 21.4377727],
                [105.7067866, 21.437722],
                [105.7067746, 21.4376152],
                [105.7067953, 21.4375192],
                [105.7068445, 21.4374325],
                [105.7069182, 21.4373625],
                [105.7069623, 21.4373356],
                [105.7070138, 21.4372723],
                [105.707057, 21.4372037],
                [105.7070911, 21.4371309],
                [105.7071279, 21.4369957],
                [105.707134, 21.4368563],
                [105.707193, 21.436297],
                [105.7070965, 21.4359974],
                [105.7071027, 21.4358881],
                [105.707134, 21.4357827],
                [105.7071634, 21.4357248],
                [105.7072445, 21.4356216],
                [105.7072949, 21.435578],
                [105.7073101, 21.4354129],
                [105.7073307, 21.4353322],
                [105.7073969, 21.4351785],
                [105.7074934, 21.4350407],
                [105.7076169, 21.4349231],
                [105.7077626, 21.4348301],
                [105.7087004, 21.4344345],
                [105.7090437, 21.4342447],
                [105.7093549, 21.4340849],
                [105.7097518, 21.4339951],
                [105.7098293, 21.4339606],
                [105.7098627, 21.4339352],
                [105.7099169, 21.4338668],
                [105.7099442, 21.4337805],
                [105.7099879, 21.4332361],
                [105.7102083, 21.433083],
                [105.7103097, 21.4329964],
                [105.7104066, 21.4329015],
                [105.7104962, 21.4328007],
                [105.7105782, 21.4326943],
                [105.7107174, 21.4324671],
                [105.7112002, 21.4315583],
                [105.7113964, 21.4313278],
                [105.7115044, 21.4312204],
                [105.7117299, 21.43103],
                [105.7119662, 21.4308687],
                [105.7120907, 21.4307967],
                [105.7124607, 21.4306078],
                [105.7126514, 21.4305238],
                [105.7130429, 21.4303773],
                [105.7134426, 21.4302783],
                [105.7141668, 21.4297256],
                [105.714433, 21.429544],
                [105.7147193, 21.4293911],
                [105.7150266, 21.4292672],
                [105.7153469, 21.4291763],
                [105.7156581, 21.429853],
                [105.7157219, 21.429951],
                [105.7158083, 21.4300327],
                [105.715901, 21.4300884],
                [105.7160041, 21.4301251],
                [105.7161237, 21.4301512],
                [105.7162347, 21.4302],
                [105.7162916, 21.4302367],
                [105.7163435, 21.4302794],
                [105.7164291, 21.4303803],
                [105.7165156, 21.4305423],
                [105.7165915, 21.4306195],
                [105.7166921, 21.4306765],
                [105.716806, 21.4307044],
                [105.7171051, 21.4307293],
                [105.7172379, 21.4308304],
                [105.7175194, 21.4310128],
                [105.7178194, 21.4311674],
                [105.7181272, 21.4312897],
                [105.7182816, 21.4313388],
                [105.7185974, 21.4314157],
                [105.7190167, 21.4314775],
                [105.7191124, 21.4314982],
                [105.7192063, 21.4315253],
                [105.7193863, 21.4315982],
                [105.7194871, 21.4316528],
                [105.7195825, 21.4317153],
                [105.7196717, 21.4317852],
                [105.719754, 21.431862],
                [105.7198289, 21.4319452],
                [105.7200539, 21.4322437],
                [105.7203061, 21.4325316],
                [105.7204429, 21.4326714],
                [105.7207328, 21.4329365],
                [105.7208255, 21.4329821],
                [105.7210189, 21.4330572],
                [105.7212295, 21.4331112],
                [105.7213415, 21.4331293],
                [105.7215682, 21.4331443],
                [105.721795, 21.4331312],
                [105.7220431, 21.4330547],
                [105.7221704, 21.433027],
                [105.7222992, 21.4330064],
                [105.7225171, 21.432988],
                [105.7226264, 21.4329864],
                [105.7245603, 21.4330588],
                [105.7245845, 21.4329136],
                [105.7245791, 21.4327667],
                [105.7245429, 21.4326198],
                [105.7244305, 21.4323759],
                [105.7244128, 21.4322623],
                [105.7244288, 21.4321348],
                [105.7244813, 21.432016],
                [105.7245201, 21.4319627],
                [105.7245396, 21.4318367],
                [105.7245258, 21.4317177],
                [105.7244853, 21.4316121],
                [105.7244557, 21.4315632],
                [105.7244417, 21.431508],
                [105.7244309, 21.4313952],
                [105.7244343, 21.4313385],
                [105.7244685, 21.4311989],
                [105.724499, 21.431133],
                [105.7251799, 21.43018],
                [105.725168, 21.4298561],
                [105.7251723, 21.4296941],
                [105.7252014, 21.4293711],
                [105.7252615, 21.4290336],
                [105.7253516, 21.428702],
                [105.7254072, 21.4285481],
                [105.725432, 21.4283873],
                [105.725426, 21.4282304],
                [105.7253907, 21.4280769],
                [105.7253442, 21.4279426],
                [105.7253355, 21.4278181],
                [105.7253637, 21.4276963],
                [105.725391, 21.4276393],
                [105.7254696, 21.4275384],
                [105.7254895, 21.4275024],
                [105.7255079, 21.4274236],
                [105.7255059, 21.4273832],
                [105.7254584, 21.427214],
                [105.7254107, 21.4270871],
                [105.7253535, 21.4269637],
                [105.7252872, 21.4268443],
                [105.7248044, 21.4260853],
                [105.7247293, 21.4258955],
                [105.7246825, 21.4256978],
                [105.7246478, 21.4253009],
                [105.7245852, 21.4249016],
                [105.7245386, 21.4246984],
                [105.7244825, 21.4244973],
                [105.7243443, 21.4240766],
                [105.7242863, 21.4238633],
                [105.7241978, 21.4234595],
                [105.7238817, 21.4226196],
                [105.7238106, 21.4223447],
                [105.7237637, 21.4222101],
                [105.7237647, 21.4220698],
                [105.7237754, 21.4219297],
                [105.7238222, 21.421668],
                [105.7238561, 21.4215469],
                [105.723976, 21.4212304],
                [105.7239976, 21.4211473],
                [105.7240149, 21.4209772],
                [105.7239982, 21.4208116],
                [105.7239508, 21.4206559],
                [105.7238741, 21.4205106],
                [105.7237708, 21.4203805],
                [105.72371, 21.4203225],
                [105.7235737, 21.4201668],
                [105.7233185, 21.4198429],
                [105.7230878, 21.4195035],
                [105.7228719, 21.4191302],
                [105.7226856, 21.4187433],
                [105.7225299, 21.4183449],
                [105.7224226, 21.4179254],
                [105.7223681, 21.4177535],
                [105.7222821, 21.4175794],
                [105.7222252, 21.417492],
                [105.72209, 21.4173311],
                [105.7214892, 21.4168567],
                [105.7210385, 21.4164674],
                [105.7209491, 21.4163538],
                [105.7208869, 21.4162255],
                [105.7208545, 21.4160869],
                [105.7208501, 21.4160155],
                [105.7208657, 21.4158733],
                [105.7209131, 21.4157374],
                [105.720948, 21.4156736],
                [105.7210386, 21.4155582],
                [105.7212752, 21.4151947],
                [105.7214892, 21.4148191],
                [105.7217038, 21.4143796],
                [105.7217217, 21.4142882],
                [105.7217494, 21.4141989],
                [105.7217864, 21.4141125],
                [105.7218325, 21.41403],
                [105.7219453, 21.4138846],
                [105.7220125, 21.41382],
                [105.7221651, 21.4137104],
                [105.7234204, 21.4130612],
                [105.7238028, 21.4128532],
                [105.7241455, 21.4126419],
                [105.7245969, 21.4123376],
                [105.7247477, 21.4122494],
                [105.7250609, 21.4120916],
                [105.7252228, 21.4120224],
                [105.7272287, 21.4108357],
                [105.7285613, 21.4096873],
                [105.7291542, 21.4092946],
                [105.7298373, 21.4090966],
                [105.730908, 21.409092],
                [105.732149, 21.4093606],
                [105.7326293, 21.4095007],
                [105.7331492, 21.4096524],
                [105.7347515, 21.4104127],
                [105.7354979, 21.4109667],
                [105.7365441, 21.4120211],
                [105.7379491, 21.4134083],
                [105.7395445, 21.4146453],
                [105.7409147, 21.4150293],
                [105.7441614, 21.4159346],
                [105.7465424, 21.4162304],
                [105.7483863, 21.4161665],
                [105.7497895, 21.4157845],
                [105.750395, 21.4145961],
                [105.7511102, 21.4140887],
                [105.7516099, 21.413827],
                [105.7518843, 21.4136036],
                [105.7521106, 21.4134583],
                [105.7523113, 21.4133097],
                [105.7528091, 21.4130123],
                [105.7532628, 21.4126822],
                [105.7542049, 21.4119234],
                [105.7547214, 21.411513],
                [105.7548591, 21.4114036],
                [105.7555919, 21.4108262],
                [105.7558268, 21.4105312],
                [105.7558866, 21.4102191],
                [105.7560413, 21.4096356],
                [105.7564219, 21.4087148],
                [105.7566485, 21.408484],
                [105.7574145, 21.4075942],
                [105.7576758, 21.4073058],
                [105.7580764, 21.4068444],
                [105.7586778, 21.4062671],
                [105.7597999, 21.4047931],
                [105.7605839, 21.4039606],
                [105.7607479, 21.4035167],
                [105.7608694, 21.4032699],
                [105.761026, 21.4030804],
                [105.761209, 21.4029073],
                [105.7614179, 21.4026519],
                [105.7620085, 21.401689],
                [105.762165, 21.4014668],
                [105.7624003, 21.4012524],
                [105.7626097, 21.4010955],
                [105.7628016, 21.4009551],
                [105.7630198, 21.4008146],
                [105.7631329, 21.4006499],
                [105.763262, 21.4002225],
                [105.7633993, 21.3996802],
                [105.7635117, 21.3993843],
                [105.7639711, 21.3984794],
                [105.7641706, 21.3981093],
                [105.7642396, 21.3979037],
                [105.7642471, 21.3976575],
                [105.764193, 21.3973541],
                [105.7641828, 21.3970916],
                [105.7641534, 21.396468],
                [105.7641351, 21.3963285],
                [105.7642219, 21.396164],
                [105.76429, 21.3957861],
                [105.7643935, 21.3954903],
                [105.7645143, 21.3951205],
                [105.764574, 21.3948328],
                [105.7649199, 21.3939778],
                [105.7650417, 21.3938049],
                [105.7651712, 21.3934678],
                [105.7653356, 21.3930978],
                [105.765413, 21.3928101],
                [105.7654377, 21.3925146],
                [105.7655886, 21.3912008],
                [105.7656311, 21.3909708],
                [105.7656828, 21.3907982],
                [105.7657167, 21.3905765],
                [105.7657247, 21.3904287],
                [105.7658021, 21.3901575],
                [105.7659574, 21.3897055],
                [105.7660353, 21.3895328],
                [105.7660522, 21.3894096],
                [105.7660424, 21.3892291],
                [105.7659628, 21.3890735],
                [105.7657338, 21.3888283],
                [105.7651094, 21.3883059],
                [105.7645562, 21.3880295],
                [105.7642751, 21.387842],
                [105.7634138, 21.3872386],
                [105.7632813, 21.3870259],
                [105.7632101, 21.3868127],
                [105.7629265, 21.386174],
                [105.7624413, 21.3854703],
                [105.7621936, 21.3849791],
                [105.7621403, 21.3848398],
                [105.7621305, 21.384651],
                [105.7621027, 21.3843475],
                [105.7621008, 21.38397],
                [105.7620458, 21.3835107],
                [105.762018, 21.3832154],
                [105.7619379, 21.3829613],
                [105.7618045, 21.3825517],
                [105.761405, 21.3815029],
                [105.7610249, 21.3808235],
                [105.7606554, 21.3804886],
                [105.7604262, 21.3801778],
                [105.7602849, 21.3799569],
                [105.7601349, 21.3797278],
                [105.7599666, 21.3793592],
                [105.7598601, 21.3790889],
                [105.7597349, 21.3785888],
                [105.7597254, 21.378433],
                [105.759926, 21.3782844],
                [105.760529, 21.3780519],
                [105.7614984, 21.377555],
                [105.7626251, 21.3769836],
                [105.7633416, 21.3766931],
                [105.7646707, 21.376326],
                [105.7658686, 21.375984],
                [105.7673103, 21.3753947],
                [105.768587, 21.375036],
                [105.7688401, 21.3748871],
                [105.7690495, 21.3747383],
                [105.7692058, 21.3744997],
                [105.7693443, 21.374179],
                [105.7694477, 21.3738584],
                [105.7696627, 21.3731025],
                [105.7700298, 21.3713281],
                [105.7702113, 21.3708431],
                [105.7703422, 21.3707767],
                [105.7711919, 21.3708385],
                [105.7726633, 21.3708974],
                [105.7729342, 21.3707977],
                [105.773074, 21.3707477],
                [105.773161, 21.3706325],
                [105.7731779, 21.3705258],
                [105.7731858, 21.3703615],
                [105.7731413, 21.3702223],
                [105.7730781, 21.3698696],
                [105.7730071, 21.369673],
                [105.7730153, 21.3695746],
                [105.7732334, 21.3694093],
                [105.7734427, 21.3692361],
                [105.7740701, 21.3686833],
                [105.7746285, 21.3683032],
                [105.7748375, 21.3680725],
                [105.7749415, 21.3678831],
                [105.7750369, 21.367694],
                [105.7750624, 21.3675297],
                [105.7750873, 21.3672916],
                [105.775087, 21.3672331],
                [105.7750863, 21.3670946],
                [105.775024, 21.3669062],
                [105.7746713, 21.3664319],
                [105.7745647, 21.366145],
                [105.7744227, 21.3657847],
                [105.7742994, 21.3656375],
                [105.7742812, 21.3655226],
                [105.7742979, 21.365342],
                [105.7744194, 21.3651445],
                [105.7745755, 21.3648811],
                [105.7747841, 21.3645683],
                [105.774957, 21.3641408],
                [105.7750251, 21.3637794],
                [105.7751455, 21.3633849],
                [105.7752833, 21.3629247],
                [105.7753833, 21.3619722],
                [105.7753987, 21.3615617],
                [105.7753965, 21.361135],
                [105.7754484, 21.3610363],
                [105.7755359, 21.3610031],
                [105.7757634, 21.3609856],
                [105.7761484, 21.3609345],
                [105.7764456, 21.3608511],
                [105.776603, 21.3608012],
                [105.7768477, 21.360718],
                [105.7771798, 21.3606015],
                [105.7774419, 21.3605018],
                [105.7776863, 21.3603529],
                [105.7780707, 21.3602036],
                [105.7781491, 21.3601738],
                [105.7782844, 21.3601207],
                [105.778404, 21.3600812],
                [105.7784918, 21.3600512],
                [105.7787876, 21.3599704],
                [105.7789975, 21.3599366],
                [105.779295, 21.3599023],
                [105.7796451, 21.3599007],
                [105.7801355, 21.359915],
                [105.7808363, 21.3599773],
                [105.781362, 21.3600405],
                [105.7827809, 21.3601323],
                [105.7832362, 21.3601467],
                [105.783516, 21.3600797],
                [105.7840579, 21.3598802],
                [105.7845083, 21.3596738],
                [105.7845645, 21.359648],
                [105.7846913, 21.3595622],
                [105.7847533, 21.359663],
                [105.784819, 21.3597698],
                [105.7850503, 21.3600593],
                [105.7854741, 21.3607465],
                [105.7860035, 21.3618676],
                [105.7864075, 21.3628619],
                [105.7865804, 21.3635127],
                [105.7867811, 21.3648682],
                [105.7868762, 21.3658622],
                [105.786972, 21.3664405],
                [105.7870674, 21.3671635],
                [105.7870565, 21.3681212],
                [105.7869971, 21.3690788],
                [105.7870162, 21.3692415],
                [105.7874018, 21.3696575],
                [105.7875752, 21.369947],
                [105.7876136, 21.3700916],
                [105.7875746, 21.3703987],
                [105.7873422, 21.370814],
                [105.7873032, 21.3710669],
                [105.7873802, 21.3712296],
                [105.7874959, 21.3713383],
                [105.7876888, 21.371447],
                [105.7879977, 21.3715557],
                [105.7881714, 21.3716463],
                [105.7883063, 21.371755],
                [105.7884606, 21.3719719],
                [105.7887081, 21.3724153],
                [105.7888937, 21.3726857],
                [105.7892897, 21.3729723],
                [105.7894927, 21.3731428],
                [105.7898297, 21.3732043],
                [105.7900086, 21.3732421],
                [105.7901334, 21.3732611],
                [105.7891664, 21.373785],
                [105.7882928, 21.3743382],
                [105.7878202, 21.3748407],
                [105.7878576, 21.3749312],
                [105.7879969, 21.3752074],
                [105.7882348, 21.3755017],
                [105.7883589, 21.3756763],
                [105.7884874, 21.375768],
                [105.7885859, 21.375786],
                [105.7886944, 21.3758132],
                [105.788793, 21.3758497],
                [105.7888818, 21.3758862],
                [105.7889512, 21.3759688],
                [105.7890108, 21.3760608],
                [105.7890702, 21.3761159],
                [105.7891593, 21.3762078],
                [105.7892481, 21.3762444],
                [105.7893171, 21.3762533],
                [105.7894155, 21.3762528],
                [105.7895633, 21.3762705],
                [105.7896225, 21.376298],
                [105.7897016, 21.376353],
                [105.7898203, 21.3764446],
                [105.7898999, 21.376592],
                [105.78997, 21.3768131],
                [105.790089, 21.3769694],
                [105.7901878, 21.3770429],
                [105.7902868, 21.3771346],
                [105.7903366, 21.3772543],
                [105.7903472, 21.3773835],
                [105.7903581, 21.3775865],
                [105.7903735, 21.3776971],
                [105.7904134, 21.3778169],
                [105.7904433, 21.3778721],
                [105.7904738, 21.3779061],
                [105.7904782, 21.3778936],
                [105.7905191, 21.3778742],
                [105.7906212, 21.3778738],
                [105.7907031, 21.3779021],
                [105.7908363, 21.3779589],
                [105.7910001, 21.3780252],
                [105.7912358, 21.3781388],
                [105.7914717, 21.3782909],
                [105.7916151, 21.3783573],
                [105.791748, 21.3783662],
                [105.791881, 21.3783943],
                [105.7919733, 21.3784512],
                [105.7921375, 21.3785941],
                [105.7923327, 21.3787559],
                [105.7924459, 21.3789086],
                [105.7924771, 21.3790137],
                [105.7925286, 21.3790805],
                [105.7926209, 21.3791471],
                [105.7927439, 21.3792039],
                [105.7928565, 21.3792321],
                [105.7930305, 21.3792887],
                [105.7931943, 21.3793263],
                [105.7935417, 21.3793245],
                [105.793795, 21.3793101],
                [105.7941631, 21.3792286],
                [105.7943301, 21.3792186],
                [105.7944578, 21.3792365],
                [105.7945366, 21.3792636],
                [105.7946155, 21.3793277],
                [105.7947243, 21.3794377],
                [105.7948733, 21.3797407],
                [105.795243, 21.380871],
                [105.7953621, 21.3811005],
                [105.7954312, 21.3811554],
                [105.7955198, 21.3811734],
                [105.795618, 21.3811729],
                [105.7957259, 21.3811449],
                [105.7957847, 21.3811169],
                [105.795942, 21.3811162],
                [105.7960304, 21.381125],
                [105.7961484, 21.3811244],
                [105.7962759, 21.3810686],
                [105.796413, 21.3810035],
                [105.796521, 21.3809846],
                [105.7965898, 21.3809842],
                [105.7966391, 21.3810116],
                [105.7967477, 21.3811215],
                [105.7967977, 21.3812686],
                [105.7967788, 21.3813976],
                [105.7967599, 21.381554],
                [105.7967603, 21.3816369],
                [105.7967705, 21.3817013],
                [105.7968297, 21.381747],
                [105.796928, 21.3817558],
                [105.7970068, 21.381783],
                [105.7970561, 21.3818288],
                [105.7970761, 21.3818747],
                [105.7970863, 21.3819483],
                [105.7970182, 21.3820591],
                [105.7969009, 21.3821792],
                [105.7968031, 21.3822718],
                [105.796774, 21.3823456],
                [105.7967746, 21.3824468],
                [105.796824, 21.3825018],
                [105.7969026, 21.3825014],
                [105.7971284, 21.3824635],
                [105.7973147, 21.382389],
                [105.7974517, 21.3822872],
                [105.7976182, 21.3821943],
                [105.7976968, 21.3821663],
                [105.7977852, 21.3821659],
                [105.7979422, 21.3821283],
                [105.7981186, 21.3820446],
                [105.7982655, 21.3819427],
                [105.798422, 21.3818223],
                [105.7985294, 21.3817021],
                [105.7986075, 21.3816189],
                [105.7986957, 21.381554],
                [105.7987349, 21.3815355],
                [105.7987841, 21.3815444],
                [105.7989125, 21.3816819],
                [105.7991894, 21.3819843],
                [105.799592, 21.3824434],
                [105.7995012, 21.3826171],
                [105.7994635, 21.3827705],
                [105.7994456, 21.383014],
                [105.7996109, 21.3833379],
                [105.7999121, 21.3838865],
                [105.8004553, 21.3846864],
                [105.8007845, 21.3850815],
                [105.8010162, 21.3852246],
                [105.8012378, 21.3852777],
                [105.8013433, 21.3852141],
                [105.8015444, 21.3850418],
                [105.8017072, 21.3848967],
                [105.8018604, 21.3847517],
                [105.8020233, 21.3846608],
                [105.8022155, 21.3845968],
                [105.8024847, 21.3845594],
                [105.8028021, 21.3845489],
                [105.80311, 21.3845475],
                [105.8033989, 21.3846001],
                [105.8036584, 21.3845448],
                [105.8038024, 21.38449],
                [105.8038789, 21.3844085],
                [105.8039072, 21.3843182],
                [105.8037514, 21.3839673],
                [105.8037027, 21.3838413],
                [105.8037211, 21.3837059],
                [105.8038359, 21.3835881],
                [105.8040179, 21.3834341],
                [105.8041425, 21.3833342],
                [105.8042765, 21.3832164],
                [105.8045734, 21.3829895],
                [105.804765, 21.3828174],
                [105.8049376, 21.3827082],
                [105.805072, 21.3826626],
                [105.8052067, 21.3826619],
                [105.8053608, 21.3826792],
                [105.8054766, 21.3827508],
                [105.8056502, 21.382822],
                [105.805862, 21.3828481],
                [105.8061218, 21.3828558],
                [105.8069203, 21.3828521],
                [105.80719, 21.3829049],
                [105.8074901, 21.382929],
                [105.8081125, 21.382711],
                [105.8086784, 21.3825377],
                [105.8091595, 21.3823904],
                [105.8099755, 21.3820709],
                [105.8103306, 21.3819158],
                [105.8108979, 21.3818591],
                [105.8115134, 21.381793],
                [105.81184, 21.3817103],
                [105.8121758, 21.3815373],
                [105.812626, 21.3811835],
                [105.8127988, 21.3811014],
                [105.8129333, 21.3810828],
                [105.8130778, 21.3811181],
                [105.8132321, 21.3811625],
                [105.813415, 21.3811976],
                [105.813521, 21.3812332],
                [105.8135503, 21.3812872],
                [105.8135506, 21.3813593],
                [105.8135225, 21.3814856],
                [105.8135139, 21.3816931],
                [105.8135164, 21.3821349],
                [105.813576, 21.3824683],
                [105.8136737, 21.3827383],
                [105.8137994, 21.3828549],
                [105.8142527, 21.3830601],
                [105.8145225, 21.3831489],
                [105.8148503, 21.3832554],
                [105.8151098, 21.3832182],
                [105.81535, 21.3831538],
                [105.8156865, 21.3831163],
                [105.815946, 21.3830519],
                [105.8161283, 21.3829699],
                [105.8163871, 21.3828063],
                [105.8166365, 21.3826697],
                [105.8167222, 21.38248],
                [105.8167111, 21.3822365],
                [105.816797, 21.3821009],
                [105.8169597, 21.3819469],
                [105.8171226, 21.3818468],
                [105.8174396, 21.3817552],
                [105.8176025, 21.3816372],
                [105.8177365, 21.3815013],
                [105.8178512, 21.3813655],
                [105.8180048, 21.3812925],
                [105.8182163, 21.3812735],
                [105.8183217, 21.3812008],
                [105.8184736, 21.3808485],
                [105.8186744, 21.380604],
                [105.8190388, 21.3803858],
                [105.8195084, 21.3800679],
                [105.8198829, 21.3799309],
                [105.8202569, 21.3797217],
                [105.8205833, 21.3795939],
                [105.8208715, 21.3795022],
                [105.821188, 21.3793385],
                [105.8213024, 21.3791575],
                [105.8213104, 21.3788509],
                [105.8213561, 21.3784359],
                [105.8214697, 21.3780927],
                [105.8215463, 21.3780292],
                [105.8216327, 21.3779928],
                [105.821787, 21.3780551],
                [105.8220084, 21.3780721],
                [105.8220949, 21.3780716],
                [105.8221624, 21.3780893],
                [105.8222779, 21.3781159],
                [105.8224516, 21.3781961],
                [105.822587, 21.3783217],
                [105.8227219, 21.3783662],
                [105.8229148, 21.3784554],
                [105.8230686, 21.3784365],
                [105.8233662, 21.3783269],
                [105.8236342, 21.3780821],
                [105.8238159, 21.3778738],
                [105.8239884, 21.3777468],
                [105.8242759, 21.377547],
                [105.8244771, 21.3774017],
                [105.8245922, 21.3773291],
                [105.8248226, 21.3772378],
                [105.8250432, 21.3771374],
                [105.8252545, 21.3770643],
                [105.8253887, 21.3769825],
                [105.8255039, 21.3769278],
                [105.8256962, 21.3769088],
                [105.8260034, 21.3767991],
                [105.8262242, 21.3767169],
                [105.8264454, 21.3766978],
                [105.826705, 21.3766604],
                [105.826926, 21.3766323],
                [105.8271758, 21.3765589],
                [105.8273774, 21.3764858],
                [105.8276266, 21.3763222],
                [105.8277609, 21.3762495],
                [105.827953, 21.3761943],
                [105.8281645, 21.3761663],
                [105.8286362, 21.3762181],
                [105.828848, 21.3762531],
                [105.828973, 21.3762254],
                [105.8290783, 21.3761257],
                [105.8293359, 21.3757457],
                [105.8295848, 21.3755371],
                [105.8296899, 21.3754104],
                [105.829911, 21.3753733],
                [105.8300851, 21.3755346],
                [105.8301531, 21.3756606],
                [105.8302596, 21.3757683],
                [105.8303365, 21.3757768],
                [105.8304228, 21.3757133],
                [105.8304896, 21.3756229],
                [105.830614, 21.375514],
                [105.8307002, 21.3754144],
                [105.8307283, 21.3752971],
                [105.8306886, 21.3750809],
                [105.8305333, 21.3748382],
                [105.8305424, 21.3747389],
                [105.8306765, 21.3746481],
                [105.8308977, 21.3746109],
                [105.8310416, 21.3745562],
                [105.8311471, 21.3744925],
                [105.8312621, 21.3744108],
                [105.8313387, 21.3743653],
                [105.8314253, 21.3743559],
                [105.8315023, 21.3743555],
                [105.8315793, 21.3743731],
                [105.8316371, 21.3743728],
                [105.8317332, 21.3743544],
                [105.8317586, 21.374306],
                [105.8317319, 21.3740721],
                [105.8316806, 21.373863],
                [105.8316722, 21.3736863],
                [105.8316896, 21.3734773],
                [105.8318188, 21.3732362],
                [105.8320422, 21.3731562],
                [105.8324544, 21.3731244],
                [105.8331758, 21.3732134],
                [105.8338369, 21.3734231],
                [105.8346265, 21.3737359],
                [105.8347986, 21.373735],
                [105.8351067, 21.3737785],
                [105.8353859, 21.3738223],
                [105.8357328, 21.3739197],
                [105.836322, 21.3743406],
                [105.8366133, 21.3748171],
                [105.8368772, 21.3755461],
                [105.8369175, 21.3758614],
                [105.8371126, 21.3763294],
                [105.8375475, 21.3766789],
                [105.8381168, 21.3769826],
                [105.8387344, 21.377304],
                [105.839187, 21.377392],
                [105.8394085, 21.3774269],
                [105.839572, 21.377426],
                [105.8399849, 21.3772707],
                [105.8407347, 21.3771678],
                [105.8412157, 21.3771744],
                [105.8427638, 21.3770133],
                [105.8431964, 21.3769661],
                [105.8435716, 21.3769552],
                [105.8437639, 21.3769362],
                [105.8439177, 21.3769264],
                [105.8440623, 21.3769708],
                [105.8442069, 21.377024],
                [105.8444094, 21.3771042],
                [105.8445925, 21.3771574],
                [105.8448623, 21.3772282],
                [105.8453537, 21.377361],
                [105.8461622, 21.37742],
                [105.846422, 21.3774278],
                [105.8466048, 21.3774449],
                [105.8467782, 21.3774709],
                [105.8471059, 21.3775776],
                [105.8473348, 21.3772338],
                [105.8478876, 21.3763294],
                [105.848258, 21.3754888],
                [105.8485722, 21.3749283],
                [105.8488686, 21.3746022],
                [105.8490507, 21.374475],
                [105.849185, 21.3744203],
                [105.8493484, 21.3744014],
                [105.8494638, 21.3743918],
                [105.8496372, 21.3744179],
                [105.8500229, 21.3745783],
                [105.850948, 21.3748441],
                [105.8521233, 21.3751266],
                [105.8530104, 21.3754739],
                [105.8546306, 21.3761508],
                [105.8555854, 21.3765517],
                [105.8559509, 21.3765499],
                [105.8564891, 21.376457],
                [105.8571313, 21.3760479],
                [105.8582908, 21.3752486],
                [105.8586627, 21.3746876],
                [105.8587383, 21.3744618],
                [105.8587374, 21.3742815],
                [105.8587062, 21.3738849],
                [105.8585975, 21.3734076],
                [105.8583826, 21.3728497],
                [105.8578596, 21.3722482],
                [105.8576171, 21.3719068],
                [105.8575099, 21.3716819],
                [105.8575088, 21.3714746],
                [105.8575146, 21.3708163],
                [105.8575697, 21.3703562],
                [105.8577598, 21.3699495],
                [105.8556666, 21.3665061],
                [105.8546694, 21.3658063],
                [105.8541521, 21.3649547],
                [105.8538981, 21.3642708],
                [105.8535771, 21.3636772],
                [105.8532866, 21.363336],
                [105.8529462, 21.3626976],
                [105.8527796, 21.3621575],
                [105.8527581, 21.3617879],
                [105.8527563, 21.3614633],
                [105.8527446, 21.3611027],
                [105.8527908, 21.3607778],
                [105.8529509, 21.360191],
                [105.8531484, 21.3593875],
                [105.8531564, 21.3591349],
                [105.8531169, 21.3589548],
                [105.8530296, 21.358811],
                [105.8528845, 21.3586765],
                [105.8526333, 21.3585065],
                [105.8521313, 21.3581934],
                [105.8517245, 21.3576905],
                [105.8516559, 21.3574835],
                [105.8516543, 21.3572039],
                [105.8515733, 21.3565011],
                [105.8515701, 21.3559331],
                [105.8515872, 21.3555723],
                [105.8517492, 21.35531],
                [105.8519598, 21.3551286],
                [105.8522284, 21.355019],
                [105.8526891, 21.3548364],
                [105.8530536, 21.3546541],
                [105.853322, 21.3544815],
                [105.8536569, 21.3541913],
                [105.8539438, 21.3539012],
                [105.8541637, 21.3536568],
                [105.8544025, 21.3533849],
                [105.8546212, 21.352933],
                [105.854715, 21.3525269],
                [105.8546458, 21.3521845],
                [105.8544329, 21.3519693],
                [105.8541145, 21.3518086],
                [105.8535258, 21.351487],
                [105.8530822, 21.3512727],
                [105.8527923, 21.3510489],
                [105.852579, 21.3507524],
                [105.8525191, 21.350383],
                [105.8524579, 21.3497702],
                [105.8524548, 21.3492472],
                [105.8524829, 21.3491028],
                [105.8525205, 21.3489583],
                [105.8526156, 21.3487775],
                [105.8527969, 21.3485061],
                [105.853017, 21.3483156],
                [105.8532471, 21.3481883],
                [105.8539474, 21.34786],
                [105.8545143, 21.347749],
                [105.8553692, 21.3475553],
                [105.8560993, 21.3473893],
                [105.8565499, 21.3471435],
                [105.8573076, 21.34677],
                [105.8581419, 21.3463419],
                [105.8588509, 21.3458605],
                [105.8590995, 21.3455977],
                [105.8592231, 21.3453536],
                [105.8592604, 21.3451461],
                [105.8592307, 21.345002],
                [105.8591721, 21.3448669],
                [105.8590275, 21.3448045],
                [105.8589217, 21.3448052],
                [105.858768, 21.344833],
                [105.8585947, 21.3448158],
                [105.8583351, 21.3448262],
                [105.8580659, 21.3448545],
                [105.8576053, 21.3450193],
                [105.8571552, 21.3453552],
                [105.8569159, 21.3455638],
                [105.8567529, 21.3456548],
                [105.8565991, 21.3456736],
                [105.8565027, 21.345647],
                [105.8563967, 21.3455935],
                [105.8563192, 21.3455037],
                [105.85628, 21.3453866],
                [105.8561632, 21.3451438],
                [105.8558811, 21.3446043],
                [105.8557726, 21.3441269],
                [105.8557414, 21.3437303],
                [105.8557481, 21.3432254],
                [105.8557557, 21.3428917],
                [105.8558029, 21.3427382],
                [105.8558782, 21.3424493],
                [105.856107, 21.3420875],
                [105.8564705, 21.3417339],
                [105.8569007, 21.341308],
                [105.8571682, 21.3409821],
                [105.8573396, 21.3406837],
                [105.8574437, 21.3404035],
                [105.857509, 21.3400245],
                [105.8576027, 21.3396092],
                [105.8576739, 21.3394302],
                [105.8577069, 21.3393473],
                [105.8578307, 21.3391302],
                [105.8580593, 21.3387323],
                [105.8584802, 21.3383335],
                [105.8589499, 21.3380695],
                [105.8593147, 21.3379505],
                [105.8596122, 21.3378498],
                [105.8597755, 21.3378039],
                [105.8600547, 21.3378385],
                [105.8606706, 21.3379165],
                [105.8611909, 21.3380671],
                [105.8615568, 21.3381463],
                [105.8618554, 21.3382169],
                [105.862356, 21.3382956],
                [105.8627016, 21.3381767],
                [105.8629025, 21.3380043],
                [105.8630169, 21.3378414],
                [105.8631504, 21.3376333],
                [105.8631783, 21.3374528],
                [105.8631767, 21.3371914],
                [105.8632133, 21.3368665],
                [105.8631444, 21.3365964],
                [105.8631719, 21.3363709],
                [105.863286, 21.3361448],
                [105.8635058, 21.3359093],
                [105.8644816, 21.3350116],
                [105.8650843, 21.3344585],
                [105.8655047, 21.3339874],
                [105.8657237, 21.3336076],
                [105.8659334, 21.3332819],
                [105.8659535, 21.3332227],
                [105.8660061, 21.3320553],
                [105.8660191, 21.3309665],
                [105.8661685, 21.3300402],
                [105.8664155, 21.3292223],
                [105.8668367, 21.3283854],
                [105.8670669, 21.327985],
                [105.8675296, 21.3276378],
                [105.8678964, 21.3274363],
                [105.8683213, 21.3272526],
                [105.8685342, 21.3272152],
                [105.868664, 21.3272449],
                [105.8689221, 21.3273039],
                [105.8692523, 21.3274655],
                [105.8695443, 21.3277362],
                [105.8698378, 21.3282247],
                [105.8702491, 21.3290029],
                [105.8705225, 21.3293825],
                [105.8707563, 21.3296172],
                [105.8709701, 21.3297613],
                [105.8711253, 21.3297968],
                [105.8713963, 21.3297772],
                [105.8721112, 21.329465],
                [105.8727475, 21.3289624],
                [105.8739196, 21.3284278],
                [105.8760444, 21.327771],
                [105.8843473, 21.3273734],
                [105.8885759, 21.3264833],
                [105.8877631, 21.3280619],
                [105.8860886, 21.3316784],
                [105.8850456, 21.3332211],
                [105.8846223, 21.3334805],
                [105.884248, 21.3338188],
                [105.8836733, 21.3342363],
                [105.883277, 21.3344834],
                [105.8819386, 21.3353179],
                [105.8815178, 21.3357257],
                [105.8809828, 21.3363524],
                [105.880669, 21.3369493],
                [105.8805752, 21.3373646],
                [105.8805789, 21.3379778],
                [105.8806776, 21.3383921],
                [105.8809011, 21.3387697],
                [105.881327, 21.3392185],
                [105.8840928, 21.3416568],
                [105.8846163, 21.3423124],
                [105.8849953, 21.3429598],
                [105.8857645, 21.3444978],
                [105.8865519, 21.3458644],
                [105.8869296, 21.3462773],
                [105.8871807, 21.3464382],
                [105.8878644, 21.3465428],
                [105.888143, 21.3464964],
                [105.8884886, 21.3463682],
                [105.8892173, 21.3459676],
                [105.8908075, 21.3448409],
                [105.8921397, 21.3440132],
                [105.8929071, 21.3436394],
                [105.8939916, 21.3432278],
                [105.8949616, 21.3429611],
                [105.8954904, 21.3429041],
                [105.896895, 21.3429327],
                [105.8977713, 21.3430633],
                [105.9009323, 21.3440023],
                [105.9028312, 21.3446143],
                [105.9036512, 21.3449796],
                [105.904908, 21.3459919],
                [105.9061368, 21.3471307],
                [105.9083754, 21.349806],
                [105.9086278, 21.3501833],
                [105.9088918, 21.3508402],
                [105.9089521, 21.3512728],
                [105.9088876, 21.3517421],
                [105.9086687, 21.352113],
                [105.9083528, 21.3523762],
                [105.9079494, 21.3524867],
                [105.9062958, 21.352667],
                [105.9052853, 21.3526092],
                [105.9038787, 21.3522921],
                [105.9031745, 21.3519894],
                [105.90103, 21.350558],
                [105.9001515, 21.3500487],
                [105.8996978, 21.3497986],
                [105.8994227, 21.3497199],
                [105.89911, 21.3496304],
                [105.8984556, 21.3496159],
                [105.8976002, 21.3497557],
                [105.8968997, 21.350039],
                [105.8960175, 21.3505037],
                [105.8956188, 21.3508109],
                [105.8955578, 21.3508579],
                [105.8953287, 21.3511476],
                [105.8952353, 21.3516081],
                [105.8952275, 21.3519148],
                [105.8953655, 21.352464],
                [105.8956577, 21.3530487],
                [105.8960065, 21.3534617],
                [105.8964902, 21.353901],
                [105.8969541, 21.3542412],
                [105.8990661, 21.3550867],
                [105.8999643, 21.3556319],
                [105.9004672, 21.3560621],
                [105.9008259, 21.3565111],
                [105.9014386, 21.3576081],
                [105.9023939, 21.359641],
                [105.9038041, 21.3621224],
                [105.9051248, 21.3640992],
                [105.9052228, 21.3643873],
                [105.9052091, 21.3652982],
                [105.9052692, 21.3656857],
                [105.9067757, 21.3697268],
                [105.9078592, 21.3722369],
                [105.9090556, 21.3743227],
                [105.9094051, 21.3748258],
                [105.91076, 21.37609],
                [105.9114456, 21.3764921],
                [105.9119849, 21.3765523],
                [105.9124653, 21.3764504],
                [105.9130795, 21.3761945],
                [105.9136641, 21.3758127],
                [105.9144576, 21.3749696],
                [105.9158799, 21.3731582],
                [105.9162721, 21.3727865],
                [105.9166554, 21.3725228],
                [105.9170204, 21.3724307],
                [105.9173862, 21.3724466],
                [105.9179071, 21.3726602],
                [105.9184672, 21.3729819],
                [105.9201112, 21.3743255],
                [105.9205361, 21.3745576],
                [105.9221159, 21.3748555],
                [105.9227703, 21.37487],
                [105.9231743, 21.3748317],
                [105.9237888, 21.3746299],
                [105.9251311, 21.373883],
                [105.9259184, 21.3736082],
                [105.9265533, 21.3735686],
                [105.9275932, 21.373689],
                [105.930208, 21.3733319],
                [105.9313613, 21.3731091],
                [105.9322639, 21.3728155],
                [105.9336866, 21.3726092],
                [105.9341851, 21.3723179],
                [105.934568, 21.3720091],
                [105.9351054, 21.3717897],
                [105.9367577, 21.3713656],
                [105.9373537, 21.3712902],
                [105.9379888, 21.3712866],
                [105.9384896, 21.371374],
                [105.9390104, 21.3715695],
                [105.9396872, 21.3720707],
                [105.9405586, 21.3729135],
                [105.9413745, 21.3741354],
                [105.9418034, 21.3749807],
                [105.9419975, 21.3752501],
                [105.9425583, 21.3756799],
                [105.9432358, 21.3763073],
                [105.9435855, 21.3768104],
                [105.9439964, 21.3778722],
                [105.9440183, 21.3782869],
                [105.9440215, 21.378792],
                [105.9437463, 21.3808856],
                [105.9438541, 21.3827067],
                [105.9442506, 21.3845081],
                [105.9445647, 21.3854802],
                [105.945118, 21.3877136],
                [105.9452773, 21.3885604],
                [105.9453974, 21.3892812],
                [105.9454808, 21.3902907],
                [105.9450642, 21.3912155],
                [105.9448231, 21.392072],
                [105.9440595, 21.3929575],
                [105.9419626, 21.394689],
                [105.9407755, 21.3957116],
                [105.9404964, 21.3963608],
                [105.9403255, 21.3967225],
                [105.9401936, 21.397732],
                [105.9403613, 21.3981936],
                [105.9406688, 21.3989468],
                [105.9413993, 21.4005675],
                [105.9422016, 21.4011669],
                [105.9428559, 21.4011452],
                [105.9438134, 21.4003823],
                [105.944312, 21.4000908],
                [105.9445425, 21.4000175],
                [105.9447542, 21.4000163],
                [105.9449856, 21.4000871],
                [105.94543, 21.4003551],
                [105.9466114, 21.4015028],
                [105.9478112, 21.402506],
                [105.9484107, 21.4029355],
                [105.9499358, 21.4036483],
                [105.9506689, 21.4038966],
                [105.9513835, 21.4042894],
                [105.9516355, 21.4045585],
                [105.9518109, 21.4049002],
                [105.9519881, 21.4054944],
                [105.9524939, 21.4063212],
                [105.9528038, 21.406644],
                [105.9531514, 21.4068044],
                [105.9535173, 21.4068384],
                [105.9540357, 21.4066371],
                [105.9547447, 21.406146],
                [105.9554721, 21.4055286],
                [105.9559663, 21.4045699],
                [105.9560782, 21.4040102],
                [105.9560173, 21.4035235],
                [105.9558418, 21.4031818],
                [105.9556866, 21.4029843],
                [105.9553382, 21.4026977],
                [105.9547012, 21.4024128],
                [105.9543918, 21.4021801],
                [105.9542743, 21.4018742],
                [105.9542924, 21.4016937],
                [105.9543679, 21.4014588],
                [105.9545777, 21.401169],
                [105.9549225, 21.4009146],
                [105.9553834, 21.4007496],
                [105.9560752, 21.4005834],
                [105.9568449, 21.4005608],
                [105.9575583, 21.4007372],
                [105.957983, 21.4009512],
                [105.9585052, 21.401327],
                [105.9588351, 21.4017399],
                [105.9590704, 21.4024059],
                [105.9591558, 21.403704],
                [105.9591021, 21.4043175],
                [105.9588367, 21.4049503],
                [105.9583796, 21.4056924],
                [105.9577873, 21.4063632],
                [105.9569263, 21.4071616],
                [105.9562205, 21.4081397],
                [105.9555213, 21.4101456],
                [105.955294, 21.410706],
                [105.9549707, 21.4113211],
                [105.9547628, 21.4118994],
                [105.9546882, 21.4122786],
                [105.9546941, 21.4131804],
                [105.9547342, 21.4134327],
                [105.9549291, 21.4138103],
                [105.9557439, 21.4147796],
                [105.9559383, 21.415067],
                [105.9561547, 21.4157872],
                [105.9565902, 21.4176424],
                [105.9569221, 21.418344],
                [105.9574666, 21.4192066],
                [105.9585621, 21.4218877],
                [105.9589992, 21.4239773],
                [105.9590422, 21.4246625],
                [105.9588765, 21.4258178],
                [105.9585726, 21.4264508],
                [105.9578193, 21.4290161],
                [105.9575956, 21.4301357],
                [105.9575797, 21.4306588],
                [105.9577241, 21.432137],
                [105.9580373, 21.4329468],
                [105.9584639, 21.4333953],
                [105.9590632, 21.4337886],
                [105.9600485, 21.434324],
                [105.9607063, 21.4348072],
                [105.9609398, 21.4352026],
                [105.9610549, 21.435623],
                [105.9610049, 21.436285],
                [105.9610634, 21.4363749],
                [105.9620662, 21.4366035],
                [105.9626074, 21.4368889],
                [105.9631278, 21.436958],
                [105.9638006, 21.4367738],
                [105.9639732, 21.4366467],
                [105.9643191, 21.4365364],
                [105.9649711, 21.4361179],
                [105.9652767, 21.4357196],
                [105.9662452, 21.4336403],
                [105.9665483, 21.4328633],
                [105.9675224, 21.4316495],
                [105.9677693, 21.4311251],
                [105.9677877, 21.4309988],
                [105.9673785, 21.430292],
                [105.9672709, 21.4300221],
                [105.9672097, 21.4295264],
                [105.9674772, 21.4291913],
                [105.9677647, 21.4289643],
                [105.9679939, 21.4286743],
                [105.968134, 21.4280153],
                [105.9681303, 21.4274562],
                [105.9683497, 21.4271303],
                [105.9690795, 21.4268121],
                [105.969185, 21.4267483],
                [105.9693282, 21.426567],
                [105.9695087, 21.426187],
                [105.9696762, 21.4252928],
                [105.9703922, 21.4243503],
                [105.9705138, 21.4237992],
                [105.9705893, 21.4235732],
                [105.9707038, 21.4234011],
                [105.9710581, 21.4231103],
                [105.9717411, 21.4229891],
                [105.9719332, 21.4229158],
                [105.9721056, 21.4227705],
                [105.972191, 21.4225805],
                [105.9722738, 21.4220024],
                [105.9723508, 21.4205405],
                [105.9723298, 21.4202699],
                [105.9724155, 21.420116],
                [105.9728751, 21.4197254],
                [105.9731535, 21.4195974],
                [105.9735769, 21.4195409],
                [105.9743437, 21.4195279],
                [105.9762374, 21.4178704],
                [105.9770709, 21.4166462],
                [105.9776069, 21.4157559],
                [105.9781428, 21.4144202],
                [105.9785, 21.4138638],
                [105.9791547, 21.4135857],
                [105.9796309, 21.4136414],
                [105.9803452, 21.414031],
                [105.9810595, 21.4138085],
                [105.9819524, 21.4133634],
                [105.9828453, 21.4130295],
                [105.9838573, 21.4124175],
                [105.9847673, 21.410779],
                [105.9868106, 21.4140726],
                [105.9872791, 21.413641],
                [105.9875095, 21.4135313],
                [105.9890006, 21.413324],
                [105.9892498, 21.4131422],
                [105.9894976, 21.4127618],
                [105.98967, 21.4126163],
                [105.9899586, 21.4125786],
                [105.9906157, 21.4129176],
                [105.9908467, 21.4129162],
                [105.9910002, 21.4128251],
                [105.9912297, 21.4125891],
                [105.9913053, 21.4123721],
                [105.9912844, 21.4121376],
                [105.9909299, 21.4109668],
                [105.9907538, 21.4105528],
                [105.990732, 21.4101739],
                [105.9908652, 21.4099387],
                [105.991133, 21.4096663],
                [105.9926309, 21.4090259],
                [105.9930528, 21.4087707],
                [105.9932631, 21.4085529],
                [105.9935115, 21.4082447],
                [105.9936059, 21.4079734],
                [105.9935023, 21.4068913],
                [105.993557, 21.4064399],
                [105.9938601, 21.4056802],
                [105.9943186, 21.4051362],
                [105.9948942, 21.4048258],
                [105.9955664, 21.4045692],
                [105.9986787, 21.4050724],
                [106.0015953, 21.4059628],
                [106.0040356, 21.4064636],
                [106.0057617, 21.4065749],
                [106.0065355, 21.4063523],
                [106.0074282, 21.4056844],
                [106.009016, 21.4066448],
                [106.0104284, 21.404272],
                [106.0119316, 21.4038522],
                [106.0123087, 21.4024008],
                [106.013188, 21.4027404],
                [106.0133157, 21.4027897],
                [106.0153568, 21.4033635],
                [106.01575, 21.4033611],
                [106.0164823, 21.4033063],
                [106.0169461, 21.4032031],
                [106.0172849, 21.4030838],
                [106.0176586, 21.4028638],
                [106.017942, 21.4025105],
                [106.0186937, 21.4013838],
                [106.0197007, 21.3997197],
                [106.019788, 21.3994178],
                [106.0198397, 21.3991328],
                [106.0198238, 21.3981448],
                [106.0197409, 21.3964874],
                [106.019827, 21.3960347],
                [106.0200687, 21.3955399],
                [106.02078, 21.3951034],
                [106.0217324, 21.3947584],
                [106.0223568, 21.3946004],
                [106.0228541, 21.3944924],
                [106.0235421, 21.3945048],
                [106.0255625, 21.3946767],
                [106.0259913, 21.3946741],
                [106.0265084, 21.394537],
                [106.0277564, 21.3941274],
                [106.0282918, 21.3940404],
                [106.0287385, 21.3940376],
                [106.0289165, 21.3941031],
                [106.0291311, 21.3942466],
                [106.0290577, 21.3944688],
                [106.0283474, 21.3955975],
                [106.0274925, 21.3969193],
                [106.0274766, 21.3974667],
                [106.0274334, 21.3986207],
                [106.0272794, 21.399169],
                [106.0276648, 21.3998396],
                [106.0278269, 21.4000228],
                [106.0293378, 21.4014538],
                [106.0295898, 21.4017035],
                [106.0296799, 21.4018201],
                [106.0296917, 21.4022219],
                [106.0296601, 21.4028083],
                [106.0297698, 21.4031593],
                [106.0298614, 21.4034769],
                [106.0300145, 21.4049329],
                [106.0301235, 21.4051835],
                [106.0302862, 21.4054504],
                [106.0306096, 21.4056997],
                [106.0307715, 21.4058494],
                [106.0308617, 21.4059829],
                [106.0311277, 21.4069525],
                [106.0319091, 21.4075338],
                [106.03268, 21.4078807],
                [106.0340291, 21.4081754],
                [106.0338567, 21.4100456],
                [106.033512, 21.4137847],
                [106.0334743, 21.4141056],
                [106.033422, 21.4142901],
                [106.0333345, 21.4145586],
                [106.03314, 21.4148445],
                [106.0326792, 21.4154],
                [106.032267, 21.4157202],
                [106.031715, 21.4163665],
                [106.0314771, 21.4171457],
                [106.030942, 21.4201509],
                [106.0304071, 21.4240464],
                [106.0302287, 21.4250482],
                [106.0297528, 21.4263283],
                [106.0291076, 21.4271979],
                [106.0287417, 21.4273312],
                [106.0285105, 21.4274832],
                [106.0275259, 21.4285276],
                [106.0260563, 21.4305126],
                [106.0253764, 21.4316891],
                [106.0258828, 21.4325401],
                [106.0260452, 21.4327735],
                [106.0262789, 21.4329563],
                [106.026602, 21.4331553],
                [106.0269428, 21.4333374],
                [106.0283377, 21.4347188],
                [106.0299766, 21.436484],
                [106.0318639, 21.4379796],
                [106.0338976, 21.4387038],
                [106.0354929, 21.4393303],
                [106.0357796, 21.4394291],
                [106.035994, 21.4394277],
                [106.0362887, 21.4393923],
                [106.0377644, 21.438278],
                [106.0381204, 21.4380748],
                [106.0384068, 21.43814],
                [106.0385683, 21.4382228],
                [106.0388203, 21.4384724],
                [106.0391441, 21.4387717],
                [106.0402338, 21.4406282],
                [106.0410078, 21.4407949],
                [106.04327, 21.4404606],
                [106.0443806, 21.4404899],
                [106.0453537, 21.4405157],
                [106.047259, 21.441016],
                [106.0479141, 21.441461],
                [106.0481524, 21.4419619],
                [106.0482696, 21.4436843],
                [106.0484515, 21.4463581],
                [106.048571, 21.4476936],
                [106.0491125, 21.4498276],
                [106.0491436, 21.4502166],
                [106.0491358, 21.4504467],
                [106.0491186, 21.4513403],
                [106.0491397, 21.4516498],
                [106.0491138, 21.4519862],
                [106.0488221, 21.4527843],
                [106.0486738, 21.4531744],
                [106.0486001, 21.4534226],
                [106.048555, 21.4537237],
                [106.0485755, 21.4539535],
                [106.0486143, 21.4541037],
                [106.0488468, 21.4542703],
                [106.0498116, 21.4544854],
                [106.0499822, 21.454564],
                [106.0500491, 21.4546696],
                [106.0500793, 21.4549438],
                [106.0500813, 21.4552091],
                [106.0501743, 21.4556759],
                [106.0504907, 21.4563458],
                [106.0506054, 21.4565395],
                [106.0507019, 21.4568307],
                [106.0508855, 21.4576032],
                [106.0511293, 21.4595402],
                [106.0514684, 21.4603951],
                [106.0506372, 21.461051],
                [106.0499193, 21.4611498],
                [106.0482235, 21.4611335],
                [106.0480516, 21.4612153],
                [106.0479098, 21.461472],
                [106.0478289, 21.4622129],
                [106.0476731, 21.4625235],
                [106.0475019, 21.4626996],
                [106.0470512, 21.4629851],
                [106.0464058, 21.4631642],
                [106.0450567, 21.4634015],
                [106.0446695, 21.4635249],
                [106.0440536, 21.4638116],
                [106.0436476, 21.4643122],
                [106.0423908, 21.4664201],
                [106.0406164, 21.4705236],
                [106.0403919, 21.4712923],
                [106.0404299, 21.4715747],
                [106.0409012, 21.4731873],
                [106.0410178, 21.4734154],
                [106.0412784, 21.473683],
                [106.0417397, 21.4738821],
                [106.0419697, 21.4738941],
                [106.0427363, 21.4735662],
                [106.0430948, 21.4734562],
                [106.0434395, 21.4734272],
                [106.0437417, 21.4734926],
                [106.0440449, 21.4736927],
                [106.0455282, 21.4751104],
                [106.0474803, 21.4767674],
                [106.0485745, 21.4780395],
                [106.0490709, 21.4791268],
                [106.0491306, 21.4794494],
                [106.0490907, 21.4798939],
                [106.0484143, 21.4807709],
                [106.0482867, 21.4810137],
                [106.0481463, 21.481472],
                [106.0470335, 21.4826225],
                [106.0455051, 21.4839637],
                [106.0440539, 21.4849626],
                [106.042061, 21.485893],
                [106.0409526, 21.4865904],
                [106.0400082, 21.4867624],
                [106.0398891, 21.4869294],
                [106.0397704, 21.4881537],
                [106.0392346, 21.4888773],
                [106.0386392, 21.4892113],
                [106.0378055, 21.4898237],
                [106.0375674, 21.4902689],
                [106.038409, 21.4924891],
                [106.0395003, 21.4936815],
                [106.0396749, 21.4937841],
                [106.0398034, 21.4940646],
                [106.0398211, 21.494331],
                [106.0403737, 21.4953613],
                [106.0422226, 21.4974801],
                [106.0424302, 21.4977894],
                [106.0428977, 21.499013],
                [106.0432918, 21.5010306],
                [106.0433658, 21.5022016],
                [106.0438432, 21.5040041],
                [106.0442136, 21.5047705],
                [106.0446631, 21.5051561],
                [106.0449647, 21.5056152],
                [106.0450753, 21.5063092],
                [106.0454793, 21.5079862],
                [106.0455462, 21.508492],
                [106.0454211, 21.5089247],
                [106.045001, 21.5100067],
                [106.0445102, 21.510983],
                [106.0445523, 21.5118454],
                [106.0449837, 21.513182],
                [106.0449501, 21.5135377],
                [106.0447181, 21.5138741],
                [106.0440934, 21.5141228],
                [106.0433888, 21.5142474],
                [106.0420065, 21.514349],
                [106.0416341, 21.5148848],
                [106.0412524, 21.5156723],
                [106.0411221, 21.5161659],
                [106.0413145, 21.516474],
                [106.0419241, 21.5170065],
                [106.0429809, 21.5175869],
                [106.0440903, 21.5184489],
                [106.0442323, 21.5188946],
                [106.0442352, 21.5193389],
                [106.0439472, 21.5200813],
                [106.0438591, 21.5206896],
                [106.0438897, 21.5213008],
                [106.0443503, 21.5223294],
                [106.0448593, 21.5232053],
                [106.0454143, 21.524019],
                [106.045656, 21.5248667],
                [106.045834, 21.5264991],
                [106.0461033, 21.5272062],
                [106.0470945, 21.529123],
                [106.0473608, 21.5298],
                [106.0474942, 21.5305213],
                [106.0478322, 21.530906],
                [106.0483644, 21.5318281],
                [106.0488253, 21.5328875],
                [106.0490356, 21.5332092],
                [106.0491875, 21.5333496],
                [106.049547, 21.5333537],
                [106.0503365, 21.5331959],
                [106.0506663, 21.5332524],
                [106.0509993, 21.5334931],
                [106.0512002, 21.5337368],
                [106.0516344, 21.5344734],
                [106.0519719, 21.5354263],
                [106.0527379, 21.536871],
                [106.0538091, 21.5386264],
                [106.0538741, 21.5389781],
                [106.0537703, 21.5392082],
                [106.0533601, 21.5394481],
                [106.0526053, 21.5396712],
                [106.0522755, 21.5399773],
                [106.0521729, 21.5403017],
                [106.0522414, 21.5409615],
                [106.0525448, 21.542193],
                [106.0527469, 21.542749],
                [106.052671, 21.5432488],
                [106.0518825, 21.5441218],
                [106.0516926, 21.544553],
                [106.0513275, 21.5451106],
                [106.0514189, 21.5454967],
                [106.0517388, 21.5459255],
                [106.0522869, 21.5461398],
                [106.0526753, 21.5464185],
                [106.0529038, 21.5467616],
                [106.0531325, 21.5477051],
                [106.0540008, 21.5490774],
                [106.0545788, 21.5502632],
                [106.0546786, 21.550562],
                [106.0547188, 21.550779],
                [106.0545497, 21.5511062],
                [106.0542073, 21.5516723],
                [106.0538877, 21.5520586],
                [106.0531113, 21.5522303],
                [106.0517411, 21.5524024],
                [106.051193, 21.552617],
                [106.0502342, 21.5536896],
                [106.0499147, 21.5542045],
                [106.0498238, 21.5553625],
                [106.0498696, 21.5561346],
                [106.04971, 21.5566709],
                [106.049436, 21.5568854],
                [106.0489336, 21.5570572],
                [106.0487053, 21.5572717],
                [106.0485684, 21.557915],
                [106.0483402, 21.5583012],
                [106.0480205, 21.5586444],
                [106.0477466, 21.5588161],
                [106.0472442, 21.5588592],
                [106.0466502, 21.5582588],
                [106.046239, 21.5581731],
                [106.0460563, 21.5582161],
                [106.0457822, 21.5584306],
                [106.0454627, 21.5587738],
                [106.0451888, 21.5594173],
                [106.0445496, 21.5601466],
                [106.0442298, 21.5602325],
                [106.0436817, 21.5602327],
                [106.0432705, 21.5599325],
                [106.041946, 21.5604047],
                [106.0412608, 21.5605335],
                [106.0408497, 21.5605336],
                [106.0403472, 21.5601905],
                [106.0397761, 21.5600835],
                [106.039365, 21.5600836],
                [106.0387714, 21.5607271],
                [106.038132, 21.5609417],
                [106.0368531, 21.5612424],
                [106.0364876, 21.5611995],
                [106.0358023, 21.5606849],
                [106.0356652, 21.5603847],
                [106.0355738, 21.5599987],
                [106.0353453, 21.5596985],
                [106.0351626, 21.5597843],
                [106.0347973, 21.560342],
                [106.0346146, 21.5603849],
                [106.0342947, 21.5601705],
                [106.0338837, 21.5600848],
                [106.0333356, 21.5602137],
                [106.0309151, 21.5621014],
                [106.0309838, 21.5628949],
                [106.0312122, 21.563238],
                [106.0315777, 21.5634953],
                [106.0328111, 21.563881],
                [106.0332679, 21.5639668],
                [106.033542, 21.5642241],
                [106.0336792, 21.5645243],
                [106.0337706, 21.5649104],
                [106.0336793, 21.5652106],
                [106.0330399, 21.5657255],
                [106.0326746, 21.5661115],
                [106.0324919, 21.5664119],
                [106.032355, 21.5668837],
                [106.0325835, 21.5673555],
                [106.0328805, 21.5675913],
                [106.0332001, 21.5668192],
                [106.033337, 21.5668621],
                [106.0336569, 21.5672051],
                [106.0339311, 21.5676341],
                [106.0341139, 21.56802],
                [106.0340684, 21.5682774],
                [106.0336573, 21.5685348],
                [106.0334289, 21.5688351],
                [106.033292, 21.5692642],
                [106.0334749, 21.5701219],
                [106.033475, 21.5705079],
                [106.033338, 21.5709369],
                [106.033201, 21.5711086],
                [106.0331591, 21.5713597],
                [106.0332903, 21.5714931],
                [106.0336505, 21.5716176],
                [106.0344258, 21.571649],
                [106.0349177, 21.5717732],
                [106.035235, 21.5720663],
                [106.0355472, 21.5726324],
                [106.0359419, 21.5731423],
                [106.036386, 21.5733172],
                [106.0368788, 21.5733176],
                [106.0375052, 21.5734654],
                [106.0381507, 21.5738994],
                [106.0389107, 21.5743251],
                [106.0396371, 21.5746583],
                [106.0405927, 21.5749925],
                [106.0415826, 21.5755417],
                [106.0439117, 21.5773804],
                [106.0447198, 21.5781886],
                [106.0461018, 21.5799194],
                [106.0462977, 21.5800435],
                [106.0465922, 21.5800467],
                [106.0472496, 21.5799213],
                [106.0477766, 21.5800068],
                [106.0484059, 21.5803994],
                [106.0490808, 21.5812396],
                [106.0498164, 21.5831123],
                [106.0502542, 21.5848331],
                [106.0503996, 21.5859768],
                [106.0506615, 21.5865123],
                [106.0508694, 21.5868162],
                [106.0512272, 21.5872847],
                [106.0520405, 21.5880701],
                [106.0522421, 21.5884901],
                [106.0523362, 21.5889219],
                [106.0522823, 21.5892642],
                [106.052074, 21.5899469],
                [106.0516152, 21.5908194],
                [106.0515865, 21.5915106],
                [106.0516475, 21.5920083],
                [106.0519149, 21.5925887],
                [106.0531451, 21.5938652],
                [106.0537607, 21.5946769],
                [106.0548449, 21.5965348],
                [106.0558605, 21.599081],
                [106.0560767, 21.5998999],
                [106.0561439, 21.6007849],
                [106.056621, 21.6022039],
                [106.0572276, 21.602823],
                [106.0576551, 21.6031339],
                [106.0582355, 21.6034161],
                [106.0588883, 21.6036601],
                [106.060085, 21.6039796],
                [106.0615652, 21.6041927],
                [106.0638945, 21.6043986],
                [106.0646053, 21.6045044],
                [106.0666358, 21.6044949],
                [106.0672686, 21.6046251],
                [106.0675976, 21.6049237],
                [106.0687736, 21.607682],
                [106.0692905, 21.6083586],
                [106.07045, 21.6097216],
                [106.0711647, 21.6102128],
                [106.0717574, 21.6104036],
                [106.0728546, 21.610868],
                [106.0737242, 21.6113382],
                [106.0747235, 21.6118785],
                [106.0757911, 21.6125658],
                [106.0763563, 21.6132828],
                [106.0767458, 21.6141108],
                [106.0771015, 21.6150879],
                [106.0775012, 21.6167246],
                [106.077823, 21.6173705],
                [106.0781785, 21.617757],
                [106.0785501, 21.6180428],
                [106.079661, 21.6185807],
                [106.0805097, 21.6188858],
                [106.0840115, 21.6196844],
                [106.0849609, 21.6200096],
                [106.085721, 21.6203992],
                [106.0868481, 21.6208909],
                [106.0879307, 21.6215109],
                [106.0894909, 21.6221629],
                [106.0901094, 21.6225306],
                [106.0907094, 21.6226594],
                [106.0925034, 21.6225679],
                [106.0927116, 21.6232193],
                [106.0931389, 21.6237711],
                [106.0935408, 21.6241669],
                [106.0941351, 21.6244092],
                [106.0957039, 21.6245088],
                [106.0974786, 21.6244712],
                [106.0979224, 21.624553],
                [106.0984683, 21.6247638],
                [106.0991484, 21.6248174],
                [106.0996389, 21.6247269],
                [106.1000647, 21.6244573],
                [106.1004481, 21.6238762],
                [106.1006524, 21.6231009],
                [106.101352, 21.621933],
                [106.1017023, 21.6216244],
                [106.1025158, 21.6213365],
                [106.1032364, 21.6213586],
                [106.1050743, 21.6218148],
                [106.106139, 21.6218102],
                [106.1076053, 21.6216097],
                [106.1082117, 21.6213492],
                [106.109726, 21.6198574],
                [106.1105368, 21.6192898],
                [106.1123981, 21.6188924],
                [106.1132666, 21.6189408],
                [106.1141139, 21.6189061],
                [106.1149098, 21.6187312],
                [106.1153653, 21.6184069],
                [106.1156131, 21.6180054],
                [106.1162757, 21.6172674],
                [106.1165124, 21.6168724],
                [106.1168969, 21.6165976],
                [106.1173803, 21.6165412],
                [106.1185948, 21.6167518],
                [106.1201914, 21.6166324],
                [106.1245389, 21.616437],
                [106.1251488, 21.6163735],
                [106.1263995, 21.6158742],
                [106.1271279, 21.6158668],
                [106.1289342, 21.6161329],
                [106.1311688, 21.6167762],
                [106.1329847, 21.6171403],
                [106.1352464, 21.6178426],
                [106.1359318, 21.6181772],
                [106.1363558, 21.6185701],
                [106.1366842, 21.619417],
                [106.1370058, 21.6197116],
                [106.1380413, 21.6203753],
                [106.1387876, 21.620721],
                [106.139794, 21.6214374],
                [106.1417912, 21.622666],
                [106.1423511, 21.6227956],
                [106.1429048, 21.622752],
                [106.1456464, 21.6221961],
                [106.1464661, 21.6223192],
                [106.1475587, 21.6226124],
                [106.1482748, 21.6231075],
                [106.149296, 21.6235773],
                [106.1504185, 21.6239886],
                [106.1513932, 21.62407],
                [106.1525564, 21.62402],
                [106.1536667, 21.6238842],
                [106.1544842, 21.6238832],
                [106.1553068, 21.6243454],
                [106.1586706, 21.6260704],
                [106.1595179, 21.6262727],
                [106.1601275, 21.6262253],
                [106.1608069, 21.6260052],
                [106.1615307, 21.625489],
                [106.1624189, 21.6247727],
                [106.1666133, 21.6196796],
                [106.167832, 21.6185211],
                [106.1689158, 21.617407],
                [106.169728, 21.6164653],
                [106.1713135, 21.6142256],
                [106.172549, 21.6116191],
                [106.1732332, 21.6104086],
                [106.1739423, 21.6097357],
                [106.1768417, 21.6083941],
                [106.1777635, 21.6078672],
                [106.178578, 21.6071818],
                [106.18069, 21.6058582],
                [106.1817939, 21.6052458],
                [106.1834084, 21.6045189],
                [106.184857, 21.6040447],
                [106.1850403, 21.6042639],
                [106.1851661, 21.6044912],
                [106.1853585, 21.6053638],
                [106.1855635, 21.6058528],
                [106.1867143, 21.6079373],
                [106.1870579, 21.6086659],
                [106.1878446, 21.6107366],
                [106.188081, 21.6111472],
                [106.1883257, 21.6113919],
                [106.1886246, 21.611548],
                [106.1898645, 21.6117908],
                [106.1904485, 21.6120001],
                [106.1914806, 21.6126186],
                [106.1921235, 21.6131589],
                [106.1926464, 21.6137132],
                [106.1932079, 21.6144156],
                [106.1940624, 21.6158157],
                [106.1944237, 21.6163239],
                [106.1948326, 21.6167486],
                [106.1959779, 21.6177811],
                [106.1967698, 21.6186719],
                [106.1973105, 21.6194628],
                [106.1975122, 21.6200933],
                [106.1975312, 21.6207914],
                [106.1974329, 21.6211908],
                [106.1973202, 21.6214444],
                [106.1960035, 21.6234714],
                [106.1955762, 21.6242426],
                [106.1952447, 21.6249907],
                [106.1950761, 21.6259139],
                [106.1950492, 21.6270201],
                [106.1951611, 21.6276276],
                [106.1955681, 21.6286785],
                [106.1960331, 21.6294465],
                [106.1975023, 21.631451],
                [106.1978937, 21.6320616],
                [106.1980555, 21.6324523],
                [106.1980889, 21.6327215],
                [106.1980613, 21.6329562],
                [106.1979033, 21.6333276],
                [106.1975106, 21.6337155],
                [106.1969246, 21.6340521],
                [106.1945975, 21.6352088],
                [106.1936088, 21.6358028],
                [106.1929024, 21.6364042],
                [106.192381, 21.6370974],
                [106.1921721, 21.6375726],
                [106.1920448, 21.638116],
                [106.1912018, 21.6437981],
                [106.1911551, 21.6443084],
                [106.1911718, 21.6449608],
                [106.1914803, 21.6459226],
                [106.191954, 21.6467096],
                [106.1926329, 21.6475133],
                [106.1935109, 21.6483511],
                [106.1942326, 21.6489146],
                [106.1985208, 21.6515984],
                [106.1994094, 21.6520549],
                [106.2010517, 21.6528083],
                [106.202128, 21.6534144],
                [106.2027898, 21.6538984],
                [106.2035712, 21.6547183],
                [106.2040247, 21.6553415],
                [106.2052146, 21.6574288],
                [106.2061894, 21.6590162],
                [106.2064018, 21.6594102],
                [106.2066293, 21.6599921],
                [106.2067293, 21.6606571],
                [106.2066233, 21.6613126],
                [106.2063934, 21.6618152],
                [106.2058869, 21.6626416],
                [106.2057366, 21.6630531],
                [106.2057778, 21.6633271],
                [106.2059234, 21.6635294],
                [106.2066517, 21.6640252],
                [106.2082142, 21.6648068],
                [106.2088591, 21.6651942],
                [106.2092656, 21.665566],
                [106.2100939, 21.6665712],
                [106.2106505, 21.6671479],
                [106.2114377, 21.6677767],
                [106.2132759, 21.669046],
                [106.214541, 21.6697817],
                [106.2160466, 21.6705562],
                [106.2184371, 21.6715771],
                [106.2185896, 21.6716655],
                [106.2187268, 21.6718387],
                [106.2187649, 21.6719494],
                [106.2188992, 21.6730591],
                [106.2190548, 21.6734717],
                [106.2191825, 21.6736638],
                [106.2195669, 21.6740304],
                [106.2211756, 21.6752752],
                [106.2224753, 21.6763617],
                [106.2234409, 21.6772913],
                [106.2248654, 21.6787786],
                [106.2254098, 21.6794435],
                [106.2257499, 21.6800191],
                [106.2260783, 21.6807822],
                [106.2263504, 21.6816392],
                [106.2265172, 21.6824945],
                [106.2265482, 21.6829055],
                [106.2265067, 21.6836687],
                [106.226355, 21.6846167],
                [106.2261354, 21.685252],
                [106.2248879, 21.6875301],
                [106.2243282, 21.6890282],
                [106.2241087, 21.6900033],
                [106.2240643, 21.6904427],
                [106.224111, 21.6911802],
                [106.2242747, 21.691739],
                [106.2243826, 21.6919578],
                [106.2246491, 21.692295],
                [106.2248096, 21.692422],
                [106.225202, 21.6926196],
                [106.2257119, 21.6927777],
                [106.2283915, 21.6934104],
                [106.2290759, 21.6937059],
                [106.2293621, 21.6939745],
                [106.2295773, 21.69433],
                [106.2297413, 21.6950035],
                [106.2297598, 21.6952577],
                [106.2298327, 21.6963155],
                [106.2298963, 21.696538],
                [106.2300962, 21.6968985],
                [106.230344, 21.6971841],
                [106.2306606, 21.6974437],
                [106.2316485, 21.6980343],
                [106.2320982, 21.6983546],
                [106.2324155, 21.6986527],
                [106.2325805, 21.6989194],
                [106.2326082, 21.6990578],
                [106.232564, 21.6993868],
                [106.2321861, 21.7004741],
                [106.2320225, 21.7010954],
                [106.2320024, 21.701618],
                [106.2320533, 21.7018502],
                [106.2322637, 21.7022711],
                [106.233169, 21.703373],
                [106.2334049, 21.7039273],
                [106.2335006, 21.7046536],
                [106.2335155, 21.7076264],
                [106.2335766, 21.7081177],
                [106.2336545, 21.7083237],
                [106.2339037, 21.708695],
                [106.234963, 21.7097231],
                [106.2352761, 21.7100988],
                [106.2358194, 21.7110303],
                [106.2365957, 21.7127716],
                [106.2373813, 21.7147327],
                [106.2375828, 21.715341],
                [106.2378366, 21.7164276],
                [106.2379808, 21.7177014],
                [106.2379808, 21.7183807],
                [106.2378894, 21.7190484],
                [106.2377255, 21.7197627],
                [106.236863, 21.7226865],
                [106.2359355, 21.7254755],
                [106.2354593, 21.7267453],
                [106.2352697, 21.7270882],
                [106.2350462, 21.7273401],
                [106.2347907, 21.7274902],
                [106.234616, 21.7275254],
                [106.2332374, 21.7274743],
                [106.2324649, 21.7275356],
                [106.2321355, 21.7276201],
                [106.2315679, 21.7278617],
                [106.2310974, 21.7281757],
                [106.2307065, 21.7285662],
                [106.2303747, 21.7290367],
                [106.2296846, 21.7302896],
                [106.2293126, 21.7306544],
                [106.2276245, 21.7316607],
                [106.2259276, 21.7328277],
                [106.2254532, 21.7330821],
                [106.2251321, 21.7331511],
                [106.2247185, 21.7330572],
                [106.2243774, 21.7328283],
                [106.220482, 21.7289192],
                [106.2191648, 21.7277542],
                [106.2185989, 21.7273067],
                [106.2181836, 21.727047],
                [106.217738, 21.7269434],
                [106.2168326, 21.7269827],
                [106.2151775, 21.7272083],
                [106.2138575, 21.7274765],
                [106.2130829, 21.7277161],
                [106.2123996, 21.7279978],
                [106.2115703, 21.7284424],
                [106.2111507, 21.7287447],
                [106.2105767, 21.7294021],
                [106.2089181, 21.7319928],
                [106.2085533, 21.7323874],
                [106.2079524, 21.732795],
                [106.2075273, 21.732975],
                [106.2071014, 21.7330811],
                [106.2043352, 21.7331591],
                [106.2035346, 21.7332707],
                [106.2026552, 21.7334541],
                [106.2010169, 21.7339452],
                [106.198049, 21.7350096],
                [106.1973497, 21.735295],
                [106.1967688, 21.735613],
                [106.1963373, 21.7359811],
                [106.1953853, 21.7370076],
                [106.1948191, 21.7376756],
                [106.1945771, 21.7380605],
                [106.1945519, 21.7383014],
                [106.1946549, 21.7394402],
                [106.1946222, 21.7409225],
                [106.1945681, 21.7413284],
                [106.1944893, 21.741475],
                [106.1943739, 21.7415547],
                [106.1941986, 21.7415842],
                [106.1938488, 21.7415409],
                [106.1899078, 21.7406805],
                [106.1891585, 21.7405635],
                [106.1880663, 21.7405073],
                [106.1866622, 21.7406218],
                [106.1850706, 21.7408773],
                [106.1836782, 21.7411981],
                [106.1828918, 21.7414819],
                [106.1825358, 21.7416989],
                [106.1822808, 21.7419675],
                [106.1821139, 21.7422893],
                [106.1818731, 21.7430945],
                [106.1817019, 21.7433519],
                [106.1814965, 21.7434061],
                [106.1797944, 21.74351],
                [106.1788687, 21.7436286],
                [106.1782316, 21.743825],
                [106.1779054, 21.7440245],
                [106.1775435, 21.7444482],
                [106.1769877, 21.7455652],
                [106.1765546, 21.7461432],
                [106.1736825, 21.7490126],
                [106.1732139, 21.7493456],
                [106.1727491, 21.7495367],
                [106.1721329, 21.7496559],
                [106.1693686, 21.7495981],
                [106.1688602, 21.7496592],
                [106.1685369, 21.7498078],
                [106.168376, 21.7500606],
                [106.1683205, 21.7504439],
                [106.1685494, 21.7525427],
                [106.1685383, 21.7535242],
                [106.1684573, 21.7538333],
                [106.1683207, 21.7540723],
                [106.16811, 21.7542908],
                [106.1678186, 21.754502],
                [106.1671631, 21.7547688],
                [106.1661275, 21.7549632],
                [106.1635003, 21.755248],
                [106.1609916, 21.7553414],
                [106.1604163, 21.7554414],
                [106.1599534, 21.7556116],
                [106.1596919, 21.75579],
                [106.1594069, 21.7560927],
                [106.1578554, 21.7582352],
                [106.1566052, 21.7597641],
                [106.1556014, 21.7608137],
                [106.1553618, 21.7610073],
                [106.1550964, 21.7611449],
                [106.1547452, 21.7612148],
                [106.1543525, 21.7612025],
                [106.1527334, 21.7607518],
                [106.1519355, 21.7606393],
                [106.1511586, 21.7606],
                [106.1505301, 21.760624],
                [106.1500024, 21.7607129],
                [106.1495316, 21.7608984],
                [106.1492012, 21.7611416],
                [106.1488647, 21.7616177],
                [106.1486676, 21.7622592],
                [106.1484917, 21.7646145],
                [106.1484803, 21.7663112],
                [106.1483546, 21.7667932],
                [106.1482396, 21.7670421],
                [106.1479193, 21.767483],
                [106.1477089, 21.7676677],
                [106.1463115, 21.7685522],
                [106.1455993, 21.7691082],
                [106.1453669, 21.7693923],
                [106.1452013, 21.7696944],
                [106.1447704, 21.7712397],
                [106.1446101, 21.7716622],
                [106.1444014, 21.772071],
                [106.1439077, 21.7728225],
                [106.1433891, 21.7734825],
                [106.1427084, 21.7742254],
                [106.1401119, 21.7767921],
                [106.1395596, 21.7775112],
                [106.1391399, 21.778359],
                [106.138396, 21.7806292],
                [106.1378293, 21.7821419],
                [106.1376437, 21.7828595],
                [106.1375956, 21.7839619],
                [106.1375339, 21.784405],
                [106.1374653, 21.7845637],
                [106.1372358, 21.7847957],
                [106.1366608, 21.7850625],
                [106.1359336, 21.7852298],
                [106.1350398, 21.7852953],
                [106.1336592, 21.7852312],
                [106.1318532, 21.784977],
                [106.1299517, 21.7844679],
                [106.1293558, 21.7842286],
                [106.1291121, 21.784082],
                [106.1284686, 21.783563],
                [106.1280379, 21.7832897],
                [106.1275725, 21.7831378],
                [106.1267953, 21.7831365],
                [106.1253167, 21.7833878],
                [106.1242528, 21.7834753],
                [106.1229949, 21.7834149],
                [106.1218203, 21.7832565],
                [106.1213294, 21.7830978],
                [106.1211496, 21.782979],
                [106.1197759, 21.7815531],
                [106.1195491, 21.7813857],
                [106.1193378, 21.7813242],
                [106.1189597, 21.7813997],
                [106.1187057, 21.7815375],
                [106.1179543, 21.7822971],
                [106.1176281, 21.7824261],
                [106.1171511, 21.7824694],
                [106.1159453, 21.7823638],
                [106.1151963, 21.7821701],
                [106.1131482, 21.7814817],
                [106.1127814, 21.7814132],
                [106.1122821, 21.7814211],
                [106.1118668, 21.7815939],
                [106.1117399, 21.7817633],
                [106.1116834, 21.7819728],
                [106.1116416, 21.7824392],
                [106.1117222, 21.7838614],
                [106.1116619, 21.7848411],
                [106.1114167, 21.7863322],
                [106.1113493, 21.7865478],
                [106.1111463, 21.7869213],
                [106.1108809, 21.7872361],
                [106.1105933, 21.7874675],
                [106.1101259, 21.7876518],
                [106.1089908, 21.7879661],
                [106.1086313, 21.7881094],
                [106.1080413, 21.7884415],
                [106.1072054, 21.7890733],
                [106.1068895, 21.7894354],
                [106.1066365, 21.7898493],
                [106.1064611, 21.7902855],
                [106.1063611, 21.7907185],
                [106.1063316, 21.7915936],
                [106.1064214, 21.7923447],
                [106.1068177, 21.7938328],
                [106.106869, 21.7944505],
                [106.1064426, 21.797617],
                [106.1064133, 21.7985474],
                [106.1064555, 21.7997225],
                [106.1065231, 21.800352],
                [106.1066882, 21.8011],
                [106.1069346, 21.801715],
                [106.1074284, 21.8026251],
                [106.1074993, 21.8029018],
                [106.1074733, 21.8031224],
                [106.1072673, 21.8033866],
                [106.1067311, 21.803779],
                [106.106412, 21.8040822],
                [106.1054583, 21.8057061],
                [106.1048378, 21.8065672],
                [106.1030298, 21.8087403],
                [106.1017717, 21.8101752],
                [106.1009941, 21.8109579],
                [106.1006012, 21.8112938],
                [106.0991046, 21.8123092],
                [106.0987017, 21.8127136],
                [106.0984931, 21.8131718],
                [106.0982239, 21.8142226],
                [106.0979948, 21.8147556],
                [106.0959868, 21.8182029],
                [106.0955733, 21.8190171],
                [106.0954651, 21.81932],
                [106.0953797, 21.8198489],
                [106.0954521, 21.8207273],
                [106.095577, 21.8211581],
                [106.0957376, 21.8214587],
                [106.096278, 21.8220871],
                [106.0967655, 21.8225225],
                [106.0972537, 21.8228656],
                [106.1000542, 21.8245253],
                [106.1006296, 21.8249704],
                [106.1009571, 21.8253456],
                [106.1010835, 21.8256195],
                [106.1011593, 21.8259344],
                [106.101164, 21.8264977],
                [106.1010082, 21.8269321],
                [106.1004493, 21.8279987],
                [106.1003245, 21.8285456],
                [106.1003726, 21.8289496],
                [106.1005887, 21.8296211],
                [106.1008752, 21.8301829],
                [106.1011614, 21.8305189],
                [106.1016822, 21.8307909],
                [106.1020921, 21.8308935],
                [106.1025766, 21.8309518],
                [106.1033392, 21.8309527],
                [106.1050532, 21.830761],
                [106.105565, 21.8307484],
                [106.106459, 21.8308745],
                [106.1068127, 21.8309876],
                [106.1072922, 21.831239],
                [106.107724, 21.8316886],
                [106.1079973, 21.8320932],
                [106.1081909, 21.8326254],
                [106.1083733, 21.833834],
                [106.1084913, 21.8352773],
                [106.1084258, 21.8358639],
                [106.1083331, 21.8360918],
                [106.1080178, 21.8365861],
                [106.1067353, 21.8380405],
                [106.1066243, 21.8382165],
                [106.1065248, 21.83856],
                [106.1065445, 21.8389702],
                [106.1067768, 21.8397578],
                [106.1070296, 21.8402886],
                [106.1073056, 21.8406556],
                [106.1074569, 21.84078],
                [106.1078124, 21.8409554],
                [106.1082611, 21.8410607],
                [106.1088229, 21.8410968],
                [106.109517, 21.8409859],
                [106.1110451, 21.840408],
                [106.1116104, 21.8402892],
                [106.1124522, 21.8402666],
                [106.1134515, 21.8403322],
                [106.1142345, 21.8404433],
                [106.115118, 21.8406354],
                [106.1162126, 21.8409766],
                [106.1166623, 21.841212],
                [106.1170026, 21.8414927],
                [106.1172841, 21.8418339],
                [106.1175304, 21.8422427],
                [106.1179542, 21.8432498],
                [106.1181179, 21.8438282],
                [106.1180801, 21.8442702],
                [106.1177619, 21.8448447],
                [106.1173693, 21.8453146],
                [106.1154671, 21.8470846],
                [106.1149838, 21.8474782],
                [106.1143698, 21.8478454],
                [106.1125619, 21.848756],
                [106.112099, 21.8491211],
                [106.1118806, 21.849496],
                [106.1117908, 21.850191],
                [106.1118981, 21.8514828],
                [106.1120878, 21.8522436],
                [106.11267, 21.8539362],
                [106.112739, 21.854366],
                [106.1127319, 21.8548357],
                [106.1126337, 21.8551137],
                [106.1120019, 21.8560775],
                [106.1118053, 21.8564859],
                [106.1116664, 21.8570165],
                [106.1116085, 21.8582056],
                [106.1115168, 21.8586738],
                [106.1113395, 21.8590474],
                [106.110871, 21.8597997],
                [106.1106982, 21.8602891],
                [106.1107051, 21.8608308],
                [106.1108424, 21.8617985],
                [106.1113603, 21.8641217],
                [106.1115088, 21.8645812],
                [106.1118081, 21.865133],
                [106.1120894, 21.8654956],
                [106.1132144, 21.8664642],
                [106.1135318, 21.8668477],
                [106.1136769, 21.8672249],
                [106.1137649, 21.8682506],
                [106.113897, 21.868456],
                [106.1146514, 21.8690588],
                [106.1151154, 21.8696209],
                [106.1152871, 21.8700446],
                [106.1154142, 21.8709129],
                [106.1155352, 21.8730692],
                [106.1154746, 21.8739829],
                [106.1153799, 21.8742438],
                [106.1152438, 21.8744216],
                [106.1143085, 21.8750336],
                [106.1141267, 21.8752878],
                [106.1140788, 21.8755613],
                [106.1142169, 21.8759484],
                [106.1153901, 21.8770182],
                [106.115639, 21.8775132],
                [106.1158018, 21.8781677],
                [106.1159901, 21.8792703],
                [106.1160321, 21.8800314],
                [106.1159972, 21.8805672],
                [106.1158632, 21.8812844],
                [106.1156786, 21.8816925],
                [106.1143216, 21.8834932],
                [106.11406, 21.8839882],
                [106.1139841, 21.8842303],
                [106.1138955, 21.8849678],
                [106.1138839, 21.8869877],
                [106.113293, 21.8897022],
                [106.113233, 21.8904433],
                [106.1132607, 21.8906],
                [106.1134066, 21.8908817],
                [106.1143211, 21.8919185],
                [106.1145421, 21.8922577],
                [106.1146527, 21.8925932],
                [106.1147246, 21.8934915],
                [106.1148171, 21.8938627],
                [106.1149885, 21.8941633],
                [106.1153964, 21.8944676],
                [106.1193567, 21.8966122],
                [106.119822, 21.8967145],
                [106.1209232, 21.8967654],
                [106.1215598, 21.8968613],
                [106.1222421, 21.8970764],
                [106.1225563, 21.8972242],
                [106.1230343, 21.897576],
                [106.1238569, 21.8984241],
                [106.1243741, 21.8987206],
                [106.1248844, 21.8988084],
                [106.1261899, 21.8988754],
                [106.1266786, 21.898954],
                [106.1271292, 21.8991315],
                [106.1273662, 21.8993517],
                [106.1277425, 21.9002121],
                [106.1280062, 21.9013677],
                [106.128162, 21.9017829],
                [106.1290618, 21.9025904],
                [106.1295631, 21.9032157],
                [106.1296984, 21.9035996],
                [106.1298221, 21.904711],
                [106.1297314, 21.9050541],
                [106.1294274, 21.9054588],
                [106.1286577, 21.9062773],
                [106.1279943, 21.9068922],
                [106.1278399, 21.9071175],
                [106.1277579, 21.9075063],
                [106.1278587, 21.9085011],
                [106.1278192, 21.908844],
                [106.1271671, 21.9101034],
                [106.1271292, 21.9105269],
                [106.1273348, 21.9110835],
                [106.1279742, 21.9120438],
                [106.1284824, 21.9132057],
                [106.1288275, 21.9137241],
                [106.1292459, 21.9141736],
                [106.1295392, 21.9144184],
                [106.1309075, 21.9152732],
                [106.1313171, 21.9156857],
                [106.1315791, 21.9161046],
                [106.1317139, 21.9165127],
                [106.1317467, 21.9168438],
                [106.1316328, 21.9185849],
                [106.1315795, 21.9204167],
                [106.1314645, 21.9212508],
                [106.1313127, 21.9216401],
                [106.1310527, 21.9220411],
                [106.1303651, 21.9228473],
                [106.1294046, 21.923827],
                [106.1288274, 21.9243323],
                [106.1282039, 21.9247119],
                [106.127668, 21.9248964],
                [106.1272855, 21.9249414],
                [106.1265567, 21.9248768],
                [106.1235578, 21.9244146],
                [106.1228233, 21.9244168],
                [106.1221264, 21.9245237],
                [106.1215687, 21.9247437],
                [106.1209737, 21.9251528],
                [106.1203602, 21.925759],
                [106.1187352, 21.9275526],
                [106.1181793, 21.9280553],
                [106.1176382, 21.9283961],
                [106.1169286, 21.9286877],
                [106.1161588, 21.9289011],
                [106.1154905, 21.9289791],
                [106.1141578, 21.9289994],
                [106.1136137, 21.9291201],
                [106.1134135, 21.9292442],
                [106.113094, 21.9296587],
                [106.1128338, 21.9302688],
                [106.1126626, 21.9308864],
                [106.1124695, 21.9319138],
                [106.1122986, 21.9323987],
                [106.1120119, 21.932867],
                [106.1116688, 21.9332722],
                [106.1113401, 21.9335714],
                [106.1110107, 21.9337832],
                [106.1106662, 21.9339081],
                [106.1103204, 21.933897],
                [106.1099388, 21.93372],
                [106.109156, 21.9332005],
                [106.1085124, 21.9329279],
                [106.1078353, 21.932855],
                [106.1072012, 21.9329595],
                [106.1067082, 21.9332076],
                [106.106547, 21.9333736],
                [106.1063689, 21.9337581],
                [106.1061741, 21.9345803],
                [106.1059877, 21.9350006],
                [106.105546, 21.9355956],
                [106.1049151, 21.936081],
                [106.1040703, 21.9364355],
                [106.103395, 21.9366102],
                [106.1025516, 21.9367242],
                [106.1005989, 21.9368479],
                [106.0995411, 21.937027],
                [106.0988878, 21.9372964],
                [106.0986133, 21.9375758],
                [106.097377, 21.9388442],
                [106.0960363, 21.9403805],
                [106.0953466, 21.9408809],
                [106.0943502, 21.9413456],
                [106.0932387, 21.9419176],
                [106.0925484, 21.9414181],
                [106.0918964, 21.9409185],
                [106.0908995, 21.9407048],
                [106.0897494, 21.9407054],
                [106.0887143, 21.9408132],
                [106.0872578, 21.9412424],
                [106.0859162, 21.9415288],
                [106.0845746, 21.9417795],
                [106.083233, 21.942173],
                [106.082198, 21.9423164],
                [106.0805112, 21.9423886],
                [106.0775975, 21.9422829],
                [106.0756807, 21.9423552],
                [106.0740705, 21.9421774],
                [106.0731503, 21.9419992],
                [106.0711186, 21.9423215],
                [106.0696578, 21.9425673],
                [106.0695565, 21.942592],
                [106.0687898, 21.9426744],
                [106.0680017, 21.9426602],
                [106.0670985, 21.9425308],
                [106.0663509, 21.9423809],
                [106.0654993, 21.9422124],
                [106.0643981, 21.9419295],
                [106.0633675, 21.9416802],
                [106.0633504, 21.9416765],
                [106.0627776, 21.9415534],
                [106.0617395, 21.9413861],
                [106.0609095, 21.9413334],
                [106.0603495, 21.9413177],
                [106.0597898, 21.94136],
                [106.0593449, 21.9414982],
                [106.059035, 21.9416355],
                [106.0587874, 21.9418112],
                [106.0578999, 21.9423969],
                [106.0571982, 21.9428654],
                [106.0568888, 21.9430077],
                [106.0565162, 21.9431791],
                [106.0559156, 21.9432989],
                [106.0556529, 21.9433135],
                [106.0548644, 21.943899],
                [106.0531485, 21.9455425],
                [106.0527734, 21.9460646],
                [106.0525885, 21.9463171],
                [106.0524249, 21.9466274],
                [106.0523026, 21.9469182],
                [106.0521421, 21.947654],
                [106.0519936, 21.9486023],
                [106.0519127, 21.9488928],
                [106.0519159, 21.9493181],
                [106.0518978, 21.9496856],
                [106.0519021, 21.9502656],
                [106.0518626, 21.9505365],
                [106.0517955, 21.9507613],
                [106.0517816, 21.9508077],
                [106.0516184, 21.951176],
                [106.0515165, 21.9514281],
                [106.0513123, 21.9518547],
                [106.0509539, 21.9524757],
                [106.0505644, 21.9530969],
                [106.0501127, 21.9537184],
                [106.0497426, 21.9541462],
                [106.049386, 21.9551849],
                [106.0488114, 21.9570419],
                [106.0483135, 21.9583634],
                [106.0470871, 21.9597921],
                [106.0465888, 21.9603635],
                [106.0459754, 21.9603281],
                [106.0445949, 21.9598642],
                [106.0439047, 21.959793],
                [106.0431763, 21.9600431],
                [106.0427547, 21.9604717],
                [106.0421415, 21.9614361],
                [106.0416048, 21.9619005],
                [106.040033, 21.962615],
                [106.0388445, 21.9630795],
                [106.0365441, 21.9634372],
                [106.0347804, 21.9639018],
                [106.0333619, 21.9643306],
                [106.0316366, 21.9654737],
                [106.0307932, 21.966188],
                [106.0303332, 21.9667595],
                [106.0297236, 21.9678599],
                [106.0246026, 21.9645968],
                [106.0239914, 21.9620023],
                [106.0239019, 21.9618267],
                [106.0235865, 21.9612483],
                [106.0231688, 21.9605255],
                [106.0228542, 21.9600487],
                [106.0226968, 21.9598175],
                [106.022555, 21.9595717],
                [106.0224598, 21.9593256],
                [106.0224115, 21.9590938],
                [106.0223945, 21.9589052],
                [106.0224085, 21.958673],
                [106.0224678, 21.9582664],
                [106.0225044, 21.9579325],
                [106.0225028, 21.9577149],
                [106.0224855, 21.9574829],
                [106.0224374, 21.95728],
                [106.0223583, 21.9571064],
                [106.0222015, 21.9569478],
                [106.0218885, 21.9566885],
                [106.0209811, 21.9560557],
                [106.0199966, 21.9555105],
                [106.019543, 21.9551796],
                [106.0193152, 21.954891],
                [106.019221, 21.9547899],
                [106.0187005, 21.9537152],
                [106.0185005, 21.9527314],
                [106.0183004, 21.9513753],
                [106.0182432, 21.950418],
                [106.0183289, 21.949966],
                [106.0184715, 21.9497],
                [106.018781, 21.9495189],
                [106.0188712, 21.9493948],
                [106.0192568, 21.9488845],
                [106.0193959, 21.9487385],
                [106.0195186, 21.9485057],
                [106.0195953, 21.9483456],
                [106.019609, 21.9480844],
                [106.0196082, 21.9479683],
                [106.019529, 21.9477656],
                [106.0193867, 21.9474619],
                [106.0190886, 21.9471299],
                [106.018022, 21.9463323],
                [106.0178689, 21.9458112],
                [106.017675, 21.9454121],
                [106.017613, 21.9453224],
                [106.0172679, 21.9444422],
                [106.0158973, 21.9426375],
                [106.0154262, 21.9420599],
                [106.0152068, 21.9418437],
                [106.0149823, 21.9414593],
                [106.0146736, 21.9407925],
                [106.0139028, 21.9398618],
                [106.0135602, 21.9394364],
                [106.0133033, 21.9389312],
                [106.0127607, 21.9379209],
                [106.0123325, 21.9374953],
                [106.0116759, 21.9372296],
                [106.0102201, 21.936884],
                [106.0090782, 21.9366713],
                [106.007451, 21.936432],
                [106.0057667, 21.9360066],
                [106.0042253, 21.935475],
                [106.0033689, 21.9349431],
                [106.0019415, 21.9337731],
                [106.0007713, 21.9327362],
                [106.0005713, 21.9328159],
                [105.9987445, 21.9345709],
                [105.9969746, 21.9359536],
                [105.9956044, 21.9367778],
                [105.9943197, 21.9372564],
                [105.9927497, 21.9374159],
                [105.988582, 21.9374688],
                [105.9875543, 21.9373625],
                [105.9866693, 21.9369635],
                [105.9856461, 21.936513],
                [105.9852991, 21.9364469],
                [105.9847564, 21.9362729],
                [105.9840228, 21.9359908],
                [105.9835391, 21.9358709],
                [105.9832827, 21.9358383],
                [105.9827414, 21.9358552],
                [105.9821417, 21.9358861],
                [105.9817761, 21.9359292],
                [105.9814694, 21.9359992],
                [105.9812212, 21.9360962],
                [105.9809443, 21.9362615],
                [105.9805222, 21.9365915],
                [105.9800564, 21.9369625],
                [105.9795184, 21.9374569],
                [105.9791111, 21.937814],
                [105.9786309, 21.9382125],
                [105.9781864, 21.938481],
                [105.9777639, 21.9387564],
                [105.9773123, 21.9390592],
                [105.9768166, 21.9393214],
                [105.9766416, 21.9394179],
                [105.9764374, 21.9395009],
                [105.9761889, 21.9395569],
                [105.9757354, 21.9395869],
                [105.9753698, 21.9396164],
                [105.9750771, 21.9396181],
                [105.9748429, 21.9395922],
                [105.974623, 21.9395526],
                [105.9745039, 21.9395223],
                [105.9738802, 21.9392222],
                [105.9723675, 21.9381849],
                [105.9713399, 21.9372008],
                [105.9705695, 21.9361105],
                [105.9703412, 21.9356584],
                [105.9702271, 21.9351532],
                [105.9698832, 21.9329839],
                [105.9696284, 21.9313771],
                [105.9694289, 21.9300475],
                [105.969429, 21.9294626],
                [105.9692293, 21.9288244],
                [105.9692294, 21.9283457],
                [105.9694579, 21.9277607],
                [105.969715, 21.9271758],
                [105.9706001, 21.9255805],
                [105.9709428, 21.9247563],
                [105.9708287, 21.9245701],
                [105.9703721, 21.9240116],
                [105.9694874, 21.9237455],
                [105.9687737, 21.9236124],
                [105.9675179, 21.9235856],
                [105.9670897, 21.9235324],
                [105.9663191, 21.9233726],
                [105.9653487, 21.9231331],
                [105.9646065, 21.9230001],
                [105.9641499, 21.923],
                [105.963265, 21.9231327],
                [105.9625514, 21.9230793],
                [105.9622089, 21.9229197],
                [105.9616382, 21.9225207],
                [105.9604111, 21.9214036],
                [105.9601829, 21.920925],
                [105.960183, 21.9207387],
                [105.96044, 21.9202336],
                [105.9607255, 21.9196488],
                [105.9608113, 21.9191169],
                [105.9606973, 21.918718],
                [105.9605263, 21.9178405],
                [105.9602696, 21.9168299],
                [105.9600987, 21.9157929],
                [105.9599849, 21.9147558],
                [105.9595856, 21.9136122],
                [105.9589008, 21.9128409],
                [105.9579592, 21.9118303],
                [105.9571603, 21.9106601],
                [105.9561903, 21.9095962],
                [105.9553629, 21.908878],
                [105.9540216, 21.908399],
                [105.9524805, 21.9079731],
                [105.9511394, 21.9073877],
                [105.9497698, 21.9063503],
                [105.9486001, 21.9050734],
                [105.9475161, 21.9036905],
                [105.9467271, 21.9027662],
                [105.9465377, 21.9025442],
                [105.9460117, 21.9023868],
                [105.9456725, 21.9023238],
                [105.9453867, 21.9022503],
                [105.9448735, 21.9020622],
                [105.9443014, 21.9018473],
                [105.9420221, 21.9011851],
                [105.9405269, 21.9007571],
                [105.9397762, 21.9004982],
                [105.9370439, 21.8994854],
                [105.9351894, 21.8983944],
                [105.9329069, 21.8970905],
                [105.9300251, 21.8958662],
                [105.9280847, 21.8955462],
                [105.9259445, 21.8953592],
                [105.9244034, 21.8953585],
                [105.9228907, 21.8954376],
                [105.9196944, 21.8958615],
                [105.9178964, 21.8960201],
                [105.9147857, 21.8958856],
                [105.9115896, 21.8957509],
                [105.9109905, 21.8955378],
                [105.9104484, 21.895192],
                [105.9095072, 21.8943937],
                [105.9079385, 21.8928772],
                [105.9070466, 21.8921341],
                [105.9055428, 21.8908814],
                [105.9034607, 21.8890455],
                [105.9019493, 21.8875556],
                [105.9011795, 21.8865978],
                [105.9005238, 21.8856135],
                [105.8999257, 21.8840709],
                [105.8987859, 21.8817301],
                [105.8981305, 21.8803471],
                [105.8963907, 21.8791493],
                [105.89525, 21.8783243],
                [105.892655, 21.8760358],
                [105.8916287, 21.8748385],
                [105.890774, 21.8731361],
                [105.8895768, 21.8715399],
                [105.8892919, 21.8710876],
                [105.8891739, 21.8703781],
                [105.8889789, 21.8697377],
                [105.8889954, 21.8694085],
                [105.8889932, 21.8690675],
                [105.8889831, 21.868597],
                [105.8889376, 21.8683515],
                [105.8888193, 21.8681476],
                [105.8885831, 21.8677805],
                [105.8883327, 21.8674954],
                [105.8880228, 21.8670743],
                [105.8875362, 21.8664356],
                [105.8872563, 21.8661097],
                [105.8868656, 21.8656139],
                [105.8864379, 21.8650296],
                [105.8859208, 21.8642004],
                [105.885522, 21.8635749],
                [105.8850792, 21.8629225],
                [105.884954, 21.8627664],
                [105.8849006, 21.8626593],
                [105.8825235, 21.8598718],
                [105.8821604, 21.8592004],
                [105.8819101, 21.8589424],
                [105.8816162, 21.8587122],
                [105.8807062, 21.858185],
                [105.878918, 21.857567],
                [105.878054, 21.8573805],
                [105.8771755, 21.8572488],
                [105.8767726, 21.8571215],
                [105.8754967, 21.8565552],
                [105.8745575, 21.8560419],
                [105.8737369, 21.855787],
                [105.8722993, 21.8562907],
                [105.8702019, 21.8577087],
                [105.8692685, 21.8581639],
                [105.8672066, 21.8594365],
                [105.8665944, 21.8597672],
                [105.8658064, 21.8600851],
                [105.8654851, 21.8601686],
                [105.8647244, 21.8602606],
                [105.8634622, 21.8606362],
                [105.8629798, 21.8606659],
                [105.8628625, 21.8606257],
                [105.8625829, 21.8603407],
                [105.8624636, 21.8599593],
                [105.862517, 21.8591133],
                [105.8625878, 21.8587309],
                [105.8627895, 21.8581979],
                [105.8635055, 21.8568776],
                [105.8636782, 21.8564266],
                [105.8641646, 21.8546097],
                [105.8644307, 21.8538648],
                [105.8646165, 21.8531272],
                [105.8647008, 21.8525674],
                [105.864711, 21.8518444],
                [105.8646651, 21.8515036],
                [105.8645835, 21.8512925],
                [105.8641557, 21.8506946],
                [105.862978, 21.8494047],
                [105.8626974, 21.848956],
                [105.8615509, 21.8467862],
                [105.8612709, 21.8464329],
                [105.8606975, 21.8459176],
                [105.860316, 21.8457149],
                [105.8598179, 21.8455811],
                [105.8589616, 21.8454559],
                [105.8579233, 21.8454478],
                [105.8572512, 21.8455468],
                [105.8561559, 21.8458252],
                [105.8556588, 21.8458551],
                [105.8520222, 21.8453589],
                [105.8507405, 21.8450163],
                [105.8486352, 21.8443316],
                [105.8478081, 21.844162],
                [105.8467883, 21.8445094],
                [105.8463942, 21.8446479],
                [105.8460291, 21.8447179],
                [105.8456491, 21.8447608],
                [105.8451814, 21.8448042],
                [105.8448304, 21.8448059],
                [105.8443406, 21.8448289],
                [105.8435664, 21.8449693],
                [105.843099, 21.8450944],
                [105.842573, 21.8451654],
                [105.8416372, 21.8451974],
                [105.841096, 21.8451729],
                [105.8403925, 21.8451668],
                [105.8390768, 21.8451125],
                [105.8380944, 21.8452285],
                [105.8366645, 21.8452357],
                [105.8343527, 21.8457983],
                [105.8338791, 21.8459324],
                [105.8318641, 21.8461011],
                [105.8290706, 21.8460982],
                [105.8264602, 21.8462662],
                [105.8240789, 21.846221],
                [105.8220642, 21.8460481],
                [105.819958, 21.8457471],
                [105.8189508, 21.8455752],
                [105.8173031, 21.8449331],
                [105.8147775, 21.8437596],
                [105.812881, 21.8424172],
                [105.8108689, 21.8406226],
                [105.8093151, 21.838487],
                [105.8072131, 21.8355827],
                [105.8057963, 21.8337886],
                [105.8047912, 21.8322512],
                [105.8041981, 21.8307568],
                [105.8040646, 21.8279401],
                [105.8038386, 21.825806],
                [105.8033368, 21.8245253],
                [105.8027438, 21.8231162],
                [105.8025162, 21.8221344],
                [105.8026548, 21.821153],
                [105.8031157, 21.8188918],
                [105.8031627, 21.817953],
                [105.8027979, 21.8169711],
                [105.8026158, 21.81616],
                [105.8027085, 21.8154347],
                [105.8029841, 21.8146241],
                [105.8029846, 21.8143253],
                [105.8027107, 21.8137703],
                [105.8017975, 21.8121475],
                [105.800747, 21.8104818],
                [105.8004737, 21.8095],
                [105.8009796, 21.807751],
                [105.8009806, 21.8070682],
                [105.8007985, 21.8063851],
                [105.8002958, 21.8057444],
                [105.7995187, 21.8049753],
                [105.7985592, 21.8049344],
                [105.7974592, 21.8048875],
                [105.7922415, 21.8047956],
                [105.7866156, 21.804335],
                [105.7862232, 21.8043825],
                [105.7843895, 21.8063432],
                [105.7825559, 21.8081758],
                [105.781594, 21.808644],
                [105.781044, 21.8091127],
                [105.7802646, 21.8100079],
                [105.7793022, 21.810732],
                [105.7787983, 21.8109448],
                [105.7774705, 21.8112417],
                [105.7757307, 21.8115381],
                [105.7746316, 21.8118779],
                [105.7734867, 21.8122605],
                [105.772617, 21.8122593],
                [105.7723055, 21.8122229],
                [105.770915, 21.8121377],
                [105.7708356, 21.8123433],
                [105.7708236, 21.812407],
                [105.7707814, 21.8127074],
                [105.7707383, 21.8128576],
                [105.7705638, 21.8130358],
                [105.770287, 21.8132281],
                [105.7698926, 21.8133118],
                [105.7695564, 21.8133133],
                [105.7692707, 21.813226],
                [105.7686117, 21.8130108],
                [105.7683628, 21.8129574],
                [105.768231, 21.8129171],
                [105.7680261, 21.812877],
                [105.7678798, 21.8128641],
                [105.7676459, 21.8128925],
                [105.7674419, 21.8130162],
                [105.7671946, 21.8132493],
                [105.7668167, 21.8136603],
                [105.7664968, 21.8140028],
                [105.766191, 21.8142498],
                [105.7658126, 21.8145653],
                [105.7653755, 21.8148539],
                [105.7646179, 21.8153758],
                [105.7637363, 21.815946],
                [105.7632406, 21.8162348],
                [105.762672, 21.8165512],
                [105.7622491, 21.8167577],
                [105.7619281, 21.816882],
                [105.7616037, 21.8170048],
                [105.7609126, 21.8179399],
                [105.7604072, 21.8190487],
                [105.7602228, 21.8198592],
                [105.7602669, 21.8208836],
                [105.7604698, 21.8217894],
                [105.761021, 21.8223158],
                [105.7615364, 21.8229955],
                [105.7620815, 21.8237706],
                [105.7623042, 21.8243834],
                [105.7623864, 21.8247446],
                [105.7623885, 21.8251265],
                [105.7623608, 21.825413],
                [105.7622745, 21.8257],
                [105.762028, 21.8260832],
                [105.7616943, 21.8265757],
                [105.7614478, 21.8269726],
                [105.7613031, 21.8272734],
                [105.7611517, 21.8276765],
                [105.7607614, 21.828524],
                [105.760516, 21.8291391],
                [105.7601705, 21.8301774],
                [105.7600337, 21.8305806],
                [105.7599325, 21.8307993],
                [105.7598454, 21.8309225],
                [105.7596998, 21.8310323],
                [105.7594955, 21.8311015],
                [105.7592908, 21.8311161],
                [105.7590277, 21.8311445],
                [105.7588524, 21.8311726],
                [105.7587064, 21.8312142],
                [105.7584871, 21.8312425],
                [105.7582097, 21.8313121],
                [105.7579908, 21.8313948],
                [105.7578888, 21.8314635],
                [105.75786, 21.8315318],
                [105.7578753, 21.8316955],
                [105.7579795, 21.8320224],
                [105.7581421, 21.8323764],
                [105.7582169, 21.8326762],
                [105.7582769, 21.8329487],
                [105.7583221, 21.8332077],
                [105.7583314, 21.8335828],
                [105.7583186, 21.833924],
                [105.7583063, 21.8343742],
                [105.7583224, 21.8346742],
                [105.7584265, 21.8349876],
                [105.7587077, 21.8356138],
                [105.7590472, 21.8362124],
                [105.7592754, 21.8365047],
                [105.7595399, 21.836749],
                [105.7601415, 21.8371283],
                [105.7604499, 21.8373724],
                [105.7608319, 21.8377117],
                [105.7610085, 21.8379292],
                [105.7611559, 21.8381331],
                [105.7612156, 21.8383512],
                [105.7612608, 21.8386237],
                [105.7612479, 21.8389376],
                [105.7611763, 21.8392381],
                [105.7609939, 21.8396891],
                [105.7609319, 21.8399821],
                [105.760839, 21.8407734],
                [105.7606437, 21.8420083],
                [105.7605959, 21.8432458],
                [105.760686, 21.8440567],
                [105.7610061, 21.8443133],
                [105.7624243, 21.8449554],
                [105.7640714, 21.8455979],
                [105.764208, 21.8461102],
                [105.764206, 21.8473477],
                [105.7643877, 21.8482442],
                [105.7648444, 21.848885],
                [105.7656213, 21.8497823],
                [105.7658497, 21.850124],
                [105.765849, 21.8505081],
                [105.7655737, 21.8509344],
                [105.7652976, 21.8517022],
                [105.7655254, 21.8523853],
                [105.7660731, 21.8534957],
                [105.7672153, 21.8549909],
                [105.7684959, 21.8558888],
                [105.7687244, 21.8561026],
                [105.7690874, 21.8566509],
                [105.7693183, 21.8569996],
                [105.7697734, 21.8587073],
                [105.7699534, 21.8606705],
                [105.7699064, 21.8614812],
                [105.7695848, 21.8620782],
                [105.7689422, 21.863059],
                [105.7686665, 21.8636986],
                [105.7684359, 21.8647226],
                [105.7683887, 21.865576],
                [105.7683867, 21.8667709],
                [105.7683404, 21.867608],
                [105.7686046, 21.8688041],
                [105.7692978, 21.8697167],
                [105.7704873, 21.8704011],
                [105.7705324, 21.8708707],
                [105.7703022, 21.8716385],
                [105.7702548, 21.8726626],
                [105.7703912, 21.8732603],
                [105.7709856, 21.8737732],
                [105.772129, 21.8747136],
                [105.7732722, 21.8757394],
                [105.7728575, 21.8773178],
                [105.7718465, 21.8815312],
                [105.7714305, 21.8818617],
                [105.7709607, 21.8825452],
                [105.7708619, 21.8829147],
                [105.7712813, 21.8839539],
                [105.7720297, 21.884074],
                [105.7720189, 21.8839925],
                [105.7720891, 21.8844502],
                [105.7717482, 21.8847073],
                [105.7712807, 21.8848085],
                [105.7705327, 21.8849043],
                [105.7703665, 21.8849235],
                [105.770221, 21.8850006],
                [105.7701484, 21.8851118],
                [105.770166, 21.8851449],
                [105.7713548, 21.8864268],
                [105.7726816, 21.8872395],
                [105.7732765, 21.8874537],
                [105.7751542, 21.8874989],
                [105.7760243, 21.8874575],
                [105.7768935, 21.8880561],
                [105.7774885, 21.8883556],
                [105.7785419, 21.8882716],
                [105.779824, 21.8884441],
                [105.7810142, 21.8888297],
                [105.7812427, 21.8890861],
                [105.7812422, 21.8894275],
                [105.7796832, 21.890663],
                [105.7755564, 21.893858],
                [105.773493, 21.8953915],
                [105.7726671, 21.8963292],
                [105.7721148, 21.8980355],
                [105.7717924, 21.8991872],
                [105.7716534, 21.9001686],
                [105.7716058, 21.901278],
                [105.7717399, 21.9033266],
                [105.7718258, 21.9069967],
                [105.7716396, 21.9088315],
                [105.7713175, 21.9097272],
                [105.770033, 21.9110057],
                [105.7686113, 21.9120278],
                [105.7659522, 21.9135604],
                [105.7641868, 21.9146887],
                [105.7648572, 21.9154813],
                [105.7653427, 21.9159702],
                [105.7657401, 21.9163777],
                [105.7660051, 21.9166836],
                [105.7662266, 21.9170509],
                [105.7664714, 21.9176637],
                [105.7666945, 21.9183381],
                [105.7668513, 21.9189105],
                [105.7669961, 21.9193089],
                [105.7671513, 21.9195947],
                [105.767438, 21.919839],
                [105.767834, 21.9200009],
                [105.7683176, 21.9201215],
                [105.7685375, 21.9201819],
                [105.7687575, 21.9202628],
                [105.7691982, 21.9205676],
                [105.7698813, 21.9210352],
                [105.770278, 21.9213199],
                [105.7705206, 21.9215439],
                [105.7707855, 21.9218087],
                [105.7709186, 21.9220537],
                [105.7709418, 21.9222992],
                [105.7708995, 21.9225859],
                [105.7707428, 21.9240706],
                [105.7707231, 21.92448],
                [105.7707277, 21.9253395],
                [105.7708173, 21.9256666],
                [105.7709724, 21.925932],
                [105.771193, 21.9261356],
                [105.7714793, 21.926298],
                [105.7719631, 21.9264594],
                [105.7727103, 21.9265787],
                [105.7730183, 21.9267001],
                [105.7731725, 21.9268017],
                [105.7734154, 21.9270462],
                [105.7737469, 21.9274745],
                [105.7742004, 21.9280966],
                [105.7746217, 21.9288723],
                [105.7747773, 21.9292195],
                [105.7749761, 21.9294438],
                [105.7751089, 21.9296273],
                [105.7753514, 21.9298104],
                [105.7755719, 21.9299936],
                [105.7759241, 21.9301761],
                [105.7762983, 21.9303381],
                [105.7766068, 21.9305414],
                [105.7768496, 21.9307857],
                [105.7770927, 21.9310711],
                [105.7773584, 21.9314997],
                [105.777602, 21.9318874],
                [105.7777908, 21.9322652],
                [105.777837, 21.9327152],
                [105.7778444, 21.9340661],
                [105.7778243, 21.9343935],
                [105.7778036, 21.9346393],
                [105.7776951, 21.9348854],
                [105.7774117, 21.9352347],
                [105.7766693, 21.9359955],
                [105.7761019, 21.9366531],
                [105.7755455, 21.93728],
                [105.7750656, 21.9378553],
                [105.7746943, 21.9382255],
                [105.774476, 21.9384517],
                [105.7742377, 21.9386625],
                [105.7738188, 21.9391039],
                [105.7741749, 21.9396197],
                [105.7748434, 21.9403279],
                [105.7757208, 21.9413972],
                [105.7759904, 21.9417848],
                [105.776156, 21.9421527],
                [105.77628, 21.9425788],
                [105.7763001, 21.9430241],
                [105.77629, 21.9440037],
                [105.7762772, 21.9446755],
                [105.7761922, 21.9451875],
                [105.7760849, 21.9456383],
                [105.7758137, 21.9462434],
                [105.7756183, 21.9466537],
                [105.7752271, 21.9473923],
                [105.7750283, 21.9476969],
                [105.7748787, 21.9479261],
                [105.7745298, 21.9483781],
                [105.7743997, 21.9486856],
                [105.7743573, 21.9489724],
                [105.7743587, 21.9492385],
                [105.7744481, 21.9495246],
                [105.7747269, 21.9503112],
                [105.774839, 21.9507405],
                [105.7751527, 21.9518852],
                [105.775221, 21.9523352],
                [105.7752443, 21.9526012],
                [105.7752676, 21.9528262],
                [105.7752464, 21.95299],
                [105.7751812, 21.9531131],
                [105.7751157, 21.9531749],
                [105.7749626, 21.9532778],
                [105.7747436, 21.9534018],
                [105.7743273, 21.9535674],
                [105.7737572, 21.9537134],
                [105.7731652, 21.9538799],
                [105.7723979, 21.9541087],
                [105.7720963, 21.9542512],
                [105.7715861, 21.9545487],
                [105.7711832, 21.9548614],
                [105.770309, 21.9556228],
                [105.7694571, 21.9564659],
                [105.7691085, 21.9569792],
                [105.7688484, 21.9576149],
                [105.7685885, 21.9582916],
                [105.7682638, 21.9591528],
                [105.7680156, 21.9599623],
                [105.7678422, 21.960393],
                [105.767712, 21.9606596],
                [105.7674064, 21.9610294],
                [105.7668162, 21.9615235],
                [105.7661373, 21.9618541],
                [105.765612, 21.962184],
                [105.7652186, 21.9625544],
                [105.7650229, 21.9629032],
                [105.7648273, 21.9632724],
                [105.7645699, 21.9637189],
                [105.7643185, 21.9641215],
                [105.7640669, 21.9645006],
                [105.7637651, 21.9649507],
                [105.7634384, 21.965519],
                [105.7631872, 21.9659689],
                [105.7629613, 21.966395],
                [105.7627342, 21.9666087],
                [105.7622037, 21.9669889],
                [105.7617238, 21.967369],
                [105.7610418, 21.9678681],
                [105.7608151, 21.9681289],
                [105.7606644, 21.9684131],
                [105.7605906, 21.9688385],
                [105.7605174, 21.969382],
                [105.7604958, 21.9700907],
                [105.7604656, 21.9706296],
                [105.7604495, 21.9709173],
                [105.7603768, 21.9715554],
                [105.7603282, 21.9719571],
                [105.7603297, 21.9722405],
                [105.760419, 21.972327],
                [105.7606735, 21.9724912],
                [105.7611306, 21.9726073],
                [105.7614355, 21.9727241],
                [105.7616897, 21.972841],
                [105.761868, 21.9730056],
                [105.7619706, 21.9731943],
                [105.7619462, 21.9733834],
                [105.7618209, 21.9736677],
                [105.7614449, 21.9744966],
                [105.7611184, 21.9750889],
                [105.7608927, 21.9755626],
                [105.7607755, 21.9760262],
                [105.7607694, 21.9760555],
                [105.7607189, 21.9762019],
                [105.7607454, 21.9764145],
                [105.7608222, 21.9765559],
                [105.76123, 21.9769322],
                [105.7617908, 21.9774731],
                [105.7620845, 21.9778618],
                [105.7622382, 21.9781446],
                [105.7622403, 21.9785464],
                [105.7619953, 21.9792309],
                [105.7621781, 21.9795725],
                [105.7627273, 21.98],
                [105.7636883, 21.9807695],
                [105.7636421, 21.9810683],
                [105.763549, 21.9818789],
                [105.7635904, 21.9845675],
                [105.7636345, 21.9856344],
                [105.7631853, 21.9860348],
                [105.7628769, 21.9862123],
                [105.7623715, 21.9865455],
                [105.7617151, 21.9871158],
                [105.7611861, 21.98778],
                [105.7607957, 21.9882899],
                [105.7600385, 21.9889788],
                [105.7592809, 21.9895968],
                [105.7584224, 21.9903334],
                [105.7575125, 21.9909285],
                [105.7572682, 21.9910786],
                [105.7567887, 21.9913602],
                [105.7562072, 21.9917174],
                [105.7559552, 21.9920258],
                [105.7559513, 21.9920383],
                [105.7559699, 21.992195],
                [105.7561065, 21.9927499],
                [105.7562421, 21.993817],
                [105.7561948, 21.9946704],
                [105.7558721, 21.9957368],
                [105.7553663, 21.9967176],
                [105.7552276, 21.9974429],
                [105.7551807, 21.9980829],
                [105.7552723, 21.9990185],
                [105.7552174, 21.9991443],
                [105.7551093, 21.9992782],
                [105.7547279, 21.9997116],
                [105.7540785, 22.0003196],
                [105.753596, 22.0008],
                [105.7533121, 22.0010669],
                [105.7531131, 22.0012273],
                [105.753, 22.0014138],
                [105.7529721, 22.0015202],
                [105.7529726, 22.0016264],
                [105.7530881, 22.0018916],
                [105.7532903, 22.0023423],
                [105.7534919, 22.0026867],
                [105.7535627, 22.0031332],
                [105.7536099, 22.00343],
                [105.7535861, 22.0043333],
                [105.7535044, 22.0050776],
                [105.7533656, 22.0057955],
                [105.7531966, 22.0062213],
                [105.7528854, 22.0067275],
                [105.7527727, 22.0069937],
                [105.7527168, 22.0072064],
                [105.7527187, 22.0075783],
                [105.7527498, 22.0080565],
                [105.7527805, 22.0084547],
                [105.7528395, 22.0088264],
                [105.7528697, 22.0091451],
                [105.7529858, 22.0095165],
                [105.7530473, 22.0103662],
                [105.7529351, 22.0107122],
                [105.7527666, 22.0112443],
                [105.7523712, 22.0120165],
                [105.7520879, 22.0123897],
                [105.7518922, 22.0125765],
                [105.7516149, 22.013014],
                [105.7516072, 22.0130169],
                [105.7500659, 22.0136059],
                [105.7477753, 22.0135644],
                [105.7474225, 22.0139372],
                [105.7472338, 22.0142258],
                [105.7468566, 22.0149381],
                [105.746043, 22.0159189],
                [105.7458169, 22.0160684],
                [105.745614, 22.0161106],
                [105.7455166, 22.0161245],
                [105.7453778, 22.0162277],
                [105.745364, 22.0163918],
                [105.7453735, 22.0165155],
                [105.745, 22.0167408],
                [105.7440684, 22.0170293],
                [105.7427186, 22.017444],
                [105.7420413, 22.0176191],
                [105.7418514, 22.0176199],
                [105.741472, 22.0176975],
                [105.7411476, 22.0179013],
                [105.7410949, 22.0181798],
                [105.7411322, 22.0183534],
                [105.7407821, 22.0185542],
                [105.7394134, 22.0190044],
                [105.7379279, 22.0189756],
                [105.7372987, 22.0188186],
                [105.7371265, 22.0186773],
                [105.7370084, 22.0179316],
                [105.7366236, 22.017134],
                [105.7362403, 22.0166916],
                [105.7353815, 22.0163224],
                [105.7351029, 22.0163273],
                [105.7349532, 22.0164157],
                [105.7346896, 22.0164989],
                [105.734098, 22.0164194],
                [105.7335441, 22.0162929],
                [105.7331783, 22.016107],
                [105.7328378, 22.0159091],
                [105.7325979, 22.015746],
                [105.7324466, 22.0156763],
                [105.7316446, 22.0154154],
                [105.7308863, 22.0160582],
                [105.7296372, 22.017556],
                [105.7288431, 22.0186787],
                [105.7280894, 22.0202276],
                [105.7276922, 22.0207446],
                [105.72737, 22.0210658],
                [105.7270288, 22.0212513],
                [105.7260371, 22.0222888],
                [105.7248898, 22.0232389],
                [105.7234974, 22.0239982],
                [105.7231757, 22.0240949],
                [105.7218601, 22.0244906],
                [105.7203561, 22.0244633],
                [105.7200553, 22.0243669],
                [105.7182599, 22.0233088],
                [105.7163686, 22.0221268],
                [105.7154154, 22.0219001],
                [105.7132442, 22.0218207],
                [105.709514, 22.0222632],
                [105.7078411, 22.0227619],
                [105.7071561, 22.0229662],
                [105.706263, 22.0233431],
                [105.7059029, 22.0236822],
                [105.7054489, 22.024306],
                [105.7051091, 22.0249115],
                [105.7046544, 22.025393],
                [105.702985, 22.0266793],
                [105.7004229, 22.028449],
                [105.6981841, 22.0301817],
                [105.6971602, 22.0310921],
                [105.6969907, 22.0314837],
                [105.6969929, 22.0319278],
                [105.6971682, 22.0327264],
                [105.6970954, 22.0334196],
                [105.696701, 22.0345403],
                [105.6962167, 22.0360436],
                [105.6954375, 22.0371039],
                [105.6942426, 22.0381216],
                [105.6923249, 22.0393022],
                [105.6902342, 22.0401992],
                [105.6881997, 22.0408829],
                [105.6855205, 22.0421732],
                [105.6828969, 22.0431611],
                [105.6798736, 22.0441863],
                [105.6784089, 22.0445832],
                [105.6774204, 22.0449959],
                [105.6763386, 22.0457821],
                [105.6747833, 22.0471563],
                [105.6735858, 22.0476587],
                [105.6725007, 22.047752],
                [105.670368, 22.0478673],
                [105.6689383, 22.0476244],
                [105.6684227, 22.0473423],
                [105.6681354, 22.047006],
                [105.6680563, 22.0463846],
                [105.6680673, 22.0446436],
                [105.6679676, 22.0439259],
                [105.6673674, 22.0425167],
                [105.6666326, 22.0417917],
                [105.6653255, 22.0408369],
                [105.6637315, 22.0401481],
                [105.6633234, 22.0397283],
                [105.6632048, 22.0379382],
                [105.6626849, 22.0329482],
                [105.6618341, 22.0293666],
                [105.6592368, 22.0297034],
                [105.6589515, 22.0297757],
                [105.6583803, 22.0298135],
                [105.6580372, 22.029726],
                [105.6571391, 22.0290546],
                [105.6551312, 22.0272684],
                [105.6540576, 22.0257094],
                [105.6536527, 22.0246095],
                [105.6532295, 22.0236875],
                [105.6529805, 22.0233864],
                [105.652623, 22.0243294],
                [105.6521686, 22.0248997],
                [105.6517895, 22.0252742],
                [105.6511425, 22.0254012],
                [105.6504567, 22.0253328],
                [105.6498461, 22.0250866],
                [105.6493119, 22.02484],
                [105.6486071, 22.024825],
                [105.648074, 22.0248627],
                [105.6471603, 22.0249373],
                [105.6462268, 22.0248522],
                [105.6457278, 22.0246958],
                [105.6452184, 22.0250338],
                [105.6442875, 22.0255527],
                [105.6430711, 22.0260904],
                [105.64098, 22.0268802],
                [105.6405425, 22.0270063],
                [105.6403136, 22.0269183],
                [105.6401385, 22.026084],
                [105.639851, 22.0256944],
                [105.6393923, 22.0253232],
                [105.6391245, 22.02504],
                [105.6387411, 22.0244907],
                [105.6382029, 22.0233736],
                [105.6377037, 22.0224489],
                [105.6375474, 22.0215794],
                [105.637356, 22.0213494],
                [105.6369551, 22.0211379],
                [105.6366502, 22.0210858],
                [105.6361741, 22.0210876],
                [105.6351272, 22.0211805],
                [105.6350128, 22.0211631],
                [105.6351829, 22.0208784],
                [105.6357518, 22.0203258],
                [105.6359781, 22.0198454],
                [105.636185, 22.0192587],
                [105.6361651, 22.0190635],
                [105.6356125, 22.0189946],
                [105.6347366, 22.018998],
                [105.6329089, 22.0190939],
                [105.6316611, 22.0192537],
                [105.6307102, 22.0192574],
                [105.6296797, 22.019086],
                [105.628993, 22.0188639],
                [105.6269575, 22.0181933],
                [105.6255012, 22.017764],
                [105.6238373, 22.0170086],
                [105.6211537, 22.0183005],
                [105.6209368, 22.0183238],
                [105.6206718, 22.0183923],
                [105.6199959, 22.0166542],
                [105.6195798, 22.0153164],
                [105.6193915, 22.0148995],
                [105.6187919, 22.0142235],
                [105.6172378, 22.0127158],
                [105.6168451, 22.012439],
                [105.6164905, 22.0123708],
                [105.6162854, 22.0123716],
                [105.616025, 22.0125117],
                [105.6156353, 22.0129306],
                [105.6149665, 22.0135072],
                [105.6144636, 22.0136309],
                [105.613829, 22.0134766],
                [105.6133055, 22.0131655],
                [105.6125748, 22.0123333],
                [105.6119202, 22.0118661],
                [105.6106694, 22.011488],
                [105.6089717, 22.0112856],
                [105.608189, 22.0113755],
                [105.6076679, 22.0116036],
                [105.607296, 22.0118311],
                [105.6068115, 22.0119025],
                [105.6058234, 22.0119235],
                [105.6049851, 22.0121005],
                [105.6043149, 22.012364],
                [105.6036642, 22.0127837],
                [105.602977, 22.0134299],
                [105.6023831, 22.0140758],
                [105.602234, 22.0140763],
                [105.6017086, 22.0133129],
                [105.6011473, 22.0128452],
                [105.6004183, 22.012413],
                [105.5986456, 22.0120889],
                [105.5970964, 22.011712],
                [105.5956665, 22.0112966],
                [105.5928308, 22.0104727],
                [105.5921779, 22.0099379],
                [105.591159, 22.0086024],
                [105.5904417, 22.0077003],
                [105.5899352, 22.0068672],
                [105.5895039, 22.0062571],
                [105.5886803, 22.0054776],
                [105.5881366, 22.0047493],
                [105.587684, 22.0035165],
                [105.5875514, 22.0029954],
                [105.5872321, 22.0024229],
                [105.5867826, 22.0019202],
                [105.5862954, 22.0013308],
                [105.5860318, 22.0007232],
                [105.5858415, 21.9998902],
                [105.5855748, 21.9992023],
                [105.5854978, 21.9990204],
                [105.5851484, 21.9981949],
                [105.58462, 21.9973486],
                [105.5840879, 21.9963656],
                [105.5838468, 21.9960829],
                [105.5836376, 21.9958002],
                [105.5835409, 21.9956215],
                [105.583444, 21.9954128],
                [105.5834116, 21.9952155],
                [105.5831062, 21.9948582],
                [105.5828813, 21.994635],
                [105.5827846, 21.9944862],
                [105.5827361, 21.9943519],
                [105.5827354, 21.9941727],
                [105.5827827, 21.9940084],
                [105.582846, 21.993844],
                [105.5829254, 21.9936794],
                [105.5830049, 21.9935447],
                [105.5830683, 21.9934103],
                [105.5831475, 21.9932308],
                [105.5832582, 21.992887],
                [105.5832897, 21.9927824],
                [105.5833052, 21.9926628],
                [105.5833367, 21.9925285],
                [105.583352, 21.9923642],
                [105.5833515, 21.9922448],
                [105.5833349, 21.9920955],
                [105.5833023, 21.9919463],
                [105.5832375, 21.9917525],
                [105.5831728, 21.9915735],
                [105.5830601, 21.9914097],
                [105.5829472, 21.991216],
                [105.5828026, 21.9910374],
                [105.5824171, 21.9906805],
                [105.5822854, 21.9904886],
                [105.5821532, 21.990311],
                [105.582033, 21.9900998],
                [105.5819007, 21.9898775],
                [105.5818135, 21.9895807],
                [105.5815606, 21.9884644],
                [105.5814274, 21.9880083],
                [105.5813073, 21.9878417],
                [105.5806728, 21.9874208],
                [105.5805169, 21.9872766],
                [105.5802754, 21.9870726],
                [105.5801465, 21.9868641],
                [105.580066, 21.9867152],
                [105.5799851, 21.9865213],
                [105.5798399, 21.9862232],
                [105.5796947, 21.9859103],
                [105.5795977, 21.9856718],
                [105.5795169, 21.9854629],
                [105.5794192, 21.9850454],
                [105.5794023, 21.9848066],
                [105.579372, 21.9842731],
                [105.5793693, 21.9835864],
                [105.5794643, 21.9833322],
                [105.5797032, 21.9830776],
                [105.58007, 21.9827777],
                [105.580421, 21.9825526],
                [105.5807403, 21.9823573],
                [105.5810433, 21.9821174],
                [105.5814258, 21.9817728],
                [105.5816804, 21.9814284],
                [105.5817919, 21.9812936],
                [105.5819031, 21.9811141],
                [105.5819665, 21.9809646],
                [105.5819814, 21.9806809],
                [105.5819489, 21.9805616],
                [105.5818202, 21.9804128],
                [105.5816116, 21.9802642],
                [105.581371, 21.9801008],
                [105.5807638, 21.9794821],
                [105.5802171, 21.9788122],
                [105.579928, 21.9785296],
                [105.5796872, 21.9783365],
                [105.5794145, 21.9781433],
                [105.5791418, 21.9779801],
                [105.5789174, 21.9778614],
                [105.5786448, 21.9777131],
                [105.578356, 21.97752],
                [105.5781793, 21.9773415],
                [105.5780505, 21.9771479],
                [105.5778889, 21.9767453],
                [105.57784, 21.9765216],
                [105.5778233, 21.9763573],
                [105.5778223, 21.9761185],
                [105.5777878, 21.9755593],
                [105.5777853, 21.9749322],
                [105.57788, 21.9746333],
                [105.577975, 21.9743642],
                [105.578134, 21.9741248],
                [105.5782771, 21.9739153],
                [105.5784044, 21.9737357],
                [105.5785313, 21.9734964],
                [105.5786106, 21.973287],
                [105.5788636, 21.9725696],
                [105.5789744, 21.9722706],
                [105.5790373, 21.9720167],
                [105.5790685, 21.9718075],
                [105.5790676, 21.9715835],
                [105.5791762, 21.9707859],
                [105.5792234, 21.9705917],
                [105.579398, 21.9702477],
                [105.5795889, 21.9699782],
                [105.57978, 21.9697686],
                [105.5800191, 21.9695587],
                [105.5803221, 21.9693188],
                [105.5806094, 21.9691386],
                [105.5807369, 21.9690486],
                [105.5811595, 21.9687175],
                [105.5782125, 21.9671921],
                [105.5755822, 21.9660127],
                [105.5753334, 21.9661494],
                [105.5749981, 21.9663147],
                [105.5745028, 21.9664957],
                [105.5741354, 21.9666164],
                [105.5736006, 21.9667527],
                [105.5731689, 21.966799],
                [105.5729449, 21.9667998],
                [105.5728168, 21.9667853],
                [105.5725924, 21.9666816],
                [105.572448, 21.9665776],
                [105.5723996, 21.9664583],
                [105.5723831, 21.966324],
                [105.5723819, 21.9660254],
                [105.5724609, 21.9657863],
                [105.5726039, 21.9655469],
                [105.572795, 21.9653223],
                [105.5731136, 21.9649927],
                [105.5733048, 21.9647831],
                [105.5734959, 21.9645884],
                [105.5736872, 21.9644084],
                [105.573926, 21.964124],
                [105.5741491, 21.9638993],
                [105.5742763, 21.9637346],
                [105.5743713, 21.9634805],
                [105.5744027, 21.9633161],
                [105.5744339, 21.9631369],
                [105.5743674, 21.9625024],
                [105.5743188, 21.9623235],
                [105.5742542, 21.9621892],
                [105.574125, 21.9618762],
                [105.5740441, 21.9616675],
                [105.5739792, 21.9614289],
                [105.5739621, 21.9611751],
                [105.5739611, 21.9609064],
                [105.5739923, 21.9607271],
                [105.5740711, 21.9604433],
                [105.5741822, 21.960204],
                [105.5742931, 21.9599348],
                [105.57442, 21.9596657],
                [105.5745466, 21.9593367],
                [105.5746419, 21.9591573],
                [105.5747687, 21.9588582],
                [105.5754197, 21.9578771],
                [105.5755151, 21.9577126],
                [105.5755148, 21.9576529],
                [105.5754827, 21.9576231],
                [105.5753866, 21.9575936],
                [105.5748907, 21.9575952],
                [105.5738191, 21.9576289],
                [105.5728433, 21.9576323],
                [105.5726194, 21.957648],
                [105.5721875, 21.9576645],
                [105.5718358, 21.9576955],
                [105.5715158, 21.9577116],
                [105.571212, 21.9577425],
                [105.5708602, 21.9577587],
                [105.5706684, 21.9577892],
                [105.5703006, 21.9578353],
                [105.5700926, 21.9578062],
                [105.5699323, 21.957732],
                [105.5697557, 21.9575684],
                [105.5696272, 21.9574346],
                [105.5694505, 21.957271],
                [105.5692739, 21.9570924],
                [105.5691454, 21.9569734],
                [105.5687826, 21.9567273],
                [105.5686704, 21.9566679],
                [105.5685102, 21.9566237],
                [105.5677744, 21.9566412],
                [105.5672948, 21.9567026],
                [105.5670069, 21.9567335],
                [105.566863, 21.9567339],
                [105.5667031, 21.9567494],
                [105.566351, 21.956691],
                [105.5660144, 21.9565278],
                [105.5657417, 21.9563495],
                [105.5655008, 21.9561116],
                [105.5653563, 21.9559777],
                [105.5650997, 21.9557994],
                [105.5648754, 21.9557107],
                [105.5646993, 21.9556665],
                [105.5644912, 21.9556522],
                [105.5642354, 21.9556681],
                [105.5637716, 21.9556996],
                [105.5633718, 21.9557307],
                [105.5627235, 21.955701],
                [105.5622115, 21.9556728],
                [105.5619235, 21.9556738],
                [105.5616838, 21.9557195],
                [105.5613163, 21.9558252],
                [105.5610608, 21.9559455],
                [105.5608533, 21.9560656],
                [105.5605499, 21.9562161],
                [105.5602945, 21.9563362],
                [105.5599429, 21.956427],
                [105.5593837, 21.9565932],
                [105.5586485, 21.956745],
                [105.5578813, 21.9569268],
                [105.5570662, 21.9571236],
                [105.5565707, 21.9572148],
                [105.5562087, 21.9573503],
                [105.5558072, 21.9574628],
                [105.5556883, 21.9574802],
                [105.5554768, 21.9572249],
                [105.5549607, 21.9563188],
                [105.5546719, 21.9558663],
                [105.5540512, 21.955256],
                [105.5536965, 21.9548862],
                [105.5534752, 21.9547633],
                [105.5530766, 21.9544761],
                [105.5527221, 21.9541682],
                [105.5524784, 21.9539629],
                [105.5521678, 21.9535929],
                [105.5519457, 21.9532639],
                [105.5517458, 21.9529761],
                [105.5516345, 21.9527497],
                [105.5514788, 21.9524617],
                [105.5513676, 21.9522353],
                [105.5513004, 21.9519883],
                [105.5512774, 21.9517616],
                [105.5512738, 21.9508342],
                [105.5512288, 21.9506283],
                [105.5510508, 21.9501609],
                [105.5508289, 21.9498732],
                [105.550452, 21.9494829],
                [105.5501637, 21.9491746],
                [105.5497203, 21.9487227],
                [105.5495648, 21.9484553],
                [105.5493873, 21.9482292],
                [105.549276, 21.9480234],
                [105.5492315, 21.9479205],
                [105.5491426, 21.9477766],
                [105.5490978, 21.9476119],
                [105.5490973, 21.9474675],
                [105.5491186, 21.947282],
                [105.5492063, 21.9471169],
                [105.5495139, 21.9467242],
                [105.5510715, 21.9450785],
                [105.5515113, 21.9445824],
                [105.5516865, 21.9442109],
                [105.5517956, 21.9438807],
                [105.5518385, 21.9435508],
                [105.5518595, 21.9432415],
                [105.5518571, 21.9426233],
                [105.5516301, 21.940961],
                [105.5514499, 21.9400548],
                [105.5513373, 21.9394575],
                [105.5513138, 21.9391072],
                [105.551313, 21.9389011],
                [105.5513748, 21.938109],
                [105.5514618, 21.9377789],
                [105.5517468, 21.9372421],
                [105.5519881, 21.9368291],
                [105.5520974, 21.9365608],
                [105.552162, 21.9361278],
                [105.5521826, 21.9357567],
                [105.5521755, 21.9339019],
                [105.5521301, 21.9335929],
                [105.5520409, 21.9333665],
                [105.5519517, 21.9331195],
                [105.5517958, 21.9327902],
                [105.5516401, 21.9324816],
                [105.5514625, 21.9322348],
                [105.5513294, 21.9320704],
                [105.5511298, 21.9318444],
                [105.550643, 21.9310535],
                [105.5505539, 21.9308477],
                [105.550531, 21.930621],
                [105.5505293, 21.9301883],
                [105.5506162, 21.9298376],
                [105.5506595, 21.9295902],
                [105.5507022, 21.9292191],
                [105.5507455, 21.9290128],
                [105.5507888, 21.9287859],
                [105.5508101, 21.9285591],
                [105.5508071, 21.9277966],
                [105.5507839, 21.9275081],
                [105.5508637, 21.9254535],
                [105.5508597, 21.9244024],
                [105.5508136, 21.9239079],
                [105.5507225, 21.9231663],
                [105.5506988, 21.9227335],
                [105.5506963, 21.9220946],
                [105.550783, 21.9216821],
                [105.5509359, 21.9212488],
                [105.5511112, 21.9208978],
                [105.5514847, 21.9204225],
                [105.5517269, 21.9202569],
                [105.5521676, 21.9200287],
                [105.5527367, 21.9197261],
                [105.5529347, 21.9195399],
                [105.5532428, 21.9192709],
                [105.5533524, 21.9190644],
                [105.5534392, 21.9186726],
                [105.5534381, 21.918384],
                [105.5534347, 21.9182527],
                [105.5534171, 21.9175437],
                [105.5534119, 21.9173331],
                [105.5533661, 21.9169004],
                [105.553321, 21.9166738],
                [105.5532983, 21.9164884],
                [105.5532975, 21.9162823],
                [105.5533406, 21.9160142],
                [105.5534061, 21.9158285],
                [105.5535594, 21.9155188],
                [105.5537979, 21.9146421],
                [105.5538624, 21.9142091],
                [105.5538831, 21.913838],
                [105.5538795, 21.91289],
                [105.5538336, 21.9124367],
                [105.553744, 21.9121073],
                [105.5536547, 21.9118603],
                [105.5535648, 21.9114484],
                [105.5531391, 21.9095159],
                [105.552939, 21.9085432],
                [105.5528528, 21.9083606],
                [105.5527208, 21.9082179],
                [105.5525146, 21.9081225],
                [105.551895, 21.9077329],
                [105.5515412, 21.9075899],
                [105.5513201, 21.9075081],
                [105.5510328, 21.907406],
                [105.5494463, 21.9071866],
                [105.5485403, 21.9069423],
                [105.5481419, 21.9066963],
                [105.5478762, 21.9064705],
                [105.5476103, 21.9062035],
                [105.5474328, 21.9059979],
                [105.5470172, 21.9055547],
                [105.5467294, 21.905329],
                [105.5462424, 21.9049802],
                [105.5458443, 21.9047755],
                [105.5456009, 21.904632],
                [105.5454459, 21.9045295],
                [105.5452467, 21.9043652],
                [105.5451137, 21.9042214],
                [105.5449804, 21.9040158],
                [105.5449137, 21.9038717],
                [105.5448688, 21.9036658],
                [105.5448675, 21.9033361],
                [105.5448888, 21.9031505],
                [105.5449103, 21.9029856],
                [105.5449977, 21.9027585],
                [105.5450632, 21.9025522],
                [105.5451721, 21.9021809],
                [105.5452595, 21.9019333],
                [105.5457188, 21.9009498],
                [105.545937, 21.9002895],
                [105.5460233, 21.8997328],
                [105.5461974, 21.8990933],
                [105.5463718, 21.8985156],
                [105.5465029, 21.8981648],
                [105.5467002, 21.8977932],
                [105.5468094, 21.8974837],
                [105.5468968, 21.8972567],
                [105.5468961, 21.8970712],
                [105.546851, 21.896824],
                [105.5467175, 21.8965566],
                [105.5465617, 21.8962273],
                [105.5456266, 21.8940172],
                [105.5451155, 21.893112],
                [105.544827, 21.8927008],
                [105.5444719, 21.8921866],
                [105.5440728, 21.8917345],
                [105.5436377, 21.8913292],
                [105.5433943, 21.8911446],
                [105.5431949, 21.8909597],
                [105.5429294, 21.8908163],
                [105.54262, 21.8906937],
                [105.5420011, 21.8904896],
                [105.5415151, 21.8903676],
                [105.5415127, 21.890367],
                [105.5410512, 21.890266],
                [105.540742, 21.8902259],
                [105.5403004, 21.890186],
                [105.5399471, 21.8901666],
                [105.5392184, 21.8900866],
                [105.5386223, 21.8900473],
                [105.5382029, 21.8900486],
                [105.5372174, 21.8901144],
                [105.5366658, 21.8901985],
                [105.5362772, 21.890319],
                [105.5360929, 21.8903678],
                [105.5358418, 21.8903479],
                [105.5356344, 21.890197],
                [105.5354492, 21.8900116],
                [105.5352515, 21.8897291],
                [105.5350297, 21.8894619],
                [105.5348745, 21.889277],
                [105.5345865, 21.8889893],
                [105.5341438, 21.888661],
                [105.533834, 21.888456],
                [105.533458, 21.8882305],
                [105.5331706, 21.8881077],
                [105.5322648, 21.8879046],
                [105.5319557, 21.8878644],
                [105.5315361, 21.8878038],
                [105.5310725, 21.8877847],
                [105.52991, 21.8876677],
                [105.5291594, 21.8876289],
                [105.5287401, 21.8876509],
                [105.5284534, 21.887693],
                [105.5281886, 21.887735],
                [105.5278578, 21.8878186],
                [105.5275491, 21.887902],
                [105.5271962, 21.8879855],
                [105.5263138, 21.888112],
                [105.5257676, 21.8880942],
                [105.525348, 21.8880337],
                [105.5249282, 21.8879114],
                [105.5245744, 21.8877476],
                [105.5241541, 21.8873901],
                [105.5240318, 21.8872292],
                [105.5239261, 21.8870057],
                [105.5234302, 21.8862868],
                [105.5228106, 21.8860187],
                [105.5223756, 21.8858105],
                [105.5217407, 21.8854483],
                [105.5212536, 21.8850376],
                [105.5208772, 21.884709],
                [105.5205447, 21.8843185],
                [105.5200768, 21.883104],
                [105.5199185, 21.8820534],
                [105.5199376, 21.8812289],
                [105.5198695, 21.8807139],
                [105.5195814, 21.8792213],
                [105.5193586, 21.8786449],
                [105.5191142, 21.8781922],
                [105.5188918, 21.8777189],
                [105.5187584, 21.8774514],
                [105.5186249, 21.8771632],
                [105.5184912, 21.8768132],
                [105.5184018, 21.8765044],
                [105.5183775, 21.8758655],
                [105.5191649, 21.8738845],
                [105.5196283, 21.8730233],
                [105.5210041, 21.8714868],
                [105.5226412, 21.8700086],
                [105.5224068, 21.8692887],
                [105.5223215, 21.8690204],
                [105.5222759, 21.8686289],
                [105.5222307, 21.86832],
                [105.5222299, 21.8680932],
                [105.5225136, 21.8672129],
                [105.5228196, 21.8664082],
                [105.5231259, 21.8656859],
                [105.523389, 21.865211],
                [105.5236524, 21.8648186],
                [105.523916, 21.864488],
                [105.5242016, 21.8641161],
                [105.5246407, 21.8634964],
                [105.5254037, 21.8623874],
                [105.5256451, 21.8615053],
                [105.5258626, 21.8606184],
                [105.525949, 21.8601029],
                [105.5260579, 21.8596903],
                [105.5261667, 21.8592984],
                [105.5262538, 21.8589683],
                [105.5266887, 21.8573897],
                [105.5270382, 21.8563994],
                [105.5273662, 21.8555739],
                [105.5277165, 21.8548308],
                [105.5279573, 21.8542942],
                [105.5280882, 21.8539022],
                [105.5283938, 21.8529943],
                [105.5285683, 21.8524167],
                [105.5285656, 21.8516861],
                [105.5284753, 21.8511299],
                [105.5281636, 21.850389],
                [105.5280299, 21.850039],
                [105.5279849, 21.8497918],
                [105.5279843, 21.849627],
                [105.5280057, 21.849462],
                [105.5280933, 21.8492763],
                [105.5282469, 21.8490491],
                [105.5284224, 21.84876],
                [105.528532, 21.8485535],
                [105.5286197, 21.848409],
                [105.5287293, 21.8482025],
                [105.528773, 21.8480787],
                [105.5288165, 21.8479136],
                [105.5288601, 21.8477692],
                [105.5289033, 21.8475218],
                [105.5289464, 21.8472331],
                [105.5290748, 21.8461511],
                [105.5290512, 21.8457389],
                [105.5290504, 21.8455123],
                [105.529005, 21.845162],
                [105.5289151, 21.8447295],
                [105.5288477, 21.8443999],
                [105.5287578, 21.8439468],
                [105.5286682, 21.8435967],
                [105.5285786, 21.843226],
                [105.5284882, 21.8426492],
                [105.5284652, 21.8424019],
                [105.528331, 21.8418871],
                [105.5282409, 21.8413928],
                [105.5280614, 21.840569],
                [105.5281254, 21.8399917],
                [105.528254, 21.8389607],
                [105.5284239, 21.8371982],
                [105.5284219, 21.8366417],
                [105.5283763, 21.8362503],
                [105.528309, 21.8359207],
                [105.5281743, 21.8353234],
                [105.5279743, 21.8349119],
                [105.5277968, 21.8346652],
                [105.5275535, 21.834501],
                [105.5272441, 21.8343783],
                [105.5269127, 21.8342557],
                [105.5264927, 21.8340509],
                [105.5260949, 21.8338462],
                [105.5258296, 21.8337234],
                [105.5255424, 21.8336006],
                [105.5253212, 21.8334777],
                [105.5245947, 21.8331282],
                [105.5240659, 21.8328346],
                [105.5230549, 21.832176],
                [105.5219628, 21.8316959],
                [105.520734, 21.8310889],
                [105.520024, 21.8307603],
                [105.5195853, 21.8300491],
                [105.5186814, 21.8288556],
                [105.5177241, 21.8279424],
                [105.5168137, 21.8266758],
                [105.5159204, 21.8266755],
                [105.5159145, 21.8266767],
                [105.515185, 21.8268305],
                [105.5145867, 21.8272141],
                [105.5142895, 21.8279786],
                [105.5139928, 21.8288449],
                [105.5133969, 21.8299157],
                [105.5124189, 21.8308605],
                [105.5113865, 21.8318053],
                [105.5107067, 21.8323165],
                [105.5100254, 21.8323695],
                [105.5090717, 21.8324742],
                [105.5077096, 21.832733],
                [105.5064016, 21.8328643],
                [105.5052562, 21.8327405],
                [105.5042738, 21.8324381],
                [105.5032369, 21.8321104],
                [105.5022549, 21.8319352],
                [105.5018903, 21.8319266],
                [105.5011336, 21.8318204],
                [105.500329, 21.8318026],
                [105.4988277, 21.8318072],
                [105.4961193, 21.8323125],
                [105.4945959, 21.8311994],
                [105.4940818, 21.8304646],
                [105.4937164, 21.82959],
                [105.4932879, 21.8289544],
                [105.4930517, 21.8284376],
                [105.4929648, 21.8279801],
                [105.492963, 21.8274427],
                [105.4930433, 21.8260096],
                [105.4931026, 21.824656],
                [105.4932465, 21.8231231],
                [105.4933588, 21.8212209],
                [105.4937135, 21.8191145],
                [105.4942912, 21.817111],
                [105.4949559, 21.8154589],
                [105.4948012, 21.8138671],
                [105.4947972, 21.8126929],
                [105.4944652, 21.8091513],
                [105.4941379, 21.8069033],
                [105.4937934, 21.8059092],
                [105.4932363, 21.8050351],
                [105.4931064, 21.8044583],
                [105.4931902, 21.8040003],
                [105.4937414, 21.8031627],
                [105.4937829, 21.8030936],
                [105.4950341, 21.8010094],
                [105.495288, 21.8004912],
                [105.4953078, 21.8000333],
                [105.495303, 21.7986601],
                [105.4951931, 21.7976653],
                [105.4953191, 21.7971474],
                [105.4965518, 21.7961486],
                [105.4969973, 21.79559],
                [105.4973491, 21.794644],
                [105.4977231, 21.7937093],
                [105.4979473, 21.7930921],
                [105.4978492, 21.7920002],
                [105.4973734, 21.7907333],
                [105.4965956, 21.7894498],
                [105.4963073, 21.7878829],
                [105.4960773, 21.7868267],
                [105.4957681, 21.7851846],
                [105.4948429, 21.7825602],
                [105.4942627, 21.7811886],
                [105.4938341, 21.7804734],
                [105.4935131, 21.7800564],
                [105.4933837, 21.7795991],
                [105.4934034, 21.7791413],
                [105.4938424, 21.7766721],
                [105.4945178, 21.7747792],
                [105.4948995, 21.7742407],
                [105.4954305, 21.7737615],
                [105.4977465, 21.7718439],
                [105.4998092, 21.7706435],
                [105.5003081, 21.7705148],
                [105.5018895, 21.7705575],
                [105.5026435, 21.7706142],
                [105.5035729, 21.770684],
                [105.5049751, 21.7709897],
                [105.5062664, 21.7712268],
                [105.5071155, 21.7714998],
                [105.5083709, 21.7720126],
                [105.509737, 21.7725249],
                [105.5110293, 21.7730032],
                [105.5120628, 21.7733099],
                [105.5129848, 21.7733415],
                [105.5139436, 21.773373],
                [105.5150507, 21.7736451],
                [105.515458, 21.774126],
                [105.5159746, 21.7742277],
                [105.5165638, 21.7740192],
                [105.5177397, 21.772879],
                [105.5186957, 21.7721527],
                [105.5199473, 21.7715632],
                [105.5211993, 21.7711116],
                [105.5222306, 21.7707984],
                [105.5231502, 21.7701755],
                [105.5245103, 21.7690346],
                [105.5253554, 21.7682398],
                [105.5257988, 21.7678184],
                [105.5257892, 21.7677316],
                [105.5255345, 21.7667927],
                [105.5251985, 21.7656228],
                [105.5248636, 21.7647972],
                [105.5241967, 21.7639039],
                [105.5230868, 21.7628741],
                [105.5217924, 21.7617761],
                [105.5208291, 21.7604704],
                [105.5194933, 21.7581326],
                [105.5189389, 21.757721],
                [105.5187143, 21.7567918],
                [105.5183057, 21.7559664],
                [105.5178247, 21.7554858],
                [105.5171608, 21.7553845],
                [105.5157592, 21.7552167],
                [105.5148367, 21.7550129],
                [105.5137664, 21.7546718],
                [105.5130644, 21.7542608],
                [105.5122135, 21.7534368],
                [105.511362, 21.7524406],
                [105.5106582, 21.7515129],
                [105.5097711, 21.7508612],
                [105.5090672, 21.7503766],
                [105.509232, 21.7495713],
                [105.5095235, 21.7485888],
                [105.5109822, 21.7455537],
                [105.5113, 21.7443671],
                [105.5118465, 21.7436046],
                [105.5123181, 21.7430724],
                [105.5133195, 21.7424146],
                [105.5144909, 21.741597],
                [105.5156242, 21.7407796],
                [105.5163221, 21.7399812],
                [105.5165649, 21.7390249],
                [105.516238, 21.7376458],
                [105.5159693, 21.7366381],
                [105.5156838, 21.7362497],
                [105.5149998, 21.7356149],
                [105.513879, 21.7346453],
                [105.5126829, 21.7337819],
                [105.5119985, 21.7330409],
                [105.5110684, 21.7324422],
                [105.5100061, 21.73195],
                [105.5091519, 21.7313511],
                [105.5087151, 21.7309986],
                [105.5079948, 21.7308061],
                [105.5067635, 21.7307038],
                [105.5059491, 21.7306886],
                [105.5052288, 21.7304784],
                [105.5046029, 21.7301972],
                [105.5041848, 21.7297739],
                [105.5038033, 21.7289965],
                [105.5031177, 21.7279015],
                [105.5026215, 21.7267706],
                [105.5022019, 21.7259226],
                [105.5018889, 21.725615],
                [105.5002309, 21.7242652],
                [105.499274, 21.7233407],
                [105.4990683, 21.7228509],
                [105.4990663, 21.7222516],
                [105.4992477, 21.7199353],
                [105.499302, 21.7187908],
                [105.4992708, 21.7181915],
                [105.4989201, 21.7178657],
                [105.4984823, 21.7176763],
                [105.497637, 21.7175971],
                [105.497083, 21.7174897],
                [105.4967621, 21.7173818],
                [105.4964696, 21.7170556],
                [105.4962633, 21.7163752],
                [105.4964032, 21.7146856],
                [105.496298, 21.7137596],
                [105.4950693, 21.7123193],
                [105.4929926, 21.7100097],
                [105.4914553, 21.7077803],
                [105.4903347, 21.7038059],
                [105.4899252, 21.7033439],
                [105.4894581, 21.7030729],
                [105.4887871, 21.7028024],
                [105.4879414, 21.702587],
                [105.4871545, 21.7025075],
                [105.4864261, 21.7025097],
                [105.4856687, 21.7025664],
                [105.4847365, 21.7025964],
                [105.4842991, 21.7024887],
                [105.4834662, 21.7017282],
                [105.4812741, 21.6996368],
                [105.479026, 21.6982266],
                [105.4781217, 21.6979023],
                [105.4776836, 21.6975766],
                [105.4775074, 21.6971412],
                [105.4775053, 21.6964874],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [104.3759531, 20.4671481],
                [104.3761247, 20.4663033],
                [104.3778176, 20.4633237],
                [104.3781877, 20.4618454],
                [104.3781361, 20.4607619],
                [104.3782782, 20.460234],
                [104.3791253, 20.4585725],
                [104.3799986, 20.4573603],
                [104.380142, 20.4565153],
                [104.3802884, 20.454957],
                [104.3802653, 20.453715],
                [104.3809161, 20.4521057],
                [104.3810596, 20.4512608],
                [104.3810621, 20.4506267],
                [104.3810388, 20.4494904],
                [104.3806774, 20.4487493],
                [104.3802864, 20.4482933],
                [104.3791998, 20.4470267],
                [104.378085, 20.4456223],
                [104.3769437, 20.4438216],
                [104.3767504, 20.443134],
                [104.376892, 20.4427382],
                [104.3770897, 20.4423161],
                [104.3773713, 20.4419738],
                [104.3778769, 20.4416056],
                [104.378494, 20.4413964],
                [104.3789696, 20.4415567],
                [104.3805054, 20.4427511],
                [104.381624, 20.4432043],
                [104.3822959, 20.4433124],
                [104.3832492, 20.443078],
                [104.3839497, 20.4430275],
                [104.3849861, 20.4430312],
                [104.3862181, 20.4431677],
                [104.3868353, 20.4429057],
                [104.3879024, 20.4422225],
                [104.3890542, 20.441381],
                [104.3907933, 20.4407794],
                [104.3918304, 20.4405717],
                [104.39228, 20.4402298],
                [104.3930946, 20.4396513],
                [104.3934051, 20.4390447],
                [104.3936319, 20.4383585],
                [104.3941675, 20.4374885],
                [104.3964434, 20.4356997],
                [104.3975119, 20.4345937],
                [104.3983263, 20.434068],
                [104.3997009, 20.4335179],
                [104.402507, 20.4322065],
                [104.4047248, 20.430893],
                [104.4069157, 20.4292625],
                [104.4084869, 20.4285544],
                [104.4089084, 20.4281859],
                [104.4095008, 20.4271045],
                [104.410205, 20.4260237],
                [104.4105979, 20.4258137],
                [104.4112145, 20.42571],
                [104.4122502, 20.4258986],
                [104.4144603, 20.4265401],
                [104.4155518, 20.4268083],
                [104.4149822, 20.4261931],
                [104.4140162, 20.4252622],
                [104.4138232, 20.4250133],
                [104.4135478, 20.4245681],
                [104.4133139, 20.4241753],
                [104.4133013, 20.4238225],
                [104.4133582, 20.4234177],
                [104.4133599, 20.4229734],
                [104.4132921, 20.4226073],
                [104.4132098, 20.4223979],
                [104.4130035, 20.4220183],
                [104.4123816, 20.4216505],
                [104.4112916, 20.420523],
                [104.4101179, 20.4194974],
                [104.4093374, 20.4185171],
                [104.408671, 20.4176134],
                [104.4084641, 20.4173906],
                [104.4082712, 20.4171286],
                [104.4082028, 20.4169193],
                [104.4081625, 20.4165794],
                [104.4083034, 20.4159528],
                [104.4083741, 20.4156002],
                [104.408223, 20.4152599],
                [104.4079335, 20.4148931],
                [104.407575, 20.4144999],
                [104.4071745, 20.4141588],
                [104.406663, 20.4139088],
                [104.4063595, 20.4136203],
                [104.4060842, 20.4131752],
                [104.4059481, 20.4125345],
                [104.405863, 20.4116356],
                [104.4058962, 20.4102619],
                [104.4059589, 20.4096991],
                [104.4059607, 20.4092418],
                [104.4059067, 20.4088758],
                [104.4054105, 20.4082469],
                [104.4053149, 20.4078937],
                [104.405193, 20.4071878],
                [104.4051957, 20.4064691],
                [104.4051838, 20.4059726],
                [104.4051301, 20.4055021],
                [104.4050926, 20.4044827],
                [104.4045323, 20.4025208],
                [104.4045908, 20.401711],
                [104.4047996, 20.4014242],
                [104.405011, 20.4004579],
                [104.4054036, 20.399205],
                [104.4055572, 20.3988918],
                [104.4055856, 20.398709],
                [104.4057698, 20.398517],
                [104.4060195, 20.3984198],
                [104.4062903, 20.3982247],
                [104.4065828, 20.3977749],
                [104.4068547, 20.3972858],
                [104.4073994, 20.3960922],
                [104.4077562, 20.3951134],
                [104.4077568, 20.3949761],
                [104.4076949, 20.394878],
                [104.4070721, 20.3947583],
                [104.406117, 20.3946375],
                [104.4059305, 20.3945192],
                [104.4058687, 20.3944014],
                [104.4060156, 20.3940099],
                [104.4065635, 20.3933208],
                [104.4069398, 20.3926754],
                [104.4071617, 20.3917059],
                [104.407665, 20.3904435],
                [104.4076385, 20.3892381],
                [104.4076405, 20.3887089],
                [104.4077665, 20.3883565],
                [104.4081121, 20.3876228],
                [104.4091762, 20.3863621],
                [104.4121861, 20.3834839],
                [104.4141581, 20.3810651],
                [104.4164076, 20.3793969],
                [104.4185489, 20.3781246],
                [104.4195927, 20.3772151],
                [104.4201598, 20.376907],
                [104.4209651, 20.3763413],
                [104.4214398, 20.3763256],
                [104.4218411, 20.3764131],
                [104.4222604, 20.3765695],
                [104.4227516, 20.3770017],
                [104.4230789, 20.3773645],
                [104.4234245, 20.3777101],
                [104.4236426, 20.3779521],
                [104.423624, 20.3780725],
                [104.424554, 20.378334],
                [104.4254478, 20.3785093],
                [104.4259406, 20.3785281],
                [104.4263825, 20.3787664],
                [104.4272587, 20.3787951],
                [104.4281904, 20.3785915],
                [104.4293698, 20.3780527],
                [104.43022, 20.3776938],
                [104.4304605, 20.3776638],
                [104.4311809, 20.3772763],
                [104.4322892, 20.3772209],
                [104.4337144, 20.3770966],
                [104.4350612, 20.3771378],
                [104.4358229, 20.3770115],
                [104.4361756, 20.3766072],
                [104.4364901, 20.3760372],
                [104.4371544, 20.3758552],
                [104.4379747, 20.3757289],
                [104.4388716, 20.3759896],
                [104.440683, 20.3771005],
                [104.4415798, 20.3769922],
                [104.4428902, 20.3768419],
                [104.4440359, 20.3770001],
                [104.4458089, 20.3772375],
                [104.4467086, 20.3774978],
                [104.4470607, 20.37822],
                [104.4472124, 20.3798101],
                [104.4491658, 20.3793927],
                [104.4504753, 20.3789733],
                [104.4519412, 20.3784254],
                [104.4527437, 20.3777833],
                [104.4530971, 20.3772318],
                [104.4531977, 20.376385],
                [104.4535909, 20.3755574],
                [104.4536128, 20.3749128],
                [104.4537312, 20.3745081],
                [104.4543962, 20.3741417],
                [104.4547289, 20.3739034],
                [104.4551803, 20.373205],
                [104.4558652, 20.3727282],
                [104.4567634, 20.3726021],
                [104.4573692, 20.3724382],
                [104.4581126, 20.3719434],
                [104.4587574, 20.3717612],
                [104.4607873, 20.371749],
                [104.4622325, 20.3715324],
                [104.463288, 20.3711305],
                [104.4636195, 20.3712051],
                [104.464436, 20.3721654],
                [104.4649811, 20.3725723],
                [104.4656247, 20.3727401],
                [104.4664639, 20.372761],
                [104.4667578, 20.3724488],
                [104.4668374, 20.3720254],
                [104.4665656, 20.3715826],
                [104.4664313, 20.3709191],
                [104.4666482, 20.3702567],
                [104.4676667, 20.3692654],
                [104.4681369, 20.3687327],
                [104.4686447, 20.368642],
                [104.4698546, 20.3687194],
                [104.4702448, 20.3687757],
                [104.4712383, 20.3693497],
                [104.4719603, 20.3694256],
                [104.4732691, 20.3690612],
                [104.4744797, 20.3689543],
                [104.4751816, 20.3692143],
                [104.4757654, 20.3696949],
                [104.4763485, 20.3704333],
                [104.476972, 20.3707852],
                [104.4784134, 20.3716551],
                [104.479252, 20.3718971],
                [104.4801453, 20.3717414],
                [104.4809147, 20.3715261],
                [104.4819904, 20.3716744],
                [104.4825083, 20.3719661],
                [104.4834471, 20.3728756],
                [104.4838301, 20.3733482],
                [104.4842685, 20.3744375],
                [104.4845173, 20.3747466],
                [104.4861495, 20.3751322],
                [104.4870712, 20.3753887],
                [104.487915, 20.3759533],
                [104.4883752, 20.3762812],
                [104.4891058, 20.3762108],
                [104.4898167, 20.3762673],
                [104.4907761, 20.3767235],
                [104.4914851, 20.3773602],
                [104.4924622, 20.378324],
                [104.493363, 20.3790883],
                [104.493899, 20.3797428],
                [104.4942432, 20.3802696],
                [104.4945281, 20.3812678],
                [104.4946222, 20.3819752],
                [104.4954465, 20.3825942],
                [104.4964435, 20.3833406],
                [104.4979034, 20.3835623],
                [104.4988843, 20.3833657],
                [104.4998082, 20.3829513],
                [104.5002696, 20.3829163],
                [104.5006728, 20.3830443],
                [104.5016516, 20.3835005],
                [104.5018741, 20.383566],
                [104.503207, 20.3839583],
                [104.5039938, 20.3843052],
                [104.5058748, 20.3851265],
                [104.5064508, 20.3853095],
                [104.5081808, 20.3852418],
                [104.5096802, 20.3851916],
                [104.5109294, 20.3852315],
                [104.511429, 20.3852509],
                [104.5123118, 20.3857068],
                [104.5129058, 20.3862525],
                [104.513383, 20.387105],
                [104.5137818, 20.3878262],
                [104.5148662, 20.3889515],
                [104.5160685, 20.3897999],
                [104.5171904, 20.39031],
                [104.518232, 20.3904972],
                [104.5195831, 20.3906392],
                [104.5204132, 20.3907183],
                [104.5208686, 20.3909039],
                [104.5222173, 20.3918294],
                [104.5229656, 20.3921079],
                [104.5239568, 20.3928019],
                [104.5247198, 20.3935414],
                [104.5251747, 20.39385],
                [104.5256135, 20.3941585],
                [104.5258654, 20.3945509],
                [104.5265302, 20.3961846],
                [104.5270083, 20.3972721],
                [104.5271512, 20.3984575],
                [104.5275453, 20.3996635],
                [104.5281474, 20.4012847],
                [104.5284379, 20.4020756],
                [104.5282863, 20.4037737],
                [104.5281458, 20.4048973],
                [104.5284044, 20.4058629],
                [104.528497, 20.4067383],
                [104.5284004, 20.4072318],
                [104.5280426, 20.4075675],
                [104.527755, 20.4082623],
                [104.5272775, 20.4088894],
                [104.5271327, 20.4095172],
                [104.5272505, 20.4099215],
                [104.527627, 20.4112465],
                [104.5277076, 20.4124518],
                [104.5275684, 20.4130986],
                [104.5277221, 20.4133093],
                [104.5280821, 20.4133589],
                [104.5290938, 20.4133454],
                [104.5302425, 20.4134617],
                [104.5308257, 20.4133985],
                [104.5314257, 20.4134487],
                [104.5320595, 20.4136608],
                [104.5327788, 20.4140187],
                [104.5334246, 20.4144532],
                [104.5339266, 20.4145928],
                [104.5346164, 20.4149304],
                [104.5349921, 20.4152672],
                [104.5353272, 20.4152681],
                [104.5364163, 20.4151128],
                [104.5371311, 20.4152251],
                [104.5373977, 20.4150664],
                [104.5376618, 20.4146773],
                [104.5380396, 20.4139872],
                [104.538191, 20.41358],
                [104.5383806, 20.4129604],
                [104.5385713, 20.4120394],
                [104.5387997, 20.4110123],
                [104.5391031, 20.4100562],
                [104.539499, 20.4095788],
                [104.5401016, 20.4090488],
                [104.5407604, 20.4085898],
                [104.5419472, 20.4083174],
                [104.5429258, 20.4084663],
                [104.5435158, 20.4084093],
                [104.5442616, 20.408177],
                [104.5450681, 20.4083548],
                [104.545688, 20.4086785],
                [104.5470537, 20.4087406],
                [104.5480164, 20.4086553],
                [104.5487605, 20.4089792],
                [104.5493179, 20.4094198],
                [104.5504343, 20.4098326],
                [104.5514268, 20.4100986],
                [104.5521709, 20.4104519],
                [104.5527278, 20.4111267],
                [104.5530678, 20.4116252],
                [104.554246, 20.4121551],
                [104.555858, 20.4129498],
                [104.5574012, 20.4133636],
                [104.5583323, 20.4134537],
                [104.5588603, 20.4133966],
                [104.5594196, 20.4132078],
                [104.560088, 20.4128434],
                [104.5604297, 20.4127564],
                [104.5608953, 20.4128015],
                [104.5613448, 20.4130515],
                [104.5619949, 20.413668],
                [104.5632193, 20.4143591],
                [104.563396, 20.4149451],
                [104.5636883, 20.4158973],
                [104.5639281, 20.4161761],
                [104.5642845, 20.4163527],
                [104.5649984, 20.416413],
                [104.5660697, 20.4163717],
                [104.5671565, 20.4162719],
                [104.5680102, 20.4162594],
                [104.5687704, 20.4163784],
                [104.5693598, 20.4165848],
                [104.5697167, 20.4166149],
                [104.5704931, 20.416529],
                [104.5718894, 20.4167814],
                [104.5731932, 20.4168138],
                [104.574343, 20.4164068],
                [104.5758665, 20.4155761],
                [104.5772035, 20.414789],
                [104.5775141, 20.4147459],
                [104.5783352, 20.4153333],
                [104.5788935, 20.4155103],
                [104.5801046, 20.4153961],
                [104.5812239, 20.414784],
                [104.5818611, 20.414522],
                [104.5824046, 20.4144353],
                [104.5834285, 20.4146282],
                [104.5856621, 20.4152483],
                [104.5864996, 20.4154845],
                [104.587229, 20.415574],
                [104.5877569, 20.4155313],
                [104.5887046, 20.4152262],
                [104.5892021, 20.41492],
                [104.5895593, 20.4148769],
                [104.590304, 20.4149665],
                [104.5912502, 20.4152469],
                [104.5923517, 20.4154543],
                [104.5934221, 20.4157349],
                [104.5937479, 20.4157942],
                [104.5940118, 20.4158094],
                [104.5944157, 20.4156787],
                [104.5946798, 20.4156207],
                [104.5950829, 20.4158119],
                [104.5956252, 20.4161645],
                [104.595858, 20.4161943],
                [104.596541, 20.4161666],
                [104.5978291, 20.4163014],
                [104.5984341, 20.4164491],
                [104.5989768, 20.4166553],
                [104.5994145, 20.4170954],
                [104.6001079, 20.4175835],
                [104.6004469, 20.4177299],
                [104.6007889, 20.4178169],
                [104.601179, 20.4181089],
                [104.6016506, 20.4187113],
                [104.6022049, 20.4191006],
                [104.602883, 20.4194321],
                [104.6034783, 20.4199377],
                [104.6041769, 20.4202496],
                [104.6049572, 20.4208334],
                [104.6053479, 20.420912],
                [104.6057184, 20.4208352],
                [104.6062536, 20.4207006],
                [104.6066031, 20.4207402],
                [104.607172, 20.4209505],
                [104.6077471, 20.4213011],
                [104.6081171, 20.421457],
                [104.6088566, 20.4218662],
                [104.6098365, 20.4225421],
                [104.6110281, 20.423185],
                [104.6118284, 20.4239822],
                [104.6126902, 20.4248572],
                [104.6130577, 20.4253959],
                [104.6133648, 20.4260174],
                [104.6136311, 20.4264449],
                [104.6140829, 20.4267951],
                [104.6144312, 20.4273584],
                [104.6148207, 20.4279413],
                [104.6149639, 20.4281325],
                [104.6150859, 20.4286758],
                [104.6150839, 20.4294503],
                [104.6154316, 20.430266],
                [104.6157693, 20.4310133],
                [104.6158096, 20.4313917],
                [104.6157466, 20.4318668],
                [104.6157668, 20.4320124],
                [104.6159722, 20.4321582],
                [104.6167533, 20.4324317],
                [104.617195, 20.4327528],
                [104.6177584, 20.4336464],
                [104.6181894, 20.434074],
                [104.6183015, 20.4344817],
                [104.618311, 20.4347921],
                [104.6182483, 20.4351994],
                [104.6182887, 20.4355584],
                [104.6184012, 20.4357819],
                [104.6184108, 20.4360632],
                [104.6183071, 20.4363927],
                [104.6183373, 20.4366353],
                [104.6184497, 20.4369653],
                [104.6186241, 20.4371985],
                [104.6186851, 20.4374896],
                [104.6185813, 20.4378192],
                [104.6184159, 20.438139],
                [104.618189, 20.4384295],
                [104.617962, 20.4386618],
                [104.617848, 20.4390398],
                [104.617836, 20.4397187],
                [104.617897, 20.4399905],
                [104.6180915, 20.440408],
                [104.6185836, 20.4411075],
                [104.6187782, 20.4414669],
                [104.6187057, 20.4416413],
                [104.6182215, 20.4419216],
                [104.6172331, 20.4422395],
                [104.6164714, 20.4424513],
                [104.6156173, 20.4425368],
                [104.6150307, 20.4426616],
                [104.6144954, 20.4427768],
                [104.6141657, 20.4429893],
                [104.613949, 20.4432508],
                [104.6138351, 20.4435804],
                [104.6137932, 20.4438712],
                [104.6138018, 20.4445308],
                [104.6138004, 20.4450838],
                [104.6137276, 20.4454329],
                [104.6135826, 20.445772],
                [104.6132733, 20.4460818],
                [104.6128709, 20.4465464],
                [104.61285, 20.4466725],
                [104.6129013, 20.4467405],
                [104.6136522, 20.4468198],
                [104.6140941, 20.4469565],
                [104.6144644, 20.4470157],
                [104.6147725, 20.4472201],
                [104.6151113, 20.4474632],
                [104.6153575, 20.4477547],
                [104.6154804, 20.4479879],
                [104.6155722, 20.448279],
                [104.6156954, 20.4484442],
                [104.6159008, 20.4485417],
                [104.6161267, 20.4487264],
                [104.6163832, 20.4489742],
                [104.6192613, 20.4497098],
                [104.6202226, 20.4502097],
                [104.621152, 20.4511193],
                [104.6217095, 20.4517061],
                [104.6217391, 20.4522624],
                [104.6212706, 20.4533738],
                [104.6208964, 20.4540171],
                [104.6202428, 20.4546305],
                [104.6202107, 20.4550402],
                [104.620706, 20.4556856],
                [104.6219459, 20.4565957],
                [104.6227831, 20.4570953],
                [104.6238685, 20.4577125],
                [104.6242704, 20.4584016],
                [104.6247966, 20.4590761],
                [104.6250748, 20.4596476],
                [104.6251047, 20.4601014],
                [104.6249793, 20.4605696],
                [104.6248075, 20.4610083],
                [104.624838, 20.461228],
                [104.6251788, 20.4615507],
                [104.6257061, 20.4618154],
                [104.6263889, 20.4619486],
                [104.6268802, 20.4620361],
                [104.6273047, 20.4621117],
                [104.6289029, 20.4625543],
                [104.6300046, 20.4628933],
                [104.6307807, 20.4629828],
                [104.6314949, 20.4629989],
                [104.6322398, 20.4631909],
                [104.6326275, 20.4633966],
                [104.6330918, 20.4640416],
                [104.6334631, 20.4646279],
                [104.6338195, 20.4649069],
                [104.6342385, 20.4650395],
                [104.6348436, 20.4652164],
                [104.6351845, 20.4655245],
                [104.6353857, 20.4657885],
                [104.6355099, 20.4658034],
                [104.6362254, 20.4652925],
                [104.6368469, 20.4651036],
                [104.637499, 20.4651049],
                [104.6381971, 20.4654138],
                [104.6388939, 20.4662788],
                [104.6397291, 20.4676419],
                [104.6403181, 20.468097],
                [104.640768, 20.4682589],
                [104.6413116, 20.4682162],
                [104.6422198, 20.4682766],
                [104.6430274, 20.4682489],
                [104.6435551, 20.4683085],
                [104.6442685, 20.4687198],
                [104.6451357, 20.4697463],
                [104.6462813, 20.4712418],
                [104.6472731, 20.4720929],
                [104.6484524, 20.4724758],
                [104.6498494, 20.4726835],
                [104.6511213, 20.4732717],
                [104.6520524, 20.4735956],
                [104.653915, 20.4739214],
                [104.6554984, 20.4741586],
                [104.6567812, 20.4742856],
                [104.6572829, 20.4742159],
                [104.6576441, 20.4742677],
                [104.6583478, 20.4746097],
                [104.6595389, 20.4751231],
                [104.6603158, 20.4751076],
                [104.6609847, 20.4749044],
                [104.6614554, 20.4744283],
                [104.6614924, 20.4740197],
                [104.6611688, 20.4733037],
                [104.6595852, 20.4705239],
                [104.6595858, 20.4702684],
                [104.6597125, 20.4701152],
                [104.6599837, 20.4700307],
                [104.660327, 20.4700314],
                [104.6609769, 20.4702029],
                [104.663108, 20.4704455],
                [104.6641367, 20.4708224],
                [104.6646786, 20.4709256],
                [104.6652391, 20.4706372],
                [104.6669568, 20.4698737],
                [104.6682592, 20.4691096],
                [104.6695444, 20.4678174],
                [104.6701981, 20.4662004],
                [104.6706709, 20.4647704],
                [104.6707266, 20.4640721],
                [104.6704385, 20.4636288],
                [104.6702039, 20.4634919],
                [104.669879, 20.463406],
                [104.6686876, 20.4630461],
                [104.6676588, 20.4626182],
                [104.6668109, 20.4621566],
                [104.6653314, 20.4613531],
                [104.6639063, 20.4604986],
                [104.6635284, 20.4598846],
                [104.6633863, 20.4587261],
                [104.6635511, 20.4577384],
                [104.6637342, 20.4565803],
                [104.6639708, 20.4557972],
                [104.6640085, 20.4550136],
                [104.6639016, 20.454434],
                [104.6639217, 20.453446],
                [104.6640855, 20.4528841],
                [104.6647325, 20.4518788],
                [104.6649925, 20.4518038],
                [104.6656523, 20.4518051],
                [104.6664325, 20.4516369],
                [104.6672931, 20.4512237],
                [104.6682936, 20.4508108],
                [104.6695966, 20.4505499],
                [104.6705068, 20.4502603],
                [104.6729456, 20.4495525],
                [104.6732892, 20.4493589],
                [104.673599, 20.4490035],
                [104.6741671, 20.4481628],
                [104.674958, 20.4475005],
                [104.6759727, 20.4465312],
                [104.6765404, 20.4458848],
                [104.6773655, 20.4452712],
                [104.6778464, 20.4451102],
                [104.6788422, 20.4449501],
                [104.6798035, 20.4447254],
                [104.6804393, 20.4443866],
                [104.6817282, 20.4435149],
                [104.6824501, 20.4430144],
                [104.682828, 20.442837],
                [104.6834119, 20.4426924],
                [104.6841844, 20.4425643],
                [104.6854892, 20.4423401],
                [104.6856955, 20.4422271],
                [104.6859024, 20.4417418],
                [104.6860421, 20.4405442],
                [104.6859929, 20.4393786],
                [104.6860292, 20.4384398],
                [104.686305, 20.4378251],
                [104.6867009, 20.4373241],
                [104.6875262, 20.4365001],
                [104.68806, 20.4356593],
                [104.6881986, 20.4349797],
                [104.6881746, 20.4341137],
                [104.688188, 20.4332313],
                [104.6883316, 20.4328352],
                [104.6890507, 20.4322109],
                [104.6903729, 20.4315414],
                [104.6913335, 20.430974],
                [104.691677, 20.4308936],
                [104.6923858, 20.4308675],
                [104.6944107, 20.4308711],
                [104.6953892, 20.4307757],
                [104.6959387, 20.43055],
                [104.6964544, 20.43013],
                [104.6970221, 20.4293702],
                [104.697487, 20.4285779],
                [104.6985041, 20.4262325],
                [104.699244, 20.4251169],
                [104.6993659, 20.4241944],
                [104.699815, 20.4226897],
                [104.7000391, 20.4221883],
                [104.7006406, 20.4217199],
                [104.7015009, 20.420556],
                [104.7017047, 20.4202615],
                [104.702172, 20.4195858],
                [104.7030332, 20.4179524],
                [104.7037733, 20.4168044],
                [104.7045297, 20.4160125],
                [104.7052861, 20.4153177],
                [104.7068763, 20.4135153],
                [104.7080287, 20.4121251],
                [104.7095933, 20.4104281],
                [104.7107791, 20.4094588],
                [104.7119811, 20.4090238],
                [104.7131825, 20.4088477],
                [104.714281, 20.4086877],
                [104.7157911, 20.4085767],
                [104.7168136, 20.4083379],
                [104.7189156, 20.4074867],
                [104.7195748, 20.4072545],
                [104.7197812, 20.4070025],
                [104.7197406, 20.4067109],
                [104.7193114, 20.4059316],
                [104.719161, 20.4052048],
                [104.7189526, 20.4042906],
                [104.7187435, 20.4037508],
                [104.7184059, 20.4032878],
                [104.7182085, 20.4028031],
                [104.7180811, 20.4022304],
                [104.7179069, 20.4017346],
                [104.7175696, 20.4011065],
                [104.717139, 20.4004231],
                [104.7165682, 20.3998498],
                [104.7160322, 20.3993864],
                [104.7152631, 20.3988457],
                [104.7146805, 20.3983383],
                [104.7142377, 20.3980182],
                [104.7139811, 20.3979297],
                [104.7135725, 20.3979952],
                [104.7128836, 20.3982473],
                [104.7119025, 20.3986199],
                [104.7113647, 20.3991144],
                [104.7109909, 20.399301],
                [104.7106757, 20.3993445],
                [104.7101973, 20.3992887],
                [104.7098126, 20.3991338],
                [104.7091246, 20.3988134],
                [104.7085998, 20.3986915],
                [104.7081212, 20.3987567],
                [104.7075022, 20.3989869],
                [104.7070349, 20.3992724],
                [104.7066025, 20.3995579],
                [104.7062056, 20.3996783],
                [104.7057855, 20.3996776],
                [104.7054122, 20.3995889],
                [104.7045725, 20.3993012],
                [104.7034061, 20.3989799],
                [104.7027527, 20.3988906],
                [104.7024146, 20.3987469],
                [104.7018781, 20.3985698],
                [104.7013644, 20.3985798],
                [104.7004418, 20.3989306],
                [104.6997529, 20.3991386],
                [104.699461, 20.399171],
                [104.6985977, 20.3990814],
                [104.6977808, 20.3991021],
                [104.6970335, 20.399299],
                [104.6966365, 20.3994303],
                [104.696321, 20.399661],
                [104.6956663, 20.4002104],
                [104.6950588, 20.4005615],
                [104.6948017, 20.4007482],
                [104.6944398, 20.4008138],
                [104.6939963, 20.400813],
                [104.6935763, 20.4007681],
                [104.6927599, 20.4005025],
                [104.6920485, 20.4003361],
                [104.6914065, 20.400401],
                [104.6908225, 20.4006202],
                [104.6904017, 20.4009717],
                [104.6899111, 20.401191],
                [104.689234, 20.401322],
                [104.6886501, 20.401475],
                [104.6877979, 20.4016827],
                [104.6865481, 20.4021098],
                [104.6856954, 20.4025377],
                [104.6851694, 20.402933],
                [104.6847016, 20.4033836],
                [104.684211, 20.403658],
                [104.6837324, 20.4037122],
                [104.6835107, 20.4037006],
                [104.6833241, 20.4036123],
                [104.6831614, 20.4033148],
                [104.6830227, 20.402676],
                [104.6829548, 20.4015747],
                [104.6828178, 20.400044],
                [104.6826447, 20.3991629],
                [104.6824017, 20.3981386],
                [104.682147, 20.3971583],
                [104.6816468, 20.3963096],
                [104.681251, 20.3958354],
                [104.6810877, 20.3957691],
                [104.6805043, 20.3957791],
                [104.6794656, 20.3958212],
                [104.6786719, 20.3959629],
                [104.6782516, 20.3959951],
                [104.6779483, 20.3959396],
                [104.677657, 20.3957297],
                [104.6773195, 20.3953218],
                [104.6769622, 20.3949013],
                [104.6765915, 20.3944434],
                [104.6763394, 20.3940959],
                [104.6759867, 20.3936634],
                [104.6757419, 20.3933244],
                [104.6749911, 20.3912745],
                [104.6748405, 20.3910783],
                [104.6745953, 20.3909176],
                [104.6743312, 20.3908102],
                [104.6728122, 20.3904779],
                [104.6721896, 20.3902808],
                [104.671756, 20.3900127],
                [104.671153, 20.3894951],
                [104.670814, 20.389049],
                [104.6704764, 20.3880152],
                [104.6701374, 20.3876406],
                [104.6698547, 20.3874263],
                [104.6696, 20.3873635],
                [104.6691094, 20.3872912],
                [104.6674098, 20.3875018],
                [104.6667869, 20.3874294],
                [104.6664098, 20.3872327],
                [104.6633762, 20.3845015],
                [104.6629029, 20.3842086],
                [104.6623557, 20.3838917],
                [104.6619297, 20.3837758],
                [104.6605603, 20.3836296],
                [104.6593733, 20.383541],
                [104.6587647, 20.383425],
                [104.6569831, 20.3832049],
                [104.6565466, 20.383157],
                [104.6562882, 20.3830751],
                [104.6560728, 20.3829736],
                [104.6554959, 20.3823221],
                [104.6550303, 20.3816917],
                [104.6547863, 20.3813976],
                [104.6544975, 20.3812081],
                [104.6540083, 20.3811861],
                [104.6532745, 20.3810799],
                [104.652363, 20.3809102],
                [104.6520073, 20.3807836],
                [104.6517631, 20.3805733],
                [104.6515193, 20.3802161],
                [104.6511413, 20.3801524],
                [104.6495176, 20.3801911],
                [104.6489392, 20.380211],
                [104.6476282, 20.3796418],
                [104.6468058, 20.3793675],
                [104.6466282, 20.3792621],
                [104.6468067, 20.3790108],
                [104.6482577, 20.3766428],
                [104.6486158, 20.3757414],
                [104.6485718, 20.3754685],
                [104.6479499, 20.3750896],
                [104.6463064, 20.3740582],
                [104.6457957, 20.3738297],
                [104.6451288, 20.3735313],
                [104.6448403, 20.373258],
                [104.6443518, 20.3729423],
                [104.6438182, 20.3728573],
                [104.6434175, 20.3730033],
                [104.6425273, 20.3732533],
                [104.6418147, 20.3736294],
                [104.6416368, 20.3736081],
                [104.640548, 20.3731652],
                [104.6396143, 20.3729745],
                [104.6388702, 20.3725847],
                [104.6386261, 20.3723221],
                [104.6383042, 20.3720906],
                [104.6379268, 20.3717855],
                [104.6376381, 20.3716067],
                [104.6369377, 20.3714898],
                [104.6356818, 20.3712144],
                [104.6349371, 20.371087],
                [104.6341371, 20.3708126],
                [104.6325475, 20.3705154],
                [104.6314693, 20.3703662],
                [104.6307691, 20.370197],
                [104.6304027, 20.3699654],
                [104.6301809, 20.3696922],
                [104.6299152, 20.3692406],
                [104.6295163, 20.3686628],
                [104.6292664, 20.3684315],
                [104.6291786, 20.3679488],
                [104.6291141, 20.3670568],
                [104.6289927, 20.3666685],
                [104.6286823, 20.3662797],
                [104.6284045, 20.3661322],
                [104.6281379, 20.3660582],
                [104.6272928, 20.3659935],
                [104.6267592, 20.3659504],
                [104.6262922, 20.3659075],
                [104.6253356, 20.3660312],
                [104.6246015, 20.3660926],
                [104.6241346, 20.3660707],
                [104.6236677, 20.3660067],
                [104.6229336, 20.366047],
                [104.6225666, 20.3660778],
                [104.622222, 20.3660037],
                [104.6218782, 20.3656672],
                [104.6214011, 20.365215],
                [104.6211464, 20.3647737],
                [104.6209805, 20.3644063],
                [104.6209146, 20.3640705],
                [104.6208263, 20.3638081],
                [104.6205935, 20.3635452],
                [104.6203167, 20.3629992],
                [104.6201619, 20.3626316],
                [104.6200067, 20.3624739],
                [104.6196846, 20.3623369],
                [104.6191511, 20.3622098],
                [104.6184954, 20.3620721],
                [104.6178623, 20.3617244],
                [104.6175295, 20.3614719],
                [104.6172856, 20.3611462],
                [104.6171198, 20.3607681],
                [104.616932, 20.3602642],
                [104.6167446, 20.3595714],
                [104.6166901, 20.3591098],
                [104.6166021, 20.3587843],
                [104.6163473, 20.3583641],
                [104.6160712, 20.3575873],
                [104.6159837, 20.3570102],
                [104.6159631, 20.3563388],
                [104.6159092, 20.3556567],
                [104.6159109, 20.3552911],
                [104.6159141, 20.3552069],
                [104.6159084, 20.3547013],
                [104.615951, 20.3543917],
                [104.6158007, 20.3541487],
                [104.6156317, 20.3538429],
                [104.6154351, 20.35358],
                [104.6153389, 20.3534016],
                [104.6152882, 20.35301],
                [104.6151814, 20.3522345],
                [104.6151031, 20.3517312],
                [104.6149283, 20.3513133],
                [104.6148299, 20.351052],
                [104.6148746, 20.350808],
                [104.6149786, 20.3504788],
                [104.6151009, 20.350082],
                [104.615349, 20.3495296],
                [104.6154486, 20.3492884],
                [104.615551, 20.349136],
                [104.6155697, 20.3489575],
                [104.6154926, 20.3486152],
                [104.6153705, 20.3483276],
                [104.6153994, 20.3479374],
                [104.615438, 20.3476779],
                [104.6155324, 20.3471327],
                [104.6157757, 20.3468729],
                [104.616016, 20.346594],
                [104.6162437, 20.3463481],
                [104.6164333, 20.3461205],
                [104.6165999, 20.3459712],
                [104.6169051, 20.3458486],
                [104.6172851, 20.345779],
                [104.6175956, 20.3458171],
                [104.6179459, 20.3459013],
                [104.6182115, 20.3460185],
                [104.6184303, 20.3461423],
                [104.6186975, 20.3461674],
                [104.6188755, 20.3459295],
                [104.6191315, 20.3456705],
                [104.6192647, 20.3453508],
                [104.6192708, 20.3451795],
                [104.6192617, 20.3450037],
                [104.6192784, 20.3447421],
                [104.6192816, 20.344523],
                [104.6193017, 20.3442822],
                [104.6193756, 20.3440686],
                [104.6194996, 20.3439021],
                [104.6197346, 20.3437772],
                [104.6199539, 20.3436406],
                [104.6202339, 20.3435191],
                [104.6204424, 20.3433379],
                [104.6206417, 20.3430702],
                [104.6207102, 20.3429568],
                [104.6207232, 20.3427907],
                [104.6208037, 20.3425688],
                [104.6209268, 20.342474],
                [104.621135, 20.3424225],
                [104.6213897, 20.3424767],
                [104.6215448, 20.342356],
                [104.6216464, 20.3421814],
                [104.6217036, 20.3419566],
                [104.6216136, 20.3415512],
                [104.6215834, 20.3413124],
                [104.6215144, 20.3410119],
                [104.6214706, 20.3407648],
                [104.6214392, 20.3406037],
                [104.6214247, 20.3403265],
                [104.6213317, 20.340098],
                [104.6212013, 20.339884],
                [104.6210319, 20.3396328],
                [104.6208494, 20.3393589],
                [104.6207367, 20.3390595],
                [104.6205543, 20.3387611],
                [104.6203605, 20.3385019],
                [104.6201866, 20.3379074],
                [104.6201177, 20.3376068],
                [104.6199842, 20.3373738],
                [104.6198184, 20.3371274],
                [104.6196166, 20.3368873],
                [104.6195298, 20.3365777],
                [104.6195391, 20.3362183],
                [104.6195616, 20.3359409],
                [104.6195644, 20.335652],
                [104.6195297, 20.3354023],
                [104.6195298, 20.3351048],
                [104.6194725, 20.3348597],
                [104.6193459, 20.33472],
                [104.6191112, 20.3345649],
                [104.618674, 20.3345059],
                [104.6185511, 20.3345003],
                [104.6182132, 20.3345046],
                [104.618042, 20.3344296],
                [104.6179131, 20.3342671],
                [104.6177908, 20.3339845],
                [104.6176945, 20.3337862],
                [104.617653, 20.333597],
                [104.6176843, 20.3333907],
                [104.6177596, 20.3332287],
                [104.6179155, 20.33309],
                [104.618237, 20.3328329],
                [104.6183324, 20.3327097],
                [104.618378, 20.3326226],
                [104.6184199, 20.3325401],
                [104.6185694, 20.3321622],
                [104.6186009, 20.3319721],
                [104.6185698, 20.3318067],
                [104.6185016, 20.331714],
                [104.6183698, 20.3315344],
                [104.6182934, 20.3314069],
                [104.6182738, 20.3312924],
                [104.6182563, 20.3311623],
                [104.6182654, 20.3310372],
                [104.6183115, 20.3308867],
                [104.6184276, 20.330789],
                [104.6185724, 20.3307412],
                [104.6187501, 20.3307344],
                [104.6190237, 20.3306401],
                [104.6191358, 20.3305276],
                [104.6191698, 20.3303793],
                [104.6191299, 20.3302361],
                [104.6191031, 20.3301024],
                [104.6190996, 20.3299415],
                [104.6190503, 20.329801],
                [104.618957, 20.3296749],
                [104.6188331, 20.3295586],
                [104.6186979, 20.3294543],
                [104.6185189, 20.3293574],
                [104.61834, 20.3292595],
                [104.6181577, 20.32915],
                [104.6180022, 20.3290789],
                [104.6178772, 20.3290182],
                [104.6177716, 20.3289283],
                [104.6177112, 20.328816],
                [104.6176819, 20.3286803],
                [104.6176619, 20.328517],
                [104.6176434, 20.32839],
                [104.6176207, 20.3282562],
                [104.6176019, 20.3281377],
                [104.6175964, 20.3280308],
                [104.617605, 20.3279184],
                [104.6176257, 20.3278155],
                [104.6176515, 20.3276835],
                [104.6176674, 20.3275771],
                [104.6176728, 20.3274556],
                [104.6176601, 20.3273296],
                [104.6176347, 20.3272264],
                [104.6176074, 20.3271124],
                [104.6175704, 20.3269989],
                [104.6175375, 20.3268914],
                [104.6175316, 20.3267487],
                [104.6175566, 20.3266304],
                [104.6176158, 20.3265087],
                [104.6177345, 20.3263508],
                [104.6178427, 20.3262249],
                [104.617925, 20.3261386],
                [104.61803, 20.326023],
                [104.6181378, 20.3259214],
                [104.6182077, 20.3258326],
                [104.6182671, 20.325712],
                [104.6183127, 20.3255935],
                [104.618347, 20.3254743],
                [104.6183788, 20.3253306],
                [104.6184878, 20.3252033],
                [104.6186185, 20.3251187],
                [104.618734, 20.3250183],
                [104.6187517, 20.3249225],
                [104.618755, 20.3248679],
                [104.618762, 20.3248084],
                [104.6187478, 20.3246828],
                [104.618667, 20.3245391],
                [104.6186115, 20.324399],
                [104.6186057, 20.3242513],
                [104.618722, 20.3241469],
                [104.6188865, 20.3240699],
                [104.619092, 20.3240602],
                [104.6192436, 20.3240739],
                [104.6194014, 20.3240747],
                [104.6195523, 20.3240236],
                [104.6197084, 20.3239234],
                [104.6198156, 20.3238241],
                [104.6198911, 20.3237048],
                [104.6199299, 20.3235738],
                [104.6199457, 20.3234337],
                [104.6199359, 20.3233079],
                [104.6198928, 20.3231813],
                [104.6198219, 20.3230577],
                [104.6197676, 20.3229278],
                [104.6197601, 20.3227702],
                [104.6197856, 20.3226309],
                [104.6198701, 20.3224823],
                [104.6199962, 20.3223895],
                [104.6200928, 20.3223051],
                [104.620145, 20.3222014],
                [104.6201528, 20.3221043],
                [104.6201264, 20.3219913],
                [104.6200714, 20.3218713],
                [104.6199783, 20.3217445],
                [104.619917, 20.3216385],
                [104.6199428, 20.3215114],
                [104.6200184, 20.3213655],
                [104.6201462, 20.3212101],
                [104.6202811, 20.3210696],
                [104.6203755, 20.3209334],
                [104.6204328, 20.3207771],
                [104.6204471, 20.320659],
                [104.6204096, 20.3205165],
                [104.6203762, 20.3203899],
                [104.6203702, 20.320268],
                [104.620352, 20.3201439],
                [104.620286, 20.3200344],
                [104.6201764, 20.3199704],
                [104.6200507, 20.319948],
                [104.6198935, 20.3199702],
                [104.6197457, 20.3200114],
                [104.6195831, 20.3200081],
                [104.6194495, 20.3199721],
                [104.6193824, 20.319876],
                [104.6193586, 20.3197389],
                [104.6193542, 20.3195766],
                [104.6193612, 20.3194157],
                [104.6193278, 20.3192667],
                [104.6192573, 20.3191688],
                [104.6191358, 20.3190567],
                [104.6190044, 20.3189766],
                [104.6188427, 20.3189205],
                [104.6186552, 20.3188648],
                [104.6185772, 20.3187786],
                [104.6185116, 20.3186865],
                [104.6184859, 20.3185079],
                [104.6184296, 20.3183945],
                [104.6183419, 20.3182767],
                [104.6182066, 20.318202],
                [104.6180629, 20.3181868],
                [104.6179041, 20.318236],
                [104.6177777, 20.318238],
                [104.6176175, 20.3181797],
                [104.6175274, 20.3181003],
                [104.6174318, 20.3179594],
                [104.6174008, 20.3177873],
                [104.6173996, 20.3176005],
                [104.6174426, 20.3173922],
                [104.6175352, 20.317243],
                [104.6176533, 20.3171317],
                [104.6177585, 20.3170071],
                [104.6177927, 20.3168723],
                [104.6178104, 20.3167171],
                [104.6177963, 20.3165912],
                [104.6177294, 20.316456],
                [104.6176072, 20.3162895],
                [104.6175324, 20.3161775],
                [104.6175126, 20.3160387],
                [104.6175514, 20.3158653],
                [104.6176961, 20.3157211],
                [104.6178594, 20.3156468],
                [104.6180555, 20.3156587],
                [104.6182044, 20.3157061],
                [104.6183257, 20.3157161],
                [104.6184399, 20.3156467],
                [104.6184828, 20.3155279],
                [104.6184802, 20.3154031],
                [104.6184826, 20.3152608],
                [104.6185183, 20.3151297],
                [104.6185807, 20.3149939],
                [104.6186596, 20.3147632],
                [104.6186875, 20.3146344],
                [104.618711, 20.3145137],
                [104.6187224, 20.314413],
                [104.6187186, 20.3143055],
                [104.6187192, 20.3141855],
                [104.6187286, 20.3140817],
                [104.6186825, 20.3139841],
                [104.6186045, 20.313908],
                [104.6184767, 20.3138847],
                [104.6183062, 20.3138738],
                [104.6181423, 20.3138619],
                [104.617954, 20.3137993],
                [104.6178425, 20.3137306],
                [104.6177102, 20.3136338],
                [104.617568, 20.3135335],
                [104.6174089, 20.3134295],
                [104.6172852, 20.313349],
                [104.617153, 20.313307],
                [104.6170353, 20.3133281],
                [104.6169413, 20.3133895],
                [104.6168728, 20.3134309],
                [104.6167763, 20.3134317],
                [104.6166683, 20.3133846],
                [104.616565, 20.3132993],
                [104.6164668, 20.3132206],
                [104.6163565, 20.3131403],
                [104.61629, 20.3130445],
                [104.6162784, 20.312907],
                [104.6163273, 20.3127441],
                [104.6163533, 20.3126106],
                [104.6163105, 20.3124856],
                [104.6162364, 20.3123451],
                [104.6161606, 20.3122265],
                [104.6160555, 20.3121158],
                [104.6159192, 20.312084],
                [104.6157619, 20.3121067],
                [104.6155736, 20.3121091],
                [104.6154254, 20.3120686],
                [104.6152871, 20.3119679],
                [104.615193, 20.3118533],
                [104.6151038, 20.3117137],
                [104.6150559, 20.3115949],
                [104.6150688, 20.3114233],
                [104.6150763, 20.3112392],
                [104.6151454, 20.3110957],
                [104.6152847, 20.3109994],
                [104.6154237, 20.3109103],
                [104.6154923, 20.3108667],
                [104.6156406, 20.3107698],
                [104.6157587, 20.3106711],
                [104.6158431, 20.3105467],
                [104.6158695, 20.310446],
                [104.6158006, 20.3102977],
                [104.6156975, 20.3101574],
                [104.6155389, 20.3099873],
                [104.6154463, 20.3098587],
                [104.6154153, 20.3096972],
                [104.6154201, 20.3095166],
                [104.615469, 20.3093469],
                [104.6155083, 20.3091998],
                [104.6155359, 20.3090237],
                [104.6155223, 20.3088663],
                [104.6155103, 20.308683],
                [104.6155215, 20.308553],
                [104.6155558, 20.308405],
                [104.6156216, 20.3082883],
                [104.615705, 20.3081582],
                [104.6157552, 20.3080754],
                [104.6157886, 20.3079649],
                [104.6157631, 20.3078594],
                [104.6156668, 20.3077282],
                [104.6156262, 20.307619],
                [104.6156615, 20.3074997],
                [104.6157418, 20.3073882],
                [104.615805, 20.3072772],
                [104.6158325, 20.3071543],
                [104.6157572, 20.3070443],
                [104.6156536, 20.3069347],
                [104.6156197, 20.3068304],
                [104.615679, 20.3066939],
                [104.6157967, 20.306545],
                [104.6159464, 20.3064432],
                [104.6161491, 20.3062488],
                [104.6162256, 20.3061261],
                [104.616273, 20.3060047],
                [104.6163126, 20.3058908],
                [104.6163721, 20.3057749],
                [104.6164117, 20.3056716],
                [104.6164111, 20.305563],
                [104.6163447, 20.3054934],
                [104.6162138, 20.3054388],
                [104.6160836, 20.3054087],
                [104.6159368, 20.3053822],
                [104.6157798, 20.3053425],
                [104.6156718, 20.3052772],
                [104.6156136, 20.3051947],
                [104.6156242, 20.3050784],
                [104.6156888, 20.3049347],
                [104.6158259, 20.3048033],
                [104.6160629, 20.3046564],
                [104.6162072, 20.304609],
                [104.616364, 20.3045269],
                [104.6165051, 20.3044725],
                [104.6166429, 20.3044013],
                [104.6167525, 20.3043298],
                [104.6168693, 20.3042499],
                [104.6169505, 20.3041487],
                [104.6169665, 20.3040456],
                [104.616912, 20.3039575],
                [104.6168288, 20.3038441],
                [104.6167547, 20.3037432],
                [104.6166697, 20.3036159],
                [104.6166405, 20.3034251],
                [104.6167047, 20.3033272],
                [104.6168386, 20.3032617],
                [104.6169652, 20.303252],
                [104.617109, 20.3032577],
                [104.6172694, 20.3032972],
                [104.6174152, 20.3033176],
                [104.6175684, 20.3033149],
                [104.6177195, 20.3032939],
                [104.617847, 20.3032534],
                [104.6179386, 20.3031961],
                [104.6180405, 20.3031096],
                [104.6180661, 20.3030061],
                [104.6180609, 20.3029006],
                [104.618045, 20.3027655],
                [104.6180653, 20.3026173],
                [104.6180857, 20.302499],
                [104.6180721, 20.3023805],
                [104.6179973, 20.3022679],
                [104.6179083, 20.3021666],
                [104.6177681, 20.302099],
                [104.6176345, 20.3020349],
                [104.6175168, 20.301976],
                [104.6174204, 20.3018731],
                [104.6173799, 20.3017782],
                [104.6173912, 20.3016637],
                [104.6173931, 20.3015375],
                [104.6173352, 20.3014396],
                [104.6172562, 20.3013322],
                [104.6171864, 20.3012228],
                [104.6171469, 20.301123],
                [104.6171676, 20.3009982],
                [104.6172887, 20.3008438],
                [104.6174077, 20.3007823],
                [104.6175487, 20.3007732],
                [104.6176828, 20.3007561],
                [104.6178015, 20.3006952],
                [104.6179092, 20.3006246],
                [104.6180279, 20.3005271],
                [104.6181179, 20.3004293],
                [104.6181518, 20.3003141],
                [104.6180857, 20.3002105],
                [104.6179525, 20.300149],
                [104.6178412, 20.3000629],
                [104.6177639, 20.2999543],
                [104.6177301, 20.2998281],
                [104.6177111, 20.2996829],
                [104.6177083, 20.2996338],
                [104.6176912, 20.299163],
                [104.6179635, 20.2992584],
                [104.6184858, 20.2994135],
                [104.6188868, 20.2996823],
                [104.6192475, 20.299951],
                [104.6196081, 20.3002196],
                [104.6200498, 20.3004125],
                [104.620372, 20.300376],
                [104.6206147, 20.3001485],
                [104.6207379, 20.2997297],
                [104.6208625, 20.2990443],
                [104.6208265, 20.2982817],
                [104.620266, 20.2977833],
                [104.6196635, 20.2975515],
                [104.6190209, 20.2973197],
                [104.6184596, 20.2969356],
                [104.6185022, 20.2965165],
                [104.6188655, 20.2963278],
                [104.6197102, 20.2964083],
                [104.6203526, 20.2966783],
                [104.6205029, 20.2967504],
                [104.6208049, 20.296729],
                [104.6210177, 20.2967075],
                [104.6211611, 20.2967695],
                [104.6212741, 20.2968113],
                [104.6213354, 20.2966756],
                [104.6213748, 20.2965891],
                [104.6213923, 20.2964623],
                [104.6214116, 20.2961038],
                [104.6214256, 20.2959433],
                [104.6214545, 20.2957926],
                [104.6215208, 20.2956169],
                [104.6216204, 20.2954335],
                [104.6217362, 20.2952811],
                [104.6218678, 20.2951366],
                [104.6219941, 20.2949919],
                [104.622119, 20.2948293],
                [104.6222544, 20.2946923],
                [104.6224321, 20.294564],
                [104.6225522, 20.2944512],
                [104.6226761, 20.2942991],
                [104.6227401, 20.2941541],
                [104.6227667, 20.2940116],
                [104.6227446, 20.2938817],
                [104.6226151, 20.2937806],
                [104.6224255, 20.2937272],
                [104.6222108, 20.293707],
                [104.6220108, 20.2936941],
                [104.621791, 20.2936713],
                [104.6215831, 20.2936337],
                [104.6212416, 20.2935503],
                [104.6210564, 20.2934971],
                [104.6208623, 20.2934287],
                [104.6207644, 20.293317],
                [104.6206892, 20.293191],
                [104.6206447, 20.2930555],
                [104.6205386, 20.292942],
                [104.6203746, 20.2928793],
                [104.6201806, 20.2928745],
                [104.6200001, 20.2929009],
                [104.6198015, 20.2929428],
                [104.6195985, 20.2929932],
                [104.6193624, 20.2930514],
                [104.619129, 20.2930911],
                [104.6189109, 20.293096],
                [104.6185896, 20.2929883],
                [104.6183978, 20.2929098],
                [104.618225, 20.2928254],
                [104.6180766, 20.2927126],
                [104.6179389, 20.2926211],
                [104.6177657, 20.2925123],
                [104.6176338, 20.2924146],
                [104.6174952, 20.2923066],
                [104.6173587, 20.2921817],
                [104.6172474, 20.2920452],
                [104.617145, 20.291902],
                [104.6170778, 20.2917296],
                [104.6170444, 20.2915405],
                [104.617002, 20.2913388],
                [104.6169529, 20.2911649],
                [104.6169077, 20.2909591],
                [104.6168992, 20.2908195],
                [104.6169384, 20.2906263],
                [104.6170241, 20.2904799],
                [104.6171047, 20.2903404],
                [104.6171809, 20.2901774],
                [104.6172078, 20.2899971],
                [104.6171977, 20.2897012],
                [104.6171846, 20.2895216],
                [104.6171526, 20.2893418],
                [104.617101, 20.2891766],
                [104.6170182, 20.2889981],
                [104.61694, 20.2888335],
                [104.6168704, 20.288662],
                [104.6167705, 20.2885246],
                [104.6166688, 20.288386],
                [104.616592, 20.2882834],
                [104.6164436, 20.2881894],
                [104.6163237, 20.2880847],
                [104.6162053, 20.2879464],
                [104.6161376, 20.2878317],
                [104.6161109, 20.2876393],
                [104.6161315, 20.2873242],
                [104.6161703, 20.2871602],
                [104.6162451, 20.2870011],
                [104.6163426, 20.2868436],
                [104.6164163, 20.2866862],
                [104.6164353, 20.2865363],
                [104.6164147, 20.2863897],
                [104.6164077, 20.2863714],
                [104.6163575, 20.2862407],
                [104.6162967, 20.2860803],
                [104.6162489, 20.2858953],
                [104.6162027, 20.2857345],
                [104.6161362, 20.2855606],
                [104.6160466, 20.2854122],
                [104.6159383, 20.2852646],
                [104.6158411, 20.2851122],
                [104.6157769, 20.2849302],
                [104.6156871, 20.284762],
                [104.6155819, 20.2846188],
                [104.6155188, 20.284468],
                [104.6155099, 20.2843039],
                [104.615517, 20.2841243],
                [104.6155154, 20.2839359],
                [104.6155104, 20.2837534],
                [104.6155295, 20.2835763],
                [104.6155655, 20.2833956],
                [104.6156161, 20.2832259],
                [104.6156651, 20.2830483],
                [104.6156859, 20.2828773],
                [104.6157056, 20.2827139],
                [104.6157174, 20.2825391],
                [104.6156883, 20.2823643],
                [104.6156307, 20.2821893],
                [104.6155587, 20.2820209],
                [104.615508, 20.2818618],
                [104.6154927, 20.2816877],
                [104.6154967, 20.2815341],
                [104.6155164, 20.2813348],
                [104.6155491, 20.2811804],
                [104.6155848, 20.2810115],
                [104.6155872, 20.28087],
                [104.6155441, 20.280763],
                [104.6154634, 20.2806891],
                [104.6153152, 20.2806863],
                [104.6151735, 20.2807662],
                [104.6150176, 20.2808721],
                [104.6148695, 20.2809773],
                [104.614708, 20.2811044],
                [104.6145961, 20.2812408],
                [104.6144776, 20.2813185],
                [104.614328, 20.2813314],
                [104.6141974, 20.2812731],
                [104.6141158, 20.2811628],
                [104.6140788, 20.2810424],
                [104.6140858, 20.2808929],
                [104.6141056, 20.2807698],
                [104.6141283, 20.2806363],
                [104.6141201, 20.2805134],
                [104.6140711, 20.2803816],
                [104.6139958, 20.2802733],
                [104.613896, 20.2801905],
                [104.6137597, 20.2801668],
                [104.6136266, 20.2801654],
                [104.6134861, 20.2801665],
                [104.6133491, 20.2801498],
                [104.6132039, 20.2801274],
                [104.6130609, 20.2801192],
                [104.61293, 20.2801336],
                [104.6127905, 20.2801413],
                [104.6126723, 20.2801415],
                [104.6125461, 20.2801211],
                [104.6124394, 20.2800924],
                [104.6123479, 20.2800562],
                [104.612282, 20.2799717],
                [104.6122055, 20.2797865],
                [104.6122182, 20.2796051],
                [104.6122707, 20.2794186],
                [104.6123955, 20.2793059],
                [104.6125782, 20.279185],
                [104.6126856, 20.2791139],
                [104.6128301, 20.2789326],
                [104.6129418, 20.2787251],
                [104.6130294, 20.2784965],
                [104.6131013, 20.2782523],
                [104.6131702, 20.2780424],
                [104.6132785, 20.2777796],
                [104.61342, 20.2775391],
                [104.6135896, 20.277307],
                [104.6137197, 20.2771152],
                [104.6138506, 20.2768799],
                [104.6139501, 20.2766318],
                [104.6140107, 20.2763867],
                [104.6141443, 20.2761116],
                [104.6143066, 20.2758929],
                [104.614475, 20.2756628],
                [104.6145754, 20.2754133],
                [104.6145047, 20.2752203],
                [104.6143284, 20.2751648],
                [104.6140996, 20.2751526],
                [104.6138551, 20.2752752],
                [104.6136086, 20.275402],
                [104.6133945, 20.2755182],
                [104.6131625, 20.2755906],
                [104.6128671, 20.2756026],
                [104.6127226, 20.2756159],
                [104.6124332, 20.2755764],
                [104.612093, 20.2755674],
                [104.6117914, 20.2755872],
                [104.6115042, 20.2756301],
                [104.6112137, 20.2756543],
                [104.6109773, 20.275621],
                [104.610712, 20.2754931],
                [104.6105464, 20.2753424],
                [104.6104247, 20.2751788],
                [104.6103939, 20.27497],
                [104.6104473, 20.2748097],
                [104.6105807, 20.2745902],
                [104.6106648, 20.274374],
                [104.6107465, 20.274167],
                [104.6107612, 20.2739305],
                [104.6107289, 20.2736352],
                [104.6107626, 20.2730705],
                [104.611049, 20.2719075],
                [104.6112043, 20.2708677],
                [104.6116554, 20.2692829],
                [104.6116583, 20.2681399],
                [104.6115191, 20.2671098],
                [104.6112916, 20.2664914],
                [104.6110094, 20.2659553],
                [104.6109769, 20.265842],
                [104.6110537, 20.2656876],
                [104.6112509, 20.265338],
                [104.6117432, 20.2646182],
                [104.6119294, 20.2643304],
                [104.6120065, 20.2640422],
                [104.6119638, 20.2636817],
                [104.611812, 20.2633312],
                [104.6116715, 20.2628058],
                [104.6116401, 20.2622908],
                [104.611555, 20.2614359],
                [104.6113616, 20.2602614],
                [104.6112541, 20.2596433],
                [104.6110808, 20.2591589],
                [104.6107986, 20.2586229],
                [104.6103419, 20.2580658],
                [104.610016, 20.2575913],
                [104.6092773, 20.2564467],
                [104.6091362, 20.2562198],
                [104.6091363, 20.2561271],
                [104.6092568, 20.255942],
                [104.6098144, 20.2554078],
                [104.6105352, 20.2549563],
                [104.6110596, 20.2545352],
                [104.6117049, 20.2537644],
                [104.6121207, 20.2532401],
                [104.6123728, 20.2526743],
                [104.6124722, 20.2521596],
                [104.6126719, 20.2508419],
                [104.6128603, 20.249586],
                [104.6128853, 20.2490242],
                [104.6129065, 20.248546],
                [104.6127768, 20.2480618],
                [104.6126689, 20.2475879],
                [104.6125285, 20.2470623],
                [104.6125182, 20.246774],
                [104.6126285, 20.2462799],
                [104.6129063, 20.2453281],
                [104.6131042, 20.2446643],
                [104.6136634, 20.2433989],
                [104.6140575, 20.242782],
                [104.6144512, 20.2423349],
                [104.6149265, 20.2419497],
                [104.6159906, 20.2415349],
                [104.6171531, 20.2410587],
                [104.6176773, 20.2407509],
                [104.618005, 20.240489],
                [104.6183169, 20.2400417],
                [104.6189733, 20.2391318],
                [104.6193827, 20.2389164],
                [104.6200869, 20.2386091],
                [104.6205783, 20.2382858],
                [104.6214303, 20.2377316],
                [104.6221845, 20.2369762],
                [104.6228727, 20.2364217],
                [104.6235286, 20.2357434],
                [104.624217, 20.2352043],
                [104.6247736, 20.2349891],
                [104.6252974, 20.234774],
                [104.626002, 20.2342812],
                [104.626379, 20.2339114],
                [104.6270027, 20.2330012],
                [104.6272663, 20.2321832],
                [104.6279878, 20.2314124],
                [104.6288403, 20.2305646],
                [104.6294798, 20.2298554],
                [104.6298404, 20.2295628],
                [104.630168, 20.2293781],
                [104.6305444, 20.2292553],
                [104.6310678, 20.2292564],
                [104.6317546, 20.2292732],
                [104.6327029, 20.2294451],
                [104.6354482, 20.2304859],
                [104.6369027, 20.2309831],
                [104.6379975, 20.2313715],
                [104.6388301, 20.2320375],
                [104.6391885, 20.232687],
                [104.6396455, 20.2330895],
                [104.6404781, 20.23374],
                [104.6410336, 20.2339882],
                [104.6424725, 20.2342383],
                [104.6432731, 20.2345334],
                [104.6439435, 20.2346738],
                [104.6445483, 20.234814],
                [104.6448856, 20.234807],
                [104.645295, 20.2345761],
                [104.6463107, 20.2338676],
                [104.6469167, 20.2334826],
                [104.6474567, 20.2333292],
                [104.6478166, 20.2333609],
                [104.6485196, 20.233455],
                [104.6492232, 20.2333328],
                [104.6502706, 20.2330568],
                [104.6513339, 20.2329818],
                [104.6519716, 20.2330603],
                [104.6524297, 20.2329838],
                [104.6527899, 20.2328147],
                [104.6534115, 20.232754],
                [104.6539509, 20.232925],
                [104.6544907, 20.2329107],
                [104.6550303, 20.2329426],
                [104.6556189, 20.2330211],
                [104.6562398, 20.2333312],
                [104.6567954, 20.2335331],
                [104.6571059, 20.2336573],
                [104.6573122, 20.2337658],
                [104.6581821, 20.2337653],
                [104.6587413, 20.2336711],
                [104.659194, 20.2335602],
                [104.6594248, 20.2335038],
                [104.6599997, 20.2333362],
                [104.6606988, 20.2331691],
                [104.6612578, 20.2330968],
                [104.6615299, 20.2329506],
                [104.6619188, 20.2326141],
                [104.662277, 20.2321381],
                [104.662425, 20.2318525],
                [104.662612, 20.2314937],
                [104.6628846, 20.2311274],
                [104.6630948, 20.2308639],
                [104.6634989, 20.2305861],
                [104.6636781, 20.2303518],
                [104.6638261, 20.2301248],
                [104.6639123, 20.2297217],
                [104.6639606, 20.2289226],
                [104.6640386, 20.2287394],
                [104.6641709, 20.2286296],
                [104.6645125, 20.2285643],
                [104.6650251, 20.2284921],
                [104.6654835, 20.2283169],
                [104.6660508, 20.227988],
                [104.6664861, 20.2276809],
                [104.6665875, 20.2275344],
                [104.6665956, 20.2273437],
                [104.6664726, 20.2267496],
                [104.6663493, 20.226324],
                [104.6663731, 20.2260455],
                [104.6663815, 20.225745],
                [104.6663978, 20.225415],
                [104.6664683, 20.2250631],
                [104.6665624, 20.224616],
                [104.6667572, 20.2243012],
                [104.6672315, 20.2239061],
                [104.6678224, 20.2234746],
                [104.6679703, 20.2233209],
                [104.6680872, 20.2231159],
                [104.6680798, 20.2229251],
                [104.6680183, 20.2225878],
                [104.6678637, 20.2222868],
                [104.6678408, 20.2221036],
                [104.6678646, 20.2218982],
                [104.6679504, 20.221693],
                [104.6681223, 20.2211287],
                [104.6682399, 20.220579],
                [104.6684435, 20.2197949],
                [104.6684986, 20.2193476],
                [104.6684763, 20.218915],
                [104.6682677, 20.2183867],
                [104.6682757, 20.218306],
                [104.6683691, 20.2182475],
                [104.6688813, 20.2182266],
                [104.6696114, 20.2180959],
                [104.6699609, 20.2179499],
                [104.6706214, 20.2176433],
                [104.6717867, 20.2171542],
                [104.6724629, 20.2167521],
                [104.672906, 20.2164377],
                [104.6732634, 20.2162918],
                [104.6735198, 20.2162262],
                [104.6739391, 20.2161609],
                [104.6748936, 20.216302],
                [104.6755611, 20.2163692],
                [104.6764613, 20.2164662],
                [104.6767756, 20.216474],
                [104.6772883, 20.2163504],
                [104.6776146, 20.2162116],
                [104.6779252, 20.2161022],
                [104.6781117, 20.2160658],
                [104.6784375, 20.2161618],
                [104.678771, 20.216331],
                [104.679283, 20.2164346],
                [104.6799738, 20.2164799],
                [104.6807581, 20.2164081],
                [104.6811618, 20.2163722],
                [104.6814956, 20.2163873],
                [104.6819764, 20.2166156],
                [104.6825118, 20.2167119],
                [104.6831082, 20.2174169],
                [104.6833717, 20.2175934],
                [104.6841398, 20.217822],
                [104.6851483, 20.2181612],
                [104.6855286, 20.2182059],
                [104.6858392, 20.2181185],
                [104.6864067, 20.2177308],
                [104.6874017, 20.2170214],
                [104.6877127, 20.2168386],
                [104.6880854, 20.2167513],
                [104.6888386, 20.2166425],
                [104.6892736, 20.216548],
                [104.6897086, 20.2163508],
                [104.6901051, 20.2160876],
                [104.6905712, 20.2158904],
                [104.6913091, 20.2156937],
                [104.6920626, 20.2154018],
                [104.6925595, 20.2153073],
                [104.693103, 20.215301],
                [104.693662, 20.2152065],
                [104.6940736, 20.2151046],
                [104.6945864, 20.2149149],
                [104.6954179, 20.2144103],
                [104.6967312, 20.213672],
                [104.696972, 20.2136138],
                [104.6974688, 20.2136074],
                [104.697725, 20.2135491],
                [104.6980515, 20.2133591],
                [104.6984869, 20.2129492],
                [104.6987589, 20.212825],
                [104.6992325, 20.2127525],
                [104.7001254, 20.2126513],
                [104.7007699, 20.212557],
                [104.7012281, 20.2124406],
                [104.7019657, 20.2123246],
                [104.7022765, 20.2121784],
                [104.702572, 20.2119371],
                [104.7028289, 20.2115122],
                [104.7031089, 20.2112266],
                [104.7033965, 20.2110145],
                [104.7038704, 20.2108393],
                [104.7042432, 20.2107153],
                [104.7043909, 20.2106056],
                [104.704469, 20.2103711],
                [104.7044463, 20.2099824],
                [104.7044239, 20.2095865],
                [104.7044559, 20.2091026],
                [104.7045574, 20.2087507],
                [104.7048609, 20.20837],
                [104.7051369, 20.2080738],
                [104.7059031, 20.2072279],
                [104.7061288, 20.2068982],
                [104.7064785, 20.2066788],
                [104.7069136, 20.2064523],
                [104.7072709, 20.2063136],
                [104.7078534, 20.2062338],
                [104.7084668, 20.2060882],
                [104.7088473, 20.2059349],
                [104.7092126, 20.2057375],
                [104.7096868, 20.205379],
                [104.7099974, 20.2052841],
                [104.7108204, 20.2052122],
                [104.7116277, 20.2051403],
                [104.7124192, 20.2052296],
                [104.7132497, 20.2053629],
                [104.7143745, 20.2057166],
                [104.7148711, 20.2058274],
                [104.7150651, 20.2058131],
                [104.7153294, 20.2056448],
                [104.715765, 20.2051396],
                [104.7162389, 20.2048838],
                [104.7168448, 20.2046575],
                [104.7171479, 20.2044086],
                [104.7173268, 20.204189],
                [104.7175448, 20.2038226],
                [104.7176928, 20.2035663],
                [104.7178098, 20.2032511],
                [104.7178336, 20.2029359],
                [104.7178729, 20.2027086],
                [104.7179896, 20.2024888],
                [104.7182152, 20.2022838],
                [104.7183789, 20.2019542],
                [104.7185424, 20.2016025],
                [104.7186824, 20.2014342],
                [104.7189933, 20.2012219],
                [104.719304, 20.2011125],
                [104.7196068, 20.201025],
                [104.720065, 20.2009304],
                [104.7206085, 20.2007994],
                [104.7214705, 20.2005221],
                [104.7219519, 20.2004567],
                [104.7227049, 20.2003994],
                [104.7239235, 20.2004379],
                [104.7249556, 20.2004983],
                [104.7257084, 20.2006021],
                [104.7261276, 20.2005808],
                [104.7265, 20.200662],
                [104.7268878, 20.200824],
                [104.727353, 20.201096],
                [104.7278571, 20.2013168],
                [104.7283227, 20.2014567],
                [104.7290989, 20.2015166],
                [104.7293704, 20.2016124],
                [104.7297191, 20.2019575],
                [104.7301533, 20.2021415],
                [104.7305024, 20.2022593],
                [104.7309061, 20.2022014],
                [104.7319459, 20.2023863],
                [104.7327451, 20.2025709],
                [104.7330401, 20.2025859],
                [104.7334826, 20.2024912],
                [104.7339876, 20.2022428],
                [104.7350906, 20.2017751],
                [104.7358286, 20.2014024],
                [104.7364579, 20.2010733],
                [104.7369396, 20.2007587],
                [104.7372658, 20.2006418],
                [104.7382361, 20.2006579],
                [104.7385855, 20.2005705],
                [104.7388652, 20.2004097],
                [104.739417, 20.1999705],
                [104.7402254, 20.1993117],
                [104.7406992, 20.1990778],
                [104.74146, 20.1990203],
                [104.742143, 20.1990726],
                [104.7427019, 20.1990368],
                [104.7430823, 20.1989861],
                [104.743579, 20.198994],
                [104.7441068, 20.1989875],
                [104.7445336, 20.1990175],
                [104.7451698, 20.1992311],
                [104.7456816, 20.1995324],
                [104.7459763, 20.1996355],
                [104.7462636, 20.1995992],
                [104.7466208, 20.1994824],
                [104.7469159, 20.1994389],
                [104.7473971, 20.1994542],
                [104.747956, 20.1994257],
                [104.7484683, 20.1993825],
                [104.7489418, 20.1993318],
                [104.7495709, 20.1991127],
                [104.7498892, 20.1990032],
                [104.7502075, 20.198989],
                [104.7507121, 20.1989604],
                [104.751209, 20.1988805],
                [104.7516903, 20.1988517],
                [104.7518714, 20.1988484],
                [104.7521017, 20.1988451],
                [104.7526759, 20.1989338],
                [104.7532731, 20.1991839],
                [104.7537153, 20.1993385],
                [104.7540256, 20.1993683],
                [104.7543054, 20.1992514],
                [104.754585, 20.1991493],
                [104.7548178, 20.1991203],
                [104.7552213, 20.1992529],
                [104.7558884, 20.1995471],
                [104.7567251, 20.2004281],
                [104.7569475, 20.2005416],
                [104.7573301, 20.2007369],
                [104.7577648, 20.2007815],
                [104.7582617, 20.2006357],
                [104.758883, 20.2003871],
                [104.7594424, 20.2001239],
                [104.760359, 20.1996265],
                [104.7608562, 20.1994073],
                [104.7614462, 20.1992321],
                [104.7622691, 20.1991893],
                [104.7627812, 20.199322],
                [104.7630762, 20.199337],
                [104.7634022, 20.1992348],
                [104.7641167, 20.1990158],
                [104.764769, 20.1988407],
                [104.7651416, 20.1987971],
                [104.7656227, 20.1988419],
                [104.7669726, 20.1994156],
                [104.7679185, 20.2000474],
                [104.7683064, 20.2002386],
                [104.7685476, 20.2002389],
                [104.7687255, 20.200239],
                [104.7699679, 20.1999034],
                [104.772215, 20.1991144],
                [104.7745861, 20.198518],
                [104.775247, 20.1982911],
                [104.7755921, 20.1980589],
                [104.7761464, 20.197769],
                [104.7763561, 20.1974783],
                [104.7765041, 20.1972344],
                [104.776517, 20.1968973],
                [104.7764805, 20.1965366],
                [104.7764932, 20.1963273],
                [104.776641, 20.1961414],
                [104.7770972, 20.1957001],
                [104.7784236, 20.194774],
                [104.7797789, 20.193931],
                [104.7809801, 20.193474],
                [104.7819761, 20.1937165],
                [104.7832683, 20.1934539],
                [104.7851571, 20.1928892],
                [104.786877, 20.1925759],
                [104.7882516, 20.191946],
                [104.789146, 20.1913904],
                [104.7895479, 20.190648],
                [104.7906829, 20.1890695],
                [104.793518, 20.1866775],
                [104.7967409, 20.1834352],
                [104.7969683, 20.1831753],
                [104.7974649, 20.183004],
                [104.798785, 20.1829548],
                [104.7997812, 20.1830284],
                [104.800114, 20.1826316],
                [104.8002194, 20.1816751],
                [104.8007311, 20.1810482],
                [104.8015032, 20.180725],
                [104.8029343, 20.1793761],
                [104.8049887, 20.1789483],
                [104.8057198, 20.178529],
                [104.8064208, 20.1782797],
                [104.8069126, 20.1781618],
                [104.8073806, 20.1779758],
                [104.8079144, 20.1774413],
                [104.8094487, 20.1760262],
                [104.8106157, 20.175209],
                [104.8115494, 20.1746119],
                [104.8122833, 20.173857],
                [104.8133496, 20.1736692],
                [104.8138501, 20.1731032],
                [104.8139092, 20.1730041],
                [104.8141199, 20.1725011],
                [104.8142013, 20.1722992],
                [104.8142455, 20.1722297],
                [104.8143046, 20.1721531],
                [104.8143858, 20.1720696],
                [104.8145184, 20.1720002],
                [104.8152187, 20.1718768],
                [104.8164502, 20.171878],
                [104.818316, 20.1716281],
                [104.8194493, 20.1711886],
                [104.8202827, 20.1707487],
                [104.8207831, 20.1701825],
                [104.8209843, 20.1691124],
                [104.8211523, 20.1678217],
                [104.8215843, 20.1670618],
                [104.8224204, 20.1659342],
                [104.8227211, 20.1651158],
                [104.8226887, 20.1644547],
                [104.8226899, 20.1632583],
                [104.8228819, 20.1618611],
                [104.8232347, 20.1613524],
                [104.8235255, 20.1606869],
                [104.824003, 20.1600608],
                [104.8246249, 20.1597481],
                [104.825122, 20.1598464],
                [104.8258679, 20.1598667],
                [104.8261581, 20.15973],
                [104.8262827, 20.1594561],
                [104.8269461, 20.1591629],
                [104.827796, 20.1587331],
                [104.8285634, 20.1580876],
                [104.8290405, 20.1574811],
                [104.8295492, 20.1566805],
                [104.8299361, 20.156194],
                [104.830677, 20.1558601],
                [104.8308369, 20.155774],
                [104.8309911, 20.1557716],
                [104.831227, 20.1557578],
                [104.8318902, 20.1556053],
                [104.8321407, 20.1555637],
                [104.832465, 20.1554944],
                [104.8325976, 20.1554945],
                [104.8327303, 20.1555642],
                [104.8332457, 20.1558154],
                [104.8341148, 20.1561505],
                [104.8348071, 20.1564435],
                [104.8352344, 20.1564719],
                [104.8355438, 20.1565835],
                [104.8357794, 20.1567371],
                [104.8360002, 20.1569879],
                [104.8361174, 20.1576287],
                [104.8362939, 20.1579769],
                [104.8364853, 20.1581442],
                [104.8366769, 20.1581723],
                [104.8368537, 20.1581585],
                [104.8369495, 20.1581448],
                [104.8378344, 20.1573657],
                [104.8384983, 20.1566838],
                [104.8393242, 20.1558629],
                [104.8396785, 20.1552366],
                [104.8398118, 20.1547353],
                [104.8404764, 20.1532039],
                [104.8411114, 20.1518816],
                [104.8412743, 20.1511435],
                [104.841356, 20.150294],
                [104.841403, 20.1495457],
                [104.8414876, 20.1495059],
                [104.84155, 20.1494516],
                [104.8416331, 20.1493597],
                [104.8417998, 20.1491727],
                [104.8421189, 20.148926],
                [104.8423075, 20.1488346],
                [104.8424846, 20.1487508],
                [104.8426295, 20.1486694],
                [104.8428847, 20.1485021],
                [104.8430432, 20.148366],
                [104.8431295, 20.1483015],
                [104.8432072, 20.1482721],
                [104.8433043, 20.1482648],
                [104.8434953, 20.1483007],
                [104.8436607, 20.14834],
                [104.8439482, 20.1484065],
                [104.8441045, 20.1484411],
                [104.8442941, 20.1484672],
                [104.8444141, 20.1484797],
                [104.8445732, 20.148491],
                [104.8447428, 20.1484899],
                [104.8450326, 20.1485013],
                [104.8452124, 20.1485526],
                [104.8453254, 20.148602],
                [104.845793, 20.1488819],
                [104.8459487, 20.1489623],
                [104.8461218, 20.1490664],
                [104.8463537, 20.1492042],
                [104.8464847, 20.1492741],
                [104.8466477, 20.1493605],
                [104.8469355, 20.1495048],
                [104.8470786, 20.1495642],
                [104.8473236, 20.1496075],
                [104.8474962, 20.149591],
                [104.8476427, 20.149597],
                [104.8477563, 20.1496434],
                [104.8478867, 20.1497164],
                [104.8480335, 20.1498079],
                [104.8481639, 20.1498809],
                [104.8482772, 20.1499114],
                [104.8484044, 20.149922],
                [104.8485007, 20.1499099],
                [104.8485773, 20.1498865],
                [104.8486413, 20.14985],
                [104.8487613, 20.1496883],
                [104.8489276, 20.1496167],
                [104.8490734, 20.1496179],
                [104.8492155, 20.1496295],
                [104.8493479, 20.1496125],
                [104.8495032, 20.1495376],
                [104.8496418, 20.1494012],
                [104.8498234, 20.1492499],
                [104.8499572, 20.1491381],
                [104.8500858, 20.149019],
                [104.8505166, 20.1484603],
                [104.8506199, 20.1483148],
                [104.8507214, 20.1481705],
                [104.8509169, 20.1478679],
                [104.8510331, 20.1477166],
                [104.85114, 20.1475874],
                [104.8512463, 20.1474884],
                [104.8513858, 20.1474345],
                [104.8514899, 20.1474253],
                [104.8516868, 20.1474225],
                [104.8517787, 20.1474508],
                [104.8519364, 20.1475301],
                [104.8520612, 20.1476227],
                [104.8522087, 20.1477113],
                [104.8523324, 20.1477753],
                [104.8525142, 20.1478332],
                [104.852665, 20.1478605],
                [104.8528111, 20.1478775],
                [104.8530147, 20.1478901],
                [104.8531952, 20.1479016],
                [104.8534326, 20.1478914],
                [104.8536409, 20.1478611],
                [104.8537725, 20.1478323],
                [104.853886, 20.147809],
                [104.8540403, 20.1477777],
                [104.8542092, 20.1477578],
                [104.8543215, 20.14774],
                [104.8544236, 20.147715],
                [104.8545236, 20.1476817],
                [104.8547646, 20.1475425],
                [104.8548887, 20.1474639],
                [104.8552801, 20.1473588],
                [104.8557001, 20.1473537],
                [104.855866, 20.1472235],
                [104.8559791, 20.1471144],
                [104.8562143, 20.1468504],
                [104.856374, 20.1466731],
                [104.8565394, 20.1464843],
                [104.8566198, 20.1463887],
                [104.8567161, 20.14628],
                [104.8568069, 20.146191],
                [104.8569023, 20.1460963],
                [104.8569948, 20.14599],
                [104.8570373, 20.1459165],
                [104.857068, 20.1458266],
                [104.8571062, 20.1457318],
                [104.8571587, 20.145598],
                [104.8573065, 20.1453429],
                [104.8574403, 20.1451691],
                [104.8575816, 20.1449667],
                [104.8576421, 20.1448867],
                [104.8577483, 20.1447178],
                [104.8578128, 20.1446084],
                [104.8580145, 20.1441596],
                [104.85806, 20.1440975],
                [104.8582667, 20.1438064],
                [104.8585506, 20.1434191],
                [104.8586601, 20.1433206],
                [104.8587593, 20.1432662],
                [104.8588916, 20.1432413],
                [104.8589842, 20.1432396],
                [104.859156, 20.1432452],
                [104.859228, 20.1432463],
                [104.8593121, 20.1432449],
                [104.8594138, 20.143213],
                [104.8595192, 20.1431361],
                [104.8597063, 20.1429651],
                [104.8597908, 20.1428829],
                [104.8599305, 20.1427752],
                [104.8600118, 20.1427273],
                [104.8601429, 20.1426736],
                [104.8602715, 20.1426243],
                [104.8603486, 20.1425898],
                [104.8604909, 20.1424859],
                [104.8605801, 20.1424139],
                [104.8606694, 20.1423341],
                [104.8607591, 20.142259],
                [104.8609195, 20.1421137],
                [104.8610504, 20.1420171],
                [104.8611961, 20.1419216],
                [104.8614659, 20.1417744],
                [104.8618933, 20.1415937],
                [104.8627811, 20.1416737],
                [104.8641278, 20.1421684],
                [104.8647598, 20.1426886],
                [104.8651997, 20.1426889],
                [104.8662444, 20.1427417],
                [104.8670968, 20.1425085],
                [104.8683614, 20.1426913],
                [104.8689661, 20.1427958],
                [104.8702035, 20.1427187],
                [104.8705883, 20.142745],
                [104.8709179, 20.143083],
                [104.8714397, 20.1438629],
                [104.8723465, 20.1444871],
                [104.8735009, 20.1448777],
                [104.8747102, 20.1454244],
                [104.8758373, 20.1456069],
                [104.8761399, 20.1454513],
                [104.8766628, 20.1448021],
                [104.8769931, 20.1442307],
                [104.8776535, 20.1436594],
                [104.8785336, 20.1432963],
                [104.8793308, 20.1434268],
                [104.8801004, 20.1437391],
                [104.8812, 20.1438438],
                [104.8819423, 20.1438442],
                [104.8829047, 20.1436109],
                [104.8832345, 20.1438452],
                [104.8838388, 20.1446769],
                [104.8849926, 20.145873],
                [104.8858994, 20.1465492],
                [104.8868062, 20.1472773],
                [104.88708, 20.1473815],
                [104.8871853, 20.1473827],
                [104.8873784, 20.1473173],
                [104.8877334, 20.1472663],
                [104.8882971, 20.1473616],
                [104.8887758, 20.147413],
                [104.8894628, 20.1474718],
                [104.8901114, 20.147494],
                [104.8907137, 20.1474434],
                [104.8912001, 20.1474145],
                [104.8918641, 20.1474149],
                [104.8925203, 20.1473788],
                [104.8928523, 20.1474664],
                [104.8930915, 20.1475762],
                [104.8935779, 20.1476129],
                [104.8945124, 20.1475113],
                [104.8951378, 20.1473366],
                [104.8956551, 20.1472273],
                [104.8960567, 20.1472204],
                [104.8971995, 20.1470387],
                [104.8975393, 20.1470534],
                [104.8984191, 20.1476084],
                [104.8991599, 20.1480174],
                [104.8998703, 20.1480615],
                [104.9006113, 20.1484414],
                [104.9013059, 20.1488066],
                [104.9019699, 20.1489529],
                [104.9027368, 20.1491426],
                [104.9035618, 20.149383],
                [104.9040377, 20.1496231],
                [104.9045134, 20.1500282],
                [104.9048941, 20.1503282],
                [104.9054969, 20.1505085],
                [104.9059571, 20.1505388],
                [104.9067189, 20.1503742],
                [104.9070679, 20.1504344],
                [104.9075121, 20.1507196],
                [104.907972, 20.1512745],
                [104.9085588, 20.1516947],
                [104.9090823, 20.1519649],
                [104.9098754, 20.152595],
                [104.9104782, 20.153015],
                [104.9107477, 20.1533451],
                [104.9108586, 20.15366],
                [104.9109695, 20.1540199],
                [104.9112071, 20.1546648],
                [104.9115402, 20.1550248],
                [104.9121907, 20.1552351],
                [104.9129999, 20.1554004],
                [104.9135711, 20.1556855],
                [104.9141898, 20.1557459],
                [104.9148088, 20.1556261],
                [104.9153644, 20.1553266],
                [104.9157929, 20.1553268],
                [104.9166973, 20.1553722],
                [104.9173321, 20.1552676],
                [104.9180303, 20.1553579],
                [104.9186015, 20.155448],
                [104.9188077, 20.1555981],
                [104.9190137, 20.1561529],
                [104.9193309, 20.1566928],
                [104.9197116, 20.1570229],
                [104.9202193, 20.157278],
                [104.9209174, 20.1574733],
                [104.9215521, 20.1575186],
                [104.9216378, 20.1575321],
                [104.9220281, 20.1575938],
                [104.9228693, 20.1574892],
                [104.9234722, 20.1575044],
                [104.9244083, 20.1577597],
                [104.9251541, 20.1580749],
                [104.9262172, 20.1584053],
                [104.9266143, 20.1585931],
                [104.9270509, 20.1589125],
                [104.9271383, 20.1589244],
                [104.9271758, 20.158889],
                [104.9275607, 20.1583706],
                [104.9277311, 20.1582724],
                [104.9279106, 20.1580723],
                [104.9280409, 20.1576875],
                [104.9283838, 20.1561021],
                [104.9285312, 20.1545473],
                [104.9286618, 20.1539625],
                [104.9289877, 20.1536393],
                [104.9294929, 20.1534086],
                [104.9301448, 20.1529163],
                [104.930259, 20.1526238],
                [104.9301127, 20.1517463],
                [104.9299501, 20.1510381],
                [104.9298689, 20.1503761],
                [104.929641, 20.1498834],
                [104.9289734, 20.1493751],
                [104.928664, 20.1490672],
                [104.9282164, 20.1485973],
                [104.927091, 20.1480279],
                [104.9256513, 20.1477306],
                [104.9247876, 20.1475075],
                [104.9240809, 20.1470372],
                [104.9225369, 20.1461212],
                [104.9220399, 20.1454779],
                [104.9217784, 20.1447851],
                [104.9217453, 20.1438911],
                [104.9217139, 20.1433806],
                [104.9217615, 20.143378],
                [104.9217323, 20.1429117],
                [104.921694, 20.1429138],
                [104.9217267, 20.1423828],
                [104.9217964, 20.1420539],
                [104.9219183, 20.1418237],
                [104.9226149, 20.141314],
                [104.9233964, 20.1408818],
                [104.9239636, 20.1406002],
                [104.9246004, 20.1402111],
                [104.9256065, 20.1392284],
                [104.9256371, 20.1390789],
                [104.9255624, 20.1387957],
                [104.9255375, 20.1386187],
                [104.92555, 20.1384064],
                [104.9258497, 20.1377943],
                [104.9260373, 20.1376043],
                [104.9260873, 20.1373565],
                [104.9259495, 20.1365163],
                [104.9259444, 20.1364261],
                [104.9259448, 20.1357582],
                [104.9264688, 20.1347938],
                [104.9268878, 20.1341507],
                [104.9274116, 20.1336562],
                [104.9285636, 20.1328652],
                [104.9297419, 20.1319751],
                [104.9302657, 20.1313816],
                [104.9315749, 20.1305906],
                [104.9326484, 20.1296757],
                [104.9338244, 20.1284689],
                [104.9343482, 20.1280486],
                [104.9343483, 20.1277765],
                [104.9341129, 20.1273807],
                [104.9340864, 20.1270116],
                [104.9343221, 20.1263191],
                [104.9346888, 20.1260719],
                [104.935605, 20.1258248],
                [104.936495, 20.1254789],
                [104.9374376, 20.1247371],
                [104.938223, 20.1242179],
                [104.9388513, 20.1239955],
                [104.9400029, 20.1238722],
                [104.9409453, 20.123551],
                [104.9426467, 20.1231063],
                [104.9433273, 20.122785],
                [104.9446886, 20.1222164],
                [104.9452907, 20.1219941],
                [104.9464945, 20.1222171],
                [104.9475677, 20.1220441],
                [104.9476323, 20.1220049],
                [104.9484685, 20.1216974],
                [104.9486925, 20.1215451],
                [104.9487201, 20.1215263],
                [104.9489717, 20.1212126],
                [104.9490321, 20.1208989],
                [104.9492132, 20.1204711],
                [104.9495275, 20.1200101],
                [104.9502644, 20.1196455],
                [104.950788, 20.1191263],
                [104.9513902, 20.118508],
                [104.9517044, 20.117766],
                [104.9519928, 20.1166034],
                [104.9524118, 20.1157625],
                [104.9530139, 20.1149464],
                [104.9533543, 20.114724],
                [104.9538516, 20.1146498],
                [104.9544535, 20.114452],
                [104.9551603, 20.1141554],
                [104.9558147, 20.1140072],
                [104.9564691, 20.1135126],
                [104.9569927, 20.1129933],
                [104.9576412, 20.1127745],
                [104.957654, 20.1128086],
                [104.9583801, 20.1128455],
                [104.9590067, 20.1125825],
                [104.9596679, 20.1124346],
                [104.9604858, 20.1123033],
                [104.9611993, 20.1121224],
                [104.9614082, 20.1118593],
                [104.9615127, 20.1115797],
                [104.9614779, 20.1112507],
                [104.9611999, 20.1101485],
                [104.9610236, 20.1090464],
                [104.9605977, 20.1063813],
                [104.9605994, 20.1063223],
                [104.9606214, 20.1063229],
                [104.9604281, 20.1057506],
                [104.9603679, 20.1054652],
                [104.9603479, 20.1053037],
                [104.9603881, 20.105142],
                [104.960539, 20.1049614],
                [104.9610597, 20.1044689],
                [104.9615453, 20.1034804],
                [104.9616796, 20.1031986],
                [104.9625434, 20.10216],
                [104.9635381, 20.1011213],
                [104.9644282, 20.1002804],
                [104.9650039, 20.0998599],
                [104.9659461, 20.0995633],
                [104.9671761, 20.0993657],
                [104.9677519, 20.0993162],
                [104.9683276, 20.099638],
                [104.9686416, 20.0998112],
                [104.9692697, 20.0996382],
                [104.9700549, 20.099292],
                [104.9705261, 20.0987974],
                [104.9712326, 20.0982532],
                [104.9720963, 20.0980555],
                [104.9729862, 20.0981793],
                [104.9730316, 20.0981912],
                [104.9740328, 20.0984516],
                [104.974687, 20.0985012],
                [104.9758124, 20.0981551],
                [104.9771994, 20.0978335],
                [104.9785864, 20.0976112],
                [104.9804445, 20.0973393],
                [104.9817268, 20.0973147],
                [104.982826, 20.0975621],
                [104.9844222, 20.097785],
                [104.9845228, 20.097785],
                [104.9869201, 20.0973111],
                [104.9880706, 20.0970695],
                [104.9900518, 20.0968883],
                [104.9911703, 20.0964957],
                [104.9919053, 20.0960728],
                [104.9927361, 20.0954081],
                [104.9937678, 20.0948072],
                [104.9915858, 20.0946225],
                [104.9910426, 20.0945017],
                [104.9899009, 20.0939361],
                [104.9896939, 20.0937428],
                [104.9895174, 20.0934829],
                [104.9895341, 20.0931168],
                [104.9896254, 20.0928039],
                [104.9896255, 20.0924693],
                [104.9894885, 20.0922209],
                [104.9888607, 20.0917029],
                [104.9886552, 20.0915626],
                [104.9880616, 20.091228],
                [104.9875937, 20.0908286],
                [104.9873654, 20.0903969],
                [104.9871125, 20.0898489],
                [104.9851953, 20.0888517],
                [104.984812, 20.0878245],
                [104.9846282, 20.0873185],
                [104.9836856, 20.0870465],
                [104.9824572, 20.0866623],
                [104.9823088, 20.0865759],
                [104.9817381, 20.0863384],
                [104.9813386, 20.085939],
                [104.9805544, 20.0855356],
                [104.9800488, 20.0852158],
                [104.9799474, 20.0848558],
                [104.9799576, 20.0846114],
                [104.9800273, 20.0843422],
                [104.9806083, 20.0835214],
                [104.9814175, 20.0822729],
                [104.9813855, 20.0819707],
                [104.9809063, 20.0817592],
                [104.9794366, 20.0808829],
                [104.97851, 20.0804598],
                [104.9762734, 20.0800063],
                [104.9759974, 20.0796247],
                [104.9758604, 20.0790418],
                [104.9757577, 20.0788259],
                [104.9756208, 20.0787181],
                [104.9753469, 20.0783078],
                [104.9757626, 20.0774987],
                [104.975758, 20.077261],
                [104.9756669, 20.0770456],
                [104.9751877, 20.0765017],
                [104.9746742, 20.0735266],
                [104.9746858, 20.0727819],
                [104.9743548, 20.0722207],
                [104.9739423, 20.0713656],
                [104.9733995, 20.069311],
                [104.9726891, 20.0683458],
                [104.9723581, 20.0680328],
                [104.9722668, 20.0678925],
                [104.9722212, 20.0676658],
                [104.9727008, 20.0668565],
                [104.973496, 20.066169],
                [104.9739114, 20.0655348],
                [104.9738795, 20.0648399],
                [104.9737854, 20.064601],
                [104.9735344, 20.0639939],
                [104.9733142, 20.0635513],
                [104.9729289, 20.0630305],
                [104.9718913, 20.0615787],
                [104.9705888, 20.0611815],
                [104.9687167, 20.060374],
                [104.9678082, 20.0599312],
                [104.9662114, 20.0592019],
                [104.965358, 20.0585768],
                [104.9640368, 20.0571967],
                [104.9634037, 20.0563634],
                [104.9631285, 20.0557905],
                [104.9628533, 20.0551656],
                [104.9624131, 20.0537335],
                [104.9622205, 20.0531606],
                [104.9619178, 20.0525356],
                [104.961421, 20.0518568],
                [104.9612956, 20.0517002],
                [104.9605447, 20.0508136],
                [104.9597863, 20.0503768],
                [104.9589951, 20.0498152],
                [104.9583356, 20.0495031],
                [104.957132, 20.0492532],
                [104.9561099, 20.0489723],
                [104.9551867, 20.0484418],
                [104.9543625, 20.0478801],
                [104.953703, 20.0475057],
                [104.9530106, 20.0473183],
                [104.9520543, 20.0473492],
                [104.9511639, 20.0475985],
                [104.950496, 20.0475219],
                [104.9497443, 20.0474144],
                [104.9492634, 20.0473489],
                [104.9489325, 20.0473057],
                [104.9486722, 20.0465373],
                [104.9486058, 20.0460739],
                [104.9485425, 20.0457738],
                [104.9481886, 20.0452753],
                [104.9480774, 20.0446698],
                [104.9480033, 20.0445207],
                [104.9479291, 20.0444329],
                [104.9477066, 20.0441872],
                [104.9473913, 20.0439853],
                [104.9471257, 20.0438544],
                [104.9462814, 20.0435222],
                [104.945438, 20.0431682],
                [104.9452889, 20.0430977],
                [104.9449694, 20.0429062],
                [104.9448177, 20.0428868],
                [104.9443836, 20.0429866],
                [104.9441066, 20.0430268],
                [104.9439895, 20.0430067],
                [104.9439682, 20.0429563],
                [104.9441175, 20.0424224],
                [104.9440941, 20.0420297],
                [104.9438514, 20.0417071],
                [104.9437663, 20.0414753],
                [104.9437344, 20.0413242],
                [104.943744, 20.0411339],
                [104.9436706, 20.0409113],
                [104.9435323, 20.0408306],
                [104.9435002, 20.0408104],
                [104.9432553, 20.0407499],
                [104.9429139, 20.0407637],
                [104.9419496, 20.0408273],
                [104.9414808, 20.0408372],
                [104.9412181, 20.0407875],
                [104.9402547, 20.0403184],
                [104.9395943, 20.0397974],
                [104.9386861, 20.03899],
                [104.9374752, 20.037844],
                [104.9367321, 20.0372189],
                [104.9357965, 20.0363593],
                [104.9347508, 20.0353175],
                [104.9339664, 20.0348049],
                [104.9334115, 20.0343106],
                [104.9325956, 20.0332602],
                [104.9319429, 20.0323025],
                [104.9304743, 20.030449],
                [104.9298216, 20.0295839],
                [104.9294954, 20.0288734],
                [104.9290839, 20.0282065],
                [104.9288101, 20.0280013],
                [104.9285021, 20.0279364],
                [104.9282169, 20.0278932],
                [104.9280919, 20.0276375],
                [104.9279614, 20.0273287],
                [104.9278309, 20.0271124],
                [104.9276125, 20.0268676],
                [104.9272434, 20.0265563],
                [104.9268845, 20.0258767],
                [104.9268848, 20.0254752],
                [104.9271135, 20.025012],
                [104.9273424, 20.0241782],
                [104.9273858, 20.0236405],
                [104.926853, 20.0234368],
                [104.9263249, 20.0230464],
                [104.9260698, 20.02251],
                [104.9260373, 20.0220466],
                [104.9260176, 20.0214921],
                [104.9258808, 20.0212115],
                [104.9256754, 20.0210711],
                [104.9253218, 20.0208443],
                [104.9249932, 20.0207183],
                [104.9246015, 20.0205019],
                [104.9239812, 20.0201927],
                [104.9237933, 20.0200018],
                [104.9232313, 20.0190843],
                [104.9229724, 20.0187387],
                [104.9226986, 20.0185335],
                [104.9223563, 20.0184254],
                [104.9212842, 20.0183739],
                [104.9208153, 20.0176281],
                [104.9204564, 20.0172263],
                [104.9196078, 20.01667],
                [104.9185123, 20.0161141],
                [104.9182115, 20.0159429],
                [104.9181274, 20.0157237],
                [104.9181476, 20.0154663],
                [104.9182163, 20.0150779],
                [104.9182621, 20.014603],
                [104.9182599, 20.014496],
                [104.9182686, 20.0142894],
                [104.9183389, 20.0140311],
                [104.9192444, 20.0134141],
                [104.9198131, 20.0129763],
                [104.920087, 20.0126179],
                [104.9201505, 20.0120802],
                [104.9201507, 20.0115624],
                [104.920151, 20.010965],
                [104.9202566, 20.0103277],
                [104.9202919, 20.0100318],
                [104.9201868, 20.0096933],
                [104.9200674, 20.0094341],
                [104.9198509, 20.0090455],
                [104.9197099, 20.008814],
                [104.9190997, 20.0082162],
                [104.9187219, 20.0079009],
                [104.9185281, 20.0077281],
                [104.9182884, 20.007782],
                [104.9180944, 20.0078683],
                [104.9177293, 20.0080515],
                [104.9174439, 20.0081161],
                [104.9170997, 20.00817],
                [104.9168471, 20.0081699],
                [104.9167481, 20.0079432],
                [104.9163377, 20.0072307],
                [104.9161896, 20.006896],
                [104.915973, 20.0063886],
                [104.9159268, 20.0059951],
                [104.9162126, 20.0053453],
                [104.9162549, 20.0052053],
                [104.9163396, 20.0049653],
                [104.9166175, 20.004471],
                [104.916657, 20.0044056],
                [104.9166676, 20.0042856],
                [104.9166177, 20.0040925],
                [104.9163651, 20.0040128],
                [104.9160283, 20.0038732],
                [104.9154179, 20.0036539],
                [104.9149548, 20.0035939],
                [104.9144285, 20.0035937],
                [104.9140706, 20.0035138],
                [104.9136707, 20.0033941],
                [104.9133129, 20.0031749],
                [104.9129551, 20.0030153],
                [104.9125131, 20.0028559],
                [104.9121974, 20.0026765],
                [104.911966, 20.0024772],
                [104.9118895, 20.0023505],
                [104.9118925, 20.0019083],
                [104.9119422, 20.0015247],
                [104.912028, 20.0011078],
                [104.912146, 20.000698],
                [104.9123693, 20.0006036],
                [104.9125755, 20.0004797],
                [104.9127603, 20.0003288],
                [104.912917, 20.0001577],
                [104.9130439, 19.999971],
                [104.9131417, 19.9997694],
                [104.9132084, 19.9995568],
                [104.9132296, 19.9994478],
                [104.9134088, 19.998995],
                [104.9135086, 19.9987723],
                [104.9137409, 19.9983117],
                [104.9140021, 19.997865],
                [104.9142805, 19.9974557],
                [104.9144306, 19.997258],
                [104.9147517, 19.9968773],
                [104.9150993, 19.9965178],
                [104.915472, 19.9961813],
                [104.9163946, 19.9963325],
                [104.9194309, 19.9975726],
                [104.923304, 19.9955663],
                [104.9235856, 19.9954041],
                [104.9237192, 19.9953129],
                [104.9239704, 19.9951114],
                [104.9242057, 19.9948776],
                [104.9243165, 19.9947475],
                [104.9244194, 19.9946117],
                [104.9245141, 19.9944707],
                [104.9246001, 19.994325],
                [104.9248255, 19.993859],
                [104.9250957, 19.9932171],
                [104.9252962, 19.9926664],
                [104.9254712, 19.9921081],
                [104.9262759, 19.9906461],
                [104.9264238, 19.9904491],
                [104.9265981, 19.9902723],
                [104.9266943, 19.9901924],
                [104.9269001, 19.9900531],
                [104.9270098, 19.9899933],
                [104.9271235, 19.9899403],
                [104.9293443, 19.9890329],
                [104.9318334, 19.9891035],
                [104.9343654, 19.9888414],
                [104.9351594, 19.9890228],
                [104.9360391, 19.9887456],
                [104.936242, 19.9887002],
                [104.9364468, 19.9886638],
                [104.9366533, 19.9886365],
                [104.9368608, 19.9886184],
                [104.9370691, 19.9886095],
                [104.9372575, 19.9886093],
                [104.9376335, 19.9886316],
                [104.9380061, 19.9886839],
                [104.9383727, 19.9887657],
                [104.9403736, 19.9894665],
                [104.9412963, 19.9899807],
                [104.941372, 19.990009],
                [104.9415294, 19.9900482],
                [104.9416924, 19.9900634],
                [104.9418569, 19.9900538],
                [104.9421213, 19.9898536],
                [104.9424041, 19.9896767],
                [104.9427249, 19.9895147],
                [104.9429026, 19.9894405],
                [104.9430841, 19.9893751],
                [104.943269, 19.9893186],
                [104.9434568, 19.9892712],
                [104.943647, 19.9892331],
                [104.943839, 19.9892043],
                [104.9445042, 19.9891942],
                [104.9448224, 19.9890392],
                [104.944987, 19.9889724],
                [104.9453419, 19.9888565],
                [104.9455234, 19.9888111],
                [104.9458802, 19.9889525],
                [104.9462328, 19.9891026],
                [104.9465812, 19.9892614],
                [104.946925, 19.9894287],
                [104.947264, 19.9896044],
                [104.947598, 19.9897885],
                [104.9482185, 19.9901609],
                [104.9487879, 19.9905407],
                [104.9493377, 19.9909454],
                [104.9498664, 19.991374],
                [104.9503729, 19.9918258],
                [104.95298, 19.991856],
                [104.9546751, 19.9908478],
                [104.957411, 19.9930558],
                [104.9594215, 19.993149],
                [104.9595573, 19.9931325],
                [104.9596859, 19.9930881],
                [104.9597455, 19.993056],
                [104.9598568, 19.992969],
                [104.9599059, 19.9929139],
                [104.9599804, 19.9927887],
                [104.9600852, 19.992471],
                [104.9604349, 19.9921145],
                [104.9611512, 19.9914162],
                [104.9615174, 19.9910746],
                [104.9622008, 19.9904634],
                [104.9629015, 19.9898698],
                [104.9636471, 19.9862753],
                [104.9635848, 19.9840337],
                [104.9635613, 19.98319],
                [104.9641728, 19.9805684],
                [104.9642693, 19.9803951],
                [104.9643921, 19.9802372],
                [104.9645407, 19.9800963],
                [104.9646243, 19.9800333],
                [104.9648057, 19.9799262],
                [104.9653029, 19.9796978],
                [104.9660933, 19.979308],
                [104.9662687, 19.9792014],
                [104.9664226, 19.9790686],
                [104.9665506, 19.9789135],
                [104.9666492, 19.9787404],
                [104.9667155, 19.9785542],
                [104.9667478, 19.9783602],
                [104.9667506, 19.9782571],
                [104.9667438, 19.9781541],
                [104.9667274, 19.978052],
                [104.9667014, 19.9779518],
                [104.9666661, 19.9778541],
                [104.9666218, 19.9777596],
                [104.9665688, 19.9776693],
                [104.9665074, 19.9775837],
                [104.9664383, 19.9775035],
                [104.966362, 19.9774294],
                [104.9662789, 19.9773618],
                [104.9658063, 19.9770267],
                [104.9657278, 19.9769185],
                [104.965698, 19.9768592],
                [104.965658, 19.9767293],
                [104.9656493, 19.97666],
                [104.9656565, 19.9765207],
                [104.9656963, 19.9763864],
                [104.9662328, 19.9753957],
                [104.9663492, 19.9752849],
                [104.9664876, 19.9751991],
                [104.966566, 19.9751658],
                [104.9667319, 19.975124],
                [104.9668175, 19.9751159],
                [104.9677348, 19.9750907],
                [104.9680148, 19.9749829],
                [104.968159, 19.9749397],
                [104.9684537, 19.9748755],
                [104.9686034, 19.9748545],
                [104.9689207, 19.9748352],
                [104.9690874, 19.9748385],
                [104.9692536, 19.9748511],
                [104.9694188, 19.9748729],
                [104.9695823, 19.9749037],
                [104.9697435, 19.9749436],
                [104.969902, 19.9749924],
                [104.970416, 19.9752293],
                [104.9706777, 19.9753384],
                [104.9712094, 19.9755377],
                [104.9717702, 19.9757167],
                [104.9723594, 19.9758722],
                [104.9726572, 19.9759385],
                [104.973258, 19.9760482],
                [104.9741202, 19.9761362],
                [104.9745607, 19.9761904],
                [104.9749997, 19.9762539],
                [104.9754371, 19.9763269],
                [104.9758726, 19.9764091],
                [104.976671, 19.9765853],
                [104.9770671, 19.9766852],
                [104.9778521, 19.9769082],
                [104.9785942, 19.9760842],
                [104.9793541, 19.9752747],
                [104.9802703, 19.9743416],
                [104.9812102, 19.9734295],
                [104.9818218, 19.9723001],
                [104.9855447, 19.9714733],
                [104.9857489, 19.9713982],
                [104.9859397, 19.9712967],
                [104.9861133, 19.9711707],
                [104.9862625, 19.971027],
                [104.9863892, 19.9708654],
                [104.9864909, 19.9706888],
                [104.9865317, 19.970596],
                [104.9866448, 19.9704194],
                [104.9868882, 19.9700767],
                [104.9870184, 19.9699108],
                [104.987306, 19.9695792],
                [104.9874639, 19.9694144],
                [104.9877971, 19.9691003],
                [104.9881521, 19.968808],
                [104.9885273, 19.9685389],
                [104.9908876, 19.9670061],
                [104.9921536, 19.9658465],
                [104.9941727, 19.9650342],
                [104.9951363, 19.9646465],
                [104.997282, 19.9633759],
                [104.9987304, 19.9626599],
                [104.9992149, 19.9623007],
                [104.999464, 19.9621295],
                [104.9999711, 19.961807],
                [105.0004899, 19.9615103],
                [105.0006376, 19.9613949],
                [105.0007045, 19.96133],
                [105.0008225, 19.9611876],
                [105.0009237, 19.9610191],
                [105.0009962, 19.9608381],
                [105.0010384, 19.9606487],
                [105.0010643, 19.9603889],
                [105.0010715, 19.9600608],
                [105.0010446, 19.9597336],
                [105.0009836, 19.9594105],
                [105.0008892, 19.9590944],
                [105.0008297, 19.95894],
                [105.000687, 19.9586403],
                [105.0006041, 19.9584957],
                [105.000416, 19.958219],
                [105.0003114, 19.9580874],
                [105.0002001, 19.9579609],
                [104.999982, 19.9577424],
                [104.9998028, 19.9575307],
                [104.9996545, 19.9572988],
                [104.9995396, 19.9570508],
                [104.99946, 19.9567908],
                [104.9994332, 19.9566535],
                [104.9994163, 19.9565148],
                [104.9994094, 19.9563754],
                [104.9994125, 19.9562358],
                [104.9994256, 19.9560967],
                [104.9994486, 19.9559588],
                [104.9994814, 19.9558227],
                [104.9995596, 19.9556597],
                [104.999647, 19.9555008],
                [104.9997432, 19.9553465],
                [104.999848, 19.9551973],
                [104.9999611, 19.9550535],
                [105.0000822, 19.9549156],
                [105.000211, 19.954784],
                [105.0003468, 19.9546591],
                [105.0004895, 19.9545412],
                [105.0006387, 19.9544306],
                [105.0007939, 19.9543276],
                [105.0009548, 19.9542326],
                [105.0011207, 19.9541457],
                [105.0012914, 19.9540672],
                [105.0014663, 19.9539973],
                [105.001638, 19.9539019],
                [105.0019708, 19.9536951],
                [105.0021315, 19.9535839],
                [105.0024696, 19.9533224],
                [105.0026308, 19.953183],
                [105.0027863, 19.9530382],
                [105.0031206, 19.9526983],
                [105.0033224, 19.952525],
                [105.0034564, 19.9524256],
                [105.0035961, 19.9523335],
                [105.0037412, 19.9522489],
                [105.003891, 19.952172],
                [105.0048888, 19.9517283],
                [105.0064766, 19.9529384],
                [105.0067299, 19.9530747],
                [105.0069996, 19.9531795],
                [105.0072813, 19.9532511],
                [105.007541, 19.9532861],
                [105.0078032, 19.9532928],
                [105.0083672, 19.9532444],
                [105.0089746, 19.9532173],
                [105.0095826, 19.9532259],
                [105.01022, 19.9532617],
                [105.0108566, 19.9533071],
                [105.0114924, 19.953362],
                [105.0126378, 19.9534853],
                [105.0134402, 19.9535908],
                [105.0136726, 19.953615],
                [105.0139057, 19.9536312],
                [105.0143731, 19.9536393],
                [105.0148292, 19.9536161],
                [105.0152824, 19.9535623],
                [105.0155071, 19.9535239],
                [105.0172538, 19.9531704],
                [105.0175958, 19.9530627],
                [105.0177624, 19.9529976],
                [105.018085, 19.9528458],
                [105.018391, 19.9526661],
                [105.0185481, 19.9525584],
                [105.0186991, 19.9524433],
                [105.0188438, 19.9523211],
                [105.0189816, 19.9521922],
                [105.0191124, 19.9520569],
                [105.0192357, 19.9519156],
                [105.0193512, 19.9517686],
                [105.0196229, 19.9513826],
                [105.0197703, 19.9511972],
                [105.0199252, 19.9510173],
                [105.0200951, 19.9508352],
                [105.0202725, 19.9506596],
                [105.0204574, 19.9504909],
                [105.0206493, 19.9503294],
                [105.0208479, 19.9501752],
                [105.0226772, 19.9486322],
                [105.0231162, 19.9483632],
                [105.0235833, 19.9481163],
                [105.0240777, 19.9478944],
                [105.0245869, 19.9477043],
                [105.0251233, 19.9468774],
                [105.0257993, 19.946363],
                [105.0272369, 19.9449511],
                [105.0276822, 19.9450166],
                [105.0281375, 19.9449678],
                [105.0283632, 19.9449308],
                [105.0288092, 19.9448315],
                [105.0290286, 19.9447695],
                [105.0294067, 19.9446412],
                [105.0296676, 19.9445372],
                [105.0298266, 19.9444967],
                [105.0299084, 19.9444871],
                [105.0299906, 19.9444849],
                [105.0301538, 19.9445021],
                [105.0302334, 19.9445214],
                [105.030874, 19.9447443],
                [105.0310779, 19.944791],
                [105.0312867, 19.9448096],
                [105.0314963, 19.9447998],
                [105.031725, 19.9447556],
                [105.0319432, 19.9446776],
                [105.0321454, 19.9445678],
                [105.0327301, 19.9446838],
                [105.0329777, 19.944613],
                [105.0332161, 19.9445181],
                [105.0334471, 19.9443974],
                [105.0336674, 19.944251],
                [105.0337708, 19.9441694],
                [105.0337413, 19.9438971],
                [105.0337388, 19.9435627],
                [105.0337732, 19.9432299],
                [105.0338042, 19.9430652],
                [105.0339056, 19.9428029],
                [105.0339693, 19.9426768],
                [105.0342643, 19.9423692],
                [105.0343611, 19.9423396],
                [105.0344122, 19.9423375],
                [105.0345071, 19.9423572],
                [105.0345861, 19.9424029],
                [105.0346452, 19.94247],
                [105.0347391, 19.9426557],
                [105.0348658, 19.9428233],
                [105.035012, 19.9429603],
                [105.0350885, 19.9430164],
                [105.0352544, 19.9431113],
                [105.0354338, 19.9431811],
                [105.0355383, 19.94307],
                [105.0355817, 19.9430079],
                [105.0356483, 19.9428735],
                [105.0356718, 19.9427996],
                [105.0356948, 19.942647],
                [105.0356941, 19.9425699],
                [105.0356682, 19.9424177],
                [105.0356108, 19.9422734],
                [105.0355478, 19.9420663],
                [105.0355078, 19.9418531],
                [105.0354967, 19.9417447],
                [105.0354928, 19.9415271],
                [105.0355303, 19.9409017],
                [105.0355469, 19.9408062],
                [105.0356022, 19.9406197],
                [105.0356406, 19.9405298],
                [105.0357456, 19.9403479],
                [105.0358134, 19.940258],
                [105.0358891, 19.9401737],
                [105.0359719, 19.9400956],
                [105.0360614, 19.9400243],
                [105.0361217, 19.9399622],
                [105.0362214, 19.9398229],
                [105.0362599, 19.9397469],
                [105.0363099, 19.9395962],
                [105.0363618, 19.9392174],
                [105.0363816, 19.9391735],
                [105.0364433, 19.939098],
                [105.0364833, 19.9390686],
                [105.0365771, 19.9390306],
                [105.0366799, 19.9390245],
                [105.0367306, 19.9390339],
                [105.0368231, 19.9390762],
                [105.0370846, 19.9392849],
                [105.0376171, 19.9396915],
                [105.038179, 19.9400955],
                [105.0385244, 19.9403328],
                [105.0386294, 19.9404308],
                [105.0387118, 19.9405463],
                [105.0387436, 19.9406093],
                [105.0387865, 19.9407419],
                [105.038801, 19.9408798],
                [105.0387865, 19.9410177],
                [105.0387704, 19.9414716],
                [105.0388251, 19.9416245],
                [105.0389115, 19.9417639],
                [105.039026, 19.9418842],
                [105.0391622, 19.9419794],
                [105.0393142, 19.9420466],
                [105.0394774, 19.9420843],
                [105.0396452, 19.9420911],
                [105.0397288, 19.9420827],
                [105.0423002, 19.9416229],
                [105.0426204, 19.9415741],
                [105.0429439, 19.9415523],
                [105.0431108, 19.9415516],
                [105.0432775, 19.9415581],
                [105.0436091, 19.9415926],
                [105.0438112, 19.9414787],
                [105.0439057, 19.9414125],
                [105.0440839, 19.9412589],
                [105.0442416, 19.9410794],
                [105.0443101, 19.9409822],
                [105.0444245, 19.9407757],
                [105.0446817, 19.9403652],
                [105.0447985, 19.9401537],
                [105.0450111, 19.9397128],
                [105.045106, 19.9394836],
                [105.0452688, 19.9390163],
                [105.0453365, 19.9387787],
                [105.0454176, 19.9385688],
                [105.0455077, 19.9383622],
                [105.0456065, 19.9381591],
                [105.045714, 19.93796],
                [105.045951, 19.9375795],
                [105.0462158, 19.9372224],
                [105.0465096, 19.9368861],
                [105.0466669, 19.9367263],
                [105.0476164, 19.9358942],
                [105.0471907, 19.9351005],
                [105.0468171, 19.9344821],
                [105.0463422, 19.9337281],
                [105.0458407, 19.9329894],
                [105.0458118, 19.9329023],
                [105.0457921, 19.9328129],
                [105.0457806, 19.9326375],
                [105.0458028, 19.9324698],
                [105.0458259, 19.932388],
                [105.0458568, 19.9323086],
                [105.0463128, 19.9313252],
                [105.0468337, 19.9315835],
                [105.0470881, 19.9317228],
                [105.0475842, 19.9320211],
                [105.0478137, 19.9321721],
                [105.0482596, 19.9324908],
                [105.0484756, 19.9326585],
                [105.0488931, 19.9330096],
                [105.0492575, 19.9331472],
                [105.0496319, 19.9332584],
                [105.0500143, 19.9333424],
                [105.0502064, 19.9333739],
                [105.0505937, 19.9334164],
                [105.0509833, 19.9334312],
                [105.0514538, 19.9334161],
                [105.0517287, 19.9333949],
                [105.0520027, 19.9333646],
                [105.0522753, 19.9333252],
                [105.0525463, 19.9332769],
                [105.0539109, 19.9328657],
                [105.0554634, 19.9324211],
                [105.0563335, 19.9321825],
                [105.0566249, 19.9321508],
                [105.0569183, 19.9321523],
                [105.0570483, 19.9321635],
                [105.0573056, 19.9322056],
                [105.0575566, 19.9322733],
                [105.0583452, 19.932533],
                [105.0586693, 19.9325823],
                [105.058997, 19.9326011],
                [105.0591783, 19.9325983],
                [105.0593591, 19.9325861],
                [105.0595389, 19.9325646],
                [105.0597173, 19.9325338],
                [105.0598935, 19.9324938],
                [105.0600672, 19.9324448],
                [105.0603798, 19.9325873],
                [105.0607063, 19.9326995],
                [105.0610431, 19.93278],
                [105.0613868, 19.932828],
                [105.0617452, 19.9328431],
                [105.0621033, 19.9328229],
                [105.062281, 19.9327997],
                [105.0627886, 19.9326988],
                [105.0631063, 19.9326619],
                [105.0632661, 19.9326534],
                [105.0635862, 19.9326566],
                [105.0637634, 19.9326699],
                [105.0641145, 19.9327211],
                [105.0642877, 19.9327589],
                [105.0646269, 19.9328583],
                [105.0660217, 19.9333323],
                [105.0665498, 19.933445],
                [105.067165, 19.9335498],
                [105.0675159, 19.9335971],
                [105.0682211, 19.9336652],
                [105.070281, 19.9345023],
                [105.070483, 19.9345779],
                [105.0708964, 19.9347041],
                [105.0711071, 19.9347545],
                [105.0715285, 19.9348285],
                [105.071955, 19.9348684],
                [105.0721693, 19.9348755],
                [105.0724993, 19.9348723],
                [105.0731592, 19.9348824],
                [105.073899, 19.9349199],
                [105.0743083, 19.9349526],
                [105.0751242, 19.9350434],
                [105.0759351, 19.935168],
                [105.0764628, 19.9352725],
                [105.076729, 19.9353134],
                [105.0772647, 19.9353721],
                [105.077818, 19.9354003],
                [105.0781025, 19.9354021],
                [105.0783869, 19.9353952],
                [105.0788948, 19.9353616],
                [105.0790072, 19.9353657],
                [105.0792289, 19.9354],
                [105.0793346, 19.9354293],
                [105.0795367, 19.9355116],
                [105.0796318, 19.9355641],
                [105.0798067, 19.93569],
                [105.0799576, 19.9358409],
                [105.0804412, 19.9364792],
                [105.0805506, 19.9365545],
                [105.0806773, 19.9366002],
                [105.0807359, 19.9366099],
                [105.0808547, 19.9366099],
                [105.0809133, 19.9366002],
                [105.0811186, 19.9365237],
                [105.0813317, 19.9364691],
                [105.0815718, 19.936435],
                [105.0818145, 19.9364287],
                [105.0837564, 19.9379113],
                [105.083928, 19.9378998],
                [105.0840948, 19.9378604],
                [105.0842519, 19.9377945],
                [105.0843253, 19.9377521],
                [105.084459, 19.9376502],
                [105.0845182, 19.9375915],
                [105.0849903, 19.9370541],
                [105.0853176, 19.9369795],
                [105.0856482, 19.9369346],
                [105.085979, 19.9369193],
                [105.0863099, 19.936933],
                [105.0865073, 19.9368453],
                [105.086611, 19.9368128],
                [105.0868249, 19.9367717],
                [105.0870511, 19.9367633],
                [105.0872757, 19.93679],
                [105.0873855, 19.9368164],
                [105.0875963, 19.9368942],
                [105.0876958, 19.9369451],
                [105.0880928, 19.9371785],
                [105.0884005, 19.9373449],
                [105.0887132, 19.9375029],
                [105.0892068, 19.9377303],
                [105.0897109, 19.9379366],
                [105.0898953, 19.9380188],
                [105.0902535, 19.9382024],
                [105.0905961, 19.9384106],
                [105.0907936, 19.9385717],
                [105.0909659, 19.9387567],
                [105.0910416, 19.9388571],
                [105.0911701, 19.9390712],
                [105.0913722, 19.9392144],
                [105.0915792, 19.9393513],
                [105.0920069, 19.9396058],
                [105.092398, 19.9398081],
                [105.0928008, 19.939989],
                [105.0930604, 19.9396745],
                [105.0933403, 19.9393757],
                [105.0936742, 19.9390632],
                [105.0938676, 19.9389006],
                [105.0940674, 19.9387451],
                [105.0942734, 19.9385968],
                [105.0944853, 19.938456],
                [105.0946724, 19.9383182],
                [105.0950634, 19.9380642],
                [105.0952666, 19.9379484],
                [105.0956869, 19.9377399],
                [105.0960963, 19.9375733],
                [105.0963057, 19.9375009],
                [105.0967323, 19.9373783],
                [105.096949, 19.9373284],
                [105.0974349, 19.937237],
                [105.0976953, 19.9371618],
                [105.0978219, 19.9371146],
                [105.0980643, 19.9370027],
                [105.0981805, 19.936938],
                [105.0984013, 19.9367918],
                [105.0985541, 19.9367004],
                [105.0988734, 19.9365397],
                [105.099047, 19.9364676],
                [105.0992245, 19.9364042],
                [105.0994052, 19.9363496],
                [105.099984, 19.9362168],
                [105.1004079, 19.936137],
                [105.1006218, 19.9361078],
                [105.101053, 19.936071],
                [105.1012696, 19.9360634],
                [105.1017031, 19.9360702],
                [105.1021349, 19.936106],
                [105.1022415, 19.9361091],
                [105.1024528, 19.936136],
                [105.1025564, 19.9361597],
                [105.1027572, 19.936227],
                [105.1029705, 19.936334],
                [105.1031634, 19.936471],
                [105.1033535, 19.9366477],
                [105.1035763, 19.9368257],
                [105.1036956, 19.9369052],
                [105.1039481, 19.937044],
                [105.1040825, 19.9371036],
                [105.1043615, 19.9371998],
                [105.1045051, 19.9372361],
                [105.1047978, 19.9372843],
                [105.1049459, 19.9372961],
                [105.1051285, 19.9372859],
                [105.105491, 19.9372405],
                [105.1058149, 19.9371705],
                [105.1060994, 19.9370847],
                [105.1062387, 19.9370339],
                [105.1073009, 19.9365397],
                [105.1074107, 19.9365014],
                [105.1075168, 19.9364547],
                [105.1076183, 19.9363999],
                [105.1077147, 19.9363373],
                [105.1078854, 19.9361942],
                [105.1080263, 19.9360309],
                [105.108304, 19.9355815],
                [105.1094867, 19.9348635],
                [105.1097605, 19.9346742],
                [105.1100319, 19.9344565],
                [105.1102971, 19.9342088],
                [105.110541, 19.9339425],
                [105.1109729, 19.9336061],
                [105.1113832, 19.9332466],
                [105.1117651, 19.9328708],
                [105.1121235, 19.932475],
                [105.112256, 19.9324132],
                [105.1123273, 19.9323943],
                [105.1124644, 19.9323817],
                [105.1126009, 19.9323988],
                [105.1127297, 19.9324448],
                [105.1131494, 19.9326662],
                [105.1135558, 19.9329087],
                [105.1137958, 19.9330662],
                [105.1140301, 19.933231],
                [105.1142585, 19.9334029],
                [105.1146778, 19.9334959],
                [105.1148904, 19.9335293],
                [105.115319, 19.9335696],
                [105.1157498, 19.9335744],
                [105.1161806, 19.9335435],
                [105.1166068, 19.9334771],
                [105.1168172, 19.9334308],
                [105.1172304, 19.9333122],
                [105.1178351, 19.9331054],
                [105.1184291, 19.932873],
                [105.1187217, 19.9327473],
                [105.1187309, 19.932564],
                [105.118747, 19.9324735],
                [105.1188016, 19.9322973],
                [105.1188397, 19.9322128],
                [105.1189394, 19.9320498],
                [105.1189994, 19.9319744],
                [105.1191378, 19.9318387],
                [105.1193576, 19.9316685],
                [105.1194769, 19.931536],
                [105.1195267, 19.9314628],
                [105.1195693, 19.9313857],
                [105.119631, 19.9312254],
                [105.1196621, 19.9310574],
                [105.1196659, 19.9309722],
                [105.1198961, 19.9308154],
                [105.1201045, 19.9306335],
                [105.120288, 19.9304293],
                [105.1204437, 19.9302056],
                [105.1205661, 19.9299734],
                [105.1206586, 19.9297293],
                [105.1206933, 19.9296039],
                [105.1207387, 19.9293483],
                [105.1207753, 19.9292025],
                [105.1208748, 19.928918],
                [105.1210084, 19.9286462],
                [105.1210874, 19.9285162],
                [105.1212556, 19.9282859],
                [105.1214491, 19.9280739],
                [105.1215547, 19.9279755],
                [105.1217818, 19.9277955],
                [105.1219028, 19.9277143],
                [105.1229596, 19.9271696],
                [105.1230796, 19.927079],
                [105.1233052, 19.9268821],
                [105.1235101, 19.9266654],
                [105.1236925, 19.926431],
                [105.1238501, 19.9261812],
                [105.1243223, 19.9259131],
                [105.1248118, 19.9256741],
                [105.1252851, 19.9254772],
                [105.125738, 19.9253178],
                [105.1259678, 19.9252471],
                [105.1261997, 19.9251826],
                [105.1275194, 19.9250615],
                [105.127732, 19.9250165],
                [105.1278355, 19.9249841],
                [105.1280343, 19.9249002],
                [105.1282395, 19.9247784],
                [105.1284233, 19.9246293],
                [105.1285058, 19.9245456],
                [105.1287411, 19.92426],
                [105.1289248, 19.9240831],
                [105.1291495, 19.9239156],
                [105.1293958, 19.9237774],
                [105.1308265, 19.9231337],
                [105.131483, 19.9228528],
                [105.1321435, 19.9225802],
                [105.1332968, 19.9221276],
                [105.1344609, 19.9217001],
                [105.1343429, 19.9212841],
                [105.1348418, 19.9210067],
                [105.1349913, 19.921138],
                [105.1352726, 19.9214175],
                [105.1354038, 19.9215652],
                [105.1356402, 19.9218667],
                [105.1357458, 19.9220195],
                [105.1359375, 19.9223362],
                [105.1361433, 19.9227514],
                [105.1361912, 19.9228335],
                [105.1363066, 19.922986],
                [105.1364458, 19.9231199],
                [105.1365352, 19.923187],
                [105.1366308, 19.9232464],
                [105.1367316, 19.9232975],
                [105.1368368, 19.9233398],
                [105.1369457, 19.9233732],
                [105.1370573, 19.9233972],
                [105.1376957, 19.9233821],
                [105.1378909, 19.9233318],
                [105.1379851, 19.9232969],
                [105.1381642, 19.9232083],
                [105.1383367, 19.9230895],
                [105.1384953, 19.9229391],
                [105.1385646, 19.9228554],
                [105.1387622, 19.9225801],
                [105.1389213, 19.9224103],
                [105.1390088, 19.9223325],
                [105.1392024, 19.92219],
                [105.1393082, 19.9221263],
                [105.1395317, 19.9220184],
                [105.1396484, 19.9219747],
                [105.1398896, 19.9219088],
                [105.1400131, 19.9218867],
                [105.1413435, 19.9218388],
                [105.1415165, 19.9218264],
                [105.1418601, 19.9217809],
                [105.1422101, 19.9217055],
                [105.1425632, 19.9215977],
                [105.1427355, 19.9215328],
                [105.1442563, 19.9208604],
                [105.1444695, 19.9207821],
                [105.1449043, 19.9206484],
                [105.1451254, 19.9205931],
                [105.1455678, 19.920507],
                [105.1460159, 19.9204519],
                [105.1472014, 19.9204519],
                [105.1473532, 19.9204535],
                [105.1475045, 19.9204646],
                [105.1476547, 19.9204851],
                [105.1478032, 19.9205149],
                [105.1479492, 19.9205539],
                [105.1480921, 19.9206019],
                [105.1482314, 19.9206587],
                [105.1483606, 19.9207211],
                [105.1486052, 19.9208683],
                [105.1487195, 19.9209525],
                [105.14893, 19.9211406],
                [105.1492155, 19.9214564],
                [105.1494006, 19.921673],
                [105.1497553, 19.9221176],
                [105.1527804, 19.9212639],
                [105.1528773, 19.9213252],
                [105.1530584, 19.9214638],
                [105.1532203, 19.921622],
                [105.1533035, 19.9217205],
                [105.1534472, 19.9219327],
                [105.1535582, 19.9221616],
                [105.1536633, 19.9224256],
                [105.1537285, 19.9225526],
                [105.1538827, 19.9227943],
                [105.1539713, 19.922908],
                [105.1541655, 19.9231154],
                [105.1542721, 19.9232105],
                [105.1545024, 19.9233821],
                [105.1587617, 19.9221364],
                [105.1592385, 19.9218387],
                [105.1597099, 19.9215334],
                [105.1601757, 19.9212206],
                [105.1606357, 19.9209004],
                [105.1610899, 19.9205729],
                [105.1618391, 19.9200068],
                [105.1622072, 19.9197162],
                [105.1629299, 19.9191204],
                [105.1641476, 19.9189994],
                [105.1661539, 19.9179251],
                [105.1664688, 19.9176861],
                [105.1667598, 19.9174217],
                [105.1670245, 19.9171339],
                [105.1671463, 19.916982],
                [105.1674507, 19.916555],
                [105.1676491, 19.9163298],
                [105.167757, 19.9162245],
                [105.1686376, 19.9154311],
                [105.1687406, 19.9152945],
                [105.1688173, 19.9151436],
                [105.168866, 19.9149811],
                [105.1689299, 19.914422],
                [105.1690093, 19.9140355],
                [105.1690614, 19.9138448],
                [105.1691837, 19.9129176],
                [105.1692784, 19.911939],
                [105.1693142, 19.9114244],
                [105.1693404, 19.9109094],
                [105.169158, 19.9090836],
                [105.1696515, 19.9038984],
                [105.1696272, 19.9033457],
                [105.1696272, 19.9030691],
                [105.1696353, 19.9027926],
                [105.1696681, 19.9023185],
                [105.1697218, 19.9019249],
                [105.1698023, 19.9015364],
                [105.169909, 19.9011545],
                [105.1699913, 19.9009288],
                [105.1701809, 19.9004863],
                [105.1704027, 19.9000572],
                [105.1706558, 19.8996437],
                [105.170939, 19.8992478],
                [105.17124, 19.8988835],
                [105.1715665, 19.898539],
                [105.1719169, 19.898216],
                [105.1722897, 19.8979159],
                [105.1724839, 19.8977749],
                [105.1772153, 19.8953436],
                [105.1763999, 19.8943449],
                [105.1762837, 19.8933436],
                [105.176239, 19.8928417],
                [105.1761828, 19.8919746],
                [105.1761532, 19.8911064],
                [105.1766159, 19.8900298],
                [105.177049, 19.8889423],
                [105.1774465, 19.8878615],
                [105.1776343, 19.8873176],
                [105.1779878, 19.8862234],
                [105.1776779, 19.8851155],
                [105.1776334, 19.8849338],
                [105.1775686, 19.8845662],
                [105.1775485, 19.8843808],
                [105.1775327, 19.8840085],
                [105.1775372, 19.8838222],
                [105.177573, 19.8834344],
                [105.1776443, 19.883051],
                [105.1777507, 19.8826748],
                [105.1778912, 19.8823088],
                [105.1790775, 19.8801397],
                [105.1809704, 19.8798231],
                [105.1812836, 19.879745],
                [105.1815813, 19.8796348],
                [105.1818601, 19.8794953],
                [105.1819928, 19.8794146],
                [105.1821203, 19.8793269],
                [105.1823603, 19.8791337],
                [105.1824715, 19.8790281],
                [105.1825757, 19.8789164],
                [105.1826725, 19.878799],
                [105.1827615, 19.8786762],
                [105.1828424, 19.8785486],
                [105.1829149, 19.8784166],
                [105.1829787, 19.8782806],
                [105.1830336, 19.8781413],
                [105.1831145, 19.8778608],
                [105.1831594, 19.8775732],
                [105.1831677, 19.8772827],
                [105.1831391, 19.8769933],
                [105.1830713, 19.876604],
                [105.1829601, 19.8758377],
                [105.1828791, 19.8750834],
                [105.183112, 19.8747338],
                [105.183222, 19.8745555],
                [105.1834916, 19.874074],
                [105.1836148, 19.8738279],
                [105.1838376, 19.8733258],
                [105.1840879, 19.8726634],
                [105.1841822, 19.8723582],
                [105.1842403, 19.8720628],
                [105.1842668, 19.8717807],
                [105.1842623, 19.8714006],
                [105.1842677, 19.8713038],
                [105.1843049, 19.8711132],
                [105.1843762, 19.8709314],
                [105.1844821, 19.8707601],
                [105.1845481, 19.8706799],
                [105.1846215, 19.8706057],
                [105.1847017, 19.8705379],
                [105.1847881, 19.8704771],
                [105.1853768, 19.8701378],
                [105.1858758, 19.8698096],
                [105.1861272, 19.8696269],
                [105.186372, 19.8694367],
                [105.1866101, 19.869239],
                [105.1868412, 19.8690341],
                [105.1870651, 19.8688222],
                [105.1880146, 19.8675465],
                [105.1878647, 19.867061],
                [105.1878004, 19.8668156],
                [105.187692, 19.8663121],
                [105.1876125, 19.8657956],
                [105.1875845, 19.865536],
                [105.1875519, 19.8650151],
                [105.1875474, 19.8647542],
                [105.1877283, 19.864329],
                [105.1879123, 19.8623936],
                [105.1861659, 19.8600125],
                [105.1836272, 19.8580334],
                [105.182385, 19.8570438],
                [105.1820602, 19.8562019],
                [105.1823286, 19.8549631],
                [105.1828126, 19.8534763],
                [105.1827581, 19.8530305],
                [105.1823802, 19.8528822],
                [105.1817866, 19.8528333],
                [105.1810852, 19.8528836],
                [105.177957, 19.8544226],
                [105.1730481, 19.8559137],
                [105.1712945, 19.8559897],
                [105.1676247, 19.855795],
                [105.1646835, 19.8555253],
                [105.1613372, 19.8548843],
                [105.1597722, 19.854737],
                [105.1589088, 19.8547378],
                [105.1576139, 19.8549867],
                [105.1550783, 19.8556329],
                [105.1537294, 19.8558818],
                [105.1527039, 19.855635],
                [105.1519478, 19.854942],
                [105.1512454, 19.8539517],
                [105.1491361, 19.8488506],
                [105.1482688, 19.8446401],
                [105.1473745, 19.8403554],
                [105.1463469, 19.837681],
                [105.1451569, 19.8345112],
                [105.1439414, 19.8327038],
                [105.1426456, 19.8318626],
                [105.1417281, 19.8315661],
                [105.1398395, 19.8312703],
                [105.1354142, 19.8300351],
                [105.1343884, 19.8292432],
                [105.1338482, 19.8284014],
                [105.1330917, 19.8269652],
                [105.1324418, 19.8241417],
                [105.13171, 19.8199063],
                [105.1314921, 19.8172806],
                [105.1316527, 19.8157943],
                [105.132406, 19.8133661],
                [105.132701, 19.8114089],
                [105.1326999, 19.8100217],
                [105.132051, 19.8081395],
                [105.1304843, 19.8053662],
                [105.1291333, 19.8024937],
                [105.1289432, 19.8008837],
                [105.1290502, 19.7997936],
                [105.1296424, 19.7983069],
                [105.13072, 19.7967703],
                [105.1320656, 19.7932021],
                [105.1326825, 19.7890153],
                [105.1326254, 19.7852004],
                [105.1321105, 19.7821539],
                [105.1322176, 19.7813116],
                [105.1325945, 19.7804195],
                [105.1351271, 19.7775441],
                [105.1364736, 19.7753136],
                [105.1383044, 19.771745],
                [105.1387065, 19.7690942],
                [105.1388127, 19.7671123],
                [105.1387039, 19.766072],
                [105.1383794, 19.7649823],
                [105.1372995, 19.7635464],
                [105.1361109, 19.760971],
                [105.1361372, 19.7603021],
                [105.1367814, 19.756685],
                [105.1375323, 19.7519777],
                [105.1375043, 19.750764],
                [105.1363098, 19.7492027],
                [105.1352079, 19.7473366],
                [105.1337573, 19.7447867],
                [105.1344987, 19.7416343],
                [105.1368631, 19.7393444],
                [105.1389245, 19.737634],
                [105.141673, 19.7377096],
                [105.145008, 19.7344539],
                [105.1485667, 19.7340574],
                [105.1498639, 19.732943],
                [105.1531765, 19.7291006],
                [105.1546845, 19.7272662],
                [105.1560319, 19.72672],
                [105.1586734, 19.7261727],
                [105.1621505, 19.7255007],
                [105.1630665, 19.7249054],
                [105.1634969, 19.7239636],
                [105.1638732, 19.7228734],
                [105.1638851, 19.722593],
                [105.1639259, 19.7216347],
                [105.1638708, 19.7205448],
                [105.1632759, 19.7187618],
                [105.1627899, 19.7179696],
                [105.1617649, 19.7173759],
                [105.1601469, 19.7168324],
                [105.1579351, 19.7156453],
                [105.1559117, 19.7139378],
                [105.1551019, 19.7128486],
                [105.1547774, 19.7118083],
                [105.1547766, 19.7110157],
                [105.1548833, 19.7097275],
                [105.1556359, 19.7075469],
                [105.1574937, 19.7052415],
                [105.1602404, 19.7025141],
                [105.1625574, 19.7013229],
                [105.1643356, 19.7004296],
                [105.164928, 19.6998345],
                [105.1656814, 19.6985952],
                [105.1657342, 19.6975547],
                [105.1651646, 19.6940128],
                [105.1648921, 19.6911891],
                [105.1650523, 19.6896531],
                [105.1653206, 19.6884142],
                [105.1656965, 19.6870266],
                [105.1666114, 19.6856386],
                [105.1681462, 19.684126],
                [105.1712698, 19.6814973],
                [105.1722542, 19.6805907],
                [105.1739362, 19.6797359],
                [105.175337, 19.6790905],
                [105.1761455, 19.6790401],
                [105.1770623, 19.6794356],
                [105.1784652, 19.6806728],
                [105.1808386, 19.6822063],
                [105.1813241, 19.6825031],
                [105.181378, 19.682503],
                [105.1818632, 19.6826016],
                [105.1832107, 19.6824516],
                [105.1841263, 19.6817571],
                [105.1885425, 19.678433],
                [105.1926895, 19.6755055],
                [105.2004188, 19.6709142],
                [105.2052665, 19.6683325],
                [105.2083361, 19.6662481],
                [105.212078, 19.6631967],
                [105.218348, 19.6564265],
                [105.2228141, 19.6512189],
                [105.2244303, 19.6493153],
                [105.2247511, 19.6489374],
                [105.2250741, 19.6487388],
                [105.2284146, 19.6480411],
                [105.2343398, 19.6460517],
                [105.2356593, 19.6454306],
                [105.2363598, 19.6453801],
                [105.2367372, 19.6454788],
                [105.2374385, 19.6459732],
                [105.2390043, 19.6479034],
                [105.2393832, 19.6490424],
                [105.2393317, 19.6506279],
                [105.239443, 19.6530058],
                [105.2398757, 19.6540953],
                [105.241063, 19.6551837],
                [105.242331, 19.656148],
                [105.244327, 19.6573839],
                [105.2459451, 19.6581745],
                [105.2470231, 19.6582225],
                [105.2477775, 19.6580728],
                [105.2491775, 19.6572782],
                [105.2508453, 19.6553932],
                [105.2549852, 19.6489962],
                [105.259906, 19.6425732],
                [105.2618957, 19.6399941],
                [105.2626485, 19.638903],
                [105.2630236, 19.6376143],
                [105.2631282, 19.6356323],
                [105.2638498, 19.6320393],
                [105.2642246, 19.630602],
                [105.2655148, 19.6287175],
                [105.2679349, 19.6258403],
                [105.2684454, 19.6249725],
                [105.2685383, 19.6242513],
                [105.268605, 19.6237337],
                [105.2684952, 19.6224952],
                [105.2684926, 19.6209098],
                [105.2685976, 19.6192747],
                [105.2689186, 19.617887],
                [105.2695633, 19.616796],
                [105.2709887, 19.6153323],
                [105.2721718, 19.6139433],
                [105.2727078, 19.612258],
                [105.2727058, 19.6110689],
                [105.272056, 19.6091872],
                [105.2708143, 19.6077028],
                [105.2705711, 19.6072821],
                [105.2705706, 19.6069848],
                [105.270732, 19.6067863],
                [105.2711631, 19.6068353],
                [105.2734817, 19.6079217],
                [105.2754225, 19.6085629],
                [105.2769316, 19.6088578],
                [105.2782786, 19.6089052],
                [105.2797867, 19.6086056],
                [105.2813478, 19.6077609],
                [105.2827999, 19.6062229],
                [105.2836051, 19.6045866],
                [105.2840341, 19.6033969],
                [105.2840316, 19.6019601],
                [105.2827842, 19.5972554],
                [105.2819975, 19.5941105],
                [105.2813487, 19.5928234],
                [105.2804307, 19.5915862],
                [105.2793516, 19.590597],
                [105.2783808, 19.590004],
                [105.2776796, 19.5895096],
                [105.2773555, 19.5889651],
                [105.2774089, 19.5887173],
                [105.2780538, 19.5878245],
                [105.2791291, 19.5865347],
                [105.2799345, 19.5849976],
                [105.2802017, 19.5837586],
                [105.2802531, 19.5822722],
                [105.2802473, 19.5789527],
                [105.2805683, 19.5776641],
                [105.2812123, 19.5762758],
                [105.2827688, 19.573053],
                [105.2829282, 19.571715],
                [105.2831386, 19.5688907],
                [105.2831642, 19.5680732],
                [105.2830547, 19.567132],
                [105.2827284, 19.5652993],
                [105.2824034, 19.5642594],
                [105.2818635, 19.5635666],
                [105.280947, 19.5630726],
                [105.2797613, 19.5626781],
                [105.278522, 19.5624323],
                [105.2768521, 19.5623358],
                [105.2756124, 19.5618422],
                [105.2743184, 19.5611506],
                [105.2732391, 19.5599137],
                [105.2720518, 19.5585282],
                [105.2713491, 19.5570924],
                [105.27097, 19.5558049],
                [105.2711024, 19.5544917],
                [105.2712626, 19.5536492],
                [105.2719611, 19.5526573],
                [105.273359, 19.5511689],
                [105.2738421, 19.5502268],
                [105.2739482, 19.5491862],
                [105.2741061, 19.5470555],
                [105.2738323, 19.5443805],
                [105.2727723, 19.5385606],
                [105.2711503, 19.5347977],
                [105.2698782, 19.5308608],
                [105.2696066, 19.529474],
                [105.2697124, 19.5283343],
                [105.2705172, 19.5264999],
                [105.2721284, 19.5239212],
                [105.2739286, 19.5216641],
                [105.2739005, 19.518076],
                [105.2741938, 19.5177789],
                [105.2743018, 19.5175065],
                [105.2743949, 19.5172721],
                [105.2744854, 19.5165037],
                [105.2743367, 19.5154735],
                [105.2738945, 19.5144439],
                [105.2734427, 19.5132745],
                [105.2731102, 19.5120178],
                [105.2726396, 19.5105166],
                [105.2722521, 19.5093124],
                [105.2722601, 19.5086661],
                [105.2724608, 19.5078976],
                [105.2728815, 19.5070587],
                [105.2735326, 19.5067259],
                [105.2748349, 19.5061652],
                [105.2763215, 19.5059882],
                [105.2775053, 19.5058816],
                [105.2784956, 19.5053737],
                [105.2794122, 19.5046563],
                [105.2799615, 19.5039045],
                [105.2800791, 19.502909],
                [105.2799305, 19.5018615],
                [105.2795711, 19.5010413],
                [105.2790735, 19.4998371],
                [105.2790806, 19.4987021],
                [105.2785461, 19.4973582],
                [105.278186, 19.4960315],
                [105.2779269, 19.4948621],
                [105.2778608, 19.4937619],
                [105.2778778, 19.4929761],
                [105.2781977, 19.4922072],
                [105.278656, 19.4918572],
                [105.2795739, 19.4919956],
                [105.2813003, 19.4926389],
                [105.2827596, 19.4926891],
                [105.2844112, 19.4925643],
                [105.285934, 19.4921777],
                [105.2870436, 19.4917045],
                [105.2883461, 19.4913182],
                [105.2896109, 19.490443],
                [105.2904627, 19.4895336],
                [105.2911389, 19.4878561],
                [105.2914578, 19.4865807],
                [105.2911035, 19.4851343],
                [105.2904716, 19.4836097],
                [105.2908435, 19.4823842],
                [105.2913857, 19.4812208],
                [105.2921662, 19.4808419],
                [105.2928268, 19.4810174],
                [105.293418, 19.4805363],
                [105.2938522, 19.4794045],
                [105.2936186, 19.4781366],
                [105.2934126, 19.4768573],
                [105.2934993, 19.4758945],
                [105.2943825, 19.4760686],
                [105.2952953, 19.4757998],
                [105.2956854, 19.4759781],
                [105.2959989, 19.4765136],
                [105.2953971, 19.4772833],
                [105.2953968, 19.4775401],
                [105.2962534, 19.4775428],
                [105.2962917, 19.4778779],
                [105.2966, 19.4782953],
                [105.2963623, 19.4787671],
                [105.2963262, 19.479099],
                [105.2965835, 19.479343],
                [105.2970702, 19.4795343],
                [105.297786, 19.4795157],
                [105.2983736, 19.4796893],
                [105.2986863, 19.4800555],
                [105.298999, 19.4804742],
                [105.2990185, 19.4811027],
                [105.2988461, 19.4821159],
                [105.2987376, 19.4830939],
                [105.2985649, 19.4839324],
                [105.2986022, 19.484299],
                [105.2986577, 19.4845261],
                [105.2989514, 19.4845954],
                [105.2991532, 19.4845601],
                [105.2995936, 19.4844197],
                [105.3000523, 19.4844014],
                [105.3005847, 19.4845054],
                [105.3008787, 19.4847145],
                [105.301357, 19.4852375],
                [105.3017535, 19.4862671],
                [105.3021037, 19.4871047],
                [105.3025178, 19.4877327],
                [105.3028854, 19.4879592],
                [105.3033446, 19.488133],
                [105.3040421, 19.4881842],
                [105.3045377, 19.4882009],
                [105.3059324, 19.4881461],
                [105.3070614, 19.4882141],
                [105.3075758, 19.4884927],
                [105.3083294, 19.4891025],
                [105.3087529, 19.4898353],
                [105.308883, 19.4906733],
                [105.309593, 19.4924533],
                [105.3101612, 19.4942605],
                [105.310301, 19.495343],
                [105.3102655, 19.4959893],
                [105.30967, 19.4964967],
                [105.3092489, 19.4971086],
                [105.3091218, 19.4978248],
                [105.3092607, 19.4984707],
                [105.3096103, 19.4989066],
                [105.3103269, 19.4993071],
                [105.3113188, 19.4997071],
                [105.3124665, 19.4999845],
                [105.3131094, 19.5002803],
                [105.3134588, 19.5006289],
                [105.3136989, 19.5013794],
                [105.3139022, 19.5020601],
                [105.3144648, 19.5034562],
                [105.3150357, 19.504468],
                [105.3159837, 19.5058285],
                [105.3168841, 19.5063508],
                [105.318436, 19.5067672],
                [105.3192988, 19.5067832],
                [105.3197395, 19.5068522],
                [105.3199602, 19.5070963],
                [105.3201815, 19.5076547],
                [105.3207245, 19.5083871],
                [105.321551, 19.5086477],
                [105.3223406, 19.5087511],
                [105.3230935, 19.5089767],
                [105.3235899, 19.5093076],
                [105.3238018, 19.5097438],
                [105.323711, 19.5103028],
                [105.3233999, 19.5107399],
                [105.3233637, 19.5110369],
                [105.3235206, 19.5114557],
                [105.3241086, 19.5117166],
                [105.3247693, 19.5116805],
                [105.3251, 19.511837],
                [105.3252292, 19.5121686],
                [105.3252122, 19.5129021],
                [105.3253364, 19.5153292],
                [105.3254944, 19.5163068],
                [105.325773, 19.5179303],
                [105.3260045, 19.5189253],
                [105.3264649, 19.5196578],
                [105.3274856, 19.5205641],
                [105.3287355, 19.5213477],
                [105.330224, 19.5221482],
                [105.331556, 19.5227046],
                [105.3321997, 19.5232448],
                [105.332495, 19.52403],
                [105.3326723, 19.5254791],
                [105.3329628, 19.5262092],
                [105.3331669, 19.5266176],
                [105.3333426, 19.527051],
                [105.3336666, 19.5282558],
                [105.3336872, 19.5293739],
                [105.3337898, 19.5301074],
                [105.3342015, 19.5313475],
                [105.3360977, 19.5320152],
                [105.3361337, 19.5317357],
                [105.3368862, 19.5315865],
                [105.3376544, 19.5308242],
                [105.3389514, 19.5304523],
                [105.3395999, 19.5302787],
                [105.3401971, 19.5303515],
                [105.3405349, 19.5305479],
                [105.3407953, 19.5309168],
                [105.3409655, 19.531643],
                [105.3410487, 19.5341795],
                [105.3411533, 19.534524],
                [105.3412836, 19.5348193],
                [105.3432615, 19.5372537],
                [105.3436778, 19.5376716],
                [105.3439896, 19.5378434],
                [105.344483, 19.5379409],
                [105.3450802, 19.5380137],
                [105.3492588, 19.5377348],
                [105.3515691, 19.5377795],
                [105.3521919, 19.5377291],
                [105.3527627, 19.5375802],
                [105.3533073, 19.5373821],
                [105.3540074, 19.5370113],
                [105.3543439, 19.5365674],
                [105.3553921, 19.5351739],
                [105.3564781, 19.5332754],
                [105.3568661, 19.5326343],
                [105.3571507, 19.5322151],
                [105.3589777, 19.5308939],
                [105.3600663, 19.5302021],
                [105.3604029, 19.5298567],
                [105.3607655, 19.5294619],
                [105.3611021, 19.5290919],
                [105.3614903, 19.5285493],
                [105.3627185, 19.5264904],
                [105.3632616, 19.5256274],
                [105.3627714, 19.5246306],
                [105.3626857, 19.5233903],
                [105.3621027, 19.5215418],
                [105.3629946, 19.5196842],
                [105.3635421, 19.5172198],
                [105.3627699, 19.5142672],
                [105.3627681, 19.5113396],
                [105.3606315, 19.5088886],
                [105.3584318, 19.5070831],
                [105.3594793, 19.5052632],
                [105.3606182, 19.5042009],
                [105.3638385, 19.5022403],
                [105.3640664, 19.5009237],
                [105.3670755, 19.4985822],
                [105.3677404, 19.4975184],
                [105.3679467, 19.4969761],
                [105.3708083, 19.4943228],
                [105.3730738, 19.4920894],
                [105.373825, 19.4914967],
                [105.3761801, 19.4887829],
                [105.3761801, 19.4887828],
                [105.3777595, 19.4872034],
                [105.3784718, 19.486697],
                [105.3809606, 19.4856575],
                [105.3824376, 19.4847924],
                [105.3830976, 19.4840645],
                [105.3832521, 19.483547],
                [105.3832245, 19.4828575],
                [105.3831195, 19.4823405],
                [105.3828587, 19.4817746],
                [105.3826759, 19.4812825],
                [105.3826493, 19.480987],
                [105.3829713, 19.4800135],
                [105.3835389, 19.4786332],
                [105.3842879, 19.4771047],
                [105.385257, 19.4754649],
                [105.3861092, 19.4737638],
                [105.3877389, 19.471448],
                [105.3880099, 19.471063],
                [105.388446, 19.4705725],
                [105.3892773, 19.4693856],
                [105.3909584, 19.4671409],
                [105.3919275, 19.4655257],
                [105.3918745, 19.4650578],
                [105.3917437, 19.4646149],
                [105.3908957, 19.4626342],
                [105.3898249, 19.4596811],
                [105.3895488, 19.4581179],
                [105.3895708, 19.4564678],
                [105.3897502, 19.4555562],
                [105.3901636, 19.4548165],
                [105.3911861, 19.4538908],
                [105.3948135, 19.451888],
                [105.3980394, 19.4502432],
                [105.4000087, 19.4492784],
                [105.4023675, 19.4484359],
                [105.4027565, 19.4483857],
                [105.4030938, 19.448385],
                [105.4035871, 19.4485564],
                [105.4047712, 19.4500436],
                [105.4051613, 19.4504122],
                [105.4054733, 19.4506824],
                [105.4058113, 19.4509772],
                [105.4070852, 19.4520333],
                [105.4087902, 19.4543075],
                [105.4105589, 19.4561013],
                [105.4113647, 19.4567398],
                [105.412352, 19.457304],
                [105.4156108, 19.4583679],
                [105.4169606, 19.4586356],
                [105.4176353, 19.4586833],
                [105.4181801, 19.4586575],
                [105.4189059, 19.4584342],
                [105.4195539, 19.4581617],
                [105.4202274, 19.4577661],
                [105.4223639, 19.4562713],
                [105.4229598, 19.4559251],
                [105.4241263, 19.4555529],
                [105.425708, 19.4552291],
                [105.4289108, 19.4547412],
                [105.430182, 19.454689],
                [105.4321276, 19.4546105],
                [105.4328538, 19.4545596],
                [105.4333725, 19.4544844],
                [105.4339945, 19.4542366],
                [105.43582, 19.4528654],
                [105.4366476, 19.4519276],
                [105.4393245, 19.4488304],
                [105.4400997, 19.4477203],
                [105.4411344, 19.4466095],
                [105.4432692, 19.4445725],
                [105.4438888, 19.4439169],
                [105.4444592, 19.4433136],
                [105.4447431, 19.4428204],
                [105.4449488, 19.4421549],
                [105.4451507, 19.4400857],
                [105.4469285, 19.4403399],
                [105.4494593, 19.4412019],
                [105.4504087, 19.441809],
                [105.4517332, 19.4423475],
                [105.452305, 19.4427155],
                [105.452825, 19.4431328],
                [105.4536189, 19.4441037],
                [105.4552879, 19.4472272],
                [105.4567341, 19.449477],
                [105.4583478, 19.4513692],
                [105.459336, 19.452204],
                [105.4603702, 19.4528575],
                [105.4626486, 19.4538825],
                [105.4650383, 19.4548862],
                [105.4671944, 19.4558658],
                [105.4683372, 19.4563061],
                [105.4694411, 19.4567095],
                [105.4704534, 19.456904],
                [105.4708424, 19.4568537],
                [105.4710757, 19.4567792],
                [105.4724196, 19.4549779],
                [105.473698, 19.4529675],
                [105.4744985, 19.4516847],
                [105.4749896, 19.4510678],
                [105.4758696, 19.4503513],
                [105.4776043, 19.44914],
                [105.4783945, 19.4487808],
                [105.4795613, 19.4485561],
                [105.4814285, 19.4483295],
                [105.4821809, 19.4483275],
                [105.4827519, 19.4484245],
                [105.483375, 19.4485952],
                [105.4850505, 19.4493173],
                [105.4857768, 19.4496705],
                [105.4883241, 19.4509092],
                [105.4891034, 19.4512519],
                [105.4895194, 19.4515709],
                [105.4899875, 19.4519637],
                [105.4902999, 19.4523076],
                [105.4909507, 19.4530447],
                [105.4912892, 19.4534379],
                [105.4920585, 19.4547533],
                [105.4925575, 19.4567714],
                [105.4927929, 19.4573865],
                [105.4931052, 19.4577057],
                [105.4937294, 19.4582459],
                [105.4952112, 19.4592269],
                [105.4978707, 19.4608996],
                [105.4995813, 19.461632],
                [105.5003072, 19.461974],
                [105.5014468, 19.4621674],
                [105.5021202, 19.462313],
                [105.5029487, 19.4623598],
                [105.50476, 19.462109],
                [105.5062607, 19.4619083],
                [105.5075546, 19.4617573],
                [105.5080724, 19.461805],
                [105.5108456, 19.4629029],
                [105.5122966, 19.4633411],
                [105.5130737, 19.4634862],
                [105.513643, 19.4634356],
                [105.5162282, 19.4623471],
                [105.516952, 19.4620501],
                [105.5183497, 19.461997],
                [105.5192301, 19.4620927],
                [105.5200586, 19.4621395],
                [105.5222838, 19.4618383],
                [105.5251288, 19.4610929],
                [105.528748, 19.4596081],
                [105.5312812, 19.4585197],
                [105.5327795, 19.4575815],
                [105.5336412, 19.4565156],
                [105.5348683, 19.4547362],
                [105.5364326, 19.452068],
                [105.5374908, 19.4506523],
                [105.5386976, 19.4491757],
                [105.5399256, 19.4476787],
                [105.5404745, 19.4465471],
                [105.5407266, 19.4456585],
                [105.5409347, 19.444326],
                [105.5410351, 19.4425298],
                [105.5412863, 19.4413587],
                [105.5417926, 19.4401867],
                [105.5439079, 19.4371133],
                [105.5458769, 19.4347868],
                [105.5466382, 19.4336545],
                [105.5500864, 19.4287809],
                [105.5522448, 19.4259492],
                [105.5550145, 19.4216831],
                [105.5564117, 19.4200241],
                [105.5585274, 19.4172329],
                [105.5602822, 19.4145437],
                [105.5613822, 19.413047],
                [105.5633266, 19.4099335],
                [105.5653363, 19.4072434],
                [105.5677061, 19.4042495],
                [105.5704163, 19.401416],
                [105.5710722, 19.4005866],
                [105.5756929, 19.3972223],
                [105.5781517, 19.3954387],
                [105.5788065, 19.3948235],
                [105.5797827, 19.3939605],
                [105.5813497, 19.3924218],
                [105.5819426, 19.391855],
                [105.5827889, 19.3908029],
                [105.5832111, 19.3900348],
                [105.5833767, 19.3887831],
                [105.5833074, 19.3872296],
                [105.5825726, 19.3837611],
                [105.5826102, 19.3823888],
                [105.5833036, 19.3801871],
                [105.5844441, 19.3782463],
                [105.5857527, 19.3758206],
                [105.5868101, 19.3744046],
                [105.5882288, 19.3730884],
                [105.5893729, 19.3721565],
                [105.5906287, 19.3700913],
                [105.5917486, 19.3710994],
                [105.5923863, 19.3711781],
                [105.5930243, 19.3713374],
                [105.5946849, 19.3722603],
                [105.5952808, 19.3725408],
                [105.5955785, 19.3725802],
                [105.5963005, 19.3724971],
                [105.5988686, 19.371742],
                [105.6009916, 19.3712508],
                [105.6051109, 19.3705914],
                [105.6098039, 19.3699098],
                [105.6108668, 19.3700677],
                [105.611505, 19.3702673],
                [105.6118453, 19.3703873],
                [105.6123136, 19.3706278],
                [105.6126981, 19.3711512],
                [105.6129548, 19.3716346],
                [105.6132983, 19.3725617],
                [105.6133433, 19.3732476],
                [105.6131398, 19.3756294],
                [105.6132486, 19.3763353],
                [105.6135477, 19.3767783],
                [105.6144851, 19.3774209],
                [105.6169569, 19.3793093],
                [105.6180649, 19.3801531],
                [105.6203241, 19.3823984],
                [105.6212215, 19.3832902],
                [105.6222609, 19.3828225],
                [105.6251033, 19.3815617],
                [105.6274781, 19.3802621],
                [105.6278168, 19.3799381],
                [105.6281556, 19.379614],
                [105.6283665, 19.3792097],
                [105.6284918, 19.3786443],
                [105.6287155, 19.3760404],
                [105.629151, 19.3741683],
                [105.6295516, 19.3724457],
                [105.629841, 19.3703461],
                [105.6301553, 19.3691948],
                [105.631168, 19.3673348],
                [105.631971, 19.3662423],
                [105.6323943, 19.3657969],
                [105.6329451, 19.3653914],
                [105.6335812, 19.3650664],
                [105.633963, 19.3649036],
                [105.6347702, 19.3648605],
                [105.6357478, 19.3649378],
                [105.6367259, 19.3651361],
                [105.6392802, 19.3663782],
                [105.6395845, 19.3626844],
                [105.6399585, 19.3605442],
                [105.6402545, 19.3601799],
                [105.6408483, 19.3598953],
                [105.6432664, 19.3589182],
                [105.643732, 19.3584726],
                [105.6440277, 19.3580276],
                [105.6441533, 19.3575429],
                [105.6441243, 19.3555857],
                [105.6439863, 19.3528821],
                [105.6456376, 19.3529824],
                [105.6490432, 19.353066],
                [105.6501477, 19.3530217],
                [105.6521855, 19.3526108],
                [105.6529491, 19.3523256],
                [105.6533305, 19.3520821],
                [105.6534994, 19.351799],
                [105.6538468, 19.3483472],
                [105.6541782, 19.346207],
                [105.6552288, 19.3433379],
                [105.6560713, 19.3415187],
                [105.6566624, 19.3405883],
                [105.6570646, 19.3402439],
                [105.659061, 19.3401559],
                [105.664414, 19.3401364],
                [105.6647961, 19.3400544],
                [105.6650506, 19.3399728],
                [105.6652199, 19.3398107],
                [105.6658268, 19.3380982],
                [105.66585, 19.3380326],
                [105.6665639, 19.3359717],
                [105.6670022, 19.3340532],
                [105.6673715, 19.3308635],
                [105.6673651, 19.3292896],
                [105.666911, 19.3272935],
                [105.6665674, 19.3263666],
                [105.6660102, 19.3251579],
                [105.6642529, 19.3212899],
                [105.6639732, 19.3204233],
                [105.6642168, 19.3176377],
                [105.6646315, 19.315134],
                [105.6653465, 19.3133557],
                [105.6659636, 19.3136561],
                [105.6682217, 19.3153025],
                [105.670481, 19.3172718],
                [105.6725709, 19.3193223],
                [105.6741102, 19.3217784],
                [105.6745358, 19.3219786],
                [105.6747908, 19.3219777],
                [105.676104, 19.321085],
                [105.6775414, 19.319425],
                [105.6776674, 19.3190613],
                [105.6769567, 19.316703],
                [105.6764409, 19.3152117],
                [105.6764386, 19.3146467],
                [105.6765218, 19.3142428],
                [105.676944, 19.3135955],
                [105.6774094, 19.3131498],
                [105.6794414, 19.3114876],
                [105.680815, 19.3098076],
                [105.6815735, 19.308352],
                [105.6819963, 19.3078661],
                [105.6827157, 19.3072177],
                [105.6840702, 19.3060826],
                [105.6855903, 19.3038975],
                [105.6872815, 19.3020347],
                [105.6888056, 19.3008586],
                [105.6896959, 19.3004516],
                [105.6908845, 19.3002858],
                [105.6914368, 19.300324],
                [105.6917352, 19.300565],
                [105.6929333, 19.3026591],
                [105.6936817, 19.303584],
                [105.6939288, 19.303583],
                [105.6953285, 19.3034211],
                [105.6983361, 19.3036445],
                [105.7017818, 19.3039218],
                [105.7020729, 19.3039898],
                [105.7040357, 19.3038786],
                [105.7059235, 19.3032496],
                [105.7082308, 19.302947],
                [105.7135026, 19.302961],
                [105.7139758, 19.3030974],
                [105.7143767, 19.3033376],
                [105.7146696, 19.3037855],
                [105.7159136, 19.3056114],
                [105.717429, 19.3071081],
                [105.7188882, 19.3082423],
                [105.7202373, 19.3091352],
                [105.7208934, 19.3095126],
                [105.7214035, 19.3097523],
                [105.7221317, 19.3099912],
                [105.7245691, 19.3103271],
                [105.72545, 19.3105326],
                [105.7263161, 19.3107346],
                [105.7268084, 19.3110609],
                [105.7270646, 19.3114398],
                [105.7273201, 19.3116806],
                [105.7274306, 19.3119911],
                [105.7274698, 19.3126472],
                [105.7275446, 19.3130959],
                [105.7276911, 19.3133372],
                [105.7279829, 19.3135778],
                [105.7285296, 19.313852],
                [105.7290395, 19.3140573],
                [105.7297198, 19.3142495],
                [105.7304514, 19.3147476],
                [105.7340472, 19.3159543],
                [105.7350324, 19.3167103],
                [105.7356887, 19.3171222],
                [105.7361991, 19.317431],
                [105.7380214, 19.3183909],
                [105.7400636, 19.3197644],
                [105.7434569, 19.3223759],
                [105.743708, 19.3216149],
                [105.7437209, 19.3204577],
                [105.7427537, 19.3156254],
                [105.7427876, 19.3150726],
                [105.7430935, 19.3143632],
                [105.7438149, 19.3130822],
                [105.746124, 19.3091693],
                [105.7464659, 19.3083907],
                [105.7465346, 19.3075268],
                [105.7465183, 19.3039344],
                [105.7465891, 19.3034849],
                [105.7467335, 19.3032771],
                [105.747096, 19.3030338],
                [105.7490746, 19.3024212],
                [105.7511081, 19.3018947],
                [105.7514742, 19.3024458],
                [105.7529017, 19.3045472],
                [105.7542974, 19.3076504],
                [105.7548056, 19.3074755],
                [105.7554224, 19.3071966],
                [105.7557488, 19.3070226],
                [105.7562564, 19.3067096],
                [105.7584052, 19.3035745],
                [105.7601023, 19.3010457],
                [105.7617608, 19.298068],
                [105.7623571, 19.2972883],
                [105.7636967, 19.2961082],
                [105.7640226, 19.2958305],
                [105.7643838, 19.2953108],
                [105.7657893, 19.2926796],
                [105.7665849, 19.2917781],
                [105.7673275, 19.2912051],
                [105.7680167, 19.2908913],
                [105.7695786, 19.2906084],
                [105.7701964, 19.2905712],
                [105.7710326, 19.2905677],
                [105.7718691, 19.2906678],
                [105.7724514, 19.2908035],
                [105.776475, 19.2921854],
                [105.7785513, 19.2930747],
                [105.7823379, 19.2943021],
                [105.7860688, 19.2952705],
                [105.7876695, 19.2955055],
                [105.7884701, 19.2956747],
                [105.790364, 19.2963919],
                [105.7965224, 19.299405],
                [105.7996554, 19.3007385],
                [105.8002381, 19.3009432],
                [105.8007107, 19.3009412],
                [105.8009648, 19.3008709],
                [105.8013987, 19.3001288],
                [105.8018517, 19.2986583],
                [105.8021965, 19.2967734],
                [105.8028108, 19.2945174],
                [105.8033481, 19.2934165],
                [105.8042669, 19.2907108],
                [105.8049412, 19.2886676],
                [105.8050977, 19.2872227],
                [106.0893261, 19.2088546],
                [106.1034101, 19.2244124],
                [106.11381, 19.2385251],
                [106.1228917, 19.2534179],
                [106.1305928, 19.2689811],
                [106.1368606, 19.2851008],
                [106.1416525, 19.3016602],
                [106.1449361, 19.3185402],
                [106.1466891, 19.33562],
                [106.1492398, 19.3758978],
                [106.1484507, 19.393468],
                [106.1460433, 19.4109085],
                [106.1420339, 19.4280881],
                [106.13645, 19.4448772],
                [106.1293305, 19.4611482],
                [106.1208273, 19.4774758],
                [106.1113864, 19.4923143],
                [106.1006186, 19.5063377],
                [106.0885998, 19.5194391],
                [106.075416, 19.5315177],
                [106.0611625, 19.5424793],
                [106.0459441, 19.5522374],
                [106.048139, 19.5808433],
                [106.0605419, 19.5936891],
                [106.071715, 19.6074901],
                [106.0815785, 19.6221407],
                [106.0900626, 19.6375301],
                [106.0953351, 19.6495137],
                [106.1108438, 19.6693845],
                [106.1179688, 19.6798878],
                [106.1253083, 19.6931063],
                [106.0275226, 19.8845403],
                [106.025136, 19.9671633],
                [106.0248022, 19.9701482],
                [106.0245034, 19.9727279],
                [106.0244422, 19.9750891],
                [106.0244295, 19.9778883],
                [106.024294, 19.9801075],
                [106.0242209, 19.9806594],
                [106.0242228, 19.9810593],
                [106.0242127, 19.9815858],
                [106.024142, 19.9819736],
                [106.0242459, 19.9836077],
                [106.0246275, 19.9852419],
                [106.025464, 19.9889769],
                [106.0261218, 19.990528],
                [106.0269143, 19.9920736],
                [106.0277637, 19.9935969],
                [106.0286329, 19.9950715],
                [106.0297893, 19.9964486],
                [106.0314431, 19.9981749],
                [106.0324646, 19.9990069],
                [106.0329924, 19.9993636],
                [106.0339837, 19.9999189],
                [106.0345848, 20.0001835],
                [106.0356924, 20.0005713],
                [106.0366486, 20.0008285],
                [106.0375435, 20.0010445],
                [106.0379424, 20.001108],
                [106.038436, 20.0011432],
                [106.0387242, 20.0011337],
                [106.0390417, 20.0011472],
                [106.0399406, 20.001155],
                [106.0415059, 20.0011083],
                [106.0429823, 20.0009786],
                [106.0440554, 20.0008692],
                [106.044992, 20.0007709],
                [106.0456259, 20.0007906],
                [106.0460217, 20.0009055],
                [106.0463143, 20.0009669],
                [106.0467557, 20.0011039],
                [106.0475541, 20.0014189],
                [106.0482109, 20.0017385],
                [106.048363, 20.0018118],
                [106.0491352, 20.0021844],
                [106.0501585, 20.0026917],
                [106.0508763, 20.0030611],
                [106.051335, 20.0033069],
                [106.0515928, 20.0034849],
                [106.0518934, 20.0037025],
                [106.0522571, 20.003979],
                [106.0527048, 20.0043928],
                [106.0533165, 20.0049516],
                [106.0540575, 20.0058715],
                [106.0544602, 20.0066216],
                [106.0550157, 20.0076422],
                [106.0555667, 20.0085633],
                [106.0562532, 20.0097457],
                [106.0566611, 20.0104965],
                [106.0570285, 20.011137],
                [106.0573266, 20.0116087],
                [106.0576589, 20.0123219],
                [106.0579618, 20.0126824],
                [106.0585886, 20.0135215],
                [106.0588167, 20.0138085],
                [106.0591276, 20.0142466],
                [106.059668, 20.0150052],
                [106.0602284, 20.0158767],
                [106.0604477, 20.0162662],
                [106.0606682, 20.0166987],
                [106.0608091, 20.0171374],
                [106.0611076, 20.0174971],
                [106.0612235, 20.0176882],
                [106.0616749, 20.0185287],
                [106.0618629, 20.0190873],
                [106.0620053, 20.0196367],
                [106.0622376, 20.0203855],
                [106.06236, 20.0210123],
                [106.0625068, 20.021516],
                [106.0627169, 20.0221597],
                [106.0630406, 20.0230467],
                [106.0632368, 20.0237038],
                [106.0634785, 20.0243994],
                [106.063763, 20.0251387],
                [106.0640316, 20.0258927],
                [106.0641748, 20.0262458],
                [106.0642513, 20.0266205],
                [106.064311, 20.0268984],
                [106.0643968, 20.0272522],
                [106.0646597, 20.028106],
                [106.0649848, 20.0291299],
                [106.065425, 20.0302466],
                [106.0656254, 20.0308065],
                [106.0660259, 20.0317683],
                [106.0663235, 20.0327397],
                [106.066372, 20.033057],
                [106.0663967, 20.0334944],
                [106.0663663, 20.0341733],
                [106.0663897, 20.0349513],
                [106.0663701, 20.0356408],
                [106.0663404, 20.0363536],
                [106.0662701, 20.0371206],
                [106.0664461, 20.0374046],
                [106.0669026, 20.0382037],
                [106.0674051, 20.0393461],
                [106.0680494, 20.0405623],
                [106.0684618, 20.0415739],
                [106.068785, 20.0423974],
                [106.0690516, 20.0432395],
                [106.0692649, 20.0438046],
                [106.0694065, 20.0441999],
                [106.0695824, 20.0444955],
                [106.0699105, 20.0452118],
                [106.070203, 20.0457949],
                [106.0704456, 20.046391],
                [106.0707067, 20.0468801],
                [106.0710011, 20.0474697],
                [106.0712286, 20.0477857],
                [106.0714094, 20.0481591],
                [106.0715789, 20.0484277],
                [106.0720208, 20.0489029],
                [106.0724641, 20.0495406],
                [106.0729198, 20.0501711],
                [106.0731799, 20.0506229],
                [106.0733455, 20.0508881],
                [106.0734833, 20.0511283],
                [106.0735914, 20.0512856],
                [106.0736829, 20.0514792],
                [106.0737816, 20.0517376],
                [106.0739257, 20.0521852],
                [106.0740833, 20.0528003],
                [106.0742187, 20.0534729],
                [106.0742803, 20.0539729],
                [106.0743404, 20.054494],
                [106.0745055, 20.0550079],
                [106.074602, 20.0553716],
                [106.0747256, 20.0558289],
                [106.0749094, 20.0565458],
                [106.0749696, 20.0570105],
                [106.0750833, 20.0575968],
                [106.0752223, 20.0580568],
                [106.0753813, 20.0585683],
                [106.0753992, 20.0588449],
                [106.0754385, 20.059149],
                [106.0754941, 20.0595731],
                [106.0755417, 20.0599818],
                [106.0757392, 20.0611258],
                [106.0758256, 20.0615504],
                [106.0759318, 20.0619958],
                [106.0760065, 20.0623987],
                [106.0760657, 20.062671],
                [106.0760695, 20.0628331],
                [106.0758904, 20.0628553],
                [106.0756058, 20.0629153],
                [106.0754391, 20.0630355],
                [106.0750546, 20.0633691],
                [106.0720678, 20.0666239],
                [106.0679726, 20.0660073],
                [106.0677786, 20.0659781],
                [106.064411, 20.0654111],
                [106.0617292, 20.0649968],
                [106.0604244, 20.0647824],
                [106.0590781, 20.0645919],
                [106.0544378, 20.0638794],
                [106.051872, 20.0634919],
                [106.0468324, 20.0627789],
                [106.0446605, 20.0624142],
                [106.0436554, 20.0622533],
                [106.0426387, 20.0622002],
                [106.0426336, 20.0621508],
                [106.0422406, 20.0621021],
                [106.041242, 20.0619447],
                [106.0406452, 20.0618467],
                [106.0399097, 20.061825],
                [106.0396282, 20.0618047],
                [106.0394051, 20.0617389],
                [106.0392832, 20.0616822],
                [106.0381451, 20.0610952],
                [106.0368293, 20.060423],
                [106.0361487, 20.0601206],
                [106.0357425, 20.0599315],
                [106.0354937, 20.0598275],
                [106.0353722, 20.0598283],
                [106.0352106, 20.0598676],
                [106.0342214, 20.0603904],
                [106.0339083, 20.0605072],
                [106.0334131, 20.0606537],
                [106.0330439, 20.0607325],
                [106.0327304, 20.0608013],
                [106.0324066, 20.060832],
                [106.0321534, 20.0608239],
                [106.030559, 20.0601437],
                [106.0298129, 20.0598703],
                [106.0292952, 20.0596723],
                [106.0290212, 20.0595877],
                [106.0286259, 20.0595326],
                [106.0283727, 20.059534],
                [106.0281096, 20.0595642],
                [106.0272765, 20.0599139],
                [106.026883, 20.0601459],
                [106.0267921, 20.0601656],
                [106.0265896, 20.0601764],
                [106.0263465, 20.0601682],
                [106.0261843, 20.06015],
                [106.0259512, 20.0601035],
                [106.0250379, 20.0598214],
                [106.0244439, 20.0595856],
                [106.0237938, 20.0592733],
                [106.0236212, 20.0591977],
                [106.0234181, 20.0591223],
                [106.0231647, 20.0590854],
                [106.0224464, 20.0591949],
                [106.0209837, 20.05928],
                [106.0204673, 20.0592829],
                [106.0203459, 20.0593124],
                [106.0194169, 20.0597295],
                [106.0187355, 20.0600495],
                [106.0186141, 20.0600788],
                [106.0185027, 20.0600699],
                [106.0183603, 20.0599941],
                [106.0181872, 20.0598323],
                [106.0180452, 20.0597757],
                [106.0179237, 20.0597956],
                [106.0178026, 20.0598632],
                [106.0172686, 20.0602781],
                [106.0165948, 20.0610194],
                [106.0157742, 20.0617518],
                [106.015533, 20.0620214],
                [106.0153219, 20.0622715],
                [106.0151308, 20.0624833],
                [106.0149455, 20.0628004],
                [106.0146908, 20.0633425],
                [106.0144505, 20.0635737],
                [106.0140641, 20.0638081],
                [106.0119253, 20.065032],
                [106.010747, 20.0656855],
                [106.0107107, 20.0656879],
                [106.0101544, 20.0657964],
                [106.0094621, 20.066011],
                [106.0089875, 20.066234],
                [106.0087045, 20.0663313],
                [106.0084518, 20.0663998],
                [106.0081382, 20.0664399],
                [106.0074797, 20.0664149],
                [106.006391, 20.066402],
                [106.0060976, 20.066442],
                [106.0058549, 20.0665008],
                [106.0056022, 20.0665884],
                [106.0053295, 20.0666953],
                [106.0050766, 20.066735],
                [106.0048284, 20.0667366],
                [106.004666, 20.0666799],
                [106.0041487, 20.0665392],
                [106.0036624, 20.0665133],
                [106.0033789, 20.066515],
                [106.0029335, 20.0665461],
                [106.0018671, 20.0666768],
                [106.0002665, 20.0666987],
                [105.998513, 20.0674518],
                [105.9974204, 20.0679905],
                [105.9968413, 20.0682533],
                [105.9959076, 20.0679065],
                [105.9949247, 20.0671639],
                [105.9943167, 20.0667712],
                [105.9937573, 20.0666863],
                [105.9932929, 20.066909],
                [105.9932508, 20.0676134],
                [105.993208, 20.0682299],
                [105.9929308, 20.0685835],
                [105.9926053, 20.0686733],
                [105.991627, 20.0686348],
                [105.9892014, 20.0680762],
                [105.9853358, 20.0681419],
                [105.9827259, 20.0680542],
                [105.979083, 20.0663281],
                [105.9784759, 20.0660674],
                [105.9780582, 20.0662898],
                [105.9776891, 20.066864],
                [105.9765734, 20.0672223],
                [105.9756901, 20.0674912],
                [105.9748057, 20.0675841],
                [105.9739656, 20.0673246],
                [105.9727038, 20.0666274],
                [105.9719551, 20.0661035],
                [105.9714416, 20.0658862],
                [105.9709758, 20.0658887],
                [105.9700395, 20.0658762],
                [105.9679955, 20.0659931],
                [105.9666456, 20.0661325],
                [105.964036, 20.0659267],
                [105.9623115, 20.0657601],
                [105.9610543, 20.0658109],
                [105.9588721, 20.0669671],
                [105.9576686, 20.0682059],
                [105.956282, 20.0699738],
                [105.9545682, 20.0715676],
                [105.9535017, 20.0723655],
                [105.9522051, 20.0736488],
                [105.951511, 20.0744008],
                [105.950301, 20.0745833],
                [105.9482974, 20.0744621],
                [105.9468996, 20.0744256],
                [105.9461129, 20.075266],
                [105.9426012, 20.080038],
                [105.9409477, 20.0839638],
                [105.9366718, 20.0856149],
                [105.934584, 20.0870343],
                [105.9316162, 20.0893387],
                [105.9297856, 20.0904705],
                [105.9289752, 20.0917731],
                [105.9280096, 20.0939348],
                [105.9259371, 20.0979946],
                [105.9242109, 20.1021784],
                [105.9227585, 20.1025435],
                [105.9218541, 20.1025482],
                [105.921375, 20.1025004],
                [105.9209, 20.1031562],
                [105.9205327, 20.1040125],
                [105.9195254, 20.1046207],
                [105.9184639, 20.1050283],
                [105.9170311, 20.1056388],
                [105.9160218, 20.1058953],
                [105.9152776, 20.1059997],
                [105.9137886, 20.106108],
                [105.9130428, 20.1059108],
                [105.9121901, 20.1056639],
                [105.9114434, 20.105316],
                [105.9106443, 20.105119],
                [105.9101657, 20.1051717],
                [105.9099031, 20.1057259],
                [105.9096396, 20.1061796],
                [105.9088585, 20.1066394],
                [105.9082585, 20.1065385],
                [105.9076195, 20.1064413],
                [105.9071437, 20.1069463],
                [105.9066178, 20.1080043],
                [105.9056142, 20.1092658],
                [105.903934, 20.1105322],
                [105.9025966, 20.1118442],
                [105.9019082, 20.1124005],
                [105.9008994, 20.1127576],
                [105.8995724, 20.1132668],
                [105.8981954, 20.1143795],
                [105.8969261, 20.1156924],
                [105.8958182, 20.1173565],
                [105.8943344, 20.1183691],
                [105.8932725, 20.1187262],
                [105.8923161, 20.1189824],
                [105.8920529, 20.1194863],
                [105.891309, 20.1196408],
                [105.8899252, 20.1195473],
                [105.8883294, 20.1196056],
                [105.8858288, 20.1196182],
                [105.883915, 20.119879],
                [105.883118, 20.120084],
                [105.8821629, 20.1205412],
                [105.8808902, 20.121251],
                [105.8796709, 20.1220612],
                [105.8789798, 20.1221651],
                [105.8778618, 20.12202],
                [105.8759443, 20.1216274],
                [105.8748272, 20.1216832],
                [105.8738718, 20.12209],
                [105.872335, 20.1232032],
                [105.8712742, 20.1238115],
                [105.8702116, 20.1240681],
                [105.8691497, 20.1244753],
                [105.8684074, 20.1249313],
                [105.8676661, 20.1255882],
                [105.8666078, 20.1266488],
                [105.8657616, 20.1275575],
                [105.8649119, 20.1278632],
                [105.8643818, 20.1282176],
                [105.8643854, 20.1288709],
                [105.8641778, 20.1298267],
                [105.8633822, 20.1302829],
                [105.862532, 20.1304881],
                [105.8615776, 20.1310958],
                [105.8612084, 20.1317006],
                [105.8610217, 20.1318674],
                [105.8605646, 20.1322755],
                [105.8595671, 20.1331661],
                [105.8581882, 20.1340272],
                [105.8565997, 20.1354419],
                [105.8552229, 20.1366547],
                [105.8537387, 20.1377174],
                [105.8530911, 20.1381218],
                [105.85236, 20.1385783],
                [105.8490121, 20.1393985],
                [105.8480034, 20.1398055],
                [105.8467277, 20.1400628],
                [105.8451321, 20.1402213],
                [105.8445502, 20.1408271],
                [105.8433849, 20.1418378],
                [105.8419511, 20.1423974],
                [105.8405681, 20.1425045],
                [105.8391832, 20.1422096],
                [105.8376419, 20.1425688],
                [105.8364183, 20.1426249],
                [105.8355159, 20.1430311],
                [105.8343533, 20.1445443],
                [105.8333513, 20.1462577],
                [105.8330395, 20.1476663],
                [105.8328341, 20.1490744],
                [105.8319907, 20.150586],
                [105.8306646, 20.1513964],
                [105.828697, 20.1516569],
                [105.8271541, 20.1517144],
                [105.8265709, 20.1521192],
                [105.8261478, 20.1526238],
                [105.8247647, 20.1527307],
                [105.8224775, 20.1529424],
                [105.8207778, 20.1535031],
                [105.8189179, 20.1540647],
                [105.8172722, 20.1548261],
                [105.8156471, 20.1558886],
                [105.8150647, 20.1562858],
                [105.811863, 20.1584695],
                [105.8108532, 20.1587254],
                [105.8097361, 20.158831],
                [105.8089377, 20.1587845],
                [105.8074002, 20.1599474],
                [105.805865, 20.1615123],
                [105.8048029, 20.1619694],
                [105.8015618, 20.1629893],
                [105.8004473, 20.1635975],
                [105.798754, 20.1655148],
                [105.7980451, 20.1664327],
                [105.7977645, 20.1668708],
                [105.7964728, 20.1668766],
                [105.7959152, 20.167044],
                [105.7952895, 20.1675742],
                [105.7946995, 20.1682691],
                [105.7939026, 20.1694595],
                [105.7933473, 20.1700882],
                [105.7928592, 20.1702223],
                [105.7920218, 20.1703249],
                [105.7904516, 20.1704638],
                [105.7884969, 20.1705385],
                [105.7880886, 20.1705108],
                [105.7877, 20.1705622],
                [105.7869927, 20.1708166],
                [105.7852626, 20.1712256],
                [105.7840441, 20.1714085],
                [105.7833666, 20.1719465],
                [105.7829372, 20.172484],
                [105.7824684, 20.1727081],
                [105.7820502, 20.1728418],
                [105.7813172, 20.172878],
                [105.7807227, 20.1726829],
                [105.7790453, 20.1723277],
                [105.7777173, 20.1720369],
                [105.7770889, 20.1720398],
                [105.7769496, 20.1721062],
                [105.7767427, 20.1726346],
                [105.7767101, 20.1730962],
                [105.7766421, 20.1734592],
                [105.7764335, 20.1736249],
                [105.7760154, 20.1738245],
                [105.7757366, 20.1739246],
                [105.775459, 20.1742555],
                [105.7753213, 20.1746187],
                [105.7752556, 20.175476],
                [105.7751183, 20.1759382],
                [105.7744216, 20.1762708],
                [105.7734815, 20.1767694],
                [105.7729583, 20.1768706],
                [105.7726789, 20.1768388],
                [105.7722935, 20.1765439],
                [105.771873, 20.1762491],
                [105.7712442, 20.1761529],
                [105.7706512, 20.1762545],
                [105.7696401, 20.1765225],
                [105.7684201, 20.1769234],
                [105.7679719, 20.1770928],
                [105.7670959, 20.1774237],
                [105.766329, 20.1776907],
                [105.765457, 20.1778264],
                [105.7639917, 20.1780305],
                [105.7606766, 20.1783415],
                [105.7595947, 20.1784121],
                [105.7588618, 20.1784812],
                [105.7579553, 20.1787159],
                [105.7572933, 20.1790154],
                [105.7562136, 20.1795145],
                [105.7546453, 20.1801146],
                [105.7535651, 20.1805148],
                [105.752589, 20.1808486],
                [105.7518691, 20.1810786],
                [105.7503298, 20.1815006],
                [105.7483096, 20.1823838],
                [105.746048, 20.1833148],
                [105.743902, 20.1841829],
                [105.7409632, 20.1853509],
                [105.7406003, 20.1855399],
                [105.7404524, 20.1857434],
                [105.7404529, 20.1858685],
                [105.741483, 20.1868323],
                [105.7418325, 20.1872681],
                [105.7420495, 20.1877045],
                [105.742053, 20.1884385],
                [105.7413511, 20.189997],
                [105.7408903, 20.1904832],
                [105.7402315, 20.1910797],
                [105.7393109, 20.192271],
                [105.7387361, 20.1931639],
                [105.7383413, 20.1936498],
                [105.7378828, 20.1942588],
                [105.7376354, 20.1943848],
                [105.7373708, 20.1944327],
                [105.735257, 20.1942829],
                [105.7340338, 20.1945067],
                [105.7333566, 20.1946814],
                [105.7327471, 20.1952464],
                [105.7320253, 20.1965149],
                [105.7313352, 20.1982459],
                [105.7307375, 20.199148],
                [105.7305384, 20.1994802],
                [105.730305, 20.1996233],
                [105.7297376, 20.199815],
                [105.7286022, 20.2001354],
                [105.7265496, 20.2009488],
                [105.7251486, 20.201649],
                [105.7241866, 20.2022493],
                [105.7234196, 20.2027103],
                [105.7219525, 20.203569],
                [105.7208861, 20.2043629],
                [105.7195863, 20.205284],
                [105.7183267, 20.2062137],
                [105.7178103, 20.2066263],
                [105.7172131, 20.2076393],
                [105.7169474, 20.2080351],
                [105.716431, 20.208432],
                [105.7157741, 20.2084347],
                [105.7155951, 20.2084354],
                [105.7149265, 20.2084855],
                [105.7142415, 20.2085987],
                [105.7137786, 20.2087379],
                [105.7134286, 20.2089762],
                [105.7127626, 20.2095788],
                [105.7116973, 20.2106253],
                [105.7108818, 20.2114338],
                [105.7101541, 20.2120298],
                [105.7094873, 20.2124588],
                [105.7072858, 20.2136677],
                [105.7053002, 20.2145598],
                [105.7039827, 20.2153073],
                [105.7024828, 20.2164027],
                [105.7020217, 20.2167469],
                [105.701689, 20.2171273],
                [105.7013077, 20.2178551],
                [105.7008919, 20.2183462],
                [105.7003589, 20.2187905],
                [105.6994577, 20.2191888],
                [105.6984894, 20.2194927],
                [105.6972043, 20.220003],
                [105.695919, 20.2204976],
                [105.6946085, 20.2211593],
                [105.6935397, 20.2214478],
                [105.6932896, 20.2216067],
                [105.6931897, 20.2217334],
                [105.6931902, 20.2218281],
                [105.6933084, 20.2220961],
                [105.695173, 20.2240466],
                [105.6961617, 20.225159],
                [105.696146, 20.2253643],
                [105.6951794, 20.2261103],
                [105.6947975, 20.2267118],
                [105.6945681, 20.227739],
                [105.6943106, 20.2300295],
                [105.694233, 20.2307329],
                [105.6941832, 20.2308278],
                [105.6936809, 20.2309835],
                [105.6936154, 20.2310038],
                [105.6916945, 20.2314535],
                [105.6897897, 20.2317926],
                [105.6895451, 20.2318673],
                [105.689029, 20.2323746],
                [105.6889687, 20.2324619],
                [105.6888082, 20.232964],
                [105.688393, 20.2333537],
                [105.6877569, 20.2336911],
                [105.6872591, 20.2339506],
                [105.6869551, 20.2339891],
                [105.6865132, 20.2339362],
                [105.6860163, 20.2337791],
                [105.6857104, 20.2337619],
                [105.6846729, 20.2342391],
                [105.6837481, 20.2347113],
                [105.6833312, 20.2349813],
                [105.6829486, 20.2354407],
                [105.6826996, 20.2358522],
                [105.6823522, 20.2367062],
                [105.6817393, 20.2380507],
                [105.6814188, 20.2388339],
                [105.6800982, 20.2399593],
                [105.6792003, 20.2412347],
                [105.6789302, 20.2418695],
                [105.6790338, 20.2461005],
                [105.6789342, 20.2466963],
                [105.6785772, 20.2469068],
                [105.6772322, 20.2473464],
                [105.675224, 20.2481263],
                [105.6749858, 20.2482399],
                [105.6742697, 20.2482426],
                [105.6733488, 20.2481336],
                [105.6720521, 20.2478972],
                [105.6714206, 20.2477548],
                [105.6710796, 20.247724],
                [105.6708414, 20.2478375],
                [105.6704168, 20.2482092],
                [105.6700433, 20.2485486],
                [105.6697725, 20.2490118],
                [105.669561, 20.2493838],
                [105.6692292, 20.2500008],
                [105.6690471, 20.2504437],
                [105.6690484, 20.2507278],
                [105.6692364, 20.2516745],
                [105.6696579, 20.2524939],
                [105.6706308, 20.2534328],
                [105.6686545, 20.255741],
                [105.668106, 20.2565326],
                [105.6676564, 20.2569764],
                [105.6658241, 20.2587044],
                [105.6622136, 20.2620912],
                [105.6598304, 20.2640739],
                [105.6579522, 20.2657949],
                [105.6566709, 20.267347],
                [105.655821, 20.2680924],
                [105.6542029, 20.2690932],
                [105.6532525, 20.2697914],
                [105.6526595, 20.2704793],
                [105.6524099, 20.2707645],
                [105.6522311, 20.2709865],
                [105.6517129, 20.271303],
                [105.6512307, 20.2716761],
                [105.6510014, 20.2719592],
                [105.6508564, 20.2722536],
                [105.6507829, 20.2728545],
                [105.6507405, 20.2730857],
                [105.6500472, 20.2737416],
                [105.6488792, 20.274425],
                [105.6481608, 20.2746171],
                [105.6467245, 20.2751435],
                [105.6453942, 20.2757913],
                [105.6446758, 20.2759993],
                [105.644041, 20.2761911],
                [105.6433058, 20.2763833],
                [105.6422045, 20.2770032],
                [105.6417212, 20.2774628],
                [105.6413618, 20.2777599],
                [105.6412784, 20.2778075],
                [105.6411114, 20.2778555],
                [105.64071, 20.2778727],
                [105.6400741, 20.2777962],
                [105.6392195, 20.2773888],
                [105.6389014, 20.2773268],
                [105.6383163, 20.2774394],
                [105.6377832, 20.2779466],
                [105.6373993, 20.2781533],
                [105.6369644, 20.2781707],
                [105.6359424, 20.2777323],
                [105.6357583, 20.2776855],
                [105.6354061, 20.2774975],
                [105.6338541, 20.2783557],
                [105.6308038, 20.2788985],
                [105.6302546, 20.2788884],
                [105.6290339, 20.2789718],
                [105.6279984, 20.2793228],
                [105.6267803, 20.2800851],
                [105.6255471, 20.2812106],
                [105.6252198, 20.2814913],
                [105.6250027, 20.2816183],
                [105.6245687, 20.2818093],
                [105.6240504, 20.2818902],
                [105.6232143, 20.2819247],
                [105.6221099, 20.2818339],
                [105.6212401, 20.281837],
                [105.620789, 20.2819491],
                [105.6203218, 20.2822507],
                [105.6194384, 20.2830435],
                [105.6184564, 20.2842785],
                [105.616798, 20.2857621],
                [105.614847, 20.2873322],
                [105.6133965, 20.2885214],
                [105.6119812, 20.2902],
                [105.6110054, 20.2912842],
                [105.6107889, 20.2915533],
                [105.6107234, 20.2919167],
                [105.6107088, 20.2924379],
                [105.6107445, 20.293022],
                [105.6109312, 20.2937319],
                [105.6113063, 20.2954989],
                [105.6112791, 20.2965637],
                [105.6111467, 20.2969115],
                [105.6108801, 20.2971967],
                [105.6105966, 20.2974344],
                [105.6097122, 20.298006],
                [105.609095, 20.2984502],
                [105.6086459, 20.2991149],
                [105.6083819, 20.3000317],
                [105.6081837, 20.3006797],
                [105.6078551, 20.3015123],
                [105.6071063, 20.3025096],
                [105.6060916, 20.3040289],
                [105.6046973, 20.3059697],
                [105.6036176, 20.3079786],
                [105.6029383, 20.3086696],
                [105.6027379, 20.3087965],
                [105.6023204, 20.3089875],
                [105.6016864, 20.3094002],
                [105.6011361, 20.3098916],
                [105.6006863, 20.3104142],
                [105.6001041, 20.311253],
                [105.5997377, 20.3116806],
                [105.5991875, 20.312172],
                [105.5982694, 20.3126961],
                [105.596253, 20.3136154],
                [105.5958019, 20.3137589],
                [105.595267, 20.3138714],
                [105.594565, 20.3140473],
                [105.5939795, 20.3140651],
                [105.5935946, 20.3140349],
                [105.593293, 20.3139413],
                [105.5927902, 20.3136745],
                [105.5924212, 20.3134705],
                [105.5920529, 20.3133769],
                [105.5887197, 20.3123619],
                [105.5874633, 20.311924],
                [105.5864686, 20.311802],
                [105.5854817, 20.311837],
                [105.5840436, 20.3120312],
                [105.5818014, 20.3119282],
                [105.5803168, 20.3118382],
                [105.5798816, 20.3118081],
                [105.5797143, 20.3118086],
                [105.5791962, 20.3119367],
                [105.5773615, 20.3134428],
                [105.5746677, 20.315497],
                [105.5729489, 20.3167026],
                [105.5724647, 20.3169726],
                [105.5719137, 20.3172744],
                [105.5712952, 20.3174343],
                [105.5706097, 20.3175629],
                [105.5699573, 20.3175809],
                [105.5693881, 20.3174879],
                [105.568366, 20.3170491],
                [105.5670747, 20.3162323],
                [105.5661422, 20.315827],
                [105.5658118, 20.3156806],
                [105.5651552, 20.3158618],
                [105.5644033, 20.3161326],
                [105.5638023, 20.3165135],
                [105.5630019, 20.3172425],
                [105.5627195, 20.3177959],
                [105.5627786, 20.3182617],
                [105.5628983, 20.3189561],
                [105.5633551, 20.3203441],
                [105.5636886, 20.3213367],
                [105.564854, 20.3232221],
                [105.5649049, 20.3234588],
                [105.5650075, 20.3240586],
                [105.5650089, 20.3244375],
                [105.5648595, 20.3247695],
                [105.5643931, 20.3253552],
                [105.5627423, 20.3269079],
                [105.5622751, 20.3272252],
                [105.5618905, 20.327337],
                [105.5614887, 20.3272909],
                [105.5609911, 20.327208],
                [105.5606902, 20.3272721],
                [105.5600391, 20.3276848],
                [105.5592055, 20.3285242],
                [105.5585716, 20.3290473],
                [105.5582376, 20.3292221],
                [105.5577194, 20.3293659],
                [105.5574183, 20.3293826],
                [105.5572509, 20.3293673],
                [105.5569322, 20.3291473],
                [105.5560934, 20.3285184],
                [105.5553556, 20.3281102],
                [105.5551359, 20.328036],
                [105.5545826, 20.3278846],
                [105.5540034, 20.3279871],
                [105.5532851, 20.3285823],
                [105.5524737, 20.3298126],
                [105.5524006, 20.3299354],
                [105.5516907, 20.3314175],
                [105.5510859, 20.3323749],
                [105.5494341, 20.3338276],
                [105.5488715, 20.3344336],
                [105.5487483, 20.3349347],
                [105.5491036, 20.3383202],
                [105.5497811, 20.3445022],
                [105.550102, 20.3469534],
                [105.5484524, 20.3483731],
                [105.5472385, 20.3494675],
                [105.5468353, 20.3502055],
                [105.5453419, 20.3516838],
                [105.5445668, 20.3532481],
                [105.5438605, 20.3566099],
                [105.5434043, 20.3600889],
                [105.5427586, 20.36216],
                [105.5416957, 20.3637357],
                [105.5413219, 20.3649172],
                [105.5410097, 20.367169],
                [105.5407504, 20.367856],
                [105.5402578, 20.3684067],
                [105.5390387, 20.3690692],
                [105.5372383, 20.3698985],
                [105.5366859, 20.369955],
                [105.5351151, 20.3698776],
                [105.5344763, 20.370209],
                [105.5329101, 20.3714767],
                [105.5321547, 20.3716986],
                [105.5310498, 20.3717844],
                [105.5296532, 20.3716515],
                [105.5292164, 20.3714824],
                [105.5272648, 20.3706107],
                [105.5264194, 20.3700372],
                [105.5254269, 20.3689706],
                [105.5247839, 20.3680398],
                [105.5244328, 20.3674101],
                [105.5241969, 20.3664781],
                [105.524105, 20.3654076],
                [105.5243597, 20.3633485],
                [105.5238322, 20.3621152],
                [105.522839, 20.3608008],
                [105.5221683, 20.3602266],
                [105.5215278, 20.3599758],
                [105.5208295, 20.3598408],
                [105.5200446, 20.3598981],
                [105.5190564, 20.3600108],
                [105.5165863, 20.3604845],
                [105.5147255, 20.3605449],
                [105.5131548, 20.3603852],
                [105.5101304, 20.3602571],
                [105.5069024, 20.360157],
                [105.505972, 20.3601598],
                [105.5053331, 20.3604085],
                [105.5042876, 20.360823],
                [105.5021733, 20.3610703],
                [105.5017887, 20.3611142],
                [105.4968748, 20.3636722],
                [105.4945085, 20.3645872],
                [105.4941073, 20.3646541],
                [105.4933652, 20.3649015],
                [105.4926235, 20.365289],
                [105.4924383, 20.3654649],
                [105.4918081, 20.3658523],
                [105.4909923, 20.3663102],
                [105.490399, 20.3666272],
                [105.4896204, 20.3670851],
                [105.4889156, 20.3674024],
                [105.487692, 20.3680717],
                [105.4864506, 20.3688217],
                [105.4851164, 20.3698068],
                [105.4839298, 20.3704759],
                [105.4831803, 20.3709794],
                [105.48241, 20.3714965],
                [105.480186, 20.3730449],
                [105.4789996, 20.3737843],
                [105.4779988, 20.3745231],
                [105.4771465, 20.3751915],
                [105.4766272, 20.375403],
                [105.4757357, 20.3754403],
                [105.4751784, 20.3753716],
                [105.4745097, 20.3753383],
                [105.473581, 20.3753406],
                [105.4725041, 20.3754133],
                [105.4717243, 20.3754854],
                [105.4706473, 20.3755582],
                [105.4697558, 20.3755253],
                [105.4687522, 20.3753525],
                [105.4680626, 20.3754216],
                [105.4670234, 20.3757397],
                [105.4663184, 20.3759868],
                [105.4654644, 20.376094],
                [105.4649071, 20.3760604],
                [105.464424, 20.3759914],
                [105.4634945, 20.3756781],
                [105.4623795, 20.3754353],
                [105.4614875, 20.3752622],
                [105.4604101, 20.3751597],
                [105.4599276, 20.3753361],
                [105.4591491, 20.3758288],
                [105.4578885, 20.3766733],
                [105.4568506, 20.3774472],
                [105.4563317, 20.3778692],
                [105.4557382, 20.3781511],
                [105.4548475, 20.3784337],
                [105.4537339, 20.3786818],
                [105.4526576, 20.379035],
                [105.4515441, 20.3793182],
                [105.4498197, 20.3797305],
                [105.4480751, 20.3801203],
                [105.4459977, 20.3805476],
                [105.4445484, 20.3809349],
                [105.4432864, 20.3812885],
                [105.4422841, 20.3815362],
                [105.4410226, 20.3820651],
                [105.4403918, 20.382312],
                [105.4396501, 20.3827695],
                [105.4385003, 20.3834032],
                [105.4377213, 20.3838257],
                [105.4363859, 20.3845301],
                [105.4355325, 20.3849177],
                [105.434679, 20.3852001],
                [105.4341961, 20.3852013],
                [105.4337129, 20.385097],
                [105.4331363, 20.384785],
                [105.4322438, 20.3844364],
                [105.431277, 20.3840529],
                [105.4307239, 20.3840275],
                [105.4302944, 20.384048],
                [105.4299488, 20.3840479],
                [105.4295169, 20.3837792],
                [105.427049, 20.3824006],
                [105.424655, 20.3813024],
                [105.423495, 20.3806482],
                [105.4226807, 20.3800875],
                [105.4213357, 20.3793281],
                [105.4178316, 20.3772019],
                [105.4149443, 20.3754496],
                [105.4127356, 20.3745497],
                [105.4108801, 20.3739169],
                [105.4095216, 20.3735909],
                [105.4089923, 20.373464],
                [105.4066642, 20.3729059],
                [105.4061487, 20.3727951],
                [105.4056289, 20.3729014],
                [105.4039591, 20.3736062],
                [105.4028832, 20.3741344],
                [105.4023514, 20.3744761],
                [105.4010899, 20.3750747],
                [105.4004962, 20.3753214],
                [105.3999768, 20.3755329],
                [105.3993827, 20.3756392],
                [105.3984172, 20.3757465],
                [105.3976375, 20.3758883],
                [105.3970434, 20.3759946],
                [105.3965237, 20.376136],
                [105.3957814, 20.3763829],
                [105.3945564, 20.376701],
                [105.3934056, 20.3769838],
                [105.391735, 20.3773729],
                [105.3907696, 20.3775502],
                [105.3889133, 20.3779396],
                [105.3880963, 20.3780464],
                [105.387391, 20.378223],
                [105.3864258, 20.3784705],
                [105.3857577, 20.3786822],
                [105.384793, 20.3791749],
                [105.3840883, 20.3796321],
                [105.3834207, 20.3800542],
                [105.3824929, 20.3804066],
                [105.3820843, 20.3804426],
                [105.3815647, 20.3805837],
                [105.3811934, 20.3806896],
                [105.3804886, 20.3811118],
                [105.3801286, 20.3814922],
                [105.379463, 20.3828259],
                [105.3791299, 20.3833525],
                [105.3787593, 20.3837038],
                [105.3782401, 20.3840905],
                [105.3774234, 20.3843024],
                [105.376829, 20.3843035],
                [105.3759898, 20.384268],
                [105.3753213, 20.3843043],
                [105.3730924, 20.3843086],
                [105.3723497, 20.384345],
                [105.3716438, 20.3843465],
                [105.3710868, 20.3844176],
                [105.3704928, 20.384594],
                [105.3698991, 20.3848406],
                [105.3693424, 20.3851222],
                [105.3688232, 20.3854738],
                [105.3684897, 20.3858601],
                [105.3682306, 20.3862814],
                [105.3680462, 20.3868427],
                [105.3677876, 20.3875093],
                [105.3673805, 20.3881763],
                [105.3670845, 20.3887028],
                [105.3669365, 20.3889835],
                [105.3665658, 20.3892999],
                [105.3659721, 20.3896165],
                [105.3653407, 20.3896878],
                [105.3647835, 20.3896889],
                [105.3640778, 20.3897603],
                [105.3630379, 20.3898323],
                [105.3624435, 20.3898334],
                [105.361738, 20.3899398],
                [105.3610696, 20.3901164],
                [105.3603274, 20.3904685],
                [105.3597337, 20.3907851],
                [105.3588234, 20.391272],
                [105.3581553, 20.3915888],
                [105.3574873, 20.3918706],
                [105.3567447, 20.3920822],
                [105.3563364, 20.3921881],
                [105.3557789, 20.392049],
                [105.3552951, 20.3916992],
                [105.354848, 20.3911039],
                [105.3544377, 20.3902982],
                [105.3539537, 20.3898433],
                [105.3532846, 20.389564],
                [105.3526899, 20.3894599],
                [105.352207, 20.3894958],
                [105.3516127, 20.3894968],
                [105.3510557, 20.3896381],
                [105.3505361, 20.3898494],
                [105.3497936, 20.3900611],
                [105.3490512, 20.3903078],
                [105.3483457, 20.3904494],
                [105.3477514, 20.3905206],
                [105.34686, 20.3905922],
                [105.3458944, 20.3907342],
                [105.3453745, 20.3908402],
                [105.3451228, 20.391011],
                [105.3449748, 20.3912568],
                [105.3448272, 20.3917479],
                [105.3447539, 20.3922038],
                [105.3447548, 20.3926246],
                [105.344644, 20.3929753],
                [105.3443848, 20.3933615],
                [105.3441257, 20.3937828],
                [105.3438295, 20.3942742],
                [105.3436078, 20.3948005],
                [105.3430891, 20.3955026],
                [105.342571, 20.3964853],
                [105.3423486, 20.3967311],
                [105.3420147, 20.396942],
                [105.3418291, 20.3970124],
                [105.3400459, 20.3970156],
                [105.3396376, 20.3971565],
                [105.3392667, 20.3974727],
                [105.339193, 20.3977884],
                [105.3391933, 20.3978936],
                [105.3391567, 20.3981391],
                [105.3390826, 20.3982794],
                [105.3387857, 20.3984202],
                [105.3382285, 20.3984211],
                [105.3378939, 20.3983516],
                [105.3374479, 20.3982472],
                [105.3371508, 20.3982477],
                [105.3368537, 20.3983184],
                [105.3365567, 20.3984591],
                [105.3362229, 20.3987051],
                [105.335889, 20.3989161],
                [105.335481, 20.3992674],
                [105.3349246, 20.3996891],
                [105.3341826, 20.4001812],
                [105.333589, 20.4005678],
                [105.3331807, 20.4007438],
                [105.3328097, 20.401025],
                [105.3323278, 20.4015166],
                [105.3321797, 20.4017624],
                [105.3321433, 20.4021481],
                [105.3321066, 20.4023936],
                [105.3320326, 20.4025339],
                [105.3319214, 20.4026394],
                [105.3316451, 20.4027633],
                [105.331385, 20.4027638],
                [105.3304184, 20.4023797],
                [105.3300463, 20.4020998],
                [105.3295629, 20.4018902],
                [105.3291913, 20.4018908],
                [105.3289316, 20.4019965],
                [105.3287091, 20.4022774],
                [105.3285985, 20.4026983],
                [105.3285996, 20.4032592],
                [105.3285258, 20.4034698],
                [105.3283406, 20.4037155],
                [105.3279693, 20.4038563],
                [105.3275611, 20.4040674],
                [105.3272643, 20.4042783],
                [105.3268561, 20.4045244],
                [105.3264477, 20.4047004],
                [105.3258931, 20.4048391],
                [105.3251869, 20.4047351],
                [105.3245176, 20.4044206],
                [105.3239225, 20.4041061],
                [105.3235508, 20.4040014],
                [105.3232539, 20.4041772],
                [105.3231428, 20.4043527],
                [105.3229949, 20.4047387],
                [105.3226243, 20.4051601],
                [105.3221793, 20.4056165],
                [105.3215487, 20.4060735],
                [105.3209547, 20.4063198],
                [105.3202495, 20.4066715],
                [105.3193585, 20.4070236],
                [105.3182943, 20.407225],
                [105.3173664, 20.4077174],
                [105.3169583, 20.4079985],
                [105.3166618, 20.4084198],
                [105.3165512, 20.4088407],
                [105.3163664, 20.4093318],
                [105.316107, 20.4097179],
                [105.3154764, 20.4102098],
                [105.3146601, 20.4107721],
                [105.3137693, 20.4112644],
                [105.3132499, 20.4116509],
                [105.3123963, 20.412143],
                [105.311877, 20.4125647],
                [105.3110978, 20.4131619],
                [105.3101702, 20.4137945],
                [105.3091683, 20.4144973],
                [105.3083891, 20.4150245],
                [105.3076949, 20.4155386],
                [105.3072497, 20.4158899],
                [105.3067304, 20.4163816],
                [105.3063969, 20.4168028],
                [105.3061005, 20.4172941],
                [105.3029494, 20.4211908],
                [105.3027258, 20.4207705],
                [105.3025188, 20.4202677],
                [105.302286, 20.4197892],
                [105.3020144, 20.4192002],
                [105.3015872, 20.4185374],
                [105.301199, 20.4177643],
                [105.3003834, 20.4165124],
                [105.2993329, 20.4164376],
                [105.296453, 20.4166918],
                [105.2956221, 20.4169241],
                [105.2951022, 20.4170301],
                [105.2945965, 20.4172716],
                [105.2941512, 20.4175528],
                [105.2934087, 20.4178694],
                [105.2925548, 20.4182563],
                [105.2916637, 20.4186433],
                [105.2907728, 20.4191004],
                [105.290216, 20.4194168],
                [105.2897708, 20.419768],
                [105.2894741, 20.4201192],
                [105.2889918, 20.4205405],
                [105.288398, 20.4209271],
                [105.2879154, 20.4211732],
                [105.2873958, 20.4215245],
                [105.2868763, 20.421911],
                [105.2863195, 20.4222274],
                [105.2857998, 20.4225436],
                [105.2853914, 20.4227195],
                [105.2846859, 20.422966],
                [105.2841289, 20.4231771],
                [105.2836835, 20.4234232],
                [105.2828668, 20.4239152],
                [105.2823472, 20.4242315],
                [105.2816199, 20.4249172],
                [105.2812489, 20.4252684],
                [105.2811061, 20.4254438],
                [105.2809717, 20.4256249],
                [105.280846, 20.4258114],
                [105.2807292, 20.4260029],
                [105.2806216, 20.4261991],
                [105.2804658, 20.426529],
                [105.2804174, 20.4266618],
                [105.2803784, 20.4267973],
                [105.2803491, 20.4269348],
                [105.2803294, 20.4270739],
                [105.2803192, 20.4273493],
                [105.2803461, 20.4276188],
                [105.2803731, 20.4277518],
                [105.2804461, 20.4279797],
                [105.2804688, 20.4280968],
                [105.2804822, 20.4282152],
                [105.2804807, 20.4284531],
                [105.2804659, 20.4285713],
                [105.2804083, 20.428803],
                [105.2803659, 20.4289152],
                [105.2802551, 20.4291292],
                [105.2801165, 20.4293197],
                [105.2799502, 20.4294897],
                [105.2797596, 20.4296354],
                [105.2795486, 20.429754],
                [105.2793217, 20.4298431],
                [105.2783262, 20.4301003],
                [105.2781359, 20.430176],
                [105.2779622, 20.430281],
                [105.2778103, 20.4304124],
                [105.2776847, 20.4305662],
                [105.277589, 20.4307378],
                [105.2775575, 20.4308165],
                [105.2775139, 20.4309791],
                [105.2774203, 20.4311874],
                [105.2773358, 20.4313228],
                [105.2773043, 20.4313553],
                [105.2772263, 20.4314032],
                [105.2771819, 20.4314172],
                [105.277089, 20.4314235],
                [105.2769992, 20.4314005],
                [105.2769586, 20.4313788],
                [105.2767455, 20.4311414],
                [105.2766468, 20.4310169],
                [105.2763668, 20.4306234],
                [105.2761514, 20.4303778],
                [105.2760338, 20.4302633],
                [105.2757727, 20.4300468],
                [105.2756293, 20.4299461],
                [105.2754802, 20.429853],
                [105.2753259, 20.4297678],
                [105.2751667, 20.4296906],
                [105.2750033, 20.4296219],
                [105.2746405, 20.4295335],
                [105.2742786, 20.4294214],
                [105.2739197, 20.429285],
                [105.2737774, 20.4292191],
                [105.273631, 20.4291616],
                [105.2734811, 20.4291129],
                [105.2733281, 20.429073],
                [105.2731728, 20.4290423],
                [105.2730157, 20.4290208],
                [105.2728575, 20.4290086],
                [105.2725774, 20.4290098],
                [105.2722991, 20.4290401],
                [105.2721617, 20.429066],
                [105.2718296, 20.4291531],
                [105.2714443, 20.4292829],
                [105.2710712, 20.4294409],
                [105.2708758, 20.4295375],
                [105.2705404, 20.429723],
                [105.2703845, 20.4297827],
                [105.2702979, 20.4298053],
                [105.2702097, 20.4298213],
                [105.2700305, 20.429833],
                [105.2688815, 20.4298136],
                [105.2685807, 20.4298419],
                [105.2682862, 20.4299056],
                [105.2680026, 20.4300037],
                [105.2677345, 20.4301346],
                [105.2676038, 20.4302142],
                [105.2674789, 20.4303017],
                [105.2673604, 20.4303966],
                [105.2672487, 20.4304986],
                [105.2671444, 20.4306072],
                [105.2669582, 20.4307527],
                [105.2667531, 20.430874],
                [105.2665329, 20.4309691],
                [105.2664025, 20.4310108],
                [105.2662691, 20.4310432],
                [105.2656935, 20.4311229],
                [105.2650922, 20.4312339],
                [105.2644987, 20.4313771],
                [105.263915, 20.4315522],
                [105.2634858, 20.4317037],
                [105.2630604, 20.4318642],
                [105.2626389, 20.4320337],
                [105.2622216, 20.4322119],
                [105.2618087, 20.432399],
                [105.2614003, 20.4325947],
                [105.2609968, 20.4327989],
                [105.2606137, 20.4330032],
                [105.2602353, 20.4332152],
                [105.2594938, 20.433662],
                [105.2591309, 20.4338965],
                [105.2584219, 20.4343874],
                [105.2590227, 20.4349806],
                [105.2607179, 20.4371421],
                [105.2609257, 20.4378182],
                [105.2609984, 20.4379998],
                [105.2611685, 20.4383535],
                [105.2612711, 20.4385341],
                [105.2613823, 20.4387102],
                [105.261502, 20.4388813],
                [105.2616298, 20.4390472],
                [105.2619076, 20.439445],
                [105.262179, 20.4398465],
                [105.2627027, 20.4406608],
                [105.2629636, 20.4410879],
                [105.2634643, 20.4419531],
                [105.2639365, 20.4428323],
                [105.2640254, 20.4430206],
                [105.2641826, 20.4434052],
                [105.2643118, 20.4437988],
                [105.2644147, 20.4442107],
                [105.2644883, 20.4446395],
                [105.2645129, 20.4448555],
                [105.2645374, 20.4452892],
                [105.2645373, 20.4466926],
                [105.2645914, 20.4471208],
                [105.2646768, 20.4479794],
                [105.2647038, 20.4483443],
                [105.264766, 20.4494656],
                [105.2647987, 20.4498556],
                [105.2648882, 20.4506334],
                [105.2650094, 20.4514074],
                [105.2650492, 20.4515418],
                [105.2651547, 20.4518025],
                [105.2652932, 20.4520493],
                [105.265461, 20.4522765],
                [105.2655547, 20.4523816],
                [105.2657613, 20.4525752],
                [105.2659911, 20.4527443],
                [105.2669835, 20.4535888],
                [105.2671358, 20.4538044],
                [105.2672017, 20.4539181],
                [105.2673116, 20.4541554],
                [105.2673912, 20.454403],
                [105.2674203, 20.4545348],
                [105.2674525, 20.4548021],
                [105.2674556, 20.4549367],
                [105.2674356, 20.455205],
                [105.2674127, 20.4553379],
                [105.2672548, 20.4553642],
                [105.2669359, 20.4553934],
                [105.2666154, 20.4553913],
                [105.2664556, 20.4553786],
                [105.266124, 20.4553264],
                [105.2659533, 20.4552857],
                [105.2657855, 20.4552358],
                [105.2655119, 20.4551362],
                [105.2652852, 20.4550789],
                [105.2650518, 20.4550553],
                [105.2648143, 20.4550664],
                [105.2646955, 20.4550853],
                [105.2645787, 20.4551131],
                [105.2644646, 20.4551496],
                [105.264354, 20.4551944],
                [105.2642476, 20.4552474],
                [105.2641531, 20.4553062],
                [105.2640636, 20.4553715],
                [105.2639014, 20.4555201],
                [105.2637648, 20.4556897],
                [105.26369, 20.4558117],
                [105.2636239, 20.455938],
                [105.2635666, 20.4560681],
                [105.2635186, 20.4562014],
                [105.2634799, 20.4563373],
                [105.2634507, 20.4564753],
                [105.2634312, 20.4566149],
                [105.2634215, 20.4567553],
                [105.2634279, 20.4569998],
                [105.2634638, 20.4572421],
                [105.2636442, 20.4578873],
                [105.2636878, 20.4580945],
                [105.2637219, 20.4583033],
                [105.2637451, 20.4584985],
                [105.2637666, 20.4588909],
                [105.2637648, 20.4590874],
                [105.2635802, 20.4625353],
                [105.2635694, 20.4626557],
                [105.263522, 20.462893],
                [105.2634856, 20.4630089],
                [105.2633886, 20.4632326],
                [105.2633283, 20.4633394],
                [105.2631855, 20.4635403],
                [105.2631106, 20.4636263],
                [105.2629445, 20.4637843],
                [105.262854, 20.4638558],
                [105.2625941, 20.464025],
                [105.2625333, 20.4640731],
                [105.2624778, 20.4641267],
                [105.262385, 20.4642479],
                [105.2623162, 20.464393],
                [105.2622933, 20.4644746],
                [105.2622803, 20.4645581],
                [105.2622772, 20.4646424],
                [105.2622843, 20.4647265],
                [105.2622738, 20.4648363],
                [105.2622536, 20.4649449],
                [105.2622239, 20.4650516],
                [105.2621848, 20.4651556],
                [105.2621365, 20.4652561],
                [105.2620796, 20.4653526],
                [105.2620142, 20.4654443],
                [105.2619409, 20.4655306],
                [105.2617426, 20.4657343],
                [105.2616522, 20.4658431],
                [105.2615681, 20.4659563],
                [105.2613502, 20.4662925],
                [105.2612685, 20.4663949],
                [105.2611797, 20.4664919],
                [105.260982, 20.4666679],
                [105.2608741, 20.466746],
                [105.2606539, 20.4668753],
                [105.2605435, 20.4669271],
                [105.2603134, 20.4670113],
                [105.2599252, 20.4671097],
                [105.2596213, 20.4671697],
                [105.2594673, 20.4671883],
                [105.2592194, 20.467202],
                [105.2590351, 20.4672261],
                [105.2588564, 20.4672745],
                [105.2587562, 20.4673136],
                [105.2586597, 20.4673604],
                [105.2584809, 20.4674756],
                [105.2578318, 20.4680535],
                [105.2577317, 20.4681336],
                [105.2575174, 20.4682767],
                [105.257404, 20.4683393],
                [105.2571666, 20.4684455],
                [105.2568872, 20.4685332],
                [105.256598, 20.4685863],
                [105.255812, 20.4685737],
                [105.2557343, 20.4685784],
                [105.2555822, 20.468609],
                [105.2554393, 20.4686667],
                [105.2553638, 20.4687111],
                [105.2552941, 20.4687632],
                [105.2552311, 20.4688222],
                [105.2551756, 20.4688875],
                [105.2550657, 20.4690483],
                [105.2549953, 20.4691332],
                [105.2549175, 20.4692122],
                [105.2548328, 20.4692847],
                [105.2547419, 20.4693502],
                [105.2546517, 20.4694047],
                [105.254459, 20.4694928],
                [105.254139, 20.4695824],
                [105.2540271, 20.4696214],
                [105.2538139, 20.4697221],
                [105.2537221, 20.4697777],
                [105.2535515, 20.4699056],
                [105.2533326, 20.4701236],
                [105.2531819, 20.4702495],
                [105.2530146, 20.4703553],
                [105.2528292, 20.4704407],
                [105.2527322, 20.4704742],
                [105.2525321, 20.4705221],
                [105.2511048, 20.4707071],
                [105.2495169, 20.4711594],
                [105.2491177, 20.4712917],
                [105.248722, 20.4714329],
                [105.2483299, 20.4715828],
                [105.2479418, 20.4717414],
                [105.2475577, 20.4719085],
                [105.2471781, 20.4720841],
                [105.2468259, 20.4722566],
                [105.2461344, 20.4726232],
                [105.245461, 20.4730183],
                [105.2446786, 20.4735205],
                [105.2446232, 20.4735707],
                [105.2445341, 20.4736882],
                [105.2445017, 20.4737537],
                [105.2444637, 20.4738933],
                [105.2444588, 20.4739556],
                [105.2444702, 20.4740799],
                [105.2444864, 20.4741405],
                [105.2445388, 20.4742552],
                [105.2451718, 20.4751397],
                [105.243895, 20.476185],
                [105.2437276, 20.4762444],
                [105.2435517, 20.4762754],
                [105.2433653, 20.4762767],
                [105.2432728, 20.4762654],
                [105.2429329, 20.4761252],
                [105.2426168, 20.4759613],
                [105.2423257, 20.4757773],
                [105.2421868, 20.4756766],
                [105.2419236, 20.4754588],
                [105.2417972, 20.475393],
                [105.2416581, 20.4753558],
                [105.2415894, 20.4753487],
                [105.2414516, 20.4753558],
                [105.2413316, 20.4754061],
                [105.241216, 20.4754648],
                [105.2410109, 20.4755985],
                [105.2409215, 20.4756712],
                [105.2407595, 20.475833],
                [105.2406227, 20.4760141],
                [105.2405525, 20.4761493],
                [105.2405155, 20.4762955],
                [105.24051, 20.4763717],
                [105.2405262, 20.4765234],
                [105.2405476, 20.4765971],
                [105.2406647, 20.4767882],
                [105.2408802, 20.47718],
                [105.2409883, 20.4774013],
                [105.2411806, 20.4778534],
                [105.241202, 20.4779239],
                [105.2412226, 20.4780691],
                [105.2412215, 20.4781424],
                [105.2411972, 20.4782851],
                [105.2411453, 20.4784191],
                [105.2410675, 20.4785416],
                [105.2410197, 20.4785972],
                [105.2409374, 20.4786742],
                [105.2408484, 20.4787444],
                [105.2407533, 20.4788072],
                [105.2406528, 20.4788621],
                [105.2403271, 20.4789925],
                [105.2401185, 20.4790997],
                [105.2399198, 20.4792328],
                [105.2398272, 20.4793074],
                [105.2396572, 20.4794716],
                [105.2416723, 20.4817979],
                [105.2417189, 20.4818614],
                [105.2417951, 20.4819977],
                [105.2418467, 20.4821409],
                [105.2418736, 20.4822871],
                [105.2418844, 20.4825008],
                [105.2419167, 20.4826372],
                [105.2419769, 20.4827711],
                [105.2420658, 20.4828968],
                [105.2421784, 20.4830044],
                [105.2425229, 20.4832774],
                [105.2427034, 20.4834042],
                [105.2428891, 20.4835244],
                [105.2431746, 20.4836904],
                [105.2433517, 20.483814],
                [105.2435088, 20.4839592],
                [105.2435803, 20.4840408],
                [105.243704, 20.4842171],
                [105.2437556, 20.484311],
                [105.2446675, 20.4864015],
                [105.2447929, 20.4867711],
                [105.244842, 20.4869595],
                [105.2449128, 20.4873416],
                [105.2449343, 20.4875345],
                [105.2449493, 20.4878514],
                [105.2449423, 20.4879746],
                [105.2449255, 20.488097],
                [105.244899, 20.4882178],
                [105.244863, 20.4883365],
                [105.2448176, 20.4884523],
                [105.244763, 20.4885646],
                [105.2446997, 20.4886728],
                [105.2446348, 20.4887671],
                [105.2444852, 20.4889422],
                [105.2443117, 20.4890966],
                [105.2440176, 20.4893083],
                [105.2438243, 20.4894584],
                [105.2436373, 20.4896154],
                [105.243457, 20.4897791],
                [105.2432835, 20.4899492],
                [105.2431143, 20.4901285],
                [105.2429527, 20.490314],
                [105.2427991, 20.4905052],
                [105.2426536, 20.490702],
                [105.2424805, 20.490957],
                [105.2423883, 20.491048],
                [105.2422744, 20.4911138],
                [105.2422116, 20.4911359],
                [105.2420831, 20.4911562],
                [105.2419569, 20.4911467],
                [105.2418956, 20.4911312],
                [105.2417107, 20.4910496],
                [105.241561, 20.4910115],
                [105.241406, 20.4910044],
                [105.2412573, 20.4910275],
                [105.2411857, 20.4910498],
                [105.2410519, 20.491115],
                [105.2403438, 20.4915371],
                [105.2402387, 20.491632],
                [105.2401571, 20.4917454],
                [105.2401263, 20.4918075],
                [105.2400863, 20.4919391],
                [105.240077, 20.4920861],
                [105.2401032, 20.4922313],
                [105.2401292, 20.4923009],
                [105.240578, 20.4930484],
                [105.2406366, 20.4931874],
                [105.2406617, 20.493335],
                [105.2406521, 20.4934842],
                [105.2406083, 20.4936279],
                [105.2405323, 20.4937593],
                [105.2404833, 20.4938184],
                [105.2403688, 20.4939181],
                [105.2403037, 20.4939589],
                [105.2401615, 20.4940197],
                [105.2400861, 20.4940392],
                [105.2399306, 20.4940549],
                [105.2397752, 20.4940395],
                [105.2392173, 20.493934],
                [105.2389878, 20.4938768],
                [105.2388702, 20.4938608],
                [105.2387517, 20.4938532],
                [105.2385145, 20.4938636],
                [105.2384135, 20.4938785],
                [105.2382157, 20.4939269],
                [105.2380264, 20.4939993],
                [105.2378557, 20.4940805],
                [105.2377654, 20.4941104],
                [105.2376726, 20.4941328],
                [105.2374826, 20.4941545],
                [105.2372914, 20.494145],
                [105.237189, 20.4941268],
                [105.2370889, 20.4940995],
                [105.2369921, 20.4940635],
                [105.2368992, 20.4940191],
                [105.2368113, 20.4939666],
                [105.2367329, 20.4939344],
                [105.2365677, 20.4938933],
                [105.236397, 20.4938842],
                [105.2363118, 20.493892],
                [105.2361461, 20.4939314],
                [105.2359859, 20.4940053],
                [105.2358459, 20.4941092],
                [105.2357326, 20.4942385],
                [105.235574, 20.4944693],
                [105.2354549, 20.494624],
                [105.2352004, 20.4949218],
                [105.2349056, 20.4952214],
                [105.2347397, 20.4953723],
                [105.2345678, 20.4955173],
                [105.2342069, 20.4957881],
                [105.2335095, 20.4965921],
                [105.2315881, 20.4970244],
                [105.2314503, 20.4970638],
                [105.2313158, 20.4971122],
                [105.2311853, 20.4971694],
                [105.2310594, 20.497235],
                [105.2309387, 20.4973087],
                [105.2308238, 20.4973902],
                [105.2307154, 20.497479],
                [105.2306139, 20.4975748],
                [105.2305198, 20.497677],
                [105.2304336, 20.4977851],
                [105.2303557, 20.4978987],
                [105.2302866, 20.4980171],
                [105.2302265, 20.4981398],
                [105.2301765, 20.4982641],
                [105.2301357, 20.4983914],
                [105.2301045, 20.4985211],
                [105.2300829, 20.4986524],
                [105.2300711, 20.4987849],
                [105.2300691, 20.4989178],
                [105.2300769, 20.4990505],
                [105.2300946, 20.4991824],
                [105.2301219, 20.4993128],
                [105.2301588, 20.4994411],
                [105.230205, 20.4995668],
                [105.2317393, 20.5031292],
                [105.2300978, 20.5042246],
                [105.2274531, 20.5054506],
                [105.2266055, 20.5054556],
                [105.2263547, 20.5054368],
                [105.2260836, 20.505395],
                [105.2257955, 20.505325],
                [105.2249211, 20.5049305],
                [105.2247488, 20.5049352],
                [105.2244055, 20.5049631],
                [105.2240655, 20.505016],
                [105.2238618, 20.50506],
                [105.22346, 20.5051678],
                [105.2232623, 20.5052314],
                [105.2228746, 20.5053777],
                [105.2226555, 20.5054738],
                [105.2224408, 20.505578],
                [105.2222307, 20.5056903],
                [105.2220256, 20.5058103],
                [105.2218258, 20.505938],
                [105.2200448, 20.5071539],
                [105.2196556, 20.5073992],
                [105.2192831, 20.5076664],
                [105.2189158, 20.5079477],
                [105.2185321, 20.508209],
                [105.218121, 20.5084566],
                [105.2174862, 20.5087885],
                [105.2170814, 20.5090242],
                [105.2166962, 20.509287],
                [105.2163327, 20.5095757],
                [105.2160046, 20.5098768],
                [105.2157001, 20.5101989],
                [105.2154207, 20.5105403],
                [105.2152871, 20.5107543],
                [105.2152305, 20.5108664],
                [105.2151389, 20.511099],
                [105.2150745, 20.5113534],
                [105.2150435, 20.5116269],
                [105.2150506, 20.5119019],
                [105.2142861, 20.5116658],
                [105.2141974, 20.5116409],
                [105.2141063, 20.511625],
                [105.214014, 20.5116182],
                [105.213837, 20.5116308],
                [105.2136733, 20.5116741],
                [105.2135217, 20.5117462],
                [105.2134472, 20.5118208],
                [105.213319, 20.5119858],
                [105.2132213, 20.5121682],
                [105.2131592, 20.5123526],
                [105.2131398, 20.5124477],
                [105.2131247, 20.5126405],
                [105.2131488, 20.5129056],
                [105.2131488, 20.5130387],
                [105.2131247, 20.5133037],
                [105.2130972, 20.5134515],
                [105.2130596, 20.5135973],
                [105.2130122, 20.5137405],
                [105.2129552, 20.5138807],
                [105.2128887, 20.5140172],
                [105.2119553, 20.5154189],
                [105.2094498, 20.5156785],
                [105.2092269, 20.515716],
                [105.2090112, 20.5157806],
                [105.2088064, 20.5158711],
                [105.2085987, 20.5159982],
                [105.2084126, 20.516152],
                [105.2082421, 20.5163229],
                [105.2081485, 20.5164013],
                [105.2080485, 20.5164727],
                [105.2079429, 20.5165364],
                [105.2078323, 20.5165923],
                [105.2077174, 20.5166398],
                [105.2074907, 20.5167061],
                [105.2073741, 20.5167273],
                [105.2070206, 20.5167542],
                [105.2069042, 20.5167724],
                [105.2066767, 20.5168307],
                [105.2065532, 20.5168761],
                [105.2059561, 20.5171462],
                [105.2054663, 20.5173983],
                [105.2052149, 20.5175406],
                [105.2047256, 20.5178447],
                [105.204488, 20.5180064],
                [105.2043749, 20.5181003],
                [105.2042555, 20.5181872],
                [105.2041304, 20.5182669],
                [105.2040002, 20.5183388],
                [105.2038653, 20.5184028],
                [105.2036365, 20.5184894],
                [105.2033626, 20.5185693],
                [105.2029914, 20.5186557],
                [105.2025801, 20.5187217],
                [105.2021297, 20.5187595],
                [105.2016775, 20.518762],
                [105.2014518, 20.5187499],
                [105.195508, 20.5178054],
                [105.1949266, 20.5176819],
                [105.1946332, 20.5176322],
                [105.1940425, 20.5175569],
                [105.1934481, 20.517514],
                [105.1928714, 20.5175036],
                [105.192583, 20.5175098],
                [105.1920075, 20.5175452],
                [105.1914673, 20.5175989],
                [105.1909637, 20.5176719],
                [105.1904665, 20.5177762],
                [105.190221, 20.51784],
                [105.1897374, 20.5179903],
                [105.1892464, 20.5181785],
                [105.188997, 20.5182889],
                [105.1887521, 20.5184076],
                [105.1885119, 20.5185346],
                [105.1880882, 20.518778],
                [105.187724, 20.5190134],
                [105.1875488, 20.5191401],
                [105.1872135, 20.5194104],
                [105.1868901, 20.5197117],
                [105.1865825, 20.5200444],
                [105.1863027, 20.5203978],
                [105.1861926, 20.5205207],
                [105.1860764, 20.5206385],
                [105.1858264, 20.5208575],
                [105.1855551, 20.521053],
                [105.1852513, 20.5212303],
                [105.1850856, 20.5213111],
                [105.1849156, 20.5213836],
                [105.1847418, 20.5214477],
                [105.1845646, 20.5215031],
                [105.1845054, 20.5212624],
                [105.1844642, 20.521145],
                [105.1843595, 20.5209183],
                [105.1842256, 20.5207041],
                [105.1840641, 20.5205058],
                [105.1839739, 20.520414],
                [105.1837266, 20.5201894],
                [105.1834062, 20.5199315],
                [105.1830641, 20.5196994],
                [105.1827026, 20.5194947],
                [105.1823208, 20.5193174],
                [105.1819209, 20.5191695],
                [105.1815092, 20.5190537],
                [105.1812997, 20.5190082],
                [105.1810884, 20.518971],
                [105.1795971, 20.5188554],
                [105.1791852, 20.5182485],
                [105.1789904, 20.5179385],
                [105.1787137, 20.5174683],
                [105.1785151, 20.5171666],
                [105.1782841, 20.5168725],
                [105.17802, 20.5165896],
                [105.1740758, 20.516577],
                [105.173788, 20.5163585],
                [105.1736519, 20.5162407],
                [105.1733966, 20.5159892],
                [105.1731321, 20.5156913],
                [105.1729657, 20.5155449],
                [105.1727554, 20.5154038],
                [105.1726301, 20.5153361],
                [105.1725003, 20.5152765],
                [105.1723666, 20.515225],
                [105.1722294, 20.5151821],
                [105.1720895, 20.5151479],
                [105.1719475, 20.5151225],
                [105.1716864, 20.5150993],
                [105.1715552, 20.5150989],
                [105.171294, 20.5151205],
                [105.1711649, 20.5151424],
                [105.1709121, 20.5152079],
                [105.1708324, 20.5152196],
                [105.1706788, 20.515265],
                [105.1705404, 20.5153366],
                [105.1704788, 20.5153806],
                [105.1703703, 20.5154842],
                [105.1701194, 20.5158802],
                [105.1698939, 20.5162893],
                [105.1696944, 20.5167101],
                [105.1696018, 20.5169318],
                [105.1694384, 20.5173826],
                [105.1693046, 20.5178418],
                [105.1691803, 20.5183764],
                [105.1691153, 20.518505],
                [105.1690717, 20.5185633],
                [105.1689649, 20.5186645],
                [105.1688513, 20.518734],
                [105.1687895, 20.5187603],
                [105.1686591, 20.5187951],
                [105.1683959, 20.5189087],
                [105.1681495, 20.5190514],
                [105.1679351, 20.5192112],
                [105.1674947, 20.5196223],
                [105.1672268, 20.5198301],
                [105.1670695, 20.5199357],
                [105.1669069, 20.5200339],
                [105.1667392, 20.5201243],
                [105.166567, 20.5202069],
                [105.1654512, 20.5208048],
                [105.1644088, 20.5221816],
                [105.1643092, 20.5222923],
                [105.164091, 20.5224974],
                [105.1639731, 20.5225911],
                [105.1637216, 20.5227593],
                [105.1634516, 20.5229003],
                [105.1631544, 20.5230164],
                [105.1629948, 20.5230637],
                [105.1628323, 20.523102],
                [105.1626677, 20.5231311],
                [105.1625016, 20.5231507],
                [105.1623344, 20.523161],
                [105.1613688, 20.5231007],
                [105.1613862, 20.5232715],
                [105.1614133, 20.5234412],
                [105.16145, 20.5236093],
                [105.1614962, 20.5237753],
                [105.1615519, 20.5239388],
                [105.1616168, 20.5240992],
                [105.1616907, 20.5242562],
                [105.1618323, 20.5245071],
                [105.1619119, 20.5246279],
                [105.1621982, 20.5250172],
                [105.1623006, 20.52518],
                [105.1623946, 20.5253473],
                [105.1624799, 20.5255185],
                [105.1625564, 20.5256934],
                [105.162624, 20.5258714],
                [105.1626823, 20.5260523],
                [105.1627672, 20.5264006],
                [105.1628512, 20.5268996],
                [105.1629372, 20.527568],
                [105.1629907, 20.5282699],
                [105.1630038, 20.5286366],
                [105.1630068, 20.5290036],
                [105.1629899, 20.5296803],
                [105.1629435, 20.5302986],
                [105.1629068, 20.5306066],
                [105.1628065, 20.5312192],
                [105.1627435, 20.5315211],
                [105.1625913, 20.5321196],
                [105.1624047, 20.5327094],
                [105.1621842, 20.533289],
                [105.1620286, 20.5354938],
                [105.16199, 20.535624],
                [105.16192, 20.5357431],
                [105.161822, 20.5358443],
                [105.1617014, 20.5359213],
                [105.1602799, 20.5365643],
                [105.1584184, 20.5369335],
                [105.1583184, 20.5369606],
                [105.1581261, 20.5370349],
                [105.1580349, 20.5370817],
                [105.1578724, 20.5371875],
                [105.1577975, 20.5372481],
                [105.157662, 20.5373831],
                [105.1555109, 20.5398396],
                [105.1552667, 20.5401797],
                [105.1551343, 20.5403429],
                [105.1548501, 20.5406544],
                [105.1546986, 20.5408024],
                [105.1543781, 20.5410814],
                [105.1542095, 20.541212],
                [105.1539113, 20.5414198],
                [105.1536774, 20.541604],
                [105.1535685, 20.5417044],
                [105.1533684, 20.5419207],
                [105.1531935, 20.5421554],
                [105.1530497, 20.5423983],
                [105.1529331, 20.5426537],
                [105.1528154, 20.5429995],
                [105.152777, 20.5430768],
                [105.1527302, 20.5431498],
                [105.1526754, 20.5432179],
                [105.1526132, 20.5432801],
                [105.1525444, 20.5433358],
                [105.1524686, 20.5433851],
                [105.1523877, 20.5434264],
                [105.1523025, 20.5434594],
                [105.152214, 20.5434838],
                [105.1521233, 20.5434991],
                [105.1519568, 20.5435067],
                [105.1516235, 20.5435006],
                [105.1512922, 20.5434664],
                [105.1510351, 20.5434199],
                [105.1509404, 20.5434097],
                [105.1507502, 20.5434156],
                [105.1505649, 20.5434564],
                [105.1504071, 20.5435222],
                [105.1503336, 20.5435647],
                [105.1502001, 20.5436674],
                [105.1493847, 20.5444711],
                [105.1492625, 20.5445667],
                [105.1491253, 20.5446423],
                [105.1489766, 20.544696],
                [105.1488992, 20.5447141],
                [105.148741, 20.5447323],
                [105.1483745, 20.5446948],
                [105.1481933, 20.544663],
                [105.1478372, 20.5445735],
                [105.1476633, 20.5445161],
                [105.1473259, 20.5443768],
                [105.1471634, 20.5442953],
                [105.1468527, 20.5441094],
                [105.1466809, 20.5439872],
                [105.1465031, 20.5438729],
                [105.1463197, 20.5437665],
                [105.1461311, 20.5436685],
                [105.1459377, 20.543579],
                [105.14574, 20.5434982],
                [105.1454091, 20.5433854],
                [105.1452768, 20.5433537],
                [105.1451424, 20.5433313],
                [105.1450066, 20.5433183],
                [105.1448701, 20.5433148],
                [105.1446088, 20.5433347],
                [105.1443633, 20.5433864],
                [105.1441277, 20.5434692],
                [105.1440149, 20.5435217],
                [105.1432655, 20.5440794],
                [105.1431815, 20.5441335],
                [105.1430005, 20.5442207],
                [105.1429047, 20.5442533],
                [105.142706, 20.5442953],
                [105.1426046, 20.5443043],
                [105.1424012, 20.5442984],
                [105.1422017, 20.5442606],
                [105.1421052, 20.54423],
                [105.1414319, 20.5438472],
                [105.1413658, 20.5438218],
                [105.1412965, 20.5438053],
                [105.1412255, 20.543798],
                [105.1411524, 20.5438001],
                [105.1410804, 20.5438119],
                [105.1408941, 20.5438731],
                [105.1407812, 20.5439218],
                [105.1406729, 20.5439788],
                [105.1405171, 20.5440819],
                [105.1400026, 20.5444797],
                [105.1397892, 20.5446579],
                [105.1393786, 20.5450305],
                [105.1389753, 20.5454398],
                [105.1387758, 20.5456608],
                [105.1383984, 20.5461187],
                [105.1382124, 20.546333],
                [105.137823, 20.5467479],
                [105.1376199, 20.5469481],
                [105.1372096, 20.5473232],
                [105.1370032, 20.5474983],
                [105.1365772, 20.5478341],
                [105.136427, 20.5479894],
                [105.1363024, 20.5481634],
                [105.136206, 20.5483524],
                [105.1361398, 20.5485525],
                [105.1361051, 20.5487607],
                [105.1361033, 20.5489728],
                [105.1361148, 20.5490783],
                [105.1361658, 20.5493321],
                [105.1361873, 20.5494829],
                [105.1361992, 20.5496347],
                [105.1362014, 20.5497868],
                [105.1361938, 20.5499388],
                [105.1361765, 20.55009],
                [105.1361496, 20.5502401],
                [105.1361132, 20.5503884],
                [105.1360236, 20.5506498],
                [105.1359045, 20.5509007],
                [105.1357811, 20.5511194],
                [105.1357197, 20.5512132],
                [105.1355742, 20.5513857],
                [105.1354909, 20.5514633],
                [105.1353271, 20.5515852],
                [105.1351465, 20.5516842],
                [105.1347623, 20.5518447],
                [105.1341959, 20.5521069],
                [105.1336455, 20.5523976],
                [105.1331479, 20.5526937],
                [105.1329053, 20.5528505],
                [105.1324332, 20.5531811],
                [105.1322722, 20.5545876],
                [105.1322522, 20.5546788],
                [105.1322235, 20.5547679],
                [105.132141, 20.5549371],
                [105.1320878, 20.5550158],
                [105.1319598, 20.555158],
                [105.131886, 20.5552204],
                [105.1317311, 20.5553205],
                [105.1316474, 20.5553609],
                [105.1314704, 20.5554207],
                [105.1312852, 20.5554515],
                [105.1305127, 20.5555218],
                [105.1289356, 20.556225],
                [105.1287096, 20.556359],
                [105.1284892, 20.5565009],
                [105.1282748, 20.5566505],
                [105.1280665, 20.5568077],
                [105.1277407, 20.5570798],
                [105.1274335, 20.5573702],
                [105.1258939, 20.5586912],
                [105.1258247, 20.5587416],
                [105.1256718, 20.5588213],
                [105.1255896, 20.5588498],
                [105.1254182, 20.5588829],
                [105.1253307, 20.5588871],
                [105.1251714, 20.5588732],
                [105.1250176, 20.5588321],
                [105.1249444, 20.5588017],
                [105.1245582, 20.5585456],
                [105.1245168, 20.5585087],
                [105.1244187, 20.5584546],
                [105.1243078, 20.5584305],
                [105.1242507, 20.5584305],
                [105.1241943, 20.5584386],
                [105.1240885, 20.5584782],
                [105.1240003, 20.5585456],
                [105.1239682, 20.5585839],
                [105.1239224, 20.558671],
                [105.1239095, 20.5587182],
                [105.1238716, 20.5590428],
                [105.1238072, 20.5594446],
                [105.1234317, 20.5605245],
                [105.1220387, 20.5613153],
                [105.1216507, 20.5615692],
                [105.1214782, 20.5616948],
                [105.1210227, 20.5620532],
                [105.1208929, 20.562138],
                [105.1207578, 20.562215],
                [105.1206177, 20.5622838],
                [105.1204734, 20.5623443],
                [105.1203252, 20.5623962],
                [105.1201738, 20.5624393],
                [105.1198872, 20.5624952],
                [105.1196189, 20.5625187],
                [105.1193494, 20.5625153],
                [105.1192152, 20.5625035],
                [105.1184399, 20.5623689],
                [105.1181724, 20.5623226],
                [105.1176334, 20.5622539],
                [105.1170909, 20.5622172],
                [105.1168169, 20.5622109],
                [105.116269, 20.5622228],
                [105.1159956, 20.562241],
                [105.1151217, 20.5623239],
                [105.1148225, 20.5623642],
                [105.1142286, 20.5624686],
                [105.1139342, 20.5625327],
                [105.1133519, 20.5626843],
                [105.1131546, 20.5627434],
                [105.1127694, 20.5628863],
                [105.1125824, 20.5629699],
                [105.1122215, 20.5631604],
                [105.1118813, 20.5633801],
                [105.1115642, 20.5636272],
                [105.1112723, 20.5639],
                [105.1109124, 20.5642729],
                [105.1107141, 20.5645206],
                [105.1105438, 20.5647858],
                [105.1104696, 20.5649241],
                [105.1103465, 20.5652043],
                [105.1102538, 20.5654946],
                [105.1101583, 20.5658651],
                [105.1100906, 20.5660859],
                [105.1099348, 20.5665215],
                [105.1097525, 20.5669475],
                [105.1095442, 20.5673629],
                [105.1094305, 20.5675662],
                [105.1060939, 20.5730456],
                [105.105762, 20.5730311],
                [105.1054336, 20.572984],
                [105.1051122, 20.572905],
                [105.1048175, 20.5728015],
                [105.1045352, 20.572671],
                [105.1042682, 20.572515],
                [105.1012015, 20.5708208],
                [105.0994849, 20.5703386],
                [105.0962019, 20.5661499],
                [105.0946891, 20.5671443],
                [105.0943848, 20.5673675],
                [105.0940866, 20.5675976],
                [105.0937946, 20.5678347],
                [105.0935089, 20.5680785],
                [105.0930657, 20.5684822],
                [105.091665, 20.569871],
                [105.0906054, 20.5708823],
                [105.0900344, 20.5714102],
                [105.0888741, 20.572448],
                [105.0883605, 20.5729909],
                [105.0878227, 20.5735127],
                [105.0872878, 20.57399],
                [105.0867341, 20.5744586],
                [105.0864622, 20.5747031],
                [105.0861973, 20.5749541],
                [105.0859339, 20.5752172],
                [105.0856781, 20.5754868],
                [105.0853026, 20.5759124],
                [105.0850258, 20.5761943],
                [105.084722, 20.5764507],
                [105.0843947, 20.576679],
                [105.0840461, 20.5768776],
                [105.0818306, 20.5779373],
                [105.0815721, 20.5780679],
                [105.0813001, 20.5781718],
                [105.081019, 20.5782478],
                [105.080732, 20.5782952],
                [105.0804412, 20.5783139],
                [105.0792181, 20.5781934],
                [105.0776827, 20.578138],
                [105.0773498, 20.5780978],
                [105.077185, 20.5780679],
                [105.0769946, 20.5780247],
                [105.0768067, 20.5779727],
                [105.0766218, 20.5779122],
                [105.0746584, 20.5768274],
                [105.0725341, 20.5756823],
                [105.0720358, 20.5754231],
                [105.0715256, 20.5751851],
                [105.0709492, 20.5749474],
                [105.0706565, 20.5748386],
                [105.0684035, 20.5741053],
                [105.0681555, 20.5740179],
                [105.0680282, 20.5739832],
                [105.0677504, 20.5739298],
                [105.0674495, 20.5739046],
                [105.0666333, 20.5739184],
                [105.0663006, 20.5739145],
                [105.0657567, 20.5738925],
                [105.0654852, 20.5738743],
                [105.0621056, 20.573583],
                [105.061866, 20.5735799],
                [105.0613868, 20.5735931],
                [105.0610961, 20.5736137],
                [105.0608063, 20.5736438],
                [105.0605178, 20.5736835],
                [105.0603915, 20.5733908],
                [105.0603163, 20.5732494],
                [105.0601432, 20.5729786],
                [105.0600457, 20.5728498],
                [105.0598213, 20.5725984],
                [105.0596987, 20.5724816],
                [105.0594342, 20.5722672],
                [105.0565374, 20.5707454],
                [105.0563527, 20.5706192],
                [105.0562538, 20.5705653],
                [105.0560452, 20.5704776],
                [105.0559366, 20.5704441],
                [105.0557427, 20.5704028],
                [105.055472, 20.5703762],
                [105.0554002, 20.5703613],
                [105.055264, 20.5703102],
                [105.0552011, 20.5702747],
                [105.0551426, 20.5702331],
                [105.0550458, 20.5701385],
                [105.0549717, 20.5700273],
                [105.0549442, 20.5699669],
                [105.0549388, 20.5696606],
                [105.0549989, 20.5690718],
                [105.0550353, 20.5684139],
                [105.0550416, 20.5680509],
                [105.055038, 20.5676879],
                [105.0550246, 20.5673251],
                [105.0549402, 20.5666333],
                [105.0548851, 20.5659389],
                [105.0548594, 20.565236],
                [105.0548637, 20.5645326],
                [105.054692, 20.5627546],
                [105.0546635, 20.562615],
                [105.0546383, 20.5625478],
                [105.0545695, 20.5624252],
                [105.0544796, 20.5623199],
                [105.0544271, 20.5622737],
                [105.0543702, 20.5622322],
                [105.0541484, 20.5621078],
                [105.0540448, 20.5620352],
                [105.0538549, 20.561871],
                [105.0537693, 20.5617802],
                [105.0536329, 20.5616038],
                [105.0535208, 20.561413],
                [105.0534381, 20.5612456],
                [105.0533938, 20.5611825],
                [105.0532921, 20.5610798],
                [105.0532329, 20.5610368],
                [105.05264, 20.5609452],
                [105.0523456, 20.560889],
                [105.0517476, 20.5607523],
                [105.051144, 20.5605828],
                [105.0506902, 20.5604442],
                [105.0497782, 20.5603136],
                [105.0481474, 20.5597611],
                [105.047957, 20.5596622],
                [105.0477826, 20.5595401],
                [105.0476986, 20.5594674],
                [105.047548, 20.5593063],
                [105.0474822, 20.5592186],
                [105.0467527, 20.5579629],
                [105.0459695, 20.5569483],
                [105.0449288, 20.557541],
                [105.0447196, 20.5577386],
                [105.0446238, 20.557845],
                [105.0444516, 20.5580717],
                [105.0443065, 20.5583145],
                [105.0441895, 20.558573],
                [105.0441424, 20.5587064],
                [105.0440721, 20.5589794],
                [105.044049, 20.5591182],
                [105.044049, 20.5598816],
                [105.0437449, 20.5599556],
                [105.0431424, 20.5601227],
                [105.0428232, 20.5602227],
                [105.0420848, 20.5604767],
                [105.0418619, 20.5605173],
                [105.041635, 20.5605245],
                [105.0414172, 20.5604997],
                [105.0412059, 20.5604442],
                [105.0406694, 20.55953],
                [105.0405585, 20.559409],
                [105.040494, 20.5593568],
                [105.0403503, 20.5592712],
                [105.0402725, 20.5592387],
                [105.0401129, 20.5591966],
                [105.0400306, 20.5591865],
                [105.0398648, 20.5591885],
                [105.0391459, 20.5594195],
                [105.0385451, 20.55953],
                [105.0378349, 20.560063],
                [105.0376661, 20.5601771],
                [105.037492, 20.5602838],
                [105.0371289, 20.5604743],
                [105.0369346, 20.5605603],
                [105.0367361, 20.5606376],
                [105.0365339, 20.5607061],
                [105.0363286, 20.5607655],
                [105.0361204, 20.5608158],
                [105.0360165, 20.5608185],
                [105.0358115, 20.5608503],
                [105.0356161, 20.5609163],
                [105.0354516, 20.5610046],
                [105.035305, 20.5611172],
                [105.0345433, 20.561961],
                [105.0280484, 20.5644344],
                [105.0276845, 20.5645974],
                [105.0271666, 20.5648724],
                [105.0270777, 20.5649064],
                [105.0268922, 20.5649527],
                [105.0266998, 20.5649688],
                [105.0266025, 20.5649651],
                [105.0264108, 20.5649344],
                [105.0255203, 20.5646431],
                [105.0253704, 20.564585],
                [105.0252247, 20.5645183],
                [105.0250837, 20.5644432],
                [105.0249479, 20.5643601],
                [105.0248179, 20.5642692],
                [105.0246942, 20.564171],
                [105.0244857, 20.5639733],
                [105.0243025, 20.5637547],
                [105.024147, 20.563518],
                [105.0231385, 20.5627044],
                [105.0230581, 20.5622724],
                [105.0230306, 20.5621793],
                [105.0229508, 20.5620012],
                [105.0228966, 20.5619142],
                [105.0227647, 20.5617553],
                [105.0226879, 20.5616848],
                [105.0209606, 20.5606953],
                [105.0208572, 20.5608504],
                [105.020629, 20.561147],
                [105.0203897, 20.5614076],
                [105.0201454, 20.5616338],
                [105.0200164, 20.56174],
                [105.0174898, 20.5636737],
                [105.0167169, 20.5642211],
                [105.0159244, 20.5647432],
                [105.0151371, 20.5652255],
                [105.0143575, 20.5656694],
                [105.0131756, 20.5662975],
                [105.0124097, 20.5667274],
                [105.0112154, 20.5674447],
                [105.0109446, 20.5676336],
                [105.0108172, 20.5677375],
                [105.0105804, 20.567963],
                [105.0104692, 20.5680866],
                [105.0102625, 20.5683455],
                [105.0100731, 20.568626],
                [105.009904, 20.5689276],
                [105.0097806, 20.569183],
                [105.0097253, 20.56928],
                [105.0095959, 20.5694635],
                [105.0094432, 20.5696304],
                [105.0093543, 20.5697107],
                [105.0091608, 20.5698542],
                [105.0090569, 20.5699167],
                [105.007453, 20.5707354],
                [105.0071677, 20.5708651],
                [105.0068951, 20.5710166],
                [105.0067599, 20.5711029],
                [105.0065022, 20.5712914],
                [105.0060386, 20.5716936],
                [105.0056771, 20.5719701],
                [105.0054896, 20.5720989],
                [105.005254, 20.5722467],
                [105.0047686, 20.5725216],
                [105.0042661, 20.5727678],
                [105.0038305, 20.5729525],
                [105.0029252, 20.5732868],
                [105.0021905, 20.5735278],
                [105.0018409, 20.5736329],
                [105.0009995, 20.5738658],
                [105.0007202, 20.5739236],
                [105.0004211, 20.5739544],
                [105.0001054, 20.5739531],
                [104.9998306, 20.5739315],
                [104.9997127, 20.5739315],
                [104.9994785, 20.5739557],
                [104.9992395, 20.5740152],
                [104.9991189, 20.5740599],
                [104.9990027, 20.5741135],
                [104.9988913, 20.5741756],
                [104.9984759, 20.5745511],
                [104.998305, 20.5746717],
                [104.9982127, 20.5747225],
                [104.998017, 20.5748034],
                [104.9978064, 20.5748561],
                [104.9976984, 20.5748708],
                [104.9974805, 20.5748762],
                [104.9969196, 20.5748415],
                [104.9958417, 20.5747511],
                [104.994809, 20.5746352],
                [104.994453, 20.574609],
                [104.9940963, 20.5745923],
                [104.9937392, 20.5745851],
                [104.9933821, 20.5745875],
                [104.9930345, 20.5745989],
                [104.9926873, 20.5746193],
                [104.9919955, 20.5746872],
                [104.9916514, 20.5747346],
                [104.9913087, 20.5747909],
                [104.9911949, 20.5748024],
                [104.9909705, 20.5748452],
                [104.9907619, 20.5749104],
                [104.9905711, 20.5749943],
                [104.9901232, 20.5752604],
                [104.989943, 20.5753403],
                [104.9897508, 20.5753902],
                [104.989652, 20.5754035],
                [104.9894527, 20.5754061],
                [104.9892478, 20.575375],
                [104.9891484, 20.5753467],
                [104.9889596, 20.575266],
                [104.9887891, 20.5751553],
                [104.988405, 20.5748287],
                [104.9878085, 20.5742886],
                [104.9872551, 20.5737435],
                [104.9867264, 20.5731773],
                [104.9861124, 20.5724701],
                [104.9859952, 20.5723545],
                [104.9857514, 20.5721471],
                [104.9854963, 20.5719686],
                [104.9852701, 20.5718276],
                [104.9850728, 20.5717269],
                [104.9848617, 20.5716546],
                [104.9847524, 20.5716297],
                [104.9845295, 20.571603],
                [104.984413, 20.5716014],
                [104.9841813, 20.5716233],
                [104.9840675, 20.5716467],
                [104.9838473, 20.5717177],
                [104.9837422, 20.5717649],
                [104.9834846, 20.5719051],
                [104.9833496, 20.5719644],
                [104.9832112, 20.5720161],
                [104.9829551, 20.5720894],
                [104.9827023, 20.572172],
                [104.982453, 20.5722635],
                [104.9822077, 20.5723639],
                [104.9817983, 20.5725558],
                [104.9816346, 20.5726463],
                [104.9813229, 20.5728498],
                [104.9811734, 20.5729639],
                [104.9810304, 20.5730851],
                [104.9806821, 20.5734238],
                [104.9804978, 20.5735573],
                [104.9803149, 20.5736518],
                [104.9798879, 20.5738166],
                [104.979435, 20.5739588],
                [104.9792047, 20.5740181],
                [104.9787379, 20.5741129],
                [104.9787763, 20.5742317],
                [104.9788796, 20.5744602],
                [104.9789438, 20.5745688],
                [104.9790908, 20.5747657],
                [104.979259, 20.5749364],
                [104.9793518, 20.5750136],
                [104.9795526, 20.57515],
                [104.9799603, 20.5754965],
                [104.9800357, 20.5755881],
                [104.9800891, 20.5756924],
                [104.9801069, 20.5757486],
                [104.9801183, 20.5758063],
                [104.9801254, 20.5760147],
                [104.980138, 20.5761053],
                [104.9801852, 20.576275],
                [104.9802578, 20.5764286],
                [104.9803455, 20.576577],
                [104.9803787, 20.5766566],
                [104.9803993, 20.576724],
                [104.980443, 20.5769529],
                [104.9804727, 20.5770412],
                [104.9805103, 20.5771268],
                [104.9806047, 20.5772819],
                [104.9807204, 20.5774154],
                [104.981355, 20.5779578],
                [104.9813978, 20.5780034],
                [104.9814654, 20.5781068],
                [104.9815339, 20.5783001],
                [104.9815664, 20.578365],
                [104.9816052, 20.5784267],
                [104.9817, 20.5785378],
                [104.9818144, 20.57863],
                [104.9819874, 20.578723],
                [104.982057, 20.5787857],
                [104.9820825, 20.5788243],
                [104.9821111, 20.57891],
                [104.9821084, 20.5789991],
                [104.9820849, 20.5791149],
                [104.9820894, 20.5792615],
                [104.9821275, 20.5794037],
                [104.9821911, 20.5795255],
                [104.9822322, 20.5795813],
                [104.9823314, 20.5796799],
                [104.9824737, 20.5797664],
                [104.9825967, 20.5798756],
                [104.9826964, 20.5800041],
                [104.9827624, 20.5801301],
                [104.982833, 20.5802324],
                [104.9829213, 20.5803147],
                [104.9830766, 20.5804077],
                [104.9831223, 20.5804436],
                [104.9831622, 20.580485],
                [104.9832219, 20.5805814],
                [104.9832387, 20.5806281],
                [104.9832536, 20.5807255],
                [104.983179, 20.5816863],
                [104.9831825, 20.5817836],
                [104.9831959, 20.5818801],
                [104.9832192, 20.581975],
                [104.9833225, 20.5822295],
                [104.9833333, 20.5822901],
                [104.9833306, 20.5824541],
                [104.9833614, 20.5825526],
                [104.9834231, 20.5826364],
                [104.9834638, 20.5826704],
                [104.9835598, 20.5827183],
                [104.9836998, 20.5828489],
                [104.9838079, 20.5829867],
                [104.9838871, 20.5831251],
                [104.9839911, 20.583238],
                [104.9841177, 20.5833284],
                [104.9841811, 20.583361],
                [104.9843657, 20.583429],
                [104.9844525, 20.5834887],
                [104.9844879, 20.5835268],
                [104.9845791, 20.5836649],
                [104.9846341, 20.5837327],
                [104.9847577, 20.5838572],
                [104.9848832, 20.5839589],
                [104.9849224, 20.584019],
                [104.9849361, 20.5840631],
                [104.9849398, 20.584109],
                [104.9849237, 20.5842045],
                [104.9849237, 20.5842553],
                [104.9849517, 20.5843529],
                [104.9849788, 20.5843969],
                [104.9851423, 20.5845864],
                [104.985194, 20.5846917],
                [104.9852238, 20.5847984],
                [104.9852335, 20.5849028],
                [104.9851728, 20.5855156],
                [104.9851446, 20.5860091],
                [104.9851418, 20.5865574],
                [104.9851732, 20.5871589],
                [104.9854756, 20.5874505],
                [104.9855126, 20.5875078],
                [104.9855203, 20.5875743],
                [104.9854969, 20.5876382],
                [104.985446, 20.5876871],
                [104.9854133, 20.5877025],
                [104.9851381, 20.5877778],
                [104.9850537, 20.5878088],
                [104.9849103, 20.5878867],
                [104.9848512, 20.5879304],
                [104.9847642, 20.5880099],
                [104.9847158, 20.5880836],
                [104.9846917, 20.588167],
                [104.9846897, 20.5882152],
                [104.9847101, 20.5883093],
                [104.984732, 20.5883528],
                [104.9848748, 20.5885209],
                [104.9848975, 20.5885986],
                [104.9848956, 20.5886391],
                [104.9848704, 20.5887072],
                [104.9848205, 20.5887621],
                [104.9847526, 20.588807],
                [104.9847215, 20.5888367],
                [104.9846956, 20.5888706],
                [104.9846627, 20.5889448],
                [104.9846546, 20.5890222],
                [104.9847005, 20.5893469],
                [104.9846856, 20.5894561],
                [104.9846649, 20.5895078],
                [104.9846297, 20.5895646],
                [104.9845378, 20.5896633],
                [104.9844178, 20.5897392],
                [104.9843485, 20.5897662],
                [104.984276, 20.589784],
                [104.9842325, 20.5898068],
                [104.984194, 20.5898364],
                [104.9841365, 20.5899121],
                [104.9841208, 20.5899512],
                [104.9841099, 20.5900338],
                [104.984159, 20.5902928],
                [104.9841611, 20.5903328],
                [104.9841446, 20.5904112],
                [104.9840985, 20.5904849],
                [104.9840636, 20.5905167],
                [104.9840231, 20.590542],
                [104.9839235, 20.590582],
                [104.9838237, 20.5906426],
                [104.983759, 20.5907016],
                [104.983734, 20.5907538],
                [104.9837342, 20.590811],
                [104.9837595, 20.5908628],
                [104.9837802, 20.5908838],
                [104.9838334, 20.5909114],
                [104.9838704, 20.5909626],
                [104.9838879, 20.5910242],
                [104.9838817, 20.5910897],
                [104.9837898, 20.5913353],
                [104.9837647, 20.5914226],
                [104.9837202, 20.5917007],
                [104.9836575, 20.5918823],
                [104.9835604, 20.5920522],
                [104.9834311, 20.5922045],
                [104.9833882, 20.5922662],
                [104.9833238, 20.5924004],
                [104.9832932, 20.5925199],
                [104.9832702, 20.5928523],
                [104.9832571, 20.5929378],
                [104.9832058, 20.5931034],
                [104.9831167, 20.5932707],
                [104.9830628, 20.5933494],
                [104.9829376, 20.5934951],
                [104.9827813, 20.59363],
                [104.982605, 20.5937411],
                [104.982326, 20.5942433],
                [104.9816206, 20.594332],
                [104.9809113, 20.5943882],
                [104.9801936, 20.5944117],
                [104.9798313, 20.5944109],
                [104.9791074, 20.5943839],
                [104.9784411, 20.5944216],
                [104.977777, 20.5944843],
                [104.9774184, 20.5945287],
                [104.977061, 20.5945803],
                [104.9763501, 20.5947053],
                [104.974761, 20.5958584],
                [104.9739176, 20.5965601],
                [104.9713826, 20.5989861],
                [104.9695802, 20.6017655],
                [104.9692939, 20.6022438],
                [104.9690344, 20.6027423],
                [104.9688036, 20.6032597],
                [104.9686533, 20.6036527],
                [104.9686157, 20.6037851],
                [104.9685721, 20.6040312],
                [104.9685609, 20.6042561],
                [104.9686131, 20.605199],
                [104.9685985, 20.6053557],
                [104.9685815, 20.6054359],
                [104.9685287, 20.6055917],
                [104.9684522, 20.6057221],
                [104.968282, 20.6059733],
                [104.9681856, 20.6060975],
                [104.9679762, 20.6063337],
                [104.9678635, 20.6064453],
                [104.9668336, 20.6072788],
                [104.9667263, 20.6075701],
                [104.9666732, 20.607685],
                [104.9666108, 20.6077959],
                [104.9665396, 20.6079019],
                [104.9664599, 20.6080026],
                [104.9663722, 20.6080973],
                [104.966217, 20.6082347],
                [104.966045, 20.6083534],
                [104.9658741, 20.6084455],
                [104.965691, 20.608514],
                [104.9656003, 20.6085379],
                [104.9654148, 20.6085681],
                [104.9653208, 20.6085743],
                [104.9642587, 20.608514],
                [104.9637009, 20.6084966],
                [104.9631429, 20.6084889],
                [104.9628899, 20.6083187],
                [104.9626172, 20.6081776],
                [104.9623657, 20.6080798],
                [104.9620125, 20.6079808],
                [104.9619224, 20.6079484],
                [104.9617508, 20.6078659],
                [104.9615795, 20.6077496],
                [104.9614952, 20.607676],
                [104.961418, 20.6075959],
                [104.9613485, 20.6075098],
                [104.9596453, 20.6094203],
                [104.959602, 20.6095318],
                [104.9595359, 20.6097608],
                [104.9594977, 20.6099953],
                [104.9594879, 20.6102289],
                [104.9595058, 20.6104622],
                [104.9596882, 20.6120238],
                [104.9589586, 20.6130832],
                [104.9579501, 20.6148606],
                [104.9568343, 20.6164773],
                [104.9581754, 20.6177727],
                [104.958524, 20.618157],
                [104.9589131, 20.6186376],
                [104.9591278, 20.6189255],
                [104.9595089, 20.6194811],
                [104.9599271, 20.6201608],
                [104.9600096, 20.6203077],
                [104.9601558, 20.6206101],
                [104.9602722, 20.6209108],
                [104.9603608, 20.6212073],
                [104.9603963, 20.6213576],
                [104.9604197, 20.6215557],
                [104.9604821, 20.62195],
                [104.9605258, 20.6221688],
                [104.9606323, 20.6226027],
                [104.9606323, 20.6250427],
                [104.960289, 20.6257858],
                [104.9600851, 20.6273522],
                [104.9600205, 20.6277466],
                [104.9599242, 20.6281354],
                [104.9598695, 20.6283116],
                [104.9597407, 20.6286582],
                [104.9596056, 20.6289734],
                [104.959535, 20.6291148],
                [104.9594553, 20.6292518],
                [104.9593668, 20.6293841],
                [104.9592698, 20.629511],
                [104.9591654, 20.6296311],
                [104.9590535, 20.629745],
                [104.9589343, 20.6298524],
                [104.9588084, 20.6299528],
                [104.956212, 20.6314388],
                [104.9559374, 20.6316422],
                [104.9556434, 20.6318204],
                [104.9553253, 20.6319749],
                [104.9550326, 20.6320939],
                [104.954909, 20.6321552],
                [104.9547905, 20.6322248],
                [104.9546778, 20.6323023],
                [104.9545735, 20.6323855],
                [104.9544758, 20.6324755],
                [104.9543853, 20.6325717],
                [104.9543023, 20.6326738],
                [104.9536471, 20.6337358],
                [104.9535459, 20.6338737],
                [104.9534859, 20.6339411],
                [104.9533528, 20.6340644],
                [104.9525052, 20.6346418],
                [104.9522209, 20.635445],
                [104.9512017, 20.6364189],
                [104.9511505, 20.6366288],
                [104.9510896, 20.6368365],
                [104.9510193, 20.6370414],
                [104.9508863, 20.6373649],
                [104.9507296, 20.637679],
                [104.9502818, 20.638054],
                [104.9498275, 20.6384221],
                [104.949367, 20.6387834],
                [104.9488927, 20.6391435],
                [104.9484122, 20.6394963],
                [104.9483961, 20.6402493],
                [104.9483626, 20.6403841],
                [104.9482739, 20.640648],
                [104.9481547, 20.6409071],
                [104.9480831, 20.6410343],
                [104.9480045, 20.6411579],
                [104.9472235, 20.6417624],
                [104.9470074, 20.6419627],
                [104.9468136, 20.642182],
                [104.9466268, 20.6424452],
                [104.9458587, 20.6437884],
                [104.9439812, 20.6438085],
                [104.9421251, 20.643678],
                [104.9416175, 20.6439245],
                [104.9411273, 20.6442001],
                [104.9407394, 20.644447],
                [104.9402359, 20.6448],
                [104.9399646, 20.6449587],
                [104.9398234, 20.6450292],
                [104.9395693, 20.6451376],
                [104.9392345, 20.6452555],
                [104.9390083, 20.6453261],
                [104.9387797, 20.6453896],
                [104.9383163, 20.6454952],
                [104.9380571, 20.6455412],
                [104.9377962, 20.6455784],
                [104.9375341, 20.6456065],
                [104.9372711, 20.6456257],
                [104.9370074, 20.6456358],
                [104.9354303, 20.6456157],
                [104.934889, 20.6458389],
                [104.9346228, 20.6459595],
                [104.9340999, 20.6462181],
                [104.9335771, 20.6465075],
                [104.9330699, 20.6468205],
                [104.9325721, 20.6470224],
                [104.9320785, 20.6472333],
                [104.9315894, 20.647453],
                [104.9311433, 20.6476629],
                [104.9307012, 20.6478801],
                [104.9298298, 20.6483365],
                [104.9276304, 20.6493806],
                [104.9275432, 20.6498623],
                [104.9274292, 20.6503391],
                [104.9272931, 20.6507963],
                [104.9271374, 20.6512331],
                [104.9270047, 20.6515781],
                [104.9268861, 20.6518272],
                [104.9267345, 20.6520602],
                [104.9265525, 20.6522731],
                [104.926451, 20.652371],
                [104.9262376, 20.6525416],
                [104.9260128, 20.6526814],
                [104.925772, 20.6527953],
                [104.9255185, 20.6528818],
                [104.9239535, 20.653317],
                [104.9234747, 20.6534828],
                [104.9230093, 20.6536792],
                [104.9225595, 20.6539051],
                [104.9221367, 20.6541536],
                [104.9217418, 20.6544218],
                [104.9213666, 20.6547137],
                [104.9211869, 20.6548682],
                [104.9208444, 20.6551934],
                [104.9207596, 20.6554586],
                [104.9205655, 20.6559814],
                [104.9203949, 20.6563748],
                [104.9202367, 20.6567114],
                [104.9201633, 20.6568508],
                [104.9199949, 20.6571186],
                [104.919803, 20.657365],
                [104.9195909, 20.6575886],
                [104.9193766, 20.6577857],
                [104.9192817, 20.6578828],
                [104.9191922, 20.6579842],
                [104.9190328, 20.6581949],
                [104.9189615, 20.6583055],
                [104.918586, 20.6590584],
                [104.9168265, 20.6596206],
                [104.9151528, 20.6604237],
                [104.9132591, 20.6611565],
                [104.913019, 20.661272],
                [104.912552, 20.6615256],
                [104.9123257, 20.6616635],
                [104.9119528, 20.6619145],
                [104.9115962, 20.6621855],
                [104.9106252, 20.6629836],
                [104.9093707, 20.663406],
                [104.9087484, 20.6636295],
                [104.907514, 20.664101],
                [104.9063374, 20.6645863],
                [104.9056071, 20.6649034],
                [104.9052804, 20.6650685],
                [104.9051229, 20.6651605],
                [104.9048209, 20.6653627],
                [104.904661, 20.6654854],
                [104.9045074, 20.6656149],
                [104.9043604, 20.6657511],
                [104.9042204, 20.6658935],
                [104.9040877, 20.6660419],
                [104.9039626, 20.6661959],
                [104.9012804, 20.6679225],
                [104.8997033, 20.6690368],
                [104.8970425, 20.6690769],
                [104.89662, 20.6690928],
                [104.8957765, 20.6691472],
                [104.8948742, 20.669239],
                [104.8944247, 20.6692978],
                [104.8938095, 20.6694076],
                [104.8932016, 20.6695487],
                [104.8928894, 20.6696342],
                [104.8922735, 20.6698298],
                [104.8921114, 20.6698748],
                [104.8917811, 20.6699427],
                [104.8914479, 20.6699802],
                [104.8912815, 20.6699877],
                [104.8909485, 20.6699804],
                [104.8894089, 20.66987],
                [104.8873115, 20.6699503],
                [104.8872124, 20.6699365],
                [104.8870199, 20.6698855],
                [104.8868394, 20.6698047],
                [104.8867497, 20.6697501],
                [104.8866657, 20.669688],
                [104.8865881, 20.669619],
                [104.8865175, 20.6695437],
                [104.8860991, 20.668821],
                [104.885112, 20.6674909],
                [104.8849185, 20.6672169],
                [104.8846966, 20.6669624],
                [104.8844892, 20.6667653],
                [104.8843333, 20.6665943],
                [104.8841951, 20.6663942],
                [104.8841342, 20.6662819],
                [104.8840821, 20.6661658],
                [104.8836596, 20.6661231],
                [104.883242, 20.6660489],
                [104.8828446, 20.6659474],
                [104.8824688, 20.6658218],
                [104.882285, 20.6657492],
                [104.8811424, 20.6645396],
                [104.8787659, 20.66334],
                [104.8785828, 20.6632542],
                [104.8783954, 20.6631769],
                [104.8782042, 20.6631084],
                [104.8780096, 20.6630488],
                [104.877174, 20.6628494],
                [104.8767596, 20.6627376],
                [104.8760131, 20.6625142],
                [104.8752761, 20.6622648],
                [104.8739556, 20.6617616],
                [104.8736533, 20.6616605],
                [104.8730811, 20.6614953],
                [104.8725418, 20.6613698],
                [104.8722696, 20.6613172],
                [104.8698449, 20.6609558],
                [104.8691353, 20.660857],
                [104.8684772, 20.6607345],
                [104.8678708, 20.6605944],
                [104.8671921, 20.6604611],
                [104.8665082, 20.6603534],
                [104.8657809, 20.6602675],
                [104.8650504, 20.6602105],
                [104.8646843, 20.6601928],
                [104.8630535, 20.6602832],
                [104.8624601, 20.6603047],
                [104.8618765, 20.6602941],
                [104.8613047, 20.660253],
                [104.860987, 20.6602495],
                [104.8608285, 20.6602604],
                [104.8605148, 20.6603074],
                [104.8603606, 20.6603434],
                [104.8598501, 20.6604939],
                [104.8593306, 20.6606144],
                [104.8587967, 20.6607056],
                [104.8582578, 20.660765],
                [104.8577471, 20.6608812],
                [104.8572456, 20.6610287],
                [104.8567557, 20.6612067],
                [104.8563165, 20.6613972],
                [104.8558904, 20.6616122],
                [104.8552243, 20.6619883],
                [104.8547494, 20.6622206],
                [104.8544817, 20.6623366],
                [104.8542098, 20.662444],
                [104.8539342, 20.6625426],
                [104.8536551, 20.6626322],
                [104.8532367, 20.6620901],
                [104.8526895, 20.6605141],
                [104.8524402, 20.6602333],
                [104.8523241, 20.6600865],
                [104.8521101, 20.6597812],
                [104.8519261, 20.6594709],
                [104.8516647, 20.6589514],
                [104.8515562, 20.6587571],
                [104.8513198, 20.6583785],
                [104.8511923, 20.6581945],
                [104.8508959, 20.6578099],
                [104.8507252, 20.657611],
                [104.850547, 20.6574181],
                [104.8503613, 20.6572313],
                [104.8503609, 20.6569587],
                [104.8503502, 20.6566862],
                [104.8503293, 20.6564143],
                [104.8502983, 20.6561432],
                [104.8502571, 20.6558734],
                [104.8501818, 20.6554954],
                [104.8501116, 20.6552815],
                [104.8500656, 20.6551782],
                [104.8499484, 20.6549743],
                [104.8498767, 20.654875],
                [104.8497122, 20.6546914],
                [104.8486796, 20.6538456],
                [104.8483658, 20.6528342],
                [104.8477542, 20.651509],
                [104.8472399, 20.6512743],
                [104.8447394, 20.6501335],
                [104.843452, 20.6492701],
                [104.8416173, 20.6488987],
                [104.8381305, 20.6476036],
                [104.837449, 20.6472029],
                [104.8371141, 20.646994],
                [104.8364568, 20.6465594],
                [104.8357491, 20.6460528],
                [104.8354034, 20.6457896],
                [104.8347294, 20.6452442],
                [104.832927, 20.6439792],
                [104.8325088, 20.6437434],
                [104.8320727, 20.643538],
                [104.8316627, 20.6433786],
                [104.8312844, 20.6432581],
                [104.8310924, 20.6432061],
                [104.8285032, 20.6432257],
                [104.8283714, 20.643216],
                [104.8282407, 20.643197],
                [104.8281119, 20.6431688],
                [104.8279858, 20.6431316],
                [104.827863, 20.6430856],
                [104.8277423, 20.6430302],
                [104.8276265, 20.6429663],
                [104.8275163, 20.6428943],
                [104.8274124, 20.6428146],
                [104.8269732, 20.6423557],
                [104.8267472, 20.6421318],
                [104.8262793, 20.6416928],
                [104.8260391, 20.6414792],
                [104.8256793, 20.6412316],
                [104.8254919, 20.6411178],
                [104.8251448, 20.6409313],
                [104.8249662, 20.6408467],
                [104.8243217, 20.6406014],
                [104.8236737, 20.6403646],
                [104.8230221, 20.6401363],
                [104.8223671, 20.6399167],
                [104.8211292, 20.639528],
                [104.8198807, 20.63917],
                [104.8194063, 20.6390484],
                [104.8184538, 20.6388186],
                [104.8175955, 20.6386278],
                [104.8173081, 20.6385148],
                [104.8171696, 20.6384477],
                [104.8169053, 20.6382931],
                [104.8167801, 20.6382061],
                [104.8165563, 20.6380237],
                [104.8164522, 20.6379247],
                [104.8162608, 20.6377126],
                [104.816174, 20.6375999],
                [104.8159557, 20.6372853],
                [104.815825, 20.6370832],
                [104.815585, 20.6366676],
                [104.8153746, 20.6362382],
                [104.8152758, 20.6360066],
                [104.815104, 20.6355344],
                [104.8150313, 20.6352944],
                [104.8143446, 20.6338787],
                [104.8141448, 20.6333509],
                [104.8140221, 20.6329644],
                [104.8139781, 20.6328484],
                [104.8138655, 20.6326261],
                [104.8137339, 20.6324348],
                [104.8135915, 20.6322739],
                [104.8135132, 20.6321994],
                [104.8125744, 20.6316697],
                [104.812437, 20.6316096],
                [104.8122901, 20.6315744],
                [104.8121229, 20.6315658],
                [104.8119575, 20.6315894],
                [104.8114639, 20.6318153],
                [104.811354, 20.6318851],
                [104.8111472, 20.632041],
                [104.8109492, 20.6322282],
                [104.8107666, 20.6324479],
                [104.8105962, 20.6327188],
                [104.8104357, 20.6329317],
                [104.8102507, 20.6331263],
                [104.8100956, 20.6332601],
                [104.8100358, 20.6332973],
                [104.8099035, 20.6333484],
                [104.8097723, 20.633366],
                [104.8096509, 20.6333559],
                [104.8095918, 20.6333415],
                [104.8095114, 20.6333022],
                [104.8093657, 20.6332012],
                [104.8092499, 20.63308],
                [104.8091651, 20.6329459],
                [104.809109, 20.6327993],
                [104.8089842, 20.6321466],
                [104.8089387, 20.6320122],
                [104.8088622, 20.6318906],
                [104.8088159, 20.6318392],
                [104.8086595, 20.6317006],
                [104.808549, 20.6316149],
                [104.8083119, 20.6314636],
                [104.8080476, 20.6313375],
                [104.8079048, 20.6312852],
                [104.8077586, 20.6312419],
                [104.8074819, 20.6311747],
                [104.8073629, 20.63112],
                [104.8072411, 20.6310321],
                [104.8071885, 20.6309794],
                [104.8071144, 20.6308732],
                [104.8069815, 20.6306521],
                [104.8068723, 20.6304265],
                [104.8067651, 20.6301446],
                [104.8067479, 20.6300306],
                [104.8067651, 20.6299166],
                [104.8068172, 20.6298084],
                [104.8068565, 20.6297593],
                [104.806903, 20.6297162],
                [104.8069559, 20.62968],
                [104.807014, 20.6296516],
                [104.8070759, 20.6296315],
                [104.8076981, 20.6294909],
                [104.8078497, 20.6294371],
                [104.8079197, 20.6293988],
                [104.8080442, 20.6293018],
                [104.8081429, 20.6291814],
                [104.8082077, 20.6290524],
                [104.8082413, 20.6289226],
                [104.8082473, 20.6287892],
                [104.8082227, 20.6286041],
                [104.80812, 20.6281381],
                [104.8080109, 20.6279216],
                [104.8078677, 20.6277161],
                [104.8077828, 20.6276186],
                [104.807592, 20.6274417],
                [104.8072965, 20.627236],
                [104.8072368, 20.6271614],
                [104.8072043, 20.6270836],
                [104.8071939, 20.6270007],
                [104.8072015, 20.6268405],
                [104.8072392, 20.6265218],
                [104.8073065, 20.6262075],
                [104.8073935, 20.6259262],
                [104.807446, 20.6257883],
                [104.8074681, 20.6256774],
                [104.8074621, 20.6255648],
                [104.8074459, 20.6255016],
                [104.807387, 20.6253841],
                [104.8072437, 20.6253014],
                [104.8071665, 20.6252697],
                [104.8069949, 20.6252253],
                [104.8069016, 20.6252148],
                [104.8055685, 20.6251783],
                [104.8053741, 20.6251463],
                [104.8051888, 20.6250826],
                [104.8050186, 20.6249892],
                [104.8049408, 20.6249323],
                [104.8048082, 20.6248059],
                [104.804751, 20.6247353],
                [104.8046569, 20.6245816],
                [104.8046207, 20.6244996],
                [104.8045921, 20.6244151],
                [104.8045554, 20.6242562],
                [104.8044867, 20.6241069],
                [104.8043884, 20.6239728],
                [104.8042636, 20.6238585],
                [104.8041166, 20.6237686],
                [104.8039538, 20.6237072],
                [104.8034974, 20.6236928],
                [104.8034176, 20.6236609],
                [104.8033569, 20.6236029],
                [104.8033369, 20.6235666],
                [104.8033211, 20.6234959],
                [104.8033323, 20.6234244],
                [104.8033477, 20.6233911],
                [104.803692, 20.6229985],
                [104.8037612, 20.6228682],
                [104.8038127, 20.6226624],
                [104.8038343, 20.6226086],
                [104.8038972, 20.6225103],
                [104.8040272, 20.6223829],
                [104.8040634, 20.6223339],
                [104.8040932, 20.6222814],
                [104.804132, 20.6221686],
                [104.8041415, 20.6220504],
                [104.8041388, 20.6215483],
                [104.8041045, 20.6213854],
                [104.8040366, 20.6212319],
                [104.8039908, 20.6211606],
                [104.8038725, 20.6210277],
                [104.8038002, 20.6209684],
                [104.8037215, 20.6209166],
                [104.8036373, 20.620873],
                [104.8033707, 20.6208308],
                [104.8031008, 20.6208153],
                [104.8029521, 20.6208182],
                [104.8026566, 20.6208484],
                [104.8024214, 20.6208888],
                [104.8022406, 20.6208909],
                [104.8020628, 20.6208605],
                [104.8019759, 20.620833],
                [104.8018129, 20.6207548],
                [104.8015931, 20.6205956],
                [104.8013159, 20.6203629],
                [104.801057, 20.6201124],
                [104.8009277, 20.6199728],
                [104.8006865, 20.6196804],
                [104.8004273, 20.6193142],
                [104.8003569, 20.6191911],
                [104.8003216, 20.6191059],
                [104.8002841, 20.6189719],
                [104.8002422, 20.6188863],
                [104.8001772, 20.6188145],
                [104.8001367, 20.6187851],
                [104.8000446, 20.6187432],
                [104.7999948, 20.6187317],
                [104.7998876, 20.6187262],
                [104.799673, 20.6187292],
                [104.799486, 20.61875],
                [104.7993044, 20.6187968],
                [104.7991224, 20.6188735],
                [104.7990321, 20.6189248],
                [104.7989468, 20.6189829],
                [104.7987932, 20.6191183],
                [104.7985518, 20.6181643],
                [104.7979707, 20.6168117],
                [104.797833, 20.6164171],
                [104.7977444, 20.6160732],
                [104.7977102, 20.6158992],
                [104.7976828, 20.6157242],
                [104.7977049, 20.6156182],
                [104.7977693, 20.6154108],
                [104.7978114, 20.6153102],
                [104.7979077, 20.6151278],
                [104.7980675, 20.6148834],
                [104.7981808, 20.6147246],
                [104.7984258, 20.6144193],
                [104.7987144, 20.6141115],
                [104.7988788, 20.6139562],
                [104.79905, 20.6138075],
                [104.7992277, 20.6136656],
                [104.7994496, 20.613565],
                [104.7995545, 20.6135043],
                [104.7997498, 20.6133635],
                [104.7998392, 20.613284],
                [104.8000053, 20.613102],
                [104.8000775, 20.6130032],
                [104.8001986, 20.6127924],
                [104.8002469, 20.6126815],
                [104.8002992, 20.612537],
                [104.8003417, 20.6123896],
                [104.8003744, 20.6122401],
                [104.8003971, 20.612089],
                [104.8004127, 20.611827],
                [104.8003983, 20.6115649],
                [104.80038, 20.6114348],
                [104.8003542, 20.6113058],
                [104.8003046, 20.6111427],
                [104.8002646, 20.6109773],
                [104.8002345, 20.6108101],
                [104.8002143, 20.6106416],
                [104.8002035, 20.6103143],
                [104.8002284, 20.6099994],
                [104.8002539, 20.6098433],
                [104.8003303, 20.6095357],
                [104.8004401, 20.6092371],
                [104.8006868, 20.6084437],
                [104.8008262, 20.6082335],
                [104.8009572, 20.6080187],
                [104.8010796, 20.6077995],
                [104.8011934, 20.6075762],
                [104.8012984, 20.6073491],
                [104.8014768, 20.6068976],
                [104.8015529, 20.6066676],
                [104.8016202, 20.6064353],
                [104.8016384, 20.6062643],
                [104.8016266, 20.6060929],
                [104.8016095, 20.6060085],
                [104.8015539, 20.6058459],
                [104.8015157, 20.6057683],
                [104.8014198, 20.6056232],
                [104.8012662, 20.6054495],
                [104.8010945, 20.6052201],
                [104.8009594, 20.6049858],
                [104.8008534, 20.6047387],
                [104.7991741, 20.5989434],
                [104.799042, 20.5984366],
                [104.7989623, 20.5981865],
                [104.7987831, 20.5977113],
                [104.7985791, 20.5972642],
                [104.798466, 20.5970453],
                [104.798435, 20.5969513],
                [104.7983909, 20.5967591],
                [104.7983771, 20.596655],
                [104.7983702, 20.5964456],
                [104.7983771, 20.5963409],
                [104.7983909, 20.5962369],
                [104.7987167, 20.5958405],
                [104.7988867, 20.5956477],
                [104.7992819, 20.5952317],
                [104.7997427, 20.5947957],
                [104.8000111, 20.5946829],
                [104.8002899, 20.5945948],
                [104.8005876, 20.5945305],
                [104.8009927, 20.5944808],
                [104.8010884, 20.5944448],
                [104.8011788, 20.5943827],
                [104.8012534, 20.5942934],
                [104.8012799, 20.5942422],
                [104.8018026, 20.5929829],
                [104.8018664, 20.5927725],
                [104.8019208, 20.5925599],
                [104.8019658, 20.5923453],
                [104.8020011, 20.5921292],
                [104.8020378, 20.5917784],
                [104.8020615, 20.5909828],
                [104.8020923, 20.5907507],
                [104.8021549, 20.5905189],
                [104.8021977, 20.5904062],
                [104.8024517, 20.5898092],
                [104.8022483, 20.5890345],
                [104.8022259, 20.5889256],
                [104.8022095, 20.5887046],
                [104.8022278, 20.5885042],
                [104.8022847, 20.5882847],
                [104.8023312, 20.5881824],
                [104.8023668, 20.5881254],
                [104.802408, 20.5880717],
                [104.8025054, 20.5879762],
                [104.8028594, 20.5875694],
                [104.8029163, 20.5874841],
                [104.8029359, 20.5874372],
                [104.802956, 20.5873384],
                [104.802956, 20.587285],
                [104.8029338, 20.5871803],
                [104.8028834, 20.5870846],
                [104.8028487, 20.5870421],
                [104.8026502, 20.5867333],
                [104.8026305, 20.5866754],
                [104.8026205, 20.5865598],
                [104.8026669, 20.5863463],
                [104.8027098, 20.5861889],
                [104.8028324, 20.5858154],
                [104.8029165, 20.585601],
                [104.8030096, 20.58539],
                [104.8033476, 20.5846517],
                [104.802702, 20.5830188],
                [104.8017059, 20.5819449],
                [104.7994943, 20.5812037],
                [104.7967825, 20.580932],
                [104.7933567, 20.5807936],
                [104.7916438, 20.5807915],
                [104.7872181, 20.5811891],
                [104.7837916, 20.5815878],
                [104.7819364, 20.5811825],
                [104.7813664, 20.5805103],
                [104.7805113, 20.5795691],
                [104.7796569, 20.5780907],
                [104.7792303, 20.5770158],
                [104.778805, 20.5750008],
                [104.7780928, 20.5739255],
                [104.7773799, 20.5733874],
                [104.7766666, 20.5731179],
                [104.7738116, 20.5732484],
                [104.772955, 20.5733816],
                [104.7719548, 20.5740518],
                [104.7708113, 20.5751247],
                [104.7698099, 20.5766006],
                [104.7688068, 20.579151],
                [104.767019, 20.5814988],
                [104.7647344, 20.5818986],
                [104.7634491, 20.5822998],
                [104.7627355, 20.5821645],
                [104.7610234, 20.5816249],
                [104.7593117, 20.5808168],
                [104.7583169, 20.5779951],
                [104.7560378, 20.5750374],
                [104.7537554, 20.574094],
                [104.7511871, 20.5734188],
                [104.7477619, 20.5731453],
                [104.7453353, 20.5731417],
                [104.7430518, 20.5730041],
                [104.7423376, 20.5732716],
                [104.7404794, 20.5747461],
                [104.7374767, 20.5778304],
                [104.7334016, 20.5817861],
                [104.7298244, 20.5866152],
                [104.7273898, 20.5910433],
                [104.7266732, 20.5926537],
                [104.7259562, 20.5943984],
                [104.7253828, 20.5957405],
                [104.7248089, 20.5973512],
                [104.7246635, 20.5988282],
                [104.7242304, 20.6015135],
                [104.7236573, 20.6025869],
                [104.7197314, 20.6025135],
                [104.7163013, 20.6045223],
                [104.7138773, 20.6029067],
                [104.711166, 20.6022308],
                [104.7088838, 20.6011525],
                [104.7074557, 20.6014187],
                [104.7030289, 20.6019483],
                [104.6998878, 20.6020773],
                [104.6986032, 20.6019407],
                [104.698033, 20.6015369],
                [104.6976065, 20.600596],
                [104.6963269, 20.5979078],
                [104.6957591, 20.5962952],
                [104.6947603, 20.5960249],
                [104.6929066, 20.5949472],
                [104.6897668, 20.5945387],
                [104.6883397, 20.5942676],
                [104.6870548, 20.5942652],
                [104.6864828, 20.5948014],
                [104.6861951, 20.5958753],
                [104.6864784, 20.5969502],
                [104.6866189, 20.5980248],
                [104.6867589, 20.599368],
                [104.6870411, 20.6009801],
                [104.6871815, 20.6021891],
                [104.6871752, 20.6052778],
                [104.686238, 20.6097752],
                [104.6855216, 20.6109825],
                [104.6843767, 20.6123234],
                [104.6812317, 20.6141978],
                [104.6795145, 20.6160748],
                [104.6779414, 20.6172805],
                [104.6770825, 20.6183533],
                [104.6762238, 20.6192918],
                [104.6750798, 20.6200954],
                [104.6736497, 20.621167],
                [104.6720783, 20.621567],
                [104.6677936, 20.622096],
                [104.6652226, 20.6224939],
                [104.6643644, 20.6231638],
                [104.6639341, 20.624103],
                [104.6633602, 20.6253106],
                [104.6630726, 20.62625],
                [104.6622126, 20.6277256],
                [104.6617819, 20.6287992],
                [104.6598511, 20.6302055],
                [104.659561, 20.6322194],
                [104.6592713, 20.634099],
                [104.6589824, 20.6355757],
                [104.6584091, 20.6365146],
                [104.6574068, 20.6377212],
                [104.6549786, 20.6379851],
                [104.6534061, 20.6387877],
                [104.6512635, 20.639052],
                [104.6498324, 20.640392],
                [104.6491153, 20.6417335],
                [104.6485411, 20.6430753],
                [104.6476817, 20.644148],
                [104.6469653, 20.6452208],
                [104.646106, 20.6462935],
                [104.6446754, 20.6473649],
                [104.6428142, 20.6493755],
                [104.6402244, 20.6526184],
                [104.6378269, 20.6525655],
                [104.6372624, 20.6525],
                [104.6365185, 20.6521487],
                [104.6359752, 20.6516479],
                [104.6356558, 20.6509109],
                [104.6352684, 20.6493978],
                [104.6349223, 20.6482137],
                [104.6341016, 20.6469891],
                [104.6339489, 20.6466206],
                [104.6339922, 20.6460684],
                [104.634008, 20.6452795],
                [104.6338697, 20.6447795],
                [104.633633, 20.6445029],
                [104.6326711, 20.6439223],
                [104.6317791, 20.6433286],
                [104.6315705, 20.6430521],
                [104.6315709, 20.6428812],
                [104.6317139, 20.641435],
                [104.6316067, 20.6396201],
                [104.6316809, 20.6377662],
                [104.6315721, 20.6365957],
                [104.6313255, 20.6346491],
                [104.6308685, 20.6331096],
                [104.6303961, 20.6322145],
                [104.6296033, 20.6310161],
                [104.6289207, 20.6304624],
                [104.6276518, 20.6298943],
                [104.626773, 20.6296425],
                [104.6266057, 20.62955],
                [104.6265088, 20.629208],
                [104.6265101, 20.6287084],
                [104.6266517, 20.6279064],
                [104.6266533, 20.6272228],
                [104.6263905, 20.6262621],
                [104.6260566, 20.6257749],
                [104.6249693, 20.6252071],
                [104.6241752, 20.6245611],
                [104.623087, 20.6243089],
                [104.6220122, 20.6243065],
                [104.6211329, 20.6242783],
                [104.6196544, 20.623828],
                [104.6188595, 20.6235369],
                [104.6169533, 20.6225849],
                [104.6161539, 20.6222149],
                [104.6148521, 20.6218526],
                [104.6139505, 20.6214561],
                [104.6125284, 20.6207691],
                [104.6119336, 20.6205048],
                [104.6116743, 20.6200388],
                [104.6114633, 20.618864],
                [104.6111959, 20.617838],
                [104.6107877, 20.6173901],
                [104.6103698, 20.6170562],
                [104.6100631, 20.616924],
                [104.6093374, 20.6169048],
                [104.6090958, 20.6167554],
                [104.6089752, 20.6165974],
                [104.6088178, 20.6163165],
                [104.6086793, 20.6158605],
                [104.6083745, 20.6150097],
                [104.6080411, 20.6143517],
                [104.6079763, 20.6142113],
                [104.6076703, 20.6138425],
                [104.6075505, 20.6133778],
                [104.6075336, 20.6127292],
                [104.6074321, 20.6124047],
                [104.6071445, 20.6120447],
                [104.6068677, 20.6115525],
                [104.6067565, 20.6113418],
                [104.6063585, 20.6109652],
                [104.6058649, 20.6106033],
                [104.6053871, 20.6102263],
                [104.6050846, 20.6099401],
                [104.604862, 20.6095938],
                [104.6045444, 20.6090518],
                [104.6040836, 20.6083142],
                [104.6036066, 20.6076216],
                [104.6030339, 20.6069439],
                [104.6025087, 20.6064014],
                [104.6019992, 20.6060094],
                [104.6015849, 20.6057228],
                [104.6011283, 20.6056625],
                [104.6011224, 20.6056617],
                [104.6008988, 20.6057363],
                [104.6004195, 20.6059607],
                [104.5997647, 20.6062148],
                [104.5992692, 20.6065142],
                [104.5989968, 20.6068745],
                [104.5985327, 20.6074298],
                [104.5980365, 20.608],
                [104.597557, 20.6083295],
                [104.5970619, 20.6084636],
                [104.596663, 20.6084777],
                [104.5961365, 20.6084164],
                [104.5955783, 20.6083099],
                [104.5950677, 20.6083237],
                [104.5947323, 20.6084432],
                [104.5941734, 20.6086222],
                [104.5933585, 20.6089661],
                [104.5926234, 20.6094003],
                [104.5921441, 20.6096096],
                [104.5918568, 20.6096089],
                [104.5912987, 20.6094723],
                [104.5905816, 20.6091399],
                [104.589912, 20.6088978],
                [104.5894493, 20.6088816],
                [104.5890983, 20.6089109],
                [104.588364, 20.6090295],
                [104.5876932, 20.6092082],
                [104.5869898, 20.6096725],
                [104.5863024, 20.6101368],
                [104.5857277, 20.6102559],
                [104.5848816, 20.6103589],
                [104.5840998, 20.610357],
                [104.5835414, 20.6102957],
                [104.5830314, 20.6100689],
                [104.5825855, 20.609737],
                [104.5819008, 20.6091943],
                [104.5809298, 20.6083352],
                [104.5800695, 20.6078219],
                [104.579337, 20.607264],
                [104.5784125, 20.606961],
                [104.577886, 20.6068995],
                [104.5768485, 20.6069873],
                [104.5760508, 20.6069101],
                [104.5746794, 20.6066362],
                [104.5739773, 20.6066645],
                [104.5730353, 20.6068426],
                [104.5724923, 20.6069915],
                [104.5720445, 20.6073512],
                [104.5714693, 20.6076054],
                [104.5711182, 20.6076345],
                [104.5703367, 20.6075123],
                [104.5697303, 20.6075109],
                [104.5691713, 20.6076899],
                [104.5684199, 20.608184],
                [104.5678607, 20.6084683],
                [104.5675893, 20.6084676],
                [104.5673504, 20.6083618],
                [104.5667288, 20.6080445],
                [104.566123, 20.6078776],
                [104.5656763, 20.6078465],
                [104.565293, 20.6079657],
                [104.5645903, 20.6081745],
                [104.5639679, 20.608188],
                [104.5630747, 20.6080804],
                [104.5621815, 20.607958],
                [104.5613044, 20.6077904],
                [104.5605389, 20.607608],
                [104.5598696, 20.6072907],
                [104.5588655, 20.6068821],
                [104.5582914, 20.6067756],
                [104.5575892, 20.6068038],
                [104.5574138, 20.6067734],
                [104.5571435, 20.6064419],
                [104.556779, 20.6055842],
                [104.5564784, 20.6046964],
                [104.5560816, 20.6039438],
                [104.5556046, 20.6033563],
                [104.5547451, 20.6026775],
                [104.554092, 20.6022549],
                [104.5536618, 20.6020734],
                [104.5533587, 20.6020576],
                [104.5527522, 20.6021162],
                [104.5519381, 20.6021893],
                [104.5508375, 20.6020812],
                [104.549498, 20.6017921],
                [104.5490837, 20.6016107],
                [104.548638, 20.6012487],
                [104.5481137, 20.6004506],
                [104.5474777, 20.5997273],
                [104.5468737, 20.5989141],
                [104.5463962, 20.5985068],
                [104.5461411, 20.598461],
                [104.545857, 20.5985342],
                [104.5447817, 20.5988522],
                [104.5437599, 20.59905],
                [104.5432916, 20.5991289],
                [104.5429407, 20.5990878],
                [104.5424943, 20.5989765],
                [104.5420266, 20.5988047],
                [104.5413815, 20.5985135],
                [104.540952, 20.5976653],
                [104.5407112, 20.5964249],
                [104.5406039, 20.5955189],
                [104.5403179, 20.5950667],
                [104.5398024, 20.5944334],
                [104.5396315, 20.5939092],
                [104.5395189, 20.5931145],
                [104.5390617, 20.5922105],
                [104.5386426, 20.5913606],
                [104.5382828, 20.5899694],
                [104.5381113, 20.589662],
                [104.537882, 20.5894085],
                [104.5371546, 20.5891899],
                [104.536446, 20.5890437],
                [104.5359292, 20.5888256],
                [104.5357001, 20.5885721],
                [104.5354524, 20.5881201],
                [104.535282, 20.5873794],
                [104.5352854, 20.5862959],
                [104.5353452, 20.5855016],
                [104.5353857, 20.5847795],
                [104.5353108, 20.5842375],
                [104.534987, 20.5835866],
                [104.5346635, 20.5828093],
                [104.5346269, 20.5822494],
                [104.5348597, 20.5813292],
                [104.5351493, 20.5806077],
                [104.5352472, 20.5799038],
                [104.535135, 20.5790188],
                [104.5349655, 20.5780432],
                [104.5346609, 20.5774103],
                [104.5344143, 20.5765791],
                [104.5341236, 20.5760428],
                [104.533722, 20.5758523],
                [104.5326501, 20.5755546],
                [104.5315123, 20.5749408],
                [104.5304417, 20.5742639],
                [104.5298852, 20.5735043],
                [104.5295293, 20.573019],
                [104.5292394, 20.5728075],
                [104.5283453, 20.5728472],
                [104.5278539, 20.5727827],
                [104.527676, 20.5725084],
                [104.5275004, 20.571434],
                [104.5273269, 20.5706334],
                [104.5267029, 20.5700841],
                [104.5261724, 20.5697204],
                [104.5260788, 20.5695569],
                [104.5260924, 20.5694266],
                [104.526847, 20.5685407],
                [104.5268977, 20.5681393],
                [104.5268633, 20.5677603],
                [104.5264638, 20.5670297],
                [104.5259296, 20.5663332],
                [104.5251503, 20.5654044],
                [104.5247962, 20.5642873],
                [104.5241749, 20.5628956],
                [104.523797, 20.5622418],
                [104.5238438, 20.5615891],
                [104.524472, 20.5607484],
                [104.5250332, 20.5599076],
                [104.5250421, 20.5598915],
                [104.5266499, 20.5581602],
                [104.5279153, 20.5572042],
                [104.5281407, 20.5567443],
                [104.5281629, 20.556188],
                [104.5271712, 20.5542089],
                [104.5264026, 20.5525183],
                [104.5250027, 20.5509602],
                [104.5233575, 20.5496317],
                [104.5226871, 20.5492078],
                [104.5222191, 20.5490914],
                [104.5213641, 20.5490505],
                [104.5206925, 20.5489335],
                [104.5192649, 20.5483743],
                [104.5188009, 20.5483143],
                [104.5180271, 20.5483889],
                [104.5170883, 20.5490772],
                [104.5157172, 20.5496521],
                [104.514922, 20.5494946],
                [104.5140728, 20.5490786],
                [104.5135813, 20.5483532],
                [104.5127898, 20.5470322],
                [104.5123528, 20.5464105],
                [104.511805, 20.5460987],
                [104.510818, 20.5458891],
                [104.509832, 20.545395],
                [104.5091477, 20.544876],
                [104.5084086, 20.5443307],
                [104.507889, 20.5438381],
                [104.5076969, 20.5438117],
                [104.5069285, 20.543887],
                [104.5063249, 20.5438853],
                [104.505571, 20.5436957],
                [104.5050774, 20.5436684],
                [104.5046656, 20.543719],
                [104.5036761, 20.5442851],
                [104.502034, 20.5451672],
                [104.5002567, 20.5450656],
                [104.4968369, 20.5455424],
                [104.495818, 20.5438344],
                [104.49287, 20.5411455],
                [104.4923185, 20.5370738],
                [104.4872266, 20.5319362],
                [104.4858867, 20.5293743],
                [104.4844027, 20.5258184],
                [104.4826089, 20.5235376],
                [104.477277, 20.5195813],
                [104.4743665, 20.5162528],
                [104.4691062, 20.5139514],
                [104.4641934, 20.5132304],
                [104.4629879, 20.5131878],
                [104.4601637, 20.512887],
                [104.4581968, 20.5126802],
                [104.4580173, 20.5126614],
                [104.4565127, 20.5122621],
                [104.4546707, 20.5116125],
                [104.4527895, 20.5111755],
                [104.4510229, 20.5105264],
                [104.449441, 20.5104104],
                [104.4489904, 20.5101948],
                [104.4477131, 20.5096195],
                [104.4469631, 20.5090829],
                [104.4458772, 20.508083],
                [104.4446746, 20.5076146],
                [104.4433932, 20.5076423],
                [104.4424488, 20.5079913],
                [104.4412004, 20.5086931],
                [104.4400297, 20.5090761],
                [104.4388246, 20.5089623],
                [104.4372817, 20.5086692],
                [104.436268, 20.5081308],
                [104.4357825, 20.5074894],
                [104.4353759, 20.5063518],
                [104.4349319, 20.5051785],
                [104.4343371, 20.5040043],
                [104.433554, 20.5028288],
                [104.4316439, 20.5011499],
                [104.4295184, 20.4989958],
                [104.4235354, 20.4939716],
                [104.4223907, 20.492858],
                [104.4211054, 20.4919026],
                [104.4193702, 20.4913685],
                [104.4172718, 20.4905159],
                [104.4157049, 20.4899293],
                [104.4143897, 20.4894758],
                [104.4132974, 20.48934],
                [104.4118678, 20.4895202],
                [104.4108299, 20.4898073],
                [104.4093155, 20.4901721],
                [104.407942, 20.4902995],
                [104.4066811, 20.4902952],
                [104.4046646, 20.4900769],
                [104.3997946, 20.4886598],
                [104.3973587, 20.4882022],
                [104.3929034, 20.4882659],
                [104.3894529, 20.4892579],
                [104.3881062, 20.4897551],
                [104.387601, 20.4899647],
                [104.3874337, 20.4897528],
                [104.3872415, 20.4887482],
                [104.3871358, 20.487136],
                [104.3875353, 20.4853144],
                [104.3882114, 20.484392],
                [104.3894477, 20.4835244],
                [104.3911029, 20.4830018],
                [104.3916086, 20.4826601],
                [104.3921169, 20.4816843],
                [104.3923439, 20.4809189],
                [104.392375, 20.4801527],
                [104.3920983, 20.4792534],
                [104.3914844, 20.4786436],
                [104.3909538, 20.478219],
                [104.3900311, 20.4777137],
                [104.3888002, 20.4772339],
                [104.3866183, 20.4763808],
                [104.3830075, 20.475549],
                [104.3815525, 20.4750417],
                [104.3807421, 20.4745369],
                [104.3799884, 20.4738208],
                [104.3799357, 20.473758],
                [104.3778138, 20.4711973],
                [104.3772565, 20.4704555],
                [104.376202, 20.4679416],
                [104.3759531, 20.4671481],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [107.0343436, 16.3583792],
                [107.0343557, 16.3583076],
                [107.0344048, 16.3581752],
                [107.0344826, 16.358061],
                [107.0345313, 16.3580111],
                [107.0346451, 16.3579292],
                [107.0346236, 16.3571713],
                [107.0349249, 16.3571728],
                [107.0350751, 16.3571613],
                [107.0353472, 16.3571193],
                [107.0355889, 16.3570581],
                [107.0369223, 16.356645],
                [107.0371063, 16.3566126],
                [107.0371998, 16.356608],
                [107.0373863, 16.3566218],
                [107.0375763, 16.3566691],
                [107.0377542, 16.3567485],
                [107.0379576, 16.3568757],
                [107.038089, 16.3569332],
                [107.0381722, 16.3569583],
                [107.0383439, 16.3569859],
                [107.038518, 16.3569825],
                [107.038604, 16.3569693],
                [107.0387077, 16.35694],
                [107.0389073, 16.3568609],
                [107.0390023, 16.3568116],
                [107.0391803, 16.3566943],
                [107.0392625, 16.356627],
                [107.039419, 16.3564674],
                [107.0395492, 16.3562874],
                [107.0396035, 16.3561909],
                [107.0396892, 16.3559878],
                [107.03972, 16.3558823],
                [107.0397569, 16.355666],
                [107.0397627, 16.3555565],
                [107.0397708, 16.3547903],
                [107.0397824, 16.354695],
                [107.0398304, 16.3545082],
                [107.0399438, 16.3542553],
                [107.0400175, 16.3540419],
                [107.0400638, 16.3538215],
                [107.0400766, 16.3537097],
                [107.0401271, 16.3535446],
                [107.0401617, 16.3534652],
                [107.0402588, 16.3533],
                [107.0403174, 16.3532235],
                [107.040427, 16.3531046],
                [107.0405313, 16.3530258],
                [107.0406512, 16.3529711],
                [107.0407747, 16.3529438],
                [107.040838, 16.3529397],
                [107.0411321, 16.3529548],
                [107.0412228, 16.3529325],
                [107.0413023, 16.3528848],
                [107.0413374, 16.3528505],
                [107.0413879, 16.3527677],
                [107.0414018, 16.3527215],
                [107.0414054, 16.3526256],
                [107.0412946, 16.3520971],
                [107.0412312, 16.3518592],
                [107.0411086, 16.351474],
                [107.0408962, 16.3509198],
                [107.0408736, 16.3508285],
                [107.0408507, 16.3506426],
                [107.0408578, 16.3504559],
                [107.0408946, 16.3502725],
                [107.0411548, 16.3493987],
                [107.0413452, 16.3493769],
                [107.0414423, 16.3494159],
                [107.0415427, 16.349446],
                [107.0416456, 16.3494669],
                [107.0417491, 16.3494783],
                [107.0418533, 16.3494804],
                [107.0419572, 16.3494731],
                [107.0420599, 16.3494566],
                [107.0425531, 16.3493236],
                [107.0429519, 16.3492404],
                [107.04341, 16.3491756],
                [107.0438719, 16.3491426],
                [107.044124, 16.3489316],
                [107.0442942, 16.3490029],
                [107.0443761, 16.3490448],
                [107.0445326, 16.3491406],
                [107.0446816, 16.3492551],
                [107.0448187, 16.3493897],
                [107.0451962, 16.3493957],
                [107.0455729, 16.3493717],
                [107.0459665, 16.3493142],
                [107.046171, 16.3492708],
                [107.0463732, 16.3492184],
                [107.0465726, 16.3491572],
                [107.046769, 16.3490873],
                [107.0469618, 16.3490088],
                [107.0471148, 16.3489343],
                [107.0471959, 16.3489073],
                [107.0473641, 16.348875],
                [107.0474572, 16.3488698],
                [107.0476427, 16.3488857],
                [107.0478214, 16.3489361],
                [107.047906, 16.3489737],
                [107.04826, 16.3491632],
                [107.0484439, 16.3491709],
                [107.0486272, 16.349188],
                [107.0488093, 16.3492147],
                [107.0489895, 16.3492507],
                [107.049162, 16.3492946],
                [107.0494986, 16.3494079],
                [107.0496618, 16.3494771],
                [107.0499276, 16.3496071],
                [107.050038, 16.3496517],
                [107.0502682, 16.3497154],
                [107.0503864, 16.3497341],
                [107.0506257, 16.3497449],
                [107.0508534, 16.3497218],
                [107.0509652, 16.3496981],
                [107.0511819, 16.3496271],
                [107.0512855, 16.3495802],
                [107.0514344, 16.3494522],
                [107.0515591, 16.3493022],
                [107.051654, 16.3491387],
                [107.0516908, 16.3490519],
                [107.05176, 16.3488206],
                [107.0518179, 16.3485321],
                [107.0518357, 16.3483861],
                [107.0518488, 16.3480926],
                [107.0519824, 16.3479969],
                [107.0521102, 16.3478941],
                [107.0522319, 16.3477846],
                [107.0523469, 16.3476688],
                [107.0525515, 16.3474251],
                [107.0527234, 16.3471673],
                [107.0533025, 16.3461674],
                [107.0548072, 16.3471094],
                [107.0552685, 16.3472718],
                [107.0557192, 16.3474594],
                [107.0559462, 16.3475654],
                [107.0563897, 16.3477966],
                [107.0567603, 16.3477763],
                [107.056945, 16.347758],
                [107.0573471, 16.3476988],
                [107.0577443, 16.3476138],
                [107.0579015, 16.3475924],
                [107.0580598, 16.3475801],
                [107.0582186, 16.3475769],
                [107.0583773, 16.3475829],
                [107.0585269, 16.3475971],
                [107.0588225, 16.3476499],
                [107.0589144, 16.3476562],
                [107.0590064, 16.3476531],
                [107.0590975, 16.3476406],
                [107.0591868, 16.3476189],
                [107.0595241, 16.3474845],
                [107.059654, 16.347444],
                [107.0598983, 16.3473895],
                [107.0601475, 16.3473616],
                [107.0602356, 16.3473631],
                [107.0604097, 16.3473881],
                [107.0604944, 16.3474114],
                [107.0606558, 16.3474787],
                [107.0607671, 16.3477631],
                [107.0608878, 16.3480076],
                [107.061671, 16.3477837],
                [107.0617907, 16.3476878],
                [107.0618885, 16.3475714],
                [107.061928, 16.3475068],
                [107.0619889, 16.3473593],
                [107.0620078, 16.3472774],
                [107.0620171, 16.3471939],
                [107.0620168, 16.3471099],
                [107.0620069, 16.3470265],
                [107.0619875, 16.3469446],
                [107.0615476, 16.3457195],
                [107.0615138, 16.3456661],
                [107.0614302, 16.3455704],
                [107.0613813, 16.3455291],
                [107.0612638, 16.3454579],
                [107.0611998, 16.3454319],
                [107.0610648, 16.3454004],
                [107.060578, 16.3453542],
                [107.0604798, 16.3453194],
                [107.0603994, 16.3452551],
                [107.060346, 16.3451687],
                [107.0603259, 16.3450742],
                [107.0603385, 16.3449785],
                [107.0603824, 16.3448918],
                [107.0604531, 16.3448232],
                [107.0606169, 16.3447338],
                [107.0608688, 16.3446254],
                [107.0611319, 16.3445453],
                [107.0618909, 16.3444017],
                [107.0619868, 16.3443319],
                [107.0620275, 16.3442892],
                [107.0620941, 16.3441863],
                [107.062133, 16.3440651],
                [107.062127, 16.3438406],
                [107.0620781, 16.3436851],
                [107.0620387, 16.343527],
                [107.062009, 16.3433671],
                [107.0619821, 16.3431148],
                [107.0619428, 16.3429355],
                [107.0618756, 16.342764],
                [107.0617964, 16.3426244],
                [107.0617694, 16.3425622],
                [107.0617515, 16.3424971],
                [107.0617432, 16.3423721],
                [107.0617514, 16.3423099],
                [107.0617921, 16.3421909],
                [107.0618238, 16.342136],
                [107.0619077, 16.34204],
                [107.0619584, 16.3420004],
                [107.0620139, 16.3419673],
                [107.0626259, 16.3417044],
                [107.0627723, 16.3415862],
                [107.0628964, 16.3414465],
                [107.062949, 16.3413697],
                [107.0630336, 16.341205],
                [107.0630654, 16.3411166],
                [107.0631053, 16.3409337],
                [107.0631131, 16.3408404],
                [107.063104, 16.3406537],
                [107.0630872, 16.3405616],
                [107.0629522, 16.3401861],
                [107.0628943, 16.3399954],
                [107.0627975, 16.3396092],
                [107.062729, 16.3392325],
                [107.0626913, 16.338948],
                [107.0626853, 16.3387412],
                [107.0626942, 16.3386302],
                [107.0627371, 16.3384115],
                [107.062771, 16.338305],
                [107.0628624, 16.3381004],
                [107.0629838, 16.3379109],
                [107.0632688, 16.3375488],
                [107.0634745, 16.337269],
                [107.0636719, 16.3369839],
                [107.0637258, 16.3368516],
                [107.0637406, 16.3367819],
                [107.0637448, 16.3366363],
                [107.0637332, 16.3365624],
                [107.063683, 16.3364211],
                [107.0636451, 16.3363558],
                [107.0631945, 16.3359543],
                [107.0628619, 16.3356454],
                [107.0627986, 16.3355384],
                [107.0627588, 16.3354149],
                [107.0627484, 16.3352798],
                [107.0627555, 16.3352123],
                [107.0627707, 16.3351461],
                [107.0628382, 16.3349852],
                [107.0628609, 16.3349012],
                [107.062876, 16.3348156],
                [107.062883, 16.3346427],
                [107.0628593, 16.3344717],
                [107.0628361, 16.3343882],
                [107.0628056, 16.334307],
                [107.0627406, 16.334182],
                [107.0627185, 16.3341154],
                [107.0627037, 16.334047],
                [107.0626956, 16.3339218],
                [107.0627117, 16.3337973],
                [107.0627756, 16.3336098],
                [107.0627989, 16.3335137],
                [107.0628294, 16.3332984],
                [107.0628339, 16.3331798],
                [107.0628136, 16.3324949],
                [107.0628188, 16.3324303],
                [107.062851, 16.3323046],
                [107.0629111, 16.3321887],
                [107.062996, 16.3320882],
                [107.0630494, 16.3320432],
                [107.0631705, 16.3319723],
                [107.0633053, 16.3319299],
                [107.0635521, 16.3319086],
                [107.0637587, 16.3318642],
                [107.0638585, 16.3318299],
                [107.0640475, 16.3317382],
                [107.0642694, 16.3316515],
                [107.0647227, 16.3314867],
                [107.064954, 16.3314087],
                [107.0650074, 16.3313975],
                [107.0651057, 16.331352],
                [107.0651847, 16.33128],
                [107.0652151, 16.3312351],
                [107.0652521, 16.3311344],
                [107.0652544, 16.3310277],
                [107.0652303, 16.3308778],
                [107.0652303, 16.3308019],
                [107.0652544, 16.3306519],
                [107.065281, 16.3305728],
                [107.0653167, 16.330497],
                [107.0653609, 16.3304255],
                [107.0654132, 16.3303593],
                [107.0654729, 16.330299],
                [107.0655392, 16.3302455],
                [107.0656114, 16.3301995],
                [107.0656884, 16.3301615],
                [107.0658455, 16.3301004],
                [107.0659173, 16.3300608],
                [107.065984, 16.3300136],
                [107.0660902, 16.3299097],
                [107.0662331, 16.3297031],
                [107.0663701, 16.3295459],
                [107.0665466, 16.3293868],
                [107.0666541, 16.3293077],
                [107.0667672, 16.3292362],
                [107.0668834, 16.3291758],
                [107.0669872, 16.3290972],
                [107.067077, 16.329001],
                [107.0672038, 16.3287949],
                [107.0672823, 16.3287144],
                [107.0673286, 16.3286816],
                [107.0674394, 16.3286311],
                [107.067568, 16.3286097],
                [107.0676977, 16.3286229],
                [107.0678794, 16.3286904],
                [107.0679423, 16.3287033],
                [107.0680708, 16.328706],
                [107.0681392, 16.3286945],
                [107.0682055, 16.3286742],
                [107.0682682, 16.3286455],
                [107.0683263, 16.3286089],
                [107.0683786, 16.328565],
                [107.0684243, 16.3285147],
                [107.0685669, 16.3283165],
                [107.0687967, 16.328047],
                [107.0689215, 16.3279205],
                [107.0691519, 16.327716],
                [107.0692241, 16.3276095],
                [107.0692581, 16.3275147],
                [107.069267, 16.3274653],
                [107.0693075, 16.3273855],
                [107.0693748, 16.3273245],
                [107.0694158, 16.3273037],
                [107.0695371, 16.327262],
                [107.06968, 16.3271837],
                [107.0698035, 16.3270792],
                [107.0698942, 16.3269656],
                [107.0699616, 16.326838],
                [107.0699859, 16.3267703],
                [107.0700264, 16.3267035],
                [107.070129, 16.3265844],
                [107.0702572, 16.3264884],
                [107.0703295, 16.3264508],
                [107.070557, 16.3263728],
                [107.0706264, 16.3263398],
                [107.0707544, 16.3262563],
                [107.0708682, 16.3261609],
                [107.0709283, 16.3261201],
                [107.0710587, 16.3260547],
                [107.0711304, 16.3260298],
                [107.0712794, 16.3259994],
                [107.0713554, 16.3259941],
                [107.0718004, 16.3260072],
                [107.0718715, 16.3260032],
                [107.0719358, 16.3259926],
                [107.0721533, 16.3259222],
                [107.0722496, 16.3259003],
                [107.0724696, 16.3258977],
                [107.0726305, 16.3258668],
                [107.0728013, 16.3258078],
                [107.0728826, 16.325769],
                [107.0736807, 16.3253102],
                [107.0738716, 16.3252199],
                [107.0740859, 16.3251536],
                [107.0742024, 16.3251312],
                [107.0743203, 16.3251178],
                [107.0745025, 16.325115],
                [107.0746805, 16.3250773],
                [107.0747656, 16.3250457],
                [107.0748492, 16.3250048],
                [107.074928, 16.3249558],
                [107.0750011, 16.3248992],
                [107.0750677, 16.3248357],
                [107.0751271, 16.3247658],
                [107.075245, 16.3246039],
                [107.0753165, 16.3245213],
                [107.075474, 16.3243686],
                [107.0765439, 16.3234575],
                [107.076737, 16.3233236],
                [107.0769435, 16.3232181],
                [107.0770197, 16.3231717],
                [107.0771564, 16.323059],
                [107.0772683, 16.3229233],
                [107.0773136, 16.3228484],
                [107.07742, 16.3225985],
                [107.0775174, 16.3224287],
                [107.0776382, 16.3222734],
                [107.0777516, 16.3221766],
                [107.0778796, 16.3220984],
                [107.0779554, 16.3220638],
                [107.078115, 16.3220146],
                [107.0781976, 16.3220003],
                [107.0782812, 16.321993],
                [107.0794744, 16.3219973],
                [107.0796741, 16.3219718],
                [107.0797716, 16.321947],
                [107.0799583, 16.3218744],
                [107.080133, 16.3217705],
                [107.0802866, 16.3216395],
                [107.0803541, 16.321565],
                [107.0805484, 16.3212888],
                [107.0806369, 16.3211824],
                [107.0807328, 16.3210822],
                [107.0808357, 16.3209887],
                [107.0809451, 16.3209022],
                [107.0810605, 16.3208232],
                [107.0811814, 16.3207521],
                [107.081511, 16.3205239],
                [107.081573, 16.3204432],
                [107.0816038, 16.3203607],
                [107.0816103, 16.3203172],
                [107.0816137, 16.3200292],
                [107.0816266, 16.3199078],
                [107.0816685, 16.319694],
                [107.0817339, 16.3194856],
                [107.0818219, 16.3193146],
                [107.0819324, 16.3191561],
                [107.0820002, 16.3190774],
                [107.0822075, 16.3188763],
                [107.082256, 16.3188125],
                [107.0823276, 16.31868],
                [107.0823688, 16.3185448],
                [107.082383, 16.3184045],
                [107.0823689, 16.3182412],
                [107.0823237, 16.3180832],
                [107.0822489, 16.317936],
                [107.0822159, 16.3178405],
                [107.082206, 16.3177404],
                [107.0822258, 16.3176198],
                [107.082279, 16.3175089],
                [107.082317, 16.3174597],
                [107.0823616, 16.317416],
                [107.0825708, 16.3172513],
                [107.0826043, 16.3172108],
                [107.0826556, 16.3171201],
                [107.0826727, 16.3170711],
                [107.0826887, 16.3169591],
                [107.0826747, 16.3168469],
                [107.0826183, 16.3166626],
                [107.0825869, 16.3165305],
                [107.0825762, 16.3150169],
                [107.0822596, 16.3144866],
                [107.0822295, 16.3143844],
                [107.0821892, 16.3141755],
                [107.0821792, 16.3140696],
                [107.0821738, 16.3136319],
                [107.0813316, 16.3124426],
                [107.0815941, 16.3124406],
                [107.0818546, 16.3124092],
                [107.082118, 16.3123464],
                [107.0824108, 16.312244],
                [107.082584, 16.312215],
                [107.082672, 16.3122122],
                [107.0828518, 16.3122314],
                [107.083029, 16.3122853],
                [107.0831916, 16.3123716],
                [107.083327, 16.3124712],
                [107.0833931, 16.31251],
                [107.0835364, 16.3125662],
                [107.0836796, 16.3125914],
                [107.0838252, 16.3125886],
                [107.0839673, 16.3125581],
                [107.0851429, 16.3121049],
                [107.08543, 16.3120256],
                [107.085726, 16.31198],
                [107.0858755, 16.3119701],
                [107.0862746, 16.3119659],
                [107.0865217, 16.3119355],
                [107.0867675, 16.311877],
                [107.0870045, 16.3117914],
                [107.0872499, 16.3117213],
                [107.0875383, 16.3116649],
                [107.0878691, 16.311633],
                [107.0888312, 16.3111286],
                [107.0903783, 16.3098541],
                [107.0919846, 16.3076837],
                [107.0932427, 16.3069502],
                [107.0940886, 16.3064286],
                [107.0961289, 16.3051709],
                [107.0979759, 16.3038395],
                [107.1016042, 16.301418],
                [107.1030685, 16.3003968],
                [107.1064024, 16.2980439],
                [107.1083859, 16.2967547],
                [107.1114713, 16.2945356],
                [107.1129559, 16.2932952],
                [107.1145557, 16.2922068],
                [107.1178204, 16.2897888],
                [107.120796, 16.2879207],
                [107.1226917, 16.2866744],
                [107.1249227, 16.285472],
                [107.1269266, 16.2840075],
                [107.128772, 16.2825665],
                [107.1303937, 16.2814341],
                [107.132916, 16.279658],
                [107.1348962, 16.2780842],
                [107.1375777, 16.2763283],
                [107.1414765, 16.2738379],
                [107.1441334, 16.2719289],
                [107.14562, 16.2709072],
                [107.1463354, 16.2699153],
                [107.146502, 16.2686338],
                [107.1464935, 16.2683504],
                [107.1466828, 16.2680803],
                [107.147486, 16.2673084],
                [107.1477375, 16.2668722],
                [107.1481772, 16.2660628],
                [107.1484681, 16.2653167],
                [107.1488039, 16.2647766],
                [107.1491191, 16.2642986],
                [107.1494996, 16.2639439],
                [107.1499446, 16.2636091],
                [107.1504116, 16.2633566],
                [107.1509018, 16.2632691],
                [107.151628, 16.2632823],
                [107.1518412, 16.2632388],
                [107.1520955, 16.2630505],
                [107.1524953, 16.2625098],
                [107.1525566, 16.2622614],
                [107.1525964, 16.2619927],
                [107.1526976, 16.2614756],
                [107.1529055, 16.2609574],
                [107.153153, 16.2601499],
                [107.1533189, 16.259694],
                [107.1537816, 16.2590494],
                [107.1541206, 16.2587983],
                [107.1546082, 16.2584631],
                [107.1554783, 16.2579588],
                [107.1560497, 16.2574782],
                [107.1567275, 16.2569759],
                [107.1570236, 16.2567046],
                [107.1570852, 16.2564769],
                [107.1570188, 16.2562711],
                [107.1569311, 16.2560656],
                [107.1567774, 16.255675],
                [107.1562348, 16.2548755],
                [107.15595, 16.2542178],
                [107.1557335, 16.2539517],
                [107.1555186, 16.25383],
                [107.154983, 16.2536703],
                [107.1542739, 16.2532647],
                [107.1539695, 16.2527724],
                [107.1538785, 16.2522572],
                [107.1538981, 16.2520919],
                [107.1539114, 16.2513488],
                [107.153994, 16.2511001],
                [107.1542441, 16.2505402],
                [107.1543472, 16.2501882],
                [107.154595, 16.249422],
                [107.1547409, 16.2490663],
                [107.1548646, 16.248636],
                [107.1549857, 16.2480177],
                [107.1550242, 16.2474422],
                [107.1549943, 16.2471339],
                [107.1548999, 16.2468545],
                [107.154388, 16.2460121],
                [107.1540141, 16.2453055],
                [107.1538355, 16.2447826],
                [107.1538061, 16.2445973],
                [107.153766, 16.2440118],
                [107.1536146, 16.2430575],
                [107.1535537, 16.2423242],
                [107.1534017, 16.2418939],
                [107.1529651, 16.2410957],
                [107.1527052, 16.2407448],
                [107.1522989, 16.240369],
                [107.1518087, 16.240174],
                [107.1514134, 16.2400985],
                [107.1508983, 16.2399482],
                [107.1506229, 16.2398562],
                [107.1504355, 16.2397145],
                [107.1502002, 16.2395241],
                [107.1495176, 16.2385675],
                [107.1493508, 16.2382153],
                [107.1492462, 16.2376758],
                [107.1492497, 16.2372261],
                [107.1492968, 16.2365275],
                [107.1493428, 16.236302],
                [107.1494434, 16.2361479],
                [107.1498644, 16.2357617],
                [107.1503402, 16.235487],
                [107.1509284, 16.2352559],
                [107.1516344, 16.2350764],
                [107.152911, 16.2346958],
                [107.1529328, 16.2346875],
                [107.1536301, 16.2344227],
                [107.154072, 16.2341818],
                [107.1543367, 16.2339749],
                [107.1547212, 16.2335626],
                [107.1550665, 16.2331436],
                [107.1553081, 16.2326525],
                [107.1553856, 16.2323091],
                [107.1554219, 16.2315565],
                [107.1554142, 16.2315098],
                [107.1552975, 16.23104],
                [107.1551526, 16.2306924],
                [107.1548636, 16.2302977],
                [107.1543437, 16.22976],
                [107.1539195, 16.2294296],
                [107.153412, 16.2290204],
                [107.1530839, 16.2286822],
                [107.152947, 16.2284388],
                [107.1528748, 16.2282126],
                [107.1527903, 16.2278543],
                [107.1527203, 16.22761],
                [107.1525943, 16.2272828],
                [107.1523514, 16.2267576],
                [107.1521978, 16.2264231],
                [107.1520861, 16.2261765],
                [107.151974, 16.225986],
                [107.1518011, 16.2257586],
                [107.150397, 16.2240729],
                [107.1497497, 16.2234912],
                [107.1494518, 16.2232876],
                [107.1482245, 16.2222827],
                [107.1475504, 16.2218301],
                [107.1473738, 16.2216758],
                [107.1469505, 16.2211282],
                [107.1468919, 16.2210333],
                [107.146792, 16.2208716],
                [107.1465105, 16.2202388],
                [107.1463085, 16.2195461],
                [107.1461632, 16.219257],
                [107.1460517, 16.2189664],
                [107.1457465, 16.2179781],
                [107.1456748, 16.2175554],
                [107.1454987, 16.2170032],
                [107.1452425, 16.2159546],
                [107.1449402, 16.2149341],
                [107.1447519, 16.2144632],
                [107.1446428, 16.2138755],
                [107.1447771, 16.2131334],
                [107.1450073, 16.212481],
                [107.1453308, 16.2115818],
                [107.1455796, 16.211355],
                [107.1456494, 16.21132],
                [107.1458373, 16.2113065],
                [107.1459525, 16.2113212],
                [107.1461559, 16.2114292],
                [107.146338, 16.2115544],
                [107.1465477, 16.211649],
                [107.1468106, 16.2116463],
                [107.1470925, 16.2115768],
                [107.1473517, 16.2114135],
                [107.1475452, 16.2111778],
                [107.1477013, 16.2108535],
                [107.1478238, 16.2104852],
                [107.1480907, 16.2099565],
                [107.1482921, 16.2093679],
                [107.1483034, 16.208832],
                [107.148105, 16.2082314],
                [107.1478776, 16.2074774],
                [107.1476785, 16.2066231],
                [107.1475448, 16.2059101],
                [107.1475154, 16.2054056],
                [107.1475092, 16.2048504],
                [107.1473339, 16.2032477],
                [107.1473249, 16.203156],
                [107.147255, 16.2026511],
                [107.1472729, 16.2022799],
                [107.14738, 16.2020563],
                [107.1476185, 16.2018704],
                [107.1478805, 16.2017333],
                [107.1484435, 16.2014924],
                [107.1486828, 16.2013415],
                [107.1489388, 16.2011153],
                [107.1492717, 16.2006913],
                [107.1494287, 16.2004787],
                [107.1495998, 16.200211],
                [107.1496639, 16.2000873],
                [107.1498556, 16.1997343],
                [107.1499703, 16.1993814],
                [107.1500904, 16.1988081],
                [107.1501693, 16.1975709],
                [107.1501782, 16.1972836],
                [107.1502055, 16.1969961],
                [107.150165, 16.1966154],
                [107.1500035, 16.1956907],
                [107.1499763, 16.1955665],
                [107.1500111, 16.1951226],
                [107.1500261, 16.1949753],
                [107.1500545, 16.1947551],
                [107.1499968, 16.1945341],
                [107.1498998, 16.1943361],
                [107.1497698, 16.1942451],
                [107.1494412, 16.1941078],
                [107.1492325, 16.1939846],
                [107.1490172, 16.1938185],
                [107.1488303, 16.193645],
                [107.1487887, 16.1935996],
                [107.1487737, 16.1935622],
                [107.1487766, 16.1933634],
                [107.1487528, 16.1933079],
                [107.1486072, 16.1929688],
                [107.1486732, 16.192431],
                [107.1489092, 16.1917661],
                [107.1492086, 16.1904861],
                [107.1498579, 16.1895225],
                [107.1498166, 16.1879265],
                [107.1505522, 16.1867532],
                [107.1526347, 16.185174],
                [107.1535942, 16.1845855],
                [107.153959, 16.1842704],
                [107.1543672, 16.1837319],
                [107.1547711, 16.182793],
                [107.1552194, 16.18172],
                [107.1560101, 16.1781374],
                [107.1562929, 16.1774801],
                [107.1564418, 16.177063],
                [107.1570491, 16.176493],
                [107.1571075, 16.1762254],
                [107.1569786, 16.1756628],
                [107.1569348, 16.1744464],
                [107.1567179, 16.1714214],
                [107.1570515, 16.1710618],
                [107.1575996, 16.1707003],
                [107.1581787, 16.1703679],
                [107.1586641, 16.1698882],
                [107.158996, 16.1693802],
                [107.1589906, 16.1688757],
                [107.1591981, 16.1682207],
                [107.1593788, 16.1679222],
                [107.1595916, 16.1677419],
                [107.1599283, 16.1676792],
                [107.1601124, 16.1676773],
                [107.1605317, 16.1677443],
                [107.1606751, 16.1677722],
                [107.1609661, 16.1678682],
                [107.1611057, 16.1679179],
                [107.1613085, 16.1679771],
                [107.1614415, 16.1680079],
                [107.1615852, 16.1680191],
                [107.1617244, 16.1680273],
                [107.1618505, 16.1680138],
                [107.1619485, 16.1679758],
                [107.1620654, 16.1678938],
                [107.162161, 16.1677951],
                [107.1622405, 16.1676983],
                [107.1623283, 16.1676066],
                [107.1624237, 16.1675152],
                [107.1625736, 16.1673313],
                [107.1627576, 16.1671647],
                [107.1629082, 16.1670551],
                [107.1631203, 16.166946],
                [107.1633414, 16.1668989],
                [107.1635619, 16.1668783],
                [107.1643071, 16.1667999],
                [107.1644382, 16.1667994],
                [107.1655702, 16.1662236],
                [107.165885, 16.1660796],
                [107.1661076, 16.1660206],
                [107.166335, 16.1659958],
                [107.1665004, 16.165976],
                [107.1666774, 16.1659513],
                [107.1669184, 16.1659411],
                [107.1671665, 16.1659871],
                [107.1674889, 16.1660471],
                [107.1677303, 16.1660781],
                [107.1681238, 16.1661547],
                [107.1683127, 16.1662069],
                [107.1687704, 16.1663758],
                [107.1688545, 16.1664527],
                [107.1690244, 16.1666396],
                [107.1691033, 16.1666897],
                [107.169201, 16.1667076],
                [107.1693155, 16.1666954],
                [107.1694589, 16.1666303],
                [107.1696731, 16.1664864],
                [107.1698884, 16.1663205],
                [107.1702385, 16.1660049],
                [107.1705064, 16.1657481],
                [107.1706844, 16.1655555],
                [107.1721925, 16.1638284],
                [107.1722486, 16.163691],
                [107.1723573, 16.1634975],
                [107.1724237, 16.1633285],
                [107.1728349, 16.1625662],
                [107.1731513, 16.1620918],
                [107.1745023, 16.1600204],
                [107.1746018, 16.1598017],
                [107.1747007, 16.1596281],
                [107.1747629, 16.1594727],
                [107.1748817, 16.1590444],
                [107.1752342, 16.1579301],
                [107.1753638, 16.1577265],
                [107.1755155, 16.1575812],
                [107.1757342, 16.1574639],
                [107.1758224, 16.1574304],
                [107.1759046, 16.1574056],
                [107.176078, 16.157393],
                [107.1764838, 16.1573851],
                [107.1769028, 16.157386],
                [107.1771838, 16.1573108],
                [107.1773002, 16.1571677],
                [107.1773727, 16.1570771],
                [107.177551, 16.1568646],
                [107.1777239, 16.1567151],
                [107.1779611, 16.1564917],
                [107.1782121, 16.1562904],
                [107.1784913, 16.156074],
                [107.1817605, 16.1534095],
                [107.181858, 16.1532806],
                [107.1819501, 16.1531015],
                [107.1820392, 16.1529249],
                [107.182113, 16.1526877],
                [107.1822028, 16.152483],
                [107.1822898, 16.1523579],
                [107.1823702, 16.1522989],
                [107.1824638, 16.152254],
                [107.1826072, 16.152211],
                [107.1829388, 16.1521564],
                [107.1832974, 16.1520408],
                [107.1834908, 16.1519922],
                [107.1838877, 16.1519449],
                [107.1840789, 16.151948],
                [107.1844013, 16.1520218],
                [107.184658, 16.1520937],
                [107.1847383, 16.1521344],
                [107.1852491, 16.1521664],
                [107.1855607, 16.1521786],
                [107.1858091, 16.152182],
                [107.1862697, 16.1520174],
                [107.1865799, 16.151678],
                [107.1866891, 16.1514919],
                [107.1868203, 16.1512205],
                [107.1878831, 16.1492087],
                [107.1891601, 16.1486326],
                [107.1893496, 16.1484707],
                [107.1895404, 16.1483539],
                [107.1896717, 16.1483076],
                [107.1897967, 16.1482695],
                [107.1900303, 16.1481998],
                [107.1902965, 16.1481356],
                [107.1905484, 16.1480777],
                [107.1906895, 16.14804],
                [107.1908082, 16.1479839],
                [107.1917028, 16.1474133],
                [107.1924683, 16.1464778],
                [107.1931287, 16.1454138],
                [107.1936269, 16.1442097],
                [107.1950449, 16.1454483],
                [107.1951947, 16.1453063],
                [107.1952876, 16.1451756],
                [107.1956419, 16.1448767],
                [107.1960277, 16.1445419],
                [107.1964401, 16.1442013],
                [107.1974932, 16.1438843],
                [107.1980377, 16.1439032],
                [107.1982839, 16.1439448],
                [107.2001784, 16.1443893],
                [107.2013637, 16.1448304],
                [107.2018033, 16.14499],
                [107.2019314, 16.1450499],
                [107.2020672, 16.145129],
                [107.2041219, 16.1452609],
                [107.2042693, 16.1452829],
                [107.2045208, 16.1452956],
                [107.2047004, 16.1452994],
                [107.2048321, 16.1453194],
                [107.2051285, 16.1453389],
                [107.2052095, 16.1453586],
                [107.2054787, 16.1454004],
                [107.2058926, 16.145407],
                [107.2070091, 16.1454525],
                [107.2076509, 16.1453002],
                [107.2088202, 16.1447017],
                [107.2116264, 16.1426996],
                [107.2128234, 16.141726],
                [107.2131602, 16.1414859],
                [107.2132633, 16.1414118],
                [107.2133364, 16.1413621],
                [107.2134322, 16.1412891],
                [107.2136772, 16.141166],
                [107.2138089, 16.1411328],
                [107.2139403, 16.1411329],
                [107.2140255, 16.1411397],
                [107.2141102, 16.1411424],
                [107.2142863, 16.1411413],
                [107.2144298, 16.14116],
                [107.2145649, 16.1411666],
                [107.2147772, 16.1411564],
                [107.2149731, 16.141162],
                [107.2152567, 16.1411616],
                [107.2155508, 16.1412316],
                [107.2156555, 16.1412645],
                [107.2157561, 16.1413318],
                [107.2158946, 16.1414159],
                [107.2161754, 16.1414975],
                [107.217269, 16.1417121],
                [107.2177307, 16.1417415],
                [107.2184957, 16.1416723],
                [107.2185739, 16.141669],
                [107.2186892, 16.1416514],
                [107.2188085, 16.1416273],
                [107.2190287, 16.1415656],
                [107.2192838, 16.1414818],
                [107.2194712, 16.1413917],
                [107.2197659, 16.1412325],
                [107.2198153, 16.1412108],
                [107.2199207, 16.1411659],
                [107.2200762, 16.1410501],
                [107.2222055, 16.1389881],
                [107.2227716, 16.1384283],
                [107.2237829, 16.1374484],
                [107.224058, 16.1373687],
                [107.2242121, 16.1374438],
                [107.2246814, 16.1388909],
                [107.2245626, 16.1402997],
                [107.2245988, 16.1413509],
                [107.227373, 16.1427748],
                [107.2275966, 16.1428989],
                [107.2278014, 16.1430026],
                [107.2280289, 16.1430902],
                [107.2281751, 16.1431378],
                [107.2283297, 16.1431726],
                [107.2286282, 16.1431985],
                [107.2287683, 16.1432161],
                [107.2288282, 16.1432262],
                [107.2288872, 16.1432351],
                [107.2290198, 16.1432602],
                [107.2291359, 16.1432882],
                [107.2317377, 16.1443204],
                [107.2319954, 16.1444444],
                [107.2324561, 16.1447152],
                [107.2325183, 16.1447549],
                [107.2325726, 16.1447893],
                [107.2326286, 16.1448255],
                [107.2326887, 16.1448605],
                [107.2332579, 16.1451749],
                [107.2335108, 16.1453261],
                [107.2335918, 16.1453678],
                [107.2338318, 16.1454641],
                [107.2340018, 16.1455382],
                [107.2342996, 16.145696],
                [107.234855, 16.1459648],
                [107.2351171, 16.1460421],
                [107.2354279, 16.14617],
                [107.2394089, 16.1469653],
                [107.240003, 16.1470779],
                [107.2413493, 16.1467808],
                [107.2417761, 16.1469521],
                [107.24259, 16.1476212],
                [107.2429434, 16.147546],
                [107.244645, 16.1470791],
                [107.2452358, 16.1468949],
                [107.2454788, 16.1466944],
                [107.2456577, 16.1462376],
                [107.2461586, 16.1462609],
                [107.2467703, 16.1456029],
                [107.2470025, 16.1447143],
                [107.2470493, 16.1440882],
                [107.2470353, 16.1433148],
                [107.2472923, 16.1425405],
                [107.2475328, 16.1421224],
                [107.2481386, 16.1414632],
                [107.2487832, 16.1406057],
                [107.2492457, 16.139928],
                [107.2502485, 16.1394247],
                [107.2509869, 16.1390802],
                [107.2511911, 16.1390583],
                [107.2512995, 16.1389567],
                [107.2515231, 16.1388554],
                [107.2516869, 16.1387521],
                [107.2517464, 16.1387146],
                [107.2522124, 16.1383535],
                [107.2525151, 16.1379943],
                [107.2532008, 16.1371758],
                [107.2536449, 16.1366766],
                [107.2542889, 16.1357794],
                [107.2545319, 16.1355791],
                [107.2551835, 16.1353546],
                [107.2556704, 16.1350328],
                [107.2557729, 16.1350515],
                [107.2558959, 16.1350897],
                [107.2560197, 16.1351874],
                [107.2567834, 16.1358322],
                [107.2573186, 16.1361629],
                [107.2575861, 16.1363183],
                [107.2579145, 16.1364335],
                [107.2587134, 16.1365833],
                [107.2590209, 16.1366592],
                [107.2593891, 16.1366751],
                [107.2600833, 16.1366083],
                [107.2604316, 16.1366839],
                [107.2606778, 16.1367603],
                [107.2610064, 16.1368954],
                [107.2612526, 16.136972],
                [107.2616621, 16.1370269],
                [107.2619074, 16.1370243],
                [107.2624593, 16.1370382],
                [107.2627445, 16.1369363],
                [107.2628213, 16.1368328],
                [107.2633082, 16.1361784],
                [107.2637008, 16.1347499],
                [107.2641577, 16.1335777],
                [107.2642718, 16.1328247],
                [107.2643663, 16.1321511],
                [107.2645206, 16.1313383],
                [107.2650421, 16.1304621],
                [107.2653019, 16.1299451],
                [107.2653592, 16.1295883],
                [107.2654489, 16.1293797],
                [107.2653834, 16.1290242],
                [107.2653822, 16.1289056],
                [107.2653474, 16.1285498],
                [107.2651581, 16.1280771],
                [107.2649974, 16.1274258],
                [107.2650227, 16.1269508],
                [107.2652322, 16.1265034],
                [107.265716, 16.1259047],
                [107.2660595, 16.1253343],
                [107.2661711, 16.124835],
                [107.2661719, 16.1237382],
                [107.266238, 16.1223199],
                [107.2665539, 16.1212287],
                [107.2666894, 16.120774],
                [107.2668667, 16.1198661],
                [107.2671848, 16.1189572],
                [107.2678794, 16.1181575],
                [107.268788, 16.1176273],
                [107.2693906, 16.1173204],
                [107.2705571, 16.116454],
                [107.2713503, 16.1160833],
                [107.2719358, 16.1160092],
                [107.2722873, 16.1160121],
                [107.2725323, 16.1159799],
                [107.2728082, 16.1159768],
                [107.2731155, 16.116033],
                [107.2736693, 16.116205],
                [107.2742551, 16.1164955],
                [107.2748393, 16.1166377],
                [107.2752075, 16.1166636],
                [107.2755746, 16.1166001],
                [107.2763711, 16.1165325],
                [107.2771963, 16.1163158],
                [107.2778367, 16.1160122],
                [107.2781106, 16.1158313],
                [107.2786018, 16.1158854],
                [107.2789101, 16.1160304],
                [107.2791893, 16.1163243],
                [107.2801516, 16.1173526],
                [107.2810139, 16.1176996],
                [107.2821214, 16.1180141],
                [107.2825533, 16.1182468],
                [107.2829539, 16.1184208],
                [107.2837249, 16.1188278],
                [107.2839981, 16.1189043],
                [107.2842444, 16.1186739],
                [107.2845129, 16.1180182],
                [107.2849567, 16.1166187],
                [107.2853436, 16.1156056],
                [107.2855228, 16.1151882],
                [107.286155, 16.1141724],
                [107.2862892, 16.1140101],
                [107.2864272, 16.1138431],
                [107.287305, 16.1128543],
                [107.2876085, 16.1125841],
                [107.2882771, 16.1120723],
                [107.2891641, 16.1118849],
                [107.2895016, 16.1119109],
                [107.2897158, 16.1118789],
                [107.2897768, 16.1118486],
                [107.2899893, 16.1116682],
                [107.2900789, 16.1114596],
                [107.2902284, 16.1111315],
                [107.2902864, 16.1108341],
                [107.2904049, 16.1104767],
                [107.2905843, 16.110089],
                [107.291037, 16.109461],
                [107.2914827, 16.1086908],
                [107.2912175, 16.1080593],
                [107.2909302, 16.1075413],
                [107.2936421, 16.104816],
                [107.2941238, 16.103285],
                [107.2940881, 16.1028403],
                [107.2940836, 16.1024545],
                [107.2941113, 16.1021871],
                [107.2944389, 16.1013527],
                [107.2946424, 16.1004009],
                [107.2953676, 16.0994731],
                [107.2959742, 16.0989028],
                [107.2965518, 16.0984811],
                [107.2972779, 16.0976424],
                [107.2983666, 16.0963546],
                [107.2988844, 16.0960522],
                [107.2999214, 16.0955959],
                [107.3007991, 16.0946072],
                [107.3017407, 16.0938551],
                [107.302164, 16.0933461],
                [107.3024936, 16.0926896],
                [107.3024248, 16.0920376],
                [107.3022941, 16.0913268],
                [107.3028889, 16.0897475],
                [107.3043352, 16.0875657],
                [107.3053898, 16.0859816],
                [107.3055655, 16.0852673],
                [107.3058036, 16.0846417],
                [107.3064077, 16.0838635],
                [107.3068383, 16.0831071],
                [107.307116, 16.0823918],
                [107.3073542, 16.0817761],
                [107.3078172, 16.0811577],
                [107.3082008, 16.0807578],
                [107.3085853, 16.0804373],
                [107.3091535, 16.0800948],
                [107.3099863, 16.0796505],
                [107.3103303, 16.07935],
                [107.3105725, 16.0790903],
                [107.310673, 16.0789507],
                [107.3108351, 16.0788303],
                [107.3109771, 16.0787494],
                [107.3113238, 16.0786864],
                [107.3115897, 16.0787033],
                [107.3119373, 16.0787194],
                [107.3127554, 16.07877],
                [107.3133465, 16.0786448],
                [107.3137733, 16.0784424],
                [107.3139746, 16.0781829],
                [107.3140117, 16.0778463],
                [107.3139263, 16.0775307],
                [107.3137178, 16.0771767],
                [107.3136124, 16.0769009],
                [107.3135691, 16.0766838],
                [107.3135241, 16.0763282],
                [107.3135425, 16.07615],
                [107.3135817, 16.0760111],
                [107.3137222, 16.075792],
                [107.3139444, 16.0755719],
                [107.3141266, 16.0754116],
                [107.3143889, 16.0751319],
                [107.3144882, 16.0748934],
                [107.3145845, 16.0743779],
                [107.3146442, 16.0742388],
                [107.3146834, 16.0740998],
                [107.3149454, 16.0737805],
                [107.3157552, 16.0731189],
                [107.3168241, 16.0719004],
                [107.318079, 16.0708582],
                [107.3185452, 16.0705366],
                [107.3190725, 16.0701945],
                [107.3193361, 16.0700137],
                [107.3196005, 16.0699118],
                [107.3199678, 16.0698684],
                [107.3203166, 16.0699833],
                [107.320603, 16.0699999],
                [107.3220138, 16.0700835],
                [107.3225449, 16.0700579],
                [107.32463, 16.0701342],
                [107.3252021, 16.070128],
                [107.325445, 16.0699276],
                [107.3258081, 16.0695279],
                [107.3260896, 16.0691292],
                [107.3264132, 16.0688486],
                [107.3268805, 16.0686062],
                [107.3274284, 16.0682838],
                [107.3278967, 16.067911],
                [107.3285489, 16.0671988],
                [107.3290409, 16.0665369],
                [107.3293556, 16.0661451],
                [107.3293362, 16.0657619],
                [107.3291689, 16.0650797],
                [107.3292444, 16.0645446],
                [107.3293668, 16.0635324],
                [107.32934, 16.0622289],
                [107.3294769, 16.0616934],
                [107.3295942, 16.061237],
                [107.3296315, 16.0609398],
                [107.3297096, 16.0606224],
                [107.3298092, 16.0604037],
                [107.3300499, 16.0600253],
                [107.3304909, 16.0592885],
                [107.3307937, 16.0589686],
                [107.331321, 16.0586265],
                [107.3320715, 16.0581633],
                [107.332762, 16.0577997],
                [107.3333106, 16.0575167],
                [107.3337556, 16.0571559],
                [107.3340191, 16.0569551],
                [107.3342208, 16.0567353],
                [107.3342804, 16.0565763],
                [107.3342761, 16.0562203],
                [107.3342187, 16.0559503],
                [107.3335149, 16.0550871],
                [107.3336709, 16.0549196],
                [107.3346306, 16.0550888],
                [107.3348148, 16.0551065],
                [107.3350803, 16.0550839],
                [107.3352436, 16.0550821],
                [107.3367354, 16.0551054],
                [107.3374719, 16.0551766],
                [107.3384549, 16.0553637],
                [107.3389064, 16.0555368],
                [107.3395251, 16.0560246],
                [107.3403922, 16.0567867],
                [107.3411323, 16.0571742],
                [107.3426921, 16.0577704],
                [107.3434128, 16.0582373],
                [107.3435595, 16.0585523],
                [107.3436445, 16.0588284],
                [107.3437724, 16.0592819],
                [107.3438371, 16.059578],
                [107.3438606, 16.0598349],
                [107.3438829, 16.0599929],
                [107.3439262, 16.0602102],
                [107.3440509, 16.0603867],
                [107.3441756, 16.0605634],
                [107.3443617, 16.0607592],
                [107.3445886, 16.0609348],
                [107.3451032, 16.0612655],
                [107.3453509, 16.0614804],
                [107.3457643, 16.0618716],
                [107.3460327, 16.062106],
                [107.3461977, 16.0622426],
                [107.3464849, 16.0623384],
                [107.3466284, 16.0623765],
                [107.3474456, 16.0623674],
                [107.3481616, 16.0624387],
                [107.3485094, 16.0624745],
                [107.3488555, 16.0623717],
                [107.3492209, 16.0621698],
                [107.3495857, 16.0619088],
                [107.3498606, 16.0617535],
                [107.3511719, 16.0617066],
                [107.3517618, 16.0617759],
                [107.3521654, 16.0618527],
                [107.3528149, 16.0620209],
                [107.3531496, 16.0621164],
                [107.3537413, 16.0622341],
                [107.354231, 16.0624024],
                [107.3547726, 16.0626198],
                [107.3554931, 16.0627855],
                [107.3560068, 16.0628296],
                [107.3572165, 16.0631141],
                [107.3577578, 16.0633066],
                [107.3581952, 16.0634011],
                [107.3586312, 16.0633714],
                [107.3592953, 16.0630911],
                [107.3598049, 16.0627877],
                [107.3604928, 16.0623582],
                [107.361309, 16.0619273],
                [107.3619719, 16.0615477],
                [107.362483, 16.0613684],
                [107.3638113, 16.0611447],
                [107.364883, 16.0609668],
                [107.3659732, 16.0612798],
                [107.3681317, 16.0626648],
                [107.3693731, 16.063446],
                [107.3699685, 16.0638865],
                [107.3702561, 16.0643304],
                [107.3707286, 16.0652195],
                [107.371329, 16.0660573],
                [107.372494, 16.0679945],
                [107.3729682, 16.0690978],
                [107.3734181, 16.0701702],
                [107.3738125, 16.0709607],
                [107.3744493, 16.0716368],
                [107.3752251, 16.072125],
                [107.3758964, 16.0724653],
                [107.3763881, 16.0728074],
                [107.3772171, 16.0734442],
                [107.3775802, 16.0737631],
                [107.3778379, 16.0741431],
                [107.3780981, 16.0741547],
                [107.3784303, 16.0740268],
                [107.3789624, 16.0734496],
                [107.3792394, 16.0729995],
                [107.3794149, 16.0726497],
                [107.3796143, 16.0723708],
                [107.3798161, 16.0720302],
                [107.3803506, 16.0714718],
                [107.381184, 16.0706475],
                [107.3815035, 16.0702293],
                [107.3820864, 16.0696019],
                [107.3825637, 16.06931],
                [107.3829268, 16.069046],
                [107.3832976, 16.0689007],
                [107.3835964, 16.0687027],
                [107.3845438, 16.0679967],
                [107.3858695, 16.0661565],
                [107.3864756, 16.06533],
                [107.3886885, 16.0625854],
                [107.3891222, 16.0623571],
                [107.3896604, 16.0623014],
                [107.3899427, 16.0622981],
                [107.3903807, 16.0624422],
                [107.3919636, 16.063705],
                [107.3925929, 16.0639091],
                [107.3928877, 16.0639047],
                [107.3934515, 16.0638239],
                [107.3949516, 16.0631058],
                [107.3958637, 16.0630142],
                [107.3972317, 16.0627941],
                [107.3981152, 16.0624082],
                [107.3986785, 16.0623553],
                [107.3991571, 16.0624757],
                [107.4004401, 16.0634103],
                [107.4006829, 16.0637505],
                [107.4008353, 16.0642752],
                [107.4007885, 16.0646483],
                [107.4005913, 16.0653213],
                [107.4006708, 16.0676552],
                [107.4005882, 16.0685019],
                [107.4007277, 16.0691233],
                [107.4012126, 16.0695573],
                [107.4017817, 16.0699029],
                [107.4024374, 16.0704576],
                [107.4027688, 16.0708644],
                [107.4030642, 16.0714165],
                [107.403306, 16.0723081],
                [107.4036218, 16.0740309],
                [107.4038062, 16.0748174],
                [107.4036428, 16.0750787],
                [107.4034978, 16.0752746],
                [107.4031733, 16.0759094],
                [107.4032507, 16.0765248],
                [107.4032043, 16.0769953],
                [107.4032785, 16.0774567],
                [107.4035639, 16.0780966],
                [107.4039958, 16.0784607],
                [107.4044883, 16.0788525],
                [107.4050842, 16.0793178],
                [107.4058103, 16.0799306],
                [107.4064084, 16.0805698],
                [107.4068237, 16.0809625],
                [107.4072119, 16.0812313],
                [107.4076373, 16.081388],
                [107.4081505, 16.0813822],
                [107.4085619, 16.0814273],
                [107.4091018, 16.0815206],
                [107.4097208, 16.0817619],
                [107.4099513, 16.0819448],
                [107.410536, 16.0823464],
                [107.4106505, 16.0824453],
                [107.4109303, 16.0827252],
                [107.4112034, 16.0833847],
                [107.4115712, 16.0841765],
                [107.4117539, 16.0844298],
                [107.4120406, 16.0845492],
                [107.4126503, 16.0842869],
                [107.4130799, 16.0840063],
                [107.414435, 16.0833072],
                [107.4178552, 16.0815956],
                [107.4181216, 16.0813318],
                [107.4182055, 16.0810905],
                [107.4183025, 16.0806174],
                [107.4184679, 16.0802597],
                [107.4188466, 16.0797338],
                [107.4193234, 16.0793405],
                [107.4199841, 16.0788113],
                [107.4203723, 16.0785492],
                [107.4209096, 16.0784188],
                [107.4215387, 16.0783857],
                [107.4220169, 16.0785848],
                [107.4221762, 16.078706],
                [107.4226215, 16.0790211],
                [107.4230758, 16.0792636],
                [107.4237771, 16.0793324],
                [107.424653, 16.0789827],
                [107.4250968, 16.0786612],
                [107.4260103, 16.0780487],
                [107.4266307, 16.077882],
                [107.4273218, 16.0779723],
                [107.429084, 16.0785387],
                [107.4312395, 16.0792434],
                [107.4322378, 16.0798702],
                [107.4345574, 16.0829002],
                [107.4350693, 16.0834464],
                [107.4357001, 16.0832284],
                [107.4362432, 16.0829902],
                [107.4367446, 16.0829001],
                [107.4381302, 16.0826203],
                [107.4388374, 16.0823793],
                [107.4395796, 16.0821969],
                [107.440262, 16.0821208],
                [107.4411093, 16.0822912],
                [107.4423277, 16.0826422],
                [107.4433143, 16.0829187],
                [107.4441383, 16.0839701],
                [107.4455055, 16.0859305],
                [107.445756, 16.0865754],
                [107.4458282, 16.0867617],
                [107.4459516, 16.0878299],
                [107.4461861, 16.088393],
                [107.4463863, 16.0888098],
                [107.4464597, 16.0892448],
                [107.4464519, 16.0897022],
                [107.4462671, 16.0911545],
                [107.4491191, 16.0913831],
                [107.449269, 16.0904016],
                [107.449532, 16.0898236],
                [107.4500596, 16.089286],
                [107.4507678, 16.0886899],
                [107.4514699, 16.0881945],
                [107.4518326, 16.0879078],
                [107.4522929, 16.0879513],
                [107.4527343, 16.0883258],
                [107.4529741, 16.0882809],
                [107.4531464, 16.0880891],
                [107.4532068, 16.0876668],
                [107.4531352, 16.0871827],
                [107.4528429, 16.0864902],
                [107.4527084, 16.0862176],
                [107.4526162, 16.0858181],
                [107.4526504, 16.0855133],
                [107.4533142, 16.0852075],
                [107.4543604, 16.0846737],
                [107.4563829, 16.0831722],
                [107.4566866, 16.082821],
                [107.4570645, 16.0822452],
                [107.4572901, 16.0817953],
                [107.4573864, 16.0812725],
                [107.4574824, 16.0807247],
                [107.4576764, 16.0798032],
                [107.4579644, 16.0792161],
                [107.4583693, 16.0787393],
                [107.4585196, 16.0784395],
                [107.4588234, 16.078088],
                [107.458897, 16.0778141],
                [107.458893, 16.0774911],
                [107.4588393, 16.0772929],
                [107.4588383, 16.0772184],
                [107.4590084, 16.0769267],
                [107.4591098, 16.0768261],
                [107.4596779, 16.0764088],
                [107.4598168, 16.0761543],
                [107.4597962, 16.075625],
                [107.4597905, 16.0751614],
                [107.4599755, 16.0747084],
                [107.4601766, 16.0743583],
                [107.4605585, 16.0741054],
                [107.4608106, 16.0737297],
                [107.461138, 16.0732043],
                [107.4611624, 16.0731046],
                [107.4611574, 16.0727072],
                [107.4611003, 16.0722358],
                [107.4607854, 16.0716679],
                [107.4605237, 16.0712733],
                [107.4599811, 16.0709815],
                [107.4589723, 16.0703719],
                [107.4562149, 16.0684533],
                [107.4545593, 16.0674288],
                [107.4540664, 16.067012],
                [107.4538314, 16.0666917],
                [107.4531005, 16.0657063],
                [107.4529558, 16.0654222],
                [107.4529015, 16.0651744],
                [107.4526666, 16.0648542],
                [107.4523552, 16.0645844],
                [107.4518123, 16.0642675],
                [107.4512455, 16.0641002],
                [107.4506524, 16.0638833],
                [107.4502112, 16.0634909],
                [107.4500269, 16.0631203],
                [107.4497128, 16.062627],
                [107.4494764, 16.0621825],
                [107.4492918, 16.0617871],
                [107.4492616, 16.0614147],
                [107.4492561, 16.0609676],
                [107.4493346, 16.060035],
                [107.4494299, 16.0594376],
                [107.4494502, 16.0589901],
                [107.4494448, 16.0585429],
                [107.4492583, 16.0579985],
                [107.4489445, 16.05753],
                [107.4480806, 16.0561485],
                [107.4465303, 16.0544859],
                [107.4463405, 16.0543394],
                [107.4461669, 16.0541156],
                [107.4460089, 16.0538079],
                [107.4460135, 16.0535098],
                [107.4460326, 16.0530486],
                [107.445857, 16.0523897],
                [107.4458887, 16.0520408],
                [107.4460164, 16.0517294],
                [107.4461108, 16.0516424],
                [107.4464177, 16.05136],
                [107.4468974, 16.0496526],
                [107.4469641, 16.0488069],
                [107.4469833, 16.048285],
                [107.4469754, 16.0476391],
                [107.4469431, 16.0470929],
                [107.4468932, 16.0461618],
                [107.4472053, 16.0443942],
                [107.4474272, 16.0436463],
                [107.4474208, 16.0431246],
                [107.4469407, 16.0416393],
                [107.4466649, 16.0411332],
                [107.4466604, 16.0407604],
                [107.44668, 16.0402634],
                [107.4467253, 16.0397659],
                [107.446848, 16.0392925],
                [107.4469466, 16.0389683],
                [107.4470968, 16.0386684],
                [107.4474513, 16.0382669],
                [107.4481404, 16.0379361],
                [107.4488952, 16.0377411],
                [107.4508425, 16.0374704],
                [107.4528007, 16.0372065],
                [107.4537171, 16.0369714],
                [107.4543895, 16.0364827],
                [107.4549537, 16.0360214],
                [107.4564511, 16.0343501],
                [107.4570829, 16.0335477],
                [107.457707, 16.0324495],
                [107.457802, 16.0320854],
                [107.457787, 16.031316],
                [107.4578067, 16.030819],
                [107.4578523, 16.0303712],
                [107.4580009, 16.0299223],
                [107.4582159, 16.0293475],
                [107.4584815, 16.0290042],
                [107.4589253, 16.0286044],
                [107.4595122, 16.0283243],
                [107.4599603, 16.0281565],
                [107.4606237, 16.0282042],
                [107.4614725, 16.028367],
                [107.462274, 16.0285138],
                [107.463051, 16.0285571],
                [107.463626, 16.0283731],
                [107.4647136, 16.0275753],
                [107.4654855, 16.0263659],
                [107.4663638, 16.0255315],
                [107.4666627, 16.0253619],
                [107.467079, 16.0251393],
                [107.467218, 16.0250651],
                [107.4672612, 16.0246607],
                [107.4677628, 16.0248659],
                [107.4678647, 16.0250515],
                [107.467913, 16.0252835],
                [107.467954, 16.025369],
                [107.4680563, 16.0255293],
                [107.4682548, 16.0257357],
                [107.4684126, 16.025846],
                [107.4688833, 16.0260576],
                [107.4692459, 16.0262767],
                [107.4694739, 16.0264542],
                [107.4697798, 16.0267581],
                [107.469896, 16.0272204],
                [107.4700206, 16.0275142],
                [107.4701713, 16.0277773],
                [107.4703411, 16.0280089],
                [107.4705356, 16.0282218],
                [107.4709591, 16.0285776],
                [107.4711979, 16.0288381],
                [107.4713797, 16.0291316],
                [107.471558, 16.0295759],
                [107.47177, 16.0299341],
                [107.4720362, 16.0302464],
                [107.4724175, 16.0305982],
                [107.4726283, 16.0308931],
                [107.4727191, 16.031084],
                [107.4728061, 16.0313872],
                [107.4729549, 16.0325683],
                [107.4729944, 16.0326649],
                [107.4730956, 16.0327841],
                [107.4732321, 16.0328591],
                [107.4752462, 16.033404],
                [107.4754672, 16.0334932],
                [107.4757316, 16.0336885],
                [107.4765444, 16.0346207],
                [107.4769418, 16.0349674],
                [107.4773476, 16.0352277],
                [107.4777899, 16.0354392],
                [107.4781351, 16.0355672],
                [107.4784917, 16.0356622],
                [107.4787957, 16.0357154],
                [107.4791865, 16.0357526],
                [107.4793536, 16.0357449],
                [107.4795226, 16.0357035],
                [107.4796854, 16.0356263],
                [107.4799677, 16.0353923],
                [107.4802099, 16.035252],
                [107.4808672, 16.0350697],
                [107.4811701, 16.0349177],
                [107.4814475, 16.0347084],
                [107.4815874, 16.0346315],
                [107.4818225, 16.0345517],
                [107.4820415, 16.0345222],
                [107.4822156, 16.0345494],
                [107.4823316, 16.0346114],
                [107.4825995, 16.0348949],
                [107.4828231, 16.0350287],
                [107.4830721, 16.0350948],
                [107.4833708, 16.0351068],
                [107.4836207, 16.035143],
                [107.483867, 16.0352102],
                [107.4841052, 16.0353084],
                [107.4843281, 16.0354353],
                [107.4843792, 16.0355222],
                [107.4843969, 16.0356107],
                [107.4843825, 16.0358887],
                [107.4844155, 16.0360844],
                [107.4844864, 16.0362705],
                [107.4845806, 16.0364246],
                [107.4847694, 16.0366213],
                [107.4850041, 16.0367655],
                [107.4853635, 16.0370336],
                [107.4854323, 16.0371539],
                [107.4854482, 16.0372985],
                [107.4853092, 16.0377334],
                [107.4852809, 16.0380509],
                [107.4853042, 16.03828],
                [107.4853635, 16.0385029],
                [107.4854249, 16.0386392],
                [107.4855137, 16.0387607],
                [107.4856613, 16.0388871],
                [107.4862942, 16.0392892],
                [107.4867026, 16.0396151],
                [107.486946, 16.0397609],
                [107.487046, 16.0399685],
                [107.4872398, 16.0402509],
                [107.4874753, 16.0404896],
                [107.4876665, 16.0406347],
                [107.4878875, 16.040764],
                [107.4882469, 16.0409041],
                [107.4885917, 16.0409717],
                [107.4889023, 16.0409759],
                [107.4891131, 16.0409026],
                [107.4894269, 16.040664],
                [107.4896663, 16.0405655],
                [107.4898392, 16.0405377],
                [107.4908094, 16.040532],
                [107.4910538, 16.0404905],
                [107.4912912, 16.0404208],
                [107.4916382, 16.0402586],
                [107.4920574, 16.039971],
                [107.4924244, 16.039772],
                [107.4939088, 16.039172],
                [107.4941128, 16.0391268],
                [107.4943026, 16.0391156],
                [107.4949887, 16.0391985],
                [107.4953949, 16.0391835],
                [107.4960118, 16.0390804],
                [107.4962639, 16.0389678],
                [107.4964103, 16.0388547],
                [107.4965323, 16.0387148],
                [107.4967671, 16.0383135],
                [107.4969578, 16.0380568],
                [107.4970039, 16.0379378],
                [107.497015, 16.0377347],
                [107.4970632, 16.0375438],
                [107.4977177, 16.0362499],
                [107.4978954, 16.0357304],
                [107.4979928, 16.0353389],
                [107.4981335, 16.034448],
                [107.4984201, 16.0343424],
                [107.4985904, 16.0342401],
                [107.4987398, 16.0341169],
                [107.4998474, 16.0327595],
                [107.5002338, 16.0324172],
                [107.5005126, 16.032213],
                [107.5011027, 16.0318727],
                [107.5017249, 16.0318108],
                [107.5022073, 16.0313695],
                [107.5025511, 16.0310993],
                [107.5029342, 16.0308346],
                [107.5038275, 16.030278],
                [107.5045252, 16.0297485],
                [107.5049724, 16.0296854],
                [107.5051253, 16.0295822],
                [107.5052016, 16.0294871],
                [107.5052521, 16.0293773],
                [107.5052743, 16.0292468],
                [107.5052395, 16.0290521],
                [107.5051733, 16.0289362],
                [107.5048305, 16.0286013],
                [107.5045037, 16.0282043],
                [107.5044599, 16.028061],
                [107.5044637, 16.0279565],
                [107.5044978, 16.0278523],
                [107.5045601, 16.0277609],
                [107.5047302, 16.0277134],
                [107.50489, 16.0276398],
                [107.5051292, 16.0274566],
                [107.5054479, 16.0270881],
                [107.5055912, 16.0267616],
                [107.505818, 16.0253351],
                [107.5058806, 16.0250749],
                [107.5060251, 16.0247332],
                [107.5063437, 16.0242781],
                [107.5063759, 16.024147],
                [107.506375, 16.0240317],
                [107.5062954, 16.0236239],
                [107.5063048, 16.0230929],
                [107.5062418, 16.0224941],
                [107.5062807, 16.022279],
                [107.5063491, 16.0221487],
                [107.5064415, 16.0220425],
                [107.506556, 16.0219586],
                [107.5069874, 16.0217877],
                [107.5071137, 16.0216952],
                [107.5072203, 16.0215823],
                [107.5073039, 16.0214526],
                [107.5074817, 16.0210698],
                [107.5076187, 16.0208607],
                [107.5092459, 16.0186735],
                [107.5098873, 16.0183018],
                [107.5102866, 16.0180135],
                [107.510794, 16.0175669],
                [107.511102, 16.01724],
                [107.5120139, 16.0166729],
                [107.513237, 16.0160232],
                [107.5153238, 16.0156004],
                [107.5170028, 16.0149765],
                [107.5181166, 16.0149006],
                [107.5193783, 16.014916],
                [107.5196743, 16.0149352],
                [107.5199551, 16.0149895],
                [107.5202281, 16.0150741],
                [107.5204897, 16.0151879],
                [107.5208417, 16.0153826],
                [107.5211887, 16.0155854],
                [107.5215304, 16.0157963],
                [107.5218428, 16.0159993],
                [107.5224531, 16.0164254],
                [107.5226471, 16.016472],
                [107.52304, 16.0165438],
                [107.5232383, 16.0165689],
                [107.5236371, 16.0165974],
                [107.5240403, 16.0165966],
                [107.5242432, 16.016585],
                [107.5246469, 16.0165393],
                [107.5250457, 16.0164638],
                [107.5252426, 16.0164151],
                [107.5262833, 16.0159407],
                [107.5267508, 16.0159125],
                [107.5272176, 16.0158746],
                [107.5276834, 16.0158272],
                [107.5281481, 16.0157701],
                [107.5286114, 16.0157035],
                [107.5294444, 16.015559],
                [107.5298585, 16.0154752],
                [107.5306814, 16.0152844],
                [107.5310898, 16.0151776],
                [107.5333911, 16.0139143],
                [107.5342011, 16.0133007],
                [107.5348587, 16.013207],
                [107.5351853, 16.0131472],
                [107.5358357, 16.0130015],
                [107.5361589, 16.0129155],
                [107.5364796, 16.012821],
                [107.5389969, 16.0120013],
                [107.540499, 16.0118466],
                [107.5448441, 16.0105988],
                [107.5498867, 16.0110732],
                [107.5501177, 16.0118882],
                [107.5502207, 16.0122988],
                [107.5504017, 16.0131254],
                [107.5505329, 16.0138568],
                [107.5505887, 16.0142241],
                [107.5506806, 16.014961],
                [107.5527406, 16.0149713],
                [107.5541997, 16.0140741],
                [107.5556052, 16.0162397],
                [107.5577509, 16.0178072],
                [107.5587165, 16.0197769],
                [107.560519, 16.0220868],
                [107.560553, 16.0221929],
                [107.5605776, 16.0223014],
                [107.5605927, 16.0224115],
                [107.5605982, 16.0225224],
                [107.5605941, 16.0226333],
                [107.5605617, 16.0228335],
                [107.5604981, 16.0230266],
                [107.5604046, 16.0232082],
                [107.5603473, 16.0232933],
                [107.5583142, 16.0257991],
                [107.5592744, 16.0267478],
                [107.5599021, 16.0286039],
                [107.5605941, 16.0296041],
                [107.5606316, 16.0309343],
                [107.5601986, 16.0311699],
                [107.5597504, 16.0313775],
                [107.5592713, 16.0315625],
                [107.5590182, 16.0316459],
                [107.5587621, 16.0317206],
                [107.5586352, 16.0317728],
                [107.5583915, 16.0318971],
                [107.5581637, 16.0320468],
                [107.557943, 16.0322307],
                [107.5578361, 16.0323375],
                [107.5577363, 16.0324505],
                [107.557644, 16.0325693],
                [107.5575596, 16.0326933],
                [107.5574834, 16.0328222],
                [107.5574157, 16.0329554],
                [107.5573164, 16.0332102],
                [107.5571419, 16.0337279],
                [107.5569999, 16.0342547],
                [107.5569364, 16.0345451],
                [107.5568827, 16.0348373],
                [107.556839, 16.0351311],
                [107.5567166, 16.0355591],
                [107.5566425, 16.0357693],
                [107.556482, 16.0361535],
                [107.5563978, 16.0363276],
                [107.5562114, 16.0366673],
                [107.5561094, 16.0368325],
                [107.5548488, 16.0380286],
                [107.5538242, 16.040029],
                [107.5523221, 16.0416453],
                [107.5524257, 16.041883],
                [107.5525571, 16.0422529],
                [107.5526115, 16.0424413],
                [107.5526976, 16.0428233],
                [107.5527315, 16.043033],
                [107.5527717, 16.0434555],
                [107.552778, 16.0436676],
                [107.5527628, 16.0440916],
                [107.5527738, 16.0441538],
                [107.5527911, 16.0442147],
                [107.5528437, 16.0443302],
                [107.5529241, 16.0444396],
                [107.5530328, 16.0445355],
                [107.5531607, 16.0446062],
                [107.5569093, 16.0459499],
                [107.5570355, 16.0461404],
                [107.5573095, 16.0465074],
                [107.557457, 16.0466833],
                [107.5577637, 16.0470107],
                [107.5579219, 16.0471623],
                [107.5582546, 16.0474491],
                [107.558932, 16.0479587],
                [107.5591769, 16.0481544],
                [107.5594171, 16.0483553],
                [107.5598828, 16.0487728],
                [107.560139, 16.0490194],
                [107.5603885, 16.0492724],
                [107.560631, 16.0495316],
                [107.5608664, 16.0497967],
                [107.5610946, 16.0500676],
                [107.5613153, 16.0503441],
                [107.5617762, 16.0505432],
                [107.5627078, 16.0509192],
                [107.5631783, 16.0510961],
                [107.5641281, 16.0514274],
                [107.5646072, 16.0515817],
                [107.5655441, 16.0518593],
                [107.566016, 16.0519873],
                [107.5669657, 16.0522213],
                [107.5687093, 16.0536605],
                [107.5703444, 16.0542446],
                [107.5715319, 16.0551466],
                [107.5716265, 16.0551967],
                [107.5717254, 16.0552384],
                [107.5718277, 16.0552715],
                [107.5719327, 16.0552957],
                [107.5720396, 16.0553109],
                [107.5721474, 16.0553168],
                [107.5722554, 16.0553135],
                [107.5723611, 16.0553011],
                [107.5724654, 16.0552799],
                [107.5725673, 16.05525],
                [107.5726661, 16.0552115],
                [107.5727609, 16.0551649],
                [107.5728512, 16.0551104],
                [107.5729361, 16.0550486],
                [107.5739483, 16.0541315],
                [107.579085, 16.0547364],
                [107.5798835, 16.0549283],
                [107.5814752, 16.0553316],
                [107.5831048, 16.0557733],
                [107.5847712, 16.0562557],
                [107.5864284, 16.0567666],
                [107.5898287, 16.0566136],
                [107.5923392, 16.0570586],
                [107.5958011, 16.0562243],
                [107.5961382, 16.0564577],
                [107.5964884, 16.0566727],
                [107.5968501, 16.0568685],
                [107.5972227, 16.0570447],
                [107.5999936, 16.057003],
                [107.6016768, 16.0577877],
                [107.6033731, 16.0585458],
                [107.605009, 16.0592467],
                [107.6057945, 16.0595728],
                [107.6073731, 16.0602081],
                [107.6105269, 16.0609909],
                [107.6195061, 16.0639752],
                [107.6209706, 16.06214],
                [107.6212479, 16.061753],
                [107.6213778, 16.0615539],
                [107.6216213, 16.0611421],
                [107.6218414, 16.060714],
                [107.6221566, 16.0600022],
                [107.6222731, 16.0597593],
                [107.6225236, 16.0592815],
                [107.6226673, 16.0590304],
                [107.622974, 16.0585387],
                [107.6231369, 16.0582986],
                [107.6234812, 16.0578304],
                [107.6243931, 16.0561614],
                [107.624572, 16.0557775],
                [107.6246501, 16.0555809],
                [107.6247847, 16.0551732],
                [107.6248406, 16.0549624],
                [107.6249265, 16.0545353],
                [107.6249564, 16.0543197],
                [107.6250985, 16.0529381],
                [107.6253238, 16.051407],
                [107.6252916, 16.0501852],
                [107.6257718, 16.0491232],
                [107.6257963, 16.0489539],
                [107.6257982, 16.0488684],
                [107.6257793, 16.0486871],
                [107.6257254, 16.0485008],
                [107.6252139, 16.0473034],
                [107.6252246, 16.0463651],
                [107.6252128, 16.0462655],
                [107.6251918, 16.0461673],
                [107.6251617, 16.0460714],
                [107.6250812, 16.0458995],
                [107.6249793, 16.0457524],
                [107.6249195, 16.0456852],
                [107.624495, 16.045267],
                [107.6243248, 16.0451323],
                [107.6241611, 16.0449905],
                [107.6240041, 16.0448417],
                [107.6238543, 16.0446863],
                [107.6235882, 16.0443714],
                [107.6233611, 16.0440511],
                [107.623258, 16.0438846],
                [107.6230736, 16.0435399],
                [107.6229191, 16.0431819],
                [107.6224673, 16.0418334],
                [107.6224275, 16.0416933],
                [107.6223241, 16.0414205],
                [107.6221902, 16.0411603],
                [107.6220274, 16.0409157],
                [107.6218427, 16.0406953],
                [107.6216344, 16.0404952],
                [107.6214051, 16.0403177],
                [107.620579, 16.03973],
                [107.6204567, 16.0395909],
                [107.6203421, 16.0394459],
                [107.6202353, 16.0392955],
                [107.6201367, 16.03914],
                [107.6200465, 16.0389799],
                [107.619965, 16.0388155],
                [107.6198923, 16.0386473],
                [107.6198285, 16.0384748],
                [107.6197739, 16.0382993],
                [107.6197289, 16.0381214],
                [107.6196934, 16.0379414],
                [107.6196677, 16.03776],
                [107.6196517, 16.0375775],
                [107.6196331, 16.0370543],
                [107.6196298, 16.0367139],
                [107.619651, 16.0360334],
                [107.6196997, 16.035439],
                [107.6197767, 16.0348474],
                [107.6199125, 16.0340781],
                [107.6199511, 16.0338979],
                [107.6200517, 16.0335427],
                [107.6201134, 16.0333685],
                [107.6202592, 16.0330281],
                [107.6204314, 16.0327048],
                [107.6206277, 16.0324003],
                [107.6208491, 16.0321119],
                [107.621094, 16.0318418],
                [107.6213028, 16.0316877],
                [107.6215283, 16.0315573],
                [107.6217705, 16.0314512],
                [107.6220264, 16.0313716],
                [107.6222902, 16.031321],
                [107.6224362, 16.0313061],
                [107.622583, 16.0313002],
                [107.6227297, 16.0313035],
                [107.622876, 16.0313159],
                [107.6230211, 16.0313374],
                [107.6231645, 16.0313678],
                [107.6233055, 16.0314071],
                [107.6234436, 16.0314551],
                [107.624259, 16.0316871],
                [107.6244879, 16.0317283],
                [107.6247183, 16.0317604],
                [107.6249499, 16.0317834],
                [107.6251824, 16.0317972],
                [107.6254152, 16.0318019],
                [107.625648, 16.0317974],
                [107.626106, 16.0317617],
                [107.6265535, 16.0316916],
                [107.6269938, 16.0315875],
                [107.6272103, 16.0315228],
                [107.6276202, 16.0313758],
                [107.6280046, 16.0312094],
                [107.6281925, 16.0311174],
                [107.6285681, 16.0309102],
                [107.6288465, 16.0307361],
                [107.6289473, 16.0306817],
                [107.6291604, 16.0305959],
                [107.6292715, 16.0305651],
                [107.6294989, 16.0305284],
                [107.6297291, 16.0305254],
                [107.629957, 16.0305562],
                [107.6301774, 16.0306199],
                [107.6303852, 16.0307152],
                [107.6305682, 16.030778],
                [107.630754, 16.0308329],
                [107.6311322, 16.0309189],
                [107.6315182, 16.0309727],
                [107.6317134, 16.0309872],
                [107.6321048, 16.0309913],
                [107.6323003, 16.0309807],
                [107.6334643, 16.0303053],
                [107.6395798, 16.027583],
                [107.6397933, 16.0274846],
                [107.6400024, 16.0273778],
                [107.6402067, 16.0272627],
                [107.6404059, 16.0271396],
                [107.6407562, 16.0268941],
                [107.641086, 16.0266237],
                [107.6414712, 16.0262631],
                [107.6419441, 16.0258462],
                [107.6424373, 16.0254515],
                [107.6426911, 16.0252629],
                [107.6432662, 16.0248684],
                [107.6435617, 16.0246823],
                [107.6441676, 16.0243328],
                [107.6444775, 16.0241698],
                [107.6448354, 16.0240115],
                [107.6450186, 16.0239418],
                [107.6452044, 16.0238785],
                [107.6455729, 16.0237739],
                [107.6459482, 16.0236946],
                [107.6461378, 16.0236645],
                [107.6479214, 16.0232585],
                [107.6480747, 16.0230613],
                [107.6482058, 16.0228499],
                [107.6483116, 16.0226302],
                [107.6484334, 16.0223043],
                [107.6485327, 16.0221189],
                [107.6485916, 16.0220313],
                [107.6498473, 16.0204987],
                [107.6504427, 16.0195036],
                [107.6505406, 16.0194043],
                [107.6505964, 16.0193617],
                [107.650719, 16.0192922],
                [107.6508551, 16.0192452],
                [107.650926, 16.0192311],
                [107.6510704, 16.0192226],
                [107.6548201, 16.0193592],
                [107.6554263, 16.0194005],
                [107.6561934, 16.0194314],
                [107.6592833, 16.01922],
                [107.6606244, 16.019122],
                [107.6609167, 16.0190843],
                [107.6612082, 16.0190414],
                [107.6614988, 16.0189931],
                [107.661887, 16.0189202],
                [107.6622732, 16.0188378],
                [107.662657, 16.0187461],
                [107.6630384, 16.0186451],
                [107.6636714, 16.0184672],
                [107.6637729, 16.018427],
                [107.6638716, 16.0183806],
                [107.6639669, 16.0183282],
                [107.6640586, 16.01827],
                [107.6641461, 16.0182062],
                [107.6642293, 16.0181372],
                [107.6643087, 16.0180621],
                [107.6643828, 16.0179822],
                [107.6644514, 16.0178979],
                [107.6645141, 16.0178094],
                [107.6645707, 16.0177172],
                [107.6646209, 16.0176216],
                [107.6649642, 16.016941],
                [107.6650383, 16.0168383],
                [107.6651188, 16.0167402],
                [107.6652056, 16.0166471],
                [107.665296, 16.0165613],
                [107.6653916, 16.016481],
                [107.6654921, 16.0164063],
                [107.6655972, 16.0163377],
                [107.6658573, 16.0161368],
                [107.6661113, 16.0159288],
                [107.6663589, 16.0157138],
                [107.6666057, 16.0154869],
                [107.6668453, 16.0152531],
                [107.6670778, 16.0150126],
                [107.6693094, 16.0128366],
                [107.6715732, 16.0121766],
                [107.6716634, 16.0121444],
                [107.6717504, 16.0121047],
                [107.6718334, 16.0120578],
                [107.671912, 16.0120043],
                [107.6719853, 16.0119443],
                [107.672053, 16.0118785],
                [107.6721144, 16.0118072],
                [107.6721692, 16.0117311],
                [107.6722169, 16.0116507],
                [107.6722578, 16.0115649],
                [107.6722907, 16.011476],
                [107.6723152, 16.0113846],
                [107.6723312, 16.0112914],
                [107.6723386, 16.0111973],
                [107.6723372, 16.0111029],
                [107.6723271, 16.011009],
                [107.6723084, 16.0109164],
                [107.6722813, 16.0108257],
                [107.6720023, 16.0098047],
                [107.671975, 16.0096801],
                [107.6719569, 16.0095539],
                [107.6719481, 16.0094268],
                [107.6719487, 16.0092994],
                [107.6719587, 16.0091709],
                [107.6719782, 16.0090434],
                [107.6720071, 16.0089176],
                [107.6720452, 16.0087941],
                [107.672925, 16.0062777],
                [107.6731724, 16.0062308],
                [107.6734181, 16.0061758],
                [107.6736616, 16.0061129],
                [107.6739028, 16.006042],
                [107.6741413, 16.0059633],
                [107.674377, 16.0058768],
                [107.6746094, 16.0057827],
                [107.6748407, 16.00568],
                [107.6750682, 16.0055697],
                [107.6752916, 16.005452],
                [107.6755107, 16.005327],
                [107.67562, 16.005264],
                [107.6757252, 16.0051949],
                [107.6758319, 16.0051154],
                [107.6759334, 16.0050298],
                [107.6760291, 16.0049383],
                [107.6761188, 16.0048413],
                [107.6762021, 16.0047391],
                [107.6762787, 16.0046323],
                [107.6763483, 16.0045211],
                [107.6764107, 16.0044059],
                [107.6764655, 16.0042873],
                [107.676487, 16.0008789],
                [107.6763797, 16.0003116],
                [107.6763709, 16.0002396],
                [107.6763709, 16.0001671],
                [107.6763797, 16.0000951],
                [107.676397, 16.0000248],
                [107.6764227, 15.9999569],
                [107.6764564, 15.9998924],
                [107.6764977, 15.9998321],
                [107.6765925, 15.9997336],
                [107.6766928, 15.9996403],
                [107.6767983, 15.9995523],
                [107.6769086, 15.9994701],
                [107.6770234, 15.9993938],
                [107.6771527, 15.9993179],
                [107.6772865, 15.9992495],
                [107.6774242, 15.9991889],
                [107.6775654, 15.9991362],
                [107.6777097, 15.9990917],
                [107.6778564, 15.9990554],
                [107.6780051, 15.9990276],
                [107.6782196, 15.9988969],
                [107.6784388, 15.9987736],
                [107.6786625, 15.9986579],
                [107.6788902, 15.9985498],
                [107.6791218, 15.9984496],
                [107.6793569, 15.9983573],
                [107.6795999, 15.9982715],
                [107.679846, 15.9981942],
                [107.6800948, 15.9981256],
                [107.6803461, 15.9980656],
                [107.6805994, 15.9980143],
                [107.6808545, 15.9979719],
                [107.681111, 15.9979384],
                [107.6813686, 15.9979138],
                [107.681912, 15.9978385],
                [107.6824544, 15.9977567],
                [107.6829957, 15.9976683],
                [107.6835358, 15.9975735],
                [107.6840284, 15.997481],
                [107.6845199, 15.9973832],
                [107.6850102, 15.9972799],
                [107.6854992, 15.9971712],
                [107.6952624, 15.9950158],
                [107.6954811, 15.9949842],
                [107.6957008, 15.9949599],
                [107.6959213, 15.9949429],
                [107.6961422, 15.9949332],
                [107.6963841, 15.9949311],
                [107.6966259, 15.9949377],
                [107.6968672, 15.9949532],
                [107.6971078, 15.9949773],
                [107.6973472, 15.9950102],
                [107.6975852, 15.9950518],
                [107.6978935, 15.9950985],
                [107.6982029, 15.9951373],
                [107.6985133, 15.9951681],
                [107.6988244, 15.9951911],
                [107.6997529, 15.9952342],
                [107.7006818, 15.9952681],
                [107.701611, 15.9952928],
                [107.7025404, 15.9953084],
                [107.70347, 15.9953148],
                [107.7043391, 15.9953125],
                [107.7052082, 15.9953022],
                [107.7060771, 15.9952839],
                [107.7062528, 15.9952973],
                [107.7064276, 15.9953195],
                [107.7066009, 15.9953506],
                [107.7067723, 15.9953905],
                [107.7069413, 15.9954389],
                [107.7071073, 15.9954958],
                [107.70727, 15.9955611],
                [107.7074289, 15.9956345],
                [107.7075801, 15.995714],
                [107.7077269, 15.9958008],
                [107.7078688, 15.9958948],
                [107.7080055, 15.9959957],
                [107.7081366, 15.9961032],
                [107.7082617, 15.9962171],
                [107.7083806, 15.996337],
                [107.7084929, 15.9964627],
                [107.7085984, 15.9965937],
                [107.7112269, 15.9996671],
                [107.7148103, 16.00108],
                [107.7175569, 16.002627],
                [107.7177637, 16.002766],
                [107.7179652, 16.0029122],
                [107.718161, 16.0030653],
                [107.7183509, 16.0032251],
                [107.7185259, 16.0033832],
                [107.7186951, 16.0035471],
                [107.7188584, 16.0037164],
                [107.7190155, 16.003891],
                [107.7191663, 16.0040708],
                [107.7203464, 16.0055352],
                [107.7240586, 16.0076803],
                [107.7241754, 16.0077695],
                [107.7242868, 16.0078649],
                [107.7243923, 16.0079663],
                [107.7244916, 16.0080733],
                [107.7245843, 16.0081856],
                [107.7246713, 16.0083043],
                [107.724751, 16.0084277],
                [107.7248232, 16.0085553],
                [107.7248875, 16.0086867],
                [107.7249439, 16.0088214],
                [107.724992, 16.0089591],
                [107.7250429, 16.0091616],
                [107.725101, 16.0093622],
                [107.7251662, 16.0095609],
                [107.7252384, 16.0097573],
                [107.7253175, 16.0099512],
                [107.7254035, 16.0101424],
                [107.7254963, 16.0103307],
                [107.7256012, 16.0105255],
                [107.7257133, 16.0107167],
                [107.7258325, 16.0109038],
                [107.7259586, 16.0110866],
                [107.7260915, 16.011265],
                [107.726231, 16.0114387],
                [107.7263769, 16.0116074],
                [107.726529, 16.0117709],
                [107.7266872, 16.0119291],
                [107.7274032, 16.0134536],
                [107.7268964, 16.0150899],
                [107.7268637, 16.0152127],
                [107.7268222, 16.0154629],
                [107.7268137, 16.0155894],
                [107.7268213, 16.0158427],
                [107.7268364, 16.0159629],
                [107.7268888, 16.0161997],
                [107.7269258, 16.0163154],
                [107.727021, 16.0165395],
                [107.7270788, 16.0166471],
                [107.7280927, 16.0181888],
                [107.7281284, 16.018302],
                [107.7281547, 16.0184175],
                [107.7281715, 16.0185347],
                [107.7281763, 16.0187635],
                [107.7281655, 16.0188737],
                [107.7280685, 16.0193815],
                [107.72804, 16.0195825],
                [107.7280184, 16.0197843],
                [107.7279961, 16.0201894],
                [107.7279957, 16.0204071],
                [107.728019, 16.020842],
                [107.7280427, 16.0210586],
                [107.7281141, 16.0214887],
                [107.7281209, 16.0216382],
                [107.7281155, 16.0219375],
                [107.7280799, 16.0222697],
                [107.728047, 16.0224513],
                [107.7277386, 16.0236336],
                [107.7275669, 16.0243967],
                [107.7274533, 16.0245377],
                [107.7273157, 16.0246699],
                [107.7271539, 16.0247885],
                [107.7264297, 16.0253557],
                [107.7262575, 16.0255392],
                [107.7261115, 16.0257426],
                [107.7259942, 16.0259625],
                [107.7259052, 16.0262028],
                [107.7258727, 16.0263306],
                [107.7258493, 16.0264603],
                [107.7258354, 16.0265912],
                [107.7258309, 16.0267226],
                [107.7258359, 16.0268541],
                [107.7258981, 16.0273349],
                [107.7259543, 16.0276837],
                [107.7260189, 16.0280311],
                [107.7261635, 16.0286834],
                [107.7263266, 16.0292916],
                [107.7265155, 16.0298929],
                [107.7265584, 16.0303724],
                [107.7268837, 16.0304894],
                [107.7275401, 16.0307075],
                [107.7282233, 16.0309097],
                [107.7285676, 16.0310022],
                [107.7287417, 16.0310412],
                [107.7290667, 16.0311398],
                [107.7292172, 16.0311975],
                [107.7295089, 16.0313334],
                [107.7296494, 16.0314112],
                [107.7299179, 16.0315857],
                [107.7300453, 16.0316819],
                [107.7305817, 16.0328523],
                [107.7307174, 16.0330425],
                [107.731011, 16.0334073],
                [107.7313328, 16.0337494],
                [107.7314975, 16.0339057],
                [107.7318446, 16.0342002],
                [107.7322133, 16.0344692],
                [107.7324052, 16.0345937],
                [107.7328026, 16.0348218],
                [107.7340472, 16.0361829],
                [107.7343942, 16.0362043],
                [107.7345667, 16.036225],
                [107.7349085, 16.0362866],
                [107.7352521, 16.0363769],
                [107.7354247, 16.0364337],
                [107.7357617, 16.0365681],
                [107.7360856, 16.0367294],
                [107.7381563, 16.0380183],
                [107.7382494, 16.0380847],
                [107.7384192, 16.0382363],
                [107.738564, 16.0384101],
                [107.7386265, 16.0385049],
                [107.7386815, 16.0386039],
                [107.7387679, 16.0388123],
                [107.7388966, 16.039266],
                [107.7400124, 16.0390804],
                [107.7402875, 16.0390519],
                [107.7405642, 16.0390554],
                [107.7408385, 16.0390907],
                [107.7409781, 16.0391213],
                [107.7412501, 16.0392071],
                [107.7413814, 16.039262],
                [107.741632, 16.0393948],
                [107.7430379, 16.0403383],
                [107.7437836, 16.0414468],
                [107.7478552, 16.0426583],
                [107.7484683, 16.042791],
                [107.7490875, 16.0428942],
                [107.7497113, 16.0429676],
                [107.75006, 16.0429956],
                [107.7504095, 16.0430142],
                [107.7507594, 16.0430236],
                [107.7511093, 16.0430236],
                [107.7514592, 16.0430142],
                [107.7518087, 16.0429956],
                [107.7521574, 16.0429676],
                [107.7528012, 16.0427975],
                [107.7538097, 16.0440245],
                [107.7539638, 16.0441013],
                [107.7541304, 16.0441483],
                [107.7543032, 16.0441637],
                [107.7544778, 16.0441467],
                [107.7546457, 16.0440975],
                [107.7547251, 16.0440613],
                [107.754871, 16.0439677],
                [107.7549362, 16.0439111],
                [107.7555799, 16.0434419],
                [107.7557814, 16.0433597],
                [107.7559932, 16.0433062],
                [107.7561015, 16.0432906],
                [107.7563202, 16.0432821],
                [107.7564397, 16.0432903],
                [107.756558, 16.0433076],
                [107.7566746, 16.0433337],
                [107.7567887, 16.0433686],
                [107.7568996, 16.043412],
                [107.7570065, 16.0434638],
                [107.7571089, 16.0435234],
                [107.7572061, 16.0435907],
                [107.7572974, 16.043665],
                [107.7573824, 16.0437461],
                [107.7592492, 16.0464734],
                [107.7592871, 16.0466383],
                [107.7593901, 16.0469611],
                [107.7595282, 16.0472716],
                [107.7596998, 16.0475663],
                [107.7598887, 16.0478244],
                [107.7601033, 16.0480632],
                [107.7603416, 16.0482803],
                [107.760601, 16.0484737],
                [107.7611804, 16.0492366],
                [107.7639484, 16.0503708],
                [107.7642585, 16.0504836],
                [107.764572, 16.0505874],
                [107.7648885, 16.0506821],
                [107.7652079, 16.0507677],
                [107.7655298, 16.0508441],
                [107.7658539, 16.0509111],
                [107.76618, 16.0509688],
                [107.7667895, 16.051051],
                [107.7670958, 16.0510798],
                [107.7677103, 16.0511129],
                [107.7683258, 16.0511132],
                [107.7685713, 16.0510983],
                [107.7688166, 16.0511168],
                [107.7690567, 16.0511682],
                [107.7692863, 16.0512512],
                [107.7695009, 16.051364],
                [107.769697, 16.0515045],
                [107.7698707, 16.0516699],
                [107.7699671, 16.0518577],
                [107.7700562, 16.0520488],
                [107.7702119, 16.0524398],
                [107.7703384, 16.0528452],
                [107.7703903, 16.0530532],
                [107.7704693, 16.0534743],
                [107.7704963, 16.0536866],
                [107.7705445, 16.0543709],
                [107.7705713, 16.0546276],
                [107.7706057, 16.0548835],
                [107.7706969, 16.055392],
                [107.7710478, 16.0558425],
                [107.7714225, 16.0562749],
                [107.7718202, 16.056688],
                [107.7722696, 16.0571073],
                [107.7725187, 16.0573207],
                [107.7727742, 16.0575268],
                [107.773036, 16.0577256],
                [107.7733038, 16.0579169],
                [107.7735774, 16.0581005],
                [107.7738564, 16.0582762],
                [107.7741408, 16.0584439],
                [107.7743935, 16.0587882],
                [107.7745094, 16.0589671],
                [107.7747194, 16.0593371],
                [107.7748132, 16.0595276],
                [107.7749777, 16.0599182],
                [107.7751147, 16.0603342],
                [107.7751702, 16.0605458],
                [107.7752549, 16.0609746],
                [107.775284, 16.061191],
                [107.7753151, 16.0616264],
                [107.7753103, 16.0620627],
                [107.7753208, 16.0624635],
                [107.7753126, 16.0626638],
                [107.7752955, 16.0628637],
                [107.7752346, 16.0632603],
                [107.7751386, 16.0636504],
                [107.775084, 16.0638233],
                [107.7749542, 16.0641624],
                [107.7747976, 16.0644909],
                [107.7742481, 16.0654753],
                [107.7741845, 16.0656694],
                [107.7740765, 16.0660629],
                [107.7740269, 16.0662883],
                [107.7739531, 16.0667434],
                [107.775836, 16.0656402],
                [107.7764197, 16.0653631],
                [107.7770242, 16.0651048],
                [107.7776492, 16.064867],
                [107.7779649, 16.0647586],
                [107.7781188, 16.0646946],
                [107.7784172, 16.0645476],
                [107.7787119, 16.0643691],
                [107.7789987, 16.0641578],
                [107.7791341, 16.0640427],
                [107.7793872, 16.0637948],
                [107.7797574, 16.0631736],
                [107.7799111, 16.0630014],
                [107.7799962, 16.0629227],
                [107.7801812, 16.0627818],
                [107.7802818, 16.0627193],
                [107.7804946, 16.0626136],
                [107.7806059, 16.0625709],
                [107.7808359, 16.0625067],
                [107.7809536, 16.0624854],
                [107.7813882, 16.0624699],
                [107.7827132, 16.0646505],
                [107.7839281, 16.0655425],
                [107.7851593, 16.0664135],
                [107.7858024, 16.0668557],
                [107.7871013, 16.0677228],
                [107.7913821, 16.0650938],
                [107.7917348, 16.0648302],
                [107.7921038, 16.064588],
                [107.7925107, 16.064356],
                [107.7927315, 16.064244],
                [107.7931845, 16.0640422],
                [107.793316, 16.0637208],
                [107.7933884, 16.0635628],
                [107.7935756, 16.063199],
                [107.793678, 16.0630215],
                [107.796607, 16.0617998],
                [107.7983826, 16.0616709],
                [107.8006223, 16.0607688],
                [107.8011721, 16.0605626],
                [107.8012518, 16.0605264],
                [107.8013986, 16.0604332],
                [107.8015198, 16.0603204],
                [107.8016142, 16.0601945],
                [107.8016525, 16.0601261],
                [107.8021204, 16.0591188],
                [107.8023014, 16.058799],
                [107.8025109, 16.0584957],
                [107.8026259, 16.0583511],
                [107.8028897, 16.0580626],
                [107.8031802, 16.0577989],
                [107.8033347, 16.0576771],
                [107.804836, 16.0566241],
                [107.8056246, 16.0548816],
                [107.805808, 16.0545694],
                [107.805909, 16.0544187],
                [107.8061289, 16.054129],
                [107.8062609, 16.0539754],
                [107.806545, 16.0536852],
                [107.806854, 16.0534195],
                [107.8071856, 16.0531804],
                [107.813918, 16.0528196],
                [107.8140979, 16.0528276],
                [107.8142772, 16.0528449],
                [107.8144552, 16.0528714],
                [107.8146315, 16.0529072],
                [107.8149489, 16.0529964],
                [107.8151037, 16.0530522],
                [107.8154039, 16.0531856],
                [107.8159734, 16.054051],
                [107.8163266, 16.0545878],
                [107.8167187, 16.0551045],
                [107.8169796, 16.055425],
                [107.8173459, 16.0558508],
                [107.8187648, 16.0573896],
                [107.818754, 16.0578845],
                [107.8185394, 16.0582918],
                [107.8187326, 16.0586836],
                [107.8186714, 16.0588892],
                [107.8186414, 16.0591011],
                [107.8186407, 16.0592822],
                [107.8186628, 16.059462],
                [107.8197303, 16.0611219],
                [107.8197472, 16.0613403],
                [107.8197677, 16.061448],
                [107.8198323, 16.061658],
                [107.8198758, 16.0617587],
                [107.8199844, 16.0619499],
                [107.820049, 16.0620395],
                [107.820197, 16.0622045],
                [107.8201648, 16.0640706],
                [107.8199422, 16.0649572],
                [107.8216481, 16.0667511],
                [107.8221577, 16.0675862],
                [107.8236973, 16.0687718],
                [107.8248131, 16.0699471],
                [107.8248912, 16.0700745],
                [107.8249204, 16.070143],
                [107.8249543, 16.0702657],
                [107.8249633, 16.0703286],
                [107.8247005, 16.0714369],
                [107.8244698, 16.0730245],
                [107.8236222, 16.0738957],
                [107.8234974, 16.0742116],
                [107.823227, 16.0748285],
                [107.8230817, 16.0751292],
                [107.8227693, 16.0757204],
                [107.822088, 16.0764163],
                [107.8217447, 16.0771689],
                [107.8217117, 16.0772943],
                [107.8216694, 16.0775496],
                [107.8216588, 16.077808],
                [107.8216653, 16.0779337],
                [107.8217009, 16.078183],
                [107.8217298, 16.0783057],
                [107.8220665, 16.0794936],
                [107.8220916, 16.0798717],
                [107.8221175, 16.0800596],
                [107.8221891, 16.0804042],
                [107.8222817, 16.0807157],
                [107.8223991, 16.0810193],
                [107.8224045, 16.0819729],
                [107.8224659, 16.0822515],
                [107.8224957, 16.0825347],
                [107.8224952, 16.0827905],
                [107.8224689, 16.083045],
                [107.8225847, 16.0831378],
                [107.8228068, 16.0833337],
                [107.8229355, 16.0834596],
                [107.823177, 16.0837254],
                [107.8252315, 16.0847254],
                [107.8255883, 16.0848517],
                [107.8255722, 16.0868129],
                [107.8256902, 16.0869675],
                [107.825776, 16.0876067],
                [107.826409, 16.088153],
                [107.8264519, 16.0887406],
                [107.8265123, 16.0887967],
                [107.8266173, 16.0889223],
                [107.8266614, 16.0889909],
                [107.8267309, 16.0891375],
                [107.8267551, 16.0892116],
                [107.8267847, 16.0893641],
                [107.8268167, 16.0897715],
                [107.826847, 16.0898357],
                [107.8269294, 16.0899519],
                [107.8270385, 16.0900478],
                [107.827101, 16.0900859],
                [107.8283188, 16.090957],
                [107.8292093, 16.0918229],
                [107.8323689, 16.0930187],
                [107.8322714, 16.0932777],
                [107.8321996, 16.0935278],
                [107.832157, 16.0937841],
                [107.8321468, 16.0939154],
                [107.8321495, 16.0941787],
                [107.8322697, 16.0952375],
                [107.832315, 16.0954294],
                [107.8323696, 16.095619],
                [107.8324332, 16.0958061],
                [107.8325057, 16.0959901],
                [107.8325874, 16.0961717],
                [107.8326779, 16.0963494],
                [107.8327768, 16.096523],
                [107.832884, 16.0966919],
                [107.8329992, 16.0968559],
                [107.8340292, 16.0981032],
                [107.8341456, 16.0982119],
                [107.834394, 16.0984125],
                [107.8345205, 16.0985007],
                [107.8347856, 16.0986599],
                [107.8348993, 16.0986853],
                [107.8350104, 16.0987198],
                [107.8351182, 16.0987629],
                [107.8352943, 16.0988573],
                [107.8354545, 16.0989749],
                [107.8355956, 16.0991134],
                [107.8364754, 16.1000824],
                [107.8365604, 16.1003165],
                [107.8366121, 16.1004302],
                [107.8367329, 16.1006493],
                [107.8368939, 16.1008786],
                [107.8369842, 16.1009864],
                [107.8371829, 16.1011864],
                [107.8372908, 16.1012781],
                [107.8379396, 16.1013577],
                [107.8379945, 16.101371],
                [107.8380981, 16.1014147],
                [107.8381469, 16.1014455],
                [107.8382319, 16.101522],
                [107.8384683, 16.1018554],
                [107.8387292, 16.1018623],
                [107.838859, 16.1018768],
                [107.8391147, 16.1019275],
                [107.8393632, 16.102007],
                [107.8396004, 16.102114],
                [107.8398228, 16.1022471],
                [107.8399362, 16.1023354],
                [107.8401768, 16.1024944],
                [107.8402987, 16.1025623],
                [107.8405523, 16.10268],
                [107.8406891, 16.1027638],
                [107.8408205, 16.1028552],
                [107.8409211, 16.1029333],
                [107.8411102, 16.1031026],
                [107.8416467, 16.1036592],
                [107.842977, 16.1037005],
                [107.8439641, 16.1043705],
                [107.8454232, 16.1045663],
                [107.8454712, 16.1045917],
                [107.8455154, 16.1046229],
                [107.8455895, 16.1047003],
                [107.8456153, 16.1047402],
                [107.8456518, 16.1048271],
                [107.8456619, 16.104873],
                [107.8457558, 16.105762],
                [107.8462708, 16.1061228],
                [107.8473008, 16.1071536],
                [107.8475628, 16.10722],
                [107.8476901, 16.1072646],
                [107.8479351, 16.1073759],
                [107.8481596, 16.1075122],
                [107.848263, 16.1075881],
                [107.848455, 16.1077569],
                [107.848625, 16.1079462],
                [107.8487706, 16.1081534],
                [107.8491998, 16.1086894],
                [107.8492616, 16.1087857],
                [107.8493171, 16.1088855],
                [107.8494061, 16.1090877],
                [107.8494402, 16.1091892],
                [107.8494886, 16.1093824],
                [107.8495532, 16.1095556],
                [107.8496512, 16.1097213],
                [107.8497825, 16.1098725],
                [107.8498583, 16.1099389],
                [107.8500237, 16.1100509],
                [107.8502011, 16.1101388],
                [107.8503891, 16.1102069],
                [107.8504805, 16.1102482],
                [107.8506491, 16.1103534],
                [107.8507249, 16.1104162],
                [107.8508574, 16.1105602],
                [107.8509565, 16.1107134],
                [107.851029, 16.1108798],
                [107.8514582, 16.1110601],
                [107.8515953, 16.1110934],
                [107.8518638, 16.1111785],
                [107.8521302, 16.1112915],
                [107.8523907, 16.1114334],
                [107.8529495, 16.1118023],
                [107.8532016, 16.1118332],
                [107.8534655, 16.1119725],
                [107.8537166, 16.1121321],
                [107.853859, 16.1122361],
                [107.8541272, 16.1124633],
                [107.8542523, 16.112586],
                [107.8544473, 16.1128008],
                [107.8546178, 16.1129567],
                [107.8546983, 16.1130166],
                [107.8549538, 16.1131792],
                [107.855106, 16.1133123],
                [107.8551732, 16.1133867],
                [107.8552884, 16.1135494],
                [107.8554064, 16.1137864],
                [107.8559214, 16.114106],
                [107.8559586, 16.1141366],
                [107.8560207, 16.1142093],
                [107.8560676, 16.1143048],
                [107.8560877, 16.1144203],
                [107.8560179, 16.1166776],
                [107.8559375, 16.1174455],
                [107.856077, 16.1183474],
                [107.8560764, 16.1184799],
                [107.8560693, 16.1186123],
                [107.8560375, 16.1188619],
                [107.8560142, 16.1189788],
                [107.8558892, 16.1194657],
                [107.8558838, 16.1203882],
                [107.8555459, 16.1211303],
                [107.8554938, 16.1211964],
                [107.8554365, 16.1212584],
                [107.8553116, 16.1213652],
                [107.8549668, 16.1215768],
                [107.8547967, 16.1217146],
                [107.8547275, 16.1217825],
                [107.8546017, 16.1219291],
                [107.8543657, 16.122264],
                [107.8543023, 16.1224201],
                [107.8542613, 16.1226015],
                [107.8542523, 16.1227032],
                [107.8542559, 16.1229131],
                [107.8542828, 16.1231274],
                [107.854338, 16.1233383],
                [107.8543763, 16.1234416],
                [107.854473, 16.12364],
                [107.8547037, 16.1240523],
                [107.8547489, 16.1241602],
                [107.8548217, 16.1243821],
                [107.854852, 16.1245092],
                [107.8548896, 16.1247672],
                [107.8549075, 16.1252582],
                [107.8550396, 16.1253383],
                [107.8551663, 16.1254262],
                [107.8552869, 16.1255216],
                [107.8555023, 16.1257268],
                [107.8555971, 16.1258352],
                [107.8557658, 16.1260672],
                [107.8558462, 16.1262602],
                [107.8558731, 16.126361],
                [107.8558983, 16.1265431],
                [107.8558892, 16.1271234],
                [107.8558978, 16.1273216],
                [107.8559375, 16.1277163],
                [107.8576112, 16.1288913],
                [107.8577292, 16.1298292],
                [107.8577869, 16.130039],
                [107.8578687, 16.1302414],
                [107.8579979, 16.1304724],
                [107.8580744, 16.1305811],
                [107.8582299, 16.1307703],
                [107.8583545, 16.1309546],
                [107.8584525, 16.1311531],
                [107.8585224, 16.1313634],
                [107.8585463, 16.131472],
                [107.858571, 16.1316926],
                [107.8585718, 16.1318036],
                [107.8585499, 16.1320244],
                [107.8585419, 16.1324547],
                [107.8585698, 16.1325826],
                [107.8586304, 16.1326995],
                [107.8586702, 16.1327496],
                [107.8587675, 16.132834],
                [107.8594297, 16.1332354],
                [107.8594532, 16.1333594],
                [107.8595263, 16.1336013],
                [107.8595682, 16.1337021],
                [107.8596703, 16.1338957],
                [107.8597301, 16.1339878],
                [107.8598481, 16.1341372],
                [107.8601861, 16.1350648],
                [107.8598857, 16.136116],
                [107.8596711, 16.1363788],
                [107.8594307, 16.13662],
                [107.8591668, 16.1368374],
                [107.8590239, 16.1369385],
                [107.8587232, 16.1371193],
                [107.8584051, 16.1372703],
                [107.8577399, 16.137497],
                [107.8575432, 16.1375879],
                [107.8573644, 16.1377083],
                [107.8572871, 16.137775],
                [107.8571498, 16.1379247],
                [107.8571576, 16.1380909],
                [107.8571901, 16.138422],
                [107.8572518, 16.1387827],
                [107.8583971, 16.1433018],
                [107.858845, 16.1450358],
                [107.8589796, 16.1452489],
                [107.8591227, 16.1454568],
                [107.8592741, 16.1456593],
                [107.8595625, 16.1460042],
                [107.859875, 16.1463292],
                [107.8602934, 16.147468],
                [107.8586841, 16.1516983],
                [107.8585017, 16.1525227],
                [107.8584911, 16.1526097],
                [107.8584891, 16.1526972],
                [107.8585105, 16.1528709],
                [107.858567, 16.1530415],
                [107.8586586, 16.153202],
                [107.859596, 16.1543468],
                [107.8596856, 16.1544387],
                [107.859879, 16.1546088],
                [107.8600895, 16.154759],
                [107.8602149, 16.1548342],
                [107.8604782, 16.1549633],
                [107.8606152, 16.1550166],
                [107.8608027, 16.1550718],
                [107.8611839, 16.1551595],
                [107.8615425, 16.1552132],
                [107.8617086, 16.1552289],
                [107.8620422, 16.1552433],
                [107.8631151, 16.1551815],
                [107.8632284, 16.1553312],
                [107.8632955, 16.1553977],
                [107.8634477, 16.1555113],
                [107.8635296, 16.1555564],
                [107.8637047, 16.1556243],
                [107.8637964, 16.1556463],
                [107.8639841, 16.1556658],
                [107.8641444, 16.1558252],
                [107.864279, 16.1560075],
                [107.8643839, 16.1562091],
                [107.8644559, 16.156398],
                [107.8644944, 16.1564784],
                [107.8645903, 16.1566294],
                [107.8647135, 16.1567684],
                [107.8647834, 16.1568303],
                [107.8651804, 16.1570982],
                [107.8653388, 16.157195],
                [107.8656632, 16.1573765],
                [107.8664678, 16.1573919],
                [107.8671169, 16.1575929],
                [107.8676909, 16.1573868],
                [107.8678974, 16.1573469],
                [107.8680021, 16.1573353],
                [107.8682562, 16.1573305],
                [107.8683829, 16.1573404],
                [107.8694558, 16.1575208],
                [107.8697702, 16.157515],
                [107.8699272, 16.1575233],
                [107.870239, 16.157562],
                [107.8705602, 16.1576339],
                [107.870872, 16.1577372],
                [107.8709732, 16.1577636],
                [107.8710761, 16.157783],
                [107.871285, 16.1578003],
                [107.8717229, 16.1577739],
                [107.8721809, 16.1577681],
                [107.8726637, 16.1576341],
                [107.8728385, 16.1576138],
                [107.8730042, 16.1576187],
                [107.8731573, 16.1576444],
                [107.8737205, 16.1579278],
                [107.8737205, 16.1581184],
                [107.8737064, 16.1582146],
                [107.8736358, 16.1584684],
                [107.8736228, 16.1586335],
                [107.8736428, 16.1587906],
                [107.8736633, 16.1588635],
                [107.8737248, 16.1590025],
                [107.8738117, 16.1591283],
                [107.8743535, 16.159623],
                [107.8744538, 16.159849],
                [107.8745627, 16.1600712],
                [107.8746766, 16.1602832],
                [107.8749275, 16.1606947],
                [107.8749479, 16.1607756],
                [107.8749679, 16.1609408],
                [107.8749597, 16.1611069],
                [107.8749416, 16.1612037],
                [107.874914, 16.1612983],
                [107.874877, 16.16139],
                [107.874831, 16.1614779],
                [107.8747984, 16.1616077],
                [107.874792, 16.1617411],
                [107.8748152, 16.1618851],
                [107.8748748, 16.1620322],
                [107.8749171, 16.1621],
                [107.8750241, 16.1622198],
                [107.8754103, 16.1630442],
                [107.8754865, 16.1632469],
                [107.8755158, 16.1633509],
                [107.8755556, 16.1635587],
                [107.8755717, 16.163765],
                [107.875582, 16.1643529],
                [107.8756463, 16.1646723],
                [107.8756954, 16.1648488],
                [107.8757536, 16.1650227],
                [107.8760433, 16.165641],
                [107.8760326, 16.1665787],
                [107.8758395, 16.1674546],
                [107.875825, 16.1675868],
                [107.8758197, 16.1678526],
                [107.8758449, 16.1681128],
                [107.8758683, 16.1682392],
                [107.8759365, 16.1684875],
                [107.8760326, 16.1687272],
                [107.8762793, 16.1691445],
                [107.8762782, 16.1692977],
                [107.876267, 16.1694504],
                [107.8762459, 16.1696022],
                [107.876215, 16.1697525],
                [107.8761371, 16.1700109],
                [107.8760301, 16.1702596],
                [107.8758953, 16.1704955],
                [107.8756373, 16.170862],
                [107.8752601, 16.17136],
                [107.8751041, 16.1715454],
                [107.8749704, 16.1717464],
                [107.8748577, 16.1719673],
                [107.8748114, 16.172082],
                [107.874702, 16.1724264],
                [107.8746046, 16.1726344],
                [107.8745454, 16.1727333],
                [107.8741593, 16.1732934],
                [107.8735113, 16.17458],
                [107.8731734, 16.1758474],
                [107.8729051, 16.1762905],
                [107.8727983, 16.1765743],
                [107.8725672, 16.1771354],
                [107.8724107, 16.1774825],
                [107.8722453, 16.1778258],
                [107.8722015, 16.1778843],
                [107.8721005, 16.1779907],
                [107.8720266, 16.1780511],
                [107.871947, 16.1781042],
                [107.8718622, 16.1781494],
                [107.8717732, 16.1781864],
                [107.871269, 16.1783256],
                [107.8708506, 16.1791396],
                [107.8707992, 16.1792285],
                [107.8706735, 16.179392],
                [107.8705977, 16.1794676],
                [107.8705153, 16.1795365],
                [107.8704268, 16.1795981],
                [107.8700995, 16.1797887],
                [107.868973, 16.1810458],
                [107.8681576, 16.1811694],
                [107.8680934, 16.1812094],
                [107.8679768, 16.1813042],
                [107.8678787, 16.1814167],
                [107.8678359, 16.1814811],
                [107.8677689, 16.1816195],
                [107.8677453, 16.1816924],
                [107.8675353, 16.1825707],
                [107.8674066, 16.1829108],
                [107.8769177, 16.1885056],
                [107.8770684, 16.1886316],
                [107.8771355, 16.1887027],
                [107.8772489, 16.1888549],
                [107.8772951, 16.1889348],
                [107.8773683, 16.1891032],
                [107.8773496, 16.1894844],
                [107.8772691, 16.1896441],
                [107.8772557, 16.1902263],
                [107.8769499, 16.1906281],
                [107.8769181, 16.1906906],
                [107.8768723, 16.1908225],
                [107.8768587, 16.1908908],
                [107.8768503, 16.1910198],
                [107.8768641, 16.1911484],
                [107.8768802, 16.1914524],
                [107.8768641, 16.1915966],
                [107.8768293, 16.191698],
                [107.8763974, 16.1925754],
                [107.8763806, 16.1927526],
                [107.8763276, 16.1929232],
                [107.8762491, 16.1930677],
                [107.8761999, 16.1931341],
                [107.8760836, 16.1932529],
                [107.8759494, 16.1936985],
                [107.8759349, 16.1937658],
                [107.8758864, 16.1938952],
                [107.8758055, 16.1940242],
                [107.8757505, 16.1940868],
                [107.8756886, 16.1941431],
                [107.8753647, 16.1943586],
                [107.8752797, 16.1944284],
                [107.8751233, 16.194582],
                [107.8747129, 16.1950276],
                [107.874684, 16.1951028],
                [107.8746467, 16.195259],
                [107.8746378, 16.1954191],
                [107.8746587, 16.1955831],
                [107.8747093, 16.195741],
                [107.874788, 16.1958879],
                [107.8752601, 16.1965936],
                [107.8753851, 16.196715],
                [107.8754542, 16.1967688],
                [107.8756034, 16.1968615],
                [107.8757023, 16.1969082],
                [107.8758052, 16.1969463],
                [107.8759112, 16.1969754],
                [107.8760195, 16.1969953],
                [107.8776097, 16.1971294],
                [107.8777014, 16.1971926],
                [107.8778703, 16.1973361],
                [107.8780174, 16.1975003],
                [107.8780903, 16.1976019],
                [107.8781548, 16.1977085],
                [107.8782105, 16.1978197],
                [107.8784466, 16.1983658],
                [107.8801846, 16.1989118],
                [107.8827274, 16.1999112],
                [107.883012, 16.2000092],
                [107.8831581, 16.2000466],
                [107.8834557, 16.2000979],
                [107.8837573, 16.2001172],
                [107.8840475, 16.2001055],
                [107.8843347, 16.2000641],
                [107.8846156, 16.1999936],
                [107.885474, 16.1999318],
                [107.8864717, 16.1996433],
                [107.8865366, 16.1996193],
                [107.886673, 16.1995949],
                [107.8868115, 16.1996031],
                [107.8869438, 16.1996433],
                [107.8870064, 16.1996755],
                [107.8871166, 16.199761],
                [107.8872013, 16.19987],
                [107.8874481, 16.2003027],
                [107.8881562, 16.2005191],
                [107.8881884, 16.2015493],
                [107.8882046, 16.2016959],
                [107.8882306, 16.2018411],
                [107.888266, 16.2019845],
                [107.8883108, 16.2021255],
                [107.8883648, 16.2022634],
                [107.8884278, 16.2023978],
                [107.8885753, 16.2026473],
                [107.8886583, 16.202762],
                [107.8888452, 16.2029759],
                [107.8890574, 16.2031669],
                [107.8894758, 16.2033626],
                [107.8896148, 16.2034462],
                [107.8896773, 16.2034973],
                [107.8897853, 16.2036158],
                [107.8898711, 16.2037598],
                [107.8899034, 16.2038415],
                [107.8899264, 16.203926],
                [107.8899398, 16.2040124],
                [107.8899434, 16.2040997],
                [107.8899127, 16.2045196],
                [107.8898835, 16.2051862],
                [107.8898787, 16.205588],
                [107.8898835, 16.2059898],
                [107.8899254, 16.2061188],
                [107.8899984, 16.2062343],
                [107.8900981, 16.2063297],
                [107.8901597, 16.2063701],
                [107.8902262, 16.2064025],
                [107.8902964, 16.2064266],
                [107.8903693, 16.2064418],
                [107.8904436, 16.2064479],
                [107.8905181, 16.2064449],
                [107.8914821, 16.2062782],
                [107.8919409, 16.2060612],
                [107.892175, 16.2059622],
                [107.8926515, 16.2057837],
                [107.8931074, 16.2056404],
                [107.8933382, 16.2055777],
                [107.894057, 16.2055365],
                [107.894197, 16.2054072],
                [107.8942748, 16.2053512],
                [107.8944433, 16.2052583],
                [107.8946318, 16.2051911],
                [107.8948295, 16.2051553],
                [107.8976244, 16.2070252],
                [107.8978194, 16.2073008],
                [107.8980428, 16.2075557],
                [107.898166, 16.2076761],
                [107.8984311, 16.2078976],
                [107.8987187, 16.2080915],
                [107.9000437, 16.2088332],
                [107.9002116, 16.2089552],
                [107.9005634, 16.2091774],
                [107.9009342, 16.209369],
                [107.9011115, 16.2094469],
                [107.9014753, 16.2095817],
                [107.9016611, 16.2096383],
                [107.9020393, 16.2097295],
                [107.9028547, 16.2099562],
                [107.9029883, 16.2100133],
                [107.9032434, 16.2101507],
                [107.9033639, 16.2102304],
                [107.9035883, 16.2104104],
                [107.9037881, 16.2106155],
                [107.9038797, 16.2107292],
                [107.9039637, 16.2108482],
                [107.9040398, 16.210972],
                [107.9041075, 16.2111002],
                [107.9041668, 16.2112323],
                [107.9042172, 16.2113676],
                [107.9042474, 16.2114737],
                [107.904285, 16.2116906],
                [107.9043245, 16.2127172],
                [107.9044093, 16.2129961],
                [107.9045284, 16.2132632],
                [107.9046021, 16.2133942],
                [107.9046841, 16.2135206],
                [107.9047741, 16.213642],
                [107.9048717, 16.2137577],
                [107.9054296, 16.2142213],
                [107.9055072, 16.214273],
                [107.9056739, 16.2143584],
                [107.9058523, 16.214418],
                [107.9060394, 16.2144505],
                [107.9062308, 16.2144546],
                [107.9064205, 16.2144298],
                [107.9069745, 16.2142831],
                [107.9077256, 16.2142728],
                [107.9081263, 16.2141024],
                [107.9082322, 16.2140828],
                [107.9083372, 16.214094],
                [107.9083864, 16.2141103],
                [107.9084747, 16.2141624],
                [107.908512, 16.2141972],
                [107.9087394, 16.2144737],
                [107.908862, 16.214556],
                [107.9089969, 16.2146179],
                [107.9090815, 16.2146443],
                [107.9091684, 16.2146627],
                [107.9093456, 16.2146746],
                [107.9100269, 16.2154163],
                [107.9100538, 16.2154353],
                [107.9100849, 16.2154467],
                [107.910145, 16.215446],
                [107.9102598, 16.2153813],
                [107.9103622, 16.2153545],
                [107.9104198, 16.2153503],
                [107.9105344, 16.2153639],
                [107.9106411, 16.215406],
                [107.9107328, 16.2154266],
                [107.9109198, 16.2154472],
                [107.9111144, 16.2154396],
                [107.9113117, 16.2154009],
                [107.9117427, 16.2151583],
                [107.9121888, 16.2149425],
                [107.9124435, 16.214834],
                [107.9127021, 16.2147342],
                [107.9133087, 16.2145347],
                [107.9134569, 16.2144596],
                [107.9135245, 16.2144119],
                [107.9136325, 16.2143107],
                [107.913679, 16.2142536],
                [107.9137552, 16.2141286],
                [107.9138145, 16.2140521],
                [107.9139668, 16.2138916],
                [107.9140617, 16.2138107],
                [107.9141629, 16.2137371],
                [107.9143597, 16.2136143],
                [107.9145378, 16.2134675],
                [107.9146939, 16.2132993],
                [107.9147677, 16.2132013],
                [107.9148923, 16.2129915],
                [107.9149846, 16.2127668],
                [107.9150821, 16.2123976],
                [107.9151788, 16.2121346],
                [107.9153134, 16.2118522],
                [107.9153993, 16.2117022],
                [107.9155949, 16.2114162],
                [107.9157041, 16.2112809],
                [107.9159439, 16.2110276],
                [107.9161192, 16.2108766],
                [107.9162002, 16.2107945],
                [107.9163564, 16.2106065],
                [107.9164297, 16.2105003],
                [107.9165555, 16.210276],
                [107.9167151, 16.2098839],
                [107.9167864, 16.2097325],
                [107.9169524, 16.2094411],
                [107.917127, 16.2091941],
                [107.9173242, 16.2089634],
                [107.9175424, 16.2087508],
                [107.9180038, 16.2081327],
                [107.9186582, 16.2079678],
                [107.9192376, 16.2075248],
                [107.9196299, 16.2073447],
                [107.9200078, 16.2071383],
                [107.9203695, 16.2069067],
                [107.9206892, 16.20667],
                [107.9208428, 16.2065441],
                [107.9211969, 16.2062205],
                [107.9212967, 16.2060884],
                [107.9213351, 16.2060155],
                [107.9213868, 16.2058596],
                [107.9213995, 16.2057786],
                [107.9213995, 16.2053562],
                [107.9216367, 16.2050745],
                [107.9217646, 16.2049414],
                [107.9220378, 16.2046916],
                [107.9223288, 16.2044681],
                [107.9224815, 16.2043655],
                [107.9230463, 16.204022],
                [107.9233575, 16.2036099],
                [107.9235338, 16.2034408],
                [107.9236146, 16.2033496],
                [107.9237681, 16.2031433],
                [107.9239025, 16.2029085],
                [107.9239583, 16.2027856],
                [107.9243123, 16.2021057],
                [107.9246032, 16.2019767],
                [107.9247522, 16.2019202],
                [107.924946, 16.2018561],
                [107.9251429, 16.2018011],
                [107.9253423, 16.2017554],
                [107.925868, 16.2014772],
                [107.9266512, 16.2014566],
                [107.9274773, 16.2007766],
                [107.9276289, 16.2007152],
                [107.9276997, 16.2006748],
                [107.9278283, 16.2005763],
                [107.927885, 16.2005191],
                [107.9279882, 16.2003792],
                [107.9280606, 16.2002226],
                [107.9281302, 16.1999842],
                [107.9281855, 16.1998313],
                [107.9282911, 16.1995982],
                [107.9284188, 16.1993754],
                [107.9285615, 16.1991829],
                [107.9286829, 16.1989798],
                [107.9287822, 16.1987683],
                [107.9288238, 16.1986594],
                [107.9290165, 16.1982992],
                [107.9292469, 16.1979294],
                [107.9295185, 16.1975544],
                [107.9295554, 16.1975263],
                [107.9296411, 16.1974897],
                [107.9297293, 16.197482],
                [107.9298108, 16.1974993],
                [107.929957, 16.1975737],
                [107.9300333, 16.197603],
                [107.9301918, 16.1976445],
                [107.9302932, 16.1976585],
                [107.9303956, 16.1976632],
                [107.930498, 16.1976585],
                [107.9305994, 16.1976445],
                [107.9306666, 16.1975913],
                [107.9307479, 16.1975504],
                [107.9308408, 16.1975261],
                [107.9319298, 16.1971758],
                [107.9332709, 16.1958673],
                [107.9344404, 16.1952955],
                [107.9346526, 16.1953367],
                [107.9348685, 16.1953532],
                [107.9350999, 16.1953433],
                [107.9352221, 16.1953264],
                [107.9354615, 16.1952689],
                [107.9355776, 16.1952285],
                [107.9360497, 16.1950224],
                [107.9374103, 16.1953356],
                [107.9375166, 16.1953472],
                [107.9376236, 16.1953495],
                [107.9377304, 16.1953426],
                [107.937836, 16.1953264],
                [107.9380204, 16.1952746],
                [107.9381086, 16.1952381],
                [107.9381935, 16.1951949],
                [107.9383141, 16.1951189],
                [107.9383975, 16.1950435],
                [107.9384712, 16.1949371],
                [107.9385158, 16.1948167],
                [107.9385285, 16.1946895],
                [107.9385227, 16.1946258],
                [107.9388822, 16.1945603],
                [107.9392459, 16.1945225],
                [107.9396117, 16.1945124],
                [107.9400094, 16.1945332],
                [107.9404037, 16.1945868],
                [107.940778, 16.1946647],
                [107.940959, 16.1946952],
                [107.9413246, 16.1947304],
                [107.9416921, 16.1947311],
                [107.9418754, 16.1947185],
                [107.9421552, 16.1946823],
                [107.9422939, 16.1946567],
                [107.9423973, 16.1946086],
                [107.9425044, 16.1945689],
                [107.9426146, 16.1945378],
                [107.942727, 16.1945155],
                [107.942841, 16.1945021],
                [107.9429483, 16.1944978],
                [107.9431623, 16.1945129],
                [107.9433714, 16.1945594],
                [107.9435706, 16.1946361],
                [107.9441285, 16.1941106],
                [107.9443762, 16.1939641],
                [107.9446381, 16.1938427],
                [107.944931, 16.193742],
                [107.9452336, 16.1936727],
                [107.9458773, 16.1934924],
                [107.946063, 16.1934019],
                [107.9461515, 16.1933491],
                [107.9463219, 16.1932264],
                [107.9464029, 16.1931565],
                [107.946551, 16.1930033],
                [107.9467131, 16.1928153],
                [107.9468149, 16.1927155],
                [107.947036, 16.1925342],
                [107.9471559, 16.1924523],
                [107.9474098, 16.1923099],
                [107.947543, 16.1922498],
                [107.9479242, 16.1921149],
                [107.9480257, 16.1920687],
                [107.9482152, 16.1919534],
                [107.948302, 16.1918851],
                [107.948444, 16.1917445],
                [107.9485629, 16.1915854],
                [107.9486561, 16.1914112],
                [107.9488481, 16.1911992],
                [107.9489531, 16.1911013],
                [107.9491925, 16.1909145],
                [107.9493271, 16.1908277],
                [107.9494671, 16.1907491],
                [107.949612, 16.190679],
                [107.9497611, 16.1906178],
                [107.950201, 16.1904736],
                [107.9510164, 16.1900305],
                [107.9522341, 16.1900254],
                [107.9532105, 16.1896416],
                [107.9534914, 16.1895605],
                [107.9537765, 16.1895019],
                [107.9540637, 16.1894658],
                [107.9551417, 16.1893943],
                [107.9558659, 16.1891135],
                [107.9559906, 16.1890938],
                [107.9561164, 16.189106],
                [107.956177, 16.1891238],
                [107.956281, 16.1891765],
                [107.9563271, 16.1892116],
                [107.9564045, 16.1892967],
                [107.9567071, 16.1898902],
                [107.956764, 16.1899603],
                [107.9568372, 16.1900129],
                [107.9568781, 16.1900314],
                [107.9569656, 16.1900524],
                [107.9580009, 16.1901026],
                [107.9590791, 16.1908033],
                [107.9598677, 16.1910454],
                [107.9599428, 16.1910903],
                [107.9600823, 16.1911948],
                [107.9601972, 16.1913081],
                [107.9602871, 16.191411],
                [107.9603298, 16.1914481],
                [107.9604256, 16.191509],
                [107.9604804, 16.1915332],
                [107.9605964, 16.1915643],
                [107.9610047, 16.1916056],
                [107.9611391, 16.191643],
                [107.9612586, 16.1917022],
                [107.9615068, 16.1918912],
                [107.9615846, 16.1919368],
                [107.9617506, 16.1920087],
                [107.9619384, 16.1920551],
                [107.9621317, 16.192069],
                [107.9623246, 16.19205],
                [107.9630581, 16.1919139],
                [107.9632181, 16.1919104],
                [107.9633759, 16.1919355],
                [107.9635262, 16.1919881],
                [107.9636035, 16.1920281],
                [107.9636761, 16.1920754],
                [107.9638044, 16.1921899],
                [107.9638588, 16.192256],
                [107.9640734, 16.1926269],
                [107.9652107, 16.1930906],
                [107.9671526, 16.1930803],
                [107.9672938, 16.193093],
                [107.9675731, 16.1931394],
                [107.9678672, 16.1932204],
                [107.9680207, 16.1932768],
                [107.9681704, 16.193342],
                [107.9687946, 16.1936801],
                [107.9692233, 16.1939123],
                [107.9694915, 16.1939947],
                [107.9695363, 16.1940177],
                [107.9696095, 16.1940848],
                [107.9696504, 16.194163],
                [107.9696983, 16.1945393],
                [107.969763, 16.1948187],
                [107.969855, 16.1950854],
                [107.9699743, 16.1953418],
                [107.9699904, 16.1960167],
                [107.9706395, 16.1962949],
                [107.9708496, 16.1965389],
                [107.9710364, 16.1967997],
                [107.9711323, 16.1969557],
                [107.9712198, 16.1971162],
                [107.9712988, 16.1972807],
                [107.9716104, 16.1980567],
                [107.972061, 16.199056],
                [107.9735899, 16.1992518],
                [107.9738332, 16.1993174],
                [107.9743126, 16.1994709],
                [107.9747808, 16.1996536],
                [107.9752442, 16.1998691],
                [107.9756918, 16.2001133],
                [107.9761219, 16.2003851],
                [107.9781818, 16.2023323],
                [107.978223, 16.2024038],
                [107.9783198, 16.202538],
                [107.9784384, 16.2026626],
                [107.9785782, 16.2027736],
                [107.9788723, 16.2029501],
                [107.9789758, 16.2030226],
                [107.9790931, 16.2031155],
                [107.9792041, 16.2032155],
                [107.9793082, 16.203322],
                [107.9794049, 16.2034347],
                [107.9794371, 16.2039911],
                [107.979598, 16.2045989],
                [107.979354, 16.2050059],
                [107.9793143, 16.2050998],
                [107.979259, 16.2052954],
                [107.9792438, 16.2053957],
                [107.9792386, 16.2055983],
                [107.9792494, 16.2057038],
                [107.9792983, 16.2059104],
                [107.9793361, 16.20601],
                [107.9794371, 16.2061984],
                [107.9794953, 16.2063388],
                [107.9796343, 16.2066098],
                [107.9797146, 16.2067397],
                [107.9798955, 16.2069862],
                [107.9799955, 16.2071021],
                [107.9802136, 16.2073181],
                [107.9804539, 16.2075112],
                [107.9807138, 16.2076794],
                [107.9807782, 16.2081069],
                [107.9820174, 16.2083387],
                [107.9823822, 16.209302],
                [107.9824872, 16.2094987],
                [107.9825508, 16.2095909],
                [107.9826987, 16.2097604],
                [107.9828571, 16.2098966],
                [107.9830334, 16.2100107],
                [107.9831273, 16.2100587],
                [107.9837179, 16.2103065],
                [107.9845548, 16.2110791],
                [107.9848444, 16.2117179],
                [107.9848783, 16.2117623],
                [107.9849592, 16.2118399],
                [107.9850579, 16.2119017],
                [107.9851712, 16.2119432],
                [107.9857993, 16.2120476],
                [107.9870224, 16.2129232],
                [107.9873715, 16.2131517],
                [107.9877037, 16.2134023],
                [107.9878921, 16.213561],
                [107.9880738, 16.2137268],
                [107.9882723, 16.2141698],
                [107.9886194, 16.2140117],
                [107.9889536, 16.2138298],
                [107.9892842, 16.2136174],
                [107.9895973, 16.2133817],
                [107.9896767, 16.2132304],
                [107.9897261, 16.2131602],
                [107.9898442, 16.2130311],
                [107.9899115, 16.2129743],
                [107.9911465, 16.2120973],
                [107.991313, 16.211945],
                [107.9914626, 16.2117714],
                [107.9915915, 16.2115779],
                [107.9916465, 16.2114758],
                [107.9936957, 16.2096883],
                [107.9938229, 16.2096119],
                [107.9939648, 16.2095655],
                [107.9940391, 16.2095544],
                [107.9941746, 16.2095553],
                [107.9942416, 16.2095659],
                [107.9943703, 16.2096069],
                [107.9949108, 16.209849],
                [107.9949696, 16.2098632],
                [107.9950905, 16.2098686],
                [107.9952023, 16.2098455],
                [107.995255, 16.209824],
                [107.9953501, 16.209763],
                [107.9956644, 16.209472],
                [107.9959569, 16.20924],
                [107.9962754, 16.2090242],
                [107.9964455, 16.2089227],
                [107.9967974, 16.2087395],
                [107.9972917, 16.2085121],
                [107.9976018, 16.2083603],
                [107.9982126, 16.20804],
                [107.998513, 16.2079884],
                [107.9986887, 16.2078323],
                [107.9987834, 16.207762],
                [107.998985, 16.2076382],
                [107.99911, 16.2075764],
                [107.999239, 16.2075232],
                [107.9993716, 16.2074787],
                [107.9995071, 16.2074431],
                [107.9996449, 16.2074167],
                [108.0000311, 16.2072492],
                [108.0002184, 16.2071538],
                [108.0005525, 16.206958],
                [108.0008442, 16.2067567],
                [108.0011201, 16.2065358],
                [108.0020428, 16.2060928],
                [108.0036199, 16.2049286],
                [108.0037557, 16.2049179],
                [108.0038848, 16.2049348],
                [108.0040018, 16.2049749],
                [108.0041657, 16.205066],
                [108.0042264, 16.2050887],
                [108.0043539, 16.2051138],
                [108.004484, 16.2051107],
                [108.0046561, 16.2050849],
                [108.0047651, 16.2050797],
                [108.0048742, 16.2050828],
                [108.0050898, 16.205114],
                [108.0052614, 16.2051553],
                [108.0054438, 16.2052789],
                [108.0058515, 16.2049286],
                [108.0059669, 16.2047057],
                [108.0060368, 16.2046008],
                [108.0061144, 16.204501],
                [108.006201, 16.204405],
                [108.0062947, 16.2043153],
                [108.006395, 16.2042324],
                [108.0065014, 16.2041568],
                [108.0066697, 16.2040621],
                [108.0067722, 16.2039922],
                [108.0068614, 16.2038977],
                [108.0069294, 16.2037804],
                [108.0069523, 16.2037168],
                [108.0069912, 16.2035621],
                [108.0070531, 16.2033884],
                [108.0071075, 16.2032709],
                [108.0071705, 16.2031575],
                [108.0072419, 16.2030488],
                [108.0073214, 16.2029453],
                [108.0080509, 16.2021263],
                [108.0081824, 16.2019655],
                [108.0082904, 16.2017893],
                [108.0083349, 16.2016964],
                [108.0084035, 16.2015046],
                [108.0084448, 16.2013072],
                [108.0084586, 16.2011063],
                [108.0084925, 16.2008984],
                [108.008523, 16.2007972],
                [108.0085982, 16.2006255],
                [108.008699, 16.2004663],
                [108.0088234, 16.2003233],
                [108.008897, 16.2001764],
                [108.0090006, 16.2000283],
                [108.0091378, 16.1998862],
                [108.009215, 16.1998236],
                [108.0094135, 16.1995506],
                [108.0096093, 16.1992242],
                [108.0096954, 16.1990549],
                [108.0098426, 16.1987057],
                [108.0102289, 16.1983554],
                [108.0103598, 16.1981305],
                [108.0104359, 16.1980243],
                [108.0106111, 16.1978227],
                [108.0108151, 16.1976406],
                [108.0109258, 16.1975593],
                [108.0111623, 16.1974179],
                [108.0119758, 16.1965194],
                [108.0120273, 16.1964703],
                [108.0120836, 16.1964262],
                [108.012206, 16.1963556],
                [108.0122709, 16.1963293],
                [108.0124776, 16.196273],
                [108.0126102, 16.1962091],
                [108.0126707, 16.1961677],
                [108.012777, 16.1960682],
                [108.0132329, 16.1954809],
                [108.0134367, 16.1953774],
                [108.0135328, 16.1953162],
                [108.0136245, 16.1952491],
                [108.0137784, 16.1951132],
                [108.0139142, 16.1949606],
                [108.0139786, 16.1947545],
                [108.0140704, 16.1945961],
                [108.0141261, 16.1945227],
                [108.0142386, 16.1944049],
                [108.0143675, 16.1943038],
                [108.0147238, 16.1940702],
                [108.0150993, 16.1938661],
                [108.0152934, 16.1937757],
                [108.0154733, 16.1937578],
                [108.0158347, 16.1937448],
                [108.0160249, 16.1937503],
                [108.0164035, 16.1937864],
                [108.0165911, 16.193817],
                [108.0175674, 16.1941312],
                [108.017716, 16.194168],
                [108.0178663, 16.1941977],
                [108.0181706, 16.1942361],
                [108.0184841, 16.1942457],
                [108.0186443, 16.1942391],
                [108.0189627, 16.1942025],
                [108.01912, 16.1941726],
                [108.020491, 16.1938221],
                [108.0228693, 16.1934048],
                [108.0232558, 16.1933249],
                [108.0236321, 16.1932225],
                [108.0239971, 16.1930988],
                [108.0241763, 16.1930288],
                [108.0245728, 16.1929025],
                [108.0246604, 16.1928856],
                [108.0247495, 16.1928779],
                [108.0249138, 16.1928878],
                [108.0249876, 16.1929026],
                [108.0251295, 16.1929512],
                [108.0251964, 16.1929846],
                [108.0259305, 16.1934177],
                [108.0267191, 16.1929979],
                [108.0282265, 16.1930803],
                [108.0293906, 16.1925188],
                [108.0317187, 16.1911639],
                [108.0318651, 16.191069],
                [108.0321723, 16.1909017],
                [108.032496, 16.190766],
                [108.0326629, 16.1907105],
                [108.0329717, 16.1906316],
                [108.0332869, 16.1905809],
                [108.0334461, 16.1905663],
                [108.0335562, 16.1905733],
                [108.0336606, 16.1906075],
                [108.0337113, 16.1906359],
                [108.0337979, 16.1907113],
                [108.0338323, 16.1907569],
                [108.0339133, 16.1908943],
                [108.0340201, 16.1910145],
                [108.0341594, 16.1911196],
                [108.0342368, 16.1911613],
                [108.0344028, 16.1912207],
                [108.034578, 16.1912463],
                [108.0362946, 16.1913004],
                [108.0364215, 16.1912919],
                [108.0365474, 16.1912744],
                [108.0366716, 16.191248],
                [108.036899, 16.1911744],
                [108.0371012, 16.1910782],
                [108.0372885, 16.1909573],
                [108.0373755, 16.1908883],
                [108.0379951, 16.1903499],
                [108.0381987, 16.1902341],
                [108.0383049, 16.1901838],
                [108.0385399, 16.1900938],
                [108.0386688, 16.190056],
                [108.0389982, 16.1899816],
                [108.0393079, 16.1898878],
                [108.0396171, 16.1897673],
                [108.0399232, 16.1896191],
                [108.0400711, 16.1895359],
                [108.0414927, 16.1886756],
                [108.0423993, 16.1882274],
                [108.0429143, 16.1876916],
                [108.0431079, 16.1875341],
                [108.043322, 16.1874031],
                [108.0435617, 16.1872976],
                [108.0436868, 16.1872572],
                [108.0439442, 16.1872022],
                [108.0441797, 16.1871372],
                [108.0444123, 16.1870634],
                [108.0446416, 16.1869807],
                [108.0450357, 16.1868146],
                [108.0454168, 16.1866225],
                [108.0456018, 16.186517],
                [108.0488634, 16.1845284],
                [108.0490769, 16.1843565],
                [108.0492963, 16.1841915],
                [108.0495213, 16.1840337],
                [108.0497517, 16.1838832],
                [108.0499873, 16.1837402],
                [108.0502277, 16.1836049],
                [108.0506909, 16.1833724],
                [108.0511363, 16.1831809],
                [108.0513633, 16.1830947],
                [108.0518246, 16.1829417],
                [108.0522376, 16.1828902],
                [108.0523321, 16.1828708],
                [108.0525151, 16.1828114],
                [108.0526971, 16.182719],
                [108.0527866, 16.1826585],
                [108.0528702, 16.1825908],
                [108.0529474, 16.1825163],
                [108.0530174, 16.1824355],
                [108.0530798, 16.1823492],
                [108.053804, 16.1816228],
                [108.0543966, 16.1811501],
                [108.0550123, 16.1806967],
                [108.0556508, 16.1802639],
                [108.0559766, 16.1800566],
                [108.0576503, 16.1792426],
                [108.0579772, 16.1791438],
                [108.0581441, 16.1791066],
                [108.0584829, 16.1790573],
                [108.0588251, 16.1790417],
                [108.0590074, 16.1790472],
                [108.0591891, 16.1790622],
                [108.0593696, 16.1790868],
                [108.0595485, 16.1791209],
                [108.0597251, 16.1791643],
                [108.059899, 16.179217],
                [108.0600697, 16.1792787],
                [108.0607617, 16.1796702],
                [108.0609314, 16.1797777],
                [108.0610963, 16.1798918],
                [108.0614108, 16.180139],
                [108.0616507, 16.1803585],
                [108.0617648, 16.1804739],
                [108.061832, 16.1805637],
                [108.0618946, 16.1806565],
                [108.0620037, 16.1808462],
                [108.0627948, 16.1825141],
                [108.0641413, 16.1840545],
                [108.0669844, 16.1833538],
                [108.0697326, 16.1823736],
                [108.0700099, 16.1823028],
                [108.0701825, 16.1822712],
                [108.0703566, 16.1822486],
                [108.0705317, 16.1822351],
                [108.0736953, 16.182174],
                [108.0740336, 16.1823976],
                [108.0743551, 16.1826429],
                [108.0746014, 16.182856],
                [108.0749989, 16.183225],
                [108.0750751, 16.1832674],
                [108.0751557, 16.1833015],
                [108.0752397, 16.183327],
                [108.0754064, 16.1833507],
                [108.0755672, 16.1833417],
                [108.0756462, 16.1833258],
                [108.07813, 16.1825864],
                [108.0783897, 16.1825369],
                [108.0785214, 16.1825234],
                [108.0787861, 16.1825192],
                [108.0789302, 16.1825297],
                [108.0790732, 16.1825493],
                [108.0792146, 16.1825777],
                [108.0793537, 16.1826149],
                [108.07949, 16.1826607],
                [108.0797559, 16.1827785],
                [108.0798846, 16.1828497],
                [108.0801272, 16.1830138],
                [108.0815381, 16.1825759],
                [108.0820147, 16.182477],
                [108.0822553, 16.1824389],
                [108.0827397, 16.1823853],
                [108.0832514, 16.1823618],
                [108.0837637, 16.1823721],
                [108.0840192, 16.18239],
                [108.0854514, 16.1825347],
                [108.0856873, 16.1825336],
                [108.0858047, 16.1825213],
                [108.0860287, 16.1824752],
                [108.086238, 16.1824031],
                [108.0863386, 16.1823574],
                [108.0869293, 16.1820504],
                [108.087149, 16.1819629],
                [108.0873891, 16.1818928],
                [108.0876481, 16.1818443],
                [108.0888015, 16.1817361],
                [108.0893628, 16.1814501],
                [108.0896383, 16.1812982],
                [108.0901242, 16.1810109],
                [108.0910116, 16.1804636],
                [108.0911358, 16.1803988],
                [108.0913936, 16.1802879],
                [108.0916792, 16.1801983],
                [108.0919908, 16.1801364],
                [108.0921489, 16.1801184],
                [108.092305, 16.1801304],
                [108.09246, 16.1801517],
                [108.0926134, 16.180182],
                [108.0927645, 16.1802213],
                [108.0929078, 16.1802677],
                [108.0931846, 16.1803845],
                [108.0933171, 16.1804545],
                [108.0935148, 16.180576],
                [108.0936416, 16.1806804],
                [108.0937482, 16.1808037],
                [108.0937958, 16.1808761],
                [108.0938698, 16.1810317],
                [108.0938955, 16.1811136],
                [108.0939234, 16.1812826],
                [108.0939032, 16.182525],
                [108.0940395, 16.1827012],
                [108.0941833, 16.1828719],
                [108.0943345, 16.1830365],
                [108.0944928, 16.1831949],
                [108.0948213, 16.1834853],
                [108.0949905, 16.1836175],
                [108.0953446, 16.1838617],
                [108.095529, 16.1839734],
                [108.0960638, 16.1842621],
                [108.0963556, 16.1844564],
                [108.0964939, 16.1845633],
                [108.0972275, 16.1852606],
                [108.0975102, 16.1854798],
                [108.0978039, 16.1856659],
                [108.0979521, 16.1857461],
                [108.0982593, 16.1858866],
                [108.0999673, 16.1865434],
                [108.1001139, 16.1865883],
                [108.1002633, 16.186624],
                [108.1004147, 16.1866503],
                [108.1005676, 16.1866671],
                [108.1007213, 16.1866743],
                [108.1008752, 16.1866719],
                [108.1010286, 16.1866599],
                [108.1013205, 16.1866099],
                [108.1014634, 16.1865718],
                [108.1017405, 16.1864703],
                [108.1018735, 16.1864073],
                [108.1020022, 16.1863363],
                [108.1022323, 16.1861726],
                [108.102354, 16.1860999],
                [108.1026086, 16.185974],
                [108.1027557, 16.1859157],
                [108.1029061, 16.1858659],
                [108.1030594, 16.1858249],
                [108.1033048, 16.1857755],
                [108.1033958, 16.1857654],
                [108.1035788, 16.1857669],
                [108.1036721, 16.1857789],
                [108.1038537, 16.1858253],
                [108.1040245, 16.1859004],
                [108.1044582, 16.1861519],
                [108.1045314, 16.1861872],
                [108.1046862, 16.1862386],
                [108.1048479, 16.1862632],
                [108.1050253, 16.1862587],
                [108.1051197, 16.1862428],
                [108.1052118, 16.1862178],
                [108.1053008, 16.1861838],
                [108.1053856, 16.1861412],
                [108.1066513, 16.1852945],
                [108.1072078, 16.1852653],
                [108.1074153, 16.1852342],
                [108.1076149, 16.1851716],
                [108.1077174, 16.1851252],
                [108.1078154, 16.1850705],
                [108.107908, 16.1850078],
                [108.1079946, 16.1849376],
                [108.1080745, 16.1848604],
                [108.1082945, 16.1845912],
                [108.1083771, 16.1845055],
                [108.1085582, 16.1843498],
                [108.1087596, 16.1842165],
                [108.1088665, 16.1841593],
                [108.1089769, 16.1841088],
                [108.1092045, 16.184022],
                [108.1094207, 16.1839117],
                [108.1095217, 16.1838451],
                [108.1097027, 16.1836944],
                [108.1098586, 16.1835196],
                [108.1103639, 16.1828032],
                [108.1106132, 16.1825315],
                [108.1108289, 16.1822597],
                [108.1109205, 16.1821948],
                [108.1109712, 16.1821696],
                [108.1110885, 16.1821333],
                [108.1112209, 16.1821235],
                [108.111287, 16.1821303],
                [108.1120589, 16.1824295],
                [108.1122334, 16.1824687],
                [108.1124123, 16.1824797],
                [108.1126099, 16.1824586],
                [108.1127064, 16.1824351],
                [108.1128003, 16.1824032],
                [108.1130459, 16.1822979],
                [108.1132107, 16.182252],
                [108.1133825, 16.1822273],
                [108.1136116, 16.1822144],
                [108.1137722, 16.1821745],
                [108.1138542, 16.1821626],
                [108.1139519, 16.1821556],
                [108.1141475, 16.1821646],
                [108.1142441, 16.1821805],
                [108.1144316, 16.1822347],
                [108.1156936, 16.1828032],
                [108.1158536, 16.1828339],
                [108.1160166, 16.1828393],
                [108.1160979, 16.1828323],
                [108.1162601, 16.1827988],
                [108.1164142, 16.18274],
                [108.1168425, 16.1824894],
                [108.1170805, 16.1823789],
                [108.1173276, 16.1822888],
                [108.1175458, 16.182086],
                [108.1177889, 16.1818947],
                [108.118057, 16.1817182],
                [108.1183759, 16.1815284],
                [108.1185505, 16.181412],
                [108.1188268, 16.1812049],
                [108.1189236, 16.181157],
                [108.1189755, 16.181141],
                [108.119095, 16.1811254],
                [108.1191613, 16.1811288],
                [108.1192894, 16.1811608],
                [108.1194733, 16.1812433],
                [108.1197297, 16.1813343],
                [108.1198496, 16.1813676],
                [108.1202696, 16.1814571],
                [108.1204439, 16.1815026],
                [108.1216163, 16.1818585],
                [108.1221555, 16.1819724],
                [108.1224225, 16.1820397],
                [108.1229504, 16.182195],
                [108.1235242, 16.1823985],
                [108.1240843, 16.1826345],
                [108.1281116, 16.1845569],
                [108.130005, 16.1865628],
                [108.1302004, 16.1867958],
                [108.1304323, 16.1871189],
                [108.1305236, 16.1872228],
                [108.130622, 16.1873205],
                [108.130727, 16.1874116],
                [108.1308382, 16.1874956],
                [108.1319994, 16.1882517],
                [108.1340141, 16.19007],
                [108.1355773, 16.1907429],
                [108.1356828, 16.1907956],
                [108.1357835, 16.1908562],
                [108.1358789, 16.1909244],
                [108.1359682, 16.1909998],
                [108.1360509, 16.1910818],
                [108.1361264, 16.19117],
                [108.136184, 16.1912484],
                [108.1362819, 16.1914155],
                [108.1363555, 16.1915938],
                [108.1363944, 16.1917258],
                [108.1364919, 16.1919836],
                [108.136611, 16.1922104],
                [108.1366785, 16.1923391],
                [108.136747, 16.1924442],
                [108.1368979, 16.1926453],
                [108.137078, 16.1928041],
                [108.1372752, 16.192943],
                [108.1374824, 16.193058],
                [108.1375905, 16.1931073],
                [108.1378142, 16.1931888],
                [108.1379543, 16.1932588],
                [108.1380195, 16.1933018],
                [108.1381377, 16.1934024],
                [108.1381775, 16.1934612],
                [108.138273, 16.1935672],
                [108.138387, 16.1936547],
                [108.1385356, 16.1937286],
                [108.1386969, 16.1937712],
                [108.1392764, 16.1937647],
                [108.1394194, 16.1938407],
                [108.1396941, 16.1940106],
                [108.139822, 16.1941016],
                [108.1400648, 16.1942992],
                [108.1410485, 16.1952077],
                [108.1413129, 16.1954961],
                [108.1415538, 16.195803],
                [108.1417739, 16.1961333],
                [108.1419666, 16.1964791],
                [108.1424297, 16.1974529],
                [108.1426127, 16.197809],
                [108.142825, 16.1981496],
                [108.1430618, 16.1984682],
                [108.1433203, 16.1987634],
                [108.1436021, 16.1990382],
                [108.1438631, 16.199223],
                [108.1439994, 16.1993072],
                [108.1442827, 16.1994588],
                [108.1444538, 16.1995364],
                [108.1446288, 16.1996057],
                [108.1448071, 16.1996667],
                [108.1449883, 16.1997191],
                [108.1451721, 16.1997629],
                [108.1453832, 16.1998007],
                [108.145596, 16.1998289],
                [108.1458098, 16.1998476],
                [108.1460244, 16.1998567],
                [108.1462348, 16.1998562],
                [108.1464449, 16.1998465],
                [108.1466544, 16.1998276],
                [108.1469441, 16.1996917],
                [108.1470186, 16.1996745],
                [108.1471707, 16.1996583],
                [108.1472473, 16.1996593],
                [108.1474215, 16.1996851],
                [108.1475058, 16.1997099],
                [108.1476651, 16.1997823],
                [108.1478271, 16.1999273],
                [108.1481676, 16.2001993],
                [108.1483456, 16.2003258],
                [108.1486917, 16.2005449],
                [108.1490531, 16.2007399],
                [108.1500907, 16.2012446],
                [108.150252, 16.2013008],
                [108.1505826, 16.2013882],
                [108.1507511, 16.2014193],
                [108.1510802, 16.2014549],
                [108.1512457, 16.2014605],
                [108.1515301, 16.2014492],
                [108.1516477, 16.201431],
                [108.1517633, 16.2014037],
                [108.1518763, 16.2013675],
                [108.1519874, 16.2013219],
                [108.1520942, 16.2012676],
                [108.1521959, 16.2012051],
                [108.152292, 16.2011347],
                [108.1524507, 16.2009923],
                [108.1526059, 16.2008824],
                [108.1526906, 16.2008381],
                [108.1528665, 16.2007737],
                [108.153047, 16.2007408],
                [108.1532307, 16.2007383],
                [108.153412, 16.2007664],
                [108.1536072, 16.2008215],
                [108.1537163, 16.2008434],
                [108.1538268, 16.2008572],
                [108.1539381, 16.2008628],
                [108.1541594, 16.2008498],
                [108.1543761, 16.2008046],
                [108.1549128, 16.2006169],
                [108.1551846, 16.2005328],
                [108.1556832, 16.200397],
                [108.155747, 16.2006436],
                [108.1557843, 16.2008952],
                [108.1557942, 16.201177],
                [108.1557708, 16.2014581],
                [108.1557978, 16.2018204],
                [108.1558258, 16.2020108],
                [108.1558633, 16.2021996],
                [108.1559101, 16.2023865],
                [108.1559662, 16.202571],
                [108.1560269, 16.2028686],
                [108.156076, 16.2032647],
                [108.1561077, 16.2039263],
                [108.1561414, 16.2041109],
                [108.1561747, 16.2042199],
                [108.1562175, 16.2043259],
                [108.1562694, 16.204428],
                [108.1567917, 16.20529],
                [108.1568635, 16.2053904],
                [108.1570286, 16.205575],
                [108.1572195, 16.2057349],
                [108.1574112, 16.2058558],
                [108.1576176, 16.2059517],
                [108.1577253, 16.2059898],
                [108.1579472, 16.2060455],
                [108.1600066, 16.2064474],
                [108.1602337, 16.2064675],
                [108.1604617, 16.2064604],
                [108.1607015, 16.2064231],
                [108.160826, 16.2063911],
                [108.1610666, 16.2063024],
                [108.1611814, 16.2062461],
                [108.1617002, 16.2059291],
                [108.1624548, 16.2056573],
                [108.1626762, 16.2055519],
                [108.1628798, 16.2054175],
                [108.1629737, 16.2053403],
                [108.1630582, 16.2052603],
                [108.1632095, 16.2050847],
                [108.1632943, 16.2049989],
                [108.1634756, 16.2048388],
                [108.1637266, 16.2046592],
                [108.1638597, 16.20458],
                [108.1649445, 16.2041302],
                [108.1651873, 16.2040427],
                [108.1654429, 16.2039767],
                [108.1655756, 16.2039522],
                [108.1658438, 16.2039222],
                [108.1659788, 16.2039168],
                [108.1663494, 16.2039298],
                [108.1668345, 16.2038521],
                [108.167189, 16.2038174],
                [108.1673668, 16.2038068],
                [108.1687144, 16.2034445],
                [108.1688136, 16.2034105],
                [108.1689089, 16.2033676],
                [108.1689997, 16.2033162],
                [108.169085, 16.2032568],
                [108.1692219, 16.2031319],
                [108.1692817, 16.2030615],
                [108.1693814, 16.2029075],
                [108.1695081, 16.2026021],
                [108.169651, 16.2023369],
                [108.1698011, 16.2021102],
                [108.1698834, 16.2020016],
                [108.1700715, 16.2018048],
                [108.1702843, 16.2016328],
                [108.1705182, 16.2014884],
                [108.1706419, 16.2014273],
                [108.1707694, 16.201374],
                [108.1709455, 16.2013195],
                [108.1710356, 16.2012996],
                [108.1711552, 16.2012949],
                [108.1712143, 16.2013046],
                [108.1713231, 16.2013461],
                [108.1714143, 16.2014127],
                [108.1714837, 16.2015002],
                [108.171722, 16.2019128],
                [108.1719351, 16.2023381],
                [108.1720421, 16.2025787],
                [108.1722552, 16.2031321],
                [108.1722857, 16.2031923],
                [108.1723655, 16.2033018],
                [108.1724674, 16.2033927],
                [108.1725955, 16.2034648],
                [108.1727371, 16.2035076],
                [108.1728108, 16.2035173],
                [108.1730523, 16.2035287],
                [108.1734947, 16.2035859],
                [108.174071, 16.203603],
                [108.1742067, 16.2036283],
                [108.1743338, 16.2036806],
                [108.1744303, 16.2037425],
                [108.1744873, 16.2038032],
                [108.17451, 16.2038423],
                [108.1745767, 16.2040221],
                [108.174637, 16.2041562],
                [108.174692, 16.2042607],
                [108.1748189, 16.2044603],
                [108.1752097, 16.2049844],
                [108.1752926, 16.2050748],
                [108.1754749, 16.2052401],
                [108.1756706, 16.2053788],
                [108.1758754, 16.205491],
                [108.1760924, 16.2055797],
                [108.176247, 16.2056287],
                [108.1765506, 16.2057414],
                [108.176707, 16.2058085],
                [108.1770121, 16.2059582],
                [108.1771767, 16.205912],
                [108.1773476, 16.2058979],
                [108.1775196, 16.2059169],
                [108.177604, 16.2059389],
                [108.1776857, 16.2059689],
                [108.1779029, 16.2060822],
                [108.1779712, 16.2061053],
                [108.1783312, 16.2061648],
                [108.1785272, 16.2062264],
                [108.1786994, 16.2063074],
                [108.1789872, 16.2064939],
                [108.1790976, 16.2065518],
                [108.1793249, 16.2066425],
                [108.1795579, 16.2067009],
                [108.179677, 16.2067184],
                [108.1799025, 16.2067334],
                [108.1801284, 16.2067249],
                [108.1803435, 16.2067002],
                [108.1804518, 16.206699],
                [108.1806354, 16.2067142],
                [108.1808157, 16.2067508],
                [108.1820284, 16.207113],
                [108.1823856, 16.2071907],
                [108.1825733, 16.2072174],
                [108.1827621, 16.2072347],
                [108.1832123, 16.2072468],
                [108.1835257, 16.207275],
                [108.1837077, 16.207303],
                [108.1857198, 16.2076988],
                [108.1861925, 16.2077795],
                [108.186789, 16.2078488],
                [108.1870887, 16.2078701],
                [108.1888359, 16.2080385],
                [108.1893998, 16.2081224],
                [108.1898708, 16.208218],
                [108.1903364, 16.2083359],
                [108.19189, 16.2088492],
                [108.1922432, 16.208983],
                [108.1926413, 16.2091586],
                [108.1947857, 16.21023],
                [108.1991691, 16.2129762],
                [108.2056713, 16.2170498],
                [108.2105656, 16.2191327],
                [108.4805829, 16.3340091],
                [107.6883175, 17.1145026],
                [107.5168222, 16.9018096],
                [107.3889166, 16.7428454],
                [107.3889858, 16.7416091],
                [107.3888516, 16.7416624],
                [107.3887477, 16.7417359],
                [107.3886732, 16.7418071],
                [107.3885364, 16.7419571],
                [107.3884425, 16.7421199],
                [107.3884012, 16.7421695],
                [107.3883325, 16.7422207],
                [107.38824, 16.7422732],
                [107.38791, 16.7424362],
                [107.3878029, 16.7424655],
                [107.3877324, 16.742462],
                [107.3876598, 16.7424214],
                [107.3876235, 16.7423801],
                [107.3875964, 16.7423155],
                [107.3875796, 16.7422442],
                [107.3875878, 16.7421412],
                [107.3875768, 16.7420946],
                [107.3875303, 16.7420226],
                [107.387392, 16.7418801],
                [107.3873321, 16.7417997],
                [107.387273, 16.7417035],
                [107.3871636, 16.741486],
                [107.3870014, 16.7412644],
                [107.3867627, 16.7410258],
                [107.3865736, 16.7408788],
                [107.3863368, 16.740719],
                [107.3860258, 16.7406104],
                [107.3853885, 16.7403152],
                [107.3850891, 16.7401948],
                [107.3849218, 16.7401439],
                [107.3841107, 16.7399464],
                [107.3840063, 16.7399722],
                [107.3836966, 16.7400995],
                [107.3836147, 16.7400998],
                [107.3835314, 16.7400697],
                [107.3833834, 16.7399549],
                [107.3830837, 16.7396584],
                [107.382854, 16.7394729],
                [107.3768277, 16.7368981],
                [107.3746209, 16.7356516],
                [107.3721842, 16.7347033],
                [107.370177, 16.734227],
                [107.3697914, 16.7335049],
                [107.3695378, 16.7329856],
                [107.3682994, 16.7308884],
                [107.3674027, 16.7292982],
                [107.3659432, 16.7269197],
                [107.3650664, 16.7255108],
                [107.3641569, 16.7238298],
                [107.3626769, 16.7212244],
                [107.3620576, 16.7201531],
                [107.3611727, 16.7185627],
                [107.3599851, 16.7167372],
                [107.3585334, 16.713791],
                [107.3582606, 16.7131471],
                [107.3581098, 16.712848],
                [107.3579913, 16.7127812],
                [107.3579085, 16.7127481],
                [107.3577683, 16.7125909],
                [107.3571701, 16.7115647],
                [107.3568692, 16.7110175],
                [107.3564038, 16.710268],
                [107.3560451, 16.709818],
                [107.3558551, 16.7096838],
                [107.3557962, 16.7096732],
                [107.3557318, 16.7097023],
                [107.3552883, 16.7100139],
                [107.3544945, 16.7105451],
                [107.3544444, 16.71054],
                [107.3537342, 16.7094923],
                [107.3532481, 16.7087374],
                [107.3531765, 16.7086473],
                [107.3531529, 16.7086476],
                [107.3528592, 16.7089347],
                [107.3521894, 16.7094986],
                [107.352154, 16.7094989],
                [107.3521126, 16.7094824],
                [107.3515778, 16.7088244],
                [107.3511713, 16.7083069],
                [107.351165, 16.7082786],
                [107.3517996, 16.7074711],
                [107.3526229, 16.7062187],
                [107.352953, 16.7057779],
                [107.3532384, 16.7055363],
                [107.3534081, 16.7054549],
                [107.3535171, 16.7054592],
                [107.3540197, 16.7056636],
                [107.3544575, 16.7058516],
                [107.354581, 16.7058445],
                [107.3547272, 16.7057746],
                [107.3550196, 16.7053855],
                [107.3554444, 16.7049832],
                [107.3556954, 16.7048215],
                [107.3558771, 16.7047626],
                [107.3561651, 16.7047252],
                [107.3564112, 16.7046373],
                [107.3565514, 16.7045563],
                [107.356714, 16.7043786],
                [107.3568351, 16.7041785],
                [107.3570439, 16.7039206],
                [107.3572417, 16.7037367],
                [107.357376, 16.7036558],
                [107.3574107, 16.7036043],
                [107.3574101, 16.7035533],
                [107.3573915, 16.7034853],
                [107.3573099, 16.7032933],
                [107.3572111, 16.7029312],
                [107.3571841, 16.7026477],
                [107.3572186, 16.7025678],
                [107.3573468, 16.7024755],
                [107.3576042, 16.7023477],
                [107.3579213, 16.7022987],
                [107.3581085, 16.7022057],
                [107.3582477, 16.7020339],
                [107.3583616, 16.7017148],
                [107.3583734, 16.7012492],
                [107.358342, 16.7006025],
                [107.358494, 16.6995677],
                [107.3584559, 16.6993411],
                [107.3582736, 16.6988778],
                [107.3581785, 16.698788],
                [107.3580899, 16.6987664],
                [107.3578629, 16.6987693],
                [107.3577149, 16.6987428],
                [107.3573241, 16.6985944],
                [107.3565577, 16.6983669],
                [107.3556615, 16.6981409],
                [107.3548325, 16.6981087],
                [107.3543003, 16.6981841],
                [107.3537552, 16.698385],
                [107.3532104, 16.6986149],
                [107.3520377, 16.6984365],
                [107.3513319, 16.698035],
                [107.3494333, 16.6967276],
                [107.3485966, 16.6954899],
                [107.3484181, 16.6948328],
                [107.3482694, 16.6939817],
                [107.3477988, 16.6930162],
                [107.3473958, 16.6922437],
                [107.3469264, 16.6918579],
                [107.3452516, 16.6912809],
                [107.3440205, 16.6908881],
                [107.3434427, 16.6906397],
                [107.342973, 16.6900605],
                [107.3420697, 16.6882373],
                [107.3412935, 16.6869065],
                [107.3399521, 16.68562],
                [107.3380123, 16.6846254],
                [107.3371414, 16.6842349],
                [107.3366532, 16.6838486],
                [107.3363749, 16.6833619],
                [107.3360057, 16.6822113],
                [107.3355986, 16.6811467],
                [107.3351526, 16.6806056],
                [107.3346114, 16.6800997],
                [107.3344463, 16.6799658],
                [107.3340869, 16.6795187],
                [107.3340413, 16.679439],
                [107.3339635, 16.6792643],
                [107.3339394, 16.6791153],
                [107.3338991, 16.678568],
                [107.3339314, 16.6783866],
                [107.3338787, 16.6773887],
                [107.3335302, 16.6761615],
                [107.333395, 16.6754526],
                [107.3324695, 16.6749421],
                [107.3313374, 16.6739799],
                [107.3310241, 16.6734527],
                [107.3308068, 16.6729478],
                [107.3301333, 16.6727439],
                [107.3294972, 16.6727076],
                [107.3289967, 16.6727088],
                [107.3285947, 16.6726568],
                [107.3278254, 16.6724309],
                [107.3270046, 16.672063],
                [107.3264727, 16.6717893],
                [107.3261471, 16.6716051],
                [107.3258788, 16.6714387],
                [107.3254793, 16.6710323],
                [107.325323, 16.6708392],
                [107.3251639, 16.6706844],
                [107.3248084, 16.6705015],
                [107.3244853, 16.6703613],
                [107.3242658, 16.6702528],
                [107.3239133, 16.6699505],
                [107.323869, 16.6698467],
                [107.3238576, 16.6697323],
                [107.3238945, 16.6695475],
                [107.3239713, 16.6692871],
                [107.3240533, 16.6690423],
                [107.3241794, 16.6688643],
                [107.3243337, 16.6687123],
                [107.3245122, 16.6686243],
                [107.3247449, 16.6685594],
                [107.3249381, 16.66852],
                [107.3252041, 16.6684797],
                [107.3255279, 16.6683921],
                [107.3258339, 16.6683723],
                [107.3264281, 16.6684175],
                [107.3266235, 16.6684528],
                [107.3268933, 16.6685196],
                [107.3272387, 16.6686255],
                [107.3276961, 16.6688691],
                [107.3279574, 16.6686046],
                [107.3282206, 16.6683712],
                [107.3285905, 16.668193],
                [107.3288678, 16.6680159],
                [107.3293232, 16.667641],
                [107.3296596, 16.6673068],
                [107.3297881, 16.6671313],
                [107.3300096, 16.6667982],
                [107.33017, 16.6666355],
                [107.3302364, 16.6663407],
                [107.3287807, 16.6652984],
                [107.3276619, 16.665025],
                [107.3272231, 16.6650785],
                [107.3249876, 16.6652825],
                [107.3232803, 16.6651562],
                [107.3226773, 16.6648993],
                [107.322074, 16.6644489],
                [107.3210009, 16.6631613],
                [107.3199952, 16.6623891],
                [107.3190581, 16.6623261],
                [107.3186563, 16.6623267],
                [107.318254, 16.6619405],
                [107.3180509, 16.6605225],
                [107.318302, 16.6598673],
                [107.3191191, 16.6587802],
                [107.3205896, 16.6574885],
                [107.3212579, 16.6567783],
                [107.3216594, 16.6566487],
                [107.322061, 16.6566481],
                [107.3234674, 16.6569683],
                [107.3240025, 16.6567741],
                [107.3244037, 16.6565156],
                [107.3249384, 16.6559989],
                [107.3250044, 16.6554186],
                [107.3249369, 16.6550964],
                [107.3249146, 16.6548028],
                [107.325422, 16.6543616],
                [107.3262082, 16.6536017],
                [107.3277164, 16.6516173],
                [107.3287043, 16.6499273],
                [107.3292098, 16.6484337],
                [107.3291076, 16.6480913],
                [107.3290054, 16.6476999],
                [107.3291063, 16.6473328],
                [107.329385, 16.6469163],
                [107.3295116, 16.646696],
                [107.3295112, 16.6465002],
                [107.3294854, 16.6461821],
                [107.3296117, 16.6458149],
                [107.3298401, 16.6456187],
                [107.3300938, 16.6454469],
                [107.330246, 16.6452999],
                [107.3302964, 16.6450306],
                [107.3301943, 16.6448106],
                [107.3299146, 16.6446398],
                [107.3291516, 16.6440781],
                [107.3288461, 16.6437361],
                [107.3287696, 16.6435159],
                [107.3288196, 16.6430753],
                [107.328743, 16.6428308],
                [107.3282599, 16.6425869],
                [107.3280819, 16.6425137],
                [107.3278275, 16.6422205],
                [107.3277507, 16.641927],
                [107.327598, 16.641756],
                [107.3275218, 16.6417316],
                [107.327395, 16.6418541],
                [107.3271923, 16.6421482],
                [107.3271164, 16.642344],
                [107.3270658, 16.6424419],
                [107.3266084, 16.6424182],
                [107.3262529, 16.6424432],
                [107.3257959, 16.6426152],
                [107.3256438, 16.6427623],
                [107.3255172, 16.6430561],
                [107.3254416, 16.64335],
                [107.3252894, 16.6435703],
                [107.3250109, 16.6440846],
                [107.3248593, 16.6445498],
                [107.3248097, 16.6452595],
                [107.3249133, 16.6463851],
                [107.3251063, 16.6478775],
                [107.3250056, 16.6484649],
                [107.3243221, 16.6498363],
                [107.3232199, 16.6515509],
                [107.3226885, 16.6527997],
                [107.3224856, 16.6530692],
                [107.321955, 16.6532958],
                [107.3215531, 16.6531676],
                [107.3213498, 16.6529153],
                [107.3209766, 16.6526514],
                [107.3197411, 16.6524617],
                [107.3187757, 16.6524632],
                [107.31842, 16.6524393],
                [107.3178862, 16.6522688],
                [107.3174538, 16.6519513],
                [107.3169065, 16.6512914],
                [107.3164483, 16.6507538],
                [107.3159646, 16.6500694],
                [107.3155826, 16.6494093],
                [107.315175, 16.6487247],
                [107.3137628, 16.647381],
                [107.312211, 16.6459885],
                [107.3090436, 16.6430813],
                [107.3074915, 16.6415176],
                [107.3068538, 16.6409497],
                [107.3066507, 16.6399037],
                [107.3070166, 16.638386],
                [107.3074725, 16.6375779],
                [107.3078779, 16.6369165],
                [107.3082583, 16.6365],
                [107.3087959, 16.6361366],
                [107.310281, 16.6354677],
                [107.311978, 16.6354667],
                [107.3128664, 16.6359327],
                [107.3134025, 16.636688],
                [107.3135269, 16.6376693],
                [107.3125658, 16.640489],
                [107.3131702, 16.6418445],
                [107.3142665, 16.6418032],
                [107.3153219, 16.6406771],
                [107.3159469, 16.6393025],
                [107.3160225, 16.6388863],
                [107.3155505, 16.6376146],
                [107.3149636, 16.6360739],
                [107.3131543, 16.6324794],
                [107.3124663, 16.6310612],
                [107.3116755, 16.6290559],
                [107.3105537, 16.6264148],
                [107.3104005, 16.6259011],
                [107.3103236, 16.6255097],
                [107.3103231, 16.625216],
                [107.3105763, 16.6246773],
                [107.311388, 16.6240155],
                [107.3145227, 16.6226404],
                [107.3159697, 16.6221488],
                [107.3182546, 16.6214846],
                [107.3192448, 16.6212139],
                [107.3197017, 16.621042],
                [107.3202598, 16.6206495],
                [107.3206653, 16.620086],
                [107.3218187, 16.6187384],
                [107.3224269, 16.6179299],
                [107.3227553, 16.6168772],
                [107.3229321, 16.6162163],
                [107.3231487, 16.6145132],
                [107.3233687, 16.6141256],
                [107.324666, 16.6126899],
                [107.3265796, 16.6102888],
                [107.3275426, 16.6090637],
                [107.3285776, 16.6079565],
                [107.3267859, 16.6065993],
                [107.3249218, 16.6049466],
                [107.3259813, 16.6041138],
                [107.326353, 16.6038526],
                [107.3264949, 16.6037617],
                [107.3279446, 16.6029083],
                [107.3282604, 16.6027409],
                [107.3284244, 16.6026688],
                [107.3287627, 16.6025485],
                [107.3289433, 16.6024988],
                [107.3291264, 16.6024582],
                [107.3293115, 16.6024267],
                [107.3294979, 16.6024045],
                [107.3296854, 16.6023917],
                [107.3311888, 16.6023158],
                [107.3311944, 16.6020941],
                [107.3312102, 16.6019945],
                [107.33126, 16.6018169],
                [107.3312947, 16.6017311],
                [107.331538, 16.6015142],
                [107.331799, 16.6013172],
                [107.3320961, 16.6011299],
                [107.3324092, 16.6009683],
                [107.3328128, 16.6006232],
                [107.3333734, 16.6002582],
                [107.3333278, 16.6001477],
                [107.3340869, 16.5996182],
                [107.3344973, 16.5987828],
                [107.3343486, 16.5987081],
                [107.3341955, 16.5986421],
                [107.3339199, 16.5985483],
                [107.3336899, 16.5984564],
                [107.333485, 16.5983481],
                [107.333293, 16.5982199],
                [107.3330053, 16.5983612],
                [107.3327463, 16.598458],
                [107.3325232, 16.5985206],
                [107.3323479, 16.5985156],
                [107.3321739, 16.5985365],
                [107.3319999, 16.5985849],
                [107.3318312, 16.598662],
                [107.3316824, 16.5987062],
                [107.3315516, 16.5987671],
                [107.3314396, 16.5988394],
                [107.3310788, 16.5991594],
                [107.330997, 16.5992202],
                [107.3308193, 16.5993218],
                [107.3307246, 16.599362],
                [107.3305355, 16.5994176],
                [107.3303488, 16.5994439],
                [107.3301601, 16.5994434],
                [107.3299801, 16.599397],
                [107.3298117, 16.5993202],
                [107.3296667, 16.5992207],
                [107.3295469, 16.5991046],
                [107.329495, 16.5990398],
                [107.3293387, 16.598984],
                [107.3292579, 16.5989642],
                [107.3290674, 16.5989396],
                [107.3289584, 16.598939],
                [107.3288499, 16.598948],
                [107.3287685, 16.5989788],
                [107.3286011, 16.599027],
                [107.3283683, 16.5990629],
                [107.3272151, 16.599081],
                [107.3272178, 16.5986864],
                [107.3266478, 16.598716],
                [107.3266277, 16.5985232],
                [107.3262253, 16.5985887],
                [107.3261817, 16.5984967],
                [107.326123, 16.5984234],
                [107.3260912, 16.5983947],
                [107.3259703, 16.598349],
                [107.3258184, 16.5983186],
                [107.3257282, 16.5983134],
                [107.3254957, 16.5983234],
                [107.3252115, 16.5983098],
                [107.3249506, 16.5982673],
                [107.3248225, 16.5982353],
                [107.3246589, 16.5984692],
                [107.3246357, 16.59849],
                [107.3245799, 16.5985187],
                [107.3245104, 16.5985274],
                [107.3244363, 16.5985078],
                [107.3242003, 16.5982122],
                [107.3243935, 16.5977181],
                [107.3244727, 16.5975951],
                [107.3245651, 16.5974976],
                [107.3245731, 16.5971737],
                [107.3243505, 16.5971609],
                [107.3243397, 16.596472],
                [107.3240849, 16.5956648],
                [107.3241328, 16.5954657],
                [107.3241681, 16.5953692],
                [107.3242566, 16.5951919],
                [107.324447, 16.5951045],
                [107.3244329, 16.5949616],
                [107.3244145, 16.594892],
                [107.3243887, 16.5948246],
                [107.3243223, 16.5947076],
                [107.3242415, 16.5946121],
                [107.3241949, 16.5945698],
                [107.3240876, 16.5945505],
                [107.3241305, 16.5940917],
                [107.3241332, 16.5930506],
                [107.3240644, 16.5928907],
                [107.3240413, 16.592807],
                [107.3240185, 16.5926346],
                [107.3240279, 16.5924604],
                [107.3240447, 16.5923746],
                [107.3240474, 16.592025],
                [107.3240551, 16.5919618],
                [107.3240908, 16.5918395],
                [107.324152, 16.5917268],
                [107.3242509, 16.591615],
                [107.3243101, 16.5915684],
                [107.3243746, 16.5915289],
                [107.3250881, 16.591187],
                [107.3252082, 16.5910916],
                [107.3252597, 16.5910353],
                [107.3253419, 16.5909105],
                [107.3253724, 16.5908426],
                [107.3254211, 16.5906882],
                [107.3254864, 16.5904047],
                [107.3255199, 16.5901485],
                [107.3259544, 16.5900431],
                [107.3262338, 16.5899882],
                [107.326515, 16.5899429],
                [107.3268959, 16.5895856],
                [107.3268262, 16.5894622],
                [107.3261073, 16.589511],
                [107.3260527, 16.5894463],
                [107.3260349, 16.5894082],
                [107.3260211, 16.5893324],
                [107.326033, 16.5892563],
                [107.3260661, 16.5891715],
                [107.3260805, 16.5890689],
                [107.3260648, 16.5889619],
                [107.3260188, 16.5888632],
                [107.3260161, 16.5883414],
                [107.3262978, 16.5879991],
                [107.3263407, 16.5879121],
                [107.3263594, 16.5878196],
                [107.3262387, 16.5877579],
                [107.326228, 16.5875831],
                [107.3265204, 16.5875754],
                [107.3265646, 16.5870259],
                [107.3264366, 16.5870253],
                [107.3264397, 16.5868793],
                [107.3264626, 16.5867252],
                [107.3264828, 16.5866448],
                [107.3266625, 16.5862567],
                [107.3258364, 16.5862232],
                [107.3255089, 16.5860692],
                [107.3252197, 16.5859015],
                [107.3249674, 16.5857271],
                [107.3249049, 16.5856984],
                [107.3248707, 16.585692],
                [107.3248012, 16.5856959],
                [107.3247678, 16.5857063],
                [107.3247367, 16.585722],
                [107.3246027, 16.5858238],
                [107.3244524, 16.5859019],
                [107.3243629, 16.5859344],
                [107.3241761, 16.5859739],
                [107.3241386, 16.5858094],
                [107.3240769, 16.5856423],
                [107.3237872, 16.5852644],
                [107.3237438, 16.5852234],
                [107.3236502, 16.5851596],
                [107.3235512, 16.5851179],
                [107.3230295, 16.5849932],
                [107.3230147, 16.5837606],
                [107.3219043, 16.5835472],
                [107.3212264, 16.5835858],
                [107.3205364, 16.5833159],
                [107.3202065, 16.5832953],
                [107.320037, 16.5832257],
                [107.3198581, 16.5831829],
                [107.3196924, 16.5831682],
                [107.319544, 16.5831745],
                [107.3194608, 16.5831685],
                [107.3192989, 16.583131],
                [107.3192221, 16.5830999],
                [107.3190867, 16.583019],
                [107.3190263, 16.5829688],
                [107.3188063, 16.5827375],
                [107.3186586, 16.5826188],
                [107.3185769, 16.5825696],
                [107.3184909, 16.5825276],
                [107.3183092, 16.5824669],
                [107.3181193, 16.5824385],
                [107.3180231, 16.5824367],
                [107.3169878, 16.5812233],
                [107.3169806, 16.5810918],
                [107.3169637, 16.5809611],
                [107.3169117, 16.58074],
                [107.3168752, 16.5806321],
                [107.3167223, 16.5805524],
                [107.3165452, 16.5805017],
                [107.3164545, 16.5804844],
                [107.3162556, 16.580466],
                [107.316041, 16.5804753],
                [107.3157942, 16.5806321],
                [107.3154026, 16.5808043],
                [107.3153224, 16.5808301],
                [107.3151556, 16.5808563],
                [107.315071, 16.5808565],
                [107.3149146, 16.5808336],
                [107.3147759, 16.5807859],
                [107.3147106, 16.5807529],
                [107.3145819, 16.580573],
                [107.314024, 16.5802876],
                [107.313911, 16.5802002],
                [107.3138635, 16.5801474],
                [107.3137937, 16.5800364],
                [107.3137532, 16.5799227],
                [107.3137423, 16.5798635],
                [107.3137251, 16.5795686],
                [107.3136954, 16.5794688],
                [107.3136498, 16.5793943],
                [107.3135291, 16.5794161],
                [107.3132475, 16.5794097],
                [107.3131536, 16.5793647],
                [107.3129527, 16.5793474],
                [107.3127631, 16.5793599],
                [107.3125213, 16.5794014],
                [107.3124146, 16.579812],
                [107.3122509, 16.5800176],
                [107.3120123, 16.5802799],
                [107.3119385, 16.5803802],
                [107.3118728, 16.5804856],
                [107.3116046, 16.5805832],
                [107.3114598, 16.5808969],
                [107.3110628, 16.5810974],
                [107.3108643, 16.5813544],
                [107.3099846, 16.581884],
                [107.3098685, 16.5819477],
                [107.3097481, 16.5820034],
                [107.3096238, 16.5820508],
                [107.3094964, 16.5820897],
                [107.3092626, 16.5821374],
                [107.3090243, 16.5821565],
                [107.3084021, 16.5821796],
                [107.307529, 16.5827902],
                [107.3073333, 16.5827975],
                [107.3071414, 16.5828351],
                [107.3069787, 16.582893],
                [107.3069011, 16.5829302],
                [107.3067552, 16.5830202],
                [107.3063314, 16.5830768],
                [107.303161, 16.5838865],
                [107.3031872, 16.5839355],
                [107.3032223, 16.5840403],
                [107.3032308, 16.5840948],
                [107.3032303, 16.5841928],
                [107.3030859, 16.584758],
                [107.3030698, 16.5852593],
                [107.3030573, 16.5853195],
                [107.3030357, 16.5853773],
                [107.3030055, 16.5854315],
                [107.3029675, 16.5854805],
                [107.3028713, 16.58556],
                [107.3028571, 16.5857764],
                [107.3028123, 16.5862078],
                [107.302784, 16.586409],
                [107.3027131, 16.5868093],
                [107.302587, 16.5867759],
                [107.3025033, 16.5867454],
                [107.3024234, 16.5867065],
                [107.3023254, 16.5866433],
                [107.3022222, 16.5865883],
                [107.3020264, 16.5865111],
                [107.301486, 16.5865073],
                [107.300908, 16.5858454],
                [107.3006532, 16.5858428],
                [107.3005793, 16.5858329],
                [107.3005074, 16.5858139],
                [107.3004386, 16.5857862],
                [107.3003738, 16.58575],
                [107.3003144, 16.5857061],
                [107.3002616, 16.5856551],
                [107.3000751, 16.5853454],
                [107.3000433, 16.5852791],
                [107.2999611, 16.5851567],
                [107.2998565, 16.585051],
                [107.2996975, 16.5849246],
                [107.2996259, 16.5848531],
                [107.2995121, 16.5847121],
                [107.2994193, 16.5845575],
                [107.2993126, 16.5844315],
                [107.2991887, 16.584321],
                [107.2991173, 16.5842698],
                [107.2989636, 16.5841831],
                [107.2985204, 16.5838808],
                [107.298303, 16.5837162],
                [107.2980916, 16.5835446],
                [107.2978583, 16.5831179],
                [107.2978177, 16.5830579],
                [107.2977179, 16.5829519],
                [107.2976598, 16.5829071],
                [107.2975407, 16.5828407],
                [107.2974104, 16.5827979],
                [107.2973138, 16.5826244],
                [107.2975619, 16.5822169],
                [107.2974606, 16.5821128],
                [107.2974037, 16.5820669],
                [107.2972758, 16.5819866],
                [107.2971318, 16.581926],
                [107.2970563, 16.5819046],
                [107.2958324, 16.5816108],
                [107.2952344, 16.5814433],
                [107.2946334, 16.5812466],
                [107.2936016, 16.5808763],
                [107.2927943, 16.5806064],
                [107.2926871, 16.5806127],
                [107.2924751, 16.5806449],
                [107.2922749, 16.5807008],
                [107.292008, 16.5808138],
                [107.2919289, 16.5808392],
                [107.2918473, 16.5808559],
                [107.2916961, 16.5808629],
                [107.2915614, 16.5808435],
                [107.2914961, 16.5808249],
                [107.2913699, 16.5807672],
                [107.2911269, 16.5806346],
                [107.2909004, 16.5804819],
                [107.2906949, 16.5803081],
                [107.2905236, 16.5801275],
                [107.290384, 16.579946],
                [107.2903213, 16.5798506],
                [107.2903103, 16.5798103],
                [107.2903052, 16.5797272],
                [107.2903213, 16.5796496],
                [107.2903367, 16.5796128],
                [107.2905546, 16.5793056],
                [107.2907397, 16.579091],
                [107.2907325, 16.5789429],
                [107.2907175, 16.5788701],
                [107.2906674, 16.5787344],
                [107.2905939, 16.578614],
                [107.2904983, 16.5785087],
                [107.2896159, 16.5784804],
                [107.2894509, 16.5784469],
                [107.2892931, 16.5783816],
                [107.2892187, 16.578337],
                [107.2890861, 16.5782272],
                [107.2889508, 16.5780579],
                [107.2888917, 16.5779676],
                [107.2887878, 16.5777682],
                [107.288712, 16.5775575],
                [107.2886825, 16.5772156],
                [107.2886657, 16.5771343],
                [107.2886074, 16.5769786],
                [107.2885179, 16.5768371],
                [107.2883996, 16.5767144],
                [107.2883308, 16.5766618],
                [107.2881782, 16.5765781],
                [107.2870101, 16.575312],
                [107.2863968, 16.5752841],
                [107.2863258, 16.5752754],
                [107.286188, 16.5752387],
                [107.2861166, 16.5752083],
                [107.2859247, 16.5750953],
                [107.2858599, 16.575072],
                [107.2857923, 16.5750576],
                [107.2857233, 16.5750523],
                [107.2856542, 16.5750562],
                [107.2855237, 16.5750901],
                [107.2854632, 16.5751195],
                [107.2853129, 16.575226],
                [107.2852609, 16.5752541],
                [107.2851314, 16.5753002],
                [107.2850541, 16.5753139],
                [107.2849756, 16.5753184],
                [107.2846779, 16.5752927],
                [107.2843131, 16.575285],
                [107.2841849, 16.5753214],
                [107.2839229, 16.5753729],
                [107.2837044, 16.5753936],
                [107.2836185, 16.5753922],
                [107.2834487, 16.5753679],
                [107.2833661, 16.5753453],
                [107.2832064, 16.5752787],
                [107.2831308, 16.5752349],
                [107.2829932, 16.5751286],
                [107.2829325, 16.5750671],
                [107.2825187, 16.5745729],
                [107.2822264, 16.574249],
                [107.2819177, 16.5739528],
                [107.2815639, 16.5736603],
                [107.2813788, 16.5723697],
                [107.2806101, 16.5726887],
                [107.2805427, 16.5727041],
                [107.2804045, 16.572713],
                [107.2802648, 16.572692],
                [107.2801312, 16.5726402],
                [107.2800699, 16.5726037],
                [107.2800025, 16.5725406],
                [107.2798829, 16.5724015],
                [107.2798312, 16.572326],
                [107.2797508, 16.5721776],
                [107.2797185, 16.5720998],
                [107.2796588, 16.5720085],
                [107.2795764, 16.5719353],
                [107.279522, 16.5719039],
                [107.2794635, 16.5718806],
                [107.279402, 16.5718659],
                [107.2791579, 16.5718582],
                [107.278592, 16.5717168],
                [107.2785054, 16.5717064],
                [107.2784182, 16.5717051],
                [107.2783313, 16.5717129],
                [107.2782459, 16.5717297],
                [107.2781628, 16.5717553],
                [107.2780311, 16.5718176],
                [107.2779134, 16.5719019],
                [107.2778216, 16.5719926],
                [107.2776532, 16.5721872],
                [107.2775692, 16.572302],
                [107.2774184, 16.5725371],
                [107.2773716, 16.5725883],
                [107.2772873, 16.5726546],
                [107.2772393, 16.5726796],
                [107.2771355, 16.5727117],
                [107.277019, 16.5727174],
                [107.2769612, 16.5727091],
                [107.2768003, 16.5726294],
                [107.2767272, 16.5725656],
                [107.2766977, 16.5725274],
                [107.2766554, 16.5724417],
                [107.276638, 16.5723537],
                [107.2766378, 16.5723089],
                [107.2766558, 16.5721917],
                [107.2766548, 16.5720447],
                [107.2766196, 16.5719015],
                [107.2765577, 16.5717783],
                [107.2764778, 16.5716775],
                [107.2763792, 16.5715934],
                [107.2758861, 16.5713208],
                [107.2757596, 16.5712245],
                [107.2756396, 16.5710934],
                [107.2755477, 16.5709429],
                [107.275553, 16.5705059],
                [107.2751909, 16.5700792],
                [107.2751286, 16.570023],
                [107.2750917, 16.570002],
                [107.2750231, 16.569979],
                [107.2749335, 16.5699602],
                [107.2748322, 16.5699172],
                [107.2747249, 16.5698381],
                [107.2744879, 16.5696241],
                [107.2741109, 16.5694233],
                [107.274042, 16.5693804],
                [107.2739166, 16.5692862],
                [107.2738069, 16.5691819],
                [107.2734026, 16.568696],
                [107.2728574, 16.5683078],
                [107.2727772, 16.5682588],
                [107.2726403, 16.568149],
                [107.2725315, 16.5680276],
                [107.2722727, 16.5676626],
                [107.2721023, 16.5674884],
                [107.2720259, 16.5673939],
                [107.2719087, 16.5672198],
                [107.2718576, 16.5671285],
                [107.2715619, 16.5669196],
                [107.2713286, 16.5666805],
                [107.2712369, 16.5664714],
                [107.2712004, 16.5663635],
                [107.2711459, 16.5661377],
                [107.2711184, 16.5659025],
                [107.2711247, 16.5656907],
                [107.2706419, 16.5661946],
                [107.2705068, 16.5663732],
                [107.2702503, 16.5667396],
                [107.2701312, 16.5669238],
                [107.269907, 16.5673001],
                [107.2689526, 16.5672816],
                [107.268862, 16.5672866],
                [107.2687723, 16.5672997],
                [107.2685985, 16.5673492],
                [107.2685159, 16.5673853],
                [107.268367, 16.5674757],
                [107.2683014, 16.5675284],
                [107.2681858, 16.5676493],
                [107.267931, 16.5680419],
                [107.2676969, 16.568349],
                [107.267559, 16.5685066],
                [107.2674135, 16.568658],
                [107.2672609, 16.5688026],
                [107.2671014, 16.5689403],
                [107.267107, 16.5693557],
                [107.267098, 16.5694468],
                [107.2670572, 16.5696123],
                [107.2670281, 16.5696858],
                [107.2669512, 16.5698246],
                [107.2667592, 16.5700508],
                [107.2665435, 16.5702565],
                [107.2663182, 16.5704314],
                [107.2656423, 16.5706833],
                [107.264843, 16.5713517],
                [107.2646175, 16.5714172],
                [107.2615653, 16.5723029],
                [107.259069, 16.572923],
                [107.2564634, 16.5735407],
                [107.2551066, 16.5738505],
                [107.2549535, 16.5739004],
                [107.2548806, 16.573934],
                [107.2547445, 16.5740176],
                [107.2546334, 16.5741118],
                [107.2545379, 16.5742207],
                [107.2545379, 16.574668],
                [107.2539693, 16.5748223],
                [107.2536304, 16.5753958],
                [107.253596, 16.5754688],
                [107.2535481, 16.5756221],
                [107.253535, 16.5757012],
                [107.2535224, 16.5758916],
                [107.2534843, 16.5759959],
                [107.2534543, 16.5760434],
                [107.2533837, 16.5761176],
                [107.253296, 16.5761728],
                [107.2531968, 16.5762053],
                [107.2532344, 16.5770691],
                [107.2529773, 16.5769278],
                [107.2528434, 16.576867],
                [107.2525665, 16.5767657],
                [107.2522629, 16.5766891],
                [107.2521082, 16.5766629],
                [107.2518529, 16.5766329],
                [107.2516526, 16.5766299],
                [107.2515529, 16.5766391],
                [107.2513568, 16.5766783],
                [107.2511758, 16.5767418],
                [107.2510893, 16.5767827],
                [107.2509263, 16.5768814],
                [107.2502518, 16.5776141],
                [107.2500801, 16.5778204],
                [107.2497153, 16.5782156],
                [107.2493331, 16.5785778],
                [107.2489268, 16.5789149],
                [107.2492325, 16.5791102],
                [107.2492582, 16.5797229],
                [107.2492543, 16.5797855],
                [107.2492225, 16.5799068],
                [107.2491634, 16.5800127],
                [107.249084, 16.5800986],
                [107.2489863, 16.5801651],
                [107.2489321, 16.5801899],
                [107.248562, 16.5803185],
                [107.2483695, 16.5803992],
                [107.2481811, 16.5804881],
                [107.2470224, 16.5818712],
                [107.2479933, 16.5831565],
                [107.2480142, 16.5845279],
                [107.2480047, 16.5846393],
                [107.2479594, 16.5848584],
                [107.2479238, 16.5849648],
                [107.2478278, 16.5851684],
                [107.2476972, 16.5853603],
                [107.2476185, 16.5854504],
                [107.2475326, 16.5855342],
                [107.24744, 16.5856111],
                [107.2473412, 16.5856807],
                [107.247237, 16.5857425],
                [107.2463572, 16.5860459],
                [107.2457778, 16.5865806],
                [107.2450992, 16.5868376],
                [107.2449546, 16.5871427],
                [107.2448713, 16.58729],
                [107.2447023, 16.5875471],
                [107.244628, 16.5873307],
                [107.244527, 16.5871196],
                [107.2444661, 16.5870167],
                [107.2440076, 16.5863389],
                [107.243447, 16.5860073],
                [107.2433516, 16.5859335],
                [107.2432976, 16.5859055],
                [107.2431143, 16.585846],
                [107.2430554, 16.5858171],
                [107.2429923, 16.5857763],
                [107.2429352, 16.585728],
                [107.2428851, 16.5856731],
                [107.2424895, 16.5850587],
                [107.2423448, 16.5848667],
                [107.2421781, 16.5846917],
                [107.2420871, 16.5846115],
                [107.2419745, 16.5845238],
                [107.2418559, 16.5844437],
                [107.2417319, 16.5843714],
                [107.2416032, 16.5843072],
                [107.2414702, 16.5842516],
                [107.2407031, 16.5842464],
                [107.2403115, 16.583686],
                [107.2392225, 16.5826835],
                [107.2390079, 16.5821282],
                [107.2387409, 16.5821829],
                [107.2386051, 16.5821974],
                [107.238332, 16.5822002],
                [107.2380666, 16.5821692],
                [107.2379361, 16.5821414],
                [107.237682, 16.5820616],
                [107.2375596, 16.58201],
                [107.2375161, 16.5820433],
                [107.2374432, 16.5821236],
                [107.2373434, 16.5823158],
                [107.2372435, 16.5824461],
                [107.2371231, 16.5825521],
                [107.2370576, 16.5825952],
                [107.2369158, 16.5826629],
                [107.2366163, 16.5825327],
                [107.2364625, 16.5824771],
                [107.2361479, 16.5823853],
                [107.2358217, 16.5823193],
                [107.2356543, 16.5822964],
                [107.2352135, 16.5822619],
                [107.2350106, 16.5822172],
                [107.2349126, 16.582183],
                [107.2347271, 16.5820922],
                [107.2345579, 16.5819721],
                [107.2344117, 16.5818268],
                [107.2342926, 16.5816604],
                [107.2341902, 16.5815676],
                [107.2340683, 16.5814998],
                [107.2339335, 16.5814604],
                [107.2337877, 16.5814519],
                [107.2337124, 16.5814606],
                [107.2336388, 16.5814782],
                [107.2335681, 16.5815043],
                [107.2335013, 16.5815386],
                [107.2334394, 16.5815806],
                [107.2333834, 16.5816295],
                [107.2333192, 16.581672],
                [107.2332501, 16.5817066],
                [107.233177, 16.5817328],
                [107.2331012, 16.5817502],
                [107.2330237, 16.5817586],
                [107.2329457, 16.5817578],
                [107.2328684, 16.5817478],
                [107.2327922, 16.5817285],
                [107.2327192, 16.5817004],
                [107.2326503, 16.5816637],
                [107.2325867, 16.5816192],
                [107.232407, 16.5814213],
                [107.2317687, 16.5817632],
                [107.2306395, 16.5817426],
                [107.2304211, 16.5817168],
                [107.2303144, 16.5816908],
                [107.2301096, 16.5816137],
                [107.2299211, 16.5815048],
                [107.2297543, 16.5813673],
                [107.2296215, 16.5812154],
                [107.2295151, 16.5810454],
                [107.2294728, 16.5809549],
                [107.229411, 16.5807658],
                [107.2284186, 16.5794393],
                [107.2283971, 16.5791359],
                [107.2277909, 16.579501],
                [107.2272101, 16.5794456],
                [107.2266269, 16.5794239],
                [107.2260493, 16.5794356],
                [107.2254735, 16.5794804],
                [107.2243707, 16.5800959],
                [107.2242715, 16.5801424],
                [107.2241682, 16.58018],
                [107.2240618, 16.5802084],
                [107.2239532, 16.5802273],
                [107.2238432, 16.5802365],
                [107.2237327, 16.5802361],
                [107.2236228, 16.5802259],
                [107.2235144, 16.5802062],
                [107.2234084, 16.580177],
                [107.2233056, 16.5801386],
                [107.2232069, 16.5800914],
                [107.223004, 16.5799719],
                [107.2228815, 16.5799368],
                [107.2227538, 16.5799329],
                [107.2224909, 16.5799843],
                [107.2223858, 16.579996],
                [107.2221744, 16.5800048],
                [107.2221494, 16.5793471],
                [107.2221166, 16.5792179],
                [107.2220515, 16.5791005],
                [107.2220081, 16.5790485],
                [107.2219092, 16.5789662],
                [107.221793, 16.5789083],
                [107.2216659, 16.578878],
                [107.2216004, 16.5788737],
                [107.220329, 16.5789046],
                [107.2202468, 16.5787714],
                [107.2201949, 16.578712],
                [107.2200726, 16.5786109],
                [107.2199306, 16.5785373],
                [107.2198542, 16.578512],
                [107.2196951, 16.5784857],
                [107.2195338, 16.5784927],
                [107.2194546, 16.5785087],
                [107.2192421, 16.5785714],
                [107.2191328, 16.578591],
                [107.2190205, 16.5786027],
                [107.2187948, 16.5786012],
                [107.2179794, 16.5780922],
                [107.2177399, 16.5780149],
                [107.2174916, 16.5779701],
                [107.2172391, 16.5779586],
                [107.2170044, 16.577978],
                [107.2167742, 16.5780263],
                [107.2165525, 16.5781025],
                [107.216498, 16.5782396],
                [107.2163647, 16.5785036],
                [107.216297, 16.5786133],
                [107.2161448, 16.5788223],
                [107.2161448, 16.5796064],
                [107.2159758, 16.5795832],
                [107.2158731, 16.5795584],
                [107.2156754, 16.5794856],
                [107.215578, 16.5794625],
                [107.2153788, 16.5794413],
                [107.2152784, 16.5794431],
                [107.2151006, 16.5794674],
                [107.2150137, 16.5794893],
                [107.2148466, 16.5795524],
                [107.2146332, 16.5796147],
                [107.2142966, 16.5796786],
                [107.2141814, 16.5796912],
                [107.2136611, 16.5796809],
                [107.2135831, 16.5797106],
                [107.2134218, 16.5797544],
                [107.2132318, 16.5797783],
                [107.2131239, 16.5797793],
                [107.2130164, 16.5797713],
                [107.2129099, 16.5797543],
                [107.2128054, 16.5797285],
                [107.2126719, 16.579548],
                [107.2125976, 16.5794633],
                [107.2124383, 16.5793094],
                [107.2122664, 16.5791756],
                [107.2120571, 16.5790334],
                [107.2118368, 16.5788505],
                [107.2116392, 16.578644],
                [107.211467, 16.5784161],
                [107.210952, 16.578411],
                [107.2099328, 16.5786269],
                [107.2097525, 16.5786659],
                [107.2093981, 16.5787662],
                [107.2090681, 16.5788901],
                [107.2087634, 16.5790331],
                [107.208482, 16.5790569],
                [107.2081997, 16.5790511],
                [107.2080592, 16.579037],
                [107.2077817, 16.5789868],
                [107.2074975, 16.5789029],
                [107.2073598, 16.5788492],
                [107.207095, 16.5787195],
                [107.2061455, 16.5787246],
                [107.2059873, 16.5791102],
                [107.2058703, 16.5791879],
                [107.2057479, 16.5792574],
                [107.2056207, 16.5793184],
                [107.2054893, 16.5793705],
                [107.2052298, 16.5794445],
                [107.2049371, 16.5794907],
                [107.2049, 16.5795046],
                [107.2048354, 16.5795489],
                [107.2047917, 16.5796069],
                [107.2047695, 16.5796706],
                [107.2047749, 16.5798506],
                [107.2047597, 16.5799234],
                [107.2047351, 16.5799938],
                [107.2047015, 16.5800607],
                [107.2046596, 16.5801231],
                [107.2045599, 16.5802251],
                [107.2045013, 16.580267],
                [107.2035652, 16.5807349],
                [107.2034836, 16.5807904],
                [107.2034258, 16.5808686],
                [107.203409, 16.5809098],
                [107.2033963, 16.5809971],
                [107.203474, 16.5813416],
                [107.2033564, 16.5814878],
                [107.2031414, 16.581794],
                [107.2030573, 16.5819317],
                [107.2029054, 16.5822156],
                [107.2025031, 16.5826115],
                [107.2016291, 16.5823244],
                [107.2014423, 16.5822818],
                [107.2011562, 16.5822504],
                [107.2009706, 16.5822037],
                [107.2007945, 16.5821308],
                [107.2006307, 16.5820323],
                [107.2004845, 16.5819111],
                [107.200419, 16.5818429],
                [107.2000059, 16.5818095],
                [107.1997764, 16.5817588],
                [107.1995419, 16.5817375],
                [107.199307, 16.5817459],
                [107.1990752, 16.5817837],
                [107.1989, 16.5816303],
                [107.1988043, 16.5815629],
                [107.1985992, 16.5814483],
                [107.1983793, 16.5813627],
                [107.1982652, 16.5813313],
                [107.1980309, 16.5812921],
                [107.1977931, 16.581285],
                [107.1976376, 16.5814856],
                [107.1973962, 16.5814732],
                [107.1972754, 16.5814794],
                [107.1970367, 16.5815164],
                [107.1969122, 16.5815494],
                [107.1967906, 16.5815915],
                [107.1966728, 16.5816423],
                [107.1965593, 16.5817015],
                [107.1952075, 16.5817015],
                [107.1951057, 16.5814645],
                [107.1950435, 16.581351],
                [107.1949038, 16.5811438],
                [107.1947461, 16.5809611],
                [107.1939737, 16.5806012],
                [107.1938074, 16.5802516],
                [107.1936243, 16.5801435],
                [107.1932458, 16.579948],
                [107.1928742, 16.5797893],
                [107.1925134, 16.5796639],
                [107.1921444, 16.5795627],
                [107.1916562, 16.5797323],
                [107.1914427, 16.5795691],
                [107.1912137, 16.5794264],
                [107.1909453, 16.5792943],
                [107.190806, 16.5792388],
                [107.1906128, 16.5793545],
                [107.1900737, 16.5793802],
                [107.1899892, 16.579378],
                [107.1898225, 16.579352],
                [107.1896633, 16.5792979],
                [107.1895817, 16.5792572],
                [107.1893646, 16.579112],
                [107.1892163, 16.5790508],
                [107.1891377, 16.5790322],
                [107.1890576, 16.579022],
                [107.1889067, 16.5790256],
                [107.18877, 16.5790553],
                [107.1885966, 16.5791242],
                [107.188539, 16.579136],
                [107.1884215, 16.5791372],
                [107.1883005, 16.579106],
                [107.1881935, 16.5790437],
                [107.1881479, 16.5790023],
                [107.1880426, 16.5789591],
                [107.187934, 16.5789244],
                [107.1878227, 16.5788985],
                [107.1877096, 16.5788816],
                [107.1875954, 16.5788737],
                [107.1873851, 16.5788831],
                [107.1871788, 16.5789231],
                [107.1869813, 16.5789928],
                [107.185463, 16.5796578],
                [107.1852069, 16.5796012],
                [107.1850766, 16.5795847],
                [107.1849454, 16.5795761],
                [107.1847606, 16.5795776],
                [107.1846579, 16.579604],
                [107.1846106, 16.5796277],
                [107.1845296, 16.5796938],
                [107.18447, 16.5797844],
                [107.1844391, 16.5798877],
                [107.1843906, 16.5799846],
                [107.1843559, 16.5800269],
                [107.1842719, 16.5800932],
                [107.1842249, 16.5801165],
                [107.1841232, 16.5801437],
                [107.1839526, 16.5801388],
                [107.1837853, 16.5801064],
                [107.1836318, 16.58005],
                [107.1835592, 16.580013],
                [107.1834899, 16.5799704],
                [107.1832707, 16.5798077],
                [107.1831861, 16.579761],
                [107.1830141, 16.5796938],
                [107.1828419, 16.5796576],
                [107.182754, 16.5796501],
                [107.1827245, 16.5786912],
                [107.1827146, 16.5786338],
                [107.1826754, 16.5785239],
                [107.1826467, 16.5784727],
                [107.1825584, 16.5783673],
                [107.1825045, 16.5783236],
                [107.1816167, 16.5783017],
                [107.1814638, 16.5786398],
                [107.181317, 16.5785893],
                [107.1811822, 16.5785138],
                [107.1811149, 16.5784626],
                [107.1809982, 16.5783419],
                [107.1808816, 16.578189],
                [107.1807397, 16.5780571],
                [107.1806606, 16.5780003],
                [107.1804806, 16.5779034],
                [107.1801618, 16.5777953],
                [107.1800545, 16.5777495],
                [107.1798557, 16.5776422],
                [107.1796775, 16.5775164],
                [107.1795163, 16.577371],
                [107.178514, 16.5763436],
                [107.1775546, 16.5763488],
                [107.1770848, 16.5765763],
                [107.1768553, 16.5766997],
                [107.1764077, 16.5769652],
                [107.1762241, 16.5770843],
                [107.1758663, 16.5773351],
                [107.1758178, 16.5773894],
                [107.1757345, 16.5775079],
                [107.175672, 16.5776347],
                [107.1756305, 16.5777666],
                [107.1756252, 16.5781775],
                [107.1753086, 16.5781849],
                [107.1749932, 16.5781577],
                [107.1747003, 16.5781007],
                [107.174565, 16.5780634],
                [107.1742638, 16.5779585],
                [107.1741857, 16.5779586],
                [107.1741137, 16.5779874],
                [107.174084, 16.578011],
                [107.1740414, 16.5780722],
                [107.1740441, 16.5784806],
                [107.1739546, 16.5784906],
                [107.1737747, 16.5784932],
                [107.1735757, 16.5784686],
                [107.1734682, 16.5784429],
                [107.1729638, 16.5782649],
                [107.1727603, 16.5782034],
                [107.1723469, 16.5781017],
                [107.1721376, 16.5780615],
                [107.1716966, 16.578001],
                [107.1714653, 16.577982],
                [107.1710013, 16.5779701],
                [107.1706094, 16.5779796],
                [107.1705303, 16.577992],
                [107.170378, 16.5780388],
                [107.1702345, 16.5781157],
                [107.17011, 16.5782186],
                [107.169826, 16.5785525],
                [107.1697639, 16.5785831],
                [107.1696319, 16.5786265],
                [107.1694937, 16.578645],
                [107.1694105, 16.5786438],
                [107.1693279, 16.5786334],
                [107.1692471, 16.5786142],
                [107.1691809, 16.5784288],
                [107.1691374, 16.5783401],
                [107.1690309, 16.5781729],
                [107.1689684, 16.5780953],
                [107.1688473, 16.5779769],
                [107.168778, 16.5779271],
                [107.1686253, 16.5778492],
                [107.1684593, 16.5778025],
                [107.1682919, 16.577789],
                [107.168208, 16.5777942],
                [107.1680439, 16.5778282],
                [107.1678972, 16.5779015],
                [107.1677411, 16.5779541],
                [107.1675535, 16.5779875],
                [107.1674916, 16.5778779],
                [107.1674526, 16.577828],
                [107.1673561, 16.5777369],
                [107.1672987, 16.5776973],
                [107.1672373, 16.5776638],
                [107.166307, 16.5772555],
                [107.1661938, 16.5772134],
                [107.1659603, 16.5771497],
                [107.1657205, 16.5771142],
                [107.1655834, 16.5771068],
                [107.1654462, 16.5771086],
                [107.1653094, 16.5771197],
                [107.1651738, 16.5771399],
                [107.164938, 16.5764053],
                [107.1636409, 16.5758916],
                [107.1634867, 16.5756309],
                [107.1633616, 16.5754557],
                [107.1633163, 16.5754108],
                [107.1632647, 16.5753727],
                [107.1632079, 16.5753421],
                [107.1631471, 16.5753197],
                [107.1630835, 16.575306],
                [107.1630238, 16.5753012],
                [107.162905, 16.5753145],
                [107.1628481, 16.5753324],
                [107.1624725, 16.5755012],
                [107.1623829, 16.5755261],
                [107.1621984, 16.5755515],
                [107.1620122, 16.5755434],
                [107.1619205, 16.5755269],
                [107.1617549, 16.5754742],
                [107.1616015, 16.5753946],
                [107.1614649, 16.5752906],
                [107.16102, 16.5748128],
                [107.1594282, 16.5739498],
                [107.1586403, 16.5739293],
                [107.1583949, 16.5738187],
                [107.1581538, 16.5736996],
                [107.1579175, 16.573572],
                [107.1576863, 16.5734361],
                [107.1573123, 16.5731913],
                [107.1569547, 16.572925],
                [107.1567717, 16.5730635],
                [107.1566725, 16.5731224],
                [107.1564616, 16.5732178],
                [107.1563737, 16.5732471],
                [107.1561932, 16.5732902],
                [107.1561012, 16.5733038],
                [107.1560105, 16.5733063],
                [107.1559664, 16.5733172],
                [107.1558856, 16.5733568],
                [107.1558213, 16.5734161],
                [107.155797, 16.5734512],
                [107.1557651, 16.5735294],
                [107.1557408, 16.5738651],
                [107.1550656, 16.5744494],
                [107.1548628, 16.5745986],
                [107.1546395, 16.5747238],
                [107.1545206, 16.5747766],
                [107.1543984, 16.574822],
                [107.1541463, 16.5748899],
                [107.1537336, 16.5747229],
                [107.1532539, 16.5746998],
                [107.1528901, 16.5744641],
                [107.1527018, 16.5743556],
                [107.152358, 16.5741791],
                [107.1521819, 16.5740988],
                [107.1513083, 16.5740628],
                [107.1508066, 16.5739281],
                [107.150408, 16.573845],
                [107.1501257, 16.5737636],
                [107.1498562, 16.573649],
                [107.1497277, 16.5735798],
                [107.1496039, 16.5735029],
                [107.1493826, 16.573336],
                [107.1491837, 16.573145],
                [107.1490935, 16.5730412],
                [107.1489336, 16.5728191],
                [107.1488643, 16.5727015],
                [107.1485275, 16.5727223],
                [107.1481902, 16.5727111],
                [107.1478557, 16.5726681],
                [107.1476905, 16.5726347],
                [107.1473783, 16.5725488],
                [107.147076, 16.5724348],
                [107.1469294, 16.5723676],
                [107.1467164, 16.5722266],
                [107.1464846, 16.5721159],
                [107.1462565, 16.5720423],
                [107.1461391, 16.5720165],
                [107.1459004, 16.5719875],
                [107.1447132, 16.5714481],
                [107.1445711, 16.5714099],
                [107.1442812, 16.5713572],
                [107.1441344, 16.5713428],
                [107.1438889, 16.5713367],
                [107.1437662, 16.5713421],
                [107.1407256, 16.5717126],
                [107.1403366, 16.5713607],
                [107.1399259, 16.5710321],
                [107.1394951, 16.5707281],
                [107.1392727, 16.5705856],
                [107.1388092, 16.5703175],
                [107.1385712, 16.5701938],
                [107.1380839, 16.5699679],
                [107.137835, 16.5698658],
                [107.137084, 16.5685392],
                [107.1368293, 16.5684134],
                [107.1363089, 16.5681837],
                [107.1360435, 16.56808],
                [107.135528, 16.5679031],
                [107.1350276, 16.5677608],
                [107.1345198, 16.5676446],
                [107.1341255, 16.5676445],
                [107.1339286, 16.5676338],
                [107.1335367, 16.5675914],
                [107.1333423, 16.5675597],
                [107.1331175, 16.567514],
                [107.1328949, 16.5674589],
                [107.1326751, 16.5673945],
                [107.1324584, 16.567321],
                [107.1322453, 16.5672384],
                [107.1318758, 16.5670694],
                [107.1316964, 16.5669747],
                [107.1315211, 16.5668733],
                [107.1312111, 16.5666443],
                [107.1309249, 16.5663885],
                [107.1307915, 16.5662512],
                [107.1305523, 16.5659679],
                [107.1303409, 16.565665],
                [107.130111, 16.5656252],
                [107.1298624, 16.5655593],
                [107.1295991, 16.5654614],
                [107.1294719, 16.5654028],
                [107.1287423, 16.5655725],
                [107.1286257, 16.5655936],
                [107.1283895, 16.5656157],
                [107.1281522, 16.5656108],
                [107.1278992, 16.5655753],
                [107.1277662, 16.5655435],
                [107.1276358, 16.5655031],
                [107.1275085, 16.5654542],
                [107.1265805, 16.5644618],
                [107.1257095, 16.5635589],
                [107.1252689, 16.5631121],
                [107.125147, 16.5627943],
                [107.124998, 16.5624874],
                [107.124915, 16.562341],
                [107.1248233, 16.5621994],
                [107.1247232, 16.5620632],
                [107.124615, 16.5619328],
                [107.1244991, 16.5618086],
                [107.1243844, 16.5616989],
                [107.1241373, 16.5614981],
                [107.1240056, 16.5614076],
                [107.1230239, 16.5604615],
                [107.1230286, 16.5602293],
                [107.1230664, 16.5599999],
                [107.1231365, 16.5597776],
                [107.1231879, 16.559661],
                [107.1232481, 16.5595485],
                [107.123317, 16.5594405],
                [107.123394, 16.5593378],
                [107.1234789, 16.5592409],
                [107.123571, 16.5591503],
                [107.1243132, 16.5582553],
                [107.1243648, 16.5581623],
                [107.1243815, 16.558112],
                [107.1243918, 16.5580602],
                [107.1243937, 16.5579639],
                [107.1243864, 16.5579162],
                [107.1238714, 16.5575563],
                [107.1237436, 16.5574387],
                [107.1236869, 16.5573734],
                [107.1235838, 16.5572221],
                [107.1235396, 16.557136],
                [107.1234745, 16.5569547],
                [107.1234572, 16.5567923],
                [107.1234121, 16.5566348],
                [107.1233404, 16.5564867],
                [107.1232939, 16.5564157],
                [107.1231828, 16.5562864],
                [107.123119, 16.556229],
                [107.1229773, 16.5561308],
                [107.1229005, 16.5560908],
                [107.1228482, 16.5560451],
                [107.1227644, 16.5559362],
                [107.1227342, 16.5558748],
                [107.1227035, 16.5557688],
                [107.1226966, 16.5556589],
                [107.1225504, 16.5555818],
                [107.1224834, 16.5555341],
                [107.122364, 16.5554223],
                [107.1222704, 16.5552963],
                [107.1222011, 16.5551566],
                [107.1221763, 16.555083],
                [107.1220368, 16.5544556],
                [107.1220643, 16.5543649],
                [107.1220676, 16.5543177],
                [107.1220529, 16.5542242],
                [107.1220072, 16.5541332],
                [107.1219349, 16.5540597],
                [107.1218973, 16.5538942],
                [107.1218457, 16.5535593],
                [107.1218257, 16.5532212],
                [107.1218382, 16.5528734],
                [107.121886, 16.5525191],
                [107.1219687, 16.5521706],
                [107.1220228, 16.5519996],
                [107.1221563, 16.551666],
                [107.1222353, 16.5515041],
                [107.1223701, 16.5510744],
                [107.1224722, 16.5506366],
                [107.1225411, 16.5501928],
                [107.1225742, 16.5497924],
                [107.1225807, 16.5495917],
                [107.1225733, 16.5491901],
                [107.1224946, 16.549127],
                [107.1223988, 16.5490825],
                [107.1222906, 16.5490617],
                [107.1222353, 16.5490616],
                [107.1220173, 16.5493932],
                [107.1219189, 16.5495649],
                [107.1217496, 16.5499057],
                [107.1216781, 16.5500738],
                [107.121473, 16.5506178],
                [107.1213079, 16.5509817],
                [107.1211518, 16.5512759],
                [107.1210004, 16.5514955],
                [107.1208205, 16.5516944],
                [107.1207208, 16.551785],
                [107.1205063, 16.5519452],
                [107.1203925, 16.5520145],
                [107.1201522, 16.5521315],
                [107.1200267, 16.5521787],
                [107.1197677, 16.5522497],
                [107.1191287, 16.5522663],
                [107.1184909, 16.5523062],
                [107.1178726, 16.5523673],
                [107.1172571, 16.5524502],
                [107.1147305, 16.5524142],
                [107.1146602, 16.5521553],
                [107.1146123, 16.5520296],
                [107.1144916, 16.5517883],
                [107.1144193, 16.5516738],
                [107.1142556, 16.5514625],
                [107.1139906, 16.551198],
                [107.113923, 16.5511174],
                [107.113863, 16.5510314],
                [107.113811, 16.5509408],
                [107.1137675, 16.5508461],
                [107.1137065, 16.5506822],
                [107.1136568, 16.55063],
                [107.1136252, 16.5506115],
                [107.1135521, 16.5505929],
                [107.1134751, 16.5506027],
                [107.1127939, 16.5509616],
                [107.1108895, 16.5513087],
                [107.1106623, 16.5511181],
                [107.1105424, 16.55103],
                [107.1102914, 16.550869],
                [107.1100161, 16.5507243],
                [107.1098737, 16.5506609],
                [107.1095806, 16.5505528],
                [107.1089548, 16.5509386],
                [107.1087751, 16.5510338],
                [107.1086803, 16.5510717],
                [107.1084841, 16.5511272],
                [107.1082824, 16.5511549],
                [107.1080786, 16.5511544],
                [107.1079727, 16.5512462],
                [107.1079118, 16.5512822],
                [107.1077787, 16.5513318],
                [107.1076384, 16.5513492],
                [107.1074992, 16.5513344],
                [107.1070433, 16.5511338],
                [107.1065819, 16.5514218],
                [107.1062761, 16.5514971],
                [107.1059684, 16.5515651],
                [107.1053481, 16.5516789],
                [107.1047543, 16.5517593],
                [107.1041572, 16.5518126],
                [107.103551, 16.5522857],
                [107.1034092, 16.5522493],
                [107.1033364, 16.5522394],
                [107.1032583, 16.552235],
                [107.1031021, 16.5522453],
                [107.1030253, 16.55226],
                [107.1025318, 16.5524759],
                [107.1023401, 16.5525432],
                [107.1021402, 16.552583],
                [107.1020385, 16.5525922],
                [107.1018356, 16.5525892],
                [107.1016366, 16.5525582],
                [107.1015394, 16.5525325],
                [107.1012432, 16.5523243],
                [107.1010888, 16.5522291],
                [107.1007735, 16.5520598],
                [107.100445, 16.5519154],
                [107.099635, 16.5517715],
                [107.0981565, 16.5509236],
                [107.0979684, 16.5507948],
                [107.0977943, 16.5506415],
                [107.0977011, 16.5505676],
                [107.0976019, 16.5505014],
                [107.0974972, 16.5504431],
                [107.0973879, 16.5503933],
                [107.0972747, 16.5503522],
                [107.0966095, 16.5503625],
                [107.096112, 16.5502215],
                [107.0956063, 16.5501106],
                [107.0951414, 16.5500362],
                [107.0946729, 16.5499871],
                [107.0944214, 16.5500911],
                [107.0939089, 16.5502764],
                [107.0936483, 16.5503574],
                [107.0932816, 16.5504545],
                [107.0930957, 16.5504936],
                [107.0927203, 16.5505528],
                [107.0925188, 16.5505738],
                [107.0921141, 16.5505939],
                [107.0917405, 16.5502488],
                [107.0914248, 16.5499884],
                [107.0911968, 16.5497815],
                [107.0910658, 16.5496453],
                [107.0909423, 16.549503],
                [107.0908266, 16.5493547],
                [107.0900863, 16.5487582],
                [107.0899473, 16.5486733],
                [107.0898732, 16.5486385],
                [107.0897182, 16.5485851],
                [107.0895568, 16.5485542],
                [107.0894034, 16.5485456],
                [107.0892635, 16.5485192],
                [107.0891334, 16.5484634],
                [107.0890195, 16.5483812],
                [107.0889302, 16.5482801],
                [107.0888675, 16.5481668],
                [107.0888459, 16.548106],
                [107.0888311, 16.5480434],
                [107.0888388, 16.5478317],
                [107.0888291, 16.547726],
                [107.0887828, 16.5475189],
                [107.0887017, 16.5473216],
                [107.0886488, 16.5472284],
                [107.0885199, 16.5470561],
                [107.0885003, 16.5468082],
                [107.0884487, 16.5465784],
                [107.0883715, 16.5463696],
                [107.0882678, 16.5461716],
                [107.0880958, 16.5461226],
                [107.0879268, 16.546065],
                [107.0877611, 16.545999],
                [107.0875992, 16.5459247],
                [107.0872425, 16.5457383],
                [107.0871368, 16.5456984],
                [107.087028, 16.5456671],
                [107.0869168, 16.5456446],
                [107.0868041, 16.5456311],
                [107.0866907, 16.5456265],
                [107.0865831, 16.5456306],
                [107.0863706, 16.545663],
                [107.0861661, 16.5457271],
                [107.0848453, 16.5463156],
                [107.0846385, 16.5463388],
                [107.084223, 16.546367],
                [107.0837564, 16.5463695],
                [107.0835233, 16.5463592],
                [107.0830589, 16.5463156],
                [107.082324, 16.5458374],
                [107.0811734, 16.5457576],
                [107.0810919, 16.5458645],
                [107.0809132, 16.5460662],
                [107.0808209, 16.5461562],
                [107.0806235, 16.5463233],
                [107.0801407, 16.5463567],
                [107.0800051, 16.5463822],
                [107.079867, 16.5463787],
                [107.0797991, 16.5463661],
                [107.0796818, 16.5463244],
                [107.0796703, 16.5463203],
                [107.0795555, 16.5462493],
                [107.0794594, 16.5461562],
                [107.0792019, 16.5457345],
                [107.0787728, 16.5457345],
                [107.0785049, 16.5457741],
                [107.0782444, 16.5458461],
                [107.077853, 16.5460063],
                [107.0777159, 16.546052],
                [107.0775765, 16.5460906],
                [107.0772922, 16.5461459],
                [107.0770116, 16.5461712],
                [107.0767298, 16.5461683],
                [107.0765895, 16.5461562],
                [107.0761925, 16.5457088],
                [107.0762032, 16.5451072],
                [107.0761842, 16.5442459],
                [107.0761444, 16.5440569],
                [107.0760691, 16.5438781],
                [107.0759648, 16.5437204],
                [107.075833, 16.543583],
                [107.075758, 16.5435233],
                [107.0753556, 16.5432765],
                [107.0752346, 16.543101],
                [107.0751056, 16.5429309],
                [107.0749688, 16.5427664],
                [107.0747668, 16.5425484],
                [107.0746335, 16.5424481],
                [107.0745593, 16.5424085],
                [107.0744812, 16.5423766],
                [107.0743317, 16.5423391],
                [107.0741775, 16.5423296],
                [107.0741004, 16.5423354],
                [107.0738225, 16.542383],
                [107.0735513, 16.5424578],
                [107.0732768, 16.5425647],
                [107.0731382, 16.542632],
                [107.0728742, 16.5427887],
                [107.0727496, 16.5428776],
                [107.0724219, 16.5431425],
                [107.0723092, 16.5432168],
                [107.0720697, 16.5433433],
                [107.0719439, 16.5433948],
                [107.0716948, 16.5434704],
                [107.071567, 16.5434967],
                [107.0713074, 16.5435258],
                [107.0699537, 16.543549],
                [107.0692348, 16.5439604],
                [107.0689626, 16.5438956],
                [107.0686843, 16.5438613],
                [107.0685441, 16.5438558],
                [107.0682639, 16.5438679],
                [107.0681442, 16.5438825],
                [107.0679079, 16.5439289],
                [107.0672669, 16.5441069],
                [107.0670017, 16.5441707],
                [107.0664668, 16.5442792],
                [107.0659028, 16.5443653],
                [107.0653349, 16.5444232],
                [107.0651407, 16.5444741],
                [107.064954, 16.5445466],
                [107.0644283, 16.5448089],
                [107.064232, 16.5448959],
                [107.0638329, 16.5450557],
                [107.0636278, 16.5451555],
                [107.0634359, 16.5452768],
                [107.0633383, 16.545351],
                [107.0632456, 16.5454308],
                [107.0630765, 16.545606],
                [107.0628932, 16.5456104],
                [107.0625281, 16.5456413],
                [107.0620544, 16.5457166],
                [107.0616916, 16.5457526],
                [107.0614756, 16.5457612],
                [107.0612593, 16.5457603],
                [107.0610434, 16.5457499],
                [107.0610809, 16.5445878],
                [107.0601422, 16.5438524],
                [107.0600826, 16.5431458],
                [107.0600456, 16.5427931],
                [107.0599383, 16.5419549],
                [107.0609146, 16.5407978],
                [107.0609879, 16.5405359],
                [107.061036, 16.5404083],
                [107.0611439, 16.5401816],
                [107.0612633, 16.5399853],
                [107.0612897, 16.5399289],
                [107.0613247, 16.5398101],
                [107.0613331, 16.5397488],
                [107.0613313, 16.5396293],
                [107.0613062, 16.5395122],
                [107.0608878, 16.5385866],
                [107.0608659, 16.5385237],
                [107.0608428, 16.5383932],
                [107.0608418, 16.5383269],
                [107.060861, 16.5381958],
                [107.0609089, 16.5380686],
                [107.0609429, 16.5380094],
                [107.0610294, 16.5379026],
                [107.0610809, 16.5378564],
                [107.0614181, 16.5376291],
                [107.0615798, 16.5375067],
                [107.0618678, 16.5372639],
                [107.0621377, 16.5370027],
                [107.0621324, 16.5359331],
                [107.0609522, 16.5340046],
                [107.0606876, 16.5336199],
                [107.0605661, 16.533421],
                [107.0603455, 16.5330117],
                [107.0602466, 16.5328016],
                [107.0600724, 16.5323721],
                [107.0599269, 16.5319198],
                [107.0598655, 16.5316839],
                [107.0598247, 16.5314974],
                [107.0598134, 16.531446],
                [107.0597706, 16.5312065],
                [107.0597371, 16.5309655],
                [107.059713, 16.5307236],
                [107.0596958, 16.530627],
                [107.0596393, 16.5304388],
                [107.0595578, 16.5302663],
                [107.0594555, 16.5301121],
                [107.0593321, 16.5299727],
                [107.0588118, 16.529623],
                [107.0583789, 16.5293486],
                [107.0582885, 16.5292817],
                [107.0581326, 16.5291413],
                [107.058005, 16.5289945],
                [107.0579481, 16.5289159],
                [107.0570408, 16.5266803],
                [107.0569602, 16.5264358],
                [107.0569024, 16.5261784],
                [107.0568699, 16.52591],
                [107.0568591, 16.525226],
                [107.0568621, 16.5249781],
                [107.0568293, 16.5247322],
                [107.0567612, 16.524493],
                [107.0566647, 16.5242756],
                [107.0565459, 16.5240819],
                [107.0564027, 16.5239041],
                [107.0563227, 16.523822],
                [107.0562578, 16.5237133],
                [107.0562275, 16.5235916],
                [107.0562321, 16.523477],
                [107.0562629, 16.5233772],
                [107.0563173, 16.5232871],
                [107.056843, 16.5227574],
                [107.0570362, 16.5223099],
                [107.0572097, 16.5220294],
                [107.0572871, 16.5218841],
                [107.0574224, 16.5215848],
                [107.0575372, 16.5212543],
                [107.0575829, 16.5210857],
                [107.0576503, 16.5207435],
                [107.0577066, 16.5201938],
                [107.0577539, 16.5199949],
                [107.0578286, 16.5197988],
                [107.0579311, 16.5196093],
                [107.0579917, 16.5195197],
                [107.0581305, 16.5193528],
                [107.0583658, 16.5191163],
                [107.0584746, 16.5189906],
                [107.0586737, 16.5187254],
                [107.0587635, 16.5185864],
                [107.0589323, 16.5182775],
                [107.0590046, 16.5181174],
                [107.0591242, 16.5177881],
                [107.0591712, 16.5176196],
                [107.0592939, 16.5175315],
                [107.0594222, 16.5174511],
                [107.0595557, 16.5173788],
                [107.0596937, 16.5173149],
                [107.0598358, 16.5172596],
                [107.0599812, 16.5172133],
                [107.0602664, 16.51715],
                [107.0604115, 16.517131],
                [107.060704, 16.5171189],
                [107.0608503, 16.5171258],
                [107.0611777, 16.5169453],
                [107.0613352, 16.5168453],
                [107.0616365, 16.5166269],
                [107.0617798, 16.5165089],
                [107.0620499, 16.5162564],
                [107.0622969, 16.5159836],
                [107.0624113, 16.5158401],
                [107.0627546, 16.5151509],
                [107.0633148, 16.5147602],
                [107.0636022, 16.5145749],
                [107.0639189, 16.5143816],
                [107.0642409, 16.5141964],
                [107.0645678, 16.5140194],
                [107.0658982, 16.5124456],
                [107.0660277, 16.5122231],
                [107.0660806, 16.5121061],
                [107.0661521, 16.5118985],
                [107.0661986, 16.5116844],
                [107.0673895, 16.5105992],
                [107.0675239, 16.5105339],
                [107.0678033, 16.5104257],
                [107.0680907, 16.5103491],
                [107.0683821, 16.5103042],
                [107.0686769, 16.5102906],
                [107.0689666, 16.5101157],
                [107.0698839, 16.5101209],
                [107.0703882, 16.5098277],
                [107.0706067, 16.5100538],
                [107.0707243, 16.5101589],
                [107.0709747, 16.5103521],
                [107.0712487, 16.5105239],
                [107.0715444, 16.5106713],
                [107.071854, 16.5107894],
                [107.0721745, 16.5108769],
                [107.073108, 16.5110415],
                [107.0735918, 16.5108927],
                [107.0738375, 16.5108306],
                [107.0742049, 16.510754],
                [107.07439, 16.5107226],
                [107.0745354, 16.51067],
                [107.0746039, 16.5106348],
                [107.074737, 16.5105423],
                [107.0748558, 16.5104211],
                [107.0749054, 16.5103529],
                [107.07564, 16.5089996],
                [107.0757003, 16.5089081],
                [107.0758421, 16.5087396],
                [107.0760093, 16.5085941],
                [107.0761979, 16.508475],
                [107.0764075, 16.5083836],
                [107.0766295, 16.5083249],
                [107.0767434, 16.5083083],
                [107.0769735, 16.508301],
                [107.0770883, 16.5083104],
                [107.0772148, 16.5083499],
                [107.0774741, 16.5084064],
                [107.0776059, 16.5084233],
                [107.0778716, 16.5084339],
                [107.0781385, 16.5084133],
                [107.0782706, 16.5083913],
                [107.0785291, 16.5083243],
                [107.0786548, 16.5082796],
                [107.0792019, 16.5088659],
                [107.079333, 16.5089398],
                [107.0794787, 16.5089816],
                [107.0796307, 16.5089887],
                [107.0797811, 16.5089604],
                [107.079853, 16.5089331],
                [107.079921, 16.5088976],
                [107.0799838, 16.5088544],
                [107.0800407, 16.5088041],
                [107.0800908, 16.5087476],
                [107.0801333, 16.5086856],
                [107.0802277, 16.5084782],
                [107.0802819, 16.508335],
                [107.0803714, 16.508043],
                [107.0804102, 16.5078778],
                [107.0804639, 16.5075431],
                [107.0804787, 16.5073743],
                [107.0805159, 16.5073193],
                [107.0809722, 16.506644],
                [107.0810737, 16.5063409],
                [107.0811339, 16.5061926],
                [107.0812726, 16.5059034],
                [107.0814466, 16.5056077],
                [107.0815434, 16.5054655],
                [107.0817554, 16.5051936],
                [107.0818265, 16.5050907],
                [107.0819469, 16.5048725],
                [107.0819956, 16.5047583],
                [107.082069, 16.5045218],
                [107.0821094, 16.504278],
                [107.0821202, 16.5036711],
                [107.0839226, 16.5027967],
                [107.0840884, 16.50273],
                [107.0844278, 16.5026166],
                [107.084601, 16.5025702],
                [107.0849526, 16.5024984],
                [107.085322, 16.502454],
                [107.085694, 16.5024404],
                [107.0858801, 16.5024452],
                [107.0862508, 16.5024779],
                [107.0882893, 16.502735],
                [107.0885885, 16.5028299],
                [107.0888756, 16.5029549],
                [107.0891431, 16.5031059],
                [107.089389, 16.5032802],
                [107.0896148, 16.5034779],
                [107.0898181, 16.5036968],
                [107.0899342, 16.5039245],
                [107.0900043, 16.5040319],
                [107.0901668, 16.5042318],
                [107.0902573, 16.504322],
                [107.0904565, 16.5044838],
                [107.090556, 16.5045141],
                [107.0906603, 16.5045095],
                [107.0907479, 16.5044758],
                [107.0907864, 16.5044494],
                [107.0911324, 16.504098],
                [107.0915133, 16.5041135],
                [107.0921409, 16.5037971],
                [107.0922659, 16.5037501],
                [107.0923982, 16.5037286],
                [107.0925328, 16.5037335],
                [107.0925992, 16.503746],
                [107.0927262, 16.5037904],
                [107.0927855, 16.5038218],
                [107.0931119, 16.5040389],
                [107.0934686, 16.5039437],
                [107.0943457, 16.5044041],
                [107.0952952, 16.5043578],
                [107.0952502, 16.5040494],
                [107.0952147, 16.5038974],
                [107.0951223, 16.5036103],
                [107.0945227, 16.502267],
                [107.0945027, 16.5020787],
                [107.0945013, 16.5019841],
                [107.0945216, 16.5017567],
                [107.0945442, 16.5016446],
                [107.094968, 16.5003845],
                [107.0950727, 16.5001493],
                [107.0951351, 16.5000362],
                [107.0952909, 16.4998056],
                [107.0953857, 16.49969],
                [107.0954881, 16.4995805],
                [107.0955975, 16.4994775],
                [107.0960194, 16.4991191],
                [107.0969421, 16.497931],
                [107.0970601, 16.497711],
                [107.0971696, 16.497487],
                [107.0972706, 16.4972594],
                [107.0973629, 16.4970284],
                [107.0974463, 16.4967943],
                [107.0975666, 16.4963935],
                [107.0976611, 16.4959864],
                [107.0976984, 16.495781],
                [107.0977628, 16.4935898],
                [107.0985246, 16.4912957],
                [107.1000963, 16.490555],
                [107.1001163, 16.4906296],
                [107.1001586, 16.4907871],
                [107.1002057, 16.4910634],
                [107.10022, 16.491223],
                [107.1002465, 16.4921855],
                [107.1004191, 16.4926219],
                [107.100625, 16.4930447],
                [107.1008594, 16.4934459],
                [107.100986, 16.4936374],
                [107.1012604, 16.4940064],
                [107.1014804, 16.4944333],
                [107.101734, 16.4946329],
                [107.1020045, 16.4948107],
                [107.1023088, 16.4949748],
                [107.1026472, 16.4951188],
                [107.1029985, 16.4952306],
                [107.1033255, 16.4952972],
                [107.1036583, 16.4953283],
                [107.1038543, 16.4951938],
                [107.1040633, 16.4950789],
                [107.104336, 16.4949658],
                [107.1046212, 16.494886],
                [107.1051577, 16.4951792],
                [107.1053178, 16.4952292],
                [107.1054849, 16.4952486],
                [107.1055754, 16.4952461],
                [107.1056651, 16.4952346],
                [107.1057531, 16.4952141],
                [107.1058384, 16.495185],
                [107.1059201, 16.4951475],
                [107.1059972, 16.495102],
                [107.1068877, 16.4944642],
                [107.1071367, 16.4944008],
                [107.1072574, 16.4943574],
                [107.1074885, 16.4942482],
                [107.1076993, 16.4941129],
                [107.1078899, 16.4939526],
                [107.1080571, 16.4937698],
                [107.1091354, 16.4926691],
                [107.1101332, 16.4925096],
                [107.1103359, 16.4923337],
                [107.1105033, 16.4921484],
                [107.1106378, 16.49196],
                [107.1107501, 16.4917586],
                [107.1112304, 16.491529],
                [107.1116942, 16.49127],
                [107.1121418, 16.4909808],
                [107.1125686, 16.4906643],
                [107.112628, 16.4904386],
                [107.1127135, 16.4902206],
                [107.1128212, 16.4900175],
                [107.1128836, 16.4899205],
                [107.1130246, 16.4897371],
                [107.1139473, 16.4888112],
                [107.1141492, 16.4886569],
                [107.1143683, 16.4885261],
                [107.1146017, 16.4884203],
                [107.114726, 16.4883763],
                [107.1149816, 16.4883099],
                [107.115112, 16.4882877],
                [107.1153758, 16.4882657],
                [107.116828, 16.4882454],
                [107.116973, 16.4881526],
                [107.1171232, 16.4880678],
                [107.1172783, 16.4879912],
                [107.1174375, 16.4879232],
                [107.1176005, 16.4878638],
                [107.1179191, 16.4877756],
                [107.1181291, 16.4877396],
                [107.1182289, 16.4877225],
                [107.1185427, 16.4877],
                [107.1187001, 16.4877002],
                [107.1191883, 16.4877465],
                [107.1192524, 16.4877426],
                [107.1193776, 16.4877156],
                [107.1194941, 16.4876642],
                [107.119596, 16.4875913],
                [107.1196799, 16.4874997],
                [107.119714, 16.4874481],
                [107.1197945, 16.4871241],
                [107.1204275, 16.4866251],
                [107.1204625, 16.486129],
                [107.1204902, 16.4860573],
                [107.1205141, 16.4860264],
                [107.1205778, 16.4859803],
                [107.1206152, 16.4859667],
                [107.1206968, 16.485961],
                [107.120737, 16.4859695],
                [107.1208592, 16.4860319],
                [107.1209134, 16.4860478],
                [107.1210262, 16.4860539],
                [107.1211365, 16.4860249],
                [107.121187, 16.4859975],
                [107.1212323, 16.4859627],
                [107.1212709, 16.4859211],
                [107.1213322, 16.4858126],
                [107.1213535, 16.4857477],
                [107.1213655, 16.4856806],
                [107.121368, 16.4856126],
                [107.1213609, 16.4855449],
                [107.1213716, 16.4848968],
                [107.1247405, 16.4819287],
                [107.1250892, 16.48169],
                [107.1254995, 16.4814092],
                [107.1255575, 16.4810617],
                [107.1255742, 16.4808864],
                [107.1255827, 16.4805347],
                [107.1255615, 16.4802144],
                [107.1255407, 16.4800551],
                [107.1254003, 16.4791664],
                [107.1255167, 16.4790118],
                [107.1255663, 16.4789291],
                [107.1256471, 16.4787548],
                [107.1257063, 16.4785522],
                [107.1257238, 16.4784482],
                [107.1257973, 16.4777723],
                [107.1258744, 16.4775725],
                [107.1259255, 16.4774778],
                [107.126051, 16.4773019],
                [107.1261245, 16.4772219],
                [107.1262072, 16.4771457],
                [107.1262964, 16.4770764],
                [107.1263913, 16.4770146],
                [107.1264913, 16.4769607],
                [107.1265957, 16.476915],
                [107.1267037, 16.476878],
                [107.1268146, 16.4768498],
                [107.1269276, 16.4768306],
                [107.1270418, 16.4768206],
                [107.128458, 16.4765326],
                [107.1285986, 16.4764723],
                [107.1288688, 16.4763309],
                [107.1289977, 16.4762501],
                [107.1292412, 16.4760696],
                [107.1294656, 16.4758634],
                [107.1295689, 16.4757519],
                [107.1297562, 16.475514],
                [107.1298152, 16.4750279],
                [107.1298678, 16.4746673],
                [107.1299616, 16.4741186],
                [107.129959, 16.4739268],
                [107.129944, 16.4738319],
                [107.1298962, 16.4736703],
                [107.1298624, 16.4735928],
                [107.1297761, 16.4734468],
                [107.129724, 16.4733792],
                [107.1295252, 16.4731517],
                [107.1294175, 16.4730451],
                [107.1291867, 16.4728474],
                [107.1290642, 16.4727567],
                [107.1290857, 16.4720108],
                [107.1293646, 16.4717639],
                [107.1293808, 16.4715199],
                [107.1294317, 16.4712803],
                [107.1295069, 16.4710706],
                [107.1296087, 16.4708714],
                [107.129636, 16.4707512],
                [107.1296597, 16.4706939],
                [107.1297428, 16.4705679],
                [107.1299459, 16.4706359],
                [107.1301559, 16.470681],
                [107.1303793, 16.470703],
                [107.1306038, 16.470699],
                [107.1309659, 16.4702515],
                [107.1309847, 16.4698297],
                [107.1311641, 16.4696471],
                [107.1313682, 16.4694901],
                [107.1315693, 16.4693735],
                [107.131784, 16.4692818],
                [107.1317959, 16.4691104],
                [107.1317947, 16.4690246],
                [107.1317732, 16.4688265],
                [107.1317732, 16.468667],
                [107.1320441, 16.4685256],
                [107.1320713, 16.4684684],
                [107.1321085, 16.4683481],
                [107.1321192, 16.4682765],
                [107.1321179, 16.4680224],
                [107.132128, 16.4679131],
                [107.1321451, 16.4678341],
                [107.1321744, 16.4676985],
                [107.1322104, 16.4675944],
                [107.1323042, 16.4674004],
                [107.1324273, 16.4672221],
                [107.1325769, 16.4670635],
                [107.1327496, 16.4669282],
                [107.1327626, 16.4666829],
                [107.1327798, 16.4665611],
                [107.1328354, 16.4663212],
                [107.1329262, 16.4660708],
                [107.1329833, 16.46595],
                [107.1331197, 16.4657193],
                [107.133049, 16.4653406],
                [107.133007, 16.4649579],
                [107.132994, 16.4645746],
                [107.1330097, 16.4641914],
                [107.1332701, 16.464006],
                [107.1333167, 16.4639644],
                [107.1333935, 16.4638674],
                [107.1334474, 16.4637502],
                [107.1334711, 16.4636178],
                [107.1334711, 16.4633297],
                [107.1335501, 16.4632326],
                [107.1335808, 16.4631784],
                [107.1336231, 16.4630609],
                [107.1336339, 16.4629989],
                [107.1336535, 16.4622802],
                [107.1336489, 16.4622152],
                [107.1336675, 16.4620863],
                [107.1336903, 16.4620249],
                [107.1337607, 16.4619137],
                [107.1338077, 16.4618655],
                [107.133861, 16.4618239],
                [107.1339199, 16.4617897],
                [107.133983, 16.4617636],
                [107.1340493, 16.4617461],
                [107.1341175, 16.4617375],
                [107.1345091, 16.4617118],
                [107.1346539, 16.461434],
                [107.134802, 16.4613208],
                [107.134868, 16.4612555],
                [107.1349812, 16.4611099],
                [107.1350596, 16.4609645],
                [107.1350898, 16.4608879],
                [107.1351314, 16.4607292],
                [107.1358234, 16.4599935],
                [107.1357755, 16.4598726],
                [107.135737, 16.4597487],
                [107.1357082, 16.4596224],
                [107.1356893, 16.4594945],
                [107.1356796, 16.4592911],
                [107.1357012, 16.458999],
                [107.1357006, 16.4589097],
                [107.1356785, 16.4587325],
                [107.1356571, 16.4586456],
                [107.1356236, 16.4585469],
                [107.1355812, 16.4584514],
                [107.1355302, 16.4583598],
                [107.1354709, 16.4582729],
                [107.135404, 16.4581913],
                [107.1353298, 16.4581157],
                [107.135539, 16.4573337],
                [107.1357027, 16.4562932],
                [107.1355316, 16.4560999],
                [107.1354568, 16.4559953],
                [107.1353303, 16.4557727],
                [107.1352365, 16.4555356],
                [107.1352025, 16.455413],
                [107.1351609, 16.4551627],
                [107.1351548, 16.4549165],
                [107.1351827, 16.4546716],
                [107.135244, 16.4544325],
                [107.1352869, 16.4543164],
                [107.1353333, 16.4542259],
                [107.1353708, 16.4541318],
                [107.1353993, 16.4540348],
                [107.1354184, 16.4539357],
                [107.1354286, 16.4537539],
                [107.135422, 16.453663],
                [107.1354076, 16.4535729],
                [107.1353819, 16.4535244],
                [107.1353486, 16.4534803],
                [107.1352665, 16.4534122],
                [107.1351716, 16.4533719],
                [107.1351206, 16.453362],
                [107.1343803, 16.4534135],
                [107.1339261, 16.4530471],
                [107.1337044, 16.4528578],
                [107.1332169, 16.4524149],
                [107.1327495, 16.4519524],
                [107.1315251, 16.4499124],
                [107.1302314, 16.4486192],
                [107.1311469, 16.4478754],
                [107.1312582, 16.447785],
                [107.131516, 16.4475204],
                [107.1317809, 16.4472623],
                [107.1320526, 16.4470109],
                [107.1325952, 16.4465459],
                [107.1331395, 16.4461236],
                [107.1334195, 16.4459221],
                [107.1337044, 16.4457271],
                [107.1339597, 16.4455651],
                [107.1342203, 16.4454112],
                [107.1344861, 16.4452656],
                [107.1347567, 16.4451285],
                [107.1350319, 16.445],
                [107.1353113, 16.4448802],
                [107.1355947, 16.4447693],
                [107.1358817, 16.4446674],
                [107.136172, 16.4445746],
                [107.1374419, 16.4442211],
                [107.137678, 16.4441312],
                [107.1379011, 16.4440147],
                [107.1381128, 16.4438692],
                [107.1383086, 16.4436952],
                [107.1384798, 16.4434988],
                [107.1386236, 16.4432832],
                [107.1385007, 16.4431632],
                [107.138385, 16.4430369],
                [107.1382769, 16.4429045],
                [107.1381767, 16.4427665],
                [107.1380848, 16.4426233],
                [107.1380013, 16.4424754],
                [107.1378761, 16.4422055],
                [107.1378241, 16.4420664],
                [107.1377792, 16.441925],
                [107.1376807, 16.4415272],
                [107.137599, 16.4413142],
                [107.1374917, 16.441112],
                [107.1370894, 16.4404894],
                [107.1372865, 16.4403932],
                [107.137379, 16.4403351],
                [107.1374717, 16.4402669],
                [107.1376897, 16.4400687],
                [107.1378021, 16.4399982],
                [107.1378629, 16.4399709],
                [107.1379959, 16.4399324],
                [107.138067, 16.4399225],
                [107.1381388, 16.4399195],
                [107.1382983, 16.4399275],
                [107.1384734, 16.4399133],
                [107.1386423, 16.4398702],
                [107.1388, 16.4398001],
                [107.1389428, 16.4397048],
                [107.1398789, 16.4389819],
                [107.1407372, 16.438277],
                [107.1412361, 16.4382667],
                [107.1413438, 16.4381686],
                [107.1414345, 16.4380558],
                [107.1415158, 16.4379098],
                [107.1415687, 16.4377522],
                [107.1416144, 16.437672],
                [107.1416685, 16.4375968],
                [107.1417304, 16.4375272],
                [107.1417993, 16.4374641],
                [107.1419345, 16.4373713],
                [107.1420848, 16.437303],
                [107.1421641, 16.4372789],
                [107.1430653, 16.4371142],
                [107.1432063, 16.4371058],
                [107.1434884, 16.4371135],
                [107.1436286, 16.4371297],
                [107.1438904, 16.4371825],
                [107.1441442, 16.4372639],
                [107.1444795, 16.4373972],
                [107.1446955, 16.4374711],
                [107.1449144, 16.4375363],
                [107.1453405, 16.4376366],
                [107.1455466, 16.4376729],
                [107.1459623, 16.4377231],
                [107.1469706, 16.4377754],
                [107.1475285, 16.4372994],
                [107.1475632, 16.4371123],
                [107.1475929, 16.4370216],
                [107.1476593, 16.4368785],
                [107.1477607, 16.4367015],
                [107.1478665, 16.4364762],
                [107.148006, 16.4358845],
                [107.1484023, 16.4348022],
                [107.1484266, 16.434691],
                [107.1484297, 16.4346342],
                [107.1484136, 16.4345019],
                [107.1483654, 16.434377],
                [107.1482226, 16.4341013],
                [107.1481615, 16.4339589],
                [107.148108, 16.4338153],
                [107.1480056, 16.4334728],
                [107.1479528, 16.4333838],
                [107.1478759, 16.433311],
                [107.1478298, 16.4332825],
                [107.1477275, 16.4332453],
                [107.1476734, 16.4332373],
                [107.1475339, 16.4332307],
                [107.1472549, 16.4332373],
                [107.1470023, 16.4332663],
                [107.1467534, 16.433317],
                [107.1466681, 16.4330288],
                [107.146616, 16.4328876],
                [107.1464932, 16.4326121],
                [107.1464108, 16.4324571],
                [107.1462228, 16.4321597],
                [107.1461175, 16.432018],
                [107.146005, 16.4318815],
                [107.1457875, 16.4316403],
                [107.1456869, 16.4315134],
                [107.1454912, 16.431229],
                [107.1453982, 16.4310712],
                [107.1453133, 16.4309093],
                [107.1451682, 16.4305746],
                [107.1446425, 16.4294478],
                [107.1445765, 16.4293394],
                [107.1444272, 16.4291334],
                [107.1443443, 16.4290363],
                [107.144162, 16.4288542],
                [107.1439594, 16.4286906],
                [107.1437399, 16.4285486],
                [107.1437851, 16.4283226],
                [107.1438539, 16.4281022],
                [107.1439582, 16.4278643],
                [107.1440899, 16.4276391],
                [107.1441081, 16.4270917],
                [107.1441543, 16.4265457],
                [107.1442309, 16.4259878],
                [107.1443367, 16.4254343],
                [107.1456081, 16.42431],
                [107.1456723, 16.4242211],
                [107.1457279, 16.4241271],
                [107.1457746, 16.4240287],
                [107.145835, 16.4238421],
                [107.1458619, 16.4236693],
                [107.1458656, 16.4235819],
                [107.1458481, 16.4233701],
                [107.1458255, 16.423266],
                [107.1457535, 16.423065],
                [107.1457046, 16.4229696],
                [107.1456201, 16.4228587],
                [107.1455437, 16.4227424],
                [107.1454758, 16.4226215],
                [107.1454167, 16.4224963],
                [107.1453667, 16.4223676],
                [107.1453258, 16.4222352],
                [107.1452945, 16.4221005],
                [107.1452729, 16.421964],
                [107.1452497, 16.4215876],
                [107.1452094, 16.421389],
                [107.1451789, 16.4212921],
                [107.1446747, 16.4201807],
                [107.1444491, 16.4200676],
                [107.1442084, 16.4199811],
                [107.144083, 16.4199485],
                [107.143693, 16.419872],
                [107.14362, 16.4198243],
                [107.1435528, 16.4197694],
                [107.143492, 16.419708],
                [107.1434384, 16.4196407],
                [107.1433554, 16.4194927],
                [107.1433068, 16.4193323],
                [107.1432424, 16.4189818],
                [107.1431942, 16.418941],
                [107.1430868, 16.4188737],
                [107.1429566, 16.4188241],
                [107.1428186, 16.4188017],
                [107.1424806, 16.4187759],
                [107.14122, 16.4182459],
                [107.1410925, 16.4180612],
                [107.140973, 16.4178716],
                [107.1408616, 16.4176775],
                [107.1407586, 16.4174792],
                [107.1405873, 16.4170939],
                [107.1404475, 16.4166971],
                [107.1404099, 16.4156422],
                [107.1404467, 16.4155233],
                [107.1405065, 16.4154132],
                [107.1406057, 16.4152983],
                [107.1407296, 16.4152082],
                [107.1409249, 16.4151155],
                [107.1411666, 16.4149802],
                [107.1413967, 16.4148183],
                [107.1415068, 16.4147271],
                [107.1417108, 16.4145281],
                [107.1424538, 16.4136456],
                [107.1426674, 16.4134051],
                [107.1429008, 16.4131784],
                [107.1430249, 16.4130706],
                [107.1432853, 16.4128686],
                [107.143248, 16.4123224],
                [107.1432155, 16.4120504],
                [107.1431274, 16.4115309],
                [107.1430707, 16.4112734],
                [107.1430336, 16.4111841],
                [107.1430054, 16.4111443],
                [107.1429355, 16.4110802],
                [107.1428532, 16.4110382],
                [107.1428084, 16.4110255],
                [107.1427622, 16.4110187],
                [107.1422821, 16.4110316],
                [107.142148, 16.4107382],
                [107.1419656, 16.4094415],
                [107.1416867, 16.4091636],
                [107.1413755, 16.4088909],
                [107.1412128, 16.408716],
                [107.1410744, 16.4085229],
                [107.141015, 16.4084204],
                [107.1409159, 16.4082017],
                [107.1408472, 16.4079682],
                [107.1408255, 16.4078486],
                [107.1408123, 16.4077279],
                [107.140764, 16.4069714],
                [107.140705, 16.4063745],
                [107.141171, 16.4055957],
                [107.1414131, 16.4052115],
                [107.1419817, 16.4043572],
                [107.1420033, 16.4042551],
                [107.1419945, 16.4041454],
                [107.1419511, 16.4040375],
                [107.1418927, 16.4039416],
                [107.1418629, 16.4038391],
                [107.1418637, 16.4033641],
                [107.1420368, 16.4032455],
                [107.1421161, 16.4031774],
                [107.1422579, 16.4030252],
                [107.1423197, 16.4029421],
                [107.1424311, 16.4027473],
                [107.1424743, 16.4026441],
                [107.1425341, 16.4024295],
                [107.1425503, 16.4023194],
                [107.1427739, 16.4021193],
                [107.1428782, 16.402012],
                [107.1430707, 16.4017842],
                [107.1431635, 16.401657],
                [107.143329, 16.4013903],
                [107.1434014, 16.4012514],
                [107.1435245, 16.4009643],
                [107.1435749, 16.4008167],
                [107.1440883, 16.4003417],
                [107.1445788, 16.3998464],
                [107.1450456, 16.3993316],
                [107.1454774, 16.3988246],
                [107.1456901, 16.3985865],
                [107.146131, 16.3981235],
                [107.1466405, 16.3976344],
                [107.146929, 16.3973769],
                [107.1472242, 16.3971266],
                [107.1475261, 16.3968837],
                [107.1478343, 16.3966483],
                [107.148183, 16.3964708],
                [107.1485279, 16.3963172],
                [107.1487033, 16.3962469],
                [107.1488062, 16.39619],
                [107.1489984, 16.3960565],
                [107.1490948, 16.395973],
                [107.1491844, 16.3958828],
                [107.1492666, 16.3957863],
                [107.1494339, 16.3955573],
                [107.1495931, 16.3953231],
                [107.1498791, 16.3948529],
                [107.150126, 16.3943792],
                [107.1502375, 16.3941369],
                [107.1510529, 16.3926754],
                [107.1511566, 16.3925278],
                [107.1512682, 16.3923857],
                [107.1513876, 16.3922495],
                [107.1515143, 16.3921196],
                [107.1516505, 16.391994],
                [107.1517937, 16.3918757],
                [107.1519432, 16.391765],
                [107.1520988, 16.3916622],
                [107.15226, 16.3915675],
                [107.1524262, 16.3914814],
                [107.1525314, 16.3912455],
                [107.1526074, 16.3910067],
                [107.1526558, 16.3907685],
                [107.1526784, 16.3905268],
                [107.1527801, 16.3901687],
                [107.1528191, 16.3899869],
                [107.1528709, 16.3896378],
                [107.1528929, 16.3893045],
                [107.1526276, 16.3891603],
                [107.1523511, 16.3890369],
                [107.1520679, 16.3889359],
                [107.1515438, 16.3887953],
                [107.1514316, 16.3887519],
                [107.1513232, 16.3887002],
                [107.1511281, 16.3885791],
                [107.1509606, 16.3884382],
                [107.1508851, 16.3883595],
                [107.1507525, 16.3881877],
                [107.150538, 16.3878223],
                [107.1504158, 16.3877113],
                [107.1503464, 16.3876655],
                [107.1501946, 16.3875959],
                [107.1500324, 16.3875579],
                [107.1498656, 16.3875527],
                [107.1496459, 16.3875841],
                [107.1495361, 16.3875718],
                [107.1494329, 16.3875341],
                [107.1488804, 16.3870555],
                [107.1486551, 16.3870143],
                [107.1485727, 16.386952],
                [107.1484966, 16.3868828],
                [107.1484273, 16.3868073],
                [107.1483654, 16.3867261],
                [107.148268, 16.3865544],
                [107.1482034, 16.3863689],
                [107.1481737, 16.3861755],
                [107.1469849, 16.3860145],
                [107.1468082, 16.3860159],
                [107.1467208, 16.386029],
                [107.146552, 16.3860792],
                [107.1463966, 16.38616],
                [107.1463233, 16.3862128],
                [107.146256, 16.3862725],
                [107.1461418, 16.3864099],
                [107.1460587, 16.3865666],
                [107.1459319, 16.387096],
                [107.1458811, 16.387222],
                [107.1458449, 16.38728],
                [107.1457534, 16.3873827],
                [107.1456401, 16.3874634],
                [107.1455105, 16.3875178],
                [107.1452038, 16.387579],
                [107.1450875, 16.387612],
                [107.1448624, 16.3876988],
                [107.1447543, 16.3877522],
                [107.1445498, 16.3878781],
                [107.1444541, 16.3879501],
                [107.1442777, 16.3881105],
                [107.1430868, 16.38827],
                [107.1420461, 16.3882736],
                [107.141833, 16.3882968],
                [107.1416271, 16.3883545],
                [107.1414345, 16.388445],
                [107.141273, 16.3885558],
                [107.1411317, 16.3886896],
                [107.1409491, 16.3889033],
                [107.1406889, 16.3891655],
                [107.1404184, 16.3893942],
                [107.1402027, 16.389551],
                [107.1401244, 16.3895944],
                [107.1400419, 16.3896299],
                [107.139956, 16.3896569],
                [107.1398676, 16.3896753],
                [107.1397777, 16.3896848],
                [107.1396873, 16.3896854],
                [107.1395973, 16.3896769],
                [107.1395087, 16.3896596],
                [107.1393543, 16.389606],
                [107.1392817, 16.389569],
                [107.1391489, 16.3894761],
                [107.13909, 16.3894212],
                [107.1390367, 16.3893611],
                [107.1384251, 16.3885119],
                [107.1377385, 16.3884347],
                [107.1375267, 16.3884032],
                [107.1371079, 16.3883156],
                [107.1369016, 16.3882598],
                [107.1365399, 16.3881403],
                [107.1361881, 16.3879961],
                [107.1360165, 16.3879149],
                [107.135362, 16.3878017],
                [107.1342355, 16.3868805],
                [107.1328199, 16.3863548],
                [107.1325945, 16.38625],
                [107.1323848, 16.3861188],
                [107.1321786, 16.3859489],
                [107.132085, 16.3858541],
                [107.1318513, 16.3855653],
                [107.1317694, 16.3854777],
                [107.1315906, 16.3853167],
                [107.1313869, 16.3851723],
                [107.1308197, 16.3848617],
                [107.130475, 16.3846829],
                [107.1298879, 16.3844025],
                [107.1295899, 16.3842712],
                [107.1295087, 16.3842209],
                [107.1293582, 16.3841048],
                [107.1292895, 16.3840396],
                [107.12915, 16.383873],
                [107.129091, 16.3837823],
                [107.1287691, 16.3829794],
                [107.128222, 16.3821868],
                [107.1268916, 16.3836485],
                [107.1261835, 16.3841219],
                [107.1258911, 16.3842526],
                [107.1257408, 16.3843087],
                [107.1253973, 16.3844209],
                [107.1253057, 16.3844613],
                [107.1251321, 16.3845594],
                [107.1249724, 16.3846807],
                [107.1248317, 16.3848219],
                [107.124483, 16.3853211],
                [107.1242751, 16.3855127],
                [107.1240434, 16.3856771],
                [107.1238017, 16.385807],
                [107.1235557, 16.3859039],
                [107.1232995, 16.3859722],
                [107.1231687, 16.3859953],
                [107.121892, 16.386057],
                [107.1216071, 16.3860417],
                [107.1214661, 16.386021],
                [107.1211895, 16.3859539],
                [107.120939, 16.3858602],
                [107.1207161, 16.3857465],
                [107.1204036, 16.3855433],
                [107.1202945, 16.3854849],
                [107.1200644, 16.3853915],
                [107.1198296, 16.3853324],
                [107.1195956, 16.3853061],
                [107.1194777, 16.3853046],
                [107.11936, 16.3853108],
                [107.118153, 16.3854601],
                [107.1149021, 16.3854498],
                [107.1146278, 16.3854601],
                [107.1140804, 16.3855013],
                [107.1135541, 16.385567],
                [107.1133013, 16.3856078],
                [107.1127993, 16.3857071],
                [107.1122038, 16.3857225],
                [107.1120912, 16.3857336],
                [107.11198, 16.3857542],
                [107.1118712, 16.3857843],
                [107.1117021, 16.3858523],
                [107.1116218, 16.3858949],
                [107.111521, 16.3859665],
                [107.1113044, 16.3860879],
                [107.1110719, 16.386178],
                [107.1108784, 16.3862262],
                [107.1106803, 16.3862526],
                [107.1100346, 16.3862464],
                [107.1096759, 16.386243],
                [107.1096391, 16.3865238],
                [107.1096004, 16.3866989],
                [107.1095552, 16.3868001],
                [107.1095166, 16.3868468],
                [107.1094256, 16.3869279],
                [107.1093191, 16.3869899],
                [107.1089491, 16.387156],
                [107.1086881, 16.3872484],
                [107.1084964, 16.3873618],
                [107.1084332, 16.3874208],
                [107.1083523, 16.3875244],
                [107.1077493, 16.3883421],
                [107.1076366, 16.3884835],
                [107.1075513, 16.3886369],
                [107.1074659, 16.388815],
                [107.1073235, 16.3892242],
                [107.1071786, 16.3897014],
                [107.106981, 16.3900234],
                [107.1066943, 16.3903413],
                [107.1062793, 16.3906108],
                [107.10604, 16.3907273],
                [107.1058415, 16.3907694],
                [107.1056152, 16.3908059],
                [107.1053648, 16.3908149],
                [107.1051955, 16.3908304],
                [107.105053, 16.3909175],
                [107.104946, 16.3910475],
                [107.1047781, 16.3913422],
                [107.1043188, 16.3919329],
                [107.1042879, 16.392024],
                [107.1042986, 16.3921414],
                [107.1043722, 16.392236],
                [107.1044837, 16.3923243],
                [107.1046661, 16.3923894],
                [107.1050403, 16.3925628],
                [107.1051001, 16.3926211],
                [107.1051361, 16.392694],
                [107.1051469, 16.3927821],
                [107.1051261, 16.3928475],
                [107.1050664, 16.39293],
                [107.1050076, 16.3929853],
                [107.1045226, 16.3933212],
                [107.104312, 16.3935039],
                [107.1041861, 16.3936466],
                [107.1039935, 16.3938837],
                [107.1039151, 16.3939246],
                [107.1038413, 16.3939452],
                [107.1034828, 16.393968],
                [107.1030138, 16.3940468],
                [107.1029361, 16.3940841],
                [107.1028584, 16.394153],
                [107.1026236, 16.3944225],
                [107.1024523, 16.3946563],
                [107.1023978, 16.3947491],
                [107.1022543, 16.3951991],
                [107.1021757, 16.3953025],
                [107.1020886, 16.3953757],
                [107.1012939, 16.3958242],
                [107.1010772, 16.3959208],
                [107.1008501, 16.3959946],
                [107.1006147, 16.3959924],
                [107.1001641, 16.3959612],
                [107.1000288, 16.3960041],
                [107.0998919, 16.3961507],
                [107.0997965, 16.3963575],
                [107.0997835, 16.3965414],
                [107.0997865, 16.3967718],
                [107.0997628, 16.3969656],
                [107.0996867, 16.3971031],
                [107.0995228, 16.3972029],
                [107.0993002, 16.3973198],
                [107.0991201, 16.3975139],
                [107.0987773, 16.3980609],
                [107.0985127, 16.3985453],
                [107.0983914, 16.3987074],
                [107.0982159, 16.3987953],
                [107.0977379, 16.3989156],
                [107.0975073, 16.3990078],
                [107.0974017, 16.3991216],
                [107.0973337, 16.3993493],
                [107.0972237, 16.3999349],
                [107.0971351, 16.4002378],
                [107.0970433, 16.4004218],
                [107.0969262, 16.4005466],
                [107.0966375, 16.4006971],
                [107.0964465, 16.4007709],
                [107.0963049, 16.4009098],
                [107.0962216, 16.4010385],
                [107.0960146, 16.4016632],
                [107.095797, 16.4025333],
                [107.0957871, 16.4028626],
                [107.0957548, 16.4030771],
                [107.095555, 16.40348],
                [107.0954314, 16.4036282],
                [107.0953526, 16.4037612],
                [107.0952225, 16.4038244],
                [107.0950579, 16.4038524],
                [107.0947817, 16.4038686],
                [107.0945915, 16.4039359],
                [107.0944713, 16.4040245],
                [107.0942446, 16.4042274],
                [107.0940691, 16.4042452],
                [107.0938935, 16.4041979],
                [107.0937642, 16.4041388],
                [107.0936241, 16.4040232],
                [107.0935733, 16.40387],
                [107.0935763, 16.4036543],
                [107.0936173, 16.4034402],
                [107.0936811, 16.4032656],
                [107.0938038, 16.4031072],
                [107.0938725, 16.4030059],
                [107.0938647, 16.402887],
                [107.0938123, 16.4027571],
                [107.0936543, 16.4025689],
                [107.0935437, 16.4023783],
                [107.0935609, 16.4022126],
                [107.0936452, 16.4020651],
                [107.0937723, 16.4019359],
                [107.0940303, 16.401777],
                [107.0941101, 16.4017093],
                [107.0941686, 16.4015992],
                [107.0941835, 16.4014381],
                [107.0942218, 16.401227],
                [107.0943025, 16.4010807],
                [107.0944914, 16.4007674],
                [107.0949216, 16.4002812],
                [107.0949981, 16.4001817],
                [107.0950276, 16.4000319],
                [107.0950222, 16.3998518],
                [107.0949632, 16.3997025],
                [107.094952, 16.3995101],
                [107.0950115, 16.3993371],
                [107.0952398, 16.3989937],
                [107.0952785, 16.39886],
                [107.0952701, 16.398734],
                [107.0952281, 16.398595],
                [107.095127, 16.3984221],
                [107.0950279, 16.3983277],
                [107.09488, 16.3982281],
                [107.0947687, 16.3981844],
                [107.0946723, 16.3981596],
                [107.0944897, 16.3981638],
                [107.094412, 16.3981741],
                [107.0943235, 16.3982191],
                [107.0942752, 16.3982577],
                [107.094231, 16.3983259],
                [107.0941827, 16.3984211],
                [107.0941438, 16.3986051],
                [107.094011, 16.3995404],
                [107.0939641, 16.3998106],
                [107.0939252, 16.3999174],
                [107.0938742, 16.4000036],
                [107.0938233, 16.4000666],
                [107.0937508, 16.4001296],
                [107.0935349, 16.400266],
                [107.0934665, 16.4003355],
                [107.093441, 16.4003805],
                [107.0934021, 16.4004834],
                [107.0933485, 16.4006558],
                [107.0933083, 16.4008218],
                [107.0932761, 16.4009067],
                [107.0932359, 16.4009762],
                [107.0931688, 16.401065],
                [107.0928697, 16.4013493],
                [107.0927946, 16.4014342],
                [107.0927356, 16.401523],
                [107.0926659, 16.4016503],
                [107.0924097, 16.4023811],
                [107.0923601, 16.4025612],
                [107.0923547, 16.4026487],
                [107.0923628, 16.4027683],
                [107.0923762, 16.4028841],
                [107.0924097, 16.4030475],
                [107.0924084, 16.4031633],
                [107.0923695, 16.4032829],
                [107.0923011, 16.4033614],
                [107.0921952, 16.403436],
                [107.0915568, 16.403786],
                [107.0910619, 16.4040381],
                [107.0909385, 16.404078],
                [107.0908527, 16.4040896],
                [107.0907696, 16.4040857],
                [107.0906743, 16.4040304],
                [107.0906207, 16.4039584],
                [107.0905858, 16.4034939],
                [107.0905697, 16.4034257],
                [107.0904973, 16.4033434],
                [107.0904477, 16.4033151],
                [107.0903324, 16.4032855],
                [107.0899837, 16.4032521],
                [107.0899072, 16.4032186],
                [107.0898174, 16.4031401],
                [107.0896068, 16.4027542],
                [107.0895331, 16.4026525],
                [107.0894083, 16.4025149],
                [107.0893171, 16.402466],
                [107.0892018, 16.4024248],
                [107.0890543, 16.4024017],
                [107.0889671, 16.4024017],
                [107.0888639, 16.4024415],
                [107.0885366, 16.4026422],
                [107.0883743, 16.4026699],
                [107.0882463, 16.4026725],
                [107.0881289, 16.4026583],
                [107.0879982, 16.4026094],
                [107.0878842, 16.4025393],
                [107.0876944, 16.4023592],
                [107.0875797, 16.4022769],
                [107.0875093, 16.4022576],
                [107.0874074, 16.4022704],
                [107.0873055, 16.4023399],
                [107.0870721, 16.402659],
                [107.0869702, 16.4027336],
                [107.0868415, 16.4027773],
                [107.0867181, 16.402767],
                [107.0865518, 16.4027104],
                [107.0862514, 16.4024647],
                [107.0861548, 16.4024055],
                [107.0860542, 16.402412],
                [107.085971, 16.402439],
                [107.0858785, 16.40252],
                [107.0854655, 16.4030269],
                [107.0853367, 16.4030938],
                [107.0851815, 16.4031123],
                [107.0850026, 16.4030991],
                [107.0848446, 16.4030198],
                [107.0846394, 16.4028686],
                [107.0844141, 16.4027285],
                [107.0843175, 16.402713],
                [107.0842263, 16.4027311],
                [107.0839822, 16.4028442],
                [107.083879, 16.4028571],
                [107.0837891, 16.4028417],
                [107.0836443, 16.4027619],
                [107.0835048, 16.4026075],
                [107.0833761, 16.4025252],
                [107.0831588, 16.4024891],
                [107.0829442, 16.4025123],
                [107.0823997, 16.4026525],
                [107.082165, 16.4026744],
                [107.0819679, 16.402677],
                [107.0818485, 16.4026268],
                [107.0817788, 16.402556],
                [107.0817211, 16.4024801],
                [107.0816863, 16.4023824],
                [107.0816822, 16.4020453],
                [107.081701, 16.4019154],
                [107.0817667, 16.4017777],
                [107.082051, 16.4015551],
                [107.0822348, 16.401366],
                [107.0823179, 16.4012399],
                [107.0823796, 16.401101],
                [107.0824145, 16.4009865],
                [107.0824614, 16.4007549],
                [107.0825419, 16.400477],
                [107.0825714, 16.4001991],
                [107.0825566, 16.4000769],
                [107.0825003, 16.399983],
                [107.0823957, 16.3998955],
                [107.0822763, 16.399853],
                [107.0821838, 16.3998389],
                [107.0820886, 16.3998582],
                [107.0819545, 16.3999135],
                [107.0812061, 16.4003612],
                [107.0809513, 16.4004847],
                [107.0806804, 16.4005465],
                [107.079868, 16.4006091],
                [107.0796111, 16.4006113],
                [107.0794936, 16.4006064],
                [107.0793184, 16.4005832],
                [107.079117, 16.400532],
                [107.0790048, 16.4004912],
                [107.0784398, 16.4007567],
                [107.076965, 16.4014497],
                [107.0765312, 16.4016623],
                [107.0757879, 16.402048],
                [107.0751679, 16.4023914],
                [107.0731294, 16.4027208],
                [107.0722926, 16.4027517],
                [107.0720916, 16.4027938],
                [107.0719945, 16.4028264],
                [107.07181, 16.4029137],
                [107.0716349, 16.4030348],
                [107.0715524, 16.4031091],
                [107.0714771, 16.4031901],
                [107.0711466, 16.4036328],
                [107.071059, 16.4037281],
                [107.0708645, 16.4039004],
                [107.0707583, 16.4039764],
                [107.0705416, 16.4041015],
                [107.0703096, 16.4041981],
                [107.069952, 16.404298],
                [107.06984, 16.4043382],
                [107.0696251, 16.4044384],
                [107.0694249, 16.4045637],
                [107.0692376, 16.4047168],
                [107.0690681, 16.4048972],
                [107.0689251, 16.4050976],
                [107.0688111, 16.4053144],
                [107.0685442, 16.405418],
                [107.0682693, 16.4054997],
                [107.068116, 16.4055348],
                [107.0678052, 16.4055846],
                [107.067735, 16.405608],
                [107.0676684, 16.4056397],
                [107.0676065, 16.4056793],
                [107.0675504, 16.4057261],
                [107.0674726, 16.4058171],
                [107.0674163, 16.4059216],
                [107.0674153, 16.4060219],
                [107.0674045, 16.4061217],
                [107.0673564, 16.4063105],
                [107.0672768, 16.4064826],
                [107.0671776, 16.4066172],
                [107.0669628, 16.4068747],
                [107.0667309, 16.407112],
                [107.0664839, 16.4073283],
                [107.066189, 16.4075665],
                [107.0658776, 16.4078601],
                [107.0656156, 16.4081507],
                [107.0655043, 16.4082892],
                [107.0652974, 16.408577],
                [107.0652974, 16.4092099],
                [107.0652699, 16.4093288],
                [107.0652153, 16.4094387],
                [107.0651364, 16.4095341],
                [107.0650302, 16.4096142],
                [107.0649703, 16.4096445],
                [107.0648414, 16.4096833],
                [107.0640661, 16.4105262],
                [107.0640154, 16.4105721],
                [107.0639603, 16.410613],
                [107.063839, 16.4106784],
                [107.0637044, 16.4107203],
                [107.0636333, 16.4107315],
                [107.0634896, 16.4107331],
                [107.0628899, 16.4106496],
                [107.0627992, 16.4106454],
                [107.0626183, 16.4106589],
                [107.0624311, 16.4107048],
                [107.0623361, 16.410742],
                [107.0622452, 16.4107876],
                [107.0621592, 16.4108412],
                [107.0614135, 16.411469],
                [107.0613599, 16.4101619],
                [107.0613408, 16.4100313],
                [107.0612795, 16.4097742],
                [107.0611881, 16.4095254],
                [107.0610677, 16.4092883],
                [107.06092, 16.4090658],
                [107.0607491, 16.4088633],
                [107.0605554, 16.4086805],
                [107.0603416, 16.4085198],
                [107.060228, 16.4084483],
                [107.0592838, 16.4080521],
                [107.0580232, 16.4067244],
                [107.0578194, 16.4054482],
                [107.0584524, 16.4047175],
                [107.0587689, 16.4032714],
                [107.0587905, 16.4031467],
                [107.0588546, 16.4029014],
                [107.0588969, 16.4027816],
                [107.0589993, 16.4025535],
                [107.0591237, 16.4023409],
                [107.0592704, 16.4021419],
                [107.0589259, 16.4019343],
                [107.0585972, 16.4017044],
                [107.0582558, 16.401427],
                [107.0579374, 16.4011255],
                [107.0564568, 16.4005028],
                [107.0563964, 16.4004688],
                [107.056291, 16.4003806],
                [107.0562476, 16.4003279],
                [107.0561841, 16.4002125],
                [107.056151, 16.400086],
                [107.0561591, 16.3994967],
                [107.0561684, 16.3994141],
                [107.0562104, 16.3992529],
                [107.0562427, 16.3991758],
                [107.0563287, 16.3990316],
                [107.0563817, 16.3989658],
                [107.0565023, 16.3988518],
                [107.0566387, 16.3987621],
                [107.0567126, 16.3987261],
                [107.0567894, 16.3986965],
                [107.0570791, 16.3986142],
                [107.057254, 16.3985532],
                [107.0575941, 16.3984083],
                [107.0590532, 16.3967667],
                [107.0590426, 16.3955522],
                [107.059026, 16.3953882],
                [107.0589733, 16.3952314],
                [107.0589341, 16.395158],
                [107.0588869, 16.395089],
                [107.0587483, 16.3949219],
                [107.0586876, 16.3948322],
                [107.0585851, 16.3946426],
                [107.0585436, 16.3945435],
                [107.0584759, 16.3943169],
                [107.0584425, 16.3940833],
                [107.0584439, 16.3938476],
                [107.0584577, 16.3937304],
                [107.0581209, 16.3929075],
                [107.0580885, 16.3927595],
                [107.0580821, 16.3926841],
                [107.0580907, 16.392521],
                [107.0581079, 16.3924346],
                [107.058168, 16.3922688],
                [107.0585972, 16.3917748],
                [107.0586079, 16.3909513],
                [107.0581573, 16.3904985],
                [107.0577496, 16.3890214],
                [107.0577253, 16.3888508],
                [107.0577338, 16.3886788],
                [107.0577503, 16.3885941],
                [107.0578073, 16.3884309],
                [107.0578945, 16.3882803],
                [107.0579463, 16.388214],
                [107.0581196, 16.3880294],
                [107.0583682, 16.3877998],
                [107.0585006, 16.3876936],
                [107.0587822, 16.3874987],
                [107.0590817, 16.38733],
                [107.0593965, 16.3871893],
                [107.0588332, 16.3853623],
                [107.0588094, 16.3852674],
                [107.058738, 16.3850846],
                [107.0586368, 16.3849154],
                [107.0585042, 16.3847601],
                [107.0584259, 16.3846891],
                [107.0582519, 16.3845677],
                [107.0566875, 16.3838286],
                [107.0564402, 16.3837302],
                [107.0562073, 16.3836035],
                [107.0560974, 16.3835301],
                [107.0559014, 16.3833726],
                [107.055726, 16.3831941],
                [107.0556468, 16.3830978],
                [107.0552087, 16.3824903],
                [107.0549039, 16.3820923],
                [107.0548183, 16.3819987],
                [107.0547253, 16.3819119],
                [107.0546256, 16.3818322],
                [107.0545197, 16.3817603],
                [107.0544083, 16.3816965],
                [107.054292, 16.3816413],
                [107.0541716, 16.381595],
                [107.0540819, 16.381567],
                [107.0538078, 16.381501],
                [107.0536229, 16.381466],
                [107.0532492, 16.3814203],
                [107.0530611, 16.3814097],
                [107.0526956, 16.3814125],
                [107.0525132, 16.3814255],
                [107.0521512, 16.3814744],
                [107.0517951, 16.3815538],
                [107.0501163, 16.3822567],
                [107.0500113, 16.3822928],
                [107.0497938, 16.3823399],
                [107.0496828, 16.3823506],
                [107.0494599, 16.3823459],
                [107.0492407, 16.3823066],
                [107.0491344, 16.3822743],
                [107.0489317, 16.3821855],
                [107.0487466, 16.3820664],
                [107.0486623, 16.3819964],
                [107.0484671, 16.3818537],
                [107.0480608, 16.3815898],
                [107.0476351, 16.3813556],
                [107.0474157, 16.38125],
                [107.0471924, 16.3811524],
                [107.0468042, 16.3810047],
                [107.0464069, 16.3808809],
                [107.0460855, 16.3807996],
                [107.0458531, 16.3807196],
                [107.0456343, 16.38061],
                [107.0454329, 16.380473],
                [107.045251, 16.3803091],
                [107.0450942, 16.3801227],
                [107.0449656, 16.3799175],
                [107.0449126, 16.3798091],
                [107.0443064, 16.3780129],
                [107.0442372, 16.3777813],
                [107.0440733, 16.3773259],
                [107.0438759, 16.376883],
                [107.043765, 16.3766668],
                [107.0435194, 16.3762466],
                [107.0432432, 16.3758443],
                [107.043094, 16.3756505],
                [107.042774, 16.3752781],
                [107.0424271, 16.3749287],
                [107.0422441, 16.3747631],
                [107.0418602, 16.3744513],
                [107.0415343, 16.3741626],
                [107.0412307, 16.3738523],
                [107.0409379, 16.3735054],
                [107.0407957, 16.3733157],
                [107.0406614, 16.3731209],
                [107.0405352, 16.3729211],
                [107.0404172, 16.3727168],
                [107.040266, 16.3724191],
                [107.0402011, 16.3722656],
                [107.0400876, 16.3719303],
                [107.0400413, 16.3717486],
                [107.0400047, 16.3715649],
                [107.039978, 16.3713796],
                [107.0399612, 16.3711933],
                [107.0399038, 16.3708455],
                [107.0398124, 16.3704823],
                [107.0396823, 16.3701073],
                [107.039532, 16.3697365],
                [107.0394422, 16.369598],
                [107.0393864, 16.3695363],
                [107.0392845, 16.3694507],
                [107.0391918, 16.3694128],
                [107.0390893, 16.369408],
                [107.0390381, 16.369419],
                [107.0389898, 16.3694387],
                [107.038946, 16.3694665],
                [107.0385624, 16.3697689],
                [107.0383676, 16.3699376],
                [107.0379952, 16.3702926],
                [107.0379077, 16.3698706],
                [107.0378423, 16.3696647],
                [107.0377962, 16.3695563],
                [107.037743, 16.3694511],
                [107.0371234, 16.3685169],
                [107.0369303, 16.3679507],
                [107.036861, 16.3677955],
                [107.0367633, 16.3676549],
                [107.0367049, 16.3675917],
                [107.0364637, 16.3673947],
                [107.0363104, 16.3672319],
                [107.0361849, 16.3670502],
                [107.0360894, 16.3668541],
                [107.0360532, 16.3667515],
                [107.0357215, 16.3655451],
                [107.0357017, 16.3653918],
                [107.0357053, 16.3653146],
                [107.0357353, 16.3651758],
                [107.035788, 16.3650572],
                [107.0358228, 16.3650019],
                [107.0358628, 16.36495],
                [107.0363134, 16.3645022],
                [107.0363567, 16.3644322],
                [107.0363688, 16.3643931],
                [107.0363724, 16.3643117],
                [107.0363614, 16.3642645],
                [107.0363411, 16.3642202],
                [107.0363122, 16.3641806],
                [107.0362759, 16.364147],
                [107.0351735, 16.3636195],
                [107.0349723, 16.362729],
                [107.0347041, 16.361836],
                [107.035097, 16.360411],
                [107.0351206, 16.3602238],
                [107.0351149, 16.3600352],
                [107.0350819, 16.3598568],
                [107.0350254, 16.3596917],
                [107.0349884, 16.3596124],
                [107.0349222, 16.3595112],
                [107.0348983, 16.359456],
                [107.0348704, 16.3593396],
                [107.034855, 16.3592075],
                [107.0348087, 16.3590822],
                [107.0347329, 16.3589694],
                [107.0346853, 16.3589201],
                [107.0344546, 16.3587399],
                [107.0343854, 16.358639],
                [107.0343474, 16.3585237],
                [107.0343436, 16.3583792],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.8157659, 10.3704431],
                [105.8165088, 10.3631831],
                [105.8167537, 10.3599867],
                [105.8171048, 10.3569107],
                [105.8173324, 10.3529527],
                [105.8175683, 10.3523348],
                [105.8179674, 10.3510929],
                [105.8180618, 10.350629],
                [105.8182801, 10.3486842],
                [105.8184852, 10.3477985],
                [105.8189428, 10.3458925],
                [105.8190989, 10.3445485],
                [105.8192231, 10.3432442],
                [105.8192685, 10.3422957],
                [105.8191858, 10.3411894],
                [105.8188907, 10.3381631],
                [105.8187583, 10.3355325],
                [105.8190258, 10.3353425],
                [105.8188012, 10.3330721],
                [105.8188032, 10.332453],
                [105.8188007, 10.3314335],
                [105.818771, 10.3313052],
                [105.8187123, 10.3310519],
                [105.8184903, 10.3303241],
                [105.8182979, 10.3294656],
                [105.8182852, 10.3294224],
                [105.81824, 10.3292692],
                [105.8180819, 10.328733],
                [105.8177254, 10.3271313],
                [105.8173997, 10.3254783],
                [105.8170953, 10.3240102],
                [105.8168745, 10.3227883],
                [105.8167905, 10.322357],
                [105.8172877, 10.3222735],
                [105.8186345, 10.3220338],
                [105.8214411, 10.3212048],
                [105.8230321, 10.3207758],
                [105.8245274, 10.3203136],
                [105.8262244, 10.3201011],
                [105.8268775, 10.3198682],
                [105.8279062, 10.3192979],
                [105.8292533, 10.3185187],
                [105.8295999, 10.3180301],
                [105.8296203, 10.317948],
                [105.8295372, 10.3178249],
                [105.8293295, 10.3176713],
                [105.8284859, 10.3171533],
                [105.8280042, 10.3167998],
                [105.8275328, 10.3162789],
                [105.8249278, 10.3149399],
                [105.8227798, 10.3139491],
                [105.8226552, 10.3138775],
                [105.8224579, 10.3137239],
                [105.8221667, 10.3133651],
                [105.8216197, 10.3124704],
                [105.8214727, 10.3120688],
                [105.8213747, 10.3118198],
                [105.8212603, 10.3114876],
                [105.8211869, 10.3111796],
                [105.8210721, 10.3108923],
                [105.82104, 10.3105122],
                [105.8211838, 10.309988],
                [105.8213892, 10.3092787],
                [105.8214924, 10.3091037],
                [105.8216993, 10.3089903],
                [105.8222274, 10.3087834],
                [105.8225793, 10.3085976],
                [105.82289, 10.3085044],
                [105.823325, 10.308421],
                [105.8236444, 10.3083096],
                [105.824518, 10.3080766],
                [105.8252691, 10.3078276],
                [105.8257263, 10.3075143],
                [105.8261257, 10.3073764],
                [105.8264693, 10.3073376],
                [105.8269673, 10.3071448],
                [105.8276613, 10.3067834],
                [105.8278899, 10.3067111],
                [105.828192, 10.3067111],
                [105.8285022, 10.3067753],
                [105.8287635, 10.3068235],
                [105.8292778, 10.3069039],
                [105.8296615, 10.3070083],
                [105.829931, 10.3070645],
                [105.8304208, 10.3072412],
                [105.8307311, 10.3072573],
                [105.8309597, 10.3072011],
                [105.8312781, 10.3071288],
                [105.8316128, 10.3070886],
                [105.8319639, 10.3070484],
                [105.8322963, 10.3069186],
                [105.832568, 10.3068557],
                [105.832813, 10.306695],
                [105.8330007, 10.3065986],
                [105.8332293, 10.3063898],
                [105.8340213, 10.3061086],
                [105.834903, 10.3056829],
                [105.8352378, 10.3055464],
                [105.8355235, 10.3053375],
                [105.8358419, 10.3052331],
                [105.8359644, 10.3049198],
                [105.8360542, 10.3046387],
                [105.8360215, 10.3040603],
                [105.8357305, 10.3035402],
                [105.8359745, 10.2988199],
                [105.8395004, 10.298916],
                [105.8405534, 10.2989022],
                [105.8416063, 10.2988795],
                [105.8426589, 10.2988478],
                [105.8437112, 10.2988072],
                [105.8447632, 10.2987576],
                [105.8458146, 10.2986991],
                [105.8468606, 10.298632],
                [105.8479059, 10.2985561],
                [105.8489505, 10.2984713],
                [105.8499944, 10.2983777],
                [105.8510371, 10.2982732],
                [105.8531193, 10.2980646],
                [105.854466, 10.2979296],
                [105.8551379, 10.2978623],
                [105.8558085, 10.2977844],
                [105.8564781, 10.2976978],
                [105.8571465, 10.2976025],
                [105.8578135, 10.2974987],
                [105.8584791, 10.2973862],
                [105.8591431, 10.2972651],
                [105.8598054, 10.2971354],
                [105.860466, 10.2969972],
                [105.8611247, 10.2968504],
                [105.8617813, 10.2966952],
                [105.8624358, 10.2965314],
                [105.8630881, 10.2963592],
                [105.863738, 10.2961786],
                [105.8643854, 10.2959896],
                [105.8650302, 10.2957923],
                [105.8657037, 10.2955764],
                [105.8663741, 10.2953513],
                [105.8670412, 10.2951172],
                [105.867705, 10.294874],
                [105.8683654, 10.2946219],
                [105.8690221, 10.2943608],
                [105.8696752, 10.2940909],
                [105.8703243, 10.2938121],
                [105.8709695, 10.2935246],
                [105.8716107, 10.2932283],
                [105.8722476, 10.2929233],
                [105.8728802, 10.2926098],
                [105.8735083, 10.2922876],
                [105.8741318, 10.291957],
                [105.8747507, 10.291618],
                [105.8753647, 10.2912706],
                [105.8759739, 10.2909149],
                [105.8765779, 10.290551],
                [105.8770304, 10.2903087],
                [105.8774791, 10.2900598],
                [105.8779241, 10.2898045],
                [105.8783652, 10.2895428],
                [105.8788024, 10.2892747],
                [105.8792355, 10.2890003],
                [105.8796644, 10.2887196],
                [105.8801132, 10.2884162],
                [105.8805571, 10.288106],
                [105.8809961, 10.2877891],
                [105.88143, 10.2874654],
                [105.8818587, 10.2871352],
                [105.8822822, 10.2867984],
                [105.8831307, 10.2860723],
                [105.8839746, 10.2853411],
                [105.8848139, 10.2846047],
                [105.8856484, 10.2838631],
                [105.8864782, 10.2831164],
                [105.8873033, 10.2823647],
                [105.8881706, 10.2815642],
                [105.8890325, 10.2807581],
                [105.889889, 10.2799464],
                [105.89074, 10.2791292],
                [105.8915855, 10.2783064],
                [105.8924255, 10.2774782],
                [105.8932599, 10.2766446],
                [105.8940887, 10.2758055],
                [105.8943536, 10.2756416],
                [105.8946234, 10.2754855],
                [105.8948978, 10.2753373],
                [105.8951766, 10.2751973],
                [105.8954595, 10.2750656],
                [105.8957462, 10.2749421],
                [105.8960366, 10.2748272],
                [105.8963326, 10.27472],
                [105.8966317, 10.2746217],
                [105.8969336, 10.2745322],
                [105.8972382, 10.2744517],
                [105.8975451, 10.2743802],
                [105.897854, 10.2743178],
                [105.8981647, 10.2742646],
                [105.8984769, 10.2742206],
                [105.8987902, 10.2741858],
                [105.8991045, 10.2741603],
                [105.8994195, 10.2741441],
                [105.8997651, 10.274132],
                [105.900111, 10.2741274],
                [105.9004569, 10.2741304],
                [105.9008365, 10.2741424],
                [105.9012156, 10.2741636],
                [105.9014051, 10.2741765],
                [105.9015941, 10.2741938],
                [105.9018427, 10.2742234],
                [105.9020901, 10.2742607],
                [105.9023363, 10.2743056],
                [105.9025808, 10.2743581],
                [105.9028236, 10.2744181],
                [105.9030644, 10.2744856],
                [105.9033029, 10.2745605],
                [105.9035388, 10.2746427],
                [105.9134043, 10.2784374],
                [105.9146119, 10.2788793],
                [105.9152222, 10.2790914],
                [105.9158295, 10.2793117],
                [105.9164336, 10.2795401],
                [105.9170346, 10.2797766],
                [105.9176322, 10.2800211],
                [105.9182264, 10.2802736],
                [105.918817, 10.280534],
                [105.919404, 10.2808023],
                [105.9199924, 10.281081],
                [105.9205769, 10.2813676],
                [105.9211574, 10.281662],
                [105.9217336, 10.2819643],
                [105.9223056, 10.2822743],
                [105.9228733, 10.2825921],
                [105.9234364, 10.2829175],
                [105.9239949, 10.2832504],
                [105.9245488, 10.2835909],
                [105.9250978, 10.2839388],
                [105.9256419, 10.2842942],
                [105.9259593, 10.284497],
                [105.9262132, 10.2846726],
                [105.9277969, 10.2859324],
                [105.9294077, 10.2872143],
                [105.9310133, 10.2885024],
                [105.9326138, 10.2897968],
                [105.934209, 10.2910973],
                [105.935799, 10.2924041],
                [105.9359859, 10.292559],
                [105.9373837, 10.293717],
                [105.9389632, 10.295036],
                [105.9405373, 10.2963612],
                [105.9421062, 10.2976924],
                [105.943187, 10.2986171],
                [105.9437783, 10.2991231],
                [105.9453209, 10.3004542],
                [105.9454444, 10.3005608],
                [105.9471042, 10.3020054],
                [105.9476638, 10.3024966],
                [105.9487578, 10.3034568],
                [105.9502828, 10.3048067],
                [105.9504052, 10.3049151],
                [105.9517215, 10.3060902],
                [105.9520463, 10.3063802],
                [105.953681, 10.3078522],
                [105.9543458, 10.3084558],
                [105.9553095, 10.3093309],
                [105.9569316, 10.3108163],
                [105.9591753, 10.3130227],
                [105.9593327, 10.3131775],
                [105.9601178, 10.3139874],
                [105.9609095, 10.3147911],
                [105.9617078, 10.3155885],
                [105.9625125, 10.3163795],
                [105.9630769, 10.3169254],
                [105.9633237, 10.3171642],
                [105.9641413, 10.3179424],
                [105.9649653, 10.3187141],
                [105.9657955, 10.3194792],
                [105.9666639, 10.3202665],
                [105.9675389, 10.3210467],
                [105.9684205, 10.3218196],
                [105.9693087, 10.3225852],
                [105.9702033, 10.3233435],
                [105.9711043, 10.3240944],
                [105.9720117, 10.3248379],
                [105.9723255, 10.3250907],
                [105.9729253, 10.3255739],
                [105.9738452, 10.3263024],
                [105.9741271, 10.3265434],
                [105.9744148, 10.3267778],
                [105.9747078, 10.3270057],
                [105.9750062, 10.3272267],
                [105.9753238, 10.32745],
                [105.9755743, 10.3276191],
                [105.9758647, 10.3278062],
                [105.9761592, 10.3279871],
                [105.9766191, 10.3282302],
                [105.9770831, 10.3284659],
                [105.977551, 10.3286941],
                [105.9780225, 10.3289147],
                [105.9784977, 10.3291277],
                [105.9789763, 10.3293331],
                [105.9790516, 10.329364],
                [105.9794583, 10.3295307],
                [105.9799436, 10.3297205],
                [105.9804319, 10.3299025],
                [105.9809232, 10.3300766],
                [105.981405, 10.3302387],
                [105.9818894, 10.3303933],
                [105.9822312, 10.3304964],
                [105.9823763, 10.3305402],
                [105.9828655, 10.3306795],
                [105.9833569, 10.3308111],
                [105.9838504, 10.330935],
                [105.9843458, 10.3310511],
                [105.984843, 10.3311594],
                [105.9853419, 10.3312598],
                [105.9854146, 10.3312753],
                [105.985778, 10.3313525],
                [105.9861473, 10.3314068],
                [105.9864537, 10.3314519],
                [105.9867614, 10.3314876],
                [105.9870701, 10.3315139],
                [105.9873794, 10.3315309],
                [105.9876891, 10.3315384],
                [105.987999, 10.3315366],
                [105.9881046, 10.3315327],
                [105.9883086, 10.3315253],
                [105.9886177, 10.3315047],
                [105.988926, 10.3314746],
                [105.9892332, 10.3314352],
                [105.9895391, 10.3313865],
                [105.9898432, 10.3313286],
                [105.990009, 10.3312917],
                [105.9901454, 10.3312614],
                [105.9904454, 10.331185],
                [105.9907428, 10.3310996],
                [105.991038, 10.3310051],
                [105.99133, 10.3309015],
                [105.9916186, 10.3307892],
                [105.9919036, 10.3306681],
                [105.9921846, 10.3305384],
                [105.9924614, 10.3304002],
                [105.9927337, 10.3302537],
                [105.9930013, 10.3300989],
                [105.9932639, 10.3299361],
                [105.9935213, 10.3297653],
                [105.9937731, 10.3295868],
                [105.9940192, 10.3294008],
                [105.9942594, 10.3292073],
                [105.9951137, 10.3285872],
                [105.9984159, 10.3261902],
                [106.0020489, 10.3235529],
                [106.00655, 10.3202856],
                [106.011971, 10.3163504],
                [106.0120557, 10.3162783],
                [106.0136167, 10.3149491],
                [106.0146787, 10.3140101],
                [106.0157471, 10.3130782],
                [106.016822, 10.3121534],
                [106.0179032, 10.3112358],
                [106.0180355, 10.311125],
                [106.0189906, 10.3103254],
                [106.0200843, 10.3094222],
                [106.0211842, 10.3085264],
                [106.0222903, 10.307638],
                [106.0234024, 10.3067569],
                [106.0239476, 10.3063309],
                [106.0245206, 10.3058832],
                [106.0256448, 10.305017],
                [106.0264376, 10.3044146],
                [106.0267749, 10.3041583],
                [106.0279109, 10.3033072],
                [106.0290527, 10.3024637],
                [106.0301536, 10.3016615],
                [106.0312599, 10.3008664],
                [106.0323713, 10.3000784],
                [106.0335094, 10.2993397],
                [106.0346526, 10.2986187],
                [106.0357475, 10.2980407],
                [106.0366457, 10.2976071],
                [106.0384634, 10.2967882],
                [106.0403847, 10.2959087],
                [106.0423024, 10.2950213],
                [106.0442162, 10.2941262],
                [106.0461263, 10.2932232],
                [106.0480326, 10.2923125],
                [106.049935, 10.2913941],
                [106.0518335, 10.2904678],
                [106.0537282, 10.2895339],
                [106.0556189, 10.2885922],
                [106.0575056, 10.2876429],
                [106.0594187, 10.2866703],
                [106.0613277, 10.2856898],
                [106.0632324, 10.2847014],
                [106.0651329, 10.2837052],
                [106.0670291, 10.282701],
                [106.0680842, 10.2821535],
                [106.0688459, 10.2817313],
                [106.0695722, 10.2813469],
                [106.0702271, 10.2810116],
                [106.0708856, 10.2806832],
                [106.0715476, 10.2803618],
                [106.072213, 10.2800473],
                [106.0728818, 10.2797399],
                [106.0735539, 10.2794394],
                [106.0742293, 10.2791461],
                [106.0749078, 10.2788599],
                [106.0755995, 10.2785766],
                [106.0762944, 10.2783008],
                [106.0769923, 10.2780324],
                [106.077693, 10.2777715],
                [106.0783966, 10.277518],
                [106.079103, 10.2772721],
                [106.079812, 10.2770337],
                [106.0805236, 10.2768029],
                [106.0812002, 10.2765902],
                [106.0816834, 10.2764727],
                [106.0821313, 10.2763748],
                [106.0825811, 10.2762861],
                [106.0830327, 10.2762066],
                [106.0834858, 10.2761363],
                [106.0839404, 10.2760753],
                [106.0843961, 10.2760236],
                [106.0848529, 10.2759812],
                [106.0853104, 10.2759481],
                [106.0857686, 10.2759244],
                [106.0862271, 10.27591],
                [106.0866858, 10.275905],
                [106.0871446, 10.2759094],
                [106.0876031, 10.2759231],
                [106.0880613, 10.2759462],
                [106.0885249, 10.2759792],
                [106.0889876, 10.2760217],
                [106.0894494, 10.2760737],
                [106.0899099, 10.2761354],
                [106.0903691, 10.2762065],
                [106.0908266, 10.2762871],
                [106.0912822, 10.2763771],
                [106.0917359, 10.2764766],
                [106.0921873, 10.2765854],
                [106.0926363, 10.2767035],
                [106.0930827, 10.2768309],
                [106.0935263, 10.2769675],
                [106.0939668, 10.2771132],
                [106.0944042, 10.277268],
                [106.0948381, 10.2774319],
                [106.0952685, 10.2776046],
                [106.0956628, 10.277781],
                [106.095947, 10.2779203],
                [106.0962364, 10.2780937],
                [106.0964942, 10.2782634],
                [106.0968468, 10.2784995],
                [106.0973813, 10.2788295],
                [106.0979524, 10.2791628],
                [106.0985279, 10.2794887],
                [106.0991077, 10.2798072],
                [106.0996916, 10.2801182],
                [106.1002796, 10.2804218],
                [106.1008715, 10.2807178],
                [106.1014883, 10.2810162],
                [106.1021091, 10.2813064],
                [106.1027338, 10.2815883],
                [106.1033623, 10.2818619],
                [106.1039944, 10.2821271],
                [106.1046302, 10.282384],
                [106.1052694, 10.2826324],
                [106.105912, 10.2828723],
                [106.1065578, 10.2831037],
                [106.1071587, 10.2833253],
                [106.1074188, 10.2834018],
                [106.1076375, 10.2834529],
                [106.1078507, 10.2834942],
                [106.1080545, 10.2835232],
                [106.1094974, 10.283677],
                [106.1104221, 10.2837735],
                [106.1113476, 10.2838614],
                [106.112274, 10.2839407],
                [106.1132011, 10.2840113],
                [106.1141289, 10.2840733],
                [106.1150572, 10.2841266],
                [106.115986, 10.2841713],
                [106.1169151, 10.2842073],
                [106.1178446, 10.2842347],
                [106.1187743, 10.2842534],
                [106.1197041, 10.2842635],
                [106.120634, 10.2842649],
                [106.1216222, 10.2842569],
                [106.1226103, 10.2842391],
                [106.1235981, 10.2842115],
                [106.1245857, 10.2841742],
                [106.1255727, 10.284127],
                [106.1265593, 10.2840701],
                [106.1275452, 10.2840035],
                [106.1284553, 10.283906],
                [106.1291832, 10.2838376],
                [106.1298372, 10.2837568],
                [106.1304922, 10.2836845],
                [106.1311481, 10.2836208],
                [106.1318049, 10.2835658],
                [106.1324623, 10.2835194],
                [106.1331203, 10.2834817],
                [106.1337787, 10.2834526],
                [106.1344375, 10.2834321],
                [106.1350965, 10.2834203],
                [106.1357608, 10.2834172],
                [106.136425, 10.2834229],
                [106.1370892, 10.2834373],
                [106.137753, 10.2834606],
                [106.1384165, 10.2834926],
                [106.1390795, 10.2835335],
                [106.1397418, 10.2835831],
                [106.1404035, 10.2836415],
                [106.1410642, 10.2837086],
                [106.141724, 10.2837845],
                [106.1423827, 10.2838691],
                [106.1430402, 10.2839624],
                [106.1437383, 10.2841296],
                [106.144434, 10.2843058],
                [106.1451273, 10.284491],
                [106.1458181, 10.2846853],
                [106.1465062, 10.2848886],
                [106.1471915, 10.2851008],
                [106.1478738, 10.2853219],
                [106.1485532, 10.2855519],
                [106.1492294, 10.2857907],
                [106.1499023, 10.2860383],
                [106.1505699, 10.2862939],
                [106.1512341, 10.2865582],
                [106.1518947, 10.286831],
                [106.1525515, 10.2871124],
                [106.1532045, 10.2874023],
                [106.1538535, 10.2877007],
                [106.1544985, 10.2880075],
                [106.1595134, 10.2901454],
                [106.160752, 10.2906623],
                [106.1619869, 10.2911878],
                [106.163218, 10.2917218],
                [106.1644452, 10.2922642],
                [106.1656686, 10.2928152],
                [106.166888, 10.2933745],
                [106.1681034, 10.2939423],
                [106.1693147, 10.2945184],
                [106.1705219, 10.2951029],
                [106.1717249, 10.2956957],
                [106.1729236, 10.2962967],
                [106.174118, 10.2969061],
                [106.1753081, 10.2975236],
                [106.1765106, 10.2981584],
                [106.1777085, 10.2988015],
                [106.1789017, 10.2994531],
                [106.1800902, 10.3001129],
                [106.1812739, 10.3007811],
                [106.1824527, 10.3014575],
                [106.1836266, 10.3021422],
                [106.1847955, 10.302835],
                [106.1859594, 10.303536],
                [106.1871182, 10.3042452],
                [106.1882718, 10.3049624],
                [106.1894202, 10.3056877],
                [106.1905634, 10.3064211],
                [106.1917012, 10.3071624],
                [106.1928336, 10.3079116],
                [106.1935121, 10.3083059],
                [106.1941947, 10.3086931],
                [106.1948814, 10.3090733],
                [106.1955721, 10.3094464],
                [106.1962668, 10.3098123],
                [106.1969653, 10.3101711],
                [106.1976676, 10.3105226],
                [106.1983736, 10.3108669],
                [106.1990832, 10.3112038],
                [106.1998355, 10.3115513],
                [106.2005916, 10.3118905],
                [106.2013514, 10.3122215],
                [106.2021149, 10.3125442],
                [106.202882, 10.3128585],
                [106.2036526, 10.3131645],
                [106.2044266, 10.3134622],
                [106.2052039, 10.3137513],
                [106.2059844, 10.314032],
                [106.2176784, 10.3169013],
                [106.2221345, 10.3176881],
                [106.2286028, 10.3188302],
                [106.2437822, 10.3201608],
                [106.249191, 10.3205496],
                [106.2502698, 10.320726],
                [106.2504561, 10.3207554],
                [106.2534043, 10.3213889],
                [106.2552554, 10.3218178],
                [106.2606352, 10.3229529],
                [106.2684136, 10.3249242],
                [106.2766762, 10.3275036],
                [106.2875451, 10.330774],
                [106.2879016, 10.3305688],
                [106.2882532, 10.3303554],
                [106.2885995, 10.3301339],
                [106.2889404, 10.3299043],
                [106.2892757, 10.329667],
                [106.2896052, 10.3294219],
                [106.2899199, 10.3291762],
                [106.2902288, 10.3289235],
                [106.2905317, 10.3286639],
                [106.2908284, 10.3283975],
                [106.2911189, 10.3281245],
                [106.2914029, 10.327845],
                [106.291937, 10.3273126],
                [106.2924656, 10.3267749],
                [106.2929886, 10.3262319],
                [106.2968633, 10.3223172],
                [106.297101, 10.3220971],
                [106.2973453, 10.321884],
                [106.2975959, 10.3216781],
                [106.2978527, 10.3214798],
                [106.2981154, 10.321289],
                [106.2983837, 10.321106],
                [106.2986575, 10.320931],
                [106.2989365, 10.3207641],
                [106.2992204, 10.3206054],
                [106.2995089, 10.3204552],
                [106.2998019, 10.3203134],
                [106.3000991, 10.3201804],
                [106.3004002, 10.3200561],
                [106.3007049, 10.3199407],
                [106.3010129, 10.3198343],
                [106.3013241, 10.319737],
                [106.301638, 10.3196488],
                [106.3019545, 10.3195699],
                [106.3022733, 10.3195003],
                [106.3025835, 10.3194419],
                [106.3028953, 10.3193924],
                [106.3032084, 10.3193517],
                [106.3035226, 10.3193199],
                [106.3038376, 10.3192971],
                [106.3041531, 10.3192832],
                [106.3044689, 10.3192782],
                [106.3047847, 10.3192823],
                [106.3051003, 10.3192953],
                [106.3054154, 10.3193172],
                [106.3057296, 10.3193481],
                [106.3060429, 10.3193878],
                [106.3063548, 10.3194365],
                [106.3066652, 10.319494],
                [106.3069738, 10.3195602],
                [106.3072803, 10.3196353],
                [106.3075845, 10.3197189],
                [106.3116561, 10.3210799],
                [106.3121208, 10.3212396],
                [106.3125833, 10.3214054],
                [106.3130436, 10.3215772],
                [106.3135015, 10.3217549],
                [106.313957, 10.3219386],
                [106.3144086, 10.3221276],
                [106.3148576, 10.3223223],
                [106.315304, 10.3225229],
                [106.3157477, 10.3227293],
                [106.316261, 10.3229598],
                [106.3169247, 10.3232362],
                [106.3176642, 10.323523],
                [106.3184068, 10.3238018],
                [106.3191525, 10.3240725],
                [106.3199013, 10.324335],
                [106.3206529, 10.3245894],
                [106.3214073, 10.3248355],
                [106.3221645, 10.3250735],
                [106.3228833, 10.325291],
                [106.3236044, 10.3255011],
                [106.3243277, 10.3257038],
                [106.3250531, 10.325899],
                [106.3257806, 10.3260867],
                [106.32651, 10.3262669],
                [106.3272412, 10.3264396],
                [106.3410902, 10.3298021],
                [106.3411667, 10.3299984],
                [106.3412521, 10.3301911],
                [106.3413465, 10.3303797],
                [106.3414494, 10.3305639],
                [106.3415608, 10.3307433],
                [106.3416804, 10.3309176],
                [106.3418079, 10.3310862],
                [106.3419431, 10.331249],
                [106.3420858, 10.3314056],
                [106.3422355, 10.3315556],
                [106.3423939, 10.3317004],
                [106.342559, 10.3318378],
                [106.3427304, 10.3319676],
                [106.3429076, 10.3320895],
                [106.3430904, 10.3322032],
                [106.3432783, 10.3323086],
                [106.343471, 10.3324052],
                [106.3436679, 10.332493],
                [106.3438688, 10.3325718],
                [106.3440732, 10.3326414],
                [106.3490832, 10.3342985],
                [106.3540721, 10.33585],
                [106.3597691, 10.3375916],
                [106.3646992, 10.3391828],
                [106.3651397, 10.3393239],
                [106.3655832, 10.3394559],
                [106.3660293, 10.3395789],
                [106.3664779, 10.3396927],
                [106.3669288, 10.3397974],
                [106.3673819, 10.3398928],
                [106.3678368, 10.339979],
                [106.3682935, 10.3400558],
                [106.3687517, 10.3401233],
                [106.3692112, 10.3401815],
                [106.3696718, 10.3402302],
                [106.3701338, 10.3402696],
                [106.3705966, 10.3402995],
                [106.3710599, 10.34032],
                [106.3715235, 10.3403309],
                [106.3719872, 10.3403324],
                [106.3724509, 10.3403245],
                [106.3729143, 10.340307],
                [106.3733773, 10.3402801],
                [106.3738396, 10.3402437],
                [106.374301, 10.3401979],
                [106.3747613, 10.3401427],
                [106.3769049, 10.3398307],
                [106.3785994, 10.3396214],
                [106.3810998, 10.3391996],
                [106.3838382, 10.3387233],
                [106.3869753, 10.3381353],
                [106.3927412, 10.3370801],
                [106.3985469, 10.3356732],
                [106.3993403, 10.3353539],
                [106.4001302, 10.3350264],
                [106.4009167, 10.3346908],
                [106.4016994, 10.3343471],
                [106.4024785, 10.3339953],
                [106.4032538, 10.3336355],
                [106.4040252, 10.3332678],
                [106.4047927, 10.3328921],
                [106.4054892, 10.3325425],
                [106.4061822, 10.3321863],
                [106.4068718, 10.3318237],
                [106.4075578, 10.3314546],
                [106.4082402, 10.331079],
                [106.4089189, 10.3306971],
                [106.4095938, 10.3303088],
                [106.4106449, 10.3296807],
                [106.4116916, 10.3290456],
                [106.4127338, 10.3284034],
                [106.4137715, 10.3277542],
                [106.4148047, 10.327098],
                [106.4158333, 10.3264349],
                [106.4168572, 10.3257648],
                [106.4178914, 10.3250779],
                [106.4189206, 10.3243839],
                [106.419945, 10.3236829],
                [106.4209643, 10.3229749],
                [106.4219786, 10.3222599],
                [106.4229878, 10.321538],
                [106.4239919, 10.3208092],
                [106.4264059, 10.3190675],
                [106.4290628, 10.3171533],
                [106.4302051, 10.3164085],
                [106.4313529, 10.3156719],
                [106.4325061, 10.3149436],
                [106.4336646, 10.3142235],
                [106.4348284, 10.3135117],
                [106.4359975, 10.3128082],
                [106.4370433, 10.3121886],
                [106.4380932, 10.3115756],
                [106.4391471, 10.3109693],
                [106.440205, 10.3103698],
                [106.4412668, 10.309777],
                [106.4423325, 10.309191],
                [106.443402, 10.3086117],
                [106.4439532, 10.308334],
                [106.4445087, 10.3080647],
                [106.4450683, 10.3078039],
                [106.445632, 10.3075515],
                [106.4461534, 10.3073272],
                [106.446678, 10.3071101],
                [106.4472057, 10.3069003],
                [106.4477363, 10.306698],
                [106.4482698, 10.306503],
                [106.4488351, 10.3066277],
                [106.4494024, 10.3067436],
                [106.4499714, 10.3068509],
                [106.4505421, 10.3069495],
                [106.4511142, 10.3070393],
                [106.4516877, 10.3071204],
                [106.4522624, 10.3071926],
                [106.4528361, 10.3072559],
                [106.4534107, 10.3073103],
                [106.4539861, 10.307356],
                [106.4545622, 10.307393],
                [106.4551388, 10.3074211],
                [106.4557158, 10.3074404],
                [106.456293, 10.307451],
                [106.4568703, 10.3074527],
                [106.4574475, 10.3074456],
                [106.4597308, 10.3073288],
                [106.4612536, 10.3072607],
                [106.4627767, 10.3072012],
                [106.4643001, 10.3071504],
                [106.4657933, 10.307109],
                [106.4672868, 10.3070759],
                [106.4687804, 10.3070511],
                [106.4702741, 10.3070346],
                [106.4717679, 10.3070264],
                [106.4732617, 10.3070265],
                [106.4740034, 10.3070542],
                [106.4747448, 10.3070876],
                [106.4756904, 10.3071388],
                [106.4766354, 10.3071993],
                [106.4769826, 10.3068164],
                [106.477337, 10.3064399],
                [106.4776986, 10.3060701],
                [106.4780671, 10.305707],
                [106.4784424, 10.3053507],
                [106.4788245, 10.3050014],
                [106.4792132, 10.3046593],
                [106.4795639, 10.3043614],
                [106.4799196, 10.3040692],
                [106.4802802, 10.303783],
                [106.4806455, 10.3035027],
                [106.4810156, 10.3032284],
                [106.4813903, 10.3029603],
                [106.4817694, 10.3026983],
                [106.4821529, 10.3024425],
                [106.4829269, 10.3019507],
                [106.4836959, 10.3014514],
                [106.4844599, 10.3009447],
                [106.4852187, 10.3004306],
                [106.4859724, 10.2999091],
                [106.4867128, 10.2993861],
                [106.4874479, 10.2988561],
                [106.4881778, 10.298319],
                [106.4889023, 10.2977749],
                [106.4896214, 10.2972239],
                [106.4904459, 10.2966113],
                [106.4912763, 10.2960064],
                [106.4921125, 10.2954092],
                [106.4929335, 10.2948343],
                [106.4937599, 10.2942669],
                [106.4945915, 10.2937071],
                [106.4954284, 10.2931548],
                [106.4962705, 10.2926101],
                [106.4971176, 10.2920732],
                [106.4979697, 10.2915439],
                [106.4988267, 10.2910224],
                [106.4997946, 10.2904904],
                [106.5007673, 10.2899671],
                [106.5017448, 10.2894523],
                [106.5027269, 10.2889463],
                [106.5037136, 10.2884489],
                [106.5047048, 10.2879603],
                [106.5057005, 10.2874805],
                [106.5067005, 10.2870095],
                [106.507612, 10.2865896],
                [106.5085271, 10.286177],
                [106.5094455, 10.2857718],
                [106.5103672, 10.285374],
                [106.5112922, 10.2849836],
                [106.5122204, 10.2846006],
                [106.5131518, 10.2842252],
                [106.5140863, 10.2838572],
                [106.5143967, 10.2837319],
                [106.5147092, 10.2836117],
                [106.5150238, 10.2834967],
                [106.5153143, 10.2833958],
                [106.5156063, 10.2832992],
                [106.5158998, 10.2832071],
                [106.5167939, 10.2825264],
                [106.5176185, 10.2819026],
                [106.5184382, 10.2812724],
                [106.5192527, 10.2806358],
                [106.5200621, 10.279993],
                [106.5208664, 10.2793438],
                [106.5216654, 10.2786884],
                [106.5224509, 10.2780338],
                [106.524326, 10.2764893],
                [106.5249728, 10.2759841],
                [106.5256136, 10.2754715],
                [106.5262483, 10.2749517],
                [106.5268769, 10.2744246],
                [106.5274992, 10.2738905],
                [106.5281153, 10.2733492],
                [106.5287259, 10.2728001],
                [106.5293301, 10.2722439],
                [106.5299276, 10.2716809],
                [106.5305185, 10.2711112],
                [106.5309815, 10.2707152],
                [106.5314506, 10.2703263],
                [106.5319258, 10.2699445],
                [106.5324068, 10.2695699],
                [106.5328172, 10.2692594],
                [106.5332318, 10.2689543],
                [106.5336503, 10.2686544],
                [106.5340727, 10.2683599],
                [106.5344989, 10.2680708],
                [106.5348673, 10.2678376],
                [106.53524, 10.267611],
                [106.5356166, 10.267391],
                [106.5359972, 10.2671776],
                [106.5363817, 10.266971],
                [106.5367698, 10.2667712],
                [106.5371615, 10.2665782],
                [106.5375566, 10.2663922],
                [106.5379767, 10.2662037],
                [106.5384004, 10.266023],
                [106.5388274, 10.2658501],
                [106.5392576, 10.2656853],
                [106.539691, 10.2655284],
                [106.5401273, 10.2653797],
                [106.5405663, 10.265239],
                [106.5420261, 10.2648969],
                [106.5435873, 10.2645392],
                [106.5451005, 10.2642009],
                [106.5466155, 10.2638704],
                [106.5481322, 10.2635476],
                [106.5496506, 10.2632326],
                [106.5511706, 10.2629253],
                [106.554202, 10.262302],
                [106.5557098, 10.2619688],
                [106.55723, 10.2616229],
                [106.5587481, 10.2612674],
                [106.5602637, 10.2609024],
                [106.561777, 10.2605279],
                [106.563261, 10.2601333],
                [106.5661429, 10.2593244],
                [106.5684184, 10.2586644],
                [106.5689956, 10.258497],
                [106.5718458, 10.2576615],
                [106.5742087, 10.2569624],
                [106.57657, 10.2562577],
                [106.5789295, 10.2555476],
                [106.5803217, 10.2551065],
                [106.5816316, 10.2546433],
                [106.5828375, 10.2541668],
                [106.5836177, 10.2538459],
                [106.5844012, 10.2535328],
                [106.5851879, 10.2532277],
                [106.5859777, 10.2529304],
                [106.5867706, 10.2526411],
                [106.5875664, 10.2523598],
                [106.5883651, 10.2520864],
                [106.5891665, 10.2518211],
                [106.5899707, 10.2515639],
                [106.5907775, 10.2513147],
                [106.5915869, 10.2510737],
                [106.5924311, 10.2508317],
                [106.5932779, 10.2505985],
                [106.5941271, 10.2503742],
                [106.5949787, 10.2501587],
                [106.5958326, 10.2499521],
                [106.5966886, 10.2497545],
                [106.5975467, 10.2495658],
                [106.598321, 10.2494073],
                [106.5991133, 10.2492788],
                [106.5997517, 10.2491837],
                [106.6004581, 10.2491054],
                [106.6015231, 10.2490021],
                [106.6027052, 10.2488898],
                [106.6038487, 10.2487631],
                [106.6049912, 10.2486273],
                [106.6061325, 10.2484824],
                [106.6072726, 10.2483286],
                [106.6083367, 10.2481766],
                [106.6093995, 10.2480168],
                [106.6104612, 10.2478491],
                [106.6115215, 10.2476735],
                [106.6125804, 10.24749],
                [106.6136379, 10.2472988],
                [106.6146939, 10.2470997],
                [106.6157484, 10.2468927],
                [106.6169933, 10.2466245],
                [106.6182362, 10.2463471],
                [106.619477, 10.2460605],
                [106.6207156, 10.2457649],
                [106.6219518, 10.2454601],
                [106.6231858, 10.2451463],
                [106.6244173, 10.2448234],
                [106.6255757, 10.2445108],
                [106.6267318, 10.2441902],
                [106.6278857, 10.2438616],
                [106.6290371, 10.243525],
                [106.6301861, 10.2431804],
                [106.6313327, 10.2428279],
                [106.6318478, 10.2426797],
                [106.6323655, 10.2425405],
                [106.6328856, 10.2424105],
                [106.633408, 10.2422895],
                [106.6338794, 10.2421886],
                [106.6343524, 10.2420951],
                [106.6348268, 10.242009],
                [106.6353026, 10.2419304],
                [106.6357796, 10.2418593],
                [106.6368619, 10.2417535],
                [106.6379451, 10.2416571],
                [106.6390292, 10.2415701],
                [106.6401139, 10.2414925],
                [106.6411994, 10.2414242],
                [106.6422854, 10.2413654],
                [106.6433718, 10.241316],
                [106.6444587, 10.241276],
                [106.6455278, 10.2412458],
                [106.6465971, 10.2412247],
                [106.6476665, 10.2412128],
                [106.648736, 10.2412099],
                [106.6498054, 10.2412162],
                [106.6508748, 10.2412316],
                [106.651944, 10.241256],
                [106.653013, 10.2412896],
                [106.6540816, 10.2413323],
                [106.6551498, 10.2413841],
                [106.655842, 10.2414027],
                [106.6565609, 10.2414132],
                [106.6571789, 10.241383],
                [106.6581395, 10.2413113],
                [106.6590994, 10.2412299],
                [106.6600584, 10.2411388],
                [106.6610164, 10.2410381],
                [106.6619732, 10.2409276],
                [106.6629289, 10.2408074],
                [106.6638833, 10.2406776],
                [106.6648362, 10.2405382],
                [106.6657877, 10.2403891],
                [106.6667222, 10.240233],
                [106.6676552, 10.2400677],
                [106.6685863, 10.239893],
                [106.6695157, 10.2397092],
                [106.6704431, 10.239516],
                [106.6713684, 10.2393137],
                [106.6722917, 10.2391021],
                [106.6732127, 10.2388814],
                [106.6741314, 10.2386515],
                [106.6745906, 10.2385359],
                [106.6750477, 10.2384125],
                [106.6755004, 10.2382817],
                [106.6759507, 10.2381432],
                [106.676648, 10.2378974],
                [106.6773419, 10.2376427],
                [106.6780324, 10.2373791],
                [106.6787194, 10.2371067],
                [106.6794027, 10.2368256],
                [106.6800822, 10.2365356],
                [106.6807579, 10.236237],
                [106.6814295, 10.2359298],
                [106.682097, 10.2356139],
                [106.6827602, 10.2352896],
                [106.6834191, 10.2349567],
                [106.6840736, 10.2346155],
                [106.6847234, 10.2342658],
                [106.6853686, 10.2339079],
                [106.686009, 10.2335418],
                [106.6866445, 10.2331674],
                [106.6872787, 10.2327826],
                [106.6879078, 10.2323897],
                [106.6885315, 10.2319888],
                [106.6891498, 10.2315798],
                [106.6897627, 10.2311628],
                [106.6903699, 10.230738],
                [106.6909715, 10.2303054],
                [106.6915672, 10.2298651],
                [106.692157, 10.2294172],
                [106.6927408, 10.2289616],
                [106.6933185, 10.2284986],
                [106.69389, 10.2280282],
                [106.6944551, 10.2275504],
                [106.6951609, 10.226937],
                [106.695862, 10.2263183],
                [106.6965583, 10.2256943],
                [106.6972497, 10.2250652],
                [106.6979363, 10.224431],
                [106.6986179, 10.2237916],
                [106.6992976, 10.2231443],
                [106.6999723, 10.2224919],
                [106.7006419, 10.2218344],
                [106.7013063, 10.2211719],
                [106.7019656, 10.2205045],
                [106.7026198, 10.2198321],
                [106.7033314, 10.2190676],
                [106.7040488, 10.2183084],
                [106.7047719, 10.2175544],
                [106.7055008, 10.2168059],
                [106.7062354, 10.2160627],
                [106.7069513, 10.215349],
                [106.7076724, 10.2146405],
                [106.7083987, 10.2139372],
                [106.7091302, 10.213239],
                [106.7098668, 10.2125461],
                [106.7101502, 10.212315],
                [106.7104364, 10.2120873],
                [106.7107254, 10.2118631],
                [106.7110555, 10.2116136],
                [106.7113891, 10.2113686],
                [106.7117261, 10.2111281],
                [106.7120396, 10.2108764],
                [106.7127108, 10.2103962],
                [106.7133608, 10.2099072],
                [106.7140162, 10.2094254],
                [106.714677, 10.2089507],
                [106.715343, 10.2084832],
                [106.7160143, 10.2080229],
                [106.7166907, 10.20757],
                [106.7173721, 10.2071245],
                [106.7180585, 10.2066864],
                [106.718759, 10.2062501],
                [106.7194644, 10.2058216],
                [106.7201746, 10.2054009],
                [106.7208896, 10.204988],
                [106.7216092, 10.204583],
                [106.7223334, 10.204186],
                [106.7230621, 10.203797],
                [106.7237951, 10.203416],
                [106.7245324, 10.2030431],
                [106.7258851, 10.2023263],
                [106.7272424, 10.201618],
                [106.7285635, 10.2009392],
                [106.7298889, 10.2002685],
                [106.7312184, 10.1996059],
                [106.7325522, 10.1989516],
                [106.7338901, 10.1983054],
                [106.735232, 10.1976675],
                [106.736578, 10.1970378],
                [106.7379279, 10.1964164],
                [106.7392818, 10.1958033],
                [106.7406395, 10.1951985],
                [106.7418955, 10.1946608],
                [106.9933717, 10.1399458],
                [106.8509964, 10.3271059],
                [106.810131, 10.3863656],
                [106.7946597, 10.4144888],
                [106.7925066, 10.4184025],
                [106.7775822, 10.4343757],
                [106.7714627, 10.4409249],
                [106.7611567, 10.4537878],
                [106.7583561, 10.4576386],
                [106.7562254, 10.4609118],
                [106.7536353, 10.4673029],
                [106.7512321, 10.475321],
                [106.7503853, 10.4784896],
                [106.7452324, 10.4978311],
                [106.7298844, 10.4934595],
                [106.7250525, 10.4896013],
                [106.7225361, 10.4874834],
                [106.7201642, 10.4848612],
                [106.7180848, 10.4828503],
                [106.716152, 10.481235],
                [106.7141469, 10.4798717],
                [106.7090237, 10.4757258],
                [106.705977, 10.4724393],
                [106.7051741, 10.4714692],
                [106.7023634, 10.4678761],
                [106.6995884, 10.4639229],
                [106.6978531, 10.4609931],
                [106.6962453, 10.4581532],
                [106.6949297, 10.4558678],
                [106.6947656, 10.4555827],
                [106.6938885, 10.454001],
                [106.6919511, 10.4501539],
                [106.6900703, 10.4471885],
                [106.6894857, 10.4461819],
                [106.6887069, 10.4452138],
                [106.6877706, 10.4440619],
                [106.6856551, 10.4419788],
                [106.6823392, 10.4401142],
                [106.6797535, 10.4392558],
                [106.6776963, 10.4388103],
                [106.6771136, 10.4386676],
                [106.6747385, 10.4386443],
                [106.6729554, 10.4386841],
                [106.671687, 10.4387475],
                [106.6706992, 10.4387968],
                [106.6695811, 10.4390794],
                [106.6685989, 10.4392614],
                [106.6675077, 10.4395517],
                [106.6659806, 10.4402388],
                [106.6644357, 10.4410878],
                [106.6633096, 10.4420621],
                [106.6620034, 10.4439725],
                [106.660482, 10.447359],
                [106.6598864, 10.4497178],
                [106.6598164, 10.4510138],
                [106.6598223, 10.4538213],
                [106.6601874, 10.462981],
                [106.6605413, 10.4668137],
                [106.6602553, 10.4692618],
                [106.6594257, 10.472791],
                [106.6587578, 10.4753659],
                [106.6576348, 10.4779238],
                [106.6557479, 10.4806632],
                [106.6536407, 10.4825393],
                [106.651568, 10.4835154],
                [106.6496395, 10.4838073],
                [106.6474741, 10.4839377],
                [106.6454715, 10.4836538],
                [106.6430675, 10.4828308],
                [106.640662, 10.4812879],
                [106.6392033, 10.4799231],
                [106.6381439, 10.4780895],
                [106.6369923, 10.4756261],
                [106.6360778, 10.4733963],
                [106.6357108, 10.4718853],
                [106.6353076, 10.4704464],
                [106.6350847, 10.4682872],
                [106.6348818, 10.4669378],
                [106.634257, 10.4639516],
                [106.6337412, 10.460893],
                [106.6337072, 10.4607482],
                [106.6333006, 10.4590222],
                [106.633063, 10.4585008],
                [106.6327708, 10.4579614],
                [106.6317852, 10.4565236],
                [106.6308363, 10.4552297],
                [106.6292315, 10.4535413],
                [106.6278075, 10.4524309],
                [106.6274817, 10.4521769],
                [106.6253686, 10.4510652],
                [106.6233471, 10.4502233],
                [106.6216719, 10.4497586],
                [106.6190134, 10.4488999],
                [106.6162814, 10.4477174],
                [106.6129299, 10.4460681],
                [106.6102885, 10.4446514],
                [106.608612, 10.4434308],
                [106.6063521, 10.4416714],
                [106.6038249, 10.4398161],
                [106.6017049, 10.4384217],
                [106.5994376, 10.4372133],
                [106.5972776, 10.436514],
                [106.5951831, 10.4362035],
                [106.5932207, 10.4361071],
                [106.5914852, 10.4364224],
                [106.5899473, 10.4369955],
                [106.5882965, 10.4381083],
                [106.5863386, 10.4364964],
                [106.5850081, 10.4352054],
                [106.5843683, 10.434196],
                [106.5841117, 10.4333495],
                [106.5840615, 10.4323904],
                [106.5843139, 10.431166],
                [106.5847843, 10.4296894],
                [106.5859662, 10.427729],
                [106.5873828, 10.4259006],
                [106.5876023, 10.4250263],
                [106.5876326, 10.4243314],
                [106.5876275, 10.4235522],
                [106.5875924, 10.4225571],
                [106.587669, 10.4221901],
                [106.5877917, 10.4218347],
                [106.5879704, 10.421582],
                [106.588264, 10.4211397],
                [106.5886281, 10.4205268],
                [106.589075, 10.4198444],
                [106.5892027, 10.4195411],
                [106.5892664, 10.4193516],
                [106.5892664, 10.4191115],
                [106.5892407, 10.4188841],
                [106.5891768, 10.4186946],
                [106.589049, 10.418543],
                [106.5888318, 10.4183788],
                [106.588391, 10.4181831],
                [106.5879439, 10.4180442],
                [106.5872924, 10.4178675],
                [106.5865897, 10.4177414],
                [106.5853571, 10.4174826],
                [106.584961, 10.417407],
                [106.5843989, 10.4172429],
                [106.5835813, 10.4169398],
                [106.5820928, 10.416239],
                [106.581352, 10.4158854],
                [106.5806492, 10.4155698],
                [106.57987, 10.4152161],
                [106.5790459, 10.4148689],
                [106.5786756, 10.4147552],
                [106.57819, 10.4146543],
                [106.5775896, 10.4146165],
                [106.5768614, 10.4145661],
                [106.575973, 10.4145449],
                [106.5751817, 10.4144466],
                [106.5748367, 10.4143455],
                [106.574364, 10.4141435],
                [106.5741469, 10.4140172],
                [106.5739552, 10.4138657],
                [106.5737508, 10.4136636],
                [106.5734442, 10.4133099],
                [106.5731379, 10.4129747],
                [106.572965, 10.4127856],
                [106.5725148, 10.4124752],
                [106.5720932, 10.4122796],
                [106.5716787, 10.4121929],
                [106.5712931, 10.4121961],
                [106.5709164, 10.4122529],
                [106.5705237, 10.4124168],
                [106.5701944, 10.4127231],
                [106.5689082, 10.4139949],
                [106.5684404, 10.4143901],
                [106.5680452, 10.4145912],
                [106.5676686, 10.4147279],
                [106.5672555, 10.4148084],
                [106.5666678, 10.4148339],
                [106.5661952, 10.4148339],
                [106.5656971, 10.4148213],
                [106.5649816, 10.4147331],
                [106.5643493, 10.4145753],
                [106.5638382, 10.4143606],
                [106.5628673, 10.4139186],
                [106.561922, 10.4135144],
                [106.5609959, 10.4131924],
                [106.5605232, 10.4130914],
                [106.5599611, 10.4129778],
                [106.559335, 10.412978],
                [106.5591181, 10.4130032],
                [106.5587092, 10.4130538],
                [106.5584922, 10.4131297],
                [106.5583517, 10.4132308],
                [106.558224, 10.4133319],
                [106.5580196, 10.4136226],
                [106.5579175, 10.4138248],
                [106.5578536, 10.4140269],
                [106.5578153, 10.4141659],
                [106.5577451, 10.4147787],
                [106.5577451, 10.4150945],
                [106.5577581, 10.4154357],
                [106.5578093, 10.416358],
                [106.5578925, 10.4168824],
                [106.5580203, 10.4175015],
                [106.5590161, 10.4214709],
                [106.5590799, 10.4221843],
                [106.5590768, 10.423001],
                [106.5589241, 10.4236169],
                [106.5587223, 10.4240446],
                [106.5584308, 10.4244403],
                [106.5580185, 10.4248447],
                [106.5574847, 10.4251945],
                [106.5569688, 10.425359],
                [106.5564145, 10.4254631],
                [106.5557704, 10.4255102],
                [106.55502, 10.4255087],
                [106.5542209, 10.4254501],
                [106.5534038, 10.4253547],
                [106.5510999, 10.4249],
                [106.5503821, 10.4248057],
                [106.5498203, 10.4248174],
                [106.5492854, 10.4249249],
                [106.5488227, 10.4251259],
                [106.5484557, 10.4253791],
                [106.5481399, 10.4257001],
                [106.547912, 10.4261295],
                [106.5477705, 10.4266406],
                [106.5475049, 10.4278932],
                [106.5473683, 10.4283312],
                [106.5472278, 10.4287229],
                [106.5471128, 10.4289377],
                [106.5468702, 10.4291906],
                [106.5465381, 10.4294305],
                [106.5461421, 10.4295444],
                [106.5457142, 10.4295381],
                [106.5453693, 10.4294624],
                [106.5450372, 10.4293108],
                [106.5442194, 10.4288687],
                [106.5435423, 10.4284139],
                [106.5427439, 10.4277002],
                [106.5419773, 10.4268916],
                [106.5412426, 10.4258494],
                [106.5405108, 10.424671],
                [106.5400534, 10.4238712],
                [106.5391736, 10.4220859],
                [106.5388003, 10.4214654],
                [106.5383411, 10.4209125],
                [106.5376259, 10.4202288],
                [106.5370372, 10.4208227],
                [106.5367125, 10.4213151],
                [106.5364326, 10.4219957],
                [106.5354494, 10.4244311],
                [106.5348971, 10.4259233],
                [106.5343691, 10.4272663],
                [106.5338047, 10.4282691],
                [106.5331541, 10.4292798],
                [106.5327788, 10.4297908],
                [106.5323638, 10.4302522],
                [106.5311873, 10.4313631],
                [106.5304478, 10.4322972],
                [106.5298102, 10.4332407],
                [106.5296222, 10.4334287],
                [106.5294386, 10.4335361],
                [106.529249, 10.4335749],
                [106.529029, 10.4335391],
                [106.5287651, 10.4334104],
                [106.5284408, 10.4332082],
                [106.5276699, 10.4326844],
                [106.5265982, 10.4323005],
                [106.5259577, 10.4320545],
                [106.5253657, 10.4317935],
                [106.5247762, 10.4314798],
                [106.524271, 10.4311059],
                [106.5238423, 10.430742],
                [106.5234296, 10.4302636],
                [106.5229425, 10.42962],
                [106.5225992, 10.4292422],
                [106.5222366, 10.4288856],
                [106.5216612, 10.4284461],
                [106.5212733, 10.4281231],
                [106.5209142, 10.4276901],
                [106.5206291, 10.4272607],
                [106.5204144, 10.4268632],
                [106.5202863, 10.4265207],
                [106.5201546, 10.4260247],
                [106.520088, 10.4258073],
                [106.5199029, 10.425331],
                [106.5197353, 10.4250065],
                [106.5194763, 10.4247317],
                [106.5191739, 10.4245535],
                [106.5188683, 10.4244629],
                [106.5185604, 10.4244355],
                [106.51829, 10.4244513],
                [106.517995, 10.4245209],
                [106.5176627, 10.4246612],
                [106.5168311, 10.425088],
                [106.5164685, 10.4252441],
                [106.5161402, 10.4253665],
                [106.5157003, 10.4254953],
                [106.5151712, 10.4256646],
                [106.5147615, 10.4258347],
                [106.5144368, 10.4260153],
                [106.5142062, 10.4262466],
                [106.5140833, 10.4264824],
                [106.5140089, 10.4267928],
                [106.514049, 10.4271386],
                [106.5142035, 10.42774],
                [106.5142185, 10.4279721],
                [106.5141863, 10.428179],
                [106.5141048, 10.4283879],
                [106.5139632, 10.4285736],
                [106.5137357, 10.4287551],
                [106.5134418, 10.4289197],
                [106.5130941, 10.4290252],
                [106.5127122, 10.4290526],
                [106.5122594, 10.4290547],
                [106.511738, 10.4289682],
                [106.510796, 10.4287129],
                [106.5104229, 10.4285192],
                [106.5101144, 10.428308],
                [106.5098275, 10.4280773],
                [106.509638, 10.4277916],
                [106.5094919, 10.4275166],
                [106.5093655, 10.4270871],
                [106.5091946, 10.4263213],
                [106.5090015, 10.4256038],
                [106.5088727, 10.42492],
                [106.5087783, 10.4239788],
                [106.5087225, 10.4236791],
                [106.5085201, 10.4230876],
                [106.5084428, 10.4229139],
                [106.5083279, 10.4227678],
                [106.5081442, 10.4226448],
                [106.5079069, 10.4225394],
                [106.5076287, 10.4224742],
                [106.5073225, 10.4224265],
                [106.5070229, 10.422449],
                [106.5067103, 10.4225296],
                [106.5064274, 10.4226531],
                [106.5056539, 10.4231553],
                [106.5053879, 10.4232819],
                [106.5051239, 10.4233178],
                [106.5048965, 10.4232777],
                [106.5046905, 10.4231658],
                [106.5044995, 10.4229949],
                [106.504345, 10.4227923],
                [106.5042464, 10.4225711],
                [106.5041814, 10.4223226],
                [106.5041655, 10.422035],
                [106.5041867, 10.4214291],
                [106.5041655, 10.4211993],
                [106.5041045, 10.4209982],
                [106.5039864, 10.4208442],
                [106.5038212, 10.4207302],
                [106.5036217, 10.4206775],
                [106.5033577, 10.4206711],
                [106.5029048, 10.420655],
                [106.5026893, 10.4206251],
                [106.5024951, 10.4205729],
                [106.5023161, 10.4205042],
                [106.5021462, 10.4203834],
                [106.5019747, 10.4202028],
                [106.5018746, 10.4200163],
                [106.5018108, 10.4198089],
                [106.5017684, 10.4195208],
                [106.5016837, 10.4188676],
                [106.501626, 10.4186318],
                [106.501535, 10.4184259],
                [106.5014075, 10.4182468],
                [106.5012376, 10.4181006],
                [106.5010039, 10.4179902],
                [106.5005396, 10.4178678],
                [106.5002847, 10.4177753],
                [106.5000693, 10.4176708],
                [106.4998902, 10.4175305],
                [106.4997719, 10.4173574],
                [106.4997294, 10.4171694],
                [106.4996353, 10.4165301],
                [106.4995307, 10.4159836],
                [106.4994459, 10.4156499],
                [106.4993629, 10.4154174],
                [106.4992366, 10.415256],
                [106.4990872, 10.4151561],
                [106.4989006, 10.4151203],
                [106.4987261, 10.4151591],
                [106.4985668, 10.4152636],
                [106.4984409, 10.4154173],
                [106.4983344, 10.4156565],
                [106.498152, 10.4162369],
                [106.4980612, 10.4164548],
                [106.4979331, 10.4166198],
                [106.4977689, 10.4167511],
                [106.4975795, 10.4168168],
                [106.4974333, 10.4168257],
                [106.4973016, 10.4167937],
                [106.4971861, 10.416721],
                [106.4970941, 10.4165985],
                [106.4970382, 10.4164406],
                [106.4970039, 10.4162649],
                [106.4970183, 10.4160981],
                [106.497067, 10.4159259],
                [106.4972114, 10.4154699],
                [106.497224, 10.415335],
                [106.4972078, 10.4151646],
                [106.4971428, 10.4150084],
                [106.4970273, 10.414847],
                [106.4968581, 10.414667],
                [106.4966463, 10.4145378],
                [106.4964524, 10.4144424],
                [106.4962468, 10.4144237],
                [106.4960827, 10.4144501],
                [106.4958831, 10.4145472],
                [106.4954972, 10.4148892],
                [106.4953561, 10.4149638],
                [106.4952074, 10.4149758],
                [106.4950875, 10.4149429],
                [106.4949798, 10.4148743],
                [106.4948918, 10.4147803],
                [106.494646, 10.4144326],
                [106.4945413, 10.4143102],
                [106.4944381, 10.4142236],
                [106.4943016, 10.4141565],
                [106.4941645, 10.414126],
                [106.4939868, 10.4141295],
                [106.4934976, 10.4141774],
                [106.4932731, 10.4141723],
                [106.4930396, 10.4141121],
                [106.4928163, 10.4140042],
                [106.4925203, 10.4138109],
                [106.4922834, 10.4136052],
                [106.492106, 10.4133839],
                [106.4919633, 10.4131705],
                [106.4918465, 10.4129228],
                [106.4917224, 10.4125177],
                [106.4916323, 10.4122201],
                [106.4915443, 10.4119985],
                [106.491407, 10.4118023],
                [106.4911902, 10.4116524],
                [106.4899049, 10.4108927],
                [106.489705, 10.4107626],
                [106.4895065, 10.4106064],
                [106.4892088, 10.4102603],
                [106.4890536, 10.4101059],
                [106.4888226, 10.4099569],
                [106.4885364, 10.4098365],
                [106.4881738, 10.4097306],
                [106.4880206, 10.4096601],
                [106.4878608, 10.4095419],
                [106.487761, 10.4094427],
                [106.4876816, 10.4093034],
                [106.4875767, 10.4090427],
                [106.4875114, 10.4089382],
                [106.4874252, 10.4088309],
                [106.487349, 10.4087707],
                [106.4872696, 10.4087612],
                [106.4871784, 10.4087802],
                [106.4869875, 10.4088615],
                [106.4868727, 10.4088973],
                [106.4867579, 10.4089079],
                [106.4866549, 10.408891],
                [106.4865669, 10.4088477],
                [106.4864918, 10.4087638],
                [106.4864412, 10.4086724],
                [106.4864121, 10.4085717],
                [106.4863696, 10.408212],
                [106.4863468, 10.4080747],
                [106.4863013, 10.4079225],
                [106.4860934, 10.4074599],
                [106.4860297, 10.4073077],
                [106.4859887, 10.4071748],
                [106.485963, 10.4070211],
                [106.485969, 10.4068779],
                [106.4859903, 10.4067316],
                [106.4861056, 10.4065839],
                [106.4866302, 10.4062114],
                [106.48678, 10.4060783],
                [106.4868449, 10.4059647],
                [106.486854, 10.4058583],
                [106.4868287, 10.4057376],
                [106.4867728, 10.4056134],
                [106.4866735, 10.4054856],
                [106.4865238, 10.4053631],
                [106.4863505, 10.405285],
                [106.4858525, 10.4051182],
                [106.4857334, 10.4050508],
                [106.4856613, 10.4049567],
                [106.4856432, 10.4048556],
                [106.4856721, 10.4047313],
                [106.4857515, 10.4046036],
                [106.4859698, 10.4043693],
                [106.4861611, 10.404206],
                [106.4862855, 10.4041062],
                [106.4863493, 10.4040092],
                [106.486372, 10.4039212],
                [106.4863629, 10.4038227],
                [106.4863235, 10.4036928],
                [106.4862628, 10.4036137],
                [106.4861808, 10.403557],
                [106.4860822, 10.4035152],
                [106.4859532, 10.403538],
                [106.4858151, 10.4036409],
                [106.485589, 10.4038081],
                [106.4854752, 10.4038096],
                [106.4853872, 10.4037618],
                [106.485322, 10.4036738],
                [106.4852871, 10.4035544],
                [106.4852643, 10.4033186],
                [106.4852097, 10.4031858],
                [106.4851247, 10.4031127],
                [106.4850124, 10.4030589],
                [106.4848606, 10.4030635],
                [106.4846975, 10.4031162],
                [106.4843714, 10.4033315],
                [106.4842061, 10.4035003],
                [106.4838521, 10.4037156],
                [106.4836118, 10.4037916],
                [106.4834637, 10.4038971],
                [106.483262, 10.4040807],
                [106.4830045, 10.4042095],
                [106.482732, 10.4043382],
                [106.4825324, 10.4043783],
                [106.4823136, 10.4043846],
                [106.4821977, 10.4044057],
                [106.4821205, 10.4044669],
                [106.4820153, 10.4046822],
                [106.4819595, 10.404735],
                [106.4818737, 10.404773],
                [106.4817383, 10.4047695],
                [106.4814982, 10.4047434],
                [106.4814453, 10.4043214],
                [106.4813487, 10.4038866],
                [106.4812221, 10.4034265],
                [106.4811728, 10.4029749],
                [106.4811642, 10.4026457],
                [106.4810912, 10.4023492],
                [106.4810548, 10.4020478],
                [106.4809668, 10.4017642],
                [106.4809638, 10.4016777],
                [106.4810002, 10.4014986],
                [106.4809911, 10.4014091],
                [106.4809061, 10.4012449],
                [106.4808846, 10.4011502],
                [106.4808897, 10.4009444],
                [106.4808565, 10.4008264],
                [106.4807927, 10.4006206],
                [106.4807407, 10.4003948],
                [106.4806633, 10.4002635],
                [106.4806299, 10.4001683],
                [106.4806148, 10.4000728],
                [106.4806148, 10.3999743],
                [106.480545, 10.3998161],
                [106.4805268, 10.3996699],
                [106.480545, 10.3995326],
                [106.4806633, 10.3991745],
                [106.4806764, 10.3990558],
                [106.4806721, 10.398944],
                [106.480649, 10.3988092],
                [106.4805595, 10.398657],
                [106.4804318, 10.3984419],
                [106.4803195, 10.3982437],
                [106.4802608, 10.3980454],
                [106.4802761, 10.3978722],
                [106.4803501, 10.3976137],
                [106.4803986, 10.3974179],
                [106.4803986, 10.3972472],
                [106.4803654, 10.397069],
                [106.4803169, 10.3969084],
                [106.4802531, 10.3967955],
                [106.4801766, 10.3967578],
                [106.480072, 10.3967653],
                [106.4798933, 10.3968206],
                [106.4792859, 10.3970939],
                [106.4788745, 10.3972252],
                [106.4784108, 10.3974417],
                [106.4772614, 10.3976884],
                [106.4763828, 10.3978278],
                [106.4760674, 10.3979017],
                [106.4758957, 10.3979524],
                [106.4757133, 10.398041],
                [106.4754421, 10.3981745],
                [106.4749405, 10.3983609],
                [106.474769, 10.3984088],
                [106.4745904, 10.3984319],
                [106.4739535, 10.3984443],
                [106.4735511, 10.398565],
                [106.4732137, 10.3986005],
                [106.473081, 10.3987232],
                [106.4728998, 10.3987897],
                [106.4726995, 10.3988198],
                [106.4724851, 10.3988223],
                [106.472281, 10.3987985],
                [106.4721777, 10.398737],
                [106.4720986, 10.398663],
                [106.4719952, 10.3986391],
                [106.4719174, 10.398604],
                [106.47186, 10.3985124],
                [106.4713159, 10.3982275],
                [106.4700528, 10.3977146],
                [106.4699203, 10.3976178],
                [106.4698391, 10.3975025],
                [106.4697345, 10.3972043],
                [106.4696244, 10.3970144],
                [106.4695053, 10.3968831],
                [106.4693862, 10.396821],
                [106.4692635, 10.3968192],
                [106.4690885, 10.3968458],
                [106.4689821, 10.3968298],
                [106.4683505, 10.3965938],
                [106.4681972, 10.3965654],
                [106.4680384, 10.3965636],
                [106.4677316, 10.396624],
                [106.4675476, 10.3966364],
                [106.4673996, 10.3966861],
                [106.4672697, 10.3967482],
                [106.4671632, 10.3968281],
                [106.4670839, 10.3969346],
                [106.4670009, 10.3969825],
                [106.4667897, 10.3970819],
                [106.4667176, 10.3971457],
                [106.4666562, 10.3972167],
                [106.4665476, 10.397425],
                [106.4664685, 10.397681],
                [106.466443, 10.3980199],
                [106.4664098, 10.3981805],
                [106.466318, 10.3983487],
                [106.4660015, 10.398813],
                [106.4659122, 10.3988958],
                [106.4657336, 10.3989987],
                [106.4655754, 10.3991694],
                [106.4654157, 10.3992242],
                [106.4653634, 10.3992597],
                [106.4653327, 10.39932],
                [106.4652551, 10.3995241],
                [106.4651571, 10.3995169],
                [106.4650493, 10.3995468],
                [106.4649598, 10.3996438],
                [106.464717, 10.4000482],
                [106.4646048, 10.4001915],
                [106.4644712, 10.4003094],
                [106.4643104, 10.4004019],
                [106.4641268, 10.4004541],
                [106.4634531, 10.4005437],
                [106.4631791, 10.4005324],
                [106.4629636, 10.4004607],
                [106.4625479, 10.400222],
                [106.4618742, 10.3997892],
                [106.4618256, 10.3997026],
                [106.4617923, 10.3995176],
                [106.4617437, 10.3993952],
                [106.4616648, 10.3992758],
                [106.4613917, 10.3989146],
                [106.4613128, 10.3987833],
                [106.4612551, 10.398652],
                [106.4611034, 10.3982908],
                [106.4610215, 10.3981386],
                [106.4607998, 10.3978504],
                [106.4605247, 10.397615],
                [106.4604144, 10.3974166],
                [106.4602817, 10.3971016],
                [106.4601631, 10.3968733],
                [106.459783, 10.396289],
                [106.459484, 10.3960406],
                [106.4590149, 10.3954925],
                [106.4587845, 10.3954647],
                [106.4584865, 10.3953929],
                [106.4581709, 10.3953586],
                [106.4576863, 10.3952302],
                [106.4574825, 10.3952069],
                [106.4571177, 10.3951099],
                [106.4564826, 10.3948946],
                [106.4561375, 10.3948326],
                [106.4559768, 10.3950108],
                [106.4559092, 10.3950949],
                [106.4558747, 10.395199],
                [106.4558207, 10.3953528],
                [106.4554941, 10.3957841],
                [106.4552559, 10.3961319],
                [106.4551221, 10.3966922],
                [106.4551068, 10.3970461],
                [106.455066, 10.3972092],
                [106.4548976, 10.3975707],
                [106.4547674, 10.3981128],
                [106.4545181, 10.3985876],
                [106.4544983, 10.3990331],
                [106.4542276, 10.3998477],
                [106.4541859, 10.3998825],
                [106.4541068, 10.4002151],
                [106.4535754, 10.3998963],
                [106.4528855, 10.3995312],
                [106.4519987, 10.3990988],
                [106.4513476, 10.3988116],
                [106.4511856, 10.398761],
                [106.4507049, 10.3985383],
                [106.4505236, 10.3984233],
                [106.4503434, 10.3983062],
                [106.4500633, 10.3981816],
                [106.4498552, 10.3982006],
                [106.449592, 10.3982726],
                [106.4491746, 10.3983539],
                [106.4487316, 10.3984611],
                [106.4485389, 10.3985446],
                [106.4483723, 10.3985976],
                [106.4480601, 10.3986772],
                [106.4481222, 10.3989921],
                [106.448188, 10.3993799],
                [106.4483857, 10.4002571],
                [106.4484841, 10.4005077],
                [106.4486459, 10.4009525],
                [106.4489555, 10.4019034],
                [106.4480926, 10.402157],
                [106.4484272, 10.4029142],
                [106.4486678, 10.4035959],
                [106.4489549, 10.4045036],
                [106.4502535, 10.4041938],
                [106.4506699, 10.4037779],
                [106.4511228, 10.4033745],
                [106.4515416, 10.4031178],
                [106.4517506, 10.4029613],
                [106.4520611, 10.4026803],
                [106.452228, 10.4025473],
                [106.4524058, 10.4024408],
                [106.4526431, 10.4023174],
                [106.4531546, 10.4020104],
                [106.453354, 10.4018445],
                [106.4534171, 10.4017282],
                [106.4534559, 10.4016209],
                [106.4535335, 10.4015224],
                [106.4536923, 10.4014363],
                [106.454027, 10.4012996],
                [106.4542025, 10.4009825],
                [106.4543686, 10.4008872],
                [106.4545339, 10.4008919],
                [106.4548058, 10.4010746],
                [106.4550061, 10.4012201],
                [106.4551198, 10.4013568],
                [106.4553183, 10.4016904],
                [106.4553886, 10.4017703],
                [106.4555618, 10.4019034],
                [106.4556467, 10.4019975],
                [106.4559246, 10.4021767],
                [106.4561415, 10.4022721],
                [106.4566289, 10.4025732],
                [106.4576069, 10.4031429],
                [106.4583898, 10.4037548],
                [106.4593806, 10.4044756],
                [106.4596933, 10.4047891],
                [106.4591916, 10.4052747],
                [106.4589747, 10.4055131],
                [106.4586506, 10.4057867],
                [106.458204, 10.4061456],
                [106.4576758, 10.406635],
                [106.4571374, 10.4072223],
                [106.4567173, 10.4077834],
                [106.4560929, 10.4087559],
                [106.4553676, 10.4097],
                [106.4551114, 10.4099236],
                [106.4549526, 10.4101969],
                [106.4545159, 10.4106299],
                [106.4537003, 10.4117338],
                [106.4530075, 10.4124188],
                [106.4523406, 10.4129132],
                [106.452115, 10.4130487],
                [106.4520123, 10.4131395],
                [106.4519089, 10.4133313],
                [106.4512388, 10.4129208],
                [106.449166, 10.4112789],
                [106.448783, 10.4113865],
                [106.4484278, 10.4114794],
                [106.4479069, 10.4116577],
                [106.4474842, 10.4118434],
                [106.4473405, 10.4118878],
                [106.447201, 10.4119089],
                [106.4470165, 10.4119215],
                [106.4467997, 10.4119701],
                [106.4463448, 10.4121621],
                [106.4462998, 10.4122824],
                [106.4462697, 10.4126116],
                [106.446186, 10.4130021],
                [106.4461217, 10.4136563],
                [106.4451328, 10.4137665],
                [106.4446671, 10.4139185],
                [106.4433209, 10.4142921],
                [106.4423862, 10.4145619],
                [106.4415346, 10.4146009],
                [106.4410005, 10.4147358],
                [106.440401, 10.4149274],
                [106.4402369, 10.4144522],
                [106.4401379, 10.4141343],
                [106.4401201, 10.4140151],
                [106.4400742, 10.4137215],
                [106.4398563, 10.4132663],
                [106.4396439, 10.412802],
                [106.4394873, 10.41242],
                [106.4394122, 10.4121499],
                [106.439322, 10.41177],
                [106.4392937, 10.4117391],
                [106.4392466, 10.4117332],
                [106.4387353, 10.4119093],
                [106.4366428, 10.41274],
                [106.434285, 10.4134051],
                [106.433619, 10.4135582],
                [106.4331367, 10.4138268],
                [106.4327718, 10.4140075],
                [106.4322997, 10.4142509],
                [106.432139, 10.4143588],
                [106.4320178, 10.4144717],
                [106.4319023, 10.4146385],
                [106.4316985, 10.415447],
                [106.4306702, 10.4151584],
                [106.4305222, 10.4150856],
                [106.4304125, 10.4149927],
                [106.4302032, 10.4147217],
                [106.4301318, 10.414551],
                [106.4298102, 10.4144757],
                [106.4297052, 10.4142169],
                [106.4295807, 10.4137379],
                [106.4294906, 10.4131448],
                [106.4293618, 10.4125391],
                [106.4291923, 10.4119145],
                [106.4290958, 10.4116718],
                [106.4288855, 10.4113552],
                [106.4286473, 10.4108846],
                [106.428362, 10.4104702],
                [106.428013, 10.4100613],
                [106.4278188, 10.4097867],
                [106.42662, 10.4104021],
                [106.4258705, 10.4106976],
                [106.4251452, 10.4110856],
                [106.4246811, 10.4113369],
                [106.4247179, 10.4114342],
                [106.4247855, 10.4115663],
                [106.4249457, 10.411826],
                [106.4251017, 10.4121024],
                [106.4251784, 10.4121845],
                [106.4254936, 10.4127183],
                [106.4258734, 10.4135108],
                [106.4261126, 10.4139951],
                [106.4262972, 10.4144341],
                [106.4257671, 10.4148468],
                [106.4248535, 10.4156563],
                [106.4242797, 10.4161816],
                [106.4238286, 10.4167779],
                [106.4230776, 10.4177245],
                [106.4227207, 10.4182728],
                [106.4225567, 10.4185816],
                [106.4220814, 10.4197024],
                [106.4215798, 10.4209766],
                [106.421412, 10.4212996],
                [106.421329, 10.4213954],
                [106.4212225, 10.4214611],
                [106.4207732, 10.4216261],
                [106.4207043, 10.4220051],
                [106.4205557, 10.422108],
                [106.4203736, 10.4221707],
                [106.4203713, 10.4222863],
                [106.4202922, 10.4223465],
                [106.420259, 10.4224996],
                [106.4203994, 10.4231772],
                [106.4204519, 10.4235421],
                [106.4204883, 10.4240465],
                [106.4205502, 10.4243664],
                [106.4203311, 10.424665],
                [106.419995, 10.4250142],
                [106.4203772, 10.4256945],
                [106.4208053, 10.4264629],
                [106.4211638, 10.4277245],
                [106.4199491, 10.4280809],
                [106.4197163, 10.4283096],
                [106.419516, 10.4285003],
                [106.4193455, 10.4286778],
                [106.4192001, 10.4288495],
                [106.4188065, 10.4291864],
                [106.4181902, 10.4296985],
                [106.4177499, 10.429702],
                [106.4173674, 10.4297269],
                [106.4164111, 10.4297286],
                [106.4148052, 10.429718],
                [106.4146801, 10.4302109],
                [106.4146608, 10.4305338],
                [106.4146548, 10.430956],
                [106.4147276, 10.4314843],
                [106.4148272, 10.4318532],
                [106.4148425, 10.4320791],
                [106.4153452, 10.4334619],
                [106.4156944, 10.4337065],
                [106.4162436, 10.4340154],
                [106.4166806, 10.4341601],
                [106.4169551, 10.434311],
                [106.4173574, 10.4345913],
                [106.4175055, 10.4346714],
                [106.4176739, 10.4347121],
                [106.417899, 10.4347084],
                [106.4189247, 10.4348308],
                [106.4191994, 10.4348248],
                [106.4195772, 10.4348651],
                [106.4199732, 10.4348442],
                [106.4204575, 10.4347062],
                [106.4206437, 10.4347413],
                [106.4213378, 10.4346761],
                [106.421906, 10.4345802],
                [106.4226223, 10.4344258],
                [106.4237009, 10.4341487],
                [106.4252849, 10.4339309],
                [106.4261953, 10.4338563],
                [106.4273828, 10.4337724],
                [106.4274466, 10.4349393],
                [106.4275133, 10.4364882],
                [106.4288606, 10.4366556],
                [106.4296552, 10.436738],
                [106.4300176, 10.4367329],
                [106.4308546, 10.4365171],
                [106.4311779, 10.4368165],
                [106.4313496, 10.4371457],
                [106.4314655, 10.4372323],
                [106.4317079, 10.4372998],
                [106.4318775, 10.4374264],
                [106.432092, 10.4375256],
                [106.4331733, 10.4383981],
                [106.4334106, 10.4385337],
                [106.4341889, 10.4387595],
                [106.4346712, 10.4389402],
                [106.4352432, 10.4389912],
                [106.4353551, 10.4389753],
                [106.4355918, 10.4394076],
                [106.4356712, 10.4396059],
                [106.4356819, 10.4397832],
                [106.4357052, 10.4399103],
                [106.4357886, 10.4402623],
                [106.4357762, 10.4406439],
                [106.4358386, 10.4411317],
                [106.4360376, 10.4416355],
                [106.4360981, 10.4416718],
                [106.4363456, 10.4415915],
                [106.436759, 10.4413833],
                [106.4368815, 10.4413632],
                [106.437175, 10.4414636],
                [106.4374633, 10.4410821],
                [106.4376215, 10.4412829],
                [106.437795, 10.4420031],
                [106.4379099, 10.4422264],
                [106.4380185, 10.4425171],
                [106.438237, 10.4433676],
                [106.4385556, 10.4433377],
                [106.4387748, 10.443678],
                [106.4389192, 10.4440578],
                [106.4390318, 10.4444179],
                [106.4391184, 10.4446007],
                [106.4393728, 10.4449999],
                [106.4395154, 10.4453335],
                [106.4400868, 10.4451647],
                [106.4405753, 10.4450722],
                [106.4413267, 10.4448632],
                [106.4418739, 10.4447324],
                [106.4422721, 10.4445674],
                [106.4424057, 10.4445465],
                [106.4430338, 10.4457253],
                [106.4436498, 10.4467847],
                [106.4439199, 10.4471966],
                [106.4441907, 10.4475353],
                [106.4446617, 10.4480747],
                [106.4454393, 10.4490329],
                [106.4456883, 10.4493754],
                [106.4459355, 10.4495777],
                [106.446226, 10.4498066],
                [106.4464462, 10.4499184],
                [106.4469821, 10.4501597],
                [106.4471834, 10.4503095],
                [106.447252, 10.4503833],
                [106.4472992, 10.4504825],
                [106.4475439, 10.4511451],
                [106.4483142, 10.4509636],
                [106.4484966, 10.4509003],
                [106.4489193, 10.4508328],
                [106.4493785, 10.4507167],
                [106.4495587, 10.4506956],
                [106.4500538, 10.4504618],
                [106.4504771, 10.4503067],
                [106.4507776, 10.4502544],
                [106.4511766, 10.4503096],
                [106.4516379, 10.4504529],
                [106.4521082, 10.4505678],
                [106.4525125, 10.4506057],
                [106.4529888, 10.4505436],
                [106.4534453, 10.4505276],
                [106.4540652, 10.451007],
                [106.453863, 10.4514396],
                [106.4536591, 10.4518659],
                [106.4534703, 10.4522014],
                [106.4531935, 10.4525622],
                [106.4531441, 10.452691],
                [106.4530862, 10.4529948],
                [106.4529875, 10.4532206],
                [106.4528716, 10.4533789],
                [106.4527686, 10.4534358],
                [106.4526399, 10.4534379],
                [106.4523207, 10.4533797],
                [106.4522129, 10.4533901],
                [106.4521128, 10.4534319],
                [106.4519307, 10.4535364],
                [106.4517851, 10.4535752],
                [106.4516151, 10.4535916],
                [106.4510977, 10.4535289],
                [106.4508474, 10.4535438],
                [106.4506168, 10.453599],
                [106.4503649, 10.4537095],
                [106.4497549, 10.4541347],
                [106.4495334, 10.4542511],
                [106.4485715, 10.4545301],
                [106.4478563, 10.4548422],
                [106.4475346, 10.455078],
                [106.4471578, 10.4555794],
                [106.4469587, 10.4557651],
                [106.4468356, 10.4558345],
                [106.4467436, 10.4558398],
                [106.4466083, 10.4557955],
                [106.4464008, 10.455705],
                [106.446307, 10.4556908],
                [106.4462131, 10.4556943],
                [106.4461175, 10.4557405],
                [106.446076, 10.4558168],
                [106.4460652, 10.4559215],
                [106.4461627, 10.4563378],
                [106.4462936, 10.4567008],
                [106.4463086, 10.4568274],
                [106.4462893, 10.456954],
                [106.4462386, 10.4570441],
                [106.4461293, 10.4571232],
                [106.445703, 10.4572829],
                [106.4452432, 10.4572381],
                [106.4451472, 10.4572416],
                [106.4450669, 10.4572743],
                [106.4449814, 10.4573684],
                [106.4447621, 10.4576781],
                [106.4445527, 10.45789],
                [106.4442857, 10.4580646],
                [106.4440429, 10.4581332],
                [106.4438589, 10.4581275],
                [106.4436669, 10.4580642],
                [106.4435424, 10.4580336],
                [106.4434212, 10.4580357],
                [106.4429143, 10.458141],
                [106.4425699, 10.4581321],
                [106.4424076, 10.458156],
                [106.4422562, 10.4582139],
                [106.4421491, 10.458326],
                [106.442093, 10.4584364],
                [106.4420459, 10.4585946],
                [106.4419528, 10.4593369],
                [106.4419142, 10.4596007],
                [106.4419271, 10.4597547],
                [106.4419786, 10.4598771],
                [106.4421845, 10.4601873],
                [106.4422339, 10.4603646],
                [106.4422403, 10.4605418],
                [106.4422862, 10.4615424],
                [106.4421671, 10.4633736],
                [106.4419759, 10.4644027],
                [106.442178, 10.4656554],
                [106.4421455, 10.4659535],
                [106.4420336, 10.4662693],
                [106.4418238, 10.4665749],
                [106.4415659, 10.4668166],
                [106.4412934, 10.4669398],
                [106.4409861, 10.46698],
                [106.4407186, 10.4669471],
                [106.4404723, 10.4668555],
                [106.4401908, 10.4666571],
                [106.4399581, 10.4664016],
                [106.43983, 10.4663111],
                [106.4396621, 10.4662615],
                [106.4394979, 10.4663076],
                [106.4393716, 10.4664123],
                [106.4391795, 10.4667053],
                [106.438961, 10.4668843],
                [106.4386955, 10.4669977],
                [106.4381942, 10.4675657],
                [106.4380307, 10.4676945],
                [106.437842, 10.4677781],
                [106.4373483, 10.4679593],
                [106.4368873, 10.4682377],
                [106.4367699, 10.4683807],
                [106.4364955, 10.468856],
                [106.4361114, 10.4693951],
                [106.4359934, 10.4695185],
                [106.4358598, 10.4695929],
                [106.4357123, 10.4696158],
                [106.4355522, 10.4696023],
                [106.4354227, 10.4695521],
                [106.4352964, 10.4694656],
                [106.4351855, 10.4693232],
                [106.4349453, 10.4689552],
                [106.4347723, 10.4687164],
                [106.4346525, 10.4686418],
                [106.4345022, 10.4686344],
                [106.434393, 10.4687239],
                [106.4338207, 10.4694879],
                [106.4334405, 10.469927],
                [106.4324325, 10.4707802],
                [106.4320115, 10.4714853],
                [106.4310291, 10.4727475],
                [106.430557, 10.4734049],
                [106.43031, 10.4738476],
                [106.4301584, 10.4739913],
                [106.4299491, 10.4740765],
                [106.4296658, 10.4740853],
                [106.4292075, 10.474002],
                [106.4280509, 10.4735637],
                [106.4275746, 10.4734182],
                [106.4274054, 10.4733981],
                [106.4272488, 10.4734087],
                [106.4270578, 10.4734814],
                [106.4268848, 10.4735961],
                [106.4262647, 10.4738282],
                [106.4258072, 10.4739491],
                [106.425681, 10.4740181],
                [106.4255544, 10.4741268],
                [106.4254654, 10.4742903],
                [106.4253738, 10.474622],
                [106.4252615, 10.475556],
                [106.4251644, 10.4764959],
                [106.4250976, 10.4767555],
                [106.4249884, 10.4770002],
                [106.4244149, 10.477785],
                [106.4241842, 10.4783758],
                [106.4239961, 10.4786384],
                [106.4236805, 10.4788383],
                [106.4226939, 10.4793623],
                [106.4217282, 10.4794922],
                [106.421166, 10.4796293],
                [106.4206424, 10.479815],
                [106.4200888, 10.4800872],
                [106.4194901, 10.4804838],
                [106.4189527, 10.4809994],
                [106.4184803, 10.4814893],
                [106.4181204, 10.4817641],
                [106.4177316, 10.4819567],
                [106.4173949, 10.4820698],
                [106.4174563, 10.4824122],
                [106.4174399, 10.482532],
                [106.4174471, 10.4825941],
                [106.4174868, 10.4826349],
                [106.4175788, 10.4826704],
                [106.4176474, 10.4827289],
                [106.4177737, 10.4830376],
                [106.4177514, 10.4832309],
                [106.4177839, 10.4834012],
                [106.4177586, 10.4834952],
                [106.4175547, 10.4836833],
                [106.4174915, 10.4840275],
                [106.4173093, 10.4840488],
                [106.417266, 10.4844338],
                [106.4167844, 10.4852719],
                [106.4166942, 10.4855522],
                [106.4166834, 10.4862761],
                [106.4168313, 10.4872909],
                [106.4170199, 10.4882477],
                [106.4162323, 10.4885647],
                [106.4159397, 10.4886422],
                [106.4155799, 10.4887652],
                [106.4153541, 10.4888505],
                [106.4151985, 10.4889772],
                [106.4151053, 10.4889834],
                [106.4148501, 10.4889433],
                [106.4143602, 10.4886911],
                [106.4142472, 10.4886655],
                [106.4141228, 10.4886699],
                [106.4140367, 10.4886617],
                [106.4139882, 10.488626],
                [106.4138581, 10.4884654],
                [106.4137688, 10.4883963],
                [106.4136776, 10.48839],
                [106.4135993, 10.4884301],
                [106.4135092, 10.4885324],
                [106.4133753, 10.4886218],
                [106.4131037, 10.4887635],
                [106.412958, 10.4888814],
                [106.4127319, 10.489135],
                [106.4125272, 10.4894912],
                [106.4124558, 10.4897597],
                [106.4124255, 10.4900003],
                [106.4123859, 10.4900831],
                [106.4123068, 10.4901496],
                [106.4111137, 10.4906159],
                [106.4110073, 10.4904014],
                [106.4109869, 10.4902509],
                [106.4111196, 10.4900627],
                [106.4111196, 10.4899397],
                [106.4110685, 10.4897942],
                [106.4109052, 10.4896963],
                [106.4105863, 10.4895985],
                [106.4103158, 10.4895232],
                [106.4100734, 10.4895408],
                [106.4099228, 10.489611],
                [106.4098463, 10.489729],
                [106.409836, 10.4898569],
                [106.4097569, 10.4904641],
                [106.409785, 10.4907953],
                [106.4097646, 10.4908631],
                [106.4096931, 10.4909108],
                [106.4093512, 10.490971],
                [106.4089595, 10.4911947],
                [106.4084593, 10.4913804],
                [106.4082935, 10.4917141],
                [106.4081378, 10.4919274],
                [106.4079796, 10.4920278],
                [106.4078801, 10.4920353],
                [106.4077627, 10.4919374],
                [106.4076096, 10.4917217],
                [106.4072855, 10.4915034],
                [106.4069742, 10.4912349],
                [106.4066603, 10.4911747],
                [106.4063516, 10.4911446],
                [106.4061704, 10.4911947],
                [106.406053, 10.4912926],
                [106.4060071, 10.4914482],
                [106.4060122, 10.4916188],
                [106.4060632, 10.4918571],
                [106.4060556, 10.4920002],
                [106.4059739, 10.4921357],
                [106.4058514, 10.4922034],
                [106.4057213, 10.4922034],
                [106.4056243, 10.4921557],
                [106.4053073, 10.4918348],
                [106.4051236, 10.4916917],
                [106.404945, 10.4916215],
                [106.4047358, 10.4915964],
                [106.4043224, 10.4916139],
                [106.404182, 10.4916566],
                [106.404057, 10.4917469],
                [106.4039983, 10.4918799],
                [106.4040289, 10.492048],
                [106.4040468, 10.4923592],
                [106.4040162, 10.4925574],
                [106.4039473, 10.4927154],
                [106.4037061, 10.4932139],
                [106.403266, 10.4921736],
                [106.4027002, 10.4907993],
                [106.4017819, 10.4884756],
                [106.4008818, 10.4862844],
                [106.4007706, 10.485979],
                [106.4009916, 10.4859051],
                [106.4008435, 10.4854557],
                [106.4005302, 10.4855633],
                [106.4003693, 10.4846645],
                [106.4000017, 10.4847398],
                [106.3996949, 10.4848835],
                [106.3992907, 10.4851656],
                [106.3985816, 10.4858629],
                [106.3983056, 10.4860811],
                [106.3979501, 10.4862674],
                [106.3975622, 10.4863526],
                [106.3972085, 10.486326],
                [106.3968657, 10.4862018],
                [106.3964658, 10.4858959],
                [106.3963171, 10.4855707],
                [106.3960796, 10.484961],
                [106.3960584, 10.4846835],
                [106.3961252, 10.4845731],
                [106.396, 10.4841115],
                [106.3958112, 10.4837254],
                [106.3955675, 10.4837317],
                [106.3954124, 10.4831994],
                [106.3955603, 10.4830965],
                [106.3948327, 10.4814243],
                [106.3942859, 10.48057],
                [106.3941124, 10.4796868],
                [106.3939337, 10.4787684],
                [106.3936837, 10.4782014],
                [106.393255, 10.4775841],
                [106.392862, 10.4772278],
                [106.3912692, 10.4778469],
                [106.3911405, 10.4778828],
                [106.3909989, 10.4778891],
                [106.3908616, 10.4778343],
                [106.3907693, 10.4777267],
                [106.3907435, 10.4776043],
                [106.3907757, 10.4773469],
                [106.3907757, 10.4772013],
                [106.3907264, 10.4770768],
                [106.3906292, 10.4770101],
                [106.3905221, 10.4770064],
                [106.3903128, 10.4770741],
                [106.3902044, 10.4770804],
                [106.3901176, 10.4770415],
                [106.3900691, 10.4769762],
                [106.389975, 10.4768237],
                [106.3898614, 10.4767225],
                [106.3896204, 10.4765986],
                [106.3895242, 10.4767781],
                [106.3893887, 10.4766831],
                [106.3892967, 10.4765234],
                [106.3891935, 10.4761765],
                [106.3891849, 10.475995],
                [106.3891742, 10.4758136],
                [106.3891184, 10.4757018],
                [106.3890218, 10.4756617],
                [106.3889103, 10.475668],
                [106.3887815, 10.4757165],
                [106.3886292, 10.4758199],
                [106.3884208, 10.4760693],
                [106.388247, 10.4763478],
                [106.388045, 10.4765231],
                [106.3877643, 10.4766634],
                [106.3870643, 10.4768847],
                [106.3859582, 10.4771011],
                [106.3857363, 10.4771757],
                [106.3855468, 10.4773158],
                [106.3853772, 10.4775075],
                [106.3849791, 10.4782727],
                [106.3847971, 10.4788277],
                [106.3845728, 10.4791214],
                [106.3842666, 10.4793297],
                [106.3837716, 10.4795254],
                [106.3830086, 10.479691],
                [106.3823779, 10.4797784],
                [106.3820956, 10.4798709],
                [106.3817072, 10.4801902],
                [106.3814675, 10.4803035],
                [106.3812186, 10.4803125],
                [106.3805419, 10.4801275],
                [106.3801869, 10.4800887],
                [106.3799107, 10.4801185],
                [106.379671, 10.4802051],
                [106.3794404, 10.4803931],
                [106.3792856, 10.4806497],
                [106.3792189, 10.4808735],
                [106.3792583, 10.4810137],
                [106.3793585, 10.4811271],
                [106.3798713, 10.4813897],
                [106.3800321, 10.4815001],
                [106.3801323, 10.4816195],
                [106.3801869, 10.4817776],
                [106.3801657, 10.4819238],
                [106.380017, 10.4821088],
                [106.3796771, 10.4823386],
                [106.3793706, 10.4825863],
                [106.3790793, 10.4829473],
                [106.378882, 10.4831323],
                [106.3785968, 10.4832905],
                [106.3777835, 10.4836724],
                [106.3770279, 10.4841349],
                [106.3767244, 10.4844691],
                [106.3764301, 10.4849704],
                [106.3761327, 10.485224],
                [106.3758748, 10.4853255],
                [106.3755076, 10.485424],
                [106.3751647, 10.485609],
                [106.3747641, 10.4860088],
                [106.3744758, 10.4861878],
                [106.374142, 10.4863161],
                [106.373529, 10.4863579],
                [106.3728807, 10.4865151],
                [106.3723193, 10.4867761],
                [106.3718396, 10.4870948],
                [106.3714007, 10.487436],
                [106.3710051, 10.487569],
                [106.3706071, 10.4878099],
                [106.3719567, 10.4898158],
                [106.3728173, 10.4922296],
                [106.3732951, 10.4933036],
                [106.3740411, 10.4944456],
                [106.3726507, 10.4953162],
                [106.3707015, 10.4965354],
                [106.3703081, 10.4967539],
                [106.369924, 10.4970497],
                [106.3695593, 10.4973603],
                [106.3693899, 10.4974877],
                [106.3691375, 10.4976347],
                [106.3686125, 10.49805],
                [106.3693436, 10.4990148],
                [106.3701379, 10.5002017],
                [106.3709621, 10.501391],
                [106.3711076, 10.5019931],
                [106.3712186, 10.5027658],
                [106.3712166, 10.5030223],
                [106.3712955, 10.5034251],
                [106.3712773, 10.5035101],
                [106.3713845, 10.50417],
                [106.371447, 10.5048487],
                [106.3714572, 10.5053304],
                [106.3714751, 10.5054709],
                [106.3715465, 10.505693],
                [106.3716678, 10.5059414],
                [106.371833, 10.5063668],
                [106.37023, 10.5078549],
                [106.3693963, 10.5085859],
                [106.3685397, 10.5093948],
                [106.3677568, 10.5100184],
                [106.3675352, 10.510099],
                [106.3673683, 10.5103198],
                [106.3668441, 10.5108421],
                [106.3668032, 10.5109297],
                [106.3667194, 10.5110658],
                [106.3665464, 10.511259],
                [106.3668877, 10.5119594],
                [106.3692184, 10.517386],
                [106.3694639, 10.5180099],
                [106.3689364, 10.5182843],
                [106.367653, 10.5189571],
                [106.3651836, 10.5208407],
                [106.3645154, 10.521355],
                [106.3639757, 10.5216883],
                [106.3637869, 10.5219056],
                [106.3631008, 10.5222194],
                [106.362169, 10.5225562],
                [106.3611423, 10.5230902],
                [106.360628, 10.5233527],
                [106.3599915, 10.5229731],
                [106.3596653, 10.5227158],
                [106.3595323, 10.5224753],
                [106.3594979, 10.5222179],
                [106.359528, 10.5219563],
                [106.3594421, 10.5216145],
                [106.3594015, 10.5214686],
                [106.3592883, 10.5213955],
                [106.3591631, 10.521403],
                [106.3587942, 10.5214373],
                [106.358719, 10.5215053],
                [106.3587189, 10.5216799],
                [106.3579033, 10.5220418],
                [106.3575461, 10.5222228],
                [106.357479, 10.5223954],
                [106.3573448, 10.5226138],
                [106.3572588, 10.522652],
                [106.3571618, 10.5226753],
                [106.3570559, 10.5226803],
                [106.3561984, 10.5226249],
                [106.3559969, 10.522622],
                [106.3558791, 10.5226486],
                [106.3557718, 10.5227129],
                [106.3553294, 10.5230702],
                [106.3549653, 10.5234593],
                [106.3546234, 10.5238757],
                [106.3535832, 10.5253381],
                [106.3533941, 10.52563],
                [106.3528161, 10.5259258],
                [106.352464, 10.5262219],
                [106.3523367, 10.5265385],
                [106.3517918, 10.5277484],
                [106.351264, 10.5284714],
                [106.3511576, 10.5286606],
                [106.3509236, 10.5287866],
                [106.3507323, 10.5288287],
                [106.3503495, 10.5287654],
                [106.3496055, 10.5285127],
                [106.3491377, 10.5284074],
                [106.3487761, 10.5284282],
                [106.3484146, 10.5284701],
                [106.3481169, 10.5285411],
                [106.3478886, 10.5286352],
                [106.3475808, 10.52896],
                [106.3477689, 10.5296309],
                [106.3478266, 10.5299928],
                [106.3478241, 10.5329837],
                [106.3479455, 10.5347558],
                [106.3479819, 10.5365399],
                [106.34815, 10.538129],
                [106.3482765, 10.5386265],
                [106.3485163, 10.5392863],
                [106.3489042, 10.5399134],
                [106.3490216, 10.5402797],
                [106.3490241, 10.5405431],
                [106.348945, 10.5407739],
                [106.348723, 10.5410022],
                [106.3483734, 10.5412004],
                [106.3480825, 10.5412732],
                [106.3476615, 10.5413284],
                [106.3474114, 10.5414488],
                [106.3472226, 10.5416194],
                [106.3470976, 10.5418477],
                [106.347095, 10.5420835],
                [106.3471472, 10.5422663],
                [106.3473348, 10.5424703],
                [106.3478315, 10.5427081],
                [106.3480956, 10.5429229],
                [106.3483436, 10.5432573],
                [106.3485518, 10.543637],
                [106.3485747, 10.5439917],
                [106.3484563, 10.54429],
                [106.3481407, 10.5447554],
                [106.3471987, 10.5439806],
                [106.3465914, 10.5435893],
                [106.3461273, 10.5433529],
                [106.3452215, 10.5435888],
                [106.344575, 10.5438796],
                [106.3439849, 10.5442487],
                [106.3434742, 10.5447023],
                [106.3430108, 10.545314],
                [106.3428606, 10.5454659],
                [106.3426696, 10.5455883],
                [106.3424228, 10.5456579],
                [106.342116, 10.5457718],
                [106.3419379, 10.5458878],
                [106.3418027, 10.5460334],
                [106.3414894, 10.5465186],
                [106.3413135, 10.5467063],
                [106.3411482, 10.5467696],
                [106.340998, 10.5467633],
                [106.34085, 10.5466747],
                [106.3407105, 10.5465059],
                [106.3404165, 10.5460439],
                [106.3400807, 10.5464142],
                [106.34002, 10.5465335],
                [106.3400094, 10.5466498],
                [106.3400601, 10.5467568],
                [106.3402297, 10.5468937],
                [106.3402862, 10.546971],
                [106.3402919, 10.547043],
                [106.3402493, 10.5471105],
                [106.340186, 10.5471616],
                [106.3401554, 10.5472243],
                [106.3401554, 10.5473171],
                [106.3402102, 10.5474187],
                [106.3402906, 10.5475379],
                [106.3403187, 10.547632],
                [106.3403136, 10.5477147],
                [106.3402638, 10.5478126],
                [106.3400838, 10.5480427],
                [106.3399647, 10.5482857],
                [106.3398709, 10.5483921],
                [106.3397446, 10.5484383],
                [106.3395966, 10.5484099],
                [106.3392953, 10.5484028],
                [106.3391491, 10.5483744],
                [106.3390246, 10.5483088],
                [106.338897, 10.548219],
                [106.3387607, 10.5482012],
                [106.338581, 10.5484764],
                [106.3385102, 10.5484311],
                [106.3384334, 10.5484506],
                [106.3382081, 10.5484727],
                [106.3381373, 10.5484348],
                [106.337721, 10.5490431],
                [106.336668, 10.550047],
                [106.3363888, 10.5501379],
                [106.3354526, 10.5510091],
                [106.3354936, 10.5513268],
                [106.3357212, 10.5516743],
                [106.3350658, 10.5525715],
                [106.3345427, 10.5533342],
                [106.3337631, 10.5545103],
                [106.3335808, 10.5547287],
                [106.3319713, 10.5567217],
                [106.3312188, 10.5572766],
                [106.3304116, 10.5582133],
                [106.3301567, 10.5587562],
                [106.3297925, 10.5591381],
                [106.3293859, 10.5595557],
                [106.328785, 10.5598898],
                [106.3279908, 10.5604585],
                [106.3271772, 10.5612847],
                [106.3269482, 10.5616432],
                [106.3266458, 10.562],
                [106.3262462, 10.5623599],
                [106.3259118, 10.5628393],
                [106.3257973, 10.5631004],
                [106.3253043, 10.5637071],
                [106.3247414, 10.5644896],
                [106.3243745, 10.5649263],
                [106.3241311, 10.5651454],
                [106.3238175, 10.5653582],
                [106.3232579, 10.5658899],
                [106.3231336, 10.5660313],
                [106.3230265, 10.5661856],
                [106.3229385, 10.5663499],
                [106.3225872, 10.567124],
                [106.3223112, 10.5675033],
                [106.3221557, 10.5680159],
                [106.3212487, 10.5691811],
                [106.320972, 10.5694107],
                [106.3184933, 10.570792],
                [106.317729, 10.570213],
                [106.3161755, 10.5692147],
                [106.3118386, 10.5662874],
                [106.3103558, 10.5655012],
                [106.3097697, 10.5663814],
                [106.3062917, 10.5689731],
                [106.3033159, 10.5712444],
                [106.3009416, 10.5730819],
                [106.3001937, 10.5736606],
                [106.2979987, 10.575545],
                [106.2962425, 10.5769946],
                [106.2956817, 10.5777345],
                [106.294365, 10.5792791],
                [106.2921215, 10.5816926],
                [106.2906579, 10.5829479],
                [106.2860755, 10.5870076],
                [106.2834761, 10.5867196],
                [106.2797292, 10.5863586],
                [106.2746661, 10.5859281],
                [106.2725887, 10.5857371],
                [106.2703559, 10.5855024],
                [106.268934, 10.5853458],
                [106.2659549, 10.5850754],
                [106.2618395, 10.5847452],
                [106.2588298, 10.5844443],
                [106.2568368, 10.5841595],
                [106.2520588, 10.5835861],
                [106.2482325, 10.5833942],
                [106.2421739, 10.5827918],
                [106.238277, 10.5824128],
                [106.2372719, 10.5822978],
                [106.2340635, 10.5820338],
                [106.2317364, 10.5817564],
                [106.229967, 10.5815939],
                [106.2286245, 10.5814654],
                [106.2234558, 10.5810401],
                [106.2186548, 10.5805792],
                [106.2153328, 10.5802085],
                [106.2083514, 10.5795939],
                [106.204842, 10.5792825],
                [106.2043427, 10.5793086],
                [106.2017794, 10.5797627],
                [106.1984239, 10.5803467],
                [106.1979416, 10.5803729],
                [106.197406, 10.5804021],
                [106.194229, 10.5802874],
                [106.1896424, 10.580054],
                [106.1857132, 10.5798447],
                [106.1845207, 10.579793],
                [106.1829945, 10.5797192],
                [106.1796619, 10.5795636],
                [106.1790472, 10.5795359],
                [106.1786314, 10.5795161],
                [106.1777919, 10.5794976],
                [106.1768706, 10.5794462],
                [106.1748187, 10.5793197],
                [106.1728754, 10.5792432],
                [106.1724302, 10.5792221],
                [106.1643352, 10.5788556],
                [106.1595824, 10.5785814],
                [106.1575332, 10.5784548],
                [106.1516538, 10.5781806],
                [106.1450654, 10.5778982],
                [106.1386422, 10.5776388],
                [106.1365149, 10.5774842],
                [106.1343417, 10.5773262],
                [106.1329525, 10.5772728],
                [106.1246445, 10.5768935],
                [106.1223329, 10.5768065],
                [106.1191334, 10.5766608],
                [106.1116534, 10.5763268],
                [106.1062914, 10.5760944],
                [106.1021145, 10.5758029],
                [106.1017689, 10.5757666],
                [106.1013853, 10.5757262],
                [106.1020734, 10.5422325],
                [106.1022449, 10.5324793],
                [106.1024645, 10.5243899],
                [106.1025676, 10.5228658],
                [106.1012586, 10.5229171],
                [106.0999494, 10.5229612],
                [106.09864, 10.5229981],
                [106.0974867, 10.5230246],
                [106.0963815, 10.523035],
                [106.0959095, 10.5230455],
                [106.09557, 10.5230295],
                [106.0951174, 10.5230123],
                [106.0946646, 10.5230044],
                [106.0942116, 10.523006],
                [106.0937597, 10.5230169],
                [106.0933082, 10.5230371],
                [106.0928571, 10.5230666],
                [106.0896895, 10.5233895],
                [106.0789472, 10.5245223],
                [106.0682881, 10.5256299],
                [106.0469315, 10.5277741],
                [106.0415679, 10.5283461],
                [106.0388535, 10.5286625],
                [106.0013622, 10.5323654],
                [105.9970553, 10.5327263],
                [105.9925407, 10.5330342],
                [105.9921365, 10.5330617],
                [105.9579894, 10.5353892],
                [105.9567135, 10.5354762],
                [105.9514458, 10.5358032],
                [105.9510581, 10.5358303],
                [105.9417001, 10.5364846],
                [105.9418148, 10.5228253],
                [105.9418474, 10.5210754],
                [105.9418556, 10.5193816],
                [105.9418883, 10.5158015],
                [105.9418474, 10.5133934],
                [105.941889, 10.5116309],
                [105.9418968, 10.5112833],
                [105.9418939, 10.5109216],
                [105.9418787, 10.5103515],
                [105.9418742, 10.5101598],
                [105.9418229, 10.5090265],
                [105.9418066, 10.5044348],
                [105.9417005, 10.5007422],
                [105.9415805, 10.4953284],
                [105.9415802, 10.4951055],
                [105.9415832, 10.4948827],
                [105.9415896, 10.4946591],
                [105.9415993, 10.4944356],
                [105.9377928, 10.4905125],
                [105.9335719, 10.4860305],
                [105.9293858, 10.481607],
                [105.9248684, 10.4768316],
                [105.9213468, 10.4732224],
                [105.9175341, 10.469152],
                [105.9137058, 10.4650682],
                [105.9096711, 10.4608666],
                [105.9056179, 10.4565734],
                [105.9012302, 10.4519322],
                [105.8801929, 10.4295778],
                [105.8798577, 10.4292269],
                [105.8733261, 10.42239],
                [105.8724394, 10.4214064],
                [105.8691245, 10.4177295],
                [105.864905, 10.4131974],
                [105.8595838, 10.4077206],
                [105.8594347, 10.4073361],
                [105.8540809, 10.3928867],
                [105.8527672, 10.3892103],
                [105.8520837, 10.3873564],
                [105.848819, 10.3783828],
                [105.8456267, 10.3695259],
                [105.8445167, 10.3662718],
                [105.8409936, 10.3675038],
                [105.8358011, 10.3694794],
                [105.8257018, 10.3700657],
                [105.8236444, 10.3701701],
                [105.8201011, 10.3703548],
                [105.8190234, 10.3704672],
                [105.8157659, 10.3704431],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.9530798, 9.9205762],
                [105.9559704, 9.9167468],
                [105.9580774, 9.9140018],
                [105.9605721, 9.9110068],
                [105.9624409, 9.9088932],
                [105.9647543, 9.9061833],
                [105.9675241, 9.9030844],
                [105.9707043, 9.8994647],
                [105.9733688, 9.8964818],
                [105.9757187, 9.8937125],
                [105.9775976, 9.891421],
                [105.9788659, 9.8894325],
                [105.9799275, 9.8876291],
                [105.9810273, 9.8856165],
                [105.9823223, 9.8829837],
                [105.9833061, 9.8804788],
                [105.9850001, 9.8782109],
                [105.9869245, 9.8758117],
                [105.9900358, 9.8721867],
                [105.9929371, 9.8690717],
                [105.9943875, 9.8674886],
                [105.9954408, 9.865805],
                [105.9961499, 9.8642571],
                [105.9967261, 9.8626568],
                [105.9972804, 9.8607215],
                [105.9978267, 9.8586173],
                [105.9985223, 9.8567709],
                [105.9986142, 9.8565269],
                [105.9988768, 9.8558641],
                [105.9988822, 9.8558544],
                [105.999561, 9.8546399],
                [106.0004052, 9.8531608],
                [106.0013983, 9.8518695],
                [106.0031607, 9.8497891],
                [106.0040945, 9.8484946],
                [106.004986, 9.8473272],
                [106.0067218, 9.8452521],
                [106.0090472, 9.8426087],
                [106.0124824, 9.8387921],
                [106.0146909, 9.8362866],
                [106.0160211, 9.8350084],
                [106.0172195, 9.8339048],
                [106.0182488, 9.8329517],
                [106.0215465, 9.8295173],
                [106.0250218, 9.825738],
                [106.0283537, 9.8225815],
                [106.03065, 9.8206666],
                [106.0311681, 9.8202245],
                [106.0317981, 9.8196869],
                [106.0358323, 9.8160363],
                [106.0409477, 9.8114947],
                [106.0460849, 9.8067979],
                [106.0487718, 9.8047706],
                [106.0499528, 9.8036578],
                [106.0511335, 9.8024117],
                [106.0539276, 9.7990087],
                [106.0567639, 9.794807],
                [106.0589788, 9.7920048],
                [106.0603153, 9.7905807],
                [106.0633633, 9.7863552],
                [106.0655882, 9.7830665],
                [106.0689268, 9.7785769],
                [106.0702641, 9.777331],
                [106.073573, 9.7744104],
                [106.0765204, 9.772639],
                [106.0785627, 9.7715489],
                [106.0803713, 9.7708107],
                [106.0818676, 9.7702733],
                [106.0843802, 9.7694884],
                [106.0871205, 9.7681375],
                [106.0911237, 9.7660513],
                [106.0928216, 9.764994],
                [106.0934917, 9.7645922],
                [106.0991632, 9.7606741],
                [106.1050514, 9.7564381],
                [106.1069473, 9.7551002],
                [106.1111659, 9.7514323],
                [106.1135783, 9.7491075],
                [106.1150484, 9.7473945],
                [106.1170075, 9.7448371],
                [106.1172968, 9.7444147],
                [106.1205583, 9.740145],
                [106.1235669, 9.736431],
                [106.1275072, 9.7320032],
                [106.1342754, 9.725062],
                [106.1349787, 9.7245482],
                [106.1356766, 9.7240274],
                [106.1363692, 9.7234997],
                [106.1370564, 9.7229651],
                [106.137738, 9.7224237],
                [106.138414, 9.7218756],
                [106.1390844, 9.7213207],
                [106.1397491, 9.7207592],
                [106.140408, 9.7201911],
                [106.141061, 9.7196164],
                [106.1417081, 9.7190353],
                [106.1423492, 9.7184478],
                [106.1429677, 9.7178694],
                [106.1435805, 9.7172851],
                [106.1441875, 9.7166948],
                [106.1447885, 9.7160987],
                [106.1453835, 9.7154968],
                [106.1459724, 9.7148891],
                [106.1465553, 9.7142758],
                [106.147132, 9.7136568],
                [106.1477026, 9.7130322],
                [106.1482668, 9.7124022],
                [106.1488247, 9.7117667],
                [106.1493763, 9.7111258],
                [106.1503506, 9.7098882],
                [106.1513322, 9.7086562],
                [106.1523209, 9.7074298],
                [106.1533167, 9.7062089],
                [106.1543197, 9.7049938],
                [106.1553297, 9.7037843],
                [106.1563468, 9.7025805],
                [106.1573708, 9.7013826],
                [106.1584018, 9.7001904],
                [106.1594398, 9.6990041],
                [106.1604846, 9.6978237],
                [106.1615364, 9.6966493],
                [106.1625949, 9.6954808],
                [106.1636603, 9.6943183],
                [106.1647324, 9.6931619],
                [106.1658112, 9.6920116],
                [106.1668967, 9.6908674],
                [106.1680072, 9.6897104],
                [106.1691245, 9.6885599],
                [106.1702485, 9.6874158],
                [106.1713794, 9.6862782],
                [106.1725169, 9.6851471],
                [106.1736612, 9.6840226],
                [106.1748121, 9.6829047],
                [106.1759696, 9.6817934],
                [106.1771337, 9.6806889],
                [106.1783043, 9.679591],
                [106.1794814, 9.6784999],
                [106.1806649, 9.6774156],
                [106.1818548, 9.6763381],
                [106.1830511, 9.6752675],
                [106.1842538, 9.6742039],
                [106.1854627, 9.6731577],
                [106.1866783, 9.6721183],
                [106.1878875, 9.671082],
                [106.18909, 9.6700383],
                [106.190286, 9.6689873],
                [106.1914753, 9.6679289],
                [106.1926579, 9.6668633],
                [106.1938337, 9.6657904],
                [106.1950028, 9.6647104],
                [106.196165, 9.6636232],
                [106.197262, 9.6625845],
                [106.1983527, 9.6615394],
                [106.1994372, 9.660488],
                [106.2005153, 9.6594303],
                [106.2015871, 9.6583663],
                [106.2026525, 9.6572962],
                [106.2037115, 9.6562198],
                [106.204764, 9.6551373],
                [106.20581, 9.6540486],
                [106.2068495, 9.6529645],
                [106.207936, 9.6518744],
                [106.2106641, 9.6493667],
                [106.2134525, 9.6468873],
                [106.2162474, 9.6444153],
                [106.219049, 9.6419505],
                [106.2218572, 9.6394929],
                [106.2246719, 9.6370427],
                [106.2274932, 9.6345997],
                [106.2299307, 9.6324995],
                [106.2323731, 9.6304047],
                [106.2348203, 9.6283154],
                [106.2372723, 9.6262316],
                [106.2397291, 9.6241532],
                [106.2421907, 9.6220804],
                [106.2446571, 9.6200131],
                [106.2453312, 9.6194595],
                [106.2458054, 9.6190795],
                [106.246262, 9.6186932],
                [106.2467011, 9.6183431],
                [106.2471811, 9.617933],
                [106.2477415, 9.6174764],
                [106.2483073, 9.6170262],
                [106.2491053, 9.6164362],
                [106.2499082, 9.6158527],
                [106.2507159, 9.6152758],
                [106.2515284, 9.6147054],
                [106.2523457, 9.6141416],
                [106.2531676, 9.6135845],
                [106.254069, 9.6129848],
                [106.2549758, 9.6123931],
                [106.2558879, 9.6118094],
                [106.2568054, 9.6112339],
                [106.257728, 9.6106664],
                [106.2586558, 9.6101072],
                [106.2595886, 9.6095562],
                [106.2605266, 9.6089931],
                [106.2614682, 9.608436],
                [106.2624134, 9.6078849],
                [106.2633623, 9.6073399],
                [106.2643146, 9.6068008],
                [106.2653056, 9.6062485],
                [106.2663004, 9.6057026],
                [106.2672988, 9.6051633],
                [106.2683008, 9.6046307],
                [106.2693064, 9.6041046],
                [106.2722461, 9.6025195],
                [106.2734986, 9.6018525],
                [106.2747467, 9.6011775],
                [106.2759904, 9.6004946],
                [106.2772295, 9.5998037],
                [106.2784641, 9.5991049],
                [106.279694, 9.5983982],
                [106.2809193, 9.5976837],
                [106.2821399, 9.5969614],
                [106.2833556, 9.5962312],
                [106.2845666, 9.5954933],
                [106.2857727, 9.5947477],
                [106.2869738, 9.5939944],
                [106.2881778, 9.5932284],
                [106.2893768, 9.5924547],
                [106.2905705, 9.5916733],
                [106.2917591, 9.5908842],
                [106.2929425, 9.5900875],
                [106.2941205, 9.5892832],
                [106.2952932, 9.5884713],
                [106.2964605, 9.5876519],
                [106.2976223, 9.586825],
                [106.2987787, 9.5859907],
                [106.2999295, 9.5851489],
                [106.3010747, 9.5842997],
                [106.3022143, 9.5834432],
                [106.3031056, 9.5826792],
                [106.3039906, 9.5819082],
                [106.3048694, 9.5811302],
                [106.3057419, 9.5803454],
                [106.3066079, 9.5795537],
                [106.3074675, 9.5787552],
                [106.3083207, 9.5779499],
                [106.3091673, 9.577138],
                [106.3100073, 9.5763194],
                [106.3108406, 9.5754943],
                [106.3116672, 9.5746626],
                [106.3124871, 9.5738244],
                [106.3133001, 9.5729798],
                [106.3141063, 9.5721288],
                [106.3149056, 9.5712715],
                [106.3156979, 9.5704079],
                [106.3164832, 9.5695382],
                [106.3172614, 9.5686622],
                [106.3180325, 9.5677802],
                [106.3187964, 9.5668921],
                [106.3195532, 9.565998],
                [106.3203026, 9.565098],
                [106.3210448, 9.5641921],
                [106.3217796, 9.5632804],
                [106.322507, 9.562363],
                [106.3232269, 9.5614398],
                [106.3239393, 9.560511],
                [106.3246442, 9.5595766],
                [106.3253415, 9.5586367],
                [106.3260312, 9.5576913],
                [106.3267132, 9.5567406],
                [106.3273901, 9.5557808],
                [106.3280591, 9.5548156],
                [106.3287203, 9.5538453],
                [106.3293736, 9.5528697],
                [106.3300189, 9.5518889],
                [106.3306562, 9.5509032],
                [106.3312855, 9.5499124],
                [106.3319068, 9.5489166],
                [106.3325199, 9.547916],
                [106.3331248, 9.5469105],
                [106.3337216, 9.5459003],
                [106.3343101, 9.5448855],
                [106.3348904, 9.543866],
                [106.3354624, 9.5428419],
                [106.336026, 9.5418133],
                [106.3365813, 9.5407804],
                [106.3371281, 9.539743],
                [106.3376665, 9.5387014],
                [106.3381964, 9.5376556],
                [106.3387178, 9.5366055],
                [106.3392306, 9.5355514],
                [106.3397349, 9.5344933],
                [106.3402305, 9.5334312],
                [106.3407175, 9.5323653],
                [106.3411958, 9.5312955],
                [106.3416654, 9.530222],
                [106.3421263, 9.5291447],
                [106.3425784, 9.5280639],
                [106.3430217, 9.5269796],
                [106.3434562, 9.5258917],
                [106.3438818, 9.5248005],
                [106.3442985, 9.5237059],
                [106.3447064, 9.5226081],
                [106.3451053, 9.521507],
                [106.3454952, 9.5204029],
                [106.3458761, 9.5192957],
                [106.3462481, 9.5181855],
                [106.346611, 9.5170724],
                [106.3469648, 9.5159565],
                [106.3472667, 9.5148061],
                [106.4538443, 9.0800586],
                [106.8722725, 9.4272857],
                [106.6064252, 9.7829138],
                [106.6048407, 9.7850859],
                [106.6041706, 9.7859796],
                [106.6035074, 9.7868782],
                [106.6028511, 9.7877818],
                [106.6022017, 9.7886902],
                [106.6015594, 9.7896034],
                [106.600924, 9.7905214],
                [106.6002957, 9.7914441],
                [106.5996745, 9.7923714],
                [106.5990604, 9.7933033],
                [106.5984534, 9.7942398],
                [106.5978059, 9.7952565],
                [106.5971668, 9.7962783],
                [106.5965362, 9.7973052],
                [106.5959141, 9.7983372],
                [106.5953005, 9.7993741],
                [106.5946956, 9.8004159],
                [106.5940993, 9.8014626],
                [106.5934585, 9.802509],
                [106.5928098, 9.8035506],
                [106.5921532, 9.8045874],
                [106.5914887, 9.8056193],
                [106.5908163, 9.8066463],
                [106.5901362, 9.8076683],
                [106.5894483, 9.8086852],
                [106.5887526, 9.809697],
                [106.5880493, 9.8107036],
                [106.5873384, 9.811705],
                [106.5866282, 9.8126896],
                [106.5859107, 9.813669],
                [106.5851858, 9.8146431],
                [106.5844536, 9.8156119],
                [106.5837141, 9.8165753],
                [106.5829675, 9.8175333],
                [106.5822136, 9.8184858],
                [106.5814526, 9.8194327],
                [106.5806844, 9.8203741],
                [106.5797805, 9.8215001],
                [106.5787739, 9.8227515],
                [106.5776311, 9.8241874],
                [106.5764807, 9.8256175],
                [106.5753286, 9.8270345],
                [106.5741691, 9.8284457],
                [106.5730023, 9.829851],
                [106.5718282, 9.8312504],
                [106.5706467, 9.8326437],
                [106.569458, 9.8340311],
                [106.5682621, 9.8354124],
                [106.5670589, 9.8367876],
                [106.5658486, 9.8381566],
                [106.5646312, 9.8395195],
                [106.5634066, 9.8408763],
                [106.562175, 9.8422267],
                [106.5609363, 9.843571],
                [106.5596907, 9.8449089],
                [106.558438, 9.8462404],
                [106.5571785, 9.8475656],
                [106.555912, 9.8488844],
                [106.5546386, 9.8501967],
                [106.5533584, 9.8515026],
                [106.5520714, 9.8528019],
                [106.5507776, 9.8540947],
                [106.5494771, 9.8553809],
                [106.5487862, 9.8560125],
                [106.5480889, 9.8566372],
                [106.5473852, 9.857255],
                [106.5466753, 9.8578658],
                [106.5459593, 9.8584695],
                [106.5452371, 9.8590662],
                [106.5445089, 9.8596557],
                [106.5438277, 9.8601963],
                [106.5431415, 9.8607307],
                [106.5424503, 9.8612588],
                [106.5417541, 9.8617804],
                [106.541053, 9.8622957],
                [106.5402184, 9.862868],
                [106.5395907, 9.8632983],
                [106.5388398, 9.8638],
                [106.5380945, 9.8643098],
                [106.5373548, 9.8648275],
                [106.5366209, 9.8653532],
                [106.5358928, 9.8658866],
                [106.5351707, 9.8664278],
                [106.5344546, 9.8669768],
                [106.5337446, 9.8675333],
                [106.5330407, 9.8680974],
                [106.5323432, 9.8686691],
                [106.531652, 9.8692481],
                [106.5309672, 9.8698346],
                [106.5302889, 9.8704283],
                [106.5296172, 9.8710293],
                [106.5289682, 9.8716226],
                [106.5283256, 9.8722227],
                [106.5276896, 9.8728295],
                [106.52706, 9.8734428],
                [106.5264372, 9.8740628],
                [106.525821, 9.8746892],
                [106.5252116, 9.875322],
                [106.5246091, 9.8759611],
                [106.5240135, 9.8766065],
                [106.5234248, 9.8772581],
                [106.5228433, 9.8779158],
                [106.5222688, 9.8785795],
                [106.5217015, 9.8792492],
                [106.5211415, 9.8799248],
                [106.5205887, 9.8806062],
                [106.5200434, 9.8812933],
                [106.5195054, 9.8819861],
                [106.518975, 9.8826845],
                [106.5184521, 9.8833884],
                [106.5179368, 9.8840977],
                [106.5171824, 9.8850872],
                [106.5164354, 9.8860812],
                [106.515435, 9.887345],
                [106.514428, 9.8886036],
                [106.5134143, 9.889857],
                [106.512394, 9.8911052],
                [106.5113671, 9.8923482],
                [106.5107073, 9.8931253],
                [106.5092938, 9.8948182],
                [106.5082474, 9.8960453],
                [106.5071945, 9.8972669],
                [106.5061353, 9.8984832],
                [106.5050696, 9.899694],
                [106.5039858, 9.9009123],
                [106.5028956, 9.9021251],
                [106.501799, 9.9033321],
                [106.5006959, 9.9045335],
                [106.4995864, 9.9057291],
                [106.4984706, 9.906919],
                [106.4973485, 9.9081031],
                [106.49622, 9.9092813],
                [106.4950853, 9.9104537],
                [106.4939443, 9.9116202],
                [106.4927972, 9.9127808],
                [106.4920192, 9.9135158],
                [106.4912344, 9.9142437],
                [106.4904428, 9.9149645],
                [106.4896446, 9.9156781],
                [106.4888397, 9.9163845],
                [106.4880284, 9.9170835],
                [106.4872105, 9.9177752],
                [106.4863863, 9.9184595],
                [106.4855557, 9.9191364],
                [106.4847189, 9.9198057],
                [106.4838758, 9.9204674],
                [106.4830267, 9.9211215],
                [106.4821715, 9.9217678],
                [106.4813103, 9.9224064],
                [106.4804432, 9.9230373],
                [106.4795702, 9.9236602],
                [106.4786915, 9.9242753],
                [106.4778072, 9.9248824],
                [106.4769172, 9.9254815],
                [106.476048, 9.9260553],
                [106.4751736, 9.9266215],
                [106.4742941, 9.92718],
                [106.4734097, 9.9277307],
                [106.4725203, 9.9282737],
                [106.471626, 9.9288089],
                [106.4707269, 9.9293362],
                [106.4698231, 9.9298556],
                [106.4689147, 9.9303671],
                [106.4680016, 9.9308705],
                [106.4670841, 9.931366],
                [106.4661621, 9.9318534],
                [106.4652357, 9.9323327],
                [106.4643051, 9.9328038],
                [106.4633702, 9.9332668],
                [106.4624312, 9.9337216],
                [106.4614881, 9.9341681],
                [106.460541, 9.9346063],
                [106.45959, 9.9350363],
                [106.4584528, 9.935479],
                [106.4574935, 9.9358275],
                [106.4563547, 9.9362054],
                [106.4552187, 9.9365916],
                [106.4540856, 9.9369859],
                [106.4529555, 9.9373884],
                [106.4518285, 9.9377991],
                [106.4507045, 9.938218],
                [106.4495837, 9.9386449],
                [106.448466, 9.93908],
                [106.4473517, 9.9395231],
                [106.4462407, 9.9399742],
                [106.4451331, 9.9404334],
                [106.4440289, 9.9409006],
                [106.4429282, 9.9413757],
                [106.4425347, 9.941549],
                [106.4418496, 9.9418506],
                [106.4407744, 9.9423332],
                [106.4397029, 9.9428233],
                [106.4386349, 9.9433211],
                [106.4375706, 9.9438265],
                [106.4365101, 9.9443394],
                [106.4354533, 9.9448599],
                [106.4344004, 9.9453878],
                [106.4333514, 9.9459232],
                [106.4323063, 9.9464661],
                [106.4312652, 9.9470163],
                [106.4302282, 9.947574],
                [106.4291953, 9.948139],
                [106.4279169, 9.9488175],
                [106.4266425, 9.9495033],
                [106.4253723, 9.9501965],
                [106.4241062, 9.950897],
                [106.4228443, 9.9516048],
                [106.4215866, 9.9523199],
                [106.4203332, 9.9530422],
                [106.4190842, 9.9537718],
                [106.4178395, 9.9545086],
                [106.4165992, 9.9552526],
                [106.4153634, 9.9560037],
                [106.4141321, 9.9567619],
                [106.4128633, 9.9575537],
                [106.4115995, 9.9583529],
                [106.4103406, 9.9591598],
                [106.4090867, 9.9599741],
                [106.4078379, 9.9607959],
                [106.4065941, 9.9616252],
                [106.4053555, 9.9624618],
                [106.404122, 9.9633059],
                [106.4028938, 9.9641573],
                [106.4016708, 9.9650161],
                [106.4004532, 9.9658821],
                [106.3994024, 9.9666312],
                [106.3983459, 9.9673725],
                [106.3972836, 9.9681057],
                [106.3962156, 9.9688309],
                [106.3951421, 9.969548],
                [106.3942326, 9.9701464],
                [106.3933192, 9.970739],
                [106.392402, 9.9713257],
                [106.3914809, 9.9719067],
                [106.390556, 9.9724817],
                [106.3896275, 9.9730509],
                [106.3813347, 9.9783242],
                [106.3797423, 9.9793368],
                [106.3789441, 9.9798354],
                [106.3781509, 9.9803418],
                [106.3773629, 9.9808559],
                [106.3765801, 9.9813777],
                [106.3758025, 9.9819072],
                [106.3750304, 9.9824442],
                [106.3742637, 9.9829888],
                [106.3735147, 9.9835319],
                [106.3727711, 9.9840823],
                [106.372033, 9.9846398],
                [106.3713004, 9.9852043],
                [106.3705735, 9.9857759],
                [106.3698523, 9.9863545],
                [106.3691369, 9.98694],
                [106.3684273, 9.9875323],
                [106.3555449, 9.9989046],
                [106.3552196, 9.9991769],
                [106.3548893, 9.9994432],
                [106.3545539, 9.9997034],
                [106.3542136, 9.9999573],
                [106.3538686, 10.0002049],
                [106.3535189, 10.0004461],
                [106.3531647, 10.0006808],
                [106.3528061, 10.0009089],
                [106.3524464, 10.0011284],
                [106.3520826, 10.0013413],
                [106.3517148, 10.0015475],
                [106.3513433, 10.001747],
                [106.350968, 10.0019396],
                [106.3505892, 10.0021254],
                [106.3502069, 10.0023041],
                [106.3498213, 10.0024759],
                [106.3369595, 10.0077667],
                [106.3298464, 10.0106927],
                [106.3292241, 10.010963],
                [106.3286054, 10.0112409],
                [106.3279902, 10.0115265],
                [106.3273788, 10.0118197],
                [106.3267711, 10.0121204],
                [106.3261673, 10.0124285],
                [106.3255674, 10.0127441],
                [106.3249716, 10.0130671],
                [106.32438, 10.0133974],
                [106.3237811, 10.0137418],
                [106.3231867, 10.0140936],
                [106.3225969, 10.0144529],
                [106.3220118, 10.0148197],
                [106.3214315, 10.0151937],
                [106.3208561, 10.0155751],
                [106.3202858, 10.0159636],
                [106.3197205, 10.0163593],
                [106.3148584, 10.0201998],
                [106.3072644, 10.0270284],
                [106.2976448, 10.0379891],
                [106.2966881, 10.0391085],
                [106.2951202, 10.0412249],
                [106.2932395, 10.0439132],
                [106.2923772, 10.0450889],
                [106.2923667, 10.0451049],
                [106.2919189, 10.0458734],
                [106.2896374, 10.049789],
                [106.2768799, 10.0594361],
                [106.2762001, 10.059951],
                [106.2755252, 10.0604722],
                [106.2748553, 10.0609996],
                [106.2741904, 10.061533],
                [106.2736331, 10.0619881],
                [106.2730795, 10.0624475],
                [106.2725296, 10.0629112],
                [106.2719834, 10.0633791],
                [106.2660675, 10.0687379],
                [106.2654456, 10.0693147],
                [106.2648207, 10.0698882],
                [106.2641926, 10.0704584],
                [106.2632585, 10.0712954],
                [106.2623178, 10.0721252],
                [106.2620278, 10.072367],
                [106.2617335, 10.0726035],
                [106.2614349, 10.0728348],
                [106.2611321, 10.0730607],
                [106.2608251, 10.0732812],
                [106.2604543, 10.0735365],
                [106.260078, 10.073784],
                [106.2596963, 10.0740234],
                [106.2593095, 10.0742546],
                [106.2589177, 10.0744775],
                [106.2585211, 10.0746921],
                [106.2580916, 10.0749117],
                [106.2576578, 10.075123],
                [106.2572199, 10.0753259],
                [106.256778, 10.0755203],
                [106.2563324, 10.0757062],
                [106.2559325, 10.0758645],
                [106.2555299, 10.0760158],
                [106.2551246, 10.0761602],
                [106.2547169, 10.0762977],
                [106.2543068, 10.0764281],
                [106.2538944, 10.0765515],
                [106.2495947, 10.0777449],
                [106.2454748, 10.0790442],
                [106.2449263, 10.079229],
                [106.2443806, 10.0794217],
                [106.2438378, 10.0796222],
                [106.243298, 10.0798305],
                [106.2427615, 10.0800466],
                [106.2422281, 10.0802704],
                [106.2416982, 10.0805019],
                [106.2411975, 10.0807291],
                [106.2407, 10.0809631],
                [106.2402059, 10.081204],
                [106.2397153, 10.0814515],
                [106.2392282, 10.0817058],
                [106.2387447, 10.0819668],
                [106.238265, 10.0822343],
                [106.2343436, 10.0779139],
                [106.234113, 10.0777026],
                [106.2340043, 10.0777066],
                [106.2338313, 10.0775323],
                [106.2337361, 10.0775336],
                [106.2336329, 10.0776274],
                [106.233201, 10.0771864],
                [106.233201, 10.0770467],
                [106.2331172, 10.0768015],
                [106.2328953, 10.0762806],
                [106.2325365, 10.0756983],
                [106.232155, 10.0751437],
                [106.2320477, 10.0750486],
                [106.2321255, 10.0749298],
                [106.2322488, 10.0748083],
                [106.2323293, 10.0747053],
                [106.2324339, 10.0745178],
                [106.232501, 10.0743593],
                [106.2325613, 10.0741745],
                [106.2325627, 10.0740926],
                [106.2325519, 10.0739936],
                [106.2325627, 10.0739513],
                [106.2325962, 10.0739117],
                [106.2326485, 10.0738813],
                [106.2327343, 10.0738523],
                [106.2327799, 10.07381],
                [106.2328067, 10.073744],
                [106.2319508, 10.0733169],
                [106.2318539, 10.0733631],
                [106.2317707, 10.0734172],
                [106.2315729, 10.0735869],
                [106.2299877, 10.0728831],
                [106.2297302, 10.0727775],
                [106.2296766, 10.0727352],
                [106.2295103, 10.0723945],
                [106.2289859, 10.0716036],
                [106.2289014, 10.0714095],
                [106.2286305, 10.0712854],
                [106.2285072, 10.0705433],
                [106.2278044, 10.0704825],
                [106.2277696, 10.0699438],
                [106.2276944, 10.068861],
                [106.2276435, 10.068185],
                [106.227563, 10.0676568],
                [106.2282389, 10.0669649],
                [106.2282577, 10.0668672],
                [106.2284609, 10.0667113],
                [106.2284917, 10.066747],
                [106.2285259, 10.0667642],
                [106.2285689, 10.0667721],
                [106.2286108, 10.0667684],
                [106.2286761, 10.0667457],
                [106.2291589, 10.0664816],
                [106.2284481, 10.0652271],
                [106.2283945, 10.0650951],
                [106.2281907, 10.064044],
                [106.2281397, 10.0638961],
                [106.2280552, 10.0637786],
                [106.2276808, 10.0635455],
                [106.22769, 10.0628496],
                [106.2276396, 10.0620783],
                [106.2274575, 10.0611267],
                [106.2273726, 10.0606088],
                [106.2273969, 10.0600793],
                [106.2275838, 10.0597045],
                [106.2278309, 10.0594134],
                [106.2281349, 10.0592265],
                [106.2284688, 10.0591215],
                [106.2289525, 10.058987],
                [106.2283704, 10.0586063],
                [106.2278259, 10.0583184],
                [106.2271392, 10.0580015],
                [106.2250713, 10.0571062],
                [106.2251477, 10.0569768],
                [106.2252577, 10.0568685],
                [106.2252912, 10.056821],
                [106.2253033, 10.0567761],
                [106.2252899, 10.0567325],
                [106.2252483, 10.0566889],
                [106.2251866, 10.0566585],
                [106.2251155, 10.056644],
                [106.2250525, 10.0566189],
                [106.225023, 10.0565859],
                [106.2250069, 10.0565397],
                [106.2250082, 10.0564961],
                [106.225023, 10.0564605],
                [106.2250471, 10.056438],
                [106.2250444, 10.0564076],
                [106.225023, 10.0563839],
                [106.2249774, 10.0563693],
                [106.2249291, 10.0563403],
                [106.224913, 10.0562954],
                [106.224913, 10.0562597],
                [106.2249278, 10.0562241],
                [106.2249613, 10.056199],
                [106.2249801, 10.0561713],
                [106.2249794, 10.0561369],
                [106.2249586, 10.0561026],
                [106.2248111, 10.0560445],
                [106.2246501, 10.0559917],
                [106.2245992, 10.0559336],
                [106.2245938, 10.0558623],
                [106.2246796, 10.0556748],
                [106.2247011, 10.0555718],
                [106.224677, 10.055474],
                [106.2245724, 10.0553288],
                [106.2248577, 10.0550376],
                [106.2249559, 10.0548778],
                [106.225021, 10.0547165],
                [106.2255889, 10.0527723],
                [106.2267101, 10.0483248],
                [106.2268066, 10.0477411],
                [106.226321, 10.0479114],
                [106.2224816, 10.0435853],
                [106.2270711, 10.0425615],
                [106.2310343, 10.042117],
                [106.2310131, 10.0417632],
                [106.231067, 10.0402282],
                [106.2311636, 10.0388865],
                [106.2312816, 10.0378195],
                [106.2314225, 10.0368922],
                [106.2314479, 10.0334692],
                [106.2314378, 10.0308331],
                [106.2314354, 10.0302106],
                [106.2315791, 10.0284595],
                [106.2316428, 10.0276838],
                [106.2319215, 10.0262333],
                [106.2320639, 10.0257159],
                [106.2321877, 10.0252446],
                [106.2323371, 10.0244444],
                [106.2323558, 10.0242007],
                [106.2323511, 10.0239799],
                [106.2323043, 10.0237406],
                [106.2322006, 10.0233242],
                [106.231807, 10.0227449],
                [106.2309996, 10.0218296],
                [106.2309183, 10.0217375],
                [106.2297415, 10.0206045],
                [106.2289556, 10.019923],
                [106.2281402, 10.0194581],
                [106.2261534, 10.0188184],
                [106.2254607, 10.0185954],
                [106.2247325, 10.018276],
                [106.2239357, 10.0177956],
                [106.2231023, 10.0170389],
                [106.2225555, 10.0163595],
                [106.2221719, 10.0156571],
                [106.2220357, 10.0154128],
                [106.2213548, 10.014494],
                [106.2206644, 10.0145517],
                [106.2145892, 10.0146177],
                [106.2135029, 10.0126948],
                [106.2126338, 10.011596],
                [106.2114456, 10.009541],
                [106.2106222, 10.0086218],
                [106.2102923, 10.0086482],
                [106.2098899, 10.008738],
                [106.2095895, 10.0088173],
                [106.2093669, 10.0088939],
                [106.2092784, 10.0088965],
                [106.2092167, 10.0088727],
                [106.2091711, 10.0088278],
                [106.2091175, 10.0087248],
                [106.2090611, 10.0086588],
                [106.2089914, 10.0086482],
                [106.2088948, 10.0086641],
                [106.2088265, 10.0086951],
                [106.2087889, 10.0087242],
                [106.2087648, 10.0087677],
                [106.2087527, 10.0088226],
                [106.2087527, 10.0088992],
                [106.2087446, 10.0089995],
                [106.2087017, 10.0090444],
                [106.2086293, 10.0090682],
                [106.208573, 10.0090392],
                [106.2085462, 10.0089784],
                [106.208522, 10.0088992],
                [106.2084791, 10.0088648],
                [106.2084255, 10.008845],
                [106.2083611, 10.0088648],
                [106.2082779, 10.0089599],
                [106.2081706, 10.0091237],
                [106.207602, 10.0096572],
                [106.2080015, 10.0099945],
                [106.2082479, 10.0102461],
                [106.208393, 10.0104971],
                [106.2084785, 10.010857],
                [106.2084792, 10.0110086],
                [106.2084533, 10.0111794],
                [106.2082334, 10.0114806],
                [106.2077238, 10.0117394],
                [106.2072088, 10.0117764],
                [106.2067152, 10.01175],
                [106.2063236, 10.0115281],
                [106.2056048, 10.0108044],
                [106.2050678, 10.0100233],
                [106.2048368, 10.0096579],
                [106.2045682, 10.0095199],
                [106.2043767, 10.0095079],
                [106.2040836, 10.0096353],
                [106.2037016, 10.0098641],
                [106.2033709, 10.0101685],
                [106.2025447, 10.0110681],
                [106.2017051, 10.0119234],
                [106.2008627, 10.0128878],
                [106.2001892, 10.0134852],
                [106.1996255, 10.0140309],
                [106.1989585, 10.0145672],
                [106.1983883, 10.0153104],
                [106.1982068, 10.0161477],
                [106.1980614, 10.0170334],
                [106.1975572, 10.018208],
                [106.1970795, 10.0190641],
                [106.1962626, 10.0206541],
                [106.1958184, 10.0218347],
                [106.1955796, 10.0227896],
                [106.1954793, 10.0235799],
                [106.1953121, 10.0241632],
                [106.1951162, 10.0245489],
                [106.1948105, 10.0247982],
                [106.1945239, 10.024944],
                [106.1940462, 10.0250428],
                [106.1936074, 10.0250839],
                [106.1929893, 10.025101],
                [106.1921923, 10.0251441],
                [106.1919286, 10.0251887],
                [106.1916199, 10.0253092],
                [106.1913521, 10.0255085],
                [106.1910416, 10.0258612],
                [106.1906595, 10.0261247],
                [106.1901961, 10.0262517],
                [106.1898235, 10.0261905],
                [106.1894271, 10.0260823],
                [106.1887344, 10.0258001],
                [106.1879988, 10.0255273],
                [106.1876262, 10.0254097],
                [106.1868476, 10.0252262],
                [106.1859735, 10.0251086],
                [106.1850754, 10.0249487],
                [106.1839003, 10.0246618],
                [106.1825819, 10.0243466],
                [106.1811298, 10.024149],
                [106.1795487, 10.0238245],
                [106.1784261, 10.0233964],
                [106.1778099, 10.0233118],
                [106.1766635, 10.0230107],
                [106.1745904, 10.0226579],
                [106.1730045, 10.0223239],
                [106.1717768, 10.0220698],
                [106.170277, 10.0217218],
                [106.1692977, 10.0214867],
                [106.1681561, 10.0212797],
                [106.1671099, 10.021068],
                [106.1664603, 10.0209222],
                [106.1658011, 10.0208093],
                [106.1649031, 10.0205976],
                [106.1628729, 10.0201272],
                [106.1604081, 10.0196474],
                [106.1580293, 10.0191535],
                [106.1564243, 10.0188195],
                [106.1551871, 10.0185655],
                [106.1537493, 10.018288],
                [106.1520201, 10.0179305],
                [106.1509071, 10.0176341],
                [106.1504294, 10.0176388],
                [106.1488483, 10.018335],
                [106.1478213, 10.0186737],
                [106.1465316, 10.0191864],
                [106.1458676, 10.019464],
                [106.1451272, 10.0197744],
                [106.144339, 10.0199908],
                [106.1428439, 10.0207105],
                [106.1397772, 10.0219006],
                [106.1365959, 10.023213],
                [106.1346958, 10.0239237],
                [106.1348031, 10.0228734],
                [106.1348471, 10.0216123],
                [106.1347867, 10.0202252],
                [106.1347808, 10.0155353],
                [106.1346989, 10.013],
                [106.134667, 10.0102122],
                [106.1345708, 10.0067728],
                [106.1345576, 10.0049899],
                [106.1345351, 10.003466],
                [106.1345085, 10.0028715],
                [106.1344099, 10.0023353],
                [106.1340064, 10.0012301],
                [106.1336553, 10.0001307],
                [106.1334673, 9.9994188],
                [106.1332729, 9.9989064],
                [106.1329352, 9.9982193],
                [106.1328118, 9.9978426],
                [106.1326832, 9.9973968],
                [106.1324961, 9.9967951],
                [106.1320991, 9.9955156],
                [106.131835, 9.9946644],
                [106.1316526, 9.9940833],
                [106.1312342, 9.9929422],
                [106.1311698, 9.9924455],
                [106.1311054, 9.9921814],
                [106.1307406, 9.9915157],
                [106.1306333, 9.9912304],
                [106.1306119, 9.9909135],
                [106.1306548, 9.9906176],
                [106.1309123, 9.9897934],
                [106.1313736, 9.9879021],
                [106.1317384, 9.9868455],
                [106.1319852, 9.9860636],
                [106.1320925, 9.9854296],
                [106.1321247, 9.984933],
                [106.1320925, 9.9845103],
                [106.1319208, 9.984056],
                [106.1318993, 9.9838235],
                [106.1319959, 9.983665],
                [106.132468, 9.9831261],
                [106.1327684, 9.9827246],
                [106.1331332, 9.9821434],
                [106.1333424, 9.9817366],
                [106.1334014, 9.9815623],
                [106.1334175, 9.9814144],
                [106.1331975, 9.9784452],
                [106.13235, 9.9689352],
                [106.1321354, 9.9657545],
                [106.1318457, 9.9635566],
                [106.1317706, 9.9624259],
                [106.1317277, 9.9620878],
                [106.1316633, 9.9617866],
                [106.131556, 9.9615224],
                [106.1314487, 9.9613058],
                [106.1312932, 9.961076],
                [106.1305261, 9.9601011],
                [106.126453, 9.9544842],
                [106.1241531, 9.9513514],
                [106.1237776, 9.9508706],
                [106.1197328, 9.9463002],
                [106.1127003, 9.938488],
                [106.1120764, 9.9390695],
                [106.1107477, 9.940496],
                [106.1093469, 9.9419151],
                [106.1078037, 9.9434606],
                [106.1070261, 9.9442791],
                [106.1044062, 9.9470248],
                [106.1028991, 9.9484145],
                [106.1016124, 9.9494162],
                [106.0986448, 9.9513987],
                [106.0956033, 9.9534468],
                [106.0943247, 9.9543802],
                [106.0913165, 9.9565585],
                [106.0900703, 9.9573778],
                [106.0877817, 9.9588948],
                [106.0865559, 9.9596403],
                [106.0856547, 9.958413],
                [106.0846321, 9.9570208],
                [106.0807899, 9.9518534],
                [106.0801193, 9.9509578],
                [106.0792375, 9.9497194],
                [106.0792885, 9.9496672],
                [106.0791054, 9.9494229],
                [106.0788909, 9.949592],
                [106.0784322, 9.9489262],
                [106.0785931, 9.9487888],
                [106.0779816, 9.9477876],
                [106.07737, 9.9467387],
                [106.07678, 9.945682],
                [106.0763937, 9.9449528],
                [106.0757929, 9.9451272],
                [106.0751492, 9.9453887],
                [106.0743982, 9.945682],
                [106.0739368, 9.9458193],
                [106.0733145, 9.9459699],
                [106.072872, 9.9460413],
                [106.0715885, 9.9461681],
                [106.0709636, 9.9462724],
                [106.0705787, 9.946353],
                [106.0681492, 9.9469888],
                [106.0656434, 9.9477189],
                [106.0651821, 9.9478219],
                [106.0647449, 9.94788],
                [106.0643694, 9.9478932],
                [106.0629103, 9.9478721],
                [106.063031, 9.9475181],
                [106.0630631, 9.9473913],
                [106.0630685, 9.9472565],
                [106.0626454, 9.9456106],
                [106.0557219, 9.9468272],
                [106.0562262, 9.9455076],
                [106.0557112, 9.9451721],
                [106.0555127, 9.9449687],
                [106.0551077, 9.9446728],
                [106.0555771, 9.9421841],
                [106.0551372, 9.942081],
                [106.053493, 9.9418459],
                [106.0531551, 9.9417878],
                [106.0526562, 9.9417455],
                [106.0521626, 9.9416557],
                [106.0517335, 9.9415447],
                [106.0514224, 9.9414126],
                [106.0508269, 9.9409899],
                [106.0485631, 9.9393149],
                [106.0481983, 9.9391247],
                [106.0479248, 9.9389979],
                [106.0477531, 9.9389133],
                [106.0474259, 9.9386597],
                [106.047281, 9.9386122],
                [106.0471094, 9.938591],
                [106.0467553, 9.9385435],
                [106.0463369, 9.9384219],
                [106.0459721, 9.9382846],
                [106.0455644, 9.9380309],
                [106.0452425, 9.9377879],
                [106.0450065, 9.9375395],
                [106.043703, 9.93606],
                [106.0435581, 9.9359702],
                [106.0432792, 9.9358962],
                [106.043086, 9.9357747],
                [106.0428661, 9.9355211],
                [106.0425979, 9.9351406],
                [106.0425013, 9.9349398],
                [106.0424852, 9.9347126],
                [106.0425711, 9.9341102],
                [106.042555, 9.9338143],
                [106.042496, 9.933138],
                [106.0424477, 9.933027],
                [106.0423726, 9.9329372],
                [106.0422546, 9.9328844],
                [106.0420346, 9.9328262],
                [106.0416752, 9.9327417],
                [106.0409349, 9.9324537],
                [106.0407445, 9.9324009],
                [106.0403287, 9.9323375],
                [106.0402107, 9.9322952],
                [106.0401142, 9.9322106],
                [106.0399961, 9.9320257],
                [106.039913, 9.9319385],
                [106.0397333, 9.9318223],
                [106.0396072, 9.9317034],
                [106.0395375, 9.9315686],
                [106.039508, 9.9314418],
                [106.0395133, 9.9311327],
                [106.0394785, 9.9309663],
                [106.03939, 9.930813],
                [106.0391593, 9.9305488],
                [106.0391217, 9.9304061],
                [106.0391271, 9.9302423],
                [106.0392237, 9.9297721],
                [106.0392183, 9.9295607],
                [106.0390735, 9.9288632],
                [106.0389876, 9.9284193],
                [106.0388589, 9.9282502],
                [106.0386175, 9.9282767],
                [106.0385477, 9.9282185],
                [106.0380167, 9.9274048],
                [106.0378611, 9.9272463],
                [106.0377377, 9.9271934],
                [106.0375982, 9.9272515],
                [106.0373408, 9.9274418],
                [106.0371101, 9.9275844],
                [106.0369277, 9.927632],
                [106.036697, 9.927595],
                [106.0362625, 9.9275052],
                [106.0359782, 9.9273995],
                [106.0356456, 9.9272304],
                [106.0352647, 9.9270296],
                [106.0348731, 9.9269556],
                [106.0345083, 9.9269768],
                [106.0338914, 9.9270455],
                [106.0333067, 9.9271247],
                [106.0324269, 9.9273255],
                [106.0318798, 9.9273942],
                [106.0315257, 9.9274523],
                [106.031236, 9.9275527],
                [106.0305226, 9.9278539],
                [106.0302865, 9.9279121],
                [106.0299325, 9.9278909],
                [106.0294068, 9.9279121],
                [106.0286289, 9.9278671],
                [106.0282883, 9.9278407],
                [106.0277116, 9.9277139],
                [106.0275319, 9.9277113],
                [106.0273415, 9.927809],
                [106.0271779, 9.9280283],
                [106.0270411, 9.9283084],
                [106.0269874, 9.9285303],
                [106.0269499, 9.9291063],
                [106.0269418, 9.9295396],
                [106.0269016, 9.9296479],
                [106.0267541, 9.9296637],
                [106.0264134, 9.9296241],
                [106.0260674, 9.9296109],
                [106.0257456, 9.92964],
                [106.0255471, 9.9297166],
                [106.0254183, 9.929854],
                [106.0252011, 9.9301631],
                [106.0250536, 9.9303797],
                [106.0248953, 9.9305118],
                [106.0247156, 9.9305937],
                [106.0244983, 9.9306069],
                [106.0242462, 9.9305541],
                [106.0238895, 9.930496],
                [106.0236105, 9.9305092],
                [106.023345, 9.9305805],
                [106.0230714, 9.9307073],
                [106.0228676, 9.9308976],
                [106.0226959, 9.9311301],
                [106.0225135, 9.9312569],
                [106.0221863, 9.9314207],
                [106.0220146, 9.9315686],
                [106.0219234, 9.9317219],
                [106.0218698, 9.9319966],
                [106.0218483, 9.9322397],
                [106.0217866, 9.9324114],
                [106.0216767, 9.9325594],
                [106.0213441, 9.9328341],
                [106.0212019, 9.9329108],
                [106.0210356, 9.9329213],
                [106.0207057, 9.9328791],
                [106.0202122, 9.9328051],
                [106.0197401, 9.9320759],
                [106.0191393, 9.9309663],
                [106.0173368, 9.9275105],
                [106.0170203, 9.927056],
                [106.0168755, 9.9268552],
                [106.0164785, 9.9264642],
                [106.0152984, 9.9258196],
                [106.0131848, 9.9244034],
                [106.0130346, 9.9241603],
                [106.0130158, 9.9238988],
                [106.0130721, 9.9236583],
                [106.0131714, 9.9234232],
                [106.0132813, 9.9232198],
                [106.0138821, 9.9225223],
                [106.0140753, 9.9223003],
                [106.0142147, 9.9219199],
                [106.0143542, 9.9212329],
                [106.0145044, 9.9209687],
                [106.0147405, 9.9207679],
                [106.0152018, 9.9205882],
                [106.0154593, 9.920324],
                [106.0155558, 9.9199964],
                [106.0155344, 9.9196688],
                [106.0153252, 9.9190769],
                [106.0152769, 9.9187705],
                [106.0152823, 9.9184851],
                [106.015403, 9.9178563],
                [106.0153332, 9.9173199],
                [106.0149765, 9.9167228],
                [106.0146868, 9.9163556],
                [106.0143703, 9.9160702],
                [106.0140055, 9.9158668],
                [106.0126778, 9.9152881],
                [106.0121307, 9.9149803],
                [106.0118061, 9.9147333],
                [106.0115969, 9.914477],
                [106.011444, 9.9141758],
                [106.0112911, 9.9138349],
                [106.0110766, 9.9135681],
                [106.0108258, 9.9133977],
                [106.0105133, 9.9133224],
                [106.0098454, 9.9131982],
                [106.0070291, 9.9121017],
                [106.0066053, 9.911885],
                [106.0055593, 9.9112747],
                [106.0045454, 9.9109312],
                [106.0027993, 9.9101201],
                [106.0023111, 9.9098294],
                [106.0014059, 9.9091372],
                [106.0009687, 9.908898],
                [106.0000715, 9.908573],
                [105.9998234, 9.9084792],
                [105.9996235, 9.9083234],
                [105.9991461, 9.9077474],
                [105.9989315, 9.9075148],
                [105.9977768, 9.9066337],
                [105.9963217, 9.9055807],
                [105.9960803, 9.9054433],
                [105.9957987, 9.9053905],
                [105.9955278, 9.9054064],
                [105.9952515, 9.9054724],
                [105.994817, 9.90566],
                [105.9944871, 9.9057657],
                [105.9940177, 9.905845],
                [105.993669, 9.9059586],
                [105.9934544, 9.9060881],
                [105.9929502, 9.9065531],
                [105.9925425, 9.9067433],
                [105.9921133, 9.9068067],
                [105.9917164, 9.9066271],
                [105.9913623, 9.906236],
                [105.9904826, 9.9043917],
                [105.9901205, 9.9039135],
                [105.9895948, 9.9035792],
                [105.9889671, 9.9033216],
                [105.9879988, 9.9030363],
                [105.9873819, 9.9027377],
                [105.9870171, 9.9010361],
                [105.9864592, 9.9012052],
                [105.9855044, 9.9018393],
                [105.9845281, 9.9026637],
                [105.9834981, 9.9038157],
                [105.9823072, 9.9053377],
                [105.9793353, 9.9092851],
                [105.9770715, 9.9105428],
                [105.9766102, 9.9109127],
                [105.9760791, 9.9113777],
                [105.9755802, 9.9117582],
                [105.9737885, 9.9128309],
                [105.9727263, 9.913613],
                [105.9718895, 9.9142894],
                [105.9712887, 9.9149341],
                [105.9706396, 9.9157479],
                [105.9703499, 9.915769],
                [105.9701514, 9.9158113],
                [105.9699583, 9.9159434],
                [105.9696364, 9.9162234],
                [105.9690571, 9.9167889],
                [105.9682041, 9.9176291],
                [105.9669113, 9.9186701],
                [105.9658009, 9.9196265],
                [105.9646811, 9.9205829],
                [105.9642291, 9.9210163],
                [105.9636658, 9.921756],
                [105.9632823, 9.9222263],
                [105.9630731, 9.9223743],
                [105.9623905, 9.9217917],
                [105.9621035, 9.9219027],
                [105.9615254, 9.9221471],
                [105.9604311, 9.9225222],
                [105.9595004, 9.922908],
                [105.9592697, 9.9226411],
                [105.9590337, 9.9224562],
                [105.9587681, 9.9223373],
                [105.9585106, 9.9222475],
                [105.9578347, 9.9221524],
                [105.9571856, 9.9220625],
                [105.9563863, 9.9220837],
                [105.9557426, 9.9220678],
                [105.9552839, 9.922118],
                [105.9530798, 9.9205762],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.6818716, 10.1347692],
                [105.6852871, 10.1326264],
                [105.6915232, 10.1287965],
                [105.6982048, 10.1245155],
                [105.7055599, 10.1199047],
                [105.70759, 10.1186417],
                [105.7087877, 10.1178966],
                [105.7112027, 10.1163542],
                [105.7135717, 10.1148153],
                [105.7153191, 10.1136842],
                [105.717479, 10.1121015],
                [105.7209923, 10.1092381],
                [105.726509, 10.1045619],
                [105.7288172, 10.1026711],
                [105.7315361, 10.1007445],
                [105.7338092, 10.0992144],
                [105.7354183, 10.0983691],
                [105.7367197, 10.0977859],
                [105.7388484, 10.096778],
                [105.7414413, 10.0955128],
                [105.744667, 10.0939578],
                [105.7494815, 10.0914622],
                [105.7504563, 10.0909077],
                [105.7551907, 10.0880895],
                [105.758067, 10.0857817],
                [105.7605018, 10.0834979],
                [105.7609653, 10.0829445],
                [105.763238, 10.0809142],
                [105.7648153, 10.079668],
                [105.7664396, 10.0786057],
                [105.7724045, 10.0754392],
                [105.7762571, 10.0733591],
                [105.7786704, 10.0719267],
                [105.781066, 10.070456],
                [105.781455, 10.0702172],
                [105.7822627, 10.0696105],
                [105.7850504, 10.0675164],
                [105.7876917, 10.0642884],
                [105.7918705, 10.0591375],
                [105.7940624, 10.0561961],
                [105.7954249, 10.0544026],
                [105.7971375, 10.0519602],
                [105.7986197, 10.0499467],
                [105.8009304, 10.0467371],
                [105.8033258, 10.0434714],
                [105.8037894, 10.04301],
                [105.8045749, 10.0419892],
                [105.8054571, 10.0408428],
                [105.80951, 10.0354713],
                [105.8108989, 10.0333508],
                [105.8128446, 10.0309068],
                [105.8149505, 10.0275189],
                [105.8162462, 10.0253066],
                [105.8172638, 10.0234172],
                [105.8189501, 10.019524],
                [105.8198741, 10.0173586],
                [105.8218179, 10.0142243],
                [105.8229746, 10.0122424],
                [105.8230974, 10.0120336],
                [105.8232191, 10.0118267],
                [105.8252649, 10.0083478],
                [105.8267988, 10.0061554],
                [105.8286679, 10.0034839],
                [105.8300098, 10.0012254],
                [105.8362759, 9.9944209],
                [105.8415444, 9.9874355],
                [105.8437676, 9.9846685],
                [105.8454722, 9.9826158],
                [105.848191, 9.9793416],
                [105.8486541, 9.9787422],
                [105.8504144, 9.9767127],
                [105.8559061, 9.971291],
                [105.8573889, 9.9697684],
                [105.8597141, 9.9675893],
                [105.8597526, 9.9675532],
                [105.865154, 9.9632361],
                [105.8659877, 9.9622675],
                [105.8684898, 9.9596837],
                [105.8731935, 9.9551842],
                [105.8757898, 9.9531524],
                [105.8780142, 9.9510756],
                [105.8823266, 9.9479807],
                [105.8890746, 9.9436597],
                [105.8968064, 9.9380667],
                [105.8995774, 9.9364291],
                [105.9017925, 9.9348566],
                [105.9028082, 9.9341355],
                [105.9032867, 9.9337958],
                [105.9056519, 9.9323167],
                [105.9072269, 9.9322709],
                [105.9092036, 9.932235],
                [105.9112764, 9.9321439],
                [105.9133559, 9.9319376],
                [105.9150999, 9.931704],
                [105.9170052, 9.9314047],
                [105.9187158, 9.9309657],
                [105.9201046, 9.9305149],
                [105.9217866, 9.9297837],
                [105.9230956, 9.9289234],
                [105.9241795, 9.928173],
                [105.9260157, 9.9265864],
                [105.931336, 9.9220524],
                [105.9317063, 9.9215911],
                [105.9331429, 9.9203445],
                [105.9357851, 9.9183582],
                [105.9378715, 9.9169257],
                [105.9415563, 9.9140851],
                [105.9426694, 9.9134377],
                [105.9457286, 9.911128],
                [105.949855, 9.908401],
                [105.9542845, 9.9061103],
                [105.9551653, 9.9054635],
                [105.9568812, 9.9045382],
                [105.9590141, 9.9031975],
                [105.9607292, 9.90195],
                [105.9620269, 9.9009338],
                [105.9634633, 9.8997332],
                [105.9651074, 9.8980397],
                [105.9658023, 9.897394],
                [105.9670521, 9.8959189],
                [105.9686249, 9.893707],
                [105.9700593, 9.8918175],
                [105.9715868, 9.8900196],
                [105.973022, 9.8884061],
                [105.9743185, 9.8870228],
                [105.9766981, 9.885051],
                [105.9789246, 9.8838302],
                [105.9823223, 9.8829837],
                [105.9810273, 9.8856165],
                [105.9799275, 9.8876291],
                [105.9788659, 9.8894325],
                [105.9775976, 9.891421],
                [105.9757187, 9.8937125],
                [105.9733688, 9.8964818],
                [105.9707043, 9.8994647],
                [105.9675241, 9.9030844],
                [105.9647543, 9.9061833],
                [105.9624409, 9.9088932],
                [105.9605721, 9.9110068],
                [105.9580774, 9.9140018],
                [105.9559704, 9.9167468],
                [105.9530798, 9.9205762],
                [105.9552839, 9.922118],
                [105.9557426, 9.9220678],
                [105.9563863, 9.9220837],
                [105.9571856, 9.9220625],
                [105.9578347, 9.9221524],
                [105.9585106, 9.9222475],
                [105.9587681, 9.9223373],
                [105.9590337, 9.9224562],
                [105.9592697, 9.9226411],
                [105.9595004, 9.922908],
                [105.9604311, 9.9225222],
                [105.9615254, 9.9221471],
                [105.9621035, 9.9219027],
                [105.9623905, 9.9217917],
                [105.9630731, 9.9223743],
                [105.9632823, 9.9222263],
                [105.9636658, 9.921756],
                [105.9642291, 9.9210163],
                [105.9646811, 9.9205829],
                [105.9658009, 9.9196265],
                [105.9669113, 9.9186701],
                [105.9682041, 9.9176291],
                [105.9690571, 9.9167889],
                [105.9696364, 9.9162234],
                [105.9699583, 9.9159434],
                [105.9701514, 9.9158113],
                [105.9703499, 9.915769],
                [105.9706396, 9.9157479],
                [105.9712887, 9.9149341],
                [105.9718895, 9.9142894],
                [105.9727263, 9.913613],
                [105.9737885, 9.9128309],
                [105.9755802, 9.9117582],
                [105.9760791, 9.9113777],
                [105.9766102, 9.9109127],
                [105.9770715, 9.9105428],
                [105.9793353, 9.9092851],
                [105.9823072, 9.9053377],
                [105.9834981, 9.9038157],
                [105.9845281, 9.9026637],
                [105.9855044, 9.9018393],
                [105.9864592, 9.9012052],
                [105.9870171, 9.9010361],
                [105.9873819, 9.9027377],
                [105.9879988, 9.9030363],
                [105.9889671, 9.9033216],
                [105.9895948, 9.9035792],
                [105.9901205, 9.9039135],
                [105.9904826, 9.9043917],
                [105.9913623, 9.906236],
                [105.9917164, 9.9066271],
                [105.9921133, 9.9068067],
                [105.9925425, 9.9067433],
                [105.9929502, 9.9065531],
                [105.9934544, 9.9060881],
                [105.993669, 9.9059586],
                [105.9940177, 9.905845],
                [105.9944871, 9.9057657],
                [105.994817, 9.90566],
                [105.9952515, 9.9054724],
                [105.9955278, 9.9054064],
                [105.9957987, 9.9053905],
                [105.9960803, 9.9054433],
                [105.9963217, 9.9055807],
                [105.9977768, 9.9066337],
                [105.9989315, 9.9075148],
                [105.9991461, 9.9077474],
                [105.9996235, 9.9083234],
                [105.9998234, 9.9084792],
                [106.0000715, 9.908573],
                [106.0009687, 9.908898],
                [106.0014059, 9.9091372],
                [106.0023111, 9.9098294],
                [106.0027993, 9.9101201],
                [106.0045454, 9.9109312],
                [106.0055593, 9.9112747],
                [106.0066053, 9.911885],
                [106.0070291, 9.9121017],
                [106.0098454, 9.9131982],
                [106.0105133, 9.9133224],
                [106.0108258, 9.9133977],
                [106.0110766, 9.9135681],
                [106.0112911, 9.9138349],
                [106.011444, 9.9141758],
                [106.0115969, 9.914477],
                [106.0118061, 9.9147333],
                [106.0121307, 9.9149803],
                [106.0126778, 9.9152881],
                [106.0140055, 9.9158668],
                [106.0143703, 9.9160702],
                [106.0146868, 9.9163556],
                [106.0149765, 9.9167228],
                [106.0153332, 9.9173199],
                [106.015403, 9.9178563],
                [106.0152823, 9.9184851],
                [106.0152769, 9.9187705],
                [106.0153252, 9.9190769],
                [106.0155344, 9.9196688],
                [106.0155558, 9.9199964],
                [106.0154593, 9.920324],
                [106.0152018, 9.9205882],
                [106.0147405, 9.9207679],
                [106.0145044, 9.9209687],
                [106.0143542, 9.9212329],
                [106.0142147, 9.9219199],
                [106.0140753, 9.9223003],
                [106.0138821, 9.9225223],
                [106.0132813, 9.9232198],
                [106.0131714, 9.9234232],
                [106.0130721, 9.9236583],
                [106.0130158, 9.9238988],
                [106.0130346, 9.9241603],
                [106.0131848, 9.9244034],
                [106.0152984, 9.9258196],
                [106.0164785, 9.9264642],
                [106.0168755, 9.9268552],
                [106.0170203, 9.927056],
                [106.0173368, 9.9275105],
                [106.0191393, 9.9309663],
                [106.0197401, 9.9320759],
                [106.0202122, 9.9328051],
                [106.0207057, 9.9328791],
                [106.0210356, 9.9329213],
                [106.0212019, 9.9329108],
                [106.0213441, 9.9328341],
                [106.0216767, 9.9325594],
                [106.0217866, 9.9324114],
                [106.0218483, 9.9322397],
                [106.0218698, 9.9319966],
                [106.0219234, 9.9317219],
                [106.0220146, 9.9315686],
                [106.0221863, 9.9314207],
                [106.0225135, 9.9312569],
                [106.0226959, 9.9311301],
                [106.0228676, 9.9308976],
                [106.0230714, 9.9307073],
                [106.023345, 9.9305805],
                [106.0236105, 9.9305092],
                [106.0238895, 9.930496],
                [106.0242462, 9.9305541],
                [106.0244983, 9.9306069],
                [106.0247156, 9.9305937],
                [106.0248953, 9.9305118],
                [106.0250536, 9.9303797],
                [106.0252011, 9.9301631],
                [106.0254183, 9.929854],
                [106.0255471, 9.9297166],
                [106.0257456, 9.92964],
                [106.0260674, 9.9296109],
                [106.0264134, 9.9296241],
                [106.0267541, 9.9296637],
                [106.0269016, 9.9296479],
                [106.0269418, 9.9295396],
                [106.0269499, 9.9291063],
                [106.0269874, 9.9285303],
                [106.0270411, 9.9283084],
                [106.0271779, 9.9280283],
                [106.0273415, 9.927809],
                [106.0275319, 9.9277113],
                [106.0277116, 9.9277139],
                [106.0282883, 9.9278407],
                [106.0286289, 9.9278671],
                [106.0294068, 9.9279121],
                [106.0299325, 9.9278909],
                [106.0302865, 9.9279121],
                [106.0305226, 9.9278539],
                [106.031236, 9.9275527],
                [106.0315257, 9.9274523],
                [106.0318798, 9.9273942],
                [106.0324269, 9.9273255],
                [106.0333067, 9.9271247],
                [106.0338914, 9.9270455],
                [106.0345083, 9.9269768],
                [106.0348731, 9.9269556],
                [106.0352647, 9.9270296],
                [106.0356456, 9.9272304],
                [106.0359782, 9.9273995],
                [106.0362625, 9.9275052],
                [106.036697, 9.927595],
                [106.0369277, 9.927632],
                [106.0371101, 9.9275844],
                [106.0373408, 9.9274418],
                [106.0375982, 9.9272515],
                [106.0377377, 9.9271934],
                [106.0378611, 9.9272463],
                [106.0380167, 9.9274048],
                [106.0385477, 9.9282185],
                [106.0386175, 9.9282767],
                [106.0388589, 9.9282502],
                [106.0389876, 9.9284193],
                [106.0390735, 9.9288632],
                [106.0392183, 9.9295607],
                [106.0392237, 9.9297721],
                [106.0391271, 9.9302423],
                [106.0391217, 9.9304061],
                [106.0391593, 9.9305488],
                [106.03939, 9.930813],
                [106.0394785, 9.9309663],
                [106.0395133, 9.9311327],
                [106.039508, 9.9314418],
                [106.0395375, 9.9315686],
                [106.0396072, 9.9317034],
                [106.0397333, 9.9318223],
                [106.039913, 9.9319385],
                [106.0399961, 9.9320257],
                [106.0401142, 9.9322106],
                [106.0402107, 9.9322952],
                [106.0403287, 9.9323375],
                [106.0407445, 9.9324009],
                [106.0409349, 9.9324537],
                [106.0416752, 9.9327417],
                [106.0420346, 9.9328262],
                [106.0422546, 9.9328844],
                [106.0423726, 9.9329372],
                [106.0424477, 9.933027],
                [106.042496, 9.933138],
                [106.042555, 9.9338143],
                [106.0425711, 9.9341102],
                [106.0424852, 9.9347126],
                [106.0425013, 9.9349398],
                [106.0425979, 9.9351406],
                [106.0428661, 9.9355211],
                [106.043086, 9.9357747],
                [106.0432792, 9.9358962],
                [106.0435581, 9.9359702],
                [106.043703, 9.93606],
                [106.0450065, 9.9375395],
                [106.0452425, 9.9377879],
                [106.0455644, 9.9380309],
                [106.0459721, 9.9382846],
                [106.0463369, 9.9384219],
                [106.0467553, 9.9385435],
                [106.0471094, 9.938591],
                [106.047281, 9.9386122],
                [106.0474259, 9.9386597],
                [106.0477531, 9.9389133],
                [106.0479248, 9.9389979],
                [106.0481983, 9.9391247],
                [106.0485631, 9.9393149],
                [106.0508269, 9.9409899],
                [106.0514224, 9.9414126],
                [106.0517335, 9.9415447],
                [106.0521626, 9.9416557],
                [106.0526562, 9.9417455],
                [106.0531551, 9.9417878],
                [106.053493, 9.9418459],
                [106.0551372, 9.942081],
                [106.0555771, 9.9421841],
                [106.0551077, 9.9446728],
                [106.0555127, 9.9449687],
                [106.0557112, 9.9451721],
                [106.0562262, 9.9455076],
                [106.0557219, 9.9468272],
                [106.0626454, 9.9456106],
                [106.0630685, 9.9472565],
                [106.0630631, 9.9473913],
                [106.063031, 9.9475181],
                [106.0629103, 9.9478721],
                [106.0643694, 9.9478932],
                [106.0647449, 9.94788],
                [106.0651821, 9.9478219],
                [106.0656434, 9.9477189],
                [106.0681492, 9.9469888],
                [106.0705787, 9.946353],
                [106.0709636, 9.9462724],
                [106.0715885, 9.9461681],
                [106.072872, 9.9460413],
                [106.0733145, 9.9459699],
                [106.0739368, 9.9458193],
                [106.0743982, 9.945682],
                [106.0751492, 9.9453887],
                [106.0757929, 9.9451272],
                [106.0763937, 9.9449528],
                [106.07678, 9.945682],
                [106.07737, 9.9467387],
                [106.0779816, 9.9477876],
                [106.0785931, 9.9487888],
                [106.0784322, 9.9489262],
                [106.0788909, 9.949592],
                [106.0791054, 9.9494229],
                [106.0792885, 9.9496672],
                [106.0792375, 9.9497194],
                [106.0801193, 9.9509578],
                [106.0807899, 9.9518534],
                [106.0846321, 9.9570208],
                [106.0856547, 9.958413],
                [106.0865559, 9.9596403],
                [106.0877817, 9.9588948],
                [106.0900703, 9.9573778],
                [106.0913165, 9.9565585],
                [106.0943247, 9.9543802],
                [106.0956033, 9.9534468],
                [106.0986448, 9.9513987],
                [106.1016124, 9.9494162],
                [106.1028991, 9.9484145],
                [106.1044062, 9.9470248],
                [106.1070261, 9.9442791],
                [106.1078037, 9.9434606],
                [106.1093469, 9.9419151],
                [106.1107477, 9.940496],
                [106.1120764, 9.9390695],
                [106.1127003, 9.938488],
                [106.1197328, 9.9463002],
                [106.1237776, 9.9508706],
                [106.1241531, 9.9513514],
                [106.126453, 9.9544842],
                [106.1305261, 9.9601011],
                [106.1312932, 9.961076],
                [106.1314487, 9.9613058],
                [106.131556, 9.9615224],
                [106.1316633, 9.9617866],
                [106.1317277, 9.9620878],
                [106.1317706, 9.9624259],
                [106.1318457, 9.9635566],
                [106.1321354, 9.9657545],
                [106.13235, 9.9689352],
                [106.1331975, 9.9784452],
                [106.1334175, 9.9814144],
                [106.1334014, 9.9815623],
                [106.1333424, 9.9817366],
                [106.1331332, 9.9821434],
                [106.1327684, 9.9827246],
                [106.132468, 9.9831261],
                [106.1319959, 9.983665],
                [106.1318993, 9.9838235],
                [106.1319208, 9.984056],
                [106.1320925, 9.9845103],
                [106.1321247, 9.984933],
                [106.1320925, 9.9854296],
                [106.1319852, 9.9860636],
                [106.1317384, 9.9868455],
                [106.1313736, 9.9879021],
                [106.1309123, 9.9897934],
                [106.1306548, 9.9906176],
                [106.1306119, 9.9909135],
                [106.1306333, 9.9912304],
                [106.1307406, 9.9915157],
                [106.1311054, 9.9921814],
                [106.1311698, 9.9924455],
                [106.1312342, 9.9929422],
                [106.1316526, 9.9940833],
                [106.131835, 9.9946644],
                [106.1320991, 9.9955156],
                [106.1324961, 9.9967951],
                [106.1326832, 9.9973968],
                [106.1328118, 9.9978426],
                [106.1329352, 9.9982193],
                [106.1332729, 9.9989064],
                [106.1334673, 9.9994188],
                [106.1336553, 10.0001307],
                [106.1340064, 10.0012301],
                [106.1344099, 10.0023353],
                [106.1345085, 10.0028715],
                [106.1345351, 10.003466],
                [106.1345576, 10.0049899],
                [106.1345708, 10.0067728],
                [106.134667, 10.0102122],
                [106.1346989, 10.013],
                [106.1347808, 10.0155353],
                [106.1347867, 10.0202252],
                [106.1348471, 10.0216123],
                [106.1348031, 10.0228734],
                [106.1346958, 10.0239237],
                [106.1365959, 10.023213],
                [106.1397772, 10.0219006],
                [106.1428439, 10.0207105],
                [106.144339, 10.0199908],
                [106.1451272, 10.0197744],
                [106.1458676, 10.019464],
                [106.1465316, 10.0191864],
                [106.1478213, 10.0186737],
                [106.1488483, 10.018335],
                [106.1504294, 10.0176388],
                [106.1509071, 10.0176341],
                [106.1520201, 10.0179305],
                [106.1537493, 10.018288],
                [106.1551871, 10.0185655],
                [106.1564243, 10.0188195],
                [106.1580293, 10.0191535],
                [106.1604081, 10.0196474],
                [106.1628729, 10.0201272],
                [106.1649031, 10.0205976],
                [106.1658011, 10.0208093],
                [106.1664603, 10.0209222],
                [106.1671099, 10.021068],
                [106.1681561, 10.0212797],
                [106.1692977, 10.0214867],
                [106.170277, 10.0217218],
                [106.1717768, 10.0220698],
                [106.1730045, 10.0223239],
                [106.1745904, 10.0226579],
                [106.1766635, 10.0230107],
                [106.1778099, 10.0233118],
                [106.1784261, 10.0233964],
                [106.1795487, 10.0238245],
                [106.1811298, 10.024149],
                [106.1825819, 10.0243466],
                [106.1839003, 10.0246618],
                [106.1850754, 10.0249487],
                [106.1859735, 10.0251086],
                [106.1868476, 10.0252262],
                [106.1876262, 10.0254097],
                [106.1879988, 10.0255273],
                [106.1887344, 10.0258001],
                [106.1894271, 10.0260823],
                [106.1898235, 10.0261905],
                [106.1901961, 10.0262517],
                [106.1906595, 10.0261247],
                [106.1910416, 10.0258612],
                [106.1913521, 10.0255085],
                [106.1916199, 10.0253092],
                [106.1919286, 10.0251887],
                [106.1921923, 10.0251441],
                [106.1929893, 10.025101],
                [106.1936074, 10.0250839],
                [106.1940462, 10.0250428],
                [106.1945239, 10.024944],
                [106.1948105, 10.0247982],
                [106.1951162, 10.0245489],
                [106.1953121, 10.0241632],
                [106.1954793, 10.0235799],
                [106.1955796, 10.0227896],
                [106.1958184, 10.0218347],
                [106.1962626, 10.0206541],
                [106.1970795, 10.0190641],
                [106.1975572, 10.018208],
                [106.1980614, 10.0170334],
                [106.1982068, 10.0161477],
                [106.1983883, 10.0153104],
                [106.1989585, 10.0145672],
                [106.1996255, 10.0140309],
                [106.2001892, 10.0134852],
                [106.2008627, 10.0128878],
                [106.2017051, 10.0119234],
                [106.2025447, 10.0110681],
                [106.2033709, 10.0101685],
                [106.2037016, 10.0098641],
                [106.2040836, 10.0096353],
                [106.2043767, 10.0095079],
                [106.2045682, 10.0095199],
                [106.2048368, 10.0096579],
                [106.2050678, 10.0100233],
                [106.2056048, 10.0108044],
                [106.2063236, 10.0115281],
                [106.2067152, 10.01175],
                [106.2072088, 10.0117764],
                [106.2077238, 10.0117394],
                [106.2082334, 10.0114806],
                [106.2084533, 10.0111794],
                [106.2084792, 10.0110086],
                [106.2084785, 10.010857],
                [106.208393, 10.0104971],
                [106.2082479, 10.0102461],
                [106.2080015, 10.0099945],
                [106.207602, 10.0096572],
                [106.2081706, 10.0091237],
                [106.2082779, 10.0089599],
                [106.2083611, 10.0088648],
                [106.2084255, 10.008845],
                [106.2084791, 10.0088648],
                [106.208522, 10.0088992],
                [106.2085462, 10.0089784],
                [106.208573, 10.0090392],
                [106.2086293, 10.0090682],
                [106.2087017, 10.0090444],
                [106.2087446, 10.0089995],
                [106.2087527, 10.0088992],
                [106.2087527, 10.0088226],
                [106.2087648, 10.0087677],
                [106.2087889, 10.0087242],
                [106.2088265, 10.0086951],
                [106.2088948, 10.0086641],
                [106.2089914, 10.0086482],
                [106.2090611, 10.0086588],
                [106.2091175, 10.0087248],
                [106.2091711, 10.0088278],
                [106.2092167, 10.0088727],
                [106.2092784, 10.0088965],
                [106.2093669, 10.0088939],
                [106.2095895, 10.0088173],
                [106.2098899, 10.008738],
                [106.2102923, 10.0086482],
                [106.2106222, 10.0086218],
                [106.2114456, 10.009541],
                [106.2126338, 10.011596],
                [106.2135029, 10.0126948],
                [106.2145892, 10.0146177],
                [106.2206644, 10.0145517],
                [106.2213548, 10.014494],
                [106.2220357, 10.0154128],
                [106.2221719, 10.0156571],
                [106.2225555, 10.0163595],
                [106.2231023, 10.0170389],
                [106.2239357, 10.0177956],
                [106.2247325, 10.018276],
                [106.2254607, 10.0185954],
                [106.2261534, 10.0188184],
                [106.2281402, 10.0194581],
                [106.2289556, 10.019923],
                [106.2297415, 10.0206045],
                [106.2309183, 10.0217375],
                [106.2309996, 10.0218296],
                [106.231807, 10.0227449],
                [106.2322006, 10.0233242],
                [106.2323043, 10.0237406],
                [106.2323511, 10.0239799],
                [106.2323558, 10.0242007],
                [106.2323371, 10.0244444],
                [106.2321877, 10.0252446],
                [106.2320639, 10.0257159],
                [106.2319215, 10.0262333],
                [106.2316428, 10.0276838],
                [106.2315791, 10.0284595],
                [106.2314354, 10.0302106],
                [106.2314378, 10.0308331],
                [106.2314479, 10.0334692],
                [106.2314225, 10.0368922],
                [106.2312816, 10.0378195],
                [106.2311636, 10.0388865],
                [106.231067, 10.0402282],
                [106.2310131, 10.0417632],
                [106.2310343, 10.042117],
                [106.2270711, 10.0425615],
                [106.2224816, 10.0435853],
                [106.226321, 10.0479114],
                [106.2268066, 10.0477411],
                [106.2267101, 10.0483248],
                [106.2255889, 10.0527723],
                [106.225021, 10.0547165],
                [106.2249559, 10.0548778],
                [106.2248577, 10.0550376],
                [106.2245724, 10.0553288],
                [106.224677, 10.055474],
                [106.2247011, 10.0555718],
                [106.2246796, 10.0556748],
                [106.2245938, 10.0558623],
                [106.2245992, 10.0559336],
                [106.2246501, 10.0559917],
                [106.2248111, 10.0560445],
                [106.2249586, 10.0561026],
                [106.2249794, 10.0561369],
                [106.2249801, 10.0561713],
                [106.2249613, 10.056199],
                [106.2249278, 10.0562241],
                [106.224913, 10.0562597],
                [106.224913, 10.0562954],
                [106.2249291, 10.0563403],
                [106.2249774, 10.0563693],
                [106.225023, 10.0563839],
                [106.2250444, 10.0564076],
                [106.2250471, 10.056438],
                [106.225023, 10.0564605],
                [106.2250082, 10.0564961],
                [106.2250069, 10.0565397],
                [106.225023, 10.0565859],
                [106.2250525, 10.0566189],
                [106.2251155, 10.056644],
                [106.2251866, 10.0566585],
                [106.2252483, 10.0566889],
                [106.2252899, 10.0567325],
                [106.2253033, 10.0567761],
                [106.2252912, 10.056821],
                [106.2252577, 10.0568685],
                [106.2251477, 10.0569768],
                [106.2250713, 10.0571062],
                [106.2271392, 10.0580015],
                [106.2278259, 10.0583184],
                [106.2283704, 10.0586063],
                [106.2289525, 10.058987],
                [106.2284688, 10.0591215],
                [106.2281349, 10.0592265],
                [106.2278309, 10.0594134],
                [106.2275838, 10.0597045],
                [106.2273969, 10.0600793],
                [106.2273726, 10.0606088],
                [106.2274575, 10.0611267],
                [106.2276396, 10.0620783],
                [106.22769, 10.0628496],
                [106.2276808, 10.0635455],
                [106.2280552, 10.0637786],
                [106.2281397, 10.0638961],
                [106.2281907, 10.064044],
                [106.2283945, 10.0650951],
                [106.2284481, 10.0652271],
                [106.2291589, 10.0664816],
                [106.2286761, 10.0667457],
                [106.2286108, 10.0667684],
                [106.2285689, 10.0667721],
                [106.2285259, 10.0667642],
                [106.2284917, 10.066747],
                [106.2284609, 10.0667113],
                [106.2282577, 10.0668672],
                [106.2282389, 10.0669649],
                [106.227563, 10.0676568],
                [106.2276435, 10.068185],
                [106.2276944, 10.068861],
                [106.2277696, 10.0699438],
                [106.2278044, 10.0704825],
                [106.2285072, 10.0705433],
                [106.2286305, 10.0712854],
                [106.2289014, 10.0714095],
                [106.2289859, 10.0716036],
                [106.2295103, 10.0723945],
                [106.2296766, 10.0727352],
                [106.2297302, 10.0727775],
                [106.2299877, 10.0728831],
                [106.2315729, 10.0735869],
                [106.2317707, 10.0734172],
                [106.2318539, 10.0733631],
                [106.2319508, 10.0733169],
                [106.2328067, 10.073744],
                [106.2327799, 10.07381],
                [106.2327343, 10.0738523],
                [106.2326485, 10.0738813],
                [106.2325962, 10.0739117],
                [106.2325627, 10.0739513],
                [106.2325519, 10.0739936],
                [106.2325627, 10.0740926],
                [106.2325613, 10.0741745],
                [106.232501, 10.0743593],
                [106.2324339, 10.0745178],
                [106.2323293, 10.0747053],
                [106.2322488, 10.0748083],
                [106.2321255, 10.0749298],
                [106.2320477, 10.0750486],
                [106.232155, 10.0751437],
                [106.2325365, 10.0756983],
                [106.2328953, 10.0762806],
                [106.2331172, 10.0768015],
                [106.233201, 10.0770467],
                [106.233201, 10.0771864],
                [106.2336329, 10.0776274],
                [106.2337361, 10.0775336],
                [106.2338313, 10.0775323],
                [106.2340043, 10.0777066],
                [106.234113, 10.0777026],
                [106.2343436, 10.0779139],
                [106.238265, 10.0822343],
                [106.2387447, 10.0819668],
                [106.2392282, 10.0817058],
                [106.2397153, 10.0814515],
                [106.2402059, 10.081204],
                [106.2407, 10.0809631],
                [106.2411975, 10.0807291],
                [106.2416982, 10.0805019],
                [106.2422281, 10.0802704],
                [106.2427615, 10.0800466],
                [106.243298, 10.0798305],
                [106.2438378, 10.0796222],
                [106.2443806, 10.0794217],
                [106.2449263, 10.079229],
                [106.2454748, 10.0790442],
                [106.2495947, 10.0777449],
                [106.2538944, 10.0765515],
                [106.2543068, 10.0764281],
                [106.2547169, 10.0762977],
                [106.2551246, 10.0761602],
                [106.2555299, 10.0760158],
                [106.2559325, 10.0758645],
                [106.2563324, 10.0757062],
                [106.256778, 10.0755203],
                [106.2572199, 10.0753259],
                [106.2576578, 10.075123],
                [106.2580916, 10.0749117],
                [106.2585211, 10.0746921],
                [106.2589177, 10.0744775],
                [106.2593095, 10.0742546],
                [106.2596963, 10.0740234],
                [106.260078, 10.073784],
                [106.2604543, 10.0735365],
                [106.2608251, 10.0732812],
                [106.2611321, 10.0730607],
                [106.2614349, 10.0728348],
                [106.2617335, 10.0726035],
                [106.2620278, 10.072367],
                [106.2623178, 10.0721252],
                [106.2632585, 10.0712954],
                [106.2641926, 10.0704584],
                [106.2648207, 10.0698882],
                [106.2654456, 10.0693147],
                [106.2660675, 10.0687379],
                [106.2719834, 10.0633791],
                [106.2725296, 10.0629112],
                [106.2730795, 10.0624475],
                [106.2736331, 10.0619881],
                [106.2741904, 10.061533],
                [106.2748553, 10.0609996],
                [106.2755252, 10.0604722],
                [106.2762001, 10.059951],
                [106.2768799, 10.0594361],
                [106.2896374, 10.049789],
                [106.2849812, 10.0581309],
                [106.2817313, 10.0634582],
                [106.2792312, 10.0673888],
                [106.2763666, 10.0719475],
                [106.2745704, 10.0747015],
                [106.2711917, 10.0807327],
                [106.269416, 10.0835011],
                [106.2674042, 10.0864007],
                [106.2655747, 10.0888828],
                [106.2640679, 10.0908664],
                [106.2626002, 10.0927233],
                [106.2605781, 10.0950779],
                [106.2579615, 10.097799],
                [106.2553132, 10.1004349],
                [106.252846, 10.1029816],
                [106.250483, 10.1058336],
                [106.2468622, 10.1111656],
                [106.2463386, 10.1119041],
                [106.2422, 10.1175111],
                [106.2407949, 10.1198762],
                [106.2393898, 10.1221733],
                [106.2383263, 10.1237499],
                [106.2379253, 10.1244355],
                [106.230919, 10.1353591],
                [106.2300895, 10.1367133],
                [106.2288456, 10.1383957],
                [106.2284468, 10.1389173],
                [106.2275129, 10.1401252],
                [106.2265237, 10.1413502],
                [106.2251969, 10.1428272],
                [106.2242434, 10.1438528],
                [106.2229168, 10.1452478],
                [106.221839, 10.1463144],
                [106.2210097, 10.147217],
                [106.2195835, 10.1485854],
                [106.2154911, 10.152655],
                [106.2132913, 10.1549195],
                [106.2112632, 10.1570124],
                [106.2094081, 10.1592073],
                [106.2072092, 10.1617111],
                [106.2053877, 10.1637007],
                [106.2042533, 10.1648671],
                [106.2041249, 10.1649998],
                [106.2037089, 10.1654069],
                [106.203286, 10.165807],
                [106.2028563, 10.1662],
                [106.20242, 10.1665858],
                [106.2019793, 10.1669625],
                [106.2015323, 10.1673318],
                [106.2010791, 10.1676937],
                [106.2002971, 10.1683134],
                [106.1995107, 10.1689276],
                [106.1987198, 10.1695363],
                [106.1979246, 10.1701394],
                [106.1971221, 10.1707391],
                [106.1963152, 10.1713331],
                [106.1955041, 10.1719214],
                [106.1946887, 10.172504],
                [106.1894376, 10.1759858],
                [106.1893034, 10.1760749],
                [106.1891646, 10.1761568],
                [106.1890214, 10.1762312],
                [106.1888745, 10.176298],
                [106.1887241, 10.1763569],
                [106.1885707, 10.1764077],
                [106.1884147, 10.1764504],
                [106.1882567, 10.1764848],
                [106.1880969, 10.1765108],
                [106.1879361, 10.1765283],
                [106.187778, 10.1765372],
                [106.1876198, 10.1765379],
                [106.1874617, 10.1765304],
                [106.1873042, 10.1765147],
                [106.1871478, 10.176491],
                [106.1869929, 10.1764592],
                [106.1868398, 10.1764194],
                [106.1866891, 10.1763718],
                [106.1865412, 10.1763164],
                [106.1863964, 10.1762535],
                [106.1835646, 10.1744592],
                [106.1833536, 10.1743328],
                [106.1831378, 10.1742145],
                [106.1829175, 10.1741045],
                [106.1826931, 10.1740029],
                [106.1824649, 10.1739098],
                [106.1822333, 10.1738254],
                [106.1819985, 10.1737498],
                [106.1817609, 10.1736832],
                [106.1815209, 10.1736256],
                [106.1812789, 10.1735771],
                [106.1810351, 10.1735378],
                [106.18079, 10.1735078],
                [106.1805439, 10.173487],
                [106.1802965, 10.1734755],
                [106.1800489, 10.1734734],
                [106.1798014, 10.1734807],
                [106.1795543, 10.1734974],
                [106.1793081, 10.1735235],
                [106.1790631, 10.1735588],
                [106.1788197, 10.1736034],
                [106.1785781, 10.1736572],
                [106.1783389, 10.1737201],
                [106.1781023, 10.173792],
                [106.1778687, 10.1738729],
                [106.1776384, 10.1739625],
                [106.1774118, 10.1740608],
                [106.1771209, 10.1742448],
                [106.1768353, 10.1744365],
                [106.1765551, 10.1746358],
                [106.1762804, 10.1748425],
                [106.1760116, 10.1750565],
                [106.1757489, 10.1752777],
                [106.1754923, 10.1755058],
                [106.1752429, 10.17574],
                [106.175, 10.1759808],
                [106.1747639, 10.1762279],
                [106.1745347, 10.1764813],
                [106.1743125, 10.1767408],
                [106.1740976, 10.177006],
                [106.173866, 10.1772928],
                [106.1736231, 10.177614],
                [106.1731462, 10.1782445],
                [106.1726082, 10.1789402],
                [106.1720761, 10.1796404],
                [106.17155, 10.1803449],
                [106.17103, 10.1810537],
                [106.170516, 10.1817668],
                [106.1700082, 10.1824841],
                [106.16946, 10.183273],
                [106.1689192, 10.1840669],
                [106.1683859, 10.1848656],
                [106.1678601, 10.1856691],
                [106.1673417, 10.1864773],
                [106.1671517, 10.1867779],
                [106.1669542, 10.1870738],
                [106.1667493, 10.1873648],
                [106.1665371, 10.1876507],
                [106.1663179, 10.1879314],
                [106.1660917, 10.1882066],
                [106.1658586, 10.1884763],
                [106.1656189, 10.1887403],
                [106.1653726, 10.1889983],
                [106.1651199, 10.1892502],
                [106.1648609, 10.189496],
                [106.1645849, 10.189745],
                [106.1643025, 10.189987],
                [106.1640138, 10.1902217],
                [106.1637191, 10.1904491],
                [106.1634186, 10.1906689],
                [106.1631124, 10.1908811],
                [106.1628007, 10.1910854],
                [106.1624839, 10.1912818],
                [106.162162, 10.19147],
                [106.1618353, 10.1916501],
                [106.161504, 10.1918219],
                [106.1611683, 10.1919852],
                [106.1606886, 10.1921736],
                [106.1602066, 10.1923566],
                [106.1597226, 10.192534],
                [106.1592364, 10.1927059],
                [106.1586184, 10.1929154],
                [106.1579972, 10.193116],
                [106.1573732, 10.1933075],
                [106.1569625, 10.1934421],
                [106.1565547, 10.1935851],
                [106.15615, 10.1937364],
                [106.1557486, 10.1938958],
                [106.1553506, 10.1940634],
                [106.1549562, 10.1942391],
                [106.1545656, 10.1944227],
                [106.154179, 10.1946143],
                [106.1537964, 10.1948137],
                [106.1534182, 10.1950209],
                [106.1530443, 10.1952357],
                [106.1526751, 10.1954581],
                [106.1523106, 10.1956879],
                [106.1519511, 10.1959252],
                [106.1515966, 10.1961697],
                [106.1512473, 10.1964214],
                [106.1508865, 10.1966931],
                [106.1505318, 10.1969724],
                [106.1501834, 10.1972593],
                [106.1498414, 10.1975535],
                [106.149506, 10.197855],
                [106.1491773, 10.1981636],
                [106.1488554, 10.1984791],
                [106.1485406, 10.1988014],
                [106.148233, 10.1991304],
                [106.1479328, 10.1994659],
                [106.14764, 10.1998077],
                [106.1473548, 10.2001557],
                [106.1470345, 10.2005467],
                [106.1468065, 10.2008138],
                [106.1465422, 10.2011233],
                [106.1462844, 10.2014381],
                [106.1460333, 10.2017581],
                [106.145789, 10.2020832],
                [106.1455516, 10.2024132],
                [106.1453213, 10.202748],
                [106.145098, 10.2030874],
                [106.144882, 10.2034313],
                [106.1446732, 10.2037795],
                [106.1444718, 10.204132],
                [106.144278, 10.2044884],
                [106.1440817, 10.2048685],
                [106.143894, 10.2052527],
                [106.1437148, 10.2056409],
                [106.1435443, 10.2060328],
                [106.1433826, 10.2064283],
                [106.1432297, 10.2068272],
                [106.1430857, 10.2072292],
                [106.1429507, 10.2076343],
                [106.1428247, 10.2080422],
                [106.1427078, 10.2084527],
                [106.1426001, 10.2088656],
                [106.1425016, 10.2092808],
                [106.1424123, 10.209698],
                [106.1423324, 10.210117],
                [106.1422618, 10.2105376],
                [106.1421951, 10.2109385],
                [106.1421054, 10.2113163],
                [106.1420017, 10.2116706],
                [106.1418892, 10.2120224],
                [106.1417682, 10.2123714],
                [106.1416387, 10.2127175],
                [106.1415007, 10.2130603],
                [106.1413543, 10.2133998],
                [106.1411997, 10.2137357],
                [106.1410369, 10.2140678],
                [106.140866, 10.214396],
                [106.1406871, 10.21472],
                [106.1405003, 10.2150397],
                [106.1402967, 10.2153689],
                [106.1400847, 10.2156931],
                [106.1398646, 10.2160119],
                [106.1396363, 10.2163251],
                [106.1394, 10.2166325],
                [106.139156, 10.216934],
                [106.1389043, 10.2172293],
                [106.1386451, 10.2175183],
                [106.1383786, 10.2178007],
                [106.138105, 10.2180765],
                [106.1378243, 10.2183454],
                [106.1375369, 10.2186072],
                [106.135058, 10.220716],
                [106.1335543, 10.2220292],
                [106.1322221, 10.2231773],
                [106.1307799, 10.2243957],
                [106.1290336, 10.225823],
                [106.1271416, 10.2272976],
                [106.1266305, 10.2276894],
                [106.1220703, 10.2308342],
                [106.1172906, 10.2336728],
                [106.1080611, 10.2380831],
                [106.106576, 10.2387288],
                [106.1048881, 10.2393676],
                [106.104002, 10.2396659],
                [106.1029466, 10.2400212],
                [106.1007052, 10.2405973],
                [106.0974677, 10.2412798],
                [106.0945683, 10.2418499],
                [106.089944, 10.2426285],
                [106.0853862, 10.2430803],
                [106.0801449, 10.2433752],
                [106.0750096, 10.2436025],
                [106.0692714, 10.2438285],
                [106.0652734, 10.2439421],
                [106.0620217, 10.2440651],
                [106.0578915, 10.2443533],
                [106.0554342, 10.2445804],
                [106.0526239, 10.2449324],
                [106.049453, 10.2453854],
                [106.0469424, 10.2458394],
                [106.0451584, 10.2464253],
                [106.042317, 10.2473363],
                [106.0400038, 10.2483787],
                [106.0382187, 10.2496181],
                [106.0368961, 10.250946],
                [106.0356695, 10.2526443],
                [106.0345257, 10.2548509],
                [106.0334276, 10.2574521],
                [106.0367264, 10.2589158],
                [106.0349295, 10.2635207],
                [106.0349026, 10.264674],
                [106.034857, 10.265066],
                [106.0348852, 10.2653259],
                [106.0349549, 10.2656849],
                [106.034967, 10.2658353],
                [106.0349422, 10.2659824],
                [106.0349013, 10.2661111],
                [106.0348302, 10.2662286],
                [106.0347524, 10.2663368],
                [106.0347095, 10.2664819],
                [106.0347202, 10.2666297],
                [106.0347792, 10.2667775],
                [106.0348329, 10.2671404],
                [106.0344225, 10.2672288],
                [106.0350884, 10.2719841],
                [106.0348225, 10.2721444],
                [106.0346961, 10.2721959],
                [106.0345821, 10.2721985],
                [106.0344896, 10.272155],
                [106.0343823, 10.2720507],
                [106.0342401, 10.2718858],
                [106.0340926, 10.2718119],
                [106.0338888, 10.2717736],
                [106.0336447, 10.2718264],
                [106.0333899, 10.2719425],
                [106.0332155, 10.2720903],
                [106.0329983, 10.2723938],
                [106.0328333, 10.2724836],
                [106.0326925, 10.2725086],
                [106.0325691, 10.2724941],
                [106.0324095, 10.2723978],
                [106.0322405, 10.2723318],
                [106.032093, 10.2723226],
                [106.031743, 10.2723622],
                [106.0312602, 10.2724941],
                [106.0308632, 10.2726789],
                [106.0306379, 10.2728478],
                [106.0305414, 10.2729085],
                [106.0303134, 10.2729692],
                [106.0300666, 10.2730365],
                [106.0296696, 10.2731566],
                [106.029439, 10.273175],
                [106.0283983, 10.2730959],
                [106.027725, 10.2731328],
                [106.0275722, 10.2731882],
                [106.0275373, 10.2732384],
                [106.0275158, 10.2734812],
                [106.0277009, 10.273658],
                [106.0278726, 10.273691],
                [106.0279718, 10.2739298],
                [106.0293263, 10.2773291],
                [106.0299245, 10.2787569],
                [106.029738, 10.2788493],
                [106.0294336, 10.2791158],
                [106.0292485, 10.2792808],
                [106.029101, 10.279352],
                [106.0291171, 10.2794273],
                [106.0291895, 10.2796621],
                [106.0292271, 10.2798442],
                [106.0289803, 10.2799524],
                [106.0285941, 10.2802005],
                [106.0285832, 10.2802093],
                [106.0283715, 10.28038],
                [106.0282803, 10.280504],
                [106.0282266, 10.2806228],
                [106.0281944, 10.2808339],
                [106.0281837, 10.2810952],
                [106.0281998, 10.2813222],
                [106.0282829, 10.2817603],
                [106.0283124, 10.281974],
                [106.028287, 10.2821258],
                [106.0281421, 10.2825639],
                [106.0280697, 10.2827275],
                [106.0280228, 10.2828001],
                [106.0279745, 10.2828621],
                [106.0278216, 10.282998],
                [106.0277747, 10.2830759],
                [106.0277425, 10.2831788],
                [106.0277371, 10.2832764],
                [106.0277572, 10.2833754],
                [106.0278068, 10.283444],
                [106.0278618, 10.283477],
                [106.0279718, 10.2834704],
                [106.028004, 10.2834731],
                [106.0280348, 10.2834902],
                [106.0280845, 10.283642],
                [106.0282105, 10.2840167],
                [106.0288382, 10.2858443],
                [106.0286799, 10.2863312],
                [106.0298292, 10.2867456],
                [106.0301484, 10.2869646],
                [106.0296375, 10.2883455],
                [106.0291969, 10.2896842],
                [106.0291915, 10.2897389],
                [106.0292043, 10.2897891],
                [106.0292472, 10.2898372],
                [106.0295892, 10.2900932],
                [106.0296194, 10.2901437],
                [106.0296395, 10.290215],
                [106.0296435, 10.2902826],
                [106.0296489, 10.2906672],
                [106.0296639, 10.2907197],
                [106.0296986, 10.2907682],
                [106.0300706, 10.2912373],
                [106.0304502, 10.2917967],
                [106.0304549, 10.2919742],
                [106.0308827, 10.2924519],
                [106.0311462, 10.2923371],
                [106.0312159, 10.2923298],
                [106.0312575, 10.2923509],
                [106.0315083, 10.2926373],
                [106.0314265, 10.2927415],
                [106.0313997, 10.292826],
                [106.031405, 10.2929078],
                [106.0314292, 10.2929949],
                [106.0316303, 10.2932086],
                [106.0317262, 10.2933445],
                [106.0346526, 10.2986187],
                [106.0335094, 10.2993397],
                [106.0323713, 10.3000784],
                [106.0312599, 10.3008664],
                [106.0301536, 10.3016615],
                [106.0290527, 10.3024637],
                [106.0279109, 10.3033072],
                [106.0267749, 10.3041583],
                [106.0264376, 10.3044146],
                [106.0256448, 10.305017],
                [106.0245206, 10.3058832],
                [106.0239476, 10.3063309],
                [106.0234024, 10.3067569],
                [106.0222903, 10.307638],
                [106.0211842, 10.3085264],
                [106.0200843, 10.3094222],
                [106.0189906, 10.3103254],
                [106.0180355, 10.311125],
                [106.0179032, 10.3112358],
                [106.016822, 10.3121534],
                [106.0157471, 10.3130782],
                [106.0146787, 10.3140101],
                [106.0136167, 10.3149491],
                [106.0120557, 10.3162783],
                [106.011971, 10.3163504],
                [106.00655, 10.3202856],
                [106.0020489, 10.3235529],
                [105.9984159, 10.3261902],
                [105.9951137, 10.3285872],
                [105.9942594, 10.3292073],
                [105.9940192, 10.3294008],
                [105.9937731, 10.3295868],
                [105.9935213, 10.3297653],
                [105.9932639, 10.3299361],
                [105.9930013, 10.3300989],
                [105.9927337, 10.3302537],
                [105.9924614, 10.3304002],
                [105.9921846, 10.3305384],
                [105.9919036, 10.3306681],
                [105.9916186, 10.3307892],
                [105.99133, 10.3309015],
                [105.991038, 10.3310051],
                [105.9907428, 10.3310996],
                [105.9904454, 10.331185],
                [105.9901454, 10.3312614],
                [105.990009, 10.3312917],
                [105.9898432, 10.3313286],
                [105.9895391, 10.3313865],
                [105.9892332, 10.3314352],
                [105.988926, 10.3314746],
                [105.9886177, 10.3315047],
                [105.9883086, 10.3315253],
                [105.9881046, 10.3315327],
                [105.987999, 10.3315366],
                [105.9876891, 10.3315384],
                [105.9873794, 10.3315309],
                [105.9870701, 10.3315139],
                [105.9867614, 10.3314876],
                [105.9864537, 10.3314519],
                [105.9861473, 10.3314068],
                [105.985778, 10.3313525],
                [105.9854146, 10.3312753],
                [105.9853419, 10.3312598],
                [105.984843, 10.3311594],
                [105.9843458, 10.3310511],
                [105.9838504, 10.330935],
                [105.9833569, 10.3308111],
                [105.9828655, 10.3306795],
                [105.9823763, 10.3305402],
                [105.9822312, 10.3304964],
                [105.9818894, 10.3303933],
                [105.981405, 10.3302387],
                [105.9809232, 10.3300766],
                [105.9804319, 10.3299025],
                [105.9799436, 10.3297205],
                [105.9794583, 10.3295307],
                [105.9790516, 10.329364],
                [105.9789763, 10.3293331],
                [105.9784977, 10.3291277],
                [105.9780225, 10.3289147],
                [105.977551, 10.3286941],
                [105.9770831, 10.3284659],
                [105.9766191, 10.3282302],
                [105.9761592, 10.3279871],
                [105.9758647, 10.3278062],
                [105.9755743, 10.3276191],
                [105.9753238, 10.32745],
                [105.9750062, 10.3272267],
                [105.9747078, 10.3270057],
                [105.9744148, 10.3267778],
                [105.9741271, 10.3265434],
                [105.9738452, 10.3263024],
                [105.9729253, 10.3255739],
                [105.9723255, 10.3250907],
                [105.9720117, 10.3248379],
                [105.9711043, 10.3240944],
                [105.9702033, 10.3233435],
                [105.9693087, 10.3225852],
                [105.9684205, 10.3218196],
                [105.9675389, 10.3210467],
                [105.9666639, 10.3202665],
                [105.9657955, 10.3194792],
                [105.9649653, 10.3187141],
                [105.9641413, 10.3179424],
                [105.9633237, 10.3171642],
                [105.9630769, 10.3169254],
                [105.9625125, 10.3163795],
                [105.9617078, 10.3155885],
                [105.9609095, 10.3147911],
                [105.9601178, 10.3139874],
                [105.9593327, 10.3131775],
                [105.9591753, 10.3130227],
                [105.9569316, 10.3108163],
                [105.9553095, 10.3093309],
                [105.9543458, 10.3084558],
                [105.953681, 10.3078522],
                [105.9520463, 10.3063802],
                [105.9517215, 10.3060902],
                [105.9504052, 10.3049151],
                [105.9502828, 10.3048067],
                [105.9487578, 10.3034568],
                [105.9476638, 10.3024966],
                [105.9471042, 10.3020054],
                [105.9454444, 10.3005608],
                [105.9453209, 10.3004542],
                [105.9437783, 10.2991231],
                [105.943187, 10.2986171],
                [105.9421062, 10.2976924],
                [105.9405373, 10.2963612],
                [105.9389632, 10.295036],
                [105.9373837, 10.293717],
                [105.9359859, 10.292559],
                [105.935799, 10.2924041],
                [105.934209, 10.2910973],
                [105.9326138, 10.2897968],
                [105.9310133, 10.2885024],
                [105.9294077, 10.2872143],
                [105.9277969, 10.2859324],
                [105.9262132, 10.2846726],
                [105.9259593, 10.284497],
                [105.9256419, 10.2842942],
                [105.9250978, 10.2839388],
                [105.9245488, 10.2835909],
                [105.9239949, 10.2832504],
                [105.9234364, 10.2829175],
                [105.9228733, 10.2825921],
                [105.9223056, 10.2822743],
                [105.9217336, 10.2819643],
                [105.9211574, 10.281662],
                [105.9205769, 10.2813676],
                [105.9199924, 10.281081],
                [105.919404, 10.2808023],
                [105.918817, 10.280534],
                [105.9182264, 10.2802736],
                [105.9176322, 10.2800211],
                [105.9170346, 10.2797766],
                [105.9164336, 10.2795401],
                [105.9158295, 10.2793117],
                [105.9152222, 10.2790914],
                [105.9146119, 10.2788793],
                [105.9134043, 10.2784374],
                [105.9035388, 10.2746427],
                [105.9033029, 10.2745605],
                [105.9030644, 10.2744856],
                [105.9028236, 10.2744181],
                [105.9025808, 10.2743581],
                [105.9023363, 10.2743056],
                [105.9020901, 10.2742607],
                [105.9018427, 10.2742234],
                [105.9015941, 10.2741938],
                [105.9014051, 10.2741765],
                [105.9012156, 10.2741636],
                [105.9008365, 10.2741424],
                [105.9004569, 10.2741304],
                [105.900111, 10.2741274],
                [105.8997651, 10.274132],
                [105.8994195, 10.2741441],
                [105.8991045, 10.2741603],
                [105.8987902, 10.2741858],
                [105.8984769, 10.2742206],
                [105.8981647, 10.2742646],
                [105.897854, 10.2743178],
                [105.8975451, 10.2743802],
                [105.8972382, 10.2744517],
                [105.8969336, 10.2745322],
                [105.8966317, 10.2746217],
                [105.8963326, 10.27472],
                [105.8960366, 10.2748272],
                [105.8957462, 10.2749421],
                [105.8954595, 10.2750656],
                [105.8951766, 10.2751973],
                [105.8948978, 10.2753373],
                [105.8946234, 10.2754855],
                [105.8943536, 10.2756416],
                [105.8940887, 10.2758055],
                [105.8883509, 10.2701727],
                [105.8834595, 10.2663314],
                [105.8813808, 10.2653255],
                [105.8796456, 10.264758],
                [105.8775506, 10.2643236],
                [105.877492, 10.2634728],
                [105.877492, 10.2632966],
                [105.8775679, 10.2631323],
                [105.8777071, 10.2630107],
                [105.8781459, 10.2627225],
                [105.8782876, 10.2625947],
                [105.8783873, 10.2624374],
                [105.8784195, 10.2622611],
                [105.8784195, 10.2620532],
                [105.8784463, 10.2619465],
                [105.8785171, 10.2618589],
                [105.8787598, 10.2617097],
                [105.8790817, 10.2614508],
                [105.8797645, 10.2607222],
                [105.8800224, 10.2604564],
                [105.8802561, 10.2602698],
                [105.8808114, 10.2599966],
                [105.8809191, 10.2599229],
                [105.8809696, 10.2598351],
                [105.8809702, 10.2597366],
                [105.8809005, 10.2593829],
                [105.8809037, 10.259309],
                [105.8809369, 10.2592584],
                [105.8810035, 10.2592193],
                [105.8811386, 10.2591718],
                [105.8812084, 10.2591232],
                [105.8812513, 10.2590525],
                [105.8813852, 10.2586842],
                [105.8815151, 10.2584747],
                [105.8815656, 10.2582155],
                [105.8816198, 10.2580859],
                [105.8817154, 10.2580042],
                [105.881876, 10.2578426],
                [105.8819915, 10.2576526],
                [105.8822008, 10.25693],
                [105.8822524, 10.2567987],
                [105.8823596, 10.2567221],
                [105.8824757, 10.2566719],
                [105.8825892, 10.2566681],
                [105.8827092, 10.2567008],
                [105.8828164, 10.2567786],
                [105.8831034, 10.257144],
                [105.883547, 10.2577701],
                [105.8836096, 10.2578392],
                [105.8836963, 10.2578957],
                [105.8837984, 10.2579246],
                [105.8840625, 10.257922],
                [105.8843866, 10.2580124],
                [105.8844874, 10.258015],
                [105.8845716, 10.2579785],
                [105.8846175, 10.2579133],
                [105.8846328, 10.2578317],
                [105.8844046, 10.2570149],
                [105.8841051, 10.2555802],
                [105.8841069, 10.255392],
                [105.8841592, 10.255202],
                [105.8843595, 10.2549197],
                [105.8844046, 10.254783],
                [105.8844046, 10.2545522],
                [105.8843649, 10.2543374],
                [105.8840491, 10.2536697],
                [105.88431, 10.2536514],
                [105.8846143, 10.2536843],
                [105.8847311, 10.2537291],
                [105.884857, 10.2538919],
                [105.8849344, 10.2539217],
                [105.8850315, 10.2539083],
                [105.8851392, 10.2538501],
                [105.8852621, 10.2537217],
                [105.8853374, 10.2534264],
                [105.8856078, 10.2531577],
                [105.8860467, 10.2525802],
                [105.8863989, 10.2520529],
                [105.8865699, 10.2518746],
                [105.8867714, 10.251297],
                [105.8869447, 10.2506124],
                [105.8870042, 10.2502218],
                [105.8870015, 10.250066],
                [105.886956, 10.2499451],
                [105.8867299, 10.2496435],
                [105.8866995, 10.2495076],
                [105.8869504, 10.2493095],
                [105.8873221, 10.2490876],
                [105.8874577, 10.2486472],
                [105.8877881, 10.2480278],
                [105.8882825, 10.2472554],
                [105.8889194, 10.2464759],
                [105.8893589, 10.2460016],
                [105.8898668, 10.2456711],
                [105.8903489, 10.2447431],
                [105.890236, 10.2442988],
                [105.8905001, 10.2442838],
                [105.8908655, 10.244309],
                [105.8911585, 10.2441499],
                [105.8915312, 10.2440271],
                [105.8918949, 10.243938],
                [105.8920985, 10.244609],
                [105.8924387, 10.2443292],
                [105.8924927, 10.2442966],
                [105.8925432, 10.2442829],
                [105.8925883, 10.2442888],
                [105.8927595, 10.2443717],
                [105.8928248, 10.2443874],
                [105.8928953, 10.2443874],
                [105.8930675, 10.244371],
                [105.8931358, 10.2443724],
                [105.8932018, 10.2443866],
                [105.893327, 10.2444202],
                [105.8933915, 10.244424],
                [105.8934446, 10.2444113],
                [105.8935318, 10.2443971],
                [105.8941622, 10.2443635],
                [105.8957, 10.2445791],
                [105.8966943, 10.2448898],
                [105.8987801, 10.2453213],
                [105.8992853, 10.2453337],
                [105.8998537, 10.2452733],
                [105.9014865, 10.2450309],
                [105.9020007, 10.2449179],
                [105.9015693, 10.238896],
                [105.9012009, 10.2342778],
                [105.9013952, 10.2342392],
                [105.9017237, 10.2338663],
                [105.9028248, 10.233144],
                [105.9061501, 10.2308279],
                [105.9110711, 10.2273634],
                [105.9123139, 10.2265566],
                [105.9126809, 10.2256613],
                [105.9129247, 10.2256868],
                [105.9128458, 10.2253216],
                [105.9128519, 10.2250826],
                [105.9129778, 10.2248408],
                [105.9130795, 10.2243883],
                [105.913567, 10.2233946],
                [105.9146856, 10.2207006],
                [105.9154632, 10.2188722],
                [105.9158204, 10.2188021],
                [105.9157627, 10.2185094],
                [105.9157384, 10.2182944],
                [105.9157809, 10.2180704],
                [105.9179008, 10.214054],
                [105.9180939, 10.2139315],
                [105.9182585, 10.2137381],
                [105.9183899, 10.2133966],
                [105.9186583, 10.2128297],
                [105.9188662, 10.2122324],
                [105.9195215, 10.2108798],
                [105.9175833, 10.2053562],
                [105.9149791, 10.1976048],
                [105.913151, 10.1924126],
                [105.9111593, 10.1922623],
                [105.9041722, 10.1919204],
                [105.8994682, 10.191692],
                [105.8977484, 10.1893255],
                [105.8966974, 10.1878013],
                [105.8951045, 10.1855625],
                [105.8942233, 10.186382],
                [105.8937512, 10.1866758],
                [105.8931158, 10.1869018],
                [105.8922431, 10.187045],
                [105.8915975, 10.1870525],
                [105.8913602, 10.1871229],
                [105.8906559, 10.1874845],
                [105.8896173, 10.1883058],
                [105.8895813, 10.1883762],
                [105.8895641, 10.1885131],
                [105.8895297, 10.188584],
                [105.8894435, 10.1886682],
                [105.889249, 10.1887655],
                [105.8890142, 10.1887956],
                [105.8890054, 10.1885164],
                [105.8889462, 10.1882954],
                [105.8888324, 10.1880953],
                [105.8886397, 10.1879146],
                [105.8883074, 10.1877578],
                [105.8875973, 10.1875442],
                [105.8865953, 10.1870768],
                [105.8862483, 10.1869839],
                [105.8859676, 10.1869889],
                [105.8856052, 10.187112],
                [105.8852301, 10.1874335],
                [105.8847402, 10.187657],
                [105.8833087, 10.188857],
                [105.8828615, 10.1886973],
                [105.8827084, 10.188666],
                [105.8825297, 10.1886672],
                [105.8761437, 10.1891533],
                [105.8760612, 10.1873715],
                [105.875963, 10.1856717],
                [105.8759553, 10.1848705],
                [105.8759755, 10.1847578],
                [105.8760423, 10.1846622],
                [105.8762152, 10.1845233],
                [105.8762835, 10.1844173],
                [105.8764413, 10.1839976],
                [105.8768965, 10.1833107],
                [105.8771528, 10.1827544],
                [105.8773296, 10.1822749],
                [105.8770157, 10.1822305],
                [105.8767107, 10.182131],
                [105.8764654, 10.181973],
                [105.8762741, 10.1817865],
                [105.876137, 10.1815361],
                [105.8756653, 10.1803621],
                [105.8750903, 10.1790126],
                [105.8747547, 10.1784534],
                [105.874522, 10.1778153],
                [105.873932, 10.1766573],
                [105.8738084, 10.1763629],
                [105.8735808, 10.1759253],
                [105.8734671, 10.1755893],
                [105.8733775, 10.1753668],
                [105.8730953, 10.174983],
                [105.8728844, 10.1745081],
                [105.8726932, 10.173963],
                [105.8718755, 10.172509],
                [105.8701783, 10.1687133],
                [105.8742635, 10.1663131],
                [105.8717881, 10.1584512],
                [105.8705288, 10.154436],
                [105.8675002, 10.1558014],
                [105.8669457, 10.1546382],
                [105.8666893, 10.1538422],
                [105.8646417, 10.1545292],
                [105.8639507, 10.1547827],
                [105.8638992, 10.1545841],
                [105.862659, 10.155015],
                [105.8615646, 10.1555599],
                [105.8515707, 10.1600683],
                [105.8510883, 10.1582728],
                [105.8500772, 10.154097],
                [105.8494649, 10.1517405],
                [105.8490918, 10.1500535],
                [105.8477123, 10.1499658],
                [105.8431192, 10.1499163],
                [105.839937, 10.1498224],
                [105.8350581, 10.1497012],
                [105.8335263, 10.1496161],
                [105.8325565, 10.1496084],
                [105.8319173, 10.1495202],
                [105.8312373, 10.1494385],
                [105.8310711, 10.1493706],
                [105.8309732, 10.149274],
                [105.8308966, 10.1491304],
                [105.8308114, 10.1490589],
                [105.8306866, 10.1490602],
                [105.8305668, 10.1491232],
                [105.8303464, 10.1492961],
                [105.8301387, 10.1493855],
                [105.8298578, 10.1494542],
                [105.8295992, 10.1494525],
                [105.8293581, 10.1493647],
                [105.8290837, 10.149193],
                [105.8288632, 10.1489452],
                [105.8286809, 10.1487936],
                [105.8284837, 10.1487518],
                [105.828304, 10.1487845],
                [105.8281282, 10.1488904],
                [105.8280081, 10.149064],
                [105.8279464, 10.1492065],
                [105.8278516, 10.1492749],
                [105.8277311, 10.1492757],
                [105.827241, 10.1491505],
                [105.8263322, 10.1489946],
                [105.8250661, 10.1488965],
                [105.8203163, 10.1493732],
                [105.8183691, 10.1495087],
                [105.8170031, 10.1509943],
                [105.8154515, 10.1527461],
                [105.8086627, 10.1540154],
                [105.8057611, 10.1546431],
                [105.8042548, 10.1549599],
                [105.8021832, 10.1555999],
                [105.8016932, 10.1555637],
                [105.8014365, 10.1555694],
                [105.8004133, 10.1568254],
                [105.7986749, 10.1588752],
                [105.7985797, 10.1590281],
                [105.7985046, 10.159085],
                [105.7983158, 10.1592278],
                [105.7929599, 10.1662295],
                [105.791798, 10.1675743],
                [105.7877194, 10.1727488],
                [105.7873526, 10.1732251],
                [105.7850121, 10.1760916],
                [105.7819572, 10.1800119],
                [105.7811467, 10.180982],
                [105.7784254, 10.1843584],
                [105.774933, 10.1890101],
                [105.7744954, 10.1885178],
                [105.7739806, 10.1881036],
                [105.7707638, 10.1846468],
                [105.7684565, 10.182195],
                [105.7648957, 10.1780841],
                [105.7606511, 10.1817259],
                [105.760293, 10.1820331],
                [105.7593786, 10.1828177],
                [105.7583271, 10.1839721],
                [105.756349, 10.1866405],
                [105.7544511, 10.1893564],
                [105.7529444, 10.191259],
                [105.7505305, 10.194326],
                [105.7479652, 10.1972217],
                [105.7421648, 10.1996607],
                [105.7391853, 10.2007254],
                [105.7308356, 10.203838],
                [105.7278896, 10.20495],
                [105.7252, 10.2009716],
                [105.7229555, 10.1977299],
                [105.7206518, 10.1944512],
                [105.7186235, 10.191413],
                [105.7152772, 10.186641],
                [105.713541, 10.1840982],
                [105.7101501, 10.1792025],
                [105.7089254, 10.1773433],
                [105.7057472, 10.1727421],
                [105.7044752, 10.1708793],
                [105.7019712, 10.1672162],
                [105.6993978, 10.163459],
                [105.6984446, 10.1620838],
                [105.6966712, 10.1595145],
                [105.6959892, 10.1584797],
                [105.6946827, 10.1565549],
                [105.6932756, 10.1545345],
                [105.69202, 10.1528077],
                [105.6899266, 10.1499042],
                [105.6863771, 10.1449795],
                [105.6853958, 10.1436234],
                [105.6852624, 10.1433595],
                [105.6851836, 10.143024],
                [105.6850848, 10.1422139],
                [105.6849437, 10.1412535],
                [105.6853527, 10.1407296],
                [105.6865744, 10.1393743],
                [105.6866572, 10.1392184],
                [105.6866578, 10.1390707],
                [105.686594, 10.1388956],
                [105.6858411, 10.138182],
                [105.6847891, 10.1372919],
                [105.6818716, 10.1347692],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.3224806, 21.4976045],
                [105.3225611, 21.4967579],
                [105.3228878, 21.4957823],
                [105.3233789, 21.4947039],
                [105.3239796, 21.4936765],
                [105.3253462, 21.491878],
                [105.3271514, 21.4901045],
                [105.3283271, 21.4887681],
                [105.329147, 21.4877147],
                [105.3304602, 21.486609],
                [105.331868, 21.4849294],
                [105.3328788, 21.4834386],
                [105.3332605, 21.4825393],
                [105.3334772, 21.4814095],
                [105.3338028, 21.4799712],
                [105.3339363, 21.4791634],
                [105.3342759, 21.4771082],
                [105.33445, 21.475298],
                [105.3343664, 21.4747078],
                [105.3343378, 21.4741945],
                [105.3344458, 21.4734755],
                [105.3347179, 21.4726279],
                [105.3353185, 21.4715486],
                [105.3358643, 21.4705207],
                [105.3358656, 21.4705178],
                [105.3367096, 21.4686963],
                [105.337255, 21.4674631],
                [105.3375817, 21.4665127],
                [105.3379761, 21.4652412],
                [105.3383844, 21.4640339],
                [105.3386014, 21.4630579],
                [105.3388188, 21.4622619],
                [105.3389811, 21.4613117],
                [105.3389803, 21.460978],
                [105.3391971, 21.4599507],
                [105.3391691, 21.4596684],
                [105.3393983, 21.4580636],
                [105.3398321, 21.4560347],
                [105.3401298, 21.4544426],
                [105.3404555, 21.4530302],
                [105.341258, 21.4505513],
                [105.3417479, 21.4490615],
                [105.3420749, 21.4482907],
                [105.3422011, 21.4476932],
                [105.3424001, 21.4467498],
                [105.3425086, 21.446262],
                [105.3430646, 21.4438093],
                [105.3431992, 21.4427822],
                [105.3430944, 21.4387943],
                [105.3427669, 21.4371497],
                [105.3425332, 21.4360679],
                [105.3421127, 21.4342502],
                [105.3418308, 21.4323024],
                [105.3413633, 21.4301818],
                [105.3411724, 21.4275844],
                [105.341169, 21.4261123],
                [105.3413055, 21.4252028],
                [105.3417457, 21.4246237],
                [105.3418805, 21.4236737],
                [105.342426, 21.42262],
                [105.3430808, 21.4214122],
                [105.3437362, 21.4204612],
                [105.344392, 21.4197409],
                [105.3454035, 21.4188404],
                [105.3469538, 21.4184576],
                [105.347553, 21.4183097],
                [105.3497441, 21.4179972],
                [105.3508945, 21.4178664],
                [105.3519631, 21.4179669],
                [105.3531149, 21.4183751],
                [105.3543787, 21.4198613],
                [105.3558647, 21.4225278],
                [105.3567165, 21.4235528],
                [105.357952, 21.424577],
                [105.3613417, 21.4270469],
                [105.3631809, 21.4284291],
                [105.365239, 21.4296824],
                [105.367461, 21.4307814],
                [105.3688521, 21.4314674],
                [105.3688602, 21.4314714],
                [105.3703283, 21.4323281],
                [105.3716176, 21.4329158],
                [105.3720113, 21.4330877],
                [105.3738394, 21.4338861],
                [105.3758128, 21.4341128],
                [105.3774567, 21.4340321],
                [105.3788801, 21.4335155],
                [105.3803855, 21.432896],
                [105.3828069, 21.4315171],
                [105.3849681, 21.4302543],
                [105.3872113, 21.4288886],
                [105.388661, 21.4279612],
                [105.389317, 21.4273436],
                [105.390601, 21.4259801],
                [105.391406, 21.4247332],
                [105.3917328, 21.4239881],
                [105.3927968, 21.4223171],
                [105.393697, 21.420826],
                [105.3945429, 21.4195406],
                [105.394761, 21.419155],
                [105.3962479, 21.4168027],
                [105.3968548, 21.4160023],
                [105.3973398, 21.4153626],
                [105.3978302, 21.4143347],
                [105.3981291, 21.4134354],
                [105.3982644, 21.4127934],
                [105.3984546, 21.4122538],
                [105.3985698, 21.4105448],
                [105.3985732, 21.4104951],
                [105.3986508, 21.4088008],
                [105.3985377, 21.4074919],
                [105.3980942, 21.4056191],
                [105.3976376, 21.403913],
                [105.3973612, 21.4030153],
                [105.3968363, 21.4013479],
                [105.3961465, 21.399527],
                [105.3958427, 21.3986293],
                [105.3952488, 21.3967697],
                [105.3946978, 21.3956158],
                [105.3938996, 21.3942315],
                [105.3934585, 21.3931543],
                [105.3931822, 21.3922565],
                [105.3930719, 21.3919744],
                [105.3927922, 21.3914196],
                [105.3927551, 21.3913463],
                [105.392369, 21.3903461],
                [105.3920376, 21.38932],
                [105.391816, 21.3883964],
                [105.3916494, 21.3875497],
                [105.3915381, 21.3868826],
                [105.3921819, 21.3715047],
                [105.3921251, 21.3707067],
                [105.3919637, 21.366692],
                [105.3939344, 21.3611298],
                [105.3944793, 21.3600761],
                [105.3955707, 21.3585846],
                [105.3960077, 21.3581216],
                [105.3967296, 21.3566952],
                [105.3977669, 21.355435],
                [105.3988316, 21.3542259],
                [105.3996781, 21.3533254],
                [105.4005515, 21.3522452],
                [105.4012607, 21.3512167],
                [105.4021754, 21.3502262],
                [105.4035147, 21.3493246],
                [105.4050722, 21.3480629],
                [105.4061652, 21.3471875],
                [105.4074225, 21.3463373],
                [105.4084615, 21.34577],
                [105.4110589, 21.3443263],
                [105.4131767, 21.3427424],
                [105.4153916, 21.3416074],
                [105.4184543, 21.3401366],
                [105.4202321, 21.3394134],
                [105.4212177, 21.3393339],
                [105.4220168, 21.3391215],
                [105.4227772, 21.3389194],
                [105.424815, 21.3381312],
                [105.4264837, 21.3375622],
                [105.4299024, 21.3361673],
                [105.4319533, 21.3352123],
                [105.4332925, 21.3343874],
                [105.4342761, 21.3336404],
                [105.4346655, 21.333187],
                [105.4356425, 21.3321896],
                [105.4378619, 21.3313838],
                [105.440668, 21.3300189],
                [105.4450912, 21.3261811],
                [105.4471914, 21.3237894],
                [105.4484147, 21.3217703],
                [105.4485937, 21.3213902],
                [105.4489382, 21.3206582],
                [105.4497246, 21.3193807],
                [105.4505493, 21.3178774],
                [105.4532443, 21.3153995],
                [105.454893, 21.3132096],
                [105.4555315, 21.3120993],
                [105.4566217, 21.3096257],
                [105.4568215, 21.3087066],
                [105.456717, 21.3076616],
                [105.4564077, 21.3059838],
                [105.4556923, 21.3039903],
                [105.4547087, 21.3026942],
                [105.4533358, 21.301146],
                [105.4518462, 21.3000729],
                [105.4506964, 21.2996643],
                [105.450482, 21.2996523],
                [105.4503022, 21.2996422],
                [105.4500011, 21.2991431],
                [105.4497176, 21.2986731],
                [105.449657, 21.2977967],
                [105.4495266, 21.2975016],
                [105.4492829, 21.2967558],
                [105.4492242, 21.2962735],
                [105.4492447, 21.2959759],
                [105.4493542, 21.2956677],
                [105.4497127, 21.2952454],
                [105.4503533, 21.2947429],
                [105.4511894, 21.2941379],
                [105.452149, 21.2935535],
                [105.4525832, 21.2933697],
                [105.4536632, 21.2930633],
                [105.4535527, 21.2924814],
                [105.4535357, 21.2920409],
                [105.4534278, 21.2914348],
                [105.4533434, 21.2906316],
                [105.4532324, 21.290162],
                [105.4530441, 21.2888959],
                [105.4529459, 21.2882994],
                [105.452437, 21.2859431],
                [105.4522607, 21.285075],
                [105.4522374, 21.284842],
                [105.4515749, 21.2844493],
                [105.4508397, 21.2834631],
                [105.4503674, 21.2822839],
                [105.4497484, 21.2809954],
                [105.4495711, 21.2805017],
                [105.449481, 21.2797332],
                [105.4492996, 21.2778945],
                [105.4491202, 21.2766507],
                [105.4491714, 21.2742056],
                [105.4491384, 21.2730243],
                [105.4489907, 21.2726126],
                [105.4485492, 21.2718995],
                [105.448372, 21.271378],
                [105.4482522, 21.2704854],
                [105.4483645, 21.2689192],
                [105.4485349, 21.267133],
                [105.4485905, 21.2661714],
                [105.4485083, 21.2647123],
                [105.4445895, 21.2646891],
                [105.4433957, 21.2644046],
                [105.4440035, 21.2602582],
                [105.4441046, 21.2578166],
                [105.4441717, 21.2563163],
                [105.4442189, 21.2552605],
                [105.4445627, 21.2523859],
                [105.4447492, 21.2512919],
                [105.4448011, 21.2509985],
                [105.4451517, 21.2490186],
                [105.445567, 21.2470016],
                [105.4460114, 21.2453681],
                [105.4468477, 21.2433124],
                [105.4474066, 21.2424158],
                [105.4481062, 21.2416242],
                [105.4492268, 21.2407261],
                [105.4502701, 21.2395016],
                [105.4507654, 21.2388002],
                [105.4513468, 21.2375536],
                [105.4514907, 21.237245],
                [105.4528889, 21.2353457],
                [105.4536154, 21.2341854],
                [105.4553733, 21.2305476],
                [105.4564893, 21.2282016],
                [105.4578027, 21.2261971],
                [105.4593052, 21.2238154],
                [105.4602076, 21.222283],
                [105.4603387, 21.2220604],
                [105.4607599, 21.2211651],
                [105.4611427, 21.2200548],
                [105.4620671, 21.2160498],
                [105.4623781, 21.214145],
                [105.4618999, 21.2139478],
                [105.4616418, 21.213715],
                [105.4615366, 21.2133746],
                [105.4616515, 21.2128195],
                [105.4623789, 21.2109212],
                [105.4625705, 21.2099004],
                [105.462609, 21.2090228],
                [105.4618248, 21.2087899],
                [105.4620928, 21.2077154],
                [105.462246, 21.2070527],
                [105.4639867, 21.2072144],
                [105.4640826, 21.2063189],
                [105.4643892, 21.2041339],
                [105.4643894, 21.2033281],
                [105.4641215, 21.2031667],
                [105.4635668, 21.2031488],
                [105.46372, 21.2024862],
                [105.4638541, 21.2020743],
                [105.463988, 21.2017878],
                [105.4644281, 21.2011431],
                [105.4647342, 21.2007492],
                [105.4649829, 21.2006059],
                [105.4652508, 21.2005702],
                [105.4666854, 21.2005525],
                [105.4683828, 21.1888634],
                [105.4686811, 21.1855454],
                [105.4691242, 21.1836487],
                [105.4698484, 21.1818564],
                [105.4699585, 21.1811717],
                [105.4699563, 21.1804871],
                [105.4695683, 21.1796168],
                [105.4693818, 21.1789103],
                [105.4694716, 21.1773742],
                [105.4695613, 21.1766479],
                [105.4698751, 21.1761782],
                [105.4720175, 21.1742086],
                [105.4740085, 21.1726708],
                [105.4748135, 21.1720491],
                [105.4766063, 21.1709383],
                [105.4821369, 21.1665155],
                [105.4836739, 21.1655141],
                [105.4865039, 21.1640577],
                [105.4870201, 21.1637921],
                [105.492218, 21.1609418],
                [105.4943997, 21.1600933],
                [105.4961935, 21.1595547],
                [105.4984913, 21.1589081],
                [105.5000206, 21.158687],
                [105.5018817, 21.1583726],
                [105.5030158, 21.1582282],
                [105.5040872, 21.1580918],
                [105.5046702, 21.1580176],
                [105.506302, 21.1577305],
                [105.5070458, 21.1575952],
                [105.5074991, 21.1575366],
                [105.5102776, 21.1572032],
                [105.5152901, 21.1564642],
                [105.5155663, 21.1564173],
                [105.5165294, 21.1562537],
                [105.5186449, 21.1560732],
                [105.5214269, 21.1557544],
                [105.5267469, 21.1556001],
                [105.5278885, 21.1555845],
                [105.5341467, 21.1559042],
                [105.537304, 21.1561959],
                [105.5429836, 21.1565946],
                [105.5462537, 21.156837],
                [105.5523094, 21.1570881],
                [105.5536712, 21.1574948],
                [105.5580083, 21.1578792],
                [105.5606531, 21.1582637],
                [105.5647673, 21.1588676],
                [105.5667213, 21.1591788],
                [105.5670285, 21.1592283],
                [105.5671771, 21.159252],
                [105.5690801, 21.1597313],
                [105.5707526, 21.160188],
                [105.5706938, 21.1608532],
                [105.5731129, 21.161591],
                [105.5734172, 21.1616838],
                [105.5736537, 21.1611292],
                [105.5750747, 21.1616276],
                [105.5763925, 21.1629715],
                [105.5759486, 21.1630547],
                [105.5759067, 21.1632253],
                [105.5763338, 21.1640525],
                [105.5775529, 21.1643264],
                [105.5797278, 21.1648763],
                [105.5818439, 21.1652608],
                [105.5840776, 21.1657556],
                [105.5901088, 21.1673278],
                [105.5902792, 21.1673499],
                [105.5903855, 21.1673751],
                [105.5951583, 21.1685041],
                [105.6000641, 21.1696631],
                [105.6023102, 21.1719646],
                [105.6049652, 21.1745186],
                [105.6089401, 21.1765423],
                [105.6173096, 21.1791248],
                [105.6220322, 21.1781864],
                [105.6258469, 21.1755333],
                [105.6263194, 21.1733562],
                [105.6266461, 21.1752571],
                [105.6268536, 21.1762565],
                [105.6268198, 21.1769737],
                [105.6265709, 21.1775134],
                [105.6260706, 21.1780205],
                [105.6250338, 21.1789337],
                [105.6245335, 21.1794407],
                [105.6243914, 21.179778],
                [105.6243571, 21.1801823],
                [105.6245389, 21.1807204],
                [105.624828, 21.1811909],
                [105.62526, 21.1815261],
                [105.6255834, 21.1816259],
                [105.6259422, 21.1816246],
                [105.6267308, 21.1813859],
                [105.6274472, 21.1810802],
                [105.6279487, 21.1808763],
                [105.6281021, 21.1808565],
                [105.6281686, 21.1809052],
                [105.6283, 21.1810581],
                [105.6284975, 21.1814252],
                [105.6286631, 21.1819458],
                [105.6287985, 21.183049],
                [105.6289631, 21.1833856],
                [105.6298155, 21.1840877],
                [105.628871, 21.1847657],
                [105.6274369, 21.1855682],
                [105.6259048, 21.186371],
                [105.6258196, 21.1864516],
                [105.6253354, 21.1869096],
                [105.624913, 21.1874631],
                [105.624654, 21.188016],
                [105.6246919, 21.1892728],
                [105.6247918, 21.1897323],
                [105.6248256, 21.1899776],
                [105.6246661, 21.1908979],
                [105.6245556, 21.1917994],
                [105.6245081, 21.1921862],
                [105.6243489, 21.1931679],
                [105.6241712, 21.1936438],
                [105.6236502, 21.1940442],
                [105.6220186, 21.1945101],
                [105.6219681, 21.1945911],
                [105.6218972, 21.1947047],
                [105.6221014, 21.1951628],
                [105.6220613, 21.1958501],
                [105.6217985, 21.1965958],
                [105.6219011, 21.1976686],
                [105.6222272, 21.19921],
                [105.6223917, 21.2009662],
                [105.6202311, 21.2018459],
                [105.619875, 21.2014931],
                [105.6194148, 21.2009684],
                [105.6188032, 21.2011597],
                [105.6189259, 21.2015032],
                [105.6188853, 21.2017323],
                [105.6186849, 21.2018808],
                [105.616623, 21.2021726],
                [105.6164271, 21.2022346],
                [105.6164996, 21.2029],
                [105.6164707, 21.2038195],
                [105.6163053, 21.2051509],
                [105.6161519, 21.2058647],
                [105.616017, 21.2059922],
                [105.6158193, 21.2060515],
                [105.6152562, 21.2059265],
                [105.6151426, 21.2066438],
                [105.6151775, 21.2071953],
                [105.6154084, 21.2076848],
                [105.6157367, 21.2080513],
                [105.6161296, 21.2082338],
                [105.6164237, 21.2082326],
                [105.6168482, 21.2081391],
                [105.6175648, 21.2075849],
                [105.6186067, 21.2066617],
                [105.6189653, 21.2064153],
                [105.6193563, 21.2061686],
                [105.6202705, 21.2059507],
                [105.6210546, 21.2059172],
                [105.621873, 21.2062514],
                [105.6219428, 21.2063476],
                [105.6224593, 21.2070403],
                [105.6227605, 21.207474],
                [105.6234728, 21.2081367],
                [105.6237119, 21.2083591],
                [105.6255879, 21.2095314],
                [105.6257107, 21.2096081],
                [105.6262685, 21.2099193],
                [105.6276598, 21.2106956],
                [105.6282819, 21.2109692],
                [105.6286096, 21.210968],
                [105.6288701, 21.210967],
                [105.6291958, 21.2106898],
                [105.6301387, 21.2095823],
                [105.630627, 21.2091205],
                [105.6310455, 21.2089363],
                [105.6315404, 21.2087185],
                [105.6328939, 21.2084152],
                [105.6341849, 21.2081259],
                [105.6363398, 21.207658],
                [105.6378738, 21.2071922],
                [105.6385108, 21.2071438],
                [105.6389365, 21.2073568],
                [105.6391337, 21.2076013],
                [105.6391669, 21.2077239],
                [105.6392158, 21.2114648],
                [105.6390543, 21.2119254],
                [105.6372631, 21.2133734],
                [105.636353, 21.214542],
                [105.6358178, 21.2155101],
                [105.6357562, 21.2157379],
                [105.6355607, 21.2164607],
                [105.635628, 21.216952],
                [105.6359567, 21.2174108],
                [105.6365466, 21.2177765],
                [105.6372658, 21.2178352],
                [105.6382461, 21.2178007],
                [105.6392582, 21.2175516],
                [105.6406931, 21.2168102],
                [105.6418346, 21.2162539],
                [105.6431394, 21.215697],
                [105.644184, 21.215417],
                [105.6453607, 21.2154431],
                [105.6466705, 21.2160514],
                [105.6472269, 21.2162334],
                [105.6487972, 21.2165952],
                [105.6508098, 21.2171855],
                [105.6531659, 21.217851],
                [105.6557193, 21.2188224],
                [105.6570955, 21.2196143],
                [105.657981, 21.2203161],
                [105.6585726, 21.2210498],
                [105.6587706, 21.2214784],
                [105.6589046, 21.2222138],
                [105.6588417, 21.2227659],
                [105.6586475, 21.223196],
                [105.6580948, 21.2238728],
                [105.6563867, 21.225612],
                [105.6549228, 21.2271816],
                [105.654369, 21.2275824],
                [105.6539115, 21.2276149],
                [105.6534857, 21.2274019],
                [105.6523689, 21.2261489],
                [105.651746, 21.225722],
                [105.6512884, 21.2256931],
                [105.650701, 21.22591],
                [105.6499195, 21.2265876],
                [105.6493002, 21.226958],
                [105.6491836, 21.2269639],
                [105.6486466, 21.2269912],
                [105.6472402, 21.2267513],
                [105.6459334, 21.2269097],
                [105.6454924, 21.2269572],
                [105.6453831, 21.2270902],
                [105.6452649, 21.2272342],
                [105.6448776, 21.2283702],
                [105.6447861, 21.2298731],
                [105.6444325, 21.2312238],
                [105.6442005, 21.2319872],
                [105.644078, 21.2323903],
                [105.6440824, 21.2334022],
                [105.6442359, 21.2338462],
                [105.6443472, 21.2341678],
                [105.6446601, 21.234734],
                [105.6462992, 21.2358316],
                [105.6476102, 21.2366545],
                [105.6493133, 21.2374147],
                [105.6506231, 21.2379615],
                [105.6514738, 21.2381423],
                [105.6524217, 21.2381386],
                [105.6527805, 21.2379839],
                [105.6534311, 21.2372761],
                [105.6539506, 21.2364767],
                [105.654341, 21.2360459],
                [105.6547315, 21.2356765],
                [105.6552199, 21.2352452],
                [105.6560029, 21.2349049],
                [105.6567538, 21.2347179],
                [105.6576621, 21.2346932],
                [105.6587311, 21.2346642],
                [105.6601039, 21.2346589],
                [105.6610471, 21.2348211],
                [105.6611507, 21.2348389],
                [105.6619689, 21.235081],
                [105.6625513, 21.2354092],
                [105.6626903, 21.2355994],
                [105.6641008, 21.2367285],
                [105.6643881, 21.2367867],
                [105.6650207, 21.2377674],
                [105.6654962, 21.2384386],
                [105.6659089, 21.2390211],
                [105.6661038, 21.2392303],
                [105.6663359, 21.2394794],
                [105.6667615, 21.239631],
                [105.6670879, 21.2395377],
                [105.6672362, 21.2393662],
                [105.6673805, 21.2391993],
                [105.6679945, 21.237633],
                [105.6683804, 21.2362209],
                [105.6685077, 21.2354537],
                [105.6683057, 21.234136],
                [105.6682716, 21.2338295],
                [105.6683026, 21.2334614],
                [105.668596, 21.2332762],
                [105.6690526, 21.2330598],
                [105.6691856, 21.233041],
                [105.6705061, 21.2328547],
                [105.6711606, 21.2330053],
                [105.6717164, 21.233287],
                [105.6718813, 21.2333705],
                [105.6723084, 21.2338594],
                [105.6729039, 21.235421],
                [105.6731483, 21.23592],
                [105.6732337, 21.2360943],
                [105.673529, 21.2363384],
                [105.6736281, 21.2363666],
                [105.6739545, 21.2364594],
                [105.6742485, 21.2364274],
                [105.6746395, 21.23615],
                [105.6750288, 21.2355044],
                [105.6752224, 21.2349518],
                [105.675253, 21.2344916],
                [105.6751859, 21.2340932],
                [105.6749877, 21.2336648],
                [105.6744623, 21.2331149],
                [105.6733141, 21.2321995],
                [105.6721993, 21.2314067],
                [105.6714124, 21.2308885],
                [105.6712151, 21.2306133],
                [105.6712793, 21.2303677],
                [105.6715722, 21.2300599],
                [105.672712, 21.2291661],
                [105.6745693, 21.2279014],
                [105.675742, 21.2270381],
                [105.6761665, 21.2269444],
                [105.6765587, 21.2269429],
                [105.6771152, 21.2271553],
                [105.6781976, 21.2279789],
                [105.6790856, 21.2291712],
                [105.6795817, 21.2304572],
                [105.6801815, 21.2329692],
                [105.680545, 21.2338264],
                [105.6809069, 21.2343156],
                [105.6816606, 21.2347419],
                [105.6823326, 21.2351838],
                [105.6829869, 21.2353037],
                [105.6841965, 21.2353601],
                [105.6856999, 21.2353235],
                [105.6866145, 21.235197],
                [105.6872998, 21.234949],
                [105.6882126, 21.2344545],
                [105.6882511, 21.2344133],
                [105.6884305, 21.2342167],
                [105.6888384, 21.2338733],
                [105.6888824, 21.2335752],
                [105.6888814, 21.2333624],
                [105.6883784, 21.2325133],
                [105.6878774, 21.2320897],
                [105.6874226, 21.2318362],
                [105.6856983, 21.2317582],
                [105.6833847, 21.2317675],
                [105.6829303, 21.2315992],
                [105.6824745, 21.2311328],
                [105.6824272, 21.2307075],
                [105.6824704, 21.2302391],
                [105.6827868, 21.2299825],
                [105.6833755, 21.2297673],
                [105.6841923, 21.2298066],
                [105.6845729, 21.2298819],
                [105.6849143, 21.2299494],
                [105.6847982, 21.2290876],
                [105.6856037, 21.2289431],
                [105.6856012, 21.2270038],
                [105.6857156, 21.226429],
                [105.6858298, 21.2260221],
                [105.6864809, 21.2248838],
                [105.6872596, 21.2236062],
                [105.6877917, 21.2225539],
                [105.687974, 21.222476],
                [105.688082, 21.2224068],
                [105.6883191, 21.222332],
                [105.6884725, 21.222224],
                [105.6889411, 21.2218075],
                [105.6888551, 21.2215053],
                [105.6888163, 21.2211104],
                [105.6888543, 21.2208949],
                [105.6890078, 21.2208229],
                [105.689369, 21.2208906],
                [105.6898221, 21.2210207],
                [105.6899663, 21.2204986],
                [105.6901195, 21.220247],
                [105.6904262, 21.2200671],
                [105.6907333, 21.2202463],
                [105.6915401, 21.221215],
                [105.6930127, 21.2212784],
                [105.693052, 21.2212047],
                [105.6937937, 21.2196067],
                [105.6943488, 21.2181852],
                [105.6952894, 21.2159916],
                [105.6958045, 21.2152596],
                [105.6961774, 21.2149067],
                [105.6968357, 21.2139577],
                [105.6973805, 21.2134149],
                [105.6982549, 21.2124787],
                [105.6986976, 21.2129152],
                [105.6987898, 21.2132128],
                [105.698688, 21.2134459],
                [105.6980268, 21.2149608],
                [105.6977625, 21.2166217],
                [105.6975445, 21.2184952],
                [105.6977721, 21.2186645],
                [105.6983168, 21.2187473],
                [105.6985601, 21.2189548],
                [105.6986068, 21.2192525],
                [105.6986593, 21.2198915],
                [105.6990936, 21.2199357],
                [105.7003718, 21.2200564],
                [105.7006778, 21.2201411],
                [105.7016499, 21.2184918],
                [105.7026302, 21.2196743],
                [105.7034074, 21.2213152],
                [105.7036247, 21.2212516],
                [105.707113, 21.2203527],
                [105.707669, 21.2201044],
                [105.7086177, 21.2197314],
                [105.7092081, 21.2198519],
                [105.7107539, 21.220983],
                [105.7109415, 21.2212231],
                [105.7104225, 21.221821],
                [105.7112032, 21.2224173],
                [105.7115342, 21.2225717],
                [105.7123847, 21.2223937],
                [105.7133174, 21.2224789],
                [105.7136129, 21.2225699],
                [105.7154145, 21.2244898],
                [105.7158172, 21.2240453],
                [105.7163941, 21.2234837],
                [105.7168202, 21.2233001],
                [105.7172452, 21.2229624],
                [105.717703, 21.2226555],
                [105.7183249, 21.222624],
                [105.7189469, 21.2226539],
                [105.7193725, 21.2227452],
                [105.7197328, 21.2229286],
                [105.7199952, 21.2232654],
                [105.7201595, 21.2237553],
                [105.7202911, 21.2242148],
                [105.7203902, 21.2247663],
                [105.72062, 21.2252256],
                [105.7207844, 21.2257157],
                [105.7212103, 21.2259603],
                [105.7214071, 21.2262358],
                [105.721342, 21.2264811],
                [105.7210481, 21.226941],
                [105.7210811, 21.2271556],
                [105.7218741, 21.2279219],
                [105.7242632, 21.225358],
                [105.7248464, 21.2257856],
                [105.7317905, 21.2315227],
                [105.7321552, 21.2310333],
                [105.7351257, 21.2329919],
                [105.7341087, 21.2333198],
                [105.7330239, 21.2337732],
                [105.7328076, 21.2339985],
                [105.7323333, 21.2347959],
                [105.731903, 21.2356952],
                [105.731341, 21.2363503],
                [105.7302379, 21.2375176],
                [105.7301303, 21.2377425],
                [105.7300667, 21.2380487],
                [105.7300894, 21.2382526],
                [105.7301554, 21.2384154],
                [105.7302211, 21.238517],
                [105.730482, 21.238516],
                [105.7309158, 21.2383101],
                [105.7317603, 21.237613],
                [105.732626, 21.2368342],
                [105.7336213, 21.2358509],
                [105.7343368, 21.2354807],
                [105.7352275, 21.2353544],
                [105.7363801, 21.2354106],
                [105.7368374, 21.2355718],
                [105.7371643, 21.2357335],
                [105.7374466, 21.2360087],
                [105.7375264, 21.2361426],
                [105.7375585, 21.2363028],
                [105.7375564, 21.2364095],
                [105.7375498, 21.2367413],
                [105.7372928, 21.2375176],
                [105.7368397, 21.2382334],
                [105.7364305, 21.2390101],
                [105.7362608, 21.2398676],
                [105.7360026, 21.2403989],
                [105.7356571, 21.24089],
                [105.735192, 21.2413304],
                [105.7346938, 21.2417201],
                [105.7339778, 21.2420088],
                [105.7331088, 21.2421554],
                [105.732261, 21.2421591],
                [105.7314989, 21.2419176],
                [105.7305624, 21.241575],
                [105.7292333, 21.2410096],
                [105.7284279, 21.2408091],
                [105.7277646, 21.2408686],
                [105.7274124, 21.24091],
                [105.7257863, 21.2417559],
                [105.7253302, 21.2420707],
                [105.7251643, 21.2423537],
                [105.7251975, 21.2426588],
                [105.7253288, 21.2428417],
                [105.7255404, 21.2429522],
                [105.7264338, 21.2430406],
                [105.7266347, 21.2431217],
                [105.7270384, 21.243436],
                [105.7271486, 21.243721],
                [105.7278282, 21.2448603],
                [105.7286615, 21.2463252],
                [105.7290132, 21.2470987],
                [105.7292029, 21.2480666],
                [105.7291845, 21.2487602],
                [105.7291008, 21.2490155],
                [105.7290774, 21.2490869],
                [105.7288614, 21.2493734],
                [105.728558, 21.2495992],
                [105.7280293, 21.2497701],
                [105.7271898, 21.2502642],
                [105.7263477, 21.2507154],
                [105.7254164, 21.250999],
                [105.7245928, 21.2510982],
                [105.723483, 21.2511802],
                [105.7233396, 21.2512807],
                [105.7232872, 21.2514475],
                [105.7240192, 21.2529125],
                [105.7241535, 21.2537075],
                [105.7242139, 21.2542656],
                [105.7243586, 21.2556034],
                [105.7246835, 21.2575906],
                [105.7245146, 21.2586111],
                [105.7241343, 21.2608766],
                [105.7239641, 21.2616524],
                [105.723596, 21.26196],
                [105.723501, 21.2625416],
                [105.7233155, 21.2646432],
                [105.7231924, 21.2661326],
                [105.7244755, 21.2661678],
                [105.7246061, 21.266208],
                [105.7250851, 21.2663283],
                [105.7254775, 21.2665306],
                [105.7260228, 21.2668954],
                [105.7265472, 21.2673827],
                [105.7270061, 21.2678497],
                [105.7273771, 21.2681133],
                [105.7277474, 21.2682545],
                [105.7284219, 21.2683331],
                [105.7286507, 21.2684443],
                [105.7289777, 21.2685857],
                [105.7292635, 21.2692371],
                [105.7293956, 21.2695629],
                [105.7293098, 21.269808],
                [105.7290934, 21.2700129],
                [105.7285509, 21.2702396],
                [105.7281387, 21.2704249],
                [105.7277049, 21.2706716],
                [105.7271194, 21.27098],
                [105.7262725, 21.2712489],
                [105.7257958, 21.2715773],
                [105.7254281, 21.2719867],
                [105.7252992, 21.2723135],
                [105.7252789, 21.2725991],
                [105.7252801, 21.2728439],
                [105.7254336, 21.2730881],
                [105.7256085, 21.2732912],
                [105.7258047, 21.2733923],
                [105.7262178, 21.2733905],
                [105.7268909, 21.2731837],
                [105.727016, 21.2731306],
                [105.7275203, 21.2729159],
                [105.7278578, 21.2730062],
                [105.7285347, 21.2735744],
                [105.7288416, 21.2740829],
                [105.7289735, 21.2743679],
                [105.7289963, 21.2745717],
                [105.7289762, 21.2749185],
                [105.728891, 21.2752656],
                [105.7290011, 21.2755507],
                [105.7290454, 21.2757136],
                [105.7292415, 21.2757944],
                [105.7294806, 21.2757933],
                [105.7298716, 21.2756897],
                [105.7307404, 21.275482],
                [105.7315647, 21.2750705],
                [105.7322602, 21.2749858],
                [105.7324069, 21.2750462],
                [105.7324563, 21.2750666],
                [105.7326093, 21.2752291],
                [105.7327195, 21.2755141],
                [105.7327859, 21.2757586],
                [105.7329422, 21.2765942],
                [105.7333715, 21.2776426],
                [105.733488, 21.2791922],
                [105.7335758, 21.2793754],
                [105.7337718, 21.2794357],
                [105.7350539, 21.2792465],
                [105.7355762, 21.2793055],
                [105.7358597, 21.2794674],
                [105.7359709, 21.2799768],
                [105.7359733, 21.2804255],
                [105.7357385, 21.2813239],
                [105.7354811, 21.2820388],
                [105.7352139, 21.2827875],
                [105.7351056, 21.2830909],
                [105.7349996, 21.2836216],
                [105.735067, 21.2840497],
                [105.7352641, 21.2843344],
                [105.7357657, 21.2846177],
                [105.7360498, 21.284902],
                [105.7362905, 21.2851865],
                [105.7366397, 21.2854296],
                [105.7367711, 21.2856331],
                [105.7367502, 21.2857964],
                [105.7364481, 21.2862872],
                [105.7362759, 21.2866142],
                [105.7362767, 21.2867977],
                [105.7363428, 21.2869607],
                [105.7366045, 21.2871227],
                [105.7381308, 21.2879114],
                [105.7384354, 21.2879305],
                [105.7386525, 21.2878683],
                [105.7388041, 21.2877249],
                [105.7388468, 21.2875616],
                [105.7387785, 21.28695],
                [105.7388212, 21.2867866],
                [105.738929, 21.2866026],
                [105.7393635, 21.2865191],
                [105.7395596, 21.2865795],
                [105.7397341, 21.286701],
                [105.7397569, 21.2869049],
                [105.7396514, 21.287558],
                [105.7397604, 21.2876188],
                [105.7398691, 21.2875979],
                [105.7401514, 21.287515],
                [105.74102, 21.2872257],
                [105.7416504, 21.2872025],
                [105.7419555, 21.2873031],
                [105.7422616, 21.2876485],
                [105.7424048, 21.2880009],
                [105.7424691, 21.288352],
                [105.7425002, 21.2884674],
                [105.7423881, 21.2890303],
                [105.7422278, 21.289291],
                [105.7419057, 21.2895523],
                [105.7415836, 21.289792],
                [105.7412838, 21.28988],
                [105.7410759, 21.289881],
                [105.7409144, 21.2899033],
                [105.7407304, 21.2900557],
                [105.740662, 21.2902294],
                [105.7406629, 21.2904243],
                [105.7407796, 21.2906621],
                [105.7410583, 21.290964],
                [105.7413126, 21.2910279],
                [105.7415666, 21.291005],
                [105.7423944, 21.2902866],
                [105.7426021, 21.2902424],
                [105.7427873, 21.2903282],
                [105.7428805, 21.2905012],
                [105.7429395, 21.2907499],
                [105.7427114, 21.2913142],
                [105.7423905, 21.2917922],
                [105.7423911, 21.2919437],
                [105.7425078, 21.2921598],
                [105.7429492, 21.2926994],
                [105.7433315, 21.2929252],
                [105.7436199, 21.2928698],
                [105.7444937, 21.2920861],
                [105.7448386, 21.2918029],
                [105.7450234, 21.2918021],
                [105.7452315, 21.2918445],
                [105.7453015, 21.2919742],
                [105.7453023, 21.2921475],
                [105.7452584, 21.2926026],
                [105.7453059, 21.2928622],
                [105.7453996, 21.2931219],
                [105.7457071, 21.2945501],
                [105.7461208, 21.2941584],
                [105.7465156, 21.2945682],
                [105.7469837, 21.2947383],
                [105.7470694, 21.294479],
                [105.7472074, 21.2943701],
                [105.747392, 21.2943261],
                [105.7478782, 21.2945621],
                [105.7481796, 21.2947991],
                [105.7483197, 21.29508],
                [105.7485993, 21.295577],
                [105.7488314, 21.2958142],
                [105.7491324, 21.2959428],
                [105.7493641, 21.2960935],
                [105.7496182, 21.2960923],
                [105.7498946, 21.2959394],
                [105.7503328, 21.2957361],
                [105.7518828, 21.295189],
                [105.7519687, 21.2951587],
                [105.7524642, 21.2950086],
                [105.7527798, 21.2950071],
                [105.7531858, 21.2950264],
                [105.7535246, 21.2951516],
                [105.7536154, 21.295278],
                [105.7536163, 21.295426],
                [105.7535053, 21.2957647],
                [105.7533273, 21.2962515],
                [105.7532167, 21.2966746],
                [105.7531501, 21.2968651],
                [105.7531736, 21.2970553],
                [105.7532644, 21.2971816],
                [105.7534681, 21.2973286],
                [105.7537847, 21.2974962],
                [105.7540336, 21.2976641],
                [105.7543054, 21.2979166],
                [105.754487, 21.2981483],
                [105.7546917, 21.2984853],
                [105.7552372, 21.2993283],
                [105.7555323, 21.2997073],
                [105.7557586, 21.2998753],
                [105.7559395, 21.2999591],
                [105.7563005, 21.2999997],
                [105.7565485, 21.2999985],
                [105.7567291, 21.30004],
                [105.7568201, 21.3001875],
                [105.7569113, 21.3003984],
                [105.7570473, 21.3005247],
                [105.7570863, 21.3006003],
                [105.7603617, 21.3075335],
                [105.7604616, 21.3080936],
                [105.7608079, 21.3093953],
                [105.7613166, 21.3108475],
                [105.7621017, 21.3125713],
                [105.7625269, 21.3134937],
                [105.7632269, 21.3144148],
                [105.7635351, 21.3147794],
                [105.7637787, 21.3150299],
                [105.7645285, 21.3159544],
                [105.7646907, 21.3161049],
                [105.7657796, 21.3171154],
                [105.7662508, 21.3175376],
                [105.7668833, 21.3178984],
                [105.7674674, 21.3182594],
                [105.7679551, 21.3187571],
                [105.7687041, 21.3197537],
                [105.7695123, 21.3210979],
                [105.7701415, 21.3220781],
                [105.7705963, 21.3228968],
                [105.7708264, 21.3236078],
                [105.7711361, 21.324061],
                [105.7715846, 21.3245282],
                [105.7717866, 21.3247701],
                [105.7724705, 21.325661],
                [105.7726666, 21.3260541],
                [105.7727988, 21.326614],
                [105.7728339, 21.3271139],
                [105.772774, 21.3280386],
                [105.772744, 21.3284932],
                [105.7727941, 21.328796],
                [105.7729745, 21.3292801],
                [105.7731381, 21.3296278],
                [105.77335, 21.3299451],
                [105.7735944, 21.3303075],
                [105.7737257, 21.3306554],
                [105.7737919, 21.3309431],
                [105.7739368, 21.3313797],
                [105.7740716, 21.331851],
                [105.774533, 21.3323687],
                [105.7746271, 21.3326071],
                [105.7747254, 21.3332107],
                [105.774763, 21.333397],
                [105.7748238, 21.3336749],
                [105.7749439, 21.3340265],
                [105.7749867, 21.3340706],
                [105.7750214, 21.3341548],
                [105.7751074, 21.3342715],
                [105.7753183, 21.3345575],
                [105.775632, 21.3348519],
                [105.7758622, 21.3350072],
                [105.7759079, 21.3351333],
                [105.7760843, 21.3353951],
                [105.7761908, 21.3356737],
                [105.7762625, 21.3360016],
                [105.7762485, 21.3366582],
                [105.7766901, 21.3374112],
                [105.7771474, 21.337803],
                [105.7772635, 21.3378782],
                [105.777773, 21.338131],
                [105.7779726, 21.338226],
                [105.7786196, 21.3385181],
                [105.7791309, 21.3387622],
                [105.7796924, 21.3389894],
                [105.7799381, 21.3390867],
                [105.780238, 21.3395121],
                [105.7805193, 21.3397571],
                [105.7807656, 21.3399693],
                [105.7807887, 21.3400045],
                [105.7809422, 21.3402638],
                [105.7810138, 21.3405426],
                [105.78119, 21.3407552],
                [105.7814361, 21.3409509],
                [105.7816998, 21.3411631],
                [105.7820583, 21.3415802],
                [105.7823511, 21.3417873],
                [105.7828097, 21.3424546],
                [105.7829158, 21.3426512],
                [105.7830916, 21.3427816],
                [105.783267, 21.34283],
                [105.7834771, 21.342829],
                [105.7837221, 21.3428114],
                [105.7840198, 21.3428266],
                [105.7841735, 21.3428258],
                [105.7843002, 21.3428257],
                [105.7844909, 21.3428256],
                [105.7849963, 21.3428252],
                [105.7857377, 21.3429],
                [105.7860716, 21.3430382],
                [105.7868139, 21.3435921],
                [105.7873709, 21.344077],
                [105.7887792, 21.3440051],
                [105.789706, 21.3440382],
                [105.7905956, 21.3441408],
                [105.790626, 21.3441777],
                [105.7909672, 21.3445912],
                [105.7916727, 21.3451799],
                [105.7928986, 21.3465309],
                [105.792812, 21.3467959],
                [105.7926037, 21.3474335],
                [105.7919747, 21.3479551],
                [105.7901988, 21.3496238],
                [105.7894592, 21.3505271],
                [105.7893489, 21.3510131],
                [105.7890905, 21.351534],
                [105.7884984, 21.3519861],
                [105.7871291, 21.3531335],
                [105.7865375, 21.3538631],
                [105.7864269, 21.354245],
                [105.7863417, 21.3546827],
                [105.7864312, 21.3550763],
                [105.7864689, 21.3555685],
                [105.7865243, 21.3560935],
                [105.7865608, 21.3563724],
                [105.7865654, 21.3572423],
                [105.7864094, 21.3575384],
                [105.7862708, 21.3578346],
                [105.7859225, 21.3581808],
                [105.7855747, 21.3586421],
                [105.7853838, 21.3589549],
                [105.7851225, 21.3592351],
                [105.7848088, 21.3594827],
                [105.7846913, 21.3595622],
                [105.7845645, 21.359648],
                [105.7845083, 21.3596738],
                [105.7840579, 21.3598802],
                [105.783516, 21.3600797],
                [105.7832362, 21.3601467],
                [105.7827809, 21.3601323],
                [105.781362, 21.3600405],
                [105.7808363, 21.3599773],
                [105.7801355, 21.359915],
                [105.7796451, 21.3599007],
                [105.779295, 21.3599023],
                [105.7789975, 21.3599366],
                [105.7787876, 21.3599704],
                [105.7784918, 21.3600512],
                [105.778404, 21.3600812],
                [105.7782844, 21.3601207],
                [105.7781491, 21.3601738],
                [105.7780707, 21.3602036],
                [105.7776863, 21.3603529],
                [105.7774419, 21.3605018],
                [105.7771798, 21.3606015],
                [105.7768477, 21.360718],
                [105.776603, 21.3608012],
                [105.7764456, 21.3608511],
                [105.7761484, 21.3609345],
                [105.7757634, 21.3609856],
                [105.7755359, 21.3610031],
                [105.7754484, 21.3610363],
                [105.7753965, 21.361135],
                [105.7753987, 21.3615617],
                [105.7753833, 21.3619722],
                [105.7752833, 21.3629247],
                [105.7751455, 21.3633849],
                [105.7750251, 21.3637794],
                [105.774957, 21.3641408],
                [105.7747841, 21.3645683],
                [105.7745755, 21.3648811],
                [105.7744194, 21.3651445],
                [105.7742979, 21.365342],
                [105.7742812, 21.3655226],
                [105.7742994, 21.3656375],
                [105.7744227, 21.3657847],
                [105.7745647, 21.366145],
                [105.7746713, 21.3664319],
                [105.775024, 21.3669062],
                [105.7750863, 21.3670946],
                [105.775087, 21.3672331],
                [105.7750873, 21.3672916],
                [105.7750624, 21.3675297],
                [105.7750369, 21.367694],
                [105.7749415, 21.3678831],
                [105.7748375, 21.3680725],
                [105.7746285, 21.3683032],
                [105.7740701, 21.3686833],
                [105.7734427, 21.3692361],
                [105.7732334, 21.3694093],
                [105.7730153, 21.3695746],
                [105.7730071, 21.369673],
                [105.7730781, 21.3698696],
                [105.7731413, 21.3702223],
                [105.7731858, 21.3703615],
                [105.7731779, 21.3705258],
                [105.773161, 21.3706325],
                [105.773074, 21.3707477],
                [105.7729342, 21.3707977],
                [105.7726633, 21.3708974],
                [105.7711919, 21.3708385],
                [105.7703422, 21.3707767],
                [105.7702113, 21.3708431],
                [105.7700298, 21.3713281],
                [105.7696627, 21.3731025],
                [105.7694477, 21.3738584],
                [105.7693443, 21.374179],
                [105.7692058, 21.3744997],
                [105.7690495, 21.3747383],
                [105.7688401, 21.3748871],
                [105.768587, 21.375036],
                [105.7673103, 21.3753947],
                [105.7658686, 21.375984],
                [105.7646707, 21.376326],
                [105.7633416, 21.3766931],
                [105.7626251, 21.3769836],
                [105.7614984, 21.377555],
                [105.760529, 21.3780519],
                [105.759926, 21.3782844],
                [105.7597254, 21.378433],
                [105.7597349, 21.3785888],
                [105.7598601, 21.3790889],
                [105.7599666, 21.3793592],
                [105.7601349, 21.3797278],
                [105.7602849, 21.3799569],
                [105.7604262, 21.3801778],
                [105.7606554, 21.3804886],
                [105.7610249, 21.3808235],
                [105.761405, 21.3815029],
                [105.7618045, 21.3825517],
                [105.7619379, 21.3829613],
                [105.762018, 21.3832154],
                [105.7620458, 21.3835107],
                [105.7621008, 21.38397],
                [105.7621027, 21.3843475],
                [105.7621305, 21.384651],
                [105.7621403, 21.3848398],
                [105.7621936, 21.3849791],
                [105.7624413, 21.3854703],
                [105.7629265, 21.386174],
                [105.7632101, 21.3868127],
                [105.7632813, 21.3870259],
                [105.7634138, 21.3872386],
                [105.7642751, 21.387842],
                [105.7645562, 21.3880295],
                [105.7651094, 21.3883059],
                [105.7657338, 21.3888283],
                [105.7659628, 21.3890735],
                [105.7660424, 21.3892291],
                [105.7660522, 21.3894096],
                [105.7660353, 21.3895328],
                [105.7659574, 21.3897055],
                [105.7658021, 21.3901575],
                [105.7657247, 21.3904287],
                [105.7657167, 21.3905765],
                [105.7656828, 21.3907982],
                [105.7656311, 21.3909708],
                [105.7655886, 21.3912008],
                [105.7654377, 21.3925146],
                [105.765413, 21.3928101],
                [105.7653356, 21.3930978],
                [105.7651712, 21.3934678],
                [105.7650417, 21.3938049],
                [105.7649199, 21.3939778],
                [105.764574, 21.3948328],
                [105.7645143, 21.3951205],
                [105.7643935, 21.3954903],
                [105.76429, 21.3957861],
                [105.7642219, 21.396164],
                [105.7641351, 21.3963285],
                [105.7641534, 21.396468],
                [105.7641828, 21.3970916],
                [105.764193, 21.3973541],
                [105.7642471, 21.3976575],
                [105.7642396, 21.3979037],
                [105.7641706, 21.3981093],
                [105.7639711, 21.3984794],
                [105.7635117, 21.3993843],
                [105.7633993, 21.3996802],
                [105.763262, 21.4002225],
                [105.7631329, 21.4006499],
                [105.7630198, 21.4008146],
                [105.7628016, 21.4009551],
                [105.7626097, 21.4010955],
                [105.7624003, 21.4012524],
                [105.762165, 21.4014668],
                [105.7620085, 21.401689],
                [105.7614179, 21.4026519],
                [105.761209, 21.4029073],
                [105.761026, 21.4030804],
                [105.7608694, 21.4032699],
                [105.7607479, 21.4035167],
                [105.7605839, 21.4039606],
                [105.7597999, 21.4047931],
                [105.7586778, 21.4062671],
                [105.7580764, 21.4068444],
                [105.7576758, 21.4073058],
                [105.7574145, 21.4075942],
                [105.7566485, 21.408484],
                [105.7564219, 21.4087148],
                [105.7560413, 21.4096356],
                [105.7558866, 21.4102191],
                [105.7558268, 21.4105312],
                [105.7555919, 21.4108262],
                [105.7548591, 21.4114036],
                [105.7547214, 21.411513],
                [105.7542049, 21.4119234],
                [105.7532628, 21.4126822],
                [105.7528091, 21.4130123],
                [105.7523113, 21.4133097],
                [105.7521106, 21.4134583],
                [105.7518843, 21.4136036],
                [105.7516099, 21.413827],
                [105.7511102, 21.4140887],
                [105.750395, 21.4145961],
                [105.7497895, 21.4157845],
                [105.7483863, 21.4161665],
                [105.7465424, 21.4162304],
                [105.7441614, 21.4159346],
                [105.7409147, 21.4150293],
                [105.7395445, 21.4146453],
                [105.7379491, 21.4134083],
                [105.7365441, 21.4120211],
                [105.7354979, 21.4109667],
                [105.7347515, 21.4104127],
                [105.7331492, 21.4096524],
                [105.7326293, 21.4095007],
                [105.732149, 21.4093606],
                [105.730908, 21.409092],
                [105.7298373, 21.4090966],
                [105.7291542, 21.4092946],
                [105.7285613, 21.4096873],
                [105.7272287, 21.4108357],
                [105.7252228, 21.4120224],
                [105.7250609, 21.4120916],
                [105.7247477, 21.4122494],
                [105.7245969, 21.4123376],
                [105.7241455, 21.4126419],
                [105.7238028, 21.4128532],
                [105.7234204, 21.4130612],
                [105.7221651, 21.4137104],
                [105.7220125, 21.41382],
                [105.7219453, 21.4138846],
                [105.7218325, 21.41403],
                [105.7217864, 21.4141125],
                [105.7217494, 21.4141989],
                [105.7217217, 21.4142882],
                [105.7217038, 21.4143796],
                [105.7214892, 21.4148191],
                [105.7212752, 21.4151947],
                [105.7210386, 21.4155582],
                [105.720948, 21.4156736],
                [105.7209131, 21.4157374],
                [105.7208657, 21.4158733],
                [105.7208501, 21.4160155],
                [105.7208545, 21.4160869],
                [105.7208869, 21.4162255],
                [105.7209491, 21.4163538],
                [105.7210385, 21.4164674],
                [105.7214892, 21.4168567],
                [105.72209, 21.4173311],
                [105.7222252, 21.417492],
                [105.7222821, 21.4175794],
                [105.7223681, 21.4177535],
                [105.7224226, 21.4179254],
                [105.7225299, 21.4183449],
                [105.7226856, 21.4187433],
                [105.7228719, 21.4191302],
                [105.7230878, 21.4195035],
                [105.7233185, 21.4198429],
                [105.7235737, 21.4201668],
                [105.72371, 21.4203225],
                [105.7237708, 21.4203805],
                [105.7238741, 21.4205106],
                [105.7239508, 21.4206559],
                [105.7239982, 21.4208116],
                [105.7240149, 21.4209772],
                [105.7239976, 21.4211473],
                [105.723976, 21.4212304],
                [105.7238561, 21.4215469],
                [105.7238222, 21.421668],
                [105.7237754, 21.4219297],
                [105.7237647, 21.4220698],
                [105.7237637, 21.4222101],
                [105.7238106, 21.4223447],
                [105.7238817, 21.4226196],
                [105.7241978, 21.4234595],
                [105.7242863, 21.4238633],
                [105.7243443, 21.4240766],
                [105.7244825, 21.4244973],
                [105.7245386, 21.4246984],
                [105.7245852, 21.4249016],
                [105.7246478, 21.4253009],
                [105.7246825, 21.4256978],
                [105.7247293, 21.4258955],
                [105.7248044, 21.4260853],
                [105.7252872, 21.4268443],
                [105.7253535, 21.4269637],
                [105.7254107, 21.4270871],
                [105.7254584, 21.427214],
                [105.7255059, 21.4273832],
                [105.7255079, 21.4274236],
                [105.7254895, 21.4275024],
                [105.7254696, 21.4275384],
                [105.725391, 21.4276393],
                [105.7253637, 21.4276963],
                [105.7253355, 21.4278181],
                [105.7253442, 21.4279426],
                [105.7253907, 21.4280769],
                [105.725426, 21.4282304],
                [105.725432, 21.4283873],
                [105.7254072, 21.4285481],
                [105.7253516, 21.428702],
                [105.7252615, 21.4290336],
                [105.7252014, 21.4293711],
                [105.7251723, 21.4296941],
                [105.725168, 21.4298561],
                [105.7251799, 21.43018],
                [105.724499, 21.431133],
                [105.7244685, 21.4311989],
                [105.7244343, 21.4313385],
                [105.7244309, 21.4313952],
                [105.7244417, 21.431508],
                [105.7244557, 21.4315632],
                [105.7244853, 21.4316121],
                [105.7245258, 21.4317177],
                [105.7245396, 21.4318367],
                [105.7245201, 21.4319627],
                [105.7244813, 21.432016],
                [105.7244288, 21.4321348],
                [105.7244128, 21.4322623],
                [105.7244305, 21.4323759],
                [105.7245429, 21.4326198],
                [105.7245791, 21.4327667],
                [105.7245845, 21.4329136],
                [105.7245603, 21.4330588],
                [105.7226264, 21.4329864],
                [105.7225171, 21.432988],
                [105.7222992, 21.4330064],
                [105.7221704, 21.433027],
                [105.7220431, 21.4330547],
                [105.721795, 21.4331312],
                [105.7215682, 21.4331443],
                [105.7213415, 21.4331293],
                [105.7212295, 21.4331112],
                [105.7210189, 21.4330572],
                [105.7208255, 21.4329821],
                [105.7207328, 21.4329365],
                [105.7204429, 21.4326714],
                [105.7203061, 21.4325316],
                [105.7200539, 21.4322437],
                [105.7198289, 21.4319452],
                [105.719754, 21.431862],
                [105.7196717, 21.4317852],
                [105.7195825, 21.4317153],
                [105.7194871, 21.4316528],
                [105.7193863, 21.4315982],
                [105.7192063, 21.4315253],
                [105.7191124, 21.4314982],
                [105.7190167, 21.4314775],
                [105.7185974, 21.4314157],
                [105.7182816, 21.4313388],
                [105.7181272, 21.4312897],
                [105.7178194, 21.4311674],
                [105.7175194, 21.4310128],
                [105.7172379, 21.4308304],
                [105.7171051, 21.4307293],
                [105.716806, 21.4307044],
                [105.7166921, 21.4306765],
                [105.7165915, 21.4306195],
                [105.7165156, 21.4305423],
                [105.7164291, 21.4303803],
                [105.7163435, 21.4302794],
                [105.7162916, 21.4302367],
                [105.7162347, 21.4302],
                [105.7161237, 21.4301512],
                [105.7160041, 21.4301251],
                [105.715901, 21.4300884],
                [105.7158083, 21.4300327],
                [105.7157219, 21.429951],
                [105.7156581, 21.429853],
                [105.7153469, 21.4291763],
                [105.7150266, 21.4292672],
                [105.7147193, 21.4293911],
                [105.714433, 21.429544],
                [105.7141668, 21.4297256],
                [105.7134426, 21.4302783],
                [105.7130429, 21.4303773],
                [105.7126514, 21.4305238],
                [105.7124607, 21.4306078],
                [105.7120907, 21.4307967],
                [105.7119662, 21.4308687],
                [105.7117299, 21.43103],
                [105.7115044, 21.4312204],
                [105.7113964, 21.4313278],
                [105.7112002, 21.4315583],
                [105.7107174, 21.4324671],
                [105.7105782, 21.4326943],
                [105.7104962, 21.4328007],
                [105.7104066, 21.4329015],
                [105.7103097, 21.4329964],
                [105.7102083, 21.433083],
                [105.7099879, 21.4332361],
                [105.7099442, 21.4337805],
                [105.7099169, 21.4338668],
                [105.7098627, 21.4339352],
                [105.7098293, 21.4339606],
                [105.7097518, 21.4339951],
                [105.7093549, 21.4340849],
                [105.7090437, 21.4342447],
                [105.7087004, 21.4344345],
                [105.7077626, 21.4348301],
                [105.7076169, 21.4349231],
                [105.7074934, 21.4350407],
                [105.7073969, 21.4351785],
                [105.7073307, 21.4353322],
                [105.7073101, 21.4354129],
                [105.7072949, 21.435578],
                [105.7072445, 21.4356216],
                [105.7071634, 21.4357248],
                [105.707134, 21.4357827],
                [105.7071027, 21.4358881],
                [105.7070965, 21.4359974],
                [105.707193, 21.436297],
                [105.707134, 21.4368563],
                [105.7071279, 21.4369957],
                [105.7070911, 21.4371309],
                [105.707057, 21.4372037],
                [105.7070138, 21.4372723],
                [105.7069623, 21.4373356],
                [105.7069182, 21.4373625],
                [105.7068445, 21.4374325],
                [105.7067953, 21.4375192],
                [105.7067746, 21.4376152],
                [105.7067866, 21.437722],
                [105.7068059, 21.4377727],
                [105.7068594, 21.4378628],
                [105.7068919, 21.437956],
                [105.7068977, 21.4380468],
                [105.7068819, 21.4381295],
                [105.7066673, 21.4385789],
                [105.7063508, 21.439288],
                [105.7062501, 21.4394739],
                [105.7061884, 21.4395609],
                [105.7060442, 21.4397201],
                [105.7058753, 21.4398566],
                [105.7056856, 21.4399671],
                [105.705576, 21.440015],
                [105.7054624, 21.4400542],
                [105.7053457, 21.4400844],
                [105.7052267, 21.4401053],
                [105.7051063, 21.4401168],
                [105.7049812, 21.4401445],
                [105.7047361, 21.4402167],
                [105.7044863, 21.4403173],
                [105.704366, 21.4403765],
                [105.7042061, 21.4404724],
                [105.7040302, 21.4405394],
                [105.703844, 21.4405753],
                [105.7037491, 21.4405812],
                [105.703565, 21.4405694],
                [105.703386, 21.4405274],
                [105.703218, 21.4404564],
                [105.701505, 21.4393322],
                [105.7013743, 21.4392542],
                [105.7012386, 21.439184],
                [105.7010983, 21.439122],
                [105.7009542, 21.4390683],
                [105.7007106, 21.4389989],
                [105.7004607, 21.4389534],
                [105.6991571, 21.4387887],
                [105.6989783, 21.4387717],
                [105.6987987, 21.4387642],
                [105.6986189, 21.4387662],
                [105.6984396, 21.4387777],
                [105.6982612, 21.4387987],
                [105.6979229, 21.4388652],
                [105.6975945, 21.4389657],
                [105.6954194, 21.439826],
                [105.6942862, 21.4398273],
                [105.6938396, 21.4398628],
                [105.693396, 21.4399229],
                [105.692967, 21.4400051],
                [105.6925538, 21.4401075],
                [105.6923496, 21.4401668],
                [105.6920987, 21.4402526],
                [105.6918384, 21.4403094],
                [105.6915665, 21.4403365],
                [105.691287, 21.4403315],
                [105.691148, 21.4403166],
                [105.6910469, 21.4403217],
                [105.6909467, 21.440336],
                [105.6908486, 21.4403592],
                [105.6907533, 21.4403912],
                [105.6906618, 21.4404316],
                [105.6905749, 21.4404802],
                [105.6904936, 21.4405363],
                [105.6903657, 21.4406522],
                [105.690261, 21.4407866],
                [105.6901824, 21.4409358],
                [105.6900054, 21.4416048],
                [105.6895119, 21.4423039],
                [105.6894526, 21.4423467],
                [105.6893882, 21.4423826],
                [105.6892478, 21.4424314],
                [105.6890962, 21.4424475],
                [105.6890186, 21.4424423],
                [105.6889423, 21.4424281],
                [105.6888685, 21.4424052],
                [105.6887984, 21.4423738],
                [105.6885674, 21.4423026],
                [105.6883056, 21.4422455],
                [105.68816, 21.442224],
                [105.6873017, 21.442234],
                [105.6871784, 21.4425086],
                [105.6871409, 21.4428791],
                [105.6871086, 21.4430628],
                [105.6870496, 21.4433125],
                [105.6870497, 21.4437509],
                [105.6870232, 21.4438764],
                [105.6869692, 21.443994],
                [105.6868922, 21.4440962],
                [105.6867197, 21.4442636],
                [105.6866017, 21.4444134],
                [105.6864736, 21.4445409],
                [105.6864005, 21.4445957],
                [105.6862551, 21.4446779],
                [105.6861773, 21.4447101],
                [105.6860143, 21.4447555],
                [105.6852633, 21.445794],
                [105.684405, 21.4470073],
                [105.6842311, 21.4471695],
                [105.6840368, 21.4473102],
                [105.6837008, 21.4474967],
                [105.6835896, 21.4475665],
                [105.6833295, 21.4477596],
                [105.6832087, 21.4478661],
                [105.6830868, 21.4479918],
                [105.6828639, 21.4482592],
                [105.6826672, 21.4485517],
                [105.6825795, 21.4487074],
                [105.6825006, 21.4488672],
                [105.6824416, 21.4491295],
                [105.6823863, 21.4492744],
                [105.6822941, 21.4494488],
                [105.6821519, 21.4496585],
                [105.681873, 21.4500979],
                [105.6814653, 21.4505273],
                [105.6813956, 21.4506229],
                [105.6813346, 21.4507234],
                [105.6812826, 21.4508283],
                [105.6812088, 21.4510406],
                [105.6811562, 21.4512753],
                [105.6811168, 21.451402],
                [105.6810683, 21.4515259],
                [105.6810092, 21.4516499],
                [105.680941, 21.4517699],
                [105.680864, 21.4518852],
                [105.6807786, 21.4519952],
                [105.6802851, 21.4525144],
                [105.6798881, 21.4531635],
                [105.6792712, 21.4538974],
                [105.6791464, 21.4540901],
                [105.6790513, 21.4542969],
                [105.6790172, 21.4543983],
                [105.6789708, 21.4546064],
                [105.6785577, 21.4554202],
                [105.678252, 21.4559894],
                [105.6777987, 21.4563988],
                [105.677619, 21.4564387],
                [105.6774686, 21.4564856],
                [105.6773096, 21.4565603],
                [105.6772271, 21.4566112],
                [105.6771496, 21.4566684],
                [105.6767353, 21.4570281],
                [105.6765845, 21.4571208],
                [105.6763234, 21.4572273],
                [105.6761545, 21.4573174],
                [105.6759622, 21.4574571],
                [105.675777, 21.4576254],
                [105.6755698, 21.4577917],
                [105.6752778, 21.4579882],
                [105.6751245, 21.4580763],
                [105.6747999, 21.4581409],
                [105.6744701, 21.4581761],
                [105.6742819, 21.458183],
                [105.6740937, 21.4581802],
                [105.6739059, 21.458168],
                [105.673719, 21.4581462],
                [105.6734602, 21.4580911],
                [105.6731816, 21.4580081],
                [105.673033, 21.4579532],
                [105.6728033, 21.457853],
                [105.6726266, 21.4577945],
                [105.6724423, 21.4577618],
                [105.6721205, 21.4577532],
                [105.6717475, 21.4577694],
                [105.6706667, 21.4578966],
                [105.6701763, 21.4579282],
                [105.669668, 21.4579823],
                [105.6691432, 21.4580613],
                [105.6690209, 21.458091],
                [105.6687843, 21.4581736],
                [105.6686711, 21.4582261],
                [105.6684663, 21.4583464],
                [105.6683707, 21.4584158],
                [105.6679094, 21.45891],
                [105.6674105, 21.4591397],
                [105.667311, 21.4592147],
                [105.6671262, 21.4593793],
                [105.6670362, 21.4594743],
                [105.6669524, 21.459574],
                [105.6668043, 21.4597862],
                [105.6667209, 21.4598805],
                [105.66663, 21.4599685],
                [105.6665325, 21.4600494],
                [105.6664288, 21.4601232],
                [105.6657904, 21.4600284],
                [105.6652218, 21.4599685],
                [105.6648236, 21.4599081],
                [105.6644287, 21.4598224],
                [105.664039, 21.4597113],
                [105.6637394, 21.4596526],
                [105.6635878, 21.4596326],
                [105.6632826, 21.4596115],
                [105.6627073, 21.4596185],
                [105.662489, 21.4596085],
                [105.6621059, 21.4595679],
                [105.6617778, 21.4595092],
                [105.6615425, 21.4594772],
                [105.6612655, 21.4594669],
                [105.6609859, 21.4594827],
                [105.6607425, 21.4594842],
                [105.6604843, 21.4594633],
                [105.6603563, 21.4594443],
                [105.6593639, 21.458935],
                [105.6592461, 21.4588995],
                [105.6590042, 21.458852],
                [105.6587809, 21.4588365],
                [105.6582198, 21.4588677],
                [105.6578833, 21.4588554],
                [105.6575745, 21.4588154],
                [105.6574219, 21.4587852],
                [105.6556195, 21.4583209],
                [105.6551923, 21.4580889],
                [105.6550451, 21.4580219],
                [105.6548941, 21.4579627],
                [105.6546046, 21.4578741],
                [105.6543294, 21.4578179],
                [105.6541899, 21.4577992],
                [105.653286, 21.4576993],
                [105.6518215, 21.4573623],
                [105.6512636, 21.4582161],
                [105.6509739, 21.4584957],
                [105.6506467, 21.45892],
                [105.6504937, 21.4591669],
                [105.6503624, 21.4594243],
                [105.6502905, 21.4595927],
                [105.650134, 21.4600456],
                [105.6500351, 21.460253],
                [105.6499714, 21.4603622],
                [105.649901, 21.4604677],
                [105.6491457, 21.4614177],
                [105.648914, 21.4616759],
                [105.6486654, 21.4619075],
                [105.6479645, 21.4624597],
                [105.6476193, 21.4627712],
                [105.647251, 21.4630588],
                [105.6470986, 21.4631661],
                [105.6466793, 21.463439],
                [105.6464858, 21.4635991],
                [105.6463981, 21.4636878],
                [105.6462524, 21.4638669],
                [105.6456631, 21.4647862],
                [105.645606, 21.4649452],
                [105.645588, 21.4650274],
                [105.645574, 21.4651806],
                [105.6456054, 21.4655696],
                [105.645597, 21.4657952],
                [105.6455666, 21.4659943],
                [105.6456417, 21.4669728],
                [105.6455625, 21.4673734],
                [105.6454424, 21.467816],
                [105.6452419, 21.4683958],
                [105.6451642, 21.4685903],
                [105.645057, 21.468785],
                [105.6448048, 21.4691694],
                [105.6446211, 21.4695719],
                [105.6444347, 21.469907],
                [105.6443161, 21.4699897],
                [105.6442038, 21.4700798],
                [105.6440983, 21.4701767],
                [105.6439322, 21.470361],
                [105.64378, 21.470569],
                [105.6436761, 21.4706686],
                [105.6435643, 21.4707444],
                [105.643115, 21.4709816],
                [105.6421763, 21.4717903],
                [105.6419531, 21.472136],
                [105.6418669, 21.4722308],
                [105.6417557, 21.4723127],
                [105.6416211, 21.4723744],
                [105.6413716, 21.4724442],
                [105.6409512, 21.4725372],
                [105.6405704, 21.4726474],
                [105.640229, 21.4727687],
                [105.639493, 21.4730717],
                [105.6393492, 21.4731531],
                [105.6391963, 21.4732729],
                [105.6391401, 21.473354],
                [105.6390113, 21.4735051],
                [105.6389378, 21.4735756],
                [105.6387766, 21.4737022],
                [105.6384884, 21.473864],
                [105.6381825, 21.4739943],
                [105.6380772, 21.4740304],
                [105.637861, 21.4740854],
                [105.6376385, 21.4741169],
                [105.6374127, 21.4741241],
                [105.6368608, 21.4740926],
                [105.6367609, 21.4741103],
                [105.6366784, 21.4741528],
                [105.6366178, 21.4742108],
                [105.6365154, 21.474365],
                [105.6364276, 21.4744795],
                [105.6363317, 21.4745883],
                [105.6361396, 21.4747684],
                [105.6359254, 21.4749253],
                [105.6350751, 21.4753933],
                [105.6345347, 21.4758338],
                [105.6340545, 21.4761783],
                [105.6337059, 21.4765252],
                [105.6334335, 21.476845],
                [105.6333786, 21.4769009],
                [105.6332619, 21.4769995],
                [105.6325123, 21.4773813],
                [105.6324373, 21.4774289],
                [105.6323017, 21.4775411],
                [105.6322031, 21.4776535],
                [105.6321233, 21.4777782],
                [105.6319785, 21.4781102],
                [105.6315762, 21.4786742],
                [105.6312664, 21.4789787],
                [105.6312176, 21.4790887],
                [105.6311417, 21.4793157],
                [105.6310944, 21.4795585],
                [105.63108, 21.4798049],
                [105.6310344, 21.4802567],
                [105.631012, 21.4803414],
                [105.6309834, 21.4805137],
                [105.6309763, 21.4806303],
                [105.6310075, 21.4812999],
                [105.6310265, 21.4814594],
                [105.6310853, 21.4817754],
                [105.6311217, 21.4818657],
                [105.6311798, 21.4819395],
                [105.6313455, 21.4820612],
                [105.631666, 21.4835013],
                [105.6316929, 21.4837771],
                [105.6317353, 21.4840006],
                [105.6317505, 21.4840342],
                [105.6317934, 21.4840928],
                [105.6320563, 21.4843412],
                [105.6320569, 21.4846292],
                [105.6320773, 21.4848678],
                [105.6321142, 21.4850632],
                [105.6321476, 21.4851618],
                [105.6321904, 21.4852571],
                [105.6323495, 21.485367],
                [105.6329656, 21.4857925],
                [105.6330621, 21.4859016],
                [105.6331319, 21.4860271],
                [105.633156, 21.4860952],
                [105.6331801, 21.4862367],
                [105.6331779, 21.4864072],
                [105.6331657, 21.4864918],
                [105.6331191, 21.4866567],
                [105.6329495, 21.4869306],
                [105.6328368, 21.4871352],
                [105.6327188, 21.4873299],
                [105.632718, 21.4874777],
                [105.6327039, 21.4875506],
                [105.6326491, 21.4876893],
                [105.6326153, 21.4877444],
                [105.632531, 21.487844],
                [105.6322682, 21.4880287],
                [105.6323755, 21.4882883],
                [105.6324339, 21.4883647],
                [105.632524, 21.4884379],
                [105.6327762, 21.4885538],
                [105.632981, 21.4886757],
                [105.6332409, 21.4888702],
                [105.6333397, 21.4889533],
                [105.6334333, 21.4890414],
                [105.6336039, 21.4892317],
                [105.633691, 21.4893483],
                [105.6337698, 21.48947],
                [105.63384, 21.4895961],
                [105.6338802, 21.490794],
                [105.6336098, 21.4910558],
                [105.6338453, 21.4914878],
                [105.6340599, 21.4918148],
                [105.6341733, 21.4920065],
                [105.6343871, 21.4924063],
                [105.6344608, 21.4925186],
                [105.6345427, 21.4926259],
                [105.6346189, 21.4927079],
                [105.6347404, 21.4928756],
                [105.634787, 21.4929589],
                [105.6348091, 21.4930189],
                [105.6348439, 21.4931595],
                [105.6348538, 21.4932398],
                [105.6334215, 21.493694],
                [105.6329226, 21.4939785],
                [105.6327443, 21.4941528],
                [105.6325406, 21.4944061],
                [105.6324694, 21.4944783],
                [105.6323111, 21.4946075],
                [105.6322259, 21.494663],
                [105.6320437, 21.4947563],
                [105.6315172, 21.4950667],
                [105.6309378, 21.495436],
                [105.6301363, 21.4960299],
                [105.6299639, 21.4961497],
                [105.6296074, 21.4963744],
                [105.6293828, 21.4965013],
                [105.6291532, 21.4966204],
                [105.628919, 21.4967314],
                [105.6286805, 21.4968341],
                [105.628438, 21.4969284],
                [105.6269681, 21.4969061],
                [105.6263028, 21.4969175],
                [105.6259382, 21.4969334],
                [105.6251925, 21.4970781],
                [105.6245709, 21.4971893],
                [105.6244554, 21.4972181],
                [105.6242323, 21.4972978],
                [105.6241398, 21.497341],
                [105.6239641, 21.4974425],
                [105.6233096, 21.4979566],
                [105.6232792, 21.4981463],
                [105.6232238, 21.4983309],
                [105.6231435, 21.4985097],
                [105.6230321, 21.4986914],
                [105.6228858, 21.49887],
                [105.6225532, 21.4991994],
                [105.6224352, 21.4993991],
                [105.6222567, 21.4994528],
                [105.6220704, 21.4994739],
                [105.621916, 21.4994663],
                [105.6218398, 21.499454],
                [105.6206489, 21.4991745],
                [105.6202733, 21.4989399],
                [105.6201173, 21.4989754],
                [105.6198013, 21.4990297],
                [105.6196249, 21.49905],
                [105.6192702, 21.4990696],
                [105.6192815, 21.4991374],
                [105.6192809, 21.4992743],
                [105.6192717, 21.4993303],
                [105.619238, 21.499439],
                [105.6181428, 21.5018738],
                [105.6180283, 21.5020547],
                [105.6179613, 21.5021392],
                [105.6178253, 21.5022798],
                [105.6176716, 21.5024037],
                [105.617162, 21.5027631],
                [105.6171137, 21.5029527],
                [105.6170178, 21.5030789],
                [105.6168935, 21.5032027],
                [105.6167382, 21.5033171],
                [105.6165129, 21.5035267],
                [105.6163504, 21.503588],
                [105.6160355, 21.5037313],
                [105.6158788, 21.503816],
                [105.6155795, 21.5040058],
                [105.6149732, 21.5041165],
                [105.6148674, 21.5041434],
                [105.6146622, 21.5042155],
                [105.6144565, 21.5043173],
                [105.6143595, 21.5043774],
                [105.6141794, 21.5045149],
                [105.6140463, 21.504602],
                [105.613767, 21.5047569],
                [105.6134613, 21.5048891],
                [105.6132976, 21.5049458],
                [105.6129616, 21.505034],
                [105.6127793, 21.5051038],
                [105.6126291, 21.5051937],
                [105.6124681, 21.5054033],
                [105.6124158, 21.5054569],
                [105.6123564, 21.5055037],
                [105.6122911, 21.5055431],
                [105.6121692, 21.505591],
                [105.611954, 21.5056274],
                [105.6118714, 21.5056508],
                [105.6117209, 21.5057209],
                [105.6115937, 21.5058176],
                [105.6114703, 21.5065612],
                [105.6114504, 21.5067747],
                [105.6113757, 21.5071394],
                [105.6113768, 21.5071863],
                [105.6114006, 21.5072774],
                [105.6114636, 21.5073947],
                [105.6115347, 21.5075669],
                [105.611466, 21.5077729],
                [105.6113684, 21.5079686],
                [105.6112611, 21.5081258],
                [105.6111082, 21.5082881],
                [105.6110835, 21.5083232],
                [105.611051, 21.5084013],
                [105.6110436, 21.5084917],
                [105.6110519, 21.5085401],
                [105.6111107, 21.5086571],
                [105.6112005, 21.5087595],
                [105.6113175, 21.5088395],
                [105.6112155, 21.5092588],
                [105.6110743, 21.509469],
                [105.6110546, 21.5095358],
                [105.61106, 21.5096156],
                [105.6111981, 21.5098177],
                [105.6113443, 21.5100149],
                [105.6115906, 21.5103162],
                [105.6116114, 21.5103514],
                [105.6116337, 21.510427],
                [105.6116389, 21.5105421],
                [105.6116258, 21.5107134],
                [105.6115776, 21.5108983],
                [105.6115884, 21.5112326],
                [105.6115317, 21.511367],
                [105.6115133, 21.5114373],
                [105.6114972, 21.511577],
                [105.6115615, 21.5118415],
                [105.6115939, 21.5119315],
                [105.611642, 21.512116],
                [105.6116576, 21.5122102],
                [105.6116715, 21.5124005],
                [105.6115267, 21.5125826],
                [105.6114284, 21.5126912],
                [105.6112155, 21.5128945],
                [105.6108293, 21.5134136],
                [105.6107291, 21.5135322],
                [105.6105116, 21.5137557],
                [105.6102618, 21.5139682],
                [105.6101231, 21.5140698],
                [105.609979, 21.5141647],
                [105.6095872, 21.5143875],
                [105.6094309, 21.5145106],
                [105.609301, 21.5146568],
                [105.609118, 21.514948],
                [105.6090134, 21.5150605],
                [105.6087023, 21.5153],
                [105.6085433, 21.5154391],
                [105.6083912, 21.5155845],
                [105.6081337, 21.5157841],
                [105.6080734, 21.5159305],
                [105.6079842, 21.5160635],
                [105.6078627, 21.5161839],
                [105.6077891, 21.5162373],
                [105.6077099, 21.5162832],
                [105.6075329, 21.5165527],
                [105.6074322, 21.5166508],
                [105.6073248, 21.5167424],
                [105.607108, 21.5168945],
                [105.607001, 21.5169562],
                [105.6067765, 21.5170617],
                [105.6067952, 21.5173662],
                [105.6068408, 21.5177355],
                [105.6067979, 21.5182395],
                [105.6067711, 21.5188184],
                [105.606696, 21.5193624],
                [105.6066638, 21.5199762],
                [105.6066754, 21.5201203],
                [105.6067121, 21.5202606],
                [105.6067895, 21.5204216],
                [105.6068408, 21.5204959],
                [105.6070058, 21.5206787],
                [105.6070903, 21.5208071],
                [105.6071287, 21.5208874],
                [105.6071797, 21.5210563],
                [105.6071918, 21.5211435],
                [105.6072056, 21.521381],
                [105.6072597, 21.521431],
                [105.6073064, 21.5214871],
                [105.6073738, 21.5216098],
                [105.6074062, 21.5217406],
                [105.6074148, 21.5220372],
                [105.6075597, 21.5223666],
                [105.6074095, 21.5228557],
                [105.6073625, 21.5229101],
                [105.6072853, 21.5230296],
                [105.6072324, 21.5231601],
                [105.6072137, 21.5232387],
                [105.6072029, 21.5233993],
                [105.6072335, 21.5236116],
                [105.6072461, 21.5237449],
                [105.6072485, 21.5238787],
                [105.60723, 21.5241033],
                [105.6071831, 21.5243244],
                [105.6071493, 21.5244326],
                [105.606696, 21.5248967],
                [105.6066204, 21.5249935],
                [105.6064511, 21.5251735],
                [105.6062542, 21.5253373],
                [105.606145, 21.5254122],
                [105.6059008, 21.5255511],
                [105.6057894, 21.5256452],
                [105.6057389, 21.5257042],
                [105.6056958, 21.5257681],
                [105.6056339, 21.5259072],
                [105.605489, 21.5264237],
                [105.6053871, 21.5268629],
                [105.605362, 21.5269219],
                [105.6052946, 21.5270321],
                [105.605253, 21.5270824],
                [105.6051669, 21.5271624],
                [105.6050736, 21.5272227],
                [105.6050246, 21.5272414],
                [105.6049731, 21.527253],
                [105.6048525, 21.5272604],
                [105.6048202, 21.5272707],
                [105.6047906, 21.5272865],
                [105.6047434, 21.5273319],
                [105.604727, 21.5273607],
                [105.6047134, 21.5274243],
                [105.6047273, 21.5277162],
                [105.6047218, 21.5277735],
                [105.6046951, 21.5278859],
                [105.6046465, 21.5279946],
                [105.6046146, 21.5280455],
                [105.6042874, 21.5283998],
                [105.6037778, 21.5291683],
                [105.6036084, 21.5291907],
                [105.6034452, 21.5292382],
                [105.6033648, 21.5292721],
                [105.6032145, 21.529358],
                [105.6030106, 21.5296773],
                [105.6024413, 21.5298651],
                [105.6020187, 21.5299816],
                [105.601888, 21.5300249],
                [105.6016493, 21.5301301],
                [105.6014382, 21.5302563],
                [105.6012458, 21.5304059],
                [105.6012404, 21.5309847],
                [105.6010419, 21.5312941],
                [105.6005752, 21.5315636],
                [105.5995902, 21.5323886],
                [105.5995059, 21.5324485],
                [105.5993222, 21.5325471],
                [105.5991231, 21.5326148],
                [105.5989112, 21.53265],
                [105.5988019, 21.5326545],
                [105.5986927, 21.53265],
                [105.5985844, 21.5326364],
                [105.5983808, 21.5325917],
                [105.5983307, 21.5325938],
                [105.5982363, 21.532624],
                [105.5981957, 21.5326508],
                [105.5981343, 21.5327232],
                [105.5980083, 21.5330356],
                [105.5979657, 21.5331207],
                [105.5978554, 21.5332777],
                [105.597788, 21.533349],
                [105.5977136, 21.5334141],
                [105.5976328, 21.5334723],
                [105.5974665, 21.5335596],
                [105.5972975, 21.533612],
                [105.5969596, 21.533612],
                [105.596686, 21.5335125],
                [105.5966319, 21.5334983],
                [105.5965362, 21.533493],
                [105.596458, 21.5335072],
                [105.596061, 21.5337068],
                [105.5958438, 21.5337118],
                [105.5956701, 21.5336299],
                [105.5955268, 21.5335347],
                [105.5953887, 21.5334651],
                [105.5952376, 21.5334249],
                [105.5950951, 21.5334155],
                [105.5950237, 21.5334206],
                [105.5949533, 21.5334323],
                [105.5947763, 21.5337916],
                [105.5939233, 21.5338515],
                [105.593749, 21.5339463],
                [105.5935499, 21.5340112],
                [105.593344, 21.5340536],
                [105.593128, 21.5340731],
                [105.5930194, 21.5340736],
                [105.5926127, 21.5340329],
                [105.5921574, 21.5339585],
                [105.5916608, 21.5338441],
                [105.5915362, 21.5338216],
                [105.5912935, 21.533792],
                [105.5909729, 21.5337708],
                [105.5908734, 21.5337741],
                [105.5906677, 21.5338026],
                [105.5905628, 21.5338289],
                [105.5903614, 21.5339039],
                [105.5897284, 21.5339039],
                [105.5895217, 21.5339507],
                [105.5893099, 21.5339688],
                [105.5890358, 21.5339538],
                [105.5887654, 21.5339089],
                [105.5884967, 21.5338326],
                [105.5882397, 21.5337268],
                [105.5877006, 21.5338839],
                [105.5872983, 21.533879],
                [105.586971, 21.5340212],
                [105.5868402, 21.5340396],
                [105.5867112, 21.5340671],
                [105.5865848, 21.5341035],
                [105.5861505, 21.5342712],
                [105.5857166, 21.5344176],
                [105.5854096, 21.5345081],
                [105.5852339, 21.5345779],
                [105.5851509, 21.534622],
                [105.5849969, 21.5347272],
                [105.5848559, 21.5348598],
                [105.5846543, 21.5351188],
                [105.5845463, 21.5352063],
                [105.5844233, 21.535269],
                [105.5842888, 21.5353061],
                [105.5839819, 21.5353443],
                [105.5836702, 21.5353628],
                [105.5833554, 21.535361],
                [105.583361, 21.5354844],
                [105.5833876, 21.5357302],
                [105.583451, 21.536038],
                [105.5835249, 21.5362988],
                [105.5835465, 21.53641],
                [105.5835637, 21.5366353],
                [105.5835593, 21.5367482],
                [105.5835289, 21.5369523],
                [105.583503, 21.5370525],
                [105.5834305, 21.5372472],
                [105.5834378, 21.5391292],
                [105.5834678, 21.5393688],
                [105.5835324, 21.5396024],
                [105.5836253, 21.5398159],
                [105.583747, 21.5400165],
                [105.5835287, 21.540093],
                [105.583314, 21.5401779],
                [105.5831033, 21.540271],
                [105.5827527, 21.5404492],
                [105.5824166, 21.5406502],
                [105.5812139, 21.5408979],
                [105.5808859, 21.5409862],
                [105.5806088, 21.5410843],
                [105.5804934, 21.5412128],
                [105.580405, 21.5413587],
                [105.5803612, 21.5414675],
                [105.5803271, 21.5415791],
                [105.5802806, 21.5418038],
                [105.5802333, 21.5419076],
                [105.580195, 21.5419631],
                [105.5800977, 21.5420587],
                [105.5800402, 21.5420972],
                [105.5786776, 21.5432149],
                [105.5784094, 21.5435268],
                [105.5781466, 21.5437887],
                [105.5775726, 21.5443875],
                [105.5773714, 21.5444623],
                [105.5772185, 21.544612],
                [105.5770712, 21.544793],
                [105.5769476, 21.5449887],
                [105.5768047, 21.545165],
                [105.5767411, 21.5452582],
                [105.5765791, 21.5455288],
                [105.5765048, 21.5456337],
                [105.5763381, 21.5458321],
                [105.576246, 21.5459251],
                [105.5760464, 21.5460964],
                [105.5759284, 21.5463459],
                [105.5758819, 21.546429],
                [105.5757675, 21.5465829],
                [105.5757125, 21.5466407],
                [105.5755904, 21.5467451],
                [105.5753303, 21.5469222],
                [105.5751163, 21.5472877],
                [105.5750446, 21.5473893],
                [105.5748949, 21.5475615],
                [105.5747402, 21.5477006],
                [105.5742708, 21.5480573],
                [105.5741929, 21.5481395],
                [105.574134, 21.5482344],
                [105.5740958, 21.5483411],
                [105.5740857, 21.5483966],
                [105.5740857, 21.5486635],
                [105.5740231, 21.5487299],
                [105.5738872, 21.5488531],
                [105.5738002, 21.5489199],
                [105.5736136, 21.5490377],
                [105.5733429, 21.5492442],
                [105.5732167, 21.5493571],
                [105.573045, 21.5495317],
                [105.5730396, 21.5497113],
                [105.5727017, 21.5502901],
                [105.572353, 21.5508439],
                [105.5719614, 21.5510285],
                [105.5718111, 21.5510853],
                [105.5715212, 21.5512203],
                [105.5712479, 21.5513828],
                [105.570992, 21.5515729],
                [105.5708724, 21.5516771],
                [105.5707276, 21.5520813],
                [105.5706921, 21.5521453],
                [105.5706032, 21.552263],
                [105.5704936, 21.5523634],
                [105.5704322, 21.5524062],
                [105.5702984, 21.5524754],
                [105.5700572, 21.5525478],
                [105.5698264, 21.5526451],
                [105.5696338, 21.5527505],
                [105.5693986, 21.552916],
                [105.5693216, 21.5529625],
                [105.5692416, 21.5530043],
                [105.5689359, 21.553139],
                [105.568673, 21.5532812],
                [105.5681848, 21.5532762],
                [105.5680478, 21.5533219],
                [105.5679834, 21.5533536],
                [105.5678657, 21.5534334],
                [105.5678164, 21.5534778],
                [105.5677315, 21.553578],
                [105.5676544, 21.553709],
                [105.5675465, 21.5538475],
                [105.5674826, 21.5539079],
                [105.5673373, 21.5540097],
                [105.5672139, 21.5543315],
                [105.5668167, 21.554609],
                [105.5667282, 21.5546944],
                [105.5666614, 21.5547955],
                [105.5666332, 21.5548612],
                [105.5666143, 21.5549298],
                [105.566605, 21.555],
                [105.5665779, 21.5559007],
                [105.5665628, 21.5559732],
                [105.5665111, 21.5561126],
                [105.5664694, 21.5561876],
                [105.5663615, 21.5563231],
                [105.5657333, 21.556871],
                [105.5652773, 21.5571903],
                [105.5650623, 21.5573591],
                [105.5648443, 21.5575641],
                [105.5646309, 21.5578089],
                [105.5641803, 21.5581457],
                [105.5641063, 21.5581838],
                [105.5639496, 21.558243],
                [105.5637458, 21.5582829],
                [105.5635822, 21.5584351],
                [105.5632791, 21.5584501],
                [105.5632102, 21.5584693],
                [105.5631574, 21.5585066],
                [105.5631016, 21.5585739],
                [105.5630397, 21.5586819],
                [105.5630172, 21.5587446],
                [105.5629068, 21.5593794],
                [105.562896, 21.5594068],
                [105.562858, 21.5594529],
                [105.5628321, 21.5594698],
                [105.5627722, 21.5594878],
                [105.5627963, 21.5596325],
                [105.5628159, 21.5596902],
                [105.5628714, 21.5597996],
                [105.5629008, 21.5598835],
                [105.5629205, 21.5599699],
                [105.5629302, 21.5600577],
                [105.56293, 21.5601459],
                [105.5629197, 21.5602337],
                [105.5628778, 21.5603832],
                [105.562807, 21.560523],
                [105.5622438, 21.5612988],
                [105.5620453, 21.5614186],
                [105.5615491, 21.5626384],
                [105.5615199, 21.5627553],
                [105.5614823, 21.5629926],
                [105.5614727, 21.5632396],
                [105.5614793, 21.5633668],
                [105.5612594, 21.5635863],
                [105.5609053, 21.5641251],
                [105.5609858, 21.5644743],
                [105.5601114, 21.5664499],
                [105.5600376, 21.5666545],
                [105.5599905, 21.5668478],
                [105.5599319, 21.5670197],
                [105.5598926, 21.5671022],
                [105.5597949, 21.5672581],
                [105.5596762, 21.5673965],
                [105.5595374, 21.5675175],
                [105.5594824, 21.5675912],
                [105.5593901, 21.5677486],
                [105.5593228, 21.5679166],
                [105.5592957, 21.5680182],
                [105.5592692, 21.568226],
                [105.5590975, 21.5685951],
                [105.5586979, 21.5693135],
                [105.558498, 21.5694939],
                [105.5584297, 21.5695904],
                [105.5583929, 21.5696985],
                [105.5583601, 21.570032],
                [105.5583277, 21.5700967],
                [105.558266, 21.5701566],
                [105.5579764, 21.5702813],
                [105.5575848, 21.5706206],
                [105.5573273, 21.5707802],
                [105.5571073, 21.5708301],
                [105.5568874, 21.5709498],
                [105.5564475, 21.5712292],
                [105.5560356, 21.5714594],
                [105.5558247, 21.5715665],
                [105.5549562, 21.5719775],
                [105.5542991, 21.5724389],
                [105.5540872, 21.5725661],
                [105.5537965, 21.5727727],
                [105.5536754, 21.5728345],
                [105.5535684, 21.5728735],
                [105.5534971, 21.5729228],
                [105.5534472, 21.5729927],
                [105.5533576, 21.5732895],
                [105.5533319, 21.5733532],
                [105.5532557, 21.5734691],
                [105.5532168, 21.5735097],
                [105.5531269, 21.5735788],
                [105.5528828, 21.573711],
                [105.5527589, 21.5737417],
                [105.5526947, 21.5737443],
                [105.5525791, 21.5737273],
                [105.5524818, 21.5736883],
                [105.5523744, 21.5736248],
                [105.552307, 21.5735956],
                [105.5521835, 21.5735629],
                [105.5520755, 21.5735539],
                [105.551641, 21.5735938],
                [105.5512762, 21.5734741],
                [105.550926, 21.5733067],
                [105.5505572, 21.5730975],
                [105.550364, 21.5729727],
                [105.5501765, 21.5728405],
                [105.5500537, 21.5727793],
                [105.5499145, 21.5727352],
                [105.5498385, 21.5727208],
                [105.5494577, 21.5727008],
                [105.5491412, 21.5727407],
                [105.5487388, 21.5728081],
                [105.5479664, 21.5730101],
                [105.5478497, 21.5730146],
                [105.547733, 21.5730101],
                [105.5475476, 21.5729842],
                [105.5474567, 21.5729627],
                [105.5466521, 21.5729453],
                [105.5446592, 21.5731698],
                [105.5445592, 21.573135],
                [105.5445062, 21.5731287],
                [105.5444131, 21.5731357],
                [105.5443373, 21.5731598],
                [105.5441424, 21.5732851],
                [105.543935, 21.5733918],
                [105.5438109, 21.5734442],
                [105.5435541, 21.5735289],
                [105.5428044, 21.5737459],
                [105.542634, 21.5737495],
                [105.5424811, 21.5737776],
                [105.5424196, 21.5737983],
                [105.5406654, 21.5720573],
                [105.5404646, 21.5718403],
                [105.5402714, 21.5716174],
                [105.540086, 21.5713888],
                [105.5397956, 21.5709967],
                [105.5395281, 21.5705906],
                [105.5390614, 21.5701117],
                [105.5389728, 21.5699688],
                [105.5389166, 21.5698124],
                [105.5388959, 21.5696732],
                [105.5389488, 21.5694831],
                [105.5392921, 21.5686949],
                [105.5393216, 21.5685846],
                [105.5393539, 21.5683595],
                [105.5393565, 21.5682459],
                [105.5393369, 21.5680345],
                [105.5393154, 21.5679302],
                [105.5392492, 21.5677271],
                [105.5386698, 21.5664599],
                [105.5379188, 21.5652127],
                [105.5378759, 21.5649732],
                [105.5376809, 21.564742],
                [105.5375725, 21.564635],
                [105.5374575, 21.5645342],
                [105.5373316, 21.5644368],
                [105.5371995, 21.5643468],
                [105.5370617, 21.5642647],
                [105.5369186, 21.5641907],
                [105.5367708, 21.5641251],
                [105.5362656, 21.5638564],
                [105.5357786, 21.5635601],
                [105.5355426, 21.5634018],
                [105.5353117, 21.5632371],
                [105.5349019, 21.562918],
                [105.5345117, 21.5625784],
                [105.5343243, 21.5624012],
                [105.5341422, 21.5622193],
                [105.530591, 21.5581382],
                [105.5304618, 21.5579562],
                [105.5303411, 21.5577691],
                [105.5302293, 21.5575774],
                [105.5301263, 21.5573814],
                [105.5300325, 21.5571814],
                [105.5299481, 21.5569779],
                [105.5298731, 21.5567712],
                [105.5298078, 21.5565617],
                [105.529754, 21.5563575],
                [105.529674, 21.5559438],
                [105.5296312, 21.5555253],
                [105.5296237, 21.5553152],
                [105.5296469, 21.5545061],
                [105.5296406, 21.554191],
                [105.5296242, 21.5538763],
                [105.5295977, 21.5535621],
                [105.529561, 21.5532488],
                [105.529516, 21.5529472],
                [105.529398, 21.5523482],
                [105.529325, 21.5520514],
                [105.5277854, 21.54796],
                [105.5276728, 21.5473687],
                [105.5276111, 21.5471268],
                [105.5275681, 21.5470091],
                [105.5274582, 21.5467825],
                [105.5273093, 21.546559],
                [105.5272231, 21.5464547],
                [105.527029, 21.5462636],
                [105.5264389, 21.5451659],
                [105.5254412, 21.5447368],
                [105.5241108, 21.5417829],
                [105.523746, 21.541229],
                [105.5236364, 21.5410869],
                [105.5235914, 21.54101],
                [105.5235226, 21.5408473],
                [105.5234834, 21.5406753],
                [105.5234753, 21.5404988],
                [105.5235068, 21.5399352],
                [105.5235057, 21.5398286],
                [105.5234778, 21.5396173],
                [105.5234506, 21.5395118],
                [105.5233705, 21.539308],
                [105.5228716, 21.5385645],
                [105.5228341, 21.5383449],
                [105.5227976, 21.5380181],
                [105.5227697, 21.5378559],
                [105.5227065, 21.5375788],
                [105.5226678, 21.5374418],
                [105.5221905, 21.5360804],
                [105.5220216, 21.5357527],
                [105.5218209, 21.5354408],
                [105.5215903, 21.5351473],
                [105.5213317, 21.5348749],
                [105.5211925, 21.5347472],
                [105.5208966, 21.5345108],
                [105.5205785, 21.5343007],
                [105.5202411, 21.5341187],
                [105.520066, 21.5340386],
                [105.5194545, 21.5338241],
                [105.5189964, 21.53373],
                [105.5187647, 21.5336955],
                [105.5182989, 21.5336518],
                [105.5178319, 21.5336418],
                [105.5174502, 21.5336542],
                [105.5173028, 21.5336685],
                [105.5171567, 21.5336922],
                [105.5170128, 21.5337253],
                [105.5168717, 21.5337676],
                [105.5167341, 21.5338189],
                [105.5166006, 21.533879],
                [105.5163776, 21.5340051],
                [105.5162727, 21.5340774],
                [105.516078, 21.5342392],
                [105.5140608, 21.5362403],
                [105.5140047, 21.5362861],
                [105.5138789, 21.5363606],
                [105.5138105, 21.5363884],
                [105.5136663, 21.5364238],
                [105.5135078, 21.5364306],
                [105.5134289, 21.5364219],
                [105.5132766, 21.5363809],
                [105.5132049, 21.536349],
                [105.5112308, 21.5352861],
                [105.5101002, 21.5342858],
                [105.5096726, 21.5340374],
                [105.5097952, 21.5334878],
                [105.5097765, 21.5332214],
                [105.5096158, 21.5330388],
                [105.5088138, 21.532442],
                [105.5085109, 21.5322432],
                [105.5078222, 21.5319209],
                [105.5073952, 21.531839],
                [105.5069153, 21.531857],
                [105.5065772, 21.5317582],
                [105.5063632, 21.5315757],
                [105.506167, 21.5313932],
                [105.5060953, 21.5312102],
                [105.5060232, 21.5309275],
                [105.5059156, 21.5306615],
                [105.5056834, 21.530346],
                [105.5051478, 21.5296984],
                [105.5050403, 21.5294491],
                [105.5050565, 21.5290162],
                [105.5050177, 21.5280842],
                [105.5049108, 21.5279846],
                [105.5046794, 21.527902],
                [105.5043238, 21.5278866],
                [105.5035995, 21.5280551],
                [105.503049, 21.5282565],
                [105.5026408, 21.5284241],
                [105.5021612, 21.5285587],
                [105.5018865, 21.5285497],
                [105.50163, 21.5285472],
                [105.50147, 21.5285227],
                [105.5011499, 21.5283991],
                [105.500936, 21.528225],
                [105.5008021, 21.5280509],
                [105.500695, 21.5279016],
                [105.5005599, 21.5273535],
                [105.5001716, 21.5261877],
                [105.5000191, 21.5260459],
                [105.4990271, 21.5254254],
                [105.4985864, 21.5253091],
                [105.4980044, 21.5252518],
                [105.4974225, 21.5252388],
                [105.4971867, 21.525269],
                [105.4971557, 21.5254016],
                [105.4970469, 21.5257553],
                [105.4969225, 21.5261679],
                [105.4967668, 21.5266543],
                [105.4965321, 21.5269936],
                [105.4960589, 21.5273536],
                [105.4946206, 21.5283562],
                [105.4945868, 21.5283393],
                [105.4943507, 21.5282663],
                [105.493942, 21.5283116],
                [105.4937375, 21.5282829],
                [105.4936577, 21.5279298],
                [105.493719, 21.5274879],
                [105.4935916, 21.5269875],
                [105.4932437, 21.5264144],
                [105.4928956, 21.5257823],
                [105.4926418, 21.5251352],
                [105.4923244, 21.5242528],
                [105.4919613, 21.5238268],
                [105.4911087, 21.522784],
                [105.4906348, 21.5221523],
                [105.4905231, 21.5216814],
                [105.4906946, 21.5212539],
                [105.4909449, 21.5208262],
                [105.4908963, 21.5204141],
                [105.4907066, 21.5201349],
                [105.4902487, 21.519562],
                [105.4894915, 21.518828],
                [105.4883413, 21.5181394],
                [105.4872705, 21.5176566],
                [105.486445, 21.5175398],
                [105.4857538, 21.517712],
                [105.4850163, 21.5178841],
                [105.4842216, 21.5181514],
                [105.4837671, 21.5185944],
                [105.483265, 21.5189198],
                [105.4828094, 21.5190242],
                [105.4823849, 21.5190254],
                [105.4819137, 21.5191887],
                [105.4811448, 21.5195198],
                [105.4794395, 21.5193475],
                [105.4790707, 21.5199072],
                [105.478751, 21.5205335],
                [105.4779608, 21.5209523],
                [105.4773683, 21.5212781],
                [105.4768004, 21.521627],
                [105.476455, 21.5219057],
                [105.4761343, 21.5221843],
                [105.4760286, 21.5224535],
                [105.4759032, 21.5228575],
                [105.4755901, 21.5232411],
                [105.4752921, 21.5234776],
                [105.4747582, 21.5237],
                [105.4742555, 21.5238781],
                [105.4739413, 21.5239527],
                [105.4737527, 21.5239531],
                [105.473611, 21.5239094],
                [105.4733902, 21.5237186],
                [105.4732306, 21.5229829],
                [105.4729775, 21.5225271],
                [105.4726141, 21.5219981],
                [105.4726289, 21.5216889],
                [105.4728791, 21.5212906],
                [105.4729728, 21.5210842],
                [105.4729557, 21.5206573],
                [105.4727499, 21.5202456],
                [105.4724814, 21.5198636],
                [105.4720085, 21.5194821],
                [105.4714254, 21.5191009],
                [105.4708428, 21.5188523],
                [105.4700398, 21.5185158],
                [105.4696299, 21.5181931],
                [105.4692516, 21.5178998],
                [105.4688107, 21.51768],
                [105.4681972, 21.5175788],
                [105.4667031, 21.5175093],
                [105.4658535, 21.5173498],
                [105.464688, 21.5167345],
                [105.4641993, 21.5163384],
                [105.4637586, 21.5162218],
                [105.4633973, 21.5162964],
                [105.4628239, 21.5167224],
                [105.4624405, 21.51705],
                [105.4617971, 21.5174493],
                [105.4611373, 21.5176425],
                [105.4601792, 21.5179838],
                [105.4593946, 21.5185013],
                [105.4589234, 21.5186646],
                [105.4586243, 21.5185623],
                [105.4581199, 21.5181663],
                [105.4576313, 21.5177994],
                [105.4572056, 21.5174325],
                [105.4569493, 21.5170181],
                [105.4557054, 21.515683],
                [105.4561756, 21.5153129],
                [105.4566829, 21.5138921],
                [105.4567853, 21.513738],
                [105.4566894, 21.5132818],
                [105.4564827, 21.512502],
                [105.4560549, 21.511502],
                [105.4554555, 21.5108558],
                [105.4547318, 21.5106958],
                [105.4534575, 21.5105078],
                [105.4518697, 21.5105563],
                [105.4506274, 21.5105008],
                [105.4487869, 21.5102112],
                [105.4476071, 21.5100525],
                [105.4467892, 21.5099516],
                [105.4464742, 21.5097757],
                [105.4456709, 21.5093362],
                [105.444994, 21.5090582],
                [105.444475, 21.508986],
                [105.4441607, 21.5090457],
                [105.4437054, 21.5092678],
                [105.4432975, 21.509578],
                [105.4428739, 21.5098736],
                [105.4423709, 21.5099339],
                [105.4417733, 21.5098766],
                [105.4407198, 21.5098794],
                [105.4402639, 21.5098953],
                [105.4399807, 21.5098371],
                [105.4393037, 21.5095298],
                [105.4386578, 21.5091044],
                [105.4374922, 21.5084007],
                [105.4363271, 21.5078443],
                [105.4346737, 21.5070241],
                [105.4342801, 21.5068189],
                [105.4337921, 21.5066436],
                [105.4330056, 21.5065573],
                [105.4321094, 21.5065006],
                [105.4312598, 21.5063409],
                [105.4304573, 21.5061221],
                [105.4297017, 21.5058001],
                [105.4290399, 21.505316],
                [105.4283465, 21.5047877],
                [105.4275426, 21.5040661],
                [105.4264068, 21.5027596],
                [105.4254911, 21.501467],
                [105.4240542, 21.49902],
                [105.4237073, 21.4983901],
                [105.4234584, 21.4975892],
                [105.4229139, 21.4982563],
                [105.4225109, 21.4985813],
                [105.4221601, 21.49902],
                [105.4211482, 21.5005902],
                [105.4208184, 21.5014391],
                [105.4204776, 21.5019865],
                [105.4201069, 21.5026187],
                [105.4197058, 21.5032134],
                [105.4194851, 21.503506],
                [105.4193852, 21.5037795],
                [105.4193158, 21.5041587],
                [105.419076, 21.5048005],
                [105.4188863, 21.5053573],
                [105.4188173, 21.5058384],
                [105.4187984, 21.5063005],
                [105.4187099, 21.5070644],
                [105.4185406, 21.5076872],
                [105.4184712, 21.5080646],
                [105.4183108, 21.5083289],
                [105.4179086, 21.5085091],
                [105.4173549, 21.5085671],
                [105.4167311, 21.5087477],
                [105.4161877, 21.5088717],
                [105.4156042, 21.5090146],
                [105.4150409, 21.5092235],
                [105.4146891, 21.5094695],
                [105.4143377, 21.509791],
                [105.4139963, 21.5101407],
                [105.4137156, 21.510594],
                [105.4134457, 21.5113019],
                [105.4132857, 21.5116889],
                [105.4130955, 21.5120759],
                [105.4128745, 21.5122651],
                [105.4124222, 21.5125396],
                [105.4120905, 21.5127573],
                [105.4117584, 21.5128241],
                [105.4113355, 21.5128629],
                [105.4109631, 21.5129016],
                [105.4105299, 21.5128177],
                [105.4099955, 21.5126022],
                [105.4095922, 21.5123863],
                [105.409068, 21.5121801],
                [105.4087557, 21.5121149],
                [105.4083733, 21.5122007],
                [105.4075171, 21.5121085],
                [105.4070437, 21.5120625],
                [105.4066607, 21.511922],
                [105.4063786, 21.5118567],
                [105.4061472, 21.5119421],
                [105.4059363, 21.5121312],
                [105.4055948, 21.5124433],
                [105.4052633, 21.5127269],
                [105.405002, 21.5129255],
                [105.4046802, 21.5130866],
                [105.4042677, 21.5131913],
                [105.4033721, 21.5134104],
                [105.402849, 21.5135908],
                [105.4024465, 21.5137049],
                [105.4019532, 21.5137532],
                [105.4014295, 21.5137074],
                [105.4009356, 21.5135294],
                [105.4004818, 21.5132853],
                [105.3999674, 21.5129753],
                [105.3991909, 21.5125718],
                [105.3983937, 21.5119231],
                [105.3976366, 21.5112271],
                [105.3971718, 21.5106247],
                [105.3969698, 21.5104083],
                [105.3965769, 21.5103432],
                [105.3960736, 21.510401],
                [105.3952383, 21.5105728],
                [105.3944735, 21.5107349],
                [105.3937189, 21.5109725],
                [105.3931853, 21.5110302],
                [105.3915545, 21.5111849],
                [105.3909708, 21.5112806],
                [105.3904475, 21.5114138],
                [105.3899445, 21.5115658],
                [105.3894616, 21.5117273],
                [105.3891399, 21.5119261],
                [105.3890195, 21.5120772],
                [105.3889396, 21.5123603],
                [105.3888199, 21.5127472],
                [105.3885996, 21.5132097],
                [105.3884899, 21.5135872],
                [105.3883808, 21.5142569],
                [105.3881405, 21.5147476],
                [105.388081, 21.5151156],
                [105.3881025, 21.5156624],
                [105.3880437, 21.5162661],
                [105.3880044, 21.5166056],
                [105.3878144, 21.5170869],
                [105.3876854, 21.517851],
                [105.3874351, 21.5183891],
                [105.3872952, 21.5187666],
                [105.3871548, 21.5189932],
                [105.3869141, 21.5193615],
                [105.3866833, 21.5196732],
                [105.3863615, 21.5198437],
                [105.3856469, 21.5199868],
                [105.3847309, 21.5201397],
                [105.3840367, 21.5203393],
                [105.3834131, 21.5206426],
                [105.3829907, 21.5208603],
                [105.3826394, 21.5212854],
                [105.3822883, 21.5217955],
                [105.3820677, 21.5221543],
                [105.3818671, 21.5224848],
                [105.3816058, 21.5226928],
                [105.3813041, 21.5228066],
                [105.3808711, 21.5228453],
                [105.3803371, 21.5227711],
                [105.3798333, 21.5226214],
                [105.3793399, 21.5226226],
                [105.3788665, 21.5226048],
                [105.3777588, 21.5225884],
                [105.377205, 21.5226368],
                [105.3766313, 21.522723],
                [105.3759769, 21.5228093],
                [105.3753629, 21.5229426],
                [105.3749806, 21.5230567],
                [105.3741859, 21.5233885],
                [105.3734818, 21.5237296],
                [105.3725266, 21.5242974],
                [105.3718927, 21.5244874],
                [105.3713087, 21.5245547],
                [105.3708354, 21.5245463],
                [105.3701004, 21.5245857],
                [105.369677, 21.5244075],
                [105.3693444, 21.5242574],
                [105.3690017, 21.5241732],
                [105.3685187, 21.524344],
                [105.3683476, 21.5243539],
                [105.3681963, 21.5242694],
                [105.3679439, 21.5240153],
                [105.3677923, 21.5237705],
                [105.3676506, 21.5234973],
                [105.3677003, 21.5232237],
                [105.3676696, 21.5230447],
                [105.3674982, 21.5229602],
                [105.367196, 21.5229514],
                [105.366914, 21.5229333],
                [105.3667124, 21.5228299],
                [105.3664803, 21.5226701],
                [105.3662988, 21.5225668],
                [105.3661278, 21.5226238],
                [105.3659972, 21.5227466],
                [105.3659273, 21.5229825],
                [105.3659179, 21.523256],
                [105.3658376, 21.5233693],
                [105.3656667, 21.5234735],
                [105.3653748, 21.5235589],
                [105.3651334, 21.5236632],
                [105.3649723, 21.523673],
                [105.3648312, 21.5236449],
                [105.3648211, 21.5236262],
                [105.3647505, 21.5235414],
                [105.3648308, 21.5234281],
                [105.3649715, 21.5233146],
                [105.365072, 21.5232201],
                [105.3650615, 21.523107],
                [105.3648397, 21.5229849],
                [105.3644767, 21.5227971],
                [105.3640331, 21.5225906],
                [105.3638511, 21.5223176],
                [105.3636592, 21.5220634],
                [105.3635679, 21.5217713],
                [105.3632035, 21.5210366],
                [105.3629304, 21.5205562],
                [105.362264, 21.5198128],
                [105.3618102, 21.5195686],
                [105.3612158, 21.5194851],
                [105.3605914, 21.5194488],
                [105.3600772, 21.5191952],
                [105.3596033, 21.5189322],
                [105.3590794, 21.5188201],
                [105.3588578, 21.5188395],
                [105.3579108, 21.5186436],
                [105.357206, 21.5186639],
                [105.3565943, 21.5188762],
                [105.3560887, 21.5188737],
                [105.3557666, 21.5189122],
                [105.3554644, 21.5188751],
                [105.355172, 21.5187437],
                [105.3542138, 21.5181139],
                [105.3536194, 21.5179737],
                [105.3530554, 21.5179185],
                [105.3523305, 21.5179954],
                [105.3516965, 21.5181853],
                [105.3511333, 21.5184411],
                [105.3506505, 21.5186684],
                [105.3499361, 21.5189056],
                [105.3493023, 21.519171],
                [105.3487489, 21.5193701],
                [105.3483362, 21.5194464],
                [105.3477722, 21.5194194],
                [105.3473291, 21.5193637],
                [105.3468152, 21.5192705],
                [105.3466137, 21.519252],
                [105.3462911, 21.5190358],
                [105.3459884, 21.5187819],
                [105.3456553, 21.5184807],
                [105.3450911, 21.5183877],
                [105.344457, 21.5185022],
                [105.344175, 21.5184273],
                [105.3439428, 21.5182297],
                [105.3437508, 21.5178907],
                [105.3434057, 21.5173536],
                [105.3432131, 21.5167394],
                [105.3437548, 21.5162705],
                [105.344103, 21.516018],
                [105.3424777, 21.5147448],
                [105.341407, 21.5139819],
                [105.3411624, 21.513732],
                [105.338082, 21.5119456],
                [105.3379657, 21.5114152],
                [105.3379242, 21.510906],
                [105.3378225, 21.510416],
                [105.3377106, 21.5099541],
                [105.3376193, 21.5096525],
                [105.3372551, 21.5089367],
                [105.3370332, 21.5087202],
                [105.336922, 21.5085696],
                [105.3369113, 21.5083338],
                [105.336971, 21.5080131],
                [105.3371513, 21.5075979],
                [105.3374225, 21.5072956],
                [105.3378649, 21.5070496],
                [105.3383479, 21.5068412],
                [105.3388006, 21.5066517],
                [105.3391624, 21.5063775],
                [105.3394536, 21.5060373],
                [105.3397043, 21.5056031],
                [105.3399448, 21.5050746],
                [105.3402304, 21.5042399],
                [105.3404502, 21.5038815],
                [105.3404938, 21.5036613],
                [105.3398172, 21.5036489],
                [105.338818, 21.5040362],
                [105.3380387, 21.5041479],
                [105.3370686, 21.5044251],
                [105.3358626, 21.5044276],
                [105.3351419, 21.5044015],
                [105.3345093, 21.5043477],
                [105.3340388, 21.5043762],
                [105.3334064, 21.504405],
                [105.3330093, 21.5044334],
                [105.3325244, 21.504627],
                [105.332172, 21.504903],
                [105.3314525, 21.5054029],
                [105.3309091, 21.5057345],
                [105.3304537, 21.5059696],
                [105.3299981, 21.5061358],
                [105.3293221, 21.5063714],
                [105.3281903, 21.5067178],
                [105.3274112, 21.5069397],
                [105.3265587, 21.5071618],
                [105.3259118, 21.5072871],
                [105.3256321, 21.5071911],
                [105.3248528, 21.5070172],
                [105.3248391, 21.5065652],
                [105.3245424, 21.5047303],
                [105.3238649, 21.5028687],
                [105.3230702, 21.5008706],
                [105.3227753, 21.4998584],
                [105.3225643, 21.4990211],
                [105.3224806, 21.4976045],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [107.8185394, 16.0582918],
                [107.818754, 16.0578845],
                [107.8187648, 16.0573896],
                [107.8192368, 16.0572917],
                [107.8200415, 16.0566576],
                [107.8217474, 16.056271],
                [107.8228363, 16.0562091],
                [107.8235659, 16.0559307],
                [107.8243437, 16.0558792],
                [107.8252611, 16.0552606],
                [107.8255309, 16.0552937],
                [107.8260729, 16.0553376],
                [107.8263447, 16.0553482],
                [107.826809, 16.0553489],
                [107.8272727, 16.0553276],
                [107.8281257, 16.0552245],
                [107.8296465, 16.0546806],
                [107.8298581, 16.0546207],
                [107.8300756, 16.0545852],
                [107.8302099, 16.0545758],
                [107.8303445, 16.0545756],
                [107.8304788, 16.0545848],
                [107.8307355, 16.0546182],
                [107.8308597, 16.0546253],
                [107.8311083, 16.0546162],
                [107.8313394, 16.0545792],
                [107.8314527, 16.0545506],
                [107.8316729, 16.0544735],
                [107.8317788, 16.0544254],
                [107.8329536, 16.0549358],
                [107.8330491, 16.0550117],
                [107.8331381, 16.0550945],
                [107.8332202, 16.0551837],
                [107.8332947, 16.0552787],
                [107.8333613, 16.0553791],
                [107.8334524, 16.0555537],
                [107.833489, 16.0556448],
                [107.8335437, 16.0558328],
                [107.8336081, 16.0568535],
                [107.8347561, 16.0575752],
                [107.8352281, 16.0579567],
                [107.8364512, 16.0584103],
                [107.8379211, 16.0588124],
                [107.8380863, 16.0588876],
                [107.8384076, 16.0590553],
                [107.838715, 16.0592455],
                [107.8388814, 16.0593621],
                [107.8390425, 16.0594853],
                [107.839348, 16.0597507],
                [107.8396699, 16.0604105],
                [107.8416386, 16.0602146],
                [107.841939, 16.0598899],
                [107.8422098, 16.059641],
                [107.8423521, 16.0595238],
                [107.8441867, 16.0601218],
                [107.8443154, 16.0601941],
                [107.844584, 16.060318],
                [107.8448728, 16.0604154],
                [107.8450249, 16.0604531],
                [107.8453347, 16.0605033],
                [107.8455754, 16.0604419],
                [107.8456981, 16.0604212],
                [107.8459462, 16.0604002],
                [107.846083, 16.0604003],
                [107.8463553, 16.0604252],
                [107.8464897, 16.0604499],
                [107.8468306, 16.0605376],
                [107.8469379, 16.0605514],
                [107.8470459, 16.0605561],
                [107.847154, 16.0605514],
                [107.8472613, 16.0605376],
                [107.8473668, 16.0605146],
                [107.8474697, 16.0604827],
                [107.8476494, 16.0604017],
                [107.847813, 16.0602938],
                [107.8479561, 16.0601616],
                [107.8480749, 16.0600088],
                [107.8481242, 16.0599259],
                [107.8484066, 16.059368],
                [107.8484979, 16.0592549],
                [107.8486124, 16.0591633],
                [107.8487472, 16.0590965],
                [107.8488966, 16.0590592],
                [107.8489736, 16.0590529],
                [107.8491273, 16.059065],
                [107.8492565, 16.059066],
                [107.8495136, 16.0590444],
                [107.8496416, 16.0590217],
                [107.8498916, 16.0589528],
                [107.8501305, 16.0588537],
                [107.850347, 16.0592637],
                [107.8505905, 16.0596595],
                [107.85086, 16.0600394],
                [107.8511599, 16.060408],
                [107.8514841, 16.0607571],
                [107.8519487, 16.0611931],
                [107.8520951, 16.0612905],
                [107.8521812, 16.0613331],
                [107.8523637, 16.061395],
                [107.8524586, 16.0614138],
                [107.8526518, 16.0614261],
                [107.8530648, 16.0610755],
                [107.8535087, 16.0609382],
                [107.8537338, 16.0608797],
                [107.8542019, 16.0607811],
                [107.8544447, 16.0607418],
                [107.8549336, 16.0606867],
                [107.8554252, 16.0606631],
                [107.8569862, 16.0606683],
                [107.8578767, 16.0608229],
                [107.8597853, 16.0608472],
                [107.8599339, 16.0608583],
                [107.8602282, 16.0609032],
                [107.8603731, 16.0609367],
                [107.8606402, 16.0610197],
                [107.8607623, 16.0610673],
                [107.8609988, 16.0611786],
                [107.8610593, 16.0612193],
                [107.861167, 16.061316],
                [107.8612132, 16.0613713],
                [107.8612885, 16.0614931],
                [107.8629997, 16.0614828],
                [107.8648612, 16.0597352],
                [107.8649722, 16.0596631],
                [107.8650212, 16.059619],
                [107.8651049, 16.0595148],
                [107.8651642, 16.0593929],
                [107.8651831, 16.0593279],
                [107.8652179, 16.059283],
                [107.8653015, 16.0592053],
                [107.8654106, 16.0591419],
                [107.8655431, 16.0591029],
                [107.8656122, 16.059096],
                [107.8661755, 16.0591527],
                [107.8665027, 16.059395],
                [107.8674039, 16.0593795],
                [107.8675425, 16.0592342],
                [107.8676026, 16.0591543],
                [107.8677013, 16.058985],
                [107.8677713, 16.058806],
                [107.8677957, 16.0587132],
                [107.8678223, 16.0585238],
                [107.8678681, 16.0583317],
                [107.8679477, 16.0581501],
                [107.8679994, 16.058065],
                [107.868273, 16.0577144],
                [107.8683185, 16.0576684],
                [107.8684208, 16.0575882],
                [107.8685379, 16.0575246],
                [107.8686672, 16.0574806],
                [107.8688566, 16.0574474],
                [107.8690958, 16.0573854],
                [107.8693346, 16.057289],
                [107.8694531, 16.0572264],
                [107.8695666, 16.0571558],
                [107.8696745, 16.0570776],
                [107.8697763, 16.0569921],
                [107.8698716, 16.0568999],
                [107.8706548, 16.0561009],
                [107.8706762, 16.0555132],
                [107.870704, 16.0553446],
                [107.870762, 16.0551832],
                [107.8708562, 16.0550233],
                [107.8709801, 16.0548833],
                [107.8713092, 16.0546265],
                [107.8713715, 16.0545032],
                [107.8714425, 16.0543844],
                [107.871522, 16.0542706],
                [107.8716903, 16.0540752],
                [107.8718671, 16.0539151],
                [107.8725323, 16.0538738],
                [107.8727434, 16.053507],
                [107.8727847, 16.0534512],
                [107.8728864, 16.0533557],
                [107.8730046, 16.0532877],
                [107.8731358, 16.0532474],
                [107.8735167, 16.0532449],
                [107.8742174, 16.0524791],
                [107.8742892, 16.0524157],
                [107.8743672, 16.0523595],
                [107.8744506, 16.0523109],
                [107.8746177, 16.0522425],
                [107.8747819, 16.0522062],
                [107.8751716, 16.0521829],
                [107.8755686, 16.0518066],
                [107.8757102, 16.051804],
                [107.8758517, 16.0518109],
                [107.8761075, 16.0518477],
                [107.8763332, 16.0519077],
                [107.8765503, 16.0519921],
                [107.8779986, 16.0520076],
                [107.8790769, 16.0512292],
                [107.8806487, 16.0516674],
                [107.8818557, 16.0504816],
                [107.8822634, 16.0498939],
                [107.8824915, 16.0497876],
                [107.8825993, 16.0497234],
                [107.8827998, 16.0495743],
                [107.8829737, 16.049404],
                [107.8831222, 16.0492127],
                [107.8835615, 16.0485226],
                [107.8836152, 16.0482442],
                [107.8849456, 16.0479452],
                [107.8852622, 16.0478442],
                [107.885569, 16.0477183],
                [107.8858773, 16.0475606],
                [107.8861829, 16.0473689],
                [107.8864691, 16.0471513],
                [107.887617, 16.0474451],
                [107.8880355, 16.0482958],
                [107.8882393, 16.048404],
                [107.8887758, 16.0479143],
                [107.8888799, 16.0478484],
                [107.8890983, 16.0477332],
                [107.8893283, 16.047641],
                [107.8895767, 16.047571],
                [107.8897036, 16.047546],
                [107.8899606, 16.0475165],
                [107.89009, 16.0475122],
                [107.8908679, 16.0476488],
                [107.8921902, 16.0476514],
                [107.8922468, 16.0476335],
                [107.8922711, 16.0476167],
                [107.8923098, 16.0475649],
                [107.8923203, 16.0474954],
                [107.8922787, 16.0471564],
                [107.8922433, 16.0469824],
                [107.8917369, 16.0451922],
                [107.8916844, 16.0449625],
                [107.8916618, 16.0447282],
                [107.8916687, 16.0445013],
                [107.8916827, 16.0443885],
                [107.8917316, 16.0441663],
                [107.8920159, 16.0432332],
                [107.892062, 16.0431331],
                [107.8921165, 16.0430371],
                [107.8921792, 16.0429457],
                [107.8922495, 16.0428596],
                [107.892327, 16.0427795],
                [107.8924777, 16.0426553],
                [107.8925597, 16.0426014],
                [107.8927347, 16.0425114],
                [107.8934804, 16.0421814],
                [107.8934857, 16.0414184],
                [107.8931699, 16.0406198],
                [107.8931497, 16.0405021],
                [107.8931507, 16.0404424],
                [107.8931746, 16.0403254],
                [107.8931987, 16.0402667],
                [107.893269, 16.0401601],
                [107.8933141, 16.0401141],
                [107.893423, 16.0400677],
                [107.8934804, 16.0400522],
                [107.8936145, 16.0400367],
                [107.8943601, 16.0400316],
                [107.8949734, 16.0398363],
                [107.895576, 16.0396126],
                [107.8958729, 16.0394902],
                [107.896387, 16.0392583],
                [107.89664, 16.0391345],
                [107.8967532, 16.0390628],
                [107.8969651, 16.0389004],
                [107.897155, 16.0387146],
                [107.8973187, 16.0385102],
                [107.8974547, 16.0382905],
                [107.8975123, 16.0381753],
                [107.8975627, 16.038057],
                [107.8976425, 16.0378158],
                [107.897691, 16.0376981],
                [107.897817, 16.0374479],
                [107.8978965, 16.0373171],
                [107.8980776, 16.0370693],
                [107.8981785, 16.0369529],
                [107.8983995, 16.0367371],
                [107.8993812, 16.0361081],
                [107.899726, 16.0358741],
                [107.9000519, 16.0356164],
                [107.9003623, 16.0353316],
                [107.9006543, 16.03502],
                [107.9009209, 16.034688],
                [107.9010442, 16.034515],
                [107.9016316, 16.0336154],
                [107.9018661, 16.0337387],
                [107.9020959, 16.0338699],
                [107.9023206, 16.034009],
                [107.9025401, 16.0341557],
                [107.902754, 16.0343098],
                [107.902962, 16.0344712],
                [107.9031392, 16.0346184],
                [107.9034789, 16.0349284],
                [107.903641, 16.0350909],
                [107.903949, 16.0354302],
                [107.9040561, 16.0355367],
                [107.9042872, 16.0357326],
                [107.9045386, 16.035904],
                [107.9048073, 16.0360488],
                [107.9059124, 16.03675],
                [107.9069445, 16.0377242],
                [107.9079821, 16.0386658],
                [107.9090238, 16.0395753],
                [107.909925, 16.0403074],
                [107.9100103, 16.0404345],
                [107.9101982, 16.040677],
                [107.9104203, 16.0409145],
                [107.9105462, 16.0410312],
                [107.9108155, 16.0412457],
                [107.9124054, 16.0423666],
                [107.9125208, 16.0424619],
                [107.9127359, 16.0426686],
                [107.9128472, 16.0427936],
                [107.912951, 16.0429243],
                [107.9130471, 16.0430604],
                [107.9133839, 16.0431678],
                [107.9137102, 16.0433022],
                [107.9140234, 16.0434626],
                [107.9141978, 16.0435668],
                [107.9143667, 16.0436791],
                [107.9144109, 16.0437806],
                [107.9145238, 16.0439718],
                [107.9145919, 16.0440602],
                [107.9147361, 16.0442086],
                [107.9148872, 16.0443254],
                [107.9150534, 16.0444215],
                [107.915184, 16.0445513],
                [107.9154578, 16.0447989],
                [107.9157642, 16.0450423],
                [107.9161048, 16.0452773],
                [107.9171562, 16.0458135],
                [107.917639, 16.0461331],
                [107.9190874, 16.0461434],
                [107.9192542, 16.0461686],
                [107.9194192, 16.0462028],
                [107.9195819, 16.0462461],
                [107.9197419, 16.0462981],
                [107.9198968, 16.0463581],
                [107.920048, 16.0464263],
                [107.920195, 16.0465026],
                [107.9203373, 16.0465868],
                [107.9212224, 16.0475354],
                [107.9225314, 16.0480921],
                [107.9239797, 16.0490304],
                [107.9276275, 16.0509482],
                [107.9296231, 16.0516906],
                [107.9319835, 16.0532268],
                [107.9325843, 16.0533815],
                [107.9327165, 16.0535471],
                [107.932874, 16.0536908],
                [107.9330469, 16.0538058],
                [107.9331397, 16.0538535],
                [107.9333353, 16.0539279],
                [107.9333669, 16.0540251],
                [107.9334075, 16.0541191],
                [107.9334569, 16.0542091],
                [107.9335145, 16.0542945],
                [107.93358, 16.0543744],
                [107.9336528, 16.0544484],
                [107.9337323, 16.0545156],
                [107.9338158, 16.0545744],
                [107.9339974, 16.0546695],
                [107.9341936, 16.0547322],
                [107.9348373, 16.0549693],
                [107.935245, 16.055258],
                [107.9368114, 16.0556807],
                [107.9370475, 16.0559282],
                [107.9371567, 16.0559676],
                [107.9373663, 16.0560657],
                [107.9375694, 16.056194],
                [107.9376678, 16.0562707],
                [107.9377605, 16.0563535],
                [107.9379272, 16.0565365],
                [107.9382384, 16.0569489],
                [107.9406309, 16.0582067],
                [107.9414302, 16.0582119],
                [107.9423422, 16.0585882],
                [107.942478, 16.0586496],
                [107.9426094, 16.0587193],
                [107.942736, 16.0587968],
                [107.9428571, 16.058882],
                [107.9430706, 16.0590636],
                [107.9432595, 16.0592687],
                [107.9433251, 16.0593308],
                [107.9433955, 16.0593878],
                [107.9435492, 16.0594852],
                [107.9442197, 16.0597017],
                [107.9448366, 16.0596965],
                [107.9449581, 16.0597801],
                [107.9452143, 16.0599274],
                [107.9454857, 16.0600471],
                [107.9456385, 16.0601],
                [107.9457944, 16.0601442],
                [107.9459526, 16.0601793],
                [107.9461128, 16.0602054],
                [107.9468054, 16.0602687],
                [107.947433, 16.0604956],
                [107.9474985, 16.0605323],
                [107.947617, 16.0606229],
                [107.9477191, 16.0607386],
                [107.9477978, 16.060877],
                [107.9481089, 16.0613513],
                [107.948699, 16.0613822],
                [107.9496807, 16.0618926],
                [107.9509735, 16.061939],
                [107.9511412, 16.0620456],
                [107.9514895, 16.0622384],
                [107.9518533, 16.0624029],
                [107.9520588, 16.0624809],
                [107.9522679, 16.0625499],
                [107.9524799, 16.0626097],
                [107.9526946, 16.0626602],
                [107.9529114, 16.0627013],
                [107.95313, 16.0627329],
                [107.9532699, 16.0628593],
                [107.9534162, 16.0629788],
                [107.9535686, 16.0630912],
                [107.9537266, 16.063196],
                [107.9538899, 16.0632932],
                [107.954058, 16.0633824],
                [107.9541939, 16.063447],
                [107.9544731, 16.0635605],
                [107.9546159, 16.0636092],
                [107.954879, 16.0636822],
                [107.9550075, 16.0637278],
                [107.9551394, 16.0637817],
                [107.9553938, 16.0639082],
                [107.9559946, 16.0639082],
                [107.9571211, 16.0643412],
                [107.95784, 16.0646299],
                [107.9579347, 16.0646543],
                [107.9581283, 16.0646853],
                [107.9583262, 16.064692],
                [107.9585251, 16.0646734],
                [107.9590758, 16.0645598],
                [107.9597926, 16.0644443],
                [107.9605355, 16.0643599],
                [107.9609084, 16.0643309],
                [107.9608655, 16.0624029],
                [107.9608671, 16.0622651],
                [107.9608785, 16.0621276],
                [107.9608994, 16.0619912],
                [107.9609299, 16.0618565],
                [107.9609698, 16.0617238],
                [107.961019, 16.0615939],
                [107.9610772, 16.0614675],
                [107.9611441, 16.0613453],
                [107.9612927, 16.0611198],
                [107.9613582, 16.0610075],
                [107.9614654, 16.0607717],
                [107.961539, 16.0605243],
                [107.9615629, 16.0603976],
                [107.961584, 16.060146],
                [107.9615709, 16.0598939],
                [107.9615238, 16.0596455],
                [107.9614434, 16.0594052],
                [107.9613912, 16.0592893],
                [107.9612517, 16.0587119],
                [107.9611345, 16.058405],
                [107.9610872, 16.058248],
                [107.9610157, 16.0579283],
                [107.960991, 16.057761],
                [107.9609669, 16.0574239],
                [107.9609675, 16.0572549],
                [107.9609766, 16.0570861],
                [107.9610425, 16.0565158],
                [107.9610364, 16.0564284],
                [107.9609974, 16.0563481],
                [107.9609667, 16.0563142],
                [107.9608753, 16.0562527],
                [107.960782, 16.0561663],
                [107.9607153, 16.0560596],
                [107.9606806, 16.0559461],
                [107.9606761, 16.0558278],
                [107.960702, 16.0557121],
                [107.960726, 16.0556575],
                [107.9608869, 16.0554229],
                [107.9609133, 16.0553353],
                [107.9609406, 16.0551549],
                [107.9609356, 16.0549924],
                [107.9609228, 16.054912],
                [107.9608768, 16.0547556],
                [107.9605651, 16.0539279],
                [107.9604399, 16.0531697],
                [107.9603773, 16.0529794],
                [107.9603321, 16.0528872],
                [107.9602783, 16.0527993],
                [107.9602164, 16.0527165],
                [107.9598516, 16.0522989],
                [107.9598462, 16.0513709],
                [107.9598292, 16.0512424],
                [107.9597711, 16.0509894],
                [107.9596818, 16.0507449],
                [107.9595625, 16.0505124],
                [107.9594922, 16.0504017],
                [107.9594702, 16.0501825],
                [107.9594144, 16.0499688],
                [107.9593267, 16.049767],
                [107.9592098, 16.0495811],
                [107.9590657, 16.0494139],
                [107.9589844, 16.0493384],
                [107.9588056, 16.0492057],
                [107.9584891, 16.0484633],
                [107.9584502, 16.0482741],
                [107.9584398, 16.0481782],
                [107.9584375, 16.0479772],
                [107.958463, 16.0477695],
                [107.9585165, 16.0475668],
                [107.9585534, 16.0474683],
                [107.9585669, 16.0473903],
                [107.9585696, 16.0472323],
                [107.9585588, 16.0471539],
                [107.9585131, 16.0470012],
                [107.9584787, 16.0469288],
                [107.9583884, 16.0467957],
                [107.9582787, 16.0466643],
                [107.9581895, 16.046508],
                [107.9581296, 16.0463393],
                [107.9581002, 16.0461523],
                [107.9581067, 16.0459632],
                [107.9581491, 16.0457785],
                [107.9582292, 16.0455914],
                [107.9582822, 16.0454964],
                [107.9584328, 16.0452751],
                [107.9584651, 16.0451817],
                [107.9584746, 16.045071],
                [107.9584508, 16.0449494],
                [107.9583454, 16.0447365],
                [107.9582691, 16.0445246],
                [107.9582117, 16.0442721],
                [107.9581878, 16.0440146],
                [107.9581994, 16.0433749],
                [107.9582632, 16.0432005],
                [107.9582855, 16.0431105],
                [107.9583108, 16.042922],
                [107.9583074, 16.042727],
                [107.9582745, 16.0425346],
                [107.9583645, 16.0421373],
                [107.9583979, 16.0419365],
                [107.9584255, 16.0417141],
                [107.9584435, 16.0414908],
                [107.9584519, 16.041267],
                [107.9584507, 16.041043],
                [107.9584398, 16.0408192],
                [107.9583228, 16.0395031],
                [107.9583093, 16.0385656],
                [107.9582815, 16.0384193],
                [107.9582276, 16.0382757],
                [107.9581472, 16.0381394],
                [107.9579633, 16.0379221],
                [107.9579095, 16.0378101],
                [107.9578854, 16.0376881],
                [107.9578931, 16.0375633],
                [107.9579089, 16.0375025],
                [107.9579632, 16.0373889],
                [107.9580376, 16.0372734],
                [107.9580658, 16.0372052],
                [107.9580851, 16.0371341],
                [107.958095, 16.0370613],
                [107.9580956, 16.0369879],
                [107.9580867, 16.036915],
                [107.9581116, 16.03678],
                [107.9581667, 16.0366535],
                [107.9582543, 16.0365361],
                [107.958311, 16.0364832],
                [107.958374, 16.0364372],
                [107.9584424, 16.036399],
                [107.958515, 16.0363691],
                [107.9598677, 16.0359354],
                [107.9604578, 16.0347393],
                [107.9604578, 16.0341],
                [107.9609942, 16.0331204],
                [107.9609929, 16.0329195],
                [107.9610058, 16.0328198],
                [107.9610586, 16.0326254],
                [107.9611344, 16.0324625],
                [107.9611818, 16.0323857],
                [107.9612946, 16.0322439],
                [107.961756, 16.0318933],
                [107.9619512, 16.031808],
                [107.9620537, 16.0317773],
                [107.9622372, 16.0317438],
                [107.9623304, 16.0317367],
                [107.9629683, 16.0317335],
                [107.9630694, 16.03172],
                [107.9632674, 16.0316728],
                [107.9633633, 16.0316393],
                [107.9635614, 16.0315451],
                [107.9636613, 16.0314826],
                [107.9637556, 16.0314125],
                [107.9638436, 16.0313352],
                [107.9639246, 16.0312513],
                [107.9639983, 16.0311612],
                [107.9647601, 16.03079],
                [107.9649014, 16.0306971],
                [107.9650265, 16.0305847],
                [107.9651372, 16.030448],
                [107.9652274, 16.0302882],
                [107.9652883, 16.0301161],
                [107.9653072, 16.0300269],
                [107.9657793, 16.0292742],
                [107.9658654, 16.0291671],
                [107.9659587, 16.0290658],
                [107.9660589, 16.0289707],
                [107.9662617, 16.0288121],
                [107.9664659, 16.0286882],
                [107.9665732, 16.0286348],
                [107.9679036, 16.028408],
                [107.9679993, 16.0283809],
                [107.9681806, 16.0283017],
                [107.9682648, 16.0282503],
                [107.9683435, 16.0281914],
                [107.9684814, 16.0280548],
                [107.9685906, 16.0278959],
                [107.9688156, 16.0274284],
                [107.9697275, 16.0261497],
                [107.9697759, 16.0260502],
                [107.9698026, 16.0259435],
                [107.969805, 16.0258143],
                [107.9697758, 16.0256882],
                [107.9696496, 16.0254443],
                [107.9695911, 16.0253125],
                [107.9695416, 16.0251774],
                [107.9695012, 16.0250395],
                [107.9694702, 16.0248994],
                [107.9694486, 16.0247576],
                [107.96916, 16.0243255],
                [107.9691213, 16.0242146],
                [107.969116, 16.0240976],
                [107.9691406, 16.0239934],
                [107.969163, 16.0239444],
                [107.9692263, 16.0238566],
                [107.9693201, 16.0237601],
                [107.969368, 16.0236965],
                [107.9694447, 16.0235578],
                [107.9694936, 16.023408],
                [107.9695129, 16.023252],
                [107.9696599, 16.0231378],
                [107.9697258, 16.0230726],
                [107.9698405, 16.0229282],
                [107.9699349, 16.0227566],
                [107.9699724, 16.0226597],
                [107.9700007, 16.0225599],
                [107.9700194, 16.022458],
                [107.9700284, 16.022355],
                [107.9700277, 16.0222516],
                [107.9700172, 16.0221487],
                [107.9700172, 16.021004],
                [107.97003, 16.0208667],
                [107.9700202, 16.0207291],
                [107.9700068, 16.0206613],
                [107.9699879, 16.0205946],
                [107.9699429, 16.0204858],
                [107.9699212, 16.0203921],
                [107.9699277, 16.0202928],
                [107.9699648, 16.0201962],
                [107.9700297, 16.0201143],
                [107.9701586, 16.0200041],
                [107.9702417, 16.0199223],
                [107.9704706, 16.0196548],
                [107.970555, 16.0195691],
                [107.9707414, 16.0194159],
                [107.9708486, 16.0193454],
                [107.9709611, 16.0192827],
                [107.971078, 16.0192283],
                [107.9711988, 16.0191823],
                [107.9713229, 16.0191451],
                [107.9714495, 16.0191169],
                [107.9717714, 16.0190859],
                [107.9722381, 16.0186013],
                [107.9724944, 16.0185896],
                [107.9726213, 16.0185712],
                [107.9728698, 16.0185094],
                [107.9731071, 16.0184156],
                [107.9733328, 16.0182889],
                [107.973538, 16.0181332],
                [107.9736316, 16.0180455],
                [107.9743516, 16.0172813],
                [107.9743624, 16.0170957],
                [107.9744755, 16.0169717],
                [107.9745405, 16.0169176],
                [107.9746842, 16.0168276],
                [107.9748288, 16.0167683],
                [107.9749816, 16.0167329],
                [107.975161, 16.0167054],
                [107.97526, 16.0166775],
                [107.975356, 16.0166408],
                [107.975448, 16.0165959],
                [107.9755352, 16.016543],
                [107.9756171, 16.0164825],
                [107.9756927, 16.0164151],
                [107.9776347, 16.0144866],
                [107.9782969, 16.0140246],
                [107.9786217, 16.0137854],
                [107.9792088, 16.0133303],
                [107.9797804, 16.0128573],
                [107.9806495, 16.0126974],
                [107.9817009, 16.012156],
                [107.9817438, 16.0116816],
                [107.9835355, 16.0119394],
                [107.9832995, 16.0114186],
                [107.9832966, 16.0112154],
                [107.9832841, 16.0110124],
                [107.9832619, 16.0108102],
                [107.9831921, 16.0104235],
                [107.9831439, 16.0102327],
                [107.983133, 16.0100684],
                [107.9830956, 16.0099078],
                [107.9830285, 16.0097467],
                [107.982747, 16.0092994],
                [107.9830205, 16.0086394],
                [107.9829786, 16.0085306],
                [107.982928, 16.0084253],
                [107.9828689, 16.0083241],
                [107.9828018, 16.0082277],
                [107.982727, 16.0081367],
                [107.982645, 16.0080516],
                [107.9824709, 16.0079076],
                [107.982376, 16.0078454],
                [107.982173, 16.0077422],
                [107.9820844, 16.0076512],
                [107.9820469, 16.0076004],
                [107.9820077, 16.0075342],
                [107.9819077, 16.0072712],
                [107.9817947, 16.0070395],
                [107.9817273, 16.0069291],
                [107.9815207, 16.0066447],
                [107.9814884, 16.0065538],
                [107.9814812, 16.0065037],
                [107.9814831, 16.0063955],
                [107.9814757, 16.0063384],
                [107.9814587, 16.0062832],
                [107.9813999, 16.0061861],
                [107.9812834, 16.0060808],
                [107.9812396, 16.0060044],
                [107.981228, 16.0059617],
                [107.981228, 16.0058736],
                [107.9812395, 16.0058308],
                [107.9812838, 16.0057537],
                [107.9813522, 16.0056951],
                [107.9821837, 16.0047514],
                [107.982173, 16.002957],
                [107.9813361, 16.0021113],
                [107.981263, 16.0019951],
                [107.9812127, 16.0018681],
                [107.9811966, 16.0018019],
                [107.9811836, 16.0016478],
                [107.9812036, 16.0014944],
                [107.9812556, 16.0013481],
                [107.9812839, 16.001228],
                [107.981286, 16.0011049],
                [107.981261, 16.0009816],
                [107.9812084, 16.0008638],
                [107.9811726, 16.0008096],
                [107.981084, 16.0007139],
                [107.9808265, 16.0004766],
                [107.9807407, 16.0003508],
                [107.9806763, 16.0002137],
                [107.9806492, 16.0001296],
                [107.9806193, 15.9999562],
                [107.9806168, 15.9998683],
                [107.9806395, 15.9996445],
                [107.9806658, 15.9995099],
                [107.9807013, 15.9993773],
                [107.980746, 15.9992473],
                [107.9807997, 15.9991205],
                [107.9809608, 15.9988682],
                [107.9810297, 15.9987357],
                [107.9810905, 15.9985996],
                [107.9811823, 15.9983354],
                [107.9812407, 15.9980804],
                [107.9812597, 15.9979511],
                [107.9812932, 15.9975013],
                [107.9817867, 15.9966246],
                [107.9816872, 15.9962204],
                [107.981552, 15.995812],
                [107.9814697, 15.9956056],
                [107.980864, 15.9939741],
                [107.9808117, 15.9937836],
                [107.9807688, 15.9935909],
                [107.9807353, 15.9933965],
                [107.9806974, 15.993012],
                [107.9806874, 15.9923305],
                [107.9806709, 15.9920867],
                [107.9806185, 15.991654],
                [107.9805368, 15.9912255],
                [107.9808694, 15.9903798],
                [107.9809499, 15.9897867],
                [107.9810625, 15.989271],
                [107.9810719, 15.9891071],
                [107.9810544, 15.9889438],
                [107.9810079, 15.9887779],
                [107.9809298, 15.9886149],
                [107.9808801, 15.9885388],
                [107.980172, 15.9877317],
                [107.9801111, 15.9876779],
                [107.9800442, 15.9876312],
                [107.9799721, 15.9875922],
                [107.9798311, 15.9875429],
                [107.9796977, 15.9875234],
                [107.9792306, 15.9875486],
                [107.9788685, 15.9876002],
                [107.9787164, 15.9874691],
                [107.9786474, 15.9873966],
                [107.9785252, 15.9872392],
                [107.978417, 15.9870504],
                [107.9783739, 15.9869509],
                [107.9783383, 15.9868486],
                [107.9782916, 15.9866638],
                [107.9782329, 15.9865092],
                [107.9781477, 15.9863664],
                [107.978096, 15.9863007],
                [107.9779802, 15.9861865],
                [107.9778466, 15.9860921],
                [107.9777741, 15.9860531],
                [107.977096, 15.9857772],
                [107.9767574, 15.9856183],
                [107.9765933, 15.9855294],
                [107.976433, 15.9854343],
                [107.9761372, 15.9852353],
                [107.9758579, 15.9850154],
                [107.9757249, 15.984898],
                [107.9742443, 15.9833921],
                [107.97212, 15.98004],
                [107.9720024, 15.9796015],
                [107.971937, 15.9792949],
                [107.9719105, 15.9792122],
                [107.9718747, 15.9791328],
                [107.9718299, 15.9790578],
                [107.9717266, 15.9789345],
                [107.971612, 15.978842],
                [107.9714817, 15.9787714],
                [107.9711142, 15.9786321],
                [107.9706911, 15.9784884],
                [107.970281, 15.9783134],
                [107.9700815, 15.9782144],
                [107.9696981, 15.9779959],
                [107.9695133, 15.9778761],
                [107.9691589, 15.9776162],
                [107.9680109, 15.976626],
                [107.967335, 15.9752387],
                [107.9672238, 15.9750807],
                [107.9670889, 15.9749409],
                [107.9669334, 15.9748224],
                [107.966761, 15.9747281],
                [107.9665644, 15.974657],
                [107.9663585, 15.9746173],
                [107.9662537, 15.9746095],
                [107.9660439, 15.9746186],
                [107.9652569, 15.9747348],
                [107.965157, 15.9747281],
                [107.9651043, 15.9747145],
                [107.9650068, 15.9746677],
                [107.9649639, 15.9746353],
                [107.9647155, 15.97437],
                [107.9645647, 15.9742508],
                [107.9644811, 15.9742021],
                [107.9643351, 15.9741393],
                [107.9641132, 15.9740846],
                [107.9639839, 15.974038],
                [107.9638586, 15.9739635],
                [107.963799, 15.9739146],
                [107.9636979, 15.9737998],
                [107.9633653, 15.9731706],
                [107.9621942, 15.9730508],
                [107.9621188, 15.9730356],
                [107.9620459, 15.9730117],
                [107.9619766, 15.9729795],
                [107.9619118, 15.9729394],
                [107.9618525, 15.9728921],
                [107.9617987, 15.9728371],
                [107.9617524, 15.9727762],
                [107.9617143, 15.9727102],
                [107.9616849, 15.9726402],
                [107.9616648, 15.9725672],
                [107.9616286, 15.9722243],
                [107.9616117, 15.9721456],
                [107.9615556, 15.9719943],
                [107.961517, 15.971923],
                [107.9614717, 15.9718555],
                [107.9613664, 15.9717373],
                [107.961306, 15.9716854],
                [107.9608816, 15.9713862],
                [107.9608018, 15.9713083],
                [107.960742, 15.9712152],
                [107.9607207, 15.9711644],
                [107.9606957, 15.9710518],
                [107.9606883, 15.9709407],
                [107.9606559, 15.970838],
                [107.9605973, 15.9707467],
                [107.9605571, 15.9707053],
                [107.9604612, 15.9706401],
                [107.9604072, 15.9706176],
                [107.9586875, 15.9701897],
                [107.958394, 15.9693267],
                [107.9583569, 15.9692592],
                [107.9583115, 15.9691967],
                [107.9582584, 15.9691401],
                [107.9581436, 15.9690543],
                [107.9580794, 15.9690214],
                [107.9578888, 15.9689537],
                [107.9577641, 15.9689173],
                [107.9576378, 15.9688866],
                [107.9573816, 15.9688425],
                [107.9571225, 15.9688217],
                [107.9566491, 15.9688126],
                [107.9563379, 15.9683794],
                [107.9559195, 15.9672809],
                [107.9551202, 15.9666104],
                [107.9549968, 15.9660689],
                [107.954988, 15.9659831],
                [107.9549968, 15.965811],
                [107.9550153, 15.9657228],
                [107.9550432, 15.965637],
                [107.9550801, 15.9655543],
                [107.9551256, 15.9654758],
                [107.9553535, 15.9651937],
                [107.9556239, 15.9649011],
                [107.9557795, 15.9647498],
                [107.9559994, 15.9645528],
                [107.9560991, 15.9644349],
                [107.9561746, 15.9642966],
                [107.9562017, 15.9642206],
                [107.9562306, 15.9640626],
                [107.9563057, 15.9630156],
                [107.9561162, 15.9626995],
                [107.9560322, 15.962536],
                [107.955905, 15.9622475],
                [107.9558498, 15.9621001],
                [107.9561448, 15.9615045],
                [107.9558659, 15.9585955],
                [107.955818, 15.9584958],
                [107.9557062, 15.9583044],
                [107.9555602, 15.9581088],
                [107.9553759, 15.957916],
                [107.9552746, 15.9578284],
                [107.9551678, 15.957747],
                [107.9538381, 15.9568574],
                [107.9529101, 15.955733],
                [107.9510969, 15.9546344],
                [107.9509867, 15.954541],
                [107.9509396, 15.9544867],
                [107.9508628, 15.954364],
                [107.9508342, 15.9542967],
                [107.9507998, 15.9541554],
                [107.9507965, 15.9540103],
                [107.9508984, 15.953247],
                [107.9509224, 15.9520469],
                [107.9509036, 15.9518658],
                [107.9508823, 15.951777],
                [107.9508104, 15.9515933],
                [107.9507051, 15.9514253],
                [107.9493481, 15.9497757],
                [107.9492719, 15.9496129],
                [107.9492278, 15.9494395],
                [107.9492172, 15.9492613],
                [107.9492247, 15.9491722],
                [107.9492636, 15.9490014],
                [107.9492949, 15.9489192],
                [107.9493796, 15.9487645],
                [107.9494916, 15.9486267],
                [107.9496274, 15.9485104],
                [107.9497826, 15.9484192],
                [107.9513085, 15.9477094],
                [107.9514028, 15.9476565],
                [107.9514916, 15.9475954],
                [107.9516431, 15.9474589],
                [107.9517619, 15.9473078],
                [107.9518533, 15.94714],
                [107.9527384, 15.9458866],
                [107.9549163, 15.9430445],
                [107.9561019, 15.9426886],
                [107.9562924, 15.9425689],
                [107.9564786, 15.9424429],
                [107.9566601, 15.9423108],
                [107.9568368, 15.9421728],
                [107.957022, 15.9420173],
                [107.957201, 15.9418553],
                [107.9573736, 15.941687],
                [107.9575396, 15.9415126],
                [107.9595995, 15.939047],
                [107.960329, 15.938036],
                [107.9623139, 15.9355497],
                [107.9625606, 15.935039],
                [107.9634565, 15.9342756],
                [107.9695237, 15.9304326],
                [107.977066, 15.9246242],
                [107.9781818, 15.9236544],
                [107.9797161, 15.9224886],
                [107.983439, 15.9194656],
                [107.9849073, 15.9191906],
                [107.9856062, 15.9189265],
                [107.9858673, 15.9188484],
                [107.9860005, 15.9188182],
                [107.9861674, 15.918789],
                [107.9863358, 15.9187692],
                [107.9870814, 15.9187588],
                [107.9874393, 15.9190208],
                [107.9876252, 15.9191427],
                [107.9880094, 15.9193676],
                [107.9884442, 15.9195822],
                [107.9888946, 15.9197648],
                [107.989115, 15.9197903],
                [107.9895578, 15.9198213],
                [107.9899873, 15.9198257],
                [107.9902986, 15.9198157],
                [107.990402, 15.9198061],
                [107.9906326, 15.9197606],
                [107.9908539, 15.9196832],
                [107.9910609, 15.9195755],
                [107.9911578, 15.919511],
                [107.9912494, 15.9194399],
                [107.9929554, 15.9179902],
                [107.9945755, 15.9194862],
                [107.995863, 15.9212092],
                [107.9959735, 15.9212284],
                [107.9961975, 15.9212411],
                [107.9964208, 15.9212195],
                [107.9965371, 15.9211943],
                [107.9966508, 15.9211598],
                [107.996761, 15.9211161],
                [107.9970996, 15.9209366],
                [107.9973721, 15.9208227],
                [107.9976547, 15.9207346],
                [107.9978213, 15.9206959],
                [107.9979898, 15.9206661],
                [107.9981599, 15.9206454],
                [107.9983309, 15.9206338],
                [107.999897, 15.9206314],
                [108.0002872, 15.920709],
                [108.0004795, 15.9207593],
                [108.0008538, 15.9208814],
                [108.0010352, 15.9209527],
                [108.0013883, 15.9211164],
                [108.0032551, 15.9219108],
                [108.0041993, 15.9219108],
                [108.0043746, 15.9218506],
                [108.0045464, 15.9217818],
                [108.0047142, 15.9217044],
                [108.0049603, 15.9215715],
                [108.005079, 15.9214981],
                [108.005137, 15.9214756],
                [108.0052582, 15.9214471],
                [108.0053204, 15.9214413],
                [108.0053881, 15.9214415],
                [108.0055215, 15.921462],
                [108.0056477, 15.9215084],
                [108.0084586, 15.9229116],
                [108.011055, 15.9246861],
                [108.0113733, 15.92492],
                [108.0116773, 15.925171],
                [108.011997, 15.9254689],
                [108.0121493, 15.925625],
                [108.0122938, 15.9257997],
                [108.0124551, 15.9259603],
                [108.0125641, 15.9260529],
                [108.0127984, 15.9262182],
                [108.0128146, 15.9266181],
                [108.014161, 15.9270049],
                [108.015258, 15.9270306],
                [108.0154268, 15.9270599],
                [108.0155088, 15.9270844],
                [108.0156649, 15.9271526],
                [108.0158176, 15.9272525],
                [108.0158912, 15.9273163],
                [108.0159581, 15.9273866],
                [108.0160292, 15.9275301],
                [108.0160698, 15.927667],
                [108.0160868, 15.9277915],
                [108.0160922, 15.9280546],
                [108.0164355, 15.9284209],
                [108.0173743, 15.9283796],
                [108.0175363, 15.9283992],
                [108.0176972, 15.9284266],
                [108.0180135, 15.9285046],
                [108.0183238, 15.9286143],
                [108.0184761, 15.9286814],
                [108.0186246, 15.9287558],
                [108.0189085, 15.9289264],
                [108.0195629, 15.9298446],
                [108.0196963, 15.9299408],
                [108.0198475, 15.9300083],
                [108.0199277, 15.9300303],
                [108.0200837, 15.9300494],
                [108.0202405, 15.9300388],
                [108.0203174, 15.9300225],
                [108.0224168, 15.9292462],
                [108.0227758, 15.9291507],
                [108.0231413, 15.9290817],
                [108.0235112, 15.9290399],
                [108.0237237, 15.9290282],
                [108.0241495, 15.9290316],
                [108.0245733, 15.9290708],
                [108.0252171, 15.9292013],
                [108.0255365, 15.929277],
                [108.0261697, 15.9294491],
                [108.0268144, 15.9296555],
                [108.0274684, 15.9298986],
                [108.0281094, 15.9301716],
                [108.0284247, 15.9303191],
                [108.0293048, 15.9307624],
                [108.0309724, 15.9315415],
                [108.0319762, 15.9319905],
                [108.0329633, 15.9326507],
                [108.0331535, 15.9327684],
                [108.0335472, 15.9329824],
                [108.0337501, 15.9330785],
                [108.0341665, 15.9332481],
                [108.0346074, 15.9333902],
                [108.034838, 15.9334503],
                [108.0350709, 15.9335014],
                [108.0353057, 15.9335435],
                [108.0355422, 15.9335765],
                [108.0357798, 15.9336004],
                [108.0360182, 15.933615],
                [108.0371475, 15.9336411],
                [108.0398621, 15.9343658],
                [108.040093, 15.9344485],
                [108.0403421, 15.9345656],
                [108.0404745, 15.9346414],
                [108.0406811, 15.9347778],
                [108.0408502, 15.9348669],
                [108.0409396, 15.9349021],
                [108.041124, 15.9349524],
                [108.041218, 15.9349675],
                [108.0414083, 15.9349773],
                [108.0416072, 15.9349575],
                [108.0417092, 15.934934],
                [108.0418085, 15.9349018],
                [108.0419044, 15.934861],
                [108.0419961, 15.9348121],
                [108.0420828, 15.9347553],
                [108.0436599, 15.9334864],
                [108.0443197, 15.9325966],
                [108.0468517, 15.9327307],
                [108.0476993, 15.9328442],
                [108.0510657, 15.9323335],
                [108.0512138, 15.9323232],
                [108.051288, 15.9323284],
                [108.051433, 15.9323593],
                [108.0515683, 15.932416],
                [108.0516899, 15.9324967],
                [108.0517441, 15.932545],
                [108.052023, 15.9329267],
                [108.0533856, 15.9329267],
                [108.0537371, 15.9329758],
                [108.0540939, 15.9330511],
                [108.0542748, 15.9330995],
                [108.0546301, 15.9332156],
                [108.0554326, 15.933193],
                [108.0556516, 15.9331656],
                [108.0557589, 15.9331402],
                [108.0559624, 15.9330685],
                [108.0561493, 15.9329713],
                [108.0565667, 15.9326765],
                [108.0566317, 15.9326413],
                [108.0566999, 15.9326119],
                [108.0568391, 15.9325729],
                [108.0569791, 15.9325587],
                [108.0570495, 15.9325605],
                [108.0572507, 15.9326069],
                [108.0573186, 15.9326525],
                [108.0573697, 15.9327152],
                [108.0574006, 15.9327948],
                [108.0574033, 15.9328851],
                [108.0573928, 15.9329293],
                [108.0572936, 15.9331614],
                [108.0572878, 15.9331992],
                [108.0572963, 15.9332749],
                [108.0573261, 15.9333374],
                [108.0573741, 15.9333884],
                [108.0575262, 15.933453],
                [108.0578381, 15.9335638],
                [108.0580177, 15.9336152],
                [108.0583836, 15.9336937],
                [108.0587554, 15.9337392],
                [108.0591011, 15.9337641],
                [108.0597907, 15.933832],
                [108.0605525, 15.9339352],
                [108.0608417, 15.9333848],
                [108.0609763, 15.9331124],
                [108.0611695, 15.9326781],
                [108.061341, 15.9322355],
                [108.0613957, 15.9320577],
                [108.061441, 15.9318775],
                [108.0614768, 15.9316953],
                [108.0615029, 15.9315116],
                [108.0615195, 15.9313269],
                [108.0615263, 15.9311416],
                [108.0615234, 15.9309562],
                [108.0615342, 15.9304352],
                [108.0615783, 15.9302216],
                [108.0616515, 15.9300155],
                [108.0617527, 15.9298207],
                [108.0618758, 15.9296454],
                [108.062017, 15.9294912],
                [108.0623268, 15.9293095],
                [108.06265, 15.9291508],
                [108.0628461, 15.9290683],
                [108.0630458, 15.9289943],
                [108.0632488, 15.9289289],
                [108.0634546, 15.9288722],
                [108.0667269, 15.9280881],
                [108.0668881, 15.9279264],
                [108.066963, 15.9278405],
                [108.0671422, 15.9275977],
                [108.0672204, 15.9274691],
                [108.0673983, 15.9272478],
                [108.0675997, 15.9270461],
                [108.0678345, 15.9268576],
                [108.067966, 15.9267692],
                [108.0681027, 15.9266884],
                [108.0682442, 15.9266156],
                [108.0683899, 15.9265509],
                [108.0686712, 15.9264705],
                [108.0692401, 15.9263312],
                [108.0695271, 15.9262724],
                [108.070099, 15.9261775],
                [108.0706751, 15.9261112],
                [108.0712541, 15.9260738],
                [108.0715442, 15.926066],
                [108.0723084, 15.9261221],
                [108.0725395, 15.9261221],
                [108.0726546, 15.9261124],
                [108.0728896, 15.9260724],
                [108.0730048, 15.9260424],
                [108.0731179, 15.9260058],
                [108.0733981, 15.925898],
                [108.0735773, 15.9258648],
                [108.073669, 15.9258606],
                [108.0738517, 15.9258772],
                [108.0740279, 15.9259267],
                [108.0746502, 15.926066],
                [108.0747346, 15.9260733],
                [108.074904, 15.9260707],
                [108.0750888, 15.9260414],
                [108.0751874, 15.9260138],
                [108.075693, 15.9258131],
                [108.0761147, 15.9256782],
                [108.0763292, 15.9256224],
                [108.0767291, 15.9255402],
                [108.077134, 15.925485],
                [108.0773377, 15.9254676],
                [108.0786788, 15.9254367],
                [108.0793387, 15.925576],
                [108.0801111, 15.925968],
                [108.0809265, 15.9259989],
                [108.0818438, 15.9263652],
                [108.0820056, 15.9264484],
                [108.082155, 15.9265509],
                [108.0822422, 15.9266253],
                [108.0823229, 15.9267063],
                [108.0824576, 15.926863],
                [108.082524, 15.926928],
                [108.0826708, 15.9270431],
                [108.082847, 15.9271424],
                [108.0829476, 15.9271842],
                [108.0830515, 15.9272176],
                [108.0831581, 15.9272422],
                [108.0832664, 15.9272581],
                [108.0833758, 15.9272649],
                [108.0834854, 15.9272628],
                [108.0842471, 15.927108],
                [108.0852771, 15.9270977],
                [108.0861354, 15.9277167],
                [108.086248, 15.9278096],
                [108.0863539, 15.9279097],
                [108.0864524, 15.9280165],
                [108.0865431, 15.9281294],
                [108.0866848, 15.9283456],
                [108.0867451, 15.9284595],
                [108.0868946, 15.9288178],
                [108.0869529, 15.9289358],
                [108.0870902, 15.9291611],
                [108.0871628, 15.92926],
                [108.087324, 15.929446],
                [108.08797, 15.9301102],
                [108.0880068, 15.9303255],
                [108.0880129, 15.9305435],
                [108.0879911, 15.9307443],
                [108.0879432, 15.9309407],
                [108.0879318, 15.9310294],
                [108.0879291, 15.9312078],
                [108.0879541, 15.9313883],
                [108.0879774, 15.9314785],
                [108.0880765, 15.9317422],
                [108.0881191, 15.9319267],
                [108.088134, 15.9321152],
                [108.0881202, 15.9325295],
                [108.087852, 15.9329835],
                [108.0878788, 15.9335354],
                [108.0881792, 15.9340925],
                [108.0882355, 15.9367774],
                [108.0900809, 15.9373138],
                [108.0907302, 15.9376235],
                [108.0913898, 15.9379122],
                [108.0918083, 15.9380823],
                [108.0922303, 15.9382439],
                [108.093181, 15.9385811],
                [108.0936965, 15.9387891],
                [108.0939719, 15.9389118],
                [108.0945123, 15.9391776],
                [108.0950376, 15.93947],
                [108.0977735, 15.9411412],
                [108.1019792, 15.941884],
                [108.1036958, 15.9426267],
                [108.1038684, 15.9429496],
                [108.1041893, 15.9436068],
                [108.104317, 15.9438932],
                [108.1045541, 15.9444733],
                [108.1058094, 15.9455049],
                [108.1060717, 15.9456563],
                [108.1065815, 15.9459814],
                [108.1068286, 15.9461548],
                [108.107082, 15.9463444],
                [108.1073292, 15.9465415],
                [108.1075697, 15.9467459],
                [108.1078035, 15.9469576],
                [108.1086525, 15.9476919],
                [108.1088158, 15.947799],
                [108.1089958, 15.9478776],
                [108.1091685, 15.9479222],
                [108.1093463, 15.9479404],
                [108.1094357, 15.9479395],
                [108.1111309, 15.94665],
                [108.1127402, 15.9457938],
                [108.1146178, 15.945247],
                [108.1154331, 15.9442567],
                [108.1161453, 15.9437737],
                [108.1169096, 15.9432181],
                [108.1173178, 15.9429045],
                [108.1177198, 15.9425836],
                [108.1181154, 15.9422554],
                [108.1186636, 15.9420181],
                [108.118932, 15.941888],
                [108.1191964, 15.9417504],
                [108.1197082, 15.9414555],
                [108.1201978, 15.941135],
                [108.1204352, 15.9409647],
                [108.1208941, 15.9406048],
                [108.121045, 15.9404961],
                [108.1213608, 15.9402983],
                [108.1216934, 15.9401276],
                [108.1233618, 15.9393565],
                [108.1247854, 15.938306],
                [108.1249343, 15.9381678],
                [108.1249998, 15.9380906],
                [108.1251106, 15.9379225],
                [108.1251904, 15.937745],
                [108.1252194, 15.9376524],
                [108.1252547, 15.937462],
                [108.1252742, 15.9370404],
                [108.1253116, 15.9369086],
                [108.1253783, 15.9367878],
                [108.1254713, 15.9366839],
                [108.1255264, 15.9366399],
                [108.12565, 15.9365708],
                [108.1257865, 15.9365298],
                [108.126069, 15.9364791],
                [108.1262076, 15.9364421],
                [108.1264607, 15.9363523],
                [108.1265831, 15.9362977],
                [108.1266508, 15.9361543],
                [108.126673, 15.9360784],
                [108.1266872, 15.936],
                [108.1266954, 15.935774],
                [108.1267306, 15.9356438],
                [108.1267973, 15.9355261],
                [108.1268916, 15.9354272],
                [108.1280261, 15.9348043],
                [108.1308277, 15.9332917],
                [108.1310452, 15.9331847],
                [108.1312671, 15.9330862],
                [108.1314929, 15.9329963],
                [108.1317144, 15.932918],
                [108.132166, 15.9327863],
                [108.1323954, 15.9327333],
                [108.1344634, 15.932073],
                [108.1347643, 15.9319684],
                [108.1350552, 15.9318402],
                [108.1352172, 15.9317561],
                [108.1355279, 15.9315662],
                [108.1360459, 15.9312718],
                [108.1376304, 15.9305539],
                [108.1380388, 15.9312438],
                [108.1384624, 15.931161],
                [108.1388904, 15.9311032],
                [108.1391493, 15.9310806],
                [108.1394089, 15.9310671],
                [108.1396688, 15.9310628],
                [108.1399287, 15.9310676],
                [108.1401883, 15.9310816],
                [108.1413849, 15.9313231],
                [108.1416108, 15.9317422],
                [108.1417483, 15.9316913],
                [108.1418872, 15.9318729],
                [108.1419857, 15.9320343],
                [108.1423733, 15.9320111],
                [108.1426549, 15.9325102],
                [108.1429768, 15.9323393],
                [108.1430651, 15.9324431],
                [108.1432624, 15.9326327],
                [108.1433723, 15.9327189],
                [108.1434881, 15.9327978],
                [108.1437948, 15.9326198],
                [108.1444412, 15.9331227],
                [108.1446424, 15.9329499],
                [108.1449884, 15.9330466],
                [108.1453291, 15.9333523],
                [108.146866, 15.9323735],
                [108.146907, 15.9326254],
                [108.1469565, 15.9328758],
                [108.1470809, 15.9333714],
                [108.1472387, 15.9338581],
                [108.1474292, 15.9343339],
                [108.1476517, 15.9347969],
                [108.1477747, 15.9350229],
                [108.1480433, 15.9354629],
                [108.1483412, 15.935885],
                [108.1485108, 15.9361959],
                [108.1486523, 15.9365195],
                [108.1487133, 15.9366886],
                [108.1487667, 15.9368601],
                [108.1488499, 15.937209],
                [108.1489014, 15.9375634],
                [108.1489674, 15.9386014],
                [108.148973, 15.9388884],
                [108.1490035, 15.9391788],
                [108.1490285, 15.9393252],
                [108.1490976, 15.9396144],
                [108.1494167, 15.9407028],
                [108.1494831, 15.94103],
                [108.1495234, 15.9413611],
                [108.149535, 15.9415627],
                [108.149537, 15.9417647],
                [108.1495294, 15.9419665],
                [108.1494752, 15.9426332],
                [108.1494424, 15.9433865],
                [108.1494342, 15.9440859],
                [108.1494585, 15.9444135],
                [108.1494859, 15.9446002],
                [108.1495229, 15.9447854],
                [108.1495696, 15.9449685],
                [108.1496982, 15.9453779],
                [108.1497985, 15.9457946],
                [108.1498379, 15.9460051],
                [108.1498911, 15.9463917],
                [108.1499317, 15.9469722],
                [108.1499833, 15.9474288],
                [108.1500678, 15.9478809],
                [108.1501222, 15.9481046],
                [108.1502509, 15.9485337],
                [108.1503265, 15.9487449],
                [108.1504997, 15.9491594],
                [108.1507868, 15.9497313],
                [108.1508789, 15.9498977],
                [108.1510832, 15.9502193],
                [108.151195, 15.950374],
                [108.151424, 15.9506553],
                [108.1515465, 15.9507896],
                [108.1518066, 15.9510446],
                [108.153888, 15.9526796],
                [108.1540006, 15.9528601],
                [108.1546122, 15.9527312],
                [108.1567553, 15.9521741],
                [108.1593704, 15.950962],
                [108.1607115, 15.9502812],
                [108.1608276, 15.9502386],
                [108.1610656, 15.9501703],
                [108.161203, 15.9501417],
                [108.1614817, 15.9501068],
                [108.1617098, 15.9500963],
                [108.1617975, 15.9500985],
                [108.1618847, 15.9501073],
                [108.1620558, 15.9501442],
                [108.1621543, 15.9501782],
                [108.1622492, 15.9502208],
                [108.1623395, 15.9502718],
                [108.1624244, 15.9503305],
                [108.1625034, 15.9503966],
                [108.163088, 15.9510317],
                [108.1633082, 15.951259],
                [108.1637612, 15.9517022],
                [108.1644237, 15.9517667],
                [108.1646785, 15.9520052],
                [108.1649762, 15.9519343],
                [108.1648421, 15.9513953],
                [108.1649711, 15.9512552],
                [108.1650272, 15.9511787],
                [108.165123, 15.9510107],
                [108.1651608, 15.950922],
                [108.165215, 15.9507377],
                [108.1652337, 15.9506286],
                [108.1652439, 15.9504078],
                [108.1652176, 15.9501883],
                [108.1651425, 15.9498634],
                [108.1650405, 15.9495146],
                [108.1649816, 15.9493425],
                [108.1654322, 15.9486822],
                [108.1654349, 15.9477216],
                [108.1657621, 15.9466526],
                [108.1664059, 15.9467686],
                [108.1668632, 15.9466771],
                [108.1669729, 15.946528],
                [108.1670581, 15.9463647],
                [108.1670908, 15.9462789],
                [108.1671362, 15.9460972],
                [108.167148, 15.9460023],
                [108.1671481, 15.9458113],
                [108.1671364, 15.9457164],
                [108.1670898, 15.9455307],
                [108.1670415, 15.9452419],
                [108.1670397, 15.9451159],
                [108.1670737, 15.9449943],
                [108.1671395, 15.9448879],
                [108.1672325, 15.9448025],
                [108.167346, 15.9447441],
                [108.167586, 15.9437228],
                [108.1678945, 15.9432199],
                [108.1679749, 15.9431374],
                [108.1682646, 15.9425545],
                [108.1684403, 15.9424823],
                [108.1686133, 15.9421419],
                [108.1688775, 15.942138],
                [108.1690586, 15.9417473],
                [108.1699061, 15.9417679],
                [108.1704158, 15.9412547],
                [108.1707269, 15.9406873],
                [108.1709074, 15.9406254],
                [108.1709938, 15.9405854],
                [108.1711323, 15.940505],
                [108.1712244, 15.9404178],
                [108.1714645, 15.9404397],
                [108.1715476, 15.9401998],
                [108.1719312, 15.9403339],
                [108.1721123, 15.940336],
                [108.1722705, 15.940308],
                [108.1724238, 15.9402621],
                [108.1726158, 15.9404539],
                [108.1726431, 15.9404959],
                [108.1726722, 15.9405768],
                [108.1726775, 15.9406866],
                [108.172874, 15.9409391],
                [108.1729907, 15.941069],
                [108.1731334, 15.9411898],
                [108.173211, 15.9412427],
                [108.173271, 15.9412711],
                [108.1733578, 15.9412911],
                [108.1734849, 15.9401051],
                [108.1740582, 15.9401653],
                [108.1741574, 15.9401599],
                [108.1742566, 15.9401327],
                [108.1742909, 15.9401408],
                [108.1743036, 15.9401518],
                [108.1743157, 15.9401819],
                [108.1743036, 15.940254],
                [108.1742178, 15.9403449],
                [108.1740582, 15.9408227],
                [108.1743747, 15.9409129],
                [108.174427, 15.940802],
                [108.1745517, 15.9408214],
                [108.1746188, 15.9408143],
                [108.1746246, 15.9400635],
                [108.1753202, 15.9398691],
                [108.1754889, 15.939807],
                [108.1756498, 15.9397263],
                [108.1757265, 15.9396789],
                [108.1761836, 15.9393546],
                [108.1762962, 15.9393041],
                [108.1763558, 15.939287],
                [108.1764975, 15.9392691],
                [108.176569, 15.9392716],
                [108.1779259, 15.9394551],
                [108.1796888, 15.9396711],
                [108.1797976, 15.9403442],
                [108.1798598, 15.9405583],
                [108.1799014, 15.9406668],
                [108.1800028, 15.9408763],
                [108.1800623, 15.9409768],
                [108.1814463, 15.94304],
                [108.1816267, 15.9432812],
                [108.1817917, 15.9434862],
                [108.1820934, 15.9438331],
                [108.1821772, 15.9439694],
                [108.1822347, 15.9441102],
                [108.1822539, 15.9441799],
                [108.1822565, 15.9442424],
                [108.182245, 15.9443669],
                [108.1822256, 15.9444466],
                [108.1821971, 15.9445236],
                [108.1814686, 15.9460159],
                [108.1817299, 15.9461362],
                [108.1802143, 15.9491134],
                [108.1811634, 15.9495023],
                [108.1804204, 15.9515036],
                [108.180415, 15.9558104],
                [108.1807879, 15.9561637],
                [108.1811821, 15.9562668],
                [108.1812251, 15.9566047],
                [108.1826466, 15.9569812],
                [108.1829041, 15.9574041],
                [108.1829899, 15.957925],
                [108.1827754, 15.9581726],
                [108.1829202, 15.9584846],
                [108.1830228, 15.9585169],
                [108.1830584, 15.9585423],
                [108.183105, 15.9586015],
                [108.1831254, 15.9586619],
                [108.18312, 15.9588979],
                [108.1835183, 15.9590907],
                [108.1838858, 15.9593795],
                [108.1841272, 15.9602615],
                [108.1837785, 15.9610196],
                [108.1834882, 15.9616057],
                [108.1835505, 15.9628544],
                [108.1833333, 15.9647589],
                [108.1832139, 15.9653391],
                [108.1840441, 15.9654706],
                [108.1841621, 15.9655028],
                [108.1843016, 15.9655273],
                [108.1846234, 15.9655454],
                [108.1846217, 15.9655183],
                [108.1847103, 15.9655109],
                [108.1848447, 15.9655176],
                [108.185234, 15.9655999],
                [108.1856252, 15.9656598],
                [108.1857028, 15.9656576],
                [108.1860665, 15.9656131],
                [108.186447, 15.9655415],
                [108.1864004, 15.9650864],
                [108.1862596, 15.964804],
                [108.1862271, 15.9647063],
                [108.1862136, 15.9645926],
                [108.1862274, 15.9644391],
                [108.1867665, 15.9643746],
                [108.1871608, 15.9644778],
                [108.1872788, 15.9643437],
                [108.1873036, 15.9641861],
                [108.1873056, 15.9641064],
                [108.1872878, 15.963943],
                [108.1872678, 15.963863],
                [108.1872068, 15.9637095],
                [108.1871662, 15.9636371],
                [108.1868631, 15.9637996],
                [108.1867626, 15.9636186],
                [108.1867028, 15.9635336],
                [108.1865653, 15.9633766],
                [108.1864001, 15.9632348],
                [108.1862153, 15.9631175],
                [108.1863776, 15.9626365],
                [108.1864467, 15.9622033],
                [108.1868225, 15.962196],
                [108.1872213, 15.9621598],
                [108.1879775, 15.9620357],
                [108.1882211, 15.9619598],
                [108.1884925, 15.9618423],
                [108.188636, 15.9620331],
                [108.1888291, 15.9622446],
                [108.1891966, 15.9624483],
                [108.1897116, 15.962144],
                [108.1905363, 15.9620486],
                [108.1901836, 15.9613342],
                [108.1900822, 15.9609287],
                [108.1910295, 15.96095],
                [108.1914168, 15.9627629],
                [108.1917541, 15.9626494],
                [108.1920145, 15.9625394],
                [108.1921417, 15.9624779],
                [108.1921685, 15.9627236],
                [108.1922731, 15.9630214],
                [108.1931622, 15.9629328],
                [108.1932152, 15.9633908],
                [108.1940138, 15.9633457],
                [108.1944671, 15.9631446],
                [108.1944644, 15.9628351],
                [108.1948185, 15.9628454],
                [108.1951327, 15.9629289],
                [108.1950438, 15.9633966],
                [108.1950418, 15.9635907],
                [108.1950731, 15.9637785],
                [108.1952074, 15.9641844],
                [108.1959128, 15.9642147],
                [108.1960577, 15.9642586],
                [108.1966478, 15.9643411],
                [108.1973639, 15.9644829],
                [108.1974336, 15.9637145],
                [108.1975389, 15.9636458],
                [108.1975855, 15.9636041],
                [108.1976643, 15.9635082],
                [108.1976986, 15.9634493],
                [108.1977455, 15.9633224],
                [108.1977576, 15.963256],
                [108.1977582, 15.9631213],
                [108.1978283, 15.9630551],
                [108.1978708, 15.9630311],
                [108.1979547, 15.963006],
                [108.1980425, 15.9630053],
                [108.1986058, 15.9631239],
                [108.2002199, 15.9635432],
                [108.2004592, 15.9635288],
                [108.2018968, 15.9637402],
                [108.2028785, 15.9638666],
                [108.2028919, 15.9630736],
                [108.2032146, 15.9628109],
                [108.2035518, 15.9625656],
                [108.2037666, 15.9624234],
                [108.2040731, 15.9622411],
                [108.2042341, 15.962128],
                [108.204375, 15.9619923],
                [108.2044369, 15.9619171],
                [108.2045499, 15.961738],
                [108.20463, 15.9615432],
                [108.204657, 15.9614416],
                [108.2046749, 15.9613382],
                [108.2047641, 15.9605438],
                [108.2047567, 15.960359],
                [108.2047445, 15.9602673],
                [108.2047001, 15.9600768],
                [108.204627, 15.9598839],
                [108.2045281, 15.9597019],
                [108.2041415, 15.9591755],
                [108.2039595, 15.958905],
                [108.2036751, 15.9584472],
                [108.203541, 15.9582139],
                [108.2034702, 15.9580065],
                [108.2034451, 15.9579],
                [108.2034161, 15.9576835],
                [108.2034123, 15.9575743],
                [108.2034259, 15.9573589],
                [108.2034672, 15.9571468],
                [108.2034981, 15.9570431],
                [108.2037288, 15.9564035],
                [108.2044704, 15.9558826],
                [108.2049143, 15.9561663],
                [108.2053515, 15.9556814],
                [108.2050055, 15.9553668],
                [108.2052255, 15.9550444],
                [108.2054803, 15.9547143],
                [108.2050109, 15.9540052],
                [108.2050095, 15.9529942],
                [108.2045898, 15.9529891],
                [108.2045951, 15.9526847],
                [108.2046418, 15.952533],
                [108.2046568, 15.9524552],
                [108.20467, 15.9522941],
                [108.2046595, 15.9521329],
                [108.204563, 15.9517615],
                [108.2045663, 15.9514307],
                [108.2056023, 15.9511064],
                [108.2051611, 15.9501987],
                [108.2051346, 15.9498413],
                [108.2051289, 15.9496622],
                [108.2051294, 15.9494146],
                [108.2051396, 15.9491671],
                [108.2046944, 15.9489066],
                [108.2046702, 15.9486539],
                [108.2047069, 15.9485984],
                [108.2047948, 15.948502],
                [108.2051651, 15.9482154],
                [108.2050109, 15.9480375],
                [108.2051465, 15.9479455],
                [108.2052979, 15.9478802],
                [108.2053681, 15.9478606],
                [108.2055938, 15.9478231],
                [108.20575, 15.94777],
                [108.2058236, 15.9477332],
                [108.2057619, 15.9472225],
                [108.2059926, 15.94714],
                [108.2059979, 15.9468357],
                [108.2064459, 15.946601],
                [108.2068858, 15.9463096],
                [108.2075027, 15.9458608],
                [108.2083234, 15.9457654],
                [108.2092246, 15.9453038],
                [108.2095358, 15.9451774],
                [108.2091629, 15.9448872],
                [108.209407, 15.9446745],
                [108.2099596, 15.9436893],
                [108.2100687, 15.9436461],
                [108.2101178, 15.9436145],
                [108.21019, 15.9435476],
                [108.2102197, 15.9435088],
                [108.2101902, 15.9433334],
                [108.2102805, 15.9432047],
                [108.2103183, 15.9431361],
                [108.2105094, 15.9427118],
                [108.2105883, 15.9427562],
                [108.2107347, 15.9428614],
                [108.210806, 15.9429262],
                [108.2109311, 15.943071],
                [108.2109842, 15.9431503],
                [108.2109815, 15.9434185],
                [108.2111236, 15.9435732],
                [108.2119524, 15.943648],
                [108.2126498, 15.9434778],
                [108.2136583, 15.944218],
                [108.2136963, 15.9442828],
                [108.2137898, 15.9444011],
                [108.2138933, 15.9444931],
                [108.2139819, 15.9445539],
                [108.2140201, 15.9446041],
                [108.2140338, 15.9446538],
                [108.2140171, 15.9448847],
                [108.2143155, 15.9448808],
                [108.2144174, 15.9448589],
                [108.2146306, 15.9448744],
                [108.2146776, 15.9448976],
                [108.214581, 15.945649],
                [108.2151785, 15.9458096],
                [108.215493, 15.9459575],
                [108.215724, 15.9457158],
                [108.2159302, 15.9457938],
                [108.2161354, 15.9458995],
                [108.2164438, 15.9461316],
                [108.2165049, 15.946242],
                [108.2165444, 15.9463612],
                [108.2165614, 15.946507],
                [108.2165502, 15.9467149],
                [108.2165196, 15.9468592],
                [108.2164282, 15.9471027],
                [108.2164036, 15.9471993],
                [108.2163191, 15.9477254],
                [108.2163164, 15.9487158],
                [108.2162386, 15.9490098],
                [108.2161193, 15.9493218],
                [108.2163164, 15.9493631],
                [108.2164773, 15.9493786],
                [108.217793, 15.9494475],
                [108.2178667, 15.9491903],
                [108.2178882, 15.9489969],
                [108.2178783, 15.9487984],
                [108.2178614, 15.9487003],
                [108.2178694, 15.9483109],
                [108.2176682, 15.948053],
                [108.2176643, 15.9479531],
                [108.2176778, 15.9477536],
                [108.2177192, 15.9475578],
                [108.2177917, 15.9473607],
                [108.2178386, 15.9472664],
                [108.2179529, 15.9470885],
                [108.2181121, 15.9469042],
                [108.218317, 15.9467195],
                [108.2184284, 15.9466373],
                [108.2185453, 15.9465623],
                [108.2188082, 15.946552],
                [108.2192615, 15.9463431],
                [108.2194879, 15.9462234],
                [108.2197094, 15.9460955],
                [108.2197215, 15.9459214],
                [108.2198971, 15.9459343],
                [108.2200743, 15.9461433],
                [108.2202818, 15.9463361],
                [108.2203974, 15.9464256],
                [108.2207001, 15.9466091],
                [108.2220724, 15.947024],
                [108.222574, 15.9471613],
                [108.2226846, 15.9474791],
                [108.2225757, 15.9475751],
                [108.2223753, 15.9477839],
                [108.2222846, 15.947896],
                [108.2221235, 15.9481341],
                [108.2219886, 15.9483938],
                [108.2219319, 15.9485317],
                [108.221844, 15.948816],
                [108.2218133, 15.9489614],
                [108.2217785, 15.9492561],
                [108.2217775, 15.9495937],
                [108.2218092, 15.9499712],
                [108.2218754, 15.9503445],
                [108.2219756, 15.9507107],
                [108.2221089, 15.951067],
                [108.2221877, 15.9512406],
                [108.2224158, 15.9516881],
                [108.2228529, 15.9525919],
                [108.2230979, 15.9531281],
                [108.2233339, 15.953668],
                [108.223561, 15.9542115],
                [108.2244456, 15.9561285],
                [108.2251904, 15.9560655],
                [108.2253932, 15.9560535],
                [108.2255964, 15.9560506],
                [108.2257995, 15.9560569],
                [108.2260081, 15.9560729],
                [108.2262158, 15.9560986],
                [108.2264219, 15.9561339],
                [108.2278046, 15.9564245],
                [108.2285248, 15.9565057],
                [108.2286776, 15.9565365],
                [108.2289783, 15.9566169],
                [108.2293975, 15.9567491],
                [108.2303953, 15.9571565],
                [108.2305898, 15.957279],
                [108.2306045, 15.9573912],
                [108.2305925, 15.9574621],
                [108.2305495, 15.9575421],
                [108.2303953, 15.9577239],
                [108.23038, 15.9577537],
                [108.230369, 15.9578163],
                [108.2303819, 15.957876],
                [108.2305455, 15.9579611],
                [108.2306327, 15.9578141],
                [108.230972, 15.9580127],
                [108.2309157, 15.9581442],
                [108.2310498, 15.9582783],
                [108.2310169, 15.958551],
                [108.2309934, 15.9586484],
                [108.2309677, 15.9587192],
                [108.2308962, 15.9588522],
                [108.2305788, 15.959686],
                [108.2302053, 15.9603358],
                [108.230012, 15.9609043],
                [108.2302173, 15.96104],
                [108.2306126, 15.9613317],
                [108.2309823, 15.9616457],
                [108.2313331, 15.961986],
                [108.2312898, 15.962398],
                [108.2321528, 15.9626346],
                [108.2326081, 15.9628867],
                [108.2336113, 15.9631832],
                [108.2347244, 15.9634978],
                [108.2362962, 15.9641296],
                [108.2372195, 15.9645789],
                [108.2375417, 15.9647029],
                [108.2378552, 15.9647924],
                [108.2380048, 15.9648251],
                [108.2383078, 15.9648723],
                [108.2385626, 15.9649471],
                [108.2386082, 15.9648427],
                [108.2388765, 15.9649407],
                [108.2388228, 15.9651212],
                [108.2393727, 15.9653326],
                [108.2393646, 15.9654293],
                [108.2393142, 15.9655678],
                [108.2393069, 15.9656107],
                [108.2393096, 15.9656975],
                [108.2394907, 15.9657078],
                [108.2394907, 15.9665176],
                [108.2408452, 15.9675491],
                [108.2412073, 15.9677347],
                [108.241328, 15.9674588],
                [108.2415292, 15.9674588],
                [108.2415372, 15.9677657],
                [108.2421193, 15.9680029],
                [108.2421555, 15.968128],
                [108.2424492, 15.9685419],
                [108.24283, 15.9689055],
                [108.2432002, 15.9693903],
                [108.2434222, 15.9697918],
                [108.2434625, 15.9698496],
                [108.2435085, 15.9699033],
                [108.2436133, 15.969995],
                [108.2436708, 15.9700327],
                [108.2437956, 15.9700917],
                [108.2441001, 15.9703773],
                [108.2440883, 15.9704156],
                [108.2444823, 15.9705997],
                [108.2454023, 15.9708949],
                [108.2457992, 15.9710123],
                [108.245936, 15.9707183],
                [108.2462579, 15.9707183],
                [108.2463598, 15.9707467],
                [108.2463625, 15.9710355],
                [108.2462633, 15.9713269],
                [108.2462249, 15.9716431],
                [108.2462427, 15.9719639],
                [108.246489, 15.9718595],
                [108.2465213, 15.9719763],
                [108.2468923, 15.9719872],
                [108.2469901, 15.971675],
                [108.2471506, 15.9716614],
                [108.2472421, 15.9715123],
                [108.2474139, 15.971644],
                [108.2476875, 15.9717988],
                [108.247835, 15.971479],
                [108.2480577, 15.9714764],
                [108.2484849, 15.9716058],
                [108.2498306, 15.9719509],
                [108.2501766, 15.9720038],
                [108.2501773, 15.9724131],
                [108.2517833, 15.9726858],
                [108.2518369, 15.972486],
                [108.2520233, 15.972519],
                [108.2528541, 15.9727819],
                [108.2528756, 15.9726993],
                [108.2532236, 15.9727935],
                [108.253229, 15.9729475],
                [108.2540122, 15.9729437],
                [108.2540256, 15.9733318],
                [108.2547425, 15.973324],
                [108.2547283, 15.9725878],
                [108.2555008, 15.9724512],
                [108.2556322, 15.9723957],
                [108.2557704, 15.9723493],
                [108.2568312, 15.9721353],
                [108.2575367, 15.9719371],
                [108.2581455, 15.971695],
                [108.2581763, 15.9712682],
                [108.2580355, 15.9711573],
                [108.258061, 15.9708201],
                [108.2578021, 15.9706719],
                [108.2578759, 15.9703992],
                [108.2581227, 15.9703412],
                [108.2581709, 15.9701909],
                [108.2584578, 15.9703036],
                [108.258584, 15.9699653],
                [108.2587409, 15.969924],
                [108.2586128, 15.9696378],
                [108.2587664, 15.9693335],
                [108.259111, 15.9695991],
                [108.2594054, 15.9694747],
                [108.2592881, 15.9691492],
                [108.2593469, 15.9681644],
                [108.2593759, 15.9679816],
                [108.2593243, 15.9670275],
                [108.2593314, 15.9668138],
                [108.2594179, 15.9665956],
                [108.2599211, 15.9657233],
                [108.2604521, 15.9656627],
                [108.2607311, 15.9654719],
                [108.261002, 15.9655583],
                [108.2618724, 15.9651792],
                [108.2623968, 15.9647743],
                [108.2631397, 15.9650528],
                [108.2631719, 15.9651302],
                [108.2634562, 15.9651302],
                [108.2635125, 15.9648852],
                [108.263581, 15.9646892],
                [108.2636225, 15.9645938],
                [108.2641026, 15.9647821],
                [108.2639524, 15.965107],
                [108.2640823, 15.9652025],
                [108.2642118, 15.9652668],
                [108.2643333, 15.9653056],
                [108.2644969, 15.9649703],
                [108.2646579, 15.9649832],
                [108.2649288, 15.9651173],
                [108.2649153, 15.9653597],
                [108.2654276, 15.965548],
                [108.2655457, 15.9652849],
                [108.2657146, 15.965321],
                [108.2658568, 15.9653855],
                [108.2657281, 15.9657543],
                [108.2660101, 15.965847],
                [108.2662886, 15.965949],
                [108.2667607, 15.9661462],
                [108.2668881, 15.96586],
                [108.2670705, 15.9659206],
                [108.2670919, 15.9659],
                [108.2671175, 15.9658506],
                [108.2671201, 15.9657362],
                [108.2671293, 15.9657164],
                [108.2671643, 15.9656885],
                [108.2671872, 15.9656833],
                [108.2675573, 15.965811],
                [108.2672998, 15.96635],
                [108.2682118, 15.966484],
                [108.2683482, 15.9644778],
                [108.2683942, 15.9641967],
                [108.2684602, 15.9639408],
                [108.2685495, 15.963653],
                [108.2685632, 15.9635159],
                [108.2685337, 15.963452],
                [108.2685094, 15.9634258],
                [108.26848, 15.963405],
                [108.2684155, 15.9633834],
                [108.2683472, 15.963387],
                [108.2681809, 15.963463],
                [108.2679248, 15.9632142],
                [108.2680535, 15.9629898],
                [108.2682359, 15.9627371],
                [108.2688263, 15.9625307],
                [108.2690609, 15.9624766],
                [108.2692981, 15.9624509],
                [108.2692391, 15.9622136],
                [108.2692227, 15.9620644],
                [108.269223, 15.9619893],
                [108.2692445, 15.9618213],
                [108.2693088, 15.9615973],
                [108.2690205, 15.9614993],
                [108.2694375, 15.960215],
                [108.2695904, 15.9602266],
                [108.2696648, 15.9600991],
                [108.269695, 15.9600319],
                [108.2697464, 15.9598702],
                [108.2697621, 15.959787],
                [108.2702073, 15.9598566],
                [108.2706016, 15.9601686],
                [108.2712561, 15.960148],
                [108.2715672, 15.9600861],
                [108.2717711, 15.9600861],
                [108.2719119, 15.9607979],
                [108.272042, 15.9607708],
                [108.2722445, 15.961155],
                [108.2724617, 15.9611021],
                [108.2729378, 15.9618087],
                [108.2729807, 15.9624019],
                [108.2732748, 15.9623732],
                [108.2734206, 15.9623503],
                [108.2736865, 15.9622932],
                [108.2738176, 15.9622575],
                [108.2739598, 15.9624328],
                [108.2742843, 15.9624148],
                [108.2743098, 15.9625734],
                [108.274515, 15.9624586],
                [108.2747188, 15.9623297],
                [108.274751, 15.9624999],
                [108.2753438, 15.9625102],
                [108.2778114, 15.9621234],
                [108.2777658, 15.9617262],
                [108.2780313, 15.9617004],
                [108.2783022, 15.9618448],
                [108.2785276, 15.9615741],
                [108.2788789, 15.96185],
                [108.279174, 15.9616514],
                [108.2800323, 15.9614787],
                [108.285911, 15.9655679],
                [108.2895709, 15.9674041],
                [108.6563747, 16.1663171],
                [108.4805829, 16.3340091],
                [108.2105656, 16.2191327],
                [108.2056713, 16.2170498],
                [108.1991691, 16.2129762],
                [108.1947857, 16.21023],
                [108.1926413, 16.2091586],
                [108.1922432, 16.208983],
                [108.19189, 16.2088492],
                [108.1903364, 16.2083359],
                [108.1898708, 16.208218],
                [108.1893998, 16.2081224],
                [108.1888359, 16.2080385],
                [108.1870887, 16.2078701],
                [108.186789, 16.2078488],
                [108.1861925, 16.2077795],
                [108.1857198, 16.2076988],
                [108.1837077, 16.207303],
                [108.1835257, 16.207275],
                [108.1832123, 16.2072468],
                [108.1827621, 16.2072347],
                [108.1825733, 16.2072174],
                [108.1823856, 16.2071907],
                [108.1820284, 16.207113],
                [108.1808157, 16.2067508],
                [108.1806354, 16.2067142],
                [108.1804518, 16.206699],
                [108.1803435, 16.2067002],
                [108.1801284, 16.2067249],
                [108.1799025, 16.2067334],
                [108.179677, 16.2067184],
                [108.1795579, 16.2067009],
                [108.1793249, 16.2066425],
                [108.1790976, 16.2065518],
                [108.1789872, 16.2064939],
                [108.1786994, 16.2063074],
                [108.1785272, 16.2062264],
                [108.1783312, 16.2061648],
                [108.1779712, 16.2061053],
                [108.1779029, 16.2060822],
                [108.1776857, 16.2059689],
                [108.177604, 16.2059389],
                [108.1775196, 16.2059169],
                [108.1773476, 16.2058979],
                [108.1771767, 16.205912],
                [108.1770121, 16.2059582],
                [108.176707, 16.2058085],
                [108.1765506, 16.2057414],
                [108.176247, 16.2056287],
                [108.1760924, 16.2055797],
                [108.1758754, 16.205491],
                [108.1756706, 16.2053788],
                [108.1754749, 16.2052401],
                [108.1752926, 16.2050748],
                [108.1752097, 16.2049844],
                [108.1748189, 16.2044603],
                [108.174692, 16.2042607],
                [108.174637, 16.2041562],
                [108.1745767, 16.2040221],
                [108.17451, 16.2038423],
                [108.1744873, 16.2038032],
                [108.1744303, 16.2037425],
                [108.1743338, 16.2036806],
                [108.1742067, 16.2036283],
                [108.174071, 16.203603],
                [108.1734947, 16.2035859],
                [108.1730523, 16.2035287],
                [108.1728108, 16.2035173],
                [108.1727371, 16.2035076],
                [108.1725955, 16.2034648],
                [108.1724674, 16.2033927],
                [108.1723655, 16.2033018],
                [108.1722857, 16.2031923],
                [108.1722552, 16.2031321],
                [108.1720421, 16.2025787],
                [108.1719351, 16.2023381],
                [108.171722, 16.2019128],
                [108.1714837, 16.2015002],
                [108.1714143, 16.2014127],
                [108.1713231, 16.2013461],
                [108.1712143, 16.2013046],
                [108.1711552, 16.2012949],
                [108.1710356, 16.2012996],
                [108.1709455, 16.2013195],
                [108.1707694, 16.201374],
                [108.1706419, 16.2014273],
                [108.1705182, 16.2014884],
                [108.1702843, 16.2016328],
                [108.1700715, 16.2018048],
                [108.1698834, 16.2020016],
                [108.1698011, 16.2021102],
                [108.169651, 16.2023369],
                [108.1695081, 16.2026021],
                [108.1693814, 16.2029075],
                [108.1692817, 16.2030615],
                [108.1692219, 16.2031319],
                [108.169085, 16.2032568],
                [108.1689997, 16.2033162],
                [108.1689089, 16.2033676],
                [108.1688136, 16.2034105],
                [108.1687144, 16.2034445],
                [108.1673668, 16.2038068],
                [108.167189, 16.2038174],
                [108.1668345, 16.2038521],
                [108.1663494, 16.2039298],
                [108.1659788, 16.2039168],
                [108.1658438, 16.2039222],
                [108.1655756, 16.2039522],
                [108.1654429, 16.2039767],
                [108.1651873, 16.2040427],
                [108.1649445, 16.2041302],
                [108.1638597, 16.20458],
                [108.1637266, 16.2046592],
                [108.1634756, 16.2048388],
                [108.1632943, 16.2049989],
                [108.1632095, 16.2050847],
                [108.1630582, 16.2052603],
                [108.1629737, 16.2053403],
                [108.1628798, 16.2054175],
                [108.1626762, 16.2055519],
                [108.1624548, 16.2056573],
                [108.1617002, 16.2059291],
                [108.1611814, 16.2062461],
                [108.1610666, 16.2063024],
                [108.160826, 16.2063911],
                [108.1607015, 16.2064231],
                [108.1604617, 16.2064604],
                [108.1602337, 16.2064675],
                [108.1600066, 16.2064474],
                [108.1579472, 16.2060455],
                [108.1577253, 16.2059898],
                [108.1576176, 16.2059517],
                [108.1574112, 16.2058558],
                [108.1572195, 16.2057349],
                [108.1570286, 16.205575],
                [108.1568635, 16.2053904],
                [108.1567917, 16.20529],
                [108.1562694, 16.204428],
                [108.1562175, 16.2043259],
                [108.1561747, 16.2042199],
                [108.1561414, 16.2041109],
                [108.1561077, 16.2039263],
                [108.156076, 16.2032647],
                [108.1560269, 16.2028686],
                [108.1559662, 16.202571],
                [108.1559101, 16.2023865],
                [108.1558633, 16.2021996],
                [108.1558258, 16.2020108],
                [108.1557978, 16.2018204],
                [108.1557708, 16.2014581],
                [108.1557942, 16.201177],
                [108.1557843, 16.2008952],
                [108.155747, 16.2006436],
                [108.1556832, 16.200397],
                [108.1551846, 16.2005328],
                [108.1549128, 16.2006169],
                [108.1543761, 16.2008046],
                [108.1541594, 16.2008498],
                [108.1539381, 16.2008628],
                [108.1538268, 16.2008572],
                [108.1537163, 16.2008434],
                [108.1536072, 16.2008215],
                [108.153412, 16.2007664],
                [108.1532307, 16.2007383],
                [108.153047, 16.2007408],
                [108.1528665, 16.2007737],
                [108.1526906, 16.2008381],
                [108.1526059, 16.2008824],
                [108.1524507, 16.2009923],
                [108.152292, 16.2011347],
                [108.1521959, 16.2012051],
                [108.1520942, 16.2012676],
                [108.1519874, 16.2013219],
                [108.1518763, 16.2013675],
                [108.1517633, 16.2014037],
                [108.1516477, 16.201431],
                [108.1515301, 16.2014492],
                [108.1512457, 16.2014605],
                [108.1510802, 16.2014549],
                [108.1507511, 16.2014193],
                [108.1505826, 16.2013882],
                [108.150252, 16.2013008],
                [108.1500907, 16.2012446],
                [108.1490531, 16.2007399],
                [108.1486917, 16.2005449],
                [108.1483456, 16.2003258],
                [108.1481676, 16.2001993],
                [108.1478271, 16.1999273],
                [108.1476651, 16.1997823],
                [108.1475058, 16.1997099],
                [108.1474215, 16.1996851],
                [108.1472473, 16.1996593],
                [108.1471707, 16.1996583],
                [108.1470186, 16.1996745],
                [108.1469441, 16.1996917],
                [108.1466544, 16.1998276],
                [108.1464449, 16.1998465],
                [108.1462348, 16.1998562],
                [108.1460244, 16.1998567],
                [108.1458098, 16.1998476],
                [108.145596, 16.1998289],
                [108.1453832, 16.1998007],
                [108.1451721, 16.1997629],
                [108.1449883, 16.1997191],
                [108.1448071, 16.1996667],
                [108.1446288, 16.1996057],
                [108.1444538, 16.1995364],
                [108.1442827, 16.1994588],
                [108.1439994, 16.1993072],
                [108.1438631, 16.199223],
                [108.1436021, 16.1990382],
                [108.1433203, 16.1987634],
                [108.1430618, 16.1984682],
                [108.142825, 16.1981496],
                [108.1426127, 16.197809],
                [108.1424297, 16.1974529],
                [108.1419666, 16.1964791],
                [108.1417739, 16.1961333],
                [108.1415538, 16.195803],
                [108.1413129, 16.1954961],
                [108.1410485, 16.1952077],
                [108.1400648, 16.1942992],
                [108.139822, 16.1941016],
                [108.1396941, 16.1940106],
                [108.1394194, 16.1938407],
                [108.1392764, 16.1937647],
                [108.1386969, 16.1937712],
                [108.1385356, 16.1937286],
                [108.138387, 16.1936547],
                [108.138273, 16.1935672],
                [108.1381775, 16.1934612],
                [108.1381377, 16.1934024],
                [108.1380195, 16.1933018],
                [108.1379543, 16.1932588],
                [108.1378142, 16.1931888],
                [108.1375905, 16.1931073],
                [108.1374824, 16.193058],
                [108.1372752, 16.192943],
                [108.137078, 16.1928041],
                [108.1368979, 16.1926453],
                [108.136747, 16.1924442],
                [108.1366785, 16.1923391],
                [108.136611, 16.1922104],
                [108.1364919, 16.1919836],
                [108.1363944, 16.1917258],
                [108.1363555, 16.1915938],
                [108.1362819, 16.1914155],
                [108.136184, 16.1912484],
                [108.1361264, 16.19117],
                [108.1360509, 16.1910818],
                [108.1359682, 16.1909998],
                [108.1358789, 16.1909244],
                [108.1357835, 16.1908562],
                [108.1356828, 16.1907956],
                [108.1355773, 16.1907429],
                [108.1340141, 16.19007],
                [108.1319994, 16.1882517],
                [108.1308382, 16.1874956],
                [108.130727, 16.1874116],
                [108.130622, 16.1873205],
                [108.1305236, 16.1872228],
                [108.1304323, 16.1871189],
                [108.1302004, 16.1867958],
                [108.130005, 16.1865628],
                [108.1281116, 16.1845569],
                [108.1240843, 16.1826345],
                [108.1235242, 16.1823985],
                [108.1229504, 16.182195],
                [108.1224225, 16.1820397],
                [108.1221555, 16.1819724],
                [108.1216163, 16.1818585],
                [108.1204439, 16.1815026],
                [108.1202696, 16.1814571],
                [108.1198496, 16.1813676],
                [108.1197297, 16.1813343],
                [108.1194733, 16.1812433],
                [108.1192894, 16.1811608],
                [108.1191613, 16.1811288],
                [108.119095, 16.1811254],
                [108.1189755, 16.181141],
                [108.1189236, 16.181157],
                [108.1188268, 16.1812049],
                [108.1185505, 16.181412],
                [108.1183759, 16.1815284],
                [108.118057, 16.1817182],
                [108.1177889, 16.1818947],
                [108.1175458, 16.182086],
                [108.1173276, 16.1822888],
                [108.1170805, 16.1823789],
                [108.1168425, 16.1824894],
                [108.1164142, 16.18274],
                [108.1162601, 16.1827988],
                [108.1160979, 16.1828323],
                [108.1160166, 16.1828393],
                [108.1158536, 16.1828339],
                [108.1156936, 16.1828032],
                [108.1144316, 16.1822347],
                [108.1142441, 16.1821805],
                [108.1141475, 16.1821646],
                [108.1139519, 16.1821556],
                [108.1138542, 16.1821626],
                [108.1137722, 16.1821745],
                [108.1136116, 16.1822144],
                [108.1133825, 16.1822273],
                [108.1132107, 16.182252],
                [108.1130459, 16.1822979],
                [108.1128003, 16.1824032],
                [108.1127064, 16.1824351],
                [108.1126099, 16.1824586],
                [108.1124123, 16.1824797],
                [108.1122334, 16.1824687],
                [108.1120589, 16.1824295],
                [108.111287, 16.1821303],
                [108.1112209, 16.1821235],
                [108.1110885, 16.1821333],
                [108.1109712, 16.1821696],
                [108.1109205, 16.1821948],
                [108.1108289, 16.1822597],
                [108.1106132, 16.1825315],
                [108.1103639, 16.1828032],
                [108.1098586, 16.1835196],
                [108.1097027, 16.1836944],
                [108.1095217, 16.1838451],
                [108.1094207, 16.1839117],
                [108.1092045, 16.184022],
                [108.1089769, 16.1841088],
                [108.1088665, 16.1841593],
                [108.1087596, 16.1842165],
                [108.1085582, 16.1843498],
                [108.1083771, 16.1845055],
                [108.1082945, 16.1845912],
                [108.1080745, 16.1848604],
                [108.1079946, 16.1849376],
                [108.107908, 16.1850078],
                [108.1078154, 16.1850705],
                [108.1077174, 16.1851252],
                [108.1076149, 16.1851716],
                [108.1074153, 16.1852342],
                [108.1072078, 16.1852653],
                [108.1066513, 16.1852945],
                [108.1053856, 16.1861412],
                [108.1053008, 16.1861838],
                [108.1052118, 16.1862178],
                [108.1051197, 16.1862428],
                [108.1050253, 16.1862587],
                [108.1048479, 16.1862632],
                [108.1046862, 16.1862386],
                [108.1045314, 16.1861872],
                [108.1044582, 16.1861519],
                [108.1040245, 16.1859004],
                [108.1038537, 16.1858253],
                [108.1036721, 16.1857789],
                [108.1035788, 16.1857669],
                [108.1033958, 16.1857654],
                [108.1033048, 16.1857755],
                [108.1030594, 16.1858249],
                [108.1029061, 16.1858659],
                [108.1027557, 16.1859157],
                [108.1026086, 16.185974],
                [108.102354, 16.1860999],
                [108.1022323, 16.1861726],
                [108.1020022, 16.1863363],
                [108.1018735, 16.1864073],
                [108.1017405, 16.1864703],
                [108.1014634, 16.1865718],
                [108.1013205, 16.1866099],
                [108.1010286, 16.1866599],
                [108.1008752, 16.1866719],
                [108.1007213, 16.1866743],
                [108.1005676, 16.1866671],
                [108.1004147, 16.1866503],
                [108.1002633, 16.186624],
                [108.1001139, 16.1865883],
                [108.0999673, 16.1865434],
                [108.0982593, 16.1858866],
                [108.0979521, 16.1857461],
                [108.0978039, 16.1856659],
                [108.0975102, 16.1854798],
                [108.0972275, 16.1852606],
                [108.0964939, 16.1845633],
                [108.0963556, 16.1844564],
                [108.0960638, 16.1842621],
                [108.095529, 16.1839734],
                [108.0953446, 16.1838617],
                [108.0949905, 16.1836175],
                [108.0948213, 16.1834853],
                [108.0944928, 16.1831949],
                [108.0943345, 16.1830365],
                [108.0941833, 16.1828719],
                [108.0940395, 16.1827012],
                [108.0939032, 16.182525],
                [108.0939234, 16.1812826],
                [108.0938955, 16.1811136],
                [108.0938698, 16.1810317],
                [108.0937958, 16.1808761],
                [108.0937482, 16.1808037],
                [108.0936416, 16.1806804],
                [108.0935148, 16.180576],
                [108.0933171, 16.1804545],
                [108.0931846, 16.1803845],
                [108.0929078, 16.1802677],
                [108.0927645, 16.1802213],
                [108.0926134, 16.180182],
                [108.09246, 16.1801517],
                [108.092305, 16.1801304],
                [108.0921489, 16.1801184],
                [108.0919908, 16.1801364],
                [108.0916792, 16.1801983],
                [108.0913936, 16.1802879],
                [108.0911358, 16.1803988],
                [108.0910116, 16.1804636],
                [108.0901242, 16.1810109],
                [108.0896383, 16.1812982],
                [108.0893628, 16.1814501],
                [108.0888015, 16.1817361],
                [108.0876481, 16.1818443],
                [108.0873891, 16.1818928],
                [108.087149, 16.1819629],
                [108.0869293, 16.1820504],
                [108.0863386, 16.1823574],
                [108.086238, 16.1824031],
                [108.0860287, 16.1824752],
                [108.0858047, 16.1825213],
                [108.0856873, 16.1825336],
                [108.0854514, 16.1825347],
                [108.0840192, 16.18239],
                [108.0837637, 16.1823721],
                [108.0832514, 16.1823618],
                [108.0827397, 16.1823853],
                [108.0822553, 16.1824389],
                [108.0820147, 16.182477],
                [108.0815381, 16.1825759],
                [108.0801272, 16.1830138],
                [108.0798846, 16.1828497],
                [108.0797559, 16.1827785],
                [108.07949, 16.1826607],
                [108.0793537, 16.1826149],
                [108.0792146, 16.1825777],
                [108.0790732, 16.1825493],
                [108.0789302, 16.1825297],
                [108.0787861, 16.1825192],
                [108.0785214, 16.1825234],
                [108.0783897, 16.1825369],
                [108.07813, 16.1825864],
                [108.0756462, 16.1833258],
                [108.0755672, 16.1833417],
                [108.0754064, 16.1833507],
                [108.0752397, 16.183327],
                [108.0751557, 16.1833015],
                [108.0750751, 16.1832674],
                [108.0749989, 16.183225],
                [108.0746014, 16.182856],
                [108.0743551, 16.1826429],
                [108.0740336, 16.1823976],
                [108.0736953, 16.182174],
                [108.0705317, 16.1822351],
                [108.0703566, 16.1822486],
                [108.0701825, 16.1822712],
                [108.0700099, 16.1823028],
                [108.0697326, 16.1823736],
                [108.0669844, 16.1833538],
                [108.0641413, 16.1840545],
                [108.0627948, 16.1825141],
                [108.0620037, 16.1808462],
                [108.0618946, 16.1806565],
                [108.061832, 16.1805637],
                [108.0617648, 16.1804739],
                [108.0616507, 16.1803585],
                [108.0614108, 16.180139],
                [108.0610963, 16.1798918],
                [108.0609314, 16.1797777],
                [108.0607617, 16.1796702],
                [108.0600697, 16.1792787],
                [108.059899, 16.179217],
                [108.0597251, 16.1791643],
                [108.0595485, 16.1791209],
                [108.0593696, 16.1790868],
                [108.0591891, 16.1790622],
                [108.0590074, 16.1790472],
                [108.0588251, 16.1790417],
                [108.0584829, 16.1790573],
                [108.0581441, 16.1791066],
                [108.0579772, 16.1791438],
                [108.0576503, 16.1792426],
                [108.0559766, 16.1800566],
                [108.0556508, 16.1802639],
                [108.0550123, 16.1806967],
                [108.0543966, 16.1811501],
                [108.053804, 16.1816228],
                [108.0530798, 16.1823492],
                [108.0530174, 16.1824355],
                [108.0529474, 16.1825163],
                [108.0528702, 16.1825908],
                [108.0527866, 16.1826585],
                [108.0526971, 16.182719],
                [108.0525151, 16.1828114],
                [108.0523321, 16.1828708],
                [108.0522376, 16.1828902],
                [108.0518246, 16.1829417],
                [108.0513633, 16.1830947],
                [108.0511363, 16.1831809],
                [108.0506909, 16.1833724],
                [108.0502277, 16.1836049],
                [108.0499873, 16.1837402],
                [108.0497517, 16.1838832],
                [108.0495213, 16.1840337],
                [108.0492963, 16.1841915],
                [108.0490769, 16.1843565],
                [108.0488634, 16.1845284],
                [108.0456018, 16.186517],
                [108.0454168, 16.1866225],
                [108.0450357, 16.1868146],
                [108.0446416, 16.1869807],
                [108.0444123, 16.1870634],
                [108.0441797, 16.1871372],
                [108.0439442, 16.1872022],
                [108.0436868, 16.1872572],
                [108.0435617, 16.1872976],
                [108.043322, 16.1874031],
                [108.0431079, 16.1875341],
                [108.0429143, 16.1876916],
                [108.0423993, 16.1882274],
                [108.0414927, 16.1886756],
                [108.0400711, 16.1895359],
                [108.0399232, 16.1896191],
                [108.0396171, 16.1897673],
                [108.0393079, 16.1898878],
                [108.0389982, 16.1899816],
                [108.0386688, 16.190056],
                [108.0385399, 16.1900938],
                [108.0383049, 16.1901838],
                [108.0381987, 16.1902341],
                [108.0379951, 16.1903499],
                [108.0373755, 16.1908883],
                [108.0372885, 16.1909573],
                [108.0371012, 16.1910782],
                [108.036899, 16.1911744],
                [108.0366716, 16.191248],
                [108.0365474, 16.1912744],
                [108.0364215, 16.1912919],
                [108.0362946, 16.1913004],
                [108.034578, 16.1912463],
                [108.0344028, 16.1912207],
                [108.0342368, 16.1911613],
                [108.0341594, 16.1911196],
                [108.0340201, 16.1910145],
                [108.0339133, 16.1908943],
                [108.0338323, 16.1907569],
                [108.0337979, 16.1907113],
                [108.0337113, 16.1906359],
                [108.0336606, 16.1906075],
                [108.0335562, 16.1905733],
                [108.0334461, 16.1905663],
                [108.0332869, 16.1905809],
                [108.0329717, 16.1906316],
                [108.0326629, 16.1907105],
                [108.032496, 16.190766],
                [108.0321723, 16.1909017],
                [108.0318651, 16.191069],
                [108.0317187, 16.1911639],
                [108.0293906, 16.1925188],
                [108.0282265, 16.1930803],
                [108.0267191, 16.1929979],
                [108.0259305, 16.1934177],
                [108.0251964, 16.1929846],
                [108.0251295, 16.1929512],
                [108.0249876, 16.1929026],
                [108.0249138, 16.1928878],
                [108.0247495, 16.1928779],
                [108.0246604, 16.1928856],
                [108.0245728, 16.1929025],
                [108.0241763, 16.1930288],
                [108.0239971, 16.1930988],
                [108.0236321, 16.1932225],
                [108.0232558, 16.1933249],
                [108.0228693, 16.1934048],
                [108.020491, 16.1938221],
                [108.01912, 16.1941726],
                [108.0189627, 16.1942025],
                [108.0186443, 16.1942391],
                [108.0184841, 16.1942457],
                [108.0181706, 16.1942361],
                [108.0178663, 16.1941977],
                [108.017716, 16.194168],
                [108.0175674, 16.1941312],
                [108.0165911, 16.193817],
                [108.0164035, 16.1937864],
                [108.0160249, 16.1937503],
                [108.0158347, 16.1937448],
                [108.0154733, 16.1937578],
                [108.0152934, 16.1937757],
                [108.0150993, 16.1938661],
                [108.0147238, 16.1940702],
                [108.0143675, 16.1943038],
                [108.0142386, 16.1944049],
                [108.0141261, 16.1945227],
                [108.0140704, 16.1945961],
                [108.0139786, 16.1947545],
                [108.0139142, 16.1949606],
                [108.0137784, 16.1951132],
                [108.0136245, 16.1952491],
                [108.0135328, 16.1953162],
                [108.0134367, 16.1953774],
                [108.0132329, 16.1954809],
                [108.012777, 16.1960682],
                [108.0126707, 16.1961677],
                [108.0126102, 16.1962091],
                [108.0124776, 16.196273],
                [108.0122709, 16.1963293],
                [108.012206, 16.1963556],
                [108.0120836, 16.1964262],
                [108.0120273, 16.1964703],
                [108.0119758, 16.1965194],
                [108.0111623, 16.1974179],
                [108.0109258, 16.1975593],
                [108.0108151, 16.1976406],
                [108.0106111, 16.1978227],
                [108.0104359, 16.1980243],
                [108.0103598, 16.1981305],
                [108.0102289, 16.1983554],
                [108.0098426, 16.1987057],
                [108.0096954, 16.1990549],
                [108.0096093, 16.1992242],
                [108.0094135, 16.1995506],
                [108.009215, 16.1998236],
                [108.0091378, 16.1998862],
                [108.0090006, 16.2000283],
                [108.008897, 16.2001764],
                [108.0088234, 16.2003233],
                [108.008699, 16.2004663],
                [108.0085982, 16.2006255],
                [108.008523, 16.2007972],
                [108.0084925, 16.2008984],
                [108.0084586, 16.2011063],
                [108.0084448, 16.2013072],
                [108.0084035, 16.2015046],
                [108.0083349, 16.2016964],
                [108.0082904, 16.2017893],
                [108.0081824, 16.2019655],
                [108.0080509, 16.2021263],
                [108.0073214, 16.2029453],
                [108.0072419, 16.2030488],
                [108.0071705, 16.2031575],
                [108.0071075, 16.2032709],
                [108.0070531, 16.2033884],
                [108.0069912, 16.2035621],
                [108.0069523, 16.2037168],
                [108.0069294, 16.2037804],
                [108.0068614, 16.2038977],
                [108.0067722, 16.2039922],
                [108.0066697, 16.2040621],
                [108.0065014, 16.2041568],
                [108.006395, 16.2042324],
                [108.0062947, 16.2043153],
                [108.006201, 16.204405],
                [108.0061144, 16.204501],
                [108.0060368, 16.2046008],
                [108.0059669, 16.2047057],
                [108.0058515, 16.2049286],
                [108.0054438, 16.2052789],
                [108.0052614, 16.2051553],
                [108.0050898, 16.205114],
                [108.0048742, 16.2050828],
                [108.0047651, 16.2050797],
                [108.0046561, 16.2050849],
                [108.004484, 16.2051107],
                [108.0043539, 16.2051138],
                [108.0042264, 16.2050887],
                [108.0041657, 16.205066],
                [108.0040018, 16.2049749],
                [108.0038848, 16.2049348],
                [108.0037557, 16.2049179],
                [108.0036199, 16.2049286],
                [108.0020428, 16.2060928],
                [108.0011201, 16.2065358],
                [108.0008442, 16.2067567],
                [108.0005525, 16.206958],
                [108.0002184, 16.2071538],
                [108.0000311, 16.2072492],
                [107.9996449, 16.2074167],
                [107.9995071, 16.2074431],
                [107.9993716, 16.2074787],
                [107.999239, 16.2075232],
                [107.99911, 16.2075764],
                [107.998985, 16.2076382],
                [107.9987834, 16.207762],
                [107.9986887, 16.2078323],
                [107.998513, 16.2079884],
                [107.9982126, 16.20804],
                [107.9976018, 16.2083603],
                [107.9972917, 16.2085121],
                [107.9967974, 16.2087395],
                [107.9964455, 16.2089227],
                [107.9962754, 16.2090242],
                [107.9959569, 16.20924],
                [107.9956644, 16.209472],
                [107.9953501, 16.209763],
                [107.995255, 16.209824],
                [107.9952023, 16.2098455],
                [107.9950905, 16.2098686],
                [107.9949696, 16.2098632],
                [107.9949108, 16.209849],
                [107.9943703, 16.2096069],
                [107.9942416, 16.2095659],
                [107.9941746, 16.2095553],
                [107.9940391, 16.2095544],
                [107.9939648, 16.2095655],
                [107.9938229, 16.2096119],
                [107.9936957, 16.2096883],
                [107.9916465, 16.2114758],
                [107.9915915, 16.2115779],
                [107.9914626, 16.2117714],
                [107.991313, 16.211945],
                [107.9911465, 16.2120973],
                [107.9899115, 16.2129743],
                [107.9898442, 16.2130311],
                [107.9897261, 16.2131602],
                [107.9896767, 16.2132304],
                [107.9895973, 16.2133817],
                [107.9892842, 16.2136174],
                [107.9889536, 16.2138298],
                [107.9886194, 16.2140117],
                [107.9882723, 16.2141698],
                [107.9880738, 16.2137268],
                [107.9878921, 16.213561],
                [107.9877037, 16.2134023],
                [107.9873715, 16.2131517],
                [107.9870224, 16.2129232],
                [107.9857993, 16.2120476],
                [107.9851712, 16.2119432],
                [107.9850579, 16.2119017],
                [107.9849592, 16.2118399],
                [107.9848783, 16.2117623],
                [107.9848444, 16.2117179],
                [107.9845548, 16.2110791],
                [107.9837179, 16.2103065],
                [107.9831273, 16.2100587],
                [107.9830334, 16.2100107],
                [107.9828571, 16.2098966],
                [107.9826987, 16.2097604],
                [107.9825508, 16.2095909],
                [107.9824872, 16.2094987],
                [107.9823822, 16.209302],
                [107.9820174, 16.2083387],
                [107.9807782, 16.2081069],
                [107.9807138, 16.2076794],
                [107.9804539, 16.2075112],
                [107.9802136, 16.2073181],
                [107.9799955, 16.2071021],
                [107.9798955, 16.2069862],
                [107.9797146, 16.2067397],
                [107.9796343, 16.2066098],
                [107.9794953, 16.2063388],
                [107.9794371, 16.2061984],
                [107.9793361, 16.20601],
                [107.9792983, 16.2059104],
                [107.9792494, 16.2057038],
                [107.9792386, 16.2055983],
                [107.9792438, 16.2053957],
                [107.979259, 16.2052954],
                [107.9793143, 16.2050998],
                [107.979354, 16.2050059],
                [107.979598, 16.2045989],
                [107.9794371, 16.2039911],
                [107.9794049, 16.2034347],
                [107.9793082, 16.203322],
                [107.9792041, 16.2032155],
                [107.9790931, 16.2031155],
                [107.9789758, 16.2030226],
                [107.9788723, 16.2029501],
                [107.9785782, 16.2027736],
                [107.9784384, 16.2026626],
                [107.9783198, 16.202538],
                [107.978223, 16.2024038],
                [107.9781818, 16.2023323],
                [107.9761219, 16.2003851],
                [107.9756918, 16.2001133],
                [107.9752442, 16.1998691],
                [107.9747808, 16.1996536],
                [107.9743126, 16.1994709],
                [107.9738332, 16.1993174],
                [107.9735899, 16.1992518],
                [107.972061, 16.199056],
                [107.9716104, 16.1980567],
                [107.9712988, 16.1972807],
                [107.9712198, 16.1971162],
                [107.9711323, 16.1969557],
                [107.9710364, 16.1967997],
                [107.9708496, 16.1965389],
                [107.9706395, 16.1962949],
                [107.9699904, 16.1960167],
                [107.9699743, 16.1953418],
                [107.969855, 16.1950854],
                [107.969763, 16.1948187],
                [107.9696983, 16.1945393],
                [107.9696504, 16.194163],
                [107.9696095, 16.1940848],
                [107.9695363, 16.1940177],
                [107.9694915, 16.1939947],
                [107.9692233, 16.1939123],
                [107.9687946, 16.1936801],
                [107.9681704, 16.193342],
                [107.9680207, 16.1932768],
                [107.9678672, 16.1932204],
                [107.9675731, 16.1931394],
                [107.9672938, 16.193093],
                [107.9671526, 16.1930803],
                [107.9652107, 16.1930906],
                [107.9640734, 16.1926269],
                [107.9638588, 16.192256],
                [107.9638044, 16.1921899],
                [107.9636761, 16.1920754],
                [107.9636035, 16.1920281],
                [107.9635262, 16.1919881],
                [107.9633759, 16.1919355],
                [107.9632181, 16.1919104],
                [107.9630581, 16.1919139],
                [107.9623246, 16.19205],
                [107.9621317, 16.192069],
                [107.9619384, 16.1920551],
                [107.9617506, 16.1920087],
                [107.9615846, 16.1919368],
                [107.9615068, 16.1918912],
                [107.9612586, 16.1917022],
                [107.9611391, 16.191643],
                [107.9610047, 16.1916056],
                [107.9605964, 16.1915643],
                [107.9604804, 16.1915332],
                [107.9604256, 16.191509],
                [107.9603298, 16.1914481],
                [107.9602871, 16.191411],
                [107.9601972, 16.1913081],
                [107.9600823, 16.1911948],
                [107.9599428, 16.1910903],
                [107.9598677, 16.1910454],
                [107.9590791, 16.1908033],
                [107.9580009, 16.1901026],
                [107.9569656, 16.1900524],
                [107.9568781, 16.1900314],
                [107.9568372, 16.1900129],
                [107.956764, 16.1899603],
                [107.9567071, 16.1898902],
                [107.9564045, 16.1892967],
                [107.9563271, 16.1892116],
                [107.956281, 16.1891765],
                [107.956177, 16.1891238],
                [107.9561164, 16.189106],
                [107.9559906, 16.1890938],
                [107.9558659, 16.1891135],
                [107.9551417, 16.1893943],
                [107.9540637, 16.1894658],
                [107.9537765, 16.1895019],
                [107.9534914, 16.1895605],
                [107.9532105, 16.1896416],
                [107.9522341, 16.1900254],
                [107.9510164, 16.1900305],
                [107.950201, 16.1904736],
                [107.9497611, 16.1906178],
                [107.949612, 16.190679],
                [107.9494671, 16.1907491],
                [107.9493271, 16.1908277],
                [107.9491925, 16.1909145],
                [107.9489531, 16.1911013],
                [107.9488481, 16.1911992],
                [107.9486561, 16.1914112],
                [107.9485629, 16.1915854],
                [107.948444, 16.1917445],
                [107.948302, 16.1918851],
                [107.9482152, 16.1919534],
                [107.9480257, 16.1920687],
                [107.9479242, 16.1921149],
                [107.947543, 16.1922498],
                [107.9474098, 16.1923099],
                [107.9471559, 16.1924523],
                [107.947036, 16.1925342],
                [107.9468149, 16.1927155],
                [107.9467131, 16.1928153],
                [107.946551, 16.1930033],
                [107.9464029, 16.1931565],
                [107.9463219, 16.1932264],
                [107.9461515, 16.1933491],
                [107.946063, 16.1934019],
                [107.9458773, 16.1934924],
                [107.9452336, 16.1936727],
                [107.944931, 16.193742],
                [107.9446381, 16.1938427],
                [107.9443762, 16.1939641],
                [107.9441285, 16.1941106],
                [107.9435706, 16.1946361],
                [107.9433714, 16.1945594],
                [107.9431623, 16.1945129],
                [107.9429483, 16.1944978],
                [107.942841, 16.1945021],
                [107.942727, 16.1945155],
                [107.9426146, 16.1945378],
                [107.9425044, 16.1945689],
                [107.9423973, 16.1946086],
                [107.9422939, 16.1946567],
                [107.9421552, 16.1946823],
                [107.9418754, 16.1947185],
                [107.9416921, 16.1947311],
                [107.9413246, 16.1947304],
                [107.940959, 16.1946952],
                [107.940778, 16.1946647],
                [107.9404037, 16.1945868],
                [107.9400094, 16.1945332],
                [107.9396117, 16.1945124],
                [107.9392459, 16.1945225],
                [107.9388822, 16.1945603],
                [107.9385227, 16.1946258],
                [107.9385285, 16.1946895],
                [107.9385158, 16.1948167],
                [107.9384712, 16.1949371],
                [107.9383975, 16.1950435],
                [107.9383141, 16.1951189],
                [107.9381935, 16.1951949],
                [107.9381086, 16.1952381],
                [107.9380204, 16.1952746],
                [107.937836, 16.1953264],
                [107.9377304, 16.1953426],
                [107.9376236, 16.1953495],
                [107.9375166, 16.1953472],
                [107.9374103, 16.1953356],
                [107.9360497, 16.1950224],
                [107.9355776, 16.1952285],
                [107.9354615, 16.1952689],
                [107.9352221, 16.1953264],
                [107.9350999, 16.1953433],
                [107.9348685, 16.1953532],
                [107.9346526, 16.1953367],
                [107.9344404, 16.1952955],
                [107.9332709, 16.1958673],
                [107.9319298, 16.1971758],
                [107.9308408, 16.1975261],
                [107.9307479, 16.1975504],
                [107.9306666, 16.1975913],
                [107.9305994, 16.1976445],
                [107.930498, 16.1976585],
                [107.9303956, 16.1976632],
                [107.9302932, 16.1976585],
                [107.9301918, 16.1976445],
                [107.9300333, 16.197603],
                [107.929957, 16.1975737],
                [107.9298108, 16.1974993],
                [107.9297293, 16.197482],
                [107.9296411, 16.1974897],
                [107.9295554, 16.1975263],
                [107.9295185, 16.1975544],
                [107.9292469, 16.1979294],
                [107.9290165, 16.1982992],
                [107.9288238, 16.1986594],
                [107.9287822, 16.1987683],
                [107.9286829, 16.1989798],
                [107.9285615, 16.1991829],
                [107.9284188, 16.1993754],
                [107.9282911, 16.1995982],
                [107.9281855, 16.1998313],
                [107.9281302, 16.1999842],
                [107.9280606, 16.2002226],
                [107.9279882, 16.2003792],
                [107.927885, 16.2005191],
                [107.9278283, 16.2005763],
                [107.9276997, 16.2006748],
                [107.9276289, 16.2007152],
                [107.9274773, 16.2007766],
                [107.9266512, 16.2014566],
                [107.925868, 16.2014772],
                [107.9253423, 16.2017554],
                [107.9251429, 16.2018011],
                [107.924946, 16.2018561],
                [107.9247522, 16.2019202],
                [107.9246032, 16.2019767],
                [107.9243123, 16.2021057],
                [107.9239583, 16.2027856],
                [107.9239025, 16.2029085],
                [107.9237681, 16.2031433],
                [107.9236146, 16.2033496],
                [107.9235338, 16.2034408],
                [107.9233575, 16.2036099],
                [107.9230463, 16.204022],
                [107.9224815, 16.2043655],
                [107.9223288, 16.2044681],
                [107.9220378, 16.2046916],
                [107.9217646, 16.2049414],
                [107.9216367, 16.2050745],
                [107.9213995, 16.2053562],
                [107.9213995, 16.2057786],
                [107.9213868, 16.2058596],
                [107.9213351, 16.2060155],
                [107.9212967, 16.2060884],
                [107.9211969, 16.2062205],
                [107.9208428, 16.2065441],
                [107.9206892, 16.20667],
                [107.9203695, 16.2069067],
                [107.9200078, 16.2071383],
                [107.9196299, 16.2073447],
                [107.9192376, 16.2075248],
                [107.9186582, 16.2079678],
                [107.9180038, 16.2081327],
                [107.9175424, 16.2087508],
                [107.9173242, 16.2089634],
                [107.917127, 16.2091941],
                [107.9169524, 16.2094411],
                [107.9167864, 16.2097325],
                [107.9167151, 16.2098839],
                [107.9165555, 16.210276],
                [107.9164297, 16.2105003],
                [107.9163564, 16.2106065],
                [107.9162002, 16.2107945],
                [107.9161192, 16.2108766],
                [107.9159439, 16.2110276],
                [107.9157041, 16.2112809],
                [107.9155949, 16.2114162],
                [107.9153993, 16.2117022],
                [107.9153134, 16.2118522],
                [107.9151788, 16.2121346],
                [107.9150821, 16.2123976],
                [107.9149846, 16.2127668],
                [107.9148923, 16.2129915],
                [107.9147677, 16.2132013],
                [107.9146939, 16.2132993],
                [107.9145378, 16.2134675],
                [107.9143597, 16.2136143],
                [107.9141629, 16.2137371],
                [107.9140617, 16.2138107],
                [107.9139668, 16.2138916],
                [107.9138145, 16.2140521],
                [107.9137552, 16.2141286],
                [107.913679, 16.2142536],
                [107.9136325, 16.2143107],
                [107.9135245, 16.2144119],
                [107.9134569, 16.2144596],
                [107.9133087, 16.2145347],
                [107.9127021, 16.2147342],
                [107.9124435, 16.214834],
                [107.9121888, 16.2149425],
                [107.9117427, 16.2151583],
                [107.9113117, 16.2154009],
                [107.9111144, 16.2154396],
                [107.9109198, 16.2154472],
                [107.9107328, 16.2154266],
                [107.9106411, 16.215406],
                [107.9105344, 16.2153639],
                [107.9104198, 16.2153503],
                [107.9103622, 16.2153545],
                [107.9102598, 16.2153813],
                [107.910145, 16.215446],
                [107.9100849, 16.2154467],
                [107.9100538, 16.2154353],
                [107.9100269, 16.2154163],
                [107.9093456, 16.2146746],
                [107.9091684, 16.2146627],
                [107.9090815, 16.2146443],
                [107.9089969, 16.2146179],
                [107.908862, 16.214556],
                [107.9087394, 16.2144737],
                [107.908512, 16.2141972],
                [107.9084747, 16.2141624],
                [107.9083864, 16.2141103],
                [107.9083372, 16.214094],
                [107.9082322, 16.2140828],
                [107.9081263, 16.2141024],
                [107.9077256, 16.2142728],
                [107.9069745, 16.2142831],
                [107.9064205, 16.2144298],
                [107.9062308, 16.2144546],
                [107.9060394, 16.2144505],
                [107.9058523, 16.214418],
                [107.9056739, 16.2143584],
                [107.9055072, 16.214273],
                [107.9054296, 16.2142213],
                [107.9048717, 16.2137577],
                [107.9047741, 16.213642],
                [107.9046841, 16.2135206],
                [107.9046021, 16.2133942],
                [107.9045284, 16.2132632],
                [107.9044093, 16.2129961],
                [107.9043245, 16.2127172],
                [107.904285, 16.2116906],
                [107.9042474, 16.2114737],
                [107.9042172, 16.2113676],
                [107.9041668, 16.2112323],
                [107.9041075, 16.2111002],
                [107.9040398, 16.210972],
                [107.9039637, 16.2108482],
                [107.9038797, 16.2107292],
                [107.9037881, 16.2106155],
                [107.9035883, 16.2104104],
                [107.9033639, 16.2102304],
                [107.9032434, 16.2101507],
                [107.9029883, 16.2100133],
                [107.9028547, 16.2099562],
                [107.9020393, 16.2097295],
                [107.9016611, 16.2096383],
                [107.9014753, 16.2095817],
                [107.9011115, 16.2094469],
                [107.9009342, 16.209369],
                [107.9005634, 16.2091774],
                [107.9002116, 16.2089552],
                [107.9000437, 16.2088332],
                [107.8987187, 16.2080915],
                [107.8984311, 16.2078976],
                [107.898166, 16.2076761],
                [107.8980428, 16.2075557],
                [107.8978194, 16.2073008],
                [107.8976244, 16.2070252],
                [107.8948295, 16.2051553],
                [107.8946318, 16.2051911],
                [107.8944433, 16.2052583],
                [107.8942748, 16.2053512],
                [107.894197, 16.2054072],
                [107.894057, 16.2055365],
                [107.8933382, 16.2055777],
                [107.8931074, 16.2056404],
                [107.8926515, 16.2057837],
                [107.892175, 16.2059622],
                [107.8919409, 16.2060612],
                [107.8914821, 16.2062782],
                [107.8905181, 16.2064449],
                [107.8904436, 16.2064479],
                [107.8903693, 16.2064418],
                [107.8902964, 16.2064266],
                [107.8902262, 16.2064025],
                [107.8901597, 16.2063701],
                [107.8900981, 16.2063297],
                [107.8899984, 16.2062343],
                [107.8899254, 16.2061188],
                [107.8898835, 16.2059898],
                [107.8898787, 16.205588],
                [107.8898835, 16.2051862],
                [107.8899127, 16.2045196],
                [107.8899434, 16.2040997],
                [107.8899398, 16.2040124],
                [107.8899264, 16.203926],
                [107.8899034, 16.2038415],
                [107.8898711, 16.2037598],
                [107.8897853, 16.2036158],
                [107.8896773, 16.2034973],
                [107.8896148, 16.2034462],
                [107.8894758, 16.2033626],
                [107.8890574, 16.2031669],
                [107.8888452, 16.2029759],
                [107.8886583, 16.202762],
                [107.8885753, 16.2026473],
                [107.8884278, 16.2023978],
                [107.8883648, 16.2022634],
                [107.8883108, 16.2021255],
                [107.888266, 16.2019845],
                [107.8882306, 16.2018411],
                [107.8882046, 16.2016959],
                [107.8881884, 16.2015493],
                [107.8881562, 16.2005191],
                [107.8874481, 16.2003027],
                [107.8872013, 16.19987],
                [107.8871166, 16.199761],
                [107.8870064, 16.1996755],
                [107.8869438, 16.1996433],
                [107.8868115, 16.1996031],
                [107.886673, 16.1995949],
                [107.8865366, 16.1996193],
                [107.8864717, 16.1996433],
                [107.885474, 16.1999318],
                [107.8846156, 16.1999936],
                [107.8843347, 16.2000641],
                [107.8840475, 16.2001055],
                [107.8837573, 16.2001172],
                [107.8834557, 16.2000979],
                [107.8831581, 16.2000466],
                [107.883012, 16.2000092],
                [107.8827274, 16.1999112],
                [107.8801846, 16.1989118],
                [107.8784466, 16.1983658],
                [107.8782105, 16.1978197],
                [107.8781548, 16.1977085],
                [107.8780903, 16.1976019],
                [107.8780174, 16.1975003],
                [107.8778703, 16.1973361],
                [107.8777014, 16.1971926],
                [107.8776097, 16.1971294],
                [107.8760195, 16.1969953],
                [107.8759112, 16.1969754],
                [107.8758052, 16.1969463],
                [107.8757023, 16.1969082],
                [107.8756034, 16.1968615],
                [107.8754542, 16.1967688],
                [107.8753851, 16.196715],
                [107.8752601, 16.1965936],
                [107.874788, 16.1958879],
                [107.8747093, 16.195741],
                [107.8746587, 16.1955831],
                [107.8746378, 16.1954191],
                [107.8746467, 16.195259],
                [107.874684, 16.1951028],
                [107.8747129, 16.1950276],
                [107.8751233, 16.194582],
                [107.8752797, 16.1944284],
                [107.8753647, 16.1943586],
                [107.8756886, 16.1941431],
                [107.8757505, 16.1940868],
                [107.8758055, 16.1940242],
                [107.8758864, 16.1938952],
                [107.8759349, 16.1937658],
                [107.8759494, 16.1936985],
                [107.8760836, 16.1932529],
                [107.8761999, 16.1931341],
                [107.8762491, 16.1930677],
                [107.8763276, 16.1929232],
                [107.8763806, 16.1927526],
                [107.8763974, 16.1925754],
                [107.8768293, 16.191698],
                [107.8768641, 16.1915966],
                [107.8768802, 16.1914524],
                [107.8768641, 16.1911484],
                [107.8768503, 16.1910198],
                [107.8768587, 16.1908908],
                [107.8768723, 16.1908225],
                [107.8769181, 16.1906906],
                [107.8769499, 16.1906281],
                [107.8772557, 16.1902263],
                [107.8772691, 16.1896441],
                [107.8773496, 16.1894844],
                [107.8773683, 16.1891032],
                [107.8772951, 16.1889348],
                [107.8772489, 16.1888549],
                [107.8771355, 16.1887027],
                [107.8770684, 16.1886316],
                [107.8769177, 16.1885056],
                [107.8674066, 16.1829108],
                [107.8675353, 16.1825707],
                [107.8677453, 16.1816924],
                [107.8677689, 16.1816195],
                [107.8678359, 16.1814811],
                [107.8678787, 16.1814167],
                [107.8679768, 16.1813042],
                [107.8680934, 16.1812094],
                [107.8681576, 16.1811694],
                [107.868973, 16.1810458],
                [107.8700995, 16.1797887],
                [107.8704268, 16.1795981],
                [107.8705153, 16.1795365],
                [107.8705977, 16.1794676],
                [107.8706735, 16.179392],
                [107.8707992, 16.1792285],
                [107.8708506, 16.1791396],
                [107.871269, 16.1783256],
                [107.8717732, 16.1781864],
                [107.8718622, 16.1781494],
                [107.871947, 16.1781042],
                [107.8720266, 16.1780511],
                [107.8721005, 16.1779907],
                [107.8722015, 16.1778843],
                [107.8722453, 16.1778258],
                [107.8724107, 16.1774825],
                [107.8725672, 16.1771354],
                [107.8727983, 16.1765743],
                [107.8729051, 16.1762905],
                [107.8731734, 16.1758474],
                [107.8735113, 16.17458],
                [107.8741593, 16.1732934],
                [107.8745454, 16.1727333],
                [107.8746046, 16.1726344],
                [107.874702, 16.1724264],
                [107.8748114, 16.172082],
                [107.8748577, 16.1719673],
                [107.8749704, 16.1717464],
                [107.8751041, 16.1715454],
                [107.8752601, 16.17136],
                [107.8756373, 16.170862],
                [107.8758953, 16.1704955],
                [107.8760301, 16.1702596],
                [107.8761371, 16.1700109],
                [107.876215, 16.1697525],
                [107.8762459, 16.1696022],
                [107.876267, 16.1694504],
                [107.8762782, 16.1692977],
                [107.8762793, 16.1691445],
                [107.8760326, 16.1687272],
                [107.8759365, 16.1684875],
                [107.8758683, 16.1682392],
                [107.8758449, 16.1681128],
                [107.8758197, 16.1678526],
                [107.875825, 16.1675868],
                [107.8758395, 16.1674546],
                [107.8760326, 16.1665787],
                [107.8760433, 16.165641],
                [107.8757536, 16.1650227],
                [107.8756954, 16.1648488],
                [107.8756463, 16.1646723],
                [107.875582, 16.1643529],
                [107.8755717, 16.163765],
                [107.8755556, 16.1635587],
                [107.8755158, 16.1633509],
                [107.8754865, 16.1632469],
                [107.8754103, 16.1630442],
                [107.8750241, 16.1622198],
                [107.8749171, 16.1621],
                [107.8748748, 16.1620322],
                [107.8748152, 16.1618851],
                [107.874792, 16.1617411],
                [107.8747984, 16.1616077],
                [107.874831, 16.1614779],
                [107.874877, 16.16139],
                [107.874914, 16.1612983],
                [107.8749416, 16.1612037],
                [107.8749597, 16.1611069],
                [107.8749679, 16.1609408],
                [107.8749479, 16.1607756],
                [107.8749275, 16.1606947],
                [107.8746766, 16.1602832],
                [107.8745627, 16.1600712],
                [107.8744538, 16.159849],
                [107.8743535, 16.159623],
                [107.8738117, 16.1591283],
                [107.8737248, 16.1590025],
                [107.8736633, 16.1588635],
                [107.8736428, 16.1587906],
                [107.8736228, 16.1586335],
                [107.8736358, 16.1584684],
                [107.8737064, 16.1582146],
                [107.8737205, 16.1581184],
                [107.8737205, 16.1579278],
                [107.8731573, 16.1576444],
                [107.8730042, 16.1576187],
                [107.8728385, 16.1576138],
                [107.8726637, 16.1576341],
                [107.8721809, 16.1577681],
                [107.8717229, 16.1577739],
                [107.871285, 16.1578003],
                [107.8710761, 16.157783],
                [107.8709732, 16.1577636],
                [107.870872, 16.1577372],
                [107.8705602, 16.1576339],
                [107.870239, 16.157562],
                [107.8699272, 16.1575233],
                [107.8697702, 16.157515],
                [107.8694558, 16.1575208],
                [107.8683829, 16.1573404],
                [107.8682562, 16.1573305],
                [107.8680021, 16.1573353],
                [107.8678974, 16.1573469],
                [107.8676909, 16.1573868],
                [107.8671169, 16.1575929],
                [107.8664678, 16.1573919],
                [107.8656632, 16.1573765],
                [107.8653388, 16.157195],
                [107.8651804, 16.1570982],
                [107.8647834, 16.1568303],
                [107.8647135, 16.1567684],
                [107.8645903, 16.1566294],
                [107.8644944, 16.1564784],
                [107.8644559, 16.156398],
                [107.8643839, 16.1562091],
                [107.864279, 16.1560075],
                [107.8641444, 16.1558252],
                [107.8639841, 16.1556658],
                [107.8637964, 16.1556463],
                [107.8637047, 16.1556243],
                [107.8635296, 16.1555564],
                [107.8634477, 16.1555113],
                [107.8632955, 16.1553977],
                [107.8632284, 16.1553312],
                [107.8631151, 16.1551815],
                [107.8620422, 16.1552433],
                [107.8617086, 16.1552289],
                [107.8615425, 16.1552132],
                [107.8611839, 16.1551595],
                [107.8608027, 16.1550718],
                [107.8606152, 16.1550166],
                [107.8604782, 16.1549633],
                [107.8602149, 16.1548342],
                [107.8600895, 16.154759],
                [107.859879, 16.1546088],
                [107.8596856, 16.1544387],
                [107.859596, 16.1543468],
                [107.8586586, 16.153202],
                [107.858567, 16.1530415],
                [107.8585105, 16.1528709],
                [107.8584891, 16.1526972],
                [107.8584911, 16.1526097],
                [107.8585017, 16.1525227],
                [107.8586841, 16.1516983],
                [107.8602934, 16.147468],
                [107.859875, 16.1463292],
                [107.8595625, 16.1460042],
                [107.8592741, 16.1456593],
                [107.8591227, 16.1454568],
                [107.8589796, 16.1452489],
                [107.858845, 16.1450358],
                [107.8583971, 16.1433018],
                [107.8572518, 16.1387827],
                [107.8571901, 16.138422],
                [107.8571576, 16.1380909],
                [107.8571498, 16.1379247],
                [107.8572871, 16.137775],
                [107.8573644, 16.1377083],
                [107.8575432, 16.1375879],
                [107.8577399, 16.137497],
                [107.8584051, 16.1372703],
                [107.8587232, 16.1371193],
                [107.8590239, 16.1369385],
                [107.8591668, 16.1368374],
                [107.8594307, 16.13662],
                [107.8596711, 16.1363788],
                [107.8598857, 16.136116],
                [107.8601861, 16.1350648],
                [107.8598481, 16.1341372],
                [107.8597301, 16.1339878],
                [107.8596703, 16.1338957],
                [107.8595682, 16.1337021],
                [107.8595263, 16.1336013],
                [107.8594532, 16.1333594],
                [107.8594297, 16.1332354],
                [107.8587675, 16.132834],
                [107.8586702, 16.1327496],
                [107.8586304, 16.1326995],
                [107.8585698, 16.1325826],
                [107.8585419, 16.1324547],
                [107.8585499, 16.1320244],
                [107.8585718, 16.1318036],
                [107.858571, 16.1316926],
                [107.8585463, 16.131472],
                [107.8585224, 16.1313634],
                [107.8584525, 16.1311531],
                [107.8583545, 16.1309546],
                [107.8582299, 16.1307703],
                [107.8580744, 16.1305811],
                [107.8579979, 16.1304724],
                [107.8578687, 16.1302414],
                [107.8577869, 16.130039],
                [107.8577292, 16.1298292],
                [107.8576112, 16.1288913],
                [107.8559375, 16.1277163],
                [107.8558978, 16.1273216],
                [107.8558892, 16.1271234],
                [107.8558983, 16.1265431],
                [107.8558731, 16.126361],
                [107.8558462, 16.1262602],
                [107.8557658, 16.1260672],
                [107.8555971, 16.1258352],
                [107.8555023, 16.1257268],
                [107.8552869, 16.1255216],
                [107.8551663, 16.1254262],
                [107.8550396, 16.1253383],
                [107.8549075, 16.1252582],
                [107.8548896, 16.1247672],
                [107.854852, 16.1245092],
                [107.8548217, 16.1243821],
                [107.8547489, 16.1241602],
                [107.8547037, 16.1240523],
                [107.854473, 16.12364],
                [107.8543763, 16.1234416],
                [107.854338, 16.1233383],
                [107.8542828, 16.1231274],
                [107.8542559, 16.1229131],
                [107.8542523, 16.1227032],
                [107.8542613, 16.1226015],
                [107.8543023, 16.1224201],
                [107.8543657, 16.122264],
                [107.8546017, 16.1219291],
                [107.8547275, 16.1217825],
                [107.8547967, 16.1217146],
                [107.8549668, 16.1215768],
                [107.8553116, 16.1213652],
                [107.8554365, 16.1212584],
                [107.8554938, 16.1211964],
                [107.8555459, 16.1211303],
                [107.8558838, 16.1203882],
                [107.8558892, 16.1194657],
                [107.8560142, 16.1189788],
                [107.8560375, 16.1188619],
                [107.8560693, 16.1186123],
                [107.8560764, 16.1184799],
                [107.856077, 16.1183474],
                [107.8559375, 16.1174455],
                [107.8560179, 16.1166776],
                [107.8560877, 16.1144203],
                [107.8560676, 16.1143048],
                [107.8560207, 16.1142093],
                [107.8559586, 16.1141366],
                [107.8559214, 16.114106],
                [107.8554064, 16.1137864],
                [107.8552884, 16.1135494],
                [107.8551732, 16.1133867],
                [107.855106, 16.1133123],
                [107.8549538, 16.1131792],
                [107.8546983, 16.1130166],
                [107.8546178, 16.1129567],
                [107.8544473, 16.1128008],
                [107.8542523, 16.112586],
                [107.8541272, 16.1124633],
                [107.853859, 16.1122361],
                [107.8537166, 16.1121321],
                [107.8534655, 16.1119725],
                [107.8532016, 16.1118332],
                [107.8529495, 16.1118023],
                [107.8523907, 16.1114334],
                [107.8521302, 16.1112915],
                [107.8518638, 16.1111785],
                [107.8515953, 16.1110934],
                [107.8514582, 16.1110601],
                [107.851029, 16.1108798],
                [107.8509565, 16.1107134],
                [107.8508574, 16.1105602],
                [107.8507249, 16.1104162],
                [107.8506491, 16.1103534],
                [107.8504805, 16.1102482],
                [107.8503891, 16.1102069],
                [107.8502011, 16.1101388],
                [107.8500237, 16.1100509],
                [107.8498583, 16.1099389],
                [107.8497825, 16.1098725],
                [107.8496512, 16.1097213],
                [107.8495532, 16.1095556],
                [107.8494886, 16.1093824],
                [107.8494402, 16.1091892],
                [107.8494061, 16.1090877],
                [107.8493171, 16.1088855],
                [107.8492616, 16.1087857],
                [107.8491998, 16.1086894],
                [107.8487706, 16.1081534],
                [107.848625, 16.1079462],
                [107.848455, 16.1077569],
                [107.848263, 16.1075881],
                [107.8481596, 16.1075122],
                [107.8479351, 16.1073759],
                [107.8476901, 16.1072646],
                [107.8475628, 16.10722],
                [107.8473008, 16.1071536],
                [107.8462708, 16.1061228],
                [107.8457558, 16.105762],
                [107.8456619, 16.104873],
                [107.8456518, 16.1048271],
                [107.8456153, 16.1047402],
                [107.8455895, 16.1047003],
                [107.8455154, 16.1046229],
                [107.8454712, 16.1045917],
                [107.8454232, 16.1045663],
                [107.8439641, 16.1043705],
                [107.842977, 16.1037005],
                [107.8416467, 16.1036592],
                [107.8411102, 16.1031026],
                [107.8409211, 16.1029333],
                [107.8408205, 16.1028552],
                [107.8406891, 16.1027638],
                [107.8405523, 16.10268],
                [107.8402987, 16.1025623],
                [107.8401768, 16.1024944],
                [107.8399362, 16.1023354],
                [107.8398228, 16.1022471],
                [107.8396004, 16.102114],
                [107.8393632, 16.102007],
                [107.8391147, 16.1019275],
                [107.838859, 16.1018768],
                [107.8387292, 16.1018623],
                [107.8384683, 16.1018554],
                [107.8382319, 16.101522],
                [107.8381469, 16.1014455],
                [107.8380981, 16.1014147],
                [107.8379945, 16.101371],
                [107.8379396, 16.1013577],
                [107.8372908, 16.1012781],
                [107.8371829, 16.1011864],
                [107.8369842, 16.1009864],
                [107.8368939, 16.1008786],
                [107.8367329, 16.1006493],
                [107.8366121, 16.1004302],
                [107.8365604, 16.1003165],
                [107.8364754, 16.1000824],
                [107.8355956, 16.0991134],
                [107.8354545, 16.0989749],
                [107.8352943, 16.0988573],
                [107.8351182, 16.0987629],
                [107.8350104, 16.0987198],
                [107.8348993, 16.0986853],
                [107.8347856, 16.0986599],
                [107.8345205, 16.0985007],
                [107.834394, 16.0984125],
                [107.8341456, 16.0982119],
                [107.8340292, 16.0981032],
                [107.8329992, 16.0968559],
                [107.832884, 16.0966919],
                [107.8327768, 16.096523],
                [107.8326779, 16.0963494],
                [107.8325874, 16.0961717],
                [107.8325057, 16.0959901],
                [107.8324332, 16.0958061],
                [107.8323696, 16.095619],
                [107.832315, 16.0954294],
                [107.8322697, 16.0952375],
                [107.8321495, 16.0941787],
                [107.8321468, 16.0939154],
                [107.832157, 16.0937841],
                [107.8321996, 16.0935278],
                [107.8322714, 16.0932777],
                [107.8323689, 16.0930187],
                [107.8292093, 16.0918229],
                [107.8283188, 16.090957],
                [107.827101, 16.0900859],
                [107.8270385, 16.0900478],
                [107.8269294, 16.0899519],
                [107.826847, 16.0898357],
                [107.8268167, 16.0897715],
                [107.8267847, 16.0893641],
                [107.8267551, 16.0892116],
                [107.8267309, 16.0891375],
                [107.8266614, 16.0889909],
                [107.8266173, 16.0889223],
                [107.8265123, 16.0887967],
                [107.8264519, 16.0887406],
                [107.826409, 16.088153],
                [107.825776, 16.0876067],
                [107.8256902, 16.0869675],
                [107.8255722, 16.0868129],
                [107.8255883, 16.0848517],
                [107.8252315, 16.0847254],
                [107.823177, 16.0837254],
                [107.8229355, 16.0834596],
                [107.8228068, 16.0833337],
                [107.8225847, 16.0831378],
                [107.8224689, 16.083045],
                [107.8224952, 16.0827905],
                [107.8224957, 16.0825347],
                [107.8224659, 16.0822515],
                [107.8224045, 16.0819729],
                [107.8223991, 16.0810193],
                [107.8222817, 16.0807157],
                [107.8221891, 16.0804042],
                [107.8221175, 16.0800596],
                [107.8220916, 16.0798717],
                [107.8220665, 16.0794936],
                [107.8217298, 16.0783057],
                [107.8217009, 16.078183],
                [107.8216653, 16.0779337],
                [107.8216588, 16.077808],
                [107.8216694, 16.0775496],
                [107.8217117, 16.0772943],
                [107.8217447, 16.0771689],
                [107.822088, 16.0764163],
                [107.8227693, 16.0757204],
                [107.8230817, 16.0751292],
                [107.823227, 16.0748285],
                [107.8234974, 16.0742116],
                [107.8236222, 16.0738957],
                [107.8244698, 16.0730245],
                [107.8247005, 16.0714369],
                [107.8249633, 16.0703286],
                [107.8249543, 16.0702657],
                [107.8249204, 16.070143],
                [107.8248912, 16.0700745],
                [107.8248131, 16.0699471],
                [107.8236973, 16.0687718],
                [107.8221577, 16.0675862],
                [107.8216481, 16.0667511],
                [107.8199422, 16.0649572],
                [107.8201648, 16.0640706],
                [107.820197, 16.0622045],
                [107.820049, 16.0620395],
                [107.8199844, 16.0619499],
                [107.8198758, 16.0617587],
                [107.8198323, 16.061658],
                [107.8197677, 16.061448],
                [107.8197472, 16.0613403],
                [107.8197303, 16.0611219],
                [107.8186628, 16.059462],
                [107.8186407, 16.0592822],
                [107.8186414, 16.0591011],
                [107.8186714, 16.0588892],
                [107.8187326, 16.0586836],
                [107.8185394, 16.0582918],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [107.48422, 12.9479862],
                [107.4842263, 12.9479156],
                [107.4842638, 12.9477789],
                [107.4843821, 12.9475333],
                [107.4844769, 12.9473554],
                [107.484691, 12.947007],
                [107.4849365, 12.9466722],
                [107.4852093, 12.946358],
                [107.48539, 12.9461241],
                [107.4854271, 12.9460338],
                [107.4854372, 12.9459371],
                [107.4854156, 12.9458303],
                [107.4853923, 12.9457806],
                [107.4853234, 12.9456948],
                [107.4852307, 12.9456339],
                [107.4851159, 12.9455881],
                [107.485005, 12.9455338],
                [107.4848988, 12.9454714],
                [107.4847977, 12.9454012],
                [107.4847026, 12.9453237],
                [107.4846138, 12.9452392],
                [107.4844693, 12.9450679],
                [107.4843509, 12.9448785],
                [107.4843196, 12.9447952],
                [107.4842819, 12.9446219],
                [107.4842776, 12.9444552],
                [107.4843012, 12.9443005],
                [107.4845607, 12.9434546],
                [107.4846521, 12.9432404],
                [107.4847763, 12.9430425],
                [107.4849303, 12.9428656],
                [107.4851049, 12.942718],
                [107.4853002, 12.9425976],
                [107.4854043, 12.9425484],
                [107.485628, 12.9424617],
                [107.485747, 12.9424243],
                [107.4859915, 12.9423739],
                [107.4862478, 12.9423567],
                [107.4863798, 12.9423616],
                [107.4865111, 12.9423758],
                [107.4866409, 12.9423993],
                [107.4867687, 12.9424319],
                [107.4870513, 12.9425231],
                [107.4872155, 12.9425441],
                [107.4873809, 12.9425357],
                [107.4875516, 12.942495],
                [107.4876378, 12.9424607],
                [107.4877199, 12.9424181],
                [107.4877971, 12.9423674],
                [107.4878686, 12.9423094],
                [107.4879336, 12.9422445],
                [107.4879915, 12.9421735],
                [107.4880417, 12.9420971],
                [107.4884047, 12.9414508],
                [107.4884755, 12.9413425],
                [107.4885543, 12.9412395],
                [107.4886405, 12.9411423],
                [107.4887337, 12.9410515],
                [107.4889121, 12.9409093],
                [107.4891075, 12.9407903],
                [107.4892107, 12.9407401],
                [107.4895448, 12.9406137],
                [107.4896611, 12.9405615],
                [107.4898837, 12.9404383],
                [107.4900907, 12.9402915],
                [107.4902874, 12.9401146],
                [107.4904683, 12.9399074],
                [107.4905487, 12.939796],
                [107.4906874, 12.9395598],
                [107.4909089, 12.939083],
                [107.4910432, 12.9389066],
                [107.4911208, 12.9388269],
                [107.4912691, 12.938705],
                [107.4914334, 12.9386045],
                [107.4921347, 12.9382701],
                [107.4922537, 12.9381946],
                [107.4924767, 12.9380235],
                [107.492677, 12.9378275],
                [107.4928528, 12.937608],
                [107.4930011, 12.9373676],
                [107.4931183, 12.9371115],
                [107.4931647, 12.9369787],
                [107.4932017, 12.9367499],
                [107.4932106, 12.9366344],
                [107.4932081, 12.9363925],
                [107.4931956, 12.9362668],
                [107.4931164, 12.9357448],
                [107.4931057, 12.9356448],
                [107.4931043, 12.9355442],
                [107.4931124, 12.935444],
                [107.4931298, 12.9353449],
                [107.4931854, 12.9351689],
                [107.4932702, 12.9350044],
                [107.4933229, 12.9349279],
                [107.4940149, 12.9341311],
                [107.4940733, 12.9340262],
                [107.4940903, 12.9339687],
                [107.4940986, 12.9339094],
                [107.4940895, 12.9337939],
                [107.4940496, 12.9336885],
                [107.4940191, 12.9336407],
                [107.4939396, 12.9335594],
                [107.4937838, 12.9334712],
                [107.4936836, 12.9333908],
                [107.4936062, 12.9332892],
                [107.4935776, 12.9332323],
                [107.4935422, 12.9331083],
                [107.4935394, 12.9329775],
                [107.4935701, 12.93285],
                [107.4935975, 12.9327901],
                [107.4936743, 12.9326827],
                [107.4937762, 12.9325974],
                [107.4938705, 12.9324948],
                [107.4939445, 12.9323786],
                [107.4939962, 12.9322523],
                [107.4940715, 12.9321288],
                [107.4941732, 12.9320249],
                [107.4942336, 12.9319811],
                [107.4943678, 12.9319151],
                [107.4944398, 12.9318938],
                [107.4945968, 12.9318668],
                [107.4947575, 12.9318181],
                [107.4948338, 12.9317835],
                [107.4949797, 12.9316924],
                [107.4951096, 12.9315734],
                [107.4951654, 12.9315055],
                [107.495348, 12.9312119],
                [107.4954034, 12.9311444],
                [107.4955302, 12.9310236],
                [107.4956006, 12.9309711],
                [107.4958844, 12.9307897],
                [107.4959369, 12.9307459],
                [107.4960228, 12.9306409],
                [107.4960549, 12.9305813],
                [107.4960946, 12.9304527],
                [107.4961017, 12.9303858],
                [107.4960998, 12.9303164],
                [107.4960887, 12.9302479],
                [107.4960685, 12.9301813],
                [107.4960395, 12.9301179],
                [107.4960023, 12.9300588],
                [107.4959575, 12.9300048],
                [107.4959059, 12.929957],
                [107.4958218, 12.9298898],
                [107.4957318, 12.9298301],
                [107.4956369, 12.9297784],
                [107.4955376, 12.929735],
                [107.4954348, 12.9297004],
                [107.4950368, 12.9296168],
                [107.4948934, 12.9295763],
                [107.4946216, 12.929476],
                [107.4943689, 12.9293521],
                [107.4942478, 12.9292808],
                [107.4940176, 12.9291205],
                [107.4937366, 12.9288446],
                [107.4936039, 12.9286994],
                [107.4933554, 12.9283953],
                [107.4931242, 12.9280652],
                [107.4929138, 12.9277094],
                [107.492732, 12.9273389],
                [107.49258, 12.9269559],
                [107.4924713, 12.9264905],
                [107.492395, 12.9260043],
                [107.49237, 12.9257522],
                [107.4923507, 12.9253728],
                [107.4923535, 12.9250201],
                [107.4923664, 12.9247944],
                [107.492418, 12.924345],
                [107.4924566, 12.9241221],
                [107.4925034, 12.9240016],
                [107.4925786, 12.9238957],
                [107.4926742, 12.923813],
                [107.4927828, 12.923756],
                [107.4929019, 12.9237242],
                [107.4930252, 12.9237195],
                [107.494377, 12.9237822],
                [107.494517, 12.9237748],
                [107.4947941, 12.9237339],
                [107.4950286, 12.9236705],
                [107.4951963, 12.9236066],
                [107.4952905, 12.9235399],
                [107.4953292, 12.9234973],
                [107.4953833, 12.9234043],
                [107.4954097, 12.9233006],
                [107.4954064, 12.9231938],
                [107.4951475, 12.9222989],
                [107.4950691, 12.9220673],
                [107.4948886, 12.9215894],
                [107.4948724, 12.9214657],
                [107.4948859, 12.9213474],
                [107.4949243, 12.9212405],
                [107.4950983, 12.9209783],
                [107.4952025, 12.9208072],
                [107.4952984, 12.9206315],
                [107.4953856, 12.9204516],
                [107.4957718, 12.9197379],
                [107.4960923, 12.9192778],
                [107.4961887, 12.9191723],
                [107.4963981, 12.9189772],
                [107.4966091, 12.9188176],
                [107.4971354, 12.918488],
                [107.4974227, 12.9182791],
                [107.4975748, 12.9181797],
                [107.4978894, 12.9179968],
                [107.49807, 12.9179044],
                [107.4987162, 12.917604],
                [107.4990083, 12.917453],
                [107.4992857, 12.9172776],
                [107.4995527, 12.9170833],
                [107.4998367, 12.9169092],
                [107.5001227, 12.9167719],
                [107.5002706, 12.916714],
                [107.5005982, 12.9166121],
                [107.5007771, 12.9165685],
                [107.5009578, 12.9165328],
                [107.501148, 12.9165121],
                [107.5015398, 12.9164844],
                [107.501741, 12.9164779],
                [107.5018641, 12.9164545],
                [107.5019228, 12.9164328],
                [107.5020308, 12.9163709],
                [107.5020788, 12.9163315],
                [107.5021616, 12.9162351],
                [107.5022207, 12.9161194],
                [107.5022499, 12.9159934],
                [107.5022478, 12.9158642],
                [107.5022143, 12.9157392],
                [107.502124, 12.9155646],
                [107.5019771, 12.9153459],
                [107.5016929, 12.9149836],
                [107.5015587, 12.9147968],
                [107.5013474, 12.9144785],
                [107.5010642, 12.9140088],
                [107.5007867, 12.9135041],
                [107.5006559, 12.9132477],
                [107.499985, 12.9117699],
                [107.4998369, 12.9115084],
                [107.4997208, 12.9113323],
                [107.4996534, 12.9112508],
                [107.4995016, 12.9111027],
                [107.4993297, 12.9109772],
                [107.4992368, 12.9109234],
                [107.4990402, 12.910836],
                [107.4989376, 12.9108028],
                [107.4987265, 12.9107582],
                [107.4980678, 12.9106787],
                [107.4978331, 12.9106687],
                [107.4976812, 12.9106746],
                [107.4975299, 12.9106901],
                [107.4973801, 12.9107152],
                [107.4972323, 12.9107498],
                [107.4967709, 12.9108727],
                [107.4965721, 12.9108937],
                [107.4963727, 12.9108797],
                [107.4962747, 12.9108596],
                [107.4960985, 12.9107995],
                [107.4959352, 12.9107115],
                [107.4957892, 12.9105981],
                [107.4953483, 12.9101562],
                [107.4953016, 12.9100968],
                [107.4952283, 12.9099713],
                [107.4951585, 12.9097686],
                [107.4950945, 12.9096334],
                [107.4950086, 12.909516],
                [107.4947941, 12.9092962],
                [107.4947291, 12.9091829],
                [107.4946876, 12.9090595],
                [107.4946708, 12.9089184],
                [107.4946873, 12.9087648],
                [107.4947391, 12.9086189],
                [107.4947775, 12.9085512],
                [107.4954367, 12.9076227],
                [107.4956757, 12.9072302],
                [107.495775, 12.9070468],
                [107.4960583, 12.9064673],
                [107.4962858, 12.9060707],
                [107.4965416, 12.9056909],
                [107.4971062, 12.9049614],
                [107.497194, 12.9048188],
                [107.4972562, 12.904664],
                [107.4972772, 12.9045834],
                [107.4972983, 12.9044174],
                [107.497298, 12.9043332],
                [107.4972757, 12.9041664],
                [107.4972538, 12.904085],
                [107.4971894, 12.903929],
                [107.4970686, 12.9037065],
                [107.496896, 12.9034545],
                [107.4966956, 12.9032229],
                [107.4959421, 12.9024777],
                [107.4957548, 12.9022768],
                [107.4955747, 12.9020698],
                [107.4954353, 12.9019402],
                [107.4952767, 12.9018241],
                [107.4951899, 12.9017718],
                [107.4947566, 12.9015648],
                [107.494424, 12.9013848],
                [107.4936414, 12.9009006],
                [107.4931403, 12.9006095],
                [107.4929446, 12.9004744],
                [107.4927664, 12.9003181],
                [107.4925922, 12.9001226],
                [107.4925152, 12.9000171],
                [107.4923828, 12.8997926],
                [107.4922472, 12.8995023],
                [107.4920722, 12.8991958],
                [107.4919659, 12.899035],
                [107.4916748, 12.8986543],
                [107.4916237, 12.8985766],
                [107.4915379, 12.8984121],
                [107.4915, 12.8983159],
                [107.4914509, 12.89814],
                [107.4913747, 12.8977832],
                [107.4913284, 12.8974214],
                [107.4913166, 12.8972395],
                [107.4913158, 12.8968758],
                [107.4913267, 12.8966943],
                [107.4913452, 12.8965133],
                [107.4914141, 12.8960284],
                [107.4915165, 12.8955492],
                [107.4916351, 12.8951296],
                [107.491704, 12.8949226],
                [107.4918104, 12.8946505],
                [107.4918839, 12.8945217],
                [107.4920033, 12.8943654],
                [107.4921729, 12.8941974],
                [107.4923892, 12.8940189],
                [107.4926226, 12.8938621],
                [107.4927449, 12.8937922],
                [107.4928659, 12.8937372],
                [107.4931157, 12.8936456],
                [107.4932438, 12.8936092],
                [107.4935417, 12.8935503],
                [107.4938446, 12.8935255],
                [107.4959421, 12.8935307],
                [107.4960376, 12.8935185],
                [107.4961313, 12.8934971],
                [107.4962224, 12.8934666],
                [107.4963099, 12.8934273],
                [107.4963928, 12.8933797],
                [107.4964705, 12.8933242],
                [107.4965998, 12.8932001],
                [107.4966549, 12.8931297],
                [107.4967434, 12.8929753],
                [107.4968004, 12.8928074],
                [107.4968165, 12.8927202],
                [107.4969171, 12.8918009],
                [107.4969721, 12.8914704],
                [107.497015, 12.8912717],
                [107.4970652, 12.8910746],
                [107.4971867, 12.8906861],
                [107.4975085, 12.889567],
                [107.4975751, 12.8892298],
                [107.4975997, 12.8890598],
                [107.4976335, 12.8886822],
                [107.4976389, 12.8883033],
                [107.4976309, 12.888114],
                [107.4975427, 12.8869386],
                [107.4975407, 12.8866177],
                [107.4975628, 12.8862583],
                [107.4976104, 12.8859013],
                [107.4979806, 12.8838305],
                [107.4981004, 12.8829386],
                [107.4982042, 12.8819867],
                [107.4982493, 12.8814809],
                [107.4983547, 12.8800136],
                [107.49845, 12.8791068],
                [107.498576, 12.8782036],
                [107.4986565, 12.8777696],
                [107.4986791, 12.8776835],
                [107.4987487, 12.8775194],
                [107.4988485, 12.8773709],
                [107.4989767, 12.8772417],
                [107.4991296, 12.8771374],
                [107.4992131, 12.8770965],
                [107.4994214, 12.8770227],
                [107.4995391, 12.8769732],
                [107.4996527, 12.8769152],
                [107.4998529, 12.8767846],
                [107.5000216, 12.8766388],
                [107.5002684, 12.8763642],
                [107.5004839, 12.8761637],
                [107.5005996, 12.8760722],
                [107.5009828, 12.8758144],
                [107.5011162, 12.8757151],
                [107.5013689, 12.8755002],
                [107.5016016, 12.8752646],
                [107.5018171, 12.8750039],
                [107.5019157, 12.8748667],
                [107.5020936, 12.8745803],
                [107.5022446, 12.8742796],
                [107.5023097, 12.8741246],
                [107.5032109, 12.8723674],
                [107.5034623, 12.872003],
                [107.5037409, 12.8716578],
                [107.5038899, 12.8714931],
                [107.5042064, 12.8711804],
                [107.5045459, 12.8708916],
                [107.5047237, 12.8707567],
                [107.5050928, 12.8705076],
                [107.5054799, 12.8702859],
                [107.5056795, 12.8701857],
                [107.5060895, 12.8700074],
                [107.5062993, 12.8699295],
                [107.5067271, 12.8697968],
                [107.5069445, 12.8697422],
                [107.5073864, 12.8696782],
                [107.5078317, 12.8696437],
                [107.508055, 12.8696376],
                [107.5085391, 12.8696498],
                [107.509021, 12.869697],
                [107.5103169, 12.8699116],
                [107.5105186, 12.8699101],
                [107.5106185, 12.8698959],
                [107.5108123, 12.8698415],
                [107.5109068, 12.8698006],
                [107.510997, 12.8697514],
                [107.5110822, 12.8696941],
                [107.5111615, 12.8696294],
                [107.5112343, 12.8695578],
                [107.5113, 12.86948],
                [107.511358, 12.8693965],
                [107.5114077, 12.8693081],
                [107.5116415, 12.8688187],
                [107.5118719, 12.8682806],
                [107.5120934, 12.8676928],
                [107.5122875, 12.8670959],
                [107.5123901, 12.8668118],
                [107.512515, 12.866527],
                [107.5127607, 12.8660487],
                [107.5128506, 12.8658503],
                [107.5130065, 12.8654442],
                [107.5131297, 12.8650276],
                [107.5132166, 12.8646197],
                [107.5132721, 12.8642067],
                [107.513296, 12.8637907],
                [107.5132749, 12.863458],
                [107.5132275, 12.8631279],
                [107.5131472, 12.8627763],
                [107.5130277, 12.8624072],
                [107.5128749, 12.8620499],
                [107.5121922, 12.8607079],
                [107.5120461, 12.8603914],
                [107.5119093, 12.8600197],
                [107.5117594, 12.859493],
                [107.5116754, 12.8591535],
                [107.5116008, 12.8588119],
                [107.5114846, 12.8581508],
                [107.5114399, 12.8578183],
                [107.5114331, 12.8577092],
                [107.5114357, 12.8576001],
                [107.5114477, 12.8574915],
                [107.5114691, 12.8573843],
                [107.5114996, 12.8572792],
                [107.5115391, 12.857177],
                [107.5115873, 12.8570784],
                [107.5117061, 12.8568974],
                [107.5118513, 12.8567404],
                [107.5119333, 12.8566711],
                [107.5121132, 12.8565531],
                [107.5123104, 12.8564651],
                [107.5125197, 12.8564096],
                [107.5162117, 12.8559713],
                [107.5167179, 12.8558837],
                [107.5169686, 12.8558284],
                [107.5174642, 12.8556949],
                [107.5179804, 12.8555202],
                [107.5182338, 12.8554204],
                [107.5187296, 12.8551964],
                [107.5189716, 12.8550725],
                [107.5193325, 12.8549159],
                [107.5197046, 12.8547867],
                [107.5200332, 12.8546963],
                [107.5201693, 12.8546509],
                [107.520302, 12.8545967],
                [107.5204307, 12.8545339],
                [107.5206547, 12.8543969],
                [107.5208596, 12.8542339],
                [107.5210422, 12.8540475],
                [107.5217289, 12.8531636],
                [107.5219031, 12.8529058],
                [107.5219823, 12.852772],
                [107.5221235, 12.8524976],
                [107.5222416, 12.852215],
                [107.5223769, 12.8518493],
                [107.5224705, 12.8516315],
                [107.5225729, 12.8514175],
                [107.5226837, 12.8512076],
                [107.5227999, 12.8510071],
                [107.5230554, 12.85062],
                [107.5231944, 12.8504339],
                [107.5234938, 12.8500779],
                [107.5237975, 12.8497848],
                [107.5241214, 12.849513],
                [107.5244325, 12.849285],
                [107.5247922, 12.8490462],
                [107.5249711, 12.8488895],
                [107.5251272, 12.848711],
                [107.5251957, 12.8486146],
                [107.5253123, 12.8484095],
                [107.5253611, 12.8482987],
                [107.5254347, 12.8480687],
                [107.5254593, 12.8479505],
                [107.5254834, 12.8477107],
                [107.5254736, 12.8474699],
                [107.5254024, 12.8470646],
                [107.5253713, 12.8467254],
                [107.5253731, 12.8463912],
                [107.525386, 12.8462245],
                [107.5254357, 12.8458938],
                [107.5255737, 12.8452359],
                [107.5256922, 12.8445523],
                [107.5257888, 12.8438435],
                [107.5258273, 12.8434879],
                [107.5258483, 12.8433791],
                [107.5259104, 12.8431662],
                [107.5260101, 12.8429395],
                [107.5260714, 12.8428315],
                [107.5265444, 12.8420764],
                [107.5266066, 12.8419575],
                [107.5266602, 12.8418345],
                [107.5267047, 12.8417083],
                [107.5267401, 12.8415792],
                [107.5267661, 12.8414481],
                [107.5267827, 12.8413136],
                [107.5267894, 12.8411783],
                [107.5267863, 12.8410429],
                [107.5267732, 12.840908],
                [107.5267011, 12.8405532],
                [107.5266938, 12.8404626],
                [107.526696, 12.8403717],
                [107.5267076, 12.8402815],
                [107.5267285, 12.8401928],
                [107.5267586, 12.8401066],
                [107.5267975, 12.8400237],
                [107.5268448, 12.8399452],
                [107.5269001, 12.8398717],
                [107.5269628, 12.8398041],
                [107.5270321, 12.839743],
                [107.5271075, 12.8396891],
                [107.5271881, 12.839643],
                [107.527273, 12.839605],
                [107.5273615, 12.8395757],
                [107.5276853, 12.8394908],
                [107.528016, 12.8394371],
                [107.5281966, 12.8394212],
                [107.5283779, 12.8394145],
                [107.5285592, 12.8394172],
                [107.5289957, 12.8394508],
                [107.5295083, 12.8394691],
                [107.5297648, 12.8394658],
                [107.5302217, 12.8394395],
                [107.5304493, 12.8394164],
                [107.5310756, 12.8393312],
                [107.5314246, 12.8393129],
                [107.5315994, 12.8393142],
                [107.5319579, 12.8392736],
                [107.532311, 12.8392005],
                [107.5326505, 12.8390974],
                [107.5329741, 12.8389664],
                [107.5332842, 12.8388074],
                [107.5335781, 12.8386216],
                [107.5338867, 12.8384062],
                [107.5342377, 12.8382022],
                [107.5344197, 12.8381116],
                [107.5347949, 12.837954],
                [107.5349875, 12.8378873],
                [107.5351828, 12.8378288],
                [107.5354357, 12.8377648],
                [107.5355589, 12.8377221],
                [107.5357965, 12.837616],
                [107.5359101, 12.837553],
                [107.536124, 12.8374088],
                [107.5363184, 12.8372421],
                [107.5364917, 12.8370547],
                [107.5367439, 12.836699],
                [107.5368104, 12.8366285],
                [107.5369587, 12.8365031],
                [107.5371247, 12.8364009],
                [107.5373087, 12.8363228],
                [107.5374048, 12.8362944],
                [107.5378261, 12.8362109],
                [107.5382684, 12.8360923],
                [107.5387019, 12.8359458],
                [107.5391533, 12.8357594],
                [107.5395903, 12.8355429],
                [107.5401824, 12.8352033],
                [107.5403577, 12.835116],
                [107.5407475, 12.834941],
                [107.5409412, 12.8348159],
                [107.5411114, 12.8346649],
                [107.5412549, 12.8344923],
                [107.5413162, 12.8343985],
                [107.5414163, 12.8341989],
                [107.5415156, 12.8340451],
                [107.5416416, 12.8339112],
                [107.5421834, 12.8335085],
                [107.5422619, 12.833434],
                [107.5424019, 12.83327],
                [107.5424629, 12.8331813],
                [107.542565, 12.8329926],
                [107.5426058, 12.8328935],
                [107.5426681, 12.8326764],
                [107.5426876, 12.8325591],
                [107.5426977, 12.8324407],
                [107.5426984, 12.8323218],
                [107.5426897, 12.8322033],
                [107.5426322, 12.8318563],
                [107.5425287, 12.831402],
                [107.5424647, 12.8311778],
                [107.542335, 12.8307959],
                [107.5422448, 12.8304683],
                [107.5421887, 12.8301335],
                [107.5421673, 12.8297949],
                [107.5421792, 12.829474],
                [107.5422222, 12.8291558],
                [107.542296, 12.8288429],
                [107.5425201, 12.8282006],
                [107.5426412, 12.8278825],
                [107.5428524, 12.8273657],
                [107.5430403, 12.8269722],
                [107.5431437, 12.82678],
                [107.5433689, 12.8264055],
                [107.5435009, 12.8262087],
                [107.543785, 12.8258288],
                [107.5439369, 12.8256461],
                [107.5442594, 12.8252966],
                [107.5452894, 12.8245329],
                [107.5454028, 12.8244252],
                [107.5454512, 12.824364],
                [107.5455289, 12.8242295],
                [107.5455791, 12.8240831],
                [107.5455934, 12.8240062],
                [107.5455989, 12.8238502],
                [107.5455901, 12.8237725],
                [107.5454986, 12.8234345],
                [107.5454258, 12.8232162],
                [107.5453787, 12.8230243],
                [107.545337, 12.8227799],
                [107.5453177, 12.8226159],
                [107.5453019, 12.822363],
                [107.545322, 12.8221878],
                [107.5453776, 12.8220201],
                [107.545463, 12.8218716],
                [107.5455717, 12.8217463],
                [107.5457025, 12.8216429],
                [107.5457973, 12.8216061],
                [107.5459948, 12.8215567],
                [107.546099, 12.8215443],
                [107.5463087, 12.8215462],
                [107.5464126, 12.8215607],
                [107.5466145, 12.8216158],
                [107.5469684, 12.8217678],
                [107.5472229, 12.8218858],
                [107.5474742, 12.8220101],
                [107.5479668, 12.8222771],
                [107.5484444, 12.8225681],
                [107.5486771, 12.8227222],
                [107.5491303, 12.8230474],
                [107.5493586, 12.8231861],
                [107.549601, 12.8232998],
                [107.5497265, 12.823347],
                [107.5499763, 12.8234191],
                [107.5500999, 12.8234429],
                [107.5503507, 12.8234664],
                [107.550564, 12.8234608],
                [107.5506502, 12.8234468],
                [107.5508157, 12.8233928],
                [107.550966, 12.8233064],
                [107.5510945, 12.8231914],
                [107.5511488, 12.8231246],
                [107.5511956, 12.8230527],
                [107.551235, 12.8229751],
                [107.5512656, 12.8228938],
                [107.5512873, 12.8228099],
                [107.5512996, 12.8227241],
                [107.5513024, 12.8226376],
                [107.5512958, 12.8225513],
                [107.5512798, 12.8224661],
                [107.5507956, 12.8206438],
                [107.5507557, 12.8204216],
                [107.5507426, 12.8202859],
                [107.5507389, 12.8201495],
                [107.5507448, 12.8200132],
                [107.5507602, 12.8198777],
                [107.550785, 12.8197435],
                [107.5508192, 12.8196112],
                [107.5508626, 12.8194815],
                [107.5509149, 12.8193551],
                [107.5509759, 12.8192323],
                [107.5511192, 12.8190057],
                [107.5512877, 12.8188042],
                [107.5514815, 12.8186258],
                [107.5515869, 12.8185462],
                [107.5518125, 12.8184078],
                [107.5522594, 12.8181977],
                [107.5524878, 12.818103],
                [107.5529704, 12.8179295],
                [107.5534813, 12.8177828],
                [107.5537403, 12.8177223],
                [107.5541581, 12.8176392],
                [107.554304, 12.8175748],
                [107.5544316, 12.8174805],
                [107.5545345, 12.8173608],
                [107.5546074, 12.8172218],
                [107.5546315, 12.8171472],
                [107.5546528, 12.8170072],
                [107.5546447, 12.816866],
                [107.5545922, 12.816678],
                [107.554495, 12.8164395],
                [107.5544348, 12.8163207],
                [107.554295, 12.8160936],
                [107.553135, 12.8144631],
                [107.5529391, 12.8141604],
                [107.5528581, 12.8140232],
                [107.5527857, 12.8138816],
                [107.5527219, 12.813736],
                [107.5526671, 12.813587],
                [107.5526215, 12.8134351],
                [107.5525851, 12.8132808],
                [107.5525582, 12.8131247],
                [107.5525406, 12.812965],
                [107.5525328, 12.8128045],
                [107.552535, 12.8126439],
                [107.552547, 12.8124836],
                [107.5527996, 12.8108911],
                [107.5528653, 12.8105809],
                [107.5529029, 12.8102662],
                [107.5529122, 12.8099496],
                [107.5528901, 12.8096054],
                [107.5528344, 12.8092649],
                [107.5527942, 12.809097],
                [107.552376, 12.8077218],
                [107.5523159, 12.8074241],
                [107.5522904, 12.8071217],
                [107.5522997, 12.8068184],
                [107.5523436, 12.8065181],
                [107.5524174, 12.8062375],
                [107.5525215, 12.8059662],
                [107.5526547, 12.8057073],
                [107.5528355, 12.8054409],
                [107.552937, 12.8053154],
                [107.5531608, 12.8050821],
                [107.5532824, 12.804975],
                [107.553595, 12.8047226],
                [107.5538658, 12.8044692],
                [107.554098, 12.8042206],
                [107.5542634, 12.8040246],
                [107.5543256, 12.803964],
                [107.5544667, 12.8038621],
                [107.5546256, 12.8037893],
                [107.5547961, 12.8037484],
                [107.5548837, 12.8037404],
                [107.5550593, 12.80375],
                [107.5552295, 12.8037931],
                [107.5553877, 12.803868],
                [107.5554603, 12.8039165],
                [107.5555891, 12.8040334],
                [107.5561282, 12.8046716],
                [107.5562551, 12.8047686],
                [107.5563258, 12.8048067],
                [107.5564776, 12.8048595],
                [107.5565571, 12.8048738],
                [107.5567183, 12.8048771],
                [107.5567983, 12.8048661],
                [107.5569485, 12.804821],
                [107.5570824, 12.8047488],
                [107.557143, 12.8047031],
                [107.5572484, 12.804595],
                [107.5572922, 12.8045336],
                [107.5573593, 12.8043995],
                [107.557791, 12.8031248],
                [107.5579762, 12.8026105],
                [107.5584483, 12.8013603],
                [107.5586406, 12.8007719],
                [107.5587238, 12.8004739],
                [107.5588639, 12.7998717],
                [107.5589207, 12.7995679],
                [107.5590027, 12.7989994],
                [107.559051, 12.7984709],
                [107.5590652, 12.7982059],
                [107.559059, 12.7980399],
                [107.5590623, 12.7978738],
                [107.5590752, 12.7977082],
                [107.5590976, 12.7975435],
                [107.5591294, 12.7973803],
                [107.5591705, 12.797219],
                [107.5592711, 12.7969266],
                [107.5593912, 12.7966671],
                [107.5594607, 12.7965418],
                [107.5596178, 12.7963018],
                [107.5602888, 12.7954582],
                [107.5604317, 12.7954498],
                [107.5607179, 12.7954555],
                [107.5610018, 12.795491],
                [107.5611366, 12.7955185],
                [107.5614011, 12.7955937],
                [107.561667, 12.7956989],
                [107.5619401, 12.7958532],
                [107.5622088, 12.796035],
                [107.5624653, 12.7962434],
                [107.5627238, 12.7964797],
                [107.5628805, 12.7964773],
                [107.5630587, 12.7964895],
                [107.5634126, 12.7965378],
                [107.5637532, 12.7966157],
                [107.563917, 12.7966647],
                [107.5642719, 12.7967863],
                [107.5654401, 12.7968149],
                [107.5703678, 12.7977346],
                [107.5756992, 12.7978582],
                [107.576033, 12.7975198],
                [107.5762075, 12.7973581],
                [107.5765839, 12.7970401],
                [107.5769931, 12.796736],
                [107.5772695, 12.7965577],
                [107.5774115, 12.7964744],
                [107.5775638, 12.796396],
                [107.5777204, 12.7963261],
                [107.5778808, 12.796265],
                [107.5782728, 12.7961455],
                [107.5785028, 12.7960838],
                [107.5789672, 12.7959775],
                [107.5822449, 12.7954596],
                [107.5824863, 12.7954273],
                [107.5827289, 12.7954043],
                [107.5829722, 12.7953906],
                [107.5834365, 12.7953905],
                [107.5836569, 12.7954024],
                [107.5839864, 12.7954338],
                [107.5842506, 12.795477],
                [107.584404, 12.795512],
                [107.5845556, 12.795554],
                [107.584852, 12.7956584],
                [107.5850915, 12.7957236],
                [107.5853373, 12.7957604],
                [107.5854615, 12.795768],
                [107.5857101, 12.7957614],
                [107.5860536, 12.7957147],
                [107.5862719, 12.7956728],
                [107.5868406, 12.7955316],
                [107.5875625, 12.7953379],
                [107.5879363, 12.7952507],
                [107.588312, 12.7951719],
                [107.5889261, 12.7950806],
                [107.5890882, 12.7950449],
                [107.5895637, 12.7949111],
                [107.589723, 12.7948779],
                [107.5898838, 12.7948528],
                [107.5899797, 12.7948499],
                [107.5901702, 12.7948708],
                [107.5903533, 12.7949265],
                [107.5904817, 12.7949898],
                [107.5905866, 12.7950614],
                [107.5906465, 12.7951132],
                [107.5907513, 12.7952313],
                [107.59088, 12.7954359],
                [107.5909903, 12.7955645],
                [107.5911282, 12.7956779],
                [107.5912086, 12.7957264],
                [107.5912936, 12.7957668],
                [107.5913822, 12.7957987],
                [107.5914738, 12.7958218],
                [107.5915672, 12.7958358],
                [107.5916616, 12.7958406],
                [107.5918651, 12.795838],
                [107.5919738, 12.7958472],
                [107.592188, 12.7958876],
                [107.5923943, 12.7959565],
                [107.5925237, 12.7960131],
                [107.5927115, 12.7961107],
                [107.5928269, 12.796199],
                [107.5928777, 12.7962541],
                [107.5929574, 12.79638],
                [107.5929853, 12.7964489],
                [107.5930151, 12.7965939],
                [107.5930123, 12.7967963],
                [107.5929992, 12.796924],
                [107.5929469, 12.7971754],
                [107.592916, 12.7973997],
                [107.5929147, 12.797626],
                [107.5929431, 12.7978507],
                [107.5929827, 12.798016],
                [107.5930351, 12.7981517],
                [107.5930774, 12.7982299],
                [107.593184, 12.7983727],
                [107.5932473, 12.7984361],
                [107.5933107, 12.7984873],
                [107.5934518, 12.7985696],
                [107.5936071, 12.7986224],
                [107.5936881, 12.798637],
                [107.5938524, 12.7986417],
                [107.5940144, 12.798614],
                [107.5942183, 12.7985486],
                [107.5942916, 12.7985339],
                [107.5944411, 12.7985312],
                [107.594515, 12.7985432],
                [107.5946555, 12.798593],
                [107.5947235, 12.7986294],
                [107.5948476, 12.79872],
                [107.5949026, 12.7987733],
                [107.5949961, 12.7988938],
                [107.5950236, 12.798947],
                [107.5950588, 12.7990609],
                [107.5950658, 12.7991796],
                [107.5950284, 12.7993873],
                [107.59502, 12.799479],
                [107.5950205, 12.799617],
                [107.5950385, 12.799725],
                [107.595058, 12.7997855],
                [107.5951161, 12.7998987],
                [107.5951973, 12.7999976],
                [107.5952495, 12.8000418],
                [107.5953073, 12.8000788],
                [107.5954353, 12.8001291],
                [107.5955033, 12.8001414],
                [107.5956413, 12.8001394],
                [107.5957739, 12.8001022],
                [107.5960137, 12.8000053],
                [107.5964069, 12.7998067],
                [107.5965246, 12.7997749],
                [107.5965853, 12.799768],
                [107.5967073, 12.7997727],
                [107.5967563, 12.7997847],
                [107.5968467, 12.7998284],
                [107.5968862, 12.7998592],
                [107.5969499, 12.7999354],
                [107.5970099, 12.8000657],
                [107.5970381, 12.8001552],
                [107.5970834, 12.8004232],
                [107.5971074, 12.8005045],
                [107.5971553, 12.8006225],
                [107.5972119, 12.8007036],
                [107.5972539, 12.8007417],
                [107.5973016, 12.8007728],
                [107.5973539, 12.8007959],
                [107.5974093, 12.8008105],
                [107.5975093, 12.8008241],
                [107.597678, 12.8008399],
                [107.598035, 12.8008555],
                [107.5982579, 12.8008349],
                [107.5983678, 12.8008139],
                [107.5985822, 12.8007509],
                [107.5986652, 12.8007339],
                [107.5988344, 12.8007255],
                [107.5990018, 12.800751],
                [107.5991602, 12.8008094],
                [107.5993274, 12.8009092],
                [107.5994165, 12.8009741],
                [107.5995808, 12.8011203],
                [107.5996551, 12.8012011],
                [107.5997865, 12.801376],
                [107.5998721, 12.8014724],
                [107.5999796, 12.8016166],
                [107.6000228, 12.8016863],
                [107.6000869, 12.8018363],
                [107.6000971, 12.8018939],
                [107.6000918, 12.8020102],
                [107.6000763, 12.8020666],
                [107.6000213, 12.80217],
                [107.5999829, 12.8022149],
                [107.5998884, 12.8022862],
                [107.6000531, 12.8022793],
                [107.6001352, 12.8022862],
                [107.600292, 12.8023194],
                [107.600441, 12.8023777],
                [107.6005125, 12.8023985],
                [107.6005862, 12.8024101],
                [107.6006608, 12.8024122],
                [107.6007351, 12.8024049],
                [107.6008078, 12.8023883],
                [107.6008776, 12.8023627],
                [107.600975, 12.8023089],
                [107.6010425, 12.8022471],
                [107.6010976, 12.8021537],
                [107.6011129, 12.8021018],
                [107.6011233, 12.8019671],
                [107.6011196, 12.801886],
                [107.6011288, 12.801833],
                [107.6011471, 12.8017823],
                [107.6011739, 12.8017354],
                [107.6012084, 12.8016935],
                [107.6012497, 12.801658],
                [107.6013939, 12.8015629],
                [107.60153, 12.8014503],
                [107.6016085, 12.801356],
                [107.6016652, 12.8012418],
                [107.6016929, 12.8011179],
                [107.6016903, 12.800991],
                [107.6016454, 12.800793],
                [107.6015648, 12.8005259],
                [107.6015446, 12.8004278],
                [107.6015335, 12.8003282],
                [107.6015317, 12.8002281],
                [107.601539, 12.8001282],
                [107.6015556, 12.8000294],
                [107.6015965, 12.7998845],
                [107.601647, 12.7997796],
                [107.6016845, 12.7997248],
                [107.6018388, 12.7995609],
                [107.6018943, 12.7994877],
                [107.6019836, 12.799328],
                [107.6020167, 12.7992429],
                [107.6020582, 12.7990656],
                [107.6020932, 12.798546],
                [107.6021193, 12.7983565],
                [107.602161, 12.7982352],
                [107.602233, 12.7981274],
                [107.6023305, 12.7980408],
                [107.6023869, 12.7980073],
                [107.6025635, 12.7979359],
                [107.602682, 12.7978971],
                [107.6029247, 12.7978384],
                [107.6030481, 12.7978188],
                [107.6034087, 12.7977831],
                [107.6035899, 12.797777],
                [107.603863, 12.7977416],
                [107.60413, 12.797675],
                [107.6042599, 12.7976303],
                [107.604387, 12.7975783],
                [107.6045714, 12.797487],
                [107.6047329, 12.7973849],
                [107.6048297, 12.7973098],
                [107.6049203, 12.7972277],
                [107.6051068, 12.7970934],
                [107.6052071, 12.7970366],
                [107.6054192, 12.7969452],
                [107.6056093, 12.7968476],
                [107.6059169, 12.7966346],
                [107.606349, 12.7963857],
                [107.6064975, 12.7962915],
                [107.6067903, 12.7960813],
                [107.6070722, 12.7958431],
                [107.6073643, 12.7955562],
                [107.6076675, 12.7952239],
                [107.6078117, 12.7950515],
                [107.6103383, 12.7967412],
                [107.6105449, 12.796729],
                [107.6106483, 12.7967321],
                [107.6108797, 12.7967613],
                [107.6110061, 12.7967907],
                [107.6111911, 12.796851],
                [107.6113256, 12.7969194],
                [107.6113957, 12.7969693],
                [107.6114599, 12.7970263],
                [107.6115174, 12.7970898],
                [107.6115675, 12.7971589],
                [107.6116846, 12.797352],
                [107.6117661, 12.7974669],
                [107.6119477, 12.7976828],
                [107.6122074, 12.7979399],
                [107.6123461, 12.7980595],
                [107.6125639, 12.7982273],
                [107.6127672, 12.7983578],
                [107.6128995, 12.7984286],
                [107.613036, 12.7984914],
                [107.6134447, 12.798658],
                [107.6137094, 12.7987783],
                [107.61397, 12.7989069],
                [107.6141508, 12.7989834],
                [107.6143352, 12.7990515],
                [107.6145226, 12.7991109],
                [107.6148066, 12.7991726],
                [107.6150959, 12.7992023],
                [107.6153783, 12.7992048],
                [107.6156854, 12.7992252],
                [107.6160227, 12.7992814],
                [107.6161885, 12.7993227],
                [107.6169655, 12.7995451],
                [107.6175449, 12.7996938],
                [107.6179665, 12.7997857],
                [107.6182659, 12.7998207],
                [107.6185673, 12.7998259],
                [107.6187178, 12.7998174],
                [107.6191896, 12.7997648],
                [107.6194716, 12.7997431],
                [107.6197543, 12.7997308],
                [107.6205174, 12.7997313],
                [107.6209974, 12.7997439],
                [107.6212161, 12.7997364],
                [107.62184, 12.799685],
                [107.6220285, 12.7996815],
                [107.6222169, 12.7996861],
                [107.6225923, 12.79972],
                [107.6227784, 12.7997491],
                [107.6229287, 12.7997825],
                [107.6230768, 12.7998244],
                [107.6233642, 12.799933],
                [107.6235709, 12.8000343],
                [107.6236367, 12.8000735],
                [107.623723, 12.800134],
                [107.6238034, 12.8002017],
                [107.6238774, 12.8002761],
                [107.6239443, 12.8003566],
                [107.6240037, 12.8004425],
                [107.6241501, 12.8007243],
                [107.6242742, 12.8009052],
                [107.624423, 12.8010675],
                [107.624582, 12.8011985],
                [107.6246623, 12.8012511],
                [107.624833, 12.8013394],
                [107.625525, 12.8016428],
                [107.6258797, 12.8017677],
                [107.6262424, 12.8018684],
                [107.6266446, 12.8019526],
                [107.6268641, 12.8019897],
                [107.627306, 12.8020456],
                [107.6275806, 12.8020915],
                [107.6277713, 12.8021461],
                [107.6278689, 12.8021862],
                [107.628053, 12.8022881],
                [107.6281384, 12.8023492],
                [107.628293, 12.8024902],
                [107.6298219, 12.8030656],
                [107.630083, 12.8030669],
                [107.6303422, 12.803097],
                [107.6305936, 12.8031549],
                [107.6308367, 12.80324],
                [107.6309541, 12.8032924],
                [107.6311254, 12.8033809],
                [107.6311791, 12.8034161],
                [107.6312378, 12.8034647],
                [107.63129, 12.8035198],
                [107.631335, 12.8035806],
                [107.6313428, 12.8036048],
                [107.6313722, 12.8036463],
                [107.6314234, 12.8036759],
                [107.6314828, 12.8036803],
                [107.6315117, 12.8036724],
                [107.6316083, 12.8036252],
                [107.63165, 12.8035915],
                [107.6318604, 12.8033507],
                [107.6319543, 12.8032644],
                [107.6320398, 12.8032275],
                [107.6320864, 12.803221],
                [107.6321791, 12.8032331],
                [107.6322613, 12.8032768],
                [107.6324132, 12.8034319],
                [107.6324475, 12.8034945],
                [107.6324583, 12.8035303],
                [107.6324639, 12.8036044],
                [107.6324845, 12.8036669],
                [107.6325282, 12.8037169],
                [107.6325636, 12.8037397],
                [107.632603, 12.8037551],
                [107.632687, 12.8037615],
                [107.6328351, 12.8037118],
                [107.6329773, 12.8036877],
                [107.6330496, 12.8036873],
                [107.6331921, 12.8037099],
                [107.6333499, 12.8037708],
                [107.6334351, 12.8038173],
                [107.6335153, 12.8038717],
                [107.6335896, 12.8039335],
                [107.6336575, 12.804002],
                [107.6337422, 12.8040573],
                [107.6338372, 12.8040935],
                [107.6338789, 12.8040999],
                [107.6339836, 12.8040903],
                [107.6340291, 12.8040684],
                [107.6340512, 12.804049],
                [107.6340688, 12.8040257],
                [107.634089, 12.803971],
                [107.634089, 12.8039126],
                [107.6340812, 12.8038843],
                [107.6340886, 12.8038289],
                [107.6341228, 12.8037841],
                [107.6341751, 12.8037614],
                [107.6342249, 12.8037545],
                [107.6342752, 12.8037572],
                [107.634324, 12.8037692],
                [107.6343694, 12.8037902],
                [107.6344099, 12.8038193],
                [107.6344439, 12.8038555],
                [107.6344952, 12.8039256],
                [107.6345725, 12.8039806],
                [107.634613, 12.803987],
                [107.6346343, 12.8039813],
                [107.6346676, 12.8039536],
                [107.6346816, 12.8039103],
                [107.6346738, 12.8038628],
                [107.6346053, 12.8037742],
                [107.6345738, 12.8037193],
                [107.6345508, 12.8036605],
                [107.6345203, 12.8034678],
                [107.6344618, 12.8032702],
                [107.6344523, 12.8032041],
                [107.6344503, 12.8031041],
                [107.634468, 12.8030152],
                [107.634491, 12.8029625],
                [107.6345224, 12.8029141],
                [107.6345614, 12.8028713],
                [107.634607, 12.8028352],
                [107.6347062, 12.8027875],
                [107.6348657, 12.8027519],
                [107.6349711, 12.8026979],
                [107.6350168, 12.8026605],
                [107.635148, 12.8024951],
                [107.6352124, 12.8024267],
                [107.6354642, 12.8022178],
                [107.6355643, 12.8021219],
                [107.6357442, 12.8019122],
                [107.6356384, 12.8017639],
                [107.6355752, 12.8016977],
                [107.6355031, 12.8016354],
                [107.6352799, 12.8014869],
                [107.6352235, 12.8014345],
                [107.635149, 12.8013465],
                [107.6351165, 12.8012763],
                [107.6351095, 12.8011984],
                [107.6351161, 12.8011597],
                [107.6351488, 12.8010883],
                [107.6352271, 12.8009875],
                [107.6352744, 12.8009441],
                [107.6353821, 12.8008738],
                [107.6355009, 12.8008304],
                [107.6355634, 12.800819],
                [107.6356902, 12.8008178],
                [107.6359001, 12.8008614],
                [107.6360754, 12.800868],
                [107.6361626, 12.8008581],
                [107.6362981, 12.8008715],
                [107.6363633, 12.8008911],
                [107.636483, 12.8009542],
                [107.6366156, 12.8010699],
                [107.6366899, 12.8011487],
                [107.637049, 12.8016213],
                [107.6374823, 12.8021502],
                [107.6377612, 12.8024484],
                [107.6383438, 12.8021178],
                [107.6382785, 12.8018338],
                [107.6382333, 12.8016951],
                [107.6381775, 12.8015536],
                [107.6381131, 12.8014157],
                [107.6379778, 12.8011762],
                [107.6379364, 12.8010611],
                [107.6379241, 12.8010011],
                [107.6379094, 12.8007856],
                [107.6378943, 12.800693],
                [107.6378232, 12.8004336],
                [107.6378077, 12.800272],
                [107.6378212, 12.8001102],
                [107.6378835, 12.7998687],
                [107.6379061, 12.7996968],
                [107.6379415, 12.7995669],
                [107.6379692, 12.7995053],
                [107.638043, 12.7993918],
                [107.6380885, 12.7993411],
                [107.6382902, 12.7991468],
                [107.6383666, 12.7990919],
                [107.6383996, 12.7990586],
                [107.6384651, 12.7989579],
                [107.6384982, 12.7988431],
                [107.6384962, 12.7987238],
                [107.6384633, 12.7986018],
                [107.6384366, 12.7985443],
                [107.6384033, 12.79849],
                [107.6383479, 12.7984241],
                [107.6381487, 12.7982283],
                [107.6381892, 12.7980973],
                [107.6382389, 12.7979693],
                [107.6382977, 12.797845],
                [107.638431, 12.7976238],
                [107.6385927, 12.7974213],
                [107.6387567, 12.7972679],
                [107.6388467, 12.7972001],
                [107.6392794, 12.7969191],
                [107.6396602, 12.7966575],
                [107.6401274, 12.7965598],
                [107.6403627, 12.7965194],
                [107.6408985, 12.7964495],
                [107.641199, 12.7964233],
                [107.6420581, 12.796375],
                [107.6424555, 12.7963426],
                [107.6433405, 12.7962519],
                [107.6446223, 12.7960978],
                [107.6448459, 12.7960778],
                [107.6452945, 12.7960568],
                [107.6457435, 12.7960612],
                [107.6458202, 12.7960971],
                [107.6459795, 12.7961553],
                [107.6461811, 12.796201],
                [107.6468991, 12.7962549],
                [107.6471543, 12.7962809],
                [107.6474647, 12.7963191],
                [107.6480824, 12.7964169],
                [107.6482697, 12.7963941],
                [107.6486404, 12.7963754],
                [107.6490051, 12.7963907],
                [107.6494484, 12.7964424],
                [107.6496556, 12.7964886],
                [107.6498862, 12.7965734],
                [107.6501018, 12.7966898],
                [107.6502979, 12.7968354],
                [107.6505052, 12.7968259],
                [107.6507128, 12.7968259],
                [107.6510986, 12.7968511],
                [107.6512764, 12.7968738],
                [107.651541, 12.7969201],
                [107.651757, 12.7969758],
                [107.6518835, 12.7970188],
                [107.6521277, 12.7971256],
                [107.652487, 12.797331],
                [107.6527555, 12.797456],
                [107.6528942, 12.7975087],
                [107.6531786, 12.7975939],
                [107.6533068, 12.7976187],
                [107.6534364, 12.7976342],
                [107.653567, 12.7976405],
                [107.6539418, 12.797622],
                [107.6541696, 12.7976362],
                [107.6543939, 12.797678],
                [107.6547645, 12.7977938],
                [107.6548503, 12.7978073],
                [107.6551225, 12.7978109],
                [107.6552206, 12.7978205],
                [107.6553178, 12.7978374],
                [107.6556133, 12.7979199],
                [107.6557216, 12.7979406],
                [107.6560708, 12.7979762],
                [107.6563943, 12.7980344],
                [107.6567888, 12.7980752],
                [107.6569313, 12.7980968],
                [107.6572111, 12.7981644],
                [107.6574808, 12.7982635],
                [107.657609, 12.7983044],
                [107.6577397, 12.7983365],
                [107.6578725, 12.7983595],
                [107.6580065, 12.7983733],
                [107.6584755, 12.7984018],
                [107.6589453, 12.7983995],
                [107.659195, 12.7983873],
                [107.6596914, 12.7983779],
                [107.6598845, 12.7983657],
                [107.6603598, 12.7983123],
                [107.6606732, 12.7982579],
                [107.6609742, 12.7982153],
                [107.6609948, 12.7980616],
                [107.6610163, 12.7979869],
                [107.6610804, 12.797845],
                [107.6611276, 12.797772],
                [107.6611824, 12.7977043],
                [107.6612443, 12.7976425],
                [107.6613125, 12.7975875],
                [107.6613862, 12.7975398],
                [107.6614647, 12.7974999],
                [107.6617527, 12.7973832],
                [107.6618847, 12.7973157],
                [107.6620353, 12.7972694],
                [107.6621175, 12.7972559],
                [107.6622007, 12.7972503],
                [107.6623669, 12.7972624],
                [107.6625464, 12.7973079],
                [107.6626468, 12.7973271],
                [107.6627514, 12.7973377],
                [107.6628567, 12.7973388],
                [107.6629616, 12.7973305],
                [107.6630652, 12.7973128],
                [107.663248, 12.7972542],
                [107.6634009, 12.7971714],
                [107.6635379, 12.7970642],
                [107.6636549, 12.7969354],
                [107.6637087, 12.7968527],
                [107.6637571, 12.7967669],
                [107.6638355, 12.796593],
                [107.6638657, 12.7965057],
                [107.663894, 12.7963907],
                [107.6647056, 12.79594],
                [107.6675337, 12.796035],
                [107.6677134, 12.7960055],
                [107.6678944, 12.7959855],
                [107.6680762, 12.795975],
                [107.6682584, 12.7959741],
                [107.6684403, 12.7959827],
                [107.6687534, 12.7960202],
                [107.6689082, 12.7960495],
                [107.6692128, 12.7961292],
                [107.674529, 12.7938536],
                [107.6751563, 12.7930498],
                [107.675221, 12.7929904],
                [107.6752974, 12.7929476],
                [107.675339, 12.7929329],
                [107.6754474, 12.7930572],
                [107.6755736, 12.793156],
                [107.6756354, 12.7931812],
                [107.6757172, 12.7931815],
                [107.6757917, 12.7931485],
                [107.6760275, 12.7929457],
                [107.6761544, 12.7928596],
                [107.676229, 12.7928252],
                [107.6763073, 12.7927995],
                [107.6763667, 12.7927909],
                [107.6764863, 12.7928001],
                [107.6765977, 12.7928435],
                [107.6766908, 12.7929172],
                [107.6767535, 12.7929838],
                [107.6769159, 12.7932022],
                [107.6770465, 12.793327],
                [107.67712, 12.7933802],
                [107.6771786, 12.7934115],
                [107.677305, 12.7934534],
                [107.6773975, 12.7934537],
                [107.6774427, 12.7934435],
                [107.6775255, 12.7934036],
                [107.677592, 12.7933409],
                [107.6776141, 12.7933076],
                [107.6776597, 12.7931993],
                [107.6777074, 12.7931395],
                [107.6777609, 12.7931158],
                [107.6777906, 12.7931142],
                [107.6778465, 12.793132],
                [107.6778694, 12.7931503],
                [107.677952, 12.793267],
                [107.678268, 12.7937725],
                [107.6784396, 12.7940184],
                [107.6785003, 12.7941223],
                [107.6786354, 12.7944283],
                [107.6787111, 12.7945671],
                [107.6787642, 12.7946177],
                [107.678841, 12.794651],
                [107.6789249, 12.7946541],
                [107.6789658, 12.7946441],
                [107.6790383, 12.7946028],
                [107.6791062, 12.7945223],
                [107.6792033, 12.7943225],
                [107.6793113, 12.7941648],
                [107.6793573, 12.7940519],
                [107.6793684, 12.7939308],
                [107.6793604, 12.7938704],
                [107.6793125, 12.7937219],
                [107.6792332, 12.7935489],
                [107.6791165, 12.7933324],
                [107.6789918, 12.7930307],
                [107.6787407, 12.7923474],
                [107.6786542, 12.7920854],
                [107.6785947, 12.7919377],
                [107.6785753, 12.7918605],
                [107.6785583, 12.7917184],
                [107.6785579, 12.7916227],
                [107.6785761, 12.7915549],
                [107.6786231, 12.7914938],
                [107.6786909, 12.7914556],
                [107.6787814, 12.7914428],
                [107.6788337, 12.7914463],
                [107.6788847, 12.7914576],
                [107.6789785, 12.7915024],
                [107.6791558, 12.7916617],
                [107.679196, 12.7916826],
                [107.6792841, 12.7917044],
                [107.6793748, 12.7916981],
                [107.6794182, 12.7916845],
                [107.6795607, 12.7916192],
                [107.6796654, 12.7915806],
                [107.6798281, 12.7914914],
                [107.6799707, 12.791374],
                [107.6800869, 12.7912338],
                [107.6802139, 12.7910049],
                [107.6803134, 12.7908737],
                [107.6804386, 12.7907519],
                [107.6805103, 12.7906939],
                [107.6806095, 12.790584],
                [107.6806769, 12.7905514],
                [107.6807144, 12.7905464],
                [107.6807883, 12.7905604],
                [107.6808212, 12.7905788],
                [107.6808711, 12.7906337],
                [107.6808968, 12.7907088],
                [107.6809013, 12.7907509],
                [107.6808896, 12.7908622],
                [107.6808723, 12.7909297],
                [107.680816, 12.7910572],
                [107.6806611, 12.7912671],
                [107.6805516, 12.7914322],
                [107.6804378, 12.7915749],
                [107.6802903, 12.7917245],
                [107.6802156, 12.7918399],
                [107.6801905, 12.7919038],
                [107.680167, 12.7920383],
                [107.6801722, 12.7920915],
                [107.6801868, 12.7921429],
                [107.6802242, 12.7922142],
                [107.6802684, 12.7922545],
                [107.6802987, 12.7922684],
                [107.6803647, 12.7922755],
                [107.6803973, 12.7922684],
                [107.680454, 12.7922345],
                [107.6805854, 12.7921142],
                [107.6806787, 12.7920574],
                [107.6807922, 12.7920226],
                [107.6809196, 12.7920124],
                [107.6809834, 12.7920181],
                [107.6811068, 12.792051],
                [107.6812591, 12.7921264],
                [107.6814383, 12.7922397],
                [107.6815695, 12.7922975],
                [107.6817101, 12.7923276],
                [107.6818541, 12.7923287],
                [107.6819667, 12.7923078],
                [107.6820295, 12.7922672],
                [107.6820711, 12.7922059],
                [107.6820849, 12.7921336],
                [107.6820805, 12.792097],
                [107.6819887, 12.7917818],
                [107.681871, 12.791543],
                [107.6818474, 12.7914567],
                [107.6818429, 12.791375],
                [107.6818569, 12.7913073],
                [107.6818993, 12.7912349],
                [107.6819294, 12.7912051],
                [107.6820031, 12.7911628],
                [107.6821103, 12.7911416],
                [107.6823374, 12.7911535],
                [107.6825057, 12.7911403],
                [107.6827365, 12.7910915],
                [107.6828343, 12.7910604],
                [107.6829758, 12.7910007],
                [107.6830896, 12.7909309],
                [107.6831529, 12.7908782],
                [107.6832097, 12.790819],
                [107.6834467, 12.7904979],
                [107.6835983, 12.7903469],
                [107.6837718, 12.7902205],
                [107.6839292, 12.7901458],
                [107.684098, 12.7901006],
                [107.6843667, 12.7900597],
                [107.6845465, 12.7900204],
                [107.6851136, 12.7898692],
                [107.685431, 12.7898022],
                [107.6858083, 12.7897542],
                [107.6861751, 12.789734],
                [107.6865809, 12.7897288],
                [107.6868776, 12.7897545],
                [107.6870424, 12.7897815],
                [107.6872053, 12.7898174],
                [107.687366, 12.7898621],
                [107.6876835, 12.7898551],
                [107.687999, 12.7898203],
                [107.688544, 12.7897111],
                [107.68893, 12.7896648],
                [107.6894528, 12.7896372],
                [107.698629, 12.7889224],
                [107.6989102, 12.7887727],
                [107.6990551, 12.788706],
                [107.6994132, 12.7885687],
                [107.6995969, 12.7885124],
                [107.6997654, 12.7884715],
                [107.6999361, 12.7884399],
                [107.7001083, 12.7884179],
                [107.7003683, 12.7884021],
                [107.7005885, 12.7884105],
                [107.7007209, 12.7884271],
                [107.7010764, 12.7885042],
                [107.7011729, 12.7885162],
                [107.7013671, 12.7885229],
                [107.7023939, 12.788507],
                [107.7025405, 12.7884956],
                [107.7031352, 12.7884192],
                [107.7038269, 12.7883856],
                [107.7039952, 12.7883647],
                [107.7047287, 12.788227],
                [107.704913, 12.7881776],
                [107.7050707, 12.7881081],
                [107.7052161, 12.7880166],
                [107.7053941, 12.7878694],
                [107.7056734, 12.787611],
                [107.7057445, 12.7875551],
                [107.7058936, 12.7874686],
                [107.7060566, 12.7874106],
                [107.706576, 12.7872883],
                [107.7069295, 12.7871706],
                [107.7071017, 12.7871],
                [107.7140969, 12.7869849],
                [107.723813, 12.7857732],
                [107.7317405, 12.781076],
                [107.7435395, 12.7846072],
                [107.7437398, 12.7846728],
                [107.7439429, 12.7847295],
                [107.7441484, 12.7847772],
                [107.7443172, 12.7848094],
                [107.744658, 12.7848557],
                [107.7449906, 12.7846935],
                [107.7455379, 12.7844587],
                [107.7458167, 12.7843535],
                [107.7464339, 12.7841437],
                [107.7466506, 12.784056],
                [107.7468634, 12.7839596],
                [107.7470719, 12.7838546],
                [107.7472758, 12.7837414],
                [107.7475387, 12.7835635],
                [107.7476942, 12.7834694],
                [107.7477872, 12.7833834],
                [107.7478543, 12.783277],
                [107.7478913, 12.7831592],
                [107.747899, 12.7830988],
                [107.7478977, 12.7830077],
                [107.7478796, 12.7829346],
                [107.7478599, 12.7828939],
                [107.7478028, 12.7828238],
                [107.7477667, 12.782796],
                [107.7476835, 12.7827579],
                [107.747528, 12.7823655],
                [107.7474224, 12.7821326],
                [107.7472871, 12.7819008],
                [107.7471989, 12.7818026],
                [107.7471429, 12.7817586],
                [107.7470169, 12.7816917],
                [107.746898, 12.7816478],
                [107.7468038, 12.7815913],
                [107.7467587, 12.7815291],
                [107.7467438, 12.7814545],
                [107.7467616, 12.7813804],
                [107.746809, 12.78132],
                [107.7470398, 12.7811675],
                [107.7473456, 12.7809792],
                [107.7475387, 12.7808275],
                [107.7476451, 12.7807718],
                [107.7477614, 12.7807399],
                [107.747969, 12.7807268],
                [107.7482782, 12.7806636],
                [107.7485925, 12.7806258],
                [107.7489119, 12.7806079],
                [107.7492017, 12.780613],
                [107.7494061, 12.7805957],
                [107.749606, 12.78055],
                [107.7498572, 12.7804589],
                [107.749981, 12.7804379],
                [107.7501253, 12.7804395],
                [107.7502056, 12.7804529],
                [107.7502839, 12.7804752],
                [107.750359, 12.780506],
                [107.7504301, 12.780545],
                [107.7504985, 12.7805952],
                [107.7505374, 12.780613],
                [107.7505855, 12.7806269],
                [107.7506855, 12.7806316],
                [107.7507818, 12.7806051],
                [107.7508255, 12.7805809],
                [107.750935, 12.7805004],
                [107.7510846, 12.7804142],
                [107.7512377, 12.7803593],
                [107.7513989, 12.7803346],
                [107.7514806, 12.7803339],
                [107.7520211, 12.7803884],
                [107.752351, 12.7804378],
                [107.7525276, 12.7804717],
                [107.7526431, 12.7804824],
                [107.752875, 12.7804773],
                [107.7531033, 12.7804369],
                [107.7533437, 12.7803595],
                [107.753471, 12.7803098],
                [107.7538529, 12.780138],
                [107.7539907, 12.7800899],
                [107.7542743, 12.7800195],
                [107.7544189, 12.7799974],
                [107.7547109, 12.77998],
                [107.7552772, 12.779998],
                [107.7555509, 12.7799819],
                [107.7558213, 12.7799381],
                [107.7559707, 12.7799014],
                [107.7561175, 12.779856],
                [107.7562612, 12.7798021],
                [107.7565129, 12.7797295],
                [107.7567708, 12.7796818],
                [107.7568929, 12.7796704],
                [107.7570154, 12.7796667],
                [107.75726, 12.7796825],
                [107.7576035, 12.7797428],
                [107.7578115, 12.7797455],
                [107.757915, 12.7797343],
                [107.7581173, 12.779687],
                [107.7583272, 12.7796099],
                [107.7585266, 12.7795098],
                [107.7587478, 12.7793826],
                [107.7590114, 12.7792661],
                [107.7594811, 12.7791177],
                [107.7598855, 12.7789794],
                [107.7608693, 12.7787087],
                [107.7612158, 12.7786425],
                [107.761391, 12.7786215],
                [107.7617437, 12.7786041],
                [107.7619441, 12.7786039],
                [107.762627, 12.7786271],
                [107.7629096, 12.7786253],
                [107.7630978, 12.7786128],
                [107.7631921, 12.7786145],
                [107.7633138, 12.7786248],
                [107.7634344, 12.778644],
                [107.7636122, 12.7786879],
                [107.7637429, 12.778744],
                [107.7638117, 12.7787873],
                [107.7638748, 12.7788381],
                [107.7639315, 12.7788957],
                [107.763981, 12.7789593],
                [107.7641475, 12.7792618],
                [107.764737, 12.7800323],
                [107.7647919, 12.7801262],
                [107.764918, 12.780304],
                [107.7650642, 12.7804665],
                [107.7651603, 12.7805552],
                [107.76537, 12.7807124],
                [107.7660352, 12.7806234],
                [107.7663914, 12.7805444],
                [107.7668225, 12.7804185],
                [107.7670759, 12.7803357],
                [107.7674534, 12.7801941],
                [107.7678769, 12.7800208],
                [107.768358, 12.7798439],
                [107.7686601, 12.7797068],
                [107.7689473, 12.779542],
                [107.769217, 12.7793512],
                [107.7695123, 12.7791046],
                [107.7695927, 12.7790297],
                [107.7697771, 12.7788884],
                [107.7698815, 12.7788238],
                [107.7700119, 12.7787778],
                [107.7701497, 12.7787622],
                [107.7702875, 12.7787778],
                [107.7704897, 12.7788494],
                [107.7707386, 12.7789191],
                [107.7710938, 12.7790017],
                [107.771245, 12.7790283],
                [107.771397, 12.7790492],
                [107.7718554, 12.7790911],
                [107.7720734, 12.7790956],
                [107.7723044, 12.7790772],
                [107.7725228, 12.7790348],
                [107.772724, 12.7789641],
                [107.773084, 12.7787767],
                [107.773226, 12.7787299],
                [107.7733742, 12.7787087],
                [107.7736919, 12.7787216],
                [107.7741878, 12.7787156],
                [107.7745056, 12.7787453],
                [107.7746336, 12.7787508],
                [107.774889, 12.778735],
                [107.775162, 12.7786981],
                [107.7753095, 12.7786913],
                [107.7754572, 12.7786938],
                [107.7756044, 12.7787054],
                [107.7757505, 12.7787261],
                [107.77631, 12.7788587],
                [107.7765537, 12.7788826],
                [107.7767984, 12.7788728],
                [107.7775671, 12.7787748],
                [107.7776974, 12.7787663],
                [107.7779346, 12.7787713],
                [107.7783205, 12.7788192],
                [107.7784723, 12.7788264],
                [107.7786242, 12.7788245],
                [107.7791697, 12.7787879],
                [107.7794141, 12.778794],
                [107.7795262, 12.7788077],
                [107.7798979, 12.7788864],
                [107.7800504, 12.7789108],
                [107.7805285, 12.7789581],
                [107.7806977, 12.778985],
                [107.7808654, 12.7790196],
                [107.7811949, 12.7791118],
                [107.7815384, 12.7792423],
                [107.7816232, 12.7792615],
                [107.7817967, 12.7792749],
                [107.7819694, 12.7792545],
                [107.7820533, 12.7792319],
                [107.7821767, 12.7791737],
                [107.7823474, 12.7790435],
                [107.7824846, 12.7789647],
                [107.7826544, 12.7789014],
                [107.7827537, 12.7788779],
                [107.7828549, 12.7788634],
                [107.782957, 12.7788581],
                [107.7830592, 12.778862],
                [107.7831606, 12.778875],
                [107.7834571, 12.778946],
                [107.7836519, 12.7790017],
                [107.7837583, 12.7790211],
                [107.7839738, 12.7790383],
                [107.7841434, 12.7790201],
                [107.7842261, 12.7789992],
                [107.7843834, 12.7789347],
                [107.7844566, 12.7788918],
                [107.7845471, 12.7788667],
                [107.7846411, 12.7788667],
                [107.7847584, 12.7788848],
                [107.7848302, 12.7788865],
                [107.7849017, 12.7788812],
                [107.7851234, 12.7788331],
                [107.7852064, 12.7788223],
                [107.7855924, 12.7788061],
                [107.7858084, 12.7787715],
                [107.785889, 12.7787656],
                [107.78601, 12.7787697],
                [107.7861001, 12.7787926],
                [107.7861903, 12.7788458],
                [107.7862598, 12.7789232],
                [107.7863281, 12.7790683],
                [107.7863609, 12.7791153],
                [107.7864445, 12.7791945],
                [107.7865466, 12.7792491],
                [107.7868518, 12.779326],
                [107.7870082, 12.7793541],
                [107.7873239, 12.7793914],
                [107.7874376, 12.7794133],
                [107.7875491, 12.7794441],
                [107.7876578, 12.7794834],
                [107.7877628, 12.7795311],
                [107.7878637, 12.7795869],
                [107.7879596, 12.7796504],
                [107.788018, 12.7796771],
                [107.7881423, 12.7797105],
                [107.788271, 12.7797159],
                [107.7883978, 12.7796928],
                [107.7885157, 12.7796419],
                [107.7885693, 12.7796067],
                [107.7886623, 12.7795196],
                [107.7887029, 12.7794414],
                [107.7887159, 12.7793547],
                [107.7886999, 12.7792685],
                [107.7887009, 12.7792379],
                [107.7887246, 12.7791818],
                [107.7887722, 12.7791427],
                [107.7888439, 12.7791243],
                [107.7888868, 12.779123],
                [107.7889293, 12.7791286],
                [107.7890086, 12.77916],
                [107.7890897, 12.779226],
                [107.7891655, 12.7793234],
                [107.7892446, 12.7794938],
                [107.7893252, 12.7795965],
                [107.7893747, 12.7796397],
                [107.7896011, 12.7798021],
                [107.7896594, 12.7798311],
                [107.7897213, 12.7798519],
                [107.7897857, 12.779864],
                [107.7898511, 12.7798671],
                [107.7899163, 12.7798612],
                [107.7901198, 12.7798009],
                [107.7902413, 12.7797813],
                [107.7902824, 12.7797812],
                [107.790383, 12.7798044],
                [107.7904288, 12.7798281],
                [107.790505, 12.7798963],
                [107.7905563, 12.7799107],
                [107.7906077, 12.7798967],
                [107.7906285, 12.77988],
                [107.790672, 12.7797949],
                [107.7906976, 12.7797598],
                [107.7907643, 12.7797039],
                [107.7908036, 12.7796844],
                [107.7908982, 12.7796639],
                [107.7910091, 12.7796737],
                [107.7910657, 12.7796879],
                [107.7911724, 12.7797341],
                [107.7913649, 12.7798505],
                [107.7915135, 12.7799273],
                [107.791782, 12.7800557],
                [107.792003, 12.7801928],
                [107.7922082, 12.7803514],
                [107.7923512, 12.7804359],
                [107.7925067, 12.7804956],
                [107.7926703, 12.7805287],
                [107.7928144, 12.780545],
                [107.7932606, 12.7805514],
                [107.7933467, 12.7805423],
                [107.7934313, 12.780524],
                [107.7935839, 12.7804794],
                [107.7936619, 12.7804639],
                [107.7938134, 12.7804508],
                [107.7939644, 12.7804692],
                [107.7941969, 12.7805458],
                [107.7942803, 12.7805611],
                [107.7944497, 12.7805656],
                [107.7946163, 12.7805354],
                [107.7947727, 12.7804718],
                [107.7948448, 12.7804283],
                [107.7950189, 12.780288],
                [107.7951413, 12.7802198],
                [107.7953008, 12.7801605],
                [107.7954024, 12.7801071],
                [107.795546, 12.7800685],
                [107.7956055, 12.7800379],
                [107.7956469, 12.7799861],
                [107.7956629, 12.7799224],
                [107.7956654, 12.7798526],
                [107.7957004, 12.7797916],
                [107.7957568, 12.7797393],
                [107.7958194, 12.7796611],
                [107.7958587, 12.7796295],
                [107.7959575, 12.7795818],
                [107.7960116, 12.7795702],
                [107.7960669, 12.7795673],
                [107.7961752, 12.7795876],
                [107.7962379, 12.7796175],
                [107.7964148, 12.7796722],
                [107.7964662, 12.7796985],
                [107.7965563, 12.7797693],
                [107.7966668, 12.7799086],
                [107.7967133, 12.7799478],
                [107.7968113, 12.7800007],
                [107.7968598, 12.7800152],
                [107.7969605, 12.7800251],
                [107.797011, 12.7800201],
                [107.797159, 12.7799783],
                [107.7972105, 12.7799721],
                [107.7972566, 12.7799718],
                [107.7973464, 12.7799912],
                [107.797388, 12.7800105],
                [107.79746, 12.7800663],
                [107.7975247, 12.7801641],
                [107.7975751, 12.7802168],
                [107.7976423, 12.7802468],
                [107.7976796, 12.7802517],
                [107.7977541, 12.7802437],
                [107.7977894, 12.7802309],
                [107.7979127, 12.7801456],
                [107.7980196, 12.7801074],
                [107.7980762, 12.7801002],
                [107.7981895, 12.7801108],
                [107.798345, 12.7801582],
                [107.7985152, 12.7801763],
                [107.7986938, 12.7801605],
                [107.7987907, 12.7801364],
                [107.7989555, 12.7801161],
                [107.7990829, 12.7800823],
                [107.7991425, 12.7800542],
                [107.7992287, 12.7799954],
                [107.799273, 12.7799328],
                [107.7992896, 12.7798585],
                [107.7992746, 12.7797466],
                [107.7992976, 12.7796753],
                [107.7993211, 12.7796454],
                [107.799383, 12.779606],
                [107.799487, 12.7795815],
                [107.7996291, 12.7795752],
                [107.7997486, 12.7795971],
                [107.7998368, 12.7796446],
                [107.7999034, 12.7797184],
                [107.7999333, 12.7797824],
                [107.8000146, 12.7798983],
                [107.8001207, 12.779993],
                [107.8002925, 12.7801025],
                [107.8004475, 12.7802339],
                [107.8005619, 12.7803586],
                [107.8007168, 12.7805697],
                [107.8007572, 12.7806122],
                [107.8008041, 12.7806477],
                [107.8008563, 12.7806754],
                [107.8009123, 12.7806946],
                [107.8009709, 12.7807046],
                [107.8010302, 12.7807053],
                [107.801089, 12.7806967],
                [107.8011654, 12.7806702],
                [107.8014974, 12.7804941],
                [107.8017403, 12.7803882],
                [107.8018705, 12.7803009],
                [107.8019289, 12.7802489],
                [107.8020831, 12.7800772],
                [107.8021437, 12.7800298],
                [107.8022097, 12.77999],
                [107.8022803, 12.7799585],
                [107.8023543, 12.7799357],
                [107.8024307, 12.779922],
                [107.8025082, 12.7799175],
                [107.8026294, 12.7799294],
                [107.8028208, 12.7799624],
                [107.8028798, 12.7799789],
                [107.802936, 12.7800028],
                [107.8030363, 12.7800715],
                [107.8031869, 12.7802349],
                [107.8034338, 12.7804505],
                [107.8035754, 12.780613],
                [107.8037172, 12.7808083],
                [107.8038923, 12.7810173],
                [107.8039647, 12.7811497],
                [107.8039908, 12.7812203],
                [107.8040195, 12.7813488],
                [107.8040127, 12.7814612],
                [107.8039629, 12.7816061],
                [107.8039573, 12.7816541],
                [107.8039688, 12.7817499],
                [107.8040086, 12.7818359],
                [107.8040936, 12.7819471],
                [107.8042046, 12.782069],
                [107.804363, 12.7822143],
                [107.804725, 12.7825019],
                [107.8048468, 12.7826271],
                [107.8049147, 12.7827152],
                [107.8049901, 12.7827974],
                [107.8050722, 12.782873],
                [107.8051607, 12.7829417],
                [107.8052547, 12.7830029],
                [107.8054598, 12.7831127],
                [107.8056115, 12.7832524],
                [107.8057638, 12.7833534],
                [107.8059628, 12.7834454],
                [107.806067, 12.7834802],
                [107.8062821, 12.7835263],
                [107.8064752, 12.7835404],
                [107.8067258, 12.7835329],
                [107.8068924, 12.7835199],
                [107.8072229, 12.7834722],
                [107.8073862, 12.7834377],
                [107.8076137, 12.783377],
                [107.8076817, 12.7833672],
                [107.8077505, 12.7833669],
                [107.8078186, 12.7833762],
                [107.8078847, 12.7833947],
                [107.8079829, 12.7834429],
                [107.8081459, 12.7835662],
                [107.8083018, 12.783723],
                [107.8084356, 12.7838983],
                [107.8085085, 12.7840191],
                [107.8086421, 12.7842672],
                [107.8087432, 12.7845016],
                [107.8087387, 12.7845598],
                [107.8087246, 12.7845859],
                [107.8086792, 12.7846217],
                [107.8085965, 12.7846438],
                [107.8083428, 12.784679],
                [107.8081223, 12.7846947],
                [107.8080118, 12.7846909],
                [107.8078175, 12.784665],
                [107.8076282, 12.7846151],
                [107.807406, 12.7845752],
                [107.8072933, 12.7845665],
                [107.8071129, 12.7845683],
                [107.8069821, 12.7845994],
                [107.8068638, 12.7846621],
                [107.8067627, 12.7847551],
                [107.8066878, 12.7848693],
                [107.8066438, 12.7849978],
                [107.8066331, 12.785133],
                [107.8066557, 12.7853435],
                [107.8066429, 12.7856775],
                [107.8066508, 12.7857916],
                [107.8066847, 12.7859802],
                [107.8067386, 12.7861256],
                [107.8068179, 12.7862593],
                [107.8069226, 12.7863794],
                [107.8069853, 12.7864315],
                [107.8070538, 12.7864762],
                [107.8071271, 12.786513],
                [107.8072043, 12.7865412],
                [107.8072843, 12.7865605],
                [107.8073661, 12.7865707],
                [107.8074485, 12.7865716],
                [107.8076038, 12.7865496],
                [107.8076794, 12.7865291],
                [107.807824, 12.7864696],
                [107.8078947, 12.7864294],
                [107.8080217, 12.7863291],
                [107.8080768, 12.7862701],
                [107.8081255, 12.786206],
                [107.8082451, 12.7859928],
                [107.8082944, 12.7859267],
                [107.8084117, 12.7858101],
                [107.8085166, 12.7857367],
                [107.8085797, 12.7856733],
                [107.8086205, 12.7855945],
                [107.8086472, 12.7854311],
                [107.8086675, 12.7853736],
                [107.8087306, 12.785269],
                [107.808794, 12.7852046],
                [107.808864, 12.7851471],
                [107.8089397, 12.7850969],
                [107.8090204, 12.7850548],
                [107.8091051, 12.785021],
                [107.809193, 12.784996],
                [107.8092831, 12.7849801],
                [107.8093743, 12.7849734],
                [107.8097391, 12.7849931],
                [107.8107111, 12.7849942],
                [107.8110629, 12.7850134],
                [107.8112768, 12.7850325],
                [107.8113645, 12.7850624],
                [107.8114378, 12.7851181],
                [107.8114822, 12.7851804],
                [107.811517, 12.7852657],
                [107.8115404, 12.7853857],
                [107.8115416, 12.7854468],
                [107.8115264, 12.785621],
                [107.8115289, 12.7858125],
                [107.811501, 12.7859129],
                [107.8114768, 12.7859594],
                [107.8114101, 12.7860406],
                [107.8112867, 12.78614],
                [107.8111898, 12.786242],
                [107.8110501, 12.7864545],
                [107.8109177, 12.7865988],
                [107.810878, 12.7866554],
                [107.8108228, 12.7867627],
                [107.8108026, 12.7868637],
                [107.8108136, 12.786966],
                [107.8108307, 12.7870149],
                [107.8108825, 12.7870987],
                [107.8109823, 12.7871983],
                [107.8111886, 12.7873544],
                [107.8112454, 12.7874136],
                [107.811295, 12.7874786],
                [107.8113367, 12.7875488],
                [107.8113699, 12.7876231],
                [107.811435, 12.7878095],
                [107.811538, 12.7880097],
                [107.8115656, 12.7881322],
                [107.8115675, 12.788195],
                [107.8115469, 12.7883189],
                [107.8114661, 12.7884949],
                [107.8113711, 12.7886405],
                [107.8110949, 12.7889927],
                [107.810981, 12.7891768],
                [107.8109378, 12.7892715],
                [107.8109253, 12.7893219],
                [107.8109193, 12.7894253],
                [107.8109277, 12.7894854],
                [107.8109455, 12.7895436],
                [107.8109723, 12.7895983],
                [107.8110074, 12.7896484],
                [107.8110501, 12.7896926],
                [107.8110993, 12.7897297],
                [107.8111538, 12.7897591],
                [107.8112123, 12.7897798],
                [107.8113295, 12.789794],
                [107.8114406, 12.789779],
                [107.8115447, 12.7897381],
                [107.8115921, 12.7897087],
                [107.811673, 12.7896353],
                [107.8118936, 12.7893544],
                [107.8120353, 12.7892172],
                [107.8121614, 12.7891216],
                [107.812214, 12.7890937],
                [107.8123282, 12.789059],
                [107.8123878, 12.7890527],
                [107.8124477, 12.7890539],
                [107.8125645, 12.7890789],
                [107.8126708, 12.7891324],
                [107.8127979, 12.7892277],
                [107.8129566, 12.7893122],
                [107.813426, 12.7894864],
                [107.8137296, 12.7895768],
                [107.8138435, 12.7896001],
                [107.8140751, 12.7896225],
                [107.8141916, 12.7896215],
                [107.8144272, 12.7895944],
                [107.814802, 12.7895661],
                [107.8149407, 12.7895661],
                [107.8152166, 12.7895916],
                [107.8153528, 12.7896168],
                [107.8157229, 12.7897145],
                [107.81594, 12.7897432],
                [107.8161295, 12.7897799],
                [107.8163198, 12.7898433],
                [107.8165646, 12.7899603],
                [107.8166859, 12.7899966],
                [107.8168128, 12.7899986],
                [107.8168752, 12.7899867],
                [107.8169918, 12.789938],
                [107.8170841, 12.7898698],
                [107.8171801, 12.7897694],
                [107.8173069, 12.7896653],
                [107.8174533, 12.7895895],
                [107.8175318, 12.7895634],
                [107.8176952, 12.7895361],
                [107.8178608, 12.789543],
                [107.817968, 12.7895699],
                [107.818064, 12.7896235],
                [107.8181056, 12.7896592],
                [107.8181722, 12.7897453],
                [107.8181961, 12.7897941],
                [107.818222, 12.7898921],
                [107.8182615, 12.7902419],
                [107.8182766, 12.7907282],
                [107.8183088, 12.7910993],
                [107.8183365, 12.7912664],
                [107.8183924, 12.7914266],
                [107.8184751, 12.7915754],
                [107.8185257, 12.7916443],
                [107.8186967, 12.7918362],
                [107.8188184, 12.7919573],
                [107.8191081, 12.7922048],
                [107.819159, 12.7922589],
                [107.8192424, 12.7923809],
                [107.8192985, 12.792517],
                [107.8193299, 12.7926817],
                [107.8193361, 12.7928492],
                [107.819328, 12.7929462],
                [107.8192917, 12.7931376],
                [107.8191908, 12.7934401],
                [107.8191531, 12.7936053],
                [107.8191567, 12.7937224],
                [107.8191946, 12.7938335],
                [107.8192636, 12.7939294],
                [107.8193045, 12.7939663],
                [107.8193993, 12.7940227],
                [107.8195059, 12.794053],
                [107.8197145, 12.7940535],
                [107.8197907, 12.7940615],
                [107.8199396, 12.7940968],
                [107.8200583, 12.7941477],
                [107.8201649, 12.7942198],
                [107.8201977, 12.7942488],
                [107.8202418, 12.7943231],
                [107.8202512, 12.7943653],
                [107.8202427, 12.7944508],
                [107.8202252, 12.7944905],
                [107.8201773, 12.7945474],
                [107.8201246, 12.7945807],
                [107.819908, 12.7946386],
                [107.8198658, 12.7946587],
                [107.8197935, 12.7947168],
                [107.8197468, 12.7947859],
                [107.819707, 12.7948904],
                [107.8196883, 12.7949624],
                [107.8196724, 12.7951078],
                [107.8196867, 12.7952512],
                [107.8197307, 12.7953887],
                [107.8197634, 12.7954534],
                [107.8198452, 12.7955677],
                [107.8199893, 12.7957127],
                [107.8201851, 12.795873],
                [107.8204379, 12.7960352],
                [107.8204889, 12.796078],
                [107.8205743, 12.7961793],
                [107.8206333, 12.796296],
                [107.8207054, 12.7964044],
                [107.8208011, 12.7964937],
                [107.8209154, 12.7965591],
                [107.8210223, 12.7965932],
                [107.8211592, 12.7966159],
                [107.8213542, 12.796632],
                [107.8214573, 12.7966313],
                [107.8216624, 12.79661],
                [107.8217635, 12.7965895],
                [107.8220017, 12.7965173],
                [107.822038, 12.7965125],
                [107.8221096, 12.7965251],
                [107.8221418, 12.7965419],
                [107.8221922, 12.7965932],
                [107.8222168, 12.79666],
                [107.8222179, 12.7966956],
                [107.8222009, 12.7967573],
                [107.8220597, 12.7969579],
                [107.8219279, 12.7971234],
                [107.8218667, 12.7972129],
                [107.8218135, 12.7973072],
                [107.8217688, 12.7974056],
                [107.821711, 12.7975893],
                [107.821683, 12.7977796],
                [107.8216827, 12.7979379],
                [107.8216619, 12.7983226],
                [107.8216662, 12.7984361],
                [107.8216964, 12.798661],
                [107.8217526, 12.7988659],
                [107.82182, 12.7990201],
                [107.8218546, 12.7990716],
                [107.8219434, 12.7991588],
                [107.8220529, 12.7992196],
                [107.822113, 12.7992386],
                [107.8222382, 12.7992522],
                [107.822434, 12.7992339],
                [107.8227345, 12.7992313],
                [107.8228459, 12.7992482],
                [107.8228989, 12.7992674],
                [107.8229946, 12.7993254],
                [107.8230535, 12.7993833],
                [107.8231609, 12.7995085],
                [107.8232186, 12.7995897],
                [107.8234309, 12.7999367],
                [107.8234799, 12.8000336],
                [107.8235578, 12.8002356],
                [107.8235865, 12.8003405],
                [107.8237336, 12.8010851],
                [107.8237916, 12.8013082],
                [107.8239144, 12.8017183],
                [107.8239776, 12.8019056],
                [107.824274, 12.8017611],
                [107.8244568, 12.8016718],
                [107.8246436, 12.8015908],
                [107.824834, 12.8015183],
                [107.8250277, 12.8014545],
                [107.8253691, 12.8013647],
                [107.8255423, 12.8013299],
                [107.8258922, 12.8012805],
                [107.8263554, 12.8012426],
                [107.8266424, 12.8012348],
                [107.8269723, 12.8012374],
                [107.8276276, 12.8012702],
                [107.8282785, 12.8013394],
                [107.8285851, 12.8013811],
                [107.8288905, 12.8014308],
                [107.8294965, 12.8015544],
                [107.8300948, 12.8017097],
                [107.8306835, 12.8018963],
                [107.8309739, 12.8020011],
                [107.8314752, 12.8022032],
                [107.8318976, 12.802395],
                [107.8329811, 12.8028513],
                [107.8334204, 12.8030432],
                [107.8338557, 12.8032437],
                [107.8342867, 12.8034527],
                [107.8347489, 12.8036889],
                [107.8356625, 12.8041806],
                [107.8361137, 12.804436],
                [107.836704, 12.8047831],
                [107.8369763, 12.8049731],
                [107.837229, 12.8051874],
                [107.8374596, 12.8054242],
                [107.8376663, 12.8056811],
                [107.8378723, 12.8059641],
                [107.8379861, 12.8060975],
                [107.8382336, 12.8063468],
                [107.8383667, 12.806462],
                [107.8386501, 12.8066719],
                [107.8387996, 12.8067662],
                [107.8392899, 12.8070295],
                [107.8394513, 12.8071292],
                [107.8396077, 12.8072362],
                [107.8399042, 12.8074709],
                [107.8401768, 12.8077317],
                [107.8403445, 12.8079193],
                [107.8406991, 12.8082772],
                [107.841078, 12.8086105],
                [107.8415173, 12.8089684],
                [107.8419327, 12.8093525],
                [107.8421309, 12.8095539],
                [107.842679, 12.8101576],
                [107.8428673, 12.8103467],
                [107.8430621, 12.8105295],
                [107.8434702, 12.8108751],
                [107.843683, 12.8110376],
                [107.844125, 12.811341],
                [107.8443528, 12.8114811],
                [107.844817, 12.8117435],
                [107.8452112, 12.8119459],
                [107.8456133, 12.8121278],
                [107.845815, 12.8122075],
                [107.8462259, 12.8123475],
                [107.8465987, 12.8124482],
                [107.8467649, 12.8124797],
                [107.8469327, 12.8125019],
                [107.8471015, 12.8125149],
                [107.8472707, 12.8125186],
                [107.8474399, 12.8125129],
                [107.8476086, 12.8124979],
                [107.847776, 12.8124737],
                [107.8479418, 12.8124402],
                [107.8482201, 12.8123619],
                [107.8485579, 12.812237],
                [107.8487789, 12.8121437],
                [107.8489959, 12.8120419],
                [107.8493229, 12.8118648],
                [107.8494796, 12.8117651],
                [107.8497778, 12.8115449],
                [107.8502174, 12.8111537],
                [107.8505364, 12.8109055],
                [107.8508738, 12.8106815],
                [107.8510487, 12.8105791],
                [107.8513503, 12.8104221],
                [107.8515848, 12.810281],
                [107.8518023, 12.8101159],
                [107.8519037, 12.8100249],
                [107.85224, 12.8096681],
                [107.8525018, 12.8094284],
                [107.8527837, 12.8092113],
                [107.8529316, 12.8091118],
                [107.8534101, 12.8088283],
                [107.8537193, 12.8086125],
                [107.8538668, 12.8084954],
                [107.8544094, 12.8080199],
                [107.8546891, 12.8077916],
                [107.8551597, 12.8074311],
                [107.855339, 12.8072847],
                [107.8555116, 12.807131],
                [107.8556773, 12.8069702],
                [107.8558357, 12.8068026],
                [107.8559866, 12.8066284],
                [107.8561297, 12.8064482],
                [107.8562647, 12.8062621],
                [107.8563914, 12.8060705],
                [107.8565096, 12.8058738],
                [107.8566189, 12.8056723],
                [107.8567193, 12.8054664],
                [107.8568105, 12.8052565],
                [107.8568923, 12.805043],
                [107.8570601, 12.804564],
                [107.8572602, 12.8040969],
                [107.8573721, 12.8038685],
                [107.857619, 12.8034233],
                [107.8577537, 12.803207],
                [107.858045, 12.802788],
                [107.8583642, 12.8023889],
                [107.8585338, 12.8021973],
                [107.858702, 12.8020195],
                [107.8590553, 12.80168],
                [107.8595714, 12.8011577],
                [107.8600821, 12.8006303],
                [107.8610873, 12.7995608],
                [107.8616454, 12.7989481],
                [107.8621963, 12.7983292],
                [107.86274, 12.7977044],
                [107.8632765, 12.7970735],
                [107.8638057, 12.7964369],
                [107.8643274, 12.7957944],
                [107.8640762, 12.7953648],
                [107.8638165, 12.79494],
                [107.8635487, 12.79452],
                [107.8632727, 12.7941051],
                [107.8629886, 12.7936954],
                [107.8626966, 12.7932911],
                [107.8623968, 12.7928922],
                [107.8620892, 12.792499],
                [107.8611918, 12.7914106],
                [107.8606337, 12.7906913],
                [107.8601001, 12.7899544],
                [107.8593815, 12.7889002],
                [107.8588919, 12.7882399],
                [107.8586368, 12.7879173],
                [107.8581062, 12.7872878],
                [107.857831, 12.7869812],
                [107.8572615, 12.786385],
                [107.8562883, 12.7854442],
                [107.8559582, 12.7851095],
                [107.8556344, 12.7847689],
                [107.8551542, 12.7842396],
                [107.8549985, 12.7840509],
                [107.8548501, 12.7838567],
                [107.8547093, 12.7836571],
                [107.8545762, 12.7834526],
                [107.8544511, 12.7832434],
                [107.854334, 12.7830297],
                [107.8542252, 12.7828119],
                [107.8541249, 12.7825903],
                [107.8540331, 12.7823653],
                [107.85395, 12.782137],
                [107.8538758, 12.7819058],
                [107.8538104, 12.7816721],
                [107.853754, 12.7814362],
                [107.8537068, 12.7811984],
                [107.8536686, 12.780959],
                [107.8536397, 12.7807185],
                [107.85362, 12.780477],
                [107.8536089, 12.7801982],
                [107.8536124, 12.7796402],
                [107.8536505, 12.7790835],
                [107.8536824, 12.7788062],
                [107.853772, 12.7782552],
                [107.8538955, 12.7777104],
                [107.8540524, 12.7771738],
                [107.8541433, 12.7769092],
                [107.8542423, 12.7766475],
                [107.8544566, 12.7761501],
                [107.8547004, 12.7756659],
                [107.8548331, 12.7754292],
                [107.8551195, 12.7749677],
                [107.855273, 12.7747433],
                [107.8555852, 12.7743267],
                [107.8558689, 12.7739191],
                [107.8561244, 12.7734942],
                [107.8563506, 12.7730538],
                [107.856543, 12.772609],
                [107.8566247, 12.7723863],
                [107.8566975, 12.7721607],
                [107.8567612, 12.7719325],
                [107.8568157, 12.771702],
                [107.856861, 12.7714697],
                [107.8568969, 12.7712358],
                [107.8569234, 12.7710007],
                [107.8569406, 12.7707647],
                [107.8569483, 12.7705283],
                [107.8569465, 12.7702918],
                [107.8569213, 12.7698861],
                [107.8568608, 12.7693795],
                [107.8568099, 12.7690432],
                [107.8566809, 12.768375],
                [107.8566029, 12.7680436],
                [107.8564203, 12.7673874],
                [107.8561799, 12.7666225],
                [107.8559697, 12.7658492],
                [107.8559099, 12.7656051],
                [107.8558596, 12.7653589],
                [107.8558186, 12.7651111],
                [107.855787, 12.7648619],
                [107.855765, 12.7646118],
                [107.8557525, 12.7643611],
                [107.8557496, 12.7641101],
                [107.8557562, 12.7638592],
                [107.8557723, 12.7636086],
                [107.8558232, 12.7631738],
                [107.8558579, 12.7629902],
                [107.855902, 12.7628085],
                [107.8559553, 12.7626291],
                [107.8560177, 12.7624526],
                [107.8560891, 12.7622794],
                [107.8561693, 12.7621098],
                [107.8562581, 12.7619444],
                [107.8563552, 12.7617835],
                [107.8564604, 12.7616275],
                [107.8565735, 12.7614769],
                [107.8566942, 12.761332],
                [107.8568222, 12.7611931],
                [107.8569571, 12.7610606],
                [107.8570986, 12.7609349],
                [107.8575985, 12.7605526],
                [107.8579282, 12.7602627],
                [107.8582334, 12.7599484],
                [107.8585122, 12.7596116],
                [107.8587629, 12.7592543],
                [107.8590909, 12.7587055],
                [107.859332, 12.758359],
                [107.8595992, 12.7580313],
                [107.8597422, 12.757875],
                [107.8599901, 12.7576297],
                [107.8600823, 12.757529],
                [107.8601672, 12.7574224],
                [107.8602445, 12.7573104],
                [107.8603138, 12.7571934],
                [107.8603747, 12.7570722],
                [107.860427, 12.7569472],
                [107.8604997, 12.75671],
                [107.8605807, 12.7564991],
                [107.8606903, 12.756301],
                [107.8608319, 12.7561135],
                [107.8609997, 12.755948],
                [107.8611904, 12.755808],
                [107.8613999, 12.7556964],
                [107.8615103, 12.755652],
                [107.8617243, 12.7555905],
                [107.8623644, 12.755484],
                [107.8624916, 12.7554533],
                [107.8626161, 12.7554136],
                [107.8627374, 12.7553652],
                [107.8628547, 12.7553083],
                [107.8629675, 12.7552433],
                [107.8630752, 12.7551705],
                [107.8631771, 12.7550903],
                [107.8632728, 12.7550031],
                [107.8634767, 12.7547847],
                [107.8635425, 12.7547308],
                [107.8636879, 12.7546416],
                [107.8637663, 12.7546073],
                [107.8639312, 12.7545604],
                [107.864228, 12.7545334],
                [107.8646144, 12.7544568],
                [107.864843, 12.7544209],
                [107.8649276, 12.7543995],
                [107.8650092, 12.754369],
                [107.8650868, 12.7543299],
                [107.8651595, 12.7542826],
                [107.8652263, 12.7542278],
                [107.8652866, 12.754166],
                [107.8653394, 12.7540982],
                [107.8654165, 12.7539572],
                [107.8654599, 12.7538141],
                [107.8654708, 12.7537401],
                [107.86547, 12.7535908],
                [107.8654533, 12.7534348],
                [107.8654681, 12.7532787],
                [107.8655138, 12.7531284],
                [107.8655478, 12.7530572],
                [107.8655888, 12.7529896],
                [107.8656834, 12.7528745],
                [107.8658399, 12.7527337],
                [107.8659508, 12.7526476],
                [107.8660673, 12.7525686],
                [107.8664092, 12.7523745],
                [107.8665113, 12.752301],
                [107.8666073, 12.75222],
                [107.8668164, 12.752021],
                [107.8670442, 12.7518425],
                [107.8671645, 12.7517615],
                [107.8673788, 12.7516365],
                [107.8675692, 12.7515569],
                [107.8676683, 12.7515274],
                [107.8678719, 12.75149],
                [107.8680537, 12.7514813],
                [107.8681318, 12.7514885],
                [107.8682835, 12.7515269],
                [107.8683553, 12.7515577],
                [107.8684869, 12.7516408],
                [107.8685452, 12.7516921],
                [107.8686842, 12.7518511],
                [107.8687364, 12.7518936],
                [107.8687937, 12.7519296],
                [107.8689749, 12.7520031],
                [107.8690985, 12.7520716],
                [107.8692196, 12.7521677],
                [107.8693217, 12.7522828],
                [107.8696114, 12.7529106],
                [107.8703248, 12.7526229],
                [107.8707223, 12.7524758],
                [107.8715245, 12.752201],
                [107.871929, 12.7520735],
                [107.8727442, 12.751838],
                [107.8742963, 12.7514398],
                [107.8746082, 12.751357],
                [107.8747997, 12.7512977],
                [107.8751742, 12.7511562],
                [107.8756714, 12.7509286],
                [107.8758345, 12.7508689],
                [107.8760008, 12.7508182],
                [107.8761697, 12.7507766],
                [107.8763407, 12.7507443],
                [107.8765134, 12.7507214],
                [107.876687, 12.7507079],
                [107.8769251, 12.7507049],
                [107.8771629, 12.7506927],
                [107.8774001, 12.7506716],
                [107.8776362, 12.7506414],
                [107.8778709, 12.7506022],
                [107.8781038, 12.7505541],
                [107.8783347, 12.7504972],
                [107.8785631, 12.7504316],
                [107.8787887, 12.7503572],
                [107.8790111, 12.7502744],
                [107.8792301, 12.7501831],
                [107.8794452, 12.7500836],
                [107.8796562, 12.7499759],
                [107.8798627, 12.7498603],
                [107.8801683, 12.7496692],
                [107.8804789, 12.7494861],
                [107.8807944, 12.7493111],
                [107.8811146, 12.7491444],
                [107.8814393, 12.7489861],
                [107.8817682, 12.7488363],
                [107.8821011, 12.7486952],
                [107.8827525, 12.748448],
                [107.8833904, 12.7482414],
                [107.884038, 12.7480658],
                [107.8846937, 12.7479217],
                [107.8850241, 12.7478615],
                [107.8853559, 12.7478093],
                [107.9056549, 12.7442514],
                [107.9123497, 12.7344041],
                [107.9112237, 12.7333413],
                [107.9106524, 12.7328184],
                [107.9094933, 12.7317899],
                [107.9083129, 12.7307849],
                [107.9071012, 12.7297954],
                [107.9058581, 12.7288225],
                [107.9052288, 12.7283456],
                [107.903955, 12.7274112],
                [107.9026616, 12.7265029],
                [107.9014142, 12.7256878],
                [107.9007982, 12.7252691],
                [107.9001876, 12.7248431],
                [107.8993706, 12.7242556],
                [107.8991646, 12.724094],
                [107.8989648, 12.7239252],
                [107.8987714, 12.7237495],
                [107.8985846, 12.7235671],
                [107.8984046, 12.7233783],
                [107.8982318, 12.7231832],
                [107.8980663, 12.7229822],
                [107.8979084, 12.7227755],
                [107.8977582, 12.7225633],
                [107.897616, 12.722346],
                [107.8974819, 12.7221238],
                [107.8973561, 12.7218971],
                [107.8972388, 12.721666],
                [107.8971301, 12.721431],
                [107.8970302, 12.7211923],
                [107.8969392, 12.7209502],
                [107.8968572, 12.7207051],
                [107.8968031, 12.7205243],
                [107.896659, 12.719977],
                [107.8965726, 12.7196099],
                [107.8964256, 12.7188704],
                [107.896313, 12.7181251],
                [107.8962351, 12.7173757],
                [107.8962092, 12.7169998],
                [107.8961686, 12.7159989],
                [107.8961121, 12.7153764],
                [107.8960226, 12.7147575],
                [107.8959656, 12.7144499],
                [107.8959005, 12.7141439],
                [107.8957852, 12.7136791],
                [107.8957272, 12.7133526],
                [107.8957047, 12.713022],
                [107.895718, 12.7126909],
                [107.8957668, 12.712363],
                [107.8958044, 12.7122013],
                [107.8959054, 12.711885],
                [107.8959686, 12.7117311],
                [107.8961193, 12.7114342],
                [107.8962065, 12.7112919],
                [107.8964032, 12.7110218],
                [107.8965122, 12.7108947],
                [107.89675, 12.710658],
                [107.8970715, 12.710379],
                [107.897372, 12.7100784],
                [107.8976499, 12.7097578],
                [107.8979112, 12.7094082],
                [107.8980502, 12.7092314],
                [107.8981966, 12.7090605],
                [107.8983503, 12.7088957],
                [107.8985109, 12.7087374],
                [107.8986782, 12.7085857],
                [107.8988518, 12.708441],
                [107.8990315, 12.7083036],
                [107.899217, 12.7081736],
                [107.8994079, 12.7080513],
                [107.8999836, 12.7077255],
                [107.9003445, 12.7074846],
                [107.9006841, 12.707216],
                [107.9008452, 12.7070717],
                [107.9011488, 12.7067647],
                [107.9013511, 12.7065291],
                [107.9017376, 12.7060437],
                [107.9020212, 12.705675],
                [107.9022973, 12.7053009],
                [107.9025658, 12.7049216],
                [107.9028268, 12.7045373],
                [107.9032822, 12.7038248],
                [107.9037085, 12.7031916],
                [107.9041634, 12.7025775],
                [107.9046457, 12.7019837],
                [107.9051547, 12.7014115],
                [107.9054189, 12.7011339],
                [107.9059552, 12.7006061],
                [107.9064845, 12.7000716],
                [107.9070067, 12.6995306],
                [107.9075217, 12.698983],
                [107.9080291, 12.6984294],
                [107.9085291, 12.6978695],
                [107.9090398, 12.6973189],
                [107.9117972, 12.6944981],
                [107.9119827, 12.6942564],
                [107.9121387, 12.6939955],
                [107.9122632, 12.6937189],
                [107.9123131, 12.6935759],
                [107.9123873, 12.6932827],
                [107.9124114, 12.6931334],
                [107.9124331, 12.6928322],
                [107.9124194, 12.6925304],
                [107.9123729, 12.6922427],
                [107.9122941, 12.6919617],
                [107.9121841, 12.691691],
                [107.9120441, 12.6914337],
                [107.9118587, 12.6911662],
                [107.9116695, 12.6908637],
                [107.9115005, 12.6905287],
                [107.9114247, 12.690347],
                [107.9113582, 12.690162],
                [107.911301, 12.6899739],
                [107.9111065, 12.6892327],
                [107.9109467, 12.6886852],
                [107.910902, 12.6885037],
                [107.9108664, 12.6883202],
                [107.9108253, 12.6879782],
                [107.9108167, 12.6878062],
                [107.9108208, 12.6873774],
                [107.9107884, 12.6872117],
                [107.9107591, 12.6871322],
                [107.9106941, 12.6870108],
                [107.9106089, 12.6869019],
                [107.9104681, 12.6867826],
                [107.9101701, 12.6865639],
                [107.9100136, 12.686465],
                [107.9098526, 12.6863733],
                [107.9095264, 12.686216],
                [107.9091869, 12.6860882],
                [107.909013, 12.6860358],
                [107.9088691, 12.6859691],
                [107.9088022, 12.6859268],
                [107.9086877, 12.6858318],
                [107.9085962, 12.6857269],
                [107.9085571, 12.6856694],
                [107.9081446, 12.6849069],
                [107.9080615, 12.6847373],
                [107.9079181, 12.6843884],
                [107.907806, 12.6840287],
                [107.9077298, 12.6836818],
                [107.9077025, 12.6835064],
                [107.9076822, 12.6830718],
                [107.9076655, 12.6829792],
                [107.9076102, 12.6827909],
                [107.9075244, 12.6826052],
                [107.9072615, 12.6821918],
                [107.9070754, 12.6819613],
                [107.906975, 12.681852],
                [107.9067533, 12.6816399],
                [107.9066318, 12.6815381],
                [107.9063754, 12.6813509],
                [107.9061028, 12.6811869],
                [107.9060662, 12.6811569],
                [107.9060067, 12.681084],
                [107.9059687, 12.6809985],
                [107.9059551, 12.6808934],
                [107.9059741, 12.6807892],
                [107.9060888, 12.6805622],
                [107.9062384, 12.6803403],
                [107.906328, 12.680232],
                [107.9065604, 12.6799943],
                [107.9066195, 12.6799191],
                [107.9067183, 12.679756],
                [107.9067573, 12.6796692],
                [107.906825, 12.6794462],
                [107.9068592, 12.6792161],
                [107.9068619, 12.6789234],
                [107.9068316, 12.6787114],
                [107.9067729, 12.6785051],
                [107.9067331, 12.6784053],
                [107.9065098, 12.6781148],
                [107.9063906, 12.6779753],
                [107.906142, 12.6777133],
                [107.9060132, 12.6775907],
                [107.9056685, 12.6772947],
                [107.9055985, 12.6772258],
                [107.9054747, 12.6770742],
                [107.9054216, 12.6769922],
                [107.9053253, 12.6767963],
                [107.9052896, 12.6766933],
                [107.9052626, 12.6765879],
                [107.9051163, 12.6758238],
                [107.9050983, 12.6757588],
                [107.9050715, 12.6756969],
                [107.9050361, 12.6756385],
                [107.9049929, 12.6755854],
                [107.9046704, 12.6752866],
                [107.9046209, 12.6752283],
                [107.9045793, 12.6751645],
                [107.9045462, 12.6750962],
                [107.904522, 12.6750243],
                [107.9045073, 12.6749501],
                [107.9045022, 12.6748746],
                [107.9045067, 12.6747991],
                [107.9045209, 12.6747248],
                [107.9045445, 12.6746527],
                [107.9046297, 12.6744648],
                [107.9046829, 12.6742659],
                [107.9047027, 12.6740613],
                [107.9046898, 12.6738635],
                [107.9046456, 12.6736701],
                [107.9046121, 12.6735764],
                [107.9042476, 12.6728447],
                [107.9042259, 12.6727284],
                [107.9042381, 12.672613],
                [107.9042814, 12.6725073],
                [107.9043138, 12.6724598],
                [107.9047868, 12.6720405],
                [107.9050307, 12.6718391],
                [107.9055597, 12.6714336],
                [107.9061366, 12.6710357],
                [107.9066163, 12.6707337],
                [107.9067868, 12.6706018],
                [107.9068666, 12.6705296],
                [107.9070143, 12.6703737],
                [107.9074876, 12.66983],
                [107.9084343, 12.6687425],
                [107.9087445, 12.6684474],
                [107.9089078, 12.6683084],
                [107.9092432, 12.6680526],
                [107.9095902, 12.6678249],
                [107.9099529, 12.6676217],
                [107.9106246, 12.6673065],
                [107.9108612, 12.6671835],
                [107.9113217, 12.6669155],
                [107.9117665, 12.6666167],
                [107.9121933, 12.6662877],
                [107.9123981, 12.6661132],
                [107.9127896, 12.6657448],
                [107.9129303, 12.6656209],
                [107.9130644, 12.6654902],
                [107.9131916, 12.665353],
                [107.9133115, 12.6652096],
                [107.9134237, 12.6650606],
                [107.9135281, 12.6649062],
                [107.9136244, 12.6647468],
                [107.9137821, 12.664436],
                [107.9139014, 12.6641339],
                [107.9139506, 12.6639793],
                [107.9140278, 12.6636644],
                [107.9140556, 12.6635047],
                [107.9143506, 12.6609977],
                [107.9144552, 12.6603513],
                [107.9145169, 12.6600213],
                [107.9145839, 12.6596925],
                [107.9147157, 12.6591192],
                [107.9147313, 12.6589939],
                [107.9147373, 12.6588679],
                [107.9147338, 12.6587417],
                [107.9147208, 12.6586162],
                [107.9146689, 12.6583781],
                [107.9145829, 12.6581496],
                [107.9145276, 12.6580404],
                [107.913959, 12.6572449],
                [107.9138193, 12.6570832],
                [107.9136725, 12.6569275],
                [107.913519, 12.6567781],
                [107.913359, 12.6566353],
                [107.9131929, 12.6564994],
                [107.913021, 12.6563706],
                [107.9128435, 12.6562491],
                [107.9126608, 12.6561352],
                [107.9123026, 12.6559414],
                [107.9121177, 12.6558553],
                [107.911738, 12.6557055],
                [107.911347, 12.6555864],
                [107.9089165, 12.6550099],
                [107.9084614, 12.6548819],
                [107.9080176, 12.6547206],
                [107.9076022, 12.6545343],
                [107.907216, 12.6543272],
                [107.9068458, 12.654094],
                [107.9056281, 12.6530824],
                [107.9055022, 12.6529647],
                [107.9052667, 12.6527137],
                [107.9051575, 12.6525811],
                [107.9049571, 12.6523026],
                [107.9048663, 12.6521573],
                [107.9046995, 12.6518456],
                [107.9046269, 12.6516846],
                [107.9044614, 12.6512358],
                [107.9044005, 12.6509926],
                [107.9043734, 12.6507437],
                [107.9043728, 12.6506185],
                [107.9043949, 12.6503841],
                [107.904447, 12.6501543],
                [107.9045284, 12.6499328],
                [107.9050755, 12.6488127],
                [107.9051807, 12.6486244],
                [107.9054088, 12.6482578],
                [107.9055315, 12.6480799],
                [107.9056819, 12.6478768],
                [107.9058398, 12.6476792],
                [107.9060049, 12.6474873],
                [107.906177, 12.6473014],
                [107.9063559, 12.6471217],
                [107.9065414, 12.6469484],
                [107.9067331, 12.6467818],
                [107.9070927, 12.6465811],
                [107.9072783, 12.6464913],
                [107.9076598, 12.6463337],
                [107.9080528, 12.646206],
                [107.9084645, 12.6461072],
                [107.908883, 12.6460413],
                [107.9093056, 12.6460088],
                [107.9097295, 12.6460097],
                [107.9102061, 12.6460462],
                [107.9104702, 12.6460772],
                [107.9109951, 12.6461625],
                [107.9112554, 12.6462165],
                [107.9117703, 12.6463473],
                [107.912431, 12.6465487],
                [107.9137454, 12.6469727],
                [107.9149407, 12.6473859],
                [107.9161952, 12.6478451],
                [107.9163745, 12.6478999],
                [107.9165564, 12.6479456],
                [107.9167405, 12.6479822],
                [107.9169263, 12.6480095],
                [107.9171133, 12.6480275],
                [107.9172976, 12.6480361],
                [107.9174821, 12.6480357],
                [107.9176664, 12.6480262],
                [107.9178499, 12.6480077],
                [107.9180323, 12.6479802],
                [107.918213, 12.6479438],
                [107.9184799, 12.647864],
                [107.91861, 12.6478147],
                [107.9188621, 12.6476978],
                [107.919001, 12.64762],
                [107.9192644, 12.6474424],
                [107.9195058, 12.6472372],
                [107.9203749, 12.6460542],
                [107.9204733, 12.6458931],
                [107.9205635, 12.6457275],
                [107.9206452, 12.6455577],
                [107.9207795, 12.645216],
                [107.9208776, 12.644872],
                [107.9210217, 12.6440958],
                [107.9211723, 12.6433982],
                [107.9212067, 12.6431801],
                [107.9212493, 12.6427409],
                [107.9212586, 12.6423509],
                [107.9212439, 12.641961],
                [107.9212254, 12.641745],
                [107.9211664, 12.6413156],
                [107.9211259, 12.6411025],
                [107.9204714, 12.6387366],
                [107.9203866, 12.6385043],
                [107.9201934, 12.6380486],
                [107.9199821, 12.6376298],
                [107.9197581, 12.6372472],
                [107.9196375, 12.637061],
                [107.919333, 12.6366231],
                [107.9191857, 12.6363497],
                [107.9190713, 12.6360618],
                [107.9190228, 12.6358786],
                [107.9190065, 12.6356902],
                [107.919023, 12.6355017],
                [107.9190744, 12.6353117],
                [107.9191589, 12.6351334],
                [107.9192742, 12.6349723],
                [107.9193422, 12.6348997],
                [107.9201791, 12.6342873],
                [107.9203742, 12.6341861],
                [107.9207257, 12.6340613],
                [107.9208454, 12.6340075],
                [107.921073, 12.6338779],
                [107.92118, 12.6338027],
                [107.921378, 12.633633],
                [107.921473, 12.6335339],
                [107.9215609, 12.6334287],
                [107.9216413, 12.633318],
                [107.9217138, 12.6332022],
                [107.9217781, 12.633082],
                [107.921834, 12.6329577],
                [107.9226806, 12.6302141],
                [107.922728, 12.6300256],
                [107.9227962, 12.6296617],
                [107.9228337, 12.6293121],
                [107.9228425, 12.6291365],
                [107.9227751, 12.6283122],
                [107.9226713, 12.6274515],
                [107.9226035, 12.6270031],
                [107.9225264, 12.6265562],
                [107.922403, 12.6259133],
                [107.9223565, 12.6257178],
                [107.9222491, 12.6253708],
                [107.9221283, 12.6250696],
                [107.9220593, 12.6249225],
                [107.9207799, 12.6226559],
                [107.9206833, 12.6224346],
                [107.9205957, 12.6222097],
                [107.9205172, 12.6219816],
                [107.920448, 12.6217507],
                [107.9203526, 12.621355],
                [107.9203071, 12.621026],
                [107.9202966, 12.6206942],
                [107.9203212, 12.6203631],
                [107.9203433, 12.6202173],
                [107.9203746, 12.6200731],
                [107.9204149, 12.6199311],
                [107.920464, 12.6197918],
                [107.9205218, 12.6196556],
                [107.9205881, 12.6195232],
                [107.9206626, 12.6193951],
                [107.920745, 12.6192716],
                [107.9208346, 12.6191538],
                [107.9210352, 12.6189354],
                [107.9211453, 12.6188355],
                [107.9213834, 12.6186565],
                [107.92171, 12.6185124],
                [107.9218781, 12.6184517],
                [107.9222221, 12.6183535],
                [107.9223973, 12.6183162],
                [107.9227417, 12.6182669],
                [107.9230893, 12.6182482],
                [107.925571, 12.6183483],
                [107.9259529, 12.6183409],
                [107.9263328, 12.6183028],
                [107.9267083, 12.6182342],
                [107.9270767, 12.6181357],
                [107.9273661, 12.6180352],
                [107.9275756, 12.617937],
                [107.9276758, 12.6178794],
                [107.9278672, 12.6177464],
                [107.9280435, 12.6175912],
                [107.9282002, 12.6174172],
                [107.9289679, 12.6163306],
                [107.9290525, 12.616166],
                [107.9290898, 12.6160711],
                [107.9291805, 12.6157793],
                [107.9292785, 12.6153844],
                [107.929349, 12.6149588],
                [107.9293722, 12.6147319],
                [107.9293857, 12.6145043],
                [107.9293893, 12.6142763],
                [107.9293832, 12.6140484],
                [107.9293672, 12.613821],
                [107.9293415, 12.6135944],
                [107.929324, 12.613299],
                [107.9292834, 12.6130043],
                [107.9286146, 12.609642],
                [107.9280002, 12.6058155],
                [107.9279537, 12.6053425],
                [107.9279387, 12.6048676],
                [107.9279548, 12.6044008],
                [107.9280013, 12.603936],
                [107.9280782, 12.603475],
                [107.9284049, 12.6020285],
                [107.9287499, 12.6004498],
                [107.9288292, 12.6001956],
                [107.9288803, 12.6000724],
                [107.9290045, 12.5998358],
                [107.9291565, 12.5996153],
                [107.929341, 12.5994069],
                [107.9294463, 12.5993082],
                [107.9295579, 12.5992162],
                [107.9297978, 12.5990539],
                [107.9299252, 12.5989842],
                [107.9300569, 12.5989226],
                [107.9301923, 12.5988692],
                [107.930331, 12.5988244],
                [107.9304722, 12.5987882],
                [107.9306156, 12.5987608],
                [107.9325946, 12.5984983],
                [107.9329845, 12.5984389],
                [107.9335109, 12.5983442],
                [107.9336469, 12.5983088],
                [107.9337801, 12.5982645],
                [107.93391, 12.5982117],
                [107.934036, 12.5981505],
                [107.9341576, 12.5980813],
                [107.9342741, 12.5980042],
                [107.9343873, 12.5979179],
                [107.9344942, 12.5978243],
                [107.9345943, 12.5977238],
                [107.9346871, 12.5976168],
                [107.9348269, 12.5973329],
                [107.934935, 12.5970361],
                [107.9350115, 12.5967231],
                [107.935037, 12.5965608],
                [107.9350532, 12.5963973],
                [107.9350601, 12.5962332],
                [107.9350577, 12.596069],
                [107.935046, 12.5959051],
                [107.935025, 12.5957421],
                [107.9349948, 12.5955806],
                [107.9349554, 12.5954209],
                [107.9349071, 12.5952635],
                [107.9340307, 12.5927729],
                [107.9339396, 12.5924426],
                [107.9339043, 12.592275],
                [107.9338543, 12.5919365],
                [107.9338389, 12.5917531],
                [107.9338324, 12.5913854],
                [107.9338413, 12.5912016],
                [107.9338832, 12.5908361],
                [107.9339571, 12.5904755],
                [107.9341371, 12.5898032],
                [107.9341767, 12.5896113],
                [107.934236, 12.5892245],
                [107.9342685, 12.5888351],
                [107.9342746, 12.5886398],
                [107.9342728, 12.5881912],
                [107.9342472, 12.5876853],
                [107.9341995, 12.5872431],
                [107.9341416, 12.5868398],
                [107.9341085, 12.5866577],
                [107.9340154, 12.5862869],
                [107.9338852, 12.5859142],
                [107.9337155, 12.5855225],
                [107.9335552, 12.5850937],
                [107.9334427, 12.5847165],
                [107.9333342, 12.5842524],
                [107.9332777, 12.5840956],
                [107.9332041, 12.5839545],
                [107.9330791, 12.5837643],
                [107.9328981, 12.5835204],
                [107.9326435, 12.5832309],
                [107.9323627, 12.5829654],
                [107.9322132, 12.5828425],
                [107.9318976, 12.5826173],
                [107.9297841, 12.581455],
                [107.9293517, 12.5812651],
                [107.9289075, 12.5811035],
                [107.9284532, 12.580971],
                [107.928223, 12.5809157],
                [107.9277632, 12.5808286],
                [107.9275313, 12.580796],
                [107.927065, 12.5807532],
                [107.9268309, 12.580743],
                [107.9234111, 12.5807665],
                [107.922788, 12.5807311],
                [107.9224773, 12.5807026],
                [107.9218583, 12.5806239],
                [107.9212439, 12.5805165],
                [107.9208988, 12.5804432],
                [107.9205559, 12.5803607],
                [107.9202154, 12.5802693],
                [107.9198776, 12.580169],
                [107.9195426, 12.5800599],
                [107.9192108, 12.5799419],
                [107.9181055, 12.5794226],
                [107.9179623, 12.5793667],
                [107.9176905, 12.5792849],
                [107.9174352, 12.5792351],
                [107.9158415, 12.5790647],
                [107.9157099, 12.5790341],
                [107.9156283, 12.5789988],
                [107.9155246, 12.5789377],
                [107.9154672, 12.5788904],
                [107.9153497, 12.5787594],
                [107.9151982, 12.5786225],
                [107.9151113, 12.578563],
                [107.9150191, 12.5785114],
                [107.9149226, 12.5784683],
                [107.9138195, 12.5780597],
                [107.9136999, 12.5779932],
                [107.9136452, 12.5779524],
                [107.9135487, 12.5778579],
                [107.9135073, 12.5778047],
                [107.9132014, 12.5772552],
                [107.9130771, 12.5770657],
                [107.9130056, 12.5769774],
                [107.9128454, 12.5768157],
                [107.9127574, 12.5767429],
                [107.9126541, 12.576669],
                [107.9125455, 12.5766026],
                [107.9124322, 12.5765443],
                [107.9123148, 12.5764942],
                [107.9111212, 12.5761133],
                [107.9107938, 12.5760314],
                [107.9106275, 12.5760013],
                [107.9102966, 12.5759634],
                [107.9099684, 12.5759543],
                [107.909708, 12.5759603],
                [107.9096122, 12.5759524],
                [107.9094273, 12.575914],
                [107.9092544, 12.5758476],
                [107.9086751, 12.5755727],
                [107.9084843, 12.5754538],
                [107.9083086, 12.5753144],
                [107.9081498, 12.5751559],
                [107.9080101, 12.5749803],
                [107.907882, 12.5747795],
                [107.9077343, 12.5745747],
                [107.9076448, 12.574468],
                [107.9074043, 12.5742193],
                [107.9073286, 12.5741093],
                [107.9072147, 12.5738745],
                [107.9071485, 12.5737618],
                [107.906999, 12.5735474],
                [107.9069146, 12.5734475],
                [107.9067264, 12.5732651],
                [107.9065118, 12.573106],
                [107.9062772, 12.5729765],
                [107.9060623, 12.5728822],
                [107.9059739, 12.5728352],
                [107.9058073, 12.5727247],
                [107.9056424, 12.572579],
                [107.9055617, 12.5724898],
                [107.9054886, 12.5723946],
                [107.9051051, 12.5718135],
                [107.9050158, 12.5716965],
                [107.9049192, 12.5715852],
                [107.9048156, 12.5714801],
                [107.9045414, 12.5712403],
                [107.9043996, 12.5710778],
                [107.9042925, 12.5709148],
                [107.9042078, 12.5707397],
                [107.9041564, 12.5706081],
                [107.9041019, 12.5705385],
                [107.9040292, 12.5704904],
                [107.903988, 12.5704754],
                [107.9038348, 12.5704612],
                [107.9037065, 12.5704309],
                [107.9035836, 12.570369],
                [107.9035264, 12.5703255],
                [107.9034754, 12.5702753],
                [107.9034314, 12.5702191],
                [107.9032557, 12.5699403],
                [107.9032014, 12.5698754],
                [107.9030773, 12.5697597],
                [107.9029219, 12.5696423],
                [107.9028493, 12.5695778],
                [107.9027582, 12.569486],
                [107.902674, 12.5693881],
                [107.9025972, 12.5692846],
                [107.902461, 12.5691608],
                [107.9023851, 12.5691081],
                [107.9022273, 12.5690257],
                [107.9020645, 12.5689719],
                [107.9004031, 12.568685],
                [107.9002564, 12.568626],
                [107.9001387, 12.5685492],
                [107.9000491, 12.5684651],
                [107.8998131, 12.5681667],
                [107.8995976, 12.5679361],
                [107.8994834, 12.5678267],
                [107.8993126, 12.5676778],
                [107.8991938, 12.5676095],
                [107.8991291, 12.5675859],
                [107.8990047, 12.5675623],
                [107.898716, 12.5675567],
                [107.8984629, 12.5675769],
                [107.8982131, 12.5676222],
                [107.8980518, 12.5676342],
                [107.89789, 12.5676381],
                [107.8975715, 12.5676214],
                [107.8972607, 12.5675736],
                [107.8971076, 12.5675382],
                [107.8966413, 12.5674115],
                [107.8961279, 12.5673138],
                [107.8959438, 12.5672468],
                [107.8957736, 12.5671509],
                [107.8956884, 12.5670873],
                [107.8956094, 12.5670163],
                [107.8955373, 12.5669386],
                [107.8954729, 12.5668548],
                [107.8954165, 12.5667657],
                [107.8953085, 12.5665517],
                [107.8952399, 12.5664359],
                [107.8951633, 12.5663251],
                [107.895079, 12.5662197],
                [107.8949873, 12.5661204],
                [107.8948888, 12.5660274],
                [107.8947839, 12.5659414],
                [107.8945817, 12.5658059],
                [107.8943637, 12.565696],
                [107.8941334, 12.5656134],
                [107.8940148, 12.5655828],
                [107.8938159, 12.5654999],
                [107.8936318, 12.5653894],
                [107.8935465, 12.5653244],
                [107.8933918, 12.565177],
                [107.8933184, 12.565089],
                [107.8932524, 12.5649956],
                [107.8931942, 12.5648974],
                [107.8931442, 12.564795],
                [107.8931027, 12.564689],
                [107.8928903, 12.5639544],
                [107.8928719, 12.5637858],
                [107.8928849, 12.5636167],
                [107.8929268, 12.5634587],
                [107.8929581, 12.563383],
                [107.8931183, 12.5630977],
                [107.8931891, 12.562951],
                [107.893309, 12.5626486],
                [107.8933579, 12.5624936],
                [107.8933992, 12.5623365],
                [107.8934529, 12.5620593],
                [107.893483, 12.5617788],
                [107.8934698, 12.5616859],
                [107.8934321, 12.5615995],
                [107.8933747, 12.5615278],
                [107.8933395, 12.5614978],
                [107.8930056, 12.5613091],
                [107.8927247, 12.561122],
                [107.8924602, 12.5609123],
                [107.8922144, 12.5606819],
                [107.8921688, 12.5606278],
                [107.8921312, 12.5605683],
                [107.8921023, 12.5605043],
                [107.8920825, 12.560437],
                [107.8920722, 12.5603677],
                [107.892076, 12.5602561],
                [107.8921044, 12.5601478],
                [107.8922278, 12.5599096],
                [107.8923043, 12.5597869],
                [107.8924801, 12.5595566],
                [107.8925786, 12.5594499],
                [107.8927811, 12.5592665],
                [107.8929889, 12.5591171],
                [107.8936733, 12.5587335],
                [107.8941192, 12.5584518],
                [107.8945693, 12.5581309],
                [107.8950195, 12.5577681],
                [107.8952359, 12.5575769],
                [107.8954681, 12.5572625],
                [107.8956754, 12.556932],
                [107.8958568, 12.5565873],
                [107.8960108, 12.5562312],
                [107.8960775, 12.5560492],
                [107.8961896, 12.5556785],
                [107.8962375, 12.5554714],
                [107.8962538, 12.5552597],
                [107.8962384, 12.5550479],
                [107.8962189, 12.5549434],
                [107.8960204, 12.5543043],
                [107.895938, 12.5540018],
                [107.8958648, 12.5536969],
                [107.89575, 12.5531193],
                [107.8957232, 12.5530306],
                [107.8956449, 12.5528624],
                [107.8955942, 12.5527844],
                [107.8954789, 12.5526506],
                [107.8953499, 12.5525443],
                [107.8951004, 12.552401],
                [107.8942072, 12.5520397],
                [107.8939309, 12.5519433],
                [107.8934479, 12.5518229],
                [107.8929809, 12.551676],
                [107.8927548, 12.5515936],
                [107.8924047, 12.5514459],
                [107.8922348, 12.5513612],
                [107.8920688, 12.5512694],
                [107.8918318, 12.5511217],
                [107.8916377, 12.550982],
                [107.8911372, 12.5505894],
                [107.8904659, 12.5500974],
                [107.8900804, 12.5497743],
                [107.8895462, 12.5492691],
                [107.8890791, 12.5488138],
                [107.8888453, 12.548607],
                [107.8886828, 12.5484394],
                [107.8885424, 12.5482539],
                [107.8883804, 12.5479988],
                [107.8880812, 12.5476023],
                [107.8880321, 12.5475188],
                [107.8879573, 12.5473487],
                [107.8878806, 12.5470928],
                [107.8878385, 12.5469199],
                [107.8877884, 12.5466361],
                [107.8877718, 12.546493],
                [107.88772, 12.5458117],
                [107.8877159, 12.5452725],
                [107.8877277, 12.5450032],
                [107.8877788, 12.5444663],
                [107.8878181, 12.5441994],
                [107.8879758, 12.5433954],
                [107.8880535, 12.5428516],
                [107.8880993, 12.5423044],
                [107.8881103, 12.5420301],
                [107.8881152, 12.5413626],
                [107.8881475, 12.5409709],
                [107.8881749, 12.5407762],
                [107.8882528, 12.540388],
                [107.8883033, 12.5401951],
                [107.8884267, 12.5398156],
                [107.8884834, 12.5397081],
                [107.8885572, 12.5396204],
                [107.8886001, 12.5395851],
                [107.8887248, 12.5395144],
                [107.8888093, 12.5394756],
                [107.8891339, 12.539354],
                [107.8893006, 12.5393059],
                [107.8896407, 12.5392354],
                [107.8899865, 12.5392001],
                [107.8901603, 12.5391957],
                [107.8903341, 12.5392002],
                [107.8908533, 12.539238],
                [107.8913739, 12.5392403],
                [107.8916339, 12.539228],
                [107.8921519, 12.5391769],
                [107.8924092, 12.539138],
                [107.8929188, 12.539034],
                [107.8934197, 12.5388955],
                [107.8936661, 12.5388134],
                [107.8941493, 12.5386242],
                [107.8946177, 12.5384025],
                [107.8948457, 12.5382797],
                [107.8952876, 12.5380112],
                [107.8957093, 12.5377131],
                [107.8958803, 12.5375789],
                [107.8962099, 12.5372961],
                [107.8972248, 12.536478],
                [107.8987703, 12.5352866],
                [107.8992634, 12.5348684],
                [107.8995021, 12.5346509],
                [107.8997355, 12.5344278],
                [107.9001072, 12.5340492],
                [107.9004119, 12.5337651],
                [107.9007374, 12.5335039],
                [107.9011964, 12.5331787],
                [107.9013317, 12.5330653],
                [107.9014603, 12.5329447],
                [107.9015818, 12.5328172],
                [107.9016958, 12.5326833],
                [107.9018019, 12.5325434],
                [107.9018998, 12.5323979],
                [107.9021918, 12.531896],
                [107.9027286, 12.531043],
                [107.9029084, 12.5307329],
                [107.9033401, 12.5299283],
                [107.9034782, 12.5296875],
                [107.9036977, 12.5293286],
                [107.903868, 12.5290987],
                [107.903962, 12.5289904],
                [107.9041664, 12.5287886],
                [107.9043892, 12.5286095],
                [107.9045074, 12.5285304],
                [107.9047579, 12.5283946],
                [107.9050238, 12.5282903],
                [107.9051612, 12.5282505],
                [107.9054096, 12.5282008],
                [107.9056226, 12.5281442],
                [107.9058266, 12.528062],
                [107.9059357, 12.5280051],
                [107.9060489, 12.5279564],
                [107.9061656, 12.527916],
                [107.9064064, 12.5278613],
                [107.9067876, 12.52783],
                [107.9072859, 12.5277383],
                [107.9077033, 12.5276902],
                [107.9079262, 12.5276291],
                [107.9081364, 12.5275346],
                [107.9082352, 12.5274755],
                [107.9083638, 12.5273812],
                [107.9086056, 12.5271741],
                [107.908718, 12.5270618],
                [107.9088832, 12.5268734],
                [107.9091942, 12.5264811],
                [107.9095197, 12.5260461],
                [107.9097354, 12.5257876],
                [107.9098517, 12.5256655],
                [107.9100281, 12.5254987],
                [107.9101528, 12.5254183],
                [107.9102922, 12.5253659],
                [107.91044, 12.5253441],
                [107.9105414, 12.5253474],
                [107.9107429, 12.525371],
                [107.9108423, 12.5253913],
                [107.9111663, 12.5254773],
                [107.9114902, 12.5255395],
                [107.9116509, 12.5255619],
                [107.912129, 12.5256085],
                [107.9122827, 12.5256338],
                [107.9124351, 12.5256662],
                [107.9128486, 12.5257788],
                [107.9130437, 12.5258144],
                [107.913103, 12.5258314],
                [107.9132126, 12.5258864],
                [107.9133046, 12.5259665],
                [107.9133758, 12.5260721],
                [107.9134204, 12.5261973],
                [107.9134325, 12.5263294],
                [107.9134114, 12.5264603],
                [107.9133583, 12.5265823],
                [107.9133207, 12.5266376],
                [107.9132528, 12.5267104],
                [107.9131318, 12.5268678],
                [107.9130516, 12.5270014],
                [107.9130207, 12.52711],
                [107.9130247, 12.5272226],
                [107.9130632, 12.5273289],
                [107.913116, 12.5274022],
                [107.9133407, 12.5276253],
                [107.9135571, 12.5277902],
                [107.9137941, 12.5279253],
                [107.9140475, 12.5280284],
                [107.914412, 12.5281277],
                [107.9146365, 12.5282136],
                [107.9148495, 12.5283238],
                [107.9151055, 12.5284777],
                [107.9152397, 12.5285439],
                [107.9155184, 12.5286539],
                [107.915808, 12.5287327],
                [107.9161048, 12.5287794],
                [107.9163943, 12.5287936],
                [107.9165393, 12.5287892],
                [107.9172158, 12.5287969],
                [107.9174575, 12.5288066],
                [107.917891, 12.5288451],
                [107.9184169, 12.5289261],
                [107.9189845, 12.5290425],
                [107.919832, 12.5291934],
                [107.920387, 12.5293262],
                [107.9209328, 12.5294916],
                [107.9213216, 12.5296316],
                [107.9225031, 12.530096],
                [107.9229183, 12.5302517],
                [107.9233369, 12.5303984],
                [107.9237588, 12.530536],
                [107.9243311, 12.530707],
                [107.9244811, 12.5307402],
                [107.9246328, 12.5307641],
                [107.9247859, 12.5307787],
                [107.9249395, 12.5307839],
                [107.9250932, 12.5307797],
                [107.9252463, 12.5307661],
                [107.9253983, 12.5307431],
                [107.9255484, 12.5307108],
                [107.9256962, 12.5306694],
                [107.925841, 12.5306191],
                [107.9259824, 12.5305599],
                [107.9261196, 12.5304923],
                [107.9262522, 12.5304163],
                [107.9263797, 12.5303324],
                [107.9265015, 12.5302408],
                [107.9266172, 12.530142],
                [107.9267263, 12.5300362],
                [107.9270968, 12.5296205],
                [107.9274936, 12.5292286],
                [107.9279151, 12.5288622],
                [107.9283597, 12.5285227],
                [107.9288256, 12.5282115],
                [107.9293107, 12.5279299],
                [107.9298133, 12.527679],
                [107.9303312, 12.5274598],
                [107.9308014, 12.5272928],
                [107.9324665, 12.5267644],
                [107.9331842, 12.5265504],
                [107.9339046, 12.5263455],
                [107.9346276, 12.5261496],
                [107.9353532, 12.5259628],
                [107.9360812, 12.5257851],
                [107.9380395, 12.5253382],
                [107.9386501, 12.5251854],
                [107.9392583, 12.5250235],
                [107.9398638, 12.5248525],
                [107.9404667, 12.5246725],
                [107.9410666, 12.5244835],
                [107.9416635, 12.5242856],
                [107.9422572, 12.5240788],
                [107.9428476, 12.5238632],
                [107.9434346, 12.5236388],
                [107.944018, 12.5234057],
                [107.9445977, 12.5231639],
                [107.9451735, 12.5229135],
                [107.9457453, 12.5226545],
                [107.9463131, 12.522387],
                [107.9468765, 12.5221111],
                [107.9471618, 12.521966],
                [107.9479902, 12.5215342],
                [107.9485401, 12.5212333],
                [107.9492492, 12.5208292],
                [107.9494179, 12.5207421],
                [107.9495907, 12.5206633],
                [107.9497674, 12.520593],
                [107.9499474, 12.5205312],
                [107.9501303, 12.5204783],
                [107.9503156, 12.5204343],
                [107.950503, 12.5203993],
                [107.9506919, 12.5203734],
                [107.9508819, 12.5203566],
                [107.9510725, 12.5203491],
                [107.9512633, 12.5203508],
                [107.9514537, 12.5203617],
                [107.9516434, 12.5203818],
                [107.9522771, 12.5204951],
                [107.9525021, 12.5205232],
                [107.9527281, 12.520542],
                [107.9529547, 12.5205514],
                [107.9531815, 12.5205515],
                [107.9534082, 12.5205421],
                [107.9536342, 12.5205235],
                [107.9538592, 12.5204954],
                [107.9540828, 12.5204582],
                [107.9543045, 12.5204117],
                [107.9545241, 12.520356],
                [107.954741, 12.5202914],
                [107.954955, 12.5202178],
                [107.9551655, 12.5201355],
                [107.9555117, 12.5199773],
                [107.9561916, 12.51961],
                [107.9569933, 12.5191452],
                [107.9577762, 12.5186509],
                [107.9585392, 12.5181277],
                [107.9592778, 12.5175789],
                [107.9596374, 12.5172956],
                [107.9603667, 12.5167418],
                [107.9618397, 12.5156522],
                [107.9633314, 12.5145872],
                [107.9644564, 12.5138086],
                [107.964823, 12.5135456],
                [107.9651838, 12.5132751],
                [107.9655387, 12.5129972],
                [107.9658874, 12.5127119],
                [107.9664659, 12.5122106],
                [107.9667082, 12.5120088],
                [107.9669567, 12.5118142],
                [107.9672111, 12.5116271],
                [107.9674713, 12.5114475],
                [107.9677368, 12.5112758],
                [107.9680076, 12.511112],
                [107.9682834, 12.5109563],
                [107.9685638, 12.5108088],
                [107.9688487, 12.5106697],
                [107.9691378, 12.5105391],
                [107.9694308, 12.5104171],
                [107.9697275, 12.5103038],
                [107.9705539, 12.5100135],
                [107.9709632, 12.509858],
                [107.9717732, 12.5095265],
                [107.9724281, 12.5092347],
                [107.9729244, 12.5089786],
                [107.9731659, 12.5088388],
                [107.9734025, 12.5086913],
                [107.9736341, 12.5085362],
                [107.9738602, 12.5083739],
                [107.9742956, 12.5080278],
                [107.9747068, 12.5076546],
                [107.9749027, 12.5074583],
                [107.975232, 12.5070969],
                [107.9759044, 12.5062799],
                [107.9764375, 12.5055855],
                [107.9769607, 12.5048479],
                [107.9774535, 12.5040906],
                [107.9778553, 12.5034197],
                [107.978014, 12.5031298],
                [107.9781643, 12.5028358],
                [107.9783061, 12.5025377],
                [107.9784392, 12.5022359],
                [107.9785636, 12.5019305],
                [107.9786791, 12.5016218],
                [107.9787857, 12.50131],
                [107.9788832, 12.5009955],
                [107.9789717, 12.5006784],
                [107.979051, 12.500359],
                [107.9791211, 12.5000376],
                [107.9791818, 12.4997143],
                [107.9793016, 12.4989445],
                [107.9794048, 12.4985058],
                [107.9795065, 12.4981772],
                [107.9795587, 12.4979463],
                [107.9795804, 12.4977109],
                [107.9795797, 12.4975926],
                [107.9795712, 12.4974747],
                [107.979521, 12.4971433],
                [107.9795221, 12.4969266],
                [107.979536, 12.496819],
                [107.9795898, 12.4966087],
                [107.9796772, 12.4964095],
                [107.979796, 12.4962264],
                [107.9798663, 12.4961424],
                [107.9799286, 12.496078],
                [107.98013, 12.4958991],
                [107.9802705, 12.495787],
                [107.9805672, 12.4955831],
                [107.9807227, 12.4954919],
                [107.9810463, 12.4953316],
                [107.9812136, 12.4952631],
                [107.9815574, 12.4951498],
                [107.981875, 12.4950758],
                [107.9823043, 12.4950056],
                [107.9828804, 12.4949373],
                [107.9834595, 12.4949007],
                [107.9840398, 12.4948959],
                [107.9854159, 12.49496],
                [107.9862865, 12.4950074],
                [107.9864071, 12.4947908],
                [107.9865601, 12.494541],
                [107.9867386, 12.4943079],
                [107.9869373, 12.494097],
                [107.9871569, 12.4939067],
                [107.987395, 12.4937391],
                [107.9877226, 12.4935768],
                [107.9878918, 12.4935071],
                [107.9882395, 12.4933914],
                [107.9884171, 12.4933457],
                [107.9890112, 12.493219],
                [107.9893029, 12.4931801],
                [107.98945, 12.4931737],
                [107.9897441, 12.4931871],
                [107.9900343, 12.4932354],
                [107.9902679, 12.4933009],
                [107.9903616, 12.4933206],
                [107.9904569, 12.4933311],
                [107.9905527, 12.4933325],
                [107.9906482, 12.4933246],
                [107.9907425, 12.4933076],
                [107.9908345, 12.4932816],
                [107.9909235, 12.4932469],
                [107.9910086, 12.4932038],
                [107.9910889, 12.4931528],
                [107.9911637, 12.4930943],
                [107.991314, 12.4929366],
                [107.9914368, 12.4927578],
                [107.9915293, 12.4925623],
                [107.9915633, 12.4924598],
                [107.991606, 12.4922485],
                [107.9916143, 12.4921411],
                [107.9916011, 12.4918609],
                [107.9915812, 12.491722],
                [107.9915153, 12.4914491],
                [107.9914696, 12.4913161],
                [107.9913534, 12.4910597],
                [107.9912057, 12.4908191],
                [107.9909061, 12.4904441],
                [107.9908093, 12.4903054],
                [107.9907199, 12.4901622],
                [107.9905645, 12.4898644],
                [107.9904997, 12.4897104],
                [107.9904439, 12.489553],
                [107.9903973, 12.4893928],
                [107.99036, 12.4892303],
                [107.9903321, 12.4890661],
                [107.9903137, 12.4889005],
                [107.9903049, 12.4887342],
                [107.9903057, 12.4885677],
                [107.9903161, 12.4884015],
                [107.9903645, 12.487983],
                [107.9903896, 12.4876006],
                [107.9904328, 12.487258],
                [107.9904667, 12.4870886],
                [107.9905588, 12.4867554],
                [107.9906168, 12.4865923],
                [107.990756, 12.4862751],
                [107.9909243, 12.4859734],
                [107.9911784, 12.4855971],
                [107.9912436, 12.4855107],
                [107.9913161, 12.48543],
                [107.9913952, 12.4853556],
                [107.9914806, 12.4852879],
                [107.9916318, 12.4851931],
                [107.991821, 12.4851046],
                [107.9919516, 12.4850559],
                [107.9920854, 12.4850161],
                [107.9922217, 12.4849853],
                [107.9923597, 12.4849638],
                [107.992499, 12.4849515],
                [107.9926388, 12.4849486],
                [107.9927785, 12.484955],
                [107.9929175, 12.4849708],
                [107.9930549, 12.4849958],
                [107.9931903, 12.48503],
                [107.993323, 12.4850732],
                [107.9934523, 12.4851252],
                [107.9935777, 12.4851857],
                [107.9936986, 12.4852545],
                [107.9942288, 12.4855934],
                [107.9945979, 12.4858048],
                [107.9949805, 12.4859918],
                [107.9954923, 12.4862072],
                [107.9958821, 12.4864047],
                [107.9960601, 12.4864732],
                [107.9961532, 12.4864953],
                [107.9963436, 12.4865141],
                [107.9965343, 12.4864988],
                [107.9966278, 12.4864785],
                [107.9968071, 12.4864132],
                [107.9968915, 12.4863689],
                [107.997046, 12.4862587],
                [107.9971774, 12.4861229],
                [107.9972331, 12.4860467],
                [107.9973218, 12.4858812],
                [107.9973753, 12.4857163],
                [107.9974182, 12.4854813],
                [107.9974384, 12.4853234],
                [107.9974549, 12.4850456],
                [107.9974505, 12.4846881],
                [107.9974383, 12.4844501],
                [107.9973902, 12.4839758],
                [107.9973063, 12.4834246],
                [107.9972941, 12.4831058],
                [107.9973016, 12.4829464],
                [107.9973435, 12.4826301],
                [107.9974208, 12.4823201],
                [107.9975326, 12.4820204],
                [107.997601, 12.4818755],
                [107.997665, 12.4817564],
                [107.9978749, 12.4814088],
                [107.9981754, 12.480958],
                [107.9985015, 12.4805245],
                [107.9986739, 12.4803147],
                [107.9993182, 12.4795801],
                [107.9996319, 12.4792059],
                [108.0001251, 12.4786519],
                [108.0006307, 12.4781259],
                [108.0010985, 12.4776641],
                [108.0012425, 12.4775416],
                [108.0013317, 12.4774493],
                [108.0014138, 12.4773509],
                [108.0014884, 12.477247],
                [108.0015551, 12.4771382],
                [108.0016137, 12.4770249],
                [108.0016637, 12.4769078],
                [108.0017049, 12.4767874],
                [108.0017372, 12.4766645],
                [108.0017603, 12.4765396],
                [108.0017741, 12.4764134],
                [108.0017785, 12.4762866],
                [108.0017736, 12.4761598],
                [108.0017593, 12.4760337],
                [108.0017357, 12.4759089],
                [108.001703, 12.4757861],
                [108.0016613, 12.4756659],
                [108.0016109, 12.475549],
                [108.0015519, 12.4754359],
                [108.0014848, 12.4753273],
                [108.0013534, 12.475155],
                [108.0012304, 12.4750265],
                [108.0008824, 12.4746168],
                [108.0002853, 12.4738535],
                [108.0000767, 12.473622],
                [107.9999631, 12.4735149],
                [107.9997192, 12.4733191],
                [107.9995895, 12.4732312],
                [107.9993165, 12.4730762],
                [107.999174, 12.4730097],
                [107.9988791, 12.4728995],
                [107.9987275, 12.4728562],
                [107.9984986, 12.4728066],
                [107.9982718, 12.4727482],
                [107.9980475, 12.4726811],
                [107.9978262, 12.4726054],
                [107.997608, 12.4725211],
                [107.9973935, 12.4724285],
                [107.997183, 12.4723277],
                [107.9969766, 12.4722187],
                [107.9967749, 12.4721019],
                [107.9966362, 12.4720153],
                [107.996503, 12.4719209],
                [107.9963757, 12.4718191],
                [107.9962546, 12.4717102],
                [107.9961403, 12.4715946],
                [107.9960331, 12.4714727],
                [107.9959334, 12.4713448],
                [107.9958415, 12.4712115],
                [107.9957522, 12.4710633],
                [107.9955961, 12.4707551],
                [107.9954713, 12.4704337],
                [107.9953789, 12.4701019],
                [107.9953197, 12.469763],
                [107.9953028, 12.4695919],
                [107.9952943, 12.4692522],
                [107.99534, 12.4684138],
                [107.9953848, 12.467931],
                [107.9953905, 12.4677822],
                [107.9953867, 12.4676333],
                [107.9953732, 12.467485],
                [107.9953503, 12.4673377],
                [107.9953179, 12.4671922],
                [107.9952762, 12.4670489],
                [107.9952254, 12.4669085],
                [107.9951656, 12.4667714],
                [107.9950971, 12.4666384],
                [107.9950202, 12.4665097],
                [107.9949352, 12.4663861],
                [107.9948423, 12.4662679],
                [107.9947421, 12.4661557],
                [107.9946348, 12.4660498],
                [107.9945209, 12.4659507],
                [107.9944008, 12.4658589],
                [107.9941721, 12.4657135],
                [107.9928721, 12.4650833],
                [107.9927609, 12.4650229],
                [107.9926547, 12.4649544],
                [107.992554, 12.4648783],
                [107.9924595, 12.4647951],
                [107.9923716, 12.4647052],
                [107.9922909, 12.4646091],
                [107.9922178, 12.4645073],
                [107.9921528, 12.4644005],
                [107.9920961, 12.4642892],
                [107.9920482, 12.4641741],
                [107.9920092, 12.4640557],
                [107.9919795, 12.4639349],
                [107.9919591, 12.4638122],
                [107.9919483, 12.4636884],
                [107.9919469, 12.4635641],
                [107.9919629, 12.4633781],
                [107.9919693, 12.4631916],
                [107.9919748, 12.4628323],
                [107.9919594, 12.462114],
                [107.9918858, 12.4610713],
                [107.9918074, 12.4603375],
                [107.9917522, 12.4599381],
                [107.9916654, 12.4595442],
                [107.9915475, 12.459158],
                [107.9914771, 12.4589686],
                [107.9913139, 12.4585985],
                [107.9911133, 12.4582275],
                [107.9909212, 12.4578523],
                [107.9907377, 12.4574731],
                [107.9903753, 12.4566798],
                [107.9901577, 12.4562842],
                [107.9899111, 12.4559051],
                [107.9897774, 12.4557224],
                [107.98949, 12.4553718],
                [107.9892765, 12.4551419],
                [107.98907, 12.4549059],
                [107.9888707, 12.4546641],
                [107.9886789, 12.4544166],
                [107.9884946, 12.4541638],
                [107.988318, 12.4539057],
                [107.9881493, 12.4536427],
                [107.9879886, 12.4533749],
                [107.9876988, 12.4528397],
                [107.9875703, 12.4525727],
                [107.9874507, 12.4523018],
                [107.9873401, 12.4520272],
                [107.9872387, 12.4517493],
                [107.9871465, 12.4514683],
                [107.9870636, 12.4511845],
                [107.9869902, 12.4508983],
                [107.9868361, 12.4502295],
                [107.9866522, 12.4495678],
                [107.9865662, 12.4492921],
                [107.9862721, 12.4484195],
                [107.9859859, 12.4476233],
                [107.9858492, 12.4473333],
                [107.9857696, 12.4471938],
                [107.9855888, 12.4469278],
                [107.9854882, 12.446802],
                [107.9852681, 12.4465668],
                [107.9850245, 12.4463549],
                [107.9848947, 12.4462584],
                [107.9846207, 12.4460856],
                [107.9843299, 12.4459411],
                [107.9838896, 12.4457753],
                [107.981497, 12.4450524],
                [107.9808628, 12.4448771],
                [107.9802201, 12.4447341],
                [107.9795707, 12.4446238],
                [107.979072, 12.4445617],
                [107.9787315, 12.4445006],
                [107.9783981, 12.4444095],
                [107.9782349, 12.444353],
                [107.9779172, 12.4442185],
                [107.9777634, 12.4441409],
                [107.9773739, 12.4439086],
                [107.9771859, 12.443782],
                [107.9768251, 12.4435091],
                [107.9766527, 12.443363],
                [107.976325, 12.4430527],
                [107.9758176, 12.4424954],
                [107.9754435, 12.4421214],
                [107.975049, 12.4417679],
                [107.974586, 12.4413988],
                [107.9741008, 12.4410578],
                [107.9738505, 12.4408984],
                [107.9730909, 12.440456],
                [107.9726057, 12.4401361],
                [107.9723709, 12.4399654],
                [107.9717147, 12.4394478],
                [107.9712667, 12.4391349],
                [107.9707994, 12.4388504],
                [107.970559, 12.4387191],
                [107.9694105, 12.4381577],
                [107.9686263, 12.4377514],
                [107.968205, 12.437505],
                [107.9675333, 12.4370612],
                [107.9671371, 12.43683],
                [107.9669778, 12.436751],
                [107.9668144, 12.4366803],
                [107.9666474, 12.4366181],
                [107.9664774, 12.4365644],
                [107.9663046, 12.4365195],
                [107.9661298, 12.4364836],
                [107.9659532, 12.4364566],
                [107.9657754, 12.4364387],
                [107.9655969, 12.4364299],
                [107.9651091, 12.4364441],
                [107.9646236, 12.4364931],
                [107.9643826, 12.4365305],
                [107.9639883, 12.4366111],
                [107.9635279, 12.4367306],
                [107.963224, 12.4368208],
                [107.962923, 12.4369197],
                [107.962344, 12.4371374],
                [107.9612943, 12.4375792],
                [107.9608727, 12.437779],
                [107.9604632, 12.4380014],
                [107.9603081, 12.4380935],
                [107.9601583, 12.4381936],
                [107.9600142, 12.4383013],
                [107.9598762, 12.4384165],
                [107.9597448, 12.4385387],
                [107.9596202, 12.4386676],
                [107.9594046, 12.4389288],
                [107.9591965, 12.4391541],
                [107.9589745, 12.439347],
                [107.9587324, 12.4395152],
                [107.958473, 12.4396568],
                [107.9583505, 12.4397117],
                [107.9579755, 12.4398588],
                [107.9574688, 12.4400374],
                [107.9566265, 12.440299],
                [107.9564787, 12.4403583],
                [107.9563349, 12.4404264],
                [107.9561956, 12.4405029],
                [107.9560614, 12.4405877],
                [107.9559328, 12.4406803],
                [107.9558102, 12.4407805],
                [107.9554954, 12.4410819],
                [107.9553871, 12.4411695],
                [107.9551548, 12.4413242],
                [107.9550318, 12.4413907],
                [107.9547742, 12.4415006],
                [107.9546406, 12.4415437],
                [107.9544563, 12.441589],
                [107.9542272, 12.4416232],
                [107.9539463, 12.4416339],
                [107.9538059, 12.4416264],
                [107.9535671, 12.4415939],
                [107.9527417, 12.4414248],
                [107.9523909, 12.4413394],
                [107.9520427, 12.4412449],
                [107.9516971, 12.4411415],
                [107.9510414, 12.440918],
                [107.9504217, 12.4406797],
                [107.949888, 12.4404543],
                [107.9494237, 12.4402814],
                [107.9490317, 12.4401626],
                [107.9487145, 12.4400991],
                [107.9483922, 12.4400693],
                [107.9482303, 12.4400673],
                [107.9479073, 12.4400887],
                [107.9475886, 12.440144],
                [107.9470712, 12.4402696],
                [107.9469491, 12.4402865],
                [107.9468261, 12.4402943],
                [107.9467028, 12.4402928],
                [107.9465799, 12.440282],
                [107.9464583, 12.440262],
                [107.9461033, 12.4401661],
                [107.9458604, 12.4401338],
                [107.9457378, 12.4401308],
                [107.9454935, 12.4401514],
                [107.9453732, 12.4401748],
                [107.9451877, 12.4402293],
                [107.9450472, 12.4402507],
                [107.9449055, 12.4402399],
                [107.9447693, 12.4401971],
                [107.944647, 12.4401226],
                [107.9445475, 12.4400206],
                [107.9444772, 12.4398978],
                [107.9444405, 12.4397625],
                [107.9443758, 12.4392781],
                [107.9443581, 12.4390696],
                [107.9443484, 12.4388605],
                [107.9443418, 12.4382492],
                [107.9443189, 12.4378222],
                [107.944281, 12.4375814],
                [107.9442512, 12.437463],
                [107.944173, 12.4372384],
                [107.9441231, 12.4371338],
                [107.9440649, 12.4370333],
                [107.9439251, 12.4368472],
                [107.9438444, 12.4367628],
                [107.9437572, 12.4366849],
                [107.9436639, 12.4366139],
                [107.9434649, 12.4364961],
                [107.9427235, 12.4361766],
                [107.9423083, 12.4359689],
                [107.9419082, 12.4357344],
                [107.9417145, 12.4356074],
                [107.9413667, 12.4353535],
                [107.9410389, 12.4350753],
                [107.9408831, 12.4349276],
                [107.9403951, 12.4344147],
                [107.9402168, 12.4342438],
                [107.9398423, 12.4339206],
                [107.9395868, 12.4337245],
                [107.9393252, 12.4335362],
                [107.9390578, 12.4333558],
                [107.9387848, 12.4331836],
                [107.9382964, 12.4329031],
                [107.9377343, 12.4326147],
                [107.9376053, 12.4325367],
                [107.9374818, 12.4324506],
                [107.9373642, 12.4323569],
                [107.9372533, 12.4322559],
                [107.9371492, 12.432148],
                [107.9370527, 12.4320337],
                [107.9369639, 12.4319135],
                [107.9368834, 12.431788],
                [107.9366827, 12.4314061],
                [107.9365806, 12.4312598],
                [107.9364522, 12.4311349],
                [107.9363021, 12.4310358],
                [107.9361355, 12.430966],
                [107.9360454, 12.4309424],
                [107.9357697, 12.4308971],
                [107.9353989, 12.4308608],
                [107.9350263, 12.4308576],
                [107.9346549, 12.4308874],
                [107.9343211, 12.430943],
                [107.9339932, 12.4310252],
                [107.9335552, 12.4311965],
                [107.9325429, 12.4315466],
                [107.9322427, 12.4316634],
                [107.9318443, 12.4318335],
                [107.9317394, 12.4318705],
                [107.9315221, 12.4319194],
                [107.9312565, 12.4319516],
                [107.9310156, 12.4320033],
                [107.9307926, 12.4320354],
                [107.93068, 12.4320411],
                [107.930483, 12.4320345],
                [107.9303183, 12.4319991],
                [107.9302396, 12.4319693],
                [107.9300933, 12.4318874],
                [107.9300273, 12.431836],
                [107.9299128, 12.4317151],
                [107.9298518, 12.4316239],
                [107.9297981, 12.4315284],
                [107.9297521, 12.4314291],
                [107.9296838, 12.4312218],
                [107.9296487, 12.4310068],
                [107.9296474, 12.430789],
                [107.9296595, 12.4306807],
                [107.9296801, 12.4305736],
                [107.9297449, 12.4303681],
                [107.9297897, 12.4302714],
                [107.9298431, 12.4301789],
                [107.9299047, 12.4300914],
                [107.9299738, 12.4300095],
                [107.9300501, 12.4299339],
                [107.930133, 12.4298651],
                [107.9302217, 12.4298037],
                [107.9303155, 12.4297502],
                [107.9304139, 12.4297049],
                [107.9305159, 12.4296683],
                [107.9306209, 12.4296406],
                [107.9307663, 12.4296177],
                [107.93091, 12.4295859],
                [107.9310513, 12.4295453],
                [107.9311897, 12.429496],
                [107.9313246, 12.4294384],
                [107.9314556, 12.4293726],
                [107.9315821, 12.4292988],
                [107.9317035, 12.4292174],
                [107.9318195, 12.4291287],
                [107.9319295, 12.429033],
                [107.9320331, 12.4289307],
                [107.9321299, 12.4288223],
                [107.932265, 12.4286439],
                [107.9323889, 12.4284434],
                [107.9324611, 12.428304],
                [107.9325247, 12.4281607],
                [107.9325794, 12.428014],
                [107.932625, 12.4278643],
                [107.9326614, 12.4277122],
                [107.9326884, 12.4275583],
                [107.932706, 12.4274031],
                [107.932714, 12.4272471],
                [107.9327125, 12.427091],
                [107.9327014, 12.4269352],
                [107.9326808, 12.4267803],
                [107.9325806, 12.4263431],
                [107.932518, 12.4261276],
                [107.9323686, 12.425704],
                [107.9322818, 12.4254966],
                [107.9321197, 12.4251578],
                [107.9319644, 12.424899],
                [107.931877, 12.4247756],
                [107.9316841, 12.4245422],
                [107.931579, 12.4244327],
                [107.9313759, 12.4242488],
                [107.9311742, 12.42411],
                [107.930954, 12.4240011],
                [107.9308385, 12.4239587],
                [107.9305994, 12.423899],
                [107.9303823, 12.4238757],
                [107.9301641, 12.4238834],
                [107.9299493, 12.4239219],
                [107.9297425, 12.4239905],
                [107.929548, 12.4240876],
                [107.9294232, 12.4241702],
                [107.9291884, 12.4243546],
                [107.929079, 12.4244559],
                [107.9288781, 12.4246753],
                [107.9287027, 12.4249147],
                [107.9286253, 12.425041],
                [107.9284427, 12.4253705],
                [107.9283418, 12.4255299],
                [107.9281219, 12.4258369],
                [107.9279264, 12.4260731],
                [107.9276857, 12.4263312],
                [107.9273433, 12.4266548],
                [107.9271632, 12.4268072],
                [107.9267865, 12.4270924],
                [107.9265905, 12.4272246],
                [107.9261841, 12.4274678],
                [107.9259744, 12.4275784],
                [107.9255828, 12.4277601],
                [107.9254016, 12.4278313],
                [107.9250307, 12.427951],
                [107.9248417, 12.4279993],
                [107.9244582, 12.428072],
                [107.9242645, 12.4280965],
                [107.9238747, 12.4281212],
                [107.9235188, 12.4281155],
                [107.9233588, 12.4281007],
                [107.9230427, 12.4280446],
                [107.9228876, 12.4280035],
                [107.9226146, 12.4279076],
                [107.9224977, 12.4278532],
                [107.922277, 12.4277209],
                [107.9221744, 12.4276436],
                [107.9219873, 12.4274689],
                [107.9219037, 12.4273723],
                [107.9217634, 12.4271705],
                [107.9216537, 12.4269515],
                [107.9215768, 12.4267195],
                [107.9215342, 12.4264792],
                [107.9215268, 12.4262354],
                [107.9215364, 12.4261138],
                [107.9215548, 12.4259931],
                [107.9216025, 12.4258026],
                [107.9216774, 12.4255926],
                [107.9217994, 12.4253212],
                [107.9218699, 12.4251899],
                [107.9219995, 12.4249811],
                [107.9221691, 12.4247459],
                [107.9222894, 12.4245943],
                [107.9225471, 12.4243048],
                [107.9226841, 12.4241674],
                [107.9229709, 12.4239106],
                [107.923241, 12.423642],
                [107.9234838, 12.4233496],
                [107.9235942, 12.4231953],
                [107.923792, 12.4228722],
                [107.9238963, 12.4226679],
                [107.923992, 12.4224597],
                [107.9240788, 12.4222478],
                [107.9241568, 12.4220326],
                [107.9242258, 12.4218145],
                [107.9242855, 12.4215939],
                [107.9243697, 12.4211912],
                [107.9244005, 12.4209879],
                [107.9244234, 12.4205935],
                [107.9244161, 12.4202021],
                [107.9244014, 12.4200087],
                [107.9243183, 12.4192954],
                [107.9242931, 12.4189662],
                [107.9242744, 12.4186366],
                [107.9242762, 12.4182551],
                [107.9242427, 12.417875],
                [107.9242133, 12.41769],
                [107.9241294, 12.4173245],
                [107.9240751, 12.4171449],
                [107.9240099, 12.4169836],
                [107.9239141, 12.4168497],
                [107.9238085, 12.4167376],
                [107.9236961, 12.4166319],
                [107.9235774, 12.4165331],
                [107.9234515, 12.4164406],
                [107.9233199, 12.4163557],
                [107.9231833, 12.4162789],
                [107.9229391, 12.4161647],
                [107.9228324, 12.4161279],
                [107.9227229, 12.4161002],
                [107.9226113, 12.4160818],
                [107.9224985, 12.416073],
                [107.9223853, 12.4160736],
                [107.9222726, 12.4160838],
                [107.9220768, 12.416125],
                [107.9218293, 12.4162078],
                [107.9216673, 12.4162708],
                [107.9213528, 12.4164179],
                [107.9211026, 12.4165608],
                [107.9209214, 12.4167006],
                [107.9207647, 12.4168662],
                [107.9206967, 12.4169575],
                [107.9206304, 12.4170639],
                [107.9205722, 12.4171749],
                [107.9205226, 12.4172897],
                [107.9204818, 12.4174078],
                [107.9204002, 12.4177495],
                [107.9203193, 12.4179615],
                [107.9202127, 12.4181543],
                [107.9201507, 12.4182414],
                [107.920007, 12.4184],
                [107.9198398, 12.4185351],
                [107.9196533, 12.4186432],
                [107.9194782, 12.4187135],
                [107.919245, 12.4187778],
                [107.919087, 12.4188106],
                [107.9187666, 12.41885],
                [107.9186052, 12.4188564],
                [107.9183036, 12.4188446],
                [107.9181645, 12.4188259],
                [107.918027, 12.4187979],
                [107.9178918, 12.4187608],
                [107.9177596, 12.4187147],
                [107.9176308, 12.4186599],
                [107.9175063, 12.4185966],
                [107.9173865, 12.418525],
                [107.9172719, 12.4184457],
                [107.9171633, 12.4183588],
                [107.917061, 12.4182649],
                [107.9169655, 12.4181643],
                [107.9168773, 12.4180576],
                [107.9167945, 12.4179419],
                [107.9166501, 12.4176976],
                [107.9165352, 12.4174388],
                [107.9164513, 12.4171689],
                [107.9164213, 12.4170308],
                [107.9164002, 12.4168974],
                [107.9163886, 12.4167628],
                [107.9163863, 12.4166278],
                [107.9163935, 12.416493],
                [107.9164102, 12.4163589],
                [107.9164361, 12.4162263],
                [107.9164713, 12.4160957],
                [107.9165155, 12.4159678],
                [107.9165686, 12.4158431],
                [107.9166303, 12.4157223],
                [107.9167002, 12.4156058],
                [107.9167883, 12.415481],
                [107.9168842, 12.415362],
                [107.9169878, 12.4152491],
                [107.9170985, 12.415143],
                [107.9172159, 12.4150439],
                [107.9173395, 12.4149523],
                [107.9174688, 12.4148686],
                [107.9176033, 12.4147932],
                [107.9177425, 12.4147262],
                [107.9178858, 12.414668],
                [107.9182064, 12.4145539],
                [107.9185152, 12.414412],
                [107.9186644, 12.414331],
                [107.9189508, 12.4141497],
                [107.9193755, 12.4138291],
                [107.919684, 12.4136364],
                [107.9200101, 12.4134736],
                [107.9201983, 12.4133963],
                [107.92039, 12.413328],
                [107.9205849, 12.4132687],
                [107.9207825, 12.4132186],
                [107.9209823, 12.4131778],
                [107.9211839, 12.4131463],
                [107.9213868, 12.4131243],
                [107.9215905, 12.4131118],
                [107.9217946, 12.4131088],
                [107.9219986, 12.4131154],
                [107.9222021, 12.4131315],
                [107.9224045, 12.4131571],
                [107.9226055, 12.4131921],
                [107.9228045, 12.4132364],
                [107.9230011, 12.41329],
                [107.9231948, 12.4133528],
                [107.9233853, 12.4134245],
                [107.9237035, 12.4135681],
                [107.9244781, 12.4139863],
                [107.9246161, 12.4140507],
                [107.924758, 12.4141063],
                [107.9249032, 12.414153],
                [107.9250511, 12.4141907],
                [107.9252012, 12.4142191],
                [107.9253529, 12.4142382],
                [107.9255055, 12.4142478],
                [107.9256584, 12.414248],
                [107.925811, 12.4142387],
                [107.9259627, 12.41422],
                [107.9261128, 12.4141919],
                [107.9262609, 12.4141546],
                [107.9264062, 12.4141082],
                [107.9265482, 12.4140529],
                [107.9266864, 12.4139889],
                [107.9268201, 12.4139165],
                [107.9269488, 12.4138359],
                [107.9270721, 12.4137476],
                [107.9271894, 12.4136518],
                [107.9273002, 12.4135489],
                [107.9274041, 12.4134394],
                [107.9275007, 12.4133236],
                [107.9275896, 12.4132021],
                [107.9277285, 12.4129716],
                [107.9277784, 12.4128638],
                [107.9278197, 12.4127526],
                [107.9278522, 12.4126387],
                [107.9278758, 12.4125227],
                [107.9278903, 12.4124053],
                [107.9278956, 12.4122871],
                [107.9278917, 12.4121689],
                [107.9278786, 12.4120513],
                [107.9278564, 12.4119351],
                [107.9278252, 12.4118208],
                [107.9277852, 12.4117091],
                [107.9277366, 12.4116008],
                [107.9276798, 12.4114964],
                [107.927615, 12.4113964],
                [107.9275426, 12.4113016],
                [107.927463, 12.4112125],
                [107.9273767, 12.4111295],
                [107.927208, 12.4109984],
                [107.9268036, 12.410759],
                [107.9263059, 12.4104932],
                [107.9260512, 12.4103713],
                [107.9255932, 12.4101743],
                [107.9242421, 12.4096728],
                [107.9235714, 12.4094108],
                [107.9229993, 12.4091674],
                [107.9220279, 12.4087214],
                [107.9217674, 12.4086086],
                [107.9212359, 12.408408],
                [107.9206923, 12.4082411],
                [107.9204167, 12.4081706],
                [107.9201388, 12.4081088],
                [107.9193905, 12.4079664],
                [107.9187585, 12.407832],
                [107.9185016, 12.4077661],
                [107.9182474, 12.4076911],
                [107.9179961, 12.407607],
                [107.9177481, 12.4075141],
                [107.9175038, 12.4074123],
                [107.9172635, 12.4073019],
                [107.9167997, 12.4070597],
                [107.9163517, 12.4067908],
                [107.9161341, 12.4066465],
                [107.9157126, 12.4063393],
                [107.9153109, 12.4060079],
                [107.9149035, 12.405627],
                [107.9147094, 12.4054271],
                [107.9142987, 12.4049677],
                [107.9138484, 12.4044399],
                [107.9135523, 12.4040682],
                [107.9132813, 12.4036787],
                [107.9131555, 12.4034778],
                [107.9129333, 12.4030825],
                [107.9128389, 12.4028878],
                [107.9127534, 12.4026892],
                [107.9126147, 12.4022985],
                [107.9125144, 12.4019146],
                [107.9124471, 12.401524],
                [107.9124131, 12.4011292],
                [107.9124087, 12.4009311],
                [107.9124141, 12.4007027],
                [107.9124288, 12.4004747],
                [107.9124528, 12.4002475],
                [107.9124861, 12.4000215],
                [107.9125287, 12.3997969],
                [107.9125804, 12.3995741],
                [107.9126745, 12.3992455],
                [107.9128858, 12.3985952],
                [107.9131275, 12.3979551],
                [107.9136263, 12.3967581],
                [107.9138422, 12.3962912],
                [107.9139614, 12.3960631],
                [107.9142219, 12.3956184],
                [107.9143345, 12.3954446],
                [107.9146884, 12.3949336],
                [107.9151824, 12.3942675],
                [107.9155462, 12.3938106],
                [107.9157403, 12.3935346],
                [107.9159075, 12.3932422],
                [107.9159805, 12.3930907],
                [107.9160463, 12.3929361],
                [107.9162073, 12.3924907],
                [107.9162719, 12.3923518],
                [107.9163451, 12.3922171],
                [107.9164267, 12.392087],
                [107.9165323, 12.3919414],
                [107.9166456, 12.3918015],
                [107.9167665, 12.3916676],
                [107.9168944, 12.3915402],
                [107.9170292, 12.3914197],
                [107.9171703, 12.3913063],
                [107.9173173, 12.3912004],
                [107.9174699, 12.3911023],
                [107.9176277, 12.3910122],
                [107.9177901, 12.3909305],
                [107.9179568, 12.3908573],
                [107.9181272, 12.3907929],
                [107.9183901, 12.3907127],
                [107.9189232, 12.3905774],
                [107.9193249, 12.3904837],
                [107.9197289, 12.3903996],
                [107.9201348, 12.390325],
                [107.9205424, 12.39026],
                [107.9209515, 12.3902046],
                [107.9213619, 12.3901589],
                [107.9224102, 12.3900658],
                [107.9231646, 12.3899871],
                [107.9236234, 12.3899163],
                [107.9242774, 12.3897801],
                [107.9249124, 12.38969],
                [107.9251074, 12.3896277],
                [107.9252001, 12.3895846],
                [107.9253724, 12.3894758],
                [107.9255232, 12.3893399],
                [107.9256267, 12.3892122],
                [107.9256567, 12.3891567],
                [107.9256913, 12.3890363],
                [107.9256953, 12.3889737],
                [107.9256762, 12.3888501],
                [107.9256535, 12.3887914],
                [107.9256289, 12.3887464],
                [107.9255386, 12.3886219],
                [107.9254717, 12.3885439],
                [107.925399, 12.388471],
                [107.9252539, 12.3883534],
                [107.9250417, 12.3882181],
                [107.9248949, 12.3881365],
                [107.9245889, 12.387997],
                [107.9244307, 12.3879394],
                [107.9242694, 12.3878902],
                [107.9240812, 12.3878442],
                [107.9238916, 12.387804],
                [107.9235233, 12.3877432],
                [107.9231731, 12.3876658],
                [107.9228319, 12.3875566],
                [107.9226656, 12.3874904],
                [107.9223436, 12.3873354],
                [107.9220378, 12.3871514],
                [107.9218843, 12.3870435],
                [107.9215921, 12.386809],
                [107.9214539, 12.3866829],
                [107.9211946, 12.386414],
                [107.9210739, 12.3862717],
                [107.9208433, 12.3859603],
                [107.9207349, 12.385791],
                [107.9206343, 12.3856172],
                [107.920542, 12.385439],
                [107.920458, 12.385257],
                [107.9203826, 12.3850714],
                [107.9203159, 12.3848827],
                [107.9202447, 12.3846423],
                [107.9201269, 12.3841554],
                [107.9200804, 12.3839093],
                [107.9200209, 12.3834911],
                [107.9200009, 12.3831475],
                [107.9200115, 12.3828036],
                [107.9200283, 12.3826323],
                [107.9200845, 12.3822926],
                [107.9201817, 12.3819223],
                [107.9203095, 12.3815733],
                [107.9204691, 12.3812371],
                [107.9205454, 12.3811137],
                [107.9205899, 12.3810563],
                [107.9206511, 12.3809889],
                [107.9208011, 12.3808552],
                [107.9212691, 12.3804851],
                [107.9215175, 12.3803033],
                [107.9217522, 12.3801501],
                [107.9220048, 12.3800271],
                [107.9222713, 12.3799362],
                [107.9224085, 12.3799033],
                [107.9226693, 12.3798647],
                [107.9229144, 12.379856],
                [107.923159, 12.3798736],
                [107.9232687, 12.3798937],
                [107.9233909, 12.3799338],
                [107.9235001, 12.3800006],
                [107.9235895, 12.3800893],
                [107.9236548, 12.3801946],
                [107.9237062, 12.3803452],
                [107.9237227, 12.3804361],
                [107.9237437, 12.3806334],
                [107.9237384, 12.3809413],
                [107.9237085, 12.381185],
                [107.9237037, 12.3812763],
                [107.9237079, 12.3813678],
                [107.9237433, 12.3815472],
                [107.9238133, 12.3817167],
                [107.9239154, 12.3818699],
                [107.9240021, 12.3819628],
                [107.9241913, 12.3821331],
                [107.9242932, 12.3822099],
                [107.9245096, 12.3823458],
                [107.924758, 12.3824635],
                [107.9248963, 12.3825138],
                [107.9250376, 12.3825552],
                [107.9251815, 12.3825873],
                [107.9253272, 12.3826102],
                [107.9254741, 12.3826236],
                [107.9256215, 12.3826275],
                [107.925769, 12.382622],
                [107.9259157, 12.382607],
                [107.9261941, 12.3825516],
                [107.9263246, 12.3825119],
                [107.9264521, 12.3824636],
                [107.9265758, 12.382407],
                [107.9266954, 12.3823422],
                [107.9268101, 12.3822697],
                [107.9269195, 12.3821897],
                [107.9270231, 12.3821026],
                [107.9271204, 12.3820088],
                [107.927211, 12.3819087],
                [107.9272943, 12.3818029],
                [107.9273701, 12.3816918],
                [107.9274558, 12.381542],
                [107.9275332, 12.381388],
                [107.927602, 12.3812301],
                [107.9276621, 12.3810689],
                [107.9277133, 12.3809047],
                [107.9277555, 12.3807381],
                [107.9277884, 12.3805696],
                [107.9278121, 12.3803996],
                [107.9278264, 12.3802285],
                [107.9278285, 12.3799174],
                [107.9277975, 12.3794998],
                [107.9277657, 12.3792224],
                [107.9276834, 12.3787246],
                [107.9276298, 12.3785175],
                [107.9275945, 12.3782879],
                [107.9275865, 12.378178],
                [107.9275868, 12.3780678],
                [107.9276123, 12.377849],
                [107.9276705, 12.3776363],
                [107.9278727, 12.3776763],
                [107.9280765, 12.3777076],
                [107.9284874, 12.3777433],
                [107.9288999, 12.3777431],
                [107.9291057, 12.3777295],
                [107.9294252, 12.3776905],
                [107.9297657, 12.3776265],
                [107.9299904, 12.3775728],
                [107.9302126, 12.3775104],
                [107.9304321, 12.3774393],
                [107.9306484, 12.3773596],
                [107.9308613, 12.3772714],
                [107.9310704, 12.377175],
                [107.9314024, 12.3770003],
                [107.9315244, 12.3769221],
                [107.9316409, 12.3768362],
                [107.9317513, 12.376743],
                [107.9318551, 12.3766428],
                [107.9319519, 12.3765362],
                [107.9320414, 12.3764236],
                [107.9321229, 12.3763054],
                [107.9322133, 12.3761407],
                [107.9322707, 12.3759624],
                [107.9322932, 12.3757769],
                [107.9322801, 12.3755904],
                [107.9322317, 12.3754097],
                [107.9321497, 12.3752408],
                [107.9320371, 12.3750898],
                [107.9318512, 12.3748991],
                [107.9317666, 12.3747966],
                [107.9316155, 12.3745786],
                [107.9314899, 12.3743457],
                [107.9314297, 12.3742055],
                [107.9313346, 12.373916],
                [107.9312745, 12.3736177],
                [107.9312579, 12.3734665],
                [107.9312518, 12.3731625],
                [107.9312817, 12.3728599],
                [107.9313474, 12.3725627],
                [107.9314391, 12.3722963],
                [107.9315969, 12.3719409],
                [107.9318333, 12.3714791],
                [107.9319635, 12.3712544],
                [107.9321928, 12.3708971],
                [107.9323952, 12.3706368],
                [107.9326223, 12.3703967],
                [107.9328721, 12.3701792],
                [107.9331422, 12.3699862],
                [107.9334988, 12.3697615],
                [107.9338357, 12.3695094],
                [107.9339961, 12.3693736],
                [107.9342382, 12.3691459],
                [107.9343188, 12.369054],
                [107.9343921, 12.3689563],
                [107.9344575, 12.3688536],
                [107.9345148, 12.3687462],
                [107.9345636, 12.3686349],
                [107.9346036, 12.3685204],
                [107.9346346, 12.3684032],
                [107.9346564, 12.368284],
                [107.9346689, 12.3681636],
                [107.934672, 12.3680425],
                [107.9346657, 12.3679216],
                [107.934635, 12.3677347],
                [107.934574, 12.3675549],
                [107.9344842, 12.3673871],
                [107.9344292, 12.367309],
                [107.9343009, 12.3671671],
                [107.9339661, 12.366894],
                [107.9338074, 12.3667479],
                [107.9335549, 12.3664874],
                [107.9333255, 12.3662073],
                [107.9332199, 12.3660605],
                [107.9330176, 12.3657363],
                [107.9329226, 12.3655588],
                [107.932836, 12.3653772],
                [107.9327577, 12.365192],
                [107.9326882, 12.3650036],
                [107.9326274, 12.3648122],
                [107.9325755, 12.3646184],
                [107.9325326, 12.3644225],
                [107.9324987, 12.3642249],
                [107.9324741, 12.3640261],
                [107.9324587, 12.3638263],
                [107.9324525, 12.3636261],
                [107.932465, 12.3632611],
                [107.9324836, 12.3630971],
                [107.9325114, 12.3629344],
                [107.9325482, 12.3627735],
                [107.932594, 12.3626147],
                [107.9326485, 12.3624586],
                [107.9327117, 12.3623056],
                [107.9327834, 12.3621563],
                [107.9328632, 12.3620109],
                [107.9330071, 12.3617885],
                [107.9331431, 12.3615613],
                [107.9333579, 12.3611603],
                [107.9335098, 12.3608114],
                [107.9335744, 12.3606326],
                [107.9336804, 12.3602679],
                [107.9337491, 12.3599311],
                [107.9337777, 12.3596248],
                [107.9337707, 12.3593173],
                [107.9337538, 12.3591643],
                [107.9336937, 12.3588624],
                [107.9335991, 12.358569],
                [107.9335391, 12.3584267],
                [107.9334483, 12.3582456],
                [107.9332432, 12.3578955],
                [107.9330083, 12.3575638],
                [107.9328801, 12.3574056],
                [107.9326181, 12.3571204],
                [107.9322212, 12.3567377],
                [107.931828, 12.3563771],
                [107.9315987, 12.3561386],
                [107.9313942, 12.3558795],
                [107.9312164, 12.3556023],
                [107.9311015, 12.3553758],
                [107.9310555, 12.3552576],
                [107.9309873, 12.3550137],
                [107.9309654, 12.354889],
                [107.9309466, 12.3546369],
                [107.9309496, 12.3545105],
                [107.9309808, 12.3542595],
                [107.9310447, 12.3540145],
                [107.9311016, 12.3538644],
                [107.9311675, 12.3537178],
                [107.931242, 12.3535752],
                [107.9313249, 12.3534372],
                [107.9314159, 12.3533041],
                [107.9315148, 12.3531764],
                [107.9316211, 12.3530546],
                [107.9317346, 12.3529391],
                [107.9319637, 12.3527418],
                [107.9326998, 12.3522211],
                [107.9330776, 12.3519255],
                [107.9332597, 12.3517699],
                [107.9335548, 12.3514975],
                [107.933771, 12.3512566],
                [107.9339598, 12.3509947],
                [107.9341192, 12.3507146],
                [107.9342472, 12.3504197],
                [107.9343158, 12.3502121],
                [107.9343749, 12.3500017],
                [107.9344246, 12.349789],
                [107.9344646, 12.3495744],
                [107.9344949, 12.3493583],
                [107.9345242, 12.3489837],
                [107.9345237, 12.348826],
                [107.9345139, 12.3486687],
                [107.9344949, 12.3485121],
                [107.9344666, 12.3483569],
                [107.9344292, 12.3482035],
                [107.9343829, 12.3480525],
                [107.9343277, 12.3479044],
                [107.9341118, 12.3474215],
                [107.9340142, 12.3471759],
                [107.9338401, 12.3466772],
                [107.9336947, 12.3461698],
                [107.9336017, 12.3457504],
                [107.9335686, 12.3455383],
                [107.9335292, 12.3451109],
                [107.9335254, 12.3446991],
                [107.9335375, 12.3445022],
                [107.9335594, 12.3443061],
                [107.933591, 12.3441112],
                [107.9336322, 12.3439181],
                [107.9336831, 12.3437272],
                [107.9337433, 12.3435389],
                [107.9338128, 12.3433536],
                [107.9338914, 12.3431719],
                [107.934029, 12.3429017],
                [107.93419, 12.3426307],
                [107.9344265, 12.3422825],
                [107.9346871, 12.3419513],
                [107.9351221, 12.3414513],
                [107.9353293, 12.341193],
                [107.9358276, 12.3405307],
                [107.9359866, 12.3403387],
                [107.9361519, 12.3401519],
                [107.9364525, 12.3398411],
                [107.936728, 12.3396001],
                [107.9368738, 12.3394888],
                [107.9371799, 12.339286],
                [107.9375033, 12.3391106],
                [107.9378414, 12.3389642],
                [107.938099, 12.3388754],
                [107.9383532, 12.3387775],
                [107.9386036, 12.3386708],
                [107.9390589, 12.338449],
                [107.9394663, 12.3382175],
                [107.9396643, 12.3380926],
                [107.9406606, 12.3375325],
                [107.940843, 12.3374364],
                [107.9413945, 12.3371668],
                [107.9416437, 12.3370235],
                [107.9417474, 12.3369638],
                [107.9420847, 12.336737],
                [107.9425549, 12.3363865],
                [107.9427965, 12.3362198],
                [107.9432917, 12.3359038],
                [107.9439236, 12.3355429],
                [107.9440571, 12.3354571],
                [107.9441852, 12.3353638],
                [107.9443074, 12.3352632],
                [107.9444233, 12.3351558],
                [107.9445325, 12.3350418],
                [107.9446346, 12.3349218],
                [107.9447292, 12.3347961],
                [107.9448161, 12.3346651],
                [107.9448949, 12.3345293],
                [107.9449654, 12.3343892],
                [107.9450229, 12.3342561],
                [107.9451715, 12.3338476],
                [107.9452608, 12.3335721],
                [107.9453758, 12.3331602],
                [107.9454014, 12.3330245],
                [107.9454176, 12.3328874],
                [107.9454242, 12.3327496],
                [107.9454213, 12.3326116],
                [107.9454089, 12.3324742],
                [107.945387, 12.3323379],
                [107.9453558, 12.3322033],
                [107.9453153, 12.3320711],
                [107.9452136, 12.3318285],
                [107.9449129, 12.3312815],
                [107.9446463, 12.3308585],
                [107.944377, 12.330479],
                [107.944141, 12.3301198],
                [107.9440324, 12.3299346],
                [107.9438593, 12.329606],
                [107.9437427, 12.3293153],
                [107.9436973, 12.3291656],
                [107.9436329, 12.3288598],
                [107.9436142, 12.3287047],
                [107.9436039, 12.3283927],
                [107.9436299, 12.3280815],
                [107.943674, 12.327847],
                [107.9437401, 12.3276085],
                [107.9438568, 12.3272997],
                [107.9440054, 12.3270041],
                [107.9441842, 12.326725],
                [107.9443914, 12.3264653],
                [107.9446086, 12.3262434],
                [107.9447257, 12.326141],
                [107.9449754, 12.3259544],
                [107.9452434, 12.3257939],
                [107.9455269, 12.3256613],
                [107.9457978, 12.3255653],
                [107.9461763, 12.3254619],
                [107.9464309, 12.3254016],
                [107.9469631, 12.3253016],
                [107.9476889, 12.3252099],
                [107.9480243, 12.3251377],
                [107.9481889, 12.3250897],
                [107.9485098, 12.3249701],
                [107.9486654, 12.3248988],
                [107.9489648, 12.3247344],
                [107.9492462, 12.324542],
                [107.9493873, 12.3244291],
                [107.9495222, 12.3243091],
                [107.9500065, 12.3238237],
                [107.9501538, 12.3236545],
                [107.9502671, 12.3235109],
                [107.9503729, 12.323362],
                [107.9504709, 12.3232081],
                [107.9505608, 12.3230496],
                [107.9506425, 12.3228869],
                [107.9507158, 12.3227204],
                [107.9507804, 12.3225505],
                [107.9510108, 12.3217716],
                [107.9511343, 12.3213845],
                [107.951373, 12.3206844],
                [107.9516091, 12.3200607],
                [107.9517976, 12.3196047],
                [107.9518686, 12.3194613],
                [107.9519481, 12.3193223],
                [107.9520358, 12.319188],
                [107.9521314, 12.319059],
                [107.9522345, 12.3189356],
                [107.9523448, 12.3188184],
                [107.952462, 12.3187076],
                [107.9525856, 12.3186037],
                [107.9527151, 12.3185071],
                [107.9528503, 12.318418],
                [107.9530929, 12.3182841],
                [107.9534078, 12.3181411],
                [107.9536213, 12.3180536],
                [107.9540566, 12.3178998],
                [107.954278, 12.3178337],
                [107.9546664, 12.3177364],
                [107.9566154, 12.3173252],
                [107.9571855, 12.3171733],
                [107.9576254, 12.3170423],
                [107.9580269, 12.3169029],
                [107.9588909, 12.3165849],
                [107.9593109, 12.3164212],
                [107.960478, 12.3159408],
                [107.9608576, 12.3157971],
                [107.9616255, 12.3155328],
                [107.9620135, 12.3154124],
                [107.9630239, 12.3151171],
                [107.9640262, 12.3147962],
                [107.965114, 12.3144155],
                [107.9657006, 12.3141958],
                [107.9662836, 12.3139673],
                [107.9668629, 12.31373],
                [107.9673497, 12.3135471],
                [107.9675892, 12.3134462],
                [107.9680594, 12.3132257],
                [107.9684915, 12.3129953],
                [107.9686883, 12.3128763],
                [107.9688799, 12.3127495],
                [107.969066, 12.312615],
                [107.9692463, 12.3124731],
                [107.9694204, 12.3123241],
                [107.969588, 12.3121682],
                [107.969749, 12.3120057],
                [107.9701215, 12.3116049],
                [107.9703161, 12.3114122],
                [107.9709702, 12.3108132],
                [107.9714027, 12.31038],
                [107.971765, 12.3099811],
                [107.971912, 12.3098005],
                [107.9720513, 12.3096141],
                [107.9721827, 12.3094222],
                [107.9723058, 12.3092253],
                [107.9725766, 12.3087448],
                [107.9726668, 12.3086125],
                [107.9727645, 12.3084854],
                [107.9728696, 12.3083641],
                [107.9729816, 12.3082489],
                [107.9731003, 12.3081401],
                [107.973434, 12.3078738],
                [107.9735293, 12.3077815],
                [107.9736178, 12.307683],
                [107.9736992, 12.3075788],
                [107.9737731, 12.3074693],
                [107.9738391, 12.3073552],
                [107.9738969, 12.3072368],
                [107.9739462, 12.3071149],
                [107.9739869, 12.30699],
                [107.9740354, 12.3067779],
                [107.9740998, 12.3064292],
                [107.9741263, 12.3062405],
                [107.9741885, 12.3056454],
                [107.9742309, 12.3051294],
                [107.9742555, 12.304656],
                [107.9742575, 12.3044318],
                [107.9742501, 12.3042077],
                [107.9742332, 12.3039841],
                [107.9742068, 12.3037614],
                [107.9741295, 12.3033363],
                [107.9740779, 12.3031263],
                [107.9739493, 12.3027132],
                [107.9739243, 12.3026005],
                [107.9738904, 12.3024901],
                [107.9738478, 12.3023826],
                [107.9737967, 12.3022787],
                [107.9737374, 12.3021791],
                [107.973537, 12.3019117],
                [107.9734795, 12.3018193],
                [107.9733841, 12.3016243],
                [107.9733467, 12.3015226],
                [107.9732982, 12.3013393],
                [107.9732617, 12.3010982],
                [107.9732487, 12.3009362],
                [107.9732455, 12.3007738],
                [107.9732615, 12.300504],
                [107.973278, 12.3003755],
                [107.9733076, 12.3001838],
                [107.9733858, 12.2998104],
                [107.9734814, 12.2994927],
                [107.9736043, 12.299184],
                [107.9737481, 12.2988967],
                [107.9740128, 12.2984413],
                [107.9740354, 12.2983845],
                [107.9740488, 12.298325],
                [107.9740526, 12.2982642],
                [107.9740468, 12.2982036],
                [107.9740315, 12.2981445],
                [107.974007, 12.2980885],
                [107.973974, 12.2980368],
                [107.9739332, 12.2979908],
                [107.9738187, 12.297889],
                [107.9737706, 12.2978296],
                [107.9737294, 12.2977654],
                [107.9736899, 12.2976837],
                [107.9736364, 12.2975109],
                [107.9736187, 12.2973313],
                [107.9736374, 12.2971517],
                [107.9736603, 12.2970641],
                [107.9737242, 12.2969116],
                [107.9737654, 12.2968488],
                [107.9738146, 12.2967917],
                [107.973871, 12.2967413],
                [107.9739336, 12.2966986],
                [107.9740014, 12.2966643],
                [107.9740732, 12.2966388],
                [107.974196, 12.2966176],
                [107.9743412, 12.2966181],
                [107.9745136, 12.2966419],
                [107.9747379, 12.2967014],
                [107.9748903, 12.2967515],
                [107.9753785, 12.2969461],
                [107.9754779, 12.2969725],
                [107.9756816, 12.2970003],
                [107.9758872, 12.296994],
                [107.9759889, 12.2969782],
                [107.9760888, 12.296954],
                [107.9763254, 12.2968725],
                [107.9764721, 12.2968541],
                [107.9765461, 12.2968566],
                [107.976691, 12.2968853],
                [107.9769288, 12.2969881],
                [107.977018, 12.297014],
                [107.9772022, 12.2970395],
                [107.9773879, 12.2970292],
                [107.9774791, 12.2970106],
                [107.9777419, 12.2969306],
                [107.9779644, 12.2968846],
                [107.9782317, 12.2968516],
                [107.978411, 12.2968409],
                [107.9785907, 12.2968393],
                [107.9787701, 12.296847],
                [107.9791015, 12.2968857],
                [107.9796582, 12.2969966],
                [107.9797628, 12.297011],
                [107.9799739, 12.2970146],
                [107.980079, 12.2970037],
                [107.9802545, 12.296966],
                [107.9804021, 12.2969533],
                [107.980476, 12.2969592],
                [107.9806195, 12.2969951],
                [107.9807511, 12.2970614],
                [107.9809257, 12.297203],
                [107.9809895, 12.2972442],
                [107.9811269, 12.2973094],
                [107.9812806, 12.2973671],
                [107.9813523, 12.2974069],
                [107.9814817, 12.2975065],
                [107.9815381, 12.2975652],
                [107.9816185, 12.2976753],
                [107.9816421, 12.2977252],
                [107.9816674, 12.2978319],
                [107.9816686, 12.2978867],
                [107.9816482, 12.2979944],
                [107.9816269, 12.2980452],
                [107.9815642, 12.298136],
                [107.9814562, 12.2982339],
                [107.9814314, 12.2982714],
                [107.9814034, 12.2983561],
                [107.9814011, 12.2984008],
                [107.9814203, 12.2984878],
                [107.9814412, 12.2985276],
                [107.9815024, 12.2985935],
                [107.9815793, 12.2986338],
                [107.981869, 12.2987228],
                [107.9821332, 12.2987721],
                [107.9824104, 12.2987906],
                [107.9825494, 12.2987878],
                [107.982918, 12.2987548],
                [107.983149, 12.2987651],
                [107.9833761, 12.2988078],
                [107.9834867, 12.2988409],
                [107.9836732, 12.2989173],
                [107.9840493, 12.2991263],
                [107.9844497, 12.2993742],
                [107.9846258, 12.2994938],
                [107.9849678, 12.2997466],
                [107.986738, 12.3009828],
                [107.9869577, 12.3011266],
                [107.9871825, 12.3012628],
                [107.9874121, 12.3013912],
                [107.9876461, 12.3015117],
                [107.9878843, 12.301624],
                [107.9881264, 12.3017282],
                [107.988372, 12.3018239],
                [107.9888211, 12.3019745],
                [107.9891409, 12.3020533],
                [107.9899887, 12.3022246],
                [107.9909476, 12.3024476],
                [107.9914241, 12.3025705],
                [107.9923707, 12.3028389],
                [107.9928741, 12.3029952],
                [107.993872, 12.3033333],
                [107.9942322, 12.3034784],
                [107.9945735, 12.3036335],
                [107.9950006, 12.3038475],
                [107.99522, 12.3039428],
                [107.9956688, 12.3041094],
                [107.9961289, 12.3042431],
                [107.9963624, 12.3042974],
                [107.9968541, 12.3043936],
                [107.9969794, 12.3044302],
                [107.9973265, 12.3045594],
                [107.9974432, 12.3045886],
                [107.9975619, 12.3046086],
                [107.9976818, 12.3046194],
                [107.9978023, 12.3046207],
                [107.9979225, 12.3046126],
                [107.9980416, 12.3045952],
                [107.9984035, 12.3044988],
                [107.9985283, 12.3044736],
                [107.9986544, 12.3044549],
                [107.9990187, 12.3044231],
                [107.9992503, 12.3043681],
                [107.9993622, 12.3043277],
                [107.9995748, 12.3042224],
                [107.9997683, 12.3040864],
                [107.999959, 12.303905],
                [108.0001277, 12.3037037],
                [108.0002534, 12.3035168],
                [108.0007031, 12.3027741],
                [108.0011135, 12.3020454],
                [108.0014565, 12.3013798],
                [108.0015964, 12.3010802],
                [108.0017275, 12.3007768],
                [108.0019845, 12.300128],
                [108.0021457, 12.2997972],
                [108.002313, 12.2995098],
                [108.0024451, 12.2992446],
                [108.0025461, 12.2989668],
                [108.0026575, 12.2985205],
                [108.0027079, 12.2983724],
                [108.0027673, 12.2982276],
                [108.0028356, 12.2980866],
                [108.0029125, 12.2979498],
                [108.003195, 12.2975352],
                [108.0032817, 12.2973866],
                [108.0033602, 12.2972336],
                [108.00343, 12.2970767],
                [108.0035657, 12.2967144],
                [108.003667, 12.2965239],
                [108.0037828, 12.2963613],
                [108.0038452, 12.2962941],
                [108.0039884, 12.2961786],
                [108.0040679, 12.2961314],
                [108.0042387, 12.2960604],
                [108.0044203, 12.2960225],
                [108.004513, 12.2960165],
                [108.0046982, 12.2960305],
                [108.0048413, 12.2960662],
                [108.0051219, 12.2961563],
                [108.0054675, 12.2963017],
                [108.0058484, 12.2964902],
                [108.0060555, 12.2966063],
                [108.0064776, 12.2968247],
                [108.0073246, 12.2972394],
                [108.0081777, 12.2976247],
                [108.0090585, 12.2979893],
                [108.009743, 12.298285],
                [108.0099774, 12.2983989],
                [108.0102073, 12.298521],
                [108.0104326, 12.2986512],
                [108.0106529, 12.2987892],
                [108.0108679, 12.298935],
                [108.0110774, 12.2990882],
                [108.0112812, 12.2992488],
                [108.0114788, 12.2994164],
                [108.0120222, 12.2999267],
                [108.0127493, 12.3006385],
                [108.0130603, 12.3009247],
                [108.0131299, 12.3009962],
                [108.013254, 12.3011518],
                [108.0134384, 12.3014545],
                [108.0135153, 12.3015568],
                [108.0135995, 12.3016534],
                [108.0136905, 12.3017439],
                [108.013788, 12.3018278],
                [108.0138913, 12.3019047],
                [108.0142043, 12.3020965],
                [108.0142983, 12.3021693],
                [108.0143859, 12.3022493],
                [108.0145178, 12.3023984],
                [108.0146565, 12.3025966],
                [108.0147716, 12.3027901],
                [108.0148527, 12.3028896],
                [108.014955, 12.3029685],
                [108.0150729, 12.3030224],
                [108.0152067, 12.3030495],
                [108.0153432, 12.3030459],
                [108.0154753, 12.3030119],
                [108.015531, 12.3029873],
                [108.0156881, 12.3028953],
                [108.0158825, 12.3027511],
                [108.0159716, 12.3026695],
                [108.0161347, 12.3024848],
                [108.0164965, 12.3019704],
                [108.0167627, 12.3015444],
                [108.0169017, 12.3012997],
                [108.0172978, 12.3005549],
                [108.017966, 12.2992599],
                [108.0182892, 12.2986671],
                [108.018485, 12.2982572],
                [108.0185704, 12.2980469],
                [108.0187152, 12.2976172],
                [108.0187744, 12.2973985],
                [108.0189778, 12.296436],
                [108.0191328, 12.2957879],
                [108.0191954, 12.2954452],
                [108.0192295, 12.2950986],
                [108.0192357, 12.2949246],
                [108.019213, 12.2940545],
                [108.0192152, 12.2936193],
                [108.0192465, 12.2927494],
                [108.0192946, 12.2921405],
                [108.0194047, 12.2913028],
                [108.0194724, 12.2908934],
                [108.0195221, 12.2907195],
                [108.0195599, 12.2906371],
                [108.0196595, 12.2904851],
                [108.0197273, 12.2904101],
                [108.0198021, 12.2903417],
                [108.0198831, 12.2902806],
                [108.0199697, 12.2902272],
                [108.0200611, 12.2901821],
                [108.0201565, 12.2901456],
                [108.0203469, 12.2901012],
                [108.0208127, 12.2900726],
                [108.0210924, 12.2900638],
                [108.0213722, 12.2900644],
                [108.0216519, 12.2900743],
                [108.0219311, 12.2900936],
                [108.0222094, 12.2901222],
                [108.0225952, 12.2901776],
                [108.0227048, 12.2901872],
                [108.0229248, 12.2901831],
                [108.023034, 12.2901693],
                [108.0232478, 12.2901186],
                [108.0234518, 12.2900381],
                [108.0235487, 12.2899872],
                [108.0236763, 12.2899055],
                [108.0239194, 12.2897314],
                [108.024263, 12.2894525],
                [108.0244856, 12.2892592],
                [108.024811, 12.2889543],
                [108.0249263, 12.288856],
                [108.0250477, 12.2887648],
                [108.0251746, 12.2886812],
                [108.0253066, 12.2886055],
                [108.0254431, 12.2885378],
                [108.0255836, 12.2884786],
                [108.0257277, 12.288428],
                [108.0258747, 12.2883862],
                [108.026024, 12.2883533],
                [108.0261751, 12.2883296],
                [108.0263275, 12.2883151],
                [108.026476, 12.2883099],
                [108.0267727, 12.2883256],
                [108.0271295, 12.2883288],
                [108.0286647, 12.2883226],
                [108.0291297, 12.2883121],
                [108.0303622, 12.2882613],
                [108.0307465, 12.2882571],
                [108.0315149, 12.2882732],
                [108.0320377, 12.2883029],
                [108.0330815, 12.2883869],
                [108.0336022, 12.2884413],
                [108.0345179, 12.288557],
                [108.0349124, 12.2886193],
                [108.0353053, 12.2886906],
                [108.0356963, 12.288771],
                [108.036554, 12.2889678],
                [108.0370295, 12.2890408],
                [108.0375093, 12.2890789],
                [108.0379907, 12.2890817],
                [108.0385874, 12.2890493],
                [108.0389441, 12.2890618],
                [108.039361, 12.2891061],
                [108.0396017, 12.2890969],
                [108.0398383, 12.2890531],
                [108.0400658, 12.2889756],
                [108.0401867, 12.2889183],
                [108.0403032, 12.2888527],
                [108.0404147, 12.2887793],
                [108.0405205, 12.2886983],
                [108.0406202, 12.2886102],
                [108.0407132, 12.2885153],
                [108.0407991, 12.2884143],
                [108.0408775, 12.2883076],
                [108.0409479, 12.2881957],
                [108.04101, 12.2880792],
                [108.0411001, 12.2878595],
                [108.0411888, 12.2875555],
                [108.0412375, 12.2873502],
                [108.041277, 12.287143],
                [108.041307, 12.2869343],
                [108.0413275, 12.2867245],
                [108.0413385, 12.286514],
                [108.0413399, 12.2863033],
                [108.0413053, 12.2856768],
                [108.0413125, 12.2852601],
                [108.0413532, 12.2848452],
                [108.0414152, 12.2844074],
                [108.0414439, 12.2839663],
                [108.041439, 12.2835244],
                [108.0414099, 12.283162],
                [108.0413905, 12.2827991],
                [108.0413808, 12.2824357],
                [108.0413808, 12.2820723],
                [108.0413905, 12.281709],
                [108.0414098, 12.281346],
                [108.0414388, 12.2809836],
                [108.0414775, 12.2806222],
                [108.0415257, 12.2802618],
                [108.0415836, 12.2799027],
                [108.041651, 12.2795453],
                [108.0417279, 12.2791897],
                [108.0418143, 12.2788362],
                [108.0419101, 12.278485],
                [108.0420151, 12.2781363],
                [108.0421295, 12.2777905],
                [108.042253, 12.2774476],
                [108.0423856, 12.2771081],
                [108.0425273, 12.276772],
                [108.0426778, 12.2764396],
                [108.0428371, 12.2761112],
                [108.0430052, 12.2757869],
                [108.0431818, 12.2754671],
                [108.0433669, 12.2751518],
                [108.0435603, 12.2748413],
                [108.043762, 12.2745359],
                [108.0439717, 12.2742357],
                [108.0441893, 12.273941],
                [108.0444148, 12.2736519],
                [108.0446478, 12.2733686],
                [108.0448884, 12.2730913],
                [108.0451362, 12.2728203],
                [108.0453912, 12.2725557],
                [108.0456531, 12.2722976],
                [108.0459218, 12.2720463],
                [108.0461972, 12.2718019],
                [108.0464789, 12.2715646],
                [108.0467669, 12.2713346],
                [108.0472738, 12.2709581],
                [108.0483606, 12.2702196],
                [108.0490185, 12.2697847],
                [108.0496815, 12.2693575],
                [108.0503497, 12.2689379],
                [108.0510229, 12.2685262],
                [108.0517011, 12.2681222],
                [108.0523841, 12.2677261],
                [108.0530719, 12.2673379],
                [108.0537643, 12.2669577],
                [108.0544612, 12.2665855],
                [108.0551627, 12.2662214],
                [108.0558684, 12.2658655],
                [108.0565785, 12.2655177],
                [108.0572927, 12.2651782],
                [108.0580109, 12.2648469],
                [108.0587331, 12.264524],
                [108.0594592, 12.2642094],
                [108.060189, 12.2639032],
                [108.0614471, 12.2633987],
                [108.0619679, 12.2631829],
                [108.0624847, 12.2629583],
                [108.0629974, 12.2627248],
                [108.0635059, 12.2624826],
                [108.0640099, 12.2622318],
                [108.0645094, 12.2619724],
                [108.0650042, 12.2617044],
                [108.0654941, 12.2614281],
                [108.0659789, 12.2611434],
                [108.0664586, 12.2608505],
                [108.066933, 12.2605494],
                [108.0674019, 12.2602402],
                [108.0678652, 12.2599231],
                [108.0683228, 12.2595981],
                [108.0687744, 12.2592653],
                [108.06922, 12.2589249],
                [108.0696595, 12.2585769],
                [108.0700927, 12.2582214],
                [108.0705194, 12.2578585],
                [108.0709396, 12.2574885],
                [108.071353, 12.2571113],
                [108.0717597, 12.256727],
                [108.0721594, 12.2563359],
                [108.0725521, 12.255938],
                [108.0729375, 12.2555335],
                [108.0733157, 12.2551224],
                [108.0736864, 12.2547049],
                [108.0740496, 12.2542811],
                [108.0744052, 12.2538512],
                [108.074753, 12.2534153],
                [108.075093, 12.2529735],
                [108.0754249, 12.252526],
                [108.0757489, 12.2520728],
                [108.0760646, 12.2516142],
                [108.0765756, 12.2508331],
                [108.076787, 12.2505209],
                [108.0770062, 12.250214],
                [108.077233, 12.2499124],
                [108.0774674, 12.2496163],
                [108.0777092, 12.249326],
                [108.0779581, 12.2490415],
                [108.0782142, 12.2487632],
                [108.0784772, 12.2484911],
                [108.078747, 12.2482253],
                [108.0790234, 12.2479662],
                [108.0793062, 12.2477138],
                [108.0795953, 12.2474682],
                [108.0798905, 12.2472297],
                [108.0806688, 12.2466362],
                [108.0808983, 12.2464443],
                [108.0811216, 12.2462453],
                [108.0813382, 12.2460396],
                [108.0815481, 12.2458272],
                [108.081751, 12.2456084],
                [108.0819466, 12.2453835],
                [108.0821349, 12.2451526],
                [108.0823156, 12.244916],
                [108.0824886, 12.2446739],
                [108.0826536, 12.2444266],
                [108.0828105, 12.2441743],
                [108.0829591, 12.2439173],
                [108.0830994, 12.2436558],
                [108.0832311, 12.24339],
                [108.083354, 12.2431204],
                [108.0834682, 12.242847],
                [108.0835735, 12.2425703],
                [108.0836697, 12.2422904],
                [108.0837569, 12.2420077],
                [108.0838348, 12.2417225],
                [108.0839034, 12.2414349],
                [108.0839626, 12.2411454],
                [108.0840125, 12.2408542],
                [108.0840528, 12.2405616],
                [108.0840837, 12.2402679],
                [108.084105, 12.2399734],
                [108.0841168, 12.2396784],
                [108.084119, 12.2393832],
                [108.0841116, 12.239088],
                [108.0840946, 12.2387932],
                [108.0840681, 12.2384991],
                [108.0840321, 12.238206],
                [108.0839865, 12.2379141],
                [108.0839316, 12.2376238],
                [108.0838672, 12.2373353],
                [108.0837936, 12.237049],
                [108.083581, 12.2363339],
                [108.0835296, 12.2361148],
                [108.0834873, 12.2358939],
                [108.0834541, 12.2356715],
                [108.0834301, 12.235448],
                [108.0834153, 12.2352237],
                [108.0834098, 12.234999],
                [108.0834136, 12.2347743],
                [108.0834266, 12.2345499],
                [108.0834488, 12.2343262],
                [108.0834803, 12.2341036],
                [108.0835209, 12.2338823],
                [108.0835706, 12.2336629],
                [108.0836293, 12.2334456],
                [108.0836969, 12.2332308],
                [108.0837733, 12.2330188],
                [108.0838583, 12.23281],
                [108.083952, 12.2326047],
                [108.0842538, 12.2320266],
                [108.0844219, 12.2316354],
                [108.0845572, 12.2312323],
                [108.0846587, 12.2308198],
                [108.0847258, 12.2304006],
                [108.084758, 12.2299775],
                [108.0847552, 12.2295532],
                [108.0846968, 12.2288709],
                [108.0846887, 12.2286346],
                [108.0846898, 12.2283983],
                [108.0847001, 12.2281622],
                [108.0847197, 12.2279266],
                [108.0847484, 12.2276919],
                [108.0847863, 12.2274585],
                [108.0848333, 12.2272266],
                [108.0848893, 12.2269967],
                [108.0849542, 12.226769],
                [108.0850279, 12.226544],
                [108.0851104, 12.2263218],
                [108.0852015, 12.2261028],
                [108.0853011, 12.2258875],
                [108.0854091, 12.225676],
                [108.0855252, 12.2254686],
                [108.0856493, 12.2252658],
                [108.0857812, 12.2250677],
                [108.0860353, 12.2247273],
                [108.0862775, 12.2244428],
                [108.0866279, 12.2240059],
                [108.0873091, 12.2231175],
                [108.0879639, 12.2222104],
                [108.0884863, 12.2214444],
                [108.0886992, 12.2211439],
                [108.0889197, 12.2208487],
                [108.0891476, 12.2205589],
                [108.0893828, 12.2202748],
                [108.0896252, 12.2199964],
                [108.0898745, 12.219724],
                [108.0901307, 12.2194578],
                [108.0913101, 12.2183039],
                [108.0915891, 12.2180733],
                [108.091737, 12.2179684],
                [108.0920479, 12.2177808],
                [108.0923763, 12.2176242],
                [108.0927189, 12.2175002],
                [108.0932148, 12.2173673],
                [108.0933708, 12.2173126],
                [108.0935234, 12.2172494],
                [108.0936723, 12.217178],
                [108.0938168, 12.2170986],
                [108.0939565, 12.2170115],
                [108.0940911, 12.2169168],
                [108.09422, 12.216815],
                [108.0943429, 12.2167063],
                [108.094433, 12.2166148],
                [108.0945959, 12.2164172],
                [108.0947339, 12.2162022],
                [108.0947978, 12.2160791],
                [108.0949009, 12.2158224],
                [108.0949696, 12.2155549],
                [108.0950664, 12.2149696],
                [108.0951518, 12.2145225],
                [108.0952468, 12.2140772],
                [108.0953514, 12.2136339],
                [108.0954602, 12.2132133],
                [108.0957036, 12.2123789],
                [108.0958382, 12.2119655],
                [108.0960792, 12.2112833],
                [108.0961727, 12.2110497],
                [108.0962741, 12.2108651],
                [108.0963478, 12.2107684],
                [108.0965141, 12.2105904],
                [108.0967033, 12.2104357],
                [108.0968105, 12.2103648],
                [108.097039, 12.2102458],
                [108.097159, 12.2101983],
                [108.0974208, 12.2101142],
                [108.097676, 12.2100462],
                [108.0979456, 12.2099669],
                [108.0982133, 12.2098818],
                [108.0984789, 12.2097908],
                [108.0987395, 12.209695],
                [108.0989979, 12.2095936],
                [108.0992539, 12.2094866],
                [108.0995074, 12.209374],
                [108.1002392, 12.209053],
                [108.1004858, 12.2089518],
                [108.1007305, 12.2088464],
                [108.1009734, 12.2087369],
                [108.1012963, 12.2085835],
                [108.1016154, 12.2084228],
                [108.1019307, 12.2082548],
                [108.1022418, 12.2080797],
                [108.1025488, 12.2078977],
                [108.1028513, 12.2077086],
                [108.1031492, 12.2075128],
                [108.1034424, 12.2073102],
                [108.1037307, 12.2071011],
                [108.1039932, 12.2069004],
                [108.1042507, 12.2066937],
                [108.1045029, 12.2064809],
                [108.1046255, 12.2063705],
                [108.1047499, 12.2062622],
                [108.1049775, 12.2060734],
                [108.1052108, 12.2058915],
                [108.1054498, 12.2057167],
                [108.1055732, 12.2056347],
                [108.105694, 12.2055491],
                [108.1058613, 12.2054214],
                [108.1060229, 12.2052868],
                [108.1061784, 12.2051457],
                [108.1063277, 12.2049982],
                [108.1064704, 12.2048446],
                [108.1066063, 12.2046853],
                [108.1067352, 12.2045204],
                [108.1068567, 12.2043504],
                [108.1069708, 12.2041754],
                [108.1073178, 12.2036324],
                [108.1074842, 12.2033566],
                [108.1076574, 12.2030848],
                [108.107836, 12.2028194],
                [108.1080212, 12.2025583],
                [108.1082128, 12.2023017],
                [108.1084107, 12.2020497],
                [108.1086149, 12.2018025],
                [108.1088252, 12.2015603],
                [108.1090414, 12.2013231],
                [108.1095059, 12.2008196],
                [108.1099744, 12.2003198],
                [108.1101357, 12.2001578],
                [108.1102932, 12.1999924],
                [108.1104469, 12.1998235],
                [108.1106275, 12.1996151],
                [108.1108022, 12.1994019],
                [108.110971, 12.1991843],
                [108.1111337, 12.1989622],
                [108.1112902, 12.198736],
                [108.1114404, 12.1985057],
                [108.1115842, 12.1982715],
                [108.1117366, 12.1980094],
                [108.1118822, 12.1977436],
                [108.112021, 12.1974744],
                [108.1121528, 12.1972019],
                [108.1124409, 12.1965961],
                [108.1127235, 12.1959879],
                [108.1128591, 12.1956813],
                [108.1130004, 12.1953772],
                [108.1131518, 12.1950667],
                [108.1133092, 12.1947591],
                [108.1133683, 12.1946599],
                [108.1134227, 12.1945583],
                [108.1134725, 12.1944544],
                [108.1135286, 12.1943195],
                [108.1135769, 12.1941817],
                [108.113617, 12.1940415],
                [108.1136489, 12.1938992],
                [108.1136725, 12.1937554],
                [108.1136877, 12.1936105],
                [108.1136944, 12.1934651],
                [108.1136926, 12.1933194],
                [108.1136823, 12.1931742],
                [108.1136636, 12.1930297],
                [108.1136366, 12.1928865],
                [108.1136012, 12.192745],
                [108.1135596, 12.1925501],
                [108.1135259, 12.1923538],
                [108.1135001, 12.1921564],
                [108.1134823, 12.1919581],
                [108.1134725, 12.1917592],
                [108.1134527, 12.191524],
                [108.1134243, 12.1912895],
                [108.1128266, 12.1876302],
                [108.1127886, 12.1873702],
                [108.1127579, 12.1871092],
                [108.1127342, 12.1868476],
                [108.1127177, 12.1865854],
                [108.1127084, 12.1863229],
                [108.1127063, 12.1860603],
                [108.1127123, 12.1857707],
                [108.112727, 12.1854814],
                [108.1127478, 12.1852189],
                [108.1127692, 12.1850096],
                [108.1128002, 12.1848184],
                [108.1128228, 12.1847331],
                [108.1128503, 12.1846493],
                [108.1128874, 12.1845559],
                [108.1129796, 12.184377],
                [108.1133069, 12.1839049],
                [108.1136441, 12.183458],
                [108.1138182, 12.1832481],
                [108.1139997, 12.1830444],
                [108.1141885, 12.1828471],
                [108.1143844, 12.1826565],
                [108.1147652, 12.1823217],
                [108.1151855, 12.1819759],
                [108.1154008, 12.1817812],
                [108.1156095, 12.1815798],
                [108.1159777, 12.181189],
                [108.1162937, 12.1808095],
                [108.1164429, 12.180613],
                [108.1165862, 12.1804124],
                [108.1171524, 12.179568],
                [108.1174317, 12.1791652],
                [108.1175775, 12.1789681],
                [108.1178401, 12.1785681],
                [108.1180668, 12.1781605],
                [108.118217, 12.1778449],
                [108.1183294, 12.1775704],
                [108.1185007, 12.1770814],
                [108.1186313, 12.1767933],
                [108.1187062, 12.1766538],
                [108.1191963, 12.1758659],
                [108.1193605, 12.1756681],
                [108.1195003, 12.1755345],
                [108.1196346, 12.1754332],
                [108.1198162, 12.1753326],
                [108.1200117, 12.1752609],
                [108.1202162, 12.1752198],
                [108.1204247, 12.1752105],
                [108.1207305, 12.1752209],
                [108.1210522, 12.1752144],
                [108.1212125, 12.1751997],
                [108.1217293, 12.175118],
                [108.1219299, 12.1750973],
                [108.1223329, 12.1750824],
                [108.1227357, 12.175103],
                [108.122936, 12.1751266],
                [108.1233323, 12.1752],
                [108.1235046, 12.175246],
                [108.1236742, 12.1753009],
                [108.1238405, 12.1753647],
                [108.1240032, 12.175437],
                [108.1241616, 12.1755177],
                [108.1243154, 12.1756066],
                [108.124779, 12.1759116],
                [108.1262988, 12.1769461],
                [108.1268498, 12.1772827],
                [108.1271315, 12.1774409],
                [108.1277064, 12.1777368],
                [108.1289005, 12.1782833],
                [108.1290946, 12.1783871],
                [108.1292838, 12.1784992],
                [108.1294679, 12.1786193],
                [108.1296464, 12.1787471],
                [108.1298189, 12.1788825],
                [108.1300683, 12.1790967],
                [108.1302108, 12.1792531],
                [108.1302702, 12.1793361],
                [108.1303687, 12.1795144],
                [108.1304382, 12.1797052],
                [108.1304999, 12.1799286],
                [108.1305474, 12.1800501],
                [108.1306039, 12.1801679],
                [108.130669, 12.1802814],
                [108.1307424, 12.18039],
                [108.1308238, 12.180493],
                [108.1309126, 12.1805899],
                [108.1310084, 12.1806803],
                [108.1312823, 12.1809104],
                [108.1313847, 12.1809812],
                [108.131604, 12.1811003],
                [108.1318386, 12.1811869],
                [108.1320836, 12.1812393],
                [108.1327001, 12.1812856],
                [108.133249, 12.1813633],
                [108.1336227, 12.1813842],
                [108.1342267, 12.1813669],
                [108.1344566, 12.1813706],
                [108.1346863, 12.1813829],
                [108.1351433, 12.1814334],
                [108.1357027, 12.1815346],
                [108.1363624, 12.1816864],
                [108.1367222, 12.1817817],
                [108.1374351, 12.1819946],
                [108.1392707, 12.1826032],
                [108.139722, 12.1827831],
                [108.1401979, 12.1830049],
                [108.1406593, 12.1832543],
                [108.1413448, 12.1836645],
                [108.1420112, 12.1841037],
                [108.1429016, 12.1847225],
                [108.1432109, 12.1849282],
                [108.1438422, 12.1853207],
                [108.144326, 12.1855985],
                [108.1447846, 12.1858391],
                [108.145084, 12.1859825],
                [108.1453874, 12.1861176],
                [108.1456947, 12.1862441],
                [108.1460056, 12.1863619],
                [108.1463198, 12.186471],
                [108.1467967, 12.1866182],
                [108.1472516, 12.1867368],
                [108.1475483, 12.1868023],
                [108.1478468, 12.186859],
                [108.148147, 12.1869068],
                [108.1488779, 12.1870073],
                [108.1497334, 12.1871513],
                [108.1505835, 12.1873233],
                [108.1512715, 12.1874922],
                [108.1519516, 12.1876892],
                [108.1526228, 12.1879138],
                [108.1529546, 12.1880364],
                [108.15329, 12.1881922],
                [108.1534523, 12.1882803],
                [108.153765, 12.1884762],
                [108.1542317, 12.1888218],
                [108.1545903, 12.1890505],
                [108.1549665, 12.1892506],
                [108.1553578, 12.1894207],
                [108.1557343, 12.1896014],
                [108.1560956, 12.1898098],
                [108.1562698, 12.189924],
                [108.1564235, 12.1900362],
                [108.1565715, 12.1901555],
                [108.1567134, 12.1902817],
                [108.156849, 12.1904144],
                [108.1569778, 12.1905533],
                [108.1570997, 12.1906981],
                [108.1572142, 12.1908485],
                [108.157429, 12.1911738],
                [108.1575304, 12.1913473],
                [108.1577131, 12.1917045],
                [108.1578684, 12.1920739],
                [108.1579361, 12.1922157],
                [108.1580121, 12.1923535],
                [108.1580961, 12.1924869],
                [108.1581877, 12.1926153],
                [108.1582868, 12.1927383],
                [108.1583929, 12.1928556],
                [108.1585057, 12.1929667],
                [108.1586248, 12.1930713],
                [108.15875, 12.1931691],
                [108.1588806, 12.1932597],
                [108.159265, 12.1934874],
                [108.159518, 12.1936248],
                [108.1602673, 12.1939969],
                [108.1604946, 12.1941239],
                [108.1609352, 12.1944005],
                [108.1611479, 12.1945497],
                [108.1616409, 12.1949288],
                [108.1619222, 12.195157],
                [108.162471, 12.1956289],
                [108.1626358, 12.1957561],
                [108.1628063, 12.1958758],
                [108.1629822, 12.1959877],
                [108.1631632, 12.1960917],
                [108.1633489, 12.1961875],
                [108.1635388, 12.196275],
                [108.1637326, 12.1963538],
                [108.1639299, 12.196424],
                [108.1641303, 12.1964853],
                [108.1643333, 12.1965375],
                [108.1645385, 12.1965807],
                [108.1647456, 12.1966146],
                [108.1650573, 12.1966355],
                [108.1653693, 12.1966207],
                [108.1655242, 12.1966],
                [108.1658288, 12.1965323],
                [108.1661233, 12.1964302],
                [108.1664036, 12.1962953],
                [108.1665373, 12.1962161],
                [108.1666245, 12.1961526],
                [108.1667822, 12.1960064],
                [108.1669146, 12.1958379],
                [108.1670185, 12.1956512],
                [108.1670912, 12.195451],
                [108.1671309, 12.1952421],
                [108.1672091, 12.1940807],
                [108.1672346, 12.1935525],
                [108.1672405, 12.1931931],
                [108.1672371, 12.1928338],
                [108.1672244, 12.1924746],
                [108.1672025, 12.1921158],
                [108.1671853, 12.1914697],
                [108.1672007, 12.190662],
                [108.1672132, 12.1905008],
                [108.1672645, 12.1901761],
                [108.167303, 12.1900161],
                [108.1674053, 12.189703],
                [108.1675404, 12.1894021],
                [108.1676621, 12.1891862],
                [108.1677918, 12.1889956],
                [108.1679819, 12.1887685],
                [108.1680863, 12.188663],
                [108.1683122, 12.1884697],
                [108.168433, 12.1883826],
                [108.1686886, 12.1882286],
                [108.1690149, 12.1880729],
                [108.16921, 12.1879894],
                [108.1696075, 12.18784],
                [108.1701604, 12.1876663],
                [108.1708687, 12.1874722],
                [108.1712258, 12.1873862],
                [108.1716005, 12.1873037],
                [108.171791, 12.1872777],
                [108.1718847, 12.1872561],
                [108.1720902, 12.1871867],
                [108.1721999, 12.1871361],
                [108.1723053, 12.1870773],
                [108.1724057, 12.1870106],
                [108.1725004, 12.1869365],
                [108.172589, 12.1868554],
                [108.1726708, 12.1867679],
                [108.1727454, 12.1866743],
                [108.1728123, 12.1865754],
                [108.1728712, 12.1864717],
                [108.1729216, 12.1863638],
                [108.1729633, 12.1862524],
                [108.172996, 12.1861382],
                [108.1730884, 12.1857395],
                [108.1731487, 12.1853349],
                [108.1731667, 12.1851312],
                [108.1731784, 12.1847225],
                [108.1731711, 12.1844912],
                [108.1731539, 12.1842605],
                [108.1731268, 12.1840307],
                [108.1730899, 12.1838021],
                [108.1730432, 12.1835753],
                [108.1729868, 12.1833506],
                [108.1728143, 12.1827416],
                [108.1727171, 12.1823524],
                [108.1725791, 12.1818975],
                [108.1722499, 12.1809891],
                [108.1721978, 12.1807749],
                [108.1721704, 12.1805317],
                [108.1721754, 12.1802871],
                [108.1721987, 12.1801048],
                [108.1722366, 12.1799762],
                [108.1723059, 12.1798475],
                [108.1723505, 12.1797893],
                [108.1724575, 12.1796883],
                [108.1725186, 12.1796466],
                [108.1726915, 12.1795548],
                [108.1727831, 12.1795194],
                [108.1729735, 12.1794708],
                [108.1732337, 12.1794436],
                [108.1733969, 12.1794384],
                [108.17356, 12.1794421],
                [108.1737228, 12.1794548],
                [108.1738845, 12.1794765],
                [108.1740447, 12.179507],
                [108.1742818, 12.1795669],
                [108.1744397, 12.1796315],
                [108.1745167, 12.1796762],
                [108.1746561, 12.1797861],
                [108.1747171, 12.1798502],
                [108.174819, 12.1799941],
                [108.1748911, 12.1801543],
                [108.1749308, 12.1803249],
                [108.1749559, 12.1806881],
                [108.1749701, 12.1812407],
                [108.1749834, 12.1814798],
                [108.1750062, 12.1817182],
                [108.1750384, 12.1819556],
                [108.1750801, 12.1821916],
                [108.1751311, 12.1824258],
                [108.1752866, 12.1830285],
                [108.1755064, 12.1837212],
                [108.1755817, 12.183915],
                [108.175716, 12.1841759],
                [108.1758814, 12.1844193],
                [108.175975, 12.1845332],
                [108.1761825, 12.1847434],
                [108.1764701, 12.1849847],
                [108.176622, 12.1850955],
                [108.1769407, 12.185296],
                [108.1771907, 12.1854284],
                [108.1772768, 12.1854671],
                [108.1773963, 12.1855118],
                [108.1775188, 12.1855478],
                [108.1777068, 12.1855858],
                [108.1778632, 12.1855962],
                [108.1780477, 12.1855778],
                [108.1781379, 12.1855563],
                [108.1783105, 12.1854898],
                [108.178579, 12.1853268],
                [108.1786942, 12.1852668],
                [108.1789351, 12.1851684],
                [108.1790597, 12.1851304],
                [108.1794392, 12.1850446],
                [108.1799483, 12.1849532],
                [108.180149, 12.1849329],
                [108.1803486, 12.1849033],
                [108.1805465, 12.1848646],
                [108.1807423, 12.1848169],
                [108.1809377, 12.1847595],
                [108.18113, 12.184693],
                [108.1813828, 12.1845945],
                [108.181504, 12.1845337],
                [108.1815761, 12.1844851],
                [108.1817034, 12.1843679],
                [108.1818041, 12.1842281],
                [108.181874, 12.1840714],
                [108.1819102, 12.1839041],
                [108.1819113, 12.1837331],
                [108.1818986, 12.1836485],
                [108.1818162, 12.1833799],
                [108.1817786, 12.1831643],
                [108.1817641, 12.1828138],
                [108.1817609, 12.182492],
                [108.1817722, 12.1823314],
                [108.1818107, 12.1821614],
                [108.1818756, 12.1819993],
                [108.1819403, 12.181885],
                [108.1820133, 12.1817913],
                [108.1821265, 12.1816923],
                [108.1822585, 12.181619],
                [108.1823298, 12.181593],
                [108.1824787, 12.1815637],
                [108.1827528, 12.1815665],
                [108.1828748, 12.1815581],
                [108.1829957, 12.1815406],
                [108.183115, 12.1815142],
                [108.1832318, 12.181479],
                [108.1833456, 12.1814352],
                [108.1834557, 12.1813831],
                [108.1835613, 12.181323],
                [108.183662, 12.1812552],
                [108.1837572, 12.1811801],
                [108.1838462, 12.1810981],
                [108.1839286, 12.1810098],
                [108.1843049, 12.1805297],
                [108.1844437, 12.1803283],
                [108.1845663, 12.1801018],
                [108.1846858, 12.1798034],
                [108.1847091, 12.1796736],
                [108.1846986, 12.1795385],
                [108.1846539, 12.1794102],
                [108.1846195, 12.1793512],
                [108.1845779, 12.1792969],
                [108.1842955, 12.179008],
                [108.1841862, 12.1788811],
                [108.1841184, 12.1787849],
                [108.1840587, 12.1786838],
                [108.1840073, 12.1785784],
                [108.1839647, 12.1784694],
                [108.183931, 12.1783573],
                [108.1839065, 12.1782431],
                [108.1838914, 12.1781272],
                [108.1838865, 12.1778506],
                [108.1838971, 12.1776909],
                [108.1839174, 12.1775321],
                [108.1839474, 12.1773747],
                [108.183987, 12.1772194],
                [108.184302, 12.1762878],
                [108.18446, 12.1757232],
                [108.1845278, 12.1754006],
                [108.1845832, 12.1749797],
                [108.1846432, 12.1747483],
                [108.184738, 12.1745281],
                [108.1847978, 12.1744239],
                [108.1848914, 12.1743218],
                [108.1850068, 12.1742438],
                [108.1851377, 12.1741943],
                [108.1852067, 12.1741811],
                [108.185347, 12.1741789],
                [108.1854839, 12.1742087],
                [108.1856974, 12.1742886],
                [108.1858489, 12.1743335],
                [108.1860029, 12.1743695],
                [108.1861587, 12.1743967],
                [108.186316, 12.1744148],
                [108.186474, 12.1744239],
                [108.1866324, 12.1744239],
                [108.1869155, 12.1744006],
                [108.1871945, 12.1743477],
                [108.1873314, 12.1743103],
                [108.187598, 12.1742141],
                [108.1877198, 12.1741571],
                [108.1879511, 12.1740208],
                [108.1880595, 12.173942],
                [108.1882596, 12.1737648],
                [108.1883505, 12.1736671],
                [108.1886882, 12.1732616],
                [108.1887782, 12.1731654],
                [108.1889853, 12.1729813],
                [108.1890973, 12.1728992],
                [108.1892739, 12.172788],
                [108.1893361, 12.1727564],
                [108.1894878, 12.1727009],
                [108.1895667, 12.172683],
                [108.1896268, 12.1726786],
                [108.1897461, 12.1726931],
                [108.1898032, 12.1727117],
                [108.1899076, 12.1727701],
                [108.1900584, 12.1728898],
                [108.1901695, 12.1729634],
                [108.1902856, 12.173029],
                [108.1904046, 12.1731311],
                [108.1904575, 12.1732032],
                [108.1904984, 12.1733112],
                [108.1905058, 12.1733685],
                [108.1904939, 12.1734832],
                [108.1904748, 12.1735378],
                [108.1904126, 12.1736357],
                [108.1903708, 12.1736766],
                [108.1902709, 12.1737377],
                [108.1901569, 12.1737668],
                [108.1899718, 12.173778],
                [108.1897231, 12.1738223],
                [108.189483, 12.1738993],
                [108.1892557, 12.1740074],
                [108.1891483, 12.1740726],
                [108.1890647, 12.1741354],
                [108.1889872, 12.1742055],
                [108.1889166, 12.1742822],
                [108.1888534, 12.1743648],
                [108.1887982, 12.1744527],
                [108.1887515, 12.1745452],
                [108.1887135, 12.1746415],
                [108.1886846, 12.1747407],
                [108.1886651, 12.1748421],
                [108.1886551, 12.1749447],
                [108.1886548, 12.1750479],
                [108.1886803, 12.1752199],
                [108.1887378, 12.1753844],
                [108.1888254, 12.1755357],
                [108.18894, 12.1756687],
                [108.1890776, 12.1757788],
                [108.1892747, 12.1758873],
                [108.1893999, 12.1759424],
                [108.1895285, 12.1759892],
                [108.1896601, 12.1760275],
                [108.189794, 12.176057],
                [108.1899297, 12.1760776],
                [108.1900664, 12.1760893],
                [108.1902037, 12.1760919],
                [108.1903408, 12.1760855],
                [108.1904772, 12.1760701],
                [108.1906122, 12.1760458],
                [108.191004, 12.1759399],
                [108.1911344, 12.1759183],
                [108.1912661, 12.1759062],
                [108.1913983, 12.1759037],
                [108.1915303, 12.1759108],
                [108.1916615, 12.1759273],
                [108.191791, 12.1759533],
                [108.1919182, 12.1759886],
                [108.1920424, 12.176033],
                [108.1921629, 12.1760862],
                [108.192279, 12.176148],
                [108.1923902, 12.176218],
                [108.1926684, 12.1764304],
                [108.193, 12.1767154],
                [108.1931028, 12.1767903],
                [108.1932108, 12.1768578],
                [108.1934403, 12.1769694],
                [108.1936837, 12.1770478],
                [108.1939361, 12.1770915],
                [108.1941923, 12.1770996],
                [108.194447, 12.177072],
                [108.1946951, 12.1770091],
                [108.1949129, 12.1769206],
                [108.1950167, 12.1768658],
                [108.1952119, 12.1767363],
                [108.1953023, 12.1766622],
                [108.1954668, 12.176497],
                [108.1955401, 12.1764066],
                [108.1957327, 12.1761216],
                [108.1958507, 12.175927],
                [108.19617, 12.175332],
                [108.1964064, 12.1749588],
                [108.1965879, 12.1747275],
                [108.1966867, 12.1746183],
                [108.1967905, 12.1745137],
                [108.1971135, 12.1742229],
                [108.197296, 12.1740131],
                [108.1974504, 12.1737828],
                [108.1975742, 12.1735355],
                [108.1976241, 12.1734066],
                [108.1977092, 12.1732651],
                [108.1978196, 12.1731413],
                [108.1979513, 12.1730395],
                [108.1980289, 12.1729958],
                [108.1981107, 12.1729602],
                [108.1981959, 12.172933],
                [108.1982834, 12.1729146],
                [108.1983725, 12.172905],
                [108.198462, 12.1729045],
                [108.1985512, 12.1729131],
                [108.1986389, 12.1729305],
                [108.1987244, 12.1729568],
                [108.1988066, 12.1729915],
                [108.1990163, 12.1730987],
                [108.1991515, 12.1731554],
                [108.1994309, 12.1732453],
                [108.1997192, 12.1733027],
                [108.2000123, 12.1733269],
                [108.2001594, 12.1733264],
                [108.2007891, 12.1732807],
                [108.2013626, 12.173206],
                [108.201524, 12.1731706],
                [108.2017175, 12.1730964],
                [108.2018953, 12.172991],
                [108.2020521, 12.1728576],
                [108.2021834, 12.1727],
                [108.2022853, 12.1725229],
                [108.2023243, 12.1724286],
                [108.2024922, 12.1718065],
                [108.2025682, 12.1716176],
                [108.2026231, 12.1715159],
                [108.2026861, 12.1714188],
                [108.2027569, 12.1713269],
                [108.202835, 12.1712409],
                [108.2029199, 12.1711612],
                [108.203011, 12.1710884],
                [108.2031077, 12.171023],
                [108.2032095, 12.1709653],
                [108.2034573, 12.1708587],
                [108.2036021, 12.1708102],
                [108.2037496, 12.1707704],
                [108.2038994, 12.1707394],
                [108.2040509, 12.1707175],
                [108.2042034, 12.1707046],
                [108.2043564, 12.1707008],
                [108.2045057, 12.1707067],
                [108.2046741, 12.1707212],
                [108.2049424, 12.17078],
                [108.2050806, 12.1708223],
                [108.2052158, 12.1708735],
                [108.2053471, 12.1709333],
                [108.2054741, 12.1710014],
                [108.2055963, 12.1710776],
                [108.205713, 12.1711615],
                [108.2058238, 12.1712528],
                [108.2060829, 12.1714873],
                [108.206243, 12.1716175],
                [108.2066637, 12.1719322],
                [108.2072132, 12.1722936],
                [108.207447, 12.1724147],
                [108.2075691, 12.1724641],
                [108.2078218, 12.1725398],
                [108.2079512, 12.1725658],
                [108.2082139, 12.1725935],
                [108.2084781, 12.1725886],
                [108.2087395, 12.1725512],
                [108.2088679, 12.1725204],
                [108.2089899, 12.172521],
                [108.2090499, 12.1725319],
                [108.2091641, 12.1725738],
                [108.2092707, 12.1726445],
                [108.2093196, 12.1726908],
                [108.2093986, 12.1727987],
                [108.2094492, 12.1729219],
                [108.2094684, 12.1730533],
                [108.2094551, 12.1731853],
                [108.2093274, 12.1736009],
                [108.209281, 12.1737789],
                [108.2092286, 12.1740132],
                [108.2091857, 12.1742493],
                [108.2091522, 12.1744868],
                [108.2091228, 12.1746048],
                [108.2090853, 12.1747205],
                [108.2089863, 12.1749432],
                [108.2089254, 12.175049],
                [108.2087821, 12.1752472],
                [108.2087021, 12.175398],
                [108.2086544, 12.1755614],
                [108.2086433, 12.1756457],
                [108.2086472, 12.1758156],
                [108.2086621, 12.1758994],
                [108.208753, 12.1761927],
                [108.2088298, 12.1764011],
                [108.2089158, 12.1766059],
                [108.2093802, 12.1775417],
                [108.2096243, 12.1780893],
                [108.2097034, 12.1782429],
                [108.2098837, 12.1785383],
                [108.210092, 12.1788156],
                [108.210206, 12.1789466],
                [108.2104525, 12.179192],
                [108.2107704, 12.1794563],
                [108.2111583, 12.179737],
                [108.211566, 12.1799895],
                [108.2119914, 12.1802125],
                [108.2124151, 12.1803979],
                [108.2126231, 12.1804761],
                [108.2130468, 12.1806115],
                [108.2131561, 12.1806571],
                [108.2133629, 12.1807712],
                [108.2135506, 12.1809135],
                [108.2136359, 12.1809944],
                [108.2137873, 12.1811733],
                [108.2138826, 12.1813205],
                [108.2139528, 12.1814619],
                [108.2139884, 12.1815548],
                [108.2140388, 12.1817468],
                [108.2140533, 12.1818449],
                [108.2140691, 12.1821397],
                [108.2140857, 12.1822354],
                [108.2141105, 12.1823293],
                [108.2141836, 12.1825096],
                [108.2142315, 12.1825946],
                [108.2143481, 12.1827515],
                [108.214416, 12.1828223],
                [108.2146507, 12.18302],
                [108.2148172, 12.1831459],
                [108.2151663, 12.183376],
                [108.2153481, 12.1834797],
                [108.2156554, 12.1836229],
                [108.2158142, 12.1836826],
                [108.2161404, 12.1837776],
                [108.2163068, 12.1838127],
                [108.2166441, 12.1838573],
                [108.2168991, 12.1838691],
                [108.2171189, 12.1838586],
                [108.2173848, 12.1838141],
                [108.2176422, 12.1837355],
                [108.2178868, 12.1836242],
                [108.2180028, 12.1835569],
                [108.2182199, 12.1834004],
                [108.2184013, 12.1832288],
                [108.2185458, 12.1830473],
                [108.2186638, 12.1828484],
                [108.2187532, 12.1826357],
                [108.2187867, 12.1825254],
                [108.2188341, 12.1822975],
                [108.2188475, 12.1821819],
                [108.2188533, 12.1819494],
                [108.2188457, 12.1818332],
                [108.2188567, 12.18164],
                [108.2188968, 12.1814505],
                [108.2189275, 12.1813584],
                [108.2190095, 12.1811823],
                [108.2191241, 12.1810121],
                [108.2191955, 12.180931],
                [108.219274, 12.1808562],
                [108.2193588, 12.1807885],
                [108.2194494, 12.1807283],
                [108.219545, 12.1806762],
                [108.219645, 12.1806324],
                [108.2198113, 12.1805317],
                [108.2199574, 12.1804046],
                [108.2200215, 12.1803324],
                [108.2201297, 12.180173],
                [108.2202559, 12.1799008],
                [108.2203113, 12.1798077],
                [108.220523, 12.1795314],
                [108.2205949, 12.1794221],
                [108.2207179, 12.1791918],
                [108.2207684, 12.1790717],
                [108.2209874, 12.1783783],
                [108.2211041, 12.1779267],
                [108.2211607, 12.1776625],
                [108.2212324, 12.1772637],
                [108.2212596, 12.1769738],
                [108.2212611, 12.1768176],
                [108.2212533, 12.1766615],
                [108.2212363, 12.1765062],
                [108.2212102, 12.176352],
                [108.2211751, 12.1761996],
                [108.2210749, 12.1758853],
                [108.2210115, 12.1757237],
                [108.2208627, 12.1754097],
                [108.2207647, 12.175166],
                [108.2206914, 12.174914],
                [108.2206478, 12.1746689],
                [108.220638, 12.1744333],
                [108.2206589, 12.1741984],
                [108.2207085, 12.1739995],
                [108.2207449, 12.1739034],
                [108.2208396, 12.1737207],
                [108.2209618, 12.1735543],
                [108.2210323, 12.1734785],
                [108.2211085, 12.1734081],
                [108.2214403, 12.1731538],
                [108.2216965, 12.1729716],
                [108.2220903, 12.1727115],
                [108.2223731, 12.172549],
                [108.2226775, 12.1724027],
                [108.2229924, 12.1722792],
                [108.2232134, 12.1722305],
                [108.2233262, 12.1722193],
                [108.2235527, 12.1722236],
                [108.2237209, 12.1722489],
                [108.223844, 12.1722893],
                [108.2239088, 12.1723227],
                [108.2240245, 12.1724103],
                [108.2240738, 12.1724634],
                [108.2241519, 12.1725844],
                [108.2241994, 12.1727197],
                [108.2242378, 12.1728704],
                [108.224296, 12.1731759],
                [108.2243606, 12.1733912],
                [108.224456, 12.1735954],
                [108.2245803, 12.1737842],
                [108.2246179, 12.1738548],
                [108.2246695, 12.1740056],
                [108.2246877, 12.1741636],
                [108.2246718, 12.1743219],
                [108.2246223, 12.1744734],
                [108.2245375, 12.174654],
                [108.2244976, 12.1747667],
                [108.2244663, 12.1748819],
                [108.2244297, 12.1751175],
                [108.2244055, 12.1755281],
                [108.2243764, 12.1758187],
                [108.2243666, 12.1760114],
                [108.2243696, 12.176397],
                [108.2244024, 12.1767814],
                [108.2244666, 12.177169],
                [108.2245632, 12.1775574],
                [108.2247592, 12.1781587],
                [108.2249041, 12.178566],
                [108.225012, 12.1788335],
                [108.2251034, 12.1791292],
                [108.2251416, 12.1792901],
                [108.2251954, 12.1796161],
                [108.2252353, 12.1801459],
                [108.225292, 12.1805439],
                [108.2253796, 12.1809366],
                [108.2254338, 12.18113],
                [108.2255622, 12.1815108],
                [108.225757, 12.1819745],
                [108.2258676, 12.1821688],
                [108.2259399, 12.1822684],
                [108.2260199, 12.1823623],
                [108.2261071, 12.1824497],
                [108.2262011, 12.1825303],
                [108.2263011, 12.1826036],
                [108.2264067, 12.182669],
                [108.2265171, 12.1827262],
                [108.2266317, 12.1827748],
                [108.2267499, 12.1828146],
                [108.2270138, 12.1828745],
                [108.2273033, 12.1829112],
                [108.2276852, 12.1829256],
                [108.2278633, 12.1829554],
                [108.227939, 12.1829604],
                [108.2280896, 12.1829441],
                [108.2282319, 12.1828934],
                [108.228358, 12.1828112],
                [108.2284606, 12.1827022],
                [108.2285012, 12.1826395],
                [108.2288641, 12.1818635],
                [108.229081, 12.1814609],
                [108.2291764, 12.1812693],
                [108.229263, 12.1810738],
                [108.2293407, 12.1808747],
                [108.2294091, 12.1806724],
                [108.2294683, 12.1804673],
                [108.2295181, 12.1802598],
                [108.2296218, 12.1797559],
                [108.2296745, 12.179562],
                [108.2297068, 12.1793441],
                [108.2297109, 12.1791047],
                [108.2297009, 12.1789853],
                [108.2296567, 12.1787497],
                [108.2296228, 12.1786346],
                [108.2295967, 12.1784414],
                [108.2296072, 12.1782468],
                [108.2296261, 12.178151],
                [108.2296905, 12.1779666],
                [108.2298047, 12.1777626],
                [108.2298817, 12.1776504],
                [108.2299662, 12.1775435],
                [108.2300578, 12.1774423],
                [108.2301561, 12.1773473],
                [108.2302606, 12.1772589],
                [108.2303709, 12.1771775],
                [108.2304865, 12.1771034],
                [108.2308715, 12.1768836],
                [108.2312728, 12.1766938],
                [108.2314789, 12.1766105],
                [108.2320088, 12.1764137],
                [108.2322767, 12.1763233],
                [108.2332668, 12.1760169],
                [108.2335501, 12.1759131],
                [108.2338414, 12.1758335],
                [108.2341637, 12.1757753],
                [108.2343392, 12.1757561],
                [108.2346919, 12.1757438],
                [108.2348683, 12.1757506],
                [108.2352189, 12.1757901],
                [108.2353923, 12.1758227],
                [108.2357329, 12.1759131],
                [108.2359718, 12.1759572],
                [108.2362143, 12.175973],
                [108.2365795, 12.1759569],
                [108.2367019, 12.1759602],
                [108.2369453, 12.1759865],
                [108.2371388, 12.1759842],
                [108.2373287, 12.1759472],
                [108.2374202, 12.1759161],
                [108.2375925, 12.1758299],
                [108.2377457, 12.1757142],
                [108.2378135, 12.1756466],
                [108.2379287, 12.1754945],
                [108.2380133, 12.1753243],
                [108.2380646, 12.1751418],
                [108.2380992, 12.1748316],
                [108.2381117, 12.1746147],
                [108.2381147, 12.1743973],
                [108.2381081, 12.1741801],
                [108.238092, 12.1739633],
                [108.2380664, 12.1737474],
                [108.2380012, 12.1733674],
                [108.2378709, 12.1728029],
                [108.2378171, 12.1724197],
                [108.2377982, 12.1720334],
                [108.2378146, 12.1716469],
                [108.2378659, 12.1712634],
                [108.2379046, 12.1710737],
                [108.2379519, 12.1708858],
                [108.2381207, 12.1703532],
                [108.2381966, 12.1700197],
                [108.2382233, 12.1698508],
                [108.2382425, 12.1696809],
                [108.2382683, 12.169234],
                [108.2382766, 12.1689574],
                [108.2382756, 12.168404],
                [108.2382823, 12.1681604],
                [108.238323, 12.1676748],
                [108.2383996, 12.1671934],
                [108.2384514, 12.1669551],
                [108.2386689, 12.1661637],
                [108.2387482, 12.1658407],
                [108.2388818, 12.1651894],
                [108.2389233, 12.1649157],
                [108.2389551, 12.1646407],
                [108.2390099, 12.1638476],
                [108.2390402, 12.1636079],
                [108.23908, 12.1633695],
                [108.2391292, 12.1631328],
                [108.2391877, 12.1628981],
                [108.2392555, 12.1626659],
                [108.23952, 12.1618774],
                [108.2396275, 12.1616026],
                [108.2396994, 12.161455],
                [108.2397797, 12.1613117],
                [108.2398682, 12.1611731],
                [108.2399647, 12.1610396],
                [108.2401222, 12.1608488],
                [108.2402504, 12.160728],
                [108.2403647, 12.1606457],
                [108.2404729, 12.1605894],
                [108.2406163, 12.1605448],
                [108.2407659, 12.1605291],
                [108.2409157, 12.1605427],
                [108.2410812, 12.1605905],
                [108.2412571, 12.1606611],
                [108.2414506, 12.1607459],
                [108.2416398, 12.1608395],
                [108.2418244, 12.1609416],
                [108.2420039, 12.1610519],
                [108.2421334, 12.1611191],
                [108.2424035, 12.1612302],
                [108.2428335, 12.1613548],
                [108.2429787, 12.1614094],
                [108.2431202, 12.1614725],
                [108.2432576, 12.1615437],
                [108.2433904, 12.1616229],
                [108.243518, 12.1617099],
                [108.2436401, 12.1618041],
                [108.2437561, 12.1619054],
                [108.2438657, 12.1620133],
                [108.2439684, 12.1621275],
                [108.2441629, 12.1623844],
                [108.2442552, 12.1625257],
                [108.2444187, 12.1628204],
                [108.2446302, 12.1633012],
                [108.244714, 12.1634706],
                [108.2450938, 12.1641354],
                [108.2452307, 12.1643933],
                [108.24536, 12.1646549],
                [108.245595, 12.1651886],
                [108.2457981, 12.1657346],
                [108.2458875, 12.1660117],
                [108.2460415, 12.1665729],
                [108.246162, 12.1671418],
                [108.2462549, 12.1677298],
                [108.2462747, 12.1679321],
                [108.2463246, 12.1681338],
                [108.2463614, 12.1682335],
                [108.2464568, 12.1684239],
                [108.2465796, 12.1685985],
                [108.2467271, 12.1687538],
                [108.2468963, 12.1688864],
                [108.2470112, 12.1689596],
                [108.2471309, 12.1690248],
                [108.2472551, 12.1690819],
                [108.2473829, 12.1691304],
                [108.2475138, 12.1691702],
                [108.2477921, 12.1692259],
                [108.2479382, 12.169242],
                [108.2483901, 12.1692554],
                [108.2487038, 12.1692961],
                [108.2490108, 12.1693714],
                [108.2491604, 12.1694218],
                [108.2494497, 12.1695471],
                [108.2497499, 12.1697136],
                [108.2500323, 12.1699076],
                [108.2502942, 12.1701272],
                [108.2505333, 12.1703704],
                [108.2507549, 12.1705724],
                [108.2509962, 12.1707515],
                [108.2512819, 12.1709217],
                [108.2514451, 12.1710031],
                [108.2516125, 12.171076],
                [108.2517836, 12.17114],
                [108.2519579, 12.1711951],
                [108.252135, 12.171241],
                [108.2525571, 12.1713333],
                [108.2531574, 12.1714977],
                [108.2532746, 12.1715398],
                [108.2533878, 12.1715902],
                [108.2534968, 12.1716488],
                [108.2536011, 12.1717152],
                [108.2536999, 12.1717891],
                [108.2537928, 12.17187],
                [108.2538792, 12.1719575],
                [108.2540307, 12.1721503],
                [108.2540949, 12.1722545],
                [108.2541509, 12.1723631],
                [108.2541984, 12.1724755],
                [108.2542372, 12.1725911],
                [108.254267, 12.1727092],
                [108.2542915, 12.1729219],
                [108.2542801, 12.1731356],
                [108.2542609, 12.1732411],
                [108.2541963, 12.1734456],
                [108.2540982, 12.173637],
                [108.2539694, 12.17381],
                [108.2538132, 12.1739601],
                [108.2535177, 12.1741631],
                [108.2532975, 12.1743389],
                [108.2529663, 12.1746505],
                [108.2527428, 12.1748708],
                [108.2526024, 12.1750259],
                [108.2525235, 12.1750951],
                [108.2524404, 12.1751575],
                [108.252261, 12.1752629],
                [108.2521656, 12.1753054],
                [108.2519664, 12.1753685],
                [108.2518637, 12.1753887],
                [108.2516929, 12.1753951],
                [108.2515242, 12.1753682],
                [108.2514428, 12.1753426],
                [108.2512897, 12.1752681],
                [108.2512106, 12.1752358],
                [108.2511281, 12.1752126],
                [108.2510435, 12.1751989],
                [108.2509578, 12.1751949],
                [108.2508722, 12.1752006],
                [108.2507878, 12.175216],
                [108.2507059, 12.1752407],
                [108.2506274, 12.1752746],
                [108.2505535, 12.1753172],
                [108.2504022, 12.1754395],
                [108.2503261, 12.175518],
                [108.2502572, 12.1756026],
                [108.250196, 12.1756928],
                [108.2500512, 12.1759714],
                [108.2499964, 12.1760516],
                [108.2498666, 12.1761966],
                [108.2497132, 12.1763177],
                [108.2496291, 12.1763681],
                [108.2494091, 12.1764813],
                [108.2491547, 12.1766368],
                [108.2490047, 12.1767679],
                [108.2488799, 12.1769222],
                [108.2487841, 12.1770952],
                [108.2487202, 12.1772817],
                [108.2486797, 12.1774898],
                [108.2486665, 12.1777012],
                [108.2486534, 12.177791],
                [108.2486307, 12.177879],
                [108.2485989, 12.1779642],
                [108.2485582, 12.1780457],
                [108.2484107, 12.178262],
                [108.2483779, 12.178334],
                [108.248354, 12.1784094],
                [108.2483393, 12.1784869],
                [108.248334, 12.1785656],
                [108.2483382, 12.1786444],
                [108.2483518, 12.1787222],
                [108.2483746, 12.1787978],
                [108.2484063, 12.1788704],
                [108.2484919, 12.1790271],
                [108.2485646, 12.1792114],
                [108.2486471, 12.1793653],
                [108.2487575, 12.1795015],
                [108.2490098, 12.1797154],
                [108.2491213, 12.1798226],
                [108.2492258, 12.1799363],
                [108.2493228, 12.1800561],
                [108.2495176, 12.180342],
                [108.2497262, 12.1806728],
                [108.2498444, 12.1808337],
                [108.2498905, 12.1808821],
                [108.2499701, 12.180989],
                [108.2500407, 12.1811306],
                [108.2500653, 12.1812058],
                [108.250092, 12.1813611],
                [108.2500805, 12.1817138],
                [108.2500826, 12.1819091],
                [108.2500944, 12.1821041],
                [108.250116, 12.1822983],
                [108.2501472, 12.1824912],
                [108.250188, 12.1826824],
                [108.2503494, 12.1833111],
                [108.2504529, 12.1837524],
                [108.2505979, 12.1842381],
                [108.2507733, 12.1847142],
                [108.2510897, 12.185438],
                [108.2513808, 12.1861922],
                [108.2515837, 12.1866376],
                [108.2516954, 12.1868557],
                [108.2519388, 12.1872813],
                [108.2520111, 12.1874247],
                [108.2521319, 12.1877218],
                [108.2522516, 12.1879658],
                [108.2524001, 12.1881942],
                [108.2525754, 12.1884037],
                [108.2526723, 12.1885004],
                [108.252883, 12.1886761],
                [108.253231, 12.1889436],
                [108.2539397, 12.1894626],
                [108.2541675, 12.189641],
                [108.2543893, 12.1898265],
                [108.2546049, 12.1900187],
                [108.2548141, 12.1902177],
                [108.2549315, 12.1903118],
                [108.2551817, 12.1904795],
                [108.2553138, 12.1905525],
                [108.2555899, 12.1906756],
                [108.2558786, 12.1907669],
                [108.2560265, 12.1908002],
                [108.2563269, 12.1908416],
                [108.2566251, 12.1908498],
                [108.2571648, 12.1908068],
                [108.2574077, 12.1907964],
                [108.257894, 12.1908015],
                [108.2583786, 12.1908411],
                [108.2586194, 12.1908738],
                [108.2593494, 12.1910015],
                [108.2595096, 12.1910191],
                [108.2596708, 12.1910588],
                [108.2597513, 12.1910876],
                [108.259905, 12.1911615],
                [108.2599747, 12.191209],
                [108.2600383, 12.1912641],
                [108.260095, 12.1913261],
                [108.260144, 12.1913941],
                [108.2601845, 12.1914671],
                [108.2603234, 12.1918222],
                [108.2603949, 12.1919854],
                [108.2605541, 12.1923046],
                [108.2605793, 12.1923843],
                [108.2605955, 12.1924662],
                [108.2606025, 12.1925494],
                [108.2606002, 12.1926328],
                [108.260568, 12.1927965],
                [108.2605385, 12.1928748],
                [108.2602513, 12.1934074],
                [108.2601922, 12.1935435],
                [108.260097, 12.1938243],
                [108.2600613, 12.1939681],
                [108.2600146, 12.1942575],
                [108.2600002, 12.1945474],
                [108.2600176, 12.1948371],
                [108.2599988, 12.1950051],
                [108.2599446, 12.1951656],
                [108.2597953, 12.1954806],
                [108.2595985, 12.1960291],
                [108.2595137, 12.1962248],
                [108.2594542, 12.1964352],
                [108.2594348, 12.1965457],
                [108.2594192, 12.1967693],
                [108.2594348, 12.1969929],
                [108.2594542, 12.1971034],
                [108.259536, 12.1974557],
                [108.259589, 12.1977947],
                [108.2596463, 12.1979459],
                [108.2597747, 12.1981552],
                [108.2598087, 12.1982312],
                [108.2598349, 12.1983101],
                [108.2598631, 12.1984736],
                [108.2598647, 12.1985566],
                [108.2598432, 12.198721],
                [108.2598202, 12.1988009],
                [108.2597508, 12.1989521],
                [108.2597051, 12.199022],
                [108.2596246, 12.1991185],
                [108.2595426, 12.1991848],
                [108.2594866, 12.1992153],
                [108.2594435, 12.1992502],
                [108.2594071, 12.1992918],
                [108.2593786, 12.1993389],
                [108.2593587, 12.19939],
                [108.2593481, 12.1994437],
                [108.259352, 12.1996069],
                [108.2593663, 12.1997146],
                [108.2593898, 12.1998207],
                [108.2594223, 12.1999245],
                [108.2594636, 12.2000253],
                [108.2595134, 12.2001224],
                [108.2596923, 12.2004179],
                [108.2597967, 12.2005481],
                [108.2599414, 12.2006801],
                [108.2600217, 12.2007369],
                [108.2601954, 12.2008297],
                [108.2602875, 12.2008653],
                [108.2604841, 12.2009167],
                [108.2605871, 12.2009342],
                [108.2609185, 12.200965],
                [108.2610401, 12.2009879],
                [108.2612766, 12.2010591],
                [108.2615001, 12.201163],
                [108.261706, 12.2012974],
                [108.2618009, 12.2013751],
                [108.2620303, 12.201585],
                [108.2623243, 12.2018212],
                [108.2627804, 12.2021667],
                [108.2631321, 12.2024075],
                [108.2633332, 12.2025305],
                [108.2637485, 12.2027543],
                [108.2639623, 12.2028548],
                [108.2644004, 12.2030324],
                [108.2645763, 12.2030896],
                [108.2649354, 12.2031798],
                [108.2653016, 12.2032369],
                [108.2655755, 12.2032972],
                [108.2658414, 12.2033853],
                [108.2659704, 12.2034395],
                [108.2663367, 12.2036232],
                [108.266581, 12.2037157],
                [108.2668342, 12.2037812],
                [108.2670933, 12.2038189],
                [108.267189, 12.203819],
                [108.2672843, 12.2038098],
                [108.2673781, 12.2037913],
                [108.2674696, 12.2037638],
                [108.2675578, 12.2037275],
                [108.2676419, 12.2036828],
                [108.2677209, 12.2036301],
                [108.2679438, 12.203454],
                [108.268157, 12.2033209],
                [108.2683861, 12.2032159],
                [108.2685654, 12.2031241],
                [108.2686596, 12.203088],
                [108.2688687, 12.2030336],
                [108.2689828, 12.2030177],
                [108.2690979, 12.203011],
                [108.2692131, 12.2030137],
                [108.2693278, 12.2030256],
                [108.269441, 12.2030467],
                [108.2695521, 12.2030769],
                [108.2696603, 12.2031159],
                [108.2697648, 12.2031635],
                [108.2699619, 12.2032759],
                [108.2701434, 12.2034112],
                [108.270436, 12.2036883],
                [108.2707128, 12.2039106],
                [108.2708594, 12.2040113],
                [108.2711673, 12.2041902],
                [108.2714093, 12.2043045],
                [108.2716126, 12.2043804],
                [108.2717353, 12.2044164],
                [108.2719856, 12.204469],
                [108.2721962, 12.2045319],
                [108.2722976, 12.204574],
                [108.2724437, 12.2046493],
                [108.2725576, 12.2047305],
                [108.2726194, 12.204789],
                [108.2727223, 12.2049234],
                [108.2727942, 12.2050759],
                [108.2728317, 12.2052397],
                [108.272837, 12.2053236],
                [108.2728315, 12.2055586],
                [108.2728476, 12.2058604],
                [108.2728988, 12.2063405],
                [108.272953, 12.2069671],
                [108.2730263, 12.2075572],
                [108.2730474, 12.2077981],
                [108.2730585, 12.2080396],
                [108.2730768, 12.2082182],
                [108.2731389, 12.208572],
                [108.2732345, 12.2089186],
                [108.2734275, 12.2094472],
                [108.2735674, 12.2098036],
                [108.2738679, 12.2105082],
                [108.2741082, 12.2110305],
                [108.2742953, 12.211375],
                [108.2744029, 12.2115442],
                [108.2745184, 12.2117083],
                [108.2746416, 12.211867],
                [108.2747721, 12.21202],
                [108.2749097, 12.2121669],
                [108.2752883, 12.2125275],
                [108.2755274, 12.2127424],
                [108.2760197, 12.2131568],
                [108.2761366, 12.2132682],
                [108.2763533, 12.2135069],
                [108.2765454, 12.213765],
                [108.2766136, 12.2138441],
                [108.2766877, 12.213918],
                [108.2768517, 12.2140486],
                [108.2770339, 12.2141537],
                [108.2772301, 12.214231],
                [108.2773321, 12.2142587],
                [108.2775701, 12.2142978],
                [108.2778407, 12.2143128],
                [108.2781112, 12.2142969],
                [108.278378, 12.2142504],
                [108.2785089, 12.2142159],
                [108.2787849, 12.2141173],
                [108.2789289, 12.2140526],
                [108.2790688, 12.21398],
                [108.2794679, 12.2137346],
                [108.2796055, 12.2136652],
                [108.2797473, 12.2136044],
                [108.2798928, 12.2135522],
                [108.2800412, 12.2135091],
                [108.2801921, 12.213475],
                [108.2806372, 12.2134128],
                [108.2809089, 12.2133467],
                [108.2811715, 12.2132516],
                [108.2814601, 12.2131258],
                [108.2817909, 12.2130085],
                [108.2819594, 12.2129589],
                [108.2824823, 12.2128281],
                [108.2831764, 12.2126289],
                [108.283856, 12.2123981],
                [108.2841913, 12.2122706],
                [108.2848519, 12.2119919],
                [108.2851767, 12.2118408],
                [108.2854429, 12.2117067],
                [108.2859626, 12.211416],
                [108.2864642, 12.2110963],
                [108.2866318, 12.211],
                [108.2868039, 12.2109118],
                [108.2869802, 12.2108317],
                [108.2871602, 12.21076],
                [108.2873436, 12.2106968],
                [108.2875298, 12.2106423],
                [108.2877185, 12.2105966],
                [108.2879093, 12.2105599],
                [108.2881016, 12.2105322],
                [108.288295, 12.2105135],
                [108.2884892, 12.2105039],
                [108.2886836, 12.2105035],
                [108.2888778, 12.2105122],
                [108.2893167, 12.21056],
                [108.2895609, 12.2105981],
                [108.2898037, 12.2106443],
                [108.2902837, 12.2107608],
                [108.2904204, 12.2107829],
                [108.2905584, 12.2107958],
                [108.290697, 12.2107994],
                [108.2908355, 12.2107936],
                [108.2909732, 12.2107785],
                [108.2911096, 12.2107542],
                [108.2912439, 12.2107208],
                [108.2913755, 12.2106784],
                [108.2915039, 12.2106272],
                [108.2916283, 12.2105675],
                [108.2917483, 12.2104996],
                [108.2918631, 12.2104238],
                [108.2919724, 12.2103404],
                [108.2920755, 12.2102498],
                [108.2928572, 12.2094852],
                [108.2932224, 12.2091602],
                [108.2934532, 12.208972],
                [108.2936901, 12.2087914],
                [108.2939331, 12.2086185],
                [108.2941817, 12.2084534],
                [108.2944357, 12.2082965],
                [108.29456, 12.208204],
                [108.2946783, 12.2081045],
                [108.2947904, 12.2079983],
                [108.2948958, 12.2078857],
                [108.2949942, 12.2077671],
                [108.2950851, 12.2076431],
                [108.2951684, 12.2075139],
                [108.2952436, 12.2073802],
                [108.2953105, 12.2072423],
                [108.2953689, 12.2071008],
                [108.2954186, 12.2069561],
                [108.2954594, 12.2068088],
                [108.2954911, 12.2066593],
                [108.2955136, 12.2065083],
                [108.2955269, 12.2063562],
                [108.2955309, 12.2062037],
                [108.2955256, 12.2060511],
                [108.295511, 12.2058992],
                [108.2954276, 12.2054133],
                [108.2952818, 12.2047482],
                [108.2951957, 12.2044187],
                [108.2951263, 12.2042023],
                [108.2950792, 12.2039596],
                [108.2950626, 12.203693],
                [108.2950677, 12.2035595],
                [108.2951049, 12.2032949],
                [108.2951771, 12.2030373],
                [108.2952833, 12.2027912],
                [108.2953908, 12.2026352],
                [108.2955263, 12.2025017],
                [108.2956849, 12.2023953],
                [108.2957712, 12.2023535],
                [108.295954, 12.2022945],
                [108.2961447, 12.20227],
                [108.296241, 12.202271],
                [108.2964312, 12.2022994],
                [108.2965235, 12.2023266],
                [108.296698, 12.2024057],
                [108.2968218, 12.2024884],
                [108.2971238, 12.2027481],
                [108.2972866, 12.202904],
                [108.2974426, 12.2030666],
                [108.2975914, 12.2032353],
                [108.2977329, 12.20341],
                [108.2978667, 12.2035904],
                [108.2979926, 12.2037762],
                [108.2981105, 12.2039669],
                [108.2983006, 12.2043198],
                [108.2984374, 12.2046457],
                [108.2985406, 12.2049831],
                [108.2986539, 12.2055408],
                [108.298769, 12.2059586],
                [108.298918, 12.2063661],
                [108.2990999, 12.2067605],
                [108.2993135, 12.2071395],
                [108.2995574, 12.2075006],
                [108.2998301, 12.2078414],
                [108.2999767, 12.2080036],
                [108.3002892, 12.20831],
                [108.3006425, 12.2086064],
                [108.3010341, 12.2088917],
                [108.3012374, 12.2090241],
                [108.3016577, 12.2092674],
                [108.3020945, 12.2094812],
                [108.3025455, 12.2096644],
                [108.3027758, 12.2097443],
                [108.3032441, 12.2098799],
                [108.3034287, 12.2099411],
                [108.3036099, 12.2100111],
                [108.3037873, 12.2100899],
                [108.3039605, 12.2101773],
                [108.304129, 12.210273],
                [108.3042923, 12.2103768],
                [108.3044502, 12.2104884],
                [108.3046022, 12.2106076],
                [108.304748, 12.2107341],
                [108.3048871, 12.2108675],
                [108.3050192, 12.2110075],
                [108.3054239, 12.2115042],
                [108.3055938, 12.2116966],
                [108.3057706, 12.2118828],
                [108.3059542, 12.2120628],
                [108.3061442, 12.2122362],
                [108.3063405, 12.2124029],
                [108.3065427, 12.2125625],
                [108.3067507, 12.212715],
                [108.3069641, 12.2128601],
                [108.3071827, 12.2129977],
                [108.3074062, 12.2131275],
                [108.3076344, 12.2132494],
                [108.3078668, 12.2133632],
                [108.3081033, 12.2134688],
                [108.3083435, 12.2135661],
                [108.3088877, 12.2137714],
                [108.3091857, 12.2138945],
                [108.3094807, 12.2140242],
                [108.3101589, 12.2143478],
                [108.3103466, 12.2144519],
                [108.3104634, 12.2145302],
                [108.3105746, 12.2146161],
                [108.3106796, 12.214709],
                [108.310778, 12.2148087],
                [108.3108692, 12.2149147],
                [108.310953, 12.2150264],
                [108.3110288, 12.2151434],
                [108.3110964, 12.2152651],
                [108.3111555, 12.215391],
                [108.3112057, 12.2155205],
                [108.3112469, 12.215653],
                [108.3112789, 12.2157879],
                [108.3113015, 12.2159246],
                [108.3113146, 12.2160625],
                [108.3113182, 12.216201],
                [108.3112981, 12.2165318],
                [108.3112773, 12.2167237],
                [108.3112156, 12.2171048],
                [108.3112099, 12.21721],
                [108.3112134, 12.2173154],
                [108.3112478, 12.2175232],
                [108.3113178, 12.2177224],
                [108.3114214, 12.217907],
                [108.3114849, 12.2179922],
                [108.3115556, 12.2180718],
                [108.3116329, 12.2181452],
                [108.3117164, 12.2182119],
                [108.3118054, 12.2182714],
                [108.3119973, 12.2183672],
                [108.3122031, 12.2184297],
                [108.3124167, 12.2184572],
                [108.3125245, 12.2184574],
                [108.3128719, 12.2184385],
                [108.3130446, 12.2184178],
                [108.3132161, 12.2183895],
                [108.3137697, 12.218262],
                [108.3139863, 12.2182202],
                [108.3144233, 12.2181572],
                [108.3148634, 12.2181219],
                [108.3152059, 12.2181078],
                [108.3155487, 12.2181033],
                [108.3158915, 12.2181085],
                [108.3162339, 12.2181232],
                [108.3165758, 12.2181475],
                [108.3169169, 12.2181813],
                [108.3172568, 12.2182247],
                [108.3175953, 12.2182776],
                [108.3179321, 12.21834],
                [108.318267, 12.2184118],
                [108.3187652, 12.218536],
                [108.319159, 12.2186568],
                [108.3193855, 12.2187384],
                [108.3198287, 12.2189254],
                [108.3202568, 12.2191432],
                [108.3206677, 12.2193907],
                [108.3207801, 12.2194454],
                [108.3208964, 12.2194918],
                [108.3210158, 12.2195296],
                [108.3211378, 12.2195587],
                [108.3212617, 12.2195789],
                [108.3213867, 12.21959],
                [108.3215122, 12.2195921],
                [108.3216376, 12.2195851],
                [108.3217621, 12.2195689],
                [108.3219204, 12.219579],
                [108.3220727, 12.2196224],
                [108.3221443, 12.219656],
                [108.3222741, 12.2197452],
                [108.3223307, 12.2197996],
                [108.3224102, 12.2199142],
                [108.3224382, 12.2199779],
                [108.3224684, 12.220113],
                [108.3224652, 12.2202954],
                [108.3224506, 12.2204077],
                [108.3224264, 12.2205184],
                [108.3223928, 12.2206267],
                [108.3223499, 12.2207318],
                [108.3222983, 12.2208331],
                [108.3222381, 12.2209298],
                [108.3220781, 12.2211339],
                [108.3219813, 12.2212424],
                [108.3216423, 12.2215767],
                [108.3215172, 12.2217126],
                [108.321398, 12.2218534],
                [108.3212288, 12.2220721],
                [108.3211175, 12.2222513],
                [108.3210655, 12.2223582],
                [108.3209862, 12.2225818],
                [108.3209491, 12.2227555],
                [108.3209337, 12.2229232],
                [108.3209347, 12.2230325],
                [108.3209627, 12.223249],
                [108.3209895, 12.2233551],
                [108.3210681, 12.2235594],
                [108.3211193, 12.2236565],
                [108.321244, 12.2238376],
                [108.3214866, 12.2241232],
                [108.321805, 12.2245444],
                [108.3220371, 12.2248147],
                [108.3225989, 12.2253675],
                [108.3226994, 12.2254527],
                [108.3228057, 12.2255309],
                [108.3229173, 12.2256017],
                [108.3231542, 12.2257199],
                [108.3232783, 12.2257666],
                [108.3234055, 12.2258049],
                [108.3237931, 12.2258823],
                [108.323918, 12.2259165],
                [108.3240407, 12.2259576],
                [108.3243793, 12.226097],
                [108.3244832, 12.2261271],
                [108.3246964, 12.2261644],
                [108.3248384, 12.2262116],
                [108.3249666, 12.2262876],
                [108.3250751, 12.2263889],
                [108.3253506, 12.2267447],
                [108.3255395, 12.2269563],
                [108.3256544, 12.2270654],
                [108.3257758, 12.2271676],
                [108.3259031, 12.2272626],
                [108.326036, 12.2273501],
                [108.3261739, 12.2274298],
                [108.3267113, 12.2277081],
                [108.3271514, 12.2279148],
                [108.3276384, 12.2281159],
                [108.328135, 12.2282929],
                [108.3284995, 12.2284495],
                [108.3288476, 12.2286381],
                [108.3291765, 12.2288573],
                [108.3294834, 12.2291051],
                [108.3296278, 12.2292391],
                [108.3298968, 12.2295259],
                [108.3301376, 12.2298356],
                [108.3302468, 12.2299983],
                [108.3304417, 12.2303376],
                [108.3305535, 12.2304636],
                [108.3306897, 12.2305642],
                [108.330765, 12.2306036],
                [108.3309262, 12.2306583],
                [108.3311477, 12.2306898],
                [108.3312859, 12.2306973],
                [108.3314242, 12.2306957],
                [108.3315622, 12.230685],
                [108.3316991, 12.2306652],
                [108.3318343, 12.2306365],
                [108.3319672, 12.2305988],
                [108.3320973, 12.2305525],
                [108.3322238, 12.2304977],
                [108.3327801, 12.2301885],
                [108.3331314, 12.2300199],
                [108.3335213, 12.2298693],
                [108.3337207, 12.229806],
                [108.3341268, 12.2297039],
                [108.3345401, 12.2296351],
                [108.3348867, 12.2295637],
                [108.3356762, 12.2293441],
                [108.3359027, 12.2292891],
                [108.3361391, 12.2292415],
                [108.3363774, 12.2292035],
                [108.3366171, 12.2291751],
                [108.3368577, 12.2291565],
                [108.3369785, 12.2291567],
                [108.337099, 12.2291475],
                [108.3373256, 12.2291042],
                [108.3374358, 12.2290701],
                [108.3376467, 12.228978],
                [108.3377461, 12.2289206],
                [108.3379303, 12.2287844],
                [108.338014, 12.2287065],
                [108.3380726, 12.2286278],
                [108.3382424, 12.2283368],
                [108.3383735, 12.2281645],
                [108.3385207, 12.2280153],
                [108.3386012, 12.2279478],
                [108.3387638, 12.2278334],
                [108.3388536, 12.2278017],
                [108.3389352, 12.2277835],
                [108.3391019, 12.2277731],
                [108.3392508, 12.2277933],
                [108.3393931, 12.2278409],
                [108.3395553, 12.22793],
                [108.3396457, 12.2279927],
                [108.3398101, 12.2281379],
                [108.3398831, 12.2282195],
                [108.3399664, 12.2283222],
                [108.3401163, 12.2285394],
                [108.3401824, 12.2286534],
                [108.3402961, 12.2288906],
                [108.3404208, 12.2292293],
                [108.3404703, 12.2294028],
                [108.3405429, 12.2297557],
                [108.3405732, 12.2300735],
                [108.3405756, 12.2302331],
                [108.3405551, 12.2305517],
                [108.3404325, 12.2313201],
                [108.340341, 12.2317504],
                [108.3402169, 12.2322014],
                [108.3399942, 12.2328692],
                [108.3398777, 12.2333259],
                [108.3397935, 12.2335421],
                [108.3397402, 12.2336455],
                [108.3396127, 12.2338405],
                [108.339539, 12.2339311],
                [108.3393735, 12.2340966],
                [108.3392521, 12.2342007],
                [108.3389947, 12.234391],
                [108.3387195, 12.234556],
                [108.3385035, 12.2346629],
                [108.3383119, 12.2347364],
                [108.3381918, 12.2347707],
                [108.337946, 12.2348152],
                [108.3376963, 12.2348271],
                [108.3375713, 12.2348207],
                [108.3372057, 12.2347684],
                [108.3370865, 12.2347601],
                [108.3368477, 12.2347636],
                [108.3366108, 12.2347938],
                [108.3364069, 12.2348061],
                [108.3360562, 12.2347874],
                [108.3357624, 12.2347989],
                [108.3354717, 12.2348425],
                [108.3353288, 12.2348762],
                [108.3350497, 12.2349668],
                [108.3348084, 12.2350264],
                [108.3345614, 12.2350555],
                [108.3343125, 12.2350537],
                [108.3341886, 12.2350411],
                [108.3339447, 12.234993],
                [108.333858, 12.2349839],
                [108.3336841, 12.2349915],
                [108.3335153, 12.2350333],
                [108.3334351, 12.2350664],
                [108.3333658, 12.2351045],
                [108.3332418, 12.235201],
                [108.3331884, 12.2352584],
                [108.3331017, 12.2353881],
                [108.3330451, 12.2355329],
                [108.333029, 12.2356089],
                [108.333022, 12.2357637],
                [108.3330487, 12.2358926],
                [108.3330737, 12.2359538],
                [108.3331453, 12.2360653],
                [108.33332, 12.2362324],
                [108.3333911, 12.2363142],
                [108.3334547, 12.2364017],
                [108.3335103, 12.2364942],
                [108.3335575, 12.2365911],
                [108.3336715, 12.2369091],
                [108.3337377, 12.2371294],
                [108.3337945, 12.2373522],
                [108.3339055, 12.2376802],
                [108.3340081, 12.2379192],
                [108.3341192, 12.2381271],
                [108.3341997, 12.2382527],
                [108.334288, 12.2383732],
                [108.3343836, 12.2384882],
                [108.3344862, 12.2385973],
                [108.3345954, 12.2387001],
                [108.3347108, 12.2387962],
                [108.334832, 12.2388852],
                [108.3351946, 12.2391203],
                [108.3352697, 12.2391766],
                [108.3353453, 12.2392448],
                [108.335414, 12.2393197],
                [108.3354751, 12.2394006],
                [108.3355281, 12.2394869],
                [108.3355725, 12.2395776],
                [108.3356079, 12.239672],
                [108.335634, 12.2397693],
                [108.3356506, 12.2398686],
                [108.335666, 12.2401117],
                [108.3356461, 12.2403544],
                [108.3355915, 12.2405921],
                [108.3354721, 12.2409947],
                [108.3353687, 12.2412651],
                [108.3353054, 12.2414007],
                [108.3352355, 12.2415331],
                [108.3349929, 12.2419292],
                [108.3349169, 12.2420751],
                [108.3347887, 12.2423776],
                [108.3346932, 12.2426914],
                [108.3346157, 12.2430841],
                [108.334583, 12.2433181],
                [108.3345564, 12.2434241],
                [108.3345227, 12.2435281],
                [108.3344346, 12.2437285],
                [108.3342822, 12.2439896],
                [108.3342513, 12.2440664],
                [108.3342116, 12.2442268],
                [108.3342022, 12.2443916],
                [108.3342091, 12.2444739],
                [108.3342614, 12.2447887],
                [108.3343605, 12.2455491],
                [108.3344343, 12.2459841],
                [108.334465, 12.246128],
                [108.3345134, 12.2463156],
                [108.3345708, 12.2465007],
                [108.3346716, 12.2467736],
                [108.3347647, 12.2469604],
                [108.3348245, 12.2470549],
                [108.334891, 12.2471452],
                [108.3350424, 12.2473109],
                [108.3353191, 12.2475441],
                [108.3354158, 12.2476403],
                [108.3355057, 12.2477427],
                [108.3356873, 12.2479075],
                [108.3358913, 12.2480449],
                [108.3360004, 12.2481024],
                [108.3362299, 12.2481935],
                [108.3364896, 12.2482601],
                [108.3367558, 12.2482941],
                [108.33689, 12.2482986],
                [108.3373152, 12.2482759],
                [108.3374726, 12.2482773],
                [108.3376296, 12.2482867],
                [108.3379412, 12.2483298],
                [108.3380472, 12.2483369],
                [108.3382593, 12.2483266],
                [108.3384671, 12.2482836],
                [108.3386654, 12.2482092],
                [108.3393091, 12.2479262],
                [108.3396417, 12.2478161],
                [108.3398069, 12.2477316],
                [108.3399532, 12.2476186],
                [108.3400755, 12.2474811],
                [108.3401263, 12.2474046],
                [108.3402718, 12.2470991],
                [108.340347, 12.2469629],
                [108.3404303, 12.2468313],
                [108.3405214, 12.2467047],
                [108.3405599, 12.2466334],
                [108.3405918, 12.2465591],
                [108.3406352, 12.2464037],
                [108.3406502, 12.2462434],
                [108.3406836, 12.2460879],
                [108.3407496, 12.2459428],
                [108.3408454, 12.2458144],
                [108.3410574, 12.2456291],
                [108.3411424, 12.2455438],
                [108.3412939, 12.2453574],
                [108.3413427, 12.2453105],
                [108.3414547, 12.2452339],
                [108.3415812, 12.2451833],
                [108.3417309, 12.2451585],
                [108.3418978, 12.2451643],
                [108.3420597, 12.2452043],
                [108.3421365, 12.2452366],
                [108.3422774, 12.2453241],
                [108.34234, 12.2453784],
                [108.3424214, 12.2454786],
                [108.3424795, 12.2455933],
                [108.3425259, 12.2457166],
                [108.3425633, 12.2458428],
                [108.3425915, 12.2459713],
                [108.3426101, 12.2461014],
                [108.3426193, 12.2462325],
                [108.3426189, 12.2463639],
                [108.342609, 12.2465012],
                [108.3425894, 12.2466375],
                [108.3425602, 12.2467721],
                [108.3425215, 12.2469044],
                [108.3424735, 12.2470338],
                [108.3424165, 12.2471597],
                [108.3423507, 12.2472813],
                [108.3422434, 12.2474386],
                [108.3422009, 12.2475144],
                [108.3421672, 12.2475943],
                [108.3421427, 12.2476774],
                [108.3421278, 12.2477626],
                [108.3421226, 12.2478489],
                [108.3421272, 12.2479352],
                [108.3421651, 12.2481196],
                [108.3421958, 12.2482169],
                [108.3422781, 12.2484037],
                [108.3423293, 12.2484923],
                [108.3423816, 12.2485636],
                [108.342507, 12.2486889],
                [108.3425788, 12.2487417],
                [108.3426556, 12.2487871],
                [108.3428454, 12.2488774],
                [108.342957, 12.2489235],
                [108.343188, 12.2489951],
                [108.3433904, 12.2490338],
                [108.3434753, 12.2490391],
                [108.3436446, 12.2490248],
                [108.3437274, 12.2490054],
                [108.3438308, 12.2489742],
                [108.3440294, 12.24889],
                [108.3442134, 12.2487786],
                [108.3442986, 12.2487134],
                [108.3443789, 12.2486423],
                [108.3446221, 12.2483868],
                [108.3447862, 12.248204],
                [108.3449231, 12.2480135],
                [108.3450839, 12.2478416],
                [108.3452741, 12.2476854],
                [108.3453811, 12.2476143],
                [108.3456091, 12.2474948],
                [108.3458519, 12.2474074],
                [108.3461047, 12.2473538],
                [108.3462333, 12.2473401],
                [108.3463627, 12.2473351],
                [108.3466394, 12.2473493],
                [108.3469811, 12.2473941],
                [108.3470768, 12.2474176],
                [108.3471411, 12.2474441],
                [108.3472572, 12.2475194],
                [108.3473504, 12.2476206],
                [108.3473866, 12.2476789],
                [108.3474352, 12.2478065],
                [108.3474754, 12.2480016],
                [108.347556, 12.2482511],
                [108.3476982, 12.2485668],
                [108.3477955, 12.2487587],
                [108.3480102, 12.2491319],
                [108.3482566, 12.249523],
                [108.3484505, 12.2498018],
                [108.3487168, 12.2501651],
                [108.3488634, 12.2503853],
                [108.3489016, 12.2504649],
                [108.3489482, 12.2505402],
                [108.3490026, 12.2506102],
                [108.3490575, 12.2506678],
                [108.3491826, 12.2507667],
                [108.3494554, 12.2509084],
                [108.3495823, 12.2509828],
                [108.349823, 12.2511511],
                [108.349936, 12.2512445],
                [108.3500999, 12.2513897],
                [108.3504086, 12.2516993],
                [108.3505529, 12.2518631],
                [108.3507621, 12.25212],
                [108.3508606, 12.2522281],
                [108.3509658, 12.25233],
                [108.3510774, 12.2524253],
                [108.3511948, 12.2525137],
                [108.3513176, 12.2525946],
                [108.3514453, 12.2526679],
                [108.3517385, 12.2528032],
                [108.3519032, 12.2528645],
                [108.3530044, 12.2532195],
                [108.3532265, 12.2533246],
                [108.3533315, 12.2533888],
                [108.353526, 12.2535383],
                [108.3537899, 12.2538063],
                [108.3538885, 12.2538937],
                [108.3540999, 12.2540517],
                [108.3542121, 12.2541218],
                [108.3544475, 12.2542431],
                [108.3547447, 12.2543616],
                [108.3550524, 12.2544508],
                [108.355357, 12.2545223],
                [108.355503, 12.2545665],
                [108.3559292, 12.2547206],
                [108.356072, 12.2547613],
                [108.3563632, 12.2548212],
                [108.3565107, 12.2548403],
                [108.3567334, 12.2548558],
                [108.3569303, 12.2548508],
                [108.3573061, 12.2547947],
                [108.3577633, 12.2547473],
                [108.3580081, 12.2547057],
                [108.3582211, 12.2546567],
                [108.3585424, 12.2545604],
                [108.3586996, 12.2545021],
                [108.358854, 12.2544373],
                [108.3591473, 12.2542918],
                [108.359421, 12.2541266],
                [108.3598397, 12.2538273],
                [108.3600036, 12.2537204],
                [108.360387, 12.2534952],
                [108.360938, 12.2532052],
                [108.3612159, 12.2530847],
                [108.361469, 12.2529976],
                [108.361672, 12.2529506],
                [108.3617901, 12.2529349],
                [108.3619091, 12.2529274],
                [108.3621472, 12.2529372],
                [108.3625235, 12.2530022],
                [108.3626668, 12.2530157],
                [108.3628106, 12.2530202],
                [108.3629544, 12.2530157],
                [108.3630977, 12.2530022],
                [108.3634074, 12.2529597],
                [108.3637444, 12.2529402],
                [108.364277, 12.252951],
                [108.3646667, 12.2529378],
                [108.3648276, 12.2529388],
                [108.3651481, 12.2529655],
                [108.3654639, 12.2530248],
                [108.3659617, 12.253173],
                [108.3666808, 12.2533631],
                [108.3670134, 12.253461],
                [108.3681053, 12.2538071],
                [108.3684287, 12.2538759],
                [108.3685981, 12.253898],
                [108.3687684, 12.2539109],
                [108.3689393, 12.2539144],
                [108.3691101, 12.2539087],
                [108.3692802, 12.2538937],
                [108.3694493, 12.2538695],
                [108.3696167, 12.2538361],
                [108.369782, 12.2537936],
                [108.3699446, 12.2537422],
                [108.370104, 12.2536821],
                [108.3702597, 12.2536134],
                [108.3704113, 12.2535363],
                [108.3705583, 12.2534511],
                [108.3707001, 12.253358],
                [108.3708365, 12.2532574],
                [108.3709669, 12.2531495],
                [108.371091, 12.2530347],
                [108.3712083, 12.2529133],
                [108.3713186, 12.2527857],
                [108.3714925, 12.2525441],
                [108.3716375, 12.2522848],
                [108.3717516, 12.2520112],
                [108.3718752, 12.251604],
                [108.3719839, 12.2513681],
                [108.3721247, 12.2511491],
                [108.3722063, 12.2510471],
                [108.3722949, 12.250951],
                [108.3724758, 12.2507929],
                [108.3726774, 12.250661],
                [108.372896, 12.2505579],
                [108.3731271, 12.2504855],
                [108.3733662, 12.2504454],
                [108.3736088, 12.2504383],
                [108.3737298, 12.2504472],
                [108.3741403, 12.2505185],
                [108.3744872, 12.2505495],
                [108.3746614, 12.2505516],
                [108.375009, 12.250529],
                [108.3753522, 12.2504708],
                [108.3756873, 12.2503777],
                [108.3760105, 12.2502507],
                [108.3763182, 12.2500912],
                [108.3768492, 12.2497756],
                [108.3773632, 12.2494341],
                [108.3778587, 12.2490674],
                [108.3780992, 12.248875],
                [108.3783121, 12.2486928],
                [108.3787197, 12.2483093],
                [108.3791019, 12.2479014],
                [108.3792829, 12.2476888],
                [108.379457, 12.2474708],
                [108.3799792, 12.2467524],
                [108.3803164, 12.2463156],
                [108.3804812, 12.2461395],
                [108.3806786, 12.2459677],
                [108.3808935, 12.2458173],
                [108.3810067, 12.2457506],
                [108.3811786, 12.2456789],
                [108.38136, 12.2456352],
                [108.3815463, 12.2456208],
                [108.3816397, 12.2456248],
                [108.3818408, 12.2456517],
                [108.3821406, 12.245725],
                [108.3822461, 12.2457376],
                [108.3823524, 12.2457411],
                [108.3824585, 12.2457354],
                [108.3825637, 12.2457206],
                [108.3826671, 12.2456968],
                [108.3827681, 12.2456642],
                [108.3828656, 12.245623],
                [108.3829591, 12.2455737],
                [108.3830478, 12.2455164],
                [108.3835829, 12.2451176],
                [108.3840483, 12.2448017],
                [108.384389, 12.2445459],
                [108.3845523, 12.2444095],
                [108.3847885, 12.2441952],
                [108.384962, 12.2440135],
                [108.3851419, 12.2437866],
                [108.3852982, 12.2435435],
                [108.3854914, 12.243195],
                [108.385663, 12.2428358],
                [108.3857507, 12.2426689],
                [108.3858469, 12.2425065],
                [108.3859513, 12.2423491],
                [108.3860638, 12.242197],
                [108.386184, 12.2420507],
                [108.3863115, 12.2419106],
                [108.3865658, 12.2416677],
                [108.3868175, 12.2414634],
                [108.3870161, 12.2413225],
                [108.3872129, 12.2412058],
                [108.3873458, 12.2411411],
                [108.3874828, 12.241085],
                [108.3876232, 12.2410378],
                [108.3877665, 12.2409997],
                [108.387912, 12.2409708],
                [108.3880592, 12.2409512],
                [108.3882073, 12.2409412],
                [108.3883558, 12.2409405],
                [108.388504, 12.2409494],
                [108.3886513, 12.2409678],
                [108.3887971, 12.2409955],
                [108.3892674, 12.2411223],
                [108.3895919, 12.2412195],
                [108.3902335, 12.2414361],
                [108.3904039, 12.2414846],
                [108.3905763, 12.2415262],
                [108.3909255, 12.2415881],
                [108.3910464, 12.2416175],
                [108.3912802, 12.241701],
                [108.391392, 12.2417545],
                [108.3916027, 12.2418841],
                [108.3917927, 12.2420413],
                [108.3919581, 12.2422231],
                [108.3921582, 12.2424882],
                [108.3924276, 12.2428096],
                [108.3926432, 12.243017],
                [108.3928805, 12.2432003],
                [108.3931369, 12.2433573],
                [108.3934092, 12.2434862],
                [108.3935503, 12.2435395],
                [108.3938405, 12.2436234],
                [108.3939886, 12.2436536],
                [108.3941286, 12.243673],
                [108.394411, 12.2436859],
                [108.3945522, 12.2436793],
                [108.3946927, 12.2436641],
                [108.3949695, 12.243608],
                [108.3952369, 12.2435184],
                [108.39567, 12.2433129],
                [108.3958533, 12.2432375],
                [108.3960401, 12.2431709],
                [108.39623, 12.2431131],
                [108.3964225, 12.2430643],
                [108.3968567, 12.2429699],
                [108.3973848, 12.2428424],
                [108.3976767, 12.2427831],
                [108.3982648, 12.242687],
                [108.3985605, 12.2426504],
                [108.3990057, 12.2426087],
                [108.3993788, 12.2425941],
                [108.3998272, 12.2426089],
                [108.4000506, 12.2426295],
                [108.4004939, 12.242697],
                [108.4007131, 12.2427438],
                [108.4014463, 12.2429443],
                [108.401708, 12.2430036],
                [108.4019038, 12.2430418],
                [108.4025898, 12.2431507],
                [108.4028803, 12.2432049],
                [108.4034568, 12.2433338],
                [108.4038819, 12.2434608],
                [108.4042966, 12.2436172],
                [108.4044994, 12.2437062],
                [108.4051038, 12.2440048],
                [108.4053171, 12.2440955],
                [108.4055342, 12.2441775],
                [108.4061784, 12.2443773],
                [108.4068497, 12.244613],
                [108.4071288, 12.2446987],
                [108.4075984, 12.2448275],
                [108.4078022, 12.2448538],
                [108.4079103, 12.2448557],
                [108.4081252, 12.2448351],
                [108.4082308, 12.2448126],
                [108.4085559, 12.2447066],
                [108.4086792, 12.2446773],
                [108.4089305, 12.2446435],
                [108.4091818, 12.2446276],
                [108.4093054, 12.2446087],
                [108.4095481, 12.2445495],
                [108.4096662, 12.2445093],
                [108.4100625, 12.2443361],
                [108.4102342, 12.2442717],
                [108.4108592, 12.2440854],
                [108.4111293, 12.2439945],
                [108.4117598, 12.2437516],
                [108.4119345, 12.2436637],
                [108.4121203, 12.2436012],
                [108.4123366, 12.2435631],
                [108.4125563, 12.2435594],
                [108.4127737, 12.2435902],
                [108.4129833, 12.2436548],
                [108.4130834, 12.2436993],
                [108.413271, 12.243811],
                [108.4133573, 12.2438776],
                [108.413512, 12.2440301],
                [108.4136676, 12.2442363],
                [108.4138247, 12.244491],
                [108.413955, 12.2447598],
                [108.4140428, 12.244973],
                [108.4141213, 12.2451897],
                [108.414234, 12.245398],
                [108.4143019, 12.2454955],
                [108.4143769, 12.2455879],
                [108.4145469, 12.2457551],
                [108.4147403, 12.245896],
                [108.4150357, 12.2460573],
                [108.415187, 12.2461767],
                [108.4152543, 12.2462455],
                [108.4153698, 12.2463985],
                [108.415457, 12.2465684],
                [108.4155465, 12.2467045],
                [108.4156604, 12.246822],
                [108.4157906, 12.2469317],
                [108.4159061, 12.2470642],
                [108.4159991, 12.2472127],
                [108.4161597, 12.2475749],
                [108.4162027, 12.2476394],
                [108.4162536, 12.2476982],
                [108.4163114, 12.2477504],
                [108.4163754, 12.2477952],
                [108.4164446, 12.2478319],
                [108.416518, 12.24786],
                [108.416728, 12.2479191],
                [108.416859, 12.2479672],
                [108.4171106, 12.2480866],
                [108.4172303, 12.2481575],
                [108.4182644, 12.2488606],
                [108.4186743, 12.2491068],
                [108.4190915, 12.2493179],
                [108.4195232, 12.2494988],
                [108.419736, 12.2495767],
                [108.4201678, 12.2497149],
                [108.4206068, 12.2498291],
                [108.4207851, 12.249883],
                [108.4211325, 12.2500156],
                [108.4214652, 12.2501803],
                [108.4215829, 12.2502303],
                [108.4218274, 12.2503062],
                [108.4220801, 12.2503489],
                [108.4222081, 12.2503576],
                [108.4224645, 12.2503493],
                [108.4227316, 12.2503212],
                [108.4230121, 12.2503217],
                [108.423152, 12.2503334],
                [108.4234285, 12.2503795],
                [108.4236433, 12.2503935],
                [108.4240077, 12.2503622],
                [108.4243085, 12.2503632],
                [108.4246073, 12.2503975],
                [108.4249002, 12.2504648],
                [108.4251927, 12.2505653],
                [108.4254836, 12.2506933],
                [108.4257622, 12.2508453],
                [108.4262609, 12.2511711],
                [108.4263914, 12.2512444],
                [108.4265264, 12.2513092],
                [108.4266656, 12.2513652],
                [108.4268081, 12.2514123],
                [108.4269788, 12.2514794],
                [108.4270998, 12.2515479],
                [108.4271613, 12.2515958],
                [108.4272668, 12.2517096],
                [108.4273452, 12.2518426],
                [108.427373, 12.2519145],
                [108.4275165, 12.2524867],
                [108.4275484, 12.2526547],
                [108.4275705, 12.2528215],
                [108.4275832, 12.2529893],
                [108.4275866, 12.2531575],
                [108.4275806, 12.2533257],
                [108.4275435, 12.2535069],
                [108.4274754, 12.2536794],
                [108.4273643, 12.2538951],
                [108.4273064, 12.254033],
                [108.4272159, 12.2543176],
                [108.4271515, 12.2546388],
                [108.4271085, 12.2549929],
                [108.4270978, 12.2551709],
                [108.42711, 12.2554591],
                [108.4271585, 12.2557437],
                [108.4272426, 12.2560202],
                [108.4272685, 12.2561824],
                [108.4272649, 12.2563466],
                [108.4272319, 12.2565077],
                [108.4272192, 12.2565953],
                [108.4272158, 12.2566837],
                [108.4272218, 12.256772],
                [108.4272372, 12.2568592],
                [108.4272618, 12.2569444],
                [108.4272953, 12.2570266],
                [108.4273373, 12.257105],
                [108.4274317, 12.2572558],
                [108.4274699, 12.257336],
                [108.4275269, 12.2575037],
                [108.427585, 12.2575935],
                [108.427668, 12.257662],
                [108.4278314, 12.25773],
                [108.427891, 12.2577636],
                [108.4279977, 12.2578484],
                [108.4280833, 12.2579535],
                [108.4282458, 12.2582114],
                [108.4283824, 12.2583658],
                [108.428543, 12.2584962],
                [108.4286309, 12.2585514],
                [108.4289264, 12.2587112],
                [108.4290563, 12.258795],
                [108.4292104, 12.2588649],
                [108.4293875, 12.2589133],
                [108.4294786, 12.2589264],
                [108.4296625, 12.2589299],
                [108.4303067, 12.2588324],
                [108.4309764, 12.2587647],
                [108.4313816, 12.2586991],
                [108.4317823, 12.2586096],
                [108.4320808, 12.258533],
                [108.4322599, 12.2584633],
                [108.4324133, 12.2583712],
                [108.4326508, 12.2581796],
                [108.4328957, 12.2579679],
                [108.4329718, 12.2578916],
                [108.4330975, 12.2577322],
                [108.4331971, 12.2575561],
                [108.4332953, 12.2574225],
                [108.4333537, 12.2573632],
                [108.4334868, 12.2572626],
                [108.4335189, 12.2572299],
                [108.4335772, 12.2571324],
                [108.4335859, 12.2570783],
                [108.4335688, 12.2570164],
                [108.4335257, 12.256968],
                [108.433497, 12.256952],
                [108.433399, 12.2569296],
                [108.4333316, 12.2569243],
                [108.4331971, 12.2569375],
                [108.4330487, 12.2569776],
                [108.4329772, 12.2570057],
                [108.432936, 12.2570094],
                [108.4328556, 12.2569924],
                [108.4327886, 12.2569458],
                [108.4327458, 12.2568772],
                [108.4327265, 12.2567722],
                [108.4327261, 12.2567058],
                [108.4327948, 12.2563609],
                [108.4328474, 12.2562423],
                [108.4329294, 12.256141],
                [108.4329798, 12.256099],
                [108.4331586, 12.2559977],
                [108.4332171, 12.2559528],
                [108.4333167, 12.2558451],
                [108.4333566, 12.2557837],
                [108.4334137, 12.2556496],
                [108.4334555, 12.2554145],
                [108.4335103, 12.2552356],
                [108.433592, 12.2550669],
                [108.4337021, 12.2548821],
                [108.4338033, 12.2546624],
                [108.4338981, 12.2545167],
                [108.4340208, 12.2543925],
                [108.4341664, 12.2542947],
                [108.434246, 12.254257],
                [108.4344575, 12.2541892],
                [108.4345883, 12.2541595],
                [108.4348546, 12.2541261],
                [108.4351183, 12.254127],
                [108.4353751, 12.2541592],
                [108.4355316, 12.2541556],
                [108.4356842, 12.2541219],
                [108.4357573, 12.2540941],
                [108.435893, 12.254018],
                [108.436093, 12.2538673],
                [108.4363782, 12.2536717],
                [108.4365483, 12.2535294],
                [108.4366993, 12.2533678],
                [108.4367669, 12.2532805],
                [108.4369014, 12.2530673],
                [108.4369654, 12.2529406],
                [108.4370203, 12.25281],
                [108.4370661, 12.252676],
                [108.4371511, 12.2523356],
                [108.4372365, 12.2518736],
                [108.4372629, 12.2517752],
                [108.4372984, 12.2516796],
                [108.4373428, 12.2515876],
                [108.4373956, 12.2515],
                [108.4374565, 12.2514175],
                [108.4375262, 12.2512969],
                [108.4375524, 12.251229],
                [108.4375698, 12.2511585],
                [108.4375781, 12.2510863],
                [108.4375771, 12.2510138],
                [108.437567, 12.2509419],
                [108.4375477, 12.2508718],
                [108.4375197, 12.2508046],
                [108.4374434, 12.2506585],
                [108.4374102, 12.2505728],
                [108.4373842, 12.2504849],
                [108.4373434, 12.2502638],
                [108.4373209, 12.250065],
                [108.4373252, 12.2499274],
                [108.4373566, 12.2497887],
                [108.4373863, 12.2497314],
                [108.4374266, 12.2496935],
                [108.4375285, 12.249649],
                [108.4376437, 12.2496273],
                [108.4377024, 12.2496266],
                [108.4378181, 12.2496452],
                [108.4379385, 12.2496937],
                [108.4381579, 12.2498359],
                [108.4382633, 12.2498925],
                [108.4383729, 12.249941],
                [108.4384859, 12.2499811],
                [108.4388139, 12.2500647],
                [108.4392428, 12.2501489],
                [108.4393287, 12.250177],
                [108.4394917, 12.2502535],
                [108.4396397, 12.2503551],
                [108.4397685, 12.2504792],
                [108.439911, 12.2506599],
                [108.4400431, 12.2508544],
                [108.4402031, 12.2510348],
                [108.4402676, 12.2511456],
                [108.4402895, 12.2512057],
                [108.440304, 12.251268],
                [108.44031, 12.2513953],
                [108.4402855, 12.2515204],
                [108.4402303, 12.2516393],
                [108.4401466, 12.2517449],
                [108.440048, 12.2518413],
                [108.4399698, 12.2519573],
                [108.4399402, 12.2520206],
                [108.4399017, 12.2521544],
                [108.4398919, 12.252293],
                [108.4398647, 12.2524279],
                [108.4398391, 12.2524921],
                [108.4397659, 12.2526096],
                [108.4397192, 12.2526612],
                [108.4393314, 12.2530063],
                [108.4389907, 12.2532995],
                [108.4384543, 12.2537398],
                [108.4382704, 12.2539006],
                [108.4378319, 12.2543245],
                [108.4377515, 12.2544161],
                [108.4376757, 12.2545167],
                [108.437608, 12.2546228],
                [108.4375486, 12.2547335],
                [108.4374121, 12.2550631],
                [108.4372993, 12.2552438],
                [108.4371572, 12.2554035],
                [108.4369898, 12.2555379],
                [108.436928, 12.2555956],
                [108.4368244, 12.2557283],
                [108.4367837, 12.2558018],
                [108.4367266, 12.255959],
                [108.4366763, 12.2561931],
                [108.436495, 12.2568173],
                [108.4364425, 12.2570711],
                [108.4364126, 12.2572889],
                [108.4364104, 12.2573622],
                [108.4364282, 12.2574997],
                [108.4364741, 12.2576309],
                [108.436507, 12.2576924],
                [108.4365828, 12.2577834],
                [108.4366796, 12.2578529],
                [108.436734, 12.257878],
                [108.4367912, 12.2578963],
                [108.4372526, 12.2579985],
                [108.4374511, 12.2580541],
                [108.4376436, 12.2581307],
                [108.4379243, 12.2582735],
                [108.4381284, 12.2583457],
                [108.4382338, 12.2583712],
                [108.4385499, 12.258418],
                [108.4386492, 12.2584433],
                [108.4389401, 12.2585495],
                [108.4390419, 12.2585766],
                [108.4392501, 12.2586094],
                [108.4393555, 12.258615],
                [108.4394125, 12.2586291],
                [108.4394669, 12.2586506],
                [108.4395643, 12.2587144],
                [108.4396403, 12.2588016],
                [108.4396891, 12.2589058],
                [108.4397022, 12.2589618],
                [108.4397072, 12.259019],
                [108.4396948, 12.2591818],
                [108.4396805, 12.2592867],
                [108.4396303, 12.2595353],
                [108.4395244, 12.2599567],
                [108.439491, 12.2602327],
                [108.4394921, 12.2605106],
                [108.4395122, 12.2607677],
                [108.4395145, 12.2611865],
                [108.4395276, 12.2613671],
                [108.4395502, 12.2615469],
                [108.4395823, 12.2617253],
                [108.4396756, 12.2620822],
                [108.4397366, 12.2622598],
                [108.4398065, 12.2624343],
                [108.4399255, 12.2626902],
                [108.4400276, 12.2628527],
                [108.4400891, 12.2629288],
                [108.4402299, 12.2630655],
                [108.4403233, 12.2631387],
                [108.4405252, 12.2632643],
                [108.4407436, 12.2633599],
                [108.4408575, 12.2633958],
                [108.4410131, 12.263438],
                [108.4412495, 12.2634901],
                [108.4413296, 12.2635006],
                [108.4415122, 12.2634996],
                [108.4416026, 12.2634863],
                [108.4416913, 12.2634645],
                [108.4418601, 12.2633964],
                [108.4420124, 12.263298],
                [108.4421449, 12.2631722],
                [108.4422033, 12.2631008],
                [108.4423006, 12.262945],
                [108.442341, 12.262896],
                [108.4424408, 12.2628169],
                [108.4425591, 12.262768],
                [108.4426224, 12.2627563],
                [108.4429079, 12.2627236],
                [108.4430516, 12.2627196],
                [108.4432602, 12.2627398],
                [108.4433627, 12.2627615],
                [108.4434319, 12.2627866],
                [108.4435587, 12.2628597],
                [108.4436147, 12.2629067],
                [108.4437078, 12.2630182],
                [108.4437436, 12.2630813],
                [108.4437885, 12.2632131],
                [108.4437995, 12.2633516],
                [108.4437759, 12.2634886],
                [108.4437191, 12.2636159],
                [108.4435916, 12.2637881],
                [108.4434966, 12.2638969],
                [108.4433947, 12.2639994],
                [108.4432862, 12.2640954],
                [108.4426775, 12.264543],
                [108.4424077, 12.2647642],
                [108.4422302, 12.2649361],
                [108.442144, 12.2650346],
                [108.4419896, 12.2652452],
                [108.4417814, 12.2656025],
                [108.4417475, 12.2656411],
                [108.4416944, 12.2657285],
                [108.4416598, 12.265845],
                [108.4416592, 12.2659664],
                [108.4416925, 12.2660834],
                [108.4417459, 12.2662031],
                [108.4417613, 12.2662714],
                [108.4417671, 12.2663412],
                [108.4417631, 12.2664111],
                [108.4417495, 12.2664799],
                [108.4416949, 12.2666249],
                [108.4416406, 12.26674],
                [108.4415847, 12.2668261],
                [108.4414629, 12.2669587],
                [108.44143, 12.2670083],
                [108.4413827, 12.2671168],
                [108.4413392, 12.2673785],
                [108.441273, 12.2676659],
                [108.4411334, 12.2680877],
                [108.44107, 12.2683024],
                [108.441056, 12.2683756],
                [108.441054, 12.2685291],
                [108.4410878, 12.2686789],
                [108.4411559, 12.2688171],
                [108.4413004, 12.2689973],
                [108.4413398, 12.2690623],
                [108.4413978, 12.2692022],
                [108.4415321, 12.2698581],
                [108.4415549, 12.2700164],
                [108.4415607, 12.2701238],
                [108.4415588, 12.2702313],
                [108.4415319, 12.2704447],
                [108.4414748, 12.2706524],
                [108.4413885, 12.2708502],
                [108.4412062, 12.2711538],
                [108.4411644, 12.2712413],
                [108.4411317, 12.2713325],
                [108.4411087, 12.2714265],
                [108.4410955, 12.2715222],
                [108.4410922, 12.2716187],
                [108.4410989, 12.2717151],
                [108.4411155, 12.2718103],
                [108.4411418, 12.2719034],
                [108.4412168, 12.2721256],
                [108.4412491, 12.272197],
                [108.4412867, 12.2722632],
                [108.4413791, 12.2723847],
                [108.4414919, 12.2724883],
                [108.4415549, 12.2725324],
                [108.441648, 12.2726304],
                [108.4417127, 12.2727482],
                [108.4417447, 12.2728782],
                [108.441742, 12.2730119],
                [108.4417048, 12.2731406],
                [108.4416738, 12.2732004],
                [108.4415828, 12.2733408],
                [108.4414941, 12.2735193],
                [108.4414206, 12.2737295],
                [108.4413903, 12.2738488],
                [108.4413674, 12.2739697],
                [108.4413444, 12.2742144],
                [108.4413444, 12.2743373],
                [108.4413671, 12.274582],
                [108.4414201, 12.2749128],
                [108.4414821, 12.2751917],
                [108.4415817, 12.2755255],
                [108.4417348, 12.2759395],
                [108.4419143, 12.2763432],
                [108.4420918, 12.2766847],
                [108.4421879, 12.2768517],
                [108.4422862, 12.2770023],
                [108.4423927, 12.2771476],
                [108.442507, 12.2772871],
                [108.4426288, 12.2774204],
                [108.4427577, 12.2775471],
                [108.4428934, 12.2776669],
                [108.4430355, 12.2777795],
                [108.443328, 12.2779815],
                [108.4434807, 12.278073],
                [108.4436317, 12.2781444],
                [108.4437459, 12.2781752],
                [108.4438712, 12.2781791],
                [108.4439938, 12.2781532],
                [108.4440517, 12.2781296],
                [108.4441063, 12.2780992],
                [108.4442401, 12.2779931],
                [108.4443882, 12.2778353],
                [108.444688, 12.2774089],
                [108.4448862, 12.2770771],
                [108.4449989, 12.2769303],
                [108.445021, 12.2768825],
                [108.4450339, 12.2768316],
                [108.4450371, 12.2767792],
                [108.4450305, 12.2767271],
                [108.4450144, 12.2766771],
                [108.4449199, 12.276532],
                [108.4448923, 12.2764701],
                [108.4448735, 12.2764051],
                [108.444864, 12.2763383],
                [108.4448638, 12.2762707],
                [108.4448731, 12.2762038],
                [108.444924, 12.2760089],
                [108.4450092, 12.2755496],
                [108.4450529, 12.2753548],
                [108.4451919, 12.2748335],
                [108.4452371, 12.2745572],
                [108.4452709, 12.2741477],
                [108.4452938, 12.2740185],
                [108.4453639, 12.2737656],
                [108.4454109, 12.2736428],
                [108.4455325, 12.2733962],
                [108.4456883, 12.2731546],
                [108.4457767, 12.2730409],
                [108.4459323, 12.2728784],
                [108.4459862, 12.2728126],
                [108.4460649, 12.2727512],
                [108.4461159, 12.2727248],
                [108.44617, 12.2727054],
                [108.4462839, 12.2726889],
                [108.446398, 12.2727029],
                [108.4464526, 12.2727211],
                [108.4465418, 12.2727733],
                [108.4466714, 12.2729058],
                [108.4467343, 12.2729595],
                [108.4468018, 12.2730075],
                [108.4469486, 12.2730852],
                [108.4473377, 12.2732128],
                [108.447547, 12.2732977],
                [108.4476531, 12.2733332],
                [108.4477619, 12.2733597],
                [108.4478727, 12.2733771],
                [108.4479845, 12.2733851],
                [108.4480967, 12.2733837],
                [108.4482083, 12.273373],
                [108.448783, 12.2732472],
                [108.4493548, 12.273151],
                [108.4496509, 12.2731277],
                [108.4499478, 12.2731399],
                [108.4500951, 12.2731593],
                [108.4503847, 12.2732244],
                [108.4505886, 12.2732611],
                [108.4506582, 12.2732824],
                [108.4507246, 12.2733117],
                [108.4508447, 12.2733921],
                [108.450942, 12.273498],
                [108.4510111, 12.2736232],
                [108.4510525, 12.27377],
                [108.4510634, 12.2738504],
                [108.4510614, 12.2740125],
                [108.451028, 12.2741713],
                [108.4509644, 12.274321],
                [108.4508729, 12.2744562],
                [108.450842, 12.2745149],
                [108.4508198, 12.2745772],
                [108.4508068, 12.2746419],
                [108.4508038, 12.2747924],
                [108.4508102, 12.2748768],
                [108.4508407, 12.2750433],
                [108.4508762, 12.2751709],
                [108.450973, 12.2754178],
                [108.4512828, 12.2760533],
                [108.4513898, 12.2762468],
                [108.4515047, 12.2764358],
                [108.4516276, 12.2766201],
                [108.4518728, 12.2769428],
                [108.4519934, 12.2770816],
                [108.4524133, 12.2775143],
                [108.452568, 12.2776904],
                [108.4527137, 12.2778429],
                [108.4528297, 12.2779351],
                [108.4528973, 12.277974],
                [108.4530431, 12.2780304],
                [108.4531197, 12.2780472],
                [108.4533711, 12.2780655],
                [108.453465, 12.2780812],
                [108.4536474, 12.2781346],
                [108.4538185, 12.2782162],
                [108.4540266, 12.2783421],
                [108.454158, 12.2784118],
                [108.4542924, 12.2784759],
                [108.454569, 12.2785867],
                [108.4546684, 12.2786355],
                [108.4547633, 12.2786924],
                [108.454853, 12.2787568],
                [108.4549368, 12.2788283],
                [108.4550142, 12.2789064],
                [108.4550619, 12.2789666],
                [108.4551394, 12.2790985],
                [108.4551687, 12.279169],
                [108.4552659, 12.2794647],
                [108.455438, 12.2799024],
                [108.4554739, 12.2800166],
                [108.4555249, 12.2802503],
                [108.4555666, 12.2805198],
                [108.4556014, 12.2806691],
                [108.4556666, 12.2808899],
                [108.4557353, 12.2810367],
                [108.4558375, 12.2811737],
                [108.4559661, 12.2812876],
                [108.4560387, 12.2813343],
                [108.4561966, 12.2814047],
                [108.4563807, 12.2814622],
                [108.4564784, 12.2815034],
                [108.4565729, 12.2815512],
                [108.4567505, 12.2816657],
                [108.4569657, 12.2818334],
                [108.4572405, 12.2820252],
                [108.4573298, 12.2820994],
                [108.4574126, 12.2821804],
                [108.4576217, 12.282434],
                [108.4577683, 12.2825641],
                [108.4578493, 12.2826201],
                [108.4580237, 12.2827119],
                [108.4583165, 12.2828396],
                [108.4584489, 12.2828751],
                [108.4585212, 12.2828859],
                [108.4586674, 12.2828901],
                [108.4587773, 12.2828605],
                [108.4588724, 12.2827993],
                [108.4589115, 12.2827586],
                [108.4589679, 12.2826618],
                [108.458991, 12.2825528],
                [108.4589764, 12.2822621],
                [108.4589249, 12.2817946],
                [108.4589142, 12.2816341],
                [108.4589169, 12.2813124],
                [108.4589587, 12.2808718],
                [108.4589492, 12.2806301],
                [108.4589085, 12.2803915],
                [108.4587409, 12.2798209],
                [108.4586137, 12.2794824],
                [108.4585295, 12.2792967],
                [108.4583879, 12.2790252],
                [108.4582679, 12.27884],
                [108.4581174, 12.2786562],
                [108.4580344, 12.278571],
                [108.4579375, 12.278435],
                [108.4578668, 12.2782844],
                [108.457842, 12.2782051],
                [108.4577951, 12.2779559],
                [108.4577301, 12.2777927],
                [108.4576327, 12.2776457],
                [108.4574951, 12.2774864],
                [108.4573555, 12.2772869],
                [108.4572274, 12.2770508],
                [108.4571692, 12.2769159],
                [108.4571199, 12.2767777],
                [108.4570795, 12.2766368],
                [108.4570343, 12.2763945],
                [108.4570182, 12.2761488],
                [108.4570211, 12.2760257],
                [108.4570312, 12.2759029],
                [108.4570496, 12.2757911],
                [108.4571114, 12.2755729],
                [108.4571546, 12.2754679],
                [108.4571953, 12.2753988],
                [108.4572437, 12.2753347],
                [108.4572994, 12.2752765],
                [108.4573616, 12.2752249],
                [108.4574294, 12.2751807],
                [108.457502, 12.2751443],
                [108.4575784, 12.2751164],
                [108.4576576, 12.2750972],
                [108.4577384, 12.2750871],
                [108.45782, 12.2750861],
                [108.4579011, 12.2750942],
                [108.4583831, 12.27519],
                [108.4587121, 12.275274],
                [108.459033, 12.2753843],
                [108.4593433, 12.2755203],
                [108.4594486, 12.2755554],
                [108.4595567, 12.2755812],
                [108.4596667, 12.2755975],
                [108.4597778, 12.2756041],
                [108.4599351, 12.2756422],
                [108.4600097, 12.2756735],
                [108.4601464, 12.2757585],
                [108.4602613, 12.2758701],
                [108.4605121, 12.2762121],
                [108.4606651, 12.2764462],
                [108.4608205, 12.2767368],
                [108.4609188, 12.2769632],
                [108.4609851, 12.2771591],
                [108.4610412, 12.2774015],
                [108.4610707, 12.2776484],
                [108.4611165, 12.2778328],
                [108.4611953, 12.2780063],
                [108.4613046, 12.2781631],
                [108.4613696, 12.2782338],
                [108.4615249, 12.2783652],
                [108.4617062, 12.2784809],
                [108.4623635, 12.2787859],
                [108.4625334, 12.278856],
                [108.4627068, 12.2789174],
                [108.4628832, 12.2789699],
                [108.4633829, 12.279082],
                [108.4637245, 12.2791925],
                [108.4638782, 12.2792674],
                [108.4639611, 12.2793218],
                [108.4640384, 12.2793836],
                [108.4641094, 12.2794522],
                [108.4641735, 12.279527],
                [108.46423, 12.2796074],
                [108.4643759, 12.2798483],
                [108.4644815, 12.2799987],
                [108.4651112, 12.2807795],
                [108.4656032, 12.2814342],
                [108.4659362, 12.281847],
                [108.4660236, 12.2819691],
                [108.4661049, 12.2820952],
                [108.4662484, 12.2823582],
                [108.4663103, 12.2824944],
                [108.4664136, 12.2827748],
                [108.4664574, 12.2828713],
                [108.4665089, 12.2829642],
                [108.4666336, 12.2831364],
                [108.4666728, 12.2832025],
                [108.4667282, 12.283345],
                [108.4667438, 12.2834198],
                [108.4667496, 12.2835722],
                [108.4667218, 12.2837222],
                [108.4666615, 12.2838629],
                [108.4666201, 12.2839276],
                [108.466517, 12.284042],
                [108.4663099, 12.284214],
                [108.4661679, 12.2843426],
                [108.4658986, 12.2846146],
                [108.4657584, 12.2847714],
                [108.4654949, 12.2850986],
                [108.4652549, 12.2854428],
                [108.4650905, 12.2857252],
                [108.4649491, 12.2860193],
                [108.464877, 12.2861989],
                [108.4648407, 12.2863433],
                [108.4648332, 12.286512],
                [108.4648621, 12.2866784],
                [108.464926, 12.2868353],
                [108.465022, 12.2869757],
                [108.4650809, 12.2870377],
                [108.4652168, 12.2871419],
                [108.4654597, 12.2872675],
                [108.4656304, 12.2873454],
                [108.4657604, 12.2874168],
                [108.4658852, 12.2874965],
                [108.4660043, 12.2875842],
                [108.4661172, 12.2876793],
                [108.4662751, 12.2878342],
                [108.4663224, 12.2878906],
                [108.4664181, 12.2880412],
                [108.4664836, 12.2882065],
                [108.4665164, 12.2883809],
                [108.4665201, 12.2884696],
                [108.4665023, 12.288646],
                [108.4664511, 12.2888161],
                [108.4663632, 12.2890241],
                [108.4663217, 12.2891544],
                [108.4662893, 12.2892871],
                [108.4662663, 12.2894216],
                [108.4661132, 12.2909201],
                [108.466071, 12.2914518],
                [108.4660592, 12.2917183],
                [108.4660381, 12.2919841],
                [108.4660085, 12.2922417],
                [108.4659228, 12.2927532],
                [108.4658021, 12.2932578],
                [108.4657709, 12.2934261],
                [108.4657491, 12.2935957],
                [108.4657366, 12.2937663],
                [108.4657335, 12.2939372],
                [108.4657398, 12.2941081],
                [108.4657556, 12.2942784],
                [108.4657806, 12.2944476],
                [108.4657884, 12.2945654],
                [108.4657885, 12.2946834],
                [108.4657653, 12.2949181],
                [108.4657115, 12.2951481],
                [108.4656433, 12.2953693],
                [108.4655124, 12.2960567],
                [108.4654427, 12.2963494],
                [108.4654105, 12.2964446],
                [108.4653246, 12.2966175],
                [108.4653116, 12.2966655],
                [108.4653098, 12.2967645],
                [108.4653211, 12.2968129],
                [108.4653664, 12.2969014],
                [108.4653993, 12.2969393],
                [108.4654814, 12.2969972],
                [108.4656198, 12.2970451],
                [108.4658086, 12.2970788],
                [108.4660006, 12.2970788],
                [108.4661489, 12.2970503],
                [108.4663936, 12.2969746],
                [108.4665689, 12.2969309],
                [108.466925, 12.2968528],
                [108.4672773, 12.2967486],
                [108.4675221, 12.296686],
                [108.4676421, 12.2966466],
                [108.4678957, 12.2965426],
                [108.4680279, 12.2964762],
                [108.4682803, 12.2963232],
                [108.4683998, 12.296237],
                [108.4686238, 12.2960462],
                [108.4687591, 12.2959134],
                [108.4688875, 12.2957741],
                [108.4690088, 12.2956288],
                [108.4691225, 12.2954779],
                [108.4692285, 12.2953216],
                [108.4693265, 12.2951604],
                [108.469549, 12.2947509],
                [108.4697424, 12.2943274],
                [108.4698279, 12.2941111],
                [108.4699808, 12.2936627],
                [108.4700496, 12.2934315],
                [108.4702165, 12.2927965],
                [108.47027, 12.2926304],
                [108.4704292, 12.2921986],
                [108.4704852, 12.2919841],
                [108.4705006, 12.2918781],
                [108.4705065, 12.2917711],
                [108.4705031, 12.291664],
                [108.4704903, 12.2915576],
                [108.4704682, 12.2914526],
                [108.4704369, 12.2913499],
                [108.4704026, 12.2911548],
                [108.4703932, 12.2904399],
                [108.4703565, 12.2899243],
                [108.4703516, 12.2897582],
                [108.4703638, 12.2894264],
                [108.4703809, 12.2892612],
                [108.4704369, 12.2889336],
                [108.4705062, 12.2887096],
                [108.4705515, 12.2886012],
                [108.4706308, 12.2884436],
                [108.4707071, 12.2883352],
                [108.4708137, 12.2882327],
                [108.4708743, 12.2881897],
                [108.4710073, 12.2881228],
                [108.4711512, 12.2880831],
                [108.4714139, 12.288046],
                [108.4715067, 12.2880248],
                [108.4716814, 12.2880056],
                [108.4718424, 12.2880111],
                [108.4718984, 12.2880244],
                [108.4720021, 12.2880733],
                [108.4720878, 12.2881484],
                [108.4721488, 12.2882437],
                [108.4722045, 12.2884334],
                [108.472236, 12.2885125],
                [108.4723198, 12.2886611],
                [108.4723876, 12.2888185],
                [108.4724463, 12.2891097],
                [108.4724776, 12.2892318],
                [108.4725602, 12.2894704],
                [108.4726112, 12.2895861],
                [108.4728938, 12.2901549],
                [108.4729898, 12.2903678],
                [108.4732907, 12.2910954],
                [108.4734825, 12.2915363],
                [108.4736334, 12.2918211],
                [108.4738161, 12.2920892],
                [108.4740278, 12.2923361],
                [108.4748465, 12.2931582],
                [108.4749915, 12.2932929],
                [108.4752917, 12.2935513],
                [108.4754595, 12.2937278],
                [108.4756035, 12.2939232],
                [108.4757217, 12.2941345],
                [108.4758121, 12.2943585],
                [108.475849, 12.2944813],
                [108.4758998, 12.2947323],
                [108.4759194, 12.2949875],
                [108.4759086, 12.2952342],
                [108.4758924, 12.2953567],
                [108.4758387, 12.295598],
                [108.4757322, 12.2959183],
                [108.4756573, 12.2961187],
                [108.4753144, 12.2969274],
                [108.4751467, 12.2973453],
                [108.4749078, 12.2979768],
                [108.4748357, 12.2981899],
                [108.4747158, 12.2986128],
                [108.4746022, 12.2991501],
                [108.474589, 12.2992592],
                [108.4745845, 12.2993748],
                [108.4745899, 12.2994904],
                [108.4746049, 12.2996052],
                [108.4746296, 12.2997184],
                [108.4746637, 12.2998292],
                [108.4747071, 12.2999369],
                [108.4747594, 12.3000408],
                [108.4748522, 12.3001889],
                [108.4748894, 12.300234],
                [108.4749918, 12.3003203],
                [108.4750505, 12.3003533],
                [108.4751131, 12.3003788],
                [108.4752454, 12.3004059],
                [108.475411, 12.3004022],
                [108.475604, 12.3003698],
                [108.4757893, 12.300308],
                [108.4758776, 12.3002665],
                [108.4760483, 12.3001602],
                [108.476205, 12.3000249],
                [108.4764096, 12.2997854],
                [108.4764865, 12.2997081],
                [108.4767484, 12.2994871],
                [108.4768381, 12.2994012],
                [108.4770032, 12.2992162],
                [108.4770781, 12.2991177],
                [108.4772074, 12.2989907],
                [108.4773178, 12.2989103],
                [108.4774316, 12.2988507],
                [108.4775904, 12.2988022],
                [108.4776728, 12.2987905],
                [108.4778392, 12.298793],
                [108.4780013, 12.2988296],
                [108.4781519, 12.2988988],
                [108.4782207, 12.2989447],
                [108.478362, 12.2990651],
                [108.4787035, 12.2994269],
                [108.4790893, 12.2998047],
                [108.4792748, 12.2999626],
                [108.4794768, 12.3000922],
                [108.4796948, 12.3001941],
                [108.4799296, 12.3002692],
                [108.4800524, 12.3002976],
                [108.4804692, 12.3003607],
                [108.4806347, 12.3003965],
                [108.4808565, 12.3004221],
                [108.4810241, 12.3004259],
                [108.4811687, 12.3004101],
                [108.4812556, 12.3003884],
                [108.4813397, 12.3003578],
                [108.4814199, 12.3003187],
                [108.4814955, 12.3002715],
                [108.4815656, 12.3002168],
                [108.4816294, 12.3001552],
                [108.4817442, 12.3000103],
                [108.4818739, 12.2998672],
                [108.4819543, 12.2997934],
                [108.4821292, 12.2996622],
                [108.4823204, 12.2995549],
                [108.482421, 12.2995108],
                [108.4827192, 12.2993996],
                [108.4828716, 12.2993535],
                [108.4829649, 12.2993378],
                [108.483154, 12.2993328],
                [108.483248, 12.2993437],
                [108.4834306, 12.2993916],
                [108.4836005, 12.2994727],
                [108.4836787, 12.2995249],
                [108.4837514, 12.2995841],
                [108.4838012, 12.2996384],
                [108.4838434, 12.2996985],
                [108.4838775, 12.2997633],
                [108.4839028, 12.2998319],
                [108.483919, 12.299903],
                [108.4839257, 12.2999756],
                [108.4839229, 12.3000484],
                [108.4839107, 12.3001203],
                [108.4838891, 12.3001901],
                [108.4838586, 12.3002566],
                [108.4838196, 12.3003188],
                [108.4837103, 12.300463],
                [108.4836551, 12.300555],
                [108.4835681, 12.3007504],
                [108.4835167, 12.3009383],
                [108.4834564, 12.3011041],
                [108.48341, 12.3011949],
                [108.4833555, 12.3012814],
                [108.4832933, 12.3013628],
                [108.483224, 12.3014385],
                [108.4831481, 12.3015079],
                [108.4830662, 12.3015704],
                [108.482979, 12.3016255],
                [108.4828871, 12.3016729],
                [108.4827912, 12.3017121],
                [108.4823715, 12.3018504],
                [108.4822338, 12.3019074],
                [108.4819698, 12.3020443],
                [108.4817237, 12.30221],
                [108.4814881, 12.3024076],
                [108.481275, 12.3026282],
                [108.4810865, 12.3028692],
                [108.4809249, 12.3031282],
                [108.4808546, 12.3032635],
                [108.4807551, 12.303511],
                [108.480717, 12.3036387],
                [108.4806649, 12.3038997],
                [108.4806289, 12.3043568],
                [108.4805825, 12.3047578],
                [108.480572, 12.3049686],
                [108.4805709, 12.3051797],
                [108.4805866, 12.305496],
                [108.4806334, 12.3058454],
                [108.4806784, 12.3060268],
                [108.480752, 12.3062226],
                [108.4808872, 12.3064929],
                [108.4809403, 12.3066702],
                [108.4809826, 12.3070413],
                [108.4810123, 12.3072278],
                [108.481051, 12.3074127],
                [108.4811228, 12.3076871],
                [108.481155, 12.3077761],
                [108.4811945, 12.3078612],
                [108.4812947, 12.3080201],
                [108.4814211, 12.30816],
                [108.4815871, 12.3083123],
                [108.4817556, 12.308511],
                [108.4818292, 12.3086182],
                [108.4820606, 12.3089994],
                [108.4821526, 12.3091087],
                [108.4822749, 12.3092112],
                [108.4824142, 12.3092902],
                [108.4824888, 12.3093201],
                [108.4826666, 12.3093681],
                [108.4829837, 12.3094215],
                [108.4830944, 12.309448],
                [108.4833105, 12.3095187],
                [108.4836013, 12.3096507],
                [108.4836996, 12.3096822],
                [108.4838004, 12.3097049],
                [108.4839029, 12.3097186],
                [108.4840062, 12.3097232],
                [108.4841018, 12.3097194],
                [108.4842903, 12.3096884],
                [108.484471, 12.3096273],
                [108.4845286, 12.3095886],
                [108.4845824, 12.309545],
                [108.4846319, 12.3094968],
                [108.4846837, 12.3094355],
                [108.4847286, 12.3093692],
                [108.4847661, 12.3092986],
                [108.4847934, 12.3092125],
                [108.4848222, 12.3090519],
                [108.4848315, 12.3088512],
                [108.4848265, 12.3087508],
                [108.4847809, 12.3084254],
                [108.4847675, 12.3081709],
                [108.4847716, 12.3076869],
                [108.4847814, 12.3075088],
                [108.4847968, 12.3073361],
                [108.4848451, 12.3069926],
                [108.4849691, 12.3063977],
                [108.4849963, 12.3062364],
                [108.4850285, 12.3059112],
                [108.4850539, 12.305437],
                [108.4850879, 12.3052996],
                [108.4851181, 12.3052261],
                [108.4852011, 12.3050904],
                [108.4853109, 12.3049743],
                [108.4854429, 12.3048829],
                [108.4855914, 12.3048201],
                [108.4859368, 12.3047072],
                [108.4863965, 12.3045795],
                [108.4866105, 12.3045397],
                [108.4867294, 12.3045298],
                [108.4869679, 12.3045352],
                [108.487203, 12.3045744],
                [108.4873178, 12.3046064],
                [108.4876672, 12.3047451],
                [108.4879303, 12.3048306],
                [108.4880411, 12.3048735],
                [108.4881485, 12.3049241],
                [108.4883509, 12.305047],
                [108.4884449, 12.3051187],
                [108.4886159, 12.3052808],
                [108.4887832, 12.3054865],
                [108.4888691, 12.3056063],
                [108.4889863, 12.3057265],
                [108.4891215, 12.3058269],
                [108.4893671, 12.305959],
                [108.489458, 12.3060205],
                [108.4895433, 12.3060891],
                [108.4897632, 12.3063068],
                [108.4898372, 12.3063608],
                [108.4899163, 12.3064073],
                [108.4899998, 12.3064458],
                [108.4901766, 12.3064972],
                [108.4902681, 12.3065096],
                [108.4904932, 12.3065108],
                [108.4907571, 12.3064812],
                [108.4910148, 12.3064187],
                [108.4912178, 12.3063408],
                [108.4914754, 12.3062252],
                [108.4916286, 12.3061316],
                [108.4917696, 12.3060122],
                [108.4918897, 12.3058727],
                [108.4919858, 12.3057164],
                [108.4921521, 12.3053967],
                [108.49227, 12.3052367],
                [108.4924145, 12.3050993],
                [108.4924954, 12.3050403],
                [108.4926175, 12.3049654],
                [108.4926724, 12.3049197],
                [108.4927718, 12.3048077],
                [108.4928122, 12.3047449],
                [108.4928724, 12.3046089],
                [108.4929249, 12.3043525],
                [108.4929836, 12.3041335],
                [108.4930269, 12.3040025],
                [108.4931284, 12.3037457],
                [108.4931966, 12.3035236],
                [108.4932203, 12.30341],
                [108.4932677, 12.3030059],
                [108.4932972, 12.3028333],
                [108.4934096, 12.3023527],
                [108.4934689, 12.3018796],
                [108.4934993, 12.3016916],
                [108.493579, 12.301319],
                [108.493628, 12.3011437],
                [108.4937488, 12.3008001],
                [108.4939395, 12.3003736],
                [108.4940672, 12.3001187],
                [108.4941381, 12.299957],
                [108.4942603, 12.2996261],
                [108.4943561, 12.299411],
                [108.4943871, 12.2992685],
                [108.4943944, 12.2990441],
                [108.4944083, 12.2989664],
                [108.4944609, 12.2988174],
                [108.4944991, 12.2987478],
                [108.4946216, 12.2985857],
                [108.4947058, 12.2984947],
                [108.4954226, 12.2978196],
                [108.4957999, 12.2974404],
                [108.496133, 12.297067],
                [108.4962919, 12.296874],
                [108.4965938, 12.296476],
                [108.4967086, 12.2963365],
                [108.4969564, 12.2960728],
                [108.4972268, 12.2958313],
                [108.4973197, 12.2957377],
                [108.4974057, 12.295638],
                [108.4974844, 12.2955326],
                [108.4977012, 12.295175],
                [108.4977924, 12.2950481],
                [108.4978915, 12.2949271],
                [108.4979981, 12.2948124],
                [108.498213, 12.2945979],
                [108.4983975, 12.2943701],
                [108.4985571, 12.2941251],
                [108.4987099, 12.29386],
                [108.4990255, 12.2934149],
                [108.4993872, 12.2928648],
                [108.4996355, 12.2925293],
                [108.4998232, 12.2923091],
                [108.49987, 12.2922364],
                [108.4999081, 12.2921591],
                [108.4999372, 12.292078],
                [108.4999569, 12.2919943],
                [108.4999668, 12.291909],
                [108.499967, 12.2918232],
                [108.4999501, 12.2916069],
                [108.4999469, 12.2913568],
                [108.4999173, 12.2911206],
                [108.4997803, 12.2903907],
                [108.4996955, 12.2901085],
                [108.4995825, 12.2898093],
                [108.499484, 12.2894793],
                [108.4993071, 12.2886428],
                [108.4992195, 12.2883287],
                [108.4991061, 12.2880381],
                [108.4990389, 12.2878972],
                [108.4988576, 12.2875551],
                [108.4987769, 12.2873847],
                [108.4985998, 12.2869483],
                [108.4985733, 12.286858],
                [108.498555, 12.2867621],
                [108.4985461, 12.2866649],
                [108.4985467, 12.2865674],
                [108.4985569, 12.2864704],
                [108.4985766, 12.2863748],
                [108.4987557, 12.2858149],
                [108.4989421, 12.2853475],
                [108.4991605, 12.2848935],
                [108.4993935, 12.2844838],
                [108.4996371, 12.2841185],
                [108.4999054, 12.2837702],
                [108.5002315, 12.2833986],
                [108.5006129, 12.2830037],
                [108.5009106, 12.2827186],
                [108.5010141, 12.282628],
                [108.5011532, 12.2825174],
                [108.5012982, 12.2824142],
                [108.5015421, 12.2822673],
                [108.5017168, 12.2821458],
                [108.5018795, 12.2819939],
                [108.5020175, 12.2818203],
                [108.5020763, 12.2817265],
                [108.5021721, 12.2816278],
                [108.5022886, 12.2815534],
                [108.5024196, 12.2815072],
                [108.5025579, 12.2814916],
                [108.5026275, 12.2814957],
                [108.5026961, 12.2815076],
                [108.5028603, 12.2815637],
                [108.5030434, 12.2816611],
                [108.5032949, 12.2818329],
                [108.5034667, 12.2819378],
                [108.5041171, 12.2822866],
                [108.5042596, 12.282345],
                [108.5044124, 12.2823716],
                [108.5045676, 12.2823649],
                [108.5047174, 12.2823251],
                [108.5048547, 12.2822543],
                [108.5049728, 12.2821558],
                [108.505066, 12.2820344],
                [108.5051297, 12.2818961],
                [108.5051798, 12.2816744],
                [108.5052398, 12.2815359],
                [108.5052802, 12.2814717],
                [108.505484, 12.281201],
                [108.5055329, 12.2811014],
                [108.5055625, 12.2809885],
                [108.5055868, 12.2806441],
                [108.5055652, 12.2804937],
                [108.5055417, 12.2804213],
                [108.5055362, 12.2803085],
                [108.5055664, 12.2801995],
                [108.5056292, 12.2801048],
                [108.5056711, 12.2800657],
                [108.5058081, 12.2799662],
                [108.5059725, 12.2798156],
                [108.5062523, 12.2794825],
                [108.5065449, 12.279173],
                [108.5069023, 12.2788447],
                [108.5074239, 12.2784078],
                [108.5077024, 12.2782014],
                [108.5080571, 12.2779742],
                [108.5084284, 12.2777738],
                [108.508814, 12.2776012],
                [108.5092034, 12.2774636],
                [108.5094023, 12.2774069],
                [108.5100649, 12.2772594],
                [108.5103214, 12.2771924],
                [108.5106314, 12.2771413],
                [108.5109452, 12.2771215],
                [108.5112595, 12.277133],
                [108.5116822, 12.2771863],
                [108.5119475, 12.2772295],
                [108.5124737, 12.2773379],
                [108.5129928, 12.2774754],
                [108.5131422, 12.2775041],
                [108.5132931, 12.2775237],
                [108.513445, 12.2775343],
                [108.5138731, 12.2775303],
                [108.5141189, 12.2775589],
                [108.5142398, 12.2775849],
                [108.5143588, 12.2776185],
                [108.5145887, 12.2777081],
                [108.5148206, 12.2778328],
                [108.5149387, 12.2779077],
                [108.5151624, 12.2780748],
                [108.5152675, 12.2781665],
                [108.5154685, 12.2783775],
                [108.5155585, 12.2784918],
                [108.515641, 12.2786114],
                [108.5157824, 12.2788645],
                [108.5158736, 12.2790583],
                [108.5160796, 12.2795743],
                [108.5161954, 12.2798028],
                [108.5162891, 12.2799142],
                [108.5163448, 12.2799617],
                [108.5164703, 12.2800375],
                [108.5166223, 12.28009],
                [108.516795, 12.2801162],
                [108.5169695, 12.2801095],
                [108.5170555, 12.2800938],
                [108.5172676, 12.2800272],
                [108.5173929, 12.279977],
                [108.5175148, 12.2799196],
                [108.5179854, 12.279671],
                [108.5182439, 12.2795544],
                [108.5183851, 12.2795004],
                [108.5186005, 12.2794288],
                [108.5187797, 12.2793897],
                [108.5189948, 12.279376],
                [108.5191025, 12.2793823],
                [108.5192092, 12.2793974],
                [108.519417, 12.2794533],
                [108.5195166, 12.2794938],
                [108.5197037, 12.2795983],
                [108.5199041, 12.2797444],
                [108.5200866, 12.2799113],
                [108.520285, 12.2801318],
                [108.5203959, 12.2802646],
                [108.5204634, 12.2803581],
                [108.5205599, 12.2804499],
                [108.5206887, 12.280529],
                [108.520759, 12.2805576],
                [108.5209073, 12.2805909],
                [108.5210595, 12.2805912],
                [108.5212079, 12.2805586],
                [108.5212784, 12.2805304],
                [108.5214572, 12.2804403],
                [108.5216886, 12.2803479],
                [108.5218436, 12.2802595],
                [108.5220596, 12.2800769],
                [108.5222371, 12.2799639],
                [108.5227991, 12.2796751],
                [108.5231078, 12.2795413],
                [108.5236412, 12.2793572],
                [108.5242741, 12.2791794],
                [108.524403, 12.2791528],
                [108.5246602, 12.2791243],
                [108.5249191, 12.2791278],
                [108.5251754, 12.2791633],
                [108.52597, 12.2793162],
                [108.5265622, 12.279448],
                [108.5269835, 12.2795659],
                [108.5272062, 12.2796416],
                [108.5274257, 12.2797259],
                [108.5276416, 12.2798185],
                [108.5278537, 12.2799193],
                [108.5280615, 12.2800282],
                [108.5283578, 12.2801493],
                [108.5285106, 12.2801976],
                [108.528744, 12.2802564],
                [108.5288233, 12.2802693],
                [108.5290086, 12.280276],
                [108.5291009, 12.2802673],
                [108.5292815, 12.2802261],
                [108.5294518, 12.2801543],
                [108.5296065, 12.2800544],
                [108.5296896, 12.2799843],
                [108.529766, 12.2799072],
                [108.529835, 12.2798238],
                [108.5299771, 12.2796054],
                [108.5300517, 12.2794725],
                [108.5301093, 12.2793928],
                [108.5302451, 12.2792498],
                [108.5304045, 12.2791325],
                [108.5307064, 12.2789699],
                [108.5308602, 12.2789135],
                [108.5310237, 12.27889],
                [108.5311065, 12.2788911],
                [108.5312693, 12.2789192],
                [108.5314223, 12.2789804],
                [108.5314929, 12.2790225],
                [108.5316183, 12.2791277],
                [108.5317583, 12.2792998],
                [108.5319349, 12.2795439],
                [108.5320963, 12.2797899],
                [108.5322243, 12.2800229],
                [108.532334, 12.2801664],
                [108.5324698, 12.2802867],
                [108.5327241, 12.2804388],
                [108.5328171, 12.2805047],
                [108.5329881, 12.2806547],
                [108.5330651, 12.280738],
                [108.5332006, 12.2809192],
                [108.5332411, 12.2809886],
                [108.5333498, 12.2812137],
                [108.533399, 12.2812891],
                [108.5335173, 12.2814253],
                [108.5336589, 12.2815383],
                [108.5338271, 12.2816295],
                [108.5339206, 12.2816668],
                [108.534017, 12.2816963],
                [108.5342155, 12.2817317],
                [108.5344173, 12.2817348],
                [108.5348809, 12.2816692],
                [108.5351528, 12.2816554],
                [108.53543, 12.2816754],
                [108.535567, 12.2816983],
                [108.5358352, 12.2817694],
                [108.5359653, 12.2818173],
                [108.5362204, 12.2819388],
                [108.536464, 12.2820917],
                [108.5366875, 12.2822716],
                [108.5368878, 12.2824068],
                [108.5372887, 12.2826005],
                [108.5374677, 12.2826954],
                [108.5376428, 12.2827971],
                [108.5379798, 12.2830204],
                [108.538641, 12.2835313],
                [108.5390478, 12.2838283],
                [108.5391653, 12.2839225],
                [108.5394424, 12.2841766],
                [108.5395543, 12.2843101],
                [108.5396084, 12.284393],
                [108.5396937, 12.2845711],
                [108.5397464, 12.2847608],
                [108.539765, 12.2849566],
                [108.5397642, 12.2851757],
                [108.5397947, 12.2854155],
                [108.5398221, 12.2855334],
                [108.5399008, 12.2857625],
                [108.5399409, 12.285947],
                [108.5399464, 12.2861355],
                [108.5399173, 12.2863219],
                [108.5398381, 12.2865582],
                [108.5397774, 12.2867007],
                [108.5397453, 12.2867967],
                [108.5397193, 12.2868945],
                [108.5396862, 12.2870938],
                [108.5396667, 12.2871592],
                [108.5396391, 12.2872217],
                [108.5395613, 12.2873344],
                [108.5394577, 12.287425],
                [108.5393344, 12.287488],
                [108.53919, 12.28754],
                [108.5391165, 12.2875805],
                [108.5390482, 12.2876289],
                [108.5389859, 12.2876845],
                [108.5389304, 12.2877466],
                [108.5388826, 12.2878145],
                [108.5387699, 12.2880462],
                [108.5387207, 12.2881245],
                [108.5386019, 12.2882667],
                [108.5384591, 12.2883861],
                [108.5383801, 12.2884359],
                [108.5382101, 12.2885141],
                [108.5379305, 12.2885898],
                [108.5378348, 12.2886252],
                [108.5376527, 12.2887161],
                [108.5374262, 12.2888533],
                [108.5372806, 12.2889277],
                [108.537131, 12.2889939],
                [108.5369779, 12.2890519],
                [108.5368216, 12.2891013],
                [108.5367101, 12.2891472],
                [108.5364988, 12.289262],
                [108.5363522, 12.2893664],
                [108.5362394, 12.2894727],
                [108.5361791, 12.289546],
                [108.5361263, 12.2896247],
                [108.5360816, 12.289708],
                [108.5360453, 12.2897952],
                [108.5360178, 12.2898853],
                [108.5359993, 12.2899776],
                [108.5359901, 12.2900713],
                [108.5359901, 12.2901654],
                [108.5360149, 12.290337],
                [108.536071, 12.2905014],
                [108.5361564, 12.2906533],
                [108.5362685, 12.2907876],
                [108.5364205, 12.2909349],
                [108.5365019, 12.291028],
                [108.5366464, 12.2912281],
                [108.5368475, 12.2915791],
                [108.5371059, 12.2920785],
                [108.5371916, 12.2922213],
                [108.5372853, 12.2923593],
                [108.5373866, 12.2924919],
                [108.5374954, 12.2926189],
                [108.5376111, 12.2927398],
                [108.5379613, 12.2930567],
                [108.5381956, 12.2932519],
                [108.5384363, 12.2934395],
                [108.5386038, 12.2935556],
                [108.5387685, 12.293696],
                [108.5388496, 12.2937769],
                [108.5389975, 12.2939512],
                [108.5391703, 12.2942028],
                [108.5393376, 12.2944737],
                [108.5393928, 12.2946123],
                [108.5394121, 12.2946956],
                [108.5394223, 12.2947805],
                [108.539423, 12.294866],
                [108.5393862, 12.2951253],
                [108.5393851, 12.2952166],
                [108.5393932, 12.2953074],
                [108.5394367, 12.2954847],
                [108.5395355, 12.2957097],
                [108.5396057, 12.2958473],
                [108.5396821, 12.2959818],
                [108.5398525, 12.2962402],
                [108.539948, 12.2963617],
                [108.5400509, 12.2964774],
                [108.5401608, 12.2965868],
                [108.5402771, 12.2966896],
                [108.5403997, 12.2967853],
                [108.5406937, 12.2969857],
                [108.5408474, 12.2970759],
                [108.5411666, 12.2972355],
                [108.5414993, 12.2973661],
                [108.5418427, 12.2974666],
                [108.5421331, 12.2975173],
                [108.5424275, 12.2975353],
                [108.542722, 12.2975205],
                [108.5428682, 12.2975007],
                [108.543013, 12.2974729],
                [108.5434201, 12.2973634],
                [108.5436719, 12.2973263],
                [108.543799, 12.2973192],
                [108.5440535, 12.2973279],
                [108.544305, 12.2973671],
                [108.5444358, 12.297382],
                [108.5445673, 12.2973874],
                [108.5446989, 12.2973832],
                [108.5448299, 12.2973696],
                [108.5449594, 12.2973466],
                [108.5451503, 12.2972964],
                [108.5452816, 12.2972366],
                [108.5453468, 12.2971927],
                [108.5454063, 12.2971417],
                [108.5454593, 12.2970841],
                [108.5455049, 12.2970209],
                [108.5455427, 12.2969529],
                [108.545572, 12.296881],
                [108.545634, 12.296633],
                [108.5456943, 12.2962821],
                [108.5457067, 12.2960515],
                [108.5456954, 12.295722],
                [108.5457222, 12.2955262],
                [108.5457814, 12.2953374],
                [108.5458441, 12.2952022],
                [108.5459047, 12.2951217],
                [108.5459856, 12.2950588],
                [108.5460806, 12.2950189],
                [108.5462595, 12.2949806],
                [108.5464425, 12.2949764],
                [108.5465335, 12.2949873],
                [108.5467103, 12.2950342],
                [108.5467943, 12.2950699],
                [108.5471374, 12.2952653],
                [108.5472003, 12.2952853],
                [108.5472656, 12.2952966],
                [108.5473317, 12.2952989],
                [108.5473976, 12.2952922],
                [108.5474619, 12.2952767],
                [108.5475233, 12.2952526],
                [108.5475807, 12.2952203],
                [108.5477326, 12.2950915],
                [108.5477906, 12.2950549],
                [108.5479177, 12.2950026],
                [108.5480521, 12.2949649],
                [108.5481772, 12.2948994],
                [108.5482336, 12.2948573],
                [108.5483312, 12.2947566],
                [108.5483712, 12.2946992],
                [108.5484481, 12.2946253],
                [108.5484927, 12.2945956],
                [108.5486353, 12.2945279],
                [108.5486752, 12.2944972],
                [108.5487389, 12.2944203],
                [108.5487612, 12.2943757],
                [108.5487844, 12.2942794],
                [108.5487847, 12.2942298],
                [108.5487512, 12.2940687],
                [108.5487487, 12.2940033],
                [108.5487708, 12.2938744],
                [108.5488172, 12.2937385],
                [108.5488395, 12.2935843],
                [108.5488316, 12.2934287],
                [108.5488073, 12.2932704],
                [108.5488107, 12.2931062],
                [108.5488427, 12.2929449],
                [108.5488691, 12.2928669],
                [108.5489513, 12.2927005],
                [108.5490085, 12.2926144],
                [108.5490734, 12.2925335],
                [108.5491453, 12.2924587],
                [108.5492239, 12.2923905],
                [108.5493084, 12.2923294],
                [108.5493981, 12.2922759],
                [108.5495832, 12.292194],
                [108.5497714, 12.2921388],
                [108.5498679, 12.2921204],
                [108.5500529, 12.2921063],
                [108.5501457, 12.2921095],
                [108.5503292, 12.2921362],
                [108.5503904, 12.2921577],
                [108.5505016, 12.2922234],
                [108.5505495, 12.2922663],
                [108.5506261, 12.2923688],
                [108.5506533, 12.2924266],
                [108.5507703, 12.2927739],
                [108.5508353, 12.2929954],
                [108.550883, 12.2932507],
                [108.5509032, 12.2935094],
                [108.5509424, 12.2937692],
                [108.5509747, 12.2938967],
                [108.5510641, 12.2941442],
                [108.5511853, 12.2943786],
                [108.5514665, 12.2948302],
                [108.5515306, 12.2949168],
                [108.5517356, 12.2951521],
                [108.5517881, 12.2952328],
                [108.5518688, 12.2954068],
                [108.5519044, 12.2954635],
                [108.5519479, 12.2955148],
                [108.5519984, 12.2955596],
                [108.5520547, 12.295597],
                [108.552116, 12.2956263],
                [108.5521808, 12.2956469],
                [108.5522479, 12.2956583],
                [108.552316, 12.2956604],
                [108.5526207, 12.2956239],
                [108.5532754, 12.2955224],
                [108.5536444, 12.2954854],
                [108.554006, 12.2954611],
                [108.5543681, 12.295446],
                [108.5547304, 12.2954401],
                [108.5550928, 12.2954435],
                [108.55531, 12.2954338],
                [108.5555517, 12.2953971],
                [108.5558133, 12.2953249],
                [108.5560633, 12.2952208],
                [108.5561828, 12.2951573],
                [108.5562591, 12.2950941],
                [108.5563289, 12.295024],
                [108.5563915, 12.2949477],
                [108.5564463, 12.2948659],
                [108.5564927, 12.2947793],
                [108.5565575, 12.2946012],
                [108.5565871, 12.294421],
                [108.5566432, 12.293865],
                [108.5566769, 12.2937125],
                [108.5567923, 12.2932818],
                [108.5568324, 12.2930928],
                [108.5569057, 12.2929007],
                [108.5570158, 12.2927123],
                [108.5570825, 12.2926254],
                [108.5571564, 12.2925443],
                [108.5572369, 12.2924694],
                [108.5573688, 12.2923698],
                [108.5575033, 12.2922938],
                [108.5575948, 12.2922548],
                [108.5577868, 12.2922015],
                [108.5579854, 12.2921823],
                [108.5580853, 12.2921857],
                [108.558282, 12.2922185],
                [108.5584697, 12.2922849],
                [108.5587458, 12.2924201],
                [108.5589364, 12.292504],
                [108.5593256, 12.292653],
                [108.5598118, 12.2928072],
                [108.5602464, 12.292933],
                [108.5605697, 12.2930062],
                [108.5607481, 12.293035],
                [108.5609277, 12.293056],
                [108.5611983, 12.2930747],
                [108.5612887, 12.2930744],
                [108.5614959, 12.293051],
                [108.5615975, 12.2930278],
                [108.5617936, 12.2929586],
                [108.5619766, 12.2928608],
                [108.5620617, 12.2928018],
                [108.5622168, 12.2926655],
                [108.5622776, 12.2926219],
                [108.5624127, 12.2925565],
                [108.5625595, 12.2925231],
                [108.5627102, 12.2925235],
                [108.5628568, 12.2925575],
                [108.5629916, 12.2926235],
                [108.563163, 12.2927565],
                [108.5632874, 12.2928109],
                [108.563422, 12.292832],
                [108.5635576, 12.2928184],
                [108.563685, 12.2927712],
                [108.5637429, 12.2927358],
                [108.5638999, 12.2925955],
                [108.56403, 12.2925172],
                [108.5641009, 12.2924889],
                [108.5643252, 12.2924344],
                [108.5643973, 12.2924044],
                [108.5644654, 12.2923663],
                [108.5645285, 12.2923207],
                [108.5646841, 12.2921674],
                [108.5647944, 12.2921023],
                [108.5649179, 12.2920662],
                [108.5650467, 12.2920614],
                [108.5651726, 12.2920882],
                [108.5652877, 12.2921448],
                [108.5654779, 12.2922943],
                [108.5656121, 12.2924109],
                [108.565741, 12.292533],
                [108.5660533, 12.2928646],
                [108.5662358, 12.2930743],
                [108.5663477, 12.2932219],
                [108.5664117, 12.2932893],
                [108.5665561, 12.2934106],
                [108.5666748, 12.2934885],
                [108.5667738, 12.2935286],
                [108.5668323, 12.2935397],
                [108.5669512, 12.293537],
                [108.5670645, 12.2935015],
                [108.5672179, 12.2934041],
                [108.567277, 12.2933799],
                [108.5674027, 12.2933567],
                [108.56753, 12.2933681],
                [108.5675912, 12.2933867],
                [108.5677029, 12.2934476],
                [108.5677511, 12.2934889],
                [108.5678278, 12.2935889],
                [108.5679679, 12.2938599],
                [108.5680706, 12.2940149],
                [108.5682075, 12.294175],
                [108.5684394, 12.2943915],
                [108.5685077, 12.2944723],
                [108.5686226, 12.2946491],
                [108.5686683, 12.2947438],
                [108.5687349, 12.2949429],
                [108.5687667, 12.29515],
                [108.5688057, 12.2953212],
                [108.5688485, 12.2954461],
                [108.5688994, 12.295528],
                [108.56898, 12.2955957],
                [108.5690787, 12.2956343],
                [108.5691848, 12.2956396],
                [108.5692871, 12.2956112],
                [108.5694055, 12.2955464],
                [108.5694587, 12.2955051],
                [108.5695499, 12.2954068],
                [108.5696401, 12.2953313],
                [108.5696911, 12.2953015],
                [108.5698652, 12.2952321],
                [108.5699785, 12.2951542],
                [108.5700683, 12.2950513],
                [108.5701293, 12.2949298],
                [108.5701776, 12.2947573],
                [108.5701895, 12.2946685],
                [108.5701931, 12.294579],
                [108.5701841, 12.2943043],
                [108.5702038, 12.293747],
                [108.5701936, 12.2933731],
                [108.5702097, 12.2930788],
                [108.5702312, 12.2929328],
                [108.570257, 12.2928363],
                [108.5702917, 12.2927425],
                [108.570335, 12.2926522],
                [108.5703866, 12.2925661],
                [108.570446, 12.2924849],
                [108.5705127, 12.2924094],
                [108.5705861, 12.2923401],
                [108.5709807, 12.2920412],
                [108.571353, 12.2917229],
                [108.5714221, 12.2916539],
                [108.5715436, 12.2915052],
                [108.5715964, 12.2914251],
                [108.5716662, 12.2912996],
                [108.5717047, 12.2911909],
                [108.5717191, 12.2910568],
                [108.5717136, 12.2909895],
                [108.5716774, 12.2908594],
                [108.5716473, 12.2907986],
                [108.5715476, 12.2906398],
                [108.5714382, 12.2904274],
                [108.5713374, 12.2902882],
                [108.5712099, 12.2901716],
                [108.5710305, 12.2900396],
                [108.5709961, 12.2899978],
                [108.5709697, 12.2899508],
                [108.570952, 12.2899],
                [108.5709436, 12.289847],
                [108.5709448, 12.2897934],
                [108.5709739, 12.2896912],
                [108.5710329, 12.289603],
                [108.5711163, 12.2895364],
                [108.5712534, 12.2894694],
                [108.571402, 12.2894325],
                [108.5715552, 12.2894273],
                [108.5717478, 12.2894575],
                [108.5719754, 12.2895206],
                [108.5721928, 12.2896118],
                [108.5723644, 12.2897026],
                [108.5725295, 12.2898057],
                [108.5726233, 12.2898773],
                [108.5727118, 12.2899553],
                [108.5728706, 12.2901286],
                [108.5730336, 12.2903593],
                [108.5734214, 12.2909951],
                [108.5735483, 12.2911513],
                [108.5736323, 12.2912348],
                [108.5737226, 12.2913116],
                [108.5738188, 12.2913813],
                [108.5739203, 12.2914435],
                [108.5740801, 12.291524],
                [108.5741365, 12.2915439],
                [108.5742022, 12.2915584],
                [108.5743365, 12.2915617],
                [108.5744671, 12.2915309],
                [108.5745283, 12.2915033],
                [108.5746368, 12.2914259],
                [108.5746825, 12.2913775],
                [108.5747531, 12.2912637],
                [108.5747764, 12.2912],
                [108.5747906, 12.2911338],
                [108.5747955, 12.2910663],
                [108.5747914, 12.2909042],
                [108.5747998, 12.29081],
                [108.5748402, 12.2906252],
                [108.5749111, 12.2904493],
                [108.5749573, 12.2903662],
                [108.5750699, 12.2902125],
                [108.5751504, 12.2900746],
                [108.5752041, 12.2899247],
                [108.5752203, 12.2898468],
                [108.5752308, 12.2896884],
                [108.5752243, 12.2895948],
                [108.575184, 12.2894116],
                [108.575109, 12.2892392],
                [108.5748982, 12.2889231],
                [108.5748413, 12.2888241],
                [108.5747706, 12.2886682],
                [108.5747405, 12.2885517],
                [108.5747351, 12.2884253],
                [108.5747427, 12.2883623],
                [108.5747582, 12.2883089],
                [108.5748126, 12.2882118],
                [108.5748503, 12.2881703],
                [108.5749424, 12.2881059],
                [108.5750615, 12.2880656],
                [108.5751302, 12.2880552],
                [108.5751996, 12.2880533],
                [108.5753365, 12.2880751],
                [108.5754634, 12.2881297],
                [108.5756215, 12.2882299],
                [108.5758337, 12.2883349],
                [108.5761019, 12.2884318],
                [108.5762622, 12.2884776],
                [108.5764246, 12.2885155],
                [108.5769363, 12.2885971],
                [108.577117, 12.2886343],
                [108.5777057, 12.28879],
                [108.5779399, 12.2888445],
                [108.57815, 12.2888828],
                [108.5783618, 12.2889114],
                [108.5785747, 12.2889303],
                [108.5789308, 12.2889437],
                [108.5790935, 12.2889256],
                [108.5791834, 12.2889046],
                [108.579356, 12.2888401],
                [108.5795145, 12.2887473],
                [108.5796541, 12.288629],
                [108.5797155, 12.2885615],
                [108.5797828, 12.2884469],
                [108.5798199, 12.2883198],
                [108.5798246, 12.2881878],
                [108.5797967, 12.2880585],
                [108.5797377, 12.2879396],
                [108.5795735, 12.2877491],
                [108.5794364, 12.2875574],
                [108.5792806, 12.2872921],
                [108.5790949, 12.2869426],
                [108.5790557, 12.2868475],
                [108.5790399, 12.2867856],
                [108.5790288, 12.2866588],
                [108.5790047, 12.2865391],
                [108.578983, 12.2864819],
                [108.5788912, 12.2863157],
                [108.5788525, 12.2861875],
                [108.5788446, 12.286054],
                [108.5788525, 12.2859875],
                [108.5788681, 12.2859223],
                [108.5789514, 12.2857269],
                [108.5789752, 12.2856524],
                [108.5790344, 12.2855662],
                [108.579074, 12.2855314],
                [108.5791679, 12.2854829],
                [108.5792197, 12.2854706],
                [108.579326, 12.2854715],
                [108.5794815, 12.2855046],
                [108.5795867, 12.2855161],
                [108.5796682, 12.2855337],
                [108.5798243, 12.2855908],
                [108.5799669, 12.2856751],
                [108.5800315, 12.2857267],
                [108.5802044, 12.2859039],
                [108.5802694, 12.2859557],
                [108.5803751, 12.2860223],
                [108.5804725, 12.2860613],
                [108.5805344, 12.2860745],
                [108.5806609, 12.2860771],
                [108.5807838, 12.2860479],
                [108.5808949, 12.2859889],
                [108.5809869, 12.285904],
                [108.5811049, 12.2857677],
                [108.5812001, 12.2857034],
                [108.581311, 12.2856709],
                [108.5814981, 12.2856723],
                [108.5816374, 12.2856437],
                [108.5817648, 12.2855816],
                [108.5819408, 12.2854342],
                [108.5820151, 12.2853855],
                [108.5821768, 12.2853114],
                [108.5823504, 12.2852706],
                [108.5824395, 12.2852632],
                [108.5826898, 12.2852752],
                [108.5829163, 12.2852462],
                [108.5830008, 12.2852477],
                [108.5830845, 12.2852588],
                [108.5831664, 12.2852791],
                [108.5832454, 12.2853085],
                [108.5833204, 12.2853466],
                [108.5833904, 12.2853928],
                [108.5834545, 12.2854466],
                [108.5836184, 12.285637],
                [108.5836805, 12.285695],
                [108.5838916, 12.2858502],
                [108.5839586, 12.2859127],
                [108.5840196, 12.2859807],
                [108.5841217, 12.2861312],
                [108.5842332, 12.2863789],
                [108.5843269, 12.2865337],
                [108.5843818, 12.2866061],
                [108.5846857, 12.2869525],
                [108.584817, 12.2870834],
                [108.5849057, 12.2871544],
                [108.5850001, 12.2872181],
                [108.5850994, 12.2872741],
                [108.5852032, 12.2873218],
                [108.5859639, 12.287601],
                [108.586244, 12.2877123],
                [108.5864552, 12.2878023],
                [108.5868716, 12.2879954],
                [108.5870153, 12.288053],
                [108.5873107, 12.2881463],
                [108.5876143, 12.2882095],
                [108.587923, 12.2882417],
                [108.5880782, 12.288246],
                [108.5883882, 12.2882304],
                [108.5886948, 12.2881829],
                [108.5890561, 12.2880909],
                [108.5892641, 12.2880268],
                [108.5894778, 12.2879916],
                [108.589586, 12.2879849],
                [108.5897058, 12.2879478],
                [108.5898118, 12.2878817],
                [108.5898573, 12.2878392],
                [108.5899144, 12.2877657],
                [108.5899474, 12.2876917],
                [108.5899573, 12.2876425],
                [108.5899518, 12.2875428],
                [108.5899364, 12.287495],
                [108.5898624, 12.2873381],
                [108.5898203, 12.2872225],
                [108.5897871, 12.2871042],
                [108.589763, 12.2869838],
                [108.5896995, 12.2867828],
                [108.5896088, 12.286592],
                [108.5895013, 12.2864002],
                [108.589456, 12.2862956],
                [108.5893882, 12.2860784],
                [108.5893661, 12.2859669],
                [108.5893058, 12.2858063],
                [108.5891332, 12.2854857],
                [108.5888886, 12.2849867],
                [108.5888167, 12.284868],
                [108.5887369, 12.2847542],
                [108.5886497, 12.2846457],
                [108.5885552, 12.2845431],
                [108.5883296, 12.2843406],
                [108.5881988, 12.2842417],
                [108.5880623, 12.2841505],
                [108.5879206, 12.2840674],
                [108.587774, 12.2839925],
                [108.5876232, 12.2839263],
                [108.5874687, 12.2838689],
                [108.5869745, 12.2837306],
                [108.5866297, 12.2836075],
                [108.5862969, 12.2834561],
                [108.5861358, 12.2833701],
                [108.5858256, 12.2831784],
                [108.5856452, 12.2830301],
                [108.5855627, 12.2829478],
                [108.5855047, 12.2828752],
                [108.5854543, 12.2827975],
                [108.585412, 12.2827152],
                [108.5853782, 12.2826293],
                [108.5853532, 12.2825405],
                [108.5853374, 12.2824498],
                [108.5853478, 12.2823361],
                [108.5853906, 12.28223],
                [108.5854719, 12.2821228],
                [108.5855854, 12.2820182],
                [108.5857169, 12.2819362],
                [108.58586, 12.2818652],
                [108.5859919, 12.2817684],
                [108.5860962, 12.281721],
                [108.5861525, 12.281709],
                [108.5862675, 12.2817096],
                [108.5863237, 12.2817223],
                [108.5864274, 12.281771],
                [108.5866637, 12.2819773],
                [108.586833, 12.2820847],
                [108.5875278, 12.2823938],
                [108.5881608, 12.2827139],
                [108.5882031, 12.2827415],
                [108.5882494, 12.282762],
                [108.588348, 12.2827794],
                [108.5884461, 12.2827653],
                [108.5884921, 12.2827469],
                [108.5885721, 12.2826897],
                [108.588604, 12.2826524],
                [108.5886475, 12.2825654],
                [108.5886773, 12.2824608],
                [108.588736, 12.2823554],
                [108.5888184, 12.2822664],
                [108.588934, 12.2821697],
                [108.5889941, 12.2821034],
                [108.589047, 12.2820315],
                [108.589092, 12.2819546],
                [108.5891288, 12.2818736],
                [108.589215, 12.2816358],
                [108.5893149, 12.2814104],
                [108.589403, 12.2812696],
                [108.5895167, 12.2811458],
                [108.5895817, 12.2810921],
                [108.5897991, 12.2809527],
                [108.5899302, 12.2808325],
                [108.5900356, 12.2806904],
                [108.590154, 12.2804476],
                [108.5902023, 12.2803671],
                [108.5903156, 12.2802169],
                [108.5903557, 12.2801525],
                [108.5904132, 12.2800128],
                [108.5904381, 12.2798641],
                [108.5904292, 12.2797138],
                [108.5903904, 12.2795143],
                [108.5903777, 12.2793872],
                [108.5903735, 12.2791741],
                [108.5903959, 12.2790758],
                [108.5904199, 12.2790226],
                [108.5904897, 12.2789288],
                [108.5905341, 12.2788901],
                [108.5906374, 12.278833],
                [108.5907892, 12.2787856],
                [108.5909475, 12.2787678],
                [108.5910272, 12.2787703],
                [108.5911841, 12.2787978],
                [108.5913729, 12.2788536],
                [108.5914878, 12.278878],
                [108.591845, 12.2789242],
                [108.5919678, 12.2789484],
                [108.5921254, 12.2790004],
                [108.5922736, 12.2790742],
                [108.5923994, 12.2791098],
                [108.5925304, 12.2791107],
                [108.5926568, 12.2790767],
                [108.5927689, 12.2790105],
                [108.5928169, 12.2789667],
                [108.5928926, 12.2788646],
                [108.5929425, 12.2787506],
                [108.5929826, 12.2785538],
                [108.5930073, 12.278481],
                [108.5930785, 12.2783445],
                [108.5931242, 12.2782822],
                [108.5931762, 12.2782247],
                [108.5934517, 12.2779875],
                [108.5935557, 12.2778895],
                [108.593935, 12.2774865],
                [108.5941209, 12.2773388],
                [108.5943267, 12.277219],
                [108.5945481, 12.2771295],
                [108.5946381, 12.277086],
                [108.5947236, 12.2770344],
                [108.5948037, 12.2769751],
                [108.5948777, 12.2769088],
                [108.5949451, 12.2768359],
                [108.5950006, 12.2767914],
                [108.5951263, 12.276724],
                [108.5951945, 12.2767022],
                [108.595265, 12.2766887],
                [108.595514, 12.2766683],
                [108.5956019, 12.2766542],
                [108.5957932, 12.2766479],
                [108.5958951, 12.276658],
                [108.5959957, 12.2766772],
                [108.5960718, 12.2766987],
                [108.5962143, 12.2767547],
                [108.5963666, 12.2768422],
                [108.5964475, 12.2769037],
                [108.5965222, 12.2769722],
                [108.5968299, 12.2772925],
                [108.5969406, 12.2773916],
                [108.5971526, 12.2775522],
                [108.5972647, 12.2776242],
                [108.5975319, 12.2777691],
                [108.5978449, 12.2779062],
                [108.5980868, 12.2779916],
                [108.5982888, 12.2780432],
                [108.5984102, 12.2780637],
                [108.5986555, 12.2780822],
                [108.5987786, 12.2780801],
                [108.5990475, 12.2780518],
                [108.5993358, 12.2779917],
                [108.5994392, 12.277978],
                [108.5996475, 12.2779763],
                [108.5998532, 12.2780087],
                [108.6000504, 12.2780744],
                [108.6002335, 12.2781714],
                [108.6003974, 12.278297],
                [108.6005375, 12.2784478],
                [108.6008674, 12.2788593],
                [108.6010328, 12.2790126],
                [108.6012194, 12.2791406],
                [108.6013195, 12.2791942],
                [108.6015302, 12.2792791],
                [108.6018791, 12.2793728],
                [108.6021199, 12.2794295],
                [108.6024833, 12.2795047],
                [108.6027634, 12.279545],
                [108.6030811, 12.2795645],
                [108.6035822, 12.2795562],
                [108.6039466, 12.2795826],
                [108.6042375, 12.2796383],
                [108.6043799, 12.2796786],
                [108.6046564, 12.2797831],
                [108.6047895, 12.279847],
                [108.605043, 12.279997],
                [108.6051626, 12.2800826],
                [108.6054109, 12.2802932],
                [108.6055389, 12.2804177],
                [108.6057757, 12.2806841],
                [108.6061054, 12.2811285],
                [108.6062036, 12.2812184],
                [108.606322, 12.2812824],
                [108.606453, 12.2813161],
                [108.6065884, 12.2813174],
                [108.6068315, 12.2812593],
                [108.6069447, 12.2812405],
                [108.6071739, 12.2812277],
                [108.6073818, 12.2812326],
                [108.6075672, 12.2812142],
                [108.6077856, 12.2811805],
                [108.6080413, 12.2811724],
                [108.608169, 12.2811809],
                [108.6084213, 12.2812225],
                [108.6086657, 12.2812964],
                [108.608842, 12.2813709],
                [108.6088979, 12.2814015],
                [108.6090271, 12.2814997],
                [108.6091313, 12.281623],
                [108.6092057, 12.2817655],
                [108.6092305, 12.2818418],
                [108.609239, 12.2819006],
                [108.6092301, 12.2820188],
                [108.6091875, 12.2821298],
                [108.6091146, 12.2822246],
                [108.6090686, 12.2822635],
                [108.6089621, 12.2823202],
                [108.6088435, 12.2823453],
                [108.6087225, 12.2823368],
                [108.6086088, 12.2822954],
                [108.608441, 12.2821733],
                [108.608366, 12.2821286],
                [108.6082051, 12.2820597],
                [108.6080342, 12.28202],
                [108.6076073, 12.281952],
                [108.6074012, 12.2818942],
                [108.6073373, 12.2818874],
                [108.6072731, 12.2818898],
                [108.6072099, 12.2819015],
                [108.6071492, 12.2819222],
                [108.6070923, 12.2819514],
                [108.6070403, 12.2819885],
                [108.6069946, 12.2820326],
                [108.606956, 12.2820829],
                [108.606934, 12.282127],
                [108.6069137, 12.2822228],
                [108.6069161, 12.2822719],
                [108.6069453, 12.2823655],
                [108.6069714, 12.2824074],
                [108.6072034, 12.2826935],
                [108.6073798, 12.2829582],
                [108.6075049, 12.2830995],
                [108.6076527, 12.2832179],
                [108.6077338, 12.2832675],
                [108.6079044, 12.2833427],
                [108.6079941, 12.2833688],
                [108.6081789, 12.2833972],
                [108.6083659, 12.2833931],
                [108.6085492, 12.2833566],
                [108.6086208, 12.2833283],
                [108.6086881, 12.2832915],
                [108.6087503, 12.2832468],
                [108.6089245, 12.283066],
                [108.6090004, 12.283002],
                [108.609082, 12.2829452],
                [108.6091686, 12.282896],
                [108.6092595, 12.2828548],
                [108.6094737, 12.2827864],
                [108.6097796, 12.2827223],
                [108.6098421, 12.2827171],
                [108.609986, 12.28273],
                [108.6101224, 12.2827766],
                [108.6102431, 12.2828543],
                [108.6103993, 12.2830155],
                [108.6104636, 12.2830663],
                [108.6105331, 12.28311],
                [108.6106846, 12.2831743],
                [108.6109212, 12.2832267],
                [108.6110791, 12.2832515],
                [108.611567, 12.2832935],
                [108.6117357, 12.2833147],
                [108.611907, 12.283304],
                [108.6120731, 12.2832615],
                [108.6121522, 12.2832287],
                [108.6123899, 12.2830901],
                [108.6124845, 12.2830452],
                [108.6126827, 12.2829765],
                [108.612889, 12.2829373],
                [108.6131083, 12.2829303],
                [108.6133257, 12.2829589],
                [108.6135353, 12.2830223],
                [108.6136353, 12.2830665],
                [108.6139333, 12.2832404],
                [108.6140486, 12.2832941],
                [108.6141678, 12.2833391],
                [108.6142901, 12.2833751],
                [108.6146625, 12.2834451],
                [108.6147816, 12.283479],
                [108.6150109, 12.2835709],
                [108.6151201, 12.2836285],
                [108.6153245, 12.2837654],
                [108.6154973, 12.2838549],
                [108.6156839, 12.2839121],
                [108.6158663, 12.2839338],
                [108.616038, 12.2839227],
                [108.6161406, 12.2838831],
                [108.616227, 12.2838159],
                [108.6162893, 12.2837269],
                [108.6163223, 12.2836239],
                [108.6163405, 12.2834723],
                [108.616333, 12.2833199],
                [108.6162955, 12.283105],
                [108.6164274, 12.2830989],
                [108.6165595, 12.2831022],
                [108.6166909, 12.2831147],
                [108.6168212, 12.2831364],
                [108.6170678, 12.2832045],
                [108.6173023, 12.2833055],
                [108.6174136, 12.2833677],
                [108.6176215, 12.2835142],
                [108.6178704, 12.2837416],
                [108.6180171, 12.2838919],
                [108.618157, 12.2840484],
                [108.6182896, 12.2842107],
                [108.6184149, 12.2843786],
                [108.6186895, 12.2847968],
                [108.6189805, 12.2853004],
                [108.6192396, 12.2858204],
                [108.6194658, 12.2863548],
                [108.6196267, 12.2867951],
                [108.6196976, 12.2869618],
                [108.6197775, 12.2871247],
                [108.6198661, 12.2872831],
                [108.6199632, 12.2874367],
                [108.6200685, 12.2875851],
                [108.6204288, 12.2880198],
                [108.6205477, 12.2881806],
                [108.6206591, 12.2883464],
                [108.6208587, 12.2886918],
                [108.621177, 12.2893531],
                [108.6214004, 12.2898386],
                [108.6218256, 12.290819],
                [108.6231995, 12.2903383],
                [108.6265468, 12.2899924],
                [108.6268275, 12.2896598],
                [108.6271262, 12.2893425],
                [108.6273256, 12.2891486],
                [108.6275317, 12.2889615],
                [108.6277443, 12.2887814],
                [108.6279631, 12.2886086],
                [108.6280472, 12.288757],
                [108.6282296, 12.2890454],
                [108.6284486, 12.2893451],
                [108.6285755, 12.2895004],
                [108.6288465, 12.2897967],
                [108.6289903, 12.2899372],
                [108.6292934, 12.2902021],
                [108.6295726, 12.2904054],
                [108.6297188, 12.2904979],
                [108.630023, 12.2906633],
                [108.6302429, 12.2907675],
                [108.6304668, 12.2908631],
                [108.6306945, 12.2909498],
                [108.6309256, 12.2910277],
                [108.6316425, 12.2912275],
                [108.6318858, 12.2913079],
                [108.6321258, 12.2913971],
                [108.6322984, 12.2914499],
                [108.6326505, 12.2915299],
                [108.633009, 12.2915754],
                [108.6331896, 12.291585],
                [108.6336675, 12.2915807],
                [108.6342606, 12.2915467],
                [108.634851, 12.291481],
                [108.635286, 12.2914212],
                [108.6355743, 12.2913701],
                [108.6358852, 12.291347],
                [108.6360507, 12.2913479],
                [108.6362158, 12.291358],
                [108.6363801, 12.2913773],
                [108.636543, 12.2914056],
                [108.636704, 12.2914429],
                [108.6368626, 12.291489],
                [108.6370182, 12.2915439],
                [108.6370746, 12.2917891],
                [108.637159, 12.2920265],
                [108.6372791, 12.2922689],
                [108.6373552, 12.2923918],
                [108.6374392, 12.2925097],
                [108.6375309, 12.2926221],
                [108.6378878, 12.2930115],
                [108.6382678, 12.293442],
                [108.6386275, 12.2938815],
                [108.6387534, 12.2939788],
                [108.6388239, 12.2940166],
                [108.6389755, 12.2940681],
                [108.639135, 12.2940856],
                [108.6392945, 12.2940682],
                [108.6394461, 12.2940168],
                [108.6396926, 12.2938669],
                [108.6399257, 12.2937558],
                [108.6401279, 12.2936988],
                [108.640232, 12.2936832],
                [108.6404424, 12.2936785],
                [108.6405471, 12.2936894],
                [108.6407517, 12.2937373],
                [108.6409449, 12.2938187],
                [108.6413312, 12.2940178],
                [108.6414314, 12.2940586],
                [108.6416393, 12.2941193],
                [108.6417459, 12.2941389],
                [108.6419622, 12.294156],
                [108.6423134, 12.2941389],
                [108.642448, 12.2941426],
                [108.6427152, 12.2941751],
                [108.6428161, 12.2941809],
                [108.6430175, 12.2941679],
                [108.643214, 12.2941226],
                [108.6433087, 12.2940881],
                [108.6436142, 12.2939344],
                [108.643878, 12.2938336],
                [108.6441529, 12.2937662],
                [108.6443757, 12.2937264],
                [108.6446001, 12.2936955],
                [108.6448255, 12.2936735],
                [108.6450517, 12.2936605],
                [108.6457227, 12.2936566],
                [108.6459403, 12.2936423],
                [108.646157, 12.2936186],
                [108.6463724, 12.2935855],
                [108.6465861, 12.2935431],
                [108.6467977, 12.2934915],
                [108.6470067, 12.2934308],
                [108.6471528, 12.2933993],
                [108.6474492, 12.2933615],
                [108.6475986, 12.2933554],
                [108.6478972, 12.2933686],
                [108.6480454, 12.2933879],
                [108.6483371, 12.2934518],
                [108.6484218, 12.2934609],
                [108.6485919, 12.2934539],
                [108.6486755, 12.2934378],
                [108.648757, 12.2934135],
                [108.6488738, 12.2933627],
                [108.6489622, 12.2933054],
                [108.6490099, 12.2932643],
                [108.6494298, 12.2927605],
                [108.6496673, 12.2931728],
                [108.6497962, 12.2933731],
                [108.6500442, 12.2937319],
                [108.6501722, 12.2938654],
                [108.6502481, 12.293927],
                [108.6504148, 12.2940298],
                [108.6505847, 12.2941143],
                [108.6506613, 12.2941643],
                [108.6508019, 12.2942805],
                [108.6509228, 12.2944162],
                [108.6511412, 12.2946999],
                [108.6515343, 12.2952705],
                [108.6517208, 12.2952826],
                [108.6520922, 12.2953229],
                [108.6523346, 12.2953612],
                [108.6525752, 12.2954087],
                [108.6528138, 12.2954654],
                [108.6530498, 12.2955312],
                [108.6532831, 12.295606],
                [108.6536948, 12.295744],
                [108.6539651, 12.2958474],
                [108.6542667, 12.2959285],
                [108.6544317, 12.2959587],
                [108.6545982, 12.2959795],
                [108.6547656, 12.2959908],
                [108.6549334, 12.2959926],
                [108.6551011, 12.2959849],
                [108.6552679, 12.2959676],
                [108.6556163, 12.2961876],
                [108.6557829, 12.2963083],
                [108.6560887, 12.2965599],
                [108.6561796, 12.2966231],
                [108.6563744, 12.2967292],
                [108.6564773, 12.2967715],
                [108.656691, 12.2968336],
                [108.6568008, 12.2968531],
                [108.6570233, 12.2968682],
                [108.6572804, 12.2968507],
                [108.657425, 12.296829],
                [108.6577088, 12.2967605],
                [108.6579823, 12.2966595],
                [108.6582355, 12.2965862],
                [108.6584963, 12.2965465],
                [108.6586282, 12.2965394],
                [108.6587603, 12.2965409],
                [108.6590226, 12.2965695],
                [108.6593858, 12.2966519],
                [108.6598474, 12.2967856],
                [108.6603582, 12.2969698],
                [108.6606387, 12.2970836],
                [108.6609158, 12.2972053],
                [108.6617795, 12.2976262],
                [108.6621044, 12.2977726],
                [108.6624329, 12.297911],
                [108.6631, 12.2981637],
                [108.6631795, 12.2983278],
                [108.6633579, 12.2986462],
                [108.6634566, 12.2988],
                [108.663664, 12.2990854],
                [108.6639197, 12.2993893],
                [108.6640012, 12.2994688],
                [108.6641308, 12.2995545],
                [108.6642764, 12.2996108],
                [108.664353, 12.299627],
                [108.6645093, 12.2996346],
                [108.6646636, 12.299609],
                [108.6647377, 12.299584],
                [108.6648753, 12.2995113],
                [108.665068, 12.2993525],
                [108.6651478, 12.2993012],
                [108.6652324, 12.2992577],
                [108.6653209, 12.2992225],
                [108.6655045, 12.2991752],
                [108.6656937, 12.2991593],
                [108.6658829, 12.2991752],
                [108.6659757, 12.299195],
                [108.6661594, 12.2992582],
                [108.6662492, 12.2993007],
                [108.6664176, 12.299405],
                [108.6665679, 12.2995331],
                [108.6667269, 12.2996916],
                [108.6669284, 12.2998441],
                [108.6673264, 12.3000643],
                [108.6676647, 12.3002821],
                [108.6679552, 12.3005055],
                [108.6680807, 12.3006135],
                [108.6683196, 12.300842],
                [108.6683464, 12.3012404],
                [108.6682705, 12.3015743],
                [108.6682445, 12.3017435],
                [108.668217, 12.3020682],
                [108.6682188, 12.302394],
                [108.6682498, 12.3027184],
                [108.66828, 12.3028946],
                [108.6683196, 12.3030689],
                [108.6684902, 12.3036191],
                [108.6685451, 12.3038303],
                [108.6685913, 12.3040434],
                [108.6686575, 12.3044742],
                [108.6687323, 12.3048532],
                [108.6687444, 12.3049497],
                [108.6687809, 12.3050403],
                [108.6688528, 12.3051322],
                [108.6688985, 12.3051692],
                [108.6690043, 12.3052212],
                [108.669062, 12.3052351],
                [108.6691804, 12.3052367],
                [108.6692939, 12.305204],
                [108.6695604, 12.3050353],
                [108.6697424, 12.3049561],
                [108.6698373, 12.3049266],
                [108.6702301, 12.3048383],
                [108.6708248, 12.3047205],
                [108.6711866, 12.3049487],
                [108.6715936, 12.3051763],
                [108.671819, 12.30529],
                [108.6722796, 12.3054972],
                [108.6725146, 12.3055906],
                [108.6727593, 12.305678],
                [108.6730071, 12.3057566],
                [108.6732577, 12.3058264],
                [108.6735107, 12.3058873],
                [108.6737658, 12.3059391],
                [108.67373, 12.3060749],
                [108.6736786, 12.3063505],
                [108.6736632, 12.30649],
                [108.6736529, 12.3067702],
                [108.6736703, 12.3070501],
                [108.6737537, 12.3075612],
                [108.6737685, 12.3076891],
                [108.6737762, 12.3078176],
                [108.6737698, 12.3080749],
                [108.6737886, 12.3082681],
                [108.673811, 12.3083627],
                [108.6738807, 12.3085444],
                [108.6739276, 12.30863],
                [108.6740946, 12.3088647],
                [108.6741753, 12.3090305],
                [108.6742249, 12.3092076],
                [108.6742558, 12.3094753],
                [108.6742789, 12.3095583],
                [108.6743111, 12.3096383],
                [108.6743519, 12.3097145],
                [108.6744009, 12.309786],
                [108.6744574, 12.3098519],
                [108.6745209, 12.3099114],
                [108.6745906, 12.309964],
                [108.6747789, 12.3100837],
                [108.6748877, 12.3101646],
                [108.6750906, 12.3103436],
                [108.6751841, 12.310441],
                [108.6754582, 12.3107694],
                [108.6755615, 12.3108736],
                [108.6757654, 12.3110466],
                [108.675828, 12.3111089],
                [108.6759357, 12.311248],
                [108.6760546, 12.311463],
                [108.6761457, 12.3115712],
                [108.6762564, 12.3116603],
                [108.6763966, 12.3117522],
                [108.6764693, 12.3118151],
                [108.6765354, 12.3118847],
                [108.6765943, 12.3119602],
                [108.6766453, 12.312041],
                [108.6770303, 12.3128016],
                [108.6771226, 12.31297],
                [108.6773098, 12.3132661],
                [108.677466, 12.3134766],
                [108.679838, 12.316123],
                [108.6798566, 12.316312],
                [108.6798554, 12.316407],
                [108.6798322, 12.3165955],
                [108.6797777, 12.3167895],
                [108.6796938, 12.3169731],
                [108.6795956, 12.3172204],
                [108.6795356, 12.3175209],
                [108.6795174, 12.3176729],
                [108.6795049, 12.3179787],
                [108.6795106, 12.3181317],
                [108.6795255, 12.3182842],
                [108.6795496, 12.3184355],
                [108.6795827, 12.3185851],
                [108.6796249, 12.3187326],
                [108.6796759, 12.3188774],
                [108.6797355, 12.319019],
                [108.6798036, 12.3191569],
                [108.6799067, 12.3193168],
                [108.6801472, 12.3195281],
                [108.6802891, 12.3196374],
                [108.680588, 12.3198357],
                [108.6808668, 12.3199873],
                [108.6810108, 12.3200545],
                [108.6813068, 12.3201711],
                [108.6813986, 12.3202145],
                [108.6815719, 12.3203191],
                [108.6817569, 12.3204603],
                [108.6818433, 12.320538],
                [108.6820211, 12.3207286],
                [108.6821383, 12.3208419],
                [108.6822574, 12.3209303],
                [108.6825794, 12.3211138],
                [108.682924, 12.3212833],
                [108.6832909, 12.321436],
                [108.6836672, 12.3215652],
                [108.6836634, 12.3216498],
                [108.6836796, 12.3218182],
                [108.683728, 12.3219841],
                [108.6838089, 12.3221412],
                [108.6838603, 12.3222134],
                [108.6839831, 12.322342],
                [108.6841285, 12.3224456],
                [108.6842846, 12.3225379],
                [108.6844453, 12.3226223],
                [108.6846103, 12.3226983],
                [108.6847791, 12.322766],
                [108.6849513, 12.3228251],
                [108.6851263, 12.3228754],
                [108.6838738, 12.3237291],
                [108.6837208, 12.3238083],
                [108.683637, 12.3238418],
                [108.6834633, 12.3238921],
                [108.6833972, 12.3239186],
                [108.6833348, 12.3239524],
                [108.6832243, 12.3240405],
                [108.683138, 12.3241514],
                [108.6830807, 12.324279],
                [108.6830341, 12.3245385],
                [108.6830042, 12.3246592],
                [108.6829197, 12.3248932],
                [108.6828039, 12.325114],
                [108.6826587, 12.3253176],
                [108.6827041, 12.3253997],
                [108.682774, 12.3255732],
                [108.682815, 12.3257573],
                [108.682824, 12.3258522],
                [108.682825, 12.3259475],
                [108.6828032, 12.3261367],
                [108.6827805, 12.3262293],
                [108.6826858, 12.3264925],
                [108.6826678, 12.3265794],
                [108.6826584, 12.3267563],
                [108.6826844, 12.3269315],
                [108.6827105, 12.3270165],
                [108.6827873, 12.3271769],
                [108.6829741, 12.3274262],
                [108.6830463, 12.3275377],
                [108.6831678, 12.3277731],
                [108.6832166, 12.3278961],
                [108.6832441, 12.3279932],
                [108.683263, 12.3280923],
                [108.6832743, 12.3282934],
                [108.6832501, 12.3284934],
                [108.6831911, 12.3286863],
                [108.6831491, 12.3287784],
                [108.6830416, 12.3289502],
                [108.6829054, 12.3291014],
                [108.6828484, 12.3291736],
                [108.6827991, 12.3292511],
                [108.6827582, 12.3293331],
                [108.6827259, 12.3294187],
                [108.6827027, 12.329507],
                [108.6826889, 12.3295972],
                [108.6826844, 12.3296883],
                [108.6826895, 12.3297794],
                [108.6827039, 12.3298695],
                [108.6827277, 12.3299577],
                [108.6827605, 12.3300431],
                [108.6829107, 12.3303089],
                [108.682961, 12.33042],
                [108.6830022, 12.3305345],
                [108.6830341, 12.3306519],
                [108.6830567, 12.3307713],
                [108.6830696, 12.3308921],
                [108.6830728, 12.3310135],
                [108.6830449, 12.3314442],
                [108.6829805, 12.3320611],
                [108.6828875, 12.3326745],
                [108.6827854, 12.3331735],
                [108.6827496, 12.3333693],
                [108.682723, 12.3335665],
                [108.6826983, 12.3339394],
                [108.6826983, 12.3341263],
                [108.682723, 12.3344993],
                [108.6825581, 12.3344611],
                [108.6824735, 12.3344547],
                [108.6823887, 12.334457],
                [108.6822238, 12.3344867],
                [108.6821454, 12.3345135],
                [108.6819989, 12.3345897],
                [108.6818712, 12.3346932],
                [108.6817676, 12.3348199],
                [108.6817263, 12.3348903],
                [108.6816666, 12.3350416],
                [108.6815826, 12.3355173],
                [108.6814948, 12.3358263],
                [108.6813768, 12.3361255],
                [108.6812237, 12.3364623],
                [108.6811494, 12.3366575],
                [108.6810841, 12.3368558],
                [108.6810279, 12.3370567],
                [108.6809853, 12.3371606],
                [108.6808756, 12.337357],
                [108.6807355, 12.337534],
                [108.6805685, 12.3376871],
                [108.6804762, 12.3377535],
                [108.6802769, 12.3378637],
                [108.680249, 12.3382091],
                [108.6802311, 12.338555],
                [108.6802232, 12.3389013],
                [108.6802341, 12.3395204],
                [108.6802769, 12.3401381],
                [108.6791932, 12.3409032],
                [108.6791289, 12.3413539],
                [108.6789104, 12.3413843],
                [108.6788038, 12.3414121],
                [108.6786148, 12.3414847],
                [108.6784146, 12.3415987],
                [108.678328, 12.3416725],
                [108.6782843, 12.3417241],
                [108.6782174, 12.3418408],
                [108.6781809, 12.3419698],
                [108.6781771, 12.3421035],
                [108.678218, 12.3423055],
                [108.6782211, 12.3423776],
                [108.6782156, 12.3424496],
                [108.6781788, 12.3425891],
                [108.6781481, 12.3426548],
                [108.6777985, 12.3432404],
                [108.6776123, 12.3435012],
                [108.6774015, 12.3437435],
                [108.677309, 12.3438839],
                [108.6772544, 12.3439974],
                [108.6772268, 12.3440975],
                [108.6772239, 12.3442213],
                [108.6772348, 12.3442824],
                [108.6772804, 12.3443979],
                [108.6773549, 12.344498],
                [108.6774938, 12.3446312],
                [108.6776608, 12.3448289],
                [108.6777349, 12.3449347],
                [108.6778629, 12.3451584],
                [108.6777077, 12.3454609],
                [108.6775839, 12.3457768],
                [108.6775335, 12.3459415],
                [108.6774583, 12.3462773],
                [108.6774015, 12.3467515],
                [108.6773522, 12.3469755],
                [108.6773152, 12.3470843],
                [108.6772177, 12.3472927],
                [108.6770395, 12.3475732],
                [108.676997, 12.3476653],
                [108.6769631, 12.3477609],
                [108.6769381, 12.347859],
                [108.6769223, 12.3479589],
                [108.6769157, 12.3480597],
                [108.6769184, 12.3481608],
                [108.6769304, 12.3482612],
                [108.6769516, 12.3483602],
                [108.6769818, 12.3484568],
                [108.6770208, 12.3485505],
                [108.6770683, 12.3486403],
                [108.6771238, 12.3487256],
                [108.6772525, 12.3489025],
                [108.6773599, 12.3491093],
                [108.6775561, 12.349685],
                [108.677627, 12.3498592],
                [108.6778606, 12.3503627],
                [108.6779874, 12.3506962],
                [108.6780882, 12.3510381],
                [108.6781638, 12.3513418],
                [108.6782301, 12.3516476],
                [108.6782872, 12.3519552],
                [108.6783145, 12.352162],
                [108.6783714, 12.3524064],
                [108.6784704, 12.3526829],
                [108.6786032, 12.3529455],
                [108.6786601, 12.3531151],
                [108.6786835, 12.3532922],
                [108.6786724, 12.3534704],
                [108.6786272, 12.3536433],
                [108.6785166, 12.3538845],
                [108.678476, 12.3540512],
                [108.6784702, 12.3542224],
                [108.6784805, 12.3543076],
                [108.6785268, 12.3544729],
                [108.6786059, 12.3546258],
                [108.6787289, 12.3548016],
                [108.6788607, 12.3550206],
                [108.6789858, 12.3551861],
                [108.6790576, 12.3552613],
                [108.6792179, 12.3553947],
                [108.6795051, 12.355571],
                [108.6797061, 12.3557278],
                [108.6798843, 12.3559089],
                [108.6799638, 12.3560077],
                [108.6801021, 12.3562195],
                [108.6801602, 12.3563317],
                [108.6802532, 12.3565659],
                [108.6803219, 12.3568398],
                [108.6803466, 12.3569943],
                [108.6803617, 12.3571499],
                [108.6803671, 12.3573062],
                [108.6803627, 12.3574625],
                [108.680706, 12.3577141],
                [108.6807382, 12.3584162],
                [108.6809048, 12.3586234],
                [108.6810493, 12.3588459],
                [108.6811126, 12.3589619],
                [108.681221, 12.3592023],
                [108.6812745, 12.3593033],
                [108.6813363, 12.3593996],
                [108.681406, 12.3594908],
                [108.681483, 12.359576],
                [108.6815669, 12.3596549],
                [108.6816572, 12.3597268],
                [108.6817531, 12.3597912],
                [108.6818541, 12.3598478],
                [108.6821573, 12.3599784],
                [108.6822417, 12.3600287],
                [108.6823948, 12.3601506],
                [108.6824623, 12.3602212],
                [108.682576, 12.360379],
                [108.6826214, 12.3604647],
                [108.6827722, 12.3608232],
                [108.6828411, 12.3609525],
                [108.6829414, 12.3610766],
                [108.6830004, 12.3611308],
                [108.6831333, 12.3612212],
                [108.6832058, 12.3612564],
                [108.6833282, 12.3612877],
                [108.6834546, 12.3612885],
                [108.6836703, 12.3612568],
                [108.6838246, 12.3612459],
                [108.6839793, 12.3612446],
                [108.6841338, 12.3612529],
                [108.6845679, 12.3613129],
                [108.6848259, 12.3613193],
                [108.6849075, 12.3613305],
                [108.6849872, 12.3613507],
                [108.6850641, 12.3613795],
                [108.6851372, 12.3614166],
                [108.6852056, 12.3614615],
                [108.6852683, 12.3615137],
                [108.6853246, 12.3615725],
                [108.6853737, 12.3616371],
                [108.6854855, 12.3618395],
                [108.6855303, 12.3618933],
                [108.6855819, 12.3619411],
                [108.6856393, 12.3619819],
                [108.6857017, 12.3620152],
                [108.685768, 12.3620403],
                [108.685837, 12.3620568],
                [108.6859076, 12.3620644],
                [108.6861149, 12.3620502],
                [108.6861807, 12.3620542],
                [108.6862458, 12.3620648],
                [108.6863708, 12.3621053],
                [108.6863908, 12.3624165],
                [108.6864459, 12.3627236],
                [108.6864874, 12.3628773],
                [108.6865377, 12.3630284],
                [108.6865968, 12.3631765],
                [108.6866643, 12.3633211],
                [108.6867401, 12.3634618],
                [108.6868239, 12.363598],
                [108.6869155, 12.3637294],
                [108.6871218, 12.3639843],
                [108.6873472, 12.3642327],
                [108.6873976, 12.3643032],
                [108.6874783, 12.3644557],
                [108.6875081, 12.3645366],
                [108.6875219, 12.3646022],
                [108.6875272, 12.3646688],
                [108.6875123, 12.3648016],
                [108.6874922, 12.3648655],
                [108.6874287, 12.3649837],
                [108.6873373, 12.365083],
                [108.6871988, 12.3651923],
                [108.6871189, 12.3652658],
                [108.6869369, 12.365466],
                [108.6869073, 12.3655113],
                [108.6868848, 12.3655599],
                [108.6868626, 12.3656641],
                [108.6868633, 12.3657175],
                [108.6868881, 12.3658211],
                [108.6869118, 12.3658692],
                [108.6869791, 12.3659529],
                [108.6871313, 12.3660604],
                [108.6871883, 12.3661134],
                [108.6872384, 12.3661727],
                [108.687281, 12.3662374],
                [108.6873154, 12.3663066],
                [108.6873824, 12.3665263],
                [108.6874147, 12.3665951],
                [108.6875008, 12.3667208],
                [108.6876123, 12.3668257],
                [108.6878132, 12.3669493],
                [108.6878782, 12.3669991],
                [108.6879387, 12.3670539],
                [108.6880445, 12.3671776],
                [108.6878373, 12.367357],
                [108.6877412, 12.3674545],
                [108.6875657, 12.3676638],
                [108.6874086, 12.3678987],
                [108.6872747, 12.3681583],
                [108.6871719, 12.3684311],
                [108.6870919, 12.3687423],
                [108.6870586, 12.3689148],
                [108.6870206, 12.3691755],
                [108.6870146, 12.3692631],
                [108.6870155, 12.36937],
                [108.6870444, 12.3695818],
                [108.6871089, 12.369786],
                [108.687154, 12.3698835],
                [108.6872681, 12.3700658],
                [108.6873364, 12.3701494],
                [108.687502, 12.3703283],
                [108.6877733, 12.3706695],
                [108.6878834, 12.3707927],
                [108.6879991, 12.3709108],
                [108.6882462, 12.3711314],
                [108.6884956, 12.371318],
                [108.6887419, 12.3714743],
                [108.6889081, 12.3716064],
                [108.6890522, 12.3717613],
                [108.6891444, 12.37189],
                [108.6892094, 12.3720148],
                [108.6892395, 12.3720975],
                [108.6892738, 12.3722696],
                [108.6892724, 12.372445],
                [108.6892185, 12.3727036],
                [108.6892079, 12.3727915],
                [108.6892036, 12.37288],
                [108.689214, 12.3730567],
                [108.6892108, 12.3731246],
                [108.6891795, 12.3732567],
                [108.6891169, 12.3733778],
                [108.6890265, 12.3734808],
                [108.6888353, 12.3736161],
                [108.6886953, 12.3737476],
                [108.6886346, 12.3738217],
                [108.6885918, 12.3738873],
                [108.6885266, 12.3740289],
                [108.6884903, 12.3741801],
                [108.6884792, 12.3744611],
                [108.6884658, 12.3745863],
                [108.6884442, 12.3747105],
                [108.6883358, 12.37511],
                [108.6883022, 12.3752683],
                [108.6882584, 12.3755886],
                [108.6882318, 12.3759052],
                [108.6882084, 12.3760596],
                [108.6881416, 12.3763648],
                [108.6880405, 12.3766806],
                [108.6879734, 12.3768429],
                [108.687897, 12.3770012],
                [108.6878118, 12.3771551],
                [108.6876692, 12.3773777],
                [108.6875421, 12.3775285],
                [108.6874617, 12.3776027],
                [108.6873749, 12.3776696],
                [108.6872823, 12.3777287],
                [108.6871847, 12.3777796],
                [108.6870829, 12.3778218],
                [108.6869777, 12.3778549],
                [108.6868699, 12.3778789],
                [108.6867603, 12.3778934],
                [108.6864785, 12.3779136],
                [108.6863084, 12.3779382],
                [108.6861399, 12.3779721],
                [108.6859737, 12.3780152],
                [108.6858101, 12.3780672],
                [108.6856498, 12.3781281],
                [108.6851936, 12.3783422],
                [108.6850433, 12.378402],
                [108.6847348, 12.3785006],
                [108.6844182, 12.3785704],
                [108.6837915, 12.3786467],
                [108.6831844, 12.3787366],
                [108.6823046, 12.3805181],
                [108.6824226, 12.3809582],
                [108.682565, 12.3810217],
                [108.6827112, 12.3810762],
                [108.6828606, 12.3811215],
                [108.6831454, 12.3811806],
                [108.6834137, 12.3812056],
                [108.6836832, 12.3812016],
                [108.6838174, 12.3811888],
                [108.6838996, 12.3811926],
                [108.683981, 12.3812053],
                [108.6841369, 12.3812562],
                [108.6842777, 12.3813392],
                [108.6843966, 12.3814502],
                [108.6844878, 12.3815838],
                [108.6845847, 12.3818198],
                [108.6846309, 12.3819019],
                [108.6846851, 12.3819791],
                [108.6847469, 12.3820508],
                [108.6848156, 12.3821161],
                [108.6848905, 12.3821746],
                [108.684971, 12.3822255],
                [108.6850562, 12.3822685],
                [108.6851453, 12.3823031],
                [108.6852375, 12.3823289],
                [108.6853318, 12.3823458],
                [108.6854274, 12.3823535],
                [108.6855233, 12.382352],
                [108.6855061, 12.3826446],
                [108.6854846, 12.3827897],
                [108.685416, 12.3830751],
                [108.6853268, 12.3833212],
                [108.6851616, 12.3836734],
                [108.6850848, 12.3839146],
                [108.6850414, 12.3841636],
                [108.6850325, 12.3842897],
                [108.6850405, 12.3845421],
                [108.6850757, 12.3847546],
                [108.685137, 12.3849613],
                [108.685182, 12.38516],
                [108.6851949, 12.385261],
                [108.6852014, 12.3854643],
                [108.6852116, 12.3855339],
                [108.6852561, 12.3856673],
                [108.6853308, 12.3857871],
                [108.6854316, 12.385887],
                [108.6855532, 12.3859617],
                [108.6856895, 12.3860249],
                [108.6857547, 12.3860689],
                [108.685868, 12.3861766],
                [108.6859148, 12.3862389],
                [108.6859858, 12.3863769],
                [108.686024, 12.3865268],
                [108.6860274, 12.3866812],
                [108.6859958, 12.3868325],
                [108.6858877, 12.3870725],
                [108.6858537, 12.3871749],
                [108.685829, 12.38728],
                [108.6858139, 12.3873867],
                [108.6858096, 12.3877349],
                [108.6857967, 12.3878673],
                [108.6857742, 12.3879984],
                [108.6857423, 12.3881276],
                [108.6857011, 12.3882543],
                [108.6856508, 12.3883778],
                [108.6855917, 12.3884976],
                [108.6853571, 12.3888686],
                [108.6851964, 12.389171],
                [108.6850654, 12.3894868],
                [108.6849654, 12.3898131],
                [108.684883, 12.3900003],
                [108.6847727, 12.3901733],
                [108.6847079, 12.3902532],
                [108.6846432, 12.3903164],
                [108.684572, 12.3903723],
                [108.684495, 12.3904205],
                [108.6844131, 12.3904602],
                [108.6843273, 12.3904911],
                [108.6842386, 12.3905129],
                [108.6841481, 12.3905251],
                [108.6840567, 12.3905278],
                [108.6839656, 12.3905208],
                [108.6838757, 12.3905043],
                [108.6837882, 12.3904784],
                [108.6837041, 12.3904435],
                [108.6836243, 12.3903999],
                [108.6834717, 12.3903451],
                [108.683311, 12.3903212],
                [108.6832297, 12.3903211],
                [108.683069, 12.3903449],
                [108.6827155, 12.3904464],
                [108.6824375, 12.3905161],
                [108.6818755, 12.3906305],
                [108.6813372, 12.39071],
                [108.6810666, 12.3907393],
                [108.6805236, 12.3907772],
                [108.6803381, 12.3909482],
                [108.6801374, 12.391102],
                [108.6798819, 12.3912605],
                [108.6796105, 12.3913911],
                [108.6793261, 12.3914924],
                [108.678769, 12.3916265],
                [108.6785081, 12.391699],
                [108.67825, 12.3917802],
                [108.677995, 12.3918703],
                [108.6777433, 12.391969],
                [108.6774953, 12.3920762],
                [108.6761999, 12.3926739],
                [108.6760604, 12.3930511],
                [108.6758673, 12.3932607],
                [108.6759622, 12.393507],
                [108.6760819, 12.3937427],
                [108.6762168, 12.3939538],
                [108.6763716, 12.3941514],
                [108.6764458, 12.3942922],
                [108.6764703, 12.3943678],
                [108.6764924, 12.3945247],
                [108.6764898, 12.394604],
                [108.6764574, 12.3947591],
                [108.6763943, 12.3948893],
                [108.6763514, 12.394948],
                [108.6762458, 12.3950484],
                [108.6761189, 12.3951217],
                [108.6759335, 12.3951969],
                [108.6758214, 12.3952554],
                [108.6757142, 12.395322],
                [108.6756124, 12.3953962],
                [108.6755167, 12.3954777],
                [108.6754274, 12.395566],
                [108.6753614, 12.3956444],
                [108.6753021, 12.3957277],
                [108.6752051, 12.3959071],
                [108.675139, 12.3960993],
                [108.6751181, 12.3961988],
                [108.675078, 12.3964762],
                [108.6750021, 12.3968258],
                [108.6749539, 12.3969982],
                [108.6747772, 12.3975275],
                [108.6747261, 12.3977207],
                [108.6746844, 12.3979159],
                [108.6746521, 12.3981129],
                [108.6746292, 12.3983111],
                [108.6746159, 12.3985102],
                [108.674612, 12.3987096],
                [108.6745992, 12.3988506],
                [108.6745484, 12.3991291],
                [108.6744648, 12.3993999],
                [108.6744111, 12.3995313],
                [108.6742806, 12.3997839],
                [108.6742043, 12.3999042],
                [108.6740867, 12.400036],
                [108.6738468, 12.4002252],
                [108.6737523, 12.4003115],
                [108.673664, 12.4004037],
                [108.6735392, 12.4005591],
                [108.6732565, 12.4008579],
                [108.6730846, 12.4010278],
                [108.6727238, 12.4013502],
                [108.6731315, 12.4023352],
                [108.6732588, 12.4023975],
                [108.673507, 12.4025343],
                [108.6737897, 12.4027176],
                [108.6741113, 12.4029596],
                [108.6742938, 12.4031064],
                [108.6744099, 12.4032105],
                [108.6745596, 12.4033135],
                [108.6747459, 12.403405],
                [108.6750504, 12.403504],
                [108.6751528, 12.4035492],
                [108.675345, 12.4036624],
                [108.6755167, 12.4038038],
                [108.6756635, 12.4039698],
                [108.675732, 12.4040679],
                [108.6757928, 12.4041707],
                [108.6758453, 12.4042778],
                [108.6758893, 12.4043885],
                [108.6759245, 12.4045021],
                [108.6759508, 12.404618],
                [108.6759679, 12.4047355],
                [108.6759758, 12.404854],
                [108.6759653, 12.4051888],
                [108.6759758, 12.4052861],
                [108.676024, 12.4054758],
                [108.6761068, 12.4056538],
                [108.6761603, 12.4057365],
                [108.6762967, 12.4059187],
                [108.6764312, 12.4061382],
                [108.6765432, 12.4063694],
                [108.6766612, 12.4066523],
                [108.6767452, 12.4067682],
                [108.6768541, 12.4068624],
                [108.6769161, 12.4068996],
                [108.6770513, 12.4069521],
                [108.6772896, 12.4069951],
                [108.6776268, 12.4070295],
                [108.6777112, 12.4070464],
                [108.6777939, 12.40707],
                [108.677952, 12.4071365],
                [108.678097, 12.4072273],
                [108.6782262, 12.4073419],
                [108.6782832, 12.4074081],
                [108.678378, 12.4075541],
                [108.6784445, 12.4077143],
                [108.6784806, 12.4078835],
                [108.6784869, 12.4079699],
                [108.6784874, 12.4082694],
                [108.6784989, 12.4084821],
                [108.6785196, 12.4086941],
                [108.6785934, 12.4091751],
                [108.6786766, 12.4096236],
                [108.6787394, 12.409834],
                [108.6787874, 12.4099532],
                [108.6788434, 12.4100689],
                [108.6789787, 12.4102882],
                [108.6790177, 12.410368],
                [108.679048, 12.4104514],
                [108.679069, 12.4105374],
                [108.6790806, 12.4106251],
                [108.6790827, 12.4107135],
                [108.6790752, 12.4108016],
                [108.6790596, 12.410859],
                [108.6790045, 12.4109646],
                [108.6789214, 12.4110509],
                [108.6787924, 12.4111461],
                [108.6787192, 12.4112141],
                [108.6785924, 12.4113675],
                [108.6786986, 12.411528],
                [108.6788928, 12.4118599],
                [108.6790825, 12.4122515],
                [108.6792387, 12.4126568],
                [108.6793038, 12.4128639],
                [108.6793863, 12.4131787],
                [108.679429, 12.4134234],
                [108.6794426, 12.4135627],
                [108.6794471, 12.4138425],
                [108.6794379, 12.4139822],
                [108.6793967, 12.4142591],
                [108.6793649, 12.4143956],
                [108.6793323, 12.414489],
                [108.6792916, 12.4145792],
                [108.6791871, 12.4147477],
                [108.679124, 12.4148247],
                [108.6789787, 12.4149614],
                [108.6788772, 12.4150391],
                [108.67866, 12.4151744],
                [108.678545, 12.4152315],
                [108.678305, 12.415323],
                [108.6780548, 12.4153834],
                [108.6775, 12.415456],
                [108.6770715, 12.4155011],
                [108.6766421, 12.4155367],
                [108.6762121, 12.4155629],
                [108.6757815, 12.4155796],
                [108.6756542, 12.4158516],
                [108.6755562, 12.416135],
                [108.6755157, 12.4162923],
                [108.6754842, 12.4164516],
                [108.6754269, 12.4169281],
                [108.6753958, 12.4170806],
                [108.6753556, 12.417231],
                [108.6753065, 12.4173789],
                [108.6752486, 12.4175237],
                [108.6751822, 12.417665],
                [108.6751074, 12.4178023],
                [108.6750245, 12.4179351],
                [108.6748304, 12.4181969],
                [108.6746065, 12.4184515],
                [108.6743615, 12.4186868],
                [108.6740971, 12.4189011],
                [108.6738694, 12.4190419],
                [108.673435, 12.4192391],
                [108.6730671, 12.4194355],
                [108.6728713, 12.4195096],
                [108.6727696, 12.4195356],
                [108.6725617, 12.4195647],
                [108.6723517, 12.4195627],
                [108.6721444, 12.4195298],
                [108.672015, 12.4197137],
                [108.6719239, 12.419899],
                [108.6718659, 12.4200781],
                [108.6718093, 12.4203718],
                [108.6717763, 12.4204781],
                [108.6717344, 12.4205814],
                [108.6716841, 12.420681],
                [108.6716256, 12.4207763],
                [108.6715593, 12.4208665],
                [108.6714856, 12.4209512],
                [108.6712374, 12.421178],
                [108.6710922, 12.421346],
                [108.6709469, 12.4215399],
                [108.6707646, 12.4217304],
                [108.6706647, 12.421817],
                [108.6704492, 12.4219711],
                [108.6703244, 12.4220867],
                [108.6702703, 12.4221522],
                [108.6701389, 12.4223523],
                [108.6700359, 12.4224488],
                [108.6699765, 12.4224875],
                [108.6698458, 12.4225434],
                [108.6697763, 12.4225598],
                [108.6695619, 12.4225775],
                [108.6694918, 12.4225955],
                [108.6694245, 12.4226222],
                [108.6693614, 12.4226571],
                [108.6693033, 12.4226996],
                [108.6692513, 12.422749],
                [108.6692061, 12.4228044],
                [108.6691686, 12.4228651],
                [108.6691394, 12.4229299],
                [108.669072, 12.4231687],
                [108.6690313, 12.4233411],
                [108.6689687, 12.4236894],
                [108.6688988, 12.4239577],
                [108.6687983, 12.4242166],
                [108.668737, 12.4243415],
                [108.6685932, 12.42458],
                [108.6686882, 12.4247197],
                [108.6688936, 12.4249886],
                [108.669033, 12.4251504],
                [108.6691798, 12.4253058],
                [108.6693337, 12.4254546],
                [108.6697399, 12.4258046],
                [108.6702454, 12.426204],
                [108.6703821, 12.4263224],
                [108.6706402, 12.4265748],
                [108.6707611, 12.4267085],
                [108.6709324, 12.4269174],
                [108.6710585, 12.4270998],
                [108.6711248, 12.4272137],
                [108.6711842, 12.4273311],
                [108.671282, 12.4275749],
                [108.6713976, 12.4279872],
                [108.671454, 12.4281435],
                [108.6715196, 12.4282963],
                [108.6715941, 12.4284452],
                [108.6716773, 12.4285896],
                [108.6717689, 12.4287291],
                [108.6718365, 12.4288506],
                [108.6718958, 12.4289762],
                [108.6719465, 12.4291053],
                [108.6719885, 12.4292373],
                [108.6720214, 12.4293718],
                [108.6720452, 12.4295081],
                [108.6720598, 12.4296457],
                [108.6720651, 12.4297838],
                [108.6720559, 12.429991],
                [108.6720293, 12.430168],
                [108.6720039, 12.430275],
                [108.6719716, 12.4303802],
                [108.6718869, 12.4305836],
                [108.6720647, 12.4308524],
                [108.6722246, 12.4311493],
                [108.6723616, 12.4314737],
                [108.6724663, 12.4318094],
                [108.6725846, 12.4321302],
                [108.6726921, 12.4323635],
                [108.6727345, 12.4324381],
                [108.6728432, 12.4325851],
                [108.6729064, 12.4326517],
                [108.6730482, 12.4327689],
                [108.6731259, 12.4328187],
                [108.6733623, 12.4329386],
                [108.673428, 12.4329845],
                [108.6735439, 12.4330942],
                [108.6736844, 12.433284],
                [108.6737397, 12.4333384],
                [108.673867, 12.4334277],
                [108.6739375, 12.4334614],
                [108.6740878, 12.4335048],
                [108.6741658, 12.433514],
                [108.6743953, 12.4335077],
                [108.6745385, 12.4335335],
                [108.6746723, 12.4335897],
                [108.6747899, 12.4336737],
                [108.6748853, 12.4337811],
                [108.675009, 12.4339684],
                [108.6752008, 12.4342067],
                [108.6753062, 12.4343179],
                [108.6755347, 12.434523],
                [108.6756756, 12.4346806],
                [108.6757894, 12.4348579],
                [108.6758876, 12.4350424],
                [108.6760128, 12.4352088],
                [108.6760849, 12.4352845],
                [108.6762458, 12.4354186],
                [108.6763337, 12.4354762],
                [108.6766216, 12.4356259],
                [108.6767166, 12.4356888],
                [108.6768061, 12.4357589],
                [108.6768895, 12.4358359],
                [108.6769663, 12.4359192],
                [108.6770359, 12.4360082],
                [108.6770979, 12.4361025],
                [108.6772614, 12.4364273],
                [108.6773712, 12.4366042],
                [108.6774822, 12.4367448],
                [108.6776404, 12.4368974],
                [108.6777277, 12.4369647],
                [108.6780272, 12.4371502],
                [108.6781328, 12.437228],
                [108.6783273, 12.4374029],
                [108.6784967, 12.4376012],
                [108.6786276, 12.4377998],
                [108.6787265, 12.4379909],
                [108.6788064, 12.438084],
                [108.6788552, 12.4381219],
                [108.6789661, 12.4381767],
                [108.679088, 12.4382007],
                [108.6792542, 12.4382074],
                [108.679357, 12.4382233],
                [108.679458, 12.438248],
                [108.6795563, 12.4382814],
                [108.6796512, 12.4383233],
                [108.6797419, 12.4383732],
                [108.6798277, 12.4384308],
                [108.6799079, 12.4384956],
                [108.6799818, 12.4385672],
                [108.6800869, 12.4386793],
                [108.6801849, 12.4387974],
                [108.6802757, 12.4389209],
                [108.6803588, 12.4390494],
                [108.680434, 12.4391824],
                [108.680501, 12.4393196],
                [108.6806638, 12.4397313],
                [108.6807267, 12.4398544],
                [108.6807977, 12.4399731],
                [108.6808767, 12.440087],
                [108.6809631, 12.4401956],
                [108.6810568, 12.4402983],
                [108.6811572, 12.4403947],
                [108.6812639, 12.4404845],
                [108.6813165, 12.4405426],
                [108.681403, 12.4406725],
                [108.6814361, 12.440743],
                [108.6814803, 12.4408918],
                [108.681494, 12.4410461],
                [108.6814911, 12.4412268],
                [108.6815025, 12.4413295],
                [108.6815232, 12.4414308],
                [108.6815531, 12.4415299],
                [108.681592, 12.4416259],
                [108.6816395, 12.4417182],
                [108.6816954, 12.4418059],
                [108.681759, 12.4418884],
                [108.68183, 12.441965],
                [108.6823153, 12.4424227],
                [108.6825683, 12.442705],
                [108.6827977, 12.443006],
                [108.6829537, 12.4432426],
                [108.6830729, 12.4434543],
                [108.6831375, 12.443588],
                [108.683247, 12.4438635],
                [108.68334, 12.4441745],
                [108.6834204, 12.4445181],
                [108.6834557, 12.4446395],
                [108.683498, 12.4447588],
                [108.683661, 12.4451252],
                [108.6837119, 12.4452638],
                [108.6837909, 12.4455477],
                [108.6838706, 12.4460039],
                [108.6839117, 12.4461678],
                [108.6839882, 12.4464097],
                [108.6840642, 12.4465683],
                [108.6841144, 12.4466449],
                [108.684235, 12.4467831],
                [108.6843045, 12.4468437],
                [108.6844586, 12.4469453],
                [108.684629, 12.4470181],
                [108.6851305, 12.4471728],
                [108.685534, 12.4473258],
                [108.6857899, 12.4474541],
                [108.6859126, 12.4475277],
                [108.6861455, 12.4476925],
                [108.6861392, 12.4480909],
                [108.6860844, 12.4486725],
                [108.6860856, 12.4487936],
                [108.6861135, 12.4490341],
                [108.6861401, 12.4491524],
                [108.6862182, 12.4493822],
                [108.6864077, 12.4497599],
                [108.6865425, 12.4500916],
                [108.6865893, 12.4501818],
                [108.6867054, 12.4503491],
                [108.6868485, 12.450495],
                [108.687114, 12.4506903],
                [108.6872082, 12.4507714],
                [108.6874522, 12.4510212],
                [108.6877549, 12.451265],
                [108.68784, 12.4513429],
                [108.6880623, 12.4515896],
                [108.6881402, 12.4516563],
                [108.6882239, 12.4517159],
                [108.6884288, 12.4518428],
                [108.6886483, 12.4520096],
                [108.6894852, 12.4527972],
                [108.6898115, 12.4530885],
                [108.69048, 12.4536536],
                [108.6914992, 12.4536851],
                [108.6915431, 12.4540631],
                [108.6916065, 12.454448],
                [108.6917064, 12.4548497],
                [108.6917725, 12.4550583],
                [108.6918478, 12.455264],
                [108.6921006, 12.4558381],
                [108.6922127, 12.4561318],
                [108.6922794, 12.4562584],
                [108.6923778, 12.4563852],
                [108.6924355, 12.4564415],
                [108.6925653, 12.4565376],
                [108.692718, 12.456614],
                [108.6928021, 12.4566455],
                [108.6929764, 12.4566897],
                [108.6933452, 12.456746],
                [108.6936235, 12.4567965],
                [108.6941707, 12.4568699],
                [108.6943081, 12.4568992],
                [108.6944434, 12.456937],
                [108.6947052, 12.4570373],
                [108.6948306, 12.4570995],
                [108.6950681, 12.4572465],
                [108.695272, 12.4574112],
                [108.6953596, 12.4574971],
                [108.6955178, 12.4576836],
                [108.6956515, 12.4578876],
                [108.6958321, 12.4582532],
                [108.6959122, 12.458397],
                [108.6960915, 12.4586736],
                [108.6966047, 12.4593357],
                [108.6968958, 12.4597508],
                [108.6970244, 12.4599206],
                [108.6971604, 12.4600849],
                [108.6973035, 12.4602432],
                [108.6975572, 12.4604859],
                [108.6976922, 12.4605986],
                [108.6979771, 12.4608058],
                [108.6981264, 12.4608997],
                [108.6984076, 12.4610523],
                [108.6985379, 12.4611126],
                [108.6988056, 12.4612175],
                [108.6989054, 12.4612889],
                [108.698984, 12.4613822],
                [108.6990366, 12.4614915],
                [108.6990598, 12.4616102],
                [108.6990523, 12.4617308],
                [108.6990033, 12.4619479],
                [108.6989221, 12.4621557],
                [108.6987765, 12.4624441],
                [108.6986899, 12.4626366],
                [108.6985709, 12.4629299],
                [108.6985056, 12.4631477],
                [108.6984817, 12.4632673],
                [108.6984656, 12.4633881],
                [108.6984575, 12.4636316],
                [108.6984813, 12.463874],
                [108.6985244, 12.4641366],
                [108.6985337, 12.4642805],
                [108.6985333, 12.4644247],
                [108.698523, 12.4645686],
                [108.6984611, 12.4649557],
                [108.6984616, 12.4651629],
                [108.6984944, 12.4653676],
                [108.6985227, 12.4654675],
                [108.6986306, 12.4657766],
                [108.6986944, 12.4659906],
                [108.6987503, 12.4662066],
                [108.69882, 12.4665339],
                [108.6988562, 12.466807],
                [108.6988654, 12.4669706],
                [108.6988654, 12.4671346],
                [108.6988562, 12.4672982],
                [108.6988377, 12.4674612],
                [108.6988337, 12.4675915],
                [108.698839, 12.4677217],
                [108.6988536, 12.4678513],
                [108.6988775, 12.4679796],
                [108.6989106, 12.4681059],
                [108.6989527, 12.4682296],
                [108.6991098, 12.4685763],
                [108.6991815, 12.4688029],
                [108.6992234, 12.4690366],
                [108.6992437, 12.4694004],
                [108.6992599, 12.4695267],
                [108.6993137, 12.4697757],
                [108.6994441, 12.4701721],
                [108.6994835, 12.4703294],
                [108.6995137, 12.4704887],
                [108.7000823, 12.4710229],
                [108.7001146, 12.471297],
                [108.7001575, 12.4718472],
                [108.7001737, 12.4725236],
                [108.7001681, 12.4726571],
                [108.7001343, 12.4729173],
                [108.7000707, 12.473172],
                [108.700026, 12.4733425],
                [108.6999153, 12.4736788],
                [108.7011015, 12.4744275],
                [108.703336, 12.4746162],
                [108.7039715, 12.4753022],
                [108.7041555, 12.4753793],
                [108.7042992, 12.4754513],
                [108.7045777, 12.4756112],
                [108.7048828, 12.4758211],
                [108.7051678, 12.4760564],
                [108.7063855, 12.476774],
                [108.706618, 12.4768639],
                [108.7068361, 12.4769835],
                [108.7070166, 12.4771147],
                [108.7072935, 12.4773679],
                [108.7074137, 12.4774624],
                [108.7075396, 12.4775496],
                [108.7076707, 12.4776291],
                [108.7078065, 12.4777006],
                [108.7079465, 12.4777639],
                [108.7082055, 12.4779094],
                [108.7086332, 12.4782185],
                [108.7089242, 12.4784081],
                [108.7091464, 12.4785279],
                [108.7092712, 12.4785836],
                [108.7096406, 12.4787213],
                [108.7097484, 12.4787751],
                [108.7099507, 12.4789048],
                [108.7101317, 12.4790616],
                [108.7102876, 12.4792423],
                [108.7105644, 12.4796547],
                [108.7112778, 12.4807965],
                [108.7113503, 12.4809007],
                [108.7115139, 12.4810951],
                [108.7116894, 12.4812596],
                [108.7117841, 12.4813342],
                [108.7123716, 12.4817239],
                [108.7127423, 12.4820012],
                [108.7129569, 12.482177],
                [108.7131654, 12.4823597],
                [108.7133675, 12.4825491],
                [108.7135631, 12.4827449],
                [108.7138982, 12.4831162],
                [108.7142079, 12.4835078],
                [108.7144911, 12.4839182],
                [108.7154138, 12.4838867],
                [108.7156309, 12.4836873],
                [108.716069, 12.4833149],
                [108.7162469, 12.4831876],
                [108.7164661, 12.4830656],
                [108.7166989, 12.4829705],
                [108.7169436, 12.482903],
                [108.7170696, 12.4828797],
                [108.7173247, 12.4828552],
                [108.717581, 12.4828602],
                [108.7176647, 12.482854],
                [108.7178281, 12.4828177],
                [108.717981, 12.4827508],
                [108.7182273, 12.4825804],
                [108.718342, 12.4825123],
                [108.718522, 12.4824241],
                [108.7186845, 12.482366],
                [108.7188915, 12.482322],
                [108.7189969, 12.4823114],
                [108.7192087, 12.4823136],
                [108.7194178, 12.4823466],
                [108.7197721, 12.4824492],
                [108.7199444, 12.4824875],
                [108.7200825, 12.4824923],
                [108.7201534, 12.4824831],
                [108.7202895, 12.4824415],
                [108.7204125, 12.482371],
                [108.72054, 12.4822587],
                [108.7206084, 12.4821871],
                [108.7206719, 12.4821114],
                [108.7207836, 12.4819488],
                [108.7208907, 12.481735],
                [108.7209646, 12.4815082],
                [108.7210035, 12.4812732],
                [108.7210285, 12.4809515],
                [108.7210303, 12.4806289],
                [108.7223446, 12.4805294],
                [108.7233665, 12.4798409],
                [108.7235999, 12.4797228],
                [108.7238424, 12.4796384],
                [108.7239674, 12.4796082],
                [108.7242223, 12.4795728],
                [108.7243509, 12.4795676],
                [108.7249262, 12.4795797],
                [108.7250751, 12.4795762],
                [108.725371, 12.4795463],
                [108.725662, 12.4794863],
                [108.7262002, 12.4793186],
                [108.726604, 12.47922],
                [108.7276661, 12.4790367],
                [108.7280685, 12.4786858],
                [108.7283474, 12.4784605],
                [108.7287004, 12.478209],
                [108.7290716, 12.4779839],
                [108.7295165, 12.4777453],
                [108.7299728, 12.4775282],
                [108.7304713, 12.4773169],
                [108.7312496, 12.4770254],
                [108.7315588, 12.4769434],
                [108.7318747, 12.4768909],
                [108.7321944, 12.4768683],
                [108.7325457, 12.4768628],
                [108.7327365, 12.4768514],
                [108.7332752, 12.4767987],
                [108.7337186, 12.4767878],
                [108.7339657, 12.4767514],
                [108.7342065, 12.4766862],
                [108.7345323, 12.4765595],
                [108.7349402, 12.4763708],
                [108.7353258, 12.4761714],
                [108.7354612, 12.4761262],
                [108.7355333, 12.4761149],
                [108.7356062, 12.4761119],
                [108.7357507, 12.4761305],
                [108.735887, 12.476181],
                [108.7360078, 12.4762607],
                [108.7361362, 12.4763243],
                [108.7362765, 12.4763557],
                [108.7363486, 12.4763588],
                [108.7364205, 12.4763533],
                [108.7365596, 12.4763171],
                [108.7368562, 12.4761806],
                [108.7369358, 12.4761507],
                [108.7370841, 12.4761152],
                [108.7371599, 12.4761064],
                [108.7372993, 12.4761073],
                [108.7374232, 12.476131],
                [108.7375651, 12.4761703],
                [108.7377332, 12.4761855],
                [108.7378176, 12.4761809],
                [108.737983, 12.4761477],
                [108.7382117, 12.4760606],
                [108.7385023, 12.4759255],
                [108.7386034, 12.4758656],
                [108.7387481, 12.4757653],
                [108.7388366, 12.4756758],
                [108.7389039, 12.4755596],
                [108.7389381, 12.4754304],
                [108.7389406, 12.4753558],
                [108.7389421, 12.474307],
                [108.7396737, 12.4759562],
                [108.7397264, 12.4760597],
                [108.7398434, 12.4762607],
                [108.7399375, 12.476401],
                [108.740037, 12.4765378],
                [108.7401952, 12.4767338],
                [108.7407017, 12.4772716],
                [108.7413186, 12.4778949],
                [108.7434697, 12.4794505],
                [108.7442735, 12.4795067],
                [108.745079, 12.479529],
                [108.7458897, 12.4795174],
                [108.7466991, 12.4794714],
                [108.7468632, 12.479538],
                [108.7471819, 12.4796914],
                [108.747338, 12.4797792],
                [108.7475647, 12.4799229],
                [108.7477726, 12.4800773],
                [108.7479015, 12.4801873],
                [108.7480242, 12.4803039],
                [108.7481403, 12.4804268],
                [108.7482494, 12.4805556],
                [108.7482172, 12.4814251],
                [108.7479061, 12.4826769],
                [108.74892, 12.4834206],
                [108.7488931, 12.4845729],
                [108.7488781, 12.4847973],
                [108.7488341, 12.4850181],
                [108.748796, 12.4851417],
                [108.7487488, 12.4852622],
                [108.7486927, 12.4853791],
                [108.7486281, 12.4854917],
                [108.7485552, 12.4855994],
                [108.7485152, 12.4856753],
                [108.748456, 12.4858356],
                [108.7484225, 12.4860166],
                [108.7484154, 12.486115],
                [108.7484161, 12.4862137],
                [108.7484404, 12.4864095],
                [108.7484973, 12.4866103],
                [108.7485379, 12.4867125],
                [108.7486405, 12.4869076],
                [108.748702, 12.4869994],
                [108.7490755, 12.4874797],
                [108.7491506, 12.4879458],
                [108.7491885, 12.4882309],
                [108.7492955, 12.4891609],
                [108.7493759, 12.4900199],
                [108.7494095, 12.4902823],
                [108.7494511, 12.4905437],
                [108.7495583, 12.4910621],
                [108.7496133, 12.4912756],
                [108.7497624, 12.4917681],
                [108.7498158, 12.4919054],
                [108.7498582, 12.4919886],
                [108.7499069, 12.4920685],
                [108.7500222, 12.4922163],
                [108.7504383, 12.4926069],
                [108.7505293, 12.4927119],
                [108.7505186, 12.494325],
                [108.7509263, 12.495074],
                [108.7508247, 12.4952548],
                [108.7506366, 12.4956239],
                [108.750435, 12.4960803],
                [108.7503644, 12.4962931],
                [108.750316, 12.4965371],
                [108.750304, 12.4966609],
                [108.7503147, 12.4968525],
                [108.7503606, 12.4970391],
                [108.7504401, 12.4972146],
                [108.7505507, 12.4973732],
                [108.7511103, 12.4974885],
                [108.7513876, 12.4975565],
                [108.7516782, 12.4976356],
                [108.7522524, 12.4978163],
                [108.7525356, 12.4979179],
                [108.7527286, 12.4979974],
                [108.7529183, 12.4980842],
                [108.7532866, 12.4982792],
                [108.7533185, 12.4983502],
                [108.7533992, 12.4984835],
                [108.7535165, 12.498618],
                [108.753584, 12.498677],
                [108.7537601, 12.4988097],
                [108.7539571, 12.498981],
                [108.7539539, 12.4991366],
                [108.7539732, 12.4994471],
                [108.7540291, 12.4997637],
                [108.7541206, 12.5000722],
                [108.7542468, 12.5003689],
                [108.754795, 12.5008491],
                [108.7558454, 12.4997038],
                [108.7568057, 12.4990282],
                [108.7574923, 12.4990386],
                [108.7576898, 12.4988758],
                [108.757782, 12.4987872],
                [108.7580637, 12.4984725],
                [108.7582862, 12.498253],
                [108.7584273, 12.4981492],
                [108.7585029, 12.4981044],
                [108.7586559, 12.498035],
                [108.7588119, 12.4979964],
                [108.7588706, 12.4979954],
                [108.7589849, 12.4980199],
                [108.759086, 12.4980774],
                [108.7592631, 12.49825],
                [108.7593913, 12.4983892],
                [108.7595361, 12.4985149],
                [108.7596558, 12.4985801],
                [108.7597204, 12.4986025],
                [108.7597872, 12.4986179],
                [108.7599239, 12.4986265],
                [108.7604052, 12.4985882],
                [108.7605083, 12.4985675],
                [108.760609, 12.4985377],
                [108.7607065, 12.4984991],
                [108.7608001, 12.4984519],
                [108.7608888, 12.4983966],
                [108.760972, 12.4983336],
                [108.7610489, 12.4982635],
                [108.7612072, 12.4981526],
                [108.7613822, 12.4980688],
                [108.761569, 12.4980145],
                [108.7616652, 12.4979989],
                [108.7637231, 12.4978183],
                [108.7638155, 12.497817],
                [108.7638888, 12.4978244],
                [108.76403, 12.4978646],
                [108.7641576, 12.4979369],
                [108.7642638, 12.4980373],
                [108.7643068, 12.4980961],
                [108.7643695, 12.4982269],
                [108.7644545, 12.4984532],
                [108.7645304, 12.4986825],
                [108.7645754, 12.4987834],
                [108.7646839, 12.4989763],
                [108.764747, 12.4990674],
                [108.7648898, 12.4992377],
                [108.7649548, 12.4992942],
                [108.7650258, 12.4993435],
                [108.7651018, 12.499385],
                [108.765182, 12.499418],
                [108.7652655, 12.4994424],
                [108.7654723, 12.4994873],
                [108.7655907, 12.499526],
                [108.7657057, 12.4995735],
                [108.7658166, 12.4996296],
                [108.7659228, 12.4996938],
                [108.7660235, 12.4997658],
                [108.7661183, 12.4998452],
                [108.7672716, 12.5008298],
                [108.7673265, 12.5009025],
                [108.7674165, 12.5010602],
                [108.7674781, 12.501231],
                [108.7675092, 12.5014096],
                [108.7675277, 12.5017097],
                [108.7675505, 12.5019185],
                [108.7675987, 12.50223],
                [108.7676478, 12.5024407],
                [108.7677169, 12.5026523],
                [108.7677367, 12.5027379],
                [108.7677482, 12.502825],
                [108.7677458, 12.5030003],
                [108.7677319, 12.503087],
                [108.7676793, 12.5032546],
                [108.7676694, 12.5033129],
                [108.7676756, 12.5034305],
                [108.7677157, 12.5035416],
                [108.7677477, 12.5035918],
                [108.7677866, 12.5036369],
                [108.7678638, 12.5036901],
                [108.7679078, 12.5037073],
                [108.7680012, 12.5037207],
                [108.7680914, 12.5037205],
                [108.76827, 12.5036959],
                [108.7684408, 12.5036396],
                [108.7686808, 12.5035331],
                [108.7688043, 12.5035041],
                [108.7688735, 12.5035002],
                [108.7690108, 12.5035176],
                [108.7690766, 12.5035387],
                [108.7691978, 12.5036041],
                [108.7693244, 12.503696],
                [108.7694026, 12.5037367],
                [108.7694848, 12.5037689],
                [108.7695701, 12.5037923],
                [108.7696574, 12.5038066],
                [108.7697459, 12.5038116],
                [108.7698343, 12.5038074],
                [108.7699218, 12.5037938],
                [108.7700073, 12.5037712],
                [108.7700899, 12.5037397],
                [108.7702484, 12.5036504],
                [108.7703937, 12.5035322],
                [108.7704411, 12.5035043],
                [108.7704921, 12.5034833],
                [108.7706007, 12.5034638],
                [108.7707104, 12.5034752],
                [108.7707629, 12.5034923],
                [108.7708577, 12.5035476],
                [108.770898, 12.5035846],
                [108.7730974, 12.5036683],
                [108.7733783, 12.5037674],
                [108.7735212, 12.5038098],
                [108.7738501, 12.5038876],
                [108.7741843, 12.5039399],
                [108.7745573, 12.5039831],
                [108.774963, 12.5040578],
                [108.7751636, 12.5041058],
                [108.7755587, 12.5042228],
                [108.7757528, 12.5042916],
                [108.7759429, 12.5043691],
                [108.7761291, 12.5044554],
                [108.7763108, 12.5045502],
                [108.7764878, 12.5046532],
                [108.7766595, 12.5047644],
                [108.7771426, 12.5051189],
                [108.7774538, 12.5053616],
                [108.7777591, 12.5056113],
                [108.7785262, 12.5055956],
                [108.7786285, 12.505584],
                [108.778774, 12.5055392],
                [108.7788427, 12.5055066],
                [108.7789633, 12.5054266],
                [108.7790665, 12.5053259],
                [108.7791485, 12.5052081],
                [108.779181, 12.5051428],
                [108.7792256, 12.5050044],
                [108.7792418, 12.5048603],
                [108.7792289, 12.5047158],
                [108.7796267, 12.5042392],
                [108.7798351, 12.5040088],
                [108.7802524, 12.5035821],
                [108.7804693, 12.5033766],
                [108.7806914, 12.5031767],
                [108.780908, 12.5030085],
                [108.7811858, 12.5028202],
                [108.7817126, 12.5024847],
                [108.7820684, 12.5022475],
                [108.7823296, 12.5020556],
                [108.7826068, 12.5018214],
                [108.78294, 12.5015021],
                [108.7830967, 12.5013797],
                [108.7831985, 12.5013368],
                [108.7833083, 12.5013207],
                [108.7833638, 12.5013232],
                [108.7835138, 12.501355],
                [108.7837784, 12.501445],
                [108.7838613, 12.5014605],
                [108.7840298, 12.5014672],
                [108.7841964, 12.5014413],
                [108.7842768, 12.5014163],
                [108.7844747, 12.5013373],
                [108.7845404, 12.501323],
                [108.7846748, 12.5013164],
                [108.7848073, 12.5013392],
                [108.7851841, 12.501492],
                [108.7855912, 12.5016119],
                [108.7858754, 12.5017149],
                [108.7860697, 12.5017617],
                [108.7862689, 12.5017797],
                [108.786369, 12.5017777],
                [108.786468, 12.5017668],
                [108.786661, 12.5017188],
                [108.7867533, 12.5016821],
                [108.7869257, 12.5015847],
                [108.7870044, 12.5015248],
                [108.7871749, 12.5013604],
                [108.7873547, 12.5011506],
                [108.7874361, 12.5010393],
                [108.787603, 12.5007738],
                [108.7876886, 12.5006203],
                [108.7877916, 12.500498],
                [108.7879152, 12.5003958],
                [108.7881066, 12.5002682],
                [108.7882239, 12.500176],
                [108.788335, 12.5000767],
                [108.7884396, 12.4999709],
                [108.7885197, 12.499905],
                [108.7886957, 12.4997944],
                [108.7887902, 12.4997504],
                [108.788989, 12.4996866],
                [108.7892961, 12.4996397],
                [108.7893948, 12.4996165],
                [108.7894918, 12.4995871],
                [108.7896788, 12.49951],
                [108.7897485, 12.4994882],
                [108.7898928, 12.4994659],
                [108.7899659, 12.4994656],
                [108.7901104, 12.4994868],
                [108.7903709, 12.499581],
                [108.7904969, 12.4996182],
                [108.7907549, 12.4996692],
                [108.7914324, 12.4997296],
                [108.7917572, 12.4997485],
                [108.7922403, 12.4992403],
                [108.7922484, 12.498981],
                [108.7926239, 12.4982426],
                [108.7930578, 12.4979774],
                [108.7932676, 12.4978341],
                [108.7936554, 12.4975399],
                [108.7937752, 12.4974306],
                [108.7938416, 12.4973836],
                [108.793984, 12.497307],
                [108.7940596, 12.4972775],
                [108.7941374, 12.4972542],
                [108.7943758, 12.497207],
                [108.7944512, 12.4971787],
                [108.7945227, 12.4971421],
                [108.7945894, 12.4970976],
                [108.7946504, 12.4970459],
                [108.7947048, 12.4969876],
                [108.7947519, 12.4969236],
                [108.7948216, 12.4967863],
                [108.7948626, 12.4966428],
                [108.7948753, 12.4965323],
                [108.7949213, 12.4959199],
                [108.7949359, 12.4954825],
                [108.7949834, 12.49465],
                [108.795011, 12.4937804],
                [108.7956659, 12.4933669],
                [108.7959879, 12.4931521],
                [108.7967329, 12.492624],
                [108.7969457, 12.4924441],
                [108.7971407, 12.4922458],
                [108.7972512, 12.4921153],
                [108.797354, 12.4919789],
                [108.7974487, 12.491837],
                [108.7975764, 12.4916157],
                [108.7976128, 12.4915387],
                [108.7976796, 12.4913479],
                [108.7976936, 12.4912466],
                [108.7976794, 12.4911237],
                [108.7976596, 12.4910647],
                [108.7975967, 12.4909574],
                [108.7967867, 12.4900565],
                [108.797189, 12.4892814],
                [108.7960356, 12.4881815],
                [108.7959552, 12.4876316],
                [108.7952739, 12.4869193],
                [108.7956226, 12.4861284],
                [108.7957043, 12.4860034],
                [108.7958237, 12.4858686],
                [108.7959029, 12.4857988],
                [108.7959884, 12.4857365],
                [108.7960794, 12.4856821],
                [108.7963616, 12.4855425],
                [108.7966376, 12.4853952],
                [108.7969246, 12.4852184],
                [108.7969851, 12.4851699],
                [108.7970403, 12.4851139],
                [108.7970882, 12.4850517],
                [108.797128, 12.4849844],
                [108.7971593, 12.4849128],
                [108.7971815, 12.4848382],
                [108.7971944, 12.4847614],
                [108.7971729, 12.4839863],
                [108.7972101, 12.4839058],
                [108.7973015, 12.4837535],
                [108.7973553, 12.4836825],
                [108.7974803, 12.4835499],
                [108.7976235, 12.4834363],
                [108.7978073, 12.4833219],
                [108.7978926, 12.4832349],
                [108.7979555, 12.4831313],
                [108.7980232, 12.4829253],
                [108.798058, 12.4828549],
                [108.7981372, 12.4827413],
                [108.7982367, 12.4826442],
                [108.7984376, 12.4825079],
                [108.7985904, 12.4823707],
                [108.798883, 12.482014],
                [108.7990665, 12.4818336],
                [108.7990719, 12.4812522],
                [108.799255, 12.4811557],
                [108.7993509, 12.481116],
                [108.7994711, 12.4810756],
                [108.7995942, 12.4810442],
                [108.7997194, 12.4810221],
                [108.799846, 12.4810093],
                [108.7999731, 12.4810061],
                [108.8001713, 12.4809782],
                [108.8003634, 12.480923],
                [108.8005387, 12.4808571],
                [108.8007081, 12.4808123],
                [108.8008568, 12.4808037],
                [108.8010037, 12.4808283],
                [108.8010741, 12.4808526],
                [108.8012041, 12.4809238],
                [108.801262, 12.4809697],
                [108.8014334, 12.4811491],
                [108.8016522, 12.4814198],
                [108.8017808, 12.481621],
                [108.8018882, 12.4818336],
                [108.8019307, 12.481894],
                [108.8019806, 12.4819486],
                [108.8020374, 12.4819965],
                [108.8020999, 12.482037],
                [108.8021672, 12.4820693],
                [108.8022157, 12.4820814],
                [108.8023155, 12.4820817],
                [108.8024101, 12.4820503],
                [108.8024521, 12.4820237],
                [108.8025466, 12.4819332],
                [108.8025999, 12.481872],
                [108.8027695, 12.4816336],
                [108.8028526, 12.4815327],
                [108.8030373, 12.4813469],
                [108.8032734, 12.4811461],
                [108.8035248, 12.4809108],
                [108.8037454, 12.4806636],
                [108.8039428, 12.4803985],
                [108.8041145, 12.4804247],
                [108.8043147, 12.4804847],
                [108.8045029, 12.4805745],
                [108.8046746, 12.4806918],
                [108.8048818, 12.4808803],
                [108.8052181, 12.4812294],
                [108.805424, 12.4813975],
                [108.8055341, 12.4814724],
                [108.8059233, 12.4816942],
                [108.806075, 12.4817928],
                [108.8063621, 12.4820117],
                [108.806474, 12.4821146],
                [108.8065792, 12.4822242],
                [108.8066772, 12.4823398],
                [108.8067677, 12.4824612],
                [108.8068503, 12.4825878],
                [108.8069057, 12.482653],
                [108.8070332, 12.4827674],
                [108.8071044, 12.4828157],
                [108.8072586, 12.4828926],
                [108.8074245, 12.4829413],
                [108.807629, 12.4829792],
                [108.807746, 12.4830112],
                [108.8079727, 12.4830962],
                [108.8081868, 12.4832083],
                [108.8086008, 12.4834911],
                [108.8087265, 12.4836115],
                [108.8087879, 12.4836878],
                [108.8088417, 12.4837695],
                [108.8088874, 12.4838557],
                [108.8089247, 12.4839456],
                [108.8089893, 12.4841735],
                [108.8090444, 12.4844472],
                [108.8090707, 12.484524],
                [108.8091485, 12.4846669],
                [108.8091989, 12.4847312],
                [108.80932, 12.4848417],
                [108.8095334, 12.4849669],
                [108.8096001, 12.4850156],
                [108.8097203, 12.485128],
                [108.8097665, 12.4851592],
                [108.8098713, 12.4851976],
                [108.8099832, 12.4852013],
                [108.8100381, 12.4851899],
                [108.810323, 12.4850841],
                [108.8109718, 12.4848631],
                [108.8113886, 12.48473],
                [108.8114794, 12.4847074],
                [108.8115673, 12.4846759],
                [108.8116515, 12.4846357],
                [108.8117849, 12.4845478],
                [108.8118452, 12.4844956],
                [108.8119505, 12.4843767],
                [108.8120455, 12.4842249],
                [108.8121289, 12.4840439],
                [108.8122045, 12.4839394],
                [108.8123046, 12.4838568],
                [108.8124228, 12.4838015],
                [108.8125514, 12.4837769],
                [108.8127575, 12.4837699],
                [108.8128971, 12.4837541],
                [108.8131888, 12.4837095],
                [108.813343, 12.4836967],
                [108.8136524, 12.483693],
                [108.813921, 12.4836761],
                [108.8142526, 12.483621],
                [108.8144075, 12.4835734],
                [108.8145778, 12.4834907],
                [108.8146569, 12.4834391],
                [108.8148005, 12.4833172],
                [108.814921, 12.4831735],
                [108.815015, 12.4830121],
                [108.815056, 12.482957],
                [108.8151583, 12.4828647],
                [108.8152815, 12.4828013],
                [108.8155032, 12.4827505],
                [108.8155868, 12.4827221],
                [108.8157442, 12.4826434],
                [108.8158166, 12.4825936],
                [108.8159457, 12.4824756],
                [108.8161052, 12.482265],
                [108.8161939, 12.4821627],
                [108.8162715, 12.4821008],
                [108.8163708, 12.482057],
                [108.8164241, 12.4820454],
                [108.8165331, 12.4820436],
                [108.816678, 12.4820644],
                [108.8168617, 12.4820635],
                [108.8169529, 12.4820518],
                [108.8171884, 12.4819964],
                [108.8173324, 12.4819541],
                [108.8174288, 12.4819134],
                [108.8175208, 12.4818641],
                [108.8176076, 12.4818064],
                [108.8176884, 12.4817411],
                [108.8177626, 12.4816685],
                [108.8178294, 12.4815894],
                [108.8178882, 12.4815045],
                [108.819446, 12.4790838],
                [108.8194536, 12.4789239],
                [108.819486, 12.478767],
                [108.8195586, 12.478581],
                [108.8196527, 12.4784235],
                [108.8197088, 12.4783506],
                [108.8198264, 12.4782391],
                [108.8199641, 12.4781525],
                [108.8201165, 12.4780939],
                [108.820166, 12.4780672],
                [108.8202505, 12.477994],
                [108.8202836, 12.4779492],
                [108.8203421, 12.4778232],
                [108.8203661, 12.4777434],
                [108.8203879, 12.4775786],
                [108.820374, 12.477413],
                [108.8203093, 12.4771397],
                [108.8202794, 12.4768627],
                [108.8202572, 12.4767483],
                [108.820189, 12.4765254],
                [108.8196957, 12.4753583],
                [108.8198752, 12.4753163],
                [108.8200468, 12.4752498],
                [108.8201428, 12.4751997],
                [108.8203926, 12.4750337],
                [108.820548, 12.4749679],
                [108.8207133, 12.4749316],
                [108.8209218, 12.4749075],
                [108.821044, 12.4748808],
                [108.8211638, 12.474845],
                [108.8212805, 12.4748003],
                [108.8215792, 12.4746664],
                [108.8217684, 12.4745931],
                [108.822155, 12.4744694],
                [108.8230117, 12.4747675],
                [108.823142, 12.4747889],
                [108.8232105, 12.4747895],
                [108.8233456, 12.4747691],
                [108.8234108, 12.4747485],
                [108.8235323, 12.4746873],
                [108.8236397, 12.4746011],
                [108.8236871, 12.4745499],
                [108.823765, 12.4744349],
                [108.8238883, 12.4741545],
                [108.8239812, 12.4740118],
                [108.8241024, 12.473888],
                [108.8242461, 12.4737898],
                [108.8244067, 12.473721],
                [108.824578, 12.4736843],
                [108.8252664, 12.4736471],
                [108.8256687, 12.4735999],
                [108.8259643, 12.4735307],
                [108.8261085, 12.4734838],
                [108.8263877, 12.4733663],
                [108.8266869, 12.4732015],
                [108.8270044, 12.4729923],
                [108.8271347, 12.4729156],
                [108.8272697, 12.4728471],
                [108.8274088, 12.472787],
                [108.8277414, 12.472659],
                [108.8279274, 12.4725739],
                [108.8281092, 12.4724807],
                [108.8282865, 12.4723795],
                [108.82878, 12.4720862],
                [108.8289278, 12.4719831],
                [108.8290699, 12.4718726],
                [108.8292059, 12.471755],
                [108.8293355, 12.4716308],
                [108.8294583, 12.4715001],
                [108.829574, 12.4713634],
                [108.8297671, 12.471112],
                [108.8298847, 12.4710091],
                [108.8300217, 12.4709325],
                [108.830172, 12.4708854],
                [108.830329, 12.4708699],
                [108.8306076, 12.4708999],
                [108.8307298, 12.4709058],
                [108.8309743, 12.4708952],
                [108.8310955, 12.4708789],
                [108.8313967, 12.4708135],
                [108.8317535, 12.4707092],
                [108.8321006, 12.4705777],
                [108.8324805, 12.470419],
                [108.8326738, 12.4703468],
                [108.8327668, 12.4703025],
                [108.832965, 12.470184],
                [108.8330676, 12.4701075],
                [108.833164, 12.4700238],
                [108.833254, 12.4699334],
                [108.8333422, 12.4698588],
                [108.8334363, 12.4697915],
                [108.8335357, 12.4697318],
                [108.8336398, 12.4696802],
                [108.8337478, 12.4696371],
                [108.8338591, 12.4696027],
                [108.8341205, 12.4695388],
                [108.8344104, 12.4694444],
                [108.8346735, 12.4693357],
                [108.8347292, 12.4692997],
                [108.8347762, 12.4692344],
                [108.8347885, 12.4691961],
                [108.8347882, 12.4691163],
                [108.8347382, 12.4689435],
                [108.8347023, 12.4688607],
                [108.8346048, 12.4686981],
                [108.8345426, 12.4686204],
                [108.8344733, 12.4685486],
                [108.8343976, 12.4684832],
                [108.8343161, 12.4684249],
                [108.8341813, 12.46835],
                [108.8340372, 12.468294],
                [108.8339728, 12.4682616],
                [108.8339132, 12.4682216],
                [108.8338119, 12.4681211],
                [108.8337453, 12.4680066],
                [108.8337238, 12.467944],
                [108.8337063, 12.4678134],
                [108.8337106, 12.4677475],
                [108.8337236, 12.4676827],
                [108.8338012, 12.4674915],
                [108.8338215, 12.4674202],
                [108.8338414, 12.4672736],
                [108.8338333, 12.4671259],
                [108.83384, 12.4669927],
                [108.8338833, 12.4668315],
                [108.8339251, 12.4667594],
                [108.833959, 12.4667225],
                [108.8340426, 12.4666668],
                [108.8340901, 12.4666494],
                [108.8341906, 12.4666379],
                [108.8343368, 12.4666486],
                [108.8344326, 12.4666439],
                [108.8345274, 12.4666301],
                [108.8346204, 12.4666072],
                [108.8347107, 12.4665755],
                [108.8347973, 12.4665352],
                [108.8349602, 12.4664288],
                [108.835035, 12.4663635],
                [108.8351032, 12.4662916],
                [108.8351923, 12.4661732],
                [108.8352466, 12.4660696],
                [108.8352857, 12.4659403],
                [108.8353083, 12.4657355],
                [108.8353266, 12.4656667],
                [108.8353857, 12.4655368],
                [108.8354258, 12.4654773],
                [108.8355246, 12.465373],
                [108.8356985, 12.4652344],
                [108.8358102, 12.4651341],
                [108.8360182, 12.4649187],
                [108.8361142, 12.464804],
                [108.8363275, 12.4645113],
                [108.8365005, 12.4642431],
                [108.836619, 12.464019],
                [108.8366765, 12.4638836],
                [108.8367746, 12.4637182],
                [108.8368328, 12.4636413],
                [108.8370579, 12.4634053],
                [108.837229, 12.4632027],
                [108.8373364, 12.4631247],
                [108.8374608, 12.4630764],
                [108.8375268, 12.4630646],
                [108.837736, 12.46306],
                [108.8378781, 12.4630665],
                [108.837989, 12.4630608],
                [108.8380989, 12.4630458],
                [108.8384697, 12.4629466],
                [108.8386291, 12.4629146],
                [108.8387329, 12.4628817],
                [108.8388333, 12.46284],
                [108.8389295, 12.4627897],
                [108.8391294, 12.4626664],
                [108.8393533, 12.4625479],
                [108.8394978, 12.4624978],
                [108.8397234, 12.4624511],
                [108.8398667, 12.4623948],
                [108.8399971, 12.4623139],
                [108.8401217, 12.4622195],
                [108.8402639, 12.4621461],
                [108.840417, 12.4620979],
                [108.8405764, 12.4620765],
                [108.840779, 12.4620812],
                [108.840978, 12.4621186],
                [108.8411678, 12.4621877],
                [108.8413829, 12.4622918],
                [108.8415106, 12.4623444],
                [108.8417062, 12.4624134],
                [108.8418859, 12.4624588],
                [108.8421167, 12.4624872],
                [108.8423492, 12.4624834],
                [108.8424647, 12.4624694],
                [108.8427025, 12.4624333],
                [108.8429387, 12.4623879],
                [108.8431728, 12.4623332],
                [108.8433135, 12.4623128],
                [108.8434554, 12.4623015],
                [108.8435976, 12.4622992],
                [108.8437398, 12.462306],
                [108.8438811, 12.4623218],
                [108.8440211, 12.4623466],
                [108.8441592, 12.4623803],
                [108.8442947, 12.4624227],
                [108.8444271, 12.4624737],
                [108.8445557, 12.4625329],
                [108.8446802, 12.4626003],
                [108.8454848, 12.4623646],
                [108.8456419, 12.4623491],
                [108.8458279, 12.4623568],
                [108.8459337, 12.462374],
                [108.8460375, 12.4624004],
                [108.8461385, 12.4624358],
                [108.8462359, 12.4624798],
                [108.8463914, 12.4625322],
                [108.8465845, 12.4625699],
                [108.8466827, 12.4625784],
                [108.8468796, 12.4625741],
                [108.8470595, 12.4625373],
                [108.8471459, 12.4625065],
                [108.8473076, 12.4624212],
                [108.8473814, 12.4623676],
                [108.8475116, 12.4622409],
                [108.8476145, 12.4620922],
                [108.8478301, 12.4617062],
                [108.8479254, 12.4615799],
                [108.8480374, 12.4614818],
                [108.8481003, 12.4614414],
                [108.8483088, 12.4613426],
                [108.8484383, 12.4612464],
                [108.8485436, 12.4611251],
                [108.8486196, 12.4609846],
                [108.8486629, 12.4608314],
                [108.8486715, 12.4607523],
                [108.8486747, 12.46047],
                [108.8486885, 12.4601661],
                [108.8487151, 12.4599487],
                [108.8487679, 12.4597194],
                [108.8488698, 12.4594311],
                [108.8488968, 12.4592472],
                [108.8488878, 12.4590617],
                [108.8488228, 12.4587194],
                [108.8487979, 12.4585293],
                [108.8488106, 12.4584591],
                [108.848829, 12.4584278],
                [108.8488851, 12.4583813],
                [108.8489197, 12.4583687],
                [108.8489564, 12.4583642],
                [108.8490578, 12.4583701],
                [108.8491863, 12.4583575],
                [108.8492751, 12.4583369],
                [108.8493613, 12.4583076],
                [108.849444, 12.4582698],
                [108.8495222, 12.4582239],
                [108.8495953, 12.4581705],
                [108.8496624, 12.45811],
                [108.8497764, 12.4579724],
                [108.8498374, 12.4579076],
                [108.8499051, 12.4578494],
                [108.8499787, 12.4577984],
                [108.8500575, 12.4577554],
                [108.8501404, 12.4577206],
                [108.8502266, 12.4576946],
                [108.8503151, 12.4576776],
                [108.850405, 12.4576698],
                [108.8507378, 12.4576815],
                [108.8509798, 12.4577006],
                [108.851221, 12.4577286],
                [108.8517098, 12.4578106],
                [108.8519574, 12.4578624],
                [108.8524479, 12.4579856],
                [108.8525804, 12.4580111],
                [108.852714, 12.4580301],
                [108.8529834, 12.4580488],
                [108.8532593, 12.4580404],
                [108.8533995, 12.4580247],
                [108.853676, 12.4579704],
                [108.8540727, 12.4578517],
                [108.8543349, 12.4578093],
                [108.8544676, 12.4578015],
                [108.8546006, 12.4578026],
                [108.854882, 12.4578294],
                [108.8550301, 12.4578515],
                [108.8553232, 12.4579122],
                [108.8555437, 12.4577574],
                [108.8559467, 12.4574964],
                [108.8563102, 12.4572837],
                [108.8566405, 12.4571357],
                [108.8568278, 12.4570187],
                [108.8569145, 12.4569507],
                [108.8570719, 12.4567973],
                [108.8572632, 12.4565548],
                [108.8574836, 12.4562237],
                [108.8576728, 12.4558746],
                [108.8579299, 12.4558515],
                [108.8580591, 12.4558505],
                [108.8583165, 12.4558694],
                [108.8585756, 12.4559174],
                [108.8587026, 12.4559521],
                [108.8589495, 12.4560422],
                [108.8596415, 12.4555184],
                [108.8601971, 12.4551125],
                [108.8604609, 12.4549459],
                [108.8607697, 12.4547834],
                [108.860929, 12.4547117],
                [108.8610914, 12.4546104],
                [108.8612373, 12.4544873],
                [108.8613635, 12.4543451],
                [108.8617283, 12.4543817],
                [108.8619519, 12.4541802],
                [108.8620716, 12.4540884],
                [108.8621707, 12.4540196],
                [108.8625111, 12.4538077],
                [108.8626391, 12.453713],
                [108.862761, 12.4536109],
                [108.8628763, 12.4535017],
                [108.8632089, 12.4534284],
                [108.86334, 12.4534078],
                [108.8634286, 12.4534023],
                [108.863525, 12.4533705],
                [108.8635741, 12.4533412],
                [108.8636552, 12.453262],
                [108.8636851, 12.453214],
                [108.8637199, 12.4531073],
                [108.8637275, 12.4529429],
                [108.8637212, 12.4526426],
                [108.8637481, 12.452477],
                [108.8637721, 12.4523965],
                [108.8638016, 12.452333],
                [108.8638392, 12.4522739],
                [108.8638843, 12.4522199],
                [108.8639361, 12.4521721],
                [108.8639938, 12.4521312],
                [108.8640565, 12.4520979],
                [108.8642203, 12.4520302],
                [108.8643055, 12.4520049],
                [108.8644803, 12.4519722],
                [108.8649684, 12.4519722],
                [108.8651031, 12.451984],
                [108.8652335, 12.4520193],
                [108.8653571, 12.4520782],
                [108.8654692, 12.4521597],
                [108.8656095, 12.4523028],
                [108.8657132, 12.4523724],
                [108.8658297, 12.4524191],
                [108.8659466, 12.4524376],
                [108.8660568, 12.4524238],
                [108.8661094, 12.4524059],
                [108.8662045, 12.4523498],
                [108.8663077, 12.4522647],
                [108.866375, 12.4522232],
                [108.8665207, 12.4521613],
                [108.8666763, 12.4521296],
                [108.8667558, 12.4521255],
                [108.8669394, 12.4521422],
                [108.8671425, 12.4521942],
                [108.8673332, 12.4522798],
                [108.8674221, 12.4523345],
                [108.8676399, 12.4525012],
                [108.8678327, 12.4526128],
                [108.8680411, 12.4526933],
                [108.8683266, 12.4527602],
                [108.8685087, 12.4528307],
                [108.8686885, 12.4529371],
                [108.8689283, 12.4531372],
                [108.8690145, 12.453196],
                [108.869105, 12.4532482],
                [108.8692968, 12.4533319],
                [108.8694994, 12.4533863],
                [108.8698532, 12.4534347],
                [108.8701041, 12.4534595],
                [108.8706077, 12.4534855],
                [108.8699573, 12.453947],
                [108.8691293, 12.4545711],
                [108.8689005, 12.4547589],
                [108.8686216, 12.4550254],
                [108.8683671, 12.4553142],
                [108.8682089, 12.455516],
                [108.8681388, 12.4556232],
                [108.8680982, 12.4557055],
                [108.8680662, 12.4557913],
                [108.8680432, 12.4558799],
                [108.8680293, 12.4559703],
                [108.8680248, 12.4560615],
                [108.8680296, 12.4561527],
                [108.8680437, 12.4562431],
                [108.8680671, 12.4563315],
                [108.8680993, 12.4564173],
                [108.8681402, 12.4564995],
                [108.8681893, 12.4565773],
                [108.8683403, 12.4567599],
                [108.8686594, 12.4570785],
                [108.8687675, 12.4571971],
                [108.8689687, 12.4574466],
                [108.869193, 12.4577715],
                [108.869319, 12.4579694],
                [108.8694392, 12.4581707],
                [108.8696623, 12.4585827],
                [108.8696676, 12.460327],
                [108.8701075, 12.4615213],
                [108.8701471, 12.4620409],
                [108.8702095, 12.4625584],
                [108.8702543, 12.462847],
                [108.8703653, 12.4634206],
                [108.8704314, 12.4637053],
                [108.8707888, 12.4650779],
                [108.871017, 12.4659038],
                [108.8712214, 12.4667454],
                [108.8712764, 12.467032],
                [108.8713651, 12.467328],
                [108.8714915, 12.4676288],
                [108.8715505, 12.4677326],
                [108.8716174, 12.4678318],
                [108.8716918, 12.4679258],
                [108.8717733, 12.468014],
                [108.8718614, 12.4680958],
                [108.8719557, 12.468171],
                [108.8720554, 12.4682389],
                [108.8721602, 12.4682992],
                [108.8724515, 12.4684346],
                [108.8727581, 12.4685669],
                [108.8729166, 12.4686091],
                [108.873013, 12.4686214],
                [108.8731102, 12.4686242],
                [108.8732073, 12.4686173],
                [108.8733031, 12.4686009],
                [108.8733967, 12.4685751],
                [108.8734871, 12.4685402],
                [108.873571, 12.4684968],
                [108.873659, 12.4684618],
                [108.8738366, 12.4684188],
                [108.8740128, 12.4684092],
                [108.8740773, 12.4684182],
                [108.8742, 12.46846],
                [108.8742562, 12.4684922],
                [108.8743536, 12.4685761],
                [108.8744845, 12.4687654],
                [108.8745506, 12.4688437],
                [108.8746236, 12.4689159],
                [108.8747031, 12.4689813],
                [108.8747883, 12.4690395],
                [108.8750568, 12.4691759],
                [108.8751377, 12.4692248],
                [108.8753985, 12.4694132],
                [108.8755119, 12.4694839],
                [108.8756292, 12.469548],
                [108.875981, 12.4697065],
                [108.8761851, 12.4698245],
                [108.876282, 12.4698915],
                [108.8764564, 12.4700448],
                [108.876534, 12.4701309],
                [108.8766677, 12.4703192],
                [108.876723, 12.4704203],
                [108.8768215, 12.4706568],
                [108.8769021, 12.470927],
                [108.8769808, 12.4712672],
                [108.8770385, 12.4714676],
                [108.8771043, 12.4716656],
                [108.8774298, 12.4725541],
                [108.8775051, 12.4727881],
                [108.8776134, 12.4731981],
                [108.877694, 12.4736143],
                [108.877789, 12.4743727],
                [108.8778391, 12.4747097],
                [108.8780576, 12.4760564],
                [108.8781535, 12.4760394],
                [108.8782507, 12.476046],
                [108.8783074, 12.4760611],
                [108.8784107, 12.4761151],
                [108.8784932, 12.4761963],
                [108.8785935, 12.476347],
                [108.8786464, 12.4764694],
                [108.8786648, 12.4765388],
                [108.8786789, 12.4766814],
                [108.8786569, 12.4769805],
                [108.8786048, 12.477434],
                [108.8785941, 12.477587],
                [108.8785961, 12.4779705],
                [108.8786202, 12.4781231],
                [108.8786707, 12.4782707],
                [108.8787054, 12.4783408],
                [108.8788374, 12.4785462],
                [108.8789032, 12.4787078],
                [108.8789236, 12.4787925],
                [108.8789381, 12.4789658],
                [108.8789174, 12.4791385],
                [108.8788212, 12.4794259],
                [108.8787603, 12.4796757],
                [108.8787509, 12.4797585],
                [108.878751, 12.4799249],
                [108.8787764, 12.4800895],
                [108.8787864, 12.4802599],
                [108.87878, 12.4803451],
                [108.8787445, 12.4805122],
                [108.8786799, 12.4806708],
                [108.8786124, 12.480879],
                [108.8785688, 12.4809795],
                [108.8784629, 12.4811718],
                [108.8783238, 12.481361],
                [108.8782397, 12.4814538],
                [108.8780525, 12.4816207],
                [108.877843, 12.4817603],
                [108.8776242, 12.4818698],
                [108.8773937, 12.4819533],
                [108.877275, 12.4819849],
                [108.877033, 12.4820274],
                [108.8769122, 12.4821743],
                [108.8767521, 12.4823243],
                [108.8766527, 12.4823991],
                [108.8765477, 12.4824662],
                [108.8764375, 12.482525],
                [108.8763536, 12.4825608],
                [108.8761787, 12.4826135],
                [108.8758605, 12.4826608],
                [108.8757246, 12.482688],
                [108.8754575, 12.4827616],
                [108.8751787, 12.482854],
                [108.8748765, 12.4829283],
                [108.8748236, 12.4831054],
                [108.8746941, 12.483452],
                [108.8745794, 12.4837063],
                [108.8745332, 12.4837872],
                [108.8744796, 12.4838628],
                [108.8743515, 12.4839972],
                [108.8742781, 12.4840547],
                [108.8741161, 12.484148],
                [108.8739388, 12.4842093],
                [108.8736466, 12.4842704],
                [108.8730998, 12.4844082],
                [108.8727935, 12.4844585],
                [108.8722706, 12.4845037],
                [108.8718469, 12.4845664],
                [108.8714291, 12.4846594],
                [108.8710195, 12.4847824],
                [108.8705974, 12.4849382],
                [108.870483, 12.4849971],
                [108.8704285, 12.4850395],
                [108.8703802, 12.4850885],
                [108.8703052, 12.4852029],
                [108.8702365, 12.4854003],
                [108.8702012, 12.4854644],
                [108.8701579, 12.4855236],
                [108.8701072, 12.4855769],
                [108.87005, 12.4856236],
                [108.8699872, 12.4856627],
                [108.8698003, 12.4857408],
                [108.8696778, 12.4857793],
                [108.8695527, 12.4858093],
                [108.8694257, 12.4858303],
                [108.8692975, 12.4858425],
                [108.8691688, 12.4858456],
                [108.8689829, 12.4858639],
                [108.868922, 12.485877],
                [108.8688559, 12.485899],
                [108.868734, 12.4859647],
                [108.8686311, 12.4860563],
                [108.8685528, 12.4861688],
                [108.8685244, 12.486231],
                [108.8683684, 12.4867091],
                [108.8682868, 12.4868676],
                [108.8682349, 12.4869428],
                [108.8681121, 12.4870785],
                [108.8678772, 12.4872657],
                [108.8677166, 12.4874332],
                [108.8675845, 12.487623],
                [108.8674231, 12.4879522],
                [108.8673544, 12.4880701],
                [108.867196, 12.4882925],
                [108.8670113, 12.4884948],
                [108.8669099, 12.4885874],
                [108.8666764, 12.4887683],
                [108.8665458, 12.4888574],
                [108.8662331, 12.4890481],
                [108.8661486, 12.4891086],
                [108.8659538, 12.4892773],
                [108.8657838, 12.4894699],
                [108.8657115, 12.48954],
                [108.8656325, 12.4896028],
                [108.8655476, 12.4896578],
                [108.8654576, 12.4897044],
                [108.8653633, 12.4897422],
                [108.8652658, 12.4897708],
                [108.8651658, 12.48979],
                [108.8650644, 12.4897995],
                [108.8649625, 12.4897993],
                [108.8648611, 12.4897894],
                [108.8647163, 12.4901299],
                [108.8646376, 12.4902914],
                [108.8644642, 12.4906065],
                [108.8643721, 12.4908254],
                [108.8643378, 12.4909389],
                [108.8642933, 12.4911715],
                [108.8642737, 12.4915168],
                [108.8642301, 12.4918206],
                [108.8642284, 12.4919091],
                [108.8642465, 12.4920852],
                [108.8643128, 12.4923505],
                [108.8643241, 12.4924462],
                [108.8643193, 12.4926386],
                [108.8643232, 12.4927244],
                [108.8643366, 12.4928094],
                [108.8643593, 12.4928924],
                [108.8643911, 12.4929725],
                [108.8645316, 12.4932084],
                [108.8646135, 12.4933942],
                [108.8646665, 12.4935898],
                [108.8646895, 12.4937908],
                [108.8646828, 12.4940114],
                [108.8646442, 12.4942289],
                [108.8646132, 12.4943351],
                [108.8644969, 12.4946307],
                [108.8644732, 12.4947238],
                [108.8644506, 12.4949144],
                [108.8644614, 12.4951059],
                [108.8644793, 12.4952003],
                [108.8645393, 12.495383],
                [108.8646817, 12.4957086],
                [108.8648044, 12.4959456],
                [108.8649139, 12.4961139],
                [108.8650194, 12.4962446],
                [108.8651077, 12.496321],
                [108.8651599, 12.4963523],
                [108.8652156, 12.4963774],
                [108.8653341, 12.4964075],
                [108.8655787, 12.4964114],
                [108.8657004, 12.4964227],
                [108.8658207, 12.4964432],
                [108.8659391, 12.4964729],
                [108.8660547, 12.4965115],
                [108.8661668, 12.4965588],
                [108.8662748, 12.4966146],
                [108.866378, 12.4966784],
                [108.8664758, 12.4967499],
                [108.8668781, 12.4970013],
                [108.8691902, 12.4982164],
                [108.8704991, 12.4981012],
                [108.8707001, 12.4980944],
                [108.8708598, 12.498109],
                [108.8710356, 12.4981431],
                [108.8711604, 12.4981765],
                [108.8714023, 12.4982659],
                [108.871631, 12.498384],
                [108.8716862, 12.4984237],
                [108.8717858, 12.4985157],
                [108.871872, 12.4986311],
                [108.8719693, 12.4988578],
                [108.8720662, 12.4990199],
                [108.873412, 12.5006151],
                [108.8743812, 12.5006205],
                [108.8745065, 12.5015152],
                [108.874538, 12.5017134],
                [108.8745641, 12.5018036],
                [108.8745993, 12.5018908],
                [108.8746431, 12.5019741],
                [108.8747428, 12.5021119],
                [108.8748018, 12.5021737],
                [108.8749355, 12.5022805],
                [108.8749466, 12.5030451],
                [108.8748926, 12.5032179],
                [108.8748027, 12.503412],
                [108.874739, 12.5035001],
                [108.8746381, 12.5035855],
                [108.8745176, 12.5036419],
                [108.8743916, 12.5036808],
                [108.8743337, 12.5037106],
                [108.8742306, 12.503789],
                [108.8741491, 12.5038889],
                [108.874118, 12.5039451],
                [108.8740486, 12.5041432],
                [108.8740121, 12.5042168],
                [108.8739174, 12.5043513],
                [108.8737969, 12.5044644],
                [108.8736099, 12.5045989],
                [108.8734952, 12.5046909],
                [108.8732779, 12.5048886],
                [108.8732013, 12.5049472],
                [108.8730344, 12.5050447],
                [108.8729452, 12.5050829],
                [108.8727587, 12.5051368],
                [108.8725656, 12.5051595],
                [108.8722646, 12.5051483],
                [108.8721581, 12.5051538],
                [108.8719475, 12.5051871],
                [108.8718224, 12.5052377],
                [108.8717116, 12.5053136],
                [108.8716632, 12.50536],
                [108.8715996, 12.5054575],
                [108.8715672, 12.5055687],
                [108.8715688, 12.5056843],
                [108.8715824, 12.5057407],
                [108.8716393, 12.5058967],
                [108.8716881, 12.5061064],
                [108.8717075, 12.5063207],
                [108.8717108, 12.5065702],
                [108.8717421, 12.5068525],
                [108.8718852, 12.5075392],
                [108.8719422, 12.5079523],
                [108.8720054, 12.5085491],
                [108.8720441, 12.5090583],
                [108.8720594, 12.5093685],
                [108.8720683, 12.509679],
                [108.8720644, 12.5098861],
                [108.8720709, 12.5099896],
                [108.8720877, 12.5101226],
                [108.8721139, 12.5102542],
                [108.8721492, 12.5103838],
                [108.8721937, 12.5105106],
                [108.872247, 12.5106342],
                [108.8723088, 12.5107539],
                [108.872379, 12.5108691],
                [108.8725652, 12.5111225],
                [108.8726782, 12.511262],
                [108.8729185, 12.5115292],
                [108.8729238, 12.5118906],
                [108.8733262, 12.5122677],
                [108.8733613, 12.5123081],
                [108.8734174, 12.5123986],
                [108.8734394, 12.5124524],
                [108.8734635, 12.5125657],
                [108.8734563, 12.5128056],
                [108.8734645, 12.512992],
                [108.8734764, 12.5130532],
                [108.8734956, 12.5131089],
                [108.8735558, 12.5132103],
                [108.8735957, 12.5132542],
                [108.8736917, 12.5133246],
                [108.8738034, 12.5133676],
                [108.8739433, 12.5133837],
                [108.8740242, 12.5133802],
                [108.8742378, 12.5133512],
                [108.8745333, 12.5133235],
                [108.8746935, 12.5132993],
                [108.8750102, 12.5132322],
                [108.8751662, 12.5131894],
                [108.8759655, 12.5129485],
                [108.8760918, 12.5130509],
                [108.8763199, 12.5132185],
                [108.8764423, 12.5132751],
                [108.8765144, 12.5132952],
                [108.8766632, 12.51331],
                [108.8778967, 12.5132941],
                [108.8782453, 12.5136031],
                [108.8782561, 12.5141582],
                [108.8775533, 12.5151847],
                [108.877482, 12.5152403],
                [108.8773549, 12.515368],
                [108.8772998, 12.5154391],
                [108.877209, 12.5155936],
                [108.8771737, 12.5156759],
                [108.8771267, 12.5158419],
                [108.8771106, 12.5160075],
                [108.8771081, 12.5161909],
                [108.8770933, 12.5162903],
                [108.8770689, 12.5163879],
                [108.8770354, 12.5164828],
                [108.8769007, 12.5167469],
                [108.8768665, 12.5168352],
                [108.8768196, 12.5170182],
                [108.8768023, 12.5172061],
                [108.8767898, 12.5172739],
                [108.8767698, 12.51734],
                [108.8767087, 12.5174639],
                [108.8766147, 12.5175823],
                [108.8764908, 12.5176899],
                [108.876348, 12.5177723],
                [108.8761245, 12.5178688],
                [108.8759816, 12.5179421],
                [108.8758429, 12.5180226],
                [108.8755797, 12.5182048],
                [108.8754559, 12.5183059],
                [108.8753596, 12.5183955],
                [108.8752688, 12.5184905],
                [108.8751052, 12.5186952],
                [108.8749674, 12.5189172],
                [108.8747925, 12.5192507],
                [108.8746691, 12.5194637],
                [108.874436, 12.5198468],
                [108.8742882, 12.5201112],
                [108.8741938, 12.5203193],
                [108.8741522, 12.52044],
                [108.8740938, 12.520688],
                [108.8740128, 12.5213799],
                [108.8732243, 12.5221131],
                [108.8731702, 12.5221798],
                [108.8730848, 12.5223278],
                [108.8730308, 12.5224925],
                [108.8730253, 12.5225788],
                [108.87305, 12.5226797],
                [108.8731311, 12.5228134],
                [108.8731488, 12.5228622],
                [108.8731588, 12.522913],
                [108.8731552, 12.5230162],
                [108.8731205, 12.5231138],
                [108.8730191, 12.5232517],
                [108.8729612, 12.5233718],
                [108.8729321, 12.5235016],
                [108.8729333, 12.5236343],
                [108.8729812, 12.5238672],
                [108.8730746, 12.5241968],
                [108.8731961, 12.5245175],
                [108.8733254, 12.5247871],
                [108.8733476, 12.5248467],
                [108.8733692, 12.5249252],
                [108.873388, 12.5250865],
                [108.8733851, 12.5251678],
                [108.8733549, 12.5253274],
                [108.8733278, 12.5254043],
                [108.8732218, 12.5256142],
                [108.8732002, 12.5256827],
                [108.8731812, 12.5258247],
                [108.8731947, 12.5259673],
                [108.8733225, 12.5263716],
                [108.8734018, 12.5265211],
                [108.873452, 12.5265897],
                [108.8737999, 12.5269789],
                [108.8740019, 12.5271893],
                [108.8741799, 12.527424],
                [108.874259, 12.5275502],
                [108.8743937, 12.527815],
                [108.8745724, 12.5283039],
                [108.8746565, 12.5285117],
                [108.8747477, 12.5287166],
                [108.8748375, 12.5288965],
                [108.8750388, 12.529245],
                [108.8753325, 12.5296793],
                [108.8755202, 12.529942],
                [108.8757899, 12.5302716],
                [108.8760836, 12.5305809],
                [108.8764891, 12.5309568],
                [108.876743, 12.531182],
                [108.8772829, 12.531636],
                [108.8773822, 12.5317073],
                [108.8775694, 12.5318638],
                [108.8777572, 12.5320576],
                [108.8778175, 12.5321391],
                [108.87787, 12.5322256],
                [108.8779144, 12.5323164],
                [108.8779502, 12.5324106],
                [108.8779772, 12.5325077],
                [108.8779952, 12.5326067],
                [108.878031, 12.5329445],
                [108.8780669, 12.533181],
                [108.8781364, 12.5335332],
                [108.8782079, 12.5338004],
                [108.8783125, 12.5340965],
                [108.8784532, 12.5344055],
                [108.8786237, 12.5347375],
                [108.8786988, 12.5349127],
                [108.8787655, 12.5350912],
                [108.8789411, 12.5356756],
                [108.8790185, 12.5358921],
                [108.8791049, 12.5361053],
                [108.8792002, 12.5363149],
                [108.8793085, 12.5365359],
                [108.8795446, 12.5369681],
                [108.8798158, 12.5374054],
                [108.8801175, 12.5378492],
                [108.8801099, 12.537936],
                [108.8801207, 12.5381096],
                [108.8801652, 12.5382764],
                [108.8802409, 12.5384304],
                [108.8802868, 12.5384946],
                [108.8803985, 12.5386064],
                [108.8804631, 12.5386528],
                [108.8806873, 12.5387673],
                [108.8807646, 12.5388179],
                [108.8809036, 12.5389384],
                [108.8810182, 12.5390814],
                [108.8810651, 12.5391598],
                [108.8811566, 12.5393736],
                [108.8811904, 12.5394358],
                [108.88129, 12.5395715],
                [108.8813491, 12.5396318],
                [108.8814835, 12.5397349],
                [108.8815576, 12.5397767],
                [108.8817884, 12.5398739],
                [108.8818559, 12.5399169],
                [108.8819176, 12.5399675],
                [108.8819728, 12.5400249],
                [108.8820205, 12.5400883],
                [108.8820602, 12.5401568],
                [108.8821385, 12.5403669],
                [108.8822071, 12.5404817],
                [108.8822971, 12.5405815],
                [108.882441, 12.5407083],
                [108.8825273, 12.5407975],
                [108.8827587, 12.5410821],
                [108.8828411, 12.5411684],
                [108.8830207, 12.5413263],
                [108.8833394, 12.5415487],
                [108.8835679, 12.5417384],
                [108.8840026, 12.5421813],
                [108.8842059, 12.5423782],
                [108.8844749, 12.5426265],
                [108.8850119, 12.543083],
                [108.8856107, 12.5435517],
                [108.8860283, 12.5438373],
                [108.8862437, 12.5439706],
                [108.8866246, 12.5442219],
                [108.8867854, 12.5443206],
                [108.8871181, 12.5444995],
                [108.8872394, 12.5445486],
                [108.887364, 12.5445889],
                [108.8874914, 12.5446201],
                [108.8876208, 12.544642],
                [108.8877515, 12.5446546],
                [108.8878828, 12.5446577],
                [108.888014, 12.5446513],
                [108.8883358, 12.5446104],
                [108.8885403, 12.5446144],
                [108.8886417, 12.5446279],
                [108.8888398, 12.5446775],
                [108.8891485, 12.544803],
                [108.8892723, 12.5448415],
                [108.8893986, 12.5448712],
                [108.8895267, 12.5448922],
                [108.8896043, 12.5449144],
                [108.8897517, 12.5449789],
                [108.8898203, 12.5450208],
                [108.8899444, 12.5451218],
                [108.8901546, 12.5453563],
                [108.8903033, 12.5455383],
                [108.8907064, 12.5460943],
                [108.8911081, 12.5466155],
                [108.8913533, 12.5469864],
                [108.8915699, 12.5473739],
                [108.8916671, 12.5475732],
                [108.8917329, 12.5477303],
                [108.8917908, 12.5478904],
                [108.8918823, 12.5482178],
                [108.8919407, 12.5485524],
                [108.8919648, 12.5486524],
                [108.8920329, 12.5488467],
                [108.8920765, 12.5489402],
                [108.8921821, 12.5491179],
                [108.8923212, 12.5492897],
                [108.8924852, 12.5494392],
                [108.8926703, 12.549563],
                [108.8927944, 12.5496736],
                [108.8928918, 12.5498072],
                [108.8929291, 12.5498809],
                [108.8929788, 12.5500376],
                [108.8929906, 12.5501189],
                [108.8929876, 12.5502829],
                [108.8928783, 12.5507388],
                [108.8928332, 12.5510398],
                [108.8928118, 12.5513711],
                [108.8928086, 12.551641],
                [108.8928151, 12.5517308],
                [108.8928499, 12.5519005],
                [108.8929129, 12.5520624],
                [108.8929545, 12.5521389],
                [108.8930923, 12.5523372],
                [108.8931477, 12.5524586],
                [108.8931939, 12.5526321],
                [108.8932051, 12.5527211],
                [108.8932032, 12.5529003],
                [108.8931902, 12.5529891],
                [108.8930553, 12.5536163],
                [108.8929352, 12.5543483],
                [108.8928594, 12.5548672],
                [108.8927603, 12.5549024],
                [108.8926642, 12.5549127],
                [108.8925697, 12.5549013],
                [108.8924409, 12.5548659],
                [108.8923752, 12.5548568],
                [108.8923606, 12.5549879],
                [108.8923123, 12.5552474],
                [108.892237, 12.5555066],
                [108.8921472, 12.5557626],
                [108.8921243, 12.55587],
                [108.8921231, 12.5559777],
                [108.8921303, 12.5560853],
                [108.8921695, 12.5562972],
                [108.8922411, 12.5565009],
                [108.8922914, 12.5566034],
                [108.8923498, 12.5567016],
                [108.8924161, 12.556795],
                [108.8924899, 12.5568829],
                [108.8925706, 12.5569647],
                [108.8926577, 12.55704],
                [108.8927507, 12.5571083],
                [108.8927615, 12.5574591],
                [108.8928357, 12.5576381],
                [108.8929009, 12.5578204],
                [108.8929413, 12.5579501],
                [108.8930082, 12.5582131],
                [108.8931692, 12.5589985],
                [108.8932039, 12.5592211],
                [108.893255, 12.5596687],
                [108.8933116, 12.5600142],
                [108.8933945, 12.5603547],
                [108.89345, 12.5605367],
                [108.8935825, 12.5608937],
                [108.8936999, 12.5611545],
                [108.8938146, 12.5613667],
                [108.8938924, 12.5614902],
                [108.8940661, 12.5617254],
                [108.8942985, 12.5619878],
                [108.8944399, 12.5621352],
                [108.8947356, 12.5624176],
                [108.8950229, 12.5626543],
                [108.895328, 12.5628688],
                [108.8958076, 12.5631739],
                [108.896282, 12.563494],
                [108.8966168, 12.563748],
                [108.8969604, 12.5640518],
                [108.8972783, 12.5643811],
                [108.897067, 12.5643588],
                [108.8968545, 12.5643602],
                [108.8967232, 12.5643729],
                [108.8965932, 12.5643947],
                [108.8964651, 12.5644254],
                [108.8963395, 12.5644649],
                [108.8961668, 12.5645442],
                [108.8960855, 12.5645931],
                [108.8959354, 12.5647083],
                [108.8956261, 12.5650199],
                [108.8946122, 12.5660933],
                [108.8939202, 12.5667949],
                [108.8939255, 12.567219],
                [108.8932443, 12.5678473],
                [108.8932171, 12.5679435],
                [108.8931853, 12.5681405],
                [108.8931807, 12.5682494],
                [108.8931987, 12.5684663],
                [108.8932526, 12.5686776],
                [108.8933784, 12.5689783],
                [108.8934116, 12.5690802],
                [108.8934589, 12.569289],
                [108.8934803, 12.5695018],
                [108.8934886, 12.5697021],
                [108.8934832, 12.5701029],
                [108.8934696, 12.5703029],
                [108.893406, 12.5708312],
                [108.8933891, 12.5712192],
                [108.8932955, 12.5712812],
                [108.8931969, 12.5713354],
                [108.8929989, 12.5714156],
                [108.8928022, 12.5714627],
                [108.8926005, 12.571481],
                [108.8930404, 12.5715857],
                [108.8933408, 12.5716747],
                [108.8934803, 12.5717323],
                [108.8935477, 12.5717714],
                [108.8936666, 12.5718708],
                [108.8937592, 12.5719941],
                [108.8938071, 12.5720782],
                [108.8939233, 12.5723371],
                [108.8940275, 12.5724863],
                [108.8940567, 12.5725409],
                [108.8941228, 12.5727661],
                [108.8941622, 12.5728705],
                [108.8942097, 12.5729716],
                [108.8943279, 12.5731617],
                [108.8944049, 12.5732632],
                [108.8945759, 12.5734528],
                [108.8947678, 12.5736224],
                [108.8949287, 12.5737271],
                [108.8949198, 12.5739718],
                [108.8948825, 12.5742529],
                [108.8948486, 12.5744105],
                [108.8948055, 12.574566],
                [108.8947268, 12.5747951],
                [108.8946927, 12.5748685],
                [108.8946519, 12.5749373],
                [108.894551, 12.5750618],
                [108.8944274, 12.575165],
                [108.8942858, 12.575243],
                [108.8940789, 12.5753149],
                [108.8939456, 12.575351],
                [108.8938106, 12.57538],
                [108.8935367, 12.5754164],
                [108.8932604, 12.5754235],
                [108.8928205, 12.5754026],
                [108.8924366, 12.5753976],
                [108.8921489, 12.5754095],
                [108.891931, 12.5754357],
                [108.8916904, 12.5754908],
                [108.8914004, 12.575595],
                [108.8912858, 12.5756591],
                [108.8912032, 12.5757232],
                [108.89115, 12.5757874],
                [108.8911277, 12.5758306],
                [108.8911051, 12.5759245],
                [108.8911135, 12.5760206],
                [108.8911292, 12.5760665],
                [108.8912594, 12.5763345],
                [108.8913421, 12.5765325],
                [108.8916233, 12.5773169],
                [108.8917137, 12.5776064],
                [108.8917511, 12.5777706],
                [108.891779, 12.5779366],
                [108.8918225, 12.578405],
                [108.8918774, 12.5786679],
                [108.8919162, 12.5787966],
                [108.8920158, 12.5790466],
                [108.8921499, 12.5792979],
                [108.8921864, 12.5793808],
                [108.8922388, 12.5795537],
                [108.8922782, 12.5798143],
                [108.8923014, 12.5798944],
                [108.8923697, 12.5800467],
                [108.8924141, 12.5801178],
                [108.892522, 12.5802465],
                [108.8926732, 12.5803725],
                [108.8927676, 12.5804343],
                [108.892867, 12.5804881],
                [108.8930742, 12.5805705],
                [108.8931801, 12.5806003],
                [108.893397, 12.580638],
                [108.8936171, 12.5806457],
                [108.893887, 12.5806343],
                [108.8942064, 12.5806508],
                [108.8945224, 12.5806996],
                [108.8948314, 12.5807801],
                [108.8952641, 12.580935],
                [108.8958192, 12.5811618],
                [108.8960451, 12.5812468],
                [108.8966942, 12.5814571],
                [108.8970619, 12.581609],
                [108.8975841, 12.5818634],
                [108.8977881, 12.5819488],
                [108.8980999, 12.5820628],
                [108.8982063, 12.5820937],
                [108.898334, 12.5821222],
                [108.8984634, 12.5821417],
                [108.8985938, 12.5821522],
                [108.8987248, 12.5821537],
                [108.8989805, 12.5821301],
                [108.8992256, 12.5820728],
                [108.8992964, 12.5820639],
                [108.8994389, 12.5820674],
                [108.8995778, 12.5820991],
                [108.8997609, 12.582177],
                [108.8998747, 12.5822351],
                [108.8999449, 12.5822589],
                [108.9000912, 12.5822836],
                [108.90032, 12.5822769],
                [108.9004002, 12.5822847],
                [108.900556, 12.5823241],
                [108.900665, 12.5823846],
                [108.9007127, 12.5824246],
                [108.9007902, 12.5825208],
                [108.9008641, 12.5826756],
                [108.9009779, 12.5830191],
                [108.9010328, 12.5831507],
                [108.9011675, 12.5834026],
                [108.9013032, 12.5835919],
                [108.9013796, 12.5836802],
                [108.9015481, 12.5838424],
                [108.9021452, 12.5842933],
                [108.9023851, 12.5845011],
                [108.9025185, 12.5846318],
                [108.9028396, 12.5849871],
                [108.9030766, 12.5852082],
                [108.9033663, 12.585534],
                [108.9034828, 12.5856223],
                [108.9035497, 12.5856563],
                [108.9036205, 12.585682],
                [108.9036939, 12.585699],
                [108.9037689, 12.5857072],
                [108.9039351, 12.5857121],
                [108.9040251, 12.5857255],
                [108.9041998, 12.5857744],
                [108.9042834, 12.5858096],
                [108.9045534, 12.5859632],
                [108.9046712, 12.5860184],
                [108.9049172, 12.5861042],
                [108.9049991, 12.5861417],
                [108.9050764, 12.5861874],
                [108.9051483, 12.586241],
                [108.9052139, 12.5863018],
                [108.9052725, 12.586369],
                [108.9053234, 12.5864419],
                [108.9053661, 12.5865197],
                [108.9055073, 12.5868476],
                [108.9055892, 12.5870087],
                [108.9056786, 12.5871659],
                [108.905881, 12.5874744],
                [108.9059913, 12.5876267],
                [108.9062262, 12.5879209],
                [108.9067859, 12.5885481],
                [108.906925, 12.5887364],
                [108.9070308, 12.5889209],
                [108.9070949, 12.5890203],
                [108.9071671, 12.5891142],
                [108.907247, 12.589202],
                [108.907334, 12.5892832],
                [108.9074275, 12.589357],
                [108.9075269, 12.5894231],
                [108.9076316, 12.5894811],
                [108.9077944, 12.5895981],
                [108.9079374, 12.5897376],
                [108.9080393, 12.589858],
                [108.9080843, 12.5899544],
                [108.9080959, 12.5900063],
                [108.9080962, 12.5901122],
                [108.9080849, 12.5901642],
                [108.9080162, 12.5903351],
                [108.9078999, 12.59057],
                [108.9084309, 12.5909679],
                [108.9090103, 12.5914496],
                [108.9092195, 12.5915962],
                [108.9093202, 12.5916511],
                [108.9094253, 12.5916977],
                [108.909534, 12.5917356],
                [108.9096455, 12.5917646],
                [108.9097591, 12.5917845],
                [108.9099665, 12.5918103],
                [108.9101475, 12.5918579],
                [108.9103011, 12.5919294],
                [108.9104377, 12.5920282],
                [108.9104981, 12.5920868],
                [108.9106003, 12.5922195],
                [108.9106848, 12.5923869],
                [108.9107203, 12.5924845],
                [108.9107473, 12.5925847],
                [108.9107752, 12.5927898],
                [108.9107784, 12.5928824],
                [108.9107698, 12.5930673],
                [108.9107782, 12.5931238],
                [108.910796, 12.5931781],
                [108.9108226, 12.5932289],
                [108.9108574, 12.5932747],
                [108.9108995, 12.5933143],
                [108.9109476, 12.5933467],
                [108.9111357, 12.5934381],
                [108.9112677, 12.5935112],
                [108.9113962, 12.5935901],
                [108.9115208, 12.5936746],
                [108.9117386, 12.5938536],
                [108.9119325, 12.5940569],
                [108.9121439, 12.5943345],
                [108.9122611, 12.594507],
                [108.9123238, 12.5945803],
                [108.9123916, 12.594649],
                [108.9125409, 12.594772],
                [108.9127064, 12.5948735],
                [108.9128, 12.5949172],
                [108.9129968, 12.594981],
                [108.9132017, 12.5950121],
                [108.9134091, 12.5950096],
                [108.9140815, 12.5956835],
                [108.9145464, 12.5961823],
                [108.9149541, 12.5966012],
                [108.9150414, 12.5967021],
                [108.9152025, 12.5969143],
                [108.9153564, 12.5971561],
                [108.9154225, 12.5972473],
                [108.9154944, 12.5973343],
                [108.9156544, 12.5974939],
                [108.9160173, 12.5977718],
                [108.9161962, 12.5979167],
                [108.9162807, 12.5979946],
                [108.916464, 12.5981334],
                [108.916562, 12.5981938],
                [108.9167687, 12.5982956],
                [108.9169872, 12.5983707],
                [108.9172038, 12.5984632],
                [108.9173065, 12.5985204],
                [108.9174983, 12.5986555],
                [108.9178165, 12.5989552],
                [108.9179706, 12.5990883],
                [108.9182961, 12.599334],
                [108.9184491, 12.599456],
                [108.918596, 12.599585],
                [108.9192134, 12.600203],
                [108.9191066, 12.6001899],
                [108.918999, 12.6001864],
                [108.9188006, 12.6002054],
                [108.9186233, 12.6002518],
                [108.9185379, 12.600285],
                [108.9183766, 12.6003705],
                [108.9181352, 12.6005358],
                [108.9179148, 12.6007268],
                [108.9173906, 12.6012874],
                [108.9172822, 12.6014281],
                [108.9171993, 12.6015925],
                [108.9171499, 12.6017693],
                [108.9171359, 12.6019521],
                [108.9171423, 12.6020436],
                [108.9171818, 12.6022227],
                [108.9172554, 12.6023913],
                [108.9173549, 12.6025367],
                [108.9174144, 12.6026021],
                [108.9176136, 12.6027745],
                [108.9177214, 12.602908],
                [108.9178013, 12.603059],
                [108.9178299, 12.6031394],
                [108.9178628, 12.6033063],
                [108.9178648, 12.6035166],
                [108.9178528, 12.6036414],
                [108.917831, 12.6037649],
                [108.9177997, 12.6038864],
                [108.9177589, 12.6040053],
                [108.9177089, 12.6041207],
                [108.91765, 12.6042321],
                [108.9175087, 12.6044451],
                [108.917243, 12.6047831],
                [108.9171464, 12.6049235],
                [108.9170572, 12.6050683],
                [108.9168082, 12.6055459],
                [108.9167082, 12.6057181],
                [108.9166015, 12.6058865],
                [108.9161771, 12.60648],
                [108.915879, 12.6069245],
                [108.9156085, 12.6073856],
                [108.9154208, 12.6077626],
                [108.9153439, 12.6078968],
                [108.9152587, 12.6080261],
                [108.9151655, 12.6081501],
                [108.9150646, 12.6082682],
                [108.9149565, 12.60838],
                [108.9146971, 12.6086274],
                [108.9144283, 12.60893],
                [108.9142628, 12.6090984],
                [108.914122, 12.6092094],
                [108.9139456, 12.6093069],
                [108.9137804, 12.6094175],
                [108.9136371, 12.6095542],
                [108.9135199, 12.6097127],
                [108.9134323, 12.6098886],
                [108.9133405, 12.6101929],
                [108.9132965, 12.6103068],
                [108.9132693, 12.6104003],
                [108.913237, 12.6105919],
                [108.9132162, 12.6108555],
                [108.913191, 12.6110209],
                [108.9131565, 12.6111847],
                [108.913113, 12.6113464],
                [108.9130604, 12.6115056],
                [108.9128929, 12.6119365],
                [108.9128351, 12.6121652],
                [108.9119714, 12.6128196],
                [108.9116709, 12.6130348],
                [108.9115262, 12.6131494],
                [108.9113785, 12.6132745],
                [108.9110968, 12.613539],
                [108.9109629, 12.6136781],
                [108.9108823, 12.6137772],
                [108.9107418, 12.6139899],
                [108.9106826, 12.6141026],
                [108.9105876, 12.614338],
                [108.9105251, 12.6145836],
                [108.9105064, 12.6147089],
                [108.9104813, 12.615101],
                [108.9104386, 12.615262],
                [108.9104058, 12.6153389],
                [108.9103189, 12.615482],
                [108.9101443, 12.6156842],
                [108.9100163, 12.6158153],
                [108.9097414, 12.6160585],
                [108.909595, 12.6161699],
                [108.9090955, 12.6165211],
                [108.9089334, 12.6166479],
                [108.9084047, 12.6170964],
                [108.9083044, 12.6171954],
                [108.9081308, 12.6174034],
                [108.9079903, 12.6176222],
                [108.9078779, 12.617856],
                [108.9078329, 12.6179775],
                [108.9077657, 12.6182272],
                [108.9068699, 12.618557],
                [108.9067649, 12.6187129],
                [108.9065695, 12.6190334],
                [108.9063867, 12.619379],
                [108.9062262, 12.6197349],
                [108.9061435, 12.6200006],
                [108.906115, 12.6201366],
                [108.9060838, 12.6204126],
                [108.9060789, 12.6210333],
                [108.9060823, 12.6211538],
                [108.9061135, 12.6214257],
                [108.9061131, 12.6214851],
                [108.9060871, 12.6216008],
                [108.906062, 12.6216549],
                [108.9059901, 12.6217503],
                [108.9059257, 12.6218123],
                [108.905855, 12.6218673],
                [108.9057787, 12.6219147],
                [108.9056977, 12.6219541],
                [108.905613, 12.6219849],
                [108.9055253, 12.6220068],
                [108.9054358, 12.6220196],
                [108.9053455, 12.6220231],
                [108.9047752, 12.6220003],
                [108.904295, 12.6220068],
                [108.9042007, 12.6220653],
                [108.9040214, 12.6221952],
                [108.9038349, 12.6223616],
                [108.9036678, 12.6225466],
                [108.9035332, 12.6227344],
                [108.9032185, 12.6231011],
                [108.903022, 12.6233211],
                [108.9026105, 12.6237447],
                [108.9008725, 12.6239227],
                [108.9007632, 12.6240621],
                [108.900527, 12.6243267],
                [108.9002929, 12.6245494],
                [108.8999673, 12.6248218],
                [108.8997332, 12.6250586],
                [108.8996923, 12.625111],
                [108.8996298, 12.6252215],
                [108.8995903, 12.6253418],
                [108.8995624, 12.6254724],
                [108.8994965, 12.6256358],
                [108.8994445, 12.6257046],
                [108.8994048, 12.6257371],
                [108.8993112, 12.6257793],
                [108.8992083, 12.6257872],
                [108.8991091, 12.6257599],
                [108.8989407, 12.6256559],
                [108.8987052, 12.6254827],
                [108.8986038, 12.625418],
                [108.8984973, 12.6253616],
                [108.8983865, 12.625314],
                [108.898272, 12.6252754],
                [108.8981546, 12.6252461],
                [108.8980351, 12.6252263],
                [108.8979144, 12.6252161],
                [108.8975883, 12.6252075],
                [108.897384, 12.6251899],
                [108.8971807, 12.6251631],
                [108.8969789, 12.625127],
                [108.8967791, 12.6250817],
                [108.8966807, 12.6250719],
                [108.8965818, 12.6250701],
                [108.8963851, 12.62509],
                [108.896484, 12.6251733],
                [108.896668, 12.6253541],
                [108.8967526, 12.6254512],
                [108.8968974, 12.6256449],
                [108.8969519, 12.6257344],
                [108.8970423, 12.6259229],
                [108.8971415, 12.6262258],
                [108.8972247, 12.6264301],
                [108.897325, 12.6266073],
                [108.8973843, 12.6266904],
                [108.8975197, 12.6268437],
                [108.8977402, 12.6270464],
                [108.897981, 12.6272258],
                [108.8983887, 12.6274928],
                [108.8986945, 12.6277074],
                [108.8988116, 12.6277783],
                [108.8991772, 12.6279545],
                [108.8993487, 12.628052],
                [108.8994587, 12.6281368],
                [108.8995098, 12.6281891],
                [108.8995954, 12.628307],
                [108.899656, 12.6284387],
                [108.8996991, 12.6286123],
                [108.8997342, 12.6288043],
                [108.8997972, 12.6289709],
                [108.8998877, 12.629125],
                [108.9000034, 12.6292621],
                [108.9001, 12.6294014],
                [108.900221, 12.6296626],
                [108.9002803, 12.629766],
                [108.9003834, 12.6299124],
                [108.9004801, 12.6300198],
                [108.9005426, 12.6300771],
                [108.9007683, 12.6302464],
                [108.9008529, 12.6303199],
                [108.9010074, 12.6304812],
                [108.9011407, 12.6306597],
                [108.9013432, 12.6309871],
                [108.9014008, 12.6310595],
                [108.9015305, 12.6311919],
                [108.903603, 12.6329734],
                [108.9035995, 12.6330608],
                [108.9036137, 12.6332351],
                [108.9036317, 12.6333225],
                [108.9037189, 12.6335876],
                [108.9037424, 12.6336853],
                [108.9040242, 12.6337792],
                [108.9041609, 12.6338371],
                [108.9043486, 12.6339313],
                [108.9044813, 12.634027],
                [108.9045407, 12.6340829],
                [108.904711, 12.6342874],
                [108.9047842, 12.6343609],
                [108.9049463, 12.6344909],
                [108.9051264, 12.6345961],
                [108.9055163, 12.6347648],
                [108.905601, 12.6348161],
                [108.9056802, 12.6348752],
                [108.9057531, 12.6349415],
                [108.9058191, 12.6350145],
                [108.9058775, 12.6350934],
                [108.9058702, 12.6357246],
                [108.905874, 12.6358114],
                [108.9058989, 12.6359833],
                [108.9059604, 12.6361882],
                [108.9060795, 12.6364441],
                [108.9060987, 12.6365091],
                [108.9061343, 12.6367138],
                [108.906189, 12.6368486],
                [108.9062275, 12.6369107],
                [108.9063245, 12.6370205],
                [108.906513, 12.6371656],
                [108.9069092, 12.637427],
                [108.9070243, 12.6375157],
                [108.9072397, 12.63771],
                [108.9073879, 12.6378689],
                [108.9074935, 12.6380125],
                [108.9075903, 12.6382004],
                [108.9076838, 12.6384839],
                [108.9077218, 12.6385633],
                [108.9078183, 12.6387108],
                [108.9078761, 12.6387778],
                [108.9080086, 12.6388959],
                [108.9082068, 12.6390214],
                [108.9083361, 12.6390888],
                [108.9084698, 12.6391476],
                [108.9086071, 12.6391978],
                [108.9094154, 12.6394575],
                [108.9101055, 12.6396264],
                [108.9103505, 12.639698],
                [108.9105928, 12.6397782],
                [108.9109236, 12.6398584],
                [108.9112608, 12.6399074],
                [108.9117628, 12.639935],
                [108.9120841, 12.6399719],
                [108.9122678, 12.6400023],
                [108.9127717, 12.6401093],
                [108.9129837, 12.6401432],
                [108.9131536, 12.6401518],
                [108.9132519, 12.6401452],
                [108.9134448, 12.6401069],
                [108.9136279, 12.6400362],
                [108.9137139, 12.6399894],
                [108.9139843, 12.6398168],
                [108.914526, 12.6395093],
                [108.9148146, 12.6393071],
                [108.9151582, 12.6390283],
                [108.9154816, 12.6387274],
                [108.9156353, 12.6385691],
                [108.9159035, 12.6385588],
                [108.9161491, 12.6385732],
                [108.9163703, 12.6386057],
                [108.9164756, 12.6386296],
                [108.9166814, 12.6386947],
                [108.916742, 12.6387049],
                [108.9168648, 12.638703],
                [108.9169833, 12.6386717],
                [108.9170387, 12.6386455],
                [108.9172306, 12.6385051],
                [108.9174295, 12.6383844],
                [108.9176984, 12.6382588],
                [108.9179485, 12.6381553],
                [108.9181894, 12.6380743],
                [108.9183482, 12.6380332],
                [108.9185091, 12.6380009],
                [108.9186716, 12.6379776],
                [108.9187708, 12.6380866],
                [108.9188912, 12.6381803],
                [108.9189592, 12.6382201],
                [108.9191048, 12.6382813],
                [108.9193058, 12.6383291],
                [108.919432, 12.6383478],
                [108.9195592, 12.6383583],
                [108.9198142, 12.6383545],
                [108.9199678, 12.6383734],
                [108.9200425, 12.6383934],
                [108.9201844, 12.6384539],
                [108.9202593, 12.6384984],
                [108.9203949, 12.6386067],
                [108.9205078, 12.6387374],
                [108.9205545, 12.6388099],
                [108.9206133, 12.6389343],
                [108.9207378, 12.6393558],
                [108.9209054, 12.639793],
                [108.920952, 12.6399519],
                [108.9210533, 12.6403944],
                [108.9211029, 12.6404992],
                [108.9211371, 12.6405466],
                [108.9212304, 12.6406353],
                [108.9212896, 12.6406739],
                [108.9213535, 12.6407046],
                [108.9214208, 12.6407271],
                [108.9214907, 12.6407408],
                [108.9215617, 12.6407456],
                [108.9218662, 12.6407416],
                [108.9220798, 12.6407615],
                [108.9222145, 12.6407841],
                [108.9224788, 12.6408523],
                [108.9227331, 12.6409501],
                [108.9229739, 12.6410764],
                [108.9232206, 12.6412334],
                [108.9232994, 12.6412731],
                [108.9234664, 12.6413309],
                [108.9235532, 12.6413485],
                [108.9236423, 12.6413573],
                [108.9237317, 12.641357],
                [108.9238207, 12.6413474],
                [108.9239081, 12.6413289],
                [108.9239931, 12.6413014],
                [108.9240686, 12.6412683],
                [108.9242679, 12.6411492],
                [108.9243973, 12.641106],
                [108.9245332, 12.641092],
                [108.924669, 12.6411078],
                [108.924805, 12.6411081],
                [108.9249376, 12.6410784],
                [108.9250004, 12.6410527],
                [108.925115, 12.6409812],
                [108.9251653, 12.6409364],
                [108.9252486, 12.6408315],
                [108.9253406, 12.6406745],
                [108.925441, 12.6405336],
                [108.9255375, 12.6404357],
                [108.9256022, 12.6403883],
                [108.9256722, 12.6403488],
                [108.9258119, 12.6402999],
                [108.9259594, 12.6402839],
                [108.9261067, 12.6403017],
                [108.9261916, 12.6402875],
                [108.9262652, 12.6402438],
                [108.9262944, 12.6402127],
                [108.926332, 12.6401446],
                [108.9263663, 12.640123],
                [108.9263867, 12.6401204],
                [108.9264371, 12.6401339],
                [108.9265253, 12.640196],
                [108.9266111, 12.6402259],
                [108.9266566, 12.6402296],
                [108.9268392, 12.6402006],
                [108.9268936, 12.6402019],
                [108.9269471, 12.6402113],
                [108.9270467, 12.6402533],
                [108.9271286, 12.6403226],
                [108.9272553, 12.6404891],
                [108.9273137, 12.6405477],
                [108.9273558, 12.6406379],
                [108.9273588, 12.6406953],
                [108.927331, 12.6407651],
                [108.9273052, 12.6407932],
                [108.9272118, 12.6408636],
                [108.9271061, 12.6409791],
                [108.9270277, 12.6411137],
                [108.9269999, 12.6411863],
                [108.926979, 12.64131],
                [108.9269829, 12.6414041],
                [108.9270056, 12.6414813],
                [108.9270615, 12.6415625],
                [108.927142, 12.6416207],
                [108.9273902, 12.6417573],
                [108.9277911, 12.6419505],
                [108.9278831, 12.6419869],
                [108.9279628, 12.6420447],
                [108.9280007, 12.6420861],
                [108.9280562, 12.6421827],
                [108.9280725, 12.6422358],
                [108.9280905, 12.6424107],
                [108.9281313, 12.6425216],
                [108.9281617, 12.642571],
                [108.9282405, 12.6426566],
                [108.9283391, 12.6427199],
                [108.9285302, 12.6427836],
                [108.92868, 12.6428575],
                [108.9288141, 12.642956],
                [108.9289856, 12.6431303],
                [108.9290476, 12.6431793],
                [108.9291841, 12.6432599],
                [108.9293333, 12.643315],
                [108.9295569, 12.6433715],
                [108.9298403, 12.6434737],
                [108.9300187, 12.6435108],
                [108.9302011, 12.6435147],
                [108.9302917, 12.643504],
                [108.9305814, 12.6434312],
                [108.9306967, 12.6434134],
                [108.9308131, 12.6434047],
                [108.9309298, 12.6434051],
                [108.9310461, 12.6434146],
                [108.9311613, 12.6434332],
                [108.9312746, 12.6434607],
                [108.9313852, 12.6434969],
                [108.9314926, 12.6435417],
                [108.9316452, 12.6436341],
                [108.9317145, 12.64369],
                [108.9318359, 12.6438192],
                [108.9323407, 12.6444506],
                [108.9324543, 12.6445747],
                [108.9326982, 12.6448074],
                [108.9329624, 12.6450178],
                [108.9332479, 12.645203],
                [108.9333974, 12.6452849],
                [108.9337081, 12.6454261],
                [108.9339224, 12.6455488],
                [108.9340223, 12.6456209],
                [108.9342052, 12.6457849],
                [108.9343626, 12.6459725],
                [108.9345701, 12.6463074],
                [108.9347418, 12.6465536],
                [108.9350549, 12.6469459],
                [108.935591, 12.6475826],
                [108.9361386, 12.6483104],
                [108.936224, 12.6483992],
                [108.9362844, 12.6484461],
                [108.9364193, 12.6485191],
                [108.9365673, 12.6485614],
                [108.9366609, 12.6485741],
                [108.9367553, 12.6485774],
                [108.9368496, 12.6485715],
                [108.9369428, 12.6485562],
                [108.9370432, 12.6485308],
                [108.9371408, 12.6484966],
                [108.9372348, 12.6484538],
                [108.9373244, 12.6484029],
                [108.9374089, 12.6483442],
                [108.9376316, 12.6481422],
                [108.9377873, 12.64803],
                [108.9378705, 12.6479816],
                [108.9381008, 12.6478747],
                [108.9382485, 12.647819],
                [108.9385524, 12.647733],
                [108.9390679, 12.6476281],
                [108.9394265, 12.647546],
                [108.939836, 12.6474804],
                [108.9402492, 12.6474436],
                [108.9408803, 12.6474303],
                [108.9408347, 12.6475093],
                [108.940727, 12.647614],
                [108.9406905, 12.647664],
                [108.9406616, 12.6477185],
                [108.9406282, 12.6478365],
                [108.9406266, 12.6478858],
                [108.9406461, 12.6479824],
                [108.9406667, 12.6480274],
                [108.9407513, 12.6481444],
                [108.9407684, 12.6481861],
                [108.9407805, 12.6482748],
                [108.9407385, 12.6485029],
                [108.9407363, 12.6486931],
                [108.9407092, 12.6487854],
                [108.9406622, 12.6488937],
                [108.9406387, 12.649031],
                [108.9406405, 12.6491007],
                [108.9406789, 12.6492917],
                [108.9406797, 12.6493473],
                [108.9406598, 12.6494566],
                [108.9406128, 12.6495576],
                [108.9405388, 12.6496712],
                [108.940478, 12.6498176],
                [108.9404138, 12.6499347],
                [108.9402513, 12.6501306],
                [108.9401206, 12.6503322],
                [108.940022, 12.6505504],
                [108.9399815, 12.6507272],
                [108.9399762, 12.6509083],
                [108.9400061, 12.6510871],
                [108.940034, 12.6511735],
                [108.9401265, 12.6513998],
                [108.9402655, 12.6518157],
                [108.940317, 12.6519375],
                [108.9403777, 12.6521531],
                [108.940395, 12.6522636],
                [108.9404052, 12.6524311],
                [108.9403926, 12.6525646],
                [108.9403744, 12.6526408],
                [108.9403483, 12.6527148],
                [108.9402738, 12.6528531],
                [108.9402261, 12.652916],
                [108.9400908, 12.6530516],
                [108.9400043, 12.6531237],
                [108.9398172, 12.6532501],
                [108.9396143, 12.6533507],
                [108.939419, 12.6534674],
                [108.9393287, 12.6535362],
                [108.9391655, 12.6536929],
                [108.9390283, 12.6538718],
                [108.9389706, 12.6539683],
                [108.9388525, 12.6542091],
                [108.9388115, 12.6542749],
                [108.9387112, 12.6543936],
                [108.9385897, 12.6544917],
                [108.9384746, 12.654607],
                [108.9384269, 12.6546728],
                [108.9383539, 12.6548172],
                [108.9383131, 12.6549732],
                [108.9383065, 12.655134],
                [108.9383177, 12.6552986],
                [108.9383106, 12.655383],
                [108.9382708, 12.6555474],
                [108.9381619, 12.6557807],
                [108.9381315, 12.6558628],
                [108.9380747, 12.6560672],
                [108.9380428, 12.6561376],
                [108.9380022, 12.6562035],
                [108.9378512, 12.6563751],
                [108.9378113, 12.6564365],
                [108.9377785, 12.6565017],
                [108.9377356, 12.6566406],
                [108.9377247, 12.6567852],
                [108.9377528, 12.6571035],
                [108.9377432, 12.6573721],
                [108.9377254, 12.6575054],
                [108.9376435, 12.6578743],
                [108.9376406, 12.6580255],
                [108.9376506, 12.6581006],
                [108.9376929, 12.6582461],
                [108.9378034, 12.6584632],
                [108.9378354, 12.6585489],
                [108.9378752, 12.6587271],
                [108.9378853, 12.6588637],
                [108.9378688, 12.6589625],
                [108.9378472, 12.6590129],
                [108.9378174, 12.6590591],
                [108.9376873, 12.6591832],
                [108.9376445, 12.6592376],
                [108.937581, 12.6593599],
                [108.9375613, 12.6594258],
                [108.9375476, 12.6595622],
                [108.9375539, 12.6596305],
                [108.9375919, 12.6597624],
                [108.937631, 12.6598475],
                [108.9376783, 12.6599285],
                [108.9377336, 12.6600046],
                [108.9379282, 12.6602042],
                [108.9381297, 12.6604431],
                [108.9382238, 12.6605366],
                [108.9383243, 12.6606235],
                [108.9384308, 12.6607034],
                [108.938764, 12.660908],
                [108.9389587, 12.6610616],
                [108.9391315, 12.6612384],
                [108.9391763, 12.6612997],
                [108.9392495, 12.6614321],
                [108.9393164, 12.6615149],
                [108.9394022, 12.6615791],
                [108.9395681, 12.6616516],
                [108.9396302, 12.6616903],
                [108.9397377, 12.6617883],
                [108.9398178, 12.6619087],
                [108.9398658, 12.6620444],
                [108.9398787, 12.6621874],
                [108.9398181, 12.6626568],
                [108.9398086, 12.6627851],
                [108.9398121, 12.6630421],
                [108.9398459, 12.6632971],
                [108.9398888, 12.6634854],
                [108.9399375, 12.66361],
                [108.9400119, 12.6637276],
                [108.9401843, 12.6639131],
                [108.9402535, 12.6640028],
                [108.9403151, 12.6640976],
                [108.9403685, 12.664197],
                [108.9404648, 12.6643998],
                [108.9405858, 12.6645884],
                [108.940831, 12.6648838],
                [108.9410867, 12.6652358],
                [108.9411644, 12.6653244],
                [108.9413351, 12.6654876],
                [108.9415597, 12.6656609],
                [108.9416957, 12.6657553],
                [108.9417686, 12.6658215],
                [108.9418346, 12.6658942],
                [108.941893, 12.6659729],
                [108.9419432, 12.6660568],
                [108.9419849, 12.666145],
                [108.9420175, 12.6662368],
                [108.942043, 12.6663362],
                [108.9420591, 12.6664374],
                [108.9420657, 12.6665397],
                [108.9420628, 12.6666421],
                [108.9420105, 12.6669594],
                [108.9420005, 12.6670757],
                [108.9419984, 12.6671924],
                [108.9420175, 12.6674249],
                [108.9420411, 12.6675466],
                [108.942074, 12.6676662],
                [108.9421161, 12.6677831],
                [108.9422653, 12.6680948],
                [108.9423202, 12.6682802],
                [108.942361, 12.6685912],
                [108.9423834, 12.6687097],
                [108.9424771, 12.6690704],
                [108.9424999, 12.6691996],
                [108.9425148, 12.6693299],
                [108.9425272, 12.6696597],
                [108.9425425, 12.6697706],
                [108.9425666, 12.66988],
                [108.9426404, 12.6700916],
                [108.9427468, 12.6702896],
                [108.9428934, 12.6704856],
                [108.9429817, 12.6705841],
                [108.9432489, 12.6708381],
                [108.9433776, 12.6709999],
                [108.9438575, 12.6718213],
                [108.9439823, 12.6719884],
                [108.9441258, 12.6721405],
                [108.9442157, 12.6722645],
                [108.9442514, 12.6723321],
                [108.94433, 12.6725334],
                [108.9444059, 12.6726369],
                [108.9444534, 12.6726807],
                [108.9445634, 12.6727489],
                [108.9446876, 12.6727878],
                [108.9448833, 12.6728013],
                [108.9449478, 12.672815],
                [108.9450699, 12.6728628],
                [108.9451328, 12.6729009],
                [108.9451903, 12.6729463],
                [108.9452415, 12.6729985],
                [108.9452856, 12.6730564],
                [108.9453901, 12.673222],
                [108.9454659, 12.6733194],
                [108.945549, 12.673411],
                [108.9456389, 12.6734962],
                [108.9457351, 12.6735746],
                [108.9458686, 12.6737132],
                [108.9459799, 12.6738695],
                [108.9460264, 12.6739532],
                [108.9460999, 12.6741294],
                [108.9461661, 12.6742444],
                [108.9462731, 12.6743703],
                [108.9464903, 12.6745685],
                [108.9465519, 12.674642],
                [108.9466061, 12.6747209],
                [108.9466831, 12.6748749],
                [108.9467262, 12.675022],
                [108.9467595, 12.6752747],
                [108.9467711, 12.6754527],
                [108.946773, 12.6756311],
                [108.9467653, 12.6758093],
                [108.946748, 12.6759868],
                [108.946721, 12.6761632],
                [108.9466846, 12.676338],
                [108.9466738, 12.6764511],
                [108.9466728, 12.6765646],
                [108.9466815, 12.6766779],
                [108.9466999, 12.6767901],
                [108.9467279, 12.6769003],
                [108.9467653, 12.6770079],
                [108.9469017, 12.6772893],
                [108.946985, 12.677437],
                [108.9470991, 12.6775898],
                [108.9472331, 12.6777263],
                [108.9494795, 12.6795514],
                [108.9497007, 12.6797224],
                [108.9501554, 12.6800486],
                [108.9504155, 12.6802212],
                [108.9509493, 12.6805458],
                [108.9512097, 12.6808681],
                [108.9517655, 12.6815927],
                [108.9519633, 12.6818865],
                [108.9520585, 12.6820484],
                [108.9522276, 12.6823832],
                [108.952471, 12.6829836],
                [108.9525363, 12.6831097],
                [108.9526904, 12.6833488],
                [108.9527786, 12.6834609],
                [108.9529702, 12.6836718],
                [108.9530734, 12.6837704],
                [108.9533884, 12.6840372],
                [108.9534783, 12.6841266],
                [108.9536422, 12.6843192],
                [108.9537303, 12.6844393],
                [108.9538882, 12.6846912],
                [108.9539576, 12.6848223],
                [108.9541484, 12.6852461],
                [108.9542282, 12.6853946],
                [108.9544113, 12.6856783],
                [108.9546239, 12.6859416],
                [108.9548263, 12.686171],
                [108.9552462, 12.6866167],
                [108.9553866, 12.6867787],
                [108.9555198, 12.6869464],
                [108.9558474, 12.6867973],
                [108.9564926, 12.6864792],
                [108.9571237, 12.6861352],
                [108.95775, 12.6857594],
                [108.9583594, 12.685358],
                [108.9585957, 12.6852123],
                [108.9590806, 12.6849409],
                [108.9593288, 12.6848153],
                [108.9597987, 12.6846007],
                [108.9602233, 12.6844406],
                [108.9606357, 12.6843164],
                [108.9610559, 12.6842198],
                [108.9614644, 12.684154],
                [108.9617733, 12.6841218],
                [108.9620546, 12.6841099],
                [108.9624103, 12.6841235],
                [108.9627633, 12.684169],
                [108.9629378, 12.6842036],
                [108.9633364, 12.6843078],
                [108.9635603, 12.6843764],
                [108.9638922, 12.6844907],
                [108.964152, 12.6846018],
                [108.964299, 12.6846779],
                [108.9645791, 12.684853],
                [108.9649948, 12.6851807],
                [108.9653206, 12.685413],
                [108.9655145, 12.6855371],
                [108.9658131, 12.6857113],
                [108.965916, 12.6857636],
                [108.966163, 12.6858644],
                [108.9664203, 12.6859363],
                [108.9666232, 12.6859633],
                [108.9667256, 12.6859664],
                [108.9668792, 12.6859592],
                [108.9669999, 12.685936],
                [108.9670679, 12.6859131],
                [108.967195, 12.6858477],
                [108.9672528, 12.6858058],
                [108.9673058, 12.6857585],
                [108.9674393, 12.6856052],
                [108.9676563, 12.6853034],
                [108.9678024, 12.6851353],
                [108.9679781, 12.6849861],
                [108.9681752, 12.6848649],
                [108.968389, 12.6847745],
                [108.9687444, 12.6846445],
                [108.9692814, 12.68446],
                [108.9696892, 12.6843364],
                [108.9701486, 12.6842198],
                [108.970327, 12.6841658],
                [108.9705891, 12.6840689],
                [108.9707893, 12.6839736],
                [108.971008, 12.6838387],
                [108.971209, 12.6836796],
                [108.9713431, 12.6835464],
                [108.971436, 12.6834661],
                [108.9715798, 12.6833828],
                [108.9716578, 12.6833529],
                [108.9718213, 12.6833187],
                [108.971905, 12.6833147],
                [108.9722943, 12.6833248],
                [108.9724103, 12.6833341],
                [108.9725253, 12.6833512],
                [108.9728653, 12.6834331],
                [108.9729818, 12.6834492],
                [108.9733567, 12.6834621],
                [108.9734958, 12.6834769],
                [108.9736336, 12.6835],
                [108.9739037, 12.6835708],
                [108.9740213, 12.6835951],
                [108.9741406, 12.6836104],
                [108.9742607, 12.6836165],
                [108.974381, 12.6836135],
                [108.9745006, 12.6836013],
                [108.9746189, 12.6835801],
                [108.974954, 12.6834966],
                [108.975395, 12.6834033],
                [108.975628, 12.683366],
                [108.9758623, 12.6833381],
                [108.9760197, 12.6833309],
                [108.9761982, 12.6832972],
                [108.9763914, 12.683227],
                [108.9764825, 12.6831799],
                [108.9767245, 12.6830193],
                [108.9768032, 12.6829835],
                [108.9768854, 12.6829563],
                [108.9769702, 12.6829379],
                [108.9770565, 12.6829286],
                [108.9773087, 12.682937],
                [108.9774645, 12.6829112],
                [108.9775393, 12.682886],
                [108.9776784, 12.682813],
                [108.9777411, 12.6827659],
                [108.9779235, 12.6826046],
                [108.9780548, 12.6825038],
                [108.9781916, 12.6824103],
                [108.9783336, 12.6823244],
                [108.9784804, 12.6822464],
                [108.9786775, 12.6824648],
                [108.9790535, 12.6829166],
                [108.9792355, 12.6831544],
                [108.9795792, 12.6836441],
                [108.9796296, 12.6837295],
                [108.979708, 12.683911],
                [108.979783, 12.6842254],
                [108.9798118, 12.6842967],
                [108.9798477, 12.6843648],
                [108.9799355, 12.6844847],
                [108.9799856, 12.684536],
                [108.9804263, 12.6848805],
                [108.9806118, 12.6850383],
                [108.980891, 12.6853002],
                [108.9810222, 12.6854392],
                [108.9812637, 12.6857275],
                [108.9813754, 12.6858785],
                [108.9815801, 12.6861928],
                [108.9816643, 12.6863066],
                [108.9817561, 12.6864147],
                [108.9818551, 12.6865164],
                [108.9826906, 12.6872395],
                [108.9832973, 12.6877911],
                [108.983463, 12.6879564],
                [108.9835522, 12.6880596],
                [108.9837127, 12.6882791],
                [108.9838481, 12.6885143],
                [108.9839566, 12.6887624],
                [108.9842808, 12.6888749],
                [108.984597, 12.6890126],
                [108.9847517, 12.6890908],
                [108.9849776, 12.6892188],
                [108.9851766, 12.6893507],
                [108.9852977, 12.6894427],
                [108.9855115, 12.6895689],
                [108.9856249, 12.6896202],
                [108.9858618, 12.6896978],
                [108.9861077, 12.689741],
                [108.9862241, 12.6897491],
                [108.9864571, 12.6897421],
                [108.9866871, 12.6897044],
                [108.9869685, 12.6896132],
                [108.9871064, 12.6896015],
                [108.9871754, 12.6896081],
                [108.9873084, 12.6896453],
                [108.9874283, 12.6897128],
                [108.987529, 12.6898039],
                [108.9875729, 12.6898548],
                [108.9876473, 12.6899661],
                [108.9877274, 12.6900468],
                [108.987828, 12.6901017],
                [108.9878833, 12.6901178],
                [108.987939, 12.6901247],
                [108.9880503, 12.6901121],
                [108.9881028, 12.6900928],
                [108.9882826, 12.6899868],
                [108.9884099, 12.6899025],
                [108.988537, 12.6900459],
                [108.9886441, 12.690185],
                [108.9887431, 12.6903297],
                [108.9888337, 12.6904796],
                [108.9889155, 12.6906341],
                [108.9889884, 12.6907929],
                [108.9890507, 12.6909517],
                [108.9891039, 12.6911136],
                [108.9891481, 12.691278],
                [108.9892278, 12.6916795],
                [108.9892459, 12.6917559],
                [108.9892646, 12.6918202],
                [108.9892862, 12.6918836],
                [108.9893857, 12.6920988],
                [108.9894515, 12.6923265],
                [108.9895206, 12.6927512],
                [108.9895356, 12.6929438],
                [108.9895247, 12.6931538],
                [108.9894772, 12.6934251],
                [108.9894377, 12.6935908],
                [108.9893357, 12.693916],
                [108.9892469, 12.6941258],
                [108.9891922, 12.694226],
                [108.9890762, 12.6943982],
                [108.9889767, 12.6945185],
                [108.9888797, 12.6946069],
                [108.9887436, 12.6946888],
                [108.9885928, 12.6947403],
                [108.9884341, 12.6947591],
                [108.9882749, 12.6947441],
                [108.9880818, 12.6949168],
                [108.9878994, 12.6950424],
                [108.987843, 12.6950923],
                [108.9877932, 12.6951486],
                [108.9877509, 12.6952104],
                [108.9877166, 12.6952767],
                [108.9876909, 12.6953467],
                [108.9876527, 12.6954977],
                [108.9876319, 12.6955325],
                [108.9875734, 12.695589],
                [108.9874986, 12.6956223],
                [108.9872333, 12.6956417],
                [108.9870229, 12.695684],
                [108.986966, 12.695707],
                [108.9869073, 12.6957612],
                [108.986876, 12.6958339],
                [108.9868724, 12.6958734],
                [108.9868903, 12.6959503],
                [108.9869384, 12.6960136],
                [108.9870201, 12.6960791],
                [108.9870627, 12.6961272],
                [108.9870987, 12.6961804],
                [108.9871479, 12.696298],
                [108.9871645, 12.696424],
                [108.9871497, 12.6965707],
                [108.9871018, 12.6967105],
                [108.9870231, 12.6968364],
                [108.9866525, 12.6972073],
                [108.9866066, 12.6972665],
                [108.9865427, 12.6973878],
                [108.986508, 12.6975199],
                [108.9865022, 12.6975879],
                [108.986514, 12.6977237],
                [108.9865623, 12.6978739],
                [108.9866017, 12.6979544],
                [108.9866495, 12.6980306],
                [108.9868115, 12.6982235],
                [108.9868819, 12.6983482],
                [108.9869268, 12.6984837],
                [108.9869659, 12.6986709],
                [108.9869999, 12.6987861],
                [108.9870894, 12.6990092],
                [108.9873002, 12.699162],
                [108.9875774, 12.6994058],
                [108.9876874, 12.6995383],
                [108.987736, 12.6996171],
                [108.9877765, 12.6997002],
                [108.9878085, 12.6997868],
                [108.9878316, 12.6998759],
                [108.9878551, 12.7000819],
                [108.9878551, 12.7001972],
                [108.9878458, 12.7003123],
                [108.9880013, 12.7005477],
                [108.9878511, 12.7007152],
                [108.9876891, 12.7008637],
                [108.9875732, 12.7009495],
                [108.9874526, 12.7010167],
                [108.9873236, 12.7010554],
                [108.987189, 12.701066],
                [108.9870378, 12.7010481],
                [108.9868984, 12.7010215],
                [108.9867836, 12.7010187],
                [108.9867178, 12.7010278],
                [108.986593, 12.701072],
                [108.9865365, 12.7011062],
                [108.9864404, 12.7011955],
                [108.9863782, 12.7012972],
                [108.986331, 12.7014533],
                [108.9863066, 12.7015033],
                [108.9862761, 12.70155],
                [108.9861507, 12.7016858],
                [108.9861093, 12.7017456],
                [108.9860487, 12.701877],
                [108.986033, 12.7019399],
                [108.986025, 12.702069],
                [108.986033, 12.7021333],
                [108.9860786, 12.7023069],
                [108.9860888, 12.7024346],
                [108.9860838, 12.7025053],
                [108.9860712, 12.7025752],
                [108.9860239, 12.7027092],
                [108.9859318, 12.7028602],
                [108.985887, 12.7029711],
                [108.9858723, 12.7030363],
                [108.9858663, 12.7031695],
                [108.9859829, 12.7032043],
                [108.9862204, 12.7032585],
                [108.9863395, 12.7032776],
                [108.9865798, 12.7033004],
                [108.9866615, 12.7033161],
                [108.9867412, 12.7033395],
                [108.9868917, 12.7034086],
                [108.9869611, 12.7034536],
                [108.9870846, 12.7035622],
                [108.9872203, 12.7037334],
                [108.9874595, 12.7041115],
                [108.987522, 12.7041874],
                [108.9875914, 12.7042573],
                [108.9876672, 12.7043206],
                [108.9877486, 12.7043769],
                [108.987835, 12.7044255],
                [108.9879974, 12.7044986],
                [108.9881625, 12.7045656],
                [108.9885, 12.7046809],
                [108.9888503, 12.7047718],
                [108.9890303, 12.7048077],
                [108.989394, 12.7048588],
                [108.9898035, 12.7048871],
                [108.9916988, 12.7049632],
                [108.9918905, 12.7062309],
                [108.9919243, 12.7063945],
                [108.9919613, 12.7065139],
                [108.9920067, 12.7066306],
                [108.9921218, 12.7068533],
                [108.9922687, 12.7070599],
                [108.9923537, 12.7071559],
                [108.9924454, 12.7072458],
                [108.9926471, 12.7074056],
                [108.9925829, 12.7078048],
                [108.9925578, 12.7078958],
                [108.9925235, 12.707984],
                [108.9924796, 12.7080701],
                [108.9922915, 12.708345],
                [108.9922338, 12.7084483],
                [108.9921719, 12.7086426],
                [108.9921433, 12.7088455],
                [108.9921419, 12.7089487],
                [108.9921493, 12.7090516],
                [108.9921903, 12.709254],
                [108.992265, 12.7094469],
                [108.992377, 12.7096379],
                [108.9924466, 12.7097332],
                [108.9926049, 12.7099087],
                [108.9927864, 12.7100615],
                [108.9928326, 12.7102693],
                [108.9929398, 12.7106511],
                [108.9929742, 12.7107365],
                [108.993007, 12.7107899],
                [108.993047, 12.7108385],
                [108.9931451, 12.7109179],
                [108.9932613, 12.7109688],
                [108.9933872, 12.7109877],
                [108.993237, 12.711265],
                [108.9931614, 12.7114304],
                [108.993094, 12.7115992],
                [108.993006, 12.711857],
                [108.9929719, 12.7120365],
                [108.9929676, 12.7121285],
                [108.9929749, 12.7122665],
                [108.9930033, 12.7123579],
                [108.9930616, 12.712439],
                [108.9931433, 12.7124977],
                [108.9932399, 12.712528],
                [108.9933781, 12.7125435],
                [108.9935478, 12.7125908],
                [108.9937088, 12.7126699],
                [108.993784, 12.712722],
                [108.9938534, 12.7127812],
                [108.9939163, 12.712847],
                [108.9940199, 12.7129878],
                [108.9941275, 12.7131175],
                [108.9942735, 12.7132545],
                [108.994437, 12.713371],
                [108.99463, 12.7134876],
                [108.9948302, 12.7136356],
                [108.9948892, 12.7136953],
                [108.9949862, 12.7138312],
                [108.9950517, 12.713984],
                [108.9951253, 12.7142426],
                [108.9951568, 12.7143117],
                [108.9952407, 12.7144387],
                [108.9952922, 12.7144952],
                [108.9954118, 12.7145912],
                [108.9956273, 12.7147048],
                [108.995769, 12.714813],
                [108.9956211, 12.7148215],
                [108.9955491, 12.7148391],
                [108.9954791, 12.7148656],
                [108.9954131, 12.7149003],
                [108.9953521, 12.7149428],
                [108.995297, 12.7149925],
                [108.9952032, 12.7151105],
                [108.995163, 12.715176],
                [108.9950997, 12.7153155],
                [108.9950353, 12.7155388],
                [108.9949709, 12.7158436],
                [108.9949322, 12.7161526],
                [108.994267, 12.716545],
                [108.9942563, 12.7168538],
                [108.9942777, 12.7171363],
                [108.9941329, 12.7173456],
                [108.994077, 12.7174449],
                [108.993983, 12.717652],
                [108.9939451, 12.717759],
                [108.9938608, 12.7180651],
                [108.9938175, 12.7181857],
                [108.9936047, 12.7186445],
                [108.993575, 12.7187219],
                [108.9935427, 12.7188281],
                [108.9935094, 12.718991],
                [108.9935073, 12.7191055],
                [108.9935162, 12.7191641],
                [108.9935535, 12.7192765],
                [108.9935837, 12.7193334],
                [108.9936204, 12.7193866],
                [108.9937109, 12.7194791],
                [108.9937637, 12.7195172],
                [108.9938807, 12.7195748],
                [108.9938632, 12.719692],
                [108.99387, 12.7198103],
                [108.9939076, 12.7199411],
                [108.9939724, 12.7200502],
                [108.9940148, 12.7200979],
                [108.9942078, 12.7202739],
                [108.9944995, 12.720512],
                [108.9946451, 12.7206169],
                [108.9949483, 12.7208097],
                [108.9926684, 12.7229395],
                [108.9923251, 12.7229395],
                [108.9920783, 12.7230964],
                [108.9918003, 12.7232402],
                [108.9915836, 12.7233317],
                [108.9913812, 12.7233964],
                [108.9912505, 12.7234262],
                [108.9909842, 12.7234596],
                [108.9907157, 12.7234575],
                [108.9906256, 12.7234638],
                [108.990449, 12.7235],
                [108.9902819, 12.7235668],
                [108.9902037, 12.723611],
                [108.9900613, 12.7237191],
                [108.9900151, 12.723771],
                [108.9899451, 12.7238898],
                [108.9899225, 12.7239549],
                [108.989904, 12.7240907],
                [108.9899084, 12.7241593],
                [108.9899494, 12.724399],
                [108.9899537, 12.7245307],
                [108.9899465, 12.7246042],
                [108.9899325, 12.7246767],
                [108.9899133, 12.7247294],
                [108.9898869, 12.7247792],
                [108.989815, 12.7248661],
                [108.989722, 12.7249312],
                [108.9896069, 12.7249873],
                [108.9895473, 12.7250256],
                [108.9893659, 12.7251752],
                [108.9892078, 12.7252774],
                [108.9890367, 12.725357],
                [108.9889248, 12.7253965],
                [108.9888103, 12.7254285],
                [108.988576, 12.725469],
                [108.9883443, 12.7254777],
                [108.9881194, 12.7254564],
                [108.9881106, 12.7255241],
                [108.9881086, 12.7256605],
                [108.9881676, 12.7260267],
                [108.9881966, 12.7263797],
                [108.9881839, 12.726531],
                [108.9881374, 12.7266863],
                [108.9881019, 12.7267596],
                [108.9880085, 12.7268933],
                [108.9878084, 12.7270807],
                [108.9876634, 12.7272459],
                [108.9875187, 12.7273932],
                [108.9872814, 12.7275916],
                [108.9872109, 12.7276657],
                [108.9870894, 12.727829],
                [108.986996, 12.7280092],
                [108.9869085, 12.7282644],
                [108.986865, 12.728427],
                [108.986804, 12.7287579],
                [108.9867783, 12.7290931],
                [108.9867843, 12.7293061],
                [108.9868135, 12.7297504],
                [108.9868139, 12.730192],
                [108.9868051, 12.730464],
                [108.9868158, 12.7308198],
                [108.9868094, 12.7309237],
                [108.9867718, 12.7311284],
                [108.9866957, 12.7313916],
                [108.9866575, 12.7315568],
                [108.9866022, 12.7318913],
                [108.986544, 12.7325149],
                [108.98651, 12.7329704],
                [108.9864564, 12.7334465],
                [108.9864282, 12.7338647],
                [108.986429, 12.7342837],
                [108.9864597, 12.7347322],
                [108.9865194, 12.7352085],
                [108.9866066, 12.7356808],
                [108.9864405, 12.7358291],
                [108.9861238, 12.7361412],
                [108.9859596, 12.7363205],
                [108.9856517, 12.7366959],
                [108.9855566, 12.7368322],
                [108.9853873, 12.7371176],
                [108.9853134, 12.737266],
                [108.9851882, 12.7375722],
                [108.9851371, 12.7377294],
                [108.9850013, 12.7382525],
                [108.9848118, 12.7391071],
                [108.984789, 12.7392301],
                [108.984761, 12.7394757],
                [108.9847476, 12.7398453],
                [108.9847052, 12.7400926],
                [108.9846291, 12.7403321],
                [108.9844651, 12.7407124],
                [108.984413, 12.7408894],
                [108.9843942, 12.7410654],
                [108.9844074, 12.7412585],
                [108.9844272, 12.7413618],
                [108.984493, 12.741562],
                [108.9844547, 12.7416856],
                [108.9843643, 12.7419282],
                [108.9842979, 12.742078],
                [108.9841443, 12.7423677],
                [108.9842441, 12.7425033],
                [108.9843965, 12.7426608],
                [108.9843428, 12.7442828],
                [108.9845004, 12.7444338],
                [108.9846754, 12.7445653],
                [108.9847804, 12.74463],
                [108.9851192, 12.7447986],
                [108.9852315, 12.7448664],
                [108.9854419, 12.7450221],
                [108.9856712, 12.7452176],
                [108.9859521, 12.7454129],
                [108.9864219, 12.7456993],
                [108.9866624, 12.7458333],
                [108.9871538, 12.7460827],
                [108.987297, 12.7461659],
                [108.987569, 12.746354],
                [108.987819, 12.7465693],
                [108.9879944, 12.7466957],
                [108.988185, 12.7467992],
                [108.988465, 12.7469148],
                [108.9885877, 12.7469881],
                [108.9886264, 12.7470382],
                [108.988643, 12.7471053],
                [108.9886294, 12.7471731],
                [108.9886119, 12.7472033],
                [108.9885221, 12.7472848],
                [108.9884919, 12.7473261],
                [108.9884697, 12.7473718],
                [108.9884514, 12.7474711],
                [108.9884695, 12.7475705],
                [108.9885491, 12.7477088],
                [108.9885795, 12.7478202],
                [108.9885756, 12.7479355],
                [108.9885189, 12.7481081],
                [108.9885058, 12.7482393],
                [108.9885261, 12.7483696],
                [108.9885762, 12.7485242],
                [108.988598, 12.748618],
                [108.9886665, 12.7491016],
                [108.9886781, 12.749235],
                [108.9886802, 12.7493688],
                [108.9886728, 12.7495025],
                [108.9886302, 12.749872],
                [108.9885749, 12.7506221],
                [108.9885828, 12.7508917],
                [108.9886061, 12.7510879],
                [108.9886451, 12.7512577],
                [108.9886803, 12.7513604],
                [108.9887748, 12.7515563],
                [108.9888995, 12.7517353],
                [108.9891194, 12.7519721],
                [108.9892733, 12.7521207],
                [108.9894335, 12.7522627],
                [108.9895999, 12.7523979],
                [108.9897183, 12.7524813],
                [108.9899681, 12.7526287],
                [108.9902329, 12.7527484],
                [108.9903274, 12.752799],
                [108.9904174, 12.7528568],
                [108.9905816, 12.7529928],
                [108.9906547, 12.7530701],
                [108.9907806, 12.7532406],
                [108.9908767, 12.7534286],
                [108.9909761, 12.7535871],
                [108.9911006, 12.7537279],
                [108.9913219, 12.7539142],
                [108.9913906, 12.7539874],
                [108.991507, 12.7541501],
                [108.9915537, 12.7542383],
                [108.9916224, 12.754425],
                [108.9916703, 12.7546773],
                [108.9916876, 12.7548337],
                [108.9916957, 12.7549909],
                [108.9916945, 12.7551482],
                [108.9916616, 12.7556259],
                [108.9916925, 12.7559008],
                [108.9917536, 12.7561574],
                [108.9918393, 12.7563932],
                [108.9918867, 12.7564706],
                [108.9919051, 12.756572],
                [108.9919066, 12.7567038],
                [108.9918733, 12.7568377],
                [108.9918065, 12.7569591],
                [108.9916491, 12.7571643],
                [108.9916106, 12.757235],
                [108.9915581, 12.7573865],
                [108.9915447, 12.7574655],
                [108.9915444, 12.7576254],
                [108.9915794, 12.7577816],
                [108.9915854, 12.7578388],
                [108.9915831, 12.7578963],
                [108.9915541, 12.7580075],
                [108.9914948, 12.7581066],
                [108.9914097, 12.7581857],
                [108.9912477, 12.7582801],
                [108.9911888, 12.7583071],
                [108.9910819, 12.7583851],
                [108.9910364, 12.7584355],
                [108.9909985, 12.7584915],
                [108.990969, 12.7585521],
                [108.9909484, 12.7586162],
                [108.9909373, 12.7586824],
                [108.9909414, 12.7588456],
                [108.9909566, 12.7589406],
                [108.9909811, 12.7590338],
                [108.9910145, 12.7591242],
                [108.9910568, 12.7592111],
                [108.9911073, 12.7592937],
                [108.9911345, 12.7593568],
                [108.9911652, 12.7594902],
                [108.9911633, 12.7596268],
                [108.9911068, 12.759853],
                [108.9910941, 12.7599484],
                [108.9910909, 12.7600444],
                [108.9910971, 12.7601404],
                [108.9911128, 12.7602353],
                [108.9911377, 12.7603283],
                [108.9911717, 12.7604185],
                [108.9912951, 12.7607011],
                [108.9913305, 12.7608035],
                [108.9914043, 12.761131],
                [108.9914419, 12.7612446],
                [108.9914882, 12.7613551],
                [108.9914992, 12.7614143],
                [108.9914954, 12.7615344],
                [108.9914578, 12.7616487],
                [108.9913892, 12.7617485],
                [108.9913449, 12.7617904],
                [108.9911916, 12.7619059],
                [108.9909985, 12.7620816],
                [108.9909095, 12.7621768],
                [108.9907479, 12.7623805],
                [108.9906051, 12.7625345],
                [108.9904433, 12.7626696],
                [108.9902651, 12.7627834],
                [108.9901102, 12.7629073],
                [108.9900421, 12.7629791],
                [108.9899516, 12.7630964],
                [108.9899272, 12.7631391],
                [108.9899033, 12.7631976],
                [108.9898884, 12.7632589],
                [108.9898827, 12.7633217],
                [108.9898865, 12.7633845],
                [108.9899312, 12.7635573],
                [108.9899244, 12.7636623],
                [108.9898841, 12.7637599],
                [108.9897667, 12.7638978],
                [108.9896937, 12.7640274],
                [108.9896694, 12.7640976],
                [108.989647, 12.764244],
                [108.9896604, 12.7643913],
                [108.9897181, 12.7645712],
                [108.9897639, 12.7646766],
                [108.9898789, 12.7648761],
                [108.9899319, 12.765019],
                [108.9899501, 12.7651699],
                [108.9899404, 12.7652835],
                [108.9899139, 12.7653819],
                [108.9898886, 12.7654406],
                [108.9898192, 12.7655483],
                [108.9897278, 12.765639],
                [108.9896033, 12.7657335],
                [108.9895011, 12.7658252],
                [108.9894416, 12.7659055],
                [108.9894194, 12.7659551],
                [108.9893991, 12.7660612],
                [108.9894122, 12.7661684],
                [108.9894135, 12.766222],
                [108.9894055, 12.7662751],
                [108.9893885, 12.7663262],
                [108.9893628, 12.7663736],
                [108.9893293, 12.7664162],
                [108.9892889, 12.7664527],
                [108.9891263, 12.7665383],
                [108.9890645, 12.7665799],
                [108.9888681, 12.7667634],
                [108.9887747, 12.76684],
                [108.9886764, 12.7669104],
                [108.9884668, 12.7670319],
                [108.9882427, 12.7671258],
                [108.9879591, 12.7672159],
                [108.9877424, 12.7672708],
                [108.9875246, 12.7672972],
                [108.9874848, 12.7673139],
                [108.9874178, 12.7673668],
                [108.9873749, 12.7674397],
                [108.9873643, 12.7674806],
                [108.9873669, 12.7675646],
                [108.9874003, 12.7676421],
                [108.9874518, 12.7677162],
                [108.9874693, 12.7677604],
                [108.9874822, 12.7678541],
                [108.9874288, 12.7681498],
                [108.9874149, 12.7684571],
                [108.987379, 12.7686581],
                [108.9873147, 12.7688523],
                [108.9872702, 12.769028],
                [108.9872365, 12.7692808],
                [108.987187, 12.7694206],
                [108.9871519, 12.7694862],
                [108.9870626, 12.7696056],
                [108.9869128, 12.7697608],
                [108.9866812, 12.7699595],
                [108.9866218, 12.7700255],
                [108.986465, 12.7702451],
                [108.9864035, 12.770315],
                [108.9862647, 12.7704398],
                [108.9860427, 12.7705915],
                [108.9859302, 12.7707085],
                [108.9858448, 12.7708456],
                [108.9858107, 12.7709264],
                [108.9857856, 12.7710103],
                [108.9857698, 12.7710963],
                [108.9857635, 12.7711835],
                [108.9857668, 12.7712708],
                [108.9857797, 12.7713573],
                [108.9858314, 12.7715208],
                [108.9859129, 12.7716682],
                [108.9859913, 12.7717682],
                [108.9860655, 12.7718304],
                [108.9861647, 12.7718764],
                [108.986273, 12.7718938],
                [108.986382, 12.7718812],
                [108.9865384, 12.7718203],
                [108.9865956, 12.7718081],
                [108.986654, 12.7718033],
                [108.9867703, 12.7718156],
                [108.9868795, 12.7718566],
                [108.9869743, 12.7719234],
                [108.9870563, 12.7720205],
                [108.9870908, 12.7720806],
                [108.9871174, 12.7721445],
                [108.9871452, 12.7722793],
                [108.987146, 12.7723482],
                [108.9871211, 12.7724835],
                [108.9870958, 12.7725479],
                [108.986986, 12.7727391],
                [108.986904, 12.7728664],
                [108.9867246, 12.7731109],
                [108.9866821, 12.7731836],
                [108.9866463, 12.7732597],
                [108.9866038, 12.7733783],
                [108.986594, 12.7734713],
                [108.9866011, 12.7735226],
                [108.9866409, 12.7736183],
                [108.9867107, 12.7736958],
                [108.9868029, 12.7737465],
                [108.9868541, 12.77376],
                [108.9869799, 12.7737766],
                [108.9871216, 12.7738172],
                [108.9872583, 12.7738855],
                [108.9873784, 12.7739789],
                [108.9874308, 12.7740339],
                [108.9875173, 12.7741577],
                [108.9875769, 12.7742964],
                [108.9876057, 12.7744447],
                [108.987608, 12.7745202],
                [108.9875705, 12.774799],
                [108.9875583, 12.775059],
                [108.9875685, 12.7752541],
                [108.9875976, 12.775407],
                [108.9876261, 12.7754931],
                [108.9877075, 12.7756555],
                [108.9877883, 12.7757659],
                [108.9878705, 12.7758494],
                [108.9879262, 12.7758941],
                [108.988004, 12.7759883],
                [108.9880329, 12.7760419],
                [108.9880683, 12.7761577],
                [108.9880742, 12.776218],
                [108.9880621, 12.7763383],
                [108.9880018, 12.7765178],
                [108.9879531, 12.776637],
                [108.9879307, 12.7767233],
                [108.9879181, 12.7768115],
                [108.9879153, 12.7769005],
                [108.9879224, 12.7769892],
                [108.9879392, 12.7770767],
                [108.9879656, 12.7771619],
                [108.9880554, 12.7773472],
                [108.9881178, 12.7774461],
                [108.988188, 12.7775398],
                [108.9882655, 12.7776278],
                [108.98835, 12.7777095],
                [108.9883682, 12.7779708],
                [108.9883661, 12.7781018],
                [108.9883403, 12.7783586],
                [108.9883166, 12.7784856],
                [108.988215, 12.7788748],
                [108.9881914, 12.7790165],
                [108.9881774, 12.7791594],
                [108.988173, 12.7793029],
                [108.9881767, 12.7795715],
                [108.9881556, 12.7798211],
                [108.9881324, 12.7799228],
                [108.9880629, 12.7801198],
                [108.9880169, 12.7802139],
                [108.9879023, 12.7804093],
                [108.9878683, 12.7805255],
                [108.9878636, 12.7806463],
                [108.9878887, 12.7807647],
                [108.987923, 12.7808421],
                [108.987996, 12.7809662],
                [108.9880361, 12.781077],
                [108.9880431, 12.7811356],
                [108.9880302, 12.7812524],
                [108.9880106, 12.7813081],
                [108.9879472, 12.781408],
                [108.9879048, 12.78145],
                [108.9878015, 12.7815303],
                [108.9877042, 12.7816323],
                [108.9876189, 12.7817568],
                [108.9873969, 12.7820441],
                [108.9872103, 12.782244],
                [108.9870014, 12.7824217],
                [108.9868894, 12.7825015],
                [108.9865873, 12.7826852],
                [108.9862706, 12.7829232],
                [108.9861506, 12.7830038],
                [108.9860473, 12.7830675],
                [108.985939, 12.7831229],
                [108.9858266, 12.7831698],
                [108.9857107, 12.7832078],
                [108.9855876, 12.7832375],
                [108.985346, 12.7833124],
                [108.9850821, 12.783417],
                [108.9850253, 12.7834311],
                [108.9849085, 12.7834382],
                [108.9847934, 12.7834171],
                [108.9846793, 12.7833647],
                [108.984628, 12.7833285],
                [108.9845818, 12.7832865],
                [108.9845086, 12.7831899],
                [108.9844608, 12.7830822],
                [108.9844225, 12.7829563],
                [108.9843576, 12.7828411],
                [108.984316, 12.7827893],
                [108.9842169, 12.782701],
                [108.9841601, 12.782666],
                [108.9840357, 12.7826169],
                [108.9839699, 12.7826035],
                [108.9839029, 12.7825978],
                [108.9837692, 12.7826097],
                [108.9836405, 12.7826527],
                [108.9835053, 12.7826791],
                [108.9832608, 12.7826645],
                [108.9831729, 12.7826709],
                [108.9830862, 12.7826864],
                [108.9830017, 12.7827108],
                [108.9827307, 12.7828105],
                [108.9825582, 12.7828394],
                [108.9823828, 12.7828355],
                [108.9822961, 12.7828212],
                [108.9821207, 12.782809],
                [108.9817691, 12.782809],
                [108.9815938, 12.7828212],
                [108.9812241, 12.782865],
                [108.9811258, 12.782867],
                [108.980874, 12.782851],
                [108.9807495, 12.782855],
                [108.9807035, 12.7828662],
                [108.9806212, 12.7829118],
                [108.9805609, 12.7829828],
                [108.9805316, 12.7830637],
                [108.9805321, 12.7831495],
                [108.9805624, 12.7832301],
                [108.9806168, 12.7832942],
                [108.9806873, 12.7833363],
                [108.980872, 12.7834955],
                [108.9809461, 12.783572],
                [108.9810127, 12.7836548],
                [108.9810713, 12.7837432],
                [108.9811212, 12.7838364],
                [108.9811622, 12.7839338],
                [108.9811939, 12.7840344],
                [108.981216, 12.7841375],
                [108.9812384, 12.784347],
                [108.9812386, 12.7844524],
                [108.981224, 12.7846176],
                [108.9812285, 12.7847384],
                [108.9812583, 12.7848557],
                [108.9813189, 12.7850091],
                [108.9813373, 12.7850894],
                [108.9813495, 12.7852533],
                [108.9813389, 12.7853643],
                [108.9812904, 12.7855821],
                [108.9812148, 12.7857735],
                [108.9810411, 12.7861427],
                [108.9808925, 12.7865505],
                [108.9808371, 12.7866735],
                [108.9808106, 12.7867082],
                [108.9807776, 12.7867371],
                [108.9806974, 12.7867731],
                [108.9806092, 12.7867756],
                [108.9804929, 12.7867345],
                [108.9803961, 12.7866841],
                [108.9802788, 12.7866031],
                [108.9801371, 12.7864774],
                [108.9799306, 12.7862531],
                [108.9796985, 12.786054],
                [108.9795738, 12.7859648],
                [108.9793163, 12.7857974],
                [108.9791329, 12.7856654],
                [108.9785646, 12.78523],
                [108.9780654, 12.7848583],
                [108.9779842, 12.7848048],
                [108.9778985, 12.7847585],
                [108.9776717, 12.7846654],
                [108.9776072, 12.7846298],
                [108.9774281, 12.7845045],
                [108.977294, 12.7844477],
                [108.9772466, 12.7844353],
                [108.9771977, 12.7844322],
                [108.9771491, 12.7844384],
                [108.9771027, 12.7844537],
                [108.9770603, 12.7844776],
                [108.9770234, 12.7845091],
                [108.9769936, 12.784547],
                [108.9769518, 12.7846463],
                [108.976934, 12.7847581],
                [108.9769453, 12.7848766],
                [108.9769371, 12.7850613],
                [108.9769025, 12.7852431],
                [108.9768317, 12.7854424],
                [108.9767794, 12.7855495],
                [108.9767187, 12.7856523],
                [108.9766502, 12.7857503],
                [108.9764786, 12.7859639],
                [108.9763345, 12.7861678],
                [108.9762628, 12.7862552],
                [108.9761835, 12.7863362],
                [108.9761171, 12.7863943],
                [108.9759698, 12.7864921],
                [108.97589, 12.786531],
                [108.9757214, 12.7865873],
                [108.9756339, 12.7866042],
                [108.9754626, 12.7866151],
                [108.9752156, 12.7865997],
                [108.9749475, 12.7865671],
                [108.9747991, 12.7865613],
                [108.974757, 12.7865708],
                [108.974718, 12.7865892],
                [108.9746843, 12.7866156],
                [108.9746573, 12.7866487],
                [108.9746309, 12.7867148],
                [108.9746316, 12.786921],
                [108.9746573, 12.7871044],
                [108.9747142, 12.7873888],
                [108.9747201, 12.7874719],
                [108.9747138, 12.7876154],
                [108.9747179, 12.7876758],
                [108.9747311, 12.7877349],
                [108.9747531, 12.7877915],
                [108.9747836, 12.7878442],
                [108.9748217, 12.787892],
                [108.9748667, 12.7879337],
                [108.9749175, 12.7879684],
                [108.9749731, 12.7879953],
                [108.9750321, 12.7880139],
                [108.9751428, 12.7880678],
                [108.9752347, 12.7881207],
                [108.9754069, 12.7882434],
                [108.9754865, 12.7883127],
                [108.9756054, 12.7884356],
                [108.9756432, 12.7884891],
                [108.9756979, 12.7886075],
                [108.9757139, 12.7886706],
                [108.9757222, 12.7888002],
                [108.975702, 12.7889267],
                [108.9756447, 12.7891214],
                [108.9756379, 12.7892597],
                [108.9756569, 12.7893701],
                [108.9757201, 12.7895311],
                [108.9757391, 12.7896182],
                [108.9757306, 12.7897068],
                [108.9757162, 12.7897491],
                [108.9756766, 12.7898155],
                [108.9756078, 12.7898851],
                [108.9755549, 12.7899238],
                [108.9754971, 12.7899551],
                [108.9753712, 12.7899937],
                [108.9752394, 12.7899981],
                [108.9750485, 12.7899689],
                [108.9748553, 12.7899728],
                [108.9746657, 12.7900096],
                [108.9743274, 12.7901289],
                [108.9742157, 12.7901543],
                [108.9739879, 12.7901796],
                [108.9738732, 12.7901795],
                [108.9736685, 12.7901578],
                [108.973236, 12.7900486],
                [108.9728061, 12.7899754],
                [108.9727162, 12.7899723],
                [108.9726265, 12.7899784],
                [108.972538, 12.7899937],
                [108.9724516, 12.790018],
                [108.9723683, 12.790051],
                [108.972289, 12.7900923],
                [108.9722007, 12.790152],
                [108.9721173, 12.7902179],
                [108.9720054, 12.7903273],
                [108.9719567, 12.7903884],
                [108.9718804, 12.7905122],
                [108.9718559, 12.7905749],
                [108.9718403, 12.7906403],
                [108.9718339, 12.7907072],
                [108.9718369, 12.7907743],
                [108.9718491, 12.7908404],
                [108.97189, 12.7909475],
                [108.9719582, 12.7910505],
                [108.9720934, 12.7911964],
                [108.9724591, 12.7915536],
                [108.9725568, 12.7916245],
                [108.9726692, 12.7916702],
                [108.972907, 12.7917018],
                [108.9730775, 12.7917559],
                [108.9731553, 12.791794],
                [108.9733726, 12.7919408],
                [108.9734517, 12.7919835],
                [108.9737052, 12.7920801],
                [108.973743, 12.7921056],
                [108.9738034, 12.7921728],
                [108.9738242, 12.7922128],
                [108.9738446, 12.7922999],
                [108.9738334, 12.7923983],
                [108.973791, 12.7924882],
                [108.9737339, 12.7925511],
                [108.973684, 12.7926195],
                [108.973642, 12.7926928],
                [108.9736082, 12.79277],
                [108.9735832, 12.7928504],
                [108.9735672, 12.7929329],
                [108.9735603, 12.7930166],
                [108.9735711, 12.7931661],
                [108.9735873, 12.7932394],
                [108.9736408, 12.7933799],
                [108.9736776, 12.7934458],
                [108.9737695, 12.7935659],
                [108.9738344, 12.7936282],
                [108.9739058, 12.7936834],
                [108.9739828, 12.7937308],
                [108.9740646, 12.7937699],
                [108.9742325, 12.7938203],
                [108.9743972, 12.7938797],
                [108.9745716, 12.7939604],
                [108.9747287, 12.7940699],
                [108.9747991, 12.7941345],
                [108.9749208, 12.7942806],
                [108.9749712, 12.794361],
                [108.9750367, 12.7945],
                [108.9751857, 12.7947691],
                [108.9753604, 12.7950285],
                [108.9755269, 12.795293],
                [108.9756851, 12.7955624],
                [108.9758796, 12.7959221],
                [108.9759169, 12.7960113],
                [108.9759683, 12.7961971],
                [108.9759876, 12.7963886],
                [108.9759698, 12.796666],
                [108.9759789, 12.7970848],
                [108.9759515, 12.7973952],
                [108.9758976, 12.7976856],
                [108.9758613, 12.7978289],
                [108.9757342, 12.7982437],
                [108.9756694, 12.7985869],
                [108.9756391, 12.7986858],
                [108.9755552, 12.7988751],
                [108.9755022, 12.7989645],
                [108.9752823, 12.7992626],
                [108.9748698, 12.7998718],
                [108.9747247, 12.8000525],
                [108.9745581, 12.8002147],
                [108.9744622, 12.8002921],
                [108.9741799, 12.8004998],
                [108.9740894, 12.8005578],
                [108.9740124, 12.8005998],
                [108.973931, 12.8006331],
                [108.9738463, 12.8006574],
                [108.9737593, 12.8006723],
                [108.9736712, 12.8006776],
                [108.9735831, 12.8006733],
                [108.973496, 12.8006593],
                [108.9732645, 12.8005823],
                [108.9730453, 12.8004762],
                [108.9729548, 12.8004215],
                [108.9726947, 12.8002408],
                [108.9723641, 12.7999793],
                [108.9720573, 12.7996987],
                [108.9719975, 12.7996541],
                [108.9718651, 12.7995847],
                [108.971794, 12.7995606],
                [108.9716461, 12.7995351],
                [108.9715708, 12.7995339],
                [108.971422, 12.7995547],
                [108.9711946, 12.7996373],
                [108.970921, 12.7997073],
                [108.970774, 12.7997666],
                [108.9707067, 12.7998082],
                [108.970589, 12.7999127],
                [108.9705049, 12.8000297],
                [108.9704555, 12.800151],
                [108.9704362, 12.8002801],
                [108.9704479, 12.8004092],
                [108.9704666, 12.8004711],
                [108.970494, 12.8005298],
                [108.9705294, 12.8005841],
                [108.9705723, 12.8006332],
                [108.9707231, 12.8007488],
                [108.9710111, 12.800927],
                [108.9711088, 12.8009994],
                [108.9712458, 12.8011181],
                [108.971389, 12.8012298],
                [108.971785, 12.8015014],
                [108.9719, 12.8015959],
                [108.9720087, 12.8016972],
                [108.9721108, 12.801805],
                [108.9722057, 12.8019187],
                [108.9722932, 12.802038],
                [108.9723729, 12.8021624],
                [108.9724445, 12.8022914],
                [108.9725969, 12.8025995],
                [108.9727752, 12.8028941],
                [108.972978, 12.8031732],
                [108.9733198, 12.8035759],
                [108.973425, 12.8037188],
                [108.9736129, 12.804019],
                [108.9737694, 12.8043359],
                [108.9738929, 12.8046663],
                [108.9739513, 12.804893],
                [108.9739741, 12.8051256],
                [108.9739611, 12.805359],
                [108.9739123, 12.8055878],
                [108.973829, 12.8058069],
                [108.9737006, 12.80605],
                [108.9736555, 12.8062003],
                [108.9736414, 12.8063562],
                [108.9736608, 12.8065207],
                [108.973666, 12.806608],
                [108.9736619, 12.8066954],
                [108.9736483, 12.8067818],
                [108.9736256, 12.8068664],
                [108.973594, 12.8069482],
                [108.9735537, 12.8070264],
                [108.9735052, 12.8070999],
                [108.9734491, 12.8071682],
                [108.973386, 12.8072303],
                [108.9733166, 12.8072857],
                [108.9732416, 12.8073336],
                [108.9731618, 12.8073737],
                [108.9730782, 12.8074053],
                [108.9727917, 12.8074668],
                [108.9726948, 12.807498],
                [108.9725106, 12.8075834],
                [108.9723434, 12.8076972],
                [108.9721978, 12.8078363],
                [108.9720515, 12.8080332],
                [108.9719352, 12.8082482],
                [108.971851, 12.808477],
                [108.9718008, 12.8087151],
                [108.9717887, 12.8088361],
                [108.9717909, 12.8090791],
                [108.9718052, 12.8091999],
                [108.9719174, 12.809756],
                [108.9719651, 12.8100766],
                [108.9720028, 12.8103985],
                [108.9720306, 12.8107213],
                [108.9720484, 12.8110447],
                [108.9720562, 12.8113686],
                [108.9720539, 12.8116925],
                [108.9720417, 12.8120163],
                [108.9720194, 12.8123395],
                [108.9719871, 12.8126619],
                [108.9719449, 12.8129832],
                [108.9718928, 12.8133032],
                [108.9718308, 12.8136214],
                [108.971759, 12.8139377],
                [108.9716774, 12.8142517],
                [108.9715862, 12.8145632],
                [108.9714925, 12.8148511],
                [108.9712804, 12.8154185],
                [108.9711622, 12.8156977],
                [108.9709018, 12.8162457],
                [108.9706698, 12.8162734],
                [108.9702035, 12.8163049],
                [108.9697362, 12.816304],
                [108.9695028, 12.8162915],
                [108.9690381, 12.8162423],
                [108.9683333, 12.8161215],
                [108.9680943, 12.8160914],
                [108.9676139, 12.8160553],
                [108.9673729, 12.8160494],
                [108.9668912, 12.8160618],
                [108.9661831, 12.8161239],
                [108.9659483, 12.8161344],
                [108.9654783, 12.8161329],
                [108.9652436, 12.8161207],
                [108.9647604, 12.8160718],
                [108.9645128, 12.816033],
                [108.964267, 12.8159847],
                [108.9640232, 12.8159271],
                [108.963782, 12.8158601],
                [108.9635437, 12.815784],
                [108.9633086, 12.8156989],
                [108.9630771, 12.8156048],
                [108.9628496, 12.8155019],
                [108.9626264, 12.8153903],
                [108.9624078, 12.8152703],
                [108.9621943, 12.8151421],
                [108.9619861, 12.8150057],
                [108.9617835, 12.8148615],
                [108.9615869, 12.8147097],
                [108.9609602, 12.8141833],
                [108.960638, 12.8139304],
                [108.9599888, 12.8134539],
                [108.9593322, 12.8130119],
                [108.9589972, 12.8128005],
                [108.958658, 12.8125958],
                [108.9580591, 12.812256],
                [108.9575359, 12.8119943],
                [108.9569982, 12.8117623],
                [108.9567245, 12.8116577],
                [108.9559709, 12.811399],
                [108.9557368, 12.8113068],
                [108.9555059, 12.8112073],
                [108.9548008, 12.8108647],
                [108.9545571, 12.8107569],
                [108.9543103, 12.8106558],
                [108.9538911, 12.8105021],
                [108.9535447, 12.8104056],
                [108.9531908, 12.8103398],
                [108.9528322, 12.8103053],
                [108.9525133, 12.8103025],
                [108.9523541, 12.8103125],
                [108.9520381, 12.8103553],
                [108.951728, 12.8104281],
                [108.9514267, 12.8105302],
                [108.9512922, 12.8105866],
                [108.9510315, 12.8107169],
                [108.9500011, 12.811174],
                [108.9495777, 12.8113733],
                [108.9494628, 12.8114177],
                [108.9493446, 12.811453],
                [108.9492238, 12.8114789],
                [108.9491013, 12.8114952],
                [108.9489778, 12.8115018],
                [108.9488542, 12.8114988],
                [108.9487313, 12.8114861],
                [108.9486098, 12.8114638],
                [108.9484905, 12.811432],
                [108.9483742, 12.811391],
                [108.948189, 12.8113027],
                [108.9479798, 12.8111729],
                [108.9477184, 12.8109757],
                [108.9475964, 12.8108669],
                [108.9473715, 12.8106308],
                [108.9472693, 12.8105041],
                [108.9471133, 12.8102758],
                [108.9469843, 12.810032],
                [108.9468839, 12.8097757],
                [108.9468448, 12.8096439],
                [108.9467942, 12.8094051],
                [108.9467531, 12.8090874],
                [108.9467217, 12.8086617],
                [108.9467168, 12.8084483],
                [108.9467221, 12.8075643],
                [108.946714, 12.8073861],
                [108.9466847, 12.8071677],
                [108.9466583, 12.8070605],
                [108.9465827, 12.8068529],
                [108.9465268, 12.8067405],
                [108.9463956, 12.8065261],
                [108.9463207, 12.8064248],
                [108.9461535, 12.8062356],
                [108.9459816, 12.8060647],
                [108.9457083, 12.8057491],
                [108.9456503, 12.8056918],
                [108.9455862, 12.805641],
                [108.9455166, 12.8055976],
                [108.9454425, 12.8055621],
                [108.9453648, 12.8055348],
                [108.9452844, 12.8055161],
                [108.9452025, 12.8055063],
                [108.9451199, 12.8055055],
                [108.9450377, 12.8055137],
                [108.9449593, 12.8055302],
                [108.9447311, 12.8056038],
                [108.9445817, 12.8056602],
                [108.9443404, 12.8057706],
                [108.9441097, 12.8059008],
                [108.9436665, 12.8061358],
                [108.9432891, 12.8063603],
                [108.9431234, 12.8064508],
                [108.9429534, 12.8065331],
                [108.9427793, 12.806607],
                [108.942383, 12.8067609],
                [108.9421902, 12.80685],
                [108.9418982, 12.8070045],
                [108.9417905, 12.8070536],
                [108.9416792, 12.8070941],
                [108.9415648, 12.8071257],
                [108.9414482, 12.8071482],
                [108.9413301, 12.8071615],
                [108.9412113, 12.8071654],
                [108.9410925, 12.80716],
                [108.9409746, 12.8071453],
                [108.9408583, 12.8071213],
                [108.9407444, 12.8070883],
                [108.9406335, 12.8070464],
                [108.9404677, 12.8069615],
                [108.9403909, 12.8069092],
                [108.9402519, 12.8067868],
                [108.9401357, 12.8066436],
                [108.9400457, 12.8064834],
                [108.9400112, 12.8063985],
                [108.939948, 12.8061533],
                [108.939928, 12.8060284],
                [108.9399113, 12.8057761],
                [108.9399147, 12.8056498],
                [108.9399465, 12.805391],
                [108.9399748, 12.8052635],
                [108.9400111, 12.805138],
                [108.9400969, 12.8048976],
                [108.9399995, 12.8049464],
                [108.9397913, 12.8050639],
                [108.9396668, 12.8051473],
                [108.9395476, 12.8052377],
                [108.9393268, 12.8054386],
                [108.939226, 12.8055483],
                [108.9390453, 12.8057841],
                [108.938966, 12.8059095],
                [108.9388311, 12.8061729],
                [108.9387759, 12.80631],
                [108.9387077, 12.8065181],
                [108.9382584, 12.8083081],
                [108.9382198, 12.8084274],
                [108.9381734, 12.8085439],
                [108.9380586, 12.8087674],
                [108.9378977, 12.8089976],
                [108.9379006, 12.810047],
                [108.9358367, 12.8099437],
                [108.9353098, 12.809934],
                [108.935035, 12.809936],
                [108.9344859, 12.8099601],
                [108.9339012, 12.8100124],
                [108.9327327, 12.8101495],
                [108.932147, 12.8102059],
                [108.9307803, 12.8103223],
                [108.928569, 12.8105459],
                [108.9276358, 12.81063],
                [108.9259079, 12.8107697],
                [108.9246192, 12.8108859],
                [108.9241232, 12.8109234],
                [108.9236265, 12.8109513],
                [108.9231294, 12.8109696],
                [108.9223409, 12.810979],
                [108.9198035, 12.8109713],
                [108.9180832, 12.8109546],
                [108.9180198, 12.8109595],
                [108.9179573, 12.8109714],
                [108.9178615, 12.8110043],
                [108.9177188, 12.8110786],
                [108.9175357, 12.8112005],
                [108.9172715, 12.8114352],
                [108.9171318, 12.8115731],
                [108.9169977, 12.8117162],
                [108.9169367, 12.8117928],
                [108.91681, 12.8119176],
                [108.9167471, 12.811967],
                [108.9166117, 12.8120524],
                [108.9165593, 12.8120719],
                [108.9164486, 12.8120883],
                [108.9163377, 12.8120737],
                [108.9162849, 12.8120549],
                [108.9161903, 12.8119966],
                [108.9157234, 12.8115286],
                [108.9153349, 12.8111788],
                [108.9150705, 12.8109596],
                [108.9147999, 12.8107479],
                [108.9145231, 12.8105436],
                [108.9142406, 12.8103471],
                [108.9138721, 12.8101209],
                [108.9132922, 12.8098115],
                [108.9130975, 12.8097],
                [108.9124196, 12.8092818],
                [108.9121172, 12.8091084],
                [108.9118102, 12.808943],
                [108.9114986, 12.8087857],
                [108.9111829, 12.8086366],
                [108.9105123, 12.8096567],
                [108.9103458, 12.8097199],
                [108.9100188, 12.8098607],
                [108.9098169, 12.8099591],
                [108.909425, 12.8101774],
                [108.9090507, 12.8104234],
                [108.9087253, 12.8106566],
                [108.9084256, 12.8108441],
                [108.9083323, 12.8109139],
                [108.9082451, 12.8109907],
                [108.9081643, 12.8110741],
                [108.9080906, 12.8111634],
                [108.9080244, 12.8112581],
                [108.907966, 12.8113576],
                [108.9078074, 12.8116711],
                [108.9075673, 12.8120786],
                [108.9060652, 12.8124343],
                [108.9056092, 12.8129416],
                [108.9054288, 12.8131283],
                [108.9050567, 12.8134909],
                [108.9047496, 12.8137507],
                [108.9042424, 12.814126],
                [108.9040672, 12.8142716],
                [108.9038987, 12.8144244],
                [108.9037371, 12.8145841],
                [108.9032635, 12.8145293],
                [108.9030257, 12.8145116],
                [108.9024979, 12.8144956],
                [108.9022082, 12.8145004],
                [108.9019644, 12.8144813],
                [108.9016777, 12.8144465],
                [108.9013447, 12.8144347],
                [108.9011781, 12.8144402],
                [108.9006686, 12.8144795],
                [108.8997097, 12.8145128],
                [108.8994057, 12.814532],
                [108.8987021, 12.8145987],
                [108.8983029, 12.8146469],
                [108.8976467, 12.8147046],
                [108.8967647, 12.8147502],
                [108.8958859, 12.8148348],
                [108.8954532, 12.8148697],
                [108.8942689, 12.8149503],
                [108.8935175, 12.814994],
                [108.8927263, 12.8150622],
                [108.8917608, 12.8151582],
                [108.8912098, 12.8151997],
                [108.8906582, 12.8152315],
                [108.8901061, 12.8152537],
                [108.8898803, 12.8153499],
                [108.8894355, 12.8155571],
                [108.8892238, 12.8156642],
                [108.8887152, 12.8159411],
                [108.8886201, 12.815986],
                [108.8884287, 12.8160602],
                [108.8881963, 12.8161387],
                [108.8880649, 12.8161935],
                [108.8878118, 12.816323],
                [108.8875741, 12.8164777],
                [108.8874094, 12.8166131],
                [108.8872083, 12.8167955],
                [108.8870776, 12.8168967],
                [108.8869411, 12.8169903],
                [108.88641, 12.8160226],
                [108.8859006, 12.8157831],
                [108.8856416, 12.8156723],
                [108.8850995, 12.8154629],
                [108.8848163, 12.8153652],
                [108.8843874, 12.8152306],
                [108.8840535, 12.8151458],
                [108.8838625, 12.8151081],
                [108.8836702, 12.8150777],
                [108.8830828, 12.8150176],
                [108.8828838, 12.8149903],
                [108.8821286, 12.8148542],
                [108.881405, 12.8147463],
                [108.8807262, 12.8146785],
                [108.8803858, 12.8146574],
                [108.8798466, 12.8146463],
                [108.8793075, 12.8146626],
                [108.8792127, 12.8147425],
                [108.8790339, 12.8149137],
                [108.8789473, 12.8150078],
                [108.8787872, 12.8152066],
                [108.878588, 12.8152472],
                [108.8783848, 12.8152589],
                [108.8781856, 12.815242],
                [108.8780875, 12.8152231],
                [108.8778967, 12.8151647],
                [108.8777534, 12.8152233],
                [108.8774782, 12.8153635],
                [108.877256, 12.8155076],
                [108.8770491, 12.8156721],
                [108.8769584, 12.8157354],
                [108.8767631, 12.8158399],
                [108.8766597, 12.8158805],
                [108.8764446, 12.8159371],
                [108.8760918, 12.8159755],
                [108.8758338, 12.8160306],
                [108.8755847, 12.8161165],
                [108.8754648, 12.8161706],
                [108.8751125, 12.8163521],
                [108.8749095, 12.816436],
                [108.8747838, 12.8164744],
                [108.8746555, 12.816504],
                [108.8745254, 12.8165243],
                [108.8743942, 12.8165355],
                [108.8742624, 12.8165374],
                [108.8734054, 12.8164792],
                [108.8730419, 12.8164672],
                [108.8727754, 12.8164641],
                [108.8719581, 12.8164736],
                [108.8716736, 12.8164688],
                [108.8706295, 12.8164194],
                [108.8701535, 12.8164061],
                [108.8692009, 12.8164044],
                [108.8688469, 12.8160592],
                [108.8685465, 12.8162057],
                [108.8683426, 12.8163147],
                [108.8676553, 12.8167164],
                [108.8674658, 12.8168602],
                [108.8673716, 12.8169449],
                [108.8672828, 12.8170348],
                [108.8671219, 12.817229],
                [108.8669854, 12.8174401],
                [108.8668621, 12.8176703],
                [108.8668085, 12.817756],
                [108.866683, 12.8179151],
                [108.8665353, 12.8180549],
                [108.8662557, 12.8182546],
                [108.8661481, 12.8183433],
                [108.8659501, 12.8185386],
                [108.8659258, 12.8186312],
                [108.8658935, 12.8188199],
                [108.8658857, 12.8189152],
                [108.8658857, 12.8190878],
                [108.8659131, 12.819257],
                [108.8659379, 12.8193392],
                [108.8660591, 12.8196118],
                [108.8661001, 12.8197311],
                [108.8661318, 12.8198531],
                [108.8661541, 12.8199771],
                [108.8661669, 12.8201023],
                [108.86617, 12.8202281],
                [108.8661645, 12.8203545],
                [108.8661415, 12.820543],
                [108.8661012, 12.82068],
                [108.8660245, 12.8208198],
                [108.865849, 12.8210182],
                [108.8657861, 12.8211016],
                [108.8656798, 12.8212807],
                [108.8655494, 12.8216008],
                [108.8654298, 12.8218496],
                [108.8653863, 12.8219585],
                [108.8653519, 12.8220704],
                [108.8653269, 12.8221846],
                [108.8653114, 12.8223004],
                [108.8653055, 12.8224171],
                [108.8653092, 12.8225338],
                [108.8653471, 12.8227843],
                [108.8653953, 12.8229963],
                [108.8654218, 12.8232762],
                [108.8654756, 12.8235096],
                [108.8655641, 12.8237845],
                [108.8656035, 12.8239486],
                [108.8656336, 12.8241145],
                [108.8655021, 12.8242271],
                [108.8652256, 12.8244365],
                [108.8649283, 12.8246261],
                [108.8646115, 12.8247937],
                [108.8640374, 12.8250411],
                [108.8637964, 12.825179],
                [108.8636273, 12.8252999],
                [108.8634821, 12.825427],
                [108.8633966, 12.8255163],
                [108.8633145, 12.825589],
                [108.8632269, 12.8256554],
                [108.8629997, 12.8257935],
                [108.8627385, 12.8260193],
                [108.8625958, 12.8261526],
                [108.862321, 12.8264368],
                [108.8621895, 12.8265877],
                [108.8619429, 12.8269024],
                [108.8615727, 12.8270018],
                [108.8611329, 12.827143],
                [108.8610059, 12.8271737],
                [108.8607472, 12.8272113],
                [108.8604857, 12.8272166],
                [108.8603553, 12.8272071],
                [108.8600975, 12.8271639],
                [108.859878, 12.8271055],
                [108.8597703, 12.8270698],
                [108.8596387, 12.8270442],
                [108.8595718, 12.8270384],
                [108.8594426, 12.8270042],
                [108.859325, 12.8269418],
                [108.8592726, 12.8269011],
                [108.8591841, 12.8268032],
                [108.8591212, 12.8266879],
                [108.8590081, 12.8263802],
                [108.8589326, 12.8262677],
                [108.8588326, 12.8261751],
                [108.8587135, 12.8261073],
                [108.8585324, 12.8260446],
                [108.8583423, 12.8260164],
                [108.8581502, 12.8260236],
                [108.8573831, 12.827598],
                [108.8565409, 12.8279903],
                [108.8561158, 12.8281721],
                [108.8556826, 12.8283912],
                [108.8554618, 12.8285172],
                [108.8550343, 12.8287916],
                [108.8548282, 12.8289395],
                [108.8543951, 12.8292718],
                [108.8541698, 12.8302761],
                [108.8539544, 12.8303795],
                [108.8537433, 12.8304911],
                [108.8535368, 12.8306108],
                [108.8532924, 12.8307634],
                [108.853215, 12.83082],
                [108.8531455, 12.8308796],
                [108.8530213, 12.8310131],
                [108.8529191, 12.8311634],
                [108.8528232, 12.8313301],
                [108.8527627, 12.8314122],
                [108.8526231, 12.8315615],
                [108.8525449, 12.8316278],
                [108.8523737, 12.8317416],
                [108.8522819, 12.8317885],
                [108.8519902, 12.8319021],
                [108.8518956, 12.8319508],
                [108.85172, 12.8320696],
                [108.851566, 12.8322142],
                [108.8512556, 12.8325636],
                [108.8509808, 12.8328352],
                [108.8508436, 12.8329495],
                [108.8507529, 12.8330124],
                [108.8506581, 12.8330692],
                [108.8503679, 12.8332123],
                [108.8502831, 12.8332692],
                [108.850204, 12.8333334],
                [108.8501314, 12.8334044],
                [108.8500657, 12.8334816],
                [108.8500076, 12.8335644],
                [108.8499575, 12.8336521],
                [108.849813, 12.8339849],
                [108.8497182, 12.8342291],
                [108.8495234, 12.8348073],
                [108.8494237, 12.835141],
                [108.8493325, 12.835477],
                [108.8491756, 12.836155],
                [108.8491335, 12.8363915],
                [108.8490486, 12.8369863],
                [108.8490522, 12.837107],
                [108.849079, 12.8372152],
                [108.8491273, 12.8373162],
                [108.8491654, 12.8373683],
                [108.8492107, 12.8374146],
                [108.8492623, 12.8374542],
                [108.8493191, 12.8374863],
                [108.849509, 12.8375474],
                [108.8496315, 12.8376091],
                [108.8497006, 12.8376764],
                [108.8497265, 12.837717],
                [108.8497578, 12.8378071],
                [108.8497451, 12.8379849],
                [108.8494438, 12.8395391],
                [108.8489547, 12.8396954],
                [108.8487142, 12.8397849],
                [108.8483136, 12.8399491],
                [108.8481663, 12.8400305],
                [108.8480632, 12.8400973],
                [108.8480066, 12.8401607],
                [108.8479884, 12.840201],
                [108.8479792, 12.8402441],
                [108.8479792, 12.8402881],
                [108.8479884, 12.8403312],
                [108.8480066, 12.8403715],
                [108.8480839, 12.8404672],
                [108.8481402, 12.8405224],
                [108.8481271, 12.8407629],
                [108.8481304, 12.8408834],
                [108.8481536, 12.8411037],
                [108.8481939, 12.8413017],
                [108.8482221, 12.8413819],
                [108.848303, 12.8415317],
                [108.8484082, 12.8416563],
                [108.8485313, 12.841752],
                [108.8486716, 12.8418216],
                [108.8488489, 12.8418847],
                [108.8489486, 12.8419301],
                [108.849093, 12.8420082],
                [108.8491793, 12.8420739],
                [108.8492507, 12.8421542],
                [108.8492685, 12.8421918],
                [108.8492793, 12.8422736],
                [108.8492718, 12.8423144],
                [108.8492287, 12.8424381],
                [108.8492105, 12.8425256],
                [108.8492018, 12.8426144],
                [108.8492027, 12.8427036],
                [108.8492131, 12.8427923],
                [108.8491526, 12.8428046],
                [108.8490361, 12.8428446],
                [108.8489653, 12.8428808],
                [108.8488388, 12.8429756],
                [108.8487376, 12.8430959],
                [108.8486082, 12.8433056],
                [108.848511, 12.8434429],
                [108.848215, 12.8438079],
                [108.8480725, 12.8440315],
                [108.8480112, 12.8441488],
                [108.847932, 12.8443306],
                [108.8478777, 12.8444975],
                [108.8478526, 12.844604],
                [108.8478345, 12.8447118],
                [108.8478308, 12.8447877],
                [108.8478345, 12.8448637],
                [108.847864, 12.8450129],
                [108.8478896, 12.8450848],
                [108.8479609, 12.8452199],
                [108.8480845, 12.845377],
                [108.8481698, 12.8454663],
                [108.8482615, 12.8455494],
                [108.8484621, 12.845695],
                [108.848456, 12.8458262],
                [108.8484406, 12.8459565],
                [108.8484181, 12.8460769],
                [108.8483494, 12.8463122],
                [108.8483283, 12.8464097],
                [108.8483162, 12.8465087],
                [108.8483134, 12.8466084],
                [108.8483199, 12.8467079],
                [108.8483355, 12.8468064],
                [108.8483773, 12.8470035],
                [108.8483957, 12.8472807],
                [108.8484334, 12.8474251],
                [108.8484998, 12.8475979],
                [108.8485318, 12.8477034],
                [108.8497388, 12.8485664],
                [108.8498381, 12.848796],
                [108.8500126, 12.8492643],
                [108.8500875, 12.8495026],
                [108.8502109, 12.8499785],
                [108.8502429, 12.8501494],
                [108.8502847, 12.8504943],
                [108.8502984, 12.8507546],
                [108.850289, 12.8509307],
                [108.8502753, 12.8510193],
                [108.8504147, 12.8511448],
                [108.8505328, 12.8512808],
                [108.8505753, 12.8513475],
                [108.8506372, 12.8514924],
                [108.8506656, 12.8516468],
                [108.8506633, 12.85186],
                [108.8506525, 12.8519942],
                [108.8506094, 12.8522602],
                [108.850501, 12.8526686],
                [108.8504715, 12.8528185],
                [108.8504157, 12.8532481],
                [108.8503871, 12.853373],
                [108.850306, 12.8536163],
                [108.8502538, 12.8537337],
                [108.8501984, 12.8538337],
                [108.8501349, 12.8539291],
                [108.8500637, 12.8540191],
                [108.8499852, 12.8541032],
                [108.8496839, 12.8543572],
                [108.8495652, 12.8544694],
                [108.8492633, 12.8547974],
                [108.8490806, 12.8549538],
                [108.8488805, 12.8550882],
                [108.8486953, 12.8551048],
                [108.848328, 12.8551614],
                [108.8480135, 12.8552362],
                [108.8477817, 12.8553067],
                [108.8475808, 12.855386],
                [108.8474594, 12.8554454],
                [108.8473421, 12.8555122],
                [108.8471216, 12.8556666],
                [108.8467885, 12.8559691],
                [108.8466507, 12.8560877],
                [108.8465537, 12.8561607],
                [108.8464505, 12.8562683],
                [108.8463991, 12.8563401],
                [108.8463558, 12.8564169],
                [108.846321, 12.8564977],
                [108.8462557, 12.8567497],
                [108.8462258, 12.8568296],
                [108.8461464, 12.8569808],
                [108.8460725, 12.8570862],
                [108.8460427, 12.8571174],
                [108.846003, 12.8571483],
                [108.8459109, 12.8571895],
                [108.8458101, 12.8572],
                [108.8457112, 12.8571788],
                [108.8456656, 12.8571568],
                [108.8455466, 12.8570789],
                [108.8453827, 12.856995],
                [108.8451858, 12.8569231],
                [108.8449566, 12.8568704],
                [108.8447223, 12.8568478],
                [108.8444871, 12.8568559],
                [108.8443002, 12.8568488],
                [108.8442075, 12.8568362],
                [108.8439022, 12.8567663],
                [108.8437772, 12.8567465],
                [108.8436512, 12.8567339],
                [108.8432569, 12.8567241],
                [108.8431165, 12.8567089],
                [108.8428402, 12.856652],
                [108.8425757, 12.8566136],
                [108.8424422, 12.8566063],
                [108.8420051, 12.8566203],
                [108.8416654, 12.8566101],
                [108.8413023, 12.8566176],
                [108.8409418, 12.8566596],
                [108.8407037, 12.8567031],
                [108.8405871, 12.8567357],
                [108.8404378, 12.8568082],
                [108.8403075, 12.8569096],
                [108.8402019, 12.8570355],
                [108.8401599, 12.8571059],
                [108.8400131, 12.8573999],
                [108.839832, 12.8577002],
                [108.8396269, 12.8579856],
                [108.8385013, 12.8596071],
                [108.8383831, 12.8597328],
                [108.8382361, 12.8598522],
                [108.8380589, 12.8599577],
                [108.8378674, 12.8600357],
                [108.8377454, 12.8601117],
                [108.8376924, 12.86016],
                [108.8376066, 12.8602736],
                [108.8375517, 12.8604042],
                [108.8375309, 12.8605438],
                [108.8375577, 12.8607989],
                [108.8375613, 12.8609142],
                [108.8375379, 12.8612905],
                [108.8375103, 12.8615509],
                [108.8372694, 12.8619157],
                [108.8369616, 12.8624772],
                [108.8368419, 12.8626685],
                [108.8365916, 12.863018],
                [108.8363278, 12.8633305],
                [108.8362085, 12.8633387],
                [108.8360902, 12.8633561],
                [108.8359737, 12.8633828],
                [108.8357593, 12.8634586],
                [108.8356572, 12.8635083],
                [108.8353254, 12.863893],
                [108.8349042, 12.8643621],
                [108.8347204, 12.8645242],
                [108.8345146, 12.8646589],
                [108.8341227, 12.8648635],
                [108.8339621, 12.8649292],
                [108.8338782, 12.864952],
                [108.8337059, 12.8649764],
                [108.8335584, 12.8649747],
                [108.8333789, 12.8649495],
                [108.8331461, 12.8648862],
                [108.8329669, 12.8648106],
                [108.8326424, 12.8646327],
                [108.8323305, 12.8644295],
                [108.8321811, 12.8643189],
                [108.8321384, 12.8643781],
                [108.8321017, 12.8644409],
                [108.832047, 12.8645752],
                [108.8319799, 12.8648904],
                [108.8318807, 12.8651975],
                [108.8318335, 12.8653146],
                [108.8317251, 12.8655427],
                [108.8307273, 12.8657937],
                [108.8306094, 12.8659838],
                [108.8303518, 12.8663502],
                [108.8302123, 12.8665259],
                [108.8297832, 12.867028],
                [108.8294452, 12.8674568],
                [108.8286457, 12.8676415],
                [108.8282436, 12.8677235],
                [108.8275269, 12.867851],
                [108.8266631, 12.8679709],
                [108.8263755, 12.8679795],
                [108.8260891, 12.8679534],
                [108.8259476, 12.8679275],
                [108.8256108, 12.8678352],
                [108.8254453, 12.8677797],
                [108.8251215, 12.8676503],
                [108.8249779, 12.8675835],
                [108.8245557, 12.8673664],
                [108.8240057, 12.8670541],
                [108.8238398, 12.8669438],
                [108.8236943, 12.8668088],
                [108.8236305, 12.8667331],
                [108.8235229, 12.8665677],
                [108.8234724, 12.8664619],
                [108.8234135, 12.8663602],
                [108.8233465, 12.8662634],
                [108.823272, 12.866172],
                [108.8231393, 12.8660395],
                [108.8230235, 12.8659619],
                [108.8228939, 12.8659093],
                [108.8228255, 12.8658931],
                [108.8226702, 12.8658813],
                [108.8225924, 12.8658873],
                [108.8225156, 12.865901],
                [108.8223686, 12.8659513],
                [108.822234, 12.8660309],
                [108.8219351, 12.8662853],
                [108.8215849, 12.8665592],
                [108.8200253, 12.867713],
                [108.8189939, 12.8684944],
                [108.8185806, 12.8687848],
                [108.8178152, 12.8692558],
                [108.8176412, 12.8693763],
                [108.8174909, 12.8695241],
                [108.8173689, 12.8696948],
                [108.8173197, 12.8697872],
                [108.8172057, 12.8700863],
                [108.8171594, 12.870184],
                [108.8170481, 12.8703697],
                [108.8169677, 12.8704767],
                [108.81688, 12.870578],
                [108.8166204, 12.8708268],
                [108.8165448, 12.87091],
                [108.8164251, 12.8710639],
                [108.8163822, 12.8711344],
                [108.8163454, 12.8712081],
                [108.8162917, 12.8713633],
                [108.8162817, 12.8714073],
                [108.8162817, 12.8714972],
                [108.8163043, 12.871575],
                [108.8163558, 12.8716705],
                [108.8164419, 12.8717869],
                [108.8165032, 12.8718493],
                [108.8170942, 12.8723022],
                [108.8174231, 12.8725419],
                [108.8175955, 12.8726426],
                [108.8176869, 12.8726834],
                [108.8178777, 12.8727447],
                [108.8181907, 12.8727963],
                [108.8184348, 12.8728511],
                [108.8183206, 12.8729641],
                [108.8181114, 12.8732071],
                [108.8179298, 12.8734702],
                [108.8177778, 12.8737507],
                [108.8177135, 12.8738964],
                [108.817609, 12.8741967],
                [108.8175377, 12.8745062],
                [108.8175076, 12.8747301],
                [108.8174914, 12.8747955],
                [108.8174662, 12.8748581],
                [108.8174327, 12.8749169],
                [108.8173914, 12.8749708],
                [108.8172898, 12.8750593],
                [108.8171694, 12.8751181],
                [108.817059, 12.875143],
                [108.8162109, 12.8751525],
                [108.8160068, 12.8751483],
                [108.8156912, 12.8751186],
                [108.8153797, 12.8750607],
                [108.8152129, 12.8750175],
                [108.8147195, 12.8748651],
                [108.8142629, 12.8747125],
                [108.8140137, 12.8745984],
                [108.8137819, 12.8744536],
                [108.813599, 12.8743057],
                [108.8133645, 12.8740768],
                [108.8132636, 12.8739939],
                [108.8131568, 12.8739183],
                [108.8130448, 12.8738501],
                [108.8129282, 12.8737899],
                [108.8126384, 12.8736583],
                [108.8124994, 12.8735813],
                [108.8121709, 12.8733726],
                [108.8120676, 12.8733207],
                [108.811851, 12.8732392],
                [108.8117387, 12.8732099],
                [108.8115473, 12.8731786],
                [108.8111579, 12.8731571],
                [108.8108974, 12.8731663],
                [108.8106402, 12.873208],
                [108.8103021, 12.8732996],
                [108.8101929, 12.8733154],
                [108.8100827, 12.8733219],
                [108.8099724, 12.8733192],
                [108.8098303, 12.8733019],
                [108.8095486, 12.8732512],
                [108.8091215, 12.873153],
                [108.8086879, 12.8730867],
                [108.8084695, 12.8730656],
                [108.8081109, 12.8730484],
                [108.8076924, 12.8730511],
                [108.8073003, 12.8730702],
                [108.8071879, 12.8730857],
                [108.8069683, 12.8731414],
                [108.8068308, 12.8731949],
                [108.8066971, 12.873257],
                [108.8065678, 12.8733275],
                [108.8064435, 12.8734061],
                [108.8063247, 12.8734923],
                [108.8061227, 12.8736695],
                [108.8060382, 12.8737577],
                [108.8058453, 12.8739891],
                [108.8057511, 12.8740571],
                [108.805698, 12.8740814],
                [108.8055843, 12.8741089],
                [108.8054579, 12.8741052],
                [108.8053963, 12.8740903],
                [108.803734, 12.8735325],
                [108.8035216, 12.8734326],
                [108.8034215, 12.8733717],
                [108.8032362, 12.8732296],
                [108.8030738, 12.873063],
                [108.8029379, 12.8728757],
                [108.8027623, 12.8725931],
                [108.8025047, 12.8722424],
                [108.8022234, 12.8719155],
                [108.8020738, 12.8717629],
                [108.8019173, 12.8716171],
                [108.8016735, 12.8714144],
                [108.8014991, 12.8713045],
                [108.80131, 12.8712207],
                [108.8012112, 12.8711891],
                [108.8010527, 12.871154],
                [108.8008781, 12.8711325],
                [108.8006437, 12.8711228],
                [108.8003393, 12.871139],
                [108.7996126, 12.8712306],
                [108.7990344, 12.8712797],
                [108.7984982, 12.871298],
                [108.7979619, 12.8712825],
                [108.7974277, 12.8712332],
                [108.7969185, 12.8711543],
                [108.7964355, 12.8710496],
                [108.7960012, 12.8709229],
                [108.795788, 12.8708479],
                [108.7951508, 12.8686923],
                [108.7935519, 12.8680948],
                [108.7931335, 12.868895],
                [108.7929941, 12.8691371],
                [108.792851, 12.8693295],
                [108.792769, 12.8694175],
                [108.7925863, 12.8695748],
                [108.7923881, 12.869701],
                [108.7917374, 12.8700515],
                [108.7914955, 12.8701693],
                [108.7912494, 12.8702785],
                [108.7909993, 12.8703789],
                [108.7907189, 12.8704808],
                [108.7897631, 12.8707694],
                [108.7896306, 12.8708218],
                [108.7894515, 12.8709085],
                [108.7894048, 12.8709404],
                [108.7893248, 12.8710194],
                [108.7892635, 12.8711205],
                [108.7892402, 12.8711782],
                [108.7892117, 12.8712982],
                [108.7891996, 12.8714703],
                [108.7892022, 12.8717296],
                [108.7892228, 12.872106],
                [108.7893075, 12.8729384],
                [108.7893355, 12.8733558],
                [108.7893534, 12.87376],
                [108.7893623, 12.8745691],
                [108.7887549, 12.8752695],
                [108.7886265, 12.8753878],
                [108.7884774, 12.8754803],
                [108.7883383, 12.8755359],
                [108.7881549, 12.8755843],
                [108.7879032, 12.8756307],
                [108.7876725, 12.8759291],
                [108.787415, 12.8762059],
                [108.7872752, 12.8763369],
                [108.7869778, 12.8765795],
                [108.7860898, 12.8773622],
                [108.7860175, 12.8774494],
                [108.7859666, 12.8775499],
                [108.7859492, 12.8776072],
                [108.7859357, 12.8777257],
                [108.7860256, 12.8788364],
                [108.7859025, 12.8793885],
                [108.7857636, 12.8799074],
                [108.7855963, 12.8804335],
                [108.7855542, 12.8805459],
                [108.7854463, 12.8807608],
                [108.7854011, 12.8808325],
                [108.7852962, 12.8809662],
                [108.7851649, 12.8810921],
                [108.7850879, 12.8811512],
                [108.7848902, 12.8813254],
                [108.7848023, 12.881433],
                [108.7847671, 12.8814928],
                [108.7847182, 12.8816134],
                [108.7846572, 12.8819114],
                [108.7846041, 12.8822721],
                [108.784581, 12.8825821],
                [108.7845826, 12.8841704],
                [108.7845885, 12.8843852],
                [108.7845635, 12.8845988],
                [108.7844592, 12.8849861],
                [108.784436, 12.8851058],
                [108.7844164, 12.8853471],
                [108.7844324, 12.8855875],
                [108.7841825, 12.8857905],
                [108.7839508, 12.8860132],
                [108.7836009, 12.8864138],
                [108.7833434, 12.8866909],
                [108.7834245, 12.8870839],
                [108.7835356, 12.8874698],
                [108.783676, 12.8878466],
                [108.7837656, 12.8880493],
                [108.7838636, 12.8882483],
                [108.78397, 12.8884432],
                [108.7840846, 12.8886336],
                [108.7842071, 12.8888192],
                [108.7843679, 12.8891027],
                [108.7845504, 12.8893735],
                [108.784739, 12.8896168],
                [108.7847701, 12.8896712],
                [108.784808, 12.8897898],
                [108.7848141, 12.8898518],
                [108.7848001, 12.8899753],
                [108.7847803, 12.8900345],
                [108.7847167, 12.8901422],
                [108.7846433, 12.8902157],
                [108.7844819, 12.8903471],
                [108.7843947, 12.8904045],
                [108.7842287, 12.8904925],
                [108.7839974, 12.8905883],
                [108.7838405, 12.8906455],
                [108.7836107, 12.8907171],
                [108.7835428, 12.8907453],
                [108.7834786, 12.8907809],
                [108.7833648, 12.8908721],
                [108.7833166, 12.8909266],
                [108.783267, 12.8909996],
                [108.7831879, 12.8911567],
                [108.7831086, 12.8913719],
                [108.7829422, 12.8917039],
                [108.7828969, 12.8918237],
                [108.7828452, 12.8920122],
                [108.7828213, 12.8920757],
                [108.7827501, 12.8921915],
                [108.7827038, 12.8922419],
                [108.7825827, 12.8923422],
                [108.7825371, 12.8924174],
                [108.7825248, 12.8924596],
                [108.7825229, 12.8925469],
                [108.782573, 12.8926925],
                [108.7825816, 12.8927469],
                [108.7825754, 12.8928568],
                [108.7825388, 12.8929608],
                [108.7824799, 12.8930693],
                [108.7824489, 12.8931883],
                [108.7824445, 12.8932497],
                [108.7824556, 12.8933607],
                [108.7824529, 12.8934608],
                [108.78241, 12.8936196],
                [108.7823939, 12.893787],
                [108.7823763, 12.8938817],
                [108.7823171, 12.8940652],
                [108.7822276, 12.8942367],
                [108.7820774, 12.8944354],
                [108.7818972, 12.8946247],
                [108.7817992, 12.8947118],
                [108.7815893, 12.8948694],
                [108.7815208, 12.8949135],
                [108.7813726, 12.8949816],
                [108.7812138, 12.8950211],
                [108.7810553, 12.8950434],
                [108.7809257, 12.8950793],
                [108.7808309, 12.8951321],
                [108.78079, 12.8951675],
                [108.7807454, 12.8952204],
                [108.7806773, 12.89534],
                [108.7806583, 12.8953931],
                [108.7806367, 12.8955035],
                [108.7806505, 12.8965793],
                [108.7804562, 12.8966897],
                [108.7800765, 12.8969244],
                [108.7798329, 12.8970893],
                [108.7792142, 12.8975489],
                [108.7790607, 12.8976491],
                [108.7787408, 12.8978291],
                [108.7783955, 12.8979847],
                [108.7779199, 12.8982149],
                [108.7777893, 12.8982882],
                [108.7775036, 12.8984709],
                [108.7773566, 12.8985293],
                [108.7772002, 12.8985554],
                [108.7770589, 12.8985505],
                [108.7768761, 12.8985172],
                [108.7765311, 12.8984292],
                [108.7764159, 12.8984125],
                [108.7762997, 12.8984042],
                [108.7760671, 12.8984134],
                [108.7758386, 12.8984566],
                [108.7755828, 12.8985294],
                [108.7752255, 12.8985981],
                [108.7750058, 12.8986679],
                [108.7747676, 12.8987686],
                [108.7746977, 12.8987862],
                [108.7745361, 12.898809],
                [108.7744546, 12.8988487],
                [108.7744172, 12.8988802],
                [108.7743627, 12.8989599],
                [108.7743417, 12.8990381],
                [108.7743457, 12.8992024],
                [108.774358, 12.8992671],
                [108.7744302, 12.8995041],
                [108.7744707, 12.8997481],
                [108.7744791, 12.8999808],
                [108.7744586, 12.9002128],
                [108.7742115, 12.9005164],
                [108.7739883, 12.900837],
                [108.7738394, 12.9010837],
                [108.7738008, 12.9011683],
                [108.773749, 12.9013462],
                [108.7737363, 12.9014379],
                [108.7737377, 12.9016227],
                [108.7737518, 12.9017142],
                [108.7738099, 12.9019579],
                [108.7738267, 12.9020819],
                [108.7738351, 12.9022068],
                [108.773827, 12.9024568],
                [108.7738002, 12.9026362],
                [108.7737645, 12.9028141],
                [108.7736357, 12.9032903],
                [108.7735802, 12.9035967],
                [108.7734878, 12.9043194],
                [108.773476, 12.9045017],
                [108.7734743, 12.9047634],
                [108.7734557, 12.9049206],
                [108.7734095, 12.9050722],
                [108.7733041, 12.9052874],
                [108.7732626, 12.9054416],
                [108.773254, 12.9055209],
                [108.7732614, 12.9056801],
                [108.7732773, 12.9057584],
                [108.7733316, 12.905928],
                [108.7733339, 12.9060258],
                [108.7733081, 12.9061204],
                [108.7732234, 12.9062591],
                [108.773187, 12.9063657],
                [108.7731779, 12.9064778],
                [108.7731878, 12.9065546],
                [108.7731831, 12.9067092],
                [108.7731684, 12.9067853],
                [108.7731051, 12.9069793],
                [108.773097, 12.9070421],
                [108.7731127, 12.9072556],
                [108.7730974, 12.9074059],
                [108.7730671, 12.9075496],
                [108.7730639, 12.907803],
                [108.7730522, 12.9078554],
                [108.7730054, 12.9079521],
                [108.7729015, 12.9080668],
                [108.7728513, 12.9081536],
                [108.7728, 12.9083438],
                [108.7727034, 12.9085655],
                [108.7726662, 12.9087289],
                [108.7726558, 12.9089499],
                [108.7726426, 12.9090175],
                [108.7725949, 12.9091469],
                [108.7725372, 12.9092422],
                [108.7724555, 12.9093391],
                [108.7723276, 12.9094504],
                [108.772255, 12.9094981],
                [108.7721193, 12.9096051],
                [108.7720597, 12.909667],
                [108.7720072, 12.9097348],
                [108.7719625, 12.9098078],
                [108.7719261, 12.9098849],
                [108.7718835, 12.9100278],
                [108.7718351, 12.9103928],
                [108.7718049, 12.9104848],
                [108.7717482, 12.9105642],
                [108.7716095, 12.9106958],
                [108.7715501, 12.9107866],
                [108.7715127, 12.910885],
                [108.77145, 12.9112317],
                [108.7714093, 12.9113195],
                [108.7713417, 12.9113997],
                [108.7711821, 12.9115165],
                [108.7711539, 12.9115478],
                [108.7711146, 12.9116217],
                [108.7711013, 12.9116952],
                [108.7711055, 12.9117281],
                [108.7711352, 12.9117873],
                [108.7711879, 12.9118287],
                [108.7713122, 12.9118565],
                [108.7713915, 12.9119053],
                [108.7714227, 12.9119399],
                [108.7714676, 12.9120205],
                [108.7714805, 12.9120647],
                [108.7714854, 12.9121563],
                [108.7714563, 12.9122644],
                [108.7714306, 12.9123145],
                [108.7713593, 12.9124021],
                [108.7712298, 12.9124899],
                [108.7710983, 12.9125503],
                [108.7710535, 12.9125982],
                [108.771039, 12.9126273],
                [108.7709854, 12.9128379],
                [108.7709609, 12.9128931],
                [108.7708926, 12.9129929],
                [108.770839, 12.9130457],
                [108.7707487, 12.9131652],
                [108.7706943, 12.9132726],
                [108.7706519, 12.9134026],
                [108.7706248, 12.9135825],
                [108.7706243, 12.9136735],
                [108.770645, 12.913835],
                [108.7706936, 12.9139704],
                [108.7707769, 12.9140992],
                [108.7708403, 12.9142182],
                [108.7708594, 12.9143233],
                [108.7708421, 12.9144287],
                [108.7708202, 12.9144779],
                [108.7707022, 12.9146661],
                [108.7706712, 12.914748],
                [108.7706673, 12.9147916],
                [108.770683, 12.9148775],
                [108.7707257, 12.9149499],
                [108.770749, 12.9150261],
                [108.7707478, 12.9150661],
                [108.7707297, 12.9151544],
                [108.7707403, 12.9153265],
                [108.7707271, 12.9154965],
                [108.7707332, 12.9156356],
                [108.7707156, 12.9157197],
                [108.7706781, 12.9158193],
                [108.7706484, 12.9159913],
                [108.7705999, 12.916103],
                [108.7705332, 12.9161911],
                [108.7704539, 12.9162504],
                [108.7703605, 12.9162848],
                [108.7702609, 12.9162917],
                [108.7701622, 12.9162697],
                [108.7700227, 12.9162062],
                [108.7698615, 12.9161168],
                [108.7697098, 12.9160517],
                [108.769622, 12.9160325],
                [108.7695769, 12.9160333],
                [108.7694899, 12.9160559],
                [108.7694147, 12.916104],
                [108.7693808, 12.9161403],
                [108.7692511, 12.9163498],
                [108.76915, 12.9165798],
                [108.7691255, 12.9166212],
                [108.769095, 12.9166586],
                [108.7690188, 12.9167183],
                [108.7689374, 12.9167515],
                [108.768821, 12.9167713],
                [108.7686638, 12.9167718],
                [108.768535, 12.9167482],
                [108.7684827, 12.916745],
                [108.7684306, 12.916751],
                [108.7683805, 12.9167658],
                [108.7683338, 12.916789],
                [108.7682308, 12.9168624],
                [108.7680951, 12.9169301],
                [108.7676986, 12.9171663],
                [108.7676297, 12.9172416],
                [108.7676042, 12.9172857],
                [108.7675781, 12.9173604],
                [108.7675506, 12.9175158],
                [108.7675566, 12.9177585],
                [108.7675345, 12.9179393],
                [108.7675042, 12.9180326],
                [108.7674448, 12.9181115],
                [108.767406, 12.9181429],
                [108.7673156, 12.9181855],
                [108.7672112, 12.9181973],
                [108.7671029, 12.9181789],
                [108.7669411, 12.9181058],
                [108.7668812, 12.9180912],
                [108.766758, 12.9180873],
                [108.7666386, 12.9181171],
                [108.7665189, 12.9181851],
                [108.7664177, 12.9182775],
                [108.7663401, 12.9183899],
                [108.7662542, 12.9185759],
                [108.7662041, 12.9187027],
                [108.7661521, 12.9188636],
                [108.7660649, 12.9190837],
                [108.7660327, 12.9191431],
                [108.7659679, 12.9192336],
                [108.7658979, 12.9192861],
                [108.7658138, 12.9193123],
                [108.7657696, 12.9193144],
                [108.765703, 12.9193033],
                [108.765576, 12.9192585],
                [108.7654239, 12.9191677],
                [108.7653181, 12.919136],
                [108.7652074, 12.9191327],
                [108.7651226, 12.9191501],
                [108.765096, 12.9191662],
                [108.7650614, 12.9192164],
                [108.7650561, 12.9192464],
                [108.7650649, 12.9192918],
                [108.7650649, 12.919384],
                [108.7650406, 12.9194761],
                [108.7649327, 12.9196961],
                [108.7649046, 12.9197677],
                [108.7648679, 12.9198356],
                [108.7648233, 12.9198987],
                [108.7647714, 12.9199563],
                [108.7647128, 12.9200075],
                [108.7646003, 12.920078],
                [108.7643077, 12.9202032],
                [108.7640154, 12.9202974],
                [108.7638696, 12.9204956],
                [108.7635583, 12.9208775],
                [108.7632653, 12.9211948],
                [108.7627893, 12.9216721],
                [108.7627112, 12.9217602],
                [108.7626388, 12.9218528],
                [108.7625261, 12.9220257],
                [108.762453, 12.9221875],
                [108.7624074, 12.9223587],
                [108.7623954, 12.9224463],
                [108.7623808, 12.9227466],
                [108.7623253, 12.9231638],
                [108.7622988, 12.9235705],
                [108.7622873, 12.9236627],
                [108.7622388, 12.9238423],
                [108.7622022, 12.9239282],
                [108.7621057, 12.9240883],
                [108.7620467, 12.9241613],
                [108.7619492, 12.9242574],
                [108.7618589, 12.92436],
                [108.7617966, 12.9244402],
                [108.7617408, 12.9245247],
                [108.7616497, 12.9247051],
                [108.7615513, 12.9250155],
                [108.7614781, 12.9253325],
                [108.7614442, 12.925541],
                [108.7613874, 12.926018],
                [108.7613208, 12.9262791],
                [108.7612748, 12.926406],
                [108.7611869, 12.9265972],
                [108.7610755, 12.9267941],
                [108.7608678, 12.9271219],
                [108.7607642, 12.9273346],
                [108.7606966, 12.9275206],
                [108.7606362, 12.9277521],
                [108.7606073, 12.9279089],
                [108.7605876, 12.928067],
                [108.760578, 12.928317],
                [108.7605855, 12.928442],
                [108.7606251, 12.9286892],
                [108.760687, 12.9289024],
                [108.7608343, 12.9293218],
                [108.7609222, 12.9295177],
                [108.7610393, 12.9296985],
                [108.7611703, 12.9298478],
                [108.7615445, 12.9301941],
                [108.7616219, 12.9302834],
                [108.7617275, 12.9304304],
                [108.7617808, 12.930544],
                [108.7617988, 12.930604],
                [108.7618159, 12.9307185],
                [108.7618022, 12.9308239],
                [108.7617836, 12.930874],
                [108.7617248, 12.9309635],
                [108.7616536, 12.9310249],
                [108.7615764, 12.9310791],
                [108.7614942, 12.9311256],
                [108.7611629, 12.9312665],
                [108.7610586, 12.9313369],
                [108.7609738, 12.9314288],
                [108.7609375, 12.9314862],
                [108.7608173, 12.9317469],
                [108.7607282, 12.9318904],
                [108.7606731, 12.931955],
                [108.7605447, 12.9320667],
                [108.760373, 12.932166],
                [108.7600991, 12.9322873],
                [108.759827, 12.932429],
                [108.75893, 12.9329751],
                [108.75871, 12.9331228],
                [108.758428, 12.9333325],
                [108.7581629, 12.933562],
                [108.7578976, 12.933826],
                [108.7578036, 12.9338871],
                [108.7577512, 12.933908],
                [108.7576401, 12.9339286],
                [108.757544, 12.9339232],
                [108.7574279, 12.9338966],
                [108.7572827, 12.933848],
                [108.7566161, 12.933578],
                [108.7565089, 12.9335618],
                [108.7564548, 12.9335665],
                [108.7564021, 12.9335797],
                [108.7563068, 12.93363],
                [108.7561834, 12.933745],
                [108.7560815, 12.9338255],
                [108.755974, 12.9338988],
                [108.755794, 12.9339992],
                [108.7556707, 12.9340863],
                [108.7555665, 12.9341946],
                [108.7554764, 12.9343306],
                [108.7554055, 12.934477],
                [108.7553676, 12.9345858],
                [108.7553179, 12.9347723],
                [108.7552525, 12.9350512],
                [108.755246, 12.935104],
                [108.7552543, 12.9352097],
                [108.7552905, 12.9353097],
                [108.7553182, 12.9353554],
                [108.7554186, 12.9354706],
                [108.7554627, 12.9355587],
                [108.7554816, 12.935671],
                [108.755478, 12.935736],
                [108.7554645, 12.9357997],
                [108.7554188, 12.9359097],
                [108.7553572, 12.9360097],
                [108.7550078, 12.9364528],
                [108.7548035, 12.9366649],
                [108.7546452, 12.9367991],
                [108.7546041, 12.9368426],
                [108.7545425, 12.9369441],
                [108.7545122, 12.9370582],
                [108.7545164, 12.9371804],
                [108.7545543, 12.9373016],
                [108.7546077, 12.937391],
                [108.75476, 12.9375684],
                [108.7547866, 12.9376177],
                [108.7548167, 12.937725],
                [108.7548196, 12.9377807],
                [108.7548009, 12.9378903],
                [108.7547797, 12.9379421],
                [108.7546828, 12.938082],
                [108.7546287, 12.9381874],
                [108.7545802, 12.9383497],
                [108.7545644, 12.9384889],
                [108.7545648, 12.9385823],
                [108.7545889, 12.9387536],
                [108.7546761, 12.9390487],
                [108.7546898, 12.9391156],
                [108.7546956, 12.9391836],
                [108.7546832, 12.9393193],
                [108.7546394, 12.9394487],
                [108.7545666, 12.939565],
                [108.754333, 12.939799],
                [108.7542155, 12.9399614],
                [108.7541578, 12.940059],
                [108.7541062, 12.9401597],
                [108.7540215, 12.9403692],
                [108.753969, 12.9405577],
                [108.7539076, 12.9407437],
                [108.7538375, 12.9409267],
                [108.7536845, 12.9412572],
                [108.7535552, 12.9414839],
                [108.7534832, 12.9416542],
                [108.7534448, 12.9418345],
                [108.7534414, 12.9420187],
                [108.7534709, 12.9421923],
                [108.753496, 12.9422727],
                [108.7535594, 12.9424129],
                [108.7535977, 12.9425463],
                [108.753604, 12.9426154],
                [108.7535903, 12.9427533],
                [108.7535705, 12.94282],
                [108.7535067, 12.9429437],
                [108.7533762, 12.9430962],
                [108.7533402, 12.9431506],
                [108.7533103, 12.9432073],
                [108.7532405, 12.9433861],
                [108.7532033, 12.9435083],
                [108.7531548, 12.9437587],
                [108.7531438, 12.9438857],
                [108.7531446, 12.9440859],
                [108.7531281, 12.9442303],
                [108.7531089, 12.9443006],
                [108.7530395, 12.94447],
                [108.7530261, 12.9445234],
                [108.7530165, 12.9446106],
                [108.7529879, 12.9446693],
                [108.7529332, 12.9447066],
                [108.7529004, 12.9447138],
                [108.7527984, 12.944711],
                [108.7527004, 12.9447389],
                [108.752656, 12.9447637],
                [108.7525406, 12.9448634],
                [108.7524955, 12.9448881],
                [108.7524469, 12.9449055],
                [108.7523507, 12.9449169],
                [108.7522623, 12.9448978],
                [108.7522219, 12.9448775],
                [108.7521547, 12.9448183],
                [108.7520979, 12.9447418],
                [108.7520635, 12.9447085],
                [108.7519629, 12.944642],
                [108.7519071, 12.9446185],
                [108.7517884, 12.9445926],
                [108.7517276, 12.9445908],
                [108.751667, 12.9445963],
                [108.7515595, 12.9446255],
                [108.7514727, 12.944677],
                [108.7514036, 12.9447512],
                [108.751269, 12.944993],
                [108.7512447, 12.9450234],
                [108.7512161, 12.9450498],
                [108.751097, 12.9451264],
                [108.7509121, 12.9452812],
                [108.7508717, 12.9453299],
                [108.7507666, 12.9455156],
                [108.7507197, 12.945575],
                [108.7506329, 12.9456564],
                [108.7505543, 12.9456924],
                [108.7504676, 12.9456993],
                [108.7504099, 12.9456867],
                [108.7503013, 12.9456417],
                [108.7502289, 12.9455868],
                [108.7501818, 12.94551],
                [108.7501699, 12.9454666],
                [108.7501672, 12.9453882],
                [108.7501858, 12.9452426],
                [108.7501795, 12.9452125],
                [108.7501435, 12.9451629],
                [108.7501164, 12.9451472],
                [108.7500545, 12.9451399],
                [108.7500139, 12.9451542],
                [108.7498208, 12.945286],
                [108.7496844, 12.9453464],
                [108.7495356, 12.9453948],
                [108.7494658, 12.9454299],
                [108.7493587, 12.9455035],
                [108.7492635, 12.9455463],
                [108.749212, 12.9455565],
                [108.7491072, 12.9455532],
                [108.7490565, 12.9455398],
                [108.7489229, 12.9454736],
                [108.7488278, 12.9454537],
                [108.7487307, 12.9454608],
                [108.7486102, 12.9455006],
                [108.7485161, 12.945504],
                [108.7483202, 12.9455374],
                [108.7482207, 12.9455717],
                [108.7481762, 12.9455996],
                [108.7480402, 12.9457254],
                [108.7479105, 12.9457925],
                [108.7477697, 12.9458332],
                [108.7475714, 12.9458554],
                [108.747452, 12.9458948],
                [108.7473629, 12.9459459],
                [108.747334, 12.9459734],
                [108.7472945, 12.9460417],
                [108.7472849, 12.946083],
                [108.7472835, 12.9461674],
                [108.7473105, 12.9462588],
                [108.7473653, 12.9463503],
                [108.7474408, 12.9464264],
                [108.7475943, 12.9465218],
                [108.7477563, 12.9466666],
                [108.747821, 12.9467093],
                [108.7479222, 12.9467584],
                [108.7479755, 12.9468023],
                [108.7480075, 12.9468627],
                [108.7480134, 12.9469305],
                [108.7480046, 12.9469681],
                [108.7479676, 12.9470361],
                [108.7479405, 12.9470642],
                [108.7478826, 12.9471008],
                [108.7477981, 12.9471275],
                [108.7477005, 12.9471393],
                [108.7475828, 12.9471313],
                [108.7475057, 12.9471159],
                [108.7474307, 12.9470925],
                [108.74727, 12.9470226],
                [108.7472217, 12.9470156],
                [108.7471251, 12.9470272],
                [108.74708, 12.9470455],
                [108.7470223, 12.9470852],
                [108.7469702, 12.9471316],
                [108.7469074, 12.9472074],
                [108.7468627, 12.9472441],
                [108.74672, 12.9472966],
                [108.7466078, 12.9473635],
                [108.7465087, 12.9474114],
                [108.7464605, 12.9474274],
                [108.7463598, 12.9474402],
                [108.7463091, 12.9474369],
                [108.7461896, 12.9474133],
                [108.7461205, 12.9474295],
                [108.7460902, 12.947448],
                [108.7460444, 12.9474998],
                [108.7460178, 12.9475631],
                [108.7460123, 12.9476041],
                [108.7459874, 12.9476801],
                [108.7459826, 12.9477569],
                [108.7460043, 12.94782],
                [108.7460124, 12.9478754],
                [108.7459805, 12.947974],
                [108.7459241, 12.9480492],
                [108.745826, 12.948129],
                [108.7456898, 12.9482126],
                [108.745649, 12.948227],
                [108.7455854, 12.9482296],
                [108.745529, 12.9482009],
                [108.7454948, 12.9481486],
                [108.7454773, 12.9480578],
                [108.7454412, 12.9479863],
                [108.7453877, 12.9479449],
                [108.7453204, 12.9479329],
                [108.74529, 12.9479382],
                [108.7450163, 12.948045],
                [108.7446715, 12.9481567],
                [108.7445653, 12.9482018],
                [108.7443665, 12.948308],
                [108.744186, 12.9484219],
                [108.7439572, 12.9485328],
                [108.7438637, 12.9485475],
                [108.7437704, 12.9485321],
                [108.7436871, 12.9484883],
                [108.7436521, 12.9484571],
                [108.7435859, 12.9483558],
                [108.7435531, 12.9482401],
                [108.7435569, 12.9480544],
                [108.7435376, 12.9479333],
                [108.743492, 12.9478226],
                [108.7434596, 12.9477718],
                [108.7433779, 12.9476831],
                [108.7433283, 12.9476479],
                [108.7432739, 12.9476203],
                [108.7432159, 12.9476011],
                [108.7431555, 12.9475907],
                [108.7430358, 12.9475963],
                [108.7428661, 12.947643],
                [108.7426606, 12.947727],
                [108.7425131, 12.9478065],
                [108.7423935, 12.9478439],
                [108.7423311, 12.9478517],
                [108.7422057, 12.9478449],
                [108.7421446, 12.9478304],
                [108.7419814, 12.9477651],
                [108.7419256, 12.9477544],
                [108.7418688, 12.9477515],
                [108.7417751, 12.9477641],
                [108.7416673, 12.9477983],
                [108.7415162, 12.9478652],
                [108.7411422, 12.9480018],
                [108.7407946, 12.9481687],
                [108.7407206, 12.9481899],
                [108.7405676, 12.9482061],
                [108.7404962, 12.9482016],
                [108.7402856, 12.9481632],
                [108.7401476, 12.9481275],
                [108.7400125, 12.9480824],
                [108.7396736, 12.9479368],
                [108.7395653, 12.9479022],
                [108.7393426, 12.9478565],
                [108.7392292, 12.9478455],
                [108.7390016, 12.9478478],
                [108.7387882, 12.9478797],
                [108.738592, 12.9479347],
                [108.7383, 12.9480498],
                [108.7381969, 12.9480761],
                [108.7380918, 12.9480935],
                [108.7379856, 12.9481017],
                [108.737879, 12.9481008],
                [108.737773, 12.9480906],
                [108.7376683, 12.9480712],
                [108.7375657, 12.9480429],
                [108.7374662, 12.9480059],
                [108.7371986, 12.9478696],
                [108.7370206, 12.9478095],
                [108.7369317, 12.9477919],
                [108.7367508, 12.9477811],
                [108.7366604, 12.9477878],
                [108.736571, 12.9478027],
                [108.7363982, 12.9478561],
                [108.7362385, 12.9479395],
                [108.736155, 12.9479996],
                [108.7359777, 12.9481043],
                [108.7357836, 12.9481891],
                [108.7356868, 12.948238],
                [108.7355947, 12.948295],
                [108.7354847, 12.9483833],
                [108.7353949, 12.9484912],
                [108.7353587, 12.9485512],
                [108.7352386, 12.9488391],
                [108.7351047, 12.9491068],
                [108.734957, 12.9493528],
                [108.7348124, 12.9495575],
                [108.734703, 12.949741],
                [108.7346184, 12.9499366],
                [108.7345013, 12.9502911],
                [108.7344209, 12.9504597],
                [108.734318, 12.9506162],
                [108.7341195, 12.9508567],
                [108.7339801, 12.9510554],
                [108.7338749, 12.9512587],
                [108.7337288, 12.9516694],
                [108.7336283, 12.9518517],
                [108.7335114, 12.952002],
                [108.7334335, 12.9520511],
                [108.7332663, 12.9521293],
                [108.7326944, 12.9523363],
                [108.732588, 12.9523963],
                [108.73247, 12.9524805],
                [108.7324057, 12.9525145],
                [108.7322827, 12.9525611],
                [108.732225, 12.9525723],
                [108.7321074, 12.9525725],
                [108.7319937, 12.9525434],
                [108.7318915, 12.9524869],
                [108.7318468, 12.9524495],
                [108.7317106, 12.9522762],
                [108.7315891, 12.952167],
                [108.7315205, 12.9521219],
                [108.7313381, 12.952037],
                [108.7312425, 12.9520048],
                [108.7310453, 12.9519617],
                [108.7309447, 12.9519511],
                [108.7307426, 12.951952],
                [108.7305307, 12.9519683],
                [108.7304245, 12.9519642],
                [108.7301236, 12.9519224],
                [108.7300248, 12.9519193],
                [108.7298281, 12.9519368],
                [108.7295501, 12.9520039],
                [108.7293639, 12.9520261],
                [108.7292701, 12.9520278],
                [108.7289458, 12.9520102],
                [108.7287833, 12.9520106],
                [108.728621, 12.9520173],
                [108.7282023, 12.9520549],
                [108.7280848, 12.9520708],
                [108.7278539, 12.9521234],
                [108.7277413, 12.9521598],
                [108.7275345, 12.9522472],
                [108.7274341, 12.952283],
                [108.7273308, 12.9523099],
                [108.7272255, 12.9523277],
                [108.7270507, 12.9523368],
                [108.7268465, 12.9523236],
                [108.7267112, 12.9523061],
                [108.7264618, 12.9522538],
                [108.7262265, 12.9522249],
                [108.7259893, 12.9522311],
                [108.7257026, 12.9522759],
                [108.725617, 12.9522815],
                [108.7254708, 12.9522776],
                [108.7253542, 12.9522476],
                [108.7252999, 12.9522218],
                [108.7252495, 12.9521893],
                [108.7251336, 12.9520854],
                [108.7250472, 12.9520424],
                [108.7250001, 12.9520304],
                [108.7249517, 12.9520251],
                [108.724885, 12.9520291],
                [108.7248194, 12.9520414],
                [108.7246733, 12.9520908],
                [108.7245855, 12.9520935],
                [108.7245011, 12.9520696],
                [108.7243769, 12.9520006],
                [108.72431, 12.9519756],
                [108.7240936, 12.9519251],
                [108.7239325, 12.9518552],
                [108.7238774, 12.9518387],
                [108.7237628, 12.9518271],
                [108.7237054, 12.9518321],
                [108.7236491, 12.9518443],
                [108.7235436, 12.9518892],
                [108.7233338, 12.9520526],
                [108.7232095, 12.9521287],
                [108.723101, 12.9522055],
                [108.7230401, 12.9522591],
                [108.7229324, 12.9523797],
                [108.7228344, 12.9525097],
                [108.722772, 12.9526202],
                [108.7227033, 12.9526731],
                [108.7226622, 12.9526885],
                [108.7225748, 12.9526939],
                [108.7224922, 12.9526656],
                [108.7224431, 12.9526264],
                [108.7223886, 12.9525947],
                [108.7223299, 12.9525712],
                [108.7222683, 12.9525564],
                [108.7221642, 12.9525516],
                [108.7220429, 12.9525708],
                [108.7219644, 12.9525933],
                [108.7217243, 12.9527029],
                [108.7215252, 12.9527632],
                [108.7214586, 12.9527933],
                [108.7213361, 12.9528721],
                [108.7212627, 12.952942],
                [108.7212074, 12.9530263],
                [108.7211473, 12.9531503],
                [108.7210576, 12.953256],
                [108.7209439, 12.9533367],
                [108.7207526, 12.9534132],
                [108.7206924, 12.9534445],
                [108.720602, 12.9535052],
                [108.7205176, 12.9535735],
                [108.7204397, 12.9536488],
                [108.720369, 12.9537307],
                [108.7203062, 12.9538183],
                [108.7202009, 12.9540021],
                [108.7201191, 12.954087],
                [108.720015, 12.9541443],
                [108.7199575, 12.9541607],
                [108.7198722, 12.9541692],
                [108.7197885, 12.9541869],
                [108.7196379, 12.9542451],
                [108.7193346, 12.9544484],
                [108.719156, 12.9545338],
                [108.7189662, 12.9545919],
                [108.7188355, 12.9546149],
                [108.7188024, 12.9546104],
                [108.7187486, 12.9545789],
                [108.7187193, 12.9545284],
                [108.7187184, 12.9544704],
                [108.7187317, 12.9544248],
                [108.7192913, 12.9532075],
                [108.7193707, 12.9529849],
                [108.7194554, 12.952727],
                [108.7194804, 12.9526085],
                [108.7194958, 12.9524884],
                [108.7195015, 12.9523676],
                [108.7194893, 12.952166],
                [108.7194504, 12.9519678],
                [108.719421, 12.9518709],
                [108.7193412, 12.9516911],
                [108.7192906, 12.9516064],
                [108.7191865, 12.951469],
                [108.7190831, 12.9513638],
                [108.7189343, 12.951248],
                [108.7188086, 12.9511075],
                [108.7187558, 12.9510292],
                [108.7186192, 12.9506921],
                [108.7185144, 12.9503443],
                [108.7184717, 12.9501548],
                [108.7184385, 12.9499634],
                [108.7184149, 12.9497707],
                [108.7184009, 12.949577],
                [108.7183974, 12.9490677],
                [108.7183656, 12.948827],
                [108.7183033, 12.9485921],
                [108.718261, 12.9484779],
                [108.718155, 12.9482584],
                [108.7180272, 12.9480607],
                [108.7178761, 12.9478793],
                [108.7177875, 12.9478401],
                [108.7176856, 12.9478349],
                [108.717522, 12.947895],
                [108.7174255, 12.9479134],
                [108.7173276, 12.9479044],
                [108.7172392, 12.9478704],
                [108.7171661, 12.9478163],
                [108.7171358, 12.9477826],
                [108.7170532, 12.9476493],
                [108.7170011, 12.9475901],
                [108.7168827, 12.9474931],
                [108.7167506, 12.9474248],
                [108.7165839, 12.9473708],
                [108.7164915, 12.9473317],
                [108.7164034, 12.9472841],
                [108.7163204, 12.9472285],
                [108.716193, 12.9471167],
                [108.7160862, 12.946986],
                [108.715623, 12.9462456],
                [108.7155672, 12.9461737],
                [108.7154899, 12.9461242],
                [108.7154013, 12.9461027],
                [108.7153116, 12.9461107],
                [108.7152191, 12.9461453],
                [108.7151134, 12.9461649],
                [108.7150061, 12.9461567],
                [108.7148959, 12.9461164],
                [108.7148022, 12.9460469],
                [108.7147281, 12.9459607],
                [108.7146817, 12.9459269],
                [108.7145346, 12.9458607],
                [108.7144495, 12.9457975],
                [108.7143953, 12.945721],
                [108.7142497, 12.9454745],
                [108.7141129, 12.9452679],
                [108.7140379, 12.9451971],
                [108.7139832, 12.9451569],
                [108.713853, 12.9450864],
                [108.7137774, 12.9450586],
                [108.7136196, 12.9450263],
                [108.7135389, 12.9450222],
                [108.7133695, 12.9450392],
                [108.7132073, 12.9450901],
                [108.7131312, 12.9451277],
                [108.7129932, 12.945225],
                [108.7128826, 12.9453422],
                [108.712838, 12.9454049],
                [108.7127667, 12.9455405],
                [108.7127209, 12.9456862],
                [108.7126753, 12.9459188],
                [108.7126539, 12.945993],
                [108.7125921, 12.9461346],
                [108.7125063, 12.9462639],
                [108.7124577, 12.946313],
                [108.7124024, 12.9463549],
                [108.7123416, 12.9463888],
                [108.7122766, 12.946414],
                [108.7122085, 12.9464299],
                [108.7121388, 12.9464364],
                [108.7120145, 12.9464247],
                [108.7119543, 12.9464077],
                [108.7118427, 12.9463531],
                [108.7115894, 12.946159],
                [108.7113529, 12.94602],
                [108.7112645, 12.9459541],
                [108.7111824, 12.9458808],
                [108.711107, 12.9458009],
                [108.7110391, 12.9457149],
                [108.7109811, 12.945626],
                [108.7109314, 12.9455324],
                [108.7108903, 12.945435],
                [108.7108582, 12.9453343],
                [108.7108017, 12.9451097],
                [108.7107147, 12.9448725],
                [108.7106354, 12.9446998],
                [108.7106019, 12.9446458],
                [108.7105053, 12.9445299],
                [108.7104646, 12.9444397],
                [108.7104542, 12.9443914],
                [108.7104523, 12.9442397],
                [108.7104438, 12.9441871],
                [108.7103883, 12.9440215],
                [108.7103667, 12.9438876],
                [108.7103849, 12.9435451],
                [108.7103593, 12.9433084],
                [108.7103332, 12.9431908],
                [108.7098855, 12.9432518],
                [108.7095889, 12.9432537],
                [108.7094409, 12.9432432],
                [108.7091477, 12.9431994],
                [108.7090033, 12.9431663],
                [108.7087077, 12.943073],
                [108.7084245, 12.9429485],
                [108.7081572, 12.9427942],
                [108.7077661, 12.9425103],
                [108.7076181, 12.9424159],
                [108.7073083, 12.9422495],
                [108.7070063, 12.9421234],
                [108.7068622, 12.9420772],
                [108.7067154, 12.94204],
                [108.7065666, 12.9420117],
                [108.7064162, 12.9419925],
                [108.7062648, 12.9419824],
                [108.7061131, 12.9419816],
                [108.7058222, 12.9420058],
                [108.7056838, 12.9420296],
                [108.7054125, 12.9421002],
                [108.7052804, 12.9421468],
                [108.7050849, 12.9422391],
                [108.7047064, 12.9424472],
                [108.704347, 12.9426853],
                [108.7041433, 12.9428454],
                [108.7040486, 12.9429335],
                [108.7038749, 12.9431244],
                [108.7037892, 12.9432368],
                [108.7036396, 12.9434759],
                [108.7035209, 12.9437309],
                [108.7034176, 12.9440191],
                [108.7032848, 12.9442955],
                [108.7032218, 12.9444055],
                [108.7030167, 12.9447263],
                [108.7027225, 12.9451406],
                [108.7024018, 12.9455388],
                [108.7020554, 12.9459191],
                [108.7018735, 12.9461014],
                [108.7014931, 12.9464495],
                [108.7012361, 12.9467124],
                [108.7011169, 12.9468521],
                [108.7008986, 12.9471463],
                [108.7007998, 12.9473003],
                [108.7006241, 12.9476206],
                [108.7005006, 12.9478988],
                [108.7003659, 12.9482767],
                [108.700342, 12.9483708],
                [108.7003271, 12.9484667],
                [108.7003215, 12.9485635],
                [108.7003252, 12.9486604],
                [108.7003381, 12.9487566],
                [108.7003602, 12.9488511],
                [108.7003913, 12.9489432],
                [108.7004756, 12.9491116],
                [108.7005271, 12.9491869],
                [108.7006494, 12.949323],
                [108.7007941, 12.9494364],
                [108.7009567, 12.9495236],
                [108.7018901, 12.9498582],
                [108.702057, 12.9499248],
                [108.70222, 12.9499999],
                [108.7023787, 12.9500833],
                [108.7025326, 12.9501747],
                [108.7026814, 12.950274],
                [108.7028246, 12.9503808],
                [108.7029618, 12.9504948],
                [108.7030926, 12.9506157],
                [108.7032167, 12.9507432],
                [108.7033337, 12.9508769],
                [108.7034432, 12.9510165],
                [108.7035451, 12.9511615],
                [108.7036389, 12.9513115],
                [108.7037046, 12.9514286],
                [108.7038838, 12.9517887],
                [108.7039938, 12.952033],
                [108.7040952, 12.9522809],
                [108.7041879, 12.9525319],
                [108.7043449, 12.9530345],
                [108.7044656, 12.9535382],
                [108.7045523, 12.9540486],
                [108.7045827, 12.9543055],
                [108.7046536, 12.955251],
                [108.7046879, 12.9555941],
                [108.7047288, 12.9559365],
                [108.7048266, 12.9565984],
                [108.7048828, 12.9569179],
                [108.7050122, 12.9575536],
                [108.705089, 12.9578265],
                [108.7052181, 12.958378],
                [108.7053142, 12.958936],
                [108.705377, 12.9594984],
                [108.705405, 12.9600139],
                [108.7053984, 12.961119],
                [108.7054055, 12.9613938],
                [108.705449, 12.9616654],
                [108.7054843, 12.9617986],
                [108.7055931, 12.9621359],
                [108.7056714, 12.9624811],
                [108.7056988, 12.9626559],
                [108.7057296, 12.9629951],
                [108.705731, 12.9633355],
                [108.7055594, 12.9645588],
                [108.7055232, 12.9647049],
                [108.7054275, 12.9649905],
                [108.7053016, 12.9652648],
                [108.7051469, 12.9655247],
                [108.7049651, 12.9657674],
                [108.704758, 12.9659904],
                [108.7046456, 12.9660937],
                [108.7044049, 12.9662824],
                [108.7042773, 12.9663672],
                [108.7039615, 12.9665441],
                [108.7033421, 12.9669176],
                [108.7027398, 12.9673166],
                [108.702267, 12.9676567],
                [108.7020834, 12.9677808],
                [108.7017015, 12.9680068],
                [108.7015039, 12.9681084],
                [108.7011345, 12.9682728],
                [108.7009454, 12.9683451],
                [108.7005597, 12.9684691],
                [108.699682, 12.9688083],
                [108.6992484, 12.9689902],
                [108.6983925, 12.9693787],
                [108.6980316, 12.9695545],
                [108.6976752, 12.9697386],
                [108.6973233, 12.969931],
                [108.696465, 12.9704324],
                [108.6962037, 12.9705734],
                [108.6959388, 12.9707079],
                [108.6953992, 12.9709574],
                [108.6950993, 12.9710824],
                [108.6944897, 12.971308],
                [108.6941804, 12.9714086],
                [108.6935538, 12.9715847],
                [108.6933053, 12.9716758],
                [108.6931856, 12.9717319],
                [108.6929573, 12.971864],
                [108.6927465, 12.9720213],
                [108.692556, 12.9722016],
                [108.692471, 12.9722975],
                [108.6923185, 12.9725028],
                [108.6922516, 12.9726115],
                [108.6921376, 12.9728393],
                [108.6920562, 12.9732292],
                [108.6920262, 12.973426],
                [108.6919874, 12.9738221],
                [108.6919951, 12.9746903],
                [108.6920249, 12.9752126],
                [108.6920934, 12.9758053],
                [108.6921414, 12.9760998],
                [108.6922219, 12.9764994],
                [108.6922502, 12.9767012],
                [108.6922826, 12.9771072],
                [108.6922866, 12.9773108],
                [108.6922704, 12.9777177],
                [108.6922198, 12.9781356],
                [108.6921803, 12.9783495],
                [108.6920742, 12.9787715],
                [108.6919327, 12.9791835],
                [108.6917567, 12.9795827],
                [108.6916723, 12.979739],
                [108.6915792, 12.9798905],
                [108.6914776, 12.9800368],
                [108.691368, 12.9801774],
                [108.6912506, 12.980312],
                [108.6911258, 12.9804401],
                [108.690994, 12.9805613],
                [108.6908555, 12.9806752],
                [108.6906133, 12.9808467],
                [108.6904864, 12.9809242],
                [108.6902225, 12.981062],
                [108.6895037, 12.9813495],
                [108.6892829, 12.9814107],
                [108.6888349, 12.9815079],
                [108.6886083, 12.9815438],
                [108.6881518, 12.98159],
                [108.6877656, 12.9816022],
                [108.6873793, 12.98159],
                [108.6856199, 12.9816322],
                [108.6849116, 12.9816322],
                [108.6841894, 12.9816024],
                [108.6838216, 12.9815757],
                [108.6834545, 12.9815412],
                [108.682723, 12.9814488],
                [108.6826279, 12.9814421],
                [108.6824371, 12.9814448],
                [108.6822308, 12.9814719],
                [108.6821209, 12.9814972],
                [108.6819076, 12.9815691],
                [108.6810171, 12.9819873],
                [108.6804982, 12.982221],
                [108.6799765, 12.9824488],
                [108.678925, 12.9828864],
                [108.6787996, 12.9829507],
                [108.6785617, 12.9831007],
                [108.6784501, 12.9831857],
                [108.6782433, 12.9833745],
                [108.678061, 12.9835858],
                [108.6779058, 12.9838168],
                [108.6777825, 12.984058],
                [108.6776888, 12.9843114],
                [108.6776535, 12.9844417],
                [108.6776063, 12.9847071],
                [108.6775755, 12.9854503],
                [108.6775639, 12.9855465],
                [108.6775197, 12.9857351],
                [108.6774874, 12.9858267],
                [108.6774098, 12.98599],
                [108.6773108, 12.9861418],
                [108.6769862, 12.9865797],
                [108.6765974, 12.9871339],
                [108.6764722, 12.9872989],
                [108.6763396, 12.9874583],
                [108.6761999, 12.9876118],
                [108.6759362, 12.9878679],
                [108.6749755, 12.9887448],
                [108.6747085, 12.9890109],
                [108.674582, 12.9891503],
                [108.6743438, 12.9894413],
                [108.6742084, 12.9896265],
                [108.6740808, 12.9898169],
                [108.6739614, 12.9900123],
                [108.6735543, 12.990741],
                [108.6734169, 12.9910108],
                [108.6731637, 12.9915603],
                [108.6730481, 12.9918396],
                [108.6728645, 12.9923328],
                [108.672699, 12.9928663],
                [108.6725575, 12.99344],
                [108.6725432, 12.9935417],
                [108.6725383, 12.9936442],
                [108.6725427, 12.9937467],
                [108.6725563, 12.9938484],
                [108.6725792, 12.9939486],
                [108.67265, 12.9941375],
                [108.672751, 12.9943083],
                [108.6728803, 12.99446],
                [108.6731962, 12.994725],
                [108.673443, 12.9949167],
                [108.6739685, 12.9952933],
                [108.6745315, 12.9956536],
                [108.6750895, 12.9959856],
                [108.6754513, 12.9961664],
                [108.6753047, 12.9962107],
                [108.6751711, 12.996284],
                [108.6750613, 12.9963777],
                [108.6749771, 12.9964845],
                [108.6749151, 12.996605],
                [108.6749037, 12.9966678],
                [108.6748596, 12.9967878],
                [108.6747891, 12.9968951],
                [108.6747067, 12.9969758],
                [108.6745225, 12.9970898],
                [108.6744592, 12.9971669],
                [108.6744275, 12.9972585],
                [108.6744243, 12.9973069],
                [108.6744357, 12.997505],
                [108.6744319, 12.9976549],
                [108.6744184, 12.9978043],
                [108.6743952, 12.9979526],
                [108.6743625, 12.9980992],
                [108.6743202, 12.9982434],
                [108.6742687, 12.9983848],
                [108.6742109, 12.9985168],
                [108.6740711, 12.9987695],
                [108.673901, 12.9990039],
                [108.6737031, 12.9992164],
                [108.6734801, 12.999404],
                [108.6720398, 13.0005174],
                [108.6716813, 13.0008376],
                [108.670983, 13.0014974],
                [108.6707022, 13.0017772],
                [108.6701542, 13.0023494],
                [108.6696866, 13.0025798],
                [108.6692047, 13.0027806],
                [108.6687686, 13.0029327],
                [108.6685474, 13.0029997],
                [108.6680996, 13.0031151],
                [108.6675933, 13.0031469],
                [108.6670864, 13.0031697],
                [108.6665792, 13.0031835],
                [108.6656044, 13.0031848],
                [108.66467, 13.0031547],
                [108.6642033, 13.0031282],
                [108.6632717, 13.0030524],
                [108.6613405, 13.0021429],
                [108.6607718, 13.0015471],
                [108.6599886, 13.0010871],
                [108.6594468, 13.0006472],
                [108.6587357, 13.0000175],
                [108.6581799, 12.9995613],
                [108.6579799, 12.9994373],
                [108.6578733, 12.9993867],
                [108.6576497, 12.99931],
                [108.657453, 12.9992714],
                [108.6572528, 12.9992577],
                [108.6570319, 12.9994636],
                [108.6567904, 12.9996462],
                [108.6565204, 12.9998091],
                [108.656223, 12.9999472],
                [108.6559117, 13.0000522],
                [108.653208, 13.0010767],
                [108.6529389, 13.0011904],
                [108.6526737, 13.0013127],
                [108.6524128, 13.0014434],
                [108.6521565, 13.0015825],
                [108.6519051, 13.0017297],
                [108.6516587, 13.0018848],
                [108.6514177, 13.0020479],
                [108.6511824, 13.0022185],
                [108.6509529, 13.0023966],
                [108.6507296, 13.002582],
                [108.6503171, 13.0029594],
                [108.6501203, 13.0031575],
                [108.6497465, 13.0035715],
                [108.6494005, 13.0040077],
                [108.6490134, 13.0045347],
                [108.648781, 13.0048304],
                [108.6485412, 13.0051203],
                [108.648084, 13.0056357],
                [108.6476494, 13.0060852],
                [108.645317, 13.0084047],
                [108.6452091, 13.0085615],
                [108.6451299, 13.0087338],
                [108.6450817, 13.0089166],
                [108.6450658, 13.0091048],
                [108.6450809, 13.0092821],
                [108.6451212, 13.0094446],
                [108.6451507, 13.0095232],
                [108.6452173, 13.0096282],
                [108.6452573, 13.0097452],
                [108.6452687, 13.0098682],
                [108.6452629, 13.0099329],
                [108.645249, 13.0099966],
                [108.6451981, 13.0101165],
                [108.6451618, 13.0101711],
                [108.6450136, 13.010326],
                [108.644963, 13.0103913],
                [108.6448809, 13.0105341],
                [108.6448261, 13.0106919],
                [108.6448099, 13.0107754],
                [108.644802, 13.0109449],
                [108.6445786, 13.0109444],
                [108.6444674, 13.0109331],
                [108.6442487, 13.0108888],
                [108.6441421, 13.010856],
                [108.6439236, 13.0107629],
                [108.6438201, 13.0107051],
                [108.6436272, 13.0105685],
                [108.6434999, 13.010515],
                [108.6434322, 13.0104995],
                [108.6432938, 13.0104923],
                [108.6432248, 13.0105006],
                [108.6430103, 13.0105511],
                [108.6427903, 13.0105685],
                [108.6426036, 13.010557],
                [108.6423282, 13.0105054],
                [108.6421416, 13.0104965],
                [108.6420484, 13.0105036],
                [108.6418656, 13.010541],
                [108.6416919, 13.0106081],
                [108.6414615, 13.0107381],
                [108.6413811, 13.0107694],
                [108.6412125, 13.0108069],
                [108.6410396, 13.0108096],
                [108.6409539, 13.0107979],
                [108.64068, 13.0107173],
                [108.6404828, 13.0106871],
                [108.6402831, 13.0106877],
                [108.6400861, 13.0107192],
                [108.6398901, 13.0107834],
                [108.6397016, 13.0108819],
                [108.639533, 13.0110101],
                [108.6393893, 13.0111644],
                [108.6387563, 13.0107253],
                [108.6349475, 13.010349],
                [108.6345076, 13.0101399],
                [108.6308277, 13.0100563],
                [108.6304344, 13.0099105],
                [108.6302334, 13.0098497],
                [108.6298245, 13.0097532],
                [108.6294304, 13.0096928],
                [108.6292318, 13.0096741],
                [108.6288331, 13.0096598],
                [108.6286336, 13.0096643],
                [108.628535, 13.0096391],
                [108.6284392, 13.0096052],
                [108.6283468, 13.0095631],
                [108.6282588, 13.009513],
                [108.6280985, 13.0093906],
                [108.6279717, 13.009253],
                [108.6278769, 13.0091087],
                [108.6278385, 13.0090316],
                [108.6277807, 13.0088698],
                [108.6276882, 13.0082201],
                [108.6276484, 13.0080753],
                [108.6276157, 13.0080072],
                [108.6275272, 13.0078846],
                [108.6274119, 13.0077853],
                [108.6272788, 13.0077157],
                [108.6271357, 13.007678],
                [108.6270618, 13.0076711],
                [108.6269876, 13.0076723],
                [108.6268419, 13.0076991],
                [108.6264503, 13.0078611],
                [108.6263132, 13.0079391],
                [108.6260283, 13.0080756],
                [108.6257315, 13.0081852],
                [108.6254399, 13.0082636],
                [108.6252917, 13.0082931],
                [108.6249919, 13.0083323],
                [108.622949, 13.008513],
                [108.6226762, 13.008557],
                [108.6224096, 13.0086282],
                [108.62216, 13.0087224],
                [108.6220431, 13.0087766],
                [108.6218181, 13.0089012],
                [108.6208177, 13.0096591],
                [108.6186719, 13.0097114],
                [108.6181354, 13.0102863],
                [108.6169848, 13.009011],
                [108.6144447, 13.0075266],
                [108.6126959, 13.011363],
                [108.6125281, 13.0116702],
                [108.6123306, 13.0119603],
                [108.6121054, 13.0122304],
                [108.6118544, 13.0124781],
                [108.6115801, 13.012701],
                [108.611426, 13.0128085],
                [108.6112665, 13.0129082],
                [108.6111019, 13.0129998],
                [108.6109327, 13.013083],
                [108.6107594, 13.0131577],
                [108.6104543, 13.0132696],
                [108.610209, 13.0133856],
                [108.6099815, 13.0135321],
                [108.6098855, 13.0136078],
                [108.6097081, 13.0137753],
                [108.6096275, 13.0138666],
                [108.6084956, 13.0153509],
                [108.6083563, 13.0155124],
                [108.6080932, 13.0158474],
                [108.6078519, 13.0161976],
                [108.6076139, 13.0165961],
                [108.6074039, 13.0170093],
                [108.6069345, 13.0181785],
                [108.6068758, 13.0183103],
                [108.6067355, 13.018563],
                [108.6066545, 13.018683],
                [108.6064717, 13.0189084],
                [108.6063706, 13.0190131],
                [108.6061509, 13.0192045],
                [108.6059099, 13.0193702],
                [108.6056505, 13.019508],
                [108.6053765, 13.0196158],
                [108.6052351, 13.019658],
                [108.6049462, 13.0197182],
                [108.6047995, 13.019736],
                [108.604679, 13.0197369],
                [108.6044393, 13.0197145],
                [108.6043212, 13.0196912],
                [108.6040914, 13.019621],
                [108.603975, 13.0195718],
                [108.6038625, 13.0195145],
                [108.603652, 13.0193767],
                [108.603555, 13.019297],
                [108.6024606, 13.018121],
                [108.6023704, 13.0179907],
                [108.6023161, 13.0179325],
                [108.6021917, 13.0178325],
                [108.6020456, 13.0177558],
                [108.6018823, 13.0177076],
                [108.6017123, 13.0176924],
                [108.6015888, 13.017692],
                [108.6014657, 13.0177007],
                [108.6013437, 13.0177186],
                [108.6012233, 13.0177455],
                [108.6011055, 13.0177813],
                [108.6009908, 13.0178257],
                [108.6008771, 13.01788],
                [108.6007681, 13.0179428],
                [108.6006646, 13.0180137],
                [108.600567, 13.0180923],
                [108.6004261, 13.0182825],
                [108.6003642, 13.0183832],
                [108.6002585, 13.018594],
                [108.6001762, 13.0188214],
                [108.6001217, 13.0190566],
                [108.5999591, 13.0195672],
                [108.5998648, 13.0198182],
                [108.599651, 13.0203106],
                [108.5995316, 13.0205513],
                [108.5992713, 13.0210164],
                [108.5991295, 13.0212424],
                [108.5988235, 13.0216803],
                [108.5984942, 13.0220649],
                [108.5981572, 13.0224432],
                [108.5978128, 13.022815],
                [108.597461, 13.0231803],
                [108.5968202, 13.0238105],
                [108.5961574, 13.0244189],
                [108.5955626, 13.0252294],
                [108.595357, 13.025461],
                [108.5952453, 13.0255688],
                [108.5950055, 13.0257666],
                [108.5945431, 13.0260736],
                [108.5942289, 13.0263187],
                [108.5940786, 13.0264527],
                [108.5937971, 13.0267394],
                [108.5917532, 13.0297812],
                [108.5911125, 13.0292986],
                [108.5909103, 13.0291812],
                [108.590692, 13.0290955],
                [108.5904799, 13.0290461],
                [108.5902802, 13.0290271],
                [108.5893393, 13.0290704],
                [108.5891429, 13.0290984],
                [108.5889444, 13.0290975],
                [108.5888457, 13.0290861],
                [108.5886467, 13.02904],
                [108.5885506, 13.0290058],
                [108.5884575, 13.0289645],
                [108.5882825, 13.0288613],
                [108.5881735, 13.0288055],
                [108.5879451, 13.0287161],
                [108.5877063, 13.0286577],
                [108.5875845, 13.0286405],
                [108.5873299, 13.0286306],
                [108.5871984, 13.0286392],
                [108.5870679, 13.0286571],
                [108.5869391, 13.0286841],
                [108.5857612, 13.0290129],
                [108.5856833, 13.0290409],
                [108.5856028, 13.0290607],
                [108.5854376, 13.0290749],
                [108.585273, 13.0290547],
                [108.5849748, 13.0289632],
                [108.5846843, 13.0289057],
                [108.5844054, 13.0288782],
                [108.5839497, 13.0288755],
                [108.5837747, 13.0288653],
                [108.5834274, 13.0288189],
                [108.5830911, 13.0287398],
                [108.5827688, 13.0286299],
                [108.5826123, 13.0285634],
                [108.5823966, 13.0285055],
                [108.5821786, 13.0284565],
                [108.5819586, 13.0284166],
                [108.5817371, 13.0283859],
                [108.5815144, 13.0283644],
                [108.581291, 13.0283521],
                [108.5810673, 13.0283491],
                [108.5806376, 13.0283695],
                [108.5804237, 13.0283926],
                [108.5799998, 13.0284641],
                [108.5798719, 13.0285065],
                [108.5796088, 13.0285655],
                [108.57934, 13.0285896],
                [108.5790926, 13.0285804],
                [108.5788483, 13.0285416],
                [108.5787284, 13.0285112],
                [108.5775536, 13.0280774],
                [108.5772602, 13.0277835],
                [108.5771066, 13.0276433],
                [108.5767865, 13.0273771],
                [108.5764267, 13.0271146],
                [108.5762404, 13.0269921],
                [108.5758558, 13.0267656],
                [108.5758502, 13.0265062],
                [108.5758152, 13.0262491],
                [108.5757512, 13.0259973],
                [108.5756611, 13.0257588],
                [108.5756062, 13.0256435],
                [108.5754776, 13.0254224],
                [108.5746515, 13.0242987],
                [108.5745199, 13.0241401],
                [108.5744448, 13.0240689],
                [108.5742782, 13.0239454],
                [108.5741879, 13.0238937],
                [108.5740049, 13.0238153],
                [108.5738212, 13.0237668],
                [108.5737271, 13.0237524],
                [108.5733672, 13.0237264],
                [108.5732364, 13.0237029],
                [108.5731078, 13.0236702],
                [108.572982, 13.0236283],
                [108.5728598, 13.0235775],
                [108.5726436, 13.02346],
                [108.5725423, 13.0233907],
                [108.5724461, 13.0233147],
                [108.5720417, 13.0229626],
                [108.5714006, 13.0224381],
                [108.5707468, 13.021938],
                [108.5704136, 13.0216959],
                [108.5698342, 13.0204416],
                [108.5697266, 13.0202624],
                [108.5696625, 13.0201793],
                [108.5695158, 13.0200287],
                [108.5693471, 13.0199018],
                [108.5692557, 13.0198482],
                [108.5690617, 13.0197621],
                [108.5688434, 13.0197035],
                [108.5687313, 13.0196874],
                [108.568505, 13.0196821],
                [108.5683922, 13.0196929],
                [108.5681713, 13.0197412],
                [108.5429263, 13.0296557],
                [108.5400081, 13.0313804],
                [108.536253, 13.0326661],
                [108.5318756, 13.0328229],
                [108.5310677, 13.0335101],
                [108.5309393, 13.0336028],
                [108.5308055, 13.0336881],
                [108.5306668, 13.0337656],
                [108.5305238, 13.0338351],
                [108.5303768, 13.0338964],
                [108.5302264, 13.0339493],
                [108.5300732, 13.0339935],
                [108.5298645, 13.034039],
                [108.529654, 13.0340754],
                [108.5294421, 13.0341027],
                [108.5292292, 13.0341208],
                [108.5290156, 13.0341297],
                [108.5288018, 13.0341294],
                [108.5285872, 13.0341198],
                [108.5283732, 13.0341009],
                [108.5281603, 13.0340727],
                [108.5279489, 13.0340353],
                [108.5270584, 13.0337845],
                [108.5268217, 13.0336973],
                [108.5265757, 13.0336399],
                [108.5263242, 13.033613],
                [108.5261977, 13.0336113],
                [108.5258303, 13.0336371],
                [108.5257094, 13.033638],
                [108.525458, 13.0336205],
                [108.5253284, 13.0336011],
                [108.5250735, 13.0335415],
                [108.5248082, 13.0334327],
                [108.5245394, 13.0333325],
                [108.5242674, 13.0332408],
                [108.5239925, 13.0331577],
                [108.5237149, 13.0330834],
                [108.5234351, 13.033018],
                [108.5231532, 13.0329614],
                [108.5228695, 13.0329137],
                [108.5223049, 13.0328461],
                [108.5217438, 13.0328141],
                [108.5211818, 13.0328168],
                [108.5206211, 13.0328542],
                [108.520338, 13.0328585],
                [108.5200571, 13.0328934],
                [108.519782, 13.0329585],
                [108.519516, 13.0330528],
                [108.519376, 13.0331162],
                [108.5192403, 13.0331877],
                [108.5191092, 13.033267],
                [108.5189833, 13.033354],
                [108.5188631, 13.0334482],
                [108.5185981, 13.0336891],
                [108.5185138, 13.0337486],
                [108.5184243, 13.0338006],
                [108.5183304, 13.0338446],
                [108.5182329, 13.0338803],
                [108.5181325, 13.0339073],
                [108.5179313, 13.0339346],
                [108.5177332, 13.0339281],
                [108.5175391, 13.0338891],
                [108.5174453, 13.0338576],
                [108.5169084, 13.0337945],
                [108.5166386, 13.0337764],
                [108.5160979, 13.0337673],
                [108.5158276, 13.0337762],
                [108.5155578, 13.0337941],
                [108.5152888, 13.0338211],
                [108.5147687, 13.0338991],
                [108.5142551, 13.0340106],
                [108.5137503, 13.0341552],
                [108.5135018, 13.0342396],
                [108.513186, 13.0344325],
                [108.5124108, 13.0352034],
                [108.5108887, 13.0369427],
                [108.5107534, 13.0371466],
                [108.5107329, 13.0372957],
                [108.5107308, 13.037501],
                [108.5108428, 13.0377259],
                [108.511031, 13.0379516],
                [108.5112671, 13.0381498],
                [108.5123088, 13.0387383],
                [108.5131786, 13.0393811],
                [108.5134805, 13.0396638],
                [108.5136691, 13.0398522],
                [108.5137246, 13.0400207],
                [108.5137412, 13.0402633],
                [108.5137386, 13.0405247],
                [108.5136788, 13.0407852],
                [108.5135427, 13.0410639],
                [108.513222, 13.0417045],
                [108.5115356, 13.0446364],
                [108.5111864, 13.0452675],
                [108.5111257, 13.0456214],
                [108.5111044, 13.0458451],
                [108.511103, 13.0459944],
                [108.5111392, 13.0461813],
                [108.5112327, 13.0463501],
                [108.5117112, 13.0470731],
                [108.5122174, 13.0478803],
                [108.5128253, 13.0489963],
                [108.5137377, 13.0510949],
                [108.5142584, 13.0523501],
                [108.5145116, 13.0531857],
                [108.5146583, 13.0542758],
                [108.5149068, 13.0551271],
                [108.5152619, 13.055821],
                [108.5161316, 13.0574247],
                [108.5165171, 13.0588838],
                [108.5165467, 13.0597237],
                [108.5165819, 13.0600226],
                [108.5166939, 13.0602476],
                [108.5168257, 13.060398],
                [108.5170719, 13.0605497],
                [108.5173758, 13.0606273],
                [108.5178322, 13.0607064],
                [108.5183265, 13.0608043],
                [108.518696, 13.0610038],
                [108.5202657, 13.0621384],
                [108.5210393, 13.0628736],
                [108.5212084, 13.0631178],
                [108.5213586, 13.0633431],
                [108.5213567, 13.0635297],
                [108.5213546, 13.0637349],
                [108.5212954, 13.0639395],
                [108.5211408, 13.064162],
                [108.5206111, 13.0647446],
                [108.5181118, 13.0670344],
                [108.5179383, 13.067238],
                [108.5178409, 13.067461],
                [108.5178192, 13.0677219],
                [108.5178922, 13.0680399],
                [108.5179939, 13.0683487],
                [108.5191891, 13.0707486],
                [108.519225, 13.0709728],
                [108.5192043, 13.0711406],
                [108.5191642, 13.0713268],
                [108.5188939, 13.0716973],
                [108.5184325, 13.0721221],
                [108.5182593, 13.0722883],
                [108.5180278, 13.0725847],
                [108.5178928, 13.0727513],
                [108.5177764, 13.0729554],
                [108.5176797, 13.0731038],
                [108.5174877, 13.0732511],
                [108.5172582, 13.0733423],
                [108.5168011, 13.0733379],
                [108.5164396, 13.0732971],
                [108.5160227, 13.0730691],
                [108.5158907, 13.0729373],
                [108.5157592, 13.0727494],
                [108.5156471, 13.0725245],
                [108.5156111, 13.0723188],
                [108.5155572, 13.0720011],
                [108.5154826, 13.0718325],
                [108.5153697, 13.0717008],
                [108.5152556, 13.071681],
                [108.5150842, 13.0716794],
                [108.5148929, 13.0717521],
                [108.5147392, 13.0718813],
                [108.5145849, 13.0720664],
                [108.5144294, 13.0723821],
                [108.5135796, 13.0744917],
                [108.5133238, 13.0753102],
                [108.513129, 13.0757374],
                [108.5128306, 13.0760425],
                [108.5119103, 13.0766308],
                [108.5115251, 13.0770562],
                [108.5112156, 13.0775197],
                [108.5108081, 13.0782622],
                [108.5102667, 13.0790593],
                [108.5097751, 13.0796389],
                [108.5086232, 13.0805228],
                [108.5079722, 13.0808521],
                [108.5072459, 13.0810874],
                [108.5065968, 13.0812303],
                [108.5056802, 13.0814452],
                [108.5052217, 13.0815713],
                [108.5049918, 13.0816996],
                [108.5047227, 13.0819394],
                [108.5043163, 13.0825694],
                [108.5040634, 13.083089],
                [108.5038703, 13.0833481],
                [108.503621, 13.0835135],
                [108.5032576, 13.0836592],
                [108.5024556, 13.0838379],
                [108.5017931, 13.0839616],
                [108.5004877, 13.0841233],
                [108.4999046, 13.0844208],
                [108.4994146, 13.0852126],
                [108.4992605, 13.0855652],
                [108.4991677, 13.0858004],
                [108.4991636, 13.0862134],
                [108.4993377, 13.0868641],
                [108.4999306, 13.0878139],
                [108.5000484, 13.0880806],
                [108.5000428, 13.088641],
                [108.4998871, 13.0891412],
                [108.4996113, 13.0896106],
                [108.4987101, 13.0908556],
                [108.4976666, 13.0927632],
                [108.4971465, 13.0935546],
                [108.4966875, 13.0942583],
                [108.4960181, 13.0949304],
                [108.4955922, 13.0953393],
                [108.4948043, 13.0958037],
                [108.4938053, 13.0962955],
                [108.4909909, 13.0975663],
                [108.4894192, 13.098082],
                [108.4886937, 13.0983405],
                [108.4884491, 13.0986921],
                [108.4884461, 13.0989871],
                [108.4885337, 13.0992534],
                [108.488917, 13.1000833],
                [108.4891848, 13.1004105],
                [108.4894526, 13.1007375],
                [108.4899604, 13.1011556],
                [108.4904984, 13.1015739],
                [108.4912463, 13.1020827],
                [108.4916943, 13.1024706],
                [108.4921706, 13.1030358],
                [108.4925568, 13.1035705],
                [108.4928484, 13.1045175],
                [108.4929027, 13.1051081],
                [108.4928985, 13.1055211],
                [108.4928338, 13.105963],
                [108.4925113, 13.1065646],
                [108.4922364, 13.1069455],
                [108.4913234, 13.1078512],
                [108.4908094, 13.1083995],
                [108.4907753, 13.1084359],
                [108.4900169, 13.1089596],
                [108.4891352, 13.1097476],
                [108.4877065, 13.1110022],
                [108.487372, 13.1113235],
                [108.4866439, 13.1118179],
                [108.4858867, 13.1122236],
                [108.4849195, 13.1125387],
                [108.4830023, 13.1129477],
                [108.4810712, 13.1132533],
                [108.4805278, 13.113366],
                [108.4799549, 13.1134194],
                [108.4794416, 13.1135324],
                [108.4788972, 13.1137337],
                [108.4786244, 13.1139079],
                [108.4770815, 13.1145419],
                [108.4762326, 13.1150646],
                [108.475716, 13.1155021],
                [108.4750778, 13.1160564],
                [108.4743757, 13.1169641],
                [108.4734629, 13.1178402],
                [108.4730993, 13.1180431],
                [108.4725994, 13.1183185],
                [108.4721168, 13.1183727],
                [108.4718156, 13.1183698],
                [108.4712138, 13.1183049],
                [108.4701908, 13.1181769],
                [108.4688986, 13.1178691],
                [108.4670966, 13.1173203],
                [108.4663751, 13.1171657],
                [108.4652938, 13.11686],
                [108.4648718, 13.1168854],
                [108.4644793, 13.11697],
                [108.4641459, 13.1171733],
                [108.4637512, 13.1174644],
                [108.462093, 13.1190559],
                [108.461393, 13.1197571],
                [108.4611182, 13.1201084],
                [108.4609013, 13.1206962],
                [108.4608374, 13.1210497],
                [108.4606834, 13.1213727],
                [108.4604987, 13.1217544],
                [108.4601279, 13.1226653],
                [108.4597874, 13.123547],
                [108.4595699, 13.1241938],
                [108.4594154, 13.1245758],
                [108.4591419, 13.1248092],
                [108.4586407, 13.1252025],
                [108.4577908, 13.1258136],
                [108.4572122, 13.1264274],
                [108.4570278, 13.1267797],
                [108.4569943, 13.1271039],
                [108.4569918, 13.1273399],
                [108.4570196, 13.1275761],
                [108.4572265, 13.1279617],
                [108.4574939, 13.1283185],
                [108.4580003, 13.1288839],
                [108.4585072, 13.1293905],
                [108.4590714, 13.1297987],
                [108.4594624, 13.1302555],
                [108.4598488, 13.1307608],
                [108.4600256, 13.1311462],
                [108.4601431, 13.1314422],
                [108.4601824, 13.132018],
                [108.4601398, 13.1332272],
                [108.4602233, 13.1339065],
                [108.4602993, 13.1341424],
                [108.4607004, 13.1343832],
                [108.4614821, 13.1345384],
                [108.4625645, 13.1347556],
                [108.4641282, 13.1350366],
                [108.4659627, 13.1353497],
                [108.4668641, 13.1355945],
                [108.4672846, 13.1357168],
                [108.4677609, 13.136282],
                [108.4681181, 13.1366986],
                [108.4684724, 13.1374101],
                [108.4690765, 13.1387289],
                [108.469638, 13.1397964],
                [108.4699013, 13.1405661],
                [108.4699584, 13.1408618],
                [108.4699269, 13.1410089],
                [108.4698057, 13.1410667],
                [108.4695949, 13.1410646],
                [108.469415, 13.1409744],
                [108.4691764, 13.140736],
                [108.468818, 13.1404374],
                [108.4683387, 13.1401672],
                [108.4679178, 13.1400746],
                [108.4673755, 13.1400693],
                [108.4665597, 13.1402972],
                [108.4656231, 13.1405535],
                [108.4648061, 13.1408995],
                [108.4645329, 13.1411034],
                [108.4643795, 13.1413673],
                [108.4643463, 13.141662],
                [108.4643731, 13.1419868],
                [108.464487, 13.1426369],
                [108.4648613, 13.1443222],
                [108.4652099, 13.1455943],
                [108.4657388, 13.1473836],
                [108.4657769, 13.1475355],
                [108.4659078, 13.1479172],
                [108.4659481, 13.1480304],
                [108.4656553, 13.1481749],
                [108.4653206, 13.1483901],
                [108.4648689, 13.1487048],
                [108.4643764, 13.1490192],
                [108.4639257, 13.149295],
                [108.463453, 13.1496694],
                [108.4628585, 13.1500029],
                [108.4623462, 13.1502572],
                [108.4618736, 13.1506319],
                [108.4616056, 13.1509285],
                [108.4614187, 13.151266],
                [108.4612295, 13.1518229],
                [108.4611652, 13.1521217],
                [108.4609946, 13.1528585],
                [108.4607534, 13.1535246],
                [108.4606669, 13.1540028],
                [108.4604973, 13.1546398],
                [108.4604528, 13.1549986],
                [108.4604676, 13.1555376],
                [108.4606455, 13.1560782],
                [108.4608057, 13.1563593],
                [108.4609665, 13.1565804],
                [108.4612887, 13.1569627],
                [108.4616308, 13.1573852],
                [108.4617501, 13.1576858],
                [108.4617766, 13.1580717],
                [108.4617896, 13.1587899],
                [108.4619278, 13.15923],
                [108.4621275, 13.1596308],
                [108.4623484, 13.1599521],
                [108.4626105, 13.1602339],
                [108.4629539, 13.1605364],
                [108.4633784, 13.1608797],
                [108.4637676, 13.1611534],
                [108.4637828, 13.161182],
                [108.4638753, 13.1614472],
                [108.463905, 13.1616651],
                [108.46391, 13.1619295],
                [108.4638605, 13.1621156],
                [108.4637637, 13.1622623],
                [108.4634868, 13.1625666],
                [108.4630142, 13.162966],
                [108.4623224, 13.1634595],
                [108.4617397, 13.1637813],
                [108.4613565, 13.1639874],
                [108.4611717, 13.1641706],
                [108.460971, 13.1644189],
                [108.4605884, 13.1649553],
                [108.4602356, 13.1653016],
                [108.4598995, 13.1655548],
                [108.4593735, 13.1657594],
                [108.4590156, 13.1658258],
                [108.458416, 13.1658472],
                [108.4578763, 13.165834],
                [108.4575671, 13.1657921],
                [108.4574803, 13.1657445],
                [108.4571481, 13.165617],
                [108.4569104, 13.1655913],
                [108.4567991, 13.1656057],
                [108.4567031, 13.1656669],
                [108.4566308, 13.1657517],
                [108.4565579, 13.1658908],
                [108.4565005, 13.1660614],
                [108.4564426, 13.1663017],
                [108.4563699, 13.1664176],
                [108.4562616, 13.1665277],
                [108.4556444, 13.1669502],
                [108.4548576, 13.1675496],
                [108.4542538, 13.1680866],
                [108.4535124, 13.1687876],
                [108.4520771, 13.1698919],
                [108.4514308, 13.1703376],
                [108.4506271, 13.1709866],
                [108.4492227, 13.1720513],
                [108.4485153, 13.17273],
                [108.4480276, 13.1733109],
                [108.4475561, 13.1737634],
                [108.4468942, 13.1742712],
                [108.4463917, 13.1745161],
                [108.4459417, 13.1745995],
                [108.4455109, 13.1746932],
                [108.4451042, 13.1748844],
                [108.4445169, 13.1752357],
                [108.4441312, 13.1754795],
                [108.4437629, 13.1758186],
                [108.443556, 13.1760738],
                [108.442972, 13.1768037],
                [108.4424105, 13.1774839],
                [108.4419084, 13.1780646],
                [108.4413184, 13.1786731],
                [108.4408622, 13.1790399],
                [108.4404307, 13.1793115],
                [108.4400919, 13.1794538],
                [108.439697, 13.1795356],
                [108.4392142, 13.17964],
                [108.4387318, 13.1797402],
                [108.4384829, 13.1798234],
                [108.4380591, 13.1798907],
                [108.4377669, 13.1799306],
                [108.4371526, 13.1800673],
                [108.4369029, 13.1802219],
                [108.4368825, 13.1802453],
                [108.4366887, 13.1804671],
                [108.436584, 13.180723],
                [108.4364486, 13.1811071],
                [108.4362377, 13.1817474],
                [108.4360433, 13.1822022],
                [108.4357472, 13.1826133],
                [108.4354519, 13.1829387],
                [108.4350371, 13.1832446],
                [108.4346814, 13.1834463],
                [108.434115, 13.1837356],
                [108.4336064, 13.1841475],
                [108.4334209, 13.1843508],
                [108.4330106, 13.1847443],
                [108.4325998, 13.1852018],
                [108.4318506, 13.1860601],
                [108.4312268, 13.1868363],
                [108.4304338, 13.1881302],
                [108.4300188, 13.1889854],
                [108.4295652, 13.1897633],
                [108.4291971, 13.1903411],
                [108.4287983, 13.1908646],
                [108.428483, 13.1911569],
                [108.4281267, 13.1913969],
                [108.4276649, 13.1917129],
                [108.4270499, 13.1922647],
                [108.4268904, 13.192494],
                [108.4267311, 13.1926847],
                [108.4266373, 13.192889],
                [108.4266327, 13.193325],
                [108.4266584, 13.1935249],
                [108.4266896, 13.193756],
                [108.4266833, 13.1941272],
                [108.4266284, 13.1943704],
                [108.4265338, 13.1946516],
                [108.4262804, 13.1950722],
                [108.4261198, 13.1953914],
                [108.425661, 13.1960472],
                [108.4252881, 13.1966206],
                [108.424984, 13.1968998],
                [108.4247466, 13.1970514],
                [108.4242195, 13.197341],
                [108.4238635, 13.1975555],
                [108.4235647, 13.1979437],
                [108.4235115, 13.1980201],
                [108.4234305, 13.1982373],
                [108.4233764, 13.1984163],
                [108.4232953, 13.1986464],
                [108.4232534, 13.1988897],
                [108.4232242, 13.1991843],
                [108.4230667, 13.1998305],
                [108.4228364, 13.2005593],
                [108.4225926, 13.2013135],
                [108.4223204, 13.2022856],
                [108.4222442, 13.2026888],
                [108.4222552, 13.2028814],
                [108.4222931, 13.20301],
                [108.4223414, 13.2034081],
                [108.4225532, 13.203827],
                [108.422812, 13.2041247],
                [108.4231093, 13.2045125],
                [108.4232109, 13.2048213],
                [108.4231828, 13.2050006],
                [108.4231283, 13.2051924],
                [108.4230484, 13.2053199],
                [108.422903, 13.2054467],
                [108.4227322, 13.2054963],
                [108.422378, 13.2055312],
                [108.4222594, 13.2056069],
                [108.4220536, 13.2058549],
                [108.4219461, 13.2061232],
                [108.4219299, 13.2064181],
                [108.421967, 13.2066236],
                [108.4220306, 13.2068038],
                [108.4221206, 13.2069715],
                [108.4222105, 13.2071392],
                [108.4223135, 13.2073069],
                [108.4223649, 13.20741],
                [108.422362, 13.2076793],
                [108.4222741, 13.2079414],
                [108.4222074, 13.2080562],
                [108.4220618, 13.2081958],
                [108.421956, 13.2082974],
                [108.4217055, 13.208436],
                [108.4213753, 13.2087019],
                [108.4211096, 13.2090455],
                [108.4210157, 13.2092498],
                [108.4210537, 13.2093784],
                [108.4211448, 13.2094435],
                [108.4212746, 13.2095474],
                [108.4214569, 13.2096519],
                [108.4215737, 13.2097685],
                [108.4216429, 13.2100322],
                [108.4216403, 13.2102886],
                [108.4216345, 13.2108401],
                [108.4215798, 13.2110575],
                [108.4215116, 13.2113134],
                [108.4213913, 13.211543],
                [108.4212199, 13.211644],
                [108.4211413, 13.2116431],
                [108.420945, 13.2116283],
                [108.4206309, 13.2115867],
                [108.4204219, 13.2115332],
                [108.420186, 13.2115309],
                [108.4200672, 13.2116194],
                [108.4199018, 13.2117781],
                [108.4198874, 13.2119062],
                [108.4198854, 13.2120858],
                [108.4198577, 13.2122394],
                [108.4198165, 13.2124186],
                [108.4197235, 13.2125331],
                [108.4196314, 13.2125706],
                [108.4193688, 13.2126193],
                [108.4189877, 13.2127308],
                [108.4185135, 13.2129826],
                [108.4182879, 13.2132496],
                [108.4180014, 13.2137021],
                [108.4177873, 13.214136],
                [108.4176915, 13.2145198],
                [108.4176606, 13.2149684],
                [108.4176844, 13.2151995],
                [108.4178562, 13.2156823],
                [108.4181516, 13.2162368],
                [108.4182272, 13.2165326],
                [108.4182503, 13.2168278],
                [108.4181956, 13.2170452],
                [108.4181285, 13.2171985],
                [108.4180091, 13.2173383],
                [108.4178237, 13.2175159],
                [108.4175656, 13.2177635],
                [108.4172208, 13.2181704],
                [108.4171, 13.2184385],
                [108.4170977, 13.2186566],
                [108.4171086, 13.2188748],
                [108.4171059, 13.2191184],
                [108.4170634, 13.2194259],
                [108.4170023, 13.2196368],
                [108.4168547, 13.219956],
                [108.4166284, 13.2203],
                [108.4163864, 13.2208875],
                [108.4162921, 13.2211302],
                [108.4162482, 13.2215659],
                [108.416279, 13.2217521],
                [108.4163691, 13.2219071],
                [108.4165115, 13.2220752],
                [108.4166799, 13.2222565],
                [108.4168744, 13.2224508],
                [108.4170158, 13.2227088],
                [108.417298, 13.223276],
                [108.4175487, 13.2237338],
                [108.4178719, 13.2241476],
                [108.4181822, 13.2245355],
                [108.4185072, 13.2247824],
                [108.4190661, 13.2252113],
                [108.4199067, 13.2256367],
                [108.4209511, 13.2259936],
                [108.4218502, 13.2264643],
                [108.4221361, 13.2266853],
                [108.4222783, 13.2268663],
                [108.4222757, 13.2271228],
                [108.4221957, 13.2272502],
                [108.4219845, 13.227402],
                [108.421636, 13.2275204],
                [108.4209919, 13.227719],
                [108.4206231, 13.2279077],
                [108.420491, 13.228009],
                [108.4203579, 13.2282],
                [108.42021, 13.2285706],
                [108.4201677, 13.2288395],
                [108.4201287, 13.2294355],
                [108.4200695, 13.230089],
                [108.4199451, 13.2307033],
                [108.4197576, 13.2310863],
                [108.4195245, 13.2314495],
                [108.4191273, 13.2318303],
                [108.4187314, 13.2321084],
                [108.4181897, 13.232539],
                [108.417779, 13.2329709],
                [108.4175925, 13.2332512],
                [108.4175306, 13.2335391],
                [108.4175142, 13.2338469],
                [108.4175484, 13.2343346],
                [108.4175947, 13.2355343],
                [108.4176664, 13.236189],
                [108.4177394, 13.2367157],
                [108.4178828, 13.2374137],
                [108.4180085, 13.2379148],
                [108.4181605, 13.2384162],
                [108.4183146, 13.2387125],
                [108.418509, 13.2389067],
                [108.4190436, 13.2391556],
                [108.4193304, 13.2392995],
                [108.4198001, 13.2394836],
                [108.4201143, 13.2395253],
                [108.42052, 13.239555],
                [108.4210159, 13.2397522],
                [108.4212238, 13.2399209],
                [108.4214168, 13.2401973],
                [108.4213747, 13.2402826],
                [108.4212786, 13.2404605],
                [108.421199, 13.2406574],
                [108.4211164, 13.2408652],
                [108.4209593, 13.2411853],
                [108.4208044, 13.2414278],
                [108.4207537, 13.2415059],
                [108.4205159, 13.2418627],
                [108.4204035, 13.2420243],
                [108.4202915, 13.2421398],
                [108.4200658, 13.2423936],
                [108.4198953, 13.2425411],
                [108.4196749, 13.2426906],
                [108.4194995, 13.2427755],
                [108.4193255, 13.2428538],
                [108.4191475, 13.2429279],
                [108.4188917, 13.2430093],
                [108.4185554, 13.2431307],
                [108.4183429, 13.2431896],
                [108.4181622, 13.2432473],
                [108.4180509, 13.2432951],
                [108.4178837, 13.2433909],
                [108.417694, 13.2435219],
                [108.4175289, 13.2436693],
                [108.4171529, 13.2440004],
                [108.4168373, 13.2442466],
                [108.4163139, 13.244633],
                [108.4160152, 13.2448469],
                [108.415146, 13.2454549],
                [108.4143857, 13.245969],
                [108.4142629, 13.2460601],
                [108.4139461, 13.2462832],
                [108.4135221, 13.2465663],
                [108.4133742, 13.2466679],
                [108.4128707, 13.2470003],
                [108.4119983, 13.2473832],
                [108.411408, 13.2476754],
                [108.4111726, 13.247791],
                [108.4110415, 13.2478818],
                [108.4109103, 13.2479835],
                [108.4107815, 13.2481124],
                [108.4106745, 13.2482711],
                [108.4105848, 13.2485103],
                [108.4104986, 13.2488049],
                [108.4104641, 13.2489156],
                [108.4104484, 13.2489711],
                [108.4104469, 13.2489711],
                [108.4102842, 13.2511614],
                [108.4103134, 13.2521786],
                [108.4102671, 13.2527648],
                [108.4102034, 13.2531163],
                [108.4100995, 13.2534868],
                [108.4099352, 13.2538958],
                [108.4097899, 13.2544029],
                [108.4096229, 13.255066],
                [108.4094759, 13.2557296],
                [108.4094118, 13.2561201],
                [108.4092441, 13.2561149],
                [108.4092439, 13.2561159],
                [108.4090188, 13.2561235],
                [108.4088852, 13.2561612],
                [108.4087781, 13.2562252],
                [108.4086037, 13.2563537],
                [108.4083735, 13.2567288],
                [108.4083025, 13.2571447],
                [108.4082597, 13.2574176],
                [108.4080952, 13.2578716],
                [108.4079593, 13.2581305],
                [108.4078381, 13.2582594],
                [108.4076106, 13.2583743],
                [108.4073175, 13.2584104],
                [108.4071177, 13.2584213],
                [108.4070239, 13.2584724],
                [108.4068765, 13.2585751],
                [108.4065521, 13.2590405],
                [108.4059443, 13.2598674],
                [108.405675, 13.2601641],
                [108.4055271, 13.2603057],
                [108.4054331, 13.2603829],
                [108.4052863, 13.2604204],
                [108.4052064, 13.2604196],
                [108.4049278, 13.2603387],
                [108.4044255, 13.2600082],
                [108.4039236, 13.2596386],
                [108.4034474, 13.2593603],
                [108.4030757, 13.2592654],
                [108.4026735, 13.2589121],
                [108.4023579, 13.2585572],
                [108.4021614, 13.2582555],
                [108.401991, 13.2580061],
                [108.4017407, 13.257756],
                [108.4013564, 13.2575958],
                [108.4009572, 13.2575917],
                [108.4001977, 13.2576753],
                [108.3998785, 13.2576459],
                [108.3996931, 13.2575528],
                [108.399644, 13.2571745],
                [108.3995579, 13.2565091],
                [108.39954, 13.2563135],
                [108.3994226, 13.2560908],
                [108.3992245, 13.2559455],
                [108.3988138, 13.2557589],
                [108.3985352, 13.2556779],
                [108.3978707, 13.25558],
                [108.397339, 13.2555223],
                [108.3965935, 13.2555278],
                [108.3955395, 13.2556462],
                [108.3946785, 13.2557743],
                [108.3937671, 13.2559314],
                [108.3929848, 13.2561777],
                [108.391801, 13.2565767],
                [108.3906597, 13.2567411],
                [108.3885881, 13.2569644],
                [108.3878058, 13.2572108],
                [108.3872036, 13.2574393],
                [108.3850502, 13.2587674],
                [108.3842651, 13.2592681],
                [108.3838018, 13.259596],
                [108.3833879, 13.2599733],
                [108.3805013, 13.2634951],
                [108.3786822, 13.2653353],
                [108.376936, 13.2668924],
                [108.376414, 13.2671022],
                [108.3758936, 13.2671556],
                [108.3743167, 13.2669242],
                [108.3730375, 13.2668914],
                [108.372576, 13.2670432],
                [108.3722326, 13.2673722],
                [108.3720884, 13.2677621],
                [108.3721636, 13.2682129],
                [108.3723801, 13.2694577],
                [108.3731564, 13.2725671],
                [108.373382, 13.2738999],
                [108.3734967, 13.2743904],
                [108.3737704, 13.2749605],
                [108.3739646, 13.2754909],
                [108.3740017, 13.2757652],
                [108.3738858, 13.2763216],
                [108.3736595, 13.2769063],
                [108.373456, 13.2772369],
                [108.373072, 13.2776243],
                [108.3726886, 13.2779529],
                [108.3726074, 13.2780695],
                [108.3726846, 13.2783247],
                [108.3728626, 13.2785026],
                [108.3733572, 13.2789969],
                [108.3737334, 13.2793334],
                [108.373801, 13.2795592],
                [108.3737365, 13.2799694],
                [108.3734057, 13.2809834],
                [108.3730553, 13.2819582],
                [108.3729185, 13.2825927],
                [108.3729543, 13.2829844],
                [108.3729687, 13.2835129],
                [108.3728967, 13.2855569],
                [108.3728469, 13.2864565],
                [108.3729204, 13.2870638],
                [108.3732706, 13.2879675],
                [108.3732679, 13.2882219],
                [108.3731546, 13.288524],
                [108.3728909, 13.2888734],
                [108.3723638, 13.2895528],
                [108.3707313, 13.2916786],
                [108.3697356, 13.2931554],
                [108.3687993, 13.2946914],
                [108.3685932, 13.2952567],
                [108.3686503, 13.2955312],
                [108.3687972, 13.295797],
                [108.3694103, 13.2964294],
                [108.3702614, 13.2972405],
                [108.3704775, 13.2975949],
                [108.3704941, 13.2979082],
                [108.37033, 13.2982978],
                [108.3700548, 13.2987744],
                [108.3693022, 13.2999601],
                [108.3689934, 13.3007787],
                [108.3689672, 13.3013655],
                [108.3690231, 13.3017378],
                [108.3692003, 13.301994],
                [108.3694589, 13.3021142],
                [108.3700583, 13.3021595],
                [108.3705603, 13.301969],
                [108.3709427, 13.3017382],
                [108.3714638, 13.3016261],
                [108.371843, 13.3016888],
                [108.3721307, 13.3018972],
                [108.3724075, 13.3021935],
                [108.3724856, 13.3023704],
                [108.3724639, 13.3025268],
                [108.3721389, 13.3029931],
                [108.3716291, 13.3039075],
                [108.3710704, 13.3047332],
                [108.3701782, 13.3058784],
                [108.3692088, 13.3067684],
                [108.3685013, 13.3074656],
                [108.3682555, 13.3080109],
                [108.3681184, 13.308665],
                [108.3677093, 13.3095217],
                [108.3672641, 13.3100062],
                [108.3668813, 13.3102762],
                [108.3664805, 13.3103503],
                [108.3653201, 13.3104165],
                [108.3641804, 13.3104046],
                [108.3639389, 13.3105392],
                [108.3637156, 13.3108499],
                [108.3635195, 13.3114251],
                [108.3630998, 13.3132404],
                [108.3629131, 13.3138647],
                [108.3625077, 13.3143692],
                [108.3620826, 13.3148539],
                [108.3617203, 13.3150654],
                [108.3610891, 13.315186],
                [108.3601085, 13.3152541],
                [108.3587092, 13.3152199],
                [108.3575899, 13.3151692],
                [108.35618, 13.3151839],
                [108.3559032, 13.3152593],
                [108.3557678, 13.3154176],
                [108.3555914, 13.31598],
                [108.3555275, 13.3163315],
                [108.3552793, 13.3180214],
                [108.3549653, 13.3193096],
                [108.3547345, 13.3203051],
                [108.3541107, 13.3215801],
                [108.3534408, 13.3225124],
                [108.3528523, 13.3232889],
                [108.3527281, 13.3236789],
                [108.3528038, 13.3240711],
                [108.3530191, 13.3245038],
                [108.3534803, 13.3253011],
                [108.3538734, 13.3259313],
                [108.3539887, 13.3263629],
                [108.3539232, 13.326871],
                [108.3538758, 13.3275553],
                [108.3537157, 13.3284796],
                [108.3533282, 13.3291795],
                [108.3528821, 13.3297418],
                [108.3522577, 13.3301458],
                [108.3516145, 13.330452],
                [108.3508898, 13.3308942],
                [108.3501046, 13.3313748],
                [108.3491214, 13.3316774],
                [108.3480601, 13.3318031],
                [108.3471799, 13.331833],
                [108.3462801, 13.3318235],
                [108.3459196, 13.3318784],
                [108.3458431, 13.3319724],
                [108.3457776, 13.3320529],
                [108.3457681, 13.3321928],
                [108.3457538, 13.3324046],
                [108.3457926, 13.3328827],
                [108.3460694, 13.3351749],
                [108.3466923, 13.3368799],
                [108.3471632, 13.3386942],
                [108.347007, 13.3391726],
                [108.3466284, 13.3392793],
                [108.3461768, 13.3391639],
                [108.3452379, 13.3387479],
                [108.3440376, 13.3380707],
                [108.3425278, 13.3380918],
                [108.3406593, 13.3381276],
                [108.3394458, 13.3386687],
                [108.3386545, 13.3385497],
                [108.3377541, 13.3380601],
                [108.337077, 13.3378684],
                [108.336091, 13.3383011],
                [108.3344213, 13.3391329],
                [108.3329062, 13.3396338],
                [108.3316025, 13.3397863],
                [108.3309209, 13.3400007],
                [108.3303086, 13.3407696],
                [108.328895, 13.3423424],
                [108.3284364, 13.3428545],
                [108.3273416, 13.3428799],
                [108.3254558, 13.3427862],
                [108.3245087, 13.3431085],
                [108.3224356, 13.344582],
                [108.3211051, 13.3454541],
                [108.3206888, 13.3455605],
                [108.3195267, 13.3448466],
                [108.3189258, 13.3445818],
                [108.3182819, 13.3447965],
                [108.3174042, 13.3456734],
                [108.3161455, 13.3468785],
                [108.3155765, 13.3471311],
                [108.3149736, 13.3470508],
                [108.3141885, 13.3463778],
                [108.3135713, 13.3458729],
                [108.3133072, 13.34587],
                [108.3115071, 13.3463917],
                [108.3048412, 13.3486785],
                [108.3033261, 13.3491794],
                [108.2996124, 13.3505428],
                [108.2966932, 13.3517301],
                [108.2955401, 13.3519144],
                [108.2932373, 13.3519635],
                [108.2919191, 13.3517281],
                [108.28989, 13.3509315],
                [108.2888764, 13.3504411],
                [108.2885368, 13.3504374],
                [108.2881177, 13.3508019],
                [108.2878051, 13.3517578],
                [108.2874514, 13.3530085],
                [108.2872471, 13.3544083],
                [108.2868246, 13.355068],
                [108.2843407, 13.3578085],
                [108.2829661, 13.3592327],
                [108.2821227, 13.3604043],
                [108.2813207, 13.3612442],
                [108.2795314, 13.362627],
                [108.277266, 13.3639725],
                [108.2743424, 13.3657858],
                [108.2701619, 13.3683517],
                [108.2649082, 13.3719061],
                [108.2638491, 13.3726095],
                [108.263637, 13.3723227],
                [108.2636362, 13.3723243],
                [108.2634018, 13.3722115],
                [108.2627512, 13.3717634],
                [108.2620388, 13.3713347],
                [108.2617546, 13.3710911],
                [108.2611463, 13.3705232],
                [108.2604558, 13.3699745],
                [108.2597027, 13.3695254],
                [108.2593137, 13.369501],
                [108.2588717, 13.3696266],
                [108.2586035, 13.369784],
                [108.2585006, 13.369823],
                [108.2584403, 13.3697222],
                [108.258423, 13.3694413],
                [108.2584273, 13.3690604],
                [108.2583268, 13.3688789],
                [108.2581648, 13.3687168],
                [108.25792, 13.368614],
                [108.2575913, 13.3686906],
                [108.2573846, 13.3688487],
                [108.2567834, 13.3694636],
                [108.2564324, 13.3697003],
                [108.2561241, 13.3697772],
                [108.255899, 13.3697547],
                [108.2548176, 13.369342],
                [108.2544909, 13.3692383],
                [108.254286, 13.369236],
                [108.2540607, 13.3692336],
                [108.2539168, 13.3692721],
                [108.2535039, 13.3695483],
                [108.2532156, 13.3696654],
                [108.2530722, 13.3696638],
                [108.2529293, 13.3696222],
                [108.252563, 13.3693978],
                [108.2517962, 13.3692091],
                [108.250947, 13.3688175],
                [108.2503834, 13.3684258],
                [108.2502244, 13.3683735],
                [108.2499961, 13.3683134],
                [108.2497405, 13.3682851],
                [108.2494982, 13.3682761],
                [108.2492826, 13.3682866],
                [108.2491206, 13.3683232],
                [108.2488567, 13.3684289],
                [108.2483684, 13.3687306],
                [108.2478475, 13.3692444],
                [108.2475957, 13.369472],
                [108.2475345, 13.3695225],
                [108.2474023, 13.3695979],
                [108.2472741, 13.3696221],
                [108.2471259, 13.3696268],
                [108.2469578, 13.3696059],
                [108.2467435, 13.3695011],
                [108.2463419, 13.3692792],
                [108.2459402, 13.3690765],
                [108.2456752, 13.3689839],
                [108.2454739, 13.3689242],
                [108.2452519, 13.3689026],
                [108.2450031, 13.3688678],
                [108.244546, 13.3687989],
                [108.2441255, 13.3687623],
                [108.243998, 13.3687289],
                [108.2438369, 13.3686823],
                [108.2436425, 13.3686034],
                [108.2434484, 13.3684926],
                [108.2432213, 13.36833],
                [108.2430708, 13.3682388],
                [108.242957, 13.3681864],
                [108.242856, 13.3681853],
                [108.2427211, 13.368203],
                [108.2425519, 13.3682651],
                [108.2423149, 13.3683842],
                [108.2419005, 13.3687125],
                [108.2415016, 13.3691433],
                [108.2412485, 13.3694925],
                [108.2410596, 13.3698104],
                [108.2409497, 13.3700011],
                [108.2407994, 13.3701914],
                [108.2406974, 13.3702736],
                [108.2405234, 13.3704765],
                [108.2400818, 13.3708108],
                [108.2398234, 13.3710385],
                [108.2393832, 13.371552],
                [108.2392324, 13.3717807],
                [108.2391982, 13.3718379],
                [108.2390777, 13.372067],
                [108.239008, 13.372271],
                [108.2389523, 13.372424],
                [108.2388699, 13.3725702],
                [108.2387813, 13.3726589],
                [108.2385341, 13.3727842],
                [108.2383516, 13.3728398],
                [108.2381835, 13.3728251],
                [108.237875, 13.3727131],
                [108.2372786, 13.3724505],
                [108.2370403, 13.3723322],
                [108.2368968, 13.3722609],
                [108.2366557, 13.3721368],
                [108.2364216, 13.3719999],
                [108.2362543, 13.3719022],
                [108.2361607, 13.3718436],
                [108.2360464, 13.3718167],
                [108.2358581, 13.3717956],
                [108.2356492, 13.3717997],
                [108.2355113, 13.3717865],
                [108.2352825, 13.3717712],
                [108.2351544, 13.3717698],
                [108.2349993, 13.3717937],
                [108.234844, 13.371824],
                [108.2346216, 13.371828],
                [108.234278, 13.371837],
                [108.2340321, 13.3718408],
                [108.2337901, 13.3717997],
                [108.2335684, 13.371746],
                [108.2332391, 13.3716849],
                [108.2329361, 13.3716751],
                [108.232657, 13.3716336],
                [108.2324619, 13.3716188],
                [108.2321858, 13.3716029],
                [108.2319706, 13.3715813],
                [108.2317688, 13.3715535],
                [108.2314192, 13.371492],
                [108.2308918, 13.3713711],
                [108.2306631, 13.3713494],
                [108.2305958, 13.3713359],
                [108.2304485, 13.3712574],
                [108.2301808, 13.3711137],
                [108.2297986, 13.3709622],
                [108.2294601, 13.3708113],
                [108.2291311, 13.370718],
                [108.2286677, 13.3705979],
                [108.2284392, 13.3705505],
                [108.2278643, 13.3704674],
                [108.2275477, 13.3704639],
                [108.2272038, 13.3704986],
                [108.2268804, 13.3705141],
                [108.2260824, 13.3704862],
                [108.225611, 13.3704811],
                [108.2248395, 13.3704982],
                [108.2244415, 13.3705514],
                [108.2241309, 13.3706184],
                [108.2236244, 13.3707346],
                [108.2231582, 13.3708574],
                [108.2227251, 13.3710126],
                [108.2224611, 13.3711312],
                [108.2220208, 13.371331],
                [108.2217603, 13.3714318],
                [108.2215847, 13.3714747],
                [108.2214698, 13.3715054],
                [108.2213003, 13.3715932],
                [108.2210696, 13.3717315],
                [108.2207503, 13.3719649],
                [108.2204678, 13.3722115],
                [108.2203381, 13.3723574],
                [108.2202285, 13.3725166],
                [108.2201596, 13.3726438],
                [108.2201338, 13.3727431],
                [108.2201057, 13.3728065],
                [108.2199868, 13.372798],
                [108.2198534, 13.3727527],
                [108.217934, 13.3715082],
                [108.2159051, 13.3703978],
                [108.2139311, 13.3690464],
                [108.213232, 13.3684047],
                [108.2131432, 13.3682078],
                [108.2120713, 13.3703811],
                [108.2100657, 13.3740458],
                [108.2091499, 13.3765008],
                [108.2069844, 13.3748208],
                [108.2055814, 13.3739314],
                [108.20436, 13.3734027],
                [108.2036769, 13.3732931],
                [108.2032939, 13.3733225],
                [108.2029163, 13.373445],
                [108.2025386, 13.3736565],
                [108.2020193, 13.3740129],
                [108.2019127, 13.374112],
                [108.2016846, 13.3743587],
                [108.2015071, 13.3745869],
                [108.2013414, 13.3749622],
                [108.2012076, 13.3751909],
                [108.2010087, 13.3755274],
                [108.2007918, 13.3759597],
                [108.2005707, 13.3764751],
                [108.2004941, 13.3766979],
                [108.2004014, 13.3768568],
                [108.2002516, 13.3770276],
                [108.2000685, 13.3771536],
                [108.1997369, 13.3773288],
                [108.1994596, 13.3774664],
                [108.1991785, 13.3776296],
                [108.198867, 13.3778179],
                [108.198501, 13.3780631],
                [108.1980632, 13.3784098],
                [108.1977378, 13.37863],
                [108.1974337, 13.3787544],
                [108.197191, 13.3788028],
                [108.1969422, 13.3788002],
                [108.1964744, 13.3788333],
                [108.1961175, 13.3788804],
                [108.1957737, 13.3789406],
                [108.19547, 13.3790331],
                [108.1951997, 13.3791388],
                [108.1948312, 13.3793137],
                [108.1944115, 13.3795519],
                [108.1938432, 13.3798397],
                [108.1936471, 13.379927],
                [108.1932924, 13.3800765],
                [108.1929342, 13.3802259],
                [108.1926574, 13.3803123],
                [108.192354, 13.3803729],
                [108.192105, 13.3803894],
                [108.1918594, 13.3803993],
                [108.1916175, 13.3803775],
                [108.1913557, 13.3803363],
                [108.191154, 13.380334],
                [108.191006, 13.3803387],
                [108.1908509, 13.3803689],
                [108.1906686, 13.3804372],
                [108.1905164, 13.3805123],
                [108.1904147, 13.3805814],
                [108.190265, 13.3807269],
                [108.1900272, 13.3809287],
                [108.1898512, 13.3810354],
                [108.1896418, 13.3811163],
                [108.1894527, 13.3811717],
                [108.1892064, 13.3812507],
                [108.1890708, 13.3813449],
                [108.1890229, 13.3814147],
                [108.189001, 13.3815549],
                [108.1889511, 13.3818034],
                [108.1888823, 13.3819367],
                [108.1887734, 13.3820504],
                [108.1886248, 13.3821062],
                [108.1885171, 13.3821115],
                [108.1875892, 13.3815905],
                [108.1874688, 13.3815381],
                [108.1873149, 13.3814854],
                [108.1870469, 13.3814123],
                [108.1868863, 13.3813594],
                [108.1867193, 13.3812748],
                [108.1862644, 13.38111],
                [108.1860702, 13.381044],
                [108.1858959, 13.3810229],
                [108.1856804, 13.3810652],
                [108.1854917, 13.3811142],
                [108.1852667, 13.381118],
                [108.1849381, 13.3810633],
                [108.1847372, 13.3810164],
                [108.1845826, 13.3810146],
                [108.1844477, 13.3810642],
                [108.184278, 13.3812155],
                [108.1840612, 13.38136],
                [108.1838423, 13.381415],
                [108.183647, 13.3814512],
                [108.1833648, 13.3814544],
                [108.1831429, 13.3814775],
                [108.1829746, 13.3815075],
                [108.182725, 13.3816006],
                [108.1824443, 13.3817634],
                [108.1821258, 13.3820025],
                [108.1817871, 13.3822414],
                [108.1814514, 13.3825121],
                [108.1812408, 13.3827142],
                [108.1810358, 13.383012],
                [108.1808473, 13.3833342],
                [108.1806492, 13.3836127],
                [108.1804522, 13.3838017],
                [108.1802423, 13.3839397],
                [108.1799252, 13.3840574],
                [108.1797098, 13.3840996],
                [108.179468, 13.3840954],
                [108.1791394, 13.3840535],
                [108.1788449, 13.3839673],
                [108.1786243, 13.383882],
                [108.1783839, 13.3837582],
                [108.1781432, 13.3836662],
                [108.1779488, 13.3836321],
                [108.177754, 13.3836299],
                [108.1775994, 13.3836474],
                [108.1773785, 13.3836868],
                [108.1773004, 13.3837315],
                [108.1772855, 13.3838204],
                [108.1772906, 13.3839711],
                [108.1772544, 13.3840871],
                [108.1771036, 13.3844209],
                [108.1769942, 13.3848031],
                [108.1769489, 13.3850902],
                [108.1768627, 13.3855869],
                [108.1768088, 13.3860112],
                [108.176798, 13.386333],
                [108.1768247, 13.386741],
                [108.1769451, 13.387215],
                [108.1770056, 13.3874281],
                [108.1770042, 13.3875446],
                [108.1769575, 13.3876502],
                [108.1768379, 13.3877105],
                [108.1767258, 13.387723],
                [108.1764746, 13.3876859],
                [108.1758958, 13.3875356],
                [108.1755046, 13.387497],
                [108.1751687, 13.3875069],
                [108.1747375, 13.3876014],
                [108.1744285, 13.3877213],
                [108.1742451, 13.3878494],
                [108.1741457, 13.3879784],
                [108.1741093, 13.3881014],
                [108.1741065, 13.3883411],
                [108.1741238, 13.3886565],
                [108.1741641, 13.3888008],
                [108.1742399, 13.3891956],
                [108.1742441, 13.3894354],
                [108.1742276, 13.3896476],
                [108.1742187, 13.389812],
                [108.1742369, 13.3900519],
                [108.1742755, 13.3906451],
                [108.1742728, 13.3908779],
                [108.1742082, 13.3910142],
                [108.1740661, 13.3912045],
                [108.1739527, 13.3913334],
                [108.1736952, 13.3915257],
                [108.1732804, 13.3917129],
                [108.172831, 13.3918655],
                [108.1725853, 13.3919381],
                [108.1723392, 13.3920518],
                [108.1720713, 13.3922406],
                [108.1718285, 13.3923647],
                [108.1715826, 13.3924578],
                [108.1713724, 13.3924897],
                [108.1711627, 13.3924874],
                [108.1709812, 13.392458],
                [108.1708281, 13.3923877],
                [108.170641, 13.3922417],
                [108.1703926, 13.3919511],
                [108.170123, 13.3916809],
                [108.1699113, 13.3915382],
                [108.169758, 13.3914953],
                [108.1696669, 13.391508],
                [108.1695895, 13.3915482],
                [108.1695542, 13.3915889],
                [108.1695254, 13.3916434],
                [108.1695029, 13.3917803],
                [108.1695131, 13.3921092],
                [108.1695169, 13.3923763],
                [108.1695083, 13.3925133],
                [108.169437, 13.3926359],
                [108.1693097, 13.3927578],
                [108.1690071, 13.392905],
                [108.1686395, 13.3929456],
                [108.1681357, 13.3929673],
                [108.1675414, 13.3929537],
                [108.1671709, 13.3929427],
                [108.1669714, 13.3929439],
                [108.166852, 13.3929974],
                [108.1667674, 13.3930581],
                [108.1667317, 13.3931125],
                [108.1667447, 13.3931949],
                [108.1668132, 13.393319],
                [108.166923, 13.3935052],
                [108.1669771, 13.3936565],
                [108.1669763, 13.3937318],
                [108.1669544, 13.393807],
                [108.1668483, 13.3939085],
                [108.1665748, 13.393974],
                [108.1659382, 13.3939874],
                [108.1652947, 13.393987],
                [108.1649619, 13.3940415],
                [108.1647298, 13.3941485],
                [108.164483, 13.3943239],
                [108.164327, 13.3945071],
                [108.1642835, 13.3946368],
                [108.1642888, 13.3947807],
                [108.1644123, 13.3949945],
                [108.1647028, 13.3952649],
                [108.1649206, 13.3954695],
                [108.165003, 13.3956075],
                [108.1650434, 13.3957449],
                [108.1650418, 13.395875],
                [108.1649564, 13.3960043],
                [108.1647799, 13.3961462],
                [108.1643365, 13.396381],
                [108.1639777, 13.396555],
                [108.16344, 13.3967854],
                [108.1632286, 13.39692],
                [108.163101, 13.3970693],
                [108.1629931, 13.3973216],
                [108.1628759, 13.3977725],
                [108.1627784, 13.3980352],
                [108.1626368, 13.3981843],
                [108.1622629, 13.3984609],
                [108.1620651, 13.39863],
                [108.1619586, 13.3987659],
                [108.161887, 13.398902],
                [108.1618714, 13.3990457],
                [108.1619281, 13.3992827],
                [108.1620775, 13.3996544],
                [108.1621449, 13.3998744],
                [108.1621497, 13.4000594],
                [108.1621131, 13.4002029],
                [108.1620134, 13.4003525],
                [108.1618548, 13.4004569],
                [108.1613259, 13.4008277],
                [108.1609028, 13.4011175],
                [108.1606425, 13.4012517],
                [108.1604248, 13.4013314],
                [108.1602078, 13.4013427],
                [108.1600996, 13.4013243],
                [108.1600299, 13.4012962],
                [108.1599955, 13.4012547],
                [108.1599544, 13.4011788],
                [108.1599206, 13.4010825],
                [108.15984, 13.4008007],
                [108.1597643, 13.4006903],
                [108.159688, 13.4006414],
                [108.1596184, 13.4006064],
                [108.1595345, 13.4006054],
                [108.1594148, 13.4006726],
                [108.1590192, 13.4010107],
                [108.1585262, 13.4013067],
                [108.1581331, 13.4014255],
                [108.1577725, 13.4014523],
                [108.1573948, 13.4014618],
                [108.1571222, 13.4014449],
                [108.156941, 13.4013881],
                [108.1568511, 13.4013117],
                [108.1567752, 13.401215],
                [108.1567274, 13.4011117],
                [108.1566595, 13.4009396],
                [108.1565035, 13.4005337],
                [108.1563185, 13.4002095],
                [108.1561427, 13.3999918],
                [108.1559488, 13.3998184],
                [108.1557406, 13.399679],
                [108.1555944, 13.3996293],
                [108.1554055, 13.3996341],
                [108.1551949, 13.3997002],
                [108.1549907, 13.3998144],
                [108.1548222, 13.3998673],
                [108.1546193, 13.3998787],
                [108.1542491, 13.3998334],
                [108.1540783, 13.3997939],
                [108.1539245, 13.3997922],
                [108.1536441, 13.3998439],
                [108.153398, 13.3999506],
                [108.1532153, 13.400017],
                [108.15297, 13.4000553],
                [108.1526614, 13.4001203],
                [108.1524852, 13.4002348],
                [108.1523224, 13.4003904],
                [108.1522228, 13.4005331],
                [108.1521153, 13.4007579],
                [108.1520227, 13.4009007],
                [108.1519592, 13.4009479],
                [108.1518925, 13.4009654],
                [108.1518368, 13.4009373],
                [108.1517537, 13.4008678],
                [108.1516511, 13.4006749],
                [108.1514207, 13.400035],
                [108.1513183, 13.3998282],
                [108.1512149, 13.3996968],
                [108.1511213, 13.3996238],
                [108.1510378, 13.3995956],
                [108.1509678, 13.3995947],
                [108.1508695, 13.399621],
                [108.1508128, 13.3996821],
                [108.1507484, 13.3998115],
                [108.1507114, 13.3999823],
                [108.1506938, 13.4002906],
                [108.1506551, 13.4006053],
                [108.1506042, 13.4007692],
                [108.1504836, 13.4009118],
                [108.150008, 13.4011976],
                [108.1496483, 13.401447],
                [108.1494362, 13.4016433],
                [108.1492577, 13.4019497],
                [108.1491784, 13.4021475],
                [108.1491649, 13.4024044],
                [108.1491603, 13.4027949],
                [108.1491301, 13.4029795],
                [108.1490444, 13.4031293],
                [108.1488813, 13.4033125],
                [108.1487049, 13.4034407],
                [108.1482078, 13.403781],
                [108.1480027, 13.4039707],
                [108.1478605, 13.4041677],
                [108.1477036, 13.4044126],
                [108.1476109, 13.4045693],
                [108.147505, 13.4046503],
                [108.1473748, 13.4047139],
                [108.147172, 13.4047047],
                [108.1470047, 13.4046617],
                [108.1468242, 13.4045431],
                [108.1466164, 13.4043626],
                [108.1464014, 13.4042095],
                [108.1460669, 13.4040961],
                [108.1457946, 13.4040588],
                [108.1455638, 13.4040561],
                [108.1452586, 13.4041246],
                [108.1450196, 13.4042315],
                [108.144857, 13.4043668],
                [108.1447292, 13.4045297],
                [108.1446361, 13.4047137],
                [108.144533, 13.4051579],
                [108.1445096, 13.4053564],
                [108.1444605, 13.4056608],
                [108.1443809, 13.4058861],
                [108.1442521, 13.4061312],
                [108.1440813, 13.4063829],
                [108.1440092, 13.4065534],
                [108.1440113, 13.4066801],
                [108.1440727, 13.406811],
                [108.1442108, 13.4069633],
                [108.144489, 13.4071035],
                [108.1447879, 13.4072576],
                [108.1448707, 13.4073545],
                [108.1449045, 13.4074576],
                [108.1448893, 13.4075603],
                [108.1448255, 13.4076281],
                [108.144734, 13.4076818],
                [108.1446217, 13.4077081],
                [108.1444045, 13.4077329],
                [108.1440514, 13.4077187],
                [108.1438067, 13.4077159],
                [108.1435684, 13.4077475],
                [108.143112, 13.407893],
                [108.142444, 13.4081801],
                [108.1421306, 13.4083582],
                [108.1417576, 13.4085527],
                [108.1414835, 13.4086592],
                [108.1412657, 13.408739],
                [108.141063, 13.408723],
                [108.1409305, 13.4086872],
                [108.1408406, 13.408597],
                [108.1407515, 13.4084453],
                [108.1406986, 13.4081844],
                [108.1406244, 13.4076593],
                [108.1405793, 13.4073299],
                [108.1404849, 13.4070341],
                [108.1403408, 13.4067927],
                [108.1401985, 13.4066985],
                [108.1399891, 13.4066688],
                [108.1397298, 13.4067002],
                [108.1394421, 13.406779],
                [108.1389785, 13.4069382],
                [108.1385921, 13.4070777],
                [108.1382454, 13.4071183],
                [108.1379658, 13.4071015],
                [108.1375611, 13.4070147],
                [108.1373239, 13.4069571],
                [108.1371628, 13.406969],
                [108.1370293, 13.4070156],
                [108.1369164, 13.4071033],
                [108.1367536, 13.4072659],
                [108.1365998, 13.4075485],
                [108.1364691, 13.4079514],
                [108.1363162, 13.4084566],
                [108.1362178, 13.4087878],
                [108.1360971, 13.4089441],
                [108.1359134, 13.4090927],
                [108.1356672, 13.4092065],
                [108.1353238, 13.4092642],
                [108.134904, 13.4092732],
                [108.1343796, 13.4092466],
                [108.1336782, 13.4091119],
                [108.1329526, 13.4089461],
                [108.1326876, 13.4088814],
                [108.132492, 13.4088586],
                [108.1323062, 13.4088873],
                [108.132158, 13.4090089],
                [108.1320015, 13.4092265],
                [108.131815, 13.4096081],
                [108.1316272, 13.4100992],
                [108.1313878, 13.4105317],
                [108.1310838, 13.4108091],
                [108.1306749, 13.4110717],
                [108.1303576, 13.4112736],
                [108.1301149, 13.4113908],
                [108.1298699, 13.4114154],
                [108.1296182, 13.411392],
                [108.1292904, 13.4113129],
                [108.1288794, 13.4111712],
                [108.1283788, 13.4109051],
                [108.1281462, 13.410762],
                [108.1275847, 13.4103308],
                [108.126995, 13.4099129],
                [108.1266824, 13.4097381],
                [108.126348, 13.4096108],
                [108.126003, 13.4095007],
                [108.1256958, 13.4094561],
                [108.1253598, 13.4094797],
                [108.1250161, 13.409558],
                [108.1246786, 13.4097048],
                [108.1242344, 13.4099876],
                [108.1237287, 13.4104512],
                [108.12326, 13.4110489],
                [108.1230894, 13.411273],
                [108.1227181, 13.4116148],
                [108.1224578, 13.4117353],
                [108.1220647, 13.4118403],
                [108.1217354, 13.4118846],
                [108.1211333, 13.4119256],
                [108.120751, 13.4120138],
                [108.1203704, 13.4122561],
                [108.1198565, 13.4125312],
                [108.1194625, 13.4127185],
                [108.1190196, 13.4129054],
                [108.1185513, 13.4131569],
                [108.1180014, 13.4135138],
                [108.1175012, 13.4138165],
                [108.1171065, 13.4140654],
                [108.1162782, 13.414587],
                [108.1157706, 13.4149238],
                [108.1152975, 13.4152884],
                [108.1145255, 13.41579],
                [108.1140604, 13.4160725],
                [108.1137017, 13.4162328],
                [108.1133221, 13.4163929],
                [108.1127294, 13.4165198],
                [108.112295, 13.4165834],
                [108.1120011, 13.4165868],
                [108.1116376, 13.4165621],
                [108.1113937, 13.4164839],
                [108.1110423, 13.4163257],
                [108.110695, 13.4161231],
                [108.1101209, 13.4155751],
                [108.1098109, 13.415181],
                [108.1095054, 13.4146944],
                [108.1092523, 13.4142119],
                [108.1090894, 13.4137989],
                [108.1089092, 13.4133549],
                [108.1086906, 13.4129138],
                [108.1082898, 13.4125119],
                [108.1074438, 13.4118854],
                [108.106401, 13.4113527],
                [108.1058986, 13.4112373],
                [108.1055763, 13.4112885],
                [108.1052848, 13.411357],
                [108.1050817, 13.4113685],
                [108.1048126, 13.4113493],
                [108.104575, 13.4113191],
                [108.1043871, 13.4112416],
                [108.1041375, 13.4110467],
                [108.1038189, 13.4107758],
                [108.103527, 13.4106149],
                [108.1031302, 13.4104459],
                [108.1024778, 13.4102979],
                [108.1018907, 13.4102501],
                [108.1013033, 13.4102159],
                [108.1002303, 13.4101316],
                [108.0995455, 13.4100688],
                [108.0989062, 13.4099895],
                [108.098564, 13.4099377],
                [108.0982993, 13.4098386],
                [108.0980013, 13.4096091],
                [108.0973997, 13.4090127],
                [108.0970717, 13.408656],
                [108.0965658, 13.4082527],
                [108.0962046, 13.4080292],
                [108.0958221, 13.4078398],
                [108.0954456, 13.4077258],
                [108.0950026, 13.4076145],
                [108.0944511, 13.4075122],
                [108.0941229, 13.4074673],
                [108.0938222, 13.407457],
                [108.0934579, 13.4074938],
                [108.0931875, 13.4075764],
                [108.0927443, 13.4077837],
                [108.092417, 13.4080685],
                [108.0921464, 13.4083093],
                [108.0918686, 13.4084747],
                [108.0915561, 13.4085609],
                [108.0912467, 13.4086577],
                [108.0909292, 13.4087215],
                [108.0906959, 13.4087176],
                [108.0905241, 13.4087448],
                [108.0903822, 13.408778],
                [108.0902235, 13.4088335],
                [108.0900605, 13.4089119],
                [108.0899194, 13.408917],
                [108.089807, 13.408889],
                [108.089709, 13.4088342],
                [108.089573, 13.4087088],
                [108.0894212, 13.4084526],
                [108.0890796, 13.4078426],
                [108.0888716, 13.407559],
                [108.0887265, 13.4073163],
                [108.0885017, 13.4069688],
                [108.0883772, 13.4067665],
                [108.0881889, 13.4066036],
                [108.0879719, 13.4064806],
                [108.0876918, 13.4063368],
                [108.0872757, 13.4060674],
                [108.0869753, 13.4058564],
                [108.086541, 13.4056304],
                [108.0856751, 13.4052554],
                [108.0852267, 13.405036],
                [108.0846663, 13.4047616],
                [108.0841305, 13.4044843],
                [108.0838789, 13.4043073],
                [108.083705, 13.404111],
                [108.0836083, 13.4039492],
                [108.0835336, 13.4037207],
                [108.0834546, 13.4035557],
                [108.0833505, 13.4034207],
                [108.0832038, 13.4033119],
                [108.083001, 13.4031822],
                [108.0828052, 13.4030527],
                [108.0826099, 13.4028897],
                [108.0823976, 13.402663],
                [108.0821963, 13.4024129],
                [108.0819676, 13.4020955],
                [108.081764, 13.4017416],
                [108.0817367, 13.4016677],
                [108.0816416, 13.401372],
                [108.0815333, 13.4010024],
                [108.0814504, 13.4008608],
                [108.0812631, 13.4006108],
                [108.0809965, 13.4002228],
                [108.0806977, 13.3998711],
                [108.0805371, 13.3997554],
                [108.0803686, 13.3996999],
                [108.0801785, 13.3996844],
                [108.0799876, 13.3997424],
                [108.0798664, 13.3998481],
                [108.0797591, 13.3999808],
                [108.0797082, 13.4001141],
                [108.0796811, 13.4003114],
                [108.0796782, 13.4005525],
                [108.0797251, 13.4007472],
                [108.0798144, 13.4009491],
                [108.0800251, 13.4012964],
                [108.0802253, 13.4016401],
                [108.0803634, 13.4018761],
                [108.0804689, 13.4021887],
                [108.0805289, 13.4024706],
                [108.0805243, 13.4028523],
                [108.0805039, 13.4030897],
                [108.0804518, 13.4033168],
                [108.0803574, 13.4035434],
                [108.0802568, 13.4036962],
                [108.0801426, 13.4038221],
                [108.0798972, 13.4040101],
                [108.0795349, 13.404247],
                [108.0793223, 13.404345],
                [108.0791531, 13.4043564],
                [108.078984, 13.4043477],
                [108.0787947, 13.4042719],
                [108.0784728, 13.4040739],
                [108.0782873, 13.4039802],
                [108.0780834, 13.403931],
                [108.0779002, 13.4039289],
                [108.0774487, 13.4039705],
                [108.077195, 13.4039743],
                [108.0768925, 13.4039306],
                [108.0767242, 13.4038551],
                [108.0765356, 13.403719],
                [108.0762359, 13.4034414],
                [108.0759787, 13.4031639],
                [108.0757633, 13.4029203],
                [108.0752817, 13.4022553],
                [108.075157, 13.4020732],
                [108.0747694, 13.4014996],
                [108.0744138, 13.400903],
                [108.0741767, 13.4004083],
                [108.0741496, 13.4003276],
                [108.0739782, 13.3999373],
                [108.0738401, 13.3997014],
                [108.0736729, 13.3995388],
                [108.0735331, 13.3994568],
                [108.0733927, 13.3994084],
                [108.0732238, 13.3993997],
                [108.0730899, 13.3993982],
                [108.0729728, 13.3994671],
                [108.0728729, 13.3995663],
                [108.072715, 13.3998055],
                [108.0725641, 13.4000514],
                [108.0724019, 13.4003542],
                [108.0723215, 13.4006009],
                [108.072241, 13.4008477],
                [108.0722033, 13.4010481],
                [108.0721554, 13.4012182],
                [108.0720758, 13.4013981],
                [108.0719884, 13.4016314],
                [108.0718726, 13.4018843],
                [108.0717033, 13.402187],
                [108.0716451, 13.4023403],
                [108.0716488, 13.4023407],
                [108.0714821, 13.4024623],
                [108.0712164, 13.4024986],
                [108.0708599, 13.4025024],
                [108.0705778, 13.4025149],
                [108.0704114, 13.4025681],
                [108.0702526, 13.4026765],
                [108.0701424, 13.4028722],
                [108.0700974, 13.403163],
                [108.070094, 13.4034466],
                [108.070108, 13.4036516],
                [108.0701887, 13.4038416],
                [108.0703271, 13.4040558],
                [108.0704999, 13.4042629],
                [108.0704087, 13.4044057],
                [108.0703501, 13.4045992],
                [108.0702844, 13.405077],
                [108.0702097, 13.4054242],
                [108.0701301, 13.4058953],
                [108.0701067, 13.4060824],
                [108.0700467, 13.4063762],
                [108.0699946, 13.4066099],
                [108.0699112, 13.4067998],
                [108.0698459, 13.406953],
                [108.0697596, 13.407106],
                [108.0696014, 13.4073719],
                [108.0694464, 13.4076534],
                [108.0693885, 13.4077798],
                [108.069344, 13.4079666],
                [108.0693317, 13.4081065],
                [108.0693276, 13.4081537],
                [108.0692827, 13.4083805],
                [108.0692158, 13.4086741],
                [108.0691786, 13.4088275],
                [108.0691127, 13.4090344],
                [108.0690257, 13.4092407],
                [108.0689608, 13.4093604],
                [108.0688891, 13.4094667],
                [108.0687471, 13.4095588],
                [108.0685988, 13.4095906],
                [108.0682433, 13.4095697],
                [108.0678218, 13.4094778],
                [108.0674426, 13.4093796],
                [108.0671199, 13.4092756],
                [108.0667345, 13.4091103],
                [108.0664791, 13.4089703],
                [108.0663744, 13.4088887],
                [108.0662357, 13.4087064],
                [108.0661393, 13.4085246],
                [108.0661206, 13.4083235],
                [108.0661345, 13.408046],
                [108.0661239, 13.4077648],
                [108.0660771, 13.4075635],
                [108.0660018, 13.4073751],
                [108.0658528, 13.4071694],
                [108.0656162, 13.406939],
                [108.0651413, 13.4065989],
                [108.0647354, 13.4063799],
                [108.0645463, 13.4063041],
                [108.0642891, 13.4063044],
                [108.0641757, 13.4063634],
                [108.0640686, 13.4064826],
                [108.0639892, 13.4066423],
                [108.0639512, 13.4068627],
                [108.0639583, 13.4071607],
                [108.0639688, 13.4074485],
                [108.0640222, 13.4076901],
                [108.0641035, 13.407952],
                [108.064188, 13.4082441],
                [108.0642636, 13.4084057],
                [108.0643105, 13.4086003],
                [108.0642945, 13.4087541],
                [108.0642439, 13.4088673],
                [108.0641365, 13.4090066],
                [108.0640015, 13.4091054],
                [108.06386, 13.4091505],
                [108.0636597, 13.4091181],
                [108.0634706, 13.4090289],
                [108.0629315, 13.4087549],
                [108.0625109, 13.4085893],
                [108.0621915, 13.408502],
                [108.0619528, 13.408439],
                [108.0615873, 13.4083746],
                [108.0612709, 13.4083306],
                [108.0609468, 13.4083403],
                [108.060548, 13.4083992],
                [108.060061, 13.4084806],
                [108.0597508, 13.4085103],
                [108.0593425, 13.4084788],
                [108.0590018, 13.4084046],
                [108.0585888, 13.4081924],
                [108.058134, 13.4079461],
                [108.0578965, 13.4077827],
                [108.0576377, 13.4076324],
                [108.0574381, 13.407533],
                [108.0571925, 13.4074633],
                [108.0570447, 13.4074548],
                [108.0569033, 13.4074934],
                [108.0565779, 13.4076101],
                [108.0562171, 13.407733],
                [108.0558809, 13.4078729],
                [108.0556106, 13.4080839],
                [108.0554182, 13.4082691],
                [108.0553108, 13.4084083],
                [108.0552386, 13.4085547],
                [108.0552113, 13.408772],
                [108.0552009, 13.409053],
                [108.0552412, 13.409214],
                [108.0553586, 13.4094028],
                [108.0554767, 13.4095314],
                [108.0556162, 13.4096468],
                [108.0558261, 13.409763],
                [108.0562156, 13.4098846],
                [108.0565868, 13.4100562],
                [108.0567827, 13.4101723],
                [108.0568797, 13.4102939],
                [108.0569202, 13.4104484],
                [108.0569183, 13.4106089],
                [108.0569051, 13.4108196],
                [108.0568328, 13.4109727],
                [108.0567398, 13.4110854],
                [108.0565407, 13.4112436],
                [108.0561781, 13.4115139],
                [108.0560106, 13.4116758],
                [108.0557812, 13.4120012],
                [108.0556242, 13.4121733],
                [108.0554041, 13.4123112],
                [108.0552131, 13.4123759],
                [108.0547828, 13.4124378],
                [108.0544616, 13.412491],
                [108.053897, 13.412578],
                [108.0535937, 13.412608],
                [108.0533402, 13.412605],
                [108.0530242, 13.4125278],
                [108.0526844, 13.4123731],
                [108.0523911, 13.4121689],
                [108.0522591, 13.4120202],
                [108.0521564, 13.4117781],
                [108.0521192, 13.4115697],
                [108.0521188, 13.4115672],
                [108.0521177, 13.4115668],
                [108.0518689, 13.4114432],
                [108.0515267, 13.41125],
                [108.0513105, 13.4110728],
                [108.0511285, 13.4109252],
                [108.0510153, 13.4107928],
                [108.050918, 13.4106026],
                [108.0508669, 13.4104052],
                [108.0508234, 13.4102085],
                [108.0507411, 13.4100475],
                [108.0506285, 13.4098571],
                [108.0505158, 13.4096813],
                [108.0502894, 13.4094022],
                [108.0500476, 13.4091231],
                [108.0499006, 13.4089334],
                [108.0497954, 13.4087575],
                [108.0497406, 13.4086053],
                [108.0494758, 13.4084598],
                [108.0489158, 13.4081858],
                [108.048336, 13.4078246],
                [108.0481681, 13.4077356],
                [108.0478265, 13.4074707],
                [108.0476455, 13.4073014],
                [108.0475213, 13.4070993],
                [108.0474563, 13.406948],
                [108.0473903, 13.4065862],
                [108.0473229, 13.4060536],
                [108.0472588, 13.4055379],
                [108.0472386, 13.4051799],
                [108.0472134, 13.4049321],
                [108.0471449, 13.4047775],
                [108.0469988, 13.404642],
                [108.0467536, 13.4045388],
                [108.0464453, 13.4044215],
                [108.0461338, 13.4042807],
                [108.0459591, 13.4041783],
                [108.045869, 13.4040636],
                [108.0458218, 13.4039025],
                [108.0457827, 13.4033503],
                [108.045741, 13.4024369],
                [108.0457113, 13.4019885],
                [108.0456429, 13.4015496],
                [108.0456368, 13.4014625],
                [108.0455628, 13.4011874],
                [108.0454588, 13.4010524],
                [108.0453265, 13.4009372],
                [108.0450612, 13.4007602],
                [108.0448341, 13.4006271],
                [108.0445612, 13.4004902],
                [108.0442526, 13.4003995],
                [108.043958, 13.4003159],
                [108.0438035, 13.4002874],
                [108.0436277, 13.4002652],
                [108.0434585, 13.4002966],
                [108.0432426, 13.4003911],
                [108.0429598, 13.4004948],
                [108.0428185, 13.4005332],
                [108.0426777, 13.4005317],
                [108.0425092, 13.4004962],
                [108.0423204, 13.4003937],
                [108.0420863, 13.4002194],
                [108.0416278, 13.399726],
                [108.0413492, 13.3994953],
                [108.0411117, 13.3993522],
                [108.0408594, 13.3992623],
                [108.0406695, 13.3992533],
                [108.0405285, 13.3992784],
                [108.0404573, 13.3993446],
                [108.0404131, 13.3995044],
                [108.0403566, 13.3998147],
                [108.040305, 13.4000014],
                [108.0402405, 13.4000942],
                [108.040056, 13.4002258],
                [108.0397799, 13.4003563],
                [108.0394611, 13.4005331],
                [108.0392659, 13.4006678],
                [108.0391446, 13.4008068],
                [108.038987, 13.4010323],
                [108.0387999, 13.4013712],
                [108.0386812, 13.4015804],
                [108.0384526, 13.4018585],
                [108.0382317, 13.4020766],
                [108.0380614, 13.4021949],
                [108.0378427, 13.4022459],
                [108.0376668, 13.4022438],
                [108.0375473, 13.402229],
                [108.0373859, 13.402187],
                [108.0372452, 13.4021853],
                [108.0370441, 13.4022332],
                [108.0367185, 13.4023831],
                [108.0363791, 13.4025128],
                [108.0361242, 13.4026435],
                [108.0359759, 13.4026819],
                [108.0358351, 13.402687],
                [108.0356881, 13.4026317],
                [108.0355624, 13.4025501],
                [108.0354756, 13.4024521],
                [108.0354556, 13.4023582],
                [108.0354508, 13.4021843],
                [108.0354481, 13.4015457],
                [108.0354493, 13.4014387],
                [108.0354416, 13.4006396],
                [108.0354308, 13.4003653],
                [108.0354283, 13.4000043],
                [108.0354026, 13.3997966],
                [108.0353247, 13.3995517],
                [108.0352633, 13.3993904],
                [108.0351045, 13.3991345],
                [108.0348865, 13.3988477],
                [108.0345601, 13.3984895],
                [108.0341388, 13.3978428],
                [108.0340632, 13.397688],
                [108.0340473, 13.3975508],
                [108.0339801, 13.3972959],
                [108.0339161, 13.3970631],
                [108.0339103, 13.3969629],
                [108.0338355, 13.3967483],
                [108.0336493, 13.3964455],
                [108.0334695, 13.3961827],
                [108.0332506, 13.3959496],
                [108.033139, 13.3958748],
                [108.0330268, 13.39584],
                [108.0328998, 13.3958719],
                [108.0327262, 13.3959803],
                [108.0325141, 13.396058],
                [108.0323738, 13.3960296],
                [108.0321704, 13.3959737],
                [108.0319954, 13.3959048],
                [108.0317787, 13.3957886],
                [108.0315974, 13.3956595],
                [108.0312945, 13.3954052],
                [108.0309036, 13.3951533],
                [108.0305888, 13.3950159],
                [108.0304205, 13.3949671],
                [108.0302169, 13.3949247],
                [108.02995, 13.3948947],
                [108.0294222, 13.3949019],
                [108.0291861, 13.3949413],
                [108.0288927, 13.3950209],
                [108.0285229, 13.3951506],
                [108.0280253, 13.3953083],
                [108.0275492, 13.3954008],
                [108.0273149, 13.3954307],
                [108.0271222, 13.3954219],
                [108.0269574, 13.3953873],
                [108.0267969, 13.3952971],
                [108.0266876, 13.3952173],
                [108.0265793, 13.3950722],
                [108.026492, 13.3949011],
                [108.0264327, 13.394678],
                [108.0264047, 13.394439],
                [108.0264009, 13.3941904],
                [108.026382, 13.3940464],
                [108.0263295, 13.3938429],
                [108.0262468, 13.3935693],
                [108.0261877, 13.3933334],
                [108.0261405, 13.3929665],
                [108.0261065, 13.3928216],
                [108.026047, 13.3925662],
                [108.0258984, 13.3922755],
                [108.0257329, 13.3919083],
                [108.0255933, 13.3916178],
                [108.0255678, 13.3915184],
                [108.0252485, 13.3914319],
                [108.0250047, 13.3913009],
                [108.0248555, 13.3912013],
                [108.0246676, 13.391026],
                [108.0243939, 13.3907443],
                [108.0240471, 13.3902836],
                [108.0240002, 13.3902379],
                [108.0238372, 13.3899654],
                [108.0236041, 13.3896093],
                [108.0232088, 13.3889203],
                [108.0231622, 13.3888445],
                [108.0230228, 13.3885945],
                [108.02279, 13.3882157],
                [108.0226506, 13.3879582],
                [108.0225612, 13.3878255],
                [108.0223815, 13.3876353],
                [108.0220997, 13.3873687],
                [108.0218095, 13.387147],
                [108.0213733, 13.3868899],
                [108.0211923, 13.3867975],
                [108.0209635, 13.3867346],
                [108.0207186, 13.3866865],
                [108.0203272, 13.3866574],
                [108.0203197, 13.3866123],
                [108.0202735, 13.3865139],
                [108.0201555, 13.3864523],
                [108.0199506, 13.3863747],
                [108.0197376, 13.386312],
                [108.0194971, 13.3862287],
                [108.0192291, 13.3861354],
                [108.0189291, 13.3860717],
                [108.0186287, 13.3860381],
                [108.0184702, 13.3860513],
                [108.0183433, 13.3860648],
                [108.0182239, 13.386116],
                [108.0181198, 13.3862125],
                [108.0179596, 13.3863684],
                [108.0177826, 13.3865919],
                [108.017686, 13.3867184],
                [108.0174563, 13.3867383],
                [108.0171081, 13.3867342],
                [108.0166182, 13.3866683],
                [108.0161998, 13.3865805],
                [108.0153675, 13.3863564],
                [108.0149343, 13.3861934],
                [108.0147373, 13.3861082],
                [108.0145957, 13.386039],
                [108.0144618, 13.3859922],
                [108.0142254, 13.3859067],
                [108.0139896, 13.3857759],
                [108.013681, 13.3854438],
                [108.0136499, 13.3853984],
                [108.0135338, 13.3851941],
                [108.0134418, 13.384945],
                [108.0133274, 13.384598],
                [108.0131198, 13.3841144],
                [108.0130275, 13.3838953],
                [108.0129509, 13.3836914],
                [108.0128673, 13.3834122],
                [108.0127643, 13.383099],
                [108.0127569, 13.3830612],
                [108.0126884, 13.3828499],
                [108.01258, 13.3826531],
                [108.0124319, 13.3824709],
                [108.0122045, 13.3823028],
                [108.0117285, 13.3820828],
                [108.0113506, 13.3819204],
                [108.0109885, 13.3817733],
                [108.0105957, 13.3815431],
                [108.0099792, 13.3811711],
                [108.0095542, 13.3809855],
                [108.0092938, 13.3809224],
                [108.0089302, 13.3808955],
                [108.0087083, 13.3809229],
                [108.0085178, 13.3809733],
                [108.0082675, 13.3810569],
                [108.0081401, 13.3811155],
                [108.0078526, 13.3813301],
                [108.0075726, 13.3815825],
                [108.0071434, 13.3820549],
                [108.0068144, 13.3824344],
                [108.0064942, 13.3827314],
                [108.0059866, 13.3831428],
                [108.0055788, 13.3834913],
                [108.0053151, 13.3836912],
                [108.0050994, 13.3838616],
                [108.004339, 13.3842398],
                [108.00379, 13.3844815],
                [108.0035274, 13.3846061],
                [108.0031611, 13.3847972],
                [108.0024713, 13.3852276],
                [108.0019054, 13.385559],
                [108.0015622, 13.3857501],
                [108.0003785, 13.385994],
                [108.0002188, 13.3860648],
                [108.0000408, 13.3865047],
                [107.9998049, 13.3872252],
                [107.9993637, 13.3881737],
                [107.9991571, 13.3886095],
                [107.9985348, 13.3896602],
                [107.9981115, 13.3903396],
                [107.9978358, 13.3905376],
                [107.997399, 13.3907375],
                [107.9967402, 13.3907313],
                [107.9964902, 13.390637],
                [107.9958702, 13.3902272],
                [107.9951597, 13.3895959],
                [107.99431, 13.3889455],
                [107.9941037, 13.3888367],
                [107.9938542, 13.388784],
                [107.9930939, 13.3888625],
                [107.9926133, 13.3890629],
                [107.9915322, 13.3898315],
                [107.9909541, 13.3904359],
                [107.9904498, 13.3910951],
                [107.9902473, 13.3912921],
                [107.9901035, 13.3915022],
                [107.9895962, 13.3919112],
                [107.9893856, 13.3920458],
                [107.9888454, 13.3921633],
                [107.9884356, 13.3921682],
                [107.9880689, 13.392117],
                [107.9872982, 13.3919385],
                [107.9868905, 13.3919259],
                [107.9866062, 13.3918435],
                [107.9861569, 13.3918854],
                [107.9854891, 13.3921833],
                [107.9851844, 13.39248],
                [107.9845524, 13.3928171],
                [107.9839652, 13.3928759],
                [107.9830445, 13.3927342],
                [107.9821746, 13.3922713],
                [107.9818506, 13.3920493],
                [107.9810088, 13.3912626],
                [107.9804872, 13.3906459],
                [107.9803194, 13.3903275],
                [107.980221, 13.3893792],
                [107.9801845, 13.3890318],
                [107.9801994, 13.3884119],
                [107.9802465, 13.3880573],
                [107.9804534, 13.3873864],
                [107.9807481, 13.3867907],
                [107.9811638, 13.3863677],
                [107.9814806, 13.3860099],
                [107.9817638, 13.3855242],
                [107.9818228, 13.3853556],
                [107.9819544, 13.3850916],
                [107.9819891, 13.3847737],
                [107.9818978, 13.3844053],
                [107.9816103, 13.384012],
                [107.9813255, 13.3838505],
                [107.9809518, 13.3837633],
                [107.9806236, 13.3837183],
                [107.9801331, 13.3838066],
                [107.9797109, 13.383958],
                [107.9787174, 13.3841529],
                [107.9782457, 13.3841982],
                [107.9778345, 13.3842091],
                [107.9776028, 13.3841752],
                [107.9770983, 13.3839065],
                [107.9766625, 13.3837315],
                [107.9762173, 13.3835902],
                [107.9758183, 13.3835339],
                [107.9755401, 13.3833784],
                [107.9753315, 13.3831366],
                [107.975195, 13.3827446],
                [107.9751399, 13.38244],
                [107.9748906, 13.3820216],
                [107.9746013, 13.3817534],
                [107.9740318, 13.3814243],
                [107.9736846, 13.3813979],
                [107.9730415, 13.3816221],
                [107.9727348, 13.381754],
                [107.972295, 13.38204],
                [107.9717067, 13.3825383],
                [107.9713751, 13.3827437],
                [107.9708896, 13.3829753],
                [107.9704598, 13.3830292],
                [107.9700062, 13.3829826],
                [107.969555, 13.3828598],
                [107.9691155, 13.3826512],
                [107.9680507, 13.3820136],
                [107.9677589, 13.3818033],
                [107.9671485, 13.3810199],
                [107.9664008, 13.3802961],
                [107.9657925, 13.3799491],
                [107.9652359, 13.3798733],
                [107.9644258, 13.37984],
                [107.9632614, 13.3799849],
                [107.9628061, 13.3800574],
                [107.9615011, 13.3802711],
                [107.9610328, 13.3803315],
                [107.9598356, 13.3804219],
                [107.9591423, 13.3804728],
                [107.9587448, 13.3805445],
                [107.9583137, 13.3807601],
                [107.9581234, 13.3809517],
                [107.9579844, 13.3811548],
                [107.9578916, 13.3814489],
                [107.9578846, 13.3816657],
                [107.9578617, 13.3818858],
                [107.9577045, 13.3821684],
                [107.957162, 13.3827273],
                [107.9568117, 13.3829878],
                [107.9563594, 13.3833106],
                [107.9559387, 13.3835841],
                [107.9557768, 13.3837417],
                [107.9554724, 13.3840749],
                [107.955182, 13.3844996],
                [107.954733, 13.3848192],
                [107.9543688, 13.3849944],
                [107.9539905, 13.3850599],
                [107.9529951, 13.3853738],
                [107.9526052, 13.3855371],
                [107.9520867, 13.3856957],
                [107.9516441, 13.3857558],
                [107.9514417, 13.3857613],
                [107.951152, 13.3857158],
                [107.9508363, 13.3856524],
                [107.9504893, 13.3856442],
                [107.9501363, 13.3856788],
                [107.9499495, 13.3856444],
                [107.9496927, 13.385394],
                [107.949639, 13.3852114],
                [107.9495998, 13.3843663],
                [107.9497271, 13.3834705],
                [107.9497889, 13.3832683],
                [107.9498585, 13.3823914],
                [107.9498929, 13.3820553],
                [107.9498111, 13.3814061],
                [107.9497119, 13.3811691],
                [107.9494106, 13.3804369],
                [107.9489871, 13.3799595],
                [107.9485936, 13.3798298],
                [107.948112, 13.3798538],
                [107.9474996, 13.3799556],
                [107.9469946, 13.3801751],
                [107.9464638, 13.3803828],
                [107.9457768, 13.3806808],
                [107.9454434, 13.3807457],
                [107.9450896, 13.3807072],
                [107.9446834, 13.3805959],
                [107.9442683, 13.3802771],
                [107.9439817, 13.3799569],
                [107.9439299, 13.3796614],
                [107.9439012, 13.379152],
                [107.9440398, 13.3781339],
                [107.9440697, 13.3779441],
                [107.9441784, 13.3771096],
                [107.9443017, 13.3766808],
                [107.9442524, 13.3760679],
                [107.9442383, 13.3754331],
                [107.9441931, 13.3751497],
                [107.944093, 13.3748396],
                [107.9438111, 13.3741193],
                [107.9434829, 13.3735431],
                [107.9431321, 13.3732237],
                [107.9428972, 13.373199],
                [107.9426798, 13.373287],
                [107.9424506, 13.3734545],
                [107.9421501, 13.3735862],
                [107.9417787, 13.3736881],
                [107.9413261, 13.3737148],
                [107.9410053, 13.3737613],
                [107.9406994, 13.3739602],
                [107.9404911, 13.3742679],
                [107.9402226, 13.3746464],
                [107.9401158, 13.3748491],
                [107.9398826, 13.3752182],
                [107.9395967, 13.375484],
                [107.9393384, 13.3756487],
                [107.9389162, 13.3758003],
                [107.9384989, 13.3758234],
                [107.93782, 13.3757478],
                [107.9372918, 13.3756318],
                [107.9365864, 13.3754906],
                [107.9361745, 13.3754344],
                [107.9358667, 13.3754745],
                [107.9355978, 13.375563],
                [107.9351495, 13.3756903],
                [107.9345083, 13.3757953],
                [107.9341485, 13.3757995],
                [107.9336468, 13.3757565],
                [107.933186, 13.3757068],
                [107.9330139, 13.3756881],
                [107.9326991, 13.3758544],
                [107.9307119, 13.3765391],
                [107.9269308, 13.3778343],
                [107.9251135, 13.3784168],
                [107.9231886, 13.3791533],
                [107.922085, 13.3792637],
                [107.9209448, 13.3795332],
                [107.9207165, 13.3798501],
                [107.9200438, 13.3810025],
                [107.9191304, 13.3819775],
                [107.917295, 13.3837383],
                [107.91643, 13.3844563],
                [107.9152166, 13.3850563],
                [107.9139146, 13.3852361],
                [107.9127844, 13.3852858],
                [107.9119625, 13.3853197],
                [107.9114022, 13.385729],
                [107.9109829, 13.3861245],
                [107.9103182, 13.386413],
                [107.9101484, 13.386714],
                [107.9098982, 13.3872785],
                [107.9094165, 13.3878211],
                [107.9089218, 13.3883519],
                [107.908142, 13.3886903],
                [107.9071743, 13.3888908],
                [107.9065554, 13.3887148],
                [107.9053314, 13.3884358],
                [107.9038321, 13.3882394],
                [107.9028179, 13.3883122],
                [107.9012507, 13.3883424],
                [107.9006522, 13.3882577],
                [107.8994486, 13.3875391],
                [107.8967204, 13.3866487],
                [107.8936107, 13.3860922],
                [107.8922834, 13.3857656],
                [107.8895119, 13.3855593],
                [107.8882094, 13.3851591],
                [107.8869696, 13.3846361],
                [107.8864439, 13.3852708],
                [107.8859804, 13.386265],
                [107.8859225, 13.3873339],
                [107.8859059, 13.3881031],
                [107.8847992, 13.3879571],
                [107.8838222, 13.3879194],
                [107.8837731, 13.3881153],
                [107.8833703, 13.3893591],
                [107.883049, 13.3904249],
                [107.8822935, 13.3911843],
                [107.8818197, 13.391324],
                [107.8810208, 13.3911377],
                [107.8802915, 13.3913902],
                [107.8791531, 13.3923738],
                [107.8784512, 13.3927847],
                [107.8765208, 13.3936125],
                [107.8755881, 13.3935193],
                [107.87216, 13.3932104],
                [107.8682252, 13.392431],
                [107.8643156, 13.3916147],
                [107.8603541, 13.3907501],
                [107.8565259, 13.3902867],
                [107.8534652, 13.389534],
                [107.8516763, 13.3892978],
                [107.8512047, 13.3896205],
                [107.8499259, 13.390691],
                [107.8491126, 13.3914693],
                [107.8477085, 13.3928098],
                [107.8456318, 13.3948659],
                [107.8435391, 13.3966659],
                [107.8416777, 13.3984633],
                [107.8406986, 13.3993532],
                [107.8392281, 13.4005113],
                [107.8357543, 13.3985175],
                [107.8344352, 13.3977999],
                [107.8330769, 13.3970338],
                [107.8314557, 13.3963012],
                [107.8276474, 13.3953611],
                [107.8237621, 13.3944218],
                [107.821623, 13.3939085],
                [107.8193098, 13.3933422],
                [107.8168624, 13.3928446],
                [107.8161933, 13.3927666],
                [107.8157019, 13.3930468],
                [107.8153574, 13.3932582],
                [107.8149352, 13.3934216],
                [107.814578, 13.3936454],
                [107.8141177, 13.3938458],
                [107.8134635, 13.3939507],
                [107.8126223, 13.3939907],
                [107.8121743, 13.3941421],
                [107.8118171, 13.3943659],
                [107.8113431, 13.3944932],
                [107.8108154, 13.3944137],
                [107.8102101, 13.3942984],
                [107.8098119, 13.394315],
                [107.809493, 13.3945261],
                [107.8092073, 13.3948162],
                [107.8088224, 13.3948693],
                [107.8085139, 13.3948605],
                [107.8080982, 13.3944744],
                [107.8075933, 13.3941504],
                [107.8071171, 13.3940946],
                [107.8064504, 13.394212],
                [107.8058929, 13.394344],
                [107.8053524, 13.3942768],
                [107.8050927, 13.3940479],
                [107.8047025, 13.3936375],
                [107.8043511, 13.3932633],
                [107.8038614, 13.3931345],
                [107.8033367, 13.3933233],
                [107.8027731, 13.3934759],
                [107.8015805, 13.3937287],
                [107.8014922, 13.3937169],
                [107.8016017, 13.3925552],
                [107.8015597, 13.3923142],
                [107.8014881, 13.3920199],
                [107.8014232, 13.3917843],
                [107.8013611, 13.3915707],
                [107.8012257, 13.3912626],
                [107.8011298, 13.3909826],
                [107.8010852, 13.3908659],
                [107.8009729, 13.3906702],
                [107.8008803, 13.3905273],
                [107.8007872, 13.3902974],
                [107.8005817, 13.3899991],
                [107.8005044, 13.3899054],
                [107.8003544, 13.3897782],
                [107.8001556, 13.3896121],
                [107.7999916, 13.3895211],
                [107.7997536, 13.3893785],
                [107.7995197, 13.3892576],
                [107.7991055, 13.3890498],
                [107.7988147, 13.388879],
                [107.7984536, 13.3886929],
                [107.7980948, 13.388517],
                [107.7979129, 13.3883849],
                [107.7977893, 13.388279],
                [107.7977204, 13.3881516],
                [107.7976932, 13.387888],
                [107.7976765, 13.3877518],
                [107.7976791, 13.3873316],
                [107.797722, 13.3870931],
                [107.797797, 13.3867403],
                [107.7978801, 13.3864449],
                [107.7979589, 13.3861899],
                [107.798081, 13.3859287],
                [107.7982733, 13.3856618],
                [107.7983621, 13.3855153],
                [107.798504, 13.3853119],
                [107.7986438, 13.3851618],
                [107.7987803, 13.385011],
                [107.7988566, 13.3848674],
                [107.7989747, 13.3846126],
                [107.7990274, 13.3845228],
                [107.7990976, 13.3843475],
                [107.7992294, 13.3840207],
                [107.7993115, 13.3838474],
                [107.7993419, 13.3837014],
                [107.7993789, 13.3835726],
                [107.799439, 13.3834023],
                [107.7995841, 13.3831189],
                [107.7997273, 13.3828729],
                [107.7998091, 13.3827007],
                [107.799926, 13.3823651],
                [107.8000101, 13.3822078],
                [107.8001034, 13.3819695],
                [107.8001408, 13.381798],
                [107.8001196, 13.3816084],
                [107.8001179, 13.38146],
                [107.8000746, 13.3813269],
                [107.7999584, 13.3811386],
                [107.7997727, 13.3809833],
                [107.7996446, 13.3808883],
                [107.7995054, 13.3808099],
                [107.7993177, 13.380741],
                [107.799115, 13.3806905],
                [107.7988095, 13.3806347],
                [107.7986018, 13.3805926],
                [107.7984567, 13.3805827],
                [107.7983066, 13.3805901],
                [107.7981691, 13.3806177],
                [107.7980385, 13.3806816],
                [107.79786, 13.3807719],
                [107.7976852, 13.3808854],
                [107.7974953, 13.3810272],
                [107.7974101, 13.381091],
                [107.797228, 13.3812483],
                [107.7970041, 13.3814462],
                [107.7968334, 13.3816187],
                [107.7963717, 13.3820638],
                [107.7961784, 13.3822067],
                [107.7960582, 13.3822737],
                [107.7959292, 13.3823224],
                [107.7957978, 13.3823328],
                [107.7956931, 13.382334],
                [107.7955375, 13.3823357],
                [107.7953603, 13.3822817],
                [107.7951769, 13.3821709],
                [107.7950075, 13.3820664],
                [107.7946249, 13.3818376],
                [107.7944396, 13.3817424],
                [107.7942576, 13.381649],
                [107.794147, 13.3816061],
                [107.7941264, 13.3816017],
                [107.7936242, 13.3816004],
                [107.7935808, 13.3816033],
                [107.7935064, 13.381623],
                [107.7934231, 13.3816579],
                [107.793203, 13.3817583],
                [107.7928481, 13.3818283],
                [107.7927269, 13.3818378],
                [107.7925657, 13.3818301],
                [107.7923975, 13.3817525],
                [107.7921623, 13.3816647],
                [107.7920263, 13.3815831],
                [107.7917962, 13.381441],
                [107.7914785, 13.3811357],
                [107.7911846, 13.3808694],
                [107.7904544, 13.3801081],
                [107.7903054, 13.37995],
                [107.7899967, 13.3796315],
                [107.7896245, 13.3793552],
                [107.7891939, 13.3790815],
                [107.7889331, 13.3789267],
                [107.7886282, 13.3788056],
                [107.7883317, 13.3786541],
                [107.7881047, 13.3784574],
                [107.7878485, 13.3782535],
                [107.7876452, 13.3780332],
                [107.7875433, 13.3779167],
                [107.7872654, 13.3775007],
                [107.7871452, 13.3773683],
                [107.7869073, 13.376918],
                [107.7867882, 13.3766168],
                [107.7866615, 13.3763003],
                [107.7864773, 13.3759504],
                [107.7863358, 13.3757476],
                [107.7861837, 13.3755951],
                [107.7861137, 13.3755504],
                [107.786012, 13.3755252],
                [107.7858979, 13.3755266],
                [107.7857682, 13.3755214],
                [107.7856085, 13.3755296],
                [107.7854257, 13.3755395],
                [107.7852643, 13.3754988],
                [107.785018, 13.3754172],
                [107.7848962, 13.3753119],
                [107.7846714, 13.3751464],
                [107.7844267, 13.3750143],
                [107.7842582, 13.3749184],
                [107.7841097, 13.3748713],
                [107.7839877, 13.3748553],
                [107.7838312, 13.3748384],
                [107.7835503, 13.3748382],
                [107.7833852, 13.3748204],
                [107.7831536, 13.3748293],
                [107.7827208, 13.3748478],
                [107.7825772, 13.3748357],
                [107.7823754, 13.3748379],
                [107.7822624, 13.3748392],
                [107.782032, 13.3748573],
                [107.7817827, 13.3749007],
                [107.7815058, 13.3749107],
                [107.7812525, 13.3749277],
                [107.7810012, 13.3748977],
                [107.7808678, 13.374836],
                [107.7806366, 13.3747032],
                [107.7803836, 13.3744647],
                [107.7802974, 13.3743766],
                [107.7801197, 13.3741045],
                [107.7799969, 13.373884],
                [107.7799144, 13.373752],
                [107.7798458, 13.3736021],
                [107.7798148, 13.3734893],
                [107.7797829, 13.3734035],
                [107.7797148, 13.3733443],
                [107.779636, 13.3732941],
                [107.7795404, 13.3732666],
                [107.77943, 13.3732503],
                [107.7793009, 13.3732364],
                [107.7791301, 13.3731795],
                [107.7790076, 13.3731442],
                [107.7788137, 13.3731055],
                [107.7786735, 13.3730587],
                [107.7785655, 13.3730553],
                [107.7784082, 13.373082],
                [107.7782596, 13.3730614],
                [107.7781455, 13.3730626],
                [107.778003, 13.3730404],
                [107.7777291, 13.372975],
                [107.777547, 13.3729339],
                [107.777441, 13.3728922],
                [107.7772181, 13.3728223],
                [107.777067, 13.3728052],
                [107.7769507, 13.3727829],
                [107.7767812, 13.3727298],
                [107.7767048, 13.3726792],
                [107.7766043, 13.3726034],
                [107.7764894, 13.372553],
                [107.7762741, 13.3724961],
                [107.7760518, 13.3724074],
                [107.7760404, 13.3724025],
                [107.7758312, 13.3723025],
                [107.7755958, 13.3721748],
                [107.7753116, 13.3718732],
                [107.7751868, 13.3716538],
                [107.775142, 13.3715386],
                [107.7750844, 13.371315],
                [107.7750597, 13.3710544],
                [107.7750301, 13.3708841],
                [107.7749939, 13.3705996],
                [107.7749512, 13.3704931],
                [107.7749018, 13.3703627],
                [107.7747753, 13.370195],
                [107.7745982, 13.3700185],
                [107.7744727, 13.3699134],
                [107.7740904, 13.3696762],
                [107.7739519, 13.3695968],
                [107.7736664, 13.369465],
                [107.7732624, 13.3693724],
                [107.7730991, 13.3693353],
                [107.7729005, 13.3692854],
                [107.7725747, 13.369232],
                [107.7724217, 13.3691902],
                [107.7722584, 13.3691404],
                [107.7720157, 13.3690906],
                [107.7718291, 13.369042],
                [107.7716502, 13.3690265],
                [107.7714575, 13.3690286],
                [107.7712636, 13.3690486],
                [107.7710845, 13.3690963],
                [107.7709353, 13.3691405],
                [107.770798, 13.3691627],
                [107.770702, 13.3691865],
                [107.7705945, 13.3692239],
                [107.7704219, 13.3692917],
                [107.7701736, 13.3693849],
                [107.7700215, 13.3694248],
                [107.7698252, 13.3694546],
                [107.7696604, 13.3694849],
                [107.7693915, 13.369495],
                [107.769224, 13.3694969],
                [107.769031, 13.3694631],
                [107.7687776, 13.3693787],
                [107.7685722, 13.3692908],
                [107.7683073, 13.3691754],
                [107.7680122, 13.3690028],
                [107.7678093, 13.3688182],
                [107.7676525, 13.3685713],
                [107.7674185, 13.3681919],
                [107.7672897, 13.367932],
                [107.7672343, 13.3676728],
                [107.7671217, 13.3672759],
                [107.7669146, 13.3667746],
                [107.7667749, 13.3665512],
                [107.7666531, 13.3663064],
                [107.7664535, 13.3659941],
                [107.7662101, 13.3656554],
                [107.7659618, 13.3653545],
                [107.7655193, 13.3648919],
                [107.7652557, 13.364677],
                [107.7651299, 13.3646189],
                [107.764962, 13.3645754],
                [107.7648352, 13.364561],
                [107.764744, 13.3645717],
                [107.7645706, 13.3646177],
                [107.7644429, 13.3646902],
                [107.7643017, 13.3648213],
                [107.7636878, 13.3653252],
                [107.7634633, 13.365489],
                [107.7631452, 13.3657389],
                [107.7627956, 13.3660688],
                [107.7627132, 13.3661612],
                [107.7625323, 13.366381],
                [107.7623887, 13.3666428],
                [107.7622292, 13.367079],
                [107.7622128, 13.3671853],
                [107.7621829, 13.367441],
                [107.762191, 13.3675929],
                [107.7621998, 13.3677655],
                [107.7622363, 13.3680301],
                [107.7622655, 13.3681858],
                [107.7623001, 13.3683703],
                [107.7623705, 13.3685584],
                [107.7624486, 13.3687093],
                [107.7625316, 13.3689172],
                [107.7626358, 13.3691821],
                [107.7627464, 13.3693802],
                [107.7628632, 13.3696095],
                [107.7629486, 13.3697804],
                [107.7631419, 13.3701409],
                [107.7633547, 13.3705544],
                [107.7634564, 13.3708062],
                [107.763516, 13.3710449],
                [107.7635175, 13.3711792],
                [107.7635193, 13.371331],
                [107.7634328, 13.3715285],
                [107.763344, 13.3716408],
                [107.7632288, 13.3717621],
                [107.7630759, 13.3718276],
                [107.7629585, 13.3718689],
                [107.7628323, 13.3718928],
                [107.7628077, 13.3718958],
                [107.7626404, 13.3719052],
                [107.7624287, 13.3718726],
                [107.7622687, 13.3717904],
                [107.7621015, 13.3716897],
                [107.762017, 13.3716034],
                [107.7618855, 13.3714812],
                [107.7617729, 13.371321],
                [107.7616929, 13.3712297],
                [107.7616061, 13.371149],
                [107.7613818, 13.3709311],
                [107.7612912, 13.3708695],
                [107.7611713, 13.370793],
                [107.7609912, 13.3706467],
                [107.7607766, 13.3704639],
                [107.7606705, 13.3703729],
                [107.760588, 13.3702684],
                [107.7604984, 13.3701794],
                [107.7604105, 13.3700976],
                [107.7602869, 13.3699895],
                [107.7602181, 13.3699199],
                [107.7600705, 13.3698246],
                [107.7599354, 13.3697323],
                [107.7598313, 13.3696641],
                [107.7596977, 13.3696088],
                [107.7595051, 13.3695054],
                [107.7593097, 13.3693758],
                [107.7590282, 13.3692371],
                [107.7589526, 13.3692253],
                [107.7588209, 13.3692267],
                [107.7586836, 13.3692282],
                [107.7585781, 13.3692093],
                [107.7584543, 13.3692107],
                [107.7583272, 13.369172],
                [107.7582066, 13.3691448],
                [107.7579729, 13.369051],
                [107.7578508, 13.3690004],
                [107.757686, 13.3689732],
                [107.7574905, 13.3689489],
                [107.7572945, 13.3689201],
                [107.7571311, 13.3688483],
                [107.7568895, 13.3687683],
                [107.756368, 13.3686198],
                [107.7562432, 13.3685557],
                [107.7559207, 13.3684736],
                [107.7556536, 13.3684333],
                [107.7554511, 13.3684294],
                [107.7552713, 13.3684312],
                [107.7551, 13.3684456],
                [107.7548608, 13.3684768],
                [107.7546472, 13.3685255],
                [107.7545058, 13.3685975],
                [107.7543708, 13.3686635],
                [107.7541873, 13.3687329],
                [107.7540531, 13.3687954],
                [107.7539155, 13.368872],
                [107.7538014, 13.3689414],
                [107.7536888, 13.3689997],
                [107.7535902, 13.3690699],
                [107.7533856, 13.369197],
                [107.7532679, 13.3692757],
                [107.7531157, 13.3693544],
                [107.7529405, 13.3693881],
                [107.7528047, 13.3694124],
                [107.7526765, 13.3694138],
                [107.7525219, 13.3694155],
                [107.7524217, 13.3694166],
                [107.7522481, 13.369402],
                [107.7520599, 13.3693363],
                [107.7519489, 13.3692707],
                [107.7518159, 13.3691471],
                [107.7516929, 13.3689842],
                [107.7516111, 13.3687918],
                [107.7515606, 13.3684968],
                [107.7515502, 13.3682465],
                [107.7515549, 13.3680088],
                [107.7515681, 13.367844],
                [107.7515893, 13.3676989],
                [107.7516397, 13.3674617],
                [107.7516382, 13.367332],
                [107.7516808, 13.3670798],
                [107.7517148, 13.3669086],
                [107.7517465, 13.3667623],
                [107.7517836, 13.3664212],
                [107.7518021, 13.3662463],
                [107.751807, 13.366142],
                [107.7517997, 13.3660425],
                [107.7517775, 13.3658405],
                [107.75175, 13.3657053],
                [107.7516898, 13.3654569],
                [107.7516593, 13.3652378],
                [107.7516056, 13.3650254],
                [107.7515452, 13.3648713],
                [107.7514942, 13.364748],
                [107.7513923, 13.3645887],
                [107.7513049, 13.3644731],
                [107.751138, 13.3642603],
                [107.7509533, 13.3640518],
                [107.7505321, 13.3637184],
                [107.7503837, 13.3635955],
                [107.7500609, 13.3634091],
                [107.7497685, 13.3632171],
                [107.7492589, 13.3630453],
                [107.7489228, 13.362978],
                [107.7484338, 13.3630366],
                [107.7482168, 13.3630744],
                [107.7477291, 13.3632393],
                [107.7468994, 13.3636208],
                [107.7460156, 13.3640206],
                [107.7452747, 13.3642415],
                [107.7449309, 13.3642984],
                [107.7445321, 13.3643028],
                [107.744187, 13.3642533],
                [107.7433418, 13.3640673],
                [107.7426508, 13.3638797],
                [107.7420148, 13.3637624],
                [107.7414523, 13.3637153],
                [107.7410534, 13.3637197],
                [107.7407279, 13.3637942],
                [107.7403313, 13.3639935],
                [107.7399355, 13.3642639],
                [107.7396486, 13.3645508],
                [107.7390596, 13.365373],
                [107.7386136, 13.3660165],
                [107.7383258, 13.3662147],
                [107.7380375, 13.3663774],
                [107.7377299, 13.366434],
                [107.7375323, 13.3665958],
                [107.7372176, 13.3668298],
                [107.7364033, 13.3669627],
                [107.7357508, 13.3669875],
                [107.7351338, 13.3669409],
                [107.7346982, 13.3668925],
                [107.7341529, 13.3667742],
                [107.7334253, 13.3665692],
                [107.7326981, 13.3663997],
                [107.7320978, 13.3662111],
                [107.7317887, 13.3661435],
                [107.7314427, 13.3660053],
                [107.730824, 13.3657991],
                [107.7304058, 13.3656973],
                [107.7300795, 13.3657008],
                [107.7297176, 13.3657756],
                [107.7293199, 13.3658686],
                [107.7290316, 13.3660314],
                [107.7286533, 13.3662483],
                [107.7279861, 13.3665748],
                [107.7274091, 13.3668648],
                [107.7268328, 13.3672079],
                [107.7263651, 13.3675323],
                [107.7260778, 13.3677838],
                [107.7258817, 13.3680874],
                [107.7257398, 13.368355],
                [107.7254902, 13.3687301],
                [107.7252023, 13.3689283],
                [107.7248769, 13.3690206],
                [107.7243876, 13.3690436],
                [107.7237888, 13.3689967],
                [107.7224998, 13.3688511],
                [107.7221735, 13.3688546],
                [107.7216842, 13.3688776],
                [107.7213766, 13.3689341],
                [107.7209427, 13.3690452],
                [107.720509, 13.369174],
                [107.720149, 13.3694085],
                [107.7198716, 13.3697307],
                [107.7197123, 13.3700695],
                [107.7196084, 13.3705139],
                [107.7195766, 13.3709045],
                [107.7194901, 13.371278],
                [107.719298, 13.3719363],
                [107.7191205, 13.3722753],
                [107.7188881, 13.3725792],
                [107.7185834, 13.3728842],
                [107.7181002, 13.3734569],
                [107.7177588, 13.3737266],
                [107.7175426, 13.3738532],
                [107.7171809, 13.3739279],
                [107.7166007, 13.3739342],
                [107.7157838, 13.3738544],
                [107.7153118, 13.3738061],
                [107.7147757, 13.3736877],
                [107.7141021, 13.3734467],
                [107.7132088, 13.3730128],
                [107.7128614, 13.3727505],
                [107.712696, 13.3725572],
                [107.7126028, 13.3723276],
                [107.7125442, 13.3719557],
                [107.7125207, 13.3714771],
                [107.712516, 13.3710514],
                [107.712457, 13.370644],
                [107.7123456, 13.3704146],
                [107.7121436, 13.3701862],
                [107.7118691, 13.3699586],
                [107.7115227, 13.3697849],
                [107.7110676, 13.3696302],
                [107.7106314, 13.3695284],
                [107.7103047, 13.3694965],
                [107.7099789, 13.3695531],
                [107.7097267, 13.3696977],
                [107.7094215, 13.3699671],
                [107.7091891, 13.3702711],
                [107.7088422, 13.3708602],
                [107.7083618, 13.3716812],
                [107.7078436, 13.3723608],
                [107.7074322, 13.3728619],
                [107.706894, 13.3733821],
                [107.7064447, 13.3737416],
                [107.7060675, 13.3740649],
                [107.7056713, 13.3742998],
                [107.7052019, 13.3744821],
                [107.7045688, 13.3746131],
                [107.7042794, 13.3746871],
                [107.7040297, 13.3746738],
                [107.7033206, 13.3744844],
                [107.7016253, 13.3740678],
                [107.6999301, 13.373651],
                [107.6982745, 13.3732212],
                [107.6962881, 13.3725191],
                [107.6946997, 13.3714284],
                [107.6933232, 13.3703381],
                [107.6924237, 13.3692483],
                [107.6915906, 13.3680372],
                [107.690824, 13.3666894],
                [107.6902425, 13.3655749],
                [107.690058, 13.3647718],
                [107.6899798, 13.3637355],
                [107.6899546, 13.3627254],
                [107.6898494, 13.3621295],
                [107.6896114, 13.3617925],
                [107.6892142, 13.3615848],
                [107.6887638, 13.3615842],
                [107.6883132, 13.3617391],
                [107.6873585, 13.3623855],
                [107.6868277, 13.3631101],
                [107.6865357, 13.3635241],
                [107.6861115, 13.363679],
                [107.6857671, 13.3636786],
                [107.6853433, 13.3635486],
                [107.6848932, 13.3633148],
                [107.6845756, 13.3630296],
                [107.6840998, 13.3621482],
                [107.6834648, 13.3614482],
                [107.6825283, 13.3607681],
                [107.6815482, 13.360372],
                [107.6810925, 13.3601463],
                [107.6806179, 13.3598675],
                [107.6799701, 13.3594842],
                [107.6794047, 13.3591885],
                [107.6788401, 13.3589462],
                [107.6778747, 13.3585485],
                [107.6770191, 13.3582383],
                [107.6761631, 13.3578925],
                [107.6756532, 13.3576851],
                [107.6752699, 13.3574585],
                [107.6749223, 13.3571784],
                [107.6746108, 13.3568801],
                [107.6743442, 13.3565459],
                [107.6741405, 13.3561578],
                [107.6739165, 13.3555748],
                [107.6736929, 13.3550273],
                [107.6735251, 13.3546034],
                [107.6734113, 13.3541611],
                [107.6732962, 13.353577],
                [107.6731351, 13.3529401],
                [107.6729839, 13.3523918],
                [107.6728335, 13.3518967],
                [107.6726836, 13.3514549],
                [107.6725699, 13.3510126],
                [107.6723471, 13.350536],
                [107.6721073, 13.350166],
                [107.6718498, 13.3498317],
                [107.6715381, 13.3495157],
                [107.6712096, 13.3493241],
                [107.6709358, 13.3491496],
                [107.6706266, 13.3490642],
                [107.6701909, 13.3490156],
                [107.6697653, 13.3490556],
                [107.6693682, 13.3492193],
                [107.6690985, 13.3494173],
                [107.668613, 13.3497772],
                [107.6680722, 13.3500666],
                [107.66753, 13.350232],
                [107.6670055, 13.3503439],
                [107.6664439, 13.3503854],
                [107.6657552, 13.3504104],
                [107.6650845, 13.3504175],
                [107.6645217, 13.3503525],
                [107.6638587, 13.3502352],
                [107.6631856, 13.3500295],
                [107.6626392, 13.3498046],
                [107.6621466, 13.3495259],
                [107.6617078, 13.3491935],
                [107.6612139, 13.3487907],
                [107.6607197, 13.3483702],
                [107.6602614, 13.3479139],
                [107.6597683, 13.347582],
                [107.6590933, 13.3471988],
                [107.6583997, 13.3467804],
                [107.6580801, 13.3465709],
                [107.6576358, 13.346126],
                [107.6570735, 13.3451187],
                [107.6566232, 13.3443156],
                [107.6563273, 13.3437866],
                [107.6559957, 13.3433111],
                [107.655629, 13.3429248],
                [107.6553086, 13.3426442],
                [107.6549252, 13.3424],
                [107.654634, 13.3422965],
                [107.6543617, 13.342264],
                [107.6540716, 13.342267],
                [107.6534554, 13.3422913],
                [107.6525677, 13.3423537],
                [107.6518795, 13.3424141],
                [107.651191, 13.3424569],
                [107.650683, 13.3424267],
                [107.6503376, 13.3423416],
                [107.6501004, 13.3422021],
                [107.6498987, 13.3419913],
                [107.649733, 13.3417625],
                [107.6496936, 13.3414791],
                [107.6496065, 13.3409656],
                [107.649635, 13.3402558],
                [107.6496835, 13.339723],
                [107.6498023, 13.3389768],
                [107.6499384, 13.3381595],
                [107.6500652, 13.3373036],
                [107.6502196, 13.3365044],
                [107.6503561, 13.3357231],
                [107.6504583, 13.3351193],
                [107.6505601, 13.3344801],
                [107.6506272, 13.3339831],
                [107.6506746, 13.3333445],
                [107.6506515, 13.3328839],
                [107.6503895, 13.3321829],
                [107.6502457, 13.3310446],
                [107.6502474, 13.3299066],
                [107.6502769, 13.3280631],
                [107.6500478, 13.3264274],
                [107.6486627, 13.3259186],
                [107.6481537, 13.3257756],
                [107.6474262, 13.3257746],
                [107.6465527, 13.3260578],
                [107.6441997, 13.3266788],
                [107.641747, 13.3288963],
                [107.6405828, 13.329037],
                [107.6400743, 13.3285384],
                [107.6398567, 13.3280402],
                [107.6397849, 13.327471],
                [107.6399952, 13.325909],
                [107.6397069, 13.3241303],
                [107.6389861, 13.3223818],
                [107.6384773, 13.3220966],
                [107.6380409, 13.3220248],
                [107.6357129, 13.3220215],
                [107.6324396, 13.3217323],
                [107.6311309, 13.3211612],
                [107.6304769, 13.3207335],
                [107.6301871, 13.3199507],
                [107.6301106, 13.3197775],
                [107.6301155, 13.3165055],
                [107.6297523, 13.3161492],
                [107.6291762, 13.3143576],
                [107.627203, 13.314696],
                [107.6256548, 13.315317],
                [107.6234865, 13.3148784],
                [107.6212951, 13.3127713],
                [107.6169136, 13.3122917],
                [107.6156831, 13.3114788],
                [107.6152492, 13.3099122],
                [107.6148342, 13.3074662],
                [107.6128307, 13.3069296],
                [107.609538, 13.3055749],
                [107.6087307, 13.3052001],
                [107.6082528, 13.3040272],
                [107.5570819, 13.1783269],
                [107.5545402, 13.1720776],
                [107.5340081, 13.12159],
                [107.5187476, 13.0840383],
                [107.4956356, 13.0271553],
                [107.4955753, 13.0270193],
                [107.4955, 13.0268928],
                [107.4954046, 13.0267826],
                [107.4952892, 13.0266926],
                [107.4951614, 13.0266274],
                [107.4950269, 13.0265876],
                [107.4949575, 13.0265769],
                [107.4946475, 13.0265543],
                [107.4945291, 13.0265333],
                [107.494359, 13.0264886],
                [107.4942616, 13.0264354],
                [107.4941834, 13.0263576],
                [107.4941309, 13.0262616],
                [107.4941153, 13.0262076],
                [107.4941091, 13.0260959],
                [107.4941362, 13.0259872],
                [107.4943974, 13.0255182],
                [107.4944769, 13.0253245],
                [107.4945279, 13.0251272],
                [107.4945495, 13.02493],
                [107.4945423, 13.0247318],
                [107.4945032, 13.024525],
                [107.4944279, 13.0243153],
                [107.4943191, 13.0241195],
                [107.4942987, 13.0240673],
                [107.4942768, 13.0239578],
                [107.4942813, 13.0238455],
                [107.4943122, 13.0237366],
                [107.4943681, 13.0236376],
                [107.494619, 13.0233195],
                [107.4947693, 13.0231583],
                [107.4948157, 13.0230797],
                [107.4948421, 13.0229956],
                [107.4948451, 13.0229516],
                [107.4948303, 13.0228653],
                [107.4948129, 13.0228249],
                [107.4947277, 13.0227283],
                [107.4946761, 13.022689],
                [107.494559, 13.0226315],
                [107.4944957, 13.0226145],
                [107.4941458, 13.0225905],
                [107.4940374, 13.0225726],
                [107.4937672, 13.0225068],
                [107.4936837, 13.0224956],
                [107.4934865, 13.0224939],
                [107.4933537, 13.022509],
                [107.4932867, 13.0224904],
                [107.4932428, 13.0224524],
                [107.4931553, 13.022263],
                [107.4930901, 13.0220736],
                [107.4930701, 13.0219756],
                [107.493047, 13.0217309],
                [107.4929809, 13.0214755],
                [107.4929549, 13.0212868],
                [107.492954, 13.0211032],
                [107.4929757, 13.0208951],
                [107.4929525, 13.0208344],
                [107.4929032, 13.0207911],
                [107.4928416, 13.020775],
                [107.4927791, 13.0207873],
                [107.4922252, 13.0211573],
                [107.492124, 13.0211906],
                [107.4920709, 13.0211972],
                [107.4919678, 13.0211903],
                [107.4918698, 13.021158],
                [107.4917093, 13.0210385],
                [107.4916375, 13.0209691],
                [107.4915294, 13.0208371],
                [107.4914622, 13.0207123],
                [107.4914383, 13.0206457],
                [107.4914111, 13.0205073],
                [107.4914081, 13.0204353],
                [107.4914243, 13.0202921],
                [107.4914656, 13.0201524],
                [107.4914916, 13.0200082],
                [107.4914915, 13.0198544],
                [107.4914706, 13.0195876],
                [107.4914736, 13.0194538],
                [107.4915046, 13.0191964],
                [107.4915534, 13.0189683],
                [107.4915674, 13.0188658],
                [107.4915746, 13.0186566],
                [107.4915674, 13.0185522],
                [107.4915185, 13.0182383],
                [107.4914794, 13.0179231],
                [107.4914501, 13.0176069],
                [107.4914306, 13.01729],
                [107.4914212, 13.0169827],
                [107.4914302, 13.016368],
                [107.4914486, 13.0160611],
                [107.4914762, 13.0157549],
                [107.4915591, 13.0151455],
                [107.4916143, 13.0148428],
                [107.491719, 13.0143909],
                [107.491784, 13.0141681],
                [107.4919389, 13.0137301],
                [107.4920751, 13.0134124],
                [107.4922366, 13.0131061],
                [107.492698, 13.0123273],
                [107.4927945, 13.01213],
                [107.4928611, 13.0119212],
                [107.4928828, 13.0118139],
                [107.4929022, 13.0115911],
                [107.4928873, 13.0113629],
                [107.4928375, 13.0111395],
                [107.4923707, 13.0099259],
                [107.4923206, 13.0097689],
                [107.4922505, 13.0094642],
                [107.4921925, 13.0089721],
                [107.4921603, 13.0087751],
                [107.4920746, 13.0084113],
                [107.4919655, 13.0080803],
                [107.4916137, 13.0072534],
                [107.4914357, 13.0067542],
                [107.4913606, 13.0065102],
                [107.4910028, 13.0052399],
                [107.4909614, 13.0050778],
                [107.4909296, 13.0049136],
                [107.4909076, 13.0047479],
                [107.4908955, 13.0045813],
                [107.4908976, 13.0042983],
                [107.4909882, 13.001715],
                [107.4910102, 13.0014874],
                [107.4910678, 13.0012657],
                [107.4911594, 13.0010553],
                [107.4912831, 13.000861],
                [107.4914338, 13.0006896],
                [107.4915187, 13.0006127],
                [107.4917052, 13.0004788],
                [107.4919103, 13.0003739],
                [107.4921294, 13.0003005],
                [107.4928859, 13.0001416],
                [107.4932612, 13.0000496],
                [107.4936593, 12.9999422],
                [107.4940546, 12.9998255],
                [107.4948556, 12.9995655],
                [107.4952612, 12.9994223],
                [107.4956632, 12.9992701],
                [107.4960615, 12.9991087],
                [107.4966113, 12.9988683],
                [107.4973382, 12.9984919],
                [107.4975507, 12.99839],
                [107.4978036, 12.9982948],
                [107.4980261, 12.9982286],
                [107.4980991, 12.9981971],
                [107.4981679, 12.9981575],
                [107.498284, 12.9980613],
                [107.4983717, 12.9979483],
                [107.4984057, 12.9978856],
                [107.4984929, 12.9976543],
                [107.4985275, 12.9975355],
                [107.4985875, 12.9972318],
                [107.4986258, 12.9968618],
                [107.498631, 12.99649],
                [107.498607, 12.996154],
                [107.4985849, 12.995987],
                [107.4984457, 12.9952613],
                [107.4984008, 12.9949797],
                [107.4983338, 12.9944352],
                [107.4982936, 12.9939097],
                [107.4982657, 12.9931765],
                [107.4982156, 12.9927088],
                [107.4981321, 12.9922456],
                [107.4980765, 12.9920103],
                [107.4979394, 12.9915462],
                [107.4978581, 12.991318],
                [107.4976708, 12.9908709],
                [107.4973442, 12.990211],
                [107.497188, 12.9898778],
                [107.4968566, 12.9891275],
                [107.4966998, 12.9887487],
                [107.4966403, 12.9885799],
                [107.49659, 12.9884084],
                [107.496549, 12.9882345],
                [107.4965174, 12.9880587],
                [107.496496, 12.9878878],
                [107.4964799, 12.9875438],
                [107.4965506, 12.986134],
                [107.4965954, 12.9857888],
                [107.4966687, 12.9854483],
                [107.4967715, 12.9851104],
                [107.4969042, 12.9847779],
                [107.4970646, 12.9844572],
                [107.4972185, 12.9841909],
                [107.4973448, 12.9839112],
                [107.4973972, 12.9837672],
                [107.4974788, 12.9834776],
                [107.4975313, 12.9831817],
                [107.49757, 12.9828591],
                [107.4976381, 12.9825411],
                [107.4976831, 12.9823846],
                [107.497785, 12.982101],
                [107.4978432, 12.981975],
                [107.4979834, 12.981735],
                [107.4980649, 12.981622],
                [107.4982479, 12.9814132],
                [107.4984551, 12.9812295],
                [107.4986841, 12.9810724],
                [107.4989314, 12.9809444],
                [107.4990812, 12.980851],
                [107.4992149, 12.9807368],
                [107.4992748, 12.9806726],
                [107.4993811, 12.9805281],
                [107.4994633, 12.9803693],
                [107.4997146, 12.9796323],
                [107.4997801, 12.9795064],
                [107.4998538, 12.9793849],
                [107.4999354, 12.9792683],
                [107.5000246, 12.9791571],
                [107.500121, 12.9790518],
                [107.5003227, 12.9788694],
                [107.5005338, 12.97872],
                [107.5006457, 12.9786546],
                [107.5008802, 12.9785434],
                [107.5018331, 12.9781991],
                [107.5023978, 12.9780117],
                [107.5025405, 12.977956],
                [107.5026794, 12.977892],
                [107.502814, 12.9778199],
                [107.5030502, 12.9776664],
                [107.5032503, 12.9775037],
                [107.5033436, 12.977415],
                [107.5035531, 12.9771826],
                [107.5036667, 12.9770376],
                [107.5037728, 12.9768872],
                [107.503871, 12.9767319],
                [107.5039612, 12.976572],
                [107.504043, 12.976408],
                [107.5041164, 12.9762401],
                [107.504181, 12.9760689],
                [107.5042706, 12.9757711],
                [107.5043116, 12.9755184],
                [107.5043185, 12.9753906],
                [107.5043053, 12.9751352],
                [107.5042851, 12.9750087],
                [107.5042266, 12.9747861],
                [107.5041403, 12.9745722],
                [107.504002, 12.9743388],
                [107.503836, 12.9741234],
                [107.5036401, 12.9739249],
                [107.5035307, 12.9738329],
                [107.5032952, 12.97367],
                [107.5030406, 12.9735372],
                [107.5027666, 12.9734284],
                [107.5022112, 12.9732297],
                [107.5019301, 12.9731399],
                [107.5014742, 12.9730128],
                [107.5012431, 12.9729609],
                [107.5007761, 12.9728806],
                [107.5003321, 12.972834],
                [107.5001227, 12.9728253],
                [107.4999132, 12.9728262],
                [107.4997039, 12.9728367],
                [107.4993558, 12.9728757],
                [107.4990119, 12.9729412],
                [107.4986986, 12.9730466],
                [107.4983086, 12.9731517],
                [107.4980751, 12.9732014],
                [107.4977426, 12.9732557],
                [107.4975486, 12.9733033],
                [107.4973597, 12.9733777],
                [107.4972643, 12.9734373],
                [107.4970641, 12.9735407],
                [107.4968431, 12.9736255],
                [107.4967239, 12.9736598],
                [107.4964801, 12.9737072],
                [107.4961724, 12.9737361],
                [107.4959879, 12.9737457],
                [107.4956184, 12.9737455],
                [107.4952116, 12.9737156],
                [107.4949905, 12.973686],
                [107.4947709, 12.9736472],
                [107.4945532, 12.9735993],
                [107.4943379, 12.9735422],
                [107.4941252, 12.9734762],
                [107.4939157, 12.9734013],
                [107.492934, 12.9729988],
                [107.4917353, 12.9724833],
                [107.4911316, 12.9722355],
                [107.4900341, 12.9718049],
                [107.4893, 12.9715297],
                [107.4889454, 12.9713716],
                [107.4886056, 12.9711851],
                [107.4882831, 12.9709716],
                [107.48798, 12.9707326],
                [107.4877074, 12.9704788],
                [107.4874568, 12.9702044],
                [107.4872297, 12.9699111],
                [107.4871255, 12.969758],
                [107.4869661, 12.9695007],
                [107.4869125, 12.9693961],
                [107.4868673, 12.9692879],
                [107.4868308, 12.9691766],
                [107.4868032, 12.9690629],
                [107.4867846, 12.9689475],
                [107.4867752, 12.968831],
                [107.4867751, 12.9687141],
                [107.4867842, 12.9685976],
                [107.4868025, 12.9684821],
                [107.486831, 12.9683642],
                [107.4868691, 12.9682488],
                [107.4869166, 12.9681367],
                [107.4869731, 12.9680287],
                [107.4870382, 12.9679255],
                [107.4871117, 12.9678276],
                [107.4871928, 12.9677358],
                [107.4872813, 12.9676506],
                [107.4873765, 12.9675725],
                [107.4879025, 12.9671889],
                [107.4881573, 12.9669867],
                [107.4886226, 12.9665871],
                [107.4890406, 12.9661905],
                [107.4894384, 12.9657747],
                [107.4897515, 12.9654223],
                [107.4898462, 12.9652656],
                [107.4899105, 12.9650949],
                [107.4899305, 12.965006],
                [107.4899457, 12.9648248],
                [107.4899407, 12.9647339],
                [107.4899061, 12.9645571],
                [107.4898399, 12.964389],
                [107.489744, 12.9642351],
                [107.4896217, 12.9641002],
                [107.4876876, 12.9625069],
                [107.4874168, 12.9622983],
                [107.4871686, 12.9620646],
                [107.4870537, 12.961939],
                [107.4868439, 12.9616719],
                [107.4866622, 12.9613859],
                [107.4865139, 12.9610912],
                [107.486398, 12.9607916],
                [107.4863511, 12.9606382],
                [107.4862799, 12.9603257],
                [107.4862392, 12.9600081],
                [107.4862185, 12.9594271],
                [107.4861653, 12.9588482],
                [107.4861098, 12.9584513],
                [107.4860541, 12.9582385],
                [107.4859761, 12.9580476],
                [107.4859281, 12.9579562],
                [107.4859441, 12.9577324],
                [107.4859624, 12.9576062],
                [107.4860213, 12.9573579],
                [107.4861094, 12.9571179],
                [107.4862332, 12.9568767],
                [107.4863105, 12.9567559],
                [107.4863958, 12.9566403],
                [107.4864885, 12.9565304],
                [107.4865884, 12.9564265],
                [107.486695, 12.9563292],
                [107.4869229, 12.9561463],
                [107.4870141, 12.9560312],
                [107.4870492, 12.955967],
                [107.4870952, 12.9558355],
                [107.4871115, 12.9556975],
                [107.4870975, 12.9555592],
                [107.4870743, 12.9554395],
                [107.4870416, 12.955322],
                [107.4869995, 12.9552073],
                [107.4869483, 12.9550962],
                [107.4868883, 12.9549894],
                [107.4868205, 12.9548882],
                [107.4867448, 12.9547924],
                [107.4866618, 12.9547027],
                [107.4865718, 12.9546196],
                [107.4864754, 12.9545435],
                [107.4863733, 12.9544749],
                [107.486266, 12.9544143],
                [107.4860253, 12.9543053],
                [107.4859001, 12.9542619],
                [107.4855889, 12.954176],
                [107.4855016, 12.9541384],
                [107.4853408, 12.9540402],
                [107.4852027, 12.9539147],
                [107.4850912, 12.953766],
                [107.4850454, 12.9536495],
                [107.4850336, 12.953588],
                [107.4850336, 12.9534602],
                [107.485067, 12.9533336],
                [107.4850958, 12.9532744],
                [107.4851751, 12.953169],
                [107.4852243, 12.9531245],
                [107.4854353, 12.9529839],
                [107.485586, 12.9528737],
                [107.4857307, 12.9527562],
                [107.485994, 12.9525073],
                [107.4862245, 12.9522417],
                [107.4864276, 12.9519555],
                [107.4865182, 12.9518056],
                [107.4865954, 12.9516594],
                [107.4866641, 12.9515093],
                [107.4867243, 12.9513557],
                [107.4868102, 12.9510966],
                [107.4868535, 12.9509973],
                [107.4869054, 12.950902],
                [107.4869654, 12.9508113],
                [107.4870332, 12.950726],
                [107.4871858, 12.9505769],
                [107.4873618, 12.9504545],
                [107.4875239, 12.9503651],
                [107.4876421, 12.9502657],
                [107.4876918, 12.9502069],
                [107.4877346, 12.9501431],
                [107.4877942, 12.9500112],
                [107.4878239, 12.9498701],
                [107.487827, 12.9497494],
                [107.4878067, 12.9496544],
                [107.4877869, 12.9496098],
                [107.4877239, 12.9495247],
                [107.4876835, 12.9494898],
                [107.4875892, 12.949439],
                [107.4871438, 12.9493134],
                [107.4867435, 12.9492282],
                [107.486503, 12.9491898],
                [107.4862611, 12.9491606],
                [107.4860183, 12.9491407],
                [107.4854367, 12.9491244],
                [107.48525, 12.9490954],
                [107.4850698, 12.94904],
                [107.4848938, 12.948956],
                [107.4848112, 12.9489045],
                [107.4846594, 12.9487838],
                [107.4845021, 12.9486152],
                [107.4843577, 12.9484044],
                [107.4842642, 12.9482284],
                [107.4842305, 12.9481154],
                [107.48422, 12.9479862],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [102.1438643, 22.4004841],
                [102.1450592, 22.3955389],
                [102.146667, 22.392222],
                [102.1481821, 22.3904474],
                [102.1495052, 22.3894006],
                [102.151111, 22.388333],
                [102.1513282, 22.3875212],
                [102.1517918, 22.3870284],
                [102.1528087, 22.3869962],
                [102.1537951, 22.3871897],
                [102.1553081, 22.3859183],
                [102.1571045, 22.384795],
                [102.1575598, 22.385171],
                [102.1578879, 22.3852227],
                [102.1586182, 22.3848699],
                [102.1588157, 22.3838646],
                [102.1591508, 22.3832139],
                [102.1594295, 22.3823478],
                [102.1600435, 22.3820158],
                [102.1603874, 22.3817368],
                [102.1604312, 22.3811603],
                [102.1607604, 22.3806178],
                [102.1613859, 22.3799581],
                [102.1617648, 22.3791592],
                [102.1623063, 22.3781384],
                [102.1624786, 22.3776501],
                [102.162614, 22.3772892],
                [102.163004, 22.3769565],
                [102.1635328, 22.3769641],
                [102.1640265, 22.3768675],
                [102.1646018, 22.3765641],
                [102.1650127, 22.3763744],
                [102.165439, 22.3762946],
                [102.1658116, 22.3762743],
                [102.1661972, 22.3761915],
                [102.1666519, 22.3760716],
                [102.1673224, 22.3758477],
                [102.1677967, 22.3756109],
                [102.1679904, 22.3754432],
                [102.1680444, 22.3752304],
                [102.168034, 22.3750572],
                [102.1678962, 22.3744905],
                [102.1676636, 22.3736065],
                [102.1676003, 22.3730721],
                [102.1677622, 22.3727248],
                [102.1681264, 22.3724442],
                [102.1689348, 22.3724312],
                [102.1693682, 22.3723239],
                [102.1697769, 22.3721253],
                [102.1706437, 22.371335],
                [102.1705677, 22.3711403],
                [102.1698378, 22.370573],
                [102.1689832, 22.3698464],
                [102.1676376, 22.3679962],
                [102.1669953, 22.3665308],
                [102.1656767, 22.3645239],
                [102.1641933, 22.3626385],
                [102.1637258, 22.3620892],
                [102.1633619, 22.3612926],
                [102.1633298, 22.3608409],
                [102.1633746, 22.3604556],
                [102.1635556, 22.3598939],
                [102.1638534, 22.3595376],
                [102.1643536, 22.3587556],
                [102.1646205, 22.3581389],
                [102.1646716, 22.3568067],
                [102.1647255, 22.355339],
                [102.164884, 22.3548664],
                [102.1650302, 22.3546438],
                [102.1654287, 22.3541085],
                [102.1662225, 22.3531735],
                [102.1666939, 22.352018],
                [102.1670841, 22.3506919],
                [102.1675115, 22.3499084],
                [102.1677386, 22.3494606],
                [102.1680091, 22.3492619],
                [102.1683032, 22.3490864],
                [102.168548, 22.3489551],
                [102.1688162, 22.3488694],
                [102.1690363, 22.3487602],
                [102.1693324, 22.3484942],
                [102.1697274, 22.348117],
                [102.1703002, 22.3473362],
                [102.1708492, 22.3465325],
                [102.1736404, 22.3432258],
                [102.1769832, 22.3395786],
                [102.1782867, 22.3385845],
                [102.1788229, 22.3384131],
                [102.1790421, 22.3383491],
                [102.1793335, 22.338309],
                [102.1795563, 22.3383304],
                [102.1800081, 22.3384789],
                [102.1803676, 22.338666],
                [102.1807969, 22.3389898],
                [102.1812031, 22.339268],
                [102.182245, 22.3404385],
                [102.1829622, 22.3409028],
                [102.1835148, 22.3411158],
                [102.1840936, 22.3412388],
                [102.1848911, 22.3413205],
                [102.1855946, 22.341265],
                [102.1869442, 22.3409834],
                [102.1898403, 22.3402655],
                [102.1922802, 22.3393588],
                [102.1945408, 22.3377259],
                [102.1950331, 22.3373277],
                [102.1955995, 22.336791],
                [102.1959541, 22.335982],
                [102.1956466, 22.3358142],
                [102.1952879, 22.3356253],
                [102.1950277, 22.3354806],
                [102.1946578, 22.3353281],
                [102.1942078, 22.3350087],
                [102.1924559, 22.3336113],
                [102.1909634, 22.3319587],
                [102.1897821, 22.3304921],
                [102.1892571, 22.3295],
                [102.1891954, 22.3289567],
                [102.1889089, 22.3275508],
                [102.1887516, 22.326938],
                [102.188613, 22.3265967],
                [102.1884487, 22.3263453],
                [102.1878737, 22.3260416],
                [102.1869149, 22.3255504],
                [102.1857631, 22.3250333],
                [102.1843915, 22.3246027],
                [102.1831634, 22.324265],
                [102.1818667, 22.3237228],
                [102.1813908, 22.3233078],
                [102.1809986, 22.3227583],
                [102.1804677, 22.3216501],
                [102.1791627, 22.3191208],
                [102.1779992, 22.3167743],
                [102.1773794, 22.3150924],
                [102.1773527, 22.3140079],
                [102.1773439, 22.3120429],
                [102.1772114, 22.307795],
                [102.1772052, 22.3056946],
                [102.1773404, 22.3049967],
                [102.1774659, 22.3047731],
                [102.177638, 22.3046405],
                [102.1777395, 22.3045694],
                [102.1778719, 22.3046245],
                [102.1783143, 22.3047202],
                [102.1792535, 22.3049623],
                [102.1808026, 22.3049894],
                [102.1818204, 22.3049393],
                [102.1822354, 22.3047658],
                [102.1829938, 22.3043724],
                [102.1836313, 22.303977],
                [102.1843672, 22.3034929],
                [102.1852871, 22.3031813],
                [102.1859954, 22.3030907],
                [102.1865263, 22.303038],
                [102.1870825, 22.3028212],
                [102.1876175, 22.3025628],
                [102.1879511, 22.302445],
                [102.1882596, 22.302471],
                [102.1889416, 22.3025858],
                [102.1894051, 22.3025938],
                [102.1897598, 22.3025176],
                [102.1899838, 22.3023566],
                [102.1901449, 22.30203],
                [102.1901515, 22.3017007],
                [102.1905999, 22.3013583],
                [102.191313, 22.3010206],
                [102.1917397, 22.3006573],
                [102.192037, 22.3001476],
                [102.1924665, 22.2996403],
                [102.1930468, 22.2993208],
                [102.1934911, 22.2991843],
                [102.1945778, 22.2989354],
                [102.1956411, 22.2987479],
                [102.1963286, 22.2985951],
                [102.1984971, 22.2972321],
                [102.1994787, 22.2967136],
                [102.2001678, 22.2964784],
                [102.2008346, 22.2962428],
                [102.2011915, 22.2960637],
                [102.2015303, 22.2956783],
                [102.2016912, 22.2953516],
                [102.201807, 22.2950858],
                [102.2019239, 22.2947583],
                [102.2019971, 22.2944095],
                [102.2020477, 22.2940809],
                [102.2022516, 22.2938167],
                [102.2025435, 22.2935746],
                [102.2033486, 22.293053],
                [102.2041086, 22.2925719],
                [102.2046715, 22.2920256],
                [102.2050748, 22.2917237],
                [102.2054733, 22.2916688],
                [102.2059379, 22.291615],
                [102.2077969, 22.2913793],
                [102.208239, 22.2913457],
                [102.2085925, 22.2913311],
                [102.2089685, 22.2912965],
                [102.2095872, 22.2912659],
                [102.2102287, 22.2911946],
                [102.2106753, 22.291092],
                [102.2110938, 22.2909829],
                [102.2112048, 22.290919],
                [102.2114065, 22.290803],
                [102.2114747, 22.2907012],
                [102.2115218, 22.2905578],
                [102.2115255, 22.2903726],
                [102.2115058, 22.2902487],
                [102.2114862, 22.2901247],
                [102.2114011, 22.2899585],
                [102.2112284, 22.2897702],
                [102.2110768, 22.2896235],
                [102.2109042, 22.2894146],
                [102.2107322, 22.2891851],
                [102.2107147, 22.2889583],
                [102.2107645, 22.2886708],
                [102.2109283, 22.2882],
                [102.2109754, 22.2880566],
                [102.2112443, 22.2878554],
                [102.2116893, 22.2876775],
                [102.2121346, 22.2874793],
                [102.2129781, 22.2872467],
                [102.2131984, 22.287271],
                [102.2135722, 22.2873393],
                [102.2136692, 22.2873917],
                [102.2137688, 22.2874456],
                [102.2139874, 22.2875523],
                [102.2142081, 22.2875561],
                [102.2145841, 22.2875214],
                [102.2148509, 22.287423],
                [102.2150308, 22.2872614],
                [102.2152558, 22.2870387],
                [102.2155922, 22.2867768],
                [102.2159481, 22.2866387],
                [102.2163695, 22.286543],
                [102.2169885, 22.2864918],
                [102.2180256, 22.2865096],
                [102.2198318, 22.2867054],
                [102.2205383, 22.2866968],
                [102.2212903, 22.2866274],
                [102.222309, 22.2864595],
                [102.2229288, 22.2863671],
                [102.2232378, 22.2863724],
                [102.2234801, 22.2863971],
                [102.2236999, 22.2864421],
                [102.2239623, 22.2865702],
                [102.224203, 22.2866773],
                [102.224534, 22.2866829],
                [102.2247551, 22.286666],
                [102.2251101, 22.2865692],
                [102.2253849, 22.2860796],
                [102.2255515, 22.2854646],
                [102.2257373, 22.2849942],
                [102.2261206, 22.2845889],
                [102.2262995, 22.2844684],
                [102.226588, 22.2843909],
                [102.2268961, 22.2844374],
                [102.2275312, 22.2846954],
                [102.2285193, 22.2849594],
                [102.2293583, 22.284953],
                [102.2303761, 22.2848263],
                [102.2311529, 22.284613],
                [102.2317303, 22.2844376],
                [102.2322636, 22.2842613],
                [102.2333374, 22.2835382],
                [102.2341471, 22.2827695],
                [102.2343746, 22.2824233],
                [102.2343807, 22.2821145],
                [102.2343019, 22.2816394],
                [102.2339943, 22.2804398],
                [102.2337661, 22.2796945],
                [102.2336635, 22.2793015],
                [102.2336703, 22.2778394],
                [102.2336592, 22.2772832],
                [102.2335761, 22.2770141],
                [102.233449, 22.2767442],
                [102.2332124, 22.2764313],
                [102.2330162, 22.2763043],
                [102.232427, 22.2759648],
                [102.2311757, 22.2756139],
                [102.2303238, 22.2751668],
                [102.2303514, 22.2748791],
                [102.2305324, 22.2746557],
                [102.2313144, 22.2741748],
                [102.2316499, 22.273954],
                [102.2317446, 22.2736261],
                [102.2316248, 22.2729856],
                [102.2314044, 22.2718491],
                [102.2314337, 22.271479],
                [102.2317073, 22.2710306],
                [102.2319337, 22.2707462],
                [102.2321701, 22.2705849],
                [102.2336117, 22.2696009],
                [102.2339908, 22.2694015],
                [102.234061, 22.2691967],
                [102.2340418, 22.2690522],
                [102.2339581, 22.2688243],
                [102.2338113, 22.2684304],
                [102.2335154, 22.2677664],
                [102.232917, 22.2667677],
                [102.232456, 22.2655702],
                [102.2323397, 22.2646984],
                [102.2325893, 22.2643526],
                [102.2329227, 22.2642348],
                [102.2333652, 22.2641805],
                [102.2337644, 22.2640843],
                [102.2341196, 22.2639669],
                [102.2345201, 22.2638089],
                [102.2350328, 22.2635499],
                [102.2356567, 22.2632516],
                [102.2365518, 22.2626285],
                [102.2369819, 22.2620797],
                [102.2371465, 22.2615677],
                [102.2372187, 22.26126],
                [102.2371839, 22.2607858],
                [102.2371285, 22.2602288],
                [102.2370505, 22.2597126],
                [102.236969, 22.2593611],
                [102.2369743, 22.2590934],
                [102.2370005, 22.258888],
                [102.2371568, 22.2587877],
                [102.2375545, 22.2587738],
                [102.2379504, 22.2588424],
                [102.2381697, 22.2589078],
                [102.2383888, 22.258994],
                [102.2385857, 22.2590797],
                [102.2388485, 22.2591871],
                [102.2391574, 22.2591924],
                [102.2394225, 22.2591763],
                [102.2397117, 22.2590577],
                [102.2398903, 22.2589578],
                [102.2399383, 22.2587526],
                [102.2399955, 22.2584955],
                [102.2400677, 22.2577451],
                [102.2402869, 22.2568363],
                [102.2407026, 22.2561724],
                [102.2416412, 22.2552489],
                [102.2422869, 22.2545889],
                [102.2424369, 22.2542693],
                [102.2424437, 22.2539205],
                [102.2423356, 22.2535698],
                [102.2420268, 22.2531887],
                [102.2416346, 22.2526453],
                [102.2412149, 22.2520476],
                [102.2411697, 22.2519931],
                [102.2400629, 22.2506593],
                [102.238404, 22.2487253],
                [102.2381817, 22.248319],
                [102.238139, 22.2475668],
                [102.2383044, 22.2464691],
                [102.2389355, 22.2450842],
                [102.239184, 22.244149],
                [102.2395637, 22.242384],
                [102.2399323, 22.2411824],
                [102.2399785, 22.2402974],
                [102.2402236, 22.2395233],
                [102.2405018, 22.2389974],
                [102.240999, 22.2383822],
                [102.2413876, 22.2381038],
                [102.2422751, 22.2377984],
                [102.2446273, 22.2370546],
                [102.2455146, 22.2367491],
                [102.2463623, 22.2365141],
                [102.2470525, 22.2364902],
                [102.2481622, 22.2365802],
                [102.2487361, 22.2366256],
                [102.2492327, 22.2367052],
                [102.2496533, 22.2367479],
                [102.2502663, 22.2367583],
                [102.2513843, 22.236421],
                [102.2533096, 22.2359548],
                [102.2544037, 22.2358551],
                [102.2555536, 22.2358389],
                [102.2560892, 22.2358836],
                [102.256436, 22.2357825],
                [102.2567459, 22.2356096],
                [102.2570184, 22.2354003],
                [102.2572126, 22.235261],
                [102.2573296, 22.235156],
                [102.2574467, 22.235051],
                [102.2577191, 22.2348417],
                [102.257834, 22.2348436],
                [102.2580255, 22.2348468],
                [102.258252, 22.2350289],
                [102.2586281, 22.2353918],
                [102.2590035, 22.2357902],
                [102.2593441, 22.2360098],
                [102.2594967, 22.236048],
                [102.2596499, 22.2360506],
                [102.2598073, 22.2358394],
                [102.2607572, 22.2342869],
                [102.2611124, 22.2337583],
                [102.2613451, 22.2336196],
                [102.2615366, 22.2336228],
                [102.2619754, 22.2337193],
                [102.2628071, 22.2343036],
                [102.2638256, 22.2351408],
                [102.2645075, 22.2355443],
                [102.2652277, 22.2359486],
                [102.2674953, 22.2375908],
                [102.2679112, 22.2378829],
                [102.2681793, 22.2378875],
                [102.2698377, 22.237345],
                [102.2712648, 22.2368699],
                [102.2720386, 22.2364907],
                [102.2725255, 22.2360891],
                [102.2729558, 22.2356329],
                [102.2734238, 22.2352129],
                [102.2735422, 22.2350367],
                [102.2735847, 22.2348235],
                [102.2734742, 22.2343364],
                [102.2732773, 22.2337212],
                [102.2730417, 22.2331747],
                [102.2728251, 22.2324937],
                [102.2726458, 22.2318491],
                [102.2725034, 22.2314555],
                [102.2725624, 22.2303871],
                [102.2726185, 22.2296059],
                [102.2726635, 22.2289619],
                [102.2725582, 22.228461],
                [102.2723388, 22.2279227],
                [102.2717095, 22.2267714],
                [102.271343, 22.2259097],
                [102.2713131, 22.2254814],
                [102.2713261, 22.2248043],
                [102.2715198, 22.2247007],
                [102.2724014, 22.2246798],
                [102.2736684, 22.2245585],
                [102.274325, 22.2242843],
                [102.2751446, 22.2235138],
                [102.2757686, 22.2229539],
                [102.2768006, 22.2220978],
                [102.2773889, 22.2213948],
                [102.2775498, 22.2211992],
                [102.2779383, 22.2207267],
                [102.278097, 22.2204442],
                [102.2783266, 22.220026],
                [102.2783335, 22.2196697],
                [102.2782627, 22.2191786],
                [102.2775261, 22.2182601],
                [102.276999, 22.2172771],
                [102.2768146, 22.2164123],
                [102.2768042, 22.2154125],
                [102.2768531, 22.2144167],
                [102.2769323, 22.2132952],
                [102.2769153, 22.2121899],
                [102.277042, 22.2115859],
                [102.277278, 22.211269],
                [102.2778212, 22.2109217],
                [102.2788656, 22.2104045],
                [102.2790988, 22.2102301],
                [102.2792582, 22.209912],
                [102.2793458, 22.209343],
                [102.2790915, 22.2086258],
                [102.2788344, 22.2080512],
                [102.2786114, 22.2076909],
                [102.2784283, 22.2072601],
                [102.2783598, 22.2068312],
                [102.2783674, 22.2064393],
                [102.2784132, 22.2060478],
                [102.2785343, 22.2057291],
                [102.2786945, 22.2053753],
                [102.2788149, 22.2050921],
                [102.2789346, 22.2048446],
                [102.2790911, 22.2046689],
                [102.2791692, 22.2045989],
                [102.2792841, 22.2046009],
                [102.2795501, 22.2047123],
                [102.280868, 22.2059106],
                [102.2821127, 22.2069296],
                [102.2832871, 22.2076265],
                [102.2837802, 22.2078843],
                [102.2844075, 22.2071508],
                [102.2849944, 22.2069892],
                [102.285513, 22.2071836],
                [102.2859118, 22.2076169],
                [102.2861177, 22.2068718],
                [102.2861636, 22.2064803],
                [102.2863209, 22.2062691],
                [102.2866301, 22.2061317],
                [102.2876287, 22.2060057],
                [102.2900773, 22.206189],
                [102.2907284, 22.2062],
                [102.2911878, 22.2060784],
                [102.291745, 22.2058425],
                [102.2925939, 22.2055358],
                [102.2931585, 22.2053492],
                [102.2936995, 22.2051087],
                [102.2944677, 22.2050145],
                [102.2951181, 22.205061],
                [102.2956132, 22.2052119],
                [102.296261, 22.2054009],
                [102.2967179, 22.205551],
                [102.2969477, 22.2055549],
                [102.2973697, 22.2055262],
                [102.2979922, 22.2050375],
                [102.2984655, 22.2043323],
                [102.2990208, 22.2033435],
                [102.2993745, 22.2028859],
                [102.2995937, 22.2027474],
                [102.3000766, 22.2023982],
                [102.3004987, 22.2021916],
                [102.3021695, 22.2014046],
                [102.3028515, 22.2013751],
                [102.3035967, 22.2014766],
                [102.3059215, 22.202121],
                [102.3071377, 22.2026403],
                [102.307817, 22.2031863],
                [102.3085381, 22.2041811],
                [102.3090058, 22.2051309],
                [102.3091985, 22.2060787],
                [102.3090611, 22.2072529],
                [102.3088506, 22.2082476],
                [102.3088061, 22.2085676],
                [102.3089956, 22.2086777],
                [102.3099532, 22.2086935],
                [102.3117214, 22.208402],
                [102.3125311, 22.2081302],
                [102.3137402, 22.2070093],
                [102.3151421, 22.2058204],
                [102.3156093, 22.205436],
                [102.3162296, 22.2050542],
                [102.3171167, 22.2047479],
                [102.3178109, 22.2045099],
                [102.3187356, 22.20424],
                [102.3197389, 22.2038643],
                [102.3210165, 22.2031724],
                [102.321581, 22.2027004],
                [102.3218968, 22.2022065],
                [102.3222472, 22.2014792],
                [102.3225161, 22.2002552],
                [102.3227757, 22.1995311],
                [102.3234985, 22.1985611],
                [102.324518, 22.1973301],
                [102.3259965, 22.1961424],
                [102.3272173, 22.1953961],
                [102.3280279, 22.1950886],
                [102.3293757, 22.1947185],
                [102.3301062, 22.194588],
                [102.3303414, 22.1943066],
                [102.3314455, 22.1926493],
                [102.3317653, 22.1919415],
                [102.3319508, 22.1902334],
                [102.3321149, 22.1886497],
                [102.3322338, 22.1884378],
                [102.3330469, 22.1879877],
                [102.3339352, 22.1876101],
                [102.3339628, 22.1875823],
                [102.3344871, 22.1870539],
                [102.3346657, 22.1865427],
                [102.3346267, 22.1853565],
                [102.3346541, 22.1840214],
                [102.3346488, 22.1832906],
                [102.334595, 22.1820775],
                [102.3344552, 22.1813622],
                [102.3343492, 22.1808971],
                [102.3343149, 22.1806827],
                [102.334364, 22.1801131],
                [102.3345722, 22.1792253],
                [102.3348478, 22.1768057],
                [102.3344872, 22.1740273],
                [102.3345522, 22.1731825],
                [102.3343231, 22.1711111],
                [102.3343528, 22.1695431],
                [102.3344938, 22.1681551],
                [102.33461, 22.1670697],
                [102.3347337, 22.1666083],
                [102.3348935, 22.1662544],
                [102.3351273, 22.1660443],
                [102.335607, 22.1659613],
                [102.3364482, 22.16642],
                [102.3367783, 22.166566],
                [102.3378215, 22.1666259],
                [102.3387208, 22.1664239],
                [102.3398428, 22.1658362],
                [102.340886, 22.1653541],
                [102.3420415, 22.1650165],
                [102.3429659, 22.1647463],
                [102.3438359, 22.164315],
                [102.3445359, 22.163756],
                [102.3452708, 22.1633758],
                [102.3462328, 22.1631419],
                [102.3474979, 22.1630912],
                [102.3489852, 22.1634363],
                [102.3511142, 22.1642909],
                [102.3533978, 22.1650767],
                [102.3552607, 22.1658199],
                [102.3565825, 22.1668395],
                [102.3571111, 22.1672402],
                [102.3576077, 22.1673196],
                [102.3583742, 22.1672964],
                [102.3589721, 22.1670743],
                [102.3595846, 22.1666659],
                [102.3609317, 22.1652736],
                [102.3629796, 22.1637525],
                [102.3637145, 22.1634761],
                [102.3640994, 22.1634252],
                [102.364731, 22.1634925],
                [102.3653438, 22.1635025],
                [102.3656694, 22.163297],
                [102.3660219, 22.1628748],
                [102.3666177, 22.1617437],
                [102.3670476, 22.1601591],
                [102.3673323, 22.1595825],
                [102.3679951, 22.1589515],
                [102.3708497, 22.1576589],
                [102.3729082, 22.1561236],
                [102.3736522, 22.1552443],
                [102.3745093, 22.1544738],
                [102.3750908, 22.154091],
                [102.3755537, 22.1539202],
                [102.3759386, 22.1538194],
                [102.3766291, 22.1537593],
                [102.3769744, 22.1537292],
                [102.3776643, 22.1537046],
                [102.3779336, 22.1536377],
                [102.3782427, 22.1535001],
                [102.3787189, 22.1530327],
                [102.379289, 22.1522398],
                [102.3796341, 22.1517934],
                [102.3802208, 22.1511255],
                [102.3805318, 22.150881],
                [102.3813452, 22.1503949],
                [102.3826945, 22.1499175],
                [102.3832742, 22.1496416],
                [102.3835857, 22.1493614],
                [102.3837297, 22.148822],
                [102.38371, 22.1482218],
                [102.3837555, 22.1474227],
                [102.3841213, 22.1462863],
                [102.3842375, 22.1441492],
                [102.3843173, 22.1419045],
                [102.3846182, 22.1401268],
                [102.384834, 22.1388113],
                [102.3849486, 22.1377971],
                [102.3848989, 22.1363346],
                [102.3846857, 22.1354399],
                [102.3840508, 22.1314548],
                [102.3840271, 22.1306701],
                [102.3840731, 22.1302431],
                [102.384159, 22.1297454],
                [102.3842939, 22.129549],
                [102.3844342, 22.1293575],
                [102.3848612, 22.1290436],
                [102.3857505, 22.1285945],
                [102.3870236, 22.1280801],
                [102.388415, 22.1273893],
                [102.3891846, 22.1271878],
                [102.3901463, 22.1269535],
                [102.3906836, 22.1268909],
                [102.3913741, 22.1268307],
                [102.3925251, 22.1267065],
                [102.3937524, 22.1266191],
                [102.3947515, 22.1264212],
                [102.3952908, 22.1262515],
                [102.3957911, 22.1261169],
                [102.3968832, 22.1260809],
                [102.3987974, 22.1261114],
                [102.4002166, 22.1259914],
                [102.4013327, 22.1256884],
                [102.4020237, 22.1255924],
                [102.402408, 22.1255272],
                [102.4027135, 22.1255677],
                [102.4033572, 22.1259701],
                [102.4062011, 22.1275127],
                [102.4069803, 22.1278281],
                [102.4076758, 22.1280574],
                [102.4079674, 22.1279282],
                [102.4088415, 22.1265742],
                [102.4103348, 22.1244945],
                [102.4111569, 22.1235094],
                [102.4115482, 22.1230521],
                [102.4122056, 22.1227061],
                [102.4127065, 22.1225358],
                [102.413554, 22.122264],
                [102.4148238, 22.1219276],
                [102.4151897, 22.1218087],
                [102.4160483, 22.1209309],
                [102.4167517, 22.1201578],
                [102.4174142, 22.1195265],
                [102.4179567, 22.1191787],
                [102.4186504, 22.1189401],
                [102.4194582, 22.1187389],
                [102.4196514, 22.118635],
                [102.4199635, 22.1183191],
                [102.4201602, 22.1180371],
                [102.420205, 22.1176813],
                [102.4202879, 22.117326],
                [102.4204852, 22.1170084],
                [102.4208356, 22.1166929],
                [102.4214546, 22.1163463],
                [102.4231084, 22.1159446],
                [102.4253329, 22.1157659],
                [102.4269803, 22.1157204],
                [102.4292714, 22.1160774],
                [102.4310096, 22.1163009],
                [102.4322295, 22.1166053],
                [102.4330666, 22.1169036],
                [102.4338868, 22.1169997],
                [102.4343607, 22.1169327],
                [102.4347546, 22.1166512],
                [102.4348883, 22.1162834],
                [102.434845, 22.115969],
                [102.4346217, 22.115609],
                [102.4343595, 22.115284],
                [102.4340974, 22.114959],
                [102.4339488, 22.1147071],
                [102.4338423, 22.114242],
                [102.4338106, 22.113885],
                [102.4338195, 22.1133861],
                [102.4339044, 22.1129239],
                [102.4341043, 22.1124636],
                [102.435736, 22.1101185],
                [102.4373803, 22.1081121],
                [102.438095, 22.1066973],
                [102.4386601, 22.1051018],
                [102.4395799, 22.1035627],
                [102.44063, 22.1020312],
                [102.4408109, 22.1015526],
                [102.4409769, 22.1008423],
                [102.4409904, 22.1000938],
                [102.4408847, 22.0995929],
                [102.4406276, 22.098983],
                [102.439283, 22.097108],
                [102.4389514, 22.0963897],
                [102.4388688, 22.0956576],
                [102.4389227, 22.0948028],
                [102.4388602, 22.0940175],
                [102.4385274, 22.0933706],
                [102.4380037, 22.092685],
                [102.437896, 22.0922911],
                [102.437903, 22.0918992],
                [102.4379484, 22.0915076],
                [102.4380702, 22.0911174],
                [102.4384627, 22.0905888],
                [102.4389739, 22.0898481],
                [102.4395958, 22.0893231],
                [102.4402939, 22.088835],
                [102.4406798, 22.0886628],
                [102.4412208, 22.088386],
                [102.4420926, 22.0878114],
                [102.4427912, 22.0872875],
                [102.4438451, 22.0861632],
                [102.4454024, 22.084726],
                [102.4458719, 22.0841629],
                [102.4459914, 22.0839151],
                [102.4460328, 22.0837374],
                [102.4459218, 22.0835219],
                [102.4455448, 22.0831952],
                [102.4453203, 22.0829064],
                [102.4452482, 22.0826557],
                [102.4452171, 22.0822631],
                [102.4452643, 22.0817647],
                [102.445259, 22.0809981],
                [102.445192, 22.0804623],
                [102.4450486, 22.0799253],
                [102.4448636, 22.0795658],
                [102.4446002, 22.0793121],
                [102.444146, 22.0790199],
                [102.4438041, 22.0788719],
                [102.4433455, 22.0788291],
                [102.4429627, 22.0788231],
                [102.4423894, 22.0787785],
                [102.4418541, 22.0787344],
                [102.4415116, 22.0786222],
                [102.4410945, 22.0784017],
                [102.4408317, 22.0781124],
                [102.4407207, 22.0778968],
                [102.4406895, 22.0775041],
                [102.4407337, 22.0771838],
                [102.440853, 22.0769362],
                [102.441246, 22.076372],
                [102.441482, 22.0760191],
                [102.4418381, 22.075383],
                [102.4418463, 22.0749196],
                [102.4416243, 22.0744883],
                [102.4415141, 22.0742371],
                [102.4414018, 22.0740927],
                [102.4415575, 22.0739525],
                [102.4423982, 22.074037],
                [102.4433895, 22.0742664],
                [102.4448379, 22.0746099],
                [102.4453919, 22.0746721],
                [102.4456216, 22.0746756],
                [102.4461657, 22.0742207],
                [102.4472871, 22.0735964],
                [102.4486024, 22.0728327],
                [102.4495273, 22.0724905],
                [102.4502959, 22.0723244],
                [102.4510626, 22.0722649],
                [102.4515206, 22.0723435],
                [102.4521311, 22.0724599],
                [102.4525514, 22.072502],
                [102.452897, 22.0724362],
                [102.4532466, 22.0721563],
                [102.4541792, 22.0713866],
                [102.4542594, 22.0713351],
                [102.4547215, 22.0710386],
                [102.4551475, 22.07076],
                [102.4566866, 22.0703204],
                [102.4583463, 22.0695619],
                [102.4590071, 22.0690018],
                [102.4596009, 22.0679058],
                [102.4595942, 22.0672105],
                [102.4596465, 22.066427],
                [102.4598474, 22.0658952],
                [102.4599655, 22.0657189],
                [102.4604298, 22.0654409],
                [102.4630531, 22.0643051],
                [102.4666746, 22.0630065],
                [102.4693373, 22.0617998],
                [102.4700299, 22.0615967],
                [102.4705649, 22.0616415],
                [102.4712514, 22.0617946],
                [102.4718242, 22.0618748],
                [102.4721298, 22.0619152],
                [102.4723595, 22.0619187],
                [102.4724361, 22.0619198],
                [102.4726206, 22.0618605],
                [102.4727326, 22.0616796],
                [102.4727869, 22.0615689],
                [102.4728314, 22.0612133],
                [102.4728384, 22.0608214],
                [102.4727389, 22.0599648],
                [102.4724823, 22.0593194],
                [102.4724114, 22.0589976],
                [102.4723024, 22.0586751],
                [102.4722678, 22.0584608],
                [102.4722742, 22.0581046],
                [102.4723194, 22.0577133],
                [102.4723616, 22.0575002],
                [102.4724023, 22.0573583],
                [102.4724795, 22.0573238],
                [102.4725956, 22.0572544],
                [102.4729413, 22.0571884],
                [102.4737834, 22.0572014],
                [102.4740902, 22.0571706],
                [102.4744775, 22.0569271],
                [102.4746024, 22.0563588],
                [102.4747543, 22.0553513],
                [102.4749188, 22.054712],
                [102.4752339, 22.0542176],
                [102.4755873, 22.0537238],
                [102.4760924, 22.0533036],
                [102.4770606, 22.0526767],
                [102.4774491, 22.0523618],
                [102.4778783, 22.0519048],
                [102.4782322, 22.0513754],
                [102.4784746, 22.0506659],
                [102.4786103, 22.0494913],
                [102.4788287, 22.0490488],
                [102.4792967, 22.0485568],
                [102.4808938, 22.0470124],
                [102.4821846, 22.0454632],
                [102.4828134, 22.0445458],
                [102.4832484, 22.0437679],
                [102.4838043, 22.0426354],
                [102.4840973, 22.0423011],
                [102.484795, 22.0418125],
                [102.4854889, 22.041538],
                [102.4859878, 22.0414743],
                [102.4867155, 22.0414498],
                [102.4870607, 22.0414194],
                [102.4876774, 22.0411793],
                [102.4880658, 22.0408643],
                [102.4883017, 22.0405113],
                [102.4885747, 22.0402303],
                [102.4889248, 22.0399147],
                [102.4890804, 22.0397745],
                [102.4893897, 22.0396009],
                [102.4898133, 22.0394647],
                [102.4903497, 22.0394373],
                [102.4910009, 22.0394116],
                [102.4913842, 22.0393818],
                [102.4916158, 22.0392784],
                [102.4918862, 22.03914],
                [102.4920801, 22.0390003],
                [102.4925889, 22.0383661],
                [102.4945922, 22.0354728],
                [102.4948846, 22.0351741],
                [102.495389, 22.0347896],
                [102.4956971, 22.0346872],
                [102.4959656, 22.0346558],
                [102.4962335, 22.0346598],
                [102.4966506, 22.0348802],
                [102.4973665, 22.035533],
                [102.4985341, 22.0366206],
                [102.4987349, 22.0368937],
                [102.4991361, 22.0375935],
                [102.4996662, 22.0374633],
                [102.5001232, 22.0372364],
                [102.5014783, 22.0362539],
                [102.5014742, 22.0358469],
                [102.5014277, 22.0355744],
                [102.5012906, 22.035174],
                [102.5011567, 22.0349226],
                [102.5013515, 22.0344271],
                [102.5014994, 22.0341805],
                [102.5018226, 22.0339148],
                [102.5026155, 22.033562],
                [102.5028782, 22.0335326],
                [102.5031455, 22.0335956],
                [102.504124, 22.0342419],
                [102.5048193, 22.0345037],
                [102.5051644, 22.0346825],
                [102.5054696, 22.0349184],
                [102.5057267, 22.035302],
                [102.5058864, 22.0354518],
                [102.5072773, 22.0361111],
                [102.5084189, 22.0368796],
                [102.5088629, 22.0370056],
                [102.5092493, 22.0369831],
                [102.5100136, 22.036678],
                [102.5102235, 22.0365495],
                [102.5106105, 22.0361953],
                [102.5108314, 22.0358654],
                [102.5119345, 22.035519],
                [102.5128225, 22.0345405],
                [102.5131839, 22.03463],
                [102.5135573, 22.034664],
                [102.514236, 22.0345825],
                [102.5146165, 22.0345993],
                [102.5153686, 22.033604],
                [102.5159122, 22.0326063],
                [102.5165128, 22.0318582],
                [102.5166589, 22.0318544],
                [102.5168048, 22.0318448],
                [102.5169501, 22.0318294],
                [102.5170945, 22.0318081],
                [102.5172724, 22.0317737],
                [102.5174481, 22.0317306],
                [102.5175246, 22.0317075],
                [102.5176034, 22.0316918],
                [102.5176834, 22.0316836],
                [102.5177639, 22.031683],
                [102.5178455, 22.0316903],
                [102.5179259, 22.0317053],
                [102.5180041, 22.0317281],
                [102.5182418, 22.0317738],
                [102.5186546, 22.0312752],
                [102.5190198, 22.0305801],
                [102.5192593, 22.0302493],
                [102.5195184, 22.0296613],
                [102.5197182, 22.0290676],
                [102.5199827, 22.0285753],
                [102.5198909, 22.0281024],
                [102.5197169, 22.026377],
                [102.5197055, 22.0258817],
                [102.5197667, 22.0252398],
                [102.519729, 22.0249646],
                [102.5193187, 22.0248094],
                [102.5182835, 22.0246472],
                [102.5172734, 22.0244157],
                [102.5163025, 22.0241212],
                [102.5156692, 22.0238886],
                [102.5141801, 22.0228278],
                [102.5139309, 22.0225598],
                [102.513661, 22.02212],
                [102.5134861, 22.0219737],
                [102.5131238, 22.0218587],
                [102.5124895, 22.0218942],
                [102.5114449, 22.0210634],
                [102.5109131, 22.0209183],
                [102.5098604, 22.0198623],
                [102.5097255, 22.019577],
                [102.5095647, 22.0193942],
                [102.5090668, 22.0189965],
                [102.5083326, 22.0192165],
                [102.5080074, 22.0192289],
                [102.5076921, 22.0191809],
                [102.5074905, 22.0191166],
                [102.5042168, 22.0174757],
                [102.5027268, 22.0173355],
                [102.5023489, 22.0163946],
                [102.5018198, 22.0156764],
                [102.5018414, 22.0151009],
                [102.5014015, 22.01424],
                [102.5009021, 22.0145854],
                [102.5004216, 22.0145428],
                [102.4996496, 22.0146129],
                [102.4989951, 22.0141048],
                [102.4987157, 22.0139673],
                [102.4980894, 22.0138872],
                [102.4973189, 22.0139767],
                [102.4970169, 22.0139685],
                [102.4967471, 22.013897],
                [102.4957951, 22.0134768],
                [102.4954603, 22.0135629],
                [102.4949812, 22.013748],
                [102.4942691, 22.0141666],
                [102.4938243, 22.0143609],
                [102.4932565, 22.0144843],
                [102.492977, 22.0145003],
                [102.4929024, 22.0144716],
                [102.4928277, 22.0143844],
                [102.492823, 22.0142642],
                [102.4932484, 22.0133731],
                [102.4932687, 22.0131683],
                [102.4931679, 22.0129697],
                [102.4926778, 22.0125686],
                [102.4924962, 22.0122933],
                [102.4924552, 22.0119968],
                [102.4926034, 22.0114791],
                [102.4924119, 22.0111192],
                [102.4921674, 22.0108202],
                [102.4921044, 22.0094276],
                [102.491182, 22.0088155],
                [102.4910021, 22.0087462],
                [102.4905762, 22.0086713],
                [102.4896397, 22.00868],
                [102.4893978, 22.0085071],
                [102.4890217, 22.0081175],
                [102.4883623, 22.0072702],
                [102.4880319, 22.0067267],
                [102.4883252, 22.0057801],
                [102.4888232, 22.0045817],
                [102.4883691, 22.0041942],
                [102.4882843, 22.0040951],
                [102.4879295, 22.0036912],
                [102.4875747, 22.0032872],
                [102.4873213, 22.0027315],
                [102.4873364, 22.0018734],
                [102.4875673, 21.9999762],
                [102.487778, 21.9990562],
                [102.4875992, 21.998134],
                [102.4874412, 21.9974564],
                [102.4874173, 21.9967807],
                [102.4877028, 21.99619],
                [102.4884567, 21.9956239],
                [102.4893427, 21.9945977],
                [102.4902968, 21.9932259],
                [102.4914295, 21.9922614],
                [102.4923084, 21.9916394],
                [102.4926253, 21.9912399],
                [102.4928203, 21.990723],
                [102.4929575, 21.9899742],
                [102.4931606, 21.9889953],
                [102.4935477, 21.9881347],
                [102.4939307, 21.9875053],
                [102.4945626, 21.9868217],
                [102.4951903, 21.9863692],
                [102.4962531, 21.9858655],
                [102.4978744, 21.9853127],
                [102.4985632, 21.9849189],
                [102.4991443, 21.9835991],
                [102.4999192, 21.9818203],
                [102.5003653, 21.9813936],
                [102.5009595, 21.9808253],
                [102.5021147, 21.9800107],
                [102.5028477, 21.9795244],
                [102.5030463, 21.9790657],
                [102.5031712, 21.9784636],
                [102.5031505, 21.9774685],
                [102.5031573, 21.9770778],
                [102.5032373, 21.9768658],
                [102.5034685, 21.9767272],
                [102.5037352, 21.9767313],
                [102.5042282, 21.9768809],
                [102.5046831, 21.97703],
                [102.5050248, 21.9771062],
                [102.5051803, 21.9769309],
                [102.5053015, 21.976542],
                [102.5053633, 21.9751929],
                [102.5054494, 21.9746258],
                [102.5057255, 21.974097],
                [102.5061546, 21.9735351],
                [102.5088578, 21.9715512],
                [102.5113854, 21.9697953],
                [102.5123155, 21.9689213],
                [102.5129, 21.9681841],
                [102.5132561, 21.9674434],
                [102.5135316, 21.9669503],
                [102.5138064, 21.9664925],
                [102.5140768, 21.9662834],
                [102.514576, 21.9660778],
                [102.5152638, 21.9659817],
                [102.5161029, 21.9659588],
                [102.5176616, 21.9661956],
                [102.5189124, 21.9666053],
                [102.5198616, 21.9668328],
                [102.520758, 21.9667931],
                [102.5218713, 21.966348],
                [102.5239845, 21.9653852],
                [102.525182, 21.9644795],
                [102.5262287, 21.963465],
                [102.5269608, 21.9630143],
                [102.5278428, 21.9627077],
                [102.5299862, 21.9622071],
                [102.5317121, 21.9615936],
                [102.5354901, 21.960247],
                [102.5372628, 21.9591367],
                [102.5383831, 21.9582653],
                [102.5395817, 21.9572884],
                [102.5407451, 21.9561335],
                [102.5419865, 21.9548731],
                [102.5427034, 21.9541911],
                [102.5440125, 21.9534289],
                [102.5447027, 21.9531907],
                [102.5452005, 21.9530558],
                [102.5458503, 21.952959],
                [102.5468035, 21.9529377],
                [102.5473756, 21.9529108],
                [102.5479116, 21.9527766],
                [102.5484131, 21.9524289],
                [102.5490731, 21.9517282],
                [102.5503163, 21.9503612],
                [102.5513223, 21.949488],
                [102.5530584, 21.9482704],
                [102.5578969, 21.945056],
                [102.5595548, 21.9439437],
                [102.5612423, 21.9433292],
                [102.561933, 21.9430553],
                [102.562282, 21.9427053],
                [102.5631507, 21.9420608],
                [102.5638467, 21.9414672],
                [102.5643167, 21.940728],
                [102.5644818, 21.9399844],
                [102.5646096, 21.9392047],
                [102.5648822, 21.9388534],
                [102.5659976, 21.938266],
                [102.5677225, 21.9376875],
                [102.569944, 21.9370454],
                [102.5722471, 21.9360847],
                [102.5740494, 21.9354361],
                [102.5759068, 21.9349129],
                [102.5766712, 21.9347821],
                [102.5775095, 21.9347945],
                [102.5781185, 21.9348389],
                [102.5785383, 21.9348096],
                [102.5793827, 21.9344668],
                [102.579809, 21.9340466],
                [102.5801234, 21.9334828],
                [102.5811381, 21.9320766],
                [102.581955, 21.9310938],
                [102.5833871, 21.9298004],
                [102.5848934, 21.9286145],
                [102.5857968, 21.9281482],
                [102.5868673, 21.9279507],
                [102.5878942, 21.9280724],
                [102.5888449, 21.9281929],
                [102.5893783, 21.9282008],
                [102.5897993, 21.9281003],
                [102.5906078, 21.9276148],
                [102.5922284, 21.9264306],
                [102.5937353, 21.9252091],
                [102.5943907, 21.9247569],
                [102.5947742, 21.9246203],
                [102.5952326, 21.924556],
                [102.5957449, 21.9246878],
                [102.5969933, 21.925239],
                [102.597791, 21.9253928],
                [102.5984012, 21.9253662],
                [102.5993573, 21.925167],
                [102.6011153, 21.9248729],
                [102.6019529, 21.9249207],
                [102.6027126, 21.9250738],
                [102.6028651, 21.9250761],
                [102.6032097, 21.9249745],
                [102.6040194, 21.9244178],
                [102.605488, 21.9231958],
                [102.6059505, 21.9228827],
                [102.6067173, 21.9226097],
                [102.6083828, 21.9221542],
                [102.6099168, 21.9215725],
                [102.6116826, 21.9208165],
                [102.6123755, 21.9204002],
                [102.612763, 21.9200151],
                [102.6130779, 21.9194155],
                [102.6139034, 21.9178999],
                [102.6155373, 21.9158984],
                [102.616378, 21.9146138],
                [102.617322, 21.912851],
                [102.6179576, 21.9112969],
                [102.6188288, 21.9093198],
                [102.6193266, 21.9080301],
                [102.6199047, 21.9053383],
                [102.6204766, 21.9030371],
                [102.6208224, 21.9017098],
                [102.6209087, 21.900999],
                [102.620999, 21.9002556],
                [102.6207612, 21.8985112],
                [102.6200242, 21.8970083],
                [102.6195806, 21.8961847],
                [102.6190403, 21.8954486],
                [102.6181349, 21.8949025],
                [102.6173063, 21.894322],
                [102.6170444, 21.894034],
                [102.6167151, 21.893212],
                [102.6165559, 21.8913267],
                [102.6163791, 21.8905069],
                [102.616085, 21.8898631],
                [102.6156771, 21.8891822],
                [102.6139231, 21.8858347],
                [102.613417, 21.884193],
                [102.613277, 21.8834449],
                [102.6130193, 21.8829082],
                [102.6127227, 21.8824064],
                [102.6119758, 21.8815074],
                [102.6107208, 21.8802279],
                [102.610353, 21.8794409],
                [102.6103231, 21.8789431],
                [102.6105243, 21.8783064],
                [102.6109175, 21.877566],
                [102.6121296, 21.8757005],
                [102.6129502, 21.8744688],
                [102.6141347, 21.8731182],
                [102.6154807, 21.872356],
                [102.6169726, 21.8719868],
                [102.6183495, 21.8716515],
                [102.6194179, 21.8715603],
                [102.6204843, 21.8715757],
                [102.6212479, 21.8714801],
                [102.6218227, 21.8712753],
                [102.6233228, 21.8704087],
                [102.625086, 21.869759],
                [102.6262902, 21.8695099],
                [102.6277017, 21.8693882],
                [102.6283504, 21.8693264],
                [102.6288503, 21.8690494],
                [102.6290079, 21.8687319],
                [102.6289756, 21.8683761],
                [102.6282309, 21.867335],
                [102.6278208, 21.8667962],
                [102.6277512, 21.8664044],
                [102.6277939, 21.8661207],
                [102.6285334, 21.8651721],
                [102.6295763, 21.8642988],
                [102.6307354, 21.8633207],
                [102.6329859, 21.8619851],
                [102.6342526, 21.8613993],
                [102.6362817, 21.8607889],
                [102.6386172, 21.8600763],
                [102.6397275, 21.8597369],
                [102.6406808, 21.8596795],
                [102.6417073, 21.8598007],
                [102.6427147, 21.8599395],
                [102.6435536, 21.8598805],
                [102.6443604, 21.8594656],
                [102.6449035, 21.8588694],
                [102.6453698, 21.8583076],
                [102.6456463, 21.8577075],
                [102.6457688, 21.8572119],
                [102.6459708, 21.8565041],
                [102.6459185, 21.8550466],
                [102.6455627, 21.8535137],
                [102.6451571, 21.8526908],
                [102.6440965, 21.8511655],
                [102.6426176, 21.8495988],
                [102.6422451, 21.8490961],
                [102.6421758, 21.8486687],
                [102.6421822, 21.8482779],
                [102.6423029, 21.8478889],
                [102.6425788, 21.8473243],
                [102.6427412, 21.8467226],
                [102.6427544, 21.8459057],
                [102.6427274, 21.8452302],
                [102.642971, 21.8443098],
                [102.643247, 21.8437453],
                [102.6436048, 21.8428267],
                [102.6436909, 21.8422239],
                [102.6437002, 21.8416555],
                [102.6437124, 21.8409096],
                [102.643836, 21.8403428],
                [102.6441136, 21.8396717],
                [102.6450319, 21.8382458],
                [102.6456952, 21.837296],
                [102.6463138, 21.8367363],
                [102.6468157, 21.8363172],
                [102.6472045, 21.8358252],
                [102.6473657, 21.8352947],
                [102.6474143, 21.8346558],
                [102.6472736, 21.8339431],
                [102.6472506, 21.833019],
                [102.6472792, 21.8324332],
                [102.647712, 21.8315866],
                [102.648296, 21.8308133],
                [102.648996, 21.829935],
                [102.6498534, 21.8287392],
                [102.6501713, 21.8279265],
                [102.6504091, 21.8273614],
                [102.6506175, 21.826263],
                [102.6507245, 21.8255361],
                [102.6507391, 21.8246481],
                [102.6505989, 21.8238999],
                [102.6504219, 21.8230802],
                [102.6502466, 21.8221539],
                [102.6501443, 21.8202516],
                [102.6501382, 21.8182974],
                [102.6501609, 21.8169119],
                [102.650101, 21.8159163],
                [102.6499597, 21.8152391],
                [102.6496291, 21.8144883],
                [102.6493333, 21.8139511],
                [102.6488886, 21.8131986],
                [102.6484077, 21.8123391],
                [102.6479159, 21.8109641],
                [102.6478509, 21.810288],
                [102.6478648, 21.8094356],
                [102.6479157, 21.8086546],
                [102.6480765, 21.808124],
                [102.6483911, 21.8075244],
                [102.6492602, 21.806773],
                [102.6513836, 21.8049911],
                [102.653469, 21.8032087],
                [102.6548989, 21.8019144],
                [102.6555929, 21.8013912],
                [102.6562482, 21.8009031],
                [102.6568631, 21.8005565],
                [102.6576285, 21.8003188],
                [102.6582423, 21.8000432],
                [102.6588572, 21.7996966],
                [102.6590931, 21.799238],
                [102.6592752, 21.7985833],
                [102.6596444, 21.7969541],
                [102.6602725, 21.7957905],
                [102.6607027, 21.795086],
                [102.6611325, 21.794417],
                [102.6615475, 21.7934813],
                [102.6618266, 21.7927035],
                [102.6619131, 21.7920652],
                [102.6620094, 21.790823],
                [102.661732, 21.7891491],
                [102.661601, 21.7878326],
                [102.661458, 21.7872621],
                [102.6612768, 21.7866911],
                [102.6609418, 21.7862244],
                [102.6603812, 21.785577],
                [102.6594183, 21.7850836],
                [102.6590809, 21.7847591],
                [102.6588595, 21.7843295],
                [102.6589061, 21.7837972],
                [102.6591831, 21.7831616],
                [102.6594593, 21.7825615],
                [102.6595054, 21.7820647],
                [102.6594725, 21.7817445],
                [102.658995, 21.7806717],
                [102.6580295, 21.7791658],
                [102.6572456, 21.7770406],
                [102.6566297, 21.7751133],
                [102.6561919, 21.7739346],
                [102.6557086, 21.7732171],
                [102.6543598, 21.7718832],
                [102.6528393, 21.7705648],
                [102.6520182, 21.7695583],
                [102.6513857, 21.768661],
                [102.6509041, 21.767837],
                [102.6505374, 21.766979],
                [102.6501736, 21.7659434],
                [102.6498519, 21.7646598],
                [102.6489562, 21.7623908],
                [102.6483526, 21.7597175],
                [102.6483295, 21.7587933],
                [102.6483787, 21.7581189],
                [102.6486835, 21.756933],
                [102.6488958, 21.7555858],
                [102.6489524, 21.7544497],
                [102.6487373, 21.7536294],
                [102.6485223, 21.7528091],
                [102.6483424, 21.752167],
                [102.6484868, 21.7514762],
                [102.648804, 21.750699],
                [102.6488485, 21.7503089],
                [102.6488565, 21.7498115],
                [102.6488547, 21.7492494],
                [102.6486612, 21.747415],
                [102.6485827, 21.7453838],
                [102.6486318, 21.744083],
                [102.6486543, 21.7427039],
                [102.6485503, 21.742234],
                [102.6481956, 21.7417343],
                [102.6476599, 21.7412234],
                [102.6473594, 21.7408285],
                [102.6472816, 21.7404627],
                [102.647373, 21.7399952],
                [102.6476681, 21.7390097],
                [102.647912, 21.7377368],
                [102.6479924, 21.7362274],
                [102.647961, 21.7355887],
                [102.6478266, 21.7352742],
                [102.6473325, 21.7347723],
                [102.6461968, 21.7342613],
                [102.6455333, 21.7338871],
                [102.645093, 21.7335163],
                [102.6449302, 21.7332275],
                [102.6449632, 21.7329153],
                [102.6456792, 21.7317796],
                [102.6480236, 21.7291691],
                [102.6488714, 21.7285039],
                [102.649573, 21.7282534],
                [102.6504677, 21.7281359],
                [102.6508042, 21.7280365],
                [102.6509475, 21.7278041],
                [102.6510472, 21.7268158],
                [102.6508695, 21.7248728],
                [102.6507153, 21.7240632],
                [102.6507761, 21.7237515],
                [102.6513699, 21.7232649],
                [102.65244, 21.7226551],
                [102.6528544, 21.7220488],
                [102.6529744, 21.7215296],
                [102.6529245, 21.7211643],
                [102.6526808, 21.720718],
                [102.6524085, 21.7202973],
                [102.6521656, 21.719799],
                [102.6521419, 21.7195382],
                [102.6522276, 21.7194093],
                [102.6526759, 21.7192853],
                [102.6538755, 21.7193023],
                [102.6549687, 21.7189792],
                [102.6560983, 21.7181357],
                [102.6568613, 21.7166748],
                [102.6575281, 21.7151215],
                [102.6579142, 21.7136684],
                [102.6579694, 21.7128486],
                [102.6578988, 21.7120402],
                [102.657966, 21.7113378],
                [102.6581384, 21.7110277],
                [102.6586446, 21.7107744],
                [102.6594026, 21.7104725],
                [102.6596014, 21.710267],
                [102.6597453, 21.7099826],
                [102.6597517, 21.7095919],
                [102.6596791, 21.7089137],
                [102.6600519, 21.7074212],
                [102.6601875, 21.7059385],
                [102.6601747, 21.7050007],
                [102.6600217, 21.7041128],
                [102.6600417, 21.7037355],
                [102.6602179, 21.703191],
                [102.6608708, 21.702497],
                [102.6611587, 21.701928],
                [102.6611914, 21.701642],
                [102.6610591, 21.7011972],
                [102.6607129, 21.7001765],
                [102.6607197, 21.6997598],
                [102.6610469, 21.6993608],
                [102.6615608, 21.6986387],
                [102.6619954, 21.697629],
                [102.6624559, 21.69675],
                [102.6633944, 21.6956432],
                [102.6638369, 21.6950112],
                [102.6642454, 21.693897],
                [102.6643476, 21.6927524],
                [102.6641466, 21.691369],
                [102.6641172, 21.6906002],
                [102.6641281, 21.6899232],
                [102.6642497, 21.6892998],
                [102.6646777, 21.6887067],
                [102.6658941, 21.6876559],
                [102.6666308, 21.686937],
                [102.6670176, 21.6863043],
                [102.6672516, 21.6856304],
                [102.6672021, 21.685239],
                [102.6669274, 21.6849747],
                [102.6658737, 21.6845951],
                [102.6650443, 21.6841408],
                [102.6641041, 21.6836325],
                [102.6624521, 21.6823069],
                [102.6623883, 21.6819284],
                [102.6623161, 21.681224],
                [102.6625027, 21.6800285],
                [102.6627616, 21.6795373],
                [102.663243, 21.6791013],
                [102.6649268, 21.6784478],
                [102.6662732, 21.6779719],
                [102.6671923, 21.6771904],
                [102.6677384, 21.6761822],
                [102.6680889, 21.6751974],
                [102.668246, 21.6741056],
                [102.6681208, 21.6732183],
                [102.6682578, 21.6725039],
                [102.6686057, 21.6716753],
                [102.6697437, 21.6702848],
                [102.6707126, 21.668996],
                [102.6719875, 21.6668911],
                [102.6727592, 21.6657298],
                [102.6731642, 21.6648238],
                [102.673661, 21.6625256],
                [102.6738781, 21.6611742],
                [102.6742011, 21.6601629],
                [102.6748448, 21.659143],
                [102.6755577, 21.6581632],
                [102.6760164, 21.6573882],
                [102.6761333, 21.6570512],
                [102.6760822, 21.656764],
                [102.6757513, 21.656525],
                [102.6750031, 21.656228],
                [102.6743407, 21.6558019],
                [102.6741369, 21.6554735],
                [102.6741701, 21.6551353],
                [102.6744527, 21.6549049],
                [102.6750144, 21.6546522],
                [102.6762762, 21.6542272],
                [102.6767863, 21.6540785],
                [102.6778433, 21.6538844],
                [102.6782598, 21.6539944],
                [102.6788689, 21.6542634],
                [102.6804248, 21.6546239],
                [102.6828184, 21.6549176],
                [102.6846685, 21.655191],
                [102.685137, 21.6555362],
                [102.6853497, 21.6561902],
                [102.6854766, 21.6569734],
                [102.6857173, 21.657628],
                [102.6860742, 21.6579716],
                [102.6872144, 21.6581697],
                [102.6882985, 21.6583933],
                [102.6886571, 21.6586327],
                [102.6887637, 21.6589467],
                [102.6887014, 21.6593627],
                [102.6884017, 21.6597882],
                [102.6872457, 21.6605796],
                [102.6870164, 21.6609671],
                [102.6870956, 21.6612547],
                [102.6878929, 21.661969],
                [102.6903806, 21.6633582],
                [102.6917111, 21.6638716],
                [102.6929163, 21.6643703],
                [102.6932501, 21.664427],
                [102.6937262, 21.6643034],
                [102.6946267, 21.663795],
                [102.6955862, 21.6630789],
                [102.6963802, 21.6622564],
                [102.6971142, 21.6616935],
                [102.6976756, 21.661467],
                [102.698651, 21.6615066],
                [102.7007052, 21.6621081],
                [102.7014681, 21.6623661],
                [102.7023869, 21.662457],
                [102.7031717, 21.6622074],
                [102.7039567, 21.6619578],
                [102.7044871, 21.6619129],
                [102.7048205, 21.6619956],
                [102.705483, 21.6624217],
                [102.7061412, 21.663134],
                [102.706828, 21.6637948],
                [102.7075179, 21.6642471],
                [102.709133, 21.6643996],
                [102.7099017, 21.664293],
                [102.7110522, 21.66384],
                [102.71243, 21.6631297],
                [102.7134121, 21.6627525],
                [102.7143646, 21.6624791],
                [102.7156771, 21.6623669],
                [102.7169035, 21.6624099],
                [102.7196694, 21.6629819],
                [102.7214166, 21.663605],
                [102.7229981, 21.6641216],
                [102.7240278, 21.6642659],
                [102.7248522, 21.6641601],
                [102.7261967, 21.6637878],
                [102.7274535, 21.6636747],
                [102.7288479, 21.6636678],
                [102.7303246, 21.6637661],
                [102.7312726, 21.6637791],
                [102.7317906, 21.6636429],
                [102.7321018, 21.6633606],
                [102.7322186, 21.6630236],
                [102.732253, 21.6626073],
                [102.7322608, 21.6621124],
                [102.7323517, 21.6616449],
                [102.7325268, 21.6611524],
                [102.7328113, 21.6607917],
                [102.7333746, 21.6604346],
                [102.7345801, 21.6600344],
                [102.7355588, 21.6598654],
                [102.7363703, 21.6596941],
                [102.7371975, 21.6594058],
                [102.7377849, 21.6592835],
                [102.7385095, 21.6593195],
                [102.7393703, 21.6595656],
                [102.7400334, 21.6599654],
                [102.7411074, 21.6608396],
                [102.7430947, 21.662169],
                [102.7451623, 21.663708],
                [102.747163, 21.6650765],
                [102.7474701, 21.6650547],
                [102.7478092, 21.6647728],
                [102.748149, 21.6644388],
                [102.7485145, 21.6642614],
                [102.748682, 21.6642376],
                [102.749125, 21.664452],
                [102.750225, 21.6654568],
                [102.7507972, 21.6663241],
                [102.7515476, 21.668288],
                [102.7524412, 21.6709178],
                [102.7529254, 21.6720705],
                [102.7535833, 21.6728088],
                [102.7549719, 21.6740909],
                [102.756386, 21.674631],
                [102.7587263, 21.6747929],
                [102.7602031, 21.674891],
                [102.7613456, 21.6749585],
                [102.7622485, 21.6751921],
                [102.7629659, 21.6756967],
                [102.7634598, 21.6762242],
                [102.7637791, 21.6772185],
                [102.7640694, 21.6782904],
                [102.7643888, 21.6792845],
                [102.7648633, 21.6801635],
                [102.7653051, 21.680456],
                [102.7663353, 21.6805741],
                [102.7671416, 21.6807412],
                [102.7677762, 21.6811925],
                [102.7681056, 21.6815356],
                [102.7682664, 21.6819545],
                [102.7683426, 21.6824505],
                [102.7680759, 21.6834628],
                [102.7679293, 21.6839296],
                [102.7680171, 21.6845691],
                [102.7686618, 21.6861666],
                [102.769394, 21.6875311],
                [102.769799, 21.6883961],
                [102.7699963, 21.6891672],
                [102.7703234, 21.6896664],
                [102.7710152, 21.6900405],
                [102.7723744, 21.6905277],
                [102.7733417, 21.6911137],
                [102.7740038, 21.6915914],
                [102.7743049, 21.6919601],
                [102.7745982, 21.6928497],
                [102.7747111, 21.6936718],
                [102.7749832, 21.6941182],
                [102.7756724, 21.6946485],
                [102.7778546, 21.6960584],
                [102.7790912, 21.6972731],
                [102.7798173, 21.6981294],
                [102.7802248, 21.6988381],
                [102.7803316, 21.6991522],
                [102.7802411, 21.6995938],
                [102.7800643, 21.7002165],
                [102.7800016, 21.7006585],
                [102.7801084, 21.7009726],
                [102.7806865, 21.7014753],
                [102.7823586, 21.7025005],
                [102.7832746, 21.7027993],
                [102.7838042, 21.7028324],
                [102.7843063, 21.7028391],
                [102.7845564, 21.7028945],
                [102.7848042, 21.7031062],
                [102.7850736, 21.703735],
                [102.7856405, 21.7049668],
                [102.7861848, 21.7058598],
                [102.786998, 21.7065088],
                [102.7876331, 21.7069341],
                [102.7884129, 21.7070226],
                [102.7898923, 21.7069643],
                [102.7915954, 21.7068827],
                [102.7927684, 21.7067942],
                [102.7939715, 21.7065758],
                [102.7950196, 21.7064465],
                [102.7957184, 21.7063516],
                [102.7963883, 21.7063344],
                [102.7973039, 21.7066593],
                [102.7984899, 21.7075607],
                [102.7996423, 21.7088263],
                [102.800545, 21.7100104],
                [102.8011962, 21.7112173],
                [102.8015822, 21.7124337],
                [102.8017931, 21.713244],
                [102.8023945, 21.7140595],
                [102.8034032, 21.7156097],
                [102.8039316, 21.7166457],
                [102.8043732, 21.7169641],
                [102.8052367, 21.7170537],
                [102.8066872, 21.717073],
                [102.8076635, 21.7170859],
                [102.8083314, 21.7171989],
                [102.8085802, 21.7173585],
                [102.8088276, 21.7175961],
                [102.8091828, 21.7180958],
                [102.809451, 21.7188027],
                [102.8097261, 21.7190668],
                [102.8100568, 21.7193316],
                [102.8113086, 21.7195827],
                [102.8115702, 21.7198075],
                [102.8115663, 21.7200679],
                [102.8112787, 21.7206372],
                [102.8104747, 21.7221375],
                [102.8097223, 21.7239249],
                [102.809332, 21.7248184],
                [102.8092683, 21.7253385],
                [102.8094308, 21.7256532],
                [102.8102878, 21.7261855],
                [102.811562, 21.7268016],
                [102.813532, 21.7275308],
                [102.8138479, 21.7278606],
                [102.813952, 21.7283569],
                [102.8139339, 21.7295549],
                [102.814031, 21.7305201],
                [102.8143299, 21.7310449],
                [102.8150709, 21.7318623],
                [102.8154842, 21.7322063],
                [102.8161232, 21.7323971],
                [102.8167935, 21.7323539],
                [102.817551, 21.7320773],
                [102.8186744, 21.7315972],
                [102.8192937, 21.7312407],
                [102.8196026, 21.7311145],
                [102.8199097, 21.7310925],
                [102.8202717, 21.7311493],
                [102.8208795, 21.731548],
                [102.8218139, 21.7324981],
                [102.8233971, 21.7339125],
                [102.8244776, 21.7344217],
                [102.8255574, 21.7349829],
                [102.8261374, 21.7353813],
                [102.8263823, 21.7358013],
                [102.8263757, 21.7362441],
                [102.8260294, 21.7369949],
                [102.8252424, 21.7383001],
                [102.8247765, 21.7395964],
                [102.8241375, 21.7412551],
                [102.823914, 21.7422029],
                [102.8237599, 21.7431646],
                [102.823661, 21.7441792],
                [102.8236527, 21.7447262],
                [102.8237569, 21.7452225],
                [102.8239734, 21.7456682],
                [102.8241994, 21.7464136],
                [102.8241612, 21.7470903],
                [102.8239492, 21.7482076],
                [102.8237942, 21.7492463],
                [102.8237774, 21.7500259],
                [102.8238928, 21.750734],
                [102.8242006, 21.7512384],
                [102.8244791, 21.7515953],
                [102.8246958, 21.7518632],
                [102.8247222, 21.7522167],
                [102.8246248, 21.7523921],
                [102.8234749, 21.7533484],
                [102.8223545, 21.7544523],
                [102.8218388, 21.7551815],
                [102.8216737, 21.7556797],
                [102.8211851, 21.7567037],
                [102.8209128, 21.7580247],
                [102.8206139, 21.7600668],
                [102.8204207, 21.7624193],
                [102.8203739, 21.7665547],
                [102.8203174, 21.7702925],
                [102.8201735, 21.7725132],
                [102.8202463, 21.7739566],
                [102.8204031, 21.7750627],
                [102.8207663, 21.7760684],
                [102.8208818, 21.7767764],
                [102.8208743, 21.7772767],
                [102.820773, 21.7777169],
                [102.8203858, 21.7783006],
                [102.8191353, 21.7796382],
                [102.8177568, 21.7810919],
                [102.81711, 21.7821431],
                [102.8165365, 21.7835633],
                [102.8162273, 21.7852372],
                [102.8158139, 21.7875278],
                [102.8156775, 21.7892482],
                [102.8152829, 21.7903027],
                [102.8151812, 21.7907723],
                [102.8151749, 21.7911845],
                [102.8154542, 21.7914825],
                [102.8160473, 21.7919025],
                [102.8182169, 21.7934178],
                [102.8188712, 21.7939563],
                [102.8196095, 21.7952024],
                [102.8201483, 21.797123],
                [102.8202255, 21.7982721],
                [102.8201242, 21.7987123],
                [102.8193813, 21.8008956],
                [102.8182882, 21.8043254],
                [102.8182251, 21.8053696],
                [102.818328, 21.8069017],
                [102.818256, 21.8074895],
                [102.8178659, 21.8082498],
                [102.8163431, 21.810879],
                [102.8160484, 21.8126266],
                [102.8157231, 21.81327],
                [102.8153388, 21.8136476],
                [102.8147972, 21.8139936],
                [102.8136522, 21.8145968],
                [102.8132349, 21.8150622],
                [102.8121333, 21.816961],
                [102.8111808, 21.8183762],
                [102.8109488, 21.819109],
                [102.8109111, 21.8195207],
                [102.8108706, 21.8201088],
                [102.8109576, 21.8206105],
                [102.8110398, 21.8214358],
                [102.8109335, 21.8221998],
                [102.8104741, 21.8243868],
                [102.8104977, 21.824917],
                [102.8107114, 21.8253908],
                [102.8127328, 21.8273311],
                [102.8133197, 21.8281631],
                [102.8135636, 21.8287256],
                [102.8138222, 21.829362],
                [102.8141305, 21.829837],
                [102.8146916, 21.8302861],
                [102.8156625, 21.83077],
                [102.8161002, 21.8310407],
                [102.8164747, 21.8313106],
                [102.8168462, 21.8317865],
                [102.8174335, 21.8325891],
                [102.8180484, 21.8336569],
                [102.8182279, 21.834307],
                [102.8183877, 21.835207],
                [102.8181567, 21.835881],
                [102.8173199, 21.8369592],
                [102.8169607, 21.8377492],
                [102.8168838, 21.8386607],
                [102.8171499, 21.8398417],
                [102.8174148, 21.840066],
                [102.8187891, 21.8409969],
                [102.8203862, 21.8418127],
                [102.8224911, 21.8424293],
                [102.8254112, 21.8434099],
                [102.8265752, 21.8436607],
                [102.8282625, 21.843727],
                [102.8299366, 21.8436314],
                [102.8304405, 21.8436968],
                [102.8306583, 21.8439058],
                [102.8315261, 21.8449476],
                [102.8319305, 21.8453356],
                [102.8323995, 21.8456361],
                [102.8335299, 21.8460043],
                [102.8346624, 21.8462546],
                [102.8359534, 21.8464481],
                [102.8364106, 21.846469],
                [102.8369176, 21.8463284],
                [102.8373652, 21.8459516],
                [102.8380038, 21.8454595],
                [102.8386744, 21.8449384],
                [102.8390876, 21.8447378],
                [102.8395947, 21.8445972],
                [102.8399742, 21.8445433],
                [102.8403519, 21.8446072],
                [102.8406947, 21.844906],
                [102.8409671, 21.845675],
                [102.8411196, 21.8460303],
                [102.8412747, 21.8462089],
                [102.8414311, 21.8462992],
                [102.8416506, 21.8463904],
                [102.8420293, 21.8463954],
                [102.842883, 21.8462888],
                [102.8439597, 21.846038],
                [102.8447534, 21.8457245],
                [102.8455693, 21.8449846],
                [102.8467838, 21.8439701],
                [102.8472614, 21.8436819],
                [102.8475787, 21.8435683],
                [102.8480214, 21.8435152],
                [102.848811, 21.8434666],
                [102.8494104, 21.8434745],
                [102.8501358, 21.8435134],
                [102.8502702, 21.8434873],
                [102.8504206, 21.8434582],
                [102.8508023, 21.8432572],
                [102.8515426, 21.8422953],
                [102.8517748, 21.8415329],
                [102.8521066, 21.8404481],
                [102.8523269, 21.8394353],
                [102.8523111, 21.8383753],
                [102.8522288, 21.83755],
                [102.8522701, 21.8369029],
                [102.8524779, 21.8356692],
                [102.8525893, 21.8345519],
                [102.8531474, 21.8320423],
                [102.853573, 21.8310174],
                [102.8537404, 21.830372],
                [102.853718, 21.8297535],
                [102.8536015, 21.8291044],
                [102.8534822, 21.8275867],
                [102.8534918, 21.8269392],
                [102.8535318, 21.8263805],
                [102.8535712, 21.825851],
                [102.8536747, 21.8252636],
                [102.8538709, 21.8247952],
                [102.8540975, 21.8244154],
                [102.8543396, 21.8240506],
                [102.8552281, 21.8237089],
                [102.8562111, 21.8233979],
                [102.8567854, 21.8229637],
                [102.8569473, 21.8227009],
                [102.8571754, 21.8222035],
                [102.8575383, 21.8211483],
                [102.857682, 21.8199726],
                [102.8578111, 21.8187233],
                [102.8577918, 21.8178987],
                [102.8575249, 21.8167471],
                [102.8560873, 21.8136964],
                [102.855258, 21.8121843],
                [102.8548503, 21.8109572],
                [102.8548267, 21.8104271],
                [102.8548328, 21.810015],
                [102.8549631, 21.8097517],
                [102.8552543, 21.809255],
                [102.8556394, 21.8088185],
                [102.8560566, 21.8083529],
                [102.8564753, 21.8077695],
                [102.8566713, 21.8073305],
                [102.8567391, 21.8070076],
                [102.8567453, 21.8065955],
                [102.8565212, 21.8057536],
                [102.8564122, 21.8046041],
                [102.8564222, 21.8039271],
                [102.856431, 21.8033385],
                [102.8564695, 21.802868],
                [102.8566022, 21.8024281],
                [102.8567324, 21.8021648],
                [102.8570219, 21.8017859],
                [102.8578034, 21.801222],
                [102.8580288, 21.8009012],
                [102.8581616, 21.8004613],
                [102.8582313, 21.8000206],
                [102.8582979, 21.799786],
                [102.8586181, 21.7994663],
                [102.8591303, 21.7989725],
                [102.8594194, 21.798623],
                [102.8596135, 21.7983017],
                [102.8596814, 21.7979787],
                [102.8597178, 21.7976553],
                [102.859507, 21.7969756],
                [102.8588043, 21.7954357],
                [102.8580255, 21.7937034],
                [102.8576689, 21.7922269],
                [102.857491, 21.7914592],
                [102.8573707, 21.7910749],
                [102.8571862, 21.7907486],
                [102.8565627, 21.7902402],
                [102.8555644, 21.7894913],
                [102.8548357, 21.7886428],
                [102.8545948, 21.7878743],
                [102.8545707, 21.7873735],
                [102.8546074, 21.7870208],
                [102.8547717, 21.7865813],
                [102.8550959, 21.7859968],
                [102.856169, 21.7849067],
                [102.856776, 21.7843846],
                [102.8573482, 21.7840977],
                [102.8579185, 21.7839285],
                [102.8584893, 21.7837298],
                [102.8588721, 21.7834404],
                [102.8591954, 21.7829147],
                [102.8592979, 21.7823861],
                [102.8592746, 21.7818265],
                [102.8590625, 21.781235],
                [102.8579241, 21.7793068],
                [102.8574334, 21.7783583],
                [102.8573972, 21.7776072],
                [102.8577258, 21.7767283],
                [102.8580809, 21.7761736],
                [102.8584686, 21.7755604],
                [102.8585409, 21.7749432],
                [102.8583026, 21.773998],
                [102.8580258, 21.7735234],
                [102.8575926, 21.7729584],
                [102.8568346, 21.7719624],
                [102.8564955, 21.7714282],
                [102.8563471, 21.770808],
                [102.856135, 21.7702164],
                [102.8557964, 21.7696528],
                [102.855333, 21.7689991],
                [102.8552135, 21.768556],
                [102.8552488, 21.7682915],
                [102.8555052, 21.7680299],
                [102.855887, 21.7677994],
                [102.8566159, 21.7675733],
                [102.8591311, 21.7670613],
                [102.8603657, 21.7667534],
                [102.8610017, 21.7664084],
                [102.8613863, 21.7660013],
                [102.8615838, 21.7654444],
                [102.8616236, 21.7648856],
                [102.8614457, 21.7641179],
                [102.8611733, 21.763349],
                [102.860503, 21.7617506],
                [102.859978, 21.7599334],
                [102.8598965, 21.7590492],
                [102.8599391, 21.7583138],
                [102.8601693, 21.7576691],
                [102.8604956, 21.7569373],
                [102.8607294, 21.7560572],
                [102.8607006, 21.7548057],
                [102.8605517, 21.754215],
                [102.8602749, 21.7537404],
                [102.8598439, 21.7530284],
                [102.8593809, 21.7523453],
                [102.8589153, 21.7518387],
                [102.8583858, 21.7513904],
                [102.8574473, 21.7508777],
                [102.8567904, 21.7505159],
                [102.856466, 21.7500555],
                [102.8564061, 21.7498486],
                [102.8563489, 21.7492428],
                [102.8563094, 21.7485463],
                [102.8562423, 21.7480466],
                [102.8560012, 21.7475994],
                [102.8554785, 21.7468591],
                [102.8552585, 21.7465418],
                [102.8551517, 21.7461737],
                [102.8551014, 21.7457802],
                [102.8552004, 21.7447859],
                [102.8551251, 21.7441824],
                [102.8548178, 21.7431213],
                [102.8545288, 21.7417805],
                [102.8545464, 21.740601],
                [102.8545019, 21.7398139],
                [102.8544927, 21.7394861],
                [102.8545812, 21.7391988],
                [102.8552384, 21.7384471],
                [102.8562667, 21.7372806],
                [102.8569569, 21.7361885],
                [102.8575329, 21.735226],
                [102.857868, 21.7344044],
                [102.8582247, 21.733072],
                [102.8584661, 21.7319477],
                [102.8586256, 21.7306652],
                [102.8587336, 21.7299981],
                [102.8586568, 21.729499],
                [102.8585219, 21.7291302],
                [102.8579712, 21.728389],
                [102.8573344, 21.7277777],
                [102.856947, 21.7273795],
                [102.8566991, 21.7270617],
                [102.856483, 21.7264822],
                [102.8564612, 21.7260624],
                [102.8562968, 21.7248412],
                [102.8562058, 21.7233981],
                [102.8562183, 21.7225593],
                [102.8563379, 21.7220628],
                [102.8565976, 21.7215943],
                [102.8569554, 21.72114],
                [102.8573816, 21.7208048],
                [102.8577769, 21.7206526],
                [102.8586507, 21.720428],
                [102.8594981, 21.720098],
                [102.8599532, 21.7197106],
                [102.8605571, 21.7187484],
                [102.8611929, 21.7175245],
                [102.8619345, 21.7167476],
                [102.8624298, 21.7164787],
                [102.8633321, 21.7162282],
                [102.8640094, 21.7160009],
                [102.8644072, 21.7156915],
                [102.8648614, 21.7153564],
                [102.8654264, 21.7151278],
                [102.8659605, 21.7150823],
                [102.8666624, 21.7150914],
                [102.8673393, 21.7148903],
                [102.8680757, 21.7144541],
                [102.8693269, 21.7133954],
                [102.8699662, 21.7128924],
                [102.8702814, 21.7124507],
                [102.8703718, 21.7120324],
                [102.8703492, 21.7116651],
                [102.8704988, 21.7110377],
                [102.8707911, 21.710255],
                [102.8713392, 21.7092658],
                [102.8719969, 21.7084615],
                [102.8726084, 21.7079319],
                [102.8738509, 21.7074498],
                [102.8757403, 21.7068972],
                [102.8766724, 21.7065159],
                [102.8772684, 21.7060778],
                [102.8776118, 21.7056366],
                [102.8778173, 21.7050362],
                [102.8779116, 21.7043558],
                [102.8778226, 21.7037123],
                [102.877605, 21.7032377],
                [102.8774973, 21.7029217],
                [102.877502, 21.7026071],
                [102.8775891, 21.7023985],
                [102.877814, 21.7023752],
                [102.8780661, 21.7024308],
                [102.8790682, 21.7030204],
                [102.8801777, 21.7039522],
                [102.8812302, 21.7049357],
                [102.881834, 21.7058872],
                [102.8820448, 21.7068338],
                [102.8820827, 21.7080665],
                [102.8821879, 21.7085659],
                [102.8826017, 21.7090693],
                [102.8856897, 21.7120057],
                [102.8863845, 21.7124865],
                [102.8872241, 21.7126807],
                [102.8883178, 21.7127733],
                [102.8891344, 21.7126265],
                [102.8898381, 21.7125043],
                [102.8905542, 21.7125003],
                [102.8915326, 21.7128012],
                [102.8931841, 21.713163],
                [102.8947501, 21.7136024],
                [102.8960105, 21.7138282],
                [102.8967104, 21.7139681],
                [102.8975496, 21.7141885],
                [102.8981212, 21.7144711],
                [102.8996429, 21.7160372],
                [102.9008299, 21.7174417],
                [102.9013824, 21.718078],
                [102.9017142, 21.718423],
                [102.9022312, 21.7186],
                [102.9027631, 21.7187115],
                [102.9033254, 21.7186663],
                [102.9046788, 21.7182901],
                [102.9055005, 21.7177761],
                [102.9063497, 21.7173149],
                [102.9068873, 21.7170334],
                [102.9070568, 21.7169569],
                [102.9072816, 21.7169598],
                [102.9085069, 21.7176568],
                [102.9106783, 21.7189689],
                [102.9134684, 21.7211932],
                [102.9140501, 21.7217511],
                [102.9145775, 21.7221772],
                [102.914914, 21.7222077],
                [102.9153659, 21.7220298],
                [102.9158777, 21.7215907],
                [102.9164449, 21.7212045],
                [102.9168409, 21.7209998],
                [102.9172071, 21.7209256],
                [102.9174863, 21.7210341],
                [102.9184019, 21.7218059],
                [102.9208545, 21.7240782],
                [102.9216606, 21.7246651],
                [102.9225541, 21.7250173],
                [102.9230853, 21.7251812],
                [102.9240957, 21.7252201],
                [102.9254741, 21.7250538],
                [102.9282195, 21.7245508],
                [102.9301291, 21.7245485],
                [102.9324572, 21.7247347],
                [102.9339304, 21.7248188],
                [102.93469, 21.7247234],
                [102.9351961, 21.7246773],
                [102.9357031, 21.7245787],
                [102.93632, 21.7246388],
                [102.9376333, 21.7251008],
                [102.9392225, 21.7259071],
                [102.9400879, 21.7262588],
                [102.9410843, 21.7263105],
                [102.9420111, 21.7262957],
                [102.9430502, 21.7263087],
                [102.9443952, 21.726509],
                [102.9455412, 21.7268902],
                [102.9467693, 21.7274298],
                [102.9476893, 21.7279131],
                [102.9486499, 21.7284887],
                [102.9494338, 21.7286558],
                [102.9503892, 21.7286152],
                [102.9515423, 21.7284983],
                [102.9523566, 21.7285084],
                [102.953284, 21.7284673],
                [102.9540982, 21.7284775],
                [102.9547137, 21.7286424],
                [102.9553967, 21.7290048],
                [102.955812, 21.7294294],
                [102.9561371, 21.7302723],
                [102.9561544, 21.731033],
                [102.9561151, 21.731819],
                [102.9560804, 21.7322905],
                [102.9560471, 21.7326571],
                [102.9560708, 21.732972],
                [102.9563045, 21.7333288],
                [102.9568878, 21.7337817],
                [102.9588116, 21.7347755],
                [102.9601698, 21.7360507],
                [102.9614138, 21.7374557],
                [102.9625036, 21.7388455],
                [102.9627197, 21.7394512],
                [102.9630673, 21.7406877],
                [102.9635861, 21.7417428],
                [102.9640357, 21.7427315],
                [102.9643952, 21.7431292],
                [102.9650636, 21.7435306],
                [102.9661244, 21.7439894],
                [102.9672902, 21.7449738],
                [102.9687635, 21.7460668],
                [102.9693634, 21.7463495],
                [102.9696427, 21.7464578],
                [102.9700359, 21.7464626],
                [102.9708525, 21.7463154],
                [102.9717832, 21.7460384],
                [102.9726578, 21.7457606],
                [102.9733071, 21.7455327],
                [102.9737878, 21.7453026],
                [102.9741014, 21.7449656],
                [102.9741621, 21.7448427],
                [102.9742171, 21.744731],
                [102.974251, 21.7443119],
                [102.9741203, 21.7436288],
                [102.9738256, 21.7426026],
                [102.9733903, 21.7416009],
                [102.9730225, 21.7407968],
                [102.9728092, 21.7399814],
                [102.9727127, 21.7388529],
                [102.9726892, 21.7375509],
                [102.9728762, 21.7364923],
                [102.9731562, 21.7362246],
                [102.9743045, 21.7357342],
                [102.975752, 21.7354162],
                [102.9767111, 21.7351133],
                [102.9772225, 21.7347001],
                [102.9774237, 21.7343616],
                [102.9776066, 21.7333414],
                [102.9778385, 21.7328198],
                [102.9786785, 21.7320043],
                [102.9792807, 21.7311202],
                [102.9799119, 21.7301577],
                [102.980564, 21.72972],
                [102.9814108, 21.7294158],
                [102.9823979, 21.7291132],
                [102.9830212, 21.7287275],
                [102.9839044, 21.7278206],
                [102.9843282, 21.7266328],
                [102.9847951, 21.7253801],
                [102.9852273, 21.724598],
                [102.9853755, 21.7240496],
                [102.9853247, 21.7236558],
                [102.9851891, 21.7233135],
                [102.9850246, 21.7230232],
                [102.9850293, 21.7226827],
                [102.9851734, 21.7224224],
                [102.9859098, 21.7219859],
                [102.988398, 21.7208107],
                [102.9885687, 21.7206556],
                [102.9884873, 21.7204449],
                [102.9883786, 21.7201816],
                [102.988299, 21.71984],
                [102.9883057, 21.7193683],
                [102.9883415, 21.7188185],
                [102.9887966, 21.7173995],
                [102.9887813, 21.7164814],
                [102.9887356, 21.7157204],
                [102.9886021, 21.7152205],
                [102.9883297, 21.714614],
                [102.9882368, 21.7142327],
                [102.9882149, 21.7137866],
                [102.9886232, 21.7126901],
                [102.9892011, 21.7115432],
                [102.9893753, 21.7111258],
                [102.9893801, 21.710785],
                [102.9892725, 21.7104427],
                [102.9889559, 21.7099799],
                [102.9885996, 21.7093463],
                [102.9885838, 21.7084546],
                [102.9887648, 21.7075651],
                [102.9889168, 21.7067277],
                [102.9889208, 21.7064393],
                [102.9888132, 21.7060971],
                [102.9886109, 21.7055046],
                [102.9881444, 21.7047123],
                [102.9873723, 21.7036802],
                [102.9863166, 21.7028544],
                [102.9851132, 21.7025513],
                [102.9840782, 21.7022502],
                [102.9833514, 21.7020054],
                [102.982657, 21.7014463],
                [102.9816404, 21.7008437],
                [102.9810022, 21.7002853],
                [102.9806161, 21.6997824],
                [102.9800108, 21.6988833],
                [102.979156, 21.6977453],
                [102.9784658, 21.6968978],
                [102.9784297, 21.6964646],
                [102.9784096, 21.6958874],
                [102.9784808, 21.6948131],
                [102.9787451, 21.6939772],
                [102.9792948, 21.6928301],
                [102.9797572, 21.6918916],
                [102.9798616, 21.6914602],
                [102.9797584, 21.6908033],
                [102.9797399, 21.6901212],
                [102.9798583, 21.6896769],
                [102.9801165, 21.6892867],
                [102.9805728, 21.688794],
                [102.9812502, 21.68854],
                [102.9819522, 21.6885486],
                [102.9834126, 21.6885401],
                [102.9845644, 21.6885017],
                [102.9851282, 21.6883512],
                [102.9856653, 21.6880955],
                [102.9861777, 21.6876034],
                [102.9872238, 21.686082],
                [102.9877354, 21.6856424],
                [102.9886969, 21.6851558],
                [102.9894037, 21.6848235],
                [102.9896883, 21.6845384],
                [102.989862, 21.6841734],
                [102.9899509, 21.6838336],
                [102.9899906, 21.6829949],
                [102.9896923, 21.6812212],
                [102.9892894, 21.679905],
                [102.9887474, 21.6784824],
                [102.9879985, 21.6768081],
                [102.9875937, 21.6756231],
                [102.9874107, 21.6746506],
                [102.9871697, 21.6738085],
                [102.9870278, 21.672902],
                [102.9869265, 21.6721141],
                [102.9868309, 21.6709066],
                [102.9866456, 21.6700914],
                [102.9864568, 21.6695384],
                [102.9859803, 21.6684706],
                [102.9853211, 21.6674137],
                [102.9852448, 21.6668358],
                [102.9851978, 21.6661796],
                [102.9852937, 21.6653416],
                [102.9853815, 21.6640708],
                [102.9856436, 21.6633921],
                [102.9861041, 21.6625848],
                [102.9864483, 21.6620644],
                [102.9866221, 21.6616732],
                [102.9867122, 21.6612547],
                [102.9866597, 21.6609918],
                [102.9864839, 21.6605046],
                [102.9858238, 21.6595263],
                [102.9848026, 21.6582552],
                [102.9843592, 21.6578302],
                [102.9837988, 21.6577447],
                [102.982422, 21.6578328],
                [102.9816625, 21.6579284],
                [102.9807631, 21.6579962],
                [102.980259, 21.6579114],
                [102.9800356, 21.6576694],
                [102.9799009, 21.6574089],
                [102.9799065, 21.657368],
                [102.9799636, 21.6572031],
                [102.9800917, 21.6571387],
                [102.9801485, 21.6571129],
                [102.9804617, 21.6569234],
                [102.9805143, 21.6568917],
                [102.9805706, 21.6566924],
                [102.9809142, 21.6563276],
                [102.9822222, 21.6555794],
                [102.9827069, 21.6552163],
                [102.9830792, 21.6548256],
                [102.9833253, 21.6543674],
                [102.983445, 21.6538683],
                [102.9834232, 21.6534201],
                [102.9832308, 21.6530487],
                [102.9829245, 21.6527551],
                [102.9823642, 21.6524848],
                [102.9813802, 21.6522357],
                [102.9805933, 21.6520153],
                [102.9801174, 21.6517461],
                [102.9799238, 21.6514802],
                [102.9798436, 21.651163],
                [102.979849, 21.6507678],
                [102.980186, 21.6498627],
                [102.9806487, 21.6490516],
                [102.9809945, 21.6485288],
                [102.9813989, 21.6478486],
                [102.981633, 21.6472453],
                [102.9816979, 21.64664],
                [102.9816645, 21.6459941],
                [102.9811469, 21.6446702],
                [102.9809817, 21.6443783],
                [102.9809594, 21.6439565],
                [102.981047, 21.6437467],
                [102.9812177, 21.6436433],
                [102.9816693, 21.6436224],
                [102.9823142, 21.6438938],
                [102.9840784, 21.6448376],
                [102.98478, 21.6451096],
                [102.9854842, 21.6451708],
                [102.9859088, 21.6450705],
                [102.9862505, 21.6448375],
                [102.9865384, 21.6444194],
                [102.9865985, 21.6441566],
                [102.9865772, 21.6436557],
                [102.9864146, 21.6431794],
                [102.9860332, 21.6421998],
                [102.9853577, 21.6410716],
                [102.9846896, 21.6404311],
                [102.9843002, 21.6400311],
                [102.9841911, 21.6397663],
                [102.9841948, 21.6395029],
                [102.9843673, 21.6392678],
                [102.9850244, 21.6386697],
                [102.9856786, 21.6382825],
                [102.9867159, 21.6377284],
                [102.9870022, 21.6374158],
                [102.9871473, 21.6371276],
                [102.9871235, 21.6368111],
                [102.986985, 21.636625],
                [102.9866216, 21.6363834],
                [102.9854104, 21.6362633],
                [102.9843947, 21.6362774],
                [102.9839126, 21.636456],
                [102.9835456, 21.6364779],
                [102.9832929, 21.6363957],
                [102.983042, 21.6361819],
                [102.9829039, 21.6359693],
                [102.9828245, 21.6355995],
                [102.9828032, 21.6350986],
                [102.9830383, 21.6344162],
                [102.9840265, 21.6333347],
                [102.9847136, 21.6326053],
                [102.9850583, 21.6321614],
                [102.9854066, 21.6314542],
                [102.9859277, 21.6304855],
                [102.9869444, 21.6293779],
                [102.9872036, 21.6289858],
                [102.9872629, 21.6287757],
                [102.9872091, 21.6285906],
                [102.9870704, 21.6284308],
                [102.9866209, 21.6282937],
                [102.9861444, 21.628077],
                [102.9857529, 21.6278351],
                [102.9854185, 21.6275412],
                [102.9851409, 21.6272217],
                [102.985005, 21.6268511],
                [102.9850079, 21.6266403],
                [102.9851676, 21.6263128],
                [102.9861348, 21.6257185],
                [102.9873873, 21.6248904],
                [102.9885578, 21.6238769],
                [102.9891555, 21.6234889],
                [102.9897261, 21.6230214],
                [102.9900131, 21.622656],
                [102.9902006, 21.6223553],
                [102.9901762, 21.6220915],
                [102.9899824, 21.6218256],
                [102.9895655, 21.6213726],
                [102.9887264, 21.6208618],
                [102.9880017, 21.6202469],
                [102.987557, 21.6197673],
                [102.9870907, 21.6188129],
                [102.9869567, 21.6183107],
                [102.9869219, 21.6177701],
                [102.9869281, 21.6173221],
                [102.9870445, 21.6170601],
                [102.987358, 21.6168267],
                [102.9877264, 21.6166994],
                [102.9883765, 21.6166018],
                [102.9889723, 21.6163456],
                [102.9892286, 21.6161642],
                [102.9893439, 21.6159811],
                [102.9893463, 21.6158231],
                [102.9892926, 21.6156116],
                [102.9890429, 21.6153187],
                [102.9888199, 21.6151316],
                [102.9883994, 21.6149421],
                [102.9878354, 21.6149352],
                [102.9871836, 21.6151645],
                [102.9867316, 21.6152117],
                [102.986394, 21.6151549],
                [102.9856395, 21.6146451],
                [102.9851671, 21.6141386],
                [102.9849884, 21.6138071],
                [102.9848807, 21.613437],
                [102.9849126, 21.6131738],
                [102.9851429, 21.612834],
                [102.9856566, 21.6123923],
                [102.9858614, 21.6118677],
                [102.9858983, 21.6112358],
                [102.9858772, 21.6107349],
                [102.9857452, 21.6100745],
                [102.9854393, 21.6087401],
                [102.9850312, 21.6076547],
                [102.9849856, 21.60689],
                [102.9849357, 21.6064151],
                [102.984969, 21.6060465],
                [102.9851004, 21.6057187],
                [102.9852725, 21.60551],
                [102.9856399, 21.6054618],
                [102.9868205, 21.6057396],
                [102.9878338, 21.6058837],
                [102.9881732, 21.6058087],
                [102.9884017, 21.6056007],
                [102.988602, 21.6053923],
                [102.9886332, 21.6051818],
                [102.9886638, 21.6049977],
                [102.9886118, 21.6046809],
                [102.9885319, 21.6043374],
                [102.9885349, 21.6041265],
                [102.9887638, 21.6038922],
                [102.9890186, 21.6038161],
                [102.9895276, 21.6037169],
                [102.9902922, 21.603489],
                [102.9907752, 21.6032314],
                [102.9910619, 21.6028922],
                [102.9912922, 21.6025524],
                [102.9913827, 21.6021319],
                [102.9914148, 21.6008279],
                [102.991463, 21.5993792],
                [102.9914148, 21.5986958],
                [102.9913963, 21.5981657],
                [102.9914559, 21.5975886],
                [102.9917186, 21.5972305],
                [102.9920626, 21.5965845],
                [102.9923584, 21.5956969],
                [102.9924975, 21.5949521],
                [102.9925627, 21.5939655],
                [102.9924949, 21.5932663],
                [102.992421, 21.5930246],
                [102.9922524, 21.5921805],
                [102.9923388, 21.5915317],
                [102.9926566, 21.5909096],
                [102.992842, 21.5905507],
                [102.9928735, 21.590142],
                [102.9928265, 21.5898044],
                [102.9926022, 21.589248],
                [102.992407, 21.5884514],
                [102.9922632, 21.5876552],
                [102.9920975, 21.5865941],
                [102.9919132, 21.5859338],
                [102.9915598, 21.5853996],
                [102.9911279, 21.5849367],
                [102.9904374, 21.5845429],
                [102.9893334, 21.5842164],
                [102.9883837, 21.58394],
                [102.9874058, 21.5838078],
                [102.9867912, 21.5837834],
                [102.9867406, 21.5837748],
                [102.9855793, 21.5833637],
                [102.9852046, 21.5828688],
                [102.9848794, 21.5822409],
                [102.984698, 21.5815702],
                [102.9845629, 21.5809891],
                [102.984294, 21.5797378],
                [102.9829909, 21.5774043],
                [102.9811566, 21.5755546],
                [102.9801037, 21.5740933],
                [102.9794547, 21.5727483],
                [102.9789432, 21.571806],
                [102.9773356, 21.5708061],
                [102.9735972, 21.5687103],
                [102.970039, 21.5673744],
                [102.9693561, 21.5667643],
                [102.9686526, 21.5659089],
                [102.9678601, 21.5646066],
                [102.9668428, 21.5623212],
                [102.9661308, 21.5603737],
                [102.964677, 21.5586175],
                [102.9631695, 21.5573065],
                [102.961468, 21.556216],
                [102.9600056, 21.5550839],
                [102.9593938, 21.554497],
                [102.9590677, 21.5539581],
                [102.958789, 21.5534199],
                [102.9587476, 21.5529736],
                [102.9588077, 21.5520829],
                [102.959141, 21.5504155],
                [102.9598272, 21.5490868],
                [102.960551, 21.5484717],
                [102.9612737, 21.5479457],
                [102.9615195, 21.5474139],
                [102.9615276, 21.5468346],
                [102.9612502, 21.5462071],
                [102.9599241, 21.545567],
                [102.9585986, 21.5448821],
                [102.9579615, 21.5444064],
                [102.9568937, 21.542343],
                [102.9556297, 21.5406784],
                [102.9545952, 21.5396405],
                [102.9521796, 21.5384966],
                [102.9490533, 21.5370319],
                [102.947187, 21.5358278],
                [102.9453492, 21.5342898],
                [102.9439863, 21.5328913],
                [102.9430943, 21.5318997],
                [102.9427694, 21.5312717],
                [102.9425901, 21.5304672],
                [102.9426517, 21.5294874],
                [102.9424086, 21.528125],
                [102.9421332, 21.527364],
                [102.9415217, 21.5267769],
                [102.9398196, 21.5257754],
                [102.9373465, 21.5253437],
                [102.9357284, 21.5251454],
                [102.93387, 21.5250778],
                [102.9327323, 21.524618],
                [102.932023, 21.5242081],
                [102.9316976, 21.5236247],
                [102.9317077, 21.5229117],
                [102.9319586, 21.5220234],
                [102.9327178, 21.5205842],
                [102.9328741, 21.5196501],
                [102.9329209, 21.5186213],
                [102.9331158, 21.5172474],
                [102.9331148, 21.5164974],
                [102.9328802, 21.5165108],
                [102.9311522, 21.5162933],
                [102.9292953, 21.5165303],
                [102.9282466, 21.5165173],
                [102.9276315, 21.5161977],
                [102.9264081, 21.5150682],
                [102.9243864, 21.513082],
                [102.9231439, 21.511618],
                [102.9220585, 21.5108468],
                [102.9200186, 21.5101528],
                [102.9190717, 21.5096953],
                [102.9187914, 21.5092906],
                [102.9187018, 21.5088885],
                [102.9187094, 21.5083537],
                [102.9189604, 21.5074654],
                [102.9193093, 21.5064001],
                [102.9194649, 21.5055106],
                [102.9193035, 21.5051297],
                [102.9185468, 21.5047191],
                [102.916219, 21.5041553],
                [102.9147454, 21.5038695],
                [102.9138437, 21.5035908],
                [102.91328, 21.5030043],
                [102.913097, 21.5024672],
                [102.9131079, 21.5017096],
                [102.9132591, 21.5011321],
                [102.9134953, 21.5000533],
                [102.9136449, 21.4990483],
                [102.9136456, 21.4990021],
                [102.9136547, 21.4983583],
                [102.9137345, 21.4978585],
                [102.9140392, 21.4972063],
                [102.9147211, 21.49602],
                [102.9150143, 21.4955899],
                [102.9151185, 21.4954371],
                [102.9151253, 21.4949612],
                [102.9148623, 21.4945099],
                [102.9143468, 21.4937581],
                [102.9140618, 21.4930296],
                [102.9139575, 21.4925795],
                [102.9138167, 21.4920946],
                [102.9137218, 21.4915561],
                [102.9135474, 21.4911901],
                [102.9131917, 21.4909337],
                [102.9128771, 21.4906674],
                [102.9123746, 21.48966],
                [102.9121634, 21.4889325],
                [102.9120295, 21.4879642],
                [102.9117854, 21.4877245],
                [102.9115792, 21.4874981],
                [102.9112647, 21.4872297],
                [102.9106784, 21.4869116],
                [102.9101268, 21.4867667],
                [102.9092798, 21.486618],
                [102.9088382, 21.486509],
                [102.9086178, 21.4864371],
                [102.908325, 21.4862608],
                [102.9080695, 21.4860505],
                [102.9078904, 21.4856686],
                [102.9078564, 21.485461],
                [102.9079416, 21.4846681],
                [102.908099, 21.4839796],
                [102.9084254, 21.4829793],
                [102.909125, 21.4822041],
                [102.9093398, 21.481843],
                [102.9093441, 21.481535],
                [102.9091976, 21.4813092],
                [102.9089905, 21.481022],
                [102.9085525, 21.4806714],
                [102.9082263, 21.480253],
                [102.907869, 21.4794201],
                [102.9073661, 21.4784471],
                [102.9070353, 21.4783394],
                [102.9057999, 21.4781592],
                [102.9048311, 21.477867],
                [102.9041167, 21.4775781],
                [102.9037616, 21.4772937],
                [102.9034509, 21.4769106],
                [102.9033089, 21.4767001],
                [102.9030903, 21.4763739],
                [102.9029364, 21.4762155],
                [102.9025425, 21.4767185],
                [102.9023619, 21.4772798],
                [102.9020627, 21.4775521],
                [102.9017275, 21.4777551],
                [102.9010616, 21.4778503],
                [102.9003229, 21.4778756],
                [102.89988, 21.4778699],
                [102.8994428, 21.4778554],
                [102.8985305, 21.4780383],
                [102.8977732, 21.4779244],
                [102.8971277, 21.477812],
                [102.8967794, 21.477831],
                [102.8963349, 21.477929],
                [102.8957105, 21.4777139],
                [102.8951548, 21.477845],
                [102.8949334, 21.4778422],
                [102.8942825, 21.4775945],
                [102.8938378, 21.4772809],
                [102.8934787, 21.4772764],
                [102.8932081, 21.477357],
                [102.8929331, 21.4777455],
                [102.8926586, 21.4781059],
                [102.8924158, 21.4783268],
                [102.8920846, 21.4784626],
                [102.890883, 21.4787554],
                [102.8901642, 21.4787743],
                [102.8898649, 21.4787706],
                [102.8896278, 21.4785996],
                [102.8895728, 21.4782629],
                [102.8895186, 21.4778704],
                [102.8892524, 21.477643],
                [102.8888047, 21.4775534],
                [102.8880442, 21.4773477],
                [102.8872721, 21.4769181],
                [102.8863127, 21.4765942],
                [102.8859836, 21.476383],
                [102.8858747, 21.4762435],
                [102.8858788, 21.4759674],
                [102.8861242, 21.4757115],
                [102.887178, 21.47537],
                [102.8886347, 21.4737718],
                [102.8895128, 21.473083],
                [102.8896996, 21.4725815],
                [102.8897202, 21.4721898],
                [102.8897307, 21.471462],
                [102.889947, 21.4709889],
                [102.8904321, 21.470575],
                [102.8908566, 21.4701885],
                [102.8910713, 21.4698273],
                [102.8910468, 21.469463],
                [102.890664, 21.4690102],
                [102.8893516, 21.4676499],
                [102.8888505, 21.4671117],
                [102.8884326, 21.4665993],
                [102.888293, 21.4659475],
                [102.8881004, 21.4655735],
                [102.8878569, 21.465199],
                [102.8875136, 21.4643233],
                [102.8873387, 21.464013],
                [102.8869236, 21.4637278],
                [102.886387, 21.4635811],
                [102.8855514, 21.4634025],
                [102.8851357, 21.4631733],
                [102.8847519, 21.4628046],
                [102.8845537, 21.4620182],
                [102.8846543, 21.4612636],
                [102.885201, 21.4608207],
                [102.8858332, 21.4603069],
                [102.886195, 21.4592054],
                [102.8862909, 21.4587475],
                [102.8861196, 21.4582445],
                [102.8857341, 21.4580017],
                [102.8853503, 21.4576329],
                [102.8851756, 21.4572948],
                [102.8851873, 21.456483],
                [102.8852708, 21.4558822],
                [102.8855781, 21.4548083],
                [102.8857633, 21.4544187],
                [102.8860386, 21.4540163],
                [102.8863315, 21.4534181],
                [102.8863676, 21.4529847],
                [102.8862651, 21.4523113],
                [102.8861232, 21.4517777],
                [102.8858674, 21.4508366],
                [102.8855012, 21.4497681],
                [102.8849744, 21.4489357],
                [102.8844305, 21.4482569],
                [102.883957, 21.4478869],
                [102.8834826, 21.447573],
                [102.8828723, 21.4473413],
                [102.8823049, 21.4472642],
                [102.8818034, 21.4472718],
                [102.8813529, 21.4473781],
                [102.8810367, 21.447514],
                [102.880677, 21.4475515],
                [102.8802283, 21.4475317],
                [102.8798407, 21.4474289],
                [102.8792885, 21.4473239],
                [102.8785915, 21.4468812],
                [102.8779061, 21.4461586],
                [102.8776712, 21.4458476],
                [102.8773932, 21.4454102],
                [102.8772503, 21.4449464],
                [102.8772018, 21.443644],
                [102.877291, 21.4426514],
                [102.8773802, 21.4416586],
                [102.8774858, 21.4410861],
                [102.8776989, 21.4408369],
                [102.8782294, 21.4403677],
                [102.8786704, 21.4398834],
                [102.878734, 21.4396182],
                [102.8787375, 21.4393802],
                [102.878594, 21.4389586],
                [102.8782219, 21.4382958],
                [102.8779711, 21.4380548],
                [102.8775574, 21.4376856],
                [102.8772054, 21.4371912],
                [102.8770344, 21.4366012],
                [102.8767358, 21.4360374],
                [102.8764729, 21.4355862],
                [102.8762371, 21.4353452],
                [102.876119, 21.4352317],
                [102.8757915, 21.4351156],
                [102.8754639, 21.4349995],
                [102.8751682, 21.4347578],
                [102.8749176, 21.4344885],
                [102.8746389, 21.4341072],
                [102.8741682, 21.4335412],
                [102.8737681, 21.4332702],
                [102.8731791, 21.4331368],
                [102.8724496, 21.4328755],
                [102.8718245, 21.4326436],
                [102.8714987, 21.4324155],
                [102.8712038, 21.4321038],
                [102.8710887, 21.4317945],
                [102.8710509, 21.431304],
                [102.8709381, 21.4308266],
                [102.8706715, 21.4301234],
                [102.8704813, 21.429827],
                [102.8701864, 21.4295293],
                [102.869875, 21.4293294],
                [102.8694905, 21.4290165],
                [102.8693902, 21.4287213],
                [102.8693953, 21.4283575],
                [102.8694394, 21.4278961],
                [102.8694454, 21.4274902],
                [102.8696206, 21.4267506],
                [102.870043, 21.4260001],
                [102.8710907, 21.4249496],
                [102.8717102, 21.4245375],
                [102.8723718, 21.424308],
                [102.8730768, 21.4241909],
                [102.8738847, 21.4241872],
                [102.8748179, 21.424325],
                [102.8757889, 21.4244354],
                [102.8764447, 21.4246116],
                [102.8774476, 21.4245824],
                [102.8785715, 21.4244706],
                [102.8798341, 21.4245846],
                [102.8806084, 21.4248463],
                [102.8812042, 21.4250359],
                [102.8814873, 21.4251234],
                [102.8817396, 21.4252667],
                [102.8820644, 21.4255647],
                [102.8824779, 21.4259478],
                [102.882864, 21.4261487],
                [102.8831765, 21.4262646],
                [102.8838278, 21.4262309],
                [102.8841572, 21.4262211],
                [102.8846512, 21.4262133],
                [102.8850989, 21.4262889],
                [102.885455, 21.4265033],
                [102.8858561, 21.4267044],
                [102.8861836, 21.4268206],
                [102.8864504, 21.4270263],
                [102.88695, 21.4272305],
                [102.8876592, 21.4275611],
                [102.8879211, 21.4277376],
                [102.8881318, 21.4277897],
                [102.888342, 21.4278913],
                [102.8885977, 21.4280268],
                [102.8888814, 21.4280584],
                [102.8891809, 21.4280482],
                [102.8896835, 21.4281284],
                [102.8899185, 21.4282261],
                [102.8901871, 21.4283103],
                [102.8904741, 21.4285862],
                [102.8906291, 21.4288354],
                [102.8907709, 21.4293421],
                [102.8909227, 21.4302258],
                [102.8910096, 21.4304369],
                [102.8911854, 21.430691],
                [102.8914804, 21.4309887],
                [102.891788, 21.4314545],
                [102.8919617, 21.4318626],
                [102.8920245, 21.4321713],
                [102.892317, 21.432651],
                [102.8925893, 21.4329014],
                [102.8928451, 21.4330773],
                [102.8930656, 21.433149],
                [102.8933588, 21.4332908],
                [102.8936156, 21.4333976],
                [102.89402, 21.4335063],
                [102.8943511, 21.4335795],
                [102.8945346, 21.4336507],
                [102.8947555, 21.4336881],
                [102.8951058, 21.433864],
                [102.8955791, 21.434043],
                [102.8960141, 21.4341472],
                [102.8962978, 21.4342253],
                [102.8967751, 21.4344039],
                [102.8974, 21.4345843],
                [102.8979874, 21.4347989],
                [102.8982467, 21.4349651],
                [102.8987524, 21.4354989],
                [102.8991938, 21.435608],
                [102.8994515, 21.4356457],
                [102.8998154, 21.4355557],
                [102.9003236, 21.4351663],
                [102.9005943, 21.4347245],
                [102.9006786, 21.4343792],
                [102.9006843, 21.4339834],
                [102.9006499, 21.4335419],
                [102.9006265, 21.4328151],
                [102.9007889, 21.4324521],
                [102.9010127, 21.4322822],
                [102.9012362, 21.432147],
                [102.9014978, 21.4319087],
                [102.9017622, 21.4314977],
                [102.9019879, 21.4311898],
                [102.9023269, 21.4307108],
                [102.9029607, 21.43027],
                [102.9033307, 21.4302056],
                [102.9039919, 21.430421],
                [102.9040647, 21.4304909],
                [102.9041375, 21.4305608],
                [102.904354, 21.4309088],
                [102.9044932, 21.4314974],
                [102.9045281, 21.4316359],
                [102.9046659, 21.432328],
                [102.9049123, 21.4331596],
                [102.9049708, 21.4336909],
                [102.9051626, 21.4344515],
                [102.9054958, 21.4347716],
                [102.9058659, 21.4348709],
                [102.9063148, 21.4349044],
                [102.9067, 21.4349092],
                [102.9071172, 21.4349144],
                [102.908295, 21.4350593],
                [102.9086651, 21.4351587],
                [102.9093827, 21.4353005],
                [102.9096934, 21.4355424],
                [102.9100187, 21.4358125],
                [102.9103205, 21.4361662],
                [102.910658, 21.4366323],
                [102.9108932, 21.4369292],
                [102.9111296, 21.4371421],
                [102.9115457, 21.4373433],
                [102.912472, 21.4374529],
                [102.9130702, 21.4374743],
                [102.9134132, 21.4375626],
                [102.9136341, 21.4378033],
                [102.9136452, 21.4380834],
                [102.9136419, 21.4383073],
                [102.9135092, 21.4386975],
                [102.9131585, 21.4391551],
                [102.9128521, 21.4396553],
                [102.9127731, 21.4399483],
                [102.9128003, 21.4401445],
                [102.9129469, 21.4403564],
                [102.9131836, 21.4405413],
                [102.9133869, 21.4408461],
                [102.913779, 21.4411726],
                [102.9140137, 21.4413982],
                [102.9142234, 21.4415491],
                [102.9144963, 21.4417714],
                [102.91464, 21.4420495],
                [102.914707, 21.4425335],
                [102.9147395, 21.4428446],
                [102.9147724, 21.4431212],
                [102.9147685, 21.4433973],
                [102.914659, 21.4436112],
                [102.9144817, 21.4439729],
                [102.9143442, 21.4441672],
                [102.9142089, 21.4444185],
                [102.9140982, 21.4447634],
                [102.9140376, 21.4453069],
                [102.9141934, 21.4455068],
                [102.9143504, 21.4456324],
                [102.9145601, 21.4457587],
                [102.9148881, 21.4459097],
                [102.915036, 21.4460375],
                [102.9153379, 21.4463773],
                [102.9155139, 21.4466314],
                [102.9156051, 21.4468597],
                [102.9157041, 21.4476896],
                [102.9162372, 21.4487233],
                [102.9164968, 21.4490728],
                [102.9167594, 21.4491998],
                [102.916997, 21.4492276],
                [102.9172883, 21.4492064],
                [102.9177859, 21.4492343],
                [102.9184124, 21.4493112],
                [102.919001, 21.4494566],
                [102.9193689, 21.4495302],
                [102.9195828, 21.4496803],
                [102.9198443, 21.4498468],
                [102.9203162, 21.450405],
                [102.920417, 21.4505318],
                [102.9204473, 21.4506786],
                [102.9204303, 21.4508322],
                [102.9201544, 21.4512574],
                [102.919966, 21.451678],
                [102.919775, 21.4521243],
                [102.9197363, 21.4522619],
                [102.9197337, 21.4524344],
                [102.9197686, 21.452573],
                [102.9199522, 21.4526444],
                [102.9204688, 21.4527008],
                [102.9210342, 21.4526233],
                [102.921507, 21.4527136],
                [102.921888, 21.4529084],
                [102.9220884, 21.4531009],
                [102.9221967, 21.453419],
                [102.9222582, 21.4538631],
                [102.9223195, 21.4543072],
                [102.9225808, 21.4549861],
                [102.9230143, 21.4554559],
                [102.9235723, 21.4559063],
                [102.9241727, 21.4562968],
                [102.9250244, 21.4567809],
                [102.9259441, 21.4569993],
                [102.9272012, 21.4571243],
                [102.9277981, 21.4571833],
                [102.9289613, 21.4571343],
                [102.9300388, 21.4571882],
                [102.9307169, 21.4572644],
                [102.9314817, 21.4574222],
                [102.9317459, 21.4574502],
                [102.9322109, 21.4575948],
                [102.9326155, 21.4577034],
                [102.9328359, 21.4577752],
                [102.9332172, 21.4578838],
                [102.9336401, 21.4580569],
                [102.9341116, 21.4581575],
                [102.9346164, 21.4582902],
                [102.9353239, 21.4584252],
                [102.9365518, 21.4586495],
                [102.9373475, 21.458737],
                [102.9388863, 21.4587164],
                [102.9397782, 21.4585195],
                [102.9405858, 21.4580168],
                [102.9418176, 21.457103],
                [102.9422312, 21.4565802],
                [102.942374, 21.4560542],
                [102.9422769, 21.4557362],
                [102.9419018, 21.4551194],
                [102.9411484, 21.4541178],
                [102.9399427, 21.4523717],
                [102.9394316, 21.4517952],
                [102.9392091, 21.4515603],
                [102.938919, 21.4513245],
                [102.9385375, 21.451172],
                [102.9382227, 21.4510837],
                [102.9379293, 21.4510801],
                [102.9376838, 21.450887],
                [102.9376446, 21.4504643],
                [102.9375388, 21.4499561],
                [102.9371593, 21.4496559],
                [102.9364882, 21.4492255],
                [102.9362627, 21.4490833],
                [102.9358393, 21.4488162],
                [102.9349986, 21.4484048],
                [102.9349345, 21.4481506],
                [102.9349395, 21.4477917],
                [102.9349244, 21.4472637],
                [102.9348163, 21.4469246],
                [102.9345286, 21.4465198],
                [102.9343331, 21.4459686],
                [102.9343421, 21.4453352],
                [102.9343267, 21.4448283],
                [102.9342864, 21.44449],
                [102.9340636, 21.4442761],
                [102.933704, 21.4441661],
                [102.93341, 21.4442047],
                [102.9331822, 21.4443496],
                [102.9329732, 21.4447693],
                [102.9328204, 21.4451231],
                [102.9328099, 21.4451473],
                [102.9325764, 21.4456934],
                [102.9321868, 21.4461109],
                [102.9319587, 21.446277],
                [102.9316883, 21.4462524],
                [102.9315553, 21.4460819],
                [102.9313569, 21.4457417],
                [102.9310969, 21.4449784],
                [102.9307684, 21.4442566],
                [102.9304684, 21.4439149],
                [102.9300692, 21.4434245],
                [102.92976, 21.442935],
                [102.9296086, 21.4424686],
                [102.9296157, 21.441962],
                [102.929601, 21.4414129],
                [102.9294927, 21.4410949],
                [102.9289896, 21.4407508],
                [102.9285661, 21.4403654],
                [102.9284121, 21.4400891],
                [102.9283726, 21.4396875],
                [102.9283806, 21.4391175],
                [102.9283719, 21.4381462],
                [102.9283133, 21.4374909],
                [102.9281602, 21.4371513],
                [102.9279589, 21.4370221],
                [102.927577, 21.4368907],
                [102.9272395, 21.436802],
                [102.9269246, 21.4367348],
                [102.92659, 21.4364562],
                [102.9263929, 21.4360315],
                [102.9263754, 21.4356723],
                [102.9262341, 21.4352906],
                [102.9257712, 21.4345037],
                [102.9255541, 21.4331074],
                [102.9255377, 21.4326639],
                [102.9253598, 21.4324716],
                [102.9251146, 21.4322575],
                [102.9246221, 21.4319768],
                [102.9240635, 21.4315688],
                [102.9237293, 21.4312691],
                [102.9235333, 21.4307599],
                [102.9234764, 21.4299992],
                [102.9236118, 21.4291985],
                [102.923842, 21.4288846],
                [102.9238682, 21.4286316],
                [102.9238049, 21.4283142],
                [102.9235379, 21.4280364],
                [102.9232717, 21.4277164],
                [102.9228937, 21.4273105],
                [102.9224963, 21.4266933],
                [102.9222133, 21.4259509],
                [102.9221436, 21.4252954],
                [102.922196, 21.4247894],
                [102.9223204, 21.4239675],
                [102.9222819, 21.4235026],
                [102.922018, 21.4230137],
                [102.9218213, 21.4225678],
                [102.9216891, 21.4215316],
                [102.9216755, 21.4208982],
                [102.9215453, 21.4205375],
                [102.9212778, 21.420302],
                [102.9208306, 21.4200009],
                [102.920225, 21.41974],
                [102.9195089, 21.4193089],
                [102.9190188, 21.4188594],
                [102.9186873, 21.4183697],
                [102.9185517, 21.4175868],
                [102.9185575, 21.4171857],
                [102.9184104, 21.4164027],
                [102.9181823, 21.4149853],
                [102.9181655, 21.4137816],
                [102.9181763, 21.4130217],
                [102.9182002, 21.4113329],
                [102.9184414, 21.410238],
                [102.9185602, 21.4098173],
                [102.9190961, 21.4094228],
                [102.9195049, 21.4092378],
                [102.9198238, 21.4090306],
                [102.9200302, 21.4088009],
                [102.9200788, 21.4085481],
                [102.9200611, 21.4082101],
                [102.9199315, 21.4078074],
                [102.9195989, 21.4073809],
                [102.9192211, 21.4069751],
                [102.9187494, 21.4068215],
                [102.9181408, 21.4067717],
                [102.917534, 21.4065952],
                [102.9168162, 21.4062908],
                [102.9160465, 21.4056689],
                [102.9156476, 21.4051572],
                [102.9153181, 21.4045197],
                [102.9151021, 21.4038414],
                [102.9147706, 21.4033517],
                [102.914461, 21.4029045],
                [102.9141276, 21.4025414],
                [102.9134687, 21.4020687],
                [102.912663, 21.4016153],
                [102.9121042, 21.4012283],
                [102.9113004, 21.4006271],
                [102.9104516, 21.4000253],
                [102.9098509, 21.3994056],
                [102.9094101, 21.3986822],
                [102.9092718, 21.3980893],
                [102.9091641, 21.3977291],
                [102.9091485, 21.3972432],
                [102.9091448, 21.395913],
                [102.9091545, 21.3952376],
                [102.9089042, 21.3945309],
                [102.9087402, 21.3943732],
                [102.9084555, 21.3939422],
                [102.9080074, 21.3933145],
                [102.9078883, 21.3929243],
                [102.9075631, 21.3924148],
                [102.9067711, 21.3925993],
                [102.9063949, 21.3927501],
                [102.9058838, 21.3927898],
                [102.9052333, 21.3925072],
                [102.9044956, 21.3920336],
                [102.9034442, 21.391387],
                [102.9025647, 21.3905738],
                [102.9022987, 21.3902326],
                [102.9021233, 21.3898714],
                [102.9018629, 21.3891504],
                [102.9015104, 21.3885548],
                [102.9010662, 21.3880636],
                [102.9006423, 21.3877205],
                [102.8995795, 21.387095],
                [102.8991103, 21.3867723],
                [102.8987746, 21.3865781],
                [102.8983469, 21.3865093],
                [102.8981214, 21.3865066],
                [102.8976467, 21.3865639],
                [102.8971457, 21.3867191],
                [102.8968238, 21.3867681],
                [102.8966543, 21.3867305],
                [102.8965063, 21.3865167],
                [102.896188, 21.3860178],
                [102.8961038, 21.3855946],
                [102.8961118, 21.3850247],
                [102.8962607, 21.3840975],
                [102.8965256, 21.3829184],
                [102.8968413, 21.3821412],
                [102.8969397, 21.3815724],
                [102.8968793, 21.3810649],
                [102.8967989, 21.3803672],
                [102.8968217, 21.3795653],
                [102.896939, 21.3792499],
                [102.897309, 21.3786213],
                [102.8979063, 21.3778686],
                [102.8985774, 21.3766947],
                [102.8986469, 21.3761676],
                [102.8986913, 21.3758305],
                [102.8986768, 21.3752603],
                [102.8986164, 21.3747528],
                [102.8984438, 21.3742017],
                [102.8980234, 21.3736264],
                [102.8968859, 21.3719231],
                [102.8961505, 21.3712805],
                [102.8950886, 21.3698103],
                [102.894385, 21.3685347],
                [102.8941883, 21.3680889],
                [102.8942822, 21.3678367],
                [102.8945314, 21.3677554],
                [102.894892, 21.367781],
                [102.8952968, 21.3678705],
                [102.8957686, 21.3680031],
                [102.8964901, 21.3680333],
                [102.8967392, 21.3679731],
                [102.8970129, 21.3677654],
                [102.897549, 21.3673498],
                [102.8986906, 21.3663929],
                [102.8989432, 21.3660794],
                [102.8989706, 21.365742],
                [102.8988863, 21.3653186],
                [102.8987565, 21.364937],
                [102.8983552, 21.3638129],
                [102.8982479, 21.3634315],
                [102.8979591, 21.3631112],
                [102.8973767, 21.3628295],
                [102.8965256, 21.3623965],
                [102.8946437, 21.3614862],
                [102.8941526, 21.361121],
                [102.893645, 21.3603336],
                [102.8934935, 21.3598883],
                [102.8935122, 21.3593607],
                [102.8936749, 21.3590249],
                [102.8939953, 21.3587123],
                [102.8943366, 21.3585054],
                [102.8952424, 21.3582634],
                [102.8961497, 21.3579159],
                [102.8966074, 21.3574571],
                [102.8967475, 21.3571211],
                [102.89679, 21.3565093],
                [102.8967721, 21.3561923],
                [102.8966398, 21.3559796],
                [102.8961724, 21.3555304],
                [102.8956153, 21.3550589],
                [102.8947673, 21.3544148],
                [102.894146, 21.3536892],
                [102.8938622, 21.3530312],
                [102.8937219, 21.352586],
                [102.8938381, 21.3507718],
                [102.8937662, 21.3487018],
                [102.8937759, 21.3480263],
                [102.893736, 21.3476458],
                [102.8934709, 21.3472624],
                [102.8929817, 21.3467706],
                [102.8924254, 21.3462358],
                [102.8922294, 21.3457478],
                [102.8922138, 21.3452619],
                [102.8922772, 21.3447771],
                [102.8928614, 21.3433488],
                [102.8931459, 21.3423811],
                [102.8932221, 21.3417909],
                [102.8931827, 21.3413893],
                [102.8929513, 21.3410063],
                [102.8926176, 21.3406855],
                [102.8920598, 21.3402562],
                [102.8914345, 21.3398261],
                [102.8910108, 21.339483],
                [102.8907255, 21.3389304],
                [102.8906053, 21.3378733],
                [102.8906819, 21.3372408],
                [102.8908252, 21.3366937],
                [102.8908178, 21.3364191],
                [102.890826, 21.3358492],
                [102.8908089, 21.3354689],
                [102.8906347, 21.3350234],
                [102.8903717, 21.3344922],
                [102.889863, 21.3337892],
                [102.8894219, 21.3330869],
                [102.8893055, 21.3325576],
                [102.8894463, 21.3321793],
                [102.8896982, 21.331908],
                [102.8899723, 21.3316581],
                [102.8904271, 21.3314104],
                [102.8910609, 21.3312496],
                [102.8915383, 21.330981],
                [102.891904, 21.3306478],
                [102.8922029, 21.3302504],
                [102.8923127, 21.3296606],
                [102.8923212, 21.3290695],
                [102.8924656, 21.328438],
                [102.8929039, 21.3277467],
                [102.8933386, 21.3273087],
                [102.8940023, 21.3266415],
                [102.8943618, 21.3259492],
                [102.8947307, 21.3253838],
                [102.8951697, 21.3246503],
                [102.8953112, 21.3242087],
                [102.8953855, 21.3237452],
                [102.8955942, 21.3233466],
                [102.8961448, 21.322699],
                [102.8967193, 21.3219461],
                [102.8974327, 21.3209416],
                [102.8981038, 21.3197466],
                [102.8986223, 21.3189634],
                [102.8991274, 21.3183364],
                [102.8997473, 21.3175629],
                [102.8999105, 21.3171848],
                [102.8999556, 21.3169377],
                [102.8997597, 21.3166975],
                [102.8994478, 21.3164191],
                [102.8987154, 21.3155865],
                [102.8982519, 21.3148839],
                [102.8981688, 21.3143762],
                [102.8981759, 21.3138907],
                [102.8984206, 21.3133237],
                [102.8989275, 21.31257],
                [102.8991374, 21.312087],
                [102.8991412, 21.3118125],
                [102.8989544, 21.3114724],
                [102.8984675, 21.310833],
                [102.8981152, 21.3102374],
                [102.8980563, 21.3096243],
                [102.8980706, 21.3086111],
                [102.8980778, 21.3081045],
                [102.8979373, 21.3076804],
                [102.8976733, 21.3072126],
                [102.8975875, 21.3068949],
                [102.8973751, 21.3059844],
                [102.8973402, 21.305266],
                [102.8972127, 21.3047156],
                [102.8969494, 21.3042056],
                [102.8965929, 21.3039055],
                [102.8962346, 21.3037321],
                [102.8958978, 21.3036223],
                [102.8951767, 21.3035922],
                [102.8946347, 21.3036488],
                [102.8936287, 21.3038261],
                [102.8929977, 21.3037971],
                [102.8923457, 21.3036623],
                [102.890999, 21.3032231],
                [102.8897888, 21.3027013],
                [102.8890273, 21.3023328],
                [102.8879243, 21.301411],
                [102.8874557, 21.3010674],
                [102.886716, 21.3007625],
                [102.8861109, 21.3005015],
                [102.8855063, 21.3001984],
                [102.8849917, 21.2999175],
                [102.8844528, 21.2997628],
                [102.8842044, 21.2997809],
                [102.8839321, 21.299904],
                [102.8836355, 21.3001538],
                [102.8830645, 21.3006533],
                [102.8825631, 21.301027],
                [102.8821542, 21.3012329],
                [102.8817582, 21.3013336],
                [102.8813064, 21.3013911],
                [102.8806066, 21.3014457],
                [102.879837, 21.3016471],
                [102.8790875, 21.3020177],
                [102.8785864, 21.3023703],
                [102.8781775, 21.3025763],
                [102.8778169, 21.3025718],
                [102.8774342, 21.3025247],
                [102.8766697, 21.3023672],
                [102.8757821, 21.3021449],
                [102.8752218, 21.3019056],
                [102.8745294, 21.3014534],
                [102.8739485, 21.3010872],
                [102.8734771, 21.3009334],
                [102.8727803, 21.3007769],
                [102.8720131, 21.3008094],
                [102.8710648, 21.3009029],
                [102.8696331, 21.3008848],
                [102.8684582, 21.3010599],
                [102.8677352, 21.3011563],
                [102.867127, 21.3011064],
                [102.8666337, 21.3009102],
                [102.8660948, 21.3007555],
                [102.8656892, 21.3007292],
                [102.864944, 21.3008043],
                [102.8633301, 21.3009315],
                [102.8627445, 21.3008819],
                [102.8622731, 21.300728],
                [102.8615119, 21.3003595],
                [102.8607093, 21.2997159],
                [102.859347, 21.2988118],
                [102.8583157, 21.2983975],
                [102.8576187, 21.298262],
                [102.8571127, 21.2981711],
                [102.8565943, 21.2981644],
                [102.855757, 21.298365],
                [102.8546665, 21.298921],
                [102.8537104, 21.2995423],
                [102.8529618, 21.2998494],
                [102.8526012, 21.2998449],
                [102.8522198, 21.2997133],
                [102.8517972, 21.2993067],
                [102.8513364, 21.2984352],
                [102.851198, 21.2978634],
                [102.8510268, 21.2972489],
                [102.85093, 21.2961499],
                [102.8507738, 21.2944799],
                [102.8507027, 21.2939513],
                [102.8504448, 21.2930823],
                [102.8499434, 21.2918936],
                [102.8493723, 21.2908517],
                [102.8490628, 21.2904255],
                [102.8486614, 21.2901248],
                [102.8483028, 21.2899725],
                [102.8478758, 21.2898825],
                [102.8472902, 21.2898329],
                [102.8468616, 21.2898485],
                [102.8463197, 21.2899049],
                [102.8458203, 21.2901308],
                [102.845317, 21.290631],
                [102.8449361, 21.2912383],
                [102.8445366, 21.2923522],
                [102.844283, 21.292729],
                [102.8441021, 21.2927689],
                [102.8436512, 21.2927631],
                [102.8433365, 21.2926957],
                [102.8427087, 21.2924555],
                [102.8418595, 21.2919379],
                [102.8410151, 21.2910826],
                [102.8403307, 21.2900814],
                [102.8400386, 21.2892544],
                [102.8396465, 21.2882992],
                [102.8393644, 21.2875566],
                [102.8392847, 21.2868378],
                [102.8393855, 21.2853401],
                [102.8394009, 21.2842847],
                [102.8392089, 21.2835433],
                [102.838921, 21.2831806],
                [102.8386537, 21.2829661],
                [102.8381139, 21.2828747],
                [102.8373456, 21.2829915],
                [102.8360539, 21.2834394],
                [102.8351053, 21.2835539],
                [102.8346115, 21.2833997],
                [102.8341666, 21.2829928],
                [102.8337914, 21.2824391],
                [102.8335679, 21.2815283],
                [102.8334446, 21.2807246],
                [102.8334652, 21.2793103],
                [102.833204, 21.2786735],
                [102.8329387, 21.2783112],
                [102.8326716, 21.2780755],
                [102.8322224, 21.2779641],
                [102.8318169, 21.2779378],
                [102.8310231, 21.2782654],
                [102.8305009, 21.278512],
                [102.8301391, 21.2785917],
                [102.8297567, 21.2785235],
                [102.8293776, 21.2782442],
                [102.8287749, 21.2778352],
                [102.8282364, 21.2776594],
                [102.8276181, 21.2775459],
                [102.8272029, 21.2774139],
                [102.8268222, 21.2772295],
                [102.8263095, 21.2768429],
                [102.8253629, 21.27606],
                [102.8246991, 21.2755975],
                [102.824295, 21.2754656],
                [102.8239021, 21.275355],
                [102.8235318, 21.2752446],
                [102.8230845, 21.274996],
                [102.8225724, 21.2745672],
                [102.8216657, 21.2737426],
                [102.8204384, 21.2728928],
                [102.8195762, 21.2725016],
                [102.8190718, 21.2723051],
                [102.8184708, 21.2721705],
                [102.817829, 21.2721201],
                [102.8167925, 21.2720856],
                [102.8160725, 21.2719917],
                [102.8155899, 21.2718483],
                [102.8152939, 21.2716649],
                [102.8151943, 21.271537],
                [102.8151519, 21.271357],
                [102.8151547, 21.271167],
                [102.8152025, 21.2709775],
                [102.8154426, 21.2707484],
                [102.8158075, 21.2704682],
                [102.816377, 21.2700639],
                [102.8166455, 21.2698246],
                [102.8167384, 21.2696357],
                [102.8167526, 21.2694353],
                [102.8167571, 21.2691398],
                [102.8166599, 21.268843],
                [102.8163963, 21.2683751],
                [102.8160487, 21.2678745],
                [102.8156725, 21.2673945],
                [102.8152385, 21.2670194],
                [102.8149591, 21.266847],
                [102.8147349, 21.2667701],
                [102.8144873, 21.2667458],
                [102.8139912, 21.2667605],
                [102.8128889, 21.2669784],
                [102.8123908, 21.2671303],
                [102.8120742, 21.2671895],
                [102.8114422, 21.2672446],
                [102.8108677, 21.2672266],
                [102.8103058, 21.2671032],
                [102.8098694, 21.266897],
                [102.8094854, 21.2665647],
                [102.8089089, 21.2659133],
                [102.8079863, 21.2646557],
                [102.8078321, 21.2644109],
                [102.8076915, 21.2640185],
                [102.8076963, 21.2636913],
                [102.8077797, 21.2633862],
                [102.8079764, 21.2630405],
                [102.8083131, 21.2627599],
                [102.8089761, 21.2621351],
                [102.8101838, 21.2608734],
                [102.8106998, 21.260289],
                [102.8114078, 21.2596648],
                [102.8117827, 21.2594587],
                [102.812485, 21.2592249],
                [102.8132604, 21.2590028],
                [102.8137609, 21.2586925],
                [102.8143987, 21.2582363],
                [102.814765, 21.2578611],
                [102.8151421, 21.257127],
                [102.8153431, 21.2564962],
                [102.8154397, 21.2560542],
                [102.8153987, 21.2557792],
                [102.8152789, 21.2555031],
                [102.8149842, 21.2552248],
                [102.8144265, 21.2548375],
                [102.8137994, 21.254555],
                [102.812547, 21.2538842],
                [102.8118288, 21.2532944],
                [102.8115619, 21.253048],
                [102.8113304, 21.2526967],
                [102.811189, 21.252357],
                [102.8111943, 21.2519876],
                [102.8113004, 21.2516724],
                [102.8115695, 21.2513909],
                [102.8119213, 21.2512265],
                [102.8124756, 21.251086],
                [102.8132767, 21.2510224],
                [102.8140541, 21.2510431],
                [102.8145664, 21.2510796],
                [102.8152065, 21.2512356],
                [102.8157226, 21.2513899],
                [102.8164741, 21.2516422],
                [102.8171687, 21.2519255],
                [102.8177724, 21.2522603],
                [102.8184538, 21.2526701],
                [102.8192637, 21.2531344],
                [102.81989, 21.2534592],
                [102.8202734, 21.2534431],
                [102.8205009, 21.2532982],
                [102.8208004, 21.2528589],
                [102.8209083, 21.2524133],
                [102.8208902, 21.2521178],
                [102.8206927, 21.2517565],
                [102.8204961, 21.2513322],
                [102.8204396, 21.2508266],
                [102.8203545, 21.2502335],
                [102.8204526, 21.2496864],
                [102.8205944, 21.2492453],
                [102.8208945, 21.2487639],
                [102.8212624, 21.2482625],
                [102.8213346, 21.247947],
                [102.8213389, 21.2476517],
                [102.8213452, 21.2472299],
                [102.8215061, 21.2469129],
                [102.8218324, 21.2465053],
                [102.8220144, 21.246387],
                [102.8222216, 21.2463173],
                [102.822376, 21.2463193],
                [102.8225814, 21.2463702],
                [102.8228115, 21.2464938],
                [102.8232193, 21.2467883],
                [102.8233996, 21.2467907],
                [102.8234776, 21.2467435],
                [102.823505, 21.2466232],
                [102.8235114, 21.2461894],
                [102.8235157, 21.2459],
                [102.8236216, 21.2457086],
                [102.8237775, 21.245614],
                [102.8242676, 21.2455722],
                [102.8248833, 21.2457489],
                [102.8255222, 21.2460947],
                [102.8261852, 21.2465614],
                [102.8265434, 21.2467348],
                [102.8267748, 21.246762],
                [102.8269293, 21.2467639],
                [102.8270853, 21.2466695],
                [102.827244, 21.2463822],
                [102.827429, 21.2460711],
                [102.8275333, 21.245976],
                [102.8277413, 21.245858],
                [102.8280245, 21.2458618],
                [102.828306, 21.2459859],
                [102.8286628, 21.2462557],
                [102.8289172, 21.246476],
                [102.8291169, 21.2469127],
                [102.8292647, 21.2473727],
                [102.8296722, 21.2476914],
                [102.8300059, 21.2477681],
                [102.8302388, 21.2476987],
                [102.83055, 21.247558],
                [102.830916, 21.247201],
                [102.8310859, 21.2470262],
                [102.8314472, 21.2469825],
                [102.831782, 21.2469868],
                [102.8320379, 21.2471107],
                [102.8326257, 21.247432],
                [102.8329082, 21.2474839],
                [102.8330371, 21.2474856],
                [102.8332968, 21.2473442],
                [102.8337135, 21.2470358],
                [102.8340773, 21.2468233],
                [102.8343355, 21.2467784],
                [102.8345677, 21.2467572],
                [102.8347218, 21.2467834],
                [102.8349011, 21.246858],
                [102.8351544, 21.2471509],
                [102.8353595, 21.2472258],
                [102.8354906, 21.2470587],
                [102.8357536, 21.2467001],
                [102.8360118, 21.2466552],
                [102.8363466, 21.2466595],
                [102.8368335, 21.2468346],
                [102.8372664, 21.2471779],
                [102.8377792, 21.2473534],
                [102.838254, 21.2474681],
                [102.8386919, 21.2474736],
                [102.8392865, 21.2473366],
                [102.8396731, 21.2473174],
                [102.840266, 21.2473009],
                [102.8411642, 21.2475295],
                [102.8414983, 21.247582],
                [102.8416797, 21.247512],
                [102.8418889, 21.2472975],
                [102.842228, 21.2470123],
                [102.8424609, 21.2469429],
                [102.842667, 21.2469455],
                [102.8428459, 21.2470444],
                [102.8430491, 21.2472399],
                [102.8431475, 21.2475548],
                [102.8431896, 21.2482069],
                [102.8432883, 21.2484977],
                [102.8434659, 21.248693],
                [102.8441825, 21.2490158],
                [102.8447442, 21.2493608],
                [102.844909, 21.2495438],
                [102.845084, 21.249908],
                [102.84513, 21.2502947],
                [102.8451763, 21.2506572],
                [102.8452761, 21.2508756],
                [102.8456096, 21.2509764],
                [102.8459179, 21.2510285],
                [102.8463548, 21.2511065],
                [102.8468936, 21.2512581],
                [102.8473258, 21.2516497],
                [102.8479074, 21.2524052],
                [102.8484417, 21.2528704],
                [102.8490811, 21.2531922],
                [102.8497688, 21.2537318],
                [102.8500887, 21.2538686],
                [102.850526, 21.2539224],
                [102.8509128, 21.2539033],
                [102.8514565, 21.2537171],
                [102.8519999, 21.2535551],
                [102.852568, 21.2534659],
                [102.8533411, 21.2534517],
                [102.85396, 21.2534113],
                [102.8542708, 21.2532945],
                [102.8545847, 21.2529607],
                [102.854818, 21.2528673],
                [102.8550498, 21.2528702],
                [102.8552541, 21.2529934],
                [102.8554052, 21.2532366],
                [102.8554535, 21.2534544],
                [102.8553727, 21.2536947],
                [102.8551128, 21.2538602],
                [102.8549279, 21.2541716],
                [102.8550806, 21.2542942],
                [102.8553881, 21.2544188],
                [102.8559001, 21.2546424],
                [102.8562328, 21.2547914],
                [102.8565928, 21.2548442],
                [102.8570447, 21.2547655],
                [102.8573553, 21.2546729],
                [102.8576382, 21.2547007],
                [102.8577657, 21.2547989],
                [102.8577371, 21.2549915],
                [102.857476, 21.2552294],
                [102.857318, 21.2554687],
                [102.857289, 21.2556855],
                [102.8573378, 21.2558791],
                [102.857516, 21.2560262],
                [102.8579514, 21.2562006],
                [102.8583344, 21.2564467],
                [102.8584614, 21.256569],
                [102.8585092, 21.2568351],
                [102.8584802, 21.2570518],
                [102.8586073, 21.2571741],
                [102.8587617, 21.257176],
                [102.8591008, 21.2568908],
                [102.8593863, 21.2567497],
                [102.8596189, 21.2567044],
                [102.8599022, 21.256708],
                [102.8603147, 21.256689],
                [102.8607025, 21.2565975],
                [102.8610666, 21.2563608],
                [102.8614331, 21.2559553],
                [102.8618508, 21.2555745],
                [102.8620324, 21.2554804],
                [102.8622902, 21.2554837],
                [102.8625982, 21.25556],
                [102.8628282, 21.2556834],
                [102.8630051, 21.2559269],
                [102.8630524, 21.2562172],
                [102.8629177, 21.2566256],
                [102.8628105, 21.2569138],
                [102.8628585, 21.2571557],
                [102.8629851, 21.257302],
                [102.8631394, 21.2573282],
                [102.8637453, 21.2572997],
                [102.864391, 21.2571871],
                [102.8647025, 21.2570222],
                [102.8648336, 21.256855],
                [102.8648887, 21.2566144],
                [102.8648929, 21.2563249],
                [102.8648508, 21.2556729],
                [102.8648539, 21.2554558],
                [102.8649068, 21.2553599],
                [102.8650366, 21.2552892],
                [102.865217, 21.2552915],
                [102.8654227, 21.2553182],
                [102.8657305, 21.2554186],
                [102.8664506, 21.2555002],
                [102.8666832, 21.2554548],
                [102.8668658, 21.2552883],
                [102.8669973, 21.2550969],
                [102.8670309, 21.2545664],
                [102.8672662, 21.2543282],
                [102.867836, 21.2541182],
                [102.8683547, 21.2538834],
                [102.8685639, 21.2538355],
                [102.8690525, 21.2537235],
                [102.8695711, 21.2534887],
                [102.8701936, 21.253207],
                [102.8704934, 21.2529575],
                [102.870909, 21.2527215],
                [102.8711412, 21.2527002],
                [102.8713473, 21.2527028],
                [102.8715777, 21.2528023],
                [102.8718552, 21.253216],
                [102.87211, 21.2534122],
                [102.8725979, 21.253515],
                [102.8731128, 21.2535456],
                [102.873526, 21.2534784],
                [102.8737605, 21.2532883],
                [102.8738407, 21.2530963],
                [102.8738458, 21.2527344],
                [102.8738997, 21.2525662],
                [102.8740303, 21.2524472],
                [102.8743916, 21.2524036],
                [102.8749092, 21.2522411],
                [102.8755033, 21.252128],
                [102.8759199, 21.2518195],
                [102.8761542, 21.2516536],
                [102.8764633, 21.2516575],
                [102.876644, 21.2516357],
                [102.8770829, 21.2515688],
                [102.8775349, 21.2514901],
                [102.8781791, 21.251474],
                [102.8786714, 21.2512872],
                [102.8790887, 21.2509305],
                [102.87961, 21.2505028],
                [102.8797932, 21.250312],
                [102.8799734, 21.2503143],
                [102.8801534, 21.2503408],
                [102.8805621, 21.2505871],
                [102.8809993, 21.2506409],
                [102.8812585, 21.2505235],
                [102.881624, 21.2501902],
                [102.8822751, 21.2496918],
                [102.8829755, 21.2493627],
                [102.8834427, 21.2491273],
                [102.8839869, 21.2488929],
                [102.8844639, 21.2488627],
                [102.8848253, 21.248819],
                [102.8850331, 21.2487009],
                [102.8855029, 21.2482725],
                [102.8860761, 21.2478213],
                [102.886674, 21.2474427],
                [102.8878166, 21.2468056],
                [102.8885681, 21.2465013],
                [102.889393, 21.2464635],
                [102.8902684, 21.2464985],
                [102.8910423, 21.2464358],
                [102.8916497, 21.2462866],
                [102.8921172, 21.2460269],
                [102.8926639, 21.2456236],
                [102.8931836, 21.2453164],
                [102.8936012, 21.2449356],
                [102.8941513, 21.244291],
                [102.8947289, 21.2435261],
                [102.8953316, 21.2428098],
                [102.8959848, 21.2421665],
                [102.8964429, 21.2416534],
                [102.8970414, 21.2412265],
                [102.8975133, 21.2406533],
                [102.8978827, 21.2400306],
                [102.898072, 21.2394056],
                [102.8983947, 21.2384445],
                [102.8986317, 21.2380855],
                [102.8988473, 21.2374126],
                [102.8991803, 21.2366326],
                [102.8996257, 21.2361073],
                [102.8999424, 21.2355804],
                [102.9002881, 21.2348127],
                [102.9007083, 21.2342388],
                [102.9013366, 21.2335227],
                [102.902041, 21.2329042],
                [102.9027566, 21.2323942],
                [102.9036907, 21.2319233],
                [102.9045728, 21.2314758],
                [102.9052702, 21.2313397],
                [102.9057087, 21.2312969],
                [102.9067529, 21.2314408],
                [102.9073883, 21.2314485],
                [102.9079759, 21.2311088],
                [102.9085626, 21.2308185],
                [102.908992, 21.2304272],
                [102.9094006, 21.2302348],
                [102.9100459, 21.230067],
                [102.9106908, 21.2300001],
                [102.9112641, 21.2299667],
                [102.9116225, 21.2298325],
                [102.9118376, 21.2297317],
                [102.9121244, 21.229362],
                [102.9123039, 21.228925],
                [102.9124834, 21.2282864],
                [102.9125195, 21.2278494],
                [102.9125197, 21.2274795],
                [102.912398, 21.2272172],
                [102.9122548, 21.2271164],
                [102.9117673, 21.2268215],
                [102.9102314, 21.226583],
                [102.9097809, 21.2262284],
                [102.9087579, 21.2253623],
                [102.9081887, 21.2246183],
                [102.9078673, 21.2239161],
                [102.9076745, 21.2229051],
                [102.9077323, 21.2217422],
                [102.9077498, 21.2205011],
                [102.9076452, 21.2191034],
                [102.9081209, 21.2180838],
                [102.909461, 21.2166046],
                [102.9098931, 21.2153299],
                [102.91003, 21.2144394],
                [102.909745, 21.2135977],
                [102.9098422, 21.2118415],
                [102.9098303, 21.2116502],
                [102.9097938, 21.2109905],
                [102.9094548, 21.2077895],
                [102.9093438, 21.2072936],
                [102.9093095, 21.206789],
                [102.9093966, 21.2064798],
                [102.9094849, 21.206093],
                [102.9099523, 21.2052454],
                [102.9103869, 21.2047207],
                [102.9111294, 21.204128],
                [102.9112264, 21.2037566],
                [102.9111355, 21.2035534],
                [102.910623, 21.2031472],
                [102.9098977, 21.2026326],
                [102.909425, 21.2021779],
                [102.9087599, 21.2014555],
                [102.908435, 21.2010277],
                [102.9082921, 21.2006538],
                [102.908166, 21.2003259],
                [102.9080207, 21.1998138],
                [102.9079695, 21.1988134],
                [102.9081521, 21.1982239],
                [102.9083555, 21.1976959],
                [102.9084256, 21.1973588],
                [102.9084815, 21.1966429],
                [102.9084553, 21.1963273],
                [102.9082453, 21.1960381],
                [102.9079435, 21.1957478],
                [102.9077013, 21.1955727],
                [102.9072776, 21.1952521],
                [102.9066719, 21.1948147],
                [102.9063724, 21.1943524],
                [102.9061942, 21.1939776],
                [102.9061105, 21.1934033],
                [102.9058422, 21.1929128],
                [102.9053572, 21.1925914],
                [102.9048393, 21.1924417],
                [102.9044772, 21.1920932],
                [102.9037521, 21.1903645],
                [102.9036978, 21.1898766],
                [102.9038282, 21.1893049],
                [102.9043256, 21.1887379],
                [102.9046686, 21.1882835],
                [102.9050517, 21.1871418],
                [102.9057669, 21.1863194],
                [102.9060839, 21.1855208],
                [102.9076462, 21.1831898],
                [102.9088064, 21.1811691],
                [102.9091821, 21.1805432],
                [102.9091292, 21.1799692],
                [102.9089206, 21.1795654],
                [102.9084696, 21.1790153],
                [102.9080186, 21.178465],
                [102.907751, 21.1779172],
                [102.9077264, 21.1774869],
                [102.907817, 21.1764848],
                [102.9077929, 21.1760259],
                [102.9074911, 21.1757356],
                [102.9068093, 21.1754641],
                [102.9063252, 21.175458],
                [102.9058732, 21.175819],
                [102.9056863, 21.175869],
                [102.9052955, 21.1758466],
                [102.9043139, 21.1754669],
                [102.9038593, 21.1751747],
                [102.9035893, 21.1747987],
                [102.903473, 21.1743673],
                [102.9034614, 21.17302],
                [102.9033481, 21.1712989],
                [102.9034499, 21.1705835],
                [102.903856, 21.1699866],
                [102.9042619, 21.1693897],
                [102.9042664, 21.1690745],
                [102.9039972, 21.1686412],
                [102.9036371, 21.1681495],
                [102.9031021, 21.1670536],
                [102.9029761, 21.1662209],
                [102.9030124, 21.16582],
                [102.9032929, 21.165451],
                [102.9036623, 21.1652836],
                [102.9042766, 21.1650905],
                [102.9048271, 21.1650974],
                [102.9057441, 21.1651374],
                [102.9062972, 21.1649436],
                [102.9066368, 21.1647184],
                [102.9069169, 21.1643781],
                [102.9070747, 21.164036],
                [102.9073261, 21.1635519],
                [102.9076041, 21.1630474],
                [102.9087005, 21.1625943],
                [102.9099583, 21.1623233],
                [102.9115241, 21.161884],
                [102.9137978, 21.1611382],
                [102.9145047, 21.1608889],
                [102.915239, 21.1608694],
                [102.9160332, 21.1609364],
                [102.9164286, 21.1610847],
                [102.9172213, 21.1612663],
                [102.9177905, 21.1610154],
                [102.9182239, 21.1606481],
                [102.9185348, 21.1602794],
                [102.9188176, 21.1597382],
                [102.9189469, 21.1592525],
                [102.9191062, 21.1587959],
                [102.919416, 21.1585131],
                [102.9198477, 21.1582604],
                [102.9203392, 21.1580944],
                [102.9209461, 21.1576457],
                [102.9213137, 21.1568599],
                [102.9215618, 21.1556342],
                [102.921746, 21.1556103],
                [102.9222932, 21.1560398],
                [102.9226522, 21.1563853],
                [102.9230388, 21.1568539],
                [102.9233232, 21.1573349],
                [102.9238571, 21.158023],
                [102.9241067, 21.1587713],
                [102.9241829, 21.1598902],
                [102.9242957, 21.1605794],
                [102.9246872, 21.1610142],
                [102.9252316, 21.1614508],
                [102.9270245, 21.1622753],
                [102.9278466, 21.1625433],
                [102.9281833, 21.1625188],
                [102.9284916, 21.1623243],
                [102.9285844, 21.1622658],
                [102.9296541, 21.1612184],
                [102.9303086, 21.1603378],
                [102.9308662, 21.1598287],
                [102.9311747, 21.1596318],
                [102.9316664, 21.1594658],
                [102.9323411, 21.1593308],
                [102.9329833, 21.1593386],
                [102.9339923, 21.159351],
                [102.9348179, 21.1593611],
                [102.9361933, 21.1594352],
                [102.9367761, 21.1592989],
                [102.9370537, 21.1591304],
                [102.9373336, 21.1587899],
                [102.9375517, 21.1585058],
                [102.9377827, 21.1580168],
                [102.937839, 21.1576794],
                [102.9377914, 21.1573891],
                [102.9375941, 21.156759],
                [102.9372914, 21.1563243],
                [102.9371134, 21.1559208],
                [102.9371198, 21.1554623],
                [102.9371897, 21.1548325],
                [102.9375031, 21.1542918],
                [102.9379689, 21.1537815],
                [102.9386205, 21.1531016],
                [102.9390307, 21.1523145],
                [102.9394451, 21.1509777],
                [102.9397569, 21.1504677],
                [102.9405904, 21.1496499],
                [102.9419721, 21.1488674],
                [102.942251, 21.1485282],
                [102.9424384, 21.1481879],
                [102.9426701, 21.146849],
                [102.9435394, 21.1456605],
                [102.9440358, 21.1450099],
                [102.9453591, 21.1434666],
                [102.946536, 21.1423917],
                [102.9472476, 21.141823],
                [102.94798, 21.1412778],
                [102.948398, 21.1410882],
                [102.9489698, 21.1410464],
                [102.9493881, 21.1412209],
                [102.9494506, 21.1411074],
                [102.9493872, 21.1409055],
                [102.9492449, 21.1405625],
                [102.9490611, 21.1400742],
                [102.9490937, 21.139588],
                [102.9492608, 21.1387626],
                [102.9491157, 21.1382638],
                [102.9487221, 21.1379726],
                [102.9468111, 21.1368609],
                [102.9462344, 21.1365389],
                [102.9456599, 21.1360737],
                [102.945148, 21.1354945],
                [102.9446881, 21.1344857],
                [102.9443935, 21.1336797],
                [102.9440925, 21.1333321],
                [102.9434247, 21.1329802],
                [102.9427556, 21.1327428],
                [102.9417227, 21.1322716],
                [102.941239, 21.1318647],
                [102.9408183, 21.1313437],
                [102.94028, 21.1304775],
                [102.93981, 21.1295546],
                [102.9397153, 21.1283087],
                [102.9399837, 21.1272297],
                [102.9403362, 21.126178],
                [102.9411041, 21.1256331],
                [102.9429909, 21.1235968],
                [102.9434165, 21.1233644],
                [102.9442317, 21.1234799],
                [102.945498, 21.1235481],
                [102.9458927, 21.1235265],
                [102.9472197, 21.1232787],
                [102.9476268, 21.1231077],
                [102.9478745, 21.1228816],
                [102.9480793, 21.1221914],
                [102.9486857, 21.1216886],
                [102.9492382, 21.1215235],
                [102.950309, 21.1211319],
                [102.951739, 21.1204187],
                [102.9526033, 21.1199413],
                [102.953733, 21.1195666],
                [102.9544491, 21.1191314],
                [102.9546918, 21.1186905],
                [102.9548861, 21.1174724],
                [102.9551372, 21.1164214],
                [102.9558534, 21.1159862],
                [102.9572817, 21.1153932],
                [102.9589444, 21.1149694],
                [102.9597774, 21.1146465],
                [102.9603159, 21.1142092],
                [102.9607422, 21.1133268],
                [102.9608705, 21.112607],
                [102.9608182, 21.1121072],
                [102.9608879, 21.1113313],
                [102.9611329, 21.110724],
                [102.9617693, 21.1096221],
                [102.962075, 21.1089046],
                [102.9628123, 21.1069163],
                [102.9635488, 21.1049834],
                [102.9658499, 21.1011277],
                [102.9673298, 21.0983863],
                [102.9673924, 21.0978454],
                [102.9675321, 21.096558],
                [102.9677338, 21.0940968],
                [102.9677125, 21.0934091],
                [102.9676121, 21.0918038],
                [102.9675614, 21.0910296],
                [102.9669608, 21.0902777],
                [102.9664445, 21.0889251],
                [102.9659669, 21.0875792],
                [102.965602, 21.0870316],
                [102.9651957, 21.0864838],
                [102.9634726, 21.0854157],
                [102.9626549, 21.0847076],
                [102.9613889, 21.0834899],
                [102.9610299, 21.0825158],
                [102.9606795, 21.0813224],
                [102.9606332, 21.0809355],
                [102.9606666, 21.0807986],
                [102.9615105, 21.0799857],
                [102.9619463, 21.0793894],
                [102.9620923, 21.078761],
                [102.9620147, 21.0777287],
                [102.9621401, 21.0775011],
                [102.9625096, 21.0772763],
                [102.9629071, 21.0772524],
                [102.9633958, 21.0772583],
                [102.9636711, 21.0772329],
                [102.964193, 21.0770387],
                [102.9648407, 21.0765881],
                [102.966381, 21.0756326],
                [102.9667221, 21.0752644],
                [102.9668493, 21.0748934],
                [102.9668583, 21.0742348],
                [102.9670587, 21.0729767],
                [102.9675694, 21.0713558],
                [102.9680518, 21.0707028],
                [102.9695322, 21.0696605],
                [102.9708571, 21.068817],
                [102.9718453, 21.0680268],
                [102.9729278, 21.067037],
                [102.9740894, 21.0658477],
                [102.9745499, 21.0656814],
                [102.97519, 21.065775],
                [102.975918, 21.066156],
                [102.9764321, 21.0665346],
                [102.9772478, 21.0672032],
                [102.9778567, 21.0673537],
                [102.9784369, 21.0673606],
                [102.9789279, 21.0671946],
                [102.979207, 21.0668827],
                [102.979458, 21.0663988],
                [102.979526, 21.0658839],
                [102.979804, 21.0656581],
                [102.9802919, 21.0657212],
                [102.9805651, 21.065839],
                [102.980869, 21.0659572],
                [102.9815414, 21.0659365],
                [102.9823267, 21.065459],
                [102.9833147, 21.0646686],
                [102.984272, 21.0639065],
                [102.9853169, 21.0634319],
                [102.9869722, 21.0629932],
                [102.9875262, 21.0626847],
                [102.9879855, 21.0626042],
                [102.9882889, 21.062751],
                [102.9889284, 21.0629018],
                [102.9896937, 21.0627676],
                [102.9905665, 21.0625201],
                [102.9917965, 21.0619045],
                [102.992166, 21.0616796],
                [102.9927169, 21.0616003],
                [102.9933257, 21.0617507],
                [102.9939019, 21.062044],
                [102.9948092, 21.0627136],
                [102.9952323, 21.0630623],
                [102.9957204, 21.0630967],
                [102.9962446, 21.0627305],
                [102.9966179, 21.0622193],
                [102.9969897, 21.0618226],
                [102.9973585, 21.0616551],
                [102.9977856, 21.0616887],
                [102.9982131, 21.0616939],
                [102.9986124, 21.0615266],
                [102.9990443, 21.0612166],
                [102.9996355, 21.0603928],
                [103.0007181, 21.0593743],
                [103.0018842, 21.0587137],
                [103.0024465, 21.0587274],
                [103.0032181, 21.0590706],
                [103.004229, 21.0597633],
                [103.0052472, 21.0606872],
                [103.0060485, 21.061517],
                [103.0072716, 21.0635239],
                [103.0078292, 21.0638821],
                [103.0088976, 21.0634257],
                [103.0106565, 21.0627431],
                [103.0116578, 21.0626377],
                [103.0123428, 21.0628215],
                [103.0127677, 21.0637642],
                [103.0130723, 21.0643537],
                [103.0134411, 21.0648268],
                [103.0137511, 21.0650063],
                [103.014126, 21.0650107],
                [103.0146937, 21.0646071],
                [103.0165939, 21.0626954],
                [103.0176006, 21.0621798],
                [103.0225693, 21.0597763],
                [103.0239524, 21.0591479],
                [103.0243967, 21.0586255],
                [103.0247277, 21.057223],
                [103.0260212, 21.0586445],
                [103.0271497, 21.0583646],
                [103.0282173, 21.0579668],
                [103.0289038, 21.0580334],
                [103.0296474, 21.0585108],
                [103.0303316, 21.0587532],
                [103.0308314, 21.058759],
                [103.0317085, 21.0585932],
                [103.0325855, 21.0584277],
                [103.0331463, 21.0585514],
                [103.0336439, 21.058733],
                [103.0340813, 21.058738],
                [103.0342718, 21.0585058],
                [103.0342818, 21.0577441],
                [103.0341638, 21.0572152],
                [103.0341683, 21.0568637],
                [103.0342987, 21.056455],
                [103.0346165, 21.0560484],
                [103.0350593, 21.0556433],
                [103.0353146, 21.0552361],
                [103.0353817, 21.0548852],
                [103.0352019, 21.0542971],
                [103.0342847, 21.0527628],
                [103.0337325, 21.0519946],
                [103.0336121, 21.0516416],
                [103.033555, 21.0512307],
                [103.0335566, 21.0511135],
                [103.0336877, 21.0506462],
                [103.0340039, 21.0503568],
                [103.0352611, 21.0497853],
                [103.0370166, 21.0493368],
                [103.0377702, 21.0490525],
                [103.0380865, 21.0487631],
                [103.038604, 21.0474212],
                [103.038863, 21.046721],
                [103.0392448, 21.0461979],
                [103.0396852, 21.0459686],
                [103.0399382, 21.0457372],
                [103.0395124, 21.0448531],
                [103.0395162, 21.0445602],
                [103.0395825, 21.0442679],
                [103.0398995, 21.0439199],
                [103.0402165, 21.043572],
                [103.0404078, 21.0432812],
                [103.0404146, 21.0427538],
                [103.039985, 21.0421628],
                [103.0386907, 21.0408],
                [103.0383868, 21.0401519],
                [103.0383381, 21.0390964],
                [103.0384768, 21.0380432],
                [103.0387984, 21.0373436],
                [103.0396815, 21.0367092],
                [103.0409378, 21.0361962],
                [103.0420669, 21.0358576],
                [103.0429416, 21.0358677],
                [103.0435038, 21.0358741],
                [103.0439433, 21.0357034],
                [103.0443251, 21.0351804],
                [103.044409, 21.0335405],
                [103.044483, 21.0326622],
                [103.0447405, 21.0320792],
                [103.0460014, 21.0312146],
                [103.0466969, 21.0305779],
                [103.0472082, 21.0297048],
                [103.0473583, 21.0277726],
                [103.047618, 21.0270136],
                [103.0477461, 21.0267807],
                [103.047999, 21.0265492],
                [103.0486237, 21.0265564],
                [103.0492438, 21.0269152],
                [103.0496796, 21.0270374],
                [103.0501176, 21.0269837],
                [103.0503712, 21.0266936],
                [103.0506996, 21.0254667],
                [103.0507195, 21.0239432],
                [103.0509175, 21.0231251],
                [103.0521242, 21.0216151],
                [103.053583, 21.0199323],
                [103.0540918, 21.019235],
                [103.0541642, 21.018474],
                [103.0541908, 21.016423],
                [103.0547003, 21.015667],
                [103.0556541, 21.0143887],
                [103.0564105, 21.0138699],
                [103.0570991, 21.0137606],
                [103.0576628, 21.0136498],
                [103.0580398, 21.0134783],
                [103.0582949, 21.013071],
                [103.0578851, 21.0109565],
                [103.0576557, 21.0093715],
                [103.0577629, 21.0059152],
                [103.0585519, 21.0028767],
                [103.0603754, 20.9990438],
                [103.0613355, 20.9979147],
                [103.0626904, 20.9968172],
                [103.064905, 20.995544],
                [103.0654802, 20.995262],
                [103.0660516, 20.9952685],
                [103.0664032, 20.9952725],
                [103.0667101, 20.9953377],
                [103.0670203, 20.9951558],
                [103.067247, 20.9946225],
                [103.0678324, 20.9935369],
                [103.0692746, 20.9925021],
                [103.0695857, 20.9922378],
                [103.0698777, 20.9917463],
                [103.0698618, 20.9912721],
                [103.0697813, 20.9906941],
                [103.0695478, 20.9900526],
                [103.0688755, 20.9893442],
                [103.0685091, 20.9887836],
                [103.0684917, 20.9884154],
                [103.0690756, 20.9874527],
                [103.0700927, 20.9852784],
                [103.0702977, 20.9847239],
                [103.0704796, 20.9842516],
                [103.0705068, 20.9838394],
                [103.0705587, 20.9832215],
                [103.0705699, 20.9823553],
                [103.0706738, 20.9810986],
                [103.0708752, 20.9808328],
                [103.0714474, 20.9807774],
                [103.0725025, 20.9807687],
                [103.0733828, 20.9806755],
                [103.0741766, 20.9804784],
                [103.0748618, 20.9801767],
                [103.0755491, 20.9797102],
                [103.0770323, 20.9771698],
                [103.0778238, 20.9754258],
                [103.078376, 20.9734936],
                [103.0788092, 20.9722818],
                [103.0793661, 20.9716899],
                [103.079611, 20.9714453],
                [103.0803168, 20.9712471],
                [103.0810006, 20.9710485],
                [103.0827396, 20.9708413],
                [103.0835548, 20.9706854],
                [103.0839527, 20.9705043],
                [103.0841769, 20.9701562],
                [103.0843579, 20.9697459],
                [103.0844531, 20.9691694],
                [103.0845032, 20.9686958],
                [103.0844662, 20.9681591],
                [103.0842778, 20.9674146],
                [103.0842324, 20.9658055],
                [103.0842844, 20.9651669],
                [103.0843585, 20.9645284],
                [103.0844568, 20.9637046],
                [103.084864, 20.9628018],
                [103.0850905, 20.9622681],
                [103.0852767, 20.9614453],
                [103.0851984, 20.960702],
                [103.0849844, 20.9602459],
                [103.0844724, 20.959044],
                [103.0843059, 20.9582998],
                [103.0844933, 20.9573946],
                [103.0851196, 20.9565354],
                [103.0854382, 20.9556728],
                [103.0853158, 20.954929],
                [103.0852257, 20.9533607],
                [103.0851077, 20.9522669],
                [103.0849019, 20.9511514],
                [103.0849628, 20.9498365],
                [103.0851276, 20.948972],
                [103.0851318, 20.948642],
                [103.0845881, 20.9481821],
                [103.0842825, 20.9480137],
                [103.084135, 20.9475169],
                [103.084171, 20.9464242],
                [103.0841579, 20.9457227],
                [103.08368, 20.9452843],
                [103.0833117, 20.9448469],
                [103.0832931, 20.9445992],
                [103.083364, 20.9442081],
                [103.083589, 20.9437981],
                [103.0847495, 20.9424085],
                [103.0862279, 20.9402182],
                [103.088034, 20.9381757],
                [103.088218, 20.9375384],
                [103.0884416, 20.9372315],
                [103.0886185, 20.937151],
                [103.0900051, 20.9369808],
                [103.0907324, 20.936824],
                [103.0924056, 20.9348626],
                [103.092895, 20.9343936],
                [103.0932919, 20.9342949],
                [103.0937533, 20.9343001],
                [103.0953124, 20.9344],
                [103.0959713, 20.934428],
                [103.0964333, 20.9343918],
                [103.0976247, 20.9340339],
                [103.0982626, 20.9339792],
                [103.0986116, 20.9341893],
                [103.0988715, 20.9345016],
                [103.099499, 20.9352717],
                [103.0997154, 20.9355423],
                [103.0999759, 20.9357927],
                [103.10026, 20.9359196],
                [103.1022248, 20.9352196],
                [103.1031746, 20.9348382],
                [103.1035937, 20.9347192],
                [103.1039267, 20.9344548],
                [103.1040177, 20.9342082],
                [103.1040673, 20.933755],
                [103.1040929, 20.9334665],
                [103.1040087, 20.9331767],
                [103.1037947, 20.9327206],
                [103.1036458, 20.932327],
                [103.1036506, 20.9319352],
                [103.1037193, 20.9317297],
                [103.104076, 20.9313212],
                [103.1050991, 20.930363],
                [103.1060343, 20.9293833],
                [103.1065811, 20.9278424],
                [103.10671, 20.9263174],
                [103.1068524, 20.925494],
                [103.1067474, 20.9251009],
                [103.1064008, 20.9247051],
                [103.1047009, 20.9235725],
                [103.1045761, 20.9230141],
                [103.1045692, 20.9218178],
                [103.1046024, 20.9209312],
                [103.1046297, 20.9204984],
                [103.1051038, 20.9194929],
                [103.1053357, 20.9185259],
                [103.1059528, 20.9183885],
                [103.106349, 20.9183309],
                [103.1070079, 20.9183589],
                [103.1077348, 20.9182226],
                [103.1083965, 20.9180237],
                [103.109126, 20.9176811],
                [103.1100306, 20.9173817],
                [103.1104983, 20.9168918],
                [103.1108383, 20.9160706],
                [103.1109339, 20.9154528],
                [103.1112293, 20.9146723],
                [103.1120159, 20.9132783],
                [103.1121813, 20.912352],
                [103.1122068, 20.9120635],
                [103.112166, 20.9118155],
                [103.1118181, 20.9115229],
                [103.1108124, 20.9111199],
                [103.1103101, 20.9108668],
                [103.1101581, 20.9107207],
                [103.1099855, 20.9104713],
                [103.1099447, 20.9102233],
                [103.1101308, 20.9094003],
                [103.1102324, 20.9083082],
                [103.110326, 20.9078555],
                [103.1105071, 20.9074244],
                [103.110732, 20.9070142],
                [103.1109115, 20.9067275],
                [103.1109594, 20.906398],
                [103.1109413, 20.9060884],
                [103.1108173, 20.9054682],
                [103.1105412, 20.904702],
                [103.1102425, 20.9039974],
                [103.1095997, 20.9026702],
                [103.1093465, 20.9018423],
                [103.1093496, 20.9015947],
                [103.1094223, 20.9010592],
                [103.109473, 20.9005235],
                [103.1097635, 20.9001349],
                [103.1110693, 20.8993861],
                [103.1134038, 20.8971843],
                [103.1139357, 20.8968188],
                [103.1153259, 20.8963391],
                [103.1160754, 20.8961411],
                [103.1170499, 20.8955125],
                [103.1175801, 20.8952914],
                [103.1178429, 20.8953561],
                [103.1185777, 20.8963337],
                [103.1199446, 20.899484],
                [103.1216417, 20.9025967],
                [103.1221577, 20.9035099],
                [103.1244273, 20.9065259],
                [103.1250105, 20.9073367],
                [103.1260936, 20.9085862],
                [103.1267473, 20.9090265],
                [103.1274255, 20.9092608],
                [103.1279741, 20.9093287],
                [103.1288974, 20.9092976],
                [103.1295369, 20.9091189],
                [103.1302681, 20.9086319],
                [103.1310017, 20.9079386],
                [103.1314231, 20.9076338],
                [103.131668, 20.9073683],
                [103.1318451, 20.9072672],
                [103.1321759, 20.9071677],
                [103.132638, 20.9071109],
                [103.1330562, 20.9070535],
                [103.133408, 20.9070367],
                [103.1338032, 20.9070617],
                [103.1339553, 20.9072077],
                [103.1341266, 20.9075602],
                [103.1342115, 20.9078087],
                [103.1344493, 20.9081207],
                [103.1347973, 20.9084133],
                [103.1350821, 20.9084783],
                [103.1356546, 20.9083814],
                [103.1361416, 20.908098],
                [103.1366979, 20.9075265],
                [103.1369658, 20.9071993],
                [103.13712, 20.9071598],
                [103.1374279, 20.9071425],
                [103.1378207, 20.907353],
                [103.1381914, 20.9075839],
                [103.138607, 20.9077329],
                [103.1389146, 20.9077362],
                [103.1395313, 20.9076192],
                [103.1404186, 20.9069482],
                [103.1410197, 20.9063153],
                [103.1418785, 20.9061596],
                [103.1426511, 20.9058792],
                [103.1429614, 20.9056558],
                [103.1434463, 20.9055372],
                [103.143842, 20.9055209],
                [103.1441936, 20.9055247],
                [103.1449374, 20.9057804],
                [103.1456345, 20.906283],
                [103.1461618, 20.9062886],
                [103.1464294, 20.9059615],
                [103.1467461, 20.9052224],
                [103.1468815, 20.9049351],
                [103.1476322, 20.9046338],
                [103.1487598, 20.9040685],
                [103.1498829, 20.9038745],
                [103.1502783, 20.9038787],
                [103.150804, 20.9040082],
                [103.1515871, 20.9046561],
                [103.1521742, 20.9051575],
                [103.1531413, 20.9051474],
                [103.15402, 20.9051569],
                [103.1544135, 20.905326],
                [103.1548491, 20.9056402],
                [103.155263, 20.9059335],
                [103.1557229, 20.9060621],
                [103.1567128, 20.9059697],
                [103.1577233, 20.9060012],
                [103.1586659, 20.9061764],
                [103.1594543, 20.9063912],
                [103.1608984, 20.9069023],
                [103.1617261, 20.9075093],
                [103.1620074, 20.9078834],
                [103.1622006, 20.9082567],
                [103.1624813, 20.9086721],
                [103.1627634, 20.9089637],
                [103.1640724, 20.9097613],
                [103.1648117, 20.9104085],
                [103.1653304, 20.9111357],
                [103.1655376, 20.9121689],
                [103.1653124, 20.9144185],
                [103.1650814, 20.9153444],
                [103.1649457, 20.9156524],
                [103.1650522, 20.9159423],
                [103.1653992, 20.9163174],
                [103.1658807, 20.9164877],
                [103.1662967, 20.9166159],
                [103.1666504, 20.9164547],
                [103.1669853, 20.916025],
                [103.1673268, 20.9150589],
                [103.1674893, 20.9143386],
                [103.1676252, 20.91401],
                [103.1678919, 20.9137653],
                [103.1682439, 20.9137484],
                [103.1685495, 20.9139168],
                [103.1691395, 20.9141913],
                [103.1699057, 20.9144471],
                [103.1705198, 20.9145568],
                [103.1711159, 20.9143363],
                [103.1716243, 20.9140941],
                [103.1725541, 20.913547],
                [103.1729321, 20.9131797],
                [103.1732229, 20.9127702],
                [103.1733579, 20.9125034],
                [103.1734279, 20.9121741],
                [103.1735428, 20.9117627],
                [103.1736359, 20.9113304],
                [103.1738657, 20.9105076],
                [103.1741773, 20.9101808],
                [103.1744205, 20.9100597],
                [103.175345, 20.9099458],
                [103.1758285, 20.9099509],
                [103.17629, 20.909956],
                [103.1767968, 20.9098375],
                [103.1772169, 20.9096357],
                [103.1775058, 20.9093705],
                [103.1778407, 20.9089409],
                [103.1783289, 20.9085541],
                [103.1790591, 20.9081492],
                [103.1800267, 20.9080977],
                [103.1813645, 20.9083389],
                [103.1821548, 20.9084092],
                [103.1825721, 20.9084343],
                [103.1827926, 20.9083748],
                [103.1832165, 20.9078429],
                [103.1833095, 20.9074311],
                [103.1834241, 20.9070404],
                [103.1835642, 20.906361],
                [103.1842975, 20.9056879],
                [103.1848735, 20.9053021],
                [103.1854748, 20.9046484],
                [103.1857012, 20.9040937],
                [103.1861053, 20.9033966],
                [103.1865297, 20.9028233],
                [103.1869958, 20.9024362],
                [103.1876117, 20.9024015],
                [103.1889096, 20.9022915],
                [103.1894607, 20.902153],
                [103.190102, 20.9018296],
                [103.190941, 20.9015084],
                [103.1916214, 20.9015775],
                [103.1916837, 20.9018876],
                [103.1919426, 20.9022824],
                [103.1926611, 20.9028471],
                [103.1940821, 20.903481],
                [103.1947124, 20.9040654],
                [103.1956021, 20.9050238],
                [103.1962731, 20.9058768],
                [103.197322, 20.9063831],
                [103.1975847, 20.9064683],
                [103.197807, 20.9062643],
                [103.1979877, 20.9058536],
                [103.1980837, 20.9051738],
                [103.1983578, 20.9043102],
                [103.1986262, 20.903921],
                [103.199138, 20.90339],
                [103.199906, 20.9023212],
                [103.2004317, 20.9017943],
                [103.2007441, 20.9017015],
                [103.2014203, 20.9018254],
                [103.2019456, 20.9020661],
                [103.2025998, 20.902436],
                [103.2035986, 20.9027623],
                [103.2043559, 20.9026197],
                [103.2058589, 20.9019816],
                [103.2078433, 20.9013564],
                [103.2090035, 20.9011848],
                [103.2102408, 20.9011959],
                [103.2108118, 20.9012226],
                [103.2114295, 20.9010227],
                [103.212469, 20.9004764],
                [103.2134869, 20.8998888],
                [103.2143703, 20.8995267],
                [103.2147918, 20.8991804],
                [103.2149494, 20.8988725],
                [103.2149782, 20.8982951],
                [103.2153009, 20.8970193],
                [103.2156809, 20.8964869],
                [103.2161923, 20.8959765],
                [103.2163266, 20.8957716],
                [103.2168213, 20.8957143],
                [103.2173822, 20.8958137],
                [103.217763, 20.8958526],
                [103.2182983, 20.8958871],
                [103.2187726, 20.8958833],
                [103.2192201, 20.8957918],
                [103.2193975, 20.8957411],
                [103.2197912, 20.8954741],
                [103.2201191, 20.8952764],
                [103.2203522, 20.8952265],
                [103.2208352, 20.8952839],
                [103.2216696, 20.8955112],
                [103.2225594, 20.895774],
                [103.2233565, 20.8960097],
                [103.2237188, 20.8960484],
                [103.2241006, 20.8960086],
                [103.224604, 20.8959089],
                [103.2249021, 20.8958684],
                [103.2252182, 20.8958804],
                [103.2256722, 20.8960249],
                [103.2264285, 20.896575],
                [103.2266128, 20.8967168],
                [103.2266875, 20.8966825],
                [103.2274523, 20.896507],
                [103.2279384, 20.8963108],
                [103.228144, 20.8962168],
                [103.2287162, 20.895803],
                [103.2300142, 20.8950812],
                [103.2303884, 20.8950296],
                [103.2307216, 20.8951255],
                [103.2309954, 20.8952393],
                [103.2314275, 20.8952808],
                [103.2318609, 20.8952112],
                [103.232078, 20.8951396],
                [103.2325337, 20.8948485],
                [103.2337271, 20.8936779],
                [103.2339847, 20.8935141],
                [103.2343003, 20.8934157],
                [103.2348813, 20.8933294],
                [103.2354119, 20.8933718],
                [103.2360295, 20.8935169],
                [103.2374097, 20.8940209],
                [103.2380441, 20.8944156],
                [103.2386408, 20.8946713],
                [103.2392391, 20.8947884],
                [103.239819, 20.8947943],
                [103.2404683, 20.8947641],
                [103.2407541, 20.8947023],
                [103.2420485, 20.8941334],
                [103.2436003, 20.8934192],
                [103.2441639, 20.8931477],
                [103.2449249, 20.8927951],
                [103.2453781, 20.8927073],
                [103.2456339, 20.8926915],
                [103.2459278, 20.8927776],
                [103.2462894, 20.8929662],
                [103.2472263, 20.8935581],
                [103.2480947, 20.8941215],
                [103.2493627, 20.8949941],
                [103.249762, 20.8953124],
                [103.2500631, 20.8956297],
                [103.2503641, 20.8959562],
                [103.2505598, 20.8960699],
                [103.2507943, 20.8961318],
                [103.2510158, 20.8961469],
                [103.2513099, 20.8961371],
                [103.2518574, 20.8961044],
                [103.2520313, 20.8960721],
                [103.2521581, 20.8960355],
                [103.2523024, 20.8960045],
                [103.2526475, 20.8959755],
                [103.2530774, 20.8960115],
                [103.2535976, 20.8960953],
                [103.2542744, 20.8963578],
                [103.2547774, 20.8967086],
                [103.255312, 20.8970669],
                [103.2556817, 20.8972538],
                [103.256304, 20.8973974],
                [103.2569182, 20.8975856],
                [103.2573833, 20.8976469],
                [103.2579194, 20.8976567],
                [103.2586949, 20.8977384],
                [103.2590383, 20.8977621],
                [103.2596549, 20.8980532],
                [103.2600597, 20.8982457],
                [103.2604268, 20.8983785],
                [103.2609452, 20.8986908],
                [103.2613618, 20.8990861],
                [103.2616144, 20.8993018],
                [103.261994, 20.8994704],
                [103.2622769, 20.8995928],
                [103.2634331, 20.9000292],
                [103.2642486, 20.9005526],
                [103.2648451, 20.9010738],
                [103.2650791, 20.9014634],
                [103.2651903, 20.9016445],
                [103.2652566, 20.9017077],
                [103.2653182, 20.9017457],
                [103.2653693, 20.9017692],
                [103.2654049, 20.9017784],
                [103.2654787, 20.9018029],
                [103.265535, 20.9018127],
                [103.265607, 20.9018075],
                [103.2656633, 20.9017956],
                [103.2657427, 20.9017684],
                [103.2659551, 20.901714],
                [103.2661321, 20.9016534],
                [103.2662393, 20.9016585],
                [103.266346, 20.9016698],
                [103.2663589, 20.9016785],
                [103.2663869, 20.9017331],
                [103.2664306, 20.9017968],
                [103.2664636, 20.901842],
                [103.2664909, 20.9018867],
                [103.2665273, 20.9019548],
                [103.2665681, 20.9020172],
                [103.2666065, 20.9020802],
                [103.2666762, 20.9021716],
                [103.2667179, 20.902232],
                [103.2668083, 20.9023743],
                [103.2668557, 20.9024406],
                [103.2669053, 20.9025017],
                [103.2671653, 20.9027799],
                [103.2673203, 20.9029089],
                [103.2675329, 20.9029848],
                [103.267749, 20.9031012],
                [103.26777, 20.9031096],
                [103.2678939, 20.9031434],
                [103.2679397, 20.9031543],
                [103.2685343, 20.9029356],
                [103.2693988, 20.9024608],
                [103.2707571, 20.9019911],
                [103.2715456, 20.9018978],
                [103.2717335, 20.9019023],
                [103.271917, 20.9019619],
                [103.2722322, 20.9021172],
                [103.2725705, 20.9023048],
                [103.2727274, 20.9023335],
                [103.2729193, 20.9023064],
                [103.273056, 20.9022318],
                [103.2731747, 20.9021389],
                [103.2735538, 20.9017329],
                [103.2740181, 20.9012117],
                [103.2742723, 20.901027],
                [103.2744917, 20.9007529],
                [103.2747117, 20.9001155],
                [103.2747677, 20.8997922],
                [103.2749033, 20.8994516],
                [103.2753318, 20.8987763],
                [103.2762079, 20.8977186],
                [103.2763328, 20.8975387],
                [103.2764323, 20.8974459],
                [103.2765536, 20.8974141],
                [103.2771274, 20.8973015],
                [103.2777234, 20.89734],
                [103.2780903, 20.8973415],
                [103.2785032, 20.8973073],
                [103.2791452, 20.8973459],
                [103.2795387, 20.8973407],
                [103.2799275, 20.8973356],
                [103.2805211, 20.8973229],
                [103.2808409, 20.8972881],
                [103.2812315, 20.8971721],
                [103.281457, 20.89706],
                [103.2816232, 20.897019],
                [103.2816437, 20.8970205],
                [103.2816682, 20.8970205],
                [103.2816716, 20.8970453],
                [103.2814457, 20.8972671],
                [103.2812301, 20.8974614],
                [103.2810327, 20.8976153],
                [103.2807743, 20.8977992],
                [103.2803834, 20.897986],
                [103.2801033, 20.8981968],
                [103.2797334, 20.8985262],
                [103.2798396, 20.8989304],
                [103.2800223, 20.8998699],
                [103.2802078, 20.9002227],
                [103.2804063, 20.9004758],
                [103.2807476, 20.9008468],
                [103.2810638, 20.9012647],
                [103.2811625, 20.9015233],
                [103.2811919, 20.9018988],
                [103.2811587, 20.9023151],
                [103.2811397, 20.9024867],
                [103.2811394, 20.902537],
                [103.2811432, 20.9025542],
                [103.2813875, 20.9027327],
                [103.2817858, 20.902928],
                [103.2822532, 20.9030649],
                [103.2825893, 20.9032012],
                [103.2829628, 20.9034139],
                [103.2832767, 20.9036417],
                [103.2836657, 20.9038334],
                [103.2841058, 20.9040439],
                [103.284662, 20.9042659],
                [103.2849867, 20.9045134],
                [103.2851095, 20.9046314],
                [103.2852167, 20.9046903],
                [103.2853896, 20.9047676],
                [103.2858336, 20.9048806],
                [103.2860696, 20.9049281],
                [103.2863394, 20.9050684],
                [103.286456, 20.9051336],
                [103.2867531, 20.9053114],
                [103.2869781, 20.9054398],
                [103.2871523, 20.9055325],
                [103.287254, 20.9056256],
                [103.2873132, 20.9057204],
                [103.2873664, 20.9058785],
                [103.2873838, 20.9060131],
                [103.2873799, 20.9062459],
                [103.2873865, 20.9064351],
                [103.2874105, 20.9066463],
                [103.2875156, 20.9068346],
                [103.2877264, 20.9071229],
                [103.2878154, 20.9072329],
                [103.2878798, 20.907371],
                [103.287923, 20.9077632],
                [103.2879715, 20.908045],
                [103.2881073, 20.9083859],
                [103.2882314, 20.9085272],
                [103.2884999, 20.9086771],
                [103.288508, 20.9086982],
                [103.288477, 20.9087355],
                [103.2881533, 20.9089559],
                [103.2879338, 20.9091779],
                [103.287789, 20.909453],
                [103.2876566, 20.9097577],
                [103.2872988, 20.9102138],
                [103.2869411, 20.910617],
                [103.2866018, 20.9111319],
                [103.2857615, 20.9120084],
                [103.2855171, 20.9122421],
                [103.2851103, 20.9124868],
                [103.2847664, 20.9126555],
                [103.2844768, 20.9128206],
                [103.284304, 20.9129822],
                [103.2840025, 20.9132804],
                [103.2838676, 20.9135477],
                [103.2837372, 20.9138833],
                [103.2836982, 20.9142233],
                [103.2836092, 20.9145808],
                [103.2834958, 20.9147856],
                [103.283358, 20.9148907],
                [103.2831453, 20.9150012],
                [103.2826204, 20.9151985],
                [103.2823448, 20.9153793],
                [103.2822005, 20.9155604],
                [103.2820117, 20.9159176],
                [103.2817037, 20.9163916],
                [103.2813458, 20.9168476],
                [103.2806453, 20.9175619],
                [103.2803669, 20.918057],
                [103.2803042, 20.9181584],
                [103.2801725, 20.9189686],
                [103.2801494, 20.9191011],
                [103.2800112, 20.9192766],
                [103.2796541, 20.9195802],
                [103.2793038, 20.9197841],
                [103.2788094, 20.9200576],
                [103.2783525, 20.920355],
                [103.2774877, 20.920926],
                [103.2770407, 20.9211167],
                [103.2769598, 20.9211597],
                [103.2768514, 20.9212877],
                [103.2768534, 20.9214403],
                [103.2769039, 20.9215688],
                [103.2772705, 20.9219634],
                [103.277463, 20.9221637],
                [103.2775369, 20.9223694],
                [103.2775542, 20.9226862],
                [103.2776077, 20.9232438],
                [103.2777495, 20.9235847],
                [103.2778358, 20.9238138],
                [103.2779657, 20.9240549],
                [103.2781419, 20.9243891],
                [103.2780542, 20.9244943],
                [103.2776347, 20.9247683],
                [103.2772847, 20.92489],
                [103.2770218, 20.9250297],
                [103.2768524, 20.925246],
                [103.2764357, 20.9257034],
                [103.276327, 20.9258261],
                [103.2760475, 20.9259716],
                [103.275435, 20.9261568],
                [103.2750974, 20.9262668],
                [103.2749035, 20.9263539],
                [103.2747595, 20.9264531],
                [103.2745773, 20.9267163],
                [103.2744685, 20.9273084],
                [103.2744725, 20.9277543],
                [103.2745819, 20.9283649],
                [103.2748973, 20.9289471],
                [103.2750761, 20.9294172],
                [103.2753961, 20.9299704],
                [103.2754468, 20.9300205],
                [103.2755792, 20.9302271],
                [103.2755903, 20.9302967],
                [103.2755612, 20.930441],
                [103.2755491, 20.9305641],
                [103.2755429, 20.9306924],
                [103.2755308, 20.9308316],
                [103.2755132, 20.9309866],
                [103.275475, 20.9312407],
                [103.2754622, 20.9315136],
                [103.2754617, 20.9316205],
                [103.2754498, 20.9317489],
                [103.2754496, 20.931791],
                [103.2753408, 20.9327388],
                [103.2752689, 20.9334191],
                [103.2751524, 20.9342752],
                [103.2751126, 20.9347795],
                [103.274928, 20.9355297],
                [103.2748861, 20.9356659],
                [103.2747823, 20.9360043],
                [103.2747625, 20.9361919],
                [103.2747877, 20.9363214],
                [103.2748047, 20.9365265],
                [103.2749095, 20.936791],
                [103.275008, 20.9370671],
                [103.2750987, 20.9376777],
                [103.2751089, 20.9381411],
                [103.2751558, 20.9389411],
                [103.2751906, 20.9390284],
                [103.2752525, 20.9391518],
                [103.2753145, 20.9392483],
                [103.2754839, 20.9394364],
                [103.2755828, 20.939509],
                [103.2756961, 20.9395791],
                [103.2758656, 20.9396313],
                [103.2764187, 20.9397183],
                [103.2768908, 20.9398154],
                [103.2771936, 20.9399909],
                [103.2774116, 20.9402453],
                [103.2776469, 20.9404681],
                [103.2778104, 20.9406355],
                [103.2780254, 20.9408186],
                [103.278479, 20.9411121],
                [103.2787463, 20.9412951],
                [103.2790136, 20.9415309],
                [103.2792688, 20.9416729],
                [103.2797861, 20.9417924],
                [103.2802038, 20.9419115],
                [103.280328, 20.942],
                [103.2805581, 20.9422121],
                [103.2811157, 20.9426798],
                [103.2812131, 20.9427565],
                [103.2813812, 20.9429158],
                [103.2815409, 20.943046],
                [103.2816331, 20.9431386],
                [103.281761, 20.9433225],
                [103.2818508, 20.9434722],
                [103.2819683, 20.9436312],
                [103.2820857, 20.9437584],
                [103.2822032, 20.9439014],
                [103.282371, 20.9440922],
                [103.28264, 20.9443152],
                [103.2829421, 20.944665],
                [103.2834115, 20.9452847],
                [103.2835117, 20.9455071],
                [103.283598, 20.9457142],
                [103.2836789, 20.9458404],
                [103.2837759, 20.9459263],
                [103.2839143, 20.9460157],
                [103.284234, 20.9462071],
                [103.2844978, 20.9463547],
                [103.2847438, 20.9465351],
                [103.28494, 20.946733],
                [103.2851074, 20.9470505],
                [103.2854359, 20.9474987],
                [103.2856528, 20.9478047],
                [103.2858642, 20.9479816],
                [103.2861135, 20.9480471],
                [103.2864257, 20.9480075],
                [103.2868317, 20.9479387],
                [103.2873506, 20.9477532],
                [103.2880258, 20.9475154],
                [103.2884141, 20.9472472],
                [103.2887584, 20.9469964],
                [103.2891589, 20.9467927],
                [103.2895533, 20.9466022],
                [103.2902585, 20.9461373],
                [103.2906985, 20.9458223],
                [103.2910709, 20.9455863],
                [103.2915924, 20.9452607],
                [103.291955, 20.9454197],
                [103.2924163, 20.9455448],
                [103.2928651, 20.9456757],
                [103.293102, 20.9457237],
                [103.2935202, 20.9457079],
                [103.2938695, 20.9457503],
                [103.294237, 20.945922],
                [103.2946354, 20.9461348],
                [103.2949285, 20.9461888],
                [103.2952902, 20.9462255],
                [103.2956584, 20.9462389],
                [103.2959389, 20.9463104],
                [103.2962687, 20.9465229],
                [103.2966171, 20.9467708],
                [103.2971336, 20.9470779],
                [103.297812, 20.9474679],
                [103.297993, 20.9475823],
                [103.2981765, 20.9476907],
                [103.2979962, 20.9481258],
                [103.2977632, 20.9485767],
                [103.2975742, 20.9489689],
                [103.2974034, 20.9494669],
                [103.2973329, 20.9498597],
                [103.2973856, 20.9506108],
                [103.2973582, 20.9511269],
                [103.2974622, 20.9515849],
                [103.2974605, 20.9519664],
                [103.2974334, 20.9523769],
                [103.2973817, 20.9527697],
                [103.2973119, 20.9530041],
                [103.2972237, 20.9531855],
                [103.2969913, 20.9535249],
                [103.296659, 20.9538814],
                [103.2965142, 20.9541038],
                [103.2962679, 20.9547247],
                [103.2961222, 20.9551699],
                [103.2960835, 20.955469],
                [103.2961569, 20.955745],
                [103.2961743, 20.9560502],
                [103.2961668, 20.956314],
                [103.2959433, 20.9568657],
                [103.2958399, 20.9570668],
                [103.2956062, 20.9574322],
                [103.2953927, 20.9576896],
                [103.295317, 20.9578652],
                [103.2953161, 20.9580471],
                [103.2953959, 20.9583232],
                [103.2955371, 20.9588341],
                [103.2956978, 20.959181],
                [103.2958647, 20.9595336],
                [103.2959572, 20.9597862],
                [103.2959747, 20.9600328],
                [103.2958804, 20.9605793],
                [103.2958468, 20.9609244],
                [103.2958447, 20.9613762],
                [103.2958218, 20.9618277],
                [103.2958189, 20.9621765],
                [103.2958885, 20.9624376],
                [103.2960311, 20.9626261],
                [103.2962051, 20.9627969],
                [103.2965161, 20.9630387],
                [103.2969958, 20.9632459],
                [103.2972888, 20.9632882],
                [103.2975319, 20.9633773],
                [103.2978369, 20.9635604],
                [103.2981783, 20.9638124],
                [103.2983643, 20.9639596],
                [103.298551, 20.9641225],
                [103.298784, 20.9644032],
                [103.2989343, 20.9646002],
                [103.2989639, 20.9647],
                [103.2989657, 20.9647131],
                [103.2989732, 20.9648557],
                [103.2989221, 20.9650668],
                [103.2987337, 20.9653417],
                [103.2984265, 20.965622],
                [103.2982133, 20.9658441],
                [103.2977171, 20.9665167],
                [103.2974154, 20.9669614],
                [103.2971508, 20.9674766],
                [103.2969558, 20.9678102],
                [103.2966604, 20.9682138],
                [103.2964949, 20.9684579],
                [103.2964656, 20.9685123],
                [103.2964448, 20.9685951],
                [103.2964461, 20.9686587],
                [103.2964676, 20.9687376],
                [103.2965387, 20.9689114],
                [103.296693, 20.9692523],
                [103.2969283, 20.9696982],
                [103.2972687, 20.970252],
                [103.297523, 20.9706402],
                [103.2975779, 20.9709045],
                [103.2976509, 20.9713095],
                [103.2976432, 20.9716557],
                [103.2976145, 20.9724242],
                [103.2976983, 20.9732242],
                [103.297821, 20.9737516],
                [103.2978986, 20.974124],
                [103.2981115, 20.9747616],
                [103.2980371, 20.9755018],
                [103.2978868, 20.9759208],
                [103.297462, 20.9768047],
                [103.2971212, 20.9775724],
                [103.297068, 20.9780217],
                [103.2970182, 20.9789847],
                [103.2969918, 20.9791746],
                [103.2969224, 20.9794526],
                [103.29683, 20.9797197],
                [103.296465, 20.9800929],
                [103.2963165, 20.9802635],
                [103.296248, 20.9803489],
                [103.2962021, 20.9804236],
                [103.2961903, 20.9805092],
                [103.2961324, 20.980723],
                [103.2960625, 20.9811401],
                [103.2960163, 20.9812897],
                [103.2960042, 20.9814609],
                [103.2960032, 20.9816536],
                [103.2959797, 20.9818462],
                [103.2959956, 20.9820657],
                [103.2959491, 20.9822795],
                [103.2958547, 20.9824477],
                [103.2958088, 20.982576],
                [103.295774, 20.9826882],
                [103.2957622, 20.9828005],
                [103.2957499, 20.9829823],
                [103.295715, 20.9831589],
                [103.2956969, 20.9834049],
                [103.2956849, 20.9835172],
                [103.2956615, 20.9836509],
                [103.2956326, 20.9837792],
                [103.2956095, 20.9838648],
                [103.2955916, 20.9840359],
                [103.295548, 20.9842525],
                [103.295519, 20.9843808],
                [103.2954784, 20.9845305],
                [103.2954609, 20.9846268],
                [103.2954035, 20.9847709],
                [103.2953632, 20.9848832],
                [103.2953117, 20.984974],
                [103.2952056, 20.9851795],
                [103.2951482, 20.9853238],
                [103.2951193, 20.9854468],
                [103.2950959, 20.9855645],
                [103.295084, 20.9856982],
                [103.2950778, 20.9858105],
                [103.2950658, 20.9859389],
                [103.2947921, 20.9862053],
                [103.2945528, 20.986322],
                [103.2944161, 20.9864285],
                [103.2942447, 20.9866312],
                [103.2941073, 20.986866],
                [103.2939929, 20.9870582],
                [103.2938669, 20.9872503],
                [103.2937072, 20.9873887],
                [103.2935474, 20.9875379],
                [103.2934445, 20.9876874],
                [103.2934096, 20.9878692],
                [103.2933637, 20.987976],
                [103.2933292, 20.9880401],
                [103.2932263, 20.9881895],
                [103.2931805, 20.988275],
                [103.2930203, 20.9884989],
                [103.2929386, 20.9886253],
                [103.2928965, 20.9887083],
                [103.2928395, 20.9888445],
                [103.2927529, 20.9889829],
                [103.2926312, 20.9891182],
                [103.2925398, 20.9892356],
                [103.2924486, 20.9893421],
                [103.2923053, 20.9895931],
                [103.2922367, 20.989797],
                [103.2922151, 20.9899336],
                [103.2922387, 20.990203],
                [103.2922448, 20.9905992],
                [103.2922325, 20.9910855],
                [103.2921995, 20.9915718],
                [103.2921271, 20.9918861],
                [103.2919819, 20.9922242],
                [103.2917334, 20.9924949],
                [103.2914885, 20.99278],
                [103.2913965, 20.9929323],
                [103.2912733, 20.9932274],
                [103.2910888, 20.9935795],
                [103.2908946, 20.9938743],
                [103.2907726, 20.9940054],
                [103.2906294, 20.9941593],
                [103.2903239, 20.9943583],
                [103.289952, 20.9945907],
                [103.2897037, 20.9946707],
                [103.2893212, 20.9949394],
                [103.288902, 20.9954105],
                [103.2886486, 20.9957471],
                [103.2884826, 20.9959085],
                [103.2883384, 20.9960093],
                [103.2881761, 20.9960965],
                [103.2879848, 20.9961609],
                [103.2879385, 20.9961765],
                [103.2876795, 20.9961957],
                [103.2874782, 20.9961881],
                [103.2872698, 20.9961738],
                [103.2868102, 20.9960502],
                [103.2865372, 20.9960018],
                [103.2862711, 20.9960344],
                [103.2860046, 20.9961279],
                [103.2858243, 20.996269],
                [103.2857878, 20.9963769],
                [103.2857393, 20.9967347],
                [103.2856882, 20.9968697],
                [103.2856015, 20.9969707],
                [103.2853781, 20.997098],
                [103.2851836, 20.9971648],
                [103.2849822, 20.9971774],
                [103.2846803, 20.9971559],
                [103.2843858, 20.997121],
                [103.2840116, 20.9971801],
                [103.283846, 20.997247],
                [103.2837016, 20.9973409],
                [103.2835787, 20.9974755],
                [103.2835134, 20.9976238],
                [103.2834913, 20.9977251],
                [103.2835124, 20.9978265],
                [103.2835694, 20.9979416],
                [103.2837158, 20.9981583],
                [103.2838873, 20.9983616],
                [103.2841233, 20.9986329],
                [103.284281, 20.9987483],
                [103.2844317, 20.9987964],
                [103.2846545, 20.9988108],
                [103.2849205, 20.9988456],
                [103.285064, 20.9989003],
                [103.2852697, 20.9990225],
                [103.2853016, 20.9990413],
                [103.2861745, 20.9997677],
                [103.285316, 21.0002396],
                [103.2843761, 21.0003503],
                [103.2836945, 21.0002632],
                [103.2831849, 21.000058],
                [103.2826344, 20.999692],
                [103.2801201, 20.9993255],
                [103.2792927, 20.9994734],
                [103.2799655, 21.0001207],
                [103.2805144, 21.0007669],
                [103.2805098, 21.0011744],
                [103.2804724, 21.0017274],
                [103.2802166, 21.0024818],
                [103.2795252, 21.0034647],
                [103.2792122, 21.003811],
                [103.2791484, 21.0039851],
                [103.279393, 21.0042204],
                [103.2797585, 21.00469],
                [103.2798787, 21.0049825],
                [103.2799386, 21.0051578],
                [103.2800602, 21.0053336],
                [103.2804291, 21.0055121],
                [103.2810467, 21.0055766],
                [103.2819746, 21.0055277],
                [103.2840805, 21.0051996],
                [103.2846368, 21.0052052],
                [103.284944, 21.005383],
                [103.2851253, 21.0057342],
                [103.2851832, 21.0060841],
                [103.2852849, 21.006653],
                [103.2853415, 21.0071194],
                [103.2854301, 21.0074698],
                [103.2856884, 21.0078654],
                [103.2860546, 21.0082767],
                [103.2864515, 21.0087176],
                [103.2868181, 21.0090997],
                [103.2869702, 21.0093051],
                [103.2870291, 21.0095678],
                [103.2870883, 21.0098013],
                [103.2871158, 21.0100927],
                [103.2871437, 21.0103551],
                [103.2871401, 21.0106753],
                [103.2870734, 21.0111114],
                [103.2869922, 21.0114454],
                [103.2868044, 21.0116474],
                [103.2864302, 21.0119348],
                [103.2852184, 21.0124758],
                [103.2838523, 21.0129862],
                [103.2834464, 21.0133315],
                [103.2834134, 21.0135059],
                [103.2834733, 21.0136811],
                [103.2836249, 21.0139447],
                [103.2839001, 21.0142096],
                [103.2843315, 21.0143303],
                [103.284764, 21.0143639],
                [103.2852897, 21.01434],
                [103.2859093, 21.0142298],
                [103.2863115, 21.0142047],
                [103.286713, 21.0142379],
                [103.2871906, 21.0143738],
                [103.2882068, 21.0147334],
                [103.2891597, 21.0152089],
                [103.2897747, 21.0155062],
                [103.2901432, 21.0157137],
                [103.2905414, 21.016038],
                [103.2910336, 21.0162468],
                [103.2918962, 21.0165175],
                [103.2921418, 21.0166657],
                [103.2922631, 21.0168706],
                [103.2922609, 21.0170744],
                [103.292196, 21.0173358],
                [103.2920541, 21.0175819],
                [103.2918968, 21.0178133],
                [103.2918025, 21.0179578],
                [103.291737, 21.0182775],
                [103.2917344, 21.0185104],
                [103.2917623, 21.0187728],
                [103.2918218, 21.0189772],
                [103.2920026, 21.0193867],
                [103.2920601, 21.0197658],
                [103.2920578, 21.0199696],
                [103.2920246, 21.020173],
                [103.2919305, 21.0202885],
                [103.2916819, 21.0204025],
                [103.2913413, 21.0204573],
                [103.2908466, 21.0204524],
                [103.2902592, 21.0204465],
                [103.2897035, 21.0203826],
                [103.2893315, 21.0204662],
                [103.2890663, 21.0206819],
                [103.2890643, 21.0208566],
                [103.2890627, 21.0210022],
                [103.289184, 21.0212072],
                [103.2893058, 21.0213541],
                [103.2896127, 21.021561],
                [103.2901649, 21.0219451],
                [103.2909022, 21.0223601],
                [103.2912694, 21.0226842],
                [103.2918638, 21.0234326],
                [103.2919389, 21.0236226],
                [103.2921192, 21.0240902],
                [103.2921144, 21.0244978],
                [103.2920656, 21.0247157],
                [103.2918462, 21.0249757],
                [103.2915667, 21.0250893],
                [103.291319, 21.0251159],
                [103.2909477, 21.0251413],
                [103.2907008, 21.0251097],
                [103.2901144, 21.0250165],
                [103.2893106, 21.0250083],
                [103.2887848, 21.0250322],
                [103.2882882, 21.0252019],
                [103.2879471, 21.0252858],
                [103.2873856, 21.0257168],
                [103.2872271, 21.0260647],
                [103.2872248, 21.0262685],
                [103.2872544, 21.0263853],
                [103.2874382, 21.0265327],
                [103.2877158, 21.0265938],
                [103.2882719, 21.0266285],
                [103.2886721, 21.0267781],
                [103.2890407, 21.0269856],
                [103.2892236, 21.0272204],
                [103.2892524, 21.0273954],
                [103.2892206, 21.0274824],
                [103.2891081, 21.0275909],
                [103.2881849, 21.0276903],
                [103.2871229, 21.0278455],
                [103.2865504, 21.028116],
                [103.2858374, 21.0286614],
                [103.2853804, 21.0291356],
                [103.28514, 21.0295568],
                [103.2850963, 21.0299524],
                [103.2851332, 21.0301554],
                [103.2855355, 21.0310252],
                [103.2861691, 21.032284],
                [103.286436, 21.0329774],
                [103.286756, 21.0341779],
                [103.2867723, 21.0344728],
                [103.2866879, 21.0349877],
                [103.2865674, 21.0352075],
                [103.2863898, 21.0352979],
                [103.2861929, 21.0353511],
                [103.2860162, 21.0353494],
                [103.2857415, 21.0353281],
                [103.2854868, 21.0352887],
                [103.2850943, 21.0352663],
                [103.2847201, 21.0353731],
                [103.2844028, 21.0356462],
                [103.283472, 21.0363367],
                [103.2824385, 21.0374313],
                [103.2820105, 21.0380353],
                [103.2817577, 21.0385849],
                [103.2815739, 21.0392092],
                [103.2815298, 21.0396325],
                [103.2814856, 21.0403004],
                [103.2814128, 21.0413117],
                [103.2814193, 21.041405],
                [103.2814293, 21.041547],
                [103.2813476, 21.0418224],
                [103.2811875, 21.0420787],
                [103.2809486, 21.0423618],
                [103.2799212, 21.0429224],
                [103.2786384, 21.0434988],
                [103.2781074, 21.0435671],
                [103.2776947, 21.0435997],
                [103.2772824, 21.0435956],
                [103.2769476, 21.0436844],
                [103.276611, 21.0439203],
                [103.2764301, 21.0443052],
                [103.2763836, 21.0449127],
                [103.2765529, 21.0455682],
                [103.2768905, 21.0469347],
                [103.2772632, 21.048679],
                [103.2776201, 21.0500824],
                [103.2777119, 21.0506359],
                [103.2776493, 21.0509485],
                [103.2774506, 21.051149],
                [103.2771543, 21.0513118],
                [103.2766024, 21.0514904],
                [103.2761691, 21.0516149],
                [103.2752439, 21.0518081],
                [103.2749097, 21.0518509],
                [103.2745138, 21.0521231],
                [103.274373, 21.0523979],
                [103.2743115, 21.0526368],
                [103.2743076, 21.0529683],
                [103.274342, 21.0533922],
                [103.2744554, 21.0537803],
                [103.2747014, 21.0545747],
                [103.2748346, 21.0549536],
                [103.2749274, 21.0554151],
                [103.2749622, 21.0558023],
                [103.2750372, 21.0561161],
                [103.2751706, 21.0564674],
                [103.2754795, 21.0569311],
                [103.2759063, 21.0573774],
                [103.2763151, 21.0576947],
                [103.2767645, 21.0578834],
                [103.2773132, 21.0579811],
                [103.2781167, 21.0581182],
                [103.2785078, 21.0582696],
                [103.2786633, 21.0584],
                [103.2788567, 21.0586598],
                [103.2789134, 21.0588446],
                [103.2789696, 21.0590846],
                [103.2789485, 21.0592133],
                [103.2788083, 21.0594514],
                [103.2786485, 21.0596892],
                [103.2779137, 21.0604001],
                [103.2774585, 21.0607086],
                [103.2768266, 21.0610153],
                [103.2760781, 21.0612103],
                [103.2758795, 21.0614109],
                [103.2758383, 21.0615763],
                [103.2757965, 21.0617968],
                [103.2758138, 21.0619996],
                [103.2758898, 21.0622214],
                [103.2760642, 21.0624258],
                [103.2763363, 21.0626681],
                [103.2766296, 21.0627631],
                [103.2768653, 21.0627655],
                [103.2771207, 21.0627681],
                [103.2774159, 21.0626974],
                [103.2776332, 21.0625891],
                [103.2778123, 21.0623883],
                [103.27819, 21.0619869],
                [103.2786459, 21.0616232],
                [103.2791997, 21.0612788],
                [103.2796543, 21.0610256],
                [103.2805642, 21.0604453],
                [103.2809185, 21.0603753],
                [103.2810948, 21.0604139],
                [103.2813678, 21.0605824],
                [103.2815422, 21.0607868],
                [103.2817158, 21.0610648],
                [103.281849, 21.0614346],
                [103.2818464, 21.0616555],
                [103.2817653, 21.0618759],
                [103.2816055, 21.0621136],
                [103.2812268, 21.0625887],
                [103.2809677, 21.062936],
                [103.2809058, 21.0631933],
                [103.2809028, 21.0634511],
                [103.2809592, 21.0636727],
                [103.2810733, 21.063987],
                [103.2813032, 21.0645051],
                [103.2815926, 21.06495],
                [103.2819014, 21.0654136],
                [103.2821292, 21.0657432],
                [103.2823126, 21.0657883],
                [103.2831505, 21.0657967],
                [103.2842936, 21.0657368],
                [103.2852126, 21.0653176],
                [103.2870666, 21.0630505],
                [103.2891377, 21.0617856],
                [103.2903604, 21.0614407],
                [103.2913513, 21.0613793],
                [103.2918852, 21.0613132],
                [103.2929564, 21.0608955],
                [103.2940996, 21.0608354],
                [103.2947817, 21.061128],
                [103.295541, 21.06135],
                [103.2959218, 21.0613539],
                [103.2963837, 21.0609299],
                [103.2969226, 21.0604353],
                [103.2981493, 21.0597332],
                [103.2993694, 21.0596026],
                [103.3018795, 21.0599134],
                [103.3032487, 21.06007],
                [103.304003, 21.0607203],
                [103.3046019, 21.0616551],
                [103.3047452, 21.0624422],
                [103.3047347, 21.0633706],
                [103.3047299, 21.0637992],
                [103.3044941, 21.0644396],
                [103.3041051, 21.0651502],
                [103.30417, 21.0661508],
                [103.3043053, 21.0676522],
                [103.3039942, 21.0682205],
                [103.3036109, 21.068431],
                [103.3030014, 21.0684249],
                [103.3023162, 21.0684181],
                [103.3011736, 21.0684066],
                [103.2999516, 21.0686801],
                [103.2988789, 21.0692409],
                [103.2981181, 21.0691618],
                [103.2971271, 21.0692232],
                [103.2965145, 21.0695028],
                [103.2958241, 21.0699245],
                [103.2948626, 21.0707362],
                [103.2947792, 21.0713783],
                [103.2948949, 21.0745939],
                [103.294655, 21.0755916],
                [103.2942659, 21.0763019],
                [103.2937254, 21.0769394],
                [103.2930358, 21.0772895],
                [103.2916584, 21.0778471],
                [103.2878416, 21.078523],
                [103.2871543, 21.0786588],
                [103.2869996, 21.0788715],
                [103.2871479, 21.0792302],
                [103.2875984, 21.0798063],
                [103.2920009, 21.0845293],
                [103.2868235, 21.0909059],
                [103.2855825, 21.0928219],
                [103.2855777, 21.0932505],
                [103.2859545, 21.0936114],
                [103.2872438, 21.0941244],
                [103.2892056, 21.0957871],
                [103.28996, 21.0964376],
                [103.2906391, 21.0970159],
                [103.2910135, 21.0975911],
                [103.2912371, 21.098022],
                [103.2918458, 21.0980996],
                [103.2931415, 21.0980411],
                [103.294593, 21.0976985],
                [103.2978121, 21.0960166],
                [103.2988853, 21.095456],
                [103.3002589, 21.0952554],
                [103.3023165, 21.0952046],
                [103.3037647, 21.0951477],
                [103.3052875, 21.0952343],
                [103.3072617, 21.0958254],
                [103.3083274, 21.0959075],
                [103.3093226, 21.0954889],
                [103.3099377, 21.094995],
                [103.3104774, 21.0944289],
                [103.3110219, 21.0934344],
                [103.3112585, 21.0927224],
                [103.3114142, 21.0924382],
                [103.3120268, 21.0921586],
                [103.3132489, 21.0918851],
                [103.3138567, 21.0920339],
                [103.3142311, 21.0926091],
                [103.3144516, 21.0933256],
                [103.3150433, 21.0949029],
                [103.3153408, 21.0955487],
                [103.315643, 21.0957661],
                [103.316024, 21.0957699],
                [103.3167873, 21.0956346],
                [103.3198154, 21.093986],
                [103.3225722, 21.0927275],
                [103.3246324, 21.0924621],
                [103.3315011, 21.0913869],
                [103.3327216, 21.0912561],
                [103.3333335, 21.0910478],
                [103.3339484, 21.0905539],
                [103.3347991, 21.0894193],
                [103.3393302, 21.0861778],
                [103.3425067, 21.084816],
                [103.3438074, 21.0843287],
                [103.345803, 21.082991],
                [103.346652, 21.0819992],
                [103.3476549, 21.080866],
                [103.3512651, 21.0781868],
                [103.3571876, 21.0730296],
                [103.3581079, 21.0724671],
                [103.3589465, 21.0724037],
                [103.3600145, 21.0722712],
                [103.3610063, 21.0721379],
                [103.3631131, 21.0710511],
                [103.3639548, 21.070702],
                [103.364565, 21.0706365],
                [103.3653274, 21.0705724],
                [103.3658629, 21.0703632],
                [103.3665531, 21.0699413],
                [103.3686245, 21.068604],
                [103.3697716, 21.0681864],
                [103.3709173, 21.0679117],
                [103.3720622, 21.0677083],
                [103.3731308, 21.0675043],
                [103.3739727, 21.0671553],
                [103.3748927, 21.0665926],
                [103.3769555, 21.0660408],
                [103.3776465, 21.0655474],
                [103.3778804, 21.0650497],
                [103.3778867, 21.0644782],
                [103.3778998, 21.0632639],
                [103.3782113, 21.0626241],
                [103.3786723, 21.0622713],
                [103.379287, 21.0617771],
                [103.3810449, 21.0612224],
                [103.3845609, 21.060113],
                [103.3852449, 21.0602623],
                [103.3856979, 21.0606238],
                [103.3859965, 21.0611981],
                [103.3861449, 21.0615567],
                [103.3866019, 21.061561],
                [103.3872119, 21.0614954],
                [103.3878991, 21.0613591],
                [103.3884322, 21.0613641],
                [103.3890377, 21.061727],
                [103.3894147, 21.0620878],
                [103.3901671, 21.0629522],
                [103.3908457, 21.0636014],
                [103.3913018, 21.0636772],
                [103.3919128, 21.0635401],
                [103.3928322, 21.0630488],
                [103.3940524, 21.0629175],
                [103.3954949, 21.0633597],
                [103.3978477, 21.0641677],
                [103.3998965, 21.0649014],
                [103.4022923, 21.0652454],
                [103.4036634, 21.0652582],
                [103.4046558, 21.0650533],
                [103.4066454, 21.0642147],
                [103.4074839, 21.0641513],
                [103.408775, 21.0645205],
                [103.4100644, 21.0650326],
                [103.4114332, 21.0652597],
                [103.413339, 21.0651347],
                [103.4168397, 21.065453],
                [103.4184362, 21.0657537],
                [103.4191934, 21.0661894],
                [103.4212499, 21.0662084],
                [103.4226195, 21.066364],
                [103.4238261, 21.0675182],
                [103.4241979, 21.0683789],
                [103.424259, 21.0698081],
                [103.4240555, 21.0710564],
                [103.4230525, 21.0722614],
                [103.4218955, 21.0736079],
                [103.4215787, 21.0747479],
                [103.4213367, 21.0760315],
                [103.4214022, 21.0770321],
                [103.4219129, 21.0791799],
                [103.4218231, 21.0804649],
                [103.4214316, 21.0814613],
                [103.4195829, 21.0833728],
                [103.4155734, 21.0879072],
                [103.4141184, 21.088608],
                [103.4137352, 21.0888187],
                [103.4132758, 21.0890286],
                [103.4131966, 21.0893136],
                [103.4131935, 21.0895994],
                [103.4134915, 21.090245],
                [103.4137886, 21.0909622],
                [103.4137064, 21.0915328],
                [103.4130025, 21.0932406],
                [103.4124463, 21.0953784],
                [103.412589, 21.0963084],
                [103.4128807, 21.0975256],
                [103.4130225, 21.0985269],
                [103.4128632, 21.0991684],
                [103.4126278, 21.0998091],
                [103.4120109, 21.1005176],
                [103.4090137, 21.1029182],
                [103.4083958, 21.1036982],
                [103.4083113, 21.1044832],
                [103.4079982, 21.105266],
                [103.4068423, 21.1075835],
                [103.4065913, 21.1081441],
                [103.4063412, 21.1088614],
                [103.4061121, 21.1095219],
                [103.4054194, 21.1100835],
                [103.4046056, 21.1106439],
                [103.4042791, 21.1109438],
                [103.4041148, 21.1112073],
                [103.4040288, 21.1116989],
                [103.4041471, 21.111965],
                [103.4045057, 21.1124228],
                [103.4048227, 21.1129939],
                [103.4051369, 21.11383],
                [103.4054536, 21.1144389],
                [103.4055687, 21.115008],
                [103.405645, 21.1154253],
                [103.405559, 21.1159168],
                [103.405433, 21.1163701],
                [103.4051837, 21.1170115],
                [103.4051763, 21.1176931],
                [103.4052304, 21.1183184],
                [103.4054279, 21.118737],
                [103.4055861, 21.1190414],
                [103.4058237, 21.119498],
                [103.4060607, 21.1199926],
                [103.4061771, 21.1204481],
                [103.4060702, 21.1209963],
                [103.4059025, 21.1215627],
                [103.4057349, 21.1221292],
                [103.4058528, 21.1224333],
                [103.4060924, 21.1227007],
                [103.4064119, 21.1230445],
                [103.4066914, 21.1233501],
                [103.4070116, 21.1236181],
                [103.4073719, 21.1239245],
                [103.4076107, 21.1242676],
                [103.4076066, 21.1246463],
                [103.4075217, 21.1250242],
                [103.4072738, 21.1255521],
                [103.4066753, 21.1267394],
                [103.4063861, 21.1273424],
                [103.4062181, 21.1279468],
                [103.4061324, 21.1284005],
                [103.4060872, 21.1288545],
                [103.4062028, 21.1293857],
                [103.4062782, 21.1298788],
                [103.4061922, 21.1303703],
                [103.4060661, 21.1308236],
                [103.4053701, 21.131688],
                [103.4048956, 21.1326114],
                [103.4047248, 21.1334808],
                [103.4046366, 21.1341617],
                [103.404507, 21.1349557],
                [103.4042525, 21.1360895],
                [103.4041159, 21.1375272],
                [103.4039691, 21.1380371],
                [103.4037193, 21.1387163],
                [103.4035554, 21.138942],
                [103.4034321, 21.1391302],
                [103.4029426, 21.13958],
                [103.4018409, 21.1405922],
                [103.40135, 21.1411556],
                [103.4011424, 21.1416838],
                [103.4009302, 21.1426285],
                [103.4008046, 21.1430439],
                [103.4006381, 21.1434968],
                [103.4004713, 21.1439875],
                [103.4002665, 21.1442507],
                [103.400062, 21.144476],
                [103.3996141, 21.1448126],
                [103.3988578, 21.1453047],
                [103.3983, 21.1457439],
                [103.3979803, 21.146062],
                [103.3976871, 21.1463803],
                [103.3975799, 21.1465521],
                [103.3974987, 21.1467488],
                [103.3974692, 21.1470449],
                [103.3974129, 21.1473901],
                [103.3973495, 21.1483773],
                [103.3972609, 21.1492655],
                [103.3972282, 21.1498579],
                [103.3971204, 21.1500791],
                [103.3969085, 21.1501759],
                [103.3967757, 21.1502735],
                [103.396696, 21.1503468],
                [103.396615, 21.1505189],
                [103.3964974, 21.150834],
                [103.3963795, 21.151063],
                [103.3963635, 21.1511982],
                [103.3963622, 21.1513201],
                [103.3964181, 21.1514967],
                [103.3965028, 21.1516735],
                [103.3966591, 21.1519187],
                [103.3967444, 21.1520414],
                [103.3967724, 21.1521365],
                [103.3967712, 21.1522447],
                [103.3967416, 21.1523121],
                [103.3966782, 21.152396],
                [103.397258, 21.1525252],
                [103.3976067, 21.152748],
                [103.3984248, 21.1528656],
                [103.3994184, 21.1529846],
                [103.4005865, 21.1532151],
                [103.4015784, 21.1534987],
                [103.4023367, 21.1537253],
                [103.4033273, 21.1541187],
                [103.4039067, 21.1546727],
                [103.4044854, 21.1552816],
                [103.4050618, 21.1561098],
                [103.4055229, 21.1567724],
                [103.4057482, 21.1575974],
                [103.4059729, 21.1584772],
                [103.4062607, 21.1589188],
                [103.4065462, 21.1595797],
                [103.4068916, 21.1601315],
                [103.4072381, 21.1605736],
                [103.4075856, 21.1609061],
                [103.4079918, 21.1612391],
                [103.4084553, 21.1616823],
                [103.4089177, 21.1622351],
                [103.4094378, 21.1628434],
                [103.4099592, 21.163342],
                [103.4104227, 21.1637853],
                [103.4110624, 21.1641752],
                [103.4116447, 21.164455],
                [103.412227, 21.1647348],
                [103.4126905, 21.165178],
                [103.4131553, 21.1655114],
                [103.4137978, 21.1656273],
                [103.4143251, 21.1655772],
                [103.4149121, 21.1654182],
                [103.4154412, 21.1652038],
                [103.4158531, 21.1649883],
                [103.4162078, 21.1646624],
                [103.4166854, 21.1637891],
                [103.4170748, 21.1629426],
                [103.4181971, 21.1619656],
                [103.4203747, 21.160834],
                [103.4222584, 21.1598093],
                [103.423313, 21.1597095],
                [103.4237223, 21.1597681],
                [103.424305, 21.1599929],
                [103.4249453, 21.160328],
                [103.4253503, 21.1607706],
                [103.425698, 21.161103],
                [103.4261047, 21.1613811],
                [103.42657, 21.1616597],
                [103.4273285, 21.1618861],
                [103.4290732, 21.1629446],
                [103.4306672, 21.1644131],
                [103.4326345, 21.1665708],
                [103.4335588, 21.1677313],
                [103.4341279, 21.1692726],
                [103.4348188, 21.1731464],
                [103.4352587, 21.1758384],
                [103.4357051, 21.177927],
                [103.4358581, 21.1800678],
                [103.4355562, 21.1809428],
                [103.4354317, 21.1816548],
                [103.4353094, 21.1821473],
                [103.4352445, 21.1827502],
                [103.4353854, 21.1832726],
                [103.4369897, 21.1865788],
                [103.4380168, 21.1891117],
                [103.4380109, 21.1896602],
                [103.4379438, 21.1904825],
                [103.4374669, 21.1913009],
                [103.4370484, 21.1921198],
                [103.4370386, 21.1930523],
                [103.4371135, 21.1942873],
                [103.4373883, 21.1959904],
                [103.4374358, 21.1970331],
                [103.4374277, 21.197801],
                [103.4374185, 21.1986787],
                [103.4372348, 21.1994449],
                [103.4368761, 21.2001548],
                [103.435418, 21.2024177],
                [103.4342246, 21.2045461],
                [103.4337476, 21.2053646],
                [103.4330955, 21.2061265],
                [103.4327987, 21.2065078],
                [103.4325004, 21.2070535],
                [103.4323781, 21.2075461],
                [103.432316, 21.2078747],
                [103.4323119, 21.2082585],
                [103.4323067, 21.2087522],
                [103.432359, 21.2093561],
                [103.4324117, 21.2099052],
                [103.4324019, 21.2108377],
                [103.432425, 21.2114139],
                [103.4323624, 21.2117972],
                [103.4322952, 21.2126195],
                [103.4323491, 21.2130588],
                [103.4325183, 21.2136639],
                [103.4327478, 21.2141048],
                [103.4332661, 21.2149325],
                [103.4337286, 21.2154853],
                [103.4340741, 21.216037],
                [103.43407, 21.216421],
                [103.4340075, 21.2168044],
                [103.4338869, 21.2171324],
                [103.4337669, 21.2174057],
                [103.4337038, 21.2178439],
                [103.4337588, 21.2181735],
                [103.4339907, 21.2183951],
                [103.43428, 21.2187269],
                [103.4345107, 21.2190582],
                [103.4344771, 21.2194693],
                [103.4343553, 21.2199071],
                [103.4339989, 21.2203975],
                [103.4332326, 21.220884],
                [103.4325815, 21.2215362],
                [103.4323433, 21.221918],
                [103.4322216, 21.2223558],
                [103.4320413, 21.2227929],
                [103.4320958, 21.2231774],
                [103.4321509, 21.2235071],
                [103.4322633, 21.223947],
                [103.432377, 21.2242772],
                [103.4326656, 21.2246639],
                [103.4330122, 21.2251059],
                [103.4331833, 21.2255463],
                [103.4331763, 21.2262045],
                [103.4325474, 21.2275427],
                [103.432363, 21.2283638],
                [103.4324169, 21.2288031],
                [103.4325874, 21.2292984],
                [103.4328738, 21.2299045],
                [103.4332199, 21.2304014],
                [103.433508, 21.230843],
                [103.4337363, 21.2313937],
                [103.4338482, 21.2318883],
                [103.4339016, 21.2323826],
                [103.433839, 21.232766],
                [103.4338344, 21.2332048],
                [103.4337711, 21.233643],
                [103.433527, 21.2345734],
                [103.4332494, 21.2359147],
                [103.4331804, 21.2369014],
                [103.4331781, 21.2371209],
                [103.4332349, 21.2372859],
                [103.4342246, 21.2378438],
                [103.4347487, 21.2381229],
                [103.4351562, 21.2383461],
                [103.4356785, 21.2387898],
                [103.4360797, 21.2396164],
                [103.4363069, 21.2402767],
                [103.4363024, 21.2407155],
                [103.4362409, 21.2409892],
                [103.4361209, 21.2412624],
                [103.4358844, 21.2414797],
                [103.4354124, 21.2418044],
                [103.4345886, 21.2421807],
                [103.4340002, 21.2424495],
                [103.4335874, 21.24272],
                [103.4331735, 21.2431001],
                [103.4328146, 21.24381],
                [103.4326008, 21.2446582],
                [103.4322377, 21.2457519],
                [103.4321119, 21.2465736],
                [103.4319233, 21.2477787],
                [103.4315612, 21.2488176],
                [103.4308451, 21.2500726],
                [103.4307327, 21.2508216],
                [103.4307299, 21.2510901],
                [103.4308062, 21.2512616],
                [103.4308817, 21.2515064],
                [103.4309822, 21.2518735],
                [103.4310832, 21.2521672],
                [103.4312097, 21.2525345],
                [103.4314398, 21.2529515],
                [103.431577, 21.2535263],
                [103.4315976, 21.254039],
                [103.431595, 21.2542831],
                [103.4316177, 21.2546006],
                [103.4316675, 21.2548207],
                [103.431743, 21.2550655],
                [103.431871, 21.2552863],
                [103.4322048, 21.2557531],
                [103.433322, 21.2572888],
                [103.4347359, 21.259108],
                [103.435273, 21.2600647],
                [103.4355559, 21.2604091],
                [103.4361276, 21.2605608],
                [103.4368551, 21.2607628],
                [103.4372304, 21.2609982],
                [103.4374368, 21.2611953],
                [103.4383634, 21.2622777],
                [103.438724, 21.2626716],
                [103.4390318, 21.2631381],
                [103.4392624, 21.2635064],
                [103.4393125, 21.2637021],
                [103.4392838, 21.2639458],
                [103.43923, 21.2641162],
                [103.4390824, 21.2645174],
                [103.4388678, 21.2651012],
                [103.4388128, 21.2653692],
                [103.4387576, 21.2656616],
                [103.4387541, 21.2660033],
                [103.4389256, 21.2670299],
                [103.439058, 21.2680682],
                [103.4391517, 21.2690697],
                [103.4391202, 21.269582],
                [103.4390611, 21.2702404],
                [103.4387617, 21.2714579],
                [103.438626, 21.2719692],
                [103.4385707, 21.2722615],
                [103.4385682, 21.2725055],
                [103.4385918, 21.2727255],
                [103.4386425, 21.2728724],
                [103.438858, 21.2731147],
                [103.4394982, 21.2736079],
                [103.4404013, 21.274825],
                [103.440728, 21.2754129],
                [103.4408831, 21.2765059],
                [103.4408373, 21.2768953],
                [103.4407908, 21.2773628],
                [103.4406605, 21.2778683],
                [103.4402735, 21.2790345],
                [103.4399505, 21.2800646],
                [103.4393464, 21.2820864],
                [103.4390871, 21.2829808],
                [103.4390323, 21.2842278],
                [103.4389453, 21.284578],
                [103.438942, 21.2848898],
                [103.4389812, 21.2851241],
                [103.4390659, 21.2851916],
                [103.438921, 21.2855205],
                [103.4391404, 21.2863783],
                [103.4392711, 21.2869787],
                [103.4393572, 21.287493],
                [103.4393061, 21.288006],
                [103.4393033, 21.2882627],
                [103.438978, 21.2887732],
                [103.4382628, 21.2894299],
                [103.4377069, 21.2901522],
                [103.4371861, 21.2918591],
                [103.4371825, 21.2922014],
                [103.4368061, 21.293225],
                [103.4361554, 21.2942459],
                [103.4355533, 21.2950106],
                [103.435109, 21.2959693],
                [103.4349157, 21.2969517],
                [103.4344408, 21.298659],
                [103.4335113, 21.3022664],
                [103.4323756, 21.305936],
                [103.4314823, 21.3082813],
                [103.4312465, 21.3089637],
                [103.4312139, 21.3098835],
                [103.4311143, 21.3106528],
                [103.4308329, 21.3113348],
                [103.4302771, 21.3120144],
                [103.429678, 21.3124795],
                [103.4288958, 21.3129857],
                [103.426873, 21.3140794],
                [103.4232159, 21.3162917],
                [103.4208294, 21.3172108],
                [103.417618, 21.3185568],
                [103.4159342, 21.3192845],
                [103.4144077, 21.3193327],
                [103.4129923, 21.319191],
                [103.4104856, 21.3185255],
                [103.409278, 21.3181931],
                [103.4084135, 21.3181089],
                [103.4076834, 21.318102],
                [103.4067642, 21.318068],
                [103.4056287, 21.318032],
                [103.4043045, 21.3179434],
                [103.4028868, 21.3177401],
                [103.401618, 21.3175254],
                [103.4006459, 21.3173896],
                [103.4000248, 21.3173077],
                [103.3995389, 21.3172271],
                [103.3988098, 21.3171189],
                [103.3982419, 21.3171135],
                [103.3976189, 21.3172088],
                [103.3964796, 21.3175271],
                [103.3959097, 21.3176989],
                [103.3951497, 21.3179449],
                [103.3947147, 21.3181686],
                [103.3941702, 21.3184926],
                [103.3933512, 21.3191937],
                [103.3921791, 21.3200434],
                [103.3912249, 21.3207433],
                [103.3908984, 21.3209174],
                [103.3906264, 21.3210668],
                [103.389867, 21.3212621],
                [103.3895408, 21.3214109],
                [103.3892416, 21.3215599],
                [103.3887497, 21.3220363],
                [103.3869274, 21.324209],
                [103.3855043, 21.32574],
                [103.3845483, 21.3265917],
                [103.3829157, 21.3274875],
                [103.3819064, 21.3282627],
                [103.381158, 21.3286986],
                [103.3801554, 21.3288662],
                [103.3788241, 21.3294105],
                [103.3783625, 21.3295832],
                [103.3779283, 21.3297056],
                [103.3775495, 21.3297273],
                [103.3769009, 21.3296704],
                [103.3765234, 21.3295655],
                [103.3761465, 21.3294099],
                [103.3757975, 21.3291787],
                [103.3754205, 21.3290231],
                [103.3750427, 21.3289435],
                [103.3743939, 21.3289119],
                [103.3737985, 21.3289568],
                [103.3735274, 21.3290048],
                [103.3732015, 21.3291282],
                [103.3727939, 21.3293015],
                [103.3721675, 21.3297006],
                [103.3712145, 21.3302737],
                [103.3699339, 21.3311222],
                [103.3695663, 21.3314289],
                [103.3697827, 21.3324646],
                [103.3701241, 21.3332883],
                [103.3711172, 21.335398],
                [103.371454, 21.3366482],
                [103.3716683, 21.3378808],
                [103.3716789, 21.3400794],
                [103.3720834, 21.3415598],
                [103.3731001, 21.3431284],
                [103.3738608, 21.3440873],
                [103.3756631, 21.3459424],
                [103.3787625, 21.3478099],
                [103.3797555, 21.3483609],
                [103.3805754, 21.3503048],
                [103.3818161, 21.3538443],
                [103.3820517, 21.3547325],
                [103.3834001, 21.3564518],
                [103.3848559, 21.3579753],
                [103.3866817, 21.3593218],
                [103.3873737, 21.3601488],
                [103.3876506, 21.3604795],
                [103.3878205, 21.3609734],
                [103.3879047, 21.3628774],
                [103.387902, 21.3647314],
                [103.3879224, 21.366077],
                [103.3879181, 21.3664707],
                [103.3877724, 21.3669615],
                [103.3871317, 21.3678413],
                [103.3867411, 21.3682969],
                [103.3865283, 21.3685246],
                [103.3861747, 21.3687837],
                [103.3851538, 21.3691677],
                [103.3840269, 21.3696163],
                [103.3821805, 21.3701399],
                [103.3804977, 21.3701237],
                [103.3789553, 21.3701087],
                [103.3775184, 21.370062],
                [103.376677, 21.3700539],
                [103.3761858, 21.370082],
                [103.3757282, 21.3702417],
                [103.3749172, 21.3706603],
                [103.3737701, 21.3713547],
                [103.3727935, 21.3718753],
                [103.3721976, 21.3720967],
                [103.3713866, 21.3722656],
                [103.3706046, 21.3722579],
                [103.3695256, 21.3722727],
                [103.367573, 21.3720392],
                [103.3666845, 21.3719042],
                [103.365688, 21.3717684],
                [103.3650417, 21.3716863],
                [103.3638557, 21.3716243],
                [103.3632085, 21.3716179],
                [103.3624261, 21.3716356],
                [103.3620484, 21.3716571],
                [103.3618048, 21.3717305],
                [103.3615866, 21.3719555],
                [103.3613668, 21.3723067],
                [103.3611421, 21.3731123],
                [103.360874, 21.3741824],
                [103.360786, 21.3748126],
                [103.3607279, 21.3751907],
                [103.360724, 21.375544],
                [103.3608552, 21.3758734],
                [103.3612246, 21.376609],
                [103.3614061, 21.377267],
                [103.3615358, 21.3777226],
                [103.3615325, 21.3780255],
                [103.3614502, 21.3781509],
                [103.3610645, 21.3788791],
                [103.3603862, 21.3804501],
                [103.3600315, 21.3820242],
                [103.3598267, 21.3834609],
                [103.3594884, 21.3847702],
                [103.3590257, 21.3863432],
                [103.3586906, 21.3873748],
                [103.3582715, 21.3886832],
                [103.3578568, 21.3895879],
                [103.3576921, 21.3898387],
                [103.3574173, 21.3902903],
                [103.3569251, 21.3908913],
                [103.3565433, 21.3912662],
                [103.3560047, 21.391607],
                [103.3555595, 21.3914809],
                [103.355299, 21.3914783],
                [103.35474, 21.3915424],
                [103.35446, 21.3916093],
                [103.3542172, 21.3916941],
                [103.3539362, 21.3918654],
                [103.3536168, 21.3921236],
                [103.3528358, 21.3929082],
                [103.3520659, 21.3935102],
                [103.3516165, 21.3937497],
                [103.3509255, 21.3939693],
                [103.3507194, 21.3940891],
                [103.3505692, 21.3942096],
                [103.350493, 21.3943655],
                [103.3504164, 21.3945564],
                [103.3502848, 21.394677],
                [103.3500225, 21.3948311],
                [103.3495735, 21.3950357],
                [103.3492543, 21.3952938],
                [103.3489621, 21.395613],
                [103.3485835, 21.396184],
                [103.3475476, 21.3972885],
                [103.3470782, 21.3976495],
                [103.3467046, 21.3977677],
                [103.346332, 21.3977989],
                [103.3459782, 21.3978128],
                [103.3457175, 21.3978276],
                [103.3453626, 21.397946],
                [103.345212, 21.3981013],
                [103.3450982, 21.3982918],
                [103.3450588, 21.3984829],
                [103.3450372, 21.3987439],
                [103.3449973, 21.3989873],
                [103.3449029, 21.3991083],
                [103.3446976, 21.3991585],
                [103.3444926, 21.3991739],
                [103.3438227, 21.3991674],
                [103.3430781, 21.3991773],
                [103.3428362, 21.399175],
                [103.3424641, 21.3991713],
                [103.3419232, 21.3992704],
                [103.34097, 21.3996267],
                [103.3398365, 21.3997912],
                [103.3393379, 21.4012524],
                [103.3389615, 21.4022254],
                [103.3386285, 21.4039805],
                [103.3376319, 21.4067301],
                [103.3370911, 21.4083853],
                [103.3363933, 21.4100877],
                [103.3360163, 21.4111096],
                [103.3358032, 21.4114982],
                [103.3355383, 21.4118374],
                [103.3352224, 21.4120786],
                [103.3350136, 21.4120765],
                [103.3340763, 21.4118717],
                [103.3326676, 21.411809],
                [103.3318841, 21.4118499],
                [103.3315183, 21.4118951],
                [103.3312034, 21.4120385],
                [103.3309404, 21.4122312],
                [103.3306216, 21.4127165],
                [103.3303017, 21.4132994],
                [103.3300876, 21.4137856],
                [103.3300302, 21.4142246],
                [103.330078, 21.4146158],
                [103.3303339, 21.415058],
                [103.3306944, 21.4155011],
                [103.3310041, 21.4157972],
                [103.3315761, 21.4159983],
                [103.3325656, 21.4162035],
                [103.3351787, 21.4167296],
                [103.3358224, 21.41692],
                [103.3362411, 21.4171605],
                [103.3366588, 21.4174538],
                [103.3369921, 21.4177987],
                [103.3373246, 21.4181962],
                [103.3377356, 21.41912],
                [103.3381841, 21.4204251],
                [103.3387031, 21.421718],
                [103.3399768, 21.4245817],
                [103.340306, 21.4252683],
                [103.3407728, 21.4262189],
                [103.3413825, 21.4269607],
                [103.3431739, 21.4287522],
                [103.3440928, 21.4294708],
                [103.3443998, 21.4296315],
                [103.3446524, 21.4296602],
                [103.3450172, 21.4296901],
                [103.345523, 21.4296688],
                [103.3459174, 21.4295677],
                [103.3471591, 21.4290544],
                [103.3487431, 21.4280714],
                [103.3501728, 21.4270739],
                [103.3516207, 21.4256954],
                [103.3527794, 21.4250761],
                [103.3531741, 21.4249486],
                [103.3541854, 21.4249322],
                [103.3550554, 21.4249933],
                [103.3558415, 21.4250273],
                [103.3565425, 21.4251393],
                [103.3569906, 21.4252488],
                [103.3573815, 21.4254629],
                [103.3576594, 21.4257284],
                [103.3578516, 21.4261244],
                [103.3580668, 21.4269674],
                [103.3582024, 21.4274155],
                [103.3583918, 21.4280481],
                [103.3585287, 21.4283648],
                [103.3586674, 21.4285238],
                [103.3591441, 21.4286072],
                [103.3599551, 21.4285358],
                [103.361276, 21.4283617],
                [103.3623496, 21.428322],
                [103.363343, 21.4282565],
                [103.3650525, 21.4289257],
                [103.3665519, 21.4291913],
                [103.3683761, 21.4292091],
                [103.3699879, 21.429024],
                [103.3721965, 21.4282424],
                [103.3737059, 21.4276045],
                [103.3748874, 21.4275155],
                [103.3757136, 21.4280004],
                [103.3768874, 21.4286142],
                [103.3783265, 21.4294815],
                [103.3793852, 21.4307969],
                [103.3793192, 21.4319006],
                [103.3791517, 21.4325014],
                [103.3789304, 21.4331016],
                [103.3784922, 21.4339005],
                [103.3780564, 21.4344986],
                [103.3775131, 21.4350958],
                [103.3773488, 21.4353954],
                [103.377454, 21.4355972],
                [103.3775602, 21.4356987],
                [103.3779617, 21.4357778],
                [103.3791487, 21.435187],
                [103.3818427, 21.4342091],
                [103.3830799, 21.4339198],
                [103.3852764, 21.4342422],
                [103.3886971, 21.4354799],
                [103.3909937, 21.4364558],
                [103.3922838, 21.4368583],
                [103.3922533, 21.4367078],
                [103.3923153, 21.4365396],
                [103.3923766, 21.4364277],
                [103.3925586, 21.4362889],
                [103.3929204, 21.4361799],
                [103.3935519, 21.4361579],
                [103.3948783, 21.4358051],
                [103.3962977, 21.4352],
                [103.3979715, 21.4346959],
                [103.3993951, 21.4336971],
                [103.400247, 21.4327492],
                [103.4005841, 21.4321619],
                [103.4007072, 21.43191],
                [103.4007985, 21.4317984],
                [103.4009196, 21.4317152],
                [103.4013711, 21.4316634],
                [103.4017021, 21.4316383],
                [103.402063, 21.4316137],
                [103.4024239, 21.431589],
                [103.4026649, 21.431535],
                [103.4029063, 21.431453],
                [103.4030879, 21.4313423],
                [103.403511, 21.4311213],
                [103.4041144, 21.4309302],
                [103.4049271, 21.4308255],
                [103.4056043, 21.4307335],
                [103.4063283, 21.4304874],
                [103.4076254, 21.4300779],
                [103.4101284, 21.4293144],
                [103.4113054, 21.4288756],
                [103.4116983, 21.4286825],
                [103.4118497, 21.4285715],
                [103.4119714, 21.428432],
                [103.4123193, 21.4280389],
                [103.4123408, 21.4276201],
                [103.4124623, 21.4275088],
                [103.4129419, 21.4276258],
                [103.4133309, 21.4277981],
                [103.4137597, 21.4279556],
                [103.4139596, 21.4280291],
                [103.4142586, 21.4281725],
                [103.4146192, 21.4281759],
                [103.41492, 21.4281506],
                [103.415101, 21.428096],
                [103.4152825, 21.4279853],
                [103.4154042, 21.4278459],
                [103.4154822, 21.4275795],
                [103.4154559, 21.4272418],
                [103.4154592, 21.4269325],
                [103.4154911, 21.4267642],
                [103.4155524, 21.4266522],
                [103.4159143, 21.4265431],
                [103.4174795, 21.426333],
                [103.4183204, 21.4263972],
                [103.4192932, 21.426786],
                [103.4198593, 21.4272413],
                [103.4200663, 21.4275525],
                [103.4203299, 21.4282017],
                [103.420591, 21.4290759],
                [103.4208275, 21.4294437],
                [103.4211557, 21.4296718],
                [103.4232237, 21.4302255],
                [103.4244219, 21.4306024],
                [103.4246918, 21.4306611],
                [103.4252629, 21.4306665],
                [103.4263621, 21.4304659],
                [103.426844, 21.4303861],
                [103.4275355, 21.4303644],
                [103.4286186, 21.4302622],
                [103.4299423, 21.4301621],
                [103.4305437, 21.4301396],
                [103.4308738, 21.430199],
                [103.4311725, 21.4303705],
                [103.4314406, 21.430598],
                [103.4319166, 21.4310523],
                [103.4325427, 21.4315363],
                [103.4329911, 21.4317654],
                [103.4333956, 21.4318957],
                [103.4339061, 21.4319286],
                [103.4345683, 21.4318505],
                [103.4361084, 21.4311899],
                [103.4368329, 21.4308874],
                [103.4370733, 21.4308896],
                [103.4373426, 21.4310046],
                [103.4375804, 21.43126],
                [103.4377583, 21.4314866],
                [103.4379964, 21.4317138],
                [103.4382644, 21.4319412],
                [103.4386834, 21.4321138],
                [103.4393438, 21.4322043],
                [103.4398255, 21.4321245],
                [103.4400074, 21.4319856],
                [103.4401749, 21.4317762],
                [103.4404801, 21.4313292],
                [103.440692, 21.4311905],
                [103.4410534, 21.4311377],
                [103.441384, 21.4311407],
                [103.441834, 21.4312293],
                [103.442223, 21.4313735],
                [103.4425514, 21.4316015],
                [103.4427886, 21.4319131],
                [103.4431151, 21.4323097],
                [103.4434418, 21.4326783],
                [103.4437393, 21.4329624],
                [103.4440068, 21.433246],
                [103.4443645, 21.4335306],
                [103.4446016, 21.4338421],
                [103.4446885, 21.4341521],
                [103.444746, 21.4344059],
                [103.4447749, 21.4345186],
                [103.4449534, 21.4346889],
                [103.4452834, 21.4347481],
                [103.4455536, 21.4347788],
                [103.4460944, 21.4348119],
                [103.4464842, 21.4349],
                [103.4468276, 21.4351141],
                [103.446946, 21.4352839],
                [103.4470339, 21.4355096],
                [103.4470901, 21.4358757],
                [103.4471469, 21.4361856],
                [103.4472052, 21.4363549],
                [103.4473835, 21.4365533],
                [103.4475019, 21.4367231],
                [103.4478293, 21.4370355],
                [103.4482171, 21.4373203],
                [103.4494008, 21.4379658],
                [103.447586, 21.4402521],
                [103.4457729, 21.4423711],
                [103.4454989, 21.442871],
                [103.4463583, 21.4441563],
                [103.4485633, 21.4473175],
                [103.4510595, 21.4504605],
                [103.4539295, 21.4542561],
                [103.4561174, 21.4569355],
                [103.4563799, 21.4575242],
                [103.4564672, 21.4577344],
                [103.4565098, 21.4579441],
                [103.456462, 21.4582369],
                [103.4561287, 21.4601183],
                [103.4556177, 21.4618724],
                [103.4553575, 21.4631891],
                [103.4547052, 21.465612],
                [103.4536893, 21.468534],
                [103.4530905, 21.4701198],
                [103.4530143, 21.4709776],
                [103.4530849, 21.4727789],
                [103.4531922, 21.4753345],
                [103.4534511, 21.478373],
                [103.4533435, 21.4800889],
                [103.453112, 21.4807988],
                [103.4529281, 21.4812577],
                [103.452385, 21.4817971],
                [103.4517059, 21.4825027],
                [103.4493075, 21.4848674],
                [103.4481209, 21.4861118],
                [103.4474644, 21.4868471],
                [103.4473887, 21.4871835],
                [103.447597, 21.4879268],
                [103.4481629, 21.4889432],
                [103.4487294, 21.489892],
                [103.449227, 21.4905707],
                [103.4496536, 21.4911139],
                [103.4499382, 21.4914536],
                [103.450223, 21.4917932],
                [103.4505085, 21.4920655],
                [103.450709, 21.4935503],
                [103.4507553, 21.4959772],
                [103.4510915, 21.498272],
                [103.4510828, 21.4990423],
                [103.4510854, 21.4996008],
                [103.4513481, 21.5005874],
                [103.4517584, 21.5018993],
                [103.452324, 21.5028231],
                [103.4530808, 21.5034643],
                [103.454257, 21.5045852],
                [103.4550928, 21.5055556],
                [103.4554314, 21.505822],
                [103.4562336, 21.506812],
                [103.4568891, 21.5081132],
                [103.4572659, 21.5086527],
                [103.4579265, 21.5094626],
                [103.4583983, 21.5100476],
                [103.4591565, 21.5106799],
                [103.4603936, 21.5112273],
                [103.4609146, 21.5116786],
                [103.4612455, 21.512039],
                [103.4614324, 21.5124426],
                [103.4615243, 21.5128008],
                [103.4614244, 21.5132018],
                [103.4612777, 21.5135131],
                [103.4597697, 21.5160002],
                [103.459116, 21.5167843],
                [103.4588225, 21.5174073],
                [103.4586737, 21.5179422],
                [103.4587172, 21.5183448],
                [103.4590788, 21.5186897],
                [103.4590958, 21.518706],
                [103.4596647, 21.5191582],
                [103.4606652, 21.5194802],
                [103.4614782, 21.519443],
                [103.4638748, 21.5188393],
                [103.4668539, 21.5173919],
                [103.4682943, 21.5167794],
                [103.4687735, 21.5166944],
                [103.4691558, 21.5166979],
                [103.4697285, 21.5167925],
                [103.4702022, 21.5171991],
                [103.4712436, 21.5181919],
                [103.4723336, 21.5190959],
                [103.473284, 21.5196408],
                [103.4736624, 21.5197627],
                [103.4741416, 21.5199169],
                [103.4762676, 21.520048],
                [103.4807121, 21.520178],
                [103.4822417, 21.5201919],
                [103.4829579, 21.5202877],
                [103.4834804, 21.5206053],
                [103.4849511, 21.5216914],
                [103.4867022, 21.5234057],
                [103.4892493, 21.5267362],
                [103.4917872, 21.5286586],
                [103.4937816, 21.5299728],
                [103.4964949, 21.5311147],
                [103.4981166, 21.5314867],
                [103.4987808, 21.5319844],
                [103.4993954, 21.5326604],
                [103.4996795, 21.5329311],
                [103.5002035, 21.5331147],
                [103.501206, 21.5332577],
                [103.5018898, 21.5330507],
                [103.5024704, 21.5330075],
                [103.5028305, 21.5331557],
                [103.503138, 21.5334],
                [103.5035966, 21.5340321],
                [103.5040572, 21.534471],
                [103.5043116, 21.5348598],
                [103.5046718, 21.5350079],
                [103.505189, 21.5349643],
                [103.5067455, 21.5343502],
                [103.507733, 21.5337794],
                [103.5085107, 21.5335448],
                [103.5097513, 21.5335076],
                [103.5110976, 21.5332298],
                [103.5123381, 21.5331924],
                [103.5148699, 21.533215],
                [103.5156229, 21.5332709],
                [103.5170901, 21.5333797],
                [103.5173471, 21.5335269],
                [103.5176541, 21.5338196],
                [103.5179066, 21.5344014],
                [103.5179017, 21.5348844],
                [103.5178451, 21.5353671],
                [103.5176862, 21.5357522],
                [103.5174751, 21.5361851],
                [103.5173147, 21.5367151],
                [103.5173629, 21.5370536],
                [103.5174638, 21.5372961],
                [103.5175661, 21.5373936],
                [103.5178225, 21.5375891],
                [103.5180268, 21.5378326],
                [103.51813, 21.5382212],
                [103.5183465, 21.5387063],
                [103.5183416, 21.5391893],
                [103.5183367, 21.5396724],
                [103.5185811, 21.5402101],
                [103.5193585, 21.5410806],
                [103.5208191, 21.5424164],
                [103.5212078, 21.5428608],
                [103.5213236, 21.5430639],
                [103.5214767, 21.5434695],
                [103.5215316, 21.5438742],
                [103.5215841, 21.5445178],
                [103.5216172, 21.5451428],
                [103.5216108, 21.546732],
                [103.5215983, 21.547963],
                [103.5212416, 21.5492183],
                [103.5210175, 21.5499881],
                [103.5207139, 21.5508673],
                [103.519961, 21.5524131],
                [103.519139, 21.5540043],
                [103.5188647, 21.5548746],
                [103.5187833, 21.5551496],
                [103.5188197, 21.5554255],
                [103.5189161, 21.55561],
                [103.5191111, 21.5557771],
                [103.5193645, 21.5559815],
                [103.5195204, 21.5561298],
                [103.5196165, 21.5563328],
                [103.5196518, 21.5567373],
                [103.5196729, 21.5575551],
                [103.5195693, 21.558087],
                [103.5194288, 21.5583614],
                [103.5192874, 21.5587459],
                [103.5192058, 21.5590393],
                [103.5191839, 21.5592595],
                [103.5192015, 21.5594618],
                [103.5193661, 21.5597113],
                [103.5196193, 21.559934],
                [103.5198903, 21.5604862],
                [103.5199869, 21.5611642],
                [103.5199124, 21.5617116],
                [103.5197698, 21.5621618],
                [103.5196618, 21.5626123],
                [103.5196227, 21.5630633],
                [103.5196181, 21.5635147],
                [103.5196476, 21.5639986],
                [103.5198158, 21.5644193],
                [103.5201231, 21.5647446],
                [103.5206017, 21.5651679],
                [103.5229714, 21.5665709],
                [103.5235773, 21.5659361],
                [103.5239226, 21.5655066],
                [103.5241433, 21.5653493],
                [103.5243635, 21.5652601],
                [103.5248997, 21.5652193],
                [103.5254113, 21.5652239],
                [103.5259717, 21.5652061],
                [103.5263122, 21.5652546],
                [103.5266038, 21.5653255],
                [103.5268707, 21.5654188],
                [103.5271618, 21.5655353],
                [103.5278387, 21.566042],
                [103.5284417, 21.5666393],
                [103.5288534, 21.5668706],
                [103.5293155, 21.5669429],
                [103.5298762, 21.5669024],
                [103.5308158, 21.5667171],
                [103.5315478, 21.566587],
                [103.5320076, 21.5665823],
                [103.5322304, 21.5671722],
                [103.5327233, 21.5679939],
                [103.5330807, 21.5684626],
                [103.5334396, 21.5688045],
                [103.5336183, 21.56906],
                [103.5334803, 21.5692705],
                [103.5332979, 21.5693958],
                [103.5330244, 21.5695627],
                [103.5325691, 21.5698126],
                [103.5322037, 21.5701057],
                [103.5317022, 21.57044],
                [103.5314275, 21.5707338],
                [103.5311968, 21.5711551],
                [103.5311025, 21.5715352],
                [103.5310065, 21.5720845],
                [103.5313275, 21.5739287],
                [103.5316842, 21.5744821],
                [103.5319058, 21.5749496],
                [103.5320388, 21.5752471],
                [103.532079, 21.5757553],
                [103.5320306, 21.5760512],
                [103.5319367, 21.5763891],
                [103.5317957, 21.5768957],
                [103.5318367, 21.5773194],
                [103.5319679, 21.5777862],
                [103.5321436, 21.578338],
                [103.5323195, 21.5788473],
                [103.5323601, 21.5793133],
                [103.5323554, 21.5797789],
                [103.5321705, 21.5801582],
                [103.5319863, 21.5804528],
                [103.5317109, 21.5808314],
                [103.5314351, 21.58121],
                [103.5309336, 21.5815442],
                [103.5301138, 21.5820025],
                [103.5296322, 21.582612],
                [103.5293104, 21.5830748],
                [103.5292993, 21.5841752],
                [103.5294287, 21.5848112],
                [103.5296492, 21.5854058],
                [103.5300495, 21.5861288],
                [103.5305861, 21.5868109],
                [103.5313886, 21.5880877],
                [103.5319687, 21.5889394],
                [103.532731, 21.589708],
                [103.5334953, 21.5902651],
                [103.5340812, 21.5905665],
                [103.5344874, 21.590697],
                [103.5350757, 21.5907445],
                [103.5354847, 21.5906212],
                [103.536531, 21.5901648],
                [103.5375781, 21.5896237],
                [103.5378973, 21.5894149],
                [103.5380784, 21.5894165],
                [103.5381233, 21.5894592],
                [103.5381216, 21.5896285],
                [103.5380263, 21.5900932],
                [103.5379032, 21.5910869],
                [103.5378507, 21.591806],
                [103.5377037, 21.5929052],
                [103.5377422, 21.5935827],
                [103.5377815, 21.5941757],
                [103.5378991, 21.5959968],
                [103.5379376, 21.5966744],
                [103.538109, 21.5976495],
                [103.5383295, 21.598244],
                [103.5384746, 21.5995786],
                [103.5386063, 21.600003],
                [103.538784, 21.6003431],
                [103.5391429, 21.600685],
                [103.5395485, 21.6009002],
                [103.5401796, 21.601202],
                [103.5409454, 21.601632],
                [103.5418462, 21.6021479],
                [103.5422512, 21.6024053],
                [103.5426126, 21.6024931],
                [103.5432019, 21.602456],
                [103.5437018, 21.602291],
                [103.5446111, 21.6019604],
                [103.5453392, 21.6016282],
                [103.5458835, 21.6015482],
                [103.5468778, 21.6017685],
                [103.5474638, 21.6020699],
                [103.5477333, 21.6022839],
                [103.548003, 21.6024979],
                [103.5482266, 21.6027962],
                [103.5484048, 21.603094],
                [103.5485821, 21.6034765],
                [103.5486676, 21.6039852],
                [103.5486181, 21.604408],
                [103.548613, 21.6049159],
                [103.5486072, 21.6055085],
                [103.5485997, 21.6062703],
                [103.5485937, 21.6068628],
                [103.5485873, 21.6074977],
                [103.5486487, 21.6081543],
                [103.549094, 21.60892],
                [103.5495407, 21.6095589],
                [103.5499886, 21.6100707],
                [103.5505723, 21.6105837],
                [103.5512459, 21.6111822],
                [103.5525511, 21.6120402],
                [103.553857, 21.6128135],
                [103.5541275, 21.6129427],
                [103.5543063, 21.6131983],
                [103.5542106, 21.6137054],
                [103.5541166, 21.6140431],
                [103.5541129, 21.614424],
                [103.5541082, 21.6148896],
                [103.5541938, 21.6153984],
                [103.5542827, 21.6155684],
                [103.5547302, 21.6161226],
                [103.5555579, 21.6171668],
                [103.5555998, 21.6175057],
                [103.5555528, 21.6176746],
                [103.5552256, 21.6186877],
                [103.5547609, 21.6198688],
                [103.554302, 21.6204574],
                [103.5541611, 21.620964],
                [103.5542945, 21.6212192],
                [103.5547449, 21.6214771],
                [103.555421, 21.6218216],
                [103.5560976, 21.6221238],
                [103.5565934, 21.6223821],
                [103.5570438, 21.62264],
                [103.557449, 21.6228974],
                [103.5578991, 21.6231976],
                [103.5581234, 21.6234112],
                [103.5582115, 21.6236659],
                [103.5583437, 21.624048],
                [103.5585638, 21.6246849],
                [103.5586951, 21.624903],
                [103.5591656, 21.6256848],
                [103.5595212, 21.6263651],
                [103.5595628, 21.6267465],
                [103.5596051, 21.6270431],
                [103.5595573, 21.6272967],
                [103.5593707, 21.6278453],
                [103.5584982, 21.6290228],
                [103.5570804, 21.630365],
                [103.5565334, 21.6306989],
                [103.5558505, 21.6310317],
                [103.5551222, 21.6313639],
                [103.5541227, 21.6316515],
                [103.5532589, 21.6319403],
                [103.5524859, 21.6322299],
                [103.5516671, 21.6325612],
                [103.5511208, 21.6328105],
                [103.5506201, 21.6330601],
                [103.5503462, 21.6332694],
                [103.5500705, 21.6336479],
                [103.5500857, 21.6343888],
                [103.5501256, 21.6349394],
                [103.5502107, 21.6354904],
                [103.550386, 21.6360845],
                [103.5506062, 21.6367214],
                [103.5506477, 21.6371027],
                [103.550688, 21.6376109],
                [103.5506832, 21.6380765],
                [103.5505897, 21.638372],
                [103.5503593, 21.6387508],
                [103.5495329, 21.6398442],
                [103.5491214, 21.6402216],
                [103.5485743, 21.6405554],
                [103.5481662, 21.6405942],
                [103.5477597, 21.6404636],
                [103.5470822, 21.6402461],
                [103.5464056, 21.6399439],
                [103.5457282, 21.6397263],
                [103.5452311, 21.6395949],
                [103.5449135, 21.6396345],
                [103.5443669, 21.639926],
                [103.5439553, 21.6403034],
                [103.5437254, 21.64064],
                [103.5437219, 21.6409785],
                [103.5437639, 21.6413175],
                [103.5439412, 21.6417001],
                [103.5442085, 21.642168],
                [103.5444769, 21.642509],
                [103.5447466, 21.642723],
                [103.5449706, 21.6429788],
                [103.544924, 21.6431055],
                [103.5448326, 21.6431893],
                [103.5444701, 21.6431861],
                [103.5440174, 21.6431398],
                [103.54352, 21.6430508],
                [103.5430225, 21.6429618],
                [103.5427062, 21.6428744],
                [103.5423885, 21.6429139],
                [103.5421824, 21.6431449],
                [103.5420409, 21.6436939],
                [103.5418986, 21.6443276],
                [103.5418482, 21.6448351],
                [103.541844, 21.6452583],
                [103.5418384, 21.6458085],
                [103.5418804, 21.6461475],
                [103.5419671, 21.6465292],
                [103.542145, 21.6468694],
                [103.5424584, 21.6472531],
                [103.5429973, 21.6477234],
                [103.5439429, 21.6483242],
                [103.5451141, 21.6490118],
                [103.5457453, 21.6493136],
                [103.5461061, 21.6494861],
                [103.5464665, 21.6497008],
                [103.5465994, 21.6499983],
                [103.5466405, 21.6504219],
                [103.5467274, 21.6508037],
                [103.5469048, 21.6511862],
                [103.5471749, 21.6513578],
                [103.5475827, 21.6513614],
                [103.5480815, 21.6513234],
                [103.5484444, 21.6512843],
                [103.548806, 21.6513722],
                [103.5491202, 21.6516712],
                [103.5500666, 21.6521874],
                [103.5504291, 21.6521904],
                [103.5508373, 21.6521517],
                [103.5514749, 21.6518186],
                [103.5520209, 21.6516118],
                [103.5524739, 21.6516157],
                [103.5527, 21.65166],
                [103.5535359, 21.6519001],
                [103.554388, 21.6527964],
                [103.5554656, 21.6537793],
                [103.5565887, 21.6547627],
                [103.5570825, 21.6552325],
                [103.5577575, 21.655704],
                [103.5582106, 21.6557079],
                [103.558709, 21.6557123],
                [103.5591168, 21.6557157],
                [103.5592518, 21.6558016],
                [103.5591592, 21.6560124],
                [103.5589305, 21.656222],
                [103.5586108, 21.6564733],
                [103.5581548, 21.6567656],
                [103.5577885, 21.6571434],
                [103.5575141, 21.657395],
                [103.5573738, 21.657817],
                [103.5572343, 21.6581967],
                [103.557185, 21.6585773],
                [103.557181, 21.6590005],
                [103.5573122, 21.6594672],
                [103.5576435, 21.6603379],
                [103.5583134, 21.6613172],
                [103.5590279, 21.6623816],
                [103.559249, 21.6629338],
                [103.5592897, 21.6633997],
                [103.5595548, 21.6640792],
                [103.5599119, 21.6646327],
                [103.560269, 21.6651859],
                [103.5603994, 21.6657373],
                [103.5603499, 21.6661603],
                [103.5602561, 21.666498],
                [103.5601162, 21.6668778],
                [103.5600227, 21.6671733],
                [103.560065, 21.6674699],
                [103.5601535, 21.6676823],
                [103.5604686, 21.6678967],
                [103.5612797, 21.6683694],
                [103.5634654, 21.6695946],
                [103.5653565, 21.6708385],
                [103.5660765, 21.6713526],
                [103.5665708, 21.6717802],
                [103.5669291, 21.6722065],
                [103.5671507, 21.6727164],
                [103.5674575, 21.6737772],
                [103.5676316, 21.6744983],
                [103.5677151, 21.6752186],
                [103.5677982, 21.6759812],
                [103.5679712, 21.6768292],
                [103.568102, 21.6773383],
                [103.5683245, 21.6777635],
                [103.5687734, 21.6781906],
                [103.5700124, 21.678942],
                [103.5714991, 21.6798438],
                [103.5728046, 21.6807439],
                [103.574108, 21.6818556],
                [103.5752792, 21.6825852],
                [103.5763614, 21.6831447],
                [103.5775818, 21.6834938],
                [103.5782153, 21.6835839],
                [103.5787588, 21.6836309],
                [103.5791662, 21.6836768],
                [103.579574, 21.6836802],
                [103.5800714, 21.6838115],
                [103.5806115, 21.684197],
                [103.581648, 21.6847984],
                [103.5824152, 21.6851437],
                [103.5828672, 21.6852745],
                [103.5832301, 21.6852353],
                [103.5835934, 21.6851537],
                [103.5839123, 21.6849871],
                [103.5841867, 21.6847355],
                [103.5847592, 21.6841266],
                [103.585538, 21.6832868],
                [103.5863171, 21.6824045],
                [103.5867286, 21.6820271],
                [103.5871847, 21.6817347],
                [103.58764, 21.6815269],
                [103.5881422, 21.6811503],
                [103.5885541, 21.6807305],
                [103.588831, 21.6802248],
                [103.589154, 21.6796351],
                [103.5895689, 21.678919],
                [103.5903965, 21.6776985],
                [103.5920465, 21.6758078],
                [103.5929161, 21.6749263],
                [103.593417, 21.6746766],
                [103.5938249, 21.67468],
                [103.5944137, 21.6747273],
                [103.5948655, 21.6748582],
                [103.5950456, 21.6749866],
                [103.595179, 21.6752418],
                [103.5952439, 21.6755597],
                [103.5952869, 21.675814],
                [103.5953252, 21.676534],
                [103.5952688, 21.6776763],
                [103.5954423, 21.678482],
                [103.5956607, 21.6793304],
                [103.5960182, 21.6798414],
                [103.5963323, 21.6801827],
                [103.5969622, 21.6806536],
                [103.5975476, 21.6810395],
                [103.5979077, 21.6812965],
                [103.5981772, 21.6815528],
                [103.5984463, 21.6818514],
                [103.5984887, 21.6821481],
                [103.5985295, 21.682614],
                [103.5987948, 21.6832935],
                [103.597857, 21.6841956],
                [103.5971719, 21.6847401],
                [103.5959417, 21.6854068],
                [103.5954844, 21.6858262],
                [103.5950275, 21.6862034],
                [103.5947062, 21.686624],
                [103.5945661, 21.687046],
                [103.5945145, 21.6876804],
                [103.5944623, 21.6883997],
                [103.5944104, 21.6890765],
                [103.5943148, 21.6895836],
                [103.5941265, 21.6903015],
                [103.593846, 21.691188],
                [103.5930595, 21.6928322],
                [103.5924621, 21.6936736],
                [103.5917316, 21.6942177],
                [103.5912294, 21.6945944],
                [103.5909096, 21.6948456],
                [103.5904502, 21.6954766],
                [103.5901737, 21.6959399],
                [103.5896223, 21.6966971],
                [103.5887962, 21.6977482],
                [103.5878391, 21.6992132],
                [103.5866038, 21.7011994],
                [103.5859223, 21.7022899],
                [103.5854526, 21.7031473],
                [103.5851115, 21.7037318],
                [103.5846465, 21.7041193],
                [103.5842642, 21.7046251],
                [103.5841353, 21.7049372],
                [103.5842169, 21.7051728],
                [103.5845484, 21.7055672],
                [103.5850054, 21.7060019],
                [103.5853797, 21.7063182],
                [103.585671, 21.7065556],
                [103.5859198, 21.7068318],
                [103.5860845, 21.7071465],
                [103.586228, 21.7074805],
                [103.58614, 21.7079104],
                [103.5860106, 21.7082617],
                [103.5857538, 21.7088076],
                [103.5854538, 21.7094707],
                [103.5851548, 21.7100164],
                [103.5844252, 21.7117329],
                [103.5840418, 21.712356],
                [103.5837006, 21.7129405],
                [103.5830632, 21.7137964],
                [103.5824262, 21.7146132],
                [103.5819591, 21.7151965],
                [103.5815745, 21.7159372],
                [103.5810621, 21.7168724],
                [103.5805067, 21.7179248],
                [103.5797842, 21.7188975],
                [103.5791899, 21.7196363],
                [103.5784914, 21.720517],
                [103.5781915, 21.7214201],
                [103.5779463, 21.7221586],
                [103.5774627, 21.7235263],
                [103.5773354, 21.7245129],
                [103.5769084, 21.7261006],
                [103.5764883, 21.726975],
                [103.5761303, 21.7275206],
                [103.5757712, 21.728176],
                [103.5754136, 21.7286668],
                [103.5751166, 21.7289934],
                [103.5748777, 21.7293755],
                [103.574813, 21.7299785],
                [103.5748669, 21.7304729],
                [103.5749796, 21.7309677],
                [103.5751505, 21.7315179],
                [103.5752648, 21.7318481],
                [103.575378, 21.732288],
                [103.5752573, 21.7326163],
                [103.574574, 21.7333512],
                [103.5734439, 21.7347133],
                [103.5724927, 21.7358026],
                [103.5718983, 21.7364558],
                [103.5716568, 21.7371123],
                [103.5717124, 21.737442],
                [103.5725869, 21.738163],
                [103.5732878, 21.738608],
                [103.5736378, 21.7388854],
                [103.573751, 21.7393254],
                [103.5736303, 21.7396536],
                [103.5732748, 21.7399249],
                [103.5727426, 21.7402495],
                [103.5719753, 21.7405721],
                [103.5711508, 21.7407296],
                [103.5705027, 21.7408887],
                [103.5699117, 21.7412128],
                [103.5692602, 21.7417009],
                [103.5681299, 21.7430631],
                [103.566642, 21.7449159],
                [103.5646822, 21.7469292],
                [103.5642082, 21.7473091],
                [103.5636166, 21.7476881],
                [103.5630832, 21.7481226],
                [103.5624922, 21.7484467],
                [103.5620747, 21.7490466],
                [103.5617742, 21.7497024],
                [103.5613558, 21.7503982],
                [103.5609823, 21.7513133],
                [103.5602904, 21.7528158],
                [103.5600873, 21.7534662],
                [103.560062, 21.7538126],
                [103.5600808, 21.7541186],
                [103.5600996, 21.7544245],
                [103.5602653, 21.7553229],
                [103.5603915, 21.7558132],
                [103.5605289, 21.7562731],
                [103.5605249, 21.7566808],
                [103.5604793, 21.7568638],
                [103.5603685, 21.7570259],
                [103.5601261, 21.7572481],
                [103.5592638, 21.7583006],
                [103.5587336, 21.7589075],
                [103.5585997, 21.7591918],
                [103.5583776, 21.7595567],
                [103.5582424, 21.7599633],
                [103.5581961, 21.7602279],
                [103.5582703, 21.760463],
                [103.5584198, 21.7607904],
                [103.5585044, 21.7610766],
                [103.5585895, 21.7613015],
                [103.5586528, 21.7615263],
                [103.5586724, 21.7617507],
                [103.5586269, 21.7619339],
                [103.55845, 21.7621565],
                [103.5580325, 21.7623976],
                [103.5572857, 21.7628191],
                [103.5563413, 21.7633408],
                [103.5559887, 21.7636639],
                [103.5558552, 21.7639074],
                [103.5556983, 21.7643138],
                [103.5556049, 21.764904],
                [103.5554134, 21.7654833],
                [103.5552143, 21.7657467],
                [103.5545785, 21.7659857],
                [103.5536577, 21.7663242],
                [103.5527805, 21.7666835],
                [103.5522953, 21.7671482],
                [103.5519398, 21.7677361],
                [103.5512767, 21.7685253],
                [103.5504392, 21.7692723],
                [103.5494391, 21.7699057],
                [103.54784, 21.7703604],
                [103.5467881, 21.7706978],
                [103.546348, 21.7710201],
                [103.5455096, 21.7718689],
                [103.5435598, 21.7735131],
                [103.5425455, 21.7744623],
                [103.5418606, 21.7752308],
                [103.5416391, 21.7755347],
                [103.5415057, 21.7757577],
                [103.5413939, 21.7760217],
                [103.5412816, 21.7763265],
                [103.5411906, 21.7766926],
                [103.541075, 21.7773032],
                [103.5410283, 21.7776085],
                [103.5410002, 21.7782403],
                [103.5410301, 21.7785157],
                [103.5411156, 21.7787],
                [103.5412229, 21.7788843],
                [103.5413523, 21.7790486],
                [103.5415692, 21.7792136],
                [103.5417642, 21.7793579],
                [103.5420031, 21.7795028],
                [103.5421547, 21.7796265],
                [103.5422627, 21.7797497],
                [103.5423265, 21.7799133],
                [103.5423465, 21.780097],
                [103.5422792, 21.7802594],
                [103.5421243, 21.7804823],
                [103.5418585, 21.7808265],
                [103.5416585, 21.7811714],
                [103.5415016, 21.7815776],
                [103.5414337, 21.7818013],
                [103.5414291, 21.7822497],
                [103.5414483, 21.7825149],
                [103.5415763, 21.7828217],
                [103.5421229, 21.7838561],
                [103.5432807, 21.7860066],
                [103.5434699, 21.7867422],
                [103.5435264, 21.7876397],
                [103.54385, 21.7891204],
                [103.5444184, 21.7912455],
                [103.5447333, 21.7925121],
                [103.5453163, 21.7942602],
                [103.5457008, 21.7951197],
                [103.5462822, 21.7959606],
                [103.547083, 21.7967219],
                [103.5477103, 21.797339],
                [103.5478279, 21.7975949],
                [103.5478245, 21.797921],
                [103.5477771, 21.7982875],
                [103.5477081, 21.7986335],
                [103.5475739, 21.7989381],
                [103.5466202, 21.8003566],
                [103.5458215, 21.8016026],
                [103.5455548, 21.8020188],
                [103.5447456, 21.8031839],
                [103.5436435, 21.8041526],
                [103.5426731, 21.8050409],
                [103.5421631, 21.8057907],
                [103.541914, 21.806665],
                [103.5413891, 21.8077918],
                [103.5407164, 21.8094982],
                [103.5399776, 21.8112447],
                [103.5391892, 21.8125118],
                [103.5386591, 21.8130779],
                [103.5378239, 21.8135598],
                [103.536727, 21.8139985],
                [103.5365293, 21.8140986],
                [103.5365279, 21.8142414],
                [103.5366572, 21.814426],
                [103.5369388, 21.8146528],
                [103.5386968, 21.8158098],
                [103.5391397, 21.8163132],
                [103.5396557, 21.8171331],
                [103.5406825, 21.819303],
                [103.5410843, 21.8206113],
                [103.5416123, 21.8213396],
                [103.5418052, 21.8217082],
                [103.5418691, 21.8218719],
                [103.5418675, 21.8220348],
                [103.5418648, 21.8222999],
                [103.5418387, 21.8227074],
                [103.5417697, 21.8230533],
                [103.5415908, 21.8234595],
                [103.5413254, 21.8237832],
                [103.5411249, 21.8241485],
                [103.540923, 21.8246766],
                [103.5407424, 21.8252457],
                [103.5403245, 21.8265978],
                [103.5401212, 21.8272482],
                [103.5399878, 21.8274713],
                [103.5398988, 21.8276335],
                [103.5396994, 21.8278967],
                [103.5394788, 21.8280987],
                [103.5392585, 21.8282802],
                [103.5390387, 21.8284006],
                [103.5386223, 21.8285396],
                [103.5382063, 21.8286174],
                [103.5378116, 21.8287566],
                [103.5374605, 21.8288962],
                [103.5373273, 21.8290989],
                [103.5371921, 21.8295054],
                [103.5370773, 21.8300547],
                [103.5366425, 21.830897],
                [103.5362224, 21.8313823],
                [103.5355162, 21.8320692],
                [103.5353177, 21.8322509],
                [103.5351203, 21.8323306],
                [103.5347044, 21.8323882],
                [103.5344207, 21.8323652],
                [103.5340719, 21.8322603],
                [103.5338992, 21.8320752],
                [103.5337046, 21.8318696],
                [103.533445, 21.8316023],
                [103.5332285, 21.831417],
                [103.5329899, 21.8312314],
                [103.5327726, 21.8311275],
                [103.5325981, 21.8310852],
                [103.53205, 21.8312638],
                [103.5311238, 21.8320913],
                [103.529687, 21.8337093],
                [103.5290371, 21.8342233],
                [103.5285308, 21.8345858],
                [103.5280472, 21.8348668],
                [103.5285391, 21.8359108],
                [103.5297832, 21.8381846],
                [103.5299008, 21.8384405],
                [103.529918, 21.8388891],
                [103.5298935, 21.8391539],
                [103.5297577, 21.8396215],
                [103.5295994, 21.8401297],
                [103.5292823, 21.8412277],
                [103.5291571, 21.8427759],
                [103.5289342, 21.8442721],
                [103.5283715, 21.8458775],
                [103.5275687, 21.84744],
                [103.527028, 21.847955],
                [103.5256834, 21.8490844],
                [103.5250836, 21.8500168],
                [103.5249894, 21.8506887],
                [103.5249514, 21.852258],
                [103.5247622, 21.8525926],
                [103.5237249, 21.8535822],
                [103.5226226, 21.85451],
                [103.5226869, 21.8567529],
                [103.5224885, 21.8579844],
                [103.5223607, 21.8597976],
                [103.5223446, 21.8613467],
                [103.5222628, 21.8618657],
                [103.5221512, 21.8620889],
                [103.5219961, 21.8622914],
                [103.5217318, 21.8624928],
                [103.5210504, 21.8628741],
                [103.5191188, 21.8641111],
                [103.5186962, 21.8643817],
                [103.5177376, 21.8647133],
                [103.5168747, 21.8650992],
                [103.5145711, 21.8659346],
                [103.5125421, 21.86664],
                [103.5117065, 21.8671217],
                [103.5112216, 21.867525],
                [103.5109979, 21.8680121],
                [103.5108829, 21.8685615],
                [103.5107456, 21.8691514],
                [103.510339, 21.870432],
                [103.5099482, 21.8712336],
                [103.5092582, 21.8724301],
                [103.5086173, 21.873097],
                [103.5083742, 21.8733599],
                [103.5081099, 21.8735613],
                [103.5077153, 21.8736596],
                [103.5071239, 21.8737766],
                [103.5063365, 21.8738306],
                [103.5047633, 21.8737755],
                [103.5032793, 21.8735582],
                [103.5022961, 21.8735084],
                [103.5018944, 21.8734748],
                [103.5002279, 21.8738607],
                [103.4979599, 21.8749837],
                [103.4969309, 21.8748827],
                [103.4962977, 21.8744652],
                [103.4955649, 21.8741841],
                [103.4931438, 21.8758546],
                [103.4929915, 21.8763563],
                [103.4930357, 21.8768142],
                [103.4934723, 21.8772756],
                [103.4943441, 21.8783356],
                [103.4952669, 21.8792132],
                [103.4955063, 21.8797643],
                [103.495453, 21.8801755],
                [103.4952058, 21.8803562],
                [103.4946639, 21.88058],
                [103.4937291, 21.8808458],
                [103.4912186, 21.881692],
                [103.4891423, 21.8832283],
                [103.4888826, 21.8845983],
                [103.4893066, 21.8862489],
                [103.490365, 21.8882255],
                [103.4907935, 21.8894645],
                [103.4907391, 21.8899672],
                [103.4903924, 21.8902842],
                [103.4898995, 21.8905085],
                [103.4865463, 21.8921701],
                [103.484814, 21.893618],
                [103.4845644, 21.8940273],
                [103.4843133, 21.8945739],
                [103.484208, 21.8952592],
                [103.4842002, 21.8959909],
                [103.4843425, 21.8964497],
                [103.484823, 21.8974147],
                [103.4852075, 21.8981501],
                [103.4859313, 21.8993004],
                [103.487186, 21.9012789],
                [103.4881453, 21.9033462],
                [103.4880895, 21.9039861],
                [103.4876417, 21.9045766],
                [103.4865057, 21.9052981],
                [103.4849219, 21.90661],
                [103.4831881, 21.9081951],
                [103.4818519, 21.9092805],
                [103.481799, 21.9096461],
                [103.4817922, 21.9102864],
                [103.4819296, 21.9112025],
                [103.4820685, 21.9119814],
                [103.4820628, 21.9125303],
                [103.4818141, 21.9128482],
                [103.4809194, 21.9139378],
                [103.4799246, 21.9152094],
                [103.4799675, 21.9158045],
                [103.4806004, 21.9162678],
                [103.481139, 21.9163641],
                [103.4817287, 21.9162782],
                [103.4821206, 21.9163275],
                [103.4825573, 21.9167889],
                [103.4834292, 21.9178491],
                [103.4840068, 21.9189066],
                [103.4845811, 21.9202842],
                [103.4844743, 21.9211065],
                [103.4841264, 21.921515],
                [103.4833837, 21.9221486],
                [103.482933, 21.9230135],
                [103.4829252, 21.9237453],
                [103.4829631, 21.9247977],
                [103.483029, 21.9255764],
                [103.4831375, 21.9268578],
                [103.4835558, 21.9290573],
                [103.4839901, 21.9297474],
                [103.4850148, 21.9303059],
                [103.4870657, 21.9312396],
                [103.4887732, 21.9321701],
                [103.4891615, 21.9325854],
                [103.4892523, 21.9332724],
                [103.4891474, 21.9339119],
                [103.4885562, 21.9341351],
                [103.4877211, 21.934219],
                [103.4861496, 21.9343417],
                [103.4853637, 21.9344259],
                [103.4849643, 21.9350627],
                [103.4845601, 21.9361568],
                [103.4845976, 21.9372549],
                [103.4847307, 21.9385827],
                [103.4853589, 21.9395034],
                [103.4859428, 21.9399662],
                [103.4869656, 21.9407074],
                [103.4894564, 21.9418282],
                [103.4922901, 21.9429978],
                [103.4935126, 21.9434208],
                [103.4950857, 21.9431607],
                [103.4959699, 21.9430774],
                [103.4962639, 21.9431257],
                [103.4965082, 21.9432195],
                [103.4965553, 21.9434028],
                [103.496551, 21.9438145],
                [103.4963451, 21.9447275],
                [103.4961835, 21.9460983],
                [103.496171, 21.9472875],
                [103.496652, 21.9482067],
                [103.497476, 21.9491749],
                [103.4986899, 21.9504211],
                [103.499675, 21.951642],
                [103.5003129, 21.9518711],
                [103.5009846, 21.9522004],
                [103.5018954, 21.9527519],
                [103.5030788, 21.9535692],
                [103.5047507, 21.9544801],
                [103.5050375, 21.954617],
                [103.5053714, 21.9548439],
                [103.5056088, 21.9551148],
                [103.5057496, 21.9554294],
                [103.5057948, 21.9556986],
                [103.5056954, 21.9560111],
                [103.5045756, 21.9574339],
                [103.5041809, 21.9584154],
                [103.5039239, 21.9600252],
                [103.5039095, 21.9614131],
                [103.5039509, 21.9620404],
                [103.5038939, 21.9628908],
                [103.5033444, 21.9649007],
                [103.5029996, 21.9657037],
                [103.5018955, 21.9671255],
                [103.5012643, 21.9674105],
                [103.4987799, 21.9689692],
                [103.498357, 21.9692136],
                [103.4970678, 21.9698699],
                [103.4967909, 21.9700109],
                [103.4951033, 21.9714275],
                [103.4941673, 21.9724251],
                [103.4929716, 21.9736601],
                [103.4912118, 21.9747461],
                [103.4890932, 21.9757329],
                [103.4869251, 21.9765276],
                [103.4851739, 21.9767989],
                [103.482395, 21.9770128],
                [103.4789486, 21.9771248],
                [103.4770914, 21.9776825],
                [103.4755352, 21.978962],
                [103.4737711, 21.9804311],
                [103.472314, 21.9820468],
                [103.4706445, 21.9842835],
                [103.4685091, 21.9868033],
                [103.4678778, 21.9881392],
                [103.4675053, 21.9892858],
                [103.4672381, 21.9902416],
                [103.4671768, 21.9911516],
                [103.467113, 21.9923011],
                [103.4669989, 21.9933543],
                [103.4667819, 21.9944065],
                [103.4663596, 21.9954089],
                [103.4656839, 21.9960734],
                [103.4650611, 21.9965947],
                [103.4643361, 21.9970671],
                [103.4631976, 21.9977274],
                [103.4623686, 21.9982946],
                [103.4616854, 21.9990449],
                [103.4604784, 21.9999337],
                [103.4595171, 22.0008123],
                [103.4582275, 22.0023616],
                [103.4569645, 22.0036275],
                [103.4561983, 22.0051755],
                [103.4551019, 22.0068788],
                [103.4544008, 22.0081598],
                [103.4539714, 22.0087801],
                [103.453833, 22.0093905],
                [103.4537884, 22.0095876],
                [103.4537909, 22.0103605],
                [103.4541417, 22.0125757],
                [103.4545366, 22.0139545],
                [103.455543, 22.0156112],
                [103.4574142, 22.0180622],
                [103.4605658, 22.0200078],
                [103.4608154, 22.020847],
                [103.4649061, 22.0231849],
                [103.4670874, 22.0246987],
                [103.4683304, 22.0256999],
                [103.4691325, 22.0266766],
                [103.469244, 22.0270886],
                [103.4694113, 22.0276548],
                [103.4694962, 22.0283759],
                [103.469447, 22.0290126],
                [103.4693609, 22.0293938],
                [103.4693352, 22.0296645],
                [103.4691162, 22.0300774],
                [103.4682906, 22.0305106],
                [103.4672205, 22.0316543],
                [103.4652903, 22.0335285],
                [103.4626969, 22.0365398],
                [103.4609914, 22.0393492],
                [103.4601626, 22.0413504],
                [103.4594453, 22.0432151],
                [103.4585326, 22.0457967],
                [103.4578499, 22.0476795],
                [103.4574103, 22.048759],
                [103.4564284, 22.0504494],
                [103.4555877, 22.0514574],
                [103.4540946, 22.0530091],
                [103.4526633, 22.0542633],
                [103.4504629, 22.0555317],
                [103.44863, 22.0563849],
                [103.4466962, 22.0569775],
                [103.4457013, 22.0572451],
                [103.4449821, 22.057385],
                [103.4439656, 22.0573786],
                [103.4427567, 22.0570977],
                [103.4402631, 22.0556832],
                [103.4384915, 22.0544758],
                [103.4380868, 22.0542256],
                [103.4377556, 22.0540462],
                [103.4369837, 22.0539452],
                [103.4363231, 22.0541789],
                [103.4356779, 22.054404],
                [103.4348643, 22.0553148],
                [103.4341269, 22.0568383],
                [103.4331691, 22.0590048],
                [103.4321282, 22.0610429],
                [103.4318819, 22.0615845],
                [103.4317458, 22.0621517],
                [103.4315552, 22.0628994],
                [103.4309244, 22.0639315],
                [103.4296889, 22.0654031],
                [103.4284521, 22.0664884],
                [103.427587, 22.067036],
                [103.4240316, 22.0698637],
                [103.4229561, 22.0706935],
                [103.4222096, 22.0714501],
                [103.4214811, 22.072417],
                [103.4207266, 22.0738993],
                [103.4200435, 22.0762988],
                [103.4193966, 22.079157],
                [103.419145, 22.0815033],
                [103.4190019, 22.083297],
                [103.4185731, 22.0849733],
                [103.4177934, 22.0868563],
                [103.4164619, 22.0885621],
                [103.4151884, 22.0899853],
                [103.4143431, 22.0909858],
                [103.4140257, 22.0918421],
                [103.4135153, 22.0934793],
                [103.412828, 22.0962607],
                [103.4123602, 22.0977457],
                [103.4113213, 22.1007719],
                [103.4101768, 22.1040835],
                [103.4089195, 22.1082915],
                [103.408531, 22.1100064],
                [103.4076156, 22.1129955],
                [103.4066408, 22.115774],
                [103.4065265, 22.1168232],
                [103.4065499, 22.1183894],
                [103.4065229, 22.1207952],
                [103.4064279, 22.12194],
                [103.406181, 22.123828],
                [103.4058728, 22.1256965],
                [103.4051077, 22.1280569],
                [103.404161, 22.1301674],
                [103.4036204, 22.1308113],
                [103.4014255, 22.132623],
                [103.398445, 22.1349617],
                [103.3938244, 22.1390219],
                [103.3922887, 22.1405727],
                [103.3913476, 22.1421483],
                [103.3907581, 22.1434792],
                [103.3901672, 22.1449247],
                [103.3898177, 22.1467927],
                [103.3898617, 22.1483398],
                [103.3901768, 22.1494887],
                [103.3912629, 22.151409],
                [103.3920674, 22.1528301],
                [103.3923662, 22.1536159],
                [103.3923187, 22.1541883],
                [103.3916095, 22.1552125],
                [103.3909054, 22.1557785],
                [103.3901202, 22.1562672],
                [103.3896667, 22.1564537],
                [103.3874091, 22.1565078],
                [103.382613, 22.1560783],
                [103.3801743, 22.155844],
                [103.3790695, 22.1555658],
                [103.3772699, 22.1550514],
                [103.375882, 22.1544264],
                [103.3745324, 22.154031],
                [103.3735076, 22.1539445],
                [103.372535, 22.1537744],
                [103.3715373, 22.1540012],
                [103.3676978, 22.1542493],
                [103.3658105, 22.1542304],
                [103.3635566, 22.1539786],
                [103.362372, 22.1536903],
                [103.3615926, 22.1535006],
                [103.3563336, 22.152321],
                [103.3526044, 22.1519015],
                [103.3496504, 22.1518716],
                [103.3469223, 22.1518248],
                [103.3441303, 22.1519875],
                [103.3392349, 22.1530452],
                [103.3340385, 22.153546],
                [103.3331373, 22.1534223],
                [103.3307283, 22.1524047],
                [103.3301602, 22.1518642],
                [103.3293947, 22.1506724],
                [103.3281733, 22.1481202],
                [103.327566, 22.1474265],
                [103.3260634, 22.1461126],
                [103.324678, 22.1452964],
                [103.3223497, 22.1444322],
                [103.32078, 22.1435951],
                [103.3197651, 22.142668],
                [103.3188749, 22.1416277],
                [103.3185594, 22.1405551],
                [103.3186333, 22.1397828],
                [103.3183409, 22.1382232],
                [103.3177817, 22.1352194],
                [103.3172621, 22.1340683],
                [103.3160108, 22.1323369],
                [103.3141637, 22.1306947],
                [103.3116408, 22.12895],
                [103.3092357, 22.1276648],
                [103.307444, 22.1265388],
                [103.3069808, 22.1258274],
                [103.3067019, 22.1251371],
                [103.3065952, 22.1237611],
                [103.3066034, 22.1230738],
                [103.3066554, 22.1221577],
                [103.3069553, 22.1210915],
                [103.3079183, 22.1194593],
                [103.3086928, 22.1181497],
                [103.3090329, 22.1171603],
                [103.3091245, 22.1163592],
                [103.309092, 22.1156332],
                [103.3088948, 22.114982],
                [103.308491, 22.1144432],
                [103.3077171, 22.1139768],
                [103.3067364, 22.1136612],
                [103.3037283, 22.1130761],
                [103.302049, 22.1128677],
                [103.2977867, 22.112556],
                [103.2957765, 22.1125733],
                [103.293623, 22.1125698],
                [103.2926387, 22.1125595],
                [103.2921072, 22.1124013],
                [103.2903159, 22.111275],
                [103.2872671, 22.1089897],
                [103.2852874, 22.1064866],
                [103.2845067, 22.1048936],
                [103.284236, 22.1035541],
                [103.2841729, 22.1019876],
                [103.2843554, 22.1004619],
                [103.2843489, 22.0992969],
                [103.2839964, 22.0979184],
                [103.2835343, 22.0954311],
                [103.2834588, 22.0931963],
                [103.2834806, 22.0914015],
                [103.2835436, 22.089569],
                [103.2835529, 22.0888054],
                [103.2833991, 22.0879635],
                [103.2829573, 22.0871951],
                [103.2809135, 22.0849395],
                [103.2788412, 22.0833519],
                [103.2762845, 22.0811098],
                [103.275252, 22.0800105],
                [103.2745713, 22.0786667],
                [103.2736182, 22.0760979],
                [103.2730573, 22.0750227],
                [103.2729967, 22.0747967],
                [103.2727238, 22.073778],
                [103.2725304, 22.0728211],
                [103.2726203, 22.0721729],
                [103.2732926, 22.0708433],
                [103.2740086, 22.0692851],
                [103.2743275, 22.0683527],
                [103.2748721, 22.0674037],
                [103.2769095, 22.0650955],
                [103.2784482, 22.0633168],
                [103.2812464, 22.0608066],
                [103.2837322, 22.058694],
                [103.2868417, 22.0558623],
                [103.2879855, 22.0545184],
                [103.2889933, 22.0525431],
                [103.2904978, 22.0501909],
                [103.2911085, 22.0488416],
                [103.2912819, 22.0446425],
                [103.2880507, 22.0405669],
                [103.2868149, 22.0395718],
                [103.2846532, 22.0387901],
                [103.2821918, 22.0382332],
                [103.2794917, 22.0382379],
                [103.2786206, 22.038329],
                [103.2773449, 22.0385931],
                [103.2763178, 22.0387695],
                [103.2756645, 22.0390326],
                [103.2747003, 22.0395292],
                [103.2739538, 22.0398215],
                [103.2731446, 22.0400267],
                [103.271962, 22.0402324],
                [103.2711221, 22.0406123],
                [103.270376, 22.0411957],
                [103.2693504, 22.0420998],
                [103.2688526, 22.0423336],
                [103.2679193, 22.0426844],
                [103.2672039, 22.043035],
                [103.2666438, 22.0432104],
                [103.2660836, 22.0432988],
                [103.2648223, 22.0436776],
                [103.2631208, 22.0444295],
                [103.2621698, 22.0448057],
                [103.2612687, 22.0449945],
                [103.2592736, 22.0453577],
                [103.2581695, 22.045753],
                [103.2568203, 22.0464274],
                [103.2555583, 22.0468887],
                [103.254261, 22.0471202],
                [103.2529811, 22.047319],
                [103.2506317, 22.0477325],
                [103.2490188, 22.0481285],
                [103.2478618, 22.0483106],
                [103.2463713, 22.0483949],
                [103.2451264, 22.04861],
                [103.244022, 22.048874],
                [103.2428302, 22.0493513],
                [103.2419365, 22.0497625],
                [103.2413231, 22.0500587],
                [103.2410428, 22.0502395],
                [103.2405875, 22.0506664],
                [103.2399397, 22.0513069],
                [103.2393792, 22.0517178],
                [103.2380478, 22.0526051],
                [103.2363662, 22.0538867],
                [103.2353501, 22.0545769],
                [103.234702, 22.0550697],
                [103.2336053, 22.056161],
                [103.2327345, 22.057005],
                [103.2318928, 22.0580121],
                [103.2313989, 22.0583391],
                [103.2309339, 22.0584757],
                [103.2302074, 22.0587485],
                [103.2293944, 22.0595109],
                [103.2278983, 22.0609784],
                [103.2278937, 22.0609773],
                [103.2251957, 22.0631734],
                [103.2231217, 22.0642436],
                [103.2201923, 22.0658],
                [103.2182222, 22.0669427],
                [103.2172107, 22.0672594],
                [103.216173, 22.0673578],
                [103.2151877, 22.0676987],
                [103.214073, 22.0684278],
                [103.2126473, 22.0692787],
                [103.2118433, 22.0695465],
                [103.2108576, 22.0697176],
                [103.2100275, 22.0698642],
                [103.2090154, 22.0697685],
                [103.207458, 22.0693581],
                [103.2049659, 22.0685123],
                [103.202552, 22.0677876],
                [103.2012284, 22.0676678],
                [103.1993606, 22.0678885],
                [103.1969485, 22.0685707],
                [103.1957814, 22.0689116],
                [103.1943807, 22.0691801],
                [103.1920162, 22.0694523],
                [103.191598, 22.0695707],
                [103.1911728, 22.0696911],
                [103.1879906, 22.0709712],
                [103.1811973, 22.073784],
                [103.180125, 22.0741498],
                [103.1785239, 22.074973],
                [103.177439, 22.0754089],
                [103.1764577, 22.0759415],
                [103.1749085, 22.0770062],
                [103.1733595, 22.0783124],
                [103.1717074, 22.0800052],
                [103.170778, 22.0806826],
                [103.1701061, 22.08078],
                [103.1696654, 22.080709],
                [103.1687528, 22.0799522],
                [103.1679408, 22.078785],
                [103.1675338, 22.0773333],
                [103.1670931, 22.0757867],
                [103.1661798, 22.0745881],
                [103.1652338, 22.074147],
                [103.1638828, 22.0740221],
                [103.1625997, 22.0740866],
                [103.161148, 22.0744668],
                [103.1595618, 22.0754155],
                [103.1579414, 22.0758906],
                [103.1562528, 22.0758922],
                [103.1537195, 22.0753264],
                [103.1513885, 22.0746024],
                [103.1505775, 22.0740664],
                [103.1502731, 22.0736248],
                [103.1501374, 22.0731198],
                [103.1502044, 22.07252],
                [103.1500864, 22.0706222],
                [103.149868, 22.0696701],
                [103.1490108, 22.0681348],
                [103.1482359, 22.0672594],
                [103.1476924, 22.0670187],
                [103.1470132, 22.0669177],
                [103.1458589, 22.0669696],
                [103.1442296, 22.0673518],
                [103.143456, 22.0677461],
                [103.1427911, 22.0682546],
                [103.1415295, 22.0696904],
                [103.140851, 22.0702876],
                [103.1399145, 22.0707582],
                [103.1386382, 22.0711528],
                [103.1357462, 22.0718916],
                [103.1349316, 22.0721843],
                [103.1344975, 22.0724767],
                [103.1339817, 22.0729342],
                [103.1330184, 22.0738744],
                [103.132625, 22.0744462],
                [103.1322454, 22.0752844],
                [103.1318254, 22.0761862],
                [103.1314047, 22.0767578],
                [103.1303871, 22.0778124],
                [103.129668, 22.0784732],
                [103.1285961, 22.0796929],
                [103.1281758, 22.0804169],
                [103.1279725, 22.0809376],
                [103.1278372, 22.0815852],
                [103.1277289, 22.0818773],
                [103.1273898, 22.0823474],
                [103.1271728, 22.0827284],
                [103.1269426, 22.0835665],
                [103.1266585, 22.0847474],
                [103.1263738, 22.0852682],
                [103.1259261, 22.0859414],
                [103.1251533, 22.0874783],
                [103.1247603, 22.0884815],
                [103.1242991, 22.0891929],
                [103.1238514, 22.0897012],
                [103.1235803, 22.0903107],
                [103.1234991, 22.0907805],
                [103.1234317, 22.0914027],
                [103.1232285, 22.0918598],
                [103.1225908, 22.0929014],
                [103.1221025, 22.093689],
                [103.1215873, 22.0946796],
                [103.1210987, 22.0952005],
                [103.1205421, 22.0955311],
                [103.1204892, 22.0955535],
                [103.1203857, 22.0955972],
                [103.1196781, 22.0957465],
                [103.1180946, 22.0959647],
                [103.1168768, 22.096292],
                [103.1160243, 22.0967286],
                [103.1148234, 22.0978965],
                [103.1138239, 22.0987777],
                [103.1133968, 22.0990617],
                [103.1126038, 22.0992626],
                [103.1115015, 22.0993812],
                [103.109973, 22.0997049],
                [103.1077075, 22.1002826],
                [103.1066031, 22.1005587],
                [103.1047367, 22.1008259],
                [103.1038603, 22.100947],
                [103.1026126, 22.101379],
                [103.1021704, 22.0990115],
                [103.1010398, 22.0941358],
                [103.1004365, 22.0915343],
                [103.1001967, 22.0892877],
                [103.0998608, 22.087923],
                [103.0996285, 22.0870748],
                [103.0993683, 22.0866594],
                [103.0980515, 22.085778],
                [103.0967808, 22.0847734],
                [103.096039, 22.0839401],
                [103.0958253, 22.0833602],
                [103.0957064, 22.0823279],
                [103.0956826, 22.0808017],
                [103.0951293, 22.0774755],
                [103.0948846, 22.0759056],
                [103.0946699, 22.0754082],
                [103.094495, 22.0752413],
                [103.0931817, 22.0741125],
                [103.0912849, 22.0719253],
                [103.090546, 22.0708856],
                [103.0903744, 22.0704713],
                [103.0901154, 22.0699734],
                [103.0900318, 22.0696012],
                [103.0902146, 22.069191],
                [103.0916534, 22.0675582],
                [103.0918098, 22.0674569],
                [103.0922547, 22.0672972],
                [103.0928308, 22.0672626],
                [103.0938957, 22.0671101],
                [103.0946527, 22.066789],
                [103.0951901, 22.0663416],
                [103.0955049, 22.0659741],
                [103.0956865, 22.0656463],
                [103.0957335, 22.0654407],
                [103.0956527, 22.0648624],
                [103.0953976, 22.0640758],
                [103.0952714, 22.0635795],
                [103.0952453, 22.0622183],
                [103.0952658, 22.0606927],
                [103.0951809, 22.0587741],
                [103.0949608, 22.057695],
                [103.09335, 22.0575968],
                [103.0918615, 22.0575637],
                [103.0899374, 22.0575987],
                [103.0879772, 22.0579052],
                [103.0862348, 22.0582117],
                [103.0848916, 22.0583142],
                [103.0830037, 22.0582473],
                [103.0807891, 22.0580786],
                [103.0782478, 22.058012],
                [103.0766141, 22.0581146],
                [103.0755252, 22.0584206],
                [103.0741094, 22.0586929],
                [103.0721853, 22.0588295],
                [103.0704429, 22.0591357],
                [103.0694266, 22.0597471],
                [103.06714, 22.0610718],
                [103.0656886, 22.062464],
                [103.064999, 22.0630074],
                [103.064382, 22.0632113],
                [103.0638735, 22.0631436],
                [103.0635104, 22.06294],
                [103.0628205, 22.062533],
                [103.0622758, 22.0623975],
                [103.0600609, 22.0618213],
                [103.0591168, 22.0613464],
                [103.058681, 22.0609732],
                [103.0577269, 22.0587301],
                [103.0569752, 22.0565997],
                [103.0567871, 22.055597],
                [103.0567866, 22.0545192],
                [103.0566524, 22.0541431],
                [103.0557674, 22.053567],
                [103.0536759, 22.0527905],
                [103.0514771, 22.0518136],
                [103.0486347, 22.0498844],
                [103.0455027, 22.0479556],
                [103.0447727, 22.0483655],
                [103.0438445, 22.0484742],
                [103.0423943, 22.0483389],
                [103.0407988, 22.048041],
                [103.0392036, 22.0479873],
                [103.0378983, 22.0482316],
                [103.0361582, 22.048693],
                [103.0343019, 22.0492629],
                [103.0327938, 22.0500226],
                [103.0302996, 22.0511079],
                [103.0287335, 22.0516777],
                [103.0275443, 22.0524644],
                [103.0246732, 22.0550955],
                [103.023948, 22.0555024],
                [103.0232229, 22.0556653],
                [103.0193359, 22.0555302],
                [103.0181757, 22.0552591],
                [103.0168414, 22.0547168],
                [103.0144046, 22.0532525],
                [103.0134474, 22.0526018],
                [103.0128382, 22.052412],
                [103.011794, 22.0523579],
                [103.0104596, 22.052385],
                [103.0094735, 22.0525206],
                [103.0083712, 22.052792],
                [103.0072401, 22.0529819],
                [103.0066019, 22.0529547],
                [103.0060798, 22.052792],
                [103.0052676, 22.0523311],
                [103.0044844, 22.0515988],
                [103.0034692, 22.050975],
                [103.0018971, 22.0502566],
                [102.9989421, 22.0531227],
                [102.9984416, 22.0532396],
                [102.9980663, 22.0530056],
                [102.9980663, 22.0525962],
                [102.9982539, 22.0516604],
                [102.9983164, 22.0501982],
                [102.9981914, 22.0493792],
                [102.9974408, 22.0480925],
                [102.9955017, 22.0454603],
                [102.9950638, 22.0444075],
                [102.9950639, 22.0436472],
                [102.9953767, 22.0399623],
                [102.9952517, 22.0390264],
                [102.9947513, 22.0382076],
                [102.994376, 22.0368038],
                [102.9939382, 22.0357509],
                [102.993, 22.0348735],
                [102.9917491, 22.0338791],
                [102.9913738, 22.0333527],
                [102.990936, 22.0323584],
                [102.990811, 22.0308376],
                [102.9902482, 22.028732],
                [102.9894352, 22.027796],
                [102.9883719, 22.0272111],
                [102.9870585, 22.0269185],
                [102.9859953, 22.0269184],
                [102.985001, 22.0271217],
                [102.9839937, 22.0273277],
                [102.9806159, 22.0290235],
                [102.9781138, 22.0307194],
                [102.9770505, 22.0310702],
                [102.9762374, 22.0310701],
                [102.9741733, 22.0306604],
                [102.9720469, 22.030075],
                [102.9707959, 22.0298995],
                [102.9700454, 22.0300747],
                [102.9691419, 22.0304969],
                [102.968544, 22.0307764],
                [102.9669174, 22.0321213],
                [102.9644775, 22.0347528],
                [102.9626003, 22.0372091],
                [102.9622248, 22.0377353],
                [102.9624123, 22.0383203],
                [102.9630376, 22.0390808],
                [102.963913, 22.0404263],
                [102.9641629, 22.0415376],
                [102.9641003, 22.042298],
                [102.9635995, 22.0431753],
                [102.9624733, 22.0442278],
                [102.9615973, 22.044988],
                [102.961347, 22.0456314],
                [102.9612213, 22.047737],
                [102.9609082, 22.0489067],
                [102.9604701, 22.0496084],
                [102.9589684, 22.0509534],
                [102.9561526, 22.0528244],
                [102.9549012, 22.0539353],
                [102.9538372, 22.0556313],
                [102.9531489, 22.0562159],
                [102.9525232, 22.0565082],
                [102.9505836, 22.0568],
                [102.9485191, 22.0574428],
                [102.9475178, 22.0582028],
                [102.9467668, 22.0591969],
                [102.9457649, 22.0614778],
                [102.9453265, 22.0628813],
                [102.9434482, 22.0663901],
                [102.9426971, 22.0671502],
                [102.9420088, 22.0674424],
                [102.9403195, 22.0675588],
                [102.938255, 22.067558],
                [102.9370035, 22.0679085],
                [102.9357522, 22.067908],
                [102.9346263, 22.0676151],
                [102.9332501, 22.0671466],
                [102.9321864, 22.0671462],
                [102.9311853, 22.0673213],
                [102.9303092, 22.0678473],
                [102.9296831, 22.0687245],
                [102.9292445, 22.070011],
                [102.9290556, 22.072409],
                [102.9288048, 22.0734032],
                [102.9281781, 22.075567],
                [102.9281777, 22.076386],
                [102.9286777, 22.0772634],
                [102.9298658, 22.0789017],
                [102.9351815, 22.0845189],
                [102.9358067, 22.0858645],
                [102.9358063, 22.0868003],
                [102.934301, 22.0942864],
                [102.9334225, 22.0993161],
                [102.9330463, 22.1010707],
                [102.9323574, 22.1023572],
                [102.9316682, 22.1037606],
                [102.9312302, 22.1041115],
                [102.9302288, 22.1041695],
                [102.9291649, 22.104052],
                [102.9270369, 22.1044605],
                [102.9251797, 22.1057439],
                [102.9252413, 22.1049018],
                [102.9250665, 22.1014557],
                [102.9249042, 22.1002355],
                [102.9249189, 22.0992277],
                [102.9252488, 22.0982239],
                [102.9256245, 22.0971787],
                [102.9260913, 22.0960505],
                [102.9262867, 22.095003],
                [102.9262972, 22.0942892],
                [102.9263478, 22.0933111],
                [102.9263838, 22.0928705],
                [102.9263323, 22.0923461],
                [102.9260744, 22.0917915],
                [102.9258995, 22.0916238],
                [102.9255477, 22.0914265],
                [102.924824, 22.0914035],
                [102.9242885, 22.0916448],
                [102.9238419, 22.0918596],
                [102.9235164, 22.0918831],
                [102.9232812, 22.0917973],
                [102.9230185, 22.0915735],
                [102.9226114, 22.0911273],
                [102.9222319, 22.0907917],
                [102.9217616, 22.0906203],
                [102.9210832, 22.090529],
                [102.9204023, 22.090603],
                [102.9200464, 22.0906812],
                [102.9196324, 22.0907035],
                [102.9193377, 22.0906446],
                [102.9190445, 22.0904756],
                [102.9188098, 22.0903623],
                [102.9183678, 22.0902739],
                [102.9179083, 22.0903645],
                [102.9174038, 22.0904959],
                [102.9169292, 22.0906277],
                [102.9164268, 22.0906212],
                [102.9158362, 22.0905862],
                [102.9151269, 22.0905771],
                [102.9145359, 22.0905696],
                [102.9141813, 22.0905651],
                [102.9136522, 22.0903654],
                [102.9133605, 22.0901135],
                [102.9130698, 22.0897791],
                [102.9128981, 22.089391],
                [102.9127841, 22.0891139],
                [102.912493, 22.0888069],
                [102.9122023, 22.0884725],
                [102.911853, 22.0881097],
                [102.9114142, 22.0878008],
                [102.9109901, 22.0875059],
                [102.910725, 22.0874474],
                [102.9101622, 22.0875229],
                [102.9096598, 22.0875165],
                [102.9094234, 22.0875134],
                [102.9088075, 22.0872024],
                [102.9083354, 22.0871413],
                [102.9075409, 22.0869105],
                [102.9066883, 22.0865964],
                [102.905549, 22.0856998],
                [102.9043671, 22.0846785],
                [102.9037892, 22.0837889],
                [102.9029173, 22.0827855],
                [102.9020428, 22.0819749],
                [102.9015756, 22.0815829],
                [102.9013433, 22.0813043],
                [102.9013187, 22.0809733],
                [102.9014583, 22.0805202],
                [102.9014633, 22.0801895],
                [102.9014082, 22.0799131],
                [102.9012961, 22.0794982],
                [102.901065, 22.0791369],
                [102.9007432, 22.0789123],
                [102.9002426, 22.0787956],
                [102.8994757, 22.078703],
                [102.898858, 22.0785021],
                [102.8981849, 22.0780524],
                [102.8978352, 22.0777172],
                [102.8975463, 22.0772724],
                [102.897435, 22.0768024],
                [102.8973217, 22.0764701],
                [102.897176, 22.0763304],
                [102.8969548, 22.0763089],
                [102.8964803, 22.076413],
                [102.8959764, 22.0765167],
                [102.8952968, 22.0765079],
                [102.8942049, 22.0764112],
                [102.893318, 22.0764274],
                [102.8927566, 22.0764201],
                [102.8924918, 22.0763341],
                [102.892258, 22.0761659],
                [102.8921439, 22.0758889],
                [102.8920605, 22.0755297],
                [102.8919768, 22.0751982],
                [102.8918024, 22.075003],
                [102.891392, 22.0747774],
                [102.8908015, 22.0747423],
                [102.8904461, 22.0747928],
                [102.8902085, 22.0748724],
                [102.8900283, 22.0750628],
                [102.8899348, 22.0753922],
                [102.889902, 22.075612],
                [102.8897822, 22.0757207],
                [102.8895976, 22.0757171],
                [102.8891543, 22.0757113],
                [102.8886215, 22.0757871],
                [102.888088, 22.0759041],
                [102.8873748, 22.0761841],
                [102.8868387, 22.0764662],
                [102.8862126, 22.0768299],
                [102.8856743, 22.0772774],
                [102.88527, 22.0776439],
                [102.8850484, 22.0776411],
                [102.8848268, 22.0776382],
                [102.8844736, 22.0775509],
                [102.8839862, 22.0775446],
                [102.8836748, 22.0776233],
                [102.8833615, 22.0778258],
                [102.8828673, 22.0782737],
                [102.8820125, 22.0791301],
                [102.8812962, 22.0796165],
                [102.8807601, 22.0798986],
                [102.8797384, 22.0800507],
                [102.8788627, 22.0801172],
                [102.8780474, 22.0795578],
                [102.876879, 22.0789939],
                [102.8757627, 22.0788879],
                [102.8745866, 22.078836],
                [102.8730952, 22.0788899],
                [102.8715264, 22.0788693],
                [102.8705112, 22.0785634],
                [102.8691043, 22.0782158],
                [102.8685967, 22.078063],
                [102.8681708, 22.0776915],
                [102.8675956, 22.0768059],
                [102.8664472, 22.074925],
                [102.8657245, 22.0734154],
                [102.8649721, 22.0712837],
                [102.8644854, 22.0697407],
                [102.8638451, 22.0679762],
                [102.8634861, 22.0670751],
                [102.8633391, 22.0664146],
                [102.8633059, 22.0660117],
                [102.8633915, 22.0655372],
                [102.8637135, 22.0649926],
                [102.8641134, 22.0644856],
                [102.8635998, 22.0637127],
                [102.8628563, 22.0626534],
                [102.8626343, 22.0623973],
                [102.8624565, 22.0620289],
                [102.8624355, 22.0616869],
                [102.8627355, 22.0608852],
                [102.863189, 22.0603297],
                [102.863244, 22.0601596],
                [102.8632451, 22.0600863],
                [102.8631684, 22.0599633],
                [102.862855, 22.0599104],
                [102.8621969, 22.0601459],
                [102.8617244, 22.0602129],
                [102.8614372, 22.0601603],
                [102.8610743, 22.0599115],
                [102.8608771, 22.0595201],
                [102.860244, 22.059647],
                [102.8597691, 22.059806],
                [102.8593815, 22.0600487],
                [102.8589608, 22.0605386],
                [102.8585076, 22.0612208],
                [102.8583172, 22.0620992],
                [102.8580756, 22.0624539],
                [102.8578356, 22.0626985],
                [102.8574776, 22.0629415],
                [102.857004, 22.0630179],
                [102.856767, 22.0630698],
                [102.8566772, 22.0631513],
                [102.8565832, 22.0635079],
                [102.8564594, 22.0638916],
                [102.8564565, 22.0640842],
                [102.8562734, 22.0644672],
                [102.8560929, 22.0646851],
                [102.8558522, 22.0649846],
                [102.8555819, 22.0652839],
                [102.8553428, 22.0654735],
                [102.8550758, 22.0655525],
                [102.8547206, 22.0656029],
                [102.8543663, 22.0655983],
                [102.8538939, 22.0655921],
                [102.8532407, 22.0658312],
                [102.852943, 22.0659925],
                [102.8524672, 22.0662064],
                [102.8519337, 22.0663371],
                [102.8515203, 22.0663316],
                [102.8510787, 22.0662433],
                [102.8507123, 22.0660643],
                [102.8503335, 22.0657288],
                [102.8498961, 22.0653649],
                [102.8496618, 22.0652241],
                [102.849427, 22.0651383],
                [102.8487695, 22.0651642],
                [102.847749, 22.0652747],
                [102.8471733, 22.0652671],
                [102.8465089, 22.0652584],
                [102.8463325, 22.0652147],
                [102.8460243, 22.0650867],
                [102.8456283, 22.0649163],
                [102.8452746, 22.0648703],
                [102.8448729, 22.0650723],
                [102.8444832, 22.0654526],
                [102.8441255, 22.0656681],
                [102.8438289, 22.0657468],
                [102.8436222, 22.0657441],
                [102.8432999, 22.0655747],
                [102.8431826, 22.065518],
                [102.8427123, 22.0653742],
                [102.842063, 22.0653381],
                [102.8414403, 22.0654951],
                [102.8406678, 22.0657878],
                [102.8405199, 22.0658134],
                [102.840106, 22.0658353],
                [102.8397533, 22.0657205],
                [102.8392862, 22.0653565],
                [102.8385846, 22.0648792],
                [102.8379096, 22.0645949],
                [102.8374978, 22.0644794],
                [102.8370693, 22.0644925],
                [102.8364463, 22.064677],
                [102.8358223, 22.0649167],
                [102.8353174, 22.065103],
                [102.8346082, 22.0651211],
                [102.8340788, 22.0649763],
                [102.8334333, 22.0646922],
                [102.832787, 22.0644633],
                [102.8320192, 22.0644531],
                [102.8312513, 22.0644429],
                [102.8302474, 22.0644296],
                [102.8292001, 22.0643468],
                [102.8281653, 22.0644158],
                [102.8269545, 22.0643996],
                [102.8261576, 22.0643615],
                [102.8255375, 22.0643533],
                [102.8251224, 22.064458],
                [102.825092, 22.0645126],
                [102.8248799, 22.064868],
                [102.8244592, 22.0653308],
                [102.8242512, 22.0654107],
                [102.8238083, 22.0654048],
                [102.8231611, 22.0652309],
                [102.8228663, 22.0651994],
                [102.822377, 22.0653169],
                [102.8218122, 22.0655573],
                [102.8209828, 22.0657116],
                [102.8200649, 22.0658646],
                [102.8191751, 22.0661008],
                [102.8185486, 22.0665056],
                [102.8180377, 22.0670775],
                [102.8176762, 22.067541],
                [102.8174062, 22.067813],
                [102.8168708, 22.0680537],
                [102.8166932, 22.0680789],
                [102.8166357, 22.0680728],
                [102.8158718, 22.0687699],
                [102.8153401, 22.069332],
                [102.8135287, 22.0705924],
                [102.8120666, 22.0720832],
                [102.8095008, 22.0735928],
                [102.8074391, 22.0741573],
                [102.8063648, 22.0749132],
                [102.8055665, 22.0754596],
                [102.8041398, 22.0761009],
                [102.8022937, 22.0771216],
                [102.8011594, 22.0779317],
                [102.8001483, 22.0784134],
                [102.7989601, 22.0788926],
                [102.7977095, 22.0795912],
                [102.796877, 22.0799652],
                [102.7959916, 22.0799531],
                [102.7954613, 22.079891],
                [102.7949487, 22.0799452],
                [102.7943152, 22.0801812],
                [102.7933601, 22.0808531],
                [102.7928285, 22.0812861],
                [102.7921532, 22.0816852],
                [102.7913034, 22.0823346],
                [102.7907014, 22.0827875],
                [102.7899247, 22.0833527],
                [102.7896054, 22.0836422],
                [102.7894074, 22.0837129],
                [102.7890531, 22.0837081],
                [102.7882705, 22.0834038],
                [102.787562, 22.0833942],
                [102.786611, 22.0837853],
                [102.7860923, 22.0843311],
                [102.7859683, 22.0846965],
                [102.7858795, 22.0853559],
                [102.7857174, 22.0856473],
                [102.785242, 22.0858243],
                [102.7837425, 22.0860243],
                [102.7827166, 22.0861572],
                [102.7820067, 22.0862208],
                [102.7813317, 22.0865787],
                [102.7806601, 22.0867164],
                [102.7797541, 22.0867408],
                [102.7789267, 22.0867663],
                [102.7781769, 22.0868662],
                [102.7775036, 22.0871139],
                [102.7771041, 22.0874755],
                [102.7770184, 22.0879147],
                [102.7770504, 22.0883923],
                [102.777202, 22.0887613],
                [102.7773944, 22.0890576],
                [102.7772317, 22.0893856],
                [102.7764322, 22.0901455],
                [102.7761908, 22.0904725],
                [102.7761063, 22.0908383],
                [102.7762859, 22.0919418],
                [102.7765761, 22.0935239],
                [102.7769179, 22.094336],
                [102.7773605, 22.0949913],
                [102.777983, 22.0954771],
                [102.7786043, 22.0960365],
                [102.7789125, 22.0964815],
                [102.7793328, 22.0972952],
                [102.7796692, 22.0979595],
                [102.7802855, 22.0981453],
                [102.780694, 22.0984398],
                [102.7810391, 22.0990577],
                [102.7815141, 22.1000855],
                [102.7811067, 22.1002477],
                [102.7801113, 22.1006115],
                [102.7792536, 22.1008094],
                [102.7780372, 22.1009185],
                [102.777178, 22.1012004],
                [102.7764955, 22.1016941],
                [102.7757587, 22.1028579],
                [102.7751444, 22.104737],
                [102.7741229, 22.1067782],
                [102.7723465, 22.1096271],
                [102.7706424, 22.1121624],
                [102.7683815, 22.1157805],
                [102.7675991, 22.1169022],
                [102.7670991, 22.1172309],
                [102.7665361, 22.1172862],
                [102.7659077, 22.1171938],
                [102.7646519, 22.1169668],
                [102.7632194, 22.1165278],
                [102.7623656, 22.1164741],
                [102.7619816, 22.1165785],
                [102.7617784, 22.1166338],
                [102.7612764, 22.1170884],
                [102.7604954, 22.1181262],
                [102.7587176, 22.1196117],
                [102.7578532, 22.1202289],
                [102.7570364, 22.1206791],
                [102.755874, 22.1216488],
                [102.7555936, 22.122316],
                [102.7554387, 22.1235722],
                [102.7552389, 22.1248277],
                [102.7551409, 22.1253297],
                [102.7546846, 22.1257428],
                [102.7539161, 22.1259839],
                [102.7521951, 22.1267152],
                [102.7500613, 22.127944],
                [102.748019, 22.1292157],
                [102.7472532, 22.1298296],
                [102.7464422, 22.1303605],
                [102.745202, 22.1310863],
                [102.7444588, 22.1314598],
                [102.7441228, 22.1317637],
                [102.7441176, 22.1321004],
                [102.7441091, 22.1326332],
                [102.7439462, 22.1334166],
                [102.7433296, 22.134334],
                [102.7428683, 22.1350008],
                [102.7425261, 22.1356971],
                [102.7425198, 22.1360896],
                [102.7424992, 22.1373792],
                [102.7423196, 22.1391995],
                [102.7422685, 22.1395826],
                [102.7419603, 22.1400273],
                [102.741685, 22.1403042],
                [102.7415858, 22.1408639],
                [102.7413928, 22.141647],
                [102.7411718, 22.1422894],
                [102.7406512, 22.1428436],
                [102.7404315, 22.1434017],
                [102.7403799, 22.1438078],
                [102.7402546, 22.1441148],
                [102.7400391, 22.1444205],
                [102.7396438, 22.1446677],
                [102.739249, 22.1448867],
                [102.7392126, 22.145279],
                [102.7392629, 22.1458971],
                [102.7395446, 22.1471077],
                [102.7399237, 22.1478706],
                [102.7402256, 22.1487588],
                [102.7403037, 22.1495175],
                [102.7404745, 22.1501373],
                [102.7407054, 22.1507579],
                [102.7411811, 22.1519995],
                [102.7410624, 22.1524599],
                [102.7405087, 22.1533326],
                [102.7402673, 22.1543773],
                [102.7387614, 22.1585489],
                [102.7383769, 22.1600948],
                [102.7380474, 22.1610125],
                [102.7374527, 22.1616332],
                [102.7371253, 22.1624251],
                [102.7369763, 22.1633035],
                [102.7371542, 22.1648362],
                [102.7374817, 22.166853],
                [102.7372341, 22.168275],
                [102.7372635, 22.1692397],
                [102.7376072, 22.1702507],
                [102.7383931, 22.1717289],
                [102.7391019, 22.1724096],
                [102.7397255, 22.1727955],
                [102.7405549, 22.1729537],
                [102.7413618, 22.1731326],
                [102.7424356, 22.1734829],
                [102.7436385, 22.1742123],
                [102.7459085, 22.175711],
                [102.7464388, 22.1763052],
                [102.7466147, 22.1765593],
                [102.7472268, 22.1776577],
                [102.7474846, 22.178416],
                [102.7476948, 22.1793412],
                [102.7476694, 22.180934],
                [102.74765, 22.1821495],
                [102.7476293, 22.1834489],
                [102.7475455, 22.1858793],
                [102.747933, 22.1869746],
                [102.7489379, 22.1888332],
                [102.7498603, 22.1902295],
                [102.7499758, 22.1914469],
                [102.7498887, 22.1926824],
                [102.7493665, 22.1943941],
                [102.7491707, 22.1953976],
                [102.7491473, 22.1968646],
                [102.7498383, 22.2000813],
                [102.750656, 22.2023984],
                [102.7509864, 22.2042477],
                [102.750978, 22.206197],
                [102.751374, 22.2067475],
                [102.7522227, 22.2071366],
                [102.7532207, 22.2072955],
                [102.7528683, 22.2080706],
                [102.7515527, 22.2084017],
                [102.7512324, 22.2087178],
                [102.7506372, 22.2092832],
                [102.750184, 22.2101238],
                [102.7499917, 22.211159],
                [102.749985, 22.2115687],
                [102.7501089, 22.2120757],
                [102.7503084, 22.2124336],
                [102.7505101, 22.2126548],
                [102.7507122, 22.2128489],
                [102.7507302, 22.2135593],
                [102.750724, 22.2139417],
                [102.7506606, 22.2142413],
                [102.7503033, 22.2145914],
                [102.7496537, 22.2148829],
                [102.749299, 22.2150692],
                [102.7490602, 22.215339],
                [102.7489936, 22.2158297],
                [102.7488481, 22.2166881],
                [102.7483967, 22.2174194],
                [102.747741, 22.2180933],
                [102.7473241, 22.2184972],
                [102.7466763, 22.2187608],
                [102.7461772, 22.2188085],
                [102.7457374, 22.2188024],
                [102.7452373, 22.2189047],
                [102.7447363, 22.2190615],
                [102.7442047, 22.2192998],
                [102.7436999, 22.2197023],
                [102.7432509, 22.2202694],
                [102.7431485, 22.2211689],
                [102.7432252, 22.2218798],
                [102.7433454, 22.2226104],
                [102.743313, 22.2228012],
                [102.7432226, 22.2229638],
                [102.7428681, 22.2231228],
                [102.7424865, 22.2231448],
                [102.7418426, 22.2230813],
                [102.7412579, 22.2230467],
                [102.7408447, 22.2232048],
                [102.7404574, 22.2235817],
                [102.7402167, 22.2239607],
                [102.7400337, 22.224395],
                [102.7398224, 22.2247743],
                [102.7396088, 22.2252902],
                [102.7396547, 22.2260827],
                [102.7396691, 22.2270114],
                [102.7396613, 22.2275028],
                [102.7396522, 22.2280761],
                [102.7395886, 22.2283755],
                [102.7393186, 22.2287541],
                [102.7385356, 22.229117],
                [102.738034, 22.2293013],
                [102.7375591, 22.22965],
                [102.7370266, 22.2300256],
                [102.7363629, 22.2302535],
                [102.735952, 22.2302752],
                [102.7355413, 22.2302695],
                [102.7353043, 22.2304029],
                [102.7351854, 22.2305105],
                [102.7351197, 22.2309468],
                [102.7351726, 22.231303],
                [102.7355158, 22.2318544],
                [102.7356523, 22.2324849],
                [102.7356177, 22.2328124],
                [102.7348781, 22.2332664],
                [102.7344642, 22.2334518],
                [102.7339357, 22.2334718],
                [102.7335556, 22.2333845],
                [102.7328293, 22.2329374],
                [102.7322477, 22.2326014],
                [102.7317789, 22.2325676],
                [102.7313681, 22.2325618],
                [102.731073, 22.2326669],
                [102.7310669, 22.2330494],
                [102.7311438, 22.2337333],
                [102.7311613, 22.2344712],
                [102.7312396, 22.2350733],
                [102.7310303, 22.2353163],
                [102.7306791, 22.2352567],
                [102.7301578, 22.2348397],
                [102.7295149, 22.2346667],
                [102.7290433, 22.2347967],
                [102.7289518, 22.235014],
                [102.7291413, 22.2360001],
                [102.7294535, 22.2366602],
                [102.7296181, 22.2373728],
                [102.729615, 22.2375639],
                [102.7295829, 22.2377274],
                [102.7294328, 22.2379438],
                [102.7291372, 22.2380764],
                [102.7287559, 22.238071],
                [102.7280529, 22.2379792],
                [102.7274089, 22.2378883],
                [102.7267929, 22.2378796],
                [102.7261759, 22.2379255],
                [102.7256456, 22.2380548],
                [102.7250553, 22.2382651],
                [102.7244361, 22.2384476],
                [102.7236631, 22.2381545],
                [102.7230219, 22.2378721],
                [102.7224373, 22.2377272],
                [102.7217917, 22.2377182],
                [102.7210244, 22.2379809],
                [102.7198377, 22.2387573],
                [102.7192419, 22.2393529],
                [102.7187533, 22.2396107],
                [102.7184567, 22.239798],
                [102.718104, 22.2398205],
                [102.7178398, 22.2398167],
                [102.7175216, 22.2395387],
                [102.7171176, 22.2390955],
                [102.7166822, 22.2387885],
                [102.7163328, 22.2386195],
                [102.7160099, 22.238615],
                [102.715828, 22.238968],
                [102.7157573, 22.2397054],
                [102.7156561, 22.2405807],
                [102.7155044, 22.2408793],
                [102.7153542, 22.2410959],
                [102.715029, 22.241228],
                [102.7146767, 22.2412231],
                [102.7142123, 22.2408885],
                [102.7136082, 22.2401145],
                [102.7133528, 22.2395643],
                [102.7129785, 22.2391215],
                [102.712602, 22.2388476],
                [102.7119595, 22.2386198],
                [102.7115227, 22.2383949],
                [102.7110568, 22.2381423],
                [102.7106756, 22.2381097],
                [102.7103526, 22.2381051],
                [102.7099958, 22.2383734],
                [102.709695, 22.2388067],
                [102.7093859, 22.2397593],
                [102.7089957, 22.2402732],
                [102.7084329, 22.2405661],
                [102.707811, 22.2408854],
                [102.7070321, 22.2418314],
                [102.7062561, 22.2425859],
                [102.7054842, 22.2430946],
                [102.7046727, 22.2433206],
                [102.7040531, 22.2435034],
                [102.7037007, 22.2434984],
                [102.7032914, 22.2433831],
                [102.7029995, 22.2432696],
                [102.7027063, 22.2432381],
                [102.7024981, 22.2433993],
                [102.702463, 22.2437545],
                [102.7023958, 22.2442735],
                [102.7021514, 22.2448445],
                [102.7019401, 22.2451972],
                [102.7016447, 22.2453025],
                [102.7010863, 22.2453221],
                [102.7003229, 22.2453113],
                [102.6996467, 22.2453563],
                [102.699264, 22.2454057],
                [102.6986113, 22.2458068],
                [102.6979875, 22.2462358],
                [102.6975435, 22.2464484],
                [102.6972484, 22.2465263],
                [102.6969037, 22.2460562],
                [102.6964985, 22.2456948],
                [102.6962093, 22.2454171],
                [102.6958302, 22.2452475],
                [102.6955371, 22.245216],
                [102.6948555, 22.2455894],
                [102.6940848, 22.2460163],
                [102.6936705, 22.2462019],
                [102.6929026, 22.2464646],
                [102.6923718, 22.2465939],
                [102.6919869, 22.24678],
                [102.6919543, 22.2469711],
                [102.6919522, 22.2471079],
                [102.6920082, 22.2472728],
                [102.6921688, 22.2475289],
                [102.6926851, 22.2477598],
                [102.6930813, 22.247989],
                [102.6932783, 22.2481782],
                [102.6933171, 22.2482532],
                [102.6933152, 22.2483651],
                [102.6933121, 22.2485513],
                [102.6932304, 22.2486619],
                [102.6929067, 22.248881],
                [102.6925443, 22.2490249],
                [102.6919808, 22.2492405],
                [102.6899244, 22.2502175],
                [102.6886743, 22.2508332],
                [102.6883906, 22.2510529],
                [102.6881057, 22.2513469],
                [102.6876984, 22.2517884],
                [102.6874523, 22.2521575],
                [102.6871656, 22.2525634],
                [102.6868796, 22.252932],
                [102.686434, 22.2532611],
                [102.6861911, 22.2534439],
                [102.6854669, 22.2536945],
                [102.6850632, 22.2539123],
                [102.6846602, 22.2540929],
                [102.6840947, 22.2544203],
                [102.6838103, 22.2546771],
                [102.6834437, 22.2550818],
                [102.6833187, 22.2553781],
                [102.6831126, 22.2557479],
                [102.6829207, 22.2560078],
                [102.6827207, 22.256128],
                [102.6826207, 22.256188],
                [102.6823891, 22.2562155],
                [102.6821909, 22.2562127],
                [102.682026, 22.2562104],
                [102.681762, 22.2562066],
                [102.6815624, 22.2562961],
                [102.6813953, 22.2564166],
                [102.6812593, 22.2566607],
                [102.6812211, 22.2569677],
                [102.6812771, 22.2575836],
                [102.6812724, 22.2578603],
                [102.681204, 22.258013],
                [102.6811354, 22.2581659],
                [102.6807683, 22.2584066],
                [102.6806017, 22.2584966],
                [102.6803352, 22.2586464],
                [102.680067, 22.2588887],
                [102.6797649, 22.2591918],
                [102.6795949, 22.259497],
                [102.6794232, 22.2598943],
                [102.679116, 22.2605051],
                [102.6789139, 22.2607482],
                [102.6787128, 22.2609298],
                [102.6783442, 22.2612628],
                [102.677976, 22.2615652],
                [102.6776673, 22.2618719],
                [102.6771441, 22.2622496],
                [102.6771434, 22.2622501],
                [102.6769141, 22.2622468],
                [102.6761348, 22.2616197],
                [102.6714991, 22.2585208],
                [102.6697878, 22.2572702],
                [102.667896, 22.2561415],
                [102.6664485, 22.2558719],
                [102.6646603, 22.2554197],
                [102.6639022, 22.2550535],
                [102.6637518, 22.2549093],
                [102.6635268, 22.2546573],
                [102.6632993, 22.2545474],
                [102.6629949, 22.2544719],
                [102.662537, 22.2544297],
                [102.6621945, 22.2543537],
                [102.6620816, 22.2542455],
                [102.6617443, 22.2538498],
                [102.6616716, 22.2536355],
                [102.6616763, 22.2533513],
                [102.6617962, 22.2530331],
                [102.6618761, 22.2528211],
                [102.6623392, 22.2525435],
                [102.6644954, 22.2515439],
                [102.666531, 22.250898],
                [102.6679084, 22.2507757],
                [102.6682175, 22.2505668],
                [102.6685285, 22.2502515],
                [102.6687699, 22.2495087],
                [102.6695204, 22.2480269],
                [102.6701833, 22.247219],
                [102.6707644, 22.2467299],
                [102.6717223, 22.2465658],
                [102.6726014, 22.246543],
                [102.6733659, 22.2465184],
                [102.6737897, 22.2463113],
                [102.674216, 22.2459436],
                [102.6745946, 22.2457829],
                [102.675143, 22.2453496],
                [102.6761647, 22.244923],
                [102.6766392, 22.2448668],
                [102.6773154, 22.2448766],
                [102.6783309, 22.244828],
                [102.6792163, 22.2444626],
                [102.6800726, 22.243813],
                [102.6806958, 22.2429396],
                [102.6812566, 22.2417503],
                [102.6818828, 22.2406879],
                [102.6824987, 22.2402555],
                [102.6839397, 22.2390157],
                [102.6857925, 22.2374036],
                [102.6871627, 22.2363517],
                [102.6873821, 22.2353466],
                [102.6873684, 22.2341174],
                [102.686874, 22.2312745],
                [102.6870976, 22.2300174],
                [102.6882137, 22.2279536],
                [102.6894577, 22.2263328],
                [102.6901629, 22.2245782],
                [102.6907796, 22.2240828],
                [102.6915328, 22.2235263],
                [102.692497, 22.2224687],
                [102.6934674, 22.221033],
                [102.69424, 22.2192793],
                [102.6947587, 22.2165138],
                [102.6949842, 22.2151305],
                [102.6958265, 22.2132519],
                [102.6963818, 22.2123775],
                [102.697637, 22.2100635],
                [102.697719, 22.2091823],
                [102.6976719, 22.2079213],
                [102.6973438, 22.2052382],
                [102.6961999, 22.2007477],
                [102.6956918, 22.1987238],
                [102.6949666, 22.1975792],
                [102.6941698, 22.1966856],
                [102.6932366, 22.1958531],
                [102.6925678, 22.1954025],
                [102.6920352, 22.1948909],
                [102.6912373, 22.1940602],
                [102.690296, 22.1937317],
                [102.6894877, 22.1935313],
                [102.688821, 22.1929546],
                [102.6883569, 22.1923808],
                [102.6881335, 22.1915787],
                [102.6880802, 22.1906963],
                [102.6876618, 22.1873528],
                [102.6872184, 22.1855202],
                [102.686981, 22.1835017],
                [102.6865265, 22.1823616],
                [102.6855995, 22.1811521],
                [102.6850046, 22.1803248],
                [102.6841402, 22.1794309],
                [102.683338, 22.1790403],
                [102.6826726, 22.1784007],
                [102.6819498, 22.1771303],
                [102.6817105, 22.1752367],
                [102.6814567, 22.174225],
                [102.6810074, 22.1727695],
                [102.6801475, 22.171623],
                [102.6795484, 22.1710475],
                [102.6788083, 22.1708479],
                [102.6784756, 22.1705281],
                [102.6780169, 22.1696396],
                [102.6774316, 22.1682451],
                [102.6767713, 22.1672906],
                [102.6761683, 22.166967],
                [102.6753532, 22.1672073],
                [102.6736523, 22.1678761],
                [102.6719577, 22.168167],
                [102.6695883, 22.1683851],
                [102.6689738, 22.1687544],
                [102.6684904, 22.1693775],
                [102.6677325, 22.1702487],
                [102.6668393, 22.1711179],
                [102.6661564, 22.1715492],
                [102.6650053, 22.1716588],
                [102.6639521, 22.1719902],
                [102.6626473, 22.1732316],
                [102.6606708, 22.1742114],
                [102.6591726, 22.1748829],
                [102.6576118, 22.1752385],
                [102.65531, 22.1754574],
                [102.6537566, 22.175372],
                [102.6507217, 22.1749502],
                [102.6486299, 22.174731],
                [102.6474187, 22.1743986],
                [102.6461378, 22.174191],
                [102.6453268, 22.1741793],
                [102.6448528, 22.1742355],
                [102.6442382, 22.1746046],
                [102.6434167, 22.1752228],
                [102.6426639, 22.1757789],
                [102.6416429, 22.1762051],
                [102.6405594, 22.1763155],
                [102.6395384, 22.1767417],
                [102.6390559, 22.1773018],
                [102.637794, 22.1799926],
                [102.6371014, 22.1809907],
                [102.6368766, 22.1823105],
                [102.6369104, 22.1843271],
                [102.6367509, 22.1857739],
                [102.6364648, 22.1867149],
                [102.6356432, 22.1873331],
                [102.6346897, 22.1877602],
                [102.6333379, 22.1877406],
                [102.6310442, 22.1874552],
                [102.6298278, 22.1874375],
                [102.6288772, 22.1876757],
                [102.6280557, 22.1882938],
                [102.6274626, 22.1893877],
                [102.6264698, 22.1921454],
                [102.6247399, 22.1945144],
                [102.62301, 22.1968833],
                [102.6220561, 22.1973104],
                [102.6209737, 22.1973575],
                [102.6200263, 22.1974067],
                [102.619078, 22.1975189],
                [102.618534, 22.1976999],
                [102.6183238, 22.1981378],
                [102.6180708, 22.1991108],
                [102.6174263, 22.2012434],
                [102.6173172, 22.203699],
                [102.6176318, 22.2050897],
                [102.6180204, 22.2061034],
                [102.6184775, 22.2070551],
                [102.6187461, 22.2091697],
                [102.6185094, 22.2111824],
                [102.6182165, 22.2125012],
                [102.6178722, 22.2128742],
                [102.6171202, 22.2133672],
                [102.6164334, 22.2139872],
                [102.6160849, 22.2146121],
                [102.6162106, 22.215181],
                [102.6166741, 22.2157548],
                [102.6173363, 22.2165836],
                [102.6176615, 22.2173444],
                [102.6175234, 22.219516],
                [102.6161277, 22.2220788],
                [102.6160526, 22.2225186],
                [102.6163124, 22.2231525],
                [102.6170433, 22.2239192],
                [102.6179082, 22.224751],
                [102.6186422, 22.2253287],
                [102.6196392, 22.2263515],
                [102.6202382, 22.2269272],
                [102.6205676, 22.227436],
                [102.6204207, 22.2281269],
                [102.6200013, 22.2289399],
                [102.6196111, 22.2300255],
                [102.6197872, 22.2316021],
                [102.619691, 22.2333004],
                [102.6193349, 22.2343655],
                [102.6185762, 22.2352358],
                [102.6174903, 22.2354717],
                [102.6167466, 22.2354608],
                [102.6157314, 22.235509],
                [102.6149835, 22.2357497],
                [102.6142967, 22.2363692],
                [102.6131314, 22.2372965],
                [102.6106719, 22.2387713],
                [102.6089023, 22.2394379],
                [102.6078183, 22.2395478],
                [102.6068751, 22.239345],
                [102.606274, 22.2388955],
                [102.605674, 22.238383],
                [102.6049283, 22.238498],
                [102.6043123, 22.2389296],
                [102.6033465, 22.2400485],
                [102.6025857, 22.2410446],
                [102.6005209, 22.2431547],
                [102.5985398, 22.2443216],
                [102.5975855, 22.2448287],
                [102.5956425, 22.2456222],
                [102.5943606, 22.2461057],
                [102.5941622, 22.2464258],
                [102.594041, 22.2467471],
                [102.5939949, 22.2471771],
                [102.5939086, 22.2477144],
                [102.5938247, 22.248108],
                [102.5934658, 22.2487846],
                [102.5932704, 22.2489253],
                [102.5929189, 22.2491714],
                [102.592606, 22.249418],
                [102.5922941, 22.2495928],
                [102.5888351, 22.2510132],
                [102.587231, 22.2522816],
                [102.5868354, 22.2528501],
                [102.5865548, 22.2534562],
                [102.5863526, 22.2539916],
                [102.5861535, 22.2543475],
                [102.5859545, 22.2547036],
                [102.5856795, 22.2549866],
                [102.5847423, 22.2556188],
                [102.5836944, 22.2559622],
                [102.5832651, 22.2562429],
                [102.5827977, 22.2564873],
                [102.5805228, 22.2586071],
                [102.5796511, 22.2599223],
                [102.5794134, 22.2602777],
                [102.579139, 22.2605248],
                [102.5785185, 22.2606951],
                [102.578287, 22.2606916],
                [102.5755536, 22.2602919],
                [102.5734314, 22.2602602],
                [102.5719596, 22.2605613],
                [102.5712246, 22.2606579],
                [102.5706452, 22.2606851],
                [102.5699899, 22.2606394],
                [102.5696058, 22.260526],
                [102.5690687, 22.2603385],
                [102.5679186, 22.2598906],
                [102.5670343, 22.2596978],
                [102.565835, 22.2598594],
                [102.5642835, 22.2603027],
                [102.5640134, 22.2602987],
                [102.5637833, 22.2602234],
                [102.5635541, 22.2600764],
                [102.5632866, 22.2599287],
                [102.5629063, 22.2596001],
                [102.5626025, 22.2593083],
                [102.5622622, 22.2589083],
                [102.561735, 22.2581467],
                [102.5608034, 22.2562302],
                [102.5599763, 22.2549615],
                [102.559751, 22.2545992],
                [102.5594884, 22.2541644],
                [102.5593016, 22.2538027],
                [102.5592295, 22.2535145],
                [102.55913, 22.2525798],
                [102.5591412, 22.2519338],
                [102.5591126, 22.251359],
                [102.5587877, 22.250062],
                [102.5573869, 22.2484974],
                [102.5562165, 22.2470082],
                [102.5552407, 22.2454142],
                [102.5547603, 22.2441898],
                [102.5545465, 22.2440617],
                [102.5544339, 22.2439942],
                [102.5541011, 22.2438014],
                [102.5534658, 22.243604],
                [102.5524288, 22.2433067],
                [102.5508854, 22.243127],
                [102.5494446, 22.2428548],
                [102.5475321, 22.2426383],
                [102.5453546, 22.2421984],
                [102.5442823, 22.2419945],
                [102.5435425, 22.2420146],
                [102.5428334, 22.2421917],
                [102.5422251, 22.2423703],
                [102.5416154, 22.2426428],
                [102.5403966, 22.243125],
                [102.5389773, 22.2435417],
                [102.5380779, 22.2439378],
                [102.5376823, 22.245093],
                [102.5371611, 22.2456246],
                [102.5364368, 22.2460581],
                [102.5353741, 22.2463276],
                [102.5348627, 22.2462882],
                [102.5338101, 22.2459864],
                [102.5336049, 22.2460151],
                [102.5332944, 22.2462008],
                [102.5331892, 22.2463579],
                [102.5330126, 22.2467045],
                [102.5329068, 22.2468933],
                [102.5324197, 22.2474256],
                [102.5290012, 22.249691],
                [102.5267555, 22.251244],
                [102.5243178, 22.252064],
                [102.5232114, 22.2528724],
                [102.5219926, 22.2542506],
                [102.520597, 22.2559751],
                [102.5191398, 22.2573179],
                [102.5167915, 22.2588692],
                [102.5163076, 22.259211],
                [102.5157163, 22.2598368],
                [102.5148907, 22.2602051],
                [102.5144449, 22.2603252],
                [102.5139649, 22.2604449],
                [102.5136874, 22.2606945],
                [102.5136302, 22.2607716],
                [102.5136174, 22.2607888],
                [102.513538, 22.2614224],
                [102.5133427, 22.2628161],
                [102.513328, 22.2636413],
                [102.5121984, 22.2676871],
                [102.5108607, 22.2699838],
                [102.50925, 22.2722765],
                [102.5091391, 22.2727509],
                [102.5090949, 22.2733217],
                [102.5091234, 22.2736395],
                [102.5092498, 22.2742129],
                [102.5094141, 22.2745645],
                [102.5096475, 22.2748855],
                [102.509779, 22.2751732],
                [102.5098404, 22.2755551],
                [102.5093823, 22.2763734],
                [102.5087835, 22.2774118],
                [102.5084634, 22.278137],
                [102.5082514, 22.2785464],
                [102.5080409, 22.2788606],
                [102.5077969, 22.2791425],
                [102.5074136, 22.279581],
                [102.5071356, 22.2798624],
                [102.506857, 22.2801757],
                [102.5066467, 22.2804898],
                [102.5064334, 22.2809627],
                [102.5059365, 22.2820343],
                [102.5048895, 22.2833197],
                [102.5033597, 22.2848834],
                [102.5019006, 22.2874043],
                [102.5013604, 22.2882625],
                [102.5002541, 22.2894211],
                [102.4997045, 22.2899066],
                [102.4990938, 22.2903743],
                [102.4987613, 22.2906735],
                [102.4984739, 22.2909318],
                [102.4982924, 22.2911088],
                [102.4980963, 22.2912579],
                [102.4978394, 22.2914891],
                [102.4976717, 22.2917215],
                [102.4973853, 22.2921683],
                [102.4971256, 22.2925515],
                [102.4969413, 22.2927684],
                [102.4967821, 22.2929364],
                [102.4965969, 22.2931144],
                [102.4962831, 22.2932625],
                [102.496149, 22.2933259],
                [102.4955994, 22.2934163],
                [102.4952877, 22.293265],
                [102.4951682, 22.2931501],
                [102.4950413, 22.293028],
                [102.4946141, 22.2923804],
                [102.4943979, 22.2922023],
                [102.4943044, 22.2921717],
                [102.4942105, 22.2921703],
                [102.4940204, 22.2922839],
                [102.4935745, 22.2926848],
                [102.4925687, 22.2937328],
                [102.4915797, 22.2947083],
                [102.4906249, 22.2955384],
                [102.4903335, 22.2960584],
                [102.4901707, 22.2964056],
                [102.490063, 22.2971615],
                [102.4900373, 22.2977293],
                [102.4901188, 22.2984298],
                [102.490207, 22.2987517],
                [102.4903882, 22.2991333],
                [102.4905407, 22.2993688],
                [102.4908478, 22.2997231],
                [102.4909694, 22.299929],
                [102.4910869, 22.3003679],
                [102.4910807, 22.3007174],
                [102.4910754, 22.3010088],
                [102.4910416, 22.3011539],
                [102.4908772, 22.3015885],
                [102.4902944, 22.3026283],
                [102.4897323, 22.3033918],
                [102.4889961, 22.3042546],
                [102.4880708, 22.3051727],
                [102.4871496, 22.3058576],
                [102.4860745, 22.3063946],
                [102.4849701, 22.3068146],
                [102.4847791, 22.3069863],
                [102.4844891, 22.3074189],
                [102.484227, 22.3080559],
                [102.4834684, 22.3092824],
                [102.482352, 22.3103724],
                [102.482093, 22.3108346],
                [102.4819906, 22.3112991],
                [102.4819514, 22.3117356],
                [102.4819144, 22.3120555],
                [102.4817813, 22.3124905],
                [102.4814611, 22.3128643],
                [102.4793364, 22.3151186],
                [102.477579, 22.3169851],
                [102.4767162, 22.3179041],
                [102.4761444, 22.3183323],
                [102.4754169, 22.3186997],
                [102.47488, 22.3189245],
                [102.4745933, 22.3191822],
                [102.4743661, 22.3196157],
                [102.4741963, 22.3203416],
                [102.4739872, 22.3215038],
                [102.4736982, 22.3227667],
                [102.4736574, 22.3232905],
                [102.4736459, 22.3239315],
                [102.4736833, 22.3253306],
                [102.4736659, 22.3262918],
                [102.473595, 22.326757],
                [102.4733873, 22.3269723],
                [102.4731967, 22.327115],
                [102.472596, 22.3273969],
                [102.4722487, 22.3275373],
                [102.471555, 22.3277595],
                [102.4708307, 22.3279522],
                [102.4702938, 22.3281769],
                [102.4696909, 22.3285753],
                [102.4693082, 22.3289482],
                [102.4689238, 22.3294084],
                [102.4684745, 22.3299841],
                [102.4679218, 22.3310827],
                [102.467624, 22.3319521],
                [102.4672672, 22.3326167],
                [102.4668662, 22.3331204],
                [102.465298, 22.3349023],
                [102.4641164, 22.3361075],
                [102.4635105, 22.3366808],
                [102.4628758, 22.3371079],
                [102.4615608, 22.3378886],
                [102.4581159, 22.3394371],
                [102.4573856, 22.3399501],
                [102.4570989, 22.3402078],
                [102.4566192, 22.3407248],
                [102.4562969, 22.3412151],
                [102.4561609, 22.3417956],
                [102.456186, 22.3421457],
                [102.4563655, 22.3426147],
                [102.4565451, 22.3430836],
                [102.4566555, 22.3439012],
                [102.4566728, 22.3446883],
                [102.4566137, 22.3453429],
                [102.4567342, 22.3490743],
                [102.4566651, 22.3494228],
                [102.4564569, 22.3496672],
                [102.4546478, 22.3508917],
                [102.4529957, 22.3520895],
                [102.4526425, 22.3525501],
                [102.4524157, 22.3529545],
                [102.4523361, 22.3538856],
                [102.4521565, 22.3551357],
                [102.4519584, 22.3556861],
                [102.4518003, 22.3559075],
                [102.4514437, 22.3564064],
                [102.4498852, 22.3584943],
                [102.4498418, 22.3588325],
                [102.4479935, 22.3607954],
                [102.4472647, 22.3612209],
                [102.4463516, 22.3614396],
                [102.4453793, 22.3614534],
                [102.4449209, 22.3616647],
                [102.4443152, 22.3622087],
                [102.4423326, 22.3643335],
                [102.4413429, 22.3653084],
                [102.4407075, 22.3657647],
                [102.4402661, 22.3659033],
                [102.4391371, 22.3659147],
                [102.4375449, 22.3655397],
                [102.4362663, 22.3651699],
                [102.4357636, 22.3652202],
                [102.4353686, 22.3654033],
                [102.4320936, 22.3678862],
                [102.4296264, 22.3690707],
                [102.4274853, 22.3695612],
                [102.4266331, 22.3698682],
                [102.4262218, 22.3700655],
                [102.4260296, 22.3702956],
                [102.4259641, 22.3704403],
                [102.4258466, 22.3708608],
                [102.4258603, 22.3718225],
                [102.4258183, 22.3724047],
                [102.4257145, 22.3729274],
                [102.4254866, 22.37339],
                [102.4252272, 22.3738521],
                [102.424776, 22.3745149],
                [102.4241656, 22.3753211],
                [102.4240347, 22.3756103],
                [102.4239327, 22.3760457],
                [102.4239076, 22.3765552],
                [102.4239283, 22.3771383],
                [102.424127, 22.3799677],
                [102.4240497, 22.3807532],
                [102.4236809, 22.3820584],
                [102.4233561, 22.382665],
                [102.4229373, 22.3832702],
                [102.4224871, 22.383875],
                [102.4216571, 22.3846775],
                [102.4208594, 22.3854224],
                [102.4202707, 22.3858937],
                [102.419955, 22.3860052],
                [102.4193881, 22.3861419],
                [102.418913, 22.3863966],
                [102.4182434, 22.3869976],
                [102.4173814, 22.3878289],
                [102.4169064, 22.3880835],
                [102.4165138, 22.3881209],
                [102.4147598, 22.3880055],
                [102.4126941, 22.3877976],
                [102.4100301, 22.3877258],
                [102.4094312, 22.3878911],
                [102.4090188, 22.3881466],
                [102.4087967, 22.3882888],
                [102.4083827, 22.3886317],
                [102.4077747, 22.3892921],
                [102.4065438, 22.3905637],
                [102.4062541, 22.3909667],
                [102.4056099, 22.391888],
                [102.4051483, 22.3931034],
                [102.4046846, 22.3944352],
                [102.4043186, 22.3955649],
                [102.404024, 22.3962297],
                [102.4036078, 22.3966889],
                [102.4031003, 22.397001],
                [102.4023403, 22.3973963],
                [102.4018009, 22.3977371],
                [102.4013857, 22.398138],
                [102.4011614, 22.3983965],
                [102.400708, 22.3991752],
                [102.4005805, 22.399483],
                [102.4004095, 22.3998958],
                [102.4001615, 22.4002954],
                [102.3998183, 22.4006485],
                [102.3993314, 22.4009547],
                [102.3988945, 22.4011719],
                [102.3984598, 22.4012546],
                [102.3979757, 22.4014261],
                [102.3975429, 22.4014192],
                [102.3969674, 22.4013203],
                [102.396489, 22.4011779],
                [102.3959657, 22.4008556],
                [102.395537, 22.4006245],
                [102.395203, 22.4004846],
                [102.3946757, 22.4003864],
                [102.3943382, 22.4004259],
                [102.3940472, 22.4005557],
                [102.3939543, 22.4006182],
                [102.3936574, 22.4008184],
                [102.392877, 22.4013888],
                [102.3920426, 22.4022722],
                [102.3914032, 22.4030243],
                [102.3913443, 22.4036062],
                [102.3912405, 22.4040081],
                [102.3910887, 22.4044092],
                [102.3909808, 22.4050353],
                [102.3907327, 22.4054349],
                [102.390532, 22.4058801],
                [102.3903801, 22.4062813],
                [102.389988, 22.4066786],
                [102.3895019, 22.4069398],
                [102.3889696, 22.4071105],
                [102.3886278, 22.407374],
                [102.3883301, 22.4078625],
                [102.3876813, 22.4091077],
                [102.387171, 22.4106689],
                [102.3868838, 22.4111189],
                [102.3868837, 22.4113466],
                [102.3870009, 22.4114348],
                [102.3872425, 22.411536],
                [102.3874515, 22.4116237],
                [102.3880841, 22.4118263],
                [102.3891437, 22.4121964],
                [102.3895873, 22.4123961],
                [102.3899435, 22.4126586],
                [102.390162, 22.4129348],
                [102.3903461, 22.4132105],
                [102.3904435, 22.4135169],
                [102.3905416, 22.4137911],
                [102.3908538, 22.414109],
                [102.3913982, 22.4144387],
                [102.3918743, 22.4147351],
                [102.3921793, 22.4149806],
                [102.3923645, 22.4151922],
                [102.3925148, 22.4154352],
                [102.3925625, 22.4156445],
                [102.3925589, 22.415837],
                [102.3924319, 22.4161878],
                [102.3919734, 22.4167901],
                [102.3918136, 22.4170602],
                [102.3915584, 22.417369],
                [102.3913816, 22.4176227],
                [102.3912556, 22.4179255],
                [102.3912384, 22.4188396],
                [102.3912234, 22.4196415],
                [102.3912166, 22.4200103],
                [102.3912429, 22.4204439],
                [102.3912947, 22.4208859],
                [102.3914455, 22.4211129],
                [102.3916144, 22.4212761],
                [102.3918009, 22.4214235],
                [102.3919035, 22.4214572],
                [102.3919894, 22.4214586],
                [102.3921448, 22.4214291],
                [102.3922667, 22.4213508],
                [102.3924243, 22.4211929],
                [102.3925837, 22.4209549],
                [102.3927095, 22.4206681],
                [102.3929044, 22.4203665],
                [102.3936914, 22.4196734],
                [102.3951388, 22.4186219],
                [102.3959207, 22.4182013],
                [102.3965436, 22.4180028],
                [102.3972071, 22.4179414],
                [102.3974997, 22.41793],
                [102.3976886, 22.417949],
                [102.3978768, 22.4180003],
                [102.3980642, 22.4180995],
                [102.3981988, 22.4182622],
                [102.3982311, 22.4183749],
                [102.3982291, 22.4184872],
                [102.398192, 22.418631],
                [102.3980682, 22.4188055],
                [102.3976961, 22.4193931],
                [102.3975549, 22.4195831],
                [102.3975338, 22.4197915],
                [102.3975438, 22.4201767],
                [102.3975623, 22.4210272],
                [102.3976021, 22.4221267],
                [102.3976106, 22.422592],
                [102.3975539, 22.4228639],
                [102.3974996, 22.4230074],
                [102.3974098, 22.4232144],
                [102.397354, 22.4234381],
                [102.3973334, 22.423542],
                [102.3971494, 22.4243606],
                [102.3968132, 22.4251712],
                [102.3964397, 22.4264164],
                [102.3963411, 22.4270133],
                [102.3962414, 22.4276644],
                [102.396283, 22.4285627],
                [102.3965917, 22.4292206],
                [102.3966932, 22.4300382],
                [102.396849, 22.4310743],
                [102.3969275, 22.4323444],
                [102.3969212, 22.432671],
                [102.3967075, 22.4331846],
                [102.3962007, 22.4337479],
                [102.3950716, 22.4348183],
                [102.3930464, 22.4369899],
                [102.3918281, 22.4381405],
                [102.3912605, 22.4388388],
                [102.3908397, 22.4394852],
                [102.3907139, 22.4399731],
                [102.3906786, 22.440299],
                [102.390693, 22.4410885],
                [102.3906798, 22.4417959],
                [102.3907205, 22.4450759],
                [102.3910545, 22.4475033],
                [102.3916045, 22.4493084],
                [102.3918192, 22.4503188],
                [102.3918489, 22.4510677],
                [102.391805, 22.4518561],
                [102.3915306, 22.4524776],
                [102.391376, 22.4529377],
                [102.3913115, 22.4532633],
                [102.3913347, 22.4535902],
                [102.3916199, 22.4539487],
                [102.3921285, 22.454855],
                [102.3923799, 22.4554577],
                [102.3924997, 22.4568476],
                [102.3924298, 22.4590236],
                [102.392434, 22.4595817],
                [102.3926014, 22.4599925],
                [102.3925958, 22.4602918],
                [102.3925026, 22.4605897],
                [102.3922657, 22.4607763],
                [102.3916456, 22.4611472],
                [102.3904978, 22.4616458],
                [102.3887878, 22.4625433],
                [102.3881068, 22.4630494],
                [102.3874515, 22.4637464],
                [102.3870256, 22.4646648],
                [102.3865372, 22.4657998],
                [102.386507, 22.4674049],
                [102.3860401, 22.468173],
                [102.385651, 22.4686838],
                [102.3852352, 22.469058],
                [102.3847642, 22.469268],
                [102.3843817, 22.4694251],
                [102.3836799, 22.4694954],
                [102.3830663, 22.4695127],
                [102.3825415, 22.4694769],
                [102.3818165, 22.4692202],
                [102.3814689, 22.4690785],
                [102.3812077, 22.4689926],
                [102.3808872, 22.4689602],
                [102.3803613, 22.4689789],
                [102.3800659, 22.4691646],
                [102.3793848, 22.4696705],
                [102.3779026, 22.4708711],
                [102.3773127, 22.4711881],
                [102.3767542, 22.4713967],
                [102.3757889, 22.4714898],
                [102.3727787, 22.4716586],
                [102.3716261, 22.4716261],
                [102.3711303, 22.4715908],
                [102.3708381, 22.4716132],
                [102.3704555, 22.4717703],
                [102.3703654, 22.471905],
                [102.3698533, 22.4727402],
                [102.3692849, 22.4734656],
                [102.3683669, 22.4741582],
                [102.3674226, 22.4746871],
                [102.3666554, 22.47511],
                [102.3659503, 22.4753434],
                [102.365424, 22.4753892],
                [102.3648699, 22.475353],
                [102.3639965, 22.4752299],
                [102.363037, 22.4750235],
                [102.3628808, 22.4749714],
                [102.3626589, 22.4748972],
                [102.3622224, 22.4749089],
                [102.3620214, 22.4750596],
                [102.3618185, 22.4753025],
                [102.3617448, 22.4757015],
                [102.3617313, 22.4760433],
                [102.3617108, 22.4771287],
                [102.3612872, 22.4789767],
                [102.3612709, 22.4798361],
                [102.3609659, 22.480555],
                [102.3602304, 22.4820469],
                [102.3599116, 22.4826307],
                [102.3594756, 22.4834089],
                [102.3592929, 22.4840779],
                [102.3591521, 22.4846646],
                [102.3591441, 22.4850873],
                [102.359138, 22.4854043],
                [102.35913, 22.4858271],
                [102.3590921, 22.4861134],
                [102.3590026, 22.486565],
                [102.3590258, 22.4870487],
                [102.3590587, 22.4874494],
                [102.3591524, 22.4876321],
                [102.3592461, 22.4878149],
                [102.3593393, 22.4880279],
                [102.3593854, 22.4881645],
                [102.3594158, 22.4882708],
                [102.3594933, 22.4884533],
                [102.359586, 22.4886964],
                [102.3595851, 22.4887416],
                [102.3595487, 22.4889525],
                [102.3594983, 22.4890423],
                [102.3593178, 22.4891601],
                [102.3589915, 22.4892756],
                [102.3588112, 22.4893935],
                [102.3586623, 22.4895572],
                [102.3583154, 22.4898988],
                [102.3581669, 22.4900474],
                [102.3579854, 22.4902105],
                [102.3578213, 22.4903287],
                [102.3575601, 22.4904301],
                [102.3573481, 22.490502],
                [102.3571455, 22.4905063],
                [102.3569671, 22.4905185],
                [102.3567082, 22.4904991],
                [102.3564976, 22.4904957],
                [102.3562062, 22.4904909],
                [102.3560117, 22.4904877],
                [102.3557679, 22.490529],
                [102.3554915, 22.4905848],
                [102.3550681, 22.4906836],
                [102.354613, 22.4907668],
                [102.3529044, 22.4907312],
                [102.3523358, 22.4908125],
                [102.3519455, 22.4908816],
                [102.3516039, 22.4909514],
                [102.3513114, 22.491007],
                [102.3509705, 22.4910316],
                [102.3505176, 22.4909939],
                [102.3503082, 22.4909302],
                [102.3499706, 22.4907886],
                [102.3497791, 22.4906345],
                [102.3494248, 22.4905229],
                [102.3489241, 22.4904392],
                [102.3484873, 22.4904169],
                [102.3481306, 22.4904261],
                [102.3478057, 22.490466],
                [102.3476758, 22.490479],
                [102.3474965, 22.4905365],
                [102.3473979, 22.4906104],
                [102.3473314, 22.4906999],
                [102.3473292, 22.4908207],
                [102.3473577, 22.4910174],
                [102.347467, 22.4912307],
                [102.3479929, 22.4921228],
                [102.3481017, 22.4923662],
                [102.3481783, 22.492594],
                [102.3482387, 22.4928216],
                [102.3482183, 22.4930477],
                [102.3481973, 22.4933041],
                [102.3481463, 22.4934241],
                [102.348045, 22.4936339],
                [102.3479097, 22.4939337],
                [102.3477096, 22.4942325],
                [102.3474085, 22.4947258],
                [102.3471305, 22.4952875],
                [102.3471088, 22.4955741],
                [102.3471519, 22.4958617],
                [102.3471951, 22.4961494],
                [102.3473363, 22.4963934],
                [102.3474471, 22.4965311],
                [102.3475582, 22.4966538],
                [102.347653, 22.4967761],
                [102.347747, 22.4969438],
                [102.3478095, 22.4970656],
                [102.3478544, 22.4972627],
                [102.3478347, 22.4974437],
                [102.347815, 22.4976245],
                [102.3476761, 22.4979296],
                [102.3477097, 22.4980457],
                [102.3478998, 22.4982753],
                [102.3480919, 22.4983992],
                [102.3481426, 22.498418],
                [102.3482876, 22.4985738],
                [102.3483282, 22.4987262],
                [102.3482298, 22.499061],
                [102.3480476, 22.4992765],
                [102.3472701, 22.4993743],
                [102.3470576, 22.4995646],
                [102.3469635, 22.4998122],
                [102.3470775, 22.5000909],
                [102.3471318, 22.5003685],
                [102.3470903, 22.5009766],
                [102.3469212, 22.5020257],
                [102.3469085, 22.5026897],
                [102.3469906, 22.5030785],
                [102.3474583, 22.5035845],
                [102.3480159, 22.5040642],
                [102.3483042, 22.5045948],
                [102.3484129, 22.5051502],
                [102.348406, 22.5055098],
                [102.3483108, 22.5058127],
                [102.3479203, 22.5059447],
                [102.3473244, 22.5059071],
                [102.346821, 22.5057051],
                [102.3463481, 22.5054759],
                [102.3458123, 22.5054117],
                [102.3451865, 22.5053737],
                [102.3448866, 22.5054519],
                [102.3445533, 22.5057231],
                [102.3444281, 22.5060255],
                [102.3445379, 22.5065255],
                [102.3448901, 22.5068357],
                [102.345927, 22.5072126],
                [102.3460092, 22.5076014],
                [102.3460028, 22.5079336],
                [102.3459411, 22.5080432],
                [102.3457605, 22.5081232],
                [102.3450459, 22.508056],
                [102.3443342, 22.507823],
                [102.3437968, 22.5078418],
                [102.3434581, 22.5083897],
                [102.3433114, 22.5090434],
                [102.3431543, 22.5094563],
                [102.3429999, 22.5097308],
                [102.3429924, 22.5101185],
                [102.3432524, 22.5105659],
                [102.3435133, 22.510958],
                [102.3435667, 22.5112913],
                [102.3431285, 22.5123643],
                [102.342268, 22.5136797],
                [102.3414607, 22.5145389],
                [102.3414454, 22.5153419],
                [102.341527, 22.5157587],
                [102.341463, 22.5159792],
                [102.3412218, 22.5161138],
                [102.340294, 22.5162646],
                [102.3397491, 22.5166711],
                [102.3390116, 22.5177946],
                [102.3387533, 22.5188152],
                [102.3384362, 22.5205689],
                [102.3384916, 22.5207914],
                [102.3386669, 22.5209882],
                [102.3389902, 22.5212428],
                [102.3391952, 22.5214401],
                [102.3392209, 22.521662],
                [102.3390052, 22.5220187],
                [102.3382834, 22.5223114],
                [102.3377423, 22.522524],
                [102.3377365, 22.5228286],
                [102.3379405, 22.5230813],
                [102.3386495, 22.523453],
                [102.3389414, 22.5237904],
                [102.3389696, 22.5238739],
                [102.3390255, 22.5240686],
                [102.3394492, 22.5245328],
                [102.3395655, 22.5247009],
                [102.3398941, 22.5246787],
                [102.3403421, 22.5246584],
                [102.3406964, 22.524858],
                [102.3409846, 22.5253891],
                [102.3413021, 22.5259483],
                [102.3419063, 22.5271217],
                [102.3425386, 22.5283786],
                [102.3425627, 22.5286837],
                [102.3423501, 22.5288741],
                [102.3418142, 22.5288099],
                [102.3409587, 22.5282972],
                [102.3399178, 22.5281137],
                [102.3395326, 22.5279689],
                [102.3392664, 22.5278536],
                [102.3389979, 22.5278491],
                [102.3386645, 22.5281207],
                [102.3381078, 22.5291363],
                [102.3379855, 22.5293005],
                [102.337607, 22.5295851],
                [102.3366789, 22.5297359],
                [102.336319, 22.5298408],
                [102.3361063, 22.5300311],
                [102.3361623, 22.5302259],
                [102.3364265, 22.530452],
                [102.336572, 22.5306482],
                [102.3365362, 22.5309523],
                [102.3359929, 22.5312757],
                [102.3349377, 22.5318399],
                [102.3340624, 22.5323517],
                [102.3339305, 22.5330143],
                [102.3339187, 22.5336235],
                [102.3337616, 22.5340364],
                [102.3328315, 22.5342979],
                [102.3321454, 22.5342866],
                [102.3312883, 22.5338568],
                [102.3308439, 22.5336832],
                [102.3306026, 22.5338177],
                [102.330234, 22.5343656],
                [102.3302598, 22.5353493],
                [102.3300415, 22.5358443],
                [102.3291976, 22.5362735],
                [102.3288306, 22.5367382],
                [102.3287645, 22.5370695],
                [102.3289084, 22.5373489],
                [102.3291652, 22.5379626],
                [102.3290624, 22.5386533],
                [102.3286372, 22.5390341],
                [102.3277635, 22.5394627],
                [102.3269577, 22.5402388],
                [102.3259317, 22.5423822],
                [102.3252215, 22.5436169],
                [102.3250089, 22.5438072],
                [102.3246488, 22.543912],
                [102.3242312, 22.5439051],
                [102.3239642, 22.5438175],
                [102.3236399, 22.5436183],
                [102.3230438, 22.5435806],
                [102.3226851, 22.5436024],
                [102.3223553, 22.54368],
                [102.3221135, 22.5438421],
                [102.3221017, 22.5444513],
                [102.3221577, 22.5446461],
                [102.3228338, 22.5459453],
                [102.3236653, 22.546971],
                [102.3237599, 22.5472702],
                [102.3234576, 22.5472702],
                [102.322611, 22.5470095],
                [102.3219861, 22.5470375],
                [102.321583, 22.5474656],
                [102.3216048, 22.5479246],
                [102.3210185, 22.5477788],
                [102.3208166, 22.547586],
                [102.3203815, 22.5475655],
                [102.3202572, 22.5472714],
                [102.3198018, 22.5467666],
                [102.3195403, 22.5465252],
                [102.3193174, 22.5465035],
                [102.3190037, 22.5467869],
                [102.3186008, 22.5469962],
                [102.3184546, 22.5467171],
                [102.3187392, 22.5461712],
                [102.3187526, 22.5454697],
                [102.3184157, 22.5452737],
                [102.3181138, 22.5453327],
                [102.318107, 22.5457255],
                [102.3179014, 22.5459237],
                [102.3174995, 22.5457952],
                [102.3170767, 22.5457211],
                [102.3166828, 22.5454413],
                [102.3165938, 22.545244],
                [102.3170519, 22.5445851],
                [102.3175739, 22.5442742],
                [102.3175233, 22.5438299],
                [102.3170523, 22.5434929],
                [102.3163182, 22.5434804],
                [102.315751, 22.5437826],
                [102.3154161, 22.5436489],
                [102.3155808, 22.5429646],
                [102.3154934, 22.5425427],
                [102.315037, 22.5420716],
                [102.3145263, 22.5420716],
                [102.3143528, 22.5423427],
                [102.3140091, 22.5426072],
                [102.3136018, 22.5423062],
                [102.3133836, 22.5416692],
                [102.3129439, 22.541496],
                [102.3124127, 22.5416574],
                [102.3117533, 22.5416232],
                [102.3114185, 22.5414416],
                [102.310762, 22.5412351],
                [102.310474, 22.5409548],
                [102.3104298, 22.5403959],
                [102.3109403, 22.5395918],
                [102.3108546, 22.5389424],
                [102.3108219, 22.5384786],
                [102.3106897, 22.5381811],
                [102.3102639, 22.5381248],
                [102.3097287, 22.5381248],
                [102.3092923, 22.5378417],
                [102.3086533, 22.5371705],
                [102.3083288, 22.5362199],
                [102.3078469, 22.5356178],
                [102.307494, 22.535282],
                [102.3066927, 22.5355027],
                [102.3059197, 22.536011],
                [102.3054047, 22.5357913],
                [102.3049021, 22.5349995],
                [102.304445, 22.534045],
                [102.3037594, 22.533713],
                [102.3028839, 22.5336711],
                [102.3021414, 22.5331191],
                [102.3014061, 22.5323383],
                [102.3009287, 22.530989],
                [102.3003217, 22.5298431],
                [102.3002119, 22.5291715],
                [102.300428, 22.528482],
                [102.3003284, 22.5278497],
                [102.3004681, 22.5271541],
                [102.3005431, 22.5265661],
                [102.300566, 22.5260443],
                [102.3002497, 22.5257074],
                [102.3001243, 22.5250229],
                [102.2995877, 22.524439],
                [102.2992459, 22.5239025],
                [102.2983509, 22.5238725],
                [102.2975783, 22.523765],
                [102.297131, 22.5233571],
                [102.2971603, 22.5223875],
                [102.29723, 22.521134],
                [102.296784, 22.520653],
                [102.29671, 22.520241],
                [102.296858, 22.520104],
                [102.297305, 22.52031],
                [102.297528, 22.520516],
                [102.297825, 22.52031],
                [102.2979, 22.520104],
                [102.297453, 22.519554],
                [102.297602, 22.519073],
                [102.29723, 22.51873],
                [102.296412, 22.517837],
                [102.296486, 22.517219],
                [102.296189, 22.517082],
                [102.295743, 22.517219],
                [102.295296, 22.51667],
                [102.294925, 22.517013],
                [102.294404, 22.516876],
                [102.294106, 22.516189],
                [102.294106, 22.515777],
                [102.294776, 22.515365],
                [102.29485, 22.514953],
                [102.294627, 22.514609],
                [102.293511, 22.513923],
                [102.293958, 22.511862],
                [102.294701, 22.511587],
                [102.294478, 22.510832],
                [102.293958, 22.510626],
                [102.293883, 22.509939],
                [102.293437, 22.509252],
                [102.293288, 22.508634],
                [102.292619, 22.50836],
                [102.291578, 22.508428],
                [102.291355, 22.508222],
                [102.29076, 22.508085],
                [102.290685, 22.50781],
                [102.289867, 22.507398],
                [102.289867, 22.507192],
                [102.290685, 22.507055],
                [102.290685, 22.506711],
                [102.289718, 22.506231],
                [102.289495, 22.505544],
                [102.288603, 22.505818],
                [102.28838, 22.505612],
                [102.288677, 22.505063],
                [102.289495, 22.504445],
                [102.289346, 22.504033],
                [102.289049, 22.503827],
                [102.2889, 22.503415],
                [102.28838, 22.50314],
                [102.28719, 22.50314],
                [102.286446, 22.502591],
                [102.285702, 22.502728],
                [102.285107, 22.502522],
                [102.284735, 22.502659],
                [102.284289, 22.502591],
                [102.2823884, 22.5028045],
                [102.2821795, 22.502477],
                [102.2817873, 22.5019032],
                [102.2816687, 22.5018993],
                [102.2815672, 22.5020019],
                [102.2814442, 22.5024614],
                [102.2812949, 22.5025676],
                [102.2808743, 22.5025149],
                [102.2805, 22.5028668],
                [102.2801718, 22.5027683],
                [102.2799979, 22.5024043],
                [102.28003, 22.5017173],
                [102.2797496, 22.5012206],
                [102.280023, 22.5007679],
                [102.2800851, 22.5003453],
                [102.2793154, 22.49976],
                [102.2785128, 22.4993091],
                [102.2780465, 22.4985376],
                [102.2777731, 22.4961503],
                [102.2772923, 22.4952241],
                [102.276482, 22.4938551],
                [102.2764657, 22.4931998],
                [102.2767063, 22.4923391],
                [102.2766733, 22.491342],
                [102.2763986, 22.4902886],
                [102.2757692, 22.4895482],
                [102.275291, 22.488725],
                [102.2752765, 22.4880497],
                [102.2757793, 22.4869848],
                [102.2760986, 22.4856806],
                [102.2760126, 22.4846577],
                [102.2769567, 22.4834959],
                [102.2775459, 22.4824692],
                [102.2753737, 22.4796892],
                [102.2748741, 22.4790806],
                [102.2737534, 22.4800637],
                [102.2727758, 22.4808322],
                [102.2719935, 22.4809204],
                [102.2712111, 22.480747],
                [102.2691148, 22.4799948],
                [102.2670745, 22.4790055],
                [102.2658404, 22.4776362],
                [102.2651159, 22.4760051],
                [102.265474, 22.473823],
                [102.265102, 22.470595],
                [102.264879, 22.469359],
                [102.2643301, 22.4684272],
                [102.264061, 22.467917],
                [102.2639944, 22.4675074],
                [102.2637281, 22.4668762],
                [102.2633794, 22.4658545],
                [102.2626325, 22.4653188],
                [102.261824, 22.4653456],
                [102.2606498, 22.465604],
                [102.2596837, 22.4659494],
                [102.2588764, 22.4661739],
                [102.2584012, 22.4660534],
                [102.2577405, 22.465372],
                [102.2572292, 22.4650682],
                [102.2560929, 22.4647242],
                [102.2546601, 22.4645935],
                [102.2535433, 22.4640838],
                [102.2523918, 22.4634562],
                [102.2508799, 22.4628542],
                [102.2492398, 22.4624467],
                [102.2490427, 22.462006],
                [102.24872, 22.4610483],
                [102.2487066, 22.4599611],
                [102.2488668, 22.4584266],
                [102.2489788, 22.4573598],
                [102.2496443, 22.4564356],
                [102.2511811, 22.4549586],
                [102.2533465, 22.4535614],
                [102.254095, 22.452876],
                [102.254987, 22.451503],
                [102.256266, 22.4497007],
                [102.2570572, 22.4490407],
                [102.2572322, 22.4477701],
                [102.2572601, 22.4464803],
                [102.257727, 22.4450576],
                [102.258408, 22.443949],
                [102.258929, 22.442644],
                [102.260667, 22.4405741],
                [102.2617597, 22.4393904],
                [102.2623848, 22.4384243],
                [102.2630369, 22.4374588],
                [102.2640098, 22.4359527],
                [102.265027, 22.434746],
                [102.2663025, 22.4328852],
                [102.266589, 22.432068],
                [102.2668586, 22.4307413],
                [102.2666536, 22.4294972],
                [102.266259, 22.4282792],
                [102.2664387, 22.4275363],
                [102.2671298, 22.4265914],
                [102.2684411, 22.4252333],
                [102.268725, 22.4244451],
                [102.2683127, 22.4237235],
                [102.2664468, 22.4220547],
                [102.2662113, 22.4212356],
                [102.2662168, 22.4201008],
                [102.266366, 22.419362],
                [102.2660231, 22.4184267],
                [102.2654431, 22.4172765],
                [102.2642096, 22.4157487],
                [102.26251, 22.4138844],
                [102.2613086, 22.4141145],
                [102.2593395, 22.4145402],
                [102.2581695, 22.4150028],
                [102.2562342, 22.4157589],
                [102.2551758, 22.4165864],
                [102.2539123, 22.417323],
                [102.252681, 22.418058],
                [102.252384, 22.41847],
                [102.2518688, 22.4192688],
                [102.2505083, 22.4201135],
                [102.2492789, 22.420871],
                [102.2481748, 22.4210904],
                [102.2473477, 22.4208393],
                [102.2463255, 22.420843],
                [102.245382, 22.421322],
                [102.242373, 22.424783],
                [102.2413198, 22.4252459],
                [102.240683, 22.426051],
                [102.240155, 22.426587],
                [102.2398384, 22.4269434],
                [102.2388033, 22.4277552],
                [102.2371118, 22.4276838],
                [102.2362436, 22.4269881],
                [102.2353945, 22.4263873],
                [102.2344118, 22.4260566],
                [102.233465, 22.426017],
                [102.2327321, 22.4268044],
                [102.2321462, 22.4277014],
                [102.2312951, 22.428019],
                [102.2306418, 22.4280068],
                [102.2299969, 22.4276352],
                [102.2297342, 22.4271553],
                [102.2288751, 22.4266797],
                [102.227827, 22.4268433],
                [102.2271904, 22.4270252],
                [102.2264723, 22.4272316],
                [102.2256881, 22.4274684],
                [102.2248121, 22.4274177],
                [102.2241129, 22.4263395],
                [102.2232557, 22.4256],
                [102.2226316, 22.4253783],
                [102.221519, 22.425612],
                [102.220358, 22.426197],
                [102.2190071, 22.4270404],
                [102.2176858, 22.4274783],
                [102.216134, 22.428586],
                [102.215184, 22.429317],
                [102.214498, 22.429609],
                [102.2138998, 22.4300844],
                [102.2133966, 22.430563],
                [102.2120614, 22.4314789],
                [102.2108687, 22.4307096],
                [102.21057, 22.4300672],
                [102.2090091, 22.4291369],
                [102.208633, 22.4293152],
                [102.2075446, 22.4286151],
                [102.2073348, 22.4283858],
                [102.206473, 22.427855],
                [102.2049414, 22.4287975],
                [102.2037341, 22.4289809],
                [102.202778, 22.428342],
                [102.201986, 22.428781],
                [102.201088, 22.428635],
                [102.19998, 22.429024],
                [102.199135, 22.429853],
                [102.197973, 22.430536],
                [102.196601, 22.431072],
                [102.1951932, 22.4316739],
                [102.193791, 22.4317056],
                [102.193132, 22.4316981],
                [102.1923435, 22.4319467],
                [102.1916366, 22.4324194],
                [102.190899, 22.433022],
                [102.190477, 22.433363],
                [102.1887911, 22.4337527],
                [102.1883856, 22.4340786],
                [102.1880017, 22.4342296],
                [102.1875094, 22.4342485],
                [102.1868395, 22.4343263],
                [102.1864638, 22.43429],
                [102.1861255, 22.4341501],
                [102.185442, 22.4337299],
                [102.1848514, 22.4335199],
                [102.1841035, 22.433489],
                [102.1834468, 22.4332019],
                [102.1830256, 22.4327628],
                [102.1823861, 22.4324843],
                [102.1813877, 22.4323031],
                [102.1801803, 22.4317259],
                [102.179105, 22.4318117],
                [102.1782368, 22.4320368],
                [102.1775629, 22.4320854],
                [102.177014, 22.432096],
                [102.176465, 22.432074],
                [102.1762368, 22.4322073],
                [102.1756728, 22.4325286],
                [102.1752698, 22.4324631],
                [102.1749187, 22.4323438],
                [102.1743767, 22.4320241],
                [102.1736662, 22.4315055],
                [102.173254, 22.4310165],
                [102.1730455, 22.4308651],
                [102.1727063, 22.4308432],
                [102.1723661, 22.4307015],
                [102.1720049, 22.4304878],
                [102.1716658, 22.4303735],
                [102.1715462, 22.4299885],
                [102.1710451, 22.4296877],
                [102.170561, 22.4284878],
                [102.1702726, 22.428244],
                [102.1697005, 22.4281874],
                [102.1693282, 22.4282717],
                [102.1678939, 22.4282752],
                [102.1676872, 22.428336],
                [102.1670379, 22.428542],
                [102.1667005, 22.4286698],
                [102.1664319, 22.4287328],
                [102.1660193, 22.4286656],
                [102.1658852, 22.4286135],
                [102.165779, 22.4286131],
                [102.1656347, 22.4287586],
                [102.1653553, 22.4289285],
                [102.1650581, 22.4289576],
                [102.1647566, 22.4288482],
                [102.1642179, 22.4289472],
                [102.163816, 22.429122],
                [102.1628727, 22.4294139],
                [102.162127, 22.429414],
                [102.1602273, 22.4295644],
                [102.159857, 22.4299104],
                [102.1595886, 22.4300631],
                [102.1593898, 22.4300882],
                [102.1592639, 22.4300961],
                [102.1591205, 22.4300505],
                [102.1590328, 22.4299905],
                [102.1590147, 22.4299454],
                [102.1590576, 22.4298397],
                [102.1591004, 22.4297464],
                [102.1591159, 22.4295898],
                [102.1590777, 22.4294183],
                [102.1586323, 22.4289712],
                [102.1584917, 22.4286517],
                [102.1584477, 22.4282232],
                [102.1583812, 22.4275744],
                [102.1584701, 22.4271631],
                [102.158273, 22.426295],
                [102.158062, 22.425417],
                [102.1569574, 22.4250336],
                [102.1555781, 22.4251154],
                [102.1541024, 22.4247922],
                [102.1541094, 22.4233957],
                [102.154947, 22.421664],
                [102.155475, 22.419957],
                [102.15558, 22.419324],
                [102.15463, 22.418056],
                [102.1538718, 22.4160517],
                [102.1531917, 22.4149277],
                [102.1523113, 22.4133876],
                [102.1521386, 22.4118107],
                [102.1518642, 22.4109963],
                [102.151424, 22.4107641],
                [102.1510683, 22.4105764],
                [102.1495856, 22.4103263],
                [102.1492115, 22.409448],
                [102.1489583, 22.4080339],
                [102.1486871, 22.4072961],
                [102.1477146, 22.4069079],
                [102.1462741, 22.4063812],
                [102.1453462, 22.4059609],
                [102.1453683, 22.405344],
                [102.1458418, 22.4039507],
                [102.146553, 22.4027],
                [102.146071, 22.4022705],
                [102.1446967, 22.4014123],
                [102.1438643, 22.4004841],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [106.7511678, 10.7103776],
                [106.7512155, 10.7092477],
                [106.751422, 10.7074537],
                [106.7519936, 10.7046983],
                [106.7520953, 10.7043535],
                [106.7527559, 10.7021122],
                [106.7537771, 10.6989778],
                [106.7544018, 10.6975937],
                [106.7559878, 10.6952629],
                [106.7564167, 10.6946874],
                [106.7572016, 10.6936341],
                [106.7577455, 10.6929042],
                [106.7586695, 10.6917427],
                [106.7595858, 10.6908051],
                [106.7598401, 10.6905808],
                [106.7601264, 10.6903284],
                [106.7601793, 10.6902818],
                [106.7614913, 10.6890194],
                [106.7625865, 10.6877582],
                [106.7657553, 10.6840916],
                [106.7677139, 10.682144],
                [106.7696395, 10.6802772],
                [106.7724854, 10.6775182],
                [106.7731778, 10.6769316],
                [106.7739876, 10.6763353],
                [106.7743365, 10.6761478],
                [106.7760775, 10.6752118],
                [106.7761539, 10.6751854],
                [106.7793989, 10.6740643],
                [106.7842303, 10.6719367],
                [106.7860503, 10.6710477],
                [106.7875207, 10.670253],
                [106.7893853, 10.6688841],
                [106.7925166, 10.6662628],
                [106.7934881, 10.6653708],
                [106.7944189, 10.6645665],
                [106.7969393, 10.662661],
                [106.8017263, 10.6600398],
                [106.8057998, 10.657218],
                [106.8082433, 10.6551022],
                [106.8098994, 10.6527804],
                [106.8109029, 10.6496853],
                [106.8112341, 10.6484346],
                [106.8117597, 10.6464816],
                [106.8121898, 10.6448996],
                [106.8127962, 10.6429403],
                [106.8132138, 10.6419869],
                [106.8154857, 10.6385342],
                [106.8167113, 10.6370661],
                [106.8192127, 10.6341587],
                [106.8231005, 10.6298342],
                [106.8248346, 10.6286154],
                [106.8262785, 10.6278335],
                [106.8279845, 10.6273561],
                [106.8302733, 10.626942],
                [106.8322736, 10.6266887],
                [106.8336668, 10.6266326],
                [106.8344154, 10.6266284],
                [106.8354265, 10.6269608],
                [106.8366992, 10.6275174],
                [106.8367084, 10.6275249],
                [106.8380342, 10.6286041],
                [106.838705, 10.6294349],
                [106.8392663, 10.6307692],
                [106.8396639, 10.6322912],
                [106.8400975, 10.6342653],
                [106.840511, 10.6354641],
                [106.8410216, 10.6364764],
                [106.8412837, 10.6367649],
                [106.8431314, 10.6379857],
                [106.8445488, 10.6384865],
                [106.8467889, 10.6390677],
                [106.8483601, 10.6394217],
                [106.8499302, 10.6396105],
                [106.8513656, 10.639602],
                [106.8524998, 10.6394632],
                [106.8539605, 10.6389954],
                [106.8556939, 10.6381749],
                [106.8576729, 10.6370996],
                [106.8589205, 10.636627],
                [106.8595541, 10.636446],
                [106.860336, 10.6365542],
                [106.8612791, 10.6371425],
                [106.8618861, 10.6379447],
                [106.8624474, 10.6392466],
                [106.8628932, 10.6404155],
                [106.8633372, 10.6411864],
                [106.8636807, 10.6415783],
                [106.8637306, 10.6416352],
                [106.8646572, 10.6421235],
                [106.8657017, 10.6426169],
                [106.8664671, 10.6426928],
                [106.8674919, 10.64259],
                [106.8687712, 10.6425178],
                [106.8693094, 10.6427079],
                [106.8700124, 10.6432194],
                [106.8703904, 10.6438294],
                [106.8707222, 10.6448589],
                [106.8709849, 10.6458779],
                [106.8712811, 10.6464078],
                [106.8714977, 10.6467303],
                [106.871626, 10.6469214],
                [106.8722488, 10.6476268],
                [106.8722517, 10.6476288],
                [106.8727718, 10.6479942],
                [106.8730977, 10.6480568],
                [106.8742381, 10.6482433],
                [106.8754274, 10.6484296],
                [106.879324, 10.6484368],
                [106.8807228, 10.6482995],
                [106.8819904, 10.6479856],
                [106.882845, 10.6476106],
                [106.8831685, 10.6472864],
                [106.8834745, 10.6467688],
                [106.8835695, 10.6463331],
                [106.883969, 10.6454035],
                [106.8841603, 10.6447577],
                [106.884302, 10.6439673],
                [106.8843289, 10.6430485],
                [106.8844211, 10.6421616],
                [106.8848701, 10.6407423],
                [106.8862716, 10.6394565],
                [106.8876851, 10.6387513],
                [106.8887879, 10.6382049],
                [106.8892573, 10.6380091],
                [106.8901036, 10.6377084],
                [106.8912743, 10.6373509],
                [106.8925618, 10.6369195],
                [106.8934871, 10.6364673],
                [106.8945218, 10.6354421],
                [106.8955564, 10.6343724],
                [106.8962752, 10.6329025],
                [106.8969032, 10.6308984],
                [106.8972622, 10.6298742],
                [106.8980001, 10.6291765],
                [106.8982476, 10.6290284],
                [106.8997058, 10.627974],
                [106.9016003, 10.6264049],
                [106.9024374, 10.6256247],
                [106.9034546, 10.6246678],
                [106.9043434, 10.6234336],
                [106.9056206, 10.6203833],
                [106.9085223, 10.6127291],
                [106.9097999, 10.6099664],
                [106.9107185, 10.6086668],
                [106.9107656, 10.6085959],
                [106.9112835, 10.6081576],
                [106.9120304, 10.6078791],
                [106.9129738, 10.6078089],
                [106.9137821, 10.6080174],
                [106.9149564, 10.6084453],
                [106.9157729, 10.6088916],
                [106.916638, 10.6093375],
                [106.9175522, 10.6097993],
                [106.9184, 10.6100519],
                [106.9194422, 10.6101745],
                [106.9205073, 10.6101196],
                [106.9212549, 10.60997],
                [106.9220334, 10.6095462],
                [106.9225992, 10.6089466],
                [106.923535, 10.6076838],
                [106.9247097, 10.6062159],
                [106.9259143, 10.6044741],
                [106.9269131, 10.6036894],
                [106.9280846, 10.6031088],
                [106.9294287, 10.6027664],
                [106.9312713, 10.6022815],
                [106.9321515, 10.6019793],
                [106.9333229, 10.6013987],
                [106.9346558, 10.6006845],
                [106.9351997, 10.6003821],
                [106.9355217, 10.600203],
                [106.9358959, 10.6001845],
                [106.936433, 10.6002134],
                [106.9371973, 10.6001282],
                [106.9378418, 10.5999071],
                [106.9386019, 10.5993595],
                [106.939077, 10.5986322],
                [106.9394901, 10.5981207],
                [106.9400409, 10.5975069],
                [106.9405574, 10.5970635],
                [106.9408675, 10.5968927],
                [106.9413844, 10.5967216],
                [106.9421773, 10.5966184],
                [106.9427979, 10.5966517],
                [106.9438925, 10.5967857],
                [106.9449192, 10.5970373],
                [106.9460114, 10.5973527],
                [106.9466067, 10.5973811],
                [106.9473843, 10.597156],
                [106.9484245, 10.5966608],
                [106.9498601, 10.596027],
                [106.9537565, 10.5946652],
                [106.9553752, 10.5942063],
                [106.95791, 10.593824],
                [106.9591627, 10.5938001],
                [106.9597807, 10.5937479],
                [106.9605119, 10.5935822],
                [106.9611932, 10.5932718],
                [106.9620508, 10.5925251],
                [106.9630801, 10.5916386],
                [106.9636957, 10.5912158],
                [106.9643287, 10.5909701],
                [106.9650763, 10.5908526],
                [106.9658728, 10.5907187],
                [106.9666688, 10.5905204],
                [106.9676343, 10.5900994],
                [106.9680718, 10.5898066],
                [106.9687519, 10.5893028],
                [106.9694637, 10.5886699],
                [106.9702223, 10.5877144],
                [106.9720572, 10.5854544],
                [106.973349, 10.5839155],
                [106.9746033, 10.5825957],
                [106.9749271, 10.5823359],
                [106.9752676, 10.5821565],
                [106.9757061, 10.5820409],
                [106.976292, 10.5820695],
                [106.9766989, 10.5820991],
                [106.9773504, 10.5822079],
                [106.9778224, 10.5822371],
                [106.9784405, 10.582201],
                [106.9789932, 10.5821331],
                [106.9796263, 10.5819196],
                [106.9804859, 10.5814791],
                [106.9819033, 10.5806056],
                [106.9826966, 10.5799883],
                [106.9832467, 10.5795013],
                [106.9835542, 10.5792577],
                [106.9837328, 10.5792082],
                [106.9837336, 10.5792092],
                [106.9838641, 10.5793685],
                [106.9840947, 10.5798181],
                [106.9850002, 10.5814723],
                [106.9858388, 10.5833465],
                [106.9863868, 10.5850511],
                [106.9870643, 10.5870689],
                [106.987199, 10.587777],
                [106.9871892, 10.5887602],
                [106.9870997, 10.5900411],
                [106.9868485, 10.5914446],
                [106.986361, 10.5939229],
                [106.9862208, 10.5948907],
                [106.9862101, 10.5957231],
                [106.9863267, 10.5961251],
                [106.986606, 10.5965585],
                [106.9871145, 10.5971998],
                [106.9883231, 10.5982489],
                [106.9895668, 10.5993529],
                [106.9901027, 10.5998733],
                [106.9903814, 10.6002099],
                [106.9905627, 10.6005472],
                [106.9906464, 10.6009172],
                [106.9907308, 10.601384],
                [106.9907499, 10.601819],
                [106.9906869, 10.6021578],
                [106.9905114, 10.6026746],
                [106.9903196, 10.6032076],
                [106.9901955, 10.6041376],
                [106.9902792, 10.6044916],
                [106.9903366, 10.6046794],
                [106.9907667, 10.6051994],
                [106.9913481, 10.605546],
                [106.9913783, 10.6055535],
                [106.9920265, 10.605717],
                [106.9929152, 10.6057225],
                [106.9941641, 10.6055919],
                [106.9951279, 10.6053629],
                [106.995586, 10.6053463],
                [106.9959167, 10.6054216],
                [106.9962237, 10.6056106],
                [106.996499, 10.6058795],
                [106.9967115, 10.606321],
                [106.9970537, 10.607054],
                [106.9972958, 10.6073604],
                [106.9976256, 10.6075983],
                [106.9981438, 10.6077893],
                [106.998644, 10.6078117],
                [106.9990204, 10.6077064],
                [106.9993417, 10.6075647],
                [106.9998081, 10.607281],
                [106.9999845, 10.6071528],
                [107.0002961, 10.6069837],
                [107.0004197, 10.6069719],
                [107.0005059, 10.6070001],
                [107.000607, 10.6071119],
                [107.0007255, 10.6072568],
                [107.0008936, 10.6075277],
                [107.0010416, 10.6077113],
                [107.0013074, 10.6079428],
                [107.0016811, 10.6081929],
                [107.0021871, 10.6084907],
                [107.0023288, 10.6085962],
                [107.0024645, 10.6087938],
                [107.0025232, 10.6089839],
                [107.0025121, 10.6091597],
                [107.0024421, 10.609468],
                [107.0021781, 10.6101225],
                [107.0021205, 10.6102879],
                [107.002102, 10.6104337],
                [107.0021126, 10.61056],
                [107.0021329, 10.610686],
                [107.0022063, 10.6108245],
                [107.0023636, 10.6109303],
                [107.0025715, 10.6110157],
                [107.0027911, 10.6110412],
                [107.0030034, 10.6110529],
                [107.00329, 10.6109797],
                [107.0036269, 10.6108498],
                [107.0048659, 10.6102206],
                [107.0053748, 10.6099712],
                [107.0057363, 10.6098816],
                [107.0059494, 10.6098554],
                [107.0061942, 10.6098732],
                [107.0064605, 10.6099432],
                [107.0067344, 10.6100437],
                [107.0068303, 10.6101227],
                [107.0069439, 10.610225],
                [107.0070344, 10.6103618],
                [107.0071184, 10.610515],
                [107.0082867, 10.6130075],
                [107.001964, 10.6179383],
                [106.9991304, 10.6204087],
                [106.9975855, 10.6231715],
                [106.9972422, 10.6249641],
                [106.9968345, 10.6272207],
                [106.997049, 10.6297725],
                [106.9979717, 10.6321978],
                [106.9987656, 10.6342013],
                [107.0009543, 10.636015],
                [107.0026066, 10.6371327],
                [107.0040228, 10.6371959],
                [107.0065977, 10.6371538],
                [107.0073487, 10.6370272],
                [107.007971, 10.6367742],
                [107.0085933, 10.6365844],
                [107.0093354, 10.6361907],
                [107.0108951, 10.6352102],
                [107.013109, 10.6332065],
                [107.0142529, 10.6322529],
                [107.0150208, 10.6318148],
                [107.0157281, 10.6314705],
                [107.0164367, 10.6313204],
                [107.017, 10.6314138],
                [107.0175147, 10.6315562],
                [107.0177852, 10.631773],
                [107.0179587, 10.6321359],
                [107.01806, 10.6326693],
                [107.0180622, 10.6330091],
                [107.0175102, 10.6347118],
                [107.0159696, 10.6387026],
                [107.0139274, 10.6406386],
                [107.0139195, 10.6436473],
                [107.014657, 10.6440935],
                [107.0169499, 10.6449566],
                [107.0178443, 10.6451883],
                [107.0183322, 10.6451883],
                [107.0187387, 10.6453322],
                [107.0190233, 10.645508],
                [107.0191534, 10.6456438],
                [107.0192265, 10.6458743],
                [107.0192439, 10.6461712],
                [107.0192207, 10.6464852],
                [107.0192562, 10.6467272],
                [107.019371, 10.6469287],
                [107.0195514, 10.6470999],
                [107.0199239, 10.6473107],
                [107.0201526, 10.6473983],
                [107.0203759, 10.6474067],
                [107.0205953, 10.6473528],
                [107.0208177, 10.6472273],
                [107.0209852, 10.6470354],
                [107.0212337, 10.6466889],
                [107.0215096, 10.6468008],
                [107.0261928, 10.6490567],
                [107.0289125, 10.6503651],
                [107.0324218, 10.6520608],
                [107.0349945, 10.6532659],
                [107.0377243, 10.6545528],
                [107.0386514, 10.6550067],
                [107.0383116, 10.6557851],
                [107.0383733, 10.6558062],
                [107.0391765, 10.6559551],
                [107.0396258, 10.656036],
                [107.0398569, 10.6560784],
                [107.0401588, 10.656142],
                [107.0403957, 10.6561947],
                [107.0409715, 10.6563542],
                [107.0411232, 10.6563963],
                [107.0415856, 10.6565417],
                [107.0419056, 10.6566422],
                [107.0419721, 10.6566631],
                [107.0423315, 10.6567554],
                [107.0428331, 10.6569346],
                [107.0429889, 10.6569838],
                [107.0433427, 10.6570954],
                [107.043617, 10.6571867],
                [107.0440195, 10.6573207],
                [107.0441742, 10.6573722],
                [107.0444585, 10.6574539],
                [107.0446758, 10.6574855],
                [107.0449919, 10.6575229],
                [107.0450286, 10.6575272],
                [107.0451666, 10.6575435],
                [107.0461561, 10.6576742],
                [107.046263, 10.6576878],
                [107.0467479, 10.6577496],
                [107.0468269, 10.6577597],
                [107.0470196, 10.657781],
                [107.0472114, 10.6577873],
                [107.0473042, 10.657794],
                [107.0476416, 10.6577926],
                [107.0479481, 10.6577913],
                [107.0480588, 10.6577923],
                [107.0482129, 10.6577937],
                [107.0485066, 10.6577964],
                [107.0485274, 10.6577966],
                [107.0490281, 10.657807],
                [107.0494152, 10.6578414],
                [107.0494813, 10.65785],
                [107.0495327, 10.6578567],
                [107.0498793, 10.657902],
                [107.0503401, 10.6579602],
                [107.0505257, 10.6579837],
                [107.0510688, 10.6580518],
                [107.0512821, 10.6580786],
                [107.0515922, 10.6581096],
                [107.0516859, 10.658119],
                [107.0517568, 10.6581261],
                [107.052064, 10.6581387],
                [107.0524586, 10.6581433],
                [107.052664, 10.6581318],
                [107.0528952, 10.6581341],
                [107.0534066, 10.6581662],
                [107.0534423, 10.6581681],
                [107.0534826, 10.6581702],
                [107.054023, 10.6581984],
                [107.0543686, 10.6582144],
                [107.0545601, 10.6582007],
                [107.0547258, 10.6581708],
                [107.054915, 10.6581249],
                [107.0550159, 10.6580949],
                [107.0551928, 10.6580423],
                [107.055438, 10.6579437],
                [107.0556598, 10.657861],
                [107.055906, 10.6577648],
                [107.0560868, 10.6576941],
                [107.0561175, 10.6576821],
                [107.057143, 10.6572122],
                [107.0571893, 10.657191],
                [107.0574897, 10.6570671],
                [107.0577543, 10.6569753],
                [107.0579318, 10.6569363],
                [107.0581046, 10.6569041],
                [107.0582634, 10.6568904],
                [107.0584222, 10.6568973],
                [107.0586276, 10.6569156],
                [107.0588121, 10.65695],
                [107.0588363, 10.6569534],
                [107.0590549, 10.6569845],
                [107.0593071, 10.6570327],
                [107.0596037, 10.6570854],
                [107.0598558, 10.6571336],
                [107.0603509, 10.6572116],
                [107.0605359, 10.6572445],
                [107.0610615, 10.657338],
                [107.0615034, 10.6574124],
                [107.0615771, 10.6574249],
                [107.0616926, 10.6574445],
                [107.0618537, 10.6574697],
                [107.0619798, 10.6574835],
                [107.0620368, 10.6574844],
                [107.0621199, 10.6574858],
                [107.0622343, 10.6574812],
                [107.0624377, 10.6574652],
                [107.0625495, 10.6574514],
                [107.0626561, 10.6574366],
                [107.0630212, 10.6573917],
                [107.0632477, 10.6573504],
                [107.0633948, 10.6573137],
                [107.063661, 10.6572426],
                [107.0638992, 10.6571645],
                [107.0641, 10.6571026],
                [107.0642569, 10.6570555],
                [107.0643947, 10.657005],
                [107.0644811, 10.656966],
                [107.0645791, 10.6569063],
                [107.0646562, 10.6568283],
                [107.0647332, 10.656709],
                [107.0648266, 10.6565575],
                [107.0649294, 10.6563533],
                [107.0650438, 10.656133],
                [107.0651559, 10.6558714],
                [107.0652237, 10.6556746],
                [107.0653069, 10.6554505],
                [107.0653808, 10.6552083],
                [107.0654455, 10.6549781],
                [107.0654979, 10.6548116],
                [107.0655503, 10.6546602],
                [107.065615, 10.6545209],
                [107.0656797, 10.654421],
                [107.0657814, 10.6542757],
                [107.0659447, 10.6541031],
                [107.0661111, 10.6539244],
                [107.0662497, 10.6538124],
                [107.0664161, 10.6537004],
                [107.0665855, 10.6536065],
                [107.0667334, 10.653555],
                [107.0668936, 10.6535217],
                [107.0670415, 10.6535156],
                [107.0674415, 10.6535196],
                [107.0679052, 10.6535335],
                [107.0684997, 10.6535089],
                [107.0692727, 10.653505],
                [107.0694922, 10.6534868],
                [107.0701873, 10.653523],
                [107.0705821, 10.6535583],
                [107.0713017, 10.6536542],
                [107.0716719, 10.6536994],
                [107.0719887, 10.6537381],
                [107.0726026, 10.653794],
                [107.0728587, 10.653814],
                [107.0730823, 10.653814],
                [107.0733791, 10.653782],
                [107.0736312, 10.6537621],
                [107.0738792, 10.6537581],
                [107.0741394, 10.653774],
                [107.0745215, 10.65381],
                [107.0747533, 10.653818],
                [107.0749403, 10.653818],
                [107.075237, 10.653774],
                [107.0755948, 10.6537261],
                [107.0761802, 10.6536342],
                [107.0765136, 10.6535823],
                [107.076851, 10.6535263],
                [107.0772047, 10.6534664],
                [107.0778085, 10.6533899],
                [107.0779898, 10.653388],
                [107.0782875, 10.6534091],
                [107.0788347, 10.6534777],
                [107.0790455, 10.6534953],
                [107.0792451, 10.6535119],
                [107.0795302, 10.6535302],
                [107.0796555, 10.6535383],
                [107.0800256, 10.6535567],
                [107.0806586, 10.6535963],
                [107.0812004, 10.6536279],
                [107.0815437, 10.6536516],
                [107.0816204, 10.653651],
                [107.0818897, 10.653649],
                [107.0825925, 10.6536253],
                [107.0829852, 10.6536035],
                [107.0830189, 10.6536016],
                [107.0832533, 10.6535875],
                [107.0838504, 10.6535515],
                [107.0842984, 10.6535357],
                [107.0848884, 10.6535172],
                [107.0852854, 10.6534987],
                [107.0856609, 10.6534566],
                [107.0861035, 10.6534118],
                [107.086479, 10.6533643],
                [107.0873936, 10.6532431],
                [107.087914, 10.6532009],
                [107.0882251, 10.6531877],
                [107.0887374, 10.6532035],
                [107.0891451, 10.6532299],
                [107.0895555, 10.6532562],
                [107.0900839, 10.65328],
                [107.0904004, 10.6532879],
                [107.0905184, 10.65328],
                [107.0906311, 10.6532668],
                [107.0907652, 10.6532457],
                [107.090902, 10.6532299],
                [107.0910361, 10.6532167],
                [107.0910758, 10.653221],
                [107.0913177, 10.6532035],
                [107.0915376, 10.6532035],
                [107.0922431, 10.6531693],
                [107.0926239, 10.653164],
                [107.0935493, 10.6531218],
                [107.0936968, 10.653106],
                [107.0939624, 10.6530401],
                [107.0940965, 10.6529874],
                [107.0950165, 10.6525234],
                [107.095274, 10.6524154],
                [107.0955985, 10.6522915],
                [107.0957621, 10.6522387],
                [107.0960303, 10.6521465],
                [107.0963254, 10.652049],
                [107.096489, 10.6519593],
                [107.0966849, 10.6521339],
                [107.0969977, 10.6527489],
                [107.0972312, 10.6532583],
                [107.098297, 10.6554014],
                [107.099023, 10.6569205],
                [107.0992565, 10.657306],
                [107.0995274, 10.6575998],
                [107.1002746, 10.6582515],
                [107.1005239, 10.6585078],
                [107.1006967, 10.658755],
                [107.1007768, 10.6589306],
                [107.1010233, 10.6598208],
                [107.1010681, 10.6600605],
                [107.1011845, 10.660872],
                [107.1011223, 10.6609084],
                [107.1011102, 10.6618205],
                [107.10107, 10.6620577],
                [107.1011585, 10.6620972],
                [107.101196, 10.6623042],
                [107.1013999, 10.6627773],
                [107.1014248, 10.6628146],
                [107.1014938, 10.6628722],
                [107.1015619, 10.6628974],
                [107.1020007, 10.6629065],
                [107.1021206, 10.6630848],
                [107.1023601, 10.6632557],
                [107.1028402, 10.6636498],
                [107.1033472, 10.6638765],
                [107.1034449, 10.66391],
                [107.1035453, 10.6639347],
                [107.1037281, 10.6639561],
                [107.1038089, 10.6639562],
                [107.1039695, 10.6639397],
                [107.1041156, 10.6639349],
                [107.1045327, 10.663945],
                [107.1046668, 10.6639714],
                [107.1047661, 10.6640083],
                [107.1048336, 10.6640208],
                [107.1049101, 10.6640084],
                [107.1049491, 10.6639897],
                [107.105028, 10.6639254],
                [107.1050558, 10.6639148],
                [107.1051172, 10.6639139],
                [107.1051739, 10.6639425],
                [107.1052452, 10.6640294],
                [107.105326, 10.6641684],
                [107.105388, 10.6643185],
                [107.1054137, 10.6643509],
                [107.1054807, 10.6643995],
                [107.1055641, 10.664422],
                [107.1056091, 10.6644212],
                [107.1056945, 10.6643944],
                [107.1057679, 10.6643461],
                [107.1058492, 10.6643172],
                [107.1059355, 10.664314],
                [107.1059832, 10.6643237],
                [107.1060699, 10.6643663],
                [107.1061063, 10.6644012],
                [107.1061377, 10.6644504],
                [107.106217, 10.6646181],
                [107.1062441, 10.6646581],
                [107.1063337, 10.6647522],
                [107.1064398, 10.664828],
                [107.106508, 10.6648629],
                [107.1066542, 10.6649093],
                [107.1067303, 10.6649203],
                [107.1069816, 10.6649229],
                [107.1070646, 10.6649538],
                [107.1071233, 10.6650075],
                [107.1071559, 10.6650703],
                [107.1071944, 10.6653911],
                [107.1072096, 10.6654414],
                [107.1072577, 10.6655348],
                [107.107335, 10.6656212],
                [107.1073861, 10.6656589],
                [107.1075424, 10.6657324],
                [107.1076099, 10.6657934],
                [107.1076552, 10.6658787],
                [107.1076876, 10.6660209],
                [107.1077299, 10.6661091],
                [107.1078096, 10.6662046],
                [107.1078587, 10.6662435],
                [107.1079954, 10.6663252],
                [107.1080711, 10.666399],
                [107.1081344, 10.6664952],
                [107.1082087, 10.6666936],
                [107.1082491, 10.6667709],
                [107.1083528, 10.6669118],
                [107.1084836, 10.6670293],
                [107.1085574, 10.6670777],
                [107.1096504, 10.667883],
                [107.1097122, 10.6679499],
                [107.1098172, 10.668098],
                [107.1098967, 10.6682669],
                [107.1099254, 10.6683585],
                [107.1100286, 10.6688214],
                [107.1100705, 10.668901],
                [107.1101359, 10.6689637],
                [107.1102129, 10.6690015],
                [107.1102979, 10.6690152],
                [107.1104185, 10.669015],
                [107.1105049, 10.6690405],
                [107.1105905, 10.6690968],
                [107.1106911, 10.6692075],
                [107.1107648, 10.6692708],
                [107.1108372, 10.6693063],
                [107.110903, 10.6693182],
                [107.1109431, 10.6693415],
                [107.1109639, 10.6693803],
                [107.1109517, 10.6694977],
                [107.1109679, 10.669582],
                [107.1109892, 10.6696251],
                [107.1110183, 10.6696635],
                [107.1110986, 10.669717],
                [107.1111444, 10.6697334],
                [107.1112348, 10.6697432],
                [107.1114215, 10.669703],
                [107.1115137, 10.6697058],
                [107.111595, 10.6697281],
                [107.1117318, 10.6698072],
                [107.111791, 10.6698302],
                [107.1119084, 10.6698556],
                [107.1120216, 10.6698569],
                [107.1122108, 10.6698367],
                [107.1123444, 10.6698307],
                [107.1124781, 10.6698314],
                [107.1127572, 10.6698546],
                [107.1130446, 10.66991],
                [107.1131855, 10.6699495],
                [107.1144623, 10.6705294],
                [107.1154654, 10.6703739],
                [107.1154935, 10.6703756],
                [107.1155205, 10.6703837],
                [107.1155653, 10.6704166],
                [107.1155908, 10.6704676],
                [107.1155891, 10.6705261],
                [107.1155774, 10.6705533],
                [107.1155234, 10.6706218],
                [107.1155131, 10.6706487],
                [107.1155098, 10.6707082],
                [107.1155329, 10.670766],
                [107.1155534, 10.6707899],
                [107.1156076, 10.670822],
                [107.1158355, 10.6708984],
                [107.1158933, 10.670906],
                [107.1160094, 10.6709007],
                [107.1160662, 10.6708878],
                [107.1161643, 10.6708477],
                [107.1162513, 10.6707877],
                [107.1164975, 10.6705356],
                [107.1165459, 10.6705112],
                [107.1165987, 10.6705116],
                [107.1166441, 10.6705346],
                [107.116728, 10.6706047],
                [107.1167985, 10.6706506],
                [107.1168759, 10.6706922],
                [107.1170412, 10.6707639],
                [107.1172035, 10.670851],
                [107.1173643, 10.6709624],
                [107.1174647, 10.6710524],
                [107.1174846, 10.6710837],
                [107.1175066, 10.6711541],
                [107.1174993, 10.6712404],
                [107.1174663, 10.6713183],
                [107.1174582, 10.6713583],
                [107.1174583, 10.6713992],
                [107.1174805, 10.6714723],
                [107.1175285, 10.6715323],
                [107.1178104, 10.6716944],
                [107.1178794, 10.6717622],
                [107.1179062, 10.6718025],
                [107.1179412, 10.6718891],
                [107.1179733, 10.6721741],
                [107.1179887, 10.6722157],
                [107.1180378, 10.6722896],
                [107.1181074, 10.6723455],
                [107.1181865, 10.672377],
                [107.1182714, 10.6723849],
                [107.1185172, 10.67235],
                [107.1187216, 10.6723296],
                [107.1190974, 10.6723122],
                [107.1191996, 10.6722908],
                [107.1192821, 10.6722634],
                [107.1193185, 10.6722607],
                [107.1193873, 10.6722796],
                [107.1194396, 10.6723256],
                [107.1194762, 10.6724034],
                [107.1194891, 10.672453],
                [107.1194937, 10.6725916],
                [107.1195088, 10.6726631],
                [107.1195445, 10.6727284],
                [107.1196047, 10.6727979],
                [107.119655, 10.6728937],
                [107.1196733, 10.6729827],
                [107.1196657, 10.6731592],
                [107.1196713, 10.6731924],
                [107.1197059, 10.6732498],
                [107.1197616, 10.6732836],
                [107.1198243, 10.6732892],
                [107.1198549, 10.6732814],
                [107.119967, 10.6732076],
                [107.1200386, 10.6731995],
                [107.1200679, 10.6732059],
                [107.1201198, 10.6732349],
                [107.1201405, 10.6732562],
                [107.1201713, 10.6733119],
                [107.1202194, 10.6733599],
                [107.1202504, 10.6733749],
                [107.1203174, 10.673383],
                [107.1203806, 10.6733626],
                [107.1204073, 10.6733429],
                [107.1204689, 10.6732547],
                [107.1205321, 10.6731995],
                [107.1206067, 10.6731677],
                [107.1206469, 10.6731607],
                [107.1212107, 10.6731573],
                [107.12126, 10.6731732],
                [107.121347, 10.673228],
                [107.1214069, 10.6733011],
                [107.1214394, 10.673381],
                [107.1214467, 10.6734235],
                [107.1214476, 10.67364],
                [107.1214548, 10.6736711],
                [107.1214916, 10.6737311],
                [107.1215913, 10.6738044],
                [107.1216164, 10.6738387],
                [107.1216333, 10.6738775],
                [107.1216412, 10.673919],
                [107.1216298, 10.6740004],
                [107.1215864, 10.6740686],
                [107.1215185, 10.6741135],
                [107.1213912, 10.674135],
                [107.1213697, 10.6741441],
                [107.1213394, 10.6741787],
                [107.1213359, 10.6742301],
                [107.1213663, 10.6742722],
                [107.121495, 10.6743671],
                [107.1215379, 10.6744277],
                [107.1216145, 10.6746082],
                [107.1217164, 10.6747757],
                [107.1217847, 10.6748626],
                [107.1220905, 10.6751842],
                [107.1222085, 10.6753424],
                [107.1223426, 10.6755532],
                [107.1225518, 10.6759222],
                [107.1227449, 10.6762122],
                [107.1227735, 10.6762638],
                [107.1228093, 10.6763756],
                [107.1228152, 10.6764873],
                [107.1227816, 10.6766677],
                [107.1227769, 10.6767391],
                [107.1227878, 10.6768817],
                [107.1228049, 10.6769567],
                [107.1228295, 10.6770296],
                [107.1229005, 10.6771663],
                [107.123011, 10.6773011],
                [107.1230313, 10.6773378],
                [107.123048, 10.6774194],
                [107.123044, 10.6774593],
                [107.1230134, 10.6775333],
                [107.1229256, 10.6776237],
                [107.1228821, 10.6777025],
                [107.1228675, 10.6777941],
                [107.1228728, 10.6778418],
                [107.1229407, 10.6780207],
                [107.1229917, 10.6782048],
                [107.1230251, 10.6783975],
                [107.1230434, 10.6785542],
                [107.1230762, 10.6786091],
                [107.1231198, 10.678658],
                [107.1231365, 10.6787188],
                [107.1231339, 10.6787528],
                [107.123105, 10.6788142],
                [107.1229972, 10.6789042],
                [107.1229509, 10.6789592],
                [107.1229127, 10.6790199],
                [107.1228831, 10.6790851],
                [107.1228629, 10.6791537],
                [107.1228524, 10.6792237],
                [107.1228516, 10.6792945],
                [107.1228606, 10.6793647],
                [107.1228903, 10.6794771],
                [107.1229097, 10.6795183],
                [107.1229698, 10.6795868],
                [107.1230511, 10.6796292],
                [107.1231407, 10.6796399],
                [107.1232268, 10.6796191],
                [107.1232656, 10.6795978],
                [107.1233288, 10.6795368],
                [107.1233801, 10.6794476],
                [107.1234293, 10.6794143],
                [107.1234914, 10.6794061],
                [107.1235235, 10.6794137],
                [107.1235771, 10.6794507],
                [107.1236098, 10.6795115],
                [107.1236188, 10.6795469],
                [107.1236189, 10.6796222],
                [107.123593, 10.6796958],
                [107.1235387, 10.6797617],
                [107.1235308, 10.6797836],
                [107.1235337, 10.6798267],
                [107.1235583, 10.6798624],
                [107.1235979, 10.6798812],
                [107.1237939, 10.6798862],
                [107.1238282, 10.6799115],
                [107.1238366, 10.6799312],
                [107.1238315, 10.6799716],
                [107.1237799, 10.6800287],
                [107.1237588, 10.6800973],
                [107.1237599, 10.6801287],
                [107.1237807, 10.6801878],
                [107.1238232, 10.6802343],
                [107.1241076, 10.6804637],
                [107.1241515, 10.6805377],
                [107.1241611, 10.6805796],
                [107.1241563, 10.6806568],
                [107.1241083, 10.6807569],
                [107.1240967, 10.6808221],
                [107.1241007, 10.6808542],
                [107.1241267, 10.6809133],
                [107.1241652, 10.6809541],
                [107.1241864, 10.6809651],
                [107.1242335, 10.6809697],
                [107.1242589, 10.6809619],
                [107.1242978, 10.6809273],
                [107.1243197, 10.6808553],
                [107.1243328, 10.6808354],
                [107.1243703, 10.6808063],
                [107.1244264, 10.6807958],
                [107.1244546, 10.6808015],
                [107.1245196, 10.6808324],
                [107.1245548, 10.6808572],
                [107.12461, 10.6809258],
                [107.1246359, 10.6810119],
                [107.1246241, 10.681077],
                [107.1245884, 10.6811332],
                [107.124563, 10.6811553],
                [107.1244765, 10.6811945],
                [107.1244382, 10.6812336],
                [107.124424, 10.681286],
                [107.1244267, 10.6813109],
                [107.1244487, 10.6813555],
                [107.1244669, 10.681373],
                [107.1245265, 10.6813875],
                [107.1245876, 10.6813941],
                [107.1246786, 10.6813902],
                [107.1247418, 10.6813704],
                [107.1248154, 10.6813169],
                [107.1248538, 10.6813188],
                [107.1248711, 10.6813282],
                [107.1249164, 10.6813881],
                [107.1249711, 10.6814362],
                [107.1250493, 10.6814767],
                [107.1251335, 10.6815009],
                [107.1252082, 10.6815436],
                [107.1252394, 10.6815733],
                [107.1252821, 10.6816384],
                [107.1253037, 10.681713],
                [107.1253075, 10.6817631],
                [107.1253037, 10.6818132],
                [107.125273, 10.6818565],
                [107.1252233, 10.6818764],
                [107.1251762, 10.6818691],
                [107.1251556, 10.681857],
                [107.1251056, 10.6818034],
                [107.1250537, 10.6817917],
                [107.125004, 10.6818103],
                [107.1249731, 10.681853],
                [107.1249706, 10.6819426],
                [107.1249792, 10.6820056],
                [107.1250275, 10.6821901],
                [107.1250476, 10.682453],
                [107.12558, 10.6823535],
                [107.1260038, 10.6822823],
                [107.1262443, 10.682318],
                [107.1265376, 10.6824615],
                [107.1268272, 10.682572],
                [107.1272608, 10.6825985],
                [107.1277177, 10.6826803],
                [107.1280485, 10.682764],
                [107.1285391, 10.6830048],
                [107.1290152, 10.6832594],
                [107.129252, 10.6833919],
                [107.1292928, 10.6834586],
                [107.1293485, 10.6835896],
                [107.1294877, 10.6836923],
                [107.1295631, 10.6837161],
                [107.1296774, 10.6837445],
                [107.1297616, 10.6837188],
                [107.1299352, 10.6837105],
                [107.130062, 10.6836634],
                [107.1303517, 10.6835764],
                [107.1305126, 10.6835026],
                [107.1307272, 10.6834051],
                [107.1308452, 10.6833735],
                [107.1310088, 10.6834157],
                [107.1313199, 10.6835791],
                [107.1316123, 10.6837847],
                [107.1317581, 10.6838975],
                [107.1318446, 10.6839147],
                [107.1319181, 10.6839323],
                [107.1320924, 10.6839375],
                [107.1323821, 10.6839349],
                [107.1325323, 10.6839929],
                [107.1328405, 10.6841783],
                [107.1331036, 10.6843777],
                [107.1331841, 10.6845517],
                [107.1332842, 10.6847473],
                [107.1334142, 10.6848328],
                [107.1338375, 10.6850708],
                [107.133832, 10.6853524],
                [107.1338383, 10.6855855],
                [107.1338435, 10.6860658],
                [107.1338885, 10.6865722],
                [107.1339153, 10.6877893],
                [107.1339311, 10.6880202],
                [107.1337785, 10.6896043],
                [107.1337717, 10.6897339],
                [107.1337743, 10.6898637],
                [107.1338028, 10.6900974],
                [107.133854, 10.6903024],
                [107.1338885, 10.6904024],
                [107.1341094, 10.6909327],
                [107.1341493, 10.69106],
                [107.1343007, 10.6922397],
                [107.134343, 10.6925047],
                [107.1344421, 10.6929834],
                [107.1344634, 10.6931949],
                [107.1344474, 10.6936058],
                [107.1344072, 10.6939511],
                [107.1343374, 10.6942707],
                [107.1342047, 10.6946871],
                [107.1341965, 10.6947284],
                [107.1341946, 10.6948123],
                [107.1342181, 10.6949124],
                [107.1347618, 10.6958432],
                [107.1349049, 10.6961275],
                [107.1352065, 10.6968417],
                [107.1357018, 10.6979644],
                [107.1361533, 10.6991439],
                [107.1362651, 10.6994048],
                [107.1364084, 10.6996779],
                [107.1368685, 10.7004011],
                [107.1368797, 10.7005216],
                [107.1368295, 10.7009216],
                [107.1368544, 10.7014407],
                [107.1368574, 10.7017599],
                [107.1369243, 10.7025872],
                [107.1370288, 10.7033567],
                [107.1370664, 10.7034754],
                [107.1371753, 10.7040246],
                [107.1374974, 10.7049549],
                [107.1376648, 10.705303],
                [107.1378022, 10.7056657],
                [107.1378123, 10.7062905],
                [107.1378063, 10.70641],
                [107.137778, 10.7066253],
                [107.1377318, 10.7068147],
                [107.1376908, 10.7069351],
                [107.1375841, 10.7071662],
                [107.1373194, 10.7075444],
                [107.1372892, 10.7075996],
                [107.1372476, 10.707718],
                [107.1372357, 10.7077899],
                [107.137233, 10.7078628],
                [107.1372758, 10.7081759],
                [107.1374689, 10.7081627],
                [107.1376798, 10.7081113],
                [107.137831, 10.7080995],
                [107.1378556, 10.7081063],
                [107.13789, 10.7081375],
                [107.1379235, 10.7082537],
                [107.1379557, 10.7082774],
                [107.1379745, 10.7082803],
                [107.1383319, 10.7082311],
                [107.1383673, 10.7082356],
                [107.1384332, 10.7082616],
                [107.1384843, 10.7083048],
                [107.138519, 10.7083617],
                [107.138747, 10.7089257],
                [107.1389669, 10.7089293],
                [107.1391947, 10.7094566],
                [107.1392433, 10.7095375],
                [107.1392764, 10.7095716],
                [107.139353, 10.7096218],
                [107.1393944, 10.7096377],
                [107.1394819, 10.7096518],
                [107.1397673, 10.7096234],
                [107.139648, 10.7142057],
                [107.1395463, 10.7181893],
                [107.1400934, 10.718416],
                [107.1405635, 10.7185589],
                [107.1406073, 10.7185829],
                [107.140646, 10.7186143],
                [107.1406783, 10.7186521],
                [107.140703, 10.718695],
                [107.1407194, 10.7187418],
                [107.1407269, 10.7187908],
                [107.1407251, 10.7188403],
                [107.1406782, 10.7194372],
                [107.1406748, 10.7194819],
                [107.1406649, 10.7195257],
                [107.1406487, 10.7195676],
                [107.1406312, 10.7195994],
                [107.1406101, 10.719629],
                [107.1405856, 10.7196559],
                [107.1403757, 10.7197946],
                [107.1403224, 10.7198268],
                [107.1402655, 10.7198524],
                [107.1402059, 10.7198711],
                [107.1401444, 10.7198826],
                [107.140079, 10.7198867],
                [107.1400136, 10.7198826],
                [107.1399878, 10.7198815],
                [107.1399622, 10.7198855],
                [107.1399378, 10.7198943],
                [107.1399157, 10.7199076],
                [107.1398959, 10.7199258],
                [107.1398802, 10.7199477],
                [107.1398695, 10.7199722],
                [107.1398252, 10.7200842],
                [107.1397803, 10.7201512],
                [107.1397287, 10.7202133],
                [107.1396791, 10.7202627],
                [107.1396253, 10.7203076],
                [107.1395677, 10.7203477],
                [107.1393357, 10.7204848],
                [107.139219, 10.7278796],
                [107.1393864, 10.7278726],
                [107.1394685, 10.7278559],
                [107.1395899, 10.7278133],
                [107.1396475, 10.7277847],
                [107.1405331, 10.7271664],
                [107.1405764, 10.7271504],
                [107.1406654, 10.7271403],
                [107.1409363, 10.727202],
                [107.1410064, 10.7271893],
                [107.1410646, 10.727149],
                [107.141101, 10.7270865],
                [107.1411063, 10.7270126],
                [107.141096, 10.7269767],
                [107.1410262, 10.7268503],
                [107.1410108, 10.7267991],
                [107.141002, 10.7266972],
                [107.1410778, 10.7261772],
                [107.1418905, 10.7253839],
                [107.1419696, 10.7251915],
                [107.1420273, 10.7251599],
                [107.1420729, 10.7249913],
                [107.1420917, 10.7248845],
                [107.1421067, 10.7242581],
                [107.1421266, 10.7242165],
                [107.1421689, 10.7241827],
                [107.142195, 10.7241743],
                [107.1424162, 10.7241558],
                [107.1424065, 10.7238675],
                [107.1424216, 10.7238317],
                [107.1424533, 10.7238051],
                [107.142494, 10.7237961],
                [107.1434154, 10.7237922],
                [107.1434622, 10.7238088],
                [107.1434811, 10.7238251],
                [107.1435034, 10.7238664],
                [107.1435213, 10.7240004],
                [107.1435336, 10.7240249],
                [107.1435723, 10.7240636],
                [107.1436211, 10.7240827],
                [107.1438719, 10.7241014],
                [107.1440068, 10.7241348],
                [107.1440844, 10.7241647],
                [107.1446018, 10.724455],
                [107.1447819, 10.7245696],
                [107.1451012, 10.7247966],
                [107.1454042, 10.725044],
                [107.145435, 10.7250845],
                [107.1454847, 10.7251731],
                [107.1455067, 10.7252313],
                [107.1455301, 10.725353],
                [107.1455035, 10.7263392],
                [107.145492, 10.7263786],
                [107.145465, 10.7264115],
                [107.1453824, 10.7264488],
                [107.1453456, 10.726485],
                [107.1453328, 10.7265105],
                [107.145277, 10.7267272],
                [107.1452381, 10.7267789],
                [107.1451923, 10.726807],
                [107.1450208, 10.7268761],
                [107.1448968, 10.726949],
                [107.1447989, 10.7270349],
                [107.1447256, 10.7271259],
                [107.1446416, 10.727336],
                [107.144617, 10.7273697],
                [107.1445754, 10.7273871],
                [107.1444774, 10.7273871],
                [107.1444482, 10.7274084],
                [107.1444363, 10.7274428],
                [107.1444306, 10.7276398],
                [107.144389, 10.7277109],
                [107.1443685, 10.7277954],
                [107.1443291, 10.7278519],
                [107.1442401, 10.7279099],
                [107.1441873, 10.7279358],
                [107.144132, 10.7279557],
                [107.1439797, 10.7279854],
                [107.143945, 10.7279991],
                [107.1438847, 10.728041],
                [107.1438405, 10.7280983],
                [107.1434375, 10.7280884],
                [107.1434216, 10.729047],
                [107.1434516, 10.7291274],
                [107.1435126, 10.7291956],
                [107.1445633, 10.7299424],
                [107.1449026, 10.7301051],
                [107.145423, 10.7298679],
                [107.1463028, 10.729839],
                [107.1462893, 10.7310696],
                [107.1451521, 10.7316362],
                [107.1447887, 10.7318352],
                [107.1447846, 10.7319169],
                [107.1441972, 10.7322173],
                [107.1441436, 10.732639],
                [107.1390292, 10.7325411],
                [107.1388362, 10.7373386],
                [107.1389044, 10.7397513],
                [107.1384305, 10.7450088],
                [107.1470296, 10.7445186],
                [107.1471274, 10.7445099],
                [107.1472254, 10.744507],
                [107.1473235, 10.7445099],
                [107.1474212, 10.7445186],
                [107.1475241, 10.7445341],
                [107.1476257, 10.744556],
                [107.1477256, 10.7445843],
                [107.1478236, 10.7446187],
                [107.1479288, 10.7446482],
                [107.1480357, 10.7446712],
                [107.1481438, 10.7446878],
                [107.1482527, 10.7446978],
                [107.1483823, 10.7447011],
                [107.1485118, 10.7446952],
                [107.1486405, 10.74468],
                [107.1487677, 10.7446556],
                [107.1507767, 10.7442867],
                [107.1513185, 10.7441391],
                [107.1513758, 10.7441168],
                [107.1514359, 10.7441033],
                [107.1514975, 10.744099],
                [107.1515589, 10.744104],
                [107.1516189, 10.7441181],
                [107.1516741, 10.7441401],
                [107.1517254, 10.7441698],
                [107.1517717, 10.7442067],
                [107.151812, 10.7442498],
                [107.1519729, 10.7444764],
                [107.1519928, 10.7445102],
                [107.1520182, 10.7445402],
                [107.1520484, 10.7445655],
                [107.1520826, 10.7445855],
                [107.1521196, 10.7445995],
                [107.1521586, 10.7446071],
                [107.1521982, 10.7446082],
                [107.1522377, 10.7446026],
                [107.1522756, 10.7445906],
                [107.1523109, 10.7445724],
                [107.1523426, 10.7445486],
                [107.1523697, 10.7445198],
                [107.1523914, 10.744487],
                [107.1524397, 10.744371],
                [107.1524472, 10.7443459],
                [107.1524619, 10.744324],
                [107.1524824, 10.7443073],
                [107.152507, 10.7442972],
                [107.1525335, 10.7442946],
                [107.1525594, 10.7442996],
                [107.1525826, 10.7443117],
                [107.1526014, 10.7443299],
                [107.152614, 10.7443526],
                [107.1526757, 10.7444896],
                [107.1526981, 10.7445174],
                [107.1527267, 10.7445389],
                [107.1527598, 10.744553],
                [107.1527953, 10.7445586],
                [107.1528313, 10.7445555],
                [107.152867, 10.7445431],
                [107.1528982, 10.744522],
                [107.1529229, 10.7444938],
                [107.1529394, 10.7444603],
                [107.1529466, 10.7444237],
                [107.1529536, 10.7443875],
                [107.1529681, 10.7443534],
                [107.1529893, 10.7443229],
                [107.1530163, 10.7442973],
                [107.153047, 10.7442781],
                [107.153081, 10.7442653],
                [107.1531168, 10.7442593],
                [107.1531531, 10.7442604],
                [107.1532128, 10.7442685],
                [107.153273, 10.7442694],
                [107.1533328, 10.744263],
                [107.1533857, 10.744251],
                [107.1534369, 10.7442334],
                [107.1534857, 10.7442103],
                [107.1536949, 10.7440812],
                [107.1537198, 10.7440639],
                [107.1537483, 10.7440532],
                [107.1537787, 10.7440499],
                [107.1538089, 10.744054],
                [107.1538371, 10.7440654],
                [107.1538615, 10.7440832],
                [107.1538806, 10.7441064],
                [107.1538933, 10.7441335],
                [107.1539364, 10.7443488],
                [107.1539466, 10.7443778],
                [107.1539636, 10.7444035],
                [107.1539863, 10.7444244],
                [107.1540135, 10.7444394],
                [107.1540436, 10.7444475],
                [107.1540727, 10.7444484],
                [107.1541012, 10.7444429],
                [107.1541278, 10.7444312],
                [107.154151, 10.744414],
                [107.1541697, 10.7443921],
                [107.1542501, 10.744263],
                [107.1542673, 10.7442439],
                [107.1542886, 10.7442296],
                [107.1543129, 10.7442208],
                [107.1543387, 10.7442182],
                [107.1543612, 10.7442211],
                [107.1543825, 10.7442287],
                [107.1544017, 10.7442406],
                [107.1545452, 10.7443895],
                [107.1546981, 10.7445476],
                [107.1547423, 10.7446024],
                [107.1547812, 10.7446609],
                [107.154815, 10.7447234],
                [107.1548429, 10.7447887],
                [107.1549287, 10.7450325],
                [107.1549556, 10.7450941],
                [107.1549878, 10.7451531],
                [107.1550253, 10.745209],
                [107.1550685, 10.7452624],
                [107.1551165, 10.7453118],
                [107.1551335, 10.7453297],
                [107.1551799, 10.7453454],
                [107.1552044, 10.7453416],
                [107.1552412, 10.7453158],
                [107.1552566, 10.7452782],
                [107.1552739, 10.7450347],
                [107.1553012, 10.7450079],
                [107.1553203, 10.7450035],
                [107.1553562, 10.745015],
                [107.1553753, 10.745047],
                [107.1554142, 10.7452275],
                [107.155439, 10.7452903],
                [107.1555032, 10.7454145],
                [107.1555626, 10.7455071],
                [107.1555881, 10.7455346],
                [107.1556186, 10.7455568],
                [107.1556881, 10.7455815],
                [107.1557602, 10.7455793],
                [107.1558769, 10.7455437],
                [107.1558998, 10.7455465],
                [107.1559386, 10.74557],
                [107.1559586, 10.7456142],
                [107.1559762, 10.7459967],
                [107.1559979, 10.7460884],
                [107.1560202, 10.7461303],
                [107.1560848, 10.7461999],
                [107.1561277, 10.746227],
                [107.1561749, 10.7462461],
                [107.1562248, 10.7462565],
                [107.1562758, 10.746258],
                [107.1563262, 10.7462504],
                [107.1564534, 10.7462069],
                [107.1564952, 10.7462025],
                [107.1565846, 10.7462147],
                [107.1567018, 10.7462662],
                [107.1567848, 10.7462689],
                [107.1568251, 10.7462578],
                [107.1568933, 10.7462145],
                [107.1569391, 10.7461486],
                [107.1570021, 10.7459073],
                [107.1570315, 10.7458699],
                [107.1570677, 10.7458388],
                [107.1571491, 10.7458012],
                [107.1572603, 10.7457911],
                [107.1573133, 10.7458054],
                [107.1573355, 10.7458217],
                [107.1573642, 10.7458678],
                [107.1573689, 10.7458943],
                [107.1573582, 10.7459467],
                [107.1573235, 10.7459877],
                [107.157273, 10.7460075],
                [107.157214, 10.746045],
                [107.1571711, 10.7460997],
                [107.1571486, 10.7461692],
                [107.1571706, 10.7464552],
                [107.1571799, 10.7468547],
                [107.157205, 10.7468976],
                [107.1572515, 10.7469166],
                [107.1572974, 10.7469056],
                [107.1573157, 10.7468906],
                [107.1573347, 10.7468481],
                [107.1574098, 10.7467269],
                [107.1574302, 10.7467118],
                [107.1574795, 10.7467005],
                [107.157502, 10.7467044],
                [107.1575407, 10.7467275],
                [107.1579623, 10.7474437],
                [107.1580057, 10.7475022],
                [107.158056, 10.7475552],
                [107.158155, 10.7476301],
                [107.1582466, 10.7476755],
                [107.1584451, 10.7477572],
                [107.1585477, 10.7478274],
                [107.1585906, 10.7478724],
                [107.1586501, 10.7479664],
                [107.1587486, 10.7482242],
                [107.158766, 10.7482477],
                [107.1588153, 10.748279],
                [107.1588808, 10.7482825],
                [107.1590808, 10.7481999],
                [107.1591363, 10.7481849],
                [107.1591935, 10.7481789],
                [107.1593046, 10.7481933],
                [107.1595716, 10.7483053],
                [107.1596732, 10.7483199],
                [107.1597245, 10.7483159],
                [107.1598095, 10.7482918],
                [107.1600196, 10.7481657],
                [107.1601045, 10.7481299],
                [107.1601966, 10.7481222],
                [107.1602426, 10.7481293],
                [107.1603282, 10.7481647],
                [107.1603656, 10.748192],
                [107.1607894, 10.7486189],
                [107.1608387, 10.7486961],
                [107.1608591, 10.7487849],
                [107.160858, 10.7488267],
                [107.1608362, 10.7489072],
                [107.1607905, 10.7489773],
                [107.1607599, 10.7490063],
                [107.160693, 10.7490513],
                [107.1606684, 10.7490832],
                [107.1606422, 10.7491586],
                [107.1606418, 10.7491987],
                [107.1606647, 10.749271],
                [107.1606868, 10.7493023],
                [107.1607477, 10.7493488],
                [107.1608402, 10.7493769],
                [107.1609558, 10.7493875],
                [107.1612829, 10.7493541],
                [107.1613318, 10.7493562],
                [107.1613794, 10.7493673],
                [107.161424, 10.7493871],
                [107.1614926, 10.749443],
                [107.1615164, 10.7494754],
                [107.1615688, 10.7495895],
                [107.1616276, 10.7496598],
                [107.1616724, 10.7496946],
                [107.1617225, 10.7497216],
                [107.1617764, 10.7497402],
                [107.1621009, 10.7497983],
                [107.1622465, 10.7497943],
                [107.1623182, 10.749781],
                [107.1624589, 10.7497308],
                [107.1625851, 10.7496519],
                [107.1627358, 10.7495238],
                [107.1627913, 10.7495031],
                [107.1628211, 10.7495057],
                [107.1628704, 10.7495336],
                [107.1628962, 10.7495834],
                [107.162919, 10.7497336],
                [107.1629306, 10.7497585],
                [107.1629692, 10.7497977],
                [107.1630212, 10.7498165],
                [107.1630762, 10.7498111],
                [107.1631014, 10.7497995],
                [107.1634154, 10.7496059],
                [107.1634569, 10.7495915],
                [107.1635444, 10.7495881],
                [107.1635869, 10.7495992],
                [107.1636603, 10.7496443],
                [107.1636888, 10.7496766],
                [107.1637239, 10.7497544],
                [107.1638417, 10.7504952],
                [107.1638699, 10.7505711],
                [107.1639215, 10.7506341],
                [107.1639929, 10.7506782],
                [107.1640773, 10.750697],
                [107.164228, 10.7506954],
                [107.1643234, 10.75072],
                [107.164405, 10.7507745],
                [107.1644616, 10.7508492],
                [107.1644908, 10.7509379],
                [107.1645793, 10.751399],
                [107.1646839, 10.7518891],
                [107.1647079, 10.7519503],
                [107.1647402, 10.7520077],
                [107.1647803, 10.7520601],
                [107.1648754, 10.7521427],
                [107.1649831, 10.7521959],
                [107.1651875, 10.752244],
                [107.1652711, 10.7522756],
                [107.1653509, 10.7523158],
                [107.1654258, 10.7523639],
                [107.1654953, 10.7524195],
                [107.1655583, 10.752482],
                [107.1656121, 10.7525476],
                [107.1656981, 10.752693],
                [107.1657527, 10.7528524],
                [107.1657817, 10.7529961],
                [107.1658019, 10.7530553],
                [107.1658279, 10.7531122],
                [107.1658963, 10.7532172],
                [107.1659472, 10.7532735],
                [107.1660674, 10.7533664],
                [107.1662959, 10.7534807],
                [107.1663369, 10.7535093],
                [107.1663999, 10.7535856],
                [107.1664177, 10.7536244],
                [107.1664339, 10.7537076],
                [107.1664059, 10.7539024],
                [107.1664083, 10.7539802],
                [107.1664435, 10.7540499],
                [107.1665055, 10.7540992],
                [107.1665829, 10.7541184],
                [107.1668256, 10.754106],
                [107.1668967, 10.7540751],
                [107.1669263, 10.7540499],
                [107.1669668, 10.7539866],
                [107.166977, 10.7539504],
                [107.166981, 10.753419],
                [107.1669692, 10.7533174],
                [107.1669293, 10.753197],
                [107.1668994, 10.7531408],
                [107.1667144, 10.7529221],
                [107.1667066, 10.7528801],
                [107.1667125, 10.7528593],
                [107.1667412, 10.7528272],
                [107.166784, 10.7528167],
                [107.1668252, 10.7528322],
                [107.1669289, 10.7529379],
                [107.1669765, 10.7529703],
                [107.1670277, 10.7529968],
                [107.1671654, 10.7530456],
                [107.1672025, 10.7530697],
                [107.1672341, 10.7530982],
                [107.1672815, 10.753168],
                [107.1673045, 10.7532489],
                [107.1673363, 10.7533101],
                [107.1673876, 10.7533569],
                [107.1674334, 10.7533745],
                [107.1674815, 10.7533648],
                [107.1675016, 10.7533502],
                [107.1675254, 10.7533076],
                [107.1675271, 10.7532831],
                [107.167517, 10.7532193],
                [107.1674631, 10.7531168],
                [107.1674375, 10.7530503],
                [107.1674442, 10.7530107],
                [107.1674565, 10.7529944],
                [107.1674937, 10.7529767],
                [107.1675148, 10.7529774],
                [107.167551, 10.7529977],
                [107.1676066, 10.7531345],
                [107.1676344, 10.7531803],
                [107.1676772, 10.7532223],
                [107.1677336, 10.7532436],
                [107.1677714, 10.7532446],
                [107.1678081, 10.7532361],
                [107.1678415, 10.7532186],
                [107.1678691, 10.7531933],
                [107.1679693, 10.7530264],
                [107.1680652, 10.7529131],
                [107.1681907, 10.7528049],
                [107.1682405, 10.7527892],
                [107.1682915, 10.7528009],
                [107.1683125, 10.7528161],
                [107.168339, 10.7528598],
                [107.1683425, 10.7528852],
                [107.168321, 10.7531672],
                [107.168326, 10.753208],
                [107.1683532, 10.7532857],
                [107.1683756, 10.7533221],
                [107.1684353, 10.7533833],
                [107.1685599, 10.7534595],
                [107.1687469, 10.7535469],
                [107.168944, 10.7536094],
                [107.1693939, 10.7537232],
                [107.1694762, 10.7537574],
                [107.1695542, 10.7538001],
                [107.1696271, 10.7538509],
                [107.1696924, 10.7539075],
                [107.1697513, 10.7539706],
                [107.169803, 10.7540394],
                [107.1701074, 10.7545163],
                [107.1702307, 10.7546639],
                [107.1703485, 10.7547603],
                [107.1708798, 10.7550855],
                [107.1709578, 10.7551503],
                [107.1710169, 10.7552322],
                [107.1710527, 10.7553233],
                [107.1710649, 10.7554175],
                [107.1710703, 10.7555835],
                [107.1710897, 10.7556568],
                [107.1711387, 10.7557169],
                [107.1711723, 10.7557383],
                [107.1712097, 10.7557522],
                [107.1713197, 10.7557733],
                [107.171354, 10.7557924],
                [107.1714077, 10.7558486],
                [107.1714351, 10.7559208],
                [107.1714371, 10.7559632],
                [107.1714297, 10.756005],
                [107.1714134, 10.7560442],
                [107.1713887, 10.756079],
                [107.171357, 10.7561077],
                [107.1713197, 10.756129],
                [107.1710783, 10.756324],
                [107.1710363, 10.7564131],
                [107.1710247, 10.756461],
                [107.1710211, 10.7565563],
                [107.1710287, 10.7566035],
                [107.1710622, 10.7566929],
                [107.1715397, 10.7573728],
                [107.1715826, 10.7575045],
                [107.1716238, 10.7577121],
                [107.1716453, 10.7577755],
                [107.1717082, 10.7578937],
                [107.1717956, 10.7579966],
                [107.1718475, 10.7580407],
                [107.1719643, 10.7581113],
                [107.1725455, 10.7583109],
                [107.1726758, 10.7583755],
                [107.172798, 10.7584676],
                [107.1728546, 10.7585249],
                [107.1729571, 10.7586517],
                [107.1730739, 10.7587694],
                [107.1732134, 10.7588762],
                [107.1735486, 10.7590803],
                [107.1736256, 10.7591495],
                [107.1736957, 10.7592253],
                [107.1737585, 10.7593072],
                [107.1738133, 10.7593943],
                [107.1738598, 10.7594861],
                [107.1739211, 10.7596602],
                [107.1739523, 10.7598419],
                [107.1739751, 10.7604347],
                [107.1739945, 10.7605061],
                [107.1740396, 10.7605651],
                [107.1741039, 10.7606034],
                [107.174143, 10.7606132],
                [107.1742232, 10.7606087],
                [107.1742949, 10.7605731],
                [107.1743545, 10.7605128],
                [107.174432, 10.7604676],
                [107.174521, 10.7604543],
                [107.1746082, 10.7604746],
                [107.1746809, 10.7605249],
                [107.1747294, 10.7605982],
                [107.1747422, 10.7606402],
                [107.1747787, 10.760916],
                [107.1747921, 10.7609581],
                [107.1748139, 10.7609966],
                [107.1748433, 10.7610299],
                [107.1749167, 10.7610745],
                [107.1749989, 10.7610878],
                [107.1750404, 10.7610826],
                [107.175225, 10.7610329],
                [107.1752572, 10.761032],
                [107.1753189, 10.7610487],
                [107.17537, 10.7610881],
                [107.1755791, 10.7613833],
                [107.1756177, 10.761426],
                [107.1757081, 10.7614976],
                [107.1758226, 10.7615516],
                [107.1759579, 10.7615782],
                [107.1764991, 10.761602],
                [107.176591, 10.7616121],
                [107.1767605, 10.7616553],
                [107.1769944, 10.7617509],
                [107.1771616, 10.7617838],
                [107.1773294, 10.761784],
                [107.1774126, 10.7617721],
                [107.1774942, 10.7617522],
                [107.1776934, 10.7616735],
                [107.1777254, 10.7616711],
                [107.177757, 10.7616771],
                [107.1778082, 10.7617109],
                [107.1778855, 10.7618513],
                [107.1779273, 10.7618774],
                [107.1779558, 10.7618835],
                [107.1780127, 10.7618739],
                [107.1780375, 10.7618587],
                [107.1782151, 10.7616689],
                [107.178244, 10.7616495],
                [107.1783113, 10.7616325],
                [107.1783462, 10.7616358],
                [107.178408, 10.7616643],
                [107.1784503, 10.7617156],
                [107.1784659, 10.7617798],
                [107.1784624, 10.7618645],
                [107.1784693, 10.7619157],
                [107.1785031, 10.7620132],
                [107.1785649, 10.7621024],
                [107.1786533, 10.7621744],
                [107.1787044, 10.7622003],
                [107.1788153, 10.7622293],
                [107.1788728, 10.7622318],
                [107.1791116, 10.7621981],
                [107.1791672, 10.7622089],
                [107.1792143, 10.7622422],
                [107.1792317, 10.7622662],
                [107.1792485, 10.7623223],
                [107.1792438, 10.7625283],
                [107.1792528, 10.7625735],
                [107.1792921, 10.7626531],
                [107.1793533, 10.7627132],
                [107.1796975, 10.7629534],
                [107.1797181, 10.7629767],
                [107.1797408, 10.7630337],
                [107.1797346, 10.763098],
                [107.1797191, 10.7631287],
                [107.1796674, 10.763174],
                [107.1796346, 10.7631857],
                [107.1794119, 10.7631857],
                [107.1793789, 10.763197],
                [107.1793251, 10.7632403],
                [107.1793073, 10.76327],
                [107.1792947, 10.7633311],
                [107.1792985, 10.7633622],
                [107.1793256, 10.7634186],
                [107.1793476, 10.7634413],
                [107.1797177, 10.7639656],
                [107.1797719, 10.7640721],
                [107.1797982, 10.7641883],
                [107.1798001, 10.7642546],
                [107.1797928, 10.7643206],
                [107.1797764, 10.764385],
                [107.1797512, 10.7644465],
                [107.1797443, 10.7645135],
                [107.1797496, 10.7645468],
                [107.1797767, 10.7646086],
                [107.1798263, 10.7646603],
                [107.1798574, 10.7646788],
                [107.1799269, 10.7646982],
                [107.1800812, 10.7646868],
                [107.1802634, 10.7646497],
                [107.1805718, 10.7645432],
                [107.1806079, 10.7645367],
                [107.1806446, 10.7645395],
                [107.1806793, 10.7645513],
                [107.1807076, 10.7645695],
                [107.1807475, 10.7646225],
                [107.1807675, 10.7647494],
                [107.1807846, 10.7648094],
                [107.1808535, 10.7649701],
                [107.1808713, 10.76509],
                [107.1808674, 10.7651551],
                [107.1808543, 10.7652191],
                [107.1808321, 10.7652806],
                [107.1808013, 10.7653385],
                [107.1806044, 10.7659421],
                [107.1805927, 10.7659913],
                [107.1805868, 10.7660921],
                [107.1805922, 10.7661408],
                [107.1806192, 10.7662349],
                [107.1806404, 10.7662792],
                [107.1807217, 10.7663932],
                [107.1807537, 10.7664554],
                [107.1807986, 10.7665875],
                [107.180817, 10.7667227],
                [107.180801, 10.7669655],
                [107.1808092, 10.7670185],
                [107.1808481, 10.7671156],
                [107.1808773, 10.7671579],
                [107.1809526, 10.7672279],
                [107.1813995, 10.7674702],
                [107.1815028, 10.7675155],
                [107.1816969, 10.7675817],
                [107.1818769, 10.767623],
                [107.1822524, 10.7676441],
                [107.1823145, 10.7676733],
                [107.1823543, 10.7677284],
                [107.1823624, 10.7677614],
                [107.1823528, 10.7678281],
                [107.1823356, 10.7678575],
                [107.1822309, 10.7679392],
                [107.1822093, 10.7679702],
                [107.1821849, 10.768041],
                [107.1821829, 10.7680785],
                [107.1821998, 10.768151],
                [107.1822179, 10.7681836],
                [107.1822703, 10.7682366],
                [107.182303, 10.7682553],
                [107.1823758, 10.7682739],
                [107.1825407, 10.7682731],
                [107.1826225, 10.7682633],
                [107.1827208, 10.7682432],
                [107.1828168, 10.7682141],
                [107.1829095, 10.7681764],
                [107.1833414, 10.7680394],
                [107.1833815, 10.7680268],
                [107.1834234, 10.7680233],
                [107.1834651, 10.7680288],
                [107.1835046, 10.7680432],
                [107.183568, 10.7680937],
                [107.1836037, 10.7681638],
                [107.1836171, 10.7682511],
                [107.183653, 10.768342],
                [107.1837142, 10.7684188],
                [107.1837525, 10.7684497],
                [107.1838408, 10.7684935],
                [107.1839382, 10.7685105],
                [107.1849185, 10.7685242],
                [107.1850435, 10.768544],
                [107.1851599, 10.7685927],
                [107.1852616, 10.7686678],
                [107.185683, 10.7691376],
                [107.1857988, 10.7692328],
                [107.1859359, 10.7693093],
                [107.1860124, 10.769339],
                [107.1861723, 10.7693761],
                [107.1862983, 10.769386],
                [107.1863814, 10.7694141],
                [107.1864179, 10.7694385],
                [107.1864671, 10.7694919],
                [107.1865153, 10.7695899],
                [107.1865627, 10.7696467],
                [107.1866336, 10.769692],
                [107.1866738, 10.7697056],
                [107.1867719, 10.7697215],
                [107.1868799, 10.7697573],
                [107.1869834, 10.7698201],
                [107.187074, 10.7699111],
                [107.1871531, 10.7700184],
                [107.1872006, 10.770069],
                [107.1873288, 10.7701834],
                [107.1873575, 10.7702256],
                [107.1873915, 10.7703212],
                [107.1873959, 10.770374],
                [107.1873909, 10.7704266],
                [107.1873767, 10.7704776],
                [107.1873536, 10.7705255],
                [107.1872548, 10.7706481],
                [107.1872323, 10.7706943],
                [107.1872102, 10.770794],
                [107.187211, 10.7708452],
                [107.1872351, 10.7709409],
                [107.1872567, 10.7709835],
                [107.1873174, 10.7710576],
                [107.1873969, 10.7711117],
                [107.1877023, 10.7711815],
                [107.1878368, 10.7712224],
                [107.1879652, 10.7712873],
                [107.1881135, 10.7713938],
                [107.1882194, 10.7714345],
                [107.1882766, 10.7714437],
                [107.1890277, 10.7718284],
                [107.1890919, 10.7718399],
                [107.1892154, 10.7718811],
                [107.189378, 10.7719793],
                [107.1894309, 10.7719889],
                [107.1894576, 10.7719842],
                [107.189503, 10.7719577],
                [107.1895308, 10.7719147],
                [107.1895359, 10.771864],
                [107.1895683, 10.7717889],
                [107.1896191, 10.7717217],
                [107.1896511, 10.7716924],
                [107.189953, 10.7714964],
                [107.190145, 10.77135],
                [107.1902083, 10.7713243],
                [107.1902427, 10.7713225],
                [107.1902701, 10.7713266],
                [107.1903205, 10.7713489],
                [107.1904365, 10.771537],
                [107.1904948, 10.7715966],
                [107.1905694, 10.7716382],
                [107.1906531, 10.7716572],
                [107.1907829, 10.7716316],
                [107.1909227, 10.7715826],
                [107.1909964, 10.7715465],
                [107.191321, 10.7713278],
                [107.1913513, 10.7713152],
                [107.191384, 10.7713114],
                [107.1914165, 10.7713165],
                [107.1914463, 10.7713302],
                [107.1914712, 10.7713515],
                [107.1914973, 10.7714028],
                [107.1914954, 10.7714601],
                [107.1912626, 10.7718418],
                [107.1912385, 10.7718944],
                [107.1912056, 10.772005],
                [107.1911949, 10.7721604],
                [107.1912082, 10.7721895],
                [107.1912512, 10.7722368],
                [107.1913148, 10.7722646],
                [107.1913844, 10.772263],
                [107.1914172, 10.7722511],
                [107.1914466, 10.7722324],
                [107.1915223, 10.7721493],
                [107.1916065, 10.7720192],
                [107.1917662, 10.7716334],
                [107.1918131, 10.7715896],
                [107.1918436, 10.7715783],
                [107.1919086, 10.7715813],
                [107.1919382, 10.7715954],
                [107.1919628, 10.7716169],
                [107.1921203, 10.7718047],
                [107.1921554, 10.7718335],
                [107.1922385, 10.7718703],
                [107.1923295, 10.7718759],
                [107.1923769, 10.7718657],
                [107.1924215, 10.7718469],
                [107.1924616, 10.7718201],
                [107.1924958, 10.7717863],
                [107.1926272, 10.7715728],
                [107.1926855, 10.7715155],
                [107.1927586, 10.771478],
                [107.1928378, 10.7714639],
                [107.1928781, 10.7714656],
                [107.1929558, 10.7714865],
                [107.1932656, 10.7716662],
                [107.1933541, 10.7717441],
                [107.1934184, 10.771839],
                [107.1934529, 10.7719018],
                [107.1934828, 10.7719338],
                [107.193543, 10.7719643],
                [107.1936103, 10.7719713],
                [107.1936787, 10.7719521],
                [107.1937102, 10.771932],
                [107.1937575, 10.7718753],
                [107.1938076, 10.7716939],
                [107.1938691, 10.7715966],
                [107.1939447, 10.7715292],
                [107.1939885, 10.7715033],
                [107.1940699, 10.7714722],
                [107.1941059, 10.7714676],
                [107.1941775, 10.771478],
                [107.1942107, 10.7714927],
                [107.194266, 10.7715386],
                [107.1943164, 10.7716313],
                [107.194393, 10.7717048],
                [107.1944886, 10.771752],
                [107.1945415, 10.7717645],
                [107.1946499, 10.7717646],
                [107.1947029, 10.7717524],
                [107.1947998, 10.7717046],
                [107.1949871, 10.7715753],
                [107.1950652, 10.7715537],
                [107.1951458, 10.7715623],
                [107.1951845, 10.7715783],
                [107.1952488, 10.7716306],
                [107.1953118, 10.7717415],
                [107.1953738, 10.7718021],
                [107.1954119, 10.7718248],
                [107.1954966, 10.7718511],
                [107.1955854, 10.7718499],
                [107.1957542, 10.771813],
                [107.1958814, 10.7717954],
                [107.1960703, 10.7717815],
                [107.1961918, 10.7717915],
                [107.1963871, 10.7718529],
                [107.1964735, 10.7718548],
                [107.196531, 10.7718444],
                [107.1965861, 10.7718249],
                [107.1966985, 10.7717658],
                [107.1967629, 10.7717416],
                [107.196898, 10.7717148],
                [107.197035, 10.7717176],
                [107.197102, 10.77173],
                [107.1972776, 10.7717838],
                [107.1974042, 10.7717968],
                [107.1975566, 10.7717863],
                [107.1977667, 10.7717388],
                [107.1978924, 10.7717415],
                [107.1980128, 10.7717757],
                [107.1985012, 10.7720445],
                [107.1985827, 10.7720763],
                [107.1986262, 10.7720823],
                [107.1987114, 10.7720742],
                [107.1988341, 10.7720204],
                [107.1989332, 10.771999],
                [107.1990292, 10.7720068],
                [107.1991145, 10.7720387],
                [107.199153, 10.7720629],
                [107.1995822, 10.772445],
                [107.1999255, 10.77264],
                [107.2001132, 10.7728666],
                [107.2001607, 10.7729086],
                [107.2003573, 10.7729904],
                [107.2004861, 10.7730854],
                [107.2006597, 10.7731347],
                [107.2008265, 10.7731958],
                [107.2009072, 10.7732329],
                [107.2010185, 10.7733239],
                [107.201072, 10.7733418],
                [107.2011264, 10.7733264],
                [107.2011815, 10.7732597],
                [107.2012061, 10.7732418],
                [107.2012627, 10.7732257],
                [107.2012914, 10.7732277],
                [107.2013437, 10.77325],
                [107.2014141, 10.7732684],
                [107.2015757, 10.7732664],
                [107.2016421, 10.7732856],
                [107.2016984, 10.7733251],
                [107.2017118, 10.7733778],
                [107.2017075, 10.7734061],
                [107.201677, 10.7734542],
                [107.2016126, 10.7735095],
                [107.2015969, 10.7735666],
                [107.2016135, 10.7736235],
                [107.2016327, 10.7736465],
                [107.2016826, 10.7736729],
                [107.2017393, 10.773672],
                [107.2018496, 10.7736127],
                [107.2019184, 10.773607],
                [107.2019543, 10.7736148],
                [107.2020173, 10.7736512],
                [107.202106, 10.7737747],
                [107.2021261, 10.7737909],
                [107.2021745, 10.7738086],
                [107.2022369, 10.7738001],
                [107.2022641, 10.7737839],
                [107.2023169, 10.773728],
                [107.2023954, 10.7736822],
                [107.2024857, 10.7736689],
                [107.2025722, 10.7736893],
                [107.2026111, 10.773711],
                [107.202673, 10.7737738],
                [107.2026939, 10.7738127],
                [107.2027579, 10.7740049],
                [107.2028276, 10.7741287],
                [107.2028563, 10.7741543],
                [107.2028899, 10.774173],
                [107.2029595, 10.774187],
                [107.2030237, 10.7741749],
                [107.2030992, 10.7741414],
                [107.2031978, 10.7741245],
                [107.203297, 10.7741366],
                [107.2033489, 10.7741554],
                [107.2033969, 10.7741822],
                [107.2034766, 10.7742568],
                [107.203542, 10.7743751],
                [107.2035675, 10.7744067],
                [107.2036312, 10.7744575],
                [107.2037071, 10.7744881],
                [107.2039388, 10.7745052],
                [107.2041303, 10.7745061],
                [107.204487, 10.7744827],
                [107.2047028, 10.7744528],
                [107.204806, 10.7744608],
                [107.2048936, 10.774495],
                [107.2049612, 10.7745455],
                [107.2049895, 10.7745767],
                [107.2050726, 10.7747479],
                [107.2050951, 10.77478],
                [107.2051566, 10.7748283],
                [107.2052319, 10.77485],
                [107.2053095, 10.774842],
                [107.2053458, 10.7748273],
                [107.2056435, 10.7746086],
                [107.205686, 10.7745849],
                [107.2057323, 10.7745697],
                [107.2057807, 10.7745635],
                [107.2058283, 10.7745664],
                [107.2060275, 10.774632],
                [107.2061061, 10.7746464],
                [107.2062211, 10.774652],
                [107.2062877, 10.7746294],
                [107.2063347, 10.7745891],
                [107.2063802, 10.7745342],
                [107.20645, 10.7744923],
                [107.2065217, 10.774477],
                [107.2065948, 10.7744844],
                [107.206595, 10.7743849],
                [107.2066035, 10.7743274],
                [107.2066367, 10.774217],
                [107.2066609, 10.7741651],
                [107.2067463, 10.774026],
                [107.2067844, 10.7738695],
                [107.2068207, 10.7738327],
                [107.2068791, 10.7738165],
                [107.2065405, 10.7580717],
                [107.2067034, 10.7581],
                [107.2067667, 10.7581213],
                [107.2067922, 10.758143],
                [107.2068228, 10.7582015],
                [107.2068259, 10.7582318],
                [107.2068105, 10.7582905],
                [107.2067371, 10.7583858],
                [107.2067119, 10.7584222],
                [107.2066828, 10.7585051],
                [107.2066798, 10.758549],
                [107.2066961, 10.758632],
                [107.2067139, 10.7586689],
                [107.2067665, 10.7587317],
                [107.2068369, 10.7587746],
                [107.2070249, 10.7588152],
                [107.2072002, 10.7588302],
                [107.2074058, 10.7588331],
                [107.2074766, 10.7588583],
                [107.2075072, 10.7588831],
                [107.2075311, 10.7589142],
                [107.2075591, 10.7589845],
                [107.2076026, 10.7590434],
                [107.207631, 10.7590646],
                [107.2077502, 10.7591159],
                [107.2077714, 10.7591351],
                [107.2078206, 10.7592057],
                [107.2078434, 10.7592244],
                [107.207903, 10.7592482],
                [107.2079635, 10.7592566],
                [107.2080076, 10.7592845],
                [107.2080374, 10.7593321],
                [107.2080526, 10.7594373],
                [107.2080701, 10.7594807],
                [107.2080958, 10.75952],
                [107.2157814, 10.7593365],
                [107.2170691, 10.757523],
                [107.2171165, 10.7575078],
                [107.2171654, 10.7575098],
                [107.2172565, 10.7575293],
                [107.2172992, 10.7575207],
                [107.2173307, 10.7574893],
                [107.2173381, 10.7574676],
                [107.2173258, 10.7573724],
                [107.2173441, 10.7573248],
                [107.2173843, 10.7572898],
                [107.2174387, 10.7572778],
                [107.2174921, 10.7572935],
                [107.2178322, 10.7575854],
                [107.2178652, 10.7575993],
                [107.2179365, 10.7576014],
                [107.217991, 10.7575766],
                [107.2180131, 10.7575565],
                [107.2180894, 10.7574057],
                [107.2181118, 10.7573812],
                [107.2181685, 10.7573463],
                [107.2182394, 10.7573342],
                [107.2182778, 10.7573393],
                [107.2183469, 10.7573728],
                [107.2183708, 10.7573912],
                [107.2184031, 10.7574412],
                [107.2184096, 10.7575031],
                [107.218401, 10.7575349],
                [107.2183847, 10.7575636],
                [107.2183455, 10.7576025],
                [107.2183272, 10.7576419],
                [107.2183308, 10.757685],
                [107.2183407, 10.7577044],
                [107.2183733, 10.7577329],
                [107.218416, 10.7577423],
                [107.2189576, 10.7577233],
                [107.2190503, 10.7577047],
                [107.2191373, 10.7576605],
                [107.2192104, 10.757592],
                [107.2194546, 10.7572753],
                [107.219499, 10.7572328],
                [107.2196017, 10.7571651],
                [107.2197245, 10.7571222],
                [107.2197925, 10.7571123],
                [107.2198612, 10.7571115],
                [107.2199294, 10.7571198],
                [107.2202754, 10.7571989],
                [107.2203282, 10.7572089],
                [107.2203819, 10.7572098],
                [107.2204818, 10.757186],
                [107.2205256, 10.7571634],
                [107.2206871, 10.7570401],
                [107.2207626, 10.7570058],
                [107.2208031, 10.7569963],
                [107.220899, 10.756995],
                [107.2210165, 10.7570212],
                [107.2211605, 10.7570355],
                [107.2212455, 10.7570327],
                [107.2214524, 10.7569984],
                [107.221532, 10.7570025],
                [107.2216393, 10.7570359],
                [107.2217318, 10.7570987],
                [107.2217734, 10.7571651],
                [107.2218255, 10.7572873],
                [107.2218499, 10.7573938],
                [107.2218527, 10.757451],
                [107.2218633, 10.7574778],
                [107.2219051, 10.7575257],
                [107.2219362, 10.7575423],
                [107.2220483, 10.757573],
                [107.222157, 10.7576218],
                [107.2222844, 10.7576574],
                [107.2223501, 10.7576672],
                [107.2225728, 10.7576766],
                [107.2226616, 10.7576912],
                [107.2228329, 10.7577456],
                [107.2231668, 10.7576508],
                [107.2233331, 10.7576191],
                [107.2235658, 10.7575988],
                [107.2236764, 10.7576126],
                [107.2237212, 10.7576273],
                [107.2238038, 10.7576718],
                [107.2239728, 10.7578128],
                [107.2240613, 10.7578695],
                [107.2241756, 10.7579107],
                [107.2242839, 10.7579283],
                [107.2243765, 10.7579578],
                [107.2244617, 10.7580062],
                [107.2245344, 10.7580687],
                [107.2246125, 10.7581211],
                [107.2246674, 10.7581459],
                [107.2247621, 10.7581732],
                [107.2247957, 10.7581911],
                [107.2248244, 10.758216],
                [107.2248608, 10.7582799],
                [107.224878, 10.758405],
                [107.2249214, 10.7585787],
                [107.2249934, 10.758743],
                [107.2250413, 10.758823],
                [107.2250961, 10.7588986],
                [107.2252243, 10.7590347],
                [107.2252968, 10.7590941],
                [107.2254561, 10.7591936],
                [107.2255059, 10.7592355],
                [107.2255371, 10.7592888],
                [107.2255616, 10.7594015],
                [107.2255834, 10.7594537],
                [107.2256135, 10.7595019],
                [107.225651, 10.7595447],
                [107.225695, 10.759581],
                [107.2257924, 10.759629],
                [107.2258428, 10.7596411],
                [107.2259461, 10.7596433],
                [107.2260457, 10.7596163],
                [107.2260915, 10.7595925],
                [107.2261333, 10.7595625],
                [107.2264418, 10.7592621],
                [107.2265114, 10.7592252],
                [107.2265498, 10.7592155],
                [107.2266287, 10.7592149],
                [107.226667, 10.7592239],
                [107.2267828, 10.7592751],
                [107.226879, 10.7592885],
                [107.2269774, 10.759277],
                [107.2271086, 10.7592324],
                [107.2271886, 10.7592324],
                [107.227268, 10.7592565],
                [107.2273387, 10.7593052],
                [107.2273673, 10.7593372],
                [107.2274074, 10.7594123],
                [107.2275013, 10.7600618],
                [107.2275224, 10.7601481],
                [107.227582, 10.760296],
                [107.2276568, 10.7604163],
                [107.2277096, 10.7604797],
                [107.227737, 10.7605571],
                [107.2277352, 10.7606415],
                [107.2277017, 10.7607221],
                [107.2276742, 10.7607564],
                [107.2275677, 10.7608338],
                [107.2275388, 10.7608669],
                [107.2275164, 10.7609044],
                [107.2274932, 10.7609881],
                [107.2274996, 10.7610687],
                [107.2275129, 10.7611072],
                [107.2275576, 10.7611752],
                [107.2276071, 10.7612817],
                [107.2276305, 10.7614041],
                [107.2276211, 10.7615357],
                [107.2276032, 10.7615994],
                [107.2275817, 10.7616238],
                [107.2275618, 10.7616846],
                [107.2275764, 10.761747],
                [107.2275952, 10.7617725],
                [107.2276493, 10.7618052],
                [107.2278285, 10.7618181],
                [107.2278519, 10.7618235],
                [107.2278908, 10.7618508],
                [107.2279036, 10.7618708],
                [107.2279119, 10.7619156],
                [107.2279073, 10.761938],
                [107.2278739, 10.761999],
                [107.2278735, 10.7620232],
                [107.2278956, 10.7620658],
                [107.2279305, 10.7620851],
                [107.228641, 10.7620077],
                [107.2286754, 10.7620132],
                [107.2287351, 10.7620474],
                [107.228769, 10.762098],
                [107.228778, 10.762158],
                [107.2287136, 10.7624426],
                [107.2287257, 10.7628965],
                [107.229116, 10.7630434],
                [107.2291394, 10.7630639],
                [107.229175, 10.7631145],
                [107.2291937, 10.7631812],
                [107.2291966, 10.7632402],
                [107.2292164, 10.7632845],
                [107.2292553, 10.7633196],
                [107.2292812, 10.7633308],
                [107.2293822, 10.7633514],
                [107.2294108, 10.7633676],
                [107.2294348, 10.7633899],
                [107.2294894, 10.7634843],
                [107.2295196, 10.7635111],
                [107.2295717, 10.7635372],
                [107.229816, 10.7635915],
                [107.2299088, 10.7636289],
                [107.2299984, 10.7636732],
                [107.2301888, 10.7637891],
                [107.2302469, 10.7638125],
                [107.2303686, 10.7638418],
                [107.2305163, 10.7638464],
                [107.2306048, 10.7638194],
                [107.230677, 10.7637693],
                [107.2307346, 10.7637562],
                [107.2307655, 10.7637589],
                [107.230795, 10.763768],
                [107.2309755, 10.7639035],
                [107.2310096, 10.7639156],
                [107.2310632, 10.7639182],
                [107.231133, 10.7639446],
                [107.2311654, 10.7639692],
                [107.2312344, 10.7640552],
                [107.2312644, 10.7640737],
                [107.2312992, 10.7640848],
                [107.2313358, 10.764087],
                [107.2314066, 10.7640708],
                [107.2314428, 10.7640697],
                [107.2315323, 10.7640976],
                [107.2315876, 10.7641014],
                [107.2316487, 10.7640853],
                [107.2319457, 10.7639419],
                [107.2320066, 10.7638545],
                [107.2320262, 10.7638049],
                [107.2320383, 10.7637494],
                [107.232041, 10.7636927],
                [107.2320342, 10.7636363],
                [107.2320412, 10.7635802],
                [107.2320778, 10.7634722],
                [107.232107, 10.7634222],
                [107.2321844, 10.7633359],
                [107.2323507, 10.763216],
                [107.232394, 10.7631197],
                [107.2324358, 10.7630717],
                [107.2325009, 10.7630381],
                [107.2327244, 10.7629795],
                [107.2327848, 10.7629364],
                [107.2328214, 10.762873],
                [107.2328334, 10.7627661],
                [107.2328442, 10.7627325],
                [107.2328713, 10.7626891],
                [107.2329417, 10.7626147],
                [107.2329837, 10.7625849],
                [107.2330745, 10.7625439],
                [107.2331874, 10.7625181],
                [107.2332439, 10.7624848],
                [107.2332962, 10.7624188],
                [107.2333344, 10.7623189],
                [107.2334021, 10.7622081],
                [107.2335019, 10.76211],
                [107.2335604, 10.7620711],
                [107.2336381, 10.7620399],
                [107.2337213, 10.7620289],
                [107.2338273, 10.7620452],
                [107.2339229, 10.7620811],
                [107.2339704, 10.7620911],
                [107.2340673, 10.7620948],
                [107.2341158, 10.7620883],
                [107.234209, 10.7620591],
                [107.2342873, 10.7620147],
                [107.234318, 10.7619873],
                [107.2343648, 10.7619159],
                [107.2343785, 10.7618734],
                [107.2343892, 10.7617865],
                [107.2344047, 10.7617585],
                [107.23442, 10.7617522],
                [107.2344452, 10.7617566],
                [107.234522, 10.7618273],
                [107.2345423, 10.7618338],
                [107.234585, 10.7618339],
                [107.2346266, 10.7618154],
                [107.2346561, 10.7617812],
                [107.2347454, 10.7616307],
                [107.2348512, 10.7613888],
                [107.2348896, 10.7613564],
                [107.2349296, 10.7613451],
                [107.2352581, 10.7613342],
                [107.2353428, 10.7613034],
                [107.2354688, 10.7612247],
                [107.2355613, 10.7611936],
                [107.2356137, 10.7611855],
                [107.2357196, 10.7611883],
                [107.2357862, 10.7612118],
                [107.2358922, 10.7612731],
                [107.2359793, 10.7612926],
                [107.236025, 10.7612927],
                [107.2360702, 10.761286],
                [107.2363901, 10.7611831],
                [107.2364555, 10.7611447],
                [107.2365158, 10.7610827],
                [107.2366932, 10.7607615],
                [107.2367348, 10.7607352],
                [107.2367595, 10.7607315],
                [107.2367843, 10.7607346],
                [107.2368265, 10.7607598],
                [107.2368488, 10.7608029],
                [107.2368598, 10.760861],
                [107.2368965, 10.7609208],
                [107.236952, 10.7609644],
                [107.2370319, 10.7609874],
                [107.2371144, 10.7609767],
                [107.2371522, 10.760959],
                [107.2373645, 10.7607762],
                [107.2374426, 10.7607501],
                [107.2375247, 10.7607588],
                [107.2375615, 10.7607754],
                [107.2376205, 10.7608294],
                [107.23764, 10.7608642],
                [107.2377487, 10.7611385],
                [107.2377878, 10.761182],
                [107.2378412, 10.7612068],
                [107.2378748, 10.7612109],
                [107.2380044, 10.7611854],
                [107.2380692, 10.7611831],
                [107.238125, 10.7611878],
                [107.2382332, 10.7612159],
                [107.238332, 10.7612674],
                [107.2386324, 10.7615362],
                [107.2386921, 10.7615762],
                [107.2387565, 10.7616083],
                [107.2388246, 10.761632],
                [107.2388953, 10.7616468],
                [107.239027, 10.7616505],
                [107.239156, 10.7616238],
                [107.2392172, 10.7615994],
                [107.2397856, 10.7612912],
                [107.2398604, 10.7612779],
                [107.2399334, 10.7612888],
                [107.2399972, 10.7613208],
                [107.2400713, 10.7613914],
                [107.2401119, 10.7614443],
                [107.2402016, 10.7616214],
                [107.2402391, 10.7616758],
                [107.2402751, 10.7617162],
                [107.2403598, 10.7617839],
                [107.2408318, 10.7610408],
                [107.2415936, 10.7594914],
                [107.2419584, 10.7591949],
                [107.2442946, 10.7597404],
                [107.2443385, 10.7595267],
                [107.2443734, 10.7593113],
                [107.2443992, 10.7590948],
                [107.244423, 10.7586768],
                [107.2444129, 10.7582582],
                [107.244369, 10.7578418],
                [107.2440599, 10.7561422],
                [107.2571906, 10.7559768],
                [107.2574357, 10.7558912],
                [107.3004983, 10.7549603],
                [107.299986, 10.7524616],
                [107.2995082, 10.7504464],
                [107.3042388, 10.7493004],
                [107.3040029, 10.7486331],
                [107.3039698, 10.7485727],
                [107.3039436, 10.7485498],
                [107.303881, 10.7485245],
                [107.3036083, 10.7485003],
                [107.3035165, 10.7484789],
                [107.3034446, 10.7484342],
                [107.3033951, 10.7483775],
                [107.3032797, 10.7481347],
                [107.3031876, 10.7478927],
                [107.3031054, 10.7477151],
                [107.3030504, 10.747623],
                [107.3028613, 10.7473531],
                [107.3028071, 10.7472619],
                [107.3027124, 10.7470726],
                [107.3025869, 10.7467779],
                [107.3025294, 10.7466633],
                [107.3024573, 10.7465578],
                [107.3022665, 10.7463066],
                [107.3017914, 10.7457233],
                [107.3017597, 10.7456592],
                [107.3017463, 10.7455872],
                [107.3017535, 10.7455126],
                [107.3018245, 10.7452251],
                [107.3018575, 10.7449919],
                [107.3020866, 10.7424722],
                [107.3024312, 10.7392522],
                [107.3024484, 10.7389004],
                [107.3024315, 10.7381625],
                [107.3024576, 10.7378627],
                [107.3025148, 10.7375672],
                [107.3025548, 10.737422],
                [107.3027453, 10.7372955],
                [107.3029143, 10.7359983],
                [107.3030685, 10.7350845],
                [107.3030853, 10.7348631],
                [107.3030861, 10.734727],
                [107.3030799, 10.734591],
                [107.3029518, 10.733419],
                [107.3028606, 10.73266],
                [107.3028565, 10.7325182],
                [107.3028663, 10.7324478],
                [107.3029089, 10.7323122],
                [107.3029795, 10.73219],
                [107.3030245, 10.7321352],
                [107.3031907, 10.7319789],
                [107.3033106, 10.7318769],
                [107.3034279, 10.7318115],
                [107.3035271, 10.7317799],
                [107.3036183, 10.7317179],
                [107.3036806, 10.7316405],
                [107.3037027, 10.731596],
                [107.3037269, 10.7315003],
                [107.3037526, 10.7313464],
                [107.3037719, 10.7313001],
                [107.3038311, 10.7312241],
                [107.3038681, 10.7311957],
                [107.3039473, 10.7311547],
                [107.30398, 10.7311279],
                [107.3040059, 10.7310947],
                [107.304032, 10.7310207],
                [107.3040372, 10.7309393],
                [107.3040481, 10.7308984],
                [107.3040879, 10.7308237],
                [107.3041159, 10.7307916],
                [107.3041882, 10.7307399],
                [107.3042296, 10.7307229],
                [107.3043179, 10.7307091],
                [107.3043994, 10.7307111],
                [107.3044355, 10.730704],
                [107.3045008, 10.7306746],
                [107.3045531, 10.7306282],
                [107.3046362, 10.7304991],
                [107.3046505, 10.7304168],
                [107.3046337, 10.7303449],
                [107.3045987, 10.7302909],
                [107.3043369, 10.7300261],
                [107.3043168, 10.7299976],
                [107.3042971, 10.7299316],
                [107.3043082, 10.7298601],
                [107.3043524, 10.7297981],
                [107.3046041, 10.7296549],
                [107.3047791, 10.7295248],
                [107.304942, 10.7293725],
                [107.3050852, 10.7292708],
                [107.3052051, 10.7291434],
                [107.3052972, 10.7289953],
                [107.3053316, 10.7289153],
                [107.3053746, 10.7287526],
                [107.3053853, 10.7285905],
                [107.3053754, 10.7284556],
                [107.3053621, 10.7284033],
                [107.30534, 10.728354],
                [107.305279, 10.7282768],
                [107.3052059, 10.7282262],
                [107.3051646, 10.728209],
                [107.3049688, 10.728209],
                [107.3045549, 10.728276],
                [107.3044741, 10.7282845],
                [107.3043117, 10.7282841],
                [107.3041065, 10.7282499],
                [107.3034295, 10.7280306],
                [107.3033632, 10.7280346],
                [107.3033099, 10.7280654],
                [107.3032773, 10.7281115],
                [107.3032187, 10.7283315],
                [107.3032016, 10.7283538],
                [107.3031779, 10.7283691],
                [107.3031503, 10.7283755],
                [107.3031221, 10.7283724],
                [107.3030799, 10.7283438],
                [107.3030631, 10.7282963],
                [107.3030782, 10.7280939],
                [107.303127, 10.7278925],
                [107.3032101, 10.7276978],
                [107.3032812, 10.7276081],
                [107.3033246, 10.7275704],
                [107.3034242, 10.7275124],
                [107.3035352, 10.7274803],
                [107.3035979, 10.7274746],
                [107.3037229, 10.7274883],
                [107.3038396, 10.7275344],
                [107.3042151, 10.7278849],
                [107.3043033, 10.7279327],
                [107.3044023, 10.7279508],
                [107.304502, 10.7279371],
                [107.304549, 10.7279187],
                [107.3046309, 10.7278611],
                [107.3046909, 10.7277793],
                [107.30471, 10.7277323],
                [107.3047235, 10.7276324],
                [107.3047036, 10.7275334],
                [107.3046523, 10.7274461],
                [107.3040736, 10.7268811],
                [107.3040321, 10.7268251],
                [107.3039657, 10.7267029],
                [107.3039037, 10.7265285],
                [107.3038369, 10.7264194],
                [107.3037753, 10.7263504],
                [107.303748, 10.7262945],
                [107.3037444, 10.7262325],
                [107.3037525, 10.7262002],
                [107.3037878, 10.726144],
                [107.3038135, 10.7261224],
                [107.3038756, 10.7260969],
                [107.3039094, 10.7260941],
                [107.3040938, 10.7261218],
                [107.3041448, 10.726117],
                [107.3041872, 10.7260887],
                [107.3042017, 10.7260678],
                [107.3042127, 10.7260116],
                [107.3042057, 10.7259835],
                [107.3040166, 10.7256659],
                [107.303868, 10.7253769],
                [107.3038534, 10.7253348],
                [107.3038443, 10.7252912],
                [107.3038429, 10.7252062],
                [107.3038611, 10.725127],
                [107.3038864, 10.7250852],
                [107.3038878, 10.7250331],
                [107.3038163, 10.724877],
                [107.3037928, 10.7247855],
                [107.303782, 10.7246776],
                [107.3037691, 10.7246442],
                [107.3037481, 10.7246151],
                [107.3037203, 10.724592],
                [107.3036867, 10.7245763],
                [107.3036501, 10.7245695],
                [107.3035208, 10.7245903],
                [107.3034737, 10.7245882],
                [107.3033836, 10.7245617],
                [107.3033394, 10.7245355],
                [107.3033006, 10.724502],
                [107.3032685, 10.7244623],
                [107.3032441, 10.7244176],
                [107.3031791, 10.7242431],
                [107.3031128, 10.7241413],
                [107.3030498, 10.7240181],
                [107.3030122, 10.7238881],
                [107.3030028, 10.7238212],
                [107.3030007, 10.7237102],
                [107.3029748, 10.7235178],
                [107.3029303, 10.7233573],
                [107.3028921, 10.7232538],
                [107.3028452, 10.7231539],
                [107.3027898, 10.7230581],
                [107.3026668, 10.7228945],
                [107.3025324, 10.7227624],
                [107.3024583, 10.7227038],
                [107.3024025, 10.7226756],
                [107.3023518, 10.7226392],
                [107.3023074, 10.7225955],
                [107.3022705, 10.7225456],
                [107.3022294, 10.722457],
                [107.3022175, 10.7224096],
                [107.3021856, 10.7221945],
                [107.3021516, 10.7220292],
                [107.3020989, 10.7218288],
                [107.3020826, 10.721794],
                [107.3020609, 10.7217621],
                [107.302005, 10.7217108],
                [107.3019372, 10.7216788],
                [107.3019004, 10.7216707],
                [107.3016, 10.7216918],
                [107.3013103, 10.7213386],
                [107.3013339, 10.7212152],
                [107.3013285, 10.7210897],
                [107.3012946, 10.7209686],
                [107.3012674, 10.7209117],
                [107.3011944, 10.720809],
                [107.3011495, 10.7207643],
                [107.3010459, 10.7206914],
                [107.3009884, 10.720664],
                [107.3010582, 10.7196414],
                [107.3008811, 10.7196309],
                [107.3008704, 10.7188087],
                [107.3011708, 10.718893],
                [107.3021083, 10.7179983],
                [107.3012741, 10.7177637],
                [107.3027976, 10.7163867],
                [107.3041239, 10.7152087],
                [107.3045927, 10.7156732],
                [107.3050654, 10.714866],
                [107.3050611, 10.7147867],
                [107.3050839, 10.7147032],
                [107.3051378, 10.7146262],
                [107.3052176, 10.71457],
                [107.3052505, 10.714534],
                [107.3052977, 10.7144524],
                [107.3053122, 10.7144075],
                [107.3054382, 10.7128578],
                [107.3051844, 10.7126855],
                [107.3045122, 10.7120089],
                [107.3038664, 10.7112054],
                [107.302157, 10.7089876],
                [107.301895, 10.708591],
                [107.3015785, 10.708311],
                [107.3012929, 10.7080026],
                [107.3012097, 10.7078807],
                [107.3011516, 10.707743],
                [107.3006921, 10.705972],
                [107.3007408, 10.7052062],
                [107.3008181, 10.7047563],
                [107.3005043, 10.7045692],
                [107.3004409, 10.7040497],
                [107.2997157, 10.7039189],
                [107.3002723, 10.7029773],
                [107.3006988, 10.7025346],
                [107.3007658, 10.7021023],
                [107.3007765, 10.7018625],
                [107.3008045, 10.7018073],
                [107.3008275, 10.7017861],
                [107.3008773, 10.7017635],
                [107.3009046, 10.7017601],
                [107.3011797, 10.7018073],
                [107.3013049, 10.7018203],
                [107.3016023, 10.7018274],
                [107.3017441, 10.7018098],
                [107.3018249, 10.7017886],
                [107.3019454, 10.7017444],
                [107.3019848, 10.7017226],
                [107.3020506, 10.7016622],
                [107.3020974, 10.7015739],
                [107.3021096, 10.7014751],
                [107.3020902, 10.7013246],
                [107.3020971, 10.7012818],
                [107.3021083, 10.7012629],
                [107.3021522, 10.7012321],
                [107.3021791, 10.7012276],
                [107.3022526, 10.7012436],
                [107.3023, 10.7012352],
                [107.3023439, 10.7012021],
                [107.3023581, 10.7011785],
                [107.3023697, 10.7011143],
                [107.3023656, 10.7010764],
                [107.3023216, 10.7009631],
                [107.3023185, 10.7008762],
                [107.3023433, 10.7007996],
                [107.3023894, 10.7007503],
                [107.3024185, 10.7007351],
                [107.3025306, 10.7007054],
                [107.3026085, 10.7006765],
                [107.3027219, 10.7006174],
                [107.3028156, 10.7005554],
                [107.3028606, 10.7005368],
                [107.3029353, 10.7005245],
                [107.303049, 10.7005248],
                [107.3031243, 10.7005169],
                [107.3031986, 10.7005025],
                [107.3033021, 10.7004563],
                [107.3033863, 10.7003813],
                [107.3034417, 10.7002884],
                [107.3034668, 10.7001836],
                [107.3034615, 10.7000641],
                [107.3034735, 10.700011],
                [107.303503, 10.69997],
                [107.3035476, 10.6999275],
                [107.3035943, 10.6998489],
                [107.3036385, 10.6998147],
                [107.3037043, 10.6998011],
                [107.3037377, 10.6998068],
                [107.3039317, 10.6999358],
                [107.303971, 10.6999412],
                [107.304014, 10.6999192],
                [107.3040274, 10.699899],
                [107.3040394, 10.6998484],
                [107.3040753, 10.6997957],
                [107.3041053, 10.6997725],
                [107.3042259, 10.6997198],
                [107.3042612, 10.6996927],
                [107.3042916, 10.6996605],
                [107.3044485, 10.6994457],
                [107.3048267, 10.699393],
                [107.3049501, 10.6993403],
                [107.305103, 10.6992573],
                [107.3051941, 10.6992243],
                [107.3053309, 10.6992058],
                [107.3055077, 10.6992266],
                [107.3055597, 10.6992158],
                [107.3055831, 10.6992032],
                [107.3056283, 10.6991515],
                [107.3056394, 10.6991189],
                [107.3056608, 10.6989291],
                [107.3056693, 10.6989018],
                [107.3056984, 10.6988527],
                [107.3057671, 10.6987783],
                [107.3058079, 10.6986863],
                [107.3058168, 10.6985891],
                [107.3057954, 10.6984968],
                [107.3057467, 10.6984152],
                [107.3057322, 10.6983511],
                [107.3057268, 10.6982857],
                [107.3057434, 10.6981559],
                [107.305765, 10.6980941],
                [107.3059666, 10.6977273],
                [107.3060086, 10.6976346],
                [107.3060788, 10.6975601],
                [107.3061222, 10.6975322],
                [107.3062054, 10.6975019],
                [107.3063302, 10.6974916],
                [107.3063647, 10.69748],
                [107.3064206, 10.6974372],
                [107.3064521, 10.6973767],
                [107.3064839, 10.6971677],
                [107.3065219, 10.6970666],
                [107.3065884, 10.6969786],
                [107.3066313, 10.696942],
                [107.3068276, 10.6968391],
                [107.3069054, 10.6967626],
                [107.306967, 10.6966285],
                [107.3070207, 10.6964912],
                [107.3070892, 10.696276],
                [107.3071038, 10.6962566],
                [107.3071236, 10.6962422],
                [107.3071682, 10.696233],
                [107.3072085, 10.6962466],
                [107.3072456, 10.6962798],
                [107.3072714, 10.6962924],
                [107.3072997, 10.6962975],
                [107.3073443, 10.696289],
                [107.307364, 10.6962777],
                [107.3074206, 10.6961969],
                [107.3074501, 10.6961063],
                [107.3074526, 10.6960141],
                [107.3074313, 10.6959759],
                [107.3073915, 10.6959592],
                [107.3073067, 10.6959825],
                [107.3072533, 10.6959756],
                [107.3072031, 10.6959403],
                [107.3071389, 10.6958611],
                [107.3071145, 10.6958163],
                [107.3070837, 10.6957232],
                [107.3070756, 10.6956291],
                [107.3070925, 10.6955206],
                [107.3070872, 10.6954895],
                [107.3070549, 10.6954359],
                [107.3070008, 10.6954036],
                [107.3069061, 10.6953934],
                [107.3068522, 10.6953595],
                [107.306833, 10.6953341],
                [107.3068151, 10.6952683],
                [107.3068196, 10.6952342],
                [107.3068587, 10.6951526],
                [107.3068758, 10.6950994],
                [107.3068839, 10.6950442],
                [107.3068802, 10.6949626],
                [107.306859, 10.6949054],
                [107.3068391, 10.6948778],
                [107.3068142, 10.6948545],
                [107.3067436, 10.6948176],
                [107.3066954, 10.6947549],
                [107.3066786, 10.694678],
                [107.3066964, 10.6946012],
                [107.3067456, 10.6945389],
                [107.3067793, 10.6945171],
                [107.3069762, 10.6944448],
                [107.3073804, 10.6942776],
                [107.3075351, 10.6941947],
                [107.3076175, 10.6941389],
                [107.307762, 10.6940779],
                [107.3077375, 10.6940026],
                [107.3076663, 10.6938608],
                [107.3075932, 10.693763],
                [107.3075511, 10.6937185],
                [107.3073653, 10.693588],
                [107.3071488, 10.6934704],
                [107.3069014, 10.6933732],
                [107.3065713, 10.6932752],
                [107.3059426, 10.6930611],
                [107.3056441, 10.6929463],
                [107.3046332, 10.6925259],
                [107.3049043, 10.6918554],
                [107.3049136, 10.6917801],
                [107.3049081, 10.6917424],
                [107.3048767, 10.6916712],
                [107.3048217, 10.6916155],
                [107.3047875, 10.6915957],
                [107.3036519, 10.691133],
                [107.3047435, 10.6885157],
                [107.3021076, 10.687412],
                [107.2994649, 10.6862622],
                [107.2995133, 10.6861974],
                [107.2995377, 10.6861206],
                [107.2995347, 10.686036],
                [107.2995025, 10.6859377],
                [107.2995132, 10.6858801],
                [107.2995301, 10.6858537],
                [107.2995815, 10.6858182],
                [107.2996125, 10.6858115],
                [107.2998726, 10.6858339],
                [107.2999273, 10.6858177],
                [107.2999504, 10.685801],
                [107.299981, 10.6857578],
                [107.2999887, 10.6857325],
                [107.2999931, 10.685609],
                [107.3000014, 10.6855611],
                [107.3000471, 10.6854183],
                [107.3000416, 10.6853476],
                [107.3000089, 10.6852852],
                [107.2999837, 10.6852599],
                [107.2999209, 10.6852264],
                [107.2996792, 10.6851638],
                [107.2996409, 10.6851315],
                [107.2996259, 10.6850841],
                [107.2996373, 10.6850383],
                [107.2996518, 10.6850192],
                [107.2996931, 10.6849951],
                [107.2998029, 10.6849866],
                [107.2998527, 10.6849924],
                [107.2999477, 10.6850235],
                [107.2999684, 10.6850242],
                [107.3000067, 10.6850103],
                [107.300036, 10.6849715],
                [107.30004, 10.6849474],
                [107.3000274, 10.6848249],
                [107.2999906, 10.6846307],
                [107.2999318, 10.6844495],
                [107.2998941, 10.6843619],
                [107.2998606, 10.6843125],
                [107.2998527, 10.6842836],
                [107.2998512, 10.6842538],
                [107.2998643, 10.6842028],
                [107.2999266, 10.6841132],
                [107.2999357, 10.6840627],
                [107.299921, 10.6840176],
                [107.2999062, 10.6839987],
                [107.2998651, 10.6839717],
                [107.2998332, 10.6839335],
                [107.2998244, 10.6838848],
                [107.2998458, 10.6838315],
                [107.2998941, 10.6837992],
                [107.2999263, 10.6837517],
                [107.2999306, 10.6837057],
                [107.2999129, 10.6836002],
                [107.2999327, 10.6835579],
                [107.299973, 10.683536],
                [107.3000448, 10.6835401],
                [107.3000966, 10.6835237],
                [107.3001388, 10.6834895],
                [107.3001737, 10.6834457],
                [107.3002253, 10.6834196],
                [107.3002839, 10.6834206],
                [107.3003476, 10.6834395],
                [107.3004228, 10.6834389],
                [107.30053, 10.683407],
                [107.3006631, 10.6834013],
                [107.3007229, 10.6833893],
                [107.3007953, 10.683363],
                [107.3008501, 10.6832925],
                [107.3008929, 10.6831425],
                [107.3009064, 10.6831232],
                [107.3009262, 10.6831104],
                [107.3009495, 10.683106],
                [107.3009703, 10.6831095],
                [107.3010023, 10.6831354],
                [107.3010823, 10.6833656],
                [107.3011204, 10.6834222],
                [107.3011778, 10.6834599],
                [107.3012471, 10.6834728],
                [107.3013173, 10.6834572],
                [107.3013485, 10.6834391],
                [107.3013963, 10.6833863],
                [107.3014109, 10.6833537],
                [107.3014471, 10.6831785],
                [107.3014739, 10.6831152],
                [107.3015004, 10.6830784],
                [107.301617, 10.6829567],
                [107.3016559, 10.6828901],
                [107.3016724, 10.68282],
                [107.3016848, 10.6826859],
                [107.3017073, 10.6826513],
                [107.301742, 10.6826373],
                [107.3020012, 10.6826497],
                [107.3020774, 10.6826329],
                [107.3021137, 10.6826177],
                [107.3021793, 10.6825749],
                [107.3022831, 10.682478],
                [107.3023932, 10.6823883],
                [107.3025961, 10.6822517],
                [107.3027775, 10.6821558],
                [107.3028528, 10.6821069],
                [107.3029486, 10.6820336],
                [107.3029683, 10.6820097],
                [107.3029796, 10.6819809],
                [107.3029755, 10.6819257],
                [107.3029464, 10.6818849],
                [107.302925, 10.6818711],
                [107.3027077, 10.6818553],
                [107.3026446, 10.6818283],
                [107.302619, 10.6818055],
                [107.3025854, 10.6817464],
                [107.302579, 10.681713],
                [107.3025872, 10.6816497],
                [107.3026203, 10.6815949],
                [107.3026729, 10.6815575],
                [107.3028292, 10.6814843],
                [107.302963, 10.6813849],
                [107.3030708, 10.6812657],
                [107.3031474, 10.6811528],
                [107.3031843, 10.6811103],
                [107.303271, 10.6810382],
                [107.3033913, 10.6809788],
                [107.3034563, 10.6809609],
                [107.3036257, 10.6809352],
                [107.3037212, 10.6808952],
                [107.3037645, 10.6808669],
                [107.3038516, 10.6807799],
                [107.3038845, 10.680728],
                [107.3039871, 10.6805032],
                [107.3040724, 10.6803704],
                [107.30436, 10.6800393],
                [107.3044134, 10.6800112],
                [107.3044431, 10.680005],
                [107.304509, 10.6800108],
                [107.3045704, 10.6800357],
                [107.3046033, 10.6800402],
                [107.3046362, 10.6800367],
                [107.3046859, 10.6800138],
                [107.3047221, 10.6799734],
                [107.3048079, 10.6797678],
                [107.304851, 10.679696],
                [107.3048797, 10.6796652],
                [107.3049553, 10.6796136],
                [107.3050017, 10.6795957],
                [107.3051003, 10.6795833],
                [107.305188, 10.6795611],
                [107.3053578, 10.679499],
                [107.3054477, 10.6794547],
                [107.305616, 10.6793465],
                [107.3057654, 10.6792143],
                [107.3059773, 10.6789165],
                [107.3061191, 10.6788389],
                [107.3061849, 10.6787922],
                [107.3063046, 10.6786845],
                [107.3063685, 10.6786105],
                [107.306425, 10.6785309],
                [107.3064736, 10.6784464],
                [107.3065138, 10.6783577],
                [107.3065871, 10.6781573],
                [107.3066854, 10.6779676],
                [107.3068356, 10.6777567],
                [107.3069713, 10.6776569],
                [107.3070093, 10.6776472],
                [107.3070475, 10.6776622],
                [107.3070614, 10.6776787],
                [107.3070897, 10.6777584],
                [107.3071307, 10.6778068],
                [107.30716, 10.6778262],
                [107.3071926, 10.6778394],
                [107.3072625, 10.6778457],
                [107.3073796, 10.6778243],
                [107.3074946, 10.6777939],
                [107.3076068, 10.6777546],
                [107.3077154, 10.6777067],
                [107.3078184, 10.6776512],
                [107.3079166, 10.677588],
                [107.3079632, 10.6775474],
                [107.3080427, 10.6774534],
                [107.3080848, 10.6773867],
                [107.3080993, 10.677377],
                [107.3081165, 10.6773732],
                [107.3081468, 10.6773827],
                [107.3082009, 10.6774536],
                [107.3082212, 10.6774679],
                [107.3082701, 10.6774742],
                [107.3083109, 10.6774517],
                [107.3083303, 10.6774125],
                [107.3083243, 10.6773693],
                [107.3081687, 10.6771742],
                [107.308158, 10.6771437],
                [107.3081553, 10.6770793],
                [107.3081991, 10.6769432],
                [107.3081963, 10.6768626],
                [107.3081764, 10.6767898],
                [107.3081902, 10.6767604],
                [107.308225, 10.676746],
                [107.3082439, 10.6767497],
                [107.3083216, 10.6768316],
                [107.308342, 10.676846],
                [107.3083907, 10.676857],
                [107.3084384, 10.676842],
                [107.3084576, 10.6768258],
                [107.3084854, 10.6767835],
                [107.3085261, 10.6767529],
                [107.3087091, 10.6767263],
                [107.3087749, 10.6767288],
                [107.3088885, 10.6767517],
                [107.3090281, 10.6768156],
                [107.3090662, 10.6768138],
                [107.3090833, 10.6768052],
                [107.3091099, 10.6767695],
                [107.3091289, 10.6766234],
                [107.3091832, 10.6764501],
                [107.309196, 10.6763809],
                [107.3091906, 10.6763071],
                [107.3091991, 10.6762828],
                [107.3092313, 10.6762498],
                [107.3093156, 10.676228],
                [107.3093482, 10.6761963],
                [107.3093569, 10.6761753],
                [107.3093572, 10.6761348],
                [107.3093499, 10.6761157],
                [107.3092416, 10.675996],
                [107.3092295, 10.6759742],
                [107.3092226, 10.6759254],
                [107.3092282, 10.6759011],
                [107.3092598, 10.6758564],
                [107.3093102, 10.6758336],
                [107.3094858, 10.6758484],
                [107.3095286, 10.6758432],
                [107.3095916, 10.6758194],
                [107.309711, 10.6757456],
                [107.309749, 10.6757426],
                [107.3097848, 10.6757593],
                [107.3097986, 10.6757746],
                [107.3098283, 10.6758445],
                [107.3098672, 10.6758833],
                [107.3098934, 10.6758932],
                [107.3099479, 10.6758904],
                [107.3099926, 10.6758596],
                [107.3101348, 10.6756666],
                [107.3101863, 10.6755782],
                [107.3102098, 10.6754806],
                [107.3102045, 10.6753819],
                [107.3101482, 10.6751065],
                [107.3101516, 10.675071],
                [107.3101643, 10.6750577],
                [107.3101949, 10.675051],
                [107.3103279, 10.6750972],
                [107.3103484, 10.6750963],
                [107.3103842, 10.6750775],
                [107.3104028, 10.675044],
                [107.3103975, 10.6749365],
                [107.3103609, 10.674777],
                [107.310301, 10.6746388],
                [107.3102408, 10.6745333],
                [107.3102275, 10.6744893],
                [107.3102268, 10.6743981],
                [107.3102394, 10.6743539],
                [107.3102823, 10.674283],
                [107.3103122, 10.6742538],
                [107.3105183, 10.6741273],
                [107.3105676, 10.6740487],
                [107.3105817, 10.6740045],
                [107.3105871, 10.6739164],
                [107.3105659, 10.673835],
                [107.3104557, 10.6736537],
                [107.3104543, 10.6736318],
                [107.3104728, 10.673594],
                [107.3105096, 10.6735759],
                [107.3105304, 10.6735764],
                [107.3107584, 10.6736133],
                [107.3108363, 10.6736084],
                [107.3109072, 10.6735761],
                [107.3109368, 10.6735509],
                [107.3109797, 10.6734868],
                [107.3109959, 10.6734034],
                [107.310977, 10.6733207],
                [107.3109551, 10.6732838],
                [107.3108718, 10.6731837],
                [107.3108657, 10.6731599],
                [107.3108793, 10.6731341],
                [107.3109494, 10.6730922],
                [107.310988, 10.6730385],
                [107.3110023, 10.6729766],
                [107.3109927, 10.6729163],
                [107.3109712, 10.6728701],
                [107.3109689, 10.6728502],
                [107.3109811, 10.6728156],
                [107.3110239, 10.6727896],
                [107.3111165, 10.6727804],
                [107.3111685, 10.6727633],
                [107.3112975, 10.6726881],
                [107.3113148, 10.6726701],
                [107.3113622, 10.6726551],
                [107.3114088, 10.6726723],
                [107.3114332, 10.6727089],
                [107.3114323, 10.6727527],
                [107.3114087, 10.6727978],
                [107.3114008, 10.6728542],
                [107.3114224, 10.6729131],
                [107.3114705, 10.6729543],
                [107.3115392, 10.6729779],
                [107.3116824, 10.6730018],
                [107.3118137, 10.672996],
                [107.3119068, 10.6729771],
                [107.3119332, 10.6729646],
                [107.3119751, 10.6729214],
                [107.3119918, 10.6728615],
                [107.3119484, 10.672625],
                [107.3118976, 10.6724699],
                [107.3118997, 10.6724114],
                [107.3119267, 10.6723548],
                [107.3119748, 10.6723138],
                [107.3122502, 10.6721389],
                [107.3122699, 10.6721352],
                [107.3123087, 10.6721438],
                [107.3123631, 10.6722043],
                [107.3124173, 10.6722466],
                [107.3124636, 10.6722487],
                [107.3124857, 10.6722413],
                [107.312525, 10.6722068],
                [107.3125358, 10.6721829],
                [107.3125313, 10.6720213],
                [107.3125494, 10.6719769],
                [107.3125662, 10.6719593],
                [107.3126164, 10.6719379],
                [107.3127647, 10.6719501],
                [107.312811, 10.6719433],
                [107.3128841, 10.6719169],
                [107.3129283, 10.6718736],
                [107.3129424, 10.6718452],
                [107.3129535, 10.6717398],
                [107.3129752, 10.6717023],
                [107.3129931, 10.6716868],
                [107.3130372, 10.6716699],
                [107.3131715, 10.6716721],
                [107.3132153, 10.6716456],
                [107.3132403, 10.6715978],
                [107.3132418, 10.6715706],
                [107.3132355, 10.6715442],
                [107.313167, 10.6714111],
                [107.3131537, 10.6713349],
                [107.313159, 10.6712964],
                [107.3131953, 10.6712221],
                [107.3132247, 10.6711923],
                [107.3133476, 10.6711272],
                [107.313403, 10.6710737],
                [107.3134289, 10.6710332],
                [107.3134567, 10.6709419],
                [107.3134724, 10.670804],
                [107.3134956, 10.6707692],
                [107.3135443, 10.6707501],
                [107.3136189, 10.6707578],
                [107.3137155, 10.6707534],
                [107.3138303, 10.6707245],
                [107.3138845, 10.6707007],
                [107.3140052, 10.6706164],
                [107.3140444, 10.6705732],
                [107.3140701, 10.6705182],
                [107.3140847, 10.6703969],
                [107.3141178, 10.6703475],
                [107.3141728, 10.6703172],
                [107.3142043, 10.6703128],
                [107.3142645, 10.6703204],
                [107.3143236, 10.6703178],
                [107.3143956, 10.6702895],
                [107.3144302, 10.6702868],
                [107.3144616, 10.6703074],
                [107.3144706, 10.6703258],
                [107.3144695, 10.6704006],
                [107.3144974, 10.6704477],
                [107.3145357, 10.6704699],
                [107.3145944, 10.6704743],
                [107.3146302, 10.6704661],
                [107.3146845, 10.670433],
                [107.3147514, 10.6703436],
                [107.3148356, 10.6702775],
                [107.315179, 10.670127],
                [107.3152336, 10.6700971],
                [107.3153122, 10.6700358],
                [107.3155421, 10.6698124],
                [107.3158373, 10.6695912],
                [107.3158592, 10.6695613],
                [107.3158827, 10.6694958],
                [107.315885, 10.6694629],
                [107.3158691, 10.6693648],
                [107.3158842, 10.6692988],
                [107.3159015, 10.6692694],
                [107.3159818, 10.6691889],
                [107.3160822, 10.6691223],
                [107.3162006, 10.6690757],
                [107.3162346, 10.6690773],
                [107.3162985, 10.6690994],
                [107.3163262, 10.6691194],
                [107.3163705, 10.6691676],
                [107.3164232, 10.6692022],
                [107.3164986, 10.669218],
                [107.316639, 10.6691956],
                [107.3167247, 10.6691579],
                [107.3167993, 10.6690992],
                [107.316851, 10.6690844],
                [107.3169043, 10.6690939],
                [107.3169715, 10.6691412],
                [107.3169981, 10.6691516],
                [107.3170549, 10.6691561],
                [107.3170854, 10.6691491],
                [107.3171383, 10.6691167],
                [107.3171806, 10.6690552],
                [107.317211, 10.6689735],
                [107.3172227, 10.6689058],
                [107.3172475, 10.6688618],
                [107.3172668, 10.6688451],
                [107.3173174, 10.6688258],
                [107.3173448, 10.6688256],
                [107.317596, 10.668878],
                [107.3176387, 10.6688753],
                [107.3176798, 10.6688635],
                [107.3177463, 10.6688187],
                [107.3178637, 10.6686396],
                [107.317911, 10.6686104],
                [107.3179391, 10.6686076],
                [107.3179893, 10.6686249],
                [107.3180207, 10.668664],
                [107.3180305, 10.6687473],
                [107.3180124, 10.6688359],
                [107.3180141, 10.668856],
                [107.3180392, 10.6688873],
                [107.3180556, 10.6688931],
                [107.3180896, 10.6688882],
                [107.3182436, 10.6687609],
                [107.3182967, 10.6687528],
                [107.3183433, 10.668769],
                [107.3184376, 10.6688691],
                [107.3185217, 10.6689201],
                [107.318573, 10.6689373],
                [107.318605, 10.6689657],
                [107.3186119, 10.6690043],
                [107.3185932, 10.6691117],
                [107.3186079, 10.6691548],
                [107.3186496, 10.669189],
                [107.3186763, 10.6691956],
                [107.3189082, 10.6691944],
                [107.3190061, 10.6692211],
                [107.3190878, 10.669275],
                [107.3191674, 10.6693765],
                [107.3191934, 10.6693996],
                [107.319257, 10.6694276],
                [107.3193221, 10.6694286],
                [107.3193535, 10.6694197],
                [107.3194818, 10.669343],
                [107.3194993, 10.6693397],
                [107.3195332, 10.6693485],
                [107.3195467, 10.6693599],
                [107.3195608, 10.6693917],
                [107.3195491, 10.6695479],
                [107.3195665, 10.6695898],
                [107.319603, 10.6696174],
                [107.3196511, 10.6696227],
                [107.3196745, 10.6696155],
                [107.3196949, 10.6696022],
                [107.319721, 10.669562],
                [107.3197478, 10.6695395],
                [107.3198102, 10.6695075],
                [107.3198827, 10.6694951],
                [107.3199211, 10.6694976],
                [107.3199941, 10.6695208],
                [107.3200598, 10.6695605],
                [107.3201314, 10.6695884],
                [107.3202151, 10.6696004],
                [107.320322, 10.6695934],
                [107.3203531, 10.6696029],
                [107.3203808, 10.66962],
                [107.3204127, 10.6696589],
                [107.3204411, 10.6697677],
                [107.3204602, 10.669793],
                [107.3205145, 10.6698244],
                [107.320577, 10.6698244],
                [107.3206577, 10.6697986],
                [107.3207644, 10.6697966],
                [107.3209938, 10.6698645],
                [107.321113, 10.6698704],
                [107.3212069, 10.6698515],
                [107.3213738, 10.6697821],
                [107.3214613, 10.669775],
                [107.3215061, 10.6697811],
                [107.3215905, 10.6698124],
                [107.3219531, 10.6700484],
                [107.3221461, 10.6702368],
                [107.3225948, 10.670439],
                [107.322739, 10.6705012],
                [107.3228039, 10.6706431],
                [107.3228881, 10.6708039],
                [107.322889, 10.6709992],
                [107.3228189, 10.671249],
                [107.3228028, 10.6715442],
                [107.3228165, 10.6717316],
                [107.3228694, 10.6718632],
                [107.3228621, 10.6720184],
                [107.3229289, 10.672153],
                [107.3230901, 10.6722913],
                [107.3232154, 10.6724376],
                [107.3233887, 10.6724862],
                [107.3236731, 10.6726214],
                [107.3238509, 10.6729047],
                [107.3238486, 10.6729546],
                [107.3238593, 10.6730306],
                [107.3238945, 10.6731257],
                [107.3239803, 10.6731731],
                [107.3240715, 10.6731494],
                [107.3241906, 10.6731164],
                [107.3243288, 10.6730834],
                [107.3243633, 10.6730907],
                [107.3244445, 10.673264],
                [107.3245572, 10.6734266],
                [107.3246267, 10.6734973],
                [107.3247874, 10.6735023],
                [107.3248909, 10.6734853],
                [107.3250452, 10.6734288],
                [107.3251283, 10.6734446],
                [107.3252429, 10.6734929],
                [107.3253295, 10.6735817],
                [107.3254422, 10.6737298],
                [107.3255628, 10.6738953],
                [107.3257962, 10.6740007],
                [107.325986, 10.6741353],
                [107.3262319, 10.6741996],
                [107.3265601, 10.6742604],
                [107.3269443, 10.674384],
                [107.3271689, 10.6745248],
                [107.3272332, 10.6745875],
                [107.3275525, 10.6749546],
                [107.3276583, 10.6752542],
                [107.3277436, 10.6757496],
                [107.3277849, 10.6759382],
                [107.3278305, 10.6760647],
                [107.3279723, 10.6762505],
                [107.3280368, 10.6763291],
                [107.3281649, 10.6763755],
                [107.3283885, 10.6763739],
                [107.3286755, 10.6763243],
                [107.3290908, 10.6763371],
                [107.3293308, 10.6763827],
                [107.3295704, 10.6763809],
                [107.3299051, 10.6762993],
                [107.3304152, 10.6761848],
                [107.3307665, 10.6761822],
                [107.3309904, 10.6762122],
                [107.3311189, 10.6763062],
                [107.3312803, 10.6765422],
                [107.3314103, 10.6768417],
                [107.3314917, 10.6770467],
                [107.3316051, 10.6772514],
                [107.3317577, 10.6773688],
                [107.3320297, 10.6774301],
                [107.3327974, 10.6775825],
                [107.3373416, 10.6776674],
                [107.3439759, 10.6764257],
                [107.3489593, 10.6772965],
                [107.352008, 10.6783643],
                [107.3506873, 10.690572],
                [107.3507517, 10.7085878],
                [107.3545401, 10.7146559],
                [107.3582119, 10.7205371],
                [107.3589407, 10.7217043],
                [107.3607048, 10.7246903],
                [107.3611829, 10.7267972],
                [107.3609531, 10.7276167],
                [107.3610895, 10.7282076],
                [107.3613834, 10.7284575],
                [107.3617845, 10.7287285],
                [107.3622617, 10.7290335],
                [107.3627254, 10.7292304],
                [107.3632434, 10.7294708],
                [107.3633952, 10.7295559],
                [107.3635884, 10.7296552],
                [107.3637291, 10.7297236],
                [107.3638093, 10.7298028],
                [107.3638413, 10.7298872],
                [107.3638247, 10.7300507],
                [107.3637914, 10.7301986],
                [107.363751, 10.7303781],
                [107.3636637, 10.7305363],
                [107.363614, 10.730726],
                [107.363599, 10.7308893],
                [107.3636258, 10.7310181],
                [107.3636901, 10.7311153],
                [107.3637974, 10.7312149],
                [107.3639227, 10.7312826],
                [107.3640855, 10.7312923],
                [107.3643147, 10.7312591],
                [107.3645364, 10.7311377],
                [107.3652025, 10.7307185],
                [107.3655468, 10.7305592],
                [107.3657195, 10.7305157],
                [107.365893, 10.7304912],
                [107.3660451, 10.7305456],
                [107.366194, 10.7306177],
                [107.3663272, 10.7307268],
                [107.3665255, 10.7309519],
                [107.366846, 10.7314992],
                [107.3673198, 10.7321125],
                [107.3678624, 10.732799],
                [107.3681198, 10.7330968],
                [107.3682095, 10.7331743],
                [107.3683099, 10.733236],
                [107.3684571, 10.733254],
                [107.3686056, 10.7332259],
                [107.3688131, 10.7331508],
                [107.3690405, 10.7330433],
                [107.3693265, 10.7329092],
                [107.3695739, 10.7327798],
                [107.3697354, 10.7326662],
                [107.3698654, 10.7325232],
                [107.3699967, 10.732269],
                [107.3701643, 10.7319274],
                [107.370423, 10.7315805],
                [107.3706544, 10.7313725],
                [107.3708882, 10.7312868],
                [107.3710525, 10.7312953],
                [107.3712602, 10.7313518],
                [107.3714193, 10.7314309],
                [107.3715462, 10.7315627],
                [107.371649, 10.7316671],
                [107.3717197, 10.7317879],
                [107.3717632, 10.7319197],
                [107.3718317, 10.7321175],
                [107.371852, 10.732289],
                [107.371811, 10.7325161],
                [107.3717385, 10.7326852],
                [107.3716339, 10.7328491],
                [107.3714674, 10.7330082],
                [107.3712779, 10.7331996],
                [107.3710494, 10.7333742],
                [107.3709441, 10.7334696],
                [107.3708863, 10.7336119],
                [107.3708766, 10.7338014],
                [107.3708784, 10.7339633],
                [107.3709178, 10.7341515],
                [107.3709991, 10.7343506],
                [107.3711225, 10.7344713],
                [107.3712824, 10.7345726],
                [107.3714476, 10.7346476],
                [107.3716813, 10.7347308],
                [107.371931, 10.7347666],
                [107.3723018, 10.734775],
                [107.3726677, 10.7347338],
                [107.3730366, 10.7347426],
                [107.3733465, 10.7348464],
                [107.3736134, 10.7349595],
                [107.3738224, 10.7351367],
                [107.3740757, 10.7353668],
                [107.3742913, 10.7356604],
                [107.3744182, 10.7359233],
                [107.3745236, 10.7361756],
                [107.3746136, 10.7363812],
                [107.3747251, 10.7365921],
                [107.3748076, 10.736704],
                [107.374976, 10.7368476],
                [107.3751429, 10.7369729],
                [107.3753688, 10.7370825],
                [107.3757241, 10.7372172],
                [107.3760871, 10.7374246],
                [107.3763765, 10.7376594],
                [107.3766301, 10.7379891],
                [107.376857, 10.738467],
                [107.3770527, 10.7388886],
                [107.3772163, 10.7392047],
                [107.3773909, 10.7395326],
                [107.3775762, 10.7398026],
                [107.3777865, 10.7400114],
                [107.3779504, 10.7402128],
                [107.3780392, 10.7403984],
                [107.3781203, 10.7405693],
                [107.3781572, 10.7407585],
                [107.3781668, 10.7409533],
                [107.3781818, 10.7411745],
                [107.3781731, 10.7414801],
                [107.3781602, 10.7417222],
                [107.3781794, 10.7419116],
                [107.3782496, 10.7421214],
                [107.378367, 10.7422574],
                [107.3785003, 10.7422935],
                [107.378642, 10.7422905],
                [107.3787612, 10.7422627],
                [107.3788696, 10.7422032],
                [107.378975, 10.742081],
                [107.3790805, 10.7418903],
                [107.3791637, 10.7416523],
                [107.3792496, 10.7414118],
                [107.3793194, 10.7412134],
                [107.3793984, 10.7410994],
                [107.3794605, 10.7410544],
                [107.3794964, 10.7410399],
                [107.3795764, 10.7410288],
                [107.3796185, 10.7410338],
                [107.3797204, 10.7410747],
                [107.3797782, 10.7411095],
                [107.3798868, 10.7412022],
                [107.3799349, 10.7412602],
                [107.3800086, 10.7413909],
                [107.3800764, 10.741611],
                [107.3801422, 10.7419143],
                [107.3801818, 10.7422484],
                [107.3801893, 10.7424296],
                [107.3801872, 10.7426109],
                [107.3801753, 10.7427919],
                [107.3801304, 10.7429922],
                [107.38006, 10.7431852],
                [107.3799479, 10.743396],
                [107.3798803, 10.7434948],
                [107.3795872, 10.7438796],
                [107.3795562, 10.7439617],
                [107.3795342, 10.7440466],
                [107.3795215, 10.7441333],
                [107.3795181, 10.7442208],
                [107.3795384, 10.7443893],
                [107.3795933, 10.7445502],
                [107.3803925, 10.7461893],
                [107.3804794, 10.7464037],
                [107.3805336, 10.7466281],
                [107.3805481, 10.7467427],
                [107.3805517, 10.7469673],
                [107.3805229, 10.7471902],
                [107.3804623, 10.7474068],
                [107.3804485, 10.7474975],
                [107.3804596, 10.7475886],
                [107.3804742, 10.747632],
                [107.3805202, 10.7477111],
                [107.3805857, 10.7477757],
                [107.3809209, 10.7479628],
                [107.3810722, 10.7480147],
                [107.3812307, 10.7480379],
                [107.381403, 10.7480298],
                [107.3814876, 10.748013],
                [107.3816975, 10.7479472],
                [107.3818302, 10.7479285],
                [107.3819761, 10.7479337],
                [107.3821756, 10.7479792],
                [107.3822341, 10.747985],
                [107.3823506, 10.747972],
                [107.3824503, 10.7479322],
                [107.3828106, 10.7476755],
                [107.3828957, 10.747645],
                [107.382941, 10.7476405],
                [107.3830306, 10.747654],
                [107.3830725, 10.7476715],
                [107.3831445, 10.7477256],
                [107.3831723, 10.7477602],
                [107.3832088, 10.7478405],
                [107.3832165, 10.7478839],
                [107.3832099, 10.7479716],
                [107.3831747, 10.7480524],
                [107.3828736, 10.7485583],
                [107.3828481, 10.7486398],
                [107.3828198, 10.7488078],
                [107.3828236, 10.7489882],
                [107.3828395, 10.7490824],
                [107.382865, 10.7491745],
                [107.3829769, 10.749428],
                [107.3830044, 10.7495094],
                [107.3830254, 10.7495926],
                [107.3830479, 10.7497626],
                [107.383042, 10.7499526],
                [107.3830269, 10.7500465],
                [107.3829482, 10.7503199],
                [107.3829297, 10.7504121],
                [107.3828979, 10.7507398],
                [107.3829022, 10.7508104],
                [107.3829326, 10.7509484],
                [107.3829598, 10.7510177],
                [107.383036, 10.7511457],
                [107.3830841, 10.7512029],
                [107.3831976, 10.7513007],
                [107.3832616, 10.7513402],
                [107.3835195, 10.7514604],
                [107.3837124, 10.7515417],
                [107.3841061, 10.7516849],
                [107.384432, 10.7517816],
                [107.3846924, 10.7518426],
                [107.3847856, 10.7518707],
                [107.3848756, 10.7519074],
                [107.3850398, 10.7520028],
                [107.3851805, 10.7521236],
                [107.3852419, 10.7521929],
                [107.3853442, 10.7523465],
                [107.3854164, 10.7525158],
                [107.3855612, 10.7529985],
                [107.385598, 10.7530787],
                [107.3856417, 10.7531554],
                [107.3857484, 10.7532963],
                [107.3858847, 10.7534223],
                [107.3859645, 10.7534779],
                [107.3860492, 10.753526],
                [107.3861382, 10.7535659],
                [107.3862306, 10.7535975],
                [107.3863256, 10.7536204],
                [107.3865416, 10.7537134],
                [107.3867413, 10.7538365],
                [107.3868231, 10.7538996],
                [107.3869732, 10.7540409],
                [107.3871661, 10.7542794],
                [107.387235, 10.7543534],
                [107.3873902, 10.7544836],
                [107.3875648, 10.7545875],
                [107.3876533, 10.7546268],
                [107.3878384, 10.7546846],
                [107.3879337, 10.7547028],
                [107.3882212, 10.7547284],
                [107.3883138, 10.754747],
                [107.3885441, 10.754824],
                [107.3885915, 10.75485],
                [107.3886339, 10.7548835],
                [107.3886698, 10.7549235],
                [107.3886992, 10.7549703],
                [107.3887199, 10.7550214],
                [107.3887312, 10.7550753],
                [107.3887837, 10.755824],
                [107.3888177, 10.7560095],
                [107.3888475, 10.7560992],
                [107.3889314, 10.7562686],
                [107.3890454, 10.7564202],
                [107.3891749, 10.7565406],
                [107.3893235, 10.7566378],
                [107.3894866, 10.7567087],
                [107.3895513, 10.7567227],
                [107.3896835, 10.7567289],
                [107.3898138, 10.7567061],
                [107.3899399, 10.7566532],
                [107.3900502, 10.7565732],
                [107.3900976, 10.7565243],
                [107.3901389, 10.7564704],
                [107.3903187, 10.7561061],
                [107.3908278, 10.7555943],
                [107.3910649, 10.7554254],
                [107.391248, 10.7553834],
                [107.3913988, 10.7553942],
                [107.3915119, 10.7554623],
                [107.3916722, 10.7555536],
                [107.3918192, 10.7557696],
                [107.3919064, 10.7560028],
                [107.3920956, 10.7562466],
                [107.3925421, 10.7566486],
                [107.3927878, 10.7569799],
                [107.392907, 10.7574073],
                [107.3928999, 10.7576681],
                [107.3929572, 10.7578972],
                [107.3931489, 10.7583528],
                [107.3931598, 10.7585498],
                [107.3930291, 10.7589342],
                [107.392877, 10.7592132],
                [107.3927276, 10.7593803],
                [107.3925093, 10.7595898],
                [107.3924816, 10.7597502],
                [107.3924968, 10.7599106],
                [107.392581, 10.7600417],
                [107.3926956, 10.7601762],
                [107.3927744, 10.7602687],
                [107.3930128, 10.7605934],
                [107.3932703, 10.7608426],
                [107.3937281, 10.7613139],
                [107.3939074, 10.7614581],
                [107.3939794, 10.76156],
                [107.3939934, 10.7616738],
                [107.3939507, 10.7621191],
                [107.3938672, 10.7624142],
                [107.3938733, 10.7625719],
                [107.3939363, 10.7627277],
                [107.3940321, 10.7628477],
                [107.394128, 10.7629572],
                [107.3942801, 10.7630403],
                [107.394442, 10.7631135],
                [107.3946146, 10.7631445],
                [107.3950515, 10.7631621],
                [107.3960139, 10.7631172],
                [107.3965994, 10.7630814],
                [107.3970829, 10.7630403],
                [107.3973065, 10.7630594],
                [107.3974188, 10.7631267],
                [107.3974945, 10.7632737],
                [107.3975734, 10.7634997],
                [107.3976739, 10.7637204],
                [107.3978484, 10.7638904],
                [107.3980283, 10.7640287],
                [107.3980929, 10.7641176],
                [107.3981468, 10.7642064],
                [107.3982557, 10.7645011],
                [107.3983911, 10.7647591],
                [107.3985413, 10.764932],
                [107.3986905, 10.7650411],
                [107.3988295, 10.7651963],
                [107.3989076, 10.7653921],
                [107.3989355, 10.7655503],
                [107.3988615, 10.7657876],
                [107.3990017, 10.7658866],
                [107.3991355, 10.7659434],
                [107.399278, 10.7659735],
                [107.3997102, 10.765981],
                [107.3997555, 10.7659892],
                [107.3998372, 10.7660298],
                [107.3998976, 10.7660974],
                [107.3999168, 10.7661382],
                [107.3999304, 10.7662267],
                [107.3999095, 10.7663139],
                [107.3998579, 10.7664209],
                [107.3998372, 10.7664917],
                [107.3998221, 10.7666379],
                [107.3998412, 10.7667793],
                [107.3998905, 10.7669087],
                [107.3999258, 10.7669686],
                [107.4000156, 10.767075],
                [107.4005306, 10.7674491],
                [107.4005702, 10.7674904],
                [107.4005877, 10.7675444],
                [107.4005793, 10.7676016],
                [107.4005652, 10.7676277],
                [107.4005209, 10.7676672],
                [107.4000263, 10.7679129],
                [107.3999869, 10.7679553],
                [107.3999756, 10.767982],
                [107.3999727, 10.7680394],
                [107.3999962, 10.7680924],
                [107.4000414, 10.7681295],
                [107.4004447, 10.7682238],
                [107.4005018, 10.7682434],
                [107.4006048, 10.768305],
                [107.4006861, 10.7683924],
                [107.4007349, 10.7684857],
                [107.4007586, 10.768588],
                [107.4007559, 10.7686928],
                [107.400713, 10.7688878],
                [107.4007131, 10.768924],
                [107.4007196, 10.7689596],
                [107.4007505, 10.7690248],
                [107.4008098, 10.769081],
                [107.4008863, 10.7691109],
                [107.4010162, 10.7691163],
                [107.4011046, 10.7691092],
                [107.4012623, 10.7690733],
                [107.4014756, 10.7689812],
                [107.4016142, 10.7689511],
                [107.4016798, 10.768949],
                [107.4018094, 10.7689676],
                [107.4019341, 10.7690151],
                [107.4020664, 10.7690469],
                [107.4021944, 10.7690484],
                [107.4023441, 10.7690201],
                [107.4024089, 10.7690302],
                [107.4024644, 10.7690644],
                [107.4025047, 10.7691241],
                [107.4025147, 10.769195],
                [107.4025031, 10.7692939],
                [107.4025043, 10.7693575],
                [107.4025235, 10.7694833],
                [107.4025464, 10.7695596],
                [107.4025776, 10.769633],
                [107.4026629, 10.7697679],
                [107.4027346, 10.7697995],
                [107.4028131, 10.7698022],
                [107.4028854, 10.7697764],
                [107.4029428, 10.7697262],
                [107.4030438, 10.7695387],
                [107.4030815, 10.7695007],
                [107.4031714, 10.7694427],
                [107.4032745, 10.7694122],
                [107.4033889, 10.7694127],
                [107.4034978, 10.7694472],
                [107.403591, 10.7695123],
                [107.4038323, 10.7697551],
                [107.4038661, 10.7697763],
                [107.4039423, 10.7697995],
                [107.4040205, 10.7697954],
                [107.4040579, 10.7697835],
                [107.4044134, 10.7696078],
                [107.4044842, 10.7695971],
                [107.4045194, 10.7696048],
                [107.4045789, 10.769644],
                [107.4045995, 10.769673],
                [107.4046124, 10.7697071],
                [107.4046162, 10.7697433],
                [107.4046106, 10.7697793],
                [107.404596, 10.7698127],
                [107.4045732, 10.7698414],
                [107.4041215, 10.7701966],
                [107.4040767, 10.770265],
                [107.4040557, 10.7703437],
                [107.4040612, 10.7704268],
                [107.4040745, 10.7704675],
                [107.4041204, 10.7705397],
                [107.4041518, 10.7705693],
                [107.4042272, 10.7706113],
                [107.4043125, 10.7706269],
                [107.4043973, 10.7706181],
                [107.4044827, 10.7706187],
                [107.4045674, 10.7706287],
                [107.4046504, 10.770648],
                [107.4047291, 10.7706755],
                [107.4048041, 10.7707114],
                [107.4048748, 10.770755],
                [107.4049401, 10.7708061],
                [107.4049844, 10.7708226],
                [107.4050308, 10.770832],
                [107.4051251, 10.770829],
                [107.4052174, 10.7707954],
                [107.4052597, 10.7707666],
                [107.4053264, 10.7706901],
                [107.4054658, 10.7703792],
                [107.4055152, 10.7703274],
                [107.4055824, 10.7703015],
                [107.4056187, 10.7703002],
                [107.4056564, 10.7703073],
                [107.4057216, 10.770346],
                [107.4057455, 10.7703754],
                [107.4057696, 10.7704464],
                [107.4057904, 10.7712698],
                [107.405809, 10.7713218],
                [107.4058289, 10.7713417],
                [107.4058816, 10.7713607],
                [107.4059373, 10.7713472],
                [107.4059755, 10.7713052],
                [107.4059992, 10.7712416],
                [107.4060514, 10.7711826],
                [107.4061235, 10.7711495],
                [107.4062013, 10.7711476],
                [107.406238, 10.771158],
                [107.4063017, 10.7711988],
                [107.4063443, 10.7712606],
                [107.4063771, 10.7712972],
                [107.4064165, 10.771327],
                [107.4064993, 10.7713601],
                [107.406579, 10.7713647],
                [107.4066316, 10.7713829],
                [107.4066819, 10.7714067],
                [107.4067726, 10.7714697],
                [107.406812, 10.7715081],
                [107.4069459, 10.7716866],
                [107.4070212, 10.7717719],
                [107.4071876, 10.7719265],
                [107.4074166, 10.7720943],
                [107.4074855, 10.7721841],
                [107.4075056, 10.7722258],
                [107.4075299, 10.7723147],
                [107.4075382, 10.772483],
                [107.4075295, 10.7726664],
                [107.4074883, 10.7728549],
                [107.4074895, 10.7728805],
                [107.4075101, 10.7729273],
                [107.4075517, 10.7729595],
                [107.4076049, 10.7729681],
                [107.4076557, 10.7729502],
                [107.4077025, 10.7729096],
                [107.4077341, 10.772894],
                [107.4078039, 10.7728883],
                [107.4078678, 10.7729167],
                [107.4079069, 10.7729663],
                [107.4079168, 10.7729964],
                [107.4079145, 10.7730592],
                [107.4079024, 10.7730885],
                [107.4078472, 10.7731654],
                [107.4078179, 10.7732209],
                [107.4077833, 10.7733409],
                [107.4077787, 10.7733945],
                [107.4077886, 10.7735016],
                [107.4078103, 10.7735459],
                [107.4078402, 10.7735854],
                [107.4078773, 10.7736184],
                [107.4079201, 10.7736439],
                [107.4079636, 10.77366],
                [107.408056, 10.7736681],
                [107.4084856, 10.7735675],
                [107.4085592, 10.7735646],
                [107.4086269, 10.773593],
                [107.4086757, 10.7736472],
                [107.4086962, 10.773718],
                [107.4086938, 10.7737557],
                [107.4086827, 10.7737919],
                [107.4086372, 10.7738522],
                [107.4086052, 10.7738731],
                [107.4081427, 10.7740418],
                [107.4081239, 10.7740624],
                [107.4081031, 10.7741135],
                [107.4081078, 10.7741683],
                [107.4081214, 10.774196],
                [107.4081677, 10.774237],
                [107.4081972, 10.7742474],
                [107.4082726, 10.7742574],
                [107.4083486, 10.7742591],
                [107.4084973, 10.7742382],
                [107.4085682, 10.7742161],
                [107.4087006, 10.7741498],
                [107.4091409, 10.7739611],
                [107.4092193, 10.7739506],
                [107.4092967, 10.7739665],
                [107.4093664, 10.7740086],
                [107.4094182, 10.7740743],
                [107.4094342, 10.7741129],
                [107.4094328, 10.7743316],
                [107.409444, 10.7744018],
                [107.4094646, 10.7744698],
                [107.4095292, 10.7745894],
                [107.4096179, 10.7746847],
                [107.4096551, 10.7746954],
                [107.4097322, 10.7747021],
                [107.4098115, 10.7746878],
                [107.4098503, 10.774672],
                [107.4099192, 10.7746252],
                [107.4099478, 10.7745951],
                [107.4100926, 10.7743527],
                [107.4101382, 10.7743028],
                [107.4102019, 10.7742791],
                [107.4102363, 10.774279],
                [107.4103014, 10.774303],
                [107.410348, 10.7743554],
                [107.4103636, 10.7744234],
                [107.4103426, 10.7745291],
                [107.4102826, 10.7747031],
                [107.4102831, 10.7747796],
                [107.4102956, 10.7748184],
                [107.4103436, 10.7748844],
                [107.4104298, 10.7749456],
                [107.4104932, 10.7749586],
                [107.4105567, 10.7749456],
                [107.4105855, 10.7749294],
                [107.4106298, 10.7748815],
                [107.4106434, 10.7748518],
                [107.4106505, 10.7747875],
                [107.4105835, 10.774574],
                [107.4105717, 10.7745024],
                [107.4105889, 10.7744317],
                [107.4106304, 10.7743747],
                [107.4106904, 10.7743369],
                [107.4107248, 10.774327],
                [107.4110931, 10.7742947],
                [107.4111951, 10.7742989],
                [107.4112916, 10.7743316],
                [107.4113398, 10.7743613],
                [107.4113825, 10.7743982],
                [107.4114186, 10.7744414],
                [107.4116436, 10.7748019],
                [107.4116797, 10.7748435],
                [107.4117212, 10.77488],
                [107.4118173, 10.774935],
                [107.4119353, 10.774964],
                [107.4119981, 10.7749322],
                [107.4120215, 10.7749058],
                [107.4120373, 10.7748744],
                [107.4120431, 10.7748072],
                [107.4120158, 10.7747453],
                [107.4117681, 10.774501],
                [107.4117138, 10.7743984],
                [107.4116993, 10.7743422],
                [107.4116953, 10.7742421],
                [107.4117181, 10.7741445],
                [107.4117564, 10.774079],
                [107.411818, 10.7740295],
                [107.411856, 10.7740135],
                [107.4119202, 10.7740042],
                [107.4119655, 10.7740177],
                [107.4120014, 10.774049],
                [107.4120135, 10.7740699],
                [107.4120366, 10.7742258],
                [107.4120507, 10.7742789],
                [107.4120779, 10.7743506],
                [107.4121137, 10.7744186],
                [107.4121477, 10.7744397],
                [107.4122251, 10.7744599],
                [107.4123045, 10.7744483],
                [107.412373, 10.7744066],
                [107.4123993, 10.7743764],
                [107.412512, 10.7742236],
                [107.4125399, 10.7741645],
                [107.4125461, 10.7741324],
                [107.4125419, 10.7740658],
                [107.412531, 10.7740332],
                [107.4124198, 10.7738656],
                [107.4123592, 10.7737545],
                [107.412353, 10.7736946],
                [107.4123591, 10.773665],
                [107.4123963, 10.7736037],
                [107.412458, 10.773566],
                [107.4124939, 10.7735589],
                [107.4125656, 10.7735701],
                [107.4126777, 10.773622],
                [107.4127278, 10.7736579],
                [107.4128124, 10.7737467],
                [107.4128669, 10.773768],
                [107.4129247, 10.7737577],
                [107.4129492, 10.7737414],
                [107.4129797, 10.7736943],
                [107.4129812, 10.7736385],
                [107.4128148, 10.7733107],
                [107.412791, 10.773217],
                [107.4127928, 10.7731322],
                [107.4128288, 10.7730002],
                [107.4128341, 10.7729533],
                [107.4128259, 10.7728486],
                [107.4127962, 10.7727196],
                [107.412791, 10.7726453],
                [107.4128114, 10.7725968],
                [107.4128526, 10.7725636],
                [107.4129091, 10.7725537],
                [107.4129373, 10.7725599],
                [107.4129841, 10.7725926],
                [107.4130023, 10.7726604],
                [107.4130178, 10.7727966],
                [107.4130071, 10.7729302],
                [107.4129821, 10.7730336],
                [107.4129814, 10.7731116],
                [107.412992, 10.7731533],
                [107.413035, 10.7732276],
                [107.4130663, 10.7732417],
                [107.4131343, 10.7732487],
                [107.4132021, 10.7732248],
                [107.413252, 10.7731738],
                [107.4133165, 10.7729654],
                [107.4133676, 10.7728271],
                [107.4134267, 10.7726922],
                [107.4135292, 10.7724912],
                [107.4135888, 10.7724397],
                [107.4136639, 10.7724143],
                [107.4137432, 10.7724186],
                [107.4138152, 10.7724522],
                [107.4138451, 10.7724788],
                [107.4138864, 10.7725464],
                [107.4138983, 10.7726243],
                [107.4138791, 10.7727008],
                [107.4137895, 10.7728388],
                [107.413714, 10.7729382],
                [107.4135622, 10.7731031],
                [107.413544, 10.7731305],
                [107.413532, 10.773161],
                [107.4135275, 10.7732227],
                [107.4135497, 10.7732857],
                [107.4135716, 10.7733167],
                [107.4136305, 10.7733646],
                [107.4136659, 10.77338],
                [107.4137036, 10.7733889],
                [107.4137807, 10.7733857],
                [107.4138385, 10.7733595],
                [107.4138813, 10.7733132],
                [107.4139032, 10.7732442],
                [107.4139094, 10.7731024],
                [107.4139409, 10.7730412],
                [107.4139671, 10.7730181],
                [107.4140294, 10.7729943],
                [107.414063, 10.7729938],
                [107.4143127, 10.7730476],
                [107.4143492, 10.7730458],
                [107.4144151, 10.7730163],
                [107.4144573, 10.7729621],
                [107.4144695, 10.7728981],
                [107.4144517, 10.7728354],
                [107.4142909, 10.7726602],
                [107.4142305, 10.7725774],
                [107.4141367, 10.7724069],
                [107.4141026, 10.7723212],
                [107.4140996, 10.7722857],
                [107.4141177, 10.7722171],
                [107.4141379, 10.7721875],
                [107.4141642, 10.7721631],
                [107.4142242, 10.7721353],
                [107.4142573, 10.7721311],
                [107.4143225, 10.7721425],
                [107.414352, 10.7721578],
                [107.4145049, 10.7722816],
                [107.4145731, 10.7722958],
                [107.414642, 10.7722853],
                [107.4146739, 10.7722711],
                [107.414708, 10.7722468],
                [107.414736, 10.7722158],
                [107.4147564, 10.7721796],
                [107.4147684, 10.7721399],
                [107.4147713, 10.7720986],
                [107.4147544, 10.7719882],
                [107.4147195, 10.7718521],
                [107.4146537, 10.7716947],
                [107.4145313, 10.7714883],
                [107.4145045, 10.7714249],
                [107.4144756, 10.7713256],
                [107.4144835, 10.771257],
                [107.4144989, 10.7712258],
                [107.414547, 10.7711781],
                [107.4146093, 10.7711553],
                [107.4146755, 10.7711598],
                [107.414734, 10.7711907],
                [107.4148936, 10.7713331],
                [107.4149551, 10.7713994],
                [107.4150628, 10.7715439],
                [107.4151176, 10.7716385],
                [107.4151638, 10.7717374],
                [107.4152011, 10.7718399],
                [107.4152292, 10.7719451],
                [107.4152479, 10.7720524],
                [107.4152762, 10.7723312],
                [107.4152729, 10.7725718],
                [107.4152425, 10.7728085],
                [107.4152261, 10.7728425],
                [107.4151786, 10.7728911],
                [107.4151227, 10.7729152],
                [107.4150476, 10.7729232],
                [107.4150044, 10.7729353],
                [107.4149287, 10.7729821],
                [107.414876, 10.773053],
                [107.4148607, 10.7730944],
                [107.4148549, 10.773182],
                [107.4148824, 10.7732653],
                [107.4149391, 10.7733329],
                [107.4150556, 10.7734051],
                [107.4152591, 10.7734964],
                [107.4153342, 10.7734989],
                [107.4153704, 10.7734882],
                [107.415428, 10.773449],
                [107.4154632, 10.7733955],
                [107.4154758, 10.773333],
                [107.4154758, 10.7727971],
                [107.4154967, 10.772747],
                [107.4155402, 10.7727138],
                [107.4155987, 10.7727068],
                [107.4156521, 10.7727312],
                [107.415708, 10.7727891],
                [107.4157897, 10.7728508],
                [107.4158496, 10.7728684],
                [107.4159104, 10.7728534],
                [107.4159509, 10.7728162],
                [107.4159697, 10.7727649],
                [107.415978, 10.7724762],
                [107.4160035, 10.772423],
                [107.4160498, 10.7723857],
                [107.4161118, 10.7723716],
                [107.4161736, 10.7723868],
                [107.4162, 10.7724045],
                [107.4163041, 10.7725133],
                [107.4163529, 10.7725478],
                [107.4164681, 10.7725994],
                [107.4165579, 10.7726178],
                [107.4166138, 10.7726111],
                [107.4166399, 10.7726005],
                [107.4166888, 10.772561],
                [107.4167177, 10.7725056],
                [107.4167472, 10.7723238],
                [107.4167714, 10.7722654],
                [107.4168143, 10.7722184],
                [107.4168425, 10.7722003],
                [107.4169066, 10.7721802],
                [107.417407, 10.7722131],
                [107.4177579, 10.7722128],
                [107.4178189, 10.7722181],
                [107.4179403, 10.7722461],
                [107.4179995, 10.772269],
                [107.4182256, 10.7723911],
                [107.4182916, 10.7723992],
                [107.4183536, 10.7723791],
                [107.4184003, 10.7723359],
                [107.4184155, 10.772308],
                [107.4184389, 10.7722011],
                [107.4184585, 10.7721499],
                [107.4185112, 10.7720558],
                [107.4188104, 10.7716679],
                [107.4188552, 10.7715892],
                [107.4188769, 10.7715017],
                [107.4188737, 10.7714113],
                [107.4188628, 10.7713673],
                [107.4188113, 10.7712557],
                [107.4188067, 10.7712239],
                [107.4188202, 10.7711615],
                [107.4188375, 10.7711343],
                [107.4188905, 10.7710943],
                [107.4189581, 10.771083],
                [107.418992, 10.7710899],
                [107.4190497, 10.7711264],
                [107.4192148, 10.7713383],
                [107.4193865, 10.771507],
                [107.419414, 10.7715454],
                [107.4194545, 10.7716303],
                [107.419467, 10.7716756],
                [107.4194748, 10.7717613],
                [107.4194884, 10.7718023],
                [107.419508, 10.7718409],
                [107.4195635, 10.7719075],
                [107.4196014, 10.771936],
                [107.4196887, 10.7719733],
                [107.4199256, 10.7719918],
                [107.4199701, 10.7720062],
                [107.4200467, 10.7720585],
                [107.4200946, 10.7721289],
                [107.4201139, 10.7722042],
                [107.4201074, 10.7722816],
                [107.4200643, 10.7724242],
                [107.4200424, 10.7725319],
                [107.4200249, 10.7727507],
                [107.4200281, 10.7728451],
                [107.420054, 10.7730323],
                [107.4201161, 10.7732616],
                [107.4201181, 10.77331],
                [107.4200955, 10.7734039],
                [107.4200461, 10.7734783],
                [107.4199814, 10.773529],
                [107.4199042, 10.7735583],
                [107.4198467, 10.7735593],
                [107.4197345, 10.7735825],
                [107.4196328, 10.773632],
                [107.4195477, 10.7737039],
                [107.4194584, 10.7738289],
                [107.4193962, 10.7738874],
                [107.4193204, 10.7739277],
                [107.4192078, 10.7739586],
                [107.4191831, 10.7739767],
                [107.4191508, 10.7740278],
                [107.4191471, 10.7740881],
                [107.4191732, 10.7741429],
                [107.4191958, 10.7741639],
                [107.4192672, 10.7741966],
                [107.4193462, 10.7742493],
                [107.4194072, 10.7743216],
                [107.4194476, 10.7744155],
                [107.4194568, 10.77447],
                [107.4194568, 10.7745252],
                [107.419375, 10.774911],
                [107.4193702, 10.7749592],
                [107.4193815, 10.7750551],
                [107.4194198, 10.7751451],
                [107.4194829, 10.7752215],
                [107.4195654, 10.7752773],
                [107.4196761, 10.775329],
                [107.4197372, 10.7753674],
                [107.4197946, 10.775411],
                [107.4198954, 10.7755107],
                [107.4199761, 10.7756244],
                [107.4202233, 10.7760338],
                [107.4202516, 10.7760703],
                [107.420324, 10.7761279],
                [107.4204111, 10.7761603],
                [107.42051, 10.7761633],
                [107.4206042, 10.7761339],
                [107.4208223, 10.7760339],
                [107.4208543, 10.776032],
                [107.4209139, 10.7760539],
                [107.4209517, 10.7761011],
                [107.4209601, 10.7761583],
                [107.4209529, 10.7761866],
                [107.4208564, 10.7763236],
                [107.4208537, 10.7763604],
                [107.4208673, 10.7764325],
                [107.4209049, 10.7764966],
                [107.4209316, 10.7765232],
                [107.4209968, 10.7765611],
                [107.4213391, 10.7766609],
                [107.4214316, 10.7766994],
                [107.4214715, 10.7767297],
                [107.4215279, 10.7767998],
                [107.4215455, 10.776836],
                [107.4215645, 10.7769138],
                [107.4215682, 10.7769724],
                [107.4215628, 10.7770308],
                [107.4215484, 10.7770877],
                [107.4214969, 10.7771879],
                [107.4214181, 10.777269],
                [107.4213188, 10.7773243],
                [107.4206266, 10.7775827],
                [107.4205987, 10.7776],
                [107.4205595, 10.7776514],
                [107.4205504, 10.7776825],
                [107.4205559, 10.7777465],
                [107.4205691, 10.7777738],
                [107.4206122, 10.7778165],
                [107.42064, 10.7778296],
                [107.4207008, 10.7778361],
                [107.4207658, 10.7778228],
                [107.420885, 10.7777837],
                [107.4211112, 10.7776701],
                [107.4211465, 10.7776605],
                [107.4212195, 10.7776631],
                [107.4212855, 10.7776938],
                [107.4213322, 10.7777449],
                [107.4213557, 10.7778096],
                [107.4213576, 10.777844],
                [107.4213284, 10.7780864],
                [107.4213406, 10.7781685],
                [107.4213792, 10.7782501],
                [107.4214099, 10.7782882],
                [107.4214719, 10.7783405],
                [107.4215011, 10.7783542],
                [107.4215326, 10.7783614],
                [107.4215966, 10.7783551],
                [107.4216561, 10.7783189],
                [107.4216777, 10.7782916],
                [107.4216986, 10.778226],
                [107.4216932, 10.7781233],
                [107.4216745, 10.7779798],
                [107.4216813, 10.7779475],
                [107.4216969, 10.7779184],
                [107.4217202, 10.7778947],
                [107.4217492, 10.7778782],
                [107.4217817, 10.7778703],
                [107.4218423, 10.7778798],
                [107.4218901, 10.7779175],
                [107.4219051, 10.7779441],
                [107.4219266, 10.7780574],
                [107.4219641, 10.778089],
                [107.4220052, 10.7780958],
                [107.422125, 10.7780785],
                [107.4222121, 10.7780908],
                [107.4222892, 10.7781325],
                [107.4223462, 10.7781978],
                [107.4223648, 10.7782366],
                [107.4223798, 10.7783209],
                [107.4224045, 10.7793094],
                [107.4224342, 10.7793691],
                [107.4224907, 10.779415],
                [107.4225281, 10.7794284],
                [107.4225676, 10.7794328],
                [107.4227473, 10.7794407],
                [107.4228053, 10.7794738],
                [107.4228439, 10.7795276],
                [107.4228527, 10.7795553],
                [107.4228541, 10.7796129],
                [107.4227978, 10.7797863],
                [107.4227887, 10.7798925],
                [107.4228011, 10.7800395],
                [107.4228323, 10.7801271],
                [107.4228531, 10.7801541],
                [107.4229082, 10.7801943],
                [107.4229426, 10.7802065],
                [107.4230152, 10.7802097],
                [107.4230832, 10.7801846],
                [107.4232293, 10.7800654],
                [107.4233749, 10.7799874],
                [107.4233966, 10.7799869],
                [107.4234366, 10.7800019],
                [107.4234648, 10.7800368],
                [107.4234706, 10.7800585],
                [107.423472, 10.7802115],
                [107.4234661, 10.7803418],
                [107.4234505, 10.7804856],
                [107.4234255, 10.7806281],
                [107.423391, 10.7807687],
                [107.4233668, 10.7809825],
                [107.4233669, 10.7810901],
                [107.4233848, 10.7811603],
                [107.4234038, 10.7811915],
                [107.4234581, 10.7812403],
                [107.4234947, 10.7812572],
                [107.4235745, 10.7812667],
                [107.4236142, 10.7812589],
                [107.4239103, 10.7811389],
                [107.4239817, 10.7811379],
                [107.4240159, 10.7811486],
                [107.4240733, 10.7811903],
                [107.4241079, 10.7812531],
                [107.4241115, 10.7813258],
                [107.4241009, 10.7813609],
                [107.4240574, 10.7814197],
                [107.423678, 10.7816619],
                [107.4236362, 10.7817025],
                [107.4235721, 10.7817991],
                [107.4235513, 10.7818532],
                [107.4235342, 10.7819653],
                [107.423549, 10.7820758],
                [107.423594, 10.7821782],
                [107.4236269, 10.7822238],
                [107.4238727, 10.7824847],
                [107.4240535, 10.7826526],
                [107.4241579, 10.7827353],
                [107.4242668, 10.7828123],
                [107.4244966, 10.7829481],
                [107.4246169, 10.7830065],
                [107.4248215, 10.7830918],
                [107.4249761, 10.783175],
                [107.4250486, 10.7832244],
                [107.4251801, 10.783335],
                [107.4251929, 10.7833688],
                [107.4251962, 10.7834404],
                [107.4251715, 10.7835042],
                [107.4251237, 10.7835537],
                [107.4250948, 10.7836151],
                [107.4250942, 10.7836828],
                [107.4251059, 10.7837175],
                [107.4251252, 10.7837487],
                [107.4251826, 10.7837945],
                [107.4252176, 10.7838067],
                [107.4254161, 10.7838409],
                [107.4254775, 10.7838707],
                [107.4255204, 10.7839231],
                [107.4255368, 10.7839885],
                [107.4255248, 10.7840517],
                [107.4255092, 10.7840801],
                [107.4254618, 10.7841246],
                [107.4254322, 10.7841387],
                [107.4252203, 10.7841466],
                [107.4251894, 10.7841524],
                [107.4251346, 10.7841822],
                [107.4250972, 10.7842314],
                [107.4250835, 10.7842915],
                [107.4250967, 10.784353],
                [107.425135, 10.7844035],
                [107.4251615, 10.7844215],
                [107.425223, 10.784439],
                [107.4254429, 10.784439],
                [107.4256552, 10.7844108],
                [107.4258655, 10.7843535],
                [107.4259767, 10.7843494],
                [107.4260236, 10.7843566],
                [107.4261135, 10.7843863],
                [107.4261326, 10.7844031],
                [107.4261586, 10.7844461],
                [107.4261646, 10.7845014],
                [107.426157, 10.7845312],
                [107.426121, 10.7845809],
                [107.4259633, 10.7846597],
                [107.4259175, 10.7846938],
                [107.4258392, 10.7847764],
                [107.425782, 10.7848741],
                [107.4257487, 10.7849818],
                [107.4256971, 10.7850868],
                [107.4256198, 10.7851823],
                [107.425518, 10.7852611],
                [107.4254592, 10.7853346],
                [107.4254404, 10.7853779],
                [107.4254274, 10.7854705],
                [107.4254477, 10.7855619],
                [107.4254864, 10.7856362],
                [107.425509, 10.785665],
                [107.4255689, 10.7857075],
                [107.4256458, 10.785725],
                [107.4256879, 10.7857214],
                [107.4257282, 10.7857088],
                [107.4258132, 10.7856505],
                [107.4258738, 10.7856399],
                [107.4259312, 10.785658],
                [107.4259544, 10.785676],
                [107.4259852, 10.7857252],
                [107.4260062, 10.7859461],
                [107.4260491, 10.7861042],
                [107.4260861, 10.7861875],
                [107.426092, 10.7862781],
                [107.4260828, 10.7863231],
                [107.4260415, 10.7864049],
                [107.4260105, 10.7864393],
                [107.4259177, 10.7865164],
                [107.4258829, 10.7865817],
                [107.4258753, 10.7866179],
                [107.4258807, 10.7866913],
                [107.4259138, 10.7867594],
                [107.4259402, 10.7867883],
                [107.4260075, 10.7868286],
                [107.4260457, 10.7868385],
                [107.4261244, 10.7868358],
                [107.4261962, 10.7868042],
                [107.4262726, 10.7867426],
                [107.426375, 10.7866844],
                [107.4264826, 10.7866476],
                [107.4265909, 10.7866312],
                [107.4266247, 10.7866321],
                [107.4266879, 10.7866541],
                [107.4267147, 10.7866743],
                [107.4267529, 10.7867286],
                [107.4267653, 10.7867963],
                [107.4267597, 10.7868319],
                [107.4267252, 10.786895],
                [107.426698, 10.7869192],
                [107.4266051, 10.7869631],
                [107.4264756, 10.7869922],
                [107.4264289, 10.7870164],
                [107.4263866, 10.7870474],
                [107.426321, 10.7871227],
                [107.4262818, 10.7872092],
                [107.4262521, 10.7873282],
                [107.4261731, 10.7875332],
                [107.426171, 10.7875679],
                [107.4261892, 10.7876346],
                [107.4262342, 10.7876878],
                [107.4262667, 10.7877072],
                [107.4263028, 10.7877188],
                [107.4263783, 10.7877165],
                [107.4264448, 10.7876813],
                [107.4267048, 10.7874268],
                [107.4267679, 10.7873895],
                [107.4268035, 10.7873799],
                [107.4268734, 10.7873797],
                [107.426936, 10.7874011],
                [107.4270065, 10.787438],
                [107.4270523, 10.787448],
                [107.4271368, 10.7874419],
                [107.4272132, 10.7874058],
                [107.4272963, 10.7873768],
                [107.4274103, 10.7873591],
                [107.427526, 10.7873633],
                [107.4276392, 10.7873898],
                [107.4277878, 10.7874399],
                [107.4278864, 10.787448],
                [107.4279471, 10.7874424],
                [107.4280635, 10.7874079],
                [107.4283028, 10.7872584],
                [107.4283463, 10.7872538],
                [107.4283863, 10.7872714],
                [107.4284127, 10.787309],
                [107.4284171, 10.787333],
                [107.4284041, 10.7873795],
                [107.4283439, 10.7874604],
                [107.4282193, 10.7875427],
                [107.4281642, 10.7875892],
                [107.4281447, 10.7876335],
                [107.4281435, 10.7876577],
                [107.4281587, 10.7877036],
                [107.428197, 10.7877391],
                [107.4282482, 10.7877512],
                [107.4282988, 10.7877369],
                [107.4285006, 10.7876193],
                [107.4285331, 10.7876075],
                [107.4286019, 10.7876062],
                [107.4286639, 10.7876335],
                [107.4286886, 10.7876561],
                [107.4287206, 10.7877141],
                [107.4287341, 10.7879361],
                [107.4287555, 10.7879802],
                [107.4287728, 10.7879975],
                [107.4288168, 10.7880191],
                [107.4290114, 10.7880579],
                [107.4290468, 10.7880774],
                [107.4291015, 10.7881357],
                [107.4291252, 10.7881981],
                [107.4291307, 10.7883343],
                [107.4291645, 10.7884695],
                [107.4292322, 10.7886004],
                [107.4292799, 10.788662],
                [107.4293349, 10.7887172],
                [107.4296218, 10.788934],
                [107.4297161, 10.7889633],
                [107.4298149, 10.7889656],
                [107.4298711, 10.7889546],
                [107.4299248, 10.7889347],
                [107.4299744, 10.7889065],
                [107.4300879, 10.788815],
                [107.4302117, 10.7886883],
                [107.4303138, 10.7885441],
                [107.4303617, 10.7884546],
                [107.4304011, 10.7883613],
                [107.4304318, 10.7882648],
                [107.4304414, 10.7882019],
                [107.4304566, 10.7881737],
                [107.4305041, 10.7881305],
                [107.4305659, 10.788112],
                [107.4305992, 10.7881134],
                [107.4306604, 10.7881381],
                [107.430704, 10.7881869],
                [107.4307458, 10.7883033],
                [107.4307692, 10.7883911],
                [107.4307966, 10.7885704],
                [107.4308005, 10.7886696],
                [107.4307846, 10.7888675],
                [107.4307147, 10.7891608],
                [107.4307138, 10.7892123],
                [107.4307335, 10.7893133],
                [107.4307809, 10.7894062],
                [107.4308533, 10.7894836],
                [107.430897, 10.7895141],
                [107.4309951, 10.7895558],
                [107.431083, 10.7896238],
                [107.4311475, 10.7897141],
                [107.4312089, 10.7899002],
                [107.4312309, 10.7899368],
                [107.4312901, 10.7899985],
                [107.4313282, 10.7900232],
                [107.4314138, 10.7900537],
                [107.431591, 10.7900596],
                [107.4317142, 10.790075],
                [107.4317509, 10.7900845],
                [107.4318159, 10.7901223],
                [107.4318598, 10.7901759],
                [107.4318829, 10.7902409],
                [107.4318818, 10.7903245],
                [107.4318482, 10.7904202],
                [107.431853, 10.7904792],
                [107.4318829, 10.7905307],
                [107.4319411, 10.790568],
                [107.4320105, 10.7905728],
                [107.4321047, 10.790539],
                [107.4322209, 10.7904806],
                [107.4323404, 10.790398],
                [107.4325159, 10.790233],
                [107.4325441, 10.7902137],
                [107.4326096, 10.7901955],
                [107.4326439, 10.7901974],
                [107.4327095, 10.7902245],
                [107.4327369, 10.7902496],
                [107.4327698, 10.790315],
                [107.4327734, 10.7904648],
                [107.4327459, 10.7906422],
                [107.4327, 10.7907966],
                [107.4326865, 10.7908663],
                [107.4326789, 10.791008],
                [107.4326969, 10.7911466],
                [107.4327148, 10.7912132],
                [107.432768, 10.7913404],
                [107.4328378, 10.7914587],
                [107.4328794, 10.791513],
                [107.4329799, 10.7916057],
                [107.4330947, 10.7916715],
                [107.4331569, 10.7916947],
                [107.4332595, 10.7917147],
                [107.4333354, 10.7917054],
                [107.4334067, 10.791674],
                [107.4334665, 10.791622],
                [107.4335944, 10.7914272],
                [107.4336151, 10.79141],
                [107.4336405, 10.7914005],
                [107.4336676, 10.7913998],
                [107.4336934, 10.7914081],
                [107.4337251, 10.7914377],
                [107.4337363, 10.7914792],
                [107.4337347, 10.7915822],
                [107.4336979, 10.7917335],
                [107.4337095, 10.7917849],
                [107.4337252, 10.7918097],
                [107.4337722, 10.791845],
                [107.4338301, 10.7918553],
                [107.4338592, 10.7918508],
                [107.4340153, 10.7917928],
                [107.4340767, 10.7917987],
                [107.4341242, 10.7918339],
                [107.4341464, 10.7918856],
                [107.434141, 10.7920665],
                [107.4341333, 10.7921168],
                [107.4340935, 10.792234],
                [107.4339662, 10.7924256],
                [107.4339486, 10.7924756],
                [107.4339402, 10.7925279],
                [107.4339498, 10.7926279],
                [107.4339923, 10.7927208],
                [107.4340248, 10.7927615],
                [107.4340635, 10.7927966],
                [107.4341708, 10.7928651],
                [107.4342543, 10.7929489],
                [107.4343673, 10.7931287],
                [107.4343888, 10.7931486],
                [107.434442, 10.7931727],
                [107.4345038, 10.7931724],
                [107.4345346, 10.7931613],
                [107.4345841, 10.7931198],
                [107.4346748, 10.7929679],
                [107.4347717, 10.7928598],
                [107.4348852, 10.7927725],
                [107.4349476, 10.7927369],
                [107.4350879, 10.7926824],
                [107.4351651, 10.7926666],
                [107.4352437, 10.7926596],
                [107.435383, 10.7926694],
                [107.4355173, 10.792707],
                [107.435567, 10.792729],
                [107.4356562, 10.79279],
                [107.4357315, 10.792877],
                [107.4357614, 10.7929304],
                [107.4357968, 10.7930468],
                [107.4358016, 10.7931075],
                [107.4357838, 10.7934189],
                [107.4357758, 10.7934446],
                [107.4357426, 10.7934869],
                [107.4356858, 10.7935109],
                [107.4356546, 10.7935109],
                [107.4355134, 10.7934775],
                [107.4353993, 10.793479],
                [107.4352867, 10.7935081],
                [107.4351847, 10.7935633],
                [107.4349701, 10.7938795],
                [107.4349477, 10.7939326],
                [107.4349487, 10.7939902],
                [107.4349781, 10.794049],
                [107.4350023, 10.7940718],
                [107.4350935, 10.7941219],
                [107.4351633, 10.7942036],
                [107.4352705, 10.7942958],
                [107.4353859, 10.7943564],
                [107.4355909, 10.7944227],
                [107.4356595, 10.794467],
                [107.4357086, 10.7945271],
                [107.4357657, 10.7946601],
                [107.4358172, 10.794708],
                [107.4358856, 10.7947336],
                [107.4361047, 10.7947358],
                [107.436183, 10.7947523],
                [107.43625, 10.7947955],
                [107.4362963, 10.7948587],
                [107.4363165, 10.7949332],
                [107.4363086, 10.7950098],
                [107.4360484, 10.7957581],
                [107.4360463, 10.7958863],
                [107.436073, 10.7960118],
                [107.4361313, 10.7961355],
                [107.4361737, 10.7961946],
                [107.4362233, 10.7962482],
                [107.4362793, 10.7962952],
                [107.4363407, 10.7963351],
                [107.4364921, 10.7964047],
                [107.4365723, 10.7964279],
                [107.4368746, 10.7964879],
                [107.4369158, 10.7965141],
                [107.4369306, 10.7965337],
                [107.4369442, 10.7965801],
                [107.4369344, 10.7966281],
                [107.436921, 10.796649],
                [107.4368812, 10.7966786],
                [107.4367806, 10.7966987],
                [107.4367385, 10.7967264],
                [107.4367136, 10.7967698],
                [107.4367093, 10.7967994],
                [107.4367255, 10.7968563],
                [107.4367449, 10.7968793],
                [107.4368061, 10.7969143],
                [107.4368849, 10.7969329],
                [107.4369726, 10.7969356],
                [107.4370649, 10.7969542],
                [107.4371157, 10.7969731],
                [107.4372089, 10.7970275],
                [107.43725, 10.7970623],
                [107.4374807, 10.7973916],
                [107.4375012, 10.797475],
                [107.4374967, 10.7975617],
                [107.4374849, 10.797604],
                [107.4374431, 10.7976814],
                [107.4374241, 10.7977323],
                [107.4374109, 10.7978395],
                [107.4374159, 10.7978865],
                [107.4374446, 10.7979764],
                [107.437468, 10.7980177],
                [107.4375514, 10.7981217],
                [107.4375683, 10.7981612],
                [107.4375772, 10.7982031],
                [107.4375725, 10.7982806],
                [107.4375414, 10.7983521],
                [107.4374875, 10.7984089],
                [107.4372244, 10.7985404],
                [107.437202, 10.7985584],
                [107.4371836, 10.7985803],
                [107.4371615, 10.7986325],
                [107.4371641, 10.7986995],
                [107.4371771, 10.7987307],
                [107.4372232, 10.7987801],
                [107.4374188, 10.7988921],
                [107.4376255, 10.798983],
                [107.4379476, 10.7990817],
                [107.4380255, 10.7991278],
                [107.4380869, 10.7991937],
                [107.4381138, 10.7992409],
                [107.438132, 10.7992919],
                [107.438141, 10.7993452],
                [107.4381727, 10.7999578],
                [107.4381701, 10.8000265],
                [107.4381792, 10.8000599],
                [107.4382163, 10.8001181],
                [107.4382428, 10.8001408],
                [107.4383068, 10.8001686],
                [107.4383855, 10.8001682],
                [107.4384229, 10.8001552],
                [107.4384843, 10.8001067],
                [107.4385053, 10.8000737],
                [107.438693, 10.7998735],
                [107.4387863, 10.7998086],
                [107.4388938, 10.7997707],
                [107.4389505, 10.7997628],
                [107.439002, 10.7997623],
                [107.4391033, 10.7997797],
                [107.4391973, 10.7998208],
                [107.4393851, 10.7999631],
                [107.4395218, 10.8000996],
                [107.4396422, 10.800248],
                [107.4397176, 10.8003056],
                [107.4398029, 10.8003425],
                [107.439994, 10.8003713],
                [107.4400878, 10.8004057],
                [107.4401343, 10.8004325],
                [107.4402158, 10.8005014],
                [107.440296, 10.8006152],
                [107.4403453, 10.8006586],
                [107.4404102, 10.8006821],
                [107.4405113, 10.8006813],
                [107.4405706, 10.8007034],
                [107.4406206, 10.8007548],
                [107.4406488, 10.800822],
                [107.4406913, 10.8008826],
                [107.4407541, 10.8009301],
                [107.4408555, 10.8009789],
                [107.4409168, 10.8010344],
                [107.4409434, 10.8010723],
                [107.4409837, 10.801103],
                [107.4410368, 10.8011158],
                [107.4410659, 10.8011131],
                [107.4412546, 10.801067],
                [107.4412907, 10.8010654],
                [107.4413264, 10.8010708],
                [107.4413913, 10.8011013],
                [107.4414157, 10.8011226],
                [107.4414511, 10.8011761],
                [107.4414611, 10.8012067],
                [107.4414932, 10.8014212],
                [107.441523, 10.8014976],
                [107.4415754, 10.8015611],
                [107.4416469, 10.8016062],
                [107.4416879, 10.8016198],
                [107.4417741, 10.8016267],
                [107.4418579, 10.8016059],
                [107.4419305, 10.8015597],
                [107.4421869, 10.8013396],
                [107.4422372, 10.8013282],
                [107.4422872, 10.801341],
                [107.4423296, 10.8013819],
                [107.4423435, 10.8014385],
                [107.4422711, 10.801702],
                [107.4422648, 10.8017459],
                [107.4422762, 10.8018336],
                [107.442318, 10.8019119],
                [107.4423821, 10.801969],
                [107.4424585, 10.8020009],
                [107.4425412, 10.8020068],
                [107.4426665, 10.8019735],
                [107.442711, 10.8019733],
                [107.4427491, 10.8019804],
                [107.4428185, 10.8020135],
                [107.4428719, 10.8020682],
                [107.4429685, 10.8022711],
                [107.4430247, 10.8023611],
                [107.4430865, 10.8024476],
                [107.4432764, 10.8026727],
                [107.4433679, 10.8027477],
                [107.443429, 10.8027822],
                [107.4434942, 10.8028085],
                [107.4435804, 10.8028247],
                [107.4436196, 10.8028445],
                [107.4436543, 10.8028712],
                [107.4437052, 10.8029415],
                [107.4437255, 10.8030225],
                [107.4437156, 10.8031025],
                [107.4436999, 10.8031399],
                [107.4436498, 10.8032037],
                [107.4435425, 10.8033882],
                [107.4435331, 10.803428],
                [107.4435413, 10.803509],
                [107.4435837, 10.803579],
                [107.4436155, 10.8036057],
                [107.4436927, 10.8036358],
                [107.4440751, 10.8036391],
                [107.4441433, 10.8036595],
                [107.4441809, 10.8036827],
                [107.4442131, 10.8037128],
                [107.4442559, 10.8037886],
                [107.4443671, 10.8043176],
                [107.4444032, 10.8043695],
                [107.4444303, 10.8043867],
                [107.4444826, 10.8043982],
                [107.4445678, 10.8043807],
                [107.4446344, 10.8043847],
                [107.4450526, 10.8045527],
                [107.4451201, 10.8045901],
                [107.4452452, 10.8046799],
                [107.445353, 10.8047863],
                [107.4454415, 10.8049057],
                [107.4454831, 10.8049357],
                [107.445508, 10.8049429],
                [107.4455595, 10.80494],
                [107.4456059, 10.8049134],
                [107.445623, 10.8048928],
                [107.4456346, 10.8048688],
                [107.4456402, 10.8048145],
                [107.4456346, 10.8047055],
                [107.4455986, 10.8045795],
                [107.4455692, 10.8045207],
                [107.4454898, 10.8044157],
                [107.445433, 10.8043754],
                [107.4454125, 10.8043473],
                [107.4453986, 10.8043156],
                [107.4453916, 10.8042569],
                [107.4454066, 10.8041996],
                [107.4454749, 10.8040696],
                [107.4455194, 10.8040109],
                [107.4455914, 10.8039367],
                [107.4456452, 10.8039124],
                [107.4457071, 10.8039148],
                [107.4457371, 10.8039274],
                [107.445783, 10.8039725],
                [107.4457959, 10.8040019],
                [107.4458358, 10.8043038],
                [107.4458653, 10.8043472],
                [107.445915, 10.8043757],
                [107.4459436, 10.8043806],
                [107.4460272, 10.804371],
                [107.4461324, 10.8043385],
                [107.4462218, 10.8042858],
                [107.4463071, 10.8041949],
                [107.4463575, 10.8041733],
                [107.4463923, 10.8041707],
                [107.4464583, 10.8041918],
                [107.4466337, 10.8043208],
                [107.4467233, 10.8043501],
                [107.4468175, 10.8043551],
                [107.4469229, 10.8043306],
                [107.4469716, 10.8043068],
                [107.4470159, 10.804276],
                [107.4471715, 10.8041259],
                [107.4472442, 10.8040934],
                [107.4472842, 10.804089],
                [107.4473565, 10.8041024],
                [107.4475363, 10.8042207],
                [107.4476489, 10.804265],
                [107.4477543, 10.8042851],
                [107.4478011, 10.8042851],
                [107.4478909, 10.8042664],
                [107.4479707, 10.8042252],
                [107.4480057, 10.804197],
                [107.4482389, 10.8038609],
                [107.4482959, 10.8038197],
                [107.4483293, 10.803808],
                [107.4484, 10.8038044],
                [107.4484722, 10.8038307],
                [107.4485271, 10.8038839],
                [107.4485448, 10.8039177],
                [107.4485441, 10.8039553],
                [107.4485609, 10.8040284],
                [107.4486377, 10.8041495],
                [107.4486391, 10.8041983],
                [107.4486308, 10.804222],
                [107.4484804, 10.804392],
                [107.4484629, 10.8044513],
                [107.4484674, 10.8044821],
                [107.4485009, 10.8045342],
                [107.4485273, 10.8045512],
                [107.4485891, 10.804561],
                [107.4486467, 10.8045369],
                [107.4486994, 10.8044895],
                [107.4487197, 10.8044605],
                [107.448747, 10.8043895],
                [107.4487486, 10.8043077],
                [107.4487571, 10.8042816],
                [107.4487916, 10.8042392],
                [107.4488463, 10.8042169],
                [107.4489051, 10.8042245],
                [107.448931, 10.8042392],
                [107.4490088, 10.8043419],
                [107.4490542, 10.8043795],
                [107.4491107, 10.8043972],
                [107.4491791, 10.8043895],
                [107.4492371, 10.804353],
                [107.4492583, 10.8043261],
                [107.4493534, 10.8041053],
                [107.4493732, 10.8040752],
                [107.449431, 10.8040325],
                [107.449466, 10.8040223],
                [107.449537, 10.8040267],
                [107.4495975, 10.804061],
                [107.4496203, 10.8040873],
                [107.4496458, 10.8041493],
                [107.4496575, 10.8042239],
                [107.4496577, 10.8042906],
                [107.4496655, 10.8043131],
                [107.4497008, 10.8043445],
                [107.4497476, 10.8043477],
                [107.4497692, 10.8043379],
                [107.449829, 10.80426],
                [107.4499074, 10.8041917],
                [107.4499471, 10.8041685],
                [107.4500619, 10.8041226],
                [107.4501112, 10.8040837],
                [107.4501565, 10.8040116],
                [107.4501675, 10.8039704],
                [107.4501756, 10.8038677],
                [107.4502158, 10.8038044],
                [107.4502478, 10.8037743],
                [107.4502849, 10.8037503],
                [107.4505028, 10.8036543],
                [107.4506423, 10.8035647],
                [107.4507134, 10.8035332],
                [107.4507893, 10.8035293],
                [107.4508597, 10.8035514],
                [107.4509179, 10.8035961],
                [107.45105, 10.8037597],
                [107.4511119, 10.8038036],
                [107.4511841, 10.8038282],
                [107.4512633, 10.8038308],
                [107.4513396, 10.80381],
                [107.4513745, 10.8037913],
                [107.4516814, 10.8035643],
                [107.4517469, 10.8035388],
                [107.4517822, 10.8035357],
                [107.4518196, 10.8035397],
                [107.4518881, 10.8035693],
                [107.4519163, 10.8035937],
                [107.4520612, 10.8037676],
                [107.4521249, 10.8038055],
                [107.452161, 10.8038153],
                [107.4522355, 10.803815],
                [107.4523074, 10.8037869],
                [107.4523635, 10.8037347],
                [107.4525547, 10.8034092],
                [107.4526191, 10.8033565],
                [107.452792, 10.8032871],
                [107.4528712, 10.8032327],
                [107.452937, 10.8031602],
                [107.4530302, 10.8029801],
                [107.4531047, 10.8029049],
                [107.4531501, 10.802877],
                [107.4532521, 10.8028444],
                [107.4533594, 10.8028454],
                [107.4536222, 10.8029245],
                [107.4537086, 10.8029386],
                [107.4537939, 10.8029192],
                [107.4538605, 10.802874],
                [107.4538866, 10.8028433],
                [107.4539065, 10.8028085],
                [107.453979, 10.8026188],
                [107.4540084, 10.8025649],
                [107.454046, 10.8025161],
                [107.4541576, 10.8024234],
                [107.4541838, 10.8023941],
                [107.454218, 10.8023252],
                [107.4542659, 10.8017942],
                [107.4542777, 10.8017143],
                [107.4543181, 10.8015816],
                [107.4543706, 10.801478],
                [107.4549955, 10.8005954],
                [107.4552771, 10.7997286],
                [107.455481, 10.7993597],
                [107.45554, 10.7992227],
                [107.4556073, 10.7991281],
                [107.4556473, 10.7990857],
                [107.4557403, 10.7990248],
                [107.4557921, 10.799004],
                [107.4558844, 10.7989848],
                [107.4560189, 10.7989775],
                [107.4561011, 10.798948],
                [107.4561728, 10.7988957],
                [107.4562027, 10.7988617],
                [107.4562455, 10.7987826],
                [107.4563862, 10.7983862],
                [107.4564962, 10.7979673],
                [107.4568301, 10.7975075],
                [107.4573062, 10.7970096],
                [107.457667, 10.7963667],
                [107.4577219, 10.7962055],
                [107.4577384, 10.796122],
                [107.457749, 10.7959535],
                [107.4577432, 10.7958698],
                [107.4577099, 10.7957054],
                [107.4576939, 10.7955476],
                [107.4577021, 10.7953614],
                [107.4577201, 10.7952558],
                [107.4577475, 10.7951521],
                [107.4581045, 10.7940227],
                [107.4581319, 10.793877],
                [107.4581346, 10.7938028],
                [107.4581176, 10.7936555],
                [107.458074, 10.7935198],
                [107.4580053, 10.7933944],
                [107.4579138, 10.793284],
                [107.457903, 10.7926754],
                [107.4579298, 10.7923118],
                [107.4579244, 10.7922267],
                [107.4579138, 10.7921853],
                [107.4578758, 10.7921053],
                [107.4578494, 10.7920694],
                [107.4574256, 10.7916241],
                [107.4574073, 10.791596],
                [107.4573895, 10.7915319],
                [107.4573988, 10.7914661],
                [107.457436, 10.7914065],
                [107.4574949, 10.7913673],
                [107.4575651, 10.7913554],
                [107.4592978, 10.7914661],
                [107.4599844, 10.7915978],
                [107.4618112, 10.7917087],
                [107.4621697, 10.7917508],
                [107.4623689, 10.7917849],
                [107.4626881, 10.7918007],
                [107.4629858, 10.7918718],
                [107.4653126, 10.7919746],
                [107.465439, 10.7919892],
                [107.4656895, 10.7920325],
                [107.4659538, 10.7920994],
                [107.467398, 10.792599],
                [107.4678259, 10.7926794],
                [107.4681973, 10.7928309],
                [107.4695116, 10.7932261],
                [107.4701426, 10.7934028],
                [107.4702474, 10.7934433],
                [107.4703485, 10.7934922],
                [107.4704449, 10.793549],
                [107.4705762, 10.7936423],
                [107.4706677, 10.7936792],
                [107.4707664, 10.7936854],
                [107.4708726, 10.7936651],
                [107.4709895, 10.7936687],
                [107.471093, 10.7937038],
                [107.471176, 10.7937553],
                [107.4712551, 10.7937899],
                [107.4713125, 10.7938038],
                [107.4714304, 10.7938064],
                [107.4714884, 10.7937952],
                [107.4716547, 10.7937082],
                [107.4717835, 10.7936118],
                [107.471842, 10.7935567],
                [107.4719876, 10.7933945],
                [107.4721509, 10.7931734],
                [107.4722276, 10.7930461],
                [107.4722956, 10.7929142],
                [107.472454, 10.7925391],
                [107.4725157, 10.7924251],
                [107.472623, 10.7922618],
                [107.47269, 10.7922038],
                [107.4728396, 10.7921254],
                [107.4729642, 10.7920292],
                [107.4734116, 10.7915635],
                [107.4737227, 10.791358],
                [107.4737442, 10.7910682],
                [107.4737556, 10.7910094],
                [107.4737987, 10.7908967],
                [107.4739587, 10.7906335],
                [107.4739968, 10.7905411],
                [107.4740569, 10.7903353],
                [107.4740998, 10.7900976],
                [107.4741308, 10.7899744],
                [107.4742162, 10.789735],
                [107.4743178, 10.7895317],
                [107.4743771, 10.7894346],
                [107.4746132, 10.7891079],
                [107.4746903, 10.7889736],
                [107.4747205, 10.7889024],
                [107.4747491, 10.7888131],
                [107.4747689, 10.7887215],
                [107.4747882, 10.7885199],
                [107.4748062, 10.7884125],
                [107.4748331, 10.7883069],
                [107.4748692, 10.7882032],
                [107.474914, 10.7881029],
                [107.4749961, 10.7879524],
                [107.4750337, 10.7878361],
                [107.4750431, 10.7877143],
                [107.475037, 10.7876535],
                [107.4749995, 10.7875268],
                [107.474969, 10.7874679],
                [107.4748868, 10.7873637],
                [107.4745823, 10.7871607],
                [107.4745069, 10.7870731],
                [107.4744619, 10.7869672],
                [107.4744523, 10.7869105],
                [107.4744516, 10.7868515],
                [107.4744603, 10.7867932],
                [107.4744782, 10.7867369],
                [107.4745049, 10.786684],
                [107.4745396, 10.7866359],
                [107.4745816, 10.7865937],
                [107.4746298, 10.7865585],
                [107.4746829, 10.7865311],
                [107.4747642, 10.786504],
                [107.474842, 10.7864683],
                [107.4749153, 10.7864244],
                [107.4750414, 10.7863185],
                [107.475139, 10.7861935],
                [107.4751944, 10.7860884],
                [107.4752271, 10.7859846],
                [107.4752369, 10.7859159],
                [107.4752283, 10.7856404],
                [107.4752355, 10.7855035],
                [107.4752683, 10.7852705],
                [107.4752945, 10.7851557],
                [107.4754983, 10.7847236],
                [107.4755292, 10.7846271],
                [107.4755681, 10.7844285],
                [107.4755758, 10.7842281],
                [107.4755681, 10.7841281],
                [107.4755803, 10.7840506],
                [107.4755955, 10.7840142],
                [107.4756445, 10.7839484],
                [107.4757153, 10.7839003],
                [107.4760777, 10.7837803],
                [107.4761769, 10.7837284],
                [107.4762748, 10.7836503],
                [107.476362, 10.7835432],
                [107.4766409, 10.7830531],
                [107.4768287, 10.782895],
                [107.4767858, 10.7820202],
                [107.4765417, 10.7814142],
                [107.4765272, 10.7813464],
                [107.4765335, 10.781312],
                [107.4765485, 10.7812804],
                [107.4765974, 10.7812346],
                [107.4766595, 10.781217],
                [107.4767459, 10.7812176],
                [107.476851, 10.7811942],
                [107.4769467, 10.7811455],
                [107.4770542, 10.7810643],
                [107.4771001, 10.7810151],
                [107.4771703, 10.780908],
                [107.4772135, 10.7807941],
                [107.4772257, 10.7807344],
                [107.477475, 10.7805305],
                [107.4775261, 10.7804551],
                [107.4775475, 10.7803718],
                [107.4775405, 10.7802304],
                [107.4775475, 10.7800335],
                [107.4775749, 10.779871],
                [107.4776173, 10.7797328],
                [107.4776389, 10.7796108],
                [107.4776306, 10.7794837],
                [107.4775904, 10.779359],
                [107.4775824, 10.7793137],
                [107.4775851, 10.779222],
                [107.4776172, 10.7791241],
                [107.478025, 10.7785053],
                [107.4787974, 10.7779625],
                [107.4793607, 10.7769929],
                [107.4797255, 10.7765713],
                [107.4798515, 10.7762604],
                [107.4799506, 10.7762307],
                [107.4799964, 10.7762066],
                [107.4800763, 10.7761418],
                [107.4801382, 10.7760552],
                [107.4801602, 10.7760046],
                [107.4801815, 10.7758968],
                [107.4805704, 10.7756543],
                [107.483561, 10.7701895],
                [107.4849772, 10.7685505],
                [107.4868441, 10.7687877],
                [107.4906528, 10.7689669],
                [107.4934525, 10.7679891],
                [107.4936517, 10.7679441],
                [107.4938425, 10.7679319],
                [107.4940199, 10.7679468],
                [107.4941933, 10.7679867],
                [107.494965, 10.7682159],
                [107.4950516, 10.7682199],
                [107.4950945, 10.7682133],
                [107.4951922, 10.7681738],
                [107.4952348, 10.7681427],
                [107.4957409, 10.7676309],
                [107.4973705, 10.7679442],
                [107.4975312, 10.7679831],
                [107.497846, 10.7680835],
                [107.4979994, 10.7681448],
                [107.4982656, 10.7682721],
                [107.4985197, 10.7684214],
                [107.4997133, 10.7694991],
                [107.5006199, 10.7698048],
                [107.5011241, 10.7705637],
                [107.502256, 10.7711592],
                [107.5052501, 10.7715976],
                [107.5070679, 10.7720656],
                [107.5107561, 10.7723711],
                [107.5154263, 10.7727813],
                [107.5170189, 10.7727349],
                [107.5174215, 10.7727341],
                [107.5178236, 10.7727559],
                [107.5180558, 10.7727789],
                [107.5185174, 10.7728475],
                [107.5187463, 10.772893],
                [107.5215733, 10.7735201],
                [107.5239605, 10.7741419],
                [107.5244946, 10.7743007],
                [107.5250205, 10.774484],
                [107.5255671, 10.774704],
                [107.5258507, 10.7748307],
                [107.5264075, 10.7751057],
                [107.5268956, 10.7753757],
                [107.5278119, 10.7758825],
                [107.5291049, 10.7765977],
                [107.5321841, 10.7782524],
                [107.5329459, 10.7787161],
                [107.5335781, 10.7791265],
                [107.5388467, 10.7827949],
                [107.5437069, 10.7859883],
                [107.5453551, 10.7871511],
                [107.5493985, 10.7900037],
                [107.5540924, 10.7933868],
                [107.5558144, 10.7946726],
                [107.5562221, 10.7950678],
                [107.5610769, 10.7985087],
                [107.5627372, 10.7999684],
                [107.5656715, 10.8041601],
                [107.5658458, 10.8050269],
                [107.5661784, 10.8060703],
                [107.5667638, 10.8069734],
                [107.5667953, 10.8070596],
                [107.5668071, 10.8071245],
                [107.5668114, 10.8072927],
                [107.5668294, 10.8073709],
                [107.5668472, 10.8074071],
                [107.5669536, 10.8075562],
                [107.5670202, 10.8075952],
                [107.5670963, 10.8076042],
                [107.5671689, 10.8075826],
                [107.5672563, 10.807527],
                [107.5673211, 10.807514],
                [107.5673589, 10.8075171],
                [107.5674288, 10.8075453],
                [107.5676054, 10.8076985],
                [107.567637, 10.8077387],
                [107.5676843, 10.807829],
                [107.5677082, 10.8079283],
                [107.5677108, 10.8079798],
                [107.5676969, 10.8080816],
                [107.5676262, 10.8082554],
                [107.5676208, 10.8082797],
                [107.5676295, 10.8083281],
                [107.5676424, 10.8083485],
                [107.5676811, 10.808377],
                [107.56781, 10.8083943],
                [107.5678598, 10.8084126],
                [107.5679058, 10.8084388],
                [107.5679725, 10.8085005],
                [107.5680184, 10.8085784],
                [107.5680377, 10.8086346],
                [107.5680485, 10.808693],
                [107.5680552, 10.8087995],
                [107.5680672, 10.8088454],
                [107.5681123, 10.8089288],
                [107.5681792, 10.8089929],
                [107.5683422, 10.8090697],
                [107.5684087, 10.809123],
                [107.5684583, 10.8091897],
                [107.5684849, 10.8092502],
                [107.5685576, 10.8093609],
                [107.5686297, 10.8094343],
                [107.5687304, 10.8095085],
                [107.5687521, 10.8095381],
                [107.5687731, 10.8096014],
                [107.568769, 10.8096616],
                [107.5687104, 10.8098114],
                [107.5686762, 10.8099569],
                [107.568664, 10.8099792],
                [107.5686273, 10.8100143],
                [107.5686004, 10.8100271],
                [107.5685018, 10.81005],
                [107.5684461, 10.8100942],
                [107.5684269, 10.8101241],
                [107.5684105, 10.8101925],
                [107.5684141, 10.8102277],
                [107.5684423, 10.8102897],
                [107.5684658, 10.810315],
                [107.5685262, 10.8103483],
                [107.568654, 10.8103572],
                [107.5687135, 10.8103824],
                [107.5687407, 10.8104047],
                [107.5688951, 10.8106142],
                [107.5689545, 10.8107098],
                [107.5690913, 10.8109654],
                [107.5691211, 10.8109952],
                [107.5691931, 10.8110392],
                [107.5692746, 10.811059],
                [107.5693574, 10.8110534],
                [107.5693972, 10.8110413],
                [107.5695795, 10.8109548],
                [107.5697395, 10.8108569],
                [107.5698155, 10.810802],
                [107.5698924, 10.8107736],
                [107.5699335, 10.8107704],
                [107.5700128, 10.8107863],
                [107.5700489, 10.8108047],
                [107.5701113, 10.8108686],
                [107.5701333, 10.8109075],
                [107.5701547, 10.8109857],
                [107.570152, 10.8110589],
                [107.5701354, 10.8111345],
                [107.5701347, 10.8112157],
                [107.5701425, 10.8112603],
                [107.5701749, 10.8113448],
                [107.5701919, 10.8114515],
                [107.5701887, 10.8115811],
                [107.5701644, 10.8117255],
                [107.5701588, 10.8118664],
                [107.5701798, 10.8120476],
                [107.570191, 10.8120825],
                [107.5702124, 10.8121214],
                [107.5702414, 10.8121553],
                [107.5702769, 10.8121826],
                [107.5704025, 10.8122431],
                [107.5705393, 10.8122718],
                [107.5706094, 10.8122736],
                [107.5707516, 10.8122512],
                [107.5707887, 10.8122552],
                [107.5708542, 10.8122845],
                [107.5709008, 10.8123356],
                [107.5709199, 10.8123906],
                [107.5708991, 10.8126146],
                [107.5709125, 10.8127042],
                [107.5709499, 10.8127948],
                [107.5710533, 10.8129598],
                [107.5711115, 10.8130983],
                [107.5711478, 10.813249],
                [107.5711566, 10.813326],
                [107.5711566, 10.813513],
                [107.5711634, 10.8135519],
                [107.5711904, 10.8136215],
                [107.5712317, 10.813679],
                [107.5716193, 10.8140294],
                [107.571891, 10.8139111],
                [107.5720315, 10.8138633],
                [107.5723033, 10.8137943],
                [107.5725645, 10.8137555],
                [107.572939, 10.8137379],
                [107.5731602, 10.8137475],
                [107.5732681, 10.8137612],
                [107.5734806, 10.8138061],
                [107.5736825, 10.8138733],
                [107.5738699, 10.8139638],
                [107.5735036, 10.8165224],
                [107.5728249, 10.8168879],
                [107.5728189, 10.817252],
                [107.5728464, 10.8176151],
                [107.5728942, 10.8179123],
                [107.5729644, 10.8182052],
                [107.5729751, 10.8190061],
                [107.573415, 10.8199334],
                [107.5740588, 10.8217671],
                [107.5743431, 10.8227945],
                [107.5745684, 10.8237219],
                [107.5745952, 10.8243673],
                [107.5748742, 10.8251076],
                [107.5756627, 10.8274049],
                [107.5760919, 10.8281847],
                [107.5770414, 10.8304134],
                [107.5777602, 10.8327053],
                [107.5777919, 10.8328382],
                [107.5778346, 10.8331078],
                [107.5778493, 10.8333803],
                [107.5778349, 10.8336606],
                [107.577816, 10.8338037],
                [107.5777548, 10.8340858],
                [107.5759095, 10.8407507],
                [107.5748312, 10.8438645],
                [107.5740588, 10.8462775],
                [107.5736511, 10.8472944],
                [107.5723743, 10.849876],
                [107.5712317, 10.8516462],
                [107.5702125, 10.8535797],
                [107.5598377, 10.8685417],
                [107.5598323, 10.8729038],
                [107.5601032, 10.8777069],
                [107.5601904, 10.8795863],
                [107.5601864, 10.8817857],
                [107.5603956, 10.8855312],
                [107.5604117, 10.888339],
                [107.5605726, 10.8900457],
                [107.5607335, 10.8936805],
                [107.5609535, 10.9082481],
                [107.5610071, 10.9102524],
                [107.5609803, 10.9171211],
                [107.5609052, 10.9186749],
                [107.5605941, 10.9211663],
                [107.5604868, 10.9241423],
                [107.5603849, 10.926955],
                [107.5604841, 10.9301021],
                [107.5604868, 10.934587],
                [107.5604117, 10.9370151],
                [107.5573271, 10.9435092],
                [107.5550687, 10.9489867],
                [107.5531322, 10.9529631],
                [107.5425911, 10.9781605],
                [107.542516, 10.9781923],
                [107.5424006, 10.978211],
                [107.5423327, 10.978249],
                [107.5423046, 10.9782774],
                [107.5422183, 10.9784133],
                [107.5421378, 10.9785134],
                [107.5420947, 10.9785423],
                [107.5420198, 10.9786134],
                [107.5419155, 10.9787786],
                [107.5418651, 10.9788052],
                [107.5418079, 10.9788057],
                [107.5417522, 10.9787753],
                [107.5417204, 10.9787211],
                [107.5417033, 10.9783607],
                [107.5416753, 10.9782997],
                [107.5416504, 10.9782766],
                [107.541591, 10.9782533],
                [107.5415315, 10.9782588],
                [107.5414807, 10.9782896],
                [107.5413038, 10.9784673],
                [107.5412466, 10.9784835],
                [107.5411883, 10.9784712],
                [107.5411603, 10.9784527],
                [107.5411386, 10.9784274],
                [107.5411247, 10.9783973],
                [107.5411197, 10.9783645],
                [107.5411434, 10.9781516],
                [107.5411402, 10.9781176],
                [107.5411146, 10.9780544],
                [107.5410663, 10.9780049],
                [107.5410021, 10.9779767],
                [107.5407734, 10.9779615],
                [107.5406653, 10.9779367],
                [107.5405585, 10.9778877],
                [107.5404166, 10.9777929],
                [107.5403649, 10.9777708],
                [107.540267, 10.977748],
                [107.5401664, 10.9777471],
                [107.5397749, 10.9777902],
                [107.5397058, 10.9777823],
                [107.5396728, 10.9777708],
                [107.5396122, 10.9777329],
                [107.539587, 10.9777076],
                [107.5394757, 10.9774996],
                [107.5394438, 10.9774619],
                [107.5394052, 10.9774308],
                [107.5393612, 10.9774076],
                [107.5393135, 10.977393],
                [107.539217, 10.9773913],
                [107.539129, 10.9774228],
                [107.5389656, 10.9775313],
                [107.5387996, 10.9776909],
                [107.5387634, 10.97771],
                [107.5386831, 10.9777261],
                [107.5386036, 10.9777113],
                [107.5385675, 10.9776929],
                [107.5383787, 10.9775154],
                [107.5383233, 10.9774799],
                [107.538203, 10.9774261],
                [107.5380742, 10.9773969],
                [107.5380043, 10.9773919],
                [107.5378648, 10.9774038],
                [107.5377309, 10.9774443],
                [107.5376122, 10.9775007],
                [107.5374831, 10.9775271],
                [107.5374171, 10.9775286],
                [107.537307, 10.9775131],
                [107.5371437, 10.9774532],
                [107.5371118, 10.9774528],
                [107.5370524, 10.977474],
                [107.5370101, 10.9775191],
                [107.5369935, 10.9775773],
                [107.5370039, 10.9776635],
                [107.5370183, 10.9777181],
                [107.5370695, 10.9778273],
                [107.5372296, 10.9780617],
                [107.5372514, 10.9781051],
                [107.5372665, 10.9781512],
                [107.537275, 10.9782474],
                [107.5372678, 10.9782975],
                [107.5372304, 10.9783913],
                [107.5372011, 10.9784329],
                [107.5370801, 10.97854],
                [107.5370423, 10.978586],
                [107.5369936, 10.978682],
                [107.536981, 10.9787287],
                [107.5369316, 10.9791216],
                [107.5369034, 10.9791818],
                [107.5368385, 10.9792649],
                [107.5368163, 10.9793327],
                [107.536819, 10.9794034],
                [107.536843, 10.9794587],
                [107.5368726, 10.9795752],
                [107.536878, 10.9796745],
                [107.5368697, 10.9797131],
                [107.5368534, 10.9797491],
                [107.5368001, 10.9798075],
                [107.5367653, 10.9798273],
                [107.5366915, 10.9798438],
                [107.5366169, 10.979831],
                [107.5365044, 10.9797792],
                [107.5363389, 10.9797325],
                [107.5362872, 10.9797246],
                [107.5362349, 10.9797259],
                [107.5361836, 10.9797363],
                [107.5361351, 10.9797556],
                [107.5360909, 10.979783],
                [107.5360524, 10.9798177],
                [107.5360207, 10.9798587],
                [107.5359971, 10.9799045],
                [107.5359821, 10.9799537],
                [107.5359788, 10.9800484],
                [107.5359891, 10.9800948],
                [107.5360323, 10.9801795],
                [107.5361012, 10.9802458],
                [107.5362019, 10.9803028],
                [107.5362249, 10.9803275],
                [107.536253, 10.9803882],
                [107.536257, 10.9804251],
                [107.5362391, 10.9804968],
                [107.5362182, 10.9805277],
                [107.5361431, 10.9806041],
                [107.5361017, 10.9806727],
                [107.5360865, 10.9807511],
                [107.5360995, 10.9808305],
                [107.5361163, 10.9808676],
                [107.5361685, 10.9809305],
                [107.5364075, 10.9811157],
                [107.5364325, 10.9811438],
                [107.5364519, 10.9811759],
                [107.5364714, 10.9812477],
                [107.5364636, 10.9813216],
                [107.536448, 10.9813593],
                [107.5363959, 10.981422],
                [107.5363232, 10.98146],
                [107.5362825, 10.9814677],
                [107.5359777, 10.9814729],
                [107.5359176, 10.9814817],
                [107.5357942, 10.981521],
                [107.5356764, 10.9815915],
                [107.5354913, 10.98176],
                [107.5353859, 10.9818958],
                [107.5353139, 10.9820371],
                [107.5352204, 10.9823445],
                [107.5351992, 10.9823828],
                [107.5351399, 10.9824472],
                [107.5351068, 10.9824698],
                [107.5350042, 10.9825148],
                [107.5349602, 10.9825605],
                [107.5349396, 10.9826198],
                [107.5349381, 10.9827132],
                [107.5349186, 10.9827527],
                [107.5348873, 10.9827713],
                [107.5348167, 10.9827895],
                [107.5347511, 10.9827827],
                [107.5346829, 10.9827588],
                [107.5346048, 10.9827553],
                [107.5345718, 10.9827625],
                [107.5345114, 10.9827919],
                [107.5344447, 10.9828566],
                [107.534408, 10.9829032],
                [107.5343424, 10.9830157],
                [107.5342977, 10.9831503],
                [107.5342878, 10.9832101],
                [107.5342821, 10.9833515],
                [107.5342621, 10.9833868],
                [107.534225, 10.9834092],
                [107.534202, 10.9834125],
                [107.5340669, 10.9833909],
                [107.5340404, 10.983394],
                [107.5339953, 10.9834208],
                [107.5339722, 10.983465],
                [107.5339705, 10.9834899],
                [107.5339964, 10.9835784],
                [107.5340188, 10.983619],
                [107.5340628, 10.9836728],
                [107.5341681, 10.9837643],
                [107.534228, 10.9838006],
                [107.5343504, 10.9838502],
                [107.534415, 10.9838651],
                [107.5345471, 10.9838744],
                [107.5349098, 10.9837945],
                [107.5349489, 10.9837934],
                [107.5350239, 10.983814],
                [107.5350829, 10.9838597],
                [107.5351191, 10.9839213],
                [107.5351351, 10.9840143],
                [107.5351366, 10.9840731],
                [107.5351225, 10.9841897],
                [107.535105, 10.9842523],
                [107.5350809, 10.9843128],
                [107.5350139, 10.9844247],
                [107.5349442, 10.9844704],
                [107.5348636, 10.9844931],
                [107.5348143, 10.9844948],
                [107.5347655, 10.9844877],
                [107.534682, 10.9844621],
                [107.5346438, 10.9844603],
                [107.5346062, 10.9844668],
                [107.5345404, 10.9845025],
                [107.534515, 10.98453],
                [107.5344889, 10.9845855],
                [107.5344586, 10.9847038],
                [107.5344548, 10.9847637],
                [107.5344694, 10.9848828],
                [107.5344832, 10.9849127],
                [107.5345302, 10.9849587],
                [107.5345606, 10.9849724],
                [107.5346197, 10.984978],
                [107.5346488, 10.9849715],
                [107.5347551, 10.9849167],
                [107.5347993, 10.9849065],
                [107.5348371, 10.9849041],
                [107.5349117, 10.9849161],
                [107.5349468, 10.9849302],
                [107.5352846, 10.9851209],
                [107.5353205, 10.9851667],
                [107.5353357, 10.9852225],
                [107.5353347, 10.9852517],
                [107.5353156, 10.9853068],
                [107.5351683, 10.9854852],
                [107.5351319, 10.9855595],
                [107.5351242, 10.9856313],
                [107.5351499, 10.9857485],
                [107.5351533, 10.9858439],
                [107.5351346, 10.9859387],
                [107.5350782, 10.9860572],
                [107.5350616, 10.9861235],
                [107.5350541, 10.9861915],
                [107.5350768, 10.9865211],
                [107.5350579, 10.9866204],
                [107.5350357, 10.9866662],
                [107.5349719, 10.9867409],
                [107.53489, 10.9867895],
                [107.5348441, 10.9868036],
                [107.5347624, 10.9868112],
                [107.534698, 10.9868326],
                [107.5346469, 10.9868767],
                [107.5346169, 10.9869366],
                [107.5346115, 10.9869679],
                [107.5346189, 10.9870308],
                [107.5347644, 10.9873263],
                [107.5347762, 10.9873714],
                [107.534779, 10.9874641],
                [107.53477, 10.9875098],
                [107.5347322, 10.9875949],
                [107.5346992, 10.9876381],
                [107.5346592, 10.9876751],
                [107.5346133, 10.9877049],
                [107.534563, 10.9877267],
                [107.5345096, 10.9877397],
                [107.5344172, 10.9877258],
                [107.5343353, 10.9876818],
                [107.5342756, 10.9876159],
                [107.534255, 10.9875765],
                [107.5342183, 10.9874329],
                [107.5342172, 10.9873289],
                [107.5342256, 10.9872748],
                [107.5342661, 10.9871367],
                [107.5342566, 10.9870681],
                [107.5342414, 10.9870367],
                [107.5341922, 10.9869852],
                [107.534126, 10.9869577],
                [107.5340784, 10.9869634],
                [107.5340328, 10.986978],
                [107.533991, 10.987001],
                [107.5339544, 10.9870314],
                [107.5339077, 10.9870977],
                [107.5338856, 10.9871753],
                [107.5338846, 10.9872157],
                [107.5339173, 10.9873977],
                [107.5339141, 10.9874632],
                [107.5338793, 10.987529],
                [107.5338504, 10.9875496],
                [107.5338042, 10.9876028],
                [107.5337778, 10.9876645],
                [107.5337609, 10.987754],
                [107.5337559, 10.9878687],
                [107.5337619, 10.9879255],
                [107.5337923, 10.9880567],
                [107.5337753, 10.9880942],
                [107.5337377, 10.988114],
                [107.5337157, 10.9881137],
                [107.5336781, 10.9880925],
                [107.5336223, 10.9880077],
                [107.5335679, 10.9878641],
                [107.5335087, 10.9877895],
                [107.5334367, 10.9877397],
                [107.5333457, 10.9877245],
                [107.5332995, 10.9877274],
                [107.5332095, 10.9877542],
                [107.533168, 10.9877781],
                [107.5331013, 10.9878373],
                [107.5330641, 10.9879106],
                [107.5330612, 10.9879925],
                [107.5330735, 10.988033],
                [107.5330945, 10.9880699],
                [107.5331233, 10.9881014],
                [107.5332873, 10.9881984],
                [107.5333719, 10.9882612],
                [107.533451, 10.9883303],
                [107.5335912, 10.9884861],
                [107.5336676, 10.9885977],
                [107.5336784, 10.9886263],
                [107.5336824, 10.9886868],
                [107.533662, 10.9887456],
                [107.5336188, 10.9887927],
                [107.5335601, 10.9888192],
                [107.5334568, 10.9888086],
                [107.5334072, 10.9887929],
                [107.5333252, 10.9887482],
                [107.5332183, 10.9886437],
                [107.5331733, 10.9886091],
                [107.5331232, 10.9885823],
                [107.5330692, 10.9885638],
                [107.5329723, 10.9885531],
                [107.5328761, 10.9885691],
                [107.5325543, 10.9887113],
                [107.5325273, 10.9887316],
                [107.5324897, 10.9887867],
                [107.532479, 10.9888522],
                [107.5324977, 10.988917],
                [107.5325178, 10.9889454],
                [107.5325437, 10.9889686],
                [107.5326079, 10.9889956],
                [107.532919, 10.9890325],
                [107.5329536, 10.9890444],
                [107.5329839, 10.9890645],
                [107.533008, 10.9890916],
                [107.5330243, 10.9891237],
                [107.5330317, 10.9891589],
                [107.5330206, 10.9892244],
                [107.53298, 10.9892776],
                [107.532919, 10.9893063],
                [107.5324714, 10.9893353],
                [107.5324386, 10.9893466],
                [107.5323871, 10.9893916],
                [107.5323655, 10.9894518],
                [107.5323737, 10.9895112],
                [107.5325462, 10.9897856],
                [107.5325666, 10.9898472],
                [107.5325659, 10.9898799],
                [107.5325428, 10.9899407],
                [107.5325216, 10.9899658],
                [107.5324671, 10.9899986],
                [107.5324367, 10.9900056],
                [107.532375, 10.9900004],
                [107.5323208, 10.9899711],
                [107.5320876, 10.9897066],
                [107.5320598, 10.9896858],
                [107.5319937, 10.9896655],
                [107.5319588, 10.9896671],
                [107.5318944, 10.9896934],
                [107.5318776, 10.9897319],
                [107.5318649, 10.9898145],
                [107.5318837, 10.9899032],
                [107.5319052, 10.9899435],
                [107.5321519, 10.99027],
                [107.5321718, 10.9903048],
                [107.532187, 10.9903826],
                [107.532168, 10.9904596],
                [107.5321246, 10.9905166],
                [107.5320954, 10.990538],
                [107.5320273, 10.9905626],
                [107.531991, 10.9905649],
                [107.5316607, 10.9905214],
                [107.5315699, 10.9905386],
                [107.5314988, 10.9905761],
                [107.531468, 10.9906018],
                [107.531427, 10.9906669],
                [107.531409, 10.9907413],
                [107.5314101, 10.9907873],
                [107.5314388, 10.9908745],
                [107.5315283, 10.9909798],
                [107.5315654, 10.9910636],
                [107.5315726, 10.9911547],
                [107.5315557, 10.9912156],
                [107.5315122, 10.991262],
                [107.5314834, 10.9912764],
                [107.5314229, 10.9912839],
                [107.531368, 10.9912664],
                [107.5312978, 10.9911968],
                [107.5312668, 10.9911791],
                [107.5311971, 10.9911653],
                [107.5311649, 10.9911689],
                [107.5311059, 10.9911942],
                [107.5310805, 10.9912166],
                [107.5310437, 10.9912728],
                [107.5310292, 10.9913387],
                [107.5310312, 10.9913729],
                [107.5310395, 10.9914061],
                [107.5310737, 10.9914654],
                [107.5311168, 10.9915179],
                [107.5312212, 10.991605],
                [107.5313292, 10.9916587],
                [107.5314465, 10.9916879],
                [107.5314701, 10.9917021],
                [107.531489, 10.9917218],
                [107.5315082, 10.9917722],
                [107.5315071, 10.9917993],
                [107.5314839, 10.991848],
                [107.5314387, 10.9918785],
                [107.531284, 10.9918901],
                [107.5311738, 10.9918811],
                [107.5311283, 10.9918706],
                [107.5310407, 10.9918338],
                [107.5309623, 10.9917768],
                [107.5309288, 10.9917419],
                [107.5307869, 10.991492],
                [107.530738, 10.9914556],
                [107.5307082, 10.9914477],
                [107.5306472, 10.9914549],
                [107.5306198, 10.9914698],
                [107.5305809, 10.9915175],
                [107.5305547, 10.9915849],
                [107.5305325, 10.9916202],
                [107.5304756, 10.9916813],
                [107.5303878, 10.991734],
                [107.5303107, 10.9917576],
                [107.5302856, 10.9917721],
                [107.5302502, 10.991817],
                [107.5302417, 10.9918742],
                [107.530249, 10.9919029],
                [107.5303441, 10.9920262],
                [107.5303711, 10.9920662],
                [107.5303897, 10.9921105],
                [107.5304005, 10.9921816],
                [107.5304017, 10.9923859],
                [107.530418, 10.9924555],
                [107.5304585, 10.9925155],
                [107.5304865, 10.9925395],
                [107.5306344, 10.9926147],
                [107.5306593, 10.9926555],
                [107.5306606, 10.9927029],
                [107.5306503, 10.9927287],
                [107.5306097, 10.9927661],
                [107.5305131, 10.9927819],
                [107.5304296, 10.9927711],
                [107.5303522, 10.9927385],
                [107.5301217, 10.992576],
                [107.530066, 10.9925605],
                [107.5300369, 10.9925621],
                [107.529982, 10.9925846],
                [107.529942, 10.9926299],
                [107.5299311, 10.9926582],
                [107.5299347, 10.9926884],
                [107.5299565, 10.9927451],
                [107.5299779, 10.9927745],
                [107.5300357, 10.9928188],
                [107.530266, 10.992917],
                [107.5305115, 10.9929963],
                [107.5306404, 10.9930284],
                [107.530902, 10.9930742],
                [107.5309263, 10.9930864],
                [107.5309628, 10.9931256],
                [107.5309771, 10.9931769],
                [107.5309743, 10.9932054],
                [107.5309477, 10.993256],
                [107.5308993, 10.9932874],
                [107.5307477, 10.9933465],
                [107.5306687, 10.9933664],
                [107.5305484, 10.9933819],
                [107.5304876, 10.993381],
                [107.5303682, 10.993362],
                [107.530255, 10.993321],
                [107.5301668, 10.993276],
                [107.5301287, 10.9932676],
                [107.5300518, 10.9932769],
                [107.5299892, 10.9933165],
                [107.5299659, 10.9933454],
                [107.5299662, 10.9933949],
                [107.5299753, 10.9934437],
                [107.5300175, 10.9935321],
                [107.5300867, 10.9936008],
                [107.5301751, 10.9936429],
                [107.5302051, 10.9936649],
                [107.5302292, 10.993693],
                [107.5302553, 10.9937613],
                [107.530256, 10.993798],
                [107.5302326, 10.9938673],
                [107.5301805, 10.9939194],
                [107.5301451, 10.9939361],
                [107.5301067, 10.9939442],
                [107.5300675, 10.9939431],
                [107.5300296, 10.9939329],
                [107.5299952, 10.9939143],
                [107.5299663, 10.9938882],
                [107.5297594, 10.9936139],
                [107.5297369, 10.9935936],
                [107.5296804, 10.9935717],
                [107.5296498, 10.9935716],
                [107.5295931, 10.9935929],
                [107.5295558, 10.9936337],
                [107.5295451, 10.9936591],
                [107.5295422, 10.9937139],
                [107.5295502, 10.9937403],
                [107.5296747, 10.9939412],
                [107.5296905, 10.9939869],
                [107.5297004, 10.9940826],
                [107.5296783, 10.9941838],
                [107.5296552, 10.9942305],
                [107.5295875, 10.99431],
                [107.5292551, 10.9945197],
                [107.5291835, 10.994553],
                [107.5290327, 10.9946005],
                [107.5288726, 10.9946218],
                [107.528708, 10.9946145],
                [107.5286091, 10.9945885],
                [107.5285578, 10.9945881],
                [107.5284791, 10.9946046],
                [107.5284076, 10.9946408],
                [107.5283387, 10.9946977],
                [107.5281876, 10.9947935],
                [107.5281102, 10.9948301],
                [107.5279476, 10.9948844],
                [107.5279043, 10.9949151],
                [107.5278895, 10.9949371],
                [107.5278778, 10.9949884],
                [107.5278825, 10.9950172],
                [107.5279138, 10.9950664],
                [107.5279382, 10.9950831],
                [107.5281756, 10.9951888],
                [107.5282084, 10.995228],
                [107.5282171, 10.995278],
                [107.5282112, 10.9953038],
                [107.5281986, 10.9953271],
                [107.5281572, 10.9953602],
                [107.5280716, 10.9953758],
                [107.5280113, 10.9953758],
                [107.5279516, 10.9953675],
                [107.5278486, 10.9953316],
                [107.5278014, 10.9953043],
                [107.527627, 10.9951068],
                [107.5275422, 10.9950595],
                [107.5274466, 10.9950403],
                [107.5273976, 10.9950419],
                [107.5273496, 10.9950509],
                [107.5272607, 10.9950905],
                [107.527222, 10.99512],
                [107.5271614, 10.9951943],
                [107.5271407, 10.9952374],
                [107.5271207, 10.9953305],
                [107.527122, 10.9953782],
                [107.5271469, 10.9954702],
                [107.5272701, 10.9956509],
                [107.527302, 10.9957291],
                [107.527302, 10.9958132],
                [107.5272703, 10.9958915],
                [107.5272434, 10.9959252],
                [107.5271725, 10.9959745],
                [107.5271312, 10.9959883],
                [107.5269757, 10.9960018],
                [107.5269209, 10.9960148],
                [107.5268206, 10.9960565],
                [107.5267343, 10.996116],
                [107.5266655, 10.9961835],
                [107.5265906, 10.9962295],
                [107.5265045, 10.9962495],
                [107.5264211, 10.9962426],
                [107.5263812, 10.9962298],
                [107.5263357, 10.9961859],
                [107.5263141, 10.9961271],
                [107.5263199, 10.9960667],
                [107.5263326, 10.9960388],
                [107.5265314, 10.9958349],
                [107.526566, 10.9957775],
                [107.5265676, 10.9957109],
                [107.5265555, 10.9956795],
                [107.5265187, 10.9956369],
                [107.5264943, 10.9956223],
                [107.5264388, 10.9956097],
                [107.5263564, 10.9956064],
                [107.5262739, 10.99561],
                [107.526048, 10.9956426],
                [107.525975, 10.9956429],
                [107.5258267, 10.995621],
                [107.5256824, 10.9955676],
                [107.5256326, 10.995556],
                [107.5255816, 10.9955523],
                [107.525481, 10.9955689],
                [107.5253908, 10.9956158],
                [107.5253233, 10.9956839],
                [107.5252813, 10.9957637],
                [107.5252491, 10.9959308],
                [107.5252046, 10.995998],
                [107.5251733, 10.9960239],
                [107.5250983, 10.9960555],
                [107.5250163, 10.9960565],
                [107.52494, 10.9960263],
                [107.5248814, 10.9959695],
                [107.5248497, 10.995895],
                [107.5248404, 10.9957966],
                [107.5248107, 10.995684],
                [107.5247586, 10.9955836],
                [107.5246885, 10.9954988],
                [107.5246471, 10.9954623],
                [107.5242327, 10.9952429],
                [107.5241693, 10.9952214],
                [107.5241033, 10.9952332],
                [107.5240747, 10.995251],
                [107.5240362, 10.9953034],
                [107.524028, 10.9953663],
                [107.5240512, 10.9954255],
                [107.524093, 10.9954834],
                [107.5241062, 10.9955207],
                [107.5241111, 10.9955991],
                [107.5241026, 10.9956377],
                [107.5240651, 10.9957072],
                [107.5239995, 10.9957619],
                [107.5239175, 10.9957877],
                [107.5238317, 10.9957807],
                [107.5236601, 10.9957019],
                [107.5236237, 10.9956779],
                [107.5235402, 10.9956537],
                [107.5234578, 10.9956626],
                [107.5233882, 10.9956988],
                [107.5233597, 10.9957256],
                [107.5233359, 10.9957642],
                [107.5233061, 10.9958493],
                [107.5233024, 10.9959471],
                [107.5233131, 10.995999],
                [107.5233597, 10.9960942],
                [107.5235152, 10.9962522],
                [107.5235728, 10.9963299],
                [107.5236076, 10.9964198],
                [107.5236171, 10.9965155],
                [107.5236007, 10.9966102],
                [107.5235831, 10.9966551],
                [107.5235305, 10.9967362],
                [107.5234964, 10.9967709],
                [107.5232031, 10.9969655],
                [107.5231742, 10.9969982],
                [107.523137, 10.9970763],
                [107.5231298, 10.997148],
                [107.5231451, 10.9972185],
                [107.5232531, 10.9974198],
                [107.5232649, 10.997456],
                [107.5232685, 10.9974939],
                [107.5232507, 10.9975674],
                [107.5232031, 10.9976267],
                [107.5231343, 10.9976608],
                [107.5230636, 10.9976643],
                [107.523029, 10.9976557],
                [107.5229686, 10.9976194],
                [107.5229273, 10.9975628],
                [107.5229161, 10.9975296],
                [107.5228956, 10.9971948],
                [107.5228684, 10.9971444],
                [107.5228461, 10.9971259],
                [107.522791, 10.9971079],
                [107.5227366, 10.9971173],
                [107.5226929, 10.9971504],
                [107.5224762, 10.9974511],
                [107.5224544, 10.9975188],
                [107.5224584, 10.9975897],
                [107.5224878, 10.9976549],
                [107.522511, 10.9976825],
                [107.5227242, 10.9978688],
                [107.5227725, 10.9979388],
                [107.5228315, 10.9981041],
                [107.5228914, 10.9982],
                [107.5229333, 10.9982476],
                [107.5230759, 10.9983673],
                [107.5231454, 10.9984625],
                [107.5231704, 10.9985159],
                [107.5231987, 10.9986297],
                [107.523201, 10.998657],
                [107.5231844, 10.9987088],
                [107.5231418, 10.9987467],
                [107.5231133, 10.9987562],
                [107.5230832, 10.9987576],
                [107.5228338, 10.9987094],
                [107.5227983, 10.9987127],
                [107.522732, 10.9987377],
                [107.5226786, 10.9987851],
                [107.5226462, 10.9988483],
                [107.5226324, 10.9990025],
                [107.5226232, 10.9990263],
                [107.5225894, 10.9990642],
                [107.5225416, 10.9990826],
                [107.5224898, 10.999077],
                [107.5224665, 10.9990653],
                [107.5224316, 10.9990273],
                [107.5223318, 10.998858],
                [107.5223003, 10.9988384],
                [107.5222622, 10.9988442],
                [107.5222468, 10.9988573],
                [107.5222355, 10.9988949],
                [107.5222599, 10.9990378],
                [107.5222571, 10.9991348],
                [107.5222278, 10.9992274],
                [107.522173, 10.9993099],
                [107.5221375, 10.9993448],
                [107.5220534, 10.9993986],
                [107.5217038, 10.9995413],
                [107.5216392, 10.9995445],
                [107.5216078, 10.9995365],
                [107.5215787, 10.9995223],
                [107.5215344, 10.9994815],
                [107.521519, 10.9994557],
                [107.5214392, 10.9992221],
                [107.5213972, 10.9991599],
                [107.5213669, 10.999137],
                [107.5212948, 10.9991133],
                [107.5212565, 10.9991136],
                [107.5212193, 10.9991221],
                [107.521162, 10.9991551],
                [107.5211216, 10.9992068],
                [107.5210756, 10.9993843],
                [107.52102, 10.9994853],
                [107.520943, 10.9995671],
                [107.5206465, 10.99978],
                [107.5206285, 10.9998049],
                [107.52061, 10.999863],
                [107.5206185, 10.999927],
                [107.5206571, 10.9999838],
                [107.5207177, 11.0000173],
                [107.5208786, 11.0000673],
                [107.5209373, 11.0001054],
                [107.5209695, 11.0001668],
                [107.5209728, 11.0002015],
                [107.520955, 11.0002639],
                [107.5209367, 11.0002887],
                [107.520886, 11.0003235],
                [107.5206828, 11.000349],
                [107.5205487, 11.0003859],
                [107.5204498, 11.0004418],
                [107.5203675, 11.0005194],
                [107.5203341, 11.0005649],
                [107.520288, 11.0006585],
                [107.520195, 11.0010928],
                [107.5201624, 11.0011317],
                [107.5201124, 11.001143],
                [107.5200874, 11.0011363],
                [107.5200242, 11.0010954],
                [107.5199882, 11.0010837],
                [107.5199118, 11.0010797],
                [107.5198382, 11.0011024],
                [107.5197565, 11.0011559],
                [107.5196475, 11.0012021],
                [107.5195363, 11.0012201],
                [107.5194497, 11.0012181],
                [107.5194202, 11.0012239],
                [107.5193694, 11.001255],
                [107.5193397, 11.001303],
                [107.5193342, 11.0013562],
                [107.5194427, 11.0016474],
                [107.5194504, 11.0017026],
                [107.5194489, 11.0017583],
                [107.5194383, 11.0018129],
                [107.519404, 11.0018889],
                [107.5193775, 11.0019214],
                [107.5193095, 11.0019709],
                [107.5192199, 11.001996],
                [107.5191271, 11.0019867],
                [107.5190599, 11.0019518],
                [107.5190145, 11.0018919],
                [107.5189997, 11.0018235],
                [107.5190035, 11.0017885],
                [107.5190668, 11.0016368],
                [107.5190913, 11.0015101],
                [107.5190867, 11.0013812],
                [107.5190735, 11.001318],
                [107.5190274, 11.0012002],
                [107.5189949, 11.0011457],
                [107.5189129, 11.0010487],
                [107.5188011, 11.000954],
                [107.5187666, 11.0008797],
                [107.5187668, 11.0007996],
                [107.5187995, 11.000728],
                [107.5188264, 11.0006987],
                [107.5189215, 11.0006291],
                [107.5189645, 11.0005647],
                [107.5189826, 11.0004931],
                [107.5189826, 11.0004577],
                [107.5189659, 11.0003895],
                [107.5189278, 11.0003308],
                [107.518865, 11.000284],
                [107.5187811, 11.0002623],
                [107.5186953, 11.0002753],
                [107.5186212, 11.0002702],
                [107.5185531, 11.0002411],
                [107.5184897, 11.0001813],
                [107.5184499, 11.0001044],
                [107.5184384, 11.000019],
                [107.5184561, 10.9999349],
                [107.51851, 10.9998235],
                [107.5185172, 10.9997436],
                [107.5185102, 10.999704],
                [107.5184705, 10.9996239],
                [107.5184029, 10.9995644],
                [107.5183154, 10.9995427],
                [107.5182701, 10.9995441],
                [107.518183, 10.9995723],
                [107.518112, 10.9996313],
                [107.5180864, 10.9996697],
                [107.5180558, 10.9997917],
                [107.5180542, 10.9999172],
                [107.5180819, 11.0000404],
                [107.5181063, 11.0000989],
                [107.5181374, 11.0001542],
                [107.5181753, 11.0002025],
                [107.5181853, 11.0002316],
                [107.5181837, 11.0002956],
                [107.518171, 11.0003269],
                [107.5181508, 11.0003543],
                [107.5180847, 11.0003969],
                [107.5180382, 11.0004596],
                [107.5180172, 11.0005323],
                [107.5180143, 11.0006158],
                [107.5180035, 11.0006602],
                [107.5179584, 11.0007391],
                [107.5179259, 11.000771],
                [107.5175589, 11.0010498],
                [107.5175047, 11.0011163],
                [107.5174715, 11.0011995],
                [107.5174649, 11.001246],
                [107.5174661, 11.0012929],
                [107.5174806, 11.0013725],
                [107.5174724, 11.0014395],
                [107.5174362, 11.0015025],
                [107.5174072, 11.0015288],
                [107.517373, 11.0015483],
                [107.5168961, 11.001727],
                [107.5168082, 11.0017481],
                [107.5167185, 11.0017603],
                [107.5165561, 11.0017592],
                [107.5164756, 11.0017476],
                [107.5163966, 11.0017287],
                [107.5162831, 11.001685],
                [107.5161627, 11.0016657],
                [107.5160344, 11.0016727],
                [107.5159047, 11.0017098],
                [107.5158441, 11.0017392],
                [107.5151521, 11.0021552],
                [107.5151057, 11.0022043],
                [107.5150931, 11.0022357],
                [107.5150931, 11.0023027],
                [107.5151044, 11.0023317],
                [107.5151453, 11.0023785],
                [107.5152031, 11.0024027],
                [107.5153908, 11.0024132],
                [107.5154244, 11.0024265],
                [107.5154765, 11.002475],
                [107.5154916, 11.0025073],
                [107.5154962, 11.0025739],
                [107.5154714, 11.002632],
                [107.5154202, 11.0026861],
                [107.5153854, 11.0027109],
                [107.515305, 11.0027397],
                [107.5152262, 11.0027394],
                [107.515188, 11.0027295],
                [107.5151521, 11.0027134],
                [107.514964, 11.0025704],
                [107.5148782, 11.0025425],
                [107.5148329, 11.00254],
                [107.5147444, 11.0025581],
                [107.5147067, 11.0025765],
                [107.5146429, 11.0026298],
                [107.5146183, 11.0026634],
                [107.5145406, 11.002874],
                [107.5145318, 11.0029406],
                [107.5145486, 11.0030057],
                [107.5145679, 11.0030373],
                [107.5146241, 11.0030857],
                [107.5146586, 11.0031004],
                [107.5149424, 11.0031656],
                [107.5149914, 11.0031988],
                [107.5150194, 11.0032503],
                [107.5150208, 11.0033061],
                [107.5149988, 11.0033546],
                [107.5149517, 11.003396],
                [107.5148866, 11.0034269],
                [107.5148475, 11.0034362],
                [107.5147674, 11.0034368],
                [107.5147282, 11.0034281],
                [107.5146908, 11.0034138],
                [107.5143763, 11.0032273],
                [107.5142004, 11.0031377],
                [107.5140208, 11.0030702],
                [107.5138036, 11.0030102],
                [107.5137422, 11.0029617],
                [107.513706, 11.0028932],
                [107.5136994, 11.0028549],
                [107.513701, 11.0028161],
                [107.5137245, 11.0027494],
                [107.5137714, 11.002696],
                [107.5138017, 11.0026767],
                [107.5141758, 11.0025107],
                [107.514199, 11.0024939],
                [107.5142277, 11.0024455],
                [107.5142267, 11.0023896],
                [107.5141956, 11.0023415],
                [107.5141712, 11.0023256],
                [107.5141141, 11.0023158],
                [107.5140185, 11.002331],
                [107.5138089, 11.0023785],
                [107.5136961, 11.0024137],
                [107.5134749, 11.002505],
                [107.5132638, 11.0026239],
                [107.5132214, 11.0026692],
                [107.5131862, 11.0027202],
                [107.513159, 11.0027757],
                [107.5131404, 11.0028345],
                [107.5131319, 11.0028842],
                [107.5131334, 11.0029847],
                [107.5131701, 11.0031168],
                [107.5131738, 11.0031531],
                [107.5131703, 11.0031893],
                [107.5131424, 11.0032565],
                [107.5130886, 11.0033092],
                [107.513016, 11.0033375],
                [107.5129768, 11.00334],
                [107.51269, 11.00332],
                [107.5126278, 11.0033349],
                [107.5126015, 11.0033532],
                [107.5125664, 11.0034059],
                [107.5125602, 11.003434],
                [107.5125675, 11.0034907],
                [107.5125994, 11.0035385],
                [107.5128025, 11.0036902],
                [107.5128543, 11.0037216],
                [107.5129106, 11.0037447],
                [107.5129697, 11.0037589],
                [107.5130305, 11.0037639],
                [107.5131339, 11.0037508],
                [107.5131833, 11.0037342],
                [107.5132732, 11.0036824],
                [107.5135626, 11.0034636],
                [107.5136325, 11.0034499],
                [107.5136679, 11.0034554],
                [107.513701, 11.003469],
                [107.5137513, 11.0035145],
                [107.5137761, 11.003577],
                [107.5137791, 11.0036298],
                [107.5137756, 11.0036826],
                [107.5137493, 11.003785],
                [107.5137252, 11.0038361],
                [107.5136581, 11.0039271],
                [107.5135696, 11.0039983],
                [107.5131553, 11.0042096],
                [107.5130812, 11.0042408],
                [107.5130045, 11.0042652],
                [107.5128494, 11.0042927],
                [107.5127723, 11.0042962],
                [107.5126952, 11.0042931],
                [107.5125971, 11.0042779],
                [107.5125475, 11.0042822],
                [107.5124993, 11.0042944],
                [107.5124142, 11.0043395],
                [107.512379, 11.0043706],
                [107.5123254, 11.0044467],
                [107.512259, 11.0046527],
                [107.5122309, 11.004708],
                [107.5121587, 11.0048092],
                [107.5120696, 11.0048934],
                [107.5119015, 11.0050004],
                [107.5118414, 11.005047],
                [107.5117271, 11.0051657],
                [107.5116404, 11.0052895],
                [107.5115991, 11.0053364],
                [107.5115016, 11.0054147],
                [107.5114436, 11.0054468],
                [107.511318, 11.0054892],
                [107.5109678, 11.0055543],
                [107.5108886, 11.0055749],
                [107.5107372, 11.0056359],
                [107.5106598, 11.0056798],
                [107.5105636, 11.0057498],
                [107.5105354, 11.0057609],
                [107.5105051, 11.0057629],
                [107.5104757, 11.0057557],
                [107.5104501, 11.0057401],
                [107.5104307, 11.0057176],
                [107.5104192, 11.0056903],
                [107.5104207, 11.0054292],
                [107.5104139, 11.005402],
                [107.5103991, 11.005378],
                [107.5103559, 11.0053492],
                [107.510308, 11.0053463],
                [107.510272, 11.0053684],
                [107.5102128, 11.0054279],
                [107.5101742, 11.005508],
                [107.5101659, 11.0055517],
                [107.5101525, 11.0057939],
                [107.5101713, 11.005863],
                [107.5102117, 11.0059187],
                [107.5102661, 11.0059561],
                [107.5102973, 11.0059677],
                [107.5105172, 11.0059887],
                [107.5105896, 11.0060255],
                [107.5106423, 11.0060864],
                [107.5106586, 11.0061233],
                [107.5106684, 11.0062019],
                [107.5106475, 11.0062778],
                [107.5106264, 11.0063114],
                [107.5105669, 11.006364],
                [107.5101203, 11.0065153],
                [107.5100628, 11.0065624],
                [107.5100239, 11.0066252],
                [107.5100076, 11.006697],
                [107.5100193, 11.0067816],
                [107.5100371, 11.0068207],
                [107.5102448, 11.0070495],
                [107.5102624, 11.0071169],
                [107.5102602, 11.0071454],
                [107.5102396, 11.0071985],
                [107.5101679, 11.007265],
                [107.5101243, 11.007288],
                [107.5100363, 11.0073095],
                [107.5099908, 11.0073096],
                [107.509911, 11.0072947],
                [107.5098482, 11.0072612],
                [107.5098225, 11.0072367],
                [107.5097835, 11.0071664],
                [107.509776, 11.0071269],
                [107.5097769, 11.0070866],
                [107.5098225, 11.0068418],
                [107.509819, 11.0067593],
                [107.509785, 11.0066838],
                [107.5097573, 11.0066514],
                [107.5096857, 11.0066048],
                [107.5095716, 11.0066002],
                [107.5095151, 11.0066093],
                [107.5094102, 11.0066486],
                [107.5093634, 11.0066776],
                [107.5092834, 11.0067522],
                [107.5092565, 11.0067886],
                [107.5092221, 11.0068715],
                [107.5092163, 11.0069608],
                [107.5092244, 11.007005],
                [107.509257, 11.0070797],
                [107.5093753, 11.0072119],
                [107.5094909, 11.0073636],
                [107.5095876, 11.0075333],
                [107.5096274, 11.007625],
                [107.5096858, 11.0078159],
                [107.5097047, 11.0079194],
                [107.509715, 11.0080555],
                [107.5096915, 11.0081139],
                [107.5096417, 11.0081549],
                [107.5096102, 11.0081653],
                [107.5095442, 11.0081615],
                [107.5094554, 11.0081204],
                [107.5094013, 11.0080851],
                [107.5093532, 11.0080424],
                [107.5092924, 11.0079638],
                [107.5092692, 11.00792],
                [107.5091306, 11.0074906],
                [107.5091125, 11.007458],
                [107.5090574, 11.0074076],
                [107.509023, 11.0073921],
                [107.508986, 11.0073842],
                [107.5089126, 11.0073915],
                [107.5088793, 11.0074059],
                [107.5088251, 11.0074529],
                [107.5088064, 11.0074835],
                [107.5087899, 11.0075526],
                [107.5087885, 11.0080991],
                [107.5088248, 11.0083767],
                [107.508809, 11.0084301],
                [107.5087913, 11.0084521],
                [107.5087435, 11.008479],
                [107.5086898, 11.0084804],
                [107.5086644, 11.0084715],
                [107.5086418, 11.0084568],
                [107.5084659, 11.0082612],
                [107.5084119, 11.0082381],
                [107.5083519, 11.008244],
                [107.5083244, 11.0082584],
                [107.5082858, 11.0083034],
                [107.5082695, 11.0083583],
                [107.5082705, 11.0083919],
                [107.5082953, 11.0084541],
                [107.5084283, 11.0085832],
                [107.5085539, 11.008669],
                [107.5086203, 11.008704],
                [107.5087604, 11.0087585],
                [107.5088195, 11.0087983],
                [107.5088407, 11.0088269],
                [107.5088613, 11.0088943],
                [107.5088498, 11.0089641],
                [107.5088322, 11.0089954],
                [107.5088079, 11.0090221],
                [107.5087443, 11.009056],
                [107.5085927, 11.0090752],
                [107.5084694, 11.0090756],
                [107.5084229, 11.009069],
                [107.5083339, 11.0090402],
                [107.5082418, 11.0089827],
                [107.5082025, 11.0089454],
                [107.5080281, 11.0087111],
                [107.5079968, 11.0086901],
                [107.5079238, 11.0086712],
                [107.507886, 11.0086742],
                [107.507821, 11.0087018],
                [107.5077733, 11.0087532],
                [107.5077466, 11.0088202],
                [107.5077286, 11.0088899],
                [107.5077192, 11.0090181],
                [107.5077244, 11.0090747],
                [107.5077519, 11.009185],
                [107.5077927, 11.0092481],
                [107.5078413, 11.0093058],
                [107.5078968, 11.0093569],
                [107.5079584, 11.0094009],
                [107.5080713, 11.009456],
                [107.5081315, 11.0094746],
                [107.5082561, 11.0094931],
                [107.5083068, 11.0095278],
                [107.5083314, 11.0095835],
                [107.5083312, 11.0096142],
                [107.5083091, 11.0096658],
                [107.5082889, 11.0096858],
                [107.5082366, 11.0097081],
                [107.5077478, 11.0097053],
                [107.5076722, 11.0097227],
                [107.507607, 11.0097642],
                [107.5075775, 11.009797],
                [107.5075406, 11.0098762],
                [107.5075346, 11.0099196],
                [107.5075464, 11.0099682],
                [107.5075936, 11.0100565],
                [107.5076548, 11.010116],
                [107.5077645, 11.0101756],
                [107.5078163, 11.0102329],
                [107.507838, 11.0103019],
                [107.5078431, 11.010483],
                [107.5078649, 11.0105592],
                [107.507894, 11.0106331],
                [107.5079598, 11.0107516],
                [107.5080704, 11.0108902],
                [107.5080895, 11.0109267],
                [107.5081035, 11.0110071],
                [107.5080844, 11.0110857],
                [107.5080363, 11.0111501],
                [107.5079609, 11.0112014],
                [107.5078132, 11.0112962],
                [107.5077837, 11.011321],
                [107.5077358, 11.0113808],
                [107.5077026, 11.0114615],
                [107.5076875, 11.0116862],
                [107.5076668, 11.0117674],
                [107.5076197, 11.0118415],
                [107.5075863, 11.0118734],
                [107.5074874, 11.0119414],
                [107.50745, 11.0120003],
                [107.507441, 11.0120323],
                [107.507415, 11.0123324],
                [107.5073741, 11.0124505],
                [107.5073065, 11.0125561],
                [107.5072148, 11.0126442],
                [107.5071612, 11.0126801],
                [107.5070429, 11.0127318],
                [107.5068604, 11.0127626],
                [107.506807, 11.0127787],
                [107.5067146, 11.0128306],
                [107.5066441, 11.0129026],
                [107.5066176, 11.0129558],
                [107.5065995, 11.0130124],
                [107.5065905, 11.0130711],
                [107.5065907, 11.013132],
                [107.5066271, 11.0132994],
                [107.5066162, 11.0133982],
                [107.5065766, 11.0134832],
                [107.5065169, 11.0135484],
                [107.5064283, 11.013605],
                [107.5063722, 11.0136284],
                [107.5063132, 11.0136436],
                [107.5061916, 11.0136484],
                [107.5060737, 11.0136191],
                [107.5056987, 11.0134148],
                [107.5056584, 11.0134044],
                [107.5055755, 11.0134021],
                [107.505535, 11.01341],
                [107.5054266, 11.0134524],
                [107.5053495, 11.0134503],
                [107.5052789, 11.013416],
                [107.505161, 11.0133298],
                [107.5051361, 11.0133236],
                [107.5050858, 11.0133317],
                [107.505064, 11.0133457],
                [107.5050361, 11.0133881],
                [107.5050124, 11.0136289],
                [107.5050169, 11.0136733],
                [107.5050294, 11.0137161],
                [107.5050674, 11.0137811],
                [107.5051426, 11.0138492],
                [107.5051571, 11.0138704],
                [107.5051693, 11.0139198],
                [107.5051571, 11.0139692],
                [107.5051425, 11.0139905],
                [107.5051003, 11.0140201],
                [107.5049087, 11.0140373],
                [107.5048062, 11.0140288],
                [107.5047049, 11.014011],
                [107.504493, 11.0139463],
                [107.5044211, 11.0139489],
                [107.5043562, 11.0139794],
                [107.5043277, 11.014006],
                [107.5042918, 11.0140742],
                [107.5042898, 11.0141164],
                [107.5043106, 11.0141979],
                [107.5043313, 11.0142323],
                [107.5043899, 11.0142872],
                [107.5044772, 11.0143226],
                [107.5044972, 11.0143408],
                [107.504518, 11.0143897],
                [107.5045171, 11.0144164],
                [107.5044943, 11.0144628],
                [107.5044399, 11.0145006],
                [107.5043616, 11.0145191],
                [107.5042977, 11.0145096],
                [107.5042409, 11.0144796],
                [107.5042115, 11.0144436],
                [107.5041765, 11.0143585],
                [107.5041741, 11.0142844],
                [107.5041819, 11.0142479],
                [107.5041729, 11.0141656],
                [107.504137, 11.0140926],
                [107.5040799, 11.0140366],
                [107.5040451, 11.0140162],
                [107.50394, 11.0140077],
                [107.5038874, 11.0140128],
                [107.5037877, 11.0140408],
                [107.5036991, 11.01409],
                [107.5036457, 11.0141804],
                [107.5036297, 11.0142304],
                [107.5036203, 11.0143266],
                [107.5036352, 11.0144142],
                [107.503761, 11.0147153],
                [107.5037713, 11.0147783],
                [107.5037571, 11.0148422],
                [107.5037406, 11.0148719],
                [107.5036709, 11.014914],
                [107.5035936, 11.0149334],
                [107.503514, 11.0149298],
                [107.5033371, 11.0148879],
                [107.5031969, 11.0148372],
                [107.5031454, 11.01481],
                [107.5030526, 11.0147403],
                [107.5029699, 11.0146419],
                [107.502938, 11.0145861],
                [107.5028698, 11.0144242],
                [107.5028403, 11.0143765],
                [107.5027817, 11.0143059],
                [107.5027194, 11.0142709],
                [107.5026841, 11.0142637],
                [107.5026128, 11.0142716],
                [107.5025481, 11.0143102],
                [107.5025239, 11.014339],
                [107.5024974, 11.0144085],
                [107.5024932, 11.0144604],
                [107.5025028, 11.0145639],
                [107.5025207, 11.0146257],
                [107.5025965, 11.0147676],
                [107.5026032, 11.0147989],
                [107.5025913, 11.0148614],
                [107.5025516, 11.0149083],
                [107.5025242, 11.0149232],
                [107.5024626, 11.0149311],
                [107.5023691, 11.0148951],
                [107.5023271, 11.014868],
                [107.5022564, 11.0147977],
                [107.5022292, 11.0147561],
                [107.5021568, 11.0145586],
                [107.5021346, 11.01452],
                [107.5021048, 11.0144867],
                [107.5020685, 11.0144602],
                [107.5020275, 11.0144417],
                [107.5019834, 11.014432],
                [107.5019382, 11.0144315],
                [107.5018939, 11.0144401],
                [107.5018183, 11.0144809],
                [107.5017648, 11.0145475],
                [107.5017422, 11.0146293],
                [107.5017457, 11.0147138],
                [107.5017266, 11.0147955],
                [107.5017062, 11.0148324],
                [107.5016501, 11.0148902],
                [107.5016153, 11.0149111],
                [107.5014956, 11.0149575],
                [107.5014353, 11.0149983],
                [107.501412, 11.0150268],
                [107.5013545, 11.0150726],
                [107.5012853, 11.015101],
                [107.50121, 11.0151089],
                [107.5009035, 11.0151026],
                [107.5008355, 11.0151226],
                [107.5007769, 11.0151638],
                [107.5007533, 11.0151914],
                [107.5007218, 11.0152563],
                [107.5007004, 11.015509],
                [107.500692, 11.0155408],
                [107.5006523, 11.0155929],
                [107.5006237, 11.0156098],
                [107.5005582, 11.0156196],
                [107.500501, 11.0155995],
                [107.5004595, 11.0155559],
                [107.5004429, 11.0154985],
                [107.5004321, 11.0153846],
                [107.5004438, 11.0152664],
                [107.500459, 11.0152066],
                [107.500528, 11.0150386],
                [107.5005426, 11.0149823],
                [107.5005509, 11.0149247],
                [107.5005493, 11.0148162],
                [107.500522, 11.0147191],
                [107.5004988, 11.014674],
                [107.5004285, 11.0145886],
                [107.5003809, 11.0145518],
                [107.5003281, 11.0145226],
                [107.5002712, 11.014502],
                [107.5000942, 11.0144665],
                [107.4999913, 11.0144646],
                [107.4998917, 11.0144899],
                [107.4998027, 11.0145406],
                [107.4997282, 11.0146165],
                [107.499677, 11.014714],
                [107.4996465, 11.0148629],
                [107.4996031, 11.0149357],
                [107.4995718, 11.0149648],
                [107.4994955, 11.0150034],
                [107.4994068, 11.0150113],
                [107.4993614, 11.0150019],
                [107.4993189, 11.0149838],
                [107.499281, 11.0149577],
                [107.4989328, 11.0145323],
                [107.498878, 11.0144969],
                [107.4988126, 11.0144921],
                [107.4987811, 11.0145019],
                [107.4987328, 11.0145395],
                [107.4987082, 11.0145908],
                [107.4987085, 11.0146475],
                [107.4990015, 11.0152392],
                [107.4990093, 11.0152696],
                [107.4990015, 11.0153313],
                [107.4989677, 11.0153797],
                [107.498919, 11.015407],
                [107.498863, 11.0154116],
                [107.4987792, 11.015368],
                [107.4987181, 11.0152974],
                [107.4986881, 11.0152103],
                [107.4986678, 11.0150701],
                [107.498642, 11.0149778],
                [107.4986086, 11.0148878],
                [107.4985404, 11.0147506],
                [107.4984646, 11.0146645],
                [107.498418, 11.0146304],
                [107.4983107, 11.0145834],
                [107.4981925, 11.0145691],
                [107.4981546, 11.0145772],
                [107.4980886, 11.0146163],
                [107.4980466, 11.0146778],
                [107.4980366, 11.0147128],
                [107.4980399, 11.0147852],
                [107.4980741, 11.0148698],
                [107.4980932, 11.0149772],
                [107.498092, 11.0150208],
                [107.4980745, 11.0151062],
                [107.4980337, 11.0151665],
                [107.4979722, 11.0152064],
                [107.4978997, 11.0152195],
                [107.4978277, 11.0152036],
                [107.4977691, 11.0151628],
                [107.4977308, 11.015103],
                [107.4977186, 11.0150335],
                [107.4977607, 11.0145375],
                [107.4977823, 11.0143843],
                [107.4977779, 11.0142297],
                [107.4977463, 11.0140728],
                [107.4976855, 11.0139188],
                [107.4976464, 11.0138746],
                [107.497592, 11.0138506],
                [107.4975273, 11.0138525],
                [107.4974668, 11.0138865],
                [107.4974307, 11.0139452],
                [107.4973336, 11.0142109],
                [107.4972887, 11.0142566],
                [107.4972284, 11.0142795],
                [107.4971639, 11.0142756],
                [107.4971324, 11.0142628],
                [107.4970821, 11.0142185],
                [107.4970566, 11.0141571],
                [107.4970454, 11.0140047],
                [107.4970204, 11.0139531],
                [107.4969896, 11.0139256],
                [107.4969161, 11.0138881],
                [107.4968382, 11.0138774],
                [107.4967649, 11.0138908],
                [107.4966901, 11.0139246],
                [107.496654, 11.0139509],
                [107.4966007, 11.0140214],
                [107.4965854, 11.0140629],
                [107.4965805, 11.0141505],
                [107.4965902, 11.014191],
                [107.4966311, 11.0142633],
                [107.4967388, 11.0143538],
                [107.4967526, 11.0143788],
                [107.4967608, 11.0144347],
                [107.4967411, 11.0144903],
                [107.4967207, 11.0145138],
                [107.4966949, 11.0145315],
                [107.4966341, 11.0145454],
                [107.4965491, 11.014528],
                [107.4965104, 11.0145084],
                [107.4964759, 11.0144822],
                [107.4964296, 11.0144246],
                [107.4964035, 11.0143559],
                [107.4963527, 11.0140084],
                [107.4963067, 11.013935],
                [107.4962347, 11.0138857],
                [107.4961926, 11.0138729],
                [107.4961099, 11.0138726],
                [107.4960725, 11.0138832],
                [107.4960066, 11.0139231],
                [107.4959802, 11.0139512],
                [107.4959448, 11.0140189],
                [107.495918, 11.0142137],
                [107.4959061, 11.0142493],
                [107.4958587, 11.0143074],
                [107.4958258, 11.0143266],
                [107.4957541, 11.0143397],
                [107.4956867, 11.0143216],
                [107.4956577, 11.0143021],
                [107.4954452, 11.014092],
                [107.4953552, 11.0140336],
                [107.4953051, 11.0140137],
                [107.4952028, 11.0139943],
                [107.4950641, 11.0140015],
                [107.4950261, 11.013995],
                [107.4949577, 11.013961],
                [107.4949056, 11.0138987],
                [107.4948907, 11.0138609],
                [107.494879, 11.0136477],
                [107.4948862, 11.0135929],
                [107.4949206, 11.0134962],
                [107.4949747, 11.013418],
                [107.49503, 11.0133642],
                [107.4950537, 11.0133114],
                [107.4950544, 11.0132823],
                [107.4950355, 11.0132311],
                [107.4949972, 11.0131962],
                [107.4949729, 11.0131861],
                [107.494947, 11.0131816],
                [107.494862, 11.0131884],
                [107.4946947, 11.0132247],
                [107.4946094, 11.0132379],
                [107.4945232, 11.0132422],
                [107.4943844, 11.0132301],
                [107.4942038, 11.013187],
                [107.4941573, 11.0131886],
                [107.4941121, 11.0131995],
                [107.4940701, 11.0132192],
                [107.4940332, 11.013247],
                [107.4940029, 11.0132817],
                [107.4939805, 11.0133216],
                [107.4939669, 11.0133653],
                [107.4939412, 11.0136977],
                [107.4939268, 11.0137452],
                [107.4938929, 11.0137819],
                [107.4938667, 11.0137953],
                [107.4938083, 11.0138004],
                [107.4937801, 11.0137917],
                [107.493735, 11.0137549],
                [107.4933714, 11.0132743],
                [107.4933294, 11.0132026],
                [107.4933162, 11.0131632],
                [107.4933067, 11.0130791],
                [107.4933295, 11.0128397],
                [107.493374, 11.0126146],
                [107.4934309, 11.0124124],
                [107.493421, 11.0123336],
                [107.4934047, 11.012297],
                [107.4933571, 11.0122397],
                [107.4932921, 11.0122022],
                [107.4930212, 11.0121259],
                [107.4929454, 11.012136],
                [107.4928788, 11.0121729],
                [107.4928308, 11.0122312],
                [107.4928156, 11.0122668],
                [107.4928074, 11.0123433],
                [107.4928842, 11.0127121],
                [107.4928665, 11.0127532],
                [107.4928288, 11.0127781],
                [107.4927834, 11.0127788],
                [107.4927624, 11.0127697],
                [107.4925035, 11.0124708],
                [107.4924123, 11.0123444],
                [107.4923587, 11.0122338],
                [107.4922085, 11.01186],
                [107.4921516, 11.0117777],
                [107.4921133, 11.0117449],
                [107.4920223, 11.0117012],
                [107.4919724, 11.0116915],
                [107.4918866, 11.0116946],
                [107.4918449, 11.0117054],
                [107.4917686, 11.0117441],
                [107.4915915, 11.0118899],
                [107.4915623, 11.0119051],
                [107.4914971, 11.0119114],
                [107.4914655, 11.0119021],
                [107.4914188, 11.0118671],
                [107.4914029, 11.0118426],
                [107.4913716, 11.0117073],
                [107.491338, 11.0116167],
                [107.4912798, 11.0115391],
                [107.4912429, 11.0115072],
                [107.4911571, 11.0114603],
                [107.49111, 11.0114464],
                [107.4910614, 11.0114391],
                [107.490969, 11.0114388],
                [107.4908849, 11.0114202],
                [107.4908093, 11.0113774],
                [107.4907769, 11.0113476],
                [107.4907279, 11.0112755],
                [107.4906206, 11.010928],
                [107.4905473, 11.0108384],
                [107.4905029, 11.0108007],
                [107.4904021, 11.0107424],
                [107.490347, 11.0107226],
                [107.4902223, 11.0107027],
                [107.490159, 11.0107049],
                [107.4900359, 11.0107331],
                [107.4899983, 11.0107557],
                [107.4899145, 11.0107817],
                [107.4898267, 11.0107805],
                [107.489783, 11.0107692],
                [107.4897041, 11.0107265],
                [107.4896711, 11.0106963],
                [107.4895263, 11.0104804],
                [107.4895001, 11.0104532],
                [107.4894345, 11.0104156],
                [107.4893975, 11.0104066],
                [107.4893592, 11.0104048],
                [107.4892849, 11.0104224],
                [107.4889647, 11.0106034],
                [107.4889264, 11.0106129],
                [107.4888477, 11.0106105],
                [107.4887752, 11.0105804],
                [107.4887192, 11.0105271],
                [107.4886995, 11.0104938],
                [107.4886802, 11.0104196],
                [107.4886894, 11.0103435],
                [107.4887972, 11.0099166],
                [107.4887868, 11.0098423],
                [107.4887484, 11.0097774],
                [107.4886855, 11.0097305],
                [107.4886482, 11.0097173],
                [107.4882738, 11.009689],
                [107.4881396, 11.009651],
                [107.4880747, 11.0096191],
                [107.4880145, 11.0095794],
                [107.487461, 11.0091008],
                [107.4874081, 11.0090716],
                [107.4873483, 11.0090613],
                [107.4873166, 11.0090638],
                [107.4872571, 11.009085],
                [107.4870687, 11.0092858],
                [107.4870029, 11.0093172],
                [107.4869299, 11.0093219],
                [107.4868645, 11.0093015],
                [107.4868107, 11.0092597],
                [107.4866885, 11.0090586],
                [107.4866658, 11.0090332],
                [107.4866048, 11.0090033],
                [107.4865367, 11.0090068],
                [107.4864793, 11.0090428],
                [107.4863101, 11.0092924],
                [107.4862859, 11.0093168],
                [107.4862258, 11.00935],
                [107.4861574, 11.0093588],
                [107.4860934, 11.0093431],
                [107.4860647, 11.0093271],
                [107.4860394, 11.0093061],
                [107.4859429, 11.009163],
                [107.4859032, 11.0091205],
                [107.485857, 11.009085],
                [107.4857647, 11.0090424],
                [107.4856639, 11.009027],
                [107.485341, 11.0090896],
                [107.4852312, 11.0090836],
                [107.4851754, 11.0090695],
                [107.4846983, 11.0089007],
                [107.484237, 11.008769],
                [107.4836254, 11.0084636],
                [107.4835709, 11.0084298],
                [107.483452, 11.0083808],
                [107.4833252, 11.0083588],
                [107.4832606, 11.0083583],
                [107.4831876, 11.0083663],
                [107.4831161, 11.0083832],
                [107.4830474, 11.0084089],
                [107.4829826, 11.0084429],
                [107.4828291, 11.008553],
                [107.4827188, 11.00859],
                [107.48266, 11.0085956],
                [107.4826009, 11.0085921],
                [107.4825432, 11.0085796],
                [107.4823524, 11.0085042],
                [107.4820751, 11.008411],
                [107.4819263, 11.0083701],
                [107.4815905, 11.0082937],
                [107.4815326, 11.0082521],
                [107.4815107, 11.008224],
                [107.481485, 11.0081582],
                [107.4814823, 11.0081196],
                [107.4815012, 11.008045],
                [107.4815221, 11.0080123],
                [107.481577, 11.0079525],
                [107.4816102, 11.007876],
                [107.4816103, 11.0077927],
                [107.4815977, 11.0077527],
                [107.4815542, 11.0076886],
                [107.4815242, 11.0076635],
                [107.4814063, 11.0076121],
                [107.4813633, 11.0075858],
                [107.4813249, 11.0075535],
                [107.4812655, 11.0074744],
                [107.481233, 11.0073826],
                [107.4811578, 11.0068602],
                [107.4811211, 11.006805],
                [107.4810625, 11.0067729],
                [107.4809998, 11.0067703],
                [107.4809716, 11.0067785],
                [107.4809235, 11.006811],
                [107.4807298, 11.0070706],
                [107.4806459, 11.0071381],
                [107.480546, 11.0071795],
                [107.4804926, 11.0071893],
                [107.4803936, 11.0071875],
                [107.4802984, 11.0071605],
                [107.4802136, 11.0071103],
                [107.4796262, 11.0066469],
                [107.4795576, 11.0066239],
                [107.4794854, 11.0066302],
                [107.4794244, 11.0066629],
                [107.4794011, 11.0066863],
                [107.47937, 11.0067439],
                [107.4793634, 11.0067759],
                [107.479358, 11.0071156],
                [107.4793502, 11.0071456],
                [107.4793046, 11.0072388],
                [107.479291, 11.0075421],
                [107.4792821, 11.0075718],
                [107.479246, 11.007622],
                [107.4791917, 11.0076527],
                [107.4791603, 11.0076587],
                [107.4790973, 11.0076501],
                [107.4790442, 11.0076158],
                [107.4789431, 11.0075075],
                [107.4788355, 11.007435],
                [107.4787331, 11.007392],
                [107.4786517, 11.0073843],
                [107.4786112, 11.0073902],
                [107.4785338, 11.0074221],
                [107.4784702, 11.0074789],
                [107.4783951, 11.0076685],
                [107.4783488, 11.0077342],
                [107.4782798, 11.0077764],
                [107.4781998, 11.0077884],
                [107.4781596, 11.0077824],
                [107.4780354, 11.0077434],
                [107.4779472, 11.0077264],
                [107.477815, 11.0077166],
                [107.4777093, 11.0077189],
                [107.4776322, 11.0077043],
                [107.4775958, 11.0076895],
                [107.4775319, 11.0076477],
                [107.4773356, 11.0074513],
                [107.477281, 11.0074125],
                [107.4771586, 11.0073578],
                [107.4770258, 11.0073367],
                [107.4768454, 11.0073622],
                [107.4766801, 11.0074158],
                [107.4765336, 11.0074921],
                [107.4762279, 11.007708],
                [107.4761362, 11.0077524],
                [107.4760347, 11.0077646],
                [107.4759482, 11.0077481],
                [107.4758819, 11.0077159],
                [107.475855, 11.0076763],
                [107.4758229, 11.0075869],
                [107.4758185, 11.0075372],
                [107.4758231, 11.0074876],
                [107.475917, 11.0071168],
                [107.4759154, 11.0070594],
                [107.4759054, 11.0070322],
                [107.4758699, 11.0069871],
                [107.4758192, 11.0069602],
                [107.4757907, 11.006955],
                [107.4756971, 11.0069762],
                [107.4756539, 11.0069971],
                [107.4755818, 11.0070551],
                [107.4755532, 11.0070913],
                [107.4754107, 11.0073477],
                [107.4753038, 11.0074924],
                [107.4752623, 11.0075303],
                [107.4752152, 11.0075613],
                [107.4751093, 11.0075999],
                [107.4750531, 11.0076066],
                [107.4749541, 11.0075972],
                [107.4748614, 11.0075616],
                [107.4747822, 11.0075026],
                [107.474707, 11.0074261],
                [107.4746388, 11.0073434],
                [107.4745783, 11.0072551],
                [107.4744791, 11.0070577],
                [107.4744067, 11.006797],
                [107.4743918, 11.0067722],
                [107.4743475, 11.0067352],
                [107.4743203, 11.0067249],
                [107.4742621, 11.0067229],
                [107.4742341, 11.0067314],
                [107.4742087, 11.0067458],
                [107.4741706, 11.0067891],
                [107.4740743, 11.0070449],
                [107.4740248, 11.0071089],
                [107.4739641, 11.0071524],
                [107.4738822, 11.0071835],
                [107.4737951, 11.0071946],
                [107.4736953, 11.0071819],
                [107.4735828, 11.0071432],
                [107.4735131, 11.0071478],
                [107.47345, 11.0071801],
                [107.4733043, 11.0073315],
                [107.4732223, 11.0074568],
                [107.4731635, 11.007594],
                [107.4731314, 11.0077268],
                [107.4730835, 11.0078414],
                [107.4730065, 11.0079397],
                [107.4729013, 11.0080172],
                [107.4727786, 11.0080634],
                [107.4725559, 11.0081082],
                [107.4724785, 11.0081127],
                [107.4724404, 11.0081051],
                [107.4723709, 11.0080713],
                [107.4723166, 11.0080156],
                [107.472285, 11.0079449],
                [107.4722558, 11.0077773],
                [107.4722146, 11.0077126],
                [107.4721844, 11.0076882],
                [107.472113, 11.0076611],
                [107.4720745, 11.0076593],
                [107.4719861, 11.00769],
                [107.4719471, 11.0077159],
                [107.4718924, 11.0077738],
                [107.4717068, 11.0081177],
                [107.4716564, 11.0081672],
                [107.4715908, 11.0081962],
                [107.4715185, 11.0082002],
                [107.4712965, 11.0081434],
                [107.4712149, 11.0081395],
                [107.4711369, 11.0081632],
                [107.4710697, 11.0082136],
                [107.4710433, 11.0082481],
                [107.471012, 11.0083284],
                [107.4710064, 11.008662],
                [107.4709788, 11.0087401],
                [107.4709541, 11.0087775],
                [107.4709233, 11.0088103],
                [107.4708474, 11.0088585],
                [107.4705431, 11.0089854],
                [107.4703382, 11.0090322],
                [107.4702653, 11.0090797],
                [107.4702418, 11.0091059],
                [107.4702078, 11.0091671],
                [107.4701124, 11.0094931],
                [107.470055, 11.0095812],
                [107.4699756, 11.009651],
                [107.4698816, 11.0096969],
                [107.4697785, 11.0097168],
                [107.4696001, 11.0097168],
                [107.4694081, 11.0096865],
                [107.4693886, 11.0096878],
                [107.4693546, 11.0097057],
                [107.4693345, 11.0097431],
                [107.4693388, 11.0098043],
                [107.4693489, 11.0098406],
                [107.4693856, 11.0099064],
                [107.4694305, 11.0099504],
                [107.4695358, 11.0100196],
                [107.4696454, 11.0100578],
                [107.4697611, 11.0100696],
                [107.4698208, 11.0100988],
                [107.4698444, 11.0101224],
                [107.4698728, 11.0101817],
                [107.4698764, 11.0102144],
                [107.469862, 11.010277],
                [107.469822, 11.0103277],
                [107.469764, 11.0103571],
                [107.4691037, 11.0103643],
                [107.4690127, 11.0103882],
                [107.468942, 11.0104281],
                [107.4688481, 11.0105242],
                [107.4687925, 11.0105999],
                [107.4687054, 11.0107633],
                [107.4686515, 11.0109378],
                [107.4686319, 11.0110913],
                [107.4686426, 11.0112176],
                [107.4686909, 11.0113597],
                [107.4689189, 11.0116572],
                [107.4690288, 11.0117794],
                [107.4691476, 11.0118666],
                [107.4692049, 11.0118974],
                [107.4693266, 11.0119442],
                [107.4693475, 11.0119654],
                [107.4693684, 11.0120201],
                [107.4693577, 11.0120763],
                [107.4693418, 11.0120999],
                [107.4692944, 11.0121311],
                [107.4690174, 11.0122089],
                [107.4688706, 11.0122417],
                [107.4687983, 11.0122855],
                [107.4687485, 11.0123431],
                [107.4687204, 11.012405],
                [107.4687204, 11.0125629],
                [107.4687112, 11.0125876],
                [107.468675, 11.0126258],
                [107.4686214, 11.0126409],
                [107.4685654, 11.0126244],
                [107.468483, 11.0125484],
                [107.4683955, 11.0124439],
                [107.4682564, 11.0122154],
                [107.4681785, 11.0120434],
                [107.4681464, 11.0119548],
                [107.4681052, 11.0118964],
                [107.4680437, 11.0118592],
                [107.4680084, 11.0118507],
                [107.467936, 11.0118556],
                [107.4679021, 11.011869],
                [107.4678717, 11.0118889],
                [107.4678266, 11.0119448],
                [107.4677752, 11.0121051],
                [107.467744, 11.0122338],
                [107.4677165, 11.0124227],
                [107.467721, 11.0126174],
                [107.467736, 11.0127156],
                [107.4677289, 11.0127432],
                [107.4676973, 11.0127905],
                [107.4676743, 11.0128078],
                [107.4676306, 11.0128241],
                [107.4676073, 11.0128262],
                [107.46754, 11.0127978],
                [107.4674823, 11.0127468],
                [107.4674597, 11.012713],
                [107.46739, 11.0124603],
                [107.4673559, 11.0124178],
                [107.4673051, 11.0123972],
                [107.4672773, 11.0123971],
                [107.4672527, 11.0124029],
                [107.4672109, 11.0124304],
                [107.4671862, 11.0124734],
                [107.467172, 11.0125731],
                [107.4671872, 11.0126775],
                [107.4672071, 11.0127292],
                [107.4672693, 11.0128209],
                [107.4675255, 11.0130136],
                [107.4676348, 11.0130588],
                [107.4677521, 11.0130763],
                [107.4678641, 11.0130666],
                [107.4679185, 11.0130522],
                [107.4679708, 11.0130316],
                [107.4680431, 11.0129876],
                [107.468098, 11.0129709],
                [107.4681544, 11.0129815],
                [107.468199, 11.0130168],
                [107.4682215, 11.0130684],
                [107.4682188, 11.0134765],
                [107.4682136, 11.0135111],
                [107.4681893, 11.0135766],
                [107.4681435, 11.0136382],
                [107.4680537, 11.0137022],
                [107.4679211, 11.0137582],
                [107.4678029, 11.0137813],
                [107.4676824, 11.0137819],
                [107.4674941, 11.0137408],
                [107.4673212, 11.0136707],
                [107.4671398, 11.013573],
                [107.467027, 11.0135456],
                [107.4669689, 11.0135423],
                [107.46686, 11.0135552],
                [107.466757, 11.0135924],
                [107.4665558, 11.0136345],
                [107.4660838, 11.0137135],
                [107.4660372, 11.0137351],
                [107.4659955, 11.0137647],
                [107.4659599, 11.0138014],
                [107.4659318, 11.0138437],
                [107.4659121, 11.0138904],
                [107.4659014, 11.0139399],
                [107.4659, 11.0139897],
                [107.4659078, 11.014039],
                [107.4659245, 11.0140861],
                [107.4659496, 11.0141295],
                [107.4662125, 11.0144191],
                [107.4662735, 11.0144724],
                [107.4663092, 11.0145444],
                [107.4663158, 11.0145842],
                [107.4663059, 11.0146615],
                [107.4662906, 11.0146965],
                [107.4662419, 11.0147552],
                [107.4661066, 11.0148349],
                [107.4659818, 11.0149351],
                [107.4658681, 11.0150655],
                [107.4657909, 11.015176],
                [107.4657133, 11.0152348],
                [107.4656241, 11.0152641],
                [107.4655339, 11.0152642],
                [107.465381, 11.0151826],
                [107.4653401, 11.0151468],
                [107.4652875, 11.0151318],
                [107.4652335, 11.0151404],
                [107.4651852, 11.0151747],
                [107.4651587, 11.015227],
                [107.4651551, 11.0153354],
                [107.4651611, 11.0154143],
                [107.4651925, 11.0155688],
                [107.4652147, 11.0157249],
                [107.4652306, 11.0159988],
                [107.4652116, 11.0160479],
                [107.4651718, 11.016083],
                [107.4651415, 11.0160939],
                [107.4651094, 11.016096],
                [107.4650779, 11.0160891],
                [107.4650497, 11.0160738],
                [107.465027, 11.0160514],
                [107.4649036, 11.0159092],
                [107.4648641, 11.0158766],
                [107.4647723, 11.0158307],
                [107.4646708, 11.015814],
                [107.4645686, 11.0158279],
                [107.4645201, 11.0158462],
                [107.4644345, 11.0159033],
                [107.4643678, 11.0159704],
                [107.4643321, 11.0159951],
                [107.4642932, 11.0160145],
                [107.4642089, 11.0160356],
                [107.4641115, 11.0160298],
                [107.4640648, 11.0160153],
                [107.4640211, 11.0159935],
                [107.4639355, 11.0159204],
                [107.4638849, 11.0158949],
                [107.4638307, 11.0158776],
                [107.4637224, 11.0158692],
                [107.4636167, 11.0158934],
                [107.4632876, 11.0160602],
                [107.4632313, 11.0160818],
                [107.4631722, 11.0160948],
                [107.4631119, 11.0160988],
                [107.4630059, 11.0160835],
                [107.4629555, 11.0160655],
                [107.462908, 11.0160409],
                [107.4627158, 11.015879],
                [107.4626474, 11.0157935],
                [107.4626034, 11.0156939],
                [107.4625866, 11.0155802],
                [107.4626033, 11.0154602],
                [107.4626539, 11.0153498],
                [107.4627282, 11.0152583],
                [107.4627864, 11.0151613],
                [107.4628195, 11.0150536],
                [107.4628265, 11.0149553],
                [107.4628125, 11.0148704],
                [107.462774, 11.0148073],
                [107.4627466, 11.014782],
                [107.46268, 11.0147482],
                [107.4626431, 11.0147408],
                [107.4625682, 11.0147466],
                [107.4625006, 11.0147786],
                [107.4624494, 11.0148324],
                [107.4618807, 11.015771],
                [107.4618215, 11.0158494],
                [107.4617439, 11.0159105],
                [107.4616563, 11.0159494],
                [107.4615616, 11.0159658],
                [107.4607307, 11.0159762],
                [107.4606722, 11.0159981],
                [107.4606363, 11.0160411],
                [107.4606281, 11.0160648],
                [107.4606281, 11.0161146],
                [107.4607086, 11.0162883],
                [107.4607238, 11.0163268],
                [107.46073, 11.0163677],
                [107.4607269, 11.0164088],
                [107.4607147, 11.0164483],
                [107.460694, 11.0164842],
                [107.4606328, 11.0165377],
                [107.4605957, 11.0165532],
                [107.4605158, 11.0165599],
                [107.4604765, 11.0165508],
                [107.4604082, 11.0165095],
                [107.4603328, 11.0164791],
                [107.4601742, 11.0164428],
                [107.4600929, 11.0164372],
                [107.4599308, 11.0164516],
                [107.4597885, 11.0164938],
                [107.4597211, 11.0165248],
                [107.4594981, 11.0166579],
                [107.4593246, 11.0167254],
                [107.4592252, 11.0167497],
                [107.4591238, 11.0167646],
                [107.4588874, 11.0167675],
                [107.4587882, 11.0167965],
                [107.4585628, 11.0169597],
                [107.4585454, 11.0169797],
                [107.4585253, 11.0170282],
                [107.4585237, 11.0170572],
                [107.4585414, 11.0171124],
                [107.4587613, 11.0173388],
                [107.4587952, 11.0173974],
                [107.4588007, 11.0174308],
                [107.4587874, 11.0174969],
                [107.4587694, 11.0175257],
                [107.4587155, 11.0175673],
                [107.4586484, 11.0175802],
                [107.4585826, 11.0175616],
                [107.4583372, 11.0173846],
                [107.458234, 11.0173453],
                [107.4581793, 11.0173362],
                [107.4580831, 11.0173377],
                [107.4580358, 11.0173467],
                [107.4579403, 11.0173809],
                [107.4578897, 11.0173868],
                [107.4577887, 11.0173755],
                [107.4577408, 11.0173586],
                [107.4576637, 11.0173112],
                [107.4576309, 11.0172802],
                [107.4576027, 11.017245],
                [107.4575222, 11.0171013],
                [107.4574775, 11.0170407],
                [107.4573752, 11.01693],
                [107.4572579, 11.0168353],
                [107.4571291, 11.0167597],
                [107.4570605, 11.0167319],
                [107.4569162, 11.0166987],
                [107.4568422, 11.0166937],
                [107.4566947, 11.016707],
                [107.456627, 11.0167239],
                [107.4564985, 11.0167773],
                [107.456439, 11.0168132],
                [107.4563326, 11.0169018],
                [107.4559403, 11.017194],
                [107.4557607, 11.0172973],
                [107.4555695, 11.0173783],
                [107.4553601, 11.0174379],
                [107.4551447, 11.0174708],
                [107.4548346, 11.0174547],
                [107.4547007, 11.0174633],
                [107.4546357, 11.0174803],
                [107.4545152, 11.0175384],
                [107.4544182, 11.0176203],
                [107.45438, 11.017667],
                [107.4543216, 11.0177719],
                [107.4542755, 11.0179408],
                [107.4542632, 11.0180275],
                [107.4542606, 11.0182024],
                [107.4542863, 11.0183719],
                [107.4543395, 11.0185351],
                [107.4543759, 11.0186131],
                [107.454583, 11.0189213],
                [107.4546002, 11.0189598],
                [107.4546096, 11.019043],
                [107.4546014, 11.0190843],
                [107.454561, 11.0191581],
                [107.4545339, 11.0191846],
                [107.4544676, 11.0192215],
                [107.4544304, 11.0192307],
                [107.4543542, 11.0192288],
                [107.4539276, 11.0190841],
                [107.4538458, 11.0190676],
                [107.4537626, 11.0190602],
                [107.4536791, 11.019062],
                [107.4535963, 11.0190728],
                [107.4535152, 11.0190926],
                [107.4534369, 11.0191211],
                [107.4533623, 11.019158],
                [107.4532923, 11.0192028],
                [107.4531793, 11.0193032],
                [107.4531312, 11.0193613],
                [107.4530542, 11.0194903],
                [107.4530053, 11.019632],
                [107.4529666, 11.0200081],
                [107.4529484, 11.0200581],
                [107.4529218, 11.0201044],
                [107.4528875, 11.0201455],
                [107.4528466, 11.0201803],
                [107.4528003, 11.0202077],
                [107.4527499, 11.020227],
                [107.4526969, 11.0202375],
                [107.4525989, 11.0202335],
                [107.4525516, 11.0202204],
                [107.452466, 11.0201736],
                [107.4523991, 11.0201032],
                [107.4521632, 11.0196726],
                [107.4521142, 11.0196438],
                [107.4520858, 11.0196395],
                [107.452031, 11.0196521],
                [107.452008, 11.0196678],
                [107.4519772, 11.0197131],
                [107.4519599, 11.0198623],
                [107.4519612, 11.0199428],
                [107.452029, 11.0205929],
                [107.4520278, 11.020644],
                [107.4520013, 11.0207425],
                [107.4519454, 11.0208282],
                [107.4518654, 11.020893],
                [107.4518182, 11.0209157],
                [107.451716, 11.0209381],
                [107.4516117, 11.0209282],
                [107.4515157, 11.0208872],
                [107.4514375, 11.0208346],
                [107.4513558, 11.0208141],
                [107.4512923, 11.02082],
                [107.4512054, 11.0208476],
                [107.4511468, 11.020856],
                [107.4510875, 11.0208549],
                [107.4510306, 11.0208446],
                [107.450976, 11.0208256],
                [107.4509253, 11.0207983],
                [107.4506503, 11.0206113],
                [107.4506069, 11.0205898],
                [107.4505604, 11.0205757],
                [107.4504741, 11.0205697],
                [107.4503995, 11.020585],
                [107.4499717, 11.0207272],
                [107.4493681, 11.0209394],
                [107.4492781, 11.0209977],
                [107.4492126, 11.0210818],
                [107.4491788, 11.0211823],
                [107.4491792, 11.0212807],
                [107.4492068, 11.0213676],
                [107.4492583, 11.0214433],
                [107.4494514, 11.0216618],
                [107.4495123, 11.0217212],
                [107.4496177, 11.021854],
                [107.4496626, 11.0219286],
                [107.4498019, 11.0222133],
                [107.4499303, 11.0224048],
                [107.4500039, 11.0224937],
                [107.4501413, 11.0226321],
                [107.4502157, 11.0226954],
                [107.4503303, 11.0227772],
                [107.4503882, 11.0228403],
                [107.4504194, 11.0229196],
                [107.4504197, 11.0230045],
                [107.4504085, 11.0230444],
                [107.4503658, 11.0231154],
                [107.4503007, 11.0231674],
                [107.4502212, 11.0231941],
                [107.4500726, 11.0231995],
                [107.4499986, 11.0231914],
                [107.4498419, 11.0231495],
                [107.4496698, 11.0230775],
                [107.449569, 11.0230459],
                [107.4494661, 11.0230216],
                [107.4492717, 11.0229966],
                [107.4491794, 11.0230051],
                [107.4490943, 11.0230413],
                [107.4490248, 11.0231014],
                [107.4489768, 11.023182],
                [107.448963, 11.023228],
                [107.4489593, 11.0233236],
                [107.4489694, 11.0233705],
                [107.4490375, 11.0235393],
                [107.4490625, 11.0236706],
                [107.4490651, 11.0237447],
                [107.4490464, 11.0238917],
                [107.4490435, 11.0239802],
                [107.4490624, 11.024156],
                [107.44911, 11.0243172],
                [107.4491423, 11.0243902],
                [107.4492203, 11.0245258],
                [107.4492524, 11.0245953],
                [107.4492763, 11.0246678],
                [107.4492985, 11.0248184],
                [107.4492967, 11.024892],
                [107.4492689, 11.0250362],
                [107.4491501, 11.0253078],
                [107.4490464, 11.0255872],
                [107.4489711, 11.0258475],
                [107.4489296, 11.0260257],
                [107.4489119, 11.02607],
                [107.4488878, 11.0261111],
                [107.4488224, 11.0261809],
                [107.4487836, 11.0262073],
                [107.4486963, 11.0262426],
                [107.4486025, 11.0262519],
                [107.4483, 11.0262359],
                [107.4482667, 11.0262401],
                [107.4482048, 11.0262651],
                [107.4481758, 11.0262872],
                [107.4481331, 11.0263454],
                [107.448066, 11.0265402],
                [107.4480337, 11.026604],
                [107.4480092, 11.0266304],
                [107.4479481, 11.0266681],
                [107.4479136, 11.0266781],
                [107.447842, 11.0266794],
                [107.4475531, 11.0266311],
                [107.4474706, 11.0266276],
                [107.4474304, 11.0266374],
                [107.4473593, 11.0266784],
                [107.4473317, 11.0267072],
                [107.4472949, 11.0267772],
                [107.4472922, 11.0268904],
                [107.4473166, 11.0269977],
                [107.4473383, 11.0270484],
                [107.4474509, 11.0272308],
                [107.4474946, 11.027325],
                [107.4475303, 11.0274223],
                [107.4475913, 11.0276563],
                [107.4476172, 11.0277206],
                [107.4476491, 11.0277822],
                [107.4477284, 11.0278947],
                [107.447826, 11.0279905],
                [107.4479065, 11.02805],
                [107.4479276, 11.0280732],
                [107.4479434, 11.0281002],
                [107.4479567, 11.0281606],
                [107.447944, 11.0282212],
                [107.447928, 11.0282488],
                [107.4478805, 11.0282912],
                [107.4478194, 11.0283108],
                [107.4476011, 11.0283061],
                [107.4474987, 11.0283226],
                [107.4474354, 11.0283437],
                [107.4471465, 11.0284785],
                [107.4470275, 11.0285208],
                [107.4467826, 11.0285832],
                [107.4466609, 11.0286027],
                [107.446415, 11.0286198],
                [107.4462378, 11.0286149],
                [107.4461849, 11.0286039],
                [107.4460879, 11.0285578],
                [107.44601, 11.028483],
                [107.4459603, 11.0283864],
                [107.4459482, 11.0283334],
                [107.4459511, 11.0282251],
                [107.4460691, 11.0279356],
                [107.4460756, 11.0278834],
                [107.4460659, 11.0277791],
                [107.44605, 11.027729],
                [107.4459974, 11.0276378],
                [107.4459189, 11.0275632],
                [107.4458712, 11.0275344],
                [107.4457654, 11.0274991],
                [107.4457097, 11.0274933],
                [107.4455488, 11.027511],
                [107.4454706, 11.0275323],
                [107.4453235, 11.0275986],
                [107.4452062, 11.0276828],
                [107.4451542, 11.0277328],
                [107.445066, 11.027846],
                [107.4448535, 11.0284605],
                [107.4448266, 11.0285193],
                [107.4447517, 11.028625],
                [107.4446529, 11.0287096],
                [107.4445378, 11.0287673],
                [107.4444118, 11.0287961],
                [107.4442315, 11.0288027],
                [107.4441159, 11.0287974],
                [107.4438189, 11.0287574],
                [107.4437267, 11.0287585],
                [107.4436351, 11.0287689],
                [107.4435452, 11.0287885],
                [107.4433909, 11.0288461],
                [107.4432501, 11.0289307],
                [107.4432192, 11.0289737],
                [107.4431754, 11.0290694],
                [107.4431631, 11.0291206],
                [107.4431589, 11.0292256],
                [107.443167, 11.0292774],
                [107.443203, 11.029376],
                [107.4432302, 11.0294211],
                [107.4433011, 11.0294994],
                [107.4434825, 11.0296283],
                [107.4435802, 11.0296824],
                [107.4437866, 11.0297679],
                [107.4439996, 11.0298211],
                [107.4441086, 11.029836],
                [107.4443284, 11.0298416],
                [107.4443925, 11.0298811],
                [107.444416, 11.0299105],
                [107.4444397, 11.0299811],
                [107.4444315, 11.0300475],
                [107.4443969, 11.030105],
                [107.4436015, 11.0308341],
                [107.4435112, 11.0308944],
                [107.4434072, 11.0309267],
                [107.4433527, 11.0309315],
                [107.443258, 11.0309213],
                [107.443169, 11.0308881],
                [107.4427888, 11.0306367],
                [107.4426637, 11.0305784],
                [107.4425283, 11.0305505],
                [107.442459, 11.0305484],
                [107.4423221, 11.0305682],
                [107.4421878, 11.0306221],
                [107.4421268, 11.0306607],
                [107.4420215, 11.0307587],
                [107.4419439, 11.0308789],
                [107.4417856, 11.0312659],
                [107.4417354, 11.031352],
                [107.44166, 11.0314181],
                [107.4416152, 11.0314415],
                [107.4415172, 11.0314657],
                [107.4414664, 11.031466],
                [107.4413734, 11.0314449],
                [107.441291, 11.0313974],
                [107.4412563, 11.0313651],
                [107.4412036, 11.0312869],
                [107.4411902, 11.0310947],
                [107.4411783, 11.0310573],
                [107.4411578, 11.0310236],
                [107.4411299, 11.0309954],
                [107.4410961, 11.0309744],
                [107.4410583, 11.0309616],
                [107.4410185, 11.0309578],
                [107.4409783, 11.0309634],
                [107.4409405, 11.0309781],
                [107.4409074, 11.0310011],
                [107.4408807, 11.0310312],
                [107.4408619, 11.0310666],
                [107.4408039, 11.0314844],
                [107.4407888, 11.0315467],
                [107.4407658, 11.0316067],
                [107.4406976, 11.0317158],
                [107.4406038, 11.0318047],
                [107.4404886, 11.0318685],
                [107.4404248, 11.0318892],
                [107.4402916, 11.0319056],
                [107.4401838, 11.0318918],
                [107.4400834, 11.0318508],
                [107.4399974, 11.0317855],
                [107.439933, 11.0317023],
                [107.4398931, 11.0316079],
                [107.4398826, 11.0315578],
                [107.4398587, 11.0312286],
                [107.4398434, 11.0311808],
                [107.4398063, 11.03113],
                [107.4397378, 11.031081],
                [107.439656, 11.0310599],
                [107.4396106, 11.0310612],
                [107.439525, 11.0310898],
                [107.4394575, 11.0311487],
                [107.4393876, 11.0312623],
                [107.4392925, 11.0313567],
                [107.4392455, 11.0313899],
                [107.4391423, 11.0314415],
                [107.4389121, 11.031508],
                [107.4388791, 11.031525],
                [107.438826, 11.0315759],
                [107.4387966, 11.0316428],
                [107.4387952, 11.0317155],
                [107.4388052, 11.0317506],
                [107.4388446, 11.031812],
                [107.4389047, 11.0318543],
                [107.4390259, 11.0319029],
                [107.4391862, 11.0319987],
                [107.439259, 11.032057],
                [107.4393208, 11.032121],
                [107.4393453, 11.0321581],
                [107.4393779, 11.0322341],
                [107.4393918, 11.0323097],
                [107.4393985, 11.032469],
                [107.4393913, 11.0325656],
                [107.4393757, 11.0326612],
                [107.4393625, 11.0327004],
                [107.4393428, 11.0327368],
                [107.4392858, 11.0327971],
                [107.4392503, 11.0328192],
                [107.4391705, 11.0328441],
                [107.4390833, 11.0328404],
                [107.4390031, 11.032807],
                [107.4389687, 11.0327803],
                [107.4388073, 11.0326113],
                [107.4387336, 11.0325505],
                [107.4385622, 11.0324399],
                [107.4384646, 11.0323924],
                [107.4383631, 11.0323532],
                [107.4382819, 11.0322966],
                [107.4382505, 11.0322584],
                [107.4382195, 11.0321968],
                [107.4381942, 11.0320214],
                [107.4381778, 11.0319534],
                [107.4381225, 11.031825],
                [107.4380435, 11.0317163],
                [107.4379478, 11.0316303],
                [107.4377608, 11.0315226],
                [107.4376247, 11.0314562],
                [107.4373439, 11.0313422],
                [107.4371056, 11.0312676],
                [107.4369845, 11.0312369],
                [107.4369077, 11.0312326],
                [107.4368702, 11.0312416],
                [107.4368352, 11.0312576],
                [107.4367779, 11.031308],
                [107.4367465, 11.0313703],
                [107.4367396, 11.0314395],
                [107.4367557, 11.0315475],
                [107.4367887, 11.0316924],
                [107.4368523, 11.0318634],
                [107.4369201, 11.0319871],
                [107.4369925, 11.0320557],
                [107.4370368, 11.0320808],
                [107.4370846, 11.0320985],
                [107.4372289, 11.0321303],
                [107.4374136, 11.0321873],
                [107.4375181, 11.0322282],
                [107.4377194, 11.0323268],
                [107.4377712, 11.0323822],
                [107.4377888, 11.0324157],
                [107.4378048, 11.0324892],
                [107.4377927, 11.032566],
                [107.4377755, 11.0326027],
                [107.4377218, 11.0326632],
                [107.4376872, 11.0326849],
                [107.4374007, 11.0327784],
                [107.4372818, 11.0328463],
                [107.4371789, 11.0329358],
                [107.4371347, 11.0329876],
                [107.4370639, 11.0331005],
                [107.4369845, 11.0333043],
                [107.4369308, 11.033372],
                [107.4368937, 11.033402],
                [107.4368075, 11.0334433],
                [107.4367022, 11.0334629],
                [107.4365889, 11.0334957],
                [107.4364704, 11.0335537],
                [107.4363832, 11.0336177],
                [107.4363048, 11.0336506],
                [107.4362195, 11.0336512],
                [107.4361435, 11.0336212],
                [107.4360863, 11.0335683],
                [107.436066, 11.033535],
                [107.4360522, 11.0334987],
                [107.4359926, 11.0332037],
                [107.4359317, 11.0330469],
                [107.4358886, 11.0329721],
                [107.4357802, 11.0328376],
                [107.4355282, 11.0325906],
                [107.4354626, 11.0325561],
                [107.4353886, 11.032548],
                [107.4353109, 11.0325704],
                [107.4352491, 11.0326217],
                [107.4350855, 11.0329047],
                [107.4350532, 11.0329756],
                [107.4350153, 11.0331141],
                [107.4349849, 11.0335905],
                [107.4349679, 11.0336589],
                [107.4349485, 11.0336888],
                [107.4348924, 11.0337327],
                [107.4348606, 11.033744],
                [107.4347935, 11.0337467],
                [107.4347609, 11.0337379],
                [107.434683, 11.0336908],
                [107.4346076, 11.033618],
                [107.4345735, 11.0335695],
                [107.4344567, 11.0332926],
                [107.4344015, 11.0331851],
                [107.4342984, 11.0330192],
                [107.4341967, 11.0328911],
                [107.4341462, 11.0328486],
                [107.4340902, 11.0328135],
                [107.4340296, 11.0327864],
                [107.4339658, 11.032768],
                [107.4338999, 11.0327586],
                [107.4337814, 11.0327647],
                [107.4336681, 11.0327996],
                [107.4335673, 11.0328613],
                [107.4334359, 11.0329797],
                [107.4334105, 11.0330227],
                [107.4333835, 11.0331181],
                [107.4333904, 11.0332169],
                [107.4334301, 11.0333075],
                [107.4334979, 11.0333797],
                [107.43354, 11.0334065],
                [107.4336211, 11.0334369],
                [107.4336522, 11.0334562],
                [107.4336773, 11.0334826],
                [107.4337029, 11.0335439],
                [107.4336934, 11.0339143],
                [107.4336713, 11.0339875],
                [107.4336264, 11.0340499],
                [107.4335633, 11.0340949],
                [107.4334885, 11.0341176],
                [107.4334137, 11.0341199],
                [107.4333791, 11.0341119],
                [107.4333232, 11.034076],
                [107.4332903, 11.0340235],
                [107.433283, 11.0339933],
                [107.4332133, 11.0335036],
                [107.4331783, 11.0334504],
                [107.4331521, 11.0334315],
                [107.4331221, 11.0334194],
                [107.4330656, 11.0334161],
                [107.4330381, 11.0334233],
                [107.4329907, 11.0334536],
                [107.432819, 11.03368],
                [107.4326912, 11.033888],
                [107.4325596, 11.0341454],
                [107.4324954, 11.0342099],
                [107.4324562, 11.0342337],
                [107.4323688, 11.0342613],
                [107.4322786, 11.0342606],
                [107.4321948, 11.0342331],
                [107.4321571, 11.0342102],
                [107.4320948, 11.0341486],
                [107.4319922, 11.0339915],
                [107.4318627, 11.033855],
                [107.431789, 11.0337958],
                [107.4316426, 11.0337059],
                [107.4314312, 11.0336163],
                [107.4313404, 11.0335472],
                [107.4313036, 11.0335036],
                [107.4312539, 11.033411],
                [107.431239, 11.0333607],
                [107.4311599, 11.0327256],
                [107.4311054, 11.0325086],
                [107.4310675, 11.0324032],
                [107.4309601, 11.0321814],
                [107.4308756, 11.0320528],
                [107.430823, 11.0320221],
                [107.4307925, 11.0320173],
                [107.4307315, 11.0320309],
                [107.4307052, 11.032049],
                [107.4306709, 11.0321017],
                [107.4305807, 11.0323946],
                [107.4304345, 11.0327902],
                [107.4304181, 11.0328789],
                [107.4304338, 11.0329677],
                [107.4304803, 11.0330463],
                [107.4305526, 11.0331045],
                [107.430625, 11.0331423],
                [107.4306684, 11.0331966],
                [107.4306802, 11.0332293],
                [107.4306813, 11.0332983],
                [107.4306553, 11.033358],
                [107.4306073, 11.0334028],
                [107.4305452, 11.0334253],
                [107.4304356, 11.0334454],
                [107.4302133, 11.0334615],
                [107.4299998, 11.0334467],
                [107.4298986, 11.0334292],
                [107.4297686, 11.0333919],
                [107.4297181, 11.0333507],
                [107.4296995, 11.033324],
                [107.4296789, 11.0332643],
                [107.429681, 11.0332027],
                [107.4297084, 11.0331189],
                [107.4297184, 11.0330626],
                [107.4297135, 11.0329758],
                [107.4296981, 11.0329348],
                [107.429644, 11.0328659],
                [107.4296076, 11.0328407],
                [107.4295222, 11.0328137],
                [107.4294767, 11.0328134],
                [107.429432, 11.0328223],
                [107.4293901, 11.0328399],
                [107.4293527, 11.0328655],
                [107.4292797, 11.0329514],
                [107.4292195, 11.0330719],
                [107.4291796, 11.0332761],
                [107.4291471, 11.0333233],
                [107.4291172, 11.0333466],
                [107.4290826, 11.0333622],
                [107.4289945, 11.03337],
                [107.4288782, 11.0333616],
                [107.4286589, 11.0333246],
                [107.428484, 11.0333261],
                [107.4283587, 11.0333471],
                [107.4283222, 11.033362],
                [107.4282608, 11.0334103],
                [107.4282381, 11.033442],
                [107.4282127, 11.0335125],
                [107.4282101, 11.0335487],
                [107.4282242, 11.0336197],
                [107.4284507, 11.0340432],
                [107.4284653, 11.034086],
                [107.4284689, 11.0341759],
                [107.4284577, 11.0342197],
                [107.428416, 11.0342923],
                [107.4283861, 11.0343222],
                [107.4283129, 11.0343645],
                [107.4282079, 11.0343968],
                [107.4280341, 11.0344348],
                [107.4279052, 11.0344777],
                [107.4277697, 11.0345553],
                [107.4276989, 11.0346145],
                [107.4275471, 11.0347199],
                [107.4274442, 11.0347774],
                [107.4271573, 11.034904],
                [107.427093, 11.0349476],
                [107.4270347, 11.0349987],
                [107.4269833, 11.0350564],
                [107.4269396, 11.03512],
                [107.4268879, 11.03523],
                [107.4268591, 11.0353478],
                [107.4268482, 11.0357875],
                [107.4268672, 11.0359046],
                [107.4268833, 11.0359533],
                [107.4269289, 11.0360454],
                [107.4271474, 11.0362874],
                [107.4271679, 11.0363266],
                [107.4271883, 11.0364121],
                [107.4271876, 11.0364562],
                [107.4271652, 11.0365396],
                [107.4271182, 11.0366108],
                [107.4270509, 11.0366641],
                [107.4269842, 11.0366825],
                [107.4268463, 11.0366945],
                [107.4267117, 11.0366732],
                [107.4265881, 11.0366215],
                [107.4265319, 11.0365851],
                [107.4259552, 11.0360609],
                [107.4258082, 11.0359664],
                [107.4257287, 11.0359296],
                [107.4255609, 11.0358782],
                [107.4253924, 11.0358583],
                [107.4253075, 11.0358594],
                [107.4251396, 11.035884],
                [107.4249548, 11.0359359],
                [107.4248495, 11.0359559],
                [107.4246397, 11.0359694],
                [107.4244344, 11.0359493],
                [107.4240267, 11.0358703],
                [107.4239182, 11.0358609],
                [107.4238114, 11.0358817],
                [107.4237147, 11.0359309],
                [107.4236356, 11.0360053],
                [107.4236047, 11.0360502],
                [107.423564, 11.0361507],
                [107.4235515, 11.0364389],
                [107.4235401, 11.0364817],
                [107.4234932, 11.0365569],
                [107.4234205, 11.0366088],
                [107.4233812, 11.0366225],
                [107.4232984, 11.0366282],
                [107.4232188, 11.0366046],
                [107.423153, 11.0365548],
                [107.423072, 11.0364623],
                [107.4230235, 11.0364239],
                [107.4229706, 11.0363916],
                [107.4228566, 11.0363474],
                [107.4227352, 11.036331],
                [107.4225853, 11.0363421],
                [107.4224417, 11.0363858],
                [107.4223745, 11.0364192],
                [107.4222638, 11.036498],
                [107.4221718, 11.0365973],
                [107.4220677, 11.0367728],
                [107.4220264, 11.0368285],
                [107.4219256, 11.0369241],
                [107.4217995, 11.0369974],
                [107.4217276, 11.0370232],
                [107.4216529, 11.0370401],
                [107.4215767, 11.0370476],
                [107.4212544, 11.0370303],
                [107.4209827, 11.0370402],
                [107.4207957, 11.0370646],
                [107.4207585, 11.0370788],
                [107.4206958, 11.0371265],
                [107.4206725, 11.0371583],
                [107.4206464, 11.0372318],
                [107.4206505, 11.0373107],
                [107.4206856, 11.0373832],
                [107.4207742, 11.0374618],
                [107.4207974, 11.0374909],
                [107.4208156, 11.0375234],
                [107.4208349, 11.0375947],
                [107.4208288, 11.0376731],
                [107.4208156, 11.0377103],
                [107.4207718, 11.0377858],
                [107.4207442, 11.0378765],
                [107.4207464, 11.0379712],
                [107.4207579, 11.0380148],
                [107.4208, 11.0380946],
                [107.4209101, 11.0382053],
                [107.4209962, 11.0382755],
                [107.4211829, 11.0383963],
                [107.4213615, 11.0384798],
                [107.4215937, 11.0385527],
                [107.4216347, 11.0385731],
                [107.4217022, 11.0386338],
                [107.4217265, 11.0386721],
                [107.4217522, 11.0387583],
                [107.4217456, 11.0388461],
                [107.4217083, 11.0389263],
                [107.4216794, 11.0389602],
                [107.4215643, 11.0390566],
                [107.421489, 11.0391302],
                [107.4214194, 11.0392091],
                [107.4212989, 11.0393809],
                [107.4212471, 11.039476],
                [107.4211664, 11.0396763],
                [107.4211105, 11.0398975],
                [107.4211081, 11.0400176],
                [107.4211314, 11.0401363],
                [107.4211796, 11.0402483],
                [107.4213063, 11.0403935],
                [107.4214526, 11.0405196],
                [107.4216208, 11.040627],
                [107.4217131, 11.0406726],
                [107.4219067, 11.0407428],
                [107.422007, 11.0407669],
                [107.4222484, 11.0407893],
                [107.4222928, 11.0407996],
                [107.4223341, 11.0408186],
                [107.4223706, 11.0408454],
                [107.4224008, 11.040879],
                [107.4224234, 11.0409179],
                [107.4224424, 11.0409989],
                [107.4224402, 11.0410375],
                [107.4224155, 11.0411108],
                [107.4223937, 11.041143],
                [107.422302, 11.0412324],
                [107.4222323, 11.041288],
                [107.4220794, 11.0413803],
                [107.4219874, 11.0414202],
                [107.4218919, 11.041451],
                [107.4215866, 11.0415143],
                [107.4213874, 11.0415844],
                [107.4212798, 11.0416361],
                [107.4211768, 11.0416961],
                [107.4207719, 11.0419694],
                [107.4206508, 11.0420376],
                [107.4203968, 11.0421513],
                [107.420265, 11.0421965],
                [107.419994, 11.0422622],
                [107.4197111, 11.042295],
                [107.4194263, 11.0422925],
                [107.4188622, 11.0422222],
                [107.4187502, 11.0422169],
                [107.4184539, 11.0422313],
                [107.41827, 11.0422149],
                [107.4180777, 11.0421669],
                [107.4179789, 11.0421288],
                [107.4178838, 11.0420824],
                [107.4177933, 11.0420279],
                [107.4176496, 11.0419278],
                [107.4174882, 11.0418583],
                [107.417403, 11.0418361],
                [107.4173158, 11.0418226],
                [107.4171573, 11.0418205],
                [107.4170785, 11.0418302],
                [107.416847, 11.0418913],
                [107.4167676, 11.0419034],
                [107.4166072, 11.0419073],
                [107.4165273, 11.0418989],
                [107.4163523, 11.0418556],
                [107.4162689, 11.0418216],
                [107.4161893, 11.0417797],
                [107.4160399, 11.0416858],
                [107.4159615, 11.0416485],
                [107.4157953, 11.0415979],
                [107.4157092, 11.041585],
                [107.4155364, 11.0415846],
                [107.4153668, 11.0416176],
                [107.4152854, 11.0416462],
                [107.4146229, 11.0419832],
                [107.4145193, 11.0420243],
                [107.4144126, 11.0420566],
                [107.4143035, 11.0420799],
                [107.4141927, 11.0420941],
                [107.4139864, 11.0420959],
                [107.4137988, 11.0420697],
                [107.413707, 11.0420468],
                [107.4129277, 11.04177],
                [107.4128691, 11.0417579],
                [107.4127497, 11.0417596],
                [107.412636, 11.0417954],
                [107.4125364, 11.0418635],
                [107.4124949, 11.0419085],
                [107.4124609, 11.0419592],
                [107.4124351, 11.0420143],
                [107.4124181, 11.0420727],
                [107.4123323, 11.0428203],
                [107.4123321, 11.0429665],
                [107.412341, 11.0431123],
                [107.4123802, 11.0433727],
                [107.4124396, 11.0435996],
                [107.412481, 11.043682],
                [107.4125423, 11.0438555],
                [107.4125737, 11.0440366],
                [107.4125779, 11.0441387],
                [107.4125725, 11.0442408],
                [107.412537, 11.0444954],
                [107.412537, 11.0445729],
                [107.4125576, 11.0447263],
                [107.4126125, 11.0448911],
                [107.412652, 11.0449687],
                [107.4127366, 11.0450911],
                [107.4127659, 11.0451451],
                [107.4127864, 11.0452029],
                [107.4127976, 11.0452631],
                [107.4127991, 11.0453242],
                [107.4127909, 11.0453849],
                [107.4127733, 11.0454436],
                [107.4127467, 11.0454989],
                [107.4127133, 11.0455475],
                [107.4126265, 11.0456276],
                [107.412575, 11.0456573],
                [107.4124615, 11.0456927],
                [107.4123217, 11.0456952],
                [107.412163, 11.0456714],
                [107.4119983, 11.0456185],
                [107.4119145, 11.0455789],
                [107.4117596, 11.0454779],
                [107.4113399, 11.0451027],
                [107.411161, 11.0449797],
                [107.410964, 11.0448871],
                [107.4108603, 11.044853],
                [107.4106462, 11.0448103],
                [107.4105372, 11.0448021],
                [107.4103234, 11.0448115],
                [107.4102198, 11.0448283],
                [107.4100184, 11.0448858],
                [107.4099218, 11.044926],
                [107.4097398, 11.0450282],
                [107.4095506, 11.0451747],
                [107.4094911, 11.0452074],
                [107.4093624, 11.0452508],
                [107.409295, 11.0452608],
                [107.409159, 11.0452569],
                [107.4090278, 11.0452212],
                [107.4089073, 11.0451547],
                [107.4088541, 11.0451111],
                [107.4087658, 11.0450066],
                [107.4087059, 11.0448843],
                [107.4085534, 11.0444036],
                [107.4085222, 11.0443393],
                [107.4084632, 11.0442512],
                [107.4082339, 11.0440221],
                [107.4080346, 11.0438381],
                [107.4076223, 11.0434851],
                [107.4072104, 11.043166],
                [107.4069987, 11.0430138],
                [107.4068364, 11.0429189],
                [107.4067496, 11.042882],
                [107.4065679, 11.0428308],
                [107.40638, 11.042811],
                [107.4061914, 11.042823],
                [107.4060913, 11.0428426],
                [107.4059934, 11.0428713],
                [107.4058987, 11.0429087],
                [107.4058079, 11.0429546],
                [107.4057219, 11.0430086],
                [107.4056414, 11.0430702],
                [107.405567, 11.0431388],
                [107.4054489, 11.0432718],
                [107.4054045, 11.0433342],
                [107.4053358, 11.0434706],
                [107.405314, 11.0435359],
                [107.4052888, 11.0436711],
                [107.4051343, 11.0457208],
                [107.4051159, 11.0458258],
                [107.4050528, 11.0460294],
                [107.4050093, 11.0461248],
                [107.4048994, 11.0463037],
                [107.4048335, 11.0463858],
                [107.4047106, 11.0465173],
                [107.4046536, 11.0465658],
                [107.4045911, 11.046607],
                [107.4045239, 11.0466405],
                [107.404453, 11.0466657],
                [107.4043119, 11.0466895],
                [107.4041763, 11.0466821],
                [107.40411, 11.0466675],
                [107.4039842, 11.0466172],
                [107.4038486, 11.0465411],
                [107.4037679, 11.0465059],
                [107.4035997, 11.0464539],
                [107.4034163, 11.0464268],
                [107.4033235, 11.046424],
                [107.4032308, 11.0464286],
                [107.4031458, 11.0464401],
                [107.4030622, 11.0464592],
                [107.4029023, 11.0465197],
                [107.4027517, 11.0466113],
                [107.4026817, 11.0466691],
                [107.4026128, 11.0467845],
                [107.40259, 11.0468476],
                [107.4025691, 11.0469797],
                [107.4025813, 11.0471089],
                [107.4026248, 11.0472313],
                [107.4030626, 11.0478827],
                [107.4031117, 11.0479816],
                [107.4031305, 11.04809],
                [107.4031176, 11.0481992],
                [107.4030748, 11.0482992],
                [107.4030054, 11.0483836],
                [107.402915, 11.0484458],
                [107.4027367, 11.0485063],
                [107.4024756, 11.0485609],
                [107.4022096, 11.0485829],
                [107.4019603, 11.0485738],
                [107.4018365, 11.0485585],
                [107.4017139, 11.048536],
                [107.4010536, 11.048375],
                [107.4009405, 11.0483704],
                [107.4008847, 11.0483801],
                [107.40078, 11.0484223],
                [107.4006995, 11.0484843],
                [107.4006379, 11.0485645],
                [107.399919, 11.049807],
                [107.3998717, 11.0498742],
                [107.3997564, 11.0499916],
                [107.3996899, 11.0500407],
                [107.3995428, 11.0501164],
                [107.3993826, 11.0501598],
                [107.3992965, 11.0501688],
                [107.3992098, 11.0501684],
                [107.3991238, 11.0501585],
                [107.3990394, 11.0501393],
                [107.3989578, 11.050111],
                [107.3988798, 11.0500739],
                [107.3988065, 11.0500286],
                [107.3987389, 11.0499755],
                [107.3978618, 11.0489936],
                [107.3977873, 11.0489316],
                [107.3976226, 11.048829],
                [107.3975337, 11.0487892],
                [107.3973468, 11.0487344],
                [107.3972502, 11.0487198],
                [107.3970587, 11.0487167],
                [107.3969635, 11.0487279],
                [107.3967782, 11.0487751],
                [107.3959279, 11.0491489],
                [107.3957815, 11.0492022],
                [107.3954827, 11.0492911],
                [107.3948362, 11.0494411],
                [107.3941993, 11.0495544],
                [107.3944268, 11.0498023],
                [107.3947908, 11.0501406],
                [107.3951322, 11.0504337],
                [107.3953142, 11.050569],
                [107.3956558, 11.0507722],
                [107.39602, 11.0509979],
                [107.3963386, 11.0512235],
                [107.3966345, 11.0514716],
                [107.3971009, 11.0519337],
                [107.3979427, 11.0526553],
                [107.3986485, 11.0531292],
                [107.3992178, 11.0533554],
                [107.3996507, 11.0534687],
                [107.3999925, 11.0535144],
                [107.4004485, 11.0535153],
                [107.4008133, 11.0534711],
                [107.4015201, 11.0533599],
                [107.4029339, 11.0531152],
                [107.4039144, 11.0529372],
                [107.4048379, 11.0527928],
                [107.4056134, 11.0525468],
                [107.4059784, 11.052435],
                [107.4064118, 11.0522559],
                [107.4071419, 11.0519423],
                [107.4079407, 11.0514714],
                [107.4087396, 11.0508881],
                [107.4090819, 11.0507087],
                [107.4092871, 11.0506867],
                [107.4094922, 11.0507095],
                [107.4098568, 11.0507552],
                [107.4102442, 11.050801],
                [107.4106317, 11.0508692],
                [107.4111332, 11.0508702],
                [107.4117258, 11.0508488],
                [107.4122502, 11.0507824],
                [107.4126835, 11.0506708],
                [107.4131626, 11.0504692],
                [107.4138241, 11.0502455],
                [107.4143714, 11.0501341],
                [107.4148957, 11.0501126],
                [107.4157629, 11.0503194],
                [107.4161033, 11.0504971],
                [107.4165558, 11.0507982],
                [107.4166264, 11.0509934],
                [107.416694, 11.051421],
                [107.4166934, 11.0516909],
                [107.4167154, 11.0520509],
                [107.4167376, 11.0523885],
                [107.4167597, 11.052726],
                [107.4169864, 11.0533789],
                [107.4174404, 11.0543021],
                [107.4178261, 11.0552704],
                [107.4182121, 11.055991],
                [107.4185759, 11.0564867],
                [107.418849, 11.0566897],
                [107.4191452, 11.0568252],
                [107.4194184, 11.0569383],
                [107.4197373, 11.0570514],
                [107.4200791, 11.0571645],
                [107.4203979, 11.0573001],
                [107.420785, 11.0575033],
                [107.4210582, 11.0577288],
                [107.4213767, 11.0579995],
                [107.4219, 11.0585403],
                [107.4230829, 11.0597801],
                [107.4237196, 11.0605237],
                [107.424322, 11.061436],
                [107.4249583, 11.0624497],
                [107.4253726, 11.0631926],
                [107.4256702, 11.0639714],
                [107.425762, 11.0642116],
                [107.4259496, 11.065106],
                [107.4260462, 11.0656915],
                [107.4261357, 11.066569],
                [107.4260999, 11.0673677],
                [107.4260765, 11.0677051],
                [107.4260304, 11.0679525],
                [107.4259614, 11.0682674],
                [107.4258467, 11.0686271],
                [107.4256861, 11.0691217],
                [107.4255946, 11.0693466],
                [107.4253655, 11.0698636],
                [107.4250911, 11.0703355],
                [107.4240512, 11.0717132],
                [107.4235941, 11.0722968],
                [107.4229084, 11.0732397],
                [107.4226566, 11.0737563],
                [107.422542, 11.0740934],
                [107.4224958, 11.0743631],
                [107.4224721, 11.0748577],
                [107.4224716, 11.0751274],
                [107.4224709, 11.0754646],
                [107.422516, 11.0757345],
                [107.4226971, 11.0763643],
                [107.4229014, 11.0767693],
                [107.4231062, 11.0770171],
                [107.4232881, 11.0772197],
                [107.4236977, 11.0775803],
                [107.4239937, 11.0777831],
                [107.4243353, 11.0779861],
                [107.4246543, 11.0780767],
                [107.4249961, 11.0780997],
                [107.4256574, 11.0780111],
                [107.4268204, 11.0778335],
                [107.4275043, 11.0778572],
                [107.4278686, 11.0780161],
                [107.4280963, 11.0781289],
                [107.4283239, 11.0783093],
                [107.428574, 11.0785797],
                [107.4287103, 11.0788049],
                [107.4289602, 11.0792777],
                [107.4291868, 11.0799529],
                [107.429391, 11.0804256],
                [107.4295274, 11.0805834],
                [107.4296639, 11.0807186],
                [107.4299142, 11.0809441],
                [107.4302329, 11.081147],
                [107.4306882, 11.0814403],
                [107.4311661, 11.0818012],
                [107.4319397, 11.0824999],
                [107.4321214, 11.0828151],
                [107.4321893, 11.0830402],
                [107.4321889, 11.0832651],
                [107.4321313, 11.0836137],
                [107.4320852, 11.0838835],
                [107.4319707, 11.0841308],
                [107.4309426, 11.0853884],
                [107.4304857, 11.0859498],
                [107.4303943, 11.0860846],
                [107.4303255, 11.086287],
                [107.4303024, 11.0864219],
                [107.4303021, 11.0865568],
                [107.4303701, 11.0867594],
                [107.4304837, 11.0869396],
                [107.4308932, 11.0873452],
                [107.4311436, 11.0875257],
                [107.4313712, 11.087706],
                [107.4316445, 11.087819],
                [107.4318951, 11.0879095],
                [107.4322595, 11.0880002],
                [107.4326469, 11.0880458],
                [107.4331028, 11.0880467],
                [107.4335131, 11.088025],
                [107.4339235, 11.0879808],
                [107.4342653, 11.0879814],
                [107.4347668, 11.0879598],
                [107.435496, 11.0880511],
                [107.4360995, 11.0883109],
                [107.4363499, 11.0884689],
                [107.4366685, 11.088762],
                [107.4369414, 11.0890323],
                [107.4371917, 11.0893027],
                [107.4375331, 11.0895508],
                [107.437852, 11.0896639],
                [107.4381483, 11.089642],
                [107.4383309, 11.0895073],
                [107.438468, 11.0893501],
                [107.4386052, 11.0891029],
                [107.4387653, 11.0888108],
                [107.4391771, 11.0880017],
                [107.4394746, 11.087395],
                [107.4395894, 11.0869454],
                [107.4396126, 11.0867205],
                [107.4396138, 11.0860682],
                [107.4396603, 11.0856184],
                [107.4398209, 11.0850788],
                [107.439981, 11.0847642],
                [107.4403468, 11.0842027],
                [107.4409175, 11.0837087],
                [107.4411914, 11.0835519],
                [107.4413967, 11.0834623],
                [107.441579, 11.0834626],
                [107.4418525, 11.0834631],
                [107.4421259, 11.0835086],
                [107.4423765, 11.0835991],
                [107.442604, 11.083802],
                [107.4428543, 11.0840272],
                [107.4429908, 11.0841851],
                [107.4431042, 11.0844551],
                [107.4431948, 11.0847927],
                [107.4432852, 11.0851978],
                [107.4433528, 11.0855803],
                [107.4434433, 11.0859628],
                [107.4434874, 11.0867502],
                [107.443406, 11.087571],
                [107.4432215, 11.0887403],
                [107.4432436, 11.0890778],
                [107.4433339, 11.0895503],
                [107.4435383, 11.0899331],
                [107.4436748, 11.0900908],
                [107.4438113, 11.0902485],
                [107.444016, 11.0904289],
                [107.444107, 11.0904965],
                [107.4442665, 11.0905867],
                [107.4446536, 11.0907225],
                [107.4449726, 11.090813],
                [107.4452686, 11.0909485],
                [107.4455646, 11.0911065],
                [107.445929, 11.0913321],
                [107.4460162, 11.0914123],
                [107.4462474, 11.0916251],
                [107.4463608, 11.0919178],
                [107.4465761, 11.0926041],
                [107.446666, 11.0932791],
                [107.4467328, 11.0941565],
                [107.4468229, 11.094674],
                [107.4469592, 11.0949442],
                [107.4470955, 11.0951919],
                [107.4473001, 11.0954622],
                [107.4474821, 11.0956649],
                [107.4477096, 11.0959128],
                [107.4480962, 11.0963633],
                [107.4484371, 11.0969038],
                [107.4486415, 11.0972866],
                [107.4490052, 11.0978496],
                [107.4491525, 11.0982885],
                [107.4492204, 11.098536],
                [107.4493109, 11.098851],
                [107.4493331, 11.099211],
                [107.4493548, 11.0998184],
                [107.4493541, 11.1001783],
                [107.4493304, 11.1006506],
                [107.4493748, 11.1013029],
                [107.4493744, 11.1015359],
                [107.4493736, 11.1019328],
                [107.4493381, 11.1026412],
                [107.4493371, 11.1032036],
                [107.4494726, 11.1038112],
                [107.4495632, 11.1041263],
                [107.4496995, 11.1043965],
                [107.4499039, 11.1047567],
                [107.4501314, 11.1050271],
                [107.4503587, 11.1053648],
                [107.4505859, 11.1057252],
                [107.4508583, 11.1063779],
                [107.4511986, 11.1072333],
                [107.4513457, 11.1078072],
                [107.4515265, 11.1086398],
                [107.4516856, 11.1088875],
                [107.4518221, 11.1090453],
                [107.4520269, 11.1092255],
                [107.4522773, 11.1093834],
                [107.4527328, 11.1095867],
                [107.4530746, 11.1096998],
                [107.4533479, 11.1098128],
                [107.4535756, 11.1099256],
                [107.4537349, 11.1100833],
                [107.453803, 11.1101959],
                [107.4538708, 11.110511],
                [107.4538932, 11.110736],
                [107.4538927, 11.1110509],
                [107.4538232, 11.1116356],
                [107.4537768, 11.1119954],
                [107.4537535, 11.1123328],
                [107.4536041, 11.112951],
                [107.4534667, 11.1133332],
                [107.4532838, 11.1135804],
                [107.4530326, 11.1138272],
                [107.4527588, 11.1139842],
                [107.4523937, 11.1142085],
                [107.4520057, 11.1144327],
                [107.4516179, 11.1146569],
                [107.4511158, 11.114971],
                [107.4508416, 11.1153304],
                [107.4507955, 11.1155552],
                [107.4508181, 11.1156901],
                [107.4508404, 11.1159602],
                [107.4508854, 11.1162302],
                [107.4509077, 11.1165],
                [107.45093, 11.1167701],
                [107.4509295, 11.1170625],
                [107.4509288, 11.1173774],
                [107.4509053, 11.1177597],
                [107.4508822, 11.1179846],
                [107.4507332, 11.1184005],
                [107.4505732, 11.118625],
                [107.4503447, 11.1188947],
                [107.4502232, 11.1190033],
                [107.4500936, 11.1191191],
                [107.4496142, 11.1194781],
                [107.4489752, 11.1199043],
                [107.4478566, 11.120712],
                [107.4475826, 11.120959],
                [107.4473087, 11.1212283],
                [107.4471258, 11.121453],
                [107.4469658, 11.1217],
                [107.4468057, 11.1219697],
                [107.446577, 11.1223517],
                [107.4462454, 11.1229431],
                [107.4454912, 11.1239982],
                [107.4444861, 11.1250527],
                [107.444121, 11.1252993],
                [107.4437103, 11.1254558],
                [107.4433684, 11.1254778],
                [107.4425478, 11.1254762],
                [107.4421147, 11.1254305],
                [107.44175, 11.1253848],
                [107.4412486, 11.1253614],
                [107.4406787, 11.1253603],
                [107.440256, 11.1254116],
                [107.4400173, 11.1254028],
                [107.4398226, 11.1253811],
                [107.439628, 11.125338],
                [107.439455, 11.1253164],
                [107.4392602, 11.1253587],
                [107.4389788, 11.1254862],
                [107.4387836, 11.1256991],
                [107.4385883, 11.1260186],
                [107.4383711, 11.1264875],
                [107.438089, 11.1269562],
                [107.4379371, 11.1271692],
                [107.4376554, 11.1274674],
                [107.4372436, 11.1278719],
                [107.4367773, 11.1284168],
                [107.4357904, 11.1297889],
                [107.4355515, 11.1303002],
                [107.435486, 11.1306201],
                [107.4354634, 11.131132],
                [107.4354407, 11.1317079],
                [107.435448, 11.1322485],
                [107.4354464, 11.1330965],
                [107.4354456, 11.1334735],
                [107.4354133, 11.1337246],
                [107.4352532, 11.1341641],
                [107.4350029, 11.1346139],
                [107.4349861, 11.1346347],
                [107.4348488, 11.1349267],
                [107.4347114, 11.1352637],
                [107.4345729, 11.1361854],
                [107.4345031, 11.1369496],
                [107.4345481, 11.137242],
                [107.4346161, 11.137422],
                [107.4347525, 11.1375797],
                [107.4349573, 11.137715],
                [107.4352762, 11.1378054],
                [107.4355722, 11.137896],
                [107.4359138, 11.1380091],
                [107.4362325, 11.1381895],
                [107.43646, 11.1383923],
                [107.4367102, 11.1386176],
                [107.4369604, 11.1388204],
                [107.4372563, 11.1390009],
                [107.437484, 11.1390913],
                [107.4376889, 11.1391591],
                [107.4379168, 11.1391595],
                [107.4384297, 11.1390817],
                [107.4395469, 11.1387691],
                [107.4405725, 11.1386585],
                [107.4410964, 11.1387719],
                [107.4415064, 11.1388176],
                [107.4418707, 11.1389308],
                [107.4421438, 11.1391561],
                [107.4424621, 11.1395164],
                [107.4427572, 11.140169],
                [107.4429836, 11.140889],
                [107.4433785, 11.142778],
                [107.443378, 11.1430574],
                [107.4432554, 11.1436355],
                [107.4432342, 11.144154],
                [107.4433057, 11.1444419],
                [107.4434297, 11.1446465],
                [107.4436578, 11.144813],
                [107.4447093, 11.1450344],
                [107.4450933, 11.1452146],
                [107.4451942, 11.1453344],
                [107.4452648, 11.1456034],
                [107.4452867, 11.1460532],
                [107.4452405, 11.1464128],
                [107.4451257, 11.1468172],
                [107.4450338, 11.1472218],
                [107.4448047, 11.1478735],
                [107.4447359, 11.1481207],
                [107.4447126, 11.1483454],
                [107.4447123, 11.1485253],
                [107.444803, 11.1487953],
                [107.4448938, 11.1489753],
                [107.445144, 11.1492456],
                [107.4454399, 11.149381],
                [107.4456678, 11.1494039],
                [107.4459923, 11.1492622],
                [107.4464296, 11.1490858],
                [107.4467169, 11.1489563],
                [107.4475615, 11.1481484],
                [107.448041, 11.1476546],
                [107.4482465, 11.1474301],
                [107.4484292, 11.1472281],
                [107.4486116, 11.1471385],
                [107.4489535, 11.1470942],
                [107.4491814, 11.1470946],
                [107.4495004, 11.1470951],
                [107.4500015, 11.147231],
                [107.4508442, 11.1474799],
                [107.4516296, 11.1478523],
                [107.4525631, 11.1482812],
                [107.4537695, 11.1490478],
                [107.4546346, 11.149499],
                [107.4551811, 11.1497023],
                [107.45591, 11.1498612],
                [107.4562744, 11.1499742],
                [107.4564792, 11.1501095],
                [107.4568433, 11.1504024],
                [107.4571162, 11.1506727],
                [107.4573664, 11.1509655],
                [107.4577301, 11.1514158],
                [107.4580932, 11.1522484],
                [107.4581946, 11.1528444],
                [107.458194, 11.1531816],
                [107.4581481, 11.1533614],
                [107.4580336, 11.1536984],
                [107.4578964, 11.1539231],
                [107.4577594, 11.1540801],
                [107.4574626, 11.1543719],
                [107.4570747, 11.1546636],
                [107.4568236, 11.1548655],
                [107.4564585, 11.1551346],
                [107.4561161, 11.1554488],
                [107.4558648, 11.1557405],
                [107.4556819, 11.1560775],
                [107.4555443, 11.1565718],
                [107.4551887, 11.1578415],
                [107.4548223, 11.1587852],
                [107.4547306, 11.1590998],
                [107.4546616, 11.1594145],
                [107.4546612, 11.1596842],
                [107.4547063, 11.1599091],
                [107.454797, 11.1601791],
                [107.454956, 11.1604268],
                [107.4552064, 11.1605397],
                [107.4555936, 11.1606753],
                [107.4559354, 11.1607208],
                [107.4563226, 11.1608115],
                [107.4566415, 11.160902],
                [107.4568921, 11.1609699],
                [107.4571197, 11.1610602],
                [107.4573018, 11.161173],
                [107.4574838, 11.1613531],
                [107.457734, 11.1616234],
                [107.458052, 11.1622085],
                [107.4582901, 11.1628273],
                [107.4587899, 11.1636601],
                [107.4592217, 11.1643354],
                [107.4594944, 11.1647181],
                [107.4598812, 11.1650786],
                [107.4612458, 11.16652],
                [107.4617233, 11.1671278],
                [107.4619958, 11.1676455],
                [107.4621536, 11.1680595],
                [107.4622049, 11.1683704],
                [107.4616543, 11.169247],
                [107.4614743, 11.1695642],
                [107.4611668, 11.1700399],
                [107.4606474, 11.1708545],
                [107.4601607, 11.172043],
                [107.4600145, 11.1730942],
                [107.4599218, 11.1738585],
                [107.4599552, 11.1743195],
                [107.4600676, 11.175174],
                [107.4602254, 11.1758747],
                [107.460618, 11.1767391],
                [107.4632597, 11.1777317],
                [107.4648664, 11.1782602],
                [107.4651873, 11.1786766],
                [107.465408, 11.1797496],
                [107.4655745, 11.1801882],
                [107.4659815, 11.1803838],
                [107.4664925, 11.1802393],
                [107.4674708, 11.1793719],
                [107.4684432, 11.1787792],
                [107.4689163, 11.1782221],
                [107.4693448, 11.1779319],
                [107.4700814, 11.1779219],
                [107.4707121, 11.1783909],
                [107.4710122, 11.179225],
                [107.4706836, 11.1810643],
                [107.4702542, 11.1818676],
                [107.4682108, 11.1825452],
                [107.4677086, 11.1828531],
                [107.4675347, 11.1832267],
                [107.4677018, 11.1836091],
                [107.4696079, 11.1839116],
                [107.4704621, 11.1843972],
                [107.4713511, 11.1851176],
                [107.471927, 11.1860465],
                [107.4721016, 11.1872552],
                [107.4719376, 11.1887314],
                [107.4711104, 11.1904104],
                [107.4707853, 11.1919865],
                [107.4710445, 11.1925915],
                [107.4716183, 11.1931633],
                [107.4727034, 11.1935545],
                [107.4738618, 11.1937272],
                [107.4747114, 11.1934494],
                [107.4754652, 11.1928542],
                [107.4764001, 11.1911775],
                [107.4769079, 11.1905452],
                [107.4778698, 11.1900156],
                [107.4788384, 11.1899862],
                [107.479214, 11.1902511],
                [107.4790808, 11.1906431],
                [107.4779866, 11.191289],
                [107.4772904, 11.1918171],
                [107.4768373, 11.1925338],
                [107.4765975, 11.1931401],
                [107.4764265, 11.1948735],
                [107.4760494, 11.1957081],
                [107.4747506, 11.1979752],
                [107.4734138, 11.1991936],
                [107.4732012, 11.199602],
                [107.473183, 11.2002907],
                [107.4733577, 11.2010756],
                [107.4739275, 11.201896],
                [107.4743968, 11.2021881],
                [107.4761242, 11.2026854],
                [107.4772001, 11.2027864],
                [107.4781385, 11.2027051],
                [107.4787322, 11.2022966],
                [107.4789393, 11.2015813],
                [107.4789299, 11.2003274],
                [107.4785275, 11.1987857],
                [107.4787185, 11.1982861],
                [107.4792496, 11.1980151],
                [107.4800914, 11.1982412],
                [107.4811265, 11.1989434],
                [107.4821355, 11.2000558],
                [107.4832235, 11.201975],
                [107.4842771, 11.2044129],
                [107.4842555, 11.2050451],
                [107.4837422, 11.2057714],
                [107.4831099, 11.2062771],
                [107.4796756, 11.2080409],
                [107.4784378, 11.2086251],
                [107.4781164, 11.2092642],
                [107.4783741, 11.2098287],
                [107.4788032, 11.2100644],
                [107.480483, 11.2099447],
                [107.4816136, 11.2100206],
                [107.4820985, 11.210232],
                [107.4824899, 11.2104026],
                [107.4832954, 11.2110097],
                [107.4836709, 11.2120769],
                [107.4841828, 11.212935],
                [107.484863, 11.2135657],
                [107.4861687, 11.2144938],
                [107.4880219, 11.2158501],
                [107.4882528, 11.2165688],
                [107.4882221, 11.2170704],
                [107.4874023, 11.2183097],
                [107.4866427, 11.2193188],
                [107.4855192, 11.2200599],
                [107.4842219, 11.2204207],
                [107.4827352, 11.2203473],
                [107.4825051, 11.2205656],
                [107.4825353, 11.2208548],
                [107.4828411, 11.2210837],
                [107.4843038, 11.2209181],
                [107.4851235, 11.2211287],
                [107.4858409, 11.2214661],
                [107.4865484, 11.2223598],
                [107.4871337, 11.2233042],
                [107.4879819, 11.2241087],
                [107.4894112, 11.2248876],
                [107.4903436, 11.2250234],
                [107.4910662, 11.2249348],
                [107.4918146, 11.2242813],
                [107.4928571, 11.222422],
                [107.4945577, 11.2207351],
                [107.496305, 11.2201041],
                [107.4975966, 11.2197449],
                [107.4983574, 11.2198543],
                [107.4988238, 11.2204134],
                [107.4988133, 11.2210173],
                [107.4982913, 11.2217362],
                [107.4956388, 11.2228124],
                [107.4946823, 11.2234379],
                [107.4936111, 11.2243688],
                [107.4932781, 11.2248072],
                [107.4933874, 11.2252937],
                [107.4937866, 11.2256257],
                [107.4961596, 11.226106],
                [107.4972458, 11.2263888],
                [107.4983853, 11.2270709],
                [107.4992153, 11.2279417],
                [107.4996678, 11.2286944],
                [107.4996678, 11.2294471],
                [107.4991069, 11.2309934],
                [107.4983119, 11.2318201],
                [107.4966209, 11.2342663],
                [107.4960407, 11.2355239],
                [107.4958722, 11.2368456],
                [107.4959571, 11.2391299],
                [107.4962677, 11.2403853],
                [107.4968044, 11.2420847],
                [107.4971582, 11.2426186],
                [107.4978504, 11.2430384],
                [107.4987723, 11.2432012],
                [107.49981, 11.2430931],
                [107.5005349, 11.242551],
                [107.5008899, 11.241819],
                [107.5011166, 11.2389191],
                [107.5014979, 11.2361562],
                [107.5018394, 11.2356233],
                [107.5021917, 11.2353998],
                [107.5024909, 11.2353377],
                [107.5030368, 11.2354937],
                [107.5040655, 11.2361438],
                [107.5047738, 11.2366191],
                [107.5058644, 11.23693],
                [107.5071084, 11.2370594],
                [107.5077812, 11.2371072],
                [107.5083213, 11.237423],
                [107.5084945, 11.2381325],
                [107.5082469, 11.2396831],
                [107.5083497, 11.2406915],
                [107.5087262, 11.2422887],
                [107.5091776, 11.243257],
                [107.5094763, 11.2438341],
                [107.5100315, 11.2440638],
                [107.5114578, 11.2437509],
                [107.5125732, 11.24377],
                [107.5135569, 11.244133],
                [107.5144778, 11.244846],
                [107.5156721, 11.2464954],
                [107.5162621, 11.2474156],
                [107.5164078, 11.2486351],
                [107.5162097, 11.2500879],
                [107.5165519, 11.2510947],
                [107.5169526, 11.2517467],
                [107.5173699, 11.2519555],
                [107.5177668, 11.2518663],
                [107.518135, 11.2513849],
                [107.5185128, 11.2490315],
                [107.518811, 11.2483102],
                [107.519396, 11.2479451],
                [107.5198204, 11.2481701],
                [107.520103, 11.2491546],
                [107.5205237, 11.2499983],
                [107.521369, 11.2511552],
                [107.5225465, 11.2516909],
                [107.5239793, 11.2518172],
                [107.5245099, 11.2517472],
                [107.5245225, 11.2518413],
                [107.524701, 11.2522379],
                [107.5246758, 11.2523463],
                [107.52438, 11.2526881],
                [107.5243362, 11.2527468],
                [107.5243199, 11.2528226],
                [107.5243154, 11.2530311],
                [107.524279, 11.253109],
                [107.524223, 11.2533268],
                [107.5242152, 11.2536695],
                [107.5241997, 11.2537485],
                [107.5241009, 11.2538878],
                [107.5237331, 11.2542383],
                [107.5236842, 11.2543272],
                [107.5237051, 11.2544747],
                [107.5235759, 11.2548073],
                [107.5235654, 11.2549098],
                [107.5236148, 11.2551088],
                [107.5236005, 11.2552027],
                [107.5235327, 11.2553228],
                [107.5234119, 11.25554],
                [107.5233826, 11.2556772],
                [107.5233808, 11.2560063],
                [107.5233442, 11.2563345],
                [107.5233561, 11.2564161],
                [107.5234274, 11.25659],
                [107.5235097, 11.2566985],
                [107.5239083, 11.2569711],
                [107.5241953, 11.2571325],
                [107.5243398, 11.257258],
                [107.5244266, 11.2573755],
                [107.5244705, 11.2575091],
                [107.5244632, 11.2576982],
                [107.5244166, 11.2578121],
                [107.5243709, 11.2578507],
                [107.5243087, 11.2578659],
                [107.524156, 11.2578614],
                [107.5241058, 11.2578838],
                [107.5240226, 11.257943],
                [107.5239668, 11.2580282],
                [107.5239357, 11.2581053],
                [107.5239129, 11.2583106],
                [107.5239293, 11.2585016],
                [107.5240445, 11.2587087],
                [107.5241268, 11.2587742],
                [107.5243352, 11.2588791],
                [107.5243892, 11.2589401],
                [107.524562, 11.2594198],
                [107.5246333, 11.2595372],
                [107.5248353, 11.2597434],
                [107.5249459, 11.2598412],
                [107.5249889, 11.2599084],
                [107.5249871, 11.2599488],
                [107.5248847, 11.26025],
                [107.5248774, 11.260302],
                [107.5250584, 11.26058],
                [107.5251599, 11.2608015],
                [107.5251519, 11.2609112],
                [107.5251164, 11.260967],
                [107.5250637, 11.2609884],
                [107.5244771, 11.2610625],
                [107.5242401, 11.2611579],
                [107.524114, 11.2613259],
                [107.524081, 11.2614927],
                [107.5241284, 11.2617093],
                [107.5242049, 11.2618809],
                [107.5243047, 11.2620035],
                [107.5243321, 11.262112],
                [107.5243127, 11.2622248],
                [107.5242267, 11.2624653],
                [107.5239399, 11.2629505],
                [107.5238776, 11.2631842],
                [107.5238831, 11.2633582],
                [107.5239718, 11.2637686],
                [107.5239261, 11.264054],
                [107.5238831, 11.2644645],
                [107.5238052, 11.2648673],
                [107.5237554, 11.2649534],
                [107.5235115, 11.2650902],
                [107.5231935, 11.2652302],
                [107.5230843, 11.2653436],
                [107.5230189, 11.2655327],
                [107.5229556, 11.2659898],
                [107.5229281, 11.2662395],
                [107.5229579, 11.2667097],
                [107.5230718, 11.2669298],
                [107.5232818, 11.2671078],
                [107.5233837, 11.267218],
                [107.5233977, 11.2672984],
                [107.5233628, 11.267378],
                [107.5227142, 11.2676211],
                [107.5226587, 11.2676893],
                [107.5226488, 11.26776],
                [107.5231077, 11.2684591],
                [107.5233135, 11.2685965],
                [107.5235595, 11.268659],
                [107.5236054, 11.2687477],
                [107.5236383, 11.2689291],
                [107.5236294, 11.2691925],
                [107.5235632, 11.2693123],
                [107.5232944, 11.2697695],
                [107.5232834, 11.2698171],
                [107.5232889, 11.2698547],
                [107.5233538, 11.269922],
                [107.5234461, 11.2699596],
                [107.5236665, 11.2700251],
                [107.5236994, 11.2700582],
                [107.5237378, 11.2702062],
                [107.523789, 11.2703299],
                [107.5237853, 11.2703658],
                [107.5237442, 11.2704016],
                [107.5233328, 11.2705451],
                [107.5232304, 11.2705684],
                [107.5230942, 11.2705765],
                [107.5230676, 11.2706061],
                [107.5230576, 11.2706518],
                [107.5230686, 11.2707881],
                [107.5231097, 11.2708598],
                [107.5231399, 11.2708912],
                [107.5233044, 11.2709396],
                [107.5233849, 11.2710131],
                [107.5234013, 11.271083],
                [107.5233492, 11.2711691],
                [107.5233145, 11.2713081],
                [107.5233227, 11.2713655],
                [107.5234946, 11.2715753],
                [107.523682, 11.2718954],
                [107.5237177, 11.2720388],
                [107.5237231, 11.2721285],
                [107.5235814, 11.2726682],
                [107.5235714, 11.2728386],
                [107.5235257, 11.2729479],
                [107.5234763, 11.2730125],
                [107.523224, 11.2732286],
                [107.5228793, 11.2734895],
                [107.5227705, 11.2735388],
                [107.5225209, 11.2735782],
                [107.5222304, 11.2735996],
                [107.522077, 11.2736637],
                [107.5214841, 11.2741093],
                [107.5212698, 11.2742775],
                [107.5210986, 11.2745181],
                [107.5210033, 11.274732],
                [107.5207942, 11.2752241],
                [107.5207323, 11.2755255],
                [107.5208398, 11.2756683],
                [107.5210845, 11.2758618],
                [107.5211397, 11.2759362],
                [107.5211775, 11.2759864],
                [107.5211865, 11.2760236],
                [107.5211761, 11.2760556],
                [107.5210483, 11.27621],
                [107.5210357, 11.2762391],
                [107.5210357, 11.2762858],
                [107.5210506, 11.2763156],
                [107.521312, 11.2767179],
                [107.5214008, 11.2771459],
                [107.5213998, 11.2777984],
                [107.5212665, 11.2783202],
                [107.5210407, 11.278881],
                [107.5209345, 11.2790896],
                [107.5206866, 11.2794176],
                [107.5205742, 11.2796687],
                [107.5205728, 11.2797713],
                [107.5206201, 11.2798562],
                [107.5207647, 11.2799262],
                [107.5209122, 11.2799295],
                [107.5213416, 11.279658],
                [107.5215573, 11.2796641],
                [107.5216992, 11.2797782],
                [107.5218044, 11.2800488],
                [107.5218456, 11.2802464],
                [107.5218053, 11.2803477],
                [107.5216326, 11.2804419],
                [107.521201, 11.2804932],
                [107.5209547, 11.2806621],
                [107.520622, 11.2809861],
                [107.5200172, 11.2814951],
                [107.5194309, 11.2819412],
                [107.5193342, 11.2821261],
                [107.5193245, 11.2823428],
                [107.5192943, 11.2828425],
                [107.519181, 11.2831694],
                [107.518657, 11.2838202],
                [107.5185365, 11.2840517],
                [107.5185038, 11.2844183],
                [107.5185826, 11.2848413],
                [107.5185821, 11.2851284],
                [107.5186082, 11.2853112],
                [107.5186608, 11.2855461],
                [107.5189776, 11.2860427],
                [107.5195587, 11.2867482],
                [107.5197729, 11.2870052],
                [107.5198757, 11.2870881],
                [107.5202105, 11.2873868],
                [107.5202895, 11.2874533],
                [107.5203477, 11.2874547],
                [107.5203893, 11.2873963],
                [107.5203726, 11.2872631],
                [107.5202355, 11.286994],
                [107.5202133, 11.2868092],
                [107.5202438, 11.2867372],
                [107.5203574, 11.2866108],
                [107.5204267, 11.286585],
                [107.520532, 11.2866027],
                [107.5206179, 11.2866625],
                [107.5206844, 11.286835],
                [107.5207315, 11.2870715],
                [107.5206276, 11.28749],
                [107.5204502, 11.2881355],
                [107.5199971, 11.2889998],
                [107.5199361, 11.2890894],
                [107.5199043, 11.2891669],
                [107.5199181, 11.2892471],
                [107.5199736, 11.2892811],
                [107.5200428, 11.2892743],
                [107.5203034, 11.2891683],
                [107.5203879, 11.289209],
                [107.5204461, 11.2893055],
                [107.5205154, 11.2894496],
                [107.5205777, 11.2894754],
                [107.5206442, 11.2894591],
                [107.520805, 11.2893626],
                [107.5209214, 11.2893585],
                [107.5210114, 11.289387],
                [107.5210516, 11.28944],
                [107.5210516, 11.2894822],
                [107.5210128, 11.2895542],
                [107.5208895, 11.2895542],
                [107.5207786, 11.2896221],
                [107.520701, 11.2897567],
                [107.5207191, 11.2899252],
                [107.5207911, 11.2899904],
                [107.5208438, 11.2900067],
                [107.5209172, 11.290004],
                [107.5211154, 11.2898708],
                [107.5212138, 11.2898355],
                [107.5213302, 11.2898368],
                [107.5214272, 11.2898925],
                [107.5215242, 11.28997],
                [107.521617, 11.2899999],
                [107.5218609, 11.2899999],
                [107.522005, 11.2899428],
                [107.5221325, 11.2898545],
                [107.5223015, 11.2895977],
                [107.5224013, 11.2895216],
                [107.5224817, 11.289527],
                [107.5226466, 11.2896452],
                [107.5227353, 11.2897703],
                [107.522884, 11.2900759],
                [107.5228965, 11.2903069],
                [107.5228323, 11.2906269],
                [107.5227678, 11.2907601],
                [107.5226241, 11.2912551],
                [107.5225492, 11.2913526],
                [107.5223116, 11.2915365],
                [107.5222748, 11.2916278],
                [107.5223925, 11.2920451],
                [107.5227532, 11.2924542],
                [107.5230292, 11.2927442],
                [107.5232135, 11.2930575],
                [107.5235835, 11.2936587],
                [107.5237151, 11.2941287],
                [107.5236688, 11.2945637],
                [107.5233631, 11.2949547],
                [107.5232794, 11.2952829],
                [107.5234404, 11.2959656],
                [107.5234597, 11.2961686],
                [107.5234738, 11.2964124],
                [107.5234524, 11.2969038],
                [107.5233654, 11.2971048],
                [107.5230348, 11.2974294],
                [107.5229199, 11.297547],
                [107.5228759, 11.2976945],
                [107.5228454, 11.2982113],
                [107.5229817, 11.2985618],
                [107.5230856, 11.2986824],
                [107.5232271, 11.2987818],
                [107.5235348, 11.298921],
                [107.5236748, 11.2989702],
                [107.5238784, 11.2991642],
                [107.5239546, 11.2994403],
                [107.5239235, 11.2996616],
                [107.5237644, 11.3000036],
                [107.5234534, 11.300329],
                [107.5231626, 11.3007915],
                [107.5229714, 11.3009952],
                [107.5225554, 11.3017422],
                [107.5225099, 11.3019647],
                [107.5223352, 11.3026112],
                [107.522376, 11.3029448],
                [107.5223492, 11.3030023],
                [107.522245, 11.303031],
                [107.5221197, 11.3030489],
                [107.5220073, 11.3031556],
                [107.5219835, 11.3032399],
                [107.5219533, 11.3034093],
                [107.5219122, 11.3035725],
                [107.5218217, 11.3036621],
                [107.5217138, 11.3037204],
                [107.5215703, 11.3037643],
                [107.5212795, 11.3038002],
                [107.5211296, 11.3038298],
                [107.5209797, 11.3039418],
                [107.5208343, 11.303975],
                [107.5207328, 11.3039562],
                [107.5206131, 11.3039095],
                [107.5205171, 11.3038907],
                [107.5204494, 11.3039212],
                [107.5203927, 11.3039723],
                [107.5203845, 11.3042179],
                [107.5203534, 11.3043461],
                [107.5202812, 11.3044573],
                [107.5198012, 11.3049109],
                [107.5196342, 11.304974],
                [107.5195719, 11.3049693],
                [107.5194933, 11.3049139],
                [107.5194512, 11.3049101],
                [107.5189778, 11.3050304],
                [107.5188772, 11.3050962],
                [107.5188542, 11.3051215],
                [107.5187411, 11.3053677],
                [107.5186625, 11.3056158],
                [107.5187124, 11.3056994],
                [107.5188944, 11.3058225],
                [107.5189548, 11.3059118],
                [107.5189557, 11.3059672],
                [107.5190219, 11.3060424],
                [107.5190477, 11.3061213],
                [107.5190621, 11.3062172],
                [107.5190573, 11.3062745],
                [107.5189625, 11.3065479],
                [107.5189385, 11.3067124],
                [107.5189634, 11.3067894],
                [107.5192106, 11.307341],
                [107.5192356, 11.3074415],
                [107.5192182, 11.307629],
                [107.5190696, 11.3079113],
                [107.5189516, 11.3079958],
                [107.5183405, 11.308101],
                [107.5182862, 11.308138],
                [107.5182615, 11.3081855],
                [107.5182556, 11.3082468],
                [107.5183924, 11.3089756],
                [107.5183735, 11.3090496],
                [107.5182957, 11.30906],
                [107.518114, 11.3090774],
                [107.5178887, 11.3091699],
                [107.5177377, 11.3092752],
                [107.5176303, 11.3094106],
                [107.5176126, 11.3094719],
                [107.5176327, 11.3095343],
                [107.5180232, 11.3101544],
                [107.5181069, 11.3102481],
                [107.5182143, 11.3103349],
                [107.5182438, 11.3103788],
                [107.5182745, 11.3104864],
                [107.5182615, 11.3107027],
                [107.5181459, 11.3109167],
                [107.5180751, 11.3111354],
                [107.5180751, 11.3111759],
                [107.5180904, 11.3112002],
                [107.5181848, 11.3112406],
                [107.5183712, 11.3112314],
                [107.5185647, 11.3112325],
                [107.5186425, 11.3112753],
                [107.5186756, 11.3113262],
                [107.5186791, 11.3114049],
                [107.5186649, 11.3115981],
                [107.5186284, 11.3116918],
                [107.5184856, 11.3119313],
                [107.518455, 11.3120643],
                [107.5184514, 11.3121314],
                [107.5185753, 11.3123789],
                [107.5187345, 11.3125027],
                [107.5187294, 11.3125653],
                [107.5187136, 11.3128573],
                [107.5190376, 11.3135537],
                [107.519096, 11.313683],
                [107.5191557, 11.3137555],
                [107.5191916, 11.3137833],
                [107.5192737, 11.3138224],
                [107.5193638, 11.3138369],
                [107.5194805, 11.313825],
                [107.5195391, 11.3138312],
                [107.5196378, 11.3138611],
                [107.5197261, 11.3139137],
                [107.5201364, 11.3144073],
                [107.5202128, 11.314454],
                [107.5203056, 11.314481],
                [107.5203573, 11.3144848],
                [107.520409, 11.314481],
                [107.5206311, 11.3144324],
                [107.5207052, 11.3144444],
                [107.5207668, 11.3144804],
                [107.5207905, 11.3145046],
                [107.5208236, 11.3145631],
                [107.5208266, 11.3146994],
                [107.5208004, 11.3148315],
                [107.5207771, 11.3148939],
                [107.5207092, 11.3150167],
                [107.5207016, 11.3150895],
                [107.5207257, 11.3151588],
                [107.5207486, 11.3151881],
                [107.5208106, 11.3152292],
                [107.5208844, 11.315241],
                [107.5213936, 11.3151194],
                [107.5214626, 11.3151267],
                [107.5215272, 11.3151539],
                [107.5215561, 11.3151745],
                [107.521654, 11.3152758],
                [107.5217094, 11.3153198],
                [107.5218308, 11.3153929],
                [107.5219044, 11.3154246],
                [107.5219809, 11.3154491],
                [107.5221393, 11.3154754],
                [107.5224947, 11.3154671],
                [107.5226322, 11.3154748],
                [107.5227688, 11.3154909],
                [107.5230378, 11.3155481],
                [107.5231671, 11.3155882],
                [107.523417, 11.3156915],
                [107.523652, 11.3158242],
                [107.5237907, 11.3159306],
                [107.5238862, 11.3160202],
                [107.52395, 11.316103],
                [107.5240007, 11.3162135],
                [107.5240383, 11.3163529],
                [107.5240667, 11.3164255],
                [107.5241559, 11.3165923],
                [107.5241637, 11.3166242],
                [107.5241631, 11.316657],
                [107.5241377, 11.3167172],
                [107.5241144, 11.3167408],
                [107.5240564, 11.3167671],
                [107.5239926, 11.3167639],
                [107.5238788, 11.3166981],
                [107.5238594, 11.3166937],
                [107.523821, 11.3167027],
                [107.5237933, 11.3167345],
                [107.5237831, 11.3168457],
                [107.5237969, 11.3170262],
                [107.5238143, 11.3171075],
                [107.5238694, 11.3172645],
                [107.5239068, 11.3173391],
                [107.5239363, 11.3173734],
                [107.5240101, 11.3174259],
                [107.5240581, 11.3174445],
                [107.5241087, 11.3174542],
                [107.5241603, 11.3174549],
                [107.5243349, 11.3174975],
                [107.5245007, 11.3175658],
                [107.5245792, 11.317609],
                [107.5247249, 11.3177126],
                [107.5249068, 11.3178854],
                [107.5249266, 11.3179353],
                [107.5249301, 11.3180618],
                [107.5249719, 11.3183608],
                [107.5249576, 11.3184457],
                [107.5249428, 11.3184785],
                [107.5248842, 11.3185648],
                [107.5248732, 11.3186285],
                [107.5248914, 11.3186962],
                [107.5249131, 11.3187276],
                [107.5249417, 11.318753],
                [107.5249757, 11.3187712],
                [107.525191, 11.318828],
                [107.5254115, 11.3188596],
                [107.5256351, 11.3188657],
                [107.5257471, 11.318859],
                [107.525969, 11.3188261],
                [107.5260781, 11.3188001],
                [107.5262256, 11.3187339],
                [107.5263543, 11.3186371],
                [107.5264272, 11.3186023],
                [107.5265038, 11.3185764],
                [107.5265829, 11.3185595],
                [107.5266636, 11.3185521],
                [107.5267446, 11.3185542],
                [107.5268248, 11.3185658],
                [107.526903, 11.3185866],
                [107.5269781, 11.3186165],
                [107.527049, 11.318655],
                [107.5275895, 11.3190829],
                [107.5276871, 11.3191254],
                [107.5277944, 11.3191377],
                [107.5278488, 11.3191317],
                [107.5280129, 11.3190772],
                [107.5280757, 11.3190643],
                [107.5282037, 11.3190566],
                [107.5282683, 11.3190619],
                [107.5283943, 11.3190909],
                [107.5289614, 11.3193301],
                [107.5290835, 11.3193551],
                [107.5291966, 11.3193538],
                [107.5292525, 11.3193445],
                [107.5295653, 11.3192645],
                [107.5296308, 11.3192556],
                [107.5296969, 11.3192565],
                [107.5297621, 11.319267],
                [107.5298169, 11.3192837],
                [107.5299175, 11.3193372],
                [107.5300682, 11.3194724],
                [107.5301403, 11.3195247],
                [107.5302072, 11.3195657],
                [107.530417, 11.3196678],
                [107.5304816, 11.3197081],
                [107.5306001, 11.3198028],
                [107.5306725, 11.3199003],
                [107.5306732, 11.3203588],
                [107.5306992, 11.3209041],
                [107.5307628, 11.3211817],
                [107.5308894, 11.3213066],
                [107.5317276, 11.3217032],
                [107.5319302, 11.3218548],
                [107.5327037, 11.3229082],
                [107.5337988, 11.3237288],
                [107.5338783, 11.3237774],
                [107.5339985, 11.3238289],
                [107.5340778, 11.3238822],
                [107.5343023, 11.3241371],
                [107.5344861, 11.3243934],
                [107.5345682, 11.3244667],
                [107.534867, 11.3246134],
                [107.5349763, 11.3246509],
                [107.5351605, 11.3246979],
                [107.535244, 11.32475],
                [107.5353004, 11.324821],
                [107.5353292, 11.324899],
                [107.5353343, 11.3249404],
                [107.5353325, 11.3254252],
                [107.5353386, 11.3254556],
                [107.5353669, 11.3255094],
                [107.5355218, 11.3256352],
                [107.5355515, 11.32569],
                [107.5355534, 11.3257471],
                [107.5355336, 11.3257953],
                [107.5354702, 11.3258716],
                [107.5354061, 11.3259158],
                [107.5353678, 11.3259316],
                [107.5352861, 11.3259459],
                [107.5349443, 11.3259488],
                [107.5348866, 11.3259683],
                [107.5348623, 11.3259866],
                [107.5348301, 11.3260316],
                [107.5348214, 11.3260578],
                [107.5348018, 11.3264424],
                [107.5348072, 11.3264824],
                [107.5348402, 11.3265531],
                [107.5348655, 11.3265814],
                [107.5350027, 11.3266891],
                [107.5350353, 11.3267388],
                [107.5350488, 11.3267743],
                [107.5350568, 11.3268492],
                [107.5350568, 11.3275541],
                [107.5353464, 11.3286271],
                [107.5354591, 11.3287665],
                [107.5356173, 11.3296843],
                [107.5355409, 11.332142],
                [107.5355004, 11.332208],
                [107.535468, 11.3322782],
                [107.5354443, 11.3323517],
                [107.5354242, 11.332497],
                [107.5354361, 11.3326359],
                [107.5354534, 11.3327037],
                [107.535671, 11.3332979],
                [107.5357139, 11.3336397],
                [107.5357354, 11.3337085],
                [107.5357756, 11.3337686],
                [107.5358086, 11.3337995],
                [107.5358883, 11.3338422],
                [107.5360078, 11.333877],
                [107.5360608, 11.3339183],
                [107.5360814, 11.3339448],
                [107.5361095, 11.3340132],
                [107.5361196, 11.3342611],
                [107.5361531, 11.3343439],
                [107.5362691, 11.3345129],
                [107.5362815, 11.3345426],
                [107.5362964, 11.334606],
                [107.5362956, 11.3347514],
                [107.5363019, 11.3347868],
                [107.5363349, 11.3348532],
                [107.536361, 11.3348814],
                [107.5363923, 11.3349041],
                [107.5372267, 11.3353255],
                [107.5382996, 11.3362328],
                [107.5390935, 11.3368587],
                [107.5395387, 11.3372637],
                [107.5395226, 11.3374478],
                [107.5411749, 11.3405826],
                [107.5415933, 11.341624],
                [107.5416868, 11.3419165],
                [107.5417454, 11.3421913],
                [107.5418293, 11.3429705],
                [107.5420794, 11.3439231],
                [107.542108, 11.3440569],
                [107.5421439, 11.3443278],
                [107.5421513, 11.3446016],
                [107.5421298, 11.3448755],
                [107.5419259, 11.3460106],
                [107.5421083, 11.3471887],
                [107.5421458, 11.3480039],
                [107.542145, 11.348264],
                [107.542171, 11.3485228],
                [107.5422252, 11.3487828],
                [107.5423084, 11.3490408],
                [107.5423995, 11.3492552],
                [107.542445, 11.3493412],
                [107.5425545, 11.3495023],
                [107.5426939, 11.3496529],
                [107.5428634, 11.3497866],
                [107.5430524, 11.3498921],
                [107.5435191, 11.3498921],
                [107.5435578, 11.3499041],
                [107.5435931, 11.3499236],
                [107.5436479, 11.3499815],
                [107.5436658, 11.3500196],
                [107.5436749, 11.3500605],
                [107.5436747, 11.3501025],
                [107.5435404, 11.3504646],
                [107.5435277, 11.3505142],
                [107.5435283, 11.3506032],
                [107.5436107, 11.3508261],
                [107.5436262, 11.3509055],
                [107.5436318, 11.3509861],
                [107.5436193, 11.3511188],
                [107.5436031, 11.3511836],
                [107.5435513, 11.3513069],
                [107.543562, 11.3516961],
                [107.5435559, 11.3519154],
                [107.5435245, 11.3521326],
                [107.543495, 11.3522559],
                [107.5434118, 11.3524955],
                [107.5431168, 11.3532082],
                [107.5427896, 11.3534764],
                [107.5437444, 11.359885],
                [107.5438846, 11.3599399],
                [107.544021, 11.3600031],
                [107.5442809, 11.3601532],
                [107.5443804, 11.3602226],
                [107.5445684, 11.3603753],
                [107.5446564, 11.3604583],
                [107.5447332, 11.3605666],
                [107.5447609, 11.3606269],
                [107.5447924, 11.3607551],
                [107.5447959, 11.3608211],
                [107.5447807, 11.3609409],
                [107.5447385, 11.3610542],
                [107.5447078, 11.3611067],
                [107.54458, 11.361259],
                [107.5445545, 11.3613313],
                [107.5445574, 11.3614132],
                [107.5445706, 11.3614523],
                [107.5446048, 11.3615004],
                [107.5446832, 11.361589],
                [107.5447899, 11.3616779],
                [107.5449099, 11.3617487],
                [107.544977, 11.361813],
                [107.5450128, 11.3618898],
                [107.54502, 11.3619662],
                [107.5443042, 11.3665313],
                [107.5412779, 11.360023],
                [107.5410897, 11.3596007],
                [107.5408958, 11.3592165],
                [107.5407878, 11.3590245],
                [107.5407145, 11.3589156],
                [107.5406335, 11.358812],
                [107.5405453, 11.3587144],
                [107.5404502, 11.3586231],
                [107.5403488, 11.3585386],
                [107.5402422, 11.3584619],
                [107.5401305, 11.3583927],
                [107.540014, 11.3583314],
                [107.5396799, 11.3581946],
                [107.5395938, 11.3581488],
                [107.539434, 11.3580378],
                [107.5393614, 11.3579734],
                [107.539233, 11.3578286],
                [107.5391549, 11.3577113],
                [107.5390175, 11.3575271],
                [107.538956, 11.3574628],
                [107.5388881, 11.357405],
                [107.5388146, 11.3573544],
                [107.5387361, 11.3573114],
                [107.5386535, 11.3572765],
                [107.5383713, 11.3572041],
                [107.5382764, 11.3571703],
                [107.5380957, 11.3570818],
                [107.5379187, 11.3569602],
                [107.5377577, 11.3568188],
                [107.5374858, 11.3565304],
                [107.5373404, 11.3563953],
                [107.5370739, 11.3561758],
                [107.5369529, 11.3560925],
                [107.5368267, 11.356017],
                [107.5366957, 11.3559497],
                [107.5365607, 11.3558908],
                [107.5362072, 11.3557668],
                [107.536105, 11.3557177],
                [107.5359141, 11.3555964],
                [107.5356659, 11.3553807],
                [107.5354886, 11.3552567],
                [107.5352971, 11.3551547],
                [107.5350944, 11.3550763],
                [107.5349897, 11.3550464],
                [107.5349009, 11.3551682],
                [107.5348042, 11.3552841],
                [107.5345649, 11.3555313],
                [107.5344533, 11.355688],
                [107.5343582, 11.3558489],
                [107.5342387, 11.3560019],
                [107.5340963, 11.3561442],
                [107.5338327, 11.3563538],
                [107.5336688, 11.3565227],
                [107.5335963, 11.3566151],
                [107.5334716, 11.3568135],
                [107.5333264, 11.3571071],
                [107.5330698, 11.3575538],
                [107.5329911, 11.3577107],
                [107.5327926, 11.3581641],
                [107.5327195, 11.3583064],
                [107.5325543, 11.3585807],
                [107.5324253, 11.3587533],
                [107.5322707, 11.3589044],
                [107.5320941, 11.3590302],
                [107.5318998, 11.3591276],
                [107.531643, 11.3592262],
                [107.5314682, 11.359312],
                [107.531379, 11.3593828],
                [107.5313106, 11.3594731],
                [107.5312674, 11.3595773],
                [107.5312521, 11.3596799],
                [107.5312201, 11.3597692],
                [107.531193, 11.3598086],
                [107.5310983, 11.3598981],
                [107.5310626, 11.3599718],
                [107.5310576, 11.3600533],
                [107.5310714, 11.3601048],
                [107.5310822, 11.3602105],
                [107.5310727, 11.3603046],
                [107.5310794, 11.360387],
                [107.5311112, 11.3604635],
                [107.5311424, 11.3605049],
                [107.5311654, 11.3605512],
                [107.5311795, 11.3606008],
                [107.5311841, 11.360652],
                [107.5311792, 11.3607033],
                [107.5311306, 11.3608375],
                [107.5311229, 11.3608826],
                [107.5311218, 11.3609283],
                [107.5311398, 11.3610546],
                [107.531118, 11.3611337],
                [107.5310951, 11.3611683],
                [107.5310439, 11.3612127],
                [107.5309612, 11.3613161],
                [107.530902, 11.3614312],
                [107.5308755, 11.3615106],
                [107.5308556, 11.3615919],
                [107.5308367, 11.3617579],
                [107.5308479, 11.3620531],
                [107.5308396, 11.3621011],
                [107.5308004, 11.3621902],
                [107.5307705, 11.362229],
                [107.530694, 11.3622905],
                [107.5306016, 11.3623253],
                [107.5304846, 11.3623456],
                [107.5304289, 11.3623663],
                [107.5302714, 11.3624608],
                [107.530122, 11.3625235],
                [107.5300255, 11.3625496],
                [107.529927, 11.3625666],
                [107.5298273, 11.3625743],
                [107.5296128, 11.3625705],
                [107.5295029, 11.362602],
                [107.5294077, 11.3626641],
                [107.5293356, 11.3627513],
                [107.5292897, 11.3628601],
                [107.5292705, 11.3629763],
                [107.5292795, 11.3631434],
                [107.5292754, 11.3631975],
                [107.529248, 11.3633119],
                [107.529223, 11.3633704],
                [107.5291913, 11.3634257],
                [107.5286908, 11.3641296],
                [107.5285353, 11.3643261],
                [107.528362, 11.3645028],
                [107.5282903, 11.3645606],
                [107.5281393, 11.3646575],
                [107.5279805, 11.3647306],
                [107.5275376, 11.3648715],
                [107.5273117, 11.3649294],
                [107.5271249, 11.3649731],
                [107.5267473, 11.3650412],
                [107.5265568, 11.3650654],
                [107.5263952, 11.3650807],
                [107.5260709, 11.3650972],
                [107.5260066, 11.3651851],
                [107.5259503, 11.3652782],
                [107.5258014, 11.3655954],
                [107.5257495, 11.3657438],
                [107.525728, 11.3658858],
                [107.5254598, 11.3658674],
                [107.5254586, 11.3659281],
                [107.5254756, 11.3660691],
                [107.5254575, 11.3661486],
                [107.5254382, 11.3661848],
                [107.5254129, 11.3662173],
                [107.5253472, 11.3662671],
                [107.5252819, 11.3662898],
                [107.5251663, 11.3663436],
                [107.5251191, 11.3663778],
                [107.5250436, 11.3664655],
                [107.5249994, 11.3665716],
                [107.5249879, 11.3666856],
                [107.5249945, 11.3667427],
                [107.5250397, 11.3668949],
                [107.5250349, 11.366996],
                [107.5249952, 11.3670894],
                [107.5248617, 11.3672401],
                [107.5248061, 11.367343],
                [107.5247759, 11.3674557],
                [107.5247709, 11.36751],
                [107.5247745, 11.3676733],
                [107.5247839, 11.3677818],
                [107.5248134, 11.3679683],
                [107.5248053, 11.368078],
                [107.5248269, 11.3681071],
                [107.524844, 11.3681145],
                [107.5248805, 11.3681105],
                [107.5249129, 11.3680933],
                [107.5249443, 11.3680943],
                [107.5249778, 11.3681135],
                [107.5249931, 11.3681341],
                [107.5250015, 11.3681702],
                [107.5249988, 11.3681886],
                [107.5250375, 11.3683301],
                [107.5250387, 11.368376],
                [107.5250226, 11.3684996],
                [107.5250285, 11.368584],
                [107.5250602, 11.3686627],
                [107.5251121, 11.3687258],
                [107.5251834, 11.3688445],
                [107.5252015, 11.3688984],
                [107.5252108, 11.3690111],
                [107.5251837, 11.3691211],
                [107.5251229, 11.3692172],
                [107.5250816, 11.369257],
                [107.5249122, 11.3693698],
                [107.524785, 11.3694846],
                [107.5247418, 11.3695392],
                [107.524682, 11.3696529],
                [107.5246525, 11.3697671],
                [107.5243578, 11.3697885],
                [107.5240624, 11.3697829],
                [107.5237393, 11.3697454],
                [107.5235796, 11.3697145],
                [107.5235031, 11.3698666],
                [107.5234209, 11.3700793],
                [107.5233842, 11.370141],
                [107.5232417, 11.3703106],
                [107.5231116, 11.3705014],
                [107.5229969, 11.3706084],
                [107.5229311, 11.3706517],
                [107.5227329, 11.3707459],
                [107.5226758, 11.3707868],
                [107.5225156, 11.370957],
                [107.5224519, 11.3710092],
                [107.5223095, 11.3710925],
                [107.5221527, 11.371145],
                [107.5219371, 11.3711826],
                [107.5217618, 11.3712271],
                [107.5216317, 11.3712855],
                [107.5215165, 11.3713686],
                [107.521335, 11.3715694],
                [107.5212592, 11.3716352],
                [107.5211775, 11.3716938],
                [107.5210905, 11.3717445],
                [107.5209737, 11.3717971],
                [107.5208611, 11.3718578],
                [107.5207532, 11.3719263],
                [107.5206506, 11.3720022],
                [107.5203781, 11.372232],
                [107.5200851, 11.3724362],
                [107.5197777, 11.3726113],
                [107.519461, 11.3727613],
                [107.5191901, 11.3728689],
                [107.5190848, 11.3729175],
                [107.5188865, 11.3730367],
                [107.5187208, 11.373171],
                [107.5186518, 11.3732402],
                [107.5185294, 11.3733919],
                [107.5184298, 11.3735589],
                [107.5183351, 11.3737511],
                [107.5182206, 11.3739288],
                [107.5181557, 11.3740105],
                [107.5180093, 11.3741596],
                [107.5178433, 11.3742874],
                [107.5176588, 11.3743923],
                [107.5175603, 11.3744353],
                [107.5173554, 11.3745004],
                [107.5172499, 11.374522],
                [107.5170355, 11.3745431],
                [107.5169277, 11.3745423],
                [107.5168065, 11.3745325],
                [107.5166864, 11.3745132],
                [107.5165683, 11.3744845],
                [107.5164776, 11.3746753],
                [107.5164098, 11.3748634],
                [107.5163359, 11.3751366],
                [107.5162191, 11.3756803],
                [107.5161675, 11.3758569],
                [107.5160569, 11.3761561],
                [107.5159332, 11.3763964],
                [107.5158601, 11.3765104],
                [107.5156936, 11.3767226],
                [107.5155689, 11.3768956],
                [107.5154989, 11.3770371],
                [107.5154561, 11.3771887],
                [107.5154453, 11.3772668],
                [107.5154442, 11.3775457],
                [107.515431, 11.3776452],
                [107.5153187, 11.3780409],
                [107.5153111, 11.378107],
                [107.5153184, 11.3782394],
                [107.5153616, 11.3783852],
                [107.5153953, 11.3784537],
                [107.5154364, 11.3785182],
                [107.5155097, 11.3786043],
                [107.5155754, 11.3786961],
                [107.5156329, 11.3787931],
                [107.5156819, 11.3788944],
                [107.5157219, 11.3789995],
                [107.5157528, 11.3791075],
                [107.5157858, 11.3793206],
                [107.5157979, 11.3796311],
                [107.5158004, 11.3799654],
                [107.5157728, 11.3802182],
                [107.515746, 11.3803427],
                [107.5157107, 11.3804651],
                [107.5155673, 11.3808275],
                [107.5155278, 11.3809524],
                [107.5154356, 11.3813519],
                [107.5153495, 11.3816176],
                [107.5152334, 11.3818836],
                [107.5151627, 11.3820167],
                [107.5148995, 11.3825668],
                [107.5147381, 11.3828308],
                [107.5145188, 11.3831294],
                [107.5144281, 11.3832726],
                [107.5143887, 11.3833206],
                [107.514291, 11.3833974],
                [107.5141311, 11.3834674],
                [107.5140445, 11.3835378],
                [107.5139826, 11.3836299],
                [107.5139279, 11.3837624],
                [107.5138584, 11.3838714],
                [107.5137451, 11.3840015],
                [107.5136617, 11.3840809],
                [107.513572, 11.3841535],
                [107.5134767, 11.3842189],
                [107.5132471, 11.3843465],
                [107.5130237, 11.3845221],
                [107.512899, 11.3845817],
                [107.5128389, 11.3845984],
                [107.5127147, 11.3846092],
                [107.5125916, 11.3845896],
                [107.5125329, 11.3845687],
                [107.5124256, 11.3845065],
                [107.5122879, 11.3843747],
                [107.5122359, 11.3843362],
                [107.5121802, 11.384303],
                [107.512065, 11.3842554],
                [107.5119539, 11.3841992],
                [107.5116501, 11.384002],
                [107.5115457, 11.3839444],
                [107.5113276, 11.3838475],
                [107.5112146, 11.3838087],
                [107.5110623, 11.3837674],
                [107.5107524, 11.3837075],
                [107.510438, 11.3836784],
                [107.51028, 11.3836755],
                [107.5099647, 11.383693],
                [107.509709, 11.3837319],
                [107.5095831, 11.3837614],
                [107.5093371, 11.3838402],
                [107.5091199, 11.3839351],
                [107.5089133, 11.3840506],
                [107.5087176, 11.3839353],
                [107.5086155, 11.3838852],
                [107.5081929, 11.383718],
                [107.5077697, 11.3835106],
                [107.5076312, 11.383451],
                [107.5075139, 11.3834055],
                [107.5073933, 11.3833689],
                [107.5072703, 11.3833414],
                [107.5071455, 11.3833231],
                [107.5070196, 11.3833143],
                [107.5068507, 11.3833173],
                [107.5065282, 11.3833064],
                [107.5062236, 11.383266],
                [107.5059253, 11.3831934],
                [107.5056194, 11.3830861],
                [107.5052436, 11.3829346],
                [107.5050074, 11.3828751],
                [107.5047652, 11.3828477],
                [107.5046432, 11.3828463],
                [107.5043455, 11.3828587],
                [107.5041965, 11.3828556],
                [107.5039461, 11.3828363],
                [107.5037463, 11.3827956],
                [107.503649, 11.3827651],
                [107.5035099, 11.3827079],
                [107.5034141, 11.3826912],
                [107.5033182, 11.3827069],
                [107.503202, 11.3827539],
                [107.5031406, 11.3827677],
                [107.5030151, 11.3827753],
                [107.5028721, 11.3827665],
                [107.50273, 11.3827849],
                [107.5024628, 11.3828762],
                [107.5022548, 11.3829134],
                [107.5020434, 11.3829149],
                [107.5019384, 11.3829021],
                [107.5016252, 11.3828247],
                [107.5014098, 11.3827969],
                [107.501195, 11.3827974],
                [107.5009847, 11.3828252],
                [107.5011098, 11.3830263],
                [107.5011831, 11.3831197],
                [107.5012975, 11.3832419],
                [107.5014049, 11.38337],
                [107.5015451, 11.3835617],
                [107.501604, 11.3836892],
                [107.5016221, 11.3837571],
                [107.5016322, 11.3838265],
                [107.5016284, 11.3839664],
                [107.5015348, 11.3843751],
                [107.5014999, 11.38467],
                [107.5014962, 11.3849565],
                [107.5015106, 11.3852277],
                [107.5015005, 11.3854943],
                [107.5014622, 11.3857583],
                [107.5013718, 11.3861393],
                [107.5013558, 11.3862672],
                [107.5013505, 11.3864784],
                [107.5013593, 11.3865602],
                [107.5014, 11.3867198],
                [107.5014315, 11.3867962],
                [107.5015317, 11.386978],
                [107.5015534, 11.3870365],
                [107.5015774, 11.3871585],
                [107.5015862, 11.3873116],
                [107.5015989, 11.3873873],
                [107.5016642, 11.3876078],
                [107.5017571, 11.3878185],
                [107.5019745, 11.3881741],
                [107.5020256, 11.3882687],
                [107.5020683, 11.3883672],
                [107.5021273, 11.3885732],
                [107.5021568, 11.3888045],
                [107.5021588, 11.3889236],
                [107.5021307, 11.3890394],
                [107.5020361, 11.3892174],
                [107.5020132, 11.3892786],
                [107.5019929, 11.3894072],
                [107.5020049, 11.3895254],
                [107.5019954, 11.3896321],
                [107.5019771, 11.3896828],
                [107.5019498, 11.3897305],
                [107.5019146, 11.389773],
                [107.5018725, 11.389809],
                [107.5018051, 11.3897868],
                [107.5016646, 11.3897669],
                [107.5014346, 11.3897899],
                [107.5013544, 11.3897917],
                [107.501092, 11.3897722],
                [107.5010025, 11.389774],
                [107.5009137, 11.3897845],
                [107.5007822, 11.3898169],
                [107.5007034, 11.3898636],
                [107.5006071, 11.3899752],
                [107.5005685, 11.3900089],
                [107.5004714, 11.3900653],
                [107.4998501, 11.3903164],
                [107.499555, 11.3904222],
                [107.4994365, 11.3904827],
                [107.4993901, 11.3905194],
                [107.4993163, 11.3906109],
                [107.4992929, 11.3906583],
                [107.4992251, 11.3908607],
                [107.4984446, 11.3908187],
                [107.4983547, 11.390816],
                [107.498224, 11.390827],
                [107.4981764, 11.3908394],
                [107.4980887, 11.390883],
                [107.4980503, 11.3909133],
                [107.4979565, 11.3910185],
                [107.4976887, 11.3913629],
                [107.4975112, 11.3916417],
                [107.4973891, 11.3918043],
                [107.497303, 11.3919564],
                [107.4972725, 11.3920229],
                [107.4973235, 11.392086],
                [107.4972188, 11.3923595],
                [107.4971169, 11.3925909],
                [107.4968992, 11.3929761],
                [107.4968621, 11.3930957],
                [107.4968521, 11.3931618],
                [107.4968535, 11.3932953],
                [107.4968783, 11.3934114],
                [107.4968993, 11.393459],
                [107.4969272, 11.3935031],
                [107.4970365, 11.3936268],
                [107.4970718, 11.3937071],
                [107.4970818, 11.3938155],
                [107.4970715, 11.3939453],
                [107.4970392, 11.3940781],
                [107.497015, 11.3941422],
                [107.4977258, 11.3944866],
                [107.4983883, 11.3948258],
                [107.4982742, 11.3948825],
                [107.4981625, 11.3949169],
                [107.4981151, 11.3949219],
                [107.4980208, 11.3949099],
                [107.49791, 11.3948754],
                [107.4978518, 11.3948705],
                [107.4977339, 11.3948856],
                [107.4976396, 11.3949225],
                [107.497606, 11.3949474],
                [107.4975601, 11.3950083],
                [107.4975185, 11.3950454],
                [107.4974617, 11.3950703],
                [107.4974212, 11.395079],
                [107.4973426, 11.3950766],
                [107.4972725, 11.3950519],
                [107.4971813, 11.3949704],
                [107.4971326, 11.3949426],
                [107.4970763, 11.3949407],
                [107.4970257, 11.3949652],
                [107.4969892, 11.3950176],
                [107.496982, 11.3950489],
                [107.4969828, 11.3950808],
                [107.4970092, 11.3951737],
                [107.4970127, 11.3952219],
                [107.4970004, 11.3953162],
                [107.4969825, 11.3953597],
                [107.4969238, 11.3954332],
                [107.4968165, 11.3955094],
                [107.4967606, 11.3955668],
                [107.4967401, 11.3956012],
                [107.4966994, 11.3957189],
                [107.4966453, 11.3957847],
                [107.4964732, 11.3958828],
                [107.4963749, 11.3959715],
                [107.4963395, 11.3960344],
                [107.4963215, 11.3961711],
                [107.4963042, 11.3962193],
                [107.496269, 11.3962783],
                [107.4962077, 11.3964113],
                [107.4961741, 11.3964484],
                [107.4961508, 11.3964587],
                [107.4961002, 11.3964587],
                [107.4960769, 11.3964485],
                [107.4960471, 11.3964185],
                [107.4960083, 11.3963127],
                [107.4960002, 11.3962518],
                [107.4960053, 11.396107],
                [107.4959961, 11.3960661],
                [107.4959677, 11.3960074],
                [107.4959315, 11.3959778],
                [107.4959093, 11.3959698],
                [107.4958858, 11.3959669],
                [107.4958413, 11.3959764],
                [107.4958053, 11.3960037],
                [107.4957906, 11.3960272],
                [107.4957605, 11.396156],
                [107.4957544, 11.3963114],
                [107.4957413, 11.3964148],
                [107.4957152, 11.3964564],
                [107.4956695, 11.3964757],
                [107.4956306, 11.3964703],
                [107.495594, 11.3964479],
                [107.495523, 11.3963476],
                [107.4954753, 11.3963209],
                [107.4954203, 11.3963216],
                [107.4953782, 11.3963448],
                [107.495338, 11.3963556],
                [107.4952558, 11.3963322],
                [107.4952233, 11.3963298],
                [107.4951005, 11.3963539],
                [107.4950629, 11.3963392],
                [107.4950489, 11.3963245],
                [107.4950087, 11.3962471],
                [107.4949679, 11.3962228],
                [107.4949202, 11.3962244],
                [107.4948522, 11.3962693],
                [107.4948234, 11.3962749],
                [107.4947941, 11.3962719],
                [107.4946848, 11.3962255],
                [107.4946589, 11.3962099],
                [107.4946206, 11.396164],
                [107.4946064, 11.3961063],
                [107.4946052, 11.3960435],
                [107.494593, 11.3960143],
                [107.494565, 11.3959842],
                [107.4945248, 11.3959743],
                [107.4945042, 11.3959783],
                [107.4944726, 11.3960002],
                [107.4944441, 11.3960453],
                [107.494419, 11.3961106],
                [107.4944231, 11.3961796],
                [107.4944707, 11.3962677],
                [107.4944742, 11.3963327],
                [107.4944329, 11.3964267],
                [107.4944266, 11.3964909],
                [107.4944106, 11.396548],
                [107.494375, 11.3965917],
                [107.4943209, 11.3966094],
                [107.4942923, 11.3966063],
                [107.4942482, 11.3965822],
                [107.4940619, 11.3963114],
                [107.4939834, 11.3962197],
                [107.4939374, 11.3961803],
                [107.4938399, 11.3961191],
                [107.4937636, 11.3960957],
                [107.4937385, 11.3960981],
                [107.4936965, 11.3961244],
                [107.4936837, 11.3961457],
                [107.4936781, 11.3961764],
                [107.4936864, 11.3962378],
                [107.4937072, 11.3962768],
                [107.493732, 11.3963613],
                [107.4937309, 11.3964356],
                [107.4937188, 11.3964711],
                [107.4936742, 11.3965312],
                [107.4935606, 11.3965952],
                [107.4935246, 11.3966245],
                [107.4934659, 11.3966987],
                [107.493428, 11.3967741],
                [107.4934052, 11.3968019],
                [107.4933775, 11.396825],
                [107.4933184, 11.396852],
                [107.4932325, 11.3968648],
                [107.4931173, 11.3968573],
                [107.4930197, 11.3968261],
                [107.4929464, 11.3967755],
                [107.4928934, 11.3967077],
                [107.492865, 11.3966296],
                [107.4928624, 11.3965467],
                [107.4928793, 11.3964821],
                [107.4928729, 11.3964326],
                [107.4928388, 11.3963955],
                [107.4927647, 11.3963808],
                [107.492727, 11.3963855],
                [107.4926655, 11.3964133],
                [107.4925996, 11.3964731],
                [107.4925389, 11.396557],
                [107.4924768, 11.3966214],
                [107.4924395, 11.3966465],
                [107.4923709, 11.3966762],
                [107.4922838, 11.396695],
                [107.4921982, 11.3967011],
                [107.4921724, 11.3966954],
                [107.4921288, 11.3966669],
                [107.4921135, 11.3966457],
                [107.4921013, 11.3966081],
                [107.4920825, 11.3965884],
                [107.4920581, 11.3965856],
                [107.4920294, 11.3966003],
                [107.4920154, 11.3966164],
                [107.4920058, 11.3966546],
                [107.4920137, 11.3967793],
                [107.4920093, 11.3968579],
                [107.4919977, 11.3968872],
                [107.4919533, 11.3969316],
                [107.491892, 11.3969477],
                [107.4918382, 11.3969348],
                [107.4917435, 11.3968865],
                [107.491588, 11.3967493],
                [107.4915439, 11.3967205],
                [107.4913696, 11.396652],
                [107.4913119, 11.3965949],
                [107.491274, 11.39651],
                [107.4912389, 11.3964772],
                [107.4912183, 11.3964704],
                [107.4911965, 11.3964703],
                [107.4911616, 11.3964868],
                [107.4911304, 11.3965274],
                [107.4911168, 11.3965585],
                [107.4911098, 11.3966018],
                [107.4911125, 11.3966893],
                [107.4911671, 11.3968559],
                [107.4911533, 11.3968978],
                [107.4911375, 11.3969139],
                [107.4910954, 11.3969293],
                [107.4910721, 11.396927],
                [107.4909662, 11.3968855],
                [107.4908091, 11.3968021],
                [107.4907749, 11.3967726],
                [107.4907477, 11.3967369],
                [107.4907285, 11.3966965],
                [107.4907182, 11.3966531],
                [107.4907182, 11.3965476],
                [107.4907064, 11.3965191],
                [107.4906642, 11.3964755],
                [107.4906072, 11.3964534],
                [107.4905669, 11.3964526],
                [107.4904901, 11.3964754],
                [107.4903957, 11.3965508],
                [107.4903183, 11.396576],
                [107.490249, 11.3965728],
                [107.4900577, 11.3965093],
                [107.489942, 11.3964954],
                [107.4898704, 11.3965043],
                [107.4897703, 11.3965598],
                [107.4896931, 11.3965762],
                [107.4896148, 11.3965664],
                [107.4895222, 11.396535],
                [107.4894243, 11.3965296],
                [107.4893811, 11.3965357],
                [107.4892295, 11.3965809],
                [107.4891898, 11.3966175],
                [107.4891749, 11.396669],
                [107.4891907, 11.3967234],
                [107.4892096, 11.3967452],
                [107.4892783, 11.396783],
                [107.4893023, 11.3968258],
                [107.4893036, 11.3968504],
                [107.4892867, 11.3968927],
                [107.4892741, 11.3969818],
                [107.4892446, 11.397066],
                [107.4891989, 11.3971232],
                [107.4891374, 11.3971638],
                [107.4890556, 11.3971845],
                [107.4889612, 11.3971772],
                [107.4888745, 11.3971396],
                [107.4888308, 11.3971046],
                [107.4887538, 11.3970239],
                [107.488696, 11.3969317],
                [107.4886663, 11.3968275],
                [107.4886628, 11.3967734],
                [107.4886787, 11.3966663],
                [107.4887144, 11.3965488],
                [107.4887176, 11.3964549],
                [107.4886927, 11.3963949],
                [107.4886433, 11.3963519],
                [107.4885794, 11.396335],
                [107.4884911, 11.3963385],
                [107.4884476, 11.3963299],
                [107.4883676, 11.396293],
                [107.4882906, 11.3962418],
                [107.4882005, 11.3962196],
                [107.4881538, 11.3962204],
                [107.488108, 11.3962292],
                [107.4879898, 11.3962822],
                [107.4879493, 11.3962924],
                [107.4879078, 11.3962969],
                [107.4877888, 11.3962896],
                [107.4877142, 11.3963102],
                [107.4876607, 11.3963461],
                [107.4876085, 11.3964034],
                [107.487528, 11.3965322],
                [107.4874694, 11.3966547],
                [107.4874179, 11.3968343],
                [107.4874223, 11.396888],
                [107.4874345, 11.3969124],
                [107.4874525, 11.3969331],
                [107.4875012, 11.3969582],
                [107.4876626, 11.3969677],
                [107.4877183, 11.3969809],
                [107.4877986, 11.3970124],
                [107.4878445, 11.3970476],
                [107.4878644, 11.397072],
                [107.4878841, 11.397131],
                [107.4878828, 11.3971622],
                [107.4878643, 11.3972106],
                [107.4878248, 11.3972591],
                [107.4877628, 11.3973078],
                [107.4876675, 11.3973657],
                [107.4875317, 11.3974722],
                [107.487469, 11.3975314],
                [107.487334, 11.3976857],
                [107.4872416, 11.397815],
                [107.4872175, 11.3978661],
                [107.4872015, 11.3979202],
                [107.4871951, 11.3980323],
                [107.4872048, 11.3980878],
                [107.4872491, 11.3981913],
                [107.4873376, 11.3983149],
                [107.4874288, 11.3984663],
                [107.4874828, 11.3985367],
                [107.4875419, 11.3986029],
                [107.4876539, 11.3987055],
                [107.487706, 11.3987416],
                [107.4878009, 11.3987903],
                [107.4878645, 11.3988479],
                [107.487899, 11.3989257],
                [107.4879032, 11.3989682],
                [107.4878984, 11.3990106],
                [107.4878742, 11.3990711],
                [107.4878322, 11.3991243],
                [107.4877587, 11.3991773],
                [107.4876834, 11.3992028],
                [107.4874516, 11.399252],
                [107.4873772, 11.3992761],
                [107.4872377, 11.3993457],
                [107.4871385, 11.3994196],
                [107.4871098, 11.3994554],
                [107.4870892, 11.3994961],
                [107.4870656, 11.3996366],
                [107.4870488, 11.3996824],
                [107.4870251, 11.3997251],
                [107.4869594, 11.3997978],
                [107.4868757, 11.3998467],
                [107.4868288, 11.3998602],
                [107.4867314, 11.3998636],
                [107.486681, 11.3998525],
                [107.4866295, 11.3998504],
                [107.4865631, 11.3998638],
                [107.4865331, 11.3998795],
                [107.4864849, 11.3999263],
                [107.4864401, 11.4000268],
                [107.4864176, 11.4000581],
                [107.4863803, 11.4000937],
                [107.4863372, 11.4001221],
                [107.4862895, 11.4001425],
                [107.4862389, 11.4001542],
                [107.486187, 11.4001568],
                [107.4861354, 11.4001503],
                [107.4860858, 11.4001348],
                [107.4860399, 11.4001108],
                [107.4859692, 11.4000466],
                [107.485933, 11.4000206],
                [107.4858922, 11.4000025],
                [107.4858037, 11.3999922],
                [107.4857184, 11.4000174],
                [107.4856815, 11.4000422],
                [107.4856505, 11.4000739],
                [107.4855402, 11.4002459],
                [107.4853138, 11.4005022],
                [107.4852381, 11.4006323],
                [107.4851898, 11.4007772],
                [107.4851742, 11.4009315],
                [107.4851795, 11.401009],
                [107.4851935, 11.4010854],
                [107.4852502, 11.4012624],
                [107.4852568, 11.4013161],
                [107.4852441, 11.4015395],
                [107.4852729, 11.4016207],
                [107.4853321, 11.4016841],
                [107.4853702, 11.4017057],
                [107.4854557, 11.4017245],
                [107.4855419, 11.4017086],
                [107.4856014, 11.4016728],
                [107.4856549, 11.4016173],
                [107.4857087, 11.4015304],
                [107.485739, 11.4014333],
                [107.4857444, 11.40134],
                [107.4857364, 11.4011124],
                [107.4857466, 11.4010459],
                [107.4857919, 11.4009193],
                [107.4858677, 11.4008075],
                [107.4859615, 11.4007243],
                [107.4860741, 11.4006678],
                [107.4861978, 11.4006419],
                [107.4863241, 11.4006482],
                [107.4864444, 11.4006865],
                [107.4864988, 11.4007163],
                [107.48665, 11.4008238],
                [107.4867822, 11.4009283],
                [107.4868116, 11.4009624],
                [107.4868511, 11.4010425],
                [107.4868721, 11.4011453],
                [107.4869171, 11.4012038],
                [107.4869192, 11.4012467],
                [107.4868951, 11.4012825],
                [107.4868541, 11.4012976],
                [107.486832, 11.4012951],
                [107.4867744, 11.4012633],
                [107.4867519, 11.4012639],
                [107.4867217, 11.4012813],
                [107.4866922, 11.4013372],
                [107.4866901, 11.4013887],
                [107.4866984, 11.4014139],
                [107.4867329, 11.4014542],
                [107.4867567, 11.4014666],
                [107.48681, 11.4014721],
                [107.486836, 11.4014648],
                [107.4869002, 11.401429],
                [107.4869916, 11.4014087],
                [107.4870486, 11.4014089],
                [107.4871588, 11.4014362],
                [107.4872091, 11.4014625],
                [107.4872856, 11.4015275],
                [107.4873322, 11.4016015],
                [107.4873473, 11.401664],
                [107.4873434, 11.401696],
                [107.4873135, 11.4017532],
                [107.4872893, 11.4017751],
                [107.4872115, 11.4018198],
                [107.4871557, 11.4018758],
                [107.4871203, 11.401946],
                [107.4871112, 11.4019866],
                [107.4871123, 11.4020696],
                [107.4871391, 11.4021484],
                [107.4872415, 11.4022861],
                [107.4872555, 11.402318],
                [107.4872566, 11.4023869],
                [107.4872437, 11.4024192],
                [107.4871939, 11.4024736],
                [107.4871623, 11.4024931],
                [107.4870908, 11.4025139],
                [107.487014, 11.4025206],
                [107.4869433, 11.4025507],
                [107.4869055, 11.4025799],
                [107.486847, 11.4026546],
                [107.4867736, 11.4028547],
                [107.4867797, 11.4029319],
                [107.4867945, 11.4029665],
                [107.486807, 11.4030287],
                [107.4868013, 11.403106],
                [107.4867858, 11.4031555],
                [107.4867613, 11.4032014],
                [107.4867287, 11.4032422],
                [107.4866405, 11.4033262],
                [107.4866052, 11.4033757],
                [107.4865551, 11.4034858],
                [107.4865195, 11.4036734],
                [107.4864673, 11.4038505],
                [107.4864578, 11.4039813],
                [107.4864735, 11.4041042],
                [107.4864735, 11.4042207],
                [107.4864266, 11.4044267],
                [107.4864105, 11.4044465],
                [107.4863648, 11.4044693],
                [107.4863139, 11.4044644],
                [107.4862738, 11.4044335],
                [107.4862379, 11.4043675],
                [107.4862188, 11.4043188],
                [107.4862002, 11.4042163],
                [107.4862009, 11.4041641],
                [107.4862194, 11.4040467],
                [107.4862166, 11.4040134],
                [107.4861953, 11.4039511],
                [107.486176, 11.4039246],
                [107.4861228, 11.4038864],
                [107.4860582, 11.4038732],
                [107.4860215, 11.4038778],
                [107.485954, 11.4039064],
                [107.4859254, 11.4039294],
                [107.4858838, 11.4039889],
                [107.4858436, 11.4041729],
                [107.4857755, 11.4043532],
                [107.4856437, 11.4045951],
                [107.4855363, 11.404833],
                [107.4853957, 11.4050669],
                [107.4853732, 11.4051164],
                [107.485348, 11.4052217],
                [107.4853501, 11.4053298],
                [107.4853634, 11.4053899],
                [107.4853861, 11.4054472],
                [107.4854177, 11.4055004],
                [107.4854574, 11.405548],
                [107.4855515, 11.4056216],
                [107.4856609, 11.4056707],
                [107.4857194, 11.4056852],
                [107.4858358, 11.4056931],
                [107.4859466, 11.4057143],
                [107.4861696, 11.4058103],
                [107.4863737, 11.4058435],
                [107.486458, 11.405869],
                [107.4865207, 11.4059175],
                [107.4865571, 11.4059871],
                [107.486565, 11.4060747],
                [107.4865517, 11.4062316],
                [107.4865624, 11.40635],
                [107.4865356, 11.4065235],
                [107.48651, 11.4066076],
                [107.4864509, 11.4067401],
                [107.4863791, 11.4068291],
                [107.4863018, 11.406886],
                [107.4862751, 11.4069136],
                [107.4862558, 11.4069467],
                [107.486245, 11.4069832],
                [107.4862434, 11.4070213],
                [107.4862509, 11.4070586],
                [107.4862673, 11.4070932],
                [107.4862915, 11.407123],
                [107.4863437, 11.4071568],
                [107.4864379, 11.4072373],
                [107.4865478, 11.4073618],
                [107.4866884, 11.407453],
                [107.4867173, 11.4075082],
                [107.4867134, 11.4075701],
                [107.4866778, 11.4076214],
                [107.4866509, 11.4076383],
                [107.4865647, 11.4076663],
                [107.4864908, 11.407718],
                [107.486386, 11.4078451],
                [107.48632, 11.4078847],
                [107.4862826, 11.4078947],
                [107.4862053, 11.4078938],
                [107.4861681, 11.4078828],
                [107.4860689, 11.4078328],
                [107.4859649, 11.4078043],
                [107.485857, 11.4078013],
                [107.4857999, 11.4078104],
                [107.4857449, 11.4078284],
                [107.4856937, 11.4078548],
                [107.4856475, 11.407889],
                [107.4855814, 11.4079663],
                [107.4855451, 11.4080475],
                [107.4855325, 11.4081352],
                [107.4855369, 11.4081898],
                [107.485551, 11.4082429],
                [107.4855742, 11.4082927],
                [107.485606, 11.4083378],
                [107.4856452, 11.4083769],
                [107.4856907, 11.4084086],
                [107.4857693, 11.4084411],
                [107.4858538, 11.4084521],
                [107.4860657, 11.408517],
                [107.4861577, 11.4085688],
                [107.4862198, 11.408627],
                [107.4862352, 11.4086555],
                [107.4862417, 11.4087191],
                [107.4862157, 11.4087778],
                [107.486172, 11.4088128],
                [107.4861262, 11.4088256],
                [107.4860098, 11.4088944],
                [107.4859288, 11.4089613],
                [107.4859037, 11.4089927],
                [107.4858843, 11.4090278],
                [107.4858647, 11.4091049],
                [107.485874, 11.4091902],
                [107.4858908, 11.4092334],
                [107.4859463, 11.4093078],
                [107.4859831, 11.4093368],
                [107.4861038, 11.4094038],
                [107.4861574, 11.4094472],
                [107.4862441, 11.4095453],
                [107.4862788, 11.4096006],
                [107.4863072, 11.4096593],
                [107.4863646, 11.4098347],
                [107.4863931, 11.4098874],
                [107.4864283, 11.4099362],
                [107.4865657, 11.410089],
                [107.4865897, 11.41013],
                [107.4866073, 11.4101741],
                [107.4866214, 11.4102675],
                [107.4866015, 11.4103908],
                [107.4865544, 11.4105068],
                [107.4865076, 11.4105789],
                [107.4863831, 11.4108137],
                [107.4862929, 11.4109364],
                [107.486249, 11.4109804],
                [107.4861733, 11.4110377],
                [107.4861366, 11.4110905],
                [107.4861299, 11.411154],
                [107.4861674, 11.4112485],
                [107.4861666, 11.4113179],
                [107.4861364, 11.4113807],
                [107.4861118, 11.4114059],
                [107.4860342, 11.4114607],
                [107.4860018, 11.4114953],
                [107.485969, 11.4115531],
                [107.4859636, 11.4115859],
                [107.4859661, 11.4116191],
                [107.4859938, 11.4116794],
                [107.4860594, 11.4117322],
                [107.4860879, 11.4117787],
                [107.486088, 11.411833],
                [107.4860769, 11.4118582],
                [107.4860516, 11.4118861],
                [107.4859892, 11.4119287],
                [107.4859271, 11.4119485],
                [107.4858458, 11.4119521],
                [107.4857922, 11.4119435],
                [107.4857409, 11.411926],
                [107.4856934, 11.4119002],
                [107.4855912, 11.4118253],
                [107.485535, 11.4117956],
                [107.485363, 11.411731],
                [107.4852186, 11.4116478],
                [107.4851672, 11.4116286],
                [107.4851123, 11.4116336],
                [107.4850619, 11.4116656],
                [107.4850301, 11.4117212],
                [107.4850255, 11.411753],
                [107.4850374, 11.4118113],
                [107.4850519, 11.4118376],
                [107.4850953, 11.4118792],
                [107.4851628, 11.4119169],
                [107.4852091, 11.4119614],
                [107.4852524, 11.4120251],
                [107.485288, 11.4121062],
                [107.4852936, 11.4121432],
                [107.4852814, 11.4122165],
                [107.4852255, 11.412307],
                [107.485207, 11.4123678],
                [107.4852065, 11.4125059],
                [107.4852405, 11.412602],
                [107.4852696, 11.4126443],
                [107.4853185, 11.4126915],
                [107.4854325, 11.4127659],
                [107.485562, 11.4128099],
                [107.4856765, 11.4128195],
                [107.4857336, 11.4128125],
                [107.4858897, 11.4127742],
                [107.4860253, 11.412763],
                [107.4861335, 11.4127378],
                [107.4862261, 11.4126903],
                [107.4862961, 11.4126234],
                [107.4863425, 11.4125391],
                [107.4863762, 11.412358],
                [107.486475, 11.4120463],
                [107.4864835, 11.411984],
                [107.4864806, 11.4118742],
                [107.4864986, 11.4117818],
                [107.4865489, 11.4117017],
                [107.4865843, 11.4116697],
                [107.4866697, 11.4116268],
                [107.4867485, 11.4116158],
                [107.4869074, 11.4116158],
                [107.4870913, 11.4116426],
                [107.4872083, 11.4116378],
                [107.4872652, 11.4116662],
                [107.4872842, 11.4116868],
                [107.4873067, 11.4117373],
                [107.4873091, 11.4117649],
                [107.4872971, 11.4118157],
                [107.4872489, 11.4118902],
                [107.4871907, 11.4119439],
                [107.487115, 11.4119686],
                [107.4870218, 11.4119672],
                [107.4869856, 11.4119744],
                [107.4869192, 11.4120054],
                [107.4868762, 11.4120437],
                [107.4868089, 11.4121361],
                [107.4867859, 11.4121884],
                [107.4867636, 11.4122999],
                [107.4867813, 11.4124846],
                [107.4868259, 11.412683],
                [107.4868599, 11.4127883],
                [107.4869909, 11.4131045],
                [107.4870247, 11.4132152],
                [107.4870935, 11.4135697],
                [107.4871813, 11.4138852],
                [107.4871974, 11.4139657],
                [107.4872072, 11.4141294],
                [107.4871646, 11.4144894],
                [107.4871336, 11.4145859],
                [107.4871135, 11.4146182],
                [107.4870566, 11.4146689],
                [107.4869845, 11.4146952],
                [107.4869077, 11.4146934],
                [107.4868268, 11.4146668],
                [107.4867808, 11.4146749],
                [107.4867609, 11.4146871],
                [107.4867355, 11.4147195],
                [107.4867054, 11.4147954],
                [107.486706, 11.4148767],
                [107.4867394, 11.4149552],
                [107.4869745, 11.4152524],
                [107.4870163, 11.4153398],
                [107.4870371, 11.4154449],
                [107.4870361, 11.4155038],
                [107.4870262, 11.4155618],
                [107.486973, 11.4157063],
                [107.4869759, 11.4157588],
                [107.4870023, 11.4158045],
                [107.487072, 11.4158755],
                [107.4871028, 11.4159383],
                [107.4872142, 11.4160935],
                [107.4872383, 11.4161489],
                [107.4872573, 11.4162324],
                [107.4872627, 11.4164197],
                [107.4872721, 11.4164928],
                [107.4873376, 11.41673],
                [107.4873529, 11.4168329],
                [107.4873489, 11.4168848],
                [107.4873181, 11.4169843],
                [107.487292, 11.4170297],
                [107.4872028, 11.4171481],
                [107.4871515, 11.4172552],
                [107.4871362, 11.4173433],
                [107.4871384, 11.417388],
                [107.4871603, 11.417496],
                [107.4871422, 11.4175588],
                [107.4870967, 11.4176066],
                [107.4870667, 11.4176213],
                [107.4869816, 11.4176432],
                [107.4869421, 11.4176625],
                [107.486867, 11.4177226],
                [107.4868337, 11.4177632],
                [107.4867875, 11.4178516],
                [107.4867751, 11.4178976],
                [107.4867715, 11.4179923],
                [107.4867802, 11.418039],
                [107.4868172, 11.4181251],
                [107.4869001, 11.4182369],
                [107.4869628, 11.4183054],
                [107.4870318, 11.4183679],
                [107.4871454, 11.4184482],
                [107.4872067, 11.4184815],
                [107.4873359, 11.4185332],
                [107.4874549, 11.4186035],
                [107.4875064, 11.4186493],
                [107.4875893, 11.4187586],
                [107.4876672, 11.4189438],
                [107.4876996, 11.4190018],
                [107.4877729, 11.4191003],
                [107.4878208, 11.4192012],
                [107.4878338, 11.4193118],
                [107.4878172, 11.4194021],
                [107.4878101, 11.4194935],
                [107.4878207, 11.4197226],
                [107.4877989, 11.4198586],
                [107.487697, 11.4201584],
                [107.4876548, 11.4202279],
                [107.487587, 11.420274],
                [107.4874993, 11.420292],
                [107.48741, 11.4202846],
                [107.4873345, 11.4202567],
                [107.487128, 11.4202005],
                [107.4868539, 11.4200621],
                [107.4868134, 11.4200498],
                [107.4867419, 11.4200409],
                [107.4866854, 11.4200577],
                [107.4866617, 11.4200753],
                [107.4866308, 11.420122],
                [107.4865998, 11.4203037],
                [107.4865997, 11.420355],
                [107.4866077, 11.4204058],
                [107.4866472, 11.4205005],
                [107.4867146, 11.4205788],
                [107.4868391, 11.4206725],
                [107.4870156, 11.4207879],
                [107.4873289, 11.4209523],
                [107.4874479, 11.4210243],
                [107.4877246, 11.4212167],
                [107.4880074, 11.4213732],
                [107.4881035, 11.4214567],
                [107.4881422, 11.4215071],
                [107.4881895, 11.4216003],
                [107.4882112, 11.4217021],
                [107.4882076, 11.4217969],
                [107.4881765, 11.4218769],
                [107.4881508, 11.4219116],
                [107.4881193, 11.4219415],
                [107.488043, 11.421983],
                [107.4878551, 11.4220141],
                [107.4877667, 11.4220566],
                [107.4877257, 11.4220878],
                [107.4876909, 11.4221256],
                [107.4876635, 11.4221688],
                [107.4876443, 11.4222161],
                [107.4876326, 11.4223168],
                [107.4876594, 11.4224246],
                [107.4876852, 11.4224742],
                [107.4877485, 11.4225501],
                [107.487851, 11.4226289],
                [107.4879246, 11.422674],
                [107.4880023, 11.4227121],
                [107.4882108, 11.4227859],
                [107.4883278, 11.4228518],
                [107.4885138, 11.4230091],
                [107.4886344, 11.423085],
                [107.4887168, 11.4231538],
                [107.4887851, 11.4232486],
                [107.4888108, 11.4233064],
                [107.4888277, 11.4233672],
                [107.4888356, 11.4234299],
                [107.4888342, 11.4234929],
                [107.4888235, 11.4235551],
                [107.4887323, 11.4237918],
                [107.4886815, 11.4239759],
                [107.4886572, 11.4241651],
                [107.4886492, 11.4244043],
                [107.4886191, 11.4247732],
                [107.4886081, 11.4248389],
                [107.4885894, 11.4249029],
                [107.4885106, 11.4250717],
                [107.4884829, 11.4251878],
                [107.4884789, 11.4252569],
                [107.4884679, 11.4253252],
                [107.48845, 11.4253921],
                [107.4883944, 11.4255191],
                [107.4882715, 11.4257091],
                [107.4881694, 11.4259078],
                [107.4881013, 11.4260773],
                [107.4880854, 11.4261465],
                [107.4880785, 11.4262171],
                [107.4880808, 11.426288],
                [107.4880923, 11.426358],
                [107.4881688, 11.4265653],
                [107.4881877, 11.4266378],
                [107.4882066, 11.4267863],
                [107.4882072, 11.42684],
                [107.4881984, 11.4268929],
                [107.4881803, 11.4269437],
                [107.4881537, 11.4269906],
                [107.4881192, 11.4270323],
                [107.4880478, 11.4270866],
                [107.4879497, 11.4271292],
                [107.4878102, 11.4271587],
                [107.4876675, 11.4271543],
                [107.4874463, 11.4271169],
                [107.4872319, 11.4271054],
                [107.486946, 11.4271149],
                [107.4867142, 11.4271445],
                [107.4866265, 11.4271672],
                [107.4865416, 11.4271987],
                [107.4864605, 11.4272385],
                [107.486202, 11.4274147],
                [107.4861521, 11.4274781],
                [107.4861293, 11.427555],
                [107.486137, 11.4276348],
                [107.4861521, 11.4276722],
                [107.4862084, 11.4277643],
                [107.4862349, 11.4278348],
                [107.48627, 11.4279016],
                [107.4863131, 11.4279637],
                [107.4863636, 11.4280203],
                [107.4864208, 11.4280703],
                [107.4864838, 11.4281131],
                [107.486681, 11.4282056],
                [107.4867858, 11.4282851],
                [107.4868261, 11.4283306],
                [107.4868857, 11.4284358],
                [107.4869138, 11.428553],
                [107.4869157, 11.4285985],
                [107.4869019, 11.4287345],
                [107.4868816, 11.4288233],
                [107.486852, 11.4289096],
                [107.4868134, 11.4289924],
                [107.4867162, 11.4291379],
                [107.4864337, 11.4294468],
                [107.4862646, 11.4296041],
                [107.4861724, 11.4296742],
                [107.4859749, 11.4297956],
                [107.4858704, 11.4298464],
                [107.4857775, 11.4298848],
                [107.4856815, 11.4299147],
                [107.485583, 11.4299359],
                [107.485483, 11.4299483],
                [107.485219, 11.4299513],
                [107.4850585, 11.4299806],
                [107.4849811, 11.4300062],
                [107.4848351, 11.4300778],
                [107.4846988, 11.4301622],
                [107.4844897, 11.4302557],
                [107.4842603, 11.4304194],
                [107.4841373, 11.4304831],
                [107.4840563, 11.4305132],
                [107.4838903, 11.4305532],
                [107.4837714, 11.4305628],
                [107.4837193, 11.4305589],
                [107.4836243, 11.4305338],
                [107.4835798, 11.4305063],
                [107.483498, 11.430434],
                [107.4834624, 11.4304143],
                [107.4834073, 11.4304046],
                [107.4833773, 11.430413],
                [107.483308, 11.4304555],
                [107.4832257, 11.4305329],
                [107.4830889, 11.4307128],
                [107.4830179, 11.430788],
                [107.4829088, 11.4308574],
                [107.4828479, 11.4308804],
                [107.4827196, 11.430901],
                [107.4825795, 11.4308872],
                [107.4825062, 11.4308683],
                [107.4823681, 11.4308078],
                [107.482305, 11.4307668],
                [107.4821893, 11.4306639],
                [107.4821368, 11.430604],
                [107.481946, 11.4303278],
                [107.4818825, 11.4302801],
                [107.4818417, 11.4302631],
                [107.4817543, 11.4302516],
                [107.4816683, 11.4302709],
                [107.4815946, 11.4303185],
                [107.4815022, 11.4304142],
                [107.4812896, 11.4305841],
                [107.4812216, 11.4306479],
                [107.4810388, 11.4308484],
                [107.4809042, 11.430954],
                [107.4808305, 11.430998],
                [107.480751, 11.4310343],
                [107.4806677, 11.4310617],
                [107.4805819, 11.4310798],
                [107.4804945, 11.4310884],
                [107.4802216, 11.4310816],
                [107.4800616, 11.431063],
                [107.4799647, 11.4310418],
                [107.4798697, 11.4310132],
                [107.479732, 11.4309573],
                [107.4796317, 11.4309018],
                [107.479527, 11.4308244],
                [107.4794247, 11.4307743],
                [107.4793124, 11.4307535],
                [107.4792396, 11.4307738],
                [107.4791832, 11.4308233],
                [107.4790898, 11.4310006],
                [107.4790158, 11.4311927],
                [107.4789152, 11.4316007],
                [107.4788913, 11.4316684],
                [107.4788244, 11.4317914],
                [107.4787815, 11.4318472],
                [107.4787076, 11.4319229],
                [107.4786342, 11.43197],
                [107.4785339, 11.4319976],
                [107.4783509, 11.4319884],
                [107.4781944, 11.4320089],
                [107.4781191, 11.4320325],
                [107.4780118, 11.4320825],
                [107.4779446, 11.4321371],
                [107.4779157, 11.4321744],
                [107.4778779, 11.4322603],
                [107.47787, 11.4323536],
                [107.4778776, 11.4323999],
                [107.477939, 11.4325412],
                [107.4779713, 11.4326569],
                [107.4779812, 11.4327327],
                [107.477976, 11.4328854],
                [107.4779378, 11.4330335],
                [107.4778617, 11.4331862],
                [107.4778088, 11.4332641],
                [107.4776816, 11.4334035],
                [107.4776284, 11.4334422],
                [107.477509, 11.433498],
                [107.4773791, 11.4335225],
                [107.4772471, 11.4335139],
                [107.4769042, 11.4334349],
                [107.4768039, 11.4334222],
                [107.4767028, 11.4334179],
                [107.4765514, 11.4334271],
                [107.4764211, 11.4334548],
                [107.4763432, 11.4334826],
                [107.4761423, 11.4335875],
                [107.476084, 11.4336085],
                [107.4759626, 11.4336336],
                [107.4759693, 11.4337803],
                [107.4759662, 11.4338949],
                [107.4759087, 11.4340609],
                [107.4758707, 11.4341403],
                [107.4758169, 11.4342324],
                [107.4757549, 11.4343193],
                [107.4756852, 11.4344005],
                [107.4756084, 11.4344752],
                [107.4755411, 11.4345245],
                [107.4753991, 11.434597],
                [107.4752556, 11.4347219],
                [107.4751332, 11.434794],
                [107.4750722, 11.4348636],
                [107.4750491, 11.4349088],
                [107.475037, 11.4349585],
                [107.4750345, 11.4350144],
                [107.4750417, 11.4350712],
                [107.4750729, 11.4351725],
                [107.475125, 11.4352681],
                [107.4751496, 11.4353412],
                [107.4751808, 11.4355146],
                [107.4751831, 11.4356028],
                [107.4751765, 11.4356907],
                [107.4751364, 11.4358633],
                [107.4750621, 11.4360246],
                [107.474964, 11.4361632],
                [107.4748868, 11.4360559],
                [107.4748416, 11.436007],
                [107.4747342, 11.4359279],
                [107.4746737, 11.4358989],
                [107.4745441, 11.4358643],
                [107.474477, 11.4358593],
                [107.4743412, 11.4358748],
                [107.4742747, 11.4358956],
                [107.4742116, 11.4359248],
                [107.4740997, 11.4360059],
                [107.4740131, 11.4361127],
                [107.4739044, 11.4363006],
                [107.4738453, 11.4363583],
                [107.4737121, 11.4364561],
                [107.4736391, 11.4364954],
                [107.4734834, 11.4365531],
                [107.4733999, 11.4365714],
                [107.4732295, 11.436584],
                [107.4730598, 11.4365644],
                [107.4724957, 11.4363868],
                [107.471885, 11.4362399],
                [107.4716927, 11.4361642],
                [107.4716013, 11.4361162],
                [107.4715136, 11.4360619],
                [107.4713516, 11.4359352],
                [107.4712743, 11.4358595],
                [107.4709225, 11.4354515],
                [107.4706754, 11.4351941],
                [107.4704055, 11.4349599],
                [107.4700711, 11.4347101],
                [107.4699821, 11.4346311],
                [107.4697773, 11.4344235],
                [107.4697104, 11.4343685],
                [107.4695653, 11.4342737],
                [107.4694657, 11.4342244],
                [107.4692561, 11.4341503],
                [107.4691474, 11.434126],
                [107.4689257, 11.4341034],
                [107.4688142, 11.4341054],
                [107.4684634, 11.4341409],
                [107.4682872, 11.4341482],
                [107.4679345, 11.4341422],
                [107.4675697, 11.4341212],
                [107.4674316, 11.4341205],
                [107.4672937, 11.4341291],
                [107.4671568, 11.4341471],
                [107.4670215, 11.4341743],
                [107.4666349, 11.4342893],
                [107.4665047, 11.4343164],
                [107.4662405, 11.4343457],
                [107.4659745, 11.4343412],
                [107.4658424, 11.4343263],
                [107.465718, 11.4343044],
                [107.4654746, 11.4342383],
                [107.4652413, 11.4341435],
                [107.4650216, 11.4340213],
                [107.4649362, 11.4339238],
                [107.4647479, 11.433745],
                [107.4646458, 11.4336645],
                [107.4644766, 11.4335511],
                [107.4641526, 11.4333775],
                [107.4636969, 11.4331774],
                [107.4636159, 11.4331298],
                [107.46354, 11.4330746],
                [107.4634701, 11.4330122],
                [107.4634069, 11.4329434],
                [107.4633371, 11.4328476],
                [107.4632599, 11.4327575],
                [107.4631757, 11.4326736],
                [107.4629375, 11.4324787],
                [107.4628097, 11.4323406],
                [107.4627046, 11.4321852],
                [107.4625839, 11.4319472],
                [107.4625325, 11.4318771],
                [107.4624735, 11.4318129],
                [107.4623434, 11.4317097],
                [107.4621958, 11.4316325],
                [107.462117, 11.4316045],
                [107.4619532, 11.4315711],
                [107.4618018, 11.4315675],
                [107.4616509, 11.4315546],
                [107.4615012, 11.4315325],
                [107.4613532, 11.4315011],
                [107.4612075, 11.4314607],
                [107.4607773, 11.4313129],
                [107.4603659, 11.4312021],
                [107.4599768, 11.4311232],
                [107.4597806, 11.4310926],
                [107.4587459, 11.4308997],
                [107.4585446, 11.4308552],
                [107.4583457, 11.4308015],
                [107.4581495, 11.4307386],
                [107.4578557, 11.4306249],
                [107.4575282, 11.4305124],
                [107.4574193, 11.4304662],
                [107.4570179, 11.4302671],
                [107.4569246, 11.4302295],
                [107.4567298, 11.4301784],
                [107.4565291, 11.4301604],
                [107.4563281, 11.4301761],
                [107.4561328, 11.430225],
                [107.4559306, 11.4303136],
                [107.4557446, 11.4304313],
                [107.455659, 11.4305002],
                [107.455579, 11.4305753],
                [107.4554022, 11.4307719],
                [107.4552771, 11.4308642],
                [107.4551352, 11.4309293],
                [107.4549026, 11.4309854],
                [107.4547544, 11.4310474],
                [107.45462, 11.4311347],
                [107.4544609, 11.4312642],
                [107.4543736, 11.4313187],
                [107.4541398, 11.4314328],
                [107.4540726, 11.4314734],
                [107.4539593, 11.431558],
                [107.4538478, 11.4316172],
                [107.4537248, 11.4316473],
                [107.453598, 11.4316464],
                [107.4534755, 11.4316144],
                [107.453365, 11.4315534],
                [107.4532836, 11.4314792],
                [107.4532284, 11.4314029],
                [107.4531341, 11.4312977],
                [107.452955, 11.4311215],
                [107.4529049, 11.4310613],
                [107.4528235, 11.4309282],
                [107.4527928, 11.4308566],
                [107.4527275, 11.4306172],
                [107.4526961, 11.4305378],
                [107.4526581, 11.4304612],
                [107.4525148, 11.4302386],
                [107.4524764, 11.4301577],
                [107.4524238, 11.4299872],
                [107.4524048, 11.4298272],
                [107.4523734, 11.4296869],
                [107.4523147, 11.4295552],
                [107.4522232, 11.4294116],
                [107.4522082, 11.429365],
                [107.4522013, 11.4293166],
                [107.4522125, 11.4292199],
                [107.4522303, 11.4291743],
                [107.4522878, 11.4290949],
                [107.4523687, 11.4290385],
                [107.4524152, 11.4290209],
                [107.452563, 11.4289911],
                [107.4527024, 11.4289343],
                [107.4527673, 11.4288964],
                [107.4528756, 11.428812],
                [107.4529514, 11.4287138],
                [107.4529939, 11.4286133],
                [107.4530344, 11.4283805],
                [107.4530604, 11.4280987],
                [107.4530621, 11.4279572],
                [107.4530429, 11.4276749],
                [107.452997, 11.4274173],
                [107.4529624, 11.427291],
                [107.4529072, 11.427133],
                [107.4528463, 11.426977],
                [107.4527305, 11.426717],
                [107.4526513, 11.4264971],
                [107.4525966, 11.4262516],
                [107.4524884, 11.4258542],
                [107.4524525, 11.425698],
                [107.4524137, 11.4254376],
                [107.4523704, 11.4252358],
                [107.4523395, 11.4251372],
                [107.4522238, 11.4248476],
                [107.452196, 11.4247473],
                [107.4521645, 11.4245417],
                [107.452161, 11.4244378],
                [107.4521656, 11.4243339],
                [107.4521994, 11.4241287],
                [107.452265, 11.4239311],
                [107.4523319, 11.4238024],
                [107.4524247, 11.4236902],
                [107.4524796, 11.4236419],
                [107.4525943, 11.423568],
                [107.4530238, 11.4233586],
                [107.4532131, 11.4232802],
                [107.4536015, 11.4231485],
                [107.4537997, 11.4230954],
                [107.4542025, 11.4230153],
                [107.4544062, 11.4229884],
                [107.4546109, 11.4229704],
                [107.4549376, 11.4229601],
                [107.4550585, 11.4229495],
                [107.4551782, 11.4229296],
                [107.4553655, 11.4228786],
                [107.4554324, 11.4228496],
                [107.455555, 11.4227719],
                [107.4556582, 11.4226707],
                [107.4557011, 11.4226127],
                [107.4557671, 11.422485],
                [107.4557894, 11.4224168],
                [107.4558092, 11.4222984],
                [107.4558041, 11.4221786],
                [107.4557921, 11.4221197],
                [107.4557262, 11.4219523],
                [107.4556312, 11.4217989],
                [107.4554729, 11.4216175],
                [107.4552051, 11.4213645],
                [107.454874, 11.4210934],
                [107.4546736, 11.4208971],
                [107.4544576, 11.4206639],
                [107.4540823, 11.420329],
                [107.453868, 11.4201207],
                [107.4537608, 11.4200513],
                [107.4537027, 11.4200243],
                [107.4536182, 11.4199958],
                [107.4535312, 11.4199761],
                [107.4534425, 11.4199654],
                [107.4533532, 11.4199638],
                [107.4532642, 11.4199715],
                [107.4531766, 11.4199882],
                [107.4530911, 11.4200138],
                [107.4528394, 11.420117],
                [107.452709, 11.4201564],
                [107.4525762, 11.4201873],
                [107.4523936, 11.4202155],
                [107.4522101, 11.4202372],
                [107.4518412, 11.4202609],
                [107.4513336, 11.4202554],
                [107.4508274, 11.4202188],
                [107.4505492, 11.4201854],
                [107.450272, 11.4201451],
                [107.4498229, 11.4200647],
                [107.4494817, 11.4199785],
                [107.4493139, 11.4199257],
                [107.4489037, 11.4197784],
                [107.4487777, 11.4197456],
                [107.4485207, 11.4197035],
                [107.4482204, 11.4196884],
                [107.4476016, 11.4196996],
                [107.4468544, 11.4196847],
                [107.446506, 11.4196526],
                [107.4463335, 11.4196237],
                [107.4461627, 11.4195863],
                [107.4457151, 11.4194564],
                [107.4454002, 11.4193736],
                [107.4452454, 11.4193233],
                [107.4448847, 11.4191895],
                [107.4447776, 11.4191596],
                [107.4445813, 11.4191209],
                [107.4444035, 11.419117],
                [107.4442284, 11.4191472],
                [107.4441439, 11.4191748],
                [107.4439853, 11.4192535],
                [107.4439126, 11.4193039],
                [107.4437797, 11.4194264],
                [107.4436679, 11.4195678],
                [107.44358, 11.4197246],
                [107.4435476, 11.4198025],
                [107.4434691, 11.4200427],
                [107.4434239, 11.420205],
                [107.4433547, 11.4205343],
                [107.4433267, 11.4207355],
                [107.4432946, 11.4211403],
                [107.4432905, 11.4213433],
                [107.4433064, 11.421749],
                [107.4433805, 11.4223543],
                [107.4434299, 11.4226553],
                [107.4435532, 11.4232528],
                [107.443585, 11.4233847],
                [107.443671, 11.4236422],
                [107.4437859, 11.4238887],
                [107.4439283, 11.4241209],
                [107.4440092, 11.4242308],
                [107.4441408, 11.4243856],
                [107.4442795, 11.4245342],
                [107.4444251, 11.4246764],
                [107.4445772, 11.4248119],
                [107.4447355, 11.4249404],
                [107.4448997, 11.4250616],
                [107.4453628, 11.4253769],
                [107.4458051, 11.4257104],
                [107.4460386, 11.4258547],
                [107.446331, 11.4260089],
                [107.4464099, 11.4260643],
                [107.4464838, 11.426126],
                [107.4466142, 11.4262667],
                [107.4467079, 11.4264071],
                [107.4468063, 11.4266028],
                [107.446914, 11.4268737],
                [107.4469918, 11.4271543],
                [107.4470235, 11.4273247],
                [107.4470647, 11.4274931],
                [107.4471849, 11.4278799],
                [107.4472022, 11.4279918],
                [107.4472, 11.4280484],
                [107.4471742, 11.4281586],
                [107.4469561, 11.4286037],
                [107.4468916, 11.4287165],
                [107.446745, 11.4289316],
                [107.4465041, 11.4292133],
                [107.446375, 11.4293468],
                [107.4462406, 11.429475],
                [107.4459565, 11.429715],
                [107.4456698, 11.4299179],
                [107.4455188, 11.4300081],
                [107.4453631, 11.4300905],
                [107.4452033, 11.4301647],
                [107.4448729, 11.4302881],
                [107.4445314, 11.4303918],
                [107.4443632, 11.4304511],
                [107.4442333, 11.4305008],
                [107.4441073, 11.4305595],
                [107.4439859, 11.4306268],
                [107.4438696, 11.4307024],
                [107.4437592, 11.430786],
                [107.4436551, 11.430877],
                [107.4434014, 11.4311282],
                [107.4431281, 11.4313588],
                [107.4425934, 11.4317489],
                [107.4424184, 11.4318517],
                [107.4423247, 11.4318915],
                [107.4421286, 11.4319466],
                [107.4420276, 11.4319614],
                [107.4418236, 11.431965],
                [107.4417221, 11.4319537],
                [107.441622, 11.4319339],
                [107.4412808, 11.4318317],
                [107.4406105, 11.4316947],
                [107.4405295, 11.4316675],
                [107.440452, 11.4316319],
                [107.4403789, 11.4315882],
                [107.4403111, 11.4315371],
                [107.4402493, 11.431479],
                [107.4401943, 11.4314147],
                [107.4400777, 11.4312385],
                [107.4400322, 11.4311936],
                [107.4399805, 11.4311557],
                [107.4398912, 11.4311125],
                [107.4397028, 11.43105],
                [107.4396051, 11.4310311],
                [107.4394065, 11.431019],
                [107.4390042, 11.4310611],
                [107.4386822, 11.4310784],
                [107.4383601, 11.4310623],
                [107.4382002, 11.4310417],
                [107.4378848, 11.4309758],
                [107.4375783, 11.4308775],
                [107.4374295, 11.4308166],
                [107.4371427, 11.430672],
                [107.4370311, 11.430605],
                [107.4369249, 11.4305301],
                [107.4368246, 11.4304478],
                [107.4367307, 11.4303584],
                [107.4366438, 11.4302625],
                [107.4365643, 11.4301607],
                [107.4364926, 11.4300533],
                [107.4364293, 11.4299411],
                [107.436242, 11.4295351],
                [107.4360223, 11.429145],
                [107.4357717, 11.4287733],
                [107.4356353, 11.428595],
                [107.435368, 11.4282838],
                [107.4350784, 11.4279925],
                [107.4347679, 11.4277225],
                [107.4344208, 11.4274404],
                [107.4340677, 11.4271655],
                [107.4337089, 11.4268979],
                [107.4330072, 11.4263606],
                [107.4322894, 11.4258441],
                [107.4314872, 11.4253036],
                [107.4306675, 11.4247891],
                [107.4302513, 11.4245417],
                [107.430292, 11.424752],
                [107.4303493, 11.4251764],
                [107.4303739, 11.4255887],
                [107.430369, 11.4259867],
                [107.4303358, 11.4264179],
                [107.4303092, 11.426626],
                [107.4302207, 11.427056],
                [107.4301644, 11.4272694],
                [107.4299557, 11.427904],
                [107.4298934, 11.4281187],
                [107.4298376, 11.4283351],
                [107.4297513, 11.4287382],
                [107.4297229, 11.4289248],
                [107.4296913, 11.4293008],
                [107.4296935, 11.4296781],
                [107.4297294, 11.4300537],
                [107.4297988, 11.4304248],
                [107.4298418, 11.430593],
                [107.4298941, 11.4307586],
                [107.4299556, 11.4309212],
                [107.4300261, 11.4310802],
                [107.4301053, 11.4312352],
                [107.430193, 11.4313857],
                [107.4302891, 11.4315313],
                [107.4303931, 11.4316715],
                [107.4305048, 11.4318059],
                [107.4306238, 11.4319341],
                [107.4307498, 11.4320558],
                [107.4310262, 11.432295],
                [107.4311459, 11.4324419],
                [107.4312382, 11.4326067],
                [107.4313003, 11.4327846],
                [107.4313304, 11.4329703],
                [107.4313331, 11.4330644],
                [107.4313256, 11.4331751],
                [107.4312856, 11.4333935],
                [107.4312131, 11.4336036],
                [107.4311097, 11.433801],
                [107.4309776, 11.4339811],
                [107.4308198, 11.4341401],
                [107.4307322, 11.4342106],
                [107.4303485, 11.4344635],
                [107.4300224, 11.4347105],
                [107.4298869, 11.434828],
                [107.4296342, 11.4350816],
                [107.4294079, 11.435358],
                [107.4292371, 11.4356103],
                [107.4291774, 11.4357212],
                [107.4291263, 11.4358363],
                [107.4290843, 11.4359549],
                [107.4290516, 11.4360762],
                [107.4290283, 11.4361996],
                [107.4290147, 11.4363243],
                [107.4290107, 11.4364498],
                [107.4290164, 11.4365751],
                [107.4290317, 11.4366997],
                [107.4290567, 11.4368228],
                [107.4290911, 11.4369437],
                [107.4291347, 11.4370617],
                [107.4291873, 11.4371761],
                [107.429236, 11.4372652],
                [107.4293545, 11.4374308],
                [107.4294235, 11.437506],
                [107.4296093, 11.437676],
                [107.4296555, 11.4377346],
                [107.4297265, 11.4378652],
                [107.4297661, 11.4380079],
                [107.4297725, 11.4381557],
                [107.4297452, 11.4383012],
                [107.4297134, 11.4383838],
                [107.4296733, 11.4385557],
                [107.4296653, 11.4387318],
                [107.4296897, 11.4389065],
                [107.4297138, 11.4389915],
                [107.429785, 11.4391535],
                [107.4298847, 11.4393002],
                [107.4300085, 11.4394486],
                [107.4301059, 11.4396149],
                [107.4301439, 11.4397033],
                [107.4302196, 11.439937],
                [107.4302975, 11.4400654],
                [107.4303473, 11.440122],
                [107.4304655, 11.4402162],
                [107.4305322, 11.4402526],
                [107.4306762, 11.4403011],
                [107.4308278, 11.4403156],
                [107.4309039, 11.4403097],
                [107.4310224, 11.4402825],
                [107.4311427, 11.4402642],
                [107.431264, 11.440255],
                [107.4313857, 11.4402549],
                [107.4315071, 11.440264],
                [107.4316274, 11.4402822],
                [107.4317459, 11.4403094],
                [107.4318619, 11.4403454],
                [107.4319748, 11.44039],
                [107.4320838, 11.4404429],
                [107.4321884, 11.4405039],
                [107.4323713, 11.4406395],
                [107.4326067, 11.4408566],
                [107.432904, 11.4411621],
                [107.4331784, 11.4414875],
                [107.4334883, 11.4418907],
                [107.4337053, 11.4421511],
                [107.4338332, 11.4422616],
                [107.4339813, 11.4423447],
                [107.4340609, 11.4423748],
                [107.4342275, 11.4424108],
                [107.4343127, 11.4424162],
                [107.4344827, 11.4424017],
                [107.4346461, 11.442354],
                [107.4347234, 11.4423182],
                [107.4349272, 11.4422026],
                [107.4350482, 11.442151],
                [107.4351725, 11.4421078],
                [107.4352996, 11.4420732],
                [107.4354289, 11.4420474],
                [107.4356914, 11.4420227],
                [107.4358233, 11.4420238],
                [107.4359548, 11.442034],
                [107.4360853, 11.4420531],
                [107.4362141, 11.4420811],
                [107.4364641, 11.4421632],
                [107.4365841, 11.4422168],
                [107.4367, 11.4422786],
                [107.4368113, 11.4423481],
                [107.4369173, 11.442425],
                [107.4371116, 11.4425997],
                [107.4371989, 11.4426966],
                [107.4372792, 11.4427992],
                [107.4373519, 11.4429071],
                [107.4375699, 11.4432888],
                [107.4378187, 11.443652],
                [107.4379541, 11.4438258],
                [107.438246, 11.4441568],
                [107.4385643, 11.4444634],
                [107.438741, 11.4446138],
                [107.4389112, 11.4447714],
                [107.4390746, 11.4449357],
                [107.4392308, 11.4451065],
                [107.4394745, 11.4454047],
                [107.4395765, 11.44552],
                [107.4396853, 11.4456291],
                [107.4398006, 11.4457316],
                [107.439922, 11.4458272],
                [107.440049, 11.4459154],
                [107.4401812, 11.4459961],
                [107.440318, 11.4460688],
                [107.4404591, 11.4461335],
                [107.4406038, 11.4461897],
                [107.4407518, 11.4462373],
                [107.4409023, 11.4462761],
                [107.441055, 11.4463061],
                [107.4412092, 11.446327],
                [107.4413644, 11.4463389],
                [107.4415201, 11.4463416],
                [107.4416757, 11.4463352],
                [107.4420607, 11.4463016],
                [107.442254, 11.4462966],
                [107.4426403, 11.4463099],
                [107.4430242, 11.4463544],
                [107.4432144, 11.4463883],
                [107.4435239, 11.4464607],
                [107.4438823, 11.4465679],
                [107.4443503, 11.4467391],
                [107.4445794, 11.4468369],
                [107.4448047, 11.4469425],
                [107.4452432, 11.4471771],
                [107.4454558, 11.4473057],
                [107.4458663, 11.4475848],
                [107.4460637, 11.4477349],
                [107.4464415, 11.4480553],
                [107.4466214, 11.4482251],
                [107.4469622, 11.4485831],
                [107.4472761, 11.448964],
                [107.44756, 11.4493635],
                [107.4478144, 11.4497816],
                [107.448038, 11.4502163],
                [107.4482297, 11.4506654],
                [107.4483884, 11.4511267],
                [107.4484552, 11.4513612],
                [107.4485631, 11.4518364],
                [107.4486365, 11.4523179],
                [107.4486494, 11.4526549],
                [107.4486494, 11.4529283],
                [107.4486027, 11.4545404],
                [107.4485956, 11.454912],
                [107.4485975, 11.4554076],
                [107.4486083, 11.4555311],
                [107.4486282, 11.4556535],
                [107.4486571, 11.4557742],
                [107.4487412, 11.4560079],
                [107.4488588, 11.4562272],
                [107.4489294, 11.45633],
                [107.4490075, 11.4564276],
                [107.449184, 11.4566049],
                [107.4493849, 11.4567553],
                [107.4494931, 11.4568195],
                [107.4496058, 11.4568758],
                [107.4499047, 11.4569953],
                [107.4499904, 11.4570425],
                [107.4500715, 11.457097],
                [107.4502174, 11.4572259],
                [107.4503377, 11.457378],
                [107.4503872, 11.4574613],
                [107.4504626, 11.457639],
                [107.4505363, 11.4578924],
                [107.450586, 11.4580149],
                [107.4507095, 11.4582489],
                [107.4507829, 11.4583593],
                [107.4509514, 11.4585646],
                [107.4510457, 11.4586586],
                [107.4512524, 11.4588271],
                [107.4514722, 11.4589631],
                [107.4518087, 11.459129],
                [107.4522681, 11.4593276],
                [107.4525026, 11.4594155],
                [107.45274, 11.4594958],
                [107.4532223, 11.4596328],
                [107.4539837, 11.4598001],
                [107.4542391, 11.4598682],
                [107.4549887, 11.4601091],
                [107.455729, 11.4603766],
                [107.4560952, 11.4605202],
                [107.4571908, 11.4609713],
                [107.4582962, 11.4613985],
                [107.4593556, 11.4617824],
                [107.4611301, 11.4623979],
                [107.4613882, 11.4624945],
                [107.4618965, 11.4627071],
                [107.4622367, 11.4628668],
                [107.4623226, 11.4629187],
                [107.4624032, 11.4629783],
                [107.4624777, 11.463045],
                [107.4625455, 11.4631183],
                [107.462606, 11.4631974],
                [107.4626587, 11.4632818],
                [107.4627031, 11.4633706],
                [107.4627389, 11.4634631],
                [107.4627656, 11.4635585],
                [107.4627831, 11.4636559],
                [107.4627913, 11.4637545],
                [107.4627816, 11.4639359],
                [107.4626987, 11.464342],
                [107.4626541, 11.4645018],
                [107.4625441, 11.4648151],
                [107.4623993, 11.4651331],
                [107.4623118, 11.4652945],
                [107.462151, 11.465624],
                [107.4618482, 11.4662915],
                [107.4615862, 11.4669303],
                [107.4614752, 11.4672347],
                [107.4613734, 11.4675422],
                [107.4612809, 11.4678526],
                [107.4611979, 11.4681655],
                [107.4611244, 11.4684806],
                [107.4610605, 11.4687978],
                [107.4610062, 11.4691166],
                [107.4609615, 11.469437],
                [107.4609266, 11.4697584],
                [107.4609014, 11.4700808],
                [107.4608815, 11.4705937],
                [107.4608864, 11.4707836],
                [107.4609007, 11.4709732],
                [107.4609244, 11.4711618],
                [107.4609574, 11.471349],
                [107.4609996, 11.4715345],
                [107.461051, 11.4717177],
                [107.4611114, 11.4718983],
                [107.4612262, 11.4721802],
                [107.4613774, 11.4724869],
                [107.4616066, 11.4728814],
                [107.4617331, 11.4730716],
                [107.4620085, 11.4734367],
                [107.4623084, 11.473775],
                [107.4624664, 11.4739328],
                [107.4628017, 11.4742289],
                [107.4631604, 11.4744972],
                [107.4634554, 11.4746862],
                [107.4636595, 11.4748336],
                [107.4638154, 11.4749697],
                [107.463985, 11.4751442],
                [107.4641889, 11.4753958],
                [107.4642806, 11.475529],
                [107.4643651, 11.4756666],
                [107.4645842, 11.4760839],
                [107.464667, 11.4762151],
                [107.4647574, 11.4763415],
                [107.4649596, 11.4765781],
                [107.4651882, 11.4767903],
                [107.4653114, 11.4768865],
                [107.4655193, 11.4770252],
                [107.4657653, 11.477161],
                [107.4659347, 11.4772416],
                [107.4661079, 11.4773138],
                [107.4662846, 11.4773774],
                [107.4664644, 11.4774323],
                [107.4666467, 11.4774783],
                [107.4668311, 11.4775153],
                [107.4670172, 11.4775433],
                [107.4672014, 11.4775619],
                [107.4673863, 11.4775715],
                [107.4675715, 11.4775723],
                [107.4679409, 11.477547],
                [107.468306, 11.4774864],
                [107.4686118, 11.477406],
                [107.4687347, 11.47736],
                [107.4688538, 11.4773053],
                [107.4689684, 11.4772421],
                [107.469078, 11.4771707],
                [107.4691818, 11.4770916],
                [107.4692795, 11.4770052],
                [107.4693703, 11.476912],
                [107.4694538, 11.4768124],
                [107.4695297, 11.4767071],
                [107.4695973, 11.4765965],
                [107.4696565, 11.4764814],
                [107.4698127, 11.4761132],
                [107.469948, 11.475853],
                [107.4700007, 11.4757762],
                [107.4700608, 11.4757048],
                [107.4701279, 11.4756395],
                [107.4702011, 11.475581],
                [107.4702799, 11.4755298],
                [107.4703634, 11.4754865],
                [107.4704508, 11.4754514],
                [107.4705414, 11.4754249],
                [107.4706341, 11.4754072],
                [107.4707282, 11.4753986],
                [107.4708227, 11.475399],
                [107.4709167, 11.4754086],
                [107.4710093, 11.4754271],
                [107.4710996, 11.4754545],
                [107.4711867, 11.4754904],
                [107.471348, 11.4755865],
                [107.4714206, 11.4756457],
                [107.471487, 11.4757117],
                [107.4715464, 11.4757836],
                [107.4715984, 11.475861],
                [107.4716428, 11.475944],
                [107.4717495, 11.4762047],
                [107.4718112, 11.4763818],
                [107.4718633, 11.4765619],
                [107.4719057, 11.4767444],
                [107.4719383, 11.4769289],
                [107.4719611, 11.4771147],
                [107.4719769, 11.4774379],
                [107.4719578, 11.4777101],
                [107.4719053, 11.4779781],
                [107.4718201, 11.4782379],
                [107.4717657, 11.4783635],
                [107.4716341, 11.478604],
                [107.4714739, 11.4788271],
                [107.4712902, 11.4790271],
                [107.4709919, 11.4792973],
                [107.470787, 11.4794706],
                [107.4705763, 11.4796373],
                [107.4701389, 11.4799499],
                [107.4699125, 11.4800954],
                [107.4694457, 11.4803643],
                [107.4688422, 11.4806703],
                [107.4684958, 11.4808831],
                [107.4681692, 11.4811241],
                [107.4680141, 11.4812546],
                [107.467729, 11.4815267],
                [107.4673386, 11.4819476],
                [107.4668341, 11.4825227],
                [107.4665902, 11.482817],
                [107.4661167, 11.4834226],
                [107.4641874, 11.4860997],
                [107.4634685, 11.4870512],
                [107.462947, 11.4877152],
                [107.4628001, 11.4879144],
                [107.4625283, 11.4883276],
                [107.4622491, 11.4888068],
                [107.4620652, 11.489054],
                [107.4618545, 11.4892797],
                [107.4617398, 11.4893835],
                [107.4616195, 11.489481],
                [107.4613631, 11.4896555],
                [107.4611184, 11.489787],
                [107.4606166, 11.4900261],
                [107.4601, 11.4902326],
                [107.4598368, 11.4903233],
                [107.4593021, 11.4904794],
                [107.4590311, 11.4905445],
                [107.4584834, 11.4906486],
                [107.4582072, 11.4906874],
                [107.4577495, 11.4907321],
                [107.4567236, 11.490785],
                [107.4566016, 11.4907986],
                [107.4564811, 11.4908213],
                [107.4563626, 11.490853],
                [107.456247, 11.4908933],
                [107.4561349, 11.4909422],
                [107.4560268, 11.4909993],
                [107.4558638, 11.4911073],
                [107.4556944, 11.4912484],
                [107.4555892, 11.4913506],
                [107.4554908, 11.4914592],
                [107.4553996, 11.4915736],
                [107.4553159, 11.4916935],
                [107.4552401, 11.4918183],
                [107.4551294, 11.4920422],
                [107.4550172, 11.4923331],
                [107.454952, 11.4925303],
                [107.4548953, 11.49273],
                [107.4548077, 11.4931356],
                [107.4547327, 11.4937177],
                [107.4547052, 11.4940099],
                [107.4546519, 11.4948001],
                [107.4545893, 11.4952948],
                [107.4544966, 11.495785],
                [107.454352, 11.4964211],
                [107.4542026, 11.497249],
                [107.4541444, 11.4975001],
                [107.4540018, 11.4979957],
                [107.4538251, 11.4984805],
                [107.4535502, 11.4991139],
                [107.4534068, 11.4995212],
                [107.4533469, 11.4997285],
                [107.4532688, 11.5000587],
                [107.4532413, 11.5003042],
                [107.4532473, 11.5005512],
                [107.4532921, 11.5008778],
                [107.4532926, 11.5009808],
                [107.4532837, 11.5010836],
                [107.4532655, 11.5011851],
                [107.4532381, 11.5012846],
                [107.4532017, 11.5013814],
                [107.4531566, 11.5014745],
                [107.4531032, 11.5015633],
                [107.4530418, 11.5016471],
                [107.4529731, 11.5017251],
                [107.4528692, 11.5018206],
                [107.452648, 11.5019964],
                [107.4524106, 11.5021507],
                [107.4522865, 11.5022193],
                [107.4521485, 11.5022869],
                [107.4518626, 11.5024006],
                [107.4514422, 11.5025893],
                [107.4510254, 11.5027855],
                [107.4502028, 11.5031998],
                [107.4494812, 11.5035943],
                [107.4488624, 11.5039696],
                [107.4485602, 11.5041681],
                [107.4482628, 11.5043736],
                [107.4476838, 11.5048055],
                [107.4473882, 11.5050433],
                [107.447099, 11.5052886],
                [107.4468165, 11.5055411],
                [107.4465406, 11.5058007],
                [107.4462718, 11.5060672],
                [107.4460101, 11.5063405],
                [107.4455532, 11.5068539],
                [107.4453342, 11.5071184],
                [107.4452527, 11.5072527],
                [107.4451634, 11.5073822],
                [107.4450665, 11.5075063],
                [107.4449625, 11.5076247],
                [107.4448515, 11.5077369],
                [107.4447341, 11.5078426],
                [107.4446106, 11.5079415],
                [107.4444815, 11.5080331],
                [107.4441295, 11.5082489],
                [107.4440288, 11.5083264],
                [107.4439343, 11.508411],
                [107.4438466, 11.5085023],
                [107.443766, 11.5085998],
                [107.4436931, 11.5087029],
                [107.443577, 11.5089125],
                [107.4435172, 11.5090093],
                [107.4434493, 11.5091009],
                [107.4433738, 11.5091865],
                [107.4432911, 11.5092655],
                [107.4432019, 11.5093374],
                [107.4431069, 11.5094018],
                [107.4430067, 11.509458],
                [107.4429019, 11.5095057],
                [107.4427935, 11.5095447],
                [107.4426821, 11.5095745],
                [107.4425685, 11.509595],
                [107.4424535, 11.5096061],
                [107.442338, 11.5096076],
                [107.4420655, 11.5095863],
                [107.4419867, 11.5095926],
                [107.4419091, 11.5096076],
                [107.4417615, 11.5096626],
                [107.4417211, 11.5096847],
                [107.4416073, 11.5097626],
                [107.441473, 11.509887],
                [107.4413624, 11.5100321],
                [107.4413171, 11.5101112],
                [107.4412789, 11.5101937],
                [107.441225, 11.510367],
                [107.4412111, 11.5104458],
                [107.4411893, 11.5106848],
                [107.4411847, 11.5110046],
                [107.441211, 11.5113233],
                [107.4412358, 11.5114814],
                [107.4413476, 11.5120149],
                [107.4413921, 11.5122837],
                [107.4414922, 11.5130862],
                [107.4416527, 11.5140382],
                [107.4416758, 11.5142515],
                [107.4416896, 11.5144655],
                [107.4416942, 11.5146799],
                [107.4416896, 11.5148943],
                [107.4416532, 11.5154129],
                [107.441671, 11.5158539],
                [107.4416431, 11.515987],
                [107.4415829, 11.5161094],
                [107.4415418, 11.5161643],
                [107.4414406, 11.5162572],
                [107.4413821, 11.5162939],
                [107.4412535, 11.5163448],
                [107.4411162, 11.5163635],
                [107.4410468, 11.5163604],
                [107.4409381, 11.5163381],
                [107.4408223, 11.5162925],
                [107.4407493, 11.5162533],
                [107.4406806, 11.5162074],
                [107.4405584, 11.5160969],
                [107.4405062, 11.5160335],
                [107.4404383, 11.5159258],
                [107.4403626, 11.5158231],
                [107.4402797, 11.515726],
                [107.4401899, 11.515635],
                [107.4399915, 11.5154729],
                [107.4396735, 11.5152741],
                [107.4395769, 11.5151978],
                [107.4394868, 11.5151141],
                [107.4394039, 11.5150236],
                [107.4393287, 11.5149269],
                [107.4392617, 11.5148245],
                [107.4391156, 11.5145677],
                [107.4389421, 11.5143279],
                [107.4387433, 11.5141077],
                [107.438635, 11.5140059],
                [107.4383394, 11.5137597],
                [107.438182, 11.513585],
                [107.4380526, 11.5133894],
                [107.4379993, 11.5132851],
                [107.437917, 11.5130664],
                [107.4378469, 11.5127791],
                [107.4378125, 11.5126961],
                [107.4377696, 11.5126171],
                [107.4377184, 11.5125429],
                [107.4376597, 11.5124743],
                [107.437594, 11.5124121],
                [107.4375221, 11.5123569],
                [107.4374447, 11.5123093],
                [107.4373627, 11.5122699],
                [107.4372007, 11.5122195],
                [107.4368687, 11.5121506],
                [107.4363676, 11.5120645],
                [107.4358617, 11.512012],
                [107.4353533, 11.5119932],
                [107.434942, 11.5120028],
                [107.4347858, 11.5120186],
                [107.434631, 11.512044],
                [107.4344781, 11.5120789],
                [107.4343277, 11.512123],
                [107.4341803, 11.5121762],
                [107.4338187, 11.5123367],
                [107.4337044, 11.5123729],
                [107.4335875, 11.5124001],
                [107.4334688, 11.5124183],
                [107.433349, 11.5124271],
                [107.4332289, 11.5124267],
                [107.4331092, 11.512417],
                [107.4329907, 11.512398],
                [107.432874, 11.51237],
                [107.4327035, 11.5123109],
                [107.4326443, 11.5122972],
                [107.4325837, 11.5122922],
                [107.4324635, 11.5123085],
                [107.4323534, 11.5123585],
                [107.4323052, 11.5123948],
                [107.4322277, 11.5124862],
                [107.4321806, 11.5125958],
                [107.4321678, 11.512701],
                [107.4321722, 11.5129123],
                [107.4321979, 11.5132279],
                [107.4322466, 11.5134933],
                [107.4323165, 11.5137052],
                [107.432363, 11.5138069],
                [107.4324777, 11.5139991],
                [107.4326178, 11.5141712],
                [107.4327416, 11.5143519],
                [107.4327924, 11.5144487],
                [107.4328701, 11.5146526],
                [107.4329144, 11.5148658],
                [107.4329243, 11.5150832],
                [107.4328995, 11.5152994],
                [107.4328407, 11.5155093],
                [107.4327989, 11.5156102],
                [107.4327026, 11.5157847],
                [107.4324336, 11.5161466],
                [107.4320923, 11.5165668],
                [107.4316066, 11.5171094],
                [107.4314647, 11.5172884],
                [107.4313306, 11.517473],
                [107.4311096, 11.5178177],
                [107.4308793, 11.5182361],
                [107.4308149, 11.5183316],
                [107.430743, 11.5184219],
                [107.430579, 11.5185849],
                [107.4303913, 11.518721],
                [107.4302899, 11.5187779],
                [107.4301843, 11.518827],
                [107.4299631, 11.5189004],
                [107.429733, 11.5189394],
                [107.4296163, 11.5189457],
                [107.4293832, 11.5189317],
                [107.4291551, 11.5188825],
                [107.4290483, 11.5188464],
                [107.428737, 11.5187152],
                [107.4283324, 11.5185185],
                [107.4279695, 11.5183095],
                [107.4274811, 11.5179933],
                [107.4271604, 11.5177756],
                [107.4265327, 11.5173212],
                [107.4260387, 11.5169352],
                [107.4254862, 11.5164749],
                [107.4251256, 11.5161594],
                [107.4247714, 11.515837],
                [107.4244237, 11.5155078],
                [107.4241991, 11.5152882],
                [107.4239811, 11.5150622],
                [107.4235658, 11.5145921],
                [107.4233688, 11.5143484],
                [107.4230417, 11.5139086],
                [107.4223829, 11.5129371],
                [107.4219995, 11.5123409],
                [107.4215593, 11.5116908],
                [107.4214159, 11.5114215],
                [107.4213316, 11.5112208],
                [107.4212613, 11.5111107],
                [107.421216, 11.5110632],
                [107.4211085, 11.5109873],
                [107.4210158, 11.5109491],
                [107.4209197, 11.5109201],
                [107.4208211, 11.5109003],
                [107.4207211, 11.5108901],
                [107.4206206, 11.5108896],
                [107.4205204, 11.5108986],
                [107.4204217, 11.5109172],
                [107.4203253, 11.5109452],
                [107.4202498, 11.5109744],
                [107.4200327, 11.5110816],
                [107.4197577, 11.5112496],
                [107.4196279, 11.5113447],
                [107.419449, 11.5114959],
                [107.4193645, 11.5115768],
                [107.4188226, 11.5120237],
                [107.4185443, 11.5122383],
                [107.4178404, 11.5127602],
                [107.4172016, 11.5132548],
                [107.4166341, 11.5137286],
                [107.4163581, 11.5139741],
                [107.4159195, 11.5143864],
                [107.4157452, 11.5145408],
                [107.4155646, 11.5146882],
                [107.4153782, 11.5148284],
                [107.414821, 11.5152036],
                [107.4146462, 11.5153354],
                [107.4143135, 11.5156192],
                [107.4140315, 11.5158998],
                [107.4136728, 11.516301],
                [107.4132158, 11.5168533],
                [107.4128428, 11.5173474],
                [107.4125528, 11.5177806],
                [107.4124177, 11.5180032],
                [107.4122894, 11.5182297],
                [107.4120533, 11.5186932],
                [107.4118936, 11.5190523],
                [107.4115961, 11.5197794],
                [107.4113284, 11.5205175],
                [107.4112058, 11.5208904],
                [107.4109837, 11.52164],
                [107.4108835, 11.5220178],
                [107.4107048, 11.5227784],
                [107.4104918, 11.5238327],
                [107.4104108, 11.5241656],
                [107.4102555, 11.5247155],
                [107.4101998, 11.5249374],
                [107.4101537, 11.5251613],
                [107.4101173, 11.525387],
                [107.4100907, 11.5256139],
                [107.4100518, 11.5262169],
                [107.4100271, 11.5264032],
                [107.409972, 11.5266851],
                [107.4099591, 11.5267835],
                [107.4099591, 11.5269816],
                [107.409972, 11.52708],
                [107.4100324, 11.5273331],
                [107.4100397, 11.5274133],
                [107.4100296, 11.5275739],
                [107.4099869, 11.5277292],
                [107.4099538, 11.5278029],
                [107.4098659, 11.5279388],
                [107.4098119, 11.5279995],
                [107.409752, 11.5280547],
                [107.409653, 11.5281252],
                [107.4094428, 11.5282476],
                [107.4091159, 11.5284101],
                [107.4087744, 11.5285407],
                [107.4085992, 11.5285936],
                [107.4082922, 11.5286649],
                [107.4079009, 11.5287303],
                [107.4073762, 11.5287977],
                [107.4063384, 11.52888],
                [107.4059525, 11.5289227],
                [107.4052844, 11.5290186],
                [107.4046853, 11.5290887],
                [107.4041811, 11.5291572],
                [107.4037533, 11.5292343],
                [107.4031538, 11.5293456],
                [107.4022043, 11.529487],
                [107.402098, 11.529496],
                [107.4019912, 11.5294958],
                [107.4018848, 11.5294863],
                [107.4017798, 11.5294675],
                [107.4015972, 11.5294112],
                [107.4015099, 11.5293725],
                [107.4014261, 11.5293271],
                [107.4009489, 11.5290202],
                [107.400646, 11.5288021],
                [107.4003606, 11.5285625],
                [107.4002211, 11.5284568],
                [107.3999578, 11.5282273],
                [107.399836, 11.5281054],
                [107.3995884, 11.5278356],
                [107.3994869, 11.5277168],
                [107.3994016, 11.5275952],
                [107.3993086, 11.527479],
                [107.3992083, 11.5273688],
                [107.3991012, 11.527265],
                [107.3989876, 11.527168],
                [107.398868, 11.5270782],
                [107.3987428, 11.526996],
                [107.3986127, 11.5269216],
                [107.398478, 11.5268554],
                [107.3983482, 11.526801],
                [107.3980798, 11.5267151],
                [107.3978029, 11.5266608],
                [107.3975215, 11.5266389],
                [107.3968229, 11.5266532],
                [107.3959905, 11.5266862],
                [107.3955751, 11.5267154],
                [107.3947315, 11.526801],
                [107.3943113, 11.5268569],
                [107.3936657, 11.5269588],
                [107.3934376, 11.5269868],
                [107.3932085, 11.5270056],
                [107.3929789, 11.527015],
                [107.392749, 11.527015],
                [107.3923138, 11.5269894],
                [107.391882, 11.5269304],
                [107.3914563, 11.5268382],
                [107.3910393, 11.5267135],
                [107.3907239, 11.5266115],
                [107.3904057, 11.5265184],
                [107.3900848, 11.5264344],
                [107.3897616, 11.5263596],
                [107.3894467, 11.5262959],
                [107.3888121, 11.5261947],
                [107.3885035, 11.5261729],
                [107.3877633, 11.5261624],
                [107.3875196, 11.5261493],
                [107.3870635, 11.5261086],
                [107.386842, 11.5260509],
                [107.3867503, 11.5260131],
                [107.3866627, 11.5259672],
                [107.3865798, 11.5259134],
                [107.3865023, 11.5258523],
                [107.386431, 11.5257845],
                [107.3863664, 11.5257104],
                [107.3862633, 11.5255532],
                [107.3862229, 11.5254685],
                [107.386166, 11.5252902],
                [107.3861499, 11.525198],
                [107.3861405, 11.5249745],
                [107.3861487, 11.5245598],
                [107.3861898, 11.52411],
                [107.3862629, 11.5236796],
                [107.3864031, 11.523108],
                [107.3864296, 11.5229587],
                [107.3864578, 11.522657],
                [107.3864527, 11.5223541],
                [107.3864221, 11.5220974],
                [107.3863675, 11.5218445],
                [107.3862889, 11.5216827],
                [107.3862019, 11.5215251],
                [107.3861068, 11.5213721],
                [107.3860038, 11.5212241],
                [107.3858933, 11.5210814],
                [107.3857754, 11.5209444],
                [107.3856506, 11.5208135],
                [107.3855191, 11.520689],
                [107.3853812, 11.5205713],
                [107.3851078, 11.5203704],
                [107.3849641, 11.5202798],
                [107.3846641, 11.5201192],
                [107.3843499, 11.5199875],
                [107.3841884, 11.5199328],
                [107.3838582, 11.5198464],
                [107.383161, 11.519759],
                [107.3824603, 11.5197057],
                [107.3818075, 11.5196869],
                [107.381153, 11.5197132],
                [107.3809762, 11.5197139],
                [107.3806232, 11.5196919],
                [107.3804362, 11.5196674],
                [107.3802507, 11.5196342],
                [107.380067, 11.5195921],
                [107.3797068, 11.5194824],
                [107.379274, 11.5193152],
                [107.3788535, 11.5191201],
                [107.3786271, 11.5190007],
                [107.3784053, 11.5188732],
                [107.3781885, 11.5187377],
                [107.377977, 11.5185946],
                [107.3777709, 11.5184438],
                [107.3775707, 11.5182858],
                [107.3773765, 11.5181206],
                [107.3771887, 11.5179485],
                [107.3769753, 11.5177277],
                [107.3766859, 11.5173695],
                [107.3764434, 11.5171025],
                [107.3759454, 11.5165801],
                [107.3749681, 11.5156189],
                [107.3747476, 11.5154329],
                [107.3745079, 11.5152712],
                [107.3742394, 11.5151301],
                [107.3740931, 11.5150684],
                [107.3739433, 11.5150154],
                [107.3737906, 11.514971],
                [107.3736355, 11.5149356],
                [107.3734785, 11.5149093],
                [107.3731458, 11.5148756],
                [107.3729707, 11.5148672],
                [107.3727955, 11.5148668],
                [107.3724458, 11.5148902],
                [107.3721, 11.5149455],
                [107.3717609, 11.5150323],
                [107.3715949, 11.5150873],
                [107.3713994, 11.5151633],
                [107.3710174, 11.5153359],
                [107.3708314, 11.5154323],
                [107.3704709, 11.5156445],
                [107.3698644, 11.5160629],
                [107.369641, 11.5162039],
                [107.3694131, 11.5163378],
                [107.3689043, 11.5166161],
                [107.3687728, 11.5167028],
                [107.3685253, 11.5168968],
                [107.3684102, 11.5170035],
                [107.3682377, 11.5171881],
                [107.368093, 11.5173141],
                [107.3679282, 11.5174138],
                [107.3676996, 11.517511],
                [107.3675764, 11.5175986],
                [107.3674762, 11.517711],
                [107.3674364, 11.5177748],
                [107.3673801, 11.5179134],
                [107.3673568, 11.5180608],
                [107.3673677, 11.5182096],
                [107.3673914, 11.5182988],
                [107.3674636, 11.5184687],
                [107.3675115, 11.518548],
                [107.3676288, 11.5186918],
                [107.3677713, 11.5188117],
                [107.3678505, 11.5188614],
                [107.3680056, 11.5189326],
                [107.3685703, 11.5191265],
                [107.3693194, 11.5193335],
                [107.3695673, 11.5194136],
                [107.3700542, 11.5195981],
                [107.3702926, 11.5197023],
                [107.370758, 11.5199337],
                [107.3710234, 11.5200836],
                [107.3715429, 11.5204013],
                [107.3720467, 11.5207427],
                [107.372523, 11.5210985],
                [107.3729678, 11.5214716],
                [107.3733891, 11.52187],
                [107.3738874, 11.5223885],
                [107.3742101, 11.522673],
                [107.3743803, 11.522805],
                [107.3747009, 11.5230247],
                [107.3755358, 11.5235186],
                [107.3756996, 11.5236443],
                [107.3758403, 11.5237945],
                [107.3759542, 11.5239651],
                [107.3760383, 11.5241516],
                [107.3760903, 11.524349],
                [107.3761022, 11.5244338],
                [107.3761164, 11.52469],
                [107.3761151, 11.5248611],
                [107.3761043, 11.5250318],
                [107.376084, 11.5252017],
                [107.3760543, 11.5253702],
                [107.3760152, 11.5255369],
                [107.3759659, 11.5257042],
                [107.3757928, 11.5261979],
                [107.3755692, 11.5267885],
                [107.3753881, 11.5273237],
                [107.3752399, 11.5278686],
                [107.3751783, 11.528144],
                [107.3751167, 11.5284695],
                [107.3750644, 11.5287966],
                [107.3750213, 11.5291249],
                [107.3749875, 11.5294543],
                [107.3749631, 11.5297845],
                [107.374948, 11.5301152],
                [107.3749442, 11.5306966],
                [107.3749552, 11.5309467],
                [107.3750046, 11.5314449],
                [107.3750902, 11.5319384],
                [107.3752117, 11.5324247],
                [107.3752856, 11.5326643],
                [107.3753714, 11.5329097],
                [107.3755647, 11.5333925],
                [107.3757865, 11.5338635],
                [107.3762509, 11.53471],
                [107.3765658, 11.5353402],
                [107.3767125, 11.5356603],
                [107.3771183, 11.5365961],
                [107.3778, 11.5380548],
                [107.3780184, 11.538602],
                [107.3782039, 11.5391608],
                [107.3783082, 11.5395361],
                [107.3784906, 11.5402932],
                [107.3786461, 11.5410509],
                [107.3786952, 11.5412368],
                [107.3788416, 11.5417148],
                [107.3788741, 11.541865],
                [107.378913, 11.5421697],
                [107.3789166, 11.5424766],
                [107.3789052, 11.5426298],
                [107.3788561, 11.542933],
                [107.3788185, 11.5430821],
                [107.3787323, 11.5433375],
                [107.3784946, 11.5438659],
                [107.3783423, 11.5441785],
                [107.3781811, 11.5444869],
                [107.3780112, 11.5447907],
                [107.3778328, 11.5450897],
                [107.377646, 11.5453838],
                [107.377421, 11.5457154],
                [107.3769542, 11.5463672],
                [107.376329, 11.5471914],
                [107.3759693, 11.547712],
                [107.3756343, 11.5482483],
                [107.3754721, 11.5485292],
                [107.3750067, 11.5493835],
                [107.3748124, 11.5497655],
                [107.3747264, 11.5499615],
                [107.374577, 11.5503623],
                [107.3744586, 11.550773],
                [107.3743738, 11.5511797],
                [107.3742744, 11.5515712],
                [107.3742127, 11.5517637],
                [107.3739894, 11.552349],
                [107.3739268, 11.5525515],
                [107.3738737, 11.5527566],
                [107.3738303, 11.5529638],
                [107.3737966, 11.5531729],
                [107.3737729, 11.5533832],
                [107.3737553, 11.5537368],
                [107.3737577, 11.5540383],
                [107.3737381, 11.5546151],
                [107.3736766, 11.5553019],
                [107.3736244, 11.5555738],
                [107.3735381, 11.5558382],
                [107.3734827, 11.555966],
                [107.3733482, 11.5562103],
                [107.3732697, 11.5563259],
                [107.3730916, 11.5565418],
                [107.3729498, 11.5567354],
                [107.3728874, 11.5568377],
                [107.3727804, 11.5570516],
                [107.3727283, 11.5571409],
                [107.3726687, 11.5572255],
                [107.3725285, 11.5573782],
                [107.3723639, 11.5575054],
                [107.3722739, 11.5575583],
                [107.3716323, 11.5578491],
                [107.3712153, 11.558019],
                [107.3708843, 11.5581352],
                [107.3704974, 11.558243],
                [107.3701036, 11.5583235],
                [107.369846, 11.5584004],
                [107.3696001, 11.558508],
                [107.3694828, 11.5585727],
                [107.3692614, 11.5587229],
                [107.36919, 11.5587592],
                [107.3691147, 11.558787],
                [107.3690367, 11.558806],
                [107.3689569, 11.5588158],
                [107.3688765, 11.5588163],
                [107.3687965, 11.5588075],
                [107.3687182, 11.5587896],
                [107.3686426, 11.5587628],
                [107.3685707, 11.5587274],
                [107.3685036, 11.558684],
                [107.3684422, 11.5586331],
                [107.3683873, 11.5585755],
                [107.3683397, 11.558512],
                [107.3683001, 11.5584435],
                [107.3682148, 11.5582287],
                [107.3681681, 11.5580841],
                [107.3680981, 11.5577887],
                [107.368075, 11.5576388],
                [107.3680533, 11.5573367],
                [107.3680666, 11.5570345],
                [107.3680863, 11.5568844],
                [107.3681514, 11.5565887],
                [107.3682502, 11.5563022],
                [107.3683941, 11.5559992],
                [107.3684845, 11.5558392],
                [107.3685828, 11.5556838],
                [107.368689, 11.5555334],
                [107.3688026, 11.5553883],
                [107.3689234, 11.5552489],
                [107.3692765, 11.5548679],
                [107.3696482, 11.5545042],
                [107.3702839, 11.5539173],
                [107.3711692, 11.5530763],
                [107.3713267, 11.5529439],
                [107.3714776, 11.5528043],
                [107.3716217, 11.5526579],
                [107.37187, 11.552369],
                [107.3720915, 11.5520601],
                [107.3721917, 11.5518987],
                [107.3722846, 11.5517333],
                [107.3724103, 11.5514782],
                [107.3725035, 11.5512454],
                [107.3725513, 11.5510969],
                [107.3725911, 11.5509462],
                [107.3726363, 11.5507169],
                [107.3726553, 11.5505161],
                [107.3726472, 11.5502688],
                [107.3726047, 11.5500248],
                [107.3725284, 11.5497888],
                [107.3724198, 11.5495654],
                [107.372354, 11.5494596],
                [107.3722011, 11.5492626],
                [107.3720549, 11.5491279],
                [107.3718887, 11.5490179],
                [107.3717065, 11.5489355],
                [107.3713725, 11.5488353],
                [107.3711319, 11.5487725],
                [107.3707681, 11.5486898],
                [107.3704916, 11.5486511],
                [107.370337, 11.5486431],
                [107.3701821, 11.5486447],
                [107.3700277, 11.5486559],
                [107.3698743, 11.5486767],
                [107.3697226, 11.548707],
                [107.3695732, 11.5487467],
                [107.3694266, 11.5487956],
                [107.3692834, 11.5488535],
                [107.3691444, 11.5489201],
                [107.3689441, 11.5490355],
                [107.3687852, 11.5491525],
                [107.3686959, 11.5492331],
                [107.3686131, 11.5493202],
                [107.3685373, 11.5494132],
                [107.3684689, 11.5495116],
                [107.3682552, 11.5498661],
                [107.3680959, 11.5501138],
                [107.3677593, 11.5505976],
                [107.3672793, 11.551233],
                [107.3670298, 11.5515436],
                [107.3667095, 11.5518995],
                [107.3663677, 11.5522356],
                [107.3661891, 11.5523958],
                [107.3658174, 11.5526999],
                [107.3654479, 11.5529618],
                [107.3650603, 11.5531973],
                [107.3646567, 11.5534054],
                [107.3640908, 11.5536533],
                [107.3633638, 11.5539398],
                [107.3629957, 11.5540717],
                [107.3624799, 11.5542319],
                [107.362219, 11.5543023],
                [107.3616922, 11.5544238],
                [107.3614822, 11.55448],
                [107.361275, 11.554545],
                [107.3610707, 11.5546188],
                [107.3608023, 11.5547269],
                [107.3606729, 11.5547917],
                [107.360589, 11.5548463],
                [107.3605107, 11.5549084],
                [107.3604385, 11.5549773],
                [107.3603733, 11.5550525],
                [107.3603154, 11.5551333],
                [107.3602655, 11.555219],
                [107.3602239, 11.5553089],
                [107.3601911, 11.5554022],
                [107.3601672, 11.5554982],
                [107.3601526, 11.5555958],
                [107.3601573, 11.5557471],
                [107.3601713, 11.5558977],
                [107.3601945, 11.5560473],
                [107.360227, 11.5561953],
                [107.3602685, 11.556341],
                [107.3603189, 11.556484],
                [107.3603753, 11.5566179],
                [107.3605115, 11.5568749],
                [107.360677, 11.5571148],
                [107.3608697, 11.5573344],
                [107.3609755, 11.5574356],
                [107.361204, 11.5576192],
                [107.3615839, 11.5578808],
                [107.3619811, 11.5581161],
                [107.3624199, 11.5583369],
                [107.3626581, 11.5584417],
                [107.3629001, 11.5585382],
                [107.3631454, 11.558626],
                [107.3639076, 11.5588578],
                [107.3644243, 11.559047],
                [107.3649277, 11.5592681],
                [107.3654737, 11.559546],
                [107.3659708, 11.559811],
                [107.3660642, 11.5598739],
                [107.3662257, 11.5600108],
                [107.3663653, 11.5601691],
                [107.366426, 11.5602553],
                [107.3665274, 11.5604394],
                [107.3665677, 11.5605364],
                [107.3666006, 11.560636],
                [107.3666263, 11.560796],
                [107.3666205, 11.560958],
                [107.3666057, 11.5610378],
                [107.3665532, 11.5611915],
                [107.3664719, 11.5613325],
                [107.3663646, 11.5614558],
                [107.3662396, 11.5615765],
                [107.3661083, 11.5616905],
                [107.3656551, 11.5620257],
                [107.3654864, 11.5621593],
                [107.3651623, 11.5624422],
                [107.3633041, 11.5642105],
                [107.3629471, 11.5645857],
                [107.3625769, 11.5650154],
                [107.3624004, 11.5652372],
                [107.3620652, 11.5656938],
                [107.3619068, 11.5659283],
                [107.3616664, 11.5663259],
                [107.3615558, 11.56653],
                [107.3613542, 11.5669479],
                [107.3612288, 11.5672828],
                [107.3611778, 11.5674541],
                [107.3610996, 11.5678026],
                [107.3610726, 11.5679791],
                [107.3610538, 11.5681566],
                [107.3610431, 11.5683486],
                [107.3610422, 11.568541],
                [107.3610513, 11.5687331],
                [107.3610704, 11.5689245],
                [107.3610992, 11.5691147],
                [107.3611378, 11.5693033],
                [107.3611862, 11.5694897],
                [107.3612763, 11.5697643],
                [107.3613795, 11.5700098],
                [107.3614559, 11.5701617],
                [107.3616324, 11.5704529],
                [107.361732, 11.5705913],
                [107.3619526, 11.570852],
                [107.3621977, 11.5710871],
                [107.3624637, 11.571295],
                [107.3626044, 11.5713888],
                [107.3628995, 11.5715548],
                [107.3632102, 11.5716908],
                [107.3633704, 11.571747],
                [107.3636985, 11.5718354],
                [107.3639642, 11.5718918],
                [107.3642318, 11.5719387],
                [107.364501, 11.5719759],
                [107.3647714, 11.5720036],
                [107.3651824, 11.5720294],
                [107.3652855, 11.5720276],
                [107.3654847, 11.5720507],
                [107.3655793, 11.5720744],
                [107.3657605, 11.5721455],
                [107.3659265, 11.5722463],
                [107.3660021, 11.5723069],
                [107.3660721, 11.5723737],
                [107.3661929, 11.5725239],
                [107.3662754, 11.572681],
                [107.3663045, 11.5727647],
                [107.3663253, 11.5728508],
                [107.3663408, 11.5730269],
                [107.3663213, 11.5732025],
                [107.3662676, 11.5733712],
                [107.3661817, 11.5735266],
                [107.3660668, 11.5736628],
                [107.3658432, 11.573852],
                [107.3656809, 11.573975],
                [107.3655132, 11.5740908],
                [107.3649377, 11.5744376],
                [107.3647175, 11.5745827],
                [107.3645027, 11.5747353],
                [107.36419, 11.5749766],
                [107.3639713, 11.5751741],
                [107.3637522, 11.5754152],
                [107.3636525, 11.5755437],
                [107.3634743, 11.5758152],
                [107.3633257, 11.5761034],
                [107.3632085, 11.5764051],
                [107.3631158, 11.5767388],
                [107.3630456, 11.5771013],
                [107.3630055, 11.5774682],
                [107.3629968, 11.5776525],
                [107.3629896, 11.5786057],
                [107.362961, 11.5791078],
                [107.3628968, 11.5795973],
                [107.3628277, 11.5799612],
                [107.3627544, 11.5802156],
                [107.3626434, 11.5804757],
                [107.3625757, 11.5806002],
                [107.3624172, 11.5808355],
                [107.3622301, 11.5810497],
                [107.3620021, 11.5812551],
                [107.361737, 11.5814502],
                [107.361597, 11.5815375],
                [107.3613044, 11.5816904],
                [107.3609972, 11.581813],
                [107.3608392, 11.5818625],
                [107.360677, 11.5819036],
                [107.3605127, 11.5819356],
                [107.3603467, 11.5819583],
                [107.3601797, 11.5819718],
                [107.3600122, 11.5819759],
                [107.3598447, 11.5819707],
                [107.3596778, 11.5819562],
                [107.3595121, 11.5819323],
                [107.3593479, 11.5818993],
                [107.3592246, 11.5818636],
                [107.3591042, 11.581819],
                [107.3589876, 11.581766],
                [107.3588752, 11.5817046],
                [107.3587677, 11.5816354],
                [107.3586657, 11.5815585],
                [107.3585698, 11.5814746],
                [107.3583713, 11.5812582],
                [107.3581313, 11.5809479],
                [107.3580464, 11.5808216],
                [107.3579003, 11.5805556],
                [107.3577874, 11.5802749],
                [107.3577091, 11.5799832],
                [107.3576833, 11.5798344],
                [107.3576666, 11.5796844],
                [107.3576588, 11.5795336],
                [107.3576602, 11.5793827],
                [107.3576708, 11.5792322],
                [107.3579371, 11.5770119],
                [107.357958, 11.5767366],
                [107.3579697, 11.5764607],
                [107.3579724, 11.5761846],
                [107.3579659, 11.5759086],
                [107.3579256, 11.5753578],
                [107.3578919, 11.5750837],
                [107.3578491, 11.5748108],
                [107.3577366, 11.5742698],
                [107.357667, 11.5740022],
                [107.3575015, 11.5734744],
                [107.3574449, 11.573296],
                [107.3572297, 11.5727041],
                [107.3569822, 11.5721245],
                [107.3568153, 11.571686],
                [107.3564988, 11.5708031],
                [107.3562016, 11.5699],
                [107.356063, 11.5694387],
                [107.3559333, 11.5689749],
                [107.3557611, 11.5682964],
                [107.3556818, 11.567867],
                [107.3556352, 11.5674329],
                [107.3556242, 11.5672149],
                [107.3556269, 11.5667785],
                [107.3556626, 11.5663435],
                [107.3557593, 11.5657354],
                [107.3558846, 11.5651324],
                [107.3560381, 11.5645356],
                [107.3561851, 11.5640597],
                [107.3563606, 11.5635932],
                [107.3564587, 11.5633639],
                [107.3567041, 11.5628364],
                [107.3568153, 11.5625236],
                [107.3568964, 11.5622021],
                [107.3569349, 11.5619729],
                [107.356964, 11.5617423],
                [107.3569836, 11.5615108],
                [107.3569938, 11.5612787],
                [107.3569944, 11.5610464],
                [107.3569856, 11.5608143],
                [107.3569673, 11.5605827],
                [107.3569395, 11.5603519],
                [107.3569022, 11.5601225],
                [107.3568557, 11.5598947],
                [107.3567998, 11.5596689],
                [107.3567369, 11.5594521],
                [107.3565869, 11.559026],
                [107.3564056, 11.5586117],
                [107.3561939, 11.5582114],
                [107.3559529, 11.5578274],
                [107.3558219, 11.5576421],
                [107.3556008, 11.557359],
                [107.3554544, 11.5571398],
                [107.3553363, 11.5569048],
                [107.3551973, 11.5565296],
                [107.3550754, 11.5562944],
                [107.3549232, 11.5560767],
                [107.3547434, 11.5558802],
                [107.3545391, 11.5557084],
                [107.3544288, 11.5556326],
                [107.3542973, 11.5555551],
                [107.3541611, 11.5554858],
                [107.3540208, 11.5554251],
                [107.3538768, 11.5553731],
                [107.3537298, 11.5553301],
                [107.3535803, 11.5552962],
                [107.353429, 11.5552716],
                [107.3532763, 11.5552564],
                [107.353123, 11.5552505],
                [107.3529696, 11.5552542],
                [107.3528631, 11.5552623],
                [107.3526517, 11.5552922],
                [107.3523327, 11.5553206],
                [107.352013, 11.5553395],
                [107.3514928, 11.5553497],
                [107.3512928, 11.5553424],
                [107.3508947, 11.5553031],
                [107.3506973, 11.5552711],
                [107.3505014, 11.5552311],
                [107.3501157, 11.5551269],
                [107.3497403, 11.5549914],
                [107.3493762, 11.5548242],
                [107.3490286, 11.5546262],
                [107.3487002, 11.554399],
                [107.3483935, 11.5541443],
                [107.348249, 11.5540072],
                [107.3479793, 11.553715],
                [107.347737, 11.5534007],
                [107.3475553, 11.5531203],
                [107.3473559, 11.5527651],
                [107.3472317, 11.5525237],
                [107.3470063, 11.5520303],
                [107.3468123, 11.5515243],
                [107.3466504, 11.5510076],
                [107.3465667, 11.5506842],
                [107.3464218, 11.5500323],
                [107.3463248, 11.5494892],
                [107.3462776, 11.5490562],
                [107.3462664, 11.5488387],
                [107.3462685, 11.5484032],
                [107.346282, 11.5481859],
                [107.3463334, 11.5477533],
                [107.3463714, 11.5475387],
                [107.3464951, 11.5469536],
                [107.3465912, 11.5463636],
                [107.3466289, 11.5460671],
                [107.3466746, 11.5455552],
                [107.346688, 11.5450416],
                [107.3466825, 11.5447847],
                [107.3466777, 11.5446743],
                [107.3466424, 11.5444562],
                [107.3466121, 11.5443498],
                [107.3465272, 11.5441452],
                [107.346473, 11.5440483],
                [107.3463428, 11.5438681],
                [107.3461861, 11.5437093],
                [107.346099, 11.5436392],
                [107.3459284, 11.5435297],
                [107.3456837, 11.5434093],
                [107.3455159, 11.5433389],
                [107.3453445, 11.5432775],
                [107.34517, 11.543225],
                [107.3449929, 11.5431818],
                [107.3448137, 11.5431478],
                [107.3446329, 11.5431233],
                [107.344451, 11.5431083],
                [107.3441203, 11.5431053],
                [107.3429382, 11.5431974],
                [107.3417151, 11.5433235],
                [107.3413452, 11.5433685],
                [107.3409765, 11.5434226],
                [107.3406093, 11.5434857],
                [107.3402439, 11.5435578],
                [107.3396928, 11.5436845],
                [107.3395076, 11.5437391],
                [107.3393255, 11.5438028],
                [107.3391468, 11.5438752],
                [107.338972, 11.5439563],
                [107.3388016, 11.5440458],
                [107.3386359, 11.5441434],
                [107.338265, 11.5444006],
                [107.3379163, 11.5446859],
                [107.3375919, 11.5449975],
                [107.3372938, 11.5453335],
                [107.337058, 11.5456432],
                [107.3367824, 11.546054],
                [107.3366085, 11.5463338],
                [107.3364431, 11.5466186],
                [107.3360586, 11.5473419],
                [107.3358082, 11.5477637],
                [107.3356748, 11.5479697],
                [107.3350934, 11.5488157],
                [107.3348962, 11.5491538],
                [107.3347283, 11.5495068],
                [107.3345911, 11.5498723],
                [107.3344939, 11.5502127],
                [107.3344552, 11.5503853],
                [107.334398, 11.5507343],
                [107.3343053, 11.5511075],
                [107.3341835, 11.5514726],
                [107.3341118, 11.5516515],
                [107.3339886, 11.55192],
                [107.3339201, 11.5521132],
                [107.3338808, 11.5523141],
                [107.3338624, 11.5526591],
                [107.3338434, 11.5527793],
                [107.3338153, 11.5528978],
                [107.3337781, 11.5530139],
                [107.3337321, 11.5531269],
                [107.3336776, 11.5532362],
                [107.3336148, 11.5533411],
                [107.3335439, 11.5534415],
                [107.3334654, 11.5535364],
                [107.3333798, 11.5536251],
                [107.3332876, 11.5537073],
                [107.3331893, 11.5537824],
                [107.3330855, 11.55385],
                [107.3329768, 11.5539098],
                [107.3328638, 11.5539613],
                [107.3325072, 11.5540985],
                [107.3321392, 11.554203],
                [107.3319519, 11.5542427],
                [107.331453, 11.5543239],
                [107.3310455, 11.5543802],
                [107.3301966, 11.5544687],
                [107.3297681, 11.554505],
                [107.3289092, 11.5545508],
                [107.3284792, 11.5545605],
                [107.3280491, 11.5545612],
                [107.3276191, 11.554553],
                [107.3268469, 11.5545159],
                [107.3260766, 11.5544502],
                [107.3247516, 11.5542907],
                [107.3241928, 11.554234],
                [107.3238794, 11.5542144],
                [107.3231649, 11.5541505],
                [107.3228027, 11.5541269],
                [107.3220774, 11.5540975],
                [107.3220672, 11.5539316],
                [107.3220289, 11.5536014],
                [107.3219657, 11.5532701],
                [107.3218769, 11.5529396],
                [107.3217639, 11.5526164],
                [107.3210997, 11.551383],
                [107.3204061, 11.5501652],
                [107.3200313, 11.5495339],
                [107.3196485, 11.5489071],
                [107.3192579, 11.5482851],
                [107.3187172, 11.547453],
                [107.318582, 11.5472338],
                [107.3183335, 11.5467832],
                [107.3181152, 11.5463179],
                [107.3180109, 11.5460627],
                [107.3179155, 11.5458042],
                [107.3178294, 11.5455425],
                [107.3177526, 11.5452781],
                [107.3176852, 11.5450113],
                [107.3176273, 11.5447423],
                [107.3172682, 11.5422998],
                [107.1913878, 11.514761],
                [107.1770885, 11.5116326],
                [107.1308971, 11.5015265],
                [107.1308969, 11.5013695],
                [107.1309227, 11.5010567],
                [107.1309832, 11.5007486],
                [107.1310261, 11.5005974],
                [107.1311304, 11.5003179],
                [107.1312556, 11.5000648],
                [107.1313275, 11.4999431],
                [107.1313199, 11.4998301],
                [107.1312873, 11.4997672],
                [107.1312368, 11.4997171],
                [107.1310435, 11.4996275],
                [107.1308422, 11.4994917],
                [107.1305609, 11.4992229],
                [107.1294424, 11.4980434],
                [107.1292959, 11.4978863],
                [107.1292359, 11.4977773],
                [107.1291795, 11.4975749],
                [107.1291841, 11.4974486],
                [107.1292037, 11.4973463],
                [107.1293338, 11.4968926],
                [107.1293324, 11.496768],
                [107.1293002, 11.4966261],
                [107.1291939, 11.4965424],
                [107.1290673, 11.4964846],
                [107.1287432, 11.4964433],
                [107.1285229, 11.4963959],
                [107.1283064, 11.4963236],
                [107.1280968, 11.4962261],
                [107.1279329, 11.496129],
                [107.1278171, 11.496034],
                [107.1277216, 11.4959232],
                [107.1276106, 11.4957421],
                [107.1275246, 11.4956325],
                [107.12731, 11.4954486],
                [107.1270097, 11.4953281],
                [107.1266985, 11.4952383],
                [107.1263494, 11.4951761],
                [107.1261728, 11.4951594],
                [107.1261167, 11.4951407],
                [107.1259743, 11.4950385],
                [107.1257704, 11.4946709],
                [107.1256578, 11.4945444],
                [107.125526, 11.4944395],
                [107.1252669, 11.4943375],
                [107.1244347, 11.4940923],
                [107.123687, 11.4938218],
                [107.1235103, 11.4937245],
                [107.1233495, 11.4936036],
                [107.1232073, 11.4934612],
                [107.1230869, 11.4933],
                [107.1229911, 11.4931237],
                [107.1228951, 11.4928885],
                [107.1228576, 11.4926152],
                [107.1228629, 11.4923628],
                [107.1229058, 11.492158],
                [107.1229676, 11.4919796],
                [107.1230471, 11.4918744],
                [107.1231553, 11.4917895],
                [107.123288, 11.4917323],
                [107.1234341, 11.4916134],
                [107.1234798, 11.4914744],
                [107.1234365, 11.4913766],
                [107.1233258, 11.4912461],
                [107.1232169, 11.4911772],
                [107.1227556, 11.4910749],
                [107.1225671, 11.4910223],
                [107.1223909, 11.4909382],
                [107.1222479, 11.4908382],
                [107.1218651, 11.4904808],
                [107.1216667, 11.4902548],
                [107.1214682, 11.4900813],
                [107.1212482, 11.4898553],
                [107.1210883, 11.4896435],
                [107.1209478, 11.4894189],
                [107.1208261, 11.4891796],
                [107.1207602, 11.4890105],
                [107.1207608, 11.4889158],
                [107.120797, 11.488828],
                [107.1208623, 11.4887607],
                [107.1209463, 11.4887217],
                [107.121039, 11.4887145],
                [107.1213946, 11.4888112],
                [107.1214935, 11.4887993],
                [107.1215808, 11.4887623],
                [107.1216511, 11.4887066],
                [107.1217093, 11.4886319],
                [107.1217512, 11.4885419],
                [107.1217693, 11.4884393],
                [107.1217585, 11.4883305],
                [107.1216746, 11.4880988],
                [107.1215808, 11.4879312],
                [107.1214629, 11.4877798],
                [107.1213233, 11.4876473],
                [107.1208264, 11.487443],
                [107.1207663, 11.4874071],
                [107.1206338, 11.4872732],
                [107.1205616, 11.4871111],
                [107.1205734, 11.4868964],
                [107.1206517, 11.4867073],
                [107.1207828, 11.4865559],
                [107.1210267, 11.4864031],
                [107.1211394, 11.4862999],
                [107.1212268, 11.4861754],
                [107.1212817, 11.4860462],
                [107.1213126, 11.4858389],
                [107.1212878, 11.4856714],
                [107.121227, 11.4855129],
                [107.1211342, 11.4853724],
                [107.1210146, 11.4852557],
                [107.1208097, 11.485132],
                [107.1206729, 11.4850891],
                [107.1204637, 11.4850909],
                [107.1203379, 11.4851313],
                [107.1202253, 11.4851997],
                [107.1198964, 11.4855551],
                [107.1196985, 11.485712],
                [107.1194644, 11.4858102],
                [107.1192188, 11.4858423],
                [107.1189766, 11.4858103],
                [107.1182879, 11.485594],
                [107.1177433, 11.4854788],
                [107.1176534, 11.4854281],
                [107.1175672, 11.4853109],
                [107.1175468, 11.4852186],
                [107.1175951, 11.4850662],
                [107.1176578, 11.4849786],
                [107.1177408, 11.4849089],
                [107.1181101, 11.4847139],
                [107.1183515, 11.4845562],
                [107.1184023, 11.4844854],
                [107.1184319, 11.4844038],
                [107.1184266, 11.4842513],
                [107.1182817, 11.4841041],
                [107.1181593, 11.4840367],
                [107.1180242, 11.483999],
                [107.1178578, 11.4840254],
                [107.1177614, 11.4840831],
                [107.1176927, 11.4841591],
                [107.1176486, 11.4842509],
                [107.1176058, 11.4846509],
                [107.1175682, 11.4848664],
                [107.117531, 11.4849446],
                [107.1174702, 11.485007],
                [107.1173923, 11.4850472],
                [107.1173054, 11.4850609],
                [107.1172147, 11.4850454],
                [107.1171345, 11.4850011],
                [107.1170533, 11.4848927],
                [107.1169948, 11.484663],
                [107.1169693, 11.4843511],
                [107.1169835, 11.4841567],
                [107.1170119, 11.4840497],
                [107.117064, 11.4839517],
                [107.1171315, 11.4838729],
                [107.1172964, 11.4837505],
                [107.1173537, 11.4836678],
                [107.1173805, 11.4835364],
                [107.1173215, 11.4834155],
                [107.1171939, 11.4833106],
                [107.1170876, 11.4832775],
                [107.1164954, 11.4832052],
                [107.1158901, 11.4831746],
                [107.1157122, 11.4831106],
                [107.1155834, 11.4829896],
                [107.1154708, 11.4826742],
                [107.115404, 11.4822396],
                [107.1153474, 11.4821485],
                [107.1152777, 11.4821035],
                [107.1151543, 11.4820907],
                [107.1149673, 11.4821405],
                [107.1146599, 11.4822991],
                [107.1145839, 11.4823062],
                [107.114479, 11.4822656],
                [107.1144295, 11.4822113],
                [107.1144032, 11.4821433],
                [107.1144345, 11.4819868],
                [107.1145231, 11.4818447],
                [107.1146901, 11.4817075],
                [107.1147352, 11.4815841],
                [107.1147081, 11.4814556],
                [107.1146163, 11.4813596],
                [107.114532, 11.4813284],
                [107.1143228, 11.4812969],
                [107.1140851, 11.4812393],
                [107.1137513, 11.4811194],
                [107.1134434, 11.4809491],
                [107.113072, 11.4806562],
                [107.1129656, 11.4806134],
                [107.1128476, 11.4805982],
                [107.1127295, 11.4806134],
                [107.1121931, 11.4807606],
                [107.1109861, 11.481055],
                [107.1109137, 11.4811081],
                [107.1108626, 11.4811746],
                [107.1107849, 11.4814467],
                [107.1106669, 11.4815387],
                [107.1105301, 11.4815834],
                [107.1103263, 11.4815807],
                [107.1100125, 11.4814955],
                [107.109749, 11.481359],
                [107.1095554, 11.4811986],
                [107.1094162, 11.4811168],
                [107.1093392, 11.4810866],
                [107.1092034, 11.4810876],
                [107.1091108, 11.4811211],
                [107.1090266, 11.4811846],
                [107.1089017, 11.481374],
                [107.1087921, 11.4815913],
                [107.108674, 11.4819645],
                [107.1086358, 11.4820176],
                [107.1085775, 11.4820486],
                [107.1085065, 11.4820501],
                [107.1083133, 11.4819252],
                [107.1082637, 11.4819093],
                [107.1082106, 11.4819147],
                [107.1081644, 11.4819409],
                [107.1080679, 11.4820539],
                [107.1079338, 11.4822773],
                [107.1078674, 11.482318],
                [107.1077954, 11.4823288],
                [107.1077299, 11.4823141],
                [107.106747, 11.4818477],
                [107.1062702, 11.4817023],
                [107.1059466, 11.481651],
                [107.1058326, 11.4816095],
                [107.1057263, 11.4815466],
                [107.1056914, 11.4814414],
                [107.1057209, 11.4813468],
                [107.1059409, 11.4811602],
                [107.1059892, 11.4810761],
                [107.1059838, 11.4810104],
                [107.1059634, 11.4809809],
                [107.1059033, 11.480942],
                [107.1058377, 11.4809366],
                [107.1057526, 11.4809833],
                [107.1054534, 11.4813474],
                [107.1052998, 11.4814809],
                [107.1050984, 11.4815946],
                [107.1047473, 11.4817122],
                [107.1046132, 11.481791],
                [107.1045273, 11.4819014],
                [107.1043503, 11.4823768],
                [107.1042025, 11.4826208],
                [107.104058, 11.482782],
                [107.1039476, 11.4828664],
                [107.1038235, 11.4829297],
                [107.1036899, 11.4829699],
                [107.1034813, 11.4829844],
                [107.1034059, 11.4829594],
                [107.1032667, 11.4828845],
                [107.1032035, 11.4828344],
                [107.1030977, 11.4827136],
                [107.1030632, 11.4826461],
                [107.103059, 11.4825768],
                [107.103079, 11.4825165],
                [107.103146, 11.4824271],
                [107.1031943, 11.482322],
                [107.103205, 11.4821695],
                [107.1031514, 11.4820355],
                [107.1030533, 11.4819646],
                [107.1029389, 11.481926],
                [107.1027221, 11.4819347],
                [107.1026337, 11.481975],
                [107.1025703, 11.4820296],
                [107.1025237, 11.4820986],
                [107.1024916, 11.4822431],
                [107.102493, 11.4824016],
                [107.1025128, 11.4825239],
                [107.1024969, 11.4826111],
                [107.1024573, 11.4826763],
                [107.1024058, 11.4827186],
                [107.1023494, 11.4827268],
                [107.1023028, 11.4827078],
                [107.1021911, 11.4825165],
                [107.1021763, 11.482417],
                [107.1021717, 11.48221],
                [107.1022015, 11.4819846],
                [107.1021776, 11.4818056],
                [107.1020813, 11.4816253],
                [107.1020222, 11.481607],
                [107.1019497, 11.4816675],
                [107.1018773, 11.4817805],
                [107.1016467, 11.4825428],
                [107.1015662, 11.482669],
                [107.1014449, 11.4827966],
                [107.1013792, 11.4829537],
                [107.1013684, 11.4830652],
                [107.1013963, 11.483231],
                [107.1014589, 11.483447],
                [107.1015528, 11.4836783],
                [107.1015355, 11.4837562],
                [107.1014678, 11.4838063],
                [107.1014059, 11.4838076],
                [107.1013516, 11.4837782],
                [107.1011585, 11.4835679],
                [107.1010163, 11.4833188],
                [107.1009125, 11.4830902],
                [107.1008283, 11.4828584],
                [107.1007025, 11.4826374],
                [107.1006144, 11.4825739],
                [107.1005054, 11.4825694],
                [107.1003774, 11.4826497],
                [107.100268, 11.4827636],
                [107.1001389, 11.4829907],
                [107.1000591, 11.4833707],
                [107.1000343, 11.4836466],
                [107.1000311, 11.4839409],
                [107.1000051, 11.48402],
                [107.0999193, 11.4841039],
                [107.0998207, 11.4841375],
                [107.099694, 11.4841462],
                [107.0995909, 11.4841263],
                [107.0994201, 11.4840331],
                [107.0993615, 11.4839648],
                [107.0993244, 11.4838844],
                [107.0993109, 11.4837982],
                [107.0993453, 11.4835918],
                [107.0993396, 11.4834784],
                [107.0992461, 11.4831947],
                [107.0992246, 11.4830133],
                [107.0992509, 11.482921],
                [107.099277, 11.4828803],
                [107.0993505, 11.4828166],
                [107.0993954, 11.4827961],
                [107.0994434, 11.4827844],
                [107.0998382, 11.4827742],
                [107.0999569, 11.482732],
                [107.1000186, 11.4826814],
                [107.1000794, 11.4825814],
                [107.1000856, 11.482506],
                [107.1000658, 11.4824458],
                [107.0999639, 11.4823562],
                [107.0998569, 11.4823116],
                [107.099243, 11.4822333],
                [107.0991039, 11.4822063],
                [107.0989886, 11.4821511],
                [107.098884, 11.4820539],
                [107.0985165, 11.4814809],
                [107.098438, 11.4814345],
                [107.0983475, 11.4814204],
                [107.0982395, 11.4814499],
                [107.0974919, 11.4819882],
                [107.097044, 11.4822379],
                [107.096765, 11.4823719],
                [107.0964641, 11.4824578],
                [107.0962115, 11.4824701],
                [107.0960942, 11.482447],
                [107.096065, 11.4824114],
                [107.0960585, 11.4823581],
                [107.0961137, 11.482233],
                [107.0961826, 11.4821468],
                [107.0966738, 11.4817858],
                [107.0973766, 11.4813862],
                [107.0974649, 11.4812589],
                [107.0975, 11.4811026],
                [107.0974863, 11.480988],
                [107.097441, 11.4808816],
                [107.0973354, 11.4807506],
                [107.0972036, 11.4806448],
                [107.0970648, 11.4805741],
                [107.0969282, 11.4805341],
                [107.0963938, 11.4804865],
                [107.096293, 11.48044],
                [107.0961857, 11.4803296],
                [107.0961688, 11.4802594],
                [107.096175, 11.4801876],
                [107.0962406, 11.4800766],
                [107.0963144, 11.4800299],
                [107.0965172, 11.47999],
                [107.0966148, 11.4799563],
                [107.0967919, 11.4798669],
                [107.0968533, 11.4797678],
                [107.0968575, 11.4796518],
                [107.0968022, 11.4795474],
                [107.0967382, 11.4794987],
                [107.0964485, 11.4794306],
                [107.0963037, 11.4793517],
                [107.0962778, 11.4793203],
                [107.0962447, 11.4792466],
                [107.0962442, 11.4791391],
                [107.0962554, 11.4791047],
                [107.0963842, 11.4789732],
                [107.0964465, 11.4788864],
                [107.0964754, 11.478784],
                [107.0964646, 11.4786683],
                [107.0963252, 11.4785316],
                [107.0960231, 11.4783887],
                [107.0959336, 11.4783108],
                [107.0958504, 11.4781978],
                [107.0958324, 11.4781052],
                [107.0958397, 11.4780112],
                [107.0958724, 11.4779212],
                [107.0959512, 11.4778116],
                [107.0959837, 11.4777413],
                [107.0959923, 11.4776077],
                [107.0959604, 11.477517],
                [107.0957673, 11.4773698],
                [107.0956436, 11.4772371],
                [107.0955615, 11.4770687],
                [107.0955366, 11.476944],
                [107.0955849, 11.4767547],
                [107.0956966, 11.4765596],
                [107.0957458, 11.4765024],
                [107.0960106, 11.4762834],
                [107.0960785, 11.4761849],
                [107.0961106, 11.476081],
                [107.0961041, 11.4759343],
                [107.0960185, 11.4757517],
                [107.0958692, 11.4755771],
                [107.0957005, 11.4754598],
                [107.0954883, 11.4753668],
                [107.0953864, 11.4752617],
                [107.0953462, 11.4751303],
                [107.0953515, 11.4749357],
                [107.0953889, 11.4747631],
                [107.0954561, 11.4745993],
                [107.0955737, 11.4744443],
                [107.0957184, 11.4743131],
                [107.0958853, 11.4742102],
                [107.0959766, 11.47417],
                [107.0961689, 11.4741156],
                [107.0965236, 11.4740736],
                [107.0966202, 11.4740394],
                [107.0966531, 11.4739918],
                [107.0966548, 11.4739384],
                [107.0966309, 11.4738948],
                [107.0963472, 11.4737513],
                [107.0962431, 11.4736391],
                [107.0961985, 11.4735437],
                [107.0961278, 11.4732624],
                [107.0961481, 11.4731614],
                [107.0961907, 11.4730894],
                [107.0962527, 11.4730326],
                [107.0964828, 11.4729084],
                [107.0965487, 11.4728356],
                [107.0965934, 11.4727487],
                [107.0966138, 11.4726551],
                [107.0966209, 11.4724515],
                [107.0966906, 11.4720341],
                [107.0966899, 11.4718445],
                [107.0966271, 11.4715562],
                [107.0965451, 11.4713766],
                [107.0964707, 11.4712901],
                [107.096374, 11.4712283],
                [107.0962695, 11.4711973],
                [107.0961159, 11.4712031],
                [107.0957726, 11.4713766],
                [107.0955293, 11.4713834],
                [107.0953703, 11.4713503],
                [107.0952085, 11.4712817],
                [107.0950645, 11.4711821],
                [107.0949498, 11.4710393],
                [107.0949005, 11.4708647],
                [107.0949233, 11.470686],
                [107.0949465, 11.4706301],
                [107.0950472, 11.4704908],
                [107.0952186, 11.4702062],
                [107.095322, 11.4699987],
                [107.0953611, 11.4698622],
                [107.0953557, 11.469661],
                [107.0953185, 11.4695464],
                [107.0952576, 11.4694419],
                [107.0950507, 11.4692531],
                [107.0948772, 11.4691445],
                [107.0946727, 11.4690574],
                [107.0941311, 11.4689109],
                [107.0939976, 11.4688348],
                [107.0939188, 11.4687542],
                [107.0938575, 11.4686481],
                [107.0937234, 11.4681355],
                [107.0936733, 11.4680771],
                [107.0936082, 11.4680444],
                [107.0935062, 11.4680435],
                [107.0933667, 11.4681407],
                [107.0933309, 11.4682191],
                [107.0933237, 11.4682985],
                [107.0933399, 11.4683703],
                [107.0935175, 11.468645],
                [107.0935183, 11.4687063],
                [107.093489, 11.4687569],
                [107.0934137, 11.4687913],
                [107.0932782, 11.4687716],
                [107.0931479, 11.4687223],
                [107.0929932, 11.4686288],
                [107.0929162, 11.4685571],
                [107.0928571, 11.4684772],
                [107.0927058, 11.4682202],
                [107.0926988, 11.468146],
                [107.0927212, 11.4680866],
                [107.0927659, 11.4680408],
                [107.0928864, 11.4680275],
                [107.0930019, 11.4679909],
                [107.0931659, 11.4678857],
                [107.0932138, 11.4677911],
                [107.0932029, 11.4676901],
                [107.0930748, 11.4675486],
                [107.0928362, 11.4673497],
                [107.0925808, 11.4671813],
                [107.0924234, 11.4670986],
                [107.0922536, 11.4670446],
                [107.0920746, 11.4670208],
                [107.0918941, 11.4670288],
                [107.0916894, 11.467065],
                [107.0914918, 11.4671287],
                [107.0913132, 11.4672141],
                [107.0909203, 11.4674867],
                [107.0908605, 11.4674951],
                [107.0908028, 11.4674775],
                [107.0907568, 11.4674351],
                [107.0907347, 11.4673742],
                [107.0907569, 11.4671234],
                [107.0907167, 11.4670018],
                [107.0906524, 11.4668919],
                [107.0905675, 11.4667981],
                [107.0902526, 11.4665346],
                [107.0901588, 11.4664084],
                [107.0899951, 11.4662349],
                [107.0899435, 11.4662156],
                [107.0897785, 11.4662058],
                [107.0897242, 11.4662192],
                [107.0896599, 11.4662639],
                [107.0896095, 11.4664111],
                [107.0895966, 11.4665576],
                [107.089566, 11.4666187],
                [107.0894828, 11.4666844],
                [107.0893916, 11.4666792],
                [107.0893472, 11.4666208],
                [107.0893235, 11.4665501],
                [107.089346, 11.4663375],
                [107.089332, 11.4662715],
                [107.0892929, 11.466201],
                [107.0892245, 11.4661395],
                [107.0891422, 11.4661035],
                [107.0889446, 11.4660606],
                [107.0888221, 11.4660714],
                [107.0887575, 11.4661074],
                [107.0887077, 11.4661613],
                [107.0885736, 11.4664084],
                [107.0884793, 11.4666897],
                [107.088425, 11.4667425],
                [107.0883153, 11.466765],
                [107.0882463, 11.4667397],
                [107.0880532, 11.4665977],
                [107.0877548, 11.4662789],
                [107.0876005, 11.4661552],
                [107.0875152, 11.4661044],
                [107.0873299, 11.4660261],
                [107.0871305, 11.4659826],
                [107.0868827, 11.4660066],
                [107.0866344, 11.4660623],
                [107.0862543, 11.4662072],
                [107.0860845, 11.4663454],
                [107.0859829, 11.4665085],
                [107.0859611, 11.4665688],
                [107.0859693, 11.4666152],
                [107.0860305, 11.4667434],
                [107.086099, 11.4668122],
                [107.0863849, 11.4669946],
                [107.0864124, 11.4670477],
                [107.0863858, 11.4671029],
                [107.0863219, 11.4671148],
                [107.0860791, 11.4670183],
                [107.0859618, 11.4669367],
                [107.0856084, 11.4666368],
                [107.0854638, 11.4664905],
                [107.0854166, 11.4664137],
                [107.0854001, 11.4663368],
                [107.0854086, 11.4662586],
                [107.0855641, 11.4658301],
                [107.0855963, 11.4655883],
                [107.0855211, 11.4653169],
                [107.0854247, 11.465052],
                [107.0851886, 11.4645789],
                [107.0850171, 11.4644766],
                [107.0848266, 11.4644458],
                [107.0847014, 11.464464],
                [107.0840201, 11.4646558],
                [107.0838261, 11.4646525],
                [107.0836952, 11.4646099],
                [107.0835793, 11.4645368],
                [107.0833997, 11.4643229],
                [107.0832219, 11.4640713],
                [107.0830536, 11.4637798],
                [107.0829334, 11.4636351],
                [107.082708, 11.4634468],
                [107.0822355, 11.4631909],
                [107.082187, 11.4631366],
                [107.0821658, 11.4630674],
                [107.08219, 11.4629666],
                [107.0822538, 11.4629104],
                [107.0823991, 11.462865],
                [107.0825455, 11.4628814],
                [107.0826727, 11.4629543],
                [107.0835239, 11.4637126],
                [107.0835944, 11.4637389],
                [107.08367, 11.4637385],
                [107.0837402, 11.4637114],
                [107.0838008, 11.4636543],
                [107.083833, 11.4635782],
                [107.0838314, 11.4634959],
                [107.0839012, 11.4629123],
                [107.0840766, 11.461925],
                [107.0841205, 11.461555],
                [107.0841177, 11.4610265],
                [107.0840801, 11.460716],
                [107.0840138, 11.4603572],
                [107.0840175, 11.4602685],
                [107.0840476, 11.4601877],
                [107.0841318, 11.4600943],
                [107.0844689, 11.4599154],
                [107.0845368, 11.4598656],
                [107.0845905, 11.4597815],
                [107.0845878, 11.4597157],
                [107.0845054, 11.4596318],
                [107.084384, 11.4595896],
                [107.0839602, 11.4596579],
                [107.0828186, 11.4599258],
                [107.0824186, 11.4599485],
                [107.0820185, 11.4599032],
                [107.0816281, 11.4597894],
                [107.0815033, 11.4597368],
                [107.0813724, 11.4596544],
                [107.0812565, 11.4595528],
                [107.0811646, 11.4594431],
                [107.081044, 11.4592424],
                [107.080999, 11.4588483],
                [107.0809569, 11.4587431],
                [107.0808902, 11.4586511],
                [107.0807504, 11.4585479],
                [107.080366, 11.4584434],
                [107.0801783, 11.4583278],
                [107.0797985, 11.4580146],
                [107.0795676, 11.4578041],
                [107.079258, 11.4574527],
                [107.0790547, 11.4571616],
                [107.0789892, 11.4571054],
                [107.0789089, 11.4570761],
                [107.0787835, 11.457087],
                [107.0785099, 11.4571869],
                [107.0780318, 11.4574261],
                [107.0778125, 11.4576075],
                [107.0775537, 11.4579397],
                [107.0774127, 11.4580411],
                [107.0772439, 11.4580912],
                [107.0771043, 11.458048],
                [107.0769997, 11.4579464],
                [107.0769617, 11.4578559],
                [107.0769632, 11.4577477],
                [107.0770281, 11.4575792],
                [107.077147, 11.457437],
                [107.0775049, 11.4572283],
                [107.07768, 11.4570868],
                [107.0777579, 11.4570058],
                [107.0779353, 11.4567506],
                [107.078032, 11.4565076],
                [107.0780647, 11.4562668],
                [107.0780499, 11.4560773],
                [107.0780003, 11.4558935],
                [107.077923, 11.4554018],
                [107.0778258, 11.4552384],
                [107.0776677, 11.4551259],
                [107.0765305, 11.4546212],
                [107.0756024, 11.4542427],
                [107.0750606, 11.4540376],
                [107.0749752, 11.4540217],
                [107.0748889, 11.4540324],
                [107.0748103, 11.4540687],
                [107.0747468, 11.4541352],
                [107.0747119, 11.4542269],
                [107.0747107, 11.4543158],
                [107.0747387, 11.4544004],
                [107.0749862, 11.4548424],
                [107.075083, 11.4550742],
                [107.075149, 11.4553208],
                [107.0751813, 11.4555781],
                [107.0751357, 11.456025],
                [107.075115, 11.4560707],
                [107.075078, 11.4561052],
                [107.0750043, 11.4561249],
                [107.074948, 11.4560819],
                [107.0749127, 11.4560282],
                [107.0748621, 11.4558541],
                [107.0748085, 11.4557306],
                [107.0746851, 11.4555939],
                [107.0746009, 11.4555451],
                [107.0745081, 11.455515],
                [107.0742077, 11.4554729],
                [107.0737946, 11.4553625],
                [107.0736998, 11.4552949],
                [107.0736048, 11.4551603],
                [107.0735747, 11.4550576],
                [107.0735264, 11.4546633],
                [107.0735295, 11.4531165],
                [107.0734678, 11.4527163],
                [107.073352, 11.452342],
                [107.0732562, 11.4521909],
                [107.0732045, 11.4521685],
                [107.0731348, 11.4521843],
                [107.0730919, 11.4522343],
                [107.0730551, 11.4523127],
                [107.0729758, 11.452664],
                [107.0729202, 11.4527258],
                [107.0728238, 11.4527639],
                [107.0726916, 11.452745],
                [107.0726037, 11.4526996],
                [107.0725058, 11.4525964],
                [107.0724669, 11.4525103],
                [107.0724572, 11.4523325],
                [107.0725072, 11.452156],
                [107.072573, 11.45205],
                [107.0727791, 11.4517872],
                [107.0728945, 11.4515471],
                [107.0729076, 11.4514392],
                [107.0728592, 11.451336],
                [107.0727968, 11.4512879],
                [107.0726461, 11.4512325],
                [107.0723936, 11.4511942],
                [107.0721433, 11.4512128],
                [107.0719766, 11.4512751],
                [107.07185, 11.4513878],
                [107.0717561, 11.4515665],
                [107.0716435, 11.4518662],
                [107.0715365, 11.4522865],
                [107.0714836, 11.4523704],
                [107.0714067, 11.4524339],
                [107.0712622, 11.4524807],
                [107.0711578, 11.452478],
                [107.0710634, 11.4524484],
                [107.070985, 11.452398],
                [107.0708894, 11.4522864],
                [107.0707422, 11.4520266],
                [107.0706511, 11.4519398],
                [107.0705148, 11.4518549],
                [107.0704803, 11.4517969],
                [107.0704752, 11.4517318],
                [107.0704981, 11.4516725],
                [107.0705441, 11.4516279],
                [107.0706317, 11.4515851],
                [107.0706966, 11.4515035],
                [107.0707044, 11.4514383],
                [107.0706663, 11.4513577],
                [107.0706189, 11.4513247],
                [107.0703989, 11.4512695],
                [107.070361, 11.4512429],
                [107.0702865, 11.4511388],
                [107.070238, 11.4509777],
                [107.0701777, 11.4509185],
                [107.0700989, 11.4508783],
                [107.0700079, 11.4508637],
                [107.0695728, 11.450862],
                [107.0693103, 11.4508123],
                [107.0689069, 11.4506817],
                [107.0686448, 11.4505571],
                [107.0685645, 11.4505056],
                [107.0684708, 11.4503886],
                [107.0684355, 11.4502837],
                [107.0684424, 11.4499909],
                [107.068402, 11.4498491],
                [107.0683443, 11.4497684],
                [107.0680993, 11.4495534],
                [107.068071, 11.4494908],
                [107.0680708, 11.4493951],
                [107.0681029, 11.4493043],
                [107.0682371, 11.4490481],
                [107.0684418, 11.4488072],
                [107.0693222, 11.4481204],
                [107.0694476, 11.4480013],
                [107.0695788, 11.4477939],
                [107.0696264, 11.4476548],
                [107.0696306, 11.4475928],
                [107.0695885, 11.4475048],
                [107.069528, 11.4474643],
                [107.0689827, 11.4472868],
                [107.0688877, 11.4472354],
                [107.0687617, 11.4471133],
                [107.068642, 11.4468619],
                [107.0685804, 11.4468088],
                [107.0685267, 11.4468005],
                [107.0680813, 11.4468497],
                [107.0679866, 11.4468298],
                [107.0678964, 11.446784],
                [107.0677891, 11.446669],
                [107.0677087, 11.4464472],
                [107.0675469, 11.4461398],
                [107.0673194, 11.4458289],
                [107.0672803, 11.4456817],
                [107.0673122, 11.4455239],
                [107.0673747, 11.4454317],
                [107.0675755, 11.4452666],
                [107.0677113, 11.4451154],
                [107.0678116, 11.4449605],
                [107.0678872, 11.4447928],
                [107.0679851, 11.4444509],
                [107.0679997, 11.444258],
                [107.0679796, 11.444048],
                [107.0678562, 11.4437221],
                [107.0676736, 11.4433954],
                [107.0675599, 11.443082],
                [107.0675343, 11.4429807],
                [107.0675344, 11.4425048],
                [107.0674753, 11.4423839],
                [107.0673573, 11.4423025],
                [107.0671213, 11.4422735],
                [107.0669911, 11.4422909],
                [107.0667205, 11.4423826],
                [107.0665182, 11.4423937],
                [107.0664561, 11.4423839],
                [107.0664186, 11.4423585],
                [107.0663407, 11.4422499],
                [107.0663295, 11.4421172],
                [107.0663616, 11.4420335],
                [107.0664212, 11.441966],
                [107.0665795, 11.4417083],
                [107.0668825, 11.441264],
                [107.0669977, 11.4412002],
                [107.0671113, 11.4411975],
                [107.0671912, 11.44118],
                [107.0672559, 11.4411306],
                [107.0672985, 11.4410216],
                [107.0672683, 11.4409137],
                [107.0672141, 11.4408604],
                [107.0668244, 11.4407105],
                [107.0666299, 11.4405982],
                [107.0664533, 11.4404642],
                [107.0662675, 11.4402744],
                [107.0662147, 11.440152],
                [107.0662076, 11.4400537],
                [107.0662291, 11.439978],
                [107.0662737, 11.4399364],
                [107.0663427, 11.4399161],
                [107.0665298, 11.4399488],
                [107.0666465, 11.4399312],
                [107.0667975, 11.4398437],
                [107.0668691, 11.4397524],
                [107.0668906, 11.4395815],
                [107.0668739, 11.4394375],
                [107.0667075, 11.4389295],
                [107.0666461, 11.4385486],
                [107.0666467, 11.4383498],
                [107.0667118, 11.4382554],
                [107.0667887, 11.4382177],
                [107.0668316, 11.4382119],
                [107.0670597, 11.4382125],
                [107.067485, 11.4382383],
                [107.067963, 11.4383043],
                [107.0682213, 11.4382954],
                [107.0684706, 11.4382112],
                [107.0686585, 11.4380877],
                [107.0687308, 11.4379846],
                [107.0687387, 11.4378561],
                [107.0687038, 11.4377754],
                [107.0684463, 11.4375967],
                [107.0681941, 11.4373811],
                [107.0677633, 11.4369131],
                [107.067552, 11.4366108],
                [107.0674295, 11.4363613],
                [107.0673809, 11.4362311],
                [107.0672393, 11.4357406],
                [107.0672339, 11.435504],
                [107.0672768, 11.435299],
                [107.0673969, 11.4350337],
                [107.0677172, 11.4344896],
                [107.0677348, 11.4343881],
                [107.067716, 11.4342868],
                [107.0676258, 11.4341613],
                [107.0675347, 11.4341099],
                [107.0674311, 11.434092],
                [107.0672795, 11.4341316],
                [107.0671684, 11.43424],
                [107.0670465, 11.4344866],
                [107.0669243, 11.4346686],
                [107.0667824, 11.4348309],
                [107.0666211, 11.4349745],
                [107.0665339, 11.4350387],
                [107.0663783, 11.4350883],
                [107.0662161, 11.4351098],
                [107.0660489, 11.4351019],
                [107.0658029, 11.4350321],
                [107.0656457, 11.4349345],
                [107.0655029, 11.4347946],
                [107.0654422, 11.4347153],
                [107.0653403, 11.4345313],
                [107.0653483, 11.4344327],
                [107.0654033, 11.4343473],
                [107.0655656, 11.4342684],
                [107.0657975, 11.4341807],
                [107.0658617, 11.4341242],
                [107.0658982, 11.4340476],
                [107.0659028, 11.4339752],
                [107.0658821, 11.4339056],
                [107.0658372, 11.433858],
                [107.065735, 11.4338152],
                [107.0656563, 11.4338189],
                [107.0654281, 11.4339335],
                [107.0652484, 11.4339958],
                [107.0648925, 11.4340432],
                [107.0647333, 11.4340177],
                [107.0646424, 11.4339602],
                [107.0645764, 11.4338791],
                [107.0645404, 11.4337838],
                [107.0645362, 11.4336822],
                [107.0646107, 11.4334797],
                [107.0647833, 11.4332294],
                [107.0649326, 11.4330906],
                [107.0653295, 11.4326122],
                [107.0653801, 11.4324914],
                [107.0653978, 11.432362],
                [107.0653831, 11.4322385],
                [107.0653108, 11.4320766],
                [107.0649546, 11.4317185],
                [107.0647931, 11.4315185],
                [107.0646053, 11.4312188],
                [107.0646103, 11.4311215],
                [107.0646429, 11.4310295],
                [107.0647106, 11.4309403],
                [107.0648038, 11.430877],
                [107.0650092, 11.4307975],
                [107.0652223, 11.4307403],
                [107.0654361, 11.4307066],
                [107.0655977, 11.4306972],
                [107.0657538, 11.4307323],
                [107.0658893, 11.4308226],
                [107.0662756, 11.4313654],
                [107.0665974, 11.4316427],
                [107.0668155, 11.4318603],
                [107.0670537, 11.4321362],
                [107.0672219, 11.4323608],
                [107.0673029, 11.4324182],
                [107.0674485, 11.4324492],
                [107.0675929, 11.4324081],
                [107.0676702, 11.4323446],
                [107.0680117, 11.4318445],
                [107.068398, 11.4313818],
                [107.0684764, 11.4311923],
                [107.0685277, 11.430994],
                [107.0685516, 11.4306819],
                [107.06853, 11.430472],
                [107.0683551, 11.4297571],
                [107.0682585, 11.4294468],
                [107.0682099, 11.4293788],
                [107.0681405, 11.4293312],
                [107.0680643, 11.4293103],
                [107.0679853, 11.4293145],
                [107.0672983, 11.429552],
                [107.0667458, 11.4297255],
                [107.0665883, 11.429732],
                [107.0664883, 11.4296992],
                [107.066395, 11.4296329],
                [107.0663276, 11.4295414],
                [107.0662094, 11.4291369],
                [107.0661528, 11.4288121],
                [107.0661273, 11.4285188],
                [107.0661235, 11.4283716],
                [107.0661758, 11.4282651],
                [107.0663005, 11.4281376],
                [107.0664681, 11.4280632],
                [107.0665905, 11.4280507],
                [107.0674699, 11.428085],
                [107.0678553, 11.4280631],
                [107.0682564, 11.427977],
                [107.068544, 11.4278716],
                [107.0686823, 11.4278063],
                [107.0690247, 11.4277225],
                [107.0691989, 11.4276941],
                [107.0695442, 11.4276651],
                [107.0698843, 11.4276713],
                [107.0702219, 11.4277117],
                [107.0705193, 11.4277701],
                [107.0709729, 11.4278011],
                [107.0714408, 11.4277628],
                [107.0715928, 11.4277009],
                [107.071675, 11.4276289],
                [107.0717347, 11.4275382],
                [107.0717675, 11.4274359],
                [107.0717627, 11.4272756],
                [107.0717239, 11.4271754],
                [107.0715094, 11.4269756],
                [107.0713913, 11.4268849],
                [107.0711387, 11.4267269],
                [107.0709458, 11.4266293],
                [107.0707963, 11.4264987],
                [107.070728, 11.4263877],
                [107.0706788, 11.4262084],
                [107.0706779, 11.4260922],
                [107.0707094, 11.4259573],
                [107.0707744, 11.4258345],
                [107.0709057, 11.4256867],
                [107.0711875, 11.4254086],
                [107.0713472, 11.425271],
                [107.0716856, 11.4250183],
                [107.0724978, 11.4244832],
                [107.0730627, 11.4240722],
                [107.0738053, 11.4234737],
                [107.0738556, 11.4233978],
                [107.0739329, 11.4232337],
                [107.073977, 11.4230583],
                [107.0739863, 11.4228787],
                [107.0739609, 11.4227007],
                [107.073941, 11.4226583],
                [107.0738536, 11.4225482],
                [107.0736977, 11.4224614],
                [107.073639, 11.4224483],
                [107.073139, 11.4224293],
                [107.073038, 11.4223896],
                [107.0729537, 11.4223222],
                [107.072878, 11.4221947],
                [107.0728603, 11.4221015],
                [107.0728674, 11.4219966],
                [107.0729254, 11.4218301],
                [107.0730424, 11.4216868],
                [107.0734298, 11.4214335],
                [107.0735854, 11.4212862],
                [107.0738776, 11.4209429],
                [107.0739889, 11.420868],
                [107.0744276, 11.4206553],
                [107.0745067, 11.4206004],
                [107.0745975, 11.4204746],
                [107.0747226, 11.4201505],
                [107.0748143, 11.4200841],
                [107.0749211, 11.4200453],
                [107.0750435, 11.4200378],
                [107.0751625, 11.4200663],
                [107.0752705, 11.4202014],
                [107.0753429, 11.4203463],
                [107.0753825, 11.4204923],
                [107.0753188, 11.4211598],
                [107.0753488, 11.4212496],
                [107.0754093, 11.4213231],
                [107.0755344, 11.4213818],
                [107.0755809, 11.4213862],
                [107.0756542, 11.4213657],
                [107.0758545, 11.42126],
                [107.0760141, 11.4210917],
                [107.0760852, 11.420955],
                [107.0761288, 11.4208235],
                [107.0762282, 11.4203873],
                [107.0763266, 11.4191935],
                [107.0763502, 11.4185063],
                [107.0763287, 11.418311],
                [107.076271, 11.4181165],
                [107.0761759, 11.4179298],
                [107.0757419, 11.4173584],
                [107.0755282, 11.4171297],
                [107.0751914, 11.4168115],
                [107.0749909, 11.4165801],
                [107.0747484, 11.4162187],
                [107.0745295, 11.4158019],
                [107.0743632, 11.4156021],
                [107.0743039, 11.4155655],
                [107.0741061, 11.4154983],
                [107.0738935, 11.4155006],
                [107.0736887, 11.4155771],
                [107.0736283, 11.4156179],
                [107.0735297, 11.4157221],
                [107.073452, 11.4158475],
                [107.0734007, 11.4159903],
                [107.0732961, 11.4167754],
                [107.0732123, 11.4170853],
                [107.0730704, 11.4173846],
                [107.0729635, 11.4174566],
                [107.0728344, 11.4174688],
                [107.0727112, 11.4174149],
                [107.072652, 11.4173478],
                [107.0724535, 11.4169114],
                [107.0724241, 11.4167757],
                [107.0724364, 11.4165825],
                [107.0725868, 11.4161185],
                [107.0726486, 11.4158322],
                [107.0726949, 11.4153812],
                [107.0726813, 11.4152253],
                [107.0726606, 11.4151496],
                [107.072593, 11.4150079],
                [107.0724957, 11.4148876],
                [107.072373, 11.4147923],
                [107.0722723, 11.4147358],
                [107.0721585, 11.4147134],
                [107.0720504, 11.4147255],
                [107.071907, 11.4148004],
                [107.0718366, 11.4148817],
                [107.0714182, 11.4152287],
                [107.0712977, 11.4153106],
                [107.0711611, 11.4153624],
                [107.0709468, 11.4153784],
                [107.0707476, 11.4153252],
                [107.0706296, 11.415255],
                [107.0702594, 11.4149343],
                [107.0700218, 11.4146828],
                [107.0697069, 11.4142717],
                [107.0693529, 11.4137249],
                [107.0690739, 11.413199],
                [107.0689077, 11.4128231],
                [107.0687594, 11.4125881],
                [107.0685821, 11.4123695],
                [107.0682495, 11.4120205],
                [107.0680526, 11.4117755],
                [107.0679446, 11.4116139],
                [107.0679206, 11.4115164],
                [107.0679284, 11.4114264],
                [107.0679635, 11.4113428],
                [107.0680493, 11.4112482],
                [107.068119, 11.4111378],
                [107.0681459, 11.4109379],
                [107.068119, 11.4107276],
                [107.0679599, 11.4101964],
                [107.0679548, 11.4100365],
                [107.0679663, 11.4099562],
                [107.0680171, 11.4098021],
                [107.0683164, 11.4093003],
                [107.0683537, 11.4091934],
                [107.0683658, 11.4090344],
                [107.0683221, 11.4089043],
                [107.0682275, 11.4088002],
                [107.0681431, 11.4087552],
                [107.0680493, 11.4087347],
                [107.0676631, 11.4085874],
                [107.0675087, 11.4084958],
                [107.067427, 11.4084086],
                [107.0673652, 11.4083005],
                [107.0673358, 11.4081536],
                [107.0673648, 11.4080598],
                [107.0674646, 11.4079564],
                [107.0676292, 11.4078607],
                [107.0677825, 11.4078188],
                [107.0679715, 11.4077951],
                [107.0682539, 11.4078102],
                [107.068434, 11.4078524],
                [107.0686505, 11.4079419],
                [107.0688505, 11.407976],
                [107.068972, 11.4079669],
                [107.0690818, 11.4079348],
                [107.0691803, 11.4078772],
                [107.0692614, 11.4077978],
                [107.06932, 11.4077016],
                [107.0693591, 11.4075391],
                [107.069325, 11.4073413],
                [107.0692617, 11.4072097],
                [107.0691643, 11.4070874],
                [107.0690412, 11.4069899],
                [107.0686018, 11.4067365],
                [107.0682076, 11.4064683],
                [107.0681468, 11.4063933],
                [107.0681083, 11.4063053],
                [107.0681003, 11.4061475],
                [107.0682049, 11.4059845],
                [107.0683226, 11.4058754],
                [107.0683901, 11.4058314],
                [107.0685966, 11.4057311],
                [107.0688732, 11.4056205],
                [107.0691459, 11.4055376],
                [107.0694587, 11.4054649],
                [107.0695767, 11.4053798],
                [107.069624, 11.4053029],
                [107.0696452, 11.405222],
                [107.0696318, 11.4051063],
                [107.069556, 11.4049943],
                [107.0689097, 11.4045876],
                [107.0686448, 11.4043439],
                [107.0684353, 11.404055],
                [107.0683298, 11.4038423],
                [107.0682795, 11.4036908],
                [107.0682399, 11.4036185],
                [107.0681768, 11.4035669],
                [107.0681003, 11.4035419],
                [107.0680225, 11.4035551],
                [107.0679626, 11.4035861],
                [107.067881, 11.4036805],
                [107.0677811, 11.4038338],
                [107.067676, 11.4040437],
                [107.0675987, 11.404265],
                [107.0675437, 11.404357],
                [107.0674152, 11.4044545],
                [107.0673105, 11.4044837],
                [107.0672017, 11.4044806],
                [107.067054, 11.4044178],
                [107.066978, 11.4043429],
                [107.0667458, 11.4038262],
                [107.066591, 11.403565],
                [107.0663658, 11.4032722],
                [107.0661902, 11.4031575],
                [107.066062, 11.4031208],
                [107.0659364, 11.403114],
                [107.0652325, 11.403277],
                [107.0649263, 11.4033296],
                [107.0646233, 11.4033522],
                [107.0643264, 11.4033464],
                [107.0640314, 11.4033132],
                [107.0637909, 11.4032507],
                [107.0633185, 11.4030981],
                [107.0628949, 11.4029254],
                [107.0623362, 11.4026401],
                [107.0620487, 11.4024308],
                [107.0617798, 11.4021989],
                [107.0615315, 11.401946],
                [107.0612924, 11.4016565],
                [107.0611185, 11.4014149],
                [107.0610299, 11.4013543],
                [107.0607334, 11.4012654],
                [107.0605978, 11.4011953],
                [107.0605096, 11.4011125],
                [107.0602763, 11.4007733],
                [107.0601578, 11.4006803],
                [107.0600612, 11.4006532],
                [107.059962, 11.4006564],
                [107.0596003, 11.4008101],
                [107.0593536, 11.4008732],
                [107.0591303, 11.4008573],
                [107.0589916, 11.400809],
                [107.0588683, 11.4007346],
                [107.0587635, 11.4006366],
                [107.0585684, 11.4003491],
                [107.0584069, 11.4000691],
                [107.0582138, 11.3996572],
                [107.0581375, 11.3995401],
                [107.0580554, 11.3994481],
                [107.0577228, 11.3992273],
                [107.0575633, 11.3990635],
                [107.0575082, 11.398917],
                [107.0575053, 11.3988131],
                [107.0575297, 11.3987119],
                [107.0576401, 11.3985978],
                [107.0577711, 11.3985069],
                [107.0579331, 11.3984378],
                [107.0581065, 11.3984045],
                [107.0588646, 11.3984075],
                [107.0590691, 11.3983789],
                [107.0591886, 11.3983265],
                [107.0593321, 11.3982124],
                [107.0593604, 11.3981309],
                [107.0593502, 11.3980688],
                [107.0592838, 11.3979915],
                [107.0585489, 11.3977023],
                [107.0567679, 11.3969503],
                [107.0566503, 11.3968867],
                [107.0565512, 11.3967977],
                [107.0564774, 11.3966903],
                [107.0564192, 11.3965086],
                [107.0564491, 11.3964196],
                [107.0564997, 11.3963403],
                [107.056561, 11.3962807],
                [107.0566906, 11.3962044],
                [107.056734, 11.3961439],
                [107.0567505, 11.3960307],
                [107.0567061, 11.3958995],
                [107.0566177, 11.3957198],
                [107.0564878, 11.3955289],
                [107.0563302, 11.3953591],
                [107.0558386, 11.3949307],
                [107.0557645, 11.3948298],
                [107.0557111, 11.394675],
                [107.0557058, 11.3945734],
                [107.0557594, 11.3944156],
                [107.0558591, 11.3941929],
                [107.05588, 11.3940898],
                [107.0558587, 11.3936715],
                [107.0558686, 11.3936033],
                [107.0559445, 11.3934112],
                [107.0560341, 11.393298],
                [107.0561483, 11.3932088],
                [107.0563544, 11.3930787],
                [107.0570005, 11.3927257],
                [107.0574546, 11.3925225],
                [107.0579934, 11.3923362],
                [107.0580804, 11.3922706],
                [107.0581366, 11.3921891],
                [107.058168, 11.3920597],
                [107.0581471, 11.3919764],
                [107.0581037, 11.391902],
                [107.0580337, 11.3918367],
                [107.057947, 11.3917951],
                [107.0577983, 11.3917751],
                [107.057696, 11.3917442],
                [107.0576075, 11.3916916],
                [107.057585, 11.3916388],
                [107.0575892, 11.3915783],
                [107.0576239, 11.391524],
                [107.0577064, 11.3914704],
                [107.0578368, 11.3914167],
                [107.0579857, 11.3913919],
                [107.0584014, 11.3913892],
                [107.0587769, 11.3914287],
                [107.0588652, 11.39147],
                [107.0589444, 11.3915293],
                [107.0590103, 11.3916048],
                [107.059375, 11.3922806],
                [107.0594128, 11.3923294],
                [107.059507, 11.3924095],
                [107.0596808, 11.3924752],
                [107.0597928, 11.3924791],
                [107.0599024, 11.3924558],
                [107.0600701, 11.392367],
                [107.0602494, 11.3922175],
                [107.0604009, 11.3920027],
                [107.0604372, 11.391923],
                [107.0604342, 11.3918241],
                [107.0603771, 11.3915267],
                [107.0602441, 11.3912394],
                [107.0599115, 11.3908292],
                [107.059844, 11.3906922],
                [107.0597949, 11.3904663],
                [107.0598149, 11.3902297],
                [107.0599222, 11.3899667],
                [107.0600467, 11.3897658],
                [107.0602003, 11.3895853],
                [107.0604041, 11.3894016],
                [107.060621, 11.3891794],
                [107.0608127, 11.388936],
                [107.0609709, 11.3886855],
                [107.061158, 11.3882981],
                [107.0612494, 11.3880454],
                [107.0613134, 11.3877838],
                [107.0613569, 11.3874673],
                [107.0613521, 11.3873688],
                [107.0613206, 11.3872751],
                [107.0612289, 11.3871582],
                [107.0610977, 11.3870862],
                [107.0609809, 11.3870663],
                [107.060852, 11.3870225],
                [107.0607408, 11.3869451],
                [107.0606947, 11.3868956],
                [107.0606121, 11.3867418],
                [107.0605981, 11.3866853],
                [107.0606016, 11.3865827],
                [107.0606649, 11.3864332],
                [107.0607436, 11.3863529],
                [107.0609105, 11.3862394],
                [107.0610589, 11.3862174],
                [107.0611614, 11.3862435],
                [107.061274, 11.3863329],
                [107.0613351, 11.3864288],
                [107.0613872, 11.3866001],
                [107.0614326, 11.3866484],
                [107.06153, 11.3866781],
                [107.0616, 11.3866616],
                [107.0616751, 11.3865873],
                [107.0618255, 11.3862618],
                [107.061884, 11.386061],
                [107.0619231, 11.3857597],
                [107.0618983, 11.3856728],
                [107.0618464, 11.3855983],
                [107.0617729, 11.3855441],
                [107.0616736, 11.3855142],
                [107.0616214, 11.3855131],
                [107.0615208, 11.3855388],
                [107.0610648, 11.3857807],
                [107.0609549, 11.3858079],
                [107.0608416, 11.3858073],
                [107.0607259, 11.3857764],
                [107.0605713, 11.3856703],
                [107.0605057, 11.3855597],
                [107.0604933, 11.3854759],
                [107.0605067, 11.3853931],
                [107.0606068, 11.385134],
                [107.0607589, 11.3849278],
                [107.0607997, 11.3848442],
                [107.0608073, 11.3847017],
                [107.0607713, 11.3846066],
                [107.0607052, 11.3845286],
                [107.0604412, 11.3842917],
                [107.0602577, 11.3842698],
                [107.0600037, 11.3842873],
                [107.0599249, 11.3842637],
                [107.05986, 11.3842167],
                [107.0597667, 11.3840742],
                [107.0596894, 11.3839101],
                [107.0595903, 11.3835037],
                [107.0595078, 11.3833874],
                [107.0594294, 11.3833299],
                [107.0593851, 11.3833091],
                [107.0592271, 11.3832873],
                [107.0589804, 11.3832963],
                [107.0588096, 11.3833341],
                [107.0586468, 11.3833968],
                [107.0582774, 11.3834715],
                [107.0581842, 11.3834559],
                [107.0580751, 11.3833837],
                [107.0580293, 11.3833135],
                [107.0579689, 11.3826493],
                [107.0578931, 11.3823192],
                [107.0578051, 11.3821016],
                [107.0576896, 11.3818967],
                [107.0575963, 11.3817656],
                [107.0575504, 11.3816465],
                [107.0575484, 11.3815642],
                [107.0575683, 11.3814842],
                [107.0577515, 11.3812053],
                [107.0577921, 11.3810859],
                [107.0578023, 11.3809643],
                [107.0577843, 11.3808473],
                [107.0577652, 11.380791],
                [107.0576976, 11.3807117],
                [107.0576091, 11.3806554],
                [107.0575043, 11.3806267],
                [107.0573382, 11.3806464],
                [107.0572408, 11.3807008],
                [107.0570487, 11.3808952],
                [107.056915, 11.3809885],
                [107.0566902, 11.3810864],
                [107.0565095, 11.3811186],
                [107.0564085, 11.3811052],
                [107.0563125, 11.3810667],
                [107.056193, 11.3809655],
                [107.0561632, 11.3809226],
                [107.0561217, 11.3808048],
                [107.0561256, 11.3806181],
                [107.0561722, 11.3805015],
                [107.056257, 11.3804105],
                [107.0564452, 11.3802471],
                [107.0566536, 11.3801116],
                [107.0575268, 11.3797155],
                [107.0581993, 11.3794618],
                [107.0583105, 11.3794022],
                [107.0584031, 11.3793174],
                [107.0584946, 11.3791547],
                [107.0585179, 11.3789602],
                [107.0584624, 11.3787721],
                [107.0583439, 11.3785975],
                [107.0582893, 11.3784636],
                [107.0582664, 11.3783212],
                [107.0582784, 11.3781674],
                [107.0583273, 11.3780207],
                [107.0584228, 11.3778758],
                [107.0585528, 11.3777334],
                [107.0587452, 11.3775755],
                [107.0588621, 11.3775316],
                [107.0589242, 11.3775228],
                [107.0590328, 11.3775292],
                [107.0591362, 11.3775625],
                [107.0591838, 11.3775886],
                [107.0599266, 11.3783125],
                [107.0601554, 11.3784476],
                [107.0602742, 11.3784541],
                [107.0603472, 11.3784221],
                [107.060424, 11.3783243],
                [107.0604367, 11.3782423],
                [107.0603627, 11.3777015],
                [107.0603312, 11.3775978],
                [107.0602821, 11.37751],
                [107.0602219, 11.3774395],
                [107.0601625, 11.377417],
                [107.0594232, 11.3773301],
                [107.059306, 11.3773026],
                [107.0589779, 11.377184],
                [107.058211, 11.3767832],
                [107.0579925, 11.3767338],
                [107.0577686, 11.3767208],
                [107.0575592, 11.3767421],
                [107.0572771, 11.3768255],
                [107.0571023, 11.3769139],
                [107.0566391, 11.3772166],
                [107.0562567, 11.3774092],
                [107.0561186, 11.3774429],
                [107.0559058, 11.3774367],
                [107.0557127, 11.3773669],
                [107.0556006, 11.3772888],
                [107.0548984, 11.3765194],
                [107.0548243, 11.3764721],
                [107.0547375, 11.3764564],
                [107.0546147, 11.3764942],
                [107.0545623, 11.3765347],
                [107.0544778, 11.3766353],
                [107.0544186, 11.3767849],
                [107.0543758, 11.3770834],
                [107.0542989, 11.3772613],
                [107.0541984, 11.3773224],
                [107.0541242, 11.3773233],
                [107.0540605, 11.3772979],
                [107.0540112, 11.377251],
                [107.0539366, 11.3770988],
                [107.0538856, 11.3768829],
                [107.0538614, 11.3766627],
                [107.053876, 11.3763304],
                [107.0539197, 11.3761124],
                [107.0539763, 11.3759514],
                [107.0539996, 11.3758349],
                [107.0539866, 11.3757169],
                [107.0539386, 11.3756082],
                [107.0538599, 11.3755183],
                [107.053741, 11.3754342],
                [107.0535059, 11.3753316],
                [107.0532729, 11.3753005],
                [107.0530512, 11.3753156],
                [107.0528604, 11.3752766],
                [107.052713, 11.375194],
                [107.052643, 11.3750641],
                [107.0526367, 11.3749701],
                [107.0526814, 11.3748432],
                [107.0528258, 11.3747309],
                [107.0529831, 11.3746368],
                [107.0531983, 11.3745458],
                [107.0534252, 11.3744889],
                [107.0535028, 11.3744444],
                [107.0535901, 11.3743444],
                [107.0536322, 11.3742069],
                [107.0535796, 11.3740211],
                [107.0535038, 11.373839],
                [107.0533673, 11.3736027],
                [107.0532697, 11.3734875],
                [107.0531509, 11.3733934],
                [107.0530218, 11.3733267],
                [107.0528888, 11.3732865],
                [107.0527507, 11.3732704],
                [107.0526209, 11.3732971],
                [107.0525025, 11.3733555],
                [107.0524017, 11.3734433],
                [107.0523278, 11.3735538],
                [107.0519939, 11.3747533],
                [107.0518918, 11.3750098],
                [107.0517467, 11.3752833],
                [107.0515885, 11.3754623],
                [107.0514812, 11.3755513],
                [107.0512888, 11.3756451],
                [107.0511429, 11.3756765],
                [107.0509443, 11.3756826],
                [107.050663, 11.3756258],
                [107.0504254, 11.3755071],
                [107.0502394, 11.3753462],
                [107.0500857, 11.3752522],
                [107.0498289, 11.3751665],
                [107.0496501, 11.3751492],
                [107.0494711, 11.3751639],
                [107.0491212, 11.3752566],
                [107.0489342, 11.3752904],
                [107.0487307, 11.3752869],
                [107.0486248, 11.3752621],
                [107.0485272, 11.3752147],
                [107.0484281, 11.3751315],
                [107.0481555, 11.3747171],
                [107.0480644, 11.3746493],
                [107.0479104, 11.3745954],
                [107.0477623, 11.3745955],
                [107.0475395, 11.3746633],
                [107.0473488, 11.3747515],
                [107.0470571, 11.374948],
                [107.0469308, 11.3750135],
                [107.0467743, 11.3750573],
                [107.0467019, 11.375047],
                [107.0466177, 11.3749834],
                [107.0465832, 11.3748121],
                [107.0466747, 11.374286],
                [107.0466747, 11.3741776],
                [107.0466386, 11.3741124],
                [107.0465888, 11.374083],
                [107.0465238, 11.3740796],
                [107.0464623, 11.3741034],
                [107.0463484, 11.374169],
                [107.0462504, 11.3742564],
                [107.0461729, 11.3743616],
                [107.0460696, 11.3745664],
                [107.0459586, 11.374899],
                [107.0458785, 11.3754681],
                [107.0458205, 11.3756893],
                [107.0457133, 11.3759471],
                [107.0456192, 11.3760351],
                [107.0455365, 11.3760671],
                [107.0454458, 11.3760723],
                [107.045199, 11.376001],
                [107.0447695, 11.3757719],
                [107.0445309, 11.3756676],
                [107.0442811, 11.3755857],
                [107.0439747, 11.3755159],
                [107.0438292, 11.3755243],
                [107.0437169, 11.3755827],
                [107.0436888, 11.3756894],
                [107.0437015, 11.3757875],
                [107.0441757, 11.3765742],
                [107.0442572, 11.3767591],
                [107.0443274, 11.3769896],
                [107.0443239, 11.3771581],
                [107.0442713, 11.3772991],
                [107.0441917, 11.3773666],
                [107.0440958, 11.3774092],
                [107.0439938, 11.3774235],
                [107.0438917, 11.3774094],
                [107.0433345, 11.3771856],
                [107.0431815, 11.37709],
                [107.0431064, 11.3769964],
                [107.0430626, 11.3768977],
                [107.0430503, 11.3768451],
                [107.0430503, 11.3766318],
                [107.0430291, 11.376541],
                [107.0429766, 11.3764633],
                [107.0428997, 11.3764087],
                [107.0428082, 11.3763842],
                [107.0425555, 11.3763635],
                [107.042324, 11.3763223],
                [107.0420608, 11.3761778],
                [107.0418994, 11.3760333],
                [107.0417249, 11.3759719],
                [107.0416012, 11.3759645],
                [107.0414763, 11.3759854],
                [107.0413597, 11.3760339],
                [107.0412284, 11.3761159],
                [107.0411387, 11.376137],
                [107.0410496, 11.3761294],
                [107.0409346, 11.3760712],
                [107.0408759, 11.3759802],
                [107.0408398, 11.3758785],
                [107.0408283, 11.3757683],
                [107.0408433, 11.3756584],
                [107.0409581, 11.3754815],
                [107.0410632, 11.3753869],
                [107.0411856, 11.3753159],
                [107.0413907, 11.3752594],
                [107.0416757, 11.3752368],
                [107.041988, 11.3752422],
                [107.0423949, 11.3752914],
                [107.0426082, 11.37528],
                [107.0427437, 11.3752443],
                [107.0429091, 11.375168],
                [107.0429746, 11.375115],
                [107.0430222, 11.3750461],
                [107.0430523, 11.3749077],
                [107.0430468, 11.3748604],
                [107.0430154, 11.374794],
                [107.0428886, 11.3746189],
                [107.0427257, 11.3744882],
                [107.0426643, 11.3744545],
                [107.0422328, 11.374341],
                [107.0418456, 11.3742737],
                [107.0414681, 11.374239],
                [107.0412924, 11.3741897],
                [107.0411392, 11.3741108],
                [107.0410047, 11.3740039],
                [107.040941, 11.3738687],
                [107.0409302, 11.3737183],
                [107.0409549, 11.3736177],
                [107.0410073, 11.3735256],
                [107.041132, 11.3734307],
                [107.041226, 11.3734047],
                [107.04234, 11.3733051],
                [107.0424515, 11.3732731],
                [107.0425493, 11.3732118],
                [107.0426463, 11.3730909],
                [107.0426889, 11.3729514],
                [107.0426621, 11.3728207],
                [107.0426117, 11.3726968],
                [107.0424674, 11.372504],
                [107.0423393, 11.3724052],
                [107.0420394, 11.3722469],
                [107.0416714, 11.3721258],
                [107.0412809, 11.3720791],
                [107.0408868, 11.3721027],
                [107.0404927, 11.3721801],
                [107.0402334, 11.3722695],
                [107.039061, 11.3728619],
                [107.03895, 11.3729041],
                [107.0388224, 11.3729204],
                [107.0387167, 11.3729252],
                [107.0386224, 11.3729135],
                [107.0385452, 11.3728447],
                [107.0385295, 11.372765],
                [107.0385343, 11.3726859],
                [107.0386758, 11.3721968],
                [107.038752, 11.3713913],
                [107.0387101, 11.371259],
                [107.0386098, 11.3711546],
                [107.0385206, 11.3711145],
                [107.0384133, 11.3711043],
                [107.0380803, 11.3711084],
                [107.0378476, 11.3711435],
                [107.037358, 11.3712899],
                [107.0370473, 11.3713537],
                [107.0367312, 11.3713828],
                [107.036446, 11.3713792],
                [107.0361626, 11.3713473],
                [107.0360225, 11.3713209],
                [107.0359561, 11.3712928],
                [107.0357827, 11.3711662],
                [107.0356962, 11.3710526],
                [107.0356399, 11.3709265],
                [107.0356137, 11.3707912],
                [107.035619, 11.3706536],
                [107.035798, 11.3700482],
                [107.0358684, 11.3695454],
                [107.0359554, 11.3693809],
                [107.0361087, 11.3692515],
                [107.0361699, 11.3692227],
                [107.0364646, 11.3691424],
                [107.0370611, 11.3690094],
                [107.037554, 11.3689282],
                [107.0382975, 11.3688484],
                [107.0386577, 11.368758],
                [107.0390014, 11.3686004],
                [107.0392131, 11.3684598],
                [107.0394048, 11.3682939],
                [107.039559, 11.3680958],
                [107.039647, 11.3680058],
                [107.0398323, 11.3678534],
                [107.0400269, 11.3677341],
                [107.0403451, 11.367606],
                [107.0410293, 11.3674443],
                [107.0417147, 11.367305],
                [107.0418086, 11.3672256],
                [107.0418363, 11.3671485],
                [107.0418168, 11.3670347],
                [107.0417358, 11.366951],
                [107.0414425, 11.3668464],
                [107.0411755, 11.3667933],
                [107.0410398, 11.3667804],
                [107.0408031, 11.3667802],
                [107.040568, 11.3668078],
                [107.0403381, 11.366863],
                [107.0401285, 11.3669645],
                [107.0399056, 11.3670337],
                [107.039672, 11.3670691],
                [107.0395525, 11.3670735],
                [107.0393144, 11.367055],
                [107.0386811, 11.3669247],
                [107.038275, 11.3668836],
                [107.0379123, 11.3668766],
                [107.0373698, 11.366918],
                [107.0371014, 11.3669013],
                [107.0367006, 11.3668213],
                [107.0363082, 11.3666724],
                [107.0361031, 11.3665573],
                [107.0359582, 11.3664336],
                [107.0358955, 11.3663621],
                [107.0357922, 11.3662028],
                [107.0357526, 11.3661163],
                [107.0356997, 11.3659342],
                [107.0357235, 11.3657892],
                [107.0357797, 11.3656529],
                [107.0358628, 11.365535],
                [107.0359671, 11.3654398],
                [107.0360895, 11.3653684],
                [107.0362545, 11.365304],
                [107.0364417, 11.3652057],
                [107.0366939, 11.3650055],
                [107.0368997, 11.3647441],
                [107.0369529, 11.3645887],
                [107.0369839, 11.3644276],
                [107.0369826, 11.3641363],
                [107.0369418, 11.363946],
                [107.0365763, 11.3629646],
                [107.0364155, 11.3625632],
                [107.0363431, 11.3624227],
                [107.0362958, 11.3622722],
                [107.036275, 11.3620353],
                [107.0363138, 11.3618253],
                [107.0364054, 11.361696],
                [107.0364927, 11.3616345],
                [107.0366518, 11.3615903],
                [107.0367622, 11.3615985],
                [107.0368155, 11.3616138],
                [107.0374821, 11.3621023],
                [107.0377277, 11.3621951],
                [107.0378645, 11.3621951],
                [107.0379569, 11.3621602],
                [107.038034, 11.3620994],
                [107.0382056, 11.3618685],
                [107.0383705, 11.3615635],
                [107.0384478, 11.3613603],
                [107.0384871, 11.3611903],
                [107.0384615, 11.3609942],
                [107.0383608, 11.3608183],
                [107.0381978, 11.3606919],
                [107.0380588, 11.3606471],
                [107.0379131, 11.3606325],
                [107.0377033, 11.3606666],
                [107.0375758, 11.3607231],
                [107.0373833, 11.3608672],
                [107.0372732, 11.3609009],
                [107.0371599, 11.3609012],
                [107.0370535, 11.3608699],
                [107.0368709, 11.3607529],
                [107.0366878, 11.3605681],
                [107.0365816, 11.3604007],
                [107.0365408, 11.3603105],
                [107.0364856, 11.3601209],
                [107.0364715, 11.3600001],
                [107.0364665, 11.3597572],
                [107.0364927, 11.3595155],
                [107.0365383, 11.3593165],
                [107.0366279, 11.3590561],
                [107.0366039, 11.3589554],
                [107.0365258, 11.3588916],
                [107.0364927, 11.3588826],
                [107.036226, 11.3589101],
                [107.0356183, 11.3590559],
                [107.0352785, 11.3590839],
                [107.0347524, 11.3590545],
                [107.0341963, 11.3589863],
                [107.0340528, 11.3589379],
                [107.0339735, 11.3588757],
                [107.0339085, 11.3587834],
                [107.0338752, 11.3586762],
                [107.0338617, 11.3579463],
                [107.0338866, 11.3578522],
                [107.0339397, 11.3577719],
                [107.0340143, 11.3577127],
                [107.0340577, 11.3576923],
                [107.0345084, 11.3575875],
                [107.034605, 11.3575341],
                [107.0346804, 11.3574658],
                [107.0347384, 11.3573828],
                [107.0347645, 11.3572552],
                [107.0347605, 11.3571235],
                [107.0347253, 11.3569945],
                [107.0346182, 11.3568246],
                [107.0341489, 11.3563577],
                [107.0338266, 11.356099],
                [107.0334823, 11.3558692],
                [107.0327687, 11.3552995],
                [107.0326309, 11.3552216],
                [107.0324892, 11.3551791],
                [107.0323525, 11.3551674],
                [107.0322067, 11.3552215],
                [107.0320718, 11.3552982],
                [107.0319455, 11.3554012],
                [107.0317946, 11.3555916],
                [107.031756, 11.3556628],
                [107.0315315, 11.3562545],
                [107.0314889, 11.3564157],
                [107.0314098, 11.3568732],
                [107.0313957, 11.3572649],
                [107.0313749, 11.3573909],
                [107.0313048, 11.357548],
                [107.031254, 11.3576153],
                [107.0312032, 11.3576473],
                [107.0311117, 11.3576542],
                [107.0310535, 11.3576278],
                [107.0309491, 11.3574963],
                [107.030912, 11.3573812],
                [107.0308719, 11.3569631],
                [107.0307879, 11.356776],
                [107.0306543, 11.3566191],
                [107.0304764, 11.3565024],
                [107.030342, 11.3564544],
                [107.0299772, 11.356368],
                [107.0297631, 11.3562717],
                [107.0297272, 11.356234],
                [107.0296535, 11.3560981],
                [107.0296368, 11.3559965],
                [107.0296647, 11.3558409],
                [107.0297175, 11.3557488],
                [107.0298895, 11.3556439],
                [107.0303281, 11.355477],
                [107.0306482, 11.3552773],
                [107.0308438, 11.3551055],
                [107.0310129, 11.3549316],
                [107.0311525, 11.354734],
                [107.0312515, 11.3545369],
                [107.0313544, 11.3542153],
                [107.0313966, 11.3539843],
                [107.0314055, 11.353758],
                [107.0313844, 11.3535408],
                [107.0313313, 11.3532933],
                [107.0312695, 11.3531629],
                [107.0311806, 11.3530484],
                [107.0310148, 11.352923],
                [107.0308859, 11.3528696],
                [107.0306674, 11.3528432],
                [107.030521, 11.3528558],
                [107.03028, 11.3529336],
                [107.0301364, 11.3530219],
                [107.0300089, 11.3531277],
                [107.0298594, 11.3533188],
                [107.0297808, 11.3535444],
                [107.0297789, 11.353778],
                [107.0298675, 11.354081],
                [107.0298794, 11.3542397],
                [107.0298304, 11.3544671],
                [107.0297155, 11.3546574],
                [107.0295456, 11.3548028],
                [107.0291526, 11.3549335],
                [107.0287999, 11.3550714],
                [107.0286263, 11.3551468],
                [107.0284804, 11.3552394],
                [107.0283085, 11.3552637],
                [107.0281951, 11.3552394],
                [107.0281422, 11.3552156],
                [107.028111, 11.3551839],
                [107.0280511, 11.3550665],
                [107.0280437, 11.3549765],
                [107.0280873, 11.3548452],
                [107.0282412, 11.3546824],
                [107.0283386, 11.3545139],
                [107.0283751, 11.3543731],
                [107.0283828, 11.354253],
                [107.0283666, 11.3541592],
                [107.0282914, 11.354032],
                [107.0282074, 11.3539664],
                [107.0279261, 11.3538626],
                [107.0277352, 11.3538327],
                [107.0274741, 11.3538527],
                [107.0273073, 11.3539041],
                [107.0271527, 11.3539841],
                [107.026876, 11.3541781],
                [107.0266194, 11.3543969],
                [107.0264059, 11.3546153],
                [107.0262124, 11.354851],
                [107.0259557, 11.3550124],
                [107.0257659, 11.3550846],
                [107.0255668, 11.3551262],
                [107.025279, 11.3551309],
                [107.0250897, 11.3550987],
                [107.0247509, 11.3547683],
                [107.0245563, 11.3545207],
                [107.0243303, 11.3541428],
                [107.0241083, 11.3536276],
                [107.0239732, 11.3534241],
                [107.0238941, 11.3533306],
                [107.023715, 11.3531629],
                [107.0235154, 11.3530264],
                [107.0231888, 11.3528829],
                [107.022378, 11.3527219],
                [107.0221716, 11.3527286],
                [107.0220064, 11.3526882],
                [107.0218895, 11.3526005],
                [107.0217533, 11.3524295],
                [107.0215433, 11.3522645],
                [107.021118, 11.3520175],
                [107.0209327, 11.3518702],
                [107.0207679, 11.3517033],
                [107.0205728, 11.3513871],
                [107.0204757, 11.3511597],
                [107.0204069, 11.3509232],
                [107.0203669, 11.3506811],
                [107.0203563, 11.3504361],
                [107.0204445, 11.3492342],
                [107.0203576, 11.3490747],
                [107.020233, 11.3489781],
                [107.0201418, 11.3489441],
                [107.0199889, 11.3489687],
                [107.0198631, 11.3490541],
                [107.0197867, 11.3491829],
                [107.0196739, 11.3498413],
                [107.0196372, 11.3502352],
                [107.0196091, 11.3507819],
                [107.0195259, 11.3510058],
                [107.0194255, 11.3511482],
                [107.0193643, 11.3512108],
                [107.0191591, 11.3513371],
                [107.0188269, 11.3514684],
                [107.0184836, 11.3515279],
                [107.0182213, 11.351491],
                [107.0179827, 11.3513849],
                [107.0178474, 11.3512821],
                [107.0177336, 11.3511568],
                [107.0173895, 11.3506846],
                [107.0172925, 11.3504397],
                [107.0172657, 11.3502663],
                [107.0172704, 11.3500891],
                [107.017307, 11.3499155],
                [107.0179193, 11.3492882],
                [107.0180776, 11.3490183],
                [107.0181036, 11.3489224],
                [107.0180986, 11.3488233],
                [107.0180632, 11.3487307],
                [107.0179619, 11.3486223],
                [107.0174171, 11.3483977],
                [107.0168116, 11.3482088],
                [107.0164123, 11.3479965],
                [107.0162198, 11.3478782],
                [107.015757, 11.3475517],
                [107.0155854, 11.3474929],
                [107.0154612, 11.3474882],
                [107.0152777, 11.3475373],
                [107.0149882, 11.3476758],
                [107.0148054, 11.347739],
                [107.0146535, 11.3478266],
                [107.0145329, 11.3479277],
                [107.0144309, 11.3480469],
                [107.0140559, 11.3483808],
                [107.0140374, 11.3484141],
                [107.0140318, 11.3485258],
                [107.0140638, 11.3485853],
                [107.0141169, 11.3486278],
                [107.0143277, 11.3487046],
                [107.0145936, 11.3487601],
                [107.0154549, 11.3488982],
                [107.0155696, 11.3489745],
                [107.015619, 11.3490565],
                [107.0156311, 11.3491395],
                [107.0156137, 11.3492112],
                [107.0155726, 11.3492785],
                [107.0155078, 11.3493324],
                [107.0145822, 11.3496457],
                [107.0144501, 11.3496643],
                [107.0143169, 11.3496544],
                [107.0141154, 11.34958],
                [107.0139974, 11.3494905],
                [107.0136997, 11.3491648],
                [107.0135171, 11.3489993],
                [107.0133132, 11.3488505],
                [107.0130891, 11.3487215],
                [107.0126006, 11.3486473],
                [107.011947, 11.3485124],
                [107.0118414, 11.3485051],
                [107.0116304, 11.3485191],
                [107.0113827, 11.3485866],
                [107.0108179, 11.349009],
                [107.0107346, 11.3490398],
                [107.0106018, 11.3490352],
                [107.010486, 11.3489716],
                [107.0104126, 11.3488632],
                [107.0102922, 11.3484044],
                [107.0102154, 11.3482215],
                [107.0101958, 11.3480907],
                [107.0102186, 11.3478837],
                [107.0105708, 11.3467179],
                [107.0105797, 11.3466401],
                [107.0105618, 11.3465638],
                [107.010515, 11.3464933],
                [107.0104401, 11.3464413],
                [107.0095663, 11.3463806],
                [107.0094518, 11.3463887],
                [107.0092871, 11.3463459],
                [107.0091534, 11.3462457],
                [107.0091143, 11.346135],
                [107.0091088, 11.3459596],
                [107.0092026, 11.3457101],
                [107.009352, 11.3454822],
                [107.0095633, 11.345297],
                [107.0097314, 11.3452067],
                [107.0099203, 11.3451658],
                [107.0100973, 11.3451615],
                [107.0102561, 11.3451852],
                [107.0106695, 11.3452864],
                [107.0107944, 11.3452506],
                [107.0108471, 11.3452011],
                [107.0108798, 11.3451373],
                [107.0109252, 11.3447682],
                [107.010994, 11.3443871],
                [107.0109871, 11.3442623],
                [107.010913, 11.3441038],
                [107.0106153, 11.3437644],
                [107.010355, 11.3433444],
                [107.0103088, 11.3432115],
                [107.0102891, 11.3430678],
                [107.0103162, 11.3428456],
                [107.010372, 11.3427572],
                [107.010447, 11.3426837],
                [107.0106004, 11.3426054],
                [107.0107352, 11.3425785],
                [107.0108942, 11.342569],
                [107.0111717, 11.3426128],
                [107.0113449, 11.3426837],
                [107.0119676, 11.3430345],
                [107.0124306, 11.3433342],
                [107.0127548, 11.3435646],
                [107.0128688, 11.3436098],
                [107.0130522, 11.3436229],
                [107.0131717, 11.3435944],
                [107.0133267, 11.3435017],
                [107.0134372, 11.3433604],
                [107.0134784, 11.3432485],
                [107.0134882, 11.3431896],
                [107.013471, 11.3430007],
                [107.0133769, 11.3426928],
                [107.0133472, 11.3424475],
                [107.0133172, 11.3423011],
                [107.0132543, 11.3421705],
                [107.013213, 11.3421136],
                [107.0128816, 11.3417989],
                [107.0128313, 11.3417364],
                [107.012799, 11.3416174],
                [107.0128139, 11.34153],
                [107.0129653, 11.3413816],
                [107.0131471, 11.3412866],
                [107.0141109, 11.3410409],
                [107.0141444, 11.3410213],
                [107.0141963, 11.3409647],
                [107.0142215, 11.3408926],
                [107.0142158, 11.3408166],
                [107.0141574, 11.3407252],
                [107.0140937, 11.3406884],
                [107.013975, 11.3406682],
                [107.0132915, 11.3406594],
                [107.0127486, 11.340586],
                [107.0123757, 11.3404954],
                [107.0117054, 11.340263],
                [107.0113811, 11.340223],
                [107.0112053, 11.3402171],
                [107.0110559, 11.340255],
                [107.0107394, 11.3403606],
                [107.0105457, 11.3404693],
                [107.0102773, 11.3406874],
                [107.0101098, 11.3407711],
                [107.0098965, 11.3408014],
                [107.0097596, 11.340763],
                [107.0096504, 11.3406694],
                [107.0096041, 11.3405817],
                [107.00952, 11.3399593],
                [107.0094699, 11.3397625],
                [107.0094016, 11.339585],
                [107.0093598, 11.3394994],
                [107.0092697, 11.3393754],
                [107.0089608, 11.3390339],
                [107.0087277, 11.3388366],
                [107.0084746, 11.3386646],
                [107.0065211, 11.3375705],
                [107.0061259, 11.3372664],
                [107.0056566, 11.3367764],
                [107.0054665, 11.336659],
                [107.0053311, 11.3366136],
                [107.0051964, 11.3365933],
                [107.0051282, 11.3365917],
                [107.005004, 11.3366168],
                [107.0048233, 11.3367062],
                [107.0047646, 11.3367539],
                [107.0046672, 11.3368683],
                [107.0045481, 11.3371094],
                [107.0044539, 11.3373608],
                [107.0043804, 11.3376454],
                [107.0043301, 11.3380827],
                [107.0042682, 11.3384335],
                [107.0041755, 11.3385472],
                [107.0040411, 11.3386089],
                [107.003928, 11.3386127],
                [107.0038727, 11.3386002],
                [107.0037728, 11.3385482],
                [107.0035113, 11.3383323],
                [107.0032705, 11.3380894],
                [107.0032527, 11.3379714],
                [107.0032705, 11.3378533],
                [107.0033212, 11.3377467],
                [107.0034008, 11.3376587],
                [107.0039104, 11.3372934],
                [107.004002, 11.3371798],
                [107.0040686, 11.3370505],
                [107.0041093, 11.3368991],
                [107.0041168, 11.3368211],
                [107.0040892, 11.3366448],
                [107.0039839, 11.3363978],
                [107.0038094, 11.33619],
                [107.0037383, 11.336133],
                [107.002724, 11.3355076],
                [107.0026147, 11.3354118],
                [107.0025274, 11.3352964],
                [107.0024645, 11.335163],
                [107.0024194, 11.3349297],
                [107.0024156, 11.3347481],
                [107.0024379, 11.3345678],
                [107.0024968, 11.3343626],
                [107.0026475, 11.3340795],
                [107.003579, 11.3329831],
                [107.0036283, 11.332834],
                [107.0036291, 11.3327383],
                [107.0036076, 11.3326451],
                [107.0035059, 11.3325247],
                [107.0033719, 11.3324046],
                [107.003202, 11.3322945],
                [107.0030159, 11.3322133],
                [107.0027786, 11.3321605],
                [107.0025367, 11.3321357],
                [107.0021625, 11.3321527],
                [107.0019046, 11.3322044],
                [107.0015357, 11.3323414],
                [107.0004676, 11.3329179],
                [106.9998742, 11.3331934],
                [106.9993321, 11.3334166],
                [106.9992199, 11.3334385],
                [106.999106, 11.3334278],
                [106.9989529, 11.3333534],
                [106.9988432, 11.3332162],
                [106.9988097, 11.3331042],
                [106.9988407, 11.3327508],
                [106.9988918, 11.3325177],
                [106.9989833, 11.3322423],
                [106.999106, 11.3319788],
                [106.9992344, 11.3317875],
                [106.9994728, 11.3315363],
                [106.9997689, 11.3313304],
                [107.0000039, 11.3312223],
                [107.000127, 11.3311814],
                [107.0003806, 11.3311271],
                [107.0005295, 11.3311141],
                [107.0006719, 11.3310693],
                [107.0008579, 11.3309475],
                [107.0009948, 11.3307695],
                [107.0010635, 11.330557],
                [107.001092, 11.3303098],
                [107.0010962, 11.3300611],
                [107.0010532, 11.3296698],
                [107.000947, 11.3292551],
                [107.0008711, 11.3291078],
                [107.0007668, 11.3289783],
                [107.000643, 11.3288753],
                [107.0005013, 11.3287976],
                [107.0003474, 11.3287584],
                [107.0001903, 11.328758],
                [107.0000356, 11.3287856],
                [106.9998808, 11.3288441],
                [106.9997333, 11.328936],
                [106.9996078, 11.3290551],
                [106.9984827, 11.33059],
                [106.9983749, 11.3306795],
                [106.9982491, 11.3307423],
                [106.9981119, 11.3307752],
                [106.9979707, 11.3307763],
                [106.9977757, 11.3307225],
                [106.9976605, 11.330653],
                [106.9970624, 11.3299852],
                [106.9969262, 11.3298528],
                [106.9966352, 11.329608],
                [106.9963303, 11.3293971],
                [106.9958528, 11.3291417],
                [106.9945321, 11.3285836],
                [106.9944744, 11.328548],
                [106.9943737, 11.3284583],
                [106.9942971, 11.3283481],
                [106.9942359, 11.3281578],
                [106.9942344, 11.3280222],
                [106.9942663, 11.3278881],
                [106.9943729, 11.3277105],
                [106.994477, 11.327618],
                [106.9950584, 11.3273119],
                [106.9957249, 11.3269989],
                [106.9964062, 11.3267182],
                [106.9964749, 11.3266539],
                [106.9965191, 11.3265715],
                [106.9965304, 11.326433],
                [106.9964996, 11.3263439],
                [106.9964406, 11.3262687],
                [106.9963141, 11.3262004],
                [106.9960733, 11.3261886],
                [106.9959037, 11.3262096],
                [106.9951778, 11.3264076],
                [106.9942328, 11.3267334],
                [106.9933757, 11.3270987],
                [106.9928196, 11.3273743],
                [106.9926178, 11.3275263],
                [106.9923589, 11.3278009],
                [106.9921596, 11.3281263],
                [106.9921075, 11.3282464],
                [106.9920301, 11.328496],
                [106.9920051, 11.3286241],
                [106.9919982, 11.3288845],
                [106.992009, 11.3290144],
                [106.9920591, 11.3292702],
                [106.992196, 11.3296207],
                [106.9923154, 11.3298191],
                [106.9925401, 11.3300848],
                [106.993297, 11.3307122],
                [106.9936376, 11.3310293],
                [106.993748, 11.3311823],
                [106.9938628, 11.3314396],
                [106.9939105, 11.3317103],
                [106.9939062, 11.3318855],
                [106.993875, 11.3320581],
                [106.9938297, 11.3321405],
                [106.9937151, 11.3322898],
                [106.9935723, 11.3324136],
                [106.9934071, 11.3325068],
                [106.9931593, 11.3325777],
                [106.9929011, 11.3325804],
                [106.9913532, 11.3322841],
                [106.9902507, 11.3321074],
                [106.9900655, 11.3320641],
                [106.9898889, 11.3319944],
                [106.9897194, 11.3318963],
                [106.9895623, 11.3317694],
                [106.9887695, 11.3309753],
                [106.9885012, 11.3307459],
                [106.9882363, 11.3305705],
                [106.9873399, 11.3301822],
                [106.9872033, 11.3300991],
                [106.9870831, 11.3299859],
                [106.9870313, 11.3299182],
                [106.9868162, 11.3295109],
                [106.9866623, 11.3293119],
                [106.9865746, 11.3292212],
                [106.9864219, 11.3290906],
                [106.9862546, 11.3289783],
                [106.9861385, 11.3289309],
                [106.9860133, 11.328918],
                [106.9858896, 11.3289406],
                [106.9857793, 11.3289958],
                [106.9856906, 11.3290775],
                [106.9856089, 11.3292367],
                [106.9855821, 11.3296083],
                [106.9856457, 11.3300611],
                [106.985773, 11.3304929],
                [106.9858525, 11.3306038],
                [106.9861199, 11.3309088],
                [106.9865504, 11.3312882],
                [106.9866606, 11.3314239],
                [106.9867776, 11.331623],
                [106.9867988, 11.3317248],
                [106.986781, 11.3318793],
                [106.986736, 11.3319759],
                [106.9866675, 11.3320581],
                [106.986437, 11.3321762],
                [106.9862259, 11.3322373],
                [106.9858681, 11.3322894],
                [106.9853675, 11.3323376],
                [106.9846373, 11.3323526],
                [106.9835898, 11.332285],
                [106.9833507, 11.3322319],
                [106.9830132, 11.3320989],
                [106.9828091, 11.3319791],
                [106.9820342, 11.331402],
                [106.9818625, 11.3313487],
                [106.9817421, 11.3313516],
                [106.9815733, 11.3314131],
                [106.9814782, 11.3314901],
                [106.9814064, 11.3315916],
                [106.9813656, 11.3317086],
                [106.9813591, 11.3318321],
                [106.9814855, 11.3322167],
                [106.9815225, 11.3324039],
                [106.981507, 11.3325843],
                [106.9814609, 11.3326999],
                [106.9813771, 11.3328028],
                [106.9812612, 11.3328859],
                [106.9811233, 11.3329465],
                [106.9809677, 11.3329773],
                [106.9808314, 11.3329896],
                [106.9805579, 11.3329907],
                [106.9804215, 11.3329796],
                [106.9801535, 11.3329346],
                [106.9800219, 11.3329007],
                [106.9789429, 11.3324995],
                [106.9787344, 11.3323993],
                [106.9785519, 11.3322761],
                [106.9783967, 11.332136],
                [106.9783267, 11.3320585],
                [106.9781867, 11.3317816],
                [106.9780801, 11.3314907],
                [106.9780101, 11.3312],
                [106.9779743, 11.330914],
                [106.97797, 11.3306259],
                [106.9779993, 11.3304017],
                [106.9780619, 11.3301842],
                [106.9781564, 11.3299781],
                [106.9782807, 11.3297878],
                [106.9783533, 11.3296999],
                [106.9785172, 11.329541],
                [106.9786083, 11.3294703],
                [106.9788054, 11.3293499],
                [106.9789102, 11.3293007],
                [106.9791296, 11.3292259],
                [106.9793578, 11.3291838],
                [106.9795899, 11.3291752],
                [106.9811332, 11.3293296],
                [106.9811949, 11.3293284],
                [106.981315, 11.3293018],
                [106.9814235, 11.329245],
                [106.9815128, 11.3291619],
                [106.9815481, 11.3291123],
                [106.9815981, 11.3289972],
                [106.981614, 11.328873],
                [106.9814354, 11.3277474],
                [106.981433, 11.3272624],
                [106.9814628, 11.3268181],
                [106.9815166, 11.326416],
                [106.9815567, 11.3262081],
                [106.9815736, 11.3259971],
                [106.9815628, 11.3257433],
                [106.9815274, 11.3255064],
                [106.9815199, 11.3253868],
                [106.9815253, 11.3251473],
                [106.9815559, 11.32492],
                [106.9815925, 11.3247536],
                [106.9816391, 11.3246526],
                [106.9817124, 11.3245683],
                [106.9818065, 11.3245074],
                [106.9819142, 11.3244744],
                [106.9819597, 11.3244843],
                [106.9820432, 11.3245242],
                [106.9820791, 11.3245532],
                [106.9821327, 11.3246218],
                [106.9822013, 11.3248182],
                [106.9823018, 11.3250503],
                [106.982423, 11.325257],
                [106.9825291, 11.3254024],
                [106.9826191, 11.3254822],
                [106.9827269, 11.3255369],
                [106.9828442, 11.3255628],
                [106.9829645, 11.3255589],
                [106.9830798, 11.3255254],
                [106.9843212, 11.3250135],
                [106.9847238, 11.3248151],
                [106.9850934, 11.3246014],
                [106.9855059, 11.3243254],
                [106.9856401, 11.3242023],
                [106.9856977, 11.3241321],
                [106.9857847, 11.3239914],
                [106.9858412, 11.3238533],
                [106.9858611, 11.3237814],
                [106.9858788, 11.3235059],
                [106.9858785, 11.3233678],
                [106.9858564, 11.3230683],
                [106.9858345, 11.3229196],
                [106.9857885, 11.3226881],
                [106.9857631, 11.3226062],
                [106.985688, 11.3224518],
                [106.9855841, 11.322316],
                [106.9854559, 11.3222037],
                [106.985384, 11.3221573],
                [106.9852281, 11.3220863],
                [106.984241, 11.3217326],
                [106.9836383, 11.321574],
                [106.9834853, 11.3215027],
                [106.9834149, 11.3214565],
                [106.9832963, 11.3213524],
                [106.9832448, 11.3212929],
                [106.9830946, 11.321064],
                [106.9829953, 11.3208961],
                [106.9827882, 11.3205022],
                [106.982684, 11.3202751],
                [106.9825888, 11.3200441],
                [106.9825756, 11.3199771],
                [106.9825697, 11.3198409],
                [106.9825914, 11.3197062],
                [106.9826405, 11.3195771],
                [106.9826746, 11.3195168],
                [106.982758, 11.3194394],
                [106.9828606, 11.3193889],
                [106.9829737, 11.3193695],
                [106.983082, 11.3193815],
                [106.9831829, 11.3194221],
                [106.9835705, 11.3196707],
                [106.9852376, 11.3207021],
                [106.9853499, 11.3207342],
                [106.9854668, 11.3207319],
                [106.98557, 11.3206992],
                [106.9856171, 11.3206726],
                [106.9857126, 11.320591],
                [106.985799, 11.3204771],
                [106.9858316, 11.3204136],
                [106.9858719, 11.3202855],
                [106.9858829, 11.3201518],
                [106.9858772, 11.3200849],
                [106.9858169, 11.3198339],
                [106.9857283, 11.3195911],
                [106.98561, 11.3193555],
                [106.985465, 11.3191347],
                [106.9852951, 11.3189316],
                [106.9846742, 11.3182925],
                [106.9846158, 11.3181992],
                [106.9845869, 11.3180935],
                [106.9845899, 11.3179838],
                [106.9846034, 11.3179302],
                [106.9846534, 11.3178316],
                [106.9851369, 11.3173181],
                [106.9851625, 11.3172834],
                [106.9851953, 11.3172044],
                [106.9852016, 11.317162],
                [106.9851933, 11.3170771],
                [106.9851788, 11.3170367],
                [106.9851237, 11.3169576],
                [106.9850862, 11.3169266],
                [106.9849974, 11.3168868],
                [106.9843131, 11.3167922],
                [106.9841234, 11.3167589],
                [106.9839352, 11.3167184],
                [106.9836208, 11.316634],
                [106.9834658, 11.3165843],
                [106.9818792, 11.3159901],
                [106.9817331, 11.3159136],
                [106.9816138, 11.3158144],
                [106.9815666, 11.3157272],
                [106.9815553, 11.3156789],
                [106.9815588, 11.3155813],
                [106.9815955, 11.3154916],
                [106.9816249, 11.3154526],
                [106.9824932, 11.3147192],
                [106.9826639, 11.3145933],
                [106.9830045, 11.314375],
                [106.9835515, 11.3140893],
                [106.9836573, 11.3140102],
                [106.983743, 11.3139105],
                [106.9838024, 11.3138002],
                [106.9838361, 11.3136858],
                [106.983844, 11.3136268],
                [106.9837931, 11.3133552],
                [106.9837646, 11.3132578],
                [106.9836925, 11.3130679],
                [106.9836275, 11.3129799],
                [106.9835849, 11.3129449],
                [106.9834853, 11.3128974],
                [106.9833753, 11.3128838],
                [106.9832795, 11.3129011],
                [106.9831927, 11.3129443],
                [106.9831549, 11.3129746],
                [106.9830944, 11.3130495],
                [106.9829831, 11.3132494],
                [106.9828771, 11.3134075],
                [106.9827444, 11.3135449],
                [106.9826692, 11.3136044],
                [106.9825182, 11.313696],
                [106.9823541, 11.3137622],
                [106.9822392, 11.3137853],
                [106.982123, 11.3138009],
                [106.9818887, 11.3138096],
                [106.9816662, 11.3137898],
                [106.9815564, 11.3137697],
                [106.9813415, 11.3137096],
                [106.9807783, 11.3134611],
                [106.9805114, 11.3133796],
                [106.9804315, 11.3133785],
                [106.9803932, 11.31339],
                [106.9803277, 11.3134348],
                [106.9802873, 11.3134987],
                [106.9802754, 11.3135729],
                [106.9803263, 11.3137482],
                [106.9804038, 11.3139265],
                [106.9805107, 11.3141031],
                [106.9811565, 11.3149458],
                [106.9812081, 11.3150501],
                [106.9812312, 11.3151638],
                [106.9812255, 11.3152734],
                [106.9811958, 11.3153727],
                [106.9811726, 11.3154192],
                [106.9810313, 11.3155732],
                [106.9809517, 11.3156415],
                [106.9807784, 11.3157574],
                [106.980686, 11.3158046],
                [106.9804913, 11.3158768],
                [106.9802516, 11.3159029],
                [106.9800104, 11.3158998],
                [106.9797663, 11.3158666],
                [106.9796438, 11.3158381],
                [106.9794056, 11.3157582],
                [106.9787183, 11.3154297],
                [106.978147, 11.3152061],
                [106.9775999, 11.3149697],
                [106.9774994, 11.3149462],
                [106.9774477, 11.3149431],
                [106.9773482, 11.3149536],
                [106.9772565, 11.314984],
                [106.9770446, 11.3151088],
                [106.9769335, 11.3151658],
                [106.9766492, 11.3152926],
                [106.9764894, 11.3153876],
                [106.9763953, 11.3154565],
                [106.9762239, 11.3156138],
                [106.9757357, 11.3161214],
                [106.9751371, 11.3167108],
                [106.9750937, 11.3167431],
                [106.9749946, 11.3167871],
                [106.9749411, 11.3167977],
                [106.9748323, 11.3167949],
                [106.9747314, 11.3167617],
                [106.9746464, 11.316703],
                [106.9745818, 11.316623],
                [106.9745589, 11.3165771],
                [106.9744185, 11.3162141],
                [106.9743503, 11.316092],
                [106.9742564, 11.3159792],
                [106.9741371, 11.3158821],
                [106.9739859, 11.3158284],
                [106.9739071, 11.3158124],
                [106.9737466, 11.3158031],
                [106.9736664, 11.3158097],
                [106.9735147, 11.315844],
                [106.9734419, 11.3158713],
                [106.9733056, 11.3159452],
                [106.9729942, 11.3162239],
                [106.9728926, 11.3163055],
                [106.972776, 11.3163813],
                [106.972665, 11.3164647],
                [106.97256, 11.3165554],
                [106.9724219, 11.3166961],
                [106.9723901, 11.3167508],
                [106.9723496, 11.31687],
                [106.972342, 11.3169954],
                [106.9723683, 11.3171199],
                [106.9723941, 11.3171793],
                [106.9724688, 11.3172852],
                [106.9727175, 11.3174451],
                [106.9729497, 11.3176171],
                [106.9730589, 11.3177067],
                [106.973108, 11.31778],
                [106.9731236, 11.3178212],
                [106.9731354, 11.3179081],
                [106.9731197, 11.3179971],
                [106.9730758, 11.3180793],
                [106.9730079, 11.3181439],
                [106.9729318, 11.3181675],
                [106.9728534, 11.3181821],
                [106.9727738, 11.3181874],
                [106.9726941, 11.3181834],
                [106.9725474, 11.3181507],
                [106.9724118, 11.3180867],
                [106.9714563, 11.3175508],
                [106.9712549, 11.3174713],
                [106.9711512, 11.3174398],
                [106.9709417, 11.3173945],
                [106.9705786, 11.3173508],
                [106.9702774, 11.3172918],
                [106.9700832, 11.3172414],
                [106.9698917, 11.3171817],
                [106.9697034, 11.3171129],
                [106.9690537, 11.3169004],
                [106.9688521, 11.3168622],
                [106.9686366, 11.3168492],
                [106.9684106, 11.3168657],
                [106.9682563, 11.3169118],
                [106.9681151, 11.3169883],
                [106.9679932, 11.3170918],
                [106.9678937, 11.3172216],
                [106.9678542, 11.3172955],
                [106.9678232, 11.3173733],
                [106.9678012, 11.3174539],
                [106.9677883, 11.3175364],
                [106.9678002, 11.3179863],
                [106.9677745, 11.3180748],
                [106.9677183, 11.3181485],
                [106.967639, 11.3181977],
                [106.9675504, 11.3182159],
                [106.9674639, 11.3182041],
                [106.9674235, 11.3181876],
                [106.9671553, 11.3179927],
                [106.9669987, 11.3178941],
                [106.9669284, 11.3178341],
                [106.9668148, 11.3177064],
                [106.966732, 11.3175724],
                [106.9666994, 11.3175009],
                [106.9666724, 11.3173747],
                [106.9666457, 11.3171182],
                [106.9666517, 11.3169028],
                [106.9666833, 11.3166895],
                [106.9667292, 11.3165612],
                [106.9668013, 11.3164449],
                [106.9668953, 11.3163467],
                [106.9670078, 11.3162695],
                [106.9670695, 11.3162398],
                [106.9678694, 11.3160246],
                [106.9679856, 11.3159677],
                [106.9680914, 11.3158873],
                [106.9682018, 11.315741],
                [106.9682497, 11.315663],
                [106.9683333, 11.3154915],
                [106.9683792, 11.3153594],
                [106.9683802, 11.315279],
                [106.9683695, 11.3152401],
                [106.9683277, 11.3151709],
                [106.968298, 11.315143],
                [106.9682219, 11.3151041],
                [106.9681365, 11.3150957],
                [106.968002, 11.3151225],
                [106.9678152, 11.315143],
                [106.9677226, 11.3151445],
                [106.9674211, 11.3151249],
                [106.9673163, 11.3151246],
                [106.9670506, 11.3151464],
                [106.9669193, 11.3151693],
                [106.9664286, 11.3153067],
                [106.9659812, 11.3154637],
                [106.9655769, 11.3156335],
                [106.9654738, 11.3156526],
                [106.9653691, 11.3156469],
                [106.965318, 11.3156348],
                [106.9652141, 11.3155882],
                [106.9651262, 11.3155165],
                [106.9649326, 11.3152436],
                [106.964835, 11.3150514],
                [106.9647975, 11.3149505],
                [106.964746, 11.3147419],
                [106.964727, 11.3145318],
                [106.9647293, 11.3144263],
                [106.9648025, 11.3139882],
                [106.9647918, 11.3138875],
                [106.9647467, 11.3137964],
                [106.9646829, 11.313733],
                [106.9646444, 11.3137092],
                [106.9645586, 11.3136801],
                [106.9640145, 11.3136544],
                [106.9635292, 11.3136652],
                [106.9633974, 11.3136861],
                [106.9632756, 11.3137398],
                [106.9632212, 11.3137779],
                [106.9631314, 11.3138711],
                [106.9630699, 11.313981],
                [106.9630501, 11.3140407],
                [106.9630341, 11.3141651],
                [106.963124, 11.3150536],
                [106.9631218, 11.3151313],
                [106.9630886, 11.315202],
                [106.963062, 11.3152311],
                [106.9629938, 11.3152708],
                [106.9629135, 11.3152809],
                [106.9628343, 11.315258],
                [106.9627715, 11.3152054],
                [106.9620441, 11.3141193],
                [106.9618593, 11.313828],
                [106.9616494, 11.3134762],
                [106.9615081, 11.3131445],
                [106.9614464, 11.3130595],
                [106.9614067, 11.3130245],
                [106.9613224, 11.3129766],
                [106.9612281, 11.3129522],
                [106.9611794, 11.3129495],
                [106.9610971, 11.3129586],
                [106.9610164, 11.3129763],
                [106.960938, 11.3130023],
                [106.9608005, 11.3130724],
                [106.9606873, 11.3131609],
                [106.9605764, 11.3132808],
                [106.9605372, 11.3133055],
                [106.9604483, 11.3133314],
                [106.9603559, 11.3133238],
                [106.9603124, 11.3133075],
                [106.9602408, 11.3132555],
                [106.9601939, 11.3131854],
                [106.9601738, 11.3131039],
                [106.9601829, 11.3130205],
                [106.9604782, 11.3123309],
                [106.9605408, 11.312167],
                [106.9606429, 11.3118317],
                [106.9606842, 11.3116524],
                [106.960724, 11.311424],
                [106.9607225, 11.311376],
                [106.9606939, 11.3112847],
                [106.9606345, 11.3112089],
                [106.9605954, 11.3111801],
                [106.9605096, 11.3111458],
                [106.9604659, 11.3111399],
                [106.9603786, 11.311149],
                [106.9603372, 11.3111637],
                [106.9602312, 11.3112363],
                [106.9601111, 11.311289],
                [106.9600465, 11.3113071],
                [106.9599042, 11.3113313],
                [106.9597578, 11.3113794],
                [106.9596513, 11.3114383],
                [106.9595529, 11.3115152],
                [106.9594981, 11.3115492],
                [106.9594391, 11.3115755],
                [106.959377, 11.3115934],
                [106.9592481, 11.3116033],
                [106.9591838, 11.311595],
                [106.9590662, 11.311555],
                [106.9589636, 11.3114858],
                [106.9589203, 11.3114418],
                [106.9584429, 11.3107455],
                [106.9583289, 11.3105962],
                [106.9582115, 11.3104812],
                [106.9581047, 11.3104012],
                [106.9580037, 11.3103142],
                [106.9579091, 11.3102206],
                [106.9577517, 11.3100308],
                [106.9576295, 11.3098394],
                [106.9575729, 11.3097171],
                [106.9575571, 11.3096517],
                [106.9575516, 11.3095175],
                [106.9575621, 11.309451],
                [106.9576036, 11.3093343],
                [106.9576719, 11.3092303],
                [106.957715, 11.309185],
                [106.9578159, 11.3091109],
                [106.9582772, 11.3088992],
                [106.9586293, 11.3087484],
                [106.9589917, 11.3085654],
                [106.9593608, 11.3083468],
                [106.9595427, 11.308225],
                [106.9598657, 11.3079794],
                [106.9600679, 11.3078025],
                [106.960121, 11.3077406],
                [106.9601666, 11.3076732],
                [106.960204, 11.3076011],
                [106.9602327, 11.3075253],
                [106.9602524, 11.3074467],
                [106.9602627, 11.3073664],
                [106.9602637, 11.3072863],
                [106.9602554, 11.3072066],
                [106.9602379, 11.3071283],
                [106.9602115, 11.3070525],
                [106.9601765, 11.3069801],
                [106.9601333, 11.3069121],
                [106.9600187, 11.3068153],
                [106.9598863, 11.3067437],
                [106.9597417, 11.3067004],
                [106.9596606, 11.3066894],
                [106.9594972, 11.3066942],
                [106.959339, 11.3067345],
                [106.9582113, 11.3071995],
                [106.9581478, 11.3072193],
                [106.9580825, 11.3072317],
                [106.9579496, 11.3072337],
                [106.9578199, 11.3072054],
                [106.9576969, 11.3071458],
                [106.9576418, 11.3071056],
                [106.9575484, 11.3070071],
                [106.9574326, 11.306835],
                [106.9572897, 11.3065954],
                [106.9571653, 11.306341],
                [106.9571105, 11.3062081],
                [106.9570615, 11.3060731],
                [106.957036, 11.3059282],
                [106.9570198, 11.3057821],
                [106.9570132, 11.3056353],
                [106.9570162, 11.3054884],
                [106.9570287, 11.3053419],
                [106.9570729, 11.3050897],
                [106.9571454, 11.3048439],
                [106.957192, 11.3047243],
                [106.9573049, 11.3044937],
                [106.9579773, 11.303773],
                [106.9581903, 11.3035136],
                [106.958299, 11.3033658],
                [106.9584019, 11.3032141],
                [106.9584582, 11.3031173],
                [106.9585463, 11.302912],
                [106.9585776, 11.3028049],
                [106.9586134, 11.302585],
                [106.9586179, 11.3024737],
                [106.9585993, 11.3022502],
                [106.9585763, 11.3021403],
                [106.9585049, 11.3020157],
                [106.9584588, 11.3019603],
                [106.9583485, 11.3018668],
                [106.9582194, 11.3018006],
                [106.9580783, 11.3017651],
                [106.9579326, 11.3017622],
                [106.9577935, 11.301791],
                [106.9576645, 11.3018494],
                [106.957552, 11.3019346],
                [106.9574618, 11.3020423],
                [106.9572482, 11.3026617],
                [106.9572086, 11.3027464],
                [106.9571057, 11.302903],
                [106.9569835, 11.30303],
                [106.9568505, 11.3031276],
                [106.9567028, 11.3032022],
                [106.9565826, 11.3032169],
                [106.9564618, 11.3032248],
                [106.9562302, 11.303221],
                [106.9560108, 11.3031943],
                [106.9558853, 11.3031667],
                [106.9556418, 11.3030857],
                [106.9555251, 11.3030329],
                [106.9553043, 11.3029038],
                [106.9551057, 11.3027469],
                [106.9549326, 11.3025661],
                [106.954856, 11.3024673],
                [106.9547246, 11.3022554],
                [106.9545511, 11.3017394],
                [106.9545294, 11.301701],
                [106.954468, 11.3016377],
                [106.9544301, 11.3016144],
                [106.9543494, 11.3015889],
                [106.9543092, 11.3015859],
                [106.9542298, 11.3015978],
                [106.9541209, 11.3016312],
                [106.9539079, 11.3017109],
                [106.953684, 11.3018171],
                [106.953351, 11.3020115],
                [106.9532278, 11.3020743],
                [106.9529721, 11.3021806],
                [106.9526977, 11.3022623],
                [106.9525531, 11.302293],
                [106.9522597, 11.3023303],
                [106.9521792, 11.3023277],
                [106.9520193, 11.3023441],
                [106.951865, 11.3023886],
                [106.9517244, 11.3024582],
                [106.9516011, 11.302549],
                [106.9514959, 11.3026594],
                [106.9513441, 11.3028572],
                [106.9511518, 11.3031447],
                [106.9510668, 11.3032951],
                [106.9509245, 11.3035975],
                [106.9508158, 11.3039016],
                [106.9507724, 11.3040571],
                [106.9507595, 11.3041322],
                [106.9507083, 11.304276],
                [106.9506255, 11.3044047],
                [106.9505154, 11.304512],
                [106.9503835, 11.3045923],
                [106.9503152, 11.3046197],
                [106.9501715, 11.3046523],
                [106.9500241, 11.3046541],
                [106.9492925, 11.3046008],
                [106.9491847, 11.3046076],
                [106.9491326, 11.304622],
                [106.949037, 11.3046714],
                [106.9489955, 11.3047054],
                [106.9489291, 11.3047886],
                [106.9488888, 11.3048862],
                [106.9488776, 11.3049909],
                [106.9489397, 11.3053552],
                [106.9489753, 11.3054826],
                [106.9491053, 11.3058422],
                [106.9492274, 11.3062506],
                [106.9492294, 11.3064109],
                [106.9492217, 11.306571],
                [106.9492045, 11.3067304],
                [106.9491778, 11.3068886],
                [106.9490984, 11.307192],
                [106.9490469, 11.3073365],
                [106.9489194, 11.3076158],
                [106.9488439, 11.3077498],
                [106.9487621, 11.3078551],
                [106.9486582, 11.3079397],
                [106.9485378, 11.3079995],
                [106.9484731, 11.308019],
                [106.9483404, 11.308036],
                [106.9482083, 11.308024],
                [106.948144, 11.3080073],
                [106.9480231, 11.3079536],
                [106.9477967, 11.3077359],
                [106.947672, 11.307643],
                [106.947604, 11.3076047],
                [106.9474592, 11.3075459],
                [106.9473834, 11.3075258],
                [106.9472279, 11.3075052],
                [106.9471346, 11.3075218],
                [106.9470522, 11.3075677],
                [106.9469986, 11.3076244],
                [106.9469606, 11.3077044],
                [106.9469065, 11.3077839],
                [106.9466847, 11.3079681],
                [106.9464288, 11.3082009],
                [106.9463496, 11.3082319],
                [106.9462644, 11.3082315],
                [106.9461842, 11.3081988],
                [106.94615, 11.3081711],
                [106.9461016, 11.3080987],
                [106.9460893, 11.3080569],
                [106.9460492, 11.3078404],
                [106.9460403, 11.3077307],
                [106.9460429, 11.3075396],
                [106.946076, 11.3073806],
                [106.9461048, 11.3073045],
                [106.9461858, 11.3071628],
                [106.9462949, 11.3070403],
                [106.9464272, 11.3069423],
                [106.9467089, 11.3067004],
                [106.9467764, 11.3066036],
                [106.9468009, 11.3065499],
                [106.9468296, 11.306436],
                [106.9468283, 11.3063072],
                [106.9468148, 11.306244],
                [106.9467223, 11.306104],
                [106.9466045, 11.3060275],
                [106.9465394, 11.3060003],
                [106.9464715, 11.305981],
                [106.946353, 11.3059671],
                [106.9462341, 11.3059771],
                [106.9457155, 11.3061442],
                [106.9449849, 11.3063446],
                [106.9447463, 11.3064328],
                [106.9445216, 11.3065507],
                [106.9443082, 11.3067013],
                [106.9442068, 11.3067896],
                [106.9441119, 11.3068845],
                [106.9438242, 11.3072284],
                [106.9437144, 11.3073413],
                [106.9434768, 11.3075486],
                [106.9433444, 11.307646],
                [106.9432065, 11.3077359],
                [106.9430636, 11.3078179],
                [106.9428442, 11.3079253],
                [106.9427767, 11.3079667],
                [106.9427144, 11.3080155],
                [106.9426583, 11.308071],
                [106.942609, 11.3081324],
                [106.9425673, 11.308199],
                [106.9425337, 11.3082698],
                [106.9425086, 11.308344],
                [106.9424856, 11.3084916],
                [106.9424948, 11.3086339],
                [106.9425343, 11.3087712],
                [106.9428424, 11.3093743],
                [106.9428578, 11.3094331],
                [106.9428637, 11.3094935],
                [106.9428477, 11.3096094],
                [106.9427995, 11.3097119],
                [106.9427027, 11.3097755],
                [106.9425939, 11.3098156],
                [106.942479, 11.3098302],
                [106.9423397, 11.309829],
                [106.9422027, 11.3098039],
                [106.9421219, 11.3097772],
                [106.9420445, 11.3097421],
                [106.9418557, 11.3096067],
                [106.9417086, 11.309538],
                [106.9416408, 11.3094936],
                [106.9415406, 11.3094079],
                [106.941496, 11.3093594],
                [106.9410494, 11.3085658],
                [106.9409485, 11.3083786],
                [106.9408564, 11.3081871],
                [106.940714, 11.3078354],
                [106.940553, 11.3073309],
                [106.9404848, 11.3071475],
                [106.9404083, 11.3069673],
                [106.9402502, 11.3066511],
                [106.9401616, 11.3064978],
                [106.940117, 11.3064345],
                [106.940065, 11.3063768],
                [106.9400065, 11.3063255],
                [106.9399422, 11.3062813],
                [106.9398731, 11.3062449],
                [106.9398, 11.3062167],
                [106.9397241, 11.3061972],
                [106.9396463, 11.3061866],
                [106.9395678, 11.3061851],
                [106.9394897, 11.3061927],
                [106.9393506, 11.3062301],
                [106.9392229, 11.306296],
                [106.9391128, 11.3063874],
                [106.9390335, 11.3065266],
                [106.9389787, 11.3066767],
                [106.9389592, 11.3067648],
                [106.9389457, 11.3069444],
                [106.9390013, 11.3074476],
                [106.9390163, 11.3076551],
                [106.9390241, 11.3078365],
                [106.9390238, 11.3080783],
                [106.9389935, 11.3081948],
                [106.9389668, 11.3082491],
                [106.9388934, 11.3083446],
                [106.9387977, 11.3084178],
                [106.9386649, 11.3084506],
                [106.9385323, 11.3084542],
                [106.9384061, 11.308431],
                [106.9383023, 11.3083841],
                [106.938213, 11.3083142],
                [106.9381427, 11.3082244],
                [106.9381097, 11.3081312],
                [106.9381043, 11.3080299],
                [106.9381204, 11.3079286],
                [106.938122, 11.3077737],
                [106.9380967, 11.3075999],
                [106.9380351, 11.3074134],
                [106.9375277, 11.3063716],
                [106.9372012, 11.3057564],
                [106.9371667, 11.3056383],
                [106.9371607, 11.305577],
                [106.9371708, 11.3054567],
                [106.9372086, 11.3053442],
                [106.9372373, 11.3052919],
                [106.9373107, 11.3052102],
                [106.9374695, 11.3050581],
                [106.9376703, 11.3049034],
                [106.9377915, 11.3048263],
                [106.9379176, 11.3047572],
                [106.9384382, 11.3045299],
                [106.938873, 11.3043171],
                [106.9393462, 11.3040492],
                [106.9394301, 11.3039838],
                [106.939583, 11.3038368],
                [106.9397137, 11.3036704],
                [106.9398256, 11.3034749],
                [106.9399068, 11.3032654],
                [106.9399214, 11.3031532],
                [106.939902, 11.3030416],
                [106.9398547, 11.3029468],
                [106.9397886, 11.3028731],
                [106.9397489, 11.302843],
                [106.9397056, 11.3028182],
                [106.9393811, 11.302713],
                [106.9389425, 11.3025973],
                [106.9388122, 11.3025374],
                [106.9386967, 11.302453],
                [106.9385996, 11.3023457],
                [106.9385258, 11.3022191],
                [106.9384805, 11.3020803],
                [106.9384583, 11.3019296],
                [106.9384547, 11.3018505],
                [106.9384679, 11.3017004],
                [106.9385042, 11.3015619],
                [106.9385308, 11.3014952],
                [106.9385979, 11.3014306],
                [106.9386388, 11.3014075],
                [106.9387285, 11.3013828],
                [106.9388206, 11.3013881],
                [106.9389059, 11.3014223],
                [106.9394867, 11.3018934],
                [106.9395232, 11.3019126],
                [106.9396041, 11.3019282],
                [106.9396815, 11.3019132],
                [106.939745, 11.3018734],
                [106.9397703, 11.3018457],
                [106.9398035, 11.3017793],
                [106.9398304, 11.3015809],
                [106.9398317, 11.3014808],
                [106.9398119, 11.3012913],
                [106.9397632, 11.3011069],
                [106.9396868, 11.3009317],
                [106.9393153, 11.299929],
                [106.9392712, 11.2997812],
                [106.9392047, 11.2994804],
                [106.9391826, 11.299328],
                [106.9391665, 11.2991547],
                [106.9391601, 11.2989809],
                [106.9391632, 11.298807],
                [106.9391759, 11.2986336],
                [106.9392109, 11.2984597],
                [106.939241, 11.298376],
                [106.9393197, 11.2982271],
                [106.9394179, 11.2981015],
                [106.9394748, 11.2980454],
                [106.9396597, 11.2979062],
                [106.9397621, 11.2978079],
                [106.9398456, 11.2976927],
                [106.9398795, 11.2976297],
                [106.9399294, 11.2974961],
                [106.939945, 11.2974266],
                [106.9399359, 11.2971801],
                [106.9399009, 11.2969166],
                [106.9398716, 11.2967771],
                [106.939835, 11.2966392],
                [106.9398012, 11.2965868],
                [106.9397146, 11.2964967],
                [106.9396077, 11.2964308],
                [106.9394887, 11.2963937],
                [106.9393656, 11.2963867],
                [106.9392444, 11.2964096],
                [106.939187, 11.2964319],
                [106.9390827, 11.2964965],
                [106.9386173, 11.2969616],
                [106.9385448, 11.2970204],
                [106.9383873, 11.2971212],
                [106.938212, 11.2971992],
                [106.9380265, 11.2972499],
                [106.937851, 11.2972687],
                [106.9376913, 11.2972514],
                [106.9375433, 11.2972027],
                [106.937474, 11.2971672],
                [106.9374162, 11.2971046],
                [106.9373746, 11.2970276],
                [106.9373536, 11.29694],
                [106.9373453, 11.2965951],
                [106.9373653, 11.29648],
                [106.9374096, 11.2963716],
                [106.9374481, 11.2963105],
                [106.9374943, 11.2962549],
                [106.9375475, 11.2962057],
                [106.9376068, 11.2961637],
                [106.9376712, 11.2961296],
                [106.9378861, 11.2960521],
                [106.938121, 11.2959916],
                [106.9383746, 11.2959526],
                [106.9386488, 11.295937],
                [106.9389398, 11.2959086],
                [106.9392513, 11.2958528],
                [106.9394049, 11.2958153],
                [106.9397292, 11.2956917],
                [106.9400626, 11.2955377],
                [106.9404015, 11.2953502],
                [106.9406816, 11.2951672],
                [106.9409274, 11.2950275],
                [106.9411728, 11.2949218],
                [106.9413535, 11.2948641],
                [106.941455, 11.2948178],
                [106.9415477, 11.2947476],
                [106.941589, 11.2947033],
                [106.9416541, 11.2946018],
                [106.941677, 11.2945462],
                [106.9417109, 11.294326],
                [106.9417162, 11.2940974],
                [106.9416905, 11.2938642],
                [106.9416659, 11.2937493],
                [106.9415634, 11.2934255],
                [106.9415239, 11.2933285],
                [106.9414754, 11.2932354],
                [106.9414186, 11.293147],
                [106.9413538, 11.293064],
                [106.9412847, 11.2929902],
                [106.9411281, 11.2928615],
                [106.9410418, 11.2928077],
                [106.9408566, 11.2927232],
                [106.9405096, 11.2926292],
                [106.9400074, 11.2925143],
                [106.9394959, 11.2924155],
                [106.9394234, 11.2923947],
                [106.9392868, 11.292332],
                [106.9392242, 11.2922907],
                [106.9391142, 11.2921913],
                [106.9390675, 11.2921339],
                [106.9389931, 11.2920066],
                [106.9389662, 11.2919381],
                [106.9389253, 11.291784],
                [106.938909, 11.2916134],
                [106.9389254, 11.2914553],
                [106.9389449, 11.2913781],
                [106.9390056, 11.2912307],
                [106.9390571, 11.2910723],
                [106.9390991, 11.2909112],
                [106.9391522, 11.2906027],
                [106.9391706, 11.2903099],
                [106.9391584, 11.2900168],
                [106.9391409, 11.2898992],
                [106.9390963, 11.2897887],
                [106.9390203, 11.289684],
                [106.938969, 11.2896362],
                [106.9389116, 11.2895957],
                [106.9388492, 11.2895632],
                [106.9387829, 11.2895392],
                [106.9387139, 11.2895242],
                [106.9386435, 11.2895184],
                [106.9382639, 11.2895348],
                [106.9381546, 11.2895308],
                [106.9380498, 11.2895003],
                [106.9380011, 11.2894756],
                [106.9379091, 11.2894035],
                [106.9378402, 11.28931],
                [106.9377992, 11.2892017],
                [106.9377596, 11.2886745],
                [106.9377238, 11.2885359],
                [106.9376604, 11.2884071],
                [106.9376139, 11.2883413],
                [106.9374996, 11.2882272],
                [106.9374334, 11.2881803],
                [106.9372871, 11.2881099],
                [106.9372088, 11.2880872],
                [106.9371284, 11.2880733],
                [106.9364521, 11.2880678],
                [106.9363659, 11.2880729],
                [106.9362839, 11.2880466],
                [106.9362481, 11.2880226],
                [106.9362175, 11.2879925],
                [106.9361771, 11.2879209],
                [106.9361652, 11.2878426],
                [106.9361699, 11.2878032],
                [106.9362, 11.2877298],
                [106.9363625, 11.2874985],
                [106.9363953, 11.2874111],
                [106.9364017, 11.2873648],
                [106.9363929, 11.2872686],
                [106.9363768, 11.287221],
                [106.9363232, 11.287136],
                [106.936287, 11.2871006],
                [106.9362, 11.2870485],
                [106.936133, 11.287035],
                [106.936065, 11.2870291],
                [106.9359291, 11.2870406],
                [106.9358632, 11.2870578],
                [106.9357398, 11.2871137],
                [106.9355512, 11.2872517],
                [106.9354657, 11.2873013],
                [106.9353765, 11.2873444],
                [106.935205, 11.2874058],
                [106.9350265, 11.2874431],
                [106.9348199, 11.2874579],
                [106.9344096, 11.2875128],
                [106.9340223, 11.2875964],
                [106.9337639, 11.2876658],
                [106.9336251, 11.287674],
                [106.9334882, 11.2876495],
                [106.9334197, 11.2876239],
                [106.933355, 11.28759],
                [106.9332951, 11.2875483],
                [106.9332643, 11.2875099],
                [106.9332242, 11.2874209],
                [106.933216, 11.2873727],
                [106.9332242, 11.2872764],
                [106.933264, 11.287188],
                [106.933971, 11.2862528],
                [106.9341274, 11.2860835],
                [106.9342571, 11.2858936],
                [106.934311, 11.2857922],
                [106.9343918, 11.2855913],
                [106.9344416, 11.2853936],
                [106.9344565, 11.2852928],
                [106.9344553, 11.2852008],
                [106.9344331, 11.2850182],
                [106.9343797, 11.2848251],
                [106.934289, 11.2846282],
                [106.9339442, 11.2840867],
                [106.9335377, 11.2835237],
                [106.9334638, 11.2833859],
                [106.9334087, 11.2832312],
                [106.9333772, 11.2830619],
                [106.933371, 11.2823879],
                [106.933347, 11.282289],
                [106.9332978, 11.2821995],
                [106.9332187, 11.2821192],
                [106.9331712, 11.2820882],
                [106.9330652, 11.2820477],
                [106.9324449, 11.2819555],
                [106.9323446, 11.2819291],
                [106.9322531, 11.2818812],
                [106.9321663, 11.2818046],
                [106.9321311, 11.2817589],
                [106.932117, 11.2816645],
                [106.9321318, 11.281566],
                [106.9321511, 11.2815175],
                [106.9322115, 11.2814321],
                [106.9328646, 11.2808487],
                [106.9332536, 11.280491],
                [106.9333632, 11.2803699],
                [106.9334418, 11.2802362],
                [106.9334903, 11.2800975],
                [106.9335043, 11.2800254],
                [106.9334957, 11.2799184],
                [106.9334593, 11.2796712],
                [106.9334284, 11.2795321],
                [106.9333447, 11.2792595],
                [106.9332328, 11.2789969],
                [106.9331406, 11.2788169],
                [106.9331065, 11.2787681],
                [106.9330651, 11.2787252],
                [106.9330174, 11.2786892],
                [106.9329645, 11.2786608],
                [106.9329078, 11.278641],
                [106.9328485, 11.27863],
                [106.9327408, 11.2786334],
                [106.9326883, 11.2786462],
                [106.9325914, 11.2786925],
                [106.9325114, 11.2787633],
                [106.9324384, 11.2788611],
                [106.9323428, 11.27896],
                [106.9322252, 11.2790471],
                [106.9320864, 11.2791164],
                [106.9319372, 11.2791601],
                [106.9318601, 11.2791719],
                [106.9318003, 11.2791711],
                [106.9316843, 11.2791432],
                [106.9315847, 11.2790846],
                [106.9315086, 11.2790029],
                [106.9314802, 11.2789549],
                [106.9314458, 11.2788496],
                [106.9314404, 11.2787944],
                [106.9314547, 11.2786421],
                [106.9314889, 11.2784852],
                [106.9315145, 11.2784049],
                [106.9316013, 11.2781925],
                [106.9316185, 11.2780725],
                [106.9316154, 11.2780119],
                [106.9315831, 11.277887],
                [106.9315544, 11.277829],
                [106.9314746, 11.2777267],
                [106.931425, 11.2776844],
                [106.9313363, 11.2776736],
                [106.9312491, 11.2776928],
                [106.9311735, 11.2777396],
                [106.9311193, 11.2778071],
                [106.9310908, 11.2778883],
                [106.9310934, 11.2780621],
                [106.9310773, 11.278242],
                [106.9310322, 11.2784221],
                [106.9308919, 11.2787655],
                [106.9308528, 11.278809],
                [106.9307572, 11.2788769],
                [106.9307028, 11.2788996],
                [106.9305899, 11.2789202],
                [106.9304757, 11.2789083],
                [106.9304211, 11.2788904],
                [106.9302754, 11.2787978],
                [106.9301288, 11.2786794],
                [106.9299872, 11.2785328],
                [106.9298659, 11.2783696],
                [106.9298273, 11.2782994],
                [106.9297661, 11.2781517],
                [106.9297439, 11.278075],
                [106.9297159, 11.27791],
                [106.9297138, 11.2777427],
                [106.929746, 11.2774741],
                [106.9298125, 11.2771063],
                [106.9299048, 11.276744],
                [106.9299654, 11.2765504],
                [106.9301086, 11.2761706],
                [106.9303273, 11.2757057],
                [106.9303671, 11.2756061],
                [106.9304239, 11.2753996],
                [106.9304407, 11.2752939],
                [106.9304506, 11.2750802],
                [106.930429, 11.2748678],
                [106.9304064, 11.2747633],
                [106.9303851, 11.2747036],
                [106.9303203, 11.2745947],
                [106.9302294, 11.2745055],
                [106.9301191, 11.2744422],
                [106.929996, 11.2744082],
                [106.9297144, 11.2743871],
                [106.9295559, 11.2743443],
                [106.9294102, 11.2742697],
                [106.9293442, 11.2742214],
                [106.9292807, 11.2741634],
                [106.9292242, 11.2740988],
                [106.9291755, 11.2740284],
                [106.929135, 11.2739531],
                [106.9290953, 11.2738199],
                [106.9290774, 11.2736822],
                [106.9290768, 11.2736104],
                [106.9290823, 11.2735388],
                [106.9291237, 11.2733385],
                [106.9291287, 11.2732778],
                [106.9291147, 11.273157],
                [106.92907, 11.2730436],
                [106.9289974, 11.2729452],
                [106.9289521, 11.2729036],
                [106.9285744, 11.2726142],
                [106.9283905, 11.2724583],
                [106.9281893, 11.2723244],
                [106.9279736, 11.2722144],
                [106.9277237, 11.2721228],
                [106.927464, 11.2720631],
                [106.9272788, 11.2720379],
                [106.9270918, 11.2720372],
                [106.9269038, 11.2720614],
                [106.9267186, 11.2721112],
                [106.9264697, 11.2722101],
                [106.9263195, 11.2722386],
                [106.9261665, 11.2722384],
                [106.9260907, 11.2722275],
                [106.9259467, 11.2721858],
                [106.9258133, 11.2721184],
                [106.925752, 11.2720757],
                [106.9256951, 11.2720276],
                [106.9255016, 11.2718289],
                [106.9253987, 11.2717356],
                [106.9252049, 11.2715794],
                [106.9251037, 11.2715068],
                [106.9249039, 11.2713904],
                [106.9248086, 11.2713253],
                [106.9246209, 11.2711753],
                [106.9245732, 11.2710973],
                [106.9245484, 11.2710096],
                [106.9245492, 11.2709128],
                [106.9245779, 11.2708202],
                [106.9246296, 11.2707427],
                [106.9246888, 11.2706705],
                [106.9248226, 11.2705489],
                [106.9249722, 11.2704572],
                [106.926257, 11.2698101],
                [106.9264011, 11.2697525],
                [106.9265416, 11.2696866],
                [106.9266777, 11.2696127],
                [106.9268093, 11.269531],
                [106.9270532, 11.2693482],
                [106.9272709, 11.269142],
                [106.9273691, 11.2690238],
                [106.9274094, 11.2689586],
                [106.9274705, 11.2688184],
                [106.9274908, 11.2687448],
                [106.9275105, 11.2685768],
                [106.9275069, 11.2684922],
                [106.9274336, 11.2682969],
                [106.9273887, 11.2681119],
                [106.9273682, 11.2679786],
                [106.927328, 11.2678904],
                [106.9272649, 11.2678264],
                [106.9271915, 11.2677886],
                [106.9271513, 11.2677783],
                [106.9269518, 11.2677623],
                [106.9265177, 11.2677431],
                [106.9262418, 11.2677409],
                [106.9258972, 11.2677489],
                [106.9257604, 11.2677343],
                [106.9256397, 11.2676989],
                [106.9255368, 11.2676492],
                [106.9254879, 11.2675804],
                [106.925472, 11.2675413],
                [106.9254591, 11.2674584],
                [106.9254636, 11.2674088],
                [106.9254772, 11.2673608],
                [106.9255294, 11.2672757],
                [106.9261497, 11.2667956],
                [106.9276974, 11.265333],
                [106.9277913, 11.2652518],
                [106.9278617, 11.2651502],
                [106.9278865, 11.2650937],
                [106.9279137, 11.2649738],
                [106.9279096, 11.2648511],
                [106.9278744, 11.2647332],
                [106.9278076, 11.2646242],
                [106.9277639, 11.264577],
                [106.9276597, 11.2645012],
                [106.927601, 11.2644739],
                [106.9269008, 11.2642896],
                [106.9268364, 11.264283],
                [106.9267078, 11.2642951],
                [106.9265869, 11.26434],
                [106.9265291, 11.2643758],
                [106.926477, 11.2644193],
                [106.9264316, 11.2644695],
                [106.9263938, 11.2645254],
                [106.9263335, 11.2647122],
                [106.9262589, 11.2648563],
                [106.9261542, 11.2649811],
                [106.926032, 11.265076],
                [106.925901, 11.2651411],
                [106.9258312, 11.265164],
                [106.92573, 11.265185],
                [106.9255248, 11.2652107],
                [106.9252615, 11.2652117],
                [106.9251305, 11.2651988],
                [106.9248785, 11.2651435],
                [106.9246358, 11.2650571],
                [106.9244063, 11.264941],
                [106.9241963, 11.264799],
                [106.9240063, 11.2646322],
                [106.9238393, 11.2644432],
                [106.9237652, 11.2643413],
                [106.9237057, 11.2642129],
                [106.9236794, 11.2640743],
                [106.9236884, 11.2639309],
                [106.9237331, 11.2637941],
                [106.9238178, 11.2637254],
                [106.9239247, 11.2636674],
                [106.9240529, 11.2636283],
                [106.9241195, 11.2636191],
                [106.9248558, 11.2636265],
                [106.9249848, 11.2636165],
                [106.925109, 11.263581],
                [106.9251747, 11.2635506],
                [106.9252932, 11.2634687],
                [106.9253898, 11.2633626],
                [106.9254282, 11.2633022],
                [106.9254684, 11.2631128],
                [106.9254847, 11.2629959],
                [106.9255043, 11.2626438],
                [106.9255374, 11.2624127],
                [106.925564, 11.2622989],
                [106.9256339, 11.2620842],
                [106.9257239, 11.2618853],
                [106.9257769, 11.2617896],
                [106.9259155, 11.2616469],
                [106.9260604, 11.2615334],
                [106.9261316, 11.2614878],
                [106.9267693, 11.261132],
                [106.9268332, 11.2611038],
                [106.926948, 11.2610256],
                [106.9270416, 11.2609202],
                [106.9270782, 11.2608586],
                [106.9271065, 11.2607929],
                [106.927126, 11.2607243],
                [106.9271171, 11.2606057],
                [106.9270774, 11.2604876],
                [106.9270052, 11.2603787],
                [106.9269589, 11.2603321],
                [106.926029, 11.2596983],
                [106.9259736, 11.2596419],
                [106.9258791, 11.2595156],
                [106.9258091, 11.2593748],
                [106.9257664, 11.2592275],
                [106.9257505, 11.2590752],
                [106.9257528, 11.2589986],
                [106.9257679, 11.2589269],
                [106.9258143, 11.2587877],
                [106.9258453, 11.2587211],
                [106.9259243, 11.2585924],
                [106.925971, 11.2585329],
                [106.9260223, 11.2584771],
                [106.9260975, 11.2584334],
                [106.9261833, 11.2584172],
                [106.9262299, 11.2584207],
                [106.9263174, 11.2584523],
                [106.9263873, 11.2585128],
                [106.9264472, 11.258604],
                [106.9265365, 11.2586929],
                [106.9266444, 11.2587539],
                [106.9267022, 11.2587729],
                [106.926823, 11.2587882],
                [106.9269437, 11.2587724],
                [106.9270546, 11.2587202],
                [106.9271514, 11.2586458],
                [106.9271931, 11.2586014],
                [106.9272678, 11.2584859],
                [106.9273116, 11.258356],
                [106.9273209, 11.2582881],
                [106.9273138, 11.2581515],
                [106.9272494, 11.2579095],
                [106.9271368, 11.257586],
                [106.9270839, 11.2573787],
                [106.9270458, 11.2571363],
                [106.9270351, 11.2569983],
                [106.9270296, 11.2566616],
                [106.9270151, 11.2565633],
                [106.9269653, 11.2563857],
                [106.9268941, 11.2562307],
                [106.9268498, 11.2561575],
                [106.9268152, 11.2561194],
                [106.9267741, 11.256088],
                [106.9267279, 11.2560645],
                [106.9266781, 11.2560497],
                [106.9266271, 11.256044],
                [106.9265759, 11.2560474],
                [106.9265262, 11.2560598],
                [106.9263592, 11.2561483],
                [106.9262781, 11.2561809],
                [106.9261946, 11.2562071],
                [106.9260226, 11.2562396],
                [106.9259351, 11.2562457],
                [106.9257604, 11.2562375],
                [106.9255891, 11.2562023],
                [106.925481, 11.2561571],
                [106.9254322, 11.2561247],
                [106.92535, 11.2560442],
                [106.9252915, 11.2559473],
                [106.9252719, 11.2558943],
                [106.9252316, 11.255633],
                [106.9251981, 11.2552185],
                [106.9251938, 11.254793],
                [106.9252034, 11.2545756],
                [106.9252464, 11.2541426],
                [106.9253494, 11.2534887],
                [106.9253776, 11.2533786],
                [106.925415, 11.2532712],
                [106.9254611, 11.2531671],
                [106.9255158, 11.2530671],
                [106.9255787, 11.2529719],
                [106.9256493, 11.252882],
                [106.9257272, 11.252798],
                [106.9258118, 11.2527206],
                [106.925901, 11.2526514],
                [106.9259957, 11.2525894],
                [106.9261988, 11.2524888],
                [106.9263058, 11.2524508],
                [106.9264157, 11.2524215],
                [106.9265276, 11.2524009],
                [106.9266408, 11.2523892],
                [106.9274707, 11.2523878],
                [106.9275244, 11.2523815],
                [106.9276257, 11.252345],
                [106.9277108, 11.25228],
                [106.9277664, 11.2522029],
                [106.9277985, 11.2521138],
                [106.9278046, 11.2520196],
                [106.927788, 11.2517051],
                [106.9277698, 11.2515487],
                [106.9277085, 11.2511845],
                [106.9277158, 11.2510711],
                [106.927762, 11.2509059],
                [106.9277751, 11.2508341],
                [106.9277832, 11.2506884],
                [106.9277618, 11.2505192],
                [106.9276897, 11.2502979],
                [106.9276789, 11.2502371],
                [106.9276795, 11.2501236],
                [106.9277031, 11.2500224],
                [106.9277228, 11.2499742],
                [106.9280581, 11.2493849],
                [106.9281183, 11.2492493],
                [106.9281453, 11.2491038],
                [106.9281459, 11.2490297],
                [106.9281211, 11.2488838],
                [106.9280632, 11.2487474],
                [106.9279751, 11.2486276],
                [106.9278614, 11.2485306],
                [106.9277968, 11.2484923],
                [106.9277282, 11.2484616],
                [106.9276012, 11.2484262],
                [106.9275357, 11.2484177],
                [106.9274037, 11.2484195],
                [106.9272251, 11.2484075],
                [106.9270261, 11.2483671],
                [106.9269178, 11.2483324],
                [106.9268128, 11.2482893],
                [106.9267116, 11.248238],
                [106.9265926, 11.2481572],
                [106.9265398, 11.2481086],
                [106.9264501, 11.2479973],
                [106.9264103, 11.247928],
                [106.9263551, 11.2477787],
                [106.9263405, 11.2477004],
                [106.9263348, 11.2476211],
                [106.9263498, 11.2474402],
                [106.9263938, 11.247264],
                [106.9264264, 11.2471788],
                [106.9265116, 11.2470174],
                [106.9265637, 11.2469422],
                [106.9266666, 11.2468205],
                [106.9267178, 11.2467758],
                [106.9268355, 11.2467077],
                [106.9269001, 11.2466853],
                [106.9269671, 11.2466714],
                [106.9270965, 11.2466687],
                [106.9272226, 11.2466968],
                [106.9272821, 11.246722],
                [106.9273895, 11.2467928],
                [106.9274357, 11.2468374],
                [106.9276545, 11.2471009],
                [106.9277529, 11.2471998],
                [106.927857, 11.2472929],
                [106.9280809, 11.2474606],
                [106.9281974, 11.2475331],
                [106.9284419, 11.2476579],
                [106.9285693, 11.2477099],
                [106.9288319, 11.2477921],
                [106.928913, 11.2478064],
                [106.9290774, 11.2478171],
                [106.9292569, 11.2478011],
                [106.9293451, 11.2477823],
                [106.9294314, 11.2477566],
                [106.9301657, 11.2474847],
                [106.9303029, 11.2474056],
                [106.9304374, 11.2472936],
                [106.9305018, 11.2472225],
                [106.9305587, 11.2471455],
                [106.9306076, 11.2470634],
                [106.9307613, 11.2468595],
                [106.9309348, 11.2466714],
                [106.9311573, 11.2464762],
                [106.9312766, 11.2463882],
                [106.9315189, 11.2462348],
                [106.9316147, 11.2461358],
                [106.9316796, 11.2460223],
                [106.9317138, 11.2459033],
                [106.9317202, 11.2458417],
                [106.9317044, 11.2455738],
                [106.93167, 11.2452864],
                [106.931608, 11.2450035],
                [106.9315199, 11.2447295],
                [106.9314662, 11.2445958],
                [106.9313398, 11.2443366],
                [106.9312379, 11.2441814],
                [106.9311333, 11.244067],
                [106.9310308, 11.2439891],
                [106.9309736, 11.2439591],
                [106.9308496, 11.2439183],
                [106.9307183, 11.2439056],
                [106.9306524, 11.2439103],
                [106.9303853, 11.2439696],
                [106.9301787, 11.2439922],
                [106.9299492, 11.2439878],
                [106.9298245, 11.2439724],
                [106.9297013, 11.2439479],
                [106.9295803, 11.2439144],
                [106.9284558, 11.2435823],
                [106.9283381, 11.2435668],
                [106.9282203, 11.2435823],
                [106.9281119, 11.243627],
                [106.9280636, 11.2436591],
                [106.9279817, 11.2437404],
                [106.9278815, 11.243897],
                [106.9277644, 11.2441249],
                [106.9276103, 11.2445349],
                [106.9275188, 11.2446815],
                [106.9273996, 11.2448075],
                [106.9272667, 11.2449027],
                [106.927118, 11.244972],
                [106.9270278, 11.2449832],
                [106.9268462, 11.2449845],
                [106.926657, 11.2449554],
                [106.9265601, 11.2449279],
                [106.9264661, 11.2448921],
                [106.9262008, 11.2447658],
                [106.9257769, 11.2445405],
                [106.9256445, 11.244437],
                [106.925376, 11.2442477],
                [106.9252405, 11.2441617],
                [106.9250756, 11.2440813],
                [106.9249214, 11.2439871],
                [106.9248489, 11.2439354],
                [106.9245914, 11.2437066],
                [106.924385, 11.2435528],
                [106.9242775, 11.2434816],
                [106.9241252, 11.243318],
                [106.9240578, 11.243229],
                [106.9239419, 11.2430388],
                [106.923894, 11.2429384],
                [106.9238154, 11.2427155],
                [106.9237884, 11.2426004],
                [106.9237699, 11.2424838],
                [106.9237519, 11.2422127],
                [106.9237333, 11.2421379],
                [106.9237055, 11.2420659],
                [106.9236687, 11.2419979],
                [106.9235811, 11.241888],
                [106.9234706, 11.2418001],
                [106.9233428, 11.2417388],
                [106.9223504, 11.2414678],
                [106.922126, 11.24142],
                [106.9220118, 11.2414073],
                [106.9217968, 11.2414036],
                [106.9215973, 11.2414241],
                [106.9214988, 11.2414428],
                [106.9213691, 11.2414818],
                [106.9212369, 11.2415119],
                [106.921103, 11.241533],
                [106.9209679, 11.2415448],
                [106.9208322, 11.2415474],
                [106.920698, 11.2415408],
                [106.9205646, 11.2415253],
                [106.9203807, 11.241488],
                [106.920274, 11.2414872],
                [106.9201724, 11.2415191],
                [106.9200853, 11.2415812],
                [106.9200063, 11.2416845],
                [106.9199437, 11.2418173],
                [106.9199249, 11.2418851],
                [106.9198906, 11.2420804],
                [106.9198407, 11.2422094],
                [106.9198036, 11.2422741],
                [106.9196816, 11.2424036],
                [106.9195421, 11.2425148],
                [106.9194687, 11.2425617],
                [106.9193128, 11.2426398],
                [106.9192634, 11.2426512],
                [106.9191623, 11.2426553],
                [106.9190633, 11.2426345],
                [106.9190159, 11.2426148],
                [106.9189307, 11.2425585],
                [106.9188943, 11.2425227],
                [106.9188144, 11.2424132],
                [106.9187619, 11.2422887],
                [106.9187397, 11.2421575],
                [106.9187478, 11.2420264],
                [106.9188265, 11.2417185],
                [106.9188579, 11.2414861],
                [106.9188628, 11.2412692],
                [106.918889, 11.2408468],
                [106.9188773, 11.2407471],
                [106.9188617, 11.2406992],
                [106.918815, 11.2406145],
                [106.9187519, 11.2405463],
                [106.9187146, 11.2405181],
                [106.9185896, 11.2404558],
                [106.9184525, 11.2404267],
                [106.9183862, 11.2404252],
                [106.9182554, 11.2404457],
                [106.9181207, 11.2404993],
                [106.9179865, 11.2405816],
                [106.9178697, 11.2406865],
                [106.9177762, 11.2408075],
                [106.9177061, 11.240943],
                [106.9176651, 11.2410608],
                [106.9176507, 11.2411846],
                [106.9176648, 11.2413134],
                [106.9176837, 11.2413783],
                [106.9177437, 11.2414994],
                [106.9177882, 11.2416358],
                [106.9178526, 11.2419152],
                [106.9178824, 11.2421753],
                [106.9178867, 11.2422937],
                [106.9178778, 11.2425306],
                [106.9178229, 11.2426315],
                [106.9177422, 11.242714],
                [106.917641, 11.2427723],
                [106.9175849, 11.2427909],
                [106.9174674, 11.2428043],
                [106.917207, 11.2427778],
                [106.9170095, 11.2427288],
                [106.9169133, 11.2426938],
                [106.9167298, 11.2426043],
                [106.9163783, 11.2423808],
                [106.9161965, 11.2422787],
                [106.9158497, 11.2421073],
                [106.915686, 11.2420362],
                [106.9154723, 11.2419553],
                [106.9153856, 11.2419061],
                [106.9153161, 11.2418343],
                [106.91527, 11.2417452],
                [106.915158, 11.2413073],
                [106.9151008, 11.2411235],
                [106.9150115, 11.2408859],
                [106.9149395, 11.240786],
                [106.9148445, 11.2407067],
                [106.9147324, 11.240653],
                [106.9146103, 11.2406283],
                [106.9144837, 11.2406343],
                [106.9144211, 11.2406494],
                [106.9143043, 11.2407027],
                [106.9142053, 11.2407835],
                [106.9141307, 11.2408864],
                [106.9139986, 11.2410255],
                [106.9139259, 11.2410883],
                [106.9137686, 11.2411995],
                [106.9136059, 11.241286],
                [106.9134334, 11.2413521],
                [106.913235, 11.241441],
                [106.9130409, 11.2415384],
                [106.9126993, 11.241737],
                [106.9122898, 11.2420184],
                [106.9122256, 11.2420483],
                [106.9120891, 11.2420863],
                [106.9119474, 11.2420939],
                [106.911874, 11.2420857],
                [106.9117324, 11.2420447],
                [106.9116661, 11.2420124],
                [106.9116041, 11.2419729],
                [106.9114855, 11.2418731],
                [106.9112449, 11.2416944],
                [106.911124, 11.2416151],
                [106.9109793, 11.2415419],
                [106.9109132, 11.2414952],
                [106.9107968, 11.2413836],
                [106.910709, 11.241259],
                [106.9106466, 11.2411206],
                [106.9106285, 11.2410344],
                [106.9106113, 11.2408594],
                [106.9106123, 11.2407715],
                [106.9106363, 11.2405831],
                [106.9106612, 11.2404841],
                [106.9107357, 11.2402937],
                [106.9107847, 11.2402037],
                [106.9108649, 11.2399107],
                [106.9108917, 11.2397613],
                [106.9109094, 11.2396105],
                [106.9109182, 11.2394531],
                [106.910917, 11.2392954],
                [106.9109061, 11.2391381],
                [106.9108853, 11.2389817],
                [106.910886, 11.2388945],
                [106.9109126, 11.2387223],
                [106.9109382, 11.2386387],
                [106.9110127, 11.2384806],
                [106.9111108, 11.238345],
                [106.9111687, 11.2382842],
                [106.9113001, 11.2381792],
                [106.9113725, 11.2381358],
                [106.9114485, 11.2380989],
                [106.9120306, 11.2378716],
                [106.9122643, 11.237736],
                [106.9123873, 11.2376795],
                [106.9126422, 11.2375907],
                [106.9128536, 11.2375462],
                [106.9129317, 11.2375251],
                [106.9130069, 11.2374954],
                [106.913116, 11.2374327],
                [106.9132121, 11.237352],
                [106.9132386, 11.2372583],
                [106.9132349, 11.2371612],
                [106.9132005, 11.2370684],
                [106.9131386, 11.2369904],
                [106.9130991, 11.2369595],
                [106.912846, 11.2368298],
                [106.9126344, 11.2367318],
                [106.9122022, 11.2365562],
                [106.9120576, 11.2364885],
                [106.9119171, 11.236413],
                [106.9117811, 11.2363299],
                [106.911747, 11.2363017],
                [106.9116927, 11.2362325],
                [106.9116736, 11.2361929],
                [106.9116528, 11.2360984],
                [106.9116547, 11.2360452],
                [106.9116658, 11.2359932],
                [106.9116859, 11.2359521],
                [106.9117414, 11.2358791],
                [106.9117758, 11.2358485],
                [106.9118615, 11.2357986],
                [106.9119087, 11.2357828],
                [106.9120076, 11.235771],
                [106.9126984, 11.2358551],
                [106.9130407, 11.2358861],
                [106.9133837, 11.2359092],
                [106.9140707, 11.2359313],
                [106.9176164, 11.2357065],
                [106.9179046, 11.2356722],
                [106.9181299, 11.2356301],
                [106.9181814, 11.2356129],
                [106.9182735, 11.2355567],
                [106.9183121, 11.235519],
                [106.9183698, 11.2354289],
                [106.9183877, 11.2353785],
                [106.9183993, 11.2352727],
                [106.9183928, 11.2352197],
                [106.9183533, 11.2351149],
                [106.9182823, 11.2350274],
                [106.9181871, 11.2349661],
                [106.9180771, 11.234937],
                [106.917513, 11.2349356],
                [106.917255, 11.2348918],
                [106.9170044, 11.2348178],
                [106.9168829, 11.2347698],
                [106.9166519, 11.2346536],
                [106.9164383, 11.2345122],
                [106.9162435, 11.234347],
                [106.916154, 11.2342561],
                [106.915877, 11.2339307],
                [106.9157553, 11.2338079],
                [106.915492, 11.2335799],
                [106.915351, 11.2334755],
                [106.9150534, 11.2332887],
                [106.9137286, 11.2325637],
                [106.9136536, 11.2325098],
                [106.9135844, 11.2324487],
                [106.9134786, 11.2323249],
                [106.9134059, 11.2322035],
                [106.913377, 11.232139],
                [106.9132724, 11.2317602],
                [106.9132748, 11.2315107],
                [106.9133046, 11.231263],
                [106.9133334, 11.2311251],
                [106.9134163, 11.2308558],
                [106.913532, 11.2305983],
                [106.9136788, 11.2303566],
                [106.9137724, 11.2303985],
                [106.9138561, 11.2304348],
                [106.9139697, 11.2304677],
                [106.9140855, 11.2304918],
                [106.9142029, 11.230507],
                [106.9143212, 11.2305132],
                [106.9144374, 11.2305104],
                [106.914553, 11.2304989],
                [106.9146674, 11.2304788],
                [106.9147799, 11.2304502],
                [106.9148898, 11.2304132],
                [106.9149686, 11.2303679],
                [106.915042, 11.2303146],
                [106.9151091, 11.2302538],
                [106.9151691, 11.2301863],
                [106.9152214, 11.2301129],
                [106.9152653, 11.2300344],
                [106.9152987, 11.2299562],
                [106.9153238, 11.229875],
                [106.9153404, 11.2297918],
                [106.9153482, 11.2297074],
                [106.9153472, 11.2296226],
                [106.9153374, 11.2295384],
                [106.9153216, 11.2294135],
                [106.9152972, 11.2292817],
                [106.9152567, 11.2291463],
                [106.9152137, 11.2289944],
                [106.9151629, 11.2288448],
                [106.9151044, 11.2286979],
                [106.9150418, 11.228561],
                [106.9149725, 11.2284273],
                [106.9148967, 11.228297],
                [106.9148146, 11.2281704],
                [106.9147264, 11.2280479],
                [106.9146323, 11.2279297],
                [106.9145469, 11.2278392],
                [106.9144673, 11.2277438],
                [106.914394, 11.2276437],
                [106.9143271, 11.2275393],
                [106.914267, 11.227431],
                [106.9142139, 11.2273193],
                [106.9141705, 11.2272115],
                [106.9141336, 11.2271015],
                [106.9141033, 11.2269895],
                [106.9140797, 11.226876],
                [106.9140629, 11.2267613],
                [106.9140556, 11.2266537],
                [106.914077, 11.226536],
                [106.9141085, 11.2264279],
                [106.9141473, 11.2263222],
                [106.9141932, 11.2262193],
                [106.9142461, 11.2261196],
                [106.9143136, 11.2260118],
                [106.9143893, 11.2259092],
                [106.9144726, 11.2258126],
                [106.9145631, 11.2257223],
                [106.9146604, 11.2256391],
                [106.9147637, 11.2255633],
                [106.9148726, 11.2254953],
                [106.9149864, 11.2254356],
                [106.9152858, 11.225273],
                [106.9156094, 11.22508],
                [106.9158447, 11.2249357],
                [106.91607, 11.2247831],
                [106.9164321, 11.224524],
                [106.9168612, 11.2242359],
                [106.9170046, 11.2241319],
                [106.9171189, 11.2240426],
                [106.9172278, 11.2239471],
                [106.9173312, 11.2238457],
                [106.9174285, 11.2237388],
                [106.9175197, 11.2236267],
                [106.9176042, 11.2235098],
                [106.917684, 11.2233849],
                [106.9177563, 11.2232558],
                [106.9178209, 11.2231227],
                [106.9178775, 11.2229862],
                [106.9179261, 11.2228468],
                [106.917988, 11.2226595],
                [106.9180417, 11.2224697],
                [106.9180868, 11.2222778],
                [106.9181234, 11.2220841],
                [106.9181514, 11.2218891],
                [106.9181704, 11.221696],
                [106.9181811, 11.2215023],
                [106.9181832, 11.2213083],
                [106.9181769, 11.2211144],
                [106.9181621, 11.2209209],
                [106.9181588, 11.2207387],
                [106.9181481, 11.2205567],
                [106.9181303, 11.2203753],
                [106.9181051, 11.2201946],
                [106.9180728, 11.2200151],
                [106.9180334, 11.219837],
                [106.9179865, 11.2196593],
                [106.9179324, 11.2194836],
                [106.9178714, 11.2193101],
                [106.9178034, 11.2191391],
                [106.9177286, 11.2189709],
                [106.9176471, 11.2188057],
                [106.9174962, 11.2184969],
                [106.9173531, 11.2181847],
                [106.917218, 11.217869],
                [106.9170838, 11.217532],
                [106.9169586, 11.2171917],
                [106.9168424, 11.2168482],
                [106.9167554, 11.2166015],
                [106.9166602, 11.2163577],
                [106.9165569, 11.2161171],
                [106.9164455, 11.21588],
                [106.9163371, 11.2156671],
                [106.9162222, 11.2154574],
                [106.9161009, 11.2152512],
                [106.9159734, 11.2150486],
                [106.9159012, 11.2149398],
                [106.9158224, 11.2148353],
                [106.9157374, 11.2147358],
                [106.9156463, 11.2146415],
                [106.9155497, 11.2145527],
                [106.9154477, 11.2144698],
                [106.9153372, 11.2143907],
                [106.9152219, 11.2143185],
                [106.9151022, 11.2142534],
                [106.9149787, 11.2141959],
                [106.9148517, 11.214146],
                [106.9147217, 11.2141039],
                [106.9145894, 11.2140699],
                [106.9143954, 11.2140416],
                [106.9142006, 11.21402],
                [106.9140051, 11.2140053],
                [106.9138092, 11.2139973],
                [106.9136131, 11.2139962],
                [106.9134079, 11.2140024],
                [106.9132031, 11.214016],
                [106.912999, 11.2140371],
                [106.9127958, 11.2140656],
                [106.9125777, 11.2140936],
                [106.9123816, 11.2141065],
                [106.9121694, 11.2141029],
                [106.9119575, 11.2140907],
                [106.9117463, 11.2140699],
                [106.9115455, 11.214042],
                [106.9113461, 11.2140064],
                [106.9111483, 11.213963],
                [106.9109523, 11.213912],
                [106.9107799, 11.2138107],
                [106.9106114, 11.2137031],
                [106.910447, 11.2135895],
                [106.9102871, 11.21347],
                [106.910131, 11.2133441],
                [106.9099798, 11.2132125],
                [106.9098337, 11.2130755],
                [106.9096929, 11.2129333],
                [106.9095576, 11.212786],
                [106.9093828, 11.2125316],
                [106.9092004, 11.2122824],
                [106.9090104, 11.2120388],
                [106.9088103, 11.2117976],
                [106.9086028, 11.2115625],
                [106.9083881, 11.2113337],
                [106.9082854, 11.2112534],
                [106.9081772, 11.2111803],
                [106.9080642, 11.2111146],
                [106.9079469, 11.2110567],
                [106.9078258, 11.2110069],
                [106.9077015, 11.2109653],
                [106.9075717, 11.2109316],
                [106.9074399, 11.2109069],
                [106.9073066, 11.2108913],
                [106.9071725, 11.210885],
                [106.9070384, 11.2108879],
                [106.9069047, 11.2109],
                [106.9064876, 11.2109324],
                [106.90607, 11.2109576],
                [106.9056519, 11.2109757],
                [106.9052337, 11.2109865],
                [106.9048153, 11.2109902],
                [106.9043969, 11.2109866],
                [106.9039786, 11.2109758],
                [106.9036099, 11.2109604],
                [106.9032415, 11.2109393],
                [106.9029111, 11.2109232],
                [106.9027314, 11.2108995],
                [106.9025906, 11.2108766],
                [106.9024518, 11.2108441],
                [106.9023156, 11.2108022],
                [106.9021845, 11.2107518],
                [106.9020573, 11.2106928],
                [106.9019345, 11.2106252],
                [106.9018167, 11.2105496],
                [106.9017386, 11.2104634],
                [106.9016668, 11.2103721],
                [106.9016015, 11.2102761],
                [106.9015431, 11.210176],
                [106.9014923, 11.210073],
                [106.9014488, 11.2099668],
                [106.9014128, 11.209858],
                [106.9013845, 11.209747],
                [106.901364, 11.2096344],
                [106.9013513, 11.2095207],
                [106.9013467, 11.2094063],
                [106.901342, 11.2092604],
                [106.9013415, 11.2090936],
                [106.9013427, 11.208895],
                [106.9013534, 11.2086967],
                [106.9013738, 11.2084991],
                [106.9014037, 11.2083027],
                [106.9014365, 11.2081372],
                [106.9014761, 11.207973],
                [106.9015224, 11.2078106],
                [106.9015753, 11.2076502],
                [106.9016331, 11.2075041],
                [106.9016987, 11.2073612],
                [106.9017719, 11.2072219],
                [106.9018524, 11.2070866],
                [106.9019401, 11.2069556],
                [106.9020255, 11.206841],
                [106.9021163, 11.2067305],
                [106.9022125, 11.2066244],
                [106.9023136, 11.2065229],
                [106.9024196, 11.2064262],
                [106.9025302, 11.2063347],
                [106.902662, 11.2062284],
                [106.9027991, 11.2061287],
                [106.9029411, 11.2060359],
                [106.9030876, 11.2059501],
                [106.9032383, 11.2058716],
                [106.9033969, 11.2057988],
                [106.9035592, 11.2057341],
                [106.9037246, 11.2056776],
                [106.9038927, 11.2056295],
                [106.9041323, 11.2055642],
                [106.9043697, 11.2054915],
                [106.9046046, 11.2054115],
                [106.9048369, 11.2053243],
                [106.9050363, 11.2052428],
                [106.9052335, 11.2051559],
                [106.9054281, 11.2050638],
                [106.9056201, 11.2049665],
                [106.9057598, 11.2048896],
                [106.9058946, 11.2048045],
                [106.9060238, 11.2047115],
                [106.906147, 11.2046111],
                [106.9062638, 11.2045034],
                [106.9063674, 11.2043961],
                [106.9064645, 11.204283],
                [106.9065549, 11.2041648],
                [106.9066383, 11.2040417],
                [106.9067144, 11.2039141],
                [106.9068211, 11.2037102],
                [106.9069212, 11.2035031],
                [106.9070148, 11.2032931],
                [106.9070996, 11.2030861],
                [106.9071779, 11.2028766],
                [106.9072499, 11.2026649],
                [106.9073152, 11.2024512],
                [106.9073848, 11.2021653],
                [106.9074457, 11.2018776],
                [106.9074976, 11.2015882],
                [106.9075333, 11.2013524],
                [106.907563, 11.2011159],
                [106.9075869, 11.2008787],
                [106.9076049, 11.200641],
                [106.9076071, 11.2003566],
                [106.9075999, 11.2000724],
                [106.9075835, 11.1997885],
                [106.9075641, 11.1995669],
                [106.9075391, 11.1993458],
                [106.9075084, 11.1991254],
                [106.9074866, 11.1989261],
                [106.9074561, 11.1987278],
                [106.9074168, 11.198531],
                [106.9073689, 11.1983361],
                [106.9073138, 11.1981479],
                [106.9072507, 11.1979622],
                [106.9071796, 11.1977792],
                [106.9071007, 11.1975994],
                [106.9070213, 11.1974428],
                [106.9069338, 11.1972903],
                [106.9068386, 11.1971424],
                [106.9067359, 11.1969995],
                [106.9066232, 11.1968586],
                [106.9065031, 11.1967237],
                [106.9063761, 11.1965952],
                [106.9062424, 11.1964732],
                [106.9061625, 11.19638],
                [106.906088, 11.1962825],
                [106.9060191, 11.1961812],
                [106.9059561, 11.1960763],
                [106.905899, 11.1959681],
                [106.9058437, 11.1958461],
                [106.9057959, 11.1957211],
                [106.905756, 11.1955935],
                [106.905724, 11.1954638],
                [106.9057002, 11.1953324],
                [106.9056737, 11.1951945],
                [106.9056525, 11.1950654],
                [106.905629, 11.1949504],
                [106.9056139, 11.1948235],
                [106.9056073, 11.194696],
                [106.9056094, 11.1945683],
                [106.9056206, 11.1944355],
                [106.9056411, 11.1943038],
                [106.9056708, 11.1941738],
                [106.9057095, 11.1940461],
                [106.905757, 11.1939213],
                [106.9058159, 11.1937763],
                [106.9058725, 11.1936442],
                [106.9059406, 11.1934919],
                [106.9060175, 11.1933437],
                [106.9061029, 11.1932001],
                [106.9061904, 11.1930699],
                [106.9062848, 11.1929444],
                [106.906386, 11.1928241],
                [106.9064935, 11.1927092],
                [106.9066071, 11.1926002],
                [106.9067555, 11.1924939],
                [106.9069094, 11.1923955],
                [106.9070685, 11.1923055],
                [106.9072067, 11.1922359],
                [106.907348, 11.1921726],
                [106.907492, 11.1921157],
                [106.9076386, 11.1920652],
                [106.9077873, 11.1920213],
                [106.9079473, 11.1919998],
                [106.9081083, 11.1919873],
                [106.9082697, 11.191984],
                [106.908431, 11.1919897],
                [106.9085819, 11.1920034],
                [106.9087318, 11.192025],
                [106.9088803, 11.1920546],
                [106.909027, 11.192092],
                [106.9091713, 11.1921371],
                [106.9094839, 11.1922584],
                [106.9097987, 11.1923742],
                [106.9101155, 11.1924844],
                [106.9104689, 11.1926],
                [106.9108247, 11.1927088],
                [106.9111825, 11.1928106],
                [106.9115424, 11.1929133],
                [106.911815, 11.1929836],
                [106.9120899, 11.1930533],
                [106.912367, 11.1931144],
                [106.9126459, 11.1931668],
                [106.9129264, 11.1932106],
                [106.9131749, 11.1932419],
                [106.9134241, 11.1932664],
                [106.913674, 11.193284],
                [106.9138679, 11.193279],
                [106.9140556, 11.1932579],
                [106.9142346, 11.1932164],
                [106.9144528, 11.1931515],
                [106.9146752, 11.1930843],
                [106.9149811, 11.1930139],
                [106.9152889, 11.1929519],
                [106.9155983, 11.1928981],
                [106.915909, 11.1928527],
                [106.9161521, 11.1928239],
                [106.916396, 11.1928037],
                [106.9166406, 11.1927923],
                [106.9168854, 11.1927896],
                [106.9171249, 11.1927955],
                [106.9173641, 11.1928097],
                [106.9176027, 11.1928323],
                [106.9178402, 11.1928633],
                [106.917995, 11.1929112],
                [106.9181519, 11.1929517],
                [106.9183106, 11.1929849],
                [106.9184707, 11.1930106],
                [106.9186319, 11.1930287],
                [106.9187938, 11.1930393],
                [106.918956, 11.1930422],
                [106.9191155, 11.1930377],
                [106.9192747, 11.1930258],
                [106.919433, 11.1930065],
                [106.9195903, 11.19298],
                [106.9197461, 11.1929463],
                [106.9198787, 11.1929054],
                [106.9200252, 11.1928434],
                [106.9201464, 11.1927746],
                [106.9202635, 11.1926992],
                [106.9203761, 11.1926175],
                [106.9204838, 11.1925297],
                [106.9205864, 11.1924361],
                [106.9206834, 11.192337],
                [106.9207726, 11.1922351],
                [106.920856, 11.1921284],
                [106.9209333, 11.1920175],
                [106.9210042, 11.1919025],
                [106.9210686, 11.1917839],
                [106.9211262, 11.191662],
                [106.9211769, 11.1915372],
                [106.9212211, 11.1913468],
                [106.9212591, 11.1911552],
                [106.9212909, 11.1909625],
                [106.9213164, 11.1907688],
                [106.9213371, 11.1905548],
                [106.9213502, 11.1903401],
                [106.9213556, 11.1901251],
                [106.9213532, 11.1899101],
                [106.92137, 11.1896216],
                [106.9213781, 11.1894795],
                [106.92139, 11.1893423],
                [106.9214156, 11.189148],
                [106.9214411, 11.1889719],
                [106.9214881, 11.1887445],
                [106.9215129, 11.1886215],
                [106.9215304, 11.188536],
                [106.9215551, 11.188449],
                [106.921583, 11.1883533],
                [106.9216179, 11.1882599],
                [106.9216597, 11.1881692],
                [106.9217137, 11.1880725],
                [106.9217754, 11.1879805],
                [106.9218445, 11.1878936],
                [106.9219206, 11.1878125],
                [106.922003, 11.1877377],
                [106.9221302, 11.1876229],
                [106.9222615, 11.1875127],
                [106.9223969, 11.1874073],
                [106.9225361, 11.1873068],
                [106.9226789, 11.1872114],
                [106.9228522, 11.1871054],
                [106.92303, 11.1870067],
                [106.923212, 11.1869157],
                [106.9233602, 11.1868509],
                [106.9234966, 11.1868042],
                [106.9235978, 11.1867852],
                [106.9237003, 11.1867757],
                [106.9238033, 11.1867757],
                [106.9239058, 11.1867852],
                [106.924007, 11.1868042],
                [106.9241059, 11.1868325],
                [106.9241989, 11.1868687],
                [106.9242882, 11.186913],
                [106.924373, 11.1869653],
                [106.9244525, 11.1870249],
                [106.924526, 11.1870915],
                [106.9245931, 11.1871644],
                [106.9246799, 11.1872693],
                [106.9248118, 11.1874636],
                [106.9249772, 11.1876795],
                [106.9251492, 11.1878904],
                [106.9253276, 11.1880961],
                [106.9255121, 11.1882965],
                [106.9257028, 11.1884914],
                [106.9258992, 11.1886805],
                [106.9261014, 11.1888638],
                [106.9262957, 11.18903],
                [106.9264947, 11.1891907],
                [106.9266553, 11.189309],
                [106.9268149, 11.1894263],
                [106.9269361, 11.1895],
                [106.9270616, 11.1895665],
                [106.9271908, 11.1896258],
                [106.9273233, 11.1896776],
                [106.9274586, 11.1897216],
                [106.9276067, 11.1897602],
                [106.927757, 11.1897896],
                [106.9279089, 11.1898097],
                [106.9282955, 11.18984],
                [106.9285144, 11.1898544],
                [106.9287394, 11.1898692],
                [106.9290024, 11.1898817],
                [106.9292657, 11.1898867],
                [106.929529, 11.1898842],
                [106.9297922, 11.1898742],
                [106.9300819, 11.1898545],
                [106.9303708, 11.1898256],
                [106.9306586, 11.1897877],
                [106.9309451, 11.1897407],
                [106.9312298, 11.1896848],
                [106.9314829, 11.1896426],
                [106.9317351, 11.1895953],
                [106.9319862, 11.1895427],
                [106.9322474, 11.1894809],
                [106.9323995, 11.1894486],
                [106.932502, 11.1894218],
                [106.9326031, 11.1893901],
                [106.9327388, 11.1893386],
                [106.9328706, 11.1892783],
                [106.9329979, 11.1892092],
                [106.9331201, 11.1891318],
                [106.9332367, 11.1890464],
                [106.9333631, 11.1889692],
                [106.9335097, 11.1888375],
                [106.9336504, 11.188702],
                [106.9338189, 11.1885409],
                [106.9339831, 11.1883756],
                [106.9341427, 11.188206],
                [106.9343209, 11.1880056],
                [106.9344927, 11.1878],
                [106.9346581, 11.1875893],
                [106.9348168, 11.1873737],
                [106.9349688, 11.1871535],
                [106.9351887, 11.1868366],
                [106.9354016, 11.1865152],
                [106.9356072, 11.1861892],
                [106.9358057, 11.185859],
                [106.9359635, 11.185584],
                [106.9361162, 11.1853064],
                [106.9362639, 11.1850261],
                [106.9364065, 11.1847433],
                [106.9365455, 11.184421],
                [106.9366777, 11.184096],
                [106.936803, 11.1837683],
                [106.9369215, 11.1834382],
                [106.9370404, 11.1830828],
                [106.9371512, 11.1827249],
                [106.9372353, 11.1824436],
                [106.9373077, 11.1822515],
                [106.9373745, 11.1821076],
                [106.9375035, 11.1818546],
                [106.9376409, 11.1816059],
                [106.9377867, 11.1813619],
                [106.9379407, 11.1811227],
                [106.9381011, 11.1808908],
                [106.9382691, 11.1806643],
                [106.9384124, 11.1804643],
                [106.9385737, 11.1802491],
                [106.9387227, 11.180042],
                [106.9389007, 11.1798189],
                [106.9390487, 11.179622],
                [106.9392039, 11.1794305],
                [106.9393662, 11.1792447],
                [106.9395353, 11.1790649],
                [106.939711, 11.1788913],
                [106.9398962, 11.1787213],
                [106.9400879, 11.1785582],
                [106.9402856, 11.1784022],
                [106.940489, 11.1782535],
                [106.9405928, 11.1781819],
                [106.940698, 11.1781124],
                [106.9409039, 11.1779854],
                [106.9411149, 11.1778666],
                [106.9413306, 11.1777564],
                [106.9415507, 11.1776548],
                [106.9417748, 11.177562],
                [106.9420025, 11.1774782],
                [106.9421639, 11.1774513],
                [106.9423265, 11.1774334],
                [106.9424899, 11.1774244],
                [106.9426536, 11.1774245],
                [106.942817, 11.1774336],
                [106.9429612, 11.1774491],
                [106.9431045, 11.1774717],
                [106.9432465, 11.1775012],
                [106.9433868, 11.1775377],
                [106.9435251, 11.1775809],
                [106.9436711, 11.1775982],
                [106.9438179, 11.1776086],
                [106.9439649, 11.1776121],
                [106.944112, 11.1776086],
                [106.9442588, 11.1775982],
                [106.9444048, 11.1775809],
                [106.9445526, 11.1775562],
                [106.9446989, 11.1775244],
                [106.9448435, 11.1774856],
                [106.9449859, 11.1774399],
                [106.9451259, 11.1773875],
                [106.9452631, 11.1773283],
                [106.9453592, 11.177242],
                [106.9454482, 11.1771488],
                [106.9455297, 11.1770492],
                [106.9456032, 11.1769437],
                [106.9456682, 11.176833],
                [106.9457245, 11.1767178],
                [106.9457716, 11.1765986],
                [106.9458094, 11.1764761],
                [106.9458374, 11.1763512],
                [106.9458557, 11.1762245],
                [106.945864, 11.1760968],
                [106.9458599, 11.1759042],
                [106.9458496, 11.1757118],
                [106.945833, 11.1755198],
                [106.9458103, 11.1753285],
                [106.9457817, 11.17514],
                [106.9457472, 11.1749525],
                [106.9457066, 11.1747662],
                [106.9456601, 11.1745812],
                [106.9455898, 11.1744083],
                [106.9455126, 11.1742382],
                [106.9454287, 11.1740712],
                [106.9453382, 11.1739075],
                [106.9452325, 11.1737336],
                [106.9451194, 11.1735643],
                [106.944999, 11.1733998],
                [106.9448716, 11.1732404],
                [106.9447374, 11.1730866],
                [106.9440749, 11.1724155],
                [106.9434178, 11.1717393],
                [106.9428849, 11.1711831],
                [106.9427129, 11.1709926],
                [106.9425365, 11.170806],
                [106.9423556, 11.1706236],
                [106.9421188, 11.1703971],
                [106.9418752, 11.1701776],
                [106.9416251, 11.1699653],
                [106.9415559, 11.1699204],
                [106.94149, 11.1698712],
                [106.9414274, 11.1698178],
                [106.9413686, 11.1697605],
                [106.941306, 11.1696903],
                [106.941249, 11.1696157],
                [106.9411979, 11.1695372],
                [106.9411529, 11.169455],
                [106.9411144, 11.1693698],
                [106.9410825, 11.169282],
                [106.9410574, 11.1691921],
                [106.9410413, 11.169077],
                [106.9410321, 11.1689611],
                [106.94103, 11.1688449],
                [106.9410348, 11.1687288],
                [106.9410467, 11.1686132],
                [106.9410683, 11.1684845],
                [106.9410986, 11.1683575],
                [106.9411374, 11.1682327],
                [106.9411845, 11.1681108],
                [106.9412398, 11.1679922],
                [106.9412993, 11.1678843],
                [106.9413658, 11.1677805],
                [106.941439, 11.1676811],
                [106.9415188, 11.1675866],
                [106.9416046, 11.1674975],
                [106.9416947, 11.1674153],
                [106.94179, 11.167339],
                [106.94189, 11.1672688],
                [106.9419945, 11.1672051],
                [106.9421029, 11.1671481],
                [106.9422148, 11.1670981],
                [106.9423298, 11.1670552],
                [106.9432831, 11.1667248],
                [106.9435851, 11.1666127],
                [106.9438898, 11.1665081],
                [106.9441686, 11.1664197],
                [106.9444494, 11.1663375],
                [106.9447321, 11.1662617],
                [106.9449453, 11.1662199],
                [106.9451196, 11.1662081],
                [106.9453129, 11.1662143],
                [106.9456098, 11.1662319],
                [106.9459069, 11.1662449],
                [106.946243, 11.1662542],
                [106.9465792, 11.1662577],
                [106.9469154, 11.1662554],
                [106.9471317, 11.1662397],
                [106.9473473, 11.166217],
                [106.9475621, 11.1661875],
                [106.9477759, 11.1661512],
                [106.9479883, 11.1661081],
                [106.9482085, 11.1660559],
                [106.9484268, 11.1659964],
                [106.9486429, 11.1659297],
                [106.9488566, 11.1658558],
                [106.9490676, 11.1657749],
                [106.9492757, 11.1656871],
                [106.9499983, 11.1653416],
                [106.9507241, 11.1650029],
                [106.951388, 11.1647001],
                [106.9520545, 11.1644029],
                [106.9522212, 11.1643481],
                [106.9523847, 11.1642846],
                [106.9525445, 11.1642127],
                [106.9527002, 11.1641325],
                [106.9528513, 11.1640444],
                [106.9529974, 11.1639485],
                [106.9531381, 11.163845],
                [106.9532678, 11.1637389],
                [106.9533919, 11.1636264],
                [106.9535099, 11.1635078],
                [106.9536216, 11.1633834],
                [106.9537266, 11.1632536],
                [106.9538247, 11.1631188],
                [106.9539888, 11.1627956],
                [106.9541462, 11.1624693],
                [106.9542968, 11.1621399],
                [106.9544289, 11.1618354],
                [106.9545552, 11.1615286],
                [106.9546757, 11.1612195],
                [106.954785, 11.160961],
                [106.9549063, 11.1606739],
                [106.9550158, 11.1604365],
                [106.9551189, 11.1601963],
                [106.9552154, 11.1599536],
                [106.9553053, 11.1597084],
                [106.955387, 11.1594657],
                [106.9554623, 11.1592211],
                [106.955531, 11.1589746],
                [106.9555931, 11.1587265],
                [106.9556486, 11.1584768],
                [106.9556746, 11.1582314],
                [106.9556909, 11.1579851],
                [106.9556973, 11.1577384],
                [106.955694, 11.1574917],
                [106.9556808, 11.1572453],
                [106.9556601, 11.1570193],
                [106.9556312, 11.1567943],
                [106.9555941, 11.1565704],
                [106.9555488, 11.1563479],
                [106.9554955, 11.1561272],
                [106.9554502, 11.1558769],
                [106.9554204, 11.1556223],
                [106.9554146, 11.1553358],
                [106.9554167, 11.1550493],
                [106.9554268, 11.1547629],
                [106.9554448, 11.1544769],
                [106.9554692, 11.1542057],
                [106.9555008, 11.1539351],
                [106.9555394, 11.1536655],
                [106.9555851, 11.1533969],
                [106.9556299, 11.1532059],
                [106.9556728, 11.1530953],
                [106.9557425, 11.152987],
                [106.9558543, 11.1528498],
                [106.9559731, 11.1527183],
                [106.9560984, 11.1525928],
                [106.9562299, 11.1524736],
                [106.9563675, 11.1523611],
                [106.9564831, 11.152275],
                [106.9566023, 11.1521936],
                [106.9567247, 11.152117],
                [106.9568503, 11.1520454],
                [106.9570691, 11.1519236],
                [106.9572914, 11.1518079],
                [106.9575169, 11.1516983],
                [106.9577454, 11.151595],
                [106.9579768, 11.151498],
                [106.958229, 11.1514007],
                [106.9584841, 11.1513109],
                [106.9587418, 11.1512287],
                [106.959002, 11.1511542],
                [106.9592643, 11.1510875],
                [106.9599623, 11.1509483],
                [106.960659, 11.1508033],
                [106.9613369, 11.1506563],
                [106.9620135, 11.1505038],
                [106.9626888, 11.1503457],
                [106.9633627, 11.1501822],
                [106.9635258, 11.1501517],
                [106.9636872, 11.1501137],
                [106.9638466, 11.1500682],
                [106.9640037, 11.1500154],
                [106.964158, 11.1499552],
                [106.9643092, 11.149888],
                [106.964457, 11.1498138],
                [106.9645941, 11.1497369],
                [106.9647274, 11.1496541],
                [106.9648568, 11.1495654],
                [106.964982, 11.1494711],
                [106.9651027, 11.1493714],
                [106.9652188, 11.1492664],
                [106.9653167, 11.1491658],
                [106.9654079, 11.1490593],
                [106.9654919, 11.1489473],
                [106.9655685, 11.1488302],
                [106.9656372, 11.1487085],
                [106.9656987, 11.1485807],
                [106.9657517, 11.1484492],
                [106.9657958, 11.1483146],
                [106.9658308, 11.1481775],
                [106.9658567, 11.1480384],
                [106.9658732, 11.147898],
                [106.9659078, 11.1476566],
                [106.9659498, 11.1474164],
                [106.9659991, 11.1471774],
                [106.9660556, 11.1469401],
                [106.9661137, 11.1467243],
                [106.9661778, 11.1465102],
                [106.9662479, 11.1462978],
                [106.9663131, 11.1461138],
                [106.9663958, 11.1459363],
                [106.9664609, 11.1457822],
                [106.9665193, 11.1456254],
                [106.9665706, 11.1454664],
                [106.9666122, 11.1453158],
                [106.9666476, 11.1451637],
                [106.9666766, 11.1450103],
                [106.966694, 11.1448769],
                [106.9666822, 11.1447207],
                [106.9666585, 11.1445865],
                [106.9666284, 11.1444536],
                [106.9665919, 11.1443222],
                [106.9665491, 11.1441927],
                [106.9664954, 11.1440537],
                [106.9664345, 11.1439177],
                [106.9663665, 11.143785],
                [106.9662917, 11.1436558],
                [106.9662075, 11.143523],
                [106.9661157, 11.1433952],
                [106.9660167, 11.1432727],
                [106.9659107, 11.1431559],
                [106.9657981, 11.1430453],
                [106.9656746, 11.1429372],
                [106.9655448, 11.1428366],
                [106.9654091, 11.1427436],
                [106.9652681, 11.1426587],
                [106.9651222, 11.1425821],
                [106.9648862, 11.1425184],
                [106.9646482, 11.1424621],
                [106.9644085, 11.1424131],
                [106.9641673, 11.1423716],
                [106.9639306, 11.1423382],
                [106.9636929, 11.1423121],
                [106.9634545, 11.1422931],
                [106.9632156, 11.1422814],
                [106.9629067, 11.142261],
                [106.9622575, 11.142226],
                [106.9615388, 11.1421716],
                [106.9607232, 11.1421054],
                [106.9604524, 11.1420726],
                [106.9601805, 11.142049],
                [106.959908, 11.1420347],
                [106.9596406, 11.1420298],
                [106.9593731, 11.1420339],
                [106.9591059, 11.142047],
                [106.9588394, 11.1420691],
                [106.9585738, 11.1421001],
                [106.9583094, 11.14214],
                [106.9579609, 11.1421602],
                [106.957612, 11.1421709],
                [106.9572629, 11.1421721],
                [106.9569139, 11.1421639],
                [106.9565653, 11.1421461],
                [106.9562173, 11.1421189],
                [106.955929, 11.1420892],
                [106.9556414, 11.1420529],
                [106.9553548, 11.1420102],
                [106.9550693, 11.141961],
                [106.9549583, 11.1419176],
                [106.9548508, 11.1418662],
                [106.9547476, 11.1418071],
                [106.9546491, 11.1417407],
                [106.9545559, 11.1416673],
                [106.9544685, 11.1415873],
                [106.9543856, 11.1414992],
                [106.9543098, 11.1414052],
                [106.9542414, 11.1413058],
                [106.954181, 11.1412016],
                [106.9541287, 11.1410932],
                [106.954085, 11.1409812],
                [106.9540501, 11.1408663],
                [106.9540022, 11.1407121],
                [106.9539631, 11.1405556],
                [106.953933, 11.1403972],
                [106.9539119, 11.1402375],
                [106.9538998, 11.1400768],
                [106.9538969, 11.1399264],
                [106.9539018, 11.139776],
                [106.9539147, 11.1396262],
                [106.9539355, 11.1394771],
                [106.9539642, 11.1393294],
                [106.9540141, 11.1391854],
                [106.9540703, 11.1390437],
                [106.9541326, 11.1389045],
                [106.9542118, 11.1387717],
                [106.9542973, 11.1386427],
                [106.954389, 11.138518],
                [106.9544867, 11.1383976],
                [106.9545902, 11.138282],
                [106.9546991, 11.1381714],
                [106.9548196, 11.1380606],
                [106.9549456, 11.1379559],
                [106.9550768, 11.1378575],
                [106.955213, 11.1377657],
                [106.9553536, 11.1376808],
                [106.9554984, 11.137603],
                [106.9556303, 11.1375412],
                [106.955758, 11.1374714],
                [106.955881, 11.1373939],
                [106.9559989, 11.1373091],
                [106.9561111, 11.1372173],
                [106.9562173, 11.1371188],
                [106.9563153, 11.1370158],
                [106.9564068, 11.1369071],
                [106.9564913, 11.1367932],
                [106.9565685, 11.1366744],
                [106.9566382, 11.1365512],
                [106.9567001, 11.136424],
                [106.9567636, 11.1362832],
                [106.9568191, 11.1361392],
                [106.9568663, 11.1359924],
                [106.9569052, 11.1358432],
                [106.9569357, 11.1356922],
                [106.9569576, 11.1355397],
                [106.9569705, 11.1353921],
                [106.9569754, 11.135244],
                [106.9569722, 11.1350959],
                [106.956961, 11.1349482],
                [106.9569418, 11.1348012],
                [106.9569147, 11.1346555],
                [106.9568862, 11.1344735],
                [106.9568504, 11.1342927],
                [106.9568075, 11.1341135],
                [106.9567573, 11.133936],
                [106.9567001, 11.1337607],
                [106.9566362, 11.1335887],
                [106.9565656, 11.1334193],
                [106.9564882, 11.1332527],
                [106.9564043, 11.1330892],
                [106.9563138, 11.1329291],
                [106.956175, 11.1327437],
                [106.9560284, 11.1325641],
                [106.9558743, 11.1323907],
                [106.955713, 11.1322238],
                [106.9555454, 11.1320641],
                [106.9553711, 11.1319113],
                [106.9551906, 11.1317658],
                [106.955004, 11.1316277],
                [106.9548118, 11.1314974],
                [106.9546712, 11.1314347],
                [106.9545344, 11.1313645],
                [106.9544018, 11.1312869],
                [106.9542738, 11.1312021],
                [106.9541508, 11.1311105],
                [106.9540332, 11.1310123],
                [106.9539213, 11.1309079],
                [106.9538252, 11.130808],
                [106.9537343, 11.1307035],
                [106.9536489, 11.1305947],
                [106.9535692, 11.1304817],
                [106.9534954, 11.1303649],
                [106.9534278, 11.1302447],
                [106.9533821, 11.1301472],
                [106.9533438, 11.1300467],
                [106.9533129, 11.1299437],
                [106.9532897, 11.1298389],
                [106.9532742, 11.1297327],
                [106.9532666, 11.1296256],
                [106.9532668, 11.1295183],
                [106.9532759, 11.1294036],
                [106.9532939, 11.1292899],
                [106.9533209, 11.129178],
                [106.9533565, 11.1290684],
                [106.9534007, 11.1289618],
                [106.9534532, 11.128859],
                [106.9535136, 11.1287604],
                [106.9536331, 11.1286076],
                [106.953757, 11.1284584],
                [106.9538853, 11.1283128],
                [106.9540179, 11.1281708],
                [106.9541541, 11.1280332],
                [106.9542944, 11.1278996],
                [106.9544386, 11.12777],
                [106.9545865, 11.1276445],
                [106.9547128, 11.1275324],
                [106.9548342, 11.127415],
                [106.9549503, 11.1272927],
                [106.9550609, 11.1271656],
                [106.9551659, 11.1270339],
                [106.9552727, 11.1268869],
                [106.9553724, 11.1267351],
                [106.9554649, 11.126579],
                [106.9555499, 11.1264188],
                [106.9556272, 11.1262549],
                [106.9557926, 11.1257932],
                [106.9559491, 11.1253285],
                [106.9560816, 11.1249095],
                [106.9562067, 11.1244884],
                [106.956346, 11.1240547],
                [106.9565008, 11.1236471],
                [106.9566847, 11.1232321],
                [106.9568764, 11.1228206],
                [106.9570756, 11.1224125],
                [106.9572647, 11.1220418],
                [106.9574601, 11.1216743],
                [106.9576617, 11.1213101],
                [106.9578695, 11.1209492],
                [106.9579811, 11.120744],
                [106.9580989, 11.1205421],
                [106.9582226, 11.1203438],
                [106.9583523, 11.1201491],
                [106.9585038, 11.1199365],
                [106.9586623, 11.1197289],
                [106.9588277, 11.1195266],
                [106.9589998, 11.1193297],
                [106.9591784, 11.1191384],
                [106.9594183, 11.1189222],
                [106.9596648, 11.1187132],
                [106.9599175, 11.1185115],
                [106.9601762, 11.1183173],
                [106.9604124, 11.1181503],
                [106.960653, 11.1179897],
                [106.9608979, 11.1178354],
                [106.961147, 11.1176876],
                [106.9614, 11.1175464],
                [106.9616568, 11.1174119],
                [106.9617816, 11.1173476],
                [106.9619023, 11.1172763],
                [106.9620186, 11.1171981],
                [106.96213, 11.1171134],
                [106.9622362, 11.1170224],
                [106.9623367, 11.1169255],
                [106.9624312, 11.116823],
                [106.9625195, 11.1167153],
                [106.9626012, 11.1166027],
                [106.962676, 11.1164855],
                [106.9627591, 11.1162405],
                [106.9628476, 11.1159973],
                [106.9629415, 11.1157561],
                [106.9630408, 11.115517],
                [106.9631753, 11.1152148],
                [106.9633184, 11.1149165],
                [106.9634914, 11.1145958],
                [106.9636607, 11.1142845],
                [106.9638584, 11.1139508],
                [106.9640631, 11.1136211],
                [106.9642746, 11.1132957],
                [106.9645075, 11.1129536],
                [106.9647479, 11.1126167],
                [106.9649958, 11.112285],
                [106.965208, 11.1119902],
                [106.9653401, 11.1117905],
                [106.9654214, 11.1116185],
                [106.9654945, 11.1114431],
                [106.9655593, 11.1112645],
                [106.9656158, 11.1110832],
                [106.9656638, 11.1108996],
                [106.9657031, 11.110714],
                [106.9657338, 11.1105269],
                [106.9657555, 11.1103398],
                [106.9657686, 11.1101519],
                [106.965773, 11.1099636],
                [106.9657686, 11.1097754],
                [106.9657555, 11.1095875],
                [106.9657177, 11.1093478],
                [106.9656472, 11.1091016],
                [106.9655514, 11.1088056],
                [106.9654573, 11.1085416],
                [106.9653558, 11.1082803],
                [106.9652578, 11.1080402],
                [106.9628035, 11.1077786],
                [106.9625576, 11.1077635],
                [106.9623113, 11.1077581],
                [106.9620771, 11.1077619],
                [106.9618433, 11.1077745],
                [106.9616101, 11.1077958],
                [106.9613779, 11.1078258],
                [106.9611471, 11.1078644],
                [106.9609179, 11.1079117],
                [106.9606907, 11.1079675],
                [106.960482, 11.1080213],
                [106.9602824, 11.1080541],
                [106.960098, 11.1080675],
                [106.9599132, 11.1080719],
                [106.9597284, 11.1080674],
                [106.959544, 11.1080539],
                [106.9593606, 11.1080315],
                [106.9591784, 11.1080002],
                [106.958998, 11.1079601],
                [106.9588197, 11.1079113],
                [106.9586441, 11.1078539],
                [106.9584717, 11.1077881],
                [106.9583027, 11.107714],
                [106.9581377, 11.1076317],
                [106.9580207, 11.1075413],
                [106.9579099, 11.1074436],
                [106.9578057, 11.1073392],
                [106.9577085, 11.1072284],
                [106.9576188, 11.1071117],
                [106.9575369, 11.1069895],
                [106.9574678, 11.106871],
                [106.9574061, 11.1067486],
                [106.957352, 11.1066228],
                [106.9573057, 11.106494],
                [106.9572673, 11.1063628],
                [106.9572371, 11.1062295],
                [106.9572151, 11.1060947],
                [106.9572227, 11.1059372],
                [106.9572387, 11.1057805],
                [106.9572633, 11.1056247],
                [106.9572962, 11.1054705],
                [106.9573374, 11.1053182],
                [106.9573867, 11.1051682],
                [106.957444, 11.1050214],
                [106.957509, 11.1048777],
                [106.9575817, 11.1047376],
                [106.9576619, 11.1046015],
                [106.9577492, 11.1044697],
                [106.9578436, 11.1043427],
                [106.9579661, 11.1041996],
                [106.9581671, 11.1040001],
                [106.9583853, 11.1037755],
                [106.9585991, 11.1035469],
                [106.9588224, 11.1032986],
                [106.9590191, 11.103088],
                [106.9591175, 11.102965],
                [106.9591886, 11.1028792],
                [106.9592535, 11.1027889],
                [106.9593176, 11.1026847],
                [106.9593735, 11.1025761],
                [106.959421, 11.1024636],
                [106.9594598, 11.102348],
                [106.9594896, 11.1022299],
                [106.959513, 11.1021152],
                [106.9595325, 11.1019993],
                [106.9595498, 11.1018606],
                [106.959564, 11.1017102],
                [106.9595725, 11.1015696],
                [106.9595725, 11.1014288],
                [106.959564, 11.1012882],
                [106.9595471, 11.1011483],
                [106.9595218, 11.1010096],
                [106.9594857, 11.1008639],
                [106.9594403, 11.1007208],
                [106.9593858, 11.1005807],
                [106.9593225, 11.1004443],
                [106.9592505, 11.100312],
                [106.9591702, 11.1001844],
                [106.9590819, 11.1000621],
                [106.9589537, 11.0999204],
                [106.9588192, 11.0997844],
                [106.9586788, 11.0996543],
                [106.9585326, 11.0995305],
                [106.958381, 11.0994132],
                [106.9582242, 11.0993025],
                [106.9580626, 11.0991988],
                [106.9578917, 11.0990995],
                [106.9577163, 11.099008],
                [106.9575368, 11.0989243],
                [106.9573537, 11.0988488],
                [106.9571672, 11.0987816],
                [106.9569778, 11.0987227],
                [106.9567859, 11.0986724],
                [106.953578, 11.098146],
                [106.9534434, 11.0981075],
                [106.9533114, 11.0980613],
                [106.9531824, 11.0980076],
                [106.9530568, 11.0979466],
                [106.952935, 11.0978786],
                [106.9528175, 11.0978036],
                [106.9527046, 11.097722],
                [106.9525968, 11.0976342],
                [106.9524944, 11.0975402],
                [106.9523978, 11.0974406],
                [106.9522998, 11.0973263],
                [106.9522094, 11.0972062],
                [106.952127, 11.0970807],
                [106.9520528, 11.0969503],
                [106.9519872, 11.0968155],
                [106.9519305, 11.096677],
                [106.9518828, 11.0965352],
                [106.9517266, 11.0961806],
                [106.9515636, 11.0958289],
                [106.9513939, 11.0954803],
                [106.9512176, 11.0951349],
                [106.9510279, 11.0947802],
                [106.9508312, 11.0944292],
                [106.9506276, 11.0940821],
                [106.9505254, 11.0938861],
                [106.9504173, 11.0936932],
                [106.9503035, 11.0935035],
                [106.950184, 11.0933173],
                [106.9500589, 11.0931345],
                [106.9499175, 11.092941],
                [106.9497698, 11.0927519],
                [106.949616, 11.0925677],
                [106.9494562, 11.0923883],
                [106.9492907, 11.0922141],
                [106.9491196, 11.0920451],
                [106.9489431, 11.0918816],
                [106.9485706, 11.0915065],
                [106.9482023, 11.0911275],
                [106.94761, 11.0905389],
                [106.9475033, 11.0904286],
                [106.947402, 11.0903137],
                [106.947306, 11.0901943],
                [106.9472158, 11.0900707],
                [106.9471203, 11.0899256],
                [106.9470326, 11.0897757],
                [106.946953, 11.0896216],
                [106.9468817, 11.0894636],
                [106.9468188, 11.0893022],
                [106.9467733, 11.0891497],
                [106.9467352, 11.0889952],
                [106.9467045, 11.0888392],
                [106.9466814, 11.088682],
                [106.9466658, 11.0885238],
                [106.9466579, 11.0883651],
                [106.946658, 11.0881867],
                [106.9466678, 11.0880086],
                [106.9466872, 11.0878312],
                [106.9467161, 11.0876551],
                [106.9467544, 11.0874807],
                [106.9468304, 11.0872363],
                [106.9468987, 11.0869898],
                [106.9469592, 11.0867412],
                [106.9470119, 11.086491],
                [106.9470526, 11.0862653],
                [106.9470869, 11.0860385],
                [106.9471148, 11.085811],
                [106.9471363, 11.0855827],
                [106.9471514, 11.0853539],
                [106.9471496, 11.0851299],
                [106.9471401, 11.0849059],
                [106.9471229, 11.0846825],
                [106.9470981, 11.0844597],
                [106.9470656, 11.0842379],
                [106.9470205, 11.0839927],
                [106.9469661, 11.0837492],
                [106.9469024, 11.083508],
                [106.9468698, 11.0833875],
                [106.9468295, 11.0832693],
                [106.9467828, 11.0831558],
                [106.9467289, 11.0830454],
                [106.9466683, 11.0829384],
                [106.9466011, 11.0828353],
                [106.9465275, 11.0827364],
                [106.9464479, 11.0826422],
                [106.9463625, 11.082553],
                [106.9462294, 11.0824001],
                [106.9461025, 11.0822422],
                [106.945982, 11.0820795],
                [106.9458685, 11.0819131],
                [106.9457618, 11.0817426],
                [106.9456618, 11.0815681],
                [106.9455421, 11.081353],
                [106.9453973, 11.0810687],
                [106.9452277, 11.0806648],
                [106.9451237, 11.0803212],
                [106.9450666, 11.0801655],
                [106.9450165, 11.0800074],
                [106.9449735, 11.0798474],
                [106.9449371, 11.0796832],
                [106.9449081, 11.0795176],
                [106.9448866, 11.079351],
                [106.9448726, 11.0791835],
                [106.9448662, 11.0790156],
                [106.9448687, 11.0788418],
                [106.9448785, 11.0786683],
                [106.9448957, 11.0784953],
                [106.9449202, 11.0783232],
                [106.944952, 11.0781523],
                [106.9449909, 11.0779832],
                [106.9450369, 11.0778159],
                [106.9450899, 11.0776506],
                [106.9451499, 11.0774876],
                [106.9452167, 11.0773271],
                [106.9452903, 11.0771696],
                [106.9453704, 11.0770151],
                [106.94553, 11.0768481],
                [106.9456958, 11.0766869],
                [106.9458677, 11.076532],
                [106.9460452, 11.0763834],
                [106.9462283, 11.0762414],
                [106.9464167, 11.0761062],
                [106.94661, 11.075978],
                [106.9468081, 11.0758569],
                [106.9470025, 11.0757476],
                [106.9472008, 11.0756451],
                [106.9474026, 11.0755496],
                [106.9476079, 11.0754613],
                [106.9478162, 11.0753802],
                [106.9480273, 11.0753065],
                [106.948241, 11.0752403],
                [106.9484569, 11.0751815],
                [106.9486856, 11.0751173],
                [106.9489389, 11.0750444],
                [106.9492, 11.0749504],
                [106.9494581, 11.0748485],
                [106.9497128, 11.0747388],
                [106.949964, 11.0746215],
                [106.9502113, 11.0744965],
                [106.9504545, 11.0743642],
                [106.9506935, 11.0742244],
                [106.950928, 11.0740775],
                [106.9511443, 11.0739328],
                [106.9513563, 11.0737819],
                [106.9515637, 11.0736251],
                [106.9517664, 11.0734623],
                [106.9519641, 11.0732939],
                [106.9521568, 11.0731199],
                [106.9523066, 11.0729562],
                [106.9524047, 11.072794],
                [106.9524565, 11.0726444],
                [106.9524994, 11.0724921],
                [106.9525334, 11.0723377],
                [106.9525582, 11.0721816],
                [106.9525738, 11.0720243],
                [106.9525802, 11.0718664],
                [106.9525777, 11.0717176],
                [106.952567, 11.0715691],
                [106.9525481, 11.0714215],
                [106.9525211, 11.071275],
                [106.9524719, 11.0711348],
                [106.9524148, 11.0709974],
                [106.95235, 11.0708634],
                [106.9522777, 11.0707332],
                [106.9521982, 11.070607],
                [106.9521117, 11.0704855],
                [106.952011, 11.0703601],
                [106.9519029, 11.0702409],
                [106.9517877, 11.0701282],
                [106.9516659, 11.0700225],
                [106.9515379, 11.0699241],
                [106.9514041, 11.0698334],
                [106.951265, 11.0697507],
                [106.9511211, 11.0696764],
                [106.9510004, 11.0696079],
                [106.9508807, 11.0695245],
                [106.9506777, 11.0693356],
                [106.9504799, 11.0691415],
                [106.9502875, 11.0689422],
                [106.9501006, 11.0687378],
                [106.9499195, 11.0685287],
                [106.9497266, 11.0682929],
                [106.949541, 11.0680516],
                [106.9493627, 11.067805],
                [106.949192, 11.0675533],
                [106.949029, 11.0672967],
                [106.9489331, 11.0670667],
                [106.9488455, 11.0668335],
                [106.9487661, 11.0665975],
                [106.948695, 11.0663589],
                [106.9486324, 11.0661181],
                [106.9485783, 11.0658753],
                [106.9485352, 11.065645],
                [106.9484998, 11.0654134],
                [106.9484721, 11.0651809],
                [106.9484521, 11.0649476],
                [106.9484398, 11.0647137],
                [106.9484353, 11.0644796],
                [106.9484386, 11.0642455],
                [106.948455, 11.0640063],
                [106.9484855, 11.0637725],
                [106.9485139, 11.0635325],
                [106.9485347, 11.0632917],
                [106.9485479, 11.0630504],
                [106.9485535, 11.0628088],
                [106.9485515, 11.0625672],
                [106.9485419, 11.0623257],
                [106.9485247, 11.0620847],
                [106.9484994, 11.0618403],
                [106.9484664, 11.0615969],
                [106.9484255, 11.0613545],
                [106.9483769, 11.0611136],
                [106.9483206, 11.0608743],
                [106.9482567, 11.0606368],
                [106.9481853, 11.0604014],
                [106.9481063, 11.0601683],
                [106.948049, 11.0599919],
                [106.947984, 11.059818],
                [106.9479115, 11.059647],
                [106.9478316, 11.0594792],
                [106.9477443, 11.059315],
                [106.94765, 11.0591546],
                [106.9475487, 11.0589984],
                [106.9474406, 11.0588466],
                [106.947326, 11.0586994],
                [106.9472051, 11.0585573],
                [106.9470655, 11.0584075],
                [106.9469188, 11.0582644],
                [106.9467655, 11.0581282],
                [106.9466058, 11.0579993],
                [106.94644, 11.0578779],
                [106.9462687, 11.0577643],
                [106.946092, 11.0576587],
                [106.9459105, 11.0575615],
                [106.9457352, 11.0574727],
                [106.9455232, 11.0573698],
                [106.9453178, 11.0572749],
                [106.9451087, 11.0571884],
                [106.9448961, 11.0571103],
                [106.9446805, 11.0570408],
                [106.9444622, 11.05698],
                [106.9442415, 11.056928],
                [106.9440188, 11.0568849],
                [106.9437945, 11.0568507],
                [106.943569, 11.0568255],
                [106.9433427, 11.0568094],
                [106.9431192, 11.0568023],
                [106.9428957, 11.0568041],
                [106.9426724, 11.0568148],
                [106.9424498, 11.0568342],
                [106.9422281, 11.0568623],
                [106.9420077, 11.0568992],
                [106.941789, 11.0569448],
                [106.9415939, 11.0570042],
                [106.9414028, 11.0570995],
                [106.9412377, 11.0572071],
                [106.9410773, 11.0573213],
                [106.940922, 11.0574421],
                [106.9407719, 11.0575692],
                [106.9406274, 11.0577023],
                [106.9404888, 11.0578413],
                [106.9403639, 11.0579771],
                [106.9402446, 11.0581177],
                [106.9401311, 11.0582628],
                [106.9400234, 11.0584122],
                [106.9399219, 11.0585656],
                [106.9398266, 11.0587229],
                [106.9397378, 11.0588837],
                [106.9395506, 11.0592128],
                [106.9393718, 11.0595463],
                [106.9392013, 11.059884],
                [106.9390725, 11.0601543],
                [106.938949, 11.0604269],
                [106.938831, 11.0607019],
                [106.9387185, 11.0609791],
                [106.9386126, 11.0612047],
                [106.9384999, 11.0614271],
                [106.9383806, 11.0616461],
                [106.9382547, 11.0618616],
                [106.9381223, 11.0620733],
                [106.9379837, 11.0622811],
                [106.9378388, 11.0624848],
                [106.9376735, 11.0627025],
                [106.9375011, 11.0629148],
                [106.9373218, 11.0631216],
                [106.9371358, 11.0633225],
                [106.9369433, 11.0635175],
                [106.9367444, 11.0637062],
                [106.9365939, 11.0638384],
                [106.9364372, 11.0639636],
                [106.9362747, 11.0640814],
                [106.9361069, 11.0641917],
                [106.935934, 11.0642942],
                [106.9357564, 11.0643888],
                [106.9355745, 11.0644751],
                [106.9353888, 11.064553],
                [106.9351995, 11.0646223],
                [106.9350171, 11.06468],
                [106.9348324, 11.0647297],
                [106.9346456, 11.0647714],
                [106.9344571, 11.0648049],
                [106.9342672, 11.0648303],
                [106.9340765, 11.0648474],
                [106.9338851, 11.0648562],
                [106.9336935, 11.0648567],
                [106.9335236, 11.0648595],
                [106.9333273, 11.0648434],
                [106.9331405, 11.0648224],
                [106.9329386, 11.0647926],
                [106.9327543, 11.0647592],
                [106.9325718, 11.0647171],
                [106.9323915, 11.0646664],
                [106.932214, 11.0646072],
                [106.9320396, 11.0645397],
                [106.9318688, 11.0644639],
                [106.9317019, 11.0643801],
                [106.9315321, 11.0642842],
                [106.9313675, 11.06418],
                [106.9312084, 11.0640677],
                [106.9310554, 11.0639477],
                [106.9309087, 11.0638203],
                [106.9307688, 11.0636858],
                [106.9306359, 11.0635444],
                [106.9305106, 11.0633967],
                [106.930393, 11.0632429],
                [106.930147, 11.0627984],
                [106.9298951, 11.0623572],
                [106.9296373, 11.0619192],
                [106.9293737, 11.0614845],
                [106.9291262, 11.0610878],
                [106.9288738, 11.060694],
                [106.9286165, 11.0603033],
                [106.9283545, 11.0599156],
                [106.9281838, 11.0597303],
                [106.9280068, 11.0595508],
                [106.9278237, 11.0593773],
                [106.9276348, 11.0592099],
                [106.9274402, 11.0590489],
                [106.9272401, 11.0588945],
                [106.9270348, 11.0587468],
                [106.926828, 11.0586082],
                [106.9266166, 11.0584765],
                [106.9264008, 11.0583517],
                [106.926181, 11.0582341],
                [106.9259572, 11.0581237],
                [106.9257298, 11.0580207],
                [106.9254991, 11.0579252],
                [106.9252653, 11.0578373],
                [106.9250178, 11.0577518],
                [106.9247914, 11.0576596],
                [106.9245577, 11.0575544],
                [106.9243278, 11.0574414],
                [106.924102, 11.0573207],
                [106.9238805, 11.0571927],
                [106.9236635, 11.0570572],
                [106.9234514, 11.0569147],
                [106.9232476, 11.0567675],
                [106.9230489, 11.0566137],
                [106.9228555, 11.0564535],
                [106.9226678, 11.056287],
                [106.9225287, 11.056146],
                [106.9224154, 11.0559988],
                [106.9223517, 11.0558795],
                [106.9222952, 11.0557568],
                [106.9222458, 11.0556311],
                [106.9222038, 11.0555029],
                [106.9221693, 11.0553725],
                [106.9221425, 11.0552404],
                [106.922123, 11.0551039],
                [106.9221117, 11.0549664],
                [106.9221086, 11.0548286],
                [106.9221136, 11.0546908],
                [106.9221269, 11.0545535],
                [106.9221483, 11.0544172],
                [106.9221778, 11.0542824],
                [106.9222152, 11.0541495],
                [106.9222659, 11.0539716],
                [106.922387, 11.0535821],
                [106.9225193, 11.0531468],
                [106.9226575, 11.0527133],
                [106.9228373, 11.052177],
                [106.923026, 11.0516436],
                [106.9232236, 11.0511133],
                [106.923328, 11.0508232],
                [106.9234031, 11.0506178],
                [106.9234568, 11.0504187],
                [106.9234967, 11.0501762],
                [106.9235174, 11.0499695],
                [106.9235297, 11.0497622],
                [106.9235336, 11.0495546],
                [106.923529, 11.0493469],
                [106.9235159, 11.0491397],
                [106.9234943, 11.0489331],
                [106.9234658, 11.0487359],
                [106.9234295, 11.04854],
                [106.9233857, 11.0483456],
                [106.9233342, 11.048153],
                [106.9232753, 11.0479625],
                [106.923209, 11.0477743],
                [106.9231568, 11.0476362],
                [106.9231081, 11.0475299],
                [106.9230598, 11.0474378],
                [106.9229847, 11.0473325],
                [106.9228848, 11.0472163],
                [106.9227084, 11.0470324],
                [106.9225254, 11.0468548],
                [106.922336, 11.0466837],
                [106.9221406, 11.0465194],
                [106.9219393, 11.046362],
                [106.9217324, 11.0462118],
                [106.9215202, 11.0460689],
                [106.9213188, 11.0459431],
                [106.9211133, 11.0458238],
                [106.9209039, 11.0457114],
                [106.9206907, 11.0456057],
                [106.9204742, 11.0455071],
                [106.9202544, 11.0454155],
                [106.9151736, 11.0433956],
                [106.9146846, 11.0431719],
                [106.9145061, 11.0430828],
                [106.9143319, 11.042986],
                [106.9141622, 11.0428816],
                [106.9139975, 11.0427699],
                [106.9138381, 11.0426511],
                [106.9136842, 11.0425254],
                [106.9135362, 11.0423931],
                [106.9133944, 11.0422544],
                [106.913259, 11.0421096],
                [106.9131321, 11.0419611],
                [106.913012, 11.0418074],
                [106.912899, 11.0416485],
                [106.9127932, 11.041485],
                [106.9126948, 11.041317],
                [106.9126041, 11.0411449],
                [106.9125212, 11.040969],
                [106.9124463, 11.0407898],
                [106.9123795, 11.0406075],
                [106.9123209, 11.0404224],
                [106.9122707, 11.040235],
                [106.912229, 11.0400456],
                [106.9121549, 11.0395127],
                [106.9120899, 11.0389786],
                [106.9120341, 11.0384435],
                [106.9119875, 11.0379076],
                [106.9119501, 11.0373709],
                [106.9119245, 11.0368905],
                [106.9119062, 11.0364097],
                [106.9118954, 11.0359287],
                [106.9118919, 11.0354477],
                [106.9118958, 11.0349666],
                [106.9119072, 11.0344856],
                [106.9118965, 11.0341276],
                [106.9118564, 11.0335959],
                [106.9117989, 11.0327066],
                [106.9117345, 11.0318178],
                [106.9116633, 11.0309295],
                [106.9115853, 11.0300417],
                [106.9114928, 11.0290769],
                [106.9113922, 11.0281129],
                [106.9112481, 11.026902],
                [106.9112032, 11.0266558],
                [106.911149, 11.0264114],
                [106.9110854, 11.0261691],
                [106.9110127, 11.0259294],
                [106.9109309, 11.0256925],
                [106.9108436, 11.0254676],
                [106.9107482, 11.0252459],
                [106.9106447, 11.0250278],
                [106.9105333, 11.0248134],
                [106.910414, 11.0246031],
                [106.9103086, 11.0244077],
                [106.9101722, 11.024221],
                [106.9100493, 11.0240501],
                [106.9099187, 11.0238848],
                [106.9097806, 11.0237254],
                [106.9096354, 11.0235722],
                [106.9094833, 11.0234257],
                [106.9093246, 11.0232859],
                [106.9091597, 11.0231534],
                [106.9089889, 11.0230282],
                [106.908844, 11.0229308],
                [106.9086954, 11.0228387],
                [106.9085434, 11.0227521],
                [106.9083883, 11.0226711],
                [106.9082517, 11.022601],
                [106.9080582, 11.0225148],
                [106.9078812, 11.0224332],
                [106.9077095, 11.0223568],
                [106.9075054, 11.0222721],
                [106.9072917, 11.0222015],
                [106.9071837, 11.0221738],
                [106.9070739, 11.0221541],
                [106.9069673, 11.0221428],
                [106.9068601, 11.0221389],
                [106.906753, 11.0221425],
                [106.9066329, 11.0221523],
                [106.9063978, 11.0221827],
                [106.9061505, 11.0222205],
                [106.9059047, 11.022267],
                [106.9056608, 11.0223222],
                [106.9054191, 11.022386],
                [106.9051799, 11.0224583],
                [106.9049436, 11.0225391],
                [106.9047103, 11.0226281],
                [106.9044804, 11.0227254],
                [106.9042543, 11.0228307],
                [106.9040322, 11.022944],
                [106.9038211, 11.0230611],
                [106.9036143, 11.0231855],
                [106.9034121, 11.0233168],
                [106.9032147, 11.023455],
                [106.9030223, 11.0235999],
                [106.9028352, 11.0237514],
                [106.9026536, 11.0239091],
                [106.9024777, 11.024073],
                [106.9023077, 11.0242428],
                [106.9021439, 11.0244183],
                [106.901929, 11.024658],
                [106.9017068, 11.0248913],
                [106.9014777, 11.025118],
                [106.9012417, 11.0253379],
                [106.9009992, 11.0255507],
                [106.9007502, 11.0257564],
                [106.9004952, 11.0259546],
                [106.9002342, 11.0261453],
                [106.8999769, 11.0263221],
                [106.8997144, 11.0264915],
                [106.8994471, 11.0266534],
                [106.8991752, 11.0268076],
                [106.8988988, 11.0269541],
                [106.8986183, 11.0270927],
                [106.8983337, 11.0272233],
                [106.8980455, 11.0273458],
                [106.897772, 11.0274414],
                [106.8974957, 11.0275285],
                [106.8972167, 11.0276071],
                [106.8969353, 11.0276771],
                [106.8966519, 11.0277386],
                [106.8963667, 11.0277913],
                [106.8960799, 11.0278353],
                [106.8957918, 11.0278705],
                [106.8955028, 11.0278969],
                [106.8952131, 11.0279145],
                [106.894933, 11.0279231],
                [106.8946528, 11.0279234],
                [106.8943726, 11.0279154],
                [106.8940929, 11.0278991],
                [106.8938138, 11.0278747],
                [106.8935355, 11.027842],
                [106.8932584, 11.0278011],
                [106.8929826, 11.027752],
                [106.8927085, 11.0276949],
                [106.8924362, 11.0276297],
                [106.8921661, 11.0275565],
                [106.8916005, 11.0273965],
                [106.8910372, 11.0272287],
                [106.8904765, 11.0270529],
                [106.8899183, 11.0268693],
                [106.889363, 11.0266779],
                [106.8888104, 11.0264787],
                [106.8882608, 11.0262717],
                [106.8876701, 11.0260393],
                [106.8870831, 11.025798],
                [106.8865, 11.0255477],
                [106.8859209, 11.0252887],
                [106.885346, 11.0250209],
                [106.8847753, 11.0247444],
                [106.8842091, 11.0244592],
                [106.8836474, 11.0241655],
                [106.8819817, 11.0232888],
                [106.8803196, 11.0224055],
                [106.8798602, 11.0221549],
                [106.8793968, 11.0219117],
                [106.8789293, 11.021676],
                [106.8784579, 11.0214479],
                [106.8779828, 11.0212274],
                [106.8775041, 11.0210146],
                [106.8770219, 11.0208095],
                [106.876511, 11.0206022],
                [106.8759965, 11.0204035],
                [106.8753071, 11.0201475],
                [106.8751333, 11.0200871],
                [106.8749575, 11.0200326],
                [106.8747473, 11.0199758],
                [106.8745349, 11.0199274],
                [106.8743207, 11.0198875],
                [106.874105, 11.0198561],
                [106.8738882, 11.0198334],
                [106.8736707, 11.0198192],
                [106.8734527, 11.0198138],
                [106.8732347, 11.019817],
                [106.873017, 11.0198288],
                [106.8727732, 11.0198704],
                [106.8724513, 11.0199576],
                [106.8721057, 11.020075],
                [106.8717633, 11.0202014],
                [106.8714245, 11.0203367],
                [106.8710896, 11.0204809],
                [106.8707586, 11.0206337],
                [106.8704319, 11.0207951],
                [106.8701097, 11.020965],
                [106.8697923, 11.0211433],
                [106.8694798, 11.0213298],
                [106.869181, 11.0215189],
                [106.8688873, 11.0217155],
                [106.8685989, 11.0219195],
                [106.8683159, 11.0221307],
                [106.8680387, 11.0223491],
                [106.8677673, 11.0225744],
                [106.8675019, 11.0228066],
                [106.8672427, 11.0230455],
                [106.86699, 11.0232908],
                [106.8667437, 11.0235425],
                [106.8665043, 11.0238004],
                [106.8662717, 11.0240642],
                [106.8660307, 11.0243656],
                [106.8658183, 11.0246766],
                [106.8653575, 11.0252835],
                [106.8648892, 11.0258849],
                [106.8644135, 11.0264807],
                [106.8639305, 11.0270708],
                [106.8634403, 11.0276552],
                [106.8629429, 11.0282336],
                [106.8624906, 11.0287476],
                [106.8620327, 11.0292566],
                [106.8615691, 11.0297608],
                [106.8611536, 11.0302179],
                [106.8609041, 11.0304922],
                [106.8607012, 11.0307187],
                [106.8604916, 11.030939],
                [106.8602753, 11.0311532],
                [106.8600527, 11.031361],
                [106.8598239, 11.0315621],
                [106.859589, 11.0317565],
                [106.8593484, 11.0319439],
                [106.8591022, 11.0321242],
                [106.8588506, 11.0322973],
                [106.8585846, 11.0324687],
                [106.8583133, 11.0326319],
                [106.8580371, 11.0327869],
                [106.8577561, 11.0329335],
                [106.8574706, 11.0330715],
                [106.8571809, 11.0332007],
                [106.8568873, 11.0333212],
                [106.8565901, 11.0334327],
                [106.8562895, 11.0335351],
                [106.8559, 11.0336284],
                [106.8553562, 11.0337071],
                [106.8547277, 11.0337934],
                [106.8541002, 11.0338874],
                [106.853474, 11.033989],
                [106.8528492, 11.0340983],
                [106.8522258, 11.0342151],
                [106.8516038, 11.0343395],
                [106.8510194, 11.0344636],
                [106.8504365, 11.0345944],
                [106.8498551, 11.0347318],
                [106.8492754, 11.034876],
                [106.8487726, 11.0350057],
                [106.8484192, 11.0350911],
                [106.8481215, 11.0351841],
                [106.8478082, 11.0352835],
                [106.847498, 11.0353918],
                [106.8471912, 11.0355089],
                [106.8468879, 11.0356348],
                [106.8465886, 11.0357693],
                [106.8462934, 11.0359124],
                [106.8459357, 11.0360728],
                [106.845581, 11.0362394],
                [106.8452294, 11.0364122],
                [106.8448809, 11.036591],
                [106.8445356, 11.0367758],
                [106.8441909, 11.0369681],
                [106.8438498, 11.0371665],
                [106.8435123, 11.0373708],
                [106.8431786, 11.0375809],
                [106.8428407, 11.0377837],
                [106.8426, 11.03793],
                [106.842388, 11.0380531],
                [106.8421802, 11.0381828],
                [106.8419769, 11.0383192],
                [106.8417781, 11.0384619],
                [106.8415842, 11.0386109],
                [106.8413953, 11.0387661],
                [106.8412116, 11.0389271],
                [106.8410334, 11.039094],
                [106.8408608, 11.0392664],
                [106.840604, 11.0394996],
                [106.8403539, 11.0397396],
                [106.8401105, 11.0399863],
                [106.8398741, 11.0402394],
                [106.8396449, 11.0404987],
                [106.839423, 11.0407642],
                [106.8392086, 11.0410355],
                [106.83902, 11.0412875],
                [106.8388379, 11.0415439],
                [106.8386625, 11.0418048],
                [106.8384937, 11.0420699],
                [106.8383316, 11.042339],
                [106.8381765, 11.0426121],
                [106.8380606, 11.042873],
                [106.8379855, 11.0430889],
                [106.8378703, 11.0434345],
                [106.8377034, 11.0439776],
                [106.8375276, 11.044518],
                [106.8373431, 11.0450556],
                [106.8371498, 11.0455902],
                [106.8369728, 11.0460572],
                [106.8367892, 11.0465849],
                [106.8365888, 11.047118],
                [106.8364013, 11.0476059],
                [106.8361944, 11.0481435],
                [106.8359789, 11.0486778],
                [106.835755, 11.0492087],
                [106.8355226, 11.0497361],
                [106.8352818, 11.0502598],
                [106.8350231, 11.0507996],
                [106.8347554, 11.0513352],
                [106.834479, 11.0518665],
                [106.8341937, 11.0523932],
                [106.8338998, 11.0529154],
                [106.8335973, 11.0534328],
                [106.8332863, 11.0539453],
                [106.833043, 11.0543599],
                [106.8328077, 11.0547789],
                [106.8325806, 11.0552022],
                [106.8323616, 11.0556297],
                [106.832151, 11.0560612],
                [106.8319488, 11.0564965],
                [106.831755, 11.0569355],
                [106.8315697, 11.057378],
                [106.831399, 11.0578081],
                [106.8312364, 11.0582413],
                [106.8310818, 11.0586773],
                [106.8309355, 11.059116],
                [106.8307973, 11.0595573],
                [106.8306674, 11.0600009],
                [106.8305457, 11.0604468],
                [106.8304324, 11.0608949],
                [106.8303581, 11.0611334],
                [106.8302749, 11.0613692],
                [106.8301829, 11.0616017],
                [106.8300822, 11.0618308],
                [106.8299729, 11.062056],
                [106.8298553, 11.0622772],
                [106.8297295, 11.0624939],
                [106.8295955, 11.0627059],
                [106.8294634, 11.0628994],
                [106.8293245, 11.0630883],
                [106.8291791, 11.0632724],
                [106.8290273, 11.0634515],
                [106.8288693, 11.0636253],
                [106.8287054, 11.0637937],
                [106.8285356, 11.0639565],
                [106.8283601, 11.0641134],
                [106.8281793, 11.0642643],
                [106.8279261, 11.0643968],
                [106.8276689, 11.0645215],
                [106.8274078, 11.0646384],
                [106.8271433, 11.0647474],
                [106.8268754, 11.0648483],
                [106.8266045, 11.0649411],
                [106.8263308, 11.0650257],
                [106.8260546, 11.065102],
                [106.8257761, 11.0651698],
                [106.8254995, 11.0652285],
                [106.8252212, 11.0652789],
                [106.8249415, 11.065321],
                [106.8246606, 11.0653547],
                [106.8243788, 11.06538],
                [106.8240963, 11.065397],
                [106.8238134, 11.0654055],
                [106.8235305, 11.0654055],
                [106.8232905, 11.0654025],
                [106.8229973, 11.0653699],
                [106.8226325, 11.0652962],
                [106.8221893, 11.0651997],
                [106.8214156, 11.065011],
                [106.8206438, 11.0648144],
                [106.8198742, 11.0646098],
                [106.8191069, 11.0643972],
                [106.8183419, 11.0641768],
                [106.8175792, 11.0639484],
                [106.8167995, 11.063706],
                [106.8160225, 11.0634553],
                [106.8152483, 11.0631965],
                [106.8144005, 11.0629594],
                [106.8135509, 11.0627287],
                [106.8126995, 11.0625042],
                [106.8118464, 11.062286],
                [106.8109917, 11.0620742],
                [106.8099917, 11.0618348],
                [106.8089895, 11.0616041],
                [106.8079853, 11.061382],
                [106.8069791, 11.0611687],
                [106.8066142, 11.0611073],
                [106.8062511, 11.0610364],
                [106.80589, 11.0609562],
                [106.8055312, 11.0608665],
                [106.805175, 11.0607676],
                [106.8048215, 11.0606594],
                [106.8044711, 11.060542],
                [106.804124, 11.0604156],
                [106.8037805, 11.0602801],
                [106.8034407, 11.0601358],
                [106.8031049, 11.0599827],
                [106.8027734, 11.0598209],
                [106.8024455, 11.05965],
                [106.8021223, 11.0594706],
                [106.8018041, 11.0592829],
                [106.8014911, 11.0590869],
                [106.8011835, 11.0588828],
                [106.8008815, 11.0586707],
                [106.8005855, 11.0584508],
                [106.8002954, 11.0582233],
                [106.8000117, 11.0579883],
                [106.7997344, 11.057746],
                [106.7994638, 11.0574965],
                [106.7992, 11.05724],
                [106.799, 11.05691],
                [106.7989196, 11.0567582],
                [106.7988466, 11.0566029],
                [106.7987811, 11.0564443],
                [106.7987233, 11.0562829],
                [106.7986741, 11.0561216],
                [106.7986325, 11.0559582],
                [106.7985987, 11.0557931],
                [106.7985888, 11.0556292],
                [106.7985863, 11.0554551],
                [106.7986085, 11.0552846],
                [106.7986393, 11.0551153],
                [106.7986786, 11.0549477],
                [106.7987263, 11.0547822],
                [106.7987823, 11.0546192],
                [106.7988217, 11.0545183],
                [106.7988642, 11.0544187],
                [106.7989537, 11.0542214],
                [106.7990508, 11.0540275],
                [106.7991553, 11.0538374],
                [106.7992671, 11.0536514],
                [106.799386, 11.0534696],
                [106.7995119, 11.0532924],
                [106.7996497, 11.0531135],
                [106.7997946, 11.0529401],
                [106.7999464, 11.0527724],
                [106.8001048, 11.0526107],
                [106.8002695, 11.0524553],
                [106.8004404, 11.0523064],
                [106.8006171, 11.0521641],
                [106.8008423, 11.0520131],
                [106.8011224, 11.0518831],
                [106.8014422, 11.0517306],
                [106.8017586, 11.0515715],
                [106.8020716, 11.0514059],
                [106.8023809, 11.0512338],
                [106.8026864, 11.0510553],
                [106.802988, 11.0508706],
                [106.8032511, 11.0507022],
                [106.8035109, 11.050529],
                [106.8040286, 11.0501799],
                [106.804541, 11.0498234],
                [106.805048, 11.0494596],
                [106.8055032, 11.0491232],
                [106.8059538, 11.0487808],
                [106.8063998, 11.0484327],
                [106.806841, 11.0480787],
                [106.8072773, 11.047719],
                [106.8077087, 11.0473536],
                [106.8080417, 11.0469654],
                [106.8083671, 11.0465712],
                [106.8086848, 11.0461709],
                [106.8089946, 11.0457647],
                [106.8092966, 11.0453529],
                [106.8095777, 11.0449539],
                [106.8098514, 11.04455],
                [106.8101176, 11.0441413],
                [106.8103761, 11.0437279],
                [106.810627, 11.04331],
                [106.8108082, 11.0429587],
                [106.8109823, 11.0426039],
                [106.8111491, 11.0422458],
                [106.8113086, 11.0418844],
                [106.8114608, 11.0415201],
                [106.8116055, 11.0411528],
                [106.8117428, 11.0407828],
                [106.8118783, 11.0403928],
                [106.8120055, 11.0400002],
                [106.8121244, 11.0396051],
                [106.8122348, 11.0392076],
                [106.8123368, 11.0388079],
                [106.8124303, 11.0384062],
                [106.812526, 11.0380291],
                [106.8126142, 11.0376822],
                [106.8127052, 11.0373593],
                [106.812788, 11.0370343],
                [106.8128627, 11.0367074],
                [106.8129292, 11.0363788],
                [106.8129875, 11.0360487],
                [106.8130375, 11.0357173],
                [106.8130791, 11.0353848],
                [106.8131125, 11.0350514],
                [106.8131375, 11.0347173],
                [106.8131538, 11.0343909],
                [106.8131622, 11.0340643],
                [106.8131626, 11.0337376],
                [106.8131551, 11.0334109],
                [106.8131395, 11.0330845],
                [106.8131161, 11.0327586],
                [106.8130616, 11.0324581],
                [106.8129973, 11.0321594],
                [106.8129233, 11.0318629],
                [106.8128397, 11.0315689],
                [106.8127466, 11.0312776],
                [106.812644, 11.0309895],
                [106.8125327, 11.0307064],
                [106.8124124, 11.030427],
                [106.812283, 11.0301515],
                [106.8121448, 11.0298802],
                [106.8119979, 11.0296133],
                [106.8118423, 11.0293511],
                [106.8116784, 11.0290939],
                [106.8114938, 11.0288268],
                [106.8113013, 11.0285651],
                [106.811101, 11.0283092],
                [106.8108932, 11.0280591],
                [106.8106778, 11.0278151],
                [106.8104553, 11.0275775],
                [106.810225, 11.0273458],
                [106.8099879, 11.0271208],
                [106.8097441, 11.0269029],
                [106.8094938, 11.0266921],
                [106.8092855, 11.0265097],
                [106.809082, 11.026377],
                [106.8088674, 11.0262612],
                [106.8085599, 11.0261487],
                [106.808142, 11.0260135],
                [106.8077212, 11.025887],
                [106.8072978, 11.0257695],
                [106.8068719, 11.0256609],
                [106.8064494, 11.0255626],
                [106.8062376, 11.0255157],
                [106.8060249, 11.0254731],
                [106.8057355, 11.0254221],
                [106.8054447, 11.0253789],
                [106.8051529, 11.0253435],
                [106.8048602, 11.0253159],
                [106.8039, 11.02532],
                [106.8024, 11.02512],
                [106.8001, 11.02486],
                [106.7963372, 11.0242268],
                [106.7957835, 11.0240975],
                [106.7951735, 11.0238484],
                [106.7931, 11.0228],
                [106.7926825, 11.0225749],
                [106.7922, 11.02222],
                [106.7919395, 11.0219711],
                [106.7904, 11.02065],
                [106.7888741, 11.0195791],
                [106.788318, 11.0192223],
                [106.7872981, 11.018749],
                [106.7870304, 11.0186399],
                [106.7867561, 11.0185662],
                [106.7864493, 11.0185234],
                [106.7861, 11.01844],
                [106.7854256, 11.0182934],
                [106.7851592, 11.0182002],
                [106.7849, 11.01801],
                [106.7846, 11.01766],
                [106.7844908, 11.0175192],
                [106.784397, 11.0173191],
                [106.7843297, 11.0171341],
                [106.7842824, 11.0169432],
                [106.7838668, 11.0151124],
                [106.7837947, 11.0148846],
                [106.783739, 11.01467],
                [106.7831887, 11.0132751],
                [106.7831036, 11.0131418],
                [106.782206, 11.0121977],
                [106.7820682, 11.0120687],
                [106.7819077, 11.0119158],
                [106.7805462, 11.0107574],
                [106.7802609, 11.0105892],
                [106.7799828, 11.0104489],
                [106.7792, 11.01015],
                [106.7787, 11.00993],
                [106.777, 11.00918],
                [106.7761, 11.00833],
                [106.7753817, 11.0075399],
                [106.7752241, 11.0073045],
                [106.7750606, 11.0070403],
                [106.7748713, 11.0066919],
                [106.77475, 11.00624],
                [106.774694, 11.0059231],
                [106.7746, 11.00473],
                [106.77464, 11.0041792],
                [106.77465, 11.0039408],
                [106.7750422, 11.0017856],
                [106.7751975, 11.001189],
                [106.7758591, 10.9992329],
                [106.7785675, 10.9932081],
                [106.7787506, 10.9928127],
                [106.7789863, 10.9923498],
                [106.7803172, 10.9898899],
                [106.7819356, 10.9872361],
                [106.782558, 10.9863471],
                [106.7829439, 10.9858921],
                [106.7833369, 10.9854742],
                [106.7881055, 10.9812382],
                [106.7883534, 10.9810126],
                [106.7886522, 10.9807699],
                [106.7896626, 10.9799514],
                [106.7941043, 10.9765404],
                [106.7943284, 10.9763519],
                [106.7945516, 10.9761454],
                [106.7948446, 10.9757862],
                [106.7960133, 10.9738325],
                [106.796084, 10.9737192],
                [106.7961476, 10.973602],
                [106.7962039, 10.9734812],
                [106.7962527, 10.9733573],
                [106.7962939, 10.9732308],
                [106.7963272, 10.9731021],
                [106.7963525, 10.9729716],
                [106.7963699, 10.9728398],
                [106.7963791, 10.9727073],
                [106.79638, 10.9725702],
                [106.7963723, 10.9724333],
                [106.796356, 10.9722972],
                [106.7963311, 10.9721623],
                [106.7962976, 10.9720292],
                [106.7962559, 10.9718984],
                [106.7962059, 10.9717703],
                [106.796148, 10.9716456],
                [106.7960822, 10.9715247],
                [106.7960116, 10.9714106],
                [106.7959017, 10.9712959],
                [106.79573, 10.9711801],
                [106.7955421, 10.971055],
                [106.7951508, 10.9708218],
                [106.7947548, 10.9705964],
                [106.7943543, 10.9703789],
                [106.7939493, 10.9701694],
                [106.7935402, 10.9699679],
                [106.7931269, 10.9697747],
                [106.7927098, 10.9695896],
                [106.7922924, 10.9694143],
                [106.7918715, 10.9692472],
                [106.7914473, 10.9690885],
                [106.7910199, 10.9689381],
                [106.7905895, 10.9687962],
                [106.7901939, 10.9686785],
                [106.7898232, 10.9685927],
                [106.7894922, 10.9685135],
                [106.7891637, 10.9684251],
                [106.7888378, 10.9683275],
                [106.7885148, 10.968221],
                [106.7881951, 10.9681055],
                [106.7878789, 10.9679811],
                [106.7875663, 10.967848],
                [106.7872578, 10.9677062],
                [106.7869534, 10.9675559],
                [106.7866535, 10.9673972],
                [106.7863583, 10.9672303],
                [106.7860664, 10.9670541],
                [106.7857797, 10.9668698],
                [106.7854985, 10.9666775],
                [106.785223, 10.9664775],
                [106.7849534, 10.9662698],
                [106.78469, 10.9660547],
                [106.7844329, 10.9658323],
                [106.7841824, 10.9656028],
                [106.7839386, 10.9653663],
                [106.7837018, 10.9651231],
                [106.7834722, 10.9648734],
                [106.7832499, 10.9646174],
                [106.7823364, 10.9643168],
                [106.7806434, 10.9637078],
                [106.780566, 10.9636663],
                [106.779758, 10.9632331],
                [106.7789048, 10.9627997],
                [106.7783163, 10.9623321],
                [106.777896, 10.9619561],
                [106.7773422, 10.961274],
                [106.777006, 10.9606814],
                [106.7766071, 10.9601038],
                [106.7761495, 10.9595903],
                [106.7755185, 10.9592452],
                [106.7749433, 10.9592666],
                [106.7741918, 10.9592354],
                [106.7735571, 10.9592533],
                [106.7732134, 10.9594608],
                [106.7730766, 10.9597004],
                [106.7729977, 10.9598653],
                [106.7729561, 10.9602822],
                [106.7729805, 10.9611026],
                [106.7729443, 10.9617851],
                [106.772882, 10.9620121],
                [106.7727773, 10.9622371],
                [106.7725093, 10.9626172],
                [106.7720561, 10.9630891],
                [106.7717308, 10.9632029],
                [106.7715184, 10.9632773],
                [106.7711685, 10.9634296],
                [106.7711586, 10.9636993],
                [106.7712082, 10.9639723],
                [106.7713485, 10.9643823],
                [106.7715821, 10.9647367],
                [106.7714637, 10.9651229],
                [106.7713282, 10.9652616],
                [106.7709905, 10.9652796],
                [106.7707717, 10.9652767],
                [106.7705437, 10.9652438],
                [106.7702565, 10.9651524],
                [106.7700319, 10.9650007],
                [106.7699682, 10.9648409],
                [106.7698481, 10.9645121],
                [106.7697708, 10.9643569],
                [106.7695891, 10.9642621],
                [106.7692897, 10.9641577],
                [106.7690807, 10.9641429],
                [106.7688167, 10.9641806],
                [106.7687293, 10.9642284],
                [106.7686611, 10.9642858],
                [106.7684532, 10.9644354],
                [106.7681686, 10.9644933],
                [106.7679289, 10.9644446],
                [106.7676748, 10.9643267],
                [106.7673067, 10.9641113],
                [106.7670429, 10.9639116],
                [106.766965, 10.9637458],
                [106.7668166, 10.963571],
                [106.7666059, 10.9634518],
                [106.7665027, 10.9635086],
                [106.7664361, 10.9636387],
                [106.7665354, 10.963849],
                [106.7667285, 10.9641548],
                [106.7666923, 10.9643061],
                [106.766619, 10.9644178],
                [106.7665634, 10.964489],
                [106.7664924, 10.9645622],
                [106.7664036, 10.9645426],
                [106.7663244, 10.96447],
                [106.766332, 10.9643774],
                [106.7663329, 10.9641612],
                [106.7663047, 10.9640092],
                [106.7662061, 10.9639547],
                [106.7660639, 10.9640404],
                [106.7659842, 10.9641834],
                [106.7658979, 10.9643096],
                [106.7657864, 10.9643982],
                [106.7657789, 10.9644042],
                [106.7656878, 10.9642989],
                [106.7654785, 10.964104],
                [106.7650494, 10.9638934],
                [106.7648047, 10.9637607],
                [106.7648259, 10.9636387],
                [106.7648034, 10.963554],
                [106.7646877, 10.9634768],
                [106.7645202, 10.9634154],
                [106.7644239, 10.9634095],
                [106.7643162, 10.9634462],
                [106.7642463, 10.963536],
                [106.7642162, 10.9635746],
                [106.7641603, 10.9637659],
                [106.7641616, 10.9640191],
                [106.7636232, 10.9647232],
                [106.7625183, 10.9637131],
                [106.7620874, 10.963298],
                [106.7622899, 10.9629888],
                [106.7622279, 10.962505],
                [106.76214, 10.9623867],
                [106.762024, 10.9622184],
                [106.7619675, 10.9621265],
                [106.7619663, 10.9618707],
                [106.7620891, 10.9617284],
                [106.7621973, 10.9615616],
                [106.7624123, 10.9610318],
                [106.76248, 10.9609698],
                [106.7626112, 10.9609112],
                [106.7630976, 10.9608117],
                [106.7633271, 10.9606848],
                [106.763519, 10.9605371],
                [106.7637625, 10.9604037],
                [106.76397, 10.9603041],
                [106.7641987, 10.9602423],
                [106.7644453, 10.960097],
                [106.7648298, 10.9597913],
                [106.7649982, 10.9594959],
                [106.765137, 10.9590997],
                [106.7651518, 10.9589986],
                [106.7651569, 10.9585618],
                [106.7650388, 10.9584674],
                [106.7648412, 10.9583818],
                [106.7646567, 10.9581492],
                [106.7645926, 10.9578858],
                [106.7646486, 10.9576064],
                [106.7647217, 10.9573245],
                [106.7647893, 10.956988],
                [106.7648073, 10.9568483],
                [106.7647, 10.95673],
                [106.7645266, 10.9565686],
                [106.7645266, 10.956369],
                [106.7645432, 10.9562572],
                [106.7645371, 10.9560918],
                [106.7643152, 10.9560976],
                [106.7641327, 10.9560595],
                [106.7640247, 10.9559669],
                [106.764, 10.9558356],
                [106.7638768, 10.955585],
                [106.7638712, 10.9552686],
                [106.7637089, 10.9549087],
                [106.7635205, 10.954567],
                [106.7633788, 10.954441],
                [106.763244, 10.9544193],
                [106.7631149, 10.9543279],
                [106.7629861, 10.9538376],
                [106.7628782, 10.9536525],
                [106.7628212, 10.9534134],
                [106.7630927, 10.9531939],
                [106.7634482, 10.9530369],
                [106.7639086, 10.9526451],
                [106.7644555, 10.9521881],
                [106.7649982, 10.9519945],
                [106.7655166, 10.9519167],
                [106.7659061, 10.9518379],
                [106.7664, 10.95185],
                [106.7666, 10.95203],
                [106.766799, 10.9521439],
                [106.7669939, 10.9521621],
                [106.7672049, 10.9521242],
                [106.7674927, 10.9521241],
                [106.7678477, 10.9519874],
                [106.7681327, 10.9519047],
                [106.7684356, 10.9518488],
                [106.7687364, 10.9517248],
                [106.7691816, 10.9518428],
                [106.7693157, 10.9519206],
                [106.7695129, 10.9520883],
                [106.7697508, 10.9522719],
                [106.7700171, 10.9524595],
                [106.7701158, 10.9524956],
                [106.770198, 10.9525134],
                [106.770448, 10.9523138],
                [106.7706268, 10.9521641],
                [106.7708821, 10.9520361],
                [106.7709089, 10.9519394],
                [106.7709174, 10.9517086],
                [106.7710553, 10.9513979],
                [106.7710701, 10.9512041],
                [106.7710111, 10.9510126],
                [106.7708729, 10.9510345],
                [106.7707488, 10.9511543],
                [106.7705842, 10.9513],
                [106.7703992, 10.9513519],
                [106.7702224, 10.95129],
                [106.7699459, 10.9510326],
                [106.7698652, 10.9508954],
                [106.7698536, 10.9508757],
                [106.7698474, 10.9507131],
                [106.7700106, 10.9504095],
                [106.7700318, 10.9502718],
                [106.7699955, 10.9501379],
                [106.7699988, 10.9496477],
                [106.7699203, 10.9494889],
                [106.7696444, 10.9492257],
                [106.7695224, 10.949078],
                [106.7694694, 10.9485507],
                [106.7694542, 10.9484001],
                [106.7694542, 10.9483158],
                [106.7694841, 10.9481419],
                [106.7694646, 10.9480646],
                [106.7693965, 10.9479549],
                [106.7693606, 10.9478883],
                [106.7693207, 10.9477513],
                [106.7693154, 10.9475943],
                [106.7693645, 10.9474132],
                [106.7694326, 10.9473035],
                [106.769477, 10.947232],
                [106.76954, 10.9471171],
                [106.7695989, 10.9467795],
                [106.7698234, 10.9464198],
                [106.7698297, 10.9462944],
                [106.7698418, 10.9460524],
                [106.7698543, 10.9456901],
                [106.7698552, 10.9454841],
                [106.7698887, 10.9451965],
                [106.7699442, 10.9449626],
                [106.769975, 10.9447425],
                [106.7700045, 10.9445009],
                [106.7700984, 10.9442358],
                [106.7702931, 10.9439862],
                [106.770353, 10.9439095],
                [106.7705642, 10.9436539],
                [106.7705819, 10.9436315],
                [106.7708159, 10.9433366],
                [106.7709313, 10.9432076],
                [106.77103, 10.9430974],
                [106.7714514, 10.94264],
                [106.771894, 10.9423195],
                [106.7721388, 10.9420887],
                [106.772426, 10.9417709],
                [106.772497, 10.9416638],
                [106.7725437, 10.9414809],
                [106.7725681, 10.9413212],
                [106.7726604, 10.9412035],
                [106.773075, 10.9409904],
                [106.7734407, 10.9407131],
                [106.7739378, 10.9403625],
                [106.7745867, 10.9398988],
                [106.7754205, 10.9403084],
                [106.7757208, 10.9398025],
                [106.7760347, 10.939322],
                [106.7763847, 10.9386308],
                [106.7765142, 10.9386137],
                [106.7765293, 10.9387446],
                [106.7765633, 10.9388198],
                [106.776612, 10.9388543],
                [106.7769888, 10.9393231],
                [106.7773665, 10.9392626],
                [106.7776854, 10.939127],
                [106.77785, 10.939256],
                [106.7783206, 10.9391206],
                [106.7784331, 10.9389077],
                [106.7783024, 10.9384722],
                [106.7780205, 10.9382982],
                [106.7778202, 10.9379743],
                [106.7777353, 10.9378449],
                [106.7777732, 10.9377307],
                [106.7792968, 10.9368205],
                [106.7795979, 10.9372326],
                [106.7798089, 10.9370943],
                [106.7806975, 10.9377104],
                [106.7811681, 10.9379259],
                [106.7820358, 10.9382108],
                [106.7821964, 10.9376518],
                [106.7824099, 10.9371268],
                [106.7825546, 10.936845],
                [106.7826645, 10.936631],
                [106.7828995, 10.9363108],
                [106.7830512, 10.9361467],
                [106.7833506, 10.9358094],
                [106.7834508, 10.935464],
                [106.7835367, 10.9351085],
                [106.7835554, 10.9349478],
                [106.7835579, 10.9347995],
                [106.7834441, 10.9345784],
                [106.7834213, 10.9345449],
                [106.7834066, 10.9345027],
                [106.7833932, 10.9344198],
                [106.7833891, 10.9343407],
                [106.7833853, 10.9342863],
                [106.7833865, 10.9342447],
                [106.7833969, 10.9341985],
                [106.7835299, 10.9337561],
                [106.7836628, 10.9332905],
                [106.7837289, 10.9330855],
                [106.7836955, 10.9327519],
                [106.7845814, 10.93287],
                [106.7847065, 10.9329592],
                [106.7850287, 10.933107],
                [106.7853055, 10.9332312],
                [106.7855015, 10.9332972],
                [106.7856167, 10.9333045],
                [106.7856945, 10.9332926],
                [106.7857897, 10.9332545],
                [106.7858742, 10.933215],
                [106.7859902, 10.9331581],
                [106.7861639, 10.933061],
                [106.786237, 10.9330188],
                [106.7863323, 10.9329726],
                [106.786587, 10.9329128],
                [106.7872169, 10.9328198],
                [106.7873749, 10.9328054],
                [106.787453, 10.932811],
                [106.7876606, 10.9328502],
                [106.7879073, 10.9328884],
                [106.788429, 10.9329358],
                [106.7886276, 10.9329235],
                [106.7888072, 10.93287],
                [106.7890714, 10.9327475],
                [106.7892927, 10.9326329],
                [106.7895595, 10.9325364],
                [106.7897809, 10.9324512],
                [106.7901549, 10.9320904],
                [106.7903065, 10.9319686],
                [106.7904595, 10.9318901],
                [106.7905823, 10.9318597],
                [106.7907522, 10.9318458],
                [106.7908685, 10.9316683],
                [106.7912547, 10.9311121],
                [106.7914755, 10.9307669],
                [106.7916093, 10.9305576],
                [106.7916787, 10.9301751],
                [106.7917143, 10.9300488],
                [106.7917697, 10.9298467],
                [106.7917804, 10.9296978],
                [106.7917737, 10.9296215],
                [106.7917503, 10.9295358],
                [106.7913716, 10.928491],
                [106.7913505, 10.9284262],
                [106.7913499, 10.9283403],
                [106.791373, 10.9282419],
                [106.7913902, 10.9281757],
                [106.7914289, 10.9281009],
                [106.791586, 10.9278457],
                [106.7916465, 10.9276932],
                [106.7916852, 10.9275133],
                [106.7917284, 10.9272107],
                [106.791762, 10.926845],
                [106.7917716, 10.9265979],
                [106.7912024, 10.9261439],
                [106.7897203, 10.9247312],
                [106.7891991, 10.9242344],
                [106.788412, 10.923234],
                [106.7880575, 10.9228611],
                [106.788181, 10.9221415],
                [106.7944724, 10.9236986],
                [106.7945606, 10.9233615],
                [106.7945565, 10.9232285],
                [106.7945498, 10.9231574],
                [106.794578, 10.9230849],
                [106.7946517, 10.9230362],
                [106.794814, 10.9230217],
                [106.7949629, 10.9230573],
                [106.7950715, 10.9230665],
                [106.7954296, 10.9229914],
                [106.7958051, 10.9228756],
                [106.7960733, 10.9227834],
                [106.7962745, 10.922732],
                [106.796367, 10.9227031],
                [106.7964408, 10.9226728],
                [106.7965749, 10.9225911],
                [106.7968608, 10.9223486],
                [106.7969195, 10.9222988],
                [106.7969424, 10.9222553],
                [106.7969598, 10.9222158],
                [106.7970963, 10.922113],
                [106.7972135, 10.9220793],
                [106.7976084, 10.9219833],
                [106.7976728, 10.9219603],
                [106.797925, 10.921868],
                [106.7981646, 10.9219254],
                [106.7983255, 10.9219541],
                [106.7986454, 10.9219704],
                [106.7987793, 10.9219455],
                [106.7991128, 10.9216978],
                [106.7993854, 10.921396],
                [106.8004939, 10.9206894],
                [106.8006651, 10.9205987],
                [106.8007159, 10.9204817],
                [106.8008751, 10.9202691],
                [106.8009874, 10.9201837],
                [106.8011304, 10.9200413],
                [106.8013282, 10.9200262],
                [106.8014584, 10.9199776],
                [106.8015833, 10.9199396],
                [106.80177, 10.9199364],
                [106.8018518, 10.9199083],
                [106.8018714, 10.9198638],
                [106.8019873, 10.9198131],
                [106.8020984, 10.9197558],
                [106.802201, 10.9196896],
                [106.8022671, 10.9196712],
                [106.8023806, 10.9197184],
                [106.8025498, 10.91987],
                [106.8026843, 10.9199737],
                [106.8028004, 10.9199661],
                [106.802971, 10.9199431],
                [106.8032507, 10.9199209],
                [106.8033938, 10.9199549],
                [106.8034555, 10.9200007],
                [106.8036318, 10.9201268],
                [106.80383, 10.9202312],
                [106.8039428, 10.920294],
                [106.8040366, 10.9202974],
                [106.8041494, 10.9203914],
                [106.8042139, 10.9204521],
                [106.8042935, 10.9204934],
                [106.8043763, 10.9204933],
                [106.8045414, 10.9204718],
                [106.8046582, 10.920526],
                [106.8047917, 10.9205081],
                [106.804846, 10.9204437],
                [106.804933, 10.9204295],
                [106.8050241, 10.920413],
                [106.8051213, 10.9204229],
                [106.805174, 10.920489],
                [106.8051757, 10.9205914],
                [106.8051689, 10.9206481],
                [106.8051725, 10.9207188],
                [106.8052364, 10.9207618],
                [106.8053152, 10.9207788],
                [106.8054327, 10.9207418],
                [106.8056225, 10.9206909],
                [106.805859, 10.9206617],
                [106.8060554, 10.9206607],
                [106.8062038, 10.9206909],
                [106.8063811, 10.9206912],
                [106.8065054, 10.9206737],
                [106.8066003, 10.9206064],
                [106.8066884, 10.9204916],
                [106.8067403, 10.9204411],
                [106.8068517, 10.9204403],
                [106.8069197, 10.9204754],
                [106.8070084, 10.9204644],
                [106.8071877, 10.9204182],
                [106.80732, 10.9203862],
                [106.8074183, 10.92038],
                [106.8075459, 10.9203797],
                [106.8075834, 10.9203497],
                [106.8076854, 10.9202163],
                [106.8077039, 10.9201316],
                [106.8077177, 10.9200786],
                [106.8077308, 10.9200278],
                [106.8077718, 10.9199622],
                [106.8079227, 10.9199365],
                [106.8080389, 10.9199218],
                [106.8081737, 10.9199017],
                [106.8082569, 10.9198351],
                [106.8082754, 10.9198033],
                [106.8083077, 10.9197806],
                [106.8083709, 10.9197549],
                [106.8084248, 10.9197443],
                [106.8085049, 10.9197685],
                [106.8085604, 10.9197988],
                [106.8086176, 10.9198163],
                [106.8086775, 10.9198185],
                [106.8087637, 10.9198003],
                [106.8088433, 10.9197193],
                [106.8089317, 10.9198185],
                [106.8090041, 10.9198427],
                [106.809041, 10.9198396],
                [106.8091165, 10.9197685],
                [106.8091258, 10.9197247],
                [106.8091011, 10.919699],
                [106.8089763, 10.91964],
                [106.8089163, 10.9196052],
                [106.8089147, 10.9195658],
                [106.8089347, 10.919519],
                [106.809041, 10.9194327],
                [106.8091776, 10.919286],
                [106.8091545, 10.9191478],
                [106.8093271, 10.9190994],
                [106.8093086, 10.9190147],
                [106.8093332, 10.9189361],
                [106.8094811, 10.9189119],
                [106.8095797, 10.9188695],
                [106.8098014, 10.9187506],
                [106.809834, 10.9188065],
                [106.8099031, 10.9187666],
                [106.8099722, 10.9186588],
                [106.8100738, 10.9185829],
                [106.8102121, 10.9185111],
                [106.8104153, 10.918543],
                [106.8106349, 10.9186069],
                [106.8107446, 10.9186428],
                [106.8108463, 10.9185869],
                [106.8108829, 10.9185271],
                [106.8108625, 10.9184712],
                [106.8107853, 10.9182716],
                [106.810769, 10.9181997],
                [106.8107731, 10.9181399],
                [106.8108381, 10.9180121],
                [106.8110333, 10.9178884],
                [106.8112935, 10.9177606],
                [106.811513, 10.9176968],
                [106.8118667, 10.9177686],
                [106.8120253, 10.9176329],
                [106.8121066, 10.9175251],
                [106.8121188, 10.9173934],
                [106.8120984, 10.9172776],
                [106.8120537, 10.9171938],
                [106.8119521, 10.9171618],
                [106.8118017, 10.9171259],
                [106.8116869, 10.9170299],
                [106.8116285, 10.9169329],
                [106.8116192, 10.9167384],
                [106.8116873, 10.9165105],
                [106.8117483, 10.9163985],
                [106.8117442, 10.9161719],
                [106.8116285, 10.9160761],
                [106.8115049, 10.9159204],
                [106.8114398, 10.9157966],
                [106.8114398, 10.9157008],
                [106.8115171, 10.9154174],
                [106.8116468, 10.9151525],
                [106.8119107, 10.9149979],
                [106.8121889, 10.9146407],
                [106.8122817, 10.9144456],
                [106.8123113, 10.9142923],
                [106.8123867, 10.914157],
                [106.8126065, 10.9139736],
                [106.8129821, 10.9137711],
                [106.8131718, 10.9136371],
                [106.8134194, 10.9133823],
                [106.8136169, 10.9131862],
                [106.8136999, 10.9131015],
                [106.813853, 10.912847],
                [106.8140498, 10.9124256],
                [106.8142932, 10.9118205],
                [106.8146322, 10.9110642],
                [106.8149033, 10.9106558],
                [106.8151867, 10.9103502],
                [106.8151517, 10.909945],
                [106.8150573, 10.9095273],
                [106.8150629, 10.9092266],
                [106.8151128, 10.9089525],
                [106.8151686, 10.908363],
                [106.815196, 10.9081603],
                [106.815273, 10.9079723],
                [106.8151445, 10.9078422],
                [106.8146491, 10.9077866],
                [106.8141844, 10.9077608],
                [106.8140491, 10.9077015],
                [106.8139688, 10.9076022],
                [106.813948, 10.9075083],
                [106.8139652, 10.9074109],
                [106.81405, 10.9073046],
                [106.8143675, 10.9069945],
                [106.8145362, 10.9067899],
                [106.8146391, 10.9065968],
                [106.8147266, 10.9063567],
                [106.8147726, 10.9061636],
                [106.8147663, 10.9059465],
                [106.814695, 10.9056273],
                [106.81468, 10.905451],
                [106.8146987, 10.9051866],
                [106.8147212, 10.9048707],
                [106.8148035, 10.9046944],
                [106.8149612, 10.9045417],
                [106.8152486, 10.9042634],
                [106.8153651, 10.9041374],
                [106.8155117, 10.9039789],
                [106.8157943, 10.9036694],
                [106.8161058, 10.9033521],
                [106.8164445, 10.9031986],
                [106.8172129, 10.9031602],
                [106.8188184, 10.9031667],
                [106.8213619, 10.9031744],
                [106.8214979, 10.9037038],
                [106.8216465, 10.9038357],
                [106.821839, 10.903836],
                [106.822108, 10.9035586],
                [106.8223179, 10.903154],
                [106.8227046, 10.9029541],
                [106.8231333, 10.9028379],
                [106.823553, 10.9027958],
                [106.8240236, 10.9029332],
                [106.8247543, 10.9031724],
                [106.8253098, 10.9033543],
                [106.8257537, 10.9036049],
                [106.8261344, 10.903703],
                [106.8265256, 10.9038206],
                [106.8273042, 10.9038943],
                [106.8280829, 10.9039136],
                [106.8286123, 10.9041012],
                [106.8292202, 10.9044691],
                [106.8298454, 10.904853],
                [106.8300135, 10.9050043],
                [106.8301816, 10.9052657],
                [106.8304712, 10.9058343],
                [106.8307841, 10.9062975],
                [106.8316807, 10.9067331],
                [106.8319554, 10.9067418],
                [106.832115, 10.9067468],
                [106.832498, 10.9065634],
                [106.8326707, 10.9063525],
                [106.8327651, 10.9061241],
                [106.8331938, 10.9050868],
                [106.8334665, 10.9046704],
                [106.8340118, 10.9041743],
                [106.8345714, 10.9036653],
                [106.8347489, 10.9035873],
                [106.8350524, 10.9035094],
                [106.835851, 10.9033351],
                [106.839302, 10.902997],
                [106.8393086, 10.9027713],
                [106.8393242, 10.9025461],
                [106.8393487, 10.9023217],
                [106.8393822, 10.9020984],
                [106.8394152, 10.9019214],
                [106.8394404, 10.9017613],
                [106.8394873, 10.9015656],
                [106.8396395, 10.9009128],
                [106.8397869, 10.9002559],
                [106.8399404, 10.8996004],
                [106.8401265, 10.8988461],
                [106.8403026, 10.8981484],
                [106.8405143, 10.8973527],
                [106.8407348, 10.8965594],
                [106.8409641, 10.8957685],
                [106.8412022, 10.8949801],
                [106.841449, 10.8941943],
                [106.8417045, 10.8934112],
                [106.8419687, 10.8926308],
                [106.8422415, 10.8918533],
                [106.8425229, 10.8910788],
                [106.8428129, 10.8903073],
                [106.8431115, 10.889539],
                [106.8434185, 10.8887739],
                [106.843734, 10.8880122],
                [106.844058, 10.8872538],
                [106.8442762, 10.8866992],
                [106.8445031, 10.886148],
                [106.8447386, 10.8856003],
                [106.8449825, 10.8850561],
                [106.845235, 10.8845157],
                [106.8454958, 10.8839792],
                [106.845765, 10.8834466],
                [106.8460424, 10.8829182],
                [106.8463281, 10.882394],
                [106.8466219, 10.8818741],
                [106.8469238, 10.8813588],
                [106.8472337, 10.880848],
                [106.8475366, 10.8803654],
                [106.8478466, 10.8798872],
                [106.8481637, 10.8794134],
                [106.8484879, 10.8789443],
                [106.8488189, 10.8784799],
                [106.8491569, 10.8780203],
                [106.8495016, 10.8775655],
                [106.8498531, 10.8771158],
                [106.8502112, 10.8766712],
                [106.8505759, 10.8762317],
                [106.8509472, 10.8757975],
                [106.8513221, 10.8753108],
                [106.8510747, 10.8745264],
                [106.8508455, 10.8738247],
                [106.8506848, 10.8733023],
                [106.8505158, 10.8727719],
                [106.8503385, 10.8722441],
                [106.8501528, 10.871719],
                [106.8499589, 10.8711969],
                [106.8497456, 10.87065],
                [106.8495233, 10.8701066],
                [106.849292, 10.8695668],
                [106.8490517, 10.8690308],
                [106.8488025, 10.8684988],
                [106.8485445, 10.8679708],
                [106.8482777, 10.867447],
                [106.8480023, 10.8669276],
                [106.8477183, 10.8664126],
                [106.8474257, 10.8659023],
                [106.8471247, 10.8653968],
                [106.8468153, 10.8648961],
                [106.8471315, 10.8639175],
                [106.847457, 10.8629419],
                [106.847792, 10.8619694],
                [106.8481364, 10.861],
                [106.8484901, 10.8600338],
                [106.8488531, 10.859071],
                [106.8492254, 10.8581116],
                [106.8496069, 10.8571557],
                [106.8499977, 10.8562035],
                [106.8503976, 10.8552548],
                [106.8508067, 10.85431],
                [106.8512249, 10.853369],
                [106.8516355, 10.8524679],
                [106.8520545, 10.8515705],
                [106.8524818, 10.8506768],
                [106.8529174, 10.8497871],
                [106.8533613, 10.8489013],
                [106.8538133, 10.8480194],
                [106.8542735, 10.8471417],
                [106.8547418, 10.8462681],
                [106.8552183, 10.8453988],
                [106.8557027, 10.8445337],
                [106.8561952, 10.843673],
                [106.8563708, 10.8434004],
                [106.8565537, 10.8431324],
                [106.856744, 10.8428695],
                [106.8569283, 10.8426284],
                [106.8571188, 10.8423919],
                [106.8573153, 10.8421602],
                [106.8575177, 10.8419335],
                [106.8577259, 10.8417119],
                [106.858749, 10.8406005],
                [106.8597649, 10.8394829],
                [106.8607737, 10.838359],
                [106.8617751, 10.8372288],
                [106.8627693, 10.8360924],
                [106.8637561, 10.8349499],
                [106.8646831, 10.8338632],
                [106.8656035, 10.832771],
                [106.8665172, 10.8316734],
                [106.8674243, 10.8305705],
                [106.8683245, 10.8294623],
                [106.8692181, 10.8283488],
                [106.8701048, 10.8272301],
                [106.8703438, 10.8269096],
                [106.8705903, 10.8265947],
                [106.8708442, 10.8262855],
                [106.8711054, 10.8259823],
                [106.871349, 10.8257119],
                [106.8715983, 10.8254466],
                [106.8718532, 10.8251866],
                [106.8721138, 10.824932],
                [106.8723797, 10.8246828],
                [106.8771366, 10.8198366],
                [106.8781227, 10.81854],
                [106.8794978, 10.8158428],
                [106.8810585, 10.8124723],
                [106.8815533, 10.8106588],
                [106.8816459, 10.8100813],
                [106.8817591, 10.8083737],
                [106.8815567, 10.8065311],
                [106.8806878, 10.8048208],
                [106.8786841, 10.8021248],
                [106.875679, 10.7985086],
                [106.8685382, 10.7931859],
                [106.8657355, 10.7912147],
                [106.8647338, 10.7899654],
                [106.8643995, 10.7891759],
                [106.8642654, 10.7884521],
                [106.8643611, 10.7866344],
                [106.8643661, 10.7865777],
                [106.8646648, 10.7851774],
                [106.8649947, 10.7844494],
                [106.8654422, 10.7837295],
                [106.8666452, 10.7823956],
                [106.8687108, 10.7802497],
                [106.8704794, 10.7782938],
                [106.8713587, 10.7772307],
                [106.871809, 10.7765179],
                [106.8722001, 10.7758989],
                [106.8733521, 10.7736419],
                [106.873868, 10.7722942],
                [106.8741142, 10.7711991],
                [106.8741548, 10.7704548],
                [106.874022, 10.7694337],
                [106.8738005, 10.7687001],
                [106.8731978, 10.7676997],
                [106.8726326, 10.7669322],
                [106.8717511, 10.7661577],
                [106.8707164, 10.7654647],
                [106.8694652, 10.7648269],
                [106.8682886, 10.7645651],
                [106.8668961, 10.7644838],
                [106.8652882, 10.764637],
                [106.8638538, 10.7650669],
                [106.8624117, 10.765739],
                [106.8609354, 10.766716],
                [106.8584194, 10.7691155],
                [106.8542618, 10.7735777],
                [106.8536416, 10.7741461],
                [106.8530668, 10.7744513],
                [106.8521205, 10.7749362],
                [106.8512722, 10.7751564],
                [106.8501931, 10.7753008],
                [106.8487105, 10.7754991],
                [106.8465768, 10.7754044],
                [106.8450574, 10.7750983],
                [106.8434153, 10.7745058],
                [106.8426174, 10.7741932],
                [106.8416745, 10.7737327],
                [106.8412183, 10.7734612],
                [106.8407189, 10.7731639],
                [106.8390248, 10.771997],
                [106.8377052, 10.7710232],
                [106.8363335, 10.7699108],
                [106.8355853, 10.7691959],
                [106.8313625, 10.7642026],
                [106.8311907, 10.7639801],
                [106.8304975, 10.7629443],
                [106.8296395, 10.7615509],
                [106.8293199, 10.761024],
                [106.8289198, 10.7606319],
                [106.8283027, 10.7602232],
                [106.8266356, 10.7595517],
                [106.8257854, 10.7594491],
                [106.8248281, 10.7595803],
                [106.8242511, 10.7598168],
                [106.8238554, 10.7601599],
                [106.8234613, 10.7607717],
                [106.8231949, 10.7615441],
                [106.8230374, 10.7624235],
                [106.8230167, 10.7634726],
                [106.8231484, 10.7643324],
                [106.8238146, 10.7668923],
                [106.8246696, 10.7692807],
                [106.8253325, 10.7712668],
                [106.8256022, 10.7725292],
                [106.8257376, 10.7739807],
                [106.8256227, 10.7759176],
                [106.8252961, 10.7772193],
                [106.8250649, 10.7778482],
                [106.8243479, 10.7788743],
                [106.8234677, 10.7798119],
                [106.8225319, 10.7805166],
                [106.8222278, 10.7806902],
                [106.8221987, 10.7807068],
                [106.8217297, 10.7808709],
                [106.8209711, 10.7810009],
                [106.8203384, 10.7810046],
                [106.8194156, 10.7808846],
                [106.8179665, 10.7803911],
                [106.8163172, 10.7797015],
                [106.8145391, 10.7786541],
                [106.8128045, 10.7772928],
                [106.8107136, 10.775297],
                [106.8100933, 10.7746693],
                [106.8096023, 10.7741724],
                [106.807322, 10.7719553],
                [106.8066849, 10.7712239],
                [106.8060649, 10.7703311],
                [106.8056713, 10.7695715],
                [106.8052142, 10.7686419],
                [106.8046622, 10.7670314],
                [106.8038509, 10.7643648],
                [106.8034011, 10.7631931],
                [106.8032183, 10.7628535],
                [106.8027078, 10.7621394],
                [106.8018691, 10.760943],
                [106.8013778, 10.760408],
                [106.8003974, 10.7596965],
                [106.7997835, 10.7592974],
                [106.7993177, 10.7589946],
                [106.7991459, 10.7589081],
                [106.7968881, 10.7577716],
                [106.7928651, 10.7561097],
                [106.791652, 10.7556011],
                [106.790432, 10.7550895],
                [106.788688, 10.7543858],
                [106.7853359, 10.7530425],
                [106.7834782, 10.7522195],
                [106.7834293, 10.7521969],
                [106.7773151, 10.7493683],
                [106.774151, 10.7476922],
                [106.7726629, 10.7466967],
                [106.7708469, 10.7452907],
                [106.7697018, 10.7442035],
                [106.7686561, 10.7431427],
                [106.7656121, 10.7388033],
                [106.764866, 10.7377245],
                [106.7550705, 10.7253651],
                [106.7536556, 10.7234634],
                [106.7528834, 10.7219417],
                [106.752304, 10.7204199],
                [106.7519171, 10.7185809],
                [106.7517273, 10.7166237],
                [106.7512531, 10.713473],
                [106.7512311, 10.7127242],
                [106.7512245, 10.7123764],
                [106.7511678, 10.7103776],
            ],
        ],
    },
    {
        type: 'Polygon',
        coordinates: [
            [
                [105.1855756, 10.8828772],
                [105.1859618, 10.8805888],
                [105.1864282, 10.8772815],
                [105.1868389, 10.8744824],
                [105.1873634, 10.8720324],
                [105.1885504, 10.8698535],
                [105.1903727, 10.8678851],
                [105.1924626, 10.8660834],
                [105.202474, 10.8588325],
                [105.205449, 10.8557529],
                [105.207905, 10.8520945],
                [105.2096277, 10.8486492],
                [105.2123915, 10.842827],
                [105.2153812, 10.8366421],
                [105.2170476, 10.8325095],
                [105.2187838, 10.828444],
                [105.2202178, 10.8250579],
                [105.222449, 10.8200756],
                [105.2245418, 10.8157362],
                [105.2250282, 10.8147277],
                [105.2292441, 10.8081305],
                [105.2313642, 10.8068506],
                [105.2338112, 10.8057554],
                [105.2365816, 10.8049388],
                [105.2402082, 10.8043819],
                [105.2463798, 10.8043356],
                [105.2504136, 10.8043054],
                [105.2532651, 10.8040495],
                [105.2577256, 10.8032376],
                [105.2591415, 10.8026979],
                [105.2595967, 10.8023382],
                [105.260894, 10.7998035],
                [105.2624165, 10.7968287],
                [105.262717, 10.7946171],
                [105.2621026, 10.7920534],
                [105.2613109, 10.79018],
                [105.2608317, 10.7886989],
                [105.2597163, 10.7869041],
                [105.2587309, 10.7852639],
                [105.2582588, 10.784101],
                [105.258099, 10.7824134],
                [105.2588103, 10.7781032],
                [105.2595726, 10.7747437],
                [105.2606196, 10.7723304],
                [105.2616392, 10.7710262],
                [105.2627409, 10.7699977],
                [105.2644546, 10.7688058],
                [105.2655776, 10.7680979],
                [105.267007, 10.7671538],
                [105.2680276, 10.7666206],
                [105.2688788, 10.7661758],
                [105.2715676, 10.764894],
                [105.273474, 10.7643536],
                [105.2756536, 10.7638466],
                [105.2774601, 10.7635844],
                [105.2819516, 10.7635041],
                [105.2866165, 10.7635671],
                [105.2911597, 10.7634702],
                [105.295231, 10.7633231],
                [105.2986012, 10.7626801],
                [105.3004049, 10.7617693],
                [105.3026422, 10.7603576],
                [105.3036208, 10.759161],
                [105.3042093, 10.7576282],
                [105.3043325, 10.755887],
                [105.3042435, 10.7545962],
                [105.3040956, 10.753801],
                [105.3038808, 10.7526466],
                [105.3031144, 10.7505491],
                [105.3022129, 10.7491917],
                [105.3011324, 10.7479434],
                [105.2997646, 10.7468644],
                [105.2981845, 10.745869],
                [105.2904823, 10.741742],
                [105.2851585, 10.7389788],
                [105.2828319, 10.7374796],
                [105.2789622, 10.7346193],
                [105.2770873, 10.7321969],
                [105.2760298, 10.7300094],
                [105.2755854, 10.7279224],
                [105.2756184, 10.7268786],
                [105.2768785, 10.72486],
                [105.2784549, 10.723134],
                [105.2802099, 10.7218242],
                [105.2826882, 10.7205771],
                [105.2874372, 10.7185668],
                [105.291365, 10.7171817],
                [105.2944307, 10.7166438],
                [105.2970447, 10.7161799],
                [105.2989334, 10.7160656],
                [105.3007946, 10.7161597],
                [105.3026491, 10.716153],
                [105.3060774, 10.7165699],
                [105.3086435, 10.717222],
                [105.3114628, 10.7181701],
                [105.3128603, 10.7185667],
                [105.3139263, 10.7188693],
                [105.3184221, 10.7203687],
                [105.3218128, 10.72148],
                [105.3252862, 10.7222507],
                [105.3293383, 10.722886],
                [105.3339146, 10.7231522],
                [105.3393274, 10.7227488],
                [105.3419856, 10.7220599],
                [105.3423109, 10.7219756],
                [105.3455818, 10.7200594],
                [105.3474705, 10.7181365],
                [105.3485791, 10.7168522],
                [105.3494618, 10.7156218],
                [105.3494618, 10.7128853],
                [105.3487948, 10.7079494],
                [105.3484506, 10.7046502],
                [105.3481753, 10.7021254],
                [105.3477986, 10.7001394],
                [105.3466139, 10.6941906],
                [105.3462858, 10.6929003],
                [105.3444122, 10.6861035],
                [105.3418438, 10.6822974],
                [105.3402655, 10.6790642],
                [105.339249, 10.6751211],
                [105.338714, 10.6717826],
                [105.3388478, 10.6694956],
                [105.338928, 10.6673137],
                [105.339035, 10.6647638],
                [105.3388745, 10.6624241],
                [105.338928, 10.6599793],
                [105.3390885, 10.6573505],
                [105.3395433, 10.6546164],
                [105.3397305, 10.6517509],
                [105.3399068, 10.6493834],
                [105.3404795, 10.645941],
                [105.341068, 10.6428651],
                [105.342138, 10.6400257],
                [105.3425676, 10.6387505],
                [105.3429069, 10.6376416],
                [105.3430844, 10.6368791],
                [105.3437031, 10.6344762],
                [105.3446514, 10.6311977],
                [105.3451444, 10.6294077],
                [105.3457514, 10.6273727],
                [105.3466242, 10.6247535],
                [105.3476492, 10.6219458],
                [105.349434, 10.6176115],
                [105.3517397, 10.6123908],
                [105.3548162, 10.6065337],
                [105.3565188, 10.6030808],
                [105.359044, 10.597201],
                [105.3600502, 10.59477],
                [105.3631832, 10.5877405],
                [105.3639619, 10.5862326],
                [105.3666959, 10.5800135],
                [105.3672273, 10.5786566],
                [105.3673433, 10.5781521],
                [105.3685136, 10.5757658],
                [105.3714294, 10.5730573],
                [105.3737289, 10.5717863],
                [105.3754419, 10.5714795],
                [105.3773412, 10.5715847],
                [105.3788659, 10.571611],
                [105.3818084, 10.5717425],
                [105.3833272, 10.5716957],
                [105.3850195, 10.5714463],
                [105.3869615, 10.5711187],
                [105.3894154, 10.5705019],
                [105.3923958, 10.5693865],
                [105.3942053, 10.5685242],
                [105.396037, 10.5675115],
                [105.3974414, 10.5661309],
                [105.399354, 10.5642507],
                [105.4035671, 10.5598723],
                [105.404811, 10.5584128],
                [105.4056938, 10.5574398],
                [105.4068717, 10.555985],
                [105.4081337, 10.5545654],
                [105.409519, 10.553482],
                [105.4117928, 10.5518647],
                [105.4138323, 10.5510784],
                [105.4156778, 10.550379],
                [105.4169659, 10.5500111],
                [105.4181708, 10.5498294],
                [105.4191798, 10.5498658],
                [105.4215405, 10.5501263],
                [105.4238443, 10.5503868],
                [105.4318792, 10.5515813],
                [105.4392409, 10.5525954],
                [105.4419512, 10.5529421],
                [105.4437979, 10.5530525],
                [105.446151, 10.5533907],
                [105.4480489, 10.5516393],
                [105.4504559, 10.5499727],
                [105.4557248, 10.5472527],
                [105.4615829, 10.5440608],
                [105.4668891, 10.5409638],
                [105.4697612, 10.5394902],
                [105.4716636, 10.5387716],
                [105.4736614, 10.5381846],
                [105.4756207, 10.5373717],
                [105.4770284, 10.5367668],
                [105.4791208, 10.5358783],
                [105.4798628, 10.5356135],
                [105.485536, 10.5338067],
                [105.4870739, 10.5380927],
                [105.4879079, 10.5408766],
                [105.4883327, 10.5423892],
                [105.4951099, 10.5441969],
                [105.4962272, 10.5444547],
                [105.4978575, 10.5448969],
                [105.4988683, 10.5451333],
                [105.4992478, 10.5438938],
                [105.4998578, 10.5441775],
                [105.5004015, 10.5443422],
                [105.5012531, 10.5447662],
                [105.5017896, 10.5449984],
                [105.5024266, 10.5452074],
                [105.5027359, 10.5453167],
                [105.5030241, 10.5454589],
                [105.503446, 10.5456817],
                [105.5038233, 10.5458417],
                [105.5041042, 10.5459417],
                [105.5043845, 10.5460361],
                [105.5046508, 10.546109],
                [105.5049644, 10.5462184],
                [105.5053926, 10.5462913],
                [105.5057263, 10.5462581],
                [105.5060905, 10.5462581],
                [105.5068389, 10.5461587],
                [105.507611, 10.545993],
                [105.5084741, 10.5457411],
                [105.5092361, 10.5453798],
                [105.5097013, 10.5452273],
                [105.5099036, 10.5452008],
                [105.5101362, 10.5453035],
                [105.5105408, 10.5457842],
                [105.510851, 10.5463642],
                [105.5112036, 10.5468542],
                [105.5114972, 10.5475967],
                [105.5124941, 10.5473365],
                [105.5133296, 10.5470926],
                [105.514914, 10.5463808],
                [105.517242, 10.5453616],
                [105.5190997, 10.5445224],
                [105.5210537, 10.5436113],
                [105.5233936, 10.542413],
                [105.5252027, 10.5414662],
                [105.5284225, 10.5396213],
                [105.5309067, 10.5381837],
                [105.5324501, 10.5371896],
                [105.5351024, 10.53525],
                [105.5369084, 10.5339578],
                [105.5381526, 10.5330827],
                [105.5388398, 10.5325798],
                [105.5402017, 10.531395],
                [105.542829, 10.5289775],
                [105.5455764, 10.5263449],
                [105.5485643, 10.5232101],
                [105.5503469, 10.5210091],
                [105.5516466, 10.5188567],
                [105.553128, 10.5165622],
                [105.5545806, 10.5143369],
                [105.5560278, 10.5119448],
                [105.5567735, 10.5105101],
                [105.5577666, 10.5083691],
                [105.5590334, 10.5053459],
                [105.5602353, 10.5025725],
                [105.5609081, 10.5008751],
                [105.5615041, 10.4996412],
                [105.5622417, 10.4975732],
                [105.5634498, 10.4946403],
                [105.5646573, 10.4915076],
                [105.5665368, 10.487197],
                [105.5682378, 10.4836416],
                [105.5694918, 10.4813303],
                [105.5698776, 10.4804327],
                [105.5704006, 10.479367],
                [105.5706585, 10.4786838],
                [105.5707305, 10.4784928],
                [105.5709497, 10.4775094],
                [105.5710309, 10.476581],
                [105.5712221, 10.4754067],
                [105.5716333, 10.4736585],
                [105.5725909, 10.4685784],
                [105.5729461, 10.4664209],
                [105.5731646, 10.465028],
                [105.5732733, 10.4640723],
                [105.5734093, 10.4629253],
                [105.5736265, 10.4608772],
                [105.5737875, 10.4583105],
                [105.573896, 10.4572182],
                [105.5740868, 10.455798],
                [105.5743053, 10.4544598],
                [105.5746611, 10.4526845],
                [105.5750174, 10.4511276],
                [105.575482, 10.4484237],
                [105.5755902, 10.4471677],
                [105.5756159, 10.44613],
                [105.5755301, 10.4445467],
                [105.5751105, 10.4417898],
                [105.574607, 10.4383779],
                [105.5744383, 10.436822],
                [105.5741857, 10.4347202],
                [105.5741558, 10.4334916],
                [105.5740421, 10.4317171],
                [105.5741224, 10.4303518],
                [105.5741207, 10.4294508],
                [105.5717975, 10.4288504],
                [105.5696548, 10.4290813],
                [105.567916, 10.4293632],
                [105.5668904, 10.4294655],
                [105.5657508, 10.4296732],
                [105.5647566, 10.4299318],
                [105.5637901, 10.4301793],
                [105.5617968, 10.4308805],
                [105.5595081, 10.431853],
                [105.5571188, 10.4330318],
                [105.5548257, 10.4343101],
                [105.5546831, 10.4341697],
                [105.5541938, 10.4336275],
                [105.5537097, 10.433281],
                [105.5532568, 10.4329857],
                [105.5528202, 10.4326242],
                [105.5523585, 10.4323891],
                [105.5521237, 10.432077],
                [105.5519048, 10.4317556],
                [105.551762, 10.4313874],
                [105.5517205, 10.4311949],
                [105.551625, 10.4308095],
                [105.5515811, 10.4305729],
                [105.5514891, 10.4300175],
                [105.5514479, 10.4294433],
                [105.5511496, 10.4293777],
                [105.5508767, 10.4294963],
                [105.5505594, 10.4297054],
                [105.5502789, 10.429811],
                [105.5498944, 10.4298727],
                [105.5495609, 10.4298683],
                [105.5493904, 10.4298419],
                [105.5490763, 10.4298021],
                [105.5488946, 10.4297249],
                [105.5487263, 10.4296543],
                [105.5485221, 10.4294712],
                [105.5484218, 10.4291929],
                [105.5483647, 10.4289134],
                [105.548353, 10.4286785],
                [105.5483853, 10.4282112],
                [105.5484952, 10.4276882],
                [105.5486208, 10.4271719],
                [105.5488923, 10.4265055],
                [105.5491279, 10.4259936],
                [105.549258, 10.4256229],
                [105.5493567, 10.4253493],
                [105.5494137, 10.4250788],
                [105.5494226, 10.4248302],
                [105.5493602, 10.4245714],
                [105.5492101, 10.4243565],
                [105.5486702, 10.4237782],
                [105.5481654, 10.423178],
                [105.5476965, 10.4226285],
                [105.547075, 10.421876],
                [105.5467654, 10.4215428],
                [105.546624, 10.4213575],
                [105.5464535, 10.4210949],
                [105.5463952, 10.4208764],
                [105.5463974, 10.4206536],
                [105.546523, 10.4204064],
                [105.5467295, 10.4201505],
                [105.5471625, 10.4199033],
                [105.5484481, 10.4196782],
                [105.5492019, 10.4195723],
                [105.5497314, 10.4194929],
                [105.5503081, 10.4194179],
                [105.5507815, 10.4193737],
                [105.5512616, 10.4192943],
                [105.551569, 10.419184],
                [105.5519235, 10.4190449],
                [105.5522466, 10.4188375],
                [105.5525607, 10.4185374],
                [105.5527491, 10.4181579],
                [105.5528456, 10.4177849],
                [105.5528456, 10.4175312],
                [105.5527761, 10.4171825],
                [105.5524956, 10.4168162],
                [105.55202, 10.4163639],
                [105.5516228, 10.416141],
                [105.5511068, 10.4159424],
                [105.5502946, 10.4156952],
                [105.549442, 10.4154481],
                [105.5489776, 10.4153047],
                [105.5483359, 10.4150774],
                [105.5477526, 10.4147618],
                [105.5472186, 10.4143447],
                [105.5467048, 10.4139277],
                [105.5462403, 10.4135239],
                [105.5458275, 10.413109],
                [105.545405, 10.412922],
                [105.5451333, 10.4128442],
                [105.5448277, 10.4128111],
                [105.5445673, 10.412834],
                [105.5441147, 10.4128795],
                [105.5435457, 10.4129921],
                [105.5431037, 10.4131377],
                [105.5419819, 10.413387],
                [105.541026, 10.4134553],
                [105.5405909, 10.4134841],
                [105.5400322, 10.4134599],
                [105.5395992, 10.4132657],
                [105.5393524, 10.4131134],
                [105.5390024, 10.4128905],
                [105.5386927, 10.4125882],
                [105.538253, 10.4121403],
                [105.5377975, 10.4115423],
                [105.5370414, 10.4103683],
                [105.5367049, 10.4099799],
                [105.5361462, 10.4096026],
                [105.5355404, 10.4093289],
                [105.5350109, 10.4091083],
                [105.53425, 10.4089028],
                [105.5335862, 10.4089538],
                [105.5331958, 10.4089604],
                [105.532682, 10.4087088],
                [105.5323612, 10.4085875],
                [105.5320381, 10.4084176],
                [105.5317778, 10.4082234],
                [105.5316096, 10.4079608],
                [105.5315086, 10.407654],
                [105.5314858, 10.4073526],
                [105.5314625, 10.4070282],
                [105.5314956, 10.4064912],
                [105.5315742, 10.4060883],
                [105.5316976, 10.4054617],
                [105.5317082, 10.4050813],
                [105.5316903, 10.4047478],
                [105.5315557, 10.4043505],
                [105.531185, 10.4035609],
                [105.5307615, 10.4028499],
                [105.5302522, 10.4017841],
                [105.5300659, 10.4013427],
                [105.5298259, 10.400716],
                [105.5295903, 10.4004159],
                [105.5292537, 10.4002769],
                [105.5288521, 10.4002526],
                [105.5284752, 10.4002702],
                [105.5278749, 10.4003895],
                [105.5267947, 10.4005858],
                [105.5261777, 10.4007204],
                [105.5255203, 10.4009301],
                [105.5251838, 10.401036],
                [105.5249033, 10.4010338],
                [105.5245847, 10.4009279],
                [105.5242774, 10.4007403],
                [105.5240158, 10.400463],
                [105.5238569, 10.4002619],
                [105.5237344, 10.4000363],
                [105.5237007, 10.3996214],
                [105.5238241, 10.3992485],
                [105.5241368, 10.3984381],
                [105.5247261, 10.3971145],
                [105.5252264, 10.395764],
                [105.5255001, 10.3945944],
                [105.5258591, 10.3934534],
                [105.526162, 10.392553],
                [105.526434, 10.3916664],
                [105.5265349, 10.3910734],
                [105.5266175, 10.3901255],
                [105.5266579, 10.3894679],
                [105.5266309, 10.3888036],
                [105.5265143, 10.3871992],
                [105.5263976, 10.3863761],
                [105.5264268, 10.3854249],
                [105.5265143, 10.3849019],
                [105.5266028, 10.3844062],
                [105.5268391, 10.3835892],
                [105.5271223, 10.3830701],
                [105.5273916, 10.3824025],
                [105.5279435, 10.3813377],
                [105.5283226, 10.3804836],
                [105.5285178, 10.3799474],
                [105.5287198, 10.3793206],
                [105.5288589, 10.3788593],
                [105.52898, 10.378301],
                [105.5289688, 10.3778971],
                [105.5289329, 10.3774028],
                [105.5289187, 10.3772106],
                [105.5289015, 10.376979],
                [105.5288867, 10.37646],
                [105.5288181, 10.3761022],
                [105.5286023, 10.3756103],
                [105.5283414, 10.3752193],
                [105.5279928, 10.3746772],
                [105.5273309, 10.3739952],
                [105.5264671, 10.3732493],
                [105.5259937, 10.3728962],
                [105.5247627, 10.3721574],
                [105.5235123, 10.371466],
                [105.5222267, 10.370868],
                [105.5215356, 10.3705766],
                [105.5211856, 10.3703736],
                [105.5209231, 10.370122],
                [105.5206382, 10.369738],
                [105.5204497, 10.3693253],
                [105.5203218, 10.3688375],
                [105.5202545, 10.3681489],
                [105.5202209, 10.3675244],
                [105.52025, 10.3668115],
                [105.5203443, 10.3660766],
                [105.5205439, 10.3651827],
                [105.5208222, 10.3641432],
                [105.5209994, 10.3634965],
                [105.5208293, 10.3633643],
                [105.5206032, 10.3631618],
                [105.520139, 10.3629724],
                [105.5195278, 10.3629063],
                [105.5190411, 10.36284],
                [105.5182376, 10.3628301],
                [105.5172416, 10.3627535],
                [105.5162229, 10.3625538],
                [105.5148983, 10.3621979],
                [105.5141396, 10.3618971],
                [105.5131882, 10.3613617],
                [105.5123157, 10.3606472],
                [105.5115902, 10.3599213],
                [105.5108533, 10.3591059],
                [105.5102749, 10.3583013],
                [105.5096624, 10.3574186],
                [105.509039, 10.3567708],
                [105.5084611, 10.3563019],
                [105.5076568, 10.3557774],
                [105.5071812, 10.3555656],
                [105.5066377, 10.3553651],
                [105.5055284, 10.3551879],
                [105.5046004, 10.3551447],
                [105.5035368, 10.3551575],
                [105.5026315, 10.355159],
                [105.5020204, 10.3551151],
                [105.5017844, 10.3550717],
                [105.5012353, 10.3550084],
                [105.500408, 10.354708],
                [105.4999499, 10.3544185],
                [105.4995838, 10.3539652],
                [105.4995598, 10.3539355],
                [105.4992092, 10.3531119],
                [105.4985833, 10.3518579],
                [105.4978271, 10.3505777],
                [105.4971447, 10.3499018],
                [105.4963166, 10.3493229],
                [105.4950019, 10.3487446],
                [105.493882, 10.3482629],
                [105.493282, 10.3478667],
                [105.4928591, 10.3475875],
                [105.4926213, 10.3473519],
                [105.4920794, 10.3468151],
                [105.4915187, 10.3460665],
                [105.4908845, 10.345052],
                [105.4905427, 10.3443273],
                [105.4904442, 10.3435538],
                [105.4905402, 10.3426832],
                [105.4907993, 10.3420012],
                [105.4914044, 10.3406924],
                [105.4915485, 10.3401638],
                [105.4916097, 10.3396015],
                [105.4915485, 10.3389086],
                [105.4911964, 10.337789],
                [105.4903966, 10.3365624],
                [105.4880479, 10.334198],
                [105.4922554, 10.3294654],
                [105.4960645, 10.3252088],
                [105.5019735, 10.319221],
                [105.5049243, 10.316206],
                [105.5102885, 10.3107729],
                [105.5108098, 10.3102568],
                [105.5148848, 10.3062226],
                [105.5179559, 10.3050516],
                [105.5215098, 10.3033946],
                [105.5263273, 10.3009182],
                [105.5308381, 10.298442],
                [105.5336947, 10.2968739],
                [105.5356676, 10.2957909],
                [105.5407344, 10.2926719],
                [105.5450171, 10.2898119],
                [105.5508758, 10.2858992],
                [105.5584234, 10.2792885],
                [105.560968, 10.276991],
                [105.565058, 10.2734396],
                [105.567547, 10.2712242],
                [105.5683613, 10.2704994],
                [105.5718888, 10.2672565],
                [105.5741579, 10.2646718],
                [105.5774749, 10.2612468],
                [105.5793012, 10.2595045],
                [105.5834542, 10.2555276],
                [105.5857102, 10.2533618],
                [105.590246, 10.2490074],
                [105.5936681, 10.2455581],
                [105.5968651, 10.2423356],
                [105.599358, 10.2394784],
                [105.5999075, 10.2387817],
                [105.6009601, 10.2374612],
                [105.602761, 10.2351323],
                [105.6042649, 10.2331256],
                [105.6057521, 10.2311175],
                [105.6078237, 10.2281835],
                [105.6091078, 10.2263741],
                [105.6104767, 10.2243673],
                [105.612453, 10.2213975],
                [105.6149423, 10.2173749],
                [105.6164719, 10.214558],
                [105.6172206, 10.2129604],
                [105.6183201, 10.2097174],
                [105.6193991, 10.206247],
                [105.6204776, 10.2025767],
                [105.621725, 10.1982738],
                [105.6232569, 10.1924381],
                [105.6242911, 10.1876274],
                [105.6247458, 10.1863385],
                [105.6277346, 10.183652],
                [105.6308173, 10.1808773],
                [105.6370187, 10.1751179],
                [105.6414572, 10.1714974],
                [105.6439136, 10.169123],
                [105.6458729, 10.1669621],
                [105.6473993, 10.1649672],
                [105.6486009, 10.1631903],
                [105.6495116, 10.161799],
                [105.6510229, 10.1596397],
                [105.6523035, 10.157896],
                [105.6536006, 10.1561783],
                [105.6568974, 10.1521727],
                [105.6573919, 10.1516171],
                [105.6587066, 10.1503531],
                [105.6607973, 10.1484676],
                [105.6633629, 10.1464528],
                [105.6668992, 10.1440084],
                [105.6708464, 10.1417984],
                [105.6723888, 10.1410041],
                [105.6738552, 10.1401246],
                [105.6771762, 10.1380013],
                [105.6818716, 10.1347692],
                [105.6847891, 10.1372919],
                [105.6858411, 10.138182],
                [105.686594, 10.1388956],
                [105.6866578, 10.1390707],
                [105.6866572, 10.1392184],
                [105.6865744, 10.1393743],
                [105.6853527, 10.1407296],
                [105.6849437, 10.1412535],
                [105.6850848, 10.1422139],
                [105.6851836, 10.143024],
                [105.6852624, 10.1433595],
                [105.6853958, 10.1436234],
                [105.6863771, 10.1449795],
                [105.6899266, 10.1499042],
                [105.69202, 10.1528077],
                [105.6932756, 10.1545345],
                [105.6946827, 10.1565549],
                [105.6959892, 10.1584797],
                [105.6966712, 10.1595145],
                [105.6984446, 10.1620838],
                [105.6993978, 10.163459],
                [105.7019712, 10.1672162],
                [105.7044752, 10.1708793],
                [105.7057472, 10.1727421],
                [105.7089254, 10.1773433],
                [105.7101501, 10.1792025],
                [105.713541, 10.1840982],
                [105.7152772, 10.186641],
                [105.7186235, 10.191413],
                [105.7206518, 10.1944512],
                [105.7229555, 10.1977299],
                [105.7252, 10.2009716],
                [105.7278896, 10.20495],
                [105.7308356, 10.203838],
                [105.7391853, 10.2007254],
                [105.7421648, 10.1996607],
                [105.7479652, 10.1972217],
                [105.7505305, 10.194326],
                [105.7529444, 10.191259],
                [105.7544511, 10.1893564],
                [105.756349, 10.1866405],
                [105.7583271, 10.1839721],
                [105.7593786, 10.1828177],
                [105.760293, 10.1820331],
                [105.7606511, 10.1817259],
                [105.7648957, 10.1780841],
                [105.7684565, 10.182195],
                [105.7707638, 10.1846468],
                [105.7739806, 10.1881036],
                [105.7744954, 10.1885178],
                [105.774933, 10.1890101],
                [105.7784254, 10.1843584],
                [105.7811467, 10.180982],
                [105.7819572, 10.1800119],
                [105.7850121, 10.1760916],
                [105.7873526, 10.1732251],
                [105.7877194, 10.1727488],
                [105.791798, 10.1675743],
                [105.7929599, 10.1662295],
                [105.7983158, 10.1592278],
                [105.7985046, 10.159085],
                [105.7985797, 10.1590281],
                [105.7986749, 10.1588752],
                [105.8004133, 10.1568254],
                [105.8014365, 10.1555694],
                [105.8016932, 10.1555637],
                [105.8021832, 10.1555999],
                [105.8042548, 10.1549599],
                [105.8057611, 10.1546431],
                [105.8086627, 10.1540154],
                [105.8154515, 10.1527461],
                [105.8170031, 10.1509943],
                [105.8183691, 10.1495087],
                [105.8203163, 10.1493732],
                [105.8250661, 10.1488965],
                [105.8263322, 10.1489946],
                [105.827241, 10.1491505],
                [105.8277311, 10.1492757],
                [105.8278516, 10.1492749],
                [105.8279464, 10.1492065],
                [105.8280081, 10.149064],
                [105.8281282, 10.1488904],
                [105.828304, 10.1487845],
                [105.8284837, 10.1487518],
                [105.8286809, 10.1487936],
                [105.8288632, 10.1489452],
                [105.8290837, 10.149193],
                [105.8293581, 10.1493647],
                [105.8295992, 10.1494525],
                [105.8298578, 10.1494542],
                [105.8301387, 10.1493855],
                [105.8303464, 10.1492961],
                [105.8305668, 10.1491232],
                [105.8306866, 10.1490602],
                [105.8308114, 10.1490589],
                [105.8308966, 10.1491304],
                [105.8309732, 10.149274],
                [105.8310711, 10.1493706],
                [105.8312373, 10.1494385],
                [105.8319173, 10.1495202],
                [105.8325565, 10.1496084],
                [105.8335263, 10.1496161],
                [105.8350581, 10.1497012],
                [105.839937, 10.1498224],
                [105.8431192, 10.1499163],
                [105.8477123, 10.1499658],
                [105.8490918, 10.1500535],
                [105.8494649, 10.1517405],
                [105.8500772, 10.154097],
                [105.8510883, 10.1582728],
                [105.8515707, 10.1600683],
                [105.8615646, 10.1555599],
                [105.862659, 10.155015],
                [105.8638992, 10.1545841],
                [105.8639507, 10.1547827],
                [105.8646417, 10.1545292],
                [105.8666893, 10.1538422],
                [105.8669457, 10.1546382],
                [105.8675002, 10.1558014],
                [105.8705288, 10.154436],
                [105.8717881, 10.1584512],
                [105.8742635, 10.1663131],
                [105.8701783, 10.1687133],
                [105.8718755, 10.172509],
                [105.8726932, 10.173963],
                [105.8728844, 10.1745081],
                [105.8730953, 10.174983],
                [105.8733775, 10.1753668],
                [105.8734671, 10.1755893],
                [105.8735808, 10.1759253],
                [105.8738084, 10.1763629],
                [105.873932, 10.1766573],
                [105.874522, 10.1778153],
                [105.8747547, 10.1784534],
                [105.8750903, 10.1790126],
                [105.8756653, 10.1803621],
                [105.876137, 10.1815361],
                [105.8762741, 10.1817865],
                [105.8764654, 10.181973],
                [105.8767107, 10.182131],
                [105.8770157, 10.1822305],
                [105.8773296, 10.1822749],
                [105.8771528, 10.1827544],
                [105.8768965, 10.1833107],
                [105.8764413, 10.1839976],
                [105.8762835, 10.1844173],
                [105.8762152, 10.1845233],
                [105.8760423, 10.1846622],
                [105.8759755, 10.1847578],
                [105.8759553, 10.1848705],
                [105.875963, 10.1856717],
                [105.8760612, 10.1873715],
                [105.8761437, 10.1891533],
                [105.8825297, 10.1886672],
                [105.8827084, 10.188666],
                [105.8828615, 10.1886973],
                [105.8833087, 10.188857],
                [105.8847402, 10.187657],
                [105.8852301, 10.1874335],
                [105.8856052, 10.187112],
                [105.8859676, 10.1869889],
                [105.8862483, 10.1869839],
                [105.8865953, 10.1870768],
                [105.8875973, 10.1875442],
                [105.8883074, 10.1877578],
                [105.8886397, 10.1879146],
                [105.8888324, 10.1880953],
                [105.8889462, 10.1882954],
                [105.8890054, 10.1885164],
                [105.8890142, 10.1887956],
                [105.889249, 10.1887655],
                [105.8894435, 10.1886682],
                [105.8895297, 10.188584],
                [105.8895641, 10.1885131],
                [105.8895813, 10.1883762],
                [105.8896173, 10.1883058],
                [105.8906559, 10.1874845],
                [105.8913602, 10.1871229],
                [105.8915975, 10.1870525],
                [105.8922431, 10.187045],
                [105.8931158, 10.1869018],
                [105.8937512, 10.1866758],
                [105.8942233, 10.186382],
                [105.8951045, 10.1855625],
                [105.8966974, 10.1878013],
                [105.8977484, 10.1893255],
                [105.8994682, 10.191692],
                [105.9041722, 10.1919204],
                [105.9111593, 10.1922623],
                [105.913151, 10.1924126],
                [105.9149791, 10.1976048],
                [105.9175833, 10.2053562],
                [105.9195215, 10.2108798],
                [105.9188662, 10.2122324],
                [105.9186583, 10.2128297],
                [105.9183899, 10.2133966],
                [105.9182585, 10.2137381],
                [105.9180939, 10.2139315],
                [105.9179008, 10.214054],
                [105.9157809, 10.2180704],
                [105.9157384, 10.2182944],
                [105.9157627, 10.2185094],
                [105.9158204, 10.2188021],
                [105.9154632, 10.2188722],
                [105.9146856, 10.2207006],
                [105.913567, 10.2233946],
                [105.9130795, 10.2243883],
                [105.9129778, 10.2248408],
                [105.9128519, 10.2250826],
                [105.9128458, 10.2253216],
                [105.9129247, 10.2256868],
                [105.9126809, 10.2256613],
                [105.9123139, 10.2265566],
                [105.9110711, 10.2273634],
                [105.9061501, 10.2308279],
                [105.9028248, 10.233144],
                [105.9017237, 10.2338663],
                [105.9013952, 10.2342392],
                [105.9012009, 10.2342778],
                [105.9015693, 10.238896],
                [105.9020007, 10.2449179],
                [105.9014865, 10.2450309],
                [105.8998537, 10.2452733],
                [105.8992853, 10.2453337],
                [105.8987801, 10.2453213],
                [105.8966943, 10.2448898],
                [105.8957, 10.2445791],
                [105.8941622, 10.2443635],
                [105.8935318, 10.2443971],
                [105.8934446, 10.2444113],
                [105.8933915, 10.244424],
                [105.893327, 10.2444202],
                [105.8932018, 10.2443866],
                [105.8931358, 10.2443724],
                [105.8930675, 10.244371],
                [105.8928953, 10.2443874],
                [105.8928248, 10.2443874],
                [105.8927595, 10.2443717],
                [105.8925883, 10.2442888],
                [105.8925432, 10.2442829],
                [105.8924927, 10.2442966],
                [105.8924387, 10.2443292],
                [105.8920985, 10.244609],
                [105.8918949, 10.243938],
                [105.8915312, 10.2440271],
                [105.8911585, 10.2441499],
                [105.8908655, 10.244309],
                [105.8905001, 10.2442838],
                [105.890236, 10.2442988],
                [105.8903489, 10.2447431],
                [105.8898668, 10.2456711],
                [105.8893589, 10.2460016],
                [105.8889194, 10.2464759],
                [105.8882825, 10.2472554],
                [105.8877881, 10.2480278],
                [105.8874577, 10.2486472],
                [105.8873221, 10.2490876],
                [105.8869504, 10.2493095],
                [105.8866995, 10.2495076],
                [105.8867299, 10.2496435],
                [105.886956, 10.2499451],
                [105.8870015, 10.250066],
                [105.8870042, 10.2502218],
                [105.8869447, 10.2506124],
                [105.8867714, 10.251297],
                [105.8865699, 10.2518746],
                [105.8863989, 10.2520529],
                [105.8860467, 10.2525802],
                [105.8856078, 10.2531577],
                [105.8853374, 10.2534264],
                [105.8852621, 10.2537217],
                [105.8851392, 10.2538501],
                [105.8850315, 10.2539083],
                [105.8849344, 10.2539217],
                [105.884857, 10.2538919],
                [105.8847311, 10.2537291],
                [105.8846143, 10.2536843],
                [105.88431, 10.2536514],
                [105.8840491, 10.2536697],
                [105.8843649, 10.2543374],
                [105.8844046, 10.2545522],
                [105.8844046, 10.254783],
                [105.8843595, 10.2549197],
                [105.8841592, 10.255202],
                [105.8841069, 10.255392],
                [105.8841051, 10.2555802],
                [105.8844046, 10.2570149],
                [105.8846328, 10.2578317],
                [105.8846175, 10.2579133],
                [105.8845716, 10.2579785],
                [105.8844874, 10.258015],
                [105.8843866, 10.2580124],
                [105.8840625, 10.257922],
                [105.8837984, 10.2579246],
                [105.8836963, 10.2578957],
                [105.8836096, 10.2578392],
                [105.883547, 10.2577701],
                [105.8831034, 10.257144],
                [105.8828164, 10.2567786],
                [105.8827092, 10.2567008],
                [105.8825892, 10.2566681],
                [105.8824757, 10.2566719],
                [105.8823596, 10.2567221],
                [105.8822524, 10.2567987],
                [105.8822008, 10.25693],
                [105.8819915, 10.2576526],
                [105.881876, 10.2578426],
                [105.8817154, 10.2580042],
                [105.8816198, 10.2580859],
                [105.8815656, 10.2582155],
                [105.8815151, 10.2584747],
                [105.8813852, 10.2586842],
                [105.8812513, 10.2590525],
                [105.8812084, 10.2591232],
                [105.8811386, 10.2591718],
                [105.8810035, 10.2592193],
                [105.8809369, 10.2592584],
                [105.8809037, 10.259309],
                [105.8809005, 10.2593829],
                [105.8809702, 10.2597366],
                [105.8809696, 10.2598351],
                [105.8809191, 10.2599229],
                [105.8808114, 10.2599966],
                [105.8802561, 10.2602698],
                [105.8800224, 10.2604564],
                [105.8797645, 10.2607222],
                [105.8790817, 10.2614508],
                [105.8787598, 10.2617097],
                [105.8785171, 10.2618589],
                [105.8784463, 10.2619465],
                [105.8784195, 10.2620532],
                [105.8784195, 10.2622611],
                [105.8783873, 10.2624374],
                [105.8782876, 10.2625947],
                [105.8781459, 10.2627225],
                [105.8777071, 10.2630107],
                [105.8775679, 10.2631323],
                [105.877492, 10.2632966],
                [105.877492, 10.2634728],
                [105.8775506, 10.2643236],
                [105.8796456, 10.264758],
                [105.8813808, 10.2653255],
                [105.8834595, 10.2663314],
                [105.8883509, 10.2701727],
                [105.8940887, 10.2758055],
                [105.8932599, 10.2766446],
                [105.8924255, 10.2774782],
                [105.8915855, 10.2783064],
                [105.89074, 10.2791292],
                [105.889889, 10.2799464],
                [105.8890325, 10.2807581],
                [105.8881706, 10.2815642],
                [105.8873033, 10.2823647],
                [105.8864782, 10.2831164],
                [105.8856484, 10.2838631],
                [105.8848139, 10.2846047],
                [105.8839746, 10.2853411],
                [105.8831307, 10.2860723],
                [105.8822822, 10.2867984],
                [105.8818587, 10.2871352],
                [105.88143, 10.2874654],
                [105.8809961, 10.2877891],
                [105.8805571, 10.288106],
                [105.8801132, 10.2884162],
                [105.8796644, 10.2887196],
                [105.8792355, 10.2890003],
                [105.8788024, 10.2892747],
                [105.8783652, 10.2895428],
                [105.8779241, 10.2898045],
                [105.8774791, 10.2900598],
                [105.8770304, 10.2903087],
                [105.8765779, 10.290551],
                [105.8759739, 10.2909149],
                [105.8753647, 10.2912706],
                [105.8747507, 10.291618],
                [105.8741318, 10.291957],
                [105.8735083, 10.2922876],
                [105.8728802, 10.2926098],
                [105.8722476, 10.2929233],
                [105.8716107, 10.2932283],
                [105.8709695, 10.2935246],
                [105.8703243, 10.2938121],
                [105.8696752, 10.2940909],
                [105.8690221, 10.2943608],
                [105.8683654, 10.2946219],
                [105.867705, 10.294874],
                [105.8670412, 10.2951172],
                [105.8663741, 10.2953513],
                [105.8657037, 10.2955764],
                [105.8650302, 10.2957923],
                [105.8643854, 10.2959896],
                [105.863738, 10.2961786],
                [105.8630881, 10.2963592],
                [105.8624358, 10.2965314],
                [105.8617813, 10.2966952],
                [105.8611247, 10.2968504],
                [105.860466, 10.2969972],
                [105.8598054, 10.2971354],
                [105.8591431, 10.2972651],
                [105.8584791, 10.2973862],
                [105.8578135, 10.2974987],
                [105.8571465, 10.2976025],
                [105.8564781, 10.2976978],
                [105.8558085, 10.2977844],
                [105.8551379, 10.2978623],
                [105.854466, 10.2979296],
                [105.8531193, 10.2980646],
                [105.8510371, 10.2982732],
                [105.8499944, 10.2983777],
                [105.8489505, 10.2984713],
                [105.8479059, 10.2985561],
                [105.8468606, 10.298632],
                [105.8458146, 10.2986991],
                [105.8447632, 10.2987576],
                [105.8437112, 10.2988072],
                [105.8426589, 10.2988478],
                [105.8416063, 10.2988795],
                [105.8405534, 10.2989022],
                [105.8395004, 10.298916],
                [105.8359745, 10.2988199],
                [105.8357305, 10.3035402],
                [105.8360215, 10.3040603],
                [105.8360542, 10.3046387],
                [105.8359644, 10.3049198],
                [105.8358419, 10.3052331],
                [105.8355235, 10.3053375],
                [105.8352378, 10.3055464],
                [105.834903, 10.3056829],
                [105.8340213, 10.3061086],
                [105.8332293, 10.3063898],
                [105.8330007, 10.3065986],
                [105.832813, 10.306695],
                [105.832568, 10.3068557],
                [105.8322963, 10.3069186],
                [105.8319639, 10.3070484],
                [105.8316128, 10.3070886],
                [105.8312781, 10.3071288],
                [105.8309597, 10.3072011],
                [105.8307311, 10.3072573],
                [105.8304208, 10.3072412],
                [105.829931, 10.3070645],
                [105.8296615, 10.3070083],
                [105.8292778, 10.3069039],
                [105.8287635, 10.3068235],
                [105.8285022, 10.3067753],
                [105.828192, 10.3067111],
                [105.8278899, 10.3067111],
                [105.8276613, 10.3067834],
                [105.8269673, 10.3071448],
                [105.8264693, 10.3073376],
                [105.8261257, 10.3073764],
                [105.8257263, 10.3075143],
                [105.8252691, 10.3078276],
                [105.824518, 10.3080766],
                [105.8236444, 10.3083096],
                [105.823325, 10.308421],
                [105.82289, 10.3085044],
                [105.8225793, 10.3085976],
                [105.8222274, 10.3087834],
                [105.8216993, 10.3089903],
                [105.8214924, 10.3091037],
                [105.8213892, 10.3092787],
                [105.8211838, 10.309988],
                [105.82104, 10.3105122],
                [105.8210721, 10.3108923],
                [105.8211869, 10.3111796],
                [105.8212603, 10.3114876],
                [105.8213747, 10.3118198],
                [105.8214727, 10.3120688],
                [105.8216197, 10.3124704],
                [105.8221667, 10.3133651],
                [105.8224579, 10.3137239],
                [105.8226552, 10.3138775],
                [105.8227798, 10.3139491],
                [105.8249278, 10.3149399],
                [105.8275328, 10.3162789],
                [105.8280042, 10.3167998],
                [105.8284859, 10.3171533],
                [105.8293295, 10.3176713],
                [105.8295372, 10.3178249],
                [105.8296203, 10.317948],
                [105.8295999, 10.3180301],
                [105.8292533, 10.3185187],
                [105.8279062, 10.3192979],
                [105.8268775, 10.3198682],
                [105.8262244, 10.3201011],
                [105.8245274, 10.3203136],
                [105.8230321, 10.3207758],
                [105.8214411, 10.3212048],
                [105.8186345, 10.3220338],
                [105.8172877, 10.3222735],
                [105.8167905, 10.322357],
                [105.8168745, 10.3227883],
                [105.8170953, 10.3240102],
                [105.8173997, 10.3254783],
                [105.8177254, 10.3271313],
                [105.8180819, 10.328733],
                [105.81824, 10.3292692],
                [105.8182852, 10.3294224],
                [105.8182979, 10.3294656],
                [105.8184903, 10.3303241],
                [105.8187123, 10.3310519],
                [105.818771, 10.3313052],
                [105.8188007, 10.3314335],
                [105.8188032, 10.332453],
                [105.8188012, 10.3330721],
                [105.8190258, 10.3353425],
                [105.8187583, 10.3355325],
                [105.8188907, 10.3381631],
                [105.8191858, 10.3411894],
                [105.8192685, 10.3422957],
                [105.8192231, 10.3432442],
                [105.8190989, 10.3445485],
                [105.8189428, 10.3458925],
                [105.8184852, 10.3477985],
                [105.8182801, 10.3486842],
                [105.8180618, 10.350629],
                [105.8179674, 10.3510929],
                [105.8175683, 10.3523348],
                [105.8173324, 10.3529527],
                [105.8171048, 10.3569107],
                [105.8167537, 10.3599867],
                [105.8165088, 10.3631831],
                [105.8157659, 10.3704431],
                [105.8190234, 10.3704672],
                [105.8201011, 10.3703548],
                [105.8236444, 10.3701701],
                [105.8257018, 10.3700657],
                [105.8358011, 10.3694794],
                [105.8409936, 10.3675038],
                [105.8445167, 10.3662718],
                [105.8456267, 10.3695259],
                [105.848819, 10.3783828],
                [105.8520837, 10.3873564],
                [105.8527672, 10.3892103],
                [105.8540809, 10.3928867],
                [105.8594347, 10.4073361],
                [105.8595838, 10.4077206],
                [105.864905, 10.4131974],
                [105.8691245, 10.4177295],
                [105.8724394, 10.4214064],
                [105.8733261, 10.42239],
                [105.8798577, 10.4292269],
                [105.8801929, 10.4295778],
                [105.9012302, 10.4519322],
                [105.9056179, 10.4565734],
                [105.9096711, 10.4608666],
                [105.9137058, 10.4650682],
                [105.9175341, 10.469152],
                [105.9213468, 10.4732224],
                [105.9248684, 10.4768316],
                [105.9293858, 10.481607],
                [105.9335719, 10.4860305],
                [105.9377928, 10.4905125],
                [105.9415993, 10.4944356],
                [105.9415896, 10.4946591],
                [105.9415832, 10.4948827],
                [105.9415802, 10.4951055],
                [105.9415805, 10.4953284],
                [105.9417005, 10.5007422],
                [105.9418066, 10.5044348],
                [105.9418229, 10.5090265],
                [105.9418742, 10.5101598],
                [105.9418787, 10.5103515],
                [105.9418939, 10.5109216],
                [105.9418968, 10.5112833],
                [105.941889, 10.5116309],
                [105.9418474, 10.5133934],
                [105.9418883, 10.5158015],
                [105.9418556, 10.5193816],
                [105.9418474, 10.5210754],
                [105.9418148, 10.5228253],
                [105.9417001, 10.5364846],
                [105.9362467, 10.5368802],
                [105.9266863, 10.5375224],
                [105.9237308, 10.537715],
                [105.9183505, 10.538028],
                [105.9174455, 10.5381003],
                [105.9157379, 10.5382367],
                [105.9123253, 10.5384535],
                [105.9103903, 10.5385899],
                [105.9100537, 10.5386091],
                [105.9097943, 10.5386621],
                [105.9068062, 10.5422741],
                [105.9037364, 10.546504],
                [105.9027485, 10.5479167],
                [105.9017606, 10.5493293],
                [105.8990582, 10.5528368],
                [105.8970417, 10.5554935],
                [105.8946413, 10.5585996],
                [105.8917104, 10.56242],
                [105.8868852, 10.5686802],
                [105.8844604, 10.5716818],
                [105.8811457, 10.5759434],
                [105.8764921, 10.5818101],
                [105.8729406, 10.5865772],
                [105.8722482, 10.5875107],
                [105.8716669, 10.5882946],
                [105.8687033, 10.5922029],
                [105.8617146, 10.6015762],
                [105.8578121, 10.6070733],
                [105.855126, 10.6108289],
                [105.8537799, 10.6127291],
                [105.8519256, 10.6153467],
                [105.8469372, 10.621967],
                [105.8432715, 10.6267816],
                [105.8421203, 10.6284747],
                [105.8388301, 10.63288],
                [105.8371074, 10.6351668],
                [105.836593, 10.6358409],
                [105.8361195, 10.6364025],
                [105.8346407, 10.6382902],
                [105.8344746, 10.6384391],
                [105.8281838, 10.6420915],
                [105.8249775, 10.6441282],
                [105.818856, 10.6473916],
                [105.814519, 10.6495338],
                [105.8094252, 10.6517538],
                [105.8079689, 10.6536377],
                [105.7956688, 10.6559383],
                [105.7858202, 10.6578364],
                [105.7847215, 10.6580397],
                [105.7806022, 10.658802],
                [105.7757539, 10.6634523],
                [105.770023, 10.6687377],
                [105.7681262, 10.670487],
                [105.7529321, 10.6846172],
                [105.7480679, 10.6893672],
                [105.7476088, 10.6898155],
                [105.747412, 10.6900077],
                [105.746641, 10.6907605],
                [105.7453657, 10.6917129],
                [105.7377366, 10.6987467],
                [105.7327031, 10.7034073],
                [105.728479, 10.7074913],
                [105.720381, 10.7150487],
                [105.7169847, 10.7183262],
                [105.7128771, 10.7221877],
                [105.7106792, 10.7240485],
                [105.7078782, 10.7266563],
                [105.7053145, 10.7292343],
                [105.7028709, 10.7314779],
                [105.7003849, 10.7337839],
                [105.6968324, 10.7369652],
                [105.6930324, 10.7406395],
                [105.690564, 10.7430572],
                [105.6882035, 10.7453208],
                [105.6855381, 10.7477287],
                [105.6823191, 10.750642],
                [105.6814374, 10.7514717],
                [105.6801953, 10.7526404],
                [105.6781684, 10.7545475],
                [105.6740387, 10.7583905],
                [105.6695905, 10.7625306],
                [105.6683142, 10.7637179],
                [105.6634125, 10.7683336],
                [105.6601739, 10.77129],
                [105.6586279, 10.7726482],
                [105.6544316, 10.7766178],
                [105.6534201, 10.7775733],
                [105.6510811, 10.7796919],
                [105.6488474, 10.7817274],
                [105.6479622, 10.7825999],
                [105.6452858, 10.7851547],
                [105.6434329, 10.7867181],
                [105.6398382, 10.7901082],
                [105.638774, 10.7911572],
                [105.6359448, 10.7935524],
                [105.6335893, 10.7960382],
                [105.6325113, 10.7968697],
                [105.6286893, 10.800514],
                [105.6237582, 10.8051016],
                [105.6191953, 10.809639],
                [105.6140512, 10.8142109],
                [105.6094483, 10.818514],
                [105.606749, 10.8209989],
                [105.6062727, 10.8214354],
                [105.6042191, 10.8233175],
                [105.5992948, 10.8278879],
                [105.5959153, 10.8306572],
                [105.6024098, 10.8312083],
                [105.6123972, 10.8319758],
                [105.6113255, 10.8346482],
                [105.6112756, 10.834757],
                [105.6111764, 10.8349498],
                [105.611009, 10.8352357],
                [105.6105423, 10.8361142],
                [105.6105208, 10.8361327],
                [105.6104873, 10.836136],
                [105.6101319, 10.8367755],
                [105.6097189, 10.8375921],
                [105.608308, 10.8404926],
                [105.6065994, 10.8440568],
                [105.605595, 10.8460115],
                [105.6055105, 10.8460088],
                [105.6051993, 10.8467517],
                [105.6050196, 10.8472522],
                [105.6048748, 10.8475947],
                [105.6008863, 10.8556186],
                [105.598231, 10.8610819],
                [105.5958331, 10.8658391],
                [105.5956315, 10.8662129],
                [105.595277, 10.8669082],
                [105.5939153, 10.8697508],
                [105.5921075, 10.8734174],
                [105.590996, 10.8756303],
                [105.5897793, 10.878185],
                [105.5892456, 10.8791886],
                [105.5887169, 10.8800831],
                [105.5885911, 10.8803107],
                [105.5881311, 10.8812391],
                [105.5877382, 10.8821044],
                [105.5864319, 10.8847805],
                [105.5858526, 10.8859829],
                [105.5853362, 10.8869509],
                [105.5847542, 10.8880782],
                [105.5847314, 10.8881506],
                [105.58414, 10.8893188],
                [105.5836746, 10.8901801],
                [105.583452, 10.8906726],
                [105.5833957, 10.8907516],
                [105.5831194, 10.8912679],
                [105.5827439, 10.8920462],
                [105.5822115, 10.8923807],
                [105.5805364, 10.8933276],
                [105.5802923, 10.8933513],
                [105.5802573, 10.8933983],
                [105.5801678, 10.8934742],
                [105.5801153, 10.8935014],
                [105.580004, 10.8935307],
                [105.5799463, 10.893533],
                [105.579536, 10.8935014],
                [105.5795372, 10.8935936],
                [105.5795145, 10.8937766],
                [105.5794946, 10.8938531],
                [105.5794373, 10.8940004],
                [105.5794002, 10.8940703],
                [105.5790693, 10.8938543],
                [105.5782485, 10.8944733],
                [105.5718058, 10.8976655],
                [105.5714464, 10.897223],
                [105.5713579, 10.897365],
                [105.5711621, 10.8976366],
                [105.5706713, 10.8982081],
                [105.5700101, 10.8978499],
                [105.5699808, 10.8979159],
                [105.5699337, 10.8980685],
                [105.5698854, 10.8981765],
                [105.5696534, 10.8985242],
                [105.5695595, 10.8984214],
                [105.5694415, 10.8983174],
                [105.5691263, 10.8987691],
                [105.5688272, 10.8985465],
                [105.5686757, 10.8987454],
                [105.5681366, 10.8982818],
                [105.5670932, 10.8992406],
                [105.5670556, 10.8991826],
                [105.5668397, 10.8993591],
                [105.5618843, 10.9015004],
                [105.5566715, 10.9039775],
                [105.5565655, 10.9038392],
                [105.556484, 10.9038215],
                [105.5564421, 10.9038234],
                [105.5563666, 10.9038462],
                [105.5563027, 10.9038919],
                [105.5562034, 10.9040314],
                [105.5558574, 10.9041052],
                [105.5551869, 10.9042843],
                [105.555156, 10.9051442],
                [105.5529928, 10.9051995],
                [105.5530196, 10.9056749],
                [105.5530733, 10.9058224],
                [105.5509718, 10.9067139],
                [105.5509181, 10.906631],
                [105.5493678, 10.9072157],
                [105.5494174, 10.9072855],
                [105.5463409, 10.908788],
                [105.5442113, 10.9101181],
                [105.543652, 10.9096501],
                [105.5434938, 10.9095452],
                [105.543011, 10.910359],
                [105.5420454, 10.9098376],
                [105.5419381, 10.9106843],
                [105.5410972, 10.9104552],
                [105.5410328, 10.9111399],
                [105.540306, 10.9109371],
                [105.5402952, 10.9112453],
                [105.5402409, 10.911332],
                [105.5401155, 10.9114942],
                [105.5400499, 10.9115641],
                [105.5399061, 10.9116914],
                [105.5390641, 10.9123146],
                [105.5385706, 10.9119854],
                [105.5382729, 10.9125832],
                [105.5379322, 10.9123752],
                [105.5374829, 10.913264],
                [105.5371356, 10.9130086],
                [105.536854, 10.9134194],
                [105.5363283, 10.9130362],
                [105.5360761, 10.913534],
                [105.5356899, 10.9134076],
                [105.5355316, 10.9138882],
                [105.5352218, 10.913825],
                [105.5351977, 10.9138935],
                [105.5350645, 10.9138623],
                [105.5349965, 10.913854],
                [105.5348477, 10.9138527],
                [105.5346216, 10.913873],
                [105.5345097, 10.9138922],
                [105.5343005, 10.9139448],
                [105.5338472, 10.9147349],
                [105.5329594, 10.9142477],
                [105.5323827, 10.9152037],
                [105.5322728, 10.9153223],
                [105.5320421, 10.9151116],
                [105.5317551, 10.9149483],
                [105.5314869, 10.9155198],
                [105.5310309, 10.9153038],
                [105.5306688, 10.9160096],
                [105.5300063, 10.9156199],
                [105.5296603, 10.9163152],
                [105.5292754, 10.9160864],
                [105.5291131, 10.9164521],
                [105.5287805, 10.9162756],
                [105.528515, 10.9168551],
                [105.527375, 10.916298],
                [105.52697, 10.9173528],
                [105.5266052, 10.9170684],
                [105.5260393, 10.9181482],
                [105.5254251, 10.9179322],
                [105.5249047, 10.9187302],
                [105.5246365, 10.9185617],
                [105.5243683, 10.9189409],
                [105.5237862, 10.9185696],
                [105.5235931, 10.9188435],
                [105.5230004, 10.9184695],
                [105.5221206, 10.919515],
                [105.5216673, 10.9192122],
                [105.5211456, 10.9199364],
                [105.5210343, 10.9201129],
                [105.5208975, 10.9203947],
                [105.5201183, 10.9200181],
                [105.5195618, 10.92109],
                [105.5194598, 10.9210465],
                [105.5192184, 10.9216141],
                [105.5187973, 10.921543],
                [105.5184285, 10.9216112],
                [105.5180651, 10.9217036],
                [105.5178915, 10.9217569],
                [105.5175501, 10.9218801],
                [105.5154365, 10.922707],
                [105.5155706, 10.9231495],
                [105.5151468, 10.92321],
                [105.5151656, 10.923555],
                [105.515281, 10.9235603],
                [105.5153534, 10.9237236],
                [105.5153561, 10.9239395],
                [105.5156189, 10.9240212],
                [105.5155089, 10.9243161],
                [105.515407, 10.9247375],
                [105.5148277, 10.9246085],
                [105.5145648, 10.9246111],
                [105.5145675, 10.924777],
                [105.5148572, 10.9247981],
                [105.51512, 10.9248824],
                [105.5150825, 10.9249851],
                [105.5150181, 10.9249798],
                [105.5149873, 10.9251628],
                [105.5148518, 10.9251418],
                [105.5148062, 10.9254196],
                [105.5145474, 10.9262466],
                [105.5149256, 10.9263901],
                [105.51488, 10.9265139],
                [105.5146587, 10.9264283],
                [105.5143502, 10.9270024],
                [105.5140042, 10.9274475],
                [105.5137548, 10.9277161],
                [105.5137575, 10.9278478],
                [105.5134866, 10.9281454],
                [105.5133417, 10.92799],
                [105.5132103, 10.9281006],
                [105.5129045, 10.9276503],
                [105.5122688, 10.9279821],
                [105.5125666, 10.9285431],
                [105.5117404, 10.9289644],
                [105.5114883, 10.9291461],
                [105.5121803, 10.9297097],
                [105.5107373, 10.930987],
                [105.5110672, 10.9311713],
                [105.5108432, 10.9315137],
                [105.5110216, 10.9316506],
                [105.5104865, 10.9320365],
                [105.5107802, 10.9325316],
                [105.5102357, 10.93292],
                [105.5089322, 10.9343316],
                [105.5097261, 10.9359143],
                [105.5092809, 10.9357879],
                [105.5091521, 10.9362145],
                [105.5091091, 10.9363197],
                [105.5090183, 10.9365038],
                [105.5053554, 10.942735],
                [105.5050564, 10.9432011],
                [105.5048123, 10.9440517],
                [105.5045468, 10.9444125],
                [105.5035838, 10.9454606],
                [105.5038816, 10.9464034],
                [105.5030742, 10.9465245],
                [105.5029052, 10.9467957],
                [105.5033559, 10.9470538],
                [105.5029321, 10.9471091],
                [105.5029911, 10.947433],
                [105.5026156, 10.9475068],
                [105.5025995, 10.9482704],
                [105.5017519, 10.9483073],
                [105.5016983, 10.9488129],
                [105.5016913, 10.9489853],
                [105.5016607, 10.9493291],
                [105.5016119, 10.9496494],
                [105.5015802, 10.9498083],
                [105.5015539, 10.9498818],
                [105.5014822, 10.9500208],
                [105.5013871, 10.9501454],
                [105.501255, 10.9502645],
                [105.5010664, 10.950392],
                [105.501017, 10.9504403],
                [105.5009389, 10.9505488],
                [105.5007917, 10.9509038],
                [105.5006854, 10.9511984],
                [105.50062, 10.951341],
                [105.5005441, 10.9514843],
                [105.50046, 10.9516231],
                [105.5003679, 10.951757],
                [105.5007045, 10.9521766],
                [105.5000626, 10.9526435],
                [105.4991563, 10.9532868],
                [105.4983865, 10.9531867],
                [105.4981756, 10.9531236],
                [105.4979764, 10.9530308],
                [105.4977932, 10.9529102],
                [105.497709, 10.9528403],
                [105.4975573, 10.9526832],
                [105.4974284, 10.952502],
                [105.4973269, 10.9522997],
                [105.4972582, 10.9520846],
                [105.4972238, 10.9518616],
                [105.497142, 10.9506903],
                [105.4971042, 10.9500296],
                [105.4970722, 10.9497001],
                [105.4969904, 10.9490948],
                [105.4968791, 10.948494],
                [105.4965653, 10.9471629],
                [105.4963371, 10.9474464],
                [105.4961334, 10.9477475],
                [105.4959806, 10.9480161],
                [105.4957445, 10.9484743],
                [105.4956846, 10.9485738],
                [105.4956015, 10.9486664],
                [105.4955504, 10.9487086],
                [105.4954951, 10.9487455],
                [105.4954385, 10.948771],
                [105.4953194, 10.9488061],
                [105.4952427, 10.948817],
                [105.49502, 10.9488224],
                [105.494875, 10.9488162],
                [105.4946048, 10.9487797],
                [105.4943578, 10.9487166],
                [105.4942388, 10.948696],
                [105.4939978, 10.9486802],
                [105.493757, 10.9486981],
                [105.4936318, 10.9487211],
                [105.4935087, 10.9487532],
                [105.4933884, 10.9487944],
                [105.4932715, 10.9488443],
                [105.4931434, 10.948914],
                [105.4928964, 10.9490689],
                [105.4925371, 10.9493323],
                [105.4922787, 10.9494856],
                [105.4920055, 10.9496119],
                [105.4918467, 10.9496705],
                [105.4916846, 10.94972],
                [105.4915199, 10.9497602],
                [105.4913531, 10.9497909],
                [105.4897927, 10.9499578],
                [105.4896141, 10.9499846],
                [105.4894367, 10.9500188],
                [105.4890872, 10.950109],
                [105.4887466, 10.9502279],
                [105.4884174, 10.9503745],
                [105.4881019, 10.9505479],
                [105.486678, 10.9514578],
                [105.4864529, 10.9515731],
                [105.4862173, 10.9516659],
                [105.4859704, 10.9517361],
                [105.4857175, 10.9517816],
                [105.4849482, 10.9518376],
                [105.4846441, 10.9518841],
                [105.4843451, 10.9519556],
                [105.4841812, 10.9520063],
                [105.48402, 10.9520645],
                [105.4837068, 10.9522031],
                [105.4834958, 10.9523332],
                [105.4833965, 10.9524071],
                [105.483212, 10.9525715],
                [105.4830442, 10.9527612],
                [105.4829675, 10.9528659],
                [105.4828345, 10.9530882],
                [105.4827304, 10.953325],
                [105.4824354, 10.9544942],
                [105.482404, 10.9547588],
                [105.4824008, 10.954892],
                [105.4824193, 10.9551578],
                [105.4824424, 10.9552966],
                [105.4824747, 10.9554338],
                [105.4825159, 10.9555686],
                [105.4829741, 10.956788],
                [105.483106, 10.957059],
                [105.4831869, 10.9571981],
                [105.4833941, 10.95751],
                [105.4834438, 10.9576261],
                [105.4834655, 10.9577503],
                [105.4834582, 10.9578747],
                [105.4834234, 10.9579928],
                [105.4833628, 10.9581005],
                [105.4832042, 10.9582615],
                [105.4830352, 10.9583922],
                [105.4828619, 10.9584942],
                [105.4825776, 10.9586179],
                [105.4824269, 10.9586721],
                [105.4821189, 10.9587601],
                [105.4807751, 10.9590709],
                [105.4806445, 10.9591138],
                [105.4803931, 10.959224],
                [105.4802733, 10.9592908],
                [105.4800481, 10.9594462],
                [105.4798422, 10.9596318],
                [105.4797475, 10.9597358],
                [105.47966, 10.9598456],
                [105.4795802, 10.9599609],
                [105.4795083, 10.9600811],
                [105.4794447, 10.9602058],
                [105.478978, 10.9614303],
                [105.4789355, 10.9615299],
                [105.4788316, 10.9617203],
                [105.4787041, 10.9618963],
                [105.4785419, 10.9620671],
                [105.4784453, 10.9621495],
                [105.4783428, 10.9622247],
                [105.478235, 10.9622923],
                [105.4781224, 10.9623519],
                [105.4778867, 10.9624393],
                [105.4777654, 10.9624728],
                [105.4775272, 10.9625177],
                [105.4772949, 10.9625362],
                [105.4767196, 10.9625284],
                [105.4763186, 10.9625498],
                [105.4751559, 10.962689],
                [105.4749506, 10.9627441],
                [105.4748517, 10.9627829],
                [105.4746643, 10.9628821],
                [105.4745769, 10.9629419],
                [105.4744172, 10.9630801],
                [105.4743459, 10.9631577],
                [105.4742234, 10.9633256],
                [105.4741722, 10.9634158],
                [105.474091, 10.9636062],
                [105.4739194, 10.9642821],
                [105.4738713, 10.964422],
                [105.4738453, 10.9644757],
                [105.4737662, 10.9645925],
                [105.4737102, 10.9646521],
                [105.473625, 10.9647073],
                [105.4735258, 10.964738],
                [105.47342, 10.9647403],
                [105.4733303, 10.9647281],
                [105.4731563, 10.9646797],
                [105.473026, 10.9646193],
                [105.4727996, 10.9644826],
                [105.472479, 10.9642689],
                [105.4721568, 10.9640245],
                [105.4720016, 10.963895],
                [105.4713177, 10.9632683],
                [105.4712546, 10.9632183],
                [105.4711159, 10.9631363],
                [105.4710414, 10.963105],
                [105.4708936, 10.9630646],
                [105.470741, 10.9630497],
                [105.4704935, 10.9630697],
                [105.4703708, 10.9630892],
                [105.4702684, 10.9631146],
                [105.4700765, 10.9631789],
                [105.4698478, 10.9632775],
                [105.4696892, 10.9633375],
                [105.4690886, 10.9635157],
                [105.4687529, 10.9636344],
                [105.4685898, 10.9637054],
                [105.4682753, 10.96387],
                [105.4678559, 10.9641437],
                [105.4675979, 10.9642822],
                [105.4673251, 10.9643902],
                [105.4670337, 10.9644676],
                [105.466881, 10.9644938],
                [105.466727, 10.9645108],
                [105.4665722, 10.9645183],
                [105.4664173, 10.9645165],
                [105.4651834, 10.9644348],
                [105.4650571, 10.9644192],
                [105.4649324, 10.9643946],
                [105.4648097, 10.9643612],
                [105.4646018, 10.9642815],
                [105.4644324, 10.9641926],
                [105.4643382, 10.9641311],
                [105.4641615, 10.9639924],
                [105.46402, 10.9638537],
                [105.4638047, 10.96361],
                [105.4636486, 10.963446],
                [105.463487, 10.9632873],
                [105.4631476, 10.9629865],
                [105.462813, 10.9627271],
                [105.4624623, 10.962489],
                [105.4615364, 10.9619328],
                [105.4611694, 10.9617015],
                [105.4604467, 10.9612222],
                [105.4600521, 10.9609384],
                [105.4598629, 10.9607861],
                [105.4595354, 10.9604936],
                [105.4593952, 10.9603559],
                [105.4591753, 10.9601014],
                [105.458948, 10.9598533],
                [105.4587134, 10.9596118],
                [105.4584718, 10.959377],
                [105.4582234, 10.9591493],
                [105.4578469, 10.9588281],
                [105.4577317, 10.9587207],
                [105.4576232, 10.9586069],
                [105.4575216, 10.958487],
                [105.4574273, 10.9583615],
                [105.4573407, 10.9582308],
                [105.4572622, 10.9580957],
                [105.4571918, 10.9579563],
                [105.4571299, 10.9578131],
                [105.4570767, 10.9576665],
                [105.4570322, 10.9575172],
                [105.4568428, 10.956824],
                [105.4568057, 10.9567314],
                [105.4567069, 10.9565579],
                [105.456646, 10.9564784],
                [105.456526, 10.9563561],
                [105.4564593, 10.956302],
                [105.4563885, 10.9562532],
                [105.4556375, 10.9558056],
                [105.455412, 10.955716],
                [105.4551696, 10.9556534],
                [105.4549136, 10.9556218],
                [105.4545787, 10.9556239],
                [105.4537519, 10.9556291],
                [105.4535116, 10.9556112],
                [105.4532751, 10.9555665],
                [105.4530415, 10.9554943],
                [105.4529265, 10.9554475],
                [105.452706, 10.9553343],
                [105.4526012, 10.9552684],
                [105.4521361, 10.9549268],
                [105.4519962, 10.9548565],
                [105.4518436, 10.9548198],
                [105.451765, 10.9548146],
                [105.4516088, 10.9548312],
                [105.4515306, 10.9548536],
                [105.4514558, 10.9548851],
                [105.4513853, 10.9549251],
                [105.4513202, 10.9549731],
                [105.4512614, 10.9550284],
                [105.4512099, 10.9550903],
                [105.4511662, 10.9551578],
                [105.4505922, 10.9562164],
                [105.4505146, 10.9563372],
                [105.4503402, 10.9565658],
                [105.4502437, 10.9566728],
                [105.4500402, 10.9568656],
                [105.4499343, 10.9569513],
                [105.4497098, 10.9571064],
                [105.4495769, 10.9571838],
                [105.4493021, 10.9573223],
                [105.4491241, 10.9573981],
                [105.4489425, 10.9574648],
                [105.4487576, 10.9575225],
                [105.4484673, 10.957594],
                [105.4477694, 10.9577453],
                [105.4476575, 10.9577755],
                [105.4475479, 10.9578131],
                [105.4473378, 10.9579097],
                [105.4471429, 10.9580333],
                [105.4469734, 10.9581754],
                [105.4468242, 10.9583378],
                [105.4466977, 10.9585179],
                [105.4466096, 10.9586219],
                [105.4464162, 10.9588144],
                [105.446194, 10.9589897],
                [105.4460714, 10.9590699],
                [105.4458125, 10.9592078],
                [105.4452826, 10.9594292],
                [105.4450132, 10.9595291],
                [105.4445802, 10.9596724],
                [105.4443615, 10.9597371],
                [105.4429587, 10.9600873],
                [105.4428354, 10.9601276],
                [105.4427156, 10.9601768],
                [105.4425997, 10.9602346],
                [105.4424886, 10.9603006],
                [105.4423827, 10.9603746],
                [105.4422014, 10.9605321],
                [105.4421252, 10.9606131],
                [105.4419895, 10.9607888],
                [105.4418777, 10.96098],
                [105.4416741, 10.9612967],
                [105.4414131, 10.9616593],
                [105.4412528, 10.9618621],
                [105.4407646, 10.9623572],
                [105.440622, 10.962517],
                [105.4405015, 10.9626936],
                [105.4404052, 10.9628838],
                [105.4403642, 10.9629899],
                [105.4403051, 10.9632089],
                [105.4402872, 10.963321],
                [105.4402617, 10.9636858],
                [105.4402666, 10.96405],
                [105.4403015, 10.964411],
                [105.4403446, 10.9647018],
                [105.4403497, 10.9648142],
                [105.4403454, 10.9649266],
                [105.4403317, 10.9650382],
                [105.4402816, 10.9652412],
                [105.4402078, 10.9654199],
                [105.4401092, 10.9655867],
                [105.4400511, 10.9656646],
                [105.4393055, 10.9663703],
                [105.4391244, 10.9664992],
                [105.4387485, 10.9667371],
                [105.4383557, 10.9669473],
                [105.4381537, 10.9670417],
                [105.4377491, 10.9672048],
                [105.4373433, 10.9673362],
                [105.436929, 10.9674393],
                [105.4368149, 10.967471],
                [105.4367036, 10.9675115],
                [105.436596, 10.9675605],
                [105.4364926, 10.9676177],
                [105.4363942, 10.9676828],
                [105.4363014, 10.9677553],
                [105.4361391, 10.9679159],
                [105.4360678, 10.9680049],
                [105.4359474, 10.9681977],
                [105.4357167, 10.9687665],
                [105.4356588, 10.9688931],
                [105.4355937, 10.9690163],
                [105.4354431, 10.969251],
                [105.4352912, 10.9694404],
                [105.4351212, 10.9696144],
                [105.4346492, 10.9700067],
                [105.434494, 10.9701516],
                [105.4344248, 10.9702319],
                [105.4343049, 10.9704061],
                [105.4342549, 10.9704991],
                [105.4341783, 10.9706871],
                [105.4341501, 10.9707845],
                [105.4340499, 10.9712503],
                [105.4339426, 10.9716127],
                [105.4338782, 10.9717918],
                [105.4335602, 10.9725714],
                [105.4335196, 10.9726619],
                [105.4334178, 10.9728323],
                [105.4332905, 10.9729853],
                [105.4331408, 10.9731173],
                [105.4329671, 10.9732279],
                [105.4328714, 10.9732738],
                [105.4327723, 10.9733119],
                [105.4325661, 10.9733638],
                [105.4324605, 10.9733772],
                [105.4307787, 10.9734667],
                [105.4306733, 10.9734613],
                [105.4305688, 10.9734465],
                [105.430466, 10.9734226],
                [105.4303659, 10.9733898],
                [105.4301856, 10.9733036],
                [105.4300308, 10.9731953],
                [105.4299607, 10.9731323],
                [105.4296013, 10.9727531],
                [105.4294708, 10.9726377],
                [105.4293219, 10.9725465],
                [105.4292418, 10.9725109],
                [105.42907, 10.9724602],
                [105.4288917, 10.9724404],
                [105.428802, 10.9724424],
                [105.4282467, 10.9724977],
                [105.427943, 10.9725428],
                [105.4273402, 10.9726583],
                [105.4268654, 10.9727742],
                [105.4267666, 10.9728053],
                [105.4266653, 10.9728273],
                [105.4265623, 10.9728401],
                [105.4264586, 10.9728435],
                [105.426355, 10.9728375],
                [105.4262524, 10.9728222],
                [105.4261517, 10.9727977],
                [105.4260537, 10.9727642],
                [105.4259593, 10.9727219],
                [105.4258693, 10.9726713],
                [105.4257843, 10.9726128],
                [105.4257052, 10.9725469],
                [105.4256326, 10.9724741],
                [105.4255672, 10.972395],
                [105.4254645, 10.9722302],
                [105.4254246, 10.9721418],
                [105.425369, 10.9719564],
                [105.4253472, 10.9717644],
                [105.4253597, 10.9715716],
                [105.4254063, 10.9713839],
                [105.4255136, 10.9707519],
                [105.4255249, 10.9706145],
                [105.4255231, 10.9703389],
                [105.4255101, 10.9702016],
                [105.425489, 10.9700653],
                [105.4254233, 10.9697986],
                [105.4253275, 10.9695423],
                [105.4252685, 10.9694186],
                [105.4248642, 10.9686823],
                [105.4246978, 10.9683371],
                [105.4246231, 10.9681608],
                [105.4245391, 10.9679409],
                [105.4244639, 10.9677178],
                [105.4241242, 10.966523],
                [105.4240376, 10.9662757],
                [105.4238444, 10.9657884],
                [105.4237379, 10.9655487],
                [105.4235169, 10.9650995],
                [105.4232729, 10.964662],
                [105.4231425, 10.964448],
                [105.4228454, 10.964009],
                [105.4225202, 10.9635896],
                [105.420868, 10.9615988],
                [105.4206228, 10.9612643],
                [105.4203852, 10.9609247],
                [105.4201616, 10.9605899],
                [105.419484, 10.9595396],
                [105.4193252, 10.9592554],
                [105.4192542, 10.9591091],
                [105.4191199, 10.958783],
                [105.4190063, 10.9584203],
                [105.418962, 10.9582356],
                [105.4188937, 10.9578612],
                [105.4188526, 10.957675],
                [105.418752, 10.957332],
                [105.4186312, 10.957021],
                [105.4184487, 10.9566309],
                [105.4183449, 10.9563885],
                [105.4182501, 10.9561426],
                [105.4181176, 10.9557431],
                [105.4180602, 10.9555408],
                [105.4179372, 10.9550361],
                [105.4178927, 10.954888],
                [105.4177932, 10.9546135],
                [105.4176815, 10.9543625],
                [105.4176067, 10.9542461],
                [105.4175608, 10.954194],
                [105.4174588, 10.9541075],
                [105.417402, 10.9540719],
                [105.4172792, 10.9540175],
                [105.4168634, 10.9539201],
                [105.416723, 10.9538752],
                [105.4165943, 10.953804],
                [105.416536, 10.9537593],
                [105.4164343, 10.9536541],
                [105.4163906, 10.9535926],
                [105.4163236, 10.9534579],
                [105.416286, 10.9533127],
                [105.4162418, 10.9523348],
                [105.4162069, 10.952175],
                [105.4161401, 10.9520124],
                [105.4160373, 10.9518545],
                [105.4159749, 10.9517835],
                [105.4156983, 10.9515291],
                [105.4156052, 10.9514241],
                [105.4154541, 10.9512155],
                [105.4153411, 10.9510101],
                [105.415294, 10.9509028],
                [105.4152533, 10.950793],
                [105.4150705, 10.9501925],
                [105.4149872, 10.9499918],
                [105.4149349, 10.9498963],
                [105.4148128, 10.94972],
                [105.4147428, 10.9496385],
                [105.4146671, 10.949562],
                [105.4143483, 10.9492905],
                [105.4141303, 10.9490694],
                [105.4140163, 10.9489357],
                [105.4139097, 10.9487964],
                [105.4138106, 10.9486517],
                [105.4137194, 10.9485021],
                [105.4136364, 10.948348],
                [105.4135616, 10.9481899],
                [105.4132311, 10.9473939],
                [105.4131593, 10.9472471],
                [105.4130238, 10.9470027],
                [105.4125999, 10.9463362],
                [105.412242, 10.9457935],
                [105.4116426, 10.9449194],
                [105.4113354, 10.9444873],
                [105.4108258, 10.9437974],
                [105.410716, 10.9436206],
                [105.4106697, 10.9435276],
                [105.4105951, 10.9433339],
                [105.4105618, 10.9432113],
                [105.410538, 10.9430867],
                [105.410524, 10.9429606],
                [105.4105198, 10.9428338],
                [105.4105951, 10.9416801],
                [105.4106092, 10.9415469],
                [105.4106325, 10.9414151],
                [105.4106649, 10.9412851],
                [105.4107387, 10.9410735],
                [105.4107847, 10.9409712],
                [105.4108365, 10.9408716],
                [105.4112764, 10.9401316],
                [105.4113311, 10.9400055],
                [105.4113512, 10.9398698],
                [105.4113477, 10.9398012],
                [105.4113352, 10.9397336],
                [105.4112876, 10.9396119],
                [105.4112159, 10.9395103],
                [105.4111715, 10.9394664],
                [105.4110687, 10.9393951],
                [105.4110116, 10.9393687],
                [105.4108902, 10.9393363],
                [105.410188, 10.9392824],
                [105.4099512, 10.9392401],
                [105.4097224, 10.9391663],
                [105.4096125, 10.939118],
                [105.4094045, 10.9390002],
                [105.4092153, 10.938856],
                [105.4090479, 10.9386878],
                [105.4089053, 10.9384988],
                [105.4082125, 10.9374092],
                [105.4080648, 10.9372114],
                [105.4078934, 10.9370292],
                [105.4077991, 10.9369447],
                [105.4068883, 10.9362551],
                [105.4067892, 10.9361695],
                [105.4066085, 10.9359805],
                [105.4065277, 10.935878],
                [105.4063839, 10.9356533],
                [105.4063222, 10.9355316],
                [105.4062688, 10.9354063],
                [105.4062241, 10.9352777],
                [105.4061479, 10.9349865],
                [105.4060917, 10.9346618],
                [105.4060753, 10.9344885],
                [105.4060681, 10.9343145],
                [105.4060702, 10.9341404],
                [105.4061085, 10.9332576],
                [105.4061348, 10.9331328],
                [105.4061896, 10.9330172],
                [105.406232, 10.9329584],
                [105.4062818, 10.9329056],
                [105.4063382, 10.9328595],
                [105.4064001, 10.932821],
                [105.4066548, 10.932707],
                [105.4069133, 10.9326017],
                [105.4071753, 10.932505],
                [105.4073827, 10.9324354],
                [105.4078634, 10.9322904],
                [105.4079726, 10.9322232],
                [105.40806, 10.9321301],
                [105.4081194, 10.9320178],
                [105.4081378, 10.9319543],
                [105.4081471, 10.9318888],
                [105.4081471, 10.9318228],
                [105.4081378, 10.9317574],
                [105.4081194, 10.9316939],
                [105.407962, 10.9314423],
                [105.4077751, 10.931211],
                [105.4076715, 10.931104],
                [105.4074548, 10.9309159],
                [105.4073385, 10.9308314],
                [105.4070922, 10.9306826],
                [105.4063572, 10.9303297],
                [105.406214, 10.9302752],
                [105.4059303, 10.9301955],
                [105.4056509, 10.9301507],
                [105.4055096, 10.9301401],
                [105.4046243, 10.9301786],
                [105.4043777, 10.9301609],
                [105.4042561, 10.930139],
                [105.4040337, 10.9300749],
                [105.403836, 10.929989],
                [105.4037417, 10.9299371],
                [105.4036509, 10.9298794],
                [105.4033585, 10.9296555],
                [105.4030726, 10.9293825],
                [105.4029388, 10.9292373],
                [105.4027218, 10.9289725],
                [105.4025539, 10.9287364],
                [105.4023165, 10.9283664],
                [105.4022662, 10.9283041],
                [105.4022089, 10.9282479],
                [105.4021453, 10.9281987],
                [105.4020764, 10.928157],
                [105.4019638, 10.9281094],
                [105.4018444, 10.9280819],
                [105.4016697, 10.9280648],
                [105.4014944, 10.9280569],
                [105.4013987, 10.9280565],
                [105.401209, 10.928079],
                [105.4010193, 10.9281343],
                [105.4008362, 10.9282242],
                [105.4002901, 10.9285915],
                [105.400241, 10.928617],
                [105.4001352, 10.9286492],
                [105.4000245, 10.9286547],
                [105.3999078, 10.9286306],
                [105.3998529, 10.9286073],
                [105.3997481, 10.9285206],
                [105.3997054, 10.9284678],
                [105.3996531, 10.9283734],
                [105.3996222, 10.9282702],
                [105.3995406, 10.9278458],
                [105.3995107, 10.9276318],
                [105.3994713, 10.9271729],
                [105.3994606, 10.9268564],
                [105.3994266, 10.9266591],
                [105.3993767, 10.9265583],
                [105.3993411, 10.9265143],
                [105.3992524, 10.9264438],
                [105.3991513, 10.9264026],
                [105.3990472, 10.9263904],
                [105.3989436, 10.9264057],
                [105.3988527, 10.926433],
                [105.3986754, 10.9265005],
                [105.3984641, 10.9266087],
                [105.398268, 10.9267415],
                [105.397109, 10.9276514],
                [105.3969513, 10.9277549],
                [105.3968181, 10.9278216],
                [105.3967118, 10.9278445],
                [105.3966032, 10.9278359],
                [105.396505, 10.9277982],
                [105.3964241, 10.9277371],
                [105.3963908, 10.927699],
                [105.3963166, 10.9275604],
                [105.3962772, 10.927461],
                [105.3962214, 10.9272566],
                [105.3961972, 10.927048],
                [105.3962059, 10.9268286],
                [105.3962241, 10.9267154],
                [105.3962516, 10.926604],
                [105.3962882, 10.9264952],
                [105.396445, 10.9260941],
                [105.3965702, 10.9256824],
                [105.3966501, 10.9253327],
                [105.3966877, 10.9250479],
                [105.3966972, 10.9247656],
                [105.3966798, 10.9244884],
                [105.3966656, 10.924419],
                [105.3966128, 10.9242873],
                [105.3965749, 10.924227],
                [105.3964791, 10.9241214],
                [105.3963684, 10.9240447],
                [105.3962517, 10.9239962],
                [105.3961901, 10.923981],
                [105.3961273, 10.9239722],
                [105.3958215, 10.9240132],
                [105.3955211, 10.9240828],
                [105.3953787, 10.9241266],
                [105.395101, 10.9242332],
                [105.3949662, 10.9242959],
                [105.3941921, 10.9246899],
                [105.3940542, 10.9247463],
                [105.3939229, 10.924772],
                [105.3938045, 10.9247742],
                [105.3936385, 10.9247353],
                [105.3934869, 10.9246684],
                [105.3933539, 10.924578],
                [105.3928335, 10.9241197],
                [105.3927154, 10.9239977],
                [105.3924966, 10.9237387],
                [105.3923963, 10.9236021],
                [105.3923025, 10.9234613],
                [105.3921603, 10.9232149],
                [105.3920416, 10.9229567],
                [105.3919913, 10.922824],
                [105.3919379, 10.9226576],
                [105.3918941, 10.9224885],
                [105.39186, 10.9223173],
                [105.3916909, 10.921149],
                [105.391671, 10.921069],
                [105.3916423, 10.9209916],
                [105.3916051, 10.9209178],
                [105.3915598, 10.9208485],
                [105.391507, 10.9207844],
                [105.3914474, 10.9207265],
                [105.3913816, 10.9206754],
                [105.3913105, 10.9206317],
                [105.3911604, 10.9205699],
                [105.391005, 10.9205412],
                [105.390847, 10.9205447],
                [105.3906931, 10.9205801],
                [105.3901692, 10.9208009],
                [105.3898226, 10.9209575],
                [105.389639, 10.921015],
                [105.389448, 10.9210405],
                [105.3892555, 10.9210331],
                [105.3890664, 10.9209929],
                [105.3888874, 10.9209212],
                [105.3887237, 10.9208199],
                [105.3886493, 10.9207592],
                [105.3882515, 10.9204156],
                [105.3880608, 10.920235],
                [105.3876966, 10.9198571],
                [105.3873565, 10.9194582],
                [105.387152, 10.9191866],
                [105.3869702, 10.9188999],
                [105.3868211, 10.9186179],
                [105.3866532, 10.9182301],
                [105.386604, 10.9181383],
                [105.3865467, 10.9180513],
                [105.3864817, 10.9179695],
                [105.3864096, 10.9178938],
                [105.3863315, 10.9178251],
                [105.3862475, 10.9177634],
                [105.3861583, 10.9177093],
                [105.3860646, 10.9176631],
                [105.3858012, 10.9175673],
                [105.385633, 10.9175164],
                [105.3853596, 10.9174494],
                [105.3851604, 10.9173797],
                [105.384975, 10.91728],
                [105.3848888, 10.9172196],
                [105.3847346, 10.917082],
                [105.3846042, 10.9169224],
                [105.3845006, 10.9167449],
                [105.3842579, 10.9162139],
                [105.3840819, 10.9157665],
                [105.3840037, 10.9155393],
                [105.3838701, 10.9150896],
                [105.3837623, 10.9146333],
                [105.3836782, 10.9142137],
                [105.3835638, 10.9138011],
                [105.3834878, 10.9135766],
                [105.3832581, 10.9130009],
                [105.3831753, 10.912722],
                [105.3831437, 10.91258],
                [105.3831073, 10.9123556],
                [105.383102, 10.912192],
                [105.3831078, 10.9121102],
                [105.3831412, 10.9119313],
                [105.3833038, 10.9114232],
                [105.3833347, 10.9112464],
                [105.3833412, 10.9111568],
                [105.3833353, 10.9109712],
                [105.3833036, 10.9107881],
                [105.3832476, 10.9106436],
                [105.3832081, 10.9105767],
                [105.3831061, 10.9104548],
                [105.3830444, 10.9104019],
                [105.3829049, 10.9103178],
                [105.3828289, 10.9102877],
                [105.3826445, 10.9102447],
                [105.3825502, 10.9102347],
                [105.3824554, 10.9102324],
                [105.3822669, 10.9102511],
                [105.382089, 10.9102985],
                [105.3820059, 10.9103316],
                [105.381849, 10.9104166],
                [105.3812035, 10.9109224],
                [105.3811519, 10.910947],
                [105.3810415, 10.9109763],
                [105.3809844, 10.9109805],
                [105.3808804, 10.9109701],
                [105.3807904, 10.9109409],
                [105.3807292, 10.9109017],
                [105.3806251, 10.9108109],
                [105.3805443, 10.9107078],
                [105.3803884, 10.9104187],
                [105.3802891, 10.9101755],
                [105.3802486, 10.9100507],
                [105.3801853, 10.9097885],
                [105.3801491, 10.9095215],
                [105.3801091, 10.9083493],
                [105.3801334, 10.9081094],
                [105.3801917, 10.9078671],
                [105.3802862, 10.9076276],
                [105.3806804, 10.9070192],
                [105.3807111, 10.9069503],
                [105.3807467, 10.9068043],
                [105.3807513, 10.9067292],
                [105.3807337, 10.9065801],
                [105.3806813, 10.906439],
                [105.3805971, 10.9063138],
                [105.3804857, 10.9062113],
                [105.3803532, 10.9061369],
                [105.3801045, 10.9060781],
                [105.3799777, 10.9060605],
                [105.379751, 10.9060492],
                [105.3794557, 10.9060655],
                [105.3793872, 10.9060603],
                [105.379254, 10.9060275],
                [105.3791317, 10.9059664],
                [105.3790211, 10.9058749],
                [105.3789727, 10.9058172],
                [105.3789319, 10.9057541],
                [105.3788995, 10.9056865],
                [105.378856, 10.9055194],
                [105.3788434, 10.9053472],
                [105.3788623, 10.9051753],
                [105.378908, 10.9050047],
                [105.3789395, 10.9049202],
                [105.3790225, 10.90476],
                [105.3790734, 10.9046852],
                [105.3792002, 10.9045411],
                [105.3793587, 10.9044137],
                [105.3795377, 10.9043157],
                [105.379633, 10.9042788],
                [105.3801301, 10.9041439],
                [105.3801992, 10.9041156],
                [105.3803255, 10.9040372],
                [105.380381, 10.903988],
                [105.3804734, 10.9038727],
                [105.3805371, 10.9037399],
                [105.3805571, 10.9036691],
                [105.3805719, 10.903519],
                [105.3805659, 10.9034437],
                [105.3805276, 10.9032977],
                [105.3804593, 10.9031268],
                [105.3803673, 10.9029669],
                [105.380313, 10.9028921],
                [105.3801818, 10.9027478],
                [105.3801085, 10.9026832],
                [105.3799482, 10.9025708],
                [105.3797239, 10.902445],
                [105.3796068, 10.9023914],
                [105.3793581, 10.9023018],
                [105.379094, 10.9022393],
                [105.3789597, 10.9022195],
                [105.3786402, 10.9021938],
                [105.3784555, 10.9021897],
                [105.3782708, 10.9021951],
                [105.3780867, 10.9022099],
                [105.3777701, 10.9022579],
                [105.3774591, 10.9023337],
                [105.3759946, 10.9028236],
                [105.3759018, 10.9028671],
                [105.3758121, 10.9029164],
                [105.3756436, 10.9030319],
                [105.375487, 10.9031731],
                [105.3753472, 10.9033394],
                [105.3752865, 10.9034294],
                [105.3752474, 10.9035081],
                [105.3752, 10.9035823],
                [105.3751449, 10.9036511],
                [105.3750827, 10.9037138],
                [105.3750144, 10.9037695],
                [105.3749406, 10.9038178],
                [105.3748621, 10.9038582],
                [105.3746702, 10.903926],
                [105.3744453, 10.9039752],
                [105.3742157, 10.9039942],
                [105.3739857, 10.9039825],
                [105.373743, 10.9039362],
                [105.3735098, 10.9038558],
                [105.3733982, 10.9038034],
                [105.3732099, 10.9036854],
                [105.3730391, 10.9035441],
                [105.3728886, 10.903382],
                [105.372757, 10.903195],
                [105.3726526, 10.9029922],
                [105.3723925, 10.902356],
                [105.3722208, 10.9020045],
                [105.3720505, 10.9017136],
                [105.3719579, 10.9015726],
                [105.3715086, 10.9009277],
                [105.3714281, 10.9007924],
                [105.3713113, 10.9005633],
                [105.371248, 10.9003744],
                [105.3712177, 10.9001893],
                [105.3712155, 10.9001012],
                [105.3712218, 10.9000133],
                [105.3712586, 10.8998432],
                [105.371326, 10.8996845],
                [105.3714382, 10.8995136],
                [105.3715519, 10.8993073],
                [105.3716347, 10.8990873],
                [105.3716851, 10.8988579],
                [105.3717019, 10.8986173],
                [105.3716967, 10.8984933],
                [105.371682, 10.8983701],
                [105.371658, 10.8982484],
                [105.3716247, 10.8981287],
                [105.3715825, 10.8980118],
                [105.3715314, 10.8978984],
                [105.3714215, 10.8977772],
                [105.3713148, 10.8976958],
                [105.3711962, 10.8976324],
                [105.3709213, 10.8975124],
                [105.3706426, 10.8974012],
                [105.3703604, 10.8972989],
                [105.369405, 10.8970321],
                [105.3692837, 10.8969891],
                [105.3691754, 10.8969203],
                [105.3690858, 10.8968293],
                [105.3690189, 10.8967194],
                [105.3689955, 10.8966595],
                [105.3689705, 10.8965339],
                [105.3689691, 10.8964698],
                [105.3689986, 10.8962212],
                [105.369061, 10.8959499],
                [105.3691084, 10.8958039],
                [105.3691649, 10.8956612],
                [105.3694975, 10.8949342],
                [105.3696399, 10.89465],
                [105.3697494, 10.894352],
                [105.3697914, 10.894199],
                [105.3698248, 10.894044],
                [105.3698638, 10.8937461],
                [105.3698708, 10.8934459],
                [105.3698623, 10.893296],
                [105.3698016, 10.8929061],
                [105.3697604, 10.892713],
                [105.3696586, 10.8923381],
                [105.3695319, 10.8919772],
                [105.3691852, 10.89117],
                [105.3690181, 10.8907037],
                [105.368945, 10.8904672],
                [105.3688138, 10.8899623],
                [105.3687601, 10.8897071],
                [105.3687143, 10.8894505],
                [105.3685878, 10.8885216],
                [105.3685666, 10.8884317],
                [105.3685022, 10.8882583],
                [105.3684596, 10.888176],
                [105.3683549, 10.8880229],
                [105.3682855, 10.8879448],
                [105.3682092, 10.8878731],
                [105.3681266, 10.8878086],
                [105.3680384, 10.8877516],
                [105.3677787, 10.8876299],
                [105.3675196, 10.8875312],
                [105.3672633, 10.887454],
                [105.3664997, 10.8872448],
                [105.3653938, 10.8868956],
                [105.3650608, 10.8868144],
                [105.364892, 10.8867846],
                [105.3645511, 10.8867465],
                [105.3643798, 10.8867384],
                [105.3640228, 10.8867448],
                [105.3636542, 10.8867849],
                [105.3630203, 10.8869093],
                [105.3627484, 10.8869532],
                [105.3622186, 10.8870192],
                [105.361703, 10.8870589],
                [105.3604434, 10.8870842],
                [105.3602046, 10.887068],
                [105.3599703, 10.8870194],
                [105.3597454, 10.8869391],
                [105.3596377, 10.8868877],
                [105.3595768, 10.8868458],
                [105.3594681, 10.8867461],
                [105.3593804, 10.8866282],
                [105.3593128, 10.8864856],
                [105.3592891, 10.8864045],
                [105.3592744, 10.8863213],
                [105.359269, 10.8862371],
                [105.359273, 10.8861528],
                [105.3593431, 10.8856352],
                [105.3594412, 10.8851494],
                [105.3595619, 10.8846962],
                [105.3596324, 10.8844723],
                [105.3602332, 10.8827866],
                [105.3602917, 10.8825322],
                [105.3603222, 10.882273],
                [105.3603244, 10.8820122],
                [105.3603138, 10.8818722],
                [105.3602681, 10.8815952],
                [105.3602332, 10.8814591],
                [105.3601435, 10.8812185],
                [105.3600891, 10.8811021],
                [105.3599621, 10.8808783],
                [105.3598118, 10.8806686],
                [105.3596398, 10.8804752],
                [105.359448, 10.8803007],
                [105.359222, 10.8801337],
                [105.3590934, 10.8800538],
                [105.35896, 10.8799819],
                [105.3587065, 10.8798722],
                [105.3584686, 10.8797976],
                [105.3582242, 10.879747],
                [105.3579516, 10.8797331],
                [105.3578151, 10.8797387],
                [105.3575445, 10.8797746],
                [105.3572963, 10.8798382],
                [105.3571831, 10.8798777],
                [105.3569641, 10.8799744],
                [105.3561455, 10.8804265],
                [105.3560513, 10.8804727],
                [105.3559534, 10.8805109],
                [105.3557496, 10.8805621],
                [105.3555399, 10.8805788],
                [105.355328, 10.8805601],
                [105.3552228, 10.8805371],
                [105.3551201, 10.8805053],
                [105.3550206, 10.8804648],
                [105.3549251, 10.880416],
                [105.3546768, 10.8802185],
                [105.3545617, 10.8801097],
                [105.3543606, 10.8798853],
                [105.3541955, 10.8796521],
                [105.3541235, 10.879529],
                [105.3539988, 10.8792648],
                [105.3537194, 10.8793466],
                [105.3525686, 10.8778578],
                [105.3506311, 10.87538],
                [105.3494267, 10.8736036],
                [105.3493953, 10.8726089],
                [105.3494298, 10.872575],
                [105.3494816, 10.8724942],
                [105.3494985, 10.8724462],
                [105.3495075, 10.8723961],
                [105.3495117, 10.8722755],
                [105.3495367, 10.8721744],
                [105.349446, 10.8720905],
                [105.3493937, 10.8720069],
                [105.3493749, 10.8719282],
                [105.3493676, 10.871785],
                [105.3493332, 10.8716573],
                [105.3492247, 10.8713615],
                [105.3490359, 10.8708912],
                [105.3490048, 10.8707577],
                [105.3489967, 10.8706844],
                [105.3489979, 10.8705643],
                [105.3489827, 10.8704725],
                [105.3489408, 10.8703692],
                [105.3464053, 10.8694469],
                [105.3443669, 10.868795],
                [105.3425589, 10.8682167],
                [105.3420392, 10.86728],
                [105.3411989, 10.8662594],
                [105.3408428, 10.8653975],
                [105.3401889, 10.8646794],
                [105.3395217, 10.8636567],
                [105.3395817, 10.8634232],
                [105.3395803, 10.86333],
                [105.3395491, 10.8632504],
                [105.3394006, 10.8630656],
                [105.3392139, 10.8624411],
                [105.3392083, 10.8618539],
                [105.3388272, 10.8615725],
                [105.3385569, 10.8604905],
                [105.3381175, 10.859772],
                [105.3376919, 10.8599861],
                [105.3374162, 10.8601135],
                [105.3368558, 10.8603483],
                [105.3351277, 10.8609818],
                [105.334905, 10.8610714],
                [105.3344696, 10.8612729],
                [105.3340495, 10.8615033],
                [105.3338606, 10.8616196],
                [105.3334948, 10.8618694],
                [105.3331459, 10.8621417],
                [105.332825, 10.8624177],
                [105.332667, 10.8625442],
                [105.3325045, 10.862665],
                [105.3321861, 10.8628775],
                [105.3320217, 10.8629758],
                [105.3306984, 10.8636675],
                [105.3304413, 10.8638081],
                [105.3301886, 10.863956],
                [105.3297031, 10.864269],
                [105.3294707, 10.8644334],
                [105.3290204, 10.8647813],
                [105.3288031, 10.8649646],
                [105.3276337, 10.8659899],
                [105.3275265, 10.8660711],
                [105.3272977, 10.8662131],
                [105.3271771, 10.8662733],
                [105.3269255, 10.8663712],
                [105.3267977, 10.866408],
                [105.3265365, 10.8664581],
                [105.3264041, 10.8664713],
                [105.3254279, 10.8665073],
                [105.3252532, 10.8665253],
                [105.3249076, 10.8665859],
                [105.3247374, 10.8666284],
                [105.3244042, 10.8667374],
                [105.3242352, 10.8668066],
                [105.32407, 10.866884],
                [105.3239089, 10.8669695],
                [105.3237524, 10.8670628],
                [105.3236009, 10.8671638],
                [105.3234547, 10.8672721],
                [105.3232799, 10.8674283],
                [105.3229174, 10.8677264],
                [105.3225388, 10.8680044],
                [105.3223216, 10.86815],
                [105.3218743, 10.8684215],
                [105.3214109, 10.8686655],
                [105.3202575, 10.8692108],
                [105.3187294, 10.8699148],
                [105.3172133, 10.8706436],
                [105.3157099, 10.8713971],
                [105.313828, 10.872363],
                [105.3119028, 10.8733143],
                [105.3109202, 10.8737856],
                [105.308944, 10.8747055],
                [105.3046504, 10.8766151],
                [105.3037482, 10.8769987],
                [105.3028569, 10.8774061],
                [105.3019569, 10.8778473],
                [105.3010499, 10.8783237],
                [105.3006017, 10.8785713],
                [105.2995229, 10.8791931],
                [105.2992385, 10.879357],
                [105.2987736, 10.8796133],
                [105.2978329, 10.8801065],
                [105.2973418, 10.8803509],
                [105.2963487, 10.8808184],
                [105.2953423, 10.8812571],
                [105.2944956, 10.8816041],
                [105.2938268, 10.8818998],
                [105.2934968, 10.882057],
                [105.2929367, 10.8823415],
                [105.2922442, 10.8827182],
                [105.2913338, 10.8832427],
                [105.2904396, 10.8837933],
                [105.2895623, 10.8843696],
                [105.2890748, 10.8847061],
                [105.288593, 10.8850506],
                [105.2881171, 10.8854029],
                [105.2876472, 10.885763],
                [105.2859676, 10.8871238],
                [105.2857853, 10.8872505],
                [105.2855975, 10.8873692],
                [105.2854046, 10.8874797],
                [105.2852069, 10.8875817],
                [105.2850048, 10.887675],
                [105.2846057, 10.8878293],
                [105.2842118, 10.8879457],
                [105.2838096, 10.8880302],
                [105.2834018, 10.8880824],
                [105.2829909, 10.8881019],
                [105.2804495, 10.8880413],
                [105.2799556, 10.8880516],
                [105.2794618, 10.8880702],
                [105.2784757, 10.8881326],
                [105.2779836, 10.8881763],
                [105.2772147, 10.8882615],
                [105.2769383, 10.8883039],
                [105.2766635, 10.8883556],
                [105.2763906, 10.8884164],
                [105.2761201, 10.8884863],
                [105.2758521, 10.8885653],
                [105.275587, 10.8886531],
                [105.2753252, 10.8887498],
                [105.2750839, 10.888848],
                [105.2746114, 10.8890666],
                [105.2743807, 10.8891869],
                [105.2739316, 10.8894488],
                [105.2737137, 10.8895902],
                [105.2732921, 10.889893],
                [105.2715486, 10.8914733],
                [105.2713202, 10.8916679],
                [105.2708467, 10.8920373],
                [105.2703524, 10.8923794],
                [105.2698459, 10.8926887],
                [105.2693224, 10.8929693],
                [105.2674094, 10.8938554],
                [105.2671283, 10.8939956],
                [105.266851, 10.8941432],
                [105.2663091, 10.8944598],
                [105.2659396, 10.8946987],
                [105.2658389, 10.8947748],
                [105.2656524, 10.8949442],
                [105.2654882, 10.8951344],
                [105.265375, 10.8952987],
                [105.2652605, 10.8955095],
                [105.2652048, 10.8956382],
                [105.2651577, 10.8957701],
                [105.2651194, 10.8959048],
                [105.2650699, 10.8961777],
                [105.2650563, 10.8964546],
                [105.2643223, 10.8963558],
                [105.2590924, 10.8953846],
                [105.2543854, 10.8946891],
                [105.2485799, 10.8934514],
                [105.2484768, 10.8934326],
                [105.2397376, 10.8918447],
                [105.2330806, 10.8939679],
                [105.2239835, 10.8968692],
                [105.215642, 10.8995039],
                [105.213704, 10.9008691],
                [105.2086959, 10.9043969],
                [105.2013226, 10.9095908],
                [105.1992121, 10.9110774],
                [105.1977502, 10.9111363],
                [105.1872347, 10.9115598],
                [105.1867515, 10.9011733],
                [105.1863888, 10.8957139],
                [105.1855756, 10.8828772],
            ],
        ],
    },
];
